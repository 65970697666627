import React from "react";
import HKCTable from "./HKCTable";

function HKCView() {
  return (
    <div>
      <HKCTable data={tableData} />
    </div>
  );
}

export default HKCView;

const tableData = {
  topicInfo: { lastUpdate: "2022-01-21", name: "nwtable" },
  nwtable: {
    header: [
      "id",
      "t10",
      "nc",
      "hkc",
      "np",
      "t10_w1_L",
      "t10_w1_H",
      "nc_w1_L",
      "nc_w1_H",
      "hkc_w1_L",
      "hkc_w1_H",
      "np_w1_L",
      "np_w1_H",
      "t10_m1_L",
      "t10_m1_H",
      "nc_m1_L",
      "nc_m1_H",
      "hkc_m1_L",
      "hkc_m1_H",
      "np_m1_L",
      "np_m1_H",
      "t10_m3_L",
      "t10_m3_H",
      "nc_m3_L",
      "nc_m3_H",
      "hkc_m3_L",
      "hkc_m3_H",
      "np_m3_L",
      "np_m3_H",
      "t10_m6_L",
      "t10_m6_H",
      "nc_m6_L",
      "nc_m6_H",
      "hkc_m6_L",
      "hkc_m6_H",
      "np_m6_L",
      "np_m6_H",
      "t10_m12_L",
      "t10_m12_H",
      "nc_m12_L",
      "nc_m12_H",
      "hkc_m12_L",
      "hkc_m12_H",
      "np_m12_L",
      "np_m12_H",
      "C",
      "V",
      "p_w1_L",
      "p_w1_H",
      "p_m1_L",
      "p_m1_H",
      "p_m3_L",
      "p_m3_H",
      "p_m6_L",
      "p_m6_H",
      "p_m12_L",
      "p_m12_H",
      "rs",
    ],
    data: [
      {
        "0001": [
          "0001",
          90.25,
          33.59,
          0.88,
          1374.0,
          90.2,
          90.25,
          33.59,
          33.59,
          0.88,
          0.89,
          1372.0,
          1377.0,
          90.12,
          90.3,
          33.58,
          33.62,
          0.86,
          0.89,
          1372.0,
          1389.0,
          90.08,
          90.51,
          33.57,
          33.63,
          0.73,
          0.89,
          1372.0,
          1389.0,
          90.08,
          90.94,
          33.52,
          33.66,
          0.64,
          0.89,
          1372.0,
          1389.0,
          90.08,
          91.04,
          33.47,
          33.67,
          0.64,
          0.89,
          1372.0,
          1413.0,
          55.75,
          4263220.0,
          54.45,
          56.4,
          49.15,
          56.4,
          48.8,
          56.4,
          48.8,
          59.5,
          48.8,
          65.8,
          60.2,
        ],
      },
      {
        "0002": [
          "0002",
          88.91,
          44.87,
          0.17,
          1050.0,
          88.89,
          88.94,
          44.87,
          44.87,
          0.17,
          0.17,
          1049.0,
          1051.0,
          88.71,
          89.02,
          44.86,
          44.9,
          0.16,
          0.17,
          1047.0,
          1060.0,
          88.14,
          89.02,
          44.86,
          44.94,
          0.15,
          0.17,
          1040.0,
          1060.0,
          88.14,
          89.02,
          44.86,
          45.11,
          0.15,
          0.21,
          1027.0,
          1060.0,
          88.14,
          89.02,
          44.86,
          45.21,
          0.15,
          0.29,
          1022.0,
          1060.0,
          78.2,
          1968630.0,
          77.3,
          78.5,
          77.05,
          80.1,
          74.55,
          80.1,
          73.8,
          81.65,
          71.6,
          81.65,
          72.2,
        ],
      },
      {
        "0003": [
          "0003",
          89.43,
          48.82,
          0.21,
          1397.0,
          89.43,
          89.54,
          48.82,
          48.83,
          0.21,
          0.22,
          1397.0,
          1399.0,
          89.43,
          89.58,
          48.82,
          48.85,
          0.21,
          0.22,
          1395.0,
          1402.0,
          89.43,
          89.68,
          48.82,
          48.99,
          0.16,
          0.22,
          1389.0,
          1402.0,
          89.43,
          89.87,
          48.82,
          49.4,
          0.16,
          0.31,
          1381.0,
          1402.0,
          88.92,
          89.87,
          46.89,
          49.51,
          0.15,
          0.33,
          1375.0,
          1402.0,
          12.16,
          15537200.0,
          12.0,
          12.3,
          11.76,
          12.4,
          11.54,
          12.4,
          11.32,
          13.4,
          10.362,
          13.4,
          73.5,
        ],
      },
      {
        "0004": [
          "0004",
          94.84,
          47.84,
          0.14,
          424.0,
          94.83,
          94.86,
          47.84,
          47.86,
          0.14,
          0.14,
          424.0,
          426.0,
          94.82,
          94.86,
          47.84,
          47.89,
          0.14,
          0.14,
          423.0,
          426.0,
          94.81,
          94.89,
          47.84,
          47.95,
          0.14,
          0.15,
          421.0,
          429.0,
          93.93,
          95.05,
          47.84,
          48.2,
          0.14,
          0.18,
          421.0,
          433.0,
          92.2,
          95.05,
          47.48,
          48.36,
          0.14,
          0.25,
          421.0,
          471.0,
          26.1,
          2664200.0,
          24.35,
          26.4,
          22.35,
          26.4,
          22.35,
          29.0,
          22.35,
          29.0,
          16.76,
          30.7,
          77.9,
        ],
      },
      {
        "0005": [
          "0005",
          89.21,
          52.51,
          9.36,
          3589.0,
          88.96,
          89.21,
          52.34,
          52.51,
          9.35,
          9.36,
          3589.0,
          3600.0,
          88.56,
          89.21,
          51.99,
          52.51,
          9.32,
          9.36,
          3589.0,
          3636.0,
          88.45,
          89.21,
          51.87,
          52.51,
          9.23,
          9.36,
          3589.0,
          3655.0,
          88.12,
          89.21,
          51.43,
          52.51,
          9.21,
          9.36,
          3589.0,
          3689.0,
          87.56,
          89.21,
          49.99,
          52.51,
          9.21,
          9.54,
          3589.0,
          3764.0,
          53.25,
          34875500.0,
          52.75,
          55.1,
          45.2,
          55.1,
          42.2,
          55.1,
          38.3,
          55.1,
          38.3,
          55.1,
          85.6,
        ],
      },
      {
        "0006": [
          "0006",
          89.98,
          42.51,
          0.19,
          841.0,
          89.83,
          89.98,
          42.51,
          42.51,
          0.19,
          0.22,
          837.0,
          841.0,
          89.75,
          89.98,
          42.51,
          42.56,
          0.19,
          0.23,
          834.0,
          841.0,
          89.61,
          89.98,
          42.51,
          42.59,
          0.19,
          0.23,
          834.0,
          843.0,
          89.49,
          89.98,
          42.51,
          42.65,
          0.19,
          0.34,
          827.0,
          843.0,
          89.16,
          89.98,
          42.51,
          42.99,
          0.19,
          0.34,
          827.0,
          843.0,
          48.6,
          2730370.0,
          48.2,
          49.25,
          48.15,
          49.65,
          45.5,
          49.65,
          44.2,
          51.35,
          40.6,
          51.35,
          76.1,
        ],
      },
      {
        "0008": [
          "0008",
          85.09,
          44.87,
          0.08,
          1609.0,
          85.06,
          85.09,
          44.87,
          44.87,
          0.08,
          0.08,
          1607.0,
          1609.0,
          85.0,
          85.09,
          44.87,
          44.87,
          0.07,
          0.09,
          1607.0,
          1616.0,
          85.0,
          85.38,
          44.86,
          44.93,
          0.06,
          0.09,
          1607.0,
          1622.0,
          84.69,
          85.49,
          44.83,
          44.93,
          0.06,
          0.09,
          1607.0,
          1623.0,
          84.69,
          90.21,
          44.79,
          44.93,
          0.06,
          0.1,
          1607.0,
          1628.0,
          4.06,
          5259870.0,
          4.01,
          4.07,
          3.89,
          4.07,
          3.89,
          4.11,
          3.89,
          4.28,
          3.89,
          4.65,
          55.5,
        ],
      },
      {
        "0010": [
          "0010",
          94.63,
          38.08,
          2.44,
          262.0,
          94.62,
          94.64,
          38.08,
          38.52,
          2.44,
          2.46,
          262.0,
          263.0,
          94.57,
          94.69,
          38.08,
          38.52,
          2.44,
          2.55,
          259.0,
          263.0,
          94.39,
          94.91,
          38.08,
          38.52,
          2.44,
          2.65,
          257.0,
          263.0,
          92.37,
          94.91,
          38.08,
          38.68,
          2.44,
          2.65,
          256.0,
          263.0,
          92.2,
          94.91,
          38.08,
          40.2,
          2.3,
          2.65,
          253.0,
          263.0,
          17.52,
          446284.0,
          16.42,
          17.56,
          15.54,
          17.56,
          15.54,
          18.78,
          15.54,
          20.3,
          15.54,
          21.6,
          40.9,
        ],
      },
      {
        "0011": [
          "0011",
          93.82,
          67.15,
          0.31,
          1090.0,
          93.73,
          93.82,
          67.15,
          67.16,
          0.27,
          0.31,
          1090.0,
          1094.0,
          93.69,
          93.82,
          67.15,
          67.18,
          0.22,
          0.31,
          1090.0,
          1096.0,
          93.56,
          93.82,
          67.15,
          67.2,
          0.18,
          0.31,
          1090.0,
          1098.0,
          93.54,
          93.82,
          67.15,
          67.24,
          0.17,
          0.31,
          1075.0,
          1098.0,
          93.21,
          93.82,
          67.15,
          67.35,
          0.17,
          0.33,
          1073.0,
          1098.0,
          155.9,
          1355450.0,
          154.0,
          157.8,
          141.5,
          157.8,
          136.2,
          157.8,
          131.0,
          157.8,
          131.0,
          166.7,
          75.7,
        ],
      },
      {
        "0012": [
          "0012",
          94.78,
          74.29,
          0.08,
          744.0,
          94.71,
          94.78,
          74.29,
          74.29,
          0.08,
          0.08,
          742.0,
          744.0,
          94.71,
          94.8,
          74.27,
          74.29,
          0.08,
          0.08,
          742.0,
          745.0,
          94.6,
          94.86,
          74.27,
          74.29,
          0.08,
          0.09,
          742.0,
          754.0,
          94.53,
          94.86,
          74.27,
          74.29,
          0.08,
          0.1,
          742.0,
          759.0,
          94.4,
          94.86,
          74.27,
          74.32,
          0.08,
          0.16,
          742.0,
          774.0,
          34.65,
          5098430.0,
          33.15,
          34.9,
          31.7,
          34.9,
          31.4,
          34.9,
          28.0,
          36.95,
          28.0,
          38.85,
          71.6,
        ],
      },
      {
        "0013": [
          "0013",
          98.71,
          81.94,
          0.28,
          256.0,
          98.71,
          98.78,
          81.94,
          82.0,
          0.28,
          0.28,
          254.0,
          256.0,
          98.58,
          98.78,
          81.94,
          82.46,
          0.28,
          0.3,
          254.0,
          258.0,
          98.4,
          98.81,
          81.94,
          83.29,
          0.03,
          0.3,
          254.0,
          279.0,
          98.4,
          98.88,
          81.94,
          84.63,
          0.0,
          0.3,
          254.0,
          279.0,
          98.4,
          98.88,
          81.94,
          84.63,
          0.0,
          0.3,
          254.0,
          279.0,
          48.4,
          596975.0,
          47.9,
          51.7,
          47.9,
          57.95,
          44.4,
          59.8,
          44.4,
          77.6,
          44.4,
          85.8,
          31.5,
        ],
      },
    ],
  },
};
