import React from "react";
import { connect } from "react-redux";
import { Container, Divider, Form, Header } from "semantic-ui-react";
// import ZoomTable from "./ZoomTable";
import ZoomFilterForm from "./ZoomFilterForm2";
import { selectFilterById } from "./zoomSlice";
import { useDispatch, useSelector } from "react-redux";
import { addFilters, editFilters } from "./zoomSlice";
import { nanoid } from "@reduxjs/toolkit";
import { useHistory } from "react-router-dom";

function EditFilterPage(props) {
  const { id } = props.match.params;
  const { values } = props;
  console.log(values);
  const history = useHistory();
  const filter = useSelector((state) => selectFilterById(state, id));
  let mode;
  if (id === "new") {
    mode = "add";
  } else {
    if (filter) {
      mode = "edit";
    } else {
      mode = "Error";
    }
  }
  const dispatch = useDispatch();
  const users = "xyz";
  const canSave = true;

  const onSaveFilterClicked = async (e) => {
    e.preventDefault();
    let time = new Date();
    let timeString = time.toISOString();
    if (canSave) {
      await dispatch(
        addFilters({
          uid: "xyz",
          newFilter: {
            id: nanoid(),
            timeString: timeString,
            filter: values,
          },
        })
      );
      await history.push("/zoom/filterlist");
    }
  };

  const onEditFilterClicked = async (e) => {
    e.preventDefault();
    let time = new Date();
    let timeString = time.toISOString();
    if (canSave) {
      await dispatch(
        editFilters({
          uid: "xyz",
          oldFilter: filter,
          newFilter: {
            ...filter,
            timeString: timeString,
            filter: values,
          },
        })
      );
      await history.push("/zoom/filterlist");
    }
  };

  const onSearchClicked = (e) => {
    console.log(users);
    e.preventDefault();
    history.push(`/zoom/picker/${id}`);
  };
  return (
    <Container>
      <Divider hidden />

      <ZoomFilterForm
        initialValues={filter ? filter.filter : undefined}
        mode={mode}
        onSaveFilterClicked={onSaveFilterClicked}
        onSearchClicked={onSearchClicked}
        onEditFilterClicked={onEditFilterClicked}
        id={id}
      />
    </Container>
  );
}

const mapStateToProps = (state) => {
  return state.form.zoom
    ? {
        values: state.form.zoom.values,
        submitSucceeded: state.form.zoom.submitSucceeded,
      }
    : {};
};

export default connect(mapStateToProps)(EditFilterPage);
