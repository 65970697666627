import { TableContainer, Table,TableHead, TableBody, TableRow, TableCell, TablePagination, CircularProgress, IconButton } from "@material-ui/core";
import { useState, useEffect } from "react";
import RouteStopRow from "./RouteStopRow";
import RouteStopRowDouble from "./RouteStopRowDouble";
import { fetchETA, getTransportName } from "../lib/ETAUtility";
import Credit from "./Credit";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
export default function RouteStopTable(props) {
  const [ routeStopData, setRouteStopData ] = useState({
    etaLoaded: false,
    dataLoaded: false,
    error: null,
    data: null,
    data2: null,
  });
  const [ currPos, setCurrPos ] = useState({
    loaded: false,
    pos: null,
  });
  const [ page, setPage ] = useState(0);
  const [ rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function showPosition(position) {
    setCurrPos({
      loaded: true,
      pos: position.coords,
    });
  }
  function showErrPosition(err) {
    setCurrPos({
      loaded: true,
      pos: null,
    });
  }

  useEffect(() => {
    if ((state.transport_name === "NEAR" || state.transport_name === "FAV") && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showErrPosition);
    }
  }, []);

  const type = props.type;
  const state = props.state;
  const onStateChange = props.onStateChange; 

  let table_type = state.transport_name;
  if (type) {
    table_type = type;
  }

  useEffect(() => {
    if (table_type === "FAV") {
      let temp = [];
      let temp_not_added = [];
      for (let favIndex in state.favourites) {
        let added = false;
        for (let routeStopIndex in routeStopData.data) {
          if (routeStopData.data[routeStopIndex][0].transport_name === state.favourites[favIndex].transport_name &&
              routeStopData.data[routeStopIndex][0].route_code === state.favourites[favIndex].route_code &&
              routeStopData.data[routeStopIndex][0].route_bound === state.favourites[favIndex].route_bound &&
              routeStopData.data[routeStopIndex][0].route_service_type === state.favourites[favIndex].route_service_type &&
              routeStopData.data[routeStopIndex][0].stop_code === state.favourites[favIndex].stop_code) {
            temp.push([routeStopData.data[routeStopIndex][0]]);
            added = true;
            break;
          }
        }
        if (!added) {
          temp_not_added.push(state.favourites[favIndex]);
        }
      }
      for (let index in temp_not_added) {
        temp.push([temp_not_added[index]]);
      }
      setRouteStopData({
        ...routeStopData,
        data: temp,
        dataLoaded: true,
        etaLoaded: false,
      });
      return;
    }
  }, [state]);

  useEffect(() => {
    if (routeStopData.dataLoaded && routeStopData.etaLoaded) {
      const interval = setInterval(() => fetchETA(routeStopData, setRouteStopData), 30000);
      return () => {
        clearInterval(interval);
      };
    } else if (routeStopData.dataLoaded && !routeStopData.etaLoaded) {
      if (table_type === "SRCH" && routeStopData.searchTime === 1 && routeStopData.data.length === 0) {
        const request = "search_route_2";
        let tempBody;
        if (state.search.src_pos && state.search.dest_pos) {
          tempBody = JSON.stringify({
            "mode": "cors",
            "request": request,
            "src_latitude": state.search.src_pos.latitude,
            "src_longitude": state.search.src_pos.longitude,
            "src_dist_lmt": 0.002,
            "dest_latitude": state.search.dest_pos.latitude,
            "dest_longitude": state.search.dest_pos.longitude,
            "dest_dist_lmt": 0.002,
            "lang": "zh-HK"
          });
        } else if (state.search.src_pos) {
          tempBody = JSON.stringify({
            "mode": "cors",
            "request": request,
            "src_latitude": state.search.src_pos.latitude,
            "src_longitude": state.search.src_pos.longitude,
            "src_dist_lmt": 0.002,
            "dest_stop_name": state.search.dest_stop_name,
            "lang": "zh-HK"
          });
        } else if (state.search.dest_pos) {
          tempBody = JSON.stringify({
            "mode": "cors",
            "request": request,
            "src_stop_name": state.search.src_stop_name,
            "dest_latitude": state.search.dest_pos.latitude,
            "dest_longitude": state.search.dest_pos.longitude,
            "dest_dist_lmt": 0.002,
            "lang": "zh-HK"
          });
        } else {
          tempBody = JSON.stringify({
            "mode": "cors",
            "request": request,
            "src_stop_name": state.search.src_stop_name,
            "dest_stop_name": state.search.dest_stop_name,
            "lang": "zh-HK"
          });
        }
        fetch("https://n6r56ojg5m.execute-api.ap-east-1.amazonaws.com/default/public-transport-route-function-xWIkOoHW0aQr", {
          method: 'POST',
          body: tempBody
        })
        .then(function(response) {
          return response.json();
        })
        .then(function(json) {
          let data = [];
          for (let index in json.data) {
            let single = [{}, {}];
            for (let key in json.data[index]) {
              const newIndex = key[key.length-1] - 1;
              const newKey = key.slice(0, -2);
              single[newIndex][newKey] = json.data[index][key];
            }
            data.push(single);
          }

          setRouteStopData({
            ...routeStopData,
            error: null,
            dataLoaded: true,
            searchTime: 2,
            data: data,
          });
        })
        .catch(function(error) {
          setRouteStopData({
            ...routeStopData,
            error: error,
            dataLoaded: false,
            data: null,
          })
        });
      } else {
        fetchETA(routeStopData, setRouteStopData);
      }
    } else if (!routeStopData.data && (table_type !== "NEAR" || (currPos.loaded && currPos.pos))) {
      let tempBody;
      if (table_type === "FAV") {
        let temp = [];
        for (let index in state.favourites) {
          temp.push([state.favourites[index]]);
        }
        setRouteStopData({
          ...routeStopData,
          error: null,
          dataLoaded: true,
          data: temp,
        });
        return;
      }
      if (table_type === "SRCH") {
        const request = "search_route";
        if (state.search.src_pos && state.search.dest_pos) {
          tempBody = JSON.stringify({
            "mode": "cors",
            "request": request,
            "src_latitude": state.search.src_pos.latitude,
            "src_longitude": state.search.src_pos.longitude,
            "src_dist_lmt": 0.004,
            "dest_latitude": state.search.dest_pos.latitude,
            "dest_longitude": state.search.dest_pos.longitude,
            "dest_dist_lmt": 0.004,
            "lang": "zh-HK"
          });
        } else if (state.search.src_pos) {
          tempBody = JSON.stringify({
            "mode": "cors",
            "request": request,
            "src_latitude": state.search.src_pos.latitude,
            "src_longitude": state.search.src_pos.longitude,
            "src_dist_lmt": 0.004,
            "dest_stop_name": state.search.dest_stop_name,
            "lang": "zh-HK"
          });
        } else if (state.search.dest_pos) {
          tempBody = JSON.stringify({
            "mode": "cors",
            "request": request,
            "src_stop_name": state.search.src_stop_name,
            "dest_latitude": state.search.dest_pos.latitude,
            "dest_longitude": state.search.dest_pos.longitude,
            "dest_dist_lmt": 0.004,
            "lang": "zh-HK"
          });
        } else {
          tempBody = JSON.stringify({
            "mode": "cors",
            "request": request,
            "src_stop_name": state.search.src_stop_name,
            "dest_stop_name": state.search.dest_stop_name,
            "lang": "zh-HK"
          });
        }
      } else if (table_type === "NEAR") {
        tempBody = JSON.stringify({
          "mode": "cors",
          "request": "nearest_stop",
          "longitude": currPos.pos.longitude,
          "latitude": currPos.pos.latitude,
          "stop_limit": 10,
          "route_limit": 99999,
          "distance_limit": 15,
          "lang": "zh-HK"
        });
      } else {
        tempBody = JSON.stringify({
          "request": "route_stop",
          "transport_name": state.transport_name,
          "route_code": state.route_code,
          "route_bound": state.route_bound,
          "route_service_type": state.route_service_type,
          "lang": "zh-HK"
        });
      }
      fetch("https://n6r56ojg5m.execute-api.ap-east-1.amazonaws.com/default/public-transport-route-function-xWIkOoHW0aQr", {
        method: 'POST',
        body: tempBody
      })
      .then(function(response) {
        return response.json();
      })
      .then(function(json) {
        let temp = [];
        for (let index in json.data) {
          temp.push([json.data[index]]);
          if (table_type !== "NEAR" && table_type !== "SRCH" && table_type !== "FAV") {
            temp[index][0].first_stop_code = json.data[0].stop_code;
            temp[index][0].first_stop_name = json.data[0].stop_name;
            temp[index][0].first_stop_latitude = json.data[0].stop_latitude;
            temp[index][0].first_stop_longitude = json.data[0].stop_longitude;
            temp[index][0].first_stop_platform = json.data[0].stop_platform;
            temp[index][0].first_stop_seq = json.data[0].stop_seq;
            temp[index][0].last_stop_code = json.data[json.data.length - 1].stop_code;
            temp[index][0].last_stop_name = json.data[json.data.length - 1].stop_name;
            temp[index][0].last_stop_latitude = json.data[json.data.length - 1].stop_latitude;
            temp[index][0].last_stop_longitude = json.data[json.data.length - 1].stop_longitude;
            temp[index][0].last_stop_platform = json.data[json.data.length - 1].stop_platform;
            temp[index][0].last_stop_seq = json.data[json.data.length - 1].stop_seq;
          }
        }
        setRouteStopData({
          ...routeStopData,
          error: null,
          dataLoaded: true,
          searchTime: 1,
          data: temp,
        });
      })
      .catch(function(error) {
        setRouteStopData({
          ...routeStopData,
          error: error,
          dataLoaded: false,
          data: null,
        })
      });
    } 
  }, [routeStopData, currPos])
  
  let routeStops = null;
  if (routeStopData.data) {
    routeStops = Object.assign([], routeStopData.data);
  }

  let firstRouteStop = null;
  let lastRouteStop = null;

  const routeStopScore = (routeStop) => {
    let score = 0;
    if (routeStop[0] && routeStop[1] &&
      routeStop[0].eta && routeStop[1].eta &&
      routeStop[0].eta[0] && routeStop[1].eta[0] &&
      (routeStop[0].eta[0].time || routeStop[0].eta[0].remain_min) && (routeStop[1].eta[0].time || routeStop[1].eta[0].remain_min)) {
      score = 8;
    } else if (routeStop[0] && routeStop[0].eta && routeStop[0].eta[0] && (routeStop[0].eta[0].time || routeStop[0].eta[0].remain_min)) {
      score = 6;
    } else if (routeStop[1] && routeStop[1].eta && routeStop[1].eta[0] && (routeStop[1].eta[0].time || routeStop[1].eta[0].remain_min)) {
      score = 4;
    }
    return score;
  }

  if (routeStops !== null && routeStops[0] && table_type !== "FAV" && table_type !== "NEAR" && table_type !== "SRCH") {
    firstRouteStop = routeStops[0][0];
    lastRouteStop = routeStops[routeStops.length-1][0];
  } else if (table_type === "FAV" && currPos.pos) {
    for (let routeIndex in routeStops) {
      let lat = currPos.pos.latitude - routeStops[routeIndex][0].stop_latitude;
      let long = currPos.pos.longitude - routeStops[routeIndex][0].stop_longitude;
      routeStops[routeIndex][0].key = lat * lat + long * long;
    }
    
    if (routeStops) {
      routeStops.sort((a, b) => {
        return a[0].key - b[0].key;
      });
    }
  } else if (table_type === "SRCH" || table_type === "NEAR") {
    for (let i in routeStops) {
      routeStops[i][0].key = routeStopScore(routeStops[i]);
    }
    if (routeStops) {
      routeStops.sort((a, b) => {
        return routeStopScore(b) - routeStopScore(a);
      });
      let tempRouteStops = routeStops;
      routeStops = [];
      for (let index = page * rowsPerPage; (index < (page + 1) * rowsPerPage) && (index < tempRouteStops.length); index++) {
        routeStops.push(tempRouteStops[index]);
      }
    }
  }

  const handleBack = () => {
    onStateChange({
      ...state,
      route_code: "",
      search: null,
    })
  }

  const getSrchFromName = () => {
    return state.search.src_stop_name;
  };
  const getSrchToName = () => {
    return state.search.dest_stop_name;
  };

  return (
    <>
      <TableContainer>
        <Table size="small" aria-label="a dense table">
        
        <TableRow>
          <TableCell width="10%">
            { state.transport_name !== "FAV" && state.transport_name !== "NEAR" &&
              <IconButton onClick={(event) => {handleBack()}} variant="outlined" color="primary">
                <NavigateBeforeIcon color="primary"></NavigateBeforeIcon>
              </IconButton>
            }
          </TableCell>
          <TableCell align="center" width="80%">
            <h1>{getTransportName(table_type)}路線{  state.route_name === "" ? "" : " " + state.route_name}</h1>
          </TableCell>
          <TableCell width="10%"></TableCell>
        </TableRow>
        { firstRouteStop && lastRouteStop && table_type !== "FAV" && table_type !== "SRCH" && table_type !== "NEAR" &&
        <TableRow>
          <TableCell align="center" colSpan={3}>
            <h3>{firstRouteStop.stop_name} 往 {lastRouteStop.stop_name}</h3>
          </TableCell>
        </TableRow>
        }
        { table_type === "SRCH" &&
          <TableRow>
            <TableCell align="center" colSpan={3}>
              <h3>{getSrchFromName()} 往 {getSrchToName()}</h3>
            </TableCell>
          </TableRow>
        }
        <TableRow>
          <TableCell colSpan={3}>
            <Credit state={state} routeStops={routeStops}></Credit>
          </TableCell>
        </TableRow>
        </Table>
      </TableContainer>
      <TableContainer>
        { (state.transport_name === "SRCH" || state.transport_name === "NEAR") && routeStopData && routeStopData.data &&
          <TablePagination
            component="div"
            count={routeStopData.data.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell width="25%">
                <h3>
                  車站
                </h3>
              </TableCell>
              <TableCell width="75%">
                <h3>到站時間
                </h3>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { (currPos.loaded && currPos.pos === null) && table_type === "FAV" &&
              <TableRow>
                <TableCell colSpan={2} align="center">
                  <h3>開啟及准許定位功能，體驗更住。</h3>
                </TableCell>
              </TableRow>
            }
            { (null != routeStops) ?
              (routeStops.length === 0 && table_type === "FAV") ? 
                <TableRow>
                  <TableCell colSpan={2} align="center">
                    <h3>沒有收藏路線</h3>
                  </TableCell>
                </TableRow>
              : routeStops.map((routeStop, index) => {
                let row = <RouteStopRow key={index} state={state} onStateChange={(state) => onStateChange(state)} routeStop={routeStop[0]}></RouteStopRow>;
                if (routeStop.length === 2) {
                  row = <RouteStopRowDouble key={index} state={state} onStateChange={(state) => onStateChange(state)} routeStop={routeStop}></RouteStopRowDouble>
                }
                return row;
              })
              : (currPos.loaded && currPos.pos === null) && table_type === "NEAR" ?
                <TableRow>
                  <TableCell colSpan={2} align="center">
                    <h3>請開啟及准許定位功能。</h3>
                  </TableCell>
                </TableRow>
              : <TableRow>
                  <TableCell colSpan={2} align="center">
                    <CircularProgress></CircularProgress>
                  </TableCell>
                </TableRow>
            }
          </TableBody>
        </Table>
        { (state.transport_name === "SRCH" || state.transport_name === "NEAR") && routeStopData && routeStopData.data &&
          <TablePagination
            component="div"
            count={routeStopData.data.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      </TableContainer>
      
    </>
  );
}