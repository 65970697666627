import React, { Component } from "react";
import { Loader, Table, Pagination, Menu, Grid, Container, Header, Dropdown, Input, Icon } from 'semantic-ui-react'
import _ from 'lodash'
import { createStore, combineReducers } from 'redux';
import { isMobile } from 'react-device-detect';
// import GoogleAd from '../Google_ad'
import { Link } from 'react-router-dom';
import InFeedAdsWhite from '../ads/InFeedAdsWhite';
import './CompanyResultTable.css';


export class CompanyResultTable extends Component {
  state = this.props.data
  render() {
    return (
      <>
        <div style={{ paddingTop: '25px', paddingBottom: '25px' }}>
          {typeof this.state !== 'undefined' ? <SortableTable data={this.state} /> : <NotFound />}
        </div>
      </>
    );
  }
}

export default CompanyResultTable;

function NotFound() {
  return (
    <div
      style={{
        padding: "100px",
        margin: "auto",
        alignItems: "center",
        height: "75vh",
        // overflow: "auto",
      }}
    >
      <Loader active inline='centered' > Loading</Loader>
    </div>
  )
}

const initialState = {
  column: null,
  data: [],
  options: [],
  direction: null,
  filterText: { value: '' },
  filterInd: "",
  activePage: "1",
  isFocus: "1",
  totalPage: "4"

};

const reducer = combineReducers({
  resultTable: resultTableReducer,
});

function resultTableReducer(state = initialState, action) {
  switch (action.type) {
    case 'CHANGE_SORT': {
      if (state.column === action.column) {
        return {
          ...state,
          data: state.data.reverse(),
          direction:
            state.direction === 'ascending' ? 'descending' : 'ascending',
        }
      }
      return {
        column: action.column,
        data: _.sortBy(state.data, [action.column]),
        direction: 'ascending',
        filterText: state.filterText
      }
    }
    case 'Filter_MESSAGE': {
      const string = action.text
      return {
        ...state, filterText: string
      }
    }
    case 'Toggle_isFocus': {
      const string = action.text
      return {
        ...state, isFocus: string
      }
    }
    case 'SET_IND_FILTER': {

      return {
        ...state, filterInd: action.state
      }
    }
    case 'SET_ACTIVE_PAGE': {
      const string = action.text
      window.scrollTo(0, 0)
      return {
        ...state, activePage: string
      }
    }
    case 'ADD_MESSAGE': {
      return {
        ...state, data: action.state
      }
    }
    case 'ADD_OPTIONS': {
      return {
        ...state, options: action.state
      }
    }
    default: {
      return state;
    }
  }
}

const store = createStore(reducer);

class SortableTable extends React.Component {
  constructor(props) {
    super(props);
    this.setData();
  }

  setData() {
    const data1 = this.props.data
    const date = data1.date
    const name_zh = data1.name_zh
    const name_en = data1.name_en
    const code = data1.code
    const content = data1.content
    const focus = data1.focus
    const industry = data1.industry
    const industryIndex = data1.industryIndex
    const options = [{ key: '所有', text: '所有', value: '所有' }]
    const tableData1 = []
    // const totalPage = Math.ceil(date.length/50)
    let i = 0
    let j = 0
    for (i; i < date.length; i += 1) {
      tableData1.push({ no: i + 1, date: date[i], name_zh: name_zh[i], name_en: name_en[i], code: code[i], content: content[i], focus: focus[i], industry: industry[i] })
    }
    for (j; j < industryIndex.length; j += 1) {
      options.push(
        { key: industryIndex[j], text: industryIndex[j], value: industryIndex[j] }
      )
    }
    store.dispatch({
      type: 'ADD_MESSAGE',
      state: tableData1,
    });
    store.dispatch({
      type: 'ADD_OPTIONS',
      state: options,
    });
  }

  componentDidMount() {
    store.subscribe(() => this.forceUpdate());
  }
  render() {
    const state = store.getState();
    const { data, filterText, activePage, isFocus, options, filterInd } = state.resultTable
    let newData = data
    if (filterText.value) {
      newData = newData.filter((d) => {
        return (
          (typeof d.code === 'string' && d.code.toLowerCase().includes(filterText.value.toLowerCase())) ||
          (typeof d.name_zh === 'string' && d.name_zh.toLowerCase().includes(filterText.value.toLowerCase())) ||
          (typeof d.name_en === 'string' && d.name_en.toLowerCase().includes(filterText.value.toLowerCase()))
        )
      })
    }
    if (filterInd && filterInd !== "所有") {
      newData = newData.filter((d) => {
        return (
          (d.industry === filterInd)
        )
      })
    }
    if (isFocus === '1') {
      newData = newData.filter((d) => {
        return (
          d.focus === 1
        )
      })
    }
    const nPerPage = 24
    const totalPage = Math.ceil(newData.length / nPerPage)

    newData = _.slice(newData, (activePage - 1) * nPerPage, activePage * nPerPage)


    return (
      <div style={{ width: '100%', maxWidth: '800px', margin: "auto", alignItems: "center", minHeight:"75vh" }}>
        {/* <Container style={{ paddingBottom: '25px' }}>
          <Grid stackable columns={2}>
            <Grid.Column floated='left'>
              <ToggleFocus />
            </Grid.Column>
            {isMobile?
                    null:
                    <Grid.Column floated='right'>
                      <PaginationContainer totalPage = {totalPage}/>
                    </Grid.Column>}
          </Grid>
        </Container> */}

        <Container style={{ paddingBottom: '25px' }}>
          <FilterInput value={filterText} />
          <DropdownExampleControlled options={options} value={filterInd} />
        </Container>

        <Container>
          <div style={{ whiteSpace: 'nowrap', overflowX: 'auto', display: 'inline-block', width: '100%', maxWidth: '800px', textAlign: 'center' }} >
            <Table unstackable celled striped >
              <Table.Header>
                <Table.Row>
                  {/* <Table.HeaderCell
                                // sorted={column === 'date' ? direction : null}
                                // onClick={() => store.dispatch({ type: 'CHANGE_SORT', column: 'date' })}
                                >
                                a
                                </Table.HeaderCell> */}
                  <Table.HeaderCell width={2}
                  // sorted={column === 'date' ? direction : null}
                  // onClick={() => store.dispatch({ type: 'CHANGE_SORT', column: 'date' })}
                  >
                    日期
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1}
                  // sorted={column === 'name_zh' ? direction : null}
                  // onClick={() => store.dispatch({ type: 'CHANGE_SORT', column: 'code' })}
                  >
                    編號
                  </Table.HeaderCell>
                  <Table.HeaderCell width={10}
                  // sorted={column === 'code' ? direction : null}
                  // onClick={() => store.dispatch({ type: 'CHANGE_SORT', column: 'name_zh' })}
                  >
                    股份名稱(中)
                  </Table.HeaderCell>
                  {/* <Table.HeaderCell
                                // sorted={column === 'code' ? direction : null}
                                // onClick={() => store.dispatch({ type: 'CHANGE_SORT', column: 'name_en' })}
                                >
                                股份名稱(英)
                                </Table.HeaderCell> */}
                  <Table.HeaderCell width={5}
                  // sorted={column === 'code' ? direction : null}
                  // onClick={() => store.dispatch({ type: 'CHANGE_SORT', column: 'content' })}
                  >
                    行業
                  </Table.HeaderCell>
                  <Table.HeaderCell width={5}
                  // sorted={column === 'code' ? direction : null}
                  // onClick={() => store.dispatch({ type: 'CHANGE_SORT', column: 'content' })}
                  >
                    內容
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {newData.map(({ no, date, code, name_zh, name_en, industry, content }, index) => (
                  <>
                    {(index !== 0) && (index % 10 === 0) && (
                      <Table.Row>
                        <Table.Cell colSpan={5} textAlign='center'>
                          <InFeedAdsWhite></InFeedAdsWhite>
                        </Table.Cell>
                      </Table.Row>
                    )}
                    <Table.Row key={code}>
                      <Table.Cell>{date}</Table.Cell>
                      <Table.Cell>
                        {/* {code} */}
                        <Link to={"/stock/" + code} >
                          {code}
                        </Link>
                      </Table.Cell>
                      <Table.Cell>{name_zh}</Table.Cell>
                      <Table.Cell>{industry}</Table.Cell>
                      <Table.Cell>{content}</Table.Cell>
                    </Table.Row>
                  </>
                ))}
              </Table.Body>
            </Table>
          </div>
        </Container>
        <Container style={{ paddingTop: '25px', paddingBottom: '25px' }}>
          <PaginationContainer totalPage={totalPage} />
        </Container>
        {/* <Container textAlign='center' style={{ paddingTop: '30px', paddingBottom: '30px' }}>
          <GoogleAd
            client="ca-pub-8753358837449417"
            slot="2486257452"
            format="auto"
            wrapperDivStyle={{
              marginTop: '30px',
              marginBottom: '30px'
            }}
          />
        </Container> */}
      </div>
    )
  }
}

class PaginationContainer extends React.Component {
  componentDidMount() {
    store.subscribe(() => this.forceUpdate());
  }

  handlePaginationChange = (e, { activePage }) => {
    store.dispatch({
      type: 'SET_ACTIVE_PAGE',
      text: activePage,
    });

  }

  render() {
    const pos = isMobile ? 'left' : 'right'

    return (
      <div style={{ float: pos }}>
        <Pagination
          boundaryRange={0}
          defaultActivePage={1}
          // ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          siblingRange={1}
          totalPages={this.props.totalPage}
          onPageChange={this.handlePaginationChange}
        />

      </div>
    );
  }
}



class ToggleFocus extends React.Component {
  componentDidMount() {
    store.subscribe(() => this.forceUpdate());
  }

  state = { activeItem: '焦點股' }

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name })
    if (name === '焦點股') {
      store.dispatch({
        type: 'Toggle_isFocus',
        text: "1",
      });
    } else {
      store.dispatch({
        type: 'Toggle_isFocus',
        text: "0",
      });
    }
  }

  render() {
    const { activeItem } = this.state

    return (
      <div>
        <Menu pointing secondary compact>
          <Menu.Item
            name='焦點股'
            active={activeItem === '焦點股'}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name='全部'
            active={activeItem === '全部'}
            onClick={this.handleItemClick}
          />
        </Menu>
      </div>
    );
  }
}

class FilterInput extends React.Component {
  componentDidMount() {
    store.subscribe(() => this.forceUpdate());
  }

  state = {
    value: ''
  };

  onChangeFilter = (e) => {
    this.setState({
      value: e.target.value,
    })

    store.dispatch({
      type: 'Filter_MESSAGE',
      text: { value: e.target.value },
    });
    store.dispatch({
      type: 'SET_ACTIVE_PAGE',
      text: '1',
    });
  };

  onClear = (e) => {
    this.setState({
      value: '',
    })

    store.dispatch({
      type: 'Filter_MESSAGE',
      text: { value: e.target.value },
    });
  };

  render() {
    return (
      <div style={{ paddingBottom: '25px' }}>
        <Input
          style={{width:'100%', maxWidth:'550px'}}
          icon={<Icon name='close' inverted circular link onClick={this.onClear} />}
          onChange={this.onChangeFilter}
          value={this.state.value}
          type='text'
          placeholder="股票編號或名字"
        />
      </div>
    );
  }
}

class DropdownExampleControlled extends Component {
  state = {}

  handleChange = (e, { value }) => {
    this.setState({ value })
    store.dispatch({
      type: 'SET_IND_FILTER',
      state: value,
    });
  }

  render() {
    return (
      <div>
        <Dropdown onChange={this.handleChange} placeholder='行業' clearable scrolling selection value={this.props.value} options={this.props.options} />
      </div>

    )
  }
}