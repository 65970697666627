import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { Table, Header, Icon } from "semantic-ui-react";
// import MetaTags from 'react-meta-tags';
// import InFeedAdsWhite from '../ads/InFeedAdsWhite';

function StockRankTable(props) {
  function obj2Array(n) {
    return Object.values(n);
  }
  const header = props.data.tableHeader;
  const lastUpdate = props.data.lastUpdate;
  var data = _.map(props.data.data, obj2Array);
  const tableHeader = props.data.Header;
  const type = props.type;
  return (
    <>
      <div
        style={{
          whiteSpace: "nowrap",
          overflowX: "auto",
          display: "inline-block",
          width: "100%",
          maxWidth: "800px",
          textAlign: "center",
        }}
      >
        <Header as="h1"> {header} </Header>
        <Header as="h4" textAlign="right" color="grey">
          {" "}
          最後更新時間 : {lastUpdate}{" "}
        </Header>
        {type === "northWater" ? (
          <Header as="h3" textAlign="right">
            <Link to="/topics/hkcactive">
              <Icon disabled name="table" />
              過去十天數據
            </Link>
          </Header>
        ) : null}
        <Table unstackable basic="very" celled collapsing>
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell width={2}>
                <Header as="h3">{tableHeader[1]}</Header>
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                <Header as="h3">{tableHeader[2]}</Header>
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                <Header as="h3">{tableHeader[3]}</Header>
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                <Header as="h3">{tableHeader[5]}</Header>
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                <Header as="h3">{tableHeader[6]}</Header>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.map((item, index) => {
              return (
                <>
                  {/* {(index !== 0) && (index % 10 === 0) && (
                    <Table.Row>
                      <Table.Cell colSpan={5} textAlign='center'>
                        <InFeedAdsWhite></InFeedAdsWhite>
                      </Table.Cell>
                    </Table.Row>
                  )} */}
                  <Table.Row key={index} textAlign="center">
                    <Table.Cell>
                      <Header as="h4">
                        {/* {item[0][1]}
                        <Header.Subheader>{item[0][0]}</Header.Subheader> */}
                        <Link to={"/stock/" + item[0][0]}>
                          {item[0][1]}
                          <Header.Subheader>{item[0][0]}</Header.Subheader>
                        </Link>
                      </Header>
                    </Table.Cell>

                    <Table.Cell>${item[0][2]}</Table.Cell>

                    {item[0][3][0] === "+" ? (
                      <Table.Cell positive>
                        {" "}
                        {item[0][3]} <br /> {item[0][4]}{" "}
                      </Table.Cell>
                    ) : item[0][3][0] === "-" ? (
                      <Table.Cell negative>
                        {" "}
                        {item[0][3]} <br /> {item[0][4]}{" "}
                      </Table.Cell>
                    ) : (
                      <Table.Cell>
                        {" "}
                        {item[0][3]} <br /> ({item[0][4]}){" "}
                      </Table.Cell>
                    )}

                    {item[0].slice(5, item[0].length).map((cell, index) => {
                      return cell[0] === "+" ? (
                        <Table.Cell key={index} positive>
                          {cell}
                        </Table.Cell>
                      ) : cell[0] === "-" ? (
                        <Table.Cell key={index} negative>
                          {cell}{" "}
                        </Table.Cell>
                      ) : (
                        <Table.Cell key={index}>{cell}</Table.Cell>
                      );
                    })}
                  </Table.Row>
                </>
              );
            })}
          </Table.Body>
        </Table>
        <Header as="h4" textAlign="right" color="grey">
          {" "}
          最後更新時間 : {lastUpdate}{" "}
        </Header>
      </div>
    </>
  );
}

export default StockRankTable;
