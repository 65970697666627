import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import DisplayAdsBanner from "../../ads/DisplayAdsBanner";
import GoogleAd from "../../ads/Google_ad";
import { Container, Menu, Message } from "semantic-ui-react";
import { USChart } from "./HSIChart2";
import { USChartPaid } from "./HSIChart2Paid";
import TopicIndexTable from "../IndexTable/IndexTableView";
import { Link, useParams } from "react-router-dom";
import { useAllowedBehindPaywall } from "features/user/userSlice";

export const USMarket = () => {
  // const { type } = useParams();
  // console.log("hi");
  const allowedBehindPaywall = useAllowedBehindPaywall();
  return (
    <>
      <MetaTags>
        <title>指數及市寛(美股)</title>
        <meta name="description" content="指數及市寛(美股)" />
        <meta property="og:title" content="指數及市寛(美股)" />
        <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
      </MetaTags>
      <Container textAlign="center">
        <DisplayAdsBanner />
      </Container>
      {/* <Container>
        <div style={{ paddingBottom: "10px" }}>
          <Message size="big" color="yellow" style={{ textAlign: "center" }}>
            美股功能測試中
          </Message>
        </div>
      </Container> */}

      <div
        style={{ paddingTop: "25px", paddingBottom: "25px", minHeight: "75vh" }}
      >
        <Container>
          <div>{allowedBehindPaywall ? <USChartPaid /> : <USChart />}</div>
        </Container>
      </div>
      <div
        textAlign="center"
        style={{ paddingTop: "30px", paddingBottom: "0px" }}
      >
        <GoogleAd
          client="ca-pub-8753358837449417"
          slot="2486257452"
          format="auto"
          wrapperDivStyle={{
            marginTop: "30px",
            marginBottom: "20px",
          }}
        />
      </div>
    </>
  );
};

export default USMarket;
