import { useEffect, useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { Loader } from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { toDateString } from "../../util/datetime";

const useStyles = makeStyles((theme) => ({
  input_grid: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 20,
    marginRight: 20,
  },
  item: {
    marginTop: "auto",
    marginBottom: "auto",
  },
}));

export default function StockStat(props) {
  const classes = useStyles();

  const [stockStat, setStockStat] = useState({
    loaded: false,
    data: null,
  });

  const [stockList, setStockList] = useState({
    loaded: false,
    data: null,
  });

  const [dateList, setDateList] = useState({
    loaded: false,
    data: null,
  });

  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [stockCode, setStockCode] = useState(null);

  function zip(arrays) {
    return Array.apply(null, Array(arrays[0].length)).map(function (_, i) {
      return arrays.map(function (array) {
        return array[i];
      });
    });
  }

  function handleDateFrom(event, data) {
    setDateFrom(data.value);
    setStockStat({
      ...stockStat,
      loaded: false,
      data: null,
    });
  }
  function handleDateTo(event, data) {
    setDateTo(data.value);
    setStockStat({
      ...stockStat,
      loaded: false,
      data: null,
    });
  }
  function handleStockCode(event, newValue) {
    setStockCode(newValue);
    setStockStat({
      ...stockStat,
      loaded: false,
      data: null,
    });
  }
  function datePickerFilterDateFrom(date) {
    if (toDateString(date).localeCompare(toDateString(dateTo)) > 0) {
      return false;
    }
    for (let index in dateList.data) {
      if (
        toDateString(new Date(dateList.data[index].date)) === toDateString(date)
      ) {
        return true;
      }
    }
    return false;
  }
  function datePickerFilterDateTo(date) {
    if (toDateString(date).localeCompare(toDateString(dateFrom)) < 0) {
      return false;
    }
    for (let index in dateList.data) {
      if (
        toDateString(new Date(dateList.data[index].date)) === toDateString(date)
      ) {
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    if (!dateList.loaded) {
      const tempBody = JSON.stringify({
        mode: "cors",
        request: "equity_price_available_date",
        lang: "zh-HK",
      });
      fetch(
        "https://gmdux1cuu9.execute-api.ap-east-1.amazonaws.com/default/daashk-stock-function-OFIqWRaOuhig",
        {
          method: "POST",
          body: tempBody,
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (json) {
          setDateList({
            ...dateList,
            loaded: true,
            data: json.data,
          });
          const tempDateFrom = new Date(
            json.data.length > 250
              ? json.data[json.data.length - 251].date
              : json.data.length > 0
              ? json.data[0].date
              : null
          );
          const tempDateTo = new Date(
            json.data.length > 0 ? json.data[json.data.length - 1].date : null
          );

          setDateFrom(tempDateFrom);
          setDateTo(tempDateTo);
        })
        .catch(function (error) {
          console.log(error);
          setDateList({
            ...dateList,
            loaded: true,
            data: null,
          });
        });
    }
  }, [dateList]);

  useEffect(() => {
    if (!stockList.loaded) {
      const tempBody = JSON.stringify({
        mode: "cors",
        request: "equity_list",
        lang: "zh-HK",
      });
      fetch(
        "https://gmdux1cuu9.execute-api.ap-east-1.amazonaws.com/default/daashk-stock-function-OFIqWRaOuhig",
        {
          method: "POST",
          body: tempBody,
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (json) {
          setStockList({
            ...stockList,
            loaded: true,
            data: json.data,
          });
          if (json.data[0]) {
            setStockCode(json.data[0]);
          }
        })
        .catch(function (error) {
          console.log(error);
          setStockList({
            ...stockList,
            loaded: true,
            data: null,
          });
        });
    }
  }, [stockList]);

  useEffect(() => {
    if (!stockStat.loaded && stockCode && dateFrom && dateTo) {
      const tempBody = JSON.stringify({
        mode: "cors",
        request: "price_stat_given_equity",
        stock_code: stockCode.stock_code,
        from_date: toDateString(dateFrom),
        to_date: toDateString(dateTo),
        lang: "zh-HK",
      });
      fetch(
        "https://gmdux1cuu9.execute-api.ap-east-1.amazonaws.com/default/daashk-stock-function-OFIqWRaOuhig",
        {
          method: "POST",
          body: tempBody,
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (json) {
          setStockStat({
            ...stockStat,
            loaded: true,
            data: json.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          setStockStat({
            ...stockStat,
            loaded: true,
            data: null,
          });
        });
    }
  }, [stockStat, stockCode, dateFrom, dateTo]);

  console.log(stockCode);

  let data = [];
  if (stockStat.data) {
    let dates = stockStat.data["date"].map((item) => new Date(item).getTime());
    data.push({
      yAxis: 0,
      type: "ohlc",
      name:
        ("0000" + stockCode.stock_code).slice(-4) + " " + stockCode.short_name,
      useOhlcData: true,
      data: zip([
        dates,
        stockStat.data["open"],
        stockStat.data["high"],
        stockStat.data["low"],
        stockStat.data["close"],
      ]),
    });
    data.push({
      yAxis: 0,
      name: "60天平均線",
      data: zip([dates, stockStat.data["sma_60_day"]]),
    });
    data.push({
      yAxis: 0,
      name: "120天平均線",
      data: zip([dates, stockStat.data["sma_120_day"]]),
    });
    data.push({
      yAxis: 0,
      name: "250天平均線",
      data: zip([dates, stockStat.data["sma_250_day"]]),
    });
    data.push({
      yAxis: 1,
      name: "RS",
      data: zip([dates, stockStat.data["rs"]]),
    });
  }

  const options = {
    legend: {
      enabled: true,
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
    },

    xAxis: {
      type: "datetime",
    },

    yAxis: [
      {
        labels: {
          format: "$ {value}",
          align: "right",
        },
        title: {
          text: "股價",
          align: "high",
          offset: 0,
          rotation: 0,
        },
        opposite: true,
        startOnTick: false,
        endOnTick: false,
      },
      {
        labels: {
          align: "left",
        },
        title: {
          text: "RS Return",
          align: "high",
          offset: 0,
          rotation: 0,
        },
        opposite: false,
        gridLineWidth: 0,
      },
    ],

    rangeSelector: {
      enabled: false,
    },

    navigator: {
      enabled: false,
    },

    scrollbar: {
      enabled: false,
    },

    series: data,

    tooltip: {
      split: true,
    },

    credits: {
      text: "DaaSHK",
      style: {
        fontSize: "1em",
      },
    },
  };

  return (
    <>
      <Grid container className={classes.input_grid} spacing={2}>
        <Grid item>
          <SemanticDatepicker
            filterDate={datePickerFilterDateFrom}
            onChange={handleDateFrom}
            clearable={false}
            showToday={false}
            datePickerOnly={true}
            value={dateFrom}
            clearOnSameDateClick={false}
            disabled={!dateList.loaded}
          />
        </Grid>
        <Grid item className={classes.item}>
          至
        </Grid>
        <Grid item>
          <SemanticDatepicker
            filterDate={datePickerFilterDateTo}
            onChange={handleDateTo}
            clearable={false}
            showToday={false}
            datePickerOnly={true}
            value={dateTo}
            clearOnSameDateClick={false}
            disabled={!dateList.loaded}
          />
        </Grid>
        <Grid item>
          {stockList.data && (
            <Autocomplete
              value={stockCode}
              onChange={handleStockCode}
              id="combo-box-demo"
              options={stockList.data}
              getOptionLabel={(option) =>
                ("0000" + option.stock_code).slice(-4) + " " + option.short_name
              }
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="stock" variant="outlined" />
              )}
            />
          )}
        </Grid>
      </Grid>
      {stockStat.data ? (
        <HighchartsReact
          highcharts={Highcharts}
          constructorType={"stockChart"}
          options={options}
        />
      ) : !stockStat.loaded ? (
        <Loader active inline="centered" />
      ) : (
        <div>沒有資料</div>
      )}
    </>
  );
}
