import React, { useEffect } from 'react';
import './StockList.css';
import { Link } from 'react-router-dom';
import SortableTable from "../../tables/SortableTable";
import { Loader } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux'
import { fetchStockListData } from '../../../features/stock/stockListSlice'

export default function StockList() {
  const dispatch = useDispatch();

  const stockListData = useSelector(state => state.stockList);
  const stockListStatus = useSelector(state => state.stockList.status);

  useEffect(() => {
    if (stockListStatus === 'idle') {
      dispatch(fetchStockListData());
    } else if (stockListStatus === 'succeeded') {
    }
  }, [stockListStatus, dispatch]);

  const config = {
    col: [
      {content: '編號', align: 'center', width: 4, prefix: '', postfix: '', },
      {content: '名稱', align: 'center', width: 4, prefix: '', postfix: '', },
      {content: '', align: 'center', width: 4, prefix: '', postfix: '', },
      {content: '', align: 'center', width: 4, prefix: '', postfix: '', },
    ],
    advRowCount: 16,
  };

  const sort = {
    col: [
      {type: 'string', sort: true},
      {type: 'string', sort: true},
      {type: '', sort: false},
      {type: '', sort: false},
    ],
    colIndex: 0,
    asc: true,
  };

  const filter = {
    col: [
      {text: '過瀘編號', type: 'match'},
      {text: '過瀘名稱', type: 'match'},
      {text: '', type: ''},
      {text: '', type: ''},
    ],
  };

  const getData = () => {
    let data = [];
    if (stockListStatus === 'succeeded')　{
      if (stockListData.stockList) {
        for (let i in stockListData.stockList) {
          let stock_code = ("000" + stockListData.stockList[i].stock_code).slice (-4);
          const part_link = "/shareholding/shareholding_by_stock/" + stock_code;
          const part_chg_link = "/shareholding/shareholding_change_by_stock/" + stock_code;
          data.push([
            stock_code,
            stockListData.stockList[i].name,
            <Link to={part_link}>持股</Link>,
            <Link to={part_chg_link}>持股變動</Link>,
          ]);
        }
      }
    }
    return {data: data};
  }

  let data = getData();
  
  return (
    <>
      { !(stockListStatus === 'succeeded') && (<Loader active inline='centered'>Loading</Loader>) }
      { stockListStatus === 'succeeded' && (<SortableTable maxRowPerPage={32} config={config} data={data} sort={sort} filter={filter}></SortableTable>) } 
    </>
  );
}