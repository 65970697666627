import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { collection, doc, getDoc } from "firebase/firestore";
import { db, analytics } from "../../config/fbConfig";
import { logEvent } from "firebase/analytics";
import { useSelector } from "react-redux";
import { Satellite } from "@material-ui/icons";

const initialState = {
  rs: [],
  tableSetting: {
    direction: "descending",
    column: "RS",
    activePage: "1",
    totalPage: "4",
    displayMode: "0",
    nChartsToShow: 0,
  },
  status: "idle",
  error: null,
};

export const addNewRS = createAsyncThunk("rs/addNewRs", async (id) => {
  let rsRef = collection(db, "equity_stat_from_aws");
  logEvent(analytics, "select_content", {
    content_type: "colRef" + id,
  });
  const snapshot = await getDoc(doc(rsRef, id));
  // console.log(snapshot.data());
  return snapshot.data();
});

export const fetchRS = createAsyncThunk("rs/fetchRs", async (id) => {
  // console.log("fetchRS");
  let rsRef = collection(db, "equity_stat_from_aws");
  logEvent(analytics, "select_content", {
    content_type: "colRef" + id,
  });
  const snapshot = await getDoc(doc(rsRef, id));
  // console.log(snapshot.data());
  return snapshot.data();
});

export const fetchBatchRS = createAsyncThunk("rs/fetchBatchRs", async (ids) => {
  let res = [];
  // console.log(ids);
  // console.log("fetchBaaa");
  for (let index = 0; index < ids.length; index++) {
    const id = ids[index];
    let rsRef = collection(db, "equity_stat_from_aws");
    logEvent(analytics, "select_content", {
      content_type: "colRef" + id,
    });
    const snapshot = await getDoc(doc(rsRef, id));
    res.push(snapshot.data());
  }
  // console.log("this is res");
  // console.log(res);
  return res;
});

const rsSlice = createSlice({
  name: "rs",
  initialState,
  reducers: {
    toIdle(state, action) {
      state.status = "idle";
    },
    setDisplayMode(state, action) {
      state.tableSetting = {
        ...state.tableSetting,
        displayMode: action.payload,
        nChartsToShow: 0,
      };
    },
    setActivePage(state, action) {
      state.tableSetting = {
        ...state.tableSetting,
        activePage: action.payload,
      };
    },
    setNChartsToShow(state, action) {
      state.tableSetting = {
        ...state.tableSetting,
        nChartsToShow: action.payload,
      };
    },
    showMoreCharts(state, action) {
      state.tableSetting = {
        ...state.tableSetting,
        nChartsToShow: action.payload + 10,
      };
    },
    changeSort(state, action) {
      if (state.tableSetting.column === action.payload)
        state.tableSetting = {
          ...state.tableSetting,
          direction:
            state.tableSetting.direction === "ascending"
              ? "descending"
              : "ascending",
          activePage: 1,
        };
      else
        state.tableSetting = {
          ...state.tableSetting,
          column: action.payload,
          direction: "ascending",
          activepage: 1,
        };
    },
  },
  extraReducers: {
    [fetchRS.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchRS.fulfilled]: (state, action) => {
      state.status = "succeeded";
      // console.log('hihihih')
      // console.log(state.rs);
      // console.log(action)
      // if (action.payload){
      state.rs.push(action.payload);
      // }
    },
    [fetchRS.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [fetchBatchRS.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchBatchRS.fulfilled]: (state, action) => {
      state.status = "succeeded";
      for (let index = 0; index < action.payload.length; index++) {
        const res = action.payload[index];
        state.rs.push(res);
      }
    },
    [addNewRS.fulfilled]: (state, action) => {
      // console.log(state)
      state.rs.push(action.payload);
    },
  },
});

export const {
  toIdle,
  setDisplayMode,
  setActivePage,
  changeSort,
  showMoreCharts,
} = rsSlice.actions;
export default rsSlice.reducer;

export const selectRSById = (state, stockId) =>
  state.rs.rs.find((item) => item.topicInfo.stockCode === stockId);

// export const selectStockById2 = (state, stockId) =>
//   state.rs.stock.find((item) => item.id === stockId);

export const selectAllRS = (state) => state.rs.rs;
