import React from "react";
import {
  Container,
  Header,
  Icon,
} from "semantic-ui-react";
import MetaTags from "react-meta-tags";
// import DisplayAdsLargeBanner from '../ads/DisplayAdsLargeBanner'
import DisplayAdsBanner from "../ads/DisplayAdsBanner";
import { SupportUs } from "../widget/SupportUsWidget"

function ContactUs() {
  const emailString = (
    <a href="mailto:contact@daashk.com"> contact@DaaSHK.com</a>
  );
  return (
    <div
      style={{
        margin: "auto",
        alignItems: "center",
        minHeight: "75vh",
        // overflow: "auto",
      }}
    >
      <MetaTags>
        <title>聯絡我們</title>
      </MetaTags>
      <Container textAlign="center">
        <DisplayAdsBanner />
      </Container>
      {/* <Segment style={{ padding: '2em 0em' }} vertical> */}
      <Container style={{ padding: "2em 0em", paddingBottom: "5px" }}>
        <Container text>
          <Header as="h2">聯絡我們</Header>
          <p>如有任何意見或建議，你可以透過以下途徑與我們聯絡:</p>

          <p>電郵(Email) : {emailString}</p>

          {/* <p>
            instagram :
            <a target="_blank" rel="noopener noreferrer" href=''>
              <Icon name='instagram' size='large' />
            </a>
          </p> */}

          <p>
            facebook :
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/idaashk"
            >
              <Icon name="facebook official" size="large" />
            </a>
          </p>

          <SupportUs />

          {/* <a target="_blank" rel="noopener noreferrer" href='https://ko-fi.com/daashk'>
            <Card centered>
              <Card.Content>
                <Card.Description>
                  <div style={{textAlign:'center'}}>
                  <span style={{textDecoration:"underline", fontWeight:'bold' }} > 與此同時，你可以一杯咖啡的價錢，支持我們製作更多免費的內容！ <Emoji symbol="☕" label="coffee"/>
                  </span>
                  </div>
                </Card.Description>
              </Card.Content>
            </Card>
          </a> */}
        </Container>
      </Container>

      <Container textAlign="center" style={{ paddingTop: "25px" }}>
        <DisplayAdsBanner />
      </Container>
      {/* </Segment> */}
    </div>
  );
}


export default ContactUs;
