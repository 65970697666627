import React from "react";
import RSTable from "./RSTable";
import RSTableFilter from "./RSTableFilter";
import { connect } from "react-redux";
import _ from "lodash";
import { Container } from "semantic-ui-react";
// import { isMobile } from "react-device-detect";

function RSTableContainer(props) {
  const { data, stockDict, stockSector, wishList, allowedBehindPaywall } =
    props;
  const sector_options = [{ key: "所有", text: "所有", value: "所有" }];
  let j = 0;
  var sDictArr = _.values(stockSector.sDict);
  for (j; j < sDictArr.length; j += 1) {
    sector_options.push({
      key: sDictArr[j],
      text: sDictArr[j],
      value: sDictArr[j],
    });
  }
  return (
    <Container style={{ maxWidth: "1000px", width: "95%" }}>
      <RSTableFilter
        initialValues={{
          isPlgma50: false,
          isPlgma200: false,
          isPlgwma30: false,
          isMa50lgma150: false,
          isMa50lgma200: false,
          isMa150lgma200: false,
          ipoType: 0,
          catType: 0,
        }}
        sector_options={sector_options}
      />
      <RSTable
        data={data}
        stockSector={stockSector}
        stockDict={stockDict}
        wishList={wishList}
        allowedBehindPaywall={allowedBehindPaywall}
        filters={props.values}
      />
    </Container>
  );
}

const mapStateToProps = (state) => {
  return state.form.rstableT
    ? {
        values: state.form.rstableT.values,
        submitSucceeded: state.form.rstableT.submitSucceeded,
      }
    : {};
};

export default connect(mapStateToProps)(RSTableContainer);
