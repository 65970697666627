import { makeStyles, Button } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { db } from '../../../../config/fbConfig';
import { collection, getDocs, query, orderBy, limit, startAfter, where } from "firebase/firestore";

import { Post } from "./Post";
import { NewPost } from "./NewPost";
import { useUserCanPostToBlog } from 'features/user/userSlice';

const useStyles = makeStyles(theme => ({
  general_button_container: {
    textAlign: 'left',
  },
  general_button: {
    width: '200px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export const AllPosts = (props) => {
  const setImageModalContent = props.setImageModalContent;
  const classes = useStyles();
  const userCanPostToBlog = useUserCanPostToBlog();
  const [ displayPosts, setDisplayPosts ] = useState([]);
  const [ lastVisible, setLastVisible ] = useState(null);
  const loadCount = 3;

  useEffect(() => {
    try {
      const q = query(collection(db, "posts"), where("valid", "==", true), orderBy("date", "desc"), limit(loadCount + 1));
      getDocs(q).then((querySnapshot) => {
        const data = [];
        querySnapshot.forEach((doc) => {
          let item = doc.data();
          item = {
            ...item,
            postID: doc.id,
          }
          data.push(item);
        });
        if (data.length < loadCount + 1) {
          setLastVisible(null);
          setDisplayPosts(data);
        } else {
          setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 2]);
          setDisplayPosts(data.slice(0, loadCount));
        }
      });
    } catch (error) {
      console.log("getPostCount,Error");
      console.log(error);
    }
  }, []);

  const loadMore = () => {
    try {
      const q = query(collection(db, "posts"), where("valid", "==", true), orderBy("date", "desc"), startAfter(lastVisible), limit(loadCount + 1));
      getDocs(q).then((querySnapshot) => {
        const data = [];
        querySnapshot.forEach((doc) => {
          let item = doc.data();
          item = {
            ...item,
            postID: doc.id,
          }
          data.push(item);
        });
        if (data.length < loadCount + 1) {
          setLastVisible(null);
          setDisplayPosts([...displayPosts, ...data]);
        } else {
          setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 2]);
          setDisplayPosts([...displayPosts, ...data.slice(0, loadCount)]);
        }
      });
    } catch (error) {
      console.log("getPostCount,Error");
      console.log(error);
    }
  };
  
  const reload = () => {
    if (displayPosts.length > 0) {
      try {
        const q = query(collection(db, "posts"), where("valid", "==", true), orderBy("date", "desc"), limit(displayPosts.length + 1));
        getDocs(q).then((querySnapshot) => {
          const data = [];
          querySnapshot.forEach((doc) => {
            let item = doc.data();
            item = {
              ...item,
              postID: doc.id,
            }
            data.push(item);
          });
          if (data.length < displayPosts.length + 1) {
            setLastVisible(null);
            setDisplayPosts(data);
          } else {
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 2]);
            setDisplayPosts(data.slice(0, displayPosts.length));
          }
        });
      } catch (error) {
        console.log("getPostCount,Error");
        console.log(error);
      }
    }
  };

  return (
    <>
      { userCanPostToBlog && <NewPost reload={reload}/> }
      { displayPosts.map((post, index) => {
        return (
          <Post key={index} content={post} reload={reload} setImageModalContent={setImageModalContent}/>
        )
      })}
      { lastVisible && <div className={classes.general_button_container}>
          <Button className={classes.general_button}
            onClick={() => loadMore()}
            variant="contained"
            color="primary">更加</Button>
        </div>
      }
    </>
  ) 
}
