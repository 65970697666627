import React from 'react';
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import _ from 'lodash';


function ShareBarChart(shareholdingData) {
  var Pos_Agent = shareholdingData.shareholdingData.d1_in.agent;
  var Pos_Share = shareholdingData.shareholdingData.d1_in.share;
  var Neg_Agent = shareholdingData.shareholdingData.d1_out.agent;
  var Neg_Share = shareholdingData.shareholdingData.d1_out.share;
  var yMax = _.max([_.max(Pos_Share), Math.abs(_.min(Neg_Share))]);

  const options = {

    chart: {
      height: 600,
      type: 'bar',
      // plotBackgroundImage: `${process.env.PUBLIC_URL}/images/watermark.svg`
    },
    title: {
      text: '經紀商持股改變',
      style: {
        display: 'none'
      }
    },
    xAxis: [{
      categories: Neg_Agent,
      reversed: true,
      labels: {
        formatter: function () {
          var text = this.value,
            formatted = text.length > 15 ? text.substring(0, 15) + '...' : text;
          return '<div class="js-ellipse" style="width:150px; overflow:hidden" title="' + text + '">' + formatted + '</div>';
        },
        align: 'Left',
        y: -15,
        x: 30,
      },
      accessibility: {
        description: '淨賣出'
      },
      min: 0,
      max: 10,
      visible: true,
      lineWidth: 0
    }, { // mirror axis on right side
      opposite: true,
      // reversed: true,
      categories: Pos_Agent,
      linkedTo: 0,
      labels: {
        formatter: function () {
          var text = this.value,
            formatted = text.length > 15 ? text.substring(0, 15) + '...' : text;

          return '<div class="js-ellipse" style="text-align:right;" title="' + text + '">' + formatted + '</div>';
        },
        align: 'right',
        y: -15,
        x: -50,

      },
      accessibility: {
        description: '淨買入'
      },
      min: 0,
      max: 10,
      visible: true,
      lineWidth: 0
    }],
    yAxis: [{
      width: '48%',
      // left:'5%',
      max: 0,
      min: yMax * -1,
      title: {
        text: '萬股',
        align: 'low'
      },
      gridLineWidth: 0,
      lineWidth: 1,
    }, {
      width: '48%',
      left: '51%',
      max: yMax,
      min: 0,
      title: {
        text: '萬股',
        align: 'high'
      },
      offset: -0.35,
      gridLineWidth: 0,
      lineWidth: 1,
    }],

    plotOptions: {
      series: {
        stacking: 'normal',
        dataLabels: {
          allowOverlap: false,
          enabled: true,
          inside: false,
          useHTML: true,
          align: 'right',
          format: '{point.y}',
          color: 'black'
        },
        groupPadding: 0.2,
      },
    },

    tooltip: {
      formatter: function () {
        return '<b>' + this.series.chart.xAxis[this.series.index].categories[this.point.index] + '</b><br/>' +
          this.series.name + ' : ' + Highcharts.numberFormat(Math.abs(this.point.y), 1);
      },
    },


    series: [
      {
        name: '淨賣出',
        data: Neg_Share,
        // color: '#8b460e',
        color: '#d91e19',
      }, {
        name: '淨買入',
        data: Pos_Share,
        // color: '#1c6a64',
        color: '#0072ce',
        yAxis: 1,
      },
    ],
    credits: {
      // enabled: false
      text: 'DaaSHK',
      // href: 'https://iccasshk.com/',
      style: {
        fontSize: '1em'
      },
    }

  };


  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  )
}

export default ShareBarChart
