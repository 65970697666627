
import React from "react";
import { Card, Container, Image } from "semantic-ui-react";

const Emoji = (props) => (
  <span
    className="emoji"
    role="img"
    aria-label={props.label ? props.label : ""}
    aria-hidden={props.label ? "false" : "true"}
  >
    {props.symbol}
  </span>
);

export const SupportUsDescription = () => (
  <div style={{ textAlign: "center" }}>
    <span
      style={{ textDecoration: "underline", fontWeight: "bold" }}
    >
      {" "}
      你可以一杯咖啡的價錢，支持我們製作更多免費的內容！{" "}
      <Emoji symbol="☕" label="coffee" />
    </span>
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://qr.payme.hsbc.com.hk/2/YXXqaVGi5Dy97TA77sp9kN"
    >
      <div>
        <Image src={"/images/payme.jpg"} size="huge" />
      </div>
      <div style={{ textAlign: "center" }}>
        {" "}
        Click to donate on phone
      </div>
    </a>
  </div>
)

export const SupportUs = () => {
  return (
      <Container text>
        <Card centered style={{ padding: "0em" }}>
          <Card.Content>
            <Card.Description>
              <SupportUsDescription/>
            </Card.Description>
          </Card.Content>
        </Card>
      </Container>
  );
};
