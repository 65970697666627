function OpenDataCredit() {
  return (
    <>
      <div><b>資料來源：資料一線通</b><a target="_blank" rel="noreferrer" href="https://data.gov.hk">DATA.GOV.HK</a></div>
      <div><b>原始數據的知識產權擁有人</b></div>
    </>
  )
}

function CTBCredit() {
  return (
    <div>城巴有限公司</div>
  )
}

function GMBCredit() {
  return (
    <div>運輸署與專線小巴營辦商</div>
  )
}

function KMBCredit() {
  return (
    <div>九龍巴士(一九三三)有限公司 (簡稱「九巴」) 及 龍運巴士有限公司 (簡稱「龍運」)</div>
  )
}

function MTRCredit() {
  return (
    <div>香港鐵路有限公司</div>
  )
}

function NWFBCredit() {
  return (
    <div>新巴有限公司</div>
  )
}

function NLBCredit() {
  return (
    <div>新大嶼山巴士（1973）有限公司</div>
  )
}

export default function Credit(props) {
  const routeStops = props.routeStops;
  const state = props.state;
  
  let hasKMB = false;
  let hasNWFB = false;
  let hasCTB = false;
  let hasNLB = false;
  let hasGMB = false;
  let hasMTR = false;

  if (state.transport_name === "KMB") {
    hasKMB = true;
  } else if (state.transport_name === "MTR") {
    hasMTR = true;
  } else if (state.transport_name === "LR") {
    hasMTR = true;
  } else if (state.transport_name === "NWFB") {
    hasNWFB = true;
  } else if (state.transport_name === "CTB") {
    hasCTB = true;
  } else if (state.transport_name === "GMB") {
    hasGMB = true;
  } else if (state.transport_name === "MTRB") {
    hasMTR = true;
  } else if (state.transport_name === "NLB") {
    hasNLB = true;
  }

  if (routeStops) {
    for (let item in routeStops) {
      if (routeStops[item].transport_name && routeStops[item].transport_name === "KMB") {
        hasKMB = true;
      } else if (routeStops[item].transport_name && routeStops[item].transport_name === "MTR") {
        hasMTR = true;
      } else if (routeStops[item].transport_name && routeStops[item].transport_name === "LR") {
        hasMTR = true;
      } else if (routeStops[item].transport_name && routeStops[item].transport_name === "NWFB") {
        hasNWFB = true;
      } else if (routeStops[item].transport_name && routeStops[item].transport_name === "CTB") {
        hasCTB = true;
      } else if (routeStops[item].transport_name && routeStops[item].transport_name === "GMB") {
        hasGMB = true;
      } else if (routeStops[item].transport_name && routeStops[item].transport_name === "MTRB") {
        hasMTR = true;
      } else if (routeStops[item].transport_name && routeStops[item].transport_name === "NLB") {
        hasNLB = true;
      }
    }
  }

  return (
    <>
      <OpenDataCredit></OpenDataCredit>
      { hasKMB && <KMBCredit></KMBCredit> }
      { hasNWFB && <NWFBCredit></NWFBCredit> }
      { hasCTB && <CTBCredit></CTBCredit> }
      { hasNLB && <NLBCredit></NLBCredit> }
      { hasGMB && <GMBCredit></GMBCredit> }
      { hasMTR && <MTRCredit></MTRCredit> }
    </>
  )
}