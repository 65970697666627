import { db } from "config/fbConfig";
import {
  collection,
  doc,
  addDoc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { functions } from "config/fbConfig";
import { httpsCallable } from "firebase/functions";

// Hard code for now, perhaps consider moving to database in the future.
const ProfessionalPlan = {
  price: process.env.REACT_APP_STRIPE_PROFESSIONALPLAN_PRICEID,
  quantity: 1,
};

export async function subscribe(uid, item) {
  const checkoutSession = {
    automatic_tax: false,
    tax_id_collection: false,
    collect_shipping_address: false,
    allow_promotion_codes: true,
    line_items: [item],
    success_url: `${window.location.origin}/myaccount`,
    cancel_url: `${window.location.origin}/myaccount`,
    // metadata: {
    //   key: 'value',
    // },
  };

  const customerRef = doc(db, "customers", uid);
  const docRef = await addDoc(
    collection(customerRef, "checkout_sessions"),
    checkoutSession
  );

  // Wait for the CheckoutSession to get attached by the extension
  onSnapshot(docRef, (snap) => {
    const { error, url } = snap.data();
    if (error) {
      // Show an error to your customer and then inspect your function logs.
      alert(`An error occured: ${error.message}`);
    }
    if (url) {
      // TODO: refactor this to return url instead of navigating to url
      window.location.assign(url);
    }
  });
}

export async function subscribeProfessionalPlan(uid) {
  return subscribe(uid, ProfessionalPlan);
}

export function getSubscriptionStatus(uid, callback) {
  const customersRef = doc(db, "customers", uid);
  const subRef = collection(customersRef, "subscriptions");
  const q = query(subRef, where("status", "in", ["trialing", "active"]));
  onSnapshot(q, (snapshot) => {
    if (snapshot.empty) {
      callback([]);
    } else {
      const subscriptions = snapshot.docs.map((x) => x.data());
      // console.log(subscriptions)
      callback(subscriptions);
    }
  });
}

export const getPortalLink = async () => {
  const functionRef = httpsCallable(
    functions,
    "ext-firestore-stripe-payments-createPortalLink"
  );
  const { data } = await functionRef({
    returnUrl: `${window.location.origin}/myaccount`,
  });
  return data;
};
