import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore"
import { getAnalytics } from "firebase/analytics";
import { getFunctions } from 'firebase/functions';
import { getAuth } from 'firebase/auth';
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey:             process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain:         process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL:        process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId:          process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket:      process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId:  process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId:              process.env.REACT_APP_FIREBASE_APPID,
  measurementId:      process.env.REACT_APP_FIREBASE_MEASUREMENTID
};

// Initialize Firebase
const functionLocation = "asia-east2"
export const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp)
export const db = getFirestore(firebaseApp);
export const functions = getFunctions(firebaseApp, functionLocation);
export const analytics = getAnalytics(firebaseApp);
export const storage = getStorage(firebaseApp);
