import React from 'react';
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";


function ShareBarChart(shareholdingData) {
  var agent = shareholdingData.shareholdingData.agent;
  var pct = shareholdingData.shareholdingData.pct;

  const options = {
    chart: {
      type: 'bar',
      height: 500,
      // plotBackgroundImage: `${process.env.PUBLIC_URL}/images/watermark.svg`
    },
    title: {
      text: '經紀商持股比例',
      style: {
        display: 'none'
      }
    },
    xAxis: {
      categories: agent,
      title: {
        text: null
      },
      labels: {
        formatter: function () {
          var text = this.value,
            formatted = text.length > 15 ? text.substring(0, 15) + '...' : text;

          return '<div class="js-ellipse" style="width:150px; overflow:hidden" title="' + text + '">' + formatted + '</div>';
        },
        align: 'Left',
        y: -15,
        x: 5,
      },
      min: 0,
      max: 9,
      lineWidth: 0
    },
    yAxis: {
      min: 0,
      title: {
        text: '%',
        align: 'high'
      },
      labels: {
        overflow: 'justify'
      },
      gridLineWidth: 0,
      lineWidth: 1,
    },

    tooltip: {
      formatter: function () {
        return '<b>' + this.point.category + '</b><br/>' +
          Highcharts.numberFormat(Math.abs(this.point.y), 1) + '%';
      },

    },

    scrollbar: {
      enabled: true
    },

    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
          format: '{y} %'
        },
        groupPadding: 0.3,
      },

    },
    legend: {
      enabled: false
    },
    
    series: [{
      data: pct,
      color: '#0072ce',
    }],

    credits: {
      text: 'DaaSHK',
      style: {
        fontSize: '1em'
      },
    }

  };


  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  )
}

export default ShareBarChart