import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { analytics, functions} from '../../config/fbConfig';
import { logEvent } from "firebase/analytics";
import { httpsCallable } from "firebase/functions";


const initialState = {
  participantList: {},
  status: 'idle',
  error: null,
}

const getParticipantList = httpsCallable(functions, 'participant_list');

export const fetchParticipantListData = createAsyncThunk('index/fetchParticipantList', async () => {
  logEvent(analytics, "select_content", {
    content_type: "participant_list",
  });
  const result = await getParticipantList({
    language_code: "zh-hk",
  });
  return (result.data.data)
});

const participantListSlice = createSlice({
  name: 'participantList',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchParticipantListData.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchParticipantListData.fulfilled]: (state, action) => {
      state.participantList = action.payload
      state.participantMap = {}
      for (let i in state.participantList) {
        state.participantMap[state.participantList[i].participant_code] = state.participantList[i];
      }
      state.status = 'succeeded'
    },
    [fetchParticipantListData.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    },
  },
})

export default participantListSlice.reducer
