import React from 'react'
import { Container, Header, Card, Segment } from 'semantic-ui-react'
import MetaTags from 'react-meta-tags'
// import GoogleAd from './Google_ad'
// import DisplayAdsLargeBanner from '../ads/DisplayAdsLargeBanner'
// import DisplayAdsBanner from '../ads/DisplayAdsBanner'

const Emoji = props => (
  <span
      className="emoji"
      role="img"
      aria-label={props.label ? props.label : ""}
      aria-hidden={props.label ? "false" : "true"}
  >
      {props.symbol}
  </span>
);


function About() {
  const emailString = <a href="mailto:contact@daashk.com"> contact@DaaSHK.com</a>
  return (
    <div
      style={{
        margin: "auto",
        alignItems: "center",
        height: "75vh",
        overflow: "auto",
      }}
    >
      <MetaTags>
        <title>關於本站</title>
      </MetaTags>
      <Segment style={{ padding: '2em 0em' }} vertical>
        <Container text>
        <Header as='h2' >關於本站</Header>

        {/* <p> 本站 (DaaSHK.COM)   (Data-as-a-service; 數據即服務) </p> */}
        <p>
          新版網站正在建設中，不日上架，敬請期待。
        </p>
        <p>
          如有需要請 email: {emailString} 致我們團隊，我們會有專人盡快回覆。
        </p>

        <a target="_blank" rel="noopener noreferrer" href='https://ko-fi.com/daashk'> 
          <Card centered>
            <Card.Content>
              <Card.Description>
                <div style={{textAlign:'center'}}>
                <span style={{textDecoration:"underline", fontWeight:'bold' }} >  與此同時，你可以一杯咖啡的價錢，支持我們製作更多免費的內容！ <Emoji symbol="☕" label="coffee"/> 
                </span>
                </div>
              </Card.Description>
            </Card.Content>
          </Card>
        </a>
      
      </Container>
    </Segment>

    </div>
  );
}

export default About;
