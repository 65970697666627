import { useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { Loader, Container, Header, Card, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import {
  selectAllTopic,
  fetchTopic,
  toIdle,
} from "../../../features/topic/topicSlice";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

function zip(arrays) {
  return Array.apply(null, Array(arrays[0].length)).map(function (_, i) {
    return arrays.map(function (array) {
      return array[i];
    });
  });
}

function roundData(d) {
  return Math.round(d * 100) / 100;
}

function roundData2(d) {
  return Math.round(d * 10) / 10;
}

export default function HSIChart() {
  const dispatch = useDispatch();
  const topics = useSelector(selectAllTopic);
  const topicStatus = useSelector((state) => state.topic.status);
  const error = useSelector((state) => state.topic.error);
  const [stockStat, setStockStat] = useState({
    data: null,
    loaded: false,
  });
  // console.log(stockStat);
  useEffect(() => {
    if (topicStatus === "idle" && !stockStat.loaded) {
      dispatch(fetchTopic("breadth_from_aws"));
    } else if (topicStatus === "loading") {
    } else if (topicStatus === "succeeded") {
      const data = topics.find(
        (item) => item.topicInfo.name === "breadth_from_aws"
      );
      if (data) {
        setStockStat({
          ...stockStat,
          data: data.data,
          loaded: true,
        });
      } else {
        dispatch(toIdle({}));
      }
    } else if (topicStatus === "error") {
    }
  }, [topicStatus, dispatch]);

  // console.log(stockStat);

  // const hsiColor = "#999999";
  // const breadthColor = "#999999";
  var windowWidth = window.innerWidth;
  const chartHeight = 550;
  // 0.656 * windowWidth < 550
  //   ? 550
  //   : 0.656 * windowWidth > 633
  //   ? 633
  //   : 0.656 * windowWidth;
  let hsiData = [];
  let marketData = [];
  let nh_nl = [];
  let nh_nl_MA10 = [];
  let chartOption = {};
  // console.log(stockStat);

  if (stockStat.loaded && stockStat.data.date.length > 0) {
    let dates = stockStat.data["date"].map((item) => new Date(item).getTime());
    hsiData.push({
      yAxis: 0,
      type: "ohlc",
      name: "HSI",
      useOhlcData: true,
      data: zip([
        dates,
        stockStat.data["open"],
        stockStat.data["high"],
        stockStat.data["low"],
        stockStat.data["close"],
      ]),
    });
    marketData.push({
      yAxis: 0,
      type: "ohlc",
      name: "HSI",
      useOhlcData: true,
      data: zip([
        dates,
        stockStat.data["open"],
        stockStat.data["high"],
        stockStat.data["low"],
        stockStat.data["close"],
      ]),
    });
    hsiData.push({
      yAxis: 1,
      name: "HSI 50",
      data: zip([
        dates,
        _.map(stockStat.data["hsi_breadth_50_day"], roundData2),
      ]),
    });
    hsiData.push({
      yAxis: 1,
      name: "HSI 100",
      data: zip([
        dates,
        _.map(stockStat.data["hsi_breadth_100_day"], roundData2),
      ]),
    });
    hsiData.push({
      yAxis: 1,
      name: "HSI 150",
      data: zip([
        dates,
        _.map(stockStat.data["hsi_breadth_150_day"], roundData2),
      ]),
    });
    hsiData.push({
      yAxis: 1,
      name: "HSI 200",
      data: zip([
        dates,
        _.map(stockStat.data["hsi_breadth_200_day"], roundData2),
      ]),
    });
    marketData.push({
      yAxis: 1,
      name: "Market 50",
      data: zip([
        dates,
        _.map(stockStat.data["mkt_breadth_50_day"], roundData2),
      ]),
    });
    marketData.push({
      yAxis: 1,
      name: "Market 100",
      data: zip([
        dates,
        _.map(stockStat.data["mkt_breadth_100_day"], roundData2),
      ]),
    });
    marketData.push({
      yAxis: 1,
      name: "Market 150",
      data: zip([
        dates,
        _.map(stockStat.data["mkt_breadth_150_day"], roundData2),
      ]),
    });
    marketData.push({
      yAxis: 1,
      name: "Market 200",
      data: zip([
        dates,
        _.map(stockStat.data["mkt_breadth_200_day"], roundData2),
      ]),
    });
    nh_nl.push({
      yAxis: 3,
      name: "HSI-nh-nl",
      data: zip([dates, _.map(stockStat.data["hsi_nh_nl"], roundData2)]),
    });
    nh_nl_MA10.push({
      yAxis: 3,
      name: "HSI-nh-nl-MA10",
      data: zip([dates, _.map(stockStat.data["hsi_nh_nl_10_day"], roundData2)]),
    });
    chartOption = {
      rangeSelector: {
        selected: 1,
      },

      navigator: {
        series: {
          lineWidth: 1,
          lineColor: "#2F4F4F",
          color: "#2F4F4F",
          fillOpacity: 0.2,
        },
      },

      legend: {
        enabled: true,
        align: "center",
        verticalAlign: "bottom",
        x: 0,
        y: 0,
      },

      chart: {
        height: chartHeight,
        // marginTop: 10,
        // marginBottom: 0,
        marginLeft: 10,
        marginRight: 10,
      },

      xAxis: {
        type: "datetime",
      },

      yAxis: [
        {
          endOnTick: false,
          startOnTick: false,
          labels: {
            style: {
              color: "black",
            },
            align: "right",
            x: -2,
            formatter: function () {
              return this.value;
            },
          },
          title: {
            style: {
              color: Highcharts.getOptions().colors[1],
            },
          },
          // opposite: true,
          // height: "45%",
          resize: {
            enabled: true,
          },
        },
      ],

      plotOptions: {
        candlestick: {
          lineColor: "red",
          color: "white",
          upLineColor: "black",
          upColor: "Green",
        },
      },

      tooltip: {
        xDateFormat: "%Y/%m/%d %a",
        shared: true,
        split: false,
        enabled: true,
        distance: 50,
        valueDecimals: 1,
      },

      scrollbar: {
        enabled: true,
      },

      series: [
        {
          type: "candlestick",
          id: "HSI",
          name: "恒生指數",
          data: marketData[0].data,
          yAxis: 0,
          showInLegend: false,
        },
      ],
      credits: {
        text: "DaaSHK",
        style: {
          fontSize: "0.8em",
        },
      },
    };
  }

  return (
    <>
      {stockStat.data && stockStat.data.date.length > 0 ? (
        <>
          <Header as="h3" textAlign="center">
            恒生指數及市寛
            <Header.Subheader>
              收市價 {">"} MA 市寬 <br /> HSIHL: HSI成份股創52W(新高-新低)之數目{" "}
            </Header.Subheader>
          </Header>
          <div>
            <HighchartsReact
              constructorType={"stockChart"}
              highcharts={Highcharts}
              options={chartOption}
            />
            <AskForSubscribe />
          </div>
        </>
      ) : !stockStat.loaded ? (
        <Loader active inline="centered" />
      ) : (
        <div>沒有資料</div>
      )}
    </>
  );
}

export function USChart() {
  const dispatch = useDispatch();
  const topics = useSelector(selectAllTopic);
  const topicStatus = useSelector((state) => state.topic.status);
  const error = useSelector((state) => state.topic.error);
  const [stockStat, setStockStat] = useState({
    data: null,
    loaded: false,
  });
  // console.log(stockStat);
  useEffect(() => {
    if (topicStatus === "idle" && !stockStat.loaded) {
      dispatch(fetchTopic("us_breadth_from_aws"));
    } else if (topicStatus === "loading") {
    } else if (topicStatus === "succeeded") {
      const data = topics.find(
        (item) => item.topicInfo.name === "us_breadth_from_aws"
      );
      if (data) {
        setStockStat({
          ...stockStat,
          data: data.data,
          loaded: true,
        });
      } else {
        dispatch(toIdle({}));
      }
    } else if (topicStatus === "error") {
    }
  }, [topicStatus, dispatch]);

  var windowWidth = window.innerWidth;
  const chartHeight = 550;

  let sp500Data = [];
  let marketData = [];
  let nh_nl = [];
  let nh_nl_MA10 = [];
  let chartOption = {};
  // console.log(stockStat);
  // console.log("hi");

  if (stockStat.loaded && stockStat.data.date.length > 0) {
    let dates = stockStat.data["date"].map((item) => new Date(item).getTime());
    sp500Data.push({
      yAxis: 0,
      type: "ohlc",
      name: "S&P",
      useOhlcData: true,
      data: zip([
        dates,
        stockStat.data["sp500_open"],
        stockStat.data["sp500_high"],
        stockStat.data["sp500_low"],
        stockStat.data["sp500_close"],
      ]),
    });
    marketData.push({
      yAxis: 0,
      type: "ohlc",
      name: "S&P",
      useOhlcData: true,
      data: zip([
        dates,
        stockStat.data["sp500_open"],
        stockStat.data["sp500_high"],
        stockStat.data["sp500_low"],
        stockStat.data["sp500_close"],
      ]),
    });
    sp500Data.push({
      yAxis: 1,
      name: "S&P 50",
      data: zip([
        dates,
        _.map(stockStat.data["sp500_breadth_50_day"], roundData2),
      ]),
    });
    sp500Data.push({
      yAxis: 1,
      name: "S&P 100",
      data: zip([
        dates,
        _.map(stockStat.data["sp500_breadth_100_day"], roundData2),
      ]),
    });
    sp500Data.push({
      yAxis: 1,
      name: "S&P 150",
      data: zip([
        dates,
        _.map(stockStat.data["sp500_breadth_150_day"], roundData2),
      ]),
    });
    sp500Data.push({
      yAxis: 1,
      name: "S&P 200",
      data: zip([
        dates,
        _.map(stockStat.data["sp500_breadth_200_day"], roundData2),
      ]),
    });
    marketData.push({
      yAxis: 1,
      name: "Market 50",
      data: zip([
        dates,
        _.map(stockStat.data["mkt_breadth_50_day"], roundData2),
      ]),
    });
    marketData.push({
      yAxis: 1,
      name: "Market 100",
      data: zip([
        dates,
        _.map(stockStat.data["mkt_breadth_100_day"], roundData2),
      ]),
    });
    marketData.push({
      yAxis: 1,
      name: "Market 150",
      data: zip([
        dates,
        _.map(stockStat.data["mkt_breadth_150_day"], roundData2),
      ]),
    });
    marketData.push({
      yAxis: 1,
      name: "Market 200",
      data: zip([
        dates,
        _.map(stockStat.data["mkt_breadth_200_day"], roundData2),
      ]),
    });
    nh_nl.push({
      yAxis: 3,
      name: "nh_nl",
      data: zip([dates, _.map(stockStat.data["nh_nl"], roundData2)]),
    });
    // nh_nl_MA10.push({
    //   yAxis: 3,
    //   name: "HSI-nh-nl-MA10",
    //   data: zip([dates, _.map(stockStat.data["hsi_nh_nl_10_day"], roundData2)]),
    // });
    chartOption = {
      rangeSelector: {
        selected: 1,
      },

      navigator: {
        series: {
          lineWidth: 1,
          lineColor: "#2F4F4F",
          color: "#2F4F4F",
          fillOpacity: 0.2,
        },
      },

      legend: {
        enabled: true,
        align: "center",
        verticalAlign: "bottom",
        x: 0,
        y: 0,
      },

      chart: {
        height: chartHeight,
        // marginTop: 10,
        // marginBottom: 0,
        marginLeft: 10,
        marginRight: 10,
      },

      xAxis: {
        type: "datetime",
      },

      yAxis: [
        {
          endOnTick: false,
          startOnTick: false,
          labels: {
            style: {
              color: "black",
            },
            align: "right",
            x: -2,
            formatter: function () {
              return this.value;
            },
          },
          title: {
            style: {
              color: Highcharts.getOptions().colors[1],
            },
          },
          // opposite: true,
          // height: "45%",
          resize: {
            enabled: true,
          },
        },
      ],

      plotOptions: {
        candlestick: {
          lineColor: "red",
          color: "white",
          upLineColor: "black",
          upColor: "Green",
        },
      },

      tooltip: {
        xDateFormat: "%Y/%m/%d %a",
        shared: true,
        split: false,
        enabled: true,
        distance: 50,
        valueDecimals: 1,
      },

      scrollbar: {
        enabled: true,
      },

      series: [
        {
          type: "candlestick",
          id: "Sp500",
          name: "S&P500",
          data: marketData[0].data,
          yAxis: 0,
          showInLegend: false,
        },
      ],
      credits: {
        text: "DaaSHK",
        style: {
          fontSize: "0.8em",
        },
      },
    };
  }

  return (
    <>
      {stockStat.data && stockStat.data.date.length > 0 ? (
        <>
          <Header as="h3" textAlign="center">
            S{"&"}P500 及市寛
            <Header.Subheader>
              收市價 {">"} MA 市寬 <br /> nhnl: 市場上創52W(新高-新低)之數目{" "}
            </Header.Subheader>
          </Header>
          <div>
            <HighchartsReact
              constructorType={"stockChart"}
              highcharts={Highcharts}
              options={chartOption}
            />
            <AskForSubscribe />
          </div>
        </>
      ) : !stockStat.loaded ? (
        <Loader active inline="centered" />
      ) : (
        <div>沒有資料</div>
      )}
    </>
  );
}

function AskForSubscribe() {
  return (
    <div style={{ margin: "auto" }}>
      <Card centered style={{ textAlign: "center" }}>
        <Card.Content>
          <Card.Header>加入付費版，以觀看市寬等其他進階數據</Card.Header>
        </Card.Content>
        <Card.Content extra>
          <Link to="/subscription">
            <Button basic color="blue">
              了解更多
            </Button>
          </Link>
        </Card.Content>
      </Card>
    </div>
  );
}
