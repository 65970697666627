import React from "react";
import MetaTags from 'react-meta-tags';
import ListedIPOTable from '../tables/ListedIPOTable'
import DisplayAdsBanner from '../ads/DisplayAdsBanner';
import GoogleAd from '../ads/Google_ad';
import {Container, Header} from 'semantic-ui-react'

export const TopicListedIPO = () => {
  return (
    <>
      <MetaTags>
        <title>半新股資訊</title>
        <meta name="description" content= '半新股資訊' />
        <meta property="og:title" content= '半新股資訊' />
        <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
      </MetaTags>
      <Container textAlign='center'>
        <DisplayAdsBanner />
      </Container>
      <div style={{paddingTop:'25px', textAlign: 'center'}}>
        <Header as='h1'> 半新股資訊  </Header>
      </div>

      <div>
        <div style={{paddingBottom:'25px'}}>
          <ListedIPOTable /> 
        </div>
      </div>
      <Container textAlign='center' style={{paddingTop:'30px', paddingBottom:'0px'}}>
          <GoogleAd 
              client="ca-pub-8753358837449417" 
              slot="2486257452" 
              format="auto" 
              wrapperDivStyle={{
              marginTop: '30px',
              marginBottom: '20px'
              }}
          />
      </Container>
    </>
  )
}

export default TopicListedIPO;