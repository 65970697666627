import './ShareholdingChangePerDayContainer.css';
import React, { useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { httpsCallable } from "firebase/functions";
import { Link } from "react-router-dom";
import { Container, Table, Header, Icon, Divider, Loader } from 'semantic-ui-react'
import SemanticDatepicker from 'react-semantic-ui-datepickers';

import { functions } from "../../../config/fbConfig"
import DisplayAdsBanner from '../../ads/DisplayAdsBanner';
import GoogleAd from '../../ads/Google_ad';
import SortableTable from "../../tables/SortableTable";
import { toDateString } from '../../../util/datetime'
import { formatSharePercentage, formatShare, formatStockCode } from '../../../util/dataFormatter'
import { useSelector, useDispatch } from 'react-redux'
import { fetchShareholdingChangeDateListData } from '../../../features/shareholding/shareholdingChangeDateListSlice'
import source from "../../../mock_source"

export default function ShareholdingChangePerDayContainer() {
  const dispatch = useDispatch();
  const shareholdingChangeDateListStatus = useSelector(state => state.shareholdingChangeDateList.status);
  const shareholdingChangeDateListData = useSelector(state => state.shareholdingChangeDateList);

  const [ shareholdingChange, setShareholdingChange ] = useState({list: []});
  const [ dateList, setDateList ] = useState({list: []});
  const [ resultLoaded, setResultLoaded ] = useState(false);
  const [ isMobile, setMobile ] = useState(false);
  const [ selectToDate , setSelectToDate] = useState(new Date());
  const getShareholdingChangePerDayDateList = httpsCallable(functions, 'shareholding_change_per_day_date_list');
  const getShareholdingChangePerDay = httpsCallable(functions, 'shareholding_change_per_day');

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return (() => {
        window.removeEventListener("resize", handleResize);
      }
    );
  }, []);

  useEffect(() => {
    if (shareholdingChangeDateListStatus === 'idle') {
      dispatch(fetchShareholdingChangeDateListData());
    } else if (shareholdingChangeDateListStatus === 'succeeded') {
      setDateList({list: shareholdingChangeDateListData.dateList});
    }
  }, [shareholdingChangeDateListStatus, dispatch]);

  useEffect(() => {
    if (dateList.list.length > 0) {
      setSelectToDate(new Date(dateList.list[dateList.list.length-1].date_to));
      updateShareholdingChange(dateList.list[dateList.list.length-1].date_to);
    }
  }, [dateList]);

  const handleResize = () => {
    const widths = [window.innerWidth];
    if (window.screen?.width) {
      widths.push(window.screen?.width);
    }
    const width = Math.min(...widths);
    
    var isMobile = false;
    if (width < 800) {
      isMobile = true;
    }
    setMobile(isMobile);
  }

  const updateShareholdingChange = (target_to_date) => {
    getShareholdingChangePerDay({
      date_to: toDateString(new Date(target_to_date)),
      current_time: (new Date()).getTime(),
      language_code: "zh-HK",
    })
      .then((result) => {
        console.log(result.data.current_time);
        setShareholdingChange({list: result.data.data});
        setResultLoaded(true);
      })
      .catch((error) => {
        console.log("error");
        console.log(error);
      });
  };

  const getDateList = () => {
    getShareholdingChangePerDayDateList()
      .then((result) => {
        console.log(result);
        setDateList({list: result.data.data});
      })
      .catch((error) => {
        console.log("getShareholdingChangePerDayDateList error");
        console.log(error);
      });
  };

  const datePickerFilterDate = (filtered_date) => {
    if (dateList.list.length > 0) {
      for (let i in dateList.list) {
        if (toDateString(new Date(dateList.list[i].date_to)) === toDateString(filtered_date)) {
          return true;
        }
      }
    }
    return false;
  };

  const handleToDate = (event, data) => {
    if (data.value) {
      if (toDateString(selectToDate) === toDateString(data.value)) {
        return;
      }
      if (!datePickerFilterDate(data.value)) {
        return;
      }
      setSelectToDate(data.value);
      setResultLoaded(false);
      updateShareholdingChange(data.value);
    }
  };

  const config = isMobile ? {
    col: [
      {content: '證劵', align: 'center', width: 5, prefix: '', postfix: '', },
      {content: '參與者', align: 'center', width: 6, prefix: '', postfix: '', },
      {content: '變動', align: 'right', width: 5, prefix: '', postfix: '', },
    ],
    advRowCount: 16,
  } : {
    col: [
      {content: '編號', align: 'center', width: 3, prefix: '', postfix: '', },
      {content: '證劵', align: 'center', width: 3, prefix: '', postfix: '', },
      {content: '參與者', align: 'center', width: 4, prefix: '', postfix: '', },
      {content: '持倉變動(股)', align: 'right', width: 3, prefix: '', postfix: '', },
      {content: '持倉變動(%)', align: 'right', width: 3, prefix: '', postfix: '', },
    ],
    advRowCount: 16,
  };

  const sort = isMobile ? {
    col: [
      {type: 'string', sort: true},
      {type: 'string', sort: true},
      {type: 'key_absolute_number', sort: true},
    ],
    colIndex: 2,
    asc: false,
  } : {
    col: [
      {type: 'string', sort: true},
      {type: 'string', sort: true},
      {type: 'string', sort: true},
      {type: 'key_absolute_number', sort: true},
      {type: 'key_absolute_number', sort: true},
    ],
    colIndex: 4,
    asc: false,
  };

  const filter = isMobile ? {
    col: [
      {text: '過瀘編號', type: 'match'},
      {text: '過瀘參與者', type: 'match'},
      {text: '大於', type: 'key_absolute_larger'},
    ],
  } : {
    col: [
      {text: '過瀘編號', type: 'match'},
      {text: '過瀘名稱', type: 'match'},
      {text: '過瀘參與者', type: 'match'},
      {text: '大於', type: 'key_absolute_larger'},
      {text: '大於', type: 'key_absolute_larger'},
    ],
  };

  const getData = (shareholdingChange) => {
    let data = [];
    let key = [];
    let positive = [];
    let postfix = [];
    if (dataReady) {
      for (let i in shareholdingChange.list) {
        let hasLink = false;
        for (let j = 0; j < source.length; j++) {
          let iStockID = parseInt(shareholdingChange.list[i].stock_code);
          let jStockID = parseInt(source[j].no);
          if (iStockID === jStockID) {
            hasLink = true;
            break;
          }
        }

        let stockLink = <></>;
        if (hasLink) {
          stockLink = <Link to={'/stock/' + formatStockCode(shareholdingChange.list[i].stock_code)}><Icon size='large' inverted name='chart line' color='blue' ></Icon></Link>;
        }

        let participantLink = <Link to={'/shareholding/shareholding_by_participant/' + shareholdingChange.list[i].participant_code}><Icon size='large' inverted name='chart line' color='blue' ></Icon></Link>;
        // participantLink = <></>;
        
        if (isMobile) {
          data.push([
            formatStockCode(shareholdingChange.list[i].stock_code),
            shareholdingChange.list[i].participant_name,
            formatSharePercentage(shareholdingChange.list[i].share_percentage_change),
          ]);
          key.push([
            null,
            null,
            shareholdingChange.list[i].share_percentage_change,
          ]);
          positive.push([
            shareholdingChange.list[i].share_percentage_change,
            shareholdingChange.list[i].share_percentage_change,
            shareholdingChange.list[i].share_percentage_change,
          ]);
          postfix.push([
            <> {stockLink}<br />{shareholdingChange.list[i].short_name}</>,
            <><br />{participantLink}</>,
            <><br />({formatShare(shareholdingChange.list[i].share_change)})</>,
          ]);
        } else {
          data.push([
            formatStockCode(shareholdingChange.list[i].stock_code),
            shareholdingChange.list[i].short_name,
            shareholdingChange.list[i].participant_name,
            formatShare(shareholdingChange.list[i].share_change),
            formatSharePercentage(shareholdingChange.list[i].share_percentage_change),
          ]);
          key.push([
            null,
            null,
            null,
            shareholdingChange.list[i].share_change,
            shareholdingChange.list[i].share_percentage_change,
          ]);
          positive.push([
            shareholdingChange.list[i].share_percentage_change,
            shareholdingChange.list[i].share_percentage_change,
            shareholdingChange.list[i].share_percentage_change,
            shareholdingChange.list[i].share_percentage_change,
            shareholdingChange.list[i].share_percentage_change,
          ]);
          postfix.push([
            <> {stockLink}</>,
            '',
            <> {participantLink}</>,
            '',
            '',
          ]);
        }
      }
    }
    return {
      data: data,
      key: key,
      positive: positive,
      postfix: postfix,
    };
  }

  let dataReady = false;
  if (dateList.list.length > 0) {
    dataReady = true;
  }


  let data = getData(shareholdingChange);

  let title = "每日持倉異動";

  let content = <></>;

  if (dataReady) {
    content = 
      <>
        <DisplayAdsBanner />
        <Table unstackable celled striped>
          <Table.Body>
            <Table.Row>
              <Table.Cell width={isMobile?4:3}>
                日期：
              </Table.Cell>
              <Table.Cell width={isMobile?12:13}>
                <SemanticDatepicker
                  filterDate={datePickerFilterDate}
                  onChange={handleToDate}
                  clearable={false}
                  showToday={false}
                  datePickerOnly={true}
                  value={selectToDate}
                  clearOnSameDateClick={false}
                  disabled={!resultLoaded}
                />
              </Table.Cell>
            </Table.Row>
            { resultLoaded && shareholdingChange.list.length > 0 && 
              <Table.Row>
                <Table.Cell width={isMobile?4:3}>
                  期間：
                </Table.Cell>
                <Table.Cell width={isMobile?4:3}>
                  {toDateString(new Date(shareholdingChange.list[0].date_from))}
                  　到　
                  {toDateString(new Date(shareholdingChange.list[0].date_to))}
                </Table.Cell>
              </Table.Row>
            }
            <Table.Row>
              <Table.Cell colSpan={2}>
                *只顯示大於0.25%的變動
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={isMobile?4:3}>
                總共：
              </Table.Cell>
              <Table.Cell width={isMobile?4:3}>
                { !resultLoaded && <Loader active inline></Loader> }
                { resultLoaded && data.data.length }
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <SortableTable maxRowPerPage={32} config={config} data={data} sort={sort} filter={filter}></SortableTable>
        <div style={{paddingTop:'30px', paddingBottom:'0px'}}>
            <GoogleAd 
                client="ca-pub-8753358837449417" 
                slot="2486257452" 
                format="auto" 
                wrapperDivStyle={{
                marginTop: '30px',
                marginBottom: '20px'
                }}
            />
        </div>
      </>;
  }
  if (!isMobile) {
    content =
      <Container textAlign='center'>
        {content}
      </Container>;
  }

  return (
    <>
      <MetaTags>
        <title>每日持倉異動</title>
        <meta name="description" content= "每日持倉異動" />
        <meta property="og:title" content= "每日持倉異動" />
        <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
      </MetaTags>
      { !dataReady && (<Loader active inline='centered'>Loading</Loader>) }
      { content } 
    </>
  );
}