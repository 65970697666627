import { configureStore } from "@reduxjs/toolkit";

// import indexReducer from './features/index/indexSlice'
import stockReducer from "./features/stock/stockSlice";
import rsReducer from "./features/rs/rsSlice";
// import dashReducer from './features/dash/dashSlice'
import topicReducer from "./features/topic/topicSlice";
import zoomReducer from "./features/zoom/zoomSlice";
import userReducer from "./features/user/userSlice";

import stockListReducer from "./features/stock/stockListSlice";
import participantListReducer from "./features/participant/participantListSlice";
import shareholdingDateListReducer from "./features/shareholding/shareholdingDateListSlice";
import shareholdingChangeDateListReducer from "./features/shareholding/shareholdingChangeDateListSlice";
import latestShareholdingTopChangeReducer from "./features/shareholding/latestShareholdingTopChangeSlice";

import KMBBusRouteReducer from "./features/kmb/KMBBusRouteSlice";
import NWFBBusRouteReducer from "./features/nwfb/NWFBBusRouteSlice";
import CTBBusRouteReducer from "./features/ctb/CTBBusRouteSlice";
import LRRouteReducer from "./features/lr/LRRouteSlice";
import MTRRouteReducer from "./features/mtr/MTRRouteSlice";
import GMBBusRouteReducer from "./features/gmb/GMBBusRouteSlice";
import MTRBRouteReducer from "./features/mtrb/MTRBRouteSlice";
import NLBRouteReducer from "./features/nlb/NLBRouteSlice";
import { reducer as formReducer } from "redux-form";

// import ccassByDateReducer from './features/ccass/ccassByDateSlice'
// import ccassByStockReducer from './features/ccass/ccassByStockSlice'
// import profitWarnReducer from './features/profitWarn/profitWarnSlice'
// import consumptionVoucherReducer from './features/consumptionVoucher/consumptionVoucherSlice'
// import priceStatReducer from './features/priceStat/priceStatSlice'
// import priceStatChangeReducer from './features/priceStat/priceStatChangeSlice'
// import priceStatOtherReducer from './features/priceStat/priceStatOtherSlice'
// import ccassByParticipantReducer from './features/ccass/ccassByParticipantSlice'

export default configureStore({
  reducer: {
    // index: indexReducer,
    stock: stockReducer,
    zoom: zoomReducer,
    rs: rsReducer,
    // dash: dashReducer,
    topic: topicReducer,
    user: userReducer,
    stockList: stockListReducer,
    participantList: participantListReducer,
    shareholdingDateList: shareholdingDateListReducer,
    shareholdingChangeDateList: shareholdingChangeDateListReducer,
    latestShareholdingTopChange: latestShareholdingTopChangeReducer,
    kmbBusRoute: KMBBusRouteReducer,
    nwfbBusRoute: NWFBBusRouteReducer,
    ctbBusRoute: CTBBusRouteReducer,
    lrRoute: LRRouteReducer,
    mtrRoute: MTRRouteReducer,
    gmbBusRoute: GMBBusRouteReducer,
    mtrbRoute: MTRBRouteReducer,
    nlbRoute: NLBRouteReducer,
    form: formReducer,
    // ccassByDate: ccassByDateReducer,
    // ccassByStock: ccassByStockReducer,
    // profitWarn: profitWarnReducer,
    // consumptionVoucher: consumptionVoucherReducer,
    // priceStat: priceStatReducer,
    // priceStatChange: priceStatChangeReducer,
    // priceStatOther: priceStatOtherReducer,
    // ccassByParticipant: ccassByParticipantReducer,
  },
});
