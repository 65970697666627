import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import _ from "lodash";
import {
  Header,
  Container,
  Menu,
  Grid,
  Table,
  Card,
  Button,
  Message,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import Slider from "@material-ui/core/Slider";
import InTableBar from "../../charts/InTableBar";
import RSTableBySector from "../RSTableT/RSTableBySector";

function initData(data, data_w, tailLength, startPoint, chartType) {
  let i = 0;
  let plotData = [];
  let all = [];

  let dd = [];
  if (chartType === "0") {
    dd = data;
  } else {
    dd = data_w;
  }

  let datelist = dd.datelist;
  // console.log(]);

  _.forEach(dd.data, function (value, key) {
    let array = JSON.parse(value);
    let sData = array.slice(
      -(tailLength + startPoint),
      array.length - startPoint
    );
    all.push(...sData);
    // console.log(sData);
    let x = sData.slice(-1)[0][0] - 100;
    let y = sData.slice(-1)[0][1] - 100;

    let cat = "";
    if ((x >= 0) & (y >= 0)) {
      cat = "1";
    } else if ((x < 0) & (y >= 0)) {
      cat = "2";
    } else if ((x >= 0) & (y < 0)) {
      cat = "3";
    } else if ((x < 0) & (y < 0)) {
      cat = "4";
    }
    // console.log(array.length);
    // console.log(Math.round(Math.sqrt(x * x + y * y) * 1000) / 1000);
    plotData.push({
      name: key,
      data: sData,
      strength: Math.round(Math.sqrt(x * x + y * y) * 1000) / 1000,
      cat: cat,
    });
    i = i + 1;
  });
  // console.log("first");
  let all_ = all.map((num, index) => ({ x: num[0], y: num[1] }));
  let maxX = Math.ceil(_.maxBy(all_, "x").x);
  let minX = Math.floor(_.minBy(all_, "x").x);
  let maxY = Math.ceil(_.maxBy(all_, "y").y);
  let minY = Math.floor(_.minBy(all_, "y").y);
  return {
    plotData: plotData,
    plotRange: { maxX: maxX, minX: minX, maxY: maxY, minY: minY },
    endDate: datelist[datelist.length - startPoint - 1],
  };
}

function RRGview(props) {
  const { data, allowedBehindPaywall, market } = props;
  const [chartType, setChartType] = useState("0");
  const [tailLength, setTailLength] = useState(10);
  const [startPoint, setStartPoint] = useState(0);
  const [gridBottom, setGridBottom] = useState("10%");
  const { plotData, plotRange, endDate } = initData(
    data["data"]["rrg_d"],
    data["data"]["rrg_w"],
    tailLength,
    startPoint,
    chartType
  );

  // console.log(plotData);
  // console.log(endDate);

  const handleChange = (event, newValue) => {
    setTailLength(newValue);
  };
  const handleChangeStartPoint = (event, newValue) => {
    setStartPoint(newValue);
  };
  const timeUnit = chartType === "0" ? "日" : "週";
  var windowWidth = window.innerWidth;
  const chartHeight =
    0.656 * windowWidth < 550
      ? 550
      : 0.656 * windowWidth > 633
      ? 633
      : 0.656 * windowWidth;

  useEffect(() => {
    // console.log(windowWidth);
    setGridBottom(windowWidth < 550 ? "20%" : "10%");
  }, []);

  let newPlotData;

  if (market === "hk") {
    newPlotData = allowedBehindPaywall
      ? plotData
      : _.filter(plotData, (obj) =>
          ["綜合企業", "資訊科技業", "電訊業"].includes(obj.name)
        );
  } else {
    newPlotData = allowedBehindPaywall
      ? plotData
      : _.filter(plotData, (obj) =>
          [
            "Energy",
            "Information Technology",
            "Communication Services",
          ].includes(obj.name)
        );
  }

  // console.log(newPlotData);

  return (
    <Container style={{ maxWidth: "1000px", width: "95%", margin: "auto" }}>
      {/* <div style={{ textAlign: "center", paddingTop: "50px" }}>
        <Header as="h1">板塊輪動圖</Header>
      </div> */}
      <Header as="h4" textAlign="right" color="grey">
        {" "}
        數據至 : {endDate}{" "}
      </Header>
      <div style={{ textAlign: "left", overflowX: "auto" }}>
        <Container>
          <div style={{ paddingBottom: "10px" }}>
            <Message size="big" color="yellow" style={{ textAlign: "center" }}>
              功能測試中
            </Message>
          </div>
        </Container>
        <Menu pointing secondary compact size="huge">
          <Menu.Item
            active={chartType === "0"}
            onClick={() => {
              setChartType("0");
            }}
          >
            按日
          </Menu.Item>
          <Menu.Item
            active={chartType === "1"}
            onClick={() => {
              setChartType("1");
            }}
          >
            按週
          </Menu.Item>
        </Menu>
      </div>
      {/* <div>
        <Header as="h2" content={chartType === "0" ? "按日" : "按週"} />
      </div> */}
      <div
        style={{ maxWidth: "650px", marginTop: "1rem", marginRight: "25px" }}
      >
        <Grid>
          <Grid.Row>
            <Grid.Column width={4}>
              <div style={{ textAlign: "center", marginTop: "0.25rem" }}>
                數據長度({timeUnit}):
              </div>
            </Grid.Column>
            <Grid.Column width={12}>
              <Slider
                valueLabelDisplay="auto"
                value={tailLength}
                onChange={handleChange}
                marks={[
                  { value: 1, label: "1" },
                  { value: 20, label: "20" },
                ]}
                min={1}
                max={20}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <div style={{ textAlign: "center" }}>
                數據結束日期:
                <br />
                (n{timeUnit}前)
              </div>
            </Grid.Column>
            <Grid.Column width={12}>
              <Slider
                valueLabelDisplay="auto"
                value={startPoint}
                onChange={handleChangeStartPoint}
                marks={[
                  { value: 0, label: "0" },
                  { value: 9, label: "9" },
                ]}
                min={0}
                max={9}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>

      {chartType === "0" ? (
        <>
          <RRGPlot
            data={newPlotData}
            range={tailLength + 1}
            chartHeight={chartHeight}
            gridBottom={gridBottom}
            plotRange={plotRange}
          />
        </>
      ) : (
        <>
          <RRGPlot
            data={newPlotData}
            range={tailLength + 1}
            chartHeight={chartHeight}
            gridBottom={gridBottom}
            plotRange={plotRange}
          />
        </>
      )}
      <Container>
        {" "}
        {allowedBehindPaywall ? null : <AskForSubscribe />}{" "}
      </Container>
      <>
        <RRGTable data={plotData} />
      </>
    </Container>
  );
}

function RRGTable(props) {
  const { data } = props;
  let sorted = _.orderBy(data, ["cat", "strength"], ["asc", "desc"]);
  let max = _.maxBy(sorted, function (o) {
    return o.strength;
  }).strength;

  const catDict = [
    { name: "Leading", id: "1", color: "#CCE6CC", name_zh: "領先" },
    { name: "Improving", id: "2", color: "#D6DAF8", name_zh: "改善" },
    { name: "Weakening", id: "3", color: "#FBEDB2", name_zh: "轉差" },
    { name: "Lagging", id: "4", color: "#FFCCCC", name_zh: "落後" },
  ];

  const [selected, setSelected] = React.useState([]);
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    // console.log("selected");
  };

  // console.log("fxxk");
  // console.log(max);
  // console.log("RRGtable");
  return (
    <div
      style={{
        whiteSpace: "nowrap",
        overflowX: "auto",
        display: "inline-block",
        width: "100%",
        textAlign: "center",
      }}
    >
      <Table sortable selectable celled unstackable compact structured>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>板塊</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">分類</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">強度</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sorted.map((d, index) => {
            const isItemSelected = isSelected(d.name);
            // console.log(d);
            // console.log(max);
            // console.log((d.strength / max) * 100);
            // console.log(typeof d.cat);
            // console.log(d.cat);
            // console.log(typeof catDict[0]["id"]);
            // console.log(catDict[0]["id"]);
            // console.log(d.cat === catDict[0]["id"]);
            // console.log(
            //   _.find(catDict, function (o) {
            //     return o.id === d.cat;
            //   })["name"]
            // );

            let color = _.find(catDict, function (o) {
              return o.id === d.cat;
            })["color"];

            return (
              <>
                <Table.Row
                  key={d.name}
                  // onClick={(event) => handleClick(event, d.name)}
                  style={{ backgroundColor: color }}
                >
                  <Table.Cell>
                    {/* <Icon
                      name={isItemSelected ? "caret down" : "caret right"}
                    /> */}
                    {d.name}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {
                      _.find(catDict, function (o) {
                        return o.id === d.cat;
                      })["name"]
                    }
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <div style={{ width: "100px", margin: "auto" }}>
                      <InTableBar
                        min={0}
                        secondMin={0}
                        // mid={0}
                        // mid={strength / max}
                        mid={(d.strength / max) * 100}
                        secondMax={(d.strength / max) * 100}
                        // secondMax={100}
                        max={100}
                        col={"#2471A3"}
                      />
                    </div>
                  </Table.Cell>
                </Table.Row>
                {isItemSelected ? (
                  <Table.Row>
                    <Table.Cell colSpan={3}>
                      <RSTableBySector industry={d.name} />
                    </Table.Cell>
                  </Table.Row>
                ) : null}
              </>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
}

function RRGPlot(props) {
  const { data, plotRange, chartHeight, gridBottom } = props;
  // console.log(data);

  let plotSeries = data.map(function (item, idx) {
    // console.log(item);
    return {
      name: item.name,
      seriesName: item.name,
      data: item.data,
      triggerLineEvent: true,
      type: "line",
      smooth: true,
      endLabel: {
        show: true,
        position: "top",
        color: "black",
        fontSize: 12,
        formatter: function (d) {
          return d.seriesName;
        },
      },
      emphasis: {
        focus: "series",
      },
      // markLine: {
      //   symbol: "none",
      //   lineStyle: {
      //     type: "solid",
      //     color: "black",
      //   },
      //   data: [{ yAxis: 100 }, { xAxis: 100 }],
      // },
      markPoint: {
        symbol: "circle",
        symbolSize: 10,
        data: [
          {
            coord: [
              item.data[item.data.length - 1][0],
              item.data[item.data.length - 1][1],
            ],
          },
        ],
      },
      // markArea: {
      //   itemStyle: {
      //     color: "rgba(255, 173, 177, 0.4)",
      //   },
      //   label: {
      //     position: "insideTopLeft",
      //   },
      //   // data: [
      //   //   [
      //   //     {
      //   //       name: "Morning Peak",
      //   //       // xAxis: 0,
      //   //       yAxis: 100,
      //   //       // y: 100,
      //   //     },
      //   //     {
      //   //       name: "Morning Peak",
      //   //       // xAxis: 100,
      //   //       yAxis: 200,
      //   //       // yAxis: 200,
      //   //     },
      //   //   ],
      //   // ],
      // },
    };
  });

  plotSeries.push(
    {
      name: "Improving",
      type: "scatter",
      data: [],
      markArea: {
        silent: true,
        itemStyle: {
          color: "transparent",
          borderWidth: 0,
          borderType: "dashed",
        },
        label: {
          position: "insideTopLeft",
          fontWeight: "bold",
          color: "#7484fc",
        },
        data: [
          [
            {
              name: "Improving",
              xAxis: plotRange.minX,
              yAxis: 100,
            },
            {
              xAxis: 100,
              yAxis: plotRange.maxY,
            },
          ],
        ],
      },
    },
    {
      name: "Lagging",
      type: "scatter",
      data: [],
      markArea: {
        silent: true,
        itemStyle: {
          color: "transparent",
          borderWidth: 0,
          borderType: "dashed",
        },
        label: {
          position: "insideBottomLeft",
          fontWeight: "bold",
          color: "#fc4242",
        },
        data: [
          [
            {
              name: "Lagging",
              xAxis: plotRange.minX,
              yAxis: plotRange.minY,
            },
            {
              xAxis: 100,
              yAxis: 100,
            },
          ],
        ],
      },
    },
    {
      name: "Leading",
      type: "scatter",
      data: [],
      markArea: {
        silent: true,
        itemStyle: {
          color: "transparent",
          borderWidth: 0,
          borderType: "dashed",
        },
        label: {
          position: "insideTopRight",
          fontWeight: "bold",
          color: "#51a651",
        },
        data: [
          [
            {
              name: "Leading",
              xAxis: 100,
              yAxis: 100,
            },
            {
              xAxis: plotRange.maxX,
              yAxis: plotRange.maxY,
            },
          ],
        ],
      },
    },
    {
      name: "Weakening",
      type: "scatter",
      data: [],
      markArea: {
        silent: true,
        itemStyle: {
          color: "transparent",
          borderWidth: 0,
          borderType: "dashed",
        },
        label: {
          position: "insideBottomRight",
          fontWeight: "bold",
          color: "#ffdc4a",
        },
        data: [
          [
            {
              name: "Weakening",
              xAxis: 100,
              yAxis: plotRange.minY,
            },
            {
              xAxis: plotRange.maxX,
              yAxis: 100,
            },
          ],
        ],
      },
    },
    {
      name: "centerLine",
      type: "scatter",
      data: [],
      markLine: {
        symbol: "none",
        lineStyle: {
          type: "solid",
          color: "black",
        },
        data: [{ yAxis: 100 }, { xAxis: 100 }],
      },
    }
  );

  const option = {
    animation: false,
    legend: {
      data: data.map(function (item, idx) {
        return item.name;
      }),
      left: "center",
      // height: 50,
      // padding: [5, 10],
      bottom: 0,
    },
    grid: {
      top: "3%",
      left: "3%",
      right: "7%",
      bottom: gridBottom,
      containLabel: true,
    },

    xAxis: {
      scale: true,
      type: "value",
      min: function (value) {
        return Math.floor(value.min) < 98 ? Math.floor(value.min) : 98;
      },
      max: function (value) {
        return Math.ceil(value.max) > 102 ? Math.ceil(value.max) : 102;
      },
    },
    yAxis: {
      scale: true,
      type: "value",
      min: function (value) {
        return Math.floor(value.min) < 98 ? Math.floor(value.min) : 98;
      },
      max: function (value) {
        return Math.ceil(value.max) > 102 ? Math.ceil(value.max) : 102;
      },
    },
    series: plotSeries,
  };

  return (
    <>
      <br />
      <ReactEcharts option={option} style={{ height: chartHeight }} />
      <br />
    </>
  );
}

export default RRGview;

function AskForSubscribe() {
  return (
    <div style={{ margin: "auto" }}>
      <Card centered style={{ textAlign: "center" }}>
        <Card.Content>
          <Card.Header>加入付費版，以觀看全部板塊</Card.Header>
        </Card.Content>
        <Card.Content extra>
          <Link to="/subscription">
            <Button basic color="blue">
              了解更多
            </Button>
          </Link>
        </Card.Content>
      </Card>
    </div>
  );
}
