import React, { useEffect } from "react";
import {
  Loader,
  Card,
  Feed,
  Icon,
  Button,
  Container,
  Grid,
  Segment,
} from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { selectAllFilters, fetchFilters, toIdle } from "./zoomSlice";
import { removeFilters } from "./zoomSlice";

// import { Spinner } from "../../components/Spinner";
// import { PostAuthor } from "./PostAuthor";
// import { TimeAgo } from "./TimeAgo";
// import { ReactionButtons } from "./ReactionButtons";
// import { selectAllPosts, fetchPosts } from "./postsSlice";

const nameC = {
  title: "名稱",
};

const FilterExcerpt = ({ filter }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  console.log(filter);

  const handleDelete = async (data) => {
    console.log(data);
    await dispatch(
      removeFilters({
        uid: "xyz",
        filter: data,
      })
    );
  };

  return (
    <div style={{ maxWidth: "600px", margin: "auto", paddingBottom: "25px" }}>
      <Card centered fluid>
        <Card.Content>
          <Card.Header>
            {filter.filter.title}
            {/* <div style={{ float: "right" }}>
              <Button icon size="mini">
                <Icon name="ellipsis horizontal" />
              </Button>
            </div> */}
          </Card.Header>
        </Card.Content>
        <Card.Content>
          <Feed>
            <Feed.Event>
              <Feed.Content>
                {Object.keys(filter.filter).map((x) =>
                  filter.filter[x] ? (
                    <Feed.Summary>
                      {x} : {filter.filter[x]}
                    </Feed.Summary>
                  ) : null
                )}
                <Feed.Date content="1 day ago" />
              </Feed.Content>
            </Feed.Event>
          </Feed>
        </Card.Content>
        <Card.Content extra>
          <Grid>
            <Grid.Row columns={3}>
              <Grid.Column>
                <Button basic color="red" onClick={() => handleDelete(filter)}>
                  刪除
                </Button>
              </Grid.Column>
              <Grid.Column>
                <Button
                  basic
                  color="grey"
                  onClick={() => {
                    history.push(`/zoom/filter/${filter.id}`);
                  }}
                >
                  更改
                </Button>
              </Grid.Column>
              <Grid.Column>
                <Button
                  basic
                  color="green"
                  onClick={() => {
                    history.push(`/zoom/picker/${filter.id}`);
                  }}
                >
                  開始選股
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {/* </div> */}
        </Card.Content>
      </Card>
    </div>
  );
};

export const FilterListPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const filters = useSelector(selectAllFilters);
  const zoomStatus = useSelector((state) => state.zoom.status);
  const error = useSelector((state) => state.zoom.error);

  useEffect(() => {
    if (zoomStatus === "idle") {
      dispatch(fetchFilters("xyz"));
      console.log("fetch");
    }
  }, [zoomStatus, dispatch]);

  let content;

  if (zoomStatus === "loading") {
    content = (
      <div>
        <section className="posts-list">
          <div
            style={{
              padding: "100px",
              margin: "auto",
              alignItems: "center",
              height: "75vh",
              // overflow: "auto",
            }}
          >
            <Loader active inline="centered">
              {" "}
              Loading
            </Loader>
          </div>
        </section>
      </div>
    );
  } else if (zoomStatus === "succeeded") {
    // if (filters) {
    console.log("succeed");
    console.log(filters);
    const orderedFilters = filters
      .slice()
      .sort((a, b) => b.timeString.localeCompare(a.timeString));
    content = orderedFilters.map((filter) => (
      <FilterExcerpt key={filter.id} filter={filter} />
    ));
    // } else {
    //   dispatch(toIdle({}));
    // }
    // Sort posts in reverse chronological order by datetime string

    // content = orderedPosts.map((post) => (
    //   <PostExcerpt key={post.id} post={post} />
    // ));
  } else if (zoomStatus === "failed") {
    content = <div>{error}</div>;
  }

  return (
    <Container
      style={{ margin: "auto", textAlign: "center", minHeight: "75vh" }}
    >
      <h1>D-ZOOM 選股條件列表</h1>
      <div style={{ paddingBottom: "25px" }}>
        <Button
          icon="add"
          size="big"
          basic
          onClick={() => {
            history.push("/zoom/filter/new");
          }}
        />
      </div>
      {content}
    </Container>
  );
};

export default FilterListPage;
