import React from "react";
import _ from "lodash";
import {
  selectAllTopic,
  fetchTopic,
  toIdle,
} from "../../../features/topic/topicSlice";
import { useSelector, useDispatch } from "react-redux";
import ZoomFilter from "./ZoomFilter";

function Zoom(props) {
  const { id } = props;
  const dispatch = useDispatch();
  const topics = useSelector(selectAllTopic);
  const topicStatus = useSelector((state) => state.topic.status);
  const error = useSelector((state) => state.topic.error);
  //   console.log(id);
  //   console.log("props");
  return (
    <div>
      {" "}
      <ZoomFilter />{" "}
    </div>
  );
}

export default Zoom;
