import React, {
  useEffect,
  useState,
  Component,
  useRef,
  useReducer,
} from "react";
import {
  Loader,
  Table,
  Pagination,
  Container,
  Header,
  Dropdown,
  Icon,
  Input,
  Form,
  Checkbox,
  Label,
  Button,
} from "semantic-ui-react";
import _ from "lodash";
import { createStore, combineReducers } from "redux";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
// import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
import InFeedAdsWhite from "../../ads/InFeedAdsWhite";
import source from "../../../mock_source";
import PriceLine from "../../charts/PriceLine2";
import Slider from "@material-ui/core/Slider";
import { formatNumber } from "../../../util/dataFormatter";
import { Field, reduxForm } from "redux-form";
// import StockChart2T from "../../charts/StockChart2T";
import InTableChart from "./InTableChart";
import RSChartContainer from "./RSChartContainer";
import StockChartContainer from "./StockChartContainer";
import RSChart from "../../charts/RSChart";
import { AddFavModal, FavBtns } from "components/widget/MyFav";

const initialState = {
  direction: "descending",
  column: "id",
  activePage: "1",
  totalPage: "4",
};

function resultTableReducer(state = initialState, action) {
  switch (action.type) {
    case "CHANGE_SORT": {
      if (state.column === action.column) {
        return {
          ...state,
          direction:
            state.direction === "ascending" ? "descending" : "ascending",
          activePage: 1,
        };
      }
      return {
        ...state,
        column: action.column,
        direction: "ascending",
        activePage: 1,
      };
    }
    case "SET_ACTIVE_PAGE": {
      const string = action.text;
      // window.scrollTo(0, 0);
      return {
        ...state,
        activePage: string,
      };
    }
    default: {
      return state;
    }
  }
}

function FavouriteTable(props) {
  const { topicInfo, rstable } = props.data; //data
  const header = rstable.header;
  const data = rstable;
  const { wishList } = props;
  const [tableData, setTableData] = useState([]);

  function obj2Array(n) {
    return Object.values(n);
  }
  var data1 = _.map(data.data, obj2Array);

  useEffect(() => {
    var buffer = _.filter(data1, function (o) {
      return wishList.includes(o[0][0]);
    })
      .map((v) => {
        var d = {};
        for (var i = 0; i < header.length; i++) {
          d[header[i]] = v[0][i];
        }
        let a = _.find(source, function (o) {
          return o.no === d.id;
        });
        return {
          ...d,
          name_zh: a ? a["name_zh"] : "",
          name_en: a ? a["name_en"] : "",
        };
      })
      .filter((data) => data.name_zh !== "");
    setTableData(buffer);
  }, [wishList]);

  let newData = _.sortBy(tableData, ["rs"]).reverse();

  return (
    <div
      style={{
        widht: "100%",
        maxWidth: "1000px",
        margin: "auto",
        alignItems: "center",
      }}
    >
      <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
        <Header as="h4" textAlign="right" color="grey">
          {" "}
          數據至 : {topicInfo.lastUpdate} <br />
        </Header>
      </div>
      <div style={{ textAlign: "center" }}>
        <SortableTable data={newData} />
      </div>
    </div>
  );
}

function SortableTable(props) {
  const scrollRef = useRef(null);
  const [chartLeft, setChartLeft] = useState(0);
  const [chartWidth, setChartWidth] = useState(10);
  const [state, dispatch] = useReducer(resultTableReducer, initialState);
  const { direction, column, activePage } = state;
  const { data } = props;
  let newData = data;

  const [selected, setSelected] = React.useState([]);
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const resize = (event) => {
    if (scrollRef && scrollRef.current) {
      setChartWidth(scrollRef.current.clientWidth - 20);
    }
  };
  const handleScroll = (event) => {
    setChartLeft(scrollRef.current.scrollLeft);
  };
  useEffect(() => {
    resize();
    window.addEventListener("resize", resize);
    return () => {
      window.addEventListener("resize", resize);
    };
  }, [scrollRef]);
  useEffect(() => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.addEventListener("scroll", handleScroll);
      return () => {
        if (scrollRef && scrollRef.current) {
          scrollRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [scrollRef]);

  const pos_pagination = isMobile ? "center" : "right";
  if (direction === "ascending") {
    newData = _.sortBy(newData, [column]);
  } else {
    newData = _.sortBy(newData, [column]).reverse();
  }

  const nPerPage = 25;
  const totalPage = Math.ceil(newData.length / nPerPage);
  let newData2 = [];
  newData2 = _.slice(
    newData,
    (activePage - 1) * nPerPage,
    activePage * nPerPage
  );
  let favBtns = FavBtns(newData2.map((d) => d.id));

  const handlePaginationChange = (e, { activePage }) => {
    dispatch({
      type: "SET_ACTIVE_PAGE",
      text: activePage,
    });
  };

  const topRowHeaderStyle = {
    position: "sticky",
    top: "0px",
    zIndex: "10",
    backgroundColor: "rgb(240,240,240)",
    // fontSize: normalSize[fontSize],
  };
  const firstColHeaderStyle = {
    position: "sticky",
    left: "0px",
    zIndex: "5",
    // backgroundColor: "rgb(240,240,240)",
    backgroundColor: "rgb(255,255,255)",
    // fontSize: normalSize[fontSize],
  };
  const contentStyle = {
    // fontSize: normalSize[fontSize],
    zIndex: "0",
  };
  // const rowHeaderStyle = {
  //   // fontSize: smallSize[fontSize],
  //   color: "grey",
  // };

  return (
    <div
      style={{
        whiteSpace: "nowrap",
        overflowX: "hidden",
        display: "inline-block",
        width: "100%",
        textAlign: "center",
      }}
    >
      <div
        style={{
          overflow: "auto",
          // maxHeight: "85vh",
          overscrollBehavior: "auto",
        }}
        ref={scrollRef}
      >
        <Table sortable selectable celled unstackable compact structured>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                style={{
                  position: "sticky",
                  top: "0px",
                  left: "0px",
                  zIndex: "15",
                  backgroundColor: "rgb(240,240,240)",
                  borderWidth: "0px",
                }}
                textAlign="left"
                rowSpan="2"
                sorted={column === "id" ? direction : null}
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "id" });
                }}
              >
                名稱
                {isMobile ? <br /> : null}
                (編號)
              </Table.HeaderCell>
              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
                sorted={column === "C" ? direction : null}
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "C" });
                }}
              >
                價格
              </Table.HeaderCell>
              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
                sorted={column === "RS" ? direction : null}
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "RS" });
                }}
              >
                RS
              </Table.HeaderCell>
              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
                sorted={column === "rsd10d" ? direction : null}
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "rsd10d" });
                }}
              >
                RS變化 <br /> (10天)
              </Table.HeaderCell>
              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
                sorted={column === "t" ? direction : null}
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "t" });
                }}
              >
                成交額
              </Table.HeaderCell>
              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
                sorted={column === "cap" ? direction : null}
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "cap" });
                }}
              >
                市值
              </Table.HeaderCell>
              {/* <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
              >
                <div>
                  <Icon name="star" style={{ color: "#FFCA28" }} />
                </div>
              </Table.HeaderCell> */}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {newData2.map((d, index) => {
              const isItemSelected = isSelected(d.id);
              // console.log(d.id);
              return (
                <>
                  {index !== 0 && index % 10 === 0 && (
                    <Table.Row key={index}>
                      <Table.Cell colSpan={7} textAlign="center">
                        <InFeedAdsWhite></InFeedAdsWhite>
                      </Table.Cell>
                    </Table.Row>
                  )}
                  <Table.Row
                    key={d.id}
                    onClick={(event) => handleClick(event, d.id)}
                  >
                    <Table.Cell style={firstColHeaderStyle} textAlign="left">
                      <Icon
                        name={isItemSelected ? "caret down" : "caret right"}
                      />
                      {favBtns.get(d.id)}
                      {d.name_zh !== "" ? (
                        <Link to={"/stock/" + d.id}>
                          {d.name_zh.length > 8
                            ? d.name_zh.substring(0, 8) + "..."
                            : d.name_zh}
                          {isMobile ? <br /> : null}({d.id})
                        </Link>
                      ) : (
                        <>({d.id})</>
                      )}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <div style={{ marginBottom: "-0.75rem" }}> ${d.C} </div>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <div style={{ marginBottom: "-0.75rem" }}> {d.rs} </div>
                      <div style={{ width: "100px", margin: "auto" }}>
                        <PriceLine
                          min={0}
                          secondMin={0}
                          mid={d.rs}
                          secondMax={d.rs}
                          max={100}
                          col={"#2471A3"}
                        />
                      </div>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <div style={{ marginBottom: "-0.75rem" }}>
                        {" "}
                        {d.rsd10d}{" "}
                      </div>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <div style={{ marginBottom: "-0.75rem" }}>
                        {" "}
                        {formatNumber(d.t * 10000)}{" "}
                      </div>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <div style={{ marginBottom: "-0.75rem" }}>
                        {" "}
                        {d.cap === 0
                          ? "-"
                          : formatNumber(d.cap * 100000000)}{" "}
                      </div>
                    </Table.Cell>
                    {/* <Table.Cell textAlign="center">
                      <span>{FavBtn(d.id)}</span>
                    </Table.Cell> */}
                  </Table.Row>

                  {isItemSelected ? (
                    <Table.Row>
                      <Table.Cell
                        style={{
                          paddingBottom: "10px",
                          paddingTop: "10px",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                        }}
                        colSpan={8}
                      >
                        <div
                          style={{
                            position: "sticky",
                            left: "10px",
                            width: chartWidth,
                            zIndex: "9",
                          }}
                        >
                          <InTableChartContainer id={d.id} />
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                </>
              );
            })}
          </Table.Body>
        </Table>
      </div>

      <div style={{ left: "50%", paddingTop: "25px" }}>
        <AddFavModal />
      </div>

      <div
        style={{
          paddingTop: "25px",
          paddingBottom: "25px",
          float: pos_pagination,
        }}
      >
        <Pagination
          boundaryRange={0}
          activePage={activePage}
          size={isMobile ? "small" : "big"}
          siblingRange={1}
          totalPages={totalPage}
          onPageChange={handlePaginationChange}
        />
      </div>
    </div>
  );
}

export const InTableChartContainer = (props) => {
  const stockId = props.id;
  const [activeChart, setActiveChart] = useState("0");
  return (
    <>
      <Container
        style={{
          //   paddingLeft: "15px",
          //   paddingTop: "px",
          paddingBottom: "5px",
        }}
      >
        <Button
          style={{ borderRadius: "15px" }}
          active={activeChart === "0"}
          onClick={() => {
            setActiveChart("0");
          }}
          size="tiny"
        >
          CCASS
        </Button>
        <Button
          style={{ borderRadius: "15px" }}
          active={activeChart === "1"}
          onClick={() => {
            setActiveChart("1");
          }}
          size="tiny"
        >
          RS
        </Button>
      </Container>
      {activeChart === "0" ? (
        <StockChartContainer id={stockId} />
      ) : (
        <RSChart id={stockId} />
      )}
    </>
  );
};
export default FavouriteTable;
