import React, { useState } from 'react'
import './StockChart.css';
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { isMobile } from "react-device-detect";
import { Label } from 'semantic-ui-react'
import _ from 'lodash'


const legendLen = isMobile ? 4 : 100;


function KeyHolderPctChart2(HolderPctChg) {
  const data = HolderPctChg.HolderPctChg.keyHolderPctChg
  const closePrice = HolderPctChg.HolderPctChg.ClosePrice
  const range = data.range

  const [duration, setDuration] = useState(range[0]);
  var offset = 0
  var colors = [];
  colors[0] = '#2F8DFA';
  colors[1] = '#1FD0BF';
  colors[2] = '#EB648B';
  colors[3] = '#F8C753';
  colors[4] = '#EB7E30';
  colors[5] = '#A93790';
  colors[6] = '#049587';
  colors[7] = '#1553B6';
  colors[8] = '#55008C';
  colors[9] = '#8B572A';
  colors[10] = '#000000';

  var agent = data.agent,
    chartData = data.keyholdingChg,
    time = data.x,
    dataLength = data.keyholdingChg.length,
    result = [],
    close = [],
    i = 0

  var k = closePrice.length - duration - 1

  if (k < 0) {
    k = 0
  }

  let buffer = []
  for (k; k < closePrice.length; k += 1) {
    buffer.push([
      time[k], closePrice[k]
    ])
  }
  result.push(
    {
      name: "收市價",
      data: buffer,
      visible: true,
      color: 'black',
      yAxis: 1,
      dashStyle: 'ShortDash',
      tooltip: {
        valuePrefix: '$ ',
      },
    }
  )

  for (i; i < dataLength; i += 1) {
    let d = Object.values(chartData[i])[0]
    var k = d.length - duration - 1
    var ref = d.length - duration - 1
    let a = agent[i]
    let isVisible = i < 5 ? true : false
    let buffer = []

    if (k < 0) {
      k = 0
      ref = 0
    }

    for (k; k < d.length; k += 1) {
      buffer.push([
        time[k], Math.round((d[k + offset] - d[ref + offset]) * 100) / 100
      ])
    }

    result.push(
      {
        name: a,
        data: buffer,
        visible: isVisible,
        color: colors[i],
        yAxis: 0,
        tooltip: {
          valueSuffix: ' 萬股',
        },
      }
    );
  }


  const options = {
    colors: colors,
    // chart: {
    //   plotBackgroundImage: `${process.env.PUBLIC_URL}/images/watermark.svg`
    // },
    legend: {
      enabled: true,
      align: 'center',
      /* layout: 'vertical', */
      // fontSize: '0.8em',
      verticalAlign: 'bottom',
      x: 0,
      y: 0,
      labelFormatter: function () {
        return this.name.length > legendLen ? this.name.substring(0, legendLen) + '...' : this.name;
      }
    },

    xAxis: {
      type: 'datetime'
    },

    yAxis: [
      {
        labels: {
          format: '{value} 萬股',
          align: 'right',
        },
        title: {
          text: "持倉變化",
          align: 'high',
          offset: -30,
          rotation: 0,
        },
        opposite: true,
        startOnTick: false,
        endOnTick: false,
      }, {
        labels: {
          align: 'left',
        },
        title: {
          text: "收市價",
          align: 'high',
          offset: -10,
          rotation: 0,
        },
        opposite: false,
        gridLineWidth: 0,
      }],

    rangeSelector: {
      enabled: false
    },

    navigator: {
      enabled: false
    },

    scrollbar: {
      enabled: false
    },

    series: result,

    tooltip: {
      split: true
    },

    credits: {
      text: 'DaaSHK',
      style: {
        fontSize: '1em'
      },
    },
  };
  var periodRange = range
  return (
    <div style={{ paddingTop: "15px" }}>
      <div style={{ paddingBottom: "15px" }}>
        時期:
        {periodRange.map((period, index) => (
          <Label key={index} color={duration === period ? 'blue' : null} onClick={() => setDuration(period)} basic style={{ marginLeft: '.5rem' }}>
            {period}天
          </Label>
        ))}
      </div>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"stockChart"}
        options={options}
      />
    </div>
  )
}

export default KeyHolderPctChart2