import React from "react";
import {
  Loader,
  Table,
  Pagination,
  Container,
  Header,
  Dropdown,
  Icon,
  Input,
  Form,
  Checkbox,
} from "semantic-ui-react";
import _, { filter } from "lodash";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import { createStore, combineReducers } from "redux";
import { useSelector, useDispatch } from "react-redux";
// import {
//   selectAllFilters,
//   fetchFilters,
//   toIdle,
// } from "../../../features/topic/zoomSlice";
import source from "../../../mock_source";
import PriceLine from "../../charts/PriceLine2";
import Slider from "@material-ui/core/Slider";
import { formatNumber } from "../../../util/dataFormatter";

function ZoomFilter() {
  return (
    <Container style={{ paddingTop: "10px" }}>
      {/* <FiltersView /> */}
      <FilterCreater />
      <ZoomTable />
    </Container>
  );
}

function FilterCreater(props) {
  const { filter } = props;
  console.log("hi");
  console.log(filter);
  const dayOptions = [
    { key: "1", text: "1天", value: "1" },
    { key: "5", text: "5天", value: "5" },
    { key: "10", text: "10天", value: "10" },
  ];
  const rankOptions = [
    { key: "3", text: "Top3", value: "3" },
    { key: "5", text: "Top5", value: "5" },
    { key: "10", text: "Top10", value: "10" },
  ];
  return (
    <>
      <Form>
        <Form.Field inline>
          <label>分類： </label>
          <Checkbox
            label="主板 "
            style={{ paddingRight: "10px" }}
            // checked={filter.cat.main}
            // onChange={() => {
            //   store.dispatch({ type: "TOGGLE_CHECKBOX_CAT", text: "main" });
            // }}
          />
          <Checkbox
            label="創業板 "
            style={{ paddingRight: "10px" }}
            // checked={filter.cat.GEM}
            // onChange={() => {
            //   store.dispatch({ type: "TOGGLE_CHECKBOX_CAT", text: "GEM" });
            // }}
          />
          <Checkbox
            label="恒指 "
            style={{ paddingRight: "10px" }}
            // checked={filter.cat.HSI}
            // onChange={() => {
            //   store.dispatch({ type: "TOGGLE_CHECKBOX_CAT", text: "HSI" });
            // }}
          />
          <Checkbox
            label="半新股 "
            style={{ paddingRight: "10px" }}
            // checked={filter.cat.IPO}
            // onChange={() => {
            //   store.dispatch({ type: "TOGGLE_CHECKBOX_CAT", text: "IPO" });
            // }}
          />
        </Form.Field>
        <Form.Group inline>
          <label>行業： </label>
          <Dropdown
            // onChange={this.handleChange}
            placeholder="行業"
            clearable
            scrolling
            selection
            // value={this.props.value}
            // options={this.props.options}
          />
        </Form.Group>
        <Form.Field inline>
          <label>價格： </label>
          <label style={{ paddingRight: "5px" }}>$</label>
          <Input
            size="mini"
            placeholder=""
            style={{ maxWidth: "75px" }}
            // defaultValue={priceFilter[0]}
            // onChange={onChangeFilterPL}
          />
          <label style={{ paddingLeft: "10px", paddingRight: "10px" }}>~</label>
          <label style={{ paddingRight: "5px" }}>$</label>{" "}
          <Input
            size="mini"
            placeholder=""
            style={{ maxWidth: "75px" }}
            // defaultValue={priceFilter[1]}
            // onChange={onChangeFilterPH}
          />
        </Form.Field>
        <Form.Group inline>
          <Form.Field>
            <Checkbox
              label="現價>50MA"
              style={{ paddingRight: "10px" }}
              //   checked={filterCondition.c1}
              //   onChange={() => {
              //     store.dispatch({ type: "TOGGLE_CHECKBOX", text: "c1" });
              //   }}
            />
            <Checkbox
              label="現價>200MA"
              style={{ paddingRight: "10px" }}
              //   checked={filterCondition.c1}
              //   onChange={() => {
              //     store.dispatch({ type: "TOGGLE_CHECKBOX", text: "c1" });
              //   }}
            />
            <Checkbox
              label="現價>30WMA"
              style={{ paddingRight: "10px" }}
              //   checked={filterCondition.c4}
              //   onChange={() => {
              //     store.dispatch({ type: "TOGGLE_CHECKBOX", text: "c4" });
              //   }}
            />
            <Checkbox
              label="50MA>150MA "
              style={{ paddingRight: "10px" }}
              //   checked={filterCondition.c2}
              //   onChange={() => {
              //     store.dispatch({ type: "TOGGLE_CHECKBOX", text: "c2" });
              //   }}
            />
            <Checkbox
              label="50MA>200MA "
              style={{ paddingRight: "10px" }}
              //   checked={filterCondition.c2}
              //   onChange={() => {
              //     store.dispatch({ type: "TOGGLE_CHECKBOX", text: "c2" });
              //   }}
            />
            <Checkbox
              label="150MA>200MA "
              style={{ paddingRight: "10px" }}
              //   checked={filterCondition.c3}
              //   onChange={() => {
              //     store.dispatch({ type: "TOGGLE_CHECKBOX", text: "c3" });
              //   }}
            />
          </Form.Field>
        </Form.Group>

        <Form.Field inline>
          <label>市值： </label>
          <Input
            size="mini"
            placeholder=""
            style={{ maxWidth: "75px" }}
            // defaultValue={capFilter[0]}
            // onChange={onChangeFilterCL}
          />
          <label style={{ paddingLeft: "5px" }}>億</label>
          <label style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            {" "}
            ~{" "}
          </label>
          <Input
            size="mini"
            placeholder=""
            style={{ maxWidth: "75px" }}
            // defaultValue={capFilter[1]}
            // onChange={onChangeFilterCH}
          />
          <label style={{ paddingLeft: "5px" }}>億</label>
        </Form.Field>
        <Form.Field inline>
          <label>成交額：</label>
          <Dropdown
            inline
            options={dayOptions}
            defaultValue={dayOptions[0].value}
          />
          <label style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            {">"}
          </label>
          <Input
            size="mini"
            placeholder=""
            style={{ maxWidth: "75px" }}
            // defaultValue={volFilter}
            // onChange={onChangeFilterV}
          />
          <label style={{ paddingLeft: "5px" }}>萬</label>
        </Form.Field>
        <Form.Field inline>
          <label>成交量：</label>
          <Dropdown
            inline
            options={dayOptions}
            defaultValue={dayOptions[0].value}
          />
          <label style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            {">"}
          </label>
          <Input
            size="mini"
            placeholder=""
            style={{ maxWidth: "75px" }}
            // defaultValue={volFilter}
            // onChange={onChangeFilterV}
          />
          <label style={{ paddingLeft: "5px" }}>萬</label>
        </Form.Field>
        <Form.Field inline>
          <label>相對強度(RS)： </label>
          <Input
            size="mini"
            placeholder=""
            style={{ maxWidth: "75px" }}
            // defaultValue={capFilter[0]}
            // onChange={onChangeFilterCL}
          />
          <label style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            {" "}
            ~{" "}
          </label>
          <Input
            size="mini"
            placeholder=""
            style={{ maxWidth: "75px" }}
            // defaultValue={capFilter[1]}
            // onChange={onChangeFilterCH}
          />
        </Form.Field>
        <Form.Field inline>
          <label>Non CCASS 持倉： </label>
          <Input
            size="mini"
            placeholder=""
            style={{ maxWidth: "75px" }}
            // defaultValue={capFilter[0]}
            // onChange={onChangeFilterCL}
          />
          <label style={{ paddingLeft: "5px" }}>%</label>
          <label style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            {" "}
            ~{" "}
          </label>
          <Input
            size="mini"
            placeholder=""
            style={{ maxWidth: "75px" }}
            // defaultValue={capFilter[1]}
            // onChange={onChangeFilterCH}
          />
          <label style={{ paddingLeft: "5px" }}>%</label>
        </Form.Field>
        <Form.Field inline>
          <label>持倉集中比率：</label>
          <Dropdown
            inline
            options={rankOptions}
            defaultValue={rankOptions[0].value}
          />
          <label style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            {">"}
          </label>
          <Input
            size="mini"
            placeholder=""
            style={{ maxWidth: "75px" }}
            // defaultValue={volFilter}
            // onChange={onChangeFilterV}
          />
          <label style={{ paddingLeft: "5px" }}>萬</label>
        </Form.Field>
        <Form.Field inline>
          <label>北水持倉： </label>
          <Input
            size="mini"
            placeholder=""
            style={{ maxWidth: "75px" }}
            // defaultValue={capFilter[0]}
            // onChange={onChangeFilterCL}
          />
          <label style={{ paddingLeft: "5px" }}>%</label>
          <label style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            {" "}
            ~{" "}
          </label>
          <Input
            size="mini"
            placeholder=""
            style={{ maxWidth: "75px" }}
            // defaultValue={capFilter[1]}
            // onChange={onChangeFilterCH}
          />
          <label style={{ paddingLeft: "5px" }}>%</label>
        </Form.Field>
        <Form.Group inline>
          <Form.Button>收藏條件</Form.Button>
          <Form.Button>重置</Form.Button>
          <Form.Button>選股</Form.Button>
        </Form.Group>
      </Form>
    </>
  );
}

function dataFilter(data, filterLow, filterHigh, q) {
  let newData;
  const low = parseFloat(filterLow) > 0 ? parseFloat(filterLow) : 0;
  const high = parseFloat(filterHigh) > 0 ? parseFloat(filterHigh) : 99999999;
  newData = _.filter(data, function (d) {
    return data.q >= low && data.q <= high;
  });
}

function FiltersView() {
  return <>This is FilterView</>;
}

function ZoomTable() {
  return <>This is ZoomTable</>;
}

export default ZoomFilter;

/* Data format

Title :  name of filter
sector :

pirce related:
pricel 
priceh
isPlgma50
isPlgma200
isPlgwma30
isMa50lgma150
isMa50lgma200
isMa150lgma200
capRl
capRh
nVolDay
voll
nTurnDay
turnl
rsl
rsh

ccass related

ncPctl
ncPcth
nTopNConc
TopNConcl
hkcPctl
hkcPcth
 */

// "id": "0836",
// "C": 20.75,
// "V": 29142800,
// "rs": 96.1,
// "ma10": "22.585",
// "ma50": "22.208",
// "ma150": "17.967",
// "ma200": "16.022",
// "maw30": "18.572",
// "t": 59852,
// "cap": 998.1,
// "ncC": 63.16,
// "hkcC": 16.72,
// "t3C": 79.88,
// "t5C": 90.82,
// "t10C": 96
