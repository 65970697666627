import React, { useEffect, useState, Component } from "react";
import {
  Accordion,
  Loader,
  Table,
  Pagination,
  Container,
  Header,
  Dropdown,
  Icon,
  Input,
  Form,
  Checkbox,
  Label,
} from "semantic-ui-react";
import _ from "lodash";
import { createStore, combineReducers } from "redux";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import {
  selectAllTopic,
  fetchTopic,
  toIdle,
} from "../../../features/topic/topicSlice";
import { useSelector, useDispatch } from "react-redux";
// import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
// import InFeedAdsWhite from "../ads/InFeedAdsWhite";
import source from "../../../mock_source";
// import PriceLine from "../charts/PriceLine2";
// import Slider from "@material-ui/core/Slider";
import { formatNumber } from "../../../util/dataFormatter";
import { Field, reduxForm } from "redux-form";
import StockMarketTable from "./StockMarketTable";
import HSIChart from "./HSIChart";

function StockMarketView() {
  return (
    <div>
      <Header as="h1" center>
        大市資訊
      </Header>
      <IndexDataSelection />
    </div>
  );
}

function IndexDataSelection() {
  const [selected, setSelected] = React.useState([]);
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleClick = (event, titleProps) => {
    const selectedIndex = selected.indexOf(titleProps.index);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, titleProps.index);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    // console.log(selected);
  };
  return (
    <Container>
      <Accordion>
        <Accordion.Title active={isSelected(0)} index={0} onClick={handleClick}>
          <Header as="h2">
            <Icon name="dropdown" />
            恒生指數及市寛
            {/* <Header.Subheader>收市價 {">"} MA 市寬</Header.Subheader> */}
          </Header>
        </Accordion.Title>
        <Accordion.Content active={isSelected(0)}>
          {isSelected(0) ? <HSIChart /> : null}
        </Accordion.Content>
        <Accordion.Title active={isSelected(1)} index={1} onClick={handleClick}>
          <Header as="h2">
            <Icon name="dropdown" />
            指數成份股表現
          </Header>
        </Accordion.Title>
        <Accordion.Content active={isSelected(1)}>
          <StockMarketTable data={data} stockSector={stockSector} />
        </Accordion.Content>
      </Accordion>
    </Container>
  );
}

// export const StockMarketTable = () => {
//   const dispatch = useDispatch();
//   const topics = useSelector(selectAllTopic);
//   const topicStatus = useSelector((state) => state.topic.status);
//   const error = useSelector((state) => state.topic.error);
//   useEffect(() => {
//     if (topicStatus === "idle") {
//       if (
//         topics.find((item) => item.topicInfo.name === "rstable2") === undefined
//       ) {
//         dispatch(fetchTopic("rstable2"));
//       }
//       if (
//         topics.find((item) => item.topicInfo.name === "stockSectorInfo") ===
//         undefined
//       ) {
//         dispatch(fetchTopic("stockSectorInfo"));
//       }
//     }
//   }, [topicStatus, topics, dispatch]);
//   let content;

//   if (topicStatus === "loading") {
//     content = (
//       <div>
//         <section className="posts-list">
//           <div
//             style={{
//               padding: "100px",
//               margin: "auto",
//               alignItems: "center",
//               height: "75vh",
//               // overflow: "auto",
//             }}
//           >
//             <Loader active inline="centered">
//               {" "}
//               Loading
//             </Loader>
//           </div>
//         </section>
//       </div>
//     );
//   } else if (topicStatus === "succeeded") {
//     const data = topics.find((item) => item.topicInfo.name === "rstable2");
//     const stockSector = topics.find(
//       (item) => item.topicInfo.name === "stockSectorInfo"
//     );
//     if (data && stockSector) {
//       content = <RSView data={data} stockSector={stockSector} />;
//     } else {
//       dispatch(toIdle({}));
//     }
//   } else if (topicStatus === "error") {
//     content = (
//       <div>
//         <section className="posts-list">
//           <div>{error}</div>
//         </section>
//       </div>
//     );
//   }
//   return <>{content}</>;
// };

export default StockMarketView;

const stockSector = {
  ind: {
    "0001": { id: "0001", i: 0, s: 0, ss: 0 },
    "0002": { id: "0002", i: 1, s: 1, ss: 1 },
    "0003": { id: "0003", i: 1, s: 1, ss: 2 },
    "0004": { id: "0004", i: 2, s: 2, ss: 3 },
    "0005": { id: "0005", i: 3, s: 3, ss: 4 },
    "0006": { id: "0006", i: 1, s: 1, ss: 1 },
    "0007": { id: "0007", i: 2, s: 2, ss: 5 },
    "0008": { id: "0008", i: 4, s: 4, ss: 6 },
    "0009": { id: "0009", i: 2, s: 2, ss: 3 },
    "0010": { id: "0010", i: 2, s: 2, ss: 5 },
    "0011": { id: "0011", i: 3, s: 3, ss: 4 },
    "0012": { id: "0012", i: 2, s: 2, ss: 3 },
    "0013": { id: "0013", i: 5, s: 5, ss: 7 },
    "0014": { id: "0014", i: 2, s: 2, ss: 5 },
    "0016": { id: "0016", i: 2, s: 2, ss: 3 },
    "0017": { id: "0017", i: 2, s: 2, ss: 3 },
    "0018": { id: "0018", i: 6, s: 6, ss: 8 },
    "0019": { id: "0019", i: 0, s: 0, ss: 0 },
    "0020": { id: "0020", i: 7, s: 7, ss: 9 },
    "0021": { id: "0021", i: 2, s: 2, ss: 3 },
    "0022": { id: "0022", i: 6, s: 8, ss: 10 },
    "0023": { id: "0023", i: 3, s: 3, ss: 4 },
    "0024": { id: "0024", i: 8, s: 9, ss: 11 },
    "0025": { id: "0025", i: 0, s: 0, ss: 0 },
    "0026": { id: "0026", i: 2, s: 2, ss: 5 },
    "0027": { id: "0027", i: 6, s: 8, ss: 12 },
    "0028": { id: "0028", i: 2, s: 2, ss: 3 },
    "0029": { id: "0029", i: 2, s: 2, ss: 5 },
    "0030": { id: "0030", i: 9, s: 10, ss: 13 },
    "0031": { id: "0031", i: 10, s: 11, ss: 14 },
    "0032": { id: "0032", i: 6, s: 12, ss: 15 },
    "0033": { id: "0033", i: 6, s: 13, ss: 16 },
    "0034": { id: "0034", i: 2, s: 2, ss: 3 },
    "0035": { id: "0035", i: 2, s: 2, ss: 3 },
    "0036": { id: "0036", i: 2, s: 2, ss: 5 },
    "0037": { id: "0037", i: 2, s: 2, ss: 5 },
    "0038": { id: "0038", i: 10, s: 11, ss: 17 },
    "0039": { id: "0039", i: 9, s: 14, ss: 18 },
    "0040": { id: "0040", i: 6, s: 13, ss: 19 },
    "0041": { id: "0041", i: 2, s: 2, ss: 5 },
    "0042": { id: "0042", i: 10, s: 11, ss: 20 },
    "0043": { id: "0043", i: 9, s: 15, ss: 21 },
    "0045": { id: "0045", i: 6, s: 8, ss: 10 },
    "0046": { id: "0046", i: 7, s: 7, ss: 22 },
    "0047": { id: "0047", i: 6, s: 8, ss: 23 },
    "0048": { id: "0048", i: 6, s: 16, ss: 24 },
    "0050": { id: "0050", i: 2, s: 2, ss: 5 },
    "0051": { id: "0051", i: 6, s: 8, ss: 10 },
    "0052": { id: "0052", i: 6, s: 8, ss: 23 },
    "0053": { id: "0053", i: 2, s: 2, ss: 3 },
    "0055": { id: "0055", i: 10, s: 17, ss: 25 },
    "0057": { id: "0057", i: 10, s: 11, ss: 20 },
    "0058": { id: "0058", i: 2, s: 18, ss: 26 },
    "0059": { id: "0059", i: 2, s: 2, ss: 3 },
    "0060": { id: "0060", i: 9, s: 10, ss: 27 },
    "0061": { id: "0061", i: 11, s: 19, ss: 28 },
    "0062": { id: "0062", i: 6, s: 8, ss: 29 },
    "0063": { id: "0063", i: 2, s: 2, ss: 5 },
    "0064": { id: "0064", i: 3, s: 20, ss: 30 },
    "0065": { id: "0065", i: 11, s: 19, ss: 28 },
    "0066": { id: "0066", i: 6, s: 8, ss: 29 },
    "0067": { id: "0067", i: 8, s: 21, ss: 31 },
    "0068": { id: "0068", i: 3, s: 20, ss: 32 },
    "0069": { id: "0069", i: 6, s: 8, ss: 10 },
    "0070": { id: "0070", i: 6, s: 8, ss: 12 },
    "0071": { id: "0071", i: 6, s: 8, ss: 33 },
    "0072": { id: "0072", i: 6, s: 6, ss: 8 },
    "0073": { id: "0073", i: 9, s: 15, ss: 34 },
    "0075": { id: "0075", i: 2, s: 2, ss: 3 },
    "0076": { id: "0076", i: 10, s: 11, ss: 20 },
    "0077": { id: "0077", i: 6, s: 8, ss: 29 },
    "0078": { id: "0078", i: 6, s: 8, ss: 10 },
    "0079": { id: "0079", i: 6, s: 12, ss: 35 },
    "0080": { id: "0080", i: 3, s: 20, ss: 32 },
    "0081": { id: "0081", i: 2, s: 2, ss: 3 },
    "0082": { id: "0082", i: 7, s: 7, ss: 9 },
    "0083": { id: "0083", i: 2, s: 2, ss: 5 },
    "0084": { id: "0084", i: 6, s: 22, ss: 36 },
    "0085": { id: "0085", i: 7, s: 23, ss: 37 },
    "0086": { id: "0086", i: 3, s: 20, ss: 38 },
    "0087": { id: "0087", i: 0, s: 0, ss: 0 },
    "0088": { id: "0088", i: 2, s: 2, ss: 3 },
    "0089": { id: "0089", i: 2, s: 2, ss: 5 },
    "0090": { id: "0090", i: 1, s: 1, ss: 39 },
    "0091": { id: "0091", i: 11, s: 24, ss: 40 },
    "0092": { id: "0092", i: 11, s: 24, ss: 40 },
    "0093": { id: "0093", i: 2, s: 2, ss: 5 },
    "0094": { id: "0094", i: 8, s: 21, ss: 41 },
    "0095": { id: "0095", i: 2, s: 2, ss: 3 },
    "0096": { id: "0096", i: 10, s: 11, ss: 20 },
    "0097": { id: "0097", i: 6, s: 22, ss: 36 },
    "0098": { id: "0098", i: 8, s: 9, ss: 42 },
    "0099": { id: "0099", i: 10, s: 11, ss: 43 },
    "0100": { id: "0100", i: 6, s: 6, ss: 44 },
    "0101": { id: "0101", i: 2, s: 2, ss: 5 },
    "0102": { id: "0102", i: 6, s: 8, ss: 12 },
    "0103": { id: "0103", i: 10, s: 11, ss: 45 },
    "0104": { id: "0104", i: 6, s: 22, ss: 36 },
    "0105": { id: "0105", i: 2, s: 2, ss: 5 },
    "0106": { id: "0106", i: 2, s: 2, ss: 3 },
    "0107": { id: "0107", i: 10, s: 25, ss: 46 },
    "0108": { id: "0108", i: 2, s: 2, ss: 47 },
    "0109": { id: "0109", i: 3, s: 20, ss: 38 },
    "0110": { id: "0110", i: 7, s: 26, ss: 48 },
    "0111": { id: "0111", i: 3, s: 20, ss: 32 },
    "0112": { id: "0112", i: 2, s: 2, ss: 3 },
    "0113": { id: "0113", i: 6, s: 27, ss: 49 },
    "0114": { id: "0114", i: 6, s: 13, ss: 16 },
    "0115": { id: "0115", i: 2, s: 2, ss: 3 },
    "0116": { id: "0116", i: 6, s: 22, ss: 36 },
    "0117": { id: "0117", i: 10, s: 11, ss: 20 },
    "0118": { id: "0118", i: 10, s: 11, ss: 20 },
    "0119": { id: "0119", i: 2, s: 2, ss: 3 },
    "0120": { id: "0120", i: 2, s: 2, ss: 3 },
    "0122": { id: "0122", i: 6, s: 27, ss: 50 },
    "0123": { id: "0123", i: 2, s: 2, ss: 3 },
    "0124": { id: "0124", i: 2, s: 2, ss: 3 },
    "0125": { id: "0125", i: 6, s: 27, ss: 49 },
    "0126": { id: "0126", i: 6, s: 8, ss: 23 },
    "0127": { id: "0127", i: 2, s: 2, ss: 5 },
    "0128": { id: "0128", i: 6, s: 22, ss: 51 },
    "0129": { id: "0129", i: 3, s: 20, ss: 32 },
    "0130": { id: "0130", i: 6, s: 27, ss: 50 },
    "0131": { id: "0131", i: 2, s: 2, ss: 3 },
    "0132": { id: "0132", i: 0, s: 0, ss: 0 },
    "0133": { id: "0133", i: 3, s: 20, ss: 32 },
    "0135": { id: "0135", i: 1, s: 1, ss: 2 },
    "0136": { id: "0136", i: 7, s: 7, ss: 52 },
    "0137": { id: "0137", i: 10, s: 25, ss: 53 },
    "0138": { id: "0138", i: 6, s: 22, ss: 54 },
    "0139": { id: "0139", i: 3, s: 20, ss: 30 },
    "0141": { id: "0141", i: 2, s: 2, ss: 5 },
    "0142": { id: "0142", i: 9, s: 10, ss: 27 },
    "0143": { id: "0143", i: 7, s: 26, ss: 48 },
    "0144": { id: "0144", i: 10, s: 25, ss: 53 },
    "0145": { id: "0145", i: 6, s: 12, ss: 35 },
    "0146": { id: "0146", i: 6, s: 27, ss: 55 },
    "0147": { id: "0147", i: 2, s: 2, ss: 3 },
    "0148": { id: "0148", i: 10, s: 11, ss: 20 },
    "0149": { id: "0149", i: 2, s: 2, ss: 3 },
    "0150": { id: "0150", i: 6, s: 6, ss: 44 },
    "0151": { id: "0151", i: 9, s: 10, ss: 27 },
    "0152": { id: "0152", i: 10, s: 25, ss: 46 },
    "0153": { id: "0153", i: 2, s: 18, ss: 56 },
    "0154": { id: "0154", i: 10, s: 11, ss: 57 },
    "0155": { id: "0155", i: 10, s: 11, ss: 58 },
    "0156": { id: "0156", i: 6, s: 8, ss: 23 },
    "0157": { id: "0157", i: 6, s: 22, ss: 36 },
    "0158": { id: "0158", i: 2, s: 2, ss: 5 },
    "0159": { id: "0159", i: 8, s: 9, ss: 11 },
    "0160": { id: "0160", i: 2, s: 2, ss: 5 },
    "0162": { id: "0162", i: 6, s: 22, ss: 59 },
    "0163": { id: "0163", i: 2, s: 2, ss: 5 },
    "0164": { id: "0164", i: 7, s: 26, ss: 48 },
    "0165": { id: "0165", i: 3, s: 20, ss: 32 },
    "0166": { id: "0166", i: 8, s: 9, ss: 60 },
    "0167": { id: "0167", i: 6, s: 13, ss: 19 },
    "0168": { id: "0168", i: 9, s: 10, ss: 61 },
    "0169": { id: "0169", i: 6, s: 8, ss: 10 },
    "0171": { id: "0171", i: 2, s: 2, ss: 5 },
    "0172": { id: "0172", i: 3, s: 20, ss: 38 },
    "0173": { id: "0173", i: 2, s: 2, ss: 3 },
    "0174": { id: "0174", i: 3, s: 20, ss: 32 },
    "0175": { id: "0175", i: 6, s: 16, ss: 62 },
    "0176": { id: "0176", i: 6, s: 13, ss: 19 },
    "0177": { id: "0177", i: 10, s: 25, ss: 46 },
    "0178": { id: "0178", i: 6, s: 22, ss: 36 },
    "0179": { id: "0179", i: 6, s: 16, ss: 24 },
    "0180": { id: "0180", i: 6, s: 13, ss: 16 },
    "0181": { id: "0181", i: 6, s: 8, ss: 10 },
    "0182": { id: "0182", i: 1, s: 1, ss: 39 },
    "0183": { id: "0183", i: 2, s: 2, ss: 3 },
    "0184": { id: "0184", i: 6, s: 8, ss: 10 },
    "0185": { id: "0185", i: 2, s: 2, ss: 3 },
    "0186": { id: "0186", i: 6, s: 13, ss: 19 },
    "0187": { id: "0187", i: 10, s: 11, ss: 20 },
    "0188": { id: "0188", i: 3, s: 20, ss: 63 },
    "0189": { id: "0189", i: 8, s: 21, ss: 31 },
    "0190": { id: "0190", i: 2, s: 2, ss: 3 },
    "0191": { id: "0191", i: 0, s: 0, ss: 0 },
    "0193": { id: "0193", i: 2, s: 2, ss: 3 },
    "0194": { id: "0194", i: 2, s: 2, ss: 5 },
    "0195": { id: "0195", i: 8, s: 9, ss: 60 },
    "0196": { id: "0196", i: 11, s: 24, ss: 64 },
    "0197": { id: "0197", i: 9, s: 10, ss: 27 },
    "0199": { id: "0199", i: 2, s: 2, ss: 3 },
    "0200": { id: "0200", i: 6, s: 8, ss: 12 },
    "0201": { id: "0201", i: 6, s: 8, ss: 10 },
    "0202": { id: "0202", i: 0, s: 0, ss: 0 },
    "0204": { id: "0204", i: 3, s: 20, ss: 32 },
    "0205": { id: "0205", i: 6, s: 6, ss: 44 },
    "0206": { id: "0206", i: 11, s: 24, ss: 64 },
    "0207": { id: "0207", i: 2, s: 2, ss: 5 },
    "0208": { id: "0208", i: 2, s: 2, ss: 3 },
    "0209": { id: "0209", i: 6, s: 13, ss: 16 },
    "0210": { id: "0210", i: 6, s: 27, ss: 65 },
    "0211": { id: "0211", i: 3, s: 20, ss: 30 },
    "0212": { id: "0212", i: 2, s: 2, ss: 5 },
    "0213": { id: "0213", i: 10, s: 11, ss: 20 },
    "0214": { id: "0214", i: 3, s: 20, ss: 32 },
    "0215": { id: "0215", i: 4, s: 4, ss: 6 },
    "0216": { id: "0216", i: 2, s: 2, ss: 3 },
    "0217": { id: "0217", i: 8, s: 9, ss: 60 },
    "0218": { id: "0218", i: 3, s: 20, ss: 30 },
    "0219": { id: "0219", i: 6, s: 8, ss: 10 },
    "0220": { id: "0220", i: 9, s: 10, ss: 66 },
    "0222": { id: "0222", i: 3, s: 28, ss: 67 },
    "0223": { id: "0223", i: 7, s: 7, ss: 52 },
    "0224": { id: "0224", i: 2, s: 2, ss: 5 },
    "0225": { id: "0225", i: 2, s: 2, ss: 5 },
    "0226": { id: "0226", i: 6, s: 8, ss: 23 },
    "0227": { id: "0227", i: 3, s: 20, ss: 30 },
    "0228": { id: "0228", i: 11, s: 24, ss: 40 },
    "0229": { id: "0229", i: 6, s: 13, ss: 68 },
    "0230": { id: "0230", i: 2, s: 2, ss: 3 },
    "0231": { id: "0231", i: 3, s: 20, ss: 30 },
    "0232": { id: "0232", i: 10, s: 11, ss: 14 },
    "0234": { id: "0234", i: 6, s: 8, ss: 33 },
    "0235": { id: "0235", i: 8, s: 9, ss: 60 },
    "0236": { id: "0236", i: 9, s: 10, ss: 61 },
    "0237": { id: "0237", i: 2, s: 2, ss: 5 },
    "0238": { id: "0238", i: 6, s: 27, ss: 50 },
    "0239": { id: "0239", i: 5, s: 5, ss: 7 },
    "0240": { id: "0240", i: 2, s: 18, ss: 69 },
    "0241": { id: "0241", i: 5, s: 5, ss: 7 },
    "0242": { id: "0242", i: 0, s: 0, ss: 0 },
    "0243": { id: "0243", i: 7, s: 23, ss: 37 },
    "0244": { id: "0244", i: 6, s: 22, ss: 59 },
    "0245": { id: "0245", i: 3, s: 20, ss: 30 },
    "0247": { id: "0247", i: 2, s: 2, ss: 5 },
    "0248": { id: "0248", i: 7, s: 26, ss: 48 },
    "0250": { id: "0250", i: 7, s: 7, ss: 22 },
    "0251": { id: "0251", i: 2, s: 2, ss: 5 },
    "0252": { id: "0252", i: 10, s: 17, ss: 25 },
    "0253": { id: "0253", i: 6, s: 8, ss: 10 },
    "0254": { id: "0254", i: 6, s: 8, ss: 33 },
    "0255": { id: "0255", i: 10, s: 11, ss: 20 },
    "0256": { id: "0256", i: 6, s: 27, ss: 70 },
    "0257": { id: "0257", i: 1, s: 1, ss: 39 },
    "0258": { id: "0258", i: 2, s: 2, ss: 3 },
    "0259": { id: "0259", i: 6, s: 13, ss: 19 },
    "0260": { id: "0260", i: 3, s: 20, ss: 38 },
    "0261": { id: "0261", i: 2, s: 2, ss: 3 },
    "0262": { id: "0262", i: 2, s: 2, ss: 3 },
    "0263": { id: "0263", i: 3, s: 20, ss: 30 },
    "0264": { id: "0264", i: 6, s: 27, ss: 49 },
    "0265": { id: "0265", i: 6, s: 8, ss: 33 },
    "0266": { id: "0266", i: 2, s: 2, ss: 5 },
    "0267": { id: "0267", i: 0, s: 0, ss: 0 },
    "0268": { id: "0268", i: 7, s: 7, ss: 9 },
    "0269": { id: "0269", i: 10, s: 25, ss: 46 },
    "0270": { id: "0270", i: 1, s: 1, ss: 71 },
    "0271": { id: "0271", i: 2, s: 2, ss: 5 },
    "0272": { id: "0272", i: 2, s: 2, ss: 3 },
    "0273": { id: "0273", i: 3, s: 20, ss: 32 },
    "0274": { id: "0274", i: 8, s: 29, ss: 72 },
    "0276": { id: "0276", i: 11, s: 19, ss: 28 },
    "0277": { id: "0277", i: 2, s: 2, ss: 5 },
    "0278": { id: "0278", i: 2, s: 2, ss: 5 },
    "0279": { id: "0279", i: 3, s: 20, ss: 30 },
    "0280": { id: "0280", i: 6, s: 27, ss: 70 },
    "0281": { id: "0281", i: 2, s: 2, ss: 3 },
    "0282": { id: "0282", i: 6, s: 6, ss: 8 },
    "0285": { id: "0285", i: 7, s: 26, ss: 48 },
    "0286": { id: "0286", i: 5, s: 30, ss: 73 },
    "0287": { id: "0287", i: 2, s: 2, ss: 5 },
    "0288": { id: "0288", i: 9, s: 15, ss: 74 },
    "0289": { id: "0289", i: 6, s: 22, ss: 59 },
    "0290": { id: "0290", i: 3, s: 20, ss: 30 },
    "0291": { id: "0291", i: 9, s: 10, ss: 61 },
    "0292": { id: "0292", i: 6, s: 8, ss: 10 },
    "0293": { id: "0293", i: 6, s: 8, ss: 75 },
    "0294": { id: "0294", i: 6, s: 27, ss: 50 },
    "0295": { id: "0295", i: 1, s: 1, ss: 39 },
    "0296": { id: "0296", i: 6, s: 8, ss: 12 },
    "0297": { id: "0297", i: 8, s: 21, ss: 76 },
    "0298": { id: "0298", i: 2, s: 2, ss: 3 },
    "0299": { id: "0299", i: 2, s: 2, ss: 3 },
    "0301": { id: "0301", i: 8, s: 21, ss: 31 },
    "0302": { id: "0302", i: 7, s: 7, ss: 9 },
    "0303": { id: "0303", i: 7, s: 26, ss: 48 },
    "0305": { id: "0305", i: 6, s: 16, ss: 24 },
    "0306": { id: "0306", i: 6, s: 8, ss: 29 },
    "0307": { id: "0307", i: 11, s: 19, ss: 28 },
    "0308": { id: "0308", i: 6, s: 8, ss: 33 },
    "0309": { id: "0309", i: 6, s: 12, ss: 35 },
    "0310": { id: "0310", i: 3, s: 20, ss: 32 },
    "0311": { id: "0311", i: 6, s: 27, ss: 50 },
    "0312": { id: "0312", i: 6, s: 22, ss: 59 },
    "0313": { id: "0313", i: 2, s: 2, ss: 3 },
    "0315": { id: "0315", i: 4, s: 4, ss: 6 },
    "0316": { id: "0316", i: 10, s: 25, ss: 53 },
    "0317": { id: "0317", i: 10, s: 11, ss: 45 },
    "0318": { id: "0318", i: 0, s: 0, ss: 0 },
    "0320": { id: "0320", i: 10, s: 11, ss: 20 },
    "0321": { id: "0321", i: 6, s: 27, ss: 55 },
    "0322": { id: "0322", i: 9, s: 10, ss: 66 },
    "0323": { id: "0323", i: 8, s: 9, ss: 11 },
    "0326": { id: "0326", i: 6, s: 6, ss: 77 },
    "0327": { id: "0327", i: 7, s: 7, ss: 52 },
    "0328": { id: "0328", i: 6, s: 13, ss: 19 },
    "0329": { id: "0329", i: 3, s: 20, ss: 30 },
    "0330": { id: "0330", i: 6, s: 27, ss: 50 },
    "0331": { id: "0331", i: 2, s: 18, ss: 56 },
    "0332": { id: "0332", i: 11, s: 24, ss: 40 },
    "0333": { id: "0333", i: 6, s: 27, ss: 50 },
    "0334": { id: "0334", i: 7, s: 26, ss: 78 },
    "0335": { id: "0335", i: 3, s: 20, ss: 38 },
    "0336": { id: "0336", i: 9, s: 10, ss: 13 },
    "0337": { id: "0337", i: 2, s: 2, ss: 3 },
    "0338": { id: "0338", i: 8, s: 21, ss: 31 },
    "0339": { id: "0339", i: 3, s: 20, ss: 32 },
    "0340": { id: "0340", i: 8, s: 29, ss: 72 },
    "0341": { id: "0341", i: 6, s: 8, ss: 23 },
    "0342": { id: "0342", i: 11, s: 24, ss: 40 },
    "0343": { id: "0343", i: 6, s: 6, ss: 8 },
    "0345": { id: "0345", i: 9, s: 10, ss: 66 },
    "0346": { id: "0346", i: 11, s: 24, ss: 40 },
    "0347": { id: "0347", i: 8, s: 9, ss: 11 },
    "0348": { id: "0348", i: 5, s: 5, ss: 79 },
    "0351": { id: "0351", i: 10, s: 25, ss: 53 },
    "0352": { id: "0352", i: 2, s: 2, ss: 80 },
    "0353": { id: "0353", i: 11, s: 24, ss: 64 },
    "0354": { id: "0354", i: 7, s: 7, ss: 22 },
    "0355": { id: "0355", i: 6, s: 8, ss: 10 },
    "0356": { id: "0356", i: 3, s: 20, ss: 32 },
    "0357": { id: "0357", i: 6, s: 8, ss: 75 },
    "0358": { id: "0358", i: 8, s: 9, ss: 81 },
    "0359": { id: "0359", i: 9, s: 10, ss: 66 },
    "0360": { id: "0360", i: 6, s: 16, ss: 24 },
    "0361": { id: "0361", i: 6, s: 13, ss: 16 },
    "0362": { id: "0362", i: 8, s: 21, ss: 31 },
    "0363": { id: "0363", i: 0, s: 0, ss: 0 },
    "0364": { id: "0364", i: 9, s: 10, ss: 66 },
    "0365": { id: "0365", i: 10, s: 11, ss: 20 },
    "0366": { id: "0366", i: 2, s: 18, ss: 26 },
    "0367": { id: "0367", i: 2, s: 2, ss: 3 },
    "0368": { id: "0368", i: 2, s: 18, ss: 56 },
    "0369": { id: "0369", i: 2, s: 2, ss: 3 },
    "0370": { id: "0370", i: 6, s: 13, ss: 19 },
    "0371": { id: "0371", i: 1, s: 1, ss: 71 },
    "0372": { id: "0372", i: 3, s: 20, ss: 32 },
    "0373": { id: "0373", i: 3, s: 20, ss: 38 },
    "0374": { id: "0374", i: 9, s: 10, ss: 27 },
    "0375": { id: "0375", i: 6, s: 27, ss: 50 },
    "0376": { id: "0376", i: 3, s: 28, ss: 67 },
    "0377": { id: "0377", i: 10, s: 17, ss: 82 },
    "0378": { id: "0378", i: 8, s: 21, ss: 31 },
    "0379": { id: "0379", i: 3, s: 20, ss: 38 },
    "0380": { id: "0380", i: 2, s: 18, ss: 26 },
    "0381": { id: "0381", i: 6, s: 13, ss: 16 },
    "0382": { id: "0382", i: 6, s: 12, ss: 15 },
    "0383": { id: "0383", i: 5, s: 30, ss: 73 },
    "0384": { id: "0384", i: 1, s: 1, ss: 2 },
    "0385": { id: "0385", i: 2, s: 18, ss: 56 },
    "0386": { id: "0386", i: 11, s: 24, ss: 40 },
    "0387": { id: "0387", i: 10, s: 11, ss: 20 },
    "0388": { id: "0388", i: 3, s: 20, ss: 63 },
    "0389": { id: "0389", i: 9, s: 10, ss: 61 },
    "0390": { id: "0390", i: 2, s: 18, ss: 69 },
    "0391": { id: "0391", i: 6, s: 6, ss: 77 },
    "0392": { id: "0392", i: 1, s: 1, ss: 2 },
    "0393": { id: "0393", i: 6, s: 27, ss: 50 },
    "0395": { id: "0395", i: 7, s: 7, ss: 52 },
    "0396": { id: "0396", i: 6, s: 13, ss: 83 },
    "0397": { id: "0397", i: 3, s: 20, ss: 38 },
    "0398": { id: "0398", i: 6, s: 22, ss: 36 },
    "0399": { id: "0399", i: 9, s: 14, ss: 84 },
    "0400": { id: "0400", i: 7, s: 7, ss: 52 },
    "0401": { id: "0401", i: 5, s: 5, ss: 7 },
    "0403": { id: "0403", i: 10, s: 17, ss: 25 },
    "0405": { id: "0405", i: "", s: "", ss: 85 },
    "0406": { id: "0406", i: 2, s: 18, ss: 56 },
    "0408": { id: "0408", i: 8, s: 21, ss: 31 },
    "0410": { id: "0410", i: 2, s: 2, ss: 5 },
    "0411": { id: "0411", i: 9, s: 15, ss: 34 },
    "0412": { id: "0412", i: 3, s: 20, ss: 38 },
    "0413": { id: "0413", i: 6, s: 13, ss: 16 },
    "0416": { id: "0416", i: 3, s: 3, ss: 4 },
    "0417": { id: "0417", i: 6, s: 22, ss: 36 },
    "0418": { id: "0418", i: 7, s: 7, ss: 9 },
    "0419": { id: "0419", i: 6, s: 8, ss: 86 },
    "0420": { id: "0420", i: 6, s: 27, ss: 55 },
    "0422": { id: "0422", i: 6, s: 16, ss: 62 },
    "0423": { id: "0423", i: 6, s: 6, ss: 8 },
    "0425": { id: "0425", i: 6, s: 16, ss: 24 },
    "0426": { id: "0426", i: 6, s: 6, ss: 8 },
    "0428": { id: "0428", i: 3, s: 20, ss: 32 },
    "0430": { id: "0430", i: 3, s: 20, ss: 32 },
    "0431": { id: "0431", i: 3, s: 20, ss: 38 },
    "0432": { id: "0432", i: 2, s: 2, ss: 3 },
    "0433": { id: "0433", i: 8, s: 9, ss: 60 },
    "0434": { id: "0434", i: 7, s: 7, ss: 9 },
    "0435": { id: "0435", i: "", s: "", ss: 85 },
    "0436": { id: "0436", i: 10, s: 11, ss: 57 },
    "0438": { id: "0438", i: 10, s: 11, ss: 58 },
    "0439": { id: "0439", i: 10, s: 11, ss: 43 },
    "0440": { id: "0440", i: 3, s: 3, ss: 4 },
    "0442": { id: "0442", i: 6, s: 27, ss: 70 },
    "0444": { id: "0444", i: 6, s: 22, ss: 36 },
    "0449": { id: "0449", i: 6, s: 13, ss: 68 },
    "0450": { id: "0450", i: 10, s: 17, ss: 25 },
    "0451": { id: "0451", i: 1, s: 1, ss: 39 },
    "0455": { id: "0455", i: 5, s: 5, ss: 7 },
    "0456": { id: "0456", i: 2, s: 2, ss: 5 },
    "0458": { id: "0458", i: 6, s: 27, ss: 50 },
    "0459": { id: "0459", i: 2, s: 2, ss: 80 },
    "0460": { id: "0460", i: 5, s: 5, ss: 7 },
    "0462": { id: "0462", i: 9, s: 10, ss: 87 },
    "0464": { id: "0464", i: 6, s: 13, ss: 68 },
    "0465": { id: "0465", i: 7, s: 7, ss: 22 },
    "0467": { id: "0467", i: 11, s: 24, ss: 40 },
    "0468": { id: "0468", i: 10, s: 17, ss: 25 },
    "0471": { id: "0471", i: 4, s: 4, ss: 88 },
    "0472": { id: "0472", i: 9, s: 10, ss: 61 },
    "0474": { id: "0474", i: 10, s: 11, ss: 17 },
    "0475": { id: "0475", i: 10, s: 11, ss: 58 },
    "0476": { id: "0476", i: 10, s: 11, ss: 45 },
    "0479": { id: "0479", i: 6, s: 16, ss: 62 },
    "0480": { id: "0480", i: 2, s: 2, ss: 3 },
    "0482": { id: "0482", i: 6, s: 13, ss: 19 },
    "0483": { id: "0483", i: 6, s: 22, ss: 51 },
    "0484": { id: "0484", i: 7, s: 7, ss: 9 },
    "0485": { id: "0485", i: 6, s: 13, ss: 19 },
    "0486": { id: "0486", i: 8, s: 9, ss: 42 },
    "0487": { id: "0487", i: 6, s: 8, ss: 33 },
    "0488": { id: "0488", i: 0, s: 0, ss: 0 },
    "0489": { id: "0489", i: 10, s: 11, ss: 45 },
    "0491": { id: "0491", i: 6, s: 6, ss: 77 },
    "0493": { id: "0493", i: 6, s: 22, ss: 89 },
    "0495": { id: "0495", i: 2, s: 2, ss: 5 },
    "0496": { id: "0496", i: 6, s: 13, ss: 83 },
    "0497": { id: "0497", i: 2, s: 2, ss: 3 },
    "0498": { id: "0498", i: 2, s: 18, ss: 56 },
    "0499": { id: "0499", i: 6, s: 12, ss: 15 },
    "0500": { id: "0500", i: 10, s: 25, ss: 90 },
    "0503": { id: "0503", i: 5, s: 5, ss: 7 },
    "0505": { id: "0505", i: 8, s: 9, ss: 81 },
    "0506": { id: "0506", i: 9, s: 10, ss: 66 },
    "0508": { id: "0508", i: 2, s: 2, ss: 3 },
    "0509": { id: "0509", i: 8, s: 21, ss: 76 },
    "0510": { id: "0510", i: 3, s: 20, ss: 30 },
    "0511": { id: "0511", i: 6, s: 6, ss: 91 },
    "0512": { id: "0512", i: 5, s: 5, ss: 7 },
    "0513": { id: "0513", i: 6, s: 27, ss: 70 },
    "0515": { id: "0515", i: 10, s: 11, ss: 20 },
    "0517": { id: "0517", i: 10, s: 17, ss: 82 },
    "0518": { id: "0518", i: 6, s: 27, ss: 50 },
    "0519": { id: "0519", i: 2, s: 2, ss: 3 },
    "0520": { id: "0520", i: 6, s: 8, ss: 23 },
    "0521": { id: "0521", i: 8, s: 9, ss: 60 },
    "0522": { id: "0522", i: 7, s: 23, ss: 37 },
    "0524": { id: "0524", i: 4, s: 4, ss: 6 },
    "0525": { id: "0525", i: 6, s: 8, ss: 29 },
    "0526": { id: "0526", i: 6, s: 22, ss: 54 },
    "0527": { id: "0527", i: 1, s: 1, ss: 39 },
    "0528": { id: "0528", i: 6, s: 27, ss: 55 },
    "0529": { id: "0529", i: 7, s: 26, ss: 48 },
    "0530": { id: "0530", i: 0, s: 0, ss: 0 },
    "0531": { id: "0531", i: 6, s: 13, ss: 83 },
    "0532": { id: "0532", i: 10, s: 11, ss: 20 },
    "0533": { id: "0533", i: 6, s: 27, ss: 50 },
    "0535": { id: "0535", i: 2, s: 2, ss: 3 },
    "0536": { id: "0536", i: 7, s: 7, ss: 52 },
    "0538": { id: "0538", i: 6, s: 8, ss: 23 },
    "0539": { id: "0539", i: 6, s: 27, ss: 55 },
    "0540": { id: "0540", i: 6, s: 27, ss: 50 },
    "0542": { id: "0542", i: 6, s: 8, ss: 10 },
    "0543": { id: "0543", i: 6, s: 22, ss: 54 },
    "0544": { id: "0544", i: 10, s: 25, ss: 90 },
    "0546": { id: "0546", i: 9, s: 10, ss: 13 },
    "0547": { id: "0547", i: 6, s: 6, ss: 77 },
    "0548": { id: "0548", i: 10, s: 25, ss: 46 },
    "0550": { id: "0550", i: 6, s: 6, ss: 44 },
    "0551": { id: "0551", i: 6, s: 27, ss: 65 },
    "0552": { id: "0552", i: 7, s: 26, ss: 48 },
    "0553": { id: "0553", i: 7, s: 26, ss: 48 },
    "0554": { id: "0554", i: 11, s: 24, ss: 64 },
    "0555": { id: "0555", i: 6, s: 8, ss: 12 },
    "0556": { id: "0556", i: 10, s: 11, ss: 57 },
    "0557": { id: "0557", i: 5, s: 30, ss: 73 },
    "0558": { id: "0558", i: 10, s: 11, ss: 20 },
    "0559": { id: "0559", i: 6, s: 8, ss: 10 },
    "0560": { id: "0560", i: 10, s: 25, ss: 53 },
    "0563": { id: "0563", i: 2, s: 2, ss: 3 },
    "0564": { id: "0564", i: 6, s: 16, ss: 24 },
    "0565": { id: "0565", i: 2, s: 2, ss: 5 },
    "0567": { id: "0567", i: 11, s: 24, ss: 40 },
    "0568": { id: "0568", i: 11, s: 24, ss: 64 },
    "0570": { id: "0570", i: 5, s: 5, ss: 79 },
    "0571": { id: "0571", i: 6, s: 6, ss: 77 },
    "0572": { id: "0572", i: 3, s: 20, ss: 32 },
    "0573": { id: "0573", i: 6, s: 8, ss: 23 },
    "0574": { id: "0574", i: 5, s: 5, ss: 7 },
    "0575": { id: "0575", i: 5, s: 5, ss: 7 },
    "0576": { id: "0576", i: 10, s: 25, ss: 46 },
    "0577": { id: "0577", i: 2, s: 18, ss: 56 },
    "0578": { id: "0578", i: 11, s: 19, ss: 28 },
    "0579": { id: "0579", i: 1, s: 1, ss: 1 },
    "0580": { id: "0580", i: 10, s: 11, ss: 20 },
    "0581": { id: "0581", i: 8, s: 9, ss: 11 },
    "0582": { id: "0582", i: 6, s: 8, ss: 10 },
    "0583": { id: "0583", i: 2, s: 2, ss: 5 },
    "0585": { id: "0585", i: 3, s: 20, ss: 30 },
    "0586": { id: "0586", i: 10, s: 11, ss: 57 },
    "0588": { id: "0588", i: 2, s: 2, ss: 3 },
    "0589": { id: "0589", i: 2, s: 18, ss: 69 },
    "0590": { id: "0590", i: 6, s: 22, ss: 36 },
    "0591": { id: "0591", i: 10, s: 11, ss: 20 },
    "0592": { id: "0592", i: 6, s: 27, ss: 50 },
    "0593": { id: "0593", i: 2, s: 2, ss: 3 },
    "0595": { id: "0595", i: 7, s: 23, ss: 37 },
    "0596": { id: "0596", i: 7, s: 7, ss: 9 },
    "0598": { id: "0598", i: 10, s: 25, ss: 53 },
    "0599": { id: "0599", i: 2, s: 18, ss: 56 },
    "0600": { id: "0600", i: 11, s: 24, ss: 64 },
    "0601": { id: "0601", i: 8, s: 9, ss: 60 },
    "0602": { id: "0602", i: 6, s: 22, ss: 59 },
    "0603": { id: "0603", i: 1, s: 1, ss: 2 },
    "0604": { id: "0604", i: 2, s: 2, ss: 3 },
    "0605": { id: "0605", i: 3, s: 20, ss: 38 },
    "0606": { id: "0606", i: 2, s: 2, ss: 47 },
    "0607": { id: "0607", i: 10, s: 11, ss: 57 },
    "0608": { id: "0608", i: 6, s: 27, ss: 50 },
    "0609": { id: "0609", i: 8, s: 21, ss: 31 },
    "0610": { id: "0610", i: 2, s: 18, ss: 69 },
    "0611": { id: "0611", i: 1, s: 1, ss: 39 },
    "0612": { id: "0612", i: 3, s: 20, ss: 32 },
    "0613": { id: "0613", i: 3, s: 20, ss: 32 },
    "0616": { id: "0616", i: 2, s: 2, ss: 5 },
    "0617": { id: "0617", i: 6, s: 8, ss: 10 },
    "0618": { id: "0618", i: 2, s: 2, ss: 3 },
    "0619": { id: "0619", i: 3, s: 20, ss: 30 },
    "0620": { id: "0620", i: 0, s: 0, ss: 0 },
    "0621": { id: "0621", i: 8, s: 29, ss: 72 },
    "0622": { id: "0622", i: 3, s: 20, ss: 30 },
    "0623": { id: "0623", i: 6, s: 6, ss: 44 },
    "0625": { id: "0625", i: "", s: "", ss: 85 },
    "0626": { id: "0626", i: 3, s: 3, ss: 4 },
    "0627": { id: "0627", i: 2, s: 2, ss: 3 },
    "0628": { id: "0628", i: 3, s: 20, ss: 38 },
    "0629": { id: "0629", i: 3, s: 20, ss: 38 },
    "0630": { id: "0630", i: 2, s: 18, ss: 56 },
    "0631": { id: "0631", i: 10, s: 11, ss: 17 },
    "0632": { id: "0632", i: 11, s: 24, ss: 40 },
    "0633": { id: "0633", i: 4, s: 4, ss: 88 },
    "0635": { id: "0635", i: 6, s: 13, ss: 16 },
    "0636": { id: "0636", i: 10, s: 25, ss: 90 },
    "0637": { id: "0637", i: 8, s: 9, ss: 60 },
    "0638": { id: "0638", i: 6, s: 13, ss: 68 },
    "0639": { id: "0639", i: 11, s: 19, ss: 28 },
    "0640": { id: "0640", i: 8, s: 21, ss: 31 },
    "0641": { id: "0641", i: 10, s: 11, ss: 20 },
    "0643": { id: "0643", i: 6, s: 27, ss: 50 },
    "0645": { id: "0645", i: 11, s: 19, ss: 28 },
    "0646": { id: "0646", i: 1, s: 1, ss: 71 },
    "0648": { id: "0648", i: 5, s: 30, ss: 92 },
    "0650": { id: "0650", i: 11, s: 24, ss: 40 },
    "0651": { id: "0651", i: 10, s: 11, ss: 17 },
    "0653": { id: "0653", i: 9, s: 14, ss: 84 },
    "0655": { id: "0655", i: 2, s: 2, ss: 5 },
    "0656": { id: "0656", i: 0, s: 0, ss: 0 },
    "0657": { id: "0657", i: 6, s: 8, ss: 23 },
    "0658": { id: "0658", i: 10, s: 11, ss: 57 },
    "0659": { id: "0659", i: 0, s: 0, ss: 0 },
    "0660": { id: "0660", i: 8, s: 21, ss: 31 },
    "0661": { id: "0661", i: 8, s: 9, ss: 81 },
    "0662": { id: "0662", i: 3, s: 28, ss: 67 },
    "0663": { id: "0663", i: 0, s: 0, ss: 0 },
    "0665": { id: "0665", i: 3, s: 20, ss: 30 },
    "0666": { id: "0666", i: 3, s: 20, ss: 32 },
    "0667": { id: "0667", i: 6, s: 12, ss: 15 },
    "0668": { id: "0668", i: 3, s: 20, ss: 38 },
    "0669": { id: "0669", i: 6, s: 13, ss: 68 },
    "0670": { id: "0670", i: 6, s: 8, ss: 75 },
    "0672": { id: "0672", i: 2, s: 2, ss: 3 },
    "0673": { id: "0673", i: 5, s: 30, ss: 92 },
    "0674": { id: "0674", i: 2, s: 2, ss: 5 },
    "0675": { id: "0675", i: 10, s: 11, ss: 20 },
    "0676": { id: "0676", i: 6, s: 27, ss: 65 },
    "0677": { id: "0677", i: 9, s: 15, ss: 34 },
    "0678": { id: "0678", i: 6, s: 8, ss: 33 },
    "0679": { id: "0679", i: 10, s: 11, ss: 20 },
    "0680": { id: "0680", i: 2, s: 2, ss: 3 },
    "0681": { id: "0681", i: 1, s: 1, ss: 2 },
    "0682": { id: "0682", i: 9, s: 15, ss: 34 },
    "0683": { id: "0683", i: 2, s: 2, ss: 3 },
    "0684": { id: "0684", i: 6, s: 13, ss: 68 },
    "0685": { id: "0685", i: 6, s: 6, ss: 8 },
    "0686": { id: "0686", i: 1, s: 1, ss: 39 },
    "0687": { id: "0687", i: 2, s: 18, ss: 69 },
    "0688": { id: "0688", i: 2, s: 2, ss: 3 },
    "0689": { id: "0689", i: 3, s: 20, ss: 38 },
    "0690": { id: "0690", i: 5, s: 5, ss: 7 },
    "0691": { id: "0691", i: 2, s: 18, ss: 26 },
    "0693": { id: "0693", i: 6, s: 22, ss: 54 },
    "0694": { id: "0694", i: 6, s: 8, ss: 75 },
    "0695": { id: "0695", i: 2, s: 18, ss: 26 },
    "0696": { id: "0696", i: 7, s: 7, ss: 22 },
    "0697": { id: "0697", i: 3, s: 20, ss: 32 },
    "0698": { id: "0698", i: 10, s: 11, ss: 43 },
    "0699": { id: "0699", i: 6, s: 8, ss: 33 },
    "0700": { id: "0700", i: 7, s: 7, ss: 52 },
    "0701": { id: "0701", i: 2, s: 18, ss: 26 },
    "0702": { id: "0702", i: 11, s: 24, ss: 40 },
    "0703": { id: "0703", i: 6, s: 8, ss: 23 },
    "0704": { id: "0704", i: 11, s: 19, ss: 28 },
    "0706": { id: "0706", i: 8, s: 21, ss: 41 },
    "0707": { id: "0707", i: 6, s: 27, ss: 55 },
    "0708": { id: "0708", i: 5, s: 30, ss: 73 },
    "0709": { id: "0709", i: 6, s: 27, ss: 50 },
    "0710": { id: "0710", i: 7, s: 26, ss: 78 },
    "0711": { id: "0711", i: 2, s: 18, ss: 69 },
    "0712": { id: "0712", i: 10, s: 11, ss: 58 },
    "0713": { id: "0713", i: 6, s: 13, ss: 83 },
    "0715": { id: "0715", i: 2, s: 2, ss: 5 },
    "0716": { id: "0716", i: 10, s: 11, ss: 45 },
    "0717": { id: "0717", i: 3, s: 20, ss: 30 },
    "0718": { id: "0718", i: 5, s: 30, ss: 73 },
    "0719": { id: "0719", i: 5, s: 5, ss: 7 },
    "0720": { id: "0720", i: 6, s: 16, ss: 62 },
    "0721": { id: "0721", i: 3, s: 20, ss: 32 },
    "0722": { id: "0722", i: 5, s: 30, ss: 73 },
    "0723": { id: "0723", i: 8, s: 21, ss: 41 },
    "0724": { id: "0724", i: 7, s: 23, ss: 37 },
    "0725": { id: "0725", i: 10, s: 11, ss: 20 },
    "0726": { id: "0726", i: 2, s: 2, ss: 3 },
    "0727": { id: "0727", i: 2, s: 2, ss: 5 },
    "0728": { id: "0728", i: 4, s: 4, ss: 6 },
    "0729": { id: "0729", i: 10, s: 11, ss: 45 },
    "0730": { id: "0730", i: 2, s: 2, ss: 5 },
    "0731": { id: "0731", i: 8, s: 21, ss: 93 },
    "0732": { id: "0732", i: 10, s: 11, ss: 43 },
    "0733": { id: "0733", i: 2, s: 2, ss: 80 },
    "0736": { id: "0736", i: 3, s: 20, ss: 38 },
    "0737": { id: "0737", i: 10, s: 25, ss: 46 },
    "0738": { id: "0738", i: 6, s: 27, ss: 65 },
    "0743": { id: "0743", i: 2, s: 18, ss: 26 },
    "0745": { id: "0745", i: 6, s: 6, ss: 44 },
    "0746": { id: "0746", i: 8, s: 21, ss: 31 },
    "0747": { id: "0747", i: 2, s: 18, ss: 56 },
    "0750": { id: "0750", i: 2, s: 18, ss: 56 },
    "0751": { id: "0751", i: 6, s: 13, ss: 19 },
    "0752": { id: "0752", i: 6, s: 6, ss: 44 },
    "0753": { id: "0753", i: 6, s: 8, ss: 75 },
    "0754": { id: "0754", i: 2, s: 2, ss: 3 },
    "0755": { id: "0755", i: 2, s: 2, ss: 3 },
    "0756": { id: "0756", i: 9, s: 10, ss: 66 },
    "0757": { id: "0757", i: 10, s: 11, ss: 58 },
    "0758": { id: "0758", i: 8, s: 9, ss: 60 },
    "0759": { id: "0759", i: 9, s: 14, ss: 18 },
    "0760": { id: "0760", i: 2, s: 2, ss: 3 },
    "0762": { id: "0762", i: 4, s: 4, ss: 6 },
    "0763": { id: "0763", i: 7, s: 26, ss: 48 },
    "0764": { id: "0764", i: 0, s: 0, ss: 0 },
    "0765": { id: "0765", i: 6, s: 13, ss: 16 },
    "0766": { id: "0766", i: 3, s: 20, ss: 38 },
    "0767": { id: "0767", i: 3, s: 20, ss: 38 },
    "0768": { id: "0768", i: 3, s: 20, ss: 32 },
    "0769": { id: "0769", i: 8, s: 9, ss: 60 },
    "0770": { id: "0770", i: 3, s: 20, ss: 32 },
    "0771": { id: "0771", i: 7, s: 7, ss: 22 },
    "0772": { id: "0772", i: 6, s: 6, ss: 8 },
    "0775": { id: "0775", i: 5, s: 5, ss: 7 },
    "0776": { id: "0776", i: 6, s: 13, ss: 83 },
    "0777": { id: "0777", i: 7, s: 7, ss: 9 },
    "0778": { id: "0778", i: "", s: "", ss: 85 },
    "0780": { id: "0780", i: 6, s: 8, ss: 33 },
    "0784": { id: "0784", i: 2, s: 18, ss: 56 },
    "0787": { id: "0787", i: 6, s: 27, ss: 50 },
    "0788": { id: "0788", i: 4, s: 4, ss: 88 },
    "0789": { id: "0789", i: 6, s: 27, ss: 70 },
    "0794": { id: "0794", i: 10, s: 17, ss: 25 },
    "0797": { id: "0797", i: 7, s: 7, ss: 9 },
    "0798": { id: "0798", i: 2, s: 2, ss: 47 },
    "0799": { id: "0799", i: 7, s: 7, ss: 9 },
    "0800": { id: "0800", i: 2, s: 2, ss: 5 },
    "0802": { id: "0802", i: 7, s: 7, ss: 9 },
    "0803": { id: "0803", i: 8, s: 9, ss: 11 },
    "0804": { id: "0804", i: 3, s: 20, ss: 30 },
    "0806": { id: "0806", i: 3, s: 20, ss: 32 },
    "0807": { id: "0807", i: 1, s: 1, ss: 71 },
    "0808": { id: "0808", i: "", s: "", ss: 85 },
    "0809": { id: "0809", i: 8, s: 21, ss: 76 },
    "0810": { id: "0810", i: 3, s: 20, ss: 32 },
    "0811": { id: "0811", i: 6, s: 6, ss: 8 },
    "0812": { id: "0812", i: 3, s: 20, ss: 32 },
    "0813": { id: "0813", i: 2, s: 2, ss: 3 },
    "0814": { id: "0814", i: 9, s: 14, ss: 18 },
    "0815": { id: "0815", i: 8, s: 29, ss: 72 },
    "0817": { id: "0817", i: 2, s: 2, ss: 3 },
    "0818": { id: "0818", i: 7, s: 7, ss: 52 },
    "0819": { id: "0819", i: 10, s: 11, ss: 58 },
    "0820": { id: "0820", i: "", s: "", ss: 85 },
    "0821": { id: "0821", i: 3, s: 20, ss: 30 },
    "0822": { id: "0822", i: 10, s: 11, ss: 20 },
    "0823": { id: "0823", i: "", s: "", ss: 85 },
    "0825": { id: "0825", i: 6, s: 22, ss: 59 },
    "0826": { id: "0826", i: 8, s: 9, ss: 11 },
    "0827": { id: "0827", i: 8, s: 21, ss: 76 },
    "0828": { id: "0828", i: 9, s: 10, ss: 61 },
    "0829": { id: "0829", i: 9, s: 10, ss: 27 },
    "0830": { id: "0830", i: 2, s: 18, ss: 56 },
    "0831": { id: "0831", i: 9, s: 14, ss: 18 },
    "0832": { id: "0832", i: 2, s: 2, ss: 3 },
    "0833": { id: "0833", i: 6, s: 13, ss: 68 },
    "0834": { id: "0834", i: 9, s: 10, ss: 27 },
    "0836": { id: "0836", i: 1, s: 1, ss: 1 },
    "0837": { id: "0837", i: 6, s: 13, ss: 83 },
    "0838": { id: "0838", i: 10, s: 11, ss: 20 },
    "0839": { id: "0839", i: 6, s: 12, ss: 15 },
    "0840": { id: "0840", i: 10, s: 11, ss: 20 },
    "0841": { id: "0841", i: 10, s: 11, ss: 58 },
    "0842": { id: "0842", i: 10, s: 11, ss: 58 },
    "0844": { id: "0844", i: 6, s: 27, ss: 50 },
    "0845": { id: "0845", i: 2, s: 2, ss: 3 },
    "0846": { id: "0846", i: 2, s: 2, ss: 3 },
    "0848": { id: "0848", i: 6, s: 22, ss: 59 },
    "0850": { id: "0850", i: 10, s: 11, ss: 20 },
    "0851": { id: "0851", i: 3, s: 20, ss: 30 },
    "0852": { id: "0852", i: 11, s: 24, ss: 40 },
    "0853": { id: "0853", i: 5, s: 30, ss: 92 },
    "0854": { id: "0854", i: 10, s: 11, ss: 20 },
    "0855": { id: "0855", i: 1, s: 1, ss: 71 },
    "0856": { id: "0856", i: 7, s: 7, ss: 22 },
    "0857": { id: "0857", i: 11, s: 24, ss: 40 },
    "0858": { id: "0858", i: 5, s: 5, ss: 7 },
    "0859": { id: "0859", i: 2, s: 2, ss: 5 },
    "0860": { id: "0860", i: 6, s: 27, ss: 70 },
    "0861": { id: "0861", i: 7, s: 7, ss: 22 },
    "0862": { id: "0862", i: 7, s: 7, ss: 22 },
    "0863": { id: "0863", i: 3, s: 20, ss: 63 },
    "0864": { id: "0864", i: 2, s: 2, ss: 5 },
    "0865": { id: "0865", i: 2, s: 2, ss: 3 },
    "0866": { id: "0866", i: 11, s: 19, ss: 28 },
    "0867": { id: "0867", i: 5, s: 5, ss: 7 },
    "0868": { id: "0868", i: 10, s: 11, ss: 20 },
    "0869": { id: "0869", i: 6, s: 13, ss: 16 },
    "0871": { id: "0871", i: 2, s: 18, ss: 69 },
    "0872": { id: "0872", i: 6, s: 16, ss: 24 },
    "0873": { id: "0873", i: 2, s: 2, ss: 47 },
    "0874": { id: "0874", i: 5, s: 5, ss: 79 },
    "0875": { id: "0875", i: 9, s: 15, ss: 34 },
    "0876": { id: "0876", i: 5, s: 30, ss: 92 },
    "0878": { id: "0878", i: 2, s: 2, ss: 5 },
    "0880": { id: "0880", i: 6, s: 8, ss: 12 },
    "0881": { id: "0881", i: 6, s: 22, ss: 54 },
    "0882": { id: "0882", i: 0, s: 0, ss: 0 },
    "0883": { id: "0883", i: 11, s: 24, ss: 40 },
    "0884": { id: "0884", i: 2, s: 2, ss: 3 },
    "0885": { id: "0885", i: 7, s: 7, ss: 9 },
    "0886": { id: "0886", i: 9, s: 10, ss: 61 },
    "0887": { id: "0887", i: 6, s: 22, ss: 36 },
    "0888": { id: "0888", i: 6, s: 6, ss: 91 },
    "0889": { id: "0889", i: 10, s: 11, ss: 20 },
    "0891": { id: "0891", i: 6, s: 22, ss: 51 },
    "0893": { id: "0893", i: 8, s: 9, ss: 11 },
    "0894": { id: "0894", i: 10, s: 11, ss: 20 },
    "0895": { id: "0895", i: 10, s: 11, ss: 57 },
    "0896": { id: "0896", i: 2, s: 18, ss: 56 },
    "0897": { id: "0897", i: 5, s: 5, ss: 79 },
    "0898": { id: "0898", i: 2, s: 2, ss: 5 },
    "0899": { id: "0899", i: 2, s: 2, ss: 5 },
    "0900": { id: "0900", i: 3, s: 20, ss: 38 },
    "0901": { id: "0901", i: 3, s: 20, ss: 32 },
    "0902": { id: "0902", i: 1, s: 1, ss: 1 },
    "0904": { id: "0904", i: 9, s: 15, ss: 34 },
    "0905": { id: "0905", i: 3, s: 20, ss: 32 },
    "0906": { id: "0906", i: 10, s: 17, ss: 25 },
    "0907": { id: "0907", i: 6, s: 27, ss: 49 },
    "0908": { id: "0908", i: 0, s: 0, ss: 0 },
    "0909": { id: "0909", i: 7, s: 7, ss: 22 },
    "0910": { id: "0910", i: 2, s: 2, ss: 3 },
    "0911": { id: "0911", i: 5, s: 5, ss: 7 },
    "0912": { id: "0912", i: 6, s: 13, ss: 19 },
    "0913": { id: "0913", i: 3, s: 20, ss: 32 },
    "0914": { id: "0914", i: 2, s: 18, ss: 26 },
    "0915": { id: "0915", i: 10, s: 17, ss: 82 },
    "0916": { id: "0916", i: 1, s: 1, ss: 39 },
    "0918": { id: "0918", i: 6, s: 27, ss: 50 },
    "0919": { id: "0919", i: 5, s: 30, ss: 73 },
    "0921": { id: "0921", i: 6, s: 13, ss: 68 },
    "0922": { id: "0922", i: 6, s: 12, ss: 35 },
    "0923": { id: "0923", i: 8, s: 21, ss: 93 },
    "0924": { id: "0924", i: 2, s: 18, ss: 56 },
    "0925": { id: "0925", i: 10, s: 25, ss: 90 },
    "0926": { id: "0926", i: 9, s: 15, ss: 34 },
    "0927": { id: "0927", i: 6, s: 13, ss: 19 },
    "0928": { id: "0928", i: 5, s: 5, ss: 94 },
    "0929": { id: "0929", i: 10, s: 11, ss: 20 },
    "0931": { id: "0931", i: 1, s: 1, ss: 39 },
    "0932": { id: "0932", i: 5, s: 5, ss: 7 },
    "0934": { id: "0934", i: 11, s: 24, ss: 64 },
    "0935": { id: "0935", i: 10, s: 25, ss: 53 },
    "0936": { id: "0936", i: 10, s: 11, ss: 17 },
    "0938": { id: "0938", i: 2, s: 2, ss: 3 },
    "0939": { id: "0939", i: 3, s: 3, ss: 4 },
    "0941": { id: "0941", i: 4, s: 4, ss: 6 },
    "0943": { id: "0943", i: 6, s: 13, ss: 83 },
    "0945": { id: "0945", i: 3, s: 28, ss: 67 },
    "0947": { id: "0947", i: 7, s: 26, ss: 48 },
    "0948": { id: "0948", i: 7, s: 26, ss: 48 },
    "0950": { id: "0950", i: 5, s: 5, ss: 7 },
    "0951": { id: "0951", i: 10, s: 11, ss: 58 },
    "0952": { id: "0952", i: 3, s: 20, ss: 30 },
    "0953": { id: "0953", i: 6, s: 6, ss: 77 },
    "0954": { id: "0954", i: 9, s: 10, ss: 13 },
    "0956": { id: "0956", i: 1, s: 1, ss: 2 },
    "0959": { id: "0959", i: 6, s: 8, ss: 12 },
    "0960": { id: "0960", i: 2, s: 2, ss: 3 },
    "0966": { id: "0966", i: 3, s: 28, ss: 67 },
    "0967": { id: "0967", i: 1, s: 1, ss: 71 },
    "0968": { id: "0968", i: 10, s: 11, ss: 58 },
    "0969": { id: "0969", i: 9, s: 15, ss: 34 },
    "0970": { id: "0970", i: 6, s: 22, ss: 54 },
    "0973": { id: "0973", i: 6, s: 22, ss: 36 },
    "0974": { id: "0974", i: 6, s: 22, ss: 59 },
    "0975": { id: "0975", i: 11, s: 19, ss: 28 },
    "0976": { id: "0976", i: 8, s: 9, ss: 81 },
    "0978": { id: "0978", i: 2, s: 2, ss: 3 },
    "0979": { id: "0979", i: 10, s: 11, ss: 58 },
    "0980": { id: "0980", i: 9, s: 14, ss: 18 },
    "0981": { id: "0981", i: 7, s: 23, ss: 37 },
    "0982": { id: "0982", i: 2, s: 2, ss: 47 },
    "0983": { id: "0983", i: 2, s: 18, ss: 56 },
    "0984": { id: "0984", i: 9, s: 14, ss: 18 },
    "0985": { id: "0985", i: 8, s: 9, ss: 81 },
    "0986": { id: "0986", i: 6, s: 27, ss: 70 },
    "0987": { id: "0987", i: 1, s: 1, ss: 39 },
    "0988": { id: "0988", i: 10, s: 25, ss: 90 },
    "0989": { id: "0989", i: 2, s: 2, ss: 3 },
    "0990": { id: "0990", i: 8, s: 21, ss: 31 },
    "0991": { id: "0991", i: 1, s: 1, ss: 1 },
    "0992": { id: "0992", i: 7, s: 26, ss: 78 },
    "0993": { id: "0993", i: 3, s: 20, ss: 32 },
    "0994": { id: "0994", i: 2, s: 18, ss: 56 },
    "0995": { id: "0995", i: 10, s: 25, ss: 46 },
    "0996": { id: "0996", i: 2, s: 2, ss: 3 },
    "0997": { id: "0997", i: 10, s: 17, ss: 82 },
    "0998": { id: "0998", i: 3, s: 3, ss: 4 },
    "0999": { id: "0999", i: 6, s: 22, ss: 51 },
    1000: { id: "1000", i: 6, s: 6, ss: 8 },
    1001: { id: "1001", i: 8, s: 9, ss: 11 },
    1002: { id: "1002", i: 10, s: 11, ss: 20 },
    1003: { id: "1003", i: 6, s: 6, ss: 77 },
    1004: { id: "1004", i: 1, s: 1, ss: 39 },
    1005: { id: "1005", i: 6, s: 13, ss: 16 },
    1007: { id: "1007", i: 6, s: 8, ss: 23 },
    1008: { id: "1008", i: 10, s: 17, ss: 25 },
    1009: { id: "1009", i: 2, s: 2, ss: 5 },
    1010: { id: "1010", i: 7, s: 23, ss: 37 },
    1011: { id: "1011", i: 5, s: 5, ss: 7 },
    1013: { id: "1013", i: 7, s: 26, ss: 48 },
    1019: { id: "1019", i: 3, s: 28, ss: 67 },
    1020: { id: "1020", i: 7, s: 7, ss: 52 },
    1022: { id: "1022", i: 7, s: 7, ss: 9 },
    1023: { id: "1023", i: 6, s: 27, ss: 49 },
    1024: { id: "1024", i: 7, s: 7, ss: 9 },
    1025: { id: "1025", i: 6, s: 27, ss: 50 },
    1026: { id: "1026", i: 1, s: 1, ss: 71 },
    1027: { id: "1027", i: 6, s: 13, ss: 83 },
    1028: { id: "1028", i: 6, s: 27, ss: 65 },
    1029: { id: "1029", i: 8, s: 9, ss: 11 },
    1030: { id: "1030", i: 2, s: 2, ss: 3 },
    1031: { id: "1031", i: 3, s: 20, ss: 30 },
    1033: { id: "1033", i: 11, s: 24, ss: 64 },
    1034: { id: "1034", i: 2, s: 18, ss: 69 },
    1036: { id: "1036", i: 2, s: 2, ss: 5 },
    1037: { id: "1037", i: 7, s: 7, ss: 22 },
    1038: { id: "1038", i: 1, s: 1, ss: 1 },
    1039: { id: "1039", i: 7, s: 7, ss: 52 },
    1041: { id: "1041", i: 3, s: 20, ss: 32 },
    1043: { id: "1043", i: 10, s: 11, ss: 58 },
    1044: { id: "1044", i: 9, s: 14, ss: 84 },
    1045: { id: "1045", i: 4, s: 4, ss: 88 },
    1046: { id: "1046", i: 6, s: 6, ss: 77 },
    1047: { id: "1047", i: 8, s: 21, ss: 31 },
    1049: { id: "1049", i: 6, s: 22, ss: 89 },
    1050: { id: "1050", i: 7, s: 26, ss: 78 },
    1051: { id: "1051", i: 3, s: 20, ss: 32 },
    1052: { id: "1052", i: 10, s: 25, ss: 46 },
    1053: { id: "1053", i: 8, s: 9, ss: 11 },
    1054: { id: "1054", i: 3, s: 20, ss: 30 },
    1055: { id: "1055", i: 6, s: 8, ss: 75 },
    1057: { id: "1057", i: 6, s: 16, ss: 24 },
    1058: { id: "1058", i: 6, s: 27, ss: 49 },
    1059: { id: "1059", i: 7, s: 7, ss: 22 },
    1060: { id: "1060", i: 6, s: 6, ss: 77 },
    1061: { id: "1061", i: 5, s: 5, ss: 7 },
    1062: { id: "1062", i: 3, s: 20, ss: 32 },
    1063: { id: "1063", i: 7, s: 26, ss: 48 },
    1064: { id: "1064", i: 2, s: 2, ss: 5 },
    1065: { id: "1065", i: 1, s: 1, ss: 71 },
    1066: { id: "1066", i: 5, s: 30, ss: 92 },
    1068: { id: "1068", i: 9, s: 15, ss: 74 },
    1069: { id: "1069", i: 8, s: 21, ss: 41 },
    1070: { id: "1070", i: 6, s: 13, ss: 19 },
    1071: { id: "1071", i: 1, s: 1, ss: 1 },
    1072: { id: "1072", i: 10, s: 11, ss: 57 },
    1073: { id: "1073", i: 3, s: 20, ss: 63 },
    1074: { id: "1074", i: 8, s: 9, ss: 11 },
    1075: { id: "1075", i: 7, s: 7, ss: 22 },
    1076: { id: "1076", i: 6, s: 8, ss: 12 },
    1079: { id: "1079", i: 7, s: 26, ss: 78 },
    1080: { id: "1080", i: 11, s: 24, ss: 64 },
    1082: { id: "1082", i: 6, s: 12, ss: 15 },
    1083: { id: "1083", i: 1, s: 1, ss: 2 },
    1084: { id: "1084", i: 9, s: 10, ss: 13 },
    1085: { id: "1085", i: 7, s: 26, ss: 48 },
    1086: { id: "1086", i: 6, s: 13, ss: 83 },
    1087: { id: "1087", i: 7, s: 7, ss: 22 },
    1088: { id: "1088", i: 11, s: 19, ss: 28 },
    1090: { id: "1090", i: 8, s: 9, ss: 11 },
    1091: { id: "1091", i: 8, s: 9, ss: 60 },
    1093: { id: "1093", i: 5, s: 5, ss: 7 },
    1094: { id: "1094", i: 7, s: 7, ss: 52 },
    1096: { id: "1096", i: 1, s: 1, ss: 2 },
    1097: { id: "1097", i: 6, s: 6, ss: 91 },
    1098: { id: "1098", i: 2, s: 2, ss: 3 },
    1099: { id: "1099", i: 5, s: 5, ss: 7 },
    1100: { id: "1100", i: 6, s: 27, ss: 49 },
    1101: { id: "1101", i: 11, s: 24, ss: 40 },
    1102: { id: "1102", i: 2, s: 18, ss: 26 },
    1103: { id: "1103", i: 8, s: 21, ss: 76 },
    1104: { id: "1104", i: 8, s: 9, ss: 11 },
    1105: { id: "1105", i: 6, s: 6, ss: 8 },
    1106: { id: "1106", i: 10, s: 17, ss: 25 },
    1107: { id: "1107", i: 2, s: 2, ss: 3 },
    1108: { id: "1108", i: 10, s: 11, ss: 20 },
    1109: { id: "1109", i: 2, s: 2, ss: 3 },
    1110: { id: "1110", i: 5, s: 5, ss: 7 },
    1111: { id: "1111", i: 3, s: 3, ss: 4 },
    1112: { id: "1112", i: 9, s: 14, ss: 84 },
    1113: { id: "1113", i: 2, s: 2, ss: 3 },
    1114: { id: "1114", i: 6, s: 16, ss: 62 },
    1115: { id: "1115", i: 9, s: 10, ss: 66 },
    1116: { id: "1116", i: 8, s: 9, ss: 11 },
    1117: { id: "1117", i: 9, s: 10, ss: 87 },
    1118: { id: "1118", i: 2, s: 18, ss: 26 },
    1119: { id: "1119", i: 7, s: 7, ss: 9 },
    1120: { id: "1120", i: 6, s: 27, ss: 49 },
    1121: { id: "1121", i: 6, s: 27, ss: 65 },
    1122: { id: "1122", i: 10, s: 11, ss: 45 },
    1123: { id: "1123", i: 6, s: 13, ss: 19 },
    1124: { id: "1124", i: 2, s: 2, ss: 5 },
    1125: { id: "1125", i: 2, s: 2, ss: 5 },
    1126: { id: "1126", i: 6, s: 13, ss: 16 },
    1127: { id: "1127", i: 10, s: 17, ss: 25 },
    1128: { id: "1128", i: 6, s: 8, ss: 12 },
    1129: { id: "1129", i: 1, s: 1, ss: 71 },
    1130: { id: "1130", i: 6, s: 16, ss: 62 },
    1131: { id: "1131", i: 11, s: 19, ss: 28 },
    1132: { id: "1132", i: 6, s: 6, ss: 77 },
    1133: { id: "1133", i: 10, s: 11, ss: 57 },
    1134: { id: "1134", i: 6, s: 27, ss: 49 },
    1137: { id: "1137", i: 7, s: 7, ss: 52 },
    1138: { id: "1138", i: 10, s: 25, ss: 53 },
    1139: { id: "1139", i: 6, s: 22, ss: 54 },
    1140: { id: "1140", i: 3, s: 20, ss: 32 },
    1141: { id: "1141", i: 3, s: 20, ss: 30 },
    1142: { id: "1142", i: 8, s: 9, ss: 60 },
    1143: { id: "1143", i: 6, s: 13, ss: 19 },
    1145: { id: "1145", i: 10, s: 25, ss: 53 },
    1146: { id: "1146", i: 6, s: 27, ss: 50 },
    1147: { id: "1147", i: 7, s: 7, ss: 22 },
    1148: { id: "1148", i: 6, s: 16, ss: 24 },
    1150: { id: "1150", i: 6, s: 27, ss: 49 },
    1152: { id: "1152", i: 9, s: 10, ss: 27 },
    1153: { id: "1153", i: 2, s: 2, ss: 47 },
    1156: { id: "1156", i: 11, s: 24, ss: 64 },
    1157: { id: "1157", i: 10, s: 11, ss: 17 },
    1158: { id: "1158", i: 6, s: 8, ss: 10 },
    1159: { id: "1159", i: 6, s: 6, ss: 77 },
    1160: { id: "1160", i: 3, s: 20, ss: 32 },
    1161: { id: "1161", i: 5, s: 30, ss: 73 },
    1162: { id: "1162", i: 2, s: 18, ss: 56 },
    1163: { id: "1163", i: 6, s: 6, ss: 44 },
    1164: { id: "1164", i: 8, s: 9, ss: 60 },
    1165: { id: "1165", i: 1, s: 1, ss: 39 },
    1166: { id: "1166", i: 10, s: 11, ss: 20 },
    1167: { id: "1167", i: 5, s: 5, ss: 94 },
    1168: { id: "1168", i: 2, s: 2, ss: 5 },
    1170: { id: "1170", i: 6, s: 27, ss: 65 },
    1171: { id: "1171", i: 11, s: 19, ss: 28 },
    1172: { id: "1172", i: 10, s: 17, ss: 25 },
    1173: { id: "1173", i: 9, s: 14, ss: 84 },
    1175: { id: "1175", i: 9, s: 10, ss: 27 },
    1176: { id: "1176", i: 2, s: 2, ss: 3 },
    1177: { id: "1177", i: 5, s: 5, ss: 7 },
    1178: { id: "1178", i: 0, s: 0, ss: 0 },
    1179: { id: "1179", i: 6, s: 8, ss: 10 },
    1180: { id: "1180", i: 6, s: 8, ss: 12 },
    1181: { id: "1181", i: 6, s: 8, ss: 23 },
    1182: { id: "1182", i: 6, s: 8, ss: 12 },
    1183: { id: "1183", i: 2, s: 18, ss: 56 },
    1184: { id: "1184", i: 10, s: 11, ss: 20 },
    1185: { id: "1185", i: 10, s: 11, ss: 57 },
    1186: { id: "1186", i: 2, s: 18, ss: 69 },
    1188: { id: "1188", i: 6, s: 16, ss: 24 },
    1189: { id: "1189", i: 6, s: 8, ss: 10 },
    1191: { id: "1191", i: 3, s: 20, ss: 32 },
    1192: { id: "1192", i: 10, s: 11, ss: 45 },
    1193: { id: "1193", i: 1, s: 1, ss: 2 },
    1194: { id: "1194", i: 8, s: 29, ss: 72 },
    1195: { id: "1195", i: 2, s: 2, ss: 3 },
    1196: { id: "1196", i: 10, s: 11, ss: 57 },
    1198: { id: "1198", i: 6, s: 13, ss: 83 },
    1199: { id: "1199", i: 10, s: 25, ss: 53 },
    1200: { id: "1200", i: 2, s: 2, ss: 80 },
    1201: { id: "1201", i: 2, s: 2, ss: 3 },
    1202: { id: "1202", i: 7, s: 26, ss: 48 },
    1203: { id: "1203", i: 8, s: 9, ss: 11 },
    1205: { id: "1205", i: 8, s: 9, ss: 60 },
    1206: { id: "1206", i: 7, s: 7, ss: 22 },
    1207: { id: "1207", i: 2, s: 2, ss: 3 },
    1208: { id: "1208", i: 8, s: 9, ss: 81 },
    1209: { id: "1209", i: 2, s: 2, ss: 47 },
    1210: { id: "1210", i: 9, s: 10, ss: 27 },
    1211: { id: "1211", i: 6, s: 16, ss: 62 },
    1212: { id: "1212", i: 6, s: 22, ss: 59 },
    1213: { id: "1213", i: 10, s: 11, ss: 20 },
    1215: { id: "1215", i: 6, s: 8, ss: 10 },
    1216: { id: "1216", i: 3, s: 3, ss: 4 },
    1217: { id: "1217", i: 3, s: 20, ss: 32 },
    1218: { id: "1218", i: 2, s: 2, ss: 3 },
    1220: { id: "1220", i: 2, s: 18, ss: 56 },
    1221: { id: "1221", i: 6, s: 8, ss: 10 },
    1222: { id: "1222", i: 2, s: 2, ss: 3 },
    1223: { id: "1223", i: 6, s: 22, ss: 51 },
    1224: { id: "1224", i: 2, s: 2, ss: 3 },
    1225: { id: "1225", i: 3, s: 20, ss: 38 },
    1226: { id: "1226", i: 3, s: 20, ss: 32 },
    1227: { id: "1227", i: 3, s: 20, ss: 32 },
    1228: { id: "1228", i: 5, s: 5, ss: 94 },
    1229: { id: "1229", i: 11, s: 19, ss: 28 },
    1230: { id: "1230", i: 9, s: 10, ss: 87 },
    1231: { id: "1231", i: 8, s: 9, ss: 11 },
    1232: { id: "1232", i: 2, s: 2, ss: 3 },
    1233: { id: "1233", i: 2, s: 2, ss: 3 },
    1234: { id: "1234", i: 6, s: 27, ss: 50 },
    1235: { id: "1235", i: 6, s: 8, ss: 33 },
    1237: { id: "1237", i: 6, s: 13, ss: 83 },
    1238: { id: "1238", i: 2, s: 2, ss: 3 },
    1239: { id: "1239", i: 10, s: 17, ss: 25 },
    1240: { id: "1240", i: 2, s: 2, ss: 3 },
    1241: { id: "1241", i: 6, s: 16, ss: 24 },
    1243: { id: "1243", i: 2, s: 2, ss: 3 },
    1245: { id: "1245", i: 6, s: 8, ss: 12 },
    1246: { id: "1246", i: 2, s: 2, ss: 3 },
    1247: { id: "1247", i: 6, s: 27, ss: 50 },
    1250: { id: "1250", i: 1, s: 1, ss: 39 },
    1251: { id: "1251", i: 11, s: 24, ss: 64 },
    1252: { id: "1252", i: 2, s: 18, ss: 26 },
    1253: { id: "1253", i: 2, s: 18, ss: 56 },
    1255: { id: "1255", i: 6, s: 22, ss: 51 },
    1257: { id: "1257", i: 1, s: 1, ss: 39 },
    1258: { id: "1258", i: 8, s: 9, ss: 81 },
    1259: { id: "1259", i: 9, s: 14, ss: 84 },
    1260: { id: "1260", i: 3, s: 20, ss: 63 },
    1262: { id: "1262", i: 9, s: 10, ss: 27 },
    1263: { id: "1263", i: 7, s: 26, ss: 78 },
    1265: { id: "1265", i: 1, s: 1, ss: 2 },
    1266: { id: "1266", i: 8, s: 9, ss: 11 },
    1268: { id: "1268", i: 6, s: 22, ss: 54 },
    1269: { id: "1269", i: 6, s: 16, ss: 24 },
    1270: { id: "1270", i: 6, s: 8, ss: 10 },
    1271: { id: "1271", i: 2, s: 18, ss: 56 },
    1272: { id: "1272", i: 10, s: 11, ss: 57 },
    1273: { id: "1273", i: 3, s: 20, ss: 38 },
    1275: { id: "1275", i: "", s: "", ss: 85 },
    1277: { id: "1277", i: 11, s: 19, ss: 28 },
    1278: { id: "1278", i: 2, s: 2, ss: 3 },
    1280: { id: "1280", i: 6, s: 22, ss: 89 },
    1281: { id: "1281", i: 1, s: 1, ss: 39 },
    1282: { id: "1282", i: 3, s: 20, ss: 30 },
    1283: { id: "1283", i: 2, s: 18, ss: 56 },
    1285: { id: "1285", i: 9, s: 10, ss: 27 },
    1286: { id: "1286", i: 10, s: 11, ss: 20 },
    1288: { id: "1288", i: 3, s: 3, ss: 4 },
    1289: { id: "1289", i: 10, s: 11, ss: 20 },
    1290: { id: "1290", i: 3, s: 20, ss: 38 },
    1292: { id: "1292", i: 10, s: 25, ss: 90 },
    1293: { id: "1293", i: 6, s: 22, ss: 54 },
    1296: { id: "1296", i: 10, s: 11, ss: 57 },
    1297: { id: "1297", i: 7, s: 7, ss: 22 },
    1298: { id: "1298", i: 5, s: 30, ss: 92 },
    1299: { id: "1299", i: 3, s: 28, ss: 67 },
    1300: { id: "1300", i: 7, s: 26, ss: 48 },
    1301: { id: "1301", i: 10, s: 11, ss: 17 },
    1302: { id: "1302", i: 5, s: 30, ss: 92 },
    1303: { id: "1303", i: 11, s: 19, ss: 28 },
    1305: { id: "1305", i: 10, s: 11, ss: 20 },
    1308: { id: "1308", i: 10, s: 25, ss: 53 },
    1310: { id: "1310", i: 4, s: 4, ss: 6 },
    1312: { id: "1312", i: 2, s: 18, ss: 26 },
    1313: { id: "1313", i: 2, s: 18, ss: 26 },
    1314: { id: "1314", i: 6, s: 8, ss: 23 },
    1315: { id: "1315", i: 2, s: 18, ss: 56 },
    1316: { id: "1316", i: 6, s: 16, ss: 24 },
    1317: { id: "1317", i: 6, s: 12, ss: 15 },
    1319: { id: "1319", i: 3, s: 20, ss: 38 },
    1321: { id: "1321", i: 2, s: 2, ss: 3 },
    1323: { id: "1323", i: 0, s: 0, ss: 0 },
    1326: { id: "1326", i: 6, s: 6, ss: 77 },
    1327: { id: "1327", i: 6, s: 27, ss: 70 },
    1328: { id: "1328", i: 6, s: 12, ss: 35 },
    1329: { id: "1329", i: 2, s: 2, ss: 3 },
    1330: { id: "1330", i: 10, s: 11, ss: 57 },
    1332: { id: "1332", i: 10, s: 17, ss: 25 },
    1333: { id: "1333", i: 8, s: 9, ss: 42 },
    1335: { id: "1335", i: 1, s: 1, ss: 1 },
    1336: { id: "1336", i: 3, s: 28, ss: 67 },
    1337: { id: "1337", i: 7, s: 26, ss: 78 },
    1338: { id: "1338", i: 9, s: 14, ss: 84 },
    1339: { id: "1339", i: 3, s: 28, ss: 67 },
    1340: { id: "1340", i: 9, s: 15, ss: 74 },
    1341: { id: "1341", i: 10, s: 11, ss: 17 },
    1343: { id: "1343", i: 2, s: 18, ss: 69 },
    1345: { id: "1345", i: 5, s: 5, ss: 7 },
    1346: { id: "1346", i: 6, s: 27, ss: 50 },
    1347: { id: "1347", i: 7, s: 23, ss: 37 },
    1348: { id: "1348", i: 6, s: 13, ss: 16 },
    1349: { id: "1349", i: 5, s: 5, ss: 94 },
    1351: { id: "1351", i: 6, s: 6, ss: 44 },
    1355: { id: "1355", i: 6, s: 8, ss: 10 },
    1357: { id: "1357", i: 7, s: 7, ss: 52 },
    1358: { id: "1358", i: 5, s: 30, ss: 92 },
    1359: { id: "1359", i: 3, s: 20, ss: 32 },
    1360: { id: "1360", i: 6, s: 6, ss: 44 },
    1361: { id: "1361", i: 6, s: 27, ss: 65 },
    1362: { id: "1362", i: 7, s: 26, ss: 48 },
    1363: { id: "1363", i: 1, s: 1, ss: 71 },
    1365: { id: "1365", i: 6, s: 16, ss: 62 },
    1366: { id: "1366", i: 10, s: 11, ss: 20 },
    1367: { id: "1367", i: 6, s: 22, ss: 51 },
    1368: { id: "1368", i: 6, s: 27, ss: 65 },
    1370: { id: "1370", i: 8, s: 9, ss: 11 },
    1371: { id: "1371", i: 6, s: 8, ss: 12 },
    1372: { id: "1372", i: 2, s: 18, ss: 69 },
    1373: { id: "1373", i: 6, s: 22, ss: 89 },
    1375: { id: "1375", i: 3, s: 20, ss: 30 },
    1376: { id: "1376", i: 2, s: 18, ss: 56 },
    1378: { id: "1378", i: 8, s: 9, ss: 42 },
    1379: { id: "1379", i: 2, s: 2, ss: 5 },
    1380: { id: "1380", i: 8, s: 9, ss: 60 },
    1381: { id: "1381", i: 1, s: 1, ss: 39 },
    1382: { id: "1382", i: 6, s: 27, ss: 55 },
    1383: { id: "1383", i: 6, s: 8, ss: 33 },
    1385: { id: "1385", i: 7, s: 23, ss: 37 },
    1386: { id: "1386", i: 6, s: 22, ss: 36 },
    1387: { id: "1387", i: 2, s: 2, ss: 5 },
    1388: { id: "1388", i: 6, s: 27, ss: 50 },
    1389: { id: "1389", i: 9, s: 10, ss: 61 },
    1393: { id: "1393", i: 11, s: 19, ss: 28 },
    1395: { id: "1395", i: 1, s: 1, ss: 71 },
    1396: { id: "1396", i: 2, s: 2, ss: 3 },
    1397: { id: "1397", i: 6, s: 12, ss: 35 },
    1398: { id: "1398", i: 3, s: 3, ss: 4 },
    1399: { id: "1399", i: 10, s: 11, ss: 58 },
    1400: { id: "1400", i: 6, s: 27, ss: 55 },
    1401: { id: "1401", i: 10, s: 11, ss: 43 },
    1402: { id: "1402", i: 7, s: 7, ss: 22 },
    1408: { id: "1408", i: 2, s: 18, ss: 56 },
    1410: { id: "1410", i: 7, s: 7, ss: 22 },
    1412: { id: "1412", i: 10, s: 17, ss: 25 },
    1413: { id: "1413", i: 2, s: 18, ss: 69 },
    1415: { id: "1415", i: 10, s: 11, ss: 43 },
    1416: { id: "1416", i: 2, s: 18, ss: 56 },
    1417: { id: "1417", i: 2, s: 2, ss: 47 },
    1418: { id: "1418", i: 6, s: 13, ss: 83 },
    1419: { id: "1419", i: 5, s: 30, ss: 73 },
    1420: { id: "1420", i: 2, s: 18, ss: 56 },
    1421: { id: "1421", i: 2, s: 18, ss: 69 },
    1425: { id: "1425", i: 6, s: 27, ss: 50 },
    1426: { id: "1426", i: "", s: "", ss: 85 },
    1427: { id: "1427", i: 2, s: 18, ss: 56 },
    1428: { id: "1428", i: 3, s: 20, ss: 30 },
    1429: { id: "1429", i: 2, s: 18, ss: 56 },
    1430: { id: "1430", i: 1, s: 1, ss: 2 },
    1431: { id: "1431", i: 9, s: 10, ss: 87 },
    1432: { id: "1432", i: 9, s: 10, ss: 87 },
    1433: { id: "1433", i: 10, s: 17, ss: 25 },
    1439: { id: "1439", i: 10, s: 17, ss: 25 },
    1440: { id: "1440", i: 6, s: 27, ss: 55 },
    1442: { id: "1442", i: 10, s: 25, ss: 90 },
    1443: { id: "1443", i: 6, s: 8, ss: 23 },
    1446: { id: "1446", i: 9, s: 10, ss: 66 },
    1447: { id: "1447", i: 2, s: 18, ss: 56 },
    1448: { id: "1448", i: 6, s: 12, ss: 35 },
    1449: { id: "1449", i: 6, s: 12, ss: 15 },
    1450: { id: "1450", i: 7, s: 7, ss: 22 },
    1451: { id: "1451", i: 10, s: 17, ss: 25 },
    1452: { id: "1452", i: 10, s: 11, ss: 57 },
    1455: { id: "1455", i: 6, s: 13, ss: 68 },
    1456: { id: "1456", i: 3, s: 20, ss: 30 },
    1458: { id: "1458", i: 9, s: 10, ss: 27 },
    1459: { id: "1459", i: 2, s: 18, ss: 56 },
    1460: { id: "1460", i: 7, s: 7, ss: 22 },
    1461: { id: "1461", i: 3, s: 20, ss: 30 },
    1463: { id: "1463", i: 6, s: 12, ss: 35 },
    1466: { id: "1466", i: 6, s: 22, ss: 36 },
    1468: { id: "1468", i: 0, s: 0, ss: 0 },
    1469: { id: "1469", i: 3, s: 20, ss: 30 },
    1470: { id: "1470", i: 6, s: 22, ss: 36 },
    1472: { id: "1472", i: 2, s: 18, ss: 69 },
    1473: { id: "1473", i: 7, s: 26, ss: 48 },
    1475: { id: "1475", i: 9, s: 10, ss: 27 },
    1476: { id: "1476", i: 3, s: 20, ss: 30 },
    1477: { id: "1477", i: 5, s: 5, ss: 94 },
    1478: { id: "1478", i: 10, s: 11, ss: 43 },
    1480: { id: "1480", i: 10, s: 11, ss: 20 },
    1481: { id: "1481", i: 8, s: 21, ss: 93 },
    1483: { id: "1483", i: 6, s: 12, ss: 35 },
    1486: { id: "1486", i: 2, s: 18, ss: 56 },
    1488: { id: "1488", i: 6, s: 8, ss: 23 },
    1490: { id: "1490", i: 6, s: 6, ss: 44 },
    1492: { id: "1492", i: 9, s: 10, ss: 87 },
    1495: { id: "1495", i: 2, s: 18, ss: 56 },
    1496: { id: "1496", i: 2, s: 18, ss: 69 },
    1498: { id: "1498", i: 5, s: 5, ss: 7 },
    1499: { id: "1499", i: 2, s: 18, ss: 56 },
    1500: { id: "1500", i: 2, s: 18, ss: 56 },
    1501: { id: "1501", i: 5, s: 30, ss: 92 },
    1502: { id: "1502", i: 2, s: 2, ss: 47 },
    1503: { id: "1503", i: "", s: "", ss: 85 },
    1508: { id: "1508", i: 3, s: 28, ss: 67 },
    1509: { id: "1509", i: 5, s: 30, ss: 73 },
    1513: { id: "1513", i: 5, s: 5, ss: 7 },
    1515: { id: "1515", i: 5, s: 30, ss: 73 },
    1516: { id: "1516", i: 2, s: 2, ss: 47 },
    1518: { id: "1518", i: 5, s: 30, ss: 73 },
    1520: { id: "1520", i: 6, s: 27, ss: 50 },
    1521: { id: "1521", i: 5, s: 5, ss: 94 },
    1522: { id: "1522", i: 7, s: 7, ss: 22 },
    1523: { id: "1523", i: 7, s: 26, ss: 78 },
    1525: { id: "1525", i: 6, s: 12, ss: 15 },
    1526: { id: "1526", i: 5, s: 30, ss: 73 },
    1527: { id: "1527", i: 10, s: 11, ss: 57 },
    1528: { id: "1528", i: 2, s: 2, ss: 5 },
    1529: { id: "1529", i: 10, s: 25, ss: 90 },
    1530: { id: "1530", i: 5, s: 5, ss: 7 },
    1532: { id: "1532", i: 6, s: 27, ss: 50 },
    1533: { id: "1533", i: 9, s: 10, ss: 87 },
    1536: { id: "1536", i: 10, s: 11, ss: 20 },
    1538: { id: "1538", i: 2, s: 2, ss: 47 },
    1539: { id: "1539", i: 10, s: 11, ss: 57 },
    1540: { id: "1540", i: 10, s: 17, ss: 25 },
    1542: { id: "1542", i: 1, s: 1, ss: 71 },
    1543: { id: "1543", i: 3, s: 20, ss: 38 },
    1545: { id: "1545", i: 6, s: 22, ss: 89 },
    1546: { id: "1546", i: 2, s: 18, ss: 56 },
    1547: { id: "1547", i: 2, s: 18, ss: 56 },
    1548: { id: "1548", i: 5, s: 5, ss: 94 },
    1549: { id: "1549", i: 10, s: 25, ss: 53 },
    1551: { id: "1551", i: 3, s: 3, ss: 4 },
    1552: { id: "1552", i: 2, s: 18, ss: 56 },
    1553: { id: "1553", i: 8, s: 9, ss: 11 },
    1555: { id: "1555", i: 11, s: 24, ss: 40 },
    1556: { id: "1556", i: 2, s: 18, ss: 56 },
    1557: { id: "1557", i: 2, s: 18, ss: 69 },
    1558: { id: "1558", i: 5, s: 5, ss: 7 },
    1559: { id: "1559", i: 2, s: 18, ss: 69 },
    1560: { id: "1560", i: 2, s: 2, ss: 3 },
    1561: { id: "1561", i: 8, s: 21, ss: 31 },
    1563: { id: "1563", i: 3, s: 20, ss: 38 },
    1565: { id: "1565", i: 6, s: 12, ss: 15 },
    1566: { id: "1566", i: 6, s: 8, ss: 86 },
    1568: { id: "1568", i: 2, s: 18, ss: 56 },
    1569: { id: "1569", i: 6, s: 12, ss: 15 },
    1570: { id: "1570", i: 2, s: 2, ss: 3 },
    1571: { id: "1571", i: 6, s: 16, ss: 24 },
    1572: { id: "1572", i: 3, s: 20, ss: 38 },
    1573: { id: "1573", i: 11, s: 19, ss: 28 },
    1575: { id: "1575", i: 6, s: 13, ss: 83 },
    1576: { id: "1576", i: 10, s: 25, ss: 46 },
    1577: { id: "1577", i: 3, s: 20, ss: 38 },
    1578: { id: "1578", i: 3, s: 3, ss: 4 },
    1579: { id: "1579", i: 9, s: 10, ss: 13 },
    1580: { id: "1580", i: 8, s: 21, ss: 41 },
    1581: { id: "1581", i: 2, s: 18, ss: 56 },
    1582: { id: "1582", i: 2, s: 18, ss: 56 },
    1583: { id: "1583", i: 9, s: 10, ss: 27 },
    1585: { id: "1585", i: 6, s: 16, ss: 62 },
    1586: { id: "1586", i: 6, s: 12, ss: 35 },
    1587: { id: "1587", i: 9, s: 10, ss: 13 },
    1588: { id: "1588", i: 7, s: 7, ss: 9 },
    1589: { id: "1589", i: 2, s: 2, ss: 5 },
    1591: { id: "1591", i: 2, s: 18, ss: 56 },
    1592: { id: "1592", i: 2, s: 18, ss: 26 },
    1593: { id: "1593", i: 6, s: 12, ss: 15 },
    1596: { id: "1596", i: 10, s: 11, ss: 45 },
    1597: { id: "1597", i: 10, s: 11, ss: 57 },
    1598: { id: "1598", i: 6, s: 12, ss: 15 },
    1599: { id: "1599", i: 2, s: 18, ss: 56 },
    1600: { id: "1600", i: 1, s: 1, ss: 2 },
    1601: { id: "1601", i: 3, s: 20, ss: 38 },
    1606: { id: "1606", i: 3, s: 20, ss: 38 },
    1608: { id: "1608", i: 1, s: 1, ss: 1 },
    1609: { id: "1609", i: 2, s: 18, ss: 56 },
    1610: { id: "1610", i: 9, s: 15, ss: 74 },
    1611: { id: "1611", i: 10, s: 11, ss: 20 },
    1612: { id: "1612", i: 5, s: 30, ss: 92 },
    1613: { id: "1613", i: 7, s: 7, ss: 22 },
    1615: { id: "1615", i: 2, s: 18, ss: 56 },
    1616: { id: "1616", i: 6, s: 6, ss: 77 },
    1617: { id: "1617", i: 7, s: 26, ss: 48 },
    1618: { id: "1618", i: 2, s: 18, ss: 69 },
    1620: { id: "1620", i: 6, s: 8, ss: 33 },
    1621: { id: "1621", i: 11, s: 24, ss: 40 },
    1622: { id: "1622", i: 2, s: 2, ss: 3 },
    1623: { id: "1623", i: 11, s: 24, ss: 64 },
    1626: { id: "1626", i: 10, s: 17, ss: 25 },
    1627: { id: "1627", i: 2, s: 18, ss: 56 },
    1628: { id: "1628", i: 2, s: 2, ss: 3 },
    1629: { id: "1629", i: 10, s: 17, ss: 25 },
    1630: { id: "1630", i: 2, s: 18, ss: 56 },
    1631: { id: "1631", i: 10, s: 17, ss: 25 },
    1632: { id: "1632", i: 6, s: 8, ss: 23 },
    1633: { id: "1633", i: 2, s: 18, ss: 56 },
    1635: { id: "1635", i: 1, s: 1, ss: 2 },
    1636: { id: "1636", i: 8, s: 9, ss: 81 },
    1637: { id: "1637", i: 2, s: 18, ss: 56 },
    1638: { id: "1638", i: 2, s: 2, ss: 3 },
    1639: { id: "1639", i: 10, s: 11, ss: 20 },
    1640: { id: "1640", i: 6, s: 6, ss: 44 },
    1642: { id: "1642", i: 2, s: 18, ss: 56 },
    1643: { id: "1643", i: 5, s: 5, ss: 79 },
    1645: { id: "1645", i: 10, s: 11, ss: 20 },
    1647: { id: "1647", i: 2, s: 18, ss: 56 },
    1649: { id: "1649", i: 2, s: 18, ss: 69 },
    1650: { id: "1650", i: 6, s: 12, ss: 35 },
    1651: { id: "1651", i: 10, s: 11, ss: 20 },
    1652: { id: "1652", i: 5, s: 5, ss: 7 },
    1653: { id: "1653", i: 2, s: 18, ss: 26 },
    1655: { id: "1655", i: 6, s: 8, ss: 12 },
    1656: { id: "1656", i: 10, s: 11, ss: 20 },
    1657: { id: "1657", i: 6, s: 27, ss: 50 },
    1658: { id: "1658", i: 3, s: 3, ss: 4 },
    1660: { id: "1660", i: 10, s: 11, ss: 17 },
    1661: { id: "1661", i: 6, s: 8, ss: 86 },
    1662: { id: "1662", i: 2, s: 18, ss: 69 },
    1663: { id: "1663", i: 2, s: 2, ss: 3 },
    1665: { id: "1665", i: 7, s: 7, ss: 22 },
    1666: { id: "1666", i: 5, s: 5, ss: 79 },
    1667: { id: "1667", i: 2, s: 18, ss: 56 },
    1668: { id: "1668", i: 2, s: 2, ss: 3 },
    1669: { id: "1669", i: 3, s: 20, ss: 38 },
    1671: { id: "1671", i: 1, s: 1, ss: 1 },
    1672: { id: "1672", i: 5, s: 5, ss: 94 },
    1673: { id: "1673", i: 10, s: 11, ss: 20 },
    1675: { id: "1675", i: 7, s: 7, ss: 9 },
    1676: { id: "1676", i: 9, s: 10, ss: 27 },
    1678: { id: "1678", i: 6, s: 13, ss: 83 },
    1679: { id: "1679", i: 7, s: 7, ss: 9 },
    1680: { id: "1680", i: 6, s: 8, ss: 12 },
    1681: { id: "1681", i: 5, s: 5, ss: 7 },
    1682: { id: "1682", i: 6, s: 27, ss: 50 },
    1683: { id: "1683", i: 2, s: 18, ss: 56 },
    1685: { id: "1685", i: 10, s: 11, ss: 57 },
    1686: { id: "1686", i: 7, s: 7, ss: 22 },
    1689: { id: "1689", i: 10, s: 17, ss: 25 },
    1690: { id: "1690", i: 2, s: 18, ss: 56 },
    1691: { id: "1691", i: 6, s: 13, ss: 68 },
    1692: { id: "1692", i: 6, s: 13, ss: 68 },
    1693: { id: "1693", i: 2, s: 18, ss: 56 },
    1695: { id: "1695", i: 9, s: 10, ss: 13 },
    1696: { id: "1696", i: 5, s: 30, ss: 92 },
    1697: { id: "1697", i: 3, s: 20, ss: 32 },
    1699: { id: "1699", i: 9, s: 15, ss: 74 },
    1701: { id: "1701", i: 6, s: 8, ss: 33 },
    1702: { id: "1702", i: 8, s: 21, ss: 76 },
    1703: { id: "1703", i: 6, s: 8, ss: 23 },
    1705: { id: "1705", i: 9, s: 10, ss: 27 },
    1706: { id: "1706", i: 2, s: 18, ss: 69 },
    1707: { id: "1707", i: 2, s: 18, ss: 69 },
    1708: { id: "1708", i: 7, s: 7, ss: 22 },
    1709: { id: "1709", i: 3, s: 20, ss: 30 },
    1710: { id: "1710", i: 10, s: 11, ss: 20 },
    1711: { id: "1711", i: 6, s: 22, ss: 89 },
    1712: { id: "1712", i: 8, s: 29, ss: 72 },
    1713: { id: "1713", i: 1, s: 1, ss: 1 },
    1715: { id: "1715", i: 6, s: 13, ss: 83 },
    1716: { id: "1716", i: 6, s: 6, ss: 44 },
    1717: { id: "1717", i: 9, s: 10, ss: 87 },
    1718: { id: "1718", i: 2, s: 18, ss: 69 },
    1719: { id: "1719", i: 10, s: 17, ss: 82 },
    1720: { id: "1720", i: 7, s: 26, ss: 48 },
    1721: { id: "1721", i: 8, s: 9, ss: 11 },
    1722: { id: "1722", i: 2, s: 18, ss: 56 },
    1723: { id: "1723", i: 6, s: 22, ss: 59 },
    1725: { id: "1725", i: 10, s: 11, ss: 20 },
    1726: { id: "1726", i: 5, s: 30, ss: 92 },
    1727: { id: "1727", i: 2, s: 18, ss: 56 },
    1728: { id: "1728", i: 6, s: 22, ss: 54 },
    1729: { id: "1729", i: 7, s: 26, ss: 48 },
    1730: { id: "1730", i: 2, s: 2, ss: 5 },
    1731: { id: "1731", i: 6, s: 27, ss: 49 },
    1732: { id: "1732", i: 10, s: 25, ss: 53 },
    1733: { id: "1733", i: 11, s: 19, ss: 28 },
    1735: { id: "1735", i: 2, s: 18, ss: 56 },
    1736: { id: "1736", i: 7, s: 7, ss: 52 },
    1737: { id: "1737", i: 10, s: 25, ss: 90 },
    1738: { id: "1738", i: 11, s: 19, ss: 28 },
    1739: { id: "1739", i: 7, s: 7, ss: 52 },
    1740: { id: "1740", i: 6, s: 6, ss: 77 },
    1741: { id: "1741", i: 2, s: 18, ss: 56 },
    1742: { id: "1742", i: 2, s: 18, ss: 56 },
    1743: { id: "1743", i: 10, s: 11, ss: 20 },
    1745: { id: "1745", i: 7, s: 7, ss: 9 },
    1746: { id: "1746", i: 2, s: 18, ss: 56 },
    1747: { id: "1747", i: 6, s: 13, ss: 19 },
    1748: { id: "1748", i: 10, s: 25, ss: 53 },
    1749: { id: "1749", i: 6, s: 27, ss: 50 },
    1750: { id: "1750", i: 10, s: 11, ss: 20 },
    1751: { id: "1751", i: 2, s: 18, ss: 56 },
    1752: { id: "1752", i: 6, s: 12, ss: 15 },
    1753: { id: "1753", i: 6, s: 6, ss: 44 },
    1755: { id: "1755", i: 2, s: 2, ss: 47 },
    1756: { id: "1756", i: 6, s: 12, ss: 15 },
    1757: { id: "1757", i: 2, s: 18, ss: 69 },
    1758: { id: "1758", i: 6, s: 12, ss: 15 },
    1759: { id: "1759", i: 1, s: 1, ss: 2 },
    1760: { id: "1760", i: 6, s: 16, ss: 24 },
    1761: { id: "1761", i: 6, s: 6, ss: 44 },
    1762: { id: "1762", i: 6, s: 6, ss: 44 },
    1763: { id: "1763", i: 5, s: 5, ss: 7 },
    1765: { id: "1765", i: 6, s: 12, ss: 15 },
    1766: { id: "1766", i: 10, s: 11, ss: 45 },
    1767: { id: "1767", i: 9, s: 10, ss: 27 },
    1769: { id: "1769", i: 6, s: 12, ss: 15 },
    1771: { id: "1771", i: 6, s: 22, ss: 54 },
    1772: { id: "1772", i: 8, s: 9, ss: 60 },
    1773: { id: "1773", i: 6, s: 12, ss: 15 },
    1775: { id: "1775", i: 6, s: 12, ss: 15 },
    1776: { id: "1776", i: 3, s: 20, ss: 30 },
    1777: { id: "1777", i: 2, s: 2, ss: 3 },
    1778: { id: "1778", i: 2, s: 2, ss: 47 },
    1780: { id: "1780", i: 2, s: 18, ss: 56 },
    1781: { id: "1781", i: 6, s: 13, ss: 83 },
    1782: { id: "1782", i: 7, s: 7, ss: 22 },
    1783: { id: "1783", i: 2, s: 18, ss: 56 },
    1785: { id: "1785", i: 10, s: 25, ss: 46 },
    1786: { id: "1786", i: 10, s: 11, ss: 17 },
    1787: { id: "1787", i: 8, s: 29, ss: 72 },
    1788: { id: "1788", i: 3, s: 20, ss: 30 },
    1789: { id: "1789", i: 5, s: 30, ss: 92 },
    1790: { id: "1790", i: 1, s: 1, ss: 71 },
    1792: { id: "1792", i: 6, s: 13, ss: 16 },
    1793: { id: "1793", i: 2, s: 18, ss: 56 },
    1795: { id: "1795", i: 6, s: 27, ss: 55 },
    1796: { id: "1796", i: 2, s: 18, ss: 56 },
    1797: { id: "1797", i: 6, s: 12, ss: 15 },
    1798: { id: "1798", i: 1, s: 1, ss: 39 },
    1799: { id: "1799", i: 1, s: 1, ss: 39 },
    1800: { id: "1800", i: 2, s: 18, ss: 69 },
    1801: { id: "1801", i: 5, s: 5, ss: 7 },
    1802: { id: "1802", i: 2, s: 18, ss: 56 },
    1803: { id: "1803", i: 6, s: 8, ss: 86 },
    1806: { id: "1806", i: 7, s: 7, ss: 52 },
    1808: { id: "1808", i: 7, s: 7, ss: 22 },
    1809: { id: "1809", i: 6, s: 16, ss: 24 },
    1810: { id: "1810", i: 7, s: 26, ss: 48 },
    1811: { id: "1811", i: 1, s: 1, ss: 39 },
    1812: { id: "1812", i: 8, s: 21, ss: 93 },
    1813: { id: "1813", i: 2, s: 2, ss: 3 },
    1815: { id: "1815", i: 6, s: 22, ss: 36 },
    1816: { id: "1816", i: 1, s: 1, ss: 1 },
    1817: { id: "1817", i: 6, s: 22, ss: 51 },
    1818: { id: "1818", i: 8, s: 29, ss: 72 },
    1820: { id: "1820", i: 10, s: 17, ss: 25 },
    1821: { id: "1821", i: 3, s: 20, ss: 32 },
    1822: { id: "1822", i: 8, s: 21, ss: 41 },
    1823: { id: "1823", i: 10, s: 25, ss: 46 },
    1825: { id: "1825", i: 6, s: 27, ss: 50 },
    1826: { id: "1826", i: 2, s: 18, ss: 56 },
    1827: { id: "1827", i: 5, s: 30, ss: 73 },
    1829: { id: "1829", i: 2, s: 18, ss: 69 },
    1830: { id: "1830", i: 5, s: 30, ss: 73 },
    1831: { id: "1831", i: 6, s: 6, ss: 44 },
    1832: { id: "1832", i: 6, s: 8, ss: 10 },
    1833: { id: "1833", i: 5, s: 30, ss: 73 },
    1835: { id: "1835", i: 3, s: 20, ss: 32 },
    1836: { id: "1836", i: 6, s: 27, ss: 65 },
    1837: { id: "1837", i: 9, s: 10, ss: 27 },
    1838: { id: "1838", i: 2, s: 2, ss: 3 },
    1839: { id: "1839", i: 10, s: 11, ss: 45 },
    1841: { id: "1841", i: 10, s: 17, ss: 25 },
    1842: { id: "1842", i: 6, s: 27, ss: 49 },
    1843: { id: "1843", i: 6, s: 8, ss: 23 },
    1845: { id: "1845", i: 1, s: 1, ss: 39 },
    1846: { id: "1846", i: 5, s: 30, ss: 73 },
    1847: { id: "1847", i: 2, s: 18, ss: 26 },
    1848: { id: "1848", i: 6, s: 8, ss: 75 },
    1849: { id: "1849", i: 6, s: 6, ss: 44 },
    1850: { id: "1850", i: 2, s: 18, ss: 56 },
    1851: { id: "1851", i: 6, s: 12, ss: 15 },
    1853: { id: "1853", i: 1, s: 1, ss: 39 },
    1854: { id: "1854", i: 9, s: 15, ss: 34 },
    1855: { id: "1855", i: 2, s: 18, ss: 56 },
    1856: { id: "1856", i: 6, s: 27, ss: 70 },
    1857: { id: "1857", i: 1, s: 1, ss: 71 },
    1858: { id: "1858", i: 5, s: 30, ss: 92 },
    1859: { id: "1859", i: 6, s: 6, ss: 91 },
    1860: { id: "1860", i: 6, s: 6, ss: 44 },
    1861: { id: "1861", i: 8, s: 21, ss: 31 },
    1862: { id: "1862", i: 2, s: 2, ss: 3 },
    1863: { id: "1863", i: 8, s: 21, ss: 31 },
    1865: { id: "1865", i: 2, s: 18, ss: 69 },
    1866: { id: "1866", i: 8, s: 21, ss: 76 },
    1867: { id: "1867", i: 2, s: 18, ss: 56 },
    1868: { id: "1868", i: 6, s: 13, ss: 83 },
    1869: { id: "1869", i: 6, s: 8, ss: 23 },
    1870: { id: "1870", i: 2, s: 18, ss: 56 },
    1871: { id: "1871", i: 6, s: 12, ss: 15 },
    1872: { id: "1872", i: 6, s: 22, ss: 54 },
    1873: { id: "1873", i: 5, s: 5, ss: 94 },
    1875: { id: "1875", i: 5, s: 5, ss: 94 },
    1876: { id: "1876", i: 9, s: 10, ss: 61 },
    1877: { id: "1877", i: 5, s: 5, ss: 94 },
    1878: { id: "1878", i: 11, s: 19, ss: 28 },
    1881: { id: "1881", i: "", s: "", ss: 85 },
    1882: { id: "1882", i: 10, s: 11, ss: 20 },
    1883: { id: "1883", i: 4, s: 4, ss: 6 },
    1884: { id: "1884", i: 10, s: 17, ss: 25 },
    1885: { id: "1885", i: 8, s: 21, ss: 41 },
    1888: { id: "1888", i: 10, s: 11, ss: 20 },
    1889: { id: "1889", i: 5, s: 5, ss: 7 },
    1890: { id: "1890", i: 6, s: 12, ss: 15 },
    1891: { id: "1891", i: 8, s: 9, ss: 60 },
    1894: { id: "1894", i: 2, s: 18, ss: 26 },
    1895: { id: "1895", i: 2, s: 2, ss: 47 },
    1896: { id: "1896", i: 6, s: 6, ss: 77 },
    1897: { id: "1897", i: 2, s: 18, ss: 56 },
    1898: { id: "1898", i: 11, s: 19, ss: 28 },
    1899: { id: "1899", i: 10, s: 11, ss: 45 },
    1900: { id: "1900", i: 7, s: 7, ss: 22 },
    1901: { id: "1901", i: 6, s: 8, ss: 33 },
    1902: { id: "1902", i: 2, s: 2, ss: 3 },
    1903: { id: "1903", i: 10, s: 25, ss: 53 },
    1905: { id: "1905", i: 3, s: 20, ss: 38 },
    1906: { id: "1906", i: 6, s: 27, ss: 50 },
    1907: { id: "1907", i: 8, s: 21, ss: 31 },
    1908: { id: "1908", i: 2, s: 2, ss: 3 },
    1909: { id: "1909", i: 7, s: 7, ss: 9 },
    1910: { id: "1910", i: 6, s: 27, ss: 49 },
    1911: { id: "1911", i: 3, s: 20, ss: 32 },
    1912: { id: "1912", i: 7, s: 23, ss: 37 },
    1913: { id: "1913", i: 6, s: 27, ss: 49 },
    1915: { id: "1915", i: 3, s: 20, ss: 38 },
    1916: { id: "1916", i: 3, s: 3, ss: 4 },
    1917: { id: "1917", i: 6, s: 6, ss: 44 },
    1918: { id: "1918", i: 2, s: 2, ss: 3 },
    1919: { id: "1919", i: 10, s: 25, ss: 53 },
    1920: { id: "1920", i: 2, s: 18, ss: 56 },
    1921: { id: "1921", i: 11, s: 24, ss: 64 },
    1922: { id: "1922", i: 2, s: 2, ss: 47 },
    1925: { id: "1925", i: 6, s: 13, ss: 83 },
    1927: { id: "1927", i: 9, s: 10, ss: 27 },
    1928: { id: "1928", i: 6, s: 8, ss: 12 },
    1929: { id: "1929", i: 6, s: 22, ss: 36 },
    1930: { id: "1930", i: 10, s: 11, ss: 20 },
    1931: { id: "1931", i: 5, s: 30, ss: 92 },
    1932: { id: "1932", i: 8, s: 21, ss: 31 },
    1933: { id: "1933", i: 7, s: 7, ss: 9 },
    1935: { id: "1935", i: 6, s: 12, ss: 15 },
    1936: { id: "1936", i: 9, s: 15, ss: 21 },
    1937: { id: "1937", i: 2, s: 18, ss: 26 },
    1938: { id: "1938", i: 11, s: 24, ss: 64 },
    1939: { id: "1939", i: 6, s: 12, ss: 35 },
    1940: { id: "1940", i: 11, s: 24, ss: 40 },
    1941: { id: "1941", i: 2, s: 2, ss: 47 },
    1942: { id: "1942", i: 6, s: 22, ss: 36 },
    1943: { id: "1943", i: 2, s: 18, ss: 56 },
    1945: { id: "1945", i: 3, s: 20, ss: 63 },
    1948: { id: "1948", i: 6, s: 6, ss: 44 },
    1949: { id: "1949", i: 7, s: 7, ss: 22 },
    1950: { id: "1950", i: 8, s: 21, ss: 31 },
    1951: { id: "1951", i: 5, s: 5, ss: 94 },
    1952: { id: "1952", i: 5, s: 5, ss: 94 },
    1953: { id: "1953", i: 2, s: 18, ss: 56 },
    1955: { id: "1955", i: 6, s: 12, ss: 35 },
    1957: { id: "1957", i: 6, s: 27, ss: 50 },
    1958: { id: "1958", i: 6, s: 16, ss: 62 },
    1959: { id: "1959", i: 6, s: 22, ss: 54 },
    1960: { id: "1960", i: 2, s: 18, ss: 69 },
    1961: { id: "1961", i: 7, s: 7, ss: 9 },
    1962: { id: "1962", i: 6, s: 27, ss: 49 },
    1963: { id: "1963", i: 3, s: 3, ss: 4 },
    1965: { id: "1965", i: 2, s: 2, ss: 47 },
    1966: { id: "1966", i: 2, s: 2, ss: 3 },
    1967: { id: "1967", i: 10, s: 11, ss: 20 },
    1968: { id: "1968", i: 6, s: 27, ss: 55 },
    1969: { id: "1969", i: 6, s: 12, ss: 15 },
    1970: { id: "1970", i: 6, s: 6, ss: 77 },
    1971: { id: "1971", i: 2, s: 2, ss: 47 },
    1972: { id: "1972", i: 2, s: 2, ss: 5 },
    1975: { id: "1975", i: 10, s: 17, ss: 25 },
    1977: { id: "1977", i: 2, s: 18, ss: 56 },
    1978: { id: "1978", i: 6, s: 8, ss: 23 },
    1979: { id: "1979", i: 10, s: 11, ss: 43 },
    1980: { id: "1980", i: 7, s: 7, ss: 9 },
    1981: { id: "1981", i: 6, s: 6, ss: 77 },
    1982: { id: "1982", i: 6, s: 27, ss: 50 },
    1983: { id: "1983", i: 3, s: 3, ss: 4 },
    1985: { id: "1985", i: 7, s: 7, ss: 22 },
    1986: { id: "1986", i: 8, s: 21, ss: 31 },
    1987: { id: "1987", i: 2, s: 18, ss: 69 },
    1988: { id: "1988", i: 3, s: 3, ss: 4 },
    1989: { id: "1989", i: 6, s: 12, ss: 35 },
    1991: { id: "1991", i: 10, s: 11, ss: 20 },
    1992: { id: "1992", i: 6, s: 8, ss: 10 },
    1993: { id: "1993", i: 6, s: 6, ss: 44 },
    1995: { id: "1995", i: 2, s: 2, ss: 47 },
    1996: { id: "1996", i: 2, s: 2, ss: 3 },
    1997: { id: "1997", i: 2, s: 2, ss: 5 },
    1999: { id: "1999", i: 6, s: 13, ss: 83 },
    2000: { id: "2000", i: 7, s: 26, ss: 48 },
    2001: { id: "2001", i: 6, s: 12, ss: 15 },
    2002: { id: "2002", i: 8, s: 21, ss: 93 },
    2003: { id: "2003", i: 3, s: 20, ss: 38 },
    2005: { id: "2005", i: 5, s: 5, ss: 7 },
    2006: { id: "2006", i: 6, s: 8, ss: 10 },
    2007: { id: "2007", i: 2, s: 2, ss: 3 },
    2008: { id: "2008", i: 6, s: 6, ss: 91 },
    2009: { id: "2009", i: 2, s: 18, ss: 26 },
    2011: { id: "2011", i: 6, s: 27, ss: 49 },
    2012: { id: "2012", i: 11, s: 24, ss: 40 },
    2013: { id: "2013", i: 6, s: 6, ss: 44 },
    2014: { id: "2014", i: 6, s: 13, ss: 68 },
    2015: { id: "2015", i: 6, s: 16, ss: 62 },
    2016: { id: "2016", i: 3, s: 3, ss: 4 },
    2017: { id: "2017", i: 2, s: 18, ss: 56 },
    2018: { id: "2018", i: 10, s: 11, ss: 43 },
    2019: { id: "2019", i: 2, s: 2, ss: 3 },
    2020: { id: "2020", i: 6, s: 27, ss: 50 },
    2022: { id: "2022", i: 7, s: 7, ss: 9 },
    2023: { id: "2023", i: 6, s: 13, ss: 83 },
    2025: { id: "2025", i: 6, s: 16, ss: 24 },
    2028: { id: "2028", i: 7, s: 26, ss: 78 },
    2030: { id: "2030", i: 6, s: 27, ss: 50 },
    2031: { id: "2031", i: 5, s: 5, ss: 7 },
    2033: { id: "2033", i: 6, s: 27, ss: 70 },
    2038: { id: "2038", i: 7, s: 26, ss: 48 },
    2039: { id: "2039", i: 10, s: 11, ss: 45 },
    2048: { id: "2048", i: 2, s: 2, ss: 80 },
    2051: { id: "2051", i: 3, s: 20, ss: 38 },
    2057: { id: "2057", i: 10, s: 25, ss: 90 },
    2060: { id: "2060", i: 2, s: 18, ss: 26 },
    2066: { id: "2066", i: 3, s: 3, ss: 4 },
    2068: { id: "2068", i: 2, s: 18, ss: 69 },
    2078: { id: "2078", i: 8, s: 9, ss: 42 },
    2080: { id: "2080", i: 2, s: 2, ss: 47 },
    2083: { id: "2083", i: 6, s: 13, ss: 83 },
    2086: { id: "2086", i: 7, s: 26, ss: 78 },
    2088: { id: "2088", i: 2, s: 2, ss: 3 },
    2096: { id: "2096", i: 5, s: 5, ss: 7 },
    2098: { id: "2098", i: 10, s: 17, ss: 82 },
    2099: { id: "2099", i: 8, s: 29, ss: 72 },
    2100: { id: "2100", i: 7, s: 7, ss: 9 },
    2101: { id: "2101", i: 7, s: 7, ss: 52 },
    2102: { id: "2102", i: 10, s: 11, ss: 17 },
    2103: { id: "2103", i: 2, s: 2, ss: 3 },
    2107: { id: "2107", i: 2, s: 2, ss: 47 },
    2108: { id: "2108", i: 6, s: 8, ss: 23 },
    2110: { id: "2110", i: 2, s: 18, ss: 69 },
    2111: { id: "2111", i: 6, s: 27, ss: 55 },
    2112: { id: "2112", i: 8, s: 9, ss: 11 },
    2113: { id: "2113", i: 2, s: 18, ss: 56 },
    2115: { id: "2115", i: 2, s: 18, ss: 56 },
    2116: { id: "2116", i: 8, s: 21, ss: 31 },
    2117: { id: "2117", i: 2, s: 2, ss: 3 },
    2118: { id: "2118", i: 2, s: 2, ss: 3 },
    2119: { id: "2119", i: 9, s: 10, ss: 66 },
    2120: { id: "2120", i: 5, s: 30, ss: 73 },
    2121: { id: "2121", i: 7, s: 7, ss: 9 },
    2122: { id: "2122", i: 6, s: 22, ss: 36 },
    2123: { id: "2123", i: 6, s: 27, ss: 55 },
    2125: { id: "2125", i: 6, s: 6, ss: 77 },
    2126: { id: "2126", i: 5, s: 5, ss: 94 },
    2127: { id: "2127", i: 6, s: 13, ss: 83 },
    2128: { id: "2128", i: 2, s: 18, ss: 26 },
    2129: { id: "2129", i: 10, s: 25, ss: 90 },
    2130: { id: "2130", i: 10, s: 25, ss: 90 },
    2131: { id: "2131", i: 6, s: 6, ss: 44 },
    2132: { id: "2132", i: 2, s: 18, ss: 69 },
    2133: { id: "2133", i: 8, s: 9, ss: 60 },
    2135: { id: "2135", i: 5, s: 30, ss: 73 },
    2136: { id: "2136", i: 6, s: 22, ss: 59 },
    2137: { id: "2137", i: 5, s: 5, ss: 94 },
    2138: { id: "2138", i: 5, s: 30, ss: 73 },
    2139: { id: "2139", i: 3, s: 3, ss: 4 },
    2142: { id: "2142", i: 5, s: 5, ss: 94 },
    2146: { id: "2146", i: 2, s: 2, ss: 47 },
    2148: { id: "2148", i: 6, s: 13, ss: 68 },
    2150: { id: "2150", i: 6, s: 8, ss: 23 },
    2153: { id: "2153", i: 10, s: 11, ss: 17 },
    2155: { id: "2155", i: 10, s: 11, ss: 17 },
    2156: { id: "2156", i: 2, s: 2, ss: 47 },
    2158: { id: "2158", i: 5, s: 30, ss: 73 },
    2159: { id: "2159", i: 5, s: 30, ss: 73 },
    2160: { id: "2160", i: 5, s: 30, ss: 92 },
    2161: { id: "2161", i: 5, s: 5, ss: 79 },
    2162: { id: "2162", i: 5, s: 5, ss: 94 },
    2163: { id: "2163", i: 2, s: 18, ss: 26 },
    2165: { id: "2165", i: 2, s: 2, ss: 47 },
    2166: { id: "2166", i: 7, s: 23, ss: 37 },
    2168: { id: "2168", i: 2, s: 2, ss: 47 },
    2169: { id: "2169", i: 10, s: 25, ss: 46 },
    2170: { id: "2170", i: 5, s: 5, ss: 94 },
    2171: { id: "2171", i: 5, s: 5, ss: 94 },
    2175: { id: "2175", i: 6, s: 12, ss: 15 },
    2176: { id: "2176", i: 7, s: 7, ss: 22 },
    2177: { id: "2177", i: 7, s: 7, ss: 52 },
    2178: { id: "2178", i: 11, s: 24, ss: 64 },
    2180: { id: "2180", i: 6, s: 12, ss: 35 },
    2181: { id: "2181", i: 5, s: 5, ss: 94 },
    2182: { id: "2182", i: 9, s: 10, ss: 27 },
    2183: { id: "2183", i: 2, s: 2, ss: 3 },
    2185: { id: "2185", i: 5, s: 30, ss: 92 },
    2186: { id: "2186", i: 5, s: 5, ss: 7 },
    2187: { id: "2187", i: 2, s: 18, ss: 26 },
    2188: { id: "2188", i: 10, s: 11, ss: 57 },
    2189: { id: "2189", i: 6, s: 12, ss: 35 },
    2190: { id: "2190", i: 5, s: 30, ss: 92 },
    2191: { id: "2191", i: "", s: "", ss: 85 },
    2192: { id: "2192", i: 5, s: 30, ss: 73 },
    2193: { id: "2193", i: 2, s: 18, ss: 69 },
    2195: { id: "2195", i: 2, s: 18, ss: 56 },
    2196: { id: "2196", i: 5, s: 5, ss: 7 },
    2197: { id: "2197", i: 5, s: 5, ss: 94 },
    2198: { id: "2198", i: 8, s: 21, ss: 31 },
    2199: { id: "2199", i: 6, s: 27, ss: 50 },
    2202: { id: "2202", i: 2, s: 2, ss: 3 },
    2203: { id: "2203", i: 7, s: 23, ss: 37 },
    2205: { id: "2205", i: 2, s: 2, ss: 47 },
    2207: { id: "2207", i: 2, s: 2, ss: 47 },
    2208: { id: "2208", i: 10, s: 11, ss: 57 },
    2209: { id: "2209", i: 7, s: 7, ss: 52 },
    2210: { id: "2210", i: 2, s: 2, ss: 47 },
    2211: { id: "2211", i: 5, s: 5, ss: 7 },
    2212: { id: "2212", i: 8, s: 9, ss: 60 },
    2213: { id: "2213", i: 6, s: 22, ss: 59 },
    2215: { id: "2215", i: 2, s: 2, ss: 47 },
    2216: { id: "2216", i: 5, s: 30, ss: 92 },
    2217: { id: "2217", i: 6, s: 8, ss: 23 },
    2218: { id: "2218", i: 9, s: 10, ss: 66 },
    2219: { id: "2219", i: 5, s: 30, ss: 73 },
    2221: { id: "2221", i: 2, s: 18, ss: 69 },
    2222: { id: "2222", i: 6, s: 13, ss: 83 },
    2223: { id: "2223", i: 6, s: 13, ss: 83 },
    2225: { id: "2225", i: 6, s: 12, ss: 35 },
    2226: { id: "2226", i: 9, s: 10, ss: 13 },
    2227: { id: "2227", i: 2, s: 18, ss: 56 },
    2228: { id: "2228", i: 6, s: 27, ss: 55 },
    2230: { id: "2230", i: 6, s: 6, ss: 77 },
    2231: { id: "2231", i: 2, s: 2, ss: 3 },
    2232: { id: "2232", i: 6, s: 27, ss: 50 },
    2233: { id: "2233", i: 2, s: 18, ss: 26 },
    2235: { id: "2235", i: 5, s: 30, ss: 92 },
    2236: { id: "2236", i: 11, s: 24, ss: 64 },
    2238: { id: "2238", i: 6, s: 16, ss: 62 },
    2239: { id: "2239", i: 7, s: 26, ss: 48 },
    2250: { id: "2250", i: 7, s: 7, ss: 52 },
    2251: { id: "2251", i: 5, s: 30, ss: 92 },
    2252: { id: "2252", i: 5, s: 30, ss: 92 },
    2255: { id: "2255", i: 6, s: 8, ss: 86 },
    2256: { id: "2256", i: 5, s: 5, ss: 94 },
    2257: { id: "2257", i: 5, s: 5, ss: 94 },
    2258: { id: "2258", i: 2, s: 18, ss: 69 },
    2260: { id: "2260", i: 10, s: 11, ss: 20 },
    2262: { id: "2262", i: 2, s: 18, ss: 56 },
    2263: { id: "2263", i: 3, s: 20, ss: 30 },
    2265: { id: "2265", i: 10, s: 11, ss: 57 },
    2266: { id: "2266", i: 2, s: 18, ss: 56 },
    2269: { id: "2269", i: 5, s: 5, ss: 94 },
    2270: { id: "2270", i: 2, s: 2, ss: 47 },
    2273: { id: "2273", i: 5, s: 30, ss: 73 },
    2276: { id: "2276", i: 10, s: 11, ss: 20 },
    2278: { id: "2278", i: 2, s: 2, ss: 3 },
    2279: { id: "2279", i: 5, s: 30, ss: 73 },
    2280: { id: "2280", i: 7, s: 7, ss: 52 },
    2281: { id: "2281", i: 1, s: 1, ss: 71 },
    2282: { id: "2282", i: 6, s: 8, ss: 12 },
    2283: { id: "2283", i: 10, s: 11, ss: 20 },
    2285: { id: "2285", i: 6, s: 13, ss: 68 },
    2286: { id: "2286", i: 2, s: 2, ss: 3 },
    2288: { id: "2288", i: 2, s: 2, ss: 3 },
    2289: { id: "2289", i: 5, s: 5, ss: 7 },
    2292: { id: "2292", i: 2, s: 2, ss: 5 },
    2293: { id: "2293", i: 5, s: 30, ss: 73 },
    2295: { id: "2295", i: 2, s: 18, ss: 69 },
    2296: { id: "2296", i: 2, s: 18, ss: 56 },
    2298: { id: "2298", i: 6, s: 27, ss: 50 },
    2299: { id: "2299", i: 6, s: 27, ss: 55 },
    2300: { id: "2300", i: 10, s: 17, ss: 25 },
    2302: { id: "2302", i: 8, s: 9, ss: 60 },
    2307: { id: "2307", i: 6, s: 27, ss: 55 },
    2308: { id: "2308", i: 7, s: 26, ss: 78 },
    2309: { id: "2309", i: 6, s: 8, ss: 86 },
    2310: { id: "2310", i: 2, s: 2, ss: 47 },
    2312: { id: "2312", i: 3, s: 20, ss: 32 },
    2313: { id: "2313", i: 6, s: 27, ss: 50 },
    2314: { id: "2314", i: 8, s: 21, ss: 93 },
    2317: { id: "2317", i: 9, s: 10, ss: 13 },
    2318: { id: "2318", i: 3, s: 28, ss: 67 },
    2319: { id: "2319", i: 9, s: 10, ss: 87 },
    2320: { id: "2320", i: 8, s: 21, ss: 93 },
    2322: { id: "2322", i: 8, s: 21, ss: 31 },
    2323: { id: "2323", i: 10, s: 11, ss: 20 },
    2324: { id: "2324", i: 3, s: 20, ss: 32 },
    2326: { id: "2326", i: 8, s: 9, ss: 60 },
    2327: { id: "2327", i: 0, s: 0, ss: 0 },
    2328: { id: "2328", i: 3, s: 28, ss: 67 },
    2329: { id: "2329", i: 2, s: 2, ss: 3 },
    2330: { id: "2330", i: 2, s: 2, ss: 3 },
    2331: { id: "2331", i: 6, s: 27, ss: 50 },
    2333: { id: "2333", i: 6, s: 16, ss: 62 },
    2336: { id: "2336", i: 8, s: 9, ss: 81 },
    2337: { id: "2337", i: 1, s: 1, ss: 2 },
    2338: { id: "2338", i: 10, s: 11, ss: 45 },
    2339: { id: "2339", i: 6, s: 16, ss: 24 },
    2340: { id: "2340", i: 2, s: 2, ss: 47 },
    2341: { id: "2341", i: 8, s: 21, ss: 31 },
    2342: { id: "2342", i: 7, s: 26, ss: 48 },
    2343: { id: "2343", i: 10, s: 25, ss: 53 },
    2346: { id: "2346", i: 10, s: 11, ss: 20 },
    2348: { id: "2348", i: 5, s: 5, ss: 7 },
    2349: { id: "2349", i: 2, s: 2, ss: 5 },
    2355: { id: "2355", i: 2, s: 18, ss: 56 },
    2356: { id: "2356", i: 3, s: 3, ss: 4 },
    2357: { id: "2357", i: 10, s: 11, ss: 14 },
    2358: { id: "2358", i: 6, s: 13, ss: 19 },
    2359: { id: "2359", i: 5, s: 5, ss: 94 },
    2360: { id: "2360", i: 9, s: 14, ss: 18 },
    2362: { id: "2362", i: 8, s: 9, ss: 60 },
    2363: { id: "2363", i: 7, s: 26, ss: 78 },
    2366: { id: "2366", i: 6, s: 6, ss: 77 },
    2368: { id: "2368", i: 6, s: 27, ss: 50 },
    2369: { id: "2369", i: 7, s: 26, ss: 48 },
    2371: { id: "2371", i: 6, s: 12, ss: 15 },
    2377: { id: "2377", i: 10, s: 11, ss: 57 },
    2378: { id: "2378", i: 3, s: 28, ss: 67 },
    2379: { id: "2379", i: 2, s: 2, ss: 5 },
    2380: { id: "2380", i: 1, s: 1, ss: 1 },
    2381: { id: "2381", i: 6, s: 13, ss: 68 },
    2382: { id: "2382", i: 10, s: 11, ss: 43 },
    2383: { id: "2383", i: 6, s: 6, ss: 8 },
    2386: { id: "2386", i: 11, s: 24, ss: 64 },
    2388: { id: "2388", i: 3, s: 3, ss: 4 },
    2389: { id: "2389", i: 5, s: 30, ss: 73 },
    2393: { id: "2393", i: 5, s: 30, ss: 92 },
    2398: { id: "2398", i: 10, s: 11, ss: 20 },
    2399: { id: "2399", i: 6, s: 27, ss: 50 },
    2400: { id: "2400", i: 7, s: 7, ss: 9 },
    2448: { id: "2448", i: 2, s: 18, ss: 56 },
    2488: { id: "2488", i: 6, s: 16, ss: 24 },
    2500: { id: "2500", i: 5, s: 30, ss: 92 },
    2518: { id: "2518", i: 7, s: 7, ss: 52 },
    2528: { id: "2528", i: 6, s: 22, ss: 51 },
    2552: { id: "2552", i: 5, s: 5, ss: 94 },
    2558: { id: "2558", i: 3, s: 3, ss: 4 },
    2588: { id: "2588", i: 6, s: 8, ss: 75 },
    2599: { id: "2599", i: 2, s: 2, ss: 3 },
    2600: { id: "2600", i: 8, s: 9, ss: 42 },
    2601: { id: "2601", i: 3, s: 28, ss: 67 },
    2606: { id: "2606", i: 2, s: 2, ss: 47 },
    2607: { id: "2607", i: 5, s: 5, ss: 7 },
    2608: { id: "2608", i: 2, s: 2, ss: 3 },
    2611: { id: "2611", i: 3, s: 20, ss: 30 },
    2616: { id: "2616", i: 5, s: 5, ss: 94 },
    2618: { id: "2618", i: 10, s: 17, ss: 82 },
    2623: { id: "2623", i: 8, s: 9, ss: 11 },
    2628: { id: "2628", i: 3, s: 28, ss: 67 },
    2633: { id: "2633", i: 5, s: 5, ss: 7 },
    2638: { id: "2638", i: 1, s: 1, ss: 1 },
    2660: { id: "2660", i: 7, s: 7, ss: 9 },
    2662: { id: "2662", i: 6, s: 12, ss: 35 },
    2663: { id: "2663", i: 2, s: 18, ss: 56 },
    2666: { id: "2666", i: 3, s: 20, ss: 38 },
    2668: { id: "2668", i: 10, s: 17, ss: 82 },
    2669: { id: "2669", i: 2, s: 2, ss: 47 },
    2678: { id: "2678", i: 6, s: 27, ss: 55 },
    2680: { id: "2680", i: 3, s: 20, ss: 63 },
    2682: { id: "2682", i: 10, s: 25, ss: 53 },
    2683: { id: "2683", i: 6, s: 27, ss: 49 },
    2686: { id: "2686", i: 11, s: 24, ss: 40 },
    2688: { id: "2688", i: 1, s: 1, ss: 2 },
    2689: { id: "2689", i: 8, s: 21, ss: 93 },
    2696: { id: "2696", i: 5, s: 5, ss: 94 },
    2698: { id: "2698", i: 6, s: 27, ss: 55 },
    2699: { id: "2699", i: 2, s: 2, ss: 3 },
    2700: { id: "2700", i: 5, s: 30, ss: 73 },
    2708: { id: "2708", i: 7, s: 7, ss: 22 },
    2718: { id: "2718", i: 3, s: 20, ss: 38 },
    2722: { id: "2722", i: 0, s: 0, ss: 0 },
    2727: { id: "2727", i: 10, s: 11, ss: 57 },
    2728: { id: "2728", i: 11, s: 24, ss: 40 },
    2738: { id: "2738", i: 8, s: 9, ss: 11 },
    2768: { id: "2768", i: 2, s: 2, ss: 3 },
    2772: { id: "2772", i: 2, s: 2, ss: 3 },
    2777: { id: "2777", i: 2, s: 2, ss: 3 },
    2778: { id: "2778", i: "", s: "", ss: 85 },
    2779: { id: "2779", i: 6, s: 12, ss: 15 },
    2788: { id: "2788", i: 10, s: 11, ss: 20 },
    2789: { id: "2789", i: 2, s: 18, ss: 56 },
    2798: { id: "2798", i: 11, s: 19, ss: 28 },
    2799: { id: "2799", i: 3, s: 20, ss: 32 },
    2800: { id: "2800", i: "", s: "", ss: 85 },
    2801: { id: "2801", i: "", s: "", ss: 85 },
    2802: { id: "2802", i: "", s: "", ss: 85 },
    2803: { id: "2803", i: "", s: "", ss: 85 },
    2804: { id: "2804", i: "", s: "", ss: 85 },
    2806: { id: "2806", i: "", s: "", ss: 85 },
    2807: { id: "2807", i: "", s: "", ss: 85 },
    2809: { id: "2809", i: "", s: "", ss: 85 },
    2810: { id: "2810", i: "", s: "", ss: 85 },
    2811: { id: "2811", i: "", s: "", ss: 85 },
    2812: { id: "2812", i: "", s: "", ss: 85 },
    2813: { id: "2813", i: "", s: "", ss: 85 },
    2814: { id: "2814", i: "", s: "", ss: 85 },
    2817: { id: "2817", i: "", s: "", ss: 85 },
    2819: { id: "2819", i: "", s: "", ss: 85 },
    2820: { id: "2820", i: "", s: "", ss: 85 },
    2821: { id: "2821", i: "", s: "", ss: 85 },
    2822: { id: "2822", i: "", s: "", ss: 85 },
    2823: { id: "2823", i: "", s: "", ss: 85 },
    2824: { id: "2824", i: "", s: "", ss: 85 },
    2825: { id: "2825", i: "", s: "", ss: 85 },
    2826: { id: "2826", i: "", s: "", ss: 85 },
    2827: { id: "2827", i: "", s: "", ss: 85 },
    2828: { id: "2828", i: "", s: "", ss: 85 },
    2829: { id: "2829", i: "", s: "", ss: 85 },
    2832: { id: "2832", i: "", s: "", ss: 85 },
    2833: { id: "2833", i: "", s: "", ss: 85 },
    2834: { id: "2834", i: "", s: "", ss: 85 },
    2836: { id: "2836", i: "", s: "", ss: 85 },
    2838: { id: "2838", i: "", s: "", ss: 85 },
    2839: { id: "2839", i: "", s: "", ss: 85 },
    2840: { id: "2840", i: "", s: "", ss: 85 },
    2843: { id: "2843", i: "", s: "", ss: 85 },
    2845: { id: "2845", i: "", s: "", ss: 85 },
    2846: { id: "2846", i: "", s: "", ss: 85 },
    2848: { id: "2848", i: "", s: "", ss: 85 },
    2849: { id: "2849", i: "", s: "", ss: 85 },
    2858: { id: "2858", i: 3, s: 20, ss: 38 },
    2863: { id: "2863", i: 2, s: 18, ss: 56 },
    2866: { id: "2866", i: 10, s: 25, ss: 53 },
    2868: { id: "2868", i: 2, s: 2, ss: 3 },
    2869: { id: "2869", i: 2, s: 2, ss: 47 },
    2877: { id: "2877", i: 5, s: 5, ss: 79 },
    2878: { id: "2878", i: 7, s: 23, ss: 37 },
    2880: { id: "2880", i: 10, s: 25, ss: 53 },
    2882: { id: "2882", i: 6, s: 22, ss: 36 },
    2883: { id: "2883", i: 11, s: 24, ss: 64 },
    2885: { id: "2885", i: 10, s: 11, ss: 20 },
    2886: { id: "2886", i: 1, s: 1, ss: 2 },
    2888: { id: "2888", i: 3, s: 3, ss: 4 },
    2892: { id: "2892", i: 2, s: 2, ss: 3 },
    2898: { id: "2898", i: 9, s: 10, ss: 66 },
    2899: { id: "2899", i: 8, s: 29, ss: 72 },
    2902: { id: "2902", i: 11, s: 24, ss: 64 },
    2905: { id: "2905", i: 8, s: 21, ss: 93 },
    2908: { id: "2908", i: 7, s: 7, ss: 52 },
    2971: { id: "2971", i: 6, s: 8, ss: 12 },
    2972: { id: "2972", i: 1, s: 1, ss: 71 },
    2973: { id: "2973", i: 6, s: 27, ss: 50 },
    2974: { id: "2974", i: 10, s: 25, ss: 90 },
    2975: { id: "2975", i: 6, s: 6, ss: 77 },
    2981: { id: "2981", i: 5, s: 30, ss: 92 },
    2983: { id: "2983", i: 3, s: 20, ss: 38 },
    2989: { id: "2989", i: 2, s: 18, ss: 56 },
    2995: { id: "2995", i: 3, s: 20, ss: 30 },
    2996: { id: "2996", i: 0, s: 0, ss: 0 },
    3001: { id: "3001", i: "", s: "", ss: 85 },
    3003: { id: "3003", i: "", s: "", ss: 85 },
    3005: { id: "3005", i: "", s: "", ss: 85 },
    3007: { id: "3007", i: "", s: "", ss: 85 },
    3010: { id: "3010", i: "", s: "", ss: 85 },
    3011: { id: "3011", i: "", s: "", ss: 85 },
    3012: { id: "3012", i: "", s: "", ss: 85 },
    3015: { id: "3015", i: "", s: "", ss: 85 },
    3016: { id: "3016", i: "", s: "", ss: 85 },
    3019: { id: "3019", i: "", s: "", ss: 85 },
    3020: { id: "3020", i: "", s: "", ss: 85 },
    3021: { id: "3021", i: "", s: "", ss: 85 },
    3022: { id: "3022", i: "", s: "", ss: 85 },
    3023: { id: "3023", i: "", s: "", ss: 85 },
    3024: { id: "3024", i: "", s: "", ss: 85 },
    3027: { id: "3027", i: "", s: "", ss: 85 },
    3031: { id: "3031", i: "", s: "", ss: 85 },
    3032: { id: "3032", i: "", s: "", ss: 85 },
    3033: { id: "3033", i: "", s: "", ss: 85 },
    3036: { id: "3036", i: "", s: "", ss: 85 },
    3037: { id: "3037", i: "", s: "", ss: 85 },
    3040: { id: "3040", i: "", s: "", ss: 85 },
    3043: { id: "3043", i: "", s: "", ss: 85 },
    3047: { id: "3047", i: "", s: "", ss: 85 },
    3048: { id: "3048", i: "", s: "", ss: 85 },
    3049: { id: "3049", i: "", s: "", ss: 85 },
    3050: { id: "3050", i: "", s: "", ss: 85 },
    3051: { id: "3051", i: "", s: "", ss: 85 },
    3053: { id: "3053", i: "", s: "", ss: 85 },
    3055: { id: "3055", i: "", s: "", ss: 85 },
    3058: { id: "3058", i: "", s: "", ss: 85 },
    3065: { id: "3065", i: "", s: "", ss: 85 },
    3067: { id: "3067", i: "", s: "", ss: 85 },
    3069: { id: "3069", i: "", s: "", ss: 85 },
    3070: { id: "3070", i: "", s: "", ss: 85 },
    3071: { id: "3071", i: "", s: "", ss: 85 },
    3072: { id: "3072", i: "", s: "", ss: 85 },
    3073: { id: "3073", i: "", s: "", ss: 85 },
    3074: { id: "3074", i: "", s: "", ss: 85 },
    3076: { id: "3076", i: "", s: "", ss: 85 },
    3077: { id: "3077", i: "", s: "", ss: 85 },
    3079: { id: "3079", i: "", s: "", ss: 85 },
    3080: { id: "3080", i: "", s: "", ss: 85 },
    3081: { id: "3081", i: "", s: "", ss: 85 },
    3082: { id: "3082", i: "", s: "", ss: 85 },
    3086: { id: "3086", i: "", s: "", ss: 85 },
    3087: { id: "3087", i: "", s: "", ss: 85 },
    3088: { id: "3088", i: "", s: "", ss: 85 },
    3091: { id: "3091", i: "", s: "", ss: 85 },
    3092: { id: "3092", i: "", s: "", ss: 85 },
    3093: { id: "3093", i: "", s: "", ss: 85 },
    3096: { id: "3096", i: "", s: "", ss: 85 },
    3097: { id: "3097", i: "", s: "", ss: 85 },
    3099: { id: "3099", i: "", s: "", ss: 85 },
    3100: { id: "3100", i: "", s: "", ss: 85 },
    3108: { id: "3108", i: "", s: "", ss: 85 },
    3109: { id: "3109", i: "", s: "", ss: 85 },
    3110: { id: "3110", i: "", s: "", ss: 85 },
    3111: { id: "3111", i: "", s: "", ss: 85 },
    3115: { id: "3115", i: "", s: "", ss: 85 },
    3117: { id: "3117", i: "", s: "", ss: 85 },
    3118: { id: "3118", i: "", s: "", ss: 85 },
    3119: { id: "3119", i: "", s: "", ss: 85 },
    3121: { id: "3121", i: "", s: "", ss: 85 },
    3122: { id: "3122", i: "", s: "", ss: 85 },
    3124: { id: "3124", i: "", s: "", ss: 85 },
    3125: { id: "3125", i: "", s: "", ss: 85 },
    3127: { id: "3127", i: "", s: "", ss: 85 },
    3128: { id: "3128", i: "", s: "", ss: 85 },
    3130: { id: "3130", i: "", s: "", ss: 85 },
    3132: { id: "3132", i: "", s: "", ss: 85 },
    3134: { id: "3134", i: "", s: "", ss: 85 },
    3141: { id: "3141", i: "", s: "", ss: 85 },
    3143: { id: "3143", i: "", s: "", ss: 85 },
    3145: { id: "3145", i: "", s: "", ss: 85 },
    3147: { id: "3147", i: "", s: "", ss: 85 },
    3149: { id: "3149", i: "", s: "", ss: 85 },
    3151: { id: "3151", i: "", s: "", ss: 85 },
    3157: { id: "3157", i: "", s: "", ss: 85 },
    3160: { id: "3160", i: "", s: "", ss: 85 },
    3162: { id: "3162", i: "", s: "", ss: 85 },
    3163: { id: "3163", i: "", s: "", ss: 85 },
    3165: { id: "3165", i: "", s: "", ss: 85 },
    3166: { id: "3166", i: "", s: "", ss: 85 },
    3167: { id: "3167", i: "", s: "", ss: 85 },
    3173: { id: "3173", i: "", s: "", ss: 85 },
    3174: { id: "3174", i: "", s: "", ss: 85 },
    3175: { id: "3175", i: "", s: "", ss: 85 },
    3176: { id: "3176", i: "", s: "", ss: 85 },
    3181: { id: "3181", i: "", s: "", ss: 85 },
    3182: { id: "3182", i: "", s: "", ss: 85 },
    3185: { id: "3185", i: "", s: "", ss: 85 },
    3186: { id: "3186", i: "", s: "", ss: 85 },
    3187: { id: "3187", i: "", s: "", ss: 85 },
    3188: { id: "3188", i: "", s: "", ss: 85 },
    3191: { id: "3191", i: "", s: "", ss: 85 },
    3193: { id: "3193", i: "", s: "", ss: 85 },
    3194: { id: "3194", i: "", s: "", ss: 85 },
    3197: { id: "3197", i: "", s: "", ss: 85 },
    3198: { id: "3198", i: "", s: "", ss: 85 },
    3199: { id: "3199", i: "", s: "", ss: 85 },
    3300: { id: "3300", i: 10, s: 11, ss: 20 },
    3301: { id: "3301", i: 2, s: 2, ss: 3 },
    3302: { id: "3302", i: 10, s: 11, ss: 20 },
    3303: { id: "3303", i: 11, s: 24, ss: 64 },
    3306: { id: "3306", i: 6, s: 27, ss: 50 },
    3308: { id: "3308", i: 6, s: 22, ss: 59 },
    3309: { id: "3309", i: 5, s: 30, ss: 73 },
    3311: { id: "3311", i: 2, s: 18, ss: 69 },
    3313: { id: "3313", i: 2, s: 18, ss: 26 },
    3315: { id: "3315", i: 7, s: 7, ss: 9 },
    3316: { id: "3316", i: 2, s: 2, ss: 47 },
    3318: { id: "3318", i: 9, s: 10, ss: 13 },
    3319: { id: "3319", i: 2, s: 2, ss: 47 },
    3320: { id: "3320", i: 5, s: 5, ss: 7 },
    3321: { id: "3321", i: 2, s: 18, ss: 56 },
    3322: { id: "3322", i: 6, s: 27, ss: 50 },
    3323: { id: "3323", i: 2, s: 18, ss: 26 },
    3326: { id: "3326", i: 6, s: 27, ss: 70 },
    3328: { id: "3328", i: 3, s: 3, ss: 4 },
    3329: { id: "3329", i: 3, s: 20, ss: 38 },
    3330: { id: "3330", i: 8, s: 29, ss: 72 },
    3331: { id: "3331", i: 9, s: 14, ss: 84 },
    3332: { id: "3332", i: 9, s: 14, ss: 84 },
    3333: { id: "3333", i: 2, s: 2, ss: 3 },
    3336: { id: "3336", i: 7, s: 26, ss: 78 },
    3337: { id: "3337", i: 11, s: 24, ss: 64 },
    3339: { id: "3339", i: 10, s: 11, ss: 17 },
    3344: { id: "3344", i: 6, s: 27, ss: 50 },
    3347: { id: "3347", i: 5, s: 5, ss: 94 },
    3348: { id: "3348", i: 10, s: 11, ss: 17 },
    3358: { id: "3358", i: 6, s: 13, ss: 16 },
    3360: { id: "3360", i: 3, s: 20, ss: 38 },
    3363: { id: "3363", i: 8, s: 21, ss: 93 },
    3366: { id: "3366", i: 2, s: 2, ss: 3 },
    3368: { id: "3368", i: 6, s: 22, ss: 59 },
    3369: { id: "3369", i: 10, s: 25, ss: 53 },
    3377: { id: "3377", i: 2, s: 2, ss: 3 },
    3378: { id: "3378", i: 10, s: 25, ss: 53 },
    3380: { id: "3380", i: 2, s: 2, ss: 3 },
    3382: { id: "3382", i: 10, s: 25, ss: 53 },
    3383: { id: "3383", i: 2, s: 2, ss: 3 },
    3389: { id: "3389", i: 6, s: 22, ss: 36 },
    3390: { id: "3390", i: 5, s: 5, ss: 7 },
    3393: { id: "3393", i: 10, s: 11, ss: 20 },
    3395: { id: "3395", i: 11, s: 24, ss: 40 },
    3396: { id: "3396", i: 7, s: 26, ss: 78 },
    3398: { id: "3398", i: 6, s: 27, ss: 50 },
    3399: { id: "3399", i: 6, s: 8, ss: 33 },
    3600: { id: "3600", i: 5, s: 30, ss: 92 },
    3601: { id: "3601", i: 7, s: 7, ss: 9 },
    3603: { id: "3603", i: 2, s: 2, ss: 5 },
    3606: { id: "3606", i: 6, s: 16, ss: 24 },
    3608: { id: "3608", i: 6, s: 27, ss: 55 },
    3613: { id: "3613", i: 5, s: 5, ss: 79 },
    3616: { id: "3616", i: 2, s: 2, ss: 3 },
    3618: { id: "3618", i: 3, s: 3, ss: 4 },
    3623: { id: "3623", i: 3, s: 20, ss: 63 },
    3626: { id: "3626", i: 10, s: 17, ss: 25 },
    3628: { id: "3628", i: 10, s: 11, ss: 20 },
    3633: { id: "3633", i: 1, s: 1, ss: 2 },
    3636: { id: "3636", i: 6, s: 6, ss: 77 },
    3638: { id: "3638", i: 7, s: 26, ss: 78 },
    3639: { id: "3639", i: 2, s: 2, ss: 3 },
    3658: { id: "3658", i: 2, s: 2, ss: 47 },
    3662: { id: "3662", i: 2, s: 2, ss: 47 },
    3663: { id: "3663", i: 6, s: 16, ss: 24 },
    3666: { id: "3666", i: 6, s: 8, ss: 23 },
    3668: { id: "3668", i: 11, s: 19, ss: 28 },
    3669: { id: "3669", i: 6, s: 22, ss: 54 },
    3678: { id: "3678", i: 3, s: 20, ss: 30 },
    3680: { id: "3680", i: 7, s: 7, ss: 22 },
    3681: { id: "3681", i: 5, s: 5, ss: 94 },
    3683: { id: "3683", i: 10, s: 25, ss: 53 },
    3686: { id: "3686", i: 0, s: 0, ss: 0 },
    3688: { id: "3688", i: 2, s: 2, ss: 5 },
    3689: { id: "3689", i: 5, s: 30, ss: 73 },
    3690: { id: "3690", i: 7, s: 7, ss: 52 },
    3692: { id: "3692", i: 5, s: 5, ss: 7 },
    3698: { id: "3698", i: 3, s: 3, ss: 4 },
    3699: { id: "3699", i: 2, s: 2, ss: 5 },
    3700: { id: "3700", i: 6, s: 6, ss: 91 },
    3708: { id: "3708", i: 2, s: 18, ss: 56 },
    3709: { id: "3709", i: 6, s: 27, ss: 50 },
    3718: { id: "3718", i: 10, s: 11, ss: 57 },
    3728: { id: "3728", i: 2, s: 18, ss: 56 },
    3737: { id: "3737", i: 5, s: 5, ss: 7 },
    3738: { id: "3738", i: 7, s: 7, ss: 9 },
    3759: { id: "3759", i: 5, s: 5, ss: 7 },
    3768: { id: "3768", i: 1, s: 1, ss: 71 },
    3773: { id: "3773", i: 7, s: 7, ss: 52 },
    3778: { id: "3778", i: 6, s: 27, ss: 55 },
    3788: { id: "3788", i: 8, s: 9, ss: 11 },
    3789: { id: "3789", i: 2, s: 18, ss: 56 },
    3798: { id: "3798", i: 7, s: 7, ss: 9 },
    3799: { id: "3799", i: 9, s: 10, ss: 27 },
    3800: { id: "3800", i: 10, s: 11, ss: 58 },
    3808: { id: "3808", i: 10, s: 11, ss: 45 },
    3813: { id: "3813", i: 6, s: 22, ss: 51 },
    3816: { id: "3816", i: 10, s: 11, ss: 20 },
    3818: { id: "3818", i: 6, s: 27, ss: 50 },
    3822: { id: "3822", i: 2, s: 18, ss: 69 },
    3828: { id: "3828", i: 9, s: 14, ss: 84 },
    3830: { id: "3830", i: 6, s: 13, ss: 16 },
    3833: { id: "3833", i: 8, s: 9, ss: 60 },
    3836: { id: "3836", i: 6, s: 22, ss: 54 },
    3838: { id: "3838", i: 9, s: 15, ss: 34 },
    3839: { id: "3839", i: 5, s: 5, ss: 94 },
    3848: { id: "3848", i: 3, s: 20, ss: 38 },
    3860: { id: "3860", i: 6, s: 27, ss: 50 },
    3866: { id: "3866", i: 3, s: 3, ss: 4 },
    3868: { id: "3868", i: 1, s: 1, ss: 39 },
    3869: { id: "3869", i: 5, s: 30, ss: 73 },
    3877: { id: "3877", i: 3, s: 20, ss: 38 },
    3878: { id: "3878", i: 2, s: 18, ss: 56 },
    3882: { id: "3882", i: 6, s: 13, ss: 19 },
    3883: { id: "3883", i: 2, s: 2, ss: 3 },
    3886: { id: "3886", i: 5, s: 30, ss: 73 },
    3888: { id: "3888", i: 7, s: 7, ss: 9 },
    3889: { id: "3889", i: 9, s: 15, ss: 34 },
    3893: { id: "3893", i: 6, s: 13, ss: 83 },
    3898: { id: "3898", i: 10, s: 11, ss: 45 },
    3899: { id: "3899", i: 11, s: 24, ss: 64 },
    3900: { id: "3900", i: 2, s: 2, ss: 3 },
    3903: { id: "3903", i: 3, s: 20, ss: 38 },
    3908: { id: "3908", i: 3, s: 20, ss: 30 },
    3913: { id: "3913", i: 2, s: 2, ss: 47 },
    3918: { id: "3918", i: 6, s: 8, ss: 12 },
    3919: { id: "3919", i: 10, s: 11, ss: 20 },
    3928: { id: "3928", i: 2, s: 18, ss: 69 },
    3933: { id: "3933", i: 5, s: 5, ss: 7 },
    3938: { id: "3938", i: 3, s: 20, ss: 63 },
    3939: { id: "3939", i: 8, s: 9, ss: 81 },
    3948: { id: "3948", i: 11, s: 19, ss: 28 },
    3958: { id: "3958", i: 3, s: 20, ss: 30 },
    3963: { id: "3963", i: 3, s: 20, ss: 38 },
    3968: { id: "3968", i: 3, s: 3, ss: 4 },
    3969: { id: "3969", i: 7, s: 7, ss: 22 },
    3978: { id: "3978", i: 6, s: 12, ss: 15 },
    3983: { id: "3983", i: 8, s: 21, ss: 76 },
    3988: { id: "3988", i: 3, s: 3, ss: 4 },
    3989: { id: "3989", i: 1, s: 1, ss: 39 },
    3990: { id: "3990", i: 2, s: 2, ss: 3 },
    3991: { id: "3991", i: 7, s: 26, ss: 78 },
    3992: { id: "3992", i: 2, s: 2, ss: 47 },
    3993: { id: "3993", i: 8, s: 9, ss: 60 },
    3996: { id: "3996", i: 10, s: 11, ss: 57 },
    3997: { id: "3997", i: 7, s: 26, ss: 48 },
    3998: { id: "3998", i: 6, s: 27, ss: 50 },
    3999: { id: "3999", i: 9, s: 15, ss: 21 },
    4332: { id: "4332", i: "", s: "", ss: 85 },
    4333: { id: "4333", i: "", s: "", ss: 85 },
    4335: { id: "4335", i: "", s: "", ss: 85 },
    4336: { id: "4336", i: "", s: "", ss: 85 },
    4337: { id: "4337", i: "", s: "", ss: 85 },
    4338: { id: "4338", i: "", s: "", ss: 85 },
    6030: { id: "6030", i: 3, s: 20, ss: 32 },
    6033: { id: "6033", i: 7, s: 26, ss: 48 },
    6036: { id: "6036", i: 10, s: 11, ss: 20 },
    6038: { id: "6038", i: 2, s: 18, ss: 56 },
    6049: { id: "6049", i: 2, s: 2, ss: 47 },
    6055: { id: "6055", i: 9, s: 10, ss: 95 },
    6058: { id: "6058", i: 3, s: 20, ss: 30 },
    6060: { id: "6060", i: 3, s: 28, ss: 67 },
    6063: { id: "6063", i: 2, s: 18, ss: 56 },
    6066: { id: "6066", i: 3, s: 20, ss: 30 },
    6068: { id: "6068", i: 6, s: 12, ss: 15 },
    6069: { id: "6069", i: 3, s: 20, ss: 38 },
    6078: { id: "6078", i: 5, s: 30, ss: 73 },
    6080: { id: "6080", i: 2, s: 18, ss: 56 },
    6083: { id: "6083", i: 10, s: 25, ss: 90 },
    6088: { id: "6088", i: 7, s: 26, ss: 48 },
    6090: { id: "6090", i: 2, s: 2, ss: 5 },
    6093: { id: "6093", i: 2, s: 2, ss: 47 },
    6098: { id: "6098", i: 2, s: 2, ss: 47 },
    6099: { id: "6099", i: 3, s: 20, ss: 30 },
    6100: { id: "6100", i: 6, s: 12, ss: 35 },
    6108: { id: "6108", i: 5, s: 5, ss: 7 },
    6110: { id: "6110", i: 6, s: 22, ss: 51 },
    6111: { id: "6111", i: 2, s: 2, ss: 3 },
    6113: { id: "6113", i: 6, s: 6, ss: 44 },
    6116: { id: "6116", i: 6, s: 27, ss: 50 },
    6117: { id: "6117", i: 10, s: 25, ss: 53 },
    6118: { id: "6118", i: 5, s: 5, ss: 94 },
    6119: { id: "6119", i: 10, s: 25, ss: 53 },
    6122: { id: "6122", i: 3, s: 3, ss: 4 },
    6123: { id: "6123", i: 10, s: 25, ss: 90 },
    6127: { id: "6127", i: 5, s: 5, ss: 94 },
    6128: { id: "6128", i: 8, s: 9, ss: 60 },
    6133: { id: "6133", i: 7, s: 26, ss: 48 },
    6136: { id: "6136", i: 1, s: 1, ss: 71 },
    6138: { id: "6138", i: 3, s: 3, ss: 4 },
    6158: { id: "6158", i: 2, s: 2, ss: 3 },
    6160: { id: "6160", i: 5, s: 5, ss: 94 },
    6161: { id: "6161", i: 3, s: 28, ss: 67 },
    6162: { id: "6162", i: 6, s: 16, ss: 24 },
    6163: { id: "6163", i: 10, s: 11, ss: 45 },
    6166: { id: "6166", i: 2, s: 2, ss: 3 },
    6168: { id: "6168", i: 7, s: 26, ss: 48 },
    6169: { id: "6169", i: 6, s: 12, ss: 15 },
    6178: { id: "6178", i: 3, s: 20, ss: 30 },
    6182: { id: "6182", i: 2, s: 18, ss: 56 },
    6183: { id: "6183", i: 9, s: 15, ss: 34 },
    6185: { id: "6185", i: 5, s: 5, ss: 94 },
    6186: { id: "6186", i: 9, s: 10, ss: 87 },
    6188: { id: "6188", i: 7, s: 26, ss: 48 },
    6189: { id: "6189", i: 2, s: 18, ss: 56 },
    6190: { id: "6190", i: 3, s: 3, ss: 4 },
    6193: { id: "6193", i: 2, s: 18, ss: 26 },
    6196: { id: "6196", i: 3, s: 3, ss: 4 },
    6198: { id: "6198", i: 10, s: 25, ss: 53 },
    6199: { id: "6199", i: 3, s: 3, ss: 4 },
    6288: { id: "6288", i: 6, s: 27, ss: 50 },
    6600: { id: "6600", i: 5, s: 5, ss: 7 },
    6601: { id: "6601", i: 6, s: 12, ss: 35 },
    6606: { id: "6606", i: 5, s: 30, ss: 92 },
    6608: { id: "6608", i: 7, s: 7, ss: 22 },
    6609: { id: "6609", i: 5, s: 30, ss: 92 },
    6611: { id: "6611", i: 2, s: 2, ss: 3 },
    6616: { id: "6616", i: 8, s: 21, ss: 31 },
    6618: { id: "6618", i: 5, s: 5, ss: 7 },
    6622: { id: "6622", i: 5, s: 5, ss: 7 },
    6626: { id: "6626", i: 2, s: 2, ss: 47 },
    6628: { id: "6628", i: 5, s: 5, ss: 94 },
    6633: { id: "6633", i: 7, s: 7, ss: 9 },
    6666: { id: "6666", i: 2, s: 2, ss: 47 },
    6668: { id: "6668", i: 2, s: 2, ss: 47 },
    6669: { id: "6669", i: 5, s: 30, ss: 92 },
    6677: { id: "6677", i: 2, s: 2, ss: 47 },
    6680: { id: "6680", i: 8, s: 9, ss: 60 },
    6690: { id: "6690", i: 6, s: 13, ss: 68 },
    6699: { id: "6699", i: 5, s: 30, ss: 73 },
    6805: { id: "6805", i: 10, s: 11, ss: 57 },
    6806: { id: "6806", i: 3, s: 20, ss: 30 },
    6808: { id: "6808", i: 9, s: 14, ss: 18 },
    6811: { id: "6811", i: 6, s: 8, ss: 23 },
    6812: { id: "6812", i: 6, s: 12, ss: 35 },
    6816: { id: "6816", i: 2, s: 18, ss: 69 },
    6818: { id: "6818", i: 3, s: 3, ss: 4 },
    6819: { id: "6819", i: 6, s: 12, ss: 35 },
    6820: { id: "6820", i: 7, s: 7, ss: 9 },
    6821: { id: "6821", i: 5, s: 5, ss: 7 },
    6822: { id: "6822", i: 6, s: 13, ss: 83 },
    6823: { id: "6823", i: 4, s: 4, ss: 6 },
    6826: { id: "6826", i: 5, s: 5, ss: 94 },
    6828: { id: "6828", i: 1, s: 1, ss: 2 },
    6829: { id: "6829", i: 2, s: 18, ss: 56 },
    6830: { id: "6830", i: 6, s: 16, ss: 24 },
    6833: { id: "6833", i: 5, s: 5, ss: 7 },
    6836: { id: "6836", i: 9, s: 10, ss: 27 },
    6837: { id: "6837", i: 3, s: 20, ss: 30 },
    6838: { id: "6838", i: 6, s: 27, ss: 49 },
    6839: { id: "6839", i: 1, s: 1, ss: 71 },
    6855: { id: "6855", i: 5, s: 5, ss: 94 },
    6858: { id: "6858", i: 6, s: 13, ss: 16 },
    6860: { id: "6860", i: 7, s: 7, ss: 9 },
    6862: { id: "6862", i: 6, s: 8, ss: 23 },
    6865: { id: "6865", i: 10, s: 11, ss: 58 },
    6866: { id: "6866", i: 3, s: 20, ss: 38 },
    6868: { id: "6868", i: 9, s: 10, ss: 66 },
    6869: { id: "6869", i: 7, s: 26, ss: 48 },
    6877: { id: "6877", i: 3, s: 20, ss: 30 },
    6878: { id: "6878", i: 2, s: 2, ss: 3 },
    6880: { id: "6880", i: 10, s: 25, ss: 90 },
    6881: { id: "6881", i: 3, s: 20, ss: 30 },
    6882: { id: "6882", i: 6, s: 8, ss: 33 },
    6885: { id: "6885", i: 11, s: 19, ss: 28 },
    6886: { id: "6886", i: 3, s: 20, ss: 30 },
    6888: { id: "6888", i: 2, s: 18, ss: 69 },
    6889: { id: "6889", i: 6, s: 8, ss: 12 },
    6890: { id: "6890", i: 8, s: 9, ss: 11 },
    6893: { id: "6893", i: 5, s: 5, ss: 7 },
    6896: { id: "6896", i: 5, s: 5, ss: 7 },
    6898: { id: "6898", i: 10, s: 17, ss: 25 },
    6899: { id: "6899", i: 7, s: 7, ss: 9 },
    6900: { id: "6900", i: 2, s: 2, ss: 3 },
    6908: { id: "6908", i: 7, s: 23, ss: 37 },
    6909: { id: "6909", i: 6, s: 22, ss: 54 },
    6913: { id: "6913", i: 6, s: 12, ss: 15 },
    6918: { id: "6918", i: 6, s: 13, ss: 16 },
    6919: { id: "6919", i: 6, s: 12, ss: 35 },
    6928: { id: "6928", i: 6, s: 16, ss: 24 },
    6933: { id: "6933", i: 7, s: 7, ss: 9 },
    6939: { id: "6939", i: 10, s: 11, ss: 20 },
    6958: { id: "6958", i: 2, s: 2, ss: 47 },
    6966: { id: "6966", i: 6, s: 12, ss: 35 },
    6968: { id: "6968", i: 2, s: 2, ss: 3 },
    6969: { id: "6969", i: 6, s: 13, ss: 19 },
    6978: { id: "6978", i: 5, s: 5, ss: 94 },
    6988: { id: "6988", i: 6, s: 6, ss: 44 },
    6989: { id: "6989", i: 2, s: 2, ss: 47 },
    6993: { id: "6993", i: 6, s: 13, ss: 83 },
    6996: { id: "6996", i: 5, s: 5, ss: 7 },
    6998: { id: "6998", i: 5, s: 5, ss: 94 },
    6999: { id: "6999", i: 2, s: 2, ss: 3 },
    7200: { id: "7200", i: "", s: "", ss: 85 },
    7226: { id: "7226", i: "", s: "", ss: 85 },
    7230: { id: "7230", i: "", s: "", ss: 85 },
    7231: { id: "7231", i: "", s: "", ss: 85 },
    7232: { id: "7232", i: "", s: "", ss: 85 },
    7233: { id: "7233", i: "", s: "", ss: 85 },
    7248: { id: "7248", i: "", s: "", ss: 85 },
    7261: { id: "7261", i: "", s: "", ss: 85 },
    7266: { id: "7266", i: "", s: "", ss: 85 },
    7272: { id: "7272", i: "", s: "", ss: 85 },
    7288: { id: "7288", i: "", s: "", ss: 85 },
    7299: { id: "7299", i: "", s: "", ss: 85 },
    7300: { id: "7300", i: "", s: "", ss: 85 },
    7321: { id: "7321", i: "", s: "", ss: 85 },
    7322: { id: "7322", i: "", s: "", ss: 85 },
    7331: { id: "7331", i: "", s: "", ss: 85 },
    7332: { id: "7332", i: "", s: "", ss: 85 },
    7333: { id: "7333", i: "", s: "", ss: 85 },
    7336: { id: "7336", i: "", s: "", ss: 85 },
    7345: { id: "7345", i: "", s: "", ss: 85 },
    7348: { id: "7348", i: "", s: "", ss: 85 },
    7362: { id: "7362", i: "", s: "", ss: 85 },
    7373: { id: "7373", i: "", s: "", ss: 85 },
    7374: { id: "7374", i: "", s: "", ss: 85 },
    7500: { id: "7500", i: "", s: "", ss: 85 },
    7522: { id: "7522", i: "", s: "", ss: 85 },
    7552: { id: "7552", i: "", s: "", ss: 85 },
    7568: { id: "7568", i: "", s: "", ss: 85 },
    7588: { id: "7588", i: "", s: "", ss: 85 },
    8001: { id: "8001", i: 3, s: 20, ss: 38 },
    8003: { id: "8003", i: 6, s: 6, ss: 44 },
    8005: { id: "8005", i: 6, s: 13, ss: 19 },
    8006: { id: "8006", i: 6, s: 6, ss: 8 },
    8007: { id: "8007", i: 8, s: 9, ss: 81 },
    8009: { id: "8009", i: 11, s: 24, ss: 40 },
    8011: { id: "8011", i: 11, s: 24, ss: 40 },
    8013: { id: "8013", i: 7, s: 7, ss: 22 },
    8017: { id: "8017", i: 7, s: 7, ss: 22 },
    8018: { id: "8018", i: 7, s: 7, ss: 9 },
    8019: { id: "8019", i: 3, s: 20, ss: 38 },
    8020: { id: "8020", i: 10, s: 25, ss: 53 },
    8021: { id: "8021", i: 2, s: 18, ss: 56 },
    8022: { id: "8022", i: 2, s: 2, ss: 3 },
    8023: { id: "8023", i: 2, s: 18, ss: 56 },
    8025: { id: "8025", i: 3, s: 20, ss: 32 },
    8026: { id: "8026", i: 5, s: 5, ss: 7 },
    8027: { id: "8027", i: 6, s: 12, ss: 35 },
    8028: { id: "8028", i: 8, s: 9, ss: 60 },
    8029: { id: "8029", i: 3, s: 20, ss: 30 },
    8030: { id: "8030", i: 2, s: 2, ss: 5 },
    8031: { id: "8031", i: 6, s: 12, ss: 35 },
    8032: { id: "8032", i: 6, s: 8, ss: 86 },
    8033: { id: "8033", i: 7, s: 7, ss: 22 },
    8035: { id: "8035", i: 10, s: 25, ss: 90 },
    8036: { id: "8036", i: 7, s: 7, ss: 22 },
    8037: { id: "8037", i: 5, s: 30, ss: 73 },
    8039: { id: "8039", i: 2, s: 18, ss: 56 },
    8040: { id: "8040", i: 2, s: 18, ss: 56 },
    8041: { id: "8041", i: 7, s: 7, ss: 52 },
    8042: { id: "8042", i: 6, s: 12, ss: 35 },
    8043: { id: "8043", i: 7, s: 26, ss: 48 },
    8045: { id: "8045", i: 7, s: 7, ss: 22 },
    8047: { id: "8047", i: 10, s: 17, ss: 82 },
    8048: { id: "8048", i: 7, s: 7, ss: 22 },
    8049: { id: "8049", i: 5, s: 5, ss: 7 },
    8050: { id: "8050", i: 7, s: 7, ss: 22 },
    8051: { id: "8051", i: 7, s: 26, ss: 78 },
    8052: { id: "8052", i: 6, s: 8, ss: 86 },
    8053: { id: "8053", i: 2, s: 18, ss: 56 },
    8055: { id: "8055", i: 6, s: 12, ss: 15 },
    8056: { id: "8056", i: 6, s: 8, ss: 23 },
    8057: { id: "8057", i: 0, s: 0, ss: 0 },
    8059: { id: "8059", i: 2, s: 18, ss: 69 },
    8060: { id: "8060", i: 7, s: 7, ss: 22 },
    8062: { id: "8062", i: 7, s: 7, ss: 22 },
    8063: { id: "8063", i: 3, s: 20, ss: 38 },
    8065: { id: "8065", i: 7, s: 7, ss: 22 },
    8066: { id: "8066", i: 7, s: 26, ss: 78 },
    8067: { id: "8067", i: 6, s: 12, ss: 35 },
    8069: { id: "8069", i: 6, s: 8, ss: 33 },
    8070: { id: "8070", i: 10, s: 11, ss: 20 },
    8071: { id: "8071", i: 7, s: 7, ss: 52 },
    8072: { id: "8072", i: 3, s: 20, ss: 63 },
    8073: { id: "8073", i: 10, s: 11, ss: 20 },
    8075: { id: "8075", i: 6, s: 6, ss: 77 },
    8076: { id: "8076", i: 7, s: 7, ss: 52 },
    8078: { id: "8078", i: 6, s: 6, ss: 77 },
    8079: { id: "8079", i: 10, s: 17, ss: 82 },
    8080: { id: "8080", i: 10, s: 11, ss: 20 },
    8081: { id: "8081", i: 7, s: 7, ss: 22 },
    8082: { id: "8082", i: 6, s: 6, ss: 77 },
    8083: { id: "8083", i: 7, s: 7, ss: 52 },
    8086: { id: "8086", i: 7, s: 7, ss: 52 },
    8087: { id: "8087", i: 6, s: 6, ss: 44 },
    8088: { id: "8088", i: 7, s: 7, ss: 9 },
    8089: { id: "8089", i: 9, s: 10, ss: 66 },
    8091: { id: "8091", i: 6, s: 6, ss: 44 },
    8092: { id: "8092", i: 7, s: 7, ss: 22 },
    8093: { id: "8093", i: 6, s: 6, ss: 44 },
    8095: { id: "8095", i: 6, s: 8, ss: 33 },
    8096: { id: "8096", i: 6, s: 8, ss: 23 },
    8098: { id: "8098", i: 3, s: 20, ss: 30 },
    8100: { id: "8100", i: 7, s: 7, ss: 52 },
    8101: { id: "8101", i: 6, s: 22, ss: 89 },
    8103: { id: "8103", i: 6, s: 6, ss: 77 },
    8106: { id: "8106", i: 7, s: 7, ss: 9 },
    8107: { id: "8107", i: 6, s: 27, ss: 50 },
    8108: { id: "8108", i: 6, s: 12, ss: 35 },
    8109: { id: "8109", i: 3, s: 28, ss: 67 },
    8111: { id: "8111", i: 10, s: 11, ss: 57 },
    8112: { id: "8112", i: 6, s: 6, ss: 44 },
    8113: { id: "8113", i: 10, s: 11, ss: 20 },
    8115: { id: "8115", i: 10, s: 11, ss: 20 },
    8116: { id: "8116", i: 9, s: 10, ss: 61 },
    8117: { id: "8117", i: 1, s: 1, ss: 2 },
    8118: { id: "8118", i: 6, s: 13, ss: 83 },
    8119: { id: "8119", i: 7, s: 7, ss: 9 },
    8120: { id: "8120", i: 6, s: 8, ss: 23 },
    8121: { id: "8121", i: 6, s: 6, ss: 44 },
    8123: { id: "8123", i: 6, s: 22, ss: 54 },
    8125: { id: "8125", i: 2, s: 18, ss: 56 },
    8126: { id: "8126", i: 6, s: 22, ss: 54 },
    8128: { id: "8128", i: 10, s: 11, ss: 57 },
    8130: { id: "8130", i: 6, s: 6, ss: 8 },
    8131: { id: "8131", i: 7, s: 7, ss: 9 },
    8132: { id: "8132", i: 11, s: 24, ss: 40 },
    8133: { id: "8133", i: 10, s: 11, ss: 20 },
    8135: { id: "8135", i: 6, s: 16, ss: 24 },
    8136: { id: "8136", i: 6, s: 13, ss: 83 },
    8137: { id: "8137", i: 10, s: 11, ss: 58 },
    8139: { id: "8139", i: 8, s: 21, ss: 31 },
    8140: { id: "8140", i: 2, s: 18, ss: 69 },
    8143: { id: "8143", i: 5, s: 30, ss: 73 },
    8146: { id: "8146", i: 9, s: 10, ss: 61 },
    8147: { id: "8147", i: 4, s: 4, ss: 88 },
    8148: { id: "8148", i: 7, s: 7, ss: 9 },
    8149: { id: "8149", i: 3, s: 20, ss: 63 },
    8150: { id: "8150", i: 6, s: 13, ss: 83 },
    8151: { id: "8151", i: 10, s: 11, ss: 20 },
    8152: { id: "8152", i: 10, s: 11, ss: 17 },
    8153: { id: "8153", i: 6, s: 6, ss: 91 },
    8155: { id: "8155", i: 2, s: 2, ss: 3 },
    8156: { id: "8156", i: 6, s: 8, ss: 12 },
    8158: { id: "8158", i: 5, s: 30, ss: 92 },
    8159: { id: "8159", i: 7, s: 26, ss: 78 },
    8160: { id: "8160", i: 6, s: 12, ss: 15 },
    8161: { id: "8161", i: 5, s: 30, ss: 73 },
    8162: { id: "8162", i: 8, s: 29, ss: 72 },
    8163: { id: "8163", i: 9, s: 14, ss: 84 },
    8166: { id: "8166", i: 10, s: 17, ss: 82 },
    8167: { id: "8167", i: 7, s: 26, ss: 48 },
    8168: { id: "8168", i: 3, s: 20, ss: 63 },
    8169: { id: "8169", i: 10, s: 11, ss: 57 },
    8170: { id: "8170", i: 2, s: 18, ss: 56 },
    8171: { id: "8171", i: 7, s: 26, ss: 78 },
    8172: { id: "8172", i: 6, s: 6, ss: 77 },
    8173: { id: "8173", i: 2, s: 18, ss: 56 },
    8175: { id: "8175", i: 6, s: 6, ss: 77 },
    8176: { id: "8176", i: 5, s: 30, ss: 73 },
    8178: { id: "8178", i: 7, s: 7, ss: 22 },
    8179: { id: "8179", i: 9, s: 15, ss: 74 },
    8181: { id: "8181", i: 2, s: 2, ss: 47 },
    8186: { id: "8186", i: 8, s: 21, ss: 41 },
    8187: { id: "8187", i: 3, s: 20, ss: 38 },
    8188: { id: "8188", i: 2, s: 18, ss: 69 },
    8189: { id: "8189", i: 8, s: 21, ss: 76 },
    8191: { id: "8191", i: 8, s: 21, ss: 41 },
    8192: { id: "8192", i: 7, s: 26, ss: 78 },
    8193: { id: "8193", i: 3, s: 20, ss: 63 },
    8195: { id: "8195", i: 6, s: 27, ss: 50 },
    8196: { id: "8196", i: 1, s: 1, ss: 71 },
    8198: { id: "8198", i: 7, s: 7, ss: 22 },
    8200: { id: "8200", i: 6, s: 22, ss: 36 },
    8201: { id: "8201", i: 6, s: 12, ss: 35 },
    8202: { id: "8202", i: 6, s: 6, ss: 44 },
    8203: { id: "8203", i: 10, s: 17, ss: 82 },
    8205: { id: "8205", i: 7, s: 7, ss: 22 },
    8206: { id: "8206", i: 6, s: 12, ss: 15 },
    8208: { id: "8208", i: 2, s: 18, ss: 56 },
    8209: { id: "8209", i: 2, s: 18, ss: 56 },
    8210: { id: "8210", i: 3, s: 20, ss: 30 },
    8211: { id: "8211", i: 6, s: 27, ss: 55 },
    8213: { id: "8213", i: 6, s: 8, ss: 23 },
    8215: { id: "8215", i: 3, s: 20, ss: 38 },
    8216: { id: "8216", i: 2, s: 18, ss: 69 },
    8217: { id: "8217", i: 2, s: 18, ss: 69 },
    8218: { id: "8218", i: 10, s: 11, ss: 20 },
    8219: { id: "8219", i: 6, s: 27, ss: 70 },
    8220: { id: "8220", i: 6, s: 6, ss: 77 },
    8221: { id: "8221", i: 3, s: 20, ss: 63 },
    8222: { id: "8222", i: 6, s: 13, ss: 83 },
    8223: { id: "8223", i: 3, s: 20, ss: 38 },
    8225: { id: "8225", i: 5, s: 5, ss: 7 },
    8226: { id: "8226", i: 3, s: 20, ss: 30 },
    8227: { id: "8227", i: 2, s: 18, ss: 26 },
    8228: { id: "8228", i: 6, s: 6, ss: 77 },
    8229: { id: "8229", i: 7, s: 7, ss: 22 },
    8231: { id: "8231", i: 7, s: 23, ss: 37 },
    8232: { id: "8232", i: 6, s: 8, ss: 23 },
    8235: { id: "8235", i: 7, s: 7, ss: 22 },
    8237: { id: "8237", i: 6, s: 8, ss: 10 },
    8238: { id: "8238", i: 6, s: 6, ss: 44 },
    8239: { id: "8239", i: 3, s: 20, ss: 38 },
    8241: { id: "8241", i: 9, s: 10, ss: 66 },
    8242: { id: "8242", i: 2, s: 2, ss: 47 },
    8245: { id: "8245", i: 7, s: 26, ss: 48 },
    8246: { id: "8246", i: 10, s: 11, ss: 58 },
    8247: { id: "8247", i: 5, s: 5, ss: 94 },
    8249: { id: "8249", i: 7, s: 7, ss: 22 },
    8250: { id: "8250", i: 11, s: 19, ss: 28 },
    8255: { id: "8255", i: 3, s: 20, ss: 38 },
    8257: { id: "8257", i: 7, s: 23, ss: 37 },
    8258: { id: "8258", i: 10, s: 11, ss: 58 },
    8259: { id: "8259", i: 2, s: 18, ss: 56 },
    8260: { id: "8260", i: 6, s: 12, ss: 35 },
    8262: { id: "8262", i: 2, s: 18, ss: 56 },
    8265: { id: "8265", i: 6, s: 16, ss: 62 },
    8266: { id: "8266", i: 7, s: 26, ss: 48 },
    8267: { id: "8267", i: 7, s: 7, ss: 9 },
    8268: { id: "8268", i: 2, s: 18, ss: 56 },
    8269: { id: "8269", i: 6, s: 27, ss: 49 },
    8270: { id: "8270", i: 11, s: 24, ss: 40 },
    8271: { id: "8271", i: 2, s: 2, ss: 5 },
    8272: { id: "8272", i: 6, s: 8, ss: 23 },
    8275: { id: "8275", i: 2, s: 18, ss: 56 },
    8277: { id: "8277", i: 8, s: 21, ss: 41 },
    8279: { id: "8279", i: 6, s: 8, ss: 12 },
    8280: { id: "8280", i: 6, s: 6, ss: 91 },
    8281: { id: "8281", i: 9, s: 14, ss: 84 },
    8282: { id: "8282", i: 7, s: 7, ss: 9 },
    8283: { id: "8283", i: 6, s: 12, ss: 35 },
    8285: { id: "8285", i: 6, s: 27, ss: 49 },
    8286: { id: "8286", i: 7, s: 26, ss: 48 },
    8287: { id: "8287", i: 7, s: 26, ss: 78 },
    8290: { id: "8290", i: 7, s: 7, ss: 9 },
    8291: { id: "8291", i: 10, s: 17, ss: 25 },
    8292: { id: "8292", i: 10, s: 25, ss: 90 },
    8293: { id: "8293", i: 6, s: 12, ss: 35 },
    8295: { id: "8295", i: 3, s: 20, ss: 63 },
    8296: { id: "8296", i: 6, s: 12, ss: 35 },
    8297: { id: "8297", i: 6, s: 27, ss: 50 },
    8299: { id: "8299", i: 8, s: 29, ss: 72 },
    8300: { id: "8300", i: 6, s: 8, ss: 23 },
    8301: { id: "8301", i: 9, s: 10, ss: 61 },
    8305: { id: "8305", i: 2, s: 18, ss: 69 },
    8307: { id: "8307", i: 5, s: 30, ss: 73 },
    8308: { id: "8308", i: 6, s: 8, ss: 10 },
    8309: { id: "8309", i: 6, s: 12, ss: 35 },
    8310: { id: "8310", i: 10, s: 17, ss: 82 },
    8311: { id: "8311", i: 7, s: 26, ss: 48 },
    8313: { id: "8313", i: 0, s: 0, ss: 0 },
    8315: { id: "8315", i: 6, s: 12, ss: 35 },
    8316: { id: "8316", i: 2, s: 18, ss: 69 },
    8317: { id: "8317", i: 6, s: 6, ss: 8 },
    8319: { id: "8319", i: 7, s: 7, ss: 22 },
    8320: { id: "8320", i: 6, s: 12, ss: 35 },
    8321: { id: "8321", i: 2, s: 18, ss: 69 },
    8325: { id: "8325", i: 7, s: 7, ss: 52 },
    8326: { id: "8326", i: 1, s: 1, ss: 39 },
    8328: { id: "8328", i: 6, s: 16, ss: 24 },
    8329: { id: "8329", i: 5, s: 5, ss: 7 },
    8331: { id: "8331", i: 8, s: 9, ss: 60 },
    8333: { id: "8333", i: 3, s: 20, ss: 63 },
    8337: { id: "8337", i: 4, s: 4, ss: 6 },
    8340: { id: "8340", i: 3, s: 20, ss: 63 },
    8341: { id: "8341", i: 2, s: 18, ss: 56 },
    8347: { id: "8347", i: 11, s: 24, ss: 40 },
    8348: { id: "8348", i: 10, s: 17, ss: 82 },
    8349: { id: "8349", i: 2, s: 18, ss: 26 },
    8350: { id: "8350", i: 3, s: 20, ss: 30 },
    8351: { id: "8351", i: 9, s: 10, ss: 27 },
    8353: { id: "8353", i: 7, s: 7, ss: 9 },
    8356: { id: "8356", i: 2, s: 18, ss: 69 },
    8357: { id: "8357", i: 5, s: 30, ss: 73 },
    8360: { id: "8360", i: 2, s: 18, ss: 56 },
    8362: { id: "8362", i: 9, s: 10, ss: 27 },
    8363: { id: "8363", i: 6, s: 12, ss: 15 },
    8365: { id: "8365", i: 3, s: 20, ss: 63 },
    8366: { id: "8366", i: 2, s: 18, ss: 69 },
    8367: { id: "8367", i: 6, s: 8, ss: 23 },
    8368: { id: "8368", i: 6, s: 6, ss: 77 },
    8370: { id: "8370", i: 6, s: 13, ss: 83 },
    8371: { id: "8371", i: 6, s: 8, ss: 23 },
    8372: { id: "8372", i: 5, s: 5, ss: 7 },
    8373: { id: "8373", i: 2, s: 18, ss: 56 },
    8375: { id: "8375", i: 10, s: 11, ss: 20 },
    8377: { id: "8377", i: 6, s: 27, ss: 55 },
    8379: { id: "8379", i: 7, s: 26, ss: 78 },
    8383: { id: "8383", i: 10, s: 17, ss: 25 },
    8385: { id: "8385", i: 6, s: 6, ss: 8 },
    8391: { id: "8391", i: 10, s: 17, ss: 25 },
    8392: { id: "8392", i: 6, s: 13, ss: 83 },
    8395: { id: "8395", i: 6, s: 13, ss: 83 },
    8400: { id: "8400", i: 6, s: 8, ss: 12 },
    8401: { id: "8401", i: 6, s: 6, ss: 44 },
    8402: { id: "8402", i: 2, s: 18, ss: 56 },
    8403: { id: "8403", i: 6, s: 6, ss: 44 },
    8405: { id: "8405", i: 6, s: 12, ss: 35 },
    8406: { id: "8406", i: 6, s: 13, ss: 16 },
    8411: { id: "8411", i: 2, s: 18, ss: 56 },
    8412: { id: "8412", i: 6, s: 8, ss: 23 },
    8413: { id: "8413", i: 9, s: 10, ss: 27 },
    8416: { id: "8416", i: 10, s: 17, ss: 25 },
    8417: { id: "8417", i: 6, s: 12, ss: 15 },
    8418: { id: "8418", i: 6, s: 12, ss: 35 },
    8419: { id: "8419", i: 6, s: 13, ss: 19 },
    8420: { id: "8420", i: 7, s: 7, ss: 22 },
    8422: { id: "8422", i: 2, s: 18, ss: 56 },
    8423: { id: "8423", i: 2, s: 18, ss: 56 },
    8425: { id: "8425", i: 10, s: 11, ss: 17 },
    8426: { id: "8426", i: 2, s: 2, ss: 47 },
    8427: { id: "8427", i: 2, s: 18, ss: 26 },
    8428: { id: "8428", i: 6, s: 8, ss: 23 },
    8429: { id: "8429", i: 6, s: 6, ss: 44 },
    8430: { id: "8430", i: 10, s: 25, ss: 90 },
    8431: { id: "8431", i: 6, s: 8, ss: 86 },
    8432: { id: "8432", i: 6, s: 8, ss: 23 },
    8436: { id: "8436", i: 6, s: 22, ss: 36 },
    8437: { id: "8437", i: 5, s: 30, ss: 73 },
    8439: { id: "8439", i: 3, s: 20, ss: 63 },
    8441: { id: "8441", i: 6, s: 12, ss: 35 },
    8445: { id: "8445", i: 2, s: 18, ss: 56 },
    8446: { id: "8446", i: 6, s: 6, ss: 77 },
    8447: { id: "8447", i: 6, s: 8, ss: 23 },
    8448: { id: "8448", i: 10, s: 17, ss: 25 },
    8450: { id: "8450", i: 10, s: 17, ss: 25 },
    8451: { id: "8451", i: 9, s: 14, ss: 84 },
    8452: { id: "8452", i: 3, s: 20, ss: 63 },
    8455: { id: "8455", i: 2, s: 18, ss: 56 },
    8456: { id: "8456", i: 6, s: 27, ss: 50 },
    8460: { id: "8460", i: 2, s: 18, ss: 56 },
    8462: { id: "8462", i: 6, s: 12, ss: 35 },
    8471: { id: "8471", i: 6, s: 27, ss: 55 },
    8472: { id: "8472", i: 6, s: 12, ss: 35 },
    8473: { id: "8473", i: 6, s: 22, ss: 36 },
    8475: { id: "8475", i: 6, s: 8, ss: 23 },
    8476: { id: "8476", i: 9, s: 10, ss: 27 },
    8479: { id: "8479", i: 11, s: 24, ss: 40 },
    8480: { id: "8480", i: 6, s: 27, ss: 55 },
    8481: { id: "8481", i: 10, s: 17, ss: 25 },
    8482: { id: "8482", i: 10, s: 25, ss: 90 },
    8483: { id: "8483", i: 6, s: 12, ss: 35 },
    8487: { id: "8487", i: 7, s: 26, ss: 48 },
    8489: { id: "8489", i: 10, s: 25, ss: 90 },
    8490: { id: "8490", i: 10, s: 11, ss: 20 },
    8491: { id: "8491", i: 9, s: 10, ss: 27 },
    8493: { id: "8493", i: 6, s: 8, ss: 23 },
    8495: { id: "8495", i: 6, s: 8, ss: 23 },
    8496: { id: "8496", i: 9, s: 10, ss: 27 },
    8500: { id: "8500", i: 6, s: 6, ss: 44 },
    8501: { id: "8501", i: 2, s: 18, ss: 56 },
    8502: { id: "8502", i: 10, s: 25, ss: 53 },
    8506: { id: "8506", i: 10, s: 11, ss: 20 },
    8507: { id: "8507", i: 6, s: 27, ss: 50 },
    8509: { id: "8509", i: 9, s: 10, ss: 61 },
    8510: { id: "8510", i: 6, s: 8, ss: 23 },
    8511: { id: "8511", i: 10, s: 11, ss: 20 },
    8512: { id: "8512", i: 6, s: 13, ss: 83 },
    8513: { id: "8513", i: 5, s: 30, ss: 92 },
    8516: { id: "8516", i: 2, s: 18, ss: 69 },
    8519: { id: "8519", i: 6, s: 8, ss: 23 },
    8521: { id: "8521", i: 6, s: 27, ss: 55 },
    8523: { id: "8523", i: 2, s: 18, ss: 69 },
    8525: { id: "8525", i: 3, s: 20, ss: 38 },
    8526: { id: "8526", i: 2, s: 18, ss: 56 },
    8527: { id: "8527", i: 6, s: 8, ss: 23 },
    8532: { id: "8532", i: 2, s: 18, ss: 56 },
    8535: { id: "8535", i: 2, s: 18, ss: 56 },
    8536: { id: "8536", i: 11, s: 24, ss: 40 },
    8537: { id: "8537", i: 6, s: 27, ss: 70 },
    8540: { id: "8540", i: 3, s: 20, ss: 30 },
    8545: { id: "8545", i: 6, s: 13, ss: 16 },
    8547: { id: "8547", i: 6, s: 13, ss: 83 },
    8551: { id: "8551", i: 6, s: 6, ss: 77 },
    8553: { id: "8553", i: 2, s: 18, ss: 69 },
    8555: { id: "8555", i: 10, s: 11, ss: 20 },
    8557: { id: "8557", i: 8, s: 21, ss: 41 },
    8559: { id: "8559", i: 0, s: 0, ss: 0 },
    8560: { id: "8560", i: 2, s: 18, ss: 56 },
    8570: { id: "8570", i: 6, s: 8, ss: 23 },
    8573: { id: "8573", i: 2, s: 18, ss: 56 },
    8575: { id: "8575", i: 2, s: 18, ss: 26 },
    8579: { id: "8579", i: 2, s: 18, ss: 56 },
    8580: { id: "8580", i: 2, s: 18, ss: 69 },
    8581: { id: "8581", i: 6, s: 6, ss: 77 },
    8582: { id: "8582", i: 6, s: 12, ss: 35 },
    8585: { id: "8585", i: 6, s: 27, ss: 50 },
    8589: { id: "8589", i: 10, s: 17, ss: 82 },
    8590: { id: "8590", i: 6, s: 27, ss: 50 },
    8591: { id: "8591", i: 8, s: 9, ss: 60 },
    8593: { id: "8593", i: 6, s: 12, ss: 35 },
    8595: { id: "8595", i: 3, s: 20, ss: 30 },
    8596: { id: "8596", i: 10, s: 11, ss: 20 },
    8597: { id: "8597", i: 6, s: 27, ss: 70 },
    8599: { id: "8599", i: 6, s: 8, ss: 23 },
    8601: { id: "8601", i: 2, s: 18, ss: 69 },
    8603: { id: "8603", i: 5, s: 30, ss: 73 },
    8606: { id: "8606", i: 7, s: 7, ss: 22 },
    8607: { id: "8607", i: 6, s: 27, ss: 55 },
    8609: { id: "8609", i: 9, s: 15, ss: 34 },
    8611: { id: "8611", i: 7, s: 7, ss: 22 },
    8612: { id: "8612", i: 10, s: 11, ss: 17 },
    8613: { id: "8613", i: 7, s: 7, ss: 52 },
    8616: { id: "8616", i: 2, s: 18, ss: 56 },
    8617: { id: "8617", i: 10, s: 11, ss: 20 },
    8619: { id: "8619", i: 2, s: 18, ss: 56 },
    8620: { id: "8620", i: 10, s: 25, ss: 90 },
    8621: { id: "8621", i: 3, s: 20, ss: 38 },
    8622: { id: "8622", i: 5, s: 5, ss: 94 },
    8623: { id: "8623", i: 10, s: 11, ss: 20 },
    8627: { id: "8627", i: 6, s: 6, ss: 44 },
    8631: { id: "8631", i: 11, s: 24, ss: 40 },
    8635: { id: "8635", i: 7, s: 7, ss: 22 },
    8645: { id: "8645", i: 7, s: 7, ss: 22 },
    8646: { id: "8646", i: 10, s: 11, ss: 20 },
    8657: { id: "8657", i: 3, s: 20, ss: 32 },
    8659: { id: "8659", i: 10, s: 17, ss: 25 },
    8668: { id: "8668", i: 6, s: 8, ss: 33 },
    9001: { id: "9001", i: "", s: "", ss: 85 },
    9010: { id: "9010", i: "", s: "", ss: 85 },
    9011: { id: "9011", i: "", s: "", ss: 85 },
    9022: { id: "9022", i: "", s: "", ss: 85 },
    9031: { id: "9031", i: "", s: "", ss: 85 },
    9047: { id: "9047", i: "", s: "", ss: 85 },
    9067: { id: "9067", i: "", s: "", ss: 85 },
    9069: { id: "9069", i: "", s: "", ss: 85 },
    9072: { id: "9072", i: "", s: "", ss: 85 },
    9074: { id: "9074", i: "", s: "", ss: 85 },
    9077: { id: "9077", i: "", s: "", ss: 85 },
    9078: { id: "9078", i: "", s: "", ss: 85 },
    9081: { id: "9081", i: "", s: "", ss: 85 },
    9085: { id: "9085", i: "", s: "", ss: 85 },
    9086: { id: "9086", i: "", s: "", ss: 85 },
    9088: { id: "9088", i: "", s: "", ss: 85 },
    9091: { id: "9091", i: "", s: "", ss: 85 },
    9096: { id: "9096", i: "", s: "", ss: 85 },
    9101: { id: "9101", i: "", s: "", ss: 85 },
    9115: { id: "9115", i: "", s: "", ss: 85 },
    9125: { id: "9125", i: "", s: "", ss: 85 },
    9126: { id: "9126", i: "", s: "", ss: 85 },
    9140: { id: "9140", i: "", s: "", ss: 85 },
    9141: { id: "9141", i: "", s: "", ss: 85 },
    9151: { id: "9151", i: "", s: "", ss: 85 },
    9167: { id: "9167", i: "", s: "", ss: 85 },
    9169: { id: "9169", i: "", s: "", ss: 85 },
    9170: { id: "9170", i: "", s: "", ss: 85 },
    9173: { id: "9173", i: "", s: "", ss: 85 },
    9181: { id: "9181", i: "", s: "", ss: 85 },
    9186: { id: "9186", i: "", s: "", ss: 85 },
    9187: { id: "9187", i: "", s: "", ss: 85 },
    9191: { id: "9191", i: "", s: "", ss: 85 },
    9600: { id: "9600", i: 7, s: 7, ss: 22 },
    9608: { id: "9608", i: 2, s: 2, ss: 47 },
    9616: { id: "9616", i: 6, s: 12, ss: 15 },
    9618: { id: "9618", i: 7, s: 7, ss: 52 },
    9626: { id: "9626", i: 7, s: 7, ss: 9 },
    9633: { id: "9633", i: 9, s: 10, ss: 66 },
    9666: { id: "9666", i: 2, s: 2, ss: 47 },
    9668: { id: "9668", i: 3, s: 3, ss: 4 },
    9677: { id: "9677", i: 3, s: 3, ss: 4 },
    9688: { id: "9688", i: 5, s: 5, ss: 94 },
    9698: { id: "9698", i: 7, s: 7, ss: 22 },
    9699: { id: "9699", i: 10, s: 25, ss: 90 },
    9801: { id: "9801", i: "", s: "", ss: 85 },
    9803: { id: "9803", i: "", s: "", ss: 85 },
    9804: { id: "9804", i: "", s: "", ss: 85 },
    9805: { id: "9805", i: "", s: "", ss: 85 },
    9806: { id: "9806", i: "", s: "", ss: 85 },
    9807: { id: "9807", i: "", s: "", ss: 85 },
    9809: { id: "9809", i: "", s: "", ss: 85 },
    9810: { id: "9810", i: "", s: "", ss: 85 },
    9812: { id: "9812", i: "", s: "", ss: 85 },
    9813: { id: "9813", i: "", s: "", ss: 85 },
    9814: { id: "9814", i: "", s: "", ss: 85 },
    9817: { id: "9817", i: "", s: "", ss: 85 },
    9820: { id: "9820", i: "", s: "", ss: 85 },
    9826: { id: "9826", i: "", s: "", ss: 85 },
    9829: { id: "9829", i: "", s: "", ss: 85 },
    9834: { id: "9834", i: "", s: "", ss: 85 },
    9836: { id: "9836", i: "", s: "", ss: 85 },
    9839: { id: "9839", i: "", s: "", ss: 85 },
    9845: { id: "9845", i: "", s: "", ss: 85 },
    9846: { id: "9846", i: "", s: "", ss: 85 },
    9858: { id: "9858", i: 9, s: 10, ss: 87 },
    9868: { id: "9868", i: 6, s: 16, ss: 62 },
    9869: { id: "9869", i: 6, s: 8, ss: 23 },
    9888: { id: "9888", i: 7, s: 7, ss: 52 },
    9889: { id: "9889", i: 3, s: 3, ss: 4 },
    9898: { id: "9898", i: 7, s: 7, ss: 52 },
    9899: { id: "9899", i: 6, s: 6, ss: 77 },
    9900: { id: "9900", i: 2, s: 18, ss: 56 },
    9901: { id: "9901", i: 6, s: 12, ss: 15 },
    9906: { id: "9906", i: 5, s: 30, ss: 73 },
    9908: { id: "9908", i: 1, s: 1, ss: 2 },
    9909: { id: "9909", i: 2, s: 2, ss: 47 },
    9911: { id: "9911", i: 7, s: 7, ss: 52 },
    9913: { id: "9913", i: 2, s: 18, ss: 56 },
    9916: { id: "9916", i: 2, s: 2, ss: 47 },
    9918: { id: "9918", i: 10, s: 11, ss: 43 },
    9919: { id: "9919", i: 6, s: 6, ss: 44 },
    9922: { id: "9922", i: 6, s: 8, ss: 23 },
    9923: { id: "9923", i: 7, s: 7, ss: 52 },
    9926: { id: "9926", i: 5, s: 5, ss: 94 },
    9928: { id: "9928", i: 2, s: 2, ss: 47 },
    9929: { id: "9929", i: 2, s: 18, ss: 56 },
    9933: { id: "9933", i: 8, s: 21, ss: 31 },
    9936: { id: "9936", i: 8, s: 21, ss: 31 },
    9938: { id: "9938", i: 2, s: 18, ss: 56 },
    9939: { id: "9939", i: 5, s: 5, ss: 7 },
    9956: { id: "9956", i: 10, s: 25, ss: 90 },
    9958: { id: "9958", i: 6, s: 6, ss: 77 },
    9959: { id: "9959", i: 7, s: 7, ss: 22 },
    9960: { id: "9960", i: 5, s: 30, ss: 73 },
    9961: { id: "9961", i: 6, s: 8, ss: 33 },
    9963: { id: "9963", i: 7, s: 26, ss: 48 },
    9966: { id: "9966", i: 5, s: 5, ss: 94 },
    9968: { id: "9968", i: 2, s: 2, ss: 3 },
    9969: { id: "9969", i: 5, s: 5, ss: 94 },
    9977: { id: "9977", i: 9, s: 15, ss: 74 },
    9978: { id: "9978", i: 2, s: 2, ss: 80 },
    9979: { id: "9979", i: 2, s: 2, ss: 47 },
    9982: { id: "9982", i: 2, s: 2, ss: 3 },
    9983: { id: "9983", i: 2, s: 2, ss: 47 },
    9986: { id: "9986", i: 6, s: 12, ss: 15 },
    9987: { id: "9987", i: 6, s: 8, ss: 23 },
    9988: { id: "9988", i: 7, s: 7, ss: 52 },
    9989: { id: "9989", i: 5, s: 5, ss: 7 },
    9990: { id: "9990", i: 7, s: 7, ss: 9 },
    9991: { id: "9991", i: 7, s: 7, ss: 52 },
    9992: { id: "9992", i: 6, s: 13, ss: 16 },
    9993: { id: "9993", i: 2, s: 2, ss: 3 },
    9995: { id: "9995", i: 5, s: 5, ss: 94 },
    9996: { id: "9996", i: 5, s: 30, ss: 92 },
    9997: { id: "9997", i: 5, s: 30, ss: 92 },
    9998: { id: "9998", i: 2, s: 18, ss: 56 },
    9999: { id: "9999", i: 7, s: 7, ss: 9 },
  },
  iDict: {
    0: "綜合企業",
    1: "公用事業",
    2: "地產建築業",
    3: "金融業",
    4: "電訊業",
    5: "醫療保健業",
    6: "非必需性消費",
    7: "資訊科技業",
    8: "原材料業",
    9: "必需性消費",
    10: "工業",
    11: "能源業",
  },
  sDict: {
    0: "綜合企業",
    1: "公用事業",
    2: "地產",
    3: "銀行",
    4: "電訊",
    5: "藥品及生物科技",
    6: "媒體及娛樂",
    7: "軟件服務",
    8: "旅遊及消閒設施",
    9: "一般金屬及礦石",
    10: "食物飲品",
    11: "工業工程",
    12: "支援服務",
    13: "家庭電器及用品",
    14: "消費者主要零售商",
    15: "農業產品",
    16: "汽車",
    17: "工用支援",
    18: "建築",
    19: "煤炭",
    20: "其他金融",
    21: "原材料",
    22: "專業零售",
    23: "半導體",
    24: "石油及天然氣",
    25: "工用運輸",
    26: "資訊科技器材",
    27: "紡織及服飾",
    28: "保險",
    29: "黃金及貴金屬",
    30: "醫療保健設備和服務",
  },
  ssDict: {
    0: "綜合企業",
    1: "電力",
    2: "燃氣供應",
    3: "地產發展商",
    4: "銀行",
    5: "地產投資",
    6: "電訊服務",
    7: "藥品",
    8: "出版",
    9: "軟件開發",
    10: "酒店及度假村",
    11: "鋼鐵",
    12: "賭場及博彩",
    13: "食品添加劑",
    14: "航空航天與國防",
    15: "教育",
    16: "玩具及消閒用品",
    17: "重機械",
    18: "超市及便利店",
    19: "消費電子產品",
    20: "工業零件及器材",
    21: "禽畜飼料",
    22: "系統開發及資訊科技顧問",
    23: "餐飲",
    24: "汽車零件",
    25: "印刷及包裝",
    26: "建築材料",
    27: "包裝食品",
    28: "煤炭",
    29: "公共運輸",
    30: "證券及經紀",
    31: "特殊化工用品",
    32: "投資及資產管理",
    33: "旅遊及觀光",
    34: "農產品",
    35: "其他支援服務",
    36: "其他零售商",
    37: "半導體",
    38: "信貸",
    39: "非傳統/ 可再生能源",
    40: "氣油生產商",
    41: "林業及木材",
    42: "鋁",
    43: "電子零件",
    44: "廣告及宣傳",
    45: "商業用車及貨車",
    46: "鐵路及公路",
    47: "物業服務及管理",
    48: "電訊設備",
    49: "其他服飾配件",
    50: "服裝",
    51: "服裝零售商",
    52: "電子商貿及互聯網服務",
    53: "航運及港口",
    54: "汽車零售商",
    55: "紡織品及布料",
    56: "樓宇建造",
    57: "環保工程",
    58: "新能源物料",
    59: "多元化零售商",
    60: "其他金屬及礦物",
    61: "酒精飲料",
    62: "汽車",
    63: "其他金融",
    64: "氣油設備與服務",
    65: "鞋類",
    66: "非酒精飲料",
    67: "保險",
    68: "家庭電器",
    69: "重型基建",
    70: "珠寶鐘錶",
    71: "水務",
    72: "黃金及貴金屬",
    73: "醫療及醫學美容服務",
    74: "禽畜肉類",
    75: "航空服務",
    76: "化肥及農用化合物",
    77: "影視娛樂",
    78: "電腦及週邊器材",
    79: "中醫藥",
    80: "地產代理",
    81: "銅",
    82: "採購及供應鏈管理",
    83: "家具",
    84: "個人護理",
    85: "-",
    86: "消閒及文娛設施",
    87: "乳製品",
    88: "衛星及無線通訊",
    89: "家居裝修零售商",
    90: "航空貨運及物流",
    91: "廣播",
    92: "醫療保健設備",
    93: "紙及紙製品",
    94: "生物技術",
    95: "煙草",
  },
  indexCons: {
    htech: [
      "0241",
      "0268",
      "0285",
      "0522",
      "0700",
      "0772",
      "0780",
      "0909",
      "0981",
      "0992",
      "1024",
      "1347",
      "1797",
      "1810",
      "1833",
      "2013",
      "2018",
      "2382",
      "2518",
      "3690",
      "3888",
      "6060",
      "6618",
      "6690",
      "9618",
      "9626",
      "9698",
      "9888",
      "9988",
      "9999",
    ],
    hsiList: [
      "0001",
      "0002",
      "0003",
      "0005",
      "0006",
      "0011",
      "0012",
      "0016",
      "0017",
      "0027",
      "0066",
      "0101",
      "0175",
      "0241",
      "0267",
      "0288",
      "0291",
      "0386",
      "0388",
      "0669",
      "0688",
      "0700",
      "0762",
      "0823",
      "0857",
      "0868",
      "0883",
      "0939",
      "0941",
      "0960",
      "0968",
      "1038",
      "1044",
      "1093",
      "1109",
      "1113",
      "1177",
      "1211",
      "1299",
      "1398",
      "1810",
      "1876",
      "1928",
      "1997",
      "2007",
      "2018",
      "2020",
      "2269",
      "2313",
      "2318",
      "2319",
      "2331",
      "2382",
      "2388",
      "2628",
      "2688",
      "3690",
      "3968",
      "3988",
      "6098",
      "6862",
      "9618",
      "9988",
      "9999",
    ],
  },
  topicInfo: { lastUpdate: "2022/02/04 14:46", name: "stockSectorInfo" },
};

const data = {
  topicInfo: { lastUpdate: "2022-02-05 02:25", name: "stockSummary" },
  data: {
    header: ["id", "cat", "cap", "ls", "nc", "pc", "pe", "yld", "turnover"],
    data: [
      {
        "0001": [
          "0001",
          "EM",
          213000000000.0,
          55.55,
          0.35,
          0.63,
          7.35,
          4.5,
          385530000.0,
        ],
      },
      {
        "0002": [
          "0002",
          "EM",
          199080000000.0,
          78.8,
          0.9,
          1.16,
          17.38,
          3.93,
          403000000.0,
        ],
      },
      {
        "0003": [
          "0003",
          "EM",
          226900000000.0,
          12.16,
          0.16,
          1.33,
          37.78,
          2.79,
          194040000.0,
        ],
      },
      {
        "0004": [
          "0004",
          "EM",
          84800000000.0,
          27.75,
          1.15,
          4.32,
          21.9,
          1.44,
          44870000.0,
        ],
      },
      {
        "0005": [
          "0005",
          "EM",
          1193340000000.0,
          57.9,
          2.75,
          4.99,
          38.78,
          2.95,
          3610000000.0,
        ],
      },
      {
        "0006": [
          "0006",
          "EM",
          104150000000.0,
          48.8,
          0.95,
          1.99,
          16.99,
          5.78,
          177150000.0,
        ],
      },
      {
        "0007": [
          "0007",
          "EM",
          340000000.0,
          0.085,
          -0.001,
          -1.16,
          "-",
          "-",
          633940.0,
        ],
      },
      {
        "0008": [
          "0008",
          "EM",
          32000000000.0,
          4.14,
          0.04,
          0.98,
          "-",
          7.82,
          31920000.0,
        ],
      },
      {
        "0009": [
          "0009",
          "EM",
          121330000.0,
          0.034,
          -0.002,
          -5.56,
          "-",
          "-",
          4480.0,
        ],
      },
      {
        "0010": [
          "0010",
          "EM",
          23820000000.0,
          17.5,
          0.2,
          1.16,
          9.2,
          4.91,
          9330000.0,
        ],
      },
      {
        "0011": [
          "0011",
          "EM",
          304360000000.0,
          159.2,
          5.3,
          3.44,
          19.04,
          3.83,
          494700000.0,
        ],
      },
      {
        "0012": [
          "0012",
          "EM",
          169440000000.0,
          35.0,
          0.95,
          2.79,
          16.62,
          5.14,
          184470000.0,
        ],
      },
      {
        "0013": [
          "0013",
          "EM",
          35530000000.0,
          41.1,
          0.3,
          0.74,
          "-",
          "-",
          23630000.0,
        ],
      },
      {
        "0014": [
          "0014",
          "EM",
          25020000000.0,
          24.2,
          0.3,
          1.26,
          "-",
          5.95,
          64040000.00000001,
        ],
      },
      {
        "0016": [
          "0016",
          "EM",
          280210000000.0,
          96.7,
          1.95,
          2.06,
          10.5,
          5.12,
          735170000.0,
        ],
      },
      {
        "0017": [
          "0017",
          "EM",
          81410000000.0,
          32.35,
          0.65,
          2.05,
          70.28,
          6.37,
          159660000.0,
        ],
      },
      {
        "0018": [
          "0018",
          "EM",
          1650000000.0,
          0.69,
          0.01,
          1.47,
          7.83,
          14.49,
          105100.0,
        ],
      },
      {
        "0019": [
          "0019",
          "EM",
          43350000000.0,
          47.9,
          0.9,
          1.91,
          "-",
          4.18,
          86330000.0,
        ],
      },
      {
        "0020": [
          "0020",
          "EM",
          228520000000.0,
          6.82,
          0.09,
          1.34,
          "-",
          "-",
          106200000.0,
        ],
      },
      { "0021": ["0021", "EM", 477020000.0, 0.12, 0.0, 0.0, "-", "-", "-"] },
      {
        "0022": [
          "0022",
          "EM",
          235960000.0,
          0.12,
          -0.003,
          -2.44,
          "-",
          "-",
          14400.0,
        ],
      },
      {
        "0023": [
          "0023",
          "EM",
          40090000000.0,
          13.72,
          0.44,
          3.31,
          14.14,
          4.3,
          49470000.0,
        ],
      },
      {
        "0025": [
          "0025",
          "EM",
          2790000000.0,
          9.27,
          -0.05,
          -0.54,
          4.37,
          5.83,
          18500.0,
        ],
      },
      {
        "0026": [
          "0026",
          "EM",
          4320000000.0,
          95.45,
          0.95,
          1.01,
          "-",
          3.35,
          80610.0,
        ],
      },
      {
        "0027": [
          "0027",
          "EM",
          200530000000.0,
          46.0,
          1.25,
          2.79,
          "-",
          "-",
          370450000.0,
        ],
      },
      {
        "0028": [
          "0028",
          "EM",
          6060000000.0,
          4.14,
          0.04,
          0.98,
          10.05,
          4.83,
          37230.0,
        ],
      },
      {
        "0029": [
          "0029",
          "EM",
          2340000000.0,
          9.85,
          -0.05,
          -0.51,
          "-",
          0.2,
          98640.0,
        ],
      },
      {
        "0030": [
          "0030",
          "EM",
          1830000000.0,
          0.285,
          -0.01,
          -3.39,
          142.5,
          "-",
          367620.0,
        ],
      },
      {
        "0031": [
          "0031",
          "EM",
          1600000000.0,
          0.52,
          0.01,
          1.96,
          5.41,
          3.85,
          688540.0,
        ],
      },
      {
        "0032": [
          "0032",
          "EM",
          4099999999.9999995,
          11.02,
          0.0,
          0.0,
          5.66,
          3.81,
          "-",
        ],
      },
      {
        "0033": [
          "0033",
          "EM",
          784370000.0,
          1.46,
          -0.04,
          -2.67,
          "-",
          "-",
          258180.0,
        ],
      },
      {
        "0034": [
          "0034",
          "EM",
          12230000000.0,
          10.4,
          -0.06,
          -0.57,
          15.28,
          7.79,
          1880000.0,
        ],
      },
      {
        "0035": [
          "0035",
          "EM",
          6850000000.0,
          2.84,
          0.02,
          0.71,
          12.39,
          6.69,
          5090000.0,
        ],
      },
      {
        "0036": [
          "0036",
          "EM",
          170990000.0,
          0.157,
          -0.005,
          -3.09,
          1.83,
          "-",
          250570.0,
        ],
      },
      {
        "0037": [
          "0037",
          "EM",
          534980000.0,
          0.73,
          -0.03,
          -3.95,
          56.59,
          "-",
          8820000.0,
        ],
      },
      {
        "0038": [
          "0038",
          "EM",
          1510000000.0,
          3.87,
          0.09,
          2.38,
          11.59,
          2.31,
          5330000.0,
        ],
      },
      {
        "0039": [
          "0039",
          "EM",
          311250000.0,
          0.05,
          0.0,
          0.0,
          20.83,
          "-",
          77560.0,
        ],
      },
      {
        "0040": [
          "0040",
          "EM",
          604210000.0,
          0.77,
          0.04,
          5.48,
          4.96,
          "-",
          617230.0,
        ],
      },
      {
        "0041": [
          "0041",
          "EM",
          16260000000.000002,
          22.25,
          0.45,
          2.06,
          "-",
          5.98,
          18740000.0,
        ],
      },
      {
        "0042": [
          "0042",
          "EM",
          152190000.0,
          0.59,
          -0.03,
          -4.84,
          7.17,
          "-",
          585820.0,
        ],
      },
      {
        "0045": [
          "0045",
          "EM",
          13080000000.0,
          7.93,
          0.43,
          5.73,
          "-",
          "-",
          4880000.0,
        ],
      },
      {
        "0046": [
          "0046",
          "EM",
          676110000.0,
          2.71,
          -0.05,
          -1.81,
          11.15,
          6.64,
          27100.0,
        ],
      },
      {
        "0048": ["0048", "EM", 147140000.0, 0.88, 0.07, 8.64, "-", "-", 8900.0],
      },
      {
        "0050": [
          "0050",
          "EM",
          2790000000.0,
          7.84,
          0.04,
          0.51,
          105.09,
          3.19,
          6870000.0,
        ],
      },
      {
        "0051": [
          "0051",
          "EM",
          4810000000.0,
          6.8,
          -0.1,
          -1.45,
          "-",
          2.06,
          47760.0,
        ],
      },
      {
        "0052": [
          "0052",
          "EM",
          2000000000.0,
          15.46,
          -0.12,
          -0.77,
          13.04,
          5.5,
          1080000.0,
        ],
      },
      {
        "0053": [
          "0053",
          "EM",
          28950000000.0,
          88.0,
          1.0,
          1.15,
          11.44,
          2.27,
          87800.0,
        ],
      },
      {
        "0055": [
          "0055",
          "EM",
          102720000.0,
          0.405,
          0.005,
          1.25,
          "-",
          "-",
          26390.0,
        ],
      },
      {
        "0057": [
          "0057",
          "EM",
          1560000000.0,
          2.48,
          0.03,
          1.22,
          7.7,
          6.73,
          286160.0,
        ],
      },
      { "0058": ["0058", "EM", 44900000.0, 0.25, 0.0, 0.0, "-", "-", "-"] },
      {
        "0059": [
          "0059",
          "EM",
          7170000000.0,
          0.85,
          0.01,
          1.19,
          5.9,
          3.53,
          3080000.0,
        ],
      },
      { "0060": ["0060", "EM", 179110000.0, 0.69, 0.0, 0.0, "-", "-", "-"] },
      {
        "0061": [
          "0061",
          "EM",
          64200000.0,
          0.122,
          0.003,
          2.52,
          "-",
          "-",
          4840.0,
        ],
      },
      {
        "0062": [
          "0062",
          "EM",
          6140000000.0,
          13.2,
          0.2,
          1.54,
          3.14,
          3.79,
          793610.0,
        ],
      },
      { "0063": ["0063", "EM", 197330000.0, 0.07, 0.0, 0.0, "-", "-", "-"] },
      {
        "0064": [
          "0064",
          "EM",
          1550000000.0,
          0.161,
          -0.001,
          -0.62,
          8.21,
          6.21,
          241780.0,
        ],
      },
      { "0065": ["0065", "EM", 228520000.0, 0.152, 0.0, 0.0, "-", "-", "-"] },
      {
        "0066": [
          "0066",
          "EM",
          265690000000.0,
          42.9,
          0.8,
          1.9,
          "-",
          2.87,
          177280000.0,
        ],
      },
      { "0067": ["0067", "EM", 7030000000.0, 25.12, 0.0, 0.0, "-", "-", "-"] },
      { "0068": ["0068", "EM", 76320000.0, 0.52, 0.0, 0.0, "-", "-", "-"] },
      {
        "0069": [
          "0069",
          "EM",
          22440000000.0,
          6.26,
          0.18,
          2.96,
          "-",
          "-",
          6720000.0,
        ],
      },
      {
        "0070": [
          "0070",
          "EM",
          155100000.0,
          0.08,
          0.005,
          6.67,
          "-",
          "-",
          2400.0,
        ],
      },
      {
        "0071": [
          "0071",
          "EM",
          9070000000.0,
          13.14,
          0.04,
          0.31,
          30.08,
          3.65,
          130840.0,
        ],
      },
      { "0072": ["0072", "EM", 80210000.0, 0.183, 0.0, 0.0, "-", "-", "-"] },
      {
        "0073": [
          "0073",
          "EM",
          414930000.0,
          0.166,
          0.001,
          0.61,
          51.88,
          "-",
          125320.0,
        ],
      },
      { "0075": ["0075", "EM", 1200000000.0, 1.51, 0.0, 0.0, "-", "-", "-"] },
      {
        "0076": [
          "0076",
          "EM",
          294270000.0,
          0.042,
          0.002,
          5.0,
          10.77,
          "-",
          128229.99999999999,
        ],
      },
      { "0077": ["0077", "EM", 198490000.0, 0.73, 0.0, 0.0, 9.09, 9.59, "-"] },
      {
        "0078": [
          "0078",
          "EM",
          2900000000.0,
          3.23,
          0.01,
          0.31,
          "-",
          "-",
          89780.0,
        ],
      },
      {
        "0079": [
          "0079",
          "EM",
          44670000.0,
          0.137,
          0.007,
          5.38,
          "-",
          "-",
          5470.0,
        ],
      },
      {
        "0080": [
          "0080",
          "-",
          295560000.0,
          0.228,
          0.007,
          3.17,
          "-",
          "-",
          134390.0,
        ],
      },
      {
        "0081": [
          "0081",
          "EM",
          16120000000.000002,
          4.71,
          0.08,
          1.73,
          3.12,
          7.54,
          9640000.0,
        ],
      },
      {
        "0082": [
          "0082",
          "EM",
          1760000000.0,
          0.39,
          0.08,
          25.81,
          "-",
          "-",
          29560000.0,
        ],
      },
      {
        "0083": [
          "0083",
          "EM",
          76910000000.0,
          10.1,
          0.02,
          0.2,
          7.56,
          8.22,
          60280000.0,
        ],
      },
      {
        "0084": [
          "0084",
          "EM",
          73250000.0,
          0.07,
          -0.005,
          -6.67,
          "-",
          "-",
          69990.0,
        ],
      },
      {
        "0085": [
          "0085",
          "EM",
          1360000000.0,
          0.67,
          0.0,
          0.0,
          "-",
          "-",
          226320.0,
        ],
      },
      {
        "0086": [
          "0086",
          "EM",
          8220000000.000001,
          4.17,
          0.0,
          0.0,
          3.25,
          6.24,
          1030000.0,
        ],
      },
      {
        "0087": [
          "0087",
          "EM",
          23610000000.0,
          7.92,
          0.21,
          2.72,
          "-",
          5.05,
          4600000.0,
        ],
      },
      {
        "0088": [
          "0088",
          "EM",
          3050000000.0,
          4.95,
          0.06,
          1.23,
          "-",
          4.85,
          550340.0,
        ],
      },
      {
        "0089": [
          "0089",
          "EM",
          1160000000.0,
          4.05,
          0.05,
          1.25,
          "-",
          5.43,
          1970.0,
        ],
      },
      {
        "0090": [
          "0090",
          "EM",
          362290000.0,
          0.79,
          -0.01,
          -1.25,
          2.49,
          12.66,
          14380.0,
        ],
      },
      {
        "0091": [
          "0091",
          "EM",
          953190000.0,
          0.214,
          -0.001,
          -0.47,
          "-",
          "-",
          628180.0,
        ],
      },
      {
        "0092": [
          "0092",
          "EM",
          295410000.0,
          0.108,
          0.003,
          2.86,
          "-",
          "-",
          138280.0,
        ],
      },
      {
        "0093": [
          "0093",
          "EM",
          706920000.0,
          0.265,
          0.005,
          1.92,
          9.17,
          "-",
          18210.0,
        ],
      },
      { "0094": ["0094", "EM", 226300000.0, 0.122, 0.0, 0.0, "-", "-", "-"] },
      {
        "0095": [
          "0095",
          "EM",
          7540000000.0,
          1.48,
          0.01,
          0.68,
          1.86,
          "-",
          6340000.0,
        ],
      },
      { "0096": ["0096", "EM", 560160000.0, 0.88, 0.0, 0.0, 9.13, 1.8, "-"] },
      {
        "0097": [
          "0097",
          "EM",
          1120000000.0,
          0.37,
          0.0,
          0.0,
          8.87,
          5.41,
          60170.0,
        ],
      },
      {
        "0098": [
          "0098",
          "EM",
          3760000000.0,
          8.96,
          0.08,
          0.9,
          4.17,
          4.02,
          8980.0,
        ],
      },
      {
        "0099": [
          "0099",
          "EM",
          1120000000.0,
          2.35,
          0.0,
          0.0,
          "-",
          1.49,
          45350.0,
        ],
      },
      {
        "0101": [
          "0101",
          "EM",
          75490000000.0,
          16.78,
          0.12,
          0.72,
          19.52,
          4.65,
          73430000.0,
        ],
      },
      {
        "0102": [
          "0102",
          "EM",
          482510000.0,
          0.107,
          -0.001,
          -0.93,
          27.44,
          "-",
          106830.0,
        ],
      },
      {
        "0103": [
          "0103",
          "EM",
          617060000.0,
          0.325,
          0.015,
          4.84,
          4.19,
          4.62,
          157730.0,
        ],
      },
      { "0104": ["0104", "EM", 164360000.0, 0.22, 0.0, 0.0, 2.81, 24.36, "-"] },
      { "0105": ["0105", "EM", 4500000000.0, 12.5, 0.0, 0.0, "-", 3.04, "-"] },
      {
        "0106": [
          "0106",
          "EM",
          1360000000.0,
          0.29,
          -0.01,
          -3.33,
          "-",
          10.34,
          133520.0,
        ],
      },
      {
        "0107": [
          "0107",
          "EM",
          1840000000.0,
          2.06,
          0.01,
          0.49,
          7.97,
          4.68,
          349120.0,
        ],
      },
      {
        "0108": [
          "0108",
          "EM",
          3390000000.0,
          1.06,
          0.02,
          1.92,
          5300.0,
          "-",
          2069999.9999999998,
        ],
      },
      { "0109": ["0109", "EM", 602490000.0, 0.085, 0.0, 0.0, 11.18, "-", "-"] },
      { "0110": ["0110", "EM", 71580000.0, 0.39, 0.0, 0.0, 169.57, "-", "-"] },
      {
        "0111": [
          "0111",
          "EM",
          282130000.0,
          0.44,
          0.005,
          1.15,
          3.37,
          6.82,
          55530.0,
        ],
      },
      { "0112": ["0112", "EM", 954160000.0, 0.89, 0.0, 0.0, 4.83, "-", "-"] },
      {
        "0113": [
          "0113",
          "EM",
          1660000000.0,
          4.23,
          0.03,
          0.71,
          3.61,
          8.27,
          184200.0,
        ],
      },
      {
        "0114": ["0114", "EM", 332460000.0, 0.55, 0.01, 1.85, 9.8, 10.91, "-"],
      },
      {
        "0115": ["0115", "EM", 166560000.0, 0.68, -0.01, -1.45, 4.8, "-", "-"],
      },
      {
        "0116": [
          "0116",
          "EM",
          7160000000.0,
          10.58,
          0.28,
          2.72,
          13.16,
          5.29,
          1650000.0,
        ],
      },
      {
        "0117": [
          "0117",
          "EM",
          323960000.0,
          0.435,
          0.005,
          1.16,
          "-",
          "-",
          4340.0,
        ],
      },
      { "0118": ["0118", "EM", 357700000.0, 0.415, 0.0, 0.0, 18.28, "-", "-"] },
      {
        "0119": [
          "0119",
          "EM",
          7790000000.0,
          2.1,
          0.05,
          2.44,
          4.09,
          6.1,
          1830000.0,
        ],
      },
      {
        "0120": [
          "0120",
          "EM",
          7990000000.0,
          1.25,
          0.02,
          1.63,
          "-",
          "-",
          1070000.0,
        ],
      },
      {
        "0122": [
          "0122",
          "EM",
          345850000.0,
          0.365,
          0.0,
          0.0,
          "-",
          "-",
          288100.0,
        ],
      },
      {
        "0123": [
          "0123",
          "EM",
          25050000000.0,
          8.09,
          0.19,
          2.41,
          4.99,
          7.92,
          24460000.0,
        ],
      },
      {
        "0124": [
          "0124",
          "EM",
          1500000000.0,
          0.88,
          0.05,
          6.02,
          0.9,
          1.74,
          529560.0,
        ],
      },
      { "0125": ["0125", "EM", 328470000.0, 1.25, 0.0, 0.0, 34.15, 2.4, "-"] },
      {
        "0126": [
          "0126",
          "EM",
          1000000000.0,
          0.64,
          -0.01,
          -1.54,
          9.94,
          4.69,
          516820.00000000006,
        ],
      },
      {
        "0127": [
          "0127",
          "EM",
          5160000000.0,
          2.71,
          0.05,
          1.88,
          8.31,
          0.37,
          856110.0,
        ],
      },
      {
        "0128": [
          "0128",
          "EM",
          1100000000.0,
          0.67,
          -0.01,
          -1.47,
          93.06,
          "-",
          1410000.0,
        ],
      },
      {
        "0129": [
          "0129",
          "EM",
          1130000000.0,
          0.86,
          -0.01,
          -1.15,
          0.84,
          3.49,
          33480.0,
        ],
      },
      { "0130": ["0130", "EM", 82060000.0, 0.285, 0.0, 0.0, "-", "-", "-"] },
      { "0131": ["0131", "EM", 1630000000.0, 2.5, 0.0, 0.0, 10.86, 2.0, "-"] },
      { "0132": ["0132", "EM", 659240000.0, 0.385, 0.0, 0.0, "-", "-", "-"] },
      {
        "0133": [
          "0133",
          "-",
          1470000000.0,
          9.67,
          0.08,
          0.83,
          2.12,
          6.45,
          652940.0,
        ],
      },
      {
        "0135": [
          "0135",
          "EM",
          71860000000.0,
          8.3,
          0.26,
          3.23,
          10.04,
          33.78,
          147060000.0,
        ],
      },
      {
        "0136": [
          "0136",
          "EM",
          22620000000.0,
          2.45,
          0.07,
          2.94,
          24500.0,
          "-",
          87680000.0,
        ],
      },
      {
        "0137": [
          "0137",
          "EM",
          625740000.0,
          1.18,
          -0.04,
          -3.28,
          "-",
          "-",
          239450.0,
        ],
      },
      {
        "0138": [
          "0138",
          "EM",
          154540000.0,
          0.177,
          -0.003,
          -1.67,
          "-",
          "-",
          "-",
        ],
      },
      {
        "0139": ["0139", "EM", 159690000.0, 0.01, 0.0, 0.0, 5.56, "-", 73900.0],
      },
      { "0141": ["0141", "EM", 62160000.0, 0.18, 0.0, 0.0, "-", "-", "-"] },
      {
        "0142": [
          "0142",
          "EM",
          13080000000.0,
          3.06,
          0.06,
          2.0,
          8.5,
          5.39,
          21560000.0,
        ],
      },
      { "0143": ["0143", "EM", 387440000.0, 0.05, 0.0, 0.0, "-", "-", "-"] },
      {
        "0144": [
          "0144",
          "EM",
          56250000000.0,
          14.86,
          0.5,
          3.48,
          10.16,
          4.91,
          43020000.0,
        ],
      },
      {
        "0145": ["0145", "EM", 73260000.0, 0.14, 0.007, 5.26, "-", "-", 3740.0],
      },
      {
        "0146": ["0146", "EM", 207940000.0, 0.98, 0.05, 5.38, 12.5, 3.06, "-"],
      },
      {
        "0147": [
          "0147",
          "EM",
          4670000000.0,
          0.23,
          0.01,
          4.55,
          "-",
          "-",
          130460.00000000001,
        ],
      },
      {
        "0148": [
          "0148",
          "EM",
          43290000000.0,
          39.05,
          1.5,
          3.99,
          9.17,
          6.56,
          50840000.0,
        ],
      },
      {
        "0149": [
          "0149",
          "EM",
          1040000000.0,
          0.105,
          -0.002,
          -1.87,
          2.13,
          "-",
          53430.0,
        ],
      },
      {
        "0150": [
          "0150",
          "EM",
          2109999999.9999998,
          1.03,
          0.03,
          3.0,
          29.68,
          "-",
          97470.0,
        ],
      },
      {
        "0151": [
          "0151",
          "EM",
          92100000000.0,
          7.74,
          0.11,
          1.44,
          19.25,
          2.3,
          81790000.0,
        ],
      },
      {
        "0152": [
          "0152",
          "EM",
          18130000000.0,
          8.0,
          0.11,
          1.39,
          4.35,
          12.0,
          23910000.0,
        ],
      },
      { "0153": ["0153", "EM", 314150000.0, 0.104, 0.0, 0.0, 2.38, "-", "-"] },
      {
        "0154": ["0154", "EM", 825190000.0, 0.55, 0.0, 0.0, 6.4, "-", 39680.0],
      },
      { "0155": ["0155", "EM", 276930000.0, 0.18, 0.0, 0.0, "-", "-", "-"] },
      {
        "0156": [
          "0156",
          "EM",
          1120000000.0,
          0.123,
          -0.002,
          -1.6,
          "-",
          3.82,
          1250.0,
        ],
      },
      {
        "0157": ["0157", "EM", 1160000000.0, 0.58, 0.0, 0.0, 148.72, 0.52, "-"],
      },
      {
        "0158": [
          "0158",
          "EM",
          3630000000.0,
          145.3,
          -0.7,
          -0.48,
          54.33,
          2.48,
          726500.0,
        ],
      },
      {
        "0159": [
          "0159",
          "EM",
          2830000000.0,
          0.305,
          0.02,
          7.02,
          "-",
          "-",
          1070000.0,
        ],
      },
      {
        "0160": [
          "0160",
          "EM",
          2069999999.9999998,
          2.88,
          0.08,
          2.86,
          17.07,
          4.34,
          120600.0,
        ],
      },
      {
        "0162": [
          "0162",
          "EM",
          178200000.0,
          0.155,
          0.007,
          4.73,
          "-",
          "-",
          946410.0,
        ],
      },
      {
        "0163": [
          "0163",
          "EM",
          3450000000.0,
          0.94,
          0.03,
          3.3,
          "-",
          2.87,
          277380.0,
        ],
      },
      {
        "0164": ["0164", "EM", 195380000.0, 0.35, 0.0, 0.0, "-", "-", 90620.0],
      },
      {
        "0165": [
          "0165",
          "EM",
          15180000000.0,
          9.01,
          0.22,
          2.5,
          6.71,
          6.99,
          28610000.0,
        ],
      },
      {
        "0166": [
          "0166",
          "EM",
          490490000.0,
          0.056,
          -0.001,
          -1.75,
          "-",
          "-",
          8400.0,
        ],
      },
      {
        "0167": [
          "0167",
          "EM",
          72790000.0,
          0.028,
          0.002,
          7.69,
          "-",
          "-",
          16580.0,
        ],
      },
      {
        "0168": [
          "0168",
          "EM",
          47230000000.0,
          72.1,
          2.1,
          3.0,
          37.69,
          1.25,
          84480000.0,
        ],
      },
      {
        "0169": [
          "0169",
          "EM",
          1310000000.0,
          0.28,
          0.0,
          0.0,
          7.95,
          "-",
          38080.0,
        ],
      },
      {
        "0171": [
          "0171",
          "EM",
          1100000000.0,
          0.48,
          -0.005,
          -1.03,
          "-",
          "-",
          60300.0,
        ],
      },
      {
        "0173": [
          "0173",
          "EM",
          9930000000.0,
          3.17,
          0.12,
          3.93,
          3.03,
          6.62,
          7030000.0,
        ],
      },
      { "0174": ["0174", "EM", 425830000.0, 0.67, 0.0, 0.0, "-", "-", "-"] },
      {
        "0175": [
          "0175",
          "EM",
          172750000000.0,
          17.24,
          0.6,
          3.61,
          26.07,
          1.16,
          560550000.0,
        ],
      },
      {
        "0176": [
          "0176",
          "EM",
          91460000.0,
          0.045,
          -0.002,
          -4.26,
          "-",
          "-",
          166000.0,
        ],
      },
      {
        "0177": [
          "0177",
          "EM",
          9920000000.0,
          8.12,
          -0.01,
          -0.12,
          14.14,
          6.87,
          9890000.0,
        ],
      },
      {
        "0178": [
          "0178",
          "EM",
          4710000000.0,
          1.52,
          0.03,
          2.01,
          "-",
          "-",
          4460000.0,
        ],
      },
      {
        "0179": [
          "0179",
          "EM",
          12210000000.0,
          13.48,
          0.24,
          1.81,
          7.33,
          3.78,
          6080000.0,
        ],
      },
      { "0180": ["0180", "EM", 418250000.0, 0.44, 0.0, 0.0, "-", "-", "-"] },
      { "0181": ["0181", "EM", 142040000.0, 0.124, 0.0, 0.0, 68.89, "-", "-"] },
      {
        "0182": [
          "0182",
          "EM",
          6640000000.0,
          0.74,
          -0.01,
          -1.33,
          7.68,
          4.05,
          7970000.0,
        ],
      },
      { "0183": ["0183", "EM", 179460000.0, 0.32, 0.0, 0.0, 4.95, "-", "-"] },
      {
        "0184": [
          "0184",
          "EM",
          1030000000.0,
          3.03,
          -0.12,
          -3.81,
          "-",
          "-",
          262600.0,
        ],
      },
      {
        "0185": [
          "0185",
          "EM",
          8300000000.000001,
          4.34,
          -0.07,
          -1.59,
          5.23,
          2.26,
          2420000.0,
        ],
      },
      { "0186": ["0186", "EM", 2550000000.0, 0.465, 0.0, 0.0, "-", "-", "-"] },
      {
        "0187": [
          "0187",
          "EM",
          388000000.0,
          3.88,
          0.17,
          4.58,
          9.66,
          "-",
          2430000.0,
        ],
      },
      { "0188": ["0188", "EM", 277540000.0, 0.38, 0.0, 0.0, 8.56, 7.89, "-"] },
      {
        "0189": [
          "0189",
          "EM",
          21080000000.0,
          9.35,
          -0.08,
          -0.85,
          21.18,
          1.5,
          64420000.0,
        ],
      },
      { "0191": ["0191", "EM", 2200000000.0, 3.75, 0.0, 0.0, "-", "-", "-"] },
      { "0193": ["0193", "EM", 51490000.0, 0.265, 0.0, 0.0, "-", "-", "-"] },
      {
        "0194": ["0194", "EM", 2970000000.0, 7.85, 0.0, 0.0, 18.28, 5.48, "-"],
      },
      {
        "0195": [
          "0195",
          "EM",
          689830000.0,
          0.101,
          0.003,
          3.06,
          "-",
          "-",
          65069.99999999999,
        ],
      },
      {
        "0196": [
          "0196",
          "EM",
          1360000000.0,
          0.255,
          0.01,
          4.08,
          23.18,
          "-",
          977790.0,
        ],
      },
      {
        "0197": [
          "0197",
          "EM",
          258470000.00000003,
          0.123,
          -0.001,
          -0.81,
          "-",
          "-",
          386730.0,
        ],
      },
      {
        "0199": [
          "0199",
          "EM",
          1020000000.0,
          1.07,
          -0.03,
          -2.73,
          "-",
          9.35,
          328040.0,
        ],
      },
      {
        "0200": [
          "0200",
          "EM",
          14080000000.0,
          9.29,
          0.22,
          2.43,
          "-",
          "-",
          16090000.0,
        ],
      },
      { "0201": ["0201", "EM", 1110000000.0, 0.125, 0.0, 0.0, "-", "-", "-"] },
      {
        "0202": [
          "0202",
          "EM",
          1350000000.0,
          0.186,
          0.001,
          0.54,
          "-",
          "-",
          1860.0,
        ],
      },
      {
        "0204": [
          "0204",
          "-",
          130270000.00000001,
          0.057,
          -0.002,
          -3.39,
          "-",
          "-",
          36540.0,
        ],
      },
      { "0205": ["0205", "EM", 277260000.0, 0.45, 0.0, 0.0, "-", "-", 4500.0] },
      {
        "0206": [
          "0206",
          "EM",
          1230000000.0,
          0.38,
          0.015,
          4.11,
          13.77,
          "-",
          76850.0,
        ],
      },
      {
        "0207": [
          "0207",
          "EM",
          5400000000.0,
          0.38,
          0.0,
          0.0,
          4.47,
          7.89,
          4210000.0,
        ],
      },
      {
        "0209": [
          "0209",
          "EM",
          135480000.0,
          0.037,
          0.001,
          2.78,
          "-",
          "-",
          5180.0,
        ],
      },
      {
        "0210": [
          "0210",
          "EM",
          310200000.0,
          0.171,
          -0.009,
          -5.0,
          "-",
          "-",
          5810.0,
        ],
      },
      {
        "0211": [
          "0211",
          "EM",
          207210000.0,
          0.295,
          0.005,
          1.72,
          "-",
          "-",
          59600.0,
        ],
      },
      {
        "0212": [
          "0212",
          "EM",
          1340000000.0,
          39.5,
          0.3,
          0.77,
          15.63,
          3.54,
          19700.0,
        ],
      },
      {
        "0213": ["0213", "EM", 999930000.0, 1.04, 0.0, 0.0, 8.79, 3.37, 1820.0],
      },
      { "0214": ["0214", "EM", 739960000.0, 0.88, 0.0, 0.0, 0.73, 4.09, "-"] },
      {
        "0215": [
          "0215",
          "EM",
          6210000000.0,
          1.29,
          0.02,
          1.57,
          17.22,
          21.16,
          2200000.0,
        ],
      },
      {
        "0216": [
          "0216",
          "EM",
          882180000.0,
          1.6,
          0.01,
          0.63,
          11.33,
          3.13,
          6400.0,
        ],
      },
      {
        "0217": [
          "0217",
          "EM",
          960300000.0,
          0.161,
          0.001,
          0.63,
          7.7,
          5.59,
          128229.99999999999,
        ],
      },
      {
        "0218": [
          "0218",
          "EM",
          1430000000.0,
          0.92,
          0.0,
          0.0,
          8.18,
          4.35,
          27600.0,
        ],
      },
      {
        "0219": [
          "0219",
          "EM",
          753670000.0,
          1.3,
          -0.01,
          -0.76,
          "-",
          "-",
          27610.0,
        ],
      },
      {
        "0220": [
          "0220",
          "EM",
          31960000000.0,
          7.4,
          0.05,
          0.68,
          16.65,
          6.13,
          27710000.0,
        ],
      },
      {
        "0222": [
          "0222",
          "EM",
          2009999999.9999998,
          3.38,
          -0.2,
          -5.59,
          4.63,
          2.96,
          723860.0,
        ],
      },
      { "0223": ["0223", "EM", 21030000.0, "-", 0.0, 0.0, "-", "-", "-"] },
      { "0224": ["0224", "EM", 1240000000.0, 1.08, 0.0, 0.0, 4.26, 0.93, "-"] },
      {
        "0225": ["0225", "EM", 1240000000.0, 11.3, 0.02, 0.18, 9.08, 3.36, "-"],
      },
      { "0226": ["0226", "EM", 1320000000.0, 2.69, 0.0, 0.0, "-", 3.47, "-"] },
      {
        "0227": [
          "0227",
          "EM",
          302000000.0,
          0.193,
          -0.002,
          -1.03,
          "-",
          "-",
          65030.0,
        ],
      },
      {
        "0228": [
          "0228",
          "EM",
          1380000000.0,
          0.146,
          0.001,
          0.69,
          "-",
          "-",
          67760.0,
        ],
      },
      { "0229": ["0229", "EM", 501190000.0, 1.0, 0.01, 1.01, 7.82, 8.0, "-"] },
      {
        "0230": [
          "0230",
          "EM",
          2910000000.0,
          0.87,
          0.02,
          2.35,
          25.89,
          2.3,
          93600.0,
        ],
      },
      { "0231": ["0231", "EM", 51650000.0, 0.01, 0.0, 0.0, "-", "-", "-"] },
      {
        "0232": [
          "0232",
          "EM",
          1120000000.0,
          0.121,
          0.003,
          2.54,
          "-",
          "-",
          317790.0,
        ],
      },
      {
        "0234": ["0234", "EM", 346820000.0, 0.06, 0.0, 0.0, "-", "-", 220440.0],
      },
      {
        "0235": [
          "0235",
          "EM",
          856180000.0,
          0.042,
          0.001,
          2.44,
          0.32,
          "-",
          292420.0,
        ],
      },
      {
        "0236": [
          "0236",
          "EM",
          354890000.0,
          0.95,
          0.05,
          5.56,
          20.61,
          "-",
          7340.0,
        ],
      },
      {
        "0237": [
          "0237",
          "EM",
          1320000000.0,
          3.28,
          0.04,
          1.23,
          "-",
          15.85,
          228140.0,
        ],
      },
      { "0238": ["0238", "EM", 241950000.0, 0.255, 0.0, 0.0, "-", "-", "-"] },
      {
        "0239": [
          "0239",
          "EM",
          451870000.0,
          1.45,
          0.08,
          5.84,
          "-",
          4.55,
          43500.0,
        ],
      },
      {
        "0240": [
          "0240",
          "EM",
          1160000000.0,
          0.94,
          0.03,
          3.3,
          2.65,
          7.45,
          450300.0,
        ],
      },
      {
        "0241": [
          "0241",
          "EM",
          83100000000.0,
          6.16,
          0.33,
          5.66,
          195.56,
          "-",
          212770000.0,
        ],
      },
      {
        "0242": [
          "0242",
          "EM",
          6280000000.0,
          2.08,
          0.02,
          0.97,
          23.94,
          "-",
          1840000.0,
        ],
      },
      {
        "0243": [
          "0243",
          "EM",
          97010000.0,
          0.43,
          0.035,
          8.86,
          "-",
          "-",
          83370.0,
        ],
      },
      {
        "0244": [
          "0244",
          "EM",
          532150000.0,
          0.405,
          0.015,
          3.85,
          "-",
          "-",
          79000.0,
        ],
      },
      {
        "0245": [
          "0245",
          "EM",
          2430000000.0,
          0.07,
          0.002,
          2.94,
          7.14,
          "-",
          33140.0,
        ],
      },
      {
        "0247": ["0247", "EM", 45630000000.0, 22.9, 0.0, 0.0, 8.26, 3.62, "-"],
      },
      { "0248": ["0248", "EM", 102110000.0, 0.082, 0.0, 0.0, 21.58, "-", "-"] },
      { "0250": ["0250", "EM", 651610000.0, 0.03, 0.0, 0.0, 5.36, "-", "-"] },
      {
        "0251": [
          "0251",
          "EM",
          3250000000.0,
          5.4,
          0.22,
          4.25,
          23.52,
          0.93,
          1190000.0,
        ],
      },
      {
        "0252": ["0252", "EM", 732610000.0, 3.25, 0.0, 0.0, 116.91, 0.92, "-"],
      },
      { "0253": ["0253", "EM", 273930000.0, 0.9, 0.02, 2.27, "-", "-", "-"] },
      { "0254": ["0254", "EM", 981000000.0, 0.765, 0.0, 0.0, 24.92, "-", "-"] },
      {
        "0255": [
          "0255",
          "EM",
          2480000000.0,
          3.94,
          0.06,
          1.55,
          13.74,
          10.91,
          93920.0,
        ],
      },
      {
        "0256": [
          "0256",
          "EM",
          6220000000.0,
          1.43,
          0.02,
          1.42,
          "-",
          "-",
          4510000.0,
        ],
      },
      {
        "0257": [
          "0257",
          "EM",
          36670000000.0,
          5.97,
          0.21,
          3.65,
          6.1,
          5.86,
          78030000.0,
        ],
      },
      {
        "0258": [
          "0258",
          "EM",
          4019999999.9999995,
          2.04,
          -0.01,
          -0.49,
          22.97,
          2.7,
          393440.0,
        ],
      },
      {
        "0259": [
          "0259",
          "EM",
          2750000000.0,
          2.77,
          0.0,
          0.0,
          14.51,
          0.72,
          33040.0,
        ],
      },
      { "0260": ["0260", "EM", 124810000.0, 0.021, 0.0, 0.0, "-", "-", "-"] },
      { "0261": ["0261", "EM", 1830000000.0, 0.01, 0.0, 0.0, "-", "-", "-"] },
      {
        "0262": [
          "0262",
          "EM",
          174550000.0,
          0.119,
          0.003,
          2.59,
          "-",
          "-",
          248780.0,
        ],
      },
      { "0263": ["0263", "EM", 6560000.0, 0.039, 0.0, 0.0, "-", "-", "-"] },
      {
        "0264": ["0264", "EM", 359740000.0, 0.94, -0.01, -1.05, "-", "-", "-"],
      },
      { "0265": ["0265", "EM", 646100000.0, 0.05, 0.0, 0.0, "-", "-", "-"] },
      { "0266": ["0266", "EM", 2120000000.0, 4.48, 0.0, 0.0, "-", 3.57, "-"] },
      {
        "0267": [
          "0267",
          "EM",
          253950000000.0,
          8.73,
          0.03,
          0.34,
          4.48,
          6.16,
          89730000.0,
        ],
      },
      {
        "0268": [
          "0268",
          "EM",
          63890000000.0,
          18.4,
          0.76,
          4.31,
          "-",
          "-",
          350490000.0,
        ],
      },
      {
        "0269": [
          "0269",
          "EM",
          276740000.0,
          0.026,
          0.0,
          0.0,
          "-",
          "-",
          316720.0,
        ],
      },
      {
        "0270": [
          "0270",
          "EM",
          72170000000.0,
          11.04,
          -0.08,
          -0.72,
          16.0,
          5.39,
          130139999.99999999,
        ],
      },
      {
        "0271": [
          "0271",
          "EM",
          657550000.0,
          0.53,
          0.0,
          0.0,
          "-",
          179.25,
          11510.0,
        ],
      },
      {
        "0272": [
          "0272",
          "EM",
          9110000000.0,
          1.13,
          0.03,
          2.73,
          "-",
          3.19,
          4260000.0,
        ],
      },
      {
        "0273": ["0273", "EM", 931660000.0, 0.021, 0.0, 0.0, "-", "-", 49220.0],
      },
      {
        "0274": [
          "0274",
          "EM",
          803080000.0,
          0.53,
          0.035,
          7.07,
          "-",
          "-",
          699000.0,
        ],
      },
      {
        "0276": [
          "0276",
          "EM",
          205050000.0,
          1.09,
          0.06,
          5.83,
          "-",
          "-",
          81750.0,
        ],
      },
      { "0277": ["0277", "EM", 1060000000.0, 3.848, 0.0, 0.0, "-", 0.86, "-"] },
      { "0278": ["0278", "EM", 604800000.0, 5.0, 0.0, 0.0, 197.63, 6.8, "-"] },
      {
        "0279": [
          "0279",
          "EM",
          4090000000.0,
          0.219,
          0.006,
          2.82,
          "-",
          "-",
          502700.0,
        ],
      },
      {
        "0280": [
          "0280",
          "EM",
          369220000.0,
          0.405,
          0.005,
          1.25,
          12.94,
          3.46,
          23530.0,
        ],
      },
      { "0282": ["0282", "EM", 764500000.0, 0.29, 0.0, 0.0, "-", "-", "-"] },
      {
        "0285": [
          "0285",
          "EM",
          54070000000.0,
          24.0,
          0.65,
          2.78,
          8.46,
          1.22,
          69610000.0,
        ],
      },
      {
        "0286": [
          "0286",
          "EM",
          2740000000.0,
          0.64,
          0.04,
          6.67,
          "-",
          "-",
          7250000.0,
        ],
      },
      { "0287": ["0287", "EM", 369200000.0, 9.23, 0.03, 0.33, "-", 1.52, "-"] },
      {
        "0288": [
          "0288",
          "EM",
          66069999999.99999,
          5.15,
          -0.05,
          -0.96,
          11.83,
          3.4,
          197160000.0,
        ],
      },
      {
        "0289": ["0289", "EM", 5190000000.0, 17.82, 0.02, 0.11, "-", 5.95, "-"],
      },
      {
        "0290": [
          "0290",
          "EM",
          205020000.0,
          0.224,
          0.003,
          1.36,
          "-",
          "-",
          787150.0,
        ],
      },
      {
        "0291": [
          "0291",
          "EM",
          198050000000.0,
          61.05,
          3.0,
          5.17,
          80.11,
          0.78,
          297810000.0,
        ],
      },
      { "0292": ["0292", "EM", 373330000.0, 0.185, 0.0, 0.0, 0.6, 3.51, "-"] },
      {
        "0293": [
          "0293",
          "EM",
          42480000000.0,
          6.6,
          0.12,
          1.85,
          "-",
          "-",
          34020000.0,
        ],
      },
      { "0294": ["0294", "EM", 417630000.0, 2.02, 0.0, 0.0, "-", "-", "-"] },
      { "0295": ["0295", "EM", 778150000.0, 0.052, 0.0, 0.0, "-", "-", "-"] },
      {
        "0296": ["0296", "EM", 957840000.0, 0.8, 0.0, 0.0, "-", 3.75, 52000.0],
      },
      {
        "0297": [
          "0297",
          "EM",
          7440000000.0,
          1.06,
          0.02,
          1.92,
          9.87,
          3.08,
          4560000.0,
        ],
      },
      {
        "0298": [
          "0298",
          "EM",
          1040000000.0,
          0.445,
          0.015,
          3.49,
          2.49,
          7.87,
          720800.0,
        ],
      },
      {
        "0299": ["0299", "EM", 273000000.0, 0.05, 0.0, 0.0, "-", "-", 171020.0],
      },
      {
        "0301": [
          "0301",
          "EM",
          577120000.0,
          1.35,
          0.07,
          5.47,
          7.36,
          2.22,
          270980.0,
        ],
      },
      {
        "0302": [
          "0302",
          "EM",
          8520000000.0,
          3.08,
          0.06,
          1.99,
          8.73,
          3.01,
          17050000.0,
        ],
      },
      {
        "0303": [
          "0303",
          "EM",
          16239999999.999998,
          64.35,
          2.8,
          4.55,
          9.08,
          10.99,
          55140000.0,
        ],
      },
      {
        "0305": [
          "0305",
          "EM",
          4650000000.0,
          1.41,
          0.06,
          4.44,
          "-",
          0.21,
          7320000.0,
        ],
      },
      {
        "0306": [
          "0306",
          "EM",
          929710000.0,
          1.95,
          0.01,
          0.52,
          "-",
          "-",
          11640.0,
        ],
      },
      {
        "0308": [
          "0308",
          "EM",
          9080000000.0,
          1.64,
          0.04,
          2.5,
          "-",
          "-",
          5540000.0,
        ],
      },
      { "0309": ["0309", "EM", 122730000.0, 0.068, 0.0, 0.0, 5.67, "-", "-"] },
      {
        "0310": ["0310", "-", 35120000.0, 0.029, -0.001, -3.33, "-", "-", "-"],
      },
      {
        "0311": [
          "0311",
          "EM",
          480860000.0,
          0.465,
          0.005,
          1.09,
          "-",
          "-",
          8280.0,
        ],
      },
      {
        "0312": [
          "0312",
          "EM",
          346800000.0,
          0.139,
          0.001,
          0.72,
          "-",
          "-",
          10660.0,
        ],
      },
      {
        "0313": [
          "0313",
          "EM",
          256700000.0,
          0.011,
          0.001,
          10.0,
          "-",
          "-",
          7050.0,
        ],
      },
      {
        "0315": [
          "0315",
          "EM",
          4870000000.0,
          4.4,
          0.05,
          1.15,
          11.03,
          6.82,
          3300000.0,
        ],
      },
      {
        "0316": [
          "0316",
          "EM",
          131870000000.0,
          199.7,
          7.7,
          4.01,
          17.89,
          22.57,
          169860000.0,
        ],
      },
      {
        "0317": [
          "0317",
          "EM",
          4030000000.0000005,
          6.81,
          0.06,
          0.89,
          2.24,
          2.85,
          1580000.0,
        ],
      },
      { "0318": ["0318", "EM", 99910000.0, 0.44, 0.0, 0.0, 4.18, "-", "-"] },
      {
        "0320": [
          "0320",
          "EM",
          758280000.0,
          0.9,
          -0.01,
          -1.1,
          9.29,
          6.61,
          55560.0,
        ],
      },
      {
        "0321": [
          "0321",
          "EM",
          2120000000.0,
          1.54,
          0.03,
          1.99,
          7.76,
          11.69,
          539160.0,
        ],
      },
      {
        "0322": [
          "0322",
          "EM",
          92340000000.0,
          16.4,
          0.26,
          1.61,
          19.23,
          8.51,
          76300000.0,
        ],
      },
      {
        "0323": [
          "0323",
          "EM",
          5380000000.0,
          3.11,
          0.07,
          2.3,
          10.25,
          5.02,
          19010000.0,
        ],
      },
      {
        "0326": [
          "0326",
          "EM",
          2230000000.0,
          0.9,
          0.03,
          3.45,
          "-",
          "-",
          105200.0,
        ],
      },
      {
        "0327": [
          "0327",
          "EM",
          6350000000.0,
          5.83,
          0.21,
          3.74,
          7.04,
          3.77,
          11870000.0,
        ],
      },
      {
        "0328": [
          "0328",
          "EM",
          97630000.0,
          0.135,
          -0.011,
          -7.53,
          "-",
          "-",
          16590.0,
        ],
      },
      {
        "0329": [
          "0329",
          "EM",
          4690000000.0,
          3.13,
          -0.21,
          -6.29,
          "-",
          "-",
          2400000.0,
        ],
      },
      {
        "0330": [
          "0330",
          "EM",
          2089999999.9999998,
          0.74,
          0.01,
          1.37,
          "-",
          "-",
          1220000.0,
        ],
      },
      {
        "0331": [
          "0331",
          "EM",
          2650000000.0,
          5.91,
          0.08,
          1.37,
          4.58,
          7.61,
          795870.0,
        ],
      },
      {
        "0332": [
          "0332",
          "EM",
          3990000000.0,
          0.61,
          0.04,
          7.02,
          1220.0,
          "-",
          10600000.0,
        ],
      },
      {
        "0333": ["0333", "EM", 159550000.0, 0.53, 0.0, 0.0, 48.62, "-", 1060.0],
      },
      {
        "0334": [
          "0334",
          "EM",
          1030000000.0,
          0.49,
          0.01,
          2.08,
          34.51,
          "-",
          94720.0,
        ],
      },
      {
        "0335": [
          "0335",
          "EM",
          1740000000.0,
          0.65,
          -0.01,
          -1.52,
          37.79,
          1.54,
          "-",
        ],
      },
      {
        "0336": [
          "0336",
          "EM",
          16660000000.0,
          5.16,
          0.37,
          7.72,
          32.47,
          2.98,
          66500000.0,
        ],
      },
      {
        "0337": [
          "0337",
          "EM",
          4320000000.0,
          1.55,
          0.02,
          1.31,
          1.4,
          19.35,
          585260.0,
        ],
      },
      {
        "0338": [
          "0338",
          "EM",
          6110000000.0,
          1.75,
          0.04,
          2.34,
          24.89,
          6.91,
          13590000.0,
        ],
      },
      { "0339": ["0339", "-", 75750000.0, 0.315, 0.0, 0.0, "-", "-", "-"] },
      {
        "0340": [
          "0340",
          "EM",
          2540000000.0,
          0.75,
          0.0,
          0.0,
          "-",
          "-",
          192580.0,
        ],
      },
      {
        "0341": [
          "0341",
          "EM",
          7780000000.0,
          13.3,
          0.08,
          0.61,
          21.46,
          2.86,
          4110000.0000000005,
        ],
      },
      {
        "0342": [
          "0342",
          "EM",
          85150000.0,
          0.058,
          -0.001,
          -1.69,
          0.13,
          "-",
          24610.0,
        ],
      },
      {
        "0343": [
          "0343",
          "EM",
          1280000000.0,
          0.77,
          0.05,
          6.94,
          "-",
          "-",
          3110000.0,
        ],
      },
      {
        "0345": [
          "0345",
          "EM",
          16219999999.999998,
          15.16,
          -0.04,
          -0.26,
          29.43,
          2.16,
          42850000.0,
        ],
      },
      {
        "0346": [
          "0346",
          "EM",
          806740000.0,
          0.044,
          0.002,
          4.76,
          "-",
          "-",
          97220.0,
        ],
      },
      {
        "0347": [
          "0347",
          "EM",
          4920000000.0,
          3.49,
          0.1,
          2.95,
          14.19,
          2.89,
          16410000.0,
        ],
      },
      {
        "0348": [
          "0348",
          "EM",
          192620000.0,
          0.25,
          -0.01,
          -3.85,
          "-",
          "-",
          1720.0,
        ],
      },
      { "0351": ["0351", "EM", 618660000.0, 0.365, 0.0, 0.0, 3.42, "-", "-"] },
      {
        "0352": [
          "0352",
          "EM",
          118160000.0,
          0.48,
          -0.005,
          -1.03,
          2400.0,
          "-",
          "-",
        ],
      },
      { "0353": ["0353", "EM", 360280000.0, 0.05, 0.0, 0.0, 71.43, "-", "-"] },
      {
        "0354": [
          "0354",
          "EM",
          24020000000.0,
          7.83,
          0.09,
          1.16,
          17.6,
          0.37,
          53900000.0,
        ],
      },
      {
        "0355": [
          "0355",
          "EM",
          1190000000.0,
          0.385,
          0.005,
          1.32,
          "-",
          "-",
          14720.0,
        ],
      },
      { "0356": ["0356", "-", 106670000.0, 0.039, 0.0, 0.0, "-", "-", 5910.0] },
      {
        "0357": [
          "0357",
          "EM",
          4600000000.0,
          20.3,
          -0.4,
          -1.93,
          "-",
          "-",
          7580000.0,
        ],
      },
      {
        "0358": [
          "0358",
          "EM",
          17980000000.0,
          12.96,
          0.36,
          2.86,
          17.15,
          0.94,
          55890000.0,
        ],
      },
      {
        "0359": ["0359", "EM", 79960000.0, 0.062, -0.004, -6.06, "-", "-", "-"],
      },
      {
        "0360": [
          "0360",
          "EM",
          433120000.0,
          0.064,
          0.005,
          8.47,
          "-",
          "-",
          4100.0,
        ],
      },
      {
        "0361": [
          "0361",
          "EM",
          312070000.0,
          0.06,
          -0.004,
          -6.25,
          "-",
          "-",
          457260.0,
        ],
      },
      {
        "0362": [
          "0362",
          "EM",
          276690000.0,
          0.104,
          0.0,
          0.0,
          "-",
          "-",
          166910.0,
        ],
      },
      {
        "0363": [
          "0363",
          "EM",
          12650000000.0,
          11.64,
          0.14,
          1.22,
          5.78,
          8.59,
          8890000.0,
        ],
      },
      {
        "0365": [
          "0365",
          "EM",
          960300000.0,
          0.66,
          -0.01,
          -1.49,
          48.89,
          "-",
          7560.0,
        ],
      },
      {
        "0366": [
          "0366",
          "EM",
          668400000.0,
          1.33,
          0.03,
          2.31,
          10.91,
          4.51,
          10580.0,
        ],
      },
      {
        "0367": [
          "0367",
          "EM",
          1520000000.0,
          0.91,
          0.03,
          3.41,
          40.63,
          3.85,
          99390.0,
        ],
      },
      { "0368": ["0368", "EM", 348000000.0, 0.435, 0.0, 0.0, 16.67, "-", "-"] },
      { "0369": ["0369", "EM", 6030000000.0, 4.45, 0.0, 0.0, "-", 6.07, "-"] },
      {
        "0370": [
          "0370",
          "EM",
          808400000.0,
          0.53,
          0.01,
          1.92,
          "-",
          "-",
          597360.0,
        ],
      },
      {
        "0371": [
          "0371",
          "EM",
          30910000000.0,
          3.05,
          0.02,
          0.66,
          7.34,
          5.51,
          44900000.0,
        ],
      },
      { "0372": ["0372", "EM", 504570000.0, 0.25, 0.0, 0.0, 3.02, "-", "-"] },
      {
        "0373": [
          "0373",
          "EM",
          10120000000.0,
          2.88,
          0.01,
          0.35,
          4.97,
          4.34,
          223060.0,
        ],
      },
      {
        "0374": ["0374", "EM", 1060000000.0, 2.78, 0.0, 0.0, 35.46, 3.42, "-"],
      },
      { "0375": ["0375", "EM", 320110000.0, 1.93, 0.0, 0.0, "-", 10.36, "-"] },
      {
        "0376": [
          "0376",
          "EM",
          6920000000.0,
          1.79,
          0.07,
          4.07,
          9.61,
          "-",
          375820.0,
        ],
      },
      { "0377": ["0377", "EM", 108310000.0, 1.76, 0.0, 0.0, "-", "-", "-"] },
      { "0378": ["0378", "EM", 121550000.0, 0.018, 0.0, 0.0, "-", "-", "-"] },
      {
        "0379": [
          "0379",
          "EM",
          264899999.99999997,
          0.157,
          0.045,
          40.18,
          "-",
          "-",
          7850.0,
        ],
      },
      {
        "0380": [
          "0380",
          "EM",
          159870000.0,
          0.12,
          -0.007,
          -5.51,
          7.95,
          "-",
          "-",
        ],
      },
      {
        "0381": [
          "0381",
          "EM",
          65650000.00000001,
          0.057,
          0.001,
          1.79,
          "-",
          "-",
          1720.0,
        ],
      },
      {
        "0382": [
          "0382",
          "EM",
          3650000000.0,
          3.41,
          0.06,
          1.79,
          7.16,
          4.08,
          4910000.0,
        ],
      },
      {
        "0383": [
          "0383",
          "EM",
          984640000.0,
          0.068,
          -0.001,
          -1.45,
          "-",
          "-",
          7480.0,
        ],
      },
      {
        "0384": [
          "0384",
          "EM",
          73180000000.0,
          13.22,
          -0.02,
          -0.15,
          6.58,
          4.16,
          243450000.0,
        ],
      },
      { "0385": ["0385", "EM", 505660000.0, 0.85, 0.0, 0.0, 4.07, 4.71, "-"] },
      {
        "0386": [
          "0386",
          "EM",
          105620000000.0,
          4.14,
          0.06,
          1.47,
          12.97,
          8.28,
          310710000.0,
        ],
      },
      {
        "0387": [
          "0387",
          "EM",
          186360000.0,
          0.81,
          0.01,
          1.25,
          41.97,
          7.41,
          3320.0,
        ],
      },
      {
        "0388": [
          "0388",
          "EM",
          561140000000.0,
          442.6,
          4.2,
          0.96,
          48.58,
          2.07,
          2120000000.0,
        ],
      },
      {
        "0389": [
          "0389",
          "EM",
          235000000.0,
          0.089,
          -0.001,
          -1.11,
          "-",
          "-",
          24150.0,
        ],
      },
      {
        "0390": [
          "0390",
          "EM",
          20610000000.0,
          4.9,
          0.1,
          2.08,
          4.35,
          4.42,
          83120000.0,
        ],
      },
      {
        "0391": [
          "0391",
          "EM",
          574600000.0,
          0.097,
          0.001,
          1.04,
          "-",
          "-",
          1960.0,
        ],
      },
      {
        "0392": [
          "0392",
          "EM",
          34259999999.999996,
          27.15,
          0.65,
          2.45,
          6.48,
          4.2,
          38700000.0,
        ],
      },
      {
        "0393": [
          "0393",
          "EM",
          1230000000.0,
          0.81,
          0.0,
          0.0,
          13.75,
          7.65,
          25640.0,
        ],
      },
      { "0395": ["0395", "EM", 90170000.0, 0.016, 0.0, 0.0, "-", "-", "-"] },
      {
        "0396": ["0396", "EM", 111510000.0, 0.138, 0.0, 0.0, "-", "-", 17840.0],
      },
      {
        "0397": [
          "0397",
          "EM",
          392480000.0,
          0.141,
          0.005,
          3.68,
          8.87,
          "-",
          964790.0,
        ],
      },
      {
        "0398": [
          "0398",
          "EM",
          2210000000.0,
          4.55,
          0.19,
          4.36,
          10.54,
          16.13,
          8460000.0,
        ],
      },
      {
        "0399": ["0399", "EM", 247440000.0, 0.169, 0.0, 0.0, "-", "-", 70870.0],
      },
      {
        "0400": [
          "0400",
          "EM",
          3440000000.0,
          2.43,
          -0.01,
          -0.41,
          23.39,
          "-",
          1380000.0,
        ],
      },
      {
        "0401": ["0401", "EM", 119880000.0, 0.214, 0.0, 0.0, "-", "-", 51360.0],
      },
      {
        "0403": [
          "0403",
          "EM",
          168040000.0,
          0.32,
          -0.005,
          -1.54,
          "-",
          34.38,
          215350.0,
        ],
      },
      {
        "0405": [
          "0405",
          "R",
          15350000000.0,
          3.3,
          0.03,
          0.92,
          8.64,
          7.25,
          9570000.0,
        ],
      },
      {
        "0406": [
          "0406",
          "EM",
          639550000.0,
          1.46,
          -0.04,
          -2.67,
          4.25,
          6.37,
          29200.0,
        ],
      },
      {
        "0408": [
          "0408",
          "EM",
          2530000000.0,
          4.46,
          0.06,
          1.36,
          8.29,
          8.52,
          755800.0,
        ],
      },
      {
        "0410": [
          "0410",
          "EM",
          9150000000.0,
          1.76,
          0.06,
          3.53,
          14.5,
          "-",
          4200000.0,
        ],
      },
      {
        "0411": ["0411", "EM", 3740000000.0, 15.38, 0.0, 0.0, 10.18, 3.12, "-"],
      },
      {
        "0412": [
          "0412",
          "EM",
          17820000000.0,
          0.74,
          0.02,
          2.78,
          "-",
          "-",
          10910000.0,
        ],
      },
      {
        "0413": [
          "0413",
          "EM",
          1280000000.0,
          0.097,
          0.004,
          4.3,
          17.96,
          "-",
          3100.0,
        ],
      },
      { "0416": ["0416", "EM", 4950000000.0, 1.41, 0.05, 3.68, "-", "-", "-"] },
      {
        "0417": [
          "0417",
          "EM",
          294030000.0,
          1.18,
          0.09,
          8.26,
          "-",
          "-",
          13680.0,
        ],
      },
      { "0418": ["0418", "EM", 959790000.0, 0.8, 0.0, 0.0, 17.78, "-", "-"] },
      {
        "0419": [
          "0419",
          "EM",
          2470000000.0,
          0.183,
          0.001,
          0.55,
          "-",
          "-",
          1470000.0,
        ],
      },
      {
        "0420": [
          "0420",
          "EM",
          1710000000.0,
          1.4,
          0.03,
          2.19,
          20.0,
          5.0,
          2590000.0,
        ],
      },
      {
        "0422": [
          "0422",
          "EM",
          224190000.0,
          0.247,
          -0.003,
          -1.2,
          "-",
          "-",
          5690.0,
        ],
      },
      {
        "0423": [
          "0423",
          "EM",
          552440000.0,
          1.28,
          0.03,
          2.4,
          17.88,
          7.03,
          22120.0,
        ],
      },
      {
        "0425": [
          "0425",
          "EM",
          42790000000.0,
          36.85,
          1.15,
          3.22,
          25.87,
          1.55,
          46770000.0,
        ],
      },
      {
        "0426": [
          "0426",
          "EM",
          78570000.0,
          0.196,
          -0.002,
          -1.01,
          "-",
          "-",
          60280.0,
        ],
      },
      {
        "0428": [
          "0428",
          "-",
          135970000.0,
          0.315,
          0.01,
          3.28,
          "-",
          "-",
          3500000.0,
        ],
      },
      { "0430": ["0430", "EM", 166910000.0, 0.43, 0.0, 0.0, "-", 6.98, "-"] },
      {
        "0431": [
          "0431",
          "EM",
          1520000000.0,
          0.196,
          -0.009,
          -4.39,
          "-",
          "-",
          104150.0,
        ],
      },
      {
        "0432": [
          "0432",
          "EM",
          1200000000.0,
          0.59,
          0.01,
          1.72,
          "-",
          "-",
          161380.0,
        ],
      },
      { "0433": ["0433", "EM", 303810000.0, 0.013, 0.0, 0.0, "-", "-", "-"] },
      {
        "0434": [
          "0434",
          "EM",
          351380000.0,
          0.495,
          0.005,
          1.02,
          "-",
          "-",
          43430.0,
        ],
      },
      {
        "0435": [
          "0435",
          "R",
          7210000000.0,
          4.3,
          0.06,
          1.42,
          "-",
          5.95,
          5110000.0,
        ],
      },
      { "0436": ["0436", "EM", 925880000.0, 0.305, 0.0, 0.0, 12.4, 2.36, "-"] },
      {
        "0438": [
          "0438",
          "EM",
          1740000000.0,
          18.16,
          -0.22,
          -1.2,
          9.37,
          "-",
          298960.0,
        ],
      },
      {
        "0439": [
          "0439",
          "EM",
          1210000000.0,
          0.198,
          0.0,
          0.0,
          "-",
          "-",
          46130.0,
        ],
      },
      {
        "0440": [
          "0440",
          "EM",
          8340000000.0,
          26.1,
          0.9,
          3.57,
          7.2,
          3.83,
          11900000.0,
        ],
      },
      {
        "0442": ["0442", "EM", 293420000.0, 1.7, 0.1, 6.25, "-", "-", 22130.0],
      },
      {
        "0444": ["0444", "EM", 350540000.0, 0.058, 0.003, 5.45, "-", "-", "-"],
      },
      { "0449": ["0449", "EM", 362660000.0, 0.043, 0.0, 0.0, "-", "-", "-"] },
      {
        "0450": [
          "0450",
          "EM",
          1180000000.0,
          1.3,
          0.0,
          0.0,
          10.79,
          10.77,
          327480.0,
        ],
      },
      {
        "0451": [
          "0451",
          "EM",
          3580000000.0,
          0.17,
          -0.003,
          -1.73,
          "-",
          "-",
          9370000.0,
        ],
      },
      {
        "0455": [
          "0455",
          "EM",
          483750000.0,
          0.225,
          -0.012,
          -5.06,
          "-",
          1.16,
          13080.0,
        ],
      },
      {
        "0456": [
          "0456",
          "EM",
          116830000.0,
          0.027,
          -0.001,
          -3.57,
          "-",
          "-",
          12640.0,
        ],
      },
      { "0458": ["0458", "EM", 239010000.0, 0.88, 0.0, 0.0, "-", "-", "-"] },
      {
        "0459": [
          "0459",
          "EM",
          222040000.0,
          0.123,
          -0.005,
          -3.91,
          "-",
          "-",
          113740.0,
        ],
      },
      {
        "0460": [
          "0460",
          "EM",
          13800000000.0,
          1.48,
          0.03,
          2.07,
          25.08,
          1.08,
          29330000.0,
        ],
      },
      { "0462": ["0462", "EM", 5950000000.0, 2.2, 0.0, 0.0, "-", "-", "-"] },
      {
        "0464": ["0464", "EM", 178250000.0, 0.4, 0.0, 0.0, "-", "-", 586980.0],
      },
      { "0465": ["0465", "EM", 140060000.0, 0.45, 0.0, 0.0, 40.18, "-", "-"] },
      {
        "0467": [
          "0467",
          "EM",
          30750000000.0,
          1.17,
          0.02,
          1.74,
          35.45,
          2.02,
          27930000.0,
        ],
      },
      {
        "0468": [
          "0468",
          "EM",
          3980000000.0,
          2.98,
          -0.02,
          -0.67,
          9.93,
          8.72,
          18970000.0,
        ],
      },
      {
        "0471": [
          "0471",
          "EM",
          354730000.0,
          0.74,
          0.01,
          1.37,
          "-",
          "-",
          304150.0,
        ],
      },
      { "0472": ["0472", "EM", 368870000.0, 0.115, 0.0, 0.0, "-", "-", "-"] },
      { "0474": ["0474", "EM", 1440000000.0, 0.196, 0.0, 0.0, "-", "-", "-"] },
      {
        "0475": [
          "0475",
          "EM",
          344930000.0,
          0.89,
          0.04,
          4.71,
          "-",
          "-",
          24320.0,
        ],
      },
      {
        "0476": [
          "0476",
          "EM",
          747800000.0,
          0.083,
          0.002,
          2.47,
          "-",
          "-",
          522220.0,
        ],
      },
      { "0479": ["0479", "EM", 52020000.0, 0.01, 0.0, 0.0, "-", "-", "-"] },
      {
        "0480": [
          "0480",
          "EM",
          4640000000.0,
          3.13,
          0.0,
          0.0,
          5.18,
          2.56,
          92000.0,
        ],
      },
      {
        "0482": ["0482", "EM", 121560000.0, 0.247, -0.001, -0.4, "-", "-", "-"],
      },
      {
        "0483": [
          "0483",
          "EM",
          264510000.0,
          0.72,
          0.01,
          1.41,
          2.65,
          153.47,
          51840.0,
        ],
      },
      {
        "0484": ["0484", "EM", 183400000.0, 1.25, 0.01, 0.81, "-", "-", 7750.0],
      },
      { "0485": ["0485", "EM", 47670000.0, 0.224, 0.0, 0.0, "-", "-", "-"] },
      {
        "0486": [
          "0486",
          "EM",
          108020000000.0,
          7.11,
          -0.06,
          -0.84,
          18.38,
          "-",
          9690000.0,
        ],
      },
      { "0487": ["0487", "EM", 630590000.0, 0.128, 0.003, 2.4, "-", "-", "-"] },
      {
        "0488": ["0488", "EM", 3970000000.0, 4.1, 0.0, 0.0, "-", "-", 135440.0],
      },
      {
        "0489": [
          "0489",
          "EM",
          19790000000.0,
          6.93,
          0.08,
          1.17,
          4.71,
          6.95,
          34030000.0,
        ],
      },
      {
        "0491": [
          "0491",
          "EM",
          160660000.0,
          0.05,
          0.003,
          6.38,
          "-",
          "-",
          51210.0,
        ],
      },
      {
        "0493": [
          "0493",
          "EM",
          18220000000.0,
          0.54,
          0.01,
          1.89,
          "-",
          "-",
          75190000.0,
        ],
      },
      { "0495": ["0495", "EM", 200400000.0, 0.143, 0.0, 0.0, "-", "-", "-"] },
      {
        "0496": ["0496", "EM", 1090000000.0, 0.73, 0.0, 0.0, 8.1, "-", 16110.0],
      },
      {
        "0497": [
          "0497",
          "EM",
          1970000000.0,
          0.21,
          0.001,
          0.48,
          6.12,
          2.0,
          583740.0,
        ],
      },
      {
        "0498": [
          "0498",
          "EM",
          118110000.0,
          0.107,
          -0.002,
          -1.83,
          0.78,
          "-",
          "-",
        ],
      },
      {
        "0499": [
          "0499",
          "EM",
          269600000.0,
          0.27,
          0.021,
          8.43,
          50.94,
          "-",
          73170.0,
        ],
      },
      {
        "0500": ["0500", "EM", 1640000000.0, 0.7, 0.0, 0.0, "-", "-", 150310.0],
      },
      {
        "0503": [
          "0503",
          "EM",
          422590000.0,
          1.12,
          -0.02,
          -1.75,
          0.65,
          138.39,
          11080.0,
        ],
      },
      { "0505": ["0505", "EM", 1000000000.0, 1.12, 0.0, 0.0, 5.22, "-", "-"] },
      {
        "0506": [
          "0506",
          "EM",
          9000000000.0,
          3.22,
          0.0,
          0.0,
          15.3,
          3.29,
          3240000.0,
        ],
      },
      {
        "0508": [
          "0508",
          "EM",
          691530000.0,
          0.094,
          -0.001,
          -1.05,
          16.21,
          "-",
          "-",
        ],
      },
      {
        "0509": [
          "0509",
          "EM",
          160330000.0,
          0.035,
          0.001,
          2.94,
          "-",
          "-",
          47250.0,
        ],
      },
      { "0510": ["0510", "EM", 79650000.0, 0.305, 0.0, 0.0, "-", "-", "-"] },
      {
        "0511": [
          "0511",
          "EM",
          2130000000.0,
          4.88,
          0.06,
          1.24,
          "-",
          "-",
          2690000.0,
        ],
      },
      {
        "0512": [
          "0512",
          "EM",
          19770000000.0,
          5.57,
          0.04,
          0.72,
          10.71,
          1.97,
          7940000.0,
        ],
      },
      { "0513": ["0513", "EM", 286900000.0, 0.42, 0.0, 0.0, 14.43, 4.76, "-"] },
      {
        "0515": [
          "0515",
          "EM",
          139460000.0,
          0.05,
          -0.002,
          -3.85,
          "-",
          "-",
          15600.0,
        ],
      },
      {
        "0517": [
          "0517",
          "EM",
          3770000000.0,
          2.46,
          0.0,
          0.0,
          11.14,
          10.37,
          1960000.0,
        ],
      },
      {
        "0518": [
          "0518",
          "EM",
          142080000.0,
          0.315,
          -0.005,
          -1.56,
          0.56,
          4.76,
          3140.0,
        ],
      },
      {
        "0519": [
          "0519",
          "EM",
          260529999.99999997,
          0.104,
          0.0,
          0.0,
          "-",
          "-",
          94940.0,
        ],
      },
      {
        "0520": [
          "0520",
          "EM",
          5720000000.0,
          5.27,
          0.35,
          7.11,
          2635.0,
          1.28,
          18070000.0,
        ],
      },
      {
        "0521": [
          "0521",
          "EM",
          980390000.0,
          0.086,
          0.003,
          3.61,
          23.89,
          "-",
          316340.0,
        ],
      },
      {
        "0522": [
          "0522",
          "EM",
          32600000000.0,
          79.0,
          1.6,
          2.07,
          19.92,
          4.18,
          80070000.0,
        ],
      },
      { "0524": ["0524", "EM", 129180000.0, 0.082, 0.0, 0.0, 5.47, "-", "-"] },
      {
        "0525": [
          "0525",
          "EM",
          1960000000.0,
          1.37,
          0.0,
          0.0,
          "-",
          "-",
          1800000.0,
        ],
      },
      {
        "0526": [
          "0526",
          "EM",
          394150000.0,
          0.049,
          -0.003,
          -5.77,
          "-",
          "-",
          26700.0,
        ],
      },
      {
        "0527": [
          "0527",
          "EM",
          348320000.0,
          0.176,
          0.002,
          1.15,
          "-",
          "-",
          75700.0,
        ],
      },
      {
        "0528": ["0528", "EM", 755610000.0, 1.2, 0.04, 3.45, "-", "-", 13250.0],
      },
      { "0529": ["0529", "EM", 555930000.0, 2.0, 0.0, 0.0, "-", "-", "-"] },
      {
        "0530": [
          "0530",
          "EM",
          1290000000.0,
          0.185,
          -0.002,
          -1.07,
          "-",
          "-",
          30660.0,
        ],
      },
      {
        "0531": [
          "0531",
          "EM",
          1070000000.0000001,
          0.345,
          0.005,
          1.47,
          "-",
          "-",
          205730.0,
        ],
      },
      { "0532": ["0532", "EM", 758930000.0, 1.04, 0.01, 0.97, 7.5, 7.69, "-"] },
      {
        "0533": [
          "0533",
          "EM",
          1590000000.0,
          1.62,
          -0.01,
          -0.61,
          10.73,
          6.48,
          "-",
        ],
      },
      {
        "0535": [
          "0535",
          "EM",
          14920000000.0,
          0.9,
          0.03,
          3.45,
          2.85,
          10.74,
          9990000.0,
        ],
      },
      {
        "0536": [
          "0536",
          "EM",
          937660000.0,
          1.18,
          0.01,
          0.85,
          12.88,
          8.52,
          455080.0,
        ],
      },
      {
        "0538": [
          "0538",
          "EM",
          1400000000.0,
          1.29,
          0.01,
          0.78,
          "-",
          9.22,
          166680.0,
        ],
      },
      { "0539": ["0539", "EM", 6750000.0, "-", 0.0, 0.0, 0.13, "-", "-"] },
      {
        "0540": [
          "0540",
          "EM",
          131400000.0,
          0.219,
          -0.002,
          -0.9,
          "-",
          "-",
          9710.0,
        ],
      },
      { "0542": ["0542", "EM", 861340000.0, 0.124, 0.0, 0.0, "-", "-", "-"] },
      {
        "0543": [
          "0543",
          "EM",
          1480000000.0,
          1.31,
          0.01,
          0.77,
          7.78,
          11.66,
          382820.0,
        ],
      },
      {
        "0544": [
          "0544",
          "EM",
          66720000.0,
          0.023,
          -0.004,
          -14.81,
          "-",
          "-",
          59580.0,
        ],
      },
      {
        "0546": [
          "0546",
          "EM",
          7340000000.0,
          2.9,
          0.05,
          1.75,
          9.9,
          4.79,
          1230000.0,
        ],
      },
      {
        "0547": [
          "0547",
          "EM",
          3240000000.0,
          0.75,
          -0.05,
          -6.25,
          "-",
          "-",
          8039999.999999999,
        ],
      },
      {
        "0548": [
          "0548",
          "EM",
          5740000000.0,
          7.68,
          -0.03,
          -0.39,
          6.96,
          6.75,
          5370000.0,
        ],
      },
      { "0550": ["0550", "EM", 180870000.0, 0.405, 0.0, 0.0, "-", "-", "-"] },
      {
        "0551": [
          "0551",
          "EM",
          21500000000.0,
          13.34,
          0.2,
          1.52,
          "-",
          "-",
          22730000.0,
        ],
      },
      {
        "0552": [
          "0552",
          "EM",
          10040000000.0,
          4.2,
          0.06,
          1.45,
          8.03,
          4.62,
          25150000.0,
        ],
      },
      {
        "0553": [
          "0553",
          "EM",
          1050000000.0,
          4.34,
          0.33,
          8.23,
          43.71,
          0.71,
          1250000.0,
        ],
      },
      {
        "0554": [
          "0554",
          "EM",
          803190000.0,
          0.203,
          -0.003,
          -1.46,
          "-",
          "-",
          568210.0,
        ],
      },
      {
        "0556": [
          "0556",
          "EM",
          806400000.0,
          0.96,
          0.01,
          1.05,
          "-",
          "-",
          671520.0,
        ],
      },
      {
        "0557": [
          "0557",
          "EM",
          375040000.0,
          0.94,
          0.05,
          5.62,
          "-",
          "-",
          10590.0,
        ],
      },
      {
        "0558": [
          "0558",
          "EM",
          18160000000.0,
          13.2,
          1.38,
          11.68,
          50.32,
          0.66,
          78930000.0,
        ],
      },
      {
        "0559": [
          "0559",
          "EM",
          470860000.0,
          0.03,
          0.002,
          7.14,
          "-",
          "-",
          132810.0,
        ],
      },
      {
        "0560": [
          "0560",
          "EM",
          1090000000.0,
          0.98,
          0.0,
          0.0,
          22.07,
          2.04,
          278100.0,
        ],
      },
      {
        "0563": [
          "0563",
          "EM",
          3600000000.0,
          0.75,
          0.0,
          0.0,
          6.91,
          5.73,
          121930.0,
        ],
      },
      {
        "0564": [
          "0564",
          "EM",
          2020000000.0,
          8.33,
          0.21,
          2.59,
          9.92,
          3.07,
          2270000.0,
        ],
      },
      {
        "0565": [
          "0565",
          "EM",
          752860000.0,
          0.28,
          -0.025,
          -8.2,
          "-",
          "-",
          38220.0,
        ],
      },
      {
        "0567": ["0567", "EM", 140350000.0, 0.087, 0.003, 3.57, "-", "-", "-"],
      },
      {
        "0568": [
          "0568",
          "EM",
          1070000000.0000001,
          4.18,
          0.08,
          1.95,
          88.94,
          "-",
          402980.0,
        ],
      },
      {
        "0570": [
          "0570",
          "EM",
          22760000000.0,
          4.52,
          -0.01,
          -0.22,
          11.69,
          1.47,
          53670000.0,
        ],
      },
      { "0571": ["0571", "EM", 492310000.0, 0.33, 0.0, 0.0, "-", "-", "-"] },
      {
        "0572": [
          "0572",
          "EM",
          53600000.0,
          0.049,
          -0.006,
          -10.91,
          "-",
          "-",
          51440.0,
        ],
      },
      { "0573": ["0573", "EM", 912910000.0, 0.9, 0.0, 0.0, "-", 6.67, 6290.0] },
      {
        "0574": [
          "0574",
          "EM",
          106190000.0,
          0.072,
          -0.002,
          -2.7,
          "-",
          "-",
          7570.0,
        ],
      },
      {
        "0575": [
          "0575",
          "EM",
          326320000.0,
          0.136,
          -0.002,
          -1.45,
          "-",
          "-",
          89970.0,
        ],
      },
      {
        "0576": [
          "0576",
          "EM",
          9730000000.0,
          6.79,
          0.11,
          1.65,
          8.33,
          6.23,
          11910000.0,
        ],
      },
      { "0577": ["0577", "EM", 24310000.0, 0.024, 0.0, 0.0, "-", "-", "-"] },
      { "0578": ["0578", "EM", 49920000.0, 0.048, 0.0, 0.0, "-", "-", "-"] },
      {
        "0579": [
          "0579",
          "EM",
          6590000000.0,
          2.33,
          0.08,
          3.56,
          7.12,
          3.55,
          12770000.0,
        ],
      },
      {
        "0580": [
          "0580",
          "EM",
          5610000000.0,
          3.43,
          0.22,
          6.85,
          26.57,
          0.87,
          28770000.0,
        ],
      },
      {
        "0581": [
          "0581",
          "EM",
          8710000000.0,
          2.34,
          0.06,
          2.63,
          4.69,
          8.55,
          2520000.0,
        ],
      },
      {
        "0582": [
          "0582",
          "EM",
          895960000.0,
          0.212,
          -0.008,
          -3.64,
          "-",
          "-",
          27080.0,
        ],
      },
      { "0583": ["0583", "EM", 470320000.0, 0.3, 0.0, 0.0, "-", "-", 52230.0] },
      {
        "0585": [
          "0585",
          "EM",
          514549999.99999994,
          0.62,
          0.02,
          3.33,
          1.81,
          "-",
          14640.0,
        ],
      },
      {
        "0586": [
          "0586",
          "EM",
          67489999999.99999,
          36.95,
          -0.1,
          -0.27,
          7.45,
          1.89,
          224590000.0,
        ],
      },
      {
        "0588": [
          "0588",
          "EM",
          876700000.0,
          1.24,
          0.07,
          5.98,
          165.33,
          2.9,
          1950000.0,
        ],
      },
      {
        "0589": ["0589", "EM", 637500000.0, 1.02, 0.0, 0.0, 8.6, 2.04, 10200.0],
      },
      {
        "0590": [
          "0590",
          "EM",
          12240000000.0,
          20.85,
          0.35,
          1.71,
          12.03,
          7.43,
          11120000.0,
        ],
      },
      { "0591": ["0591", "EM", 1260000000.0, 1.22, 0.0, 0.0, "-", "-", "-"] },
      {
        "0592": [
          "0592",
          "EM",
          1940000000.0,
          0.79,
          0.03,
          3.95,
          "-",
          "-",
          3300000.0,
        ],
      },
      { "0593": ["0593", "EM", 236950000.0, 0.83, 0.0, 0.0, "-", "-", "-"] },
      {
        "0595": [
          "0595",
          "EM",
          490670000.0,
          0.54,
          0.02,
          3.85,
          1.82,
          7.41,
          238740.0,
        ],
      },
      {
        "0596": [
          "0596",
          "EM",
          4860000000.0,
          4.26,
          0.15,
          3.65,
          "-",
          "-",
          5560000.0,
        ],
      },
      {
        "0598": [
          "0598",
          "EM",
          5460000000.0,
          2.55,
          0.03,
          1.19,
          5.85,
          5.6,
          3440000.0,
        ],
      },
      { "0599": ["0599", "EM", 240240000.0, 0.4, 0.0, 0.0, 22.35, 2.5, "-"] },
      { "0600": ["0600", "EM", 17930000.0, "-", 0.0, 0.0, "-", "-", "-"] },
      {
        "0601": ["0601", "EM", 322630000.0, 0.049, -0.001, -2.0, "-", "-", "-"],
      },
      { "0602": ["0602", "EM", 97520000.0, 0.094, 0.0, 0.0, "-", "-", 5220.0] },
      {
        "0603": [
          "0603",
          "EM",
          2500000000.0,
          0.435,
          0.025,
          6.1,
          75.0,
          "-",
          504810.0,
        ],
      },
      {
        "0604": [
          "0604",
          "EM",
          16370000000.000002,
          1.84,
          0.01,
          0.55,
          4.38,
          9.78,
          4670000.0,
        ],
      },
      { "0605": ["0605", "EM", 355780000.0, 1.7, 0.0, 0.0, 1.95, "-", "-"] },
      {
        "0606": [
          "0606",
          "EM",
          5910000000.0,
          2.85,
          0.12,
          4.4,
          "-",
          "-",
          15340000.0,
        ],
      },
      {
        "0607": [
          "0607",
          "EM",
          2890000000.0,
          0.147,
          0.007,
          5.0,
          "-",
          "-",
          2730000.0,
        ],
      },
      {
        "0608": [
          "0608",
          "EM",
          449250000.0,
          1.47,
          0.02,
          1.38,
          3.61,
          3.4,
          35280.0,
        ],
      },
      {
        "0609": [
          "0609",
          "EM",
          1090000000.0,
          1.28,
          0.05,
          4.07,
          9.79,
          2.34,
          61520.0,
        ],
      },
      {
        "0610": [
          "0610",
          "EM",
          3070000000.0,
          3.88,
          0.02,
          0.52,
          2.84,
          7.99,
          520780.0,
        ],
      },
      {
        "0611": [
          "0611",
          "EM",
          1160000000.0,
          0.89,
          0.04,
          4.71,
          20.46,
          "-",
          3870000.0,
        ],
      },
      {
        "0612": [
          "0612",
          "-",
          4860000000.0,
          3.6,
          0.09,
          2.56,
          16.45,
          "-",
          3130000.0,
        ],
      },
      { "0613": ["0613", "EM", 391140000.0, 0.415, 0.0, 0.0, 9.28, "-", "-"] },
      {
        "0616": [
          "0616",
          "EM",
          130400000.0,
          0.14,
          0.001,
          0.72,
          "-",
          "-",
          280940.0,
        ],
      },
      {
        "0617": ["0617", "EM", 2400000000.0, 2.16, 0.1, 4.85, "-", "-", 4850.0],
      },
      { "0618": ["0618", "EM", 1030000000.0, 0.162, 0.0, 0.0, "-", "-", "-"] },
      {
        "0619": ["0619", "EM", 90380000.0, 0.3, 0.005, 1.69, "-", "-", 2250.0],
      },
      {
        "0620": [
          "0620",
          "EM",
          1890000000.0,
          2.84,
          -0.04,
          -1.39,
          58.2,
          "-",
          302280.0,
        ],
      },
      {
        "0621": [
          "0621",
          "EM",
          544540000.0,
          0.03,
          -0.001,
          -3.23,
          "-",
          "-",
          1470000.0,
        ],
      },
      {
        "0622": [
          "0622",
          "EM",
          3420000000.0,
          0.56,
          0.04,
          7.69,
          1.19,
          "-",
          376200.0,
        ],
      },
      {
        "0623": ["0623", "EM", 521640000.0, 1.13, 0.0, 0.0, 5.31, 7.96, 3430.0],
      },
      {
        "0626": [
          "0626",
          "EM",
          2810000000.0,
          2.56,
          0.05,
          1.99,
          5.66,
          7.81,
          475420.0,
        ],
      },
      {
        "0627": [
          "0627",
          "EM",
          227300000.0,
          0.02,
          0.004,
          25.0,
          "-",
          "-",
          2020000.0,
        ],
      },
      {
        "0628": [
          "0628",
          "EM",
          715790000.0,
          0.265,
          -0.005,
          -1.85,
          42.74,
          "-",
          531950.0,
        ],
      },
      { "0629": ["0629", "EM", 350580000.0, 0.3, 0.0, 0.0, 28.3, "-", "-"] },
      { "0630": ["0630", "EM", 193510000.0, 0.08, 0.0, 0.0, "-", "-", "-"] },
      {
        "0631": [
          "0631",
          "EM",
          27280000000.0,
          8.65,
          0.51,
          6.27,
          21.8,
          1.73,
          11050000.0,
        ],
      },
      {
        "0632": [
          "0632",
          "EM",
          231510000.0,
          0.275,
          0.015,
          5.77,
          19.93,
          "-",
          116700.0,
        ],
      },
      { "0633": ["0633", "EM", 240430000.0, 0.104, 0.0, 0.0, "-", "-", "-"] },
      {
        "0635": [
          "0635",
          "EM",
          1630000000.0,
          0.78,
          -0.01,
          -1.27,
          "-",
          9.62,
          33140.0,
        ],
      },
      {
        "0636": [
          "0636",
          "EM",
          35820000000.0,
          19.82,
          0.34,
          1.75,
          12.15,
          39.0,
          22020000.0,
        ],
      },
      {
        "0637": [
          "0637",
          "EM",
          232050000.0,
          0.28,
          0.0,
          0.0,
          13.73,
          3.57,
          27960.0,
        ],
      },
      { "0638": ["0638", "EM", 333600000.0, 0.76, 0.0, 0.0, 3.22, "-", "-"] },
      {
        "0639": [
          "0639",
          "EM",
          12120000000.0,
          2.4,
          0.02,
          0.84,
          11.64,
          7.08,
          15980000.0,
        ],
      },
      { "0640": ["0640", "EM", 383070000.0, 0.68, 0.0, 0.0, 9.24, 8.09, "-"] },
      {
        "0641": [
          "0641",
          "EM",
          456580000.0,
          0.415,
          -0.005,
          -1.19,
          72.81,
          "-",
          "-",
        ],
      },
      { "0643": ["0643", "EM", 153790000.0, 0.188, 0.0, 0.0, "-", "-", "-"] },
      {
        "0645": [
          "0645",
          "EM",
          133419999.99999999,
          0.26,
          0.0,
          0.0,
          "-",
          "-",
          "-",
        ],
      },
      {
        "0646": ["0646", "EM", 137980000.0, 0.63, 0.0, 0.0, "-", "-", 15120.0],
      },
      { "0648": ["0648", "EM", 123890000.0, 0.38, 0.0, 0.0, "-", "-", "-"] },
      {
        "0650": [
          "0650",
          "EM",
          8570000000.0,
          1.23,
          -0.13,
          -9.56,
          "-",
          "-",
          3370000.0,
        ],
      },
      { "0651": ["0651", "EM", 51110000.0, 0.125, 0.0, 0.0, "-", "-", "-"] },
      {
        "0653": [
          "0653",
          "EM",
          586590000.0,
          0.167,
          -0.001,
          -0.6,
          "-",
          "-",
          1210000.0,
        ],
      },
      {
        "0655": [
          "0655",
          "EM",
          1130000000.0,
          0.57,
          0.0,
          0.0,
          "-",
          1.75,
          27360.0,
        ],
      },
      {
        "0656": [
          "0656",
          "EM",
          75700000000.0,
          9.1,
          0.27,
          3.06,
          8.27,
          2.42,
          30830000.0,
        ],
      },
      { "0657": ["0657", "EM", 114830000.0, 0.059, 0.0, 0.0, "-", "-", "-"] },
      {
        "0658": [
          "0658",
          "EM",
          9190000000.0,
          5.62,
          0.21,
          3.88,
          9.34,
          "-",
          8440000.0,
        ],
      },
      {
        "0659": [
          "0659",
          "EM",
          30460000000.0,
          7.79,
          0.05,
          0.65,
          26.58,
          7.57,
          22400000.0,
        ],
      },
      { "0660": ["0660", "EM", 84030000.0, 0.05, 0.0, 0.0, "-", "-", "-"] },
      {
        "0661": [
          "0661",
          "EM",
          1370000000.0,
          0.077,
          -0.002,
          -2.53,
          3.85,
          "-",
          32520.000000000004,
        ],
      },
      {
        "0662": [
          "0662",
          "EM",
          3420000000.0,
          3.65,
          0.05,
          1.39,
          10.3,
          3.01,
          43400.0,
        ],
      },
      {
        "0663": [
          "0663",
          "EM",
          451150000.0,
          0.38,
          -0.015,
          -3.8,
          "-",
          "-",
          472940.0,
        ],
      },
      {
        "0665": [
          "0665",
          "EM",
          11040000000.0,
          1.83,
          0.05,
          2.81,
          5.55,
          11.31,
          7150000.0,
        ],
      },
      {
        "0667": [
          "0667",
          "EM",
          8720000000.0,
          4.01,
          0.0,
          0.0,
          28.95,
          6.73,
          8670000.0,
        ],
      },
      { "0668": ["0668", "EM", 280280000.0, 0.22, 0.0, 0.0, 2.45, "-", "-"] },
      {
        "0669": [
          "0669",
          "EM",
          253890000000.0,
          138.4,
          10.4,
          8.13,
          40.89,
          1.21,
          1670000000.0,
        ],
      },
      {
        "0670": [
          "0670",
          "EM",
          15890000000.0,
          3.07,
          0.05,
          1.66,
          "-",
          "-",
          7020000.0,
        ],
      },
      {
        "0672": [
          "0672",
          "EM",
          1690000000.0,
          0.3,
          0.0,
          0.0,
          1.74,
          "-",
          336760.0,
        ],
      },
      {
        "0673": [
          "0673",
          "EM",
          228270000.0,
          0.052,
          -0.003,
          -5.45,
          "-",
          "-",
          1630.0,
        ],
      },
      {
        "0674": ["0674", "EM", 346320000.0, 0.15, -0.001, -0.66, "-", "-", "-"],
      },
      {
        "0675": [
          "0675",
          "EM",
          256320000.0,
          0.96,
          -0.03,
          -3.03,
          3.83,
          11.98,
          200160.0,
        ],
      },
      { "0676": ["0676", "EM", 577210000.0, 0.79, 0.0, 0.0, "-", "-", "-"] },
      {
        "0677": [
          "0677",
          "EM",
          865670000.0,
          0.51,
          0.0,
          0.0,
          21.25,
          4.71,
          7140.0,
        ],
      },
      { "0678": ["0678", "EM", 3520000000.0, 0.415, 0.0, 0.0, "-", "-", "-"] },
      {
        "0679": [
          "0679",
          "EM",
          520280000.0,
          1.22,
          -0.01,
          -0.81,
          3.75,
          2.46,
          84400.0,
        ],
      },
      {
        "0680": [
          "0680",
          "EM",
          2530000000.0,
          0.037,
          -0.001,
          -2.63,
          "-",
          "-",
          96200.0,
        ],
      },
      {
        "0681": [
          "0681",
          "EM",
          419920000.0,
          0.047,
          -0.001,
          -2.08,
          2.47,
          "-",
          63030.0,
        ],
      },
      {
        "0682": ["0682", "EM", 141710000.0, 0.043, 0.0, 0.0, "-", "-", 27740.0],
      },
      {
        "0683": [
          "0683",
          "EM",
          33299999999.999996,
          22.9,
          0.9,
          4.09,
          6.17,
          15.94,
          45570000.0,
        ],
      },
      {
        "0684": ["0684", "EM", 556680000.0, 1.67, 0.0, 0.0, 12.08, 14.97, "-"],
      },
      {
        "0685": [
          "0685",
          "EM",
          480860000.0,
          0.285,
          -0.01,
          -3.39,
          "-",
          2.74,
          "-",
        ],
      },
      {
        "0686": [
          "0686",
          "EM",
          5940000000.0,
          0.265,
          0.005,
          1.92,
          20.23,
          "-",
          1010000.0,
        ],
      },
      {
        "0687": [
          "0687",
          "EM",
          1260000000.0,
          0.375,
          0.005,
          1.35,
          "-",
          2.67,
          23680.0,
        ],
      },
      {
        "0688": [
          "0688",
          "EM",
          257200000000.0,
          23.5,
          0.55,
          2.4,
          4.99,
          5.02,
          444260000.0,
        ],
      },
      {
        "0689": [
          "0689",
          "EM",
          188650000.0,
          0.036,
          0.006,
          20.0,
          22.5,
          "-",
          1390000.0,
        ],
      },
      {
        "0690": [
          "0690",
          "EM",
          615920000.0,
          0.097,
          0.014,
          16.87,
          "-",
          "-",
          32380.000000000004,
        ],
      },
      {
        "0691": [
          "0691",
          "EM",
          9090000000.0,
          2.09,
          0.06,
          2.96,
          2.42,
          "-",
          2090.0,
        ],
      },
      {
        "0693": [
          "0693",
          "EM",
          4019999999.9999995,
          2.0,
          0.0,
          0.0,
          425.53,
          2.25,
          12000.0,
        ],
      },
      {
        "0694": [
          "0694",
          "EM",
          10120000000.0,
          5.39,
          0.2,
          3.85,
          "-",
          "-",
          33740000.0,
        ],
      },
      {
        "0695": [
          "0695",
          "EM",
          2490000000.0,
          4.52,
          0.02,
          0.44,
          42.12,
          1.6,
          784760.0,
        ],
      },
      {
        "0696": [
          "0696",
          "EM",
          13610000000.0,
          14.6,
          0.02,
          0.14,
          100.62,
          0.13,
          32250000.0,
        ],
      },
      {
        "0697": [
          "0697",
          "EM",
          10930000000.0,
          1.5,
          0.07,
          4.9,
          15.59,
          6.4,
          23760000.0,
        ],
      },
      {
        "0698": [
          "0698",
          "EM",
          1950000000.0,
          0.201,
          -0.002,
          -0.99,
          4.12,
          "-",
          2069999.9999999998,
        ],
      },
      {
        "0700": [
          "0700",
          "EM",
          4602740000000.0,
          479.0,
          7.0,
          1.48,
          24.11,
          0.33,
          7610000000.0,
        ],
      },
      {
        "0701": [
          "0701",
          "EM",
          751950000.0,
          0.395,
          -0.005,
          -1.25,
          "-",
          5.06,
          97600.0,
        ],
      },
      {
        "0702": [
          "0702",
          "EM",
          441590000.0,
          0.132,
          -0.004,
          -2.94,
          "-",
          "-",
          218270.0,
        ],
      },
      { "0703": ["0703", "EM", 113170000.0, 0.163, 0.0, 0.0, "-", "-", "-"] },
      { "0704": ["0704", "EM", 87550000.0, 0.305, 0.0, 0.0, 8.71, "-", "-"] },
      { "0706": ["0706", "EM", 120430000.0, 0.013, 0.0, 0.0, "-", "-", "-"] },
      {
        "0707": [
          "0707",
          "EM",
          178660000.0,
          0.018,
          -0.001,
          -5.26,
          "-",
          "-",
          98550.0,
        ],
      },
      {
        "0708": [
          "0708",
          "EM",
          39360000000.0,
          3.63,
          0.03,
          0.83,
          "-",
          "-",
          45890000.0,
        ],
      },
      {
        "0709": [
          "0709",
          "EM",
          2630000000.0,
          1.67,
          0.05,
          3.09,
          "-",
          8.02,
          3100000.0,
        ],
      },
      {
        "0710": [
          "0710",
          "EM",
          6670000000.0,
          9.06,
          0.24,
          2.72,
          97.0,
          0.55,
          15120000.0,
        ],
      },
      { "0711": ["0711", "EM", 1080000000.0, 0.6, 0.0, 0.0, "-", 1.47, "-"] },
      {
        "0712": ["0712", "EM", 249050000.0, 0.32, 0.0, 0.0, "-", "-", 233250.0],
      },
      {
        "0713": [
          "0713",
          "EM",
          745070000.0,
          0.96,
          -0.01,
          -1.03,
          10.63,
          "-",
          3880.0,
        ],
      },
      {
        "0715": [
          "0715",
          "EM",
          2089999999.9999998,
          0.13,
          0.0,
          0.0,
          "-",
          "-",
          "-",
        ],
      },
      {
        "0716": [
          "0716",
          "EM",
          2800000000.0,
          1.16,
          0.01,
          0.87,
          78.91,
          23.28,
          13190000.0,
        ],
      },
      {
        "0717": [
          "0717",
          "EM",
          512299999.99999994,
          0.076,
          0.002,
          2.7,
          "-",
          "-",
          218520.0,
        ],
      },
      { "0718": ["0718", "EM", 535500000.0, 0.102, 0.0, 0.0, "-", "-", "-"] },
      {
        "0719": [
          "0719",
          "EM",
          836550000.0,
          4.29,
          0.07,
          1.66,
          7.01,
          4.2,
          501790.0,
        ],
      },
      {
        "0720": [
          "0720",
          "EM",
          1040000000.0,
          0.197,
          -0.014,
          -6.64,
          "-",
          "-",
          50870.0,
        ],
      },
      {
        "0721": [
          "0721",
          "-",
          1150000000.0,
          0.105,
          0.003,
          2.94,
          "-",
          "-",
          7270.0,
        ],
      },
      {
        "0722": [
          "0722",
          "EM",
          657910000.0,
          0.83,
          0.0,
          0.0,
          18.12,
          4.58,
          14940.0,
        ],
      },
      {
        "0723": [
          "0723",
          "EM",
          227650000.0,
          0.025,
          -0.002,
          -7.41,
          6.94,
          "-",
          32590.000000000004,
        ],
      },
      {
        "0724": [
          "0724",
          "EM",
          29400000.0,
          0.035,
          0.002,
          6.06,
          "-",
          "-",
          35110.0,
        ],
      },
      { "0725": ["0725", "EM", 147220000.0, 0.74, 0.0, 0.0, "-", "-", "-"] },
      {
        "0726": [
          "0726",
          "EM",
          2760000000.0,
          0.89,
          -0.01,
          -1.11,
          15.7,
          "-",
          446150.0,
        ],
      },
      {
        "0727": ["0727", "EM", 476770000.0, 0.139, 0.0, 0.0, "-", "-", 47240.0],
      },
      {
        "0728": [
          "0728",
          "EM",
          41770000000.0,
          3.01,
          0.07,
          2.38,
          9.92,
          4.15,
          180050000.0,
        ],
      },
      {
        "0730": [
          "0730",
          "EM",
          557840000.0,
          0.14,
          -0.001,
          -0.71,
          93.33,
          "-",
          379280.0,
        ],
      },
      {
        "0731": [
          "0731",
          "EM",
          516320000.00000006,
          "-",
          0.0,
          0.0,
          "-",
          "-",
          "-",
        ],
      },
      {
        "0732": [
          "0732",
          "EM",
          10590000000.0,
          3.22,
          0.1,
          3.21,
          21.09,
          1.55,
          33549999.999999996,
        ],
      },
      {
        "0733": [
          "0733",
          "EM",
          903360000.0,
          1.34,
          -0.04,
          -2.9,
          4.91,
          2.24,
          253580.0,
        ],
      },
      {
        "0736": [
          "0736",
          "EM",
          109530000.0,
          0.41,
          0.015,
          3.8,
          1.53,
          "-",
          2440.0,
        ],
      },
      {
        "0737": [
          "0737",
          "EM",
          8650000000.0,
          2.81,
          0.01,
          0.36,
          10.58,
          7.86,
          5890.0,
        ],
      },
      {
        "0738": ["0738", "EM", 423530000.0, 0.6, 0.0, 0.0, 3.34, 83.33, 6000.0],
      },
      {
        "0743": [
          "0743",
          "EM",
          8610000000.0,
          5.5,
          0.11,
          2.04,
          2.75,
          11.29,
          5360000.0,
        ],
      },
      {
        "0745": [
          "0745",
          "EM",
          129410000.0,
          0.177,
          0.002,
          1.14,
          "-",
          "-",
          3540.0,
        ],
      },
      {
        "0746": [
          "0746",
          "EM",
          5790000000.0,
          7.03,
          0.16,
          2.33,
          11.54,
          6.05,
          1920000.0,
        ],
      },
      { "0747": ["0747", "EM", 69610000.0, 0.115, 0.0, 0.0, "-", "-", "-"] },
      {
        "0750": [
          "0750",
          "EM",
          3470000000.0,
          1.38,
          0.03,
          2.22,
          9.79,
          2.03,
          2170000.0,
        ],
      },
      {
        "0751": [
          "0751",
          "EM",
          12100000000.0,
          4.56,
          -0.03,
          -0.65,
          7.86,
          "-",
          39770000.0,
        ],
      },
      {
        "0752": [
          "0752",
          "EM",
          1600000000.0,
          1.3,
          0.01,
          0.78,
          11.75,
          3.85,
          251560.0,
        ],
      },
      {
        "0753": [
          "0753",
          "EM",
          27780000000.0,
          6.09,
          0.25,
          4.28,
          "-",
          "-",
          44810000.0,
        ],
      },
      {
        "0754": [
          "0754",
          "EM",
          39130000000.0,
          16.48,
          0.2,
          1.23,
          2.95,
          8.83,
          81840000.0,
        ],
      },
      {
        "0755": ["0755", "EM", 461250000.0, 0.031, 0.0, 0.0, "-", "-", 19510.0],
      },
      {
        "0756": [
          "0756",
          "EM",
          198540000.0,
          0.087,
          0.007,
          8.75,
          "-",
          "-",
          23500.0,
        ],
      },
      {
        "0757": [
          "0757",
          "EM",
          1190000000.0,
          0.36,
          0.01,
          2.86,
          "-",
          "-",
          654290.0,
        ],
      },
      { "0758": ["0758", "EM", 202640000.0, 0.168, 0.0, 0.0, "-", "-", "-"] },
      {
        "0759": [
          "0759",
          "EM",
          439680000.0,
          0.66,
          0.01,
          1.54,
          13.61,
          1.36,
          26440.0,
        ],
      },
      { "0760": ["0760", "EM", 205860000.0, 0.02, 0.0, 0.0, "-", "-", "-"] },
      {
        "0762": [
          "0762",
          "EM",
          128199999999.99998,
          4.19,
          0.08,
          1.95,
          8.69,
          8.17,
          193560000.0,
        ],
      },
      {
        "0763": [
          "0763",
          "EM",
          16200000000.0,
          21.45,
          0.5,
          2.39,
          19.65,
          1.12,
          54710000.0,
        ],
      },
      {
        "0764": [
          "0764",
          "EM",
          687520000.0,
          0.18,
          -0.002,
          -1.1,
          "-",
          "-",
          38340.0,
        ],
      },
      { "0765": ["0765", "EM", 202690000.0, 0.62, 0.0, 0.0, "-", "-", "-"] },
      { "0766": ["0766", "EM", 80630000.0, 0.035, 0.0, 0.0, "-", "-", "-"] },
      {
        "0767": [
          "0767",
          "EM",
          696610000.0,
          0.18,
          0.005,
          2.86,
          "-",
          "-",
          190570.0,
        ],
      },
      {
        "0768": [
          "0768",
          "-",
          66129999.99999999,
          0.052,
          0.003,
          6.12,
          "-",
          "-",
          14530.0,
        ],
      },
      {
        "0769": [
          "0769",
          "EM",
          1940000000.0,
          0.83,
          0.02,
          2.47,
          98.81,
          "-",
          2850000.0,
        ],
      },
      { "0770": ["0770", "-", 19650000.0, 0.236, 0.0, 0.0, 67.43, "-", "-"] },
      {
        "0771": [
          "0771",
          "EM",
          1190000000.0,
          1.43,
          0.01,
          0.7,
          1.38,
          18.88,
          661480.0,
        ],
      },
      {
        "0772": [
          "0772",
          "EM",
          50640000000.0,
          49.55,
          2.65,
          5.65,
          "-",
          "-",
          110940000.0,
        ],
      },
      {
        "0775": [
          "0775",
          "EM",
          7010000000.0,
          0.73,
          0.01,
          1.39,
          56.15,
          1.37,
          2370000.0,
        ],
      },
      {
        "0776": [
          "0776",
          "EM",
          5190000000.0,
          16.7,
          -0.46,
          -2.68,
          "-",
          "-",
          2220000.0,
        ],
      },
      {
        "0777": [
          "0777",
          "EM",
          11230000000.0,
          20.6,
          0.96,
          4.89,
          10.21,
          10.1,
          38660000.0,
        ],
      },
      {
        "0778": [
          "0778",
          "R",
          15940000000.0,
          8.08,
          0.05,
          0.62,
          "-",
          6.13,
          35600000.0,
        ],
      },
      {
        "0780": [
          "0780",
          "EM",
          36900000000.0,
          16.64,
          0.7,
          4.39,
          91.93,
          "-",
          80090000.0,
        ],
      },
      {
        "0784": [
          "0784",
          "EM",
          117600000.0,
          0.147,
          0.007,
          5.0,
          "-",
          "-",
          189220.0,
        ],
      },
      { "0787": ["0787", "EM", 194410000.0, 0.189, 0.0, 0.0, "-", "-", "-"] },
      {
        "0788": [
          "0788",
          "EM",
          44790000000.0,
          0.96,
          0.02,
          2.13,
          22.07,
          2.8,
          173470000.0,
        ],
      },
      {
        "0789": [
          "0789",
          "EM",
          89420000.0,
          0.081,
          -0.001,
          -1.22,
          "-",
          "-",
          4940.0,
        ],
      },
      { "0794": ["0794", "EM", 170200000.0, 0.495, 0.0, 0.0, 6.26, "-", "-"] },
      { "0797": ["0797", "EM", 7020000000.0, 2.55, 0.0, 0.0, 64.23, "-", "-"] },
      {
        "0798": [
          "0798",
          "EM",
          3100000000.0,
          0.41,
          0.0,
          0.0,
          5.69,
          4.88,
          75440.0,
        ],
      },
      {
        "0799": [
          "0799",
          "EM",
          5850000000.0,
          4.9,
          0.05,
          1.03,
          2.85,
          13.86,
          13370000.0,
        ],
      },
      {
        "0800": [
          "0800",
          "EM",
          1040000000.0,
          0.385,
          0.01,
          2.67,
          17.5,
          "-",
          82880.0,
        ],
      },
      {
        "0802": [
          "0802",
          "EM",
          199170000.0,
          0.066,
          0.0,
          0.0,
          "-",
          "-",
          285600.0,
        ],
      },
      { "0803": ["0803", "EM", 49710000.0, 0.037, 0.0, 0.0, "-", "-", "-"] },
      {
        "0804": [
          "0804",
          "EM",
          212050000.0,
          0.047,
          0.0,
          0.0,
          "-",
          "-",
          227160.0,
        ],
      },
      {
        "0806": [
          "0806",
          "EM",
          7210000000.0,
          3.9,
          0.08,
          2.09,
          5.24,
          8.72,
          4160000.0,
        ],
      },
      {
        "0807": [
          "0807",
          "EM",
          3730000000.0,
          1.45,
          0.03,
          2.11,
          5.0,
          5.97,
          1650000.0,
        ],
      },
      {
        "0808": [
          "0808",
          "R",
          4620000000.0,
          3.07,
          0.04,
          1.32,
          "-",
          5.84,
          3130000.0,
        ],
      },
      {
        "0809": [
          "0809",
          "EM",
          1640000000.0,
          0.185,
          0.016,
          9.47,
          "-",
          "-",
          483160.0,
        ],
      },
      { "0810": ["0810", "-", 212130000.0, 1.5, 0.0, 0.0, "-", "-", 27480.0] },
      {
        "0811": [
          "0811",
          "EM",
          2600000000.0,
          5.89,
          0.01,
          0.17,
          4.92,
          6.35,
          1360000.0,
        ],
      },
      {
        "0812": ["0812", "EM", 161120000.0, 0.044, 0.0, 0.0, "-", "-", 31810.0],
      },
      {
        "0813": [
          "0813",
          "EM",
          21980000000.0,
          5.79,
          0.07,
          1.22,
          1.37,
          31.09,
          76480000.0,
        ],
      },
      {
        "0814": [
          "0814",
          "EM",
          173050000.0,
          0.95,
          0.0,
          0.0,
          5.92,
          12.69,
          57950.0,
        ],
      },
      {
        "0815": [
          "0815",
          "EM",
          944470000.0,
          0.58,
          0.01,
          1.75,
          3.54,
          "-",
          431660.0,
        ],
      },
      {
        "0817": [
          "0817",
          "EM",
          36410000000.0,
          2.87,
          0.04,
          1.41,
          7.63,
          9.06,
          32159999.999999996,
        ],
      },
      {
        "0818": [
          "0818",
          "EM",
          2880000000.0,
          1.04,
          0.02,
          1.96,
          4.4,
          "-",
          1270000.0,
        ],
      },
      {
        "0819": [
          "0819",
          "EM",
          9250000000.0,
          8.22,
          0.2,
          2.49,
          3.18,
          4.87,
          3630000.0,
        ],
      },
      {
        "0820": ["0820", "F", 721610000.0, 17.5, 0.4, 2.34, "-", "-", 121460.0],
      },
      { "0821": ["0821", "EM", 633970000.0, 0.305, 0.0, 0.0, "-", "-", "-"] },
      {
        "0822": [
          "0822",
          "EM",
          634570000.0,
          0.71,
          0.03,
          4.41,
          5.12,
          5.63,
          1880000.0,
        ],
      },
      {
        "0823": [
          "0823",
          "R",
          141480000000.0,
          67.05,
          0.3,
          0.45,
          12.05,
          4.59,
          486810000.0,
        ],
      },
      {
        "0825": [
          "0825",
          "EM",
          2069999999.9999998,
          1.23,
          0.01,
          0.82,
          "-",
          "-",
          491240.0,
        ],
      },
      {
        "0826": [
          "0826",
          "EM",
          11480000000.0,
          4.11,
          0.26,
          6.75,
          16.72,
          2.16,
          3660000.0,
        ],
      },
      {
        "0827": [
          "0827",
          "EM",
          1790000000.0,
          0.315,
          0.0,
          0.0,
          "-",
          "-",
          1160000.0,
        ],
      },
      {
        "0828": [
          "0828",
          "EM",
          461830000.0,
          0.37,
          0.03,
          8.82,
          3.97,
          "-",
          12940.0,
        ],
      },
      {
        "0829": [
          "0829",
          "EM",
          1420000000.0,
          0.44,
          -0.005,
          -1.12,
          13.37,
          13.64,
          739010.0,
        ],
      },
      {
        "0830": [
          "0830",
          "EM",
          3850000000.0,
          1.79,
          0.05,
          2.87,
          19.84,
          3.35,
          1400000.0,
        ],
      },
      {
        "0831": [
          "0831",
          "EM",
          582180000.0,
          0.75,
          0.0,
          0.0,
          0.18,
          516.0,
          538160.0,
        ],
      },
      {
        "0832": [
          "0832",
          "EM",
          2570000000.0,
          0.87,
          0.03,
          3.57,
          1.15,
          47.76,
          3580000.0,
        ],
      },
      {
        "0833": [
          "0833",
          "EM",
          283830000.0,
          0.3,
          0.015,
          5.26,
          2.32,
          10.0,
          20100.0,
        ],
      },
      {
        "0834": [
          "0834",
          "EM",
          160190000.0,
          0.37,
          -0.005,
          -1.33,
          "-",
          "-",
          4500.0,
        ],
      },
      {
        "0836": [
          "0836",
          "EM",
          94090000000.0,
          19.56,
          0.64,
          3.38,
          12.41,
          3.35,
          190200000.0,
        ],
      },
      {
        "0837": [
          "0837",
          "EM",
          900340000.0,
          3.62,
          0.02,
          0.56,
          9.73,
          5.41,
          111700.0,
        ],
      },
      {
        "0838": [
          "0838",
          "EM",
          2710000000.0,
          1.55,
          0.1,
          6.9,
          "-",
          0.77,
          20450000.0,
        ],
      },
      {
        "0839": [
          "0839",
          "EM",
          16980000000.0,
          7.11,
          0.31,
          4.56,
          9.06,
          5.61,
          208120000.0,
        ],
      },
      {
        "0840": [
          "0840",
          "EM",
          42700000.0,
          0.211,
          0.02,
          10.47,
          140.67,
          "-",
          3840.0,
        ],
      },
      { "0841": ["0841", "EM", 175410000.0, 0.3, 0.0, 0.0, "-", "-", 36580.0] },
      {
        "0842": [
          "0842",
          "EM",
          1080000000.0,
          0.8,
          0.02,
          2.56,
          7.48,
          "-",
          94570.0,
        ],
      },
      { "0844": ["0844", "EM", 170540000.0, 0.345, 0.0, 0.0, 16.35, "-", "-"] },
      {
        "0845": [
          "0845",
          "EM",
          1800000000.0,
          0.232,
          -0.003,
          -1.28,
          19.02,
          "-",
          16910.0,
        ],
      },
      {
        "0846": [
          "0846",
          "EM",
          3040000000.0,
          0.5,
          0.01,
          2.04,
          2.41,
          "-",
          11860.0,
        ],
      },
      {
        "0848": [
          "0848",
          "EM",
          1270000000.0,
          0.248,
          0.005,
          2.06,
          "-",
          "-",
          13440.0,
        ],
      },
      {
        "0851": [
          "0851",
          "EM",
          137500000.0,
          0.036,
          0.0,
          0.0,
          11.61,
          "-",
          70360.0,
        ],
      },
      {
        "0852": [
          "0852",
          "EM",
          469260000.0,
          0.221,
          -0.006,
          -2.64,
          "-",
          "-",
          7360.0,
        ],
      },
      {
        "0853": [
          "0853",
          "EM",
          41690000000.0,
          22.9,
          0.65,
          2.92,
          "-",
          0.19,
          85220000.0,
        ],
      },
      {
        "0854": [
          "0854",
          "EM",
          340570000.0,
          3.98,
          0.16,
          4.19,
          3.67,
          8.29,
          123480.0,
        ],
      },
      {
        "0855": [
          "0855",
          "EM",
          15620000000.0,
          9.57,
          0.47,
          5.16,
          9.0,
          3.34,
          64550000.0,
        ],
      },
      {
        "0856": [
          "0856",
          "EM",
          11420000000.0,
          7.85,
          0.15,
          1.95,
          10.39,
          2.8,
          9360000.0,
        ],
      },
      {
        "0857": [
          "0857",
          "EM",
          83760000000.0,
          3.97,
          0.1,
          2.58,
          32.46,
          6.61,
          401880000.0,
        ],
      },
      { "0858": ["0858", "EM", 222270000.0, 0.093, 0.0, 0.0, "-", "-", "-"] },
      { "0859": ["0859", "EM", 337500000.0, 0.3, 0.0, 0.0, "-", "-", "-"] },
      {
        "0860": [
          "0860",
          "EM",
          3710000000.0,
          0.465,
          0.005,
          1.09,
          "-",
          "-",
          3190000.0,
        ],
      },
      {
        "0861": [
          "0861",
          "EM",
          7510000000.0,
          4.49,
          0.07,
          1.58,
          11.9,
          2.67,
          2710000.0,
        ],
      },
      {
        "0862": [
          "0862",
          "EM",
          780910000.0,
          0.199,
          0.009,
          4.74,
          "-",
          "-",
          17340.0,
        ],
      },
      {
        "0863": [
          "0863",
          "EM",
          3920000000.0,
          9.28,
          0.28,
          3.11,
          "-",
          "-",
          3010000.0,
        ],
      },
      { "0864": ["0864", "EM", 193080000.0, 0.5, 0.0, 0.0, "-", "-", "-"] },
      {
        "0865": [
          "0865",
          "EM",
          560440000.0,
          0.096,
          0.005,
          5.49,
          4.85,
          "-",
          25720.0,
        ],
      },
      {
        "0866": [
          "0866",
          "EM",
          635820000.0,
          0.255,
          0.005,
          2.0,
          "-",
          "-",
          48730.0,
        ],
      },
      {
        "0867": [
          "0867",
          "EM",
          32290000000.0,
          13.14,
          0.16,
          1.23,
          10.86,
          4.26,
          31660000.0,
        ],
      },
      {
        "0868": [
          "0868",
          "EM",
          85990000000.0,
          21.4,
          0.85,
          4.14,
          13.42,
          5.98,
          182500000.0,
        ],
      },
      {
        "0869": ["0869", "EM", 572300000.0, 0.485, 0.0, 0.0, "-", "-", 42980.0],
      },
      { "0871": ["0871", "EM", 210540000.0, 0.14, 0.0, 0.0, "-", "-", "-"] },
      {
        "0872": ["0872", "EM", 163020000.0, 0.079, 0.0, 0.0, "-", "-", 9000.0],
      },
      {
        "0873": [
          "0873",
          "EM",
          15760000000.0,
          6.36,
          0.17,
          2.75,
          16.04,
          1.73,
          44570000.0,
        ],
      },
      {
        "0874": [
          "0874",
          "EM",
          4430000000.0,
          20.15,
          0.29,
          1.46,
          9.51,
          3.25,
          9650000.0,
        ],
      },
      {
        "0875": [
          "0875",
          "EM",
          1390000000.0,
          3.69,
          0.46,
          14.24,
          "-",
          "-",
          186960.0,
        ],
      },
      {
        "0876": [
          "0876",
          "EM",
          413450000.0,
          0.082,
          -0.001,
          -1.2,
          "-",
          "-",
          146450.0,
        ],
      },
      {
        "0878": [
          "0878",
          "EM",
          2089999999.9999998,
          7.4,
          -0.01,
          -0.13,
          "-",
          2.7,
          66790.0,
        ],
      },
      {
        "0880": [
          "0880",
          "EM",
          29480000000.0,
          5.19,
          0.21,
          4.22,
          "-",
          "-",
          81090000.0,
        ],
      },
      {
        "0881": [
          "0881",
          "EM",
          146710000000.0,
          60.75,
          1.1,
          1.84,
          21.1,
          0.95,
          128460000.00000001,
        ],
      },
      {
        "0882": [
          "0882",
          "EM",
          1900000000.0,
          1.78,
          0.04,
          2.3,
          6.48,
          4.62,
          832900.0,
        ],
      },
      {
        "0883": [
          "0883",
          "EM",
          424150000000.0,
          9.5,
          0.14,
          1.5,
          14.42,
          5.79,
          683160000.0,
        ],
      },
      {
        "0884": [
          "0884",
          "EM",
          45840000000.0,
          5.22,
          0.13,
          2.55,
          4.45,
          7.81,
          38770000.0,
        ],
      },
      { "0885": ["0885", "EM", 110220000.0, 0.01, 0.0, 0.0, "-", "-", "-"] },
      {
        "0886": [
          "0886",
          "EM",
          97180000.0,
          0.043,
          0.003,
          7.5,
          "-",
          "-",
          23370.0,
        ],
      },
      {
        "0887": [
          "0887",
          "EM",
          1390000000.0,
          0.206,
          0.004,
          1.98,
          41.2,
          3.4,
          139260.0,
        ],
      },
      { "0888": ["0888", "EM", 281520000.0, 0.198, 0.0, 0.0, "-", "-", "-"] },
      {
        "0889": [
          "0889",
          "EM",
          163200000.0,
          0.51,
          -0.02,
          -3.77,
          "-",
          3.92,
          22440.0,
        ],
      },
      { "0891": ["0891", "EM", 151120000.0, 0.042, 0.0, 0.0, 3.0, "-", "-"] },
      {
        "0893": [
          "0893",
          "EM",
          359840000.0,
          0.16,
          -0.004,
          -2.44,
          "-",
          "-",
          7410.0,
        ],
      },
      {
        "0894": [
          "0894",
          "EM",
          565900000.0,
          1.19,
          0.02,
          1.71,
          23.52,
          "-",
          119700.0,
        ],
      },
      {
        "0895": [
          "0895",
          "EM",
          716490000.0,
          3.58,
          -0.18,
          -4.79,
          8.84,
          3.61,
          21610.0,
        ],
      },
      {
        "0896": [
          "0896",
          "EM",
          1410000000.0,
          1.28,
          0.01,
          0.79,
          5.08,
          5.86,
          132850.0,
        ],
      },
      {
        "0897": [
          "0897",
          "EM",
          357170000.0,
          0.29,
          0.005,
          1.75,
          "-",
          "-",
          44140.0,
        ],
      },
      {
        "0898": [
          "0898",
          "EM",
          886230000.0,
          1.06,
          -0.02,
          -1.85,
          23.82,
          4.25,
          "-",
        ],
      },
      {
        "0899": [
          "0899",
          "EM",
          669820000.0,
          0.088,
          -0.003,
          -3.3,
          7.33,
          "-",
          142890.0,
        ],
      },
      {
        "0900": [
          "0900",
          "EM",
          2120000000.0,
          5.08,
          0.0,
          0.0,
          7.05,
          7.87,
          395160.0,
        ],
      },
      {
        "0901": ["0901", "-", 300820000.0, 0.139, -0.005, -3.47, "-", "-", "-"],
      },
      {
        "0902": [
          "0902",
          "EM",
          19450000000.0,
          4.14,
          0.04,
          0.98,
          87.71,
          5.2,
          61930000.0,
        ],
      },
      {
        "0904": [
          "0904",
          "EM",
          48200000.0,
          0.11,
          0.017,
          18.28,
          "-",
          "-",
          21030.0,
        ],
      },
      { "0905": ["0905", "-", 90340000.0, 0.129, 0.0, 0.0, "-", "-", "-"] },
      {
        "0906": [
          "0906",
          "EM",
          4950000000.0,
          4.45,
          0.0,
          0.0,
          10.94,
          7.01,
          1020000.0,
        ],
      },
      {
        "0907": [
          "0907",
          "EM",
          471340000.0,
          0.54,
          0.01,
          1.89,
          "-",
          "-",
          2110000.0,
        ],
      },
      {
        "0909": [
          "0909",
          "EM",
          33659999999.999996,
          17.1,
          1.18,
          7.41,
          "-",
          0.49,
          46020000.0,
        ],
      },
      {
        "0910": [
          "0910",
          "EM",
          2490000000.0,
          0.49,
          0.055,
          12.64,
          5.02,
          "-",
          60780.0,
        ],
      },
      {
        "0911": [
          "0911",
          "EM",
          125380000.0,
          0.074,
          -0.003,
          -3.9,
          "-",
          "-",
          1620.0,
        ],
      },
      {
        "0912": [
          "0912",
          "EM",
          441420000.0,
          1.55,
          -0.03,
          -1.9,
          5.54,
          10.97,
          49760.0,
        ],
      },
      {
        "0913": [
          "0913",
          "-",
          305910000.0,
          1.11,
          0.06,
          5.71,
          "-",
          "-",
          2640000.0,
        ],
      },
      {
        "0914": [
          "0914",
          "EM",
          53990000000.0,
          41.55,
          0.45,
          1.09,
          5.31,
          6.17,
          244610000.0,
        ],
      },
      {
        "0915": [
          "0915",
          "EM",
          150950000.0,
          0.1,
          0.0,
          0.0,
          "-",
          "-",
          8109.999999999999,
        ],
      },
      {
        "0916": [
          "0916",
          "EM",
          55040000000.0,
          16.48,
          0.74,
          4.7,
          23.94,
          0.86,
          357730000.0,
        ],
      },
      {
        "0918": [
          "0918",
          "EM",
          252460000.0,
          0.33,
          0.005,
          1.54,
          7.17,
          "-",
          30940.0,
        ],
      },
      {
        "0919": [
          "0919",
          "EM",
          128430000.0,
          0.142,
          0.004,
          2.9,
          1.02,
          "-",
          27400.0,
        ],
      },
      {
        "0921": [
          "0921",
          "EM",
          4810000000.0,
          10.48,
          0.28,
          2.75,
          7.73,
          3.88,
          3600000.0,
        ],
      },
      {
        "0922": [
          "0922",
          "EM",
          406500000.0,
          0.183,
          -0.001,
          -0.54,
          4.21,
          10.38,
          3660.0,
        ],
      },
      { "0923": ["0923", "EM", 207380000.0, 0.043, 0.0, 0.0, "-", "-", "-"] },
      { "0924": ["0924", "EM", 670000000.0, 0.67, 0.0, 0.0, "-", "-", "-"] },
      {
        "0925": [
          "0925",
          "EM",
          696930000.0,
          0.1,
          0.001,
          1.01,
          "-",
          "-",
          255210.0,
        ],
      },
      {
        "0926": [
          "0926",
          "EM",
          262459999.99999997,
          0.161,
          -0.01,
          -5.85,
          4.78,
          23.29,
          432210.0,
        ],
      },
      {
        "0927": [
          "0927",
          "EM",
          336410000.0,
          0.79,
          -0.01,
          -1.25,
          32.78,
          5.06,
          121040.0,
        ],
      },
      { "0928": ["0928", "EM", 154200000.0, 0.12, 0.0, 0.0, "-", "-", "-"] },
      {
        "0929": [
          "0929",
          "EM",
          999640000.0,
          0.95,
          0.01,
          1.06,
          36.54,
          "-",
          123500.0,
        ],
      },
      {
        "0931": [
          "0931",
          "EM",
          1970000000.0,
          0.35,
          0.0,
          0.0,
          "-",
          "-",
          419840.0,
        ],
      },
      {
        "0932": [
          "0932",
          "EM",
          590490000.0,
          0.19,
          0.001,
          0.53,
          "-",
          "-",
          287180.0,
        ],
      },
      {
        "0934": [
          "0934",
          "EM",
          7480000000.0,
          3.01,
          0.04,
          1.35,
          3.25,
          6.64,
          2740000.0,
        ],
      },
      {
        "0935": [
          "0935",
          "EM",
          1500000000.0,
          1.23,
          0.01,
          0.82,
          17.9,
          3.9,
          14760.0,
        ],
      },
      { "0936": ["0936", "EM", 318000000.0, 0.3, 0.0, 0.0, "-", "-", "-"] },
      { "0938": ["0938", "EM", 996680000.0, 1.54, 0.0, 0.0, "-", "-", "-"] },
      {
        "0939": [
          "0939",
          "EM",
          1456920000000.0,
          6.06,
          0.1,
          1.68,
          4.87,
          6.45,
          1380000000.0,
        ],
      },
      {
        "0941": [
          "0941",
          "EM",
          1158800000000.0,
          54.35,
          1.85,
          3.52,
          8.76,
          6.24,
          1910000000.0,
        ],
      },
      {
        "0943": ["0943", "EM", 235870000.0, 0.022, 0.0, 0.0, "-", "-", 63170.0],
      },
      {
        "0945": [
          "0945",
          "EM",
          313540000000.0,
          161.4,
          2.6,
          1.64,
          9.12,
          4.45,
          8560000.0,
        ],
      },
      {
        "0947": [
          "0947",
          "EM",
          290070000.0,
          0.355,
          -0.005,
          -1.39,
          "-",
          "-",
          122500.0,
        ],
      },
      {
        "0948": [
          "0948",
          "EM",
          487250000.0,
          1.55,
          0.01,
          0.65,
          "-",
          "-",
          12320.0,
        ],
      },
      {
        "0950": [
          "0950",
          "EM",
          1730000000.0,
          2.95,
          -0.05,
          -1.67,
          13.42,
          2.07,
          1230000.0,
        ],
      },
      {
        "0951": [
          "0951",
          "EM",
          2500000000.0,
          2.27,
          0.07,
          3.18,
          2.96,
          5.15,
          2990000.0,
        ],
      },
      {
        "0952": [
          "0952",
          "EM",
          1220000000.0,
          0.197,
          0.002,
          1.03,
          11.73,
          2.54,
          11820.0,
        ],
      },
      {
        "0953": [
          "0953",
          "EM",
          161830000.0,
          0.114,
          0.0,
          0.0,
          60.0,
          "-",
          18560.0,
        ],
      },
      {
        "0954": [
          "0954",
          "EM",
          124910000.0,
          0.68,
          -0.02,
          -2.86,
          18.28,
          3.54,
          10880.0,
        ],
      },
      {
        "0956": [
          "0956",
          "EM",
          9100000000.0,
          4.95,
          0.22,
          4.65,
          11.03,
          3.31,
          51600000.0,
        ],
      },
      {
        "0959": ["0959", "EM", 51290000.0, 0.4, 0.005, 1.27, "-", "-", 19870.0],
      },
      {
        "0960": [
          "0960",
          "EM",
          285490000000.0,
          47.0,
          0.5,
          1.08,
          11.75,
          3.88,
          208070000.0,
        ],
      },
      {
        "0966": [
          "0966",
          "EM",
          40390000000.0,
          11.24,
          0.26,
          2.37,
          6.17,
          3.56,
          30420000.0,
        ],
      },
      { "0967": ["0967", "EM", 4480000000.0, 2.98, 0.0, 0.0, "-", "-", "-"] },
      {
        "0968": [
          "0968",
          "EM",
          114860000000.0,
          12.92,
          0.52,
          4.19,
          23.32,
          2.63,
          251300000.0,
        ],
      },
      { "0969": ["0969", "EM", 138040000.0, 0.063, 0.0, 0.0, "-", "-", "-"] },
      {
        "0970": [
          "0970",
          "EM",
          957590000.0,
          0.175,
          -0.003,
          -1.69,
          23.65,
          "-",
          2870000.0,
        ],
      },
      {
        "0973": [
          "0973",
          "EM",
          45490000000.0,
          30.8,
          0.25,
          0.82,
          31.91,
          1.08,
          29840000.0,
        ],
      },
      { "0974": ["0974", "EM", 197510000.0, 0.68, 0.0, 0.0, "-", "-", "-"] },
      {
        "0975": [
          "0975",
          "EM",
          1520000000.0,
          1.48,
          0.02,
          1.37,
          6.81,
          "-",
          1800000.0,
        ],
      },
      {
        "0976": [
          "0976",
          "EM",
          1440000000.0,
          0.9,
          0.02,
          2.27,
          "-",
          "-",
          505720.0,
        ],
      },
      {
        "0978": [
          "0978",
          "EM",
          3970000000.0,
          0.81,
          0.0,
          0.0,
          4.8,
          8.64,
          246900.0,
        ],
      },
      { "0979": ["0979", "EM", 281800000.0, 0.248, 0.0, 0.0, "-", "-", "-"] },
      {
        "0980": [
          "0980",
          "EM",
          253360000.0,
          0.68,
          -0.02,
          -2.86,
          "-",
          "-",
          325630.0,
        ],
      },
      {
        "0981": [
          "0981",
          "EM",
          149700000000.0,
          18.94,
          0.26,
          1.39,
          22.23,
          "-",
          117500000.0,
        ],
      },
      {
        "0982": [
          "0982",
          "EM",
          1580000000.0,
          0.158,
          0.001,
          0.64,
          42.7,
          "-",
          3140.0,
        ],
      },
      {
        "0983": [
          "0983",
          "EM",
          494200000.0,
          1.32,
          0.01,
          0.76,
          9.5,
          "-",
          21260.0,
        ],
      },
      {
        "0984": [
          "0984",
          "EM",
          338000000.0,
          1.3,
          0.02,
          1.56,
          "-",
          6.15,
          38910.0,
        ],
      },
      {
        "0985": [
          "0985",
          "EM",
          1160000000.0,
          2.4,
          -0.01,
          -0.41,
          0.39,
          "-",
          126960.0,
        ],
      },
      { "0986": ["0986", "EM", 109930000.0, 0.088, 0.0, 0.0, 13.54, "-", "-"] },
      {
        "0987": [
          "0987",
          "EM",
          776900000.0,
          0.31,
          0.005,
          1.64,
          9.01,
          2.9,
          376880.0,
        ],
      },
      {
        "0988": [
          "0988",
          "EM",
          93700000.0,
          0.146,
          -0.004,
          -2.67,
          "-",
          "-",
          4880.0,
        ],
      },
      {
        "0989": [
          "0989",
          "EM",
          3510000000.0,
          0.52,
          -0.02,
          -3.7,
          "-",
          "-",
          123300.0,
        ],
      },
      {
        "0990": [
          "0990",
          "EM",
          11850000000.0,
          0.88,
          0.1,
          12.82,
          23.34,
          "-",
          9680000.0,
        ],
      },
      {
        "0991": [
          "0991",
          "EM",
          9040000000.0,
          1.48,
          0.02,
          1.37,
          12.75,
          7.38,
          10050000.0,
        ],
      },
      {
        "0992": [
          "0992",
          "EM",
          105000000000.0,
          8.72,
          0.35,
          4.18,
          11.82,
          3.67,
          198390000.0,
        ],
      },
      {
        "0993": [
          "0993",
          "EM",
          1450000000.0,
          0.167,
          -0.004,
          -2.34,
          "-",
          "-",
          35230.0,
        ],
      },
      { "0994": ["0994", "EM", 1720000000.0, 2.27, 0.0, 0.0, "-", "-", "-"] },
      {
        "0995": [
          "0995",
          "EM",
          3280000000.0,
          6.66,
          0.06,
          0.91,
          10.27,
          4.17,
          2540000.0,
        ],
      },
      {
        "0996": ["0996", "EM", 93830000.0, 0.099, -0.001, -1.0, "-", "-", "-"],
      },
      { "0997": ["0997", "EM", 70150000.0, 0.06, 0.0, 0.0, "-", "-", 14910.0] },
      {
        "0998": [
          "0998",
          "EM",
          55800000000.0,
          3.75,
          0.04,
          1.08,
          3.39,
          8.14,
          70430000.0,
        ],
      },
      { 1000: ["1000", "EM", 39520000.0, 0.72, 0.0, 0.0, "-", "-", "-"] },
      { 1001: ["1001", "EM", 268970000.0, 0.42, 0.0, 0.0, 161.54, 5.95, "-"] },
      { 1002: ["1002", "EM", 336890000.0, 0.146, 0.0, 0.0, "-", "-", "-"] },
      {
        1003: ["1003", "EM", 5220000000.0, 1.43, 0.0, 0.0, "-", "-", 3770000.0],
      },
      { 1004: ["1004", "EM", 384340000.0, 0.041, 0.0, 0.0, "-", "-", "-"] },
      { 1005: ["1005", "EM", 1960000000.0, 2.6, 0.0, 0.0, 185.71, 2.31, "-"] },
      { 1007: ["1007", "EM", 96050000.0, 0.137, -0.004, -2.84, "-", "-", "-"] },
      {
        1008: [
          "1008",
          "EM",
          525240000.0,
          0.335,
          -0.005,
          -1.47,
          58.77,
          29.85,
          "-",
        ],
      },
      { 1009: ["1009", "EM", 376510000.0, 0.275, 0.0, 0.0, "-", "-", "-"] },
      {
        1010: [
          "1010",
          "EM",
          1820000000.0,
          4.92,
          0.03,
          0.61,
          "-",
          "-",
          3050000.0,
        ],
      },
      {
        1011: [
          "1011",
          "EM",
          127610000.0,
          0.067,
          -0.001,
          -1.47,
          "-",
          "-",
          32390.0,
        ],
      },
      { 1013: ["1013", "EM", 179680000.0, 0.084, 0.0, 0.0, "-", "-", "-"] },
      {
        1020: [
          "1020",
          "EM",
          564510000.0,
          0.142,
          -0.012,
          -7.79,
          "-",
          "-",
          110910.0,
        ],
      },
      {
        1022: [
          "1022",
          "EM",
          575800000.0,
          0.335,
          0.005,
          1.52,
          "-",
          "-",
          200770.0,
        ],
      },
      {
        1023: [
          "1023",
          "EM",
          502020000.0,
          0.52,
          -0.01,
          -1.89,
          "-",
          5.77,
          42760.0,
        ],
      },
      {
        1024: [
          "1024",
          "EM",
          380780000000.0,
          89.65,
          1.7,
          1.93,
          "-",
          "-",
          1720000000.0,
        ],
      },
      {
        1025: [
          "1025",
          "EM",
          360360000.0,
          0.385,
          0.02,
          5.48,
          "-",
          "-",
          928380.0,
        ],
      },
      {
        1026: [
          "1026",
          "EM",
          975800000.0,
          0.177,
          0.015,
          9.26,
          "-",
          "-",
          22880.0,
        ],
      },
      { 1027: ["1027", "EM", 148410000.0, 0.49, 0.0, 0.0, "-", "-", 14750.0] },
      {
        1028: [
          "1028",
          "EM",
          768490000.0,
          0.37,
          -0.005,
          -1.33,
          105.71,
          "-",
          14170.0,
        ],
      },
      {
        1029: [
          "1029",
          "EM",
          1840000000.0,
          0.26,
          -0.005,
          -1.89,
          2.36,
          "-",
          3900000.0,
        ],
      },
      {
        1030: [
          "1030",
          "EM",
          34870000000.0,
          5.36,
          0.09,
          1.71,
          2.77,
          9.22,
          9800000.0,
        ],
      },
      {
        1031: [
          "1031",
          "EM",
          5710000000.0,
          0.42,
          0.01,
          2.44,
          131.25,
          "-",
          184620.0,
        ],
      },
      {
        1033: [
          "1033",
          "EM",
          3730000000.0,
          0.69,
          0.01,
          1.47,
          "-",
          "-",
          987680.0,
        ],
      },
      {
        1034: [
          "1034",
          "EM",
          3960000000.0,
          2.48,
          -0.11,
          -4.25,
          "-",
          "-",
          15830000.0,
        ],
      },
      {
        1036: [
          "1036",
          "EM",
          954340000.0,
          2.45,
          -0.03,
          -1.21,
          5.14,
          3.67,
          49600.0,
        ],
      },
      { 1037: ["1037", "EM", 273600000.0, 0.39, 0.0, 0.0, 14.94, "-", "-"] },
      {
        1038: [
          "1038",
          "EM",
          122570000000.0,
          48.65,
          0.65,
          1.35,
          16.75,
          5.1,
          121250000.0,
        ],
      },
      {
        1039: ["1039", "EM", 362190000.0, 0.2, -0.007, -3.38, "-", "-", 7800.0],
      },
      { 1041: ["1041", "EM", 76080000.0, 0.043, 0.0, 0.0, "-", "-", "-"] },
      { 1043: ["1043", "EM", 195140000.0, 0.52, 0.0, 0.0, "-", "-", "-"] },
      {
        1044: [
          "1044",
          "EM",
          45200000000.0,
          38.9,
          0.8,
          2.1,
          8.53,
          7.09,
          127990000.0,
        ],
      },
      {
        1045: [
          "1045",
          "EM",
          2200000000.0,
          2.37,
          0.03,
          1.28,
          9.53,
          9.7,
          1090000.0,
        ],
      },
      {
        1046: ["1046", "EM", 571170000.0, 0.63, 0.02, 3.28, 3.7, "-", 333000.0],
      },
      {
        1047: ["1047", "EM", 239980000.0, 0.65, 0.01, 1.56, 2.67, 9.23, 1320.0],
      },
      { 1049: ["1049", "EM", 138030000.0, 1.71, 0.0, 0.0, 2.61, 14.62, "-"] },
      {
        1050: [
          "1050",
          "EM",
          3200000000.0,
          1.59,
          0.05,
          3.25,
          8.7,
          7.86,
          1630000.0,
        ],
      },
      {
        1051: ["1051", "EM", 1190000000.0, 2.65, 0.0, 0.0, 2.11, "-", 279180.0],
      },
      {
        1052: [
          "1052",
          "EM",
          7980000000.0,
          4.77,
          0.05,
          1.06,
          42.21,
          5.66,
          5910000.0,
        ],
      },
      {
        1053: [
          "1053",
          "EM",
          672650000.0,
          1.25,
          0.02,
          1.63,
          15.11,
          "-",
          1070000.0,
        ],
      },
      {
        1055: [
          "1055",
          "EM",
          21880000000.0,
          5.12,
          0.17,
          3.43,
          "-",
          "-",
          27430000.0,
        ],
      },
      {
        1057: [
          "1057",
          "EM",
          414050000.0,
          1.91,
          -0.01,
          -0.52,
          31.47,
          "-",
          678100.0,
        ],
      },
      { 1058: ["1058", "EM", 484210000.0, 0.9, 0.0, 0.0, "-", "-", 45000.0] },
      {
        1059: ["1059", "EM", 103090000.0, 0.475, 0.0, 0.0, 18.13, "-", 26420.0],
      },
      {
        1060: [
          "1060",
          "EM",
          21730000000.0,
          0.81,
          -0.07,
          -7.95,
          "-",
          "-",
          25020000.0,
        ],
      },
      {
        1061: [
          "1061",
          "EM",
          2940000000.0,
          5.12,
          0.0,
          0.0,
          13.52,
          1.76,
          830510.0,
        ],
      },
      {
        1062: ["1062", "-", 417910000.0, 0.144, 0.01, 7.46, 7.87, "-", 11180.0],
      },
      {
        1063: [
          "1063",
          "EM",
          477730000.0,
          0.32,
          -0.01,
          -3.03,
          "-",
          "-",
          59000.0,
        ],
      },
      { 1064: ["1064", "EM", 64220000.0, 0.09, 0.001, 1.12, "-", "-", "-"] },
      {
        1065: [
          "1065",
          "EM",
          1240000000.0,
          3.66,
          -0.1,
          -2.66,
          7.78,
          3.98,
          616680.0,
        ],
      },
      {
        1066: [
          "1066",
          "EM",
          44900000000.0,
          9.93,
          0.39,
          4.09,
          18.76,
          1.76,
          58170000.0,
        ],
      },
      {
        1068: [
          "1068",
          "EM",
          1420000000.0,
          0.78,
          -0.08,
          -9.3,
          "-",
          "-",
          22050000.0,
        ],
      },
      { 1069: ["1069", "EM", 110240000.0, 0.01, 0.0, 0.0, "-", "-", "-"] },
      {
        1070: [
          "1070",
          "EM",
          10040000000.0,
          4.05,
          0.1,
          2.53,
          2.62,
          2.84,
          7030000.0,
        ],
      },
      {
        1071: [
          "1071",
          "EM",
          4920000000.0,
          2.87,
          0.02,
          0.7,
          7.45,
          10.46,
          9780000.0,
        ],
      },
      {
        1072: [
          "1072",
          "EM",
          3690000000.0,
          10.86,
          0.36,
          3.43,
          15.52,
          1.99,
          8690000.0,
        ],
      },
      {
        1073: [
          "1073",
          "EM",
          262040000.00000003,
          0.23,
          0.0,
          0.0,
          5.37,
          "-",
          "-",
        ],
      },
      {
        1075: [
          "1075",
          "EM",
          305920000.0,
          0.395,
          -0.005,
          -1.25,
          7.67,
          5.95,
          566460.0,
        ],
      },
      {
        1076: [
          "1076",
          "EM",
          713490000.0,
          0.067,
          0.002,
          3.08,
          "-",
          "-",
          182510.0,
        ],
      },
      {
        1079: [
          "1079",
          "EM",
          110110000.0,
          0.083,
          -0.001,
          -1.19,
          "-",
          "-",
          5470.0,
        ],
      },
      {
        1080: ["1080", "EM", 302200000.0, 0.078, 0.003, 4.0, "-", "-", 57200.0],
      },
      {
        1082: [
          "1082",
          "EM",
          942360000.0,
          1.58,
          0.02,
          1.28,
          250.79,
          "-",
          591400.0,
        ],
      },
      {
        1083: [
          "1083",
          "EM",
          18320000000.0,
          5.8,
          0.07,
          1.22,
          11.7,
          2.59,
          19290000.0,
        ],
      },
      {
        1084: ["1084", "EM", 952150000.0, 1.16, -0.01, -0.85, 13.57, 3.45, "-"],
      },
      {
        1085: [
          "1085",
          "EM",
          1600000000.0,
          4.14,
          0.13,
          3.24,
          22.17,
          0.46,
          228320.0,
        ],
      },
      {
        1086: [
          "1086",
          "EM",
          1660000000.0,
          1.0,
          -0.1,
          -9.09,
          6.5,
          "-",
          5740000.0,
        ],
      },
      { 1087: ["1087", "EM", 155000000.0, 1.84, 0.01, 0.55, "-", "-", 7400.0] },
      {
        1088: [
          "1088",
          "EM",
          67540000000.00001,
          20.0,
          0.92,
          4.82,
          9.45,
          10.79,
          405920000.0,
        ],
      },
      {
        1090: [
          "1090",
          "EM",
          3650000000.0,
          2.87,
          0.12,
          4.36,
          8.56,
          4.18,
          159700.0,
        ],
      },
      {
        1091: [
          "1091",
          "EM",
          3220000000.0,
          0.94,
          0.02,
          2.17,
          "-",
          "-",
          9710000.0,
        ],
      },
      {
        1093: [
          "1093",
          "EM",
          114200000000.0,
          9.57,
          0.12,
          1.27,
          18.79,
          1.78,
          203580000.0,
        ],
      },
      {
        1094: [
          "1094",
          "EM",
          386870000.0,
          1.32,
          0.13,
          10.92,
          178.38,
          "-",
          81680.0,
        ],
      },
      { 1096: ["1096", "EM", 50090000.0, 0.026, 0.0, 0.0, "-", "-", "-"] },
      {
        1097: [
          "1097",
          "EM",
          435210000.0,
          0.061,
          0.001,
          1.67,
          "-",
          "-",
          492200.0,
        ],
      },
      {
        1098: [
          "1098",
          "EM",
          5600000000.0,
          7.48,
          0.1,
          1.36,
          3.25,
          9.36,
          1110000.0,
        ],
      },
      {
        1099: [
          "1099",
          "EM",
          24230000000.0,
          18.06,
          0.68,
          3.91,
          6.62,
          4.51,
          68590000.0,
        ],
      },
      {
        1100: [
          "1100",
          "EM",
          693100000.0,
          1.71,
          0.03,
          1.79,
          15.11,
          3.51,
          160280.0,
        ],
      },
      {
        1101: [
          "1101",
          "EM",
          448420000.0,
          0.094,
          -0.002,
          -2.08,
          1.2,
          "-",
          2070.0,
        ],
      },
      { 1102: ["1102", "EM", 48810000.0, 0.09, 0.0, 0.0, "-", "-", "-"] },
      { 1103: ["1103", "EM", 80620000.0, 0.013, 0.0, 0.0, "-", "-", 27880.0] },
      {
        1104: [
          "1104",
          "EM",
          1360000000.0,
          1.05,
          0.0,
          0.0,
          0.94,
          19.05,
          105000.0,
        ],
      },
      {
        1105: ["1105", "EM", 457880000.0, 0.52, 0.01, 1.96, "-", 3.85, 78580.0],
      },
      {
        1107: [
          "1107",
          "EM",
          424830000.0,
          0.152,
          0.001,
          0.66,
          0.49,
          24.01,
          73770.0,
        ],
      },
      {
        1108: [
          "1108",
          "EM",
          3570000000.0,
          14.3,
          0.5,
          3.62,
          20.6,
          "-",
          15130000.0,
        ],
      },
      {
        1109: [
          "1109",
          "EM",
          275250000000.0,
          38.6,
          1.05,
          2.8,
          7.86,
          3.94,
          328160000.0,
        ],
      },
      {
        1110: ["1110", "EM", 398400000.0, 0.64, 0.0, 0.0, 28.57, 1.02, 30720.0],
      },
      {
        1112: [
          "1112",
          "EM",
          8380000000.000001,
          13.0,
          -0.08,
          -0.61,
          6.24,
          5.85,
          20900000.0,
        ],
      },
      {
        1113: [
          "1113",
          "EM",
          190740000000.0,
          52.35,
          0.5,
          0.96,
          11.84,
          3.57,
          317780000.0,
        ],
      },
      { 1114: ["1114", "EM", 36830000000.0, 7.3, 0.0, 0.0, 4.97, 5.62, "-"] },
      {
        1115: [
          "1115",
          "EM",
          1530000000.0,
          0.59,
          0.02,
          3.51,
          73.75,
          "-",
          4110.0,
        ],
      },
      {
        1116: [
          "1116",
          "EM",
          422960000.0,
          0.196,
          -0.006,
          -2.97,
          23.06,
          "-",
          39670.0,
        ],
      },
      {
        1117: [
          "1117",
          "EM",
          10520000000.0,
          1.33,
          0.01,
          0.76,
          9.33,
          1.83,
          7560000.0,
        ],
      },
      { 1118: ["1118", "EM", 631810000.0, 1.1, 0.0, 0.0, 6.75, 5.0, "-"] },
      {
        1119: [
          "1119",
          "EM",
          7350000000.0,
          5.3,
          0.22,
          4.33,
          "-",
          "-",
          15260000.0,
        ],
      },
      { 1120: ["1120", "EM", 270380000.0, 0.7, 0.0, 0.0, "-", "-", 15400.0] },
      {
        1121: [
          "1121",
          "EM",
          15610000000.0,
          9.12,
          0.33,
          3.75,
          "-",
          "-",
          5120000.0,
        ],
      },
      {
        1122: [
          "1122",
          "EM",
          1950000000.0,
          1.58,
          0.03,
          1.94,
          11.1,
          7.66,
          1570000.0,
        ],
      },
      { 1123: ["1123", "EM", 156460000.0, 0.132, 0.0, 0.0, 4.65, 7.58, "-"] },
      { 1124: ["1124", "EM", 203150000.0, 0.049, 0.0, 0.0, "-", "-", "-"] },
      { 1125: ["1125", "EM", 1880000000.0, 5.7, 0.0, 0.0, "-", "-", "-"] },
      {
        1126: [
          "1126",
          "EM",
          1900000000.0,
          2.82,
          0.02,
          0.71,
          7.0,
          4.26,
          255100.0,
        ],
      },
      {
        1127: ["1127", "EM", 685300000.0, 0.89, 0.01, 1.14, 6.55, 8.99, 7040.0],
      },
      {
        1128: [
          "1128",
          "EM",
          36440000000.0,
          7.0,
          -0.02,
          -0.28,
          "-",
          "-",
          101480000.0,
        ],
      },
      {
        1129: [
          "1129",
          "EM",
          462990000.0,
          0.29,
          0.005,
          1.75,
          42.03,
          "-",
          25880.0,
        ],
      },
      { 1130: ["1130", "EM", 120150000.0, 0.059, 0.0, 0.0, "-", "-", "-"] },
      {
        1132: [
          "1132",
          "EM",
          265959999.99999997,
          0.095,
          -0.007,
          -6.86,
          "-",
          "-",
          11200.0,
        ],
      },
      {
        1133: [
          "1133",
          "EM",
          1860000000.0,
          2.76,
          0.18,
          6.98,
          "-",
          0.47,
          20310000.0,
        ],
      },
      { 1134: ["1134", "EM", 590000000.0, 1.18, 0.0, 0.0, "-", "-", "-"] },
      {
        1137: [
          "1137",
          "EM",
          6960000000.0,
          7.6,
          0.38,
          5.26,
          37.16,
          1.05,
          16620000.000000002,
        ],
      },
      {
        1138: [
          "1138",
          "EM",
          3950000000.0,
          3.05,
          0.11,
          3.74,
          5.01,
          7.88,
          9060000.0,
        ],
      },
      { 1139: ["1139", "EM", 204470000.0, 0.238, 0.0, 0.0, "-", "-", "-"] },
      {
        1140: ["1140", "-", 2880000000.0, 0.71, 0.01, 1.43, 5.53, "-", 36880.0],
      },
      {
        1141: [
          "1141",
          "EM",
          3400000000.0,
          2.92,
          0.04,
          1.39,
          8.8,
          4.52,
          1200000.0,
        ],
      },
      { 1142: ["1142", "EM", 133410000.0, 0.92, 0.01, 1.1, 0.39, "-", 7360.0] },
      {
        1143: [
          "1143",
          "EM",
          147890000.0,
          0.16,
          0.008,
          5.26,
          "-",
          "-",
          848970.0,
        ],
      },
      { 1145: ["1145", "EM", 270030000.0, 0.246, -0.004, -1.6, "-", "-", "-"] },
      {
        1146: [
          "1146",
          "EM",
          544380000.0,
          0.158,
          -0.022,
          -12.22,
          "-",
          "-",
          18070.0,
        ],
      },
      {
        1147: ["1147", "EM", 204000000.0, 0.102, 0.0, 0.0, 9.81, "-", 44680.0],
      },
      {
        1148: ["1148", "EM", 910370000.0, 0.71, 0.01, 1.43, "-", "-", 507240.0],
      },
      {
        1150: ["1150", "EM", 188660000.0, 0.265, 0.005, 1.92, "-", "-", 2650.0],
      },
      {
        1152: [
          "1152",
          "EM",
          66769999.99999999,
          0.068,
          0.0,
          0.0,
          14.78,
          "-",
          "-",
        ],
      },
      {
        1153: [
          "1153",
          "EM",
          2080000000.0,
          3.41,
          0.0,
          0.0,
          20.38,
          1.88,
          88540.0,
        ],
      },
      { 1156: ["1156", "EM", 265390000.0, 0.45, 0.0, 0.0, "-", "-", "-"] },
      {
        1157: [
          "1157",
          "EM",
          8410000000.0,
          5.32,
          0.24,
          4.72,
          4.65,
          7.27,
          24490000.0,
        ],
      },
      { 1159: ["1159", "EM", 576490000.0, 0.7, -0.03, -4.11, "-", "-", "-"] },
      { 1160: ["1160", "-", 352510000.0, 1.36, 0.0, 0.0, "-", "-", "-"] },
      {
        1161: [
          "1161",
          "EM",
          1190000000.0,
          1.76,
          0.03,
          1.73,
          7.03,
          12.5,
          1250000.0,
        ],
      },
      { 1162: ["1162", "EM", 124800000.0, 0.208, 0.0, 0.0, "-", "-", "-"] },
      {
        1163: [
          "1163",
          "EM",
          610050000.0,
          0.98,
          0.02,
          2.08,
          155.56,
          "-",
          4900.0,
        ],
      },
      {
        1164: [
          "1164",
          "EM",
          4950000000.0,
          0.75,
          -0.07,
          -8.54,
          31.91,
          0.67,
          13950000.0,
        ],
      },
      {
        1165: [
          "1165",
          "EM",
          283990000.0,
          0.057,
          0.002,
          3.64,
          "-",
          "-",
          138260.0,
        ],
      },
      {
        1166: ["1166", "EM", 163840000.0, 0.069, -0.001, -1.43, 1.05, "-", "-"],
      },
      {
        1167: [
          "1167",
          "EM",
          7390000000.0,
          9.58,
          0.31,
          3.34,
          "-",
          "-",
          3350000.0,
        ],
      },
      {
        1168: [
          "1168",
          "EM",
          1490000000.0,
          0.234,
          0.004,
          1.74,
          "-",
          "-",
          166960.0,
        ],
      },
      { 1170: ["1170", "EM", 695160000.0, 1.02, 0.0, 0.0, 7.82, 4.71, "-"] },
      {
        1171: [
          "1171",
          "EM",
          32710000000.0,
          17.22,
          0.78,
          4.74,
          11.33,
          7.04,
          175850000.0,
        ],
      },
      { 1172: ["1172", "EM", 421880000.0, 0.073, 0.0, 0.0, 146.0, "-", "-"] },
      {
        1173: [
          "1173",
          "EM",
          130930000.0,
          0.052,
          -0.005,
          -8.77,
          "-",
          "-",
          45500.0,
        ],
      },
      { 1175: ["1175", "EM", 97130000.0, 0.059, 0.0, 0.0, "-", "-", "-"] },
      {
        1176: [
          "1176",
          "EM",
          12280000000.0,
          1.7,
          0.02,
          1.19,
          5.57,
          5.88,
          2490000.0,
        ],
      },
      {
        1177: [
          "1177",
          "EM",
          102840000000.0,
          5.46,
          0.1,
          1.87,
          31.38,
          1.47,
          125870000.0,
        ],
      },
      { 1178: ["1178", "EM", 35420000.0, 0.119, 0.0, 0.0, "-", "-", "-"] },
      {
        1179: [
          "1179",
          "EM",
          101290000000.0,
          31.0,
          1.1,
          3.68,
          "-",
          "-",
          15720000.0,
        ],
      },
      {
        1180: ["1180", "EM", 1060000000.0, 1.01, 0.0, 0.0, "-", "-", 125680.0],
      },
      { 1181: ["1181", "EM", 817780000.0, 0.76, 0.0, 0.0, 158.33, 7.24, "-"] },
      {
        1182: [
          "1182",
          "EM",
          48230000.0,
          0.345,
          -0.015,
          -4.17,
          "-",
          "-",
          28050.0,
        ],
      },
      {
        1183: [
          "1183",
          "EM",
          6360000000.0,
          3.57,
          0.07,
          2.0,
          129.82,
          1.34,
          4210000.0,
        ],
      },
      {
        1184: [
          "1184",
          "EM",
          3510000000.0,
          5.61,
          0.01,
          0.18,
          13.41,
          5.61,
          189840.0,
        ],
      },
      { 1185: ["1185", "EM", 668450000.0, 0.153, 0.0, 0.0, "-", "-", "-"] },
      {
        1186: [
          "1186",
          "EM",
          11480000000.0,
          5.53,
          0.09,
          1.65,
          3.15,
          4.99,
          17260000.0,
        ],
      },
      { 1188: ["1188", "EM", 814110000.0, 0.04, 0.0, 0.0, "-", "-", "-"] },
      { 1189: ["1189", "EM", 120740000.0, 0.153, 0.0, 0.0, "-", "-", "-"] },
      { 1191: ["1191", "EM", 89250000.0, 0.021, 0.0, 0.0, "-", "-", "-"] },
      { 1192: ["1192", "EM", 387490000.0, 0.315, 0.0, 0.0, 0.24, "-", "-"] },
      {
        1193: [
          "1193",
          "EM",
          90470000000.0,
          39.1,
          0.1,
          0.26,
          16.94,
          2.38,
          88750000.0,
        ],
      },
      { 1194: ["1194", "EM", 117800000.0, 0.395, 0.0, 0.0, "-", "-", "-"] },
      {
        1195: [
          "1195",
          "EM",
          141810000.0,
          0.049,
          0.003,
          6.52,
          "-",
          "-",
          65420.0,
        ],
      },
      {
        1196: [
          "1196",
          "EM",
          13280000000.0,
          9.23,
          0.09,
          0.98,
          15.0,
          "-",
          9160000.0,
        ],
      },
      {
        1198: [
          "1198",
          "EM",
          7820000000.0,
          3.01,
          -0.04,
          -1.31,
          10.94,
          3.32,
          192390.0,
        ],
      },
      {
        1199: [
          "1199",
          "EM",
          21050000000.0,
          6.35,
          0.15,
          2.42,
          7.59,
          5.34,
          12410000.0,
        ],
      },
      {
        1200: [
          "1200",
          "EM",
          674060000.0,
          0.94,
          0.01,
          1.08,
          5.12,
          "-",
          113460.0,
        ],
      },
      {
        1201: ["1201", "EM", 550230000.0, 0.445, 0.01, 2.3, "-", "-", 76630.0],
      },
      {
        1202: ["1202", "EM", 219200000.0, 1.37, 0.01, 0.74, "-", "-", 134140.0],
      },
      {
        1203: ["1203", "EM", 626230000.0, 0.69, 0.0, 0.0, 8.96, 3.62, 19120.0],
      },
      {
        1205: [
          "1205",
          "EM",
          3880000000.0,
          0.495,
          0.0,
          0.0,
          "-",
          "-",
          3700000.0,
        ],
      },
      { 1206: ["1206", "EM", 703970000.0, 0.9, 0.0, 0.0, 4.59, "-", 231360.0] },
      { 1207: ["1207", "EM", 719760000.0, 0.035, 0.0, 0.0, "-", "-", 72570.0] },
      {
        1208: [
          "1208",
          "EM",
          22460000000.0,
          2.6,
          0.11,
          4.42,
          "-",
          "-",
          21000000.0,
        ],
      },
      {
        1209: [
          "1209",
          "EM",
          105670000000.0,
          46.3,
          0.65,
          1.42,
          81.29,
          0.34,
          74470000.0,
        ],
      },
      { 1210: ["1210", "EM", 170720000.0, 0.169, 0.0, 0.0, "-", "-", "-"] },
      {
        1211: [
          "1211",
          "EM",
          260220000000.00003,
          237.0,
          15.6,
          7.05,
          137.21,
          0.08,
          1760000000.0,
        ],
      },
      {
        1212: [
          "1212",
          "EM",
          6570000000.0,
          4.38,
          0.15,
          3.55,
          47.51,
          "-",
          794630.0,
        ],
      },
      { 1213: ["1213", "EM", 90000000.0, 0.45, 0.0, 0.0, 7.4, 2.22, "-"] },
      { 1215: ["1215", "EM", 357800000.0, 0.028, 0.0, 0.0, "-", "-", "-"] },
      {
        1216: [
          "1216",
          "EM",
          3330000000.0,
          0.88,
          -0.01,
          -1.12,
          5.35,
          "-",
          38680.0,
        ],
      },
      {
        1217: [
          "1217",
          "-",
          128009999.99999999,
          0.01,
          0.0,
          0.0,
          "-",
          "-",
          118400.0,
        ],
      },
      { 1218: ["1218", "EM", 312420000.0, 4.21, 0.01, 0.24, 17.14, "-", "-"] },
      {
        1220: [
          "1220",
          "EM",
          233640000.0,
          0.118,
          0.002,
          1.72,
          "-",
          "-",
          162810.0,
        ],
      },
      { 1221: ["1221", "EM", 2390000000.0, 2.1, 0.0, 0.0, "-", "-", "-"] },
      {
        1222: [
          "1222",
          "EM",
          1330000000.0,
          0.08,
          0.001,
          1.27,
          10.39,
          3.75,
          540840.0,
        ],
      },
      {
        1223: [
          "1223",
          "EM",
          3420000000.0,
          1.15,
          0.09,
          8.49,
          "-",
          0.43,
          2440000.0,
        ],
      },
      {
        1224: [
          "1224",
          "EM",
          7100000000.0,
          1.83,
          0.02,
          1.1,
          12.08,
          1.09,
          185970.0,
        ],
      },
      {
        1225: [
          "1225",
          "EM",
          63330000.0,
          0.275,
          -0.005,
          -1.79,
          "-",
          "-",
          7970.0,
        ],
      },
      { 1226: ["1226", "-", 221060000.0, 0.68, 0.0, 0.0, 47.55, "-", "-"] },
      { 1227: ["1227", "-", 37730000.0, 0.023, 0.0, 0.0, "-", "-", "-"] },
      {
        1228: ["1228", "EM", 3100000000.0, 7.32, 0.21, 2.95, "-", "-", 57640.0],
      },
      { 1229: ["1229", "EM", 55100000.0, 0.072, 0.0, 0.0, "-", "-", "-"] },
      {
        1230: [
          "1230",
          "EM",
          2610000000.0,
          0.55,
          0.01,
          1.85,
          21.91,
          "-",
          221820.0,
        ],
      },
      { 1231: ["1231", "EM", 1960000000.0, 0.49, 0.0, 0.0, 326.67, "-", "-"] },
      { 1232: ["1232", "EM", 298630000.0, 0.166, 0.0, 0.0, 7.35, "-", "-"] },
      {
        1233: [
          "1233",
          "EM",
          6130000000.0,
          3.09,
          0.07,
          2.32,
          1.03,
          29.96,
          23040000.0,
        ],
      },
      {
        1234: [
          "1234",
          "EM",
          5260000000.0,
          4.4,
          -0.02,
          -0.45,
          8.0,
          10.23,
          953410.0,
        ],
      },
      { 1235: ["1235", "EM", 96870000.0, 0.19, -0.001, -0.52, "-", "-", "-"] },
      {
        1237: ["1237", "EM", 75680000.0, 0.089, 0.001, 1.14, "-", "-", 72180.0],
      },
      {
        1238: [
          "1238",
          "EM",
          18010000000.0,
          4.35,
          0.02,
          0.46,
          2.49,
          11.72,
          6770000.0,
        ],
      },
      { 1239: ["1239", "EM", 59280000.0, 0.09, 0.0, 0.0, "-", "-", "-"] },
      {
        1240: [
          "1240",
          "EM",
          1040000000.0,
          0.69,
          0.02,
          2.99,
          5.19,
          11.59,
          40920.0,
        ],
      },
      {
        1241: [
          "1241",
          "EM",
          63700000.0,
          0.098,
          -0.014,
          -12.5,
          "-",
          "-",
          87640.0,
        ],
      },
      {
        1243: [
          "1243",
          "EM",
          1180000000.0,
          0.078,
          0.001,
          1.3,
          2.54,
          9.49,
          281680.0,
        ],
      },
      { 1245: ["1245", "EM", 298960000.0, 0.25, 0.0, 0.0, "-", "-", "-"] },
      { 1246: ["1246", "EM", 233570000.0, 0.172, 0.0, 0.0, "-", "-", "-"] },
      { 1247: ["1247", "EM", 51360000.0, 0.435, 0.0, 0.0, "-", "-", "-"] },
      {
        1250: [
          "1250",
          "EM",
          6220000000.0,
          0.098,
          0.003,
          3.16,
          10.65,
          "-",
          10130000.0,
        ],
      },
      {
        1251: [
          "1251",
          "EM",
          546860000.0,
          0.295,
          0.015,
          5.36,
          "-",
          "-",
          714910.0,
        ],
      },
      {
        1252: [
          "1252",
          "EM",
          19040000000.0,
          6.48,
          0.15,
          2.37,
          8.67,
          "-",
          8780000.0,
        ],
      },
      { 1253: ["1253", "EM", 601650000.0, 0.18, 0.0, 0.0, 6.57, "-", "-"] },
      {
        1255: ["1255", "EM", 388550000.0, 1.6, -0.08, -4.76, "-", "-", 28800.0],
      },
      {
        1257: [
          "1257",
          "EM",
          5590000000.0,
          2.71,
          0.07,
          2.65,
          3.73,
          5.17,
          2290000.0,
        ],
      },
      {
        1258: [
          "1258",
          "EM",
          11850000000.0,
          3.17,
          0.14,
          4.62,
          11.32,
          3.51,
          11970000.0,
        ],
      },
      {
        1259: ["1259", "EM", 166010000.0, 0.073, 0.0, 0.0, "-", "-", 106480.0],
      },
      { 1260: ["1260", "EM", 316640000.0, 0.275, 0.0, 0.0, 2.68, "-", 2750.0] },
      {
        1262: [
          "1262",
          "EM",
          677770000.0,
          0.51,
          -0.01,
          -1.92,
          "-",
          "-",
          149020.0,
        ],
      },
      {
        1263: [
          "1263",
          "EM",
          4710000000.0,
          12.16,
          0.64,
          5.56,
          21.83,
          8.72,
          34760000.0,
        ],
      },
      {
        1265: [
          "1265",
          "EM",
          240020000.0,
          0.48,
          -0.005,
          -1.03,
          "-",
          12.52,
          4800.0,
        ],
      },
      {
        1266: ["1266", "EM", 900260000.0, 0.38, 0.0, 0.0, 41.76, "-", 847190.0],
      },
      {
        1268: [
          "1268",
          "EM",
          47630000000.0,
          37.5,
          1.0,
          2.74,
          51.17,
          1.17,
          50970000.0,
        ],
      },
      {
        1269: ["1269", "EM", 326880000.0, 0.243, 0.005, 2.1, "-", "-", 86980.0],
      },
      {
        1270: ["1270", "EM", 2720000000.0, 0.84, 0.0, 0.0, "-", "-", 706940.0],
      },
      {
        1271: [
          "1271",
          "EM",
          11310000000.0,
          7.97,
          -0.01,
          -0.13,
          75.9,
          1.0,
          206800.0,
        ],
      },
      { 1272: ["1272", "EM", 611810000.0, 0.98, 0.0, 0.0, 8.17, 5.46, "-"] },
      {
        1273: ["1273", "EM", 178450000.0, 0.43, 0.0, 0.0, 2.44, 6.05, 24080.0],
      },
      {
        1277: [
          "1277",
          "EM",
          4800000000.0,
          0.57,
          0.02,
          3.64,
          4.99,
          16.67,
          2610000.0,
        ],
      },
      {
        1278: [
          "1278",
          "EM",
          826730000.0,
          0.085,
          -0.01,
          -10.53,
          "-",
          "-",
          55030.0,
        ],
      },
      { 1280: ["1280", "EM", 129370000.0, 0.59, 0.0, 0.0, "-", "-", "-"] },
      {
        1281: [
          "1281",
          "EM",
          191510000.0,
          0.129,
          -0.002,
          -1.53,
          161.25,
          "-",
          5560.0,
        ],
      },
      {
        1282: [
          "1282",
          "EM",
          5860000000.0,
          0.187,
          0.002,
          1.08,
          "-",
          "-",
          35660.0,
        ],
      },
      {
        1283: [
          "1283",
          "EM",
          1290000000.0,
          1.62,
          0.05,
          3.18,
          17.69,
          3.83,
          1610000.0,
        ],
      },
      {
        1285: [
          "1285",
          "EM",
          647400000.0,
          1.56,
          -0.06,
          -3.7,
          3.88,
          9.62,
          929400.0,
        ],
      },
      {
        1286: [
          "1286",
          "EM",
          3570000000.0,
          1.9,
          -0.03,
          -1.55,
          "-",
          2.47,
          311030.0,
        ],
      },
      {
        1288: [
          "1288",
          "EM",
          91900000000.0,
          2.99,
          0.03,
          1.01,
          4.33,
          7.51,
          220650000.0,
        ],
      },
      { 1289: ["1289", "EM", 29440000.0, 0.92, 0.0, 0.0, 41.44, 6.6, "-"] },
      { 1290: ["1290", "EM", 1130000000.0, 1.04, 0.0, 0.0, 452.17, "-", "-"] },
      {
        1292: [
          "1292",
          "EM",
          117700000.0,
          2.14,
          0.07,
          3.38,
          713.33,
          "-",
          6420.0,
        ],
      },
      {
        1293: ["1293", "EM", 1810000000.0, 0.64, 0.0, 0.0, 7.31, "-", 833070.0],
      },
      {
        1296: [
          "1296",
          "EM",
          1320000000.0,
          1.01,
          0.02,
          2.02,
          91.82,
          "-",
          24450000.0,
        ],
      },
      {
        1297: [
          "1297",
          "EM",
          965680000.0,
          0.79,
          0.01,
          1.28,
          1.59,
          30.62,
          269670.0,
        ],
      },
      { 1298: ["1298", "EM", 322260000.0, 1.17, 0.09, 8.33, "-", "-", 8900.0] },
      {
        1299: [
          "1299",
          "EM",
          1028240000000.0,
          85.0,
          4.35,
          5.39,
          22.95,
          1.63,
          3520000000.0,
        ],
      },
      {
        1300: [
          "1300",
          "EM",
          949490000.0,
          0.53,
          -0.01,
          -1.85,
          "-",
          "-",
          62020.0,
        ],
      },
      {
        1301: ["1301", "EM", 671370000.0, 1.05, 0.01, 0.96, "-", "-", 316820.0],
      },
      {
        1302: [
          "1302",
          "EM",
          15270000000.0,
          3.3,
          0.08,
          2.48,
          56.6,
          "-",
          10030000.0,
        ],
      },
      { 1303: ["1303", "EM", 388800000.0, 0.24, -0.001, -0.41, "-", "-", "-"] },
      {
        1305: ["1305", "EM", 260190000.0, 1.2, -0.08, -6.25, 8.4, "-", 24000.0],
      },
      {
        1308: [
          "1308",
          "EM",
          78990000000.0,
          29.45,
          -0.05,
          -0.17,
          28.8,
          8.15,
          192150000.0,
        ],
      },
      {
        1310: [
          "1310",
          "EM",
          13190000000.0,
          10.06,
          0.26,
          2.65,
          63.75,
          7.6,
          48990000.0,
        ],
      },
      { 1312: ["1312", "EM", 268250000.0, 0.048, 0.0, 0.0, "-", "-", "-"] },
      {
        1313: [
          "1313",
          "EM",
          47620000000.0,
          6.82,
          0.12,
          1.79,
          5.32,
          8.5,
          76310000.0,
        ],
      },
      {
        1314: [
          "1314",
          "EM",
          334460000.0,
          0.237,
          0.002,
          0.85,
          "-",
          "-",
          74300.0,
        ],
      },
      {
        1315: [
          "1315",
          "EM",
          187490000.0,
          0.025,
          0.001,
          4.17,
          20.83,
          "-",
          38140.0,
        ],
      },
      {
        1316: [
          "1316",
          "EM",
          22360000000.0,
          8.91,
          0.39,
          4.58,
          24.72,
          0.82,
          39510000.0,
        ],
      },
      {
        1317: [
          "1317",
          "EM",
          1450000000.0,
          0.485,
          0.0,
          0.0,
          "-",
          "-",
          1340000.0,
        ],
      },
      {
        1319: [
          "1319",
          "EM",
          470750000.0,
          0.244,
          -0.011,
          -4.31,
          5.06,
          7.79,
          148490.0,
        ],
      },
      {
        1321: [
          "1321",
          "EM",
          2109999999.9999998,
          1.05,
          0.05,
          5.0,
          40.38,
          "-",
          10500.0,
        ],
      },
      {
        1323: [
          "1323",
          "EM",
          482870000.0,
          0.138,
          0.001,
          0.73,
          10.62,
          2.17,
          1520000.0,
        ],
      },
      { 1326: ["1326", "EM", 332230000.0, 0.128, 0.0, 0.0, "-", "-", "-"] },
      {
        1327: ["1327", "EM", 194080000.0, 0.36, 0.005, 1.41, "-", "-", 81650.0],
      },
      {
        1328: [
          "1328",
          "EM",
          1230000000.0,
          0.108,
          0.01,
          10.2,
          72.0,
          "-",
          1080.0,
        ],
      },
      { 1329: ["1329", "EM", 778840000.0, 0.81, 0.0, 0.0, "-", "-", "-"] },
      {
        1330: [
          "1330",
          "EM",
          1520000000.0,
          3.76,
          0.1,
          2.73,
          7.55,
          6.23,
          588290.0,
        ],
      },
      {
        1332: ["1332", "EM", 3000000000.0, 1.07, 0.02, 1.9, "-", "-", 893100.0],
      },
      { 1333: ["1333", "EM", 9150000000.0, 1.68, 0.0, 0.0, 5.65, "-", "-"] },
      { 1335: ["1335", "EM", 340770000.0, 0.14, 0.0, 0.0, 22.58, "-", "-"] },
      {
        1336: [
          "1336",
          "EM",
          23680000000.0,
          22.9,
          0.75,
          3.39,
          4.24,
          7.29,
          89790000.0,
        ],
      },
      {
        1337: [
          "1337",
          "EM",
          20410000000.0,
          2.33,
          0.05,
          2.19,
          506.52,
          "-",
          11650000.0,
        ],
      },
      { 1338: ["1338", "EM", 268800000.0, 0.085, 0.0, 0.0, "-", "-", 16480.0] },
      {
        1339: [
          "1339",
          "EM",
          21720000000.0,
          2.49,
          0.03,
          1.22,
          4.65,
          6.52,
          39230000.0,
        ],
      },
      {
        1340: ["1340", "EM", 73100000.0, 0.083, 0.003, 3.75, "-", "-", 43590.0],
      },
      {
        1341: [
          "1341",
          "EM",
          2600000000.0,
          0.345,
          -0.01,
          -2.82,
          18.35,
          "-",
          112960.0,
        ],
      },
      {
        1343: [
          "1343",
          "EM",
          118100000.0,
          0.111,
          -0.002,
          -1.77,
          "-",
          "-",
          1130.0,
        ],
      },
      {
        1345: [
          "1345",
          "EM",
          2690000000.0,
          2.14,
          0.08,
          3.88,
          42.21,
          6.12,
          363840.0,
        ],
      },
      { 1346: ["1346", "EM", 249240000.0, 0.39, 0.0, 0.0, 260.0, "-", "-"] },
      {
        1347: [
          "1347",
          "EM",
          50220000000.0,
          38.6,
          1.1,
          2.93,
          64.86,
          "-",
          157230000.0,
        ],
      },
      {
        1348: ["1348", "EM", 390670000.0, 0.265, 0.01, 3.92, "-", "-", 2200.0],
      },
      {
        1349: [
          "1349",
          "EM",
          1310000000.0,
          3.87,
          0.02,
          0.52,
          19.73,
          1.52,
          255140.0,
        ],
      },
      {
        1351: [
          "1351",
          "EM",
          355000000.0,
          0.71,
          -0.03,
          -4.05,
          3.5,
          "-",
          203200.0,
        ],
      },
      {
        1355: [
          "1355",
          "EM",
          138990000.0,
          0.31,
          0.01,
          3.33,
          67.39,
          "-",
          43390.0,
        ],
      },
      {
        1357: [
          "1357",
          "EM",
          6880000000.0,
          1.56,
          0.04,
          2.63,
          "-",
          "-",
          1890000.0,
        ],
      },
      {
        1358: [
          "1358",
          "EM",
          1670000000.0,
          1.07,
          0.02,
          1.9,
          2.27,
          "-",
          719810.0,
        ],
      },
      {
        1359: [
          "1359",
          "EM",
          19670000000.0,
          1.45,
          0.03,
          2.11,
          3.86,
          8.62,
          30120000.0,
        ],
      },
      { 1360: ["1360", "EM", 282090000.0, 0.152, 0.0, 0.0, "-", "-", "-"] },
      {
        1361: [
          "1361",
          "EM",
          8740000000.0,
          4.23,
          -0.27,
          -6.0,
          17.85,
          "-",
          30340000.0,
        ],
      },
      {
        1362: ["1362", "EM", 106400000.0, 0.38, 0.0, 0.0, 9.82, "-", 844940.0],
      },
      { 1365: ["1365", "EM", 343960000.0, 0.3, 0.0, 0.0, "-", "-", "-"] },
      {
        1366: [
          "1366",
          "EM",
          1800000000.0,
          0.295,
          0.01,
          3.51,
          8.97,
          "-",
          242360.0,
        ],
      },
      { 1367: ["1367", "EM", 244800000.0, 0.51, 0.0, 0.0, "-", "-", "-"] },
      {
        1368: [
          "1368",
          "EM",
          38080000000.0,
          14.48,
          0.56,
          4.02,
          58.84,
          1.31,
          202480000.0,
        ],
      },
      { 1370: ["1370", "EM", 1730000000.0, 1.06, 0.0, 0.0, "-", "-", "-"] },
      { 1371: ["1371", "EM", 262510000.0, 0.085, 0.0, 0.0, "-", "-", 24500.0] },
      {
        1372: [
          "1372",
          "EM",
          1360000000.0,
          4.48,
          0.04,
          0.9,
          2.97,
          "-",
          305080.0,
        ],
      },
      {
        1373: [
          "1373",
          "EM",
          1900000000.0,
          2.64,
          -0.02,
          -0.75,
          7.41,
          11.33,
          2240000.0,
        ],
      },
      {
        1375: [
          "1375",
          "EM",
          1790000000.0,
          1.5,
          -0.01,
          -0.66,
          51.37,
          2.74,
          147590.0,
        ],
      },
      { 1376: ["1376", "EM", 107000000.0, 0.107, 0.0, 0.0, "-", "-", "-"] },
      {
        1378: [
          "1378",
          "EM",
          81810000000.0,
          8.97,
          0.27,
          3.1,
          6.19,
          10.59,
          73100000.0,
        ],
      },
      { 1379: ["1379", "EM", 99200000.0, 4.96, -0.13, -2.55, 9.0, "-", "-"] },
      {
        1380: ["1380", "EM", 65129999.99999999, 0.023, 0.0, 0.0, "-", "-", "-"],
      },
      {
        1381: [
          "1381",
          "EM",
          10190000000.0,
          4.18,
          0.19,
          4.76,
          9.64,
          2.37,
          9160000.0,
        ],
      },
      {
        1382: [
          "1382",
          "EM",
          5650000000.0,
          4.01,
          0.06,
          1.52,
          7.84,
          10.72,
          3960000.0,
        ],
      },
      {
        1383: [
          "1383",
          "EM",
          746810000.0,
          0.112,
          0.007,
          6.67,
          0.9,
          "-",
          1540000.0,
        ],
      },
      {
        1385: [
          "1385",
          "EM",
          6090000000.0,
          21.45,
          0.6,
          2.88,
          94.74,
          "-",
          8690000.0,
        ],
      },
      { 1386: ["1386", "EM", 363400000.0, 0.455, 0.0, 0.0, "-", "-", "-"] },
      {
        1387: [
          "1387",
          "EM",
          18410000000.0,
          2.07,
          0.16,
          8.38,
          60.0,
          "-",
          5050000.0,
        ],
      },
      {
        1388: [
          "1388",
          "EM",
          439310000.0,
          1.04,
          -0.02,
          -1.89,
          "-",
          4.33,
          36350.0,
        ],
      },
      { 1389: ["1389", "EM", 149670000.0, 0.045, 0.004, 9.76, "-", "-", "-"] },
      {
        1393: [
          "1393",
          "EM",
          940970000.0,
          0.46,
          -0.01,
          -2.13,
          "-",
          "-",
          91520.0,
        ],
      },
      {
        1395: [
          "1395",
          "EM",
          136190000.0,
          0.123,
          -0.004,
          -3.15,
          "-",
          4.07,
          1830.0,
        ],
      },
      { 1396: ["1396", "EM", 1790000000.0, 0.395, 0.0, 0.0, 4.0, "-", "-"] },
      {
        1397: [
          "1397",
          "EM",
          219950000.0,
          0.53,
          0.01,
          1.92,
          4.28,
          6.79,
          22920.0,
        ],
      },
      {
        1398: [
          "1398",
          "EM",
          414870000000.0,
          4.78,
          0.07,
          1.49,
          4.73,
          6.69,
          754390000.0,
        ],
      },
      {
        1399: [
          "1399",
          "EM",
          299750000.0,
          0.275,
          -0.005,
          -1.79,
          "-",
          "-",
          22800.0,
        ],
      },
      {
        1400: [
          "1400",
          "EM",
          12020000.0,
          0.023,
          -0.001,
          -4.17,
          "-",
          "-",
          57390.0,
        ],
      },
      { 1401: ["1401", "EM", 700000000.0, 0.7, 0.0, 0.0, 19.28, "-", "-"] },
      { 1402: ["1402", "EM", 519990000.0, 0.495, 0.0, 0.0, 38.67, 1.31, "-"] },
      {
        1408: [
          "1408",
          "EM",
          125000000.0,
          0.25,
          -0.01,
          -3.85,
          4.13,
          "-",
          6360.0,
        ],
      },
      {
        1410: [
          "1410",
          "EM",
          755130000.0,
          0.75,
          0.0,
          0.0,
          21.68,
          1.33,
          369700.0,
        ],
      },
      {
        1412: ["1412", "EM", 659680000.0, 1.24, 0.0, 0.0, 5.05, 11.29, 49800.0],
      },
      {
        1413: [
          "1413",
          "EM",
          330000000.0,
          0.33,
          0.005,
          1.54,
          8.11,
          "-",
          51870.0,
        ],
      },
      {
        1415: [
          "1415",
          "EM",
          7700000000.0,
          9.24,
          0.39,
          4.41,
          23.41,
          10.09,
          9170000.0,
        ],
      },
      { 1416: ["1416", "EM", 348600000.0, 0.249, 0.0, 0.0, 6.68, "-", "-"] },
      {
        1417: [
          "1417",
          "EM",
          631800000.0,
          1.56,
          0.0,
          0.0,
          20.16,
          0.77,
          107040.0,
        ],
      },
      { 1418: ["1418", "EM", 350000000.0, 0.2, 0.0, 0.0, 5.99, "-", "-"] },
      {
        1419: [
          "1419",
          "EM",
          459250000.0,
          1.21,
          0.03,
          2.54,
          3.18,
          9.92,
          51880.0,
        ],
      },
      {
        1420: [
          "1420",
          "EM",
          140950000.0,
          0.136,
          -0.012,
          -8.11,
          "-",
          "-",
          60730.0,
        ],
      },
      {
        1421: [
          "1421",
          "EM",
          115390000.0,
          0.083,
          0.003,
          3.75,
          "-",
          "-",
          85760.0,
        ],
      },
      {
        1425: ["1425", "EM", 537500000.0, 0.43, 0.0, 0.0, 5.09, 8.37, 412370.0],
      },
      {
        1426: [
          "1426",
          "R",
          4040000000.0,
          2.75,
          0.01,
          0.36,
          11.6,
          7.75,
          118600.0,
        ],
      },
      {
        1427: [
          "1427",
          "EM",
          2760000000.0,
          3.42,
          -0.01,
          -0.29,
          6.94,
          "-",
          137030.0,
        ],
      },
      {
        1428: [
          "1428",
          "EM",
          2460000000.0,
          1.45,
          0.02,
          1.4,
          3.5,
          8.97,
          1900000.0,
        ],
      },
      {
        1429: [
          "1429",
          "EM",
          156800000.0,
          0.098,
          -0.005,
          -4.85,
          2.27,
          "-",
          32350.0,
        ],
      },
      { 1430: ["1430", "EM", 2180000000.0, 2.42, 0.0, 0.0, 34.28, 1.07, "-"] },
      {
        1431: [
          "1431",
          "EM",
          1310000000.0,
          0.28,
          0.01,
          3.7,
          1.92,
          "-",
          466710.0,
        ],
      },
      {
        1432: [
          "1432",
          "EM",
          4190000000.0000005,
          0.5,
          0.0,
          0.0,
          7.91,
          "-",
          215830.0,
        ],
      },
      {
        1433: [
          "1433",
          "EM",
          110000000.0,
          0.055,
          0.0,
          0.0,
          61.11,
          1.45,
          51400.0,
        ],
      },
      { 1439: ["1439", "EM", 56470000.0, 0.041, 0.0, 0.0, "-", "-", 8960.0] },
      {
        1440: [
          "1440",
          "EM",
          718200000.0,
          0.57,
          0.0,
          0.0,
          10.46,
          "-",
          1020000.0,
        ],
      },
      {
        1442: [
          "1442",
          "EM",
          3750000000.0,
          1.82,
          -0.06,
          -3.19,
          76.79,
          "-",
          1190000.0,
        ],
      },
      { 1443: ["1443", "EM", 351000000.0, 0.27, 0.0, 0.0, "-", "-", "-"] },
      {
        1446: [
          "1446",
          "EM",
          265649999.99999997,
          0.405,
          -0.005,
          -1.22,
          4.25,
          4.84,
          "-",
        ],
      },
      {
        1447: ["1447", "EM", 340000000.0, 0.85, -0.01, -1.16, "-", 11.76, "-"],
      },
      {
        1448: [
          "1448",
          "EM",
          14590000000.0,
          6.29,
          0.19,
          3.11,
          19.55,
          1.78,
          9520000.0,
        ],
      },
      {
        1449: [
          "1449",
          "EM",
          273330000.0,
          0.41,
          -0.015,
          -3.53,
          4.35,
          "-",
          316110.0,
        ],
      },
      {
        1450: [
          "1450",
          "EM",
          1470000000.0,
          1.35,
          -0.01,
          -0.74,
          "-",
          "-",
          334160.0,
        ],
      },
      {
        1451: ["1451", "EM", 106000000.0, 0.53, 0.01, 1.92, 7.57, "-", 30000.0],
      },
      {
        1452: [
          "1452",
          "EM",
          92380000.0,
          0.187,
          0.042,
          28.97,
          "-",
          "-",
          188350.0,
        ],
      },
      {
        1455: [
          "1455",
          "EM",
          294060000.0,
          0.23,
          -0.007,
          -2.95,
          4.38,
          16.52,
          42130.0,
        ],
      },
      {
        1456: [
          "1456",
          "EM",
          1790000000.0,
          4.06,
          -0.1,
          -2.4,
          12.28,
          3.49,
          1980000.0,
        ],
      },
      {
        1458: [
          "1458",
          "EM",
          13600000000.0,
          5.71,
          0.26,
          4.77,
          74.25,
          1.4,
          44810000.0,
        ],
      },
      {
        1459: ["1459", "EM", 101350000.0, 0.76, 0.05, 7.04, 3.0, 5.26, 1520.0],
      },
      {
        1460: [
          "1460",
          "EM",
          258880000.0,
          0.295,
          -0.005,
          -1.67,
          24.58,
          "-",
          4800.0,
        ],
      },
      {
        1461: ["1461", "EM", 177330000.0, 0.64, 0.0, 0.0, 9.58, 4.47, 211990.0],
      },
      {
        1463: [
          "1463",
          "EM",
          2180000000.0,
          2.73,
          -0.27,
          -9.0,
          "-",
          "-",
          556800.0,
        ],
      },
      {
        1466: [
          "1466",
          "EM",
          44110000.0,
          0.138,
          0.007,
          5.34,
          "-",
          "-",
          64150.00000000001,
        ],
      },
      {
        1468: ["1468", "EM", 4160000000.0, 0.62, 0.0, 0.0, "-", "-", 2550000.0],
      },
      {
        1469: ["1469", "EM", 2120000000.0, 0.85, 0.0, 0.0, 9.96, 7.06, 13440.0],
      },
      { 1470: ["1470", "EM", 96000000.0, 0.12, 0.0, 0.0, "-", "-", "-"] },
      {
        1472: [
          "1472",
          "EM",
          132000000.0,
          0.132,
          0.012,
          10.0,
          5.0,
          "-",
          160130.0,
        ],
      },
      {
        1473: [
          "1473",
          "EM",
          275000000.0,
          0.275,
          0.015,
          5.77,
          4.26,
          4.73,
          59300.0,
        ],
      },
      {
        1475: [
          "1475",
          "EM",
          6200000000.0,
          5.92,
          0.2,
          3.5,
          21.06,
          2.37,
          12690000.0,
        ],
      },
      { 1476: ["1476", "EM", 1350000000.0, 3.0, 0.0, 0.0, 15.97, "-", "-"] },
      {
        1477: [
          "1477",
          "EM",
          7440000000.0,
          11.16,
          -0.24,
          -2.11,
          "-",
          "-",
          6600000.0,
        ],
      },
      {
        1478: [
          "1478",
          "EM",
          10340000000.0,
          8.75,
          0.34,
          4.04,
          10.36,
          0.57,
          11870000.0,
        ],
      },
      {
        1480: ["1480", "EM", 309600000.0, 1.29, 0.01, 0.78, 8.0, 4.65, 2560.0],
      },
      {
        1481: [
          "1481",
          "EM",
          820000000.0,
          0.82,
          0.0,
          0.0,
          51.25,
          0.61,
          64900.00000000001,
        ],
      },
      { 1483: ["1483", "EM", 1050000000.0, 1.33, 0.0, 0.0, 9.78, "-", "-"] },
      { 1486: ["1486", "EM", 285370000.0, 0.99, 0.0, 0.0, 30.09, "-", "-"] },
      { 1488: ["1488", "EM", 1340000000.0, 0.85, 0.0, 0.0, "-", "-", "-"] },
      {
        1490: [
          "1490",
          "EM",
          703720000.0,
          0.57,
          0.02,
          3.64,
          6.38,
          "-",
          656680.0,
        ],
      },
      {
        1495: ["1495", "EM", 824970000.0, 0.89, 0.0, 0.0, 55.63, "-", 720460.0],
      },
      {
        1496: [
          "1496",
          "EM",
          139960000.0,
          0.162,
          0.002,
          1.25,
          "-",
          "-",
          48820.0,
        ],
      },
      {
        1498: [
          "1498",
          "EM",
          327760000.0,
          0.83,
          0.03,
          3.75,
          9.71,
          "-",
          716020.0,
        ],
      },
      {
        1499: [
          "1499",
          "EM",
          1210000000.0,
          0.226,
          0.006,
          2.73,
          "-",
          "-",
          143960.0,
        ],
      },
      {
        1500: [
          "1500",
          "EM",
          240700000.0,
          0.29,
          -0.005,
          -1.69,
          8.38,
          10.34,
          165220.0,
        ],
      },
      {
        1501: [
          "1501",
          "EM",
          1650000000.0,
          15.86,
          -0.78,
          -4.69,
          18.37,
          2.15,
          1260000.0,
        ],
      },
      {
        1502: [
          "1502",
          "EM",
          338440000.0,
          3.27,
          0.08,
          2.51,
          8.5,
          5.67,
          260430.0,
        ],
      },
      {
        1503: [
          "1503",
          "R",
          3110000000.0,
          2.76,
          0.02,
          0.73,
          6.95,
          9.38,
          1860000.0,
        ],
      },
      {
        1508: [
          "1508",
          "EM",
          5200000000.0,
          0.78,
          0.02,
          2.63,
          4.94,
          6.31,
          9400000.0,
        ],
      },
      {
        1513: [
          "1513",
          "EM",
          8690000000.0,
          27.75,
          0.0,
          0.0,
          12.83,
          5.43,
          4770000.0,
        ],
      },
      {
        1515: [
          "1515",
          "EM",
          5770000000.0,
          4.45,
          0.07,
          1.6,
          15.35,
          1.98,
          8150000.0,
        ],
      },
      {
        1516: [
          "1516",
          "EM",
          27520000000.0,
          8.94,
          0.13,
          1.48,
          30.19,
          0.78,
          65050000.0,
        ],
      },
      {
        1518: [
          "1518",
          "EM",
          519419999.99999994,
          1.06,
          0.06,
          6.0,
          "-",
          "-",
          "-",
        ],
      },
      {
        1520: [
          "1520",
          "EM",
          440400000.0,
          0.246,
          0.004,
          1.65,
          "-",
          "-",
          737260.0,
        ],
      },
      {
        1521: [
          "1521",
          "EM",
          7900000000.0,
          3.81,
          0.06,
          1.6,
          57.9,
          "-",
          1570000.0,
        ],
      },
      {
        1522: [
          "1522",
          "EM",
          891280000.0,
          0.425,
          0.02,
          4.94,
          5.3,
          5.88,
          178800.0,
        ],
      },
      {
        1523: [
          "1523",
          "EM",
          4280000000.0000005,
          3.93,
          0.23,
          6.22,
          37.97,
          3.49,
          3890000.0,
        ],
      },
      {
        1525: [
          "1525",
          "EM",
          1760000000.0,
          4.26,
          -0.04,
          -0.93,
          7.66,
          4.23,
          97110.0,
        ],
      },
      {
        1526: [
          "1526",
          "EM",
          1200000000.0,
          0.76,
          -0.14,
          -15.56,
          "-",
          "-",
          134520.0,
        ],
      },
      {
        1527: [
          "1527",
          "EM",
          28700000.0,
          0.82,
          -0.08,
          -8.89,
          21.03,
          "-",
          3600.0,
        ],
      },
      {
        1528: [
          "1528",
          "EM",
          3130000000.0,
          4.23,
          -0.06,
          -1.4,
          8.15,
          "-",
          286510.0,
        ],
      },
      {
        1529: [
          "1529",
          "EM",
          309540000.0,
          0.35,
          0.01,
          2.94,
          94.59,
          "-",
          324750.0,
        ],
      },
      {
        1530: [
          "1530",
          "EM",
          14830000000.0,
          6.09,
          -0.06,
          -0.98,
          15.78,
          "-",
          18610000.0,
        ],
      },
      {
        1532: [
          "1532",
          "EM",
          193900000.0,
          0.18,
          -0.005,
          -2.7,
          "-",
          "-",
          80810.0,
        ],
      },
      {
        1533: [
          "1533",
          "EM",
          334780000.0,
          9.53,
          0.33,
          3.59,
          147.98,
          0.25,
          506890.0,
        ],
      },
      {
        1536: [
          "1536",
          "EM",
          58140000.0,
          0.153,
          -0.001,
          -0.65,
          "-",
          "-",
          6860.0,
        ],
      },
      {
        1538: ["1538", "EM", 504180000.0, 0.59, 0.01, 1.72, 3.2, 5.08, 82420.0],
      },
      {
        1539: ["1539", "EM", 1890000000.0, 0.8, 0.03, 3.9, "-", "-", 2230000.0],
      },
      { 1540: ["1540", "EM", 304180000.0, 0.61, 0.02, 3.39, 10.95, 8.2, "-"] },
      { 1542: ["1542", "EM", 159000000.0, 3.18, 0.0, 0.0, 5.24, 6.45, "-"] },
      {
        1543: [
          "1543",
          "EM",
          1110000000.0,
          2.02,
          -0.13,
          -6.05,
          25.19,
          3.62,
          124660.0,
        ],
      },
      {
        1545: [
          "1545",
          "EM",
          256000000.0,
          0.128,
          0.001,
          0.79,
          6.07,
          22.5,
          22240.0,
        ],
      },
      {
        1546: ["1546", "EM", 168000000.0, 0.21, -0.006, -2.78, 7.95, "-", "-"],
      },
      { 1547: ["1547", "EM", 236000000.0, 0.295, 0.0, 0.0, 4.34, 16.95, "-"] },
      {
        1548: [
          "1548",
          "EM",
          51590000000.0,
          24.55,
          -0.1,
          -0.41,
          "-",
          "-",
          98600000.0,
        ],
      },
      {
        1549: [
          "1549",
          "EM",
          269700000.0,
          0.174,
          -0.001,
          -0.57,
          9.3,
          "-",
          775080.0,
        ],
      },
      { 1551: ["1551", "EM", 6370000000.0, 3.0, 0.0, 0.0, 5.67, 8.09, "-"] },
      { 1552: ["1552", "EM", 136000000.0, 0.17, -0.003, -1.73, 8.5, "-", "-"] },
      { 1553: ["1553", "EM", 559600000.0, 1.29, 0.0, 0.0, 4.76, 9.3, "-"] },
      {
        1555: ["1555", "EM", 196160000.0, 0.06, 0.003, 5.26, "-", "-", 84300.0],
      },
      {
        1556: ["1556", "EM", 301500000.0, 0.201, 0.001, 0.5, 3.9, 7.46, 3000.0],
      },
      { 1557: ["1557", "EM", 196000000.0, 0.49, 0.0, 0.0, 181.48, "-", "-"] },
      {
        1558: [
          "1558",
          "EM",
          4530000000.0,
          6.93,
          -0.14,
          -1.98,
          6.16,
          "-",
          13780000.0,
        ],
      },
      {
        1559: [
          "1559",
          "EM",
          256600000.00000003,
          0.162,
          0.0,
          0.0,
          "-",
          "-",
          "-",
        ],
      },
      {
        1560: [
          "1560",
          "EM",
          259800000.0,
          0.405,
          0.01,
          2.53,
          0.85,
          "-",
          24300.0,
        ],
      },
      {
        1561: [
          "1561",
          "EM",
          1550000000.0,
          2.04,
          -0.03,
          -1.45,
          "-",
          "-",
          2850000.0,
        ],
      },
      {
        1563: [
          "1563",
          "EM",
          3930000000.0,
          2.62,
          0.09,
          3.56,
          201.54,
          "-",
          1450000.0,
        ],
      },
      { 1565: ["1565", "EM", 1420000000.0, 0.46, 0.0, 0.0, 5.38, 6.2, "-"] },
      {
        1566: [
          "1566",
          "EM",
          250370000.0,
          0.231,
          -0.019,
          -7.6,
          2.66,
          "-",
          7240000.0,
        ],
      },
      {
        1568: [
          "1568",
          "EM",
          884860000.0,
          0.41,
          0.115,
          38.98,
          2.18,
          19.51,
          26340000.0,
        ],
      },
      {
        1569: [
          "1569",
          "EM",
          3540000000.0,
          0.84,
          0.02,
          2.44,
          22.4,
          1.27,
          206540.0,
        ],
      },
      { 1570: ["1570", "EM", 786490000.0, 4.01, 0.0, 0.0, 28.64, "-", "-"] },
      {
        1571: ["1571", "EM", 2620000000.0, 2.62, 0.0, 0.0, 6.71, 7.02, 10480.0],
      },
      {
        1572: ["1572", "EM", 389290000.0, 0.232, 0.0, 0.0, 7.56, "-", 11790.0],
      },
      { 1575: ["1575", "EM", 97000000.0, 0.097, 0.0, 0.0, "-", "-", 505680.0] },
      {
        1576: [
          "1576",
          "EM",
          3250000000.0,
          2.96,
          -0.04,
          -1.33,
          8.12,
          7.32,
          263750.0,
        ],
      },
      {
        1577: [
          "1577",
          "EM",
          183600000.0,
          1.02,
          -0.02,
          -1.92,
          14.39,
          5.94,
          13240.0,
        ],
      },
      {
        1578: [
          "1578",
          "EM",
          3560000000.0,
          2.02,
          -0.02,
          -0.98,
          2.42,
          "-",
          410750.0,
        ],
      },
      {
        1579: [
          "1579",
          "EM",
          36740000000.0,
          35.1,
          1.95,
          5.88,
          32.94,
          0.86,
          144990000.0,
        ],
      },
      { 1580: ["1580", "EM", 111080000.0, 0.114, 0.0, 0.0, "-", "-", 1140.0] },
      {
        1581: ["1581", "EM", 134870000.0, 0.13, -0.002, -1.52, 36.11, "-", "-"],
      },
      {
        1582: ["1582", "EM", 265000000.0, 0.53, 0.0, 0.0, 2.97, 9.91, 32720.0],
      },
      {
        1583: [
          "1583",
          "EM",
          1450000000.0,
          1.93,
          -0.03,
          -1.53,
          62.66,
          "-",
          8109.999999999999,
        ],
      },
      {
        1585: [
          "1585",
          "EM",
          33780000000.0,
          11.28,
          0.22,
          1.99,
          28.71,
          1.68,
          24680000.0,
        ],
      },
      { 1586: ["1586", "EM", 727350000.0, 1.64, 0.0, 0.0, 12.24, 3.87, "-"] },
      {
        1587: [
          "1587",
          "EM",
          578000000.0,
          0.85,
          0.03,
          3.66,
          14.14,
          1.76,
          230200.0,
        ],
      },
      {
        1588: [
          "1588",
          "EM",
          668250000.0,
          8.1,
          0.25,
          3.18,
          66.56,
          0.79,
          649310.0,
        ],
      },
      {
        1589: [
          "1589",
          "EM",
          15210000000.0,
          4.38,
          -0.02,
          -0.45,
          534.15,
          "-",
          66940000.0,
        ],
      },
      { 1591: ["1591", "EM", 92000000.0, 0.023, 0.0, 0.0, "-", "-", 5060.0] },
      {
        1592: [
          "1592",
          "EM",
          511350000.0,
          0.41,
          -0.01,
          -2.38,
          "-",
          "-",
          183870.0,
        ],
      },
      {
        1593: [
          "1593",
          "EM",
          2180000000.0,
          2.18,
          0.03,
          1.4,
          "-",
          "-",
          3000000.0,
        ],
      },
      {
        1596: [
          "1596",
          "EM",
          1000000000.0,
          4.48,
          -0.02,
          -0.44,
          13.61,
          "-",
          510960.0,
        ],
      },
      {
        1597: ["1597", "EM", 490000000.0, 1.96, -0.07, -3.45, 8.3, "-", 1960.0],
      },
      {
        1598: [
          "1598",
          "EM",
          630290000.0,
          0.54,
          -0.01,
          -1.82,
          7.09,
          2.91,
          1010000.0,
        ],
      },
      {
        1599: [
          "1599",
          "EM",
          822420000.0,
          2.12,
          0.03,
          1.44,
          3.1,
          8.94,
          1390000.0,
        ],
      },
      {
        1600: [
          "1600",
          "EM",
          9420000000.0,
          9.39,
          0.16,
          1.73,
          7.7,
          3.72,
          16030000.000000002,
        ],
      },
      {
        1601: [
          "1601",
          "EM",
          370000000.0,
          0.75,
          -0.02,
          -2.6,
          5.17,
          7.01,
          3000.0,
        ],
      },
      {
        1606: [
          "1606",
          "EM",
          3210000000.0,
          1.16,
          0.02,
          1.75,
          3.83,
          8.03,
          1000000.0,
        ],
      },
      {
        1608: [
          "1608",
          "EM",
          3320000000.0,
          1.23,
          0.04,
          3.36,
          6.16,
          3.41,
          3030000.0,
        ],
      },
      { 1609: ["1609", "EM", 996800000.0, 0.91, 0.02, 2.25, "-", "-", 1820.0] },
      {
        1610: [
          "1610",
          "EM",
          12790000000.0,
          3.28,
          0.08,
          2.5,
          2.71,
          6.28,
          34870000.0,
        ],
      },
      {
        1611: [
          "1611",
          "EM",
          2270000000.0,
          7.4,
          0.1,
          1.37,
          16.08,
          "-",
          1240000.0,
        ],
      },
      {
        1612: [
          "1612",
          "EM",
          575610000.0,
          0.88,
          -0.02,
          -2.22,
          2.6,
          14.77,
          266840.0,
        ],
      },
      {
        1613: [
          "1613",
          "EM",
          256730000.00000003,
          0.205,
          0.002,
          0.99,
          "-",
          "-",
          113610.0,
        ],
      },
      {
        1615: ["1615", "EM", 162000000.0, 0.27, 0.015, 5.88, "-", "-", 6650.0],
      },
      { 1616: ["1616", "EM", 344250000.0, 0.16, 0.0, 0.0, "-", "-", "-"] },
      {
        1617: [
          "1617",
          "EM",
          199580000.0,
          0.135,
          0.001,
          0.75,
          "-",
          "-",
          395500.0,
        ],
      },
      {
        1618: [
          "1618",
          "EM",
          6110000000.0,
          2.13,
          0.05,
          2.4,
          5.66,
          4.23,
          10420000.0,
        ],
      },
      {
        1620: [
          "1620",
          "EM",
          223200000.0,
          0.186,
          0.003,
          1.64,
          "-",
          "-",
          11130.0,
        ],
      },
      {
        1621: [
          "1621",
          "EM",
          127000000.0,
          0.127,
          -0.002,
          -1.55,
          6.94,
          7.87,
          10160.0,
        ],
      },
      {
        1622: [
          "1622",
          "EM",
          9580000000.0,
          2.7,
          0.02,
          0.75,
          6.3,
          2.18,
          10800000.0,
        ],
      },
      {
        1623: [
          "1623",
          "EM",
          636160000.0,
          0.375,
          0.02,
          5.63,
          "-",
          "-",
          1950000.0,
        ],
      },
      { 1626: ["1626", "EM", 570000000.0, 1.9, 0.0, 0.0, "-", "-", "-"] },
      { 1627: ["1627", "EM", 810000000.0, 0.405, 0.0, 0.0, 8.54, 6.17, "-"] },
      {
        1628: [
          "1628",
          "EM",
          4120000000.0,
          0.63,
          0.0,
          0.0,
          572.73,
          42.54,
          2560000.0,
        ],
      },
      { 1629: ["1629", "EM", 218430000.0, 0.4, 0.01, 2.56, "-", "-", 3160.0] },
      {
        1630: [
          "1630",
          "EM",
          151500000.0,
          0.101,
          0.007,
          7.45,
          56.11,
          "-",
          3630.0,
        ],
      },
      {
        1631: [
          "1631",
          "EM",
          189440000.0,
          0.74,
          0.01,
          1.37,
          10.36,
          40.54,
          7350.0,
        ],
      },
      {
        1632: [
          "1632",
          "EM",
          480990000.0,
          0.56,
          -0.02,
          -3.45,
          18.73,
          "-",
          8420.0,
        ],
      },
      {
        1633: ["1633", "EM", 106130000.0, 0.155, -0.015, -8.82, "-", "-", "-"],
      },
      {
        1635: ["1635", "EM", 907190000.0, 1.7, 0.02, 1.19, 8.32, 3.92, 25080.0],
      },
      { 1636: ["1636", "EM", 622080000.0, 0.18, 0.0, 0.0, "-", "-", "-"] },
      { 1637: ["1637", "EM", 204000000.0, 0.51, 0.0, 0.0, 4.62, 7.65, 9180.0] },
      {
        1638: [
          "1638",
          "EM",
          6170000000.0,
          0.88,
          0.0,
          0.0,
          0.86,
          17.78,
          3410000.0,
        ],
      },
      {
        1639: [
          "1639",
          "EM",
          2670000000.0,
          1.74,
          -0.01,
          -0.57,
          22.34,
          0.83,
          1650000.0,
        ],
      },
      { 1640: ["1640", "EM", 160000000.0, 0.4, 0.0, 0.0, 48.19, "-", "-"] },
      { 1643: ["1643", "EM", 498000000.0, 0.83, 0.0, 0.0, 5.0, "-", 630480.0] },
      {
        1645: [
          "1645",
          "EM",
          490650000.0,
          0.87,
          0.02,
          2.35,
          7.73,
          5.75,
          9440000.0,
        ],
      },
      {
        1647: [
          "1647",
          "EM",
          192720000.0,
          0.176,
          -0.006,
          -3.3,
          6.03,
          "-",
          7080.0,
        ],
      },
      { 1650: ["1650", "EM", 156000000.0, 0.078, 0.0, 0.0, 2.36, 11.03, "-"] },
      {
        1651: [
          "1651",
          "EM",
          3820000000.0,
          10.04,
          0.04,
          0.4,
          8.2,
          6.47,
          321170.0,
        ],
      },
      {
        1652: ["1652", "EM", 923920000.0, 1.2, 0.04, 3.45, 11.49, 1.02, 6000.0],
      },
      { 1653: ["1653", "EM", 76800000.0, 0.32, 0.0, 0.0, 7.39, "-", 64000.0] },
      { 1655: ["1655", "EM", 240000000.0, 0.48, 0.03, 6.67, "-", "-", 2400.0] },
      { 1656: ["1656", "EM", 1660000000.0, 3.8, 0.0, 0.0, 18.76, 1.23, "-"] },
      {
        1657: ["1657", "EM", 152000000.0, 4.75, 0.25, 5.56, "-", "-", 11500.0],
      },
      {
        1658: [
          "1658",
          "EM",
          129460000000.00002,
          6.52,
          0.06,
          0.93,
          7.81,
          3.84,
          285800000.0,
        ],
      },
      {
        1660: [
          "1660",
          "EM",
          2660000000.0,
          0.43,
          -0.025,
          -5.49,
          30.28,
          "-",
          1540000.0,
        ],
      },
      {
        1661: [
          "1661",
          "EM",
          223010000.0,
          0.14,
          -0.009,
          -6.04,
          "-",
          "-",
          51660.0,
        ],
      },
      {
        1662: [
          "1662",
          "EM",
          545000000.0,
          1.09,
          -0.02,
          -1.8,
          11.05,
          "-",
          354920.0,
        ],
      },
      {
        1663: ["1663", "EM", 406560000.0, 0.165, 0.0, 0.0, 5.96, 6.06, 33240.0],
      },
      {
        1665: [
          "1665",
          "EM",
          2640000000.0,
          1.1,
          0.0,
          0.0,
          12.24,
          1.21,
          326020.0,
        ],
      },
      {
        1666: [
          "1666",
          "EM",
          4630000000.0,
          7.37,
          0.08,
          1.1,
          17.19,
          2.64,
          6430000.0,
        ],
      },
      { 1667: ["1667", "EM", 288000000.0, 0.3, 0.0, 0.0, "-", "-", "-"] },
      {
        1668: [
          "1668",
          "EM",
          5250000000.0,
          0.65,
          0.03,
          4.84,
          2.18,
          4.62,
          3480000.0,
        ],
      },
      {
        1669: [
          "1669",
          "EM",
          212000000.0,
          0.53,
          -0.04,
          -7.02,
          32.32,
          18.11,
          24440.0,
        ],
      },
      { 1671: ["1671", "EM", 131130000.0, 0.82, 0.0, 0.0, 6.38, 7.39, "-"] },
      {
        1672: [
          "1672",
          "EM",
          4240000000.0,
          3.91,
          -0.05,
          -1.26,
          "-",
          "-",
          11430000.0,
        ],
      },
      {
        1673: [
          "1673",
          "EM",
          620710000.0,
          0.7,
          -0.02,
          -2.78,
          23.97,
          "-",
          96000.0,
        ],
      },
      {
        1675: [
          "1675",
          "EM",
          13170000000.0,
          14.24,
          -0.12,
          -0.84,
          14.21,
          2.42,
          4410000.0,
        ],
      },
      {
        1676: ["1676", "EM", 105840000.0, 0.98, -0.02, -2.0, "-", "-", 13360.0],
      },
      { 1678: ["1678", "EM", 86800000.0, 0.04, 0.0, 0.0, "-", "-", "-"] },
      { 1679: ["1679", "EM", 332090000.0, 0.187, 0.0, 0.0, "-", "-", "-"] },
      {
        1680: [
          "1680",
          "EM",
          2940000000.0,
          0.475,
          0.015,
          3.26,
          "-",
          "-",
          1090000.0,
        ],
      },
      {
        1681: [
          "1681",
          "EM",
          3160000000.0,
          3.89,
          0.08,
          2.1,
          5.4,
          7.71,
          1030000.0,
        ],
      },
      { 1682: ["1682", "EM", 104520000.0, 0.133, 0.0, 0.0, "-", "-", "-"] },
      {
        1683: [
          "1683",
          "EM",
          144000000.0,
          0.2,
          -0.003,
          -1.48,
          "-",
          "-",
          16720.0,
        ],
      },
      {
        1685: [
          "1685",
          "EM",
          297900000.0,
          0.385,
          0.015,
          4.05,
          15.46,
          "-",
          58070.0,
        ],
      },
      {
        1686: [
          "1686",
          "EM",
          16290000000.0,
          6.97,
          0.12,
          1.75,
          35.87,
          2.78,
          3910000.0,
        ],
      },
      {
        1689: [
          "1689",
          "EM",
          1460000000.0,
          2.09,
          0.07,
          3.47,
          15.55,
          3.83,
          1270000.0,
        ],
      },
      { 1690: ["1690", "EM", 94860000.0, 0.062, 0.0, 0.0, "-", "-", "-"] },
      {
        1691: [
          "1691",
          "EM",
          43120000000.0,
          12.34,
          -0.06,
          -0.48,
          15.29,
          2.16,
          36190000.0,
        ],
      },
      {
        1692: [
          "1692",
          "EM",
          728770000.0,
          2.03,
          0.03,
          1.5,
          7.37,
          11.92,
          19980.0,
        ],
      },
      { 1693: ["1693", "EM", 61200000.0, 0.034, 0.0, 0.0, "-", "-", "-"] },
      { 1695: ["1695", "EM", 76680000.0, 0.071, 0.0, 0.0, "-", "-", 16390.0] },
      {
        1696: [
          "1696",
          "EM",
          3880000000.0,
          8.34,
          0.52,
          6.65,
          35.7,
          0.84,
          3700000.0,
        ],
      },
      {
        1697: [
          "1697",
          "EM",
          495010000.0,
          0.425,
          0.005,
          1.19,
          2.69,
          "-",
          52840.0,
        ],
      },
      { 1699: ["1699", "EM", 126560000.0, 0.067, 0.0, 0.0, 2.0, "-", 31990.0] },
      {
        1701: ["1701", "EM", 142000000.0, 0.142, 0.002, 1.43, "-", "-", 8470.0],
      },
      {
        1702: [
          "1702",
          "EM",
          1330000000.0,
          2.15,
          -0.2,
          -8.51,
          9.07,
          2.33,
          223600.0,
        ],
      },
      {
        1703: [
          "1703",
          "EM",
          966000000.0,
          0.84,
          -0.01,
          -1.18,
          "-",
          "-",
          92700.0,
        ],
      },
      { 1705: ["1705", "EM", 158000000.0, 0.395, 0.0, 0.0, 263.33, "-", "-"] },
      {
        1706: ["1706", "EM", 255000000.0, 0.255, 0.0, 0.0, 75.0, "-", 19980.0],
      },
      { 1707: ["1707", "EM", 554400000.0, 0.33, 0.0, 0.0, "-", "-", 5280.0] },
      {
        1708: [
          "1708",
          "EM",
          1440000000.0,
          6.29,
          0.01,
          0.16,
          "-",
          "-",
          62010000.0,
        ],
      },
      {
        1709: [
          "1709",
          "EM",
          3660000000.0,
          2.56,
          -0.04,
          -1.54,
          17.0,
          1.8,
          1620000.0,
        ],
      },
      { 1710: ["1710", "EM", 174000000.0, 0.174, 0.0, 0.0, 6.02, 6.9, "-"] },
      { 1711: ["1711", "EM", 200000000.0, 0.25, 0.0, 0.0, 11.9, 2.52, "-"] },
      { 1712: ["1712", "EM", 246910000.0, 1.56, 0.0, 0.0, 3.56, "-", 1560.0] },
      {
        1713: [
          "1713",
          "EM",
          483840000.0,
          1.8,
          -0.01,
          -0.55,
          6.09,
          7.96,
          221200.0,
        ],
      },
      {
        1715: ["1715", "EM", 216000000.0, 0.144, 0.001, 0.7, "-", "-", 7200.0],
      },
      {
        1716: [
          "1716",
          "EM",
          121500000.0,
          0.45,
          -0.01,
          -2.17,
          13.01,
          7.56,
          81740.0,
        ],
      },
      {
        1717: [
          "1717",
          "EM",
          17900000000.0,
          9.9,
          0.02,
          0.2,
          13.75,
          2.73,
          307580000.0,
        ],
      },
      {
        1718: [
          "1718",
          "EM",
          162240000.0,
          0.169,
          0.036,
          27.07,
          "-",
          "-",
          30720.0,
        ],
      },
      {
        1719: [
          "1719",
          "EM",
          1960000000.0,
          1.14,
          -0.01,
          -0.87,
          76.0,
          "-",
          177960.0,
        ],
      },
      { 1720: ["1720", "EM", 335500000.0, 0.305, 0.0, 0.0, 8.87, "-", "-"] },
      {
        1721: [
          "1721",
          "EM",
          620000000.0,
          0.62,
          -0.03,
          -4.62,
          "-",
          "-",
          63100.0,
        ],
      },
      {
        1722: [
          "1722",
          "EM",
          90000000.0,
          0.09,
          -0.006,
          -6.25,
          7.2,
          "-",
          17040.0,
        ],
      },
      {
        1723: [
          "1723",
          "EM",
          1710000000.0,
          4.28,
          0.18,
          4.39,
          159.11,
          "-",
          211340.0,
        ],
      },
      {
        1725: [
          "1725",
          "EM",
          5870000000.0,
          19.02,
          -0.9,
          -4.52,
          279.3,
          "-",
          7730000.0,
        ],
      },
      {
        1726: [
          "1726",
          "EM",
          902400000.0,
          0.94,
          -0.04,
          -4.08,
          552.94,
          "-",
          324450.0,
        ],
      },
      {
        1727: [
          "1727",
          "EM",
          655160000.0,
          1.42,
          0.02,
          1.43,
          2.79,
          11.96,
          347660.0,
        ],
      },
      { 1728: ["1728", "EM", 1750000000.0, 0.64, 0.0, 0.0, "-", "-", "-"] },
      { 1729: ["1729", "EM", 1790000000.0, 0.97, 0.0, 0.0, 7.89, 2.58, "-"] },
      {
        1730: [
          "1730",
          "EM",
          752450000.0,
          1.84,
          0.07,
          3.95,
          4.7,
          5.48,
          182960.0,
        ],
      },
      {
        1731: [
          "1731",
          "EM",
          1150000000.0,
          1.03,
          0.01,
          0.98,
          49.28,
          6.8,
          189400.0,
        ],
      },
      { 1732: ["1732", "EM", 163000000.0, 0.163, 0.0, 0.0, 9.64, "-", 6580.0] },
      {
        1733: [
          "1733",
          "EM",
          3780000000.0,
          1.25,
          0.04,
          3.31,
          8.22,
          5.12,
          4430000.0,
        ],
      },
      {
        1735: [
          "1735",
          "EM",
          12650000000.0,
          11.98,
          0.86,
          7.73,
          "-",
          "-",
          22670000.0,
        ],
      },
      {
        1736: ["1736", "EM", 80000000.0, 0.078, 0.006, 8.33, "-", "-", 14700.0],
      },
      { 1737: ["1737", "EM", 200000000.0, 0.2, 0.0, 0.0, 4.94, 15.0, 12000.0] },
      {
        1738: ["1738", "EM", 1560000000.0, 1.13, 0.03, 2.73, "-", "-", 5750.0],
      },
      {
        1739: [
          "1739",
          "EM",
          680890000.0,
          0.59,
          0.02,
          3.51,
          14.53,
          "-",
          160060.0,
        ],
      },
      {
        1740: [
          "1740",
          "EM",
          215800000.0,
          0.208,
          0.004,
          1.96,
          148.57,
          "-",
          14280.0,
        ],
      },
      {
        1741: [
          "1741",
          "EM",
          1580000000.0,
          1.98,
          0.1,
          5.32,
          "-",
          "-",
          2410000.0,
        ],
      },
      { 1742: ["1742", "EM", 160000000.0, 0.1, 0.0, 0.0, "-", "-", "-"] },
      {
        1745: [
          "1745",
          "EM",
          819640000.0,
          0.56,
          0.01,
          1.82,
          "-",
          "-",
          129139.99999999999,
        ],
      },
      { 1746: ["1746", "EM", 490000000.0, 0.49, 0.0, 0.0, "-", "-", "-"] },
      {
        1747: [
          "1747",
          "EM",
          391270000.0,
          0.78,
          0.06,
          8.33,
          13.64,
          3.68,
          222020.0,
        ],
      },
      {
        1748: ["1748", "EM", 1080000000.0, 2.47, -0.01, -0.4, 15.72, "-", "-"],
      },
      { 1749: ["1749", "EM", 21040000.0, 0.63, 0.0, 0.0, "-", "-", "-"] },
      {
        1750: [
          "1750",
          "EM",
          77400000.0,
          0.043,
          -0.002,
          -4.44,
          "-",
          "-",
          63020.0,
        ],
      },
      { 1751: ["1751", "EM", 185470000.0, 0.23, 0.0, 0.0, "-", "-", "-"] },
      {
        1752: [
          "1752",
          "EM",
          352830000.0,
          0.145,
          -0.005,
          -3.33,
          362.5,
          "-",
          "-",
        ],
      },
      {
        1753: ["1753", "EM", 1350000000.0, 1.26, 0.01, 0.8, "-", "-", 381070.0],
      },
      {
        1755: [
          "1755",
          "EM",
          12960000000.0,
          14.9,
          1.0,
          7.19,
          22.81,
          2.21,
          7540000.0,
        ],
      },
      {
        1756: [
          "1756",
          "EM",
          1300000000.0,
          1.09,
          0.04,
          3.81,
          7.55,
          10.3,
          94830.0,
        ],
      },
      { 1757: ["1757", "EM", 295200000.0, 0.246, 0.0, 0.0, 492.0, "-", "-"] },
      { 1758: ["1758", "EM", 382160000.0, 0.465, 0.0, 0.0, 37.8, "-", "-"] },
      {
        1759: [
          "1759",
          "EM",
          263519999.99999997,
          1.22,
          0.05,
          4.27,
          17.81,
          "-",
          31400.0,
        ],
      },
      {
        1760: [
          "1760",
          "EM",
          4770000000.0,
          4.4,
          0.07,
          1.62,
          40.93,
          0.73,
          3260000.0,
        ],
      },
      { 1761: ["1761", "EM", 1190000000.0, 0.72, 0.0, 0.0, "-", "-", 79480.0] },
      {
        1762: [
          "1762",
          "EM",
          713110000.0,
          0.48,
          0.01,
          2.13,
          7.74,
          "-",
          335640.0,
        ],
      },
      {
        1763: [
          "1763",
          "EM",
          1930000000.0,
          24.15,
          0.0,
          0.0,
          30.89,
          0.9,
          729920.0,
        ],
      },
      {
        1765: [
          "1765",
          "EM",
          8020000000.0,
          1.0,
          0.0,
          0.0,
          10.41,
          4.0,
          58530000.0,
        ],
      },
      {
        1766: [
          "1766",
          "EM",
          15770000000.0,
          3.61,
          0.05,
          1.4,
          7.81,
          5.84,
          59510000.0,
        ],
      },
      { 1767: ["1767", "EM", 260000000.0, 0.26, 0.0, 0.0, 6.7, "-", "-"] },
      {
        1769: [
          "1769",
          "EM",
          572370000.0,
          1.03,
          -0.01,
          -0.96,
          9.99,
          35.92,
          2590000.0,
        ],
      },
      {
        1771: [
          "1771",
          "EM",
          1180000000.0,
          1.98,
          0.01,
          0.51,
          6.99,
          6.57,
          168330.0,
        ],
      },
      {
        1772: [
          "1772",
          "EM",
          36490000000.0,
          126.6,
          4.2,
          3.43,
          137.58,
          0.29,
          288650000.0,
        ],
      },
      { 1773: ["1773", "EM", 4130000000.0, 1.91, 0.0, 0.0, 8.71, 5.67, "-"] },
      {
        1775: [
          "1775",
          "EM",
          375000000.0,
          0.75,
          0.09,
          13.64,
          "-",
          "-",
          188000.0,
        ],
      },
      {
        1776: [
          "1776",
          "EM",
          23070000000.0,
          13.56,
          0.08,
          0.59,
          8.76,
          4.0,
          40500000.0,
        ],
      },
      {
        1777: [
          "1777",
          "EM",
          1840000000.0,
          0.32,
          0.005,
          1.59,
          1.6,
          22.03,
          1440000.0,
        ],
      },
      {
        1778: [
          "1778",
          "EM",
          1350000000.0,
          0.91,
          -0.05,
          -5.21,
          2.21,
          11.41,
          648410.0,
        ],
      },
      { 1780: ["1780", "EM", 1610000000.0, 2.6, 0.0, 0.0, "-", 2.48, "-"] },
      { 1781: ["1781", "EM", 98820000.0, 0.183, 0.0, 0.0, "-", "-", "-"] },
      {
        1782: ["1782", "EM", 355600000.0, 0.7, 0.0, 0.0, 269.23, "-", 464750.0],
      },
      { 1783: ["1783", "EM", 192800000.0, 0.241, 0.0, 0.0, "-", "-", 82400.0] },
      {
        1785: [
          "1785",
          "EM",
          1000000000.0,
          2.2,
          -0.02,
          -0.9,
          8.98,
          6.69,
          24740.0,
        ],
      },
      {
        1786: [
          "1786",
          "EM",
          457430000.0,
          0.86,
          0.01,
          1.18,
          50.0,
          "-",
          289390.0,
        ],
      },
      {
        1787: [
          "1787",
          "EM",
          11590000000.0,
          13.5,
          0.42,
          3.21,
          22.39,
          0.45,
          30730000.0,
        ],
      },
      {
        1788: [
          "1788",
          "EM",
          10480000000.0,
          1.09,
          0.04,
          3.81,
          6.47,
          9.27,
          3860000.0,
        ],
      },
      {
        1789: [
          "1789",
          "EM",
          6390000000.0,
          5.73,
          0.27,
          4.95,
          17.03,
          0.7,
          18460000.0,
        ],
      },
      { 1790: ["1790", "EM", 580000000.0, 0.58, 0.0, 0.0, 4.58, "-", "-"] },
      {
        1792: ["1792", "EM", 139060000.0, 0.077, 0.001, 1.32, "-", "-", 9220.0],
      },
      { 1793: ["1793", "EM", 156000000.0, 0.195, 0.0, 0.0, 8.09, 6.15, "-"] },
      {
        1795: [
          "1795",
          "EM",
          528000000.0,
          0.88,
          0.14,
          18.92,
          9.79,
          3.41,
          263580.0,
        ],
      },
      {
        1796: ["1796", "EM", 648000000.0, 1.35, 0.02, 1.5, "-", "-", 199300.0],
      },
      {
        1797: [
          "1797",
          "EM",
          4380000000.0,
          4.38,
          0.2,
          4.78,
          "-",
          "-",
          10510000.0,
        ],
      },
      {
        1798: [
          "1798",
          "EM",
          8050000000.000001,
          3.22,
          0.07,
          2.22,
          21.57,
          1.12,
          58120000.0,
        ],
      },
      {
        1799: [
          "1799",
          "EM",
          5630000000.0,
          14.98,
          0.66,
          4.61,
          22.08,
          0.81,
          34460000.0,
        ],
      },
      {
        1800: [
          "1800",
          "EM",
          20280000000.0,
          4.59,
          0.07,
          1.55,
          4.26,
          4.67,
          45620000.0,
        ],
      },
      {
        1801: [
          "1801",
          "EM",
          45690000000.0,
          31.25,
          -1.45,
          -4.43,
          "-",
          "-",
          241180000.0,
        ],
      },
      {
        1802: [
          "1802",
          "EM",
          611820000.0,
          1.03,
          0.05,
          5.1,
          24.58,
          "-",
          9210000.0,
        ],
      },
      { 1803: ["1803", "EM", 301310000.0, 0.214, 0.014, 7.0, "-", "-", "-"] },
      { 1808: ["1808", "EM", 68030000.0, 0.05, 0.0, 0.0, "-", "-", 10000.0] },
      {
        1809: [
          "1809",
          "EM",
          4110000000.0000005,
          6.47,
          -0.21,
          -3.14,
          5.8,
          3.09,
          1240000.0,
        ],
      },
      {
        1810: [
          "1810",
          "EM",
          414530000000.0,
          16.58,
          0.18,
          1.1,
          16.57,
          "-",
          960700000.0,
        ],
      },
      {
        1811: [
          "1811",
          "EM",
          25740000000.0,
          6.0,
          0.13,
          2.21,
          20.51,
          2.07,
          34880000.0,
        ],
      },
      {
        1812: [
          "1812",
          "EM",
          1910000000.0,
          3.63,
          0.07,
          1.97,
          8.56,
          6.17,
          9920000.0,
        ],
      },
      {
        1813: [
          "1813",
          "EM",
          13460000000.0,
          4.23,
          -0.01,
          -0.24,
          1.71,
          25.79,
          37400000.0,
        ],
      },
      {
        1815: [
          "1815",
          "EM",
          1790000000.0,
          1.45,
          0.04,
          2.84,
          "-",
          "-",
          387820.0,
        ],
      },
      {
        1816: [
          "1816",
          "EM",
          24780000000.0,
          2.22,
          0.07,
          3.26,
          9.92,
          4.35,
          50540000.0,
        ],
      },
      {
        1817: [
          "1817",
          "EM",
          4970000000.0,
          5.24,
          -0.12,
          -2.24,
          "-",
          "-",
          2450000.0,
        ],
      },
      {
        1818: [
          "1818",
          "EM",
          16620000000.000002,
          6.37,
          0.14,
          2.25,
          16.79,
          0.95,
          21650000.0,
        ],
      },
      {
        1820: [
          "1820",
          "EM",
          1870000000.0,
          6.23,
          -0.01,
          -0.16,
          20.41,
          3.85,
          154790.0,
        ],
      },
      {
        1821: [
          "1821",
          "EM",
          119150000000.0,
          26.65,
          0.4,
          1.52,
          36.63,
          "-",
          103870000.0,
        ],
      },
      { 1822: ["1822", "EM", 22260000.0, 0.065, 0.0, 0.0, "-", "-", "-"] },
      {
        1823: [
          "1823",
          "EM",
          1570000000.0,
          3.82,
          0.37,
          10.72,
          22.42,
          1.57,
          1110000.0,
        ],
      },
      {
        1825: [
          "1825",
          "EM",
          34800000.0,
          0.174,
          -0.01,
          -5.43,
          "-",
          "-",
          17400.0,
        ],
      },
      { 1826: ["1826", "EM", 186480000.0, 0.14, 0.0, 0.0, "-", "-", "-"] },
      { 1827: ["1827", "EM", 624000000.0, 1.56, -0.02, -1.27, "-", "-", "-"] },
      {
        1830: [
          "1830",
          "EM",
          6120000000.0,
          4.94,
          0.07,
          1.44,
          20.29,
          8.64,
          4820000.0,
        ],
      },
      { 1831: ["1831", "EM", 170760000.0, 0.181, 0.0, 0.0, "-", "-", "-"] },
      { 1832: ["1832", "EM", 309600000.0, 0.86, 0.0, 0.0, "-", "-", "-"] },
      {
        1833: [
          "1833",
          "EM",
          28960000000.0,
          25.65,
          0.95,
          3.85,
          "-",
          "-",
          132509999.99999999,
        ],
      },
      { 1835: ["1835", "EM", 171760000.0, 4.48, 0.0, 0.0, 132.54, "-", "-"] },
      {
        1836: [
          "1836",
          "EM",
          6890000000.0,
          8.69,
          0.04,
          0.46,
          529.88,
          2.42,
          724060.0,
        ],
      },
      {
        1837: [
          "1837",
          "EM",
          1080000000.0,
          0.495,
          -0.005,
          -1.0,
          "-",
          4.04,
          231660.0,
        ],
      },
      { 1838: ["1838", "EM", 750760000.0, 0.415, 0.0, 0.0, "-", "-", "-"] },
      {
        1839: [
          "1839",
          "EM",
          3360000000.0,
          5.97,
          -0.02,
          -0.33,
          7.9,
          5.98,
          23830.0,
        ],
      },
      { 1841: ["1841", "EM", 172000000.0, 0.43, 0.0, 0.0, 6.23, 34.88, "-"] },
      {
        1842: [
          "1842",
          "EM",
          370000000.0,
          0.37,
          -0.01,
          -2.63,
          "-",
          "-",
          1110000.0,
        ],
      },
      {
        1843: [
          "1843",
          "EM",
          200000000.0,
          0.25,
          -0.005,
          -1.96,
          33.78,
          "-",
          58320.0,
        ],
      },
      {
        1845: [
          "1845",
          "EM",
          493330000.0,
          0.37,
          0.05,
          15.63,
          "-",
          "-",
          120120.0,
        ],
      },
      {
        1846: [
          "1846",
          "EM",
          2700000000.0,
          8.21,
          0.3,
          3.79,
          41.17,
          0.36,
          592330.0,
        ],
      },
      {
        1847: [
          "1847",
          "EM",
          178060000.0,
          1.33,
          0.03,
          2.31,
          2.45,
          11.17,
          1360.0,
        ],
      },
      {
        1848: [
          "1848",
          "EM",
          4019999999.9999995,
          5.42,
          0.03,
          0.56,
          11.25,
          6.46,
          302720.0,
        ],
      },
      {
        1849: [
          "1849",
          "EM",
          388000000.0,
          0.485,
          0.01,
          2.11,
          8.83,
          "-",
          110720.0,
        ],
      },
      {
        1850: [
          "1850",
          "EM",
          180000000.0,
          0.225,
          0.02,
          9.76,
          36.89,
          "-",
          1150000.0,
        ],
      },
      { 1851: ["1851", "EM", 530000000.0, 1.06, 0.0, 0.0, 26.63, "-", "-"] },
      { 1853: ["1853", "EM", 250900000.0, 2.15, 0.0, 0.0, 5.23, 5.78, "-"] },
      {
        1854: ["1854", "EM", 551880000.0, 0.365, 0.02, 5.8, "-", "-", 250000.0],
      },
      {
        1855: [
          "1855",
          "EM",
          720500000.0,
          2.62,
          0.09,
          3.56,
          7.17,
          "-",
          646640.0,
        ],
      },
      {
        1856: [
          "1856",
          "EM",
          903330000.0,
          2.6,
          0.11,
          4.42,
          684.21,
          "-",
          474390.0,
        ],
      },
      {
        1857: [
          "1857",
          "EM",
          5230000000.0,
          1.83,
          0.03,
          1.67,
          5.11,
          6.46,
          1160000.0,
        ],
      },
      {
        1858: [
          "1858",
          "EM",
          1130000000.0,
          11.82,
          0.02,
          0.17,
          12.28,
          "-",
          3160000.0,
        ],
      },
      {
        1859: ["1859", "EM", 944000000.0, 0.59, 0.0, 0.0, "-", "-", 4710000.0],
      },
      {
        1860: [
          "1860",
          "EM",
          10690000000.0,
          6.46,
          0.22,
          3.53,
          "-",
          "-",
          5380000.0,
        ],
      },
      {
        1861: ["1861", "EM", 498240000.0, 2.13, 0.06, 2.9, 9.36, 1.32, 77220.0],
      },
      {
        1862: [
          "1862",
          "EM",
          3120000000.0,
          2.03,
          -0.02,
          -0.98,
          2.52,
          12.32,
          1530000.0,
        ],
      },
      { 1863: ["1863", "EM", 1160000000.0, 1.37, 0.0, 0.0, 22.17, "-", "-"] },
      {
        1865: ["1865", "EM", 929200000.0, 1.01, 0.0, 0.0, 50.5, "-", 3590000.0],
      },
      {
        1866: [
          "1866",
          "EM",
          6580000000.0,
          5.62,
          0.16,
          2.93,
          16.04,
          2.11,
          4730000.0,
        ],
      },
      {
        1867: ["1867", "EM", 416640000.0, 0.31, 0.0, 0.0, 344.44, "-", 34000.0],
      },
      {
        1868: [
          "1868",
          "EM",
          1010000000.0,
          0.485,
          0.005,
          1.04,
          "-",
          "-",
          1980.0,
        ],
      },
      { 1869: ["1869", "EM", 300000000.0, 0.3, 0.01, 3.45, "-", "-", 20950.0] },
      {
        1870: ["1870", "EM", 755040000.0, 1.21, 0.04, 3.42, "-", "-", 661320.0],
      },
      {
        1871: [
          "1871",
          "EM",
          106000000.0,
          0.265,
          0.01,
          3.92,
          13.25,
          "-",
          1060.0,
        ],
      },
      {
        1872: ["1872", "EM", 166500000.0, 0.185, 0.0, 0.0, 8.98, "-", 167700.0],
      },
      {
        1873: [
          "1873",
          "EM",
          7060000000.0,
          3.65,
          0.08,
          2.24,
          "-",
          0.27,
          8560000.0,
        ],
      },
      { 1875: ["1875", "EM", 2220000000.0, 3.62, 0.0, 0.0, "-", "-", "-"] },
      {
        1876: [
          "1876",
          "EM",
          286050000000.0,
          21.6,
          1.05,
          5.11,
          71.88,
          1.02,
          141180000.0,
        ],
      },
      {
        1877: [
          "1877",
          "EM",
          9750000000.0,
          44.5,
          1.25,
          2.89,
          "-",
          "-",
          26020000.0,
        ],
      },
      { 1878: ["1878", "EM", 364650000.0, 1.33, 0.0, 0.0, "-", "-", 12010.0] },
      {
        1881: [
          "1881",
          "R",
          4690000000.0,
          1.44,
          0.0,
          0.0,
          "-",
          8.4,
          518070.00000000006,
        ],
      },
      {
        1882: [
          "1882",
          "EM",
          33509999999.999996,
          21.0,
          0.65,
          3.19,
          11.89,
          6.67,
          35530000.0,
        ],
      },
      {
        1883: [
          "1883",
          "EM",
          10420000000.0,
          2.83,
          -0.01,
          -0.35,
          10.13,
          7.6,
          9490000.0,
        ],
      },
      {
        1884: ["1884", "EM", 156750000.0, 0.285, 0.0, 0.0, 8.24, 2.81, 2280.0],
      },
      { 1885: ["1885", "EM", 900000000.0, 0.9, 0.0, 0.0, 11.48, "-", "-"] },
      {
        1888: [
          "1888",
          "EM",
          42740000000.0,
          13.7,
          0.44,
          3.32,
          15.12,
          5.84,
          22230000.0,
        ],
      },
      {
        1889: [
          "1889",
          "EM",
          312850000.0,
          0.102,
          0.001,
          0.99,
          40.8,
          "-",
          1010.0,
        ],
      },
      {
        1890: [
          "1890",
          "EM",
          5030000000.0,
          2.5,
          -0.01,
          -0.4,
          10.15,
          3.6,
          3810000.0,
        ],
      },
      {
        1891: [
          "1891",
          "EM",
          242000000.0,
          0.242,
          0.0,
          0.0,
          15.61,
          2.27,
          71560.0,
        ],
      },
      { 1894: ["1894", "EM", 506610000.0, 0.66, 0.0, 0.0, 41.25, "-", "-"] },
      { 1895: ["1895", "EM", 1140000000.0, 2.018, 0.0, 0.0, 6.43, 5.05, "-"] },
      {
        1896: [
          "1896",
          "EM",
          10900000000.0,
          9.55,
          -0.45,
          -4.5,
          "-",
          "-",
          20010000.0,
        ],
      },
      {
        1897: ["1897", "EM", 340940000.0, 0.81, 0.0, 0.0, 9.11, 5.19, 10590.0],
      },
      {
        1898: [
          "1898",
          "EM",
          18720000000.0,
          4.56,
          0.22,
          5.07,
          9.58,
          3.46,
          30060000.0,
        ],
      },
      {
        1899: [
          "1899",
          "EM",
          2900000000.0,
          1.75,
          0.04,
          2.34,
          20.11,
          8.57,
          1760000.0,
        ],
      },
      {
        1900: [
          "1900",
          "EM",
          236520000.0,
          0.143,
          0.002,
          1.42,
          "-",
          "-",
          27450.0,
        ],
      },
      {
        1901: ["1901", "EM", 594000000.0, 0.99, -0.03, -2.94, "-", "-", 5940.0],
      },
      {
        1902: [
          "1902",
          "EM",
          4520000000.0,
          3.13,
          0.02,
          0.64,
          20.48,
          0.98,
          1010000.0,
        ],
      },
      {
        1903: ["1903", "EM", 290000000.0, 0.58, 0.05, 9.43, "-", "-", 37660.0],
      },
      {
        1905: [
          "1905",
          "EM",
          5150000000.0,
          0.89,
          0.01,
          1.14,
          5.87,
          8.38,
          938460.0,
        ],
      },
      {
        1906: [
          "1906",
          "EM",
          516000000.0,
          0.43,
          0.005,
          1.18,
          9.13,
          "-",
          243970.0,
        ],
      },
      {
        1907: [
          "1907",
          "EM",
          18780000000.0,
          4.23,
          0.0,
          0.0,
          8.67,
          6.43,
          15560000.0,
        ],
      },
      {
        1908: [
          "1908",
          "EM",
          20830000000.0,
          15.12,
          0.3,
          2.02,
          6.71,
          7.28,
          11580000.0,
        ],
      },
      {
        1909: [
          "1909",
          "EM",
          2720000000.0,
          0.71,
          -0.03,
          -4.05,
          6.78,
          1.83,
          4370000.0,
        ],
      },
      {
        1910: [
          "1910",
          "EM",
          25260000000.0,
          17.58,
          1.38,
          8.52,
          "-",
          "-",
          170890000.0,
        ],
      },
      {
        1911: [
          "1911",
          "EM",
          7930000000.0,
          14.42,
          0.84,
          6.19,
          5.79,
          3.16,
          1740000.0,
        ],
      },
      { 1912: ["1912", "EM", 144800000.0, 0.181, 0.0, 0.0, 9.23, "-", "-"] },
      {
        1913: [
          "1913",
          "EM",
          127040000000.0,
          49.65,
          2.2,
          4.64,
          "-",
          0.64,
          37900000.0,
        ],
      },
      { 1915: ["1915", "EM", 300000000.0, 2.0, 0.0, 0.0, 27.74, "-", "-"] },
      {
        1916: [
          "1916",
          "EM",
          3330000000.0,
          2.48,
          -0.09,
          -3.5,
          6.84,
          2.37,
          146500.0,
        ],
      },
      { 1917: ["1917", "EM", 220800000.0, 0.096, 0.0, 0.0, "-", "-", 11660.0] },
      {
        1918: [
          "1918",
          "EM",
          52470000000.0,
          9.63,
          0.06,
          0.63,
          1.04,
          20.77,
          206960000.0,
        ],
      },
      {
        1919: [
          "1919",
          "EM",
          47500000000.0,
          14.16,
          0.14,
          1.0,
          14.94,
          "-",
          210080000.0,
        ],
      },
      {
        1920: [
          "1920",
          "EM",
          88400000.0,
          0.034,
          -0.001,
          -2.86,
          12.14,
          "-",
          67480.0,
        ],
      },
      {
        1921: [
          "1921",
          "EM",
          4710000000.0,
          3.14,
          -0.02,
          -0.63,
          "-",
          "-",
          10500000.0,
        ],
      },
      {
        1922: [
          "1922",
          "EM",
          1020000000.0,
          3.85,
          -0.02,
          -0.52,
          12.95,
          2.39,
          31200.0,
        ],
      },
      {
        1925: [
          "1925",
          "EM",
          550850000.0,
          1.36,
          -0.01,
          -0.73,
          11.39,
          "-",
          163140.0,
        ],
      },
      {
        1927: ["1927", "EM", 188490000.0, 0.238, 0.0, 0.0, 2.74, "-", 67130.0],
      },
      {
        1928: [
          "1928",
          "EM",
          180880000000.0,
          22.35,
          0.9,
          4.2,
          "-",
          "-",
          733860000.0,
        ],
      },
      {
        1929: [
          "1929",
          "EM",
          139800000000.0,
          13.98,
          0.34,
          2.49,
          23.2,
          3.29,
          62620000.0,
        ],
      },
      {
        1930: [
          "1930",
          "EM",
          214500000.0,
          0.325,
          0.005,
          1.56,
          6.44,
          1.14,
          18520.0,
        ],
      },
      {
        1931: [
          "1931",
          "EM",
          4470000000.0,
          3.31,
          0.07,
          2.16,
          23.49,
          1.89,
          3430000.0,
        ],
      },
      { 1932: ["1932", "EM", 435000000.0, 0.435, 0.0, 0.0, "-", 2.3, 3460.0] },
      {
        1933: ["1933", "EM", 191490000.0, 0.38, 0.01, 2.7, 7.58, "-", 13520.0],
      },
      {
        1935: [
          "1935",
          "EM",
          5090000000.0,
          3.18,
          0.18,
          6.0,
          16.31,
          3.54,
          3110000.0,
        ],
      },
      {
        1936: [
          "1936",
          "EM",
          344560000.0,
          0.73,
          0.09,
          14.06,
          21.16,
          "-",
          243540.0,
        ],
      },
      {
        1937: [
          "1937",
          "EM",
          1090000000.0,
          1.09,
          0.01,
          0.93,
          1211.11,
          "-",
          5310000.0,
        ],
      },
      {
        1938: ["1938", "EM", 283110000.0, 0.28, 0.01, 3.7, 0.39, "-", 12790.0],
      },
      { 1939: ["1939", "EM", 490000000.0, 0.98, 0.0, 0.0, 58.68, 1.02, "-"] },
      { 1940: ["1940", "EM", 4580000000.0, 3.82, 0.0, 0.0, "-", "-", "-"] },
      {
        1941: [
          "1941",
          "EM",
          267500000.0,
          0.66,
          -0.01,
          -1.49,
          10.44,
          "-",
          29580.0,
        ],
      },
      {
        1942: ["1942", "EM", 550000000.0, 1.1, 0.0, 0.0, 29.81, 1.82, 15760.0],
      },
      {
        1943: [
          "1943",
          "EM",
          890000000.0,
          0.89,
          0.01,
          1.14,
          63.12,
          "-",
          956400.0,
        ],
      },
      {
        1945: [
          "1945",
          "EM",
          1110000000.0,
          3.56,
          0.0,
          0.0,
          25.16,
          "-",
          366200.0,
        ],
      },
      {
        1948: [
          "1948",
          "EM",
          3510000000.0,
          5.86,
          -0.13,
          -2.17,
          "-",
          "-",
          2470000.0,
        ],
      },
      {
        1949: [
          "1949",
          "EM",
          112000000.0,
          0.28,
          -0.02,
          -6.67,
          107.69,
          "-",
          14350.0,
        ],
      },
      {
        1950: [
          "1950",
          "EM",
          146400000.0,
          0.122,
          0.005,
          4.27,
          "-",
          "-",
          636210.0,
        ],
      },
      {
        1951: [
          "1951",
          "EM",
          20030000000.0,
          7.99,
          0.23,
          2.96,
          64.91,
          "-",
          54380000.0,
        ],
      },
      {
        1952: [
          "1952",
          "EM",
          8320000000.0,
          27.9,
          2.0,
          7.72,
          "-",
          "-",
          6280000.0,
        ],
      },
      {
        1953: [
          "1953",
          "EM",
          259560000.0,
          0.206,
          -0.001,
          -0.48,
          11.14,
          "-",
          1030.0,
        ],
      },
      {
        1955: ["1955", "EM", 505000000.0, 1.01, 0.0, 0.0, 2.68, 7.43, 80800.0],
      },
      {
        1957: [
          "1957",
          "EM",
          235500000.0,
          0.375,
          -0.005,
          -1.32,
          288.46,
          "-",
          97280.0,
        ],
      },
      {
        1958: [
          "1958",
          "EM",
          7280000000.0,
          2.89,
          0.04,
          1.4,
          10.21,
          3.35,
          35810000.0,
        ],
      },
      { 1959: ["1959", "EM", 333430000.0, 0.66, 0.0, 0.0, 13.1, "-", 2600.0] },
      { 1960: ["1960", "EM", 430000000.0, 0.43, 0.0, 0.0, 390.91, "-", "-"] },
      { 1961: ["1961", "EM", 414960000.0, 0.76, 0.0, 0.0, "-", "-", "-"] },
      {
        1962: [
          "1962",
          "EM",
          878180000.0,
          1.28,
          -0.01,
          -0.78,
          "-",
          1.02,
          925360.0,
        ],
      },
      {
        1963: [
          "1963",
          "EM",
          7380000000.0,
          4.68,
          0.08,
          1.74,
          3.03,
          9.62,
          250070.0,
        ],
      },
      { 1965: ["1965", "EM", 1300000000.0, 3.18, 0.0, 0.0, "-", "-", 15900.0] },
      {
        1966: [
          "1966",
          "EM",
          7430000000.0,
          1.76,
          0.02,
          1.15,
          1.66,
          17.05,
          11860000.0,
        ],
      },
      {
        1967: [
          "1967",
          "EM",
          6810000000.0,
          27.25,
          0.45,
          1.68,
          492.77,
          "-",
          1790000.0,
        ],
      },
      {
        1968: ["1968", "EM", 113280000.0, 0.177, -0.002, -1.12, "-", "-", "-"],
      },
      {
        1969: [
          "1969",
          "EM",
          2200000000.0,
          1.84,
          -0.11,
          -5.64,
          3.01,
          "-",
          1440000.0,
        ],
      },
      {
        1970: [
          "1970",
          "EM",
          3750000000.0,
          10.98,
          -0.1,
          -0.9,
          "-",
          3.39,
          2430000.0,
        ],
      },
      {
        1971: [
          "1971",
          "EM",
          1540000000.0,
          3.72,
          0.06,
          1.64,
          16.03,
          1.67,
          335360.0,
        ],
      },
      {
        1972: [
          "1972",
          "EM",
          126060000000.0,
          21.55,
          0.85,
          4.11,
          30.78,
          4.27,
          80130000.0,
        ],
      },
      {
        1975: [
          "1975",
          "EM",
          403200000.0,
          0.84,
          0.13,
          18.31,
          5.18,
          6.55,
          1590000.0,
        ],
      },
      {
        1977: [
          "1977",
          "EM",
          2490000000.0,
          1.78,
          0.01,
          0.56,
          8.27,
          6.19,
          241840.0,
        ],
      },
      {
        1978: ["1978", "EM", 800000000.0, 1.0, 0.0, 0.0, 5.7, 15.56, 256200.0],
      },
      {
        1979: [
          "1979",
          "EM",
          2130000000.0,
          2.13,
          0.03,
          1.43,
          7.37,
          5.26,
          575440.0,
        ],
      },
      {
        1980: [
          "1980",
          "EM",
          1160000000.0,
          0.92,
          0.07,
          8.24,
          12.38,
          "-",
          2890000.0,
        ],
      },
      {
        1981: [
          "1981",
          "EM",
          3150000000.0,
          1.9,
          0.05,
          2.7,
          7.2,
          7.37,
          1790000.0,
        ],
      },
      {
        1982: [
          "1982",
          "EM",
          1120000000.0,
          0.495,
          0.015,
          3.13,
          3.6,
          10.91,
          28510.0,
        ],
      },
      { 1983: ["1983", "EM", 1730000000.0, 2.3, 0.0, 0.0, "-", "-", "-"] },
      {
        1985: ["1985", "EM", 273000000.0, 0.91, 0.0, 0.0, 5.77, 15.38, 3640.0],
      },
      {
        1986: [
          "1986",
          "EM",
          1350000000.0,
          1.32,
          0.02,
          1.54,
          8.03,
          3.56,
          45370.0,
        ],
      },
      {
        1987: [
          "1987",
          "EM",
          139000000.0,
          0.139,
          -0.003,
          -2.11,
          "-",
          "-",
          827960.0,
        ],
      },
      {
        1988: [
          "1988",
          "EM",
          26200000000.0,
          3.15,
          0.05,
          1.61,
          3.79,
          8.22,
          52630000.0,
        ],
      },
      { 1989: ["1989", "EM", 713270000.0, 0.79, 0.0, 0.0, 121.54, "-", "-"] },
      { 1991: ["1991", "EM", 224760000.0, 0.172, 0.001, 0.58, "-", "-", "-"] },
      {
        1992: [
          "1992",
          "EM",
          13300000000.0,
          10.74,
          0.4,
          3.87,
          "-",
          "-",
          975410.0,
        ],
      },
      {
        1993: ["1993", "EM", 751560000.0, 1.58, 0.23, 17.04, "-", "-", 9470.0],
      },
      {
        1995: [
          "1995",
          "EM",
          26160000000.0,
          14.92,
          0.94,
          6.72,
          52.08,
          0.56,
          77110000.0,
        ],
      },
      {
        1996: [
          "1996",
          "EM",
          8940000000.0,
          2.68,
          0.03,
          1.13,
          4.56,
          5.41,
          2110000.0,
        ],
      },
      {
        1997: [
          "1997",
          "EM",
          114920000000.0,
          37.85,
          0.9,
          2.44,
          "-",
          3.59,
          161870000.0,
        ],
      },
      {
        1999: [
          "1999",
          "EM",
          48140000000.0,
          12.18,
          0.2,
          1.67,
          24.23,
          2.38,
          50240000.0,
        ],
      },
      { 2000: ["2000", "EM", 926460000.0, 0.41, 0.0, 0.0, "-", "-", 5000.0] },
      {
        2001: [
          "2001",
          "EM",
          4179999999.9999995,
          2.64,
          -0.05,
          -1.86,
          6.07,
          6.28,
          5200000.0,
        ],
      },
      {
        2002: [
          "2002",
          "EM",
          2710000000.0,
          2.66,
          0.04,
          1.53,
          3.73,
          "-",
          5450000.0,
        ],
      },
      {
        2003: [
          "2003",
          "EM",
          1870000000.0,
          3.83,
          -0.17,
          -4.25,
          "-",
          5.22,
          175860.0,
        ],
      },
      {
        2005: [
          "2005",
          "EM",
          10270000000.0,
          3.43,
          -0.02,
          -0.58,
          16.99,
          2.92,
          14520000.0,
        ],
      },
      {
        2006: [
          "2006",
          "EM",
          4059999999.9999995,
          2.92,
          0.01,
          0.34,
          46.57,
          0.42,
          4900000.0,
        ],
      },
      {
        2007: [
          "2007",
          "EM",
          149530000000.0,
          6.46,
          0.07,
          1.1,
          3.39,
          8.62,
          132180000.0,
        ],
      },
      {
        2008: [
          "2008",
          "EM",
          2140000000.0000002,
          0.43,
          0.0,
          0.0,
          "-",
          "-",
          343640.0,
        ],
      },
      {
        2009: [
          "2009",
          "EM",
          2900000000.0,
          1.24,
          0.02,
          1.64,
          3.95,
          5.82,
          4179999.9999999995,
        ],
      },
      { 2011: ["2011", "EM", 1050000000.0, 2.28, 0.0, 0.0, "-", "-", "-"] },
      {
        2012: ["2012", "EM", 730430000.0, 3.0, 0.05, 1.69, 28.65, "-", 7990.0],
      },
      {
        2013: [
          "2013",
          "EM",
          15800000000.0,
          6.2,
          0.4,
          6.9,
          "-",
          "-",
          107180000.0,
        ],
      },
      { 2014: ["2014", "EM", 189770000.0, 0.089, 0.0, 0.0, "-", "-", "-"] },
      {
        2015: [
          "2015",
          "EM",
          218130000000.0,
          105.6,
          11.8,
          12.58,
          "-",
          "-",
          143380000.0,
        ],
      },
      {
        2016: [
          "2016",
          "EM",
          14520000000.0,
          3.19,
          0.09,
          2.9,
          5.14,
          6.06,
          127610.0,
        ],
      },
      {
        2017: ["2017", "EM", 179360000.0, 0.29, -0.005, -1.69, 6.65, "-", "-"],
      },
      {
        2018: [
          "2018",
          "EM",
          29420000000.0,
          24.35,
          0.8,
          3.4,
          16.57,
          1.64,
          159660000.0,
        ],
      },
      {
        2019: [
          "2019",
          "EM",
          7370000000.0,
          2.73,
          0.08,
          3.02,
          5.25,
          4.91,
          1950000.0,
        ],
      },
      {
        2020: [
          "2020",
          "EM",
          334940000000.0,
          123.9,
          7.4,
          6.35,
          54.74,
          1.11,
          704620000.0,
        ],
      },
      {
        2022: [
          "2022",
          "EM",
          224000000.0,
          0.112,
          -0.006,
          -5.08,
          "-",
          "-",
          11760.0,
        ],
      },
      {
        2023: [
          "2023",
          "EM",
          614750000.0,
          1.25,
          0.01,
          0.81,
          12.95,
          "-",
          199200.0,
        ],
      },
      {
        2025: [
          "2025",
          "EM",
          1100000000.0,
          1.38,
          0.0,
          0.0,
          27.99,
          2.17,
          30320.0,
        ],
      },
      { 2028: ["2028", "EM", 153220000.0, 0.25, 0.0, 0.0, "-", "-", "-"] },
      {
        2030: [
          "2030",
          "EM",
          1920000000.0,
          2.88,
          0.06,
          2.13,
          8.36,
          4.79,
          533850.0,
        ],
      },
      {
        2031: ["2031", "EM", 181350000.0, 0.238, 0.0, 0.0, 47.6, 4.2, 207610.0],
      },
      { 2033: ["2033", "EM", 1410000000.0, 0.68, 0.0, 0.0, 5.46, "-", "-"] },
      {
        2038: [
          "2038",
          "EM",
          9750000000.0,
          1.22,
          0.03,
          2.52,
          "-",
          "-",
          11590000.0,
        ],
      },
      {
        2039: [
          "2039",
          "EM",
          29250000000.0,
          14.2,
          0.18,
          1.28,
          8.57,
          2.39,
          4250000.0,
        ],
      },
      {
        2048: [
          "2048",
          "EM",
          3140000000.0,
          1.8,
          -0.02,
          -1.1,
          7.26,
          3.5,
          4070000.0000000005,
        ],
      },
      {
        2051: [
          "2051",
          "EM",
          645200000.0,
          0.475,
          -0.025,
          -5.0,
          "-",
          "-",
          2410.0,
        ],
      },
      {
        2057: [
          "2057",
          "EM",
          196030000000.0,
          229.2,
          8.2,
          3.71,
          "-",
          0.84,
          11640000.0,
        ],
      },
      {
        2060: [
          "2060",
          "EM",
          2550000000.0,
          3.15,
          -0.06,
          -1.87,
          14.3,
          1.59,
          1080000.0,
        ],
      },
      {
        2066: ["2066", "EM", 15800000000.0, 6.75, 0.0, 0.0, 42.13, "-", 3370.0],
      },
      {
        2068: [
          "2068",
          "EM",
          954740000.0,
          2.39,
          0.08,
          3.46,
          "-",
          "-",
          1370000.0,
        ],
      },
      {
        2078: [
          "2078",
          "EM",
          306000000.0,
          0.255,
          0.006,
          2.41,
          0.33,
          "-",
          290760.0,
        ],
      },
      {
        2080: [
          "2080",
          "EM",
          269980000.0,
          0.72,
          -0.05,
          -6.49,
          "-",
          "-",
          22040.0,
        ],
      },
      {
        2086: ["2086", "EM", 297190000.0, 0.93, 0.06, 6.9, "-", "-", 286140.0],
      },
      { 2088: ["2088", "EM", 122560000.0, 0.087, 0.0, 0.0, "-", "-", "-"] },
      {
        2096: [
          "2096",
          "EM",
          20400000000.0,
          7.73,
          0.06,
          0.78,
          23.45,
          2.33,
          8570000.0,
        ],
      },
      {
        2098: ["2098", "EM", 5420000000.0, 0.46, 0.0, 0.0, "-", "-", 5650000.0],
      },
      {
        2099: [
          "2099",
          "EM",
          8380000000.000001,
          21.15,
          0.5,
          2.42,
          9.67,
          4.39,
          4390000.0,
        ],
      },
      {
        2100: [
          "2100",
          "EM",
          1550000000.0,
          0.56,
          0.0,
          0.0,
          4.6,
          10.71,
          285900.0,
        ],
      },
      {
        2101: [
          "2101",
          "EM",
          2910000000.0,
          7.22,
          -0.26,
          -3.48,
          16.68,
          4.43,
          2160000.0,
        ],
      },
      {
        2102: [
          "2102",
          "EM",
          285000000.0,
          0.285,
          0.005,
          1.79,
          5.42,
          12.28,
          67750.0,
        ],
      },
      { 2103: ["2103", "EM", 1780000000.0, 0.5, 0.0, 0.0, 0.78, 34.1, "-"] },
      {
        2107: [
          "2107",
          "EM",
          980000000.0,
          0.98,
          0.0,
          0.0,
          6.99,
          4.05,
          1090000.0,
        ],
      },
      {
        2108: [
          "2108",
          "EM",
          216000000.0,
          0.27,
          -0.025,
          -8.47,
          17.53,
          "-",
          34720.0,
        ],
      },
      {
        2110: [
          "2110",
          "EM",
          331370000.0,
          0.149,
          -0.003,
          -1.97,
          7.68,
          7.52,
          1470000.0,
        ],
      },
      {
        2111: [
          "2111",
          "EM",
          2390000000.0,
          2.3,
          -0.01,
          -0.43,
          9.28,
          6.48,
          50400.0,
        ],
      },
      {
        2112: [
          "2112",
          "EM",
          174000000.0,
          0.116,
          -0.002,
          -1.69,
          "-",
          "-",
          73050.0,
        ],
      },
      { 2113: ["2113", "EM", 277630000.0, 0.345, 0.0, 0.0, "-", "-", "-"] },
      {
        2115: [
          "2115",
          "EM",
          196000000.0,
          0.14,
          0.004,
          2.94,
          12.84,
          4.57,
          2780.0,
        ],
      },
      { 2116: ["2116", "EM", 170400000.0, 0.355, 0.0, 0.0, 6.59, 2.82, "-"] },
      {
        2117: [
          "2117",
          "EM",
          6380000000.0,
          4.68,
          -0.12,
          -2.5,
          5.64,
          2.78,
          247090.0,
        ],
      },
      {
        2118: ["2118", "EM", 2060000000.0, 2.05, 0.01, 0.49, "-", "-", 28680.0],
      },
      { 2119: ["2119", "EM", 720730000.0, 1.0, 0.0, 0.0, 9.69, 7.19, 74020.0] },
      {
        2120: [
          "2120",
          "EM",
          237880000.0,
          12.3,
          -4.5,
          -26.79,
          10.84,
          "-",
          29630000.0,
        ],
      },
      {
        2121: [
          "2121",
          "EM",
          10880000000.0,
          19.46,
          -0.54,
          -2.7,
          "-",
          "-",
          15800000.0,
        ],
      },
      { 2122: ["2122", "EM", 180800000.0, 0.226, 0.0, 0.0, "-", "-", "-"] },
      {
        2125: [
          "2125",
          "EM",
          3630000000.0,
          5.23,
          -0.38,
          -6.77,
          96.49,
          "-",
          2690000.0,
        ],
      },
      {
        2126: [
          "2126",
          "EM",
          3680000000.0,
          9.05,
          0.05,
          0.56,
          "-",
          "-",
          11980000.0,
        ],
      },
      {
        2127: [
          "2127",
          "EM",
          4320000000.0,
          1.41,
          0.0,
          0.0,
          4.99,
          5.6,
          5490000.0,
        ],
      },
      {
        2128: [
          "2128",
          "EM",
          42810000000.0,
          13.8,
          0.32,
          2.37,
          9.64,
          3.62,
          42010000.0,
        ],
      },
      {
        2129: [
          "2129",
          "EM",
          147500000.0,
          0.118,
          -0.003,
          -2.48,
          4.92,
          "-",
          403760.0,
        ],
      },
      {
        2130: [
          "2130",
          "EM",
          2550000000.0,
          9.26,
          -0.13,
          -1.38,
          22.5,
          2.59,
          7340000.0,
        ],
      },
      {
        2131: [
          "2131",
          "EM",
          1880000000.0,
          2.37,
          0.08,
          3.49,
          11.87,
          "-",
          3640000.0,
        ],
      },
      {
        2132: [
          "2132",
          "EM",
          384000000.0,
          0.24,
          0.008,
          3.45,
          7.14,
          1.29,
          124560.0,
        ],
      },
      { 2133: ["2133", "EM", 102020000.0, 0.228, 0.0, 0.0, "-", "-", "-"] },
      {
        2135: [
          "2135",
          "EM",
          511810000.0,
          0.245,
          0.004,
          1.66,
          84.48,
          "-",
          399150.0,
        ],
      },
      {
        2136: [
          "2136",
          "EM",
          1710000000.0,
          1.17,
          0.01,
          0.86,
          6.82,
          "-",
          162180.0,
        ],
      },
      {
        2137: [
          "2137",
          "EM",
          10840000000.0,
          15.06,
          -1.36,
          -8.28,
          "-",
          "-",
          55580000.0,
        ],
      },
      {
        2138: [
          "2138",
          "EM",
          10640000000.0,
          9.03,
          0.12,
          1.35,
          48.06,
          2.56,
          12540000.0,
        ],
      },
      {
        2139: [
          "2139",
          "EM",
          4430000000.0,
          1.17,
          0.07,
          6.36,
          18.03,
          "-",
          15790.0,
        ],
      },
      {
        2142: [
          "2142",
          "EM",
          4059999999.9999995,
          5.29,
          -0.14,
          -2.58,
          "-",
          "-",
          377040.0,
        ],
      },
      {
        2146: [
          "2146",
          "EM",
          532980000.0,
          5.67,
          0.17,
          3.09,
          4.99,
          3.18,
          248890.0,
        ],
      },
      {
        2148: [
          "2148",
          "EM",
          7770000000.0,
          6.67,
          -0.07,
          -1.04,
          12.75,
          1.91,
          26360000.0,
        ],
      },
      {
        2150: [
          "2150",
          "EM",
          12700000000.0,
          7.41,
          0.48,
          6.93,
          "-",
          "-",
          12200000.0,
        ],
      },
      {
        2153: [
          "2153",
          "EM",
          1340000000.0,
          1.15,
          0.02,
          1.77,
          8.97,
          5.22,
          493880.0,
        ],
      },
      {
        2155: [
          "2155",
          "EM",
          8090000000.0,
          7.8,
          0.08,
          1.04,
          "-",
          "-",
          1780000.0,
        ],
      },
      {
        2156: [
          "2156",
          "EM",
          5740000000.0,
          4.3,
          0.05,
          1.18,
          40.6,
          "-",
          319280.0,
        ],
      },
      {
        2158: [
          "2158",
          "EM",
          14460000000.0,
          14.72,
          0.18,
          1.24,
          "-",
          "-",
          16110000.0,
        ],
      },
      {
        2159: [
          "2159",
          "EM",
          320000000.0,
          1.6,
          0.15,
          10.34,
          9.71,
          "-",
          1190000.0,
        ],
      },
      {
        2160: [
          "2160",
          "EM",
          7900000000.0,
          3.29,
          0.04,
          1.23,
          "-",
          "-",
          5530000.0,
        ],
      },
      {
        2161: [
          "2161",
          "EM",
          795380000.0,
          0.89,
          -0.02,
          -2.2,
          32.01,
          "-",
          79530.0,
        ],
      },
      {
        2162: [
          "2162",
          "EM",
          7370000000.0,
          26.35,
          0.6,
          2.33,
          "-",
          "-",
          1770000.0,
        ],
      },
      {
        2163: [
          "2163",
          "EM",
          3630000000.0,
          11.72,
          -0.04,
          -0.34,
          22.22,
          "-",
          2520000.0,
        ],
      },
      { 2165: ["2165", "EM", 1100000000.0, 3.86, 0.0, 0.0, "-", "-", "-"] },
      {
        2166: [
          "2166",
          "EM",
          733020000.0,
          1.5,
          -0.01,
          -0.66,
          10.4,
          5.33,
          116600.0,
        ],
      },
      {
        2168: [
          "2168",
          "EM",
          1790000000.0,
          11.64,
          1.02,
          9.6,
          6.54,
          5.93,
          2880000.0,
        ],
      },
      {
        2169: [
          "2169",
          "EM",
          2400000000.0,
          2.4,
          0.05,
          2.13,
          25.53,
          2.01,
          4360000.0,
        ],
      },
      {
        2170: [
          "2170",
          "EM",
          453220000.0,
          5.6,
          0.18,
          3.32,
          "-",
          "-",
          258709.99999999997,
        ],
      },
      {
        2171: [
          "2171",
          "EM",
          9610000000.0,
          16.94,
          -0.06,
          -0.35,
          "-",
          "-",
          3610000.0,
        ],
      },
      {
        2175: [
          "2175",
          "EM",
          1930000000.0,
          3.83,
          0.01,
          0.26,
          9.57,
          "-",
          4080000.0,
        ],
      },
      { 2176: ["2176", "EM", 70010000.0, 0.335, 0.0, 0.0, 9.36, 5.55, "-"] },
      {
        2177: [
          "2177",
          "EM",
          638690000.0,
          3.85,
          -0.01,
          -0.26,
          "-",
          "-",
          496260.0,
        ],
      },
      { 2178: ["2178", "EM", 176120000.0, 0.102, 0.0, 0.0, "-", "-", "-"] },
      { 2180: ["2180", "EM", 1840000000.0, 8.88, 0.0, 0.0, 12.46, 3.6, "-"] },
      { 2181: ["2181", "EM", 3950000000.0, 0.96, -0.01, -1.03, "-", "-", "-"] },
      { 2182: ["2182", "EM", 251100000.0, 0.405, 0.0, 0.0, 3.41, 3.7, "-"] },
      { 2183: ["2183", "EM", 2150000000.0, 4.39, 0.0, 0.0, 2.25, 5.5, "-"] },
      {
        2185: [
          "2185",
          "EM",
          2049999999.9999998,
          33.3,
          -1.05,
          -3.06,
          "-",
          "-",
          7840000.0,
        ],
      },
      {
        2186: [
          "2186",
          "EM",
          12210000000.0,
          3.45,
          0.06,
          1.77,
          13.29,
          "-",
          8050000.000000001,
        ],
      },
      {
        2187: [
          "2187",
          "EM",
          1680000000.0,
          2.25,
          0.07,
          3.21,
          "-",
          "-",
          12230000.0,
        ],
      },
      {
        2188: [
          "2188",
          "EM",
          342270000.0,
          0.37,
          -0.005,
          -1.33,
          "-",
          "-",
          369490.0,
        ],
      },
      {
        2189: ["2189", "EM", 660000000.0, 0.66, 0.0, 0.0, 8.26, 6.06, 196960.0],
      },
      {
        2190: [
          "2190",
          "EM",
          2870000000.0,
          22.0,
          -0.7,
          -3.08,
          "-",
          "-",
          278250.0,
        ],
      },
      {
        2191: [
          "2191",
          "R",
          2880000000.0,
          3.6,
          -0.09,
          -2.44,
          78.26,
          "-",
          9870000.0,
        ],
      },
      {
        2192: [
          "2192",
          "EM",
          8760000000.0,
          12.28,
          -0.34,
          -2.69,
          "-",
          "-",
          22160000.0,
        ],
      },
      { 2193: ["2193", "EM", 125940000.0, 0.3, 0.005, 1.69, 8.0, 10.0, "-"] },
      {
        2195: [
          "2195",
          "EM",
          305000000.0,
          0.305,
          -0.01,
          -3.17,
          "-",
          "-",
          124570.0,
        ],
      },
      {
        2196: [
          "2196",
          "EM",
          17850000000.0,
          32.35,
          1.15,
          3.69,
          19.26,
          1.61,
          62760000.0,
        ],
      },
      {
        2197: [
          "2197",
          "EM",
          14540000000.0,
          12.56,
          0.64,
          5.37,
          "-",
          "-",
          2660000.0,
        ],
      },
      {
        2198: [
          "2198",
          "EM",
          2790000000.0,
          2.35,
          0.03,
          1.29,
          2.09,
          18.09,
          2590000.0,
        ],
      },
      {
        2199: [
          "2199",
          "EM",
          7630000000.0,
          6.24,
          0.08,
          1.3,
          60.88,
          1.86,
          1790000.0,
        ],
      },
      {
        2202: [
          "2202",
          "EM",
          38780000000.0,
          20.4,
          0.3,
          1.49,
          4.81,
          7.35,
          121930000.0,
        ],
      },
      { 2203: ["2203", "EM", 148000000.0, 0.185, 0.0, 0.0, "-", "-", "-"] },
      {
        2205: [
          "2205",
          "EM",
          2490000000.0,
          3.57,
          -0.06,
          -1.65,
          "-",
          "-",
          42810.0,
        ],
      },
      {
        2207: [
          "2207",
          "EM",
          2560000000.0,
          5.04,
          0.18,
          3.7,
          "-",
          "-",
          2190000.0,
        ],
      },
      {
        2208: [
          "2208",
          "EM",
          10590000000.0,
          13.7,
          0.2,
          1.48,
          17.41,
          2.19,
          36460000.0,
        ],
      },
      {
        2209: [
          "2209",
          "EM",
          562180000.0,
          1.42,
          0.04,
          2.9,
          "-",
          3.52,
          16100.000000000002,
        ],
      },
      {
        2210: [
          "2210",
          "EM",
          169760000.0,
          4.63,
          -0.7,
          -13.13,
          "-",
          "-",
          148920.0,
        ],
      },
      { 2211: ["2211", "EM", 67210000.0, 0.145, 0.02, 16.0, "-", "-", 6030.0] },
      {
        2212: ["2212", "EM", 144820000.0, 0.033, -0.001, -2.94, "-", "-", "-"],
      },
      { 2213: ["2213", "EM", 101310000.0, 0.101, 0.0, 0.0, "-", "-", "-"] },
      {
        2215: [
          "2215",
          "EM",
          3160000000.0,
          3.11,
          0.05,
          1.63,
          "-",
          "-",
          800330.0,
        ],
      },
      {
        2216: [
          "2216",
          "EM",
          3330000000.0,
          6.34,
          -0.16,
          -2.46,
          "-",
          "-",
          127360.0,
        ],
      },
      {
        2217: [
          "2217",
          "EM",
          4040000000.0,
          3.02,
          0.03,
          1.0,
          "-",
          "-",
          4070000.0000000005,
        ],
      },
      { 2218: ["2218", "EM", 694760000.0, 7.18, 0.0, 0.0, 14.96, 0.81, "-"] },
      {
        2219: [
          "2219",
          "EM",
          3310000000.0,
          4.69,
          0.19,
          4.22,
          "-",
          "-",
          2040000.0,
        ],
      },
      { 2221: ["2221", "EM", 580850000.0, 0.61, 0.0, 0.0, "-", "-", 39440.0] },
      { 2222: ["2222", "EM", 714410000.0, 0.169, 0.0, 0.0, "-", "-", "-"] },
      { 2223: ["2223", "EM", 180490000.0, 0.7, 0.0, 0.0, 11.2, 21.43, "-"] },
      {
        2225: [
          "2225",
          "EM",
          2970000000.0,
          2.42,
          0.0,
          0.0,
          424.56,
          "-",
          2700000.0,
        ],
      },
      { 2226: ["2226", "EM", 1770000000.0, 3.07, 0.0, 0.0, 8.33, 2.43, "-"] },
      {
        2227: ["2227", "EM", 138250000.0, 0.151, 0.007, 4.86, "-", "-", 1200.0],
      },
      { 2228: ["2228", "EM", 1390000000.0, 0.6, 0.0, 0.0, "-", "-", "-"] },
      {
        2230: [
          "2230",
          "EM",
          328680000.0,
          0.165,
          0.002,
          1.23,
          8.38,
          4.24,
          97970.0,
        ],
      },
      {
        2231: [
          "2231",
          "EM",
          4230000000.0000005,
          2.57,
          0.0,
          0.0,
          7.4,
          4.87,
          58890.0,
        ],
      },
      {
        2232: [
          "2232",
          "EM",
          7380000000.0,
          2.59,
          0.29,
          12.61,
          8.83,
          5.71,
          1020000.0,
        ],
      },
      {
        2233: [
          "2233",
          "EM",
          7280000000.0,
          1.34,
          0.03,
          2.29,
          3.97,
          10.82,
          10450000.0,
        ],
      },
      {
        2235: [
          "2235",
          "EM",
          1180000000.0,
          18.0,
          0.4,
          2.27,
          "-",
          "-",
          10820000.0,
        ],
      },
      {
        2236: [
          "2236",
          "EM",
          1400000000.0,
          0.345,
          -0.025,
          -6.76,
          "-",
          "-",
          56470.0,
        ],
      },
      {
        2238: [
          "2238",
          "EM",
          24160000000.0,
          7.8,
          0.2,
          2.63,
          11.36,
          3.08,
          46440000.0,
        ],
      },
      { 2239: ["2239", "EM", 855340000.0, 2.67, 0.01, 0.38, "-", 0.37, "-"] },
      {
        2250: [
          "2250",
          "EM",
          1930000000.0,
          1.93,
          0.06,
          3.21,
          "-",
          "-",
          7210000.0,
        ],
      },
      {
        2251: [
          "2251",
          "EM",
          854520000.0,
          32.95,
          0.75,
          2.33,
          "-",
          "-",
          1270000.0,
        ],
      },
      {
        2252: [
          "2252",
          "EM",
          44260000000.0,
          46.5,
          -1.0,
          -2.11,
          "-",
          "-",
          36550000.0,
        ],
      },
      {
        2255: [
          "2255",
          "EM",
          11680000000.0,
          2.92,
          0.05,
          1.74,
          "-",
          "-",
          22010000.0,
        ],
      },
      {
        2256: ["2256", "EM", 5280000000.0, 7.52, 0.0, 0.0, "-", "-", 195780.0],
      },
      {
        2257: [
          "2257",
          "EM",
          6630000000.0,
          74.5,
          1.4,
          1.92,
          "-",
          "-",
          8950000.0,
        ],
      },
      { 2258: ["2258", "EM", 305390000.0, 0.37, 0.0, 0.0, 5.52, 2.16, "-"] },
      {
        2260: [
          "2260",
          "EM",
          552000000.0,
          1.15,
          -0.07,
          -5.74,
          "-",
          "-",
          1160000.0,
        ],
      },
      {
        2262: [
          "2262",
          "EM",
          833220000.0,
          0.73,
          0.0,
          0.0,
          24.83,
          4.11,
          3010000.0,
        ],
      },
      { 2263: ["2263", "EM", 145000000.0, 0.145, 0.0, 0.0, 13.55, "-", "-"] },
      {
        2265: ["2265", "EM", 920000000.0, 0.92, 0.01, 1.1, "-", "-", 305350.0],
      },
      {
        2266: ["2266", "EM", 134000000.0, 0.335, 0.01, 3.08, "-", "-", 7660.0],
      },
      {
        2269: [
          "2269",
          "EM",
          335310000000.0,
          79.55,
          3.6,
          4.74,
          157.71,
          "-",
          1330000000.0,
        ],
      },
      { 2270: ["2270", "EM", 680790000.0, 1.11, 0.0, 0.0, "-", "-", "-"] },
      {
        2273: [
          "2273",
          "EM",
          7530000000.0,
          32.7,
          0.7,
          2.19,
          "-",
          "-",
          3790000.0,
        ],
      },
      {
        2276: [
          "2276",
          "EM",
          1860000000.0,
          4.37,
          -0.11,
          -2.46,
          "-",
          "-",
          4950000.0,
        ],
      },
      { 2278: ["2278", "EM", 1390000000.0, 4.64, 0.0, 0.0, 18.43, "-", "-"] },
      {
        2279: [
          "2279",
          "EM",
          5790000000.0,
          11.0,
          0.22,
          2.04,
          "-",
          "-",
          1850000.0,
        ],
      },
      {
        2280: ["2280", "EM", 982440000.0, 0.75, 0.01, 1.35, "-", "-", 92420.0],
      },
      {
        2281: ["2281", "EM", 195590000.0, 0.91, 0.0, 0.0, 3.21, 8.02, 91000.0],
      },
      {
        2282: [
          "2282",
          "EM",
          20250000000.0,
          5.33,
          0.18,
          3.5,
          "-",
          "-",
          18190000.0,
        ],
      },
      {
        2283: [
          "2283",
          "EM",
          2150000000.0,
          2.59,
          0.01,
          0.39,
          10.26,
          5.17,
          67140.0,
        ],
      },
      {
        2285: [
          "2285",
          "EM",
          29040000000.0,
          59.25,
          3.35,
          5.99,
          "-",
          "-",
          13220000.0,
        ],
      },
      {
        2286: [
          "2286",
          "EM",
          869990000.0,
          1.45,
          -0.02,
          -1.36,
          3.57,
          "-",
          56440.0,
        ],
      },
      { 2288: ["2288", "EM", 232770000.0, 0.62, 0.0, 0.0, 61.39, 4.84, "-"] },
      { 2289: ["2289", "EM", 156800000.0, 5.6, 0.0, 0.0, 12.7, 4.32, "-"] },
      { 2292: ["2292", "EM", 662400000.0, 0.92, 0.0, 0.0, "-", "-", "-"] },
      {
        2293: [
          "2293",
          "EM",
          316000000.0,
          0.79,
          0.01,
          1.28,
          5.18,
          12.66,
          31360.0,
        ],
      },
      { 2295: ["2295", "EM", 560000000.0, 1.4, 0.0, 0.0, 14.23, "-", "-"] },
      { 2296: ["2296", "EM", 2120000000.0, 1.06, 0.0, 0.0, 109.28, 2.36, "-"] },
      {
        2298: [
          "2298",
          "EM",
          1070000000.0000001,
          0.48,
          -0.02,
          -4.0,
          "-",
          "-",
          23890.0,
        ],
      },
      {
        2299: [
          "2299",
          "EM",
          11860000000.0,
          5.6,
          -0.2,
          -3.45,
          12.64,
          "-",
          101600.0,
        ],
      },
      { 2302: ["2302", "EM", 733750000.0, 1.5, 0.0, 0.0, "-", "-", "-"] },
      {
        2307: ["2307", "EM", 304470000.0, 0.35, 0.02, 6.06, "-", "-", 19820.0],
      },
      {
        2308: ["2308", "EM", 394690000.0, 1.28, 0.03, 2.4, 6.41, "-", 994120.0],
      },
      {
        2309: [
          "2309",
          "EM",
          2210000000.0,
          0.115,
          0.005,
          4.55,
          "-",
          "-",
          988640.0,
        ],
      },
      {
        2310: [
          "2310",
          "EM",
          101630000.0,
          0.093,
          -0.007,
          -7.0,
          "-",
          "-",
          51510.0,
        ],
      },
      { 2312: ["2312", "-", 38150000.0, 0.165, 0.0, 0.0, "-", "-", 3330.0] },
      {
        2313: [
          "2313",
          "EM",
          218260000000.0,
          145.2,
          2.1,
          1.47,
          36.38,
          1.49,
          270420000.0,
        ],
      },
      {
        2314: [
          "2314",
          "EM",
          23210000000.0,
          5.36,
          0.04,
          0.75,
          6.62,
          6.16,
          20190000.0,
        ],
      },
      {
        2317: ["2317", "EM", 1030000000.0, 0.68, 0.0, 0.0, 8.07, 8.37, 29720.0],
      },
      {
        2318: [
          "2318",
          "EM",
          474410000000.0,
          63.7,
          2.8,
          4.6,
          6.62,
          4.28,
          1970000000.0,
        ],
      },
      {
        2319: [
          "2319",
          "EM",
          185790000000.0,
          47.0,
          1.0,
          2.17,
          44.42,
          0.69,
          299010000.0,
        ],
      },
      {
        2320: [
          "2320",
          "EM",
          201140000.0,
          0.246,
          -0.004,
          -1.6,
          144.71,
          "-",
          1220.0,
        ],
      },
      { 2322: ["2322", "EM", 3660000000.0, 0.89, 0.0, 0.0, "-", "-", "-"] },
      { 2323: ["2323", "EM", 236250000.0, 0.107, 0.0, 0.0, "-", "-", "-"] },
      { 2324: ["2324", "-", 111330000.0, 0.265, 0.0, 0.0, 1.44, "-", "-"] },
      { 2326: ["2326", "EM", 274090000.0, 0.013, 0.0, 0.0, "-", "-", 2590.0] },
      {
        2327: [
          "2327",
          "EM",
          1890000000.0,
          0.45,
          -0.005,
          -1.1,
          23.08,
          "-",
          325170.0,
        ],
      },
      {
        2328: [
          "2328",
          "EM",
          51120000000.0,
          7.41,
          0.17,
          2.35,
          6.69,
          6.13,
          134850000.0,
        ],
      },
      {
        2329: [
          "2329",
          "EM",
          1350000000.0,
          0.305,
          -0.015,
          -4.69,
          11.69,
          "-",
          4610.0,
        ],
      },
      { 2330: ["2330", "EM", 142500000.0, 0.56, 0.0, 0.0, "-", "-", "-"] },
      {
        2331: [
          "2331",
          "EM",
          212200000000.0,
          81.1,
          5.75,
          7.63,
          99.19,
          0.3,
          1060000000.0,
        ],
      },
      {
        2333: [
          "2333",
          "EM",
          65709999999.99999,
          21.2,
          0.4,
          1.92,
          30.69,
          3.3,
          272950000.0,
        ],
      },
      {
        2336: [
          "2336",
          "EM",
          176140000.0,
          0.097,
          -0.009,
          -8.49,
          "-",
          "-",
          57900.0,
        ],
      },
      {
        2337: [
          "2337",
          "EM",
          4800000000.0,
          12.82,
          0.4,
          3.22,
          30.88,
          0.42,
          4350000.0,
        ],
      },
      {
        2338: [
          "2338",
          "EM",
          27740000000.0,
          14.28,
          0.24,
          1.71,
          10.51,
          3.52,
          66900000.00000001,
        ],
      },
      { 2339: ["2339", "EM", 321620000.0, 0.56, 0.02, 3.7, "-", "-", 69500.0] },
      {
        2340: [
          "2340",
          "EM",
          399350000.0,
          0.94,
          0.0,
          0.0,
          28.06,
          62.77,
          4059999.9999999995,
        ],
      },
      {
        2341: [
          "2341",
          "EM",
          1450000000.0,
          1.88,
          0.09,
          5.03,
          3.05,
          3.99,
          7660000.0,
        ],
      },
      {
        2342: [
          "2342",
          "EM",
          5080000000.0,
          1.83,
          0.03,
          1.67,
          "-",
          "-",
          4250000.0,
        ],
      },
      {
        2343: [
          "2343",
          "EM",
          15970000000.0,
          3.32,
          0.02,
          0.61,
          "-",
          4.22,
          82770000.0,
        ],
      },
      {
        2346: [
          "2346",
          "EM",
          970020000.0,
          1.63,
          0.05,
          3.16,
          31.53,
          "-",
          712220.0,
        ],
      },
      {
        2348: [
          "2348",
          "EM",
          2029999999.9999998,
          1.36,
          -0.09,
          -6.21,
          6.67,
          8.46,
          383810.0,
        ],
      },
      { 2349: ["2349", "EM", 500520000.0, 0.16, 0.01, 6.67, "-", "-", 3200.0] },
      {
        2355: [
          "2355",
          "EM",
          919320000.0,
          4.38,
          -0.01,
          -0.23,
          2.64,
          "-",
          109600.0,
        ],
      },
      {
        2356: [
          "2356",
          "EM",
          10850000000.0,
          7.72,
          0.34,
          4.61,
          7.27,
          4.15,
          32930000.0,
        ],
      },
      {
        2357: [
          "2357",
          "EM",
          28320000000.0,
          4.56,
          0.18,
          4.11,
          15.08,
          1.32,
          27950000.0,
        ],
      },
      {
        2358: [
          "2358",
          "EM",
          492480000.0,
          0.09,
          -0.005,
          -5.26,
          21.43,
          "-",
          9000.0,
        ],
      },
      {
        2359: [
          "2359",
          "EM",
          44700000000.0,
          114.0,
          2.7,
          2.43,
          92.0,
          0.31,
          140420000.0,
        ],
      },
      {
        2360: ["2360", "EM", 1610000000.0, 1.61, 0.01, 0.63, 19.78, 2.48, "-"],
      },
      {
        2362: [
          "2362",
          "EM",
          17520000000.0,
          1.39,
          0.01,
          0.72,
          74.73,
          0.07,
          24190000.0,
        ],
      },
      { 2363: ["2363", "EM", 102110000.0, 0.15, 0.0, 0.0, "-", "-", 100360.0] },
      { 2366: ["2366", "EM", 93170000.0, 0.059, 0.0, 0.0, "-", "-", "-"] },
      {
        2368: [
          "2368",
          "EM",
          2570000000.0,
          4.83,
          0.13,
          2.77,
          8.19,
          7.04,
          1140000.0,
        ],
      },
      {
        2369: [
          "2369",
          "EM",
          4480000000.0,
          0.325,
          0.01,
          3.17,
          "-",
          "-",
          1820000.0,
        ],
      },
      {
        2371: [
          "2371",
          "EM",
          499660000.0,
          0.074,
          -0.001,
          -1.33,
          "-",
          "-",
          1020000.0,
        ],
      },
      {
        2377: ["2377", "EM", 1400000000.0, 1.4, 0.04, 2.94, 5.7, 5.29, 25180.0],
      },
      {
        2378: [
          "2378",
          "EM",
          361970000000.0,
          131.8,
          1.7,
          1.31,
          20.91,
          0.95,
          9500000.0,
        ],
      },
      { 2379: ["2379", "EM", 101320000.0, 0.247, 0.0, 0.0, "-", "-", "-"] },
      {
        2380: [
          "2380",
          "EM",
          43330000000.0,
          4.0,
          0.15,
          3.9,
          19.44,
          3.89,
          126520000.0,
        ],
      },
      {
        2381: [
          "2381",
          "EM",
          208000000.0,
          0.104,
          0.001,
          0.97,
          5.39,
          3.85,
          9450.0,
        ],
      },
      {
        2382: [
          "2382",
          "EM",
          224630000000.0,
          204.8,
          5.1,
          2.55,
          38.9,
          0.52,
          994820000.0,
        ],
      },
      {
        2383: [
          "2383",
          "EM",
          3480000000.0,
          0.88,
          -0.02,
          -2.22,
          "-",
          "-",
          572120.0,
        ],
      },
      {
        2386: [
          "2386",
          "EM",
          5560000000.0,
          3.81,
          0.02,
          0.53,
          6.0,
          8.66,
          20140000.0,
        ],
      },
      {
        2388: [
          "2388",
          "EM",
          328810000000.0,
          31.1,
          1.1,
          3.67,
          12.41,
          3.99,
          736740000.0,
        ],
      },
      {
        2389: ["2389", "EM", 553180000.0, 0.091, 0.001, 1.11, "-", "-", 3450.0],
      },
      {
        2393: [
          "2393",
          "EM",
          2610000000.0,
          1.12,
          -0.01,
          -0.88,
          "-",
          "-",
          114520.0,
        ],
      },
      {
        2399: [
          "2399",
          "EM",
          1150000000.0,
          0.55,
          0.01,
          1.85,
          "-",
          "-",
          7480000.0,
        ],
      },
      {
        2400: [
          "2400",
          "EM",
          14340000000.0,
          29.85,
          0.65,
          2.23,
          1198.8,
          "-",
          25750000.0,
        ],
      },
      {
        2448: [
          "2448",
          "EM",
          4230000000.0000005,
          5.26,
          -0.11,
          -2.05,
          104.57,
          "-",
          1350000.0,
        ],
      },
      {
        2488: ["2488", "EM", 634230000.0, 3.55, 0.08, 2.31, "-", "-", 301010.0],
      },
      {
        2500: [
          "2500",
          "EM",
          13000000000.0,
          29.5,
          -0.55,
          -1.83,
          "-",
          "-",
          22700000.0,
        ],
      },
      {
        2518: [
          "2518",
          "EM",
          30660000000.0,
          60.2,
          0.65,
          1.09,
          "-",
          "-",
          464790.0,
        ],
      },
      { 2528: ["2528", "EM", 360000000.0, 0.9, 0.0, 0.0, "-", "-", "-"] },
      {
        2552: [
          "2552",
          "EM",
          3900000000.0,
          3.7,
          0.02,
          0.54,
          "-",
          "-",
          1020000.0,
        ],
      },
      { 2558: ["2558", "EM", 1330000000.0, 1.38, 0.0, 0.0, 4.38, 8.79, "-"] },
      {
        2588: [
          "2588",
          "EM",
          46600000000.0,
          67.15,
          1.95,
          2.99,
          11.82,
          2.63,
          40960000.0,
        ],
      },
      {
        2599: [
          "2599",
          "EM",
          3310000000.0,
          1.09,
          0.02,
          1.87,
          0.87,
          18.35,
          829390.0,
        ],
      },
      {
        2600: [
          "2600",
          "EM",
          16760000000.000002,
          4.25,
          0.14,
          3.41,
          128.4,
          "-",
          178340000.0,
        ],
      },
      {
        2601: [
          "2601",
          "EM",
          67019999999.99999,
          24.15,
          0.5,
          2.11,
          7.82,
          6.51,
          208320000.0,
        ],
      },
      {
        2607: [
          "2607",
          "EM",
          14090000000.0,
          15.34,
          0.16,
          1.05,
          8.25,
          3.76,
          17890000.0,
        ],
      },
      { 2608: ["2608", "EM", 994810000.0, 0.39, 0.0, 0.0, 1.94, "-", "-"] },
      {
        2611: [
          "2611",
          "EM",
          16530000000.000002,
          11.88,
          0.06,
          0.51,
          8.4,
          5.66,
          9010000.0,
        ],
      },
      {
        2616: [
          "2616",
          "EM",
          7120000000.0,
          6.0,
          -0.03,
          -0.5,
          "-",
          "-",
          11280000.0,
        ],
      },
      {
        2618: [
          "2618",
          "EM",
          147160000000.0,
          23.8,
          0.65,
          2.81,
          "-",
          "-",
          111600000.0,
        ],
      },
      {
        2623: [
          "2623",
          "EM",
          625380000.0,
          0.119,
          -0.001,
          -0.83,
          8.88,
          "-",
          51310.0,
        ],
      },
      {
        2628: [
          "2628",
          "EM",
          103430000000.0,
          13.9,
          0.28,
          2.06,
          6.63,
          5.53,
          305860000.0,
        ],
      },
      {
        2633: ["2633", "EM", 1330000000.0, 0.69, 0.0, 0.0, 7.61, 3.91, 63160.0],
      },
      {
        2638: [
          "2638",
          "EM",
          69090000000.0,
          7.82,
          0.06,
          0.77,
          25.29,
          4.1,
          22870000.0,
        ],
      },
      {
        2660: [
          "2660",
          "EM",
          1450000000.0,
          1.43,
          0.01,
          0.7,
          5.58,
          4.2,
          1090000.0,
        ],
      },
      { 2663: ["2663", "EM", 216000000.0, 0.36, 0.0, 0.0, 4.31, 15.28, "-"] },
      {
        2666: [
          "2666",
          "EM",
          10660000000.0,
          5.64,
          0.01,
          0.18,
          5.02,
          5.5,
          5160000.0,
        ],
      },
      { 2668: ["2668", "EM", 780000000.0, 0.2, -0.002, -0.99, 27.4, "-", "-"] },
      {
        2669: [
          "2669",
          "EM",
          30170000000.0,
          9.18,
          0.17,
          1.89,
          43.1,
          0.78,
          25530000.0,
        ],
      },
      {
        2678: [
          "2678",
          "EM",
          9510000000.0,
          10.36,
          0.3,
          2.98,
          15.51,
          6.76,
          6830000.0,
        ],
      },
      {
        2680: [
          "2680",
          "EM",
          208000000.0,
          0.52,
          0.03,
          6.12,
          86.67,
          "-",
          508400.0,
        ],
      },
      {
        2682: [
          "2682",
          "EM",
          143000000.0,
          0.143,
          0.0,
          0.0,
          5.72,
          9.09,
          205260.0,
        ],
      },
      {
        2683: ["2683", "EM", 165490000.0, 0.405, 0.005, 1.25, 29.78, 9.88, "-"],
      },
      {
        2686: [
          "2686",
          "EM",
          5220000000.0,
          1.54,
          0.1,
          6.94,
          8.72,
          8.06,
          28710000.0,
        ],
      },
      {
        2688: [
          "2688",
          "EM",
          139230000000.0,
          123.2,
          -0.4,
          -0.32,
          18.67,
          2.44,
          301820000.0,
        ],
      },
      {
        2689: [
          "2689",
          "EM",
          37630000000.0,
          8.02,
          0.26,
          3.35,
          4.45,
          6.53,
          42570000.0,
        ],
      },
      {
        2696: [
          "2696",
          "EM",
          3790000000.0,
          23.2,
          -0.25,
          -1.07,
          "-",
          "-",
          372670.0,
        ],
      },
      {
        2698: [
          "2698",
          "EM",
          1010000000.0,
          2.45,
          0.05,
          2.08,
          12.1,
          2.94,
          2580000.0,
        ],
      },
      {
        2699: ["2699", "EM", 84530000.0, 0.045, 0.001, 2.27, "-", "-", 1820.0],
      },
      { 2700: ["2700", "EM", 234260000.0, 0.355, 0.0, 0.0, "-", "-", "-"] },
      {
        2708: [
          "2708",
          "EM",
          1680000000.0,
          3.06,
          0.01,
          0.33,
          "-",
          "-",
          1250000.0,
        ],
      },
      { 2718: ["2718", "EM", 312990000.0, 0.58, 0.0, 0.0, 19.27, "-", "-"] },
      {
        2722: [
          "2722",
          "EM",
          726120000.0,
          0.66,
          0.0,
          0.0,
          11.26,
          1.82,
          582020.0,
        ],
      },
      {
        2727: [
          "2727",
          "EM",
          6490000000.0,
          2.22,
          0.08,
          3.74,
          7.63,
          3.88,
          13950000.0,
        ],
      },
      { 2728: ["2728", "EM", 592510000.0, 0.133, 0.001, 0.76, 1.66, "-", "-"] },
      {
        2738: [
          "2738",
          "EM",
          1360000000.0,
          2.28,
          -0.22,
          -8.8,
          32.11,
          "-",
          779080.0,
        ],
      },
      {
        2768: [
          "2768",
          "EM",
          13300000000.0,
          2.71,
          0.0,
          0.0,
          2.81,
          5.72,
          917680.0,
        ],
      },
      {
        2772: [
          "2772",
          "EM",
          13320000000.0,
          3.72,
          0.05,
          1.36,
          3.01,
          13.68,
          6370000.0,
        ],
      },
      {
        2777: [
          "2777",
          "EM",
          13130000000.0,
          3.5,
          0.04,
          1.16,
          1.17,
          24.84,
          18640000.0,
        ],
      },
      {
        2778: [
          "2778",
          "R",
          23800000000.0,
          4.01,
          0.09,
          2.3,
          "-",
          6.17,
          5950000.0,
        ],
      },
      {
        2779: [
          "2779",
          "EM",
          2089999999.9999998,
          1.3,
          -0.03,
          -2.26,
          5.48,
          5.56,
          198300.0,
        ],
      },
      {
        2788: ["2788", "EM", 800010000.0, 0.98, 0.0, 0.0, "-", 3.57, 1050000.0],
      },
      {
        2789: [
          "2789",
          "EM",
          229720000.0,
          0.037,
          -0.003,
          -7.5,
          "-",
          "-",
          304750.0,
        ],
      },
      {
        2798: ["2798", "EM", 2720000000.0, 1.7, 0.0, 0.0, 6.68, 2.21, 385450.0],
      },
      {
        2799: [
          "2799",
          "EM",
          11070000000.0,
          0.41,
          -0.005,
          -1.2,
          "-",
          "-",
          22300000.0,
        ],
      },
      {
        2800: [
          "2800",
          "F",
          103750000000.0,
          24.7,
          0.72,
          3.0,
          "-",
          2.8,
          5400000000.0,
        ],
      },
      {
        2801: [
          "2801",
          "F",
          5450000000.0,
          26.52,
          0.74,
          2.87,
          "-",
          1.51,
          1510000.0,
        ],
      },
      {
        2802: [
          "2802",
          "F",
          136120000.0,
          62.52,
          1.72,
          2.83,
          "-",
          1.79,
          632260.0,
        ],
      },
      {
        2803: ["2803", "F", 588590000.0, 8.79, 0.14, 1.62, "-", 2.55, 43950.0],
      },
      {
        2804: ["2804", "F", 238510000.0, 102.0, 1.75, 1.75, "-", "-", 387760.0],
      },
      {
        2806: ["2806", "F", 769430000.0, 64.54, 2.18, 3.5, "-", "-", 638380.0],
      },
      {
        2807: ["2807", "F", 302660000.0, 59.78, 0.8, 1.36, "-", "-", 647700.0],
      },
      {
        2809: [
          "2809",
          "F",
          3220000000.0,
          140.25,
          2.35,
          1.7,
          "-",
          "-",
          1990000.0,
        ],
      },
      { 2810: ["2810", "F", 354180000.0, 73.6, 0.6, 0.82, "-", 1.92, 21340.0] },
      { 2811: ["2811", "F", 199530000.0, 19.8, 0.25, 1.28, "-", 0.49, "-"] },
      {
        2812: [
          "2812",
          "F",
          188040000.0,
          13.55,
          0.38,
          2.89,
          "-",
          "-",
          4099999.9999999995,
        ],
      },
      { 2813: ["2813", "F", 19840000.0, 132.05, 0.9, 0.69, "-", 2.04, "-"] },
      {
        2814: ["2814", "F", 94820000.0, 20.14, 0.54, 2.76, "-", "-", 1730000.0],
      },
      { 2817: ["2817", "F", 1090000000.0, 132.1, 0.15, 0.11, "-", "-", "-"] },
      {
        2819: ["2819", "F", 2270000000.0, 99.4, -0.3, -0.3, "-", 0.96, 69640.0],
      },
      {
        2820: ["2820", "F", 1480000000.0, 82.9, 1.6, 1.97, "-", "-", 2580000.0],
      },
      { 2821: ["2821", "F", 28300000000.0, 117.5, 0.05, 0.04, "-", 2.43, "-"] },
      {
        2822: [
          "2822",
          "F",
          12190000000.0,
          17.06,
          0.27,
          1.61,
          "-",
          2.12,
          79610000.0,
        ],
      },
      {
        2823: [
          "2823",
          "F",
          18040000000.0,
          17.56,
          0.3,
          1.74,
          "-",
          1.49,
          94600000.0,
        ],
      },
      {
        2824: ["2824", "F", 66209999.99999999, 61.58, 0.0, 0.0, "-", "-", "-"],
      },
      { 2825: ["2825", "F", 1590000000.0, 26.04, 0.74, 2.92, "-", "-", "-"] },
      {
        2826: ["2826", "F", 745560000.0, 71.26, 1.9, 2.74, "-", "-", 1890000.0],
      },
      {
        2827: [
          "2827",
          "F",
          1090000000.0,
          46.66,
          0.56,
          1.21,
          "-",
          "-",
          1090000.0,
        ],
      },
      {
        2828: [
          "2828",
          "F",
          24790000000.0,
          87.04,
          2.42,
          2.86,
          "-",
          2.12,
          3120000000.0,
        ],
      },
      {
        2829: ["2829", "F", 4050000000.0, 63.18, 0.08, 0.13, "-", "-", 51780.0],
      },
      { 2832: ["2832", "F", 41790000.0, 10.53, 0.15, 1.45, "-", "-", "-"] },
      {
        2833: [
          "2833",
          "F",
          45770000000.0,
          25.04,
          0.72,
          2.96,
          "-",
          2.73,
          14490000.0,
        ],
      },
      {
        2834: ["2834", "F", 365310000.0, 278.7, 4.7, 1.72, "-", "-", 4490000.0],
      },
      {
        2836: [
          "2836",
          "F",
          748920000.0,
          34.98,
          0.86,
          2.52,
          "-",
          "-",
          2140000.0,
        ],
      },
      { 2838: ["2838", "F", 116450000.0, 169.2, 7.2, 4.44, "-", 1.33, "-"] },
      {
        2839: [
          "2839",
          "F",
          1350000000.0,
          31.54,
          0.5,
          1.61,
          "-",
          "-",
          51500000.0,
        ],
      },
      {
        2840: [
          "2840",
          "F",
          457080000000.0,
          1315.0,
          14.0,
          1.08,
          "-",
          "-",
          8220000.000000001,
        ],
      },
      { 2843: ["2843", "F", 108860000.0, 18.38, 0.3, 1.66, "-", 2.02, "-"] },
      {
        2845: [
          "2845",
          "F",
          7100000000.0,
          161.0,
          2.6,
          1.64,
          "-",
          "-",
          51990000.0,
        ],
      },
      {
        2846: ["2846", "F", 471920000.0, 37.12, 0.46, 1.25, "-", 0.9, 899940.0],
      },
      { 2848: ["2848", "F", 752040000.0, 650.4, 0.0, 0.0, "-", "-", "-"] },
      {
        2849: ["2849", "F", 221500000.0, 57.5, 2.12, 3.83, "-", "-", 903500.0],
      },
      {
        2858: [
          "2858",
          "EM",
          8529999999.999999,
          1.31,
          0.05,
          3.97,
          "-",
          "-",
          766140.0,
        ],
      },
      { 2863: ["2863", "EM", 216540000.0, 0.33, 0.0, 0.0, 12.64, "-", "-"] },
      {
        2866: [
          "2866",
          "EM",
          5250000000.0,
          1.43,
          0.0,
          0.0,
          7.85,
          4.7,
          6750000.0,
        ],
      },
      {
        2869: [
          "2869",
          "EM",
          28680000000.0,
          8.84,
          0.28,
          3.27,
          32.25,
          2.26,
          49890000.0,
        ],
      },
      {
        2877: [
          "2877",
          "EM",
          5580000000.0,
          6.75,
          -0.25,
          -3.57,
          14.79,
          10.64,
          2130000.0,
        ],
      },
      {
        2878: [
          "2878",
          "EM",
          1910000000.0,
          0.77,
          0.0,
          0.0,
          21.15,
          1.04,
          4860000.0,
        ],
      },
      {
        2880: [
          "2880",
          "EM",
          3660000000.0,
          0.71,
          0.0,
          0.0,
          9.57,
          5.13,
          251830.0,
        ],
      },
      { 2882: ["2882", "EM", 71460000.0, 0.265, 0.0, 0.0, "-", "-", "-"] },
      {
        2883: [
          "2883",
          "EM",
          14140000000.0,
          7.81,
          0.2,
          2.63,
          11.69,
          2.64,
          73350000.0,
        ],
      },
      {
        2885: [
          "2885",
          "EM",
          162000000.0,
          0.405,
          -0.005,
          -1.22,
          5.96,
          3.33,
          1620.0,
        ],
      },
      {
        2886: [
          "2886",
          "EM",
          2120000000.0,
          1.57,
          0.02,
          1.29,
          5.41,
          5.03,
          280900.0,
        ],
      },
      {
        2888: [
          "2888",
          "EM",
          180430000000.0,
          58.6,
          2.7,
          4.83,
          72.87,
          1.58,
          202190000.0,
        ],
      },
      { 2892: ["2892", "EM", 772500000.0, 1.03, 0.03, 3.0, 5.83, "-", 8000.0] },
      {
        2899: [
          "2899",
          "EM",
          59540000000.0,
          10.38,
          0.42,
          4.22,
          35.16,
          1.4,
          180160000.0,
        ],
      },
      {
        2902: [
          "2902",
          "EM",
          185740000.0,
          0.435,
          0.05,
          12.99,
          "-",
          "-",
          47610.0,
        ],
      },
      { 2903: ["2903", "EM", "-", "-", "-", "-", "-", "-", "-"] },
      {
        2905: [
          "2905",
          "EM",
          1620000000.0,
          1.15,
          -0.53,
          -31.55,
          "-",
          "-",
          2470000.0,
        ],
      },
      { 2906: ["2906", "EM", "-", "-", "-", "-", "-", "-", "-"] },
      { 2907: ["2907", "EM", "-", 0.011, 0.001, 10.0, "-", "-", 399800.0] },
      {
        2908: [
          "2908",
          "EM",
          115340000.0,
          0.17,
          -0.02,
          -10.53,
          "-",
          "-",
          22850.0,
        ],
      },
      { 2910: ["2910", "-", "-", 0.021, 0.003, 16.67, "-", "-", 1070000.0] },
      { 2983: ["2983", "EM", 52970000.0, 0.23, 0.0, 0.0, "-", "-", "-"] },
      { 2996: ["2996", "EM", 522390000.0, 0.44, 0.0, 0.0, "-", "-", "-"] },
      { 2999: ["2999", "EM", "-", 0.06, 0.002, 3.45, "-", "-", 1600000.0] },
      {
        3001: [
          "3001",
          "F",
          156900000.0,
          231.25,
          -1.75,
          -0.75,
          "-",
          "-",
          1640000.0,
        ],
      },
      {
        3003: [
          "3003",
          "F",
          908430000.0,
          7.145,
          0.085,
          1.2,
          "-",
          "-",
          10380000.0,
        ],
      },
      {
        3005: ["3005", "F", 52750000.0, 22.22, 0.16, 0.73, "-", "-", 120250.0],
      },
      { 3007: ["3007", "F", 962580000.0, 283.4, 8.0, 2.9, "-", "-", "-"] },
      {
        3010: [
          "3010",
          "F",
          19870000000.0,
          60.9,
          1.46,
          2.46,
          "-",
          1.71,
          2270000.0,
        ],
      },
      {
        3011: ["3011", "F", 3050000000.0, 8030.55, -1.8, -0.02, "-", "-", "-"],
      },
      { 3012: ["3012", "F", 20920000.0, 20.76, 0.52, 2.57, "-", 3.2, "-"] },
      {
        3015: ["3015", "F", 973370000.0, 1763.5, 34.0, 1.97, "-", "-", 17630.0],
      },
      {
        3020: [
          "3020",
          "F",
          35630000000.0,
          1023.5,
          24.5,
          2.45,
          "-",
          "-",
          227880.0,
        ],
      },
      { 3021: ["3021", "F", 29870000.0, 7.495, 0.195, 2.67, "-", "-", 2980.0] },
      {
        3022: ["3022", "F", 108930000.0, 552.6, 12.6, 2.33, "-", 2.1, 33150.0],
      },
      { 3023: ["3023", "F", 50080000.0, 126.5, 1.85, 1.48, "-", "-", "-"] },
      { 3024: ["3024", "F", 25010000.0, 31.32, 0.04, 0.13, "-", "-", "-"] },
      {
        3031: ["3031", "F", 403430000.0, 12.32, 0.22, 1.82, "-", "-", 13550.0],
      },
      {
        3032: [
          "3032",
          "F",
          1430000000.0,
          5.54,
          0.145,
          2.69,
          "-",
          "-",
          12750000.0,
        ],
      },
      {
        3033: [
          "3033",
          "F",
          14490000000.0,
          5.51,
          0.17,
          3.18,
          "-",
          "-",
          385210000.0,
        ],
      },
      { 3036: ["3036", "F", 1380000000.0, 453.8, 11.9, 2.69, "-", "-", "-"] },
      {
        3037: ["3037", "F", 869100000.0, 25.06, 0.72, 2.96, "-", "-", 126990.0],
      },
      { 3040: ["3040", "F", 646390000.0, 34.28, 1.0, 3.0, "-", "-", 41020.0] },
      {
        3047: [
          "3047",
          "F",
          198660000.0,
          14.83,
          0.13,
          0.88,
          "-",
          "-",
          2300000.0,
        ],
      },
      {
        3050: ["3050", "F", 110650000.0, 59.58, 1.78, 3.08, "-", "-", 70420.0],
      },
      {
        3051: ["3051", "F", 22120000.0, 52.36, 1.34, 2.63, "-", "-", 340980.0],
      },
      { 3053: ["3053", "F", 1630000000.0, 1033.8, 0.05, 0.0, "-", "-", "-"] },
      {
        3058: ["3058", "F", 161220000.0, 49.24, 1.34, 2.8, "-", "-", 167410.0],
      },
      {
        3067: [
          "3067",
          "F",
          9700000000.0,
          11.65,
          0.33,
          2.92,
          "-",
          "-",
          140110000.0,
        ],
      },
      { 3069: ["3069", "F", 1000000000.0, 14.6, 0.3, 2.1, "-", "-", 703450.0] },
      {
        3070: ["3070", "F", 807810000.0, 26.2, 0.4, 1.55, "-", 5.69, 1400000.0],
      },
      { 3071: ["3071", "F", 226500000.0, 1002.0, 0.0, 0.0, "-", "-", "-"] },
      {
        3072: ["3072", "F", 143220000.0, 128.0, 3.3, 2.65, "-", "-", 1910000.0],
      },
      {
        3073: [
          "3073",
          "F",
          1380000000.0,
          50.94,
          1.24,
          2.49,
          "-",
          "-",
          2029999.9999999998,
        ],
      },
      { 3074: ["3074", "F", 598470000.0, 205.8, 5.6, 2.8, "-", 1.8, 348320.0] },
      {
        3076: ["3076", "F", 43660000.0, 7.495, 0.195, 2.67, "-", "-", 347670.0],
      },
      { 3077: ["3077", "F", 11700000.0, 3899.4, -0.6, -0.02, "-", 0.13, "-"] },
      { 3079: ["3079", "F", 97960000.0, 139.75, 0.15, 0.11, "-", "-", "-"] },
      { 3080: ["3080", "F", 278940000.0, 134.6, 0.05, 0.04, "-", 2.65, "-"] },
      {
        3081: [
          "3081",
          "F",
          1760000000.0,
          43.12,
          0.42,
          0.98,
          "-",
          "-",
          422530.0,
        ],
      },
      {
        3086: [
          "3086",
          "F",
          1660000000.0,
          29.82,
          0.5,
          1.71,
          "-",
          0.17,
          1090000.0,
        ],
      },
      {
        3087: ["3087", "F", 2900000000.0, 348.0, 1.0, 0.29, "-", "-", 410630.0],
      },
      {
        3088: [
          "3088",
          "F",
          807350000.0,
          7.035,
          0.195,
          2.85,
          "-",
          "-",
          3450000.0,
        ],
      },
      { 3091: ["3091", "F", 135780000.0, 96.64, 2.42, 2.57, "-", "-", 3860.0] },
      { 3093: ["3093", "F", 66720000.0, 13.55, 0.15, 1.12, "-", "-", "-"] },
      { 3096: ["3096", "F", 1030000000.0, 800.15, 0.0, 0.0, "-", "-", "-"] },
      {
        3097: ["3097", "F", 73620000.0, 5.13, 0.148, 2.97, "-", "-", 3800000.0],
      },
      { 3100: ["3100", "F", 31970000.0, 52.24, 0.0, 0.0, "-", "-", "-"] },
      {
        3108: ["3108", "F", 72110000.0, 11.15, 0.21, 1.92, "-", "-", 409220.0],
      },
      {
        3109: ["3109", "F", 95630000.0, 13.68, 0.21, 1.56, "-", "-", 965610.0],
      },
      {
        3110: [
          "3110",
          "F",
          320160000.0,
          26.52,
          0.44,
          1.69,
          "-",
          7.3,
          5730000.0,
        ],
      },
      {
        3111: [
          "3111",
          "F",
          563010000.0,
          2.918,
          0.04,
          1.39,
          "-",
          "-",
          6870000.0,
        ],
      },
      {
        3115: [
          "3115",
          "F",
          682050000.0,
          88.16,
          2.46,
          2.87,
          "-",
          2.11,
          4490000.0,
        ],
      },
      {
        3117: ["3117", "F", 26740000.0, 43.66, 1.14, 2.68, "-", "-", 436600.0],
      },
      {
        3118: [
          "3118",
          "F",
          64349999.99999999,
          20.06,
          0.17,
          0.85,
          "-",
          "-",
          1800000.0,
        ],
      },
      {
        3119: ["3119", "F", 156220000.0, 59.18, 0.98, 1.68, "-", "-", 171160.0],
      },
      { 3121: ["3121", "F", 8020000.0, 8.99, 0.12, 1.35, "-", 3.07, "-"] },
      { 3122: ["3122", "F", 205860000.0, 193.1, 0.0, 0.0, "-", "-", "-"] },
      { 3124: ["3124", "F", 35310000.0, 52.26, 1.42, 2.79, "-", "-", 5230.0] },
      {
        3125: ["3125", "F", 253880000.0, 62.14, 0.04, 0.06, "-", "-", 190530.0],
      },
      { 3127: ["3127", "F", 39190000.0, 19.47, 0.2, 1.04, "-", "-", "-"] },
      {
        3128: ["3128", "F", 179830000.0, 65.42, 0.06, 0.09, "-", 1.22, 32910.0],
      },
      { 3130: ["3130", "F", 81010000.0, 28.18, 0.44, 1.59, "-", 1.1, "-"] },
      {
        3132: ["3132", "F", 161870000.0, 14.8, 0.51, 3.57, "-", "-", 1390000.0],
      },
      {
        3134: [
          "3134",
          "F",
          141650000.0,
          9.83,
          0.095,
          0.98,
          "-",
          "-",
          1280000.0,
        ],
      },
      {
        3141: [
          "3141",
          "F",
          1320000000.0,
          15.59,
          -0.02,
          -0.13,
          "-",
          2.94,
          396560.0,
        ],
      },
      {
        3143: ["3143", "F", 92340000.0, 7.82, 0.22, 2.89, "-", 4.23, 835680.0],
      },
      {
        3145: ["3145", "F", 655520000.0, 11.08, 0.3, 2.78, "-", 2.69, 11080.0],
      },
      {
        3147: [
          "3147",
          "F",
          725880000.0,
          12.7,
          0.17,
          1.36,
          "-",
          "-",
          4110000.0000000005,
        ],
      },
      {
        3149: ["3149", "F", 11140000.0, 15.96, 0.14, 0.88, "-", 1.54, 19150.0],
      },
      {
        3151: [
          "3151",
          "F",
          467270000.0,
          9.89,
          0.185,
          1.91,
          "-",
          "-",
          1600000.0,
        ],
      },
      { 3157: ["3157", "F", 48810000.0, 23.52, 0.02, 0.09, "-", "-", "-"] },
      {
        3160: ["3160", "F", 399110000.0, 13.28, 0.17, 1.3, "-", 1.31, 319200.0],
      },
      { 3162: ["3162", "F", 43410000.0, 6.59, 0.16, 2.49, "-", "-", 81550.0] },
      {
        3163: [
          "3163",
          "F",
          32299999.999999996,
          36.44,
          1.18,
          3.35,
          "-",
          "-",
          "-",
        ],
      },
      {
        3165: [
          "3165",
          "F",
          220930000.0,
          15.57,
          0.05,
          0.32,
          "-",
          1.24,
          177450.0,
        ],
      },
      { 3166: ["3166", "F", 37740000.0, 42.6, 0.84, 2.01, "-", "-", "-"] },
      {
        3167: ["3167", "F", 475020000.0, 76.84, 3.84, 5.26, "-", "-", 207070.0],
      },
      {
        3173: [
          "3173",
          "F",
          2310000000.0,
          11.25,
          0.15,
          1.35,
          "-",
          "-",
          6060000.0,
        ],
      },
      {
        3174: ["3174", "F", 51150000.0, 4.034, 0.084, 2.13, "-", "-", 203880.0],
      },
      {
        3175: [
          "3175",
          "F",
          1300000000.0,
          6.23,
          0.18,
          2.98,
          "-",
          "-",
          18130000.0,
        ],
      },
      { 3176: ["3176", "F", 79730000.0, 10.28, 0.28, 2.8, "-", "-", "-"] },
      { 3181: ["3181", "F", 72530000.0, 112.75, 3.25, 2.97, "-", "-", "-"] },
      { 3182: ["3182", "F", 10820000.0, 13.97, 0.53, 3.94, "-", "-", "-"] },
      { 3185: ["3185", "F", 134350000.0, 48.32, 0.64, 1.34, "-", "-", "-"] },
      { 3186: ["3186", "F", 20190000.0, 52.84, 1.6, 3.12, "-", "-", 10480.0] },
      {
        3187: [
          "3187",
          "F",
          212200000.0,
          20.36,
          0.24,
          1.19,
          "-",
          5.24,
          3420000.0,
        ],
      },
      {
        3188: [
          "3188",
          "F",
          19310000000.0,
          57.86,
          0.8,
          1.4,
          "-",
          0.64,
          93240000.0,
        ],
      },
      {
        3191: [
          "3191",
          "F",
          1070000000.0000001,
          58.0,
          0.7,
          1.22,
          "-",
          "-",
          1010000.0,
        ],
      },
      { 3193: ["3193", "F", 13540000.0, 7.58, 0.05, 0.66, "-", "-", 250670.0] },
      {
        3194: ["3194", "F", 20850000.0, 14.89, 0.24, 1.64, "-", "-", 1890000.0],
      },
      { 3198: ["3198", "F", 35880000.0, 98.84, 0.64, 0.65, "-", "-", "-"] },
      {
        3199: [
          "3199",
          "F",
          6470000000.0,
          127.6,
          0.25,
          0.2,
          "-",
          3.47,
          596850.0,
        ],
      },
      {
        3300: [
          "3300",
          "EM",
          3160000000.0,
          1.73,
          0.06,
          3.59,
          "-",
          2.89,
          5040000.0,
        ],
      },
      {
        3301: [
          "3301",
          "EM",
          5670000000.0,
          3.37,
          0.15,
          4.66,
          2.02,
          14.84,
          6580000.0,
        ],
      },
      {
        3302: ["3302", "EM", 681090000.0, 0.74, 0.02, 2.78, "-", 0.99, 41020.0],
      },
      {
        3303: [
          "3303",
          "EM",
          1440000000.0,
          0.86,
          0.03,
          3.61,
          8.18,
          25.58,
          518039.99999999994,
        ],
      },
      {
        3306: [
          "3306",
          "EM",
          6190000000.0,
          11.94,
          0.44,
          3.83,
          7.71,
          12.56,
          8289999.999999999,
        ],
      },
      {
        3308: [
          "3308",
          "EM",
          9190000000.0,
          5.54,
          -0.26,
          -4.48,
          5.01,
          10.01,
          483420.0,
        ],
      },
      {
        3309: [
          "3309",
          "EM",
          6740000000.0,
          5.46,
          0.13,
          2.44,
          "-",
          0.37,
          10780000.0,
        ],
      },
      {
        3311: [
          "3311",
          "EM",
          47850000000.0,
          9.5,
          0.32,
          3.49,
          7.97,
          4.11,
          23730000.0,
        ],
      },
      {
        3313: ["3313", "EM", 161640000.0, 0.037, 0.0, 0.0, "-", "-", 186560.0],
      },
      {
        3315: [
          "3315",
          "EM",
          1710000000.0,
          2.08,
          -0.04,
          -1.89,
          10.0,
          7.93,
          1190000.0,
        ],
      },
      {
        3316: [
          "3316",
          "EM",
          6810000000.0,
          24.65,
          1.85,
          8.11,
          26.51,
          3.83,
          7590000.0,
        ],
      },
      {
        3318: [
          "3318",
          "EM",
          4150000000.0000005,
          3.85,
          -0.02,
          -0.52,
          27.84,
          "-",
          1160000.0,
        ],
      },
      {
        3319: [
          "3319",
          "EM",
          22600000000.0,
          15.92,
          0.8,
          5.29,
          10.25,
          3.94,
          55610000.0,
        ],
      },
      {
        3320: [
          "3320",
          "EM",
          24310000000.0,
          3.87,
          0.07,
          1.84,
          7.37,
          3.1,
          20960000.0,
        ],
      },
      {
        3321: [
          "3321",
          "EM",
          1310000000.0,
          2.6,
          0.07,
          2.77,
          29.28,
          "-",
          19400.0,
        ],
      },
      {
        3322: [
          "3322",
          "EM",
          443110000.0,
          0.345,
          0.005,
          1.47,
          "-",
          "-",
          190570.0,
        ],
      },
      {
        3323: [
          "3323",
          "EM",
          47310000000.0,
          10.38,
          0.3,
          2.98,
          5.94,
          5.32,
          104030000.0,
        ],
      },
      {
        3326: [
          "3326",
          "EM",
          398250000.0,
          0.295,
          0.005,
          1.72,
          5.2,
          6.78,
          18580.0,
        ],
      },
      {
        3328: [
          "3328",
          "EM",
          185210000000.0,
          5.29,
          0.07,
          1.34,
          4.55,
          7.04,
          107600000.0,
        ],
      },
      {
        3329: [
          "3329",
          "EM",
          3630000000.0,
          1.33,
          0.05,
          3.91,
          4.31,
          12.03,
          965520.0,
        ],
      },
      { 3330: ["3330", "EM", 315110000.0, 1.06, 0.0, 0.0, 8.65, "-", 65740.0] },
      {
        3331: [
          "3331",
          "EM",
          25640000000.0,
          21.35,
          0.45,
          2.15,
          15.64,
          2.34,
          16480000.0,
        ],
      },
      { 3332: ["3332", "EM", 33780000.0, 0.124, 0.0, 0.0, "-", "-", "-"] },
      {
        3333: [
          "3333",
          "EM",
          22310000000.0,
          1.69,
          0.03,
          1.81,
          2.36,
          10.77,
          26340000.0,
        ],
      },
      {
        3336: [
          "3336",
          "EM",
          1920000000.0,
          1.6,
          0.02,
          1.27,
          10.58,
          6.25,
          175000.0,
        ],
      },
      {
        3337: [
          "3337",
          "EM",
          1470000000.0,
          0.49,
          0.015,
          3.16,
          "-",
          "-",
          1500000.0,
        ],
      },
      {
        3339: [
          "3339",
          "EM",
          9920000000.0,
          2.32,
          0.05,
          2.2,
          4.3,
          14.22,
          7900000.0,
        ],
      },
      { 3344: ["3344", "EM", 107070000.0, 0.017, 0.0, 0.0, "-", "-", "-"] },
      {
        3347: [
          "3347",
          "EM",
          9870000000.0,
          80.2,
          -0.95,
          -1.17,
          30.93,
          0.45,
          110840000.0,
        ],
      },
      { 3348: ["3348", "EM", 520000000.0, 1.04, 0.0, 0.0, 3.37, 9.11, "-"] },
      {
        3360: [
          "3360",
          "EM",
          29460000000.0,
          6.83,
          0.16,
          2.4,
          4.86,
          5.27,
          4170000.0,
        ],
      },
      { 3363: ["3363", "EM", 345000000.0, 0.69, 0.0, 0.0, 5.37, 5.12, "-"] },
      {
        3366: ["3366", "EM", 935450000.0, 1.25, -0.02, -1.57, "-", "-", 5000.0],
      },
      { 3368: ["3368", "EM", 500560000.0, 0.19, 0.0, 0.0, "-", "-", 212020.0] },
      {
        3369: [
          "3369",
          "EM",
          1050000000.0,
          1.27,
          0.02,
          1.6,
          6.07,
          6.06,
          70880.0,
        ],
      },
      {
        3377: [
          "3377",
          "EM",
          15000000000.0,
          1.97,
          0.06,
          3.14,
          4.43,
          7.36,
          12730000.0,
        ],
      },
      {
        3378: [
          "3378",
          "EM",
          848560000.0,
          0.86,
          0.01,
          1.18,
          7.01,
          3.94,
          138800.0,
        ],
      },
      {
        3380: [
          "3380",
          "EM",
          26790000000.0,
          4.71,
          -0.14,
          -2.89,
          1.71,
          22.72,
          89440000.0,
        ],
      },
      {
        3382: [
          "3382",
          "EM",
          3870000000.0,
          0.63,
          -0.01,
          -1.56,
          6.1,
          6.56,
          2080000.0,
        ],
      },
      {
        3383: [
          "3383",
          "EM",
          16370000000.000002,
          4.18,
          0.06,
          1.46,
          1.45,
          26.32,
          21370000.0,
        ],
      },
      { 3389: ["3389", "EM", 1200000000.0, 0.27, 0.0, 0.0, "-", "-", 48560.0] },
      {
        3390: ["3390", "EM", 1060000000.0, 1.33, 0.0, 0.0, "-", 1.5, 1020000.0],
      },
      {
        3393: [
          "3393",
          "EM",
          3140000000.0,
          3.16,
          -0.01,
          -0.32,
          11.49,
          6.33,
          1400000.0,
        ],
      },
      { 3395: ["3395", "EM", 194960000.0, 0.49, 0.0, 0.0, "-", "-", "-"] },
      {
        3396: [
          "3396",
          "EM",
          14290000000.0,
          11.24,
          0.42,
          3.88,
          5.78,
          3.88,
          7570000.0,
        ],
      },
      { 3398: ["3398", "EM", 671940000.0, 0.32, 0.0, 0.0, 4.6, "-", "-"] },
      {
        3399: [
          "3399",
          "EM",
          219420000.0,
          1.06,
          -0.03,
          -2.75,
          "-",
          "-",
          67740.0,
        ],
      },
      {
        3600: [
          "3600",
          "EM",
          4440000000.0,
          4.65,
          -0.01,
          -0.21,
          41.52,
          4.19,
          7270000.0,
        ],
      },
      {
        3601: ["3601", "EM", 438470000.0, 1.63, 0.0, 0.0, 5.14, "-", 100970.0],
      },
      {
        3603: [
          "3603",
          "EM",
          885000000.0,
          0.59,
          -0.01,
          -1.67,
          22.87,
          "-",
          2250000.0,
        ],
      },
      {
        3606: [
          "3606",
          "EM",
          26090000000.0,
          43.0,
          1.1,
          2.63,
          35.43,
          2.12,
          39840000.0,
        ],
      },
      {
        3608: ["3608", "EM", 593350000.0, 0.81, 0.06, 8.0, 6.98, 3.7, 168020.0],
      },
      {
        3613: [
          "3613",
          "EM",
          9960000000.0,
          11.9,
          -0.32,
          -2.62,
          18.36,
          2.02,
          1750000.0,
        ],
      },
      {
        3616: [
          "3616",
          "EM",
          1290000000.0,
          1.08,
          0.0,
          0.0,
          3.87,
          5.56,
          161680.0,
        ],
      },
      {
        3618: [
          "3618",
          "EM",
          7360000000.0,
          2.93,
          0.08,
          2.81,
          3.38,
          9.17,
          9780000.0,
        ],
      },
      { 3623: ["3623", "EM", 574980000.0, 1.05, 0.0, 0.0, "-", "-", "-"] },
      { 3626: ["3626", "EM", 90160000.0, 0.49, 0.0, 0.0, "-", "-", "-"] },
      { 3628: ["3628", "EM", 192960000.0, 0.24, 0.0, 0.0, "-", "-", "-"] },
      {
        3633: [
          "3633",
          "EM",
          23230000000.0,
          8.2,
          0.08,
          0.99,
          20.53,
          1.22,
          8580000.0,
        ],
      },
      {
        3636: ["3636", "EM", 403400000.0, 4.51, 0.17, 3.92, "-", "-", 127990.0],
      },
      {
        3638: [
          "3638",
          "EM",
          197310000.0,
          0.045,
          -0.001,
          -2.17,
          "-",
          "-",
          27550.0,
        ],
      },
      {
        3639: ["3639", "EM", 3020000000.0, 1.17, -0.02, -1.68, 14.96, "-", "-"],
      },
      {
        3658: [
          "3658",
          "EM",
          1760000000.0,
          2.17,
          0.04,
          1.88,
          "-",
          "-",
          113980.0,
        ],
      },
      {
        3662: [
          "3662",
          "EM",
          1860000000.0,
          2.57,
          -0.03,
          -1.15,
          6.34,
          6.46,
          1270000.0,
        ],
      },
      { 3666: ["3666", "EM", 157120000.0, 0.071, 0.0, 0.0, "-", "-", "-"] },
      {
        3668: [
          "3668",
          "EM",
          24030000000.0,
          18.2,
          -0.28,
          -1.52,
          "-",
          "-",
          55640.0,
        ],
      },
      {
        3669: [
          "3669",
          "EM",
          20160000000.0,
          10.24,
          0.27,
          2.71,
          10.18,
          6.02,
          20190000.0,
        ],
      },
      {
        3678: [
          "3678",
          "EM",
          227220000.0,
          0.91,
          0.0,
          0.0,
          10.61,
          6.63,
          121010.0,
        ],
      },
      {
        3680: [
          "3680",
          "EM",
          2890000000.0,
          7.11,
          0.01,
          0.14,
          "-",
          "-",
          13800000.0,
        ],
      },
      {
        3681: ["3681", "EM", 2700000000.0, 2.69, 0.06, 2.28, "-", "-", 79560.0],
      },
      {
        3683: [
          "3683",
          "EM",
          1290000000.0,
          1.36,
          0.01,
          0.74,
          "-",
          "-",
          580350.0,
        ],
      },
      {
        3686: ["3686", "EM", 487560000.0, 0.48, 0.0, 0.0, 3.22, 5.63, 19050.0],
      },
      {
        3688: [
          "3688",
          "EM",
          1370000000.0,
          0.97,
          -0.06,
          -5.83,
          "-",
          7.22,
          11040.0,
        ],
      },
      { 3689: ["3689", "EM", 205920000.0, 2.44, -0.02, -0.81, "-", "-", "-"] },
      {
        3690: [
          "3690",
          "EM",
          1391630000000.0,
          226.8,
          7.2,
          3.28,
          239.8,
          "-",
          2700000000.0,
        ],
      },
      {
        3692: [
          "3692",
          "EM",
          97480000000.0,
          16.46,
          0.42,
          2.62,
          32.15,
          0.47,
          44290000.0,
        ],
      },
      {
        3698: [
          "3698",
          "EM",
          8970000000.0,
          2.58,
          0.03,
          1.18,
          3.05,
          "-",
          43900.0,
        ],
      },
      {
        3699: ["3699", "EM", 225110000.0, 0.51, 0.0, 0.0, 5.62, 6.51, 40800.0],
      },
      {
        3700: [
          "3700",
          "EM",
          4090000000.0,
          2.04,
          0.03,
          1.49,
          17.29,
          "-",
          22620000.0,
        ],
      },
      { 3708: ["3708", "EM", 721620000.0, 0.129, 0.0, 0.0, "-", "-", "-"] },
      {
        3709: [
          "3709",
          "EM",
          9010000000.0,
          12.8,
          0.3,
          2.4,
          16.17,
          2.97,
          9520000.0,
        ],
      },
      {
        3718: [
          "3718",
          "EM",
          2080000000.0,
          0.58,
          0.04,
          7.41,
          4.85,
          "-",
          3160000.0,
        ],
      },
      {
        3728: [
          "3728",
          "EM",
          186390000.0,
          0.184,
          0.017,
          10.18,
          16.14,
          3.53,
          10700.0,
        ],
      },
      {
        3737: ["3737", "EM", 863600000.0, 1.0, 0.04, 4.17, 5.07, "-", 11670.0],
      },
      {
        3738: [
          "3738",
          "EM",
          11750000000.0,
          5.55,
          0.27,
          5.11,
          117.34,
          "-",
          47610000.0,
        ],
      },
      {
        3759: [
          "3759",
          "EM",
          13340000000.0,
          99.55,
          0.8,
          0.81,
          57.15,
          0.35,
          65450000.0,
        ],
      },
      {
        3768: [
          "3768",
          "EM",
          649440000.0,
          1.91,
          0.02,
          1.06,
          4.8,
          10.8,
          242600.0,
        ],
      },
      {
        3773: [
          "3773",
          "EM",
          282200000.0,
          0.68,
          -0.01,
          -1.45,
          7.98,
          "-",
          130919.99999999999,
        ],
      },
      {
        3778: [
          "3778",
          "EM",
          607380000.0,
          0.485,
          0.01,
          2.11,
          8.26,
          7.22,
          1940.0,
        ],
      },
      {
        3788: [
          "3788",
          "EM",
          3010000000.0,
          1.54,
          0.04,
          2.67,
          6.3,
          9.09,
          1810000.0,
        ],
      },
      {
        3789: [
          "3789",
          "EM",
          181200000.0,
          0.151,
          0.009,
          6.34,
          4.89,
          "-",
          86290.0,
        ],
      },
      {
        3798: [
          "3798",
          "EM",
          2140000000.0000002,
          1.67,
          0.05,
          3.09,
          4.53,
          "-",
          761300.0,
        ],
      },
      {
        3799: [
          "3799",
          "EM",
          59150000000.0,
          4.32,
          -0.18,
          -4.0,
          13.08,
          3.98,
          27560000.0,
        ],
      },
      {
        3800: [
          "3800",
          "EM",
          64489999999.99999,
          2.38,
          0.04,
          1.71,
          "-",
          "-",
          142430000.0,
        ],
      },
      {
        3808: [
          "3808",
          "EM",
          32409999999.999996,
          11.74,
          0.2,
          1.73,
          4.04,
          8.86,
          20630000.0,
        ],
      },
      {
        3813: [
          "3813",
          "EM",
          6710000000.0,
          1.26,
          0.01,
          0.8,
          18.56,
          "-",
          885650.0,
        ],
      },
      { 3816: ["3816", "EM", 159000000.0, 0.265, 0.0, 0.0, 8.86, "-", "-"] },
      {
        3818: [
          "3818",
          "EM",
          3940000000.0,
          0.67,
          0.02,
          3.08,
          1.83,
          9.1,
          2049999.9999999998,
        ],
      },
      {
        3822: [
          "3822",
          "EM",
          142800000.0,
          0.085,
          -0.001,
          -1.16,
          "-",
          "-",
          8500.0,
        ],
      },
      { 3828: ["3828", "EM", 293700000.0, 0.4, 0.0, 0.0, 3.69, 3.75, "-"] },
      { 3830: ["3830", "EM", 175000000.0, 0.175, 0.0, 0.0, 26.52, "-", "-"] },
      {
        3833: [
          "3833",
          "EM",
          963930000.0,
          1.27,
          0.05,
          4.1,
          15.74,
          "-",
          1090000.0,
        ],
      },
      {
        3836: [
          "3836",
          "EM",
          6760000000.0,
          4.33,
          0.03,
          0.7,
          13.84,
          1.82,
          3510000.0,
        ],
      },
      {
        3838: [
          "3838",
          "EM",
          1040000000.0,
          0.175,
          0.011,
          6.71,
          4.68,
          5.14,
          2000000.0,
        ],
      },
      {
        3839: ["3839", "EM", 327370000.0, 1.36, 0.01, 0.74, 2.74, "-", 93390.0],
      },
      {
        3848: [
          "3848",
          "EM",
          1000000000.0,
          6.44,
          0.09,
          1.42,
          "-",
          "-",
          890510.0,
        ],
      },
      {
        3860: [
          "3860",
          "EM",
          715000000.0,
          1.43,
          0.02,
          1.42,
          110.0,
          "-",
          49550.0,
        ],
      },
      { 3866: ["3866", "EM", 8820000000.0, 3.85, 0.0, 0.0, 8.03, 5.48, "-"] },
      {
        3868: [
          "3868",
          "EM",
          27720000000.0,
          3.9,
          0.0,
          0.0,
          29.02,
          4.08,
          20110000.0,
        ],
      },
      { 3869: ["3869", "EM", 1330000000.0, 9.67, 0.0, 0.0, "-", "-", "-"] },
      {
        3877: [
          "3877",
          "EM",
          7240000000.0,
          1.18,
          0.06,
          5.36,
          6.53,
          7.63,
          1140000.0,
        ],
      },
      { 3878: ["3878", "EM", 237400000.0, 0.495, -0.005, -1.0, "-", "-", "-"] },
      {
        3882: [
          "3882",
          "EM",
          158150000.0,
          0.166,
          -0.019,
          -10.27,
          "-",
          "-",
          7120.0,
        ],
      },
      {
        3883: [
          "3883",
          "EM",
          4590000000.0,
          1.55,
          -0.01,
          -0.64,
          0.6,
          59.03,
          20100000.0,
        ],
      },
      {
        3886: ["3886", "EM", 2820000000.0, 0.375, 0.0, 0.0, "-", "-", 15190.0],
      },
      {
        3888: [
          "3888",
          "EM",
          49390000000.0,
          36.05,
          1.3,
          3.74,
          4.15,
          0.84,
          178840000.0,
        ],
      },
      {
        3889: [
          "3889",
          "EM",
          137480000.0,
          0.09,
          -0.005,
          -5.26,
          "-",
          "-",
          76250.0,
        ],
      },
      {
        3893: [
          "3893",
          "EM",
          79920000.0,
          0.111,
          -0.004,
          -3.48,
          "-",
          "-",
          799780.0,
        ],
      },
      {
        3898: [
          "3898",
          "EM",
          23230000000.0,
          42.45,
          1.65,
          4.04,
          17.18,
          1.29,
          67090000.0,
        ],
      },
      {
        3899: [
          "3899",
          "EM",
          19040000000.0,
          9.39,
          -0.11,
          -1.16,
          27.11,
          1.49,
          31390000.0,
        ],
      },
      {
        3900: [
          "3900",
          "EM",
          33500000000.0,
          13.4,
          0.56,
          4.36,
          10.81,
          3.08,
          20360000.0,
        ],
      },
      { 3903: ["3903", "EM", 573300000.0, 0.49, 0.01, 2.08, 12.76, 7.35, "-"] },
      {
        3908: [
          "3908",
          "EM",
          40450000000.0,
          21.25,
          0.2,
          0.95,
          11.34,
          1.02,
          65970000.0,
        ],
      },
      {
        3913: [
          "3913",
          "EM",
          7080000000.0,
          3.51,
          0.09,
          2.63,
          15.65,
          2.08,
          17810000.0,
        ],
      },
      {
        3918: [
          "3918",
          "EM",
          28430000000.0,
          6.55,
          0.35,
          5.65,
          35.89,
          2.22,
          8359999.999999999,
        ],
      },
      {
        3919: ["3919", "EM", 74880000.0, 0.208, -0.002, -0.95, 4.32, "-", "-"],
      },
      { 3928: ["3928", "EM", 460800000.0, 0.96, 0.0, 0.0, "-", "-", "-"] },
      {
        3933: [
          "3933",
          "EM",
          8340000000.0,
          4.57,
          0.15,
          3.39,
          9.8,
          3.17,
          4340000.0,
        ],
      },
      {
        3938: ["3938", "EM", 98640000.0, 0.243, -0.005, -2.02, 6.12, "-", "-"],
      },
      {
        3939: [
          "3939",
          "EM",
          1360000000.0,
          1.65,
          -0.18,
          -9.84,
          11.71,
          2.16,
          34240.0,
        ],
      },
      {
        3948: [
          "3948",
          "EM",
          2320000000.0,
          7.12,
          -0.01,
          -0.14,
          "-",
          3.72,
          992610.0,
        ],
      },
      {
        3958: [
          "3958",
          "EM",
          6420000000.0,
          6.26,
          0.19,
          3.13,
          14.07,
          4.81,
          4980000.0,
        ],
      },
      { 3963: ["3963", "EM", 65170000.0, 0.158, -0.001, -0.63, "-", "-", "-"] },
      {
        3968: [
          "3968",
          "EM",
          307590000000.0,
          67.0,
          2.1,
          3.24,
          14.97,
          2.25,
          845920000.0,
        ],
      },
      {
        3969: [
          "3969",
          "EM",
          5490000000.0,
          2.79,
          0.04,
          1.45,
          6.99,
          8.7,
          4430000.0,
        ],
      },
      {
        3978: [
          "3978",
          "EM",
          262630000.0,
          0.31,
          0.005,
          1.64,
          1.6,
          17.74,
          112310.0,
        ],
      },
      {
        3983: [
          "3983",
          "EM",
          3980000000.0,
          2.25,
          0.13,
          6.13,
          11.85,
          4.29,
          30840000.0,
        ],
      },
      {
        3988: [
          "3988",
          "EM",
          255880000000.0,
          3.06,
          0.03,
          0.99,
          4.28,
          7.76,
          899710000.0,
        ],
      },
      {
        3989: [
          "3989",
          "EM",
          2310000000.0,
          0.162,
          -0.003,
          -1.82,
          4.22,
          "-",
          310690.0,
        ],
      },
      {
        3990: [
          "3990",
          "EM",
          14540000000.0,
          11.78,
          0.32,
          2.79,
          2.84,
          13.58,
          4580000.0,
        ],
      },
      { 3991: ["3991", "EM", 1040000000.0, 0.72, 0.0, 0.0, 5.36, 6.94, "-"] },
      {
        3993: [
          "3993",
          "EM",
          16399999999.999998,
          4.17,
          0.21,
          5.3,
          32.73,
          0.95,
          58150000.0,
        ],
      },
      {
        3996: [
          "3996",
          "EM",
          11850000000.0,
          1.28,
          0.04,
          3.23,
          7.16,
          2.13,
          10190000.0,
        ],
      },
      {
        3997: [
          "3997",
          "EM",
          166840000.0,
          1.3,
          -0.2,
          -13.33,
          17.43,
          3.85,
          2600.0,
        ],
      },
      {
        3998: [
          "3998",
          "EM",
          41460000000.0,
          3.81,
          0.02,
          0.53,
          20.11,
          3.81,
          46290000.0,
        ],
      },
      { 3999: ["3999", "EM", 680840000.0, 0.67, 0.0, 0.0, 2.85, "-", "-"] },
      { 4332: ["4332", "-", 1128380000000.0, "-", 0.0, 0.0, "-", "-", "-"] },
      { 4333: ["4333", "-", 1605270000000.0, "-", 0.0, 0.0, "-", "-", "-"] },
      { 4335: ["4335", "-", 1567840000000.0, "-", 0.0, 0.0, "-", "-", "-"] },
      { 4336: ["4336", "-", 655270000000.0, "-", 0.0, 0.0, "-", "-", "-"] },
      { 4337: ["4337", "-", 70240000000.0, "-", 0.0, 0.0, "-", "-", "-"] },
      { 4338: ["4338", "-", 7662810000000.0, "-", 0.0, 0.0, "-", "-", "-"] },
      { 4610: ["4610", "EM", "-", "-", 0.0, 0.0, "-", "-", "-"] },
      { 4611: ["4611", "EM", "-", "-", 0.0, 0.0, "-", "-", "-"] },
      { 4612: ["4612", "EM", "-", "-", 0.0, 0.0, "-", "-", "-"] },
      { 4613: ["4613", "EM", "-", "-", 0.0, 0.0, "-", "-", "-"] },
      { 4614: ["4614", "EM", "-", "-", 0.0, 0.0, "-", "-", "-"] },
      { 4615: ["4615", "EM", "-", "-", 0.0, 0.0, "-", "-", "-"] },
      { 4616: ["4616", "EM", "-", "-", 0.0, 0.0, "-", "-", "-"] },
      { 4617: ["4617", "EM", "-", "-", 0.0, 0.0, "-", "-", "-"] },
      { 4618: ["4618", "EM", "-", "-", 0.0, 0.0, "-", "-", "-"] },
      { 4619: ["4619", "EM", "-", "-", 0.0, 0.0, "-", "-", "-"] },
      { 4620: ["4620", "EM", "-", "-", 0.0, 0.0, "-", "-", "-"] },
      { 4621: ["4621", "EM", "-", "-", 0.0, 0.0, "-", "-", "-"] },
      {
        6030: [
          "6030",
          "EM",
          54750000000.0,
          20.9,
          0.15,
          0.72,
          15.61,
          2.21,
          100530000.0,
        ],
      },
      {
        6033: [
          "6033",
          "EM",
          1390000000.0,
          3.45,
          0.0,
          0.0,
          10.3,
          7.83,
          1360000.0,
        ],
      },
      {
        6036: ["6036", "EM", 318000000.0, 0.315, 0.0, 0.0, "-", "-", 1000000.0],
      },
      {
        6038: [
          "6038",
          "EM",
          178000000.0,
          0.178,
          -0.002,
          -1.11,
          7.64,
          9.27,
          "-",
        ],
      },
      {
        6049: [
          "6049",
          "EM",
          9260000000.0,
          60.45,
          2.4,
          4.13,
          42.04,
          0.86,
          20000000.0,
        ],
      },
      {
        6055: [
          "6055",
          "EM",
          10470000000.0,
          15.14,
          0.14,
          0.93,
          110.03,
          0.26,
          2480000.0,
        ],
      },
      { 6058: ["6058", "EM", 716000000.0, 0.179, 0.0, 0.0, "-", "-", "-"] },
      {
        6060: [
          "6060",
          "EM",
          39960000000.0,
          28.15,
          1.55,
          5.83,
          63.27,
          "-",
          110660000.0,
        ],
      },
      {
        6063: [
          "6063",
          "EM",
          144000000.0,
          0.072,
          -0.008,
          -10.0,
          6.55,
          13.89,
          13820.0,
        ],
      },
      {
        6066: [
          "6066",
          "EM",
          11520000000.0,
          9.14,
          0.38,
          4.34,
          6.51,
          4.8,
          41810000.0,
        ],
      },
      {
        6068: [
          "6068",
          "EM",
          1280000000.0,
          0.59,
          0.07,
          13.46,
          "-",
          35.25,
          673360.0,
        ],
      },
      {
        6069: [
          "6069",
          "EM",
          7730000000.0,
          7.7,
          0.29,
          3.91,
          17.75,
          0.82,
          15470000.0,
        ],
      },
      {
        6078: [
          "6078",
          "EM",
          21380000000.0,
          34.6,
          -0.45,
          -1.28,
          77.34,
          0.42,
          47320000.0,
        ],
      },
      { 6080: ["6080", "EM", 108310000.0, 0.116, 0.0, 0.0, "-", "-", "-"] },
      {
        6083: [
          "6083",
          "EM",
          195710000.0,
          0.39,
          -0.015,
          -3.7,
          9.44,
          7.69,
          63140.0,
        ],
      },
      {
        6088: [
          "6088",
          "EM",
          9910000000.0,
          1.39,
          0.03,
          2.21,
          28.08,
          "-",
          1170000.0,
        ],
      },
      { 6090: ["6090", "EM", 1580000000.0, 1.89, 0.0, 0.0, 15.92, "-", "-"] },
      {
        6093: [
          "6093",
          "EM",
          2300000000.0,
          4.12,
          0.0,
          0.0,
          27.39,
          "-",
          123360.0,
        ],
      },
      {
        6098: [
          "6098",
          "EM",
          164760000000.0,
          48.95,
          3.1,
          6.76,
          42.47,
          0.54,
          400710000.0,
        ],
      },
      {
        6099: [
          "6099",
          "EM",
          14300000000.0,
          11.22,
          0.28,
          2.56,
          9.01,
          4.74,
          2029999.9999999998,
        ],
      },
      {
        6100: [
          "6100",
          "EM",
          9950000000.0,
          19.0,
          0.1,
          0.53,
          154.47,
          "-",
          744720.0,
        ],
      },
      { 6108: ["6108", "EM", 710530000.0, 0.425, 0.0, 0.0, "-", "-", "-"] },
      {
        6110: [
          "6110",
          "EM",
          44020000000.0,
          7.1,
          0.1,
          1.43,
          13.31,
          4.22,
          32320000.0,
        ],
      },
      {
        6111: [
          "6111",
          "EM",
          3770000000.0,
          4.57,
          0.01,
          0.22,
          9.48,
          2.55,
          518090.00000000006,
        ],
      },
      { 6113: ["6113", "EM", 504000000.0, 1.26, 0.0, 0.0, 16.94, 7.94, "-"] },
      {
        6116: [
          "6116",
          "EM",
          90210000.0,
          0.42,
          -0.01,
          -2.33,
          "-",
          "-",
          139660.0,
        ],
      },
      {
        6117: [
          "6117",
          "EM",
          459200000.0,
          0.56,
          -0.01,
          -1.75,
          5.21,
          "-",
          37980.0,
        ],
      },
      {
        6118: [
          "6118",
          "EM",
          1440000000.0,
          2.82,
          -0.05,
          -1.74,
          37.15,
          "-",
          4370000.0,
        ],
      },
      {
        6119: [
          "6119",
          "EM",
          201000000.0,
          0.335,
          0.005,
          1.52,
          8.23,
          12.09,
          3900.0,
        ],
      },
      { 6122: ["6122", "EM", 2100000000.0, 2.4, 0.0, 0.0, 8.56, 7.21, "-"] },
      {
        6123: [
          "6123",
          "EM",
          1190000000.0,
          2.84,
          0.07,
          2.53,
          4.67,
          2.15,
          564660.0,
        ],
      },
      {
        6127: [
          "6127",
          "EM",
          3580000000.0,
          59.0,
          1.6,
          2.79,
          50.57,
          0.51,
          15770000.0,
        ],
      },
      {
        6128: [
          "6128",
          "EM",
          717850000.0,
          1.41,
          0.19,
          15.57,
          "-",
          "-",
          21720000.0,
        ],
      },
      {
        6133: [
          "6133",
          "EM",
          204000000.0,
          0.24,
          -0.008,
          -3.23,
          "-",
          "-",
          14990.0,
        ],
      },
      {
        6136: [
          "6136",
          "EM",
          1640000000.0,
          0.77,
          0.01,
          1.32,
          2.92,
          "-",
          11550.0,
        ],
      },
      {
        6138: [
          "6138",
          "EM",
          2470000000.0,
          0.82,
          0.02,
          2.5,
          10.33,
          "-",
          485750.0,
        ],
      },
      {
        6158: [
          "6158",
          "EM",
          16510000000.000002,
          3.78,
          0.08,
          2.16,
          5.3,
          3.97,
          13190000.0,
        ],
      },
      {
        6160: [
          "6160",
          "EM",
          179760000000.0,
          135.0,
          6.4,
          4.98,
          "-",
          "-",
          74020000.0,
        ],
      },
      { 6161: ["6161", "EM", 300330000.0, 0.48, 0.0, 0.0, 4.03, "-", "-"] },
      {
        6162: [
          "6162",
          "EM",
          300000000.0,
          0.15,
          0.007,
          4.9,
          5.03,
          4.0,
          932260.0,
        ],
      },
      { 6163: ["6163", "EM", 180980000.0, 0.72, 0.0, 0.0, 28.13, 2.08, "-"] },
      {
        6166: [
          "6166",
          "EM",
          2490000000.0,
          1.51,
          0.0,
          0.0,
          2.52,
          11.92,
          28590.0,
        ],
      },
      { 6168: ["6168", "EM", 460450000.0, 0.161, 0.0, 0.0, "-", "-", "-"] },
      {
        6169: [
          "6169",
          "EM",
          6070000000.0,
          1.69,
          0.0,
          0.0,
          5.68,
          7.28,
          34530000.0,
        ],
      },
      {
        6178: [
          "6178",
          "EM",
          4240000000.0,
          6.03,
          0.19,
          3.25,
          10.11,
          3.16,
          1400000.0,
        ],
      },
      {
        6182: ["6182", "EM", 192000000.0, 0.24, 0.0, 0.0, 17.02, 7.29, 1920.0],
      },
      { 6183: ["6183", "EM", 122940000.0, 0.081, 0.0, 0.0, 0.73, "-", "-"] },
      {
        6185: [
          "6185",
          "EM",
          16980000000.0,
          128.0,
          -2.0,
          -1.54,
          "-",
          "-",
          105700000.0,
        ],
      },
      {
        6186: [
          "6186",
          "EM",
          97420000000.0,
          10.94,
          0.16,
          1.48,
          11.12,
          4.17,
          90970000.0,
        ],
      },
      { 6188: ["6188", "EM", 1510000000.0, 3.83, 0.0, 0.0, 20.97, "-", "-"] },
      { 6189: ["6189", "EM", 43930000.0, 0.7, 0.02, 2.94, "-", "-", 5920.0] },
      { 6190: ["6190", "EM", 4440000000.0, 10.92, 0.0, 0.0, 13.42, 1.1, "-"] },
      {
        6193: ["6193", "EM", 152000000.0, 0.38, 0.0, 0.0, 8.86, 6.58, 170370.0],
      },
      {
        6196: [
          "6196",
          "EM",
          3150000000.0,
          1.72,
          -0.03,
          -1.71,
          3.94,
          "-",
          83890.0,
        ],
      },
      {
        6198: [
          "6198",
          "EM",
          4710000000.0,
          4.29,
          0.07,
          1.66,
          6.17,
          7.18,
          508290.0,
        ],
      },
      {
        6199: ["6199", "EM", 5690000000.0, 2.59, 0.0, 0.0, 8.79, 3.27, 2450.0],
      },
      {
        6288: [
          "6288",
          "-",
          22000000000.0,
          44.0,
          -1.7,
          -3.72,
          39.19,
          0.76,
          146430.0,
        ],
      },
      {
        6600: [
          "6600",
          "EM",
          5700000000.0,
          8.38,
          -0.02,
          -0.24,
          5.19,
          "-",
          419100.0,
        ],
      },
      {
        6601: [
          "6601",
          "EM",
          3160000000.0,
          2.37,
          0.01,
          0.42,
          8.97,
          2.21,
          3240000.0,
        ],
      },
      {
        6606: [
          "6606",
          "EM",
          10520000000.0,
          24.5,
          1.75,
          7.69,
          "-",
          "-",
          34570000.0,
        ],
      },
      {
        6608: [
          "6608",
          "EM",
          4830000000.0,
          9.67,
          0.39,
          4.2,
          "-",
          "-",
          1650000.0,
        ],
      },
      {
        6609: [
          "6609",
          "EM",
          2260000000.0,
          71.65,
          -0.85,
          -1.17,
          "-",
          "-",
          3030000.0,
        ],
      },
      { 6611: ["6611", "EM", 2020000000.0, 3.0, 0.01, 0.33, "-", "-", 6010.0] },
      {
        6616: [
          "6616",
          "EM",
          6410000000.0,
          5.38,
          0.19,
          3.66,
          "-",
          "-",
          9450000.0,
        ],
      },
      {
        6618: [
          "6618",
          "EM",
          211000000000.0,
          66.05,
          3.7,
          5.93,
          "-",
          "-",
          247020000.0,
        ],
      },
      {
        6622: ["6622", "EM", 2680000000.0, 4.96, 0.14, 2.9, "-", "-", 266910.0],
      },
      {
        6626: [
          "6626",
          "EM",
          6550000000.0,
          4.31,
          0.48,
          12.53,
          "-",
          "-",
          47500000.0,
        ],
      },
      {
        6628: [
          "6628",
          "EM",
          3290000000.0,
          7.4,
          -0.12,
          -1.6,
          "-",
          "-",
          178270.0,
        ],
      },
      {
        6633: [
          "6633",
          "EM",
          5390000000.0,
          7.8,
          -0.02,
          -0.26,
          "-",
          "-",
          511200.0,
        ],
      },
      {
        6666: [
          "6666",
          "EM",
          28100000000.0,
          2.6,
          0.06,
          2.36,
          8.36,
          "-",
          17710000.0,
        ],
      },
      {
        6668: [
          "6668",
          "EM",
          3630000000.0,
          3.56,
          0.16,
          4.71,
          17.98,
          1.69,
          3620000.0,
        ],
      },
      {
        6669: [
          "6669",
          "EM",
          3660000000.0,
          11.68,
          0.3,
          2.64,
          "-",
          "-",
          6450000.0,
        ],
      },
      {
        6677: [
          "6677",
          "EM",
          5200000000.0,
          4.4,
          0.13,
          3.04,
          13.02,
          1.5,
          1020000.0,
        ],
      },
      {
        6680: [
          "6680",
          "EM",
          3880000000.0,
          31.0,
          -0.1,
          -0.32,
          "-",
          "-",
          132180000.0,
        ],
      },
      {
        6690: [
          "6690",
          "EM",
          90670000000.0,
          31.7,
          0.65,
          2.09,
          20.25,
          1.39,
          109700000.0,
        ],
      },
      {
        6699: [
          "6699",
          "EM",
          24910000000.0,
          148.0,
          -16.9,
          -10.25,
          "-",
          "-",
          281190000.0,
        ],
      },
      { 6805: ["6805", "EM", 1100000000.0, 0.99, 0.0, 0.0, 9.2, "-", "-"] },
      {
        6806: [
          "6806",
          "EM",
          4880000000.0,
          1.95,
          0.03,
          1.56,
          5.37,
          6.2,
          2220000.0,
        ],
      },
      {
        6808: [
          "6808",
          "EM",
          28040000000.0,
          2.94,
          0.1,
          3.52,
          8.22,
          3.54,
          8470000.0,
        ],
      },
      {
        6811: [
          "6811",
          "EM",
          1300000000.0,
          1.3,
          0.01,
          0.78,
          10.93,
          6.86,
          448160.0,
        ],
      },
      {
        6812: [
          "6812",
          "EM",
          249000000.0,
          0.415,
          -0.005,
          -1.19,
          3.37,
          14.22,
          21020.0,
        ],
      },
      { 6816: ["6816", "EM", 408000000.0, 0.51, 0.0, 0.0, 30.0, "-", 17860.0] },
      {
        6818: [
          "6818",
          "EM",
          37650000000.0,
          2.97,
          0.05,
          1.71,
          3.72,
          8.49,
          16590000.0,
        ],
      },
      {
        6819: ["6819", "EM", 3490000000.0, 7.7, 0.2, 2.67, "-", "-", 6130000.0],
      },
      {
        6820: [
          "6820",
          "EM",
          2810000000.0,
          1.29,
          -0.04,
          -3.01,
          4.74,
          9.3,
          439040.0,
        ],
      },
      {
        6821: [
          "6821",
          "EM",
          5300000000.0,
          269.8,
          -1.6,
          -0.59,
          "-",
          "-",
          1060000.0,
        ],
      },
      { 6822: ["6822", "EM", 917000000.0, 1.31, 0.0, 0.0, 8.04, 8.78, "-"] },
      {
        6823: [
          "6823",
          "EM",
          80300000000.0,
          10.6,
          -0.02,
          -0.19,
          15.38,
          6.76,
          107850000.0,
        ],
      },
      {
        6826: [
          "6826",
          "EM",
          1740000000.0,
          45.8,
          0.0,
          0.0,
          30.05,
          1.32,
          9740000.0,
        ],
      },
      { 6828: ["6828", "EM", 1500000000.0, 0.116, 0.0, 0.0, "-", "-", "-"] },
      {
        6829: [
          "6829",
          "EM",
          277200000.0,
          0.231,
          -0.002,
          -0.86,
          "-",
          "-",
          78380.0,
        ],
      },
      {
        6830: [
          "6830",
          "EM",
          5370000000.0,
          3.04,
          0.04,
          1.33,
          42.58,
          0.24,
          14870000.0,
        ],
      },
      {
        6833: [
          "6833",
          "EM",
          3630000000.0,
          1.79,
          0.09,
          5.29,
          21.91,
          "-",
          7250000.0,
        ],
      },
      {
        6836: [
          "6836",
          "EM",
          1460000000.0,
          1.48,
          0.02,
          1.37,
          8.37,
          "-",
          2560000.0,
        ],
      },
      {
        6837: [
          "6837",
          "EM",
          24000000000.0,
          7.04,
          0.05,
          0.72,
          6.65,
          4.3,
          33430000.0,
        ],
      },
      { 6838: ["6838", "EM", 630000000.0, 1.05, 0.0, 0.0, 6.55, 2.86, "-"] },
      {
        6839: [
          "6839",
          "EM",
          320060000.0,
          0.88,
          0.01,
          1.15,
          4.02,
          7.95,
          15840.0,
        ],
      },
      {
        6855: [
          "6855",
          "EM",
          5340000000.0,
          20.35,
          0.25,
          1.24,
          "-",
          "-",
          10400000.0,
        ],
      },
      {
        6858: [
          "6858",
          "EM",
          2049999999.9999998,
          3.39,
          -0.01,
          -0.29,
          15.98,
          6.5,
          1730.0,
        ],
      },
      {
        6860: [
          "6860",
          "EM",
          334120000.0,
          0.173,
          -0.005,
          -2.81,
          2.19,
          "-",
          1610000.0,
        ],
      },
      {
        6862: [
          "6862",
          "EM",
          101220000000.0,
          18.16,
          1.38,
          8.22,
          263.19,
          0.12,
          248520000.0,
        ],
      },
      {
        6865: [
          "6865",
          "EM",
          14370000000.0,
          31.95,
          1.0,
          3.23,
          32.76,
          0.56,
          109900000.0,
        ],
      },
      {
        6866: ["6866", "EM", 120000000.0, 0.4, 0.005, 1.27, 3.63, "-", 2420.0],
      },
      {
        6868: [
          "6868",
          "EM",
          5910000000.0,
          5.4,
          0.1,
          1.89,
          16.56,
          5.19,
          87500.0,
        ],
      },
      {
        6869: [
          "6869",
          "EM",
          3890000000.0,
          11.08,
          -0.1,
          -0.89,
          13.12,
          2.36,
          10560000.0,
        ],
      },
      { 6877: ["6877", "EM", 252120000.0, 0.124, 0.0, 0.0, "-", "-", "-"] },
      {
        6878: [
          "6878",
          "EM",
          18660000000.0,
          2.59,
          0.33,
          14.6,
          42.11,
          0.19,
          122340000.0,
        ],
      },
      { 6880: ["6880", "EM", 74740000.0, 0.214, 0.0, 0.0, "-", "-", "-"] },
      {
        6881: [
          "6881",
          "EM",
          17310000000.0,
          4.69,
          0.08,
          1.74,
          5.59,
          5.63,
          41180000.0,
        ],
      },
      {
        6882: [
          "6882",
          "EM",
          208510000.0,
          0.415,
          0.005,
          1.22,
          "-",
          "-",
          40480.0,
        ],
      },
      {
        6885: [
          "6885",
          "EM",
          2220000000.0,
          4.15,
          0.09,
          2.22,
          3.91,
          8.72,
          181890.0,
        ],
      },
      {
        6886: [
          "6886",
          "EM",
          23720000000.0,
          13.8,
          0.0,
          0.0,
          9.75,
          3.5,
          21530000.0,
        ],
      },
      {
        6888: ["6888", "EM", 285930000.0, 0.265, 0.01, 3.92, 8.86, "-", 1290.0],
      },
      {
        6889: [
          "6889",
          "EM",
          5160000000.0,
          7.08,
          0.09,
          1.29,
          32.4,
          4.32,
          1640000.0,
        ],
      },
      {
        6890: ["6890", "EM", 375870000.0, 0.62, -0.01, -1.59, 4.42, 6.13, "-"],
      },
      { 6893: ["6893", "EM", 327530000.0, 0.3, -0.005, -1.64, "-", "-", "-"] },
      {
        6896: [
          "6896",
          "EM",
          1160000000.0,
          1.57,
          0.11,
          7.53,
          6.43,
          3.82,
          621990.0,
        ],
      },
      { 6898: ["6898", "EM", 450890000.0, 0.5, 0.0, 0.0, 20.83, 1.6, 20900.0] },
      {
        6899: [
          "6899",
          "EM",
          393390000.0,
          0.365,
          0.005,
          1.39,
          "-",
          "-",
          68940.0,
        ],
      },
      {
        6900: [
          "6900",
          "EM",
          4450000000.0,
          2.15,
          0.03,
          1.42,
          8.08,
          1.12,
          371460.0,
        ],
      },
      {
        6908: [
          "6908",
          "EM",
          3480000000.0,
          6.18,
          -0.17,
          -2.68,
          461.19,
          "-",
          7210000.0,
        ],
      },
      {
        6909: [
          "6909",
          "EM",
          3460000000.0,
          5.56,
          -0.14,
          -2.46,
          "-",
          "-",
          147300.0,
        ],
      },
      {
        6913: [
          "6913",
          "EM",
          1440000000.0,
          1.08,
          -0.03,
          -2.7,
          "-",
          "-",
          153220.0,
        ],
      },
      {
        6918: [
          "6918",
          "EM",
          489630000.0,
          0.94,
          0.0,
          0.0,
          16.58,
          1.91,
          175740.0,
        ],
      },
      {
        6919: [
          "6919",
          "EM",
          1370000000.0,
          8.77,
          0.01,
          0.11,
          6.25,
          4.79,
          772720.0,
        ],
      },
      { 6928: ["6928", "EM", 1570000000.0, 3.5, 0.0, 0.0, 1521.74, "-", "-"] },
      {
        6933: [
          "6933",
          "EM",
          2200000000.0,
          5.39,
          -0.11,
          -2.0,
          29.37,
          "-",
          76000.0,
        ],
      },
      {
        6939: [
          "6939",
          "EM",
          596560000.0,
          1.15,
          0.0,
          0.0,
          12.68,
          1.16,
          584200.0,
        ],
      },
      {
        6958: [
          "6958",
          "EM",
          4330000000.0,
          4.18,
          0.07,
          1.7,
          18.37,
          1.91,
          3340000.0,
        ],
      },
      {
        6966: ["6966", "EM", 470000000.0, 0.47, -0.02, -4.08, 22.82, "-", "-"],
      },
      {
        6968: [
          "6968",
          "EM",
          7770000000.0,
          4.78,
          0.1,
          2.14,
          5.96,
          4.39,
          6130000.0,
        ],
      },
      {
        6969: [
          "6969",
          "EM",
          208850000000.0,
          34.75,
          1.45,
          4.35,
          66.72,
          1.38,
          111970000.0,
        ],
      },
      {
        6978: [
          "6978",
          "EM",
          3830000000.0,
          7.45,
          0.12,
          1.64,
          "-",
          "-",
          4160000.0,
        ],
      },
      {
        6988: [
          "6988",
          "EM",
          4960000000.0,
          2.27,
          0.03,
          1.34,
          29.18,
          "-",
          8180000.0,
        ],
      },
      {
        6989: [
          "6989",
          "EM",
          6150000000.0,
          5.04,
          0.21,
          4.35,
          12.69,
          5.58,
          2310000.0,
        ],
      },
      {
        6993: [
          "6993",
          "EM",
          40370000000.0,
          6.89,
          0.13,
          1.92,
          26.47,
          1.0,
          6570000.0,
        ],
      },
      {
        6996: [
          "6996",
          "EM",
          3940000000.0,
          5.92,
          -0.17,
          -2.79,
          "-",
          "-",
          4940000.0,
        ],
      },
      {
        6998: [
          "6998",
          "EM",
          2790000000.0,
          5.55,
          -0.18,
          -3.14,
          "-",
          "-",
          1590000.0,
        ],
      },
      {
        6999: [
          "6999",
          "EM",
          6040000000.0,
          5.89,
          0.01,
          0.17,
          4.46,
          "-",
          58940.0,
        ],
      },
      {
        7200: [
          "7200",
          "F",
          2060000000.0,
          7.5,
          0.42,
          5.93,
          "-",
          "-",
          496850000.0,
        ],
      },
      {
        7226: [
          "7226",
          "F",
          2860000000.0,
          2.99,
          0.17,
          6.03,
          "-",
          "-",
          288840000.0,
        ],
      },
      {
        7230: ["7230", "F", 13170000.0, 4.776, 0.22, 4.83, "-", "-", 2770000.0],
      },
      {
        7231: ["7231", "F", 61130000.0, 7.78, 0.425, 5.78, "-", "-", 945570.0],
      },
      {
        7232: ["7232", "F", 29850000.0, 9.35, 0.485, 5.47, "-", "-", 122900.0],
      },
      {
        7233: [
          "7233",
          "F",
          294040000.0,
          8.69,
          0.205,
          2.42,
          "-",
          "-",
          9270000.0,
        ],
      },
      {
        7248: [
          "7248",
          "F",
          64730000.00000001,
          5.06,
          0.124,
          2.51,
          "-",
          "-",
          8100000.0,
        ],
      },
      {
        7261: ["7261", "F", 71580000.0, 23.32, 0.66, 2.91, "-", "-", 8020000.0],
      },
      {
        7266: ["7266", "F", 30110000.0, 18.17, 0.51, 2.89, "-", "-", 1410000.0],
      },
      {
        7272: ["7272", "F", 38520000.0, 10.27, 0.22, 2.19, "-", "-", 471320.0],
      },
      {
        7288: [
          "7288",
          "F",
          256529999.99999997,
          4.776,
          0.242,
          5.34,
          "-",
          "-",
          18820000.0,
        ],
      },
      {
        7299: [
          "7299",
          "F",
          1670000000.0,
          7.555,
          0.155,
          2.09,
          "-",
          "-",
          4210000.0,
        ],
      },
      {
        7300: [
          "7300",
          "F",
          415520000.0,
          4.946,
          -0.154,
          -3.02,
          "-",
          "-",
          32830000.0,
        ],
      },
      {
        7322: [
          "7322",
          "F",
          39360000.0,
          6.04,
          -0.135,
          -2.19,
          "-",
          "-",
          194690.0,
        ],
      },
      {
        7331: [
          "7331",
          "F",
          62560000.0,
          3.74,
          -0.072,
          -1.89,
          "-",
          "-",
          786000.0,
        ],
      },
      { 7332: ["7332", "F", 16990000.0, 6.785, -0.18, -2.58, "-", "-", "-"] },
      {
        7333: [
          "7333",
          "F",
          177340000.0,
          7.275,
          -0.085,
          -1.15,
          "-",
          "-",
          3010000.0,
        ],
      },
      {
        7336: ["7336", "F", 29750000.0, 4.98, -0.16, -3.11, "-", "-", 356150.0],
      },
      { 7345: ["7345", "F", 31760000.0, 5.52, -0.2, -3.5, "-", "-", 262760.0] },
      {
        7348: [
          "7348",
          "F",
          189360000.0,
          8.63,
          -0.145,
          -1.65,
          "-",
          "-",
          66550.0,
        ],
      },
      {
        7362: ["7362", "F", 25370000.0, 5.89, -0.185, -3.05, "-", "-", 8840.0],
      },
      { 7373: ["7373", "F", 30260000.0, 5.685, -0.05, -0.87, "-", "-", "-"] },
      { 7374: ["7374", "F", 42610000.0, 7.605, -0.09, -1.17, "-", "-", "-"] },
      {
        7500: [
          "7500",
          "F",
          2640000000.0,
          5.075,
          -0.325,
          -6.02,
          "-",
          "-",
          534750000.0,
        ],
      },
      {
        7522: [
          "7522",
          "F",
          614770000.0,
          1.942,
          -0.058,
          -2.9,
          "-",
          "-",
          10090000.0,
        ],
      },
      {
        7552: [
          "7552",
          "F",
          423710000.0,
          9.76,
          -0.62,
          -5.97,
          "-",
          "-",
          185810000.0,
        ],
      },
      {
        7568: [
          "7568",
          "F",
          1940000000.0,
          1.32,
          -0.054,
          -3.93,
          "-",
          "-",
          89010000.0,
        ],
      },
      {
        7588: [
          "7588",
          "F",
          33310000.000000004,
          5.22,
          -0.28,
          -5.09,
          "-",
          "-",
          11750000.0,
        ],
      },
      {
        8001: [
          "8001",
          "EG",
          50970000.0,
          0.118,
          0.007,
          6.31,
          3.03,
          "-",
          382420.0,
        ],
      },
      { 8003: ["8003", "EG", 184950000.0, 0.56, 0.0, 0.0, "-", "-", "-"] },
      {
        8005: [
          "8005",
          "EG",
          920450000.0,
          0.37,
          -0.015,
          -3.9,
          34.91,
          "-",
          37860.0,
        ],
      },
      {
        8006: [
          "8006",
          "EG",
          33009999.999999996,
          0.069,
          0.0,
          0.0,
          "-",
          "-",
          "-",
        ],
      },
      {
        8007: [
          "8007",
          "EG",
          132190000.0,
          0.29,
          -0.01,
          -3.33,
          "-",
          "-",
          768600.0,
        ],
      },
      { 8009: ["8009", "EG", 30030000.0, 0.51, 0.0, 0.0, "-", "-", "-"] },
      { 8011: ["8011", "EG", 613910000.0, 0.16, 0.0, 0.0, "-", "-", "-"] },
      { 8013: ["8013", "EG", 120000000.0, 0.075, 0.0, 0.0, 13.89, "-", "-"] },
      {
        8017: [
          "8017",
          "EG",
          540000000.0,
          0.9,
          -0.01,
          -1.1,
          20.79,
          "-",
          1380000.0,
        ],
      },
      {
        8018: [
          "8018",
          "EG",
          30380000.0,
          0.241,
          -0.049,
          -16.9,
          "-",
          "-",
          7180.0,
        ],
      },
      {
        8019: ["8019", "EG", 81130000.0, 0.315, 0.01, 3.28, "-", "-", 108640.0],
      },
      {
        8020: [
          "8020",
          "EG",
          44420000.0,
          0.017,
          0.004,
          30.77,
          "-",
          "-",
          258440.0,
        ],
      },
      {
        8021: ["8021", "EG", 718350000.0, 0.05, 0.003, 6.38, "-", "-", 18600.0],
      },
      { 8022: ["8022", "EG", 21640000.0, 0.01, 0.0, 0.0, "-", "-", "-"] },
      { 8023: ["8023", "EG", 258000000.0, 0.43, 0.0, 0.0, 16.6, 2.33, "-"] },
      { 8025: ["8025", "EG", 100450000.0, 0.5, 0.0, 0.0, "-", "-", "-"] },
      { 8026: ["8026", "EG", 524600000.0, 0.36, -0.01, -2.7, "-", "-", "-"] },
      { 8027: ["8027", "EG", 85120000.0, 0.38, 0.0, 0.0, "-", "-", "-"] },
      { 8028: ["8028", "EG", 78760000.0, 0.028, 0.0, 0.0, 10.0, "-", "-"] },
      { 8029: ["8029", "EG", 890850000.0, 0.39, 0.0, 0.0, "-", "-", 7870.0] },
      { 8030: ["8030", "EG", 31160000.0, 0.018, 0.0, 0.0, "-", "-", "-"] },
      { 8031: ["8031", "EG", 74200000.0, 0.265, 0.0, 0.0, "-", "-", "-"] },
      {
        8032: [
          "8032",
          "EG",
          13180000000.0,
          1.37,
          0.07,
          5.38,
          12.94,
          9.71,
          5510000.0,
        ],
      },
      { 8033: ["8033", "EG", 92410000.0, 0.15, 0.0, 0.0, 24.19, 6.67, "-"] },
      { 8035: ["8035", "EG", 111600000.0, 0.186, 0.0, 0.0, 10.0, "-", "-"] },
      { 8036: ["8036", "EG", 104550000.0, 0.085, 0.0, 0.0, 60.71, "-", "-"] },
      {
        8037: [
          "8037",
          "EG",
          1170000000.0,
          1.22,
          0.06,
          5.17,
          39.1,
          "-",
          431430.0,
        ],
      },
      {
        8039: [
          "8039",
          "EG",
          369750000.0,
          0.75,
          -0.03,
          -3.85,
          "-",
          "-",
          12480.0,
        ],
      },
      {
        8040: [
          "8040",
          "EG",
          123200000.0,
          0.385,
          0.02,
          5.48,
          48.13,
          2.86,
          11400.0,
        ],
      },
      {
        8041: ["8041", "EG", 327740000.0, 0.415, 0.0, 0.0, "-", "-", 140700.0],
      },
      {
        8042: [
          "8042",
          "EG",
          42400000.0,
          0.053,
          -0.005,
          -8.62,
          "-",
          "-",
          2770.0,
        ],
      },
      {
        8043: [
          "8043",
          "EG",
          72000000.0,
          0.18,
          -0.002,
          -1.1,
          15.13,
          2.28,
          7100.0,
        ],
      },
      {
        8045: [
          "8045",
          "EG",
          23230000.0,
          0.046,
          -0.007,
          -13.21,
          "-",
          "-",
          11770.0,
        ],
      },
      {
        8047: [
          "8047",
          "EG",
          1120000000.0,
          0.196,
          0.013,
          7.1,
          "-",
          "-",
          2750000.0,
        ],
      },
      {
        8048: ["8048", "EG", 85690000.0, 0.044, 0.012, 37.5, "-", "-", 95330.0],
      },
      {
        8049: ["8049", "EG", 224250000.0, 1.3, 0.06, 4.84, 4.92, 5.71, 10120.0],
      },
      { 8050: ["8050", "EG", 515370000.0, 0.38, 0.0, 0.0, 30.89, "-", "-"] },
      { 8051: ["8051", "EG", 114820000.0, 4.9, 0.0, 0.0, 12.76, "-", "-"] },
      {
        8052: ["8052", "EG", 83410000.0, 0.037, 0.003, 8.82, "-", "-", 8360.0],
      },
      {
        8053: [
          "8053",
          "EG",
          1470000000.0,
          0.415,
          -0.015,
          -3.49,
          10.12,
          4.82,
          421100.0,
        ],
      },
      { 8055: ["8055", "EG", 43280000.0, 0.011, 0.0, 0.0, "-", "-", "-"] },
      { 8056: ["8056", "EG", 107760000.0, 0.133, -0.007, -5.0, "-", "-", "-"] },
      { 8057: ["8057", "EG", 64800000.0, "-", 0.0, 0.0, "-", "-", "-"] },
      { 8059: ["8059", "EG", 31320000.0, 0.031, 0.0, 0.0, "-", "-", "-"] },
      {
        8060: [
          "8060",
          "EG",
          43730000.0,
          0.134,
          0.004,
          3.08,
          19.42,
          "-",
          6760.0,
        ],
      },
      { 8062: ["8062", "EG", 132000000.0, 0.275, 0.0, 0.0, 4.97, 10.91, "-"] },
      {
        8063: [
          "8063",
          "EG",
          46990000.0,
          0.092,
          -0.006,
          -6.12,
          "-",
          "-",
          4600.0,
        ],
      },
      {
        8065: ["8065", "EG", 134830000.0, 0.335, 0.0, 0.0, 4.15, 8.15, 11720.0],
      },
      { 8066: ["8066", "EG", 21530000.0, 0.041, 0.0, 0.0, 5.47, "-", 24480.0] },
      { 8067: ["8067", "EG", 140400000.0, 0.78, 0.0, 0.0, 3.39, "-", "-"] },
      {
        8069: [
          "8069",
          "EG",
          112000000.0,
          0.28,
          -0.01,
          -3.45,
          "-",
          "-",
          82850.0,
        ],
      },
      { 8070: ["8070", "EG", 42000000.0, 0.21, 0.0, 0.0, 35.0, "-", "-"] },
      { 8071: ["8071", "EG", 145260000.0, 0.031, 0.002, 6.9, "-", "-", "-"] },
      {
        8072: [
          "8072",
          "EG",
          196580000.0,
          0.28,
          -0.01,
          -3.45,
          "-",
          "-",
          1480000.0,
        ],
      },
      { 8073: ["8073", "EG", 218400000.0, 0.42, 0.0, 0.0, 323.08, "-", "-"] },
      { 8075: ["8075", "EG", 2980000000.0, 1.0, 0.0, 0.0, "-", "-", "-"] },
      { 8076: ["8076", "EG", 90880000.0, 0.069, -0.002, -2.82, "-", "-", "-"] },
      { 8078: ["8078", "EG", 15230000.0, 0.039, 0.0, 0.0, "-", "-", 11080.0] },
      { 8079: ["8079", "EG", 40620000.0, 0.169, 0.0, 0.0, "-", "-", "-"] },
      { 8080: ["8080", "EG", 297580000.0, 0.7, 0.0, 0.0, 1.39, "-", "-"] },
      {
        8081: ["8081", "EG", 576310000.0, 0.108, -0.002, -1.82, "-", "-", "-"],
      },
      {
        8082: [
          "8082",
          "EG",
          161390000.0,
          0.077,
          0.006,
          8.45,
          "-",
          "-",
          42200.0,
        ],
      },
      {
        8083: [
          "8083",
          "EG",
          5510000000.0,
          0.305,
          0.005,
          1.67,
          "-",
          "-",
          31070000.0,
        ],
      },
      {
        8086: ["8086", "EG", 45170000.0, 0.8, -0.01, -1.23, "-", "-", 26640.0],
      },
      {
        8087: ["8087", "EG", 66349999.99999999, 0.096, 0.0, 0.0, "-", "-", "-"],
      },
      { 8088: ["8088", "EG", 33290000.0, 0.06, 0.0, 0.0, "-", "-", "-"] },
      { 8089: ["8089", "EG", 13910000.0, 0.033, 0.0, 0.0, "-", "-", "-"] },
      {
        8091: [
          "8091",
          "EG",
          30960000.0,
          0.043,
          -0.011,
          -20.37,
          "-",
          "-",
          617160.0,
        ],
      },
      { 8092: ["8092", "EG", 35160000.0, 0.038, 0.0, 0.0, 4.18, 13.16, "-"] },
      {
        8093: ["8093", "EG", 263519999.99999997, 0.54, 0.0, 0.0, "-", "-", "-"],
      },
      {
        8095: ["8095", "EG", 480530000.0, 0.59, -0.01, -1.67, 10.81, "-", "-"],
      },
      {
        8096: [
          "8096",
          "EG",
          89500000.0,
          0.179,
          -0.002,
          -1.1,
          "-",
          "-",
          95580.0,
        ],
      },
      {
        8098: ["8098", "EG", 154000000.0, 0.07, 0.0, 0.0, 10.14, 7.14, 2100.0],
      },
      {
        8100: [
          "8100",
          "EG",
          166660000.0,
          0.375,
          -0.005,
          -1.32,
          "-",
          "-",
          11710.0,
        ],
      },
      { 8101: ["8101", "EG", 83630000.0, 0.241, 0.0, 0.0, 0.84, "-", "-"] },
      { 8103: ["8103", "EG", 183380000.0, 1.7, 0.0, 0.0, "-", "-", "-"] },
      { 8106: ["8106", "EG", 49800000.0, 0.19, 0.0, 0.0, 16.24, "-", "-"] },
      {
        8107: [
          "8107",
          "EG",
          23900000.0,
          0.239,
          0.009,
          3.91,
          9.37,
          "-",
          47800.0,
        ],
      },
      { 8109: ["8109", "EG", 17090000.0, 0.034, 0.0, 0.0, "-", "-", "-"] },
      { 8111: ["8111", "EG", 82910000.0, 0.185, 0.0, 0.0, 0.98, "-", "-"] },
      {
        8112: [
          "8112",
          "EG",
          24080000.0,
          0.42,
          -0.01,
          -2.33,
          "-",
          "-",
          466680.0,
        ],
      },
      {
        8113: [
          "8113",
          "EG",
          161230000.0,
          0.247,
          -0.001,
          -0.4,
          5.02,
          14.17,
          "-",
        ],
      },
      { 8115: ["8115", "EG", 36110000.0, 0.65, 0.0, 0.0, "-", "-", "-"] },
      { 8117: ["8117", "EG", 146430000.0, 0.143, 0.0, 0.0, 14.3, "-", "-"] },
      {
        8118: [
          "8118",
          "EG",
          295000000.0,
          0.59,
          0.01,
          1.72,
          12.22,
          "-",
          73120.0,
        ],
      },
      { 8120: ["8120", "EG", 80790000.0, 0.132, 0.0, 0.0, "-", "-", "-"] },
      {
        8121: ["8121", "EG", 25000000.0, 0.15, 0.001, 0.67, "-", "-", 151500.0],
      },
      { 8123: ["8123", "EG", 92980000.0, 0.012, 0.0, 0.0, "-", "-", 2880.0] },
      { 8125: ["8125", "EG", 30250000.0, 0.193, -0.003, -1.53, "-", "-", "-"] },
      {
        8126: ["8126", "EG", 123830000.0, 0.26, 0.01, 4.0, 2.34, "-", 17680.0],
      },
      {
        8128: [
          "8128",
          "EG",
          393840000.0,
          0.087,
          -0.014,
          -13.86,
          "-",
          "-",
          2080.0,
        ],
      },
      { 8130: ["8130", "EG", 182030000.0, 0.05, 0.0, 0.0, "-", "-", "-"] },
      { 8131: ["8131", "EG", 27590000.0, 0.058, 0.0, 0.0, "-", "-", "-"] },
      { 8132: ["8132", "EG", 734700000.0, 0.29, -0.005, -1.69, "-", "-", "-"] },
      {
        8133: ["8133", "EG", 35100000.0, 0.225, 0.005, 2.27, "-", "-", 36990.0],
      },
      { 8135: ["8135", "EG", 206230000.0, 0.26, 0.0, 0.0, "-", "-", "-"] },
      {
        8136: ["8136", "EG", 50000000.0, 0.05, 0.0, 0.0, 9.26, 20.0, 207500.0],
      },
      {
        8137: [
          "8137",
          "EG",
          3390000000.0,
          0.345,
          0.01,
          2.99,
          2.9,
          "-",
          194840.0,
        ],
      },
      { 8139: ["8139", "EG", 68350000.0, 1.78, 0.0, 0.0, 49.31, "-", 8010.0] },
      { 8140: ["8140", "EG", 116800000.0, 0.146, 0.0, 0.0, 5.39, "-", 7160.0] },
      {
        8143: ["8143", "EG", 180360000.0, 0.064, -0.004, -5.88, "-", "-", "-"],
      },
      { 8146: ["8146", "EG", 141600000.0, 0.177, 0.0, 0.0, "-", "-", 21240.0] },
      {
        8147: [
          "8147",
          "EG",
          35040000.0,
          0.034,
          -0.002,
          -5.56,
          "-",
          "-",
          34920.0,
        ],
      },
      { 8148: ["8148", "EG", 26720000.0, 0.021, 0.0, 0.0, "-", "-", "-"] },
      { 8149: ["8149", "EG", 142100000.0, 0.176, 0.0, 0.0, 22.28, 0.57, "-"] },
      {
        8150: [
          "8150",
          "EG",
          49060000.0,
          0.026,
          -0.001,
          -3.7,
          "-",
          "-",
          87190.0,
        ],
      },
      { 8151: ["8151", "EG", 49560000.0, 0.118, 0.0, 0.0, "-", "-", "-"] },
      {
        8152: [
          "8152",
          "EG",
          51000000.0,
          0.085,
          0.008,
          10.39,
          "-",
          "-",
          12540.0,
        ],
      },
      {
        8153: [
          "8153",
          "EG",
          414200000.0,
          0.058,
          -0.001,
          -1.69,
          145.0,
          "-",
          235480.0,
        ],
      },
      { 8155: ["8155", "EG", 122960000.0, 0.011, 0.0, 0.0, 3.79, "-", "-"] },
      {
        8156: [
          "8156",
          "EG",
          612580000.0,
          0.135,
          0.002,
          1.5,
          "-",
          "-",
          537590.0,
        ],
      },
      {
        8158: [
          "8158",
          "EG",
          673610000.0,
          0.236,
          0.001,
          0.43,
          "-",
          "-",
          491230.0,
        ],
      },
      { 8159: ["8159", "EG", 86590000.0, 0.123, 0.0, 0.0, "-", "-", "-"] },
      {
        8160: ["8160", "EG", 42320000.0, 0.081, 0.001, 1.25, "-", "-", 74980.0],
      },
      { 8161: ["8161", "EG", 56160000.0, 0.054, 0.0, 0.0, 11.74, "-", "-"] },
      {
        8162: ["8162", "EG", 223930000.0, 0.27, 0.005, 1.89, "-", "-", 10800.0],
      },
      {
        8163: [
          "8163",
          "EG",
          91670000.0,
          0.189,
          -0.001,
          -0.53,
          "-",
          "-",
          204660.0,
        ],
      },
      { 8166: ["8166", "EG", 29130000.0, 0.275, 0.005, 1.85, "-", "-", "-"] },
      {
        8167: [
          "8167",
          "EG",
          1910000000.0,
          0.201,
          0.006,
          3.08,
          "-",
          "-",
          371850.0,
        ],
      },
      { 8168: ["8168", "EG", 95000000.0, 0.095, 0.0, 0.0, "-", "-", "-"] },
      { 8169: ["8169", "EG", 51310000.0, 0.079, 0.0, 0.0, 10.13, "-", "-"] },
      {
        8170: ["8170", "EG", 863520000.0, 2.1, 0.35, 20.0, 50.6, "-", 11620.0],
      },
      {
        8172: [
          "8172",
          "EG",
          837610000.0,
          0.199,
          0.009,
          4.74,
          "-",
          "-",
          320560.0,
        ],
      },
      { 8173: ["8173", "EG", 109820000.0, 0.51, 0.0, 0.0, 15.22, "-", "-"] },
      { 8175: ["8175", "EG", 157680000.0, 0.058, 0.0, 0.0, "-", "-", "-"] },
      {
        8176: [
          "8176",
          "EG",
          199950000.0,
          0.395,
          0.045,
          12.86,
          "-",
          "-",
          3950.0,
        ],
      },
      { 8178: ["8178", "EG", 85780000.0, 0.25, 0.0, 0.0, "-", "-", "-"] },
      { 8179: ["8179", "EG", 118480000.0, 0.202, 0.0, 0.0, "-", "-", "-"] },
      { 8181: ["8181", "EG", 99550000.0, 0.097, 0.0, 0.0, 6.3, "-", "-"] },
      { 8186: ["8186", "EG", 14020000.0, 0.9, 0.0, 0.0, "-", "-", 51200.0] },
      {
        8187: [
          "8187",
          "EG",
          40320000.0,
          0.067,
          -0.001,
          -1.47,
          "-",
          "-",
          875890.0,
        ],
      },
      { 8188: ["8188", "EG", 82920000.0, 0.17, 0.0, 0.0, 80.95, "-", "-"] },
      { 8189: ["8189", "EG", 171170000.0, 0.143, 0.0, 0.0, "-", "-", "-"] },
      {
        8191: ["8191", "EG", 41460000.0, 0.83, 0.01, 1.22, "-", "-", 1180000.0],
      },
      {
        8193: ["8193", "EG", 76950000.0, 0.066, 0.001, 1.54, "-", "-", 30860.0],
      },
      { 8195: ["8195", "EG", 464200000.0, 1.295, 0.0, 0.0, 8.63, 8.22, "-"] },
      {
        8196: [
          "8196",
          "EG",
          127500000.0,
          0.425,
          0.01,
          2.41,
          "-",
          "-",
          165000.0,
        ],
      },
      {
        8198: ["8198", "EG", 216600000.0, 0.395, -0.005, -1.25, "-", "-", "-"],
      },
      { 8200: ["8200", "EG", 105060000.0, 1.4, 0.04, 2.94, "-", "-", 5600.0] },
      {
        8201: [
          "8201",
          "EG",
          53460000.0,
          0.099,
          0.014,
          16.47,
          2.79,
          "-",
          28920.0,
        ],
      },
      { 8203: ["8203", "EG", 170080000.0, 0.295, 0.0, 0.0, "-", "-", 1200.0] },
      { 8205: ["8205", "EG", 39600000.0, 0.3, 0.0, 0.0, "-", "-", "-"] },
      { 8206: ["8206", "EG", 98570000.0, 0.052, 0.0, 0.0, "-", "-", "-"] },
      {
        8208: ["8208", "EG", 50400000.0, 0.07, 0.008, 12.9, "-", "-", 50630.0],
      },
      { 8210: ["8210", "EG", 46400000.0, 0.058, 0.0, 0.0, "-", "-", "-"] },
      { 8211: ["8211", "EG", 27570000.0, 0.058, 0.0, 0.0, "-", "-", "-"] },
      { 8213: ["8213", "EG", 197890000.0, 0.38, 0.0, 0.0, 79.17, "-", "-"] },
      { 8215: ["8215", "EG", 391910000.0, 0.108, 0.0, 0.0, "-", "-", "-"] },
      {
        8217: ["8217", "EG", 59460000.0, 0.197, 0.003, 1.55, "-", "-", 2920.0],
      },
      { 8218: ["8218", "EG", 40240000.0, 0.255, 0.0, 0.0, "-", "-", "-"] },
      {
        8219: [
          "8219",
          "EG",
          45000000.0,
          0.3,
          -0.005,
          -1.64,
          "-",
          "-",
          850960.0,
        ],
      },
      { 8220: ["8220", "EG", 25660000.0, 0.025, 0.002, 8.7, "-", "-", 3980.0] },
      { 8221: ["8221", "EG", 100000000.0, 0.05, 0.0, 0.0, "-", "-", "-"] },
      {
        8222: [
          "8222",
          "EG",
          24800000.0,
          0.055,
          0.014,
          34.15,
          3.04,
          "-",
          26360.0,
        ],
      },
      {
        8223: [
          "8223",
          "EG",
          996000000.0,
          2.49,
          -0.01,
          -0.4,
          65.01,
          1.0,
          447720.0,
        ],
      },
      { 8225: ["8225", "EG", 338410000.0, 0.34, 0.0, 0.0, 4.72, "-", 68000.0] },
      {
        8226: [
          "8226",
          "EG",
          48700000.0,
          0.35,
          -0.025,
          -6.67,
          11.44,
          "-",
          14150.0,
        ],
      },
      { 8227: ["8227", "EG", 774910000.0, 1.2, 0.02, 1.69, "-", "-", "-"] },
      {
        8228: [
          "8228",
          "EG",
          68250000.0,
          0.082,
          0.004,
          5.13,
          1.13,
          "-",
          1260000.0,
        ],
      },
      { 8229: ["8229", "EG", 91600000.0, 0.229, 0.0, 0.0, 11.62, "-", 3420.0] },
      {
        8231: ["8231", "EG", 150470000.0, 1.24, 0.0, 0.0, 21.12, "-", 94620.0],
      },
      {
        8232: [
          "8232",
          "EG",
          26760000.0,
          0.06,
          0.015,
          33.33,
          "-",
          "-",
          111020.0,
        ],
      },
      {
        8237: [
          "8237",
          "EG",
          55840000.0,
          0.016,
          0.002,
          14.29,
          "-",
          "-",
          20930.0,
        ],
      },
      {
        8238: [
          "8238",
          "EG",
          52700000.0,
          0.122,
          -0.008,
          -6.15,
          "-",
          "-",
          5830.0,
        ],
      },
      {
        8239: [
          "8239",
          "EG",
          38440000.0,
          0.038,
          -0.001,
          -2.56,
          "-",
          "-",
          169210.0,
        ],
      },
      {
        8241: ["8241", "EG", 84570000.0, 0.234, 0.004, 1.74, "-", "-", 1170.0],
      },
      { 8242: ["8242", "EG", 16770000.0, 0.01, 0.0, 0.0, "-", "-", "-"] },
      {
        8245: [
          "8245",
          "EG",
          33509999.999999996,
          0.056,
          0.0,
          0.0,
          "-",
          "-",
          "-",
        ],
      },
      {
        8246: [
          "8246",
          "EG",
          696710000.0,
          0.19,
          -0.004,
          -2.06,
          "-",
          "-",
          4590.0,
        ],
      },
      {
        8247: [
          "8247",
          "EG",
          99640000.0,
          1.55,
          -0.34,
          -17.99,
          70.45,
          "-",
          51940.0,
        ],
      },
      {
        8249: ["8249", "EG", 14040000.0, 0.108, 0.009, 9.09, "-", "-", 16520.0],
      },
      { 8250: ["8250", "EG", 172320000.0, 0.023, 0.0, 0.0, "-", "-", "-"] },
      { 8255: ["8255", "EG", 53760000.0, 0.112, 0.0, 0.0, "-", "-", "-"] },
      { 8257: ["8257", "EG", 180000000.0, 0.18, 0.0, 0.0, 4.6, 6.67, 5400.0] },
      { 8258: ["8258", "EG", 23170000.0, 0.07, 0.0, 0.0, 8.14, "-", "-"] },
      { 8259: ["8259", "EG", 269560000.0, 0.39, 0.0, 0.0, "-", "-", "-"] },
      { 8260: ["8260", "EG", 68940000.0, 0.04, 0.0, 0.0, "-", "-", "-"] },
      { 8262: ["8262", "EG", 112800000.0, 0.141, 0.0, 0.0, "-", "-", "-"] },
      { 8267: ["8267", "EG", 99350000.0, 0.27, 0.0, 0.0, "-", "-", "-"] },
      { 8268: ["8268", "EG", 160000000.0, 0.8, 0.0, 0.0, 16.23, "-", "-"] },
      {
        8269: [
          "8269",
          "EG",
          141640000.0,
          0.197,
          0.008,
          4.23,
          "-",
          "-",
          18220.0,
        ],
      },
      {
        8270: [
          "8270",
          "EG",
          112210000.0,
          0.054,
          0.007,
          14.89,
          "-",
          "-",
          9360.0,
        ],
      },
      {
        8271: [
          "8271",
          "EG",
          164400000.0,
          0.109,
          -0.001,
          -0.91,
          22.24,
          "-",
          "-",
        ],
      },
      {
        8275: [
          "8275",
          "EG",
          37800000.0,
          0.14,
          -0.012,
          -7.89,
          "-",
          "-",
          737910.0,
        ],
      },
      { 8277: ["8277", "EG", 73490000.0, 0.28, 0.0, 0.0, "-", "-", "-"] },
      { 8279: ["8279", "EG", 2700000000.0, 0.232, 0.002, 0.87, "-", "-", "-"] },
      {
        8280: [
          "8280",
          "EG",
          83830000.0,
          0.133,
          0.002,
          1.53,
          "-",
          "-",
          130970.0,
        ],
      },
      { 8281: ["8281", "EG", 179000000.0, 0.179, 0.0, 0.0, 8.36, "-", 1430.0] },
      { 8282: ["8282", "EG", 118400000.0, 0.74, 0.0, 0.0, "-", "-", "-"] },
      {
        8283: ["8283", "EG", 290000000.0, 0.145, 0.0, 0.0, 51.79, "-", 15220.0],
      },
      {
        8285: [
          "8285",
          "EG",
          35280000.0,
          0.063,
          -0.008,
          -11.27,
          "-",
          "-",
          3120.0,
        ],
      },
      { 8286: ["8286", "EG", 18260000.0, 0.166, 0.0, 0.0, "-", "-", "-"] },
      { 8287: ["8287", "EG", 74580000.0, 0.113, -0.006, -5.04, "-", "-", "-"] },
      {
        8290: [
          "8290",
          "EG",
          56000000.0,
          0.028,
          -0.001,
          -3.45,
          "-",
          "-",
          21600.0,
        ],
      },
      { 8291: ["8291", "EG", 38080000.0, 0.119, -0.004, -3.25, "-", "-", "-"] },
      {
        8292: [
          "8292",
          "EG",
          677950000.0,
          1.07,
          0.01,
          0.94,
          "-",
          "-",
          20450000.0,
        ],
      },
      { 8293: ["8293", "EG", 70500000.0, 0.047, -0.001, -2.08, "-", "-", "-"] },
      {
        8295: [
          "8295",
          "EG",
          1750000000.0,
          0.42,
          0.02,
          5.0,
          51.85,
          "-",
          57800.0,
        ],
      },
      {
        8296: [
          "8296",
          "EG",
          134390000.0,
          0.181,
          0.002,
          1.12,
          "-",
          "-",
          32580.0,
        ],
      },
      {
        8297: [
          "8297",
          "EG",
          129360000.00000001,
          0.245,
          -0.01,
          -3.92,
          31.82,
          "-",
          35600.0,
        ],
      },
      { 8299: ["8299", "EG", 58370000.0, 0.039, 0.0, 0.0, "-", "-", "-"] },
      { 8300: ["8300", "EG", 89870000.0, 0.034, 0.0, 0.0, "-", "-", 1360.0] },
      {
        8305: [
          "8305",
          "EG",
          320250000.0,
          0.305,
          -0.01,
          -3.17,
          "-",
          "-",
          1650000.0,
        ],
      },
      { 8307: ["8307", "EG", 87280000.0, 0.22, 0.0, 0.0, 95.65, 11.36, "-"] },
      { 8308: ["8308", "EG", 171500000.0, 0.175, 0.0, 0.0, 51.47, "-", "-"] },
      {
        8309: ["8309", "EG", 54000000.0, 0.09, -0.001, -1.1, 1.89, 16.67, "-"],
      },
      { 8310: ["8310", "EG", 121070000.0, 0.094, 0.0, 0.0, 0.21, "-", "-"] },
      {
        8311: ["8311", "EG", 332340000.0, 0.224, -0.001, -0.44, "-", "-", "-"],
      },
      { 8313: ["8313", "EG", 76000000.0, 0.038, 0.0, 0.0, "-", "-", "-"] },
      { 8315: ["8315", "EG", 366300000.0, 0.63, 0.0, 0.0, "-", "-", "-"] },
      {
        8316: ["8316", "EG", 832000000.0, 1.04, 0.05, 5.05, "-", "-", 300900.0],
      },
      { 8317: ["8317", "EG", 266610000.0, 0.4, 0.0, 0.0, "-", "-", "-"] },
      { 8319: ["8319", "EG", 85950000.0, 0.107, 0.0, 0.0, 3.93, 8.41, "-"] },
      {
        8320: ["8320", "EG", 61730000.0, 0.088, -0.001, -1.12, 16.0, "-", "-"],
      },
      { 8321: ["8321", "EG", 175100000.0, 0.76, 0.0, 0.0, "-", "-", 15200.0] },
      { 8325: ["8325", "EG", 378810000.0, 0.16, 0.0, 0.0, "-", "-", 5070.0] },
      { 8326: ["8326", "EG", 161960000.0, 0.198, 0.0, 0.0, "-", "-", "-"] },
      {
        8328: [
          "8328",
          "EG",
          3060000000.0,
          4.3,
          -0.1,
          -2.27,
          142.38,
          "-",
          4000000.0,
        ],
      },
      {
        8329: [
          "8329",
          "EG",
          84770000.0,
          0.199,
          -0.001,
          -0.5,
          7.74,
          "-",
          19440.0,
        ],
      },
      { 8331: ["8331", "EG", 60460000.0, 0.38, 0.0, 0.0, 4.15, "-", "-"] },
      { 8333: ["8333", "EG", 227860000.0, 0.191, 0.0, 0.0, "-", 1.31, "-"] },
      { 8337: ["8337", "EG", 42520000.0, 0.23, 0.0, 0.0, "-", "-", "-"] },
      { 8340: ["8340", "EG", 38400000.0, 0.06, 0.0, 0.0, "-", "-", "-"] },
      {
        8341: ["8341", "EG", 29600000.0, 0.037, 0.001, 2.78, "-", "-", 2640.0],
      },
      {
        8347: [
          "8347",
          "EG",
          40320000.0,
          0.04,
          0.001,
          2.56,
          5.48,
          "-",
          400940.0,
        ],
      },
      { 8348: ["8348", "EG", 34870000.0, 0.355, 0.0, 0.0, "-", "-", "-"] },
      {
        8349: [
          "8349",
          "EG",
          824000000.0,
          2.06,
          0.0,
          0.0,
          125.61,
          "-",
          826000.0,
        ],
      },
      { 8350: ["8350", "EG", 196000000.0, 0.245, 0.0, 0.0, "-", "-", "-"] },
      { 8353: ["8353", "EG", 161160000.0, 0.4, 0.0, 0.0, 13.11, "-", "-"] },
      { 8356: ["8356", "EG", 48660000.0, 0.012, 0.0, 0.0, "-", "-", 8760.0] },
      { 8357: ["8357", "EG", 205920000.0, 0.33, 0.0, 0.0, 550.0, "-", "-"] },
      { 8360: ["8360", "EG", 145690000.0, 0.41, 0.0, 0.0, "-", "-", "-"] },
      { 8362: ["8362", "EG", 56000000.0, 0.04, -0.001, -2.44, "-", "-", "-"] },
      {
        8363: [
          "8363",
          "EG",
          82360000.0,
          0.18,
          -0.02,
          -10.0,
          "-",
          "-",
          118950.0,
        ],
      },
      {
        8365: [
          "8365",
          "EG",
          327910000.0,
          0.55,
          0.065,
          13.4,
          "-",
          "-",
          2140000.0,
        ],
      },
      { 8366: ["8366", "EG", 34690000.0, 0.022, 0.0, 0.0, "-", "-", "-"] },
      {
        8367: [
          "8367",
          "EG",
          446400000.0,
          0.465,
          0.035,
          8.14,
          46.97,
          "-",
          632050.0,
        ],
      },
      { 8368: ["8368", "EG", 114570000.0, 0.34, 0.0, 0.0, 4.56, "-", "-"] },
      {
        8370: [
          "8370",
          "EG",
          272190000.0,
          0.3,
          -0.01,
          -3.23,
          "-",
          "-",
          594480.0,
        ],
      },
      { 8371: ["8371", "EG", 348230000.0, 0.9, 0.0, 0.0, 13.82, 8.89, "-"] },
      {
        8372: [
          "8372",
          "EG",
          49600000.0,
          0.062,
          -0.003,
          -4.62,
          6.33,
          7.26,
          3050.0,
        ],
      },
      { 8373: ["8373", "EG", 108000000.0, 0.27, 0.0, 0.0, "-", "-", "-"] },
      {
        8375: [
          "8375",
          "EG",
          66239999.99999999,
          0.23,
          0.01,
          4.55,
          48.94,
          3.04,
          211730.0,
        ],
      },
      { 8377: ["8377", "EG", 194340000.0, 0.475, 0.0, 0.0, "-", "-", 22250.0] },
      { 8379: ["8379", "EG", 119200000.0, 0.149, 0.0, 0.0, "-", "-", 16400.0] },
      { 8383: ["8383", "EG", 128000000.0, 0.16, 0.0, 0.0, 9.7, "-", "-"] },
      { 8385: ["8385", "EG", 40800000.0, 0.051, -0.002, -3.77, "-", "-", "-"] },
      { 8391: ["8391", "EG", 486230000.0, 0.8, 0.01, 1.27, "-", "-", 80000.0] },
      {
        8392: [
          "8392",
          "EG",
          117000000.0,
          0.117,
          0.004,
          3.54,
          "-",
          "-",
          345400.0,
        ],
      },
      {
        8395: [
          "8395",
          "EG",
          1520000000.0,
          0.96,
          -0.02,
          -2.04,
          104.35,
          0.83,
          91600.0,
        ],
      },
      { 8400: ["8400", "EG", 42000000.0, 0.042, 0.0, 0.0, "-", "-", "-"] },
      { 8401: ["8401", "EG", 90000000.0, 0.45, 0.01, 2.27, "-", "-", 10890.0] },
      {
        8402: [
          "8402",
          "EG",
          1430000000.0,
          2.98,
          -0.03,
          -1.0,
          "-",
          "-",
          14900.0,
        ],
      },
      { 8403: ["8403", "EG", 100000000.0, 1.0, 0.0, 0.0, "-", "-", "-"] },
      { 8405: ["8405", "EG", 360000000.0, 0.9, 0.01, 1.12, 7.79, 17.78, "-"] },
      {
        8406: [
          "8406",
          "EG",
          784000000.0,
          0.98,
          0.03,
          3.16,
          28.24,
          "-",
          562300.0,
        ],
      },
      { 8411: ["8411", "EG", 183000000.0, 0.183, 0.0, 0.0, 10.96, 1.64, "-"] },
      { 8412: ["8412", "EG", 33950000.0, 0.034, 0.0, 0.0, "-", "-", 18550.0] },
      {
        8413: [
          "8413",
          "EG",
          241690000.0,
          0.208,
          0.014,
          7.22,
          "-",
          "-",
          60880.0,
        ],
      },
      { 8416: ["8416", "EG", 49200000.0, 0.123, 0.003, 2.5, "-", "-", 4860.0] },
      { 8417: ["8417", "EG", 54260000.0, 0.031, 0.0, 0.0, 8.86, "-", "-"] },
      { 8418: ["8418", "EG", 901000000.0, 1.06, 0.0, 0.0, "-", "-", 350680.0] },
      { 8419: ["8419", "EG", 74400000.0, 0.186, 0.0, 0.0, 10.39, "-", "-"] },
      { 8420: ["8420", "EG", 128880000.0, 0.179, 0.0, 0.0, "-", "-", "-"] },
      { 8422: ["8422", "EG", 49200000.0, 0.041, -0.001, -2.38, "-", "-", "-"] },
      {
        8423: [
          "8423",
          "EG",
          160000000.0,
          0.2,
          -0.003,
          -1.48,
          7.46,
          1.25,
          20000.0,
        ],
      },
      {
        8425: [
          "8425",
          "EG",
          144000000.0,
          0.36,
          0.015,
          4.35,
          31.58,
          "-",
          1880000.0,
        ],
      },
      {
        8426: [
          "8426",
          "EG",
          120000000.0,
          0.15,
          0.01,
          7.14,
          6.17,
          10.0,
          49450.0,
        ],
      },
      { 8427: ["8427", "EG", 20640000.0, 0.2, 0.005, 2.56, "-", "-", 34330.0] },
      { 8428: ["8428", "EG", 31880000.0, 0.082, 0.0, 0.0, "-", "-", 132860.0] },
      { 8429: ["8429", "EG", 76800000.0, 0.16, 0.0, 0.0, "-", "-", 392710.0] },
      { 8430: ["8430", "EG", 78080000.0, 0.1, 0.008, 8.7, "-", "-", 4040.0] },
      { 8431: ["8431", "EG", 36400000.0, 0.028, 0.0, 0.0, "-", "-", "-"] },
      { 8432: ["8432", "EG", 46440000.0, 0.054, 0.001, 1.89, "-", "-", "-"] },
      { 8436: ["8436", "EG", 79600000.0, 0.199, 0.0, 0.0, 4.33, "-", "-"] },
      {
        8437: [
          "8437",
          "EG",
          104540000.0,
          0.121,
          0.001,
          0.83,
          "-",
          "-",
          577220.0,
        ],
      },
      { 8439: ["8439", "EG", 198770000.0, 1.4, 0.0, 0.0, 23.22, 2.71, "-"] },
      {
        8441: [
          "8441",
          "EG",
          664000000.0,
          0.83,
          -0.01,
          -1.19,
          10.02,
          3.92,
          2730000.0,
        ],
      },
      { 8445: ["8445", "EG", 62820000.0, 0.3, 0.0, 0.0, "-", "-", "-"] },
      {
        8446: [
          "8446",
          "EG",
          864000000.0,
          1.08,
          0.01,
          0.93,
          "-",
          "-",
          2300000.0,
        ],
      },
      {
        8447: ["8447", "EG", 79000000.0, 0.079, -0.002, -2.47, 20.79, "-", "-"],
      },
      {
        8448: ["8448", "EG", 23400000.0, 0.026, 0.001, 4.0, "-", "-", 66560.0],
      },
      { 8450: ["8450", "EG", 65000000.0, 0.065, -0.001, -1.52, "-", "-", "-"] },
      {
        8451: [
          "8451",
          "EG",
          47200000.0,
          0.059,
          -0.018,
          -23.38,
          34.71,
          "-",
          79270.0,
        ],
      },
      { 8452: ["8452", "EG", 41770000.0, 0.465, 0.0, 0.0, 43.87, 0.77, "-"] },
      { 8455: ["8455", "EG", 41600000.0, 0.052, 0.0, 0.0, "-", "-", "-"] },
      { 8456: ["8456", "EG", 89890000.0, 0.425, 0.0, 0.0, "-", "-", 50150.0] },
      { 8460: ["8460", "EG", 37400000.0, 0.034, 0.0, 0.0, 68.0, "-", 42260.0] },
      { 8462: ["8462", "EG", 80400000.0, 0.134, 0.0, 0.0, 58.26, "-", "-"] },
      {
        8471: [
          "8471",
          "EG",
          209100000.0,
          0.246,
          -0.019,
          -7.17,
          "-",
          "-",
          134320.0,
        ],
      },
      {
        8472: [
          "8472",
          "EG",
          584000000.0,
          1.46,
          0.02,
          1.39,
          31.74,
          "-",
          5440000.0,
        ],
      },
      {
        8473: [
          "8473",
          "EG",
          255360000.0,
          0.228,
          0.008,
          3.64,
          9.42,
          10.53,
          24380.0,
        ],
      },
      { 8475: ["8475", "EG", 39600000.0, 0.09, 0.0, 0.0, "-", "-", 4500.0] },
      {
        8476: [
          "8476",
          "EG",
          260399999.99999997,
          0.93,
          0.03,
          3.33,
          9.52,
          2.69,
          132100.0,
        ],
      },
      { 8479: ["8479", "EG", 585900000.0, 0.63, 0.0, 0.0, 32.14, "-", "-"] },
      { 8480: ["8480", "EG", 151200000.0, 0.27, 0.0, 0.0, "-", "-", "-"] },
      { 8481: ["8481", "EG", 100000000.0, 0.2, 0.0, 0.0, 9.17, "-", "-"] },
      {
        8482: [
          "8482",
          "EG",
          298200000.0,
          0.355,
          -0.005,
          -1.39,
          61.21,
          "-",
          "-",
        ],
      },
      {
        8483: ["8483", "EG", 99200000.0, 0.124, 0.006, 5.08, "-", "-", 5760.0],
      },
      {
        8487: [
          "8487",
          "EG",
          189200000.0,
          0.215,
          -0.095,
          -30.65,
          "-",
          "-",
          1590000.0,
        ],
      },
      { 8489: ["8489", "EG", 91500000.0, 0.305, 0.0, 0.0, 2.96, "-", 12050.0] },
      {
        8490: ["8490", "EG", 246920000.0, 0.35, 0.02, 6.06, "-", 0.86, 13650.0],
      },
      { 8491: ["8491", "EG", 54080000.0, 0.82, -0.01, -1.2, "-", "-", 5540.0] },
      { 8493: ["8493", "EG", 14160000.0, 0.082, 0.0, 0.0, "-", "-", "-"] },
      {
        8495: [
          "8495",
          "EG",
          128639999.99999999,
          0.335,
          0.02,
          6.35,
          "-",
          "-",
          6800.0,
        ],
      },
      { 8496: ["8496", "EG", 127200000.0, 0.53, 0.0, 0.0, "-", "-", 200900.0] },
      { 8500: ["8500", "EG", 77400000.0, 0.43, 0.0, 0.0, 1.04, "-", "-"] },
      {
        8501: [
          "8501",
          "EG",
          190000000.0,
          0.95,
          0.03,
          3.26,
          475.0,
          "-",
          55100.0,
        ],
      },
      {
        8502: [
          "8502",
          "EG",
          200000000.0,
          0.25,
          -0.01,
          -3.85,
          3.8,
          "-",
          18480.0,
        ],
      },
      { 8506: ["8506", "EG", 1310000000.0, 1.31, 0.0, 0.0, 72.38, "-", "-"] },
      { 8507: ["8507", "EG", 74400000.0, 0.186, 0.0, 0.0, "-", "-", "-"] },
      {
        8509: [
          "8509",
          "EG",
          190000000.0,
          0.475,
          -0.025,
          -5.0,
          6.88,
          "-",
          11320.0,
        ],
      },
      {
        8510: ["8510", "EG", 89850000.0, 0.065, -0.001, -1.52, 3.19, "-", "-"],
      },
      {
        8511: ["8511", "EG", 192000000.0, 0.48, 0.035, 7.87, "-", "-", 42000.0],
      },
      {
        8512: [
          "8512",
          "EG",
          272800000.0,
          0.248,
          0.011,
          4.64,
          3.01,
          "-",
          120650.0,
        ],
      },
      {
        8513: [
          "8513",
          "EG",
          100510000.0,
          0.211,
          0.001,
          0.48,
          21.75,
          "-",
          50660.0,
        ],
      },
      { 8516: ["8516", "EG", 62830000.0, 1.1, 0.03, 2.8, "-", "-", 5500.0] },
      {
        8519: ["8519", "EG", 118310000.0, 0.102, 0.0, 0.0, "-", "-", 477030.0],
      },
      {
        8521: [
          "8521",
          "EG",
          65760000.00000001,
          0.137,
          0.0,
          0.0,
          10.79,
          "-",
          "-",
        ],
      },
      {
        8523: [
          "8523",
          "EG",
          92400000.0,
          0.231,
          0.021,
          10.0,
          4.48,
          "-",
          35800.0,
        ],
      },
      {
        8525: ["8525", "EG", 143100000.0, 0.53, -0.01, -1.85, 19.2, 3.77, "-"],
      },
      { 8526: ["8526", "EG", 45430000.0, "-", 0.0, 0.0, 1.78, "-", "-"] },
      {
        8527: [
          "8527",
          "EG",
          1660000000.0,
          3.33,
          0.03,
          0.91,
          "-",
          "-",
          1620000.0,
        ],
      },
      { 8532: ["8532", "EG", 76800000.0, 0.096, 0.0, 0.0, 40.0, "-", "-"] },
      {
        8535: [
          "8535",
          "EG",
          516000000.0,
          0.43,
          0.01,
          2.38,
          18.07,
          2.79,
          467600.0,
        ],
      },
      {
        8536: [
          "8536",
          "EG",
          54060000.0,
          0.305,
          0.035,
          12.96,
          "-",
          "-",
          1120000.0,
        ],
      },
      { 8537: ["8537", "EG", 60000000.0, 0.08, 0.0, 0.0, "-", "-", "-"] },
      { 8540: ["8540", "EG", 458090000.0, 2.29, 0.0, 0.0, 19.51, 1.31, "-"] },
      {
        8545: [
          "8545",
          "EG",
          63000000.0,
          0.063,
          0.003,
          5.0,
          7.24,
          "-",
          150340.0,
        ],
      },
      {
        8547: [
          "8547",
          "EG",
          88440000.0,
          0.067,
          0.005,
          8.06,
          "-",
          "-",
          228010.0,
        ],
      },
      { 8555: ["8555", "EG", 69980000.0, 0.243, 0.0, 0.0, 51.7, 2.88, "-"] },
      { 8557: ["8557", "EG", 41460000.0, 0.83, 0.0, 0.0, "-", "-", "-"] },
      { 8558: ["8558", "EG", "-", 0.01, 0.0, 0.0, "-", "-", 9900.0] },
      {
        8559: ["8559", "EG", 660510000.0, 1.06, 0.04, 3.92, "-", "-", 51880.0],
      },
      {
        8560: [
          "8560",
          "EG",
          197940000.0,
          1.22,
          0.02,
          1.67,
          7.76,
          "-",
          273450.0,
        ],
      },
      { 8561: ["8561", "EG", "-", 0.065, -0.009, -12.16, "-", "-", 43660.0] },
      { 8585: ["8585", "EG", 457030000.0, 1.275, 0.0, 0.0, 8.5, 8.35, "-"] },
      { 8599: ["8599", "EG", 143210000.0, 0.275, 0.0, 0.0, 57.29, "-", "-"] },
      { 8601: ["8601", "EG", 224000000.0, 0.28, 0.0, 0.0, 9.33, "-", "-"] },
      { 8603: ["8603", "EG", 296000000.0, 0.37, 0.0, 0.0, "-", "-", "-"] },
      {
        8606: [
          "8606",
          "EG",
          192780000.0,
          0.2,
          -0.003,
          -1.48,
          15.5,
          "-",
          33100.0,
        ],
      },
      { 8607: ["8607", "EG", 122400000.0, 0.153, 0.0, 0.0, "-", "-", "-"] },
      { 8609: ["8609", "EG", 135000000.0, 0.27, 0.0, 0.0, 3.17, "-", "-"] },
      { 8611: ["8611", "EG", 42120000.0, 0.108, 0.0, 0.0, "-", "-", "-"] },
      {
        8612: ["8612", "EG", 80360000.0, 0.098, 0.005, 5.38, "-", "-", 85930.0],
      },
      {
        8613: [
          "8613",
          "EG",
          138000000.0,
          0.138,
          0.041,
          42.27,
          "-",
          "-",
          3020000.0,
        ],
      },
      {
        8616: ["8616", "EG", 127000000.0, 0.127, 0.0, 0.0, 10.08, "-", 15240.0],
      },
      { 8617: ["8617", "EG", 224000000.0, 0.56, 0.0, 0.0, 16.42, 3.57, "-"] },
      {
        8619: [
          "8619",
          "EG",
          135930000.0,
          0.118,
          -0.005,
          -4.07,
          17.88,
          "-",
          57650.0,
        ],
      },
      {
        8620: [
          "8620",
          "EG",
          119520000.0,
          0.249,
          0.004,
          1.63,
          "-",
          "-",
          26110.0,
        ],
      },
      { 8621: ["8621", "EG", 67200000.0, 0.07, -0.001, -1.41, 7.0, "-", "-"] },
      { 8622: ["8622", "EG", 102000000.0, 0.255, 0.0, 0.0, "-", "-", "-"] },
      {
        8623: ["8623", "EG", 74520000.0, 0.081, -0.002, -2.41, 25.31, "-", "-"],
      },
      {
        8627: [
          "8627",
          "EG",
          161600000.0,
          0.202,
          -0.005,
          -2.42,
          "-",
          "-",
          759080.0,
        ],
      },
      { 8631: ["8631", "EG", 33600000.0, 0.084, -0.012, -12.5, "-", "-", "-"] },
      {
        8635: [
          "8635",
          "EG",
          52000000.0,
          0.13,
          -0.007,
          -5.11,
          3.99,
          1.54,
          50790.0,
        ],
      },
      { 8645: ["8645", "EG", 444000000.0, 0.74, 0.0, 0.0, "-", "-", "-"] },
      {
        8646: ["8646", "EG", 108000000.0, 0.36, 0.03, 9.09, 8.89, "-", 1110.0],
      },
      { 8657: ["8657", "EG", 368000000.0, 0.92, 0.0, 0.0, 11.29, 2.17, "-"] },
      {
        8659: [
          "8659",
          "EG",
          119400000.0,
          0.199,
          -0.001,
          -0.5,
          4.26,
          "-",
          40050.0,
        ],
      },
      { 8668: ["8668", "EG", 156000000.0, 0.13, 0.0, 0.0, "-", "-", 1300.0] },
      { 9001: ["9001", "F", 156900000.0, 29.9, 0.1, 0.34, "-", "-", 22130.0] },
      {
        9010: [
          "9010",
          "F",
          19870000000.0,
          7.825,
          0.195,
          2.56,
          "-",
          1.71,
          3950000.0,
        ],
      },
      { 9011: ["9011", "F", 3050000000.0, 1030.7, 0.0, 0.0, "-", "-", "-"] },
      { 9022: ["9022", "F", 108930000.0, 70.4, 1.22, 1.76, "-", 2.1, "-"] },
      { 9031: ["9031", "F", 403430000.0, 1.574, 0.022, 1.42, "-", "-", "-"] },
      {
        9047: ["9047", "F", 198660000.0, 1.908, 0.01, 0.53, "-", "-", 761820.0],
      },
      {
        9067: [
          "9067",
          "F",
          9700000000.0,
          1.496,
          0.044,
          3.03,
          "-",
          "-",
          4450000.0,
        ],
      },
      { 9069: ["9069", "F", 1000000000.0, 1.866, 0.036, 1.97, "-", "-", "-"] },
      {
        9072: [
          "9072",
          "F",
          143220000.0,
          16.44,
          0.45,
          2.81,
          "-",
          "-",
          1920000.0,
        ],
      },
      {
        9074: ["9074", "F", 598470000.0, 26.44, 0.92, 3.61, "-", 1.8, 949070.0],
      },
      { 9077: ["9077", "F", 11700000.0, 500.15, 0.0, 0.0, "-", 0.13, "-"] },
      { 9078: ["9078", "F", 11850000.0, 506.95, 0.05, 0.01, "-", "-", "-"] },
      {
        9081: [
          "9081",
          "F",
          1760000000.0,
          5.525,
          0.045,
          0.82,
          "-",
          "-",
          99020.0,
        ],
      },
      { 9086: ["9086", "F", 1660000000.0, 3.822, 0.064, 1.7, "-", 0.17, "-"] },
      {
        9088: ["9088", "F", 807350000.0, 0.902, 0.026, 2.97, "-", "-", 2770.0],
      },
      { 9091: ["9091", "F", 135780000.0, 12.36, 0.28, 2.32, "-", "-", "-"] },
      { 9096: ["9096", "F", 1030000000.0, 102.6, 0.0, 0.0, "-", "-", "-"] },
      {
        9115: [
          "9115",
          "F",
          682050000.0,
          11.33,
          0.36,
          3.28,
          "-",
          2.11,
          263700.0,
        ],
      },
      { 9125: ["9125", "F", 253880000.0, 7.98, 0.0, 0.0, "-", "-", "-"] },
      {
        9141: ["9141", "F", 1320000000.0, 2.002, 0.0, 0.0, "-", 2.94, 156000.0],
      },
      { 9151: ["9151", "F", 467270000.0, 1.26, 0.014, 1.12, "-", "-", "-"] },
      { 9167: ["9167", "F", 475020000.0, 9.74, 0.335, 3.56, "-", "-", "-"] },
      { 9173: ["9173", "F", 2310000000.0, 1.432, 0.004, 0.28, "-", "-", "-"] },
      { 9181: ["9181", "F", 72530000.0, 14.46, 0.42, 2.99, "-", "-", "-"] },
      { 9186: ["9186", "F", 20190000.0, 6.785, 0.265, 4.06, "-", "-", "-"] },
      {
        9187: [
          "9187",
          "F",
          212200000.0,
          2.612,
          0.036,
          1.4,
          "-",
          5.24,
          8140.000000000001,
        ],
      },
      {
        9191: [
          "9191",
          "F",
          1070000000.0000001,
          7.46,
          0.12,
          1.63,
          "-",
          "-",
          194830.0,
        ],
      },
      {
        9600: [
          "9600",
          "EM",
          1430000000.0,
          1.82,
          0.02,
          1.11,
          29.12,
          "-",
          986900.0,
        ],
      },
      {
        9608: [
          "9608",
          "EM",
          364800000.0,
          0.114,
          -0.001,
          -0.87,
          7.17,
          "-",
          83650.0,
        ],
      },
      {
        9616: [
          "9616",
          "EM",
          3240000000.0,
          4.86,
          0.0,
          0.0,
          30.21,
          0.56,
          130889.99999999999,
        ],
      },
      {
        9618: [
          "9618",
          "EM",
          891360000000.0,
          284.8,
          5.2,
          1.86,
          "-",
          "-",
          2040000000.0,
        ],
      },
      {
        9626: [
          "9626",
          "EM",
          97850000000.0,
          254.6,
          11.8,
          4.86,
          "-",
          "-",
          302470000.0,
        ],
      },
      {
        9633: [
          "9633",
          "EM",
          245940000000.0,
          48.85,
          1.85,
          3.94,
          85.93,
          0.42,
          202490000.0,
        ],
      },
      {
        9666: [
          "9666",
          "EM",
          27410000000.0,
          42.0,
          0.1,
          0.24,
          28.66,
          1.44,
          35110000.0,
        ],
      },
      {
        9668: [
          "9668",
          "EM",
          12580000000.0,
          2.03,
          0.08,
          4.1,
          3.68,
          5.06,
          7090000.0,
        ],
      },
      {
        9677: ["9677", "EM", 3250000000.0, 3.23, 0.0, 0.0, 10.61, 3.76, 3230.0],
      },
      {
        9688: [
          "9688",
          "EM",
          34800000000.0,
          395.4,
          50.8,
          14.74,
          "-",
          "-",
          49100000.0,
        ],
      },
      {
        9698: [
          "9698",
          "EM",
          59280000000.0,
          39.65,
          2.95,
          8.04,
          "-",
          "-",
          37170000.0,
        ],
      },
      {
        9699: [
          "9699",
          "EM",
          2560000000.0,
          11.1,
          -0.5,
          -4.31,
          "-",
          "-",
          416190.0,
        ],
      },
      { 9801: ["9801", "F", 5450000000.0, 3.392, 0.094, 2.85, "-", 1.51, "-"] },
      { 9803: ["9803", "F", 588590000.0, 1.12, 0.01, 0.9, "-", 2.55, "-"] },
      { 9804: ["9804", "F", 238510000.0, 12.9, 0.03, 0.23, "-", "-", "-"] },
      { 9806: ["9806", "F", 769430000.0, 8.28, 0.28, 3.5, "-", "-", 675420.0] },
      {
        9807: ["9807", "F", 302660000.0, 7.69, 0.135, 1.79, "-", "-", 110670.0],
      },
      {
        9809: [
          "9809",
          "F",
          3220000000.0,
          17.96,
          0.27,
          1.53,
          "-",
          "-",
          1350000.0,
        ],
      },
      { 9810: ["9810", "F", 354180000.0, 9.45, 0.09, 0.96, "-", 1.92, "-"] },
      { 9812: ["9812", "F", 188040000.0, 1.734, 0.05, 2.97, "-", "-", "-"] },
      { 9813: ["9813", "F", 19840000.0, 16.92, 0.09, 0.53, "-", 2.04, "-"] },
      { 9814: ["9814", "F", 94820000.0, 2.58, 0.07, 2.79, "-", "-", 694390.0] },
      { 9817: ["9817", "F", 1090000000.0, 16.95, 0.0, 0.0, "-", "-", "-"] },
      {
        9820: [
          "9820",
          "F",
          1480000000.0,
          10.65,
          0.23,
          2.21,
          "-",
          "-",
          568900.0,
        ],
      },
      {
        9826: [
          "9826",
          "F",
          745560000.0,
          9.155,
          0.265,
          2.98,
          "-",
          "-",
          1830000.0,
        ],
      },
      { 9829: ["9829", "F", 4050000000.0, 8.095, 0.0, 0.0, "-", "-", "-"] },
      {
        9834: [
          "9834",
          "F",
          365310000.0,
          35.82,
          0.76,
          2.17,
          "-",
          "-",
          1670000.0,
        ],
      },
      { 9836: ["9836", "F", 748920000.0, 4.46, 0.086, 1.97, "-", "-", "-"] },
      { 9839: ["9839", "F", 1350000000.0, 4.034, 0.044, 1.1, "-", "-", "-"] },
      {
        9845: [
          "9845",
          "F",
          7100000000.0,
          20.68,
          0.44,
          2.17,
          "-",
          "-",
          9100000.0,
        ],
      },
      {
        9846: [
          "9846",
          "F",
          471920000.0,
          4.752,
          0.062,
          1.32,
          "-",
          0.9,
          670260.0,
        ],
      },
      {
        9858: [
          "9858",
          "EM",
          16200000000.0,
          4.27,
          -0.04,
          -0.93,
          "-",
          "-",
          3080000.0,
        ],
      },
      {
        9868: [
          "9868",
          "EM",
          237900000000.0,
          138.9,
          14.0,
          11.21,
          "-",
          "-",
          362340000.0,
        ],
      },
      {
        9869: [
          "9869",
          "EM",
          22700000000.0,
          17.92,
          -0.32,
          -1.75,
          "-",
          "-",
          36980000.0,
        ],
      },
      {
        9888: [
          "9888",
          "EM",
          431110000000.0,
          152.4,
          8.6,
          5.98,
          "-",
          "-",
          484720000.0,
        ],
      },
      {
        9889: ["9889", "EM", 8740000000.0, 7.62, 0.02, 0.26, "-", "-", 38000.0],
      },
      {
        9898: [
          "9898",
          "EM",
          61110000000.0,
          251.2,
          -1.8,
          -0.71,
          "-",
          "-",
          5210000.0,
        ],
      },
      {
        9899: [
          "9899",
          "EM",
          26170000000.0,
          126.0,
          3.0,
          2.44,
          "-",
          "-",
          785800.0,
        ],
      },
      {
        9900: [
          "9900",
          "EM",
          1060000000.0,
          2.85,
          -0.04,
          -1.38,
          31.11,
          "-",
          1360000.0,
        ],
      },
      {
        9901: [
          "9901",
          "EM",
          18850000000.0,
          11.0,
          0.44,
          4.17,
          "-",
          "-",
          27700000.0,
        ],
      },
      {
        9906: [
          "9906",
          "EM",
          1450000000.0,
          2.42,
          0.01,
          0.41,
          48.89,
          "-",
          1030000.0,
        ],
      },
      {
        9908: [
          "9908",
          "EM",
          450340000.0,
          11.9,
          0.12,
          1.02,
          12.82,
          3.67,
          413150.0,
        ],
      },
      {
        9909: [
          "9909",
          "EM",
          10740000000.0,
          16.72,
          0.4,
          2.45,
          28.67,
          1.97,
          4910000.0,
        ],
      },
      {
        9911: [
          "9911",
          "EM",
          4490000000.0,
          4.09,
          0.01,
          0.25,
          87.39,
          "-",
          11930000.0,
        ],
      },
      {
        9913: [
          "9913",
          "EM",
          3380000000.0,
          3.38,
          -0.02,
          -0.59,
          71.76,
          "-",
          121920.0,
        ],
      },
      {
        9916: [
          "9916",
          "EM",
          384000000.0,
          0.96,
          -0.01,
          -1.03,
          7.13,
          "-",
          207020.0,
        ],
      },
      {
        9918: [
          "9918",
          "EM",
          188000000.0,
          0.094,
          -0.003,
          -3.09,
          134.29,
          5.32,
          35750.0,
        ],
      },
      {
        9919: [
          "9919",
          "EM",
          1190000000.0,
          1.59,
          -0.03,
          -1.85,
          44.41,
          5.01,
          258339.99999999997,
        ],
      },
      {
        9922: [
          "9922",
          "EM",
          25660000000.0,
          17.66,
          0.98,
          5.88,
          168.03,
          0.23,
          169760000.0,
        ],
      },
      {
        9923: [
          "9923",
          "EM",
          10550000000.0,
          23.35,
          0.45,
          1.97,
          13.7,
          "-",
          37600000.0,
        ],
      },
      {
        9926: [
          "9926",
          "EM",
          16090000000.0,
          19.7,
          -1.3,
          -6.19,
          "-",
          "-",
          67390000.0,
        ],
      },
      {
        9928: [
          "9928",
          "EM",
          2980000000.0,
          3.03,
          -0.02,
          -0.66,
          10.41,
          2.82,
          3450000.0,
        ],
      },
      { 9929: ["9929", "EM", 136000000.0, 0.068, 0.0, 0.0, "-", "-", "-"] },
      {
        9933: [
          "9933",
          "EM",
          385000000.0,
          0.385,
          0.03,
          8.45,
          "-",
          "-",
          283120.0,
        ],
      },
      {
        9936: [
          "9936",
          "EM",
          1480000000.0,
          4.94,
          0.36,
          7.86,
          17.13,
          "-",
          14640000.0,
        ],
      },
      {
        9938: [
          "9938",
          "EM",
          117000000.0,
          0.117,
          -0.003,
          -2.5,
          19.5,
          "-",
          3510.0,
        ],
      },
      {
        9939: [
          "9939",
          "EM",
          3090000000.0,
          7.98,
          0.2,
          2.57,
          "-",
          "-",
          29450000.0,
        ],
      },
      {
        9956: [
          "9956",
          "EM",
          7990000000.0,
          6.88,
          0.17,
          2.53,
          "-",
          "-",
          834400.0,
        ],
      },
      { 9958: ["9958", "EM", 342000000.0, 1.14, 0.0, 0.0, 3.66, "-", 18240.0] },
      {
        9959: [
          "9959",
          "EM",
          19170000000.0,
          8.32,
          -0.15,
          -1.77,
          "-",
          "-",
          19470000.0,
        ],
      },
      {
        9960: [
          "9960",
          "EM",
          4219999999.9999995,
          4.25,
          0.1,
          2.41,
          "-",
          "-",
          1900000.0,
        ],
      },
      {
        9961: [
          "9961",
          "EM",
          132590000000.0,
          208.0,
          8.0,
          4.0,
          "-",
          "-",
          113370000.0,
        ],
      },
      {
        9963: ["9963", "EM", 356200000.0, 1.37, 0.06, 4.58, 12.62, "-", 8180.0],
      },
      {
        9966: [
          "9966",
          "EM",
          9130000000.0,
          9.75,
          -0.23,
          -2.3,
          "-",
          "-",
          9800000.0,
        ],
      },
      {
        9968: [
          "9968",
          "EM",
          10240000000.0,
          1.95,
          0.02,
          1.04,
          16.74,
          2.54,
          7480000.0,
        ],
      },
      {
        9969: [
          "9969",
          "EM",
          17360000000.0,
          11.58,
          0.1,
          0.87,
          "-",
          "-",
          12470000.0,
        ],
      },
      {
        9977: [
          "9977",
          "EM",
          621250000.0,
          1.75,
          0.04,
          2.34,
          11.82,
          2.03,
          293960.0,
        ],
      },
      {
        9978: ["9978", "EM", 196000000.0, 0.49, -0.01, -2.0, 15.31, 2.04, "-"],
      },
      {
        9979: [
          "9979",
          "EM",
          12680000000.0,
          6.48,
          0.33,
          5.37,
          20.99,
          3.09,
          76580000.0,
        ],
      },
      {
        9982: [
          "9982",
          "EM",
          4510000000.0,
          1.37,
          0.03,
          2.24,
          "-",
          6.28,
          1640000.0,
        ],
      },
      {
        9983: [
          "9983",
          "EM",
          6700000000.0,
          5.28,
          0.31,
          6.24,
          11.75,
          6.16,
          3090000.0,
        ],
      },
      {
        9986: [
          "9986",
          "EM",
          272000000.0,
          0.34,
          0.005,
          1.49,
          97.14,
          "-",
          25820.0,
        ],
      },
      {
        9987: [
          "9987",
          "EM",
          153050000000.0,
          365.2,
          0.6,
          0.16,
          23.53,
          1.02,
          90810000.0,
        ],
      },
      {
        9988: [
          "9988",
          "EM",
          2611150000000.0,
          120.4,
          6.4,
          5.61,
          "-",
          "-",
          5180000000.0,
        ],
      },
      {
        9989: [
          "9989",
          "EM",
          1610000000.0,
          7.35,
          0.0,
          0.0,
          8.27,
          2.45,
          1460000.0,
        ],
      },
      {
        9990: [
          "9990",
          "EM",
          6960000000.0,
          8.56,
          0.39,
          4.77,
          "-",
          0.86,
          4340000.0,
        ],
      },
      {
        9991: [
          "9991",
          "EM",
          7850000000.0,
          31.9,
          1.8,
          5.98,
          "-",
          "-",
          2110000.0,
        ],
      },
      {
        9992: [
          "9992",
          "EM",
          55510000000.0,
          39.6,
          1.15,
          2.99,
          76.97,
          0.46,
          290960000.0,
        ],
      },
      {
        9993: [
          "9993",
          "EM",
          18280000000.0,
          4.52,
          0.14,
          3.2,
          4.3,
          7.08,
          7160000.0,
        ],
      },
      {
        9995: [
          "9995",
          "EM",
          9570000000.0,
          50.5,
          -0.6,
          -1.17,
          "-",
          "-",
          117900000.0,
        ],
      },
      {
        9996: [
          "9996",
          "EM",
          7490000000.0,
          11.16,
          0.1,
          0.9,
          "-",
          "-",
          3330000.0,
        ],
      },
      {
        9997: [
          "9997",
          "EM",
          11010000000.0,
          8.85,
          0.34,
          4.0,
          28.59,
          0.5,
          14770000.0,
        ],
      },
      {
        9998: [
          "9998",
          "EM",
          148000000.0,
          0.185,
          -0.004,
          -2.12,
          "-",
          "-",
          5270.0,
        ],
      },
      {
        9999: [
          "9999",
          "EM",
          544179999999.99994,
          157.5,
          2.8,
          1.81,
          36.31,
          0.81,
          532830000.00000006,
        ],
      },
    ],
  },
};
