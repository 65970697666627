import { useEffect, useState } from "react";
// import { Grid, TextField } from "@material-ui/core";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
// import { makeStyles } from "@material-ui/core/styles";
import { Loader, Container, Header } from "semantic-ui-react";
// import SemanticDatepicker from "react-semantic-ui-datepickers";
// import { toDateString } from "../../../util/datetime";
// import { isMobile } from "react-device-detect";
import {
  selectAllTopic,
  fetchTopic,
  toIdle,
} from "../../../features/topic/topicSlice";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

function zip(arrays) {
  return Array.apply(null, Array(arrays[0].length)).map(function (_, i) {
    return arrays.map(function (array) {
      return array[i];
    });
  });
}

function roundData(d) {
  return Math.round(d * 100) / 100;
}

function roundData2(d) {
  return Math.round(d * 10) / 10;
}

export default function HSIChart() {
  const dispatch = useDispatch();
  const topics = useSelector(selectAllTopic);
  const topicStatus = useSelector((state) => state.topic.status);
  const error = useSelector((state) => state.topic.error);
  const [stockStat, setStockStat] = useState({
    data: null,
    loaded: false,
  });

  useEffect(() => {
    if (topicStatus === "idle" && !stockStat.loaded) {
      dispatch(fetchTopic("breadth_from_aws"));
    } else if (topicStatus === "loading") {
    } else if (topicStatus === "succeeded") {
      const data = topics.find(
        (item) => item.topicInfo.name === "breadth_from_aws"
      );
      if (data) {
        setStockStat({
          ...stockStat,
          data: data.data,
          loaded: true,
        });
      } else {
        dispatch(toIdle({}));
      }
    } else if (topicStatus === "error") {
    }
  }, [topicStatus, dispatch]);

  // const hsiColor = "#999999";
  // const breadthColor = "#999999";
  var windowWidth = window.innerWidth;
  const chartHeight = 750;
  // 0.656 * windowWidth < 550
  //   ? 550
  //   : 0.656 * windowWidth > 633
  //   ? 633
  //   : 0.656 * windowWidth;
  let hsiData = [];
  let marketData = [];
  let chartOption = {};
  // console.log(stockStat.data);

  if (stockStat.loaded && stockStat.data.date.length > 0) {
    let dates = stockStat.data["date"].map((item) => new Date(item).getTime());
    hsiData.push({
      yAxis: 0,
      type: "ohlc",
      name: "HSI",
      useOhlcData: true,
      data: zip([
        dates,
        stockStat.data["open"],
        stockStat.data["high"],
        stockStat.data["low"],
        stockStat.data["close"],
      ]),
    });
    marketData.push({
      yAxis: 0,
      type: "ohlc",
      name: "HSI",
      useOhlcData: true,
      data: zip([
        dates,
        stockStat.data["open"],
        stockStat.data["high"],
        stockStat.data["low"],
        stockStat.data["close"],
      ]),
    });
    hsiData.push({
      yAxis: 1,
      name: "HSI 50",
      data: zip([
        dates,
        _.map(stockStat.data["hsi_breadth_50_day"], roundData2),
      ]),
    });
    // console.log(hsiData);
    hsiData.push({
      yAxis: 1,
      name: "HSI 100",
      data: zip([
        dates,
        _.map(stockStat.data["hsi_breadth_100_day"], roundData2),
      ]),
    });
    hsiData.push({
      yAxis: 1,
      name: "HSI 150",
      data: zip([
        dates,
        _.map(stockStat.data["hsi_breadth_150_day"], roundData2),
      ]),
    });
    hsiData.push({
      yAxis: 1,
      name: "HSI 200",
      data: zip([
        dates,
        _.map(stockStat.data["hsi_breadth_200_day"], roundData2),
      ]),
    });
    marketData.push({
      yAxis: 1,
      name: "Market 50",
      data: zip([
        dates,
        _.map(stockStat.data["mkt_breadth_50_day"], roundData2),
      ]),
    });
    marketData.push({
      yAxis: 1,
      name: "Market 100",
      data: zip([
        dates,
        _.map(stockStat.data["mkt_breadth_100_day"], roundData2),
      ]),
    });
    marketData.push({
      yAxis: 1,
      name: "Market 150",
      data: zip([
        dates,
        _.map(stockStat.data["mkt_breadth_150_day"], roundData2),
      ]),
    });
    marketData.push({
      yAxis: 1,
      name: "Market 200",
      data: zip([
        dates,
        _.map(stockStat.data["mkt_breadth_200_day"], roundData2),
      ]),
    });
    chartOption = {
      rangeSelector: {
        selected: 1,
      },

      navigator: {
        series: {
          lineWidth: 1,
          lineColor: "#2F4F4F",
          color: "#2F4F4F",
          fillOpacity: 0.2,
        },
      },

      legend: {
        enabled: true,
        align: "center",
        verticalAlign: "bottom",
        x: 0,
        y: 0,
      },

      chart: {
        height: chartHeight,
        // marginTop: 10,
        // marginBottom: 0,
        marginLeft: 10,
        marginRight: 10,
      },

      xAxis: {
        type: "datetime",
      },

      yAxis: [
        {
          endOnTick: false,
          startOnTick: false,
          labels: {
            style: {
              color: "black",
            },
            align: "right",
            x: -2,
            formatter: function () {
              return this.value;
            },
          },
          title: {
            style: {
              color: Highcharts.getOptions().colors[1],
            },
          },
          // opposite: true,
          height: "55%",
          resize: {
            enabled: true,
          },
        },
        {
          // opposite: false,
          // endOnTick: false,
          labels: {
            style: {
              color: "black",
            },
            align: "right",
            x: 10,
            formatter: function () {
              return this.value + "%";
            },
          },
          title: {
            text: "市寬(MKT)",
            align: "high",
            rotation: 0,
            // y: 10,
            x: -40,
            style: {
              color: Highcharts.getOptions().colors[1],
            },
          },
          // tickPositions: [0, 25, 50, 75, 100],
          top: "60%",
          height: "15%",
          offset: -20,
        },
        {
          // opposite: false,
          // endOnTick: false,
          labels: {
            style: {
              color: "black",
            },
            align: "right",
            x: 10,
            formatter: function () {
              return this.value + "%";
            },
          },
          // gridLineWidth: 0,
          title: {
            text: "市寬(HSI)",
            align: "high",
            rotation: 0,
            // y: 10,
            x: -40,
            style: {
              color: Highcharts.getOptions().colors[1],
            },
          },
          top: "85%",
          height: "15%",
          offset: -20,
        },
      ],

      plotOptions: {
        candlestick: {
          lineColor: "red",
          color: "white",
          upLineColor: "black",
          upColor: "Green",
        },
      },

      tooltip: {
        shared: true,
        style: {
          fontSize: "10px",
        },
      },

      scrollbar: {
        enabled: true,
      },

      series: [
        {
          type: "candlestick",
          id: "HSI",
          name: "恒生指數",
          data: marketData[0].data,
          yAxis: 0,
          showInLegend: false,
        },
        {
          type: "line",
          name: "MKT-MA50",
          data: marketData[1].data,
          yAxis: 1,
          color: "#EB7E30",
          visible: true,
        },
        {
          type: "line",
          name: "MKT-MA100",
          data: marketData[2].data,
          yAxis: 1,
          color: "#0072ce",
          visible: false,
        },
        {
          type: "line",
          name: "MKT-MA150",
          data: marketData[3].data,
          yAxis: 1,
          color: "red",
          visible: true,
        },
        {
          type: "line",
          name: "MKT-MA200",
          data: marketData[4].data,
          yAxis: 1,
          color: "green",
          visible: true,
        },
        {
          type: "line",
          name: "HSI-MA50",
          data: hsiData[1].data,
          yAxis: 2,
          color: "#EB7E30",
          visible: true,
        },
        {
          type: "line",
          name: "HSI-MA100",
          data: hsiData[2].data,
          yAxis: 2,
          color: "#0072ce",
          visible: false,
        },
        {
          type: "line",
          name: "HSI-MA150",
          data: hsiData[3].data,
          yAxis: 2,
          color: "red",
          visible: true,
        },
        {
          type: "line",
          name: "HSI-MA200",
          data: hsiData[4].data,
          yAxis: 2,
          color: "green",
          visible: true,
        },
      ],
      credits: {
        text: "DaaSHK",
        style: {
          fontSize: "0.8em",
        },
      },
    };
  }

  return (
    <>
      {stockStat.data && stockStat.data.date.length > 0 ? (
        <>
          <Header as="h3" textAlign="center">
            恒生指數及市寛
            <Header.Subheader>收市價 {">"} MA 市寬</Header.Subheader>
          </Header>
          <div>
            <HighchartsReact
              constructorType={"stockChart"}
              highcharts={Highcharts}
              options={chartOption}
            />
          </div>
        </>
      ) : !stockStat.loaded ? (
        <Loader active inline="centered" />
      ) : (
        <div>沒有資料</div>
      )}
    </>
  );
}
