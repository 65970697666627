import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { collection, doc, getDoc } from "firebase/firestore";
import { db, analytics } from "../../config/fbConfig";
import { logEvent } from "firebase/analytics";
import _ from "lodash";

const initialState = {
  topic: [],
  status: "idle",
  error: null,
};

export const addNewTopic = createAsyncThunk("topic/addNewTopic", async (id) => {
  let topicRef = collection(db, "topics");
  logEvent(analytics, "select_content", {
    content_type: "topics_" + id,
  });
  const snapshot = await getDoc(doc(topicRef, id));
  return snapshot.data();
});

export const fetchTopic = createAsyncThunk("topic/fetchTopic", async (id) => {
  let topicRef = collection(db, "topics");
  logEvent(analytics, "select_content", {
    content_type: "topics_" + id,
    item_id: id,
  });
  let data = {};
  if (id === "us_rs_from_aws") {
    let data1 = await getDoc(doc(topicRef, "us_rs_from_aws_1"));
    let data2 = await getDoc(doc(topicRef, "us_rs_from_aws_2"));
    let merged = _.map(
      _.concat(data1.data()["data"]["data"], data2.data()["data"]["data"]),
      obj2Array
    );
    let header = data1.data()["data"]["header"];
    let tableData = merged.map((v) => {
      var d = {};
      var e = v[0];
      for (var i = 0; i < header.length; i++) {
        d[header[i]] = e[i];
      }
      return {
        ...d,
      };
    });
    tableData = tableData.filter(function (d) {
      return d.id !== undefined;
    });
    data = {
      topicInfo: {
        lastUpdate: data1.data()["topicInfo"]["lastUpdate"],
        name: "us_rs_from_aws",
      },
      data: tableData,
    };
  } else {
    let data1 = await getDoc(doc(topicRef, id));
    data = data1.data();
  }
  // console.log(data);
  return data;
});

function obj2Array(n) {
  return Object.values(n);
}

export const fetchTopicwM = createAsyncThunk(
  "topic/fetchTopicwM",
  async (id) => {
    let topicRef = collection(db, "topics");
    logEvent(analytics, "select_content", {
      content_type: "topics_" + id,
      item_id: id,
    });
    // console.log("fetch topic");
    const snapshot = await getDoc(doc(topicRef, id));
    const { data, header } = snapshot.data().data;
    var data1 = _.map(data, obj2Array);
    const newdata = data1.map((v) => {
      var d = {};
      var e = v[0];
      for (var i = 0; i < header.length; i++) {
        d[header[i]] = e[i];
      }
      return { ...d };
    });
    return { topicInfo: snapshot.data().topicInfo, data: newdata };
  }
);

const topicSlice = createSlice({
  name: "topic",
  initialState,
  reducers: {
    toIdle(state, action) {
      state.status = "idle";
    },
  },
  extraReducers: {
    [fetchTopic.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchTopic.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.topic.push(action.payload);
    },
    [fetchTopic.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [fetchTopicwM.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchTopicwM.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.topic.push(action.payload);
    },
    [fetchTopicwM.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [addNewTopic.fulfilled]: (state, action) => {
      state.topic.push(action.payload);
    },
  },
});

export const { toIdle } = topicSlice.actions;
export default topicSlice.reducer;

export const selectOtherById = (state, topicId) => {
  let wanted = state.topic.topic.find((item) => item.id === topicId);
  if (wanted) {
    return wanted;
  } else {
    console.log(topicId);
    return state.topic.topic.find((item) => item.id === topicId);
  }
};

export const selectAllTopic = (state) => state.topic.topic;
