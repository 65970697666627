import React, { useRef, useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import _ from "lodash";
import { Header, Container, Menu, Grid, Table, Icon } from "semantic-ui-react";
import Slider from "@material-ui/core/Slider";
import InTableBar from "./InTableBar";
import RSTableBySector from "../pages/RSTableT/RSTableBySector";

const data = {
  topicInfo: { lastUpdate: "2022/07/05 00:21", name: "rrg" },
  data: {
    rrg_d: {
      data: {
        非必需性消費:
          "[[101.2,101.6],[101.5,100.6],[101.6,100.0],[101.3,99.1],[101.4,99.8],[101.3,99.6],[101.2,99.6],[101.0,99.3],[101.1,100.1],[100.8,99.1],[100.5,99.4],[100.3,99.5],[100.4,100.0],[100.4,100.2],[100.8,100.9],[101.2,101.1],[101.8,101.6],[102.0,100.4],[102.2,100.3],[102.1,99.4]]",
        必需性消費:
          "[[100.3,101.0],[100.2,99.4],[99.7,98.2],[99.1,97.8],[98.4,97.9],[98.0,99.2],[98.0,100.3],[98.1,100.7],[98.4,101.3],[98.6,101.1],[98.8,100.8],[99.0,100.5],[99.4,101.5],[99.8,101.1],[100.1,101.1],[100.3,100.4],[100.7,101.0],[100.8,100.3],[101.2,100.9],[101.4,100.4]]",
        醫療保健業:
          "[[100.2,101.0],[100.6,101.3],[101.0,101.5],[101.5,101.5],[102.0,101.3],[102.1,99.8],[102.0,98.8],[101.6,98.1],[101.4,98.6],[101.3,99.4],[101.4,100.1],[101.6,100.6],[101.7,100.0],[101.8,100.1],[102.0,100.2],[102.0,99.6],[101.8,98.8],[101.7,99.0],[101.6,99.1],[101.5,99.4]]",
        綜合企業:
          "[[98.7,97.9],[98.3,99.0],[98.1,99.4],[97.9,99.6],[97.6,99.7],[98.0,101.8],[98.2,101.3],[98.5,101.4],[98.7,100.9],[98.7,100.4],[98.6,99.9],[98.5,99.9],[98.5,100.5],[98.6,100.6],[98.6,100.7],[98.6,100.5],[98.7,100.7],[98.8,100.4],[98.8,100.3],[98.9,100.7]]",
        資訊科技業:
          "[[100.9,101.9],[101.3,101.4],[101.7,101.1],[102.0,100.8],[102.2,100.6],[102.2,99.5],[102.0,99.3],[101.7,98.9],[101.4,99.0],[101.2,99.2],[101.1,99.6],[101.0,99.6],[100.8,99.3],[100.8,99.5],[100.7,99.6],[100.7,99.6],[100.5,99.2],[100.4,99.4],[100.1,98.7],[99.7,98.6]]",
        地產建築業:
          "[[97.6,100.2],[97.8,101.1],[97.8,100.6],[97.9,100.4],[98.0,100.5],[98.2,101.1],[98.4,100.8],[98.6,100.8],[98.7,100.5],[98.8,100.4],[98.9,100.7],[99.1,100.6],[99.2,100.8],[99.4,100.7],[99.5,100.4],[99.4,99.7],[99.4,99.8],[99.5,100.6],[99.8,101.4],[100.2,102.0]]",
        金融業:
          "[[99.2,98.4],[98.9,99.0],[98.6,99.1],[98.4,99.3],[98.1,99.3],[98.2,100.5],[98.3,100.6],[98.7,101.2],[99.1,101.1],[99.4,101.0],[99.6,100.5],[99.8,100.4],[100.0,100.6],[100.1,100.2],[100.0,99.9],[99.9,99.8],[99.8,99.8],[99.7,99.8],[99.8,100.4],[99.8,100.1]]",
        公用事業:
          "[[99.0,98.7],[98.9,99.7],[98.5,99.0],[98.3,99.5],[98.0,99.4],[98.2,101.7],[98.5,101.6],[98.7,101.4],[99.0,101.2],[99.0,100.5],[98.9,100.0],[98.7,99.5],[98.7,100.5],[98.6,100.1],[98.6,100.5],[98.4,99.7],[98.4,100.6],[98.5,100.4],[98.6,101.0],[98.8,101.1]]",
        電訊業:
          "[[98.0,100.0],[98.1,100.5],[98.2,100.6],[98.3,100.6],[98.3,100.4],[98.5,101.2],[98.7,101.0],[98.9,101.0],[99.1,101.0],[99.3,100.7],[99.4,100.2],[99.3,99.9],[99.4,100.5],[99.4,99.9],[99.1,99.0],[98.7,98.7],[98.4,99.0],[98.2,99.0],[98.2,99.9],[98.4,101.2]]",
        工業: "[[100.9,99.5],[100.9,99.9],[100.7,99.0],[100.3,98.1],[99.8,98.3],[99.7,99.6],[99.3,99.0],[98.9,98.8],[98.8,99.9],[98.6,99.5],[98.2,99.2],[98.0,100.1],[98.1,101.8],[98.1,101.6],[98.3,102.1],[98.4,101.6],[98.8,102.1],[98.9,101.2],[99.1,101.2],[99.1,100.5]]",
        原材料業:
          "[[99.5,100.9],[99.8,101.2],[100.0,100.5],[100.1,100.4],[100.2,100.0],[100.3,99.9],[100.2,99.0],[100.0,98.8],[99.9,99.2],[99.9,99.5],[99.3,97.5],[98.8,98.2],[98.6,99.3],[98.3,98.8],[97.7,98.3],[97.7,100.4],[97.9,101.1],[97.8,100.0],[98.0,101.2],[98.2,101.1]]",
        能源業:
          "[[100.5,98.3],[100.3,99.1],[100.2,99.8],[99.9,99.2],[99.6,99.2],[99.7,100.4],[99.7,100.2],[99.4,99.1],[99.1,99.2],[98.8,99.3],[98.2,98.5],[97.8,99.1],[97.7,100.2],[97.8,101.2],[97.9,101.6],[98.1,102.0],[98.3,101.8],[98.4,101.4],[98.5,101.1],[98.7,101.2]]",
      },
      datelist: [
        "2022-06-06",
        "2022-06-07",
        "2022-06-08",
        "2022-06-09",
        "2022-06-10",
        "2022-06-13",
        "2022-06-14",
        "2022-06-15",
        "2022-06-16",
        "2022-06-17",
        "2022-06-20",
        "2022-06-21",
        "2022-06-22",
        "2022-06-23",
        "2022-06-24",
        "2022-06-27",
        "2022-06-28",
        "2022-06-29",
        "2022-06-30",
        "2022-07-04",
      ],
    },
    rrg_w: {
      data: {
        非必需性消費:
          "[[100.6,100.4],[100.1,96.4],[97.7,100.4],[98.4,100.7],[99.4,100.4],[99.8,100.5],[99.4,97.6],[98.8,101.5],[101.0,101.1],[100.9,99.4],[100.2,99.1],[100.0,100.0],[101.0,99.6],[99.1,99.2],[100.6,101.4],[101.4,99.8],[100.8,99.1],[100.8,100.9],[102.2,100.3],[102.1,99.4]]",
        必需性消費:
          "[[102.1,99.1],[101.5,99.0],[100.0,98.9],[98.8,99.1],[98.2,101.9],[98.6,101.6],[98.9,100.6],[99.6,101.8],[101.6,101.7],[101.5,97.7],[100.3,98.7],[100.6,99.7],[99.4,99.2],[100.0,100.7],[100.1,100.6],[98.4,97.9],[98.6,101.1],[100.1,101.1],[101.2,100.9],[101.4,100.4]]",
        醫療保健業:
          "[[100.3,101.6],[101.2,97.8],[99.8,98.6],[98.6,100.1],[100.3,100.6],[100.4,99.9],[100.4,99.8],[99.6,99.2],[98.8,100.0],[98.9,101.3],[98.3,99.2],[98.7,101.7],[99.8,101.3],[99.7,98.3],[99.9,100.4],[102.0,101.3],[101.3,99.4],[102.0,100.2],[101.6,99.1],[101.5,99.4]]",
        綜合企業:
          "[[101.6,99.2],[100.6,100.2],[102.2,100.6],[101.4,99.0],[100.5,100.3],[100.0,99.2],[99.2,100.2],[100.1,101.8],[101.6,100.9],[100.8,98.1],[99.8,99.8],[100.7,100.1],[100.3,100.1],[100.9,100.2],[99.3,98.4],[97.6,99.7],[98.7,100.4],[98.6,100.7],[98.8,100.3],[98.9,100.7]]",
        資訊科技業:
          "[[97.9,100.8],[98.9,100.0],[98.4,99.9],[98.8,101.5],[99.8,99.2],[100.0,100.3],[100.0,99.2],[99.1,98.8],[98.3,99.1],[99.6,103.1],[101.2,100.5],[100.5,99.8],[100.5,99.4],[99.1,99.4],[100.3,101.8],[102.2,100.6],[101.2,99.2],[100.7,99.6],[100.1,98.7],[99.7,98.6]]",
        地產建築業:
          "[[101.5,98.4],[100.4,100.9],[101.7,99.5],[99.9,99.9],[99.4,99.8],[99.0,100.0],[101.3,101.7],[101.7,99.5],[101.0,99.4],[100.5,99.0],[99.7,99.3],[99.1,100.6],[99.4,100.3],[99.4,99.0],[97.7,99.0],[98.0,100.5],[98.8,100.4],[99.5,100.4],[99.8,101.4],[100.2,102.0]]",
        金融業:
          "[[101.4,98.8],[100.4,101.1],[101.4,101.6],[101.6,98.2],[100.1,100.1],[99.9,99.9],[100.0,100.4],[100.7,100.8],[101.3,101.0],[99.6,97.0],[98.6,100.0],[99.5,100.2],[99.1,100.5],[101.0,100.8],[99.7,98.3],[98.1,99.3],[99.4,101.0],[100.0,99.9],[99.8,100.4],[99.8,100.1]]",
        公用事業:
          "[[100.8,102.6],[101.9,98.8],[102.2,99.2],[100.5,98.5],[99.1,100.1],[99.0,100.7],[99.2,101.3],[99.8,100.9],[101.0,101.9],[101.6,97.2],[100.1,99.4],[101.3,100.4],[100.6,99.5],[101.1,100.0],[99.4,99.0],[98.0,99.4],[99.0,100.5],[98.6,100.5],[98.6,101.0],[98.8,101.1]]",
        電訊業:
          "[[101.6,100.4],[101.4,100.2],[102.0,100.0],[101.1,98.4],[100.5,100.8],[99.7,98.7],[99.7,101.7],[101.0,100.8],[101.6,99.6],[100.8,98.4],[99.5,99.8],[100.7,100.6],[99.5,99.6],[99.8,99.8],[98.1,99.6],[98.3,100.4],[99.3,100.7],[99.1,99.0],[98.2,99.9],[98.4,101.2]]",
        工業: "[[102.5,100.0],[101.4,98.6],[100.8,99.7],[99.8,99.0],[97.8,99.4],[98.2,101.7],[98.6,100.5],[98.9,101.0],[99.4,100.3],[99.5,99.3],[100.0,99.8],[100.9,100.0],[101.8,101.0],[101.9,97.8],[101.0,99.2],[99.8,98.3],[98.6,99.5],[98.3,102.1],[99.1,101.2],[99.1,100.5]]",
        原材料業:
          "[[101.6,99.4],[101.3,99.8],[101.1,99.2],[98.8,99.5],[99.7,101.4],[99.8,99.9],[100.7,101.5],[101.7,101.0],[101.0,97.4],[98.7,99.4],[98.5,99.8],[98.6,100.4],[99.3,101.1],[100.2,99.6],[99.3,98.4],[100.2,100.0],[99.9,99.5],[97.7,98.3],[98.0,101.2],[98.2,101.1]]",
        能源業:
          "[[102.0,99.4],[102.0,100.8],[101.5,98.5],[100.1,99.2],[100.1,101.4],[101.1,100.7],[101.4,100.9],[101.6,100.0],[101.3,98.4],[100.4,99.0],[100.5,100.8],[100.9,100.3],[100.7,100.9],[102.3,100.4],[101.0,98.1],[99.6,99.2],[98.8,99.3],[97.9,101.6],[98.5,101.1],[98.7,101.2]]",
      },
      datelist: [
        "2022/02/27",
        "2022/03/06",
        "2022/03/13",
        "2022/03/20",
        "2022/03/27",
        "2022/04/03",
        "2022/04/10",
        "2022/04/17",
        "2022/04/24",
        "2022/05/01",
        "2022/05/08",
        "2022/05/15",
        "2022/05/22",
        "2022/05/29",
        "2022/06/05",
        "2022/06/12",
        "2022/06/19",
        "2022/06/26",
        "2022/07/03",
        "2022/07/10",
      ],
    },
  },
};

function initData(data, data_w, tailLength, startPoint, chartType) {
  let i = 0;
  let plotData = [];
  let all = [];

  let dd = [];
  if (chartType === "0") {
    dd = data;
  } else {
    dd = data_w;
  }

  let datelist = dd.datelist;
  // console.log(]);

  _.forEach(dd.data, function (value, key) {
    let array = JSON.parse(value);
    let sData = array.slice(
      -(tailLength + startPoint),
      array.length - startPoint
    );
    all.push(...sData);
    // console.log(sData);
    let x = sData.slice(-1)[0][0] - 100;
    let y = sData.slice(-1)[0][1] - 100;

    let cat = "";
    if ((x >= 0) & (y >= 0)) {
      cat = "1";
    } else if ((x < 0) & (y >= 0)) {
      cat = "2";
    } else if ((x >= 0) & (y < 0)) {
      cat = "3";
    } else if ((x < 0) & (y < 0)) {
      cat = "4";
    }
    // console.log(array.length);
    // console.log(Math.round(Math.sqrt(x * x + y * y) * 1000) / 1000);
    plotData.push({
      name: key,
      data: sData,
      strength: Math.round(Math.sqrt(x * x + y * y) * 1000) / 1000,
      cat: cat,
    });
    i = i + 1;
  });
  // console.log("first");
  let all_ = all.map((num, index) => ({ x: num[0], y: num[1] }));
  let maxX = Math.ceil(_.maxBy(all_, "x").x);
  let minX = Math.floor(_.minBy(all_, "x").x);
  let maxY = Math.ceil(_.maxBy(all_, "y").y);
  let minY = Math.floor(_.minBy(all_, "y").y);
  return {
    plotData: plotData,
    plotRange: { maxX: maxX, minX: minX, maxY: maxY, minY: minY },
    endDate: datelist[datelist.length - startPoint - 1],
  };
}

function RRGChart() {
  const [chartType, setChartType] = useState("0");
  const [tailLength, setTailLength] = useState(4);
  const [startPoint, setStartPoint] = useState(0);
  const [gridBottom, setGridBottom] = useState("10%");
  const { plotData, plotRange, endDate } = initData(
    data["data"]["rrg_d"],
    data["data"]["rrg_w"],
    tailLength,
    startPoint,
    chartType
  );

  console.log(plotData);
  console.log(endDate);

  const handleChange = (event, newValue) => {
    setTailLength(newValue);
  };
  const handleChangeStartPoint = (event, newValue) => {
    setStartPoint(newValue);
  };
  const timeUnit = chartType === "0" ? "日" : "週";
  var windowWidth = window.innerWidth;
  const chartHeight =
    0.656 * windowWidth < 550
      ? 550
      : 0.656 * windowWidth > 633
      ? 633
      : 0.656 * windowWidth;

  useEffect(() => {
    // console.log(windowWidth);
    setGridBottom(windowWidth < 550 ? "20%" : "10%");
  }, []);

  return (
    <Container style={{ maxWidth: "1000px", width: "95%", margin: "auto" }}>
      <div style={{ textAlign: "center", paddingTop: "50px" }}>
        <Header as="h1">板塊輪動圖</Header>
      </div>
      <div style={{ textAlign: "left", overflowX: "auto" }}>
        <Menu pointing secondary compact size="huge">
          <Menu.Item
            active={chartType === "0"}
            onClick={() => {
              setChartType("0");
            }}
          >
            按日
          </Menu.Item>
          <Menu.Item
            active={chartType === "1"}
            onClick={() => {
              setChartType("1");
            }}
          >
            按週
          </Menu.Item>
        </Menu>
      </div>
      {/* <div>
        <Header as="h2" content={chartType === "0" ? "按日" : "按週"} />
      </div> */}
      <div
        style={{ maxWidth: "650px", marginTop: "1rem", marginRight: "25px" }}
      >
        <Grid>
          <Grid.Row>
            <Grid.Column width={4}>
              <div style={{ textAlign: "center", marginTop: "0.25rem" }}>
                數據長度({timeUnit}):
              </div>
            </Grid.Column>
            <Grid.Column width={12}>
              <Slider
                valueLabelDisplay="auto"
                value={tailLength}
                onChange={handleChange}
                marks={[
                  { value: 1, label: "1" },
                  { value: 10, label: "10" },
                ]}
                min={1}
                max={10}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <div style={{ textAlign: "center" }}>
                數據結束日期:
                <br />
                (n{timeUnit}前)
              </div>
            </Grid.Column>
            <Grid.Column width={12}>
              <Slider
                valueLabelDisplay="auto"
                value={startPoint}
                onChange={handleChangeStartPoint}
                marks={[
                  { value: 0, label: "0" },
                  { value: 9, label: "9" },
                ]}
                min={0}
                max={9}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>

      {chartType === "0" ? (
        <>
          <RRGPlot
            data={plotData}
            range={tailLength + 1}
            chartHeight={chartHeight}
            gridBottom={gridBottom}
            plotRange={plotRange}
          />
        </>
      ) : (
        <>
          <RRGPlot
            data={plotData}
            range={tailLength + 1}
            chartHeight={chartHeight}
            gridBottom={gridBottom}
            plotRange={plotRange}
          />
        </>
      )}
      <>
        <RRGTable data={plotData} />
      </>
    </Container>
  );
}

function RRGTable(props) {
  const { data } = props;
  let sorted = _.orderBy(data, ["cat", "strength"], ["asc", "desc"]);
  let max = _.maxBy(sorted, function (o) {
    return o.strength;
  }).strength;

  const catDict = [
    { name: "Leading", id: "1", color: "#CCE6CC", name_zh: "領先" },
    { name: "Improving", id: "2", color: "#D6DAF8", name_zh: "改善" },
    { name: "Weakening", id: "3", color: "#FBEDB2", name_zh: "轉差" },
    { name: "Lagging", id: "4", color: "#FFCCCC", name_zh: "落後" },
  ];

  const [selected, setSelected] = React.useState([]);
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    // console.log("selected");
  };

  // console.log("fxxk");
  // console.log(max);
  // console.log("RRGtable");
  return (
    <div
      style={{
        whiteSpace: "nowrap",
        overflowX: "auto",
        display: "inline-block",
        width: "100%",
        textAlign: "center",
      }}
    >
      <Table sortable selectable celled unstackable compact structured>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>板塊</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">分類</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">強度</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sorted.map((d, index) => {
            const isItemSelected = isSelected(d.name);
            // console.log(d);
            // console.log(max);
            // console.log((d.strength / max) * 100);
            // console.log(typeof d.cat);
            // console.log(d.cat);
            // console.log(typeof catDict[0]["id"]);
            // console.log(catDict[0]["id"]);
            // console.log(d.cat === catDict[0]["id"]);
            // console.log(
            //   _.find(catDict, function (o) {
            //     return o.id === d.cat;
            //   })["name"]
            // );

            let color = _.find(catDict, function (o) {
              return o.id === d.cat;
            })["color"];

            return (
              <>
                <Table.Row
                  key={d.name}
                  onClick={(event) => handleClick(event, d.name)}
                  style={{ backgroundColor: color }}
                >
                  <Table.Cell>
                    <Icon
                      name={isItemSelected ? "caret down" : "caret right"}
                    />
                    {d.name}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {
                      _.find(catDict, function (o) {
                        return o.id === d.cat;
                      })["name"]
                    }
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <div style={{ width: "100px", margin: "auto" }}>
                      <InTableBar
                        min={0}
                        secondMin={0}
                        // mid={0}
                        // mid={strength / max}
                        mid={(d.strength / max) * 100}
                        secondMax={(d.strength / max) * 100}
                        // secondMax={100}
                        max={100}
                        col={"#2471A3"}
                      />
                    </div>
                  </Table.Cell>
                </Table.Row>
                {isItemSelected ? (
                  <Table.Row>
                    <Table.Cell colSpan={3}>
                      <RSTableBySector industry={d.name} />
                    </Table.Cell>
                  </Table.Row>
                ) : null}
              </>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
}

function RRGPlot(props) {
  const { data, plotRange, chartHeight, gridBottom } = props;
  // console.log(data);

  let plotSeries = data.map(function (item, idx) {
    // console.log(item);
    return {
      name: item.name,
      seriesName: item.name,
      data: item.data,
      triggerLineEvent: true,
      type: "line",
      smooth: true,
      endLabel: {
        show: true,
        position: "top",
        color: "black",
        fontSize: 12,
        formatter: function (d) {
          return d.seriesName;
        },
      },
      emphasis: {
        focus: "series",
      },
      // markLine: {
      //   symbol: "none",
      //   lineStyle: {
      //     type: "solid",
      //     color: "black",
      //   },
      //   data: [{ yAxis: 100 }, { xAxis: 100 }],
      // },
      markPoint: {
        symbol: "circle",
        symbolSize: 10,
        data: [
          {
            coord: [
              item.data[item.data.length - 1][0],
              item.data[item.data.length - 1][1],
            ],
          },
        ],
      },
      // markArea: {
      //   itemStyle: {
      //     color: "rgba(255, 173, 177, 0.4)",
      //   },
      //   label: {
      //     position: "insideTopLeft",
      //   },
      //   // data: [
      //   //   [
      //   //     {
      //   //       name: "Morning Peak",
      //   //       // xAxis: 0,
      //   //       yAxis: 100,
      //   //       // y: 100,
      //   //     },
      //   //     {
      //   //       name: "Morning Peak",
      //   //       // xAxis: 100,
      //   //       yAxis: 200,
      //   //       // yAxis: 200,
      //   //     },
      //   //   ],
      //   // ],
      // },
    };
  });

  plotSeries.push(
    {
      name: "Improving",
      type: "scatter",
      data: [],
      markArea: {
        silent: true,
        itemStyle: {
          color: "transparent",
          borderWidth: 0,
          borderType: "dashed",
        },
        label: {
          position: "insideTopLeft",
          fontWeight: "bold",
          color: "#7484fc",
        },
        data: [
          [
            {
              name: "Improving",
              xAxis: plotRange.minX,
              yAxis: 100,
            },
            {
              xAxis: 100,
              yAxis: plotRange.maxY,
            },
          ],
        ],
      },
    },
    {
      name: "Lagging",
      type: "scatter",
      data: [],
      markArea: {
        silent: true,
        itemStyle: {
          color: "transparent",
          borderWidth: 0,
          borderType: "dashed",
        },
        label: {
          position: "insideBottomLeft",
          fontWeight: "bold",
          color: "#fc4242",
        },
        data: [
          [
            {
              name: "Lagging",
              xAxis: plotRange.minX,
              yAxis: plotRange.minY,
            },
            {
              xAxis: 100,
              yAxis: 100,
            },
          ],
        ],
      },
    },
    {
      name: "Leading",
      type: "scatter",
      data: [],
      markArea: {
        silent: true,
        itemStyle: {
          color: "transparent",
          borderWidth: 0,
          borderType: "dashed",
        },
        label: {
          position: "insideTopRight",
          fontWeight: "bold",
          color: "#51a651",
        },
        data: [
          [
            {
              name: "Leading",
              xAxis: 100,
              yAxis: 100,
            },
            {
              xAxis: plotRange.maxX,
              yAxis: plotRange.maxY,
            },
          ],
        ],
      },
    },
    {
      name: "Weakening",
      type: "scatter",
      data: [],
      markArea: {
        silent: true,
        itemStyle: {
          color: "transparent",
          borderWidth: 0,
          borderType: "dashed",
        },
        label: {
          position: "insideBottomRight",
          fontWeight: "bold",
          color: "#ffdc4a",
        },
        data: [
          [
            {
              name: "Weakening",
              xAxis: 100,
              yAxis: plotRange.minY,
            },
            {
              xAxis: plotRange.maxX,
              yAxis: 100,
            },
          ],
        ],
      },
    },
    {
      name: "centerLine",
      type: "scatter",
      data: [],
      markLine: {
        symbol: "none",
        lineStyle: {
          type: "solid",
          color: "black",
        },
        data: [{ yAxis: 100 }, { xAxis: 100 }],
      },
    }
  );

  const option = {
    animation: false,
    legend: {
      data: data.map(function (item, idx) {
        return item.name;
      }),
      left: "center",
      // height: 50,
      // padding: [5, 10],
      bottom: 0,
    },
    grid: {
      top: "3%",
      left: "3%",
      right: "7%",
      bottom: gridBottom,
      containLabel: true,
    },

    xAxis: {
      scale: true,
      type: "value",
      min: function (value) {
        return Math.floor(value.min) < 98 ? Math.floor(value.min) : 98;
      },
      max: function (value) {
        return Math.ceil(value.max) > 102 ? Math.ceil(value.max) : 102;
      },
    },
    yAxis: {
      scale: true,
      type: "value",
      min: function (value) {
        return Math.floor(value.min) < 98 ? Math.floor(value.min) : 98;
      },
      max: function (value) {
        return Math.ceil(value.max) > 102 ? Math.ceil(value.max) : 102;
      },
    },
    series: plotSeries,
  };

  return (
    <>
      <br />
      <ReactEcharts option={option} style={{ height: chartHeight }} />
      <br />
    </>
  );
}

export default RRGChart;
