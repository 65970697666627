import React, { useEffect, useState } from 'react';
import './ShareholdingByParticipantTable.css';
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../config/fbConfig"
import SortableTable from "../SortableTable";
import { Link } from "react-router-dom";
import { Table, Header, Icon, Divider, Loader } from 'semantic-ui-react'
import { toDateString } from '../../../util/datetime'
import { formatSharePercentage, formatShare, formatStockCode } from '../../../util/dataFormatter'
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { useSelector, useDispatch } from 'react-redux'
import { fetchStockListData } from '../../../features/stock/stockListSlice'
import { fetchParticipantListData } from '../../../features/participant/participantListSlice'
import { fetchShareholdingDateListData } from '../../../features/shareholding/shareholdingDateListSlice'
import source from "../../../mock_source"

const getShareholdingByParticipant = httpsCallable(functions, 'shareholding_by_participant');

export default function ShareholdingByParticipantTable(props) {
  const dispatch = useDispatch();

  const stockListData = useSelector(state => state.stockList);
  const stockListStatus = useSelector(state => state.stockList.status);
  // const participantListData = useSelector(state => state.participantList);
  const participantListStatus = useSelector(state => state.participantList.status);
  const shareholdingDateListData = useSelector(state => state.shareholdingDateList);
  const shareholdingDateListStatus = useSelector(state => state.shareholdingDateList.status);

  const [ shareholdingByParticipant, setShareholdingByParticipant ] = useState({list: []});
  const [ resultLoaded, setResultLoaded ] = useState(false);
  const [ isMobile, setMobile ] = useState(false);
  const [ selectDate , setSelectDate] = useState(new Date());
  const participant_code = props.participantCode;

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return (() => {
        window.removeEventListener("resize", handleResize);
      }
    );
  }, []);

  const handleResize = () => {
    const widths = [window.innerWidth];
    if (window.screen?.width) {
      widths.push(window.screen?.width);
    }
    const width = Math.min(...widths);
    
    var isMobile = false;
    if (width < 800) {
      isMobile = true;
    }
    setMobile(isMobile);
  }

  useEffect(() => {
    if (stockListStatus === 'idle') {
      dispatch(fetchStockListData());
    } else if (stockListStatus === 'succeeded') {
    }
  }, [stockListStatus, dispatch]);

  useEffect(() => {
    if (participantListStatus === 'idle') {
      dispatch(fetchParticipantListData());
    } else if (participantListStatus === 'succeeded') {
    }
  }, [participantListStatus, dispatch]);

  useEffect(() => {
    if (shareholdingDateListStatus === 'idle') {
      dispatch(fetchShareholdingDateListData());
    } else if (shareholdingDateListStatus === 'succeeded') {
    }
  }, [shareholdingDateListStatus, dispatch]);

  let dataReady = false;
  if (stockListStatus === 'succeeded' &&
      participantListStatus === 'succeeded' &&
      shareholdingDateListStatus === 'succeeded') {
    dataReady = true;
  }

  useEffect(() => {
    const updateShareholding = (target_date) => {
      getShareholdingByParticipant({
        date: toDateString(target_date),
        participant_code: participant_code,
        language_code: "zh-hk",
      })
        .then((result) => {
          setResultLoaded(true);
          setShareholdingByParticipant({list: result.data.data});
          if (result.data.data.length > 0) {
            setSelectDate(new Date(result.data.data[0].date));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    if (!resultLoaded) {
      updateShareholding(selectDate);
    }
  }, [participant_code, resultLoaded, selectDate]);

  const datePickerFilterDate = (filtered_date) => {
    if (dataReady) {
      for (let i in shareholdingDateListData.shareholdingDateList) {
        if (toDateString(new Date(shareholdingDateListData.shareholdingDateList[i].date)) === toDateString(filtered_date)) {
          return true;
        }
      }
    }
    return false;
  };

  const handleDate = (event, data) => {
    if (data.value) {
      if (toDateString(selectDate) === toDateString(data.value)) {
        return;
      }
      if (!datePickerFilterDate(data.value)) {
        return;
      }
      setSelectDate(data.value);
      setResultLoaded(false);
    }
  };

  const config = isMobile ? {
    col: [
      {content: '證劵', align: 'center', width: 8, prefix: '', postfix: '', },
      {content: '持倉', align: 'right', width: 8, prefix: '', postfix: '', },
    ],
    advRowCount: 16,
  } : {
    col: [
      {content: '編號', align: 'center', width: 4, prefix: '', postfix: '', },
      {content: '證劵', align: 'center', width: 4, prefix: '', postfix: '', },
      {content: '持倉(股)', align: 'right', width: 4, prefix: '', postfix: '', },
      {content: '持倉(%)', align: 'right', width: 4, prefix: '', postfix: '', },
    ],
    advRowCount: 16,
  };

  const sort = isMobile ? {
    col: [
      {type: 'string', sort: true},
      {type: 'key_absolute_number', sort: true},
    ],
    colIndex: 1,
    asc: false,
  } : {
    col: [
      {type: 'string', sort: true},
      {type: 'string', sort: true},
      {type: 'key_absolute_number', sort: true},
      {type: 'key_absolute_number', sort: true},
    ],
    colIndex: 3,
    asc: false,
  };

  const filter = isMobile ? {
    col: [
      {text: '過瀘證劵', type: 'match'},
      {text: '大於', type: 'key_absolute_larger'},
    ],
  } : {
    col: [
      {text: '過瀘編號', type: 'match'},
      {text: '過瀘證劵', type: 'match'},
      {text: '大於', type: 'key_absolute_larger'},
      {text: '大於', type: 'key_absolute_larger'},
    ],
  };

  const getData = (shareholdingByParticipant) => {
    let data = [];
    let key = [];
    let postfix = [];
    if (dataReady) {
      for (let i in shareholdingByParticipant.list) {
        let hasLink = false;
        for (let j = 0; j < source.length; j++) {
          let iStockID = parseInt(shareholdingByParticipant.list[i].stock_code);
          let jStockID = parseInt(source[j].no);
          if (iStockID === jStockID) {
            hasLink = true;
            break;
          }
        }

        let stockLink = <></>;
        if (hasLink) {
          stockLink = <Link to={'/stock/' + formatStockCode(shareholdingByParticipant.list[i].stock_code)}><Icon size='large' inverted name='chart line' color='blue' ></Icon></Link>;
        }

        if (isMobile) {
          data.push([
            formatStockCode(shareholdingByParticipant.list[i].stock_code),
            formatSharePercentage(shareholdingByParticipant.list[i].share_percentage),
          ]);
          key.push([
            null,
            shareholdingByParticipant.list[i].share_percentage,
          ]);
          postfix.push([
            <> {stockLink}<br />{stockListData.stockMap[shareholdingByParticipant.list[i].stock_code].short_name}</>,
            <><br />({formatShare(shareholdingByParticipant.list[i].share)})</>,
          ]);
        } else {
          data.push([
            formatStockCode(shareholdingByParticipant.list[i].stock_code),
            stockListData.stockMap[shareholdingByParticipant.list[i].stock_code].short_name,
            formatShare(shareholdingByParticipant.list[i].share),
            formatSharePercentage(shareholdingByParticipant.list[i].share_percentage),
          ]);
          key.push([
            null,
            null,
            shareholdingByParticipant.list[i].share,
            shareholdingByParticipant.list[i].share_percentage,
          ]);
          postfix.push([
            <> {stockLink}</>,
            <></>,
            <></>,
            <></>,
          ]);
        }
      }
    }
    return {
      data: data,
      key: key,
      postfix: postfix,
    };
  }

  let data = getData(shareholdingByParticipant);

  let title = "持倉";

  return (
    <>
    { !dataReady && (<Loader active inline='centered'>Loading</Loader>) }
    { dataReady && (
      <>
        <Divider horizontal>
          <Header as='h1'>
            {title}
          </Header>
        </Divider>
        <Table>
          <Table.Body>
            <Table.Row>
              <Table.Cell width="3">
                日期
              </Table.Cell>
              <Table.Cell>
                <SemanticDatepicker
                  filterDate={datePickerFilterDate}
                  onChange={handleDate}
                  clearable={false}
                  showToday={false}
                  datePickerOnly={true}
                  value={selectDate}
                  clearOnSameDateClick={false}
                  disabled={!resultLoaded}
                />
              </Table.Cell>
              <Table.Cell>
              </Table.Cell>
              <Table.Cell width="10">
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width="3">
                總共：
              </Table.Cell>
              <Table.Cell>
                { !resultLoaded && <Loader active inline></Loader> }
                { resultLoaded && data.data.length }
              </Table.Cell>
              <Table.Cell width="10">
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <SortableTable maxRowPerPage={32} config={config} data={data} sort={sort} filter={filter}></SortableTable>
      </>
    ) }
    </>
  );
}