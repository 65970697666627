import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import './ShareholdingChangeByStock.css';
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../config/fbConfig"
import SortableTable from "../../tables/SortableTable";
import { Loader, Table, TableBody, TableCell, TableRow } from 'semantic-ui-react';
import { toDateString } from '../../../util/datetime'
import { formatSharePercentage, formatShare } from '../../../util/dataFormatter'
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { useSelector, useDispatch } from 'react-redux'
import { fetchStockListData } from '../../../features/stock/stockListSlice'
import { fetchParticipantListData } from '../../../features/participant/participantListSlice'
import { fetchShareholdingDateListData } from '../../../features/shareholding/shareholdingDateListSlice'

export default function ShareholdingChangeByStock() {
  const dispatch = useDispatch();
  const stockListData = useSelector(state => state.stockList);
  const stockListStatus = useSelector(state => state.stockList.status);
  const participantListData = useSelector(state => state.participantList);
  const participantListStatus = useSelector(state => state.participantList.status);
  const shareholdingDateListData = useSelector(state => state.shareholdingDateList);
  const shareholdingDateListStatus = useSelector(state => state.shareholdingDateList.status);
  const [ shareholdingChangeByStock, setShareholdingChangeByStock ] = useState({list: []});
  const [ resultLoaded, setResultLoaded ] = useState(false);
  const [ selectFromDate , setSelectFromDate] = useState(new Date());
  const [ selectToDate , setSelectToDate] = useState(new Date());
  const { stock_code } = useParams()
  const getShareholdingChangeByStock = httpsCallable(functions, 'shareholding_change_by_stock');

  useEffect(() => {
    if (stockListStatus === 'idle') {
      dispatch(fetchStockListData());
    } else if (stockListStatus === 'succeeded') {
    }
  }, [stockListStatus, dispatch]);

  useEffect(() => {
    if (participantListStatus === 'idle') {
      dispatch(fetchParticipantListData());
    } else if (participantListStatus === 'succeeded') {
    }
  }, [participantListStatus, dispatch]);

  useEffect(() => {
    if (shareholdingDateListStatus === 'idle') {
      dispatch(fetchShareholdingDateListData());
    } else if (shareholdingDateListStatus === 'succeeded') {
    }
  }, [shareholdingDateListStatus, dispatch]);

  let dataReady = false;
  if (stockListStatus === 'succeeded' &&
      participantListStatus === 'succeeded' &&
      shareholdingDateListStatus === 'succeeded') {
    dataReady = true;
  }

  useEffect(() => {
    let from_date = new Date();
    let to_date = new Date();
    let day_ms = 86400000;
    to_date.setTime(to_date.getTime() - day_ms);
    while (to_date.getDay() === 0 || to_date.getDay() === 6)
    {
      to_date.setTime(to_date.getTime() - day_ms);
    } 
    from_date.setTime(to_date.getTime() - day_ms);
    while (from_date.getDay() === 0 || from_date.getDay() === 6)
    {
      from_date.setTime(from_date.getTime() - day_ms);
    }
    setSelectFromDate(from_date);
    setSelectToDate(to_date);
    updateShareholdingChange(from_date, to_date);
  }, []);

  const updateShareholdingChange = (target_from_date, target_to_date) => {
    getShareholdingChangeByStock({
      from_date: toDateString(target_from_date),
      to_date: toDateString(target_to_date),
      stock_code: parseInt(stock_code),
      language_code: "en",
    })
      .then((result) => {
        setShareholdingChangeByStock({list: result.data.data});
        if (result.data.data.length > 0) {
          setSelectFromDate(new Date(result.data.data[0].from_date));
          setSelectToDate(new Date(result.data.data[0].to_date));
        }
        setResultLoaded(true);
      })
      .catch((error) => {
        console.log("error");
        console.log(error);
      });
  };

  const datePickerFilterDate = (filtered_date) => {
    if (dataReady) {
      for (let i in shareholdingDateListData.shareholdingDateList) {
        if (toDateString(new Date(shareholdingDateListData.shareholdingDateList[i].date)) === toDateString(filtered_date)) {
          return true;
        }
      }
    }
    return false;
  };

  const handleFromDate = (event, data) => {
    if (data.value) {
      if (toDateString(selectFromDate) === toDateString(data.value)) {
        return;
      }
      if (!datePickerFilterDate(data.value)) {
        return;
      }
      setSelectFromDate(data.value);
      setResultLoaded(false);
      updateShareholdingChange(data.value, selectToDate);
    }
  };

  const handleToDate = (event, data) => {
    if (data.value) {
      if (toDateString(selectToDate) === toDateString(data.value)) {
        return;
      }
      if (!datePickerFilterDate(data.value)) {
        return;
      }
      setSelectToDate(data.value);
      setResultLoaded(false);
      updateShareholdingChange(selectFromDate, data.value);
    }
  };

  const config = {
    col: [
      {content: '中介者', align: 'center', width: 4, prefix: '', postfix: '', },
      {content: '股份變動', align: 'right', width: 3, prefix: '', postfix: '', },
      {content: '股份百分比變動', align: 'right', width: 3, prefix: '', postfix: '', },
    ],
    advRowCount: 0,
  };

  const sort = {
    col: [
      {type: 'string', sort: true},
      {type: 'key_absolute_number', sort: true},
      {type: 'key_absolute_number', sort: true},
    ],
    colIndex: 2,
    asc: false,
  };

  const filter = {
    col: [
      {text: '過瀘中介者', type: 'match'},
      {text: '大於', type: 'key_absolute_larger'},
      {text: '大於', type: 'key_absolute_larger'},
    ],
  };

  const getData = (shareholdingChangeByStock) => {
    let data = [];
    let key = [];
    let positive = [];
    if (dataReady) {
      for (let i in shareholdingChangeByStock.list) {
        data.push([
          participantListData.participantMap[shareholdingChangeByStock.list[i].participant_code].short_name,
          formatShare(shareholdingChangeByStock.list[i].share_change),
          formatSharePercentage(shareholdingChangeByStock.list[i].share_percentage_change),
        ]);
        key.push([
          null,
          shareholdingChangeByStock.list[i].share_change,
          shareholdingChangeByStock.list[i].share_percentage_change,
        ]);
        positive.push([
          shareholdingChangeByStock.list[i].share_percentage_change,
          shareholdingChangeByStock.list[i].share_percentage_change,
          shareholdingChangeByStock.list[i].share_percentage_change,
        ]);
      }
    }
    return {
      data: data,
      key: key,
      positive,
    };
  }

  let data = getData(shareholdingChangeByStock);

  let title = stock_code
  if (dataReady) {
    if (stockListData.stockMap[parseInt(stock_code)]) {
      title = title + " " +  stockListData.stockMap[parseInt(stock_code)].name;
    }
  }

  return (
    <>
      { !dataReady && (<Loader active inline='centered'>Loading</Loader>) }
      { dataReady && (
        <>
          <h1>{title}</h1>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell width="3">
                  由：
                </TableCell>
                <TableCell>
                  <SemanticDatepicker
                    filterDate={datePickerFilterDate}
                    onChange={handleFromDate}
                    clearable={false}
                    showToday={false}
                    datePickerOnly={true}
                    value={selectFromDate}
                    clearOnSameDateClick={false}
                    disabled={!resultLoaded}
                  />
                </TableCell>
                <TableCell width="10">
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell width="3">
                  到：
                </TableCell>
                <TableCell>
                  <SemanticDatepicker
                    filterDate={datePickerFilterDate}
                    onChange={handleToDate}
                    clearable={false}
                    showToday={false}
                    datePickerOnly={true}
                    value={selectToDate}
                    clearOnSameDateClick={false}
                    disabled={!resultLoaded}
                  />
                </TableCell>
                <TableCell>
                </TableCell>
                <TableCell width="10">
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell width="3">
                  總共：
                </TableCell>
                <TableCell>
                  { !resultLoaded && <Loader active inline></Loader> }
                  { resultLoaded && data.data.length }
                </TableCell>
                <TableCell width="10">
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <SortableTable maxRowPerPage={32} config={config} data={data} sort={sort} filter={filter}></SortableTable>
        </>
      ) }
    </>
  );
}