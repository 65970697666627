import React, {useEffect, useState} from 'react';
// import '../../App.css';
import HeroSection2 from './HeroSection2';
import MetaTags from 'react-meta-tags';
import { Divider, Container } from 'semantic-ui-react'
import HomeWidget from '../widget/HomeWidget'
import GoogleAd from '../ads/Google_ad';

function Home() {

  const [isMobile, setMobile ] = useState(false);
  const [sWidth, setSWidth] = useState(null);

  const handleResize = () => {
    const widths = [window.innerWidth];
    if (window.screen?.width) {
      widths.push(window.screen?.width);
    }
    const width = Math.min(...widths);

    var isMobile = false;
    if (width < 800) {
      isMobile = true;
    }
    setMobile(isMobile);
    setSWidth(width);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return (() => {
        window.removeEventListener("resize", handleResize);
      }
    );
  }, []);

  return (
    <div>
      <MetaTags>
        <title>DaaSHK-主頁</title>
        <meta name="description" content=""/>
        <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
      </MetaTags>
      <HeroSection2 isMobile={isMobile} sWidth={sWidth}/>
      <Divider hidden/>
      <HomeWidget isMobile={isMobile} sWidth={sWidth}/>

      <Container textAlign='center' style={{paddingTop:'30px', paddingBottom:'0px'}}>
          <GoogleAd 
              client="ca-pub-8753358837449417" 
              slot="2486257452" 
              format="auto" 
              wrapperDivStyle={{
              marginTop: '30px',
              marginBottom: '20px'
              }}
          />
      </Container>

    </div>
  );
}

export default Home;