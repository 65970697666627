import React, { useEffect } from "react";
// import CategorySearchBarUS from "../views/CategorySearchBarUS";
import { Container } from "semantic-ui-react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { fetchEPS } from "../../features/stock/stockSlice";
import { useSelector, useDispatch } from "react-redux";

const data = {
  Period: [
    "2018/May",
    "2018/Aug",
    "2018/Oct",
    "2019/Jan",
    "2019/Apr",
    "2019/Jul",
    "2019/Oct",
    "2020/Jan",
    "2020/Apr",
    "2020/Jul",
    "2020/Oct",
    "2021/Jan",
    "2021/Apr",
    "2021/Jul",
    "2021/Oct",
    "2022/Jan",
    "2022/Apr",
    "2022/Jul",
    "2022/Oct",
    "2023/Jan",
  ],
  EPS_Est: [
    "-3.58",
    "-2.92",
    "-0.04",
    "0.44",
    "-0.14",
    "-0.07",
    "-0.08",
    "0.35",
    "-0.06",
    "-0.04",
    "0.19",
    "0.34",
    "0.26",
    "0.33",
    "0.53",
    "0.79",
    "0.75",
    "0.61",
    "0.99",
    "1.26",
  ],
  EPS: [
    -3.35,
    -3.06,
    0.58,
    0.39,
    -0.58,
    -0.22,
    0.37,
    0.41,
    0.23,
    0.44,
    0.25,
    0.27,
    0.31,
    0.48,
    0.62,
    0.85,
    1.07,
    0.76,
    1.05,
    "-",
  ],
  Sur: [
    6.48,
    -4.69,
    1587.18,
    -12.27,
    -323.36,
    -215.49,
    542.86,
    19.42,
    456.25,
    1278.38,
    30.41,
    -22.38,
    17.87,
    47.71,
    16.76,
    7.22,
    42.5,
    25.12,
    5.74,
    "-",
  ],
  yoy: [
    1.52,
    1.3,
    -1.2,
    -1.13,
    -0.83,
    -0.93,
    -0.36,
    0.05,
    -1.4,
    -3.0,
    -0.32,
    -0.34,
    0.35,
    0.09,
    1.48,
    2.15,
    2.45,
    0.58,
    0.69,
    "-",
  ],
};

function SearchBarTest() {
  return (
    <div>
      <FetchTest />
      <TableView />
      <ChartView />
    </div>
  );
}

function FetchTest() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchEPS("TSLA"));
  }, []);
  return <>Hello</>;
}

function TableView() {
  return <div>Table</div>;
}

function ChartView() {
  const options = {
    chart: {
      type: "column",
      height: "500",
    },
    title: {
      text: "每股收益(EPS)",
    },
    legend: {
      enable: true,
      align: "center",
      x: 0,
      verticalAlign: "bottom",
      y: 0,
    },
    xAxis: {
      categories: data["Period"],
      crosshair: true,
      min: data["Period"].length - 1 - 9,
      max: data["Period"].length - 1,
    },
    yAxis: [
      {
        labels: {
          format: "{value}",
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        title: {
          text: "EPS",
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
      },
      {
        labels: {
          enabled: false,
        },
        opposite: true,
        gridLineWidth: 0,
        title: "",
      },
      {
        labels: {
          enabled: false,
        },
        opposite: true,
        gridLineWidth: 0,
        title: "",
      },
    ],
    tooltip: {
      shared: true,
    },

    scrollbar: {
      enabled: true,
    },

    series: [
      {
        type: "column",
        name: "EPS",
        yAxis: 0,
        data: data["EPS"],
        tooltip: {
          valueSuffix: " $",
        },
      },
      {
        type: "line",
        name: "yoy",
        data: data["yoy"],
        yAxis: 1,
        tooltip: {
          valueSuffix: "%",
        },
      },
      {
        type: "line",
        name: "EPS_Est",
        data: data["EPS_Est"],
        yAxis: 2,
        color: "orange",
        dashStyle: "dash",
      },
    ],
    credits: {
      text: "DaaSHK",
      href: "https://daashk.com/",
      style: {
        fontSize: "1em",
      },
    },
  };
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
      hi
    </div>
  );
}

export default SearchBarTest;
