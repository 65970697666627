import { useEffect, useState } from "react";
// import { Grid, TextField } from "@material-ui/core";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
// import { makeStyles } from "@material-ui/core/styles";
import { Loader, Container, Header } from "semantic-ui-react";
// import SemanticDatepicker from "react-semantic-ui-datepickers";
// import { toDateString } from "../../../util/datetime";
// import { isMobile } from "react-device-detect";
import {
  selectAllTopic,
  fetchTopic,
  toIdle,
} from "../../../features/topic/topicSlice";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

function zip(arrays) {
  return Array.apply(null, Array(arrays[0].length)).map(function (_, i) {
    return arrays.map(function (array) {
      return array[i];
    });
  });
}

function roundData(d) {
  return Math.round(d * 100) / 100;
}

function roundData2(d) {
  return Math.round(d * 10) / 10;
}

export default function HSIChart() {
  const dispatch = useDispatch();
  const topics = useSelector(selectAllTopic);
  const topicStatus = useSelector((state) => state.topic.status);
  const error = useSelector((state) => state.topic.error);
  const [stockStat, setStockStat] = useState({
    data: null,
    loaded: false,
  });
  // console.log(stockStat);
  useEffect(() => {
    if (topicStatus === "idle" && !stockStat.loaded) {
      dispatch(fetchTopic("breadth_from_aws"));
    } else if (topicStatus === "loading") {
    } else if (topicStatus === "succeeded") {
      const data = topics.find(
        (item) => item.topicInfo.name === "breadth_from_aws"
      );
      if (data) {
        setStockStat({
          ...stockStat,
          data: data.data,
          loaded: true,
        });
      } else {
        dispatch(toIdle({}));
      }
    } else if (topicStatus === "error") {
    }
  }, [topicStatus, dispatch]);

  // console.log(stockStat);

  // const hsiColor = "#999999";
  // const breadthColor = "#999999";
  var windowWidth = window.innerWidth;
  const chartHeight = 750;
  // 0.656 * windowWidth < 550
  //   ? 550
  //   : 0.656 * windowWidth > 633
  //   ? 633
  //   : 0.656 * windowWidth;
  let hsiData = [];
  let marketData = [];
  let nh_nl = [];
  let nh_nl_MA10 = [];
  let chartOption = {};
  // console.log(stockStat.data);

  if (stockStat.loaded && stockStat.data.date.length > 0) {
    let dates = stockStat.data["date"].map((item) => new Date(item).getTime());
    hsiData.push({
      yAxis: 0,
      type: "ohlc",
      name: "HSI",
      useOhlcData: true,
      data: zip([
        dates,
        stockStat.data["open"],
        stockStat.data["high"],
        stockStat.data["low"],
        stockStat.data["close"],
      ]),
    });
    marketData.push({
      yAxis: 0,
      type: "ohlc",
      name: "HSI",
      useOhlcData: true,
      data: zip([
        dates,
        stockStat.data["open"],
        stockStat.data["high"],
        stockStat.data["low"],
        stockStat.data["close"],
      ]),
    });
    hsiData.push({
      yAxis: 1,
      name: "HSI 20",
      data: zip([
        dates,
        _.map(stockStat.data["hsi_breadth_20_day"], roundData2),
      ]),
    });
    hsiData.push({
      yAxis: 1,
      name: "HSI 50",
      data: zip([
        dates,
        _.map(stockStat.data["hsi_breadth_50_day"], roundData2),
      ]),
    });
    // console.log(stockStat);
    hsiData.push({
      yAxis: 1,
      name: "HSI 100",
      data: zip([
        dates,
        _.map(stockStat.data["hsi_breadth_100_day"], roundData2),
      ]),
    });
    hsiData.push({
      yAxis: 1,
      name: "HSI 150",
      data: zip([
        dates,
        _.map(stockStat.data["hsi_breadth_150_day"], roundData2),
      ]),
    });
    hsiData.push({
      yAxis: 1,
      name: "HSI 200",
      data: zip([
        dates,
        _.map(stockStat.data["hsi_breadth_200_day"], roundData2),
      ]),
    });
    marketData.push({
      yAxis: 1,
      name: "Market 20",
      data: zip([
        dates,
        _.map(stockStat.data["mkt_breadth_20_day"], roundData2),
      ]),
    });
    marketData.push({
      yAxis: 1,
      name: "Market 50",
      data: zip([
        dates,
        _.map(stockStat.data["mkt_breadth_50_day"], roundData2),
      ]),
    });
    marketData.push({
      yAxis: 1,
      name: "Market 100",
      data: zip([
        dates,
        _.map(stockStat.data["mkt_breadth_100_day"], roundData2),
      ]),
    });
    marketData.push({
      yAxis: 1,
      name: "Market 150",
      data: zip([
        dates,
        _.map(stockStat.data["mkt_breadth_150_day"], roundData2),
      ]),
    });
    marketData.push({
      yAxis: 1,
      name: "Market 200",
      data: zip([
        dates,
        _.map(stockStat.data["mkt_breadth_200_day"], roundData2),
      ]),
    });
    nh_nl.push({
      yAxis: 3,
      name: "HSI-nh-nl",
      data: zip([dates, _.map(stockStat.data["hsi_nh_nl"], roundData2)]),
    });
    nh_nl_MA10.push({
      yAxis: 3,
      name: "HSI-nh-nl-MA10",
      data: zip([dates, _.map(stockStat.data["hsi_nh_nl_10_day"], roundData2)]),
    });

    // console.log(hsiData);
    // console.log(marketData);
    // nh_nl.push({
    //   yAxis: 3,
    //   name: "nh-nl",
    //   data: zip([dates, _.map(stockStat.data["nh_nl"], roundData2)]),
    // });
    // nh_nl_MA10.push({
    //   yAxis: 3,
    //   name: "nh-nl-MA10",
    //   data: zip([dates, _.map(stockStat.data["nh_nl_10_day"], roundData2)]),
    // });
    chartOption = {
      rangeSelector: {
        selected: 1,
      },

      navigator: {
        series: {
          lineWidth: 1,
          lineColor: "#2F4F4F",
          color: "#2F4F4F",
          fillOpacity: 0.2,
        },
      },

      legend: {
        enabled: true,
        align: "center",
        verticalAlign: "bottom",
        x: 0,
        y: 0,
      },

      chart: {
        height: chartHeight,
        // marginTop: 10,
        // marginBottom: 0,
        marginLeft: 10,
        marginRight: 10,
      },

      xAxis: {
        type: "datetime",
      },

      yAxis: [
        {
          endOnTick: false,
          startOnTick: false,
          labels: {
            style: {
              color: "black",
            },
            align: "right",
            x: -2,
            formatter: function () {
              return this.value;
            },
          },
          title: {
            style: {
              color: Highcharts.getOptions().colors[1],
            },
          },
          // opposite: true,
          height: "45%",
          resize: {
            enabled: true,
          },
        },
        {
          // opposite: false,
          // endOnTick: false,
          labels: {
            style: {
              color: "black",
            },
            align: "right",
            x: 10,
            formatter: function () {
              return this.value + "%";
            },
          },
          title: {
            text: "市寬(MKT)",
            align: "high",
            rotation: 0,
            y: 2.5,
            x: -40,
            style: {
              color: Highcharts.getOptions().colors[1],
            },
          },
          // tickPositions: [0, 25, 50, 75, 100],
          top: "50%",
          height: "15%",
          offset: -20,
        },
        {
          // opposite: false,
          // endOnTick: false,
          labels: {
            style: {
              color: "black",
            },
            align: "right",
            x: 10,
            formatter: function () {
              return this.value + "%";
            },
          },
          // gridLineWidth: 0,
          title: {
            text: "市寬(HSI)",
            align: "high",
            rotation: 0,
            y: 2.5,
            x: -40,
            style: {
              color: Highcharts.getOptions().colors[1],
            },
          },
          top: "70%",
          height: "15%",
          offset: -20,
        },
        {
          labels: {
            enabled: false,
          },
          // gridLineWidth: 0,
          title: {
            text: "HSIHL",
            align: "high",
            rotation: 0,
            y: 2.5,
            x: -30,
            style: {
              color: Highcharts.getOptions().colors[1],
            },
          },
          top: "90%",
          height: "10%",
          offset: -20,
        },
      ],

      plotOptions: {
        candlestick: {
          lineColor: "red",
          color: "white",
          upLineColor: "black",
          upColor: "Green",
        },
      },

      tooltip: {
        xDateFormat: "%Y/%m/%d %a",
        shared: true,
        split: false,
        enabled: true,
        distance: 50,
        valueDecimals: 1,
        // headerFormat: "{point.key} ",
        // pointFormat:
        //   ' | <span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b>',
        // positioner: function () {
        //   return { x: 10, y: 35 };
        // },
        // useHTML: true,
        // headerFormat: '<table><tr><th colspan="2">{point.key}</th></tr>',
        // pointFormat:
        //   '<tr><td style="color: {series.color}">{series.name} </td>' +
        //   '<td style="text-align: right"><b>{point.y} EUR</b></td></tr>',
        // footerFormat: "</table>",
      },

      scrollbar: {
        enabled: true,
      },

      series: [
        {
          type: "candlestick",
          id: "HSI",
          name: "恒生指數",
          data: marketData[0].data,
          yAxis: 0,
          showInLegend: false,
        },
        {
          type: "line",
          name: "MKT-MA20(%)",
          data: marketData[1].data,
          yAxis: 1,
          color: "#7D187E",
          visible: false,
        },
        {
          type: "line",
          name: "MKT-MA50(%)",
          data: marketData[2].data,
          yAxis: 1,
          color: "#EB7E30",
          visible: true,
        },
        {
          type: "line",
          name: "MKT-MA100(%)",
          data: marketData[3].data,
          yAxis: 1,
          color: "#0072ce",
          visible: false,
        },
        {
          type: "line",
          name: "MKT-MA150(%)",
          data: marketData[4].data,
          yAxis: 1,
          color: "red",
          visible: false,
        },
        {
          type: "line",
          name: "MKT-MA200(%)",
          data: marketData[5].data,
          yAxis: 1,
          color: "green",
          visible: true,
        },
        {
          type: "line",
          name: "HSI-MA20(%)",
          data: hsiData[1].data,
          yAxis: 2,
          color: "#7D187E",
          visible: false,
        },
        {
          type: "line",
          name: "HSI-MA50(%)",
          data: hsiData[2].data,
          yAxis: 2,
          color: "#EB7E30",
          visible: true,
        },
        {
          type: "line",
          name: "HSI-MA100(%)",
          data: hsiData[3].data,
          yAxis: 2,
          color: "#0072ce",
          visible: false,
        },
        {
          type: "line",
          name: "HSI-MA150(%)",
          data: hsiData[4].data,
          yAxis: 2,
          color: "red",
          visible: false,
        },
        {
          type: "line",
          name: "HSI-MA200(%)",
          data: hsiData[5].data,
          yAxis: 2,
          color: "green",
          visible: true,
        },
        {
          type: "column",
          name: "HSIHL",
          data: nh_nl[0].data,
          yAxis: 3,
          color: "green",
          negativeColor: "red",
          showInLegend: false,
        },
        // {
        //   type: "line",
        //   name: "nh-nl",
        //   data: nh_nl_MA10[0].data,
        //   yAxis: 3,
        //   color: "grey",
        //   // negativeColor: "red",
        //   showInLegend: false,
        // },
      ],
      credits: {
        text: "DaaSHK",
        style: {
          fontSize: "0.8em",
        },
      },
    };
  }

  return (
    <>
      {stockStat.data && stockStat.data.date.length > 0 ? (
        <>
          <Header as="h3" textAlign="center">
            恒生指數及市寛
            <Header.Subheader>
              收市價 {">"} MA 市寬 <br /> HSIHL: HSI成份股創52W(新高-新低)之數目{" "}
            </Header.Subheader>
          </Header>
          <div>
            <HighchartsReact
              constructorType={"stockChart"}
              highcharts={Highcharts}
              options={chartOption}
            />
          </div>
        </>
      ) : !stockStat.loaded ? (
        <Loader active inline="centered" />
      ) : (
        <div>沒有資料</div>
      )}
    </>
  );
}

export function USChartPaid() {
  const dispatch = useDispatch();
  const topics = useSelector(selectAllTopic);
  const topicStatus = useSelector((state) => state.topic.status);
  const error = useSelector((state) => state.topic.error);
  const [stockStat, setStockStat] = useState({
    data: null,
    loaded: false,
  });
  // console.log(stockStat);
  useEffect(() => {
    if (topicStatus === "idle" && !stockStat.loaded) {
      dispatch(fetchTopic("us_breadth_from_aws"));
    } else if (topicStatus === "loading") {
    } else if (topicStatus === "succeeded") {
      const data = topics.find(
        (item) => item.topicInfo.name === "us_breadth_from_aws"
      );
      if (data) {
        setStockStat({
          ...stockStat,
          data: data.data,
          loaded: true,
        });
      } else {
        dispatch(toIdle({}));
      }
    } else if (topicStatus === "error") {
    }
  }, [topicStatus, dispatch]);

  // console.log(stockStat);

  // const hsiColor = "#999999";
  // const breadthColor = "#999999";
  var windowWidth = window.innerWidth;
  const chartHeight = 750;
  // 0.656 * windowWidth < 550
  //   ? 550
  //   : 0.656 * windowWidth > 633
  //   ? 633
  //   : 0.656 * windowWidth;
  let sp500Data = [];
  let marketData = [];
  let nh_nl = [];
  let nh_nl_MA10 = [];
  let chartOption = {};
  // console.log(stockStat.data);

  if (stockStat.loaded && stockStat.data.date.length > 0) {
    let dates = stockStat.data["date"].map((item) => new Date(item).getTime());
    sp500Data.push({
      yAxis: 0,
      type: "ohlc",
      name: "S&P",
      useOhlcData: true,
      data: zip([
        dates,
        stockStat.data["sp500_open"],
        stockStat.data["sp500_high"],
        stockStat.data["sp500_low"],
        stockStat.data["sp500_close"],
      ]),
    });
    marketData.push({
      yAxis: 0,
      type: "ohlc",
      name: "S&P",
      useOhlcData: true,
      data: zip([
        dates,
        stockStat.data["sp500_open"],
        stockStat.data["sp500_high"],
        stockStat.data["sp500_low"],
        stockStat.data["sp500_close"],
      ]),
    });
    sp500Data.push({
      yAxis: 1,
      name: "S&P 50",
      data: zip([
        dates,
        _.map(stockStat.data["sp500_breadth_50_day"], roundData2),
      ]),
    });
    sp500Data.push({
      yAxis: 1,
      name: "S&P 100",
      data: zip([
        dates,
        _.map(stockStat.data["sp500_breadth_100_day"], roundData2),
      ]),
    });
    sp500Data.push({
      yAxis: 1,
      name: "S&P 150",
      data: zip([
        dates,
        _.map(stockStat.data["sp500_breadth_150_day"], roundData2),
      ]),
    });
    sp500Data.push({
      yAxis: 1,
      name: "S&P 200",
      data: zip([
        dates,
        _.map(stockStat.data["sp500_breadth_200_day"], roundData2),
      ]),
    });
    marketData.push({
      yAxis: 1,
      name: "Market 50",
      data: zip([
        dates,
        _.map(stockStat.data["mkt_breadth_50_day"], roundData2),
      ]),
    });
    marketData.push({
      yAxis: 1,
      name: "Market 100",
      data: zip([
        dates,
        _.map(stockStat.data["mkt_breadth_100_day"], roundData2),
      ]),
    });
    marketData.push({
      yAxis: 1,
      name: "Market 150",
      data: zip([
        dates,
        _.map(stockStat.data["mkt_breadth_150_day"], roundData2),
      ]),
    });
    marketData.push({
      yAxis: 1,
      name: "Market 200",
      data: zip([
        dates,
        _.map(stockStat.data["mkt_breadth_200_day"], roundData2),
      ]),
    });
    nh_nl.push({
      yAxis: 3,
      name: "nh_nl",
      data: zip([dates, _.map(stockStat.data["nh_nl"], roundData2)]),
    });
    // nh_nl_MA10.push({
    //   yAxis: 3,
    //   name: "HSI-nh-nl-MA10",
    //   data: zip([dates, _.map(stockStat.data["hsi_nh_nl_10_day"], roundData2)]),
    // });
    chartOption = {
      rangeSelector: {
        selected: 1,
      },

      navigator: {
        series: {
          lineWidth: 1,
          lineColor: "#2F4F4F",
          color: "#2F4F4F",
          fillOpacity: 0.2,
        },
      },

      legend: {
        enabled: true,
        align: "center",
        verticalAlign: "bottom",
        x: 0,
        y: 0,
      },

      chart: {
        height: chartHeight,
        // marginTop: 10,
        // marginBottom: 0,
        marginLeft: 10,
        marginRight: 10,
      },

      xAxis: {
        type: "datetime",
      },

      yAxis: [
        {
          endOnTick: false,
          startOnTick: false,
          labels: {
            style: {
              color: "black",
            },
            align: "right",
            x: -2,
            formatter: function () {
              return this.value;
            },
          },
          title: {
            style: {
              color: Highcharts.getOptions().colors[1],
            },
          },
          // opposite: true,
          height: "45%", //50%
          resize: {
            enabled: true,
          },
        },
        {
          // opposite: false,
          // endOnTick: false,
          labels: {
            style: {
              color: "black",
            },
            align: "right",
            x: 10,
            formatter: function () {
              return this.value + "%";
            },
          },
          title: {
            text: "市寬(MKT)",
            align: "high",
            rotation: 0,
            y: 2.5,
            x: -40,
            style: {
              color: Highcharts.getOptions().colors[1],
            },
          },
          // tickPositions: [0, 25, 50, 75, 100],
          top: "50%",
          height: "15%", //"30%",
          offset: -20,
        },
        {
          // opposite: false,
          // endOnTick: false,
          labels: {
            style: {
              color: "black",
            },
            align: "right",
            x: 10,
            formatter: function () {
              return this.value + "%";
            },
          },
          // gridLineWidth: 0,
          title: {
            text: "市寬(S&P)",
            align: "high",
            rotation: 0,
            y: 2.5,
            x: -40,
            style: {
              color: Highcharts.getOptions().colors[1],
            },
          },
          top: "70%",
          height: "15%",
          offset: -20,
        },
        {
          labels: {
            enabled: false,
          },
          // gridLineWidth: 0,
          title: {
            text: "nhnl",
            align: "high",
            rotation: 0,
            y: 2.5,
            x: -30,
            style: {
              color: Highcharts.getOptions().colors[1],
            },
          },
          top: "85%",
          height: "15%",
          offset: -20,
        },
      ],

      plotOptions: {
        candlestick: {
          lineColor: "red",
          color: "white",
          upLineColor: "black",
          upColor: "Green",
        },
      },

      tooltip: {
        xDateFormat: "%Y/%m/%d %a",
        shared: true,
        split: false,
        enabled: true,
        distance: 50,
        valueDecimals: 1,
        // headerFormat: "{point.key} ",
        // pointFormat:
        //   ' | <span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b>',
        // positioner: function () {
        //   return { x: 10, y: 35 };
        // },
        // useHTML: true,
        // headerFormat: '<table><tr><th colspan="2">{point.key}</th></tr>',
        // pointFormat:
        //   '<tr><td style="color: {series.color}">{series.name} </td>' +
        //   '<td style="text-align: right"><b>{point.y} EUR</b></td></tr>',
        // footerFormat: "</table>",
      },

      scrollbar: {
        enabled: true,
      },

      series: [
        {
          type: "candlestick",
          id: "S&P500",
          name: "S&P500指數",
          data: marketData[0].data,
          yAxis: 0,
          showInLegend: false,
        },
        {
          type: "line",
          name: "MKT-MA50(%)",
          data: marketData[1].data,
          yAxis: 1,
          color: "#EB7E30",
          visible: true,
        },
        {
          type: "line",
          name: "MKT-MA100(%)",
          data: marketData[2].data,
          yAxis: 1,
          color: "#0072ce",
          visible: false,
        },
        {
          type: "line",
          name: "MKT-MA150(%)",
          data: marketData[3].data,
          yAxis: 1,
          color: "red",
          visible: false,
        },
        {
          type: "line",
          name: "MKT-MA200(%)",
          data: marketData[4].data,
          yAxis: 1,
          color: "green",
          visible: true,
        },
        {
          type: "line",
          name: "S&P500-MA50(%)",
          data: sp500Data[1].data,
          yAxis: 2,
          color: "#EB7E30",
          visible: true,
        },
        {
          type: "line",
          name: "S&P500-MA100(%)",
          data: sp500Data[2].data,
          yAxis: 2,
          color: "#0072ce",
          visible: false,
        },
        {
          type: "line",
          name: "S&P500-MA150(%)",
          data: sp500Data[3].data,
          yAxis: 2,
          color: "red",
          visible: false,
        },
        {
          type: "line",
          name: "S&P500-MA200(%)",
          data: sp500Data[4].data,
          yAxis: 2,
          color: "green",
          visible: true,
        },
        {
          type: "column",
          name: "nhnl",
          data: nh_nl[0].data,
          yAxis: 3,
          color: "green",
          negativeColor: "red",
          showInLegend: false,
        },
      ],
      credits: {
        text: "DaaSHK",
        style: {
          fontSize: "0.8em",
        },
      },
    };
  }

  return (
    <>
      {stockStat.data && stockStat.data.date.length > 0 ? (
        <>
          <Header as="h3" textAlign="center">
            S{"&"}P500及市寛
            <Header.Subheader>
              收市價 {">"} MA 市寬 <br /> nhnl: 市場上創52W(新高-新低)之數目{" "}
            </Header.Subheader>
          </Header>
          <div>
            <HighchartsReact
              constructorType={"stockChart"}
              highcharts={Highcharts}
              options={chartOption}
            />
          </div>
        </>
      ) : !stockStat.loaded ? (
        <Loader active inline="centered" />
      ) : (
        <div>沒有資料</div>
      )}
    </>
  );
}
