import React, { useEffect, useState } from "react";
import { Loader, Table, Pagination, Menu, Container, Header, Popup, Icon, Input} from 'semantic-ui-react'
import _ from 'lodash'
import { createStore, combineReducers } from 'redux';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { selectAllTopic, fetchTopic, toIdle } from '../../features/topic/topicSlice'
import { useSelector, useDispatch } from 'react-redux'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import InFeedAdsWhite from '../ads/InFeedAdsWhite';
import axios from 'axios';


const carparkdata = 
{
  "10": {
      "park_id": "10",
      "parking_name_en": "Kai Tak Cruise Terminal Car Park 1",
      "parking_address_en": "1st floor, Kai Tak Cruise Terminal, 33 Shing Fung Road, Kowloon Bay, KLN",
      "parking_district_en": "Kwun Tong District",
      "parking_name_sc": "啟德郵輪碼頭停車場 1",
      "parking_address_sc": "九龍九龍灣承豐路33號啟德郵輪碼頭1樓",
      "parking_district_sc": "觀塘區",
      "parking_name_tc": "啟德郵輪碼頭停車場 1",
      "parking_address_tc": "九龍九龍灣承豐路33號啟德郵輪碼頭1樓",
      "parking_district_tc": "觀塘區",
      "parking_starttime": "07:00",
      "parking_endtime": "23:00",
      "parking_contactNo": "+852 3465 6888, 09:30-18:00 Mon-Fri, except public holiday",
      "parking_latitude": "22.3062049",
      "parking_longitude": "114.21309471"
  },
  "12": {
      "park_id": "12",
      "parking_name_en": "Amoy Plaza",
      "parking_address_en": "77 Ngau Tau Kok Raod, Kowloon Bay, KLN",
      "parking_district_en": "Kwun Tong District",
      "parking_name_sc": "淘大商場",
      "parking_address_sc": "九龍九龍灣牛頭角道77號",
      "parking_district_sc": "觀塘區",
      "parking_name_tc": "淘大商場",
      "parking_address_tc": "九龍九龍灣牛頭角道77號",
      "parking_district_tc": "觀塘區",
      "parking_starttime": "00:00",
      "parking_endtime": "24:00",
      "parking_contactNo": "27563666",
      "parking_latitude": "22.32470074",
      "parking_longitude": "114.21675324"
  },
  "27": {
      "park_id": "27",
      "parking_name_en": "Yau Lai Shopping Centre Carpark",
      "parking_address_en": "LG1 & LG2, Yau Lai Shopping Centre, 9 Yau Tong, Yau Tong, Kowloon, Yau Tong, KLN",
      "parking_district_en": "Kwun Tong District",
      "parking_name_sc": "油麗商場停車場",
      "parking_address_sc": "九龍油塘油塘道9號油麗商場LG1 & LG2",
      "parking_district_sc": "觀塘區",
      "parking_name_tc": "油麗商場停車場",
      "parking_address_tc": "九龍油塘油塘道9號油麗商場LG1 & LG2",
      "parking_district_tc": "觀塘區",
      "parking_starttime": "00:00",
      "parking_endtime": "00:00",
      "parking_contactNo": "23530667",
      "parking_latitude": "22.29851205",
      "parking_longitude": "114.23740089"
  },
  "30": {
      "park_id": "30",
      "parking_name_en": "Telford Plaza D Carpark",
      "parking_address_en": "33 Wai Yip Street, Kowloon Bay, KLN",
      "parking_district_en": "Kwun Tong District",
      "parking_name_sc": "德福廣場D車場",
      "parking_address_sc": "九龍九龍灣偉業街33號",
      "parking_district_sc": "觀塘區",
      "parking_name_tc": "德福廣場D車場",
      "parking_address_tc": "九龍九龍灣偉業街33號",
      "parking_district_tc": "觀塘區",
      "parking_starttime": "00:00",
      "parking_endtime": "24:00",
      "parking_contactNo": "2997 2262",
      "parking_latitude": "22.32228903",
      "parking_longitude": "114.21204329"
  },
  "31": {
      "park_id": "31",
      "parking_name_en": "Telford Plaza I Carpark",
      "parking_address_en": "33 Wai Yip Street, Kowloon Bay, KLN",
      "parking_district_en": "Kwun Tong District",
      "parking_name_sc": "德福廣場一期停車場",
      "parking_address_sc": "九龍九龍灣偉業街33號",
      "parking_district_sc": "觀塘區",
      "parking_name_tc": "德福廣場一期停車場",
      "parking_address_tc": "九龍九龍灣偉業街33號",
      "parking_district_tc": "觀塘區",
      "parking_starttime": "00:00",
      "parking_endtime": "24:00",
      "parking_contactNo": "2997 2262",
      "parking_latitude": "22.32268602",
      "parking_longitude": "114.21353459"
  },
  "32": {
      "park_id": "32",
      "parking_name_en": "Telford Plaza II Carpark",
      "parking_address_en": "33 Wai Yip Street, Kowloon Bay, KLN",
      "parking_district_en": "Kwun Tong District",
      "parking_name_sc": "德福廣場二期停車場",
      "parking_address_sc": "九龍九龍灣偉業街33號",
      "parking_district_sc": "觀塘區",
      "parking_name_tc": "德福廣場二期停車場",
      "parking_address_tc": "九龍九龍灣偉業街33號",
      "parking_district_tc": "觀塘區",
      "parking_starttime": "00:00",
      "parking_endtime": "24:00",
      "parking_contactNo": "2997 2262",
      "parking_latitude": "22.32135609",
      "parking_longitude": "114.21326637"
  },
  "36": {
      "park_id": "36",
      "parking_name_en": "Kowloonbay International Trade & Exhibition Centre",
      "parking_address_en": "Kowloonbay International Trade & Exhibitiion Centre, 1 Trademart Drive, Kowloon Bay, KLN",
      "parking_district_en": "Kwun Tong District",
      "parking_name_sc": "九龍灣國際展貿中心",
      "parking_address_sc": "九龍九龍灣展貿徑1號九龍灣國際展貿中心",
      "parking_district_sc": "觀塘區",
      "parking_name_tc": "九龍灣國際展貿中心",
      "parking_address_tc": "九龍九龍灣展貿徑1號九龍灣國際展貿中心",
      "parking_district_tc": "觀塘區",
      "parking_starttime": "07:00",
      "parking_endtime": "00:30",
      "parking_contactNo": "2620 3388",
      "parking_latitude": "22.3229788",
      "parking_longitude": "114.20428634"
  },
  "37": {
      "park_id": "37",
      "parking_name_en": "Manhattan Place Carpark",
      "parking_address_en": "Manhattan Place, 23 Wang Tai Road, Kowloon Bay, KLN",
      "parking_district_en": "Kwun Tong District",
      "parking_name_sc": "Manhattan Place 停車場",
      "parking_address_sc": "九龍九龍灣宏泰道23號Manhattan Place",
      "parking_district_sc": "觀塘區",
      "parking_name_tc": "Manhattan Place 停車場",
      "parking_address_tc": "九龍九龍灣宏泰道23號Manhattan Place",
      "parking_district_tc": "觀塘區",
      "parking_starttime": "00:00",
      "parking_endtime": "24:00",
      "parking_contactNo": "27079990",
      "parking_latitude": "22.31994674",
      "parking_longitude": "114.21005309"
  },
  "38": {
      "park_id": "38",
      "parking_name_en": "Domain Carpark",
      "parking_address_en": "LG1, Domain, 38 Ko Chiu Road, Yau Tong, KLN",
      "parking_district_en": "Kwun Tong District",
      "parking_name_sc": "大本型停車場",
      "parking_address_sc": "九龍油塘高超道38號LG1",
      "parking_district_sc": "觀塘區",
      "parking_name_tc": "大本型停車場",
      "parking_address_tc": "九龍油塘高超道38號LG1",
      "parking_district_tc": "觀塘區",
      "parking_starttime": "00:00",
      "parking_endtime": "24:00",
      "parking_contactNo": "28702992",
      "parking_latitude": "22.29598076",
      "parking_longitude": "114.23869371"
  },
  "39": {
      "park_id": "39",
      "parking_name_en": "Choi Tak Estate Carpark",
      "parking_address_en": "Choi Tak Estate, Ngau Tau Kok, KLN",
      "parking_district_en": "Kwun Tong District",
      "parking_name_sc": "彩德邨停車場",
      "parking_address_sc": "九龍牛頭角彩德商場",
      "parking_district_sc": "觀塘區",
      "parking_name_tc": "彩德邨停車場",
      "parking_address_tc": "九龍牛頭角彩德商場",
      "parking_district_tc": "觀塘區",
      "parking_starttime": "00:00",
      "parking_endtime": "24:00",
      "parking_contactNo": "28063290",
      "parking_latitude": "22.32957367",
      "parking_longitude": "114.21329856"
  },
  "49": {
      "park_id": "49",
      "parking_name_en": "One Pacific Centre",
      "parking_address_en": "Room 1905, 19/F, One Pacific Centre, 414 Kwun Tong Road, Kwun Tong, KLN",
      "parking_district_en": "Kwun Tong District",
      "parking_name_sc": "1亞太中心",
      "parking_address_sc": "九龍觀塘觀塘道414號1亞太中心19/F1905室",
      "parking_district_sc": "觀塘區",
      "parking_name_tc": "1亞太中心",
      "parking_address_tc": "九龍觀塘觀塘道414號1亞太中心19/F1905室",
      "parking_district_tc": "觀塘區",
      "parking_starttime": "00:00",
      "parking_endtime": "24:00",
      "parking_contactNo": "34282233",
      "parking_latitude": "22.31269634",
      "parking_longitude": "114.22471404"
  },
  "50": {
      "park_id": "50",
      "parking_name_en": "Millennium City 1 (Wilson Parking)",
      "parking_address_en": "388 Kwun Tong Road, Kwun Tong, KLN",
      "parking_district_en": "Kwun Tong District",
      "parking_name_sc": "創紀之城1期 (威信停車場)",
      "parking_address_sc": "九龍觀塘觀塘道388號",
      "parking_district_sc": "觀塘區",
      "parking_name_tc": "創紀之城1期 (威信停車場)",
      "parking_address_tc": "九龍觀塘觀塘道388號",
      "parking_district_tc": "觀塘區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "9856-0631",
      "parking_latitude": "22.31315787",
      "parking_longitude": "114.22168851"
  },
  "51": {
      "park_id": "51",
      "parking_name_en": "Millennium City 2 & 3 (Wilson Parking)",
      "parking_address_en": "370-378 Kwun Tong Road, Kwun Tong, KLN",
      "parking_district_en": "Kwun Tong District",
      "parking_name_sc": "創紀之城2及3期 (威信停車場)",
      "parking_address_sc": "九龍觀塘觀塘道370-378號",
      "parking_district_sc": "觀塘區",
      "parking_name_tc": "創紀之城2及3期 (威信停車場)",
      "parking_address_tc": "九龍觀塘觀塘道370-378號",
      "parking_district_tc": "觀塘區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2172-7112",
      "parking_latitude": "22.31420005",
      "parking_longitude": "114.2200309"
  },
  "52": {
      "park_id": "52",
      "parking_name_en": "Millennium City 6 (Wilson Parking)",
      "parking_address_en": "392 Kwun Tong Road, Kwun Tong, KLN",
      "parking_district_en": "Kwun Tong District",
      "parking_name_sc": "創紀之城6期 (威信停車場)",
      "parking_address_sc": "九龍觀塘觀塘道392號",
      "parking_district_sc": "觀塘區",
      "parking_name_tc": "創紀之城6期 (威信停車場)",
      "parking_address_tc": "九龍觀塘觀塘道392號",
      "parking_district_tc": "觀塘區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2954-8029",
      "parking_latitude": "22.31293951",
      "parking_longitude": "114.22217667"
  },
  "53": {
      "park_id": "53",
      "parking_name_en": "Paul Y. Centre (Wilson Parking)",
      "parking_address_en": "51 Hung To Road, Kwun Tong, KLN",
      "parking_district_en": "Kwun Tong District",
      "parking_name_sc": "保華企業中心 (威信停車場)",
      "parking_address_sc": "九龍觀塘鴻圖道51號",
      "parking_district_sc": "觀塘區",
      "parking_name_tc": "保華企業中心 (威信停車場)",
      "parking_address_tc": "九龍觀塘鴻圖道51號",
      "parking_district_tc": "觀塘區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2793-3019",
      "parking_latitude": "22.31137624",
      "parking_longitude": "114.22169387"
  },
  "59": {
      "park_id": "59",
      "parking_name_en": "Kai Tak Cruise Terminal Car Park 2",
      "parking_address_en": "1st floor, Kai Tak Cruise Terminal, 33 Shing Fung Road, Kowloon Bay, KLN",
      "parking_district_en": "Kwun Tong District",
      "parking_name_sc": "啟德郵輪碼頭停車場 2",
      "parking_address_sc": "九龍九龍灣承豐路33號啟德郵輪碼頭1樓",
      "parking_district_sc": "觀塘區",
      "parking_name_tc": "啟德郵輪碼頭停車場 2",
      "parking_address_tc": "九龍九龍灣承豐路33號啟德郵輪碼頭1樓",
      "parking_district_tc": "觀塘區",
      "parking_starttime": "07:00",
      "parking_endtime": "23:00",
      "parking_contactNo": "+852 3465 6888, 09:30-18:00 Mon-Fri, except public holiday",
      "parking_latitude": "22.30618008",
      "parking_longitude": "114.21272993"
  },
  "73": {
      "park_id": "73",
      "parking_name_en": "New Tech Plaza (Wilson Parking)",
      "parking_address_en": "34 Tai Yau Street, San Po Kong, KLN",
      "parking_district_en": "Wong Tai Sin District",
      "parking_name_sc": "新蒲崗新科技廣場 (威信停車場)",
      "parking_address_sc": "九龍新蒲崗大有街34號",
      "parking_district_sc": "黃大仙區",
      "parking_name_tc": "新蒲崗新科技廣場 (威信停車場)",
      "parking_address_tc": "九龍新蒲崗大有街34號",
      "parking_district_tc": "黃大仙區",
      "parking_starttime": "00:00",
      "parking_endtime": "00:00",
      "parking_contactNo": "9544-0650",
      "parking_latitude": "22.33677854",
      "parking_longitude": "114.19701219"
  },
  "74": {
      "park_id": "74",
      "parking_name_en": "Goldin Financial Global Centre",
      "parking_address_en": "Goldin Financial Global Centre, 17 Kai Cheung Road, Kowloon Bay, KLN",
      "parking_district_en": "Kwun Tong District",
      "parking_name_sc": "高銀金融國際中心",
      "parking_address_sc": "九龍九龍灣啟祥道17號高銀金融國際中心",
      "parking_district_sc": "觀塘區",
      "parking_name_tc": "高銀金融國際中心",
      "parking_address_tc": "九龍九龍灣啟祥道17號高銀金融國際中心",
      "parking_district_tc": "觀塘區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "38993888",
      "parking_latitude": "22.32588177",
      "parking_longitude": "114.20690417"
  },
  "75": {
      "park_id": "75",
      "parking_name_en": "Landmark East Carpark",
      "parking_address_en": "Landmark East, 100 How Ming Street, Kwun Tong, KLN",
      "parking_district_en": "Kwun Tong District",
      "parking_name_sc": "Landmark East 停車場",
      "parking_address_sc": "九龍觀塘巧明街100號Landmark East",
      "parking_district_sc": "觀塘區",
      "parking_name_tc": "Landmark East 停車場",
      "parking_address_tc": "九龍觀塘巧明街100號Landmark East",
      "parking_district_tc": "觀塘區",
      "parking_starttime": "00:00",
      "parking_endtime": "00:00",
      "parking_contactNo": "3971 0203",
      "parking_latitude": "22.31267152",
      "parking_longitude": "114.22228396"
  },
  "77": {
      "park_id": "77",
      "parking_name_en": "Kingston International Center",
      "parking_address_en": "19 Wang Chiu Road, Kowloon Bay, KLN",
      "parking_district_en": "Kwun Tong District",
      "parking_name_sc": "金利豐國際中心",
      "parking_address_sc": "九龍九龍灣宏照道19號",
      "parking_district_sc": "觀塘區",
      "parking_name_tc": "金利豐國際中心",
      "parking_address_tc": "九龍九龍灣宏照道19號",
      "parking_district_tc": "觀塘區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "35895161",
      "parking_latitude": "22.32361547",
      "parking_longitude": "114.20924917"
  },
  "78": {
      "park_id": "78",
      "parking_name_en": "Stelux House",
      "parking_address_en": "Stelux House, 698 Prince Edward Road East, San Po Kong, KLN",
      "parking_district_en": "Wong Tai Sin District",
      "parking_name_sc": "寶光商業中心",
      "parking_address_sc": "九龍新蒲崗太子道東698號寶光商業中心",
      "parking_district_sc": "黃大仙區",
      "parking_name_tc": "寶光商業中心",
      "parking_address_tc": "九龍新蒲崗太子道東698號寶光商業中心",
      "parking_district_tc": "黃大仙區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2350 4501",
      "parking_latitude": "22.33448116",
      "parking_longitude": "114.1981709"
  },
  "79": {
      "park_id": "79",
      "parking_name_en": "NEO",
      "parking_address_en": "NEO, 123 Hoi Bun Road, Kwun Tong, KLN",
      "parking_district_en": "Kwun Tong District",
      "parking_name_sc": "綠景",
      "parking_address_sc": "九龍觀塘海濱道123號NEO",
      "parking_district_sc": "觀塘區",
      "parking_name_tc": "綠景",
      "parking_address_tc": "九龍觀塘海濱道123號NEO",
      "parking_district_tc": "觀塘區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2118 0002",
      "parking_latitude": "22.31411072",
      "parking_longitude": "114.21644211"
  },
  "82": {
      "park_id": "82",
      "parking_name_en": "The Latitude Carpark",
      "parking_address_en": "B1 and B2, The Latitude, 638 Prince Edward Road East, San Po Kong, KLN",
      "parking_district_en": "Wong Tai Sin District",
      "parking_name_sc": "譽．港灣停車場",
      "parking_address_sc": "九龍新蒲崗太子道東638號譽．港灣B1及B2層",
      "parking_district_sc": "黃大仙區",
      "parking_name_tc": "譽．港灣停車場",
      "parking_address_tc": "九龍新蒲崗太子道東638號譽．港灣B1及B2層",
      "parking_district_tc": "黃大仙區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "39809900",
      "parking_latitude": "22.3338361",
      "parking_longitude": "114.19739306"
  },
  "tdc6p17": {
      "park_id": "tdc6p17",
      "parking_name_en": "Hung Fuk Estate Carpark",
      "parking_address_en": "Hung Fuk Shopping Centre Management Office, G/F, Ancillary Facilities Block, Hung Fuk Estate, Hung Shui Kiu, Yuen Long",
      "parking_district_en": "Yuen Long",
      "parking_name_sc": "洪福邨停車場",
      "parking_address_sc": "元朗洪水桥洪福邨服务设施大楼地下洪福商场管理处",
      "parking_district_sc": "元朗区",
      "parking_name_tc": "洪福邨停車場",
      "parking_address_tc": "元朗洪水橋洪福邨服務設施大樓地下洪福商場管理處",
      "parking_district_tc": "元朗區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2377 9431",
      "parking_latitude": "22.43649101",
      "parking_longitude": "113.99769592"
  },
  "tdc6p18": {
      "park_id": "tdc6p18",
      "parking_name_en": "Shui Chuen O Capark",
      "parking_address_en": "Shui Chuen O Plaza, 1 Pok Chuen Street, Shatin",
      "parking_district_en": "Sha Tin",
      "parking_name_sc": "水泉澳停車場",
      "parking_address_sc": "沙田博泉街1號水泉澳廣場",
      "parking_district_sc": "沙田区",
      "parking_name_tc": "水泉澳停車場",
      "parking_address_tc": "沙田博泉街1號水泉澳廣場",
      "parking_district_tc": "沙田區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2377 9431",
      "parking_latitude": "22.3737011",
      "parking_longitude": "114.19704437"
  },
  "tdc6p19": {
      "park_id": "tdc6p19",
      "parking_name_en": "Hoi Lai Estate Car Park",
      "parking_address_en": "100 Sham Mong Road, Cheung Sha Wan, Kowloon, HK",
      "parking_district_en": "Sham Shui Po",
      "parking_name_sc": "海麗邨停車場",
      "parking_address_sc": "九龍長沙灣深旺道100號",
      "parking_district_sc": "深水埗区",
      "parking_name_tc": "海麗邨停車場",
      "parking_address_tc": "九龍長沙灣深旺道100號",
      "parking_district_tc": "深水埗區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3514 6415 / 5281 1471",
      "parking_latitude": "22.33249474",
      "parking_longitude": "114.1458664"
  },
  "tdc6p20": {
      "park_id": "tdc6p20",
      "parking_name_en": "Shek Pai Wan Estate Car Park",
      "parking_address_en": "Shek Pai Wan, Yue Kwong Road, Aberdeen, Hong Kong",
      "parking_district_en": "Southern",
      "parking_name_sc": "石排灣邨停車場",
      "parking_address_sc": "香港仔石排灣漁光道",
      "parking_district_sc": "南区",
      "parking_name_tc": "石排灣邨停車場",
      "parking_address_tc": "香港仔石排灣漁光道",
      "parking_district_tc": "南區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3583 0248",
      "parking_latitude": "22.24921036",
      "parking_longitude": "114.1585693"
  },
  "tdc6p21": {
      "park_id": "tdc6p21",
      "parking_name_en": "Yue Wan Estate Car Park",
      "parking_address_en": "365 Chai Wan Road, Chai Wan, Hong Kong",
      "parking_district_en": "Eastern",
      "parking_name_sc": "漁灣邨停車場",
      "parking_address_sc": "柴灣柴灣道365號",
      "parking_district_sc": "东区",
      "parking_name_tc": "漁灣邨停車場",
      "parking_address_tc": "柴灣柴灣道365號",
      "parking_district_tc": "東區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2505 1471 / 5281 0834 ",
      "parking_latitude": "22.26508713",
      "parking_longitude": "114.2402191"
  },
  "tdc6p22": {
      "park_id": "tdc6p22",
      "parking_name_en": "So Uk Estate Car Park (Phase 2)",
      "parking_address_en": "Cheung Sha Wan Cheung Fat Street, Kowloon",
      "parking_district_en": "Sham Shui Po",
      "parking_name_sc": "蘇屋邨停車場 (第二期)",
      "parking_address_sc": "九龍長沙灣長發街",
      "parking_district_sc": "深水埗区",
      "parking_name_tc": "蘇屋邨停車場 (第二期)",
      "parking_address_tc": "九龍長沙灣長發街",
      "parking_district_tc": "深水埗區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2741 6968 / 5281 3274",
      "parking_latitude": "22.34108161",
      "parking_longitude": "114.1565667"
  },
  "tdc6p6": {
      "park_id": "tdc6p6",
      "parking_name_en": "Po Heung Estate Car Park",
      "parking_address_en": "No.1 Po Heung Street, Tai Po, N.T",
      "parking_district_en": "Tai Po",
      "parking_name_sc": "宝乡邨停车场",
      "parking_address_sc": "沐宁街 九龙城",
      "parking_district_sc": "大埔区",
      "parking_name_tc": "寶鄉邨停車場",
      "parking_address_tc": "寶鄉邨停車場",
      "parking_district_tc": "大埔區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "",
      "parking_latitude": "22.446862864",
      "parking_longitude": "114.165271187"
  },
  "tdc6p7": {
      "park_id": "tdc6p7",
      "parking_name_en": "Po Shek Wu Estate",
      "parking_address_en": "Choi Yuen Road, Sheung Shui, N.T.",
      "parking_district_en": "North",
      "parking_name_sc": "寶石湖邨",
      "parking_address_sc": "新界上水宝石湖路与彩园路路口",
      "parking_district_sc": "北区",
      "parking_name_tc": "寶石湖邨",
      "parking_address_tc": "新界上水寶石湖路與彩園路路口",
      "parking_district_tc": "北區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2739 0831",
      "parking_latitude": "22.50333",
      "parking_longitude": "114.1247"
  },
  "tdc6p8": {
      "park_id": "tdc6p8",
      "parking_name_en": "Kwai Shing West Estate Carpark",
      "parking_address_en": "Kwai Shing (West) Estate Carpark Kwai Chung, NT ",
      "parking_district_en": "Kwai Tsing",
      "parking_name_sc": "葵盛西邨停車場",
      "parking_address_sc": "新界葵涌葵盛西邨",
      "parking_district_sc": "葵青区",
      "parking_name_tc": "葵盛西邨停車場",
      "parking_address_tc": "新界葵涌葵盛西邨",
      "parking_district_tc": "葵青區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "98553566",
      "parking_latitude": "22.362299167",
      "parking_longitude": "114.123577257"
  },
  "tdc6p9": {
      "park_id": "tdc6p9",
      "parking_name_en": "Lai King Estate Carpark",
      "parking_address_en": "Lai King Estate Carpark Kwai Chung, NT",
      "parking_district_en": "Kwai Tsing",
      "parking_name_sc": "荔景邨停車場",
      "parking_address_sc": "新界葵涌荔景邨",
      "parking_district_sc": "葵青区",
      "parking_name_tc": "荔景邨停車場",
      "parking_address_tc": "新界葵涌荔景邨",
      "parking_district_tc": "葵青區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "91365567",
      "parking_latitude": "22.350990252",
      "parking_longitude": "114.126459924"
  },
  "tdc9p3": {
      "park_id": "tdc9p3",
      "parking_name_en": "Tai Po Complex Car Park",
      "parking_address_en": "No. 8 Heung Sze Wui Street, Tai Po",
      "parking_district_en": "Tai Po",
      "parking_name_sc": "大埔綜合大樓停車場",
      "parking_address_sc": "大埔乡事会街8号",
      "parking_district_sc": "大埔区",
      "parking_name_tc": "大埔綜合大樓停車場",
      "parking_address_tc": "大埔鄉事會街8號",
      "parking_district_tc": "大埔區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2370 8881",
      "parking_latitude": "22.44634819",
      "parking_longitude": "114.16671753"
  },
  "tdcp1": {
      "park_id": "tdcp1",
      "parking_name_en": "Yau Ma Tei Car Park",
      "parking_address_en": "250 Shanghai Street, Yau Ma Tei, Kowloon",
      "parking_district_en": "Yau Tsim Mong",
      "parking_name_sc": "油麻地停车场",
      "parking_address_sc": "九龙油麻地上海街250号 ",
      "parking_district_sc": "油尖旺区",
      "parking_name_tc": "油麻地停車場",
      "parking_address_tc": "九龍油麻地上海街250號 ",
      "parking_district_tc": "油尖旺區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2771 2915",
      "parking_latitude": "22.30919474",
      "parking_longitude": "114.17043492"
  },
  "tdcp10": {
      "park_id": "tdcp10",
      "parking_name_en": "Aberdeen Car Park",
      "parking_address_en": "18 Aberdeen Reservoir Road, Aberdeen, Hong Kong",
      "parking_district_en": "Southern",
      "parking_name_sc": "香港仔停车场",
      "parking_address_sc": "香港仔香港仔水塘道18号",
      "parking_district_sc": "南区",
      "parking_name_tc": "香港仔停車場",
      "parking_address_tc": "香港仔香港仔水塘道18號",
      "parking_district_tc": "南區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2580 3792",
      "parking_latitude": "22.25027272",
      "parking_longitude": "114.15661803"
  },
  "tdcp11": {
      "park_id": "tdcp11",
      "parking_name_en": "Tin Hau Car Park",
      "parking_address_en": "1 King's Road, Causeway Bay, Hong Kong",
      "parking_district_en": "Wan Chai",
      "parking_name_sc": "天后停车场 ",
      "parking_address_sc": "香港铜锣湾英皇道1号",
      "parking_district_sc": "湾仔区",
      "parking_name_tc": "天后停車場 ",
      "parking_address_tc": "香港銅鑼灣英皇道1號",
      "parking_district_tc": "灣仔區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2566 3419",
      "parking_latitude": "22.28288221",
      "parking_longitude": "114.19166511"
  },
  "tdcp12": {
      "park_id": "tdcp12",
      "parking_name_en": "Sheung Fung Street Car Park",
      "parking_address_en": "Sheung Fung Street, Wong Tai Sin, Kowloon",
      "parking_district_en": "Wong Tai Sin",
      "parking_name_sc": "双凤街停车场",
      "parking_address_sc": "九龙黄大仙双凤街",
      "parking_district_sc": "黄大仙区",
      "parking_name_tc": "雙鳳街停車場",
      "parking_address_tc": "九龍黃大仙雙鳳街",
      "parking_district_tc": "黃大仙區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2350 3386",
      "parking_latitude": "22.34395897",
      "parking_longitude": "114.19705899"
  },
  "tdcp2": {
      "park_id": "tdcp2",
      "parking_name_en": "Rumsey Street Car Park",
      "parking_address_en": "2 Rumsey Street, Sheung Wan, Hong Kong",
      "parking_district_en": "Central & Western",
      "parking_name_sc": "林士街停车场",
      "parking_address_sc": "香港上环林士街2号",
      "parking_district_sc": "中西区",
      "parking_name_tc": "林士街停車場",
      "parking_address_tc": "香港上環林士街2號",
      "parking_district_tc": "中西區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2545 6543",
      "parking_latitude": "22.28740848",
      "parking_longitude": "114.15322694"
  },
  "tdcp3": {
      "park_id": "tdcp3",
      "parking_name_en": "Tsuen Wan Car Park",
      "parking_address_en": "174-208 Castle Peak Road, Tsuen Wan, New Territories",
      "parking_district_en": "Tsuen Wan",
      "parking_name_sc": "荃湾停车场 ",
      "parking_address_sc": "新界荃湾青山公路-荃湾段174-208号",
      "parking_district_sc": "荃湾区",
      "parking_name_tc": "荃灣停車場 ",
      "parking_address_tc": "新界荃灣青山公路-荃灣段174-208號",
      "parking_district_tc": "荃灣區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2611 9148",
      "parking_latitude": "22.37284735",
      "parking_longitude": "114.11860252"
  },
  "tdcp5": {
      "park_id": "tdcp5",
      "parking_name_en": "City Hall Car Park",
      "parking_address_en": "1 Edinburgh Place, Central, Hong Kong",
      "parking_district_en": "Central & Western",
      "parking_name_sc": "大会堂停车场",
      "parking_address_sc": "香港中环爱丁堡广场1号 ",
      "parking_district_sc": "中西区",
      "parking_name_tc": "大會堂停車場",
      "parking_address_tc": "香港中環愛丁堡廣場1號 ",
      "parking_district_tc": "中西區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2118 6918",
      "parking_latitude": "22.28191055",
      "parking_longitude": "114.16213972"
  },
  "tdcp6": {
      "park_id": "tdcp6",
      "parking_name_en": "Kwai Fong Car Park",
      "parking_address_en": "19 Kwai Yi Road, Kwai Chung, New Territories",
      "parking_district_en": "Kwai Tsing",
      "parking_name_sc": "葵芳停车场 ",
      "parking_address_sc": "新界葵涌葵义路19号",
      "parking_district_sc": "葵青区",
      "parking_name_tc": "葵芳停車場 ",
      "parking_address_tc": "新界葵涌葵義路19號",
      "parking_district_tc": "葵青區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2429 0566",
      "parking_latitude": "22.35548531",
      "parking_longitude": "114.1273145"
  },
  "tdcp7": {
      "park_id": "tdcp7",
      "parking_name_en": "Kennedy Town Car Park",
      "parking_address_en": "12 Rock Hill Street, Kennedy Town, Hong Kong",
      "parking_district_en": "Central & Western",
      "parking_name_sc": "坚尼地城停车场",
      "parking_address_sc": "香港坚尼地城石山街12号",
      "parking_district_sc": "中西区",
      "parking_name_tc": "堅尼地城停車場",
      "parking_address_tc": "香港堅尼地城石山街12號",
      "parking_district_tc": "中西區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2986 4507",
      "parking_latitude": "22.28177031",
      "parking_longitude": "114.12885637"
  },
  "tdcp8": {
      "park_id": "tdcp8",
      "parking_name_en": "Star Ferry Car Park",
      "parking_address_en": "9 Edinburgh Place, Central, Hong Kong",
      "parking_district_en": "Central & Western",
      "parking_name_sc": "天星停车场",
      "parking_address_sc": "香港中环爱丁堡广场9号 ",
      "parking_district_sc": "中西区",
      "parking_name_tc": "天星停車場",
      "parking_address_tc": "香港中環愛丁堡廣場9號 ",
      "parking_district_tc": "中西區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2118 6917",
      "parking_latitude": "22.28275461",
      "parking_longitude": "114.16043975"
  },
  "tdstt7p1": {
      "park_id": "tdstt7p1",
      "parking_name_en": "Treasure Top Carpark (Tai Po Yee Nga Court)",
      "parking_address_en": "Adjacent to Yee Nga Court , Area 17, Tai Po, NT",
      "parking_district_en": "Tai Po",
      "parking_name_sc": "宝淘停车场(大埔怡雅苑)",
      "parking_address_sc": "新界大埔第 17 区毗邻怡雅苑",
      "parking_district_sc": "大埔区",
      "parking_name_tc": "寶淘停車場(大埔怡雅苑)",
      "parking_address_tc": "新界大埔第 17 區毗鄰怡雅苑",
      "parking_district_tc": "大埔區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "62237535",
      "parking_latitude": "22.4547138",
      "parking_longitude": "114.1725464"
  },
  "tdstt9p1": {
      "park_id": "tdstt9p1",
      "parking_name_en": "Shing Hong Lane",
      "parking_address_en": "Shing Hong Lane Open Space Carpark, Heng Fa Chuen",
      "parking_district_en": "Eastern",
      "parking_name_sc": "盛康里",
      "parking_address_sc": "杏花邨盛康里露天停车场",
      "parking_district_sc": "东区",
      "parking_name_tc": "盛康里",
      "parking_address_tc": "杏花邨盛康里露天停車場",
      "parking_district_tc": "東區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "",
      "parking_latitude": "22.27917047",
      "parking_longitude": "114.23856157"
  },
  "tdc14p3436": {
      "park_id": "tdc14p3436",
      "parking_name_en": "Tak Tin  Car Park B",
      "parking_address_en": "Tak Tin Plaza, 223 Pik Wan Road, Lam Tin, \nKwun Tong, Kowloon",
      "parking_district_en": "Kwun Tong",
      "parking_name_sc": "德田B停車場",
      "parking_address_sc": "东区 康盛街",
      "parking_district_sc": "观塘区",
      "parking_name_tc": "德田B停車場",
      "parking_address_tc": "九龍觀塘藍田碧雲道223號德田廣場",
      "parking_district_tc": "觀塘區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.30619",
      "parking_longitude": "114.2389"
  },
  "tdc14p3441": {
      "park_id": "tdc14p3441",
      "parking_name_en": "Kai Tin (New Wing) Car Park ",
      "parking_address_en": "Kai Tin \nShopping Centre, 50 Kai Tin Road, Lam Tin, Kwun Tong, Kowloon",
      "parking_district_en": "Kwun Tong",
      "parking_name_sc": "啟田新翼停車場",
      "parking_address_sc": "东区 建华街",
      "parking_district_sc": "观塘区",
      "parking_name_tc": "啟田新翼停車場",
      "parking_address_tc": "九龍觀塘藍田啟田道50號啟田商場",
      "parking_district_tc": "觀塘區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.30826",
      "parking_longitude": "114.2357"
  },
  "tdc14p3443": {
      "park_id": "tdc14p3443",
      "parking_name_en": "Ping Tin Phase 2 Car Park ",
      "parking_address_en": "Ping Tin Shopping Centre, 23 On Tin Street, Lam Tin, Kwun Tong, Kowloon",
      "parking_district_en": "Kwun Tong",
      "parking_name_sc": "平田2期停車場",
      "parking_address_sc": "东区 英皇道",
      "parking_district_sc": "观塘区",
      "parking_name_tc": "平田2期停車場",
      "parking_address_tc": "九龍觀塘藍田安田街23號平田商場",
      "parking_district_tc": "觀塘區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.30586",
      "parking_longitude": "114.2377"
  },
  "tdc14p3444": {
      "park_id": "tdc14p3444",
      "parking_name_en": "Ping Tin Phase 4 Car Park ",
      "parking_address_en": "Ping Tin Shopping Centre, 23 On Tin Street, Lam Tin, Kwun Tong, Kowloon",
      "parking_district_en": "Kwun Tong",
      "parking_name_sc": "平田4期停車場",
      "parking_address_sc": "沙田 铜锣湾山路",
      "parking_district_sc": "观塘区",
      "parking_name_tc": "平田4期停車場",
      "parking_address_tc": "九龍觀塘藍田安田街23號平田商場",
      "parking_district_tc": "觀塘區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.30853",
      "parking_longitude": "114.2364"
  },
  "tdc14p3445": {
      "park_id": "tdc14p3445",
      "parking_name_en": "Hong Yat Car Park ",
      "parking_address_en": "Hong Yat Court Retail and Car Park, 1 Tak Tin Street, Lam Tin, Kwun Tong, Kowloon",
      "parking_district_en": "Kwun Tong",
      "parking_name_sc": "康逸停車場",
      "parking_address_sc": "深水 长沙湾道",
      "parking_district_sc": "观塘区",
      "parking_name_tc": "康逸停車場",
      "parking_address_tc": "九龍觀塘藍田德田街1號康逸苑商舖及停車場",
      "parking_district_tc": "觀塘區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.310441",
      "parking_longitude": "114.23449"
  },
  "tdc14p3503": {
      "park_id": "tdc14p3503",
      "parking_name_en": "Siu Hei  Car Park ",
      "parking_address_en": "Siu Hei Commercial Centre, 201 \nWu Chui Road, Tuen Mun, New Territories",
      "parking_district_en": "Tuen Mun",
      "parking_name_sc": "兆禧停車場",
      "parking_address_sc": "南区 海滩道",
      "parking_district_sc": "屯门区",
      "parking_name_tc": "兆禧停車場",
      "parking_address_tc": "新界屯門湖翠路201號兆禧苑商場",
      "parking_district_tc": "屯門區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.373358",
      "parking_longitude": "113.966837"
  },
  "tdc14p3512": {
      "park_id": "tdc14p3512",
      "parking_name_en": "Leung King Car Park ",
      "parking_address_en": "Leung King Plaza, 31 Tin King Road, Tuen Mun, New Territories",
      "parking_district_en": "Tuen Mun",
      "parking_name_sc": "良景停車場",
      "parking_address_sc": "东区 英皇道",
      "parking_district_sc": "屯门区",
      "parking_name_tc": "良景停車場",
      "parking_address_tc": "新界屯門田景路31號良景廣場",
      "parking_district_tc": "屯門區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.406017",
      "parking_longitude": "113.962654"
  },
  "tdc14p3519": {
      "park_id": "tdc14p3519",
      "parking_name_en": "Kin Sang  Car Park ",
      "parking_address_en": "Kin Sang Shopping Centre, 3 Leung Wan Street, Tuen Mun, New Territories",
      "parking_district_en": "Tuen Mun",
      "parking_name_sc": "建生停車場",
      "parking_address_sc": "东区 祥利街",
      "parking_district_sc": "屯门区",
      "parking_name_tc": "建生停車場",
      "parking_address_tc": "新界屯門良運街3號建生商場",
      "parking_district_tc": "屯門區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.407139",
      "parking_longitude": "113.969919"
  },
  "tdc14p3520": {
      "park_id": "tdc14p3520",
      "parking_name_en": "Tai Hing - Car Park H",
      "parking_address_en": "Tai Hing Commercial Centre, 1 Tai Hing Street, Tuen Mun, New Territories",
      "parking_district_en": "Tuen Mun",
      "parking_name_sc": "大興H停車場",
      "parking_address_sc": "东区 利众街",
      "parking_district_sc": "屯门区",
      "parking_name_tc": "大興H停車場",
      "parking_address_tc": "新界屯門大興街1號大興商場",
      "parking_district_tc": "屯門區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.402652",
      "parking_longitude": "113.971106"
  },
  "tdc14p3521": {
      "park_id": "tdc14p3521",
      "parking_name_en": "Tai Hing - Car Park D ",
      "parking_address_en": "Tai Hing Commercial Centre, 1 Tai Hing Street, Tuen Mun, New Territories",
      "parking_district_en": "Tuen Mun",
      "parking_name_sc": "大興D停車場",
      "parking_address_sc": "东区 利众街",
      "parking_district_sc": "屯门区",
      "parking_name_tc": "大興D停車場",
      "parking_address_tc": "新界屯門大興街1號大興商場",
      "parking_district_tc": "屯門區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.403861",
      "parking_longitude": "113.970647"
  },
  "tdc14p3522": {
      "park_id": "tdc14p3522",
      "parking_name_en": "Tai Hing - Car Park  C ",
      "parking_address_en": "Tai Hing Commercial Centre, 1 Tai Hing Street, Tuen Mun, New \nTerritories",
      "parking_district_en": "Tuen Mun",
      "parking_name_sc": "大興C停車場",
      "parking_address_sc": "东区 利众街",
      "parking_district_sc": "屯门区",
      "parking_name_tc": "大興C停車場",
      "parking_address_tc": "新界屯門大興街1號大興商場",
      "parking_district_tc": "屯門區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.401487",
      "parking_longitude": "113.969813"
  },
  "tdc14p3526": {
      "park_id": "tdc14p3526",
      "parking_name_en": "Shan King Car Park  1 ",
      "parking_address_en": "Shan King Commercial \nCentre, 1 Ming Kum Road, Tuen Mun, New Territories",
      "parking_district_en": "Tuen Mun",
      "parking_name_sc": "山景1號停車場",
      "parking_address_sc": "观塘 开源道",
      "parking_district_sc": "屯门区",
      "parking_name_tc": "山景1號停車場",
      "parking_address_tc": "新界屯門鳴琴路1號山景商場",
      "parking_district_tc": "屯門區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.396745",
      "parking_longitude": "113.965599"
  },
  "tdc14p3527": {
      "park_id": "tdc14p3527",
      "parking_name_en": "Fu Tai Car Park ",
      "parking_address_en": "Fu Tai Shopping Centre, 9 Tuen Kwai Road, Tuen Mun, New Territories",
      "parking_district_en": "Tuen Mun",
      "parking_name_sc": "富泰停車場",
      "parking_address_sc": "观塘 开源道",
      "parking_district_sc": "屯门区",
      "parking_name_tc": "富泰停車場",
      "parking_address_tc": "新界屯門屯貴路9號富泰商場",
      "parking_district_tc": "屯門區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.414191",
      "parking_longitude": "113.983815"
  },
  "tdc14p3554": {
      "park_id": "tdc14p3554",
      "parking_name_en": "Kai Tin (Old Wing) Car Park ",
      "parking_address_en": "Kai Tin Shopping Centre, 50 Kai Tin Road, Lam Tin, Kwun Tong, Kowloon",
      "parking_district_en": "Kwun Tong",
      "parking_name_sc": "啟田舊翼停車場",
      "parking_address_sc": "九龙观塘蓝田启田道50号启田商场",
      "parking_district_sc": "观塘区",
      "parking_name_tc": "啟田舊翼停車場",
      "parking_address_tc": "九龍觀塘藍田啟田道50號啟田商場",
      "parking_district_tc": "觀塘區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.30892",
      "parking_longitude": "114.2344"
  },
  "tdc14p4219": {
      "park_id": "tdc14p4219",
      "parking_name_en": "Yu Chui Car Park A",
      "parking_address_en": "Yu Chui Shopping Centre, 2 Ngau Pei Sha Street, Siu Lek Yuen, Sha Tin, New Territories",
      "parking_district_en": "Sha Tin",
      "parking_name_sc": "愉翠A停車場",
      "parking_address_sc": "北区 智昌路",
      "parking_district_sc": "沙田区",
      "parking_name_tc": "愉翠A停車場",
      "parking_address_tc": "新界沙田小瀝源牛皮沙街2號愉翠商場",
      "parking_district_tc": "沙田區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.381515",
      "parking_longitude": "114.205714"
  },
  "tdc14p4220": {
      "park_id": "tdc14p4220",
      "parking_name_en": "Yu Chui Car Park B",
      "parking_address_en": "Yu Chui Shopping Centre, 2 Ngau Pei \nSha Street, Siu Lek Yuen, Sha Tin, New Territories",
      "parking_district_en": "Sha Tin",
      "parking_name_sc": "愉翠B停車場",
      "parking_address_sc": "北区 粉岭车站路",
      "parking_district_sc": "沙田区",
      "parking_name_tc": "愉翠B停車場",
      "parking_address_tc": "新界沙田小瀝源牛皮沙街2號愉翠商場",
      "parking_district_tc": "沙田區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.379938",
      "parking_longitude": "114.205293"
  },
  "tdc14p4222": {
      "park_id": "tdc14p4222",
      "parking_name_en": "Kwong Yuen Car Park A ",
      "parking_address_en": "Kwong Yuen Shopping Centre, 68 Siu Lek Yuen Road, Sha Tin, New Territories",
      "parking_district_en": "Sha Tin",
      "parking_name_sc": "廣源A停車場",
      "parking_address_sc": "北区 乐业路",
      "parking_district_sc": "沙田区",
      "parking_name_tc": "廣源A停車場",
      "parking_address_tc": "新界沙田小瀝源路68號廣源商場",
      "parking_district_tc": "沙田區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.381504",
      "parking_longitude": "114.215424"
  },
  "tdc14p4223": {
      "park_id": "tdc14p4223",
      "parking_name_en": "Lek Yuen Car Park ",
      "parking_address_en": "Lek Yuen Plaza, 6 Lek Yuen Street, Sha Tin, New Territories",
      "parking_district_en": "Sha Tin",
      "parking_name_sc": "瀝源停車場",
      "parking_address_sc": "北区 乐业路",
      "parking_district_sc": "沙田区",
      "parking_name_tc": "瀝源停車場",
      "parking_address_tc": "新界沙田瀝源街6號瀝源廣場",
      "parking_district_tc": "沙田區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.385562",
      "parking_longitude": "114.191191"
  },
  "tdc14p4225": {
      "park_id": "tdc14p4225",
      "parking_name_en": "Wo Che Car Park S ",
      "parking_address_en": "Wo Che Plaza, 3 Tak Hau Street, Sha Tin, New Territories",
      "parking_district_en": "Sha Tin",
      "parking_name_sc": "禾輋S場停車場",
      "parking_address_sc": "西贡 灵实路",
      "parking_district_sc": "沙田区",
      "parking_name_tc": "禾輋S場停車場",
      "parking_address_tc": "新界沙田德厚街3號禾輋廣場",
      "parking_district_tc": "沙田區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.387545",
      "parking_longitude": "114.195409"
  },
  "tdc14p4227": {
      "park_id": "tdc14p4227",
      "parking_name_en": "Wo Che Car Park F ",
      "parking_address_en": "Wo Che Plaza, 3 Tak Hau Street, Sha Tin, New Territories",
      "parking_district_en": "Sha Tin",
      "parking_name_sc": "禾輋F場停車場",
      "parking_address_sc": "东区 筲箕湾东大街",
      "parking_district_sc": "沙田区",
      "parking_name_tc": "禾輋F場停車場",
      "parking_address_tc": "新界沙田德厚街3號禾輋廣場",
      "parking_district_tc": "沙田區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.385801",
      "parking_longitude": "114.193494"
  },
  "tdc14p4235": {
      "park_id": "tdc14p4235",
      "parking_name_en": "Long Ping Car Park B ",
      "parking_address_en": "Long Ping Commercial Centre, 1 Long Ping Road, Yuen Long, New Territories",
      "parking_district_en": "Yuen Long",
      "parking_name_sc": "朗屏B停車場",
      "parking_address_sc": "新界元朗朗屏路1号朗屏商场",
      "parking_district_sc": "元朗区",
      "parking_name_tc": "朗屏B停車場",
      "parking_address_tc": "新界元朗朗屏路1號朗屏商場",
      "parking_district_tc": "元朗區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.450374",
      "parking_longitude": "114.023061"
  },
  "tdc14p4237": {
      "park_id": "tdc14p4237",
      "parking_name_en": "Long Ping Car Park D",
      "parking_address_en": "Long Ping Commercial Centre, 1 Long Ping \nRoad, Yuen Long, New Territories",
      "parking_district_en": "Yuen Long",
      "parking_name_sc": "朗屏D停車場",
      "parking_address_sc": "中西区 干诺道中",
      "parking_district_sc": "元朗区",
      "parking_name_tc": "朗屏D停車場",
      "parking_address_tc": "新界元朗朗屏路1號朗屏商場",
      "parking_district_tc": "元朗區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.448067",
      "parking_longitude": "114.023123"
  },
  "tdc14p4239": {
      "park_id": "tdc14p4239",
      "parking_name_en": "Tin Shing  Car Park A",
      "parking_address_en": "Tin \nShing Shopping Centre, 3 Tin Ching Street, Tin Shui Wai, Yuen Long, New Territories",
      "parking_district_en": "Yuen Long",
      "parking_name_sc": "天盛A停車場",
      "parking_address_sc": "中西区 干诺道西",
      "parking_district_sc": "元朗区",
      "parking_name_tc": "天盛A停車場",
      "parking_address_tc": "新界元朗天水圍天靖街3號天盛商場",
      "parking_district_tc": "元朗區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.448224",
      "parking_longitude": "114.003136"
  },
  "tdc14p4243": {
      "park_id": "tdc14p4243",
      "parking_name_en": "Tin Yiu Car Park  A ",
      "parking_address_en": "Tin Yiu Plaza, 2 Tin Wu Road, Tin Shui Wai, Yuen Long, New Territories",
      "parking_district_en": "Yuen Long",
      "parking_name_sc": "天耀A停車場",
      "parking_address_sc": "中西区 侨福道",
      "parking_district_sc": "元朗区",
      "parking_name_tc": "天耀A停車場",
      "parking_address_tc": "新界元朗天水圍天湖路2號天耀廣場",
      "parking_district_tc": "元朗區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.451433",
      "parking_longitude": "114.002891"
  },
  "tdc14p4246": {
      "park_id": "tdc14p4246",
      "parking_name_en": "Tin Tsz Car Park ",
      "parking_address_en": "Tin Tsz Shopping Centre, 9 Tin Hei Street, Tin Shui Wai, Yuen Long, New Territories",
      "parking_district_en": "Yuen Long",
      "parking_name_sc": "天慈停車場",
      "parking_address_sc": "香港西营盘东边街北18号",
      "parking_district_sc": "元朗区",
      "parking_name_tc": "天慈停車場",
      "parking_address_tc": "新界元朗天水圍天喜街9號天慈商場",
      "parking_district_tc": "元朗區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.453857",
      "parking_longitude": "114.008088"
  },
  "tdc14p4258": {
      "park_id": "tdc14p4258",
      "parking_name_en": "Sha Kok Car Park 2",
      "parking_address_en": "Sha Kok Commercial Centre, 5 Sha Kok Street, Sha Tin,  New Territories",
      "parking_district_en": "Sha Tin",
      "parking_name_sc": "沙角2號停車場",
      "parking_address_sc": "新界沙田沙角街5号沙角商场",
      "parking_district_sc": "沙田区",
      "parking_name_tc": "沙角2號停車場",
      "parking_address_tc": "新界沙田沙角街5號沙角商場",
      "parking_district_tc": "沙田區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.378105999",
      "parking_longitude": "114.193963002"
  },
  "tdc14p4259": {
      "park_id": "tdc14p4259",
      "parking_name_en": "Sha Kok Car Park 1",
      "parking_address_en": "Sha Kok Commercial Centre, 5 Sha Kok Street, Sha Tin,  New Territories",
      "parking_district_en": "Sha Tin",
      "parking_name_sc": "沙角1號停車場",
      "parking_address_sc": "新界沙田沙角街5号沙角商场",
      "parking_district_sc": "沙田区",
      "parking_name_tc": "沙角1號停車場",
      "parking_address_tc": "新界沙田沙角街5號沙角商場",
      "parking_district_tc": "沙田區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.377772874",
      "parking_longitude": "114.194143841"
  },
  "tdc14p4312": {
      "park_id": "tdc14p4312",
      "parking_name_en": "Kwong Fuk  Car Park ",
      "parking_address_en": "Kwong Fuk Commercial Centre, 28 Plover Cove Road, Tai Po, New Territories",
      "parking_district_en": "Tai Po",
      "parking_name_sc": "廣褔停車場",
      "parking_address_sc": "中西区 金钟道",
      "parking_district_sc": "大埔区",
      "parking_name_tc": "廣褔停車場",
      "parking_address_tc": "新界大埔寶湖道28號廣福商場",
      "parking_district_tc": "大埔區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.448533",
      "parking_longitude": "114.173875"
  },
  "tdc14p4320": {
      "park_id": "tdc14p4320",
      "parking_name_en": "Tai Wo Car Park A ",
      "parking_address_en": "Tai Wo Plaza, 12 Tai Wo Road, Tai Po, New \nTerritories",
      "parking_district_en": "Tai Po",
      "parking_name_sc": "太和A停車場",
      "parking_address_sc": "湾仔 告士打道",
      "parking_district_sc": "大埔区",
      "parking_name_tc": "太和A停車場",
      "parking_address_tc": "新界大埔太和路12號太和廣場",
      "parking_district_tc": "大埔區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.451409",
      "parking_longitude": "114.162171"
  },
  "tdc14p4321": {
      "park_id": "tdc14p4321",
      "parking_name_en": "Tai Wo Car Park B ",
      "parking_address_en": "Tai Wo Plaza, 12 Tai Wo Road, \nTai Po, New Territories",
      "parking_district_en": "Tai Po",
      "parking_name_sc": "太和B停車場",
      "parking_address_sc": "湾仔 告士打道",
      "parking_district_sc": "大埔区",
      "parking_name_tc": "太和B停車場",
      "parking_address_tc": "新界大埔太和路12號太和廣場",
      "parking_district_tc": "大埔區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.450499",
      "parking_longitude": "114.16076"
  },
  "tdc14p4322": {
      "park_id": "tdc14p4322",
      "parking_name_en": "Fu Shin Car Park ",
      "parking_address_en": "Fu Shin Shopping \nCentre, 12 On Po Road, Tai Po, New Territories",
      "parking_district_en": "Tai Po",
      "parking_name_sc": "富善停車場",
      "parking_address_sc": "新界大埔安埔路12号富善商场",
      "parking_district_sc": "大埔区",
      "parking_name_tc": "富善停車場",
      "parking_address_tc": "新界大埔安埔路12號富善商場",
      "parking_district_tc": "大埔區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.453548",
      "parking_longitude": "114.174143"
  },
  "tdc14p4327": {
      "park_id": "tdc14p4327",
      "parking_name_en": "Tai Yuen Car Park A ",
      "parking_address_en": "Tai Yuen Commercial Centre, 10 Ting Kok Road, Tai Po, New Territories",
      "parking_district_en": "Tai Po",
      "parking_name_sc": "大元A停車場",
      "parking_address_sc": "荃湾 德士古道",
      "parking_district_sc": "大埔区",
      "parking_name_tc": "大元A停車場",
      "parking_address_tc": "新界大埔汀角路10號大元商場",
      "parking_district_tc": "大埔區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.455049",
      "parking_longitude": "114.169807"
  },
  "tdc14p4328": {
      "park_id": "tdc14p4328",
      "parking_name_en": "Tai Yuen Car Park B",
      "parking_address_en": "Tai Yuen Commercial Centre, 10 Ting Kok Road, Tai Po, New Territories",
      "parking_district_en": "Tai Po",
      "parking_name_sc": "大元B停車場",
      "parking_address_sc": "葵青 青敬路",
      "parking_district_sc": "大埔区",
      "parking_name_tc": "大元B停車場",
      "parking_address_tc": "新界大埔汀角路10號大元商場",
      "parking_district_tc": "大埔區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.454338",
      "parking_longitude": "114.16771"
  },
  "tdc14p4330": {
      "park_id": "tdc14p4330",
      "parking_name_en": "Fu Heng Car Park A",
      "parking_address_en": "Fu Heng Shopping Centre, 6 Chung Nga Road, Tai Po, New Territories",
      "parking_district_en": "Tai Po",
      "parking_name_sc": "富亨停車場A",
      "parking_address_sc": "葵青 青绿街",
      "parking_district_sc": "大埔区",
      "parking_name_tc": "富亨停車場A",
      "parking_address_tc": "新界大埔頌雅路6號富亨商場",
      "parking_district_tc": "大埔區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.457943",
      "parking_longitude": "114.170917"
  },
  "tdc14p4332": {
      "park_id": "tdc14p4332",
      "parking_name_en": "Lok Fu UNY Car Park ",
      "parking_address_en": "Lok Fu Place, 198 Junction Road, Wang Tau Hom, Kowloon",
      "parking_district_en": "Wong Tai Sin",
      "parking_name_sc": "樂富生活創庫停車場",
      "parking_address_sc": "南区 数码港道",
      "parking_district_sc": "黄大仙区",
      "parking_name_tc": "樂富生活創庫停車場",
      "parking_address_tc": "九龍橫頭磡聯合道198號樂富廣場",
      "parking_district_tc": "黃大仙區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2788 3070",
      "parking_latitude": "22.338639",
      "parking_longitude": "114.185866"
  },
  "tdc14p4334": {
      "park_id": "tdc14p4334",
      "parking_name_en": "Lok Fu Market Car Park ",
      "parking_address_en": "Lok Fu Place, 198 Junction Road, Wang Tau Hom, Kowloon",
      "parking_district_en": "Wong Tai Sin",
      "parking_name_sc": "樂富街市停車場",
      "parking_address_sc": "南区 数码港道",
      "parking_district_sc": "黄大仙区",
      "parking_name_tc": "樂富街市停車場",
      "parking_address_tc": "九龍橫頭磡聯合道198號樂富廣場",
      "parking_district_tc": "黃大仙區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2788 3070",
      "parking_latitude": "22.339622",
      "parking_longitude": "114.187127"
  },
  "tdc14p4335": {
      "park_id": "tdc14p4335",
      "parking_name_en": "Lok Fu Zone B Car Park ",
      "parking_address_en": "Lok Fu Place, 198 Junction Road, \nWang Tau Hom, Kowloon",
      "parking_district_en": "Wong Tai Sin",
      "parking_name_sc": "樂富B區停車場",
      "parking_address_sc": "九龙城 必嘉街",
      "parking_district_sc": "黄大仙区",
      "parking_name_tc": "樂富B區停車場",
      "parking_address_tc": "九龍橫頭磡聯合道198號樂富廣場",
      "parking_district_tc": "黃大仙區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2788 3070",
      "parking_latitude": "22.337352",
      "parking_longitude": "114.187922"
  },
  "tdc14p4337": {
      "park_id": "tdc14p4337",
      "parking_name_en": "Tung Tau II  Car Park A",
      "parking_address_en": "Tung Tau \nEstate Retail and Car Park, 183 Tung Tau Tsuen Road, Wong Tai Sin, Kowloon",
      "parking_district_en": "Wong Tai Sin",
      "parking_name_sc": "東頭二(A)停車場",
      "parking_address_sc": "西贡 唐德街",
      "parking_district_sc": "黄大仙区",
      "parking_name_tc": "東頭二(A)停車場",
      "parking_address_tc": "九龍黃大仙東頭村道183號東頭邨商舖及停車場",
      "parking_district_tc": "黃大仙區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2788 3070",
      "parking_latitude": "22.335231",
      "parking_longitude": "114.192711"
  },
  "tdc14p6730": {
      "park_id": "tdc14p6730",
      "parking_name_en": "Fu Tung Car Park A ",
      "parking_address_en": "Fu Tung Plaza, 6 Fu Tung Street, Tung \nChung, New Territories",
      "parking_district_en": "Islands",
      "parking_name_sc": "富東A停車場",
      "parking_address_sc": "新界东涌富东街6号富东广场",
      "parking_district_sc": "离岛区",
      "parking_name_tc": "富東A停車場",
      "parking_address_tc": "新界東涌富東街6號富東廣場",
      "parking_district_tc": "離島區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.286571",
      "parking_longitude": "113.942254"
  },
  "tdc14p7542": {
      "park_id": "tdc14p7542",
      "parking_name_en": "Tsui Ping North Phase 1 Car Park ",
      "parking_address_en": "Tsui Ping North Shopping Circuit, 18 Tsui Ping Road, Kwun Tong, Kowloon",
      "parking_district_en": "Kwun Tong",
      "parking_name_sc": "翠屏北 1 號停車場",
      "parking_address_sc": "九龙观塘翠屏道18号翠屏(北)商场",
      "parking_district_sc": "观塘区",
      "parking_name_tc": "翠屏北 1 號停車場",
      "parking_address_tc": "九龍觀塘翠屏道18號翠屏(北)商場",
      "parking_district_tc": "觀塘區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.313284",
      "parking_longitude": "114.229644"
  },
  "tdc14p7622": {
      "park_id": "tdc14p7622",
      "parking_name_en": "Tsz Wan Shan Centre Car Park ",
      "parking_address_en": "Tsz Wan Shan Shopping Centre, 23 Yuk Wah Street, Tsz Wan Shan, Kowloon",
      "parking_district_en": "Wong Tai Sin",
      "parking_name_sc": "慈雲山中心停車場",
      "parking_address_sc": "九龙慈云山毓华街23号慈云山中心",
      "parking_district_sc": "黄大仙区",
      "parking_name_tc": "慈雲山中心停車場",
      "parking_address_tc": "九龍慈雲山毓華街23號慈雲山中心",
      "parking_district_tc": "黃大仙區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2788 3070",
      "parking_latitude": "22.347916",
      "parking_longitude": "114.200846"
  },
  "tdc14p7623": {
      "park_id": "tdc14p7623",
      "parking_name_en": "Tsz Oi Car Park",
      "parking_address_en": "Tsz Oi Court Retail and Car Park, 2 Wan Wah Street, Tsz Wan Shan, Kowloon",
      "parking_district_en": "Wong Tai Sin",
      "parking_name_sc": "慈愛停車場",
      "parking_address_sc": "九龙慈云山云华街2号慈爱苑商舖及停车场",
      "parking_district_sc": "黄大仙区",
      "parking_name_tc": "慈愛停車場",
      "parking_address_tc": "九龍慈雲山雲華街2號慈愛苑商舖及停車場",
      "parking_district_tc": "黃大仙區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2788 3070",
      "parking_latitude": "22.349275",
      "parking_longitude": "114.199577"
  },
  "tdc14p7629": {
      "park_id": "tdc14p7629",
      "parking_name_en": "Fung Tak Car Park ",
      "parking_address_en": "Fung Tak Shopping Centre, 111 Fung Tak Road, Diamond Hill, Kowloon",
      "parking_district_en": "Wong Tai Sin",
      "parking_name_sc": "鳳德停車場",
      "parking_address_sc": "九龙钻石山凤德道111号凤德商场",
      "parking_district_sc": "黄大仙区",
      "parking_name_tc": "鳳德停車場",
      "parking_address_tc": "九龍鑽石山鳳德道111號鳳德商場",
      "parking_district_tc": "黃大仙區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2788 3070",
      "parking_latitude": "22.3429501",
      "parking_longitude": "114.2008493"
  },
  "tdc14p7632": {
      "park_id": "tdc14p7632",
      "parking_name_en": "Tsz Lok Phase 1 Car Park ",
      "parking_address_en": "Tsz Wan Shan Shopping Centre, 23 \nYuk Wah Street, Tsz Wan Shan, Kowloon",
      "parking_district_en": "Wong Tai Sin",
      "parking_name_sc": "慈樂1期停車場",
      "parking_address_sc": "九龙慈云山毓华街23号慈云山中心",
      "parking_district_sc": "黄大仙区",
      "parking_name_tc": "慈樂1期停車場",
      "parking_address_tc": "九龍慈雲山毓華街23號慈雲山中心",
      "parking_district_tc": "黃大仙區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2788 3070",
      "parking_latitude": "22.348781",
      "parking_longitude": "114.19911"
  },
  "tdc14p7633": {
      "park_id": "tdc14p7633",
      "parking_name_en": "Tsz Lok Phase 2 Car Park ",
      "parking_address_en": "Tsz Wan Shan Shopping Centre, 23 Yuk Wah Street, Tsz Wan Shan, Kowloon",
      "parking_district_en": "Wong Tai Sin",
      "parking_name_sc": "慈樂2期停車場",
      "parking_address_sc": "九龙慈云山毓华街23号慈云山中心",
      "parking_district_sc": "黄大仙区",
      "parking_name_tc": "慈樂2期停車場",
      "parking_address_tc": "九龍慈雲山毓華街23號慈雲山中心",
      "parking_district_tc": "黃大仙區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2788 3070",
      "parking_latitude": "22.3469694",
      "parking_longitude": "114.1993685"
  },
  "tdc14p7636": {
      "park_id": "tdc14p7636",
      "parking_name_en": "Tsz Lok Phase 3 Car Park ",
      "parking_address_en": "Tsz Wan Shan Shopping Centre, 23 Yuk Wah Street, Tsz Wan Shan, Kowloon",
      "parking_district_en": "Wong Tai Sin",
      "parking_name_sc": "慈樂3期停車場",
      "parking_address_sc": "九龙慈云山毓华街23号慈云山中心",
      "parking_district_sc": "黄大仙区",
      "parking_name_tc": "慈樂3期停車場",
      "parking_address_tc": "九龍慈雲山毓華街23號慈雲山中心",
      "parking_district_tc": "黃大仙區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2788 3070",
      "parking_latitude": "22.346859",
      "parking_longitude": "114.199564"
  },
  "tdc14p7732": {
      "park_id": "tdc14p7732",
      "parking_name_en": "Choi Wan Car Park A",
      "parking_address_en": "Choi Wan Commercial Complex, 45 Clear Water Bay Road, Ngau Chi Wan, Kowloon",
      "parking_district_en": "Wong Tai Sin",
      "parking_name_sc": "彩雲A停車場",
      "parking_address_sc": "九龙牛池湾清水湾道45号彩云商场",
      "parking_district_sc": "黄大仙区",
      "parking_name_tc": "彩雲A停車場",
      "parking_address_tc": "九龍牛池灣清水灣道45號彩雲商場",
      "parking_district_tc": "黃大仙區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2788 3070",
      "parking_latitude": "22.332597",
      "parking_longitude": "114.214538"
  },
  "tdc14p7733": {
      "park_id": "tdc14p7733",
      "parking_name_en": "Choi Wan Car Park B & C",
      "parking_address_en": "Choi Wan Commercial Complex, 45 Clear Water Bay Road, Ngau Chi Wan, Kowloon",
      "parking_district_en": "Wong Tai Sin",
      "parking_name_sc": "彩雲B,C停車場",
      "parking_address_sc": "九龙牛池湾清水湾道45号彩云商场",
      "parking_district_sc": "黄大仙区",
      "parking_name_tc": "彩雲B,C停車場",
      "parking_address_tc": "九龍牛池灣清水灣道45號彩雲商場",
      "parking_district_tc": "黃大仙區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2788 3070",
      "parking_latitude": "22.334268",
      "parking_longitude": "114.21403"
  },
  "tdc14p7737": {
      "park_id": "tdc14p7737",
      "parking_name_en": "Shun Lee Car Park A ",
      "parking_address_en": "Shun Lee Commercial Centre, 15 Lee On \nRoad, Kwun Tong, Kowloon",
      "parking_district_en": "Kwun Tong",
      "parking_name_sc": "順利A停車場",
      "parking_address_sc": "九龙观塘利安道15号顺利商场",
      "parking_district_sc": "观塘区",
      "parking_name_tc": "順利A停車場",
      "parking_address_tc": "九龍觀塘利安道15號順利商場",
      "parking_district_tc": "觀塘區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.332927",
      "parking_longitude": "114.224524"
  },
  "tdc14p7739": {
      "park_id": "tdc14p7739",
      "parking_name_en": "Shun Lee Car Park B ",
      "parking_address_en": "Shun Lee \nCommercial Centre, 15 Lee On Road, Kwun Tong, Kowloon",
      "parking_district_en": "Kwun Tong",
      "parking_name_sc": "順利B停車場",
      "parking_address_sc": "九龙观塘利安道15号顺利商场",
      "parking_district_sc": "观塘区",
      "parking_name_tc": "順利B停車場",
      "parking_address_tc": "九龍觀塘利安道15號順利商場",
      "parking_district_tc": "觀塘區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.332332",
      "parking_longitude": "114.225282"
  },
  "tdc14p7740": {
      "park_id": "tdc14p7740",
      "parking_name_en": "Shun Lee Car Park  C",
      "parking_address_en": "Shun Lee Commercial Centre, 15 Lee On Road, Kwun Tong, Kowloon",
      "parking_district_en": "Kwun Tong",
      "parking_name_sc": "順利C停車場",
      "parking_address_sc": "九龙观塘利安道15号顺利商场",
      "parking_district_sc": "观塘区",
      "parking_name_tc": "順利C停車場",
      "parking_address_tc": "九龍觀塘利安道15號順利商場",
      "parking_district_tc": "觀塘區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.33008",
      "parking_longitude": "114.226715"
  },
  "tdc14p7742": {
      "park_id": "tdc14p7742",
      "parking_name_en": "Shun On Car Park ",
      "parking_address_en": "Shun On Commercial Centre, 1 Lee On Road, Kwun Tong, Kowloon",
      "parking_district_en": "Kwun Tong",
      "parking_name_sc": "順安停車場",
      "parking_address_sc": "九龙观塘利安道1号顺安商场",
      "parking_district_sc": "观塘区",
      "parking_name_tc": "順安停車場",
      "parking_address_tc": "九龍觀塘利安道1號順安商場",
      "parking_district_tc": "觀塘區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.328085",
      "parking_longitude": "114.226643"
  },
  "tdc14p8423": {
      "park_id": "tdc14p8423",
      "parking_name_en": "Oi Man Car Park ",
      "parking_address_en": "Oi Man Plaza, 60 Chung Hau Street, Ho Man Tin, Kowloon",
      "parking_district_en": "Kowloon City",
      "parking_name_sc": "愛民停車場",
      "parking_address_sc": "九龙何文田忠孝街60号爱民广场",
      "parking_district_sc": "九龙城区",
      "parking_name_tc": "愛民停車場",
      "parking_address_tc": "九龍何文田忠孝街60號愛民廣場",
      "parking_district_tc": "九龍城區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2788 3070",
      "parking_latitude": "22.3112762",
      "parking_longitude": "114.1792853"
  },
  "tdc14p8424": {
      "park_id": "tdc14p8424",
      "parking_name_en": "Ho Man Tin Car Park ",
      "parking_address_en": "Homantin Plaza, 80 Fat Kwong Street, Ho Man Tin, Kowloon",
      "parking_district_en": "Kowloon City",
      "parking_name_sc": "何文田停車場",
      "parking_address_sc": "九龙何文田佛光街80号何文田广场",
      "parking_district_sc": "九龙城区",
      "parking_name_tc": "何文田停車場",
      "parking_address_tc": "九龍何文田佛光街80號何文田廣場",
      "parking_district_tc": "九龍城區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2788 3070",
      "parking_latitude": "22.315026",
      "parking_longitude": "114.182131"
  },
  "tdc14p8426": {
      "park_id": "tdc14p8426",
      "parking_name_en": "Hung Hom Car Park ",
      "parking_address_en": "Hung Hom Estate Retail and Car Park, 9 Dyer Avenue, Hung Hom, Kowloon",
      "parking_district_en": "Kowloon City",
      "parking_name_sc": "紅磡停車場",
      "parking_address_sc": "九龙红磡戴亚街9号红磡邨商舖及停车场",
      "parking_district_sc": "九龙城区",
      "parking_name_tc": "紅磡停車場",
      "parking_address_tc": "九龍紅磡戴亞街9號紅磡邨商舖及停車場",
      "parking_district_tc": "九龍城區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2788 3070",
      "parking_latitude": "22.307175",
      "parking_longitude": "114.188498"
  },
  "tdc14p8428": {
      "park_id": "tdc14p8428",
      "parking_name_en": "Hoi Fu Car Park ",
      "parking_address_en": "Hoi Fu Shopping Centre, 2 Hoi Ting Road, Mong Kok, Kowloon",
      "parking_district_en": "Yau Tsim Mong",
      "parking_name_sc": "海富停車場",
      "parking_address_sc": "九龙旺角海庭路2号海富商场",
      "parking_district_sc": "油尖旺区",
      "parking_name_tc": "海富停車場",
      "parking_address_tc": "九龍旺角海庭路2號海富商場",
      "parking_district_tc": "油尖旺區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2788 3070",
      "parking_latitude": "22.31575",
      "parking_longitude": "114.165453"
  },
  "tdc14p8502": {
      "park_id": "tdc14p8502",
      "parking_name_en": "Butterfly Inner Car Park",
      "parking_address_en": "Butterfly Plaza, 1 Wu Chui Road, Tuen Mun, New Territories",
      "parking_district_en": "Tuen Mun",
      "parking_name_sc": "蝴蝶停車場",
      "parking_address_sc": "新界屯门湖翠路1号蝴蝶广场",
      "parking_district_sc": "屯门区",
      "parking_name_tc": "蝴蝶停車場",
      "parking_address_tc": "新界屯門湖翠路1號蝴蝶廣場",
      "parking_district_tc": "屯門區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.37566",
      "parking_longitude": "113.962962"
  },
  "tdcp9": {
      "park_id": "tdcp9",
      "parking_name_en": "Shau Kei Wan Car Park",
      "parking_address_en": "1 Po Man Street, Shau Kei Wan, Hong Kong",
      "parking_district_en": "Eastern",
      "parking_name_sc": "筲箕湾停车场",
      "parking_address_sc": "香港筲箕湾宝文街1号 ",
      "parking_district_sc": "东区",
      "parking_name_tc": "筲箕灣停車場",
      "parking_address_tc": "香港筲箕灣寶文街1號 ",
      "parking_district_tc": "東區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2569 1042",
      "parking_latitude": "22.27879407",
      "parking_longitude": "114.22927727"
  },
  "tdstt10": {
      "park_id": "tdstt10",
      "parking_name_en": "Sha Tau Kok Road",
      "parking_address_en": "Sha Tau Kok Road, Fanling, N.T.",
      "parking_district_en": "North",
      "parking_name_sc": "Sha Tau Kok Road",
      "parking_address_sc": "新界粉岭沙头角道",
      "parking_district_sc": "北区",
      "parking_name_tc": "沙頭角道",
      "parking_address_tc": "新界粉嶺沙頭角道",
      "parking_district_tc": "北區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "",
      "parking_latitude": "22.49414982",
      "parking_longitude": "114.139612634"
  },
  "tdstt11": {
      "park_id": "tdstt11",
      "parking_name_en": "On Chun Street",
      "parking_address_en": "On Chun Street, Ma On Shan, N.T.",
      "parking_district_en": "Sha Tin",
      "parking_name_sc": "On Chun Street",
      "parking_address_sc": "新界马鞍山鞍骏街",
      "parking_district_sc": "沙田区",
      "parking_name_tc": "鞍駿街",
      "parking_address_tc": "新界馬鞍山鞍駿街",
      "parking_district_tc": "沙田區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "",
      "parking_latitude": "22.42554166",
      "parking_longitude": "114.2267528"
  },
  "tdstt12": {
      "park_id": "tdstt12",
      "parking_name_en": "Ping Ha Road",
      "parking_address_en": "Ping Ha Road, Ha Tsuen, Yuen Long, New Territories",
      "parking_district_en": "Yuen Long",
      "parking_name_sc": "屏廈路",
      "parking_address_sc": "元朗厦村屏厦路停车场",
      "parking_district_sc": "元朗区",
      "parking_name_tc": "屏廈路",
      "parking_address_tc": "元朗廈村屏廈路停車場",
      "parking_district_tc": "元朗區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "",
      "parking_latitude": "22.445961651",
      "parking_longitude": "113.996367124"
  },
  "tdstt15": {
      "park_id": "tdstt15",
      "parking_name_en": "San On Street",
      "parking_address_en": "San On Street, Area 12, Tuen Mun, New Territories",
      "parking_district_en": "Tuen Mun",
      "parking_name_sc": "新安街",
      "parking_address_sc": "新界屯门第 12 区新安街",
      "parking_district_sc": "屯门区",
      "parking_name_tc": "新安街",
      "parking_address_tc": "新界屯門第 12 區新安街",
      "parking_district_tc": "屯門區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "(852) 2463 1761",
      "parking_latitude": "22.3934536",
      "parking_longitude": "113.97033691"
  },
  "tdstt16": {
      "park_id": "tdstt16",
      "parking_name_en": "Tsing Yin Street",
      "parking_address_en": "Tsing Yin Street, Area 10, Tuen Mun, New Territories",
      "parking_district_en": "Tuen Mun",
      "parking_name_sc": "青賢街",
      "parking_address_sc": " 新界屯门第10区青贤街",
      "parking_district_sc": "屯门区",
      "parking_name_tc": "青賢街",
      "parking_address_tc": " 新界屯門第10區青賢街",
      "parking_district_tc": "屯門區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "",
      "parking_latitude": "22.39818382",
      "parking_longitude": "113.97418213"
  },
  "tdstt17": {
      "park_id": "tdstt17",
      "parking_name_en": "Hoi Wah Road",
      "parking_address_en": "Hoi Wah Road, Tuen Mun",
      "parking_district_en": "Tuen Mun",
      "parking_name_sc": "海華路",
      "parking_address_sc": "屯门海华路",
      "parking_district_sc": "屯门区",
      "parking_name_tc": "海華路",
      "parking_address_tc": "屯門海華路",
      "parking_district_tc": "屯門區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "28303845",
      "parking_latitude": "22.37989616",
      "parking_longitude": "113.97042084"
  },
  "tdstt19": {
      "park_id": "tdstt19",
      "parking_name_en": "Junction of Hoi Wang Road and Lai Cheung Road",
      "parking_address_en": "Junction of Hoi Wang Road and Lai Cheung Road",
      "parking_district_en": "Yau Tsim Mong",
      "parking_name_sc": "海泓道麗翔道交界",
      "parking_address_sc": "海泓道丽翔道交界",
      "parking_district_sc": "油尖旺区",
      "parking_name_tc": "海泓道麗翔道交界",
      "parking_address_tc": "海泓道麗翔道交界",
      "parking_district_tc": "油尖旺區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "22119800",
      "parking_latitude": "22.31217384",
      "parking_longitude": "114.16407776"
  },
  "tdstt20": {
      "park_id": "tdstt20",
      "parking_name_en": "Chui Tong Road",
      "parking_address_en": "Chui Tong Road, Tui Min Hoi, Sai Kung, NT",
      "parking_district_en": "Sai Kung",
      "parking_name_sc": "翠塘路",
      "parking_address_sc": "新界西贡对面海翠塘路",
      "parking_district_sc": "西贡区",
      "parking_name_tc": "翠塘路",
      "parking_address_tc": "新界西貢對面海翠塘路",
      "parking_district_tc": "西貢區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "28158060",
      "parking_latitude": "22.37871742",
      "parking_longitude": "114.26834869"
  },
  "tdstt21": {
      "park_id": "tdstt21",
      "parking_name_en": "Tuen Yee Street",
      "parking_address_en": "Tuen Yee Street, Area 16, Tuen Mun, New Territories",
      "parking_district_en": "Tuen Mun",
      "parking_name_sc": "屯義街",
      "parking_address_sc": "新界屯門第 16 區屯義街",
      "parking_district_sc": "屯门区",
      "parking_name_tc": "屯義街",
      "parking_address_tc": "新界屯門第 16 區屯義街",
      "parking_district_tc": "屯門區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "96530394",
      "parking_latitude": "22.38224602",
      "parking_longitude": "113.9687347"
  },
  "tdstt22": {
      "park_id": "tdstt22",
      "parking_name_en": "Sheung Fung Street",
      "parking_address_en": "SHEUNG FUNG STREET, WONG TAI SIN",
      "parking_district_en": "Wong Tai Sin",
      "parking_name_sc": "雙鳳街",
      "parking_address_sc": "黄大仙 双凤街",
      "parking_district_sc": "黄大仙区",
      "parking_name_tc": "雙鳳街",
      "parking_address_tc": "黃大仙 雙鳳街",
      "parking_district_tc": "黃大仙區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "",
      "parking_latitude": "22.34658241",
      "parking_longitude": "114.1974411"
  },
  "tdstt23": {
      "park_id": "tdstt23",
      "parking_name_en": "On Pong Road",
      "parking_address_en": "On Pong Road, TAI PO",
      "parking_district_en": "Tai Po",
      "parking_name_sc": "安邦路",
      "parking_address_sc": "大埔 安邦路",
      "parking_district_sc": "大埔区",
      "parking_name_tc": "安邦路",
      "parking_address_tc": "大埔 安邦路",
      "parking_district_tc": "大埔區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "28062235",
      "parking_latitude": "22.453491947",
      "parking_longitude": "114.171640876"
  },
  "tdstt24": {
      "park_id": "tdstt24",
      "parking_name_en": "Wo Hing Road",
      "parking_address_en": "WO HING ROAD ,FANLING,NEW TERRITORIES",
      "parking_district_en": "North",
      "parking_name_sc": "和興路",
      "parking_address_sc": "和興路",
      "parking_district_sc": "北区",
      "parking_name_tc": "和興路",
      "parking_address_tc": "和興路",
      "parking_district_tc": "北區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "65881723",
      "parking_latitude": "22.485867345",
      "parking_longitude": "114.146615066"
  },
  "tdstt25": {
      "park_id": "tdstt25",
      "parking_name_en": "Wing Lai Road",
      "parking_address_en": "WING LAI ROAD, SAI KUNG",
      "parking_district_en": "Sai Kung",
      "parking_name_sc": "穎禮路",
      "parking_address_sc": "西贡 颖礼路",
      "parking_district_sc": "西贡区",
      "parking_name_tc": "穎禮路",
      "parking_address_tc": "西貢 穎禮路",
      "parking_district_tc": "西貢區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "28892867",
      "parking_latitude": "22.32606316",
      "parking_longitude": "114.2492065"
  },
  "tdstt26": {
      "park_id": "tdstt26",
      "parking_name_en": "Castle Peak Road - Castle Peak Bay",
      "parking_address_en": "Castle Peak Road – Castle Peak Bay, Area 41, Tuen Mun",
      "parking_district_en": "Tuen Mun",
      "parking_name_sc": "青山公路 – 青山灣段",
      "parking_address_sc": "新界屯門第 41 區青山公路–青山灣段",
      "parking_district_sc": "屯门区",
      "parking_name_tc": "青山公路 – 青山灣段",
      "parking_address_tc": "新界屯門第 41 區青山公路–青山灣段",
      "parking_district_tc": "屯門區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "22119800",
      "parking_latitude": "22.38314629",
      "parking_longitude": "113.97858429"
  },
  "tdstt27": {
      "park_id": "tdstt27",
      "parking_name_en": "Sam Shing Street 1",
      "parking_address_en": "Sam Shing Street, Area 27, Tuen Mun, N. T.",
      "parking_district_en": "Tuen Mun",
      "parking_name_sc": "三聖街 1",
      "parking_address_sc": "屯門三聖街",
      "parking_district_sc": "屯门区",
      "parking_name_tc": "三聖街 1",
      "parking_address_tc": "屯門三聖街",
      "parking_district_tc": "屯門區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "93090598",
      "parking_latitude": "22.38008308",
      "parking_longitude": "113.97774506"
  },
  "tdstt28": {
      "park_id": "tdstt28",
      "parking_name_en": "Sam Shing Street 4",
      "parking_address_en": "Sam Shing Street, Area 27, Tuen Mun, N. T.",
      "parking_district_en": "Tuen Mun",
      "parking_name_sc": "三聖街 4",
      "parking_address_sc": "屯門三聖街",
      "parking_district_sc": "屯门区",
      "parking_name_tc": "三聖街 4",
      "parking_address_tc": "屯門三聖街",
      "parking_district_tc": "屯門區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "93090598",
      "parking_latitude": "22.38125801",
      "parking_longitude": "113.97698212"
  },
  "tdstt2p1": {
      "park_id": "tdstt2p1",
      "parking_name_en": "Value Parking Wan Po Road",
      "parking_address_en": "Area 85 Wan Po Road, Tsueng Kwan O",
      "parking_district_en": "Sai Kung",
      "parking_name_sc": "Value Parking Wan Po Road",
      "parking_address_sc": "新界将军澳第 85 区环保大道",
      "parking_district_sc": "西贡区",
      "parking_name_tc": "威利停車場環保大道",
      "parking_address_tc": "新界將軍澳第 85 區環保大道",
      "parking_district_tc": "西貢區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "28062235",
      "parking_latitude": "22.29268473007",
      "parking_longitude": "114.27423511455"
  },
  "tdstt2p2": {
      "park_id": "tdstt2p2",
      "parking_name_en": "Value Parking Shan Mei Street",
      "parking_address_en": "Junction of Shan Mei Street & Sui Wo Road, Fo Tan",
      "parking_district_en": "Sha Tin",
      "parking_name_sc": "Value Parking Shan Mei Street",
      "parking_address_sc": "新界沙田火炭山尾街与穗禾路交界处",
      "parking_district_sc": "沙田区",
      "parking_name_tc": "威利停車場山尾街",
      "parking_address_tc": "新界沙田火炭山尾街與穗禾路交界處",
      "parking_district_tc": "沙田區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "28062235",
      "parking_latitude": "22.397891278542",
      "parking_longitude": "114.19250685444"
  },
  "tdstt3p1": {
      "park_id": "tdstt3p1",
      "parking_name_en": "Skye Parking Ho Tin Street",
      "parking_address_en": " Ho Tin Street, Area 9, Tuen Mun, N.T.",
      "parking_district_en": "Tuen Mun",
      "parking_name_sc": "绰德停车场河田街",
      "parking_address_sc": "大埔 马窝路",
      "parking_district_sc": "屯门区",
      "parking_name_tc": "綽德停車場河田街",
      "parking_address_tc": "新界屯門第 9 區河田街",
      "parking_district_tc": "屯門區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "",
      "parking_latitude": "22.3983",
      "parking_longitude": "113.9707"
  },
  "tdstt4p1": {
      "park_id": "tdstt4p1",
      "parking_name_en": "Ngau Pei Sha Street",
      "parking_address_en": "Ngau Pei Sha Street, Shatin, N.T.",
      "parking_district_en": "Sha Tin",
      "parking_name_sc": "Ngau Pei Sha Street",
      "parking_address_sc": "新界沙田牛皮沙街",
      "parking_district_sc": "沙田区",
      "parking_name_tc": "牛皮沙街",
      "parking_address_tc": "新界沙田牛皮沙街",
      "parking_district_tc": "沙田區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "27081737",
      "parking_latitude": "22.38232836",
      "parking_longitude": "114.2058283"
  },
  "tdstt5p1": {
      "park_id": "tdstt5p1",
      "parking_name_en": "Skye Parking Ma Wo Road",
      "parking_address_en": "Ma Wo Road, Area 6, Tai Po, N.T.",
      "parking_district_en": "Tai Po",
      "parking_name_sc": "绰德停车场马窝路",
      "parking_address_sc": "大埔 马窝路",
      "parking_district_sc": "大埔区",
      "parking_name_tc": "綽德停車場馬窩路",
      "parking_address_tc": "新界大埔第 6 區馬窩路",
      "parking_district_tc": "大埔區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "",
      "parking_latitude": "22.4421",
      "parking_longitude": "114.1635"
  },
  "tdc10p1": {
      "park_id": "tdc10p1",
      "parking_name_en": "Sai Kung Government Offices Car Park",
      "parking_address_en": "34 Chan Man Street, Sai Kung, N.T.",
      "parking_district_en": "Sai Kung",
      "parking_name_sc": "西贡政府合署停车场",
      "parking_address_sc": "新界 西贡区 亲民街34号",
      "parking_district_sc": "西贡区",
      "parking_name_tc": "西貢政府合署停車場",
      "parking_address_tc": "新界 西貢區 親民街34號",
      "parking_district_tc": "西貢區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "5282 9983",
      "parking_latitude": "22.382026",
      "parking_longitude": "114.272443"
  },
  "tdc10p2": {
      "park_id": "tdc10p2",
      "parking_name_en": "Shatin Government Offices",
      "parking_address_en": "1 Sheung Wo Che Road, Sha Tin, NT",
      "parking_district_en": "Sha Tin",
      "parking_name_sc": "沙田政府合署",
      "parking_address_sc": "沙田上禾輋路1号",
      "parking_district_sc": "沙田区",
      "parking_name_tc": "沙田政府合署",
      "parking_address_tc": "沙田上禾輋路1號",
      "parking_district_tc": "沙田區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "28156209, 28156931",
      "parking_latitude": "22.385732692",
      "parking_longitude": "114.186986002"
  },
  "tdc10p3": {
      "park_id": "tdc10p3",
      "parking_name_en": "Cheung Sha Wan Government Offices",
      "parking_address_en": "303 Cheung Sha Wan Road, Cheung Sha Wan",
      "parking_district_en": "Sham Shui Po",
      "parking_name_sc": "长沙湾政府合署",
      "parking_address_sc": "长沙湾道303号",
      "parking_district_sc": "深水埗区",
      "parking_name_tc": "長沙灣政府合署",
      "parking_address_tc": "長沙灣道303號",
      "parking_district_tc": "深水埗區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "28156209, 28156931",
      "parking_latitude": "22.331759072",
      "parking_longitude": "114.160364488"
  },
  "tdc10p4": {
      "park_id": "tdc10p4",
      "parking_name_en": "IMMIGRATION TOWER / REVENUE TOWER / WANCHAI TOWER",
      "parking_address_en": "12 Harbour Road, 7 Gloucester Road and 5 Gloucester Road, Wan Chai, HK",
      "parking_district_en": "Wan Chai",
      "parking_name_sc": "湾仔政府大楼、入境事务大楼及税务大楼",
      "parking_address_sc": "香港湾仔港湾道12号、告士打道7号及告士打道5号",
      "parking_district_sc": "湾仔区",
      "parking_name_tc": "灣仔政府大樓、入境事務大樓及稅務大樓",
      "parking_address_tc": "香港灣仔港灣道12號、告士打道7號及告士打道5號",
      "parking_district_tc": "灣仔區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2815 6931",
      "parking_latitude": "22.28018712",
      "parking_longitude": "114.17260722"
  },
  "tdc14p1004": {
      "park_id": "tdc14p1004",
      "parking_name_en": "Choi Ming Car Park  1 ",
      "parking_address_en": "Choi Ming Shopping Centre, 1 Choi Ming Street, Tiu Keng Leng, Tseung Kwan O, New \nTerritories",
      "parking_district_en": "Sai Kung",
      "parking_name_sc": "彩明1號停車場",
      "parking_address_sc": "新界将军澳调景岭彩明街1号彩明商场",
      "parking_district_sc": "西贡区",
      "parking_name_tc": "彩明1號停車場",
      "parking_address_tc": "新界將軍澳調景嶺彩明街1號彩明商場",
      "parking_district_tc": "西貢區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.30666",
      "parking_longitude": "114.252446"
  },
  "tdc14p1005": {
      "park_id": "tdc14p1005",
      "parking_name_en": "Choi Ming Car Park  2 ",
      "parking_address_en": "Choi Ming \nShopping Centre, 1 Choi Ming Street, Tiu Keng Leng, Tseung Kwan O, New Territories",
      "parking_district_en": "Sai Kung",
      "parking_name_sc": "彩明2號停車場",
      "parking_address_sc": "新界将军澳调景岭彩明街1号彩明商场",
      "parking_district_sc": "西贡区",
      "parking_name_tc": "彩明2號停車場",
      "parking_address_tc": "新界將軍澳調景嶺彩明街1號彩明商場",
      "parking_district_tc": "西貢區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.308972",
      "parking_longitude": "114.255308"
  },
  "tdc14p1006": {
      "park_id": "tdc14p1006",
      "parking_name_en": "Kin Ming Car Park A ",
      "parking_address_en": "Kin Ming Estate Car Park, 1 Choi Ming Street, Tiu Keng Leng, Tseung Kwan O, New Territories",
      "parking_district_en": "Sai Kung",
      "parking_name_sc": "健明A停車場",
      "parking_address_sc": "新界将军澳调景岭彩明街1号健明邨停车场",
      "parking_district_sc": "西贡区",
      "parking_name_tc": "健明A停車場",
      "parking_address_tc": "新界將軍澳調景嶺彩明街1號健明邨停車場",
      "parking_district_tc": "西貢區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.306028",
      "parking_longitude": "114.251108"
  },
  "tdc14p1007": {
      "park_id": "tdc14p1007",
      "parking_name_en": "Tong Ming Car Park ",
      "parking_address_en": "Tong Ming Court Retail and Car Park, 15 Tong Ming Street, Tseung Kwan O, New Territories",
      "parking_district_en": "Sai Kung",
      "parking_name_sc": "唐明停車場",
      "parking_address_sc": "新界将军澳唐明街15号唐明苑商舖及停车场",
      "parking_district_sc": "西贡区",
      "parking_name_tc": "唐明停車場",
      "parking_address_tc": "新界將軍澳唐明街15號唐明苑商舖及停車場",
      "parking_district_tc": "西貢區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.30821",
      "parking_longitude": "114.2561"
  },
  "tdc14p1008": {
      "park_id": "tdc14p1008",
      "parking_name_en": "Sheung Tak Car Park A ",
      "parking_address_en": "Sheung Tak Plaza, 2 Tong Ming \nStreet, Tseung Kwan O, New Territories",
      "parking_district_en": "Sai Kung",
      "parking_name_sc": "尚德A停車場",
      "parking_address_sc": "新界将军澳唐明街2号尚德广场",
      "parking_district_sc": "西贡区",
      "parking_name_tc": "尚德A停車場",
      "parking_address_tc": "新界將軍澳唐明街2號尚德廣場",
      "parking_district_tc": "西貢區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.31174",
      "parking_longitude": "114.26"
  },
  "tdc14p1012": {
      "park_id": "tdc14p1012",
      "parking_name_en": "Sheung Tak Car Park B ",
      "parking_address_en": "Sheung Tak Plaza, 2 Tong Ming Street, Tseung Kwan O, New Territories",
      "parking_district_en": "Sai Kung",
      "parking_name_sc": "尚德B停車場",
      "parking_address_sc": "新界将军澳唐明街2号尚德广场",
      "parking_district_sc": "西贡区",
      "parking_name_tc": "尚德B停車場",
      "parking_address_tc": "新界將軍澳唐明街2號尚德廣場",
      "parking_district_tc": "西貢區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.31074",
      "parking_longitude": "114.2579"
  },
  "tdc14p1015": {
      "park_id": "tdc14p1015",
      "parking_name_en": "Oi Tung Car Park ",
      "parking_address_en": "Oi Tung Shopping Centre, 18 Oi Yin Street, Shau Kei Wan, Hong Kong",
      "parking_district_en": "Eastern",
      "parking_name_sc": "愛東停車場",
      "parking_address_sc": "香港筲箕湾爱贤街18号爱东商场",
      "parking_district_sc": "东区",
      "parking_name_tc": "愛東停車場",
      "parking_address_tc": "香港筲箕灣愛賢街18號愛東商場",
      "parking_district_tc": "東區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.280511",
      "parking_longitude": "114.22719"
  },
  "tdc14p1016": {
      "park_id": "tdc14p1016",
      "parking_name_en": "Yiu Tung Car Park 1 ",
      "parking_address_en": "Yiu Tung Shopping Centre, 12 Yiu Hing Road, Shau Kei Wan, Hong Kong",
      "parking_district_en": "Eastern",
      "parking_name_sc": "耀東1停車場",
      "parking_address_sc": "香港筲箕湾耀兴道12号耀东商场",
      "parking_district_sc": "东区",
      "parking_name_tc": "耀東1停車場",
      "parking_address_tc": "香港筲箕灣耀興道12號耀東商場",
      "parking_district_tc": "東區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.2785116",
      "parking_longitude": "114.2227053"
  },
  "tdc14p1017": {
      "park_id": "tdc14p1017",
      "parking_name_en": "Hing Tung  Car Park ",
      "parking_address_en": "Hing Tung Shopping Centre, 55 Yiu Hing Road, Shau Kei Wan, Hong Kong",
      "parking_district_en": "Eastern",
      "parking_name_sc": "興東停車場",
      "parking_address_sc": "香港筲箕湾耀兴道55号兴东商场",
      "parking_district_sc": "东区",
      "parking_name_tc": "興東停車場",
      "parking_address_tc": "香港筲箕灣耀興道55號興東商場",
      "parking_district_tc": "東區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.2804899",
      "parking_longitude": "114.2200719"
  },
  "tdc14p1022": {
      "park_id": "tdc14p1022",
      "parking_name_en": "Lei Tung Car Park 2 ",
      "parking_address_en": "Lei Tung Commercial Centre, 5 Lei Tung Estate Road, Ap Lei Chau, Hong Kong",
      "parking_district_en": "Southern",
      "parking_name_sc": "利東2號停車場",
      "parking_address_sc": "香港鸭脷洲利东邨道5号利东商场",
      "parking_district_sc": "南区",
      "parking_name_tc": "利東2號停車場",
      "parking_address_tc": "香港鴨脷洲利東邨道5號利東商場",
      "parking_district_tc": "南區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.241694",
      "parking_longitude": "114.15523"
  },
  "tdc14p1024": {
      "park_id": "tdc14p1024",
      "parking_name_en": "Ap Lei Chau Car Park A ",
      "parking_address_en": "Ap Lei Chau Estate Retail and Car \nPark, 322 Ap Lei Chau Bridge Road, Ap Lei Chau, Hong Kong",
      "parking_district_en": "Southern",
      "parking_name_sc": "鴨脷洲A停車場",
      "parking_address_sc": "香港鸭脷洲鸭脷洲桥道322号鸭脷洲邨商舖及停车场",
      "parking_district_sc": "南区",
      "parking_name_tc": "鴨脷洲A停車場",
      "parking_address_tc": "香港鴨脷洲鴨脷洲橋道322號鴨脷洲邨商舖及停車場",
      "parking_district_tc": "南區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.244402",
      "parking_longitude": "114.151404"
  },
  "tdc14p1030": {
      "park_id": "tdc14p1030",
      "parking_name_en": "Siu Sai Wan Phase 3 Car Park ",
      "parking_address_en": "Siu Sai Wan Plaza, 10 Siu Sai Wan Road, Siu Sai Wan, Hong Kong",
      "parking_district_en": "Eastern",
      "parking_name_sc": "小西灣三期停車場",
      "parking_address_sc": "香港小西湾小西湾道10号小西湾广场",
      "parking_district_sc": "东区",
      "parking_name_tc": "小西灣三期停車場",
      "parking_address_tc": "香港小西灣小西灣道10號小西灣廣場",
      "parking_district_tc": "東區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.261",
      "parking_longitude": "114.251273"
  },
  "tdc14p1031": {
      "park_id": "tdc14p1031",
      "parking_name_en": "Siu Sai Wan Phase 1 Car Park ",
      "parking_address_en": "Siu Sai Wan Plaza, 10 Siu Sai Wan Road, Siu Sai Wan, Hong Kong",
      "parking_district_en": "Eastern",
      "parking_name_sc": "小西灣一期停車場",
      "parking_address_sc": "香港小西湾小西湾道10号小西湾广场",
      "parking_district_sc": "东区",
      "parking_name_tc": "小西灣一期停車場",
      "parking_address_tc": "香港小西灣小西灣道10號小西灣廣場",
      "parking_district_tc": "東區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.262853",
      "parking_longitude": "114.248621"
  },
  "tdc14p1032": {
      "park_id": "tdc14p1032",
      "parking_name_en": "Tsui Wan Car Park ",
      "parking_address_en": "Tsui Wan Estate Retail and Car Park, 3 Tsui Wan Street, Chai Wan, Hong Kong",
      "parking_district_en": "Eastern",
      "parking_name_sc": "翠灣停車場",
      "parking_address_sc": "香港柴湾翠湾街3号翠湾邨商舖及停车场",
      "parking_district_sc": "东区",
      "parking_name_tc": "翠灣停車場",
      "parking_address_tc": "香港柴灣翠灣街3號翠灣邨商舖及停車場",
      "parking_district_tc": "東區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.26733",
      "parking_longitude": "114.24"
  },
  "tdc14p1033": {
      "park_id": "tdc14p1033",
      "parking_name_en": "Wan Tsui Car Park ",
      "parking_address_en": "Wan Tsui Commercial Complex, 2 Wah \nHa Street, Chai Wan, Hong Kong",
      "parking_district_en": "Eastern",
      "parking_name_sc": "環翠停車場",
      "parking_address_sc": "香港柴湾华厦街2号环翠商场",
      "parking_district_sc": "东区",
      "parking_name_tc": "環翠停車場",
      "parking_address_tc": "香港柴灣華廈街2號環翠商場",
      "parking_district_tc": "東區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.261888",
      "parking_longitude": "114.236826"
  },
  "tdc14p1034": {
      "park_id": "tdc14p1034",
      "parking_name_en": "Hing Wah Car Park B ",
      "parking_address_en": "Hing Wah \nPlaza, 11 Wan Tsui Road, Chai Wan, Hong Kong",
      "parking_district_en": "Eastern",
      "parking_name_sc": "興華B停車場",
      "parking_address_sc": "香港柴湾环翠道11号兴华广场",
      "parking_district_sc": "东区",
      "parking_name_tc": "興華B停車場",
      "parking_address_tc": "香港柴灣環翠道11號興華廣場",
      "parking_district_tc": "東區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.26303",
      "parking_longitude": "114.2351"
  },
  "tdc14p1037": {
      "park_id": "tdc14p1037",
      "parking_name_en": "Yiu Tung Car Park 2 ",
      "parking_address_en": "Yiu Tung Shopping Centre, 12 Yiu Hing Road, Shau Kei Wan, Hong Kong",
      "parking_district_en": "Eastern",
      "parking_name_sc": "耀東2停車場",
      "parking_address_sc": "香港筲箕湾耀兴道12号耀东商场",
      "parking_district_sc": "东区",
      "parking_name_tc": "耀東2停車場",
      "parking_address_tc": "香港筲箕灣耀興道12號耀東商場",
      "parking_district_tc": "東區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.277303",
      "parking_longitude": "114.225057"
  },
  "tdc14p1050": {
      "park_id": "tdc14p1050",
      "parking_name_en": "Lei Tung Car Park 3",
      "parking_address_en": "Lei Tung Commercial Centre, 5 Lei Tung Estate Road, Ap Lei Chau, Hong Kong",
      "parking_district_en": "Southern",
      "parking_name_sc": "利東3號停車場",
      "parking_address_sc": "香港鸭脷洲利东邨道5号利东商场",
      "parking_district_sc": "南区",
      "parking_name_tc": "利東3號停車場",
      "parking_address_tc": "香港鴨脷洲利東邨道5號利東商場",
      "parking_district_tc": "南區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.2409782",
      "parking_longitude": "114.1571693"
  },
  "tdc14p2101": {
      "park_id": "tdc14p2101",
      "parking_name_en": "Chung On Car Park A ",
      "parking_address_en": "Chung On Shopping Centre, 632 Sai Sha Road, Ma On Shan, Sha Tin, New Territories",
      "parking_district_en": "Sha Tin",
      "parking_name_sc": "頌安A停車場",
      "parking_address_sc": "新界沙田马鞍山西沙路632号颂安商场",
      "parking_district_sc": "沙田区",
      "parking_name_tc": "頌安A停車場",
      "parking_address_tc": "新界沙田馬鞍山西沙路632號頌安商場",
      "parking_district_tc": "沙田區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.42166",
      "parking_longitude": "114.226755"
  },
  "tdc14p2104": {
      "park_id": "tdc14p2104",
      "parking_name_en": "Heng On Car Park ",
      "parking_address_en": "Heng On Commercial Centre, 1 Hang Kam Street, Ma On Shan, Sha Tin, New Territories",
      "parking_district_en": "Sha Tin",
      "parking_name_sc": "恆安停車場",
      "parking_address_sc": "新界沙田马鞍山恒锦街1号恒安商场",
      "parking_district_sc": "沙田区",
      "parking_name_tc": "恆安停車場",
      "parking_address_tc": "新界沙田馬鞍山恆錦街1號恆安商場",
      "parking_district_tc": "沙田區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.416621",
      "parking_longitude": "114.228137"
  },
  "tdc14p2106": {
      "park_id": "tdc14p2106",
      "parking_name_en": "Yiu On Car Park ",
      "parking_address_en": "Yiu On Shopping Centre, 2 Hang Hong \nStreet, Ma On Shan, Sha Tin, New Territories",
      "parking_district_en": "Sha Tin",
      "parking_name_sc": "耀安停車場",
      "parking_address_sc": "新界沙田马鞍山恒康街2号耀安商场",
      "parking_district_sc": "沙田区",
      "parking_name_tc": "耀安停車場",
      "parking_address_tc": "新界沙田馬鞍山恆康街2號耀安商場",
      "parking_district_tc": "沙田區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.420482",
      "parking_longitude": "114.230566"
  },
  "tdc14p2111": {
      "park_id": "tdc14p2111",
      "parking_name_en": "Wah Sum Car Park ",
      "parking_address_en": "Wah Sum Shopping Centre, 18 Yat Ming Road, Fanling, New Territories",
      "parking_district_en": "North",
      "parking_name_sc": "華心停車場",
      "parking_address_sc": "新界粉岭一鸣路18号华心商场",
      "parking_district_sc": "北区",
      "parking_name_tc": "華心停車場",
      "parking_address_tc": "新界粉嶺一鳴路18號華心商場",
      "parking_district_tc": "北區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.486642",
      "parking_longitude": "114.142864"
  },
  "tdc14p2112": {
      "park_id": "tdc14p2112",
      "parking_name_en": "Wah Ming Car Park ",
      "parking_address_en": "Wah Ming Shopping Centre, 21 Wah Ming Road, Fanling, New Territories",
      "parking_district_en": "North",
      "parking_name_sc": "華明停車場",
      "parking_address_sc": "新界粉岭华明路21号华明商场",
      "parking_district_sc": "北区",
      "parking_name_tc": "華明停車場",
      "parking_address_tc": "新界粉嶺華明路21號華明商場",
      "parking_district_tc": "北區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.483875",
      "parking_longitude": "114.140498"
  },
  "tdc14p2116": {
      "park_id": "tdc14p2116",
      "parking_name_en": "Cheung Wah - Cheung Yu Hs.",
      "parking_address_en": "Cheung Wah Shopping Centre, 38 San Wan Road, Fanling, New Territories",
      "parking_district_en": "North",
      "parking_name_sc": "祥華A場",
      "parking_address_sc": "新界粉岭新运路38号祥华商场",
      "parking_district_sc": "北区",
      "parking_name_tc": "祥華A場",
      "parking_address_tc": "新界粉嶺新運路38號祥華商場",
      "parking_district_tc": "北區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.493288",
      "parking_longitude": "114.142545"
  },
  "tdc14p2117": {
      "park_id": "tdc14p2117",
      "parking_name_en": "Cheung Wah - Cheung Chung Hs. Car Park ",
      "parking_address_en": "Cheung Wah Shopping Centre, 38 San Wan Road, Fanling, New Territories",
      "parking_district_en": "North",
      "parking_name_sc": "祥華B停車場",
      "parking_address_sc": "新界粉岭新运路38号祥华商场",
      "parking_district_sc": "北区",
      "parking_name_tc": "祥華B停車場",
      "parking_address_tc": "新界粉嶺新運路38號祥華商場",
      "parking_district_tc": "北區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.492991",
      "parking_longitude": "114.140185"
  },
  "tdc14p2118": {
      "park_id": "tdc14p2118",
      "parking_name_en": "Choi Yuen Car Park A ",
      "parking_address_en": "Choi Yuen Plaza, 8 Choi Yuen Road, \nSheung Shui, New Territories",
      "parking_district_en": "North",
      "parking_name_sc": "彩園A停車場",
      "parking_address_sc": "新界上水彩园路8号彩园广场",
      "parking_district_sc": "北区",
      "parking_name_tc": "彩園A停車場",
      "parking_address_tc": "新界上水彩園路8號彩園廣場",
      "parking_district_tc": "北區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.500856",
      "parking_longitude": "114.126642"
  },
  "tdc14p2119": {
      "park_id": "tdc14p2119",
      "parking_name_en": "Choi Yuen Car Park B",
      "parking_address_en": "Choi Yuen \nPlaza, 8 Choi Yuen Road, Sheung Shui, New Territories",
      "parking_district_en": "North",
      "parking_name_sc": "彩園B停車場",
      "parking_address_sc": "新界上水彩园路8号彩园广场",
      "parking_district_sc": "北区",
      "parking_name_tc": "彩園B停車場",
      "parking_address_tc": "新界上水彩園路8號彩園廣場",
      "parking_district_tc": "北區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.500256",
      "parking_longitude": "114.127127"
  },
  "tdc14p2120": {
      "park_id": "tdc14p2120",
      "parking_name_en": "Choi Yuen Car Park C",
      "parking_address_en": "Choi Yuen Plaza, 8 Choi Yuen Road, Sheung Shui, New Territories",
      "parking_district_en": "North",
      "parking_name_sc": "彩園C停車場",
      "parking_address_sc": "新界上水彩园路8号彩园广场",
      "parking_district_sc": "北区",
      "parking_name_tc": "彩園C停車場",
      "parking_address_tc": "新界上水彩園路8號彩園廣場",
      "parking_district_tc": "北區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.500147",
      "parking_longitude": "114.126765"
  },
  "tdc14p2121": {
      "park_id": "tdc14p2121",
      "parking_name_en": "Choi Yuen D",
      "parking_address_en": "Choi Yuen Plaza, 8 Choi Yuen Road, Sheung Shui, New Territories",
      "parking_district_en": "North",
      "parking_name_sc": "彩園D場",
      "parking_address_sc": "新界上水彩园路8号彩园广场",
      "parking_district_sc": "北区",
      "parking_name_tc": "彩園D場",
      "parking_address_tc": "新界上水彩園路8號彩園廣場",
      "parking_district_tc": "北區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.500501",
      "parking_longitude": "114.128343"
  },
  "tdc14p2123": {
      "park_id": "tdc14p2123",
      "parking_name_en": "Tai Ping Car Park ",
      "parking_address_en": "Tai Ping Estate Retail and Car Park, 8 Po Ping Road, Sheung Shui, New Territories",
      "parking_district_en": "North",
      "parking_name_sc": "太平停車場",
      "parking_address_sc": "新界上水保平路8号太平邨商舖及停车场",
      "parking_district_sc": "北区",
      "parking_name_tc": "太平停車場",
      "parking_address_tc": "新界上水保平路8號太平邨商舖及停車場",
      "parking_district_tc": "北區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.498175",
      "parking_longitude": "114.126304"
  },
  "tdc14p3201": {
      "park_id": "tdc14p3201",
      "parking_name_en": "Hin Keng Car Park ",
      "parking_address_en": "Hin Keng Shopping Centre, 69 Che Kung Miu Road, Tai Wai, Sha Tin, New Territories",
      "parking_district_en": "Sha Tin",
      "parking_name_sc": "顯徑停車場",
      "parking_address_sc": "新界沙田大围车公庙道69号显径商场",
      "parking_district_sc": "沙田区",
      "parking_name_tc": "顯徑停車場",
      "parking_address_tc": "新界沙田大圍車公廟道69號顯徑商場",
      "parking_district_tc": "沙田區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.364076",
      "parking_longitude": "114.172507"
  },
  "tdc14p3204": {
      "park_id": "tdc14p3204",
      "parking_name_en": "Lung Hang  A Car Park ",
      "parking_address_en": "Lung Hang Commercial Centre, 1 Tin Sam Street, Tai Wai, Sha Tin, New Territories",
      "parking_district_en": "Sha Tin",
      "parking_name_sc": "隆亨A停車場",
      "parking_address_sc": "新界沙田大围田心街1号隆亨商场",
      "parking_district_sc": "沙田区",
      "parking_name_tc": "隆亨A停車場",
      "parking_address_tc": "新界沙田大圍田心街1號隆亨商場",
      "parking_district_tc": "沙田區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.368096",
      "parking_longitude": "114.179012"
  },
  "tdc14p3206": {
      "park_id": "tdc14p3206",
      "parking_name_en": "Sun Chui Car Park ",
      "parking_address_en": "Sun Chui Shopping Centre, 2 Chui Tin \nStreet, Tai Wai, Sha Tin, New Territories",
      "parking_district_en": "Sha Tin",
      "parking_name_sc": "新翠停車場",
      "parking_address_sc": "新界沙田大围翠田街2号新翠商场",
      "parking_district_sc": "沙田区",
      "parking_name_tc": "新翠停車場",
      "parking_address_tc": "新界沙田大圍翠田街2號新翠商場",
      "parking_district_tc": "沙田區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.370813",
      "parking_longitude": "114.182751"
  },
  "tdc14p3214": {
      "park_id": "tdc14p3214",
      "parking_name_en": "Chun Shek Car Park ",
      "parking_address_en": "Chun Shek Shopping Centre, 1 Shing Tin Street, Tai Wai, Sha Tin, New Territories",
      "parking_district_en": "Sha Tin",
      "parking_name_sc": "秦石停車場",
      "parking_address_sc": "新界沙田大围盛田街1号秦石商场",
      "parking_district_sc": "沙田区",
      "parking_name_tc": "秦石停車場",
      "parking_address_tc": "新界沙田大圍盛田街1號秦石商場",
      "parking_district_tc": "沙田區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.3735655",
      "parking_longitude": "114.186299"
  },
  "tdc14p3216": {
      "park_id": "tdc14p3216",
      "parking_name_en": "Mei Lam Car Park ",
      "parking_address_en": "Mei Lam Commercial Centre, 30 Mei Tin Road, Tai Wai, Sha Tin, New Territories",
      "parking_district_en": "Sha Tin",
      "parking_name_sc": "美林停車場",
      "parking_address_sc": "新界沙田大围美田路30号美林商场",
      "parking_district_sc": "沙田区",
      "parking_name_tc": "美林停車場",
      "parking_address_tc": "新界沙田大圍美田路30號美林商場",
      "parking_district_tc": "沙田區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.37805",
      "parking_longitude": "114.175472"
  },
  "tdc14p3301": {
      "park_id": "tdc14p3301",
      "parking_name_en": "Tin Shui Car Park ",
      "parking_address_en": "Tin Shui Shopping Centre, 9 Tin Shui Road, Tin Shui Wai, Yuen Long, New Territories",
      "parking_district_en": "Yuen Long",
      "parking_name_sc": "天瑞停車場",
      "parking_address_sc": "新界元朗天水围天瑞路9号天瑞商场",
      "parking_district_sc": "元朗区",
      "parking_name_tc": "天瑞停車場",
      "parking_address_tc": "新界元朗天水圍天瑞路9號天瑞商場",
      "parking_district_tc": "元朗區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.456333",
      "parking_longitude": "113.998435"
  },
  "tdc14p3303": {
      "park_id": "tdc14p3303",
      "parking_name_en": "Tin Wah Car Park ",
      "parking_address_en": "Tin Wah Estate Retail and Car Park, 21 Tin Tan Street, Tin Shui Wai, Yuen Long, New \nTerritories",
      "parking_district_en": "Yuen Long",
      "parking_name_sc": "天華停車場",
      "parking_address_sc": "新界元朗天水围天坛街21号天华邨商舖及停车场",
      "parking_district_sc": "元朗区",
      "parking_name_tc": "天華停車場",
      "parking_address_tc": "新界元朗天水圍天壇街21號天華邨商舖及停車場",
      "parking_district_tc": "元朗區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.461156",
      "parking_longitude": "113.996398"
  },
  "tdc14p3304": {
      "park_id": "tdc14p3304",
      "parking_name_en": "T Town South Car Park",
      "parking_address_en": "T \nTown, 30 & 33 Tin Wah Road, Tin Shui Wai, Yuen Long, New Territories",
      "parking_district_en": "Yuen Long",
      "parking_name_sc": "T Town South 停車場",
      "parking_address_sc": "新界元朗天水围天华路30及33号T Town",
      "parking_district_sc": "元朗区",
      "parking_name_tc": "T Town South 停車場",
      "parking_address_tc": "新界元朗天水圍天華路30及33號T Town",
      "parking_district_tc": "元朗區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.461066",
      "parking_longitude": "113.99724"
  },
  "tdc14p3306": {
      "park_id": "tdc14p3306",
      "parking_name_en": "T Town North Car Park",
      "parking_address_en": "Tin Yuet Estate Car Park, 33 & 39 Tin Wah Road, Tin Shui Wai, Yuen Long, New Territories",
      "parking_district_en": "Yuen Long",
      "parking_name_sc": "T Town North  停車場",
      "parking_address_sc": "新界元朗天水围天华路33及39号天悦邨停车场",
      "parking_district_sc": "元朗区",
      "parking_name_tc": "T Town North  停車場",
      "parking_address_tc": "新界元朗天水圍天華路33及39號天悅邨停車場",
      "parking_district_tc": "元朗區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.462466",
      "parking_longitude": "113.998936"
  },
  "tdc14p3307": {
      "park_id": "tdc14p3307",
      "parking_name_en": "Tin Yuet Car Park",
      "parking_address_en": "Tin Yuet Estate Car Park, 33 & 39 Tin Wah Road, Tin Shui Wai, Yuen Long, New Territories",
      "parking_district_en": "Yuen Long",
      "parking_name_sc": "天悅停車場",
      "parking_address_sc": "新界元朗天水围天华路33及39号天悦邨停车场",
      "parking_district_sc": "元朗区",
      "parking_name_tc": "天悅停車場",
      "parking_address_tc": "新界元朗天水圍天華路33及39號天悅邨停車場",
      "parking_district_tc": "元朗區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.462589",
      "parking_longitude": "114.000443"
  },
  "tdc14p3309": {
      "park_id": "tdc14p3309",
      "parking_name_en": "Tin Chak Car Park A ",
      "parking_address_en": "Tin Chak Shopping Centre, \n71, 73, 75 & 77 Tin Shui Road, Tin Shui Wai, Yuen Long, New Territories",
      "parking_district_en": "Yuen Long",
      "parking_name_sc": "天澤A停車場",
      "parking_address_sc": "新界元朗天水围天瑞路71, 73, 75及77号天泽商场",
      "parking_district_sc": "元朗区",
      "parking_name_tc": "天澤A停車場",
      "parking_address_tc": "新界元朗天水圍天瑞路71, 73, 75及77號天澤商場",
      "parking_district_tc": "元朗區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.46684",
      "parking_longitude": "113.997449"
  },
  "tdc14p3311": {
      "park_id": "tdc14p3311",
      "parking_name_en": "Tin Yat Car Park ",
      "parking_address_en": "Tin Yat Estate Retail and Car Park, 76 Tin Shui Road, Tin Shui Wai, Yuen Long, New Territories",
      "parking_district_en": "Yuen Long",
      "parking_name_sc": "天逸停車場",
      "parking_address_sc": "新界元朗天水围天瑞路76号天逸邨商舖及停车场",
      "parking_district_sc": "元朗区",
      "parking_name_tc": "天逸停車場",
      "parking_address_tc": "新界元朗天水圍天瑞路76號天逸邨商舖及停車場",
      "parking_district_tc": "元朗區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.467847",
      "parking_longitude": "113.999802"
  },
  "tdc14p3431": {
      "park_id": "tdc14p3431",
      "parking_name_en": "Hong Pak Car Park ",
      "parking_address_en": "Hong Pak Court Retail and Car Park, 139 Pik Wan Road, Lam Tin, Kwun Tong, Kowloon",
      "parking_district_en": "Kwun Tong",
      "parking_name_sc": "康柏停車場",
      "parking_address_sc": "油尖旺 豉油街",
      "parking_district_sc": "观塘区",
      "parking_name_tc": "康柏停車場",
      "parking_address_tc": "九龍觀塘藍田碧雲道139號康栢苑商舖及停車場",
      "parking_district_tc": "觀塘區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.302487",
      "parking_longitude": "114.239156"
  },
  "tdc14p3432": {
      "park_id": "tdc14p3432",
      "parking_name_en": "Hong Shui Car Park ",
      "parking_address_en": "Hong Shui Court Retail and \nCar Park, 133 Pik Wan Road, Lam Tin, Kwun Tong, Kowloon",
      "parking_district_en": "Kwun Tong",
      "parking_name_sc": "康瑞停車場",
      "parking_address_sc": "九龙观塘蓝田碧云道133号康瑞苑商舖及停车场",
      "parking_district_sc": "观塘区",
      "parking_name_tc": "康瑞停車場",
      "parking_address_tc": "九龍觀塘藍田碧雲道133號康瑞苑商舖及停車場",
      "parking_district_tc": "觀塘區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.301579",
      "parking_longitude": "114.24035"
  },
  "tdc14p3433": {
      "park_id": "tdc14p3433",
      "parking_name_en": "Ko Chun Car Park ",
      "parking_address_en": "Ko Chun Court Retail and Car Park, 23 Ko Chiu Road, Yau Tong, Kwun Tong, Kowloon",
      "parking_district_en": "Kwun Tong",
      "parking_name_sc": "高俊停車場",
      "parking_address_sc": "东区 海湾街",
      "parking_district_sc": "观塘区",
      "parking_name_tc": "高俊停車場",
      "parking_address_tc": "九龍觀塘油塘高超道23號高俊苑商舖及停車場",
      "parking_district_tc": "觀塘區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.29806",
      "parking_longitude": "114.2419"
  },
  "tdc14p3434": {
      "park_id": "tdc14p3434",
      "parking_name_en": "Ko Yee Car Park ",
      "parking_address_en": "Ko Yee Estate Retail and Car Park, 28 Ko Chiu Road, Yau Tong, Kwun Tong, Kowloon",
      "parking_district_en": "Kwun Tong",
      "parking_name_sc": "高怡停車場",
      "parking_address_sc": "东区 康民街",
      "parking_district_sc": "观塘区",
      "parking_name_tc": "高怡停車場",
      "parking_address_tc": "九龍觀塘油塘高超道28號高怡邨商舖及停車場",
      "parking_district_tc": "觀塘區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.2964",
      "parking_longitude": "114.2416"
  },
  "tdc14p3435": {
      "park_id": "tdc14p3435",
      "parking_name_en": "Tak Tin Car Park  A ",
      "parking_address_en": "Tak Tin Plaza, 223 Pik Wan Road, Lam Tin, Kwun Tong, Kowloon",
      "parking_district_en": "Kwun Tong",
      "parking_name_sc": "德田A停車場",
      "parking_address_sc": "东区 康平街",
      "parking_district_sc": "观塘区",
      "parking_name_tc": "德田A停車場",
      "parking_address_tc": "九龍觀塘藍田碧雲道223號德田廣場",
      "parking_district_tc": "觀塘區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.30923",
      "parking_longitude": "114.2389"
  },
  "tdc14p4341": {
      "park_id": "tdc14p4341",
      "parking_name_en": "Wang Tau Hom Car Park  A ",
      "parking_address_en": "Wang Tau Hom (Wang Fai Centre), 17 Fu Mei Street, Wang Tau Hom, Kowloon",
      "parking_district_en": "Wong Tai Sin",
      "parking_name_sc": "橫頭磡A停車場",
      "parking_address_sc": "观塘 安德道",
      "parking_district_sc": "黄大仙区",
      "parking_name_tc": "橫頭磡A停車場",
      "parking_address_tc": "九龍橫頭磡富美街17號橫頭磡 (宏輝中心)",
      "parking_district_tc": "黃大仙區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2788 3070",
      "parking_latitude": "22.3415632",
      "parking_longitude": "114.1883192"
  },
  "tdc14p4352": {
      "park_id": "tdc14p4352",
      "parking_name_en": "Lok Fu Estate Car Park ",
      "parking_address_en": "Lok Fu Place, 198 Junction Road, Wang Tau Hom, Kowloon",
      "parking_district_en": "Wong Tai Sin",
      "parking_name_sc": "樂富邨停車場",
      "parking_address_sc": "元朗 加州花园大道",
      "parking_district_sc": "黄大仙区",
      "parking_name_tc": "樂富邨停車場",
      "parking_address_tc": "九龍橫頭磡聯合道198號樂富廣場",
      "parking_district_tc": "黃大仙區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2788 3070",
      "parking_latitude": "22.335421",
      "parking_longitude": "114.18698"
  },
  "tdc14p5128": {
      "park_id": "tdc14p5128",
      "parking_name_en": "TKO Gateway Car Park West",
      "parking_address_en": "TKO Gateway, 2 Sheung Ning Street, Tseung Kwan O, New Territories",
      "parking_district_en": "Sai Kung",
      "parking_name_sc": "TKO Gateway 西翼停車場",
      "parking_address_sc": "新界将军澳常宁路2号TKO Gateway",
      "parking_district_sc": "西贡区",
      "parking_name_tc": "TKO Gateway 西翼停車場",
      "parking_address_tc": "新界將軍澳常寧路2號TKO Gateway",
      "parking_district_tc": "西貢區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.317634",
      "parking_longitude": "114.264292"
  },
  "tdc14p5129": {
      "park_id": "tdc14p5129",
      "parking_name_en": "TKO Gateway Car Park East",
      "parking_address_en": "TKO Gateway, 2 Sheung \nNing Street, Tseung Kwan O, New Territories",
      "parking_district_en": "Sai Kung",
      "parking_name_sc": "TKO Gateway 東翼停車場",
      "parking_address_sc": "新界将军澳常宁路2号TKO Gateway",
      "parking_district_sc": "西贡区",
      "parking_name_tc": "TKO Gateway 東翼停車場",
      "parking_address_tc": "新界將軍澳常寧路2號TKO Gateway",
      "parking_district_tc": "西貢區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.317361",
      "parking_longitude": "114.266936"
  },
  "tdc14p5131": {
      "park_id": "tdc14p5131",
      "parking_name_en": "Ming Tak Car Park ",
      "parking_address_en": "Ming Tak Shopping Centre, 10 Pui Shing Lane, Tseung Kwan O, New Territories",
      "parking_district_en": "Sai Kung",
      "parking_name_sc": "明德停車場",
      "parking_address_sc": "新界将军澳培成里10号明德商场",
      "parking_district_sc": "西贡区",
      "parking_name_tc": "明德停車場",
      "parking_address_tc": "新界將軍澳培成里10號明德商場",
      "parking_district_tc": "西貢區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.316521",
      "parking_longitude": "114.268606"
  },
  "tdc14p5132": {
      "park_id": "tdc14p5132",
      "parking_name_en": "Wo Ming Car Park ",
      "parking_address_en": "Wo Ming Court Retail and Car Park, 8 Ngan O Road, Tseung Kwan O, New Territories",
      "parking_district_en": "Sai Kung",
      "parking_name_sc": "和明停車場",
      "parking_address_sc": "观塘 翠屏道",
      "parking_district_sc": "西贡区",
      "parking_name_tc": "和明停車場",
      "parking_address_tc": "新界將軍澳銀澳路8號和明苑商舖及停車場",
      "parking_district_tc": "西貢區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.315847",
      "parking_longitude": "114.266501"
  },
  "tdc14p5133": {
      "park_id": "tdc14p5133",
      "parking_name_en": "King Lam  Car Park ",
      "parking_address_en": "King Lam Shopping Centre, 38 Po Lam Road North, Tseung Kwan O, New Territories",
      "parking_district_en": "Sai Kung",
      "parking_name_sc": "景林停車場",
      "parking_address_sc": "新界将军澳宝琳北路38号景林商场",
      "parking_district_sc": "西贡区",
      "parking_name_tc": "景林停車場",
      "parking_address_tc": "新界將軍澳寶琳北路38號景林商場",
      "parking_district_tc": "西貢區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.321247",
      "parking_longitude": "114.26158"
  },
  "tdc14p5135": {
      "park_id": "tdc14p5135",
      "parking_name_en": "Yan Ming Court Car Park ",
      "parking_address_en": "Yan Ming Court Retail and Car \nPark, 100 Po Lam Road North, Tseung Kwan O, New Territories",
      "parking_district_en": "Sai Kung",
      "parking_name_sc": "欣明停車場",
      "parking_address_sc": "新界将军澳宝琳北路100号欣明苑商舖及停车场",
      "parking_district_sc": "西贡区",
      "parking_name_tc": "欣明停車場",
      "parking_address_tc": "新界將軍澳寶琳北路100號欣明苑商舖及停車場",
      "parking_district_tc": "西貢區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.324448",
      "parking_longitude": "114.259057"
  },
  "tdc14p5136": {
      "park_id": "tdc14p5136",
      "parking_name_en": "Ying Ming Court Car Park ",
      "parking_address_en": "Ying Ming Court Car Park, 20 Po Lam Road North, Tseung Kwan O, New Territories",
      "parking_district_en": "Sai Kung",
      "parking_name_sc": "英明停車場",
      "parking_address_sc": "新界将军澳宝琳北路20号英明苑停车场",
      "parking_district_sc": "西贡区",
      "parking_name_tc": "英明停車場",
      "parking_address_tc": "新界將軍澳寶琳北路20號英明苑停車場",
      "parking_district_tc": "西貢區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.325824",
      "parking_longitude": "114.25679"
  },
  "tdc14p5137": {
      "park_id": "tdc14p5137",
      "parking_name_en": "Po Lam Car Park ",
      "parking_address_en": "Po Lam Shopping Centre, 18 Po Lam Road North, Tseung Kwan O, New Territories",
      "parking_district_en": "Sai Kung",
      "parking_name_sc": "寶林停車場",
      "parking_address_sc": "新界将军澳宝琳北路18号宝林商场",
      "parking_district_sc": "西贡区",
      "parking_name_tc": "寶林停車場",
      "parking_address_tc": "新界將軍澳寶琳北路18號寶林商場",
      "parking_district_tc": "西貢區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.326221",
      "parking_longitude": "114.25467"
  },
  "tdc14p5411": {
      "park_id": "tdc14p5411",
      "parking_name_en": "Fortune Car Park ",
      "parking_address_en": "Fortune Shopping Centre, 8 Hang Cheung Street, Cheung Sha Wan, Kowloon",
      "parking_district_en": "Sham Shui Po",
      "parking_name_sc": "幸福停車場",
      "parking_address_sc": "九龙长沙湾幸祥街 8号幸福商场",
      "parking_district_sc": "深水埗区",
      "parking_name_tc": "幸福停車場",
      "parking_address_tc": "九龍長沙灣幸祥街 8號幸福商場",
      "parking_district_tc": "深水埗區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2788 3070",
      "parking_latitude": "22.3357272",
      "parking_longitude": "114.1545036"
  },
  "tdc14p5419": {
      "park_id": "tdc14p5419",
      "parking_name_en": "Fu Cheong Car Park A ",
      "parking_address_en": "Fu Cheong Shopping Centre, 19 Sai Chuen Road, Sham Shui Po, Kowloon",
      "parking_district_en": "Sham Shui Po",
      "parking_name_sc": "富昌A停車場",
      "parking_address_sc": "九龙深水埗西邨路19号富昌商场",
      "parking_district_sc": "深水埗区",
      "parking_name_tc": "富昌A停車場",
      "parking_address_tc": "九龍深水埗西邨路19號富昌商場",
      "parking_district_tc": "深水埗區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2788 3070",
      "parking_latitude": "22.329791",
      "parking_longitude": "114.1541881"
  },
  "tdc14p5421": {
      "park_id": "tdc14p5421",
      "parking_name_en": "Nam Cheong Car Park ",
      "parking_address_en": "Nam Cheong Estate Retail and Car Park, 3 \nCheong San Lane, Sham Shui Po, Kowloon",
      "parking_district_en": "Sham Shui Po",
      "parking_name_sc": "南昌停車場",
      "parking_address_sc": "九龙深水埗昌新里3号南昌邨商舖及停车场",
      "parking_district_sc": "深水埗区",
      "parking_name_tc": "南昌停車場",
      "parking_address_tc": "九龍深水埗昌新里3號南昌邨商舖及停車場",
      "parking_district_tc": "深水埗區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2788 3070",
      "parking_latitude": "22.326358",
      "parking_longitude": "114.157698"
  },
  "tdc14p5528": {
      "park_id": "tdc14p5528",
      "parking_name_en": "Chuk Yuen (S) Car Park",
      "parking_address_en": "Chuk Yuen Plaza, 15 Chuk Yuen Road, Wong Tai Sin, Kowloon",
      "parking_district_en": "Wong Tai Sin",
      "parking_name_sc": "竹園南停車場(多層)",
      "parking_address_sc": "九龙黄大仙竹园道15号竹园广场",
      "parking_district_sc": "黄大仙区",
      "parking_name_tc": "竹園南停車場(多層)",
      "parking_address_tc": "九龍黃大仙竹園道15號竹園廣場",
      "parking_district_tc": "黃大仙區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2788 3070",
      "parking_latitude": "22.3436818",
      "parking_longitude": "114.1938529"
  },
  "tdc14p5532": {
      "park_id": "tdc14p5532",
      "parking_name_en": "Temple Mall North Car Park",
      "parking_address_en": "Temple Mall North, 136 Lung Cheung Road, Wong Tai Sin, Kowloon",
      "parking_district_en": "Wong Tai Sin",
      "parking_name_sc": "黃大仙中心 北館 停車場",
      "parking_address_sc": "九龙黄大仙龙翔道136号黄大仙中心 北馆",
      "parking_district_sc": "黄大仙区",
      "parking_name_tc": "黃大仙中心 北館 停車場",
      "parking_address_tc": "九龍黃大仙龍翔道136號黃大仙中心 北館",
      "parking_district_tc": "黃大仙區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2788 3070",
      "parking_latitude": "22.342478",
      "parking_longitude": "114.190706"
  },
  "tdc14p5534": {
      "park_id": "tdc14p5534",
      "parking_name_en": "Temple Mall South Car Park A",
      "parking_address_en": "Temple Mall South, 103 Ching Tak Street, Wong Tai Sin, Kowloon",
      "parking_district_en": "Wong Tai Sin",
      "parking_name_sc": "黃大仙中心 南館 A停車場",
      "parking_address_sc": "九龙黄大仙正德街103号黄大仙中心 \n南馆",
      "parking_district_sc": "黄大仙区",
      "parking_name_tc": "黃大仙中心 南館 A停車場",
      "parking_address_tc": "九龍黃大仙正德街103號黃大仙中心 \n南館",
      "parking_district_tc": "黃大仙區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2788 3070",
      "parking_latitude": "22.341184",
      "parking_longitude": "114.195234"
  },
  "tdc14p5535": {
      "park_id": "tdc14p5535",
      "parking_name_en": "Temple Mall South Car Park B",
      "parking_address_en": "Temple Mall South, 103 Ching Tak Street, Wong \nTai Sin, Kowloon",
      "parking_district_en": "Wong Tai Sin",
      "parking_name_sc": "黃大仙中心 南館 B停車場",
      "parking_address_sc": "九龙黄大仙正德街103号黄大仙中心 南馆",
      "parking_district_sc": "黄大仙区",
      "parking_name_tc": "黃大仙中心 南館 B停車場",
      "parking_address_tc": "九龍黃大仙正德街103號黃大仙中心 南館",
      "parking_district_tc": "黃大仙區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2788 3070",
      "parking_latitude": "22.341282",
      "parking_longitude": "114.19403"
  },
  "tdc14p5727": {
      "park_id": "tdc14p5727",
      "parking_name_en": "Yin Lai Car Park ",
      "parking_address_en": "Yin Lai Court \nShopping Centre, 180 Lai King Hill Road, Kwai Chung, New Territories",
      "parking_district_en": "Kwai Tsing",
      "parking_name_sc": "賢麗停車場",
      "parking_address_sc": "新界葵涌荔景山路180号贤丽苑购物中心",
      "parking_district_sc": "葵青区",
      "parking_name_tc": "賢麗停車場",
      "parking_address_tc": "新界葵涌荔景山路180號賢麗苑購物中心",
      "parking_district_tc": "葵青區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.348928",
      "parking_longitude": "114.126325"
  },
  "tdc14p5904": {
      "park_id": "tdc14p5904",
      "parking_name_en": "Ying Fuk Car Park",
      "parking_address_en": "Ying Fuk Court Retail and Car Park, 17 Wing Chuk Street, Wong Tai Sin, Kowloon",
      "parking_district_en": "Wong Tai Sin",
      "parking_name_sc": "盈福停車場",
      "parking_address_sc": "九龙黄大仙颖竹街17号盈福苑商舖及停车场",
      "parking_district_sc": "黄大仙区",
      "parking_name_tc": "盈福停車場",
      "parking_address_tc": "九龍黃大仙穎竹街17號盈福苑商舖及停車場",
      "parking_district_tc": "黃大仙區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2788 3070",
      "parking_latitude": "22.346239",
      "parking_longitude": "114.195434"
  },
  "tdc14p6401": {
      "park_id": "tdc14p6401",
      "parking_name_en": "Stanley Plaza Car Park ",
      "parking_address_en": "Stanley Plaza, 23 Carmel Road, Stanley, Hong Kong",
      "parking_district_en": "Southern",
      "parking_name_sc": "赤柱廣場停車場",
      "parking_address_sc": "香港赤柱佳美道23号赤柱广场",
      "parking_district_sc": "南区",
      "parking_name_tc": "赤柱廣場停車場",
      "parking_address_tc": "香港赤柱佳美道23號赤柱廣場",
      "parking_district_tc": "南區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.22033",
      "parking_longitude": "114.2089"
  },
  "tdc14p6601": {
      "park_id": "tdc14p6601",
      "parking_name_en": "Sau Mau Ping SC Car Park ",
      "parking_address_en": "Sau Mau Ping Shopping Centre, 101 Sau Ming Road, Sau Mau Ping, Kwun Tong, Kowloon",
      "parking_district_en": "Kwun Tong",
      "parking_name_sc": "秀茂坪商場停車場",
      "parking_address_sc": "九龙观塘秀茂坪秀明道101号秀茂坪商场",
      "parking_district_sc": "观塘区",
      "parking_name_tc": "秀茂坪商場停車場",
      "parking_address_tc": "九龍觀塘秀茂坪秀明道101號秀茂坪商場",
      "parking_district_tc": "觀塘區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.320031",
      "parking_longitude": "114.232278"
  },
  "tdc14p6602": {
      "park_id": "tdc14p6602",
      "parking_name_en": "Sau Mau Ping Phase 15 Car Park ",
      "parking_address_en": "Sau Mau Ping \nShopping Centre, 101 Sau Ming Road, Sau Mau Ping, Kwun Tong, Kowloon",
      "parking_district_en": "Kwun Tong",
      "parking_name_sc": "秀茂坪15期停車場",
      "parking_address_sc": "九龙观塘秀茂坪秀明道101号秀茂坪商场",
      "parking_district_sc": "观塘区",
      "parking_name_tc": "秀茂坪15期停車場",
      "parking_address_tc": "九龍觀塘秀茂坪秀明道101號秀茂坪商場",
      "parking_district_tc": "觀塘區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.317339",
      "parking_longitude": "114.234743"
  },
  "tdc14p6604": {
      "park_id": "tdc14p6604",
      "parking_name_en": "Sau Mau Ping Phase 3 Car Park ",
      "parking_address_en": "Sau Mau Ping (III) Estate Retail and Car Park, Sau Ming Road, Sau Mau Ping, Kwun Tong, Kowloon",
      "parking_district_en": "Kwun Tong",
      "parking_name_sc": "秀茂坪3期停車場",
      "parking_address_sc": "九龙观塘秀茂坪秀明道秀茂坪邨第三期商舖及停车场",
      "parking_district_sc": "观塘区",
      "parking_name_tc": "秀茂坪3期停車場",
      "parking_address_tc": "九龍觀塘秀茂坪秀明道秀茂坪邨第三期商舖及停車場",
      "parking_district_tc": "觀塘區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.321743",
      "parking_longitude": "114.230534"
  },
  "tdc14p6605": {
      "park_id": "tdc14p6605",
      "parking_name_en": "Po Tat Car Park  A ",
      "parking_address_en": "Po Tat Shopping Centre, 2 Po Lam Road, Sau Mau Ping, Kwun Tong, Kowloon",
      "parking_district_en": "Kwun Tong",
      "parking_name_sc": "寶達 A停車場",
      "parking_address_sc": "九龙观塘秀茂坪宝琳路2号宝达商场",
      "parking_district_sc": "观塘区",
      "parking_name_tc": "寶達 A停車場",
      "parking_address_tc": "九龍觀塘秀茂坪寶琳路2號寶達商場",
      "parking_district_tc": "觀塘區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.317872",
      "parking_longitude": "114.236143"
  },
  "tdc14p6606": {
      "park_id": "tdc14p6606",
      "parking_name_en": "Po Tat Car Park B and C ",
      "parking_address_en": "Po Tat Shopping Centre, 2 \nPo Lam Road, Sau Mau Ping, Kwun Tong, Kowloon",
      "parking_district_en": "Kwun Tong",
      "parking_name_sc": "寶達 B & C停車場",
      "parking_address_sc": "九龙观塘秀茂坪宝琳路2号宝达商场",
      "parking_district_sc": "观塘区",
      "parking_name_tc": "寶達 B & C停車場",
      "parking_address_tc": "九龍觀塘秀茂坪寶琳路2號寶達商場",
      "parking_district_tc": "觀塘區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.316985",
      "parking_longitude": "114.238072"
  },
  "tdc14p6607": {
      "park_id": "tdc14p6607",
      "parking_name_en": "Hiu Lai Car Park ",
      "parking_address_en": "Hiu Lai Shopping Centre, 21 Hiu Kwong Street, Kwun Tong, Kowloon",
      "parking_district_en": "Kwun Tong",
      "parking_name_sc": "曉麗停車場",
      "parking_address_sc": "九龙观塘晓光街21号晓丽商场",
      "parking_district_sc": "观塘区",
      "parking_name_tc": "曉麗停車場",
      "parking_address_tc": "九龍觀塘曉光街21號曉麗商場",
      "parking_district_tc": "觀塘區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.320862",
      "parking_longitude": "114.229666"
  },
  "tdc14p6608": {
      "park_id": "tdc14p6608",
      "parking_name_en": "Cheung Fat  Car Park ",
      "parking_address_en": "Cheung Fat Plaza, 6 Tam Kon Shan Road, Tsing Yi, New Territories",
      "parking_district_en": "Kwai Tsing",
      "parking_name_sc": "長發停車場",
      "parking_address_sc": "新界青衣担杆山路6号长发广场",
      "parking_district_sc": "葵青区",
      "parking_name_tc": "長發停車場",
      "parking_address_tc": "新界青衣擔桿山路6號長發廣場",
      "parking_district_tc": "葵青區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.362637",
      "parking_longitude": "114.102933"
  },
  "tdc14p6610": {
      "park_id": "tdc14p6610",
      "parking_name_en": "Cheung On Phase 1 Car Park ",
      "parking_address_en": "Cheung On Estate Retail and Car Park, 1 Tam Kon Shan Road, Tsing Yi, New Territories",
      "parking_district_en": "Kwai Tsing",
      "parking_name_sc": "長安1期停車場",
      "parking_address_sc": "新界青衣担杆山路1号长安邨商舖及停车场",
      "parking_district_sc": "葵青区",
      "parking_name_tc": "長安1期停車場",
      "parking_address_tc": "新界青衣擔桿山路1號長安邨商舖及停車場",
      "parking_district_tc": "葵青區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.361",
      "parking_longitude": "114.101831"
  },
  "tdc14p6611": {
      "park_id": "tdc14p6611",
      "parking_name_en": "Cheung On Phase 2 Car Park ",
      "parking_address_en": "Cheung On Estate Retail and Car Park, 1 Tam Kon Shan Road, Tsing Yi, New \nTerritories",
      "parking_district_en": "Kwai Tsing",
      "parking_name_sc": "長安2期停車場",
      "parking_address_sc": "新界青衣担杆山路1号长安邨商舖及停车场",
      "parking_district_sc": "葵青区",
      "parking_name_tc": "長安2期停車場",
      "parking_address_tc": "新界青衣擔桿山路1號長安邨商舖及停車場",
      "parking_district_tc": "葵青區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.360484",
      "parking_longitude": "114.104416"
  },
  "tdc14p6620": {
      "park_id": "tdc14p6620",
      "parking_name_en": "Ching Wah Court Car Park ",
      "parking_address_en": "Ching Wah \nCourt Retail and Car Park, 22 Tsing Chin Street, Tsing Yi, New Territories",
      "parking_district_en": "Kwai Tsing",
      "parking_name_sc": "青華停車場",
      "parking_address_sc": "新界青衣青芊街22号青华苑商舖及停车场",
      "parking_district_sc": "葵青区",
      "parking_name_tc": "青華停車場",
      "parking_address_tc": "新界青衣青芊街22號青華苑商舖及停車場",
      "parking_district_tc": "葵青區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.349081",
      "parking_longitude": "114.099421"
  },
  "tdc14p6639": {
      "park_id": "tdc14p6639",
      "parking_name_en": "Lok Wah (S) MS Car Park",
      "parking_address_en": "Lok Wah (South) Estate Retail and Car Park, 70 Chun Wah Road, Ngau Tau Kwok, Kwun Tong, Kowloon",
      "parking_district_en": "Kwun Tong",
      "parking_name_sc": "樂華南-多層停車場",
      "parking_address_sc": "九龙观塘牛头角振华道70号乐华南邨商舖及停车场",
      "parking_district_sc": "观塘区",
      "parking_name_tc": "樂華南-多層停車場",
      "parking_address_tc": "九龍觀塘牛頭角振華道70號樂華南邨商舖及停車場",
      "parking_district_tc": "觀塘區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2788 3070",
      "parking_latitude": "22.320623",
      "parking_longitude": "114.220764"
  },
  "tdc14p6640": {
      "park_id": "tdc14p6640",
      "parking_name_en": "Lok Wah (N) CPA",
      "parking_address_en": "Lok Wah Commercial Centre, 70 Chun Wah Road, Ngau Tau Kwok, Kwun Tong, Kowloon",
      "parking_district_en": "Kwun Tong",
      "parking_name_sc": "樂華北A",
      "parking_address_sc": "九龙观塘牛头角振华道70号乐华商场",
      "parking_district_sc": "观塘区",
      "parking_name_tc": "樂華北A",
      "parking_address_tc": "九龍觀塘牛頭角振華道70號樂華商場",
      "parking_district_tc": "觀塘區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2788 3070",
      "parking_latitude": "22.321522",
      "parking_longitude": "114.220794"
  },
  "tdc14p6641": {
      "park_id": "tdc14p6641",
      "parking_name_en": "Lok Wah (N) B Car Park ",
      "parking_address_en": "Lok Wah Commercial Centre, 70 \nChun Wah Road, Ngau Tau Kwok, Kwun Tong, Kowloon",
      "parking_district_en": "Kwun Tong",
      "parking_name_sc": "樂華北B停車場",
      "parking_address_sc": "九龙观塘牛头角振华道70号乐华商场",
      "parking_district_sc": "观塘区",
      "parking_name_tc": "樂華北B停車場",
      "parking_address_tc": "九龍觀塘牛頭角振華道70號樂華商場",
      "parking_district_tc": "觀塘區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2788 3070",
      "parking_latitude": "22.32233",
      "parking_longitude": "114.220275"
  },
  "tdc14p6645": {
      "park_id": "tdc14p6645",
      "parking_name_en": "Cheung Wang Car Park ",
      "parking_address_en": "Cheung Wang Estate Retail and Car Park, 9 Liu To Road, Tsing Yi, New Territories",
      "parking_district_en": "Kwai Tsing",
      "parking_name_sc": "長宏停車場",
      "parking_address_sc": "新界青衣寮肚路9号长宏邨商舖及停车场",
      "parking_district_sc": "葵青区",
      "parking_name_tc": "長宏停車場",
      "parking_address_tc": "新界青衣寮肚路9號長宏邨商舖及停車場",
      "parking_district_tc": "葵青區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.356988",
      "parking_longitude": "114.095805"
  },
  "tdc14p6728": {
      "park_id": "tdc14p6728",
      "parking_name_en": "Yat Tung Car Park 2 & 3 (Sin Yat)  ",
      "parking_address_en": "Yat Tung Shopping Centre, 8 Yat Tung Street, Tung Chung, New Territories",
      "parking_district_en": "Islands",
      "parking_name_sc": "逸東2號及3號(善逸)停車場",
      "parking_address_sc": "新界东涌逸东街8号逸东商场",
      "parking_district_sc": "离岛区",
      "parking_name_tc": "逸東2號及3號(善逸)停車場",
      "parking_address_tc": "新界東涌逸東街8號逸東商場",
      "parking_district_tc": "離島區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.28116",
      "parking_longitude": "113.935776"
  },
  "tdc14p6729": {
      "park_id": "tdc14p6729",
      "parking_name_en": "Yat Tung Car Park 1 (Mei Yat)  ",
      "parking_address_en": "Yat Tung Shopping Centre, 8 Yat Tung Street, Tung Chung, New Territories",
      "parking_district_en": "Islands",
      "parking_name_sc": "逸東1號(美逸)停車場",
      "parking_address_sc": "新界东涌逸东街8号逸东商场",
      "parking_district_sc": "离岛区",
      "parking_name_tc": "逸東1號(美逸)停車場",
      "parking_address_tc": "新界東涌逸東街8號逸東商場",
      "parking_district_tc": "離島區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2830 3845",
      "parking_latitude": "22.281096",
      "parking_longitude": "113.938628"
  },
  "tdc14p8511": {
      "park_id": "tdc14p8511",
      "parking_name_en": "Sam Shing Car Park ",
      "parking_address_en": "Sam Shing Commercial Centre, 6 Sam Shing Street, Tuen Mun,  New Territories",
      "parking_district_en": "Tuen Mun",
      "parking_name_sc": "三聖停車場",
      "parking_address_sc": "新界屯门三圣街6号三圣商场",
      "parking_district_sc": "屯门区",
      "parking_name_tc": "三聖停車場",
      "parking_address_tc": "新界屯門三聖街6號三聖商場",
      "parking_district_tc": "屯門區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3471 2340",
      "parking_latitude": "22.380657",
      "parking_longitude": "113.978527"
  },
  "tdc14p8888": {
      "park_id": "tdc14p8888",
      "parking_name_en": "The Quayside Car Park",
      "parking_address_en": "The Quayside,77 Hoi Bun Road,Kwun Tong,Kowloon,Hong Kong\n",
      "parking_district_en": "Kwun Tong",
      "parking_name_sc": "海滨汇停车场",
      "parking_address_sc": "香港九龙观塘海滨道77号海滨汇",
      "parking_district_sc": "观塘区",
      "parking_name_tc": "海濱匯停車場",
      "parking_address_tc": "香港九龍觀塘海濱道77號海濱匯",
      "parking_district_tc": "觀塘區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3951 0838",
      "parking_latitude": "22.316532625",
      "parking_longitude": "114.213428223"
  },
  "tdc16p544": {
      "park_id": "tdc16p544",
      "parking_name_en": "Tuen Mun Government Office",
      "parking_address_en": "No. 1 Tuen Hi Road, Tuen Mun",
      "parking_district_en": "Tuen Mun",
      "parking_name_sc": "屯門政府合署",
      "parking_address_sc": "屯门屯喜路1号",
      "parking_district_sc": "屯门区",
      "parking_name_tc": "屯門政府合署",
      "parking_address_tc": "屯門屯喜路1號",
      "parking_district_tc": "屯門區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "96828850",
      "parking_latitude": "22.3899712106",
      "parking_longitude": "113.9763359174"
  },
  "tdc17p1": {
      "park_id": "tdc17p1",
      "parking_name_en": "WKCD Xiqu Centre Car Park",
      "parking_address_en": "88 Austin Road West, Tsim Sha Tsui, Kowloon ",
      "parking_district_en": "Yau Tsim Mong",
      "parking_name_sc": "西九文化区戏曲中心停车场",
      "parking_address_sc": "九龙尖沙咀柯士甸道西88号",
      "parking_district_sc": "油尖旺区",
      "parking_name_tc": "西九文化區戲曲中心停車場",
      "parking_address_tc": "九龍尖沙咀柯士甸道西88號",
      "parking_district_tc": "油尖旺區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "",
      "parking_latitude": "22.302356716",
      "parking_longitude": "114.167349602"
  },
  "tdc17p2": {
      "park_id": "tdc17p2",
      "parking_name_en": "Zone E Car Park",
      "parking_address_en": "West Kowloon Cultural District, No.36 Museum Drive, Kowloon, Hong Kong",
      "parking_district_en": "Yau Tsim Mong",
      "parking_name_sc": "E區停車場",
      "parking_address_sc": "香港九龙西九文化区博物馆路36号",
      "parking_district_sc": "油尖旺区",
      "parking_name_tc": "E區停車場",
      "parking_address_tc": "香港九龍西九文化區博物館路36號",
      "parking_district_tc": "油尖旺區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "52820288",
      "parking_latitude": "22.301372571",
      "parking_longitude": "114.158341115"
  },
  "tdc17p3": {
      "park_id": "tdc17p3",
      "parking_name_en": "PSO Car Park",
      "parking_address_en": "Project Site Office, WKCD, Kowloon, Hong Kong",
      "parking_district_en": "Yau Tsim Mong",
      "parking_name_sc": "項目辦公室停車場",
      "parking_address_sc": "香港九龙西九文化区项目办公室",
      "parking_district_sc": "油尖旺区",
      "parking_name_tc": "項目辦公室停車場",
      "parking_address_tc": "香港九龍西九文化區項目辦公室",
      "parking_district_tc": "油尖旺區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "",
      "parking_latitude": "22.302552693",
      "parking_longitude": "114.156506313"
  },
  "tdc1p1": {
      "park_id": "tdc1p1",
      "parking_name_en": "Lee Garden One Car Park",
      "parking_address_en": "33 HYSAN AVENUE, Wan Chai District, Hong Kong",
      "parking_district_en": "Wan Chai",
      "parking_name_sc": "利园一期停车场",
      "parking_address_sc": "香港 湾仔区 希慎道33号",
      "parking_district_sc": "湾仔区",
      "parking_name_tc": "利園一期停車場",
      "parking_address_tc": "香港 灣仔區 希慎道33號",
      "parking_district_tc": "灣仔區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "",
      "parking_latitude": "22.27859811",
      "parking_longitude": "114.18479255"
  },
  "tdc1p2": {
      "park_id": "tdc1p2",
      "parking_name_en": "Lee Garden Two Car Park",
      "parking_address_en": "28 YUN PING ROAD, Wan Chai District, Hong Kong",
      "parking_district_en": "Wan Chai",
      "parking_name_sc": "利园二期停车场",
      "parking_address_sc": "香港 湾仔区 恩平道28号",
      "parking_district_sc": "湾仔区",
      "parking_name_tc": "利園二期停車場",
      "parking_address_tc": "香港 灣仔區 恩平道28號",
      "parking_district_tc": "灣仔區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "",
      "parking_latitude": "22.27825194",
      "parking_longitude": "114.18594438"
  },
  "tdc1p3": {
      "park_id": "tdc1p3",
      "parking_name_en": "Leighton Car Park",
      "parking_address_en": "77 LEIGHTON ROAD, Wan Chai District, Hong Kong",
      "parking_district_en": "Wan Chai",
      "parking_name_sc": "礼顿中心停车场",
      "parking_address_sc": "香港 湾仔区 礼顿道77号",
      "parking_district_sc": "湾仔区",
      "parking_name_tc": "禮頓中心停車場",
      "parking_address_tc": "香港 灣仔區 禮頓道77號",
      "parking_district_tc": "灣仔區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "",
      "parking_latitude": "22.27776811",
      "parking_longitude": "114.18310035"
  },
  "tdc1p4": {
      "park_id": "tdc1p4",
      "parking_name_en": "Hysan Place Car Park",
      "parking_address_en": "500 Hennessy Road, Wan Chai District, Hong Kong",
      "parking_district_en": "Wan Chai",
      "parking_name_sc": "希慎广场停车场",
      "parking_address_sc": "沐宁街 九龙城",
      "parking_district_sc": "湾仔区",
      "parking_name_tc": "希慎廣場停車場",
      "parking_address_tc": "香港 灣仔區 軒尼詩道500號",
      "parking_district_tc": "灣仔區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "",
      "parking_latitude": "22.279907691",
      "parking_longitude": "114.183409552"
  },
  "tdc1p5": {
      "park_id": "tdc1p5",
      "parking_name_en": "Lee Garden Three Car Park",
      "parking_address_en": "1 Sunning Road, Causeway Bay, Hong Kong",
      "parking_district_en": "Wan Chai",
      "parking_name_sc": "利园三期停车场",
      "parking_address_sc": "香港 铜锣湾 新宁道1号",
      "parking_district_sc": "湾仔区",
      "parking_name_tc": "利園三期停車場",
      "parking_address_tc": "香港 銅鑼灣 新寧道1號",
      "parking_district_tc": "灣仔區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "",
      "parking_latitude": "22.277647169",
      "parking_longitude": "114.185359006"
  },
  "tdc20p1": {
      "park_id": "tdc20p1",
      "parking_name_en": "Hong Kong Wetland Park Car Park",
      "parking_address_en": "Wetland Park Road, Tin Shui Wai, NT",
      "parking_district_en": "Yuen Long",
      "parking_name_sc": "香港湿地公园停车场",
      "parking_address_sc": "新界 天水围 湿地公园路",
      "parking_district_sc": "元朗区",
      "parking_name_tc": "香港濕地公園停車場",
      "parking_address_tc": "新界 天水圍 濕地公園路",
      "parking_district_tc": "元朗區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3152 2666",
      "parking_latitude": "22.4692249",
      "parking_longitude": "114.0045853"
  },
  "tdc21p1": {
      "park_id": "tdc21p1",
      "parking_name_en": "Peak Galleria Car Park",
      "parking_address_en": "118 Peak Road, The Peak",
      "parking_district_en": "Central & Western",
      "parking_name_sc": "山頂廣場停車埸",
      "parking_address_sc": "山顶道118号",
      "parking_district_sc": "中西区",
      "parking_name_tc": "山頂廣場停車埸",
      "parking_address_tc": "山頂道118號",
      "parking_district_tc": "中西區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2849 8053",
      "parking_latitude": "22.2702541",
      "parking_longitude": "114.1498795"
  },
  "tdc22p1": {
      "park_id": "tdc22p1",
      "parking_name_en": "Hong Kong Disneyland Car Park",
      "parking_address_en": "Hong Kong Disneyland Resort, Lautau Island, Hong Kong",
      "parking_district_en": "Tsuen Wan",
      "parking_name_sc": "香港迪士尼樂園私家車停車場",
      "parking_address_sc": "香港大屿山香港迪士尼乐园度假区",
      "parking_district_sc": "荃湾区",
      "parking_name_tc": "香港迪士尼樂園私家車停車場",
      "parking_address_tc": "香港大嶼山香港迪士尼樂園度假區",
      "parking_district_tc": "荃灣區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3550-3388",
      "parking_latitude": "22.316644",
      "parking_longitude": "114.047746"
  },
  "tdc26p1": {
      "park_id": "tdc26p1",
      "parking_name_en": "No.1 Lei Yue Mun Path Public Carpark",
      "parking_address_en": "No.1 Lei Yue Mun Path, Yau Tong, Kowloon",
      "parking_district_en": "Kwun Tong",
      "parking_name_sc": "鯉魚門徑1號公眾停車場",
      "parking_address_sc": "九龙油塘鲤鱼门径1号",
      "parking_district_sc": "观塘区",
      "parking_name_tc": "鯉魚門徑1號公眾停車場",
      "parking_address_tc": "九龍油塘鯉魚門徑1號",
      "parking_district_tc": "觀塘區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2791 1860",
      "parking_latitude": "22.292694384",
      "parking_longitude": "114.238817154"
  },
  "tdc26p2": {
      "park_id": "tdc26p2",
      "parking_name_en": "Pioneer Centre Car Park",
      "parking_address_en": "NO.133 Sai Yeung Choi Street South",
      "parking_district_en": "Yau Tsim Mong",
      "parking_name_sc": "始創中心停車場",
      "parking_address_sc": "西洋菜南街133号",
      "parking_district_sc": "油尖旺区",
      "parking_name_tc": "始創中心停車場",
      "parking_address_tc": "西洋菜南街133號",
      "parking_district_tc": "油尖旺區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2399 8201",
      "parking_latitude": "22.32224464",
      "parking_longitude": "114.16905212"
  },
  "tdc27p1": {
      "park_id": "tdc27p1",
      "parking_name_en": "Kowloon Tsai Park Carpark",
      "parking_address_en": "13 Inverness Road, Kowloon City",
      "parking_district_en": "Kowloon City",
      "parking_name_sc": "九龍仔公園停車場",
      "parking_address_sc": "九龙城延文礼士道13号",
      "parking_district_sc": "九龙城区",
      "parking_name_tc": "九龍仔公園停車場",
      "parking_address_tc": "九龍城延文禮士道13號",
      "parking_district_tc": "九龍城區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "",
      "parking_latitude": "22.33398628",
      "parking_longitude": "114.18489838"
  },
  "tdc29p1": {
      "park_id": "tdc29p1",
      "parking_name_en": "Hong Kong Heritage Museum Carpark",
      "parking_address_en": "Hong Kong Heritage Museum Carpark,  Sha Tin, NT",
      "parking_district_en": "Sha Tin",
      "parking_name_sc": "香港文化博物館停車場",
      "parking_address_sc": "沙田香港文化博物館停車場",
      "parking_district_sc": "沙田区",
      "parking_name_tc": "香港文化博物館停車場",
      "parking_address_tc": "沙田香港文化博物館停車場",
      "parking_district_tc": "沙田區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "",
      "parking_latitude": "22.37736724",
      "parking_longitude": "114.1853745"
  },
  "tdc2p1": {
      "park_id": "tdc2p1",
      "parking_name_en": "Car Park 1 (Hourly)",
      "parking_address_en": " CHEONG SHUN ROAD, Islands District, New Territories",
      "parking_district_en": "Islands",
      "parking_name_sc": "一号停车埸(时租)",
      "parking_address_sc": "新界 离岛区 畅顺路号",
      "parking_district_sc": "离岛区",
      "parking_name_tc": "一號停車埸(時租)",
      "parking_address_tc": "新界 離島區 暢順路號",
      "parking_district_tc": "離島區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2183 4630",
      "parking_latitude": "22.31322343",
      "parking_longitude": "113.9366562"
  },
  "tdc2p10": {
      "park_id": "tdc2p10",
      "parking_name_en": "Airport Car Park 3 - Light Goods Vehicle (HOURLY)",
      "parking_address_en": "Sky Plaza Road Central, Hong Kong International Airport, Lantau",
      "parking_district_en": "Islands",
      "parking_name_sc": "机场三号停车场 - 轻型货车(时租)",
      "parking_address_sc": "大屿山香港国际机场翔天中路",
      "parking_district_sc": "离岛区",
      "parking_name_tc": "機場三號停車場 - 輕型貨車(時租)",
      "parking_address_tc": "大嶼山香港國際機場翔天中路",
      "parking_district_tc": "離島區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2183 4630",
      "parking_latitude": "22.314164",
      "parking_longitude": "113.9374"
  },
  "tdc2p11": {
      "park_id": "tdc2p11",
      "parking_name_en": "Airport Car Park 3 - Light Goods Vehicle (LONG-TER",
      "parking_address_en": "Sky Plaza Road Central, Hong Kong International Airport, Lantau",
      "parking_district_en": "Islands",
      "parking_name_sc": "机场三号停车场 - 轻型货车(长期停放)",
      "parking_address_sc": "新界将军澳培成里10号明德商场",
      "parking_district_sc": "离岛区",
      "parking_name_tc": "機場三號停車場 - 輕型貨車(長期停放)",
      "parking_address_tc": "大嶼山香港國際機場翔天中路",
      "parking_district_tc": "離島區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2183 4630",
      "parking_latitude": "22.31220107",
      "parking_longitude": "113.936833"
  },
  "tdc2p12": {
      "park_id": "tdc2p12",
      "parking_name_en": "Airport SkyCity Car Park (HOURLY)",
      "parking_address_en": "Airport Expo Boulevard, Hong Kong International Airport, Lantau",
      "parking_district_en": "Islands",
      "parking_name_sc": "機場航天城停車場 - (時租)",
      "parking_address_sc": "大屿山香港国际机场航展道",
      "parking_district_sc": "离岛区",
      "parking_name_tc": "機場航天城停車場 - (時租)",
      "parking_address_tc": "大嶼山香港國際機場航展道",
      "parking_district_tc": "離島區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2183 4360",
      "parking_latitude": "22.31883439",
      "parking_longitude": "113.9408393"
  },
  "tdc2p13": {
      "park_id": "tdc2p13",
      "parking_name_en": "Airport Car Park 5 (HOURLY)",
      "parking_address_en": "SkyCity Road, Hong Kong International Airport, Lantau",
      "parking_district_en": "Islands",
      "parking_name_sc": "機場五號停車場 - (時租)",
      "parking_address_sc": "大屿山香港国际机场航天城路",
      "parking_district_sc": "离岛区",
      "parking_name_tc": "機場五號停車場 - (時租)",
      "parking_address_tc": "大嶼山香港國際機場航天城路",
      "parking_district_tc": "離島區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2183 4360",
      "parking_latitude": "22.31515198",
      "parking_longitude": "113.9415748"
  },
  "tdc2p14": {
      "park_id": "tdc2p14",
      "parking_name_en": "Airport Car Park 5 (DAILY)",
      "parking_address_en": "SkyCity Road, Hong Kong International Airport, Lantau",
      "parking_district_en": "Islands",
      "parking_name_sc": "機場五號停車場 - (日租)",
      "parking_address_sc": "大屿山香港国际机场航天城路",
      "parking_district_sc": "离岛区",
      "parking_name_tc": "機場五號停車場 - (日租)",
      "parking_address_tc": "大嶼山香港國際機場航天城路",
      "parking_district_tc": "離島區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2183 4360",
      "parking_latitude": "22.3153816",
      "parking_longitude": "113.9413024"
  },
  "tdc2p15": {
      "park_id": "tdc2p15",
      "parking_name_en": "Airport SkyCity Car Park (DAILY)",
      "parking_address_en": "Airport Expo Boulevard, Hong Kong International Airport, Lantau",
      "parking_district_en": "Islands",
      "parking_name_sc": "機場航天城停車場 - (日租)",
      "parking_address_sc": "大屿山香港国际机场航展道",
      "parking_district_sc": "离岛区",
      "parking_name_tc": "機場航天城停車場 - (日租)",
      "parking_address_tc": "大嶼山香港國際機場航展道",
      "parking_district_tc": "離島區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2183 4360",
      "parking_latitude": "22.3188677",
      "parking_longitude": "113.9403075"
  },
  "tdc2p4": {
      "park_id": "tdc2p4",
      "parking_name_en": "Car Park 4 - Indoor Zone (Hourly)",
      "parking_address_en": "11 CHEONG TAT ROAD, Islands District, New Territories",
      "parking_district_en": "Islands",
      "parking_name_sc": "四号停车埸-室内区(时租)",
      "parking_address_sc": "新界 离岛区 畅达路11号",
      "parking_district_sc": "离岛区",
      "parking_name_tc": "四號停車埸-室內區(時租)",
      "parking_address_tc": "新界 離島區 暢達路11號",
      "parking_district_tc": "離島區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2183 4630",
      "parking_latitude": "22.31773139",
      "parking_longitude": "113.93404192"
  },
  "tdc2p5": {
      "park_id": "tdc2p5",
      "parking_name_en": "Car Park 4 - Indoor Zone (Long-term)",
      "parking_address_en": "11 CHEONG TAT ROAD, Islands District, New Territories",
      "parking_district_en": "Islands",
      "parking_name_sc": "四号停车埸-室内区(长期停放)",
      "parking_address_sc": "新界 离岛区 畅达路11号",
      "parking_district_sc": "离岛区",
      "parking_name_tc": "四號停車埸-室內區(長期停放)",
      "parking_address_tc": "新界 離島區 暢達路11號",
      "parking_district_tc": "離島區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2183 4630",
      "parking_latitude": "22.31844975",
      "parking_longitude": "113.93437125"
  },
  "tdc2p6": {
      "park_id": "tdc2p6",
      "parking_name_en": "Car Park 4 - Outdoor Zone (Hourly)",
      "parking_address_en": "11 CHEONG TAT ROAD, Islands District, New Territories",
      "parking_district_en": "Islands",
      "parking_name_sc": "四号停车埸-露天区(时租)",
      "parking_address_sc": "新界 离岛区 畅达路11号",
      "parking_district_sc": "离岛区",
      "parking_name_tc": "四號停車埸-露天區(時租)",
      "parking_address_tc": "新界 離島區 暢達路11號",
      "parking_district_tc": "離島區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2183 4630",
      "parking_latitude": "22.31821796",
      "parking_longitude": "113.93399697"
  },
  "tdc2p7": {
      "park_id": "tdc2p7",
      "parking_name_en": "Car Park 4 - Outdoor Zone (Long-term)",
      "parking_address_en": "11 CHEONG TAT ROAD, Islands District, New Territories",
      "parking_district_en": "Islands",
      "parking_name_sc": "四号停车埸-露天区(长期停放)",
      "parking_address_sc": "新界将军澳唐明街2号尚德广场",
      "parking_district_sc": "离岛区",
      "parking_name_tc": "四號停車埸-露天區(長期停放)",
      "parking_address_tc": "新界 離島區 暢達路11號",
      "parking_district_tc": "離島區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2183 4630",
      "parking_latitude": "22.31797036",
      "parking_longitude": "113.93412819"
  },
  "tdc2p8": {
      "park_id": "tdc2p8",
      "parking_name_en": "Airport Car Park 3 - Urban & Lantau Taxi (HOURLY)",
      "parking_address_en": "Sky Plaza Road Central, Hong Kong International Airport, Lantau",
      "parking_district_en": "Islands",
      "parking_name_sc": "机场三号停车场 - 市区及大屿山的士(时租)",
      "parking_address_sc": "新界将军澳常宁路2号TKO Gateway",
      "parking_district_sc": "离岛区",
      "parking_name_tc": "機場三號停車場 - 市區及大嶼山的士(時租)",
      "parking_address_tc": "大嶼山香港國際機場翔天中路",
      "parking_district_tc": "離島區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2183 4630",
      "parking_latitude": "22.31267043",
      "parking_longitude": "113.9367046"
  },
  "tdc2p9": {
      "park_id": "tdc2p9",
      "parking_name_en": "Airport Car Park 3 - Urban & Lantau Taxi (LONG-TER",
      "parking_address_en": "Sky Plaza Road Central, Hong Kong International Airport, Lantau",
      "parking_district_en": "Islands",
      "parking_name_sc": "机场三号停车场 - 市区及大屿山的士(长期停放)",
      "parking_address_sc": "新界将军澳常宁路2号TKO Gateway",
      "parking_district_sc": "离岛区",
      "parking_name_tc": "機場三號停車場 - 市區及大嶼山的士(長期停放)",
      "parking_address_tc": "大嶼山香港國際機場翔天中路",
      "parking_district_tc": "離島區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2183 4630",
      "parking_latitude": "22.31249673",
      "parking_longitude": "113.937091"
  },
  "tdc5p1": {
      "park_id": "tdc5p1",
      "parking_name_en": "Hopewell Centre ",
      "parking_address_en": "183 QUEEN'S ROAD EAST, Wan Chai District, Hong Kong",
      "parking_district_en": "Wan Chai",
      "parking_name_sc": "合和中心",
      "parking_address_sc": "香港 湾仔区 皇后大道东183号",
      "parking_district_sc": "湾仔区",
      "parking_name_tc": "合和中心",
      "parking_address_tc": "香港 灣仔區 皇后大道東183號",
      "parking_district_tc": "灣仔區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2527 7292",
      "parking_latitude": "22.2745918",
      "parking_longitude": "114.17167341"
  },
  "tdc5p3": {
      "park_id": "tdc5p3",
      "parking_name_en": "Wu Chung House",
      "parking_address_en": "213 Queen's Road East, Wan Chai District, Hong Kong",
      "parking_district_en": "Wan Chai",
      "parking_name_sc": "胡忠大厦",
      "parking_address_sc": "香港 湾仔区 皇后大道东213号",
      "parking_district_sc": "湾仔区",
      "parking_name_tc": "胡忠大廈",
      "parking_address_tc": "香港 灣仔區 皇后大道東213號",
      "parking_district_tc": "灣仔區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "2893 9369",
      "parking_latitude": "22.27437133",
      "parking_longitude": "114.17247315"
  },
  "tdc5p4": {
      "park_id": "tdc5p4",
      "parking_name_en": "Panda Place",
      "parking_address_en": "3 TSUEN WAH STREET, Kwai Tsing District, New Territories",
      "parking_district_en": "Tsuen Wan",
      "parking_name_sc": "悦来坊商场",
      "parking_address_sc": "新界 葵青区 荃华街3号",
      "parking_district_sc": "荃湾区",
      "parking_name_tc": "悅來坊商場",
      "parking_address_tc": "新界 葵青區 荃華街3號",
      "parking_district_tc": "荃灣區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "3114 0000",
      "parking_latitude": "22.36891824",
      "parking_longitude": "114.12042744"
  },
  "tdc6p10": {
      "park_id": "tdc6p10",
      "parking_name_en": "Cheung Ching Estate Carpark",
      "parking_address_en": "Cheung Ching Estate Carpark Tsing Yi, NT",
      "parking_district_en": "Kwai Tsing",
      "parking_name_sc": "長青邨停車場",
      "parking_address_sc": "新界青衣长青邨",
      "parking_district_sc": "葵青区",
      "parking_name_tc": "長青邨停車場",
      "parking_address_tc": "新界青衣長青邨",
      "parking_district_tc": "葵青區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "93870562",
      "parking_latitude": "22.34520075",
      "parking_longitude": "114.109138703"
  },
  "tdc6p11": {
      "park_id": "tdc6p11",
      "parking_name_en": "Cheung Shan Estate Carpark",
      "parking_address_en": "Cheung Shan Estate Carpark Tsuen Wan, NT",
      "parking_district_en": "Tsuen Wan",
      "parking_name_sc": "象山邨停車場",
      "parking_address_sc": "新界荃湾象山邨",
      "parking_district_sc": "荃湾区",
      "parking_name_tc": "象山邨停車場",
      "parking_address_tc": "新界荃灣象山邨",
      "parking_district_tc": "荃灣區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "98455563",
      "parking_latitude": "22.378625303",
      "parking_longitude": "114.130549011"
  },
  "tdc6p12": {
      "park_id": "tdc6p12",
      "parking_name_en": "Lung Poon Court Carpark",
      "parking_address_en": "Lung Poon Court Carpark Diamond Hill, Kln",
      "parking_district_en": "Wong Tai Sin",
      "parking_name_sc": "龍蟠苑停車場",
      "parking_address_sc": "九龙钻石山龙蟠苑",
      "parking_district_sc": "黄大仙区",
      "parking_name_tc": "龍蟠苑停車場",
      "parking_address_tc": "九龍鑽石山龍蟠苑",
      "parking_district_tc": "黃大仙區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": "28303845",
      "parking_latitude": "22.341731028",
      "parking_longitude": "114.20028889"
  },
  "tdc6p13": {
      "park_id": "tdc6p13",
      "parking_name_en": "Kwai Chung Ph. 3 & 4",
      "parking_address_en": "TAI WO HAU ROAD, KWAI TSING",
      "parking_district_en": "Kwai Tsing",
      "parking_name_sc": "葵涌邨（第三及四期）",
      "parking_address_sc": "葵青 大窝口道",
      "parking_district_sc": "葵青区",
      "parking_name_tc": "葵涌邨（第三及四期）",
      "parking_address_tc": "葵青 大窩口道",
      "parking_district_tc": "葵青區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": null,
      "parking_latitude": "22.3679047",
      "parking_longitude": "114.1286087"
  },
  "tdc6p14": {
      "park_id": "tdc6p14",
      "parking_name_en": "Choi Hung Estate",
      "parking_address_en": "CHOI HUNG ROAD, WONG TAI SIN",
      "parking_district_en": "Wong Tai Sin",
      "parking_name_sc": "彩虹邨",
      "parking_address_sc": "黄大仙 彩虹道",
      "parking_district_sc": "黄大仙区",
      "parking_name_tc": "彩虹邨",
      "parking_address_tc": "黃大仙 彩虹道",
      "parking_district_tc": "黃大仙區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": null,
      "parking_latitude": "22.3346329",
      "parking_longitude": "114.2072296"
  },
  "tdc6p15": {
      "park_id": "tdc6p15",
      "parking_name_en": "Lei Yue Mun Estate",
      "parking_address_en": "YAN WING STREET, KWUN TONG",
      "parking_district_en": "Kwun Tong",
      "parking_name_sc": "鯉魚門邨",
      "parking_address_sc": "观塘 欣荣街",
      "parking_district_sc": "观塘区",
      "parking_name_tc": "鯉魚門邨",
      "parking_address_tc": "觀塘 欣榮街",
      "parking_district_tc": "觀塘區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": null,
      "parking_latitude": "22.2946053",
      "parking_longitude": "114.2401428"
  },
  "tdc6p16": {
      "park_id": "tdc6p16",
      "parking_name_en": "Lei Muk Shue Shopping Centre",
      "parking_address_en": "NGONG HOM ROAD, TSUEN WAN",
      "parking_district_en": "Tsuen Wan",
      "parking_name_sc": "梨木樹商場",
      "parking_address_sc": "荃湾 昂磡路",
      "parking_district_sc": "荃湾区",
      "parking_name_tc": "梨木樹商場",
      "parking_address_tc": "荃灣 昂磡路",
      "parking_district_tc": "荃灣區",
      "parking_starttime": null,
      "parking_endtime": null,
      "parking_contactNo": null,
      "parking_latitude": "22.378336",
      "parking_longitude": "114.1361771"
  }
}


// export const ParkingTable = () => {

function ParkingTable(){
    const [vData, setVData] = useState(null);
    useEffect(() => {
        axios.get(`https://resource.data.one.gov.hk/td/carpark/vacancy_all.json`)
            .then(response => setVData(response.data))
    }, []);

    return(
        <>
            {vData ? <ParkingView result={carparkdata} vData = {vData} /> :null}
        </>
    )
}

function ParkingView(data) {
    let result = data.result
    let vData = data.vData
    return (
        <>
        <div style={{ paddingTop: '25px', paddingBottom: '25px' }}>
            {typeof result !== 'undefined' ? <SortableTable data={carparkdata} vData={vData} /> : <NotFound />}
        </div>
        </>
    )
}

function NotFound() {
    return (
        <div
        style={{
            padding: "100px",
            margin: "auto",
            alignItems: "center",
            height: "75vh",
            // overflow: "auto",
        }}
        >
        <Loader active inline='centered' > Loading</Loader>
        </div>
    )
}

const initialState = {
    data: {},
    optionsDist: [],
    optionsArea: [],
    filterKEYWORD: "",
    filterInd: "",
    activePage: "1",
    totalPage: "4",
    itemType: '0',
    lastUpdate: "",
};
  
const reducer = combineReducers({
    resultTable: resultTableReducer,
});

function resultTableReducer(state = initialState, action) {
    switch (action.type) {
        case 'Filter_KEYWORD': {
            const string = action.text
            return {
                ...state, filterKEYWORD: string
            }
        }
        case 'ADD_MESSAGE': {
            return {
                ...state, data: action.state
            }
        }
        case 'ADD_OPTIONS': {
            return {
                ...state, options: action.state
            }
        }
        case 'ADD_LASTUPDATE':{
            return {
                ...state, lastUpdate: action.state
            }
        }
        default: {
            return state;
        }
    }
}

const store = createStore(reducer);


class SortableTable extends React.Component {
    constructor(props) {
      super(props);
      this.setData();
    }
  
    setData() {
      var data1 = this.props.data
      store.dispatch({
        type: 'ADD_MESSAGE',
        state: data1,
      });
    }
  
    componentDidMount() {
      store.subscribe(() => this.forceUpdate());
    }
  
    render() {
        const state = store.getState();
        const {data} = state.resultTable
        const vData = this.props.vData.car_park
        const iData = _.values(data)
        console.log(vData)
        console.log(iData)
        const r = iData.filter(({ park_id: idv }) => vData.every(({ park_id: idc }) => idv !== idc));
        console.log(r)
        const newArr = vData.concat(r).map((v) => v.position ? v : { ...v, position: null });
        console.log('hi')
        console.log(newArr)
    //   const { data, filterText, activePage, itemType, filterDate, lastUpdate} = state.resultTable
    //   let newData = data
    //   let newDatagp = []
    //   let aDate_ts = _.map(data,'t_date_ts')

  
    //   const nPerPage = 25
    //   const totalPage = Math.ceil(newData.length / nPerPage)
    //   newData = _.slice(newData, (activePage - 1) * nPerPage, activePage * nPerPage)
  
      return (
        <Container style={{ widht:'100%', maxWidth: '950px', margin: "auto", alignItems: "center" }}>
            {
                newArr.map(({park_id, parking_name_tc}, index) => (
                    <>
                        {park_id} / {parking_name_tc} /<br/>
                    </>
                ))
            }

        </Container>
      )
    }
  }
  



export default ParkingTable;

