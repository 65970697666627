import React, { useEffect } from "react";
import { Loader } from "semantic-ui-react";
import {
  selectAllTopic,
  fetchTopic,
  toIdle,
} from "../../../features/topic/topicSlice";
import { useSelector, useDispatch } from "react-redux";
import {
  // useIsLoggedIn,
  useUserFavourites,
  useAllowedBehindPaywall,
} from "features/user/userSlice";
import RSTableBySectorContainer from "./RSTableBySectorContainer";

export const RSTableBySector = (props) => {
  const { industry } = props;
  // console.log(industry);
  const allowedBehindPaywall = useAllowedBehindPaywall();
  const wishList = useUserFavourites();
  const dispatch = useDispatch();
  const topics = useSelector(selectAllTopic);
  const topicStatus = useSelector((state) => state.topic.status);
  const error = useSelector((state) => state.topic.error);
  useEffect(() => {
    if (topicStatus === "idle") {
      if (
        topics.find((item) => item.topicInfo.name === "rstable2") === undefined
      ) {
        dispatch(fetchTopic("rstable2"));
      }
      if (
        topics.find((item) => item.topicInfo.name === "stockSectorInfo") ===
        undefined
      ) {
        dispatch(fetchTopic("stockSectorInfo"));
      }
    }
  }, [topicStatus, topics, dispatch]);

  let content;
  if (topicStatus === "loading") {
    content = (
      <div>
        <section className="posts-list">
          <div
            style={{
              padding: "100px",
              margin: "auto",
              alignItems: "center",
              height: "75vh",
            }}
          >
            <Loader active inline="centered">
              {" "}
              Loading
            </Loader>
          </div>
        </section>
      </div>
    );
  } else if (topicStatus === "succeeded") {
    const data = topics.find((item) => item.topicInfo.name === "rstable2");
    const stockSector = topics.find(
      (item) => item.topicInfo.name === "stockSectorInfo"
    );
    if (data && stockSector) {
      content = (
        <RSTableBySectorContainer
          data={data}
          stockSector={stockSector}
          wishList={wishList}
          allowedBehindPaywall={allowedBehindPaywall}
          industry={industry}
        />
      );
    } else {
      dispatch(toIdle({}));
    }
  } else if (topicStatus === "error") {
    content = (
      <div>
        <section className="posts-list">
          <div>{error}</div>
        </section>
      </div>
    );
  }
  return <>{content}</>;
};

export default RSTableBySector;
