import React from "react";
import { Button, Dropdown, Icon, Modal } from "semantic-ui-react";
import {
  addFavourite,
  removeFavourite,
  setUserFavourites,
  useAllowedBehindPaywall,
  useUser,
  useUserFavourites,
} from "features/user/userSlice";
import { useDispatch } from "react-redux";
import { FireBaseLoginModal } from "components/auth/FirebaseLoginForm";
import { SubScriptionPlansModal } from "./SubscriptionWidget";
import source from "mock_source"

const maxFav = 20;

/**
 * Create a Map of fav buttons from stock codes.
 * @param {Array} stock_codes - Array of stock codes.
 * @returns {Map} Map of stock code to its corresponding favourite button.
 */
function FavBtns(stock_codes) {
  const dispatch = useDispatch()
  const user = useUser()
  const allowedBehindPaywall = useAllowedBehindPaywall()
  const favourites = useUserFavourites()
  const isFavourite = (stock_code) => favourites? favourites.includes(stock_code): false

  const toggleFavourite = async (stock_code) => {
    if (!user) { return; }

    if (isFavourite(stock_code)) {
      let fav = favourites.filter((value) => (value !== stock_code))
      if (!allowedBehindPaywall && fav.length > maxFav) { return }
      await removeFavourite(user.uid, [stock_code])
      dispatch(setUserFavourites(fav))
    } else {
      let fav = favourites ? [...favourites, stock_code] : [stock_code]
      if (!allowedBehindPaywall && fav.length > maxFav) { return }
      await addFavourite(user.uid, [stock_code])
      dispatch(setUserFavourites(fav))
    }
  };

  function createFavBtn(stock_code) {
    const onClick = (e) => {
      e.stopPropagation(); // stop event from propagating element outside fav button
      toggleFavourite(stock_code)
    }
    if (!user) {
      return <FireBaseLoginModal trigger={<Icon name="star outline" />} prompt="請先登入" />
    } else if (isFavourite(stock_code)) {
      return <Icon name="star" style={{ color: '#FFCA28' }} onClick={onClick} />
    } else if (!allowedBehindPaywall && favourites && favourites.length >= maxFav) {
      let prompt = "最愛股票數目已達上限，請訂閱付費計劃"
      return <SubScriptionPlansModal trigger={<Icon name="star outline" />} prompt={prompt} />
    } else {
      return <Icon name="star outline" onClick={onClick} />
    }
  }

  let favbtns = new Map()
  stock_codes.forEach((stock_code) => favbtns.set(stock_code, createFavBtn(stock_code)));
  return favbtns
}

/**
 * Create a fav button from a stock code.
 * @param {string} stock_code.
 * @returns {JSX.Element} Favourite button.
 */
function FavBtn(stock_code) {
  return FavBtns([stock_code]).get(stock_code)
}

function AddFavModal() {
  const [open, setOpen] = React.useState(false)
  const [codes, setCodes] = React.useState([])
  const dispatch = useDispatch()
  const user = useUser()
  const allowedBehindPaywall = useAllowedBehindPaywall()
  const favourites = useUserFavourites()

  const trigger = <Button icon="plus square outline" content="新增最愛股票" />

  if (!user) {
    return <FireBaseLoginModal trigger={trigger} prompt="請先登入" />
  }

  const options = source.map((stock) => ({ key: stock.no, text: `${stock.no} ${stock.name_zh} ${stock.name_en}`, value: stock.no }))
  const onChange = (e, { value }) => { setCodes(value) }

  let newfavs = new Set(favourites.concat(codes))
  const onConfirm = async () => {
    if (!allowedBehindPaywall && newfavs.size > maxFav) { return }
    await addFavourite(user.uid, codes)
    dispatch(setUserFavourites(Array.from(newfavs)))
    setCodes([])
    setOpen(false)
  }

  let confirmBtn;
  if (!allowedBehindPaywall && newfavs.size > maxFav) {
    confirmBtn = <SubScriptionPlansModal trigger={<Button content="確定" />} prompt={"最愛股票數目已達上限"} />
  } else {
    confirmBtn = <Button onClick={onConfirm} content="確定" />
  }

  const onCancel = () => {
    setCodes([])
    setOpen(false)
  }

  return (
    <Modal
      centered={false}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={trigger}
      closeOnDimmerClick={false}
      dimmer="inverted"
    >
      <Modal.Header>新增最愛股票</Modal.Header>
      <Modal.Content>
        <Dropdown
          placeholder='輸入股份代號或名稱'
          fluid multiple search selection
          options={options}
          onChange={onChange}
          value={codes}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCancel}>取消</Button>
        {confirmBtn}
      </Modal.Actions>
    </Modal>
  )
}

export { AddFavModal, FavBtns, FavBtn }
