import { useParams } from "react-router-dom"
import StockStat from "../views/StockStat"
import MarketStat from "../views/MarketStat"
import { Container } from "@material-ui/core"

const StockStatPage = ({ match }) => {
  const { stockId } = useParams()
  return (
    <Container>
      <MarketStat />
      <StockStat />
    </Container>
  )
}

export default StockStatPage;