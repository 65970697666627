import React, { useEffect } from "react";
import "./StockChart.css";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { Loader } from "semantic-ui-react";
import { selectAllRS, fetchRS, toIdle } from "../../features/rs/rsSlice";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import dataModule from "highcharts/modules/data";
import exportingModule from "highcharts/modules/exporting";
import indicators from "highcharts/indicators/indicators";
import pivotpoints from "highcharts/indicators/pivot-points";

dataModule(Highcharts);
exportingModule(Highcharts);
indicators(Highcharts);
pivotpoints(Highcharts);

function roundData3(number) {
  return Math.round(number * 1000) / 1000;
}

function roundData1(number) {
  return Math.round(number * 10) / 10;
}

const fontSizePct = isMobile ? "85%" : "100%";

function zip(arrays) {
  return Array.apply(null, Array(arrays[0].length)).map(function (_, i) {
    return arrays.map(function (array) {
      return array[i];
    });
  });
}

function zipVol(arrays) {
  var volume = [];
  var i = 0;
  var dateLength = arrays[0].length;
  for (i; i < dateLength; i += 1) {
    volume.push({
      x: arrays[0][i], // the date
      y: arrays[1][i], // the volume
      color: volumeBarColor(arrays[2][i], arrays[3][i]),
    });
  }
  return volume;
}

export const RSChart = ({ id }) => {
  const stockId = ("0000" + id).slice(-5);
  const dispatch = useDispatch();
  const rs = useSelector(selectAllRS);
  const rsStatus = useSelector((state) => state.rs.status);
  const error = useSelector((state) => state.rs.error);
  // console.log(rs);
  // console.log(rsStatus);
  // console.log(error);
  useEffect(() => {
    if (rsStatus === "idle") {
      if (true) {
        dispatch(fetchRS(stockId));
      }
    }
  }, [stockId, rsStatus, dispatch]);
  // console.log("first");

  let content;

  if (rsStatus === "loading") {
    content = (
      <div>
        <section className="posts-list">
          <div
            style={{
              padding: "100px",
              margin: "auto",
              alignItems: "center",
              height: "75vh",
              // overflow: "auto",
            }}
          >
            <Loader active inline="centered">
              {" "}
              Loading
            </Loader>
          </div>
        </section>
      </div>
    );
  } else if (rsStatus === "succeeded") {
    const data = rs.find((item) => item.topicInfo.stockCode === stockId);
    if (data) {
      // content = <>hello</>;
      content = <RSChartView data={data} />;
    } else {
      dispatch(toIdle({}));
    }
  } else if (rsStatus === "error") {
    content = (
      <div>
        <section className="posts-list">
          <div>{error}</div>
        </section>
      </div>
    );
  }
  return <>{content}</>;
};

function volumeBarColor(open, close) {
  if (open < close) return "Green";
  if (open > close) return "red";
  return "grey";
}

function RSChartView(props) {
  const topicInfo = props.data.topicInfo;
  const stockStat = props.data;
  // console.log(stockStat);
  var windowWidth = window.innerWidth;
  // console.log(windowWidth);
  // console.log("this widnowWidth in highchart");
  // const chartHeight =
  //   0.656 * windowWidth < 550
  //     ? 550
  //     : 0.656 * windowWidth > 633
  //     ? 633
  //     : 0.656 * windowWidth;
  const chartHeight =
    0.656 * windowWidth < 600
      ? 600
      : 0.656 * windowWidth > 675
      ? 675
      : 0.656 * windowWidth;

  let data = [];
  let datalength = 0;
  let startDate = new Date();
  startDate.setFullYear(startDate.getFullYear() - 1);
  var groupingUnits = [
    [
      "day", // unit name
      [1], // allowed multiples
    ],
    ["week", [1]],
  ];

  if (stockStat.data) {
    let dates = stockStat.data["date"].map((item) => new Date(item).getTime());
    let index = 0;
    for (index; index < dates.length && dates[index] < startDate; index++);
    datalength = dates.length;
    console.log(dates);
    data.push({
      yAxis: 0,
      type: "ohlc",
      name: topicInfo.stockName,
      useOhlcData: true,
      data: zip([
        dates,
        stockStat.data["open"],
        stockStat.data["high"],
        stockStat.data["low"],
        stockStat.data["close"],
      ]),
    });
  }

  const options = {
    chart: {
      height: chartHeight,
      // plotBackgroundImage: `${process.env.PUBLIC_URL}/images/watermark.svg`
    },

    rangeSelector: {
      selected: isMobile ? 1 : 2,
      dropdown: "never",
    },

    legend: {
      enabled: true,
      align: "center",
      /* layout: 'vertical', */
      verticalAlign: "bottom",
      x: 0,
      y: 0,
    },

    plotOptions: {
      candlestick: {
        lineColor: "red",
        color: "white",
        upLineColor: "black",
        upColor: "Green",
      },
      series: {
        showInLegend: true,
      },
    },

    navigator: {
      series: {
        lineWidth: 1,
        lineColor: "#2F4F4F",
        color: "#2F4F4F",
        fillOpacity: 0.2,
      },
    },

    tooltip: {
      shared: true,
    },

    scrollbar: {
      enabled: true,
    },

    series: [
      {
        type: "ohlc",
        id: "ohlc",
        name: data[0]["name"],
        data: data[0]["data"],
        yAxis: 0,
        zIndex: 1,
        // dataGrouping: {
        //   units: groupingUnits,
        // },
        showInLegend: false,
      },
      {
        type: "pivotpoints",
        linkedTo: "ohlc",
        zIndex: 0,
        lineWidth: 1,
        dataLabels: {
          overflow: "none",
          crop: false,
          y: 4,
          style: {
            fontSize: 9,
          },
        },
        showInLegend: true,
      },
      {
        type: "pivotpoints",
        linkedTo: "ohlc",
        zIndex: 0,
        lineWidth: 1,
        visible: true,
        name: "Camarilla PP (56)",
        params: {
          algorithm: "camarilla",
          period: 56,
        },
        dataLabels: {
          overflow: "none",
          crop: false,
          y: 4,
          style: {
            fontSize: 9,
          },
        },
        showInLegend: true,
      },
      {
        type: "pivotpoints",
        linkedTo: "ohlc",
        zIndex: 0,
        lineWidth: 1,
        visible: true,
        params: {
          algorithm: "fibonacci",
          period: 14,
        },
        name: "Fibonacci PP (14)",
        dataLabels: {
          overflow: "none",
          crop: false,
          y: 4,
          style: {
            fontSize: 9,
          },
        },
        showInLegend: true,
      },
    ],
    credits: {
      text: "DaaSHK",
      href: "https://daashk.com/",
      style: {
        fontSize: "1em",
      },
    },
  };

  return (
    <div className="chart">
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"stockChart"}
        options={options}
      />
      <div style={{ fontSize: fontSizePct }}>
        <span style={{ color: "purple" }}>紫線</span>
        :為相對強度評級(RS)之變化{" "}
        <Link to="/topics/rs">(相對強度評級列表)</Link> <br />
      </div>
    </div>
  );
}

export default RSChart;
