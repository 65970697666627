import React from "react";
import MetaTags from "react-meta-tags";
import ActiveHKC from "./ActiveHKC";
import DisplayAdsBanner from "../../ads/DisplayAdsBanner";
import GoogleAd from "../../ads/Google_ad";
import { Container, Header } from "semantic-ui-react";

export const TopicHKC = () => {
  return (
    <>
      <MetaTags>
        <title>港股通成交活躍股</title>
        <meta name="description" content="港股通成交活躍股" />
        <meta property="og:title" content="港股通成交活躍股" />
        <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
      </MetaTags>
      <Container textAlign="center">
        <DisplayAdsBanner />
      </Container>

      <div style={{ paddingTop: "25px", paddingBottom: "25px" }}>
        <div style={{ textAlign: "center" }}>
          <Header as="h1">
            港股通成交活躍股
            <Header.Subheader>一文看清港股通成交活躍股</Header.Subheader>
          </Header>
        </div>
        <ActiveHKC />
      </div>
      <Container
        textAlign="center"
        style={{ paddingTop: "30px", paddingBottom: "0px" }}
      >
        <GoogleAd
          client="ca-pub-8753358837449417"
          slot="2486257452"
          format="auto"
          wrapperDivStyle={{
            marginTop: "30px",
            marginBottom: "20px",
          }}
        />
      </Container>
    </>
  );
};

export default TopicHKC;
