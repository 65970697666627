import React, { Component } from "react";
import "./DisplayAdsBanner.css";

export default class DisplayAdsBanner extends Component {
  // componentDidMount() {
  //   (window.adsbygoogle = window.adsbygoogle || []).push({});
  // }

  //   render() {
  //     return (
  //       <ins
  //         className="adsbygoogle general_banner"
  //         style={{ display: "inline-block" }}
  //         data-ad-client="ca-pub-8753358837449417"
  //         data-ad-slot="6375911592"
  //       ></ins>
  //     );
  //   }
  // }

  render() {
    return <div></div>;
  }
}
