import React from 'react';
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

const chartHeight = 125;


function ShareBarChart(keyholderData) {
  var dataK = keyholderData.keyholderData;

  var colors = [];
  colors[0] = '#C02E1D';
  colors[1] = '#D94E1E';
  colors[2] = '#F26D22';
  colors[3] = '#EF8B2B';
  colors[4] = '#ECAA38';
  colors[5] = '#EBC844';
  colors[6] = '#A2B86C';
  colors[7] = '#5CA793';
  colors[8] = '#1195BA';
  colors[9] = '#115B78';
  colors[10] = '#000000';
  // colors[11] = '#0D3C55';
  // colors[12] = '#C02E1D';

  var options = {
    chart: {
      type: 'bar',
      height: chartHeight,
    },
    title: {
      text: '十大經紀商股權分佈',
      style: {
        display: 'none'
      }
    },
    xAxis: {
      categories: ['']
    },
    colors: colors,
    yAxis: {
      min: 0,
      max: 100,
      title: {
        text: '%',
        align: 'high'
      },
      reversedStacks: false,
    },
    legend: {
      reversed: false,
      enabled: false,
    },
    tooltip: {
      valueSuffix: ' %'
    },
    plotOptions: {
      series: {
        stacking: 'normal',

      },
    },
    series:  dataK,
    credits: {
      // enabled: false
      text: 'DaaSHK',
      // href: 'https://iccasshk.com/',
      style: {
        fontSize: '1em'
      },
    }
  };


  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  )
}

export default ShareBarChart