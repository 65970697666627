import React, { useEffect } from "react";
import { Loader, Card, Button, Container } from "semantic-ui-react";
import {
  selectAllTopic,
  fetchTopic,
  fetchTopicwM,
  toIdle,
} from "../../../features/topic/topicSlice";
import { useSelector, useDispatch } from "react-redux";
import {
  // useIsLoggedIn,
  useUserFavourites,
  useAllowedBehindPaywall,
} from "features/user/userSlice";
import { Link } from "react-router-dom";
// import { FirebaseLoginCard } from "components/auth/FirebaseLoginForm";
import RSTableContainer from "./RSTableContainer";
import _ from "lodash";

function RSView() {
  const allowedBehindPaywall = useAllowedBehindPaywall();
  return (
    <div style={{ paddingTop: "25px" }}>
      <>{allowedBehindPaywall ? <RSViewContainer /> : <AskForSubscribe />}</>
    </div>
  );
}

export const RSViewContainer = () => {
  // const isLoggedIn = useIsLoggedIn();
  const allowedBehindPaywall = useAllowedBehindPaywall();
  const wishList = useUserFavourites();
  const dispatch = useDispatch();
  const topics = useSelector(selectAllTopic);
  const topicStatus = useSelector((state) => state.topic.status);
  const error = useSelector((state) => state.topic.error);
  useEffect(() => {
    if (topicStatus === "idle") {
      if (
        topics.find((item) => item.topicInfo.name === "rstable2") === undefined
      ) {
        dispatch(fetchTopic("rstable2"));
      }
      if (
        topics.find((item) => item.topicInfo.name === "stockSectorInfo") ===
        undefined
      ) {
        dispatch(fetchTopic("stockSectorInfo"));
      }
      if (
        topics.find((item) => item.topicInfo.name === "stockDict_all_n") ===
        undefined
      ) {
        dispatch(fetchTopicwM("stockDict_all_n"));
      }
    }
  }, [topicStatus, topics, dispatch]);

  let content;
  if (topicStatus === "loading") {
    content = (
      <div>
        <section className="posts-list">
          <div
            style={{
              padding: "100px",
              margin: "auto",
              alignItems: "center",
              height: "75vh",
            }}
          >
            <Loader active inline="centered">
              {" "}
              Loading
            </Loader>
          </div>
        </section>
      </div>
    );
  } else if (topicStatus === "succeeded") {
    const data = topics.find((item) => item.topicInfo.name === "rstable2");
    const stockSector = topics.find(
      (item) => item.topicInfo.name === "stockSectorInfo"
    );
    const stockDict = topics.find(
      (item) => item.topicInfo.name === "stockDict_all_n"
    );
    // console.log(stockDict);
    if (data && stockSector && stockDict) {
      content = (
        <RSTableContainer
          data={data}
          stockDict={_.filter(stockDict["data"], function (o) {
            return o.m === "1";
          })}
          stockSector={stockSector}
          wishList={wishList}
          allowedBehindPaywall={allowedBehindPaywall}
        />
      );
    } else {
      dispatch(toIdle({}));
    }
  } else if (topicStatus === "error") {
    content = (
      <div>
        <section className="posts-list">
          <div>{error}</div>
        </section>
      </div>
    );
  }
  return <>{content}</>;
};

export default RSView;

function AskForSubscribe() {
  return (
    <div style={{ margin: "auto" }}>
      <Card centered style={{ textAlign: "center" }}>
        <Card.Content>
          <Card.Header>加入付費版，以體驗進階功能</Card.Header>
        </Card.Content>
        <Card.Content extra>
          <Link to="/subscription">
            <Button basic color="blue">
              了解更多
            </Button>
          </Link>
        </Card.Content>
      </Card>
    </div>
  );
}
