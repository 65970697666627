import React, { Component } from "react";
import { Container, Header, List, Image, Menu } from "semantic-ui-react";
import MetaTags from "react-meta-tags";
import DisplayAdsLargeBanner from "../ads/DisplayAdsLargeBanner";
import DisplayAdsBanner from "../ads/DisplayAdsBanner";
import { SupportUs } from "../widget/SupportUsWidget";

const emailString = <a href="mailto:contact@daashk.com"> contact@DaaSHK.com</a>;

function About() {
  return (
    <div
      style={{
        margin: "auto",
        alignItems: "center",
        // height: "75vh",
        // overflow: "auto",
      }}
    >
      <MetaTags>
        <title>關於本站</title>
      </MetaTags>
      <Container textAlign="center">
        <DisplayAdsBanner />
      </Container>
      <Container style={{ padding: "2em 0em" }}>
        <Header as="h1" STYLE="text-decoration:underline">
          關於本站
        </Header>

        <p>
          本站(DaaSHK.COM)是利用網上開放數據,
          以圖表化的方式讓公眾更有效地理解及使用數據。
        </p>
        <SupportUs />
      </Container>

      <Container style={{ textAlign: "center" }}>
        <TabMenu />
      </Container>

      <Container style={{ padding: "1em 0em" }}>
        <AboutCCASS />
      </Container>
      <Container style={{ padding: "1em 0em" }}>
        <SupportUs />
      </Container>

      <Container textAlign="center">
        <DisplayAdsBanner />
      </Container>
    </div>
  );
}

export class TabMenu extends Component {
  state = { activeItem: "股票數據" };

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  render() {
    const { activeItem } = this.state;

    return (
      <div style={{ margin: "auto" }}>
        <Menu
          compact
          pointing
          secondary
          size="massive"
          style={{ margin: "auto" }}
        >
          <Menu.Item
            name="股票數據"
            active={activeItem === "股票數據"}
            onClick={this.handleItemClick}
          />
        </Menu>
      </div>
    );
  }
}

const AboutCCASS = () => {
  return (
    <div style={{ paddingTop: "10px" }}>
      <p>
        利用中央結算系統（Central Clearing and Settlement System，
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.hkexnews.hk/sdw/search/searchsdw_c.aspx"
        >
          {" "}
          CCASS{" "}
        </a>
        )上的公開數據配合股價走勢，以互動式圖表讓讀者有效地理解和使用資訊。{" "}
        <br />
        所有內容只供參考並非投資意見，不構成任何投資產品之要約、要約招攬或建議。
      </p>

      <Header as="h2" STYLE="text-decoration:underline">
        數據來源及更新
      </Header>
      <Header as="h4">網站資料來源</Header>
      <p>
        中央結算系統持股紀錄:{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.hkexnews.hk/sdw/search/searchsdw_c.aspx"
        >
          {" "}
          CCASS{" "}
        </a>
      </p>

      <p>
        股價資料:{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://finance.yahoo.com/"
        >
          {" "}
          Yahoo Finance{" "}
        </a>
      </p>

      <Header as="h4">資料更新時間</Header>
      <p>每逢交易日後晚上三點前(網站仍在建設中，我們會盡力提早更新時間)。</p>
      <p>
        <Image
          alt="lastUpate"
          size="small"
          src="images/about/chartExample/lastUpdate.png"
          // centered
          // floated='left'
        />
      </p>

      <p>
        在閱讀圖表前請留意數據是否更新至上一個交易天，如發現尚未更新，請更新頁面以獲取最新數據。
      </p>

      <p>
        如對數據有疑問，請 email: {emailString}{" "}
        致我們團隊，我們會有專人盡快回覆。
      </p>

      <Header as="h2" STYLE="text-decoration:underline">
        圖表解讀
      </Header>

      <Header as="h4">參與者數目及股價走勢圖</Header>
      <p>下圖為參與者數目及股價走勢圖</p>
      <p>陰陽燭為股價變化</p>
      <p>
        <span style={{ color: "#0072ce" }}>藍線</span>
        為股票在中央結算系統紀錄的參與者數目變化
      </p>

      <Image
        alt="388"
        size="big"
        src="images/about/chartExample/388.png"
        centered
      />

      <Header as="h5">參與者定義</Header>

      <p>
        參與者一般為市場中介人士(例如經紀及託管商)，當中亦有願意披露的投資者戶口持有人和不願意披露的投資者戶口持有人。
      </p>
      <p>
        參與者數目及
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.hkexnews.hk/sdw/search/partlist_c.aspx?sortby=partid&shareholdingdate"
        >
          {" "}
          中央結算系統參與者名錄(中介人){" "}
        </a>
        可於
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.hkexnews.hk/sdw/search/searchsdw_c.aspx"
        >
          {" "}
          中央結算系統持股紀錄網頁{" "}
        </a>
        查閱到。
      </p>

      <Image
        alt="CCASS Table"
        size="big"
        src="images/about/chartExample/ccassTable.png"
        centered
      />

      <Header as="h5">例子</Header>

      <p>參與者人數及股票變化一般有三種好值得投資者留意的形態：</p>

      <Container
        textAlign="center"
        style={{ paddingTop: "25px", paddingBottom: "25px" }}
      >
        <DisplayAdsLargeBanner />
      </Container>

      <List ordered>
        <List.Item>
          股價升參與者數目跌：
          <List.Item>
            過去一年大部份的倍升股都有呢個形態。最近例子
            {/* 一般可解讀為股價上升後，引致大量參與者持續獲利離場，呢個時候股票貨源比較集中，沽盤減少，喺求過於供的情況下，公司股價不需要大量資金都可以大幅炒上。 */}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://daashk.com/stock/0388"
            >
              {" "}
              388{" "}
            </a>
            ,
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://daashk.com/stock/0992"
            >
              {" "}
              992{" "}
            </a>
            ,
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://daashk.com/stock/2013"
            >
              {" "}
              2013{" "}
            </a>
            ...
          </List.Item>
        </List.Item>
        <Image
          alt="388"
          size="big"
          src="images/about/chartExample/388.png"
          centered
        />
        <List.Item>
          股價跌參與者數目升:
          <List.Item>
            過去一年好多令人失望的陰跌股大都有呢個形態。例子有：
            {/* 可解讀為股價下跌後，由於仍有投資者看好公司前景而進行低吸，呢個時候因散戶太多，股價稍一上升就沽盤涌現，股價難而持續上升而轉勢。 */}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://daashk.com/stock/0788"
            >
              {" "}
              788{" "}
            </a>
            ,
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://daashk.com/stock/0941"
            >
              {" "}
              941{" "}
            </a>
            ...
          </List.Item>
        </List.Item>
        <Image
          alt="788"
          size="big"
          src="images/about/chartExample/788.png"
          centered
        />
        <List.Item>
          股價升參與者數目升：
          <List.Item>
            此類形態多出現於細價股，例如：
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://daashk.com/stock/0305"
            >
              {" "}
              305{" "}
            </a>
            。 亦有時會出現於強勢大價股上，例如：
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://daashk.com/stock/1211"
            >
              {" "}
              1211{" "}
            </a>
            。
          </List.Item>
        </List.Item>
        <Image
          alt="1211"
          size="big"
          src="images/about/chartExample/1211.png"
          centered
        />
      </List>
      <p>觀察過去股票及參與者數目變化， 能幫助投資者對未來股價作出預測。</p>
      <p>
        如你對本頁資訊有任何疑問或發現任何有趣的圖形，請電郵致{emailString}
        ，我們會有專人盡快回覆。
        {/* 如果你有細仔觀察以上例子，你會發現圖形形態係有持續性三個月或以上不等。善用呢個工具及嘗試理解圖表背後的原因，你就可以為投資作出合適的部署，例如你會購入或繼續持有出現上述形態一及三的股票，然後減持出現形態二的股票。
                  最後大家要留意，預測股價走勢有好多工具，參與者人數及股票變化圖表就是其中之一，沒有工具是萬能，如果你發現某一隻股票的走勢是不能用此圖表解釋，建議就換另一種工具或指標。 */}
      </p>
    </div>
  );
};

export default About;
