import React from "react";
import { Header, Card, Image, Grid } from "semantic-ui-react";
import SingleLineImageList from "./SingleLineImageList";
import { useHistory } from "react-router-dom";
import TableWidget from "./TableWidget";
import DisplayAdsLargeBanner from "../ads/DisplayAdsLargeBanner";

function HomeWidget(props) {
  const isMobile = props.isMobile;
  const sWidth = props.sWidth;
  const pWidth = sWidth < 800 ? "100%" : "90%";
  return (
    <div
      style={{
        textAlign: "center",
        margin: "auto",
        paddingBottom: "25px",
        width: { pWidth },
        maxWidth: "1000px",
      }}
    >
      {sWidth !== null ? (
        <HomeWidgetView sWidth={sWidth} isMobile={isMobile} />
      ) : null}
    </div>
  );
}

function HomeWidgetView(props) {
  const sWidth = props.sWidth;
  const isMobile = props.isMobile;
  const titlePos = isMobile ? "left" : "center";
  const headerSize = "h1";
  const lifeList = [
    {
      title: "每日金融數據",
      src: "images/topics/T_money.png",
      href: "/topics/hkmonetary",
      update: "定期更新",
      cat: "life",
    },
    // {
    //   title: "社區檢測中心預約情況",
    //   src: "images/topics/topics_covidtest.png",
    //   href: "/topics/covidtest",
    // },
    // {
    //   title: "疫苗接種統計",
    //   src: "images/topics/T_vaccineStat.png",
    //   href: "/topics/vaccination",
    // },
    {
      title: "交通工具到站預報",
      src: "images/topics/topics_eta.png",
      href: "/eta",
    },
    // {
    //   title: "疫苗抽獎及優惠詳情",
    //   src: "images/topics/T_vaccine.png",
    //   href: "/topics/vaccine_luckydraw_info",
    // },
  ];

  const finList = [
    {
      title: "港股排行榜",
      src: "images/topics/T_rank.png",
      href: "/rank/active",
    },
    {
      title: "港股相對強度評級(RS)",
      src: "images/topics/T_RS_HK.png",
      href: "/topics/rs",
    },
    {
      title: "美股相對強度評級(RS)",
      src: "images/topics/T_RS_US.png",
      href: "/topics/usrs",
    },
    {
      title: "板塊輪動圖(港股)",
      src: "images/topics/topics_rrg.png",
      href: "/topics/rrghk",
      update: "定期更新",
      cat: "stock",
    },
    {
      title: "股份回購",
      src: "images/topics/topics_buyback.png",
      href: "/topics/buyback",
    },
    {
      title: "半新股資訊",
      src: "images/topics/T_listedipo.png",
      href: "/topics/listedipo",
    },
    {
      title: "業績公佈時間表",
      src: "images/topics/T_companyResultA.png",
      href: "/topics/resultcalendar",
    },
    {
      title: "持倉異動",
      src: "images/topics/topics_shareholding_change.png",
      href: "/shareholding/shareholding_change",
    },
  ];

  var computer_width = 7;
  var tablet_width = 7;
  var mobile_width = 16;
  //var ads_width = 3
  var table_max_row = 5;

  return (
    <div style={{ margin: "auto" }}>
      <div className="widget-grid">
        {/* <Divider horizontal> */}
        <Header as="h1">排行榜</Header>
        {/* </Divider> */}

        <Grid centered>
          <Grid.Column
            className="widget-view"
            centered="true"
            computer={computer_width}
            tablet={tablet_width}
            mobile={mobile_width}
          >
            <div className="widget-element">
              <TableWidget
                table="active"
                title="最大成交額"
                maxRow={table_max_row}
                to="/rank/active"
                cols="1,2,6"
                colswidth="8,3,4"
              />
            </div>
          </Grid.Column>

          <Grid.Column
            className="widget-view"
            centered="true"
            computer={computer_width}
            tablet={tablet_width}
            mobile={mobile_width}
          >
            <div className="widget-element">
              <TableWidget
                table="topgainers"
                title="最大升幅"
                maxRow={table_max_row}
                to="/rank/topgainers"
                cols="1,2,4"
                colswidth="8,3,4"
              />
            </div>
          </Grid.Column>

          <Grid.Column
            className="widget-view"
            centered="true"
            computer={computer_width}
            tablet={tablet_width}
            mobile={mobile_width}
          >
            <div className="widget-element">
              <TableWidget
                table="toplosers"
                title="最大跌幅"
                maxRow={table_max_row}
                to="/rank/toplosers"
                cols="1,2,4"
                colswidth="8,3,4"
              />
            </div>
          </Grid.Column>

          <Grid.Column
            className="widget-view"
            centered="true"
            computer={computer_width}
            tablet={tablet_width}
            mobile={mobile_width}
          >
            <div className="widget-element">
              <TableWidget
                table="northWater"
                title="港股通"
                maxRow={table_max_row}
                to="/rank/northWater"
                cols="1,2,5"
                colswidth="8,3,4"
              />
            </div>
          </Grid.Column>
        </Grid>

        <Grid centered>
          <Grid.Column centered="true">
            <div className="widget-element">
              <DisplayAdsLargeBanner />
            </div>
          </Grid.Column>
        </Grid>
      </div>

      <Header textAlign={titlePos} as={headerSize}>
        股市
      </Header>
      {isMobile ? (
        <SingleLineImageList itemData={finList} sWidth={sWidth} />
      ) : (
        <CardGroupContent itemData={finList} />
      )}
      <Header textAlign={titlePos} as={headerSize}>
        生活
      </Header>
      {isMobile ? (
        <SingleLineImageList itemData={lifeList} sWidth={sWidth} />
      ) : (
        <CardGroupContent itemData={lifeList} />
      )}
    </div>
  );
}

function CardGroupContent(props) {
  const history = useHistory();
  const itemData = props.itemData;
  // console.log('not mobile')
  return (
    <div>
      <Card.Group centered itemsPerRow={3} style={{ paddingTop: "0px" }}>
        {itemData.map((item, index) => (
          <Card
            key={index}
            centered
            onClick={() => {
              history.push(item.href);
            }}
          >
            <Image
              alt={item.title}
              size="big"
              src={item.src}
              centered
              wrapped
              ui={false}
            />
            <Card.Content>
              <Card.Header>{item.title}</Card.Header>
            </Card.Content>
          </Card>
        ))}
      </Card.Group>
    </div>
  );
}

export default HomeWidget;
