import React from "react";
import { getAuth, signOut } from "firebase/auth";
import { Button } from "semantic-ui-react";
import { firebaseApp } from "config/fbConfig";
import { useDispatch } from "react-redux";
import { logout } from "features/user/userSlice";
import { useHistory } from "react-router-dom";

export const firebaseLogout = async (dispatch, history) => {
  const auth = getAuth(firebaseApp);
  // console.log("signout")
  await signOut(auth)
    .then(() => {
      // Sign-out successful.
      dispatch(logout());
      history.push("/");
    })
    .catch((error) => {
      // An error happened.
      console.error(error);
    });
};

const FirebaseLogoutForm = (props) => {
  const { floated } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const onClick = async (event, data) =>
    await firebaseLogout(dispatch, history);
  return (
    <Button floated={floated} onClick={async () => onClick()}>
      <Button.Content>登出</Button.Content>
    </Button>
  );
};

export default FirebaseLogoutForm;
