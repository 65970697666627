const source = [
  {
    no: "0001",
    name_en: "CK HUTCHISON HOLDINGS LIMITED",
    name_zh: "長和",
    isHKConnect: 1,
  },
  {
    no: "0002",
    name_en: "CLP HOLDINGS LIMITED",
    name_zh: "中電控股",
    isHKConnect: 1,
  },
  {
    no: "0003",
    name_en: "HONG KONG AND CHINA GAS COMPANY LIMITED, THE",
    name_zh: "香港中華煤氣",
    isHKConnect: 1,
  },
  {
    no: "0004",
    name_en: "WHARF (HOLDINGS) LIMITED, THE",
    name_zh: "九龍倉集團",
    isHKConnect: 1,
  },
  {
    no: "0005",
    name_en: "HSBC HOLDINGS PLC",
    name_zh: "匯豐控股",
    isHKConnect: 1,
  },
  {
    no: "0006",
    name_en: "POWER ASSETS HOLDINGS LIMITED",
    name_zh: "電能實業",
    isHKConnect: 1,
  },
  {
    no: "0007",
    name_en: "WISDOM WEALTH RESOURCES INVESTMENT HOLDING GROUP LIMITED",
    name_zh: "智富資源投資",
    isHKConnect: 0,
  },
  { no: "0008", name_en: "PCCW LIMITED", name_zh: "電訊盈科", isHKConnect: 1 },
  { no: "0009", name_en: "KEYNE LTD", name_zh: "金奧國際", isHKConnect: 0 },
  {
    no: "0010",
    name_en: "HANG LUNG GROUP LIMITED",
    name_zh: "恆隆集團",
    isHKConnect: 1,
  },
  {
    no: "0011",
    name_en: "HANG SENG BANK LTD",
    name_zh: "恆生銀行",
    isHKConnect: 1,
  },
  {
    no: "0012",
    name_en: "HENDERSON LAND DEVELOPMENT CO LTD",
    name_zh: "恆基地產",
    isHKConnect: 1,
  },
  {
    no: "0013",
    name_en: "HUTCHMED (CHINA) LIMITED",
    name_zh: "和黃醫藥",
    isHKConnect: 1,
  },
  {
    no: "0014",
    name_en: "HYSAN DEVELOPMENT COMPANY LIMITED",
    name_zh: "希慎興業",
    isHKConnect: 1,
  },
  {
    no: "0016",
    name_en: "SUN HUNG KAI PROPERTIES LIMITED",
    name_zh: "新鴻基地產",
    isHKConnect: 1,
  },
  {
    no: "0017",
    name_en: "NEW WORLD DEVELOPMENT CO LTD",
    name_zh: "新世界發展",
    isHKConnect: 1,
  },
  {
    no: "0018",
    name_en: "ORIENTAL ENTERPRISE HOLDINGS LIMITED",
    name_zh: "東方企控集團",
    isHKConnect: 0,
  },
  {
    no: "0019",
    name_en: "SWIRE PACIFIC LTD 'A'",
    name_zh: "太古股份公司Ａ",
    isHKConnect: 1,
  },
  {
    no: "0020",
    name_en: "SENSETIME GROUP INC.",
    name_zh: "商湯-W",
    isHKConnect: 1,
  },
  {
    no: "0021",
    name_en: "GREAT CHINA HOLDINGS (HONG KONG) LIMITED",
    name_zh: "大中華控股",
    isHKConnect: 0,
  },
  { no: "0022", name_en: "MEXAN LIMITED", name_zh: "茂盛控股", isHKConnect: 0 },
  {
    no: "0023",
    name_en: "BANK OF EAST ASIA LTD",
    name_zh: "東亞銀行",
    isHKConnect: 1,
  },
  {
    no: "0025",
    name_en: "CHEVALIER INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "CHEVALIER INT'L",
    isHKConnect: 0,
  },
  {
    no: "0026",
    name_en: "CHINA MOTOR BUS COMPANY LIMITED",
    name_zh: "中華汽車",
    isHKConnect: 0,
  },
  {
    no: "0027",
    name_en: "GALAXY ENTERTAINMENT GROUP LIMITED",
    name_zh: "銀河娛樂",
    isHKConnect: 1,
  },
  {
    no: "0028",
    name_en: "TIAN AN CHINA INVESTMENTS COMPANY LIMITED",
    name_zh: "天安",
    isHKConnect: 0,
  },
  {
    no: "0029",
    name_en: "DYNAMIC HOLDINGS LIMITED",
    name_zh: "達力集團",
    isHKConnect: 0,
  },
  {
    no: "0030",
    name_en: "BAN LOONG HOLDINGS LIMITED",
    name_zh: "萬隆控股集團",
    isHKConnect: 0,
  },
  {
    no: "0031",
    name_en: "CHINA AEROSPACE INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "航天控股",
    isHKConnect: 0,
  },
  {
    no: "0032",
    name_en: "CROSS-HARBOUR (HOLDINGS) LIMITED, THE",
    name_zh: "港通控股",
    isHKConnect: 0,
  },
  {
    no: "0033",
    name_en: "INTERNATIONAL GENIUS COMPANY",
    name_zh: "安山金控",
    isHKConnect: 0,
  },
  {
    no: "0034",
    name_en: "KOWLOON DEVELOPMENT COMPANY LIMITED",
    name_zh: "九龍建業",
    isHKConnect: 0,
  },
  {
    no: "0035",
    name_en: "FAR EAST CONSORTIUM INTERNATIONAL LTD",
    name_zh: "遠東發展",
    isHKConnect: 1,
  },
  {
    no: "0036",
    name_en: "FAR EAST HOLDINGS INTERNATIONAL LIMITED",
    name_zh: "遠東控股國際",
    isHKConnect: 0,
  },
  {
    no: "0037",
    name_en: "FAR EAST HOTELS & ENTERTAINMENT LIMITED",
    name_zh: "遠東酒店實業",
    isHKConnect: 0,
  },
  {
    no: "0038",
    name_en: "FIRST TRACTOR COMPANY LIMITED",
    name_zh: "第一拖拉機股份",
    isHKConnect: 1,
  },
  {
    no: "0039",
    name_en: "CHINA BEIDAHUANG INDUSTRY GROUP HOLDINGS LIMITED",
    name_zh: "中國北大荒",
    isHKConnect: 0,
  },
  {
    no: "0040",
    name_en: "GOLD PEAK TECHNOLOGY GROUP LIMITED",
    name_zh: "金山科技工業",
    isHKConnect: 0,
  },
  {
    no: "0041",
    name_en: "GREAT EAGLE HOLDINGS LIMITED",
    name_zh: "鷹君",
    isHKConnect: 1,
  },
  {
    no: "0042",
    name_en: "NORTHEAST ELECTRIC DEVELOPMENT COMPANY LIMITED",
    name_zh: "東北電氣",
    isHKConnect: 1,
  },
  {
    no: "0045",
    name_en: "HONGKONG AND SHANGHAI HOTELS LTD,THE",
    name_zh: "大酒店",
    isHKConnect: 1,
  },
  {
    no: "0046",
    name_en: "COMPUTER AND TECHNOLOGIES HOLDINGS LIMITED",
    name_zh: "科聯系統",
    isHKConnect: 0,
  },
  {
    no: "0048",
    name_en: "CHINA AUTOMOTIVE INTERIOR DECORATION HOLDINGS LIMITED",
    name_zh: "中國汽車內飾",
    isHKConnect: 0,
  },
  {
    no: "0050",
    name_en: "HONG KONG FERRY (HOLDINGS) CO LTD",
    name_zh: "香港小輪(集團)",
    isHKConnect: 0,
  },
  {
    no: "0051",
    name_en: "HARBOUR CENTRE DEVELOPMENT LTD",
    name_zh: "海港企業",
    isHKConnect: 0,
  },
  {
    no: "0052",
    name_en: "FAIRWOOD HOLDINGS LIMITED",
    name_zh: "大快活集團",
    isHKConnect: 0,
  },
  {
    no: "0053",
    name_en: "GUOCO GROUP LIMITED",
    name_zh: "國浩集團",
    isHKConnect: 0,
  },
  {
    no: "0055",
    name_en: "NEWAY GROUP HOLDINGS LIMITED",
    name_zh: "中星集團控股",
    isHKConnect: 0,
  },
  {
    no: "0057",
    name_en: "CHEN HSONG HOLDINGS LTD",
    name_zh: "震雄集團",
    isHKConnect: 0,
  },
  {
    no: "0058",
    name_en: "SUNWAY INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "新威國際",
    isHKConnect: 0,
  },
  {
    no: "0059",
    name_en: "SKYFAME REALTY (HOLDINGS) LIMITED",
    name_zh: "天譽置業",
    isHKConnect: 1,
  },
  {
    no: "0060",
    name_en: "HONG KONG FOOD INVESTMENT HOLDINGS LIMITED",
    name_zh: "香港食品投資",
    isHKConnect: 0,
  },
  {
    no: "0061",
    name_en: "GREEN LEADER HOLDINGS GROUP LIMITED",
    name_zh: "綠領控股",
    isHKConnect: 0,
  },
  {
    no: "0062",
    name_en: "TRANSPORT INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "載通",
    isHKConnect: 0,
  },
  {
    no: "0063",
    name_en: "CHINA ASIA VALLEY GROUP LIMITED",
    name_zh: "中亞烯谷集團",
    isHKConnect: 0,
  },
  {
    no: "0064",
    name_en: "GET NICE HOLDINGS LIMITED",
    name_zh: "結好控股",
    isHKConnect: 0,
  },
  {
    no: "0065",
    name_en: "GRAND OCEAN ADVANCED RESOURCES COMPANY LIMITED",
    name_zh: "弘海高新資源",
    isHKConnect: 0,
  },
  {
    no: "0066",
    name_en: "MTR CORPORATION LIMITED",
    name_zh: "港鐵公司",
    isHKConnect: 1,
  },
  {
    no: "0069",
    name_en: "SHANGRI-LA ASIA LIMITED",
    name_zh: "香格里拉(亞洲)",
    isHKConnect: 1,
  },
  {
    no: "0070",
    name_en: "RICH GOLDMAN HOLDINGS LIMITED",
    name_zh: "金粵控股",
    isHKConnect: 0,
  },
  {
    no: "0071",
    name_en: "MIRAMAR HOTEL & INVESTMENT CO LTD",
    name_zh: "美麗華酒店",
    isHKConnect: 0,
  },
  {
    no: "0072",
    name_en: "META MEDIA HOLDINGS LIMITED",
    name_zh: "超媒體控股",
    isHKConnect: 0,
  },
  {
    no: "0073",
    name_en: "ASIAN CITRUS HOLDINGS LIMITED",
    name_zh: "亞洲果業",
    isHKConnect: 0,
  },
  {
    no: "0075",
    name_en: "Y. T. REALTY GROUP LIMITED",
    name_zh: "渝太地產",
    isHKConnect: 0,
  },
  {
    no: "0076",
    name_en: "ELATE HOLDINGS LIMITED",
    name_zh: "誼礫控股",
    isHKConnect: 0,
  },
  {
    no: "0077",
    name_en: "AMS PUBLIC TRANSPORT HOLDINGS LIMITED",
    name_zh: "進智公共交通",
    isHKConnect: 0,
  },
  {
    no: "0078",
    name_en: "REGAL HOTELS INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "REGAL INT'L",
    isHKConnect: 1,
  },
  {
    no: "0079",
    name_en: "CENTURY LEGEND (HOLDINGS) LIMITED",
    name_zh: "世紀建業",
    isHKConnect: 0,
  },
  {
    no: "0080",
    name_en: "CHINA NEW ECONOMY FUND LIMITED",
    name_zh: "中國新經濟投資",
    isHKConnect: 0,
  },
  {
    no: "0081",
    name_en: "CHINA OVERSEAS GRAND OCEANS GROUP LIMITED",
    name_zh: "中國海外宏洋集團",
    isHKConnect: 1,
  },
  {
    no: "0082",
    name_en: "CRAZY SPORTS GROUP LIMITED",
    name_zh: "瘋狂體育",
    isHKConnect: 0,
  },
  {
    no: "0083",
    name_en: "SINO LAND CO.LTD.",
    name_zh: "信和置業",
    isHKConnect: 1,
  },
  {
    no: "0084",
    name_en: "STELUX HOLDINGS INTERNATIONAL LIMITED",
    name_zh: "寶光實業",
    isHKConnect: 0,
  },
  {
    no: "0085",
    name_en: "CHINA ELECTRONICS HUADA TECHNOLOGY COMPANY LIMITED",
    name_zh: "中電華大科技",
    isHKConnect: 0,
  },
  {
    no: "0086",
    name_en: "SUN HUNG KAI & CO.LTD",
    name_zh: "新鴻基公司",
    isHKConnect: 1,
  },
  {
    no: "0087",
    name_en: "SWIRE PACIFIC LTD 'B'",
    name_zh: "太古股份公司Ｂ",
    isHKConnect: 1,
  },
  {
    no: "0088",
    name_en: "TAI CHEUNG HOLDINGS LTD",
    name_zh: "TAI CHEUNG HOLD",
    isHKConnect: 0,
  },
  {
    no: "0089",
    name_en: "TAI SANG LAND DEVELOPMENT LTD",
    name_zh: "大生地產",
    isHKConnect: 0,
  },
  {
    no: "0090",
    name_en: "PUXING ENERGY LIMITED",
    name_zh: "普星能量",
    isHKConnect: 0,
  },
  {
    no: "0091",
    name_en: "GOLDEN CENTURY INTERNATIONAL HOLDINGS GROUP LIMITED",
    name_zh: "金禧國際控股",
    isHKConnect: 0,
  },
  {
    no: "0092",
    name_en: "CHAMPION TECHNOLOGY HOLDINGS LIMITED",
    name_zh: "冠軍科技集團",
    isHKConnect: 0,
  },
  {
    no: "0093",
    name_en: "TERMBRAY INDUSTRIES INTERNATIONAL (HOLDINGS) LIMITED",
    name_zh: "TERMBRAY IND",
    isHKConnect: 0,
  },
  {
    no: "0094",
    name_en: "GREENHEART GROUP LIMITED",
    name_zh: "綠心集團",
    isHKConnect: 0,
  },
  {
    no: "0095",
    name_en: "LVGEM (CHINA) REAL ESTATE INVESTMENT COMPANY LIMITED",
    name_zh: "綠景中國地產",
    isHKConnect: 1,
  },
  {
    no: "0096",
    name_en: "YUSEI HOLDINGS LIMITED",
    name_zh: "YUSEI HOLDINGS LIMITED",
    isHKConnect: 0,
  },
  {
    no: "0097",
    name_en: "HENDERSON INVESTMENT LTD",
    name_zh: "恆基發展",
    isHKConnect: 0,
  },
  {
    no: "0098",
    name_en: "XINGFA ALUMINIUM HOLDINGS LIMITED",
    name_zh: "興發鋁業",
    isHKConnect: 0,
  },
  {
    no: "0099",
    name_en: "WONG'S INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "王氏國際",
    isHKConnect: 0,
  },
  {
    no: "0101",
    name_en: "HANG LUNG PROPERTIES LIMITED",
    name_zh: "恆隆地產",
    isHKConnect: 1,
  },
  {
    no: "0102",
    name_en: "SUMMIT ASCENT HOLDINGS LIMITED",
    name_zh: "凱升控股",
    isHKConnect: 0,
  },
  {
    no: "0103",
    name_en: "SHOUGANG CENTURY HOLDINGS LIMITED",
    name_zh: "首佳科技",
    isHKConnect: 0,
  },
  {
    no: "0104",
    name_en: "ASIA COMMERCIAL HOLDINGS LIMITED",
    name_zh: "ASIA COMM HOLD",
    isHKConnect: 0,
  },
  {
    no: "0105",
    name_en: "ASSOCIATED INTERNATIONAL HOTELS LTD",
    name_zh: "凱聯國際酒店",
    isHKConnect: 0,
  },
  {
    no: "0106",
    name_en: "LANDSEA GREEN MANAGEMENT LIMITED",
    name_zh: "朗詩綠色管理",
    isHKConnect: 0,
  },
  {
    no: "0107",
    name_en: "SICHUAN EXPRESSWAY COMPANY LIMITED",
    name_zh: "四川成渝高速公路",
    isHKConnect: 1,
  },
  {
    no: "0108",
    name_en: "GR PROPERTIES LIMITED",
    name_zh: "國銳地產",
    isHKConnect: 0,
  },
  {
    no: "0110",
    name_en: "CHINA FORTUNE HOLDINGS LIMITED",
    name_zh: "中國長遠",
    isHKConnect: 0,
  },
  {
    no: "0111",
    name_en: "CINDA INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "信達國際控股",
    isHKConnect: 0,
  },
  {
    no: "0113",
    name_en: "DICKSON CONCEPTS (INTERNATIONAL) LIMITED",
    name_zh: "迪生創建",
    isHKConnect: 0,
  },
  {
    no: "0114",
    name_en: "HERALD HOLDINGS LTD",
    name_zh: "HERALD HOLD",
    isHKConnect: 0,
  },
  {
    no: "0115",
    name_en: "GRAND FIELD GROUP HOLDINGS LIMITED",
    name_zh: "鈞濠集團",
    isHKConnect: 0,
  },
  {
    no: "0116",
    name_en: "CHOW SANG SANG HOLDINGS INTERNATIONAL LIMITED",
    name_zh: "周生生",
    isHKConnect: 1,
  },
  {
    no: "0117",
    name_en: "TIANLI HOLDINGS GROUP LIMITED",
    name_zh: "天利控股集團",
    isHKConnect: 0,
  },
  {
    no: "0118",
    name_en: "COSMOS MACHINERY ENTERPRISES LIMITED",
    name_zh: "大同機械",
    isHKConnect: 0,
  },
  {
    no: "0119",
    name_en: "POLY PROPERTY GROUP CO., LIMITED",
    name_zh: "保利置業集團",
    isHKConnect: 1,
  },
  {
    no: "0120",
    name_en: "COSMOPOLITAN INTERNATIONAL HOLDINGS LTD",
    name_zh: "COSMOPOL INT'L",
    isHKConnect: 1,
  },
  {
    no: "0122",
    name_en: "CROCODILE GARMENTS LIMITED",
    name_zh: "鱷魚恤",
    isHKConnect: 0,
  },
  {
    no: "0123",
    name_en: "YUEXIU PROPERTY COMPANY LIMITED",
    name_zh: "越秀地產",
    isHKConnect: 1,
  },
  {
    no: "0124",
    name_en: "GUANGDONG LAND HOLDINGS LIMITED",
    name_zh: "粵海置地",
    isHKConnect: 0,
  },
  {
    no: "0125",
    name_en: "SUN HING VISION GROUP HOLDINGS LIMITED",
    name_zh: "新興光學",
    isHKConnect: 0,
  },
  {
    no: "0126",
    name_en: "CARRIANNA GROUP HOLDINGS COMPANY LIMITED",
    name_zh: "佳寧娜",
    isHKConnect: 0,
  },
  {
    no: "0127",
    name_en: "CHINESE ESTATES HOLDINGS LIMITED",
    name_zh: "華人置業",
    isHKConnect: 1,
  },
  {
    no: "0128",
    name_en: "ENM HOLDINGS LIMITED",
    name_zh: "安寧控股",
    isHKConnect: 0,
  },
  {
    no: "0129",
    name_en: "ASIA STANDARD INTERNATIONAL GROUP LTD",
    name_zh: "泛海集團",
    isHKConnect: 0,
  },
  {
    no: "0130",
    name_en: "MOISELLE INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "慕詩國際",
    isHKConnect: 0,
  },
  {
    no: "0131",
    name_en: "CHEUK NANG (HOLDINGS) LIMITED",
    name_zh: "卓能(集團)",
    isHKConnect: 0,
  },
  {
    no: "0132",
    name_en: "CHINA INVESTMENTS HOLDINGS LIMITED",
    name_zh: "中國興業控股",
    isHKConnect: 0,
  },
  {
    no: "0133",
    name_en: "CHINA MERCHANTS CHINA DIRECT INVESTMENTS LIMITED",
    name_zh: "招商局中國基金",
    isHKConnect: 0,
  },
  {
    no: "0135",
    name_en: "KUNLUN ENERGY COMPANY LIMITED",
    name_zh: "昆侖能源",
    isHKConnect: 1,
  },
  {
    no: "0136",
    name_en: "CHINA RUYI HOLDINGS LIMITED",
    name_zh: "中國儒意",
    isHKConnect: 1,
  },
  {
    no: "0137",
    name_en: "JINHUI HOLDINGS COMPANY LIMITED",
    name_zh: "金輝集團",
    isHKConnect: 0,
  },
  {
    no: "0138",
    name_en: "CCT FORTIS HOLDINGS LIMITED",
    name_zh: "中建富通",
    isHKConnect: 0,
  },
  {
    no: "0139",
    name_en: "CENTRAL WEALTH GROUP HOLDINGS LIMITED",
    name_zh: "中達集團控股",
    isHKConnect: 0,
  },
  {
    no: "0141",
    name_en: "SKY CHINAFORTUNE HOLDINGS GROUP LIMITED",
    name_zh: "天禧海嘉控股",
    isHKConnect: 0,
  },
  {
    no: "0142",
    name_en: "FIRST PACIFIC CO. LTD.",
    name_zh: "第一太平",
    isHKConnect: 1,
  },
  {
    no: "0143",
    name_en: "GUOAN INTERNATIONAL LIMITED",
    name_zh: "國安國際",
    isHKConnect: 0,
  },
  {
    no: "0144",
    name_en: "CHINA MERCHANTS PORT HOLDINGS COMPANY LIMITED",
    name_zh: "招商局港口",
    isHKConnect: 1,
  },
  {
    no: "0145",
    name_en: "CCIAM FUTURE ENERGY LIMITED",
    name_zh: "信能低碳",
    isHKConnect: 0,
  },
  {
    no: "0146",
    name_en: "TAI PING CARPETS INTERNATIONAL LTD",
    name_zh: "TAI PING CARPET",
    isHKConnect: 0,
  },
  {
    no: "0147",
    name_en: "INTERNATIONAL BUSINESS SETTLEMENT HOLDINGS LIMITED",
    name_zh: "國際商業結算",
    isHKConnect: 0,
  },
  {
    no: "0148",
    name_en: "KINGBOARD HOLDINGS LIMITED",
    name_zh: "建滔集團",
    isHKConnect: 1,
  },
  {
    no: "0149",
    name_en: "CHINA AGRI-PRODUCTS EXCHANGE LIMITED",
    name_zh: "中國農產品交易",
    isHKConnect: 0,
  },
  {
    no: "0150",
    name_en: "HYPEBEAST LIMITED",
    name_zh: "HYPEBEAST LIMITED",
    isHKConnect: 0,
  },
  {
    no: "0151",
    name_en: "WANT WANT CHINA HOLDINGS LIMITED",
    name_zh: "中國旺旺",
    isHKConnect: 1,
  },
  {
    no: "0152",
    name_en: "SHENZHEN INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "深圳國際",
    isHKConnect: 1,
  },
  {
    no: "0153",
    name_en: "CHINA SAITE GROUP COMPANY LIMITED",
    name_zh: "中國賽特",
    isHKConnect: 0,
  },
  {
    no: "0154",
    name_en: "BEIJING ENTERPRISES ENVIRONMENT GROUP LIMITED",
    name_zh: "北京控股環境集團",
    isHKConnect: 0,
  },
  {
    no: "0156",
    name_en: "LIPPO CHINA RESOURCES LIMITED",
    name_zh: "力寶華潤",
    isHKConnect: 0,
  },
  {
    no: "0157",
    name_en: "NATURAL BEAUTY BIO-TECHNOLOGY LIMITED",
    name_zh: "自然美",
    isHKConnect: 0,
  },
  {
    no: "0158",
    name_en: "MELBOURNE ENTERPRISES LTD.",
    name_zh: "萬邦投資",
    isHKConnect: 0,
  },
  {
    no: "0159",
    name_en: "BROCKMAN MINING LIMITED",
    name_zh: "布萊克萬礦業",
    isHKConnect: 0,
  },
  {
    no: "0160",
    name_en: "HON KWOK LAND INVESTMENT COMPANY,LIMITED",
    name_zh: "漢國置業",
    isHKConnect: 0,
  },
  {
    no: "0162",
    name_en: "CENTURY GINWA RETAIL HOLDINGS LIMITED",
    name_zh: "世紀金花",
    isHKConnect: 0,
  },
  {
    no: "0163",
    name_en: "EMPEROR INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "英皇國際",
    isHKConnect: 1,
  },
  {
    no: "0164",
    name_en: "CHINA BAOLI TECHNOLOGIES HOLDINGS LIMITED",
    name_zh: "中國寶力科技",
    isHKConnect: 0,
  },
  {
    no: "0165",
    name_en: "CHINA EVERBRIGHT LIMITED",
    name_zh: "中國光大控股",
    isHKConnect: 1,
  },
  {
    no: "0166",
    name_en: "NEW TIMES ENERGY CORPORATION LIMITED",
    name_zh: "新時代能源",
    isHKConnect: 0,
  },
  {
    no: "0167",
    name_en: "IDT INTERNATIONAL LIMITED",
    name_zh: "IDT INT'L",
    isHKConnect: 0,
  },
  {
    no: "0168",
    name_en: "TSINGTAO BREWERY COMPANY LIMITED",
    name_zh: "青島啤酒股份",
    isHKConnect: 1,
  },
  {
    no: "0169",
    name_en: "WANDA HOTEL DEVELOPMENT COMPANY LIMITED",
    name_zh: "萬達酒店發展",
    isHKConnect: 0,
  },
  {
    no: "0171",
    name_en: "SILVER GRANT INTERNATIONAL HOLDINGS GROUP LIMITED",
    name_zh: "銀建國際",
    isHKConnect: 0,
  },
  {
    no: "0173",
    name_en: "K. WAH INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "嘉華國際",
    isHKConnect: 1,
  },
  {
    no: "0174",
    name_en: "GEMINI INVESTMENTS (HOLDINGS) LIMITED",
    name_zh: "盛洋投資",
    isHKConnect: 0,
  },
  {
    no: "0175",
    name_en: "GEELY AUTOMOBILE HOLDINGS LIMITED",
    name_zh: "吉利汽車",
    isHKConnect: 1,
  },
  {
    no: "0176",
    name_en: "SUPERACTIVE GROUP COMPANY LIMITED",
    name_zh: "先機企業集團",
    isHKConnect: 0,
  },
  {
    no: "0177",
    name_en: "JIANGSU EXPRESSWAY COMPANY LIMITED",
    name_zh: "江蘇寧滬高速公路",
    isHKConnect: 1,
  },
  {
    no: "0178",
    name_en: "SA SA INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "莎莎國際",
    isHKConnect: 1,
  },
  {
    no: "0179",
    name_en: "JOHNSON ELECTRIC HOLDINGS LIMITED",
    name_zh: "德昌電機控股",
    isHKConnect: 1,
  },
  {
    no: "0180",
    name_en: "KADER HOLDINGS CO. LTD",
    name_zh: "開達集團",
    isHKConnect: 0,
  },
  {
    no: "0181",
    name_en: "FUJIAN HOLDINGS LIMITED",
    name_zh: "閩港控股",
    isHKConnect: 0,
  },
  {
    no: "0182",
    name_en: "CONCORD NEW ENERGY GROUP LIMITED",
    name_zh: "協合新能源",
    isHKConnect: 0,
  },
  {
    no: "0183",
    name_en: "WINFULL GROUP HOLDINGS LIMITED",
    name_zh: "宏輝集團",
    isHKConnect: 0,
  },
  {
    no: "0184",
    name_en: "KECK SENG INVESTMENTS (HONG KONG) LTD",
    name_zh: "激成投資",
    isHKConnect: 0,
  },
  {
    no: "0185",
    name_en: "ZENSUN ENTERPRISES LIMITED",
    name_zh: "正商實業",
    isHKConnect: 1,
  },
  {
    no: "0186",
    name_en: "NIMBLE HOLDINGS COMPANY LIMITED",
    name_zh: "敏捷控股",
    isHKConnect: 0,
  },
  {
    no: "0187",
    name_en: "BEIJING JINGCHENG MACHINERY ELECTRIC COMPANY LIMITED",
    name_zh: "京城機電股份",
    isHKConnect: 1,
  },
  {
    no: "0188",
    name_en: "SUNWAH KINGSWAY CAPITAL HOLDINGS LIMITED",
    name_zh: "新華匯富金融",
    isHKConnect: 0,
  },
  {
    no: "0189",
    name_en: "DONGYUE GROUP LIMITED",
    name_zh: "東岳集團",
    isHKConnect: 1,
  },
  {
    no: "0191",
    name_en: "LAI SUN GARMENT (INTERNATIONAL) LIMITED",
    name_zh: "麗新國際",
    isHKConnect: 0,
  },
  {
    no: "0193",
    name_en: "CAPITAL ESTATE LIMITED",
    name_zh: "冠中地產",
    isHKConnect: 0,
  },
  {
    no: "0194",
    name_en: "LIU CHONG HING INVESTMENT LTD",
    name_zh: "廖創興企業",
    isHKConnect: 0,
  },
  {
    no: "0195",
    name_en: "GREENTECH TECHNOLOGY INTERNATIONAL LIMITED",
    name_zh: "綠科科技國際",
    isHKConnect: 0,
  },
  {
    no: "0196",
    name_en: "HONGHUA GROUP LIMITED",
    name_zh: "宏華集團",
    isHKConnect: 0,
  },
  {
    no: "0197",
    name_en: "HENG TAI CONSUMABLES GROUP LIMITED",
    name_zh: "亨泰",
    isHKConnect: 0,
  },
  {
    no: "0199",
    name_en: "ITC PROPERTIES GROUP LIMITED",
    name_zh: "德祥地產",
    isHKConnect: 0,
  },
  {
    no: "0200",
    name_en: "MELCO INTERNATIONAL DEVELOPMENT LIMITED",
    name_zh: "新濠國際發展",
    isHKConnect: 1,
  },
  {
    no: "0201",
    name_en: "MAGNIFICENT HOTEL INVESTMENTS LIMITED",
    name_zh: "華大酒店",
    isHKConnect: 0,
  },
  {
    no: "0202",
    name_en: "EVERCHINA INT'L HOLDINGS COMPANY LIMITED",
    name_zh: "潤中國際控股",
    isHKConnect: 0,
  },
  {
    no: "0204",
    name_en: "CHINA INVESTMENT DEVELOPMENT LIMITED",
    name_zh: "中國投資開發",
    isHKConnect: 0,
  },
  {
    no: "0205",
    name_en: "SEEC MEDIA GROUP LIMITED",
    name_zh: "財訊傳媒",
    isHKConnect: 0,
  },
  {
    no: "0206",
    name_en: "CMIC OCEAN EN-TECH HOLDING CO., LTD.",
    name_zh: "華商國際海洋控股",
    isHKConnect: 0,
  },
  {
    no: "0207",
    name_en: "JOY CITY PROPERTY LIMITED",
    name_zh: "大悅城地產",
    isHKConnect: 1,
  },
  {
    no: "0209",
    name_en: "WINSHINE SCIENCE COMPANY LIMITED",
    name_zh: "瀛晟科學",
    isHKConnect: 0,
  },
  {
    no: "0210",
    name_en: "DAPHNE INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "達芙妮國際",
    isHKConnect: 0,
  },
  {
    no: "0211",
    name_en: "STYLAND HOLDINGS LIMITED",
    name_zh: "STYLAND HOLD",
    isHKConnect: 0,
  },
  {
    no: "0212",
    name_en: "NANYANG HOLDINGS LIMITED",
    name_zh: "NANYANG HOLD",
    isHKConnect: 0,
  },
  {
    no: "0213",
    name_en: "NATIONAL ELECTRONICS HOLDINGS LTD",
    name_zh: "NATIONAL ELEC H",
    isHKConnect: 0,
  },
  {
    no: "0214",
    name_en: "ASIA ORIENT HOLDINGS LIMITED",
    name_zh: "匯漢控股",
    isHKConnect: 0,
  },
  {
    no: "0215",
    name_en: "HUTCHISON TELECOMMUNICATIONS HONG KONG HOLDINGS LIMITED",
    name_zh: "和記電訊香港",
    isHKConnect: 1,
  },
  {
    no: "0216",
    name_en: "CHINNEY INVESTMENTS LTD",
    name_zh: "建業實業",
    isHKConnect: 0,
  },
  {
    no: "0217",
    name_en: "CHINA CHENGTONG DEVELOPMENT GROUP LIMITED",
    name_zh: "中國誠通發展集團",
    isHKConnect: 0,
  },
  {
    no: "0218",
    name_en: "SHENWAN HONGYUAN (H.K.) LIMITED",
    name_zh: "申萬宏源香港",
    isHKConnect: 0,
  },
  {
    no: "0219",
    name_en: "SHUN HO PROPERTY INVESTMENTS LIMITED",
    name_zh: "順豪物業",
    isHKConnect: 0,
  },
  {
    no: "0220",
    name_en: "UNI-PRESIDENT CHINA HOLDINGS LTD.",
    name_zh: "統一企業中國",
    isHKConnect: 1,
  },
  {
    no: "0222",
    name_en: "MIN XIN HOLDINGS LIMITED",
    name_zh: "閩信集團",
    isHKConnect: 0,
  },
  {
    no: "0223",
    name_en: "ELIFE HOLDINGS LIMITED",
    name_zh: "易生活控股",
    isHKConnect: 0,
  },
  {
    no: "0224",
    name_en: "PIONEER GLOBAL GROUP LIMITED",
    name_zh: "建生國際",
    isHKConnect: 0,
  },
  {
    no: "0225",
    name_en: "POKFULAM DEVELOPMENT CO. LTD",
    name_zh: "博富臨置業",
    isHKConnect: 0,
  },
  { no: "0226", name_en: "LIPPO LIMITED", name_zh: "力寶", isHKConnect: 0 },
  {
    no: "0227",
    name_en: "FIRST SHANGHAI INVESTMENTS LIMITED",
    name_zh: "第一上海",
    isHKConnect: 0,
  },
  {
    no: "0228",
    name_en: "CHINA ENERGY DEVELOPMENT HOLDINGS LTD",
    name_zh: "中能控股",
    isHKConnect: 0,
  },
  {
    no: "0229",
    name_en: "RAYMOND INDUSTRIAL LIMITED",
    name_zh: "利民實業",
    isHKConnect: 0,
  },
  {
    no: "0230",
    name_en: "MINMETALS LAND LIMITED",
    name_zh: "五礦地產",
    isHKConnect: 0,
  },
  {
    no: "0231",
    name_en: "PING AN SECURITIES GROUP (HOLDINGS) LIMITED",
    name_zh: "平安證券集團控股",
    isHKConnect: 0,
  },
  {
    no: "0232",
    name_en: "CONTINENTAL AEROSPACE TECHNOLOGIES HOLDING LIMITED",
    name_zh: "大陸航空科技控股",
    isHKConnect: 0,
  },
  {
    no: "0234",
    name_en: "NEW CENTURY GROUP HONG KONG LIMITED",
    name_zh: "新世紀集團",
    isHKConnect: 0,
  },
  {
    no: "0235",
    name_en: "CHINA STRATEGIC HOLDINGS LIMITED",
    name_zh: "中策集團",
    isHKConnect: 0,
  },
  {
    no: "0236",
    name_en: "SAN MIGUEL BREWERY HONG KONG LIMITED",
    name_zh: "香港生力啤",
    isHKConnect: 0,
  },
  {
    no: "0237",
    name_en: "SAFETY GODOWN CO. LTD",
    name_zh: "安全貨倉",
    isHKConnect: 0,
  },
  {
    no: "0239",
    name_en: "PAK FAH YEOW INTERNATIONAL LIMITED",
    name_zh: "白花油",
    isHKConnect: 0,
  },
  {
    no: "0240",
    name_en: "BUILD KING HOLDINGS LIMITED",
    name_zh: "利基控股",
    isHKConnect: 0,
  },
  {
    no: "0241",
    name_en: "ALIBABA HEALTH INFORMATION TECHNOLOGY LIMITED",
    name_zh: "阿里健康",
    isHKConnect: 1,
  },
  {
    no: "0242",
    name_en: "SHUN TAK HOLDINGS LIMITED",
    name_zh: "信德集團",
    isHKConnect: 1,
  },
  {
    no: "0243",
    name_en: "QPL INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "QPL INT'L",
    isHKConnect: 0,
  },
  {
    no: "0244",
    name_en: "THE SINCERE COMPANY, LIMITED",
    name_zh: "先施",
    isHKConnect: 0,
  },
  {
    no: "0245",
    name_en: "CHINA VERED FINANCIAL HOLDING CORPORATION LIMITED",
    name_zh: "中薇金融",
    isHKConnect: 1,
  },
  {
    no: "0247",
    name_en: "TSIM SHA TSUI PROPERTIES LTD",
    name_zh: "TST PROPERTIES",
    isHKConnect: 0,
  },
  {
    no: "0248",
    name_en: "HKC INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "香港通訊國際控股",
    isHKConnect: 0,
  },
  {
    no: "0250",
    name_en: "SINO-I TECHNOLOGY LIMITED",
    name_zh: "中國數碼信息",
    isHKConnect: 0,
  },
  {
    no: "0251",
    name_en: "SEA HOLDINGS LIMITED",
    name_zh: "爪哇控股",
    isHKConnect: 0,
  },
  {
    no: "0252",
    name_en: "SOUTHEAST ASIA PROPERTIES & FINANCE LTD",
    name_zh: "華信地產財務",
    isHKConnect: 0,
  },
  {
    no: "0253",
    name_en: "SHUN HO HOLDINGS LIMITED",
    name_zh: "順豪控股",
    isHKConnect: 0,
  },
  {
    no: "0254",
    name_en: "NATIONAL UNITED RESOURCES HOLDINGS LIMITED",
    name_zh: "國家聯合資源",
    isHKConnect: 0,
  },
  {
    no: "0255",
    name_en: "LUNG KEE (BERMUDA) HOLDINGS LIMITED",
    name_zh: "龍記集團",
    isHKConnect: 0,
  },
  {
    no: "0256",
    name_en: "CITYCHAMP WATCH & JEWELLERY GROUP LIMITED",
    name_zh: "冠城鐘錶珠寶",
    isHKConnect: 1,
  },
  {
    no: "0257",
    name_en: "CHINA EVERBRIGHT ENVIRONMENT GROUP LIMITED",
    name_zh: "光大環境",
    isHKConnect: 1,
  },
  {
    no: "0258",
    name_en: "TOMSON GROUP LIMITED",
    name_zh: "湯臣集團",
    isHKConnect: 0,
  },
  {
    no: "0259",
    name_en: "YEEBO (INTERNATIONAL HOLDINGS) LIMITED",
    name_zh: "億都(國際控股)",
    isHKConnect: 0,
  },
  {
    no: "0260",
    name_en: "AVIC JOY HOLDINGS (HK) LIMITED",
    name_zh: "幸福控股",
    isHKConnect: 0,
  },
  {
    no: "0261",
    name_en: "GBA HOLDINGS LIMITED",
    name_zh: "GBA集團",
    isHKConnect: 0,
  },
  {
    no: "0262",
    name_en: "DESON DEVELOPMENT INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "迪臣發展國際",
    isHKConnect: 0,
  },
  {
    no: "0264",
    name_en: "CHINA INTERNATIONAL DEVELOPMENT CORPORATION LIMITED",
    name_zh: "中聯發展控股",
    isHKConnect: 0,
  },
  {
    no: "0265",
    name_en: "ORIENT VICTORY SMART URBAN SERVICES HOLDING LIMITED",
    name_zh: "東勝智慧城市服務",
    isHKConnect: 0,
  },
  {
    no: "0266",
    name_en: "TIAN TECK LAND LTD",
    name_zh: "天德地產",
    isHKConnect: 0,
  },
  { no: "0267", name_en: "CITIC LIMITED", name_zh: "中信股份", isHKConnect: 1 },
  {
    no: "0268",
    name_en: "KINGDEE INTERNATIONAL SOFTWARE GROUP COMPANY LIMITED",
    name_zh: "金蝶國際",
    isHKConnect: 1,
  },
  {
    no: "0269",
    name_en: "CHINA RESOURCES AND TRANSPORTATION GROUP LIMITED",
    name_zh: "中國資源交通",
    isHKConnect: 0,
  },
  {
    no: "0270",
    name_en: "GUANGDONG INVESTMENT LIMITED",
    name_zh: "粵海投資",
    isHKConnect: 1,
  },
  {
    no: "0271",
    name_en: "ASIASEC PROPERTIES LIMITED",
    name_zh: "亞證地產",
    isHKConnect: 0,
  },
  {
    no: "0272",
    name_en: "SHUI ON LAND LIMITED",
    name_zh: "瑞安房地產",
    isHKConnect: 1,
  },
  {
    no: "0273",
    name_en: "MASON GROUP HOLDINGS LIMITED",
    name_zh: "茂宸集團",
    isHKConnect: 0,
  },
  {
    no: "0274",
    name_en: "RENAISSANCE ASIA SILK ROAD GROUP LIMITED",
    name_zh: "復興亞洲",
    isHKConnect: 0,
  },
  {
    no: "0276",
    name_en: "MONGOLIA ENERGY CORPORATION LIMITED",
    name_zh: "蒙古能源",
    isHKConnect: 0,
  },
  {
    no: "0277",
    name_en: "TERN PROPERTIES CO. LTD",
    name_zh: "太興置業",
    isHKConnect: 0,
  },
  {
    no: "0278",
    name_en: "WAH HA REALTY CO. LTD",
    name_zh: "華廈置業",
    isHKConnect: 0,
  },
  {
    no: "0279",
    name_en: "ARTA TECHFIN CORPORATION LIMITED",
    name_zh: "裕承科金",
    isHKConnect: 1,
  },
  {
    no: "0280",
    name_en: "KING FOOK HOLDINGS LTD",
    name_zh: "景福集團",
    isHKConnect: 0,
  },
  {
    no: "0282",
    name_en: "NEXT DIGITAL LIMITED",
    name_zh: "壹傳媒",
    isHKConnect: 0,
  },
  {
    no: "0285",
    name_en: "BYD ELECTRONIC (INTERNATIONAL) COMPANY LIMITED",
    name_zh: "比亞迪電子",
    isHKConnect: 1,
  },
  {
    no: "0286",
    name_en: "AIDIGONG MATERNAL & CHILD HEALTH LIMITED",
    name_zh: "愛帝宮",
    isHKConnect: 0,
  },
  {
    no: "0287",
    name_en: "WINFAIR INVESTMENT CO. LTD",
    name_zh: "永發置業",
    isHKConnect: 0,
  },
  {
    no: "0288",
    name_en: "WH GROUP LIMITED",
    name_zh: "萬洲國際",
    isHKConnect: 1,
  },
  {
    no: "0289",
    name_en: "WING ON COMPANY INTERNATIONAL LIMITED",
    name_zh: "WING ON CO",
    isHKConnect: 0,
  },
  {
    no: "0290",
    name_en: "CHINA FORTUNE FINANCIAL GROUP LIMITED",
    name_zh: "中國富強金融",
    isHKConnect: 0,
  },
  {
    no: "0291",
    name_en: "CHINA RESOURCES BEER (HOLDINGS) COMPANY LIMITED",
    name_zh: "華潤啤酒",
    isHKConnect: 1,
  },
  {
    no: "0292",
    name_en: "ASIA STANDARD HOTEL GROUP LIMITED",
    name_zh: "泛海酒店",
    isHKConnect: 0,
  },
  {
    no: "0293",
    name_en: "CATHAY PACIFIC AIRWAYS LTD",
    name_zh: "國泰航空",
    isHKConnect: 1,
  },
  {
    no: "0294",
    name_en: "YANGTZEKIANG GARMENT LIMITED",
    name_zh: "長江製衣",
    isHKConnect: 0,
  },
  {
    no: "0295",
    name_en: "KONG SUN HOLDINGS LIMITED",
    name_zh: "江山控股",
    isHKConnect: 1,
  },
  {
    no: "0296",
    name_en: "EMPEROR ENTERTAINMENT HOTEL LIMITED",
    name_zh: "英皇娛樂酒店",
    isHKConnect: 0,
  },
  {
    no: "0297",
    name_en: "SINOFERT HOLDINGS LIMITED",
    name_zh: "中化化肥",
    isHKConnect: 1,
  },
  {
    no: "0298",
    name_en: "CHUANG'S CHINA INVESTMENTS LIMITED",
    name_zh: "莊士中國",
    isHKConnect: 0,
  },
  {
    no: "0299",
    name_en: "GLORY SUN LAND GROUP LIMITED",
    name_zh: "寶新置地",
    isHKConnect: 1,
  },
  {
    no: "0301",
    name_en: "SANVO FINE CHEMICALS GROUP LIMITED",
    name_zh: "三和精化",
    isHKConnect: 0,
  },
  {
    no: "0302",
    name_en: "CMGE TECHNOLOGY GROUP LIMITED",
    name_zh: "中手游",
    isHKConnect: 1,
  },
  {
    no: "0303",
    name_en: "VTECH HOLDINGS LIMITED",
    name_zh: "VTECH HOLDINGS",
    isHKConnect: 1,
  },
  {
    no: "0305",
    name_en: "WULING MOTORS HOLDINGS LIMITED",
    name_zh: "五菱汽車",
    isHKConnect: 0,
  },
  {
    no: "0306",
    name_en: "KWOON CHUNG BUS HOLDINGS LIMITED",
    name_zh: "冠忠巴士集團",
    isHKConnect: 0,
  },
  {
    no: "0308",
    name_en: "CHINA TRAVEL INTERNATIONAL INVESTMENT HONG KONG LIMITED",
    name_zh: "香港中旅",
    isHKConnect: 1,
  },
  {
    no: "0309",
    name_en: "XINHUA NEWS MEDIA HOLDINGS LIMITED",
    name_zh: "新華通訊頻媒",
    isHKConnect: 0,
  },
  {
    no: "0310",
    name_en: "PROSPERITY INVESTMENT HOLDINGS LIMITED",
    name_zh: "嘉進投資國際",
    isHKConnect: 0,
  },
  {
    no: "0311",
    name_en: "LUEN THAI HOLDINGS LIMITED",
    name_zh: "聯泰控股",
    isHKConnect: 0,
  },
  {
    no: "0312",
    name_en: "SHIRBLE DEPARTMENT STORE HOLDINGS (CHINA) LIMITED",
    name_zh: "歲寶百貨",
    isHKConnect: 0,
  },
  {
    no: "0313",
    name_en: "RICHLY FIELD CHINA DEVLOPMENT LIMITED",
    name_zh: "裕田中國",
    isHKConnect: 0,
  },
  {
    no: "0315",
    name_en: "SMARTONE TELECOMMUNICATIONS HOLDINGS LIMITED",
    name_zh: "數碼通電訊",
    isHKConnect: 1,
  },
  {
    no: "0316",
    name_en: "ORIENT OVERSEAS (INTERNATIONAL) LIMITED",
    name_zh: "東方海外國際",
    isHKConnect: 1,
  },
  {
    no: "0317",
    name_en: "CSSC OFFSHORE & MARINE ENGINEERING (GROUP) COMPANY LIMITED",
    name_zh: "中船防務",
    isHKConnect: 1,
  },
  {
    no: "0318",
    name_en: "VONGROUP LIMITED",
    name_zh: "黃河實業",
    isHKConnect: 0,
  },
  {
    no: "0320",
    name_en: "COMPUTIME GROUP LIMITED",
    name_zh: "金寶通",
    isHKConnect: 0,
  },
  {
    no: "0321",
    name_en: "TEXWINCA HOLDINGS LIMITED",
    name_zh: "德永佳集團",
    isHKConnect: 1,
  },
  {
    no: "0322",
    name_en: "TINGYI (CAYMAN ISLANDS) HOLDING CORP.",
    name_zh: "康師傅控股",
    isHKConnect: 1,
  },
  {
    no: "0323",
    name_en: "MAANSHAN IRON & STEEL COMPANY LIMITED",
    name_zh: "馬鞍山鋼鐵股份",
    isHKConnect: 1,
  },
  {
    no: "0326",
    name_en: "CHINA STAR ENTERTAINMENT LIMITED",
    name_zh: "中國星集團",
    isHKConnect: 0,
  },
  {
    no: "0327",
    name_en: "PAX GLOBAL TECHNOLOGY LIMITED",
    name_zh: "百富環球",
    isHKConnect: 1,
  },
  {
    no: "0328",
    name_en: "ALCO HOLDINGS LIMITED",
    name_zh: "ALCO HOLDINGS",
    isHKConnect: 0,
  },
  {
    no: "0329",
    name_en: "OCI INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "東建國際",
    isHKConnect: 0,
  },
  {
    no: "0330",
    name_en: "ESPRIT HOLDINGS LIMITED",
    name_zh: "思捷環球",
    isHKConnect: 1,
  },
  {
    no: "0331",
    name_en: "FSE LIFESTYLE SERVICES LIMITED",
    name_zh: "豐盛生活服務",
    isHKConnect: 0,
  },
  {
    no: "0332",
    name_en: "YUAN HENG GAS HOLDINGS LIMITED",
    name_zh: "元亨燃氣",
    isHKConnect: 0,
  },
  {
    no: "0333",
    name_en: "TOP FORM INTERNATIONAL LIMITED",
    name_zh: "黛麗斯國際",
    isHKConnect: 0,
  },
  {
    no: "0334",
    name_en: "CHINA DISPLAY OPTOELECTRONICS TECHNOLOGY HOLDINGS LIMITED",
    name_zh: "華顯光電",
    isHKConnect: 0,
  },
  {
    no: "0335",
    name_en: "UPBEST GROUP LIMITED",
    name_zh: "美建集團",
    isHKConnect: 0,
  },
  {
    no: "0336",
    name_en: "HUABAO INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "華寶國際",
    isHKConnect: 1,
  },
  {
    no: "0337",
    name_en: "GREENLAND HONG KONG HOLDINGS LIMITED",
    name_zh: "綠地香港",
    isHKConnect: 1,
  },
  {
    no: "0338",
    name_en: "SINOPEC SHANGHAI PETROCHEMICAL COMPANY LIMITED",
    name_zh: "上海石油化工股份",
    isHKConnect: 1,
  },
  {
    no: "0339",
    name_en: "CORE ECONOMY INVESTMENT GROUP LIMITED",
    name_zh: "核心經濟投資",
    isHKConnect: 0,
  },
  {
    no: "0340",
    name_en: "TONGGUAN GOLD GROUP LIMITED",
    name_zh: "潼關黃金",
    isHKConnect: 0,
  },
  {
    no: "0341",
    name_en: "CAFE DE CORAL HOLDINGS LTD",
    name_zh: "大家樂集團",
    isHKConnect: 1,
  },
  {
    no: "0342",
    name_en: "NEWOCEAN ENERGY HOLDINGS LIMITED",
    name_zh: "新海能源",
    isHKConnect: 0,
  },
  {
    no: "0343",
    name_en: "CULTURECOM HOLDINGS LIMITED",
    name_zh: "文化傳信",
    isHKConnect: 0,
  },
  {
    no: "0345",
    name_en: "VITASOY INTERNATIONAL HOLDINGS LTD",
    name_zh: "VITASOY INT'L",
    isHKConnect: 1,
  },
  {
    no: "0346",
    name_en: "YANCHANG PETROLEUM INTERNATIONAL LIMITED",
    name_zh: "延長石油國際",
    isHKConnect: 0,
  },
  {
    no: "0347",
    name_en: "ANGANG STEEL COMPANY LIMITED",
    name_zh: "鞍鋼股份",
    isHKConnect: 1,
  },
  {
    no: "0348",
    name_en: "CHINA HEALTHWISE HOLDINGS LIMITED",
    name_zh: "中國智能健康",
    isHKConnect: 0,
  },
  {
    no: "0351",
    name_en: "ASIA ENERGY LOGISTICS GROUP LIMITED",
    name_zh: "亞洲能源物流",
    isHKConnect: 0,
  },
  {
    no: "0352",
    name_en: "FORTUNE SUN (CHINA) HOLDINGS LIMITED",
    name_zh: "富陽",
    isHKConnect: 0,
  },
  {
    no: "0353",
    name_en: "ENERGY INTERNATIONAL INVESTMENTS HOLDINGS LIMITED",
    name_zh: "能源國際投資",
    isHKConnect: 0,
  },
  {
    no: "0354",
    name_en: "CHINASOFT INTERNATIONAL LIMITED",
    name_zh: "中國軟件國際",
    isHKConnect: 1,
  },
  {
    no: "0355",
    name_en: "CENTURY CITY INTERNATIONAL HOLDINGS LTD",
    name_zh: "世紀城市國際",
    isHKConnect: 0,
  },
  {
    no: "0356",
    name_en: "DT CAPITAL LIMITED",
    name_zh: "鼎立資本",
    isHKConnect: 0,
  },
  {
    no: "0357",
    name_en: "HAINAN MEILAN INTERNATIONAL AIRPORT COMPANY LIMITED",
    name_zh: "美蘭空港",
    isHKConnect: 0,
  },
  {
    no: "0358",
    name_en: "JIANGXI COPPER COMPANY LIMITED",
    name_zh: "江西銅業股份",
    isHKConnect: 1,
  },
  {
    no: "0359",
    name_en: "CHINA HAISHENG JUICE HOLDINGS CO., LTD.",
    name_zh: "海升果汁",
    isHKConnect: 0,
  },
  {
    no: "0360",
    name_en: "NEW FOCUS AUTO TECH HOLDINGS LIMITED",
    name_zh: "新焦點",
    isHKConnect: 0,
  },
  {
    no: "0361",
    name_en: "SINO GOLF HOLDINGS LIMITED",
    name_zh: "順龍控股",
    isHKConnect: 0,
  },
  {
    no: "0362",
    name_en: "CHINA ZENITH CHEMICAL GROUP LIMITED",
    name_zh: "中國天化工",
    isHKConnect: 0,
  },
  {
    no: "0363",
    name_en: "SHANGHAI INDUSTRIAL HOLDINGS LIMITED",
    name_zh: "上海實業控股",
    isHKConnect: 1,
  },
  {
    no: "0365",
    name_en: "SINO ICT HOLDINGS LIMITED",
    name_zh: "芯成科技",
    isHKConnect: 0,
  },
  {
    no: "0366",
    name_en: "LUKS GROUP (VIETNAM HOLDINGS) COMPANY LIMITED",
    name_zh: "陸氏集團(越南)",
    isHKConnect: 0,
  },
  {
    no: "0367",
    name_en: "CHUANG'S CONSORTIUM INTERNATIONAL LIMITED",
    name_zh: "莊士機構國際",
    isHKConnect: 0,
  },
  {
    no: "0368",
    name_en: "SUPERLAND GROUP HOLDINGS LIMITED",
    name_zh: "德合集團",
    isHKConnect: 0,
  },
  {
    no: "0369",
    name_en: "WING TAI PROPERTIES LIMITED",
    name_zh: "永泰地產",
    isHKConnect: 0,
  },
  {
    no: "0370",
    name_en: "CHINA BEST GROUP HOLDING LIMITED",
    name_zh: "國華",
    isHKConnect: 0,
  },
  {
    no: "0371",
    name_en: "BEIJING ENTERPRISES WATER GROUP LIMITED",
    name_zh: "北控水務集團",
    isHKConnect: 1,
  },
  {
    no: "0372",
    name_en: "PT INTERNATIONAL DEVELOPMENT CORPORATION LIMITED",
    name_zh: "保德國際發展",
    isHKConnect: 0,
  },
  {
    no: "0373",
    name_en: "ALLIED GROUP LIMITED",
    name_zh: "聯合集團",
    isHKConnect: 0,
  },
  {
    no: "0374",
    name_en: "FOUR SEAS MERCANTILE HOLDINGS LIMITED",
    name_zh: "四洲集團",
    isHKConnect: 0,
  },
  {
    no: "0375",
    name_en: "YGM TRADING LTD",
    name_zh: "YGM TRADING",
    isHKConnect: 0,
  },
  {
    no: "0376",
    name_en: "YUNFENG FINANCIAL GROUP LIMITED",
    name_zh: "雲鋒金融",
    isHKConnect: 0,
  },
  {
    no: "0377",
    name_en: "CHINA HUAJUN GROUP LIMITED",
    name_zh: "中國華君",
    isHKConnect: 0,
  },
  {
    no: "0378",
    name_en: "FDG KINETIC LIMITED",
    name_zh: "五龍動力",
    isHKConnect: 1,
  },
  {
    no: "0379",
    name_en: "CHINA EVER GRAND FINANCIAL LEASING GROUP CO., LTD",
    name_zh: "恆嘉融資租賃",
    isHKConnect: 0,
  },
  {
    no: "0380",
    name_en: "CHINA PIPE GROUP LIMITED",
    name_zh: "中國管業",
    isHKConnect: 0,
  },
  {
    no: "0381",
    name_en: "KIU HUNG INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "僑雄國際",
    isHKConnect: 0,
  },
  {
    no: "0382",
    name_en: "EDVANTAGE GROUP HOLDINGS LIMITED",
    name_zh: "中匯集團",
    isHKConnect: 0,
  },
  {
    no: "0383",
    name_en: "CHINA MEDICAL & HEALTHCARE GROUP LIMITED",
    name_zh: "中國醫療網絡",
    isHKConnect: 0,
  },
  {
    no: "0384",
    name_en: "CHINA GAS HOLDINGS LIMITED",
    name_zh: "中國燃氣",
    isHKConnect: 1,
  },
  {
    no: "0385",
    name_en: "CHINNEY ALLIANCE GROUP LIMITED",
    name_zh: "建聯集團",
    isHKConnect: 0,
  },
  {
    no: "0386",
    name_en: "CHINA PETROLEUM & CHEMICAL CORPORATION",
    name_zh: "中國石油化工股份",
    isHKConnect: 1,
  },
  {
    no: "0387",
    name_en: "LEEPORT (HOLDINGS) LIMITED",
    name_zh: "力豐(集團)",
    isHKConnect: 0,
  },
  {
    no: "0388",
    name_en: "HONG KONG EXCHANGES AND CLEARING LIMITED",
    name_zh: "香港交易所",
    isHKConnect: 1,
  },
  {
    no: "0389",
    name_en: "CHINA TONTINE WINES GROUP LIMITED",
    name_zh: "通天酒業",
    isHKConnect: 0,
  },
  {
    no: "0390",
    name_en: "CHINA RAILWAY GROUP LIMITED",
    name_zh: "中國中鐵",
    isHKConnect: 1,
  },
  {
    no: "0391",
    name_en: "MEI AH ENTERTAINMENT GROUP LIMITED",
    name_zh: "美亞娛樂資訊",
    isHKConnect: 0,
  },
  {
    no: "0392",
    name_en: "BEIJING ENTERPRISES HOLDINGS LIMITED",
    name_zh: "北京控股",
    isHKConnect: 1,
  },
  {
    no: "0393",
    name_en: "GLORIOUS SUN ENTERPRISES LIMITED",
    name_zh: "旭日企業",
    isHKConnect: 0,
  },
  {
    no: "0395",
    name_en: "SMARTAC INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "環球智能控股",
    isHKConnect: 0,
  },
  {
    no: "0396",
    name_en: "HING LEE (HK) HOLDINGS LIMITED",
    name_zh: "興利(香港)控股",
    isHKConnect: 0,
  },
  {
    no: "0397",
    name_en: "POWER FINANCIAL GROUP LIMITED",
    name_zh: "權威金融",
    isHKConnect: 0,
  },
  {
    no: "0398",
    name_en: "ORIENTAL WATCH HOLDINGS LIMITED",
    name_zh: "東方表行集團",
    isHKConnect: 0,
  },
  {
    no: "0399",
    name_en: "INNOVATIVE PHARMACEUTICAL BIOTECH LIMITED",
    name_zh: "領航醫藥生物科技",
    isHKConnect: 0,
  },
  { no: "0400", name_en: "INGDAN, INC.", name_zh: "硬蛋創新", isHKConnect: 1 },
  {
    no: "0401",
    name_en: "WANJIA GROUP HOLDINGS LIMITED",
    name_zh: "萬嘉集團",
    isHKConnect: 0,
  },
  {
    no: "0403",
    name_en: "STARLITE HOLDINGS LIMITED",
    name_zh: "星光集團",
    isHKConnect: 0,
  },
  {
    no: "0405",
    name_en: "YUEXIU REAL ESTATE INVESTMENT TRUST",
    name_zh: "越秀房產信託基金",
    isHKConnect: 0,
  },
  {
    no: "0406",
    name_en: "YAU LEE HOLDINGS LIMITED",
    name_zh: "有利集團",
    isHKConnect: 0,
  },
  {
    no: "0408",
    name_en: "YIP'S CHEMICAL HOLDINGS LIMITED",
    name_zh: "葉氏化工集團",
    isHKConnect: 0,
  },
  {
    no: "0410",
    name_en: "SOHO CHINA LIMITED",
    name_zh: "SOHO中國",
    isHKConnect: 1,
  },
  {
    no: "0411",
    name_en: "LAM SOON (HONG KONG) LIMITED",
    name_zh: "南順(香港)",
    isHKConnect: 0,
  },
  {
    no: "0412",
    name_en: "SHANDONG HI-SPEED HOLDINGS GROUP LIMITED",
    name_zh: "山高控股",
    isHKConnect: 1,
  },
  {
    no: "0413",
    name_en: "SOUTH CHINA HOLDINGS COMPANY LIMITED",
    name_zh: "南華集團控股",
    isHKConnect: 0,
  },
  {
    no: "0416",
    name_en: "BANK OF JINZHOU CO., LTD.",
    name_zh: "錦州銀行",
    isHKConnect: 0,
  },
  {
    no: "0417",
    name_en: "TSE SUI LUEN JEWELLERY (INTERNATIONAL) LIMITED",
    name_zh: "謝瑞麟",
    isHKConnect: 0,
  },
  {
    no: "0418",
    name_en: "FOUNDER HOLDINGS LIMITED",
    name_zh: "方正控股",
    isHKConnect: 0,
  },
  {
    no: "0419",
    name_en: "HUAYI TENCENT ENTERTAINMENT COMPANY LIMITED",
    name_zh: "華誼騰訊娛樂",
    isHKConnect: 1,
  },
  {
    no: "0420",
    name_en: "FOUNTAIN SET (HOLDINGS) LTD",
    name_zh: "福田實業",
    isHKConnect: 0,
  },
  {
    no: "0422",
    name_en: "VIETNAM MANUFACTURING AND EXPORT PROCESSING (HOLDINGS) LIMITED",
    name_zh: "越南製造加工出口",
    isHKConnect: 0,
  },
  {
    no: "0423",
    name_en: "HONG KONG ECONOMIC TIMES HOLDINGS LIMITED",
    name_zh: "經濟日報集團",
    isHKConnect: 0,
  },
  {
    no: "0425",
    name_en: "MINTH GROUP LIMITED",
    name_zh: "敏實集團",
    isHKConnect: 1,
  },
  {
    no: "0426",
    name_en: "ONE MEDIA GROUP LIMITED",
    name_zh: "萬華媒體",
    isHKConnect: 0,
  },
  {
    no: "0428",
    name_en: "COCOON HOLDINGS LIMITED",
    name_zh: "中國天弓控股",
    isHKConnect: 0,
  },
  {
    no: "0430",
    name_en: "ORIENTAL EXPLORER HOLDINGS LIMITED",
    name_zh: "東方興業控股",
    isHKConnect: 0,
  },
  {
    no: "0431",
    name_en: "GREATER CHINA FINANCIAL HOLDINGS LIMITED",
    name_zh: "大中華金融",
    isHKConnect: 0,
  },
  {
    no: "0432",
    name_en: "PACIFIC CENTURY PREMIUM DEVELOPMENTS LIMITED",
    name_zh: "盈大地產",
    isHKConnect: 1,
  },
  {
    no: "0433",
    name_en: "NORTH MINING SHARES COMPANY LIMITED",
    name_zh: "北方礦業",
    isHKConnect: 0,
  },
  {
    no: "0434",
    name_en: "BOYAA INTERACTIVE INTERNATIONAL LIMITED",
    name_zh: "博雅互動",
    isHKConnect: 0,
  },
  {
    no: "0435",
    name_en: "SUNLIGHT REAL ESTATE INVESTMENT TRUST",
    name_zh: "陽光房地產基金",
    isHKConnect: 0,
  },
  {
    no: "0436",
    name_en: "NEW UNIVERSE ENVIRONMENTAL GROUP LIMITED",
    name_zh: "新宇環保",
    isHKConnect: 0,
  },
  {
    no: "0438",
    name_en: "IRICO GROUP NEW ENERGY COMPANY LIMITED",
    name_zh: "彩虹新能源",
    isHKConnect: 0,
  },
  {
    no: "0439",
    name_en: "KUANGCHI SCIENCE LIMITED",
    name_zh: "光啟科學",
    isHKConnect: 1,
  },
  {
    no: "0440",
    name_en: "DAH SING FINANCIAL HOLDINGS LTD",
    name_zh: "大新金融",
    isHKConnect: 1,
  },
  {
    no: "0442",
    name_en: "DOMAINE POWER HOLDINGS LIMITED",
    name_zh: "域能控股",
    isHKConnect: 0,
  },
  {
    no: "0444",
    name_en: "SINCERE WATCH (HONG KONG) LIMITED",
    name_zh: "SINCEREWATCH HK",
    isHKConnect: 0,
  },
  {
    no: "0450",
    name_en: "HUNG HING PRINTING GROUP LIMITED",
    name_zh: "鴻興印刷集團",
    isHKConnect: 0,
  },
  {
    no: "0451",
    name_en: "GCL NEW ENERGY HOLDINGS LIMITED",
    name_zh: "協鑫新能源",
    isHKConnect: 1,
  },
  {
    no: "0455",
    name_en: "TIANDA PHARMACEUTICALS LIMITED",
    name_zh: "天大藥業",
    isHKConnect: 0,
  },
  {
    no: "0456",
    name_en: "NEW CITY DEVELOPMENT GROUP LIMITED",
    name_zh: "新城市建設發展",
    isHKConnect: 0,
  },
  {
    no: "0458",
    name_en: "TRISTATE HOLDINGS LTD",
    name_zh: "聯亞集團",
    isHKConnect: 0,
  },
  {
    no: "0459",
    name_en: "LEGEND UPSTAR HOLDINGS LIMITED",
    name_zh: "鋑聯控股",
    isHKConnect: 0,
  },
  {
    no: "0460",
    name_en: "SIHUAN PHARMACEUTICAL HOLDINGS GROUP LTD.",
    name_zh: "四環醫藥",
    isHKConnect: 1,
  },
  {
    no: "0462",
    name_en: "NATURAL DAIRY (NZ) HOLDINGS LIMITED",
    name_zh: "天然乳品",
    isHKConnect: 0,
  },
  {
    no: "0464",
    name_en: "CHINA OVERSEAS NUOXIN INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "中國海外諾信",
    isHKConnect: 0,
  },
  {
    no: "0465",
    name_en: "FUTONG TECHNOLOGY DEVELOPMENT HOLDINGS LIMITED",
    name_zh: "富通科技",
    isHKConnect: 0,
  },
  {
    no: "0467",
    name_en: "UNITED ENERGY GROUP LIMITED",
    name_zh: "聯合能源集團",
    isHKConnect: 1,
  },
  {
    no: "0468",
    name_en: "GREATVIEW ASEPTIC PACKAGING COMPANY LIMITED",
    name_zh: "紛美包裝",
    isHKConnect: 1,
  },
  { no: "0471", name_en: "SILKWAVE INC", name_zh: "中播數據", isHKConnect: 0 },
  {
    no: "0472",
    name_en: "NEW SILKROAD CULTURALTAINMENT LIMITED",
    name_zh: "新絲路文旅",
    isHKConnect: 0,
  },
  {
    no: "0474",
    name_en: "ACESO LIFE SCIENCE GROUP LIMITED",
    name_zh: "信銘生命科技",
    isHKConnect: 0,
  },
  {
    no: "0475",
    name_en: "CENTRAL DEVELOPMENT HOLDINGS LIMITED",
    name_zh: "中發展控股",
    isHKConnect: 0,
  },
  {
    no: "0476",
    name_en: "EV DYNAMICS (HOLDINGS) LIMITED",
    name_zh: "科軒動力控股",
    isHKConnect: 0,
  },
  {
    no: "0479",
    name_en: "CIL HOLDINGS LIMITED",
    name_zh: "華建控股",
    isHKConnect: 0,
  },
  {
    no: "0480",
    name_en: "HKR INTERNATIONAL LTD",
    name_zh: "香港興業國際",
    isHKConnect: 1,
  },
  {
    no: "0482",
    name_en: "SANDMARTIN INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "聖馬丁國際",
    isHKConnect: 0,
  },
  {
    no: "0483",
    name_en: "BAUHAUS INTERNATIONAL (HOLDINGS) LIMITED",
    name_zh: "包浩斯國際",
    isHKConnect: 0,
  },
  {
    no: "0484",
    name_en: "FORGAME HOLDINGS LIMITED",
    name_zh: "雲遊控股",
    isHKConnect: 0,
  },
  {
    no: "0485",
    name_en: "CHINA SINOSTAR GROUP COMPANY LIMITED",
    name_zh: "中國華星",
    isHKConnect: 0,
  },
  {
    no: "0486",
    name_en: "UNITED COMPANY RUSAL,INTERNATIONAL PUBLIC JOINT-STOCK COMPANY",
    name_zh: "俄鋁",
    isHKConnect: 0,
  },
  {
    no: "0487",
    name_en: "SUCCESS UNIVERSE GROUP LIMITED",
    name_zh: "實德環球",
    isHKConnect: 0,
  },
  {
    no: "0488",
    name_en: "LAI SUN DEVELOPMENT COMPANY LIMITED",
    name_zh: "麗新發展",
    isHKConnect: 1,
  },
  {
    no: "0489",
    name_en: "DONGFENG MOTOR GROUP COMPANY LIMITED",
    name_zh: "東風集團股份",
    isHKConnect: 1,
  },
  {
    no: "0491",
    name_en: "EMPEROR CULTURE GROUP LIMITED",
    name_zh: "英皇文化產業",
    isHKConnect: 0,
  },
  {
    no: "0493",
    name_en: "GOME RETAIL HOLDINGS LIMITED",
    name_zh: "國美零售",
    isHKConnect: 1,
  },
  { no: "0495", name_en: "PALADIN LTD", name_zh: "PALADIN", isHKConnect: 0 },
  {
    no: "0496",
    name_en: "KASEN INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "卡森國際",
    isHKConnect: 1,
  },
  {
    no: "0497",
    name_en: "CSI PROPERTIES LIMITED",
    name_zh: "資本策略地產",
    isHKConnect: 0,
  },
  {
    no: "0498",
    name_en: "BLUE RIVER HOLDINGS LIMITED",
    name_zh: "藍河控股",
    isHKConnect: 0,
  },
  {
    no: "0499",
    name_en: "QINGDAO HOLDINGS INTERNATIONAL LIMITED",
    name_zh: "青島控股",
    isHKConnect: 0,
  },
  {
    no: "0500",
    name_en: "FRONTIER SERVICES GROUP LIMITED",
    name_zh: "先豐服務集團",
    isHKConnect: 0,
  },
  {
    no: "0503",
    name_en: "LANSEN PHARMACEUTICAL HOLDINGS LIMITED",
    name_zh: "朗生醫藥",
    isHKConnect: 0,
  },
  {
    no: "0505",
    name_en: "XINGYE ALLOY MATERIALS GROUP LIMITED",
    name_zh: "興業合金",
    isHKConnect: 0,
  },
  {
    no: "0506",
    name_en: "CHINA FOODS LIMITED",
    name_zh: "中國食品",
    isHKConnect: 1,
  },
  {
    no: "0508",
    name_en: "DINGYI GROUP INVESTMENT LIMITED",
    name_zh: "鼎億集團投資",
    isHKConnect: 1,
  },
  {
    no: "0509",
    name_en: "CENTURY SUNSHINE GROUP HOLDINGS LIMITED",
    name_zh: "世紀陽光",
    isHKConnect: 0,
  },
  {
    no: "0510",
    name_en: "CASH FINANCIAL SERVICES GROUP LIMITED",
    name_zh: "時富金融服務集團",
    isHKConnect: 0,
  },
  {
    no: "0511",
    name_en: "TELEVISION BROADCASTS LIMITED",
    name_zh: "電視廣播",
    isHKConnect: 1,
  },
  {
    no: "0512",
    name_en: "GRAND PHARMACEUTICAL GROUP LIMITED",
    name_zh: "遠大醫藥",
    isHKConnect: 1,
  },
  {
    no: "0513",
    name_en: "CONTINENTAL HOLDINGS LIMITED",
    name_zh: "恆和集團",
    isHKConnect: 0,
  },
  {
    no: "0515",
    name_en: "CHINA SILVER TECHNOLOGY HOLDINGS LIMITED",
    name_zh: "中華銀科技",
    isHKConnect: 0,
  },
  {
    no: "0517",
    name_en: "COSCO SHIPPING INTERNATIONAL (HONG KONG) CO., LTD.",
    name_zh: "中遠海運國際",
    isHKConnect: 1,
  },
  {
    no: "0518",
    name_en: "TUNGTEX (HOLDINGS) CO. LTD",
    name_zh: "同得仕(集團)",
    isHKConnect: 0,
  },
  {
    no: "0519",
    name_en: "APPLIED DEVELOPMENT HOLDINGS LIMITED",
    name_zh: "實力建業",
    isHKConnect: 0,
  },
  {
    no: "0520",
    name_en: "XIABUXIABU CATERING MANAGEMENT (CHINA) HOLDINGS CO., LTD.",
    name_zh: "呷哺呷哺",
    isHKConnect: 1,
  },
  {
    no: "0521",
    name_en: "CWT INTERNATIONAL LIMITED",
    name_zh: "CWT INT'L",
    isHKConnect: 0,
  },
  {
    no: "0522",
    name_en: "ASMPT LIMITED",
    name_zh: "ASM PACIFIC",
    isHKConnect: 1,
  },
  {
    no: "0524",
    name_en: "GREAT WALL TERROIR HOLDINGS LIMITED",
    name_zh: "長城天下",
    isHKConnect: 0,
  },
  {
    no: "0525",
    name_en: "GUANGSHEN RAILWAY COMPANY LIMITED",
    name_zh: "廣深鐵路股份",
    isHKConnect: 1,
  },
  {
    no: "0526",
    name_en: "CHINA AUTOMOBILE NEW RETAIL (HOLDINGS) LIMITED",
    name_zh: "中國汽車新零售",
    isHKConnect: 0,
  },
  {
    no: "0527",
    name_en: "CHINA RUIFENG RENEWABLE ENERGY HOLDINGS LIMITED",
    name_zh: "瑞風新能源",
    isHKConnect: 0,
  },
  {
    no: "0528",
    name_en: "KINGDOM HOLDINGS LIMITED",
    name_zh: "金達控股",
    isHKConnect: 0,
  },
  {
    no: "0529",
    name_en: "SIS INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "SIS INT'L",
    isHKConnect: 0,
  },
  {
    no: "0530",
    name_en: "GOLDIN FINANCIAL HOLDINGS LIMITED",
    name_zh: "高銀金融",
    isHKConnect: 1,
  },
  {
    no: "0531",
    name_en: "SAMSON HOLDING LTD.",
    name_zh: "順誠",
    isHKConnect: 0,
  },
  {
    no: "0532",
    name_en: "WONG'S KONG KING INTERNATIONAL (HOLDINGS) LTD",
    name_zh: "WKK INTL (HOLD)",
    isHKConnect: 0,
  },
  {
    no: "0533",
    name_en: "GOLDLION HOLDINGS LIMITED",
    name_zh: "金利來集團",
    isHKConnect: 0,
  },
  {
    no: "0535",
    name_en: "GEMDALE PROPERTIES AND INVESTMENT CORPORATION LIMITED",
    name_zh: "金地商置",
    isHKConnect: 1,
  },
  {
    no: "0536",
    name_en: "TRADELINK ELECTRONIC COMMERCE LIMITED",
    name_zh: "貿易通",
    isHKConnect: 0,
  },
  {
    no: "0538",
    name_en: "AJISEN (CHINA) HOLDINGS LIMITED",
    name_zh: "味千(中國)",
    isHKConnect: 0,
  },
  {
    no: "0540",
    name_en: "SPEEDY GLOBAL HOLDINGS LIMITED",
    name_zh: "迅捷環球控股",
    isHKConnect: 0,
  },
  {
    no: "0542",
    name_en: "TFG INTERNATIONAL GROUP LIMITED",
    name_zh: "富元國際集團",
    isHKConnect: 0,
  },
  {
    no: "0543",
    name_en: "PACIFIC ONLINE LIMITED",
    name_zh: "太平洋網絡",
    isHKConnect: 0,
  },
  {
    no: "0544",
    name_en: "DAIDO GROUP LIMITED",
    name_zh: "大同集團",
    isHKConnect: 0,
  },
  {
    no: "0546",
    name_en: "FUFENG GROUP LIMITED",
    name_zh: "阜豐集團",
    isHKConnect: 1,
  },
  {
    no: "0547",
    name_en: "DIGITAL DOMAIN HOLDINGS LIMITED",
    name_zh: "數字王國",
    isHKConnect: 1,
  },
  {
    no: "0548",
    name_en: "SHENZHEN EXPRESSWAY CORPORATION LIMITED",
    name_zh: "深圳高速公路股份",
    isHKConnect: 1,
  },
  {
    no: "0550",
    name_en: "KINGKEY INTELLIGENCE CULTURE HOLDINGS LIMITED",
    name_zh: "京基智慧文化",
    isHKConnect: 0,
  },
  {
    no: "0551",
    name_en: "YUE YUEN INDUSTRIAL (HOLDINGS) LIMITED",
    name_zh: "裕元集團",
    isHKConnect: 1,
  },
  {
    no: "0552",
    name_en: "CHINA COMMUNICATIONS SERVICES CORPORATION LIMITED",
    name_zh: "中國通信服務",
    isHKConnect: 1,
  },
  {
    no: "0553",
    name_en: "NANJING PANDA ELECTRONICS COMPANY LIMITED",
    name_zh: "南京熊貓電子股份",
    isHKConnect: 1,
  },
  {
    no: "0554",
    name_en: "HANS ENERGY COMPANY LIMITED",
    name_zh: "漢思能源",
    isHKConnect: 0,
  },
  {
    no: "0556",
    name_en: "PAN ASIA ENVIRONMENTAL PROTECTION GROUP LIMITED",
    name_zh: "泛亞環保",
    isHKConnect: 0,
  },
  {
    no: "0557",
    name_en: "CHINA TIAN YUAN HEALTHCARE GROUP LIMITED",
    name_zh: "天元醫療",
    isHKConnect: 0,
  },
  {
    no: "0558",
    name_en: "L.K. TECHNOLOGY HOLDINGS LIMITED",
    name_zh: "力勁科技",
    isHKConnect: 1,
  },
  {
    no: "0559",
    name_en: "DETAI NEW ENERGY GROUP LIMITED",
    name_zh: "德泰新能源集團",
    isHKConnect: 0,
  },
  {
    no: "0560",
    name_en: "CHU KONG SHIPPING ENTERPRISES (GROUP) COMPANY LIMITED",
    name_zh: "珠江船務",
    isHKConnect: 0,
  },
  {
    no: "0563",
    name_en: "SHANGHAI INDUSTRIAL URBAN DEVELOPMENT GROUP LIMITED",
    name_zh: "上實城市開發",
    isHKConnect: 1,
  },
  {
    no: "0564",
    name_en: "ZHENGZHOU COAL MINING MACHINERY GROUP COMPANY LIMITED",
    name_zh: "鄭煤機",
    isHKConnect: 1,
  },
  {
    no: "0565",
    name_en: "ART GROUP HOLDINGS LIMITED",
    name_zh: "錦藝集團控股",
    isHKConnect: 0,
  },
  {
    no: "0567",
    name_en: "DAISHO MICROLINE HOLDINGS LIMITED",
    name_zh: "大昌微綫集團",
    isHKConnect: 0,
  },
  {
    no: "0568",
    name_en: "SHANDONG MOLONG PETROLEUM MACHINERY COMPANY LIMITED",
    name_zh: "山東墨龍",
    isHKConnect: 1,
  },
  {
    no: "0570",
    name_en: "CHINA TRADITIONAL CHINESE MEDICINE HOLDINGS CO LIMITED",
    name_zh: "中國中藥",
    isHKConnect: 1,
  },
  {
    no: "0571",
    name_en: "ESUN HOLDINGS LIMITED",
    name_zh: "豐德麗控股",
    isHKConnect: 0,
  },
  {
    no: "0572",
    name_en: "FUTURE WORLD HOLDINGS LIMITED",
    name_zh: "未來世界控股",
    isHKConnect: 0,
  },
  {
    no: "0573",
    name_en: "TAO HEUNG HOLDINGS LIMITED",
    name_zh: "稻香控股",
    isHKConnect: 0,
  },
  {
    no: "0574",
    name_en: "PA SHUN INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "百信國際",
    isHKConnect: 0,
  },
  {
    no: "0575",
    name_en: "ENDURANCE RP LIMITED",
    name_zh: "壽康集團",
    isHKConnect: 0,
  },
  {
    no: "0576",
    name_en: "ZHEJIANG EXPRESSWAY CO., LTD.",
    name_zh: "浙江滬杭甬",
    isHKConnect: 1,
  },
  {
    no: "0577",
    name_en: "SOUTH SHORE HOLDINGS LIMITED",
    name_zh: "南岸集團",
    isHKConnect: 0,
  },
  {
    no: "0578",
    name_en: "ROSAN RESOURCES HOLDINGS LIMITED",
    name_zh: "融信資源",
    isHKConnect: 0,
  },
  {
    no: "0579",
    name_en: "BEIJING JINGNENG CLEAN ENERGY CO., LIMITED",
    name_zh: "京能清潔能源",
    isHKConnect: 1,
  },
  {
    no: "0580",
    name_en: "SUN.KING TECHNOLOGY GROUP LIMITED",
    name_zh: "賽晶科技",
    isHKConnect: 0,
  },
  {
    no: "0581",
    name_en: "CHINA ORIENTAL GROUP COMPANY LIMITED",
    name_zh: "中國東方集團",
    isHKConnect: 1,
  },
  {
    no: "0582",
    name_en: "LANDING INTERNATIONAL DEVELOPMENT LIMITED",
    name_zh: "藍鼎國際",
    isHKConnect: 1,
  },
  {
    no: "0583",
    name_en: "GREAT WALL PAN ASIA HOLDINGS LIMITED",
    name_zh: "長城環亞控股",
    isHKConnect: 0,
  },
  {
    no: "0585",
    name_en: "IMAGI INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "意馬國際",
    isHKConnect: 0,
  },
  {
    no: "0586",
    name_en: "CHINA CONCH VENTURE HOLDINGS LIMITED",
    name_zh: "海螺創業",
    isHKConnect: 1,
  },
  {
    no: "0587",
    name_en: "CHINA CONCH ENVIRONMENT PROTECTION HOLDINGS LIMITED",
    name_zh: "海螺環保",
    isHKConnect: 1,
  },
  {
    no: "0588",
    name_en: "BEIJING NORTH STAR COMPANY LIMITED",
    name_zh: "北京北辰實業股份",
    isHKConnect: 1,
  },
  {
    no: "0589",
    name_en: "JIANZHONG CONSTRUCTION DEVELOPMENT LIMITED",
    name_zh: "建中建設",
    isHKConnect: 0,
  },
  {
    no: "0590",
    name_en: "LUK FOOK HOLDINGS (INTERNATIONAL) LIMITED",
    name_zh: "六福集團",
    isHKConnect: 1,
  },
  {
    no: "0591",
    name_en: "CHINA HIGH PRECISION AUTOMATION GROUP LIMITED",
    name_zh: "中國高精密",
    isHKConnect: 0,
  },
  {
    no: "0592",
    name_en: "BOSSINI INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "BOSSINI INT'L",
    isHKConnect: 0,
  },
  {
    no: "0593",
    name_en: "DREAMEAST GROUP LIMITED",
    name_zh: "夢東方",
    isHKConnect: 0,
  },
  {
    no: "0595",
    name_en: "AV CONCEPT HOLDINGS LIMITED",
    name_zh: "AV CONCEPT HOLD",
    isHKConnect: 0,
  },
  {
    no: "0596",
    name_en: "INSPUR INTERNATIONAL LIMITED",
    name_zh: "浪潮國際",
    isHKConnect: 0,
  },
  {
    no: "0598",
    name_en: "SINOTRANS LIMITED",
    name_zh: "中國外運",
    isHKConnect: 1,
  },
  {
    no: "0599",
    name_en: "E. BON HOLDINGS LIMITED",
    name_zh: "怡邦行控股",
    isHKConnect: 0,
  },
  {
    no: "0600",
    name_en: "CHINA INFRASTRUCTURE INVESTMENT LIMITED -NEW",
    name_zh: "中國基建投資",
    isHKConnect: 0,
  },
  {
    no: "0601",
    name_en: "RARE EARTH MAGNESIUM TECHNOLOGY GROUP HOLDINGS LIMITED",
    name_zh: "稀鎂科技",
    isHKConnect: 0,
  },
  {
    no: "0602",
    name_en: "JIAHUA STORES HOLDINGS LIMITED",
    name_zh: "佳華百貨控股",
    isHKConnect: 0,
  },
  {
    no: "0603",
    name_en: "CHINA OIL AND GAS GROUP LIMITED",
    name_zh: "中油燃氣",
    isHKConnect: 0,
  },
  {
    no: "0604",
    name_en: "SHENZHEN INVESTMENT LIMITED",
    name_zh: "深圳控股",
    isHKConnect: 1,
  },
  {
    no: "0605",
    name_en: "CHINA FINANCIAL SERVICES HOLDINGS LIMITED",
    name_zh: "中國金融投資管理",
    isHKConnect: 0,
  },
  {
    no: "0606",
    name_en: "SCE INTELLIGENT COMMERCIAL MANAGEMENT HOLDINGS LIMITED",
    name_zh: "中駿商管",
    isHKConnect: 0,
  },
  {
    no: "0607",
    name_en: "FULLSHARE HOLDINGS LIMITED",
    name_zh: "豐盛控股",
    isHKConnect: 1,
  },
  {
    no: "0608",
    name_en: "HIGH FASHION INTERNATIONAL LIMITED",
    name_zh: "達利國際",
    isHKConnect: 0,
  },
  {
    no: "0609",
    name_en: "TIANDE CHEMICAL HOLDINGS LIMITED",
    name_zh: "天德化工",
    isHKConnect: 0,
  },
  {
    no: "0610",
    name_en: "WAI KEE HOLDINGS LIMITED",
    name_zh: "WAI KEE HOLD",
    isHKConnect: 0,
  },
  {
    no: "0611",
    name_en: "CHINA NUCLEAR ENERGY TECHNOLOGY CORPORATION LIMITED",
    name_zh: "中國核能科技",
    isHKConnect: 0,
  },
  {
    no: "0612",
    name_en: "CHINA INVESTMENT FUND COMPANY LIMITED",
    name_zh: "中國投資基金公司",
    isHKConnect: 1,
  },
  {
    no: "0613",
    name_en: "PLANETREE INTERNATIONAL DEVELOPMENT LIMITED",
    name_zh: "梧桐國際",
    isHKConnect: 0,
  },
  {
    no: "0616",
    name_en: "EMINENCE ENTERPRISE LIMITED",
    name_zh: "高山企業",
    isHKConnect: 0,
  },
  {
    no: "0617",
    name_en: "PALIBURG HOLDINGS LIMITED",
    name_zh: "百利保控股",
    isHKConnect: 0,
  },
  {
    no: "0618",
    name_en: "PEKING UNIVERSITY RESOURCES (HOLDINGS) COMPANY LIMITED",
    name_zh: "北大資源",
    isHKConnect: 0,
  },
  {
    no: "0619",
    name_en: "SOUTH CHINA FINANCIAL HOLDINGS LIMITED",
    name_zh: "南華金融",
    isHKConnect: 0,
  },
  {
    no: "0620",
    name_en: "DTXS SILK ROAD INVESTMENT HOLDINGS COMPANY LIMITED",
    name_zh: "大唐西市",
    isHKConnect: 0,
  },
  {
    no: "0621",
    name_en: "TAUNG GOLD INTERNATIONAL LIMITED",
    name_zh: "壇金礦業",
    isHKConnect: 0,
  },
  {
    no: "0622",
    name_en: "OSHIDORI INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "威華達控股",
    isHKConnect: 0,
  },
  {
    no: "0623",
    name_en: "SINOMEDIA HOLDING LIMITED",
    name_zh: "中視金橋",
    isHKConnect: 0,
  },
  {
    no: "0626",
    name_en: "PUBLIC FINANCIAL HOLDINGS LIMITED",
    name_zh: "大眾金融控股",
    isHKConnect: 0,
  },
  {
    no: "0627",
    name_en: "FULLSUN INTERNATIONAL HOLDINGS GROUP CO., LIMITED",
    name_zh: "福晟國際",
    isHKConnect: 0,
  },
  {
    no: "0628",
    name_en: "GOME FINANCE TECHNOLOGY CO., LTD.",
    name_zh: "國美金融科技",
    isHKConnect: 0,
  },
  {
    no: "0629",
    name_en: "YUE DA INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "悅達國際控股",
    isHKConnect: 0,
  },
  {
    no: "0630",
    name_en: "AMCO UNITED HOLDING LIMITED",
    name_zh: "雋泰控股",
    isHKConnect: 0,
  },
  {
    no: "0631",
    name_en: "SANY HEAVY EQUIPMENT INTERNATIONAL HOLDINGS COMPANY LIMITED",
    name_zh: "三一國際",
    isHKConnect: 1,
  },
  {
    no: "0632",
    name_en: "CHK OIL LIMITED",
    name_zh: "中港石油",
    isHKConnect: 0,
  },
  {
    no: "0633",
    name_en: "CHINA ALL ACCESS (HOLDINGS) LIMITED",
    name_zh: "中國全通",
    isHKConnect: 0,
  },
  {
    no: "0635",
    name_en: "PLAYMATES HOLDINGS LIMITED",
    name_zh: "彩星集團",
    isHKConnect: 0,
  },
  {
    no: "0636",
    name_en: "KERRY LOGISTICS NETWORK LIMITED",
    name_zh: "嘉里物流",
    isHKConnect: 1,
  },
  {
    no: "0637",
    name_en: "LEE KEE HOLDINGS LIMITED",
    name_zh: "利記",
    isHKConnect: 0,
  },
  {
    no: "0638",
    name_en: "KIN YAT HOLDINGS LIMITED",
    name_zh: "建溢集團",
    isHKConnect: 0,
  },
  {
    no: "0639",
    name_en: "SHOUGANG FUSHAN RESOURCES GROUP LIMITED",
    name_zh: "首鋼資源",
    isHKConnect: 1,
  },
  {
    no: "0640",
    name_en: "INFINITY DEVELOPMENT HOLDINGS COMPANY LIMITED",
    name_zh: "星謙發展",
    isHKConnect: 0,
  },
  {
    no: "0641",
    name_en: "CHTC FONG'S INTERNATIONAL COMPANY LIMITED",
    name_zh: "中國恆天立信國際",
    isHKConnect: 0,
  },
  {
    no: "0643",
    name_en: "CARRY WEALTH HOLDINGS LIMITED",
    name_zh: "恆富控股",
    isHKConnect: 0,
  },
  {
    no: "0645",
    name_en: "ARES ASIA LIMITED",
    name_zh: "安域亞洲",
    isHKConnect: 0,
  },
  {
    no: "0646",
    name_en: "CHINA ENVIRONMENTAL TECHNOLOGY HOLDINGS LIMITED",
    name_zh: "中國環保科技",
    isHKConnect: 0,
  },
  {
    no: "0650",
    name_en: "PRODUCTIVE TECHNOLOGIES COMPANY LIMITED",
    name_zh: "普達特科技",
    isHKConnect: 1,
  },
  {
    no: "0651",
    name_en: "CHINA OCEAN INDUSTRY GROUP LIMITED",
    name_zh: "中海重工",
    isHKConnect: 0,
  },
  {
    no: "0653",
    name_en: "BONJOUR HOLDINGS LIMITED",
    name_zh: "卓悅控股",
    isHKConnect: 0,
  },
  {
    no: "0655",
    name_en: "HONGKONG CHINESE LIMITED",
    name_zh: "香港華人有限公司",
    isHKConnect: 0,
  },
  {
    no: "0656",
    name_en: "FOSUN INTERNATIONAL LIMITED",
    name_zh: "復星國際",
    isHKConnect: 1,
  },
  {
    no: "0657",
    name_en: "G-VISION INTERNATIONAL (HOLDINGS) LIMITED",
    name_zh: "環科國際",
    isHKConnect: 0,
  },
  {
    no: "0658",
    name_en: "CHINA HIGH SPEED TRANSMISSION EQUIPMENT GROUP CO., LTD.",
    name_zh: "中國高速傳動",
    isHKConnect: 1,
  },
  {
    no: "0659",
    name_en: "NWS HOLDINGS LIMITED",
    name_zh: "新創建集團",
    isHKConnect: 1,
  },
  {
    no: "0660",
    name_en: "WAI CHUN BIO-TECHNOLOGY LIMITED",
    name_zh: "瑋俊生物科技",
    isHKConnect: 0,
  },
  {
    no: "0661",
    name_en: "CHINA DAYE NON-FERROUS METALS MINING LIMITED",
    name_zh: "中國大冶有色金屬",
    isHKConnect: 0,
  },
  {
    no: "0662",
    name_en: "ASIA FINANCIAL HOLDINGS LIMITED",
    name_zh: "亞洲金融",
    isHKConnect: 0,
  },
  {
    no: "0663",
    name_en: "KING STONE ENERGY GROUP LIMITED",
    name_zh: "金山能源",
    isHKConnect: 0,
  },
  {
    no: "0665",
    name_en: "HAITONG INTERNATIONAL SECURITIES GROUP LIMITED",
    name_zh: "海通國際",
    isHKConnect: 1,
  },
  {
    no: "0667",
    name_en: "CHINA EAST EDUCATION HOLDINGS LIMITED",
    name_zh: "中國東方教育",
    isHKConnect: 1,
  },
  {
    no: "0668",
    name_en: "DOYEN INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "東銀國際控股",
    isHKConnect: 0,
  },
  {
    no: "0669",
    name_en: "TECHTRONIC INDUSTRIES CO. LTD.",
    name_zh: "創科實業",
    isHKConnect: 1,
  },
  {
    no: "0670",
    name_en: "CHINA EASTERN AIRLINES CORPORATION LIMITED",
    name_zh: "中國東方航空股份",
    isHKConnect: 1,
  },
  {
    no: "0672",
    name_en: "ZHONG AN GROUP LIMITED",
    name_zh: "眾安集團",
    isHKConnect: 0,
  },
  {
    no: "0673",
    name_en: "CHINA HEALTH GROUP LIMITED",
    name_zh: "中國衛生集團",
    isHKConnect: 0,
  },
  {
    no: "0674",
    name_en: "CHINA TANGSHANG HOLDINGS LIMITED",
    name_zh: "中國唐商",
    isHKConnect: 0,
  },
  {
    no: "0675",
    name_en: "K & P INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "堅寶國際",
    isHKConnect: 0,
  },
  {
    no: "0676",
    name_en: "PEGASUS INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "創信國際",
    isHKConnect: 0,
  },
  {
    no: "0677",
    name_en: "GOLDEN RESOURCES DEVELOPMENT INTERNATIONAL LIMITED",
    name_zh: "金源米業",
    isHKConnect: 0,
  },
  {
    no: "0678",
    name_en: "GENTING HONG KONG LIMITED",
    name_zh: "雲頂香港",
    isHKConnect: 0,
  },
  {
    no: "0679",
    name_en: "ASIA TELE-NET AND TECHNOLOGY CORPORATION LIMITED",
    name_zh: "亞洲聯網科技",
    isHKConnect: 0,
  },
  {
    no: "0680",
    name_en: "NAN HAI CORPORATION LIMITED",
    name_zh: "南海控股",
    isHKConnect: 1,
  },
  {
    no: "0681",
    name_en: "CHINESE PEOPLE HOLDINGS COMPANY LIMITED",
    name_zh: "中民控股",
    isHKConnect: 0,
  },
  {
    no: "0682",
    name_en: "CHAODA MODERN AGRICULTURE (HOLDINGS) LIMITED",
    name_zh: "超大現代",
    isHKConnect: 0,
  },
  {
    no: "0683",
    name_en: "KERRY PROPERTIES LIMITED",
    name_zh: "嘉里建設",
    isHKConnect: 1,
  },
  {
    no: "0684",
    name_en: "ALLAN INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "亞倫國際",
    isHKConnect: 0,
  },
  {
    no: "0685",
    name_en: "MEDIA CHINESE INTERNATIONAL LIMITED",
    name_zh: "世界華文媒體",
    isHKConnect: 0,
  },
  {
    no: "0686",
    name_en: "BEIJING ENERGY INTERNATIONAL HOLDING CO., LTD",
    name_zh: "北京能源國際",
    isHKConnect: 1,
  },
  {
    no: "0687",
    name_en: "TYSAN HOLDINGS LIMITED",
    name_zh: "泰昇集團",
    isHKConnect: 1,
  },
  {
    no: "0688",
    name_en: "CHINA OVERSEAS LAND & INVESTMENT LIMITED",
    name_zh: "中國海外發展",
    isHKConnect: 1,
  },
  {
    no: "0689",
    name_en: "EPI (HOLDINGS) LIMITED",
    name_zh: "長盈集團(控股)",
    isHKConnect: 0,
  },
  {
    no: "0690",
    name_en: "UNI-BIO SCIENCE GROUP LIMITED",
    name_zh: "聯康生物科技集團",
    isHKConnect: 0,
  },
  {
    no: "0691",
    name_en: "CHINA SHANSHUI CEMENT GROUP LIMITED",
    name_zh: "山水水泥",
    isHKConnect: 1,
  },
  {
    no: "0693",
    name_en: "TAN CHONG INTERNATIONAL LIMITED",
    name_zh: "陳唱國際",
    isHKConnect: 0,
  },
  {
    no: "0694",
    name_en: "BEIJING CAPITAL INTERNATIONAL AIRPORT COMPANY LIMITED",
    name_zh: "北京首都機場股份",
    isHKConnect: 1,
  },
  {
    no: "0695",
    name_en: "DONGWU CEMENT INTERNATIONAL LIMITED",
    name_zh: "東吳水泥",
    isHKConnect: 0,
  },
  {
    no: "0696",
    name_en: "TRAVELSKY TECHNOLOGY LIMITED",
    name_zh: "中國民航信息網絡",
    isHKConnect: 1,
  },
  {
    no: "0697",
    name_en: "SHOUCHENG HOLDINGS LIMITED",
    name_zh: "首程控股",
    isHKConnect: 1,
  },
  {
    no: "0698",
    name_en: "TONGDA GROUP HOLDINGS LIMITED",
    name_zh: "通達集團",
    isHKConnect: 1,
  },
  {
    no: "0700",
    name_en: "TENCENT HOLDINGS LIMITED",
    name_zh: "騰訊控股",
    isHKConnect: 1,
  },
  {
    no: "0701",
    name_en: "CNT GROUP LIMITED",
    name_zh: "CNT GROUP",
    isHKConnect: 0,
  },
  {
    no: "0702",
    name_en: "SINO OIL AND GAS HOLDINGS LIMITED",
    name_zh: "中國油氣控股",
    isHKConnect: 0,
  },
  {
    no: "0703",
    name_en: "FUTURE BRIGHT HOLDINGS LIMITED",
    name_zh: "FUTURE BRIGHT",
    isHKConnect: 0,
  },
  {
    no: "0704",
    name_en: "HUSCOKE HOLDINGS LIMITED",
    name_zh: "和嘉控股",
    isHKConnect: 0,
  },
  {
    no: "0707",
    name_en: "ASIA TELEVISION HOLDINGS LIMITED",
    name_zh: "亞洲電視控股",
    isHKConnect: 0,
  },
  {
    no: "0708",
    name_en: "CHINA EVERGRANDE NEW ENERGY VEHICLE GROUP LIMITED",
    name_zh: "恆大汽車",
    isHKConnect: 0,
  },
  {
    no: "0709",
    name_en: "GIORDANO INTERNATIONAL LIMITED",
    name_zh: "佐丹奴國際",
    isHKConnect: 1,
  },
  {
    no: "0710",
    name_en: "BOE VARITRONIX LIMITED",
    name_zh: "京東方精電",
    isHKConnect: 0,
  },
  {
    no: "0711",
    name_en: "ASIA ALLIED INFRASTRUCTURE HOLDINGS LIMITED",
    name_zh: "亞洲聯合基建控股",
    isHKConnect: 0,
  },
  {
    no: "0712",
    name_en: "COMTEC SOLAR SYSTEMS GROUP LIMITED",
    name_zh: "卡姆丹克太陽能",
    isHKConnect: 0,
  },
  {
    no: "0713",
    name_en: "WORLD HOUSEWARE (HOLDINGS) LIMITED",
    name_zh: "世界(集團)",
    isHKConnect: 0,
  },
  {
    no: "0715",
    name_en: "CHINA OCEANWIDE HOLDINGS LIMITED",
    name_zh: "中泛控股",
    isHKConnect: 1,
  },
  {
    no: "0716",
    name_en: "SINGAMAS CONTAINER HOLDINGS LIMITED",
    name_zh: "勝獅貨櫃",
    isHKConnect: 0,
  },
  {
    no: "0717",
    name_en: "EMPEROR CAPITAL GROUP LIMITED",
    name_zh: "英皇證券",
    isHKConnect: 0,
  },
  {
    no: "0718",
    name_en: "TAI UNITED HOLDINGS LIMITED",
    name_zh: "太和控股",
    isHKConnect: 1,
  },
  {
    no: "0719",
    name_en: "SHANDONG XINHUA PHARMACEUTICAL COMPANY LIMITED",
    name_zh: "山東新華製藥股份",
    isHKConnect: 1,
  },
  {
    no: "0720",
    name_en: "AUTO ITALIA HOLDINGS LIMITED",
    name_zh: "意達利控股",
    isHKConnect: 0,
  },
  {
    no: "0721",
    name_en: "CHINA FINANCIAL INTERNATIONAL INVESTMENTS LIMITED",
    name_zh: "中國金融國際",
    isHKConnect: 1,
  },
  {
    no: "0722",
    name_en: "UMP HEALTHCARE HOLDINGS LIMITED",
    name_zh: "聯合醫務",
    isHKConnect: 0,
  },
  {
    no: "0723",
    name_en: "RELIANCE GLOBAL HOLDINGS LIMITED",
    name_zh: "信保環球控股",
    isHKConnect: 0,
  },
  {
    no: "0724",
    name_en: "RUIXIN INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "瑞鑫國際集團",
    isHKConnect: 0,
  },
  {
    no: "0725",
    name_en: "PERENNIAL INTERNATIONAL LIMITED",
    name_zh: "恆都集團",
    isHKConnect: 0,
  },
  {
    no: "0726",
    name_en: "DIT GROUP LIMITED",
    name_zh: "築友智造科技",
    isHKConnect: 0,
  },
  {
    no: "0727",
    name_en: "CROWN INTERNATIONAL CORPORATION LIMITED",
    name_zh: "皇冠環球集團",
    isHKConnect: 0,
  },
  {
    no: "0728",
    name_en: "CHINA TELECOM CORPORATION LIMITED",
    name_zh: "中國電信",
    isHKConnect: 1,
  },
  {
    no: "0730",
    name_en: "CAPITAL INDUSTRIAL FINANCIAL SERVICES GROUP LIMITED",
    name_zh: "首惠產業金融",
    isHKConnect: 0,
  },
  {
    no: "0731",
    name_en: "C&D NEWIN PAPER & PULP CORPORATION LIMITED",
    name_zh: "建發新勝",
    isHKConnect: 0,
  },
  {
    no: "0732",
    name_en: "TRULY INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "信利國際",
    isHKConnect: 1,
  },
  {
    no: "0733",
    name_en: "HOPEFLUENT GROUP HOLDINGS LIMITED",
    name_zh: "合富輝煌",
    isHKConnect: 0,
  },
  {
    no: "0736",
    name_en: "CHINA PROPERTIES INVESTMENT HOLDINGS LIMITED",
    name_zh: "中國置業投資",
    isHKConnect: 0,
  },
  {
    no: "0737",
    name_en: "SZ INVESTMENT HLDGS BAY AREA DEV CO LTD -HKD TRADED SHARES",
    name_zh: "灣區發展",
    isHKConnect: 1,
  },
  {
    no: "0738",
    name_en: "LE SAUNDA HOLDINGS LIMITED",
    name_zh: "萊爾斯丹",
    isHKConnect: 0,
  },
  {
    no: "0743",
    name_en: "ASIA CEMENT (CHINA) HOLDINGS CORPORATION",
    name_zh: "亞洲水泥(中國)",
    isHKConnect: 1,
  },
  {
    no: "0745",
    name_en: "CHINA NATIONAL CULTURE GROUP LIMITED",
    name_zh: "中國國家文化產業",
    isHKConnect: 0,
  },
  {
    no: "0746",
    name_en: "LEE & MAN CHEMICAL COMPANY LIMITED",
    name_zh: "理文化工",
    isHKConnect: 0,
  },
  {
    no: "0747",
    name_en: "SHENYANG PUBLIC UTILITY HOLDINGS COMPANY LIMITED",
    name_zh: "瀋陽公用發展股份",
    isHKConnect: 0,
  },
  {
    no: "0750",
    name_en: "CHINA SHUIFA SINGYES ENERGY HOLDINGS LIMITED",
    name_zh: "水發興業能源",
    isHKConnect: 0,
  },
  {
    no: "0751",
    name_en: "SKYWORTH GROUP LIMITED",
    name_zh: "創維集團",
    isHKConnect: 1,
  },
  {
    no: "0752",
    name_en: "PICO FAR EAST HOLDINGS LIMITED",
    name_zh: "PICO FAR EAST",
    isHKConnect: 0,
  },
  {
    no: "0753",
    name_en: "AIR CHINA LIMITED",
    name_zh: "中國國航",
    isHKConnect: 1,
  },
  {
    no: "0754",
    name_en: "HOPSON DEVELOPMENT HOLDINGS LIMITED",
    name_zh: "合生創展集團",
    isHKConnect: 1,
  },
  {
    no: "0755",
    name_en: "SHANGHAI ZENDAI PROPERTY LIMITED",
    name_zh: "上海証大",
    isHKConnect: 0,
  },
  {
    no: "0756",
    name_en: "SUMMI (GROUP) HOLDINGS LIMITED",
    name_zh: "森美控股",
    isHKConnect: 0,
  },
  {
    no: "0757",
    name_en: "SOLARGIGA ENERGY HOLDINGS LIMITED",
    name_zh: "陽光能源",
    isHKConnect: 0,
  },
  {
    no: "0758",
    name_en: "MACROLINK CAPITAL HOLDINGS LIMITED",
    name_zh: "新華聯資本",
    isHKConnect: 0,
  },
  {
    no: "0759",
    name_en: "CEC INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "CEC INT'L HOLD",
    isHKConnect: 0,
  },
  {
    no: "0760",
    name_en: "TALENT PROPERTY GROUP LIMITED",
    name_zh: "新天地產集團",
    isHKConnect: 0,
  },
  {
    no: "0762",
    name_en: "CHINA UNICOM (HONG KONG) LIMITED",
    name_zh: "中國聯通",
    isHKConnect: 1,
  },
  {
    no: "0763",
    name_en: "ZTE CORPORATION",
    name_zh: "中興通訊",
    isHKConnect: 1,
  },
  {
    no: "0764",
    name_en: "ETERNITY INVESTMENT LIMITED",
    name_zh: "永恆策略",
    isHKConnect: 0,
  },
  {
    no: "0765",
    name_en: "PERFECTECH INTERNATIONAL HOLDINGS LTD.",
    name_zh: "PERFECTECH INTL",
    isHKConnect: 0,
  },
  {
    no: "0766",
    name_en: "SINO PROSPER (GROUP) HOLDINGS LIMITED",
    name_zh: "中盈集團控股",
    isHKConnect: 0,
  },
  {
    no: "0767",
    name_en: "ZHONG JI LONGEVITY SCIENCE GROUP LIMITED",
    name_zh: "中基長壽科學",
    isHKConnect: 0,
  },
  {
    no: "0768",
    name_en: "UBA INVESTMENTS LIMITED",
    name_zh: "開明投資",
    isHKConnect: 0,
  },
  {
    no: "0769",
    name_en: "CHINA RARE EARTH HOLDINGS LIMITED",
    name_zh: "中國稀土",
    isHKConnect: 0,
  },
  {
    no: "0770",
    name_en: "SHANGHAI INTERNATIONAL SHANGHAI GROWTH INVESTMENT LIMITED",
    name_zh: "SHANGHAI GROWTH",
    isHKConnect: 0,
  },
  {
    no: "0771",
    name_en: "AUTOMATED SYSTEMS HOLDINGS LIMITED",
    name_zh: "自動系統",
    isHKConnect: 0,
  },
  {
    no: "0772",
    name_en: "CHINA LITERATURE LIMITED",
    name_zh: "閱文集團",
    isHKConnect: 1,
  },
  {
    no: "0775",
    name_en: "CK LIFE SCIENCES INT'L., (HOLDINGS) INC.",
    name_zh: "長江生命科技",
    isHKConnect: 1,
  },
  {
    no: "0776",
    name_en: "IMPERIUM TECHNOLOGY GROUP LIMITED",
    name_zh: "帝國科技集團",
    isHKConnect: 0,
  },
  {
    no: "0777",
    name_en: "NETDRAGON WEBSOFT HOLDINGS LIMITED",
    name_zh: "網龍",
    isHKConnect: 1,
  },
  {
    no: "0778",
    name_en: "FORTUNE REAL ESTATE INVESTMENT TRUST",
    name_zh: "置富產業信託",
    isHKConnect: 0,
  },
  {
    no: "0780",
    name_en: "TONGCHENG TRAVEL HOLDINGS LIMITED",
    name_zh: "同程旅行",
    isHKConnect: 1,
  },
  {
    no: "0784",
    name_en: "LING YUI HOLDINGS LIMITED",
    name_zh: "凌銳控股",
    isHKConnect: 0,
  },
  {
    no: "0788",
    name_en: "CHINA TOWER CORPORATION LIMITED",
    name_zh: "中國鐵塔",
    isHKConnect: 1,
  },
  {
    no: "0789",
    name_en: "ARTINI HOLDINGS LIMITED",
    name_zh: "雅天妮集團",
    isHKConnect: 0,
  },
  {
    no: "0794",
    name_en: "COME SURE GROUP (HOLDINGS) LIMITED",
    name_zh: "錦勝集團(控股)",
    isHKConnect: 0,
  },
  {
    no: "0797",
    name_en: "7ROAD HOLDINGS LIMITED",
    name_zh: "第七大道",
    isHKConnect: 0,
  },
  {
    no: "0798",
    name_en: "CHINA ELECTRONICS OPTICS VALLEY UNION HOLDING COMPANY LIMITED",
    name_zh: "中電光谷",
    isHKConnect: 0,
  },
  { no: "0799", name_en: "IGG INC", name_zh: "IGG INC", isHKConnect: 1 },
  {
    no: "0800",
    name_en: "A8 NEW MEDIA GROUP LIMITED",
    name_zh: "A8新媒體",
    isHKConnect: 0,
  },
  {
    no: "0802",
    name_en: "CHINA E-WALLET PAYMENT GROUP LIMITED",
    name_zh: "中國錢包",
    isHKConnect: 0,
  },
  {
    no: "0803",
    name_en: "PROSPERITY INTERNATIONAL HOLDINGS (H.K.) LIMITED",
    name_zh: "昌興國際",
    isHKConnect: 0,
  },
  {
    no: "0804",
    name_en: "PINESTONE CAPITAL LIMITED",
    name_zh: "鼎石資本",
    isHKConnect: 0,
  },
  {
    no: "0806",
    name_en: "VALUE PARTNERS GROUP LIMITED",
    name_zh: "惠理集團",
    isHKConnect: 1,
  },
  {
    no: "0807",
    name_en: "SIIC ENVIRONMENT HOLDINGS LTD.",
    name_zh: "上海實業環境",
    isHKConnect: 0,
  },
  {
    no: "0808",
    name_en: "PROSPERITY REAL ESTATE INVESTMENT TRUST",
    name_zh: "泓富產業信託",
    isHKConnect: 0,
  },
  {
    no: "0809",
    name_en: "GLOBAL BIO-CHEM TECHNOLOGY GROUP COMPANY LIMITED",
    name_zh: "大成生化科技",
    isHKConnect: 0,
  },
  {
    no: "0810",
    name_en: "CHINA INTERNET INVESTMENT FINANCE HOLDINGS LIMITED",
    name_zh: "中國互聯網投資",
    isHKConnect: 0,
  },
  {
    no: "0811",
    name_en: "XINHUA WINSHARE PUBLISHING AND MEDIA CO., LTD.",
    name_zh: "新華文軒",
    isHKConnect: 1,
  },
  {
    no: "0812",
    name_en: "SOUTHWEST SECURITIES INTERNATIONAL SECURITIES LIMITED",
    name_zh: "西證國際證券",
    isHKConnect: 0,
  },
  {
    no: "0813",
    name_en: "SHIMAO GROUP HOLDINGS LIMITED",
    name_zh: "世茂集團",
    isHKConnect: 1,
  },
  {
    no: "0814",
    name_en: "BEIJING JINGKELONG COMPANY LIMITED",
    name_zh: "北京京客隆",
    isHKConnect: 0,
  },
  {
    no: "0815",
    name_en: "CHINA SILVER GROUP LIMITED",
    name_zh: "中國白銀集團",
    isHKConnect: 0,
  },
  {
    no: "0816",
    name_en: "JINMAO PROPERTY SERVICES CO., LIMITED",
    name_zh: "金茂服務",
    isHKConnect: 1,
  },
  {
    no: "0817",
    name_en: "CHINA JINMAO HOLDINGS GROUP LIMITED",
    name_zh: "中國金茂",
    isHKConnect: 1,
  },
  {
    no: "0818",
    name_en: "HI SUN TECHNOLOGY (CHINA) LIMITED",
    name_zh: "高陽科技",
    isHKConnect: 0,
  },
  {
    no: "0819",
    name_en: "TIANNENG POWER INTERNATIONAL LIMITED",
    name_zh: "天能動力",
    isHKConnect: 1,
  },
  {
    no: "0820",
    name_en: "HSBC CHINA DRAGON FUND",
    name_zh: "匯豐中國翔龍基金",
    isHKConnect: 0,
  },
  {
    no: "0821",
    name_en: "VALUE CONVERGENCE HOLDINGS LIMITED",
    name_zh: "匯盈控股",
    isHKConnect: 0,
  },
  {
    no: "0822",
    name_en: "KA SHUI INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "嘉瑞國際",
    isHKConnect: 0,
  },
  {
    no: "0823",
    name_en: "LINK REAL ESTATE INVESTMENT TRUST",
    name_zh: "領展房產基金",
    isHKConnect: 0,
  },
  {
    no: "0825",
    name_en: "NEW WORLD DEPARTMENT STORE CHINA LIMITED",
    name_zh: "新世界百貨中國",
    isHKConnect: 0,
  },
  {
    no: "0826",
    name_en: "TIANGONG INTERNATIONAL COMPANY LIMITED",
    name_zh: "天工國際",
    isHKConnect: 1,
  },
  {
    no: "0827",
    name_en: "KO YO CHEMICAL (GROUP) LIMITED",
    name_zh: "玖源集團",
    isHKConnect: 0,
  },
  {
    no: "0828",
    name_en: "DYNASTY FINE WINES GROUP LIMITED",
    name_zh: "王朝酒業",
    isHKConnect: 0,
  },
  {
    no: "0829",
    name_en: "SHENGUAN HOLDINGS (GROUP) LIMITED",
    name_zh: "神冠控股",
    isHKConnect: 1,
  },
  {
    no: "0830",
    name_en: "CHINA STATE CONSTRUCTION DEVELOPMENT HOLDINGS LIMITED",
    name_zh: "中國建築興業",
    isHKConnect: 0,
  },
  {
    no: "0831",
    name_en: "CONVENIENCE RETAIL ASIA LIMITED",
    name_zh: "利亞零售",
    isHKConnect: 0,
  },
  {
    no: "0832",
    name_en: "CENTRAL CHINA REAL ESTATE LIMITED",
    name_zh: "建業地產",
    isHKConnect: 1,
  },
  {
    no: "0833",
    name_en: "ALLTRONICS HOLDINGS LIMITED",
    name_zh: "華訊",
    isHKConnect: 0,
  },
  {
    no: "0834",
    name_en: "CHINA KANGDA FOOD COMPANY LIMITED",
    name_zh: "康大食品",
    isHKConnect: 0,
  },
  {
    no: "0836",
    name_en: "CHINA RESOURCES POWER HOLDINGS COMPANY LIMITED",
    name_zh: "華潤電力",
    isHKConnect: 1,
  },
  {
    no: "0837",
    name_en: "CARPENTER TAN HOLDINGS LIMITED",
    name_zh: "譚木匠",
    isHKConnect: 0,
  },
  {
    no: "0838",
    name_en: "EVA PRECISION INDUSTRIAL HOLDINGS LIMITED",
    name_zh: "億和控股",
    isHKConnect: 0,
  },
  {
    no: "0839",
    name_en: "CHINA EDUCATION GROUP HOLDINGS LIMITED",
    name_zh: "中教控股",
    isHKConnect: 1,
  },
  {
    no: "0840",
    name_en: "XINJIANG TIANYE WATER SAVING IRRIGATION SYSTEM COMPANY LIMITED",
    name_zh: "天業節水",
    isHKConnect: 0,
  },
  {
    no: "0841",
    name_en: "ASIA CASSAVA RESOURCES HOLDINGS LIMITED",
    name_zh: "木薯資源",
    isHKConnect: 0,
  },
  {
    no: "0842",
    name_en: "LEOCH INTERNATIONAL TECHNOLOGY LIMITED",
    name_zh: "理士國際",
    isHKConnect: 0,
  },
  {
    no: "0844",
    name_en: "GREATIME INTERNATIONAL HOLDINGS LIMITED LIMITED",
    name_zh: "廣泰國際控股",
    isHKConnect: 0,
  },
  {
    no: "0845",
    name_en: "GLORIOUS PROPERTY HOLDINGS LIMITED",
    name_zh: "恆盛地產",
    isHKConnect: 1,
  },
  {
    no: "0846",
    name_en: "MINGFA GROUP (INTERNATIONAL) COMPANY LIMITED",
    name_zh: "明發集團",
    isHKConnect: 1,
  },
  {
    no: "0848",
    name_en: "MAOYE INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "茂業國際",
    isHKConnect: 0,
  },
  {
    no: "0851",
    name_en: "SHENG YUAN HOLDINGS LIMITED -NEW",
    name_zh: "盛源控股(新)",
    isHKConnect: 0,
  },
  {
    no: "0852",
    name_en: "STRONG PETROCHEMICAL HOLDINGS LIMITED",
    name_zh: "海峽石油化工",
    isHKConnect: 0,
  },
  {
    no: "0853",
    name_en: "MICROPORT SCIENTIFIC CORPORATION",
    name_zh: "微創醫療",
    isHKConnect: 1,
  },
  {
    no: "0854",
    name_en: "WILLAS-ARRAY ELECTRONICS (HOLDINGS) LIMITED",
    name_zh: "威雅利",
    isHKConnect: 0,
  },
  {
    no: "0855",
    name_en: "CHINA WATER AFFAIRS GROUP LIMITED",
    name_zh: "中國水務",
    isHKConnect: 1,
  },
  {
    no: "0856",
    name_en: "VSTECS HOLDINGS LIMITED",
    name_zh: "偉仕佳杰",
    isHKConnect: 1,
  },
  {
    no: "0857",
    name_en: "PETROCHINA COMPANY LIMITED",
    name_zh: "中國石油股份",
    isHKConnect: 1,
  },
  {
    no: "0858",
    name_en: "EXTRAWELL PHARMACEUTICAL HOLDINGS LIMITED",
    name_zh: "精優藥業",
    isHKConnect: 0,
  },
  {
    no: "0859",
    name_en: "ZHONGCHANG INTERNATIONAL HOLDINGS GROUP LIMITED",
    name_zh: "中昌國際控股",
    isHKConnect: 0,
  },
  {
    no: "0860",
    name_en: "APOLLO FUTURE MOBILITY GROUP LIMITED",
    name_zh: "APOLLO出行",
    isHKConnect: 1,
  },
  {
    no: "0861",
    name_en: "DIGITAL CHINA HOLDINGS LIMITED",
    name_zh: "神州控股",
    isHKConnect: 1,
  },
  {
    no: "0862",
    name_en: "VISION VALUES HOLDINGS LIMITED",
    name_zh: "遠見控股",
    isHKConnect: 0,
  },
  {
    no: "0863",
    name_en: "BC TECHNOLOGY GROUP LIMITED",
    name_zh: "BC科技集團",
    isHKConnect: 0,
  },
  {
    no: "0864",
    name_en: "WING LEE PROPERTY INVESTMENTS LIMITED",
    name_zh: "永利地產發展",
    isHKConnect: 0,
  },
  {
    no: "0865",
    name_en: "JIANDE INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "建德國際控股",
    isHKConnect: 0,
  },
  {
    no: "0866",
    name_en: "CHINA QINFA GROUP LIMITED",
    name_zh: "中國秦發",
    isHKConnect: 0,
  },
  {
    no: "0867",
    name_en: "CHINA MEDICAL SYSTEM HOLDINGS LIMITED",
    name_zh: "康哲藥業",
    isHKConnect: 1,
  },
  {
    no: "0868",
    name_en: "XINYI GLASS HOLDINGS LIMITED",
    name_zh: "信義玻璃",
    isHKConnect: 1,
  },
  {
    no: "0869",
    name_en: "PLAYMATES TOYS LIMITED",
    name_zh: "彩星玩具",
    isHKConnect: 0,
  },
  {
    no: "0871",
    name_en: "CHINA DREDGING ENVIRONMENT PROTECTION HOLDINGS LIMITED",
    name_zh: "中國疏浚環保",
    isHKConnect: 0,
  },
  {
    no: "0872",
    name_en: "TITAN INVO TECHNOLOGY LIMITED",
    name_zh: "泰坦智華科技",
    isHKConnect: 0,
  },
  {
    no: "0873",
    name_en: "SHIMAO SERVICES HOLDINGS LIMITED",
    name_zh: "世茂服務",
    isHKConnect: 1,
  },
  {
    no: "0874",
    name_en: "GUANGZHOU BAIYUNSHAN PHARMACEUTICAL HOLDINGS CO., LTD.",
    name_zh: "白雲山",
    isHKConnect: 1,
  },
  {
    no: "0875",
    name_en: "CHINA FINANCE INVESTMENT HOLDINGS LIMITED",
    name_zh: "中國金控",
    isHKConnect: 0,
  },
  {
    no: "0876",
    name_en: "KAISA HEALTH GROUP HOLDINGS LIMITED",
    name_zh: "佳兆業健康",
    isHKConnect: 0,
  },
  {
    no: "0878",
    name_en: "SOUNDWILL HOLDINGS LIMITED",
    name_zh: "金朝陽集團",
    isHKConnect: 0,
  },
  {
    no: "0880",
    name_en: "SJM HOLDINGS LIMITED",
    name_zh: "澳博控股",
    isHKConnect: 1,
  },
  {
    no: "0881",
    name_en: "ZHONGSHENG GROUP HOLDINGS LIMITED",
    name_zh: "中升控股",
    isHKConnect: 1,
  },
  {
    no: "0882",
    name_en: "TIANJIN DEVELOPMENT HOLDINGS LIMITED",
    name_zh: "天津發展",
    isHKConnect: 0,
  },
  {
    no: "0883",
    name_en: "CNOOC LIMITED",
    name_zh: "中國海洋石油",
    isHKConnect: 1,
  },
  {
    no: "0884",
    name_en: "CIFI HOLDINGS (GROUP) CO. LTD.",
    name_zh: "旭輝控股集團",
    isHKConnect: 1,
  },
  {
    no: "0886",
    name_en: "SILVER BASE GROUP HOLDINGS LIMITED",
    name_zh: "銀基集團",
    isHKConnect: 0,
  },
  {
    no: "0887",
    name_en: "EMPEROR WATCH & JEWELLERY LIMITED",
    name_zh: "諃皇鐘錶珠褐",
    isHKConnect: 0,
  },
  {
    no: "0888",
    name_en: "BISON FINANCE GROUP LIMITED",
    name_zh: "貝森金融",
    isHKConnect: 0,
  },
  {
    no: "0889",
    name_en: "DATRONIX HOLDINGS LIMITED",
    name_zh: "連達科技控股",
    isHKConnect: 0,
  },
  {
    no: "0893",
    name_en: "CHINA VANADIUM TITANO-MAGNETITE MINING COMPANY LIMITED",
    name_zh: "中國鐵鈦",
    isHKConnect: 0,
  },
  {
    no: "0894",
    name_en: "MAN YUE TECHNOLOGY HOLDINGS LIMITED",
    name_zh: "萬裕科技",
    isHKConnect: 0,
  },
  {
    no: "0895",
    name_en: "DONGJIANG ENVIRONMENTAL COMPANY LIMITED",
    name_zh: "東江環保",
    isHKConnect: 1,
  },
  {
    no: "0896",
    name_en: "HANISON CONSTRUCTION HOLDINGS LIMITED",
    name_zh: "興勝創建",
    isHKConnect: 0,
  },
  {
    no: "0897",
    name_en: "WAI YUEN TONG MEDICINE HOLDINGS LIMITED",
    name_zh: "位元堂",
    isHKConnect: 0,
  },
  {
    no: "0898",
    name_en: "MULTIFIELD INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "萬事昌國際",
    isHKConnect: 0,
  },
  {
    no: "0899",
    name_en: "ASIA RESOURCES HOLDINGS LIMITED -NEW",
    name_zh: "亞洲資源",
    isHKConnect: 0,
  },
  {
    no: "0900",
    name_en: "AEON CREDIT SERVICE (ASIA) COMPANY LIMITED",
    name_zh: "AEON CREDIT",
    isHKConnect: 0,
  },
  {
    no: "0901",
    name_en: "GOLDSTONE INVESTMENT GROUP LIMITED",
    name_zh: "金石投資集團",
    isHKConnect: 0,
  },
  {
    no: "0902",
    name_en: "HUANENG POWER INTERNATIONAL, INC.",
    name_zh: "華能國際電力股份",
    isHKConnect: 1,
  },
  {
    no: "0904",
    name_en: "CHINA GREEN (HOLDINGS) LIMITED",
    name_zh: "中綠",
    isHKConnect: 0,
  },
  {
    no: "0905",
    name_en: "WALNUT CAPITAL LIMITED",
    name_zh: "胡桃資本",
    isHKConnect: 0,
  },
  {
    no: "0906",
    name_en: "CPMC HOLDINGS LIMITED",
    name_zh: "中糧包裝",
    isHKConnect: 1,
  },
  {
    no: "0907",
    name_en: "ELEGANCE OPTICAL INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "高雅光學",
    isHKConnect: 0,
  },
  {
    no: "0909",
    name_en: "MING YUAN CLOUD GROUP HOLDINGS LIMITED",
    name_zh: "明源雲",
    isHKConnect: 1,
  },
  {
    no: "0910",
    name_en: "CHINA SANDI HOLDINGS LIMITED",
    name_zh: "中國三迪",
    isHKConnect: 0,
  },
  {
    no: "0911",
    name_en: "QIANHAI HEALTH HOLDINGS LIMITED",
    name_zh: "前海健康",
    isHKConnect: 1,
  },
  {
    no: "0912",
    name_en: "SUGA INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "信佳國際",
    isHKConnect: 0,
  },
  {
    no: "0913",
    name_en: "HARBOUR DIGITAL ASSET CAPITAL LIMITED",
    name_zh: "港灣數字",
    isHKConnect: 0,
  },
  {
    no: "0914",
    name_en: "ANHUI CONCH CEMENT COMPANY LIMITED",
    name_zh: "海螺水泥",
    isHKConnect: 1,
  },
  {
    no: "0915",
    name_en: "DAOHE GLOBAL GROUP LIMITED",
    name_zh: "道和環球",
    isHKConnect: 0,
  },
  {
    no: "0916",
    name_en: "CHINA LONGYUAN POWER GROUP CORPORATION LIMITED",
    name_zh: "龍源電力",
    isHKConnect: 1,
  },
  {
    no: "0918",
    name_en: "STATE ENERGY GROUP INTERNATIONAL ASSETS HOLDINGS LIMITED",
    name_zh: "國能國際資產",
    isHKConnect: 0,
  },
  {
    no: "0919",
    name_en: "MODERN HEALTHCARE TECHNOLOGY HOLDINGS LIMITED",
    name_zh: "現代健康科技",
    isHKConnect: 0,
  },
  {
    no: "0921",
    name_en: "HISENSE HOME APPLIANCES GROUP CO., LTD.",
    name_zh: "海信家電",
    isHKConnect: 1,
  },
  {
    no: "0922",
    name_en: "ANXIAN YUAN CHINA HOLDINGS LIMITED",
    name_zh: "安賢園中國",
    isHKConnect: 0,
  },
  {
    no: "0923",
    name_en: "INTEGRATED WASTE SOLUTIONS GROUP HOLDINGS LIMITED",
    name_zh: "綜合環保集團",
    isHKConnect: 0,
  },
  {
    no: "0924",
    name_en: "KHOON GROUP LIMITED",
    name_zh: "坤集團",
    isHKConnect: 0,
  },
  {
    no: "0925",
    name_en: "BEIJING PROPERTIES (HOLDINGS) LIMITED",
    name_zh: "北京建設",
    isHKConnect: 0,
  },
  {
    no: "0926",
    name_en: "BESUNYEN HOLDINGS COMPANY LIMITED",
    name_zh: "碧生源",
    isHKConnect: 0,
  },
  {
    no: "0927",
    name_en: "FUJIKON INDUSTRIAL HOLDINGS LIMITED",
    name_zh: "富士高實業",
    isHKConnect: 0,
  },
  {
    no: "0928",
    name_en: "LIFE HEALTHCARE GROUP LIMITED",
    name_zh: "蓮和醫療",
    isHKConnect: 0,
  },
  {
    no: "0929",
    name_en: "IPE GROUP LIMITED",
    name_zh: "國際精密",
    isHKConnect: 0,
  },
  {
    no: "0931",
    name_en: "CHINA LNG GROUP LIMITED",
    name_zh: "中國天然氣",
    isHKConnect: 1,
  },
  {
    no: "0932",
    name_en: "SHUNTEN INTERNATIONAL (HOLDINGS) LIMITED",
    name_zh: "順騰國際控股",
    isHKConnect: 0,
  },
  {
    no: "0934",
    name_en: "SINOPEC KANTONS HOLDINGS LIMITED",
    name_zh: "中石化冠德",
    isHKConnect: 1,
  },
  {
    no: "0936",
    name_en: "KAISA CAPITAL INVESTMENT HOLDINGS LIMITED",
    name_zh: "佳兆業資本",
    isHKConnect: 0,
  },
  {
    no: "0938",
    name_en: "MAN SANG INTERNATIONAL LIMITED",
    name_zh: "民生國際",
    isHKConnect: 0,
  },
  {
    no: "0939",
    name_en: "CHINA CONSTRUCTION BANK CORPORATION",
    name_zh: "建設銀行",
    isHKConnect: 1,
  },
  {
    no: "0941",
    name_en: "CHINA MOBILE LIMITED",
    name_zh: "中國移動",
    isHKConnect: 1,
  },
  {
    no: "0943",
    name_en: "ZHONGZHENG INTERNATIONAL COMPANY LIMITED",
    name_zh: "中證國際",
    isHKConnect: 0,
  },
  {
    no: "0945",
    name_en: "MANULIFE FINANCIAL CORPORATION",
    name_zh: "宏利金融-S",
    isHKConnect: 0,
  },
  {
    no: "0947",
    name_en: "MOBI DEVELOPMENT CO., LTD.",
    name_zh: "摩比發展",
    isHKConnect: 0,
  },
  {
    no: "0948",
    name_en: "ALPHA PROFESSIONAL HOLDINGS LIMITED",
    name_zh: "阿爾法企業",
    isHKConnect: 0,
  },
  {
    no: "0950",
    name_en: "LEE'S PHARMACEUTICAL HOLDINGS LIMITED",
    name_zh: "李氏大藥廠",
    isHKConnect: 1,
  },
  {
    no: "0951",
    name_en: "CHAOWEI POWER HOLDINGS LIMITED",
    name_zh: "超威動力",
    isHKConnect: 1,
  },
  {
    no: "0952",
    name_en: "CHINA TONGHAI INTERNATIONAL FINANCIAL LIMITED",
    name_zh: "中國通海金融",
    isHKConnect: 0,
  },
  {
    no: "0953",
    name_en: "SHAW BROTHERS HOLDINGS LIMITED",
    name_zh: "邵氏兄弟控股",
    isHKConnect: 0,
  },
  {
    no: "0954",
    name_en: "CHANGMAO BIOCHEMICAL ENGINEERING COMPANY LIMITED",
    name_zh: "常茂生物",
    isHKConnect: 0,
  },
  {
    no: "0956",
    name_en: "CHINA SUNTIEN GREEN ENERGY CORPORATION LIMITED",
    name_zh: "新天綠色能源",
    isHKConnect: 1,
  },
  {
    no: "0959",
    name_en: "CENTURY ENTERTAINMENT INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "世紀娛樂國際",
    isHKConnect: 0,
  },
  {
    no: "0960",
    name_en: "LONGFOR GROUP HOLDINGS LIMITED",
    name_zh: "龍湖集團",
    isHKConnect: 1,
  },
  {
    no: "0966",
    name_en: "CHINA TAIPING INSURANCE HOLDINGS COMPANY LIMITED",
    name_zh: "中國太平",
    isHKConnect: 1,
  },
  {
    no: "0968",
    name_en: "XINYI SOLAR HOLDINGS LIMITED",
    name_zh: "信義光能",
    isHKConnect: 1,
  },
  {
    no: "0969",
    name_en: "HUA LIEN INTERNATIONAL (HOLDING) COMPANY LIMITED",
    name_zh: "華聯國際",
    isHKConnect: 0,
  },
  {
    no: "0970",
    name_en: "NEW SPARKLE ROLL INTERATIONAL GROUP LIMITED",
    name_zh: "新耀萊",
    isHKConnect: 0,
  },
  {
    no: "0973",
    name_en: "L'OCCITANE INTERNATIONAL S.A.",
    name_zh: "L'OCCITANE INTERNATIONAL S.A.",
    isHKConnect: 0,
  },
  {
    no: "0974",
    name_en: "CHINA SHUN KE LONG HOLDINGS LIMITED",
    name_zh: "中國順客隆",
    isHKConnect: 0,
  },
  {
    no: "0975",
    name_en: "MONGOLIAN MINING CORPORATION",
    name_zh: "MONGOLIAN MINING CORPORATION",
    isHKConnect: 0,
  },
  {
    no: "0976",
    name_en: "CHIHO ENVIRONMENTAL GROUP LIMITED",
    name_zh: "齊合環保",
    isHKConnect: 1,
  },
  {
    no: "0978",
    name_en: "CHINA MERCHANTS LAND LIMITED",
    name_zh: "招商局置地",
    isHKConnect: 1,
  },
  {
    no: "0979",
    name_en: "GREEN ENERGY GROUP LIMITED",
    name_zh: "綠色能源科技集團",
    isHKConnect: 0,
  },
  {
    no: "0980",
    name_en: "LIANHUA SUPERMARKET HOLDINGS CO., LTD.",
    name_zh: "聯華超市",
    isHKConnect: 0,
  },
  {
    no: "0981",
    name_en: "SEMICONDUCTOR MANUFACTURING INTERNATIONAL CORPORATION",
    name_zh: "中芯國際",
    isHKConnect: 1,
  },
  {
    no: "0982",
    name_en: "HUAFA PROPERTY SERVICES GROUP COMPANY LIMITED",
    name_zh: "華發物業服務",
    isHKConnect: 0,
  },
  {
    no: "0983",
    name_en: "SOCAM DEVELOPMENT LIMITED",
    name_zh: "瑞安建業",
    isHKConnect: 0,
  },
  {
    no: "0984",
    name_en: "AEON STORES (HONG KONG) CO., LIMITED",
    name_zh: "永旺",
    isHKConnect: 0,
  },
  {
    no: "0985",
    name_en: "CST GROUP LIMITED",
    name_zh: "中譽集團",
    isHKConnect: 0,
  },
  {
    no: "0986",
    name_en: "CHINA ENVIRONMENTAL ENERGY INVESTMENT LIMITED",
    name_zh: "中國環保能源",
    isHKConnect: 0,
  },
  {
    no: "0987",
    name_en: "CHINA RENEWABLE ENERGY INVESTMENT LIMITED",
    name_zh: "中國再生能源投資",
    isHKConnect: 0,
  },
  {
    no: "0988",
    name_en: "SILK ROAD LOGISTICS HOLDINGS LIMITED",
    name_zh: "絲路物流控股",
    isHKConnect: 0,
  },
  {
    no: "0989",
    name_en: "HUA YIN INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "華音國際控股",
    isHKConnect: 1,
  },
  {
    no: "0990",
    name_en: "THEME INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "榮暉國際",
    isHKConnect: 1,
  },
  {
    no: "0991",
    name_en: "DATANG INTERNATIONAL POWER GENERATION CO.,LTD.",
    name_zh: "大唐發電",
    isHKConnect: 1,
  },
  {
    no: "0992",
    name_en: "LENOVO GROUP LIMITED",
    name_zh: "聯想集團",
    isHKConnect: 1,
  },
  {
    no: "0993",
    name_en: "HUARONG INTERNATIONAL FINANCIAL HOLDINGS LIMITED",
    name_zh: "華融金控",
    isHKConnect: 0,
  },
  {
    no: "0994",
    name_en: "CT VISION S.L. (INTERNATIONAL) HOLDINGS LIMITED",
    name_zh: "中天順聯",
    isHKConnect: 0,
  },
  {
    no: "0995",
    name_en: "ANHUI EXPRESSWAY COMPANY LIMITED",
    name_zh: "安徽皖通高速公路",
    isHKConnect: 1,
  },
  {
    no: "0996",
    name_en: "CARNIVAL GROUP INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "嘉年華國際",
    isHKConnect: 1,
  },
  {
    no: "0997",
    name_en: "CHINLINK INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "普匯中金國際",
    isHKConnect: 0,
  },
  {
    no: "0998",
    name_en: "CHINA CITIC BANK CORPORATION LIMITED",
    name_zh: "中信銀行",
    isHKConnect: 1,
  },
  {
    no: "1000",
    name_en: "BEIJING MEDIA CORPORATION LIMITED",
    name_zh: "北青傳媒",
    isHKConnect: 0,
  },
  {
    no: "1001",
    name_en: "HONG KONG SHANGHAI ALLIANCE HOLDINGS LIMITED",
    name_zh: "滬港聯合",
    isHKConnect: 0,
  },
  {
    no: "1002",
    name_en: "V.S. INTERNATIONAL GROUP LIMITED",
    name_zh: "威鋮國際",
    isHKConnect: 0,
  },
  {
    no: "1003",
    name_en: "HUANXI MEDIA GROUP LIMITED",
    name_zh: "歡喜傳媒",
    isHKConnect: 0,
  },
  {
    no: "1004",
    name_en: "CHINA SMARTER ENERGY GROUP HOLDINGS LIMITED",
    name_zh: "中國智慧能源",
    isHKConnect: 0,
  },
  {
    no: "1005",
    name_en: "MATRIX HOLDINGS LIMITED",
    name_zh: "MATRIX HOLDINGS",
    isHKConnect: 0,
  },
  {
    no: "1007",
    name_en: "LONGHUI INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "龍輝國際控股",
    isHKConnect: 0,
  },
  {
    no: "1008",
    name_en: "LITU HOLDINGS LIMITED",
    name_zh: "力圖控股",
    isHKConnect: 0,
  },
  {
    no: "1009",
    name_en: "INTERNATIONAL ENTERTAINMENT CORPORATION",
    name_zh: "國際娛樂",
    isHKConnect: 0,
  },
  {
    no: "1010",
    name_en: "PACRAY INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "太睿國際控股",
    isHKConnect: 0,
  },
  {
    no: "1011",
    name_en: "CHINA NT PHARMA GROUP COMPANY LIMITED",
    name_zh: "泰凌醫藥",
    isHKConnect: 0,
  },
  {
    no: "1013",
    name_en: "WAI CHUN GROUP HOLDINGS LIMITED",
    name_zh: "偉俊集團控股",
    isHKConnect: 0,
  },
  {
    no: "1020",
    name_en: "CYBERNAUT INTERNATIONAL HOLDINGS COMPANY LIMITED",
    name_zh: "賽伯樂國際控股",
    isHKConnect: 0,
  },
  {
    no: "1022",
    name_en: "FEIYU TECHNOLOGY INTERNATIONAL COMPANY LTD.",
    name_zh: "飛魚科技",
    isHKConnect: 0,
  },
  {
    no: "1023",
    name_en: "SITOY GROUP HOLDINGS LIMITED",
    name_zh: "時代集團控股",
    isHKConnect: 0,
  },
  {
    no: "1024",
    name_en: "KUAISHOU TECHNOLOGY",
    name_zh: "快手-W",
    isHKConnect: 1,
  },
  {
    no: "1025",
    name_en: "KNT HOLDINGS LIMITED",
    name_zh: "嘉藝控股",
    isHKConnect: 0,
  },
  {
    no: "1026",
    name_en: "UNIVERSAL TECHNOLOGIES HOLDINGS LIMITED",
    name_zh: "環球實業科技",
    isHKConnect: 0,
  },
  {
    no: "1027",
    name_en: "CHINA JICHENG HOLDINGS LIMITED",
    name_zh: "中國集成控股",
    isHKConnect: 0,
  },
  {
    no: "1028",
    name_en: "C.BANNER INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "千百度",
    isHKConnect: 1,
  },
  { no: "1029", name_en: "IRC LIMITED", name_zh: "鐵貨", isHKConnect: 0 },
  {
    no: "1030",
    name_en: "SEAZEN GROUP LIMITED",
    name_zh: "新城發展",
    isHKConnect: 1,
  },
  {
    no: "1031",
    name_en: "KINGSTON FINANCIAL GROUP LIMITED",
    name_zh: "金利豐金融",
    isHKConnect: 1,
  },
  {
    no: "1033",
    name_en: "SINOPEC OILFIELD SERVICE CORPORATION",
    name_zh: "中石化油服",
    isHKConnect: 1,
  },
  {
    no: "1034",
    name_en: "FULLWEALTH INTERNATIONAL GROUP HOLDINGS LIMITED",
    name_zh: "富匯國際集團控股",
    isHKConnect: 0,
  },
  {
    no: "1036",
    name_en: "VANKE OVERSEAS INVESTMENT HOLDING COMPANY LIMITED",
    name_zh: "萬科海外",
    isHKConnect: 0,
  },
  {
    no: "1037",
    name_en: "MAXNERVA TECHNOLOGY SERVICES LIMITED",
    name_zh: "雲智匯科技",
    isHKConnect: 0,
  },
  {
    no: "1038",
    name_en: "CK INFRASTRUCTURE HOLDINGS LIMITED",
    name_zh: "長江基建集團",
    isHKConnect: 1,
  },
  {
    no: "1039",
    name_en: "CHANGYOU ALLIANCE GROUP LIMITED",
    name_zh: "暢由聯盟",
    isHKConnect: 0,
  },
  {
    no: "1044",
    name_en: "HENGAN INTERNATIONAL GROUP COMPANY LIMITED",
    name_zh: "恆安國際",
    isHKConnect: 1,
  },
  {
    no: "1045",
    name_en: "APT SATELLITE HOLDINGS LIMITED",
    name_zh: "亞太衛星",
    isHKConnect: 0,
  },
  {
    no: "1046",
    name_en: "UNIVERSE ENTERTAINMENT AND CULTURE GROUP COMPANY LIMITED",
    name_zh: "寰宇娛樂文化",
    isHKConnect: 0,
  },
  {
    no: "1047",
    name_en: "NGAI HING HONG COMPANY LIMITED",
    name_zh: "毅興行",
    isHKConnect: 0,
  },
  {
    no: "1049",
    name_en: "CELESTIAL ASIA SECURITIES HOLDINGS LIMITED",
    name_zh: "時富投資",
    isHKConnect: 0,
  },
  {
    no: "1050",
    name_en: "KARRIE INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "嘉利國際",
    isHKConnect: 0,
  },
  {
    no: "1051",
    name_en: "G-RESOURCES GROUP LIMITED",
    name_zh: "國際資源",
    isHKConnect: 0,
  },
  {
    no: "1052",
    name_en: "YUEXIU TRANSPORT INFRASTRUCTURE LIMITED",
    name_zh: "越秀交通基建",
    isHKConnect: 1,
  },
  {
    no: "1053",
    name_en: "CHONGQING IRON & STEEL COMPANY LIMITED",
    name_zh: "重慶鋼鐵股份",
    isHKConnect: 1,
  },
  {
    no: "1055",
    name_en: "CHINA SOUTHERN AIRLINES COMPANY LIMITED",
    name_zh: "中國南方航空股份",
    isHKConnect: 1,
  },
  {
    no: "1057",
    name_en: "ZHEJIANG SHIBAO COMPANY LIMITED",
    name_zh: "浙江世寶",
    isHKConnect: 1,
  },
  {
    no: "1058",
    name_en: "NAMYUE HOLDINGS LIMITED",
    name_zh: "南粵控股",
    isHKConnect: 0,
  },
  {
    no: "1059",
    name_en: "KANTONE HOLDINGS LIMITED",
    name_zh: "看通集團",
    isHKConnect: 0,
  },
  {
    no: "1060",
    name_en: "ALIBABA PICTURES GROUP LIMITED",
    name_zh: "阿里影業",
    isHKConnect: 1,
  },
  {
    no: "1061",
    name_en: "ESSEX BIO-TECHNOLOGY LIMITED",
    name_zh: "億勝生物科技",
    isHKConnect: 0,
  },
  {
    no: "1062",
    name_en: "CHINA DEVELOPMENT BANK INTERNATIONAL INVESTMENT LIMITED",
    name_zh: "國開國際投資",
    isHKConnect: 0,
  },
  {
    no: "1063",
    name_en: "SUNCORP TECHNOLOGIES LIMITED",
    name_zh: "新確科技",
    isHKConnect: 0,
  },
  {
    no: "1064",
    name_en: "ZHONG HUA INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "中華國際",
    isHKConnect: 0,
  },
  {
    no: "1065",
    name_en: "TIANJIN CAPITAL ENVIRONMENTAL PROTECTION GROUP COMPANY LIMITED",
    name_zh: "天津創業環保股份",
    isHKConnect: 1,
  },
  {
    no: "1066",
    name_en: "SHANDONG WEIGAO GROUP MEDICAL POLYMER COMPANY LIMITED",
    name_zh: "威高股份",
    isHKConnect: 1,
  },
  {
    no: "1068",
    name_en: "CHINA YURUN FOOD GROUP LIMITED",
    name_zh: "雨潤食品",
    isHKConnect: 1,
  },
  {
    no: "1069",
    name_en: "CHINA BOZZA DEVELOPMENT HOLDINGS LIMITED",
    name_zh: "寶沙發展",
    isHKConnect: 0,
  },
  {
    no: "1070",
    name_en: "TCL ELECTRONICS HOLDINGS LIMITED",
    name_zh: "TCL電子",
    isHKConnect: 1,
  },
  {
    no: "1071",
    name_en: "HUADIAN POWER INTERNATIONAL CORPORATION LIMITED",
    name_zh: "華電國際電力股份",
    isHKConnect: 1,
  },
  {
    no: "1072",
    name_en: "DONGFANG ELECTRIC CORPORATION LIMITED",
    name_zh: "東方電氣",
    isHKConnect: 1,
  },
  {
    no: "1073",
    name_en: "DA YU FINANCIAL HOLDINGS LIMITED",
    name_zh: "大禹金融",
    isHKConnect: 0,
  },
  {
    no: "1075",
    name_en: "CAPINFO COMPANY LIMITED",
    name_zh: "首都信息",
    isHKConnect: 0,
  },
  {
    no: "1076",
    name_en: "IMPERIAL PACIFIC INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "博華太平洋",
    isHKConnect: 1,
  },
  {
    no: "1079",
    name_en: "PINE TECHNOLOGY HOLDINGS LIMITED",
    name_zh: "松景科技",
    isHKConnect: 0,
  },
  {
    no: "1080",
    name_en: "SHENGLI OIL & GAS PIPE HOLDINGS LIMITED",
    name_zh: "勝利管道",
    isHKConnect: 0,
  },
  {
    no: "1082",
    name_en: "HONG KONG EDUCATION (INT'L) INVESTMENTS LIMITED",
    name_zh: "香港教育國際",
    isHKConnect: 0,
  },
  {
    no: "1083",
    name_en: "TOWNGAS SMART ENERGY COMPANY LIMITED",
    name_zh: "港華智慧能源",
    isHKConnect: 1,
  },
  {
    no: "1084",
    name_en: "GREEN FUTURE FOOD HYDROCOLLOID MARINE SCIENCE COMPANY LIMITED",
    name_zh: "綠新親水膠體",
    isHKConnect: 0,
  },
  {
    no: "1085",
    name_en: "HENGXIN TECHNOLOGY LTD.",
    name_zh: "亨鑫科技",
    isHKConnect: 0,
  },
  {
    no: "1086",
    name_en: "GOODBABY INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "好孩子國際",
    isHKConnect: 1,
  },
  {
    no: "1087",
    name_en: "INVESTECH HOLDINGS LIMITED",
    name_zh: "威訊控股",
    isHKConnect: 0,
  },
  {
    no: "1088",
    name_en: "CHINA SHENHUA ENERGY COMPANY LIMITED",
    name_zh: "中國神華",
    isHKConnect: 1,
  },
  {
    no: "1090",
    name_en: "DA MING INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "大明國際",
    isHKConnect: 0,
  },
  {
    no: "1091",
    name_en: "SOUTH MANGANESE INVESTMENT LIMITED",
    name_zh: "南方錳業",
    isHKConnect: 0,
  },
  {
    no: "1093",
    name_en: "CSPC PHARMACEUTICAL GROUP LIMITED",
    name_zh: "石藥集團",
    isHKConnect: 1,
  },
  {
    no: "1094",
    name_en: "CHERISH SUNSHINE INTERNATIONAL LIMITED",
    name_zh: "承輝國際",
    isHKConnect: 0,
  },
  {
    no: "1096",
    name_en: "SINO ENERGY INTERNATIONAL HOLDINGS GROUP LIMITED",
    name_zh: "中能國際控股",
    isHKConnect: 0,
  },
  {
    no: "1097",
    name_en: "I-CABLE COMMUNICATIONS LIMITED",
    name_zh: "有線寬頻",
    isHKConnect: 1,
  },
  {
    no: "1098",
    name_en: "ROAD KING INFRASTRUCTURE LIMITED",
    name_zh: "路勁",
    isHKConnect: 1,
  },
  {
    no: "1099",
    name_en: "SINOPHARM GROUP CO. LTD.",
    name_zh: "國藥控股",
    isHKConnect: 1,
  },
  {
    no: "1100",
    name_en: "MAINLAND HEADWEAR HOLDINGS LIMITED",
    name_zh: "飛達控股",
    isHKConnect: 0,
  },
  {
    no: "1101",
    name_en: "CHINA HUARONG ENERGY COMPANY LIMITED",
    name_zh: "華榮能源",
    isHKConnect: 0,
  },
  {
    no: "1102",
    name_en: "ENVIRO ENERGY INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "環能國際",
    isHKConnect: 0,
  },
  {
    no: "1103",
    name_en: "SHANGHAI DASHENG AGRICULTURE FINANCE TECHNOLOGY CO., LTD.",
    name_zh: "大生農業金融",
    isHKConnect: 0,
  },
  {
    no: "1104",
    name_en: "APAC RESOURCES LIMITED",
    name_zh: "亞太資源",
    isHKConnect: 0,
  },
  {
    no: "1105",
    name_en: "SING TAO NEWS CORPORATION LIMITED",
    name_zh: "星島",
    isHKConnect: 0,
  },
  {
    no: "1107",
    name_en: "MODERN LAND (CHINA) CO., LIMITED",
    name_zh: "當代置業",
    isHKConnect: 0,
  },
  {
    no: "1108",
    name_en: "LUOYANG GLASS COMPANY LIMITED",
    name_zh: "洛陽玻璃股份",
    isHKConnect: 1,
  },
  {
    no: "1109",
    name_en: "CHINA RESOURCES LAND LIMITED",
    name_zh: "華潤置地",
    isHKConnect: 1,
  },
  {
    no: "1110",
    name_en: "KINGWORLD MEDICINES GROUP LIMITED",
    name_zh: "金活醫藥集團",
    isHKConnect: 0,
  },
  {
    no: "1112",
    name_en: "HEALTH AND HAPPINESS (H&H) INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "H&H國際控股",
    isHKConnect: 1,
  },
  {
    no: "1113",
    name_en: "CK ASSET HOLDINGS LIMITED",
    name_zh: "長實集團",
    isHKConnect: 1,
  },
  {
    no: "1114",
    name_en: "BRILLIANCE CHINA AUTOMOTIVE HOLDINGS LIMITED",
    name_zh: "BRILLIANCE CHI",
    isHKConnect: 1,
  },
  {
    no: "1115",
    name_en: "TIBET WATER RESOURCES LTD.",
    name_zh: "西藏水資源",
    isHKConnect: 1,
  },
  {
    no: "1116",
    name_en: "MAYER HOLDINGS LIMITED",
    name_zh: "MAYER HOLDINGS LIMITED",
    isHKConnect: 0,
  },
  {
    no: "1117",
    name_en: "CHINA MODERN DAIRY HOLDINGS LTD.",
    name_zh: "現代牧業",
    isHKConnect: 1,
  },
  {
    no: "1118",
    name_en: "GOLIK HOLDINGS LIMITED",
    name_zh: "高力集團",
    isHKConnect: 0,
  },
  {
    no: "1119",
    name_en: "IDREAMSKY TECHNOLOGY HOLDINGS LIMITED",
    name_zh: "創夢天地",
    isHKConnect: 1,
  },
  {
    no: "1120",
    name_en: "ARTS OPTICAL INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "雅視光學",
    isHKConnect: 0,
  },
  {
    no: "1121",
    name_en: "GOLDEN SOLAR NEW ENERGY TECHNOLOGY HOLDINGS LIMITED",
    name_zh: "金陽新能源",
    isHKConnect: 1,
  },
  {
    no: "1122",
    name_en: "QINGLING MOTORS COMPANY LIMITED",
    name_zh: "慶鈴汽車股份",
    isHKConnect: 0,
  },
  {
    no: "1123",
    name_en: "CHINA-HONGKONG PHOTO PRODUCTS HOLDINGS LIMITED",
    name_zh: "中港照相",
    isHKConnect: 0,
  },
  {
    no: "1124",
    name_en: "COASTAL GREENLAND LIMITED",
    name_zh: "沿海家園",
    isHKConnect: 0,
  },
  {
    no: "1125",
    name_en: "LAI FUNG HOLDINGS LIMITED",
    name_zh: "麗豐控股",
    isHKConnect: 0,
  },
  {
    no: "1126",
    name_en: "DREAM INTERNATIONAL LIMITED",
    name_zh: "德林國際",
    isHKConnect: 0,
  },
  {
    no: "1127",
    name_en: "LION ROCK GROUP LIMITED",
    name_zh: "獅子山集團",
    isHKConnect: 0,
  },
  {
    no: "1128",
    name_en: "WYNN MACAU, LIMITED",
    name_zh: "永利澳門",
    isHKConnect: 1,
  },
  {
    no: "1129",
    name_en: "CHINA WATER INDUSTRY GROUP LIMITED",
    name_zh: "中國水業集團",
    isHKConnect: 0,
  },
  {
    no: "1130",
    name_en: "CHINA ENVIRONMENTAL RESOURCES GROUP LIMITED",
    name_zh: "中國環境資源",
    isHKConnect: 0,
  },
  {
    no: "1132",
    name_en: "ORANGE SKY GOLDEN HARVEST ENTERTAINMENT (HOLDINGS) LIMITED",
    name_zh: "橙天嘉禾",
    isHKConnect: 0,
  },
  {
    no: "1133",
    name_en: "HARBIN ELECTRIC COMPANY LIMITED",
    name_zh: "哈爾濱電氣",
    isHKConnect: 0,
  },
  {
    no: "1134",
    name_en: "KELFRED HOLDINGS LIMITED",
    name_zh: "恆發光學",
    isHKConnect: 0,
  },
  {
    no: "1137",
    name_en: "HONG KONG TECHNOLOGY VENTURE COMPANY LIMITED",
    name_zh: "香港科技探索",
    isHKConnect: 1,
  },
  {
    no: "1138",
    name_en: "COSCO SHIPPING ENERGY TRANSPORTATION CO., LTD.",
    name_zh: "中遠海能",
    isHKConnect: 1,
  },
  {
    no: "1140",
    name_en: "WEALTHKING INVESTMENTS LIMITED",
    name_zh: "華科資本",
    isHKConnect: 1,
  },
  {
    no: "1141",
    name_en: "CMBC CAPITAL HOLDINGS LIMITED",
    name_zh: "民銀資本",
    isHKConnect: 1,
  },
  {
    no: "1142",
    name_en: "E&P GLOBAL HOLDINGS LIMITED",
    name_zh: "能源及能量環球",
    isHKConnect: 0,
  },
  {
    no: "1143",
    name_en: "LINK-ASIA INTERNATIONAL MEDTECH GROUP LIMITED",
    name_zh: "環亞國際醫療集團",
    isHKConnect: 0,
  },
  {
    no: "1145",
    name_en: "COURAGE INVESTMENT GROUP LIMITED",
    name_zh: "勇利投資",
    isHKConnect: 0,
  },
  {
    no: "1146",
    name_en: "CHINA OUTFITTERS HOLDINGS LIMITED",
    name_zh: "中國服飾控股",
    isHKConnect: 0,
  },
  {
    no: "1147",
    name_en: "EDENSOFT HOLDINGS LIMITED",
    name_zh: "伊登軟件",
    isHKConnect: 0,
  },
  {
    no: "1148",
    name_en: "XINCHEN CHINA POWER HOLDINGS LIMITED",
    name_zh: "新晨動力",
    isHKConnect: 0,
  },
  {
    no: "1150",
    name_en: "MILAN STATION HOLDINGS LIMITED",
    name_zh: "米蘭站",
    isHKConnect: 0,
  },
  {
    no: "1152",
    name_en: "MOMENTUM FINANCIAL HOLDINGS LIMITED",
    name_zh: "正乾金融控股",
    isHKConnect: 0,
  },
  {
    no: "1153",
    name_en: "JIAYUAN SERVICES HOLDINGS LIMITED",
    name_zh: "佳源服務",
    isHKConnect: 0,
  },
  {
    no: "1156",
    name_en: "CHINA NEW ENERGY LIMITED",
    name_zh: "CHINA NEW ENERGY LIMITED",
    isHKConnect: 0,
  },
  {
    no: "1157",
    name_en: "ZOOMLION HEAVY INDUSTRY SCIENCE AND TECHNOLOGY CO., LTD.",
    name_zh: "中聯重科",
    isHKConnect: 1,
  },
  {
    no: "1159",
    name_en: "STARLIGHT CULTURE ENTERTAINMENT GROUP LIMITED",
    name_zh: "星光文化",
    isHKConnect: 0,
  },
  {
    no: "1160",
    name_en: "GOLDSTONE CAPITAL GROUP LIMITED",
    name_zh: "金石資本集團",
    isHKConnect: 0,
  },
  {
    no: "1161",
    name_en: "WATER OASIS GROUP LIMITED",
    name_zh: "奧思集團",
    isHKConnect: 0,
  },
  {
    no: "1162",
    name_en: "LUMINA GROUP LIMITED",
    name_zh: "瑩嵐集團",
    isHKConnect: 0,
  },
  {
    no: "1163",
    name_en: "ADTIGER CORPORATIONS LIMITED",
    name_zh: "虎視傳媒",
    isHKConnect: 0,
  },
  {
    no: "1164",
    name_en: "CGN MINING COMPANY LIMITED",
    name_zh: "中廣核礦業",
    isHKConnect: 0,
  },
  {
    no: "1165",
    name_en: "SHUNFENG INTERNATIONAL CLEAN ENERGY LIMITED",
    name_zh: "順風清潔能源",
    isHKConnect: 1,
  },
  {
    no: "1166",
    name_en: "SOLARTECH INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "星凱控股",
    isHKConnect: 0,
  },
  {
    no: "1167",
    name_en: "JACOBIO PHARMACEUTICALS GROUP CO., LTD.",
    name_zh: "加科思-B",
    isHKConnect: 1,
  },
  {
    no: "1168",
    name_en: "SINOLINK WORLDWIDE HOLDINGS LIMITED",
    name_zh: "百仕達控股",
    isHKConnect: 0,
  },
  {
    no: "1170",
    name_en: "KINGMAKER FOOTWEAR HOLDINGS LIMITED",
    name_zh: "信星集團",
    isHKConnect: 0,
  },
  {
    no: "1171",
    name_en: "YANKUANG ENERGY GROUP COMPANY LIMITED",
    name_zh: "兗礦能源",
    isHKConnect: 1,
  },
  {
    no: "1172",
    name_en: "MAGNUS CONCORDIA GROUP LIMITED",
    name_zh: "融太集團",
    isHKConnect: 0,
  },
  {
    no: "1173",
    name_en: "VEEKO INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "威高國際",
    isHKConnect: 0,
  },
  {
    no: "1175",
    name_en: "FRESH EXPRESS DELIVERY HOLDINGS GROUP CO., LIMITED",
    name_zh: "鮮馳達控股",
    isHKConnect: 0,
  },
  {
    no: "1176",
    name_en: "ZHUGUANG HOLDINGS GROUP COMPANY LIMITED",
    name_zh: "珠光控股",
    isHKConnect: 1,
  },
  {
    no: "1177",
    name_en: "SINO BIOPHARMACEUTICAL LIMITED",
    name_zh: "中國生物製藥",
    isHKConnect: 1,
  },
  {
    no: "1179",
    name_en: "H WORLD GROUP LIMITED",
    name_zh: "華住集團-S",
    isHKConnect: 0,
  },
  {
    no: "1180",
    name_en: "PARADISE ENTERTAINMENT LIMITED",
    name_zh: "匯彩控股",
    isHKConnect: 0,
  },
  {
    no: "1181",
    name_en: "TANG PALACE (CHINA) HOLDINGS LIMITED",
    name_zh: "唐宮中國",
    isHKConnect: 0,
  },
  {
    no: "1182",
    name_en: "SUCCESS DRAGON INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "勝龍國際",
    isHKConnect: 0,
  },
  {
    no: "1183",
    name_en: "MECOM POWER AND CONSTRUCTION LIMITED",
    name_zh: "澳能建設",
    isHKConnect: 0,
  },
  {
    no: "1184",
    name_en: "S.A.S. DRAGON HOLDINGS LIMITED",
    name_zh: "S.A.S. DRAGON",
    isHKConnect: 0,
  },
  {
    no: "1185",
    name_en: "CHINA ENERGINE INTERNATIONAL (HOLDINGS) LIMITED",
    name_zh: "中國航天萬源",
    isHKConnect: 0,
  },
  {
    no: "1186",
    name_en: "CHINA RAILWAY CONSTRUCTION CORPORATION LIMITED",
    name_zh: "中國鐵建",
    isHKConnect: 1,
  },
  {
    no: "1188",
    name_en: "HYBRID KINETIC GROUP LIMITED",
    name_zh: "正道集團",
    isHKConnect: 1,
  },
  {
    no: "1189",
    name_en: "GREATER BAY AREA DYNAMIC GROWTH HOLDING LIMITED",
    name_zh: "大灣區聚變力量",
    isHKConnect: 0,
  },
  {
    no: "1191",
    name_en: "CHINA GEM HOLDINGS LIMITED",
    name_zh: "中國中石控股",
    isHKConnect: 0,
  },
  {
    no: "1192",
    name_en: "TITAN PETROCHEMICALS GROUP LIMITED",
    name_zh: "泰山石化",
    isHKConnect: 0,
  },
  {
    no: "1193",
    name_en: "CHINA RESOURCES GAS GROUP LIMITED",
    name_zh: "華潤燃氣",
    isHKConnect: 1,
  },
  {
    no: "1194",
    name_en: "BAY AREA GOLD GROUP LIMITED",
    name_zh: "灣區黃金",
    isHKConnect: 0,
  },
  {
    no: "1195",
    name_en: "KINGWELL GROUP LIMITED",
    name_zh: "京維集團",
    isHKConnect: 0,
  },
  {
    no: "1196",
    name_en: "REALORD GROUP HOLDINGS LIMITED",
    name_zh: "偉祿集團",
    isHKConnect: 1,
  },
  {
    no: "1198",
    name_en: "ROYALE HOME HOLDINGS LIMITED",
    name_zh: "皇朝家居",
    isHKConnect: 0,
  },
  {
    no: "1199",
    name_en: "COSCO SHIPPING PORTS LIMITED",
    name_zh: "中遠海運港口",
    isHKConnect: 1,
  },
  {
    no: "1200",
    name_en: "MIDLAND HOLDINGS LIMITED",
    name_zh: "美聯集團",
    isHKConnect: 0,
  },
  {
    no: "1201",
    name_en: "TESSON HOLDINGS LIMITED",
    name_zh: "天臣控股",
    isHKConnect: 0,
  },
  {
    no: "1202",
    name_en: "CHENGDU SIWI SCIENCE & TECHNOLOGY COMPANY LIMITED",
    name_zh: "四威科技",
    isHKConnect: 0,
  },
  {
    no: "1203",
    name_en: "GDH GUANGNAN (HOLDINGS) LIMITED",
    name_zh: "廣南(集團)",
    isHKConnect: 0,
  },
  {
    no: "1204",
    name_en: "BOARDWARE INTELLIGENCE TECHNOLOGY LIMITED",
    name_zh: "博維智慧",
    isHKConnect: 0,
  },
  {
    no: "1205",
    name_en: "CITIC RESOURCES HOLDINGS LIMITED",
    name_zh: "中信資源",
    isHKConnect: 1,
  },
  {
    no: "1206",
    name_en: "TECHNOVATOR INTERNATIONAL LIMITED",
    name_zh: "同方泰德",
    isHKConnect: 0,
  },
  {
    no: "1207",
    name_en: "SRE GROUP LIMITED",
    name_zh: "上置集團",
    isHKConnect: 0,
  },
  { no: "1208", name_en: "MMG LIMITED", name_zh: "五礦資源", isHKConnect: 1 },
  {
    no: "1209",
    name_en: "CHINA RESOURCES MIXC LIFESTYLE SERVICES LIMITED",
    name_zh: "華潤萬象生活",
    isHKConnect: 1,
  },
  {
    no: "1210",
    name_en: "CHRISTINE INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "克莉絲汀",
    isHKConnect: 0,
  },
  {
    no: "1211",
    name_en: "BYD COMPANY LIMITED",
    name_zh: "比亞迪股份",
    isHKConnect: 1,
  },
  {
    no: "1212",
    name_en: "LIFESTYLE INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "利福國際",
    isHKConnect: 1,
  },
  {
    no: "1213",
    name_en: "MOBICON GROUP LIMITED",
    name_zh: "萬保剛集團",
    isHKConnect: 0,
  },
  {
    no: "1215",
    name_en: "KAI YUAN HOLDINGS LIMITED",
    name_zh: "開源控股",
    isHKConnect: 0,
  },
  {
    no: "1216",
    name_en: "ZHONGYUAN BANK CO., LTD.",
    name_zh: "中原銀行",
    isHKConnect: 0,
  },
  {
    no: "1217",
    name_en: "CHINA INNOVATION INVESTMENT LIMITED",
    name_zh: "中國創新投資",
    isHKConnect: 0,
  },
  {
    no: "1218",
    name_en: "EASYKNIT INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "永義國際",
    isHKConnect: 0,
  },
  {
    no: "1220",
    name_en: "ZHIDAO INTERNATIONAL (HOLDINGS) LIMITED",
    name_zh: "志道國際",
    isHKConnect: 0,
  },
  {
    no: "1221",
    name_en: "SINO HOTELS (HOLDINGS) LIMITED",
    name_zh: "SINO HOTELS",
    isHKConnect: 0,
  },
  {
    no: "1222",
    name_en: "WANG ON GROUP LIMITED",
    name_zh: "WANG ON GROUP",
    isHKConnect: 0,
  },
  {
    no: "1223",
    name_en: "SYMPHONY HOLDINGS LIMITED",
    name_zh: "新灃集團",
    isHKConnect: 0,
  },
  {
    no: "1224",
    name_en: "C C LAND HOLDINGS LIMITED",
    name_zh: "中渝置地",
    isHKConnect: 0,
  },
  {
    no: "1225",
    name_en: "LERADO FINANCIAL GROUP COMPANY LIMITED",
    name_zh: "隆成金融",
    isHKConnect: 0,
  },
  {
    no: "1226",
    name_en: "CHINA INVESTMENT AND FINANCE GROUP LIMITED",
    name_zh: "中國投融資",
    isHKConnect: 0,
  },
  {
    no: "1228",
    name_en: "CANBRIDGE PHARMACEUTICALS INC.",
    name_zh: "北海康成-B",
    isHKConnect: 0,
  },
  {
    no: "1229",
    name_en: "NAN NAN RESOURCES ENTERPRISE LIMITED",
    name_zh: "南南資源",
    isHKConnect: 0,
  },
  {
    no: "1230",
    name_en: "YASHILI INTERNATIONAL HOLDINGS LTD",
    name_zh: "雅士利國際",
    isHKConnect: 1,
  },
  {
    no: "1231",
    name_en: "NEWTON RESOURCES LTD",
    name_zh: "新礦資源",
    isHKConnect: 0,
  },
  {
    no: "1232",
    name_en: "GOLDEN WHEEL TIANDI HOLDINGS COMPANY LIMITED",
    name_zh: "金輪天地控股",
    isHKConnect: 0,
  },
  {
    no: "1233",
    name_en: "TIMES CHINA HOLDINGS LIMITED",
    name_zh: "時代中國控股",
    isHKConnect: 1,
  },
  {
    no: "1234",
    name_en: "CHINA LILANG LIMITED",
    name_zh: "中國利郎",
    isHKConnect: 1,
  },
  {
    no: "1235",
    name_en: "TRAVEL EXPERT (ASIA) ENTERPRISES LIMITED",
    name_zh: "專業旅運",
    isHKConnect: 0,
  },
  {
    no: "1237",
    name_en: "CHINA ENVIRONMENTAL TECHNOLOGY AND BIOENERGY HOLDINGS LIMITED",
    name_zh: "中科生物",
    isHKConnect: 0,
  },
  {
    no: "1238",
    name_en: "POWERLONG REAL ESTATE HOLDINGS LIMITED",
    name_zh: "寶龍地產",
    isHKConnect: 1,
  },
  {
    no: "1239",
    name_en: "TEAMWAY INTERNATIONAL GROUP HOLDINGS LIMITED",
    name_zh: "TEAMWAY INTL GP",
    isHKConnect: 0,
  },
  {
    no: "1240",
    name_en: "CNQC INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "青建國際",
    isHKConnect: 0,
  },
  {
    no: "1241",
    name_en: "SHUANGHUA HOLDINGS LIMITED",
    name_zh: "雙樺控股",
    isHKConnect: 0,
  },
  {
    no: "1243",
    name_en: "WANG ON PROPERTIES LIMITED",
    name_zh: "宏安地產",
    isHKConnect: 0,
  },
  {
    no: "1245",
    name_en: "NIRAKU GC HOLDINGS, INC.",
    name_zh: "NIRAKU GC HOLDINGS, INC.",
    isHKConnect: 0,
  },
  {
    no: "1246",
    name_en: "BOILL HEALTHCARE HOLDINGS LIMITED",
    name_zh: "保集健康",
    isHKConnect: 0,
  },
  {
    no: "1247",
    name_en: "MIKO INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "米格國際控股",
    isHKConnect: 0,
  },
  {
    no: "1250",
    name_en: "SHANDONG HI-SPEED NEW ENERGY GROUP LIMITED",
    name_zh: "山高新能源",
    isHKConnect: 1,
  },
  {
    no: "1251",
    name_en: "SPT ENERGY GROUP INC.",
    name_zh: "華油能源",
    isHKConnect: 0,
  },
  {
    no: "1252",
    name_en: "CHINA TIANRUI GROUP CEMENT COMPANY LIMITED",
    name_zh: "中國天瑞水泥",
    isHKConnect: 0,
  },
  {
    no: "1253",
    name_en: "CHINA GREENLAND BROAD GREENSTATE GROUP COMPANY LIMITED",
    name_zh: "中國綠地博大綠澤",
    isHKConnect: 1,
  },
  {
    no: "1255",
    name_en: "TATA HEALTH INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "TATA健康",
    isHKConnect: 0,
  },
  {
    no: "1257",
    name_en: "CHINA EVERBRIGHT GREENTECH LIMITED",
    name_zh: "中國光大綠色環保",
    isHKConnect: 1,
  },
  {
    no: "1258",
    name_en: "CHINA NONFERROUS MINING CORPORATION LIMITED",
    name_zh: "中國有色礦業",
    isHKConnect: 1,
  },
  {
    no: "1259",
    name_en: "PROSPEROUS FUTURE HOLDINGS LIMITED",
    name_zh: "未來發展控股",
    isHKConnect: 0,
  },
  {
    no: "1260",
    name_en: "WONDERFUL SKY FINANCIAL GROUP HOLDINGS LIMITED",
    name_zh: "皓天財經集團",
    isHKConnect: 0,
  },
  {
    no: "1262",
    name_en: "LABIXIAOXIN SNACKS GROUP LIMITED",
    name_zh: "蠟筆小新食品",
    isHKConnect: 0,
  },
  {
    no: "1263",
    name_en: "PC PARTNER GROUP LIMITED",
    name_zh: "柏能集團",
    isHKConnect: 0,
  },
  {
    no: "1265",
    name_en: "TIANJIN JINRAN PUBLIC UTILITIES COMPANY LIMITED",
    name_zh: "天津津燃公用",
    isHKConnect: 0,
  },
  {
    no: "1266",
    name_en: "XIWANG SPECIAL STEEL COMPANY LIMITED",
    name_zh: "西王特鋼",
    isHKConnect: 0,
  },
  {
    no: "1268",
    name_en: "CHINA MEIDONG AUTO HOLDINGS LIMITED",
    name_zh: "美東汽車",
    isHKConnect: 1,
  },
  {
    no: "1269",
    name_en: "CHINA FIRST CAPITAL GROUP LIMITED",
    name_zh: "首控集團",
    isHKConnect: 1,
  },
  {
    no: "1270",
    name_en:
      "LANGHAM HOSPITALITY INVESTMENTS AND LANGHAM HOSPITALITY INVESTMENTS LIMITED",
    name_zh: "朗廷-SS",
    isHKConnect: 0,
  },
  {
    no: "1271",
    name_en: "GRAND MING GROUP HOLDINGS LIMITED",
    name_zh: "佳明集團控股",
    isHKConnect: 0,
  },
  {
    no: "1272",
    name_en: "DATANG ENVIRONMENT INDUSTRY GROUP CO., LTD.",
    name_zh: "大唐環境",
    isHKConnect: 0,
  },
  {
    no: "1273",
    name_en: "HONG KONG FINANCE GROUP LIMITED",
    name_zh: "香港信貸",
    isHKConnect: 0,
  },
  {
    no: "1277",
    name_en: "KINETIC DEVELOPMENT GROUP LIMITED",
    name_zh: "力量發展",
    isHKConnect: 0,
  },
  {
    no: "1278",
    name_en: "CHINA NEW TOWN DEVELOPMENT COMPANY LIMITED",
    name_zh: "中國新城鎮",
    isHKConnect: 0,
  },
  {
    no: "1280",
    name_en: "QIDIAN INTERNATIONAL CO., LTD.",
    name_zh: "奇點國際",
    isHKConnect: 0,
  },
  {
    no: "1281",
    name_en: "XINDA INVESTMENT HOLDINGS LIMITED",
    name_zh: "鑫達投資控股",
    isHKConnect: 0,
  },
  {
    no: "1282",
    name_en: "GLORY SUN FINANCIAL GROUP LIMITED",
    name_zh: "寶新金融",
    isHKConnect: 1,
  },
  {
    no: "1283",
    name_en: "ACCEL GROUP HOLDINGS LIMITED",
    name_zh: "高陞集團控股",
    isHKConnect: 0,
  },
  {
    no: "1285",
    name_en: "JIASHILI GROUP LIMITED",
    name_zh: "嘉士利集團",
    isHKConnect: 0,
  },
  {
    no: "1286",
    name_en: "IMPRO PRECISION INDUSTRIES LIMITED",
    name_zh: "鷹普精密",
    isHKConnect: 1,
  },
  {
    no: "1288",
    name_en: "AGRICULTURAL BANK OF CHINA LIMITED",
    name_zh: "農業銀行",
    isHKConnect: 1,
  },
  {
    no: "1289",
    name_en: "WUXI SUNLIT SCIENCE AND TECHNOLOGY COMPANY LIMITED",
    name_zh: "盛力達科技",
    isHKConnect: 0,
  },
  {
    no: "1290",
    name_en: "CHINA HUIRONG FINANCIAL HOLDINGS LIMITED",
    name_zh: "中國匯融",
    isHKConnect: 0,
  },
  {
    no: "1292",
    name_en: "CHANGAN MINSHENG APLL LOGISTICS CO., LTD",
    name_zh: "長安民生物流",
    isHKConnect: 0,
  },
  {
    no: "1293",
    name_en: "GRAND BAOXIN AUTO GROUP LIMITED",
    name_zh: "廣匯寶信",
    isHKConnect: 1,
  },
  {
    no: "1297",
    name_en: "SINOSOFT TECHNOLOGY GROUP LIMITED",
    name_zh: "中國擎天軟件",
    isHKConnect: 0,
  },
  {
    no: "1298",
    name_en: "YUNNAN ENERGY INTERNATIONAL CO. LIMITED",
    name_zh: "雲能國際",
    isHKConnect: 0,
  },
  {
    no: "1299",
    name_en: "AIA GROUP LIMITED",
    name_zh: "友邦保險",
    isHKConnect: 1,
  },
  {
    no: "1300",
    name_en: "TRIGIANT GROUP LIMITED",
    name_zh: "俊知集團",
    isHKConnect: 0,
  },
  {
    no: "1301",
    name_en: "D&G TECHNOLOGY HOLDING COMPANY LIMITED",
    name_zh: "德基科技控股",
    isHKConnect: 0,
  },
  {
    no: "1302",
    name_en: "LIFETECH SCIENTIFIC CORPORATION",
    name_zh: "先健科技",
    isHKConnect: 1,
  },
  {
    no: "1303",
    name_en: "HUILI RESOURCES (GROUP) LIMITED",
    name_zh: "匯力資源",
    isHKConnect: 0,
  },
  {
    no: "1305",
    name_en: "WAI CHI HOLDINGS COMPANY LIMITED",
    name_zh: "偉志控股",
    isHKConnect: 0,
  },
  {
    no: "1308",
    name_en: "SITC INTERNATIONAL HOLDINGS COMPANY LIMITED",
    name_zh: "海豐國際",
    isHKConnect: 1,
  },
  { no: "1310", name_en: "HKBN LTD.", name_zh: "香港寬頻", isHKConnect: 1 },
  {
    no: "1312",
    name_en: "KONTAFARMA CHINA HOLDINGS LIMITED",
    name_zh: "華控康泰",
    isHKConnect: 0,
  },
  {
    no: "1313",
    name_en: "CHINA RESOURCES CEMENT HOLDINGS LIMITED",
    name_zh: "華潤水泥控股",
    isHKConnect: 1,
  },
  {
    no: "1314",
    name_en: "TSUI WAH HOLDINGS LIMITED",
    name_zh: "翠華控股",
    isHKConnect: 0,
  },
  {
    no: "1315",
    name_en: "GREEN ECONOMY DEVELOPMENT LIMITED",
    name_zh: "綠色經濟",
    isHKConnect: 1,
  },
  {
    no: "1316",
    name_en: "NEXTEER AUTOMOTIVE GROUP LIMITED",
    name_zh: "耐世特",
    isHKConnect: 1,
  },
  {
    no: "1317",
    name_en: "CHINA MAPLE LEAF EDUCATIONAL SYSTEMS LIMITED",
    name_zh: "楓葉教育",
    isHKConnect: 1,
  },
  {
    no: "1319",
    name_en: "OI WAH PAWNSHOP CREDIT HOLDINGS LIMITED",
    name_zh: "靄華押業信貸",
    isHKConnect: 0,
  },
  {
    no: "1321",
    name_en: "CHINA NEW CITY COMMERCIAL DEVELOPMENT LIMITED",
    name_zh: "中國新城市",
    isHKConnect: 0,
  },
  {
    no: "1323",
    name_en: "HUASHENG INTERNATIONAL HOLDING LIMITED",
    name_zh: "華盛國際控股",
    isHKConnect: 0,
  },
  {
    no: "1326",
    name_en: "TRANSMIT ENTERTAINMENT LIMITED",
    name_zh: "傳遞娛樂",
    isHKConnect: 0,
  },
  {
    no: "1327",
    name_en: "LUXXU GROUP LIMITED",
    name_zh: "勵時集團",
    isHKConnect: 0,
  },
  {
    no: "1328",
    name_en: "GOLDSTREAM INVESTMENT LIMITED",
    name_zh: "金涌投資",
    isHKConnect: 0,
  },
  {
    no: "1329",
    name_en: "BEIJING CAPITAL GRAND LIMITED",
    name_zh: "首創鉅大",
    isHKConnect: 0,
  },
  {
    no: "1330",
    name_en: "DYNAGREEN ENVIRONMENTAL PROTECTION GROUP CO., LTD",
    name_zh: "綠色動力環保",
    isHKConnect: 1,
  },
  {
    no: "1332",
    name_en: "TOUYUN BIOTECH GROUP LIMITED",
    name_zh: "透雲生物",
    isHKConnect: 0,
  },
  {
    no: "1333",
    name_en: "CHINA ZHONGWANG HOLDINGS LIMITED",
    name_zh: "中國忠旺",
    isHKConnect: 1,
  },
  {
    no: "1335",
    name_en: "SHEEN TAI HOLDINGS GROUP COMPANY LIMITED",
    name_zh: "順泰控股",
    isHKConnect: 0,
  },
  {
    no: "1336",
    name_en: "NEW CHINA LIFE INSURANCE COMPANY LTD.",
    name_zh: "新華保險",
    isHKConnect: 1,
  },
  {
    no: "1338",
    name_en: "BAWANG INTERNATIONAL (GROUP) HOLDING LIMITED",
    name_zh: "霸王集團",
    isHKConnect: 0,
  },
  {
    no: "1339",
    name_en: "PEOPLE'S INSURANCE COMPANY (GROUP) OF CHINA LIMITED, THE",
    name_zh: "中國人民保險集團",
    isHKConnect: 1,
  },
  {
    no: "1340",
    name_en: "HUISHENG INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "惠生國際",
    isHKConnect: 0,
  },
  {
    no: "1341",
    name_en: "HAO TIAN INTERNATIONAL CONSTRUCTION INVESTMENT GROUP LIMITED",
    name_zh: "昊天國際建投",
    isHKConnect: 0,
  },
  {
    no: "1343",
    name_en: "WEI YUAN HOLDINGS LIMITED",
    name_zh: "偉源控股",
    isHKConnect: 0,
  },
  {
    no: "1345",
    name_en: "CHINA PIONEER PHARMA HOLDINGS LIMITED",
    name_zh: "中國先鋒醫藥",
    isHKConnect: 0,
  },
  {
    no: "1346",
    name_en: "LEVER STYLE CORPORATION",
    name_zh: "利華控股集團",
    isHKConnect: 0,
  },
  {
    no: "1347",
    name_en: "HUA HONG SEMICONDUCTOR LIMITED",
    name_zh: "華虹半導體",
    isHKConnect: 1,
  },
  {
    no: "1348",
    name_en: "QUALI-SMART HOLDINGS LIMITED",
    name_zh: "滉達富控股",
    isHKConnect: 0,
  },
  {
    no: "1349",
    name_en: "SHANGHAI FUDAN-ZHANGJIANG BIO-PHARMACEUTICAL CO., LTD.",
    name_zh: "復旦張江",
    isHKConnect: 1,
  },
  {
    no: "1351",
    name_en: "BRIGHT FUTURE TECHNOLOGY HOLDINGS LIMITED",
    name_zh: "輝煌明天",
    isHKConnect: 0,
  },
  {
    no: "1355",
    name_en: "LEGEND STRATEGY INTERNATIONAL HOLDINGS GROUP COMPANY LIMITED",
    name_zh: "朸濬國際",
    isHKConnect: 0,
  },
  { no: "1357", name_en: "MEITU, INC.", name_zh: "美圖公司", isHKConnect: 1 },
  {
    no: "1358",
    name_en: "PW MEDTECH GROUP LIMITED",
    name_zh: "普華和順",
    isHKConnect: 0,
  },
  {
    no: "1359",
    name_en: "CHINA CINDA ASSET MANAGEMENT CO., LTD.",
    name_zh: "中國信達",
    isHKConnect: 1,
  },
  {
    no: "1360",
    name_en: "NOVA GROUP HOLDINGS LIMITED",
    name_zh: "諾發集團",
    isHKConnect: 0,
  },
  {
    no: "1361",
    name_en: "361 DEGREES INTERNATIONAL LIMITED",
    name_zh: "361度",
    isHKConnect: 1,
  },
  {
    no: "1362",
    name_en: "SIS MOBILE HOLDINGS LIMITED",
    name_zh: "新龍移動",
    isHKConnect: 0,
  },
  {
    no: "1366",
    name_en: "JIANGNAN GROUP LIMITED",
    name_zh: "江南集團",
    isHKConnect: 0,
  },
  {
    no: "1368",
    name_en: "XTEP INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "特步國際",
    isHKConnect: 1,
  },
  {
    no: "1370",
    name_en: "AOWEI HOLDING LIMITED",
    name_zh: "奧威控股",
    isHKConnect: 0,
  },
  {
    no: "1371",
    name_en: "CHINA ECOTOURISM GROUP LIMITED",
    name_zh: "中國生態旅遊",
    isHKConnect: 0,
  },
  {
    no: "1372",
    name_en: "CHINA CARBON NEUTRAL DEVELOPMENT GROUP LIMITED",
    name_zh: "中國碳中和",
    isHKConnect: 0,
  },
  {
    no: "1373",
    name_en: "INTERNATIONAL HOUSEWARES RETAIL COMPANY LIMITED",
    name_zh: "國際家居零售",
    isHKConnect: 0,
  },
  {
    no: "1375",
    name_en: "CENTRAL CHINA SECURITIES CO., LTD.",
    name_zh: "中州證券",
    isHKConnect: 1,
  },
  {
    no: "1376",
    name_en: "RAFFLES INTERIOR LIMITED",
    name_zh: "RAFFLES INTERIOR LIMITED",
    isHKConnect: 0,
  },
  {
    no: "1378",
    name_en: "CHINA HONGQIAO GROUP LIMITED",
    name_zh: "中國宏橋",
    isHKConnect: 1,
  },
  {
    no: "1379",
    name_en:
      "WENLING ZHEJIANG MEASURING AND CUTTING TOOLS TRADING CENTRE COMPANY LIMITED",
    name_zh: "溫嶺工量刃具",
    isHKConnect: 0,
  },
  {
    no: "1380",
    name_en: "CHINA KINGSTONE MINING HOLDINGS LIMITED",
    name_zh: "中國金石",
    isHKConnect: 0,
  },
  {
    no: "1381",
    name_en: "CANVEST ENVIRONMENTAL PROTECTION GROUP COMPANY LIMITED",
    name_zh: "粵豐環保",
    isHKConnect: 1,
  },
  {
    no: "1382",
    name_en: "PACIFIC TEXTILES HOLDINGS LIMITED",
    name_zh: "互太紡織",
    isHKConnect: 1,
  },
  {
    no: "1383",
    name_en: "LET GROUP HOLDINGS LIMITED",
    name_zh: "太陽城集團",
    isHKConnect: 1,
  },
  {
    no: "1385",
    name_en: "SHANGHAI FUDAN MICROELECTRONICS GROUP COMPANY LIMITED",
    name_zh: "上海復旦",
    isHKConnect: 1,
  },
  {
    no: "1386",
    name_en: "VESTATE GROUP HOLDINGS LIMITED",
    name_zh: "國投集團控股",
    isHKConnect: 0,
  },
  {
    no: "1387",
    name_en: "CHINA DILI GROUP",
    name_zh: "中國地利",
    isHKConnect: 1,
  },
  {
    no: "1388",
    name_en: "EMBRY HOLDINGS LIMITED",
    name_zh: "安莉芳控股",
    isHKConnect: 0,
  },
  {
    no: "1389",
    name_en: "MAJOR HOLDINGS LIMITED",
    name_zh: "美捷匯控股",
    isHKConnect: 0,
  },
  {
    no: "1393",
    name_en: "HIDILI INDUSTRY INTERNATIONAL DEVELOPMENT LIMITED",
    name_zh: "恆鼎實業",
    isHKConnect: 0,
  },
  {
    no: "1395",
    name_en: "ELL ENVIRONMENTAL HOLDINGS LIMITED",
    name_zh: "強泰環保",
    isHKConnect: 0,
  },
  {
    no: "1396",
    name_en: "GUANGDONG-HONG KONG GREATER BAY AREA HOLDINGS LIMITED-NEW",
    name_zh: "粵港灣控股(新)",
    isHKConnect: 0,
  },
  {
    no: "1397",
    name_en: "BAGUIO GREEN GROUP LIMITED",
    name_zh: "碧瑤綠色集團",
    isHKConnect: 0,
  },
  {
    no: "1398",
    name_en: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA LIMITED",
    name_zh: "工商銀行",
    isHKConnect: 1,
  },
  {
    no: "1399",
    name_en: "VESON HOLDINGS LIMITED",
    name_zh: "銳信控股",
    isHKConnect: 0,
  },
  {
    no: "1400",
    name_en: "MOODY TECHNOLOGY HOLDINGS LIMITED",
    name_zh: "滿地科技股份",
    isHKConnect: 0,
  },
  {
    no: "1401",
    name_en: "SPROCOMM INTELLIGENCE LIMITED",
    name_zh: "SPROCOMM INTELLIGENCE LIMITED",
    isHKConnect: 0,
  },
  {
    no: "1402",
    name_en: "I-CONTROL HOLDINGS LIMITED",
    name_zh: "超智能控股",
    isHKConnect: 0,
  },
  {
    no: "1406",
    name_en: "CLARITY MEDICAL GROUP HOLDING LIMITED",
    name_zh: "清晰醫療",
    isHKConnect: 0,
  },
  {
    no: "1408",
    name_en: "MACAU E&M HOLDING LIMITED",
    name_zh: "濠江機電",
    isHKConnect: 0,
  },
  {
    no: "1410",
    name_en: "EDVANCE INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "安領國際",
    isHKConnect: 0,
  },
  {
    no: "1412",
    name_en: "Q P GROUP HOLDINGS LIMITED",
    name_zh: "雋思集團",
    isHKConnect: 0,
  },
  {
    no: "1413",
    name_en: "KWONG LUEN ENGINEERING HOLDINGS LIMITED",
    name_zh: "廣聯工程控股",
    isHKConnect: 0,
  },
  {
    no: "1415",
    name_en: "COWELL E HOLDINGS INC.",
    name_zh: "高偉電子",
    isHKConnect: 0,
  },
  {
    no: "1416",
    name_en: "CTR HOLDINGS LIMITED",
    name_zh: "CTR HOLDINGS LIMITED",
    isHKConnect: 0,
  },
  {
    no: "1417",
    name_en: "RIVERINE CHINA HOLDINGS LIMITED",
    name_zh: "浦江中國",
    isHKConnect: 0,
  },
  {
    no: "1418",
    name_en: "SINOMAX GROUP LIMITED",
    name_zh: "盛諾集團",
    isHKConnect: 0,
  },
  {
    no: "1419",
    name_en: "HUMAN HEALTH HOLDINGS LIMITED",
    name_zh: "盈健醫療",
    isHKConnect: 0,
  },
  {
    no: "1420",
    name_en: "CHUAN HOLDINGS LIMITED",
    name_zh: "川控股",
    isHKConnect: 0,
  },
  {
    no: "1421",
    name_en: "KINGBO STRIKE LIMITED",
    name_zh: "工蓋有限公司",
    isHKConnect: 0,
  },
  {
    no: "1425",
    name_en: "JUSTIN ALLEN HOLDINGS LIMITED",
    name_zh: "捷隆控股",
    isHKConnect: 0,
  },
  {
    no: "1426",
    name_en: "SPRING REAL ESTATE INVESTMENT TRUST",
    name_zh: "春泉產業信託",
    isHKConnect: 0,
  },
  {
    no: "1427",
    name_en: "CHINA TIANBAO GROUP DEVELOPMENT COMPANY LIMITED",
    name_zh: "中國天保集團",
    isHKConnect: 0,
  },
  {
    no: "1428",
    name_en: "BRIGHT SMART SECURITIES & COMMODITIES GROUP LIMITED",
    name_zh: "耀才證券金融",
    isHKConnect: 0,
  },
  {
    no: "1429",
    name_en: "SKYMISSION GROUP HOLDINGS LIMITED",
    name_zh: "天任集團",
    isHKConnect: 0,
  },
  {
    no: "1431",
    name_en: "YUANSHENGTAI DAIRY FARM LIMITED",
    name_zh: "原生態牧業",
    isHKConnect: 0,
  },
  {
    no: "1432",
    name_en: "CHINA SHENGMU ORGANIC MILK LIMITED",
    name_zh: "中國聖牧",
    isHKConnect: 1,
  },
  {
    no: "1433",
    name_en: "CIRTEK HOLDINGS LIMITED",
    name_zh: "常達控股",
    isHKConnect: 0,
  },
  {
    no: "1439",
    name_en: "MOBILE INTERNET (CHINA) HOLDINGS LIMITED",
    name_zh: "移動互聯（中國）",
    isHKConnect: 0,
  },
  {
    no: "1440",
    name_en: "DEYUN HOLDING LTD.",
    name_zh: "DEYUN HOLDING LTD.",
    isHKConnect: 0,
  },
  {
    no: "1442",
    name_en: "INFINITY LOGISTICS AND TRANSPORT VENTURES LIMITED",
    name_zh: "INFINITY LOGISTICS AND TRANSPORT VENTURES LIMITED",
    isHKConnect: 0,
  },
  {
    no: "1443",
    name_en: "FULUM GROUP HOLDINGS LIMITED",
    name_zh: "富臨集團控股",
    isHKConnect: 0,
  },
  {
    no: "1446",
    name_en: "HUNG FOOK TONG GROUP HOLDINGS LIMITED",
    name_zh: "鴻福堂",
    isHKConnect: 0,
  },
  {
    no: "1447",
    name_en: "SFK CONSTRUCTION HOLDINGS LIMITED",
    name_zh: "新福港",
    isHKConnect: 0,
  },
  {
    no: "1448",
    name_en: "FU SHOU YUAN INTERNATIONAL GROUP LIMITED",
    name_zh: "福壽園",
    isHKConnect: 1,
  },
  {
    no: "1449",
    name_en: "LEADER EDUCATION LIMITED",
    name_zh: "立德教育",
    isHKConnect: 0,
  },
  {
    no: "1450",
    name_en: "CENTURY SAGE SCIENTIFIC HOLDINGS LIMITED",
    name_zh: "世紀睿科",
    isHKConnect: 0,
  },
  {
    no: "1451",
    name_en: "MS GROUP HOLDINGS LIMITED",
    name_zh: "萬成集團股份",
    isHKConnect: 0,
  },
  {
    no: "1452",
    name_en: "DENOX ENVIRONMENTAL & TECHNOLOGY HOLDINGS LIMITED",
    name_zh: "迪諾斯環保",
    isHKConnect: 0,
  },
  {
    no: "1455",
    name_en: "FOURACE INDUSTRIES GROUP HOLDINGS LIMITED",
    name_zh: "科利實業控股",
    isHKConnect: 0,
  },
  {
    no: "1456",
    name_en: "GUOLIAN SECURITIES CO., LTD.",
    name_zh: "國聯證券",
    isHKConnect: 1,
  },
  {
    no: "1458",
    name_en: "ZHOU HEI YA INTERNATIONAL HOLDINGS COMPANY LIMITED",
    name_zh: "周黑鴨",
    isHKConnect: 1,
  },
  {
    no: "1459",
    name_en: "JUJIANG CONSTRUCTION GROUP CO., LTD.",
    name_zh: "巨匠建設",
    isHKConnect: 0,
  },
  {
    no: "1460",
    name_en: "ICO GROUP LIMITED",
    name_zh: "揚科集團",
    isHKConnect: 0,
  },
  {
    no: "1461",
    name_en: "ZHONGTAI FUTURES COMPANY LIMITED",
    name_zh: "中泰期貨",
    isHKConnect: 0,
  },
  {
    no: "1463",
    name_en: "C-LINK SQUARED LIMITED",
    name_zh: "C-LINK SQUARED LIMITED",
    isHKConnect: 0,
  },
  {
    no: "1466",
    name_en: "AFFLUENT PARTNERS HOLDINGS LIMITED",
    name_zh: "錢唐控股",
    isHKConnect: 0,
  },
  {
    no: "1468",
    name_en: "KINGKEY FINANCIAL INTERNATIONAL (HOLDINGS) LIMITED",
    name_zh: "京基金融國際",
    isHKConnect: 0,
  },
  {
    no: "1469",
    name_en: "GET NICE FINANCIAL GROUP LIMITED",
    name_zh: "結好金融",
    isHKConnect: 0,
  },
  {
    no: "1470",
    name_en: "PROSPER ONE INTERNATIONAL HOLDINGS COMPANY LIMITED",
    name_zh: "富一國際控股",
    isHKConnect: 0,
  },
  {
    no: "1472",
    name_en: "SANG HING HOLDINGS (INTERNATIONAL) LIMITED",
    name_zh: "生興控股",
    isHKConnect: 0,
  },
  {
    no: "1473",
    name_en: "PANGAEA CONNECTIVITY TECHNOLOGY LIMITED",
    name_zh: "環聯連訊",
    isHKConnect: 0,
  },
  {
    no: "1475",
    name_en: "NISSIN FOODS COMPANY LIMITED",
    name_zh: "日清食品",
    isHKConnect: 1,
  },
  {
    no: "1476",
    name_en: "HENGTOU SECURITIES",
    name_zh: "恆投證券",
    isHKConnect: 0,
  },
  {
    no: "1477",
    name_en: "OCUMENSION THERAPEUTICS",
    name_zh: "歐康維視生物-B",
    isHKConnect: 1,
  },
  {
    no: "1478",
    name_en: "Q TECHNOLOGY (GROUP) COMPANY LIMITED",
    name_zh: "丘鈦科技",
    isHKConnect: 1,
  },
  {
    no: "1480",
    name_en: "YAN TAT GROUP HOLDINGS LIMITED",
    name_zh: "恩達集團控股",
    isHKConnect: 0,
  },
  {
    no: "1481",
    name_en: "SMART GLOBE HOLDINGS LIMITED",
    name_zh: "竣球控股",
    isHKConnect: 0,
  },
  {
    no: "1483",
    name_en: "NET-A-GO TECHNOLOGY COMPANY LIMITED",
    name_zh: "網譽科技",
    isHKConnect: 0,
  },
  {
    no: "1486",
    name_en: "C CHENG HOLDINGS LIMITED",
    name_zh: "思城控股",
    isHKConnect: 0,
  },
  {
    no: "1488",
    name_en: "BEST FOOD HOLDING COMPANY LIMITED",
    name_zh: "百福控股",
    isHKConnect: 0,
  },
  {
    no: "1489",
    name_en: "GC CONSTRUCTION HOLDINGS LIMITED",
    name_zh: "GC CONSTRUCTION HOLDINGS LIMITED",
    isHKConnect: 0,
  },
  {
    no: "1490",
    name_en: "CHESHI TECHNOLOGY INC.",
    name_zh: "車市科技",
    isHKConnect: 0,
  },
  {
    no: "1495",
    name_en: "JIYI HOLDINGS LIMITED",
    name_zh: "集一控股",
    isHKConnect: 0,
  },
  {
    no: "1496",
    name_en: "AP RENTALS HOLDINGS LIMITED",
    name_zh: "亞積邦租賃",
    isHKConnect: 0,
  },
  {
    no: "1498",
    name_en: "PURAPHARM CORPORATION LIMITED",
    name_zh: "培力農本方",
    isHKConnect: 0,
  },
  {
    no: "1499",
    name_en: "OKG TECHNOLOGY HOLDINGS LIMITED",
    name_zh: "歐科雲鏈",
    isHKConnect: 0,
  },
  {
    no: "1500",
    name_en: "IN CONSTRUCTION HOLDINGS LIMITED",
    name_zh: "現恆建築",
    isHKConnect: 0,
  },
  {
    no: "1501",
    name_en: "SHANGHAI KINDLY MEDICAL INSTRUMENTS CO., LTD.",
    name_zh: "康德萊醫械",
    isHKConnect: 0,
  },
  {
    no: "1502",
    name_en: "FINANCIAL STREET PROPERTY CO., LIMITED",
    name_zh: "金融街物業",
    isHKConnect: 0,
  },
  {
    no: "1503",
    name_en: "CHINA MERCHANTS COMMERCIAL REAL ESTATE INVESTMENT TRUST",
    name_zh: "招商局商業房託",
    isHKConnect: 0,
  },
  {
    no: "1508",
    name_en: "CHINA REINSURANCE (GROUP) CORPORATION",
    name_zh: "中國再保險",
    isHKConnect: 1,
  },
  {
    no: "1513",
    name_en: "LIVZON PHARMACEUTICAL GROUP INC.",
    name_zh: "麗珠醫藥",
    isHKConnect: 1,
  },
  {
    no: "1515",
    name_en: "CHINA RESOURCES MEDICAL HOLDINGS COMPANY LIMITED",
    name_zh: "華潤醫療",
    isHKConnect: 1,
  },
  {
    no: "1516",
    name_en: "SUNAC SERVICES HOLDINGS LIMITED",
    name_zh: "融創服務",
    isHKConnect: 1,
  },
  {
    no: "1518",
    name_en: "NEW CENTURY HEALTHCARE HOLDING CO. LIMITED",
    name_zh: "新世紀醫療",
    isHKConnect: 0,
  },
  {
    no: "1520",
    name_en: "VIRTUAL MIND HOLDING COMPANY LIMITED",
    name_zh: "天機控股",
    isHKConnect: 0,
  },
  {
    no: "1521",
    name_en: "FRONTAGE HOLDINGS CORPORATION",
    name_zh: "方達控股",
    isHKConnect: 1,
  },
  {
    no: "1522",
    name_en: "BII RAILWAY TRANSPORTATION TECHNOLOGY HOLDINGS COMPANY LIMITED",
    name_zh: "京投交通科技",
    isHKConnect: 0,
  },
  {
    no: "1523",
    name_en: "PLOVER BAY TECHNOLOGIES LIMITED",
    name_zh: "珩灣科技",
    isHKConnect: 0,
  },
  {
    no: "1525",
    name_en: "SHANGHAI GENCH EDUCATION GROUP LIMITED",
    name_zh: "建橋教育",
    isHKConnect: 0,
  },
  {
    no: "1526",
    name_en: "RICI HEALTHCARE HOLDINGS LIMITED",
    name_zh: "瑞慈醫療",
    isHKConnect: 0,
  },
  {
    no: "1527",
    name_en: "ZHEJIANG TENGY ENVIRONMENTAL TECHNOLOGY CO., LTD",
    name_zh: "天潔環境",
    isHKConnect: 0,
  },
  {
    no: "1528",
    name_en: "RED STAR MACALLINE GROUP CORPORATION LTD.",
    name_zh: "紅星美凱龍",
    isHKConnect: 1,
  },
  {
    no: "1529",
    name_en: "YUES INTERNATIONAL HOLDINGS GROUP LIMITED",
    name_zh: "樂氏國際控股",
    isHKConnect: 0,
  },
  { no: "1530", name_en: "3SBIO INC.", name_zh: "三生製藥", isHKConnect: 1 },
  {
    no: "1532",
    name_en: "CHINA PARTYTIME CULTURE HOLDINGS LIMITED",
    name_zh: "中國派對文化",
    isHKConnect: 0,
  },
  {
    no: "1536",
    name_en: "YUK WING GROUP HOLDINGS LIMITED",
    name_zh: "煜榮集團",
    isHKConnect: 0,
  },
  {
    no: "1538",
    name_en: "ZHONG AO HOME GROUP LIMITED",
    name_zh: "中奧到家",
    isHKConnect: 0,
  },
  {
    no: "1539",
    name_en: "UNITY GROUP HOLDINGS INTERNATIONAL LIMITED",
    name_zh: "知行集團控股",
    isHKConnect: 0,
  },
  {
    no: "1540",
    name_en: "LEFT FIELD PRINTING GROUP LIMITED",
    name_zh: "澳獅環球",
    isHKConnect: 0,
  },
  {
    no: "1542",
    name_en: "TAIZHOU WATER GROUP CO., LTD.",
    name_zh: "台州水務",
    isHKConnect: 0,
  },
  {
    no: "1543",
    name_en: "GUANGDONG JOIN-SHARE FINANCING GUARANTEE INVESTMENT CO., LTD.",
    name_zh: "中盈盛達融資擔保",
    isHKConnect: 0,
  },
  {
    no: "1545",
    name_en: "DESIGN CAPITAL LIMITED",
    name_zh: "設計都會",
    isHKConnect: 0,
  },
  {
    no: "1546",
    name_en: "THELLOY DEVELOPMENT GROUP LIMITED",
    name_zh: "德萊建業",
    isHKConnect: 0,
  },
  {
    no: "1547",
    name_en: "IBI GROUP HOLDINGS LIMITED",
    name_zh: "IBI GROUP HOLDINGS LIMITED",
    isHKConnect: 0,
  },
  {
    no: "1548",
    name_en: "GENSCRIPT BIOTECH CORPORATION",
    name_zh: "金斯瑞生物科技",
    isHKConnect: 1,
  },
  {
    no: "1549",
    name_en: "EVER HARVEST GROUP HOLDINGS LIMITED",
    name_zh: "永豐集團控股",
    isHKConnect: 0,
  },
  {
    no: "1551",
    name_en: "GUANGZHOU RURAL COMMERCIAL BANK CO., LTD",
    name_zh: "廣州農商銀行",
    isHKConnect: 1,
  },
  {
    no: "1552",
    name_en: "BHCC HOLDING LIMITED",
    name_zh: "BHCC HOLDING LIMITED",
    isHKConnect: 0,
  },
  {
    no: "1553",
    name_en: "MAIKE TUBE INDUSTRY HOLDINGS LIMITED",
    name_zh: "邁科管業",
    isHKConnect: 0,
  },
  {
    no: "1555",
    name_en: "MIE HOLDINGS CORPORATION",
    name_zh: "MI能源",
    isHKConnect: 0,
  },
  {
    no: "1556",
    name_en: "CHINNEY KIN WING HOLDINGS LIMITED",
    name_zh: "建業建榮",
    isHKConnect: 0,
  },
  {
    no: "1557",
    name_en: "K. H. GROUP HOLDINGS LIMITED",
    name_zh: "劍虹集團控股",
    isHKConnect: 0,
  },
  {
    no: "1558",
    name_en: "YICHANG HEC CHANGJIANG PHARMACEUTICAL CO., LTD.",
    name_zh: "東陽光藥",
    isHKConnect: 1,
  },
  {
    no: "1559",
    name_en: "KWAN ON HOLDINGS LIMITED",
    name_zh: "均安控股",
    isHKConnect: 0,
  },
  {
    no: "1560",
    name_en: "STAR GROUP COMPANY LIMITED",
    name_zh: "星星集團",
    isHKConnect: 0,
  },
  {
    no: "1561",
    name_en: "PAN ASIA DATA HOLDINGS INC.",
    name_zh: "聯洋智能控股",
    isHKConnect: 0,
  },
  {
    no: "1563",
    name_en: "INTERNATIONAL ALLIANCE FINANCIAL LEASING CO., LTD.",
    name_zh: "友聯租賃",
    isHKConnect: 0,
  },
  {
    no: "1565",
    name_en: "VIRSCEND EDUCATION COMPANY LIMITED",
    name_zh: "成實外教育",
    isHKConnect: 0,
  },
  {
    no: "1566",
    name_en: "CA CULTURAL TECHNOLOGY GROUP LIMITED",
    name_zh: "華夏文化科技",
    isHKConnect: 0,
  },
  {
    no: "1568",
    name_en: "SUNDART HOLDINGS LIMITED",
    name_zh: "承達集團",
    isHKConnect: 0,
  },
  {
    no: "1569",
    name_en: "MINSHENG EDUCATION GROUP COMPANY LIMITED",
    name_zh: "民生教育",
    isHKConnect: 1,
  },
  {
    no: "1570",
    name_en: "WEIYE HOLDINGS LIMITED",
    name_zh: "偉業控股",
    isHKConnect: 0,
  },
  {
    no: "1571",
    name_en: "XIN POINT HOLDINGS LIMITED",
    name_zh: "信邦控股",
    isHKConnect: 0,
  },
  {
    no: "1572",
    name_en: "CHINA ART FINANCIAL HOLDINGS LIMITED",
    name_zh: "中國藝術金融",
    isHKConnect: 0,
  },
  {
    no: "1575",
    name_en: "MORRIS HOME HOLDINGS LIMITED",
    name_zh: "慕容家居",
    isHKConnect: 0,
  },
  {
    no: "1576",
    name_en: "QILU EXPRESSWAY COMPANY LIMITED",
    name_zh: "齊魯高速",
    isHKConnect: 0,
  },
  {
    no: "1577",
    name_en: "QUANZHOU HUIXIN MICRO-CREDIT CO., LTD.",
    name_zh: "匯鑫小貸",
    isHKConnect: 0,
  },
  {
    no: "1578",
    name_en: "BANK OF TIANJIN CO., LTD.",
    name_zh: "天津銀行",
    isHKConnect: 1,
  },
  {
    no: "1579",
    name_en: "YIHAI INTERNATIONAL HOLDING LTD.",
    name_zh: "頤海國際",
    isHKConnect: 1,
  },
  {
    no: "1580",
    name_en: "DA SEN HOLDINGS GROUP LIMITED",
    name_zh: "大森控股",
    isHKConnect: 0,
  },
  {
    no: "1581",
    name_en: "PROGRESSIVE PATH GROUP HOLDINGS LIMITED",
    name_zh: "進昇集團控股",
    isHKConnect: 0,
  },
  {
    no: "1582",
    name_en: "CR CONSTRUCTION GROUP HOLDINGS LIMITED",
    name_zh: "華營建築",
    isHKConnect: 0,
  },
  {
    no: "1583",
    name_en: "QINQIN FOODSTUFFS GROUP (CAYMAN) COMPANY LIMITED",
    name_zh: "親親食品",
    isHKConnect: 1,
  },
  {
    no: "1585",
    name_en: "YADEA GROUP HOLDINGS LTD.",
    name_zh: "雅迪控股",
    isHKConnect: 1,
  },
  {
    no: "1586",
    name_en: "CHINA LEON INSPECTION HOLDING LIMITED",
    name_zh: "力鴻檢驗",
    isHKConnect: 0,
  },
  {
    no: "1587",
    name_en: "SHINEROAD INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "欣融國際",
    isHKConnect: 0,
  },
  {
    no: "1588",
    name_en: "CHANJET INFORMATION TECHNOLOGY COMPANY LIMITED",
    name_zh: "暢捷通",
    isHKConnect: 0,
  },
  {
    no: "1591",
    name_en: "SHUN WO GROUP HOLDINGS LIMITED",
    name_zh: "汛和集團",
    isHKConnect: 0,
  },
  {
    no: "1592",
    name_en: "ANCHORSTONE HOLDINGS LIMITED",
    name_zh: "基石控股",
    isHKConnect: 0,
  },
  {
    no: "1593",
    name_en: "CHEN LIN EDUCATION GROUP HOLDINGS LIMITED",
    name_zh: "辰林教育",
    isHKConnect: 0,
  },
  {
    no: "1596",
    name_en: "HEBEI YICHEN INDUSTRIAL GROUP CORPORATION LIMITED",
    name_zh: "翼辰實業",
    isHKConnect: 0,
  },
  {
    no: "1597",
    name_en: "CHINA NATURE ENERGY TECHNOLOGY HOLDINGS LIMITED",
    name_zh: "納泉能源科技",
    isHKConnect: 0,
  },
  {
    no: "1598",
    name_en: "CHINA 21ST CENTURY EDUCATION GROUP LIMITED",
    name_zh: "２１世紀教育",
    isHKConnect: 0,
  },
  {
    no: "1599",
    name_en:
      "BEIJING URBAN CONSTRUCTION DESIGN & DEVELOPMENT GROUP CO., LIMITED",
    name_zh: "城建設計",
    isHKConnect: 0,
  },
  {
    no: "1600",
    name_en: "TIAN LUN GAS HOLDINGS LIMITED",
    name_zh: "天倫燃氣",
    isHKConnect: 1,
  },
  {
    no: "1601",
    name_en: "ZHONGGUANCUN SCIENCE-TECH LEASING CO., LTD.",
    name_zh: "中關村科技租賃",
    isHKConnect: 0,
  },
  {
    no: "1606",
    name_en: "CHINA DEVELOPMENT BANK FINANCIAL LEASING CO., LTD.",
    name_zh: "國銀租賃",
    isHKConnect: 1,
  },
  {
    no: "1608",
    name_en: "VPOWER GROUP INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "偉能集團",
    isHKConnect: 1,
  },
  {
    no: "1609",
    name_en: "CHONG KIN GROUP HOLDINGS LIMITED",
    name_zh: "創建集團控股",
    isHKConnect: 0,
  },
  {
    no: "1610",
    name_en: "COFCO JOYCOME FOODS LIMITED",
    name_zh: "中糧家佳康",
    isHKConnect: 1,
  },
  {
    no: "1611",
    name_en: "HUOBI TECHNOLOGY HOLDINGS LIMITED",
    name_zh: "火幣科技",
    isHKConnect: 0,
  },
  {
    no: "1612",
    name_en: "VINCENT MEDICAL HOLDINGS LIMITED",
    name_zh: "永勝醫療",
    isHKConnect: 0,
  },
  {
    no: "1613",
    name_en: "SYNERTONE COMMUNICATION CORPORATION",
    name_zh: "協同通信",
    isHKConnect: 0,
  },
  {
    no: "1615",
    name_en: "AB BUILDERS GROUP LIMITED",
    name_zh: "奧邦建築",
    isHKConnect: 0,
  },
  {
    no: "1616",
    name_en: "A METAVERSE COMPANY",
    name_zh: "一元宇宙",
    isHKConnect: 0,
  },
  {
    no: "1617",
    name_en: "NANFANG COMMUNICATION HOLDINGS LIMITED",
    name_zh: "南方通信",
    isHKConnect: 0,
  },
  {
    no: "1618",
    name_en: "METALLURGICAL CORPORATION OF CHINA LTD.",
    name_zh: "中國中冶",
    isHKConnect: 1,
  },
  {
    no: "1620",
    name_en: "CINESE INTERNATIONAL GROUP HOLDINGS LIMITED",
    name_zh: "富盈環球集團",
    isHKConnect: 0,
  },
  {
    no: "1621",
    name_en: "VICO INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "域高國際控股",
    isHKConnect: 0,
  },
  {
    no: "1622",
    name_en: "REDCO PROPERTIES GROUP LIMITED",
    name_zh: "力高集團",
    isHKConnect: 1,
  },
  {
    no: "1623",
    name_en: "HILONG HOLDING LIMITED",
    name_zh: "海隆控股",
    isHKConnect: 0,
  },
  {
    no: "1626",
    name_en: "JIA YAO HOLDINGS LIMITED",
    name_zh: "嘉耀控股",
    isHKConnect: 0,
  },
  {
    no: "1627",
    name_en: "ABLE ENGINEERING HOLDINGS LIMITED",
    name_zh: "安保工程控股",
    isHKConnect: 0,
  },
  {
    no: "1628",
    name_en: "YUZHOU GROUP HOLDINGS COMPANY LIMITED",
    name_zh: "禹洲集團",
    isHKConnect: 1,
  },
  {
    no: "1629",
    name_en: "CHAMPION ALLIANCE INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "冠均國際控股",
    isHKConnect: 0,
  },
  {
    no: "1630",
    name_en: "KIN SHING HOLDINGS LIMITED",
    name_zh: "建成控股",
    isHKConnect: 0,
  },
  {
    no: "1631",
    name_en: "REF HOLDINGS LIMITED",
    name_zh: "REF HOLDINGS LIMITED",
    isHKConnect: 0,
  },
  {
    no: "1632",
    name_en: "MINSHANG CREATIVE TECHNOLOGY HOLDINGS LIMITED",
    name_zh: "民商創科",
    isHKConnect: 0,
  },
  {
    no: "1633",
    name_en: "SHEUNG YUE GROUP HOLDINGS LIMITED",
    name_zh: "上諭集團",
    isHKConnect: 0,
  },
  {
    no: "1635",
    name_en: "SHANGHAI DAZHONG PUBLIC UTILITIES (GROUP) CO., LTD.",
    name_zh: "大眾公用",
    isHKConnect: 1,
  },
  {
    no: "1636",
    name_en: "CHINA METAL RESOURCES UTILIZATION LIMITED",
    name_zh: "中國金屬利用",
    isHKConnect: 1,
  },
  {
    no: "1637",
    name_en: "SH GROUP (HOLDINGS) LIMITED",
    name_zh: "順興集團控股",
    isHKConnect: 0,
  },
  {
    no: "1638",
    name_en: "KAISA GROUP HOLDINGS LTD.",
    name_zh: "佳兆業集團",
    isHKConnect: 1,
  },
  {
    no: "1640",
    name_en: "RUICHENG (CHINA) MEDIA GROUP LIMITED",
    name_zh: "瑞誠中國傳媒",
    isHKConnect: 0,
  },
  {
    no: "1643",
    name_en: "MODERN CHINESE MEDICINE GROUP CO., LTD.",
    name_zh: "現代中藥集團",
    isHKConnect: 0,
  },
  {
    no: "1645",
    name_en: "HAINA INTELLIGENT EQUIPMENT INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "海納智能",
    isHKConnect: 0,
  },
  {
    no: "1647",
    name_en: "GRANDSHORES TECHNOLOGY GROUP LIMITED",
    name_zh: "雄岸科技",
    isHKConnect: 0,
  },
  {
    no: "1650",
    name_en: "HYGIEIA GROUP LIMITED",
    name_zh: "HYGIEIA GROUP LIMITED",
    isHKConnect: 0,
  },
  {
    no: "1651",
    name_en: "PRECISION TSUGAMI (CHINA) CORPORATION LIMITED",
    name_zh: "津上機床中國",
    isHKConnect: 0,
  },
  {
    no: "1652",
    name_en: "FUSEN PHARMACEUTICAL COMPANY LIMITED",
    name_zh: "福森藥業",
    isHKConnect: 0,
  },
  {
    no: "1653",
    name_en: "MOS HOUSE GROUP LIMITED",
    name_zh: "MOS HOUSE GROUP LIMITED",
    isHKConnect: 0,
  },
  {
    no: "1655",
    name_en: "OKURA HOLDINGS LIMITED",
    name_zh: "OKURA HOLDINGS LIMITED",
    isHKConnect: 0,
  },
  {
    no: "1656",
    name_en: "ISDN HOLDINGS LIMITED",
    name_zh: "億仕登控股",
    isHKConnect: 0,
  },
  {
    no: "1657",
    name_en: "SG GROUP HOLDINGS LIMITED",
    name_zh: "樺欣控股",
    isHKConnect: 0,
  },
  {
    no: "1658",
    name_en: "POSTAL SAVINGS BANK OF CHINA CO., LTD.",
    name_zh: "郵儲銀行",
    isHKConnect: 1,
  },
  {
    no: "1660",
    name_en: "ZHAOBANGJI PROPERTIES HOLDINGS LIMITED",
    name_zh: "兆邦基地產",
    isHKConnect: 0,
  },
  {
    no: "1661",
    name_en: "WISDOM SPORTS GROUP",
    name_zh: "智美體育",
    isHKConnect: 1,
  },
  {
    no: "1662",
    name_en: "YEE HOP HOLDINGS LIMITED",
    name_zh: "義合控股",
    isHKConnect: 0,
  },
  {
    no: "1663",
    name_en: "SINO HARBOUR HOLDINGS GROUP LIMITED",
    name_zh: "漢港控股",
    isHKConnect: 0,
  },
  {
    no: "1665",
    name_en: "PENTAMASTER INTERNATIONAL LIMITED",
    name_zh: "檳傑科達",
    isHKConnect: 0,
  },
  {
    no: "1666",
    name_en: "TONG REN TANG TECHNOLOGIES CO LTD",
    name_zh: "同仁堂科技",
    isHKConnect: 1,
  },
  {
    no: "1667",
    name_en: "MILESTONE BUILDER HOLDINGS LIMITED",
    name_zh: "進階發展",
    isHKConnect: 0,
  },
  {
    no: "1668",
    name_en: "CHINA SOUTH CITY HOLDINGS LIMITED",
    name_zh: "華南城",
    isHKConnect: 1,
  },
  {
    no: "1669",
    name_en: "GLOBAL INTERNATIONAL CREDIT GROUP LIMITED",
    name_zh: "環球信貸集團",
    isHKConnect: 0,
  },
  {
    no: "1671",
    name_en: "TIANJIN TIANBAO ENERGY CO., LTD.",
    name_zh: "天保能源",
    isHKConnect: 0,
  },
  {
    no: "1672",
    name_en: "ASCLETIS PHARMA INC.",
    name_zh: "歌禮製藥-Ｂ",
    isHKConnect: 0,
  },
  {
    no: "1673",
    name_en: "HUAZHANG TECHNOLOGY HOLDING LIMITED",
    name_zh: "華章科技",
    isHKConnect: 0,
  },
  {
    no: "1675",
    name_en: "ASIAINFO TECHNOLOGIES LIMITED",
    name_zh: "亞信科技",
    isHKConnect: 1,
  },
  {
    no: "1676",
    name_en: "CHINA SHENGHAI GROUP LIMITED",
    name_zh: "中國升海集團",
    isHKConnect: 0,
  },
  {
    no: "1679",
    name_en: "RISECOMM GROUP HOLDINGS LIMITED",
    name_zh: "瑞斯康集團",
    isHKConnect: 0,
  },
  {
    no: "1680",
    name_en: "MACAU LEGEND DEVELOPMENT LIMITED",
    name_zh: "澳門勵駿",
    isHKConnect: 1,
  },
  {
    no: "1681",
    name_en: "CONSUN PHARMACEUTICAL GROUP LIMITED",
    name_zh: "康臣葯業",
    isHKConnect: 1,
  },
  {
    no: "1682",
    name_en: "HANG PIN LIVING TECHNOLOGY COMPANY LIMITED",
    name_zh: "杭品生活科技",
    isHKConnect: 0,
  },
  {
    no: "1683",
    name_en: "HOPE LIFE INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "曠逸國際",
    isHKConnect: 0,
  },
  {
    no: "1685",
    name_en: "BOER POWER HOLDINGS LIMITED",
    name_zh: "博耳電力",
    isHKConnect: 1,
  },
  {
    no: "1686",
    name_en: "SUNEVISION HOLDINGS LIMITED",
    name_zh: "新意網集團",
    isHKConnect: 1,
  },
  {
    no: "1689",
    name_en: "HUAXI HOLDINGS COMPANY LIMITED",
    name_zh: "華禧控股",
    isHKConnect: 0,
  },
  {
    no: "1690",
    name_en: "LAP KEI ENGINEERING (HOLDINGS) LIMITED",
    name_zh: "立基工程控股",
    isHKConnect: 0,
  },
  {
    no: "1691",
    name_en: "JS GLOBAL LIFESTYLE COMPANY LIMITED",
    name_zh: "JS環球生活",
    isHKConnect: 1,
  },
  {
    no: "1692",
    name_en: "TOWN RAY HOLDNGS LIMITED",
    name_zh: "登輝控股",
    isHKConnect: 0,
  },
  {
    no: "1693",
    name_en: "BGMC INTERNATIONAL LIMITED",
    name_zh: "璋利國際",
    isHKConnect: 0,
  },
  {
    no: "1695",
    name_en: "S&P INTERNATIONAL HOLDING LIMITED",
    name_zh: "椰豐集團",
    isHKConnect: 0,
  },
  {
    no: "1696",
    name_en: "SISRAM MEDICAL LTD",
    name_zh: "復銳醫療科技",
    isHKConnect: 0,
  },
  {
    no: "1697",
    name_en: "SHANDONG INTERNATIONAL TRUST CO., LTD.",
    name_zh: "山東國信",
    isHKConnect: 0,
  },
  {
    no: "1698",
    name_en: "TENCENT MUSIC ENTERTAINMENT GROUP",
    name_zh: "騰訊音樂-SW",
    isHKConnect: 0,
  },
  {
    no: "1699",
    name_en: "CHINA PUTIAN FOOD HOLDING LIMITED",
    name_zh: "普甜食品",
    isHKConnect: 0,
  },
  {
    no: "1701",
    name_en: "TU YI HOLDING COMPANY LIMITED",
    name_zh: "途屹控股",
    isHKConnect: 0,
  },
  {
    no: "1702",
    name_en: "DONGGUANG CHEMICAL LIMITED",
    name_zh: "東光化工",
    isHKConnect: 0,
  },
  {
    no: "1703",
    name_en: "PALACE BANQUET HOLDINGS LIMITED",
    name_zh: "首灃控股",
    isHKConnect: 0,
  },
  {
    no: "1705",
    name_en: "B & S INTERNATIONAL HOLDINGS LTD.",
    name_zh: "賓仕國際",
    isHKConnect: 0,
  },
  {
    no: "1706",
    name_en: "SHUANG YUN HOLDINGS LIMITED",
    name_zh: "雙運控股",
    isHKConnect: 0,
  },
  {
    no: "1707",
    name_en: "GEOTECH HOLDINGS LTD.",
    name_zh: "致浩達控股",
    isHKConnect: 0,
  },
  {
    no: "1708",
    name_en: "NANJING SAMPLE TECHNOLOGY COMPANY LIMITED",
    name_zh: "三寶科技",
    isHKConnect: 0,
  },
  {
    no: "1709",
    name_en: "DL HOLDINGS GROUP LIMITED",
    name_zh: "德林控股",
    isHKConnect: 0,
  },
  {
    no: "1710",
    name_en: "TRIO INDUSTRIAL ELECTRONICS GROUP LIMITED",
    name_zh: "致豐工業電子",
    isHKConnect: 0,
  },
  {
    no: "1711",
    name_en: "ULFERTS INTERNATIONAL LIMITED",
    name_zh: "歐化",
    isHKConnect: 0,
  },
  {
    no: "1712",
    name_en: "DRAGON MINING LIMITED",
    name_zh: "龍資源",
    isHKConnect: 0,
  },
  {
    no: "1713",
    name_en: "SICHUAN ENERGY INVESTMENT DEVELOPMENT CO., LTD.",
    name_zh: "四川能投發展",
    isHKConnect: 0,
  },
  {
    no: "1715",
    name_en: "MIJI INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "米技國際控股",
    isHKConnect: 0,
  },
  {
    no: "1716",
    name_en: "MOST KWAI CHUNG LIMITED",
    name_zh: "毛記葵涌",
    isHKConnect: 0,
  },
  {
    no: "1717",
    name_en: "AUSNUTRIA DAIRY CORPORATION LTD",
    name_zh: "澳優",
    isHKConnect: 1,
  },
  {
    no: "1718",
    name_en: "WAN KEI GROUP HOLDINGS LIMITED",
    name_zh: "宏基集團控股",
    isHKConnect: 0,
  },
  {
    no: "1719",
    name_en: "CHINA INFRASTRUCTURE & LOGISTICS GROUP LTD.",
    name_zh: "中國通商集團",
    isHKConnect: 0,
  },
  {
    no: "1720",
    name_en: "PUTIAN COMMUNICATION GROUP LIMITED",
    name_zh: "普天通信集團",
    isHKConnect: 0,
  },
  {
    no: "1722",
    name_en: "KIN PANG HOLDINGS LIMITED",
    name_zh: "建鵬控股",
    isHKConnect: 0,
  },
  {
    no: "1723",
    name_en: "HK ASIA HOLDINGS LIMITED",
    name_zh: "港亞控股",
    isHKConnect: 0,
  },
  {
    no: "1725",
    name_en: "HONG KONG AEROSPACE TECHNOLOGY GROUP LIMITED",
    name_zh: "香港航天科技",
    isHKConnect: 1,
  },
  {
    no: "1726",
    name_en: "HKE HOLDINGS LIMITED",
    name_zh: "HKE HOLDINGS LIMITED",
    isHKConnect: 0,
  },
  {
    no: "1727",
    name_en: "HEBEI CONSTRUCTION GROUP CORPORATION LIMITED",
    name_zh: "河北建設",
    isHKConnect: 0,
  },
  {
    no: "1728",
    name_en: "CHINA ZHENGTONG AUTO SERVICES HOLDINGS LIMITED",
    name_zh: "正通汽車",
    isHKConnect: 1,
  },
  {
    no: "1729",
    name_en: "TIME INTERCONNECT TECHNOLOGY LIMITED",
    name_zh: "匯聚科技",
    isHKConnect: 0,
  },
  {
    no: "1730",
    name_en: "LHN LIMITED",
    name_zh: "LHN LIMITED",
    isHKConnect: 0,
  },
  {
    no: "1731",
    name_en: "PROSPEROUS INDUSTRIAL (HOLDINGS) LIMITED",
    name_zh: "其利工業集團",
    isHKConnect: 0,
  },
  {
    no: "1732",
    name_en: "XIANGXING INTERNATIONAL HOLDING LIMITED",
    name_zh: "象興國際",
    isHKConnect: 0,
  },
  {
    no: "1733",
    name_en: "E-COMMODITIES HOLDINGS LIMITED",
    name_zh: "易大宗",
    isHKConnect: 0,
  },
  {
    no: "1735",
    name_en: "CENTRAL HOLDING GROUP CO. LTD.",
    name_zh: "中環控股",
    isHKConnect: 0,
  },
  {
    no: "1736",
    name_en: "CHINA PARENTING NETWORK HOLDINGS LIMITED",
    name_zh: "中國育兒網絡",
    isHKConnect: 0,
  },
  {
    no: "1737",
    name_en: "A & S GROUP (HOLDINGS) LIMITED",
    name_zh: "亞洲實業集團",
    isHKConnect: 0,
  },
  {
    no: "1738",
    name_en: "FEISHANG ANTHRACITE RESOURCES LIMITED",
    name_zh: "飛尚無煙煤",
    isHKConnect: 0,
  },
  {
    no: "1739",
    name_en: "QEEKA HOME (CAYMAN) INC.",
    name_zh: "齊屹科技",
    isHKConnect: 0,
  },
  {
    no: "1740",
    name_en: "VALUES CULTURAL INVESTMENT LIMITED",
    name_zh: "新石文化",
    isHKConnect: 0,
  },
  {
    no: "1741",
    name_en: "RI YING HOLDINGS LIMITED",
    name_zh: "日贏控股",
    isHKConnect: 0,
  },
  {
    no: "1742",
    name_en: "HPC HOLDINGS LIMITED",
    name_zh: "HPC HOLDINGS LIMITED",
    isHKConnect: 0,
  },
  {
    no: "1745",
    name_en: "LVJI TECHNOLOGY HOLDINGS INC.",
    name_zh: "LVJI TECHNOLOGY HOLDINGS INC.",
    isHKConnect: 0,
  },
  {
    no: "1746",
    name_en: "MAN SHUN GROUP (HOLDINGS) LIMITED",
    name_zh: "萬順集團控股",
    isHKConnect: 0,
  },
  {
    no: "1747",
    name_en: "HOME CONTROL INTERNATIONAL LIMITED",
    name_zh: "HOME CONTROL INTERNATIONAL LIMITED",
    isHKConnect: 0,
  },
  {
    no: "1748",
    name_en: "XIN YUAN ENTERPRISES GROUP LIMITED",
    name_zh: "信源企業集團",
    isHKConnect: 0,
  },
  {
    no: "1749",
    name_en: "SHANSHAN BRAND MANAGEMENT CO., LTD.",
    name_zh: "杉杉品牌",
    isHKConnect: 0,
  },
  {
    no: "1750",
    name_en: "REM GROUP (HOLDINGS) LIMITED",
    name_zh: "全達電器集團控股",
    isHKConnect: 0,
  },
  {
    no: "1751",
    name_en: "KINGLAND GROUP HOLDINGS LIMITED",
    name_zh: "景聯集團",
    isHKConnect: 0,
  },
  {
    no: "1752",
    name_en: "TOP EDUCATION GROUP LTD",
    name_zh: "澳洲成峰高教",
    isHKConnect: 0,
  },
  {
    no: "1753",
    name_en: "DUIBA GROUP LIMITED",
    name_zh: "兌吧",
    isHKConnect: 0,
  },
  {
    no: "1755",
    name_en: "S-ENJOY SERVICE GROUP CO., LIMITED",
    name_zh: "新城悅服務",
    isHKConnect: 1,
  },
  {
    no: "1756",
    name_en: "CHINA VOCATIONAL EDUCATION HOLDINGS LIMITED",
    name_zh: "中國職業教育",
    isHKConnect: 0,
  },
  {
    no: "1757",
    name_en: "AFFLUENT FOUNDATION HOLDINGS LIMITED",
    name_zh: "俊裕地基",
    isHKConnect: 0,
  },
  {
    no: "1758",
    name_en: "BOJUN EDUCATION COMPANY LIMITED",
    name_zh: "博駿教育",
    isHKConnect: 0,
  },
  {
    no: "1759",
    name_en: "SINO GAS HOLDINGS GROUP LIMITED",
    name_zh: "中油潔能控股",
    isHKConnect: 0,
  },
  {
    no: "1760",
    name_en: "INTRON TECHNOLOGY HOLDINGS LIMITED",
    name_zh: "英恆科技",
    isHKConnect: 0,
  },
  {
    no: "1761",
    name_en: "BABYTREE GROUP",
    name_zh: "寶寶樹集團",
    isHKConnect: 1,
  },
  {
    no: "1762",
    name_en: "WANKA ONLINE INC.",
    name_zh: "萬咖壹聯",
    isHKConnect: 0,
  },
  {
    no: "1763",
    name_en: "CHINA ISOTOPE & RADIATION CORPORATION",
    name_zh: "中國同輻",
    isHKConnect: 0,
  },
  {
    no: "1765",
    name_en: "HOPE EDUCATION GROUP CO., LTD.",
    name_zh: "希望教育",
    isHKConnect: 1,
  },
  {
    no: "1766",
    name_en: "CRRC CORPORATION LIMITED",
    name_zh: "中國中車",
    isHKConnect: 1,
  },
  {
    no: "1767",
    name_en: "TS WONDERS HOLDING LIMITED",
    name_zh: "TS WONDERS HOLDING LIMITED",
    isHKConnect: 0,
  },
  {
    no: "1769",
    name_en: "SCHOLAR EDUCATION GROUP",
    name_zh: "思考樂教育",
    isHKConnect: 1,
  },
  {
    no: "1771",
    name_en: "SUNFONDA GROUP HOLDINGS LIMITED",
    name_zh: "新豐泰集團",
    isHKConnect: 0,
  },
  {
    no: "1772",
    name_en: "GANFENG LITHIUM GROUP CO., LTD.",
    name_zh: "贛鋒鋰業",
    isHKConnect: 1,
  },
  {
    no: "1773",
    name_en: "TIANLI INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "天立國際控股",
    isHKConnect: 1,
  },
  {
    no: "1775",
    name_en: "BEXCELLENT GROUP HOLDINGS LIMITED",
    name_zh: "精英匯集團",
    isHKConnect: 0,
  },
  {
    no: "1776",
    name_en: "GF SECURITIES CO., LTD.",
    name_zh: "廣發証券",
    isHKConnect: 1,
  },
  {
    no: "1777",
    name_en: "FANTASIA HOLDINGS GROUP CO., LIMITED",
    name_zh: "花樣年控股",
    isHKConnect: 1,
  },
  {
    no: "1778",
    name_en: "COLOUR LIFE SERVICES GROUP CO., LIMITED",
    name_zh: "彩生活",
    isHKConnect: 1,
  },
  {
    no: "1780",
    name_en: "B & D STRATEGIC HOLDINGS LIMITED",
    name_zh: "B & D STRATEGIC HOLDINGS LIMITED",
    isHKConnect: 0,
  },
  {
    no: "1782",
    name_en: "VIXTEL TECHNOLOGIES HOLDINGS LIMITED",
    name_zh: "飛思達科技",
    isHKConnect: 0,
  },
  {
    no: "1783",
    name_en: "GOLDEN PONDER HOLDINGS LIMITED",
    name_zh: "金侖控股有限公司",
    isHKConnect: 0,
  },
  {
    no: "1785",
    name_en: "CHENGDU EXPRESSWAY CO., LTD",
    name_zh: "成都高速",
    isHKConnect: 0,
  },
  {
    no: "1786",
    name_en: "CRCC HIGH-TECH EQUIPMENT CORPORATION LIMITED",
    name_zh: "鐵建裝備",
    isHKConnect: 0,
  },
  {
    no: "1787",
    name_en: "SHANDONG GOLD MINING CO., LTD.",
    name_zh: "山東黃金",
    isHKConnect: 1,
  },
  {
    no: "1788",
    name_en: "GUOTAI JUNAN INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "國泰君安國際",
    isHKConnect: 1,
  },
  {
    no: "1789",
    name_en: "AK MEDICAL HOLDINGS LIMITED",
    name_zh: "愛康醫療",
    isHKConnect: 1,
  },
  {
    no: "1790",
    name_en: "TIL ENVIRO LIMITED",
    name_zh: "達力環保",
    isHKConnect: 0,
  },
  {
    no: "1792",
    name_en: "CMON LIMITED",
    name_zh: "CMON LIMITED",
    isHKConnect: 0,
  },
  {
    no: "1793",
    name_en: "WECON HOLDINGS LIMITED",
    name_zh: "偉工控股",
    isHKConnect: 0,
  },
  {
    no: "1795",
    name_en: "YADONG GROUP HOLDINGS LIMITED",
    name_zh: "亞東集團",
    isHKConnect: 0,
  },
  {
    no: "1796",
    name_en: "YIELD GO HOLDINGS LTD.",
    name_zh: "耀高控股",
    isHKConnect: 0,
  },
  {
    no: "1797",
    name_en: "KOOLEARN TECHNOLOGY HOLDING LIMITED",
    name_zh: "新東方在線",
    isHKConnect: 1,
  },
  {
    no: "1798",
    name_en: "CHINA DATANG CORPORATION RENEWABLE POWER CO., LIMITED",
    name_zh: "大唐新能源",
    isHKConnect: 1,
  },
  {
    no: "1799",
    name_en: "XINTE ENERGY CO., LTD.",
    name_zh: "新特能源",
    isHKConnect: 0,
  },
  {
    no: "1800",
    name_en: "CHINA COMMUNICATIONS CONSTRUCTION COMPANY LIMITED",
    name_zh: "中國交通建設",
    isHKConnect: 1,
  },
  {
    no: "1801",
    name_en: "INNOVENT BIOLOGICS, INC.",
    name_zh: "信達生物",
    isHKConnect: 1,
  },
  {
    no: "1802",
    name_en: "WENYE GROUP HOLDINGS LIMITED",
    name_zh: "文業集團",
    isHKConnect: 0,
  },
  {
    no: "1803",
    name_en: "BEIJING SPORTS AND ENTERTAINMENT INDUSTRY GROUP LIMITED",
    name_zh: "北京體育文化",
    isHKConnect: 0,
  },
  {
    no: "1808",
    name_en: "ENTERPRISE DEVELOPMENT HOLDINGS LIMITED",
    name_zh: "企展控股",
    isHKConnect: 0,
  },
  {
    no: "1809",
    name_en: "PRINX CHENGSHAN HOLDINGS LIMITED",
    name_zh: "浦林成山",
    isHKConnect: 0,
  },
  {
    no: "1810",
    name_en: "XIAOMI CORPORATION",
    name_zh: "小米集團－Ｗ",
    isHKConnect: 1,
  },
  {
    no: "1811",
    name_en: "CGN NEW ENERGY HOLDINGS CO., LTD.",
    name_zh: "中廣核新能源",
    isHKConnect: 1,
  },
  {
    no: "1812",
    name_en: "SHANDONG CHENMING PAPER HOLDINGS LIMITED",
    name_zh: "晨鳴紙業",
    isHKConnect: 1,
  },
  {
    no: "1813",
    name_en: "KWG GROUP HOLDINGS LIMITED",
    name_zh: "合景泰富集團",
    isHKConnect: 1,
  },
  {
    no: "1815",
    name_en: "CSMALL GROUP LIMITED",
    name_zh: "金貓銀貓",
    isHKConnect: 0,
  },
  {
    no: "1816",
    name_en: "CGN POWER CO., LTD",
    name_zh: "中廣核電力",
    isHKConnect: 1,
  },
  {
    no: "1817",
    name_en: "MULSANNE GROUP HOLDING LIMITED",
    name_zh: "慕尚集團控股",
    isHKConnect: 1,
  },
  {
    no: "1818",
    name_en: "ZHAOJIN MINING INDUSTRY COMPANY LIMITED",
    name_zh: "招金礦業",
    isHKConnect: 1,
  },
  {
    no: "1820",
    name_en: "PACIFIC MILLENNIUM PACKAGING GROUP CORPORATION",
    name_zh: "濟豐包裝",
    isHKConnect: 0,
  },
  {
    no: "1821",
    name_en: "ESR GROUP LIMITED",
    name_zh: "ESR GROUP LIMITED",
    isHKConnect: 1,
  },
  {
    no: "1822",
    name_en: "CHINA WOOD INTERNATIONAL HOLDING CO., LIMITED",
    name_zh: "中木國際",
    isHKConnect: 0,
  },
  {
    no: "1823",
    name_en: "HUAYU EXPRESSWAY GROUP LIMITED",
    name_zh: "華昱高速",
    isHKConnect: 0,
  },
  {
    no: "1825",
    name_en: "STERLING GROUP HOLDINGS LIMITED",
    name_zh: "STERLING GROUP HOLDINGS LIMITED",
    isHKConnect: 0,
  },
  {
    no: "1826",
    name_en: "FDB HOLDINGS LIMITED",
    name_zh: "豐展控股",
    isHKConnect: 0,
  },
  {
    no: "1827",
    name_en: "MIRICOR ENTERPRISES HOLDINGS LIMITED",
    name_zh: "卓珈控股",
    isHKConnect: 0,
  },
  {
    no: "1830",
    name_en: "PERFECT MEDICAL HEALTH MANAGEMENT LTD",
    name_zh: "完美醫療",
    isHKConnect: 0,
  },
  {
    no: "1831",
    name_en: "SHIFANG HOLDING LIMITED",
    name_zh: "十方控股",
    isHKConnect: 0,
  },
  {
    no: "1832",
    name_en: "S.A.I. LEISURE GROUP COMPANY LIMITED",
    name_zh: "海天地悅旅",
    isHKConnect: 0,
  },
  {
    no: "1833",
    name_en: "PING AN HEALTHCARE AND TECHNOLOGY COMPANY LIMITED",
    name_zh: "平安好醫生",
    isHKConnect: 1,
  },
  {
    no: "1835",
    name_en: "SHANGHAI REALWAY CAPITAL ASSETS MANAGEMENT CO., LTD.",
    name_zh: "瑞威資管",
    isHKConnect: 0,
  },
  {
    no: "1836",
    name_en: "STELLA INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "九興控股",
    isHKConnect: 1,
  },
  {
    no: "1837",
    name_en: "NATURAL FOOD INTERNATIONAL HOLDING LIMITED",
    name_zh: "五谷磨房",
    isHKConnect: 0,
  },
  {
    no: "1838",
    name_en: "CHINA PROPERTIES GROUP LIMITED",
    name_zh: "CHINA PROPERTIES GROUP LIMITED",
    isHKConnect: 0,
  },
  {
    no: "1839",
    name_en: "CIMC VEHICLES (GROUP) CO., LTD.",
    name_zh: "中集車輛",
    isHKConnect: 1,
  },
  {
    no: "1841",
    name_en: "A.PLUS GROUP HOLDINGS LIMITED",
    name_zh: "優越集團控股",
    isHKConnect: 0,
  },
  {
    no: "1842",
    name_en: "GROWN UP GROUP INVESTMENT HOLDINGS LIMITED",
    name_zh: "植華集團",
    isHKConnect: 0,
  },
  {
    no: "1843",
    name_en: "SNACK EMPIRE HOLDINGS LIMITED",
    name_zh: "快餐帝國",
    isHKConnect: 0,
  },
  {
    no: "1845",
    name_en: "WEIGANG ENVIRONMENTAL TECHNOLOGY HOLDING GROUP LIMITED",
    name_zh: "維港環保科技",
    isHKConnect: 0,
  },
  {
    no: "1846",
    name_en: "EUROEYES INTERNATIONAL EYE CLINIC LIMITED",
    name_zh: "德視佳",
    isHKConnect: 0,
  },
  {
    no: "1847",
    name_en: "YCIH GREEN HIGH-PERFORMANCE CONCRETE COMPANY LIMITED",
    name_zh: "雲南建投混凝土",
    isHKConnect: 0,
  },
  {
    no: "1848",
    name_en: "CHINA AIRCRAFT LEASING GROUP HOLDINGS LIMITED",
    name_zh: "中國飛機租賃",
    isHKConnect: 1,
  },
  {
    no: "1849",
    name_en: "AM GROUP HOLDINGS LIMITED",
    name_zh: "秀商時代控股",
    isHKConnect: 0,
  },
  {
    no: "1850",
    name_en: "WINDMILL GROUP LIMITED",
    name_zh: "海鑫集團",
    isHKConnect: 0,
  },
  {
    no: "1851",
    name_en: "CHINA GINGKO EDUCATION GROUP COMPANY LIMITED",
    name_zh: "銀杏教育",
    isHKConnect: 0,
  },
  {
    no: "1853",
    name_en: "JILIN PROVINCE CHUNCHENG HEATING COMPANY LIMITED",
    name_zh: "春城熱力",
    isHKConnect: 0,
  },
  {
    no: "1854",
    name_en: "CHINA WANTIAN HOLDINGS LIMITED",
    name_zh: "中國萬天控股",
    isHKConnect: 0,
  },
  {
    no: "1855",
    name_en: "ZONBONG LANDSCAPE ENVIRONMENTAL LIMITED",
    name_zh: "中邦環境",
    isHKConnect: 0,
  },
  {
    no: "1856",
    name_en: "ERNEST BOREL HOLDINGS LIMITED",
    name_zh: "依波路",
    isHKConnect: 0,
  },
  {
    no: "1857",
    name_en: "CHINA EVERBRIGHT WATER LIMITED",
    name_zh: "中國光大水務",
    isHKConnect: 0,
  },
  {
    no: "1858",
    name_en: "BEIJING CHUNLIZHENGDA MEDICAL INSTRUMENTS CO., LTD.",
    name_zh: "春立醫療",
    isHKConnect: 1,
  },
  {
    no: "1859",
    name_en: "CHINA BRIGHT CULTURE GROUP",
    name_zh: "煜盛文化",
    isHKConnect: 0,
  },
  { no: "1860", name_en: "MOBVISTA INC.", name_zh: "匯量科技", isHKConnect: 1 },
  {
    no: "1861",
    name_en: "PRECIOUS DRAGON TECHNOLOGY HOLDINGS LIMITED",
    name_zh: "保寶龍科技",
    isHKConnect: 0,
  },
  {
    no: "1862",
    name_en: "JINGRUI HOLDINGS LIMITED",
    name_zh: "景瑞控股",
    isHKConnect: 0,
  },
  {
    no: "1863",
    name_en: "CHINA LONGEVITY GROUP COMPANY LIMITED",
    name_zh: "中國龍天集團",
    isHKConnect: 0,
  },
  {
    no: "1865",
    name_en: "TRENDZON HOLDINGS GROUP LIMITED",
    name_zh: "卓航控股",
    isHKConnect: 0,
  },
  {
    no: "1866",
    name_en: "CHINA XLX FERTILISER LTD",
    name_zh: "中國心連心化肥",
    isHKConnect: 0,
  },
  {
    no: "1867",
    name_en: "STANDARD DEVELOPMENT GROUP LIMITED",
    name_zh: "標準發展集團",
    isHKConnect: 0,
  },
  {
    no: "1868",
    name_en: "NEO-NEON HOLDINGS LIMITED",
    name_zh: "同方友友",
    isHKConnect: 0,
  },
  {
    no: "1869",
    name_en: "LI BAO GE GROUP LIMITED",
    name_zh: "利寶閣集團",
    isHKConnect: 0,
  },
  {
    no: "1870",
    name_en: "ACME INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "益美國際控股",
    isHKConnect: 0,
  },
  {
    no: "1871",
    name_en: "CHINA ORIENTED INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "向中國際",
    isHKConnect: 0,
  },
  {
    no: "1872",
    name_en: "GUAN CHAO HOLDINGS LIMITED",
    name_zh: "GUAN CHAO HOLDINGS LIMITED",
    isHKConnect: 0,
  },
  {
    no: "1873",
    name_en: "VIVA BIOTECH HOLDINGS",
    name_zh: "維亞生物",
    isHKConnect: 1,
  },
  {
    no: "1875",
    name_en: "TOT BIOPHARM INTERNATIONAL COMPANY LIMITED",
    name_zh: "東曜藥業-B",
    isHKConnect: 0,
  },
  {
    no: "1876",
    name_en: "BUDWEISER BREWING COMPANY APAC LIMITED",
    name_zh: "百威亞太",
    isHKConnect: 1,
  },
  {
    no: "1877",
    name_en: "SHANGHAI JUNSHI BIOSCIENCES CO., LTD.",
    name_zh: "君實生物",
    isHKConnect: 1,
  },
  {
    no: "1878",
    name_en: "SOUTHGOBI RESOURCES LIMITED",
    name_zh: "南戈壁-S",
    isHKConnect: 0,
  },
  {
    no: "1880",
    name_en: "CHINA TOURISM GROUP DUTY FREE CORPORATION LIMITED",
    name_zh: "中國中免",
    isHKConnect: 1,
  },
  {
    no: "1881",
    name_en: "REGAL REAL ESTATE INVESTMENT TRUST",
    name_zh: "富豪產業信託",
    isHKConnect: 0,
  },
  {
    no: "1882",
    name_en: "HAITIAN INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "海天國際",
    isHKConnect: 1,
  },
  {
    no: "1883",
    name_en: "CITIC TELECOM INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "中信國際電訊",
    isHKConnect: 1,
  },
  {
    no: "1884",
    name_en: "EPRINT GROUP LIMITED",
    name_zh: "EPRINT集團",
    isHKConnect: 0,
  },
  {
    no: "1885",
    name_en: "CHINA WOOD OPTIMIZATION (HOLDING) LIMITED",
    name_zh: "中國優材",
    isHKConnect: 0,
  },
  {
    no: "1888",
    name_en: "KINGBOARD LAMINATES HOLDINGS LIMITED",
    name_zh: "建滔積層板",
    isHKConnect: 1,
  },
  {
    no: "1889",
    name_en: "SANAI HEALTH INDUSTRY GROUP COMPANY LIMITED",
    name_zh: "三愛健康集團",
    isHKConnect: 0,
  },
  {
    no: "1890",
    name_en: "CHINA KEPEI EDUCATION GROUP LIMITED",
    name_zh: "中國科培",
    isHKConnect: 1,
  },
  {
    no: "1891",
    name_en: "HENG HUP HOLDINGS LIMITED",
    name_zh: "興合控股",
    isHKConnect: 0,
  },
  {
    no: "1894",
    name_en: "HANG YICK HOLDINGS COMPANY LIMITED",
    name_zh: "恆益控股",
    isHKConnect: 0,
  },
  {
    no: "1895",
    name_en: "XINYUAN PROPERTY MANAGEMENT SERVICE (CAYMAN) LTD.",
    name_zh: "鑫苑服務",
    isHKConnect: 0,
  },
  {
    no: "1896",
    name_en: "MAOYAN ENTERTAINMENT",
    name_zh: "貓眼娛樂",
    isHKConnect: 1,
  },
  {
    no: "1897",
    name_en: "MILLION HOPE INDUSTRIES HOLDINGS LIMITED",
    name_zh: "美亨實業",
    isHKConnect: 0,
  },
  {
    no: "1898",
    name_en: "CHINA COAL ENERGY COMPANY LIMITED",
    name_zh: "中煤能源",
    isHKConnect: 1,
  },
  {
    no: "1899",
    name_en: "XINGDA INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "興達國際",
    isHKConnect: 0,
  },
  {
    no: "1900",
    name_en: "CHINA ITS (HOLDINGS) CO., LTD.",
    name_zh: "中國智能交通",
    isHKConnect: 0,
  },
  {
    no: "1901",
    name_en: "FEIYANG INTERNATIONAL HOLDINGS GROUP LIMITED",
    name_zh: "飛揚集團",
    isHKConnect: 0,
  },
  {
    no: "1902",
    name_en: "YINCHENG INTERNATIONAL HOLDING CO., LTD.",
    name_zh: "銀城國際控股",
    isHKConnect: 0,
  },
  {
    no: "1903",
    name_en: "JBB BUILDERS INTERNATIONAL LIMITED",
    name_zh: "JBB BUILDERS INTERNATIONAL LIMITED",
    isHKConnect: 0,
  },
  {
    no: "1905",
    name_en: "HAITONG UNITRUST INTERNATIONAL FINANCIAL LEASING CO., LTD.",
    name_zh: "海通恆信",
    isHKConnect: 1,
  },
  {
    no: "1906",
    name_en: "BONNY INTERNATIONAL HOLDING LIMITED",
    name_zh: "博尼控股",
    isHKConnect: 0,
  },
  {
    no: "1907",
    name_en: "CHINA RISUN GROUP LIMITED",
    name_zh: "中國旭陽集團",
    isHKConnect: 1,
  },
  {
    no: "1908",
    name_en: "C&D INTERNATIONAL INVESTMENT GROUP LIMITED",
    name_zh: "建發國際集團",
    isHKConnect: 1,
  },
  {
    no: "1909",
    name_en: "FIRE ROCK HOLDINGS LIMITED",
    name_zh: "火岩控股",
    isHKConnect: 1,
  },
  {
    no: "1910",
    name_en: "SAMSONITE INTERNATIONAL S.A.",
    name_zh: "新秀麗",
    isHKConnect: 0,
  },
  {
    no: "1911",
    name_en: "CHINA RENAISSANCE HOLDINGS LIMITED",
    name_zh: "華興資本控股",
    isHKConnect: 1,
  },
  {
    no: "1912",
    name_en: "CONTEL TECHNOLOGY COMPANY LIMITED",
    name_zh: "康特隆",
    isHKConnect: 0,
  },
  { no: "1913", name_en: "PRADA S.P.A.", name_zh: "普拉達", isHKConnect: 0 },
  {
    no: "1915",
    name_en:
      "YANGZHOU GUANGLING DISTRICT TAIHE RURAL MICRO-FINANCE COMPANY LIMITED",
    name_zh: "泰和小貸",
    isHKConnect: 0,
  },
  {
    no: "1916",
    name_en: "JIANGXI BANK CO., LTD.",
    name_zh: "江西銀行",
    isHKConnect: 1,
  },
  { no: "1917", name_en: "DOUMOB", name_zh: "豆盟科技", isHKConnect: 0 },
  {
    no: "1918",
    name_en: "SUNAC CHINA HOLDINGS LIMITED",
    name_zh: "融創中國",
    isHKConnect: 1,
  },
  {
    no: "1919",
    name_en: "COSCO SHIPPING HOLDINGS CO., LTD.",
    name_zh: "中遠海控",
    isHKConnect: 1,
  },
  {
    no: "1920",
    name_en: "HANDS FORM HOLDINGS LIMITED",
    name_zh: "恆新豐控股",
    isHKConnect: 0,
  },
  {
    no: "1921",
    name_en: "DALIPAL HOLDINGS LIMITED",
    name_zh: "達力普控股",
    isHKConnect: 0,
  },
  {
    no: "1922",
    name_en: "YINCHENG LIFE SERVICE CO., LTD.",
    name_zh: "銀城生活服務",
    isHKConnect: 0,
  },
  {
    no: "1925",
    name_en: "KWUNG'S HOLDINGS LIMITED",
    name_zh: "曠世控股",
    isHKConnect: 0,
  },
  {
    no: "1927",
    name_en: "JIUJIUWANG FOOD INTERNATIONAL LIMITED",
    name_zh: "久久王",
    isHKConnect: 0,
  },
  {
    no: "1928",
    name_en: "SANDS CHINA LTD.",
    name_zh: "金沙中國有限公司",
    isHKConnect: 1,
  },
  {
    no: "1929",
    name_en: "CHOW TAI FOOK JEWELLERY GROUP LIMITED",
    name_zh: "周大福",
    isHKConnect: 1,
  },
  {
    no: "1930",
    name_en: "SHINELONG AUTOMOTIVE LIGHTWEIGHT APPLICATION LIMITED",
    name_zh: "勛龍",
    isHKConnect: 0,
  },
  {
    no: "1931",
    name_en: "IVD MEDICAL HOLDING LIMITED",
    name_zh: "華檢醫療",
    isHKConnect: 0,
  },
  {
    no: "1932",
    name_en: "CPM GROUP LIMITED",
    name_zh: "中漆集團",
    isHKConnect: 0,
  },
  {
    no: "1933",
    name_en: "ONEFORCE HOLDINGS LIMITED",
    name_zh: "元力控股",
    isHKConnect: 0,
  },
  {
    no: "1935",
    name_en: "JH EDUCATIONAL TECHNOLOGY INC.",
    name_zh: "嘉宏教育",
    isHKConnect: 0,
  },
  {
    no: "1936",
    name_en: "RITAMIX GLOBAL LIMITED",
    name_zh: "利特米",
    isHKConnect: 0,
  },
  {
    no: "1937",
    name_en: "JIACHEN HOLDING GROUP LIMITED",
    name_zh: "佳辰控股",
    isHKConnect: 0,
  },
  {
    no: "1938",
    name_en: "CHU KONG PETROLEUM AND NATURAL GAS STEEL PIPE HOLDINGS LIMITED",
    name_zh: "珠江鋼管",
    isHKConnect: 0,
  },
  {
    no: "1939",
    name_en: "TOKYO CHUO AUCTION HOLDINGS LIMITED",
    name_zh: "東京中央拍賣",
    isHKConnect: 0,
  },
  {
    no: "1940",
    name_en: "CHINA GAS INDUSTRY INVESTMENT HOLDINGS CO. LTD.",
    name_zh: "CHINA GAS INDUSTRY INVESTMENT HOLDINGS CO. LTD.",
    isHKConnect: 0,
  },
  {
    no: "1941",
    name_en: "YE XING GROUP HOLDINGS LIMITED",
    name_zh: "燁星集團",
    isHKConnect: 0,
  },
  {
    no: "1942",
    name_en: "MOG HOLDINGS LIMITED",
    name_zh: "MOG HOLDINGS LIMITED",
    isHKConnect: 0,
  },
  {
    no: "1943",
    name_en: "SILVER TIDE HOLDINGS LIMITED",
    name_zh: "銀濤控股",
    isHKConnect: 0,
  },
  {
    no: "1945",
    name_en: "ZERO2IPO HOLDINGS INC.",
    name_zh: "清科創業",
    isHKConnect: 0,
  },
  {
    no: "1948",
    name_en: "UJU HOLDING LIMITED",
    name_zh: "優矩控股",
    isHKConnect: 0,
  },
  {
    no: "1949",
    name_en: "PLATT NERA INTERNATIONAL LIMITED",
    name_zh: "PLATT NERA INTERNATIONAL LIMITED",
    isHKConnect: 0,
  },
  {
    no: "1950",
    name_en: "DIWANG INDUSTRIAL HOLDINGS LIMITED",
    name_zh: "帝王實業控股",
    isHKConnect: 0,
  },
  {
    no: "1951",
    name_en: "JINXIN FERTILITY GROUP LIMITED",
    name_zh: "錦欣生殖",
    isHKConnect: 1,
  },
  {
    no: "1952",
    name_en: "EVEREST MEDICINES LIMITED",
    name_zh: "雲頂新耀-Ｂ",
    isHKConnect: 1,
  },
  {
    no: "1953",
    name_en: "RIMBACO GROUP GLOBAL LIMITED",
    name_zh: "RIMBACO GROUP GLOBAL LIMITED",
    isHKConnect: 0,
  },
  {
    no: "1955",
    name_en: "HONG KONG JOHNSON HOLDINGS CO., LTD.",
    name_zh: "莊臣控股",
    isHKConnect: 0,
  },
  {
    no: "1957",
    name_en: "MBV INTERNATIONAL LIMITED",
    name_zh: "MBV INTERNATIONAL LIMITED",
    isHKConnect: 0,
  },
  {
    no: "1958",
    name_en: "BAIC MOTOR CORPORATION LIMITED",
    name_zh: "北京汽車",
    isHKConnect: 1,
  },
  {
    no: "1959",
    name_en: "CENTENARY UNITED HOLDINGS LIMITED",
    name_zh: "世紀聯合控股",
    isHKConnect: 0,
  },
  {
    no: "1960",
    name_en: "TBK & SONS HOLDINGS LIMITED",
    name_zh: "TBK & SONS HOLDINGS LIMITED",
    isHKConnect: 0,
  },
  {
    no: "1961",
    name_en: "INFINITIES TECHNOLOGY INTERNATIONAL (CAYMAN) HOLDING LIMITED",
    name_zh: "多牛科技",
    isHKConnect: 0,
  },
  {
    no: "1962",
    name_en: "EVERGREEN PRODUCTS GROUP LIMITED",
    name_zh: "訓修實業",
    isHKConnect: 0,
  },
  {
    no: "1963",
    name_en: "BANK OF CHONGQING CO., LTD.",
    name_zh: "重慶銀行",
    isHKConnect: 1,
  },
  {
    no: "1965",
    name_en: "LANDSEA GREEN LIFE SERVICE COMPANY LIMITED",
    name_zh: "朗詩綠色生活",
    isHKConnect: 0,
  },
  {
    no: "1966",
    name_en: "CHINA SCE GROUP HOLDINGS LIMITED",
    name_zh: "中駿集團控股",
    isHKConnect: 1,
  },
  {
    no: "1967",
    name_en: "CONFIDENCE INTELLIGENCE HOLDINGS LIMITED",
    name_zh: "信懇智能",
    isHKConnect: 0,
  },
  {
    no: "1968",
    name_en: "HINGTEX HOLDINGS LIMITED",
    name_zh: "興紡控股",
    isHKConnect: 0,
  },
  {
    no: "1969",
    name_en: "CHINA CHUNLAI EDUCATION GROUP CO., LTD.",
    name_zh: "中國春來",
    isHKConnect: 0,
  },
  {
    no: "1970",
    name_en: "IMAX CHINA HOLDING, INC.",
    name_zh: "IMAX CHINA HOLDING, INC.",
    isHKConnect: 1,
  },
  {
    no: "1971",
    name_en: "REDSUN SERVICES GROUP LIMITED",
    name_zh: "弘陽服務",
    isHKConnect: 0,
  },
  {
    no: "1972",
    name_en: "SWIRE PROPERTIES LIMITED",
    name_zh: "太古地產",
    isHKConnect: 1,
  },
  {
    no: "1975",
    name_en: "SUN HING PRINTING HOLDINGS LIMITED",
    name_zh: "新興印刷",
    isHKConnect: 0,
  },
  {
    no: "1977",
    name_en: "ANALOGUE HOLDINGS LIMITED",
    name_zh: "安樂工程",
    isHKConnect: 0,
  },
  {
    no: "1978",
    name_en: "LH GROUP LIMITED",
    name_zh: "敘福樓集團",
    isHKConnect: 0,
  },
  {
    no: "1979",
    name_en: "TEN PAO GROUP HOLDINGS LIMITED",
    name_zh: "天寶集團",
    isHKConnect: 0,
  },
  {
    no: "1980",
    name_en: "TIAN GE INTERACTIVE HOLDINGS LIMITED",
    name_zh: "天鴿互動",
    isHKConnect: 1,
  },
  {
    no: "1981",
    name_en: "CATHAY MEDIA AND EDUCATION GROUP INC.",
    name_zh: "華夏視聽教育",
    isHKConnect: 1,
  },
  {
    no: "1982",
    name_en: "NAMESON HOLDINGS LIMITED",
    name_zh: "南旋控股",
    isHKConnect: 0,
  },
  {
    no: "1983",
    name_en: "LUZHOU BANK CO., LTD.",
    name_zh: "瀘州銀行",
    isHKConnect: 0,
  },
  {
    no: "1985",
    name_en: "MICROWARE GROUP LIMITED",
    name_zh: "美高域",
    isHKConnect: 0,
  },
  {
    no: "1986",
    name_en: "TSAKER NEW ENERGY TECH CO., LIMITED",
    name_zh: "彩客新能源",
    isHKConnect: 0,
  },
  {
    no: "1987",
    name_en: "BENG SOON MACHINERY HOLDINGS LIMITED",
    name_zh: "BENG SOON MACHINERY HOLDINGS LIMITED",
    isHKConnect: 0,
  },
  {
    no: "1988",
    name_en: "CHINA MINSHENG BANKING CORP., LTD.",
    name_zh: "民生銀行",
    isHKConnect: 1,
  },
  {
    no: "1989",
    name_en: "PINE CARE GROUP LIMITED",
    name_zh: "松齡護老集團",
    isHKConnect: 0,
  },
  {
    no: "1991",
    name_en: "TA YANG GROUP HOLDINGS LIMITED",
    name_zh: "大洋集團",
    isHKConnect: 0,
  },
  {
    no: "1992",
    name_en: "FOSUN TOURISM GROUP",
    name_zh: "復星旅遊文化",
    isHKConnect: 1,
  },
  {
    no: "1993",
    name_en: "ASIARAY MEDIA GROUP LIMITED",
    name_zh: "雅仕維",
    isHKConnect: 0,
  },
  {
    no: "1995",
    name_en: "CIFI EVER SUNSHINE SERVICES GROUP LIMITED",
    name_zh: "旭輝永升服務",
    isHKConnect: 1,
  },
  {
    no: "1996",
    name_en: "REDSUN PROPERTIES GROUP LIMITED",
    name_zh: "弘陽地產",
    isHKConnect: 1,
  },
  {
    no: "1997",
    name_en: "WHARF REAL ESTATE INVESTMENT COMPANY LIMITED",
    name_zh: "九龍倉置業",
    isHKConnect: 1,
  },
  {
    no: "1999",
    name_en: "MAN WAH HOLDINGS LIMITED",
    name_zh: "敏華控股",
    isHKConnect: 1,
  },
  {
    no: "2000",
    name_en: "SIM TECHNOLOGY GROUP LIMITED",
    name_zh: "晨訊科技",
    isHKConnect: 0,
  },
  {
    no: "2001",
    name_en: "CHINA NEW HIGHER EDUCATION GROUP LIMITED",
    name_zh: "新高教集團",
    isHKConnect: 1,
  },
  {
    no: "2002",
    name_en: "CHINA SUNSHINE PAPER HOLDINGS COMPANY LIMITED",
    name_zh: "陽光紙業",
    isHKConnect: 0,
  },
  {
    no: "2003",
    name_en: "VCREDIT HOLDINGS LIMITED",
    name_zh: "維信金科",
    isHKConnect: 1,
  },
  {
    no: "2005",
    name_en: "SSY GROUP LIMITED",
    name_zh: "石四藥集團",
    isHKConnect: 1,
  },
  {
    no: "2007",
    name_en: "COUNTRY GARDEN HOLDINGS COMPANY LIMITED",
    name_zh: "碧桂園",
    isHKConnect: 1,
  },
  {
    no: "2008",
    name_en: "PHOENIX MEDIA INVESTMENT (HOLDINGS) LIMITED",
    name_zh: "鳳凰衛視",
    isHKConnect: 1,
  },
  {
    no: "2009",
    name_en: "BBMG CORPORATION",
    name_zh: "金隅集團",
    isHKConnect: 1,
  },
  {
    no: "2011",
    name_en: "CHINA APEX GROUP LIMITED",
    name_zh: "中國恆泰集團",
    isHKConnect: 0,
  },
  {
    no: "2012",
    name_en: "SUNSHINE OILSANDS LTD.",
    name_zh: "陽光油砂",
    isHKConnect: 0,
  },
  { no: "2013", name_en: "WEIMOB INC.", name_zh: "微盟集團", isHKConnect: 1 },
  {
    no: "2014",
    name_en: "OZNER WATER INTERNATIONAL HOLDING LIMITED",
    name_zh: "浩澤淨水",
    isHKConnect: 0,
  },
  {
    no: "2015",
    name_en: "LI AUTO INC.",
    name_zh: "理想汽車-W",
    isHKConnect: 1,
  },
  {
    no: "2016",
    name_en: "CHINA ZHESHANG BANK CO., LTD.",
    name_zh: "浙商銀行",
    isHKConnect: 1,
  },
  {
    no: "2017",
    name_en: "CHANHIGH HOLDINGS LIMITED",
    name_zh: "滄海控股",
    isHKConnect: 0,
  },
  {
    no: "2018",
    name_en: "AAC TECHNOLOGIES HOLDINGS INC.",
    name_zh: "瑞聲科技",
    isHKConnect: 1,
  },
  {
    no: "2019",
    name_en: "DEXIN CHINA HOLDINGS COMPANY LIMITED",
    name_zh: "德信中國",
    isHKConnect: 1,
  },
  {
    no: "2020",
    name_en: "ANTA SPORTS PRODUCTS LIMITED",
    name_zh: "安踏體育",
    isHKConnect: 1,
  },
  {
    no: "2022",
    name_en: "DIGITAL HOLLYWOOD INTERACTIVE LIMITED",
    name_zh: "遊萊互動",
    isHKConnect: 0,
  },
  {
    no: "2023",
    name_en: "CHINA LUDAO TECHNOLOGY COMPANY LIMITED",
    name_zh: "中國綠島科技",
    isHKConnect: 0,
  },
  {
    no: "2025",
    name_en: "RUIFENG POWER GROUP COMPANY LIMITED",
    name_zh: "瑞豐動力",
    isHKConnect: 0,
  },
  {
    no: "2028",
    name_en: "JOLIMARK HOLDINGS LIMITED",
    name_zh: "映美控股",
    isHKConnect: 0,
  },
  {
    no: "2030",
    name_en: "CABBEEN FASHION LIMITED",
    name_zh: "卡賓",
    isHKConnect: 0,
  },
  {
    no: "2031",
    name_en: "AUSUPREME INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "澳至尊",
    isHKConnect: 0,
  },
  {
    no: "2033",
    name_en: "TIME WATCH INVESTMENTS LIMITED",
    name_zh: "時計寶",
    isHKConnect: 0,
  },
  {
    no: "2038",
    name_en: "FIH MOBILE LIMITED",
    name_zh: "富智康集團",
    isHKConnect: 1,
  },
  {
    no: "2039",
    name_en: "CHINA INTERNATIONAL MARINE CONTAINERS (GROUP) CO., LTD.",
    name_zh: "中集集團",
    isHKConnect: 1,
  },
  {
    no: "2048",
    name_en: "E-HOUSE (CHINA) ENTERPRISE HOLDINGS LIMITED",
    name_zh: "易居企業控股",
    isHKConnect: 1,
  },
  {
    no: "2051",
    name_en: "51 CREDIT CARD INC.",
    name_zh: "５１信用卡",
    isHKConnect: 1,
  },
  {
    no: "2057",
    name_en: "ZTO EXPRESS (CAYMAN) INC.",
    name_zh: "中通快遞-SW",
    isHKConnect: 0,
  },
  {
    no: "2060",
    name_en: "PUJIANG INTERNATIONAL GROUP LIMITED",
    name_zh: "浦江國際",
    isHKConnect: 0,
  },
  {
    no: "2066",
    name_en: "SHENGJING BANK CO., LTD.",
    name_zh: "盛京銀行",
    isHKConnect: 1,
  },
  {
    no: "2068",
    name_en: "CHINA ALUMINUM INTERNATIONAL ENGINEERING CORPORATION LIMITED",
    name_zh: "中鋁國際",
    isHKConnect: 1,
  },
  {
    no: "2078",
    name_en: "PANASIALUM HOLDINGS COMPANY LIMITED",
    name_zh: "榮陽實業",
    isHKConnect: 0,
  },
  {
    no: "2080",
    name_en: "AUX INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "奧克斯國際",
    isHKConnect: 0,
  },
  {
    no: "2086",
    name_en: "LEADWAY TECHNOLOGY INVESTMENT GROUP LIMITED",
    name_zh: "高維科技",
    isHKConnect: 0,
  },
  {
    no: "2088",
    name_en: "XIWANG PROPERTY HOLDINGS COMPANY LIMITED",
    name_zh: "西王置業",
    isHKConnect: 0,
  },
  {
    no: "2096",
    name_en: "SIMCERE PHARMACEUTICAL GROUP LIMITED",
    name_zh: "先聲藥業",
    isHKConnect: 1,
  },
  {
    no: "2098",
    name_en: "ZALL SMART COMMERCE GROUP LTD.",
    name_zh: "卓爾智聯",
    isHKConnect: 1,
  },
  {
    no: "2099",
    name_en: "CHINA GOLD INTERNATIONAL RESOURCES CORP. LTD.",
    name_zh: "中國黃金國際",
    isHKConnect: 1,
  },
  {
    no: "2100",
    name_en: "BAIOO FAMILY INTERACTIVE LIMITED",
    name_zh: "百奧家庭互動",
    isHKConnect: 0,
  },
  {
    no: "2101",
    name_en: "FULU HOLDINGS LIMITED",
    name_zh: "福祿控股",
    isHKConnect: 0,
  },
  {
    no: "2102",
    name_en: "TAK LEE MACHINERY HOLDINGS LIMITED",
    name_zh: "德利機械",
    isHKConnect: 0,
  },
  {
    no: "2103",
    name_en: "SINIC HOLDINGS (GROUP) COMPANY LIMITED",
    name_zh: "新力控股集團",
    isHKConnect: 1,
  },
  {
    no: "2107",
    name_en: "FIRST SERVICE HOLDING LIMITED",
    name_zh: "第一服務控股",
    isHKConnect: 0,
  },
  {
    no: "2108",
    name_en: "K2 F&B HOLDINGS LIMITED",
    name_zh: "K2 F&B HOLDINGS LIMITED",
    isHKConnect: 0,
  },
  {
    no: "2110",
    name_en: "YUE KAN HOLDINGS LIMITED",
    name_zh: "裕勤控股",
    isHKConnect: 0,
  },
  {
    no: "2111",
    name_en: "BEST PACIFIC INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "超盈國際控股",
    isHKConnect: 1,
  },
  {
    no: "2112",
    name_en: "CAA RESOURCES LIMITED",
    name_zh: "優庫資源",
    isHKConnect: 0,
  },
  {
    no: "2113",
    name_en: "CENTURY GROUP INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "世紀集團國際",
    isHKConnect: 0,
  },
  {
    no: "2115",
    name_en: "CHANNEL MICRON HOLDINGS COMPANY LIMITED",
    name_zh: "捷心隆",
    isHKConnect: 0,
  },
  {
    no: "2116",
    name_en: "JIANGSU INNOVATIVE ECOLOGICAL NEW MATERIALS LIMITED",
    name_zh: "江蘇創新",
    isHKConnect: 0,
  },
  {
    no: "2117",
    name_en: "DATANG GROUP HOLDINGS LIMITED",
    name_zh: "大唐集團控股",
    isHKConnect: 0,
  },
  {
    no: "2118",
    name_en: "TIAN SHAN DEVELOPMENT (HOLDING) LIMITED",
    name_zh: "天山發展控股",
    isHKConnect: 0,
  },
  {
    no: "2119",
    name_en: "TSIT WING INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "捷榮國際控股",
    isHKConnect: 0,
  },
  {
    no: "2120",
    name_en: "WENZHOU KANGNING HOSPITAL CO., LTD.",
    name_zh: "康寧醫院",
    isHKConnect: 0,
  },
  {
    no: "2121",
    name_en: "QINGDAO AINNOVATION TECHNOLOGY GROUP CO., LTD",
    name_zh: "創新奇智",
    isHKConnect: 1,
  },
  {
    no: "2122",
    name_en: "KIDSLAND INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "凱知樂國際",
    isHKConnect: 0,
  },
  {
    no: "2125",
    name_en: "STRAWBEAR ENTERTAINMENT GROUP",
    name_zh: "稻草熊娛樂",
    isHKConnect: 0,
  },
  {
    no: "2126",
    name_en: "JW (CAYMAN) THERAPEUTICS CO. LTD",
    name_zh: "藥明巨諾-B",
    isHKConnect: 1,
  },
  {
    no: "2127",
    name_en: "HUISEN HOUSEHOLD INTERNATIONAL GROUP LIMITED",
    name_zh: "匯森家居",
    isHKConnect: 0,
  },
  {
    no: "2128",
    name_en: "CHINA LESSO GROUP HOLDINGS LIMITED",
    name_zh: "中國聯塑",
    isHKConnect: 1,
  },
  {
    no: "2129",
    name_en: "LEGION CONSORTIUM LIMITED",
    name_zh: "LEGION CONSORTIUM LIMITED",
    isHKConnect: 0,
  },
  {
    no: "2130",
    name_en: "CN LOGISTICS INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "嘉泓物流",
    isHKConnect: 0,
  },
  {
    no: "2131",
    name_en: "NETJOY HOLDINGS LIMITED",
    name_zh: "云想科技",
    isHKConnect: 0,
  },
  {
    no: "2132",
    name_en: "LANDRICH HOLDING LIMITED",
    name_zh: "LANDRICH HOLDING LIMITED",
    isHKConnect: 0,
  },
  {
    no: "2133",
    name_en: "GREENWAY MINING GROUP LIMITED",
    name_zh: "信盛礦業",
    isHKConnect: 0,
  },
  {
    no: "2135",
    name_en: "RAILY AESTHETIC MEDICINE INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "瑞麗醫美",
    isHKConnect: 0,
  },
  {
    no: "2136",
    name_en: "LIFESTYLE CHINA GROUP LIMITED",
    name_zh: "利福中國",
    isHKConnect: 1,
  },
  {
    no: "2137",
    name_en: "BRII BIOSCIENCES LIMITED",
    name_zh: "騰盛博藥-B",
    isHKConnect: 1,
  },
  { no: "2138", name_en: "EC HEALTHCARE", name_zh: "醫思健康", isHKConnect: 1 },
  {
    no: "2139",
    name_en: "BANK OF GANSU CO., LTD.",
    name_zh: "甘肅銀行",
    isHKConnect: 0,
  },
  {
    no: "2142",
    name_en: "HBM HOLDINGS LIMITED",
    name_zh: "和鉑醫藥-B",
    isHKConnect: 1,
  },
  {
    no: "2146",
    name_en: "ROISERV LIFESTYLE SERVICES CO., LTD.",
    name_zh: "榮萬家",
    isHKConnect: 0,
  },
  {
    no: "2148",
    name_en: "VESYNC CO., LTD",
    name_zh: "VESYNC CO., LTD",
    isHKConnect: 0,
  },
  {
    no: "2150",
    name_en: "NAYUKI HOLDINGS LIMITED",
    name_zh: "奈雪的茶",
    isHKConnect: 1,
  },
  {
    no: "2152",
    name_en: "SUXIN JOYFUL LIFE SERVICES CO., LTD.",
    name_zh: "蘇新服務",
    isHKConnect: 0,
  },
  {
    no: "2153",
    name_en: "TAT HONG EQUIPMENT SERVICE CO., LTD.",
    name_zh: "達豐設備",
    isHKConnect: 0,
  },
  {
    no: "2155",
    name_en: "MORIMATSU INTERNATIONAL HOLDINGS COMPANY LIMITED",
    name_zh: "森松國際",
    isHKConnect: 0,
  },
  {
    no: "2156",
    name_en: "C&D PROPERTY MANAGEMENT GROUP CO., LTD",
    name_zh: "建發物業",
    isHKConnect: 1,
  },
  {
    no: "2157",
    name_en: "LEPU BIOPHARMA CO., LTD.",
    name_zh: "樂普生物-B",
    isHKConnect: 1,
  },
  {
    no: "2158",
    name_en: "YIDU TECH INC.",
    name_zh: "醫渡科技",
    isHKConnect: 1,
  },
  {
    no: "2159",
    name_en: "MEDIWELCOME HEALTHCARE MANAGEMENT & TECHNOLOGY INC.",
    name_zh: "麥迪衛康",
    isHKConnect: 0,
  },
  {
    no: "2160",
    name_en: "MICROPORT CARDIOFLOW MEDTECH CORPORATION",
    name_zh: "心通醫療-B",
    isHKConnect: 1,
  },
  {
    no: "2161",
    name_en: "JBM (HEALTHCARE) LIMITED",
    name_zh: "健倍苗苗",
    isHKConnect: 0,
  },
  {
    no: "2162",
    name_en: "KEYMED BIOSCIENCES INC.",
    name_zh: "康諾亞-B",
    isHKConnect: 1,
  },
  {
    no: "2163",
    name_en: "CHANGSHA BROAD HOMES INDUSTRIAL GROUP CO., LTD.",
    name_zh: "遠大住工",
    isHKConnect: 0,
  },
  {
    no: "2165",
    name_en: "LING YUE SERVICES GROUP LIMITED",
    name_zh: "領悅服務集團",
    isHKConnect: 0,
  },
  {
    no: "2166",
    name_en: "SMART-CORE HOLDINGS LIMITED",
    name_zh: "芯智控股",
    isHKConnect: 0,
  },
  { no: "2167", name_en: "TI CLOUD INC.", name_zh: "天潤雲", isHKConnect: 0 },
  {
    no: "2168",
    name_en: "KAISA PROSPERITY HOLDINGS LIMITED",
    name_zh: "佳兆業美好",
    isHKConnect: 0,
  },
  {
    no: "2169",
    name_en: "CANGGANG RAILWAY LIMITED",
    name_zh: "滄港鐵路",
    isHKConnect: 0,
  },
  {
    no: "2170",
    name_en: "SUZHOU BASECARE MEDICAL CORPORATION LIMITED",
    name_zh: "貝康醫療-B",
    isHKConnect: 0,
  },
  {
    no: "2171",
    name_en: "CARSGEN THERAPEUTICS HOLDINGS LIMITED",
    name_zh: "科濟藥業-B",
    isHKConnect: 1,
  },
  {
    no: "2172",
    name_en: "MICROPORT NEUROTECH LIMITED",
    name_zh: "微創腦科學",
    isHKConnect: 0,
  },
  {
    no: "2175",
    name_en: "CHINA GENERAL EDUCATION GROUP LIMITED",
    name_zh: "中國通才教育",
    isHKConnect: 0,
  },
  {
    no: "2176",
    name_en: "CCID CONSULTING COMPANY LIMITED",
    name_zh: "賽迪顧問",
    isHKConnect: 0,
  },
  {
    no: "2177",
    name_en: "UNQ HOLDINGS LIMITED",
    name_zh: "優趣匯控股",
    isHKConnect: 0,
  },
  {
    no: "2178",
    name_en: "PETRO-KING OILFIELD SERVICES LIMITED",
    name_zh: "百勤油服",
    isHKConnect: 0,
  },
  {
    no: "2179",
    name_en: "JIANGSU RECBIO TECHNOLOGY CO., LTD.",
    name_zh: "瑞科生物-B",
    isHKConnect: 0,
  },
  {
    no: "2180",
    name_en: "MANPOWERGROUP GREATER CHINA LIMITED",
    name_zh: "萬寶盛華",
    isHKConnect: 0,
  },
  {
    no: "2181",
    name_en: "MABPHARM LIMITED",
    name_zh: "邁博藥業-B",
    isHKConnect: 0,
  },
  {
    no: "2182",
    name_en: "TIAN CHANG GROUP HOLDINGS LTD.",
    name_zh: "天長集團",
    isHKConnect: 0,
  },
  {
    no: "2183",
    name_en: "SANSHENG HOLDINGS (GROUP) CO. LTD.",
    name_zh: "三盛控股",
    isHKConnect: 0,
  },
  {
    no: "2185",
    name_en: "SHANGHAI BIO-HEART BIOLOGICAL TECHNOLOGY CO., LTD.",
    name_zh: "百心安-B",
    isHKConnect: 0,
  },
  {
    no: "2186",
    name_en: "LUYE PHARMA GROUP LTD.",
    name_zh: "綠葉製藥",
    isHKConnect: 1,
  },
  {
    no: "2187",
    name_en: "ZHIXIN GROUP HOLDING LIMITED",
    name_zh: "智欣集團控股",
    isHKConnect: 0,
  },
  {
    no: "2188",
    name_en: "CHINA TITANS ENERGY TECHNOLOGY GROUP CO LIMITED",
    name_zh: "泰坦能源技術",
    isHKConnect: 0,
  },
  {
    no: "2189",
    name_en: "KATO (HONG KONG) HOLDINGS LIMITED",
    name_zh: "嘉濤（香港）控股",
    isHKConnect: 0,
  },
  {
    no: "2190",
    name_en: "ZYLOX-TONBRIDGE MEDICAL TECHNOLOGY CO., LTD.",
    name_zh: "歸創通橋-B",
    isHKConnect: 0,
  },
  {
    no: "2191",
    name_en: "SF REAL ESTATE INVESTMENT TRUST",
    name_zh: "順豐房託",
    isHKConnect: 0,
  },
  {
    no: "2192",
    name_en: "MEDLIVE TECHNOLOGY CO., LTD.",
    name_zh: "醫脈通",
    isHKConnect: 1,
  },
  {
    no: "2193",
    name_en: "MAN KING HOLDINGS LIMITED",
    name_zh: "萬景控股",
    isHKConnect: 0,
  },
  {
    no: "2195",
    name_en: "UNITY ENTERPRISE HOLDINGS LIMITED",
    name_zh: "盈匯企業控股",
    isHKConnect: 0,
  },
  {
    no: "2196",
    name_en: "SHANGHAI FOSUN PHARMACEUTICAL (GROUP) CO., LTD.",
    name_zh: "復星醫藥",
    isHKConnect: 1,
  },
  {
    no: "2197",
    name_en: "CLOVER BIOPHARMACEUTICALS, LTD.",
    name_zh: "三葉草生物-B",
    isHKConnect: 1,
  },
  {
    no: "2198",
    name_en: "CHINA SANJIANG FINE CHEMICALS COMPANY LIMITED",
    name_zh: "中國三江化工",
    isHKConnect: 0,
  },
  {
    no: "2199",
    name_en: "REGINA MIRACLE INTERNATIONAL (HOLDINGS) LIMITED",
    name_zh: "維珍妮",
    isHKConnect: 1,
  },
  {
    no: "2202",
    name_en: "CHINA VANKE CO., LTD.",
    name_zh: "萬科企業",
    isHKConnect: 1,
  },
  {
    no: "2203",
    name_en: "BRAINHOLE TECHNOLOGY LIMITED",
    name_zh: "腦洞科技",
    isHKConnect: 0,
  },
  {
    no: "2205",
    name_en: "KANGQIAO SERVICE GROUP LIMITED",
    name_zh: "康橋悅生活",
    isHKConnect: 0,
  },
  {
    no: "2207",
    name_en: "RONSHINE SERVICE HOLDING CO., LTD",
    name_zh: "融信服務",
    isHKConnect: 0,
  },
  {
    no: "2208",
    name_en: "XINJIANG GOLDWIND SCIENCE & TECHNOLOGY CO., LTD.",
    name_zh: "金風科技",
    isHKConnect: 1,
  },
  {
    no: "2209",
    name_en: "YESASIA HOLDINGS LIMITED",
    name_zh: "YESASIA HOLDINGS LIMITED",
    isHKConnect: 0,
  },
  {
    no: "2210",
    name_en: "BEIJING CAPITAL JIAYE PROPERTY SERVICES CO., LIMITED",
    name_zh: "京城佳業",
    isHKConnect: 0,
  },
  {
    no: "2211",
    name_en: "UNIVERSAL HEALTH INTERNATIONAL GROUP HOLDING LIMITED",
    name_zh: "大健康國際",
    isHKConnect: 0,
  },
  {
    no: "2212",
    name_en: "FUTURE BRIGHT MINING HOLDINGS LIMITED",
    name_zh: "高鵬礦業",
    isHKConnect: 0,
  },
  {
    no: "2215",
    name_en: "DEXIN SERVICES GROUP LIMITED",
    name_zh: "德信服務集團",
    isHKConnect: 0,
  },
  {
    no: "2216",
    name_en: "BRONCUS HOLDING CORPORATION",
    name_zh: "BRONCUS HOLDING CORPORATION",
    isHKConnect: 0,
  },
  {
    no: "2217",
    name_en: "TAM JAI INTERNATIONAL CO. LIMITED",
    name_zh: "譚仔國際",
    isHKConnect: 0,
  },
  {
    no: "2218",
    name_en: "YANTAI NORTH ANDRE JUICE CO., LTD.",
    name_zh: "安德利果汁",
    isHKConnect: 1,
  },
  {
    no: "2219",
    name_en: "CHAOJU EYE CARE HOLDINGS LIMITED",
    name_zh: "朝聚眼科",
    isHKConnect: 0,
  },
  {
    no: "2221",
    name_en: "NEW CONCEPTS HOLDINGS LIMITED",
    name_zh: "創業集團控股",
    isHKConnect: 0,
  },
  {
    no: "2222",
    name_en: "NVC INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "雷士國際",
    isHKConnect: 0,
  },
  {
    no: "2223",
    name_en: "CASABLANCA GROUP LIMITED",
    name_zh: "卡撒天嬌",
    isHKConnect: 0,
  },
  {
    no: "2225",
    name_en: "JINHAI INTERNATIONAL GROUP HOLDINGS LIMITED",
    name_zh: "今海國際",
    isHKConnect: 0,
  },
  {
    no: "2226",
    name_en: "HONWORLD GROUP LIMITED",
    name_zh: "老恆和釀造",
    isHKConnect: 0,
  },
  {
    no: "2227",
    name_en: "SOLIS HOLDINGS LIMITED",
    name_zh: "守益控股",
    isHKConnect: 0,
  },
  {
    no: "2230",
    name_en: "MEDIALINK GROUP LIMITED",
    name_zh: "羚邦集團",
    isHKConnect: 0,
  },
  {
    no: "2231",
    name_en: "JY GRANDMARK HOLDINGS LIMITED",
    name_zh: "景業名邦集團",
    isHKConnect: 0,
  },
  {
    no: "2232",
    name_en: "CRYSTAL INTERNATIONAL GROUP LIMITED",
    name_zh: "晶苑國際",
    isHKConnect: 1,
  },
  {
    no: "2233",
    name_en: "WEST CHINA CEMENT LIMITED",
    name_zh: "西部水泥",
    isHKConnect: 1,
  },
  {
    no: "2235",
    name_en: "MICROTECH MEDICAL (HANGZHOU) CO., LTD.",
    name_zh: "微泰醫療-B",
    isHKConnect: 0,
  },
  {
    no: "2236",
    name_en: "WISON ENGINEERING SERVICES CO. LTD.",
    name_zh: "惠生工程",
    isHKConnect: 0,
  },
  {
    no: "2237",
    name_en: "CHINA GRAPHITE GROUP LIMITED",
    name_zh: "中國石墨",
    isHKConnect: 0,
  },
  {
    no: "2238",
    name_en: "GUANGZHOU AUTOMOBILE GROUP CO., LTD.",
    name_zh: "廣汽集團",
    isHKConnect: 1,
  },
  {
    no: "2239",
    name_en: "SMIT HOLDINGS LIMITED",
    name_zh: "國微控股",
    isHKConnect: 0,
  },
  {
    no: "2246",
    name_en: "GOGOX HOLDINGS LIMITED",
    name_zh: "快狗打車",
    isHKConnect: 1,
  },
  {
    no: "2250",
    name_en: "SEMK HOLDINGS INTERNATIONAL LIMITED",
    name_zh: "德盈控股",
    isHKConnect: 0,
  },
  {
    no: "2251",
    name_en: "BEIJING AIRDOC TECHNOLOGY CO., LTD.",
    name_zh: "鷹瞳科技-B",
    isHKConnect: 0,
  },
  {
    no: "2252",
    name_en: "SHANGHAI MICROPORT MEDBOT (GROUP) CO., LTD.",
    name_zh: "微創機器人-B",
    isHKConnect: 1,
  },
  {
    no: "2255",
    name_en: "HAICHANG OCEAN PARK HOLDINGS LTD.",
    name_zh: "海昌海洋公園",
    isHKConnect: 1,
  },
  {
    no: "2256",
    name_en: "ABBISKO CAYMAN LIMITED",
    name_zh: "和譽-B",
    isHKConnect: 0,
  },
  {
    no: "2257",
    name_en: "SIRNAOMICS LTD.",
    name_zh: "聖諾醫藥-B",
    isHKConnect: 1,
  },
  {
    no: "2258",
    name_en: "WATTS INTERNATIONAL MARITIME COMPANY LIMITED",
    name_zh: "華滋國際海洋",
    isHKConnect: 0,
  },
  {
    no: "2260",
    name_en: "VANOV HOLDINGS COMPANY LIMITED",
    name_zh: "環龍控股",
    isHKConnect: 0,
  },
  {
    no: "2262",
    name_en: "STEVE LEUNG DESIGN GROUP LIMITED",
    name_zh: "梁志天設計集團",
    isHKConnect: 0,
  },
  {
    no: "2263",
    name_en: "FU SHEK FINANCIAL HOLDINGS LIMITED",
    name_zh: "富石金融",
    isHKConnect: 0,
  },
  {
    no: "2265",
    name_en: "HONGCHENG ENVIRONMENTAL TECHNOLOGY COMPANY LIMITED",
    name_zh: "鴻承環保科技",
    isHKConnect: 0,
  },
  {
    no: "2266",
    name_en: "LAI SI ENTERPRISE HOLDING LIMITED",
    name_zh: "黎氏企業",
    isHKConnect: 0,
  },
  {
    no: "2269",
    name_en: "WUXI BIOLOGICS (CAYMAN) INC.",
    name_zh: "藥明生物",
    isHKConnect: 1,
  },
  {
    no: "2270",
    name_en: "DESUN REAL ESTATE INVESTMENT SERVICES GROUP CO., LTD.",
    name_zh: "德商產投服務",
    isHKConnect: 0,
  },
  {
    no: "2273",
    name_en: "GUSHENGTANG HOLDINGS LIMITED",
    name_zh: "固生堂",
    isHKConnect: 1,
  },
  {
    no: "2276",
    name_en: "SHANGHAI CONANT OPTICAL CO., LTD.",
    name_zh: "康耐特光學",
    isHKConnect: 0,
  },
  {
    no: "2278",
    name_en: "HAILAN HOLDINGS LIMITED",
    name_zh: "海藍控股",
    isHKConnect: 0,
  },
  {
    no: "2279",
    name_en: "YONGHE MEDICAL GROUP CO., LTD.",
    name_zh: "雍禾醫療",
    isHKConnect: 1,
  },
  { no: "2280", name_en: "HC GROUP INC.", name_zh: "慧聰集團", isHKConnect: 1 },
  {
    no: "2281",
    name_en: "LUZHOU XINGLU WATER (GROUP) CO., LTD.",
    name_zh: "興瀘水務",
    isHKConnect: 0,
  },
  {
    no: "2282",
    name_en: "MGM CHINA HOLDINGS LIMITED",
    name_zh: "美高梅中國",
    isHKConnect: 1,
  },
  {
    no: "2283",
    name_en: "TK GROUP (HOLDINGS) LIMITED",
    name_zh: "東江集團控股",
    isHKConnect: 0,
  },
  {
    no: "2285",
    name_en: "CHERVON HOLDINGS LIMITED",
    name_zh: "泉峰控股",
    isHKConnect: 1,
  },
  {
    no: "2286",
    name_en: "CHEN XING DEVELOPMENT HOLDINGS LIMITED",
    name_zh: "辰興發展",
    isHKConnect: 0,
  },
  {
    no: "2288",
    name_en: "RYKADAN CAPITAL LIMITED",
    name_zh: "宏基資本",
    isHKConnect: 0,
  },
  {
    no: "2289",
    name_en: "CHARMACY PHARMACEUTICAL CO., LTD.",
    name_zh: "創美藥業",
    isHKConnect: 0,
  },
  {
    no: "2292",
    name_en: "THING ON ENTERPRISE LIMITED",
    name_zh: "晉安實業",
    isHKConnect: 0,
  },
  {
    no: "2293",
    name_en: "BAMBOOS HEALTH CARE HOLDINGS LIMITED",
    name_zh: "百本醫護",
    isHKConnect: 0,
  },
  {
    no: "2295",
    name_en: "MAXICITY HOLDINGS LIMITED",
    name_zh: "豐城控股",
    isHKConnect: 0,
  },
  {
    no: "2296",
    name_en: "HUARCHI GLOBAL GROUP HOLDINGS LIMITED",
    name_zh: "華記環球集團",
    isHKConnect: 0,
  },
  {
    no: "2297",
    name_en: "RAINMED MEDICAL LIMITED",
    name_zh: "潤邁德-B",
    isHKConnect: 0,
  },
  {
    no: "2298",
    name_en: "COSMO LADY (CHINA) HOLDINGS COMPANY LIMITED",
    name_zh: "都市麗人",
    isHKConnect: 1,
  },
  {
    no: "2299",
    name_en: "BILLION INDUSTRIAL HOLDINGS LIMITED",
    name_zh: "百宏實業",
    isHKConnect: 0,
  },
  {
    no: "2302",
    name_en: "CNNC INTERNATIONAL LIMITED",
    name_zh: "中核國際",
    isHKConnect: 0,
  },
  {
    no: "2307",
    name_en: "KAM HING INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "錦興國際控股",
    isHKConnect: 0,
  },
  {
    no: "2309",
    name_en: "BIRMINGHAM SPORTS HOLDINGS LIMITED",
    name_zh: "伯明翰體育",
    isHKConnect: 0,
  },
  {
    no: "2310",
    name_en: "TIMES UNIVERSAL GROUP HOLDINGS LIMITED",
    name_zh: "時代環球集團",
    isHKConnect: 0,
  },
  {
    no: "2312",
    name_en: "CHINA FINANCIAL LEASING GROUP LIMITED",
    name_zh: "中國金融租賃",
    isHKConnect: 0,
  },
  {
    no: "2313",
    name_en: "SHENZHOU INTERNATIONAL GROUP HOLDINGS LIMITED",
    name_zh: "申洲國際",
    isHKConnect: 1,
  },
  {
    no: "2314",
    name_en: "LEE & MAN PAPER MANUFACTURING LIMITED",
    name_zh: "理文造紙",
    isHKConnect: 1,
  },
  {
    no: "2315",
    name_en: "BIOCYTOGEN PHARMACEUTICALS (BEIJING) CO., LTD.",
    name_zh: "百奧賽圖-B",
    isHKConnect: 0,
  },
  {
    no: "2317",
    name_en: "VEDAN INTERNATIONAL (HOLDINGS) LIMITED",
    name_zh: "味丹國際",
    isHKConnect: 0,
  },
  {
    no: "2318",
    name_en: "PING AN INSURANCE (GROUP) COMPANY OF CHINA, LTD.",
    name_zh: "中國平安",
    isHKConnect: 1,
  },
  {
    no: "2319",
    name_en: "CHINA MENGNIU DAIRY COMPANY LIMITED",
    name_zh: "蒙牛乳業",
    isHKConnect: 1,
  },
  {
    no: "2320",
    name_en: "HOP FUNG GROUP HOLDINGS LIMITED",
    name_zh: "合豐集團",
    isHKConnect: 0,
  },
  {
    no: "2321",
    name_en: "SWANG CHAI CHUAN LIMITED",
    name_zh: "雙財莊",
    isHKConnect: 0,
  },
  {
    no: "2322",
    name_en: "HONG KONG CHAOSHANG GROUP LIMITED",
    name_zh: "香港潮商集團",
    isHKConnect: 0,
  },
  {
    no: "2323",
    name_en: "RENCO HOLDINGS GROUP LIMITED",
    name_zh: "融科控股",
    isHKConnect: 0,
  },
  {
    no: "2324",
    name_en: "CAPITAL VC LIMITED",
    name_zh: "首都創投",
    isHKConnect: 0,
  },
  {
    no: "2325",
    name_en: "YUNKANG GROUP LIMITED",
    name_zh: "雲康集團",
    isHKConnect: 0,
  },
  {
    no: "2326",
    name_en: "NEW PROVENANCE EVERLASTING HOLDINGS LIMITED",
    name_zh: "新源萬恆控股",
    isHKConnect: 1,
  },
  {
    no: "2327",
    name_en: "MEILLEURE HEALTH INTERNATIONAL INDUSTRY GROUP LIMITED",
    name_zh: "美瑞健康國際",
    isHKConnect: 0,
  },
  {
    no: "2328",
    name_en: "PICC PROPERTY AND CASUALTY COMPANY LIMITED",
    name_zh: "中國財險",
    isHKConnect: 1,
  },
  {
    no: "2329",
    name_en: "GLORY HEALTH INDUSTRY LIMITED",
    name_zh: "國瑞健康",
    isHKConnect: 1,
  },
  {
    no: "2330",
    name_en: "CHINA UPTOWN GROUP COMPANY LIMITED",
    name_zh: "中國上城",
    isHKConnect: 0,
  },
  {
    no: "2331",
    name_en: "LI NING COMPANY LIMITED",
    name_zh: "李寧",
    isHKConnect: 1,
  },
  {
    no: "2333",
    name_en: "GREAT WALL MOTOR COMPANY LIMITED",
    name_zh: "長城汽車",
    isHKConnect: 1,
  },
  {
    no: "2336",
    name_en: "HAILIANG INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "海亮國際",
    isHKConnect: 0,
  },
  {
    no: "2337",
    name_en: "UNITED STRENGTH POWER HOLDINGS LIMITED",
    name_zh: "眾誠能源",
    isHKConnect: 0,
  },
  {
    no: "2338",
    name_en: "WEICHAI POWER CO., LTD.",
    name_zh: "濰柴動力",
    isHKConnect: 1,
  },
  {
    no: "2339",
    name_en: "BEIJINGWEST INDUSTRIES INTERNATIONAL LIMITED",
    name_zh: "京西國際",
    isHKConnect: 0,
  },
  {
    no: "2340",
    name_en: "ISP HOLDINGS LIMITED",
    name_zh: "ISP HOLDINGS",
    isHKConnect: 0,
  },
  {
    no: "2341",
    name_en: "ECOGREEN INTERNATIONAL GROUP LIMITED",
    name_zh: "中怡國際",
    isHKConnect: 0,
  },
  {
    no: "2342",
    name_en: "COMBA TELECOM SYSTEMS HOLDINGS LIMITED",
    name_zh: "京信通信",
    isHKConnect: 1,
  },
  {
    no: "2343",
    name_en: "PACIFIC BASIN SHIPPING LIMITED",
    name_zh: "太平洋航運",
    isHKConnect: 1,
  },
  {
    no: "2346",
    name_en: "UNIVERSAL STAR (HOLDINGS) LIMITED",
    name_zh: "星宇控股",
    isHKConnect: 0,
  },
  {
    no: "2347",
    name_en: "YOHO GROUP HOLDINGS LIMITED",
    name_zh: "友和集團",
    isHKConnect: 0,
  },
  {
    no: "2348",
    name_en: "DAWNRAYS PHARMACEUTICAL (HOLDINGS) LIMITED",
    name_zh: "東瑞製葯",
    isHKConnect: 0,
  },
  {
    no: "2349",
    name_en: "CHINA CITY INFRASTRUCTURE GROUP LIMITED",
    name_zh: "中國城市基礎設施",
    isHKConnect: 0,
  },
  {
    no: "2350",
    name_en: "MTT GROUP HOLDINGS LIMITED",
    name_zh: "數科集團",
    isHKConnect: 0,
  },
  {
    no: "2352",
    name_en: "DOWELL SERVICE GROUP CO. LIMITED",
    name_zh: "東原仁知服務",
    isHKConnect: 0,
  },
  {
    no: "2355",
    name_en: "BAOYE GROUP COMPANY LIMITED",
    name_zh: "寶業集團",
    isHKConnect: 0,
  },
  {
    no: "2356",
    name_en: "DAH SING BANKING GROUP LIMITED",
    name_zh: "大新銀行集團",
    isHKConnect: 1,
  },
  {
    no: "2357",
    name_en: "AVICHINA INDUSTRY & TECHNOLOGY COMPANY LIMITED",
    name_zh: "中航科工",
    isHKConnect: 1,
  },
  {
    no: "2358",
    name_en: "JIU RONG HOLDINGS LIMITED",
    name_zh: "久融控股",
    isHKConnect: 0,
  },
  {
    no: "2359",
    name_en: "WUXI APPTEC CO., LTD.",
    name_zh: "藥明康德",
    isHKConnect: 1,
  },
  {
    no: "2360",
    name_en: "BEST MART 360 HOLDINGS LIMITED",
    name_zh: "優品360",
    isHKConnect: 0,
  },
  {
    no: "2361",
    name_en: "SINOHEALTH HOLDINGS LIMITED",
    name_zh: "中康控股",
    isHKConnect: 0,
  },
  {
    no: "2362",
    name_en: "JINCHUAN GROUP INTERNATIONAL RESOURCES CO. LTD.",
    name_zh: "金川國際",
    isHKConnect: 1,
  },
  {
    no: "2363",
    name_en: "TONGDA HONG TAI HOLDINGS LIMITED",
    name_zh: "通達宏泰",
    isHKConnect: 1,
  },
  {
    no: "2368",
    name_en: "EAGLE NICE (INTERNATIONAL) HOLDINGS LIMITED",
    name_zh: "鷹美",
    isHKConnect: 0,
  },
  {
    no: "2369",
    name_en: "COOLPAD GROUP LIMITED",
    name_zh: "酷派集團",
    isHKConnect: 1,
  },
  {
    no: "2370",
    name_en: "REDCO HEALTHY LIVING COMPANY LIMITED",
    name_zh: "力高健康生活",
    isHKConnect: 0,
  },
  {
    no: "2371",
    name_en: "CHUANGLIAN HOLDINGS LIMITED",
    name_zh: "創聯控股",
    isHKConnect: 0,
  },
  {
    no: "2372",
    name_en: "WEILI HOLDINGS LIMITED",
    name_zh: "偉立控股",
    isHKConnect: 0,
  },
  {
    no: "2376",
    name_en: "LUSHANG LIFE SERVICES CO., LTD.",
    name_zh: "魯商服務",
    isHKConnect: 0,
  },
  {
    no: "2377",
    name_en: "CHINA BOQI ENVIRONMENTAL (HOLDING) CO., LTD.",
    name_zh: "博奇環保",
    isHKConnect: 0,
  },
  { no: "2378", name_en: "PRUDENTIAL PLC", name_zh: "保誠", isHKConnect: 1 },
  {
    no: "2379",
    name_en: "CHINA CLEAN ENERGY TECHNOLOGY GROUP LIMITED",
    name_zh: "中國清潔能源科技",
    isHKConnect: 0,
  },
  {
    no: "2380",
    name_en: "CHINA POWER INTERNATIONAL DEVELOPMENT LIMITED",
    name_zh: "中國電力",
    isHKConnect: 1,
  },
  {
    no: "2381",
    name_en: "SMC ELECTRIC LIMITED",
    name_zh: "蜆殼電業",
    isHKConnect: 0,
  },
  {
    no: "2382",
    name_en: "SUNNY OPTICAL TECHNOLOGY (GROUP) COMPANY LIMITED",
    name_zh: "舜宇光學科技",
    isHKConnect: 1,
  },
  {
    no: "2383",
    name_en: "TOM GROUP LIMITED",
    name_zh: "TOM集團",
    isHKConnect: 0,
  },
  {
    no: "2385",
    name_en: "READBOY EDUCATION HOLDING COMPANY LIMITED",
    name_zh: "讀書郎",
    isHKConnect: 0,
  },
  {
    no: "2386",
    name_en: "SINOPEC ENGINEERING (GROUP) CO., LTD.",
    name_zh: "中石化煉化工程",
    isHKConnect: 1,
  },
  {
    no: "2388",
    name_en: "BOC HONG KONG (HOLDINGS) LIMITED",
    name_zh: "中銀香港",
    isHKConnect: 1,
  },
  {
    no: "2389",
    name_en: "BEIJING HEALTH (HOLDINGS) LIMITED",
    name_zh: "北京健康",
    isHKConnect: 0,
  },
  { no: "2390", name_en: "ZHIHU INC.", name_zh: "知乎-W", isHKConnect: 0 },
  { no: "2391", name_en: "TUYA INC.", name_zh: "塗鴉智能-W", isHKConnect: 0 },
  {
    no: "2392",
    name_en: "XUAN WU CLOUD TECHNOLOGY HOLDINGS LIMITED",
    name_zh: "玄武雲",
    isHKConnect: 0,
  },
  {
    no: "2393",
    name_en: "YESTAR HEALTHCARE HOLDINGS COMPANY LIMITED",
    name_zh: "巨星醫療控股",
    isHKConnect: 0,
  },
  {
    no: "2399",
    name_en: "CHINA ANCHU ENERGY STORAGE GROUP LIMITED",
    name_zh: "中國安儲能源",
    isHKConnect: 0,
  },
  { no: "2400", name_en: "XD INC.", name_zh: "心動公司", isHKConnect: 1 },
  {
    no: "2418",
    name_en: "DEEWIN TIANXIA CO., LTD",
    name_zh: "德銀天下",
    isHKConnect: 0,
  },
  {
    no: "2422",
    name_en: "REGO INTERACTIVE CO., LTD",
    name_zh: "潤歌互動",
    isHKConnect: 0,
  },
  {
    no: "2423",
    name_en: "KE HOLDINGS INC.",
    name_zh: "貝殼-W",
    isHKConnect: 0,
  },
  {
    no: "2448",
    name_en: "SPACE GROUP HOLDINGS LIMITED",
    name_zh: "恆宇集團",
    isHKConnect: 0,
  },
  {
    no: "2488",
    name_en: "LAUNCH TECH COMPANY LIMITED",
    name_zh: "元征科技",
    isHKConnect: 0,
  },
  {
    no: "2500",
    name_en: "VENUS MEDTECH (HANGZHOU) INC.",
    name_zh: "啟明醫療-B",
    isHKConnect: 1,
  },
  {
    no: "2518",
    name_en: "AUTOHOME INC.",
    name_zh: "汽車之家-S",
    isHKConnect: 0,
  },
  {
    no: "2528",
    name_en: "FORWARD FASHION (INTERNATIONAL) HOLDINGS COMPANY LIMITED",
    name_zh: "尚晉國際控股",
    isHKConnect: 0,
  },
  {
    no: "2552",
    name_en: "HUA MEDICINE",
    name_zh: "華領醫藥-B",
    isHKConnect: 0,
  },
  {
    no: "2558",
    name_en: "JINSHANG BANK CO., LTD.",
    name_zh: "晉商銀行",
    isHKConnect: 0,
  },
  {
    no: "2588",
    name_en: "BOC AVIATION LIMITED",
    name_zh: "中銀航空租賃",
    isHKConnect: 1,
  },
  {
    no: "2599",
    name_en: "SHINSUN HOLDINGS (GROUP) CO., LTD.",
    name_zh: "祥生控股集團",
    isHKConnect: 1,
  },
  {
    no: "2600",
    name_en: "ALUMINUM CORPORATION OF CHINA LIMITED",
    name_zh: "中國鋁業",
    isHKConnect: 1,
  },
  {
    no: "2601",
    name_en: "CHINA PACIFIC INSURANCE (GROUP) CO. LTD.",
    name_zh: "中國太保",
    isHKConnect: 1,
  },
  { no: "2602", name_en: "ONEWO INC.", name_zh: "萬物雲", isHKConnect: 0 },
  {
    no: "2607",
    name_en: "SHANGHAI PHARMACEUTICALS HOLDING CO., LTD.",
    name_zh: "上海醫藥",
    isHKConnect: 1,
  },
  {
    no: "2608",
    name_en: "SUNSHINE 100 CHINA HOLDINGS LTD",
    name_zh: "陽光100中國",
    isHKConnect: 0,
  },
  {
    no: "2611",
    name_en: "GUOTAI JUNAN SECURITIES CO., LTD.",
    name_zh: "國泰君安",
    isHKConnect: 1,
  },
  {
    no: "2616",
    name_en: "CSTONE PHARMACEUTICALS",
    name_zh: "基石藥業-B",
    isHKConnect: 1,
  },
  {
    no: "2618",
    name_en: "JD LOGISTICS, INC.",
    name_zh: "京東物流",
    isHKConnect: 1,
  },
  {
    no: "2623",
    name_en: "ADD NEW ENERGY INVESTMENT HOLDINGS GROUP LIMITED",
    name_zh: "愛德新能源",
    isHKConnect: 0,
  },
  {
    no: "2628",
    name_en: "CHINA LIFE INSURANCE COMPANY LIMITED",
    name_zh: "中國人壽",
    isHKConnect: 1,
  },
  {
    no: "2633",
    name_en: "JACOBSON PHARMA CORPORATION LIMITED",
    name_zh: "雅各臣科研製藥",
    isHKConnect: 0,
  },
  {
    no: "2638",
    name_en: "HK ELECTRIC INVESTMENTS AND HK ELECTRIC INVESTMENTS LIMITED",
    name_zh: "港燈-SS",
    isHKConnect: 0,
  },
  {
    no: "2660",
    name_en: "ZENGAME TECHNOLOGY HOLDING LIMITED",
    name_zh: "禪遊科技",
    isHKConnect: 0,
  },
  {
    no: "2663",
    name_en: "KPA-BM HOLDINGS LIMITED",
    name_zh: "應力控股",
    isHKConnect: 0,
  },
  {
    no: "2666",
    name_en: "GENERTEC UNIVERSAL MEDICAL GROUP COMPANY LIMITED",
    name_zh: "環球醫療",
    isHKConnect: 1,
  },
  {
    no: "2668",
    name_en: "PAK TAK INTERNATIONAL LIMITED",
    name_zh: "百德國際",
    isHKConnect: 0,
  },
  {
    no: "2669",
    name_en: "CHINA OVERSEAS PROPERTY HOLDINGS LIMITED",
    name_zh: "中海物業",
    isHKConnect: 1,
  },
  {
    no: "2678",
    name_en: "TEXHONG TEXTILE GROUP LIMITED",
    name_zh: "天虹紡織",
    isHKConnect: 1,
  },
  {
    no: "2680",
    name_en: "INNOVAX HOLDINGS LIMITED",
    name_zh: "INNOVAX HOLDINGS LIMITED",
    isHKConnect: 0,
  },
  {
    no: "2682",
    name_en: "YUN LEE MARINE GROUP HOLDINGS LIMITED",
    name_zh: "潤利海事",
    isHKConnect: 0,
  },
  {
    no: "2683",
    name_en: "WAH SUN HANDBAGS INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "華新手袋國際控股",
    isHKConnect: 0,
  },
  {
    no: "2686",
    name_en: "AAG ENERGY HOLDINGS LIMITED",
    name_zh: "亞美能源",
    isHKConnect: 0,
  },
  {
    no: "2688",
    name_en: "ENN ENERGY HOLDINGS LIMITED",
    name_zh: "新奧能源",
    isHKConnect: 1,
  },
  {
    no: "2689",
    name_en: "NINE DRAGONS PAPER (HOLDINGS) LIMITED",
    name_zh: "玖龍紙業",
    isHKConnect: 1,
  },
  {
    no: "2696",
    name_en: "SHANGHAI HENLIUS BIOTECH, INC.",
    name_zh: "復宏漢霖",
    isHKConnect: 0,
  },
  {
    no: "2698",
    name_en: "WEIQIAO TEXTILE COMPANY LIMITED",
    name_zh: "魏橋紡織",
    isHKConnect: 0,
  },
  {
    no: "2699",
    name_en: "XINMING CHINA HOLDINGS LIMITED",
    name_zh: "新明中國",
    isHKConnect: 0,
  },
  {
    no: "2700",
    name_en: "GREEN INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "格林國際控股",
    isHKConnect: 0,
  },
  {
    no: "2708",
    name_en: "IBO TECHNOLOGY COMPANY LIMITED",
    name_zh: "艾伯科技",
    isHKConnect: 0,
  },
  {
    no: "2718",
    name_en: "SHANGHAI DONGZHENG AUTOMOTIVE FINANCE CO., LTD.",
    name_zh: "東正金融",
    isHKConnect: 0,
  },
  {
    no: "2722",
    name_en: "CHONGQING MACHINERY & ELECTRIC CO., LTD.",
    name_zh: "重慶機電",
    isHKConnect: 0,
  },
  {
    no: "2727",
    name_en: "SHANGHAI ELECTRIC GROUP COMPANY LIMITED",
    name_zh: "上海電氣",
    isHKConnect: 1,
  },
  {
    no: "2728",
    name_en: "JINTAI ENERGY HOLDINGS LIMITED",
    name_zh: "金泰能源控股",
    isHKConnect: 0,
  },
  {
    no: "2738",
    name_en: "HUAJIN INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "華津國際控股",
    isHKConnect: 0,
  },
  {
    no: "2768",
    name_en: "JIAYUAN INTERNATIONAL GROUP LIMITED",
    name_zh: "佳源國際控股",
    isHKConnect: 1,
  },
  {
    no: "2772",
    name_en: "ZHONGLIANG HOLDINGS GROUP COMPANY LIMITED",
    name_zh: "中梁控股",
    isHKConnect: 1,
  },
  {
    no: "2777",
    name_en: "GUANGZHOU R&F PROPERTIES CO., LTD.",
    name_zh: "富力地產",
    isHKConnect: 1,
  },
  {
    no: "2778",
    name_en: "CHAMPION REAL ESTATE INVESTMENT TRUST",
    name_zh: "冠君產業信託",
    isHKConnect: 0,
  },
  {
    no: "2779",
    name_en: "CHINA XINHUA EDUCATION GROUP LIMITED",
    name_zh: "中國新華教育",
    isHKConnect: 0,
  },
  {
    no: "2789",
    name_en: "YUANDA CHINA HOLDINGS LIMITED",
    name_zh: "遠大中國",
    isHKConnect: 0,
  },
  {
    no: "2798",
    name_en: "PERENNIAL ENERGY HOLDINGS LIMITED",
    name_zh: "久泰邦達能源",
    isHKConnect: 1,
  },
  {
    no: "2799",
    name_en: "CHINA HUARONG ASSET MANAGEMENT CO., LTD.",
    name_zh: "中國華融",
    isHKConnect: 1,
  },
  {
    no: "2800",
    name_en: "TRACKER FUND OF HONG KONG -HKD TRADED UNITS",
    name_zh: "盈富基金",
    isHKConnect: 1,
  },
  {
    no: "2828",
    name_en: "HANG SENG CHINA ENTERPRISES INDEX ETF -HKD TRADED UNITS",
    name_zh: "恆生中國企業",
    isHKConnect: 1,
  },
  {
    no: "2858",
    name_en: "YIXIN GROUP LIMITED",
    name_zh: "易鑫集團",
    isHKConnect: 1,
  },
  {
    no: "2863",
    name_en: "GOLDEN FAITH GROUP HOLDINGS LIMITED",
    name_zh: "高豐集團控股",
    isHKConnect: 0,
  },
  {
    no: "2866",
    name_en: "COSCO SHIPPING DEVELOPMENT CO., LTD.",
    name_zh: "中遠海發",
    isHKConnect: 1,
  },
  {
    no: "2869",
    name_en: "GREENTOWN SERVICE GROUP CO. LTD.",
    name_zh: "綠城服務",
    isHKConnect: 1,
  },
  {
    no: "2877",
    name_en: "CHINA SHINEWAY PHARMACEUTICAL GROUP LIMITED",
    name_zh: "神威藥業",
    isHKConnect: 1,
  },
  {
    no: "2878",
    name_en: "SOLOMON SYSTECH (INTERNATIONAL) LIMITED",
    name_zh: "晶門半導體",
    isHKConnect: 0,
  },
  {
    no: "2880",
    name_en: "LIAONING PORT CO., LTD.",
    name_zh: "遼港股份",
    isHKConnect: 1,
  },
  {
    no: "2882",
    name_en: "HONG KONG RESOURCES HOLDINGS COMPANY LIMITED",
    name_zh: "香港資源控股",
    isHKConnect: 0,
  },
  {
    no: "2883",
    name_en: "CHINA OILFIELD SERVICES LIMITED",
    name_zh: "中海油田服務",
    isHKConnect: 1,
  },
  {
    no: "2885",
    name_en: "PEIPORT HOLDINGS LTD.",
    name_zh: "彼岸控股",
    isHKConnect: 0,
  },
  {
    no: "2886",
    name_en: "BINHAI INVESTMENT COMPANY LIMITED",
    name_zh: "濱海投資",
    isHKConnect: 0,
  },
  {
    no: "2888",
    name_en: "STANDARD CHARTERED PLC",
    name_zh: "渣打集團",
    isHKConnect: 1,
  },
  {
    no: "2892",
    name_en: "MILLION CITIES HOLDINGS LIMITED",
    name_zh: "萬城控股",
    isHKConnect: 0,
  },
  {
    no: "2899",
    name_en: "ZIJIN MINING GROUP CO., LTD.",
    name_zh: "紫金礦業",
    isHKConnect: 1,
  },
  {
    no: "3300",
    name_en: "CHINA GLASS HOLDINGS LIMITED",
    name_zh: "中國玻璃",
    isHKConnect: 0,
  },
  {
    no: "3301",
    name_en: "RONSHINE CHINA HOLDINGS LIMITED",
    name_zh: "融信中國",
    isHKConnect: 1,
  },
  {
    no: "3302",
    name_en: "KINERGY CORPORATION LTD.",
    name_zh: "光控精技",
    isHKConnect: 0,
  },
  {
    no: "3303",
    name_en: "JUTAL OFFSHORE OIL SERVICES LIMITED",
    name_zh: "巨濤海洋石油服務",
    isHKConnect: 0,
  },
  {
    no: "3306",
    name_en: "JNBY DESIGN LIMITED",
    name_zh: "江南布衣",
    isHKConnect: 1,
  },
  {
    no: "3308",
    name_en: "GOLDEN EAGLE RETAIL GROUP LIMITED",
    name_zh: "金鷹商貿集團",
    isHKConnect: 1,
  },
  {
    no: "3309",
    name_en: "C-MER EYE CARE HOLDINGS LIMITED",
    name_zh: "希瑪眼科",
    isHKConnect: 1,
  },
  {
    no: "3311",
    name_en: "CHINA STATE CONSTRUCTION INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "中國建築國際",
    isHKConnect: 1,
  },
  {
    no: "3313",
    name_en: "ARTGO HOLDINGS LIMITED",
    name_zh: "雅高控股",
    isHKConnect: 0,
  },
  {
    no: "3315",
    name_en: "GOLDPAC GROUP LIMITED",
    name_zh: "GOLDPAC GROUP LIMITED",
    isHKConnect: 0,
  },
  {
    no: "3316",
    name_en: "BINJIANG SERVICE GROUP CO. LTD.",
    name_zh: "濱江服務",
    isHKConnect: 0,
  },
  {
    no: "3318",
    name_en: "CHINA BOTON GROUP COMPANY LIMITED",
    name_zh: "中國波頓",
    isHKConnect: 0,
  },
  {
    no: "3319",
    name_en: "A-LIVING SMART CITY SERVICES CO., LTD.",
    name_zh: "雅生活服務",
    isHKConnect: 1,
  },
  {
    no: "3320",
    name_en: "CHINA RESOURCES PHARMACEUTICAL GROUP LIMITED",
    name_zh: "華潤醫藥",
    isHKConnect: 1,
  },
  {
    no: "3321",
    name_en: "WAI HUNG GROUP HOLDINGS LIMITED",
    name_zh: "偉鴻集團控股",
    isHKConnect: 0,
  },
  {
    no: "3322",
    name_en: "WIN HANVERKY HOLDINGS LIMITED",
    name_zh: "永嘉集團",
    isHKConnect: 0,
  },
  {
    no: "3323",
    name_en: "CHINA NATIONAL BUILDING MATERIAL COMPANY LIMITED",
    name_zh: "中國建材",
    isHKConnect: 1,
  },
  {
    no: "3326",
    name_en: "PERFECT GROUP INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "保發集團",
    isHKConnect: 0,
  },
  {
    no: "3328",
    name_en: "BANK OF COMMUNICATIONS CO., LTD.",
    name_zh: "交通銀行",
    isHKConnect: 1,
  },
  {
    no: "3329",
    name_en: "BOCOM INTERNATIONAL HOLDINGS COMPANY LIMITED",
    name_zh: "交銀國際",
    isHKConnect: 1,
  },
  {
    no: "3330",
    name_en: "LINGBAO GOLD GROUP COMPANY LTD.",
    name_zh: "靈寶黃金",
    isHKConnect: 0,
  },
  {
    no: "3331",
    name_en: "VINDA INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "維達國際",
    isHKConnect: 1,
  },
  {
    no: "3332",
    name_en: "NANJING SINOLIFE UNITED COMPANY LIMITED",
    name_zh: "中生聯合",
    isHKConnect: 0,
  },
  {
    no: "3333",
    name_en: "CHINA EVERGRANDE GROUP",
    name_zh: "中國恆大",
    isHKConnect: 1,
  },
  {
    no: "3336",
    name_en: "JU TENG INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "巨騰國際",
    isHKConnect: 0,
  },
  {
    no: "3337",
    name_en: "ANTON OILFIELD SERVICES GROUP",
    name_zh: "安東油田服務",
    isHKConnect: 0,
  },
  {
    no: "3339",
    name_en: "LONKING HOLDINGS LIMITED",
    name_zh: "中國龍工",
    isHKConnect: 1,
  },
  {
    no: "3344",
    name_en: "GTI HOLDINGS LIMITED",
    name_zh: "共享集團",
    isHKConnect: 0,
  },
  {
    no: "3347",
    name_en: "HANGZHOU TIGERMED CONSULTING CO., LTD.",
    name_zh: "泰格醫藥",
    isHKConnect: 1,
  },
  {
    no: "3348",
    name_en: "CHINA PENGFEI GROUP LIMITED",
    name_zh: "中國鵬飛集團",
    isHKConnect: 0,
  },
  {
    no: "3360",
    name_en: "FAR EAST HORIZON LIMITED",
    name_zh: "遠東宏信",
    isHKConnect: 1,
  },
  {
    no: "3363",
    name_en: "ZHENGYE INTERNATIONAL HOLDINGS COMPANY LIMITED",
    name_zh: "正業國際",
    isHKConnect: 0,
  },
  {
    no: "3366",
    name_en: "OVERSEAS CHINESE TOWN (ASIA) HOLDINGS LIMITED",
    name_zh: "華僑城(亞洲)",
    isHKConnect: 0,
  },
  {
    no: "3368",
    name_en: "PARKSON RETAIL GROUP LIMITED",
    name_zh: "百盛集團",
    isHKConnect: 1,
  },
  {
    no: "3369",
    name_en: "QINHUANGDAO PORT CO., LTD.",
    name_zh: "秦港股份",
    isHKConnect: 1,
  },
  {
    no: "3377",
    name_en: "SINO-OCEAN GROUP HOLDING LIMITED",
    name_zh: "遠洋集團",
    isHKConnect: 1,
  },
  {
    no: "3380",
    name_en: "LOGAN GROUP COMPANY LIMITED",
    name_zh: "龍光集團",
    isHKConnect: 1,
  },
  {
    no: "3382",
    name_en: "TIANJIN PORT DEVELOPMENT HOLDINGS LIMITED",
    name_zh: "天津港發展",
    isHKConnect: 1,
  },
  {
    no: "3383",
    name_en: "AGILE GROUP HOLDINGS LIMITED",
    name_zh: "雅居樂集團",
    isHKConnect: 1,
  },
  {
    no: "3389",
    name_en: "HENGDELI HOLDINGS LIMITED",
    name_zh: "亨得利",
    isHKConnect: 1,
  },
  {
    no: "3390",
    name_en: "TYCOON GROUP HOLDINGS LIMITED",
    name_zh: "滿貫集團",
    isHKConnect: 0,
  },
  {
    no: "3393",
    name_en: "WASION HOLDINGS LIMITED",
    name_zh: "威勝控股",
    isHKConnect: 1,
  },
  {
    no: "3395",
    name_en: "JX ENERGY LTD.",
    name_zh: "吉星新能源",
    isHKConnect: 0,
  },
  {
    no: "3396",
    name_en: "LEGEND HOLDINGS CORPORATION",
    name_zh: "聯想控股",
    isHKConnect: 1,
  },
  {
    no: "3398",
    name_en: "CHINA TING GROUP HOLDINGS LIMITED",
    name_zh: "華鼎控股",
    isHKConnect: 0,
  },
  {
    no: "3399",
    name_en: "GUANGDONG YUEYUN TRANSPORTATION COMPANY LIMITED",
    name_zh: "粵運交通",
    isHKConnect: 0,
  },
  {
    no: "3600",
    name_en: "MODERN DENTAL GROUP LIMITED",
    name_zh: "現代牙科",
    isHKConnect: 0,
  },
  {
    no: "3601",
    name_en: "360 LUDASHI HOLDINGS LIMITED",
    name_zh: "魯大師",
    isHKConnect: 0,
  },
  {
    no: "3603",
    name_en: "XINJI SHAXI GROUP CO., LTD",
    name_zh: "信基沙溪",
    isHKConnect: 0,
  },
  {
    no: "3606",
    name_en: "FUYAO GLASS INDUSTRY GROUP CO., LTD",
    name_zh: "福耀玻璃",
    isHKConnect: 1,
  },
  {
    no: "3608",
    name_en: "YONGSHENG ADVANCED MATERIALS COMPANY LIMITED",
    name_zh: "永盛新材料",
    isHKConnect: 0,
  },
  {
    no: "3613",
    name_en: "BEIJING TONG REN TANG CHINESE MEDICINE COMPANY LIMITED",
    name_zh: "同仁堂國藥",
    isHKConnect: 1,
  },
  {
    no: "3616",
    name_en: "EVER REACH GROUP (HOLDINGS) COMPANY LIMITED",
    name_zh: "恆達集團控股",
    isHKConnect: 0,
  },
  {
    no: "3618",
    name_en: "CHONGQING RURAL COMMERCIAL BANK CO., LTD.",
    name_zh: "重慶農村商業銀行",
    isHKConnect: 1,
  },
  {
    no: "3623",
    name_en: "CHINA SUCCESS FINANCE GROUP HOLDINGS LIMITED",
    name_zh: "中國金融發展",
    isHKConnect: 0,
  },
  {
    no: "3626",
    name_en: "HANG SANG (SIU PO) INTERNATIONAL HOLDING COMPANY LIMITED",
    name_zh: "HANG SANG (SIU PO) INTERNATIONAL HOLDING COMPANY LIMITED",
    isHKConnect: 0,
  },
  {
    no: "3628",
    name_en: "RENHENG ENTERPRISE HOLDINGS LIMITED",
    name_zh: "仁恆實業控股",
    isHKConnect: 0,
  },
  {
    no: "3633",
    name_en: "ZHONGYU ENERGY HOLDINGS LIMITED",
    name_zh: "中裕能源",
    isHKConnect: 1,
  },
  {
    no: "3636",
    name_en: "POLY CULTURE GROUP CORPORATION LIMITED",
    name_zh: "保利文化",
    isHKConnect: 0,
  },
  {
    no: "3638",
    name_en: "HUABANG TECHNOLOGY HOLDINGS LIMITED",
    name_zh: "華邦科技",
    isHKConnect: 0,
  },
  {
    no: "3639",
    name_en: "YIDA CHINA HOLDINGS LIMITED",
    name_zh: "億達中國",
    isHKConnect: 0,
  },
  {
    no: "3658",
    name_en: "NEW HOPE SERVICE HOLDINGS LIMITED",
    name_zh: "新希望服務",
    isHKConnect: 0,
  },
  {
    no: "3662",
    name_en: "AOYUAN HEALTHY LIFE GROUP COMPANY LIMITED",
    name_zh: "奧園健康",
    isHKConnect: 0,
  },
  {
    no: "3666",
    name_en: "SHANGHAI XNG HOLDINGS LIMITED",
    name_zh: "上海小南國",
    isHKConnect: 0,
  },
  {
    no: "3668",
    name_en: "YANCOAL AUSTRALIA LTD",
    name_zh: "兗煤澳大利亞",
    isHKConnect: 0,
  },
  {
    no: "3669",
    name_en: "CHINA YONGDA AUTOMOBILES SERVICES HOLDINGS LIMITED",
    name_zh: "永達汽車",
    isHKConnect: 1,
  },
  {
    no: "3678",
    name_en: "HOLLY FUTURES CO., LTD.",
    name_zh: "弘業期貨",
    isHKConnect: 1,
  },
  {
    no: "3680",
    name_en: "SUOXINDA HOLDINGS LIMITED",
    name_zh: "索信達控股",
    isHKConnect: 0,
  },
  {
    no: "3681",
    name_en: "SINOMAB BIOSCIENCE LIMITED",
    name_zh: "中國抗體-B",
    isHKConnect: 0,
  },
  {
    no: "3683",
    name_en: "GREAT HARVEST MAETA HOLDINGS LIMITED",
    name_zh: "榮豐億控股",
    isHKConnect: 0,
  },
  {
    no: "3686",
    name_en: "CLIFFORD MODERN LIVING HOLDINGS LIMITED",
    name_zh: "祈福生活服務",
    isHKConnect: 0,
  },
  {
    no: "3688",
    name_en: "TOP SPRING INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "萊蒙國際",
    isHKConnect: 0,
  },
  {
    no: "3689",
    name_en: "GUANGDONG KANGHUA HEALTHCARE CO., LTD.",
    name_zh: "康華醫療",
    isHKConnect: 0,
  },
  { no: "3690", name_en: "MEITUAN", name_zh: "美團-W", isHKConnect: 1 },
  {
    no: "3692",
    name_en: "HANSOH PHARMACEUTICAL GROUP COMPANY LIMITED",
    name_zh: "翰森製藥",
    isHKConnect: 1,
  },
  {
    no: "3698",
    name_en: "HUISHANG BANK CORPORATION LIMITED",
    name_zh: "徽商銀行",
    isHKConnect: 1,
  },
  {
    no: "3699",
    name_en: "EVERBRIGHT GRAND CHINA ASSETS LIMITED",
    name_zh: "光大永年",
    isHKConnect: 0,
  },
  {
    no: "3700",
    name_en: "INKEVERSE GROUP LIMITED",
    name_zh: "映宇宙",
    isHKConnect: 0,
  },
  {
    no: "3708",
    name_en: "CHINA SUPPLY CHAIN HOLDINGS LIMITED",
    name_zh: "中國供應鏈產業",
    isHKConnect: 0,
  },
  {
    no: "3709",
    name_en: "EEKA FASHION HOLDINGS LIMITED",
    name_zh: "贏家時尚",
    isHKConnect: 1,
  },
  {
    no: "3718",
    name_en: "BEIJING ENTERPRISES URBAN RESOURCES GROUP LIMITED",
    name_zh: "北控城市資源",
    isHKConnect: 0,
  },
  {
    no: "3728",
    name_en: "CHING LEE HOLDINGS LIMITED",
    name_zh: "正利控股",
    isHKConnect: 0,
  },
  {
    no: "3737",
    name_en: "ZHONGZHI PHARMACEUTICAL HOLDINGS LIMITED",
    name_zh: "中智藥業",
    isHKConnect: 0,
  },
  {
    no: "3738",
    name_en: "VOBILE GROUP LIMITED",
    name_zh: "阜博集團",
    isHKConnect: 1,
  },
  {
    no: "3759",
    name_en: "PHARMARON BEIJING CO., LTD.",
    name_zh: "康龍化成",
    isHKConnect: 1,
  },
  {
    no: "3768",
    name_en: "KUNMING DIANCHI WATER TREATMENT CO.,LTD.",
    name_zh: "滇池水務",
    isHKConnect: 0,
  },
  {
    no: "3773",
    name_en: "NNK GROUP LIMITED",
    name_zh: "年年卡",
    isHKConnect: 0,
  },
  {
    no: "3778",
    name_en: "CHINA WEAVING MATERIALS HOLDINGS LIMITED",
    name_zh: "中國織材控股",
    isHKConnect: 0,
  },
  {
    no: "3788",
    name_en: "CHINA HANKING HOLDINGS LIMITED",
    name_zh: "CHINA HANKING HOLDINGS LIMITED",
    isHKConnect: 1,
  },
  {
    no: "3789",
    name_en: "ROYAL DELUXE HOLDINGS LIMITED",
    name_zh: "御佳控股",
    isHKConnect: 0,
  },
  {
    no: "3798",
    name_en: "HOMELAND INTERACTIVE TECHNOLOGY LTD.",
    name_zh: "家鄉互動",
    isHKConnect: 0,
  },
  {
    no: "3799",
    name_en: "DALI FOODS GROUP COMPANY LIMITED",
    name_zh: "達利食品",
    isHKConnect: 1,
  },
  {
    no: "3800",
    name_en: "GCL TECHNOLOGY HOLDINGS LIMITED",
    name_zh: "協鑫科技",
    isHKConnect: 1,
  },
  {
    no: "3808",
    name_en: "SINOTRUK (HONG KONG) LIMITED",
    name_zh: "中國重汽",
    isHKConnect: 1,
  },
  {
    no: "3813",
    name_en: "POU SHENG INTERNATIONAL (HOLDINGS) LIMITED",
    name_zh: "寶勝國際",
    isHKConnect: 1,
  },
  {
    no: "3816",
    name_en: "KFM KINGDOM HOLDINGS LIMITED",
    name_zh: "KFM金德",
    isHKConnect: 0,
  },
  {
    no: "3818",
    name_en: "CHINA DONGXIANG (GROUP) CO., LTD.",
    name_zh: "中國動向",
    isHKConnect: 1,
  },
  {
    no: "3822",
    name_en: "SAM WOO CONSTRUCTION GROUP LIMITED",
    name_zh: "三和建築集團",
    isHKConnect: 0,
  },
  {
    no: "3828",
    name_en: "MING FAI INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "明輝國際",
    isHKConnect: 0,
  },
  {
    no: "3830",
    name_en: "KIDDIELAND INTERNATIONAL LIMITED",
    name_zh: "童園國際",
    isHKConnect: 0,
  },
  {
    no: "3833",
    name_en: "XINJIANG XINXIN MINING INDUSTRY CO.,LTD.",
    name_zh: "新疆新鑫礦業",
    isHKConnect: 0,
  },
  {
    no: "3836",
    name_en: "CHINA HARMONY AUTO HOLDING LIMITED",
    name_zh: "和諧汽車",
    isHKConnect: 1,
  },
  {
    no: "3838",
    name_en: "CHINA STARCH HOLDINGS LIMITED",
    name_zh: "中國澱粉",
    isHKConnect: 0,
  },
  {
    no: "3839",
    name_en: "CHIA TAI ENTERPRISES INTERNATIONAL LIMITED",
    name_zh: "正大企業國際",
    isHKConnect: 0,
  },
  {
    no: "3848",
    name_en: "WEALTHY WAY GROUP LIMITED",
    name_zh: "富道集團",
    isHKConnect: 0,
  },
  {
    no: "3860",
    name_en: "EPS CREATIVE HEALTH TECHNOLOGY GROUP LIMITED",
    name_zh: "EPS創健科技",
    isHKConnect: 0,
  },
  {
    no: "3866",
    name_en: "BANK OF QINGDAO CO., LTD.",
    name_zh: "青島銀行",
    isHKConnect: 1,
  },
  {
    no: "3868",
    name_en: "XINYI ENERGY HOLDINGS LIMITED",
    name_zh: "信義能源",
    isHKConnect: 1,
  },
  {
    no: "3869",
    name_en: "HOSPITAL CORPORATION OF CHINA LIMITED",
    name_zh: "弘和仁愛醫療",
    isHKConnect: 0,
  },
  {
    no: "3877",
    name_en: "CSSC (HONG KONG) SHIPPING COMPANY LIMITED",
    name_zh: "中國船舶租賃",
    isHKConnect: 1,
  },
  {
    no: "3878",
    name_en: "VICON HOLDINGS LIMITED",
    name_zh: "VICON HOLDINGS LIMITED",
    isHKConnect: 0,
  },
  {
    no: "3882",
    name_en: "SKY LIGHT HOLDINGS LIMITED",
    name_zh: "天彩控股",
    isHKConnect: 0,
  },
  {
    no: "3883",
    name_en: "CHINA AOYUAN GROUP LIMITED",
    name_zh: "中國奧園",
    isHKConnect: 1,
  },
  {
    no: "3886",
    name_en: "TOWN HEALTH INTERNATIONAL MEDICAL GROUP LIMITED",
    name_zh: "康健國際醫療",
    isHKConnect: 1,
  },
  {
    no: "3888",
    name_en: "KINGSOFT CORPORATION LIMITED",
    name_zh: "金山軟件",
    isHKConnect: 1,
  },
  {
    no: "3889",
    name_en: "GLOBAL SWEETENERS HOLDINGS LIMITED",
    name_zh: "大成糖業",
    isHKConnect: 0,
  },
  {
    no: "3893",
    name_en: "CROSSTEC GROUP HOLDINGS LIMITED",
    name_zh: "易緯集團",
    isHKConnect: 0,
  },
  {
    no: "3898",
    name_en: "ZHUZHOU CRRC TIMES ELECTRIC CO., LTD.",
    name_zh: "時代電氣",
    isHKConnect: 1,
  },
  {
    no: "3899",
    name_en: "CIMC ENRIC HOLDINGS LIMITED",
    name_zh: "中集安瑞科",
    isHKConnect: 1,
  },
  {
    no: "3900",
    name_en: "GREENTOWN CHINA HOLDINGS LIMITED",
    name_zh: "綠城中國",
    isHKConnect: 1,
  },
  {
    no: "3903",
    name_en: "HANHUA FINANCIAL HOLDING CO., LTD.",
    name_zh: "瀚華金控",
    isHKConnect: 0,
  },
  {
    no: "3908",
    name_en: "CHINA INTERNATIONAL CAPITAL CORPORATION LIMITED",
    name_zh: "中金公司",
    isHKConnect: 1,
  },
  {
    no: "3913",
    name_en: "KWG LIVING GROUP HOLDINGS LIMITED",
    name_zh: "合景悠活",
    isHKConnect: 1,
  },
  { no: "3918", name_en: "NAGACORP LTD", name_zh: "金界控股", isHKConnect: 0 },
  {
    no: "3919",
    name_en: "GOLDEN POWER GROUP HOLDINGS LIMITED",
    name_zh: "金力集團",
    isHKConnect: 0,
  },
  {
    no: "3928",
    name_en: "S&T HOLDINGS LIMITED",
    name_zh: "S&T HOLDINGS LIMITED",
    isHKConnect: 0,
  },
  {
    no: "3931",
    name_en: "CALB CO., LTD.",
    name_zh: "中創新航",
    isHKConnect: 0,
  },
  {
    no: "3933",
    name_en: "UNITED LABORATORIES INTERNATIONAL HOLDINGS LIMITED, THE",
    name_zh: "聯邦制藥",
    isHKConnect: 1,
  },
  {
    no: "3938",
    name_en: "LFG INVESTMENT HOLDINGS LIMITED",
    name_zh: "LFG投資控股",
    isHKConnect: 0,
  },
  {
    no: "3939",
    name_en: "WANGUO INTERNATIONAL MINING GROUP LIMITED",
    name_zh: "萬國國際礦業",
    isHKConnect: 0,
  },
  {
    no: "3948",
    name_en: "INNER MONGOLIA YITAI COAL CO., LTD.",
    name_zh: "伊泰煤炭",
    isHKConnect: 0,
  },
  { no: "3958", name_en: "DFZQ", name_zh: "東方證券", isHKConnect: 1 },
  {
    no: "3963",
    name_en: "CHINA RONGZHONG FINANCIAL HOLDINGS COMPANY LIMITED",
    name_zh: "融眾金融",
    isHKConnect: 0,
  },
  {
    no: "3968",
    name_en: "CHINA MERCHANTS BANK CO., LTD.",
    name_zh: "招商銀行",
    isHKConnect: 1,
  },
  {
    no: "3969",
    name_en: "CHINA RAILWAY SIGNAL & COMMUNICATION CORPORATION LIMITED",
    name_zh: "中國通號",
    isHKConnect: 1,
  },
  {
    no: "3978",
    name_en: "CHINA BESTSTUDY EDUCATION GROUP",
    name_zh: "卓越教育集團",
    isHKConnect: 0,
  },
  {
    no: "3983",
    name_en: "CHINA BLUECHEMICAL LTD",
    name_zh: "中海石油化學",
    isHKConnect: 0,
  },
  {
    no: "3988",
    name_en: "BANK OF CHINA LIMITED",
    name_zh: "中國銀行",
    isHKConnect: 1,
  },
  {
    no: "3989",
    name_en: "CAPITAL ENVIRONMENT HOLDINGS LIMITED",
    name_zh: "首創環境",
    isHKConnect: 0,
  },
  {
    no: "3990",
    name_en: "MIDEA REAL ESTATE HOLDING LIMITED",
    name_zh: "美的置業",
    isHKConnect: 1,
  },
  {
    no: "3991",
    name_en: "CHANGHONG JIAHUA HOLDINGS LIMITED",
    name_zh: "長虹佳華",
    isHKConnect: 0,
  },
  {
    no: "3993",
    name_en: "CMOC GROUP LIMITED",
    name_zh: "洛陽鉬業",
    isHKConnect: 1,
  },
  {
    no: "3996",
    name_en: "CHINA ENERGY ENGINEERING CORPORATION LIMITED",
    name_zh: "中國能源建設",
    isHKConnect: 1,
  },
  {
    no: "3997",
    name_en: "TELECOM SERVICE ONE HOLDINGS LIMITED",
    name_zh: "電訊首科",
    isHKConnect: 0,
  },
  {
    no: "3998",
    name_en: "BOSIDENG INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "波司登",
    isHKConnect: 1,
  },
  {
    no: "3999",
    name_en: "DACHAN FOOD (ASIA) LIMITED",
    name_zh: "大成食品",
    isHKConnect: 0,
  },
  {
    no: "4332",
    name_en: "AMGEN INC.-T (AMGN)",
    name_zh: "AMGEN-T",
    isHKConnect: 0,
  },
  {
    no: "4333",
    name_en: "CISCO SYSTEMS,INC.-T (CSCO)",
    name_zh: "思科－T",
    isHKConnect: 0,
  },
  {
    no: "4335",
    name_en: "INTEL CORPORATION-T (INTC)",
    name_zh: "英特爾-T",
    isHKConnect: 0,
  },
  {
    no: "4336",
    name_en: "APPLIED MATERIALS,INC.T (AMAT)",
    name_zh: "應用材料－T",
    isHKConnect: 0,
  },
  {
    no: "4337",
    name_en: "STARBUCKS CORPORATION-T",
    name_zh: "星巴克－Ｔ",
    isHKConnect: 0,
  },
  {
    no: "4338",
    name_en: "MICROSOFT CORPORATION-T (MSFT)",
    name_zh: "微軟－T",
    isHKConnect: 0,
  },
  {
    no: "6030",
    name_en: "CITIC SECURITIES COMPANY LIMITED",
    name_zh: "中信証券",
    isHKConnect: 1,
  },
  {
    no: "6033",
    name_en: "TELECOM DIGITAL HOLDINGS LIMITED",
    name_zh: "電訊數碼控股",
    isHKConnect: 0,
  },
  {
    no: "6036",
    name_en: "APEX ACE HOLDING LIMITED",
    name_zh: "光麗科技",
    isHKConnect: 0,
  },
  {
    no: "6038",
    name_en: "G & M HOLDINGS LIMITED",
    name_zh: "信越控股",
    isHKConnect: 0,
  },
  {
    no: "6049",
    name_en: "POLY PROPERTY SERVICES CO., LTD.",
    name_zh: "保利物業",
    isHKConnect: 1,
  },
  {
    no: "6055",
    name_en: "CHINA TOBACCO INTERNATIONAL (HK) COMPANY LIMITED",
    name_zh: "中煙香港",
    isHKConnect: 1,
  },
  {
    no: "6058",
    name_en:
      "CHINA INDUSTRIAL SECURITIES INTERNATIONAL FINANCIAL GROUP LIMITED",
    name_zh: "興證國際",
    isHKConnect: 0,
  },
  {
    no: "6060",
    name_en: "ZHONGAN ONLINE P & C INSURANCE CO., LTD.",
    name_zh: "眾安在線",
    isHKConnect: 1,
  },
  {
    no: "6063",
    name_en: "LOTUS HORIZON HOLDINGS LIMITED",
    name_zh: "智中國際",
    isHKConnect: 0,
  },
  {
    no: "6066",
    name_en: "CSC FINANCIAL CO., LTD.",
    name_zh: "中信建投証券",
    isHKConnect: 1,
  },
  {
    no: "6068",
    name_en: "WISDOM EDUCATION INTERNATIONAL HOLDINGS COMPANY LIMITED",
    name_zh: "光正教育",
    isHKConnect: 1,
  },
  {
    no: "6069",
    name_en: "SY HOLDINGS GROUP LIMITED",
    name_zh: "盛業",
    isHKConnect: 1,
  },
  {
    no: "6078",
    name_en: "HYGEIA HEALTHCARE HOLDINGS CO., LIMITED",
    name_zh: "海吉亞醫療",
    isHKConnect: 1,
  },
  {
    no: "6080",
    name_en: "WING CHI HOLDINGS LIMITED",
    name_zh: "榮智控股",
    isHKConnect: 0,
  },
  {
    no: "6083",
    name_en: "WORLD-LINK LOGISTICS (ASIA) HOLDING LIMITED",
    name_zh: "環宇物流（亞洲）",
    isHKConnect: 0,
  },
  {
    no: "6088",
    name_en: "FIT HON TENG LIMITED",
    name_zh: "FIT HON TENG LIMITED",
    isHKConnect: 1,
  },
  {
    no: "6090",
    name_en: "CENTURION CORPORATION LIMITED",
    name_zh: "勝捷企業",
    isHKConnect: 0,
  },
  {
    no: "6093",
    name_en: "HEVOL SERVICES GROUP CO. LIMITED",
    name_zh: "和泓服務",
    isHKConnect: 0,
  },
  {
    no: "6098",
    name_en: "COUNTRY GARDEN SERVICES HOLDINGS COMPANY LIMITED",
    name_zh: "碧桂園服務",
    isHKConnect: 1,
  },
  {
    no: "6099",
    name_en: "CHINA MERCHANTS SECURITIES CO., LTD.",
    name_zh: "招商證券",
    isHKConnect: 1,
  },
  {
    no: "6100",
    name_en: "TONGDAO LIEPIN GROUP",
    name_zh: "同道獵聘",
    isHKConnect: 1,
  },
  {
    no: "6108",
    name_en: "NEW RAY MEDICINE INTERNATIONAL HOLDING LIMITED",
    name_zh: "新銳醫藥",
    isHKConnect: 0,
  },
  {
    no: "6110",
    name_en: "TOPSPORTS INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "滔搏",
    isHKConnect: 1,
  },
  {
    no: "6111",
    name_en: "DAFA PROPERTIES GROUP LIMITED",
    name_zh: "大發地產",
    isHKConnect: 0,
  },
  {
    no: "6113",
    name_en: "UTS MARKETING SOLUTIONS HOLDINGS LIMITED",
    name_zh: "UTS MARKETING SOLUTIONS HOLDINGS LIMITED",
    isHKConnect: 0,
  },
  {
    no: "6116",
    name_en: "XINJIANG LA CHAPELLE FASHION CO., LTD.",
    name_zh: "拉夏貝爾",
    isHKConnect: 1,
  },
  {
    no: "6117",
    name_en: "RIZHAO PORT JURONG CO., LTD.",
    name_zh: "日照港裕廊",
    isHKConnect: 0,
  },
  {
    no: "6118",
    name_en: "AUSTAR LIFESCIENCES LIMITED",
    name_zh: "奧星生命科技",
    isHKConnect: 0,
  },
  {
    no: "6119",
    name_en: "TIAN YUAN GROUP HOLDINGS LIMITED",
    name_zh: "天源集團",
    isHKConnect: 0,
  },
  {
    no: "6122",
    name_en: "JILIN JIUTAI RURAL COMMERCIAL BANK CORPORATION LIMITED",
    name_zh: "九台農商銀行",
    isHKConnect: 0,
  },
  {
    no: "6123",
    name_en: "YTO EXPRESS (INTERNATIONAL) HOLDINGS LIMITED",
    name_zh: "圓通速遞國際",
    isHKConnect: 0,
  },
  {
    no: "6127",
    name_en: "JOINN LABORATORIES (CHINA) CO., LTD.",
    name_zh: "昭衍新藥",
    isHKConnect: 1,
  },
  {
    no: "6128",
    name_en: "GRAPHEX GROUP LIMITED",
    name_zh: "烯石電車新材料",
    isHKConnect: 0,
  },
  {
    no: "6133",
    name_en: "VITAL INNOVATIONS HOLDINGS LIMITED",
    name_zh: "維太創科",
    isHKConnect: 0,
  },
  {
    no: "6136",
    name_en: "KANGDA INTERNATIONAL ENVIRONMENTAL COMPANY LIMITED",
    name_zh: "康達環保",
    isHKConnect: 0,
  },
  {
    no: "6138",
    name_en: "HARBIN BANK CO., LTD",
    name_zh: "哈爾濱銀行",
    isHKConnect: 0,
  },
  {
    no: "6158",
    name_en: "ZHENRO PROPERTIES GROUP LIMITED",
    name_zh: "正榮地產",
    isHKConnect: 1,
  },
  { no: "6160", name_en: "BEIGENE, LTD.", name_zh: "百濟神州", isHKConnect: 1 },
  {
    no: "6161",
    name_en: "TARGET INSURANCE (HOLDINGS) LIMITED",
    name_zh: "泰加保險",
    isHKConnect: 0,
  },
  {
    no: "6162",
    name_en: "CHINA TIANRUI AUTOMOTIVE INTERIORS CO., LTD",
    name_zh: "天瑞汽車內飾",
    isHKConnect: 0,
  },
  {
    no: "6163",
    name_en: "GEMILANG INTERNATIONAL LIMITED",
    name_zh: "彭順國際",
    isHKConnect: 0,
  },
  {
    no: "6166",
    name_en: "CHINA VAST INDUSTRIAL URBAN DEVELOPMENT COMPANY LIMITED",
    name_zh: "中國宏泰發展",
    isHKConnect: 1,
  },
  {
    no: "6168",
    name_en: "CHINA U-TON FUTURE SPACE INDUSTRIAL GROUP HOLDINGS LTD.",
    name_zh: "優通未來",
    isHKConnect: 0,
  },
  {
    no: "6169",
    name_en: "CHINA YUHUA EDUCATION CORPORATION LIMITED",
    name_zh: "宇華教育",
    isHKConnect: 1,
  },
  {
    no: "6178",
    name_en: "EVERBRIGHT SECURITIES COMPANY LIMITED",
    name_zh: "光大證券",
    isHKConnect: 1,
  },
  {
    no: "6182",
    name_en: "TWINTEK INVESTMENT HOLDINGS LIMITED",
    name_zh: "乙德投資控股",
    isHKConnect: 0,
  },
  {
    no: "6185",
    name_en: "CANSINO BIOLOGICS INC.",
    name_zh: "康希諾生物",
    isHKConnect: 1,
  },
  {
    no: "6186",
    name_en: "CHINA FEIHE LIMITED",
    name_zh: "中國飛鶴",
    isHKConnect: 1,
  },
  {
    no: "6188",
    name_en: "BEIJING DIGITAL TELECOM CO., LTD.",
    name_zh: "迪信通",
    isHKConnect: 0,
  },
  {
    no: "6189",
    name_en: "GUANGDONG ADWAY CONSTRUCTION (GROUP) HOLDINGS COMPANY LIMITED",
    name_zh: "愛得威建設集團",
    isHKConnect: 0,
  },
  {
    no: "6190",
    name_en: "BANK OF JIUJIANG CO., LTD.",
    name_zh: "九江銀行",
    isHKConnect: 0,
  },
  {
    no: "6193",
    name_en: "TAILAM TECH CONSTRUCTION HOLDINGS LIMITED",
    name_zh: "泰林科建",
    isHKConnect: 0,
  },
  {
    no: "6196",
    name_en: "BANK OF ZHENGZHOU CO., LTD.",
    name_zh: "鄭州銀行",
    isHKConnect: 1,
  },
  {
    no: "6198",
    name_en: "QINGDAO PORT INTERNATIONAL CO., LTD.",
    name_zh: "青島港",
    isHKConnect: 1,
  },
  {
    no: "6199",
    name_en: "BANK OF GUIZHOU CO., LTD.",
    name_zh: "貴州銀行",
    isHKConnect: 0,
  },
  {
    no: "6600",
    name_en: "SCICLONE PHARMACEUTICALS (HOLDINGS) LIMITED",
    name_zh: "賽生藥業",
    isHKConnect: 1,
  },
  {
    no: "6601",
    name_en: "CHEERWIN GROUP LIMITED",
    name_zh: "朝雲集團",
    isHKConnect: 1,
  },
  {
    no: "6606",
    name_en: "NEW HORIZON HEALTH LIMITED",
    name_zh: "諾輝健康-B",
    isHKConnect: 1,
  },
  { no: "6608", name_en: "BAIRONG INC.", name_zh: "百融雲-W", isHKConnect: 0 },
  {
    no: "6609",
    name_en: "SHANGHAI HEARTCARE MEDICAL TECHNOLOGY CORPORATION LIMITED",
    name_zh: "心瑋醫療-B",
    isHKConnect: 0,
  },
  {
    no: "6610",
    name_en: "FLOWING CLOUD TECHNOLOGY LTD",
    name_zh: "飛天雲動",
    isHKConnect: 0,
  },
  {
    no: "6611",
    name_en: "SANXUN HOLDINGS GROUP LIMITED",
    name_zh: "三巽集團",
    isHKConnect: 0,
  },
  {
    no: "6616",
    name_en: "GLOBAL NEW MATERIAL INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "環球新材國際",
    isHKConnect: 1,
  },
  {
    no: "6618",
    name_en: "JD HEALTH INTERNATIONAL INC.",
    name_zh: "京東健康",
    isHKConnect: 1,
  },
  {
    no: "6622",
    name_en: "ZHAOKE OPHTHALMOLOGY LIMITED",
    name_zh: "兆科眼科-B",
    isHKConnect: 0,
  },
  {
    no: "6626",
    name_en: "YUEXIU SERVICES GROUP LIMITED",
    name_zh: "越秀服務",
    isHKConnect: 0,
  },
  {
    no: "6628",
    name_en: "TRANSCENTA HOLDING LIMITED",
    name_zh: "創勝集團-B",
    isHKConnect: 0,
  },
  {
    no: "6633",
    name_en: "QINGCI GAMES INC.",
    name_zh: "青瓷遊戲",
    isHKConnect: 0,
  },
  {
    no: "6638",
    name_en: "ONECONNECT FINANCIAL TECHNOLOGY CO., LTD.",
    name_zh: "金融壹賬通",
    isHKConnect: 0,
  },
  {
    no: "6639",
    name_en: "ARRAIL GROUP LIMITED",
    name_zh: "瑞爾集團",
    isHKConnect: 1,
  },
  {
    no: "6655",
    name_en: "HUAXIN CEMENT CO., LTD.",
    name_zh: "華新水泥",
    isHKConnect: 1,
  },
  {
    no: "6660",
    name_en: "AIM VACCINE CO., LTD.",
    name_zh: "艾美疫苗",
    isHKConnect: 0,
  },
  {
    no: "6661",
    name_en: "HUZHOU GAS CO., LTD.",
    name_zh: "湖州燃氣",
    isHKConnect: 0,
  },
  {
    no: "6663",
    name_en: "IWS GROUP HOLDINGS LIMITED",
    name_zh: "國際永勝集團",
    isHKConnect: 0,
  },
  {
    no: "6666",
    name_en: "EVERGRANDE PROPERTY SERVICES GROUP LIMITED",
    name_zh: "恆大物業",
    isHKConnect: 1,
  },
  {
    no: "6667",
    name_en: "MEGA GENOMICS LIMITED",
    name_zh: "美因基因",
    isHKConnect: 0,
  },
  {
    no: "6668",
    name_en: "E-STAR COMMERCIAL MANAGEMENT COMPANY LIMITED",
    name_zh: "星盛商業",
    isHKConnect: 0,
  },
  {
    no: "6669",
    name_en: "ACOTEC SCIENTIFIC HOLDINGS LIMITED",
    name_zh: "先瑞達醫療-B",
    isHKConnect: 0,
  },
  {
    no: "6677",
    name_en: "SINO-OCEAN SERVICE HOLDING LIMITED",
    name_zh: "遠洋服務",
    isHKConnect: 0,
  },
  {
    no: "6680",
    name_en: "JL MAG RARE-EARTH CO., LTD.",
    name_zh: "金力永磁",
    isHKConnect: 1,
  },
  {
    no: "6686",
    name_en: "NOAH HOLDINGS PRIVATE WEALTH AND ASSET MANAGEMENT LIMITED",
    name_zh: "諾亞控股-S",
    isHKConnect: 0,
  },
  {
    no: "6689",
    name_en: "CHONGQING HONGJIU FRUIT CO., LIMITED",
    name_zh: "洪九果品",
    isHKConnect: 0,
  },
  {
    no: "6690",
    name_en: "HAIER SMART HOME CO., LTD.",
    name_zh: "海爾智家",
    isHKConnect: 1,
  },
  {
    no: "6699",
    name_en: "ANGELALIGN TECHNOLOGY INC.",
    name_zh: "時代天使",
    isHKConnect: 1,
  },
  {
    no: "6805",
    name_en: "KIMOU ENVIRONMENTAL HOLDING LIMITED",
    name_zh: "金茂源環保",
    isHKConnect: 0,
  },
  {
    no: "6806",
    name_en: "SHENWAN HONGYUAN GROUP CO., LTD.",
    name_zh: "申萬宏源",
    isHKConnect: 1,
  },
  {
    no: "6808",
    name_en: "SUN ART RETAIL GROUP LIMITED",
    name_zh: "高鑫零售",
    isHKConnect: 1,
  },
  {
    no: "6811",
    name_en: "TAI HING GROUP HOLDINGS LIMITED",
    name_zh: "太興集團",
    isHKConnect: 0,
  },
  {
    no: "6812",
    name_en: "WINSON HOLDINGS HONG KONG LIMITED",
    name_zh: "永順控股香港",
    isHKConnect: 0,
  },
  {
    no: "6816",
    name_en: "PROSPER CONSTRUCTION HOLDINGS LIMITED",
    name_zh: "瑞港建設",
    isHKConnect: 0,
  },
  {
    no: "6818",
    name_en: "CHINA EVERBRIGHT BANK COMPANY LIMITED",
    name_zh: "中國光大銀行",
    isHKConnect: 1,
  },
  {
    no: "6819",
    name_en: "INTELLICENTRICS GLOBAL HOLDINGS LTD.",
    name_zh: "中智全球",
    isHKConnect: 0,
  },
  {
    no: "6820",
    name_en: "FRIENDTIMES INC.",
    name_zh: "友誼時光",
    isHKConnect: 0,
  },
  {
    no: "6821",
    name_en: "ASYMCHEM LABORATORIES (TIANJIN) CO., LTD.",
    name_zh: "凱萊英",
    isHKConnect: 1,
  },
  {
    no: "6822",
    name_en: "KING'S FLAIR INTERNATIONAL (HOLDINGS) LIMITED",
    name_zh: "科勁國際",
    isHKConnect: 0,
  },
  {
    no: "6823",
    name_en: "HKT TRUST AND HKT LIMITED",
    name_zh: "香港電訊-SS",
    isHKConnect: 0,
  },
  {
    no: "6826",
    name_en: "SHANGHAI HAOHAI BIOLOGICAL TECHNOLOGY CO., LTD.",
    name_zh: "昊海生物科技",
    isHKConnect: 1,
  },
  {
    no: "6828",
    name_en: "BEIJING GAS BLUE SKY HOLDINGS LIMITED",
    name_zh: "北京燃氣藍天",
    isHKConnect: 1,
  },
  {
    no: "6829",
    name_en: "DRAGON RISE GROUP HOLDINGS LIMITED",
    name_zh: "龍昇集團控股",
    isHKConnect: 0,
  },
  {
    no: "6830",
    name_en: "HUAZHONG IN-VEHICLE HOLDINGS COMPANY LIMITED",
    name_zh: "華眾車載",
    isHKConnect: 0,
  },
  {
    no: "6833",
    name_en: "SINCO PHARMACEUTICALS HOLDINGS LIMITED",
    name_zh: "興科蓉醫藥",
    isHKConnect: 0,
  },
  {
    no: "6836",
    name_en: "TIANYUN INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "天韻國際控股",
    isHKConnect: 0,
  },
  {
    no: "6837",
    name_en: "HAITONG SECURITIES CO., LTD.",
    name_zh: "海通證券",
    isHKConnect: 1,
  },
  {
    no: "6838",
    name_en: "WINOX HOLDINGS LIMITED",
    name_zh: "盈利時",
    isHKConnect: 0,
  },
  {
    no: "6839",
    name_en: "YUNNAN WATER INVESTMENT CO., LIMITED",
    name_zh: "雲南水務",
    isHKConnect: 0,
  },
  {
    no: "6855",
    name_en: "ASCENTAGE PHARMA GROUP INTERNATIONAL",
    name_zh: "亞盛醫藥-B",
    isHKConnect: 1,
  },
  {
    no: "6858",
    name_en: "HONMA GOLF LIMITED",
    name_zh: "本間高爾夫",
    isHKConnect: 0,
  },
  {
    no: "6860",
    name_en: "FINGERTANGO INC.",
    name_zh: "指尖悅動",
    isHKConnect: 0,
  },
  {
    no: "6862",
    name_en: "HAIDILAO INTERNATIONAL HOLDING LTD.",
    name_zh: "海底撈",
    isHKConnect: 1,
  },
  {
    no: "6865",
    name_en: "FLAT GLASS GROUP CO., LTD.",
    name_zh: "福萊特玻璃",
    isHKConnect: 1,
  },
  {
    no: "6866",
    name_en: "ZUOLI KECHUANG MICRO-FINANCE COMPANY LIMITED",
    name_zh: "佐力小貸",
    isHKConnect: 0,
  },
  {
    no: "6868",
    name_en: "TENFU (CAYMAN) HOLDINGS COMPANY LIMITED",
    name_zh: "天福",
    isHKConnect: 0,
  },
  {
    no: "6869",
    name_en: "YANGTZE OPTICAL FIBRE AND CABLE JOINT STOCK LIMITED COMPANY",
    name_zh: "長飛光纖光纜",
    isHKConnect: 1,
  },
  {
    no: "6877",
    name_en: "CLSA PREMIUM LIMITED",
    name_zh: "CLSA PREMIUM",
    isHKConnect: 0,
  },
  {
    no: "6878",
    name_en: "DIFFER GROUP AUTO LIMITED",
    name_zh: "鼎豐集團汽車",
    isHKConnect: 1,
  },
  {
    no: "6880",
    name_en: "TEMPUS HOLDINGS LIMITED",
    name_zh: "騰邦控股",
    isHKConnect: 0,
  },
  {
    no: "6881",
    name_en: "CHINA GALAXY SECURITIES CO., LTD.",
    name_zh: "中國銀河",
    isHKConnect: 1,
  },
  {
    no: "6882",
    name_en: "EGL HOLDINGS COMPANY LIMITED",
    name_zh: "東瀛遊",
    isHKConnect: 0,
  },
  {
    no: "6885",
    name_en: "HENAN JINMA ENERGY COMPANY LIMITED",
    name_zh: "金馬能源",
    isHKConnect: 0,
  },
  {
    no: "6886",
    name_en: "HUATAI SECURITIES CO., LTD.",
    name_zh: "HUATAI SECURITIES CO., LTD.",
    isHKConnect: 1,
  },
  {
    no: "6888",
    name_en: "FREETECH ROAD RECYCLING TECHNOLOGY (HOLDINGS) LIMITED",
    name_zh: "英達公路再生科技",
    isHKConnect: 0,
  },
  {
    no: "6889",
    name_en: "DYNAM JAPAN HOLDINGS CO., LTD.",
    name_zh: "DYNAM JAPAN HOLDINGS CO., LTD.",
    isHKConnect: 0,
  },
  {
    no: "6890",
    name_en: "KANGLI INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "康利國際控股",
    isHKConnect: 0,
  },
  {
    no: "6893",
    name_en: "HIN SANG GROUP (INTERNATIONAL) HOLDING CO. LTD.",
    name_zh: "衍生集團",
    isHKConnect: 0,
  },
  {
    no: "6896",
    name_en: "GOLDEN THROAT HOLDINGS GROUP COMPANY LIMITED",
    name_zh: "金嗓子",
    isHKConnect: 0,
  },
  {
    no: "6898",
    name_en: "CHINA ALUMINUM CANS HOLDINGS LIMITED",
    name_zh: "中國鋁罐",
    isHKConnect: 0,
  },
  {
    no: "6899",
    name_en: "OURGAME INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "聯眾",
    isHKConnect: 0,
  },
  {
    no: "6900",
    name_en: "SUNKWAN PROPERTIES GROUP LIMITED",
    name_zh: "上坤地產",
    isHKConnect: 0,
  },
  {
    no: "6908",
    name_en: "HG SEMICONDUCTOR LIMITED",
    name_zh: "宏光半導體",
    isHKConnect: 0,
  },
  {
    no: "6909",
    name_en: "BETTERLIFE HOLDING LIMITED",
    name_zh: "百得利控股",
    isHKConnect: 0,
  },
  {
    no: "6913",
    name_en: "SOUTH CHINA VOCATIONAL EDUCATION GROUP COMPANY LIMITED",
    name_zh: "華南職業教育",
    isHKConnect: 0,
  },
  {
    no: "6918",
    name_en: "KIDZTECH HOLDINGS LIMITED",
    name_zh: "奇士達",
    isHKConnect: 0,
  },
  {
    no: "6919",
    name_en: "RENRUI HUMAN RESOURCES TECHNOLOGY HOLDINGS LIMITED",
    name_zh: "人瑞人才",
    isHKConnect: 0,
  },
  {
    no: "6928",
    name_en: "TOMO HOLDINGS LIMITED",
    name_zh: "萬馬控股",
    isHKConnect: 0,
  },
  {
    no: "6933",
    name_en: "SINO-ENTERTAINMENT TECHNOLOGY HOLDINGS LIMITED",
    name_zh: "新娛科控股",
    isHKConnect: 0,
  },
  {
    no: "6939",
    name_en: "MEGAIN HOLDING (CAYMAN) CO., LTD.",
    name_zh: "美佳音控股",
    isHKConnect: 0,
  },
  {
    no: "6958",
    name_en: "ZHENRO SERVICES GROUP LIMITED",
    name_zh: "正榮服務",
    isHKConnect: 0,
  },
  {
    no: "6966",
    name_en: "CHINA WAN TONG YUAN (HOLDINGS) LIMITED",
    name_zh: "中國萬桐園",
    isHKConnect: 0,
  },
  {
    no: "6968",
    name_en: "GANGLONG CHINA PROPERTY GROUP LIMITED",
    name_zh: "港龍中國地產",
    isHKConnect: 1,
  },
  {
    no: "6969",
    name_en: "SMOORE INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "思摩爾國際",
    isHKConnect: 1,
  },
  {
    no: "6978",
    name_en: "IMMUNOTECH BIOPHARM LTD",
    name_zh: "永泰生物-B",
    isHKConnect: 1,
  },
  {
    no: "6988",
    name_en: "JOY SPREADER GROUP INC.",
    name_zh: "樂享集團",
    isHKConnect: 1,
  },
  {
    no: "6989",
    name_en:
      "EXCELLENCE COMMERCIAL PROPERTY & FACILITIES MANAGEMENT GROUP LIMITED",
    name_zh: "卓越商企服務",
    isHKConnect: 1,
  },
  {
    no: "6993",
    name_en: "BLUE MOON GROUP HOLDINGS LIMITED",
    name_zh: "藍月亮集團",
    isHKConnect: 1,
  },
  {
    no: "6996",
    name_en: "ANTENGENE CORPORATION LIMITED",
    name_zh: "德琪醫藥-B",
    isHKConnect: 1,
  },
  {
    no: "6998",
    name_en: "GENOR BIOPHARMA HOLDINGS LIMITED",
    name_zh: "嘉和生物-B",
    isHKConnect: 1,
  },
  {
    no: "6999",
    name_en: "LEADING HOLDINGS GROUP LIMITED",
    name_zh: "領地控股",
    isHKConnect: 0,
  },
  {
    no: "7200",
    name_en: "CSOP HANG SENG INDEX DAILY (2X) LEVERAGED PRODUCT",
    name_zh: "ＦＬ二南方恆指",
    isHKConnect: 0,
  },
  {
    no: "7300",
    name_en: "CSOP HANG SENG INDEX DAILY (-1X) INVERSE PRODUCT",
    name_zh: "ＦＩ南方恆指",
    isHKConnect: 0,
  },
  {
    no: "7322",
    name_en: "GLOBAL X S&P 500 DAILY (-1X) INVERSE PRODUCT",
    name_zh: "XIGX標普",
    isHKConnect: 0,
  },
  {
    no: "7331",
    name_en: "CHINAAMC NASDAQ-100 INDEX DAILY (-1X) INVERSE PRODUCT",
    name_zh: "FI華夏納一百",
    isHKConnect: 0,
  },
  {
    no: "7333",
    name_en: "CSOP CSI 300 INDEX DAILY (-1X) INVERSE PRODUCT",
    name_zh: "XI南方滬深三百",
    isHKConnect: 0,
  },
  {
    no: "7336",
    name_en: "GLOBAL X HANG SENG INDEX DAILY (-1X) INVERSE PRODUCT",
    name_zh: "FIGX恆指",
    isHKConnect: 0,
  },
  {
    no: "7345",
    name_en: "CSOP WTI CRUDE OIL FUTURES DAILY (-1X) INVERSE PRODUCT",
    name_zh: "FI南方原油",
    isHKConnect: 0,
  },
  {
    no: "7348",
    name_en: "CSOP FTSE CHINA A50 INDEX DAILY (-1X) INVERSE PRODUCT",
    name_zh: "XI南方A50",
    isHKConnect: 0,
  },
  {
    no: "7362",
    name_en:
      "GLOBAL X HANG SENG CHINA ENTERPRISES INDEX DAILY (-1X) INVERSE PRODUCT",
    name_zh: "FIGX國指",
    isHKConnect: 0,
  },
  {
    no: "7373",
    name_en: "CHINAAMC CSI 300 INDEX DAILY (-1X) INVERSE PRODUCT",
    name_zh: "XI華夏滬深三百",
    isHKConnect: 0,
  },
  {
    no: "7374",
    name_en: "CSOP GOLD FUTURES DAILY (-1X) INVERSE PRODUCT",
    name_zh: "FI南方黃金",
    isHKConnect: 0,
  },
  {
    no: "7500",
    name_en: "CSOP HANG SENG INDEX DAILY (-2X) INVERSE PRODUCT",
    name_zh: "FI二南方恆指",
    isHKConnect: 0,
  },
  {
    no: "7522",
    name_en: "CHINAAMC NASDAQ-100 INDEX DAILY (-2X) INVERSE PRODUCT",
    name_zh: "FI二華夏納一百",
    isHKConnect: 0,
  },
  {
    no: "7552",
    name_en: "CSOP HANG SENG TECH INDEX DAILY (-2X) INVERSE PRODUCT",
    name_zh: "XI二南方恆科",
    isHKConnect: 0,
  },
  {
    no: "7568",
    name_en: "CSOP NASDAQ-100 INDEX DAILY (-2X) INVERSE PRODUCT",
    name_zh: "FI二南方納指",
    isHKConnect: 0,
  },
  {
    no: "7588",
    name_en:
      "CSOP HANG SENG CHINA ENTERPRISES INDEX DAILY (-2X) INVERSE PRODUCT",
    name_zh: "FI二南方國指",
    isHKConnect: 0,
  },
  {
    no: "7801",
    name_en: "INTERRA ACQUISITION CORPORATION",
    name_zh: "INTERRA ACQUISITION CORPORATION",
    isHKConnect: 0,
  },
  {
    no: "7827",
    name_en: "VISION DEAL HK ACQUISITION CORP.",
    name_zh: "VISION DEAL HK ACQUISITION CORP.",
    isHKConnect: 0,
  },
  {
    no: "7836",
    name_en: "AQUILA ACQUISITION CORPORATION",
    name_zh: "AQUILA ACQUISITION CORPORATION",
    isHKConnect: 0,
  },
  {
    no: "8001",
    name_en: "ORIENT SECURITIES INTERNATIONAL HOLDINGS LIMITED-NEW",
    name_zh: "東方匯財證券-新",
    isHKConnect: 0,
  },
  {
    no: "8003",
    name_en: "GREAT WORLD COMPANY HOLDINGS LTD",
    name_zh: "世大控股",
    isHKConnect: 0,
  },
  {
    no: "8005",
    name_en: "YUXING INFOTECH INVESTMENT HOLDINGS LIMITED",
    name_zh: "裕興科技",
    isHKConnect: 0,
  },
  {
    no: "8006",
    name_en: "SINO SPLENDID HOLDINGS LIMITED",
    name_zh: "華泰瑞銀",
    isHKConnect: 0,
  },
  {
    no: "8007",
    name_en: "GLOBAL STRATEGIC GROUP LIMITED",
    name_zh: "環球戰略集團",
    isHKConnect: 0,
  },
  {
    no: "8009",
    name_en: "CHINESE ENERGY HOLDINGS LIMITED",
    name_zh: "華夏能源控股",
    isHKConnect: 0,
  },
  {
    no: "8011",
    name_en: "POLYARD PETROLEUM INTERNATIONAL GROUP LIMITED",
    name_zh: "百田石油",
    isHKConnect: 0,
  },
  {
    no: "8013",
    name_en: "ECI TECHNOLOGY HOLDINGS LIMITED",
    name_zh: "ECI TECHNOLOGY HOLDINGS LIMITED",
    isHKConnect: 0,
  },
  {
    no: "8017",
    name_en: "TRADEGO FINTECH LIMITED",
    name_zh: "捷利交易寶",
    isHKConnect: 0,
  },
  {
    no: "8018",
    name_en: "FINSOFT FINANCIAL INVESTMENT HOLDINGS LIMITED",
    name_zh: "匯財金融投資",
    isHKConnect: 0,
  },
  {
    no: "8019",
    name_en: "HAO WEN HOLDINGS LIMITED",
    name_zh: "皓文控股",
    isHKConnect: 0,
  },
  {
    no: "8020",
    name_en: "UNITAS HOLDINGS LIMITED",
    name_zh: "宏海控股集團",
    isHKConnect: 0,
  },
  {
    no: "8021",
    name_en: "WLS HOLDINGS LIMITED",
    name_zh: "匯隆控股",
    isHKConnect: 0,
  },
  {
    no: "8023",
    name_en: "KWONG MAN KEE GROUP LIMITED",
    name_zh: "鄺文記",
    isHKConnect: 0,
  },
  {
    no: "8025",
    name_en: "ASIAN CAPITAL RESOURCES (HOLDINGS) LIMITED",
    name_zh: "亞洲資產",
    isHKConnect: 0,
  },
  {
    no: "8026",
    name_en: "CHINA BRILLIANT GLOBAL LIMITED",
    name_zh: "朗華國際集團",
    isHKConnect: 0,
  },
  {
    no: "8027",
    name_en: "KPM HOLDING LIMITED",
    name_zh: "吉輝控股",
    isHKConnect: 0,
  },
  {
    no: "8028",
    name_en: "TIMELESS SOFTWARE LIMITED",
    name_zh: "天時軟件",
    isHKConnect: 0,
  },
  {
    no: "8029",
    name_en: "IMPERIUM FINANCIAL GROUP LIMITED",
    name_zh: "帝國金融集團",
    isHKConnect: 0,
  },
  {
    no: "8030",
    name_en: "FLYING FINANCIAL SERVICE HOLDINGS LIMITED",
    name_zh: "匯聯金融服務",
    isHKConnect: 0,
  },
  {
    no: "8031",
    name_en: "ETS GROUP LIMITED",
    name_zh: "易通訊集團",
    isHKConnect: 0,
  },
  {
    no: "8032",
    name_en: "VIVA CHINA HOLDINGS LIMITED",
    name_zh: "非凡中國",
    isHKConnect: 0,
  },
  {
    no: "8033",
    name_en: "VODATEL NETWORKS HOLDINGS LIMITED",
    name_zh: "愛達利網絡",
    isHKConnect: 0,
  },
  {
    no: "8035",
    name_en: "JANCO HOLDINGS LIMITED",
    name_zh: "駿高控股",
    isHKConnect: 0,
  },
  {
    no: "8036",
    name_en: "EBROKER GROUP LIMITED",
    name_zh: "電子交易集團",
    isHKConnect: 0,
  },
  {
    no: "8037",
    name_en: "CHINA BIOTECH SERVICES HOLDINGS LIMITED",
    name_zh: "中國生物科技服務",
    isHKConnect: 0,
  },
  {
    no: "8039",
    name_en: "KNK HOLDINGS LIMITED",
    name_zh: "中國卓銀",
    isHKConnect: 0,
  },
  {
    no: "8040",
    name_en: "COOLPOINT INNONISM HOLDING LIMITED",
    name_zh: "快意智能",
    isHKConnect: 0,
  },
  {
    no: "8041",
    name_en: "LUXEY INTERNATIONAL (HOLDINGS) LIMITED",
    name_zh: "薈萃國際(控股)",
    isHKConnect: 0,
  },
  {
    no: "8042",
    name_en: "KOS INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "高奧士國際",
    isHKConnect: 0,
  },
  {
    no: "8043",
    name_en: "ATLINKS GROUP LIMITED",
    name_zh: "ATLINKS GROUP LIMITED",
    isHKConnect: 0,
  },
  {
    no: "8045",
    name_en: "JIANGSU NANDASOFT TECHNOLOGY COMPANY LIMITED",
    name_zh: "南大蘇富特",
    isHKConnect: 0,
  },
  {
    no: "8047",
    name_en: "CHINA OCEAN GROUP DEVELOPMENT LIMITED",
    name_zh: "中國海洋發展",
    isHKConnect: 0,
  },
  {
    no: "8048",
    name_en: "YU TAK INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "御德國際控股",
    isHKConnect: 0,
  },
  {
    no: "8049",
    name_en: "JILIN PROVINCE HUINAN CHANGLONG BIO-PHARMACY COMPANY LIMITED",
    name_zh: "吉林長龍藥業",
    isHKConnect: 0,
  },
  {
    no: "8050",
    name_en: "QUANTUM THINKING LIMITED",
    name_zh: "量子思維",
    isHKConnect: 0,
  },
  {
    no: "8051",
    name_en: "CIRCUTECH INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "訊智海",
    isHKConnect: 0,
  },
  {
    no: "8052",
    name_en: "LUK HING ENTERTAINMENT GROUP HOLDINGS LIMITED",
    name_zh: "陸慶娛樂",
    isHKConnect: 0,
  },
  {
    no: "8053",
    name_en: "PIZU GROUP HOLDINGS LIMITED",
    name_zh: "比優集團",
    isHKConnect: 0,
  },
  {
    no: "8055",
    name_en: "CHINA E-INFORMATION TECHNOLOGY GROUP LIMITED",
    name_zh: "中國網絡信息科技",
    isHKConnect: 0,
  },
  {
    no: "8056",
    name_en: "LIFE CONCEPTS HOLDINGS LIMITED",
    name_zh: "生活概念",
    isHKConnect: 0,
  },
  {
    no: "8057",
    name_en: "MADISON HOLDINGS GROUP LIMITED",
    name_zh: "麥迪森控股",
    isHKConnect: 0,
  },
  {
    no: "8059",
    name_en: "GLORY FLAME HOLDINGS LIMITED",
    name_zh: "朝威控股",
    isHKConnect: 0,
  },
  {
    no: "8060",
    name_en: "GLOBAL LINK COMMUNICATIONS HOLDINGS LIMITED",
    name_zh: "國聯通信",
    isHKConnect: 0,
  },
  {
    no: "8062",
    name_en: "EFT SOLUTIONS HOLDINGS LIMITED",
    name_zh: "俊盟國際",
    isHKConnect: 0,
  },
  {
    no: "8063",
    name_en: "GLOBAL MASTERMIND HOLDINGS LIMITED",
    name_zh: "環球大通集團",
    isHKConnect: 0,
  },
  {
    no: "8065",
    name_en: "KML TECHNOLOGY GROUP LIMITED",
    name_zh: "高萌科技",
    isHKConnect: 0,
  },
  {
    no: "8066",
    name_en: "PHOENITRON HOLDINGS LIMITED",
    name_zh: "品創控股",
    isHKConnect: 0,
  },
  {
    no: "8067",
    name_en: "ORIENTAL UNIVERSITY CITY HOLDINGS (H.K.) LIMITED",
    name_zh: "東方大學城控股",
    isHKConnect: 0,
  },
  {
    no: "8069",
    name_en: "WWPKG HOLDINGS COMPANY LIMITED",
    name_zh: "縱橫遊控股",
    isHKConnect: 0,
  },
  {
    no: "8070",
    name_en: "KEEN OCEAN INTERNATIONAL HOLDING LIMITED",
    name_zh: "僑洋國際控股",
    isHKConnect: 0,
  },
  {
    no: "8071",
    name_en: "CHINA NETCOM TECHNOLOGY HOLDINGS LIMITED",
    name_zh: "中彩網通控股",
    isHKConnect: 0,
  },
  {
    no: "8072",
    name_en: "ROMA (META) GROUP LIMITED",
    name_zh: "羅馬元宇宙集團",
    isHKConnect: 0,
  },
  {
    no: "8073",
    name_en: "CHINA SINGYES NEW MATERIALS HOLDINGS LIMITED",
    name_zh: "興業新材料",
    isHKConnect: 0,
  },
  {
    no: "8075",
    name_en: "MEDIA ASIA GROUP HOLDINGS LIMITED",
    name_zh: "寰亞傳媒",
    isHKConnect: 0,
  },
  {
    no: "8076",
    name_en: "SING LEE SOFTWARE (GROUP) LIMITED",
    name_zh: "新利軟件",
    isHKConnect: 0,
  },
  {
    no: "8078",
    name_en: "CHINA CREATIVE DIGITAL ENTERTAINMENT LIMITED",
    name_zh: "中國創意數碼",
    isHKConnect: 0,
  },
  {
    no: "8079",
    name_en: "EASY REPAY FINANCE & INVESTMENT LIMITED",
    name_zh: "易還財務投資",
    isHKConnect: 0,
  },
  {
    no: "8080",
    name_en: "NORTH ASIA STRATEGIC HOLDINGS LIMITED",
    name_zh: "北亞策略",
    isHKConnect: 0,
  },
  {
    no: "8081",
    name_en: "HANG TAI YUE GROUP HOLDINGS LIMITED",
    name_zh: "恆泰裕集團",
    isHKConnect: 0,
  },
  {
    no: "8082",
    name_en: "YEAH YEAH GROUP HOLDINGS LIMITED",
    name_zh: "太陽娛樂集團",
    isHKConnect: 0,
  },
  {
    no: "8083",
    name_en: "CHINA YOUZAN LIMITED",
    name_zh: "中國有贊",
    isHKConnect: 0,
  },
  {
    no: "8086",
    name_en: "SINO VISION WORLDWIDE HOLDINGS LIMITED",
    name_zh: "新維國際控股",
    isHKConnect: 0,
  },
  {
    no: "8087",
    name_en: "CHINA 33 MEDIA GROUP LIMITED",
    name_zh: "中國三三傳媒",
    isHKConnect: 0,
  },
  {
    no: "8091",
    name_en: "OOH HOLDINGS LIMITED",
    name_zh: "奧傳思維控股",
    isHKConnect: 0,
  },
  {
    no: "8092",
    name_en: "ITE (HOLDINGS) LIMITED",
    name_zh: "ITE (HOLDINGS) LIMITED",
    isHKConnect: 0,
  },
  {
    no: "8093",
    name_en: "MILLION STARS HOLDINGS LIMITED",
    name_zh: "萬星控股",
    isHKConnect: 0,
  },
  {
    no: "8095",
    name_en: "BEIJING BEIDA JADE BIRD UNIVERSAL SCI-TECH COMPANY LIMITED",
    name_zh: "北大青鳥環宇",
    isHKConnect: 0,
  },
  {
    no: "8096",
    name_en: "TASTY CONCEPTS HOLDING LIMITED",
    name_zh: "賞之味",
    isHKConnect: 0,
  },
  {
    no: "8098",
    name_en: "CL GROUP (HOLDINGS) LIMITED",
    name_zh: "昌利控股",
    isHKConnect: 0,
  },
  {
    no: "8100",
    name_en: "GET HOLDINGS LIMITED",
    name_zh: "智易控股",
    isHKConnect: 0,
  },
  {
    no: "8101",
    name_en: "EJE (HONG KONG) HOLDINGS LIMITED",
    name_zh: "壹家壹品",
    isHKConnect: 0,
  },
  {
    no: "8103",
    name_en: "HMVOD LIMITED",
    name_zh: "HMVOD視頻",
    isHKConnect: 0,
  },
  {
    no: "8106",
    name_en: "SHENGHUA LANDE SCITECH LIMITED",
    name_zh: "升華蘭德",
    isHKConnect: 0,
  },
  {
    no: "8107",
    name_en: "VISION INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "威誠國際控股",
    isHKConnect: 0,
  },
  {
    no: "8109",
    name_en: "KIRIN GROUP HOLDINGS LIMITED",
    name_zh: "麒麟集團控股",
    isHKConnect: 0,
  },
  {
    no: "8111",
    name_en: "CHINA TECHNOLOGY INDUSTRY GROUP LIMITED",
    name_zh: "中國科技產業集團",
    isHKConnect: 0,
  },
  {
    no: "8112",
    name_en: "CORNERSTONE FINANCIAL HOLDINGS LIMITED",
    name_zh: "基石金融",
    isHKConnect: 0,
  },
  {
    no: "8113",
    name_en: "HI-LEVEL TECHNOLOGY HOLDINGS LIMITED",
    name_zh: "揚宇科技",
    isHKConnect: 0,
  },
  {
    no: "8115",
    name_en: "SHANGHAI QINGPU FIRE-FIGHTING EQUIPMENT CO., LTD.",
    name_zh: "上海青浦消防",
    isHKConnect: 0,
  },
  {
    no: "8117",
    name_en: "CHINA PRIMARY ENERGY HOLDINGS LIMITED",
    name_zh: "中國基礎能源",
    isHKConnect: 0,
  },
  {
    no: "8118",
    name_en: "BORTEX GLOBAL LIMITED",
    name_zh: "濠亮環球",
    isHKConnect: 0,
  },
  {
    no: "8120",
    name_en: "CHINA DEMETER FINANCIAL INVESTMENTS LIMITED",
    name_zh: "國農金融投資",
    isHKConnect: 0,
  },
  {
    no: "8121",
    name_en: "GURU ONLINE (HOLDINGS) LIMITED",
    name_zh: "超凡網絡",
    isHKConnect: 0,
  },
  {
    no: "8123",
    name_en: "SINOFORTUNE FINANCIAL HOLDINGS LIMITED",
    name_zh: "華億金控",
    isHKConnect: 0,
  },
  {
    no: "8125",
    name_en: "ROYAL CENTURY RESOURCES HOLDINGS LIMITED",
    name_zh: "仁德資源",
    isHKConnect: 0,
  },
  {
    no: "8126",
    name_en: "G. A. HOLDINGS LIMITED",
    name_zh: "Ｇ﹒Ａ﹒控股",
    isHKConnect: 0,
  },
  {
    no: "8128",
    name_en: "CHYY DEVELOPMENT GROUP LIMITED",
    name_zh: "中國恆有源集團",
    isHKConnect: 0,
  },
  {
    no: "8130",
    name_en: "DADI INTERNATIONAL GROUP LIMITED",
    name_zh: "大地國際集團",
    isHKConnect: 0,
  },
  {
    no: "8131",
    name_en: "ABC MULTIACTIVE LIMITED",
    name_zh: "辰罡科技",
    isHKConnect: 0,
  },
  {
    no: "8132",
    name_en: "CENTURY ENERGY INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "百能國際能源",
    isHKConnect: 0,
  },
  {
    no: "8133",
    name_en: "SOLOMON WORLDWIDE HOLDINGS LIMITED",
    name_zh: "所羅門環球控股",
    isHKConnect: 0,
  },
  {
    no: "8135",
    name_en: "ZMFY AUTOMOBILE GLASS SERVICES LIMITED",
    name_zh: "正美豐業",
    isHKConnect: 0,
  },
  {
    no: "8136",
    name_en: "IMS GROUP HOLDINGS LIMITED",
    name_zh: "英馬斯集團",
    isHKConnect: 0,
  },
  {
    no: "8137",
    name_en: "HONBRIDGE HOLDINGS LIMITED",
    name_zh: "洪橋集團",
    isHKConnect: 0,
  },
  {
    no: "8139",
    name_en: "ZHEJIANG CHANG'AN RENHENG TECHNOLOGY CO., LTD",
    name_zh: "長安仁恆",
    isHKConnect: 0,
  },
  {
    no: "8140",
    name_en: "BOSA TECHNOLOGY HOLDINGS LIMITED",
    name_zh: "人和科技",
    isHKConnect: 0,
  },
  {
    no: "8143",
    name_en: "GOOD FELLOW HEALTHCARE HOLDINGS LIMITED",
    name_zh: "金威醫療",
    isHKConnect: 0,
  },
  {
    no: "8146",
    name_en: "GRACE WINE HOLDINGS LIMITED",
    name_zh: "怡園酒業",
    isHKConnect: 0,
  },
  {
    no: "8147",
    name_en: "MILLENNIUM PACIFIC GROUP HOLDINGS LIMITED",
    name_zh: "匯思太平洋",
    isHKConnect: 0,
  },
  {
    no: "8148",
    name_en: "AURUM PACIFIC (CHINA) GROUP LIMITED",
    name_zh: "奧柏中國",
    isHKConnect: 0,
  },
  {
    no: "8149",
    name_en: "ALTUS HOLDINGS LIMITED",
    name_zh: "浩德控股",
    isHKConnect: 0,
  },
  {
    no: "8150",
    name_en: "SEAMLESS GREEN CHINA (HOLDINGS) LIMITED",
    name_zh: "無縫綠色",
    isHKConnect: 0,
  },
  {
    no: "8151",
    name_en: "BAO SHEN HOLDINGS LIMITED",
    name_zh: "寶申控股",
    isHKConnect: 0,
  },
  {
    no: "8152",
    name_en: "M&L HOLDINGS GROUP LIMITED",
    name_zh: "明樑控股",
    isHKConnect: 0,
  },
  {
    no: "8153",
    name_en: "FARNOVA GROUP HOLDINGS LIMITED -NEW",
    name_zh: "法諾集團(新)",
    isHKConnect: 0,
  },
  {
    no: "8156",
    name_en: "SINOPHARM TECH HOLDINGS LIMITED",
    name_zh: "國藥科技股份",
    isHKConnect: 0,
  },
  {
    no: "8158",
    name_en: "CHINA REGENERATIVE MEDICINE INTERNATIONAL LIMITED",
    name_zh: "中國再生醫學",
    isHKConnect: 0,
  },
  {
    no: "8159",
    name_en: "CHINA UNITED VENTURE INVESTMENT LIMITED",
    name_zh: "新華聯合投資",
    isHKConnect: 0,
  },
  {
    no: "8160",
    name_en: "GOLDWAY EDUCATION GROUP LIMITED",
    name_zh: "GOLDWAY EDUCATION GROUP LIMITED",
    isHKConnect: 0,
  },
  {
    no: "8161",
    name_en: "MEDINET GROUP LIMITED",
    name_zh: "醫匯集團",
    isHKConnect: 0,
  },
  {
    no: "8162",
    name_en: "LOCO HONG KONG HOLDINGS LIMITED",
    name_zh: "港銀控股",
    isHKConnect: 0,
  },
  {
    no: "8163",
    name_en: "MERDEKA FINANCIAL GROUP LIMITED",
    name_zh: "領智金融",
    isHKConnect: 0,
  },
  {
    no: "8166",
    name_en: "CHINA ECO-FARMING LIMITED",
    name_zh: "中國農業生態",
    isHKConnect: 0,
  },
  {
    no: "8167",
    name_en: "NEO TELEMEDIA LIMITED",
    name_zh: "中國新電信",
    isHKConnect: 0,
  },
  {
    no: "8168",
    name_en: "AMASSE CAPITAL HOLDINGS LIMITED",
    name_zh: "寶積資本",
    isHKConnect: 0,
  },
  {
    no: "8169",
    name_en: "ECO-TEK HOLDINGS LIMITED",
    name_zh: "環康集團",
    isHKConnect: 0,
  },
  {
    no: "8170",
    name_en: "CHINA ALL NATION INTERNATIONAL HOLDINGS GROUP LIMITED",
    name_zh: "全民國際",
    isHKConnect: 0,
  },
  {
    no: "8172",
    name_en: "LAJIN ENTERTAINMENT NETWORK GROUP LIMITED",
    name_zh: "拉近網娛",
    isHKConnect: 0,
  },
  {
    no: "8173",
    name_en: "HEPHAESTUS HOLDINGS LIMITED",
    name_zh: "客思控股",
    isHKConnect: 0,
  },
  {
    no: "8175",
    name_en: "CHINA DIGITAL CULTURE (GROUP) LIMITED",
    name_zh: "中國數碼文化",
    isHKConnect: 0,
  },
  {
    no: "8176",
    name_en: "SUPERROBOTICS HOLDINGS LIMITED",
    name_zh: "超人智能",
    isHKConnect: 0,
  },
  {
    no: "8178",
    name_en: "CHINA INFORMATION TECHNOLOGY DEVELOPMENT LIMITED",
    name_zh: "中國信息科技",
    isHKConnect: 0,
  },
  {
    no: "8179",
    name_en: "PALINDA GROUP HOLDINGS LIMITED",
    name_zh: "百利達集團控股",
    isHKConnect: 0,
  },
  {
    no: "8181",
    name_en: "SHI SHI SERVICES LIMITED",
    name_zh: "時時服務",
    isHKConnect: 0,
  },
  {
    no: "8186",
    name_en: "M-RESOURCES GROUP LIMITED",
    name_zh: "脈資資源",
    isHKConnect: 0,
  },
  {
    no: "8187",
    name_en: "JIMU GROUP LIMITED",
    name_zh: "積木集團",
    isHKConnect: 0,
  },
  {
    no: "8188",
    name_en: "GME GROUP HOLDINGS LIMITED",
    name_zh: "駿傑集團控股",
    isHKConnect: 0,
  },
  {
    no: "8189",
    name_en: "TIANJIN TEDA BIOMEDICAL ENGINEERING COMPANY LIMITED",
    name_zh: "泰達生物",
    isHKConnect: 0,
  },
  {
    no: "8191",
    name_en: "HONG WEI (ASIA) HOLDINGS COMPANY LIMITED",
    name_zh: "鴻偉亞洲",
    isHKConnect: 0,
  },
  {
    no: "8193",
    name_en: "ASIA-PAC FINANCIAL INVESTMENT COMPANY LIMITED",
    name_zh: "亞太金融投資",
    isHKConnect: 0,
  },
  {
    no: "8195",
    name_en: "LEGENDARY GROUP LIMITED",
    name_zh: "創天傳承",
    isHKConnect: 0,
  },
  {
    no: "8196",
    name_en: "CHINA TIANYF HOLDINGS GROUP LIMITED",
    name_zh: "中國天億福",
    isHKConnect: 0,
  },
  {
    no: "8198",
    name_en: "LOTO INTERACTIVE LIMITED",
    name_zh: "樂透互娛",
    isHKConnect: 0,
  },
  {
    no: "8200",
    name_en: "SAU SAN TONG HOLDINGS LIMITED",
    name_zh: "修身堂",
    isHKConnect: 0,
  },
  {
    no: "8201",
    name_en: "PPS INTERNATIONAL (HOLDINGS) LIMITED",
    name_zh: "寶聯控股",
    isHKConnect: 0,
  },
  {
    no: "8203",
    name_en: "KAISUN HOLDINGS LIMITED",
    name_zh: "凱順控股",
    isHKConnect: 0,
  },
  {
    no: "8205",
    name_en: "SHANGHAI JIAODA WITHUB INFORMATION INDUSTRIAL COMPANY LIMITED",
    name_zh: "交大慧谷",
    isHKConnect: 0,
  },
  {
    no: "8206",
    name_en: "SHENTONG ROBOT EDUCATION GROUP COMPANY LIMITED",
    name_zh: "神通機器人教育",
    isHKConnect: 0,
  },
  {
    no: "8208",
    name_en: "WMCH GLOBAL INVESTMENT LIMITED",
    name_zh: "WMCH GLOBAL INVESTMENT LIMITED",
    isHKConnect: 0,
  },
  {
    no: "8209",
    name_en: "GT STEEL CONSTRUCTION GROUP LIMITED WARRANTS 2023 AUGUST",
    name_zh: "GT STEEL CONSTRUCTION GROUP LIMITED WARRANTS 2023 AUGUST",
    isHKConnect: 0,
  },
  {
    no: "8210",
    name_en: "DLC ASIA LIMITED",
    name_zh: "衍匯亞洲",
    isHKConnect: 0,
  },
  {
    no: "8211",
    name_en: "ZHEJIANG YONGAN RONGTONG HOLDINGS CO., LTD.",
    name_zh: "浙江永安",
    isHKConnect: 0,
  },
  {
    no: "8213",
    name_en: "STARGLORY HOLDINGS COMPANY LIMITED",
    name_zh: "榮暉控股",
    isHKConnect: 0,
  },
  {
    no: "8215",
    name_en: "FIRST CREDIT FINANCE GROUP LIMITED",
    name_zh: "FIRST CREDIT FINANCE GROUP LIMITED",
    isHKConnect: 0,
  },
  {
    no: "8217",
    name_en: "LUEN WONG GROUP HOLDINGS LIMITED",
    name_zh: "聯旺集團",
    isHKConnect: 0,
  },
  {
    no: "8218",
    name_en: "ECHO INTERNATIONAL HOLDINGS GROUP LIMITED",
    name_zh: "毅高國際控股",
    isHKConnect: 0,
  },
  {
    no: "8219",
    name_en: "HANVEY GROUP HOLDINGS LIMITED",
    name_zh: "恆偉集團控股",
    isHKConnect: 0,
  },
  {
    no: "8220",
    name_en: "BINGO GROUP HOLDINGS LIMITED",
    name_zh: "比高集團",
    isHKConnect: 0,
  },
  {
    no: "8221",
    name_en: "PF GROUP HOLDINGS LIMITED",
    name_zh: "PF GROUP HOLDINGS LIMITED",
    isHKConnect: 0,
  },
  {
    no: "8222",
    name_en: "E LIGHTING GROUP HOLDINGS LIMITED",
    name_zh: "壹照明",
    isHKConnect: 0,
  },
  {
    no: "8223",
    name_en: "ZIYUANYUAN HOLDINGS GROUP LIMITED",
    name_zh: "紫元元",
    isHKConnect: 0,
  },
  {
    no: "8225",
    name_en: "CHINA HEALTH GROUP INC.",
    name_zh: "中國醫療集團",
    isHKConnect: 0,
  },
  {
    no: "8226",
    name_en: "KOALA FINANCIAL GROUP LIMITED",
    name_zh: "樹熊金融集團",
    isHKConnect: 0,
  },
  {
    no: "8227",
    name_en: "XI'AN HAITIAN ANTENNA TECHNOLOGIES CO., LTD.",
    name_zh: "海天天線",
    isHKConnect: 0,
  },
  {
    no: "8228",
    name_en: "NATIONAL ARTS GROUP HOLDINGS LIMITED",
    name_zh: "國藝集團控股",
    isHKConnect: 0,
  },
  {
    no: "8229",
    name_en: "FUTURE DATA GROUP LIMITED",
    name_zh: "FUTURE DATA GROUP LIMITED",
    isHKConnect: 0,
  },
  {
    no: "8232",
    name_en: "CLASSIFIED GROUP (HOLDINGS) LIMITED",
    name_zh: "CLASSIFIED GROUP (HOLDINGS) LIMITED",
    isHKConnect: 0,
  },
  {
    no: "8237",
    name_en: "LINK HOLDINGS LIMITED",
    name_zh: "華星控股",
    isHKConnect: 0,
  },
  {
    no: "8238",
    name_en: "WINTO GROUP (HOLDINGS) LIMITED",
    name_zh: "惠陶集團",
    isHKConnect: 0,
  },
  {
    no: "8239",
    name_en: "CAPITAL FINANCE HOLDINGS LIMITED",
    name_zh: "首都金融控股",
    isHKConnect: 0,
  },
  {
    no: "8241",
    name_en: "YING KEE TEA HOUSE GROUP LIMITED",
    name_zh: "英記茶莊集團",
    isHKConnect: 0,
  },
  {
    no: "8245",
    name_en: "ZHAO XIAN BUSINESS ECOLOGY INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "照現生態國際控股",
    isHKConnect: 0,
  },
  {
    no: "8246",
    name_en: "ZHONGHUA GAS HOLDINGS LIMITED",
    name_zh: "中華燃氣",
    isHKConnect: 0,
  },
  {
    no: "8247",
    name_en: "BIOSINO BIO-TECHNOLOGY AND SCIENCE INCORPORATION",
    name_zh: "中生北控生物科技",
    isHKConnect: 0,
  },
  {
    no: "8249",
    name_en: "ZHEJIANG RUIYUAN INTELLIGENT CONTROL TECHNOLOGY COMPANY LIMITED",
    name_zh: "瑞遠智控",
    isHKConnect: 0,
  },
  {
    no: "8250",
    name_en: "SILK ROAD ENERGY SERVICES GROUP LIMITED",
    name_zh: "絲路能源",
    isHKConnect: 0,
  },
  {
    no: "8255",
    name_en: "CHINA BINARY NEW FINTECH GROUP",
    name_zh: "神州數字",
    isHKConnect: 0,
  },
  {
    no: "8257",
    name_en: "GENES TECH GROUP HOLDINGS COMPANY LIMTED",
    name_zh: "靖洋集團",
    isHKConnect: 0,
  },
  {
    no: "8262",
    name_en: "SUPER STRONG HOLDINGS LIMITED",
    name_zh: "宏強控股",
    isHKConnect: 0,
  },
  {
    no: "8267",
    name_en: "LINEKONG INTERACTIVE GROUP CO., LTD.",
    name_zh: "藍港互動",
    isHKConnect: 0,
  },
  {
    no: "8268",
    name_en: "SMART CITY DEVELOPMENT HOLDINGS LIMITED",
    name_zh: "智城發展控股",
    isHKConnect: 0,
  },
  {
    no: "8269",
    name_en: "WEALTH GLORY HOLDINGS LIMITED",
    name_zh: "富譽控股",
    isHKConnect: 0,
  },
  {
    no: "8270",
    name_en: "CHINA CBM GROUP COMPANY LIMITED",
    name_zh: "中國煤層氣",
    isHKConnect: 0,
  },
  {
    no: "8271",
    name_en: "GLOBAL DIGITAL CREATIONS HOLDINGS LIMITED",
    name_zh: "環球數碼創意",
    isHKConnect: 0,
  },
  {
    no: "8275",
    name_en: "STATE INNOVATION HOLDINGS LIMITED",
    name_zh: "國科控股",
    isHKConnect: 0,
  },
  {
    no: "8277",
    name_en: "STEED ORIENTAL (HOLDINGS) COMPANY LIMITED",
    name_zh: "駿東控股",
    isHKConnect: 0,
  },
  {
    no: "8279",
    name_en: "AGTECH HOLDINGS LIMITED",
    name_zh: "亞博科技控股",
    isHKConnect: 0,
  },
  {
    no: "8280",
    name_en: "CHINA DIGITAL VIDEO HOLDINGS LIMITED",
    name_zh: "中國數字視頻",
    isHKConnect: 0,
  },
  {
    no: "8281",
    name_en: "CHINA GOLDEN CLASSIC GROUP LIMITED",
    name_zh: "中國金典集團",
    isHKConnect: 0,
  },
  {
    no: "8282",
    name_en: "GAMEONE HOLDINGS LIMITED",
    name_zh: "智傲控股",
    isHKConnect: 0,
  },
  {
    no: "8283",
    name_en: "ZHONGSHI MINAN HOLDINGS LIMITED",
    name_zh: "中食民安",
    isHKConnect: 0,
  },
  {
    no: "8285",
    name_en: "SLING GROUP HOLDINGS LIMITED",
    name_zh: "森浩集團",
    isHKConnect: 0,
  },
  {
    no: "8286",
    name_en: "SHANXI CHANGCHENG MICROLIGHT EQUIPMENT CO. LTD.",
    name_zh: "長城微光",
    isHKConnect: 0,
  },
  {
    no: "8287",
    name_en: "ZIONCOM HOLDINGS LIMITED",
    name_zh: "百家淘客",
    isHKConnect: 0,
  },
  {
    no: "8290",
    name_en: "AHSAY BACKUP SOFTWARE DEVELOPMENT COMPANY LIMITED",
    name_zh: "亞勢備份",
    isHKConnect: 0,
  },
  {
    no: "8291",
    name_en: "WAN CHENG METAL PACKAGING COMPANY LIMITED",
    name_zh: "萬成金屬包裝",
    isHKConnect: 0,
  },
  {
    no: "8292",
    name_en: "WORLDGATE GLOBAL LOGISTICS LTD",
    name_zh: "盛良物流",
    isHKConnect: 0,
  },
  {
    no: "8293",
    name_en: "SINGASIA HOLDINGS LIMITED",
    name_zh: "星亞控股",
    isHKConnect: 0,
  },
  {
    no: "8295",
    name_en: "KINGWISOFT TECHNOLOGY GROUP COMPANY LIMITED",
    name_zh: "金慧科技",
    isHKConnect: 0,
  },
  {
    no: "8296",
    name_en: "SINO-LIFE GROUP LIMITED",
    name_zh: "中國生命集團",
    isHKConnect: 0,
  },
  {
    no: "8297",
    name_en: "OCEAN STAR TECHNOLOGY GROUP LIMITED",
    name_zh: "海納星空科技",
    isHKConnect: 0,
  },
  {
    no: "8299",
    name_en: "GRAND T G GOLD HOLDINGS LIMITED",
    name_zh: "大唐潼金",
    isHKConnect: 0,
  },
  {
    no: "8300",
    name_en: "ROYAL CATERING GROUP HOLDINGS COMPANY LIMITED",
    name_zh: "皇璽餐飲集團",
    isHKConnect: 0,
  },
  {
    no: "8305",
    name_en: "TONG KEE (HOLDING) LIMITED",
    name_zh: "棠記控股",
    isHKConnect: 0,
  },
  {
    no: "8307",
    name_en: "MEDICSKIN HOLDINGS LIMITED",
    name_zh: "密迪斯肌",
    isHKConnect: 0,
  },
  {
    no: "8308",
    name_en: "GUDOU HOLDINGS LIMITED",
    name_zh: "古兜控股",
    isHKConnect: 0,
  },
  {
    no: "8309",
    name_en: "MAN SHING GLOBAL HOLDINGS LIMITED",
    name_zh: "萬成環球控股",
    isHKConnect: 0,
  },
  {
    no: "8310",
    name_en: "DAFENG PORT HESHUN TECHNOLOGY COMPANY LIMITED",
    name_zh: "大豐港",
    isHKConnect: 0,
  },
  {
    no: "8311",
    name_en: "PERFECT OPTRONICS LIMITED",
    name_zh: "圓美光電",
    isHKConnect: 0,
  },
  {
    no: "8313",
    name_en: "ZACD GROUP LTD.",
    name_zh: "杰地集團",
    isHKConnect: 0,
  },
  {
    no: "8315",
    name_en: "GREATWALLE INC.",
    name_zh: "長城匯理",
    isHKConnect: 0,
  },
  {
    no: "8316",
    name_en: "CHINA HONGBAO HOLDINGS LIMITED",
    name_zh: "中國紅包",
    isHKConnect: 0,
  },
  {
    no: "8317",
    name_en: "FINET GROUP LIMITED",
    name_zh: "財華社集團",
    isHKConnect: 0,
  },
  {
    no: "8319",
    name_en: "EXPERT SYSTEMS HOLDINGS LIMITED",
    name_zh: "思博系統",
    isHKConnect: 0,
  },
  {
    no: "8320",
    name_en:
      "ALLIED SUSTAINABILITY AND ENVIRONMENTAL CONSULTANTS GROUP LIMITED",
    name_zh: "沛然環保",
    isHKConnect: 0,
  },
  {
    no: "8321",
    name_en: "TAI KAM HOLDINGS LIMITED",
    name_zh: "泰錦控股",
    isHKConnect: 0,
  },
  {
    no: "8325",
    name_en: "CHINA SMARTPAY GROUP HOLDINGS LIMITED",
    name_zh: "中國支付通",
    isHKConnect: 0,
  },
  {
    no: "8326",
    name_en: "TONKING NEW ENERGY GROUP HOLDINGS LIMITED",
    name_zh: "同景新能源",
    isHKConnect: 0,
  },
  {
    no: "8328",
    name_en: "XINYI ELECTRIC STORAGE HOLDINGS LIMITED",
    name_zh: "信義儲電",
    isHKConnect: 1,
  },
  {
    no: "8329",
    name_en: "SHENZHEN NEPTUNUS INTERLONG BIO-TECHNIQUE COMPANY LIMITED",
    name_zh: "海王英特龍",
    isHKConnect: 0,
  },
  {
    no: "8331",
    name_en: "P.B. GROUP LIMITED",
    name_zh: "倍搏集團",
    isHKConnect: 0,
  },
  {
    no: "8333",
    name_en: "ASTRUM FINANCIAL HOLDINGS LIMITED",
    name_zh: "阿仕特朗金融",
    isHKConnect: 0,
  },
  {
    no: "8337",
    name_en: "DIRECTEL HOLDINGS LIMITED",
    name_zh: "直通電訊",
    isHKConnect: 0,
  },
  {
    no: "8340",
    name_en: "ZIJING INTERNATIONAL FINANCIAL HOLDINGS LIMITED",
    name_zh: "紫荊國際金融",
    isHKConnect: 0,
  },
  {
    no: "8341",
    name_en: "AESO HOLDING LIMITED",
    name_zh: "艾碩控股",
    isHKConnect: 0,
  },
  {
    no: "8347",
    name_en: "F8 ENTERPRISES (HOLDINGS) GROUP LIMITED",
    name_zh: "F8企業",
    isHKConnect: 0,
  },
  {
    no: "8348",
    name_en: "TIANJIN BINHAI TEDA LOGISTICS (GROUP) CORPORATION LIMITED",
    name_zh: "濱海泰達物流",
    isHKConnect: 0,
  },
  {
    no: "8349",
    name_en: "YUNHONG GUIXIN GROUP HOLDINGS LIMITED",
    name_zh: "硅鑫集團",
    isHKConnect: 0,
  },
  {
    no: "8350",
    name_en: "EXCALIBUR GLOBAL FINANCIAL HOLDINGS LIMITED",
    name_zh: "駿溢環球金融",
    isHKConnect: 0,
  },
  {
    no: "8353",
    name_en: "ANACLE SYSTEMS LIMITED",
    name_zh: "安科系統",
    isHKConnect: 0,
  },
  {
    no: "8356",
    name_en: "CNC HOLDINGS LIMITED",
    name_zh: "中國新華電視",
    isHKConnect: 0,
  },
  {
    no: "8357",
    name_en: "REPUBLIC HEALTHCARE LIMITED",
    name_zh: "REPUBLIC HEALTHCARE LIMITED",
    isHKConnect: 0,
  },
  {
    no: "8360",
    name_en: "AL GROUP LIMITED",
    name_zh: "利駿集團香港",
    isHKConnect: 0,
  },
  {
    no: "8362",
    name_en: "WINNING TOWER GROUP HOLDINGS LIMITED",
    name_zh: "運興泰集團",
    isHKConnect: 0,
  },
  {
    no: "8363",
    name_en: "SDM EDUCATION GROUP HOLDINGS LIMITED",
    name_zh: "SDM教育",
    isHKConnect: 0,
  },
  {
    no: "8365",
    name_en: "HATCHER GROUP LIMITED",
    name_zh: "亦辰集團",
    isHKConnect: 0,
  },
  {
    no: "8366",
    name_en: "ZHEJIANG UNITED INVESTMENT HOLDINGS GROUP LIMITED",
    name_zh: "浙江聯合投資",
    isHKConnect: 0,
  },
  {
    no: "8367",
    name_en: "SIMPLICITY HOLDING LIMITED",
    name_zh: "倩碧控股",
    isHKConnect: 0,
  },
  {
    no: "8368",
    name_en: "CREATIVE CHINA HOLDINGS LIMITED",
    name_zh: "中國創意控股",
    isHKConnect: 0,
  },
  {
    no: "8370",
    name_en: "ZHI SHENG GROUP HOLDINGS LIMITED",
    name_zh: "智昇集團控股",
    isHKConnect: 0,
  },
  {
    no: "8371",
    name_en: "TASTE.GOURMET GROUP LIMITED",
    name_zh: "嚐高美集團",
    isHKConnect: 0,
  },
  {
    no: "8372",
    name_en: "GRAND BRILLIANCE GROUP HOLDINGS LIMITED",
    name_zh: "君百延集團",
    isHKConnect: 0,
  },
  {
    no: "8373",
    name_en: "INDIGO STAR HOLDINGS LIMITED",
    name_zh: "靛藍星",
    isHKConnect: 0,
  },
  {
    no: "8375",
    name_en: "VERTICAL INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "弘浩國際控股",
    isHKConnect: 0,
  },
  {
    no: "8377",
    name_en: "SHEN YOU HOLDINGS LIMITED",
    name_zh: "申酉控股",
    isHKConnect: 0,
  },
  {
    no: "8379",
    name_en: "PRIME INTELLIGENCE SOLUTIONS GROUP LIMITED",
    name_zh: "懶豬科技",
    isHKConnect: 0,
  },
  {
    no: "8383",
    name_en: "LINOCRAFT HOLDINGS LIMITED",
    name_zh: "東駿控股",
    isHKConnect: 0,
  },
  {
    no: "8385",
    name_en: "PROSPEROUS PRINTING COMPANY LIMITED",
    name_zh: "萬里印刷",
    isHKConnect: 0,
  },
  {
    no: "8391",
    name_en: "CORNERSTONE TECHNOLOGIES HOLDINGS LIMITED",
    name_zh: "基石科技控股",
    isHKConnect: 0,
  },
  {
    no: "8392",
    name_en: "SATU HOLDINGS LIMITED",
    name_zh: "舍圖控股",
    isHKConnect: 0,
  },
  {
    no: "8395",
    name_en: "TREE HOLDINGS LIMITED",
    name_zh: "齊家控股",
    isHKConnect: 0,
  },
  {
    no: "8400",
    name_en: "ASIA PIONEER ENTERTAINMENT HOLDINGS LIMITED",
    name_zh: "亞洲先鋒娛樂",
    isHKConnect: 0,
  },
  {
    no: "8401",
    name_en: "STREAM IDEAS GROUP LIMITED",
    name_zh: "源想集團",
    isHKConnect: 0,
  },
  {
    no: "8402",
    name_en: "GT STEEL CONSTRUCTION GROUP LIMITED",
    name_zh: "GT STEEL CONSTRUCTION GROUP LIMITED",
    isHKConnect: 0,
  },
  {
    no: "8403",
    name_en: "DOWWAY HOLDINGS LIMITED",
    name_zh: "天平道合",
    isHKConnect: 0,
  },
  {
    no: "8405",
    name_en: "HANG CHI HOLDINGS LIMITED",
    name_zh: "恆智控股",
    isHKConnect: 0,
  },
  {
    no: "8406",
    name_en: "CHINA ORAL INDUSTRY GROUP HOLDINGS LIMITED",
    name_zh: "中國口腔產業",
    isHKConnect: 0,
  },
  {
    no: "8411",
    name_en: "K W NELSON INTERIOR DESIGN AND CONTRACTING GROUP LIMITED",
    name_zh: "K W NELSON INTERIOR DESIGN AND CONTRACTING GROUP LIMITED",
    isHKConnect: 0,
  },
  {
    no: "8412",
    name_en: "BCI GROUP HOLDINGS LIMITED",
    name_zh: "高門集團",
    isHKConnect: 0,
  },
  {
    no: "8413",
    name_en: "ASIA GROCERY DISTRIBUTION LIMITED",
    name_zh: "亞洲雜貨",
    isHKConnect: 0,
  },
  {
    no: "8416",
    name_en: "HM INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "HM INTERNATIONAL HOLDINGS LIMITED",
    isHKConnect: 0,
  },
  {
    no: "8417",
    name_en: "DADI EDUCATION HOLDINGS LIMITED",
    name_zh: "大地教育",
    isHKConnect: 0,
  },
  {
    no: "8418",
    name_en: "OPTIMA AUTOMOBILE GROUP HOLDINGS LIMITED",
    name_zh: "傲迪瑪汽車",
    isHKConnect: 0,
  },
  {
    no: "8419",
    name_en: "AV PROMOTIONS HOLDINGS LIMITED",
    name_zh: "ＡＶ策劃推廣",
    isHKConnect: 0,
  },
  {
    no: "8420",
    name_en: "NEXION TECHNOLOGIES LIMITED",
    name_zh: "NEXION TECHNOLOGIES LIMITED",
    isHKConnect: 0,
  },
  {
    no: "8422",
    name_en: "WT GROUP HOLDINGS LIMITED",
    name_zh: "WT集團",
    isHKConnect: 0,
  },
  {
    no: "8423",
    name_en: "CHI HO DEVELOPMENT HOLDINGS LIMITED",
    name_zh: "CHI HO DEVELOPMENT HOLDINGS LIMITED",
    isHKConnect: 0,
  },
  {
    no: "8425",
    name_en: "HING MING HOLDINGS LIMITED",
    name_zh: "興銘控股",
    isHKConnect: 0,
  },
  {
    no: "8426",
    name_en: "MODERN LIVING INVESTMENTS HOLDINGS LIMITED",
    name_zh: "雅居投資控股",
    isHKConnect: 0,
  },
  {
    no: "8427",
    name_en: "SK TARGET GROUP LIMITED",
    name_zh: "瑞強集團",
    isHKConnect: 0,
  },
  {
    no: "8428",
    name_en: "CBK HOLDINGS LIMITED",
    name_zh: "國茂控股",
    isHKConnect: 0,
  },
  {
    no: "8429",
    name_en: "SV VISION LIMITED",
    name_zh: "華美樂樂",
    isHKConnect: 0,
  },
  {
    no: "8430",
    name_en: "C&N HOLDINGS LIMITED",
    name_zh: "春能控股",
    isHKConnect: 0,
  },
  {
    no: "8431",
    name_en: "HAO BAI INTERNATIONAL (CAYMAN) LIMITED",
    name_zh: "浩柏國際",
    isHKConnect: 0,
  },
  {
    no: "8432",
    name_en: "BAR PACIFIC GROUP HOLDINGS LIMITED",
    name_zh: "太平洋酒吧",
    isHKConnect: 0,
  },
  {
    no: "8436",
    name_en: "TAKBO GROUP HOLDINGS LIMITED",
    name_zh: "德寶集團控股",
    isHKConnect: 0,
  },
  {
    no: "8437",
    name_en: "RMH HOLDINGS LIMITED",
    name_zh: "德斯控股",
    isHKConnect: 0,
  },
  {
    no: "8439",
    name_en: "SOMERLEY CAPITAL HOLDINGS LIMITED",
    name_zh: "新百利融資",
    isHKConnect: 0,
  },
  {
    no: "8445",
    name_en: "NOBLE ENGINEERING GROUP HOLDINGS LIMITED",
    name_zh: "怡康泰工程集團",
    isHKConnect: 0,
  },
  {
    no: "8446",
    name_en: "IN TECHNICAL PRODUCTIONS HOLDINGS LIMITED",
    name_zh: "IN TECHNICAL PRODUCTIONS HOLDINGS LIMITED",
    isHKConnect: 0,
  },
  {
    no: "8447",
    name_en: "MS CONCEPT LIMITED",
    name_zh: "MS CONCEPT LIMITED",
    isHKConnect: 0,
  },
  {
    no: "8448",
    name_en: "UNIVERSE PRINTSHOP HOLDINGS LIMITED",
    name_zh: "環球印館",
    isHKConnect: 0,
  },
  {
    no: "8450",
    name_en: "EDICO HOLDINGS LIMITED",
    name_zh: "鉅京控股",
    isHKConnect: 0,
  },
  {
    no: "8451",
    name_en: "SUNLIGHT (1977) HOLDINGS LIMITED",
    name_zh: "日光控股",
    isHKConnect: 0,
  },
  {
    no: "8452",
    name_en: "FY FINANCIAL (SHENZHEN) CO., LTD.",
    name_zh: "富銀融資股份",
    isHKConnect: 0,
  },
  {
    no: "8455",
    name_en: "LAI GROUP HOLDING COMPANY LIMITED",
    name_zh: "禮建德集團",
    isHKConnect: 0,
  },
  {
    no: "8456",
    name_en: "MANSION INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "民信國際控股",
    isHKConnect: 0,
  },
  {
    no: "8460",
    name_en: "BASETROPHY GROUP HOLDINGS LIMITED",
    name_zh: "基地錦標集團",
    isHKConnect: 0,
  },
  {
    no: "8462",
    name_en: "OMNIBRIDGE HOLDINGS LIMITED",
    name_zh: "中安控股集團",
    isHKConnect: 0,
  },
  {
    no: "8471",
    name_en: "REACH NEW HOLDINGS LIMITED",
    name_zh: "新達控股",
    isHKConnect: 0,
  },
  {
    no: "8472",
    name_en: "LAPCO HOLDINGS LIMITED",
    name_zh: "立高控股",
    isHKConnect: 0,
  },
  {
    no: "8473",
    name_en: "MI MING MART HOLDINGS LIMITED",
    name_zh: "彌明生活百貨",
    isHKConnect: 0,
  },
  {
    no: "8475",
    name_en: "K GROUP HOLDINGS LIMITED",
    name_zh: "千盛集團控股",
    isHKConnect: 0,
  },
  {
    no: "8476",
    name_en: "OCEAN ONE HOLDING LTD.",
    name_zh: "大洋環球控股",
    isHKConnect: 0,
  },
  {
    no: "8479",
    name_en: "JTF INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "金泰豐國際控股",
    isHKConnect: 0,
  },
  {
    no: "8480",
    name_en: "FURNIWEB HOLDINGS LIMITED",
    name_zh: "飛霓控股",
    isHKConnect: 0,
  },
  {
    no: "8481",
    name_en: "SHENGLONG SPLENDECOR INTERNATIONAL LIMITED",
    name_zh: "盛龍錦秀國際",
    isHKConnect: 0,
  },
  {
    no: "8482",
    name_en: "WAN LEADER INTERNATIONAL LIMITED",
    name_zh: "萬勵達",
    isHKConnect: 0,
  },
  {
    no: "8483",
    name_en: "MAX SIGHT GROUP HOLDINGS LIMITED",
    name_zh: "名仕快相",
    isHKConnect: 0,
  },
  {
    no: "8487",
    name_en: "ISP GLOBAL LIMITED",
    name_zh: "ISP GLOBAL LIMITED",
    isHKConnect: 0,
  },
  {
    no: "8489",
    name_en: "GRAND POWER LOGISTICS GROUP LIMITED",
    name_zh: "裕程物流",
    isHKConnect: 0,
  },
  {
    no: "8490",
    name_en: "NICHE-TECH SEMICONDUCTOR MATERIALS LIMITED",
    name_zh: "駿碼半導體",
    isHKConnect: 0,
  },
  {
    no: "8491",
    name_en: "COOL LINK (HOLDINGS) LIMITED",
    name_zh: "COOL LINK (HOLDINGS) LIMITED",
    isHKConnect: 0,
  },
  {
    no: "8493",
    name_en: "DRAGON KING GROUP HOLDINGS LIMITED",
    name_zh: "龍皇集團",
    isHKConnect: 0,
  },
  {
    no: "8495",
    name_en: "1957 & CO. (HOSPITALITY) LIMITED",
    name_zh: "1957 & CO. (HOSPITALITY) LIMITED",
    isHKConnect: 0,
  },
  {
    no: "8496",
    name_en: "GLOBAL DINING HOLDINGS LIMITED",
    name_zh: "環球美食控股",
    isHKConnect: 0,
  },
  {
    no: "8500",
    name_en: "ICON CULTURE GLOBAL COMPANY LIMITED",
    name_zh: "天泓文創",
    isHKConnect: 0,
  },
  {
    no: "8501",
    name_en: "SANBASE CORPORATION LIMITED",
    name_zh: "莊皇集團公司",
    isHKConnect: 0,
  },
  {
    no: "8502",
    name_en: "OCEAN LINE PORT DEVELOPMENT LIMITED",
    name_zh: "遠航港口",
    isHKConnect: 0,
  },
  {
    no: "8507",
    name_en: "I.CENTURY HOLDING LIMITED",
    name_zh: "愛世紀集團",
    isHKConnect: 0,
  },
  {
    no: "8509",
    name_en: "WINE'S LINK INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "威揚酒業控股",
    isHKConnect: 0,
  },
  {
    no: "8510",
    name_en: "TOP STANDARD CORPORATION",
    name_zh: "TOP STANDARD CORPORATION",
    isHKConnect: 0,
  },
  {
    no: "8511",
    name_en: "MIN FU INTERNATIONAL HOLDING LIMITED",
    name_zh: "民富國際",
    isHKConnect: 0,
  },
  {
    no: "8512",
    name_en: "HYFUSIN GROUP HOLDINGS LIMITED",
    name_zh: "凱富善集團控股",
    isHKConnect: 0,
  },
  {
    no: "8513",
    name_en: "IAG HOLDINGS LIMITED",
    name_zh: "官醞控股",
    isHKConnect: 0,
  },
  {
    no: "8516",
    name_en: "GRAND TALENTS GROUP HOLDINGS LIMITED",
    name_zh: "廣駿集團控股",
    isHKConnect: 0,
  },
  {
    no: "8519",
    name_en: "JIA GROUP HOLDINGS LIMITED",
    name_zh: "佳民集團",
    isHKConnect: 0,
  },
  {
    no: "8521",
    name_en: "ST INTERNATIONAL HOLDINGS COMPANY LIMITED",
    name_zh: "智紡國際控股",
    isHKConnect: 0,
  },
  {
    no: "8523",
    name_en: "SHEUNG MOON HOLDINGS LIMITED",
    name_zh: "常滿控股",
    isHKConnect: 0,
  },
  {
    no: "8525",
    name_en: "BAIYING HOLDINGS GROUP LIMITED",
    name_zh: "百應控股",
    isHKConnect: 0,
  },
  {
    no: "8526",
    name_en: "WING FUNG GROUP ASIA LIMITED",
    name_zh: "榮豐集團亞洲",
    isHKConnect: 0,
  },
  {
    no: "8527",
    name_en: "JLOGO HOLDINGS LIMITED",
    name_zh: "聚利寶控股",
    isHKConnect: 0,
  },
  {
    no: "8532",
    name_en: "POLYFAIR HOLDINGS LIMITED",
    name_zh: "寶發控股",
    isHKConnect: 0,
  },
  {
    no: "8535",
    name_en: "VISTAR HOLDINGS LIMITED",
    name_zh: "熒德控股",
    isHKConnect: 0,
  },
  {
    no: "8536",
    name_en: "TL NATURAL GAS HOLDINGS LIMITED",
    name_zh: "TL NATURAL GAS HOLDINGS LIMITED",
    isHKConnect: 0,
  },
  {
    no: "8537",
    name_en: "CHONG FAI JEWELLERY GROUP HOLDINGS COMPANY LIMITED",
    name_zh: "創輝珠寶",
    isHKConnect: 0,
  },
  {
    no: "8540",
    name_en: "VICTORY SECURITIES (HOLDINGS) COMPANY LIMITED",
    name_zh: "勝利證券",
    isHKConnect: 0,
  },
  {
    no: "8545",
    name_en: "AMUSE GROUP HOLDING LIMITED",
    name_zh: "佰悅集團",
    isHKConnect: 0,
  },
  {
    no: "8547",
    name_en: "PACIFIC LEGEND GROUP LIMITED",
    name_zh: "PACIFIC LEGEND GROUP LIMITED",
    isHKConnect: 0,
  },
  {
    no: "8551",
    name_en: "HMVOD LIMITED TEMPORARY COUNTER-6000",
    name_zh: "HMVOD-六千",
    isHKConnect: 0,
  },
  {
    no: "8553",
    name_en: "K GROUP HOLDINGS LIMITED -TEMPORARY COUNTER",
    name_zh: "千盛集團控股",
    isHKConnect: 0,
  },
  {
    no: "8598",
    name_en: "WAN CHENG METAL PACKAGING COMPANY LIMITED-OLD",
    name_zh: "萬成金屬包裝-舊",
    isHKConnect: 0,
  },
  {
    no: "8601",
    name_en: "BOLTEK HOLDINGS LIMITED",
    name_zh: "BOLTEK HOLDINGS LIMITED",
    isHKConnect: 0,
  },
  {
    no: "8603",
    name_en: "FAMEGLOW HOLDINGS LIMITED",
    name_zh: "亮晴控股",
    isHKConnect: 0,
  },
  {
    no: "8606",
    name_en: "KINETIX SYSTEMS HOLDINGS LIMITED",
    name_zh: "KINETIX SYSTEMS HOLDINGS LIMITED",
    isHKConnect: 0,
  },
  {
    no: "8607",
    name_en: "NARNIA (HONG KONG) GROUP COMPANY LIMITED",
    name_zh: "納尼亞集團",
    isHKConnect: 0,
  },
  {
    no: "8609",
    name_en: "EGGRICULTURE FOODS LTD.",
    name_zh: "永續農業",
    isHKConnect: 0,
  },
  {
    no: "8611",
    name_en: "MINDTELL TECHNOLOGY LIMITED",
    name_zh: "MINDTELL TECHNOLOGY LIMITED",
    isHKConnect: 0,
  },
  {
    no: "8612",
    name_en: "WORLD SUPER HOLDINGS LIMITED",
    name_zh: "維亮控股",
    isHKConnect: 0,
  },
  {
    no: "8613",
    name_en: "ORIENTAL PAYMENT GROUP HOLDINGS LIMITED",
    name_zh: "東方支付集團控股",
    isHKConnect: 0,
  },
  {
    no: "8616",
    name_en: "SUNRAY ENGINEERING GROUP LIMITED",
    name_zh: "新威工程集團",
    isHKConnect: 0,
  },
  {
    no: "8617",
    name_en: "BEST LINKING GROUP HOLDINGS LIMITED",
    name_zh: "永聯豐控股",
    isHKConnect: 0,
  },
  {
    no: "8619",
    name_en: "WAC HOLDINGS LIMITED",
    name_zh: "WAC HOLDINGS LIMITED",
    isHKConnect: 0,
  },
  {
    no: "8620",
    name_en: "ASIA-EXPRESS LOGISTICS HOLDINGS LIMITED",
    name_zh: "亞洲速運",
    isHKConnect: 0,
  },
  {
    no: "8621",
    name_en: "METROPOLIS CAPITAL HOLDINGS LIMITED",
    name_zh: "METROPOLIS CAPITAL HOLDINGS LIMITED",
    isHKConnect: 0,
  },
  {
    no: "8622",
    name_en: "HUAKANG BIOMEDICAL HOLDINGS COMPANY LIMITED",
    name_zh: "華康生物醫學",
    isHKConnect: 0,
  },
  {
    no: "8623",
    name_en: "CHINA SAFTOWER INTERNATIONAL HOLDING GROUP LIMITED",
    name_zh: "中國蜀塔",
    isHKConnect: 0,
  },
  {
    no: "8627",
    name_en: "ORANGE TOUR CULTURAL HOLDING LIMITED",
    name_zh: "旅橙文化",
    isHKConnect: 0,
  },
  {
    no: "8631",
    name_en: "SUN KONG HOLDINGS LIMITED",
    name_zh: "申港控股",
    isHKConnect: 0,
  },
  {
    no: "8635",
    name_en: "NOVACON TECHNOLOGY GROUP LIMITED",
    name_zh: "連成科技集團",
    isHKConnect: 0,
  },
  {
    no: "8645",
    name_en: "MICHONG METAVERSE (CHINA) HOLDINGS GROUP LIMITED",
    name_zh: "米虫元宇宙",
    isHKConnect: 0,
  },
  {
    no: "8646",
    name_en: "CHINA HONGGUANG HOLDINGS LIMITED",
    name_zh: "中國宏光",
    isHKConnect: 0,
  },
  {
    no: "8657",
    name_en: "TRUE PARTNER CAPITAL HOLDING LIMITED",
    name_zh: "TRUE PARTNER CAPITAL HOLDING LIMITED",
    isHKConnect: 0,
  },
  {
    no: "8659",
    name_en: "YIK WO INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "易和國際控股",
    isHKConnect: 0,
  },
  {
    no: "8668",
    name_en: "YING HAI GROUP HOLDINGS COMPANY LIMITED",
    name_zh: "瀛海集團",
    isHKConnect: 0,
  },
  {
    no: "9001",
    name_en: "PREMIA CHINA USD PROPERTY BOND ETF -USD TRADED UNITS",
    name_zh: "PP中地美債-U",
    isHKConnect: 0,
  },
  {
    no: "9010",
    name_en: "ISHARES CORE MSCI ASIA EX JAPAN ETF -USD COUNTER",
    name_zh: "安碩亞洲除日-U",
    isHKConnect: 0,
  },
  {
    no: "9011",
    name_en: "ICBC CICC USD MONEY MARKET ETF -USD TRADED UNITS",
    name_zh: "A工銀中金美-U",
    isHKConnect: 0,
  },
  {
    no: "9022",
    name_en: "ISHARES MSCI EMERGING MARKETS ETF (HK)- USD TRADED UNITS",
    name_zh: "安碩新興市場-Ｕ",
    isHKConnect: 0,
  },
  {
    no: "9031",
    name_en: "HAITONG MSCI CHINA A ESG ETF - USD TRADED UNITS",
    name_zh: "海通AESG-U",
    isHKConnect: 0,
  },
  {
    no: "9047",
    name_en: "SSIF DCE IRON ORE FUTURES INDEX ETF -USD TRADED UNITS",
    name_zh: "F山證鐵礦石-U",
    isHKConnect: 0,
  },
  {
    no: "9060",
    name_en: "CICC CARBON FUTURES ETF- USD TRADED UNITS",
    name_zh: "F中金碳期貨-U",
    isHKConnect: 0,
  },
  {
    no: "9067",
    name_en: "ISHARES HANG SENG TECH ETF - USD TRADED UNITS",
    name_zh: "安碩恆生科技-U",
    isHKConnect: 0,
  },
  {
    no: "9072",
    name_en: "NIKKO AM GLOBAL INTERNET ETF-USD TRADED UNITS",
    name_zh: "日興環球聯網-U",
    isHKConnect: 0,
  },
  {
    no: "9074",
    name_en: "ISHARES CORE MSCI TAIWAN ETF - USD TRADED UNITS",
    name_zh: "安碩ＭＳ台灣－Ｕ",
    isHKConnect: 0,
  },
  {
    no: "9077",
    name_en: "PREMIA US TREASURY FLOATING RATE ETF -USD TRADED UNITS",
    name_zh: "PP美國庫-U",
    isHKConnect: 0,
  },
  {
    no: "9078",
    name_en: "PREMIA US TREASURY FLOATING RATE ETF- ACCUMULATING UNIT CLASS",
    name_zh: "PP美國庫A-U",
    isHKConnect: 0,
  },
  {
    no: "9081",
    name_en: "VALUE GOLD ETF-USD TRADED UNITS",
    name_zh: "價值黃金-U",
    isHKConnect: 0,
  },
  {
    no: "9086",
    name_en: "CHINAAMC NASDAQ 100 ETF -USD TRADED UNITS",
    name_zh: "華夏納指-U",
    isHKConnect: 0,
  },
  {
    no: "9088",
    name_en: "CHINAAMC HANG SENG TECH INDEX ETF -USD TRADED UNITS",
    name_zh: "華夏恆生科技-U",
    isHKConnect: 0,
  },
  {
    no: "9091",
    name_en: "NIKKOAM METAVERSE THEME ACTIVE ETF -USD TRADED UNITS",
    name_zh: "A日興元宇宙-U",
    isHKConnect: 0,
  },
  {
    no: "9096",
    name_en: "CSOP US DOLLAR MONEY MARKET ETF -USD TRADED UNITS",
    name_zh: "A南方美元-U",
    isHKConnect: 0,
  },
  {
    no: "9115",
    name_en: "ISHARES CORE HANG SENG INDEX ETF -USD TRADED UNITS",
    name_zh: "安碩恆生指數-U",
    isHKConnect: 0,
  },
  {
    no: "9125",
    name_en:
      "ISHARES SHORT DURATION CHINA POLICY BANK BOND ETF-USD TRADED UNITS",
    name_zh: "安碩短期政銀-U",
    isHKConnect: 0,
  },
  {
    no: "9141",
    name_en: "CHINAAMC ASIA USD INVESTMENT GRADE BOND ETF-USD TRADED UNITS",
    name_zh: "華夏亞投債-U",
    isHKConnect: 0,
  },
  {
    no: "9151",
    name_en: "PREMIA CHINA STAR50 ETF-USD TRADED UNITS",
    name_zh: "PP科創50-U",
    isHKConnect: 0,
  },
  {
    no: "9167",
    name_en: "ICBC CSOP S&P NEW CHINA SECTORS ETF -USD TRADED UNITS",
    name_zh: "工銀南方中國-U",
    isHKConnect: 0,
  },
  {
    no: "9173",
    name_en: "PREMIA CSI CAIXIN CHINA NEW ECONOMY ETF-USD TRADED UNITS",
    name_zh: "PP中新經濟-U",
    isHKConnect: 0,
  },
  {
    no: "9177",
    name_en:
      "PREMIA CHINA TREASURY AND POLICY BANK BOND LONG DURATION ETF - USD HEDGED UNIT",
    name_zh: "PP國債對沖-U",
    isHKConnect: 0,
  },
  {
    no: "9181",
    name_en:
      "PREMIA ASIA INNOVATIVE TECHNOLOGY AND METAVERSE THEME ETF-USD TRADED UNITS",
    name_zh: "ＰＰ亞洲創科-Ｕ",
    isHKConnect: 0,
  },
  {
    no: "9186",
    name_en: "CICC KRANESHARES CSI CHINA INTERNET INDEX ETF-USD TRADED UNITS",
    name_zh: "中金金瑞中網－Ｕ",
    isHKConnect: 0,
  },
  {
    no: "9187",
    name_en: "SAMSUNG S&P HIGH DIVIDEND APAC EX NZ REITS ETF-USD TRADED UNITS",
    name_zh: "三星高息房託-U",
    isHKConnect: 0,
  },
  {
    no: "9191",
    name_en: "GLOBAL X CHINA SEMICONDUCTOR ETF - USD TRADED SHARES",
    name_zh: "GX中國半導-U",
    isHKConnect: 0,
  },
  {
    no: "9600",
    name_en: "NEWLINK TECHNOLOGY INC.",
    name_zh: "新紐科技",
    isHKConnect: 0,
  },
  {
    no: "9608",
    name_en: "SUNDY SERVICE GROUP CO. LTD",
    name_zh: "宋都服務",
    isHKConnect: 0,
  },
  {
    no: "9616",
    name_en: "NEUSOFT EDUCATION TECHNOLOGY CO. LIMITED",
    name_zh: "東軟教育",
    isHKConnect: 0,
  },
  {
    no: "9618",
    name_en: "JD.COM, INC.",
    name_zh: "京東集團-SW",
    isHKConnect: 1,
  },
  {
    no: "9626",
    name_en: "BILIBILI INC.",
    name_zh: "嗶哩嗶哩-Ｗ",
    isHKConnect: 0,
  },
  {
    no: "9633",
    name_en: "NONGFU SPRING CO., LTD.",
    name_zh: "農夫山泉",
    isHKConnect: 1,
  },
  { no: "9638", name_en: "FERRETTI S.P.A.", name_zh: "法拉帝", isHKConnect: 0 },
  {
    no: "9666",
    name_en: "JINKE SMART SERVICES GROUP CO., LTD.",
    name_zh: "金科服務",
    isHKConnect: 1,
  },
  {
    no: "9668",
    name_en: "CHINA BOHAI BANK CO., LTD.",
    name_zh: "渤海銀行",
    isHKConnect: 1,
  },
  {
    no: "9677",
    name_en: "WEIHAI CITY COMMERCIAL BANK CO., LTD.",
    name_zh: "威海銀行",
    isHKConnect: 0,
  },
  {
    no: "9688",
    name_en: "ZAI LAB LIMITED",
    name_zh: "再鼎醫藥-B",
    isHKConnect: 1,
  },
  {
    no: "9696",
    name_en: "TIANQI LITHIUM CORPORATION",
    name_zh: "天齊鋰業",
    isHKConnect: 1,
  },
  {
    no: "9698",
    name_en: "GDS HOLDINGS LIMITED",
    name_zh: "萬國數據-SW",
    isHKConnect: 0,
  },
  {
    no: "9699",
    name_en: "HANGZHOU SF INTRA-CITY INDUSTRIAL CO., LTD.",
    name_zh: "順豐同城",
    isHKConnect: 0,
  },
  {
    no: "9801",
    name_en: "ISHARES CORE MSCI CHINA ETF -USD TRADED UNIT",
    name_zh: "安碩中國-U",
    isHKConnect: 0,
  },
  {
    no: "9804",
    name_en: "PREMIA MSCI VIETNAM ETF-USD TRADED UNITS",
    name_zh: "PP越南-U",
    isHKConnect: 0,
  },
  {
    no: "9806",
    name_en: "GLOBAL X CHINA CONSUMER BRAND ETF - USD TRADED SHARES",
    name_zh: "GX中國消費-U",
    isHKConnect: 0,
  },
  {
    no: "9807",
    name_en: "GLOBAL X CHINA ROBOTICS AND AI ETF - USD TRADED SHARES",
    name_zh: "GX中國機智-U",
    isHKConnect: 0,
  },
  {
    no: "9809",
    name_en: "GLOBAL X CHINA CLEAN ENERGY ETF - USD TRADED SHARES",
    name_zh: "GX中國潔能-U",
    isHKConnect: 0,
  },
  {
    no: "9810",
    name_en: "PREMIA DOW JONES EMERGING ASEAN TITANS 100 ETF-USD TRADED UNITS",
    name_zh: "ＰＰ新興東盟-Ｕ",
    isHKConnect: 0,
  },
  {
    no: "9812",
    name_en: "SAMSUNG CSI CHINA DRAGON INTERNET ETF - USD TRADED UNIT",
    name_zh: "三星中國龍網-U",
    isHKConnect: 0,
  },
  {
    no: "9814",
    name_en: "SAMSUNG NYSE FANG+ETF -USD TRADED UNITS",
    name_zh: "三星FANG-U",
    isHKConnect: 0,
  },
  {
    no: "9820",
    name_en: "GLOBAL X CHINA BIOTECH ETF -USD TRADED UNITS",
    name_zh: "GX中國生科-U",
    isHKConnect: 0,
  },
  {
    no: "9826",
    name_en: "GLOBAL X CHINA CLOUD COMPUTING ETF -USD TRADED UNITS",
    name_zh: "GX中國雲算-U",
    isHKConnect: 0,
  },
  {
    no: "9829",
    name_en: "ISHARES CHINA GOVERNMENT BOND ETF- USD TRADED UNITS",
    name_zh: "安碩中國國債-U",
    isHKConnect: 0,
  },
  {
    no: "9834",
    name_en: "ISHARES NASDAQ 100 ETF - USD TRADED UNITS",
    name_zh: "安碩納指一百－Ｕ",
    isHKConnect: 0,
  },
  {
    no: "9836",
    name_en: "ISHARES CORE S&P BSE SENSEX INDIA ETF -USD COUNTER",
    name_zh: "安碩印度-U",
    isHKConnect: 0,
  },
  {
    no: "9839",
    name_en: "CHINAAMC MSCI CHINA A 50 CONNECT ETF- USD TRADED UNITS",
    name_zh: "華夏A50-U",
    isHKConnect: 0,
  },
  {
    no: "9845",
    name_en: "GLOBAL X CHINA ELECTRIC VEHICLE ETF- USD TRADED SHARES",
    name_zh: "GX中國電車-U",
    isHKConnect: 0,
  },
  {
    no: "9846",
    name_en: "ISHARES CORE CSI 300 ETF -USD COUNTER",
    name_zh: "安碩滬深三百-U",
    isHKConnect: 0,
  },
  {
    no: "9857",
    name_en: "LINMON MEDIA LIMITED",
    name_zh: "檸萌影視",
    isHKConnect: 0,
  },
  {
    no: "9858",
    name_en: "CHINA YOURAN DAIRY GROUP LIMITED",
    name_zh: "優然牧業",
    isHKConnect: 1,
  },
  {
    no: "9863",
    name_en: "ZHEJIANG LEAPMOTOR TECHNOLOGY CO., LTD.",
    name_zh: "零跑汽車",
    isHKConnect: 0,
  },
  { no: "9866", name_en: "NIO INC.", name_zh: "蔚來-SW", isHKConnect: 0 },
  { no: "9868", name_en: "XPENG INC.", name_zh: "小鵬汽車-W", isHKConnect: 1 },
  {
    no: "9869",
    name_en: "HELENS INTERNATIONAL HOLDINGS COMPANY LIMITED",
    name_zh: "海倫司",
    isHKConnect: 1,
  },
  {
    no: "9877",
    name_en: "JENSCARE SCIENTIFIC CO., LTD.",
    name_zh: "健世科技-B",
    isHKConnect: 0,
  },
  {
    no: "9878",
    name_en: "HUITONGDA NETWORK CO., LTD.",
    name_zh: "匯通達網絡",
    isHKConnect: 1,
  },
  {
    no: "9886",
    name_en: "DINGDANG HEALTH TECHNOLOGY GROUP LTD.",
    name_zh: "叮噹健康",
    isHKConnect: 0,
  },
  {
    no: "9888",
    name_en: "BAIDU, INC.",
    name_zh: "百度集團-SW",
    isHKConnect: 0,
  },
  {
    no: "9889",
    name_en: "DONGGUAN RURAL COMMERCIAL BANK CO., LTD.",
    name_zh: "東莞農商銀行",
    isHKConnect: 0,
  },
  {
    no: "9896",
    name_en: "MINISO GROUP HOLDING LIMITED",
    name_zh: "名創優品",
    isHKConnect: 0,
  },
  {
    no: "9898",
    name_en: "WEIBO CORPORATION",
    name_zh: "微博-SW",
    isHKConnect: 0,
  },
  {
    no: "9899",
    name_en: "CLOUD MUSIC INC.",
    name_zh: "CLOUD MUSIC INC.",
    isHKConnect: 1,
  },
  {
    no: "9900",
    name_en: "GAIN PLUS HOLDINGS LIMITED",
    name_zh: "德益控股",
    isHKConnect: 0,
  },
  {
    no: "9901",
    name_en: "NEW ORIENTAL EDUCATION & TECHNOLOGY GROUP INC.",
    name_zh: "新東方-S",
    isHKConnect: 0,
  },
  {
    no: "9906",
    name_en: "HONLIV HEALTHCARE MANAGEMENT GROUP COMPANY LIMITED",
    name_zh: "宏力醫療管理",
    isHKConnect: 0,
  },
  {
    no: "9908",
    name_en: "JIAXING GAS GROUP CO., LTD.",
    name_zh: "嘉興燃氣",
    isHKConnect: 0,
  },
  {
    no: "9909",
    name_en: "POWERLONG COMMERCIAL MANAGEMENT HOLDINGS LIMITED",
    name_zh: "寶龍商業",
    isHKConnect: 1,
  },
  {
    no: "9911",
    name_en: "NEWBORN TOWN INC.",
    name_zh: "赤子城科技",
    isHKConnect: 0,
  },
  {
    no: "9913",
    name_en: "CHI KAN HOLDINGS LIMITED",
    name_zh: "智勤控股",
    isHKConnect: 0,
  },
  {
    no: "9916",
    name_en: "XINGYE WULIAN SERVICE GROUP CO. LTD.",
    name_zh: "興業物聯",
    isHKConnect: 0,
  },
  {
    no: "9918",
    name_en: "WISE ALLY INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "麗年國際",
    isHKConnect: 0,
  },
  {
    no: "9919",
    name_en: "ACTIVATION GROUP HOLDINGS LIMITED",
    name_zh: "艾德韋宣集團",
    isHKConnect: 0,
  },
  {
    no: "9922",
    name_en: "JIUMAOJIU INTERNATIONAL HOLDINGS LIMITED",
    name_zh: "九毛九",
    isHKConnect: 1,
  },
  { no: "9923", name_en: "YEAHKA LIMITED", name_zh: "移卡", isHKConnect: 1 },
  { no: "9926", name_en: "AKESO, INC.", name_zh: "康方生物-B", isHKConnect: 1 },
  {
    no: "9928",
    name_en: "TIMES NEIGHBORHOOD HOLDINGS LIMITED",
    name_zh: "時代鄰里",
    isHKConnect: 1,
  },
  {
    no: "9929",
    name_en: "SEM HOLDINGS LIMITED",
    name_zh: "澳達控股",
    isHKConnect: 0,
  },
  {
    no: "9933",
    name_en: "GHW INTERNATIONAL",
    name_zh: "GHW INTERNATIONAL",
    isHKConnect: 0,
  },
  {
    no: "9936",
    name_en: "XIMEI RESOURCES HOLDING LIMITED",
    name_zh: "稀美資源",
    isHKConnect: 0,
  },
  {
    no: "9938",
    name_en: "WAH WO HOLDINGS GROUP LIMITED",
    name_zh: "華和控股",
    isHKConnect: 0,
  },
  {
    no: "9939",
    name_en: "KINTOR PHARMACEUTICAL LIMITED",
    name_zh: "開拓藥業-B",
    isHKConnect: 1,
  },
  {
    no: "9955",
    name_en: "CLOUDR GROUP LIMITED",
    name_zh: "智雲健康",
    isHKConnect: 0,
  },
  {
    no: "9956",
    name_en: "ANE (CAYMAN) INC.",
    name_zh: "安能物流",
    isHKConnect: 1,
  },
  {
    no: "9958",
    name_en: "LITIAN PICTURES HOLDINGS LIMITED",
    name_zh: "力天影業",
    isHKConnect: 0,
  },
  {
    no: "9959",
    name_en: "LINKLOGIS INC.",
    name_zh: "聯易融科技-W",
    isHKConnect: 1,
  },
  {
    no: "9960",
    name_en: "KINDSTAR GLOBALGENE TECHNOLOGY, INC.",
    name_zh: "康聖環球",
    isHKConnect: 0,
  },
  {
    no: "9961",
    name_en: "TRIP.COM GROUP LIMITED",
    name_zh: "攜程集團-S",
    isHKConnect: 0,
  },
  {
    no: "9963",
    name_en: "TRANSTECH OPTELECOM SCIENCE HOLDINGS LIMITED",
    name_zh: "高科橋",
    isHKConnect: 0,
  },
  {
    no: "9966",
    name_en: "ALPHAMAB ONCOLOGY",
    name_zh: "康寧傑瑞製藥-B",
    isHKConnect: 1,
  },
  {
    no: "9968",
    name_en: "HUIJING HOLDINGS COMPANY LIMITED",
    name_zh: "匯景控股",
    isHKConnect: 1,
  },
  {
    no: "9969",
    name_en: "INNOCARE PHARMA LIMITED",
    name_zh: "諾誠健華-Ｂ",
    isHKConnect: 1,
  },
  {
    no: "9977",
    name_en: "SHANDONG FENGXIANG CO., LTD.",
    name_zh: "鳳祥股份",
    isHKConnect: 0,
  },
  {
    no: "9978",
    name_en: "FINELAND LIVING SERVICES GROUP LIMITED",
    name_zh: "方圓生活服務",
    isHKConnect: 0,
  },
  {
    no: "9979",
    name_en: "GREENTOWN MANAGEMENT HOLDINGS COMPANY LIMITED",
    name_zh: "綠城管理控股",
    isHKConnect: 1,
  },
  {
    no: "9982",
    name_en: "CENTRAL CHINA MANAGEMENT COMPANY LIMITED",
    name_zh: "中原建業",
    isHKConnect: 1,
  },
  {
    no: "9983",
    name_en: "CENTRAL CHINA NEW LIFE LIMITED",
    name_zh: "建業新生活",
    isHKConnect: 1,
  },
  {
    no: "9986",
    name_en: "DASHAN EDUCATION HOLDINGS LIMITED",
    name_zh: "大山教育",
    isHKConnect: 0,
  },
  {
    no: "9987",
    name_en: "YUM CHINA HOLDINGS, INC.",
    name_zh: "百勝中國",
    isHKConnect: 1,
  },
  {
    no: "9988",
    name_en: "ALIBABA GROUP HOLDING LIMITED",
    name_zh: "阿里巴巴-SW",
    isHKConnect: 0,
  },
  {
    no: "9989",
    name_en: "SHENZHEN HEPALINK PHARMACEUTICAL GROUP CO., LTD.",
    name_zh: "海普瑞",
    isHKConnect: 1,
  },
  {
    no: "9990",
    name_en: "ARCHOSAUR GAMES INC.",
    name_zh: "祖龍娛樂",
    isHKConnect: 1,
  },
  {
    no: "9991",
    name_en: "BAOZUN INC.",
    name_zh: "寶尊電商-SW",
    isHKConnect: 0,
  },
  {
    no: "9992",
    name_en: "POP MART INTERNATIONAL GROUP LIMITED",
    name_zh: "泡泡瑪特",
    isHKConnect: 1,
  },
  {
    no: "9993",
    name_en: "RADIANCE HOLDINGS (GROUP) COMPANY LIMITED",
    name_zh: "金輝控股",
    isHKConnect: 1,
  },
  {
    no: "9995",
    name_en: "REMEGEN CO., LTD.",
    name_zh: "榮昌生物－Ｂ",
    isHKConnect: 1,
  },
  {
    no: "9996",
    name_en: "PEIJIA MEDICAL LIMITED",
    name_zh: "沛嘉醫療-B",
    isHKConnect: 1,
  },
  {
    no: "9997",
    name_en: "KANGJI MEDICAL HOLDINGS LIMITED",
    name_zh: "康基醫療",
    isHKConnect: 1,
  },
  {
    no: "9998",
    name_en: "KWAN YONG HOLDINGS LIMITED",
    name_zh: "光榮控股",
    isHKConnect: 0,
  },
  { no: "9999", name_en: "NETEASE, INC.", name_zh: "網易-S", isHKConnect: 0 },
];
export default source;
