import React, { useState } from 'react'
import { Container, Dimmer, Header, Loader, Segment } from 'semantic-ui-react'
import { FirebaseLoginCard } from 'components/auth/FirebaseLoginForm'
import FirebaseLogoutButton from 'components/auth/FirebaseLogoutForm'
import { useLocation } from 'react-router-dom'
import MetaTags from 'react-meta-tags';
import { SubscriptionPlans } from 'components/widget/SubscriptionWidget'
import MySubscription from 'components/widget/MySubscription'
import { subscribeProfessionalPlan } from 'components/subscription/subscribe'
import { db } from 'config/fbConfig'
import { collection, doc, getDocs, query, where } from 'firebase/firestore'
import { useUser, useIsLoggedIn, useIsLoadingSubscriptions, useIsSubscribed, useSubscriptions } from 'features/user/userSlice'

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const MyAccount = () => {
  const urlQuery = useQuery()
  const redirectUrl = urlQuery.get('redirectUrl')
  const user = useUser()
  const subscriptions = useSubscriptions()
  const isLoggedIn = useIsLoggedIn()
  const isLoadingSubscription = useIsLoadingSubscriptions()
  const isSubscribed = useIsSubscribed()

  // this is used when redirecting from subscription page
  const [isLoading, setLoading] = useState(false)
  let onLogin = urlQuery.get('onLogin')
  let onLoginCallback;
  let onLoginTitle;
  if (onLogin === 'subscribe') {
    onLoginTitle = <h3 style={{ textAlign: "center"}}>多謝訂閱，登入後會自動前往付款頁面</h3>
    onLoginCallback = async (user) => {
      const customersRef = doc(db, 'customers', user.uid)
      const subRef = collection(customersRef, 'subscriptions')
      const q = query(subRef, where('status', 'in', ['trialing', 'active']))
      const subscriptions = await getDocs(q)
      if (subscriptions.empty) {
        // user has not subscribed
        subscribeProfessionalPlan(user.uid)
        setLoading(true)
      }
    }
  }

  let subView;
  if (isLoadingSubscription) {
    subView = <Loader active inline='centered' > Loading </Loader>
  } else if (isSubscribed) {
    subView = <MySubscription subscriptions={subscriptions} />
  } else {
    subView = (
      <div style={{ textAlign: "center" }}>
        <h3>你正在享受本站的免費服務，請考慮捐贈或訂閱本站的專業計劃</h3>
        <SubscriptionPlans />
      </div>
    )
  }

  return (

    <div>
      <MetaTags>
        <title>我的帳戶</title>
        <meta name="description" content='我的帳戶' />
        <meta property="og:title" content='我的帳戶' />
        <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
      </MetaTags>
      <Container
        style={{
          paddingTop: "25px",
          paddingBottom: "25px",
          minHeight: "75vh",
        }}
      >
        <div style={{ textAlign: 'center', paddingTop: '25px' }}>
          <Header as='h1'>我的帳戶</Header>
        </div>
        <div style={{ minHeight: "75vh", padding: "2em 0em" }}>
          <div style={{ display: isLoggedIn ? 'none' : null }}>
            {onLoginTitle}
            <FirebaseLoginCard redirectUrl={redirectUrl} onLoginCallback={onLoginCallback} />
          </div>
          <div style={{ display: isLoggedIn ? null : 'none' }}>
            <div style={{ padding: "2em" }}>
              <div>
              <h2 style={{ display: "inline", fontSize: "2em" }}>{user?.displayName}，您好！</h2>
              <FirebaseLogoutButton floated='right' />
              </div>
            </div>
            <Segment basic>
              {isLoading? <Dimmer active inverted><Loader size='medium'>Loading</Loader></Dimmer>: undefined}
              {subView}
            </Segment>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default MyAccount
