import { createMedia } from "@artsy/fresnel";
import PropTypes from "prop-types";
import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import SearchBar from "./CategorySearchBar";
import SearchBar from "./CategorySearchBarUS";
import {
  Container,
  Dropdown,
  Icon,
  Menu,
  Segment,
  Sidebar,
} from "semantic-ui-react";
import { connect, useDispatch } from "react-redux";
import {
  login,
  setUserSubscriptions,
  fetchUserData,
} from "features/user/userSlice";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { firebaseApp } from "config/fbConfig";
import { getSubscriptionStatus } from "components/subscription/subscribe";
import {
  AccountDropDown,
  MobileAccountButton,
  MobileAccountDropDown,
} from "components/auth/AccountDropDown";
import { fetchTopicwM } from "../../features/topic/topicSlice";

const { MediaContextProvider } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
});

const HorizontalSidebar = ({ animation, direction, visible, onHide }) => (
  <Sidebar
    as={Segment}
    animation={animation}
    direction={direction}
    visible={visible}
    onHide={onHide}
  >
    <Icon style={{ padding: "10px" }} link name="arrow up" onClick={onHide} />
    <div style={{ height: "60vh", float: "right", width: "88%" }}>
      <SearchBar onHide={onHide} />
    </div>
  </Sidebar>
);

class DesktopContainer extends Component {
  state = {};

  hideFixedMenu = () => this.setState({ ...this.state, fixed: false });
  showFixedMenu = () => this.setState({ ...this.state, fixed: true });
  hideSearchMenu = () => this.setState({ ...this.state, visible: false });
  // showSearchMenu = () => this.setState({ ...this.state,visible: true })
  toggleSearchMenu = () =>
    this.setState({ ...this.state, visible: !this.state.visible });
  onHide = () => console.log("");

  render() {
    const { children } = this.props;
    const { fixed, visible } = this.state;
    return (
      <>
        <Segment
          inverted
          // margin="auto"
          // textAlign="left"
          style={{ minHeight: 10, padding: "0em 0em 0.25em" }}
          vertical
        >
          <Menu
            fixed={fixed ? "top" : null}
            inverted={!fixed}
            pointing={!fixed}
            secondary={!fixed}
          >
            <Container
              style={{
                paddingLeft: "5%",
                width: "100%",
                maxWidth: "900px",
                minWidth: "768px",
              }}
            >
              <Menu.Item>
                <Link to="/">主頁</Link>
              </Menu.Item>
              <Dropdown item simple text="股市" style={{ lineHeight: "1.3" }}>
                <Dropdown.Menu>
                  <Link to="/rank/active">
                    <Dropdown.Item style={{ color: "black" }}>
                      排行榜{" "}
                    </Dropdown.Item>
                  </Link>
                  <Link to="/hkmarket">
                    <Dropdown.Item style={{ color: "black" }}>
                      指數及市寛
                      <span style={{ color: "rgb(33, 133, 208)" }}>
                        (港)
                      </span>{" "}
                    </Dropdown.Item>
                  </Link>
                  <Link to="/usmarket">
                    <Dropdown.Item style={{ color: "black" }}>
                      指數及市寛
                      <span style={{ color: "rgb(219, 40, 40)" }}>
                        (美)
                      </span>{" "}
                    </Dropdown.Item>
                  </Link>
                </Dropdown.Menu>
              </Dropdown>
              {/* <Menu.Item>
                <Link to="/rank/active">排行榜</Link>
              </Menu.Item> */}
              <Menu.Item>
                <Link to="/topics">專題</Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="/about">關於本站</Link>
              </Menu.Item>
              {/* <Menu.Item >股票搜尋</Menu.Item> */}
              <Menu.Item>
                <Link to="/contact">聯絡我們</Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="/subscription">訂閱計劃</Link>
              </Menu.Item>
              <Menu.Item>
                {/* <a target="_blank" rel="noopener noreferrer" href=''>
                  <Icon name='instagram' size='large' />
                </a> */}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/idaashk/"
                >
                  <Icon name="facebook official" size="large" />
                </a>
              </Menu.Item>

              <Menu.Item position="right" fitted>
                <div
                  style={{
                    width: "100%",
                    minWidth: "325px",
                    // maxWidth: "350px",
                    paddingBottom: "0px",
                    paddingRight: "10px",
                  }}
                >
                  <SearchBar onHide={this.onHide} />
                </div>
              </Menu.Item>
              <Menu.Item>
                <AccountDropDown user={this.props.user} />
              </Menu.Item>
            </Container>
          </Menu>
        </Segment>

        <Sidebar.Pushable style={{ overflow: "auto" }}>
          {
            <HorizontalSidebar
              animation="overlay"
              onHide={this.hideSearchMenu}
              direction="top"
              visible={visible}
            />
          }

          <Sidebar.Pusher dimmed={"dimmed" && visible}>
            {children}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </>
    );
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
};

DesktopContainer = connect((state) => state.user)(DesktopContainer);

class MobileContainer extends Component {
  state = {};

  handleSidebarHide = () =>
    this.setState({ ...this.state, sidebarOpened: false });
  handleToggle = () => this.setState({ ...this.state, sidebarOpened: true });
  hideSearchMenu = () => this.setState({ ...this.state, visible: false });
  // showSearchMenu = () => this.setState({ ...this.state,visible: true })
  toggleSearchMenu = () =>
    this.setState({ ...this.state, visible: !this.state.visible });
  hideUserMenu = () => this.setState({ ...this.state, userMenuVisible: false });
  toggleUserMenu = () =>
    this.setState({
      ...this.state,
      userMenuVisible: !this.state.userMenuVisible,
    });

  render() {
    const { children, user } = this.props;
    const { sidebarOpened, visible, userMenuVisible } = this.state;
    return (
      <Sidebar.Pushable>
        <Sidebar
          as={Menu}
          animation="overlay"
          icon="labeled"
          inverted
          onHide={this.handleSidebarHide}
          vertical
          visible={sidebarOpened}
          width="thin"
        >
          <Link to="/" onClick={this.handleSidebarHide}>
            <Menu.Item>主頁</Menu.Item>
          </Link>

          <Link to="/rank/active" onClick={this.handleSidebarHide}>
            <Menu.Item>排行榜</Menu.Item>
          </Link>
          <Link to="/hkmarket" onClick={this.handleSidebarHide}>
            <Menu.Item>
              指數及市寬
              <span style={{ color: "rgb(33, 133, 208)" }}>(港)</span>{" "}
            </Menu.Item>
          </Link>
          <Link to="/usmarket" onClick={this.handleSidebarHide}>
            <Menu.Item>
              指數及市寬
              <span style={{ color: "rgb(219, 40, 40)" }}>(美)</span>{" "}
            </Menu.Item>
          </Link>
          <Link to="/topics" onClick={this.handleSidebarHide}>
            <Menu.Item>專題</Menu.Item>
          </Link>

          <Link to="/about" onClick={this.handleSidebarHide}>
            <Menu.Item>關於本站</Menu.Item>
          </Link>
          <Link to="/contact" onClick={this.handleSidebarHide}>
            <Menu.Item>聯絡我們</Menu.Item>
          </Link>
          <Link to="/subscription" onClick={this.handleSidebarHide}>
            <Menu.Item>訂閱計劃</Menu.Item>
          </Link>
          {/* <a target="_blank" rel="noopener noreferrer" href=''>
            <Menu.Item >
              <Icon name='instagram' />
            </Menu.Item>
          </a> */}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/idaashk/"
          >
            <Menu.Item>
              <Icon name="facebook official" />
            </Menu.Item>
          </a>
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment
            inverted
            textAlign="center"
            style={{ minHeight: 10, padding: "0.5em 0em" }}
            vertical
          >
            <Container>
              <Menu inverted pointing secondary size="large">
                <Menu.Item onClick={this.handleToggle}>
                  <Icon name="sidebar" />
                </Menu.Item>
                <Menu.Item position="right" onClick={this.toggleSearchMenu}>
                  <Icon name="search" />
                </Menu.Item>
                <Menu.Item
                  onClick={() => {
                    if (user) {
                      this.toggleUserMenu();
                    }
                  }}
                >
                  <MobileAccountButton user={user} />
                </Menu.Item>
              </Menu>
            </Container>
          </Segment>

          <Sidebar.Pushable style={{ overflow: "hidden" }}>
            <HorizontalSidebar
              animation="overlay"
              onHide={this.hideSearchMenu}
              direction="top"
              visible={visible}
            />
            <MobileAccountDropDown
              animation="overlay"
              onHide={this.hideUserMenu}
              direction="top"
              visible={userMenuVisible}
              user={user}
            />

            <Sidebar.Pusher dimmed={"dimmed" && visible}>
              {children}
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
};

MobileContainer = connect((state) => state.user)(MobileContainer);

const AppContainer = ({ children }) => {
  const dispatch = useDispatch();
  const auth = getAuth(firebaseApp);
  useEffect(() => {
    const unregisterAuthObserver = onAuthStateChanged(auth, (user) => {
      if (user) {
        dispatch(
          login({
            uid: user.uid,
            displayName: user.displayName,
            email: user.email,
            photoURL: user.photoURL,
          })
        );

        const callback = (x) =>
          dispatch(setUserSubscriptions(JSON.stringify(x)));
        getSubscriptionStatus(user.uid, callback);
        dispatch(fetchUserData(user.uid));
      } else {
        dispatch(login(null));
      }
    });
    return () => unregisterAuthObserver();
  }, []);
  useEffect(() => {
    dispatch(fetchTopicwM("stockDict_all_n"));
  }, []);

  const [isMobile, setMobile] = useState(false);

  const handleResize = () => {
    const widths = [window.innerWidth];
    if (window.screen?.width) {
      widths.push(window.screen?.width);
    }
    const width = Math.min(...widths);
    var isMobile = width < 768;
    setMobile(isMobile);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const com = isMobile ? (
    <MobileContainer>{children}</MobileContainer>
  ) : (
    <DesktopContainer>{children}</DesktopContainer>
  );

  return <MediaContextProvider>{com}</MediaContextProvider>;
};

AppContainer.propTypes = {
  children: PropTypes.node,
};

export default AppContainer;
