import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import DisplayAdsBanner from "../../ads/DisplayAdsBanner";
import GoogleAd from "../../ads/Google_ad";
import { Container, Menu } from "semantic-ui-react";
import HSIChart from "./HSIChart2";
import HSIChartPaid from "./HSIChart2Paid";
import TopicIndexTable from "../IndexTable/IndexTableView";
import { Link, useParams } from "react-router-dom";
import { useAllowedBehindPaywall } from "features/user/userSlice";

export const HKMarket = ({ match }) => {
  const { type } = useParams();
  const allowedBehindPaywall = useAllowedBehindPaywall();
  return (
    <>
      <MetaTags>
        <title>指數及市寛(港股)</title>
        <meta name="description" content="指數及市寛(港股)" />
        <meta property="og:title" content="指數及市寛(港股)" />
        <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
      </MetaTags>
      <Container textAlign="center">
        <DisplayAdsBanner />
      </Container>

      <div
        style={{ paddingTop: "25px", paddingBottom: "25px", minHeight: "75vh" }}
      >
        <Container>
          <div style={{ paddingBottom: "20px", textAlign: "center" }}>
            <Menu compact pointing secondary size="big">
              <Link to="/hkmarket/hsi">
                <Menu.Item name="恒指及市寛" active={type === "hsi"} />
              </Link>
              <Link to="/hkmarket/indextable">
                <Menu.Item
                  name="指數成份股表現"
                  active={type === "indextable"}
                />
              </Link>
            </Menu>
          </div>
          <div>
            {type === "hsi" ? (
              allowedBehindPaywall ? (
                <HSIChartPaid />
              ) : (
                <HSIChart />
              )
            ) : (
              <TopicIndexTable />
            )}
          </div>
        </Container>
      </div>
      <div
        textAlign="center"
        style={{ paddingTop: "30px", paddingBottom: "0px" }}
      >
        <GoogleAd
          client="ca-pub-8753358837449417"
          slot="2486257452"
          format="auto"
          wrapperDivStyle={{
            marginTop: "30px",
            marginBottom: "20px",
          }}
        />
      </div>
    </>
  );
};

export default HKMarket;
