import React, { useEffect, Component, useState } from "react";
import { Loader, Container, Menu, Label, Header } from "semantic-ui-react";
import StockRankTable from "../tables/StockRankTable";
import _ from "lodash";
import MetaTags from "react-meta-tags";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {
  selectAllTopic,
  fetchTopic,
  toIdle,
} from "../../features/topic/topicSlice";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import "./TopicStockRank.css";
import { isMobile } from "react-device-detect";
import DisplayAdsLargeBanner from "../ads/DisplayAdsLargeBanner";
import DisplayAdsBanner from "../ads/DisplayAdsBanner";

export const TopicStockRank = ({ match }) => {
  const { type } = useParams();
  return (
    <>
      <MetaTags>
        <title>港股排行榜</title>
        <meta name="description" content="港股排行榜" />
        <meta name="keywords" content="港股排行榜" />
        <meta property="og:description" content="港股排行榜" />
        <meta property="og:title" content="港股排行榜" />
        <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
      </MetaTags>

      <Container textAlign="center">
        <DisplayAdsBanner />
      </Container>

      <div style={{ paddingTop: "25px", paddingBottom: "25px" }}>
        <StockRankContainer type={type} />
      </div>

      <Container textAlign="center" style={{ paddingTop: "25px" }}>
        <DisplayAdsLargeBanner />
      </Container>
    </>
  );
};

export const StockRankContainer = (props) => {
  const type = props.type;
  const dispatch = useDispatch();
  const topics = useSelector(selectAllTopic);
  const topicStatus = useSelector((state) => state.topic.status);
  const error = useSelector((state) => state.topic.error);
  // const { type } = useParams();

  useEffect(() => {
    if (topicStatus === "idle") {
      dispatch(fetchTopic("stockRank"));
    }
  }, [topicStatus, dispatch]);

  let content;

  if (topicStatus === "loading") {
    content = (
      <div>
        <section className="posts-list">
          <div
            style={{
              padding: "100px",
              margin: "auto",
              alignItems: "center",
              height: "75vh",
              // overflow: "auto",
            }}
          >
            <Loader active inline="centered">
              {" "}
              Loading
            </Loader>
          </div>
        </section>
      </div>
    );
  } else if (topicStatus === "succeeded") {
    const data = topics.find((item) => item.topicInfo.name === "stockRank");
    if (data) {
      content = <StockRankView result={data} type={type} />;
    } else {
      dispatch(toIdle({}));
    }
  } else if (topicStatus === "error") {
    content = (
      <div>
        <section className="posts-list">
          <div>{error}</div>
        </section>
      </div>
    );
  }
  return (
    <>
      <MetaTags>
        <title>港股排行榜</title>
        <meta name="description" content="港股排行榜" />
        <meta name="keywords" content="港股排行榜" />
        <meta property="og:description" content="港股排行榜" />
        <meta property="og:title" content="港股排行榜" />
        <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
      </MetaTags>
      {content}
    </>
  );
};

function StockRankView(props) {
  let dashdata = props.result;
  let type = props.type;
  let header;
  let content;
  if (type === "northWater") {
    header = "港股通成交活躍股";
  } else if (type === "topgainers") {
    header = "二十大升幅股份";
  } else if (type === "toplosers") {
    header = "二十大跌幅股份";
  } else {
    header = "二十大活躍股份";
  }

  let meta = (
    <MetaTags>
      <title>{header}</title>
      <meta name="description" content={header} />
      <meta property="og:title" content={header} />
      <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
    </MetaTags>
  );

  if (type === "northWater") {
    content = (
      <>
        <Container
          textAlign="center"
          style={{ paddingTop: "25px", paddingBottom: "25px" }}
        >
          <TabMenu id={type} />
        </Container>
        <Container
          textAlign="center"
          style={{ paddingTop: "25px", paddingBottom: "25px" }}
        >
          <StockRankTable data={dashdata[type]} type={type} />
        </Container>
        <Container textAlign="center">
          <DisplayAdsBanner />
        </Container>
        <Container style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <Header as="h2" textAlign="center">
            港股通資金流
            <Header.Subheader>(最近N個交易天)</Header.Subheader>
          </Header>
        </Container>
        <Container>
          <LineChart chartData={dashdata.northWater} />
        </Container>
      </>
    );
  } else {
    content = (
      <>
        <div style={{ margin: "auto", alignItems: "center" }}>
          <Container
            style={{
              textAlign: "center",
              paddingTop: "25px",
              paddingBottom: "25px",
            }}
          >
            <TabMenu id={type} />
          </Container>
        </div>

        <Container
          textAlign="center"
          style={{ paddingTop: "25px", paddingBottom: "25px" }}
        >
          <StockRankTable data={dashdata[type]} />
        </Container>
      </>
    );
  }
  return (
    <>
      {meta}
      {content}
    </>
  );
}

export class TabMenu extends Component {
  state = { activeItem: this.props.id };

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  render() {
    const { activeItem } = this.state;

    return (
      <div className="tabDiv">
        <div style={{ display: "inline-block" }}>
          <Menu
            compact
            pointing
            secondary
            size="large"
            style={{ margin: "auto" }}
          >
            <Link to="/rank/active">
              <Menu.Item
                name="active"
                active={activeItem === "active"}
                onClick={this.handleItemClick}
              >
                二十大活躍股份
              </Menu.Item>
            </Link>
            <Link to="/rank/northWater">
              <Menu.Item
                name="northWater"
                active={activeItem === "northWater"}
                onClick={this.handleItemClick}
              >
                港股通成交活躍股
              </Menu.Item>
            </Link>
            <Link to="/rank/topgainers">
              <Menu.Item
                name="topgainers"
                active={activeItem === "topgainers"}
                onClick={this.handleItemClick}
              >
                二十大升幅股份
              </Menu.Item>
            </Link>
            <Link to="/rank/toplosers">
              <Menu.Item
                name="toplosers"
                active={activeItem === "toplosers"}
                onClick={this.handleItemClick}
              >
                二十大跌幅股份
              </Menu.Item>
            </Link>
          </Menu>
        </div>
      </div>
    );
  }
}

function LineChart(chartData) {
  const [duration, setDuration] = useState(10);
  var date_ = chartData.chartData.date,
    fundflow_ = chartData.chartData.northWaterFundFlowd,
    date = [],
    fundflow = [],
    fAmount = [],
    cumsum = [],
    dataLength = fundflow_.length,
    i = dataLength - duration;

  for (i; i < dataLength; i += 1) {
    let d = fundflow_[i];

    date.push(date_[i]);
    fAmount.push(parseFloat(d));
    if (d < 0) {
      fundflow.push({ y: d, color: "#d91e19" });
    } else {
      fundflow.push({ y: d, color: "#0072ce" });
    }
    if (i === dataLength - duration) {
      cumsum.push(parseFloat(d));
    } else {
      let buffer = parseFloat(cumsum[cumsum.length - 1]) + parseFloat(d);
      cumsum.push(buffer);
    }
  }
  let yMax = Math.ceil(_.max(fAmount) / 50) * 50;
  let yMin = Math.floor(_.min(fAmount) / 50) * 50;
  let yMax2 = Math.ceil(_.max(cumsum) / 100) * 100;
  let yMin2 = Math.floor(_.min(cumsum) / 100) * 100;
  const chartAxisTitle = isMobile ? [null, null] : ["資金流入", "累積資金"];

  const chartOptions = {
    // chart: {
    //   plotBackgroundImage: `${process.env.PUBLIC_URL}/images/watermark.svg`
    // },
    xAxis: {
      categories: date,
    },
    yAxis: [
      {
        title: {
          text: chartAxisTitle[0],
          // text:"資金流入",
          // align: 'high',
          // offset: -20,
          // y:-20,
          // rotation:0,
        },
        opposite: false,
        max: yMax,
        min: yMin,
        // startOnTick: false,
        // endOnTick: false,
      },
      {
        title: {
          //text:"累積資金"
          text: chartAxisTitle[1],
          style: {
            color: "black",
          },
        },
        opposite: true,
        gridLineWidth: 0,
        labels: {
          style: {
            color: "black",
          },
        },
        max: yMax2,
        min: yMin2,
        // startOnTick: true,
        // endOnTick: true
      },
    ],
    title: {
      text: null,
    },
    credits: {
      text: "DaaSHK",
      href: "https://DaaSHK.com/",
      style: {
        fontSize: "1em",
      },
    },
    // credits: {
    //   enabled: false
    // },
    legend: {
      enabled: true,
    },
    series: [
      {
        type: "column",
        name: "資金流入金額",
        data: fundflow,
        yAxis: 0,
      },
      {
        type: "line",
        name: "累積資金流入",
        data: cumsum,
        color: "black",
        marker: {
          lineWidth: 1.5,
          lineColor: "black",
          // fillColor: 'white'
        },
        yAxis: 1,
      },
    ],
    plotOptions: {
      column: {
        color: "#0072ce",
      },
    },

    tooltip: {
      formatter: function () {
        return ["<b>" + this.x + "</b>"].concat(
          this.points
            ? this.points.map(function (point) {
                return point.series.name + ": " + point.y.toFixed(2) + "億";
              })
            : []
        );
      },
      split: true,
    },
  };

  return (
    <>
      <div style={{ paddingBottom: "15px" }}>
        時期:
        <Label
          color={duration === 10 ? "blue" : null}
          onClick={() => setDuration(10)}
          basic
          style={{ marginLeft: ".5rem" }}
        >
          10天
        </Label>
        <Label
          color={duration === 20 ? "blue" : null}
          onClick={() => setDuration(20)}
          basic
        >
          20天
        </Label>
        <Label
          color={duration === 30 ? "blue" : null}
          onClick={() => setDuration(30)}
          basic
        >
          30天
        </Label>
        <Label
          color={duration === 60 ? "blue" : null}
          onClick={() => setDuration(60)}
          basic
        >
          60天
        </Label>
      </div>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </>
  );
}

// function NotFound() {
//   return (
//     <div
//       style={{
//         padding: "100px",
//         margin: "auto",
//         alignItems: "center",
//         height: "75vh",
//       }}
//     >
//       <Loader active inline='centered' > Loading</Loader>
//     </div>
//   )
// }

export default TopicStockRank;
