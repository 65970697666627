import React, { useEffect, useReducer } from "react";
import { Loader } from "semantic-ui-react";
import _ from "lodash";
import {
  selectAllTopic,
  fetchTopic,
  toIdle,
} from "../../../features/topic/topicSlice";
import { useSelector, useDispatch } from "react-redux";
import CCASSTable from "./CCASSTable";
import CCASSTableFilter from "./CCASSTableFilter";
import { connect } from "react-redux";

function CCASSTableContainer(props) {
  console.log(props);
  return (
    <div>
      hi
      <CCASSTableFilter
        initialValues={{
          priceChg_r: "1",
          isHSI: false,
          isHTECH: false,
          isMain: false,
          isGEM: false,
        }}
      />
      <CCASSTable
        priceStatDataSet={priceStatDataSet}
        // ccassStat = {ccassStat}
        filters={props.values}
        stockSector={stockSector}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return state.form.ccassTable
    ? {
        values: state.form.ccassTable.values,
        submitSucceeded: state.form.ccassTable.submitSucceeded,
      }
    : {};
};

export default connect(mapStateToProps)(CCASSTableContainer);

const priceStatDataSet = {
  topicInfo: { lastUpdate: "2022-04-01", name: "zoomData" },
  data: {
    "0001": {
      id: "0001",
      c: 57.55,
      ma50: 56.26,
      ma100: 53.879,
      ma150: 53.753,
      ma200: 54.922,
      maw30: 53.625,
      rs: 82.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: 2.0,
      price_5d_h: 59.45,
      price_5d_L: 57.5,
      price_1m_h: 59.45,
      price_1m_l: 50.8,
      price_3m_h: 60.45,
      price_3m_l: 50.5,
      price_6m_h: 60.45,
      price_6m_l: 48.8,
      price_52w_h: 65.8,
      price_52w_l: 48.8,
      price_pc_1d: -2.043,
      price_pc_1w: -1.032,
      price_pc_1m: 5.114,
      price_pc_6m: 10.78,
      price_pc_1y: -7.846,
      price_pc_ytd: -7.846,
    },
    "0002": {
      id: "0002",
      c: 76.35,
      ma50: 78.117,
      ma100: 77.533,
      ma150: 76.967,
      ma200: 77.25,
      maw30: 76.902,
      rs: 73.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: -3.0,
      price_5d_h: 77.0,
      price_5d_L: 75.85,
      price_1m_h: 79.55,
      price_1m_l: 75.3,
      price_3m_h: 80.5,
      price_3m_l: 75.3,
      price_6m_h: 80.5,
      price_6m_l: 73.8,
      price_52w_h: 81.65,
      price_52w_l: 73.8,
      price_pc_1d: -0.065,
      price_pc_1w: 0.263,
      price_pc_1m: -4.023,
      price_pc_6m: 1.8,
      price_pc_1y: 2.346,
      price_pc_ytd: 2.346,
    },
    "0003": {
      id: "0003",
      c: 9.49,
      ma50: 11.529,
      ma100: 11.716,
      ma150: 11.827,
      ma200: 11.988,
      maw30: 11.856,
      rs: 34.0,
      rsd1d: 2.0,
      rsd5d: -2.0,
      rsd10d: -35.0,
      price_5d_h: 9.77,
      price_5d_L: 9.49,
      price_1m_h: 11.9,
      price_1m_l: 9.43,
      price_3m_h: 12.4,
      price_3m_l: 9.43,
      price_6m_h: 12.4,
      price_6m_l: 9.43,
      price_52w_h: 13.4,
      price_52w_l: 9.43,
      price_pc_1d: -0.732,
      price_pc_1w: -2.467,
      price_pc_1m: -19.712,
      price_pc_6m: -19.576,
      price_pc_1y: -17.785,
      price_pc_ytd: -17.785,
    },
    "0004": {
      id: "0004",
      c: 23.9,
      ma50: 26.369,
      ma100: 25.95,
      ma150: 26.017,
      ma200: 26.426,
      maw30: 26.21,
      rs: 71.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: -14.0,
      price_5d_h: 24.7,
      price_5d_L: 23.05,
      price_1m_h: 29.35,
      price_1m_l: 21.75,
      price_3m_h: 29.35,
      price_3m_l: 21.75,
      price_6m_h: 29.35,
      price_6m_l: 21.75,
      price_52w_h: 30.7,
      price_52w_l: 20.15,
      price_pc_1d: -1.443,
      price_pc_1w: 1.486,
      price_pc_1m: -15.248,
      price_pc_6m: -7.544,
      price_pc_1y: 17.157,
      price_pc_ytd: 17.157,
    },
    "0005": {
      id: "0005",
      c: 54.2,
      ma50: 54.09,
      ma100: 50.392,
      ma150: 47.951,
      ma200: 47.006,
      maw30: 48.182,
      rs: 90.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: 1.0,
      price_5d_h: 54.45,
      price_5d_L: 52.7,
      price_1m_h: 54.45,
      price_1m_l: 47.6,
      price_3m_h: 59.6,
      price_3m_l: 46.65,
      price_6m_h: 59.6,
      price_6m_l: 40.05,
      price_52w_h: 59.6,
      price_52w_l: 38.3,
      price_pc_1d: 0.463,
      price_pc_1w: 2.846,
      price_pc_1m: 0.185,
      price_pc_6m: 32.357,
      price_pc_1y: 19.515,
      price_pc_ytd: 19.515,
    },
    "0006": {
      id: "0006",
      c: 51.05,
      ma50: 49.838,
      ma100: 48.906,
      ma150: 48.178,
      ma200: 48.386,
      maw30: 48.09,
      rs: 84.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: -2.0,
      price_5d_h: 51.75,
      price_5d_L: 50.65,
      price_1m_h: 51.95,
      price_1m_l: 47.9,
      price_3m_h: 51.95,
      price_3m_l: 47.6,
      price_6m_h: 51.95,
      price_6m_l: 44.2,
      price_52w_h: 51.95,
      price_52w_l: 44.2,
      price_pc_1d: -0.874,
      price_pc_1w: -0.293,
      price_pc_1m: 3.655,
      price_pc_6m: 11.463,
      price_pc_1y: 13.193,
      price_pc_ytd: 13.193,
    },
    "0007": {
      id: "0007",
      c: 0.083,
      ma50: 0.09,
      ma100: 0.105,
      ma150: 0.113,
      ma200: 0.116,
      maw30: 0.112,
      rs: 19.0,
      rsd1d: 3.0,
      rsd5d: -1.0,
      rsd10d: 1.0,
      price_5d_h: 0.089,
      price_5d_L: 0.075,
      price_1m_h: 0.098,
      price_1m_l: 0.072,
      price_3m_h: 0.131,
      price_3m_l: 0.072,
      price_6m_h: 0.2,
      price_6m_l: 0.072,
      price_52w_h: 0.2,
      price_52w_l: 0.072,
      price_pc_1d: -2.353,
      price_pc_1w: -5.682,
      price_pc_1m: -15.306,
      price_pc_6m: -34.646,
      price_pc_1y: -34.646,
      price_pc_ytd: -34.646,
    },
    "0008": {
      id: "0008",
      c: 4.42,
      ma50: 4.252,
      ma100: 4.121,
      ma150: 4.09,
      ma200: 4.094,
      maw30: 4.09,
      rs: 84.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: -2.0,
      price_5d_h: 4.47,
      price_5d_L: 4.4,
      price_1m_h: 4.47,
      price_1m_l: 4.1,
      price_3m_h: 4.47,
      price_3m_l: 3.96,
      price_6m_h: 4.47,
      price_6m_l: 3.89,
      price_52w_h: 4.65,
      price_52w_l: 3.89,
      price_pc_1d: -0.674,
      price_pc_1w: -0.45,
      price_pc_1m: 1.376,
      price_pc_6m: 10.5,
      price_pc_1y: 0.227,
      price_pc_ytd: 0.227,
    },
    "0010": {
      id: "0010",
      c: 16.64,
      ma50: 17.002,
      ma100: 17.076,
      ma150: 17.541,
      ma200: 18.097,
      maw30: 17.507,
      rs: 59.0,
      rsd1d: -3.0,
      rsd5d: -2.0,
      rsd10d: 0.0,
      price_5d_h: 17.26,
      price_5d_L: 16.3,
      price_1m_h: 17.34,
      price_1m_l: 15.1,
      price_3m_h: 17.96,
      price_3m_l: 15.1,
      price_6m_h: 18.78,
      price_6m_l: 15.1,
      price_52w_h: 20.75,
      price_52w_l: 15.1,
      price_pc_1d: -1.887,
      price_pc_1w: -0.716,
      price_pc_1m: -3.368,
      price_pc_6m: -8.471,
      price_pc_1y: -15.96,
      price_pc_ytd: -15.96,
    },
    "0011": {
      id: "0011",
      c: 151.2,
      ma50: 152.07,
      ma100: 148.878,
      ma150: 146.234,
      ma200: 147.484,
      maw30: 146.773,
      rs: 81.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: 5.0,
      price_5d_h: 153.5,
      price_5d_L: 148.3,
      price_1m_h: 153.5,
      price_1m_l: 133.5,
      price_3m_h: 169.5,
      price_3m_l: 133.5,
      price_6m_h: 169.5,
      price_6m_l: 131.0,
      price_52w_h: 169.5,
      price_52w_l: 131.0,
      price_pc_1d: -1.047,
      price_pc_1w: 0.935,
      price_pc_1m: 4.854,
      price_pc_6m: 13.174,
      price_pc_1y: 0.332,
      price_pc_ytd: 0.332,
    },
    "0012": {
      id: "0012",
      c: 32.6,
      ma50: 33.364,
      ma100: 33.266,
      ma150: 33.023,
      ma200: 33.832,
      maw30: 33.077,
      rs: 70.0,
      rsd1d: -1.0,
      rsd5d: -3.0,
      rsd10d: -3.0,
      price_5d_h: 33.7,
      price_5d_L: 32.5,
      price_1m_h: 34.35,
      price_1m_l: 29.9,
      price_3m_h: 35.2,
      price_3m_l: 29.9,
      price_6m_h: 35.2,
      price_6m_l: 29.35,
      price_52w_h: 38.85,
      price_52w_l: 28.0,
      price_pc_1d: -1.955,
      price_pc_1w: -0.761,
      price_pc_1m: 0.308,
      price_pc_6m: 9.213,
      price_pc_1y: -6.456,
      price_pc_ytd: -6.456,
    },
    "0013": {
      id: "0013",
      c: 30.1,
      ma50: 37.684,
      ma100: 45.676,
      ma150: 48.919,
      ma200: null,
      maw30: 48.852,
      rs: 9.0,
      rsd1d: 1.0,
      rsd5d: -1.0,
      rsd10d: 1.0,
      price_5d_h: 32.95,
      price_5d_L: 28.1,
      price_1m_h: 43.6,
      price_1m_l: 22.5,
      price_3m_h: 57.9,
      price_3m_l: 22.5,
      price_6m_h: 59.8,
      price_6m_l: 22.5,
      price_52w_h: 85.8,
      price_52w_l: 22.5,
      price_pc_1d: -1.311,
      price_pc_1w: -10.55,
      price_pc_1m: -29.919,
      price_pc_6m: -47.193,
      price_pc_1y: -50.083,
      price_pc_ytd: -50.083,
    },
    "0014": {
      id: "0014",
      c: 22.9,
      ma50: 23.367,
      ma100: 24.042,
      ma150: 24.83,
      ma200: 26.169,
      maw30: 24.822,
      rs: 46.0,
      rsd1d: 1.0,
      rsd5d: -4.0,
      rsd10d: 0.0,
      price_5d_h: 23.65,
      price_5d_L: 22.7,
      price_1m_h: 23.95,
      price_1m_l: 20.15,
      price_3m_h: 24.75,
      price_3m_l: 20.15,
      price_6m_h: 27.5,
      price_6m_l: 20.15,
      price_52w_h: 32.45,
      price_52w_l: 20.15,
      price_pc_1d: -1.717,
      price_pc_1w: -2.345,
      price_pc_1m: -0.435,
      price_pc_6m: -9.665,
      price_pc_1y: -23.411,
      price_pc_ytd: -23.411,
    },
    "0016": {
      id: "0016",
      c: 93.6,
      ma50: 94.446,
      ma100: 95.327,
      ma150: 98.043,
      ma200: 102.131,
      maw30: 98.108,
      rs: 57.0,
      rsd1d: 0.0,
      rsd5d: -3.0,
      rsd10d: 2.0,
      price_5d_h: 97.2,
      price_5d_L: 93.6,
      price_1m_h: 97.2,
      price_1m_l: 87.15,
      price_3m_h: 99.55,
      price_3m_l: 87.15,
      price_6m_h: 104.7,
      price_6m_l: 87.15,
      price_52w_h: 124.3,
      price_52w_l: 87.15,
      price_pc_1d: -1.732,
      price_pc_1w: -1.474,
      price_pc_1m: 2.97,
      price_pc_6m: -3.901,
      price_pc_1y: -20.543,
      price_pc_ytd: -20.543,
    },
    "0017": {
      id: "0017",
      c: 31.85,
      ma50: 31.547,
      ma100: 31.562,
      ma150: 32.417,
      ma200: 33.877,
      maw30: 32.365,
      rs: 62.0,
      rsd1d: -3.0,
      rsd5d: -6.0,
      rsd10d: 7.0,
      price_5d_h: 33.25,
      price_5d_L: 31.8,
      price_1m_h: 33.25,
      price_1m_l: 28.2,
      price_3m_h: 34.0,
      price_3m_l: 28.2,
      price_6m_h: 35.2,
      price_6m_l: 28.2,
      price_52w_h: 43.0,
      price_52w_l: 28.2,
      price_pc_1d: -2.748,
      price_pc_1w: -0.933,
      price_pc_1m: 1.92,
      price_pc_6m: 0.0,
      price_pc_1y: -21.261,
      price_pc_ytd: -21.261,
    },
    "0018": {
      id: "0018",
      c: 0.67,
      ma50: 0.677,
      ma100: 0.683,
      ma150: 0.69,
      ma200: 0.705,
      maw30: 0.693,
      rs: 79.0,
      rsd1d: 2.0,
      rsd5d: 0.0,
      rsd10d: -7.0,
      price_5d_h: 0.67,
      price_5d_L: 0.65,
      price_1m_h: 0.68,
      price_1m_l: 0.64,
      price_3m_h: 0.75,
      price_3m_l: 0.64,
      price_6m_h: 0.75,
      price_6m_l: 0.64,
      price_52w_h: 0.85,
      price_52w_l: 0.46,
      price_pc_1d: 1.515,
      price_pc_1w: 0.0,
      price_pc_1m: -1.471,
      price_pc_6m: -4.286,
      price_pc_1y: 34.0,
      price_pc_ytd: 34.0,
    },
    "0019": {
      id: "0019",
      c: 47.9,
      ma50: 45.999,
      ma100: 45.682,
      ma150: 46.948,
      ma200: 48.286,
      maw30: 46.85,
      rs: 72.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: 8.0,
      price_5d_h: 48.4,
      price_5d_L: 46.1,
      price_1m_h: 48.4,
      price_1m_l: 40.25,
      price_3m_h: 49.55,
      price_3m_l: 40.25,
      price_6m_h: 51.45,
      price_6m_l: 40.25,
      price_52w_h: 64.6,
      price_52w_l: 40.25,
      price_pc_1d: -0.312,
      price_pc_1w: 0.842,
      price_pc_1m: 10.88,
      price_pc_6m: 3.456,
      price_pc_1y: -16.912,
      price_pc_ytd: -16.912,
    },
    "0020": {
      id: "0020",
      c: 6.15,
      ma50: 6.572,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 86.0,
      rsd1d: -9.0,
      rsd5d: -9.0,
      rsd10d: -10.0,
      price_5d_h: 6.48,
      price_5d_L: 6.02,
      price_1m_h: 6.86,
      price_1m_l: 5.79,
      price_3m_h: 9.7,
      price_3m_l: 5.79,
      price_6m_h: 9.7,
      price_6m_l: 3.9,
      price_52w_h: 9.7,
      price_52w_l: 3.9,
      price_pc_1d: -0.806,
      price_pc_1w: -4.056,
      price_pc_1m: -4.799,
      price_pc_6m: 48.91,
      price_pc_1y: 48.91,
      price_pc_ytd: 48.91,
    },
    "0023": {
      id: "0023",
      c: 12.3,
      ma50: 12.807,
      ma100: 12.238,
      ma150: 12.422,
      ma200: 12.718,
      maw30: 12.432,
      rs: 66.0,
      rsd1d: 5.0,
      rsd5d: 7.0,
      rsd10d: 3.0,
      price_5d_h: 12.4,
      price_5d_L: 11.7,
      price_1m_h: 13.3,
      price_1m_l: 11.04,
      price_3m_h: 14.36,
      price_3m_l: 11.04,
      price_6m_h: 14.36,
      price_6m_l: 10.88,
      price_52w_h: 17.36,
      price_52w_l: 10.88,
      price_pc_1d: 1.821,
      price_pc_1w: 2.671,
      price_pc_1m: -6.535,
      price_pc_6m: -2.07,
      price_pc_1y: -24.54,
      price_pc_ytd: -24.54,
    },
    "0025": {
      id: "0025",
      c: 9.35,
      ma50: 9.258,
      ma100: 9.347,
      ma150: 9.405,
      ma200: 9.505,
      maw30: 9.388,
      rs: 71.0,
      rsd1d: 5.0,
      rsd5d: 8.0,
      rsd10d: 5.0,
      price_5d_h: 9.35,
      price_5d_L: 8.69,
      price_1m_h: 9.4,
      price_1m_l: 8.69,
      price_3m_h: 9.6,
      price_3m_l: 8.69,
      price_6m_h: 9.76,
      price_6m_l: 8.69,
      price_52w_h: 10.12,
      price_52w_l: 8.69,
      price_pc_1d: 1.63,
      price_pc_1w: 3.889,
      price_pc_1m: 0.538,
      price_pc_6m: 1.19,
      price_pc_1y: -3.708,
      price_pc_ytd: -3.708,
    },
    "0027": {
      id: "0027",
      c: 47.0,
      ma50: 44.864,
      ma100: 43.685,
      ma150: 43.71,
      ma200: 46.587,
      maw30: 43.542,
      rs: 72.0,
      rsd1d: -1.0,
      rsd5d: 4.0,
      rsd10d: 14.0,
      price_5d_h: 47.75,
      price_5d_L: 44.35,
      price_1m_h: 47.75,
      price_1m_l: 34.25,
      price_3m_h: 49.4,
      price_3m_l: 34.25,
      price_6m_h: 50.25,
      price_6m_l: 34.25,
      price_52w_h: 73.2,
      price_52w_l: 34.25,
      price_pc_1d: -1.053,
      price_pc_1w: 1.184,
      price_pc_1m: 8.17,
      price_pc_6m: 17.5,
      price_pc_1y: -34.081,
      price_pc_ytd: -34.081,
    },
    "0028": {
      id: "0028",
      c: 3.93,
      ma50: 4.016,
      ma100: 4.142,
      ma150: 4.275,
      ma200: 4.344,
      maw30: 4.279,
      rs: 49.0,
      rsd1d: 5.0,
      rsd5d: -8.0,
      rsd10d: -9.0,
      price_5d_h: 4.15,
      price_5d_L: 3.65,
      price_1m_h: 4.15,
      price_1m_l: 3.65,
      price_3m_h: 4.3,
      price_3m_l: 3.65,
      price_6m_h: 4.69,
      price_6m_l: 3.65,
      price_52w_h: 5.0,
      price_52w_l: 3.65,
      price_pc_1d: 1.55,
      price_pc_1w: -3.676,
      price_pc_1m: -1.504,
      price_pc_6m: -12.86,
      price_pc_1y: -15.665,
      price_pc_ytd: -15.665,
    },
    "0029": {
      id: "0029",
      c: 10.78,
      ma50: 9.816,
      ma100: 10.258,
      ma150: 10.913,
      ma200: 11.465,
      maw30: 10.951,
      rs: 56.0,
      rsd1d: 16.0,
      rsd5d: 11.0,
      rsd10d: 18.0,
      price_5d_h: 10.96,
      price_5d_L: 9.02,
      price_1m_h: 10.96,
      price_1m_l: 8.18,
      price_3m_h: 10.96,
      price_3m_l: 8.18,
      price_6m_h: 13.16,
      price_6m_l: 8.18,
      price_52w_h: 14.64,
      price_52w_l: 8.18,
      price_pc_1d: 7.8,
      price_pc_1w: 5.479,
      price_pc_1m: 5.686,
      price_pc_6m: -17.584,
      price_pc_1y: -18.947,
      price_pc_ytd: -18.947,
    },
    "0030": {
      id: "0030",
      c: 0.255,
      ma50: 0.275,
      ma100: 0.271,
      ma150: 0.277,
      ma200: 0.297,
      maw30: 0.278,
      rs: 35.0,
      rsd1d: -5.0,
      rsd5d: -6.0,
      rsd10d: 7.0,
      price_5d_h: 0.285,
      price_5d_L: 0.25,
      price_1m_h: 0.285,
      price_1m_l: 0.203,
      price_3m_h: 0.32,
      price_3m_l: 0.203,
      price_6m_h: 0.32,
      price_6m_l: 0.203,
      price_52w_h: 0.51,
      price_52w_l: 0.203,
      price_pc_1d: -3.774,
      price_pc_1w: -7.273,
      price_pc_1m: -10.526,
      price_pc_6m: -8.929,
      price_pc_1y: -43.333,
      price_pc_ytd: -43.333,
    },
    "0031": {
      id: "0031",
      c: 0.52,
      ma50: 0.518,
      ma100: 0.525,
      ma150: 0.556,
      ma200: 0.586,
      maw30: 0.556,
      rs: 48.0,
      rsd1d: -3.0,
      rsd5d: 2.0,
      rsd10d: 6.0,
      price_5d_h: 0.53,
      price_5d_L: 0.495,
      price_1m_h: 0.54,
      price_1m_l: 0.44,
      price_3m_h: 0.59,
      price_3m_l: 0.44,
      price_6m_h: 0.63,
      price_6m_l: 0.44,
      price_52w_h: 0.83,
      price_52w_l: 0.44,
      price_pc_1d: -1.887,
      price_pc_1w: 1.961,
      price_pc_1m: -1.887,
      price_pc_6m: -13.333,
      price_pc_1y: -20.0,
      price_pc_ytd: -20.0,
    },
    "0033": {
      id: "0033",
      c: 1.55,
      ma50: 1.605,
      ma100: 1.462,
      ma150: 1.374,
      ma200: 1.382,
      maw30: 1.394,
      rs: 82.0,
      rsd1d: -2.0,
      rsd5d: -5.0,
      rsd10d: -12.0,
      price_5d_h: 1.76,
      price_5d_L: 1.52,
      price_1m_h: 1.87,
      price_1m_l: 1.52,
      price_3m_h: 1.87,
      price_3m_l: 1.24,
      price_6m_h: 1.87,
      price_6m_l: 0.55,
      price_52w_h: 3.26,
      price_52w_l: 0.55,
      price_pc_1d: 0.0,
      price_pc_1w: -6.627,
      price_pc_1m: -14.365,
      price_pc_6m: 24.0,
      price_pc_1y: -34.322,
      price_pc_ytd: -34.322,
    },
    "0034": {
      id: "0034",
      c: 10.5,
      ma50: 10.314,
      ma100: 10.274,
      ma150: 9.883,
      ma200: 9.713,
      maw30: 9.888,
      rs: 87.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 10.52,
      price_5d_L: 10.24,
      price_1m_h: 10.52,
      price_1m_l: 9.6,
      price_3m_h: 10.6,
      price_3m_l: 9.6,
      price_6m_h: 11.06,
      price_6m_l: 8.65,
      price_52w_h: 11.06,
      price_52w_l: 8.52,
      price_pc_1d: 0.0,
      price_pc_1w: 0.382,
      price_pc_1m: 3.143,
      price_pc_6m: 21.387,
      price_pc_1y: 19.318,
      price_pc_ytd: 19.318,
    },
    "0035": {
      id: "0035",
      c: 2.66,
      ma50: 2.731,
      ma100: 2.677,
      ma150: 2.681,
      ma200: 2.768,
      maw30: 2.676,
      rs: 64.0,
      rsd1d: 3.0,
      rsd5d: -1.0,
      rsd10d: -10.0,
      price_5d_h: 2.69,
      price_5d_L: 2.6,
      price_1m_h: 2.7,
      price_1m_l: 2.39,
      price_3m_h: 2.95,
      price_3m_l: 2.39,
      price_6m_h: 2.95,
      price_6m_l: 2.36,
      price_52w_h: 3.25,
      price_52w_l: 2.36,
      price_pc_1d: 1.141,
      price_pc_1w: -0.746,
      price_pc_1m: -1.481,
      price_pc_6m: 0.758,
      price_pc_1y: -10.135,
      price_pc_ytd: -10.135,
    },
    "0036": {
      id: "0036",
      c: 0.136,
      ma50: 0.144,
      ma100: 0.131,
      ma150: 0.132,
      ma200: 0.135,
      maw30: 0.133,
      rs: 82.0,
      rsd1d: 7.0,
      rsd5d: 5.0,
      rsd10d: 11.0,
      price_5d_h: 0.139,
      price_5d_L: 0.121,
      price_1m_h: 0.16,
      price_1m_l: 0.121,
      price_3m_h: 0.22,
      price_3m_l: 0.104,
      price_6m_h: 0.22,
      price_6m_l: 0.09,
      price_52w_h: 0.22,
      price_52w_l: 0.09,
      price_pc_1d: 6.25,
      price_pc_1w: 4.615,
      price_pc_1m: -10.526,
      price_pc_6m: 12.397,
      price_pc_1y: -7.483,
      price_pc_ytd: -7.483,
    },
    "0037": {
      id: "0037",
      c: 0.53,
      ma50: 0.506,
      ma100: 0.32,
      ma150: 0.258,
      ma200: 0.226,
      maw30: 0.269,
      rs: 99.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.58,
      price_5d_L: 0.495,
      price_1m_h: 0.62,
      price_1m_l: 0.4,
      price_3m_h: 1.01,
      price_3m_l: 0.133,
      price_6m_h: 1.01,
      price_6m_l: 0.125,
      price_52w_h: 1.01,
      price_52w_l: 0.111,
      price_pc_1d: -1.852,
      price_pc_1w: 0.0,
      price_pc_1m: 6.0,
      price_pc_6m: 327.419,
      price_pc_1y: 292.593,
      price_pc_ytd: 292.593,
    },
    "0038": {
      id: "0038",
      c: 4.07,
      ma50: 3.803,
      ma100: 3.85,
      ma150: 3.913,
      ma200: 4.067,
      maw30: 3.912,
      rs: 79.0,
      rsd1d: -2.0,
      rsd5d: 15.0,
      rsd10d: 39.0,
      price_5d_h: 4.12,
      price_5d_L: 3.75,
      price_1m_h: 4.12,
      price_1m_l: 2.98,
      price_3m_h: 4.37,
      price_3m_l: 2.98,
      price_6m_h: 4.37,
      price_6m_l: 2.98,
      price_52w_h: 6.3,
      price_52w_l: 2.98,
      price_pc_1d: -0.732,
      price_pc_1w: 7.388,
      price_pc_1m: 8.533,
      price_pc_6m: 7.105,
      price_pc_1y: 18.659,
      price_pc_ytd: 18.659,
    },
    "0039": {
      id: "0039",
      c: 0.049,
      ma50: 0.048,
      ma100: 0.048,
      ma150: 0.052,
      ma200: 0.055,
      maw30: 0.052,
      rs: 61.0,
      rsd1d: 7.0,
      rsd5d: -18.0,
      rsd10d: 16.0,
      price_5d_h: 0.054,
      price_5d_L: 0.042,
      price_1m_h: 0.065,
      price_1m_l: 0.04,
      price_3m_h: 0.065,
      price_3m_l: 0.035,
      price_6m_h: 0.065,
      price_6m_l: 0.03,
      price_52w_h: 0.098,
      price_52w_l: 0.03,
      price_pc_1d: 2.083,
      price_pc_1w: -3.922,
      price_pc_1m: 13.953,
      price_pc_6m: -16.949,
      price_pc_1y: -42.353,
      price_pc_ytd: -42.353,
    },
    "0040": {
      id: "0040",
      c: 0.66,
      ma50: 0.675,
      ma100: 0.652,
      ma150: 0.65,
      ma200: 0.655,
      maw30: 0.655,
      rs: 73.0,
      rsd1d: 1.0,
      rsd5d: -2.0,
      rsd10d: -5.0,
      price_5d_h: 0.67,
      price_5d_L: 0.64,
      price_1m_h: 0.67,
      price_1m_l: 0.61,
      price_3m_h: 0.876,
      price_3m_l: 0.61,
      price_6m_h: 0.876,
      price_6m_l: 0.57,
      price_52w_h: 0.876,
      price_52w_l: 0.57,
      price_pc_1d: 0.0,
      price_pc_1w: 1.538,
      price_pc_1m: 1.538,
      price_pc_6m: 4.762,
      price_pc_1y: -1.493,
      price_pc_ytd: -1.493,
    },
    "0041": {
      id: "0041",
      c: 19.02,
      ma50: 20.236,
      ma100: 20.362,
      ma150: 20.995,
      ma200: 22.121,
      maw30: 20.99,
      rs: 38.0,
      rsd1d: 4.0,
      rsd5d: -4.0,
      rsd10d: -5.0,
      price_5d_h: 19.38,
      price_5d_L: 18.8,
      price_1m_h: 19.9,
      price_1m_l: 16.64,
      price_3m_h: 22.65,
      price_3m_l: 16.64,
      price_6m_h: 22.7,
      price_6m_l: 16.64,
      price_52w_h: 28.5,
      price_52w_l: 16.64,
      price_pc_1d: -0.419,
      price_pc_1w: 0.211,
      price_pc_1m: -3.647,
      price_pc_6m: -11.121,
      price_pc_1y: -28.496,
      price_pc_ytd: -28.496,
    },
    "0042": {
      id: "0042",
      c: 0.27,
      ma50: 0.48,
      ma100: 0.578,
      ma150: 0.605,
      ma200: 0.607,
      maw30: 0.612,
      rs: 4.0,
      rsd1d: 0.0,
      rsd5d: -2.0,
      rsd10d: -2.0,
      price_5d_h: 0.32,
      price_5d_L: 0.255,
      price_1m_h: 0.53,
      price_1m_l: 0.201,
      price_3m_h: 0.7,
      price_3m_l: 0.201,
      price_6m_h: 0.85,
      price_6m_l: 0.201,
      price_52w_h: 0.85,
      price_52w_l: 0.201,
      price_pc_1d: -3.571,
      price_pc_1w: -16.923,
      price_pc_1m: -47.059,
      price_pc_6m: -60.294,
      price_pc_1y: -59.701,
      price_pc_ytd: -59.701,
    },
    "0045": {
      id: "0045",
      c: 8.35,
      ma50: 8.298,
      ma100: 7.655,
      ma150: 7.514,
      ma200: 7.53,
      maw30: 7.518,
      rs: 87.0,
      rsd1d: -2.0,
      rsd5d: 0.0,
      rsd10d: -1.0,
      price_5d_h: 8.67,
      price_5d_L: 8.32,
      price_1m_h: 8.7,
      price_1m_l: 7.4,
      price_3m_h: 9.47,
      price_3m_l: 6.6,
      price_6m_h: 9.47,
      price_6m_l: 6.56,
      price_52w_h: 9.47,
      price_52w_l: 6.56,
      price_pc_1d: -1.649,
      price_pc_1w: 0.12,
      price_pc_1m: 2.959,
      price_pc_6m: 14.54,
      price_pc_1y: 6.505,
      price_pc_ytd: 6.505,
    },
    "0050": {
      id: "0050",
      c: 7.0,
      ma50: 7.422,
      ma100: 6.515,
      ma150: 6.2,
      ma200: 6.097,
      maw30: 6.215,
      rs: 91.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -1.0,
      price_5d_h: 7.1,
      price_5d_L: 6.9,
      price_1m_h: 7.7,
      price_1m_l: 6.34,
      price_3m_h: 8.36,
      price_3m_l: 5.47,
      price_6m_h: 8.36,
      price_6m_l: 5.4,
      price_52w_h: 8.36,
      price_52w_l: 5.35,
      price_pc_1d: -0.709,
      price_pc_1w: -0.85,
      price_pc_1m: -10.026,
      price_pc_6m: 29.151,
      price_pc_1y: 15.321,
      price_pc_ytd: 15.321,
    },
    "0052": {
      id: "0052",
      c: 15.2,
      ma50: 15.095,
      ma100: 15.617,
      ma150: 16.062,
      ma200: 16.475,
      maw30: 16.048,
      rs: 60.0,
      rsd1d: 3.0,
      rsd5d: 4.0,
      rsd10d: 15.0,
      price_5d_h: 15.26,
      price_5d_L: 14.9,
      price_1m_h: 15.4,
      price_1m_l: 13.6,
      price_3m_h: 15.82,
      price_3m_l: 13.6,
      price_6m_h: 17.2,
      price_6m_l: 13.6,
      price_52w_h: 18.24,
      price_52w_l: 13.6,
      price_pc_1d: -0.393,
      price_pc_1w: 1.333,
      price_pc_1m: 1.333,
      price_pc_6m: -8.764,
      price_pc_1y: -10.588,
      price_pc_ytd: -10.588,
    },
    "0053": {
      id: "0053",
      c: 83.2,
      ma50: 85.53,
      ma100: 86.572,
      ma150: 87.882,
      ma200: 88.277,
      maw30: 87.978,
      rs: 63.0,
      rsd1d: 1.0,
      rsd5d: 3.0,
      rsd10d: -4.0,
      price_5d_h: 83.9,
      price_5d_L: 82.0,
      price_1m_h: 87.5,
      price_1m_l: 82.0,
      price_3m_h: 88.5,
      price_3m_l: 82.0,
      price_6m_h: 93.0,
      price_6m_l: 82.0,
      price_52w_h: 96.6,
      price_52w_l: 82.0,
      price_pc_1d: -0.834,
      price_pc_1w: 0.241,
      price_pc_1m: -2.69,
      price_pc_6m: -7.039,
      price_pc_1y: -12.88,
      price_pc_ytd: -12.88,
    },
    "0057": {
      id: "0057",
      c: 2.32,
      ma50: 2.353,
      ma100: 2.489,
      ma150: 2.483,
      ma200: 2.552,
      maw30: 2.494,
      rs: 65.0,
      rsd1d: -1.0,
      rsd5d: 5.0,
      rsd10d: -2.0,
      price_5d_h: 2.38,
      price_5d_L: 2.23,
      price_1m_h: 2.43,
      price_1m_l: 2.0,
      price_3m_h: 2.51,
      price_3m_l: 2.0,
      price_6m_h: 3.93,
      price_6m_l: 2.0,
      price_52w_h: 3.93,
      price_52w_l: 2.0,
      price_pc_1d: -0.855,
      price_pc_1w: 0.0,
      price_pc_1m: -1.277,
      price_pc_6m: -0.855,
      price_pc_1y: -6.827,
      price_pc_ytd: -6.827,
    },
    "0059": {
      id: "0059",
      c: 0.7,
      ma50: 0.832,
      ma100: 0.85,
      ma150: 0.874,
      ma200: 0.888,
      maw30: 0.873,
      rs: 32.0,
      rsd1d: -5.0,
      rsd5d: -10.0,
      rsd10d: -24.0,
      price_5d_h: 0.77,
      price_5d_L: 0.7,
      price_1m_h: 0.85,
      price_1m_l: 0.7,
      price_3m_h: 0.95,
      price_3m_l: 0.7,
      price_6m_h: 0.97,
      price_6m_l: 0.7,
      price_52w_h: 1.11,
      price_52w_l: 0.7,
      price_pc_1d: -5.405,
      price_pc_1w: -9.091,
      price_pc_1m: -17.647,
      price_pc_6m: -23.077,
      price_pc_1y: -27.835,
      price_pc_ytd: -27.835,
    },
    "0062": {
      id: "0062",
      c: 12.76,
      ma50: 12.793,
      ma100: 12.819,
      ma150: 13.089,
      ma200: 13.535,
      maw30: 13.085,
      rs: 59.0,
      rsd1d: -1.0,
      rsd5d: -7.0,
      rsd10d: 2.0,
      price_5d_h: 13.1,
      price_5d_L: 12.7,
      price_1m_h: 13.2,
      price_1m_l: 12.14,
      price_3m_h: 13.2,
      price_3m_l: 12.14,
      price_6m_h: 13.7,
      price_6m_l: 12.14,
      price_52w_h: 16.5,
      price_52w_l: 12.14,
      price_pc_1d: -0.623,
      price_pc_1w: -2.892,
      price_pc_1m: 1.754,
      price_pc_6m: -6.176,
      price_pc_1y: -17.677,
      price_pc_ytd: -17.677,
    },
    "0064": {
      id: "0064",
      c: 0.152,
      ma50: 0.158,
      ma100: 0.16,
      ma150: 0.164,
      ma200: 0.168,
      maw30: 0.164,
      rs: 49.0,
      rsd1d: -3.0,
      rsd5d: 6.0,
      rsd10d: 6.0,
      price_5d_h: 0.16,
      price_5d_L: 0.151,
      price_1m_h: 0.16,
      price_1m_l: 0.138,
      price_3m_h: 0.169,
      price_3m_l: 0.138,
      price_6m_h: 0.172,
      price_6m_l: 0.138,
      price_52w_h: 0.209,
      price_52w_l: 0.138,
      price_pc_1d: -3.185,
      price_pc_1w: 0.662,
      price_pc_1m: -5.59,
      price_pc_6m: -10.059,
      price_pc_1y: -23.232,
      price_pc_ytd: -23.232,
    },
    "0066": {
      id: "0066",
      c: 42.35,
      ma50: 41.848,
      ma100: 41.898,
      ma150: 42.202,
      ma200: 42.819,
      maw30: 42.235,
      rs: 73.0,
      rsd1d: 1.0,
      rsd5d: 2.0,
      rsd10d: 4.0,
      price_5d_h: 42.8,
      price_5d_L: 41.5,
      price_1m_h: 42.8,
      price_1m_l: 39.35,
      price_3m_h: 43.15,
      price_3m_l: 39.35,
      price_6m_h: 43.5,
      price_6m_l: 39.35,
      price_52w_h: 47.4,
      price_52w_l: 39.35,
      price_pc_1d: -0.236,
      price_pc_1w: 0.833,
      price_pc_1m: 4.697,
      price_pc_6m: 0.833,
      price_pc_1y: -3.75,
      price_pc_ytd: -3.75,
    },
    "0069": {
      id: "0069",
      c: 6.16,
      ma50: 6.381,
      ma100: 6.403,
      ma150: 6.387,
      ma200: 6.564,
      maw30: 6.379,
      rs: 55.0,
      rsd1d: 2.0,
      rsd5d: -1.0,
      rsd10d: 0.0,
      price_5d_h: 6.39,
      price_5d_L: 5.97,
      price_1m_h: 6.67,
      price_1m_l: 5.39,
      price_3m_h: 7.27,
      price_3m_l: 5.39,
      price_6m_h: 7.27,
      price_6m_l: 5.39,
      price_52w_h: 7.98,
      price_52w_l: 5.39,
      price_pc_1d: 0.163,
      price_pc_1w: -1.282,
      price_pc_1m: -3.297,
      price_pc_6m: -0.485,
      price_pc_1y: -21.628,
      price_pc_ytd: -21.628,
    },
    "0070": {
      id: "0070",
      c: 0.066,
      ma50: 0.073,
      ma100: 0.077,
      ma150: 0.079,
      ma200: 0.088,
      maw30: 0.079,
      rs: 29.0,
      rsd1d: -4.0,
      rsd5d: -15.0,
      rsd10d: 0.0,
      price_5d_h: 0.075,
      price_5d_L: 0.062,
      price_1m_h: 0.078,
      price_1m_l: 0.058,
      price_3m_h: 0.089,
      price_3m_l: 0.058,
      price_6m_h: 0.125,
      price_6m_l: 0.058,
      price_52w_h: 0.238,
      price_52w_l: 0.058,
      price_pc_1d: -5.714,
      price_pc_1w: -5.714,
      price_pc_1m: -14.286,
      price_pc_6m: -16.456,
      price_pc_1y: -5.714,
      price_pc_ytd: -5.714,
    },
    "0071": {
      id: "0071",
      c: 12.52,
      ma50: 12.797,
      ma100: 12.816,
      ma150: 12.996,
      ma200: 13.47,
      maw30: 12.963,
      rs: 59.0,
      rsd1d: 1.0,
      rsd5d: -2.0,
      rsd10d: -4.0,
      price_5d_h: 12.8,
      price_5d_L: 12.34,
      price_1m_h: 12.88,
      price_1m_l: 11.7,
      price_3m_h: 13.68,
      price_3m_l: 11.7,
      price_6m_h: 13.68,
      price_6m_l: 11.7,
      price_52w_h: 16.3,
      price_52w_l: 11.7,
      price_pc_1d: 0.0,
      price_pc_1w: -0.477,
      price_pc_1m: -2.946,
      price_pc_6m: -3.988,
      price_pc_1y: -14.714,
      price_pc_ytd: -14.714,
    },
    "0072": {
      id: "0072",
      c: 0.177,
      ma50: 0.187,
      ma100: 0.187,
      ma150: 0.184,
      ma200: 0.184,
      maw30: 0.184,
      rs: 69.0,
      rsd1d: -12.0,
      rsd5d: -9.0,
      rsd10d: -16.0,
      price_5d_h: 0.193,
      price_5d_L: 0.177,
      price_1m_h: 0.21,
      price_1m_l: 0.172,
      price_3m_h: 0.22,
      price_3m_l: 0.164,
      price_6m_h: 0.22,
      price_6m_l: 0.164,
      price_52w_h: 0.22,
      price_52w_l: 0.15,
      price_pc_1d: -6.842,
      price_pc_1w: -6.842,
      price_pc_1m: -1.667,
      price_pc_6m: 0.0,
      price_pc_1y: -11.5,
      price_pc_ytd: -11.5,
    },
    "0073": {
      id: "0073",
      c: 0.125,
      ma50: 0.145,
      ma100: 0.158,
      ma150: 0.174,
      ma200: 0.198,
      maw30: 0.173,
      rs: 12.0,
      rsd1d: -1.0,
      rsd5d: 3.0,
      rsd10d: -2.0,
      price_5d_h: 0.14,
      price_5d_L: 0.115,
      price_1m_h: 0.151,
      price_1m_l: 0.114,
      price_3m_h: 0.18,
      price_3m_l: 0.114,
      price_6m_h: 0.212,
      price_6m_l: 0.114,
      price_52w_h: 0.405,
      price_52w_l: 0.114,
      price_pc_1d: -8.759,
      price_pc_1w: 0.806,
      price_pc_1m: -16.667,
      price_pc_6m: -39.32,
      price_pc_1y: -66.216,
      price_pc_ytd: -66.216,
    },
    "0076": {
      id: "0076",
      c: 0.039,
      ma50: 0.041,
      ma100: 0.041,
      ma150: 0.036,
      ma200: 0.034,
      maw30: 0.037,
      rs: 92.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: -2.0,
      price_5d_h: 0.042,
      price_5d_L: 0.038,
      price_1m_h: 0.043,
      price_1m_l: 0.032,
      price_3m_h: 0.06,
      price_3m_l: 0.032,
      price_6m_h: 0.06,
      price_6m_l: 0.023,
      price_52w_h: 0.06,
      price_52w_l: 0.015,
      price_pc_1d: -2.5,
      price_pc_1w: -2.5,
      price_pc_1m: -4.878,
      price_pc_6m: 39.286,
      price_pc_1y: 143.75,
      price_pc_ytd: 143.75,
    },
    "0077": {
      id: "0077",
      c: 0.69,
      ma50: 0.701,
      ma100: 0.716,
      ma150: 0.697,
      ma200: 0.696,
      maw30: 0.696,
      rs: 72.0,
      rsd1d: 1.0,
      rsd5d: -5.0,
      rsd10d: -2.0,
      price_5d_h: 0.71,
      price_5d_L: 0.69,
      price_1m_h: 0.71,
      price_1m_l: 0.64,
      price_3m_h: 0.75,
      price_3m_l: 0.64,
      price_6m_h: 0.75,
      price_6m_l: 0.63,
      price_52w_h: 0.79,
      price_52w_l: 0.56,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: 1.471,
      price_pc_6m: 2.985,
      price_pc_1y: 4.545,
      price_pc_ytd: 4.545,
    },
    "0078": {
      id: "0078",
      c: 3.21,
      ma50: 3.223,
      ma100: 3.174,
      ma150: 3.231,
      ma200: 3.421,
      maw30: 3.221,
      rs: 73.0,
      rsd1d: 3.0,
      rsd5d: 3.0,
      rsd10d: 5.0,
      price_5d_h: 3.48,
      price_5d_L: 3.1,
      price_1m_h: 3.48,
      price_1m_l: 2.99,
      price_3m_h: 3.48,
      price_3m_l: 2.99,
      price_6m_h: 3.48,
      price_6m_l: 2.92,
      price_52w_h: 4.27,
      price_52w_l: 2.92,
      price_pc_1d: 0.0,
      price_pc_1w: 0.943,
      price_pc_1m: -0.311,
      price_pc_6m: 3.548,
      price_pc_1y: -2.432,
      price_pc_ytd: -2.432,
    },
    "0080": {
      id: "0080",
      c: 0.206,
      ma50: 0.215,
      ma100: 0.237,
      ma150: 0.217,
      ma200: 0.212,
      maw30: 0.218,
      rs: 72.0,
      rsd1d: 6.0,
      rsd5d: 12.0,
      rsd10d: 25.0,
      price_5d_h: 0.218,
      price_5d_L: 0.2,
      price_1m_h: 0.228,
      price_1m_l: 0.18,
      price_3m_h: 0.265,
      price_3m_l: 0.18,
      price_6m_h: 0.4,
      price_6m_l: 0.162,
      price_52w_h: 0.4,
      price_52w_l: 0.161,
      price_pc_1d: -1.435,
      price_pc_1w: -0.483,
      price_pc_1m: -3.738,
      price_pc_6m: 27.16,
      price_pc_1y: -5.936,
      price_pc_ytd: -5.936,
    },
    "0081": {
      id: "0081",
      c: 4.6,
      ma50: 4.598,
      ma100: 4.226,
      ma150: 4.322,
      ma200: 4.44,
      maw30: 4.309,
      rs: 80.0,
      rsd1d: -2.0,
      rsd5d: -7.0,
      rsd10d: 6.0,
      price_5d_h: 4.86,
      price_5d_L: 4.37,
      price_1m_h: 4.99,
      price_1m_l: 3.33,
      price_3m_h: 5.05,
      price_3m_l: 3.33,
      price_6m_h: 5.05,
      price_6m_l: 3.33,
      price_52w_h: 5.34,
      price_52w_l: 3.33,
      price_pc_1d: -0.217,
      price_pc_1w: -4.564,
      price_pc_1m: 2.222,
      price_pc_6m: -3.564,
      price_pc_1y: -8.549,
      price_pc_ytd: -8.549,
    },
    "0082": {
      id: "0082",
      c: 0.335,
      ma50: 0.339,
      ma100: 0.341,
      ma150: 0.378,
      ma200: 0.441,
      maw30: 0.374,
      rs: 30.0,
      rsd1d: 1.0,
      rsd5d: -15.0,
      rsd10d: 5.0,
      price_5d_h: 0.375,
      price_5d_L: 0.33,
      price_1m_h: 0.38,
      price_1m_l: 0.28,
      price_3m_h: 0.405,
      price_3m_l: 0.28,
      price_6m_h: 0.47,
      price_6m_l: 0.28,
      price_52w_h: 0.86,
      price_52w_l: 0.28,
      price_pc_1d: -1.471,
      price_pc_1w: -6.944,
      price_pc_1m: -10.667,
      price_pc_6m: -23.864,
      price_pc_1y: -46.825,
      price_pc_ytd: -46.825,
    },
    "0083": {
      id: "0083",
      c: 10.16,
      ma50: 10.082,
      ma100: 9.898,
      ma150: 10.197,
      ma200: 10.746,
      maw30: 10.148,
      rs: 68.0,
      rsd1d: -2.0,
      rsd5d: -9.0,
      rsd10d: -2.0,
      price_5d_h: 10.78,
      price_5d_L: 10.1,
      price_1m_h: 10.78,
      price_1m_l: 9.36,
      price_3m_h: 10.78,
      price_3m_l: 9.36,
      price_6m_h: 11.0,
      price_6m_l: 9.22,
      price_52w_h: 12.98,
      price_52w_l: 9.22,
      price_pc_1d: -2.682,
      price_pc_1w: -3.788,
      price_pc_1m: 3.357,
      price_pc_6m: -3.053,
      price_pc_1y: -6.273,
      price_pc_ytd: -6.273,
    },
    "0085": {
      id: "0085",
      c: 0.58,
      ma50: 0.637,
      ma100: 0.675,
      ma150: 0.719,
      ma200: 0.756,
      maw30: 0.717,
      rs: 30.0,
      rsd1d: 1.0,
      rsd5d: 5.0,
      rsd10d: 5.0,
      price_5d_h: 0.63,
      price_5d_L: 0.55,
      price_1m_h: 0.67,
      price_1m_l: 0.49,
      price_3m_h: 0.73,
      price_3m_l: 0.49,
      price_6m_h: 0.89,
      price_6m_l: 0.49,
      price_52w_h: 1.03,
      price_52w_l: 0.49,
      price_pc_1d: -3.333,
      price_pc_1w: 1.754,
      price_pc_1m: -12.121,
      price_pc_6m: -26.582,
      price_pc_1y: -22.667,
      price_pc_ytd: -22.667,
    },
    "0086": {
      id: "0086",
      c: 3.99,
      ma50: 4.028,
      ma100: 4.056,
      ma150: 4.077,
      ma200: 4.073,
      maw30: 4.077,
      rs: 70.0,
      rsd1d: 1.0,
      rsd5d: -2.0,
      rsd10d: -8.0,
      price_5d_h: 4.02,
      price_5d_L: 3.9,
      price_1m_h: 4.09,
      price_1m_l: 3.6,
      price_3m_h: 4.3,
      price_3m_l: 3.6,
      price_6m_h: 5.04,
      price_6m_l: 3.6,
      price_52w_h: 5.04,
      price_52w_l: 3.6,
      price_pc_1d: 0.0,
      price_pc_1w: 0.504,
      price_pc_1m: 0.758,
      price_pc_6m: -0.499,
      price_pc_1y: 1.013,
      price_pc_ytd: 1.013,
    },
    "0087": {
      id: "0087",
      c: 7.9,
      ma50: 7.711,
      ma100: 7.695,
      ma150: 7.851,
      ma200: 8.044,
      maw30: 7.824,
      rs: 69.0,
      rsd1d: -2.0,
      rsd5d: -2.0,
      rsd10d: 5.0,
      price_5d_h: 8.05,
      price_5d_L: 7.82,
      price_1m_h: 8.05,
      price_1m_l: 6.86,
      price_3m_h: 8.28,
      price_3m_l: 6.86,
      price_6m_h: 8.48,
      price_6m_l: 6.86,
      price_52w_h: 10.1,
      price_52w_l: 6.86,
      price_pc_1d: -1.25,
      price_pc_1w: -0.253,
      price_pc_1m: 7.629,
      price_pc_6m: 3.133,
      price_pc_1y: -14.687,
      price_pc_ytd: -14.687,
    },
    "0089": {
      id: "0089",
      c: 4.34,
      ma50: 4.112,
      ma100: 4.079,
      ma150: 4.115,
      ma200: 4.168,
      maw30: 4.105,
      rs: 81.0,
      rsd1d: 4.0,
      rsd5d: 11.0,
      rsd10d: 5.0,
      price_5d_h: 4.38,
      price_5d_L: 4.06,
      price_1m_h: 4.38,
      price_1m_l: 3.87,
      price_3m_h: 4.48,
      price_3m_l: 3.87,
      price_6m_h: 4.48,
      price_6m_l: 3.87,
      price_52w_h: 4.5,
      price_52w_l: 3.87,
      price_pc_1d: 3.333,
      price_pc_1w: 5.854,
      price_pc_1m: 5.596,
      price_pc_6m: 3.58,
      price_pc_1y: 2.118,
      price_pc_ytd: 2.118,
    },
    "0090": {
      id: "0090",
      c: 0.69,
      ma50: 0.779,
      ma100: 0.78,
      ma150: 0.812,
      ma200: 0.844,
      maw30: 0.814,
      rs: 30.0,
      rsd1d: -34.0,
      rsd5d: -36.0,
      rsd10d: -32.0,
      price_5d_h: 0.82,
      price_5d_L: 0.68,
      price_1m_h: 0.85,
      price_1m_l: 0.68,
      price_3m_h: 0.85,
      price_3m_l: 0.68,
      price_6m_h: 1.01,
      price_6m_l: 0.68,
      price_52w_h: 1.11,
      price_52w_l: 0.68,
      price_pc_1d: -12.658,
      price_pc_1w: -9.211,
      price_pc_1m: -11.538,
      price_pc_6m: -28.125,
      price_pc_1y: -22.472,
      price_pc_ytd: -22.472,
    },
    "0091": {
      id: "0091",
      c: 0.205,
      ma50: 0.208,
      ma100: 0.207,
      ma150: 0.217,
      ma200: 0.218,
      maw30: 0.218,
      rs: 60.0,
      rsd1d: 5.0,
      rsd5d: -17.0,
      rsd10d: -9.0,
      price_5d_h: 0.205,
      price_5d_L: 0.192,
      price_1m_h: 0.228,
      price_1m_l: 0.189,
      price_3m_h: 0.228,
      price_3m_l: 0.184,
      price_6m_h: 0.255,
      price_6m_l: 0.154,
      price_52w_h: 0.265,
      price_52w_l: 0.154,
      price_pc_1d: 1.485,
      price_pc_1w: -0.485,
      price_pc_1m: -6.393,
      price_pc_6m: -11.638,
      price_pc_1y: -21.154,
      price_pc_ytd: -21.154,
    },
    "0092": {
      id: "0092",
      c: 0.083,
      ma50: 0.098,
      ma100: 0.103,
      ma150: 0.102,
      ma200: 0.1,
      maw30: 0.103,
      rs: 32.0,
      rsd1d: -1.0,
      rsd5d: 2.0,
      rsd10d: -9.0,
      price_5d_h: 0.094,
      price_5d_L: 0.083,
      price_1m_h: 0.1,
      price_1m_l: 0.068,
      price_3m_h: 0.116,
      price_3m_l: 0.068,
      price_6m_h: 0.128,
      price_6m_l: 0.068,
      price_52w_h: 0.148,
      price_52w_l: 0.068,
      price_pc_1d: -3.488,
      price_pc_1w: -2.353,
      price_pc_1m: -11.702,
      price_pc_6m: -15.306,
      price_pc_1y: -38.519,
      price_pc_ytd: -38.519,
    },
    "0095": {
      id: "0095",
      c: 0.87,
      ma50: 1.21,
      ma100: 1.35,
      ma150: 1.489,
      ma200: 1.6,
      maw30: 1.499,
      rs: 9.0,
      rsd1d: 2.0,
      rsd5d: 3.0,
      rsd10d: 3.0,
      price_5d_h: 0.95,
      price_5d_L: 0.72,
      price_1m_h: 1.38,
      price_1m_l: 0.59,
      price_3m_h: 1.62,
      price_3m_l: 0.59,
      price_6m_h: 1.87,
      price_6m_l: 0.59,
      price_52w_h: 2.33,
      price_52w_l: 0.59,
      price_pc_1d: 0.0,
      price_pc_1w: 1.163,
      price_pc_1m: -35.075,
      price_pc_6m: -52.459,
      price_pc_1y: -59.346,
      price_pc_ytd: -59.346,
    },
    "0097": {
      id: "0097",
      c: 0.335,
      ma50: 0.356,
      ma100: 0.361,
      ma150: 0.364,
      ma200: 0.375,
      maw30: 0.363,
      rs: 54.0,
      rsd1d: -2.0,
      rsd5d: -2.0,
      rsd10d: 1.0,
      price_5d_h: 0.35,
      price_5d_L: 0.335,
      price_1m_h: 0.365,
      price_1m_l: 0.32,
      price_3m_h: 0.395,
      price_3m_l: 0.32,
      price_6m_h: 0.41,
      price_6m_l: 0.32,
      price_52w_h: 0.46,
      price_52w_l: 0.32,
      price_pc_1d: -2.899,
      price_pc_1w: -2.899,
      price_pc_1m: -6.944,
      price_pc_6m: -2.899,
      price_pc_1y: -18.293,
      price_pc_ytd: -18.293,
    },
    "0098": {
      id: "0098",
      c: 10.64,
      ma50: 9.55,
      ma100: 8.591,
      ma150: 9.306,
      ma200: 10.136,
      maw30: 9.213,
      rs: 90.0,
      rsd1d: 1.0,
      rsd5d: 4.0,
      rsd10d: 1.0,
      price_5d_h: 10.8,
      price_5d_L: 9.5,
      price_1m_h: 10.8,
      price_1m_l: 8.91,
      price_3m_h: 11.06,
      price_3m_l: 7.01,
      price_6m_h: 11.06,
      price_6m_l: 7.0,
      price_52w_h: 13.88,
      price_52w_l: 7.0,
      price_pc_1d: 4.931,
      price_pc_1w: 11.882,
      price_pc_1m: 2.308,
      price_pc_6m: 2.308,
      price_pc_1y: 23.291,
      price_pc_ytd: 23.291,
    },
    "0101": {
      id: "0101",
      c: 15.8,
      ma50: 16.231,
      ma100: 16.197,
      ma150: 16.824,
      ma200: 17.521,
      maw30: 16.833,
      rs: 55.0,
      rsd1d: 2.0,
      rsd5d: -3.0,
      rsd10d: 1.0,
      price_5d_h: 16.12,
      price_5d_L: 15.6,
      price_1m_h: 17.02,
      price_1m_l: 14.02,
      price_3m_h: 17.22,
      price_3m_l: 14.02,
      price_6m_h: 18.68,
      price_6m_l: 14.02,
      price_52w_h: 22.25,
      price_52w_l: 14.02,
      price_pc_1d: -0.629,
      price_pc_1w: -1.25,
      price_pc_1m: -3.541,
      price_pc_6m: -11.036,
      price_pc_1y: -22.549,
      price_pc_ytd: -22.549,
    },
    "0102": {
      id: "0102",
      c: 0.083,
      ma50: 0.101,
      ma100: 0.18,
      ma150: 0.296,
      ma200: 0.387,
      maw30: 0.294,
      rs: 2.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.093,
      price_5d_L: 0.08,
      price_1m_h: 0.129,
      price_1m_l: 0.07,
      price_3m_h: 0.165,
      price_3m_l: 0.07,
      price_6m_h: 0.55,
      price_6m_l: 0.07,
      price_52w_h: 1.0,
      price_52w_l: 0.07,
      price_pc_1d: 0.0,
      price_pc_1w: -1.19,
      price_pc_1m: -17.0,
      price_pc_6m: -84.34,
      price_pc_1y: -91.17,
      price_pc_ytd: -91.17,
    },
    "0103": {
      id: "0103",
      c: 0.265,
      ma50: 0.308,
      ma100: 0.323,
      ma150: 0.309,
      ma200: 0.298,
      maw30: 0.312,
      rs: 55.0,
      rsd1d: -3.0,
      rsd5d: -11.0,
      rsd10d: -17.0,
      price_5d_h: 0.305,
      price_5d_L: 0.265,
      price_1m_h: 0.31,
      price_1m_l: 0.241,
      price_3m_h: 0.36,
      price_3m_l: 0.241,
      price_6m_h: 0.445,
      price_6m_l: 0.241,
      price_52w_h: 0.445,
      price_52w_l: 0.241,
      price_pc_1d: -3.636,
      price_pc_1w: -8.621,
      price_pc_1m: -10.169,
      price_pc_6m: 1.923,
      price_pc_1y: -7.018,
      price_pc_ytd: -7.018,
    },
    "0106": {
      id: "0106",
      c: 0.26,
      ma50: 0.252,
      ma100: 0.321,
      ma150: 0.376,
      ma200: 0.413,
      maw30: 0.379,
      rs: 12.0,
      rsd1d: 4.0,
      rsd5d: 4.0,
      rsd10d: 4.0,
      price_5d_h: 0.26,
      price_5d_L: 0.223,
      price_1m_h: 0.265,
      price_1m_l: 0.192,
      price_3m_h: 0.4,
      price_3m_l: 0.192,
      price_6m_h: 0.59,
      price_6m_l: 0.192,
      price_52w_h: 0.67,
      price_52w_l: 0.192,
      price_pc_1d: 6.996,
      price_pc_1w: 6.122,
      price_pc_1m: 17.647,
      price_pc_6m: -50.0,
      price_pc_1y: -50.943,
      price_pc_ytd: -50.943,
    },
    "0107": {
      id: "0107",
      c: 1.94,
      ma50: 2.067,
      ma100: 1.974,
      ma150: 1.925,
      ma200: 1.884,
      maw30: 1.928,
      rs: 75.0,
      rsd1d: -6.0,
      rsd5d: -9.0,
      rsd10d: -11.0,
      price_5d_h: 2.04,
      price_5d_L: 1.93,
      price_1m_h: 2.15,
      price_1m_l: 1.78,
      price_3m_h: 2.3,
      price_3m_l: 1.78,
      price_6m_h: 2.3,
      price_6m_l: 1.73,
      price_52w_h: 2.3,
      price_52w_l: 1.68,
      price_pc_1d: -3.96,
      price_pc_1w: -3.483,
      price_pc_1m: -10.185,
      price_pc_6m: 6.593,
      price_pc_1y: 1.042,
      price_pc_ytd: 1.042,
    },
    "0108": {
      id: "0108",
      c: 1.14,
      ma50: 1.081,
      ma100: 1.062,
      ma150: 1.056,
      ma200: 1.04,
      maw30: 1.05,
      rs: 87.0,
      rsd1d: -2.0,
      rsd5d: 1.0,
      rsd10d: -4.0,
      price_5d_h: 1.2,
      price_5d_L: 1.07,
      price_1m_h: 1.2,
      price_1m_l: 1.0,
      price_3m_h: 1.27,
      price_3m_l: 0.98,
      price_6m_h: 1.45,
      price_6m_l: 0.95,
      price_52w_h: 1.45,
      price_52w_l: 0.84,
      price_pc_1d: -3.39,
      price_pc_1w: 3.636,
      price_pc_1m: 3.636,
      price_pc_6m: 8.571,
      price_pc_1y: 12.871,
      price_pc_ytd: 12.871,
    },
    "0110": {
      id: "0110",
      c: 0.365,
      ma50: 0.382,
      ma100: 0.401,
      ma150: 0.414,
      ma200: 0.417,
      maw30: 0.414,
      rs: 40.0,
      rsd1d: 8.0,
      rsd5d: 9.0,
      rsd10d: 7.0,
      price_5d_h: 0.365,
      price_5d_L: 0.32,
      price_1m_h: 0.39,
      price_1m_l: 0.275,
      price_3m_h: 0.415,
      price_3m_l: 0.275,
      price_6m_h: 0.495,
      price_6m_l: 0.275,
      price_52w_h: 0.59,
      price_52w_l: 0.275,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: -6.41,
      price_pc_6m: -15.116,
      price_pc_1y: -32.407,
      price_pc_ytd: -32.407,
    },
    "0111": {
      id: "0111",
      c: 0.415,
      ma50: 0.416,
      ma100: 0.429,
      ma150: 0.444,
      ma200: 0.448,
      maw30: 0.445,
      rs: 55.0,
      rsd1d: 1.0,
      rsd5d: 3.0,
      rsd10d: 15.0,
      price_5d_h: 0.42,
      price_5d_L: 0.4,
      price_1m_h: 0.425,
      price_1m_l: 0.375,
      price_3m_h: 0.45,
      price_3m_l: 0.375,
      price_6m_h: 0.5,
      price_6m_l: 0.375,
      price_52w_h: 0.59,
      price_52w_l: 0.375,
      price_pc_1d: -1.19,
      price_pc_1w: 0.0,
      price_pc_1m: -1.19,
      price_pc_6m: -10.753,
      price_pc_1y: -18.627,
      price_pc_ytd: -18.627,
    },
    "0113": {
      id: "0113",
      c: 4.0,
      ma50: 4.122,
      ma100: 4.216,
      ma150: 4.19,
      ma200: 4.274,
      maw30: 4.197,
      rs: 61.0,
      rsd1d: 2.0,
      rsd5d: 0.0,
      rsd10d: 6.0,
      price_5d_h: 4.1,
      price_5d_L: 3.89,
      price_1m_h: 4.12,
      price_1m_l: 3.7,
      price_3m_h: 4.48,
      price_3m_l: 3.7,
      price_6m_h: 4.51,
      price_6m_l: 3.7,
      price_52w_h: 4.76,
      price_52w_l: 3.7,
      price_pc_1d: 0.0,
      price_pc_1w: -0.99,
      price_pc_1m: -0.249,
      price_pc_6m: -1.961,
      price_pc_1y: -3.382,
      price_pc_ytd: -3.382,
    },
    "0114": {
      id: "0114",
      c: 0.495,
      ma50: 0.538,
      ma100: 0.54,
      ma150: 0.563,
      ma200: 0.588,
      maw30: 0.563,
      rs: 41.0,
      rsd1d: -1.0,
      rsd5d: 4.0,
      rsd10d: -1.0,
      price_5d_h: 0.52,
      price_5d_L: 0.49,
      price_1m_h: 0.55,
      price_1m_l: 0.49,
      price_3m_h: 0.68,
      price_3m_l: 0.48,
      price_6m_h: 0.68,
      price_6m_l: 0.48,
      price_52w_h: 0.74,
      price_52w_l: 0.48,
      price_pc_1d: -1.0,
      price_pc_1w: -4.808,
      price_pc_1m: -10.0,
      price_pc_6m: -16.102,
      price_pc_1y: -26.119,
      price_pc_ytd: -26.119,
    },
    "0116": {
      id: "0116",
      c: 9.1,
      ma50: 10.598,
      ma100: 10.783,
      ma150: 11.064,
      ma200: 11.537,
      maw30: 11.085,
      rs: 32.0,
      rsd1d: 2.0,
      rsd5d: -13.0,
      rsd10d: -25.0,
      price_5d_h: 9.77,
      price_5d_L: 9.0,
      price_1m_h: 11.36,
      price_1m_l: 9.0,
      price_3m_h: 11.98,
      price_3m_l: 9.0,
      price_6m_h: 12.86,
      price_6m_l: 9.0,
      price_52w_h: 14.98,
      price_52w_l: 9.0,
      price_pc_1d: -0.763,
      price_pc_1w: -7.238,
      price_pc_1m: -16.819,
      price_pc_6m: -21.955,
      price_pc_1y: -26.016,
      price_pc_ytd: -26.016,
    },
    "0117": {
      id: "0117",
      c: 0.475,
      ma50: 0.474,
      ma100: 0.53,
      ma150: 0.612,
      ma200: 0.651,
      maw30: 0.607,
      rs: 43.0,
      rsd1d: 23.0,
      rsd5d: 24.0,
      rsd10d: 18.0,
      price_5d_h: 0.52,
      price_5d_L: 0.405,
      price_1m_h: 0.52,
      price_1m_l: 0.4,
      price_3m_h: 0.54,
      price_3m_l: 0.4,
      price_6m_h: 0.84,
      price_6m_l: 0.4,
      price_52w_h: 1.1,
      price_52w_l: 0.4,
      price_pc_1d: 17.284,
      price_pc_1w: 13.095,
      price_pc_1m: -8.654,
      price_pc_6m: -33.099,
      price_pc_1y: -6.863,
      price_pc_ytd: -6.863,
    },
    "0118": {
      id: "0118",
      c: 0.37,
      ma50: 0.405,
      ma100: 0.417,
      ma150: 0.426,
      ma200: 0.421,
      maw30: 0.427,
      rs: 44.0,
      rsd1d: -2.0,
      rsd5d: -2.0,
      rsd10d: -1.0,
      price_5d_h: 0.39,
      price_5d_L: 0.355,
      price_1m_h: 0.405,
      price_1m_l: 0.355,
      price_3m_h: 0.43,
      price_3m_l: 0.355,
      price_6m_h: 0.49,
      price_6m_l: 0.355,
      price_52w_h: 0.54,
      price_52w_l: 0.335,
      price_pc_1d: -2.632,
      price_pc_1w: -5.128,
      price_pc_1m: -8.642,
      price_pc_6m: -17.778,
      price_pc_1y: -27.451,
      price_pc_ytd: -27.451,
    },
    "0119": {
      id: "0119",
      c: 1.98,
      ma50: 2.044,
      ma100: 2.023,
      ma150: 2.021,
      ma200: 2.014,
      maw30: 2.025,
      rs: 65.0,
      rsd1d: 2.0,
      rsd5d: 2.0,
      rsd10d: 2.0,
      price_5d_h: 2.05,
      price_5d_L: 1.84,
      price_1m_h: 2.15,
      price_1m_l: 1.47,
      price_3m_h: 2.25,
      price_3m_l: 1.47,
      price_6m_h: 2.25,
      price_6m_l: 1.47,
      price_52w_h: 2.31,
      price_52w_l: 1.47,
      price_pc_1d: 1.02,
      price_pc_1w: 3.125,
      price_pc_1m: -6.604,
      price_pc_6m: -3.415,
      price_pc_1y: -13.158,
      price_pc_ytd: -13.158,
    },
    "0120": {
      id: "0120",
      c: 1.46,
      ma50: 1.377,
      ma100: 1.343,
      ma150: 1.26,
      ma200: 1.261,
      maw30: 1.264,
      rs: 78.0,
      rsd1d: -4.0,
      rsd5d: -8.0,
      rsd10d: -12.0,
      price_5d_h: 1.54,
      price_5d_L: 1.37,
      price_1m_h: 1.54,
      price_1m_l: 1.21,
      price_3m_h: 1.74,
      price_3m_l: 1.16,
      price_6m_h: 1.74,
      price_6m_l: 1.02,
      price_52w_h: 1.74,
      price_52w_l: 1.0,
      price_pc_1d: 0.0,
      price_pc_1w: 3.546,
      price_pc_1m: 8.148,
      price_pc_6m: 28.07,
      price_pc_1y: 15.873,
      price_pc_ytd: 15.873,
    },
    "0122": {
      id: "0122",
      c: 0.34,
      ma50: 0.353,
      ma100: 0.373,
      ma150: 0.391,
      ma200: 0.402,
      maw30: 0.39,
      rs: 45.0,
      rsd1d: -1.0,
      rsd5d: -15.0,
      rsd10d: -8.0,
      price_5d_h: 0.36,
      price_5d_L: 0.34,
      price_1m_h: 0.36,
      price_1m_l: 0.31,
      price_3m_h: 0.405,
      price_3m_l: 0.31,
      price_6m_h: 0.445,
      price_6m_l: 0.31,
      price_52w_h: 0.61,
      price_52w_l: 0.31,
      price_pc_1d: -1.449,
      price_pc_1w: -2.857,
      price_pc_1m: -2.857,
      price_pc_6m: -17.073,
      price_pc_1y: -8.108,
      price_pc_ytd: -8.108,
    },
    "0123": {
      id: "0123",
      c: 7.79,
      ma50: 7.89,
      ma100: 7.49,
      ma150: 7.378,
      ma200: 7.48,
      maw30: 7.417,
      rs: 79.0,
      rsd1d: -3.0,
      rsd5d: -4.0,
      rsd10d: -2.0,
      price_5d_h: 7.99,
      price_5d_L: 7.6,
      price_1m_h: 8.05,
      price_1m_l: 6.78,
      price_3m_h: 8.48,
      price_3m_l: 6.78,
      price_6m_h: 8.48,
      price_6m_l: 6.4,
      price_52w_h: 9.9,
      price_52w_l: 6.4,
      price_pc_1d: -1.142,
      price_pc_1w: -2.136,
      price_pc_1m: -0.384,
      price_pc_6m: 6.421,
      price_pc_1y: -11.977,
      price_pc_ytd: -11.977,
    },
    "0124": {
      id: "0124",
      c: 1.0,
      ma50: 0.821,
      ma100: 0.792,
      ma150: 0.792,
      ma200: 0.835,
      maw30: 0.793,
      rs: 86.0,
      rsd1d: 31.0,
      rsd5d: 52.0,
      rsd10d: 39.0,
      price_5d_h: 1.01,
      price_5d_L: 0.73,
      price_1m_h: 1.01,
      price_1m_l: 0.65,
      price_3m_h: 1.01,
      price_3m_l: 0.65,
      price_6m_h: 1.01,
      price_6m_l: 0.65,
      price_52w_h: 1.12,
      price_52w_l: 0.65,
      price_pc_1d: 25.0,
      price_pc_1w: 38.889,
      price_pc_1m: 17.647,
      price_pc_6m: 23.457,
      price_pc_1y: -8.257,
      price_pc_ytd: -8.257,
    },
    "0126": {
      id: "0126",
      c: 0.5,
      ma50: 0.571,
      ma100: 0.605,
      ma150: 0.65,
      ma200: 0.67,
      maw30: 0.652,
      rs: 35.0,
      rsd1d: -3.0,
      rsd5d: 1.0,
      rsd10d: -8.0,
      price_5d_h: 0.54,
      price_5d_L: 0.495,
      price_1m_h: 0.58,
      price_1m_l: 0.445,
      price_3m_h: 0.7,
      price_3m_l: 0.445,
      price_6m_h: 0.78,
      price_6m_l: 0.445,
      price_52w_h: 0.93,
      price_52w_l: 0.445,
      price_pc_1d: -3.846,
      price_pc_1w: -3.846,
      price_pc_1m: -13.793,
      price_pc_6m: -27.536,
      price_pc_1y: -1.961,
      price_pc_ytd: -1.961,
    },
    "0127": {
      id: "0127",
      c: 2.25,
      ma50: 2.524,
      ma100: 2.969,
      ma150: 3.018,
      ma200: 3.101,
      maw30: 3.041,
      rs: 22.0,
      rsd1d: 3.0,
      rsd5d: -7.0,
      rsd10d: -3.0,
      price_5d_h: 2.31,
      price_5d_L: 2.21,
      price_1m_h: 2.6,
      price_1m_l: 2.19,
      price_3m_h: 2.93,
      price_3m_l: 2.19,
      price_6m_h: 3.9,
      price_6m_l: 2.19,
      price_52w_h: 4.1,
      price_52w_l: 1.98,
      price_pc_1d: 0.446,
      price_pc_1w: -2.174,
      price_pc_1m: -13.793,
      price_pc_6m: -22.414,
      price_pc_1y: -43.467,
      price_pc_ytd: -43.467,
    },
    "0128": {
      id: "0128",
      c: 0.59,
      ma50: 0.64,
      ma100: 0.605,
      ma150: 0.606,
      ma200: 0.614,
      maw30: 0.606,
      rs: 76.0,
      rsd1d: -2.0,
      rsd5d: -5.0,
      rsd10d: -2.0,
      price_5d_h: 0.61,
      price_5d_L: 0.58,
      price_1m_h: 0.67,
      price_1m_l: 0.52,
      price_3m_h: 0.86,
      price_3m_l: 0.52,
      price_6m_h: 0.86,
      price_6m_l: 0.52,
      price_52w_h: 0.86,
      price_52w_l: 0.52,
      price_pc_1d: 0.0,
      price_pc_1w: -1.667,
      price_pc_1m: -11.94,
      price_pc_6m: -1.667,
      price_pc_1y: 0.0,
      price_pc_ytd: 0.0,
    },
    "0129": {
      id: "0129",
      c: 0.72,
      ma50: 0.809,
      ma100: 0.848,
      ma150: 0.87,
      ma200: 0.943,
      maw30: 0.873,
      rs: 28.0,
      rsd1d: 5.0,
      rsd5d: 0.0,
      rsd10d: -3.0,
      price_5d_h: 0.74,
      price_5d_L: 0.7,
      price_1m_h: 0.86,
      price_1m_l: 0.61,
      price_3m_h: 0.92,
      price_3m_l: 0.61,
      price_6m_h: 0.95,
      price_6m_l: 0.61,
      price_52w_h: 1.34,
      price_52w_l: 0.61,
      price_pc_1d: 1.408,
      price_pc_1w: -2.703,
      price_pc_1m: -11.111,
      price_pc_6m: -15.294,
      price_pc_1y: -33.945,
      price_pc_ytd: -33.945,
    },
    "0130": {
      id: "0130",
      c: 0.255,
      ma50: 0.279,
      ma100: 0.284,
      ma150: 0.299,
      ma200: 0.312,
      maw30: 0.297,
      rs: 42.0,
      rsd1d: -16.0,
      rsd5d: -17.0,
      rsd10d: -26.0,
      price_5d_h: 0.285,
      price_5d_L: 0.255,
      price_1m_h: 0.295,
      price_1m_l: 0.234,
      price_3m_h: 0.295,
      price_3m_l: 0.234,
      price_6m_h: 0.33,
      price_6m_l: 0.234,
      price_52w_h: 0.395,
      price_52w_l: 0.234,
      price_pc_1d: -10.526,
      price_pc_1w: -10.526,
      price_pc_1m: -12.069,
      price_pc_6m: -20.312,
      price_pc_1y: -8.929,
      price_pc_ytd: -8.929,
    },
    "0132": {
      id: "0132",
      c: 0.39,
      ma50: 0.394,
      ma100: 0.416,
      ma150: 0.422,
      ma200: 0.445,
      maw30: 0.425,
      rs: 47.0,
      rsd1d: 15.0,
      rsd5d: -2.0,
      rsd10d: 9.0,
      price_5d_h: 0.42,
      price_5d_L: 0.36,
      price_1m_h: 0.455,
      price_1m_l: 0.36,
      price_3m_h: 0.46,
      price_3m_l: 0.33,
      price_6m_h: 0.55,
      price_6m_l: 0.33,
      price_52w_h: 0.56,
      price_52w_l: 0.33,
      price_pc_1d: 8.333,
      price_pc_1w: -2.5,
      price_pc_1m: -9.302,
      price_pc_6m: -11.364,
      price_pc_1y: -27.778,
      price_pc_ytd: -27.778,
    },
    "0133": {
      id: "0133",
      c: 9.93,
      ma50: 9.584,
      ma100: 9.465,
      ma150: 9.468,
      ma200: 9.494,
      maw30: 9.48,
      rs: 78.0,
      rsd1d: 0.0,
      rsd5d: 8.0,
      rsd10d: 12.0,
      price_5d_h: 9.96,
      price_5d_L: 9.42,
      price_1m_h: 9.96,
      price_1m_l: 8.5,
      price_3m_h: 10.02,
      price_3m_l: 8.5,
      price_6m_h: 10.02,
      price_6m_l: 8.5,
      price_52w_h: 11.28,
      price_52w_l: 8.5,
      price_pc_1d: 0.404,
      price_pc_1w: 4.526,
      price_pc_1m: 3.438,
      price_pc_6m: 7.584,
      price_pc_1y: -8.056,
      price_pc_ytd: -8.056,
    },
    "0135": {
      id: "0135",
      c: 6.83,
      ma50: 7.459,
      ma100: 7.379,
      ma150: 7.484,
      ma200: 7.362,
      maw30: 7.516,
      rs: 56.0,
      rsd1d: 3.0,
      rsd5d: 1.0,
      rsd10d: 13.0,
      price_5d_h: 7.22,
      price_5d_L: 6.56,
      price_1m_h: 7.88,
      price_1m_l: 5.17,
      price_3m_h: 8.6,
      price_3m_l: 5.17,
      price_6m_h: 8.6,
      price_6m_l: 5.17,
      price_52w_h: 9.3,
      price_52w_l: 5.17,
      price_pc_1d: -0.292,
      price_pc_1w: -2.009,
      price_pc_1m: -10.602,
      price_pc_6m: -15.887,
      price_pc_1y: -16.809,
      price_pc_ytd: -16.809,
    },
    "0136": {
      id: "0136",
      c: 2.12,
      ma50: 2.213,
      ma100: 2.432,
      ma150: 2.557,
      ma200: 3.144,
      maw30: 2.508,
      rs: 12.0,
      rsd1d: 0.0,
      rsd5d: -10.0,
      rsd10d: -3.0,
      price_5d_h: 2.28,
      price_5d_L: 2.0,
      price_1m_h: 2.38,
      price_1m_l: 1.86,
      price_3m_h: 3.35,
      price_3m_l: 1.85,
      price_6m_h: 4.19,
      price_6m_l: 1.47,
      price_52w_h: 11.08,
      price_52w_l: 1.47,
      price_pc_1d: -4.933,
      price_pc_1w: 0.474,
      price_pc_1m: -8.225,
      price_pc_6m: -8.225,
      price_pc_1y: -79.01,
      price_pc_ytd: -79.01,
    },
    "0137": {
      id: "0137",
      c: 1.22,
      ma50: 1.247,
      ma100: 1.268,
      ma150: 1.333,
      ma200: 1.328,
      maw30: 1.331,
      rs: 81.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: 8.0,
      price_5d_h: 1.26,
      price_5d_L: 1.15,
      price_1m_h: 1.4,
      price_1m_l: 0.99,
      price_3m_h: 1.48,
      price_3m_l: 0.99,
      price_6m_h: 1.53,
      price_6m_l: 0.99,
      price_52w_h: 1.55,
      price_52w_l: 0.75,
      price_pc_1d: 0.0,
      price_pc_1w: -5.426,
      price_pc_1m: -12.857,
      price_pc_6m: -18.667,
      price_pc_1y: 38.636,
      price_pc_ytd: 38.636,
    },
    "0138": {
      id: "0138",
      c: 0.175,
      ma50: 0.181,
      ma100: 0.182,
      ma150: 0.184,
      ma200: 0.185,
      maw30: 0.185,
      rs: 65.0,
      rsd1d: 2.0,
      rsd5d: 4.0,
      rsd10d: -7.0,
      price_5d_h: 0.185,
      price_5d_L: 0.175,
      price_1m_h: 0.199,
      price_1m_l: 0.16,
      price_3m_h: 0.199,
      price_3m_l: 0.16,
      price_6m_h: 0.199,
      price_6m_l: 0.16,
      price_52w_h: 0.205,
      price_52w_l: 0.16,
      price_pc_1d: -1.685,
      price_pc_1w: -3.315,
      price_pc_1m: 0.575,
      price_pc_6m: -3.315,
      price_pc_1y: -11.168,
      price_pc_ytd: -11.168,
    },
    "0139": {
      id: "0139",
      c: 0.015,
      ma50: 0.016,
      ma100: 0.013,
      ma150: 0.013,
      ma200: 0.013,
      maw30: 0.013,
      rs: 89.0,
      rsd1d: -2.0,
      rsd5d: -1.0,
      rsd10d: -5.0,
      price_5d_h: 0.018,
      price_5d_L: 0.014,
      price_1m_h: 0.025,
      price_1m_l: 0.014,
      price_3m_h: 0.027,
      price_3m_l: 0.01,
      price_6m_h: 0.027,
      price_6m_l: 0.01,
      price_52w_h: 0.027,
      price_52w_l: 0.01,
      price_pc_1d: -6.25,
      price_pc_1w: -6.25,
      price_pc_1m: -40.0,
      price_pc_6m: 25.0,
      price_pc_1y: -28.571,
      price_pc_ytd: -28.571,
    },
    "0142": {
      id: "0142",
      c: 3.17,
      ma50: 3.099,
      ma100: 3.0,
      ma150: 2.978,
      ma200: 2.897,
      maw30: 2.983,
      rs: 88.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: 0.0,
      price_5d_h: 3.22,
      price_5d_L: 3.13,
      price_1m_h: 3.22,
      price_1m_l: 2.65,
      price_3m_h: 3.47,
      price_3m_l: 2.65,
      price_6m_h: 3.47,
      price_6m_l: 2.6,
      price_52w_h: 3.47,
      price_52w_l: 2.41,
      price_pc_1d: -0.314,
      price_pc_1w: 0.316,
      price_pc_1m: 3.595,
      price_pc_6m: 13.214,
      price_pc_1y: 17.407,
      price_pc_ytd: 17.407,
    },
    "0144": {
      id: "0144",
      c: 14.2,
      ma50: 14.45,
      ma100: 13.971,
      ma150: 13.654,
      ma200: 13.069,
      maw30: 13.798,
      rs: 85.0,
      rsd1d: 2.0,
      rsd5d: 6.0,
      rsd10d: 3.0,
      price_5d_h: 14.3,
      price_5d_L: 13.0,
      price_1m_h: 14.94,
      price_1m_l: 12.12,
      price_3m_h: 16.12,
      price_3m_l: 12.12,
      price_6m_h: 16.12,
      price_6m_l: 11.52,
      price_52w_h: 16.12,
      price_52w_l: 10.38,
      price_pc_1d: 4.566,
      price_pc_1w: 8.232,
      price_pc_1m: -3.401,
      price_pc_6m: 6.287,
      price_pc_1y: 16.585,
      price_pc_ytd: 16.585,
    },
    "0145": {
      id: "0145",
      c: 0.315,
      ma50: 0.21,
      ma100: 0.175,
      ma150: 0.165,
      ma200: 0.16,
      maw30: 0.164,
      rs: 97.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.35,
      price_5d_L: 0.29,
      price_1m_h: 0.35,
      price_1m_l: 0.175,
      price_3m_h: 0.35,
      price_3m_l: 0.11,
      price_6m_h: 0.35,
      price_6m_l: 0.11,
      price_52w_h: 0.35,
      price_52w_l: 0.11,
      price_pc_1d: -4.545,
      price_pc_1w: -4.545,
      price_pc_1m: 75.0,
      price_pc_6m: 103.226,
      price_pc_1y: 69.355,
      price_pc_ytd: 69.355,
    },
    "0146": {
      id: "0146",
      c: 0.81,
      ma50: 0.962,
      ma100: 1.008,
      ma150: 1.033,
      ma200: 1.059,
      maw30: 1.029,
      rs: 31.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -3.0,
      price_5d_h: 0.84,
      price_5d_L: 0.76,
      price_1m_h: 1.0,
      price_1m_l: 0.7,
      price_3m_h: 1.23,
      price_3m_l: 0.7,
      price_6m_h: 1.23,
      price_6m_l: 0.7,
      price_52w_h: 1.23,
      price_52w_l: 0.7,
      price_pc_1d: -2.41,
      price_pc_1w: -3.571,
      price_pc_1m: -19.0,
      price_pc_6m: -22.857,
      price_pc_1y: -32.5,
      price_pc_ytd: -32.5,
    },
    "0147": {
      id: "0147",
      c: 0.143,
      ma50: 0.218,
      ma100: 0.162,
      ma150: 0.133,
      ma200: 0.119,
      maw30: 0.134,
      rs: 95.0,
      rsd1d: 3.0,
      rsd5d: 2.0,
      rsd10d: -3.0,
      price_5d_h: 0.145,
      price_5d_L: 0.118,
      price_1m_h: 0.248,
      price_1m_l: 0.112,
      price_3m_h: 0.4,
      price_3m_l: 0.112,
      price_6m_h: 0.4,
      price_6m_l: 0.053,
      price_52w_h: 0.4,
      price_52w_l: 0.053,
      price_pc_1d: 15.323,
      price_pc_1w: 20.168,
      price_pc_1m: -40.417,
      price_pc_6m: 90.667,
      price_pc_1y: 43.0,
      price_pc_ytd: 43.0,
    },
    "0148": {
      id: "0148",
      c: 38.1,
      ma50: 37.609,
      ma100: 37.558,
      ma150: 36.995,
      ma200: 37.97,
      maw30: 36.995,
      rs: 70.0,
      rsd1d: -2.0,
      rsd5d: -11.0,
      rsd10d: -6.0,
      price_5d_h: 38.7,
      price_5d_L: 37.05,
      price_1m_h: 39.3,
      price_1m_l: 32.4,
      price_3m_h: 40.65,
      price_3m_l: 32.4,
      price_6m_h: 41.8,
      price_6m_l: 32.2,
      price_52w_h: 48.0,
      price_52w_l: 32.2,
      price_pc_1d: -0.131,
      price_pc_1w: -1.931,
      price_pc_1m: 4.527,
      price_pc_6m: 8.085,
      price_pc_1y: -12.615,
      price_pc_ytd: -12.615,
    },
    "0149": {
      id: "0149",
      c: 0.104,
      ma50: 0.107,
      ma100: 0.112,
      ma150: 0.111,
      ma200: 0.115,
      maw30: 0.112,
      rs: 42.0,
      rsd1d: -5.0,
      rsd5d: -10.0,
      rsd10d: -12.0,
      price_5d_h: 0.115,
      price_5d_L: 0.103,
      price_1m_h: 0.117,
      price_1m_l: 0.097,
      price_3m_h: 0.128,
      price_3m_l: 0.097,
      price_6m_h: 0.128,
      price_6m_l: 0.095,
      price_52w_h: 0.145,
      price_52w_l: 0.095,
      price_pc_1d: -3.704,
      price_pc_1w: -3.704,
      price_pc_1m: -1.887,
      price_pc_6m: -13.333,
      price_pc_1y: -24.088,
      price_pc_ytd: -24.088,
    },
    "0150": {
      id: "0150",
      c: 0.79,
      ma50: 0.931,
      ma100: 1.032,
      ma150: 1.04,
      ma200: 1.055,
      maw30: 1.044,
      rs: 30.0,
      rsd1d: 2.0,
      rsd5d: 0.0,
      rsd10d: -4.0,
      price_5d_h: 0.82,
      price_5d_L: 0.78,
      price_1m_h: 0.97,
      price_1m_l: 0.75,
      price_3m_h: 1.13,
      price_3m_l: 0.75,
      price_6m_h: 1.28,
      price_6m_l: 0.75,
      price_52w_h: 1.44,
      price_52w_l: 0.75,
      price_pc_1d: -1.25,
      price_pc_1w: -3.659,
      price_pc_1m: -18.557,
      price_pc_6m: -22.549,
      price_pc_1y: -13.187,
      price_pc_ytd: -13.187,
    },
    "0151": {
      id: "0151",
      c: 7.24,
      ma50: 7.736,
      ma100: 7.307,
      ma150: 6.759,
      ma200: 6.427,
      maw30: 6.8,
      rs: 89.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -3.0,
      price_5d_h: 7.43,
      price_5d_L: 7.08,
      price_1m_h: 8.4,
      price_1m_l: 6.96,
      price_3m_h: 8.44,
      price_3m_l: 6.8,
      price_6m_h: 8.44,
      price_6m_l: 5.44,
      price_52w_h: 8.44,
      price_52w_l: 5.06,
      price_pc_1d: -0.822,
      price_pc_1w: 0.138,
      price_pc_1m: -12.876,
      price_pc_6m: 22.92,
      price_pc_1y: 23.761,
      price_pc_ytd: 23.761,
    },
    "0152": {
      id: "0152",
      c: 8.28,
      ma50: 8.029,
      ma100: 8.226,
      ma150: 8.836,
      ma200: 9.262,
      maw30: 8.793,
      rs: 45.0,
      rsd1d: 7.0,
      rsd5d: 10.0,
      rsd10d: 9.0,
      price_5d_h: 8.35,
      price_5d_L: 7.46,
      price_1m_h: 8.35,
      price_1m_l: 6.8,
      price_3m_h: 8.77,
      price_3m_l: 6.8,
      price_6m_h: 10.26,
      price_6m_l: 6.8,
      price_52w_h: 13.68,
      price_52w_l: 6.8,
      price_pc_1d: 3.63,
      price_pc_1w: 7.254,
      price_pc_1m: 2.985,
      price_pc_6m: -17.365,
      price_pc_1y: -36.308,
      price_pc_ytd: -36.308,
    },
    "0154": {
      id: "0154",
      c: 0.51,
      ma50: 0.519,
      ma100: 0.554,
      ma150: 0.585,
      ma200: 0.572,
      maw30: 0.59,
      rs: 48.0,
      rsd1d: 5.0,
      rsd5d: 9.0,
      rsd10d: 4.0,
      price_5d_h: 0.51,
      price_5d_L: 0.475,
      price_1m_h: 0.58,
      price_1m_l: 0.415,
      price_3m_h: 0.59,
      price_3m_l: 0.415,
      price_6m_h: 0.79,
      price_6m_l: 0.415,
      price_52w_h: 0.79,
      price_52w_l: 0.415,
      price_pc_1d: 2.0,
      price_pc_1w: 4.082,
      price_pc_1m: -1.923,
      price_pc_6m: -23.881,
      price_pc_1y: -7.273,
      price_pc_ytd: -7.273,
    },
    "0156": {
      id: "0156",
      c: 0.143,
      ma50: 0.12,
      ma100: 0.121,
      ma150: 0.123,
      ma200: 0.122,
      maw30: 0.124,
      rs: 85.0,
      rsd1d: 4.0,
      rsd5d: 9.0,
      rsd10d: 28.0,
      price_5d_h: 0.143,
      price_5d_L: 0.12,
      price_1m_h: 0.143,
      price_1m_l: 0.1,
      price_3m_h: 0.143,
      price_3m_l: 0.1,
      price_6m_h: 0.143,
      price_6m_l: 0.1,
      price_52w_h: 0.145,
      price_52w_l: 0.1,
      price_pc_1d: 7.519,
      price_pc_1w: 15.323,
      price_pc_1m: 23.276,
      price_pc_6m: 2.878,
      price_pc_1y: 2.143,
      price_pc_ytd: 2.143,
    },
    "0163": {
      id: "0163",
      c: 0.82,
      ma50: 0.879,
      ma100: 0.925,
      ma150: 0.968,
      ma200: 1.009,
      maw30: 0.969,
      rs: 37.0,
      rsd1d: -1.0,
      rsd5d: 4.0,
      rsd10d: -5.0,
      price_5d_h: 0.85,
      price_5d_L: 0.81,
      price_1m_h: 0.88,
      price_1m_l: 0.76,
      price_3m_h: 0.96,
      price_3m_l: 0.76,
      price_6m_h: 1.1,
      price_6m_l: 0.76,
      price_52w_h: 1.22,
      price_52w_l: 0.76,
      price_pc_1d: -1.205,
      price_pc_1w: 0.0,
      price_pc_1m: -6.818,
      price_pc_6m: -18.812,
      price_pc_1y: -25.455,
      price_pc_ytd: -25.455,
    },
    "0164": {
      id: "0164",
      c: 0.33,
      ma50: 0.327,
      ma100: 0.324,
      ma150: 0.347,
      ma200: 0.376,
      maw30: 0.354,
      rs: 38.0,
      rsd1d: 0.0,
      rsd5d: 22.0,
      rsd10d: 9.0,
      price_5d_h: 0.375,
      price_5d_L: 0.28,
      price_1m_h: 0.375,
      price_1m_l: 0.28,
      price_3m_h: 0.38,
      price_3m_l: 0.255,
      price_6m_h: 0.53,
      price_6m_l: 0.255,
      price_52w_h: 1.9,
      price_52w_l: 0.18,
      price_pc_1d: -2.941,
      price_pc_1w: 8.197,
      price_pc_1m: 0.0,
      price_pc_6m: -23.256,
      price_pc_1y: -66.667,
      price_pc_ytd: -66.667,
    },
    "0165": {
      id: "0165",
      c: 7.76,
      ma50: 8.521,
      ma100: 8.712,
      ma150: 8.924,
      ma200: 8.942,
      maw30: 8.957,
      rs: 40.0,
      rsd1d: 0.0,
      rsd5d: -2.0,
      rsd10d: -11.0,
      price_5d_h: 7.95,
      price_5d_L: 7.65,
      price_1m_h: 8.63,
      price_1m_l: 7.0,
      price_3m_h: 9.45,
      price_3m_l: 7.0,
      price_6m_h: 9.74,
      price_6m_l: 7.0,
      price_52w_h: 10.62,
      price_52w_l: 7.0,
      price_pc_1d: -1.523,
      price_pc_1w: -1.02,
      price_pc_1m: -9.662,
      price_pc_6m: -15.652,
      price_pc_1y: -23.168,
      price_pc_ytd: -23.168,
    },
    "0166": {
      id: "0166",
      c: 0.078,
      ma50: 0.07,
      ma100: 0.068,
      ma150: 0.071,
      ma200: 0.069,
      maw30: 0.071,
      rs: 88.0,
      rsd1d: -3.0,
      rsd5d: -4.0,
      rsd10d: 7.0,
      price_5d_h: 0.091,
      price_5d_L: 0.078,
      price_1m_h: 0.091,
      price_1m_l: 0.063,
      price_3m_h: 0.1,
      price_3m_l: 0.053,
      price_6m_h: 0.106,
      price_6m_l: 0.053,
      price_52w_h: 0.106,
      price_52w_l: 0.05,
      price_pc_1d: -6.024,
      price_pc_1w: -11.364,
      price_pc_1m: 6.849,
      price_pc_6m: -1.266,
      price_pc_1y: 16.418,
      price_pc_ytd: 16.418,
    },
    "0167": {
      id: "0167",
      c: 0.024,
      ma50: 0.025,
      ma100: 0.028,
      ma150: 0.032,
      ma200: 0.036,
      maw30: 0.032,
      rs: 18.0,
      rsd1d: 6.0,
      rsd5d: 2.0,
      rsd10d: 5.0,
      price_5d_h: 0.024,
      price_5d_L: 0.022,
      price_1m_h: 0.026,
      price_1m_l: 0.021,
      price_3m_h: 0.035,
      price_3m_l: 0.021,
      price_6m_h: 0.042,
      price_6m_l: 0.021,
      price_52w_h: 0.078,
      price_52w_l: 0.021,
      price_pc_1d: 9.091,
      price_pc_1w: 0.0,
      price_pc_1m: -4.0,
      price_pc_6m: -38.462,
      price_pc_1y: -51.02,
      price_pc_ytd: -51.02,
    },
    "0168": {
      id: "0168",
      c: 62.3,
      ma50: 69.791,
      ma100: 68.964,
      ma150: 66.913,
      ma200: 68.53,
      maw30: 67.39,
      rs: 51.0,
      rsd1d: 4.0,
      rsd5d: 3.0,
      rsd10d: -13.0,
      price_5d_h: 63.35,
      price_5d_L: 57.1,
      price_1m_h: 82.0,
      price_1m_l: 54.75,
      price_3m_h: 82.0,
      price_3m_l: 54.75,
      price_6m_h: 82.0,
      price_6m_l: 54.75,
      price_52w_h: 93.55,
      price_52w_l: 54.75,
      price_pc_1d: -0.32,
      price_pc_1w: 1.054,
      price_pc_1m: -16.878,
      price_pc_6m: 2.131,
      price_pc_1y: -11.694,
      price_pc_ytd: -11.694,
    },
    "0169": {
      id: "0169",
      c: 0.325,
      ma50: 0.285,
      ma100: 0.299,
      ma150: 0.313,
      ma200: 0.352,
      maw30: 0.31,
      rs: 58.0,
      rsd1d: 19.0,
      rsd5d: 17.0,
      rsd10d: 17.0,
      price_5d_h: 0.325,
      price_5d_L: 0.265,
      price_1m_h: 0.325,
      price_1m_l: 0.25,
      price_3m_h: 0.325,
      price_3m_l: 0.25,
      price_6m_h: 0.37,
      price_6m_l: 0.25,
      price_52w_h: 0.53,
      price_52w_l: 0.25,
      price_pc_1d: 8.333,
      price_pc_1w: 10.169,
      price_pc_1m: 14.035,
      price_pc_6m: 8.333,
      price_pc_1y: -25.287,
      price_pc_ytd: -25.287,
    },
    "0171": {
      id: "0171",
      c: 0.43,
      ma50: 0.474,
      ma100: 0.484,
      ma150: 0.506,
      ma200: 0.533,
      maw30: 0.504,
      rs: 30.0,
      rsd1d: 0.0,
      rsd5d: -5.0,
      rsd10d: -21.0,
      price_5d_h: 0.48,
      price_5d_L: 0.41,
      price_1m_h: 0.495,
      price_1m_l: 0.355,
      price_3m_h: 0.51,
      price_3m_l: 0.355,
      price_6m_h: 0.63,
      price_6m_l: 0.355,
      price_52w_h: 0.7,
      price_52w_l: 0.355,
      price_pc_1d: -1.149,
      price_pc_1w: -4.444,
      price_pc_1m: -5.495,
      price_pc_6m: -24.561,
      price_pc_1y: -38.571,
      price_pc_ytd: -38.571,
    },
    "0173": {
      id: "0173",
      c: 2.96,
      ma50: 2.994,
      ma100: 3.025,
      ma150: 3.101,
      ma200: 3.235,
      maw30: 3.097,
      rs: 53.0,
      rsd1d: 1.0,
      rsd5d: 4.0,
      rsd10d: 4.0,
      price_5d_h: 3.02,
      price_5d_L: 2.93,
      price_1m_h: 3.02,
      price_1m_l: 2.56,
      price_3m_h: 3.2,
      price_3m_l: 2.56,
      price_6m_h: 3.29,
      price_6m_l: 2.56,
      price_52w_h: 4.06,
      price_52w_l: 2.56,
      price_pc_1d: -1.333,
      price_pc_1w: 1.024,
      price_pc_1m: 0.0,
      price_pc_6m: -6.329,
      price_pc_1y: -25.441,
      price_pc_ytd: -25.441,
    },
    "0175": {
      id: "0175",
      c: 12.36,
      ma50: 14.777,
      ma100: 18.899,
      ma150: 21.026,
      ma200: 22.075,
      maw30: 21.035,
      rs: 11.0,
      rsd1d: 0.0,
      rsd5d: 3.0,
      rsd10d: 3.0,
      price_5d_h: 12.96,
      price_5d_L: 11.2,
      price_1m_h: 14.94,
      price_1m_l: 10.0,
      price_3m_h: 22.05,
      price_3m_l: 10.0,
      price_6m_h: 28.0,
      price_6m_l: 10.0,
      price_52w_h: 29.8,
      price_52w_l: 10.0,
      price_pc_1d: -2.37,
      price_pc_1w: 2.318,
      price_pc_1m: -14.286,
      price_pc_6m: -44.698,
      price_pc_1y: -39.854,
      price_pc_ytd: -39.854,
    },
    "0176": {
      id: "0176",
      c: 0.062,
      ma50: 0.045,
      ma100: 0.1,
      ma150: 0.129,
      ma200: 0.134,
      maw30: 0.129,
      rs: 2.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.072,
      price_5d_L: 0.051,
      price_1m_h: 0.074,
      price_1m_l: 0.031,
      price_3m_h: 0.209,
      price_3m_l: 0.03,
      price_6m_h: 0.248,
      price_6m_l: 0.03,
      price_52w_h: 0.248,
      price_52w_l: 0.03,
      price_pc_1d: 0.0,
      price_pc_1w: 5.085,
      price_pc_1m: 63.158,
      price_pc_6m: -70.755,
      price_pc_1y: -72.072,
      price_pc_ytd: -72.072,
    },
    "0177": {
      id: "0177",
      c: 8.2,
      ma50: 8.3,
      ma100: 8.077,
      ma150: 7.991,
      ma200: 8.134,
      maw30: 7.986,
      rs: 70.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: 1.0,
      price_5d_h: 8.44,
      price_5d_L: 7.96,
      price_1m_h: 8.57,
      price_1m_l: 7.5,
      price_3m_h: 8.94,
      price_3m_l: 7.5,
      price_6m_h: 8.94,
      price_6m_l: 7.14,
      price_52w_h: 9.94,
      price_52w_l: 7.14,
      price_pc_1d: 0.0,
      price_pc_1w: 0.985,
      price_pc_1m: -1.914,
      price_pc_6m: 3.929,
      price_pc_1y: -13.319,
      price_pc_ytd: -13.319,
    },
    "0178": {
      id: "0178",
      c: 1.39,
      ma50: 1.435,
      ma100: 1.572,
      ma150: 1.623,
      ma200: 1.703,
      maw30: 1.631,
      rs: 32.0,
      rsd1d: 2.0,
      rsd5d: -5.0,
      rsd10d: -6.0,
      price_5d_h: 1.44,
      price_5d_L: 1.35,
      price_1m_h: 1.52,
      price_1m_l: 1.2,
      price_3m_h: 1.67,
      price_3m_l: 1.2,
      price_6m_h: 1.95,
      price_6m_l: 1.2,
      price_52w_h: 2.29,
      price_52w_l: 1.2,
      price_pc_1d: -1.418,
      price_pc_1w: -3.472,
      price_pc_1m: 0.0,
      price_pc_6m: -20.115,
      price_pc_1y: -23.626,
      price_pc_ytd: -23.626,
    },
    "0179": {
      id: "0179",
      c: 10.84,
      ma50: 12.741,
      ma100: 14.922,
      ma150: 15.732,
      ma200: 16.593,
      maw30: 15.717,
      rs: 14.0,
      rsd1d: 2.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 11.44,
      price_5d_L: 10.6,
      price_1m_h: 13.28,
      price_1m_l: 10.18,
      price_3m_h: 16.8,
      price_3m_l: 10.18,
      price_6m_h: 19.5,
      price_6m_l: 10.18,
      price_52w_h: 22.75,
      price_52w_l: 10.18,
      price_pc_1d: -1.095,
      price_pc_1w: -4.409,
      price_pc_1m: -16.487,
      price_pc_6m: -34.699,
      price_pc_1y: -49.346,
      price_pc_ytd: -49.346,
    },
    "0180": {
      id: "0180",
      c: 0.45,
      ma50: 0.439,
      ma100: 0.436,
      ma150: 0.438,
      ma200: 0.435,
      maw30: 0.438,
      rs: 79.0,
      rsd1d: 15.0,
      rsd5d: -2.0,
      rsd10d: -4.0,
      price_5d_h: 0.45,
      price_5d_L: 0.41,
      price_1m_h: 0.46,
      price_1m_l: 0.41,
      price_3m_h: 0.46,
      price_3m_l: 0.405,
      price_6m_h: 0.46,
      price_6m_l: 0.39,
      price_52w_h: 0.46,
      price_52w_l: 0.39,
      price_pc_1d: 9.756,
      price_pc_1w: 1.124,
      price_pc_1m: 1.124,
      price_pc_6m: 0.0,
      price_pc_1y: 3.448,
      price_pc_ytd: 3.448,
    },
    "0182": {
      id: "0182",
      c: 0.73,
      ma50: 0.729,
      ma100: 0.758,
      ma150: 0.778,
      ma200: 0.74,
      maw30: 0.782,
      rs: 76.0,
      rsd1d: -3.0,
      rsd5d: -5.0,
      rsd10d: 1.0,
      price_5d_h: 0.76,
      price_5d_L: 0.71,
      price_1m_h: 0.78,
      price_1m_l: 0.62,
      price_3m_h: 0.86,
      price_3m_l: 0.62,
      price_6m_h: 0.99,
      price_6m_l: 0.62,
      price_52w_h: 1.01,
      price_52w_l: 0.5,
      price_pc_1d: -2.667,
      price_pc_1w: 0.0,
      price_pc_1m: 0.0,
      price_pc_6m: -25.51,
      price_pc_1y: 30.357,
      price_pc_ytd: 30.357,
    },
    "0183": {
      id: "0183",
      c: 0.3,
      ma50: 0.312,
      ma100: 0.307,
      ma150: 0.304,
      ma200: 0.302,
      maw30: 0.304,
      rs: 69.0,
      rsd1d: 8.0,
      rsd5d: 3.0,
      rsd10d: 1.0,
      price_5d_h: 0.32,
      price_5d_L: 0.285,
      price_1m_h: 0.33,
      price_1m_l: 0.285,
      price_3m_h: 0.33,
      price_3m_l: 0.285,
      price_6m_h: 0.375,
      price_6m_l: 0.28,
      price_52w_h: 0.46,
      price_52w_l: 0.255,
      price_pc_1d: 0.0,
      price_pc_1w: -1.639,
      price_pc_1m: -9.091,
      price_pc_6m: -1.639,
      price_pc_1y: -28.571,
      price_pc_ytd: -28.571,
    },
    "0184": {
      id: "0184",
      c: 2.83,
      ma50: 2.969,
      ma100: 3.025,
      ma150: 3.301,
      ma200: 3.555,
      maw30: 3.293,
      rs: 49.0,
      rsd1d: 5.0,
      rsd5d: 6.0,
      rsd10d: 16.0,
      price_5d_h: 2.86,
      price_5d_L: 2.83,
      price_1m_h: 2.91,
      price_1m_l: 2.7,
      price_3m_h: 3.45,
      price_3m_l: 2.7,
      price_6m_h: 3.8,
      price_6m_l: 2.7,
      price_52w_h: 6.1,
      price_52w_l: 2.7,
      price_pc_1d: 0.0,
      price_pc_1w: -2.749,
      price_pc_1m: -2.414,
      price_pc_6m: -26.873,
      price_pc_1y: -7.213,
      price_pc_ytd: -7.213,
    },
    "0185": {
      id: "0185",
      c: 3.7,
      ma50: 4.052,
      ma100: 4.041,
      ma150: 4.34,
      ma200: 4.743,
      maw30: 4.308,
      rs: 32.0,
      rsd1d: 6.0,
      rsd5d: -18.0,
      rsd10d: -20.0,
      price_5d_h: 3.95,
      price_5d_L: 3.42,
      price_1m_h: 4.53,
      price_1m_l: 3.2,
      price_3m_h: 5.35,
      price_3m_l: 3.2,
      price_6m_h: 5.95,
      price_6m_l: 3.2,
      price_52w_h: 6.9,
      price_52w_l: 3.2,
      price_pc_1d: 4.225,
      price_pc_1w: -2.116,
      price_pc_1m: -15.525,
      price_pc_6m: -36.968,
      price_pc_1y: -15.909,
      price_pc_ytd: -15.909,
    },
    "0187": {
      id: "0187",
      c: 3.99,
      ma50: 3.795,
      ma100: 3.718,
      ma150: 3.27,
      ma200: 3.012,
      maw30: 3.307,
      rs: 95.0,
      rsd1d: 0.0,
      rsd5d: -2.0,
      rsd10d: 4.0,
      price_5d_h: 5.22,
      price_5d_L: 3.75,
      price_1m_h: 5.55,
      price_1m_l: 2.84,
      price_3m_h: 5.55,
      price_3m_l: 2.84,
      price_6m_h: 6.78,
      price_6m_l: 2.01,
      price_52w_h: 6.78,
      price_52w_l: 1.7,
      price_pc_1d: -2.683,
      price_pc_1w: -18.238,
      price_pc_1m: 7.547,
      price_pc_6m: 60.241,
      price_pc_1y: 103.571,
      price_pc_ytd: 103.571,
    },
    "0188": {
      id: "0188",
      c: 0.35,
      ma50: 0.372,
      ma100: 0.379,
      ma150: 0.375,
      ma200: 0.375,
      maw30: 0.377,
      rs: 61.0,
      rsd1d: -7.0,
      rsd5d: -2.0,
      rsd10d: -7.0,
      price_5d_h: 0.365,
      price_5d_L: 0.35,
      price_1m_h: 0.375,
      price_1m_l: 0.35,
      price_3m_h: 0.415,
      price_3m_l: 0.35,
      price_6m_h: 0.465,
      price_6m_l: 0.35,
      price_52w_h: 0.465,
      price_52w_l: 0.345,
      price_pc_1d: -4.11,
      price_pc_1w: -1.408,
      price_pc_1m: -5.405,
      price_pc_6m: 0.0,
      price_pc_1y: -2.778,
      price_pc_ytd: -2.778,
    },
    "0189": {
      id: "0189",
      c: 10.78,
      ma50: 10.005,
      ma100: 11.634,
      ma150: 14.558,
      ma200: 13.914,
      maw30: 14.208,
      rs: 88.0,
      rsd1d: -2.0,
      rsd5d: -4.0,
      rsd10d: 8.0,
      price_5d_h: 11.84,
      price_5d_L: 10.02,
      price_1m_h: 11.84,
      price_1m_l: 7.6,
      price_3m_h: 12.64,
      price_3m_l: 7.6,
      price_6m_h: 20.6,
      price_6m_l: 7.6,
      price_52w_h: 28.5,
      price_52w_l: 5.18,
      price_pc_1d: -1.642,
      price_pc_1w: -6.424,
      price_pc_1m: -4.263,
      price_pc_6m: -44.547,
      price_pc_1y: 82.095,
      price_pc_ytd: 82.095,
    },
    "0191": {
      id: "0191",
      c: 3.66,
      ma50: 3.638,
      ma100: 3.605,
      ma150: 4.047,
      ma200: 4.121,
      maw30: 4.032,
      rs: 37.0,
      rsd1d: 9.0,
      rsd5d: 5.0,
      rsd10d: 6.0,
      price_5d_h: 3.68,
      price_5d_L: 3.26,
      price_1m_h: 3.68,
      price_1m_l: 3.19,
      price_3m_h: 4.0,
      price_3m_l: 3.19,
      price_6m_h: 5.2,
      price_6m_l: 3.09,
      price_52w_h: 6.5,
      price_52w_l: 3.05,
      price_pc_1d: 4.571,
      price_pc_1w: 3.39,
      price_pc_1m: -0.272,
      price_pc_6m: -29.07,
      price_pc_1y: -39.0,
      price_pc_ytd: -39.0,
    },
    "0193": {
      id: "0193",
      c: 0.26,
      ma50: 0.284,
      ma100: 0.304,
      ma150: 0.323,
      ma200: 0.34,
      maw30: 0.323,
      rs: 32.0,
      rsd1d: 5.0,
      rsd5d: 10.0,
      rsd10d: 11.0,
      price_5d_h: 0.275,
      price_5d_L: 0.244,
      price_1m_h: 0.31,
      price_1m_l: 0.24,
      price_3m_h: 0.4,
      price_3m_l: 0.24,
      price_6m_h: 0.4,
      price_6m_l: 0.24,
      price_52w_h: 0.44,
      price_52w_l: 0.24,
      price_pc_1d: 1.961,
      price_pc_1w: 6.557,
      price_pc_1m: -16.129,
      price_pc_6m: -26.761,
      price_pc_1y: -36.585,
      price_pc_ytd: -36.585,
    },
    "0194": {
      id: "0194",
      c: 7.38,
      ma50: 7.615,
      ma100: 7.705,
      ma150: 7.79,
      ma200: 7.896,
      maw30: 7.779,
      rs: 65.0,
      rsd1d: 3.0,
      rsd5d: 4.0,
      rsd10d: 1.0,
      price_5d_h: 7.38,
      price_5d_L: 7.27,
      price_1m_h: 7.7,
      price_1m_l: 7.02,
      price_3m_h: 7.94,
      price_3m_l: 7.02,
      price_6m_h: 8.16,
      price_6m_l: 7.02,
      price_52w_h: 8.67,
      price_52w_l: 7.02,
      price_pc_1d: 0.82,
      price_pc_1w: 1.096,
      price_pc_1m: -4.031,
      price_pc_6m: -3.906,
      price_pc_1y: 0.0,
      price_pc_ytd: 0.0,
    },
    "0195": {
      id: "0195",
      c: 0.179,
      ma50: 0.132,
      ma100: 0.112,
      ma150: 0.108,
      ma200: 0.107,
      maw30: 0.107,
      rs: 97.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: 3.0,
      price_5d_h: 0.19,
      price_5d_L: 0.16,
      price_1m_h: 0.19,
      price_1m_l: 0.126,
      price_3m_h: 0.19,
      price_3m_l: 0.089,
      price_6m_h: 0.19,
      price_6m_l: 0.085,
      price_52w_h: 0.19,
      price_52w_l: 0.08,
      price_pc_1d: -3.243,
      price_pc_1w: 12.579,
      price_pc_1m: 26.056,
      price_pc_6m: 86.458,
      price_pc_1y: 73.786,
      price_pc_ytd: 73.786,
    },
    "0196": {
      id: "0196",
      c: 0.24,
      ma50: 0.257,
      ma100: 0.254,
      ma150: 0.252,
      ma200: 0.25,
      maw30: 0.254,
      rs: 58.0,
      rsd1d: -6.0,
      rsd5d: -8.0,
      rsd10d: -6.0,
      price_5d_h: 0.248,
      price_5d_L: 0.23,
      price_1m_h: 0.29,
      price_1m_l: 0.214,
      price_3m_h: 0.29,
      price_3m_l: 0.214,
      price_6m_h: 0.31,
      price_6m_l: 0.205,
      price_52w_h: 0.31,
      price_52w_l: 0.201,
      price_pc_1d: -0.826,
      price_pc_1w: -1.639,
      price_pc_1m: -12.727,
      price_pc_6m: 1.266,
      price_pc_1y: -5.882,
      price_pc_ytd: -5.882,
    },
    "0197": {
      id: "0197",
      c: 0.104,
      ma50: 0.114,
      ma100: 0.129,
      ma150: 0.12,
      ma200: 0.113,
      maw30: 0.121,
      rs: 64.0,
      rsd1d: -2.0,
      rsd5d: -1.0,
      rsd10d: -16.0,
      price_5d_h: 0.111,
      price_5d_L: 0.097,
      price_1m_h: 0.129,
      price_1m_l: 0.096,
      price_3m_h: 0.178,
      price_3m_l: 0.095,
      price_6m_h: 0.195,
      price_6m_l: 0.095,
      price_52w_h: 0.195,
      price_52w_l: 0.065,
      price_pc_1d: 0.971,
      price_pc_1w: 1.961,
      price_pc_1m: 1.961,
      price_pc_6m: 4.0,
      price_pc_1y: 40.541,
      price_pc_ytd: 40.541,
    },
    "0199": {
      id: "0199",
      c: 1.03,
      ma50: 1.062,
      ma100: 1.116,
      ma150: 1.081,
      ma200: 1.049,
      maw30: 1.088,
      rs: 65.0,
      rsd1d: 1.0,
      rsd5d: 5.0,
      rsd10d: 6.0,
      price_5d_h: 1.06,
      price_5d_L: 1.0,
      price_1m_h: 1.06,
      price_1m_l: 0.9,
      price_3m_h: 1.19,
      price_3m_l: 0.9,
      price_6m_h: 1.32,
      price_6m_l: 0.9,
      price_52w_h: 1.32,
      price_52w_l: 0.87,
      price_pc_1d: -0.962,
      price_pc_1w: 0.98,
      price_pc_1m: -2.83,
      price_pc_6m: 1.98,
      price_pc_1y: -10.435,
      price_pc_ytd: -10.435,
    },
    "0200": {
      id: "0200",
      c: 7.24,
      ma50: 8.171,
      ma100: 8.977,
      ma150: 9.408,
      ma200: 10.22,
      maw30: 9.436,
      rs: 18.0,
      rsd1d: 2.0,
      rsd5d: 4.0,
      rsd10d: 4.0,
      price_5d_h: 7.43,
      price_5d_L: 6.75,
      price_1m_h: 8.3,
      price_1m_l: 5.02,
      price_3m_h: 10.12,
      price_3m_l: 5.02,
      price_6m_h: 11.66,
      price_6m_l: 5.02,
      price_52w_h: 16.64,
      price_52w_l: 5.02,
      price_pc_1d: -1.764,
      price_pc_1w: 1.828,
      price_pc_1m: -6.581,
      price_pc_6m: -20.961,
      price_pc_1y: -54.806,
      price_pc_ytd: -54.806,
    },
    "0201": {
      id: "0201",
      c: 0.119,
      ma50: 0.119,
      ma100: 0.12,
      ma150: 0.119,
      ma200: 0.119,
      maw30: 0.118,
      rs: 76.0,
      rsd1d: 8.0,
      rsd5d: 13.0,
      rsd10d: 9.0,
      price_5d_h: 0.119,
      price_5d_L: 0.108,
      price_1m_h: 0.131,
      price_1m_l: 0.108,
      price_3m_h: 0.131,
      price_3m_l: 0.108,
      price_6m_h: 0.131,
      price_6m_l: 0.105,
      price_52w_h: 0.131,
      price_52w_l: 0.105,
      price_pc_1d: 3.478,
      price_pc_1w: 6.25,
      price_pc_1m: -1.653,
      price_pc_6m: 2.586,
      price_pc_1y: 5.31,
      price_pc_ytd: 5.31,
    },
    "0202": {
      id: "0202",
      c: 0.176,
      ma50: 0.183,
      ma100: 0.184,
      ma150: 0.184,
      ma200: 0.185,
      maw30: 0.184,
      rs: 65.0,
      rsd1d: -1.0,
      rsd5d: -5.0,
      rsd10d: -11.0,
      price_5d_h: 0.182,
      price_5d_L: 0.173,
      price_1m_h: 0.186,
      price_1m_l: 0.16,
      price_3m_h: 0.186,
      price_3m_l: 0.16,
      price_6m_h: 0.188,
      price_6m_l: 0.125,
      price_52w_h: 0.204,
      price_52w_l: 0.125,
      price_pc_1d: 0.0,
      price_pc_1w: -2.222,
      price_pc_1m: -4.865,
      price_pc_6m: -5.882,
      price_pc_1y: -5.376,
      price_pc_ytd: -5.376,
    },
    "0204": {
      id: "0204",
      c: 0.085,
      ma50: 0.057,
      ma100: 0.086,
      ma150: 0.123,
      ma200: 0.149,
      maw30: 0.12,
      rs: 24.0,
      rsd1d: 16.0,
      rsd5d: 20.0,
      rsd10d: 21.0,
      price_5d_h: 0.092,
      price_5d_L: 0.056,
      price_1m_h: 0.092,
      price_1m_l: 0.045,
      price_3m_h: 0.092,
      price_3m_l: 0.045,
      price_6m_h: 0.202,
      price_6m_l: 0.045,
      price_52w_h: 0.295,
      price_52w_l: 0.045,
      price_pc_1d: 2.41,
      price_pc_1w: 49.123,
      price_pc_1m: 51.786,
      price_pc_6m: -57.071,
      price_pc_1y: -67.357,
      price_pc_ytd: -67.357,
    },
    "0205": {
      id: "0205",
      c: 0.32,
      ma50: 0.382,
      ma100: 0.478,
      ma150: 0.558,
      ma200: 0.571,
      maw30: 0.561,
      rs: 56.0,
      rsd1d: 18.0,
      rsd5d: 21.0,
      rsd10d: -2.0,
      price_5d_h: 0.32,
      price_5d_L: 0.26,
      price_1m_h: 0.45,
      price_1m_l: 0.26,
      price_3m_h: 0.65,
      price_3m_l: 0.26,
      price_6m_h: 0.88,
      price_6m_l: 0.26,
      price_52w_h: 1.17,
      price_52w_l: 0.12,
      price_pc_1d: 10.345,
      price_pc_1w: 4.918,
      price_pc_1m: -15.789,
      price_pc_6m: -56.757,
      price_pc_1y: 150.0,
      price_pc_ytd: 150.0,
    },
    "0206": {
      id: "0206",
      c: 0.265,
      ma50: 0.33,
      ma100: 0.332,
      ma150: 0.328,
      ma200: 0.309,
      maw30: 0.334,
      rs: 54.0,
      rsd1d: -12.0,
      rsd5d: -22.0,
      rsd10d: -28.0,
      price_5d_h: 0.28,
      price_5d_L: 0.265,
      price_1m_h: 0.335,
      price_1m_l: 0.24,
      price_3m_h: 0.45,
      price_3m_l: 0.24,
      price_6m_h: 0.45,
      price_6m_l: 0.24,
      price_52w_h: 0.45,
      price_52w_l: 0.181,
      price_pc_1d: -5.357,
      price_pc_1w: -7.018,
      price_pc_1m: -20.896,
      price_pc_6m: -23.188,
      price_pc_1y: 26.19,
      price_pc_ytd: 26.19,
    },
    "0207": {
      id: "0207",
      c: 0.32,
      ma50: 0.366,
      ma100: 0.382,
      ma150: 0.395,
      ma200: 0.408,
      maw30: 0.396,
      rs: 27.0,
      rsd1d: -2.0,
      rsd5d: -5.0,
      rsd10d: -9.0,
      price_5d_h: 0.345,
      price_5d_L: 0.31,
      price_1m_h: 0.365,
      price_1m_l: 0.285,
      price_3m_h: 0.415,
      price_3m_l: 0.285,
      price_6m_h: 0.43,
      price_6m_l: 0.285,
      price_52w_h: 0.5,
      price_52w_l: 0.285,
      price_pc_1d: -3.03,
      price_pc_1w: -7.246,
      price_pc_1m: -11.111,
      price_pc_6m: -23.81,
      price_pc_1y: -33.333,
      price_pc_ytd: -33.333,
    },
    "0209": {
      id: "0209",
      c: 0.026,
      ma50: 0.032,
      ma100: 0.045,
      ma150: 0.052,
      ma200: 0.053,
      maw30: 0.052,
      rs: 7.0,
      rsd1d: 1.0,
      rsd5d: -1.0,
      rsd10d: 4.0,
      price_5d_h: 0.027,
      price_5d_L: 0.024,
      price_1m_h: 0.035,
      price_1m_l: 0.016,
      price_3m_h: 0.066,
      price_3m_l: 0.016,
      price_6m_h: 0.076,
      price_6m_l: 0.016,
      price_52w_h: 0.1,
      price_52w_l: 0.016,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: -21.212,
      price_pc_6m: -65.333,
      price_pc_1y: -48.0,
      price_pc_ytd: -48.0,
    },
    "0210": {
      id: "0210",
      c: 0.139,
      ma50: 0.154,
      ma100: 0.157,
      ma150: 0.161,
      ma200: 0.164,
      maw30: 0.163,
      rs: 36.0,
      rsd1d: 9.0,
      rsd5d: 7.0,
      rsd10d: -10.0,
      price_5d_h: 0.142,
      price_5d_L: 0.125,
      price_1m_h: 0.165,
      price_1m_l: 0.12,
      price_3m_h: 0.185,
      price_3m_l: 0.12,
      price_6m_h: 0.186,
      price_6m_l: 0.12,
      price_52w_h: 0.197,
      price_52w_l: 0.12,
      price_pc_1d: 6.923,
      price_pc_1w: 8.594,
      price_pc_1m: -6.711,
      price_pc_6m: -16.766,
      price_pc_1y: -29.442,
      price_pc_ytd: -29.442,
    },
    "0211": {
      id: "0211",
      c: 0.29,
      ma50: 0.278,
      ma100: 0.272,
      ma150: 0.283,
      ma200: 0.289,
      maw30: 0.284,
      rs: 69.0,
      rsd1d: 4.0,
      rsd5d: 3.0,
      rsd10d: 15.0,
      price_5d_h: 0.29,
      price_5d_L: 0.285,
      price_1m_h: 0.34,
      price_1m_l: 0.245,
      price_3m_h: 0.34,
      price_3m_l: 0.245,
      price_6m_h: 0.34,
      price_6m_l: 0.203,
      price_52w_h: 0.34,
      price_52w_l: 0.203,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: 5.455,
      price_pc_6m: -1.695,
      price_pc_1y: -12.121,
      price_pc_ytd: -12.121,
    },
    "0214": {
      id: "0214",
      c: 0.73,
      ma50: 0.815,
      ma100: 0.857,
      ma150: 0.89,
      ma200: 0.981,
      maw30: 0.888,
      rs: 25.0,
      rsd1d: 0.0,
      rsd5d: -2.0,
      rsd10d: -10.0,
      price_5d_h: 0.75,
      price_5d_L: 0.69,
      price_1m_h: 0.81,
      price_1m_l: 0.64,
      price_3m_h: 0.92,
      price_3m_l: 0.64,
      price_6m_h: 0.99,
      price_6m_l: 0.64,
      price_52w_h: 1.62,
      price_52w_l: 0.64,
      price_pc_1d: -2.667,
      price_pc_1w: -1.351,
      price_pc_1m: -10.976,
      price_pc_6m: -17.045,
      price_pc_1y: -34.821,
      price_pc_ytd: -34.821,
    },
    "0215": {
      id: "0215",
      c: 1.26,
      ma50: 1.277,
      ma100: 1.279,
      ma150: 1.262,
      ma200: 1.335,
      maw30: 1.266,
      rs: 65.0,
      rsd1d: 3.0,
      rsd5d: 2.0,
      rsd10d: 2.0,
      price_5d_h: 1.28,
      price_5d_L: 1.24,
      price_1m_h: 1.29,
      price_1m_l: 1.16,
      price_3m_h: 1.35,
      price_3m_l: 1.16,
      price_6m_h: 1.36,
      price_6m_l: 1.16,
      price_52w_h: 1.82,
      price_52w_l: 1.16,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: -2.326,
      price_pc_6m: 4.132,
      price_pc_1y: -15.436,
      price_pc_ytd: -15.436,
    },
    "0216": {
      id: "0216",
      c: 1.52,
      ma50: 1.573,
      ma100: 1.575,
      ma150: 1.614,
      ma200: 1.669,
      maw30: 1.611,
      rs: 60.0,
      rsd1d: 2.0,
      rsd5d: 4.0,
      rsd10d: -1.0,
      price_5d_h: 1.54,
      price_5d_L: 1.51,
      price_1m_h: 1.55,
      price_1m_l: 1.45,
      price_3m_h: 1.68,
      price_3m_l: 1.45,
      price_6m_h: 1.71,
      price_6m_l: 1.45,
      price_52w_h: 1.99,
      price_52w_l: 1.45,
      price_pc_1d: -0.654,
      price_pc_1w: -1.299,
      price_pc_1m: -6.173,
      price_pc_6m: -7.317,
      price_pc_1y: -8.434,
      price_pc_ytd: -8.434,
    },
    "0218": {
      id: "0218",
      c: 0.76,
      ma50: 0.861,
      ma100: 0.908,
      ma150: 0.959,
      ma200: 0.973,
      maw30: 0.964,
      rs: 31.0,
      rsd1d: 3.0,
      rsd5d: -9.0,
      rsd10d: -6.0,
      price_5d_h: 0.82,
      price_5d_L: 0.75,
      price_1m_h: 0.87,
      price_1m_l: 0.73,
      price_3m_h: 1.02,
      price_3m_l: 0.73,
      price_6m_h: 1.1,
      price_6m_l: 0.73,
      price_52w_h: 1.28,
      price_52w_l: 0.73,
      price_pc_1d: 0.0,
      price_pc_1w: -8.434,
      price_pc_1m: -10.588,
      price_pc_6m: -26.923,
      price_pc_1y: -26.214,
      price_pc_ytd: -26.214,
    },
    "0219": {
      id: "0219",
      c: 1.16,
      ma50: 1.284,
      ma100: 1.29,
      ma150: 1.263,
      ma200: 1.258,
      maw30: 1.265,
      rs: 58.0,
      rsd1d: -1.0,
      rsd5d: -6.0,
      rsd10d: -13.0,
      price_5d_h: 1.22,
      price_5d_L: 1.16,
      price_1m_h: 1.36,
      price_1m_l: 1.16,
      price_3m_h: 1.36,
      price_3m_l: 1.16,
      price_6m_h: 1.36,
      price_6m_l: 1.16,
      price_52w_h: 1.36,
      price_52w_l: 1.11,
      price_pc_1d: -1.695,
      price_pc_1w: -4.918,
      price_pc_1m: -12.121,
      price_pc_6m: -3.333,
      price_pc_1y: -12.121,
      price_pc_ytd: -12.121,
    },
    "0220": {
      id: "0220",
      c: 6.83,
      ma50: 7.484,
      ma100: 7.476,
      ma150: 7.369,
      ma200: 7.541,
      maw30: 7.411,
      rs: 44.0,
      rsd1d: 3.0,
      rsd5d: -7.0,
      rsd10d: -10.0,
      price_5d_h: 7.11,
      price_5d_L: 6.66,
      price_1m_h: 8.34,
      price_1m_l: 5.7,
      price_3m_h: 8.66,
      price_3m_l: 5.7,
      price_6m_h: 8.66,
      price_6m_l: 5.7,
      price_52w_h: 10.46,
      price_52w_l: 5.7,
      price_pc_1d: 0.147,
      price_pc_1w: -3.938,
      price_pc_1m: -15.679,
      price_pc_6m: -7.827,
      price_pc_1y: -29.588,
      price_pc_ytd: -29.588,
    },
    "0222": {
      id: "0222",
      c: 3.5,
      ma50: 3.507,
      ma100: 3.763,
      ma150: 3.671,
      ma200: 3.713,
      maw30: 3.659,
      rs: 65.0,
      rsd1d: 13.0,
      rsd5d: 20.0,
      rsd10d: -7.0,
      price_5d_h: 3.55,
      price_5d_L: 3.3,
      price_1m_h: 3.65,
      price_1m_l: 3.24,
      price_3m_h: 3.99,
      price_3m_l: 3.2,
      price_6m_h: 4.6,
      price_6m_l: 2.95,
      price_52w_h: 4.6,
      price_52w_l: 2.87,
      price_pc_1d: 5.105,
      price_pc_1w: 8.025,
      price_pc_1m: 2.941,
      price_pc_6m: 16.667,
      price_pc_1y: -12.281,
      price_pc_ytd: -12.281,
    },
    "0223": {
      id: "0223",
      c: 0.092,
      ma50: 0.119,
      ma100: 0.157,
      ma150: 0.181,
      ma200: 0.204,
      maw30: 0.184,
      rs: 12.0,
      rsd1d: 4.0,
      rsd5d: 2.0,
      rsd10d: 0.0,
      price_5d_h: 0.092,
      price_5d_L: 0.078,
      price_1m_h: 0.165,
      price_1m_l: 0.065,
      price_3m_h: 0.21,
      price_3m_l: 0.065,
      price_6m_h: 0.3,
      price_6m_l: 0.044,
      price_52w_h: 0.355,
      price_52w_l: 0.044,
      price_pc_1d: 17.949,
      price_pc_1w: 17.949,
      price_pc_1m: -17.857,
      price_pc_6m: -54.0,
      price_pc_1y: 33.333,
      price_pc_ytd: 33.333,
    },
    "0226": {
      id: "0226",
      c: 2.78,
      ma50: 2.707,
      ma100: 2.569,
      ma150: 2.546,
      ma200: 2.512,
      maw30: 2.549,
      rs: 88.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: -2.0,
      price_5d_h: 2.8,
      price_5d_L: 2.72,
      price_1m_h: 2.83,
      price_1m_l: 2.58,
      price_3m_h: 2.83,
      price_3m_l: 2.37,
      price_6m_h: 2.83,
      price_6m_l: 2.31,
      price_52w_h: 2.83,
      price_52w_l: 2.23,
      price_pc_1d: 2.206,
      price_pc_1w: 2.963,
      price_pc_1m: -0.714,
      price_pc_6m: 7.336,
      price_pc_1y: 23.556,
      price_pc_ytd: 23.556,
    },
    "0227": {
      id: "0227",
      c: 0.158,
      ma50: 0.182,
      ma100: 0.201,
      ma150: 0.223,
      ma200: 0.245,
      maw30: 0.223,
      rs: 14.0,
      rsd1d: 2.0,
      rsd5d: 2.0,
      rsd10d: -4.0,
      price_5d_h: 0.168,
      price_5d_L: 0.155,
      price_1m_h: 0.2,
      price_1m_l: 0.151,
      price_3m_h: 0.217,
      price_3m_l: 0.151,
      price_6m_h: 0.27,
      price_6m_l: 0.151,
      price_52w_h: 0.435,
      price_52w_l: 0.151,
      price_pc_1d: -1.25,
      price_pc_1w: -1.25,
      price_pc_1m: -15.054,
      price_pc_6m: -40.377,
      price_pc_1y: -54.857,
      price_pc_ytd: -54.857,
    },
    "0228": {
      id: "0228",
      c: 0.162,
      ma50: 0.156,
      ma100: 0.152,
      ma150: 0.146,
      ma200: 0.136,
      maw30: 0.146,
      rs: 92.0,
      rsd1d: 0.0,
      rsd5d: -2.0,
      rsd10d: -2.0,
      price_5d_h: 0.168,
      price_5d_L: 0.15,
      price_1m_h: 0.185,
      price_1m_l: 0.146,
      price_3m_h: 0.189,
      price_3m_l: 0.135,
      price_6m_h: 0.189,
      price_6m_l: 0.112,
      price_52w_h: 0.189,
      price_52w_l: 0.082,
      price_pc_1d: 4.516,
      price_pc_1w: -3.571,
      price_pc_1m: -8.475,
      price_pc_6m: 50.0,
      price_pc_1y: 60.396,
      price_pc_ytd: 60.396,
    },
    "0229": {
      id: "0229",
      c: 0.98,
      ma50: 0.974,
      ma100: 0.999,
      ma150: 1.014,
      ma200: 1.035,
      maw30: 1.015,
      rs: 62.0,
      rsd1d: 7.0,
      rsd5d: 5.0,
      rsd10d: -1.0,
      price_5d_h: 1.01,
      price_5d_L: 0.96,
      price_1m_h: 1.01,
      price_1m_l: 0.91,
      price_3m_h: 1.05,
      price_3m_l: 0.91,
      price_6m_h: 1.25,
      price_6m_l: 0.91,
      price_52w_h: 1.3,
      price_52w_l: 0.91,
      price_pc_1d: 2.083,
      price_pc_1w: 2.083,
      price_pc_1m: 0.0,
      price_pc_6m: -4.854,
      price_pc_1y: -11.712,
      price_pc_ytd: -11.712,
    },
    "0230": {
      id: "0230",
      c: 0.77,
      ma50: 0.85,
      ma100: 0.826,
      ma150: 0.813,
      ma200: 0.815,
      maw30: 0.817,
      rs: 64.0,
      rsd1d: -3.0,
      rsd5d: -2.0,
      rsd10d: -10.0,
      price_5d_h: 0.82,
      price_5d_L: 0.75,
      price_1m_h: 0.87,
      price_1m_l: 0.71,
      price_3m_h: 0.91,
      price_3m_l: 0.71,
      price_6m_h: 0.91,
      price_6m_l: 0.71,
      price_52w_h: 0.91,
      price_52w_l: 0.7,
      price_pc_1d: -1.282,
      price_pc_1w: -2.532,
      price_pc_1m: -11.494,
      price_pc_6m: 2.667,
      price_pc_1y: -9.412,
      price_pc_ytd: -9.412,
    },
    "0232": {
      id: "0232",
      c: 0.105,
      ma50: 0.122,
      ma100: 0.119,
      ma150: 0.123,
      ma200: 0.125,
      maw30: 0.122,
      rs: 45.0,
      rsd1d: 9.0,
      rsd5d: -8.0,
      rsd10d: -11.0,
      price_5d_h: 0.112,
      price_5d_L: 0.1,
      price_1m_h: 0.132,
      price_1m_l: 0.094,
      price_3m_h: 0.137,
      price_3m_l: 0.094,
      price_6m_h: 0.137,
      price_6m_l: 0.094,
      price_52w_h: 0.156,
      price_52w_l: 0.094,
      price_pc_1d: 3.96,
      price_pc_1w: -6.25,
      price_pc_1m: -18.605,
      price_pc_6m: -19.231,
      price_pc_1y: -20.455,
      price_pc_ytd: -20.455,
    },
    "0235": {
      id: "0235",
      c: 0.036,
      ma50: 0.04,
      ma100: 0.044,
      ma150: 0.048,
      ma200: 0.058,
      maw30: 0.048,
      rs: 10.0,
      rsd1d: 1.0,
      rsd5d: -2.0,
      rsd10d: 2.0,
      price_5d_h: 0.038,
      price_5d_L: 0.034,
      price_1m_h: 0.041,
      price_1m_l: 0.028,
      price_3m_h: 0.049,
      price_3m_l: 0.028,
      price_6m_h: 0.064,
      price_6m_l: 0.028,
      price_52w_h: 0.16,
      price_52w_l: 0.028,
      price_pc_1d: -2.703,
      price_pc_1w: -5.263,
      price_pc_1m: -10.0,
      price_pc_6m: -40.984,
      price_pc_1y: -76.0,
      price_pc_ytd: -76.0,
    },
    "0236": {
      id: "0236",
      c: 0.84,
      ma50: 0.898,
      ma100: 0.959,
      ma150: 0.986,
      ma200: 0.992,
      maw30: 0.991,
      rs: 39.0,
      rsd1d: -1.0,
      rsd5d: 2.0,
      rsd10d: -15.0,
      price_5d_h: 0.86,
      price_5d_L: 0.84,
      price_1m_h: 0.92,
      price_1m_l: 0.74,
      price_3m_h: 1.06,
      price_3m_l: 0.74,
      price_6m_h: 1.18,
      price_6m_l: 0.74,
      price_52w_h: 1.18,
      price_52w_l: 0.74,
      price_pc_1d: -2.326,
      price_pc_1w: -1.176,
      price_pc_1m: -7.692,
      price_pc_6m: -22.222,
      price_pc_1y: -10.638,
      price_pc_ytd: -10.638,
    },
    "0237": {
      id: "0237",
      c: 3.14,
      ma50: 3.209,
      ma100: 3.256,
      ma150: 3.299,
      ma200: 3.415,
      maw30: 3.298,
      rs: 61.0,
      rsd1d: -1.0,
      rsd5d: -2.0,
      rsd10d: 0.0,
      price_5d_h: 3.19,
      price_5d_L: 3.03,
      price_1m_h: 3.21,
      price_1m_l: 2.95,
      price_3m_h: 3.45,
      price_3m_l: 2.95,
      price_6m_h: 3.5,
      price_6m_l: 2.95,
      price_52w_h: 3.94,
      price_52w_l: 2.95,
      price_pc_1d: -1.567,
      price_pc_1w: -1.258,
      price_pc_1m: -1.567,
      price_pc_6m: -4.848,
      price_pc_1y: -6.269,
      price_pc_ytd: -6.269,
    },
    "0239": {
      id: "0239",
      c: 1.38,
      ma50: 1.39,
      ma100: 1.505,
      ma150: 1.574,
      ma200: 1.635,
      maw30: 1.57,
      rs: 38.0,
      rsd1d: 2.0,
      rsd5d: 3.0,
      rsd10d: 15.0,
      price_5d_h: 1.44,
      price_5d_L: 1.2,
      price_1m_h: 1.44,
      price_1m_l: 1.2,
      price_3m_h: 1.59,
      price_3m_l: 1.2,
      price_6m_h: 1.7,
      price_6m_l: 1.2,
      price_52w_h: 1.94,
      price_52w_l: 1.2,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: 1.471,
      price_pc_6m: -18.824,
      price_pc_1y: -24.59,
      price_pc_ytd: -24.59,
    },
    "0240": {
      id: "0240",
      c: 0.84,
      ma50: 0.896,
      ma100: 0.932,
      ma150: 0.953,
      ma200: 0.982,
      maw30: 0.954,
      rs: 41.0,
      rsd1d: 7.0,
      rsd5d: -8.0,
      rsd10d: -9.0,
      price_5d_h: 0.9,
      price_5d_L: 0.73,
      price_1m_h: 0.91,
      price_1m_l: 0.73,
      price_3m_h: 0.95,
      price_3m_l: 0.73,
      price_6m_h: 1.15,
      price_6m_l: 0.73,
      price_52w_h: 1.2,
      price_52w_l: 0.73,
      price_pc_1d: 3.704,
      price_pc_1w: -4.545,
      price_pc_1m: -6.667,
      price_pc_6m: -14.286,
      price_pc_1y: -23.636,
      price_pc_ytd: -23.636,
    },
    "0241": {
      id: "0241",
      c: 5.08,
      ma50: 5.425,
      ma100: 6.478,
      ma150: 8.109,
      ma200: 9.64,
      maw30: 8.109,
      rs: 8.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: 3.0,
      price_5d_h: 5.31,
      price_5d_L: 4.4,
      price_1m_h: 5.53,
      price_1m_l: 3.28,
      price_3m_h: 7.38,
      price_3m_l: 3.28,
      price_6m_h: 13.08,
      price_6m_l: 3.28,
      price_52w_h: 24.7,
      price_52w_l: 3.28,
      price_pc_1d: -2.682,
      price_pc_1w: 1.397,
      price_pc_1m: -6.618,
      price_pc_6m: -54.643,
      price_pc_1y: -78.056,
      price_pc_ytd: -78.056,
    },
    "0242": {
      id: "0242",
      c: 1.72,
      ma50: 1.956,
      ma100: 2.038,
      ma150: 2.083,
      ma200: 2.149,
      maw30: 2.092,
      rs: 31.0,
      rsd1d: 2.0,
      rsd5d: -3.0,
      rsd10d: -3.0,
      price_5d_h: 1.82,
      price_5d_L: 1.69,
      price_1m_h: 2.03,
      price_1m_l: 1.5,
      price_3m_h: 2.16,
      price_3m_l: 1.5,
      price_6m_h: 2.34,
      price_6m_l: 1.5,
      price_52w_h: 2.6,
      price_52w_l: 1.5,
      price_pc_1d: -1.149,
      price_pc_1w: -4.972,
      price_pc_1m: -13.568,
      price_pc_6m: -17.703,
      price_pc_1y: -29.796,
      price_pc_ytd: -29.796,
    },
    "0243": {
      id: "0243",
      c: 0.305,
      ma50: 0.361,
      ma100: 0.359,
      ma150: 0.39,
      ma200: 0.416,
      maw30: 0.388,
      rs: 45.0,
      rsd1d: -5.0,
      rsd5d: -36.0,
      rsd10d: -27.0,
      price_5d_h: 0.39,
      price_5d_L: 0.265,
      price_1m_h: 0.39,
      price_1m_l: 0.26,
      price_3m_h: 0.465,
      price_3m_l: 0.26,
      price_6m_h: 0.465,
      price_6m_l: 0.26,
      price_52w_h: 0.6,
      price_52w_l: 0.255,
      price_pc_1d: -3.175,
      price_pc_1w: -15.278,
      price_pc_1m: -23.75,
      price_pc_6m: -29.07,
      price_pc_1y: 15.094,
      price_pc_ytd: 15.094,
    },
    "0244": {
      id: "0244",
      c: 0.35,
      ma50: 0.373,
      ma100: 0.429,
      ma150: 0.454,
      ma200: 0.477,
      maw30: 0.452,
      rs: 56.0,
      rsd1d: -20.0,
      rsd5d: 8.0,
      rsd10d: -9.0,
      price_5d_h: 0.41,
      price_5d_L: 0.315,
      price_1m_h: 0.41,
      price_1m_l: 0.3,
      price_3m_h: 0.49,
      price_3m_l: 0.3,
      price_6m_h: 0.57,
      price_6m_l: 0.3,
      price_52w_h: 0.79,
      price_52w_l: 0.235,
      price_pc_1d: -10.256,
      price_pc_1w: 2.941,
      price_pc_1m: -1.408,
      price_pc_6m: -23.913,
      price_pc_1y: 42.857,
      price_pc_ytd: 42.857,
    },
    "0245": {
      id: "0245",
      c: 0.057,
      ma50: 0.062,
      ma100: 0.067,
      ma150: 0.072,
      ma200: 0.077,
      maw30: 0.072,
      rs: 25.0,
      rsd1d: -3.0,
      rsd5d: 7.0,
      rsd10d: 0.0,
      price_5d_h: 0.06,
      price_5d_L: 0.049,
      price_1m_h: 0.061,
      price_1m_l: 0.049,
      price_3m_h: 0.074,
      price_3m_l: 0.049,
      price_6m_h: 0.093,
      price_6m_l: 0.049,
      price_52w_h: 0.107,
      price_52w_l: 0.049,
      price_pc_1d: -5.0,
      price_pc_1w: 1.786,
      price_pc_1m: -8.065,
      price_pc_6m: -21.918,
      price_pc_1y: -44.118,
      price_pc_ytd: -44.118,
    },
    "0250": {
      id: "0250",
      c: 0.02,
      ma50: 0.028,
      ma100: 0.03,
      ma150: 0.032,
      ma200: 0.035,
      maw30: 0.032,
      rs: 10.0,
      rsd1d: -4.0,
      rsd5d: -4.0,
      rsd10d: -6.0,
      price_5d_h: 0.027,
      price_5d_L: 0.019,
      price_1m_h: 0.027,
      price_1m_l: 0.019,
      price_3m_h: 0.037,
      price_3m_l: 0.019,
      price_6m_h: 0.037,
      price_6m_l: 0.019,
      price_52w_h: 0.058,
      price_52w_l: 0.019,
      price_pc_1d: -16.667,
      price_pc_1w: -16.667,
      price_pc_1m: -25.926,
      price_pc_6m: -42.857,
      price_pc_1y: -62.264,
      price_pc_ytd: -62.264,
    },
    "0251": {
      id: "0251",
      c: 5.83,
      ma50: 6.029,
      ma100: 5.9,
      ma150: 6.024,
      ma200: 6.232,
      maw30: 6.006,
      rs: 53.0,
      rsd1d: -4.0,
      rsd5d: -9.0,
      rsd10d: -25.0,
      price_5d_h: 6.06,
      price_5d_L: 5.83,
      price_1m_h: 6.74,
      price_1m_l: 5.83,
      price_3m_h: 6.74,
      price_3m_l: 4.98,
      price_6m_h: 6.74,
      price_6m_l: 4.98,
      price_52w_h: 9.99,
      price_52w_l: 4.98,
      price_pc_1d: -2.833,
      price_pc_1w: -2.833,
      price_pc_1m: -9.331,
      price_pc_6m: -8.906,
      price_pc_1y: -25.732,
      price_pc_ytd: -25.732,
    },
    "0253": {
      id: "0253",
      c: 0.85,
      ma50: 0.895,
      ma100: 0.896,
      ma150: 0.896,
      ma200: 0.895,
      maw30: 0.9,
      rs: 65.0,
      rsd1d: 0.0,
      rsd5d: -10.0,
      rsd10d: -9.0,
      price_5d_h: 0.9,
      price_5d_L: 0.85,
      price_1m_h: 0.9,
      price_1m_l: 0.8,
      price_3m_h: 0.94,
      price_3m_l: 0.8,
      price_6m_h: 0.98,
      price_6m_l: 0.8,
      price_52w_h: 1.02,
      price_52w_l: 0.8,
      price_pc_1d: -1.163,
      price_pc_1w: -5.556,
      price_pc_1m: -5.556,
      price_pc_6m: -4.494,
      price_pc_1y: -8.602,
      price_pc_ytd: -8.602,
    },
    "0254": {
      id: "0254",
      c: 0.135,
      ma50: 0.566,
      ma100: 0.392,
      ma150: 0.308,
      ma200: 0.267,
      maw30: 0.309,
      rs: 66.0,
      rsd1d: -27.0,
      rsd5d: -12.0,
      rsd10d: 46.0,
      price_5d_h: 0.29,
      price_5d_L: 0.122,
      price_1m_h: 0.765,
      price_1m_l: 0.092,
      price_3m_h: 1.42,
      price_3m_l: 0.092,
      price_6m_h: 1.42,
      price_6m_l: 0.092,
      price_52w_h: 1.42,
      price_52w_l: 0.092,
      price_pc_1d: -29.319,
      price_pc_1w: -6.897,
      price_pc_1m: -82.353,
      price_pc_6m: -4.93,
      price_pc_1y: -4.93,
      price_pc_ytd: -4.93,
    },
    "0255": {
      id: "0255",
      c: 3.71,
      ma50: 3.854,
      ma100: 3.806,
      ma150: 3.761,
      ma200: 3.707,
      maw30: 3.765,
      rs: 82.0,
      rsd1d: -3.0,
      rsd5d: -6.0,
      rsd10d: -7.0,
      price_5d_h: 3.9,
      price_5d_L: 3.63,
      price_1m_h: 4.0,
      price_1m_l: 3.6,
      price_3m_h: 4.02,
      price_3m_l: 3.6,
      price_6m_h: 4.02,
      price_6m_l: 3.52,
      price_52w_h: 4.02,
      price_52w_l: 3.16,
      price_pc_1d: -1.33,
      price_pc_1w: -4.872,
      price_pc_1m: -6.784,
      price_pc_6m: 3.922,
      price_pc_1y: 17.405,
      price_pc_ytd: 17.405,
    },
    "0256": {
      id: "0256",
      c: 1.01,
      ma50: 1.159,
      ma100: 1.269,
      ma150: 1.306,
      ma200: 1.351,
      maw30: 1.308,
      rs: 18.0,
      rsd1d: 1.0,
      rsd5d: 2.0,
      rsd10d: -8.0,
      price_5d_h: 1.09,
      price_5d_L: 0.97,
      price_1m_h: 1.1,
      price_1m_l: 0.9,
      price_3m_h: 1.6,
      price_3m_l: 0.9,
      price_6m_h: 1.6,
      price_6m_l: 0.9,
      price_52w_h: 1.78,
      price_52w_l: 0.9,
      price_pc_1d: 0.0,
      price_pc_1w: 2.02,
      price_pc_1m: -1.942,
      price_pc_6m: -26.812,
      price_pc_1y: -39.881,
      price_pc_ytd: -39.881,
    },
    "0257": {
      id: "0257",
      c: 4.74,
      ma50: 5.49,
      ma100: 5.586,
      ma150: 5.648,
      ma200: 5.36,
      maw30: 5.687,
      rs: 46.0,
      rsd1d: -2.0,
      rsd5d: 0.0,
      rsd10d: -7.0,
      price_5d_h: 4.84,
      price_5d_L: 4.5,
      price_1m_h: 5.8,
      price_1m_l: 4.13,
      price_3m_h: 6.65,
      price_3m_l: 4.13,
      price_6m_h: 6.65,
      price_6m_l: 4.13,
      price_52w_h: 6.65,
      price_52w_l: 4.02,
      price_pc_1d: -1.25,
      price_pc_1w: -2.066,
      price_pc_1m: -15.357,
      price_pc_6m: -19.388,
      price_pc_1y: -9.886,
      price_pc_ytd: -9.886,
    },
    "0258": {
      id: "0258",
      c: 1.91,
      ma50: 2.031,
      ma100: 2.063,
      ma150: 2.028,
      ma200: 2.004,
      maw30: 2.036,
      rs: 68.0,
      rsd1d: 0.0,
      rsd5d: -4.0,
      rsd10d: -3.0,
      price_5d_h: 2.03,
      price_5d_L: 1.9,
      price_1m_h: 2.1,
      price_1m_l: 1.84,
      price_3m_h: 2.2,
      price_3m_l: 1.84,
      price_6m_h: 2.56,
      price_6m_l: 1.84,
      price_52w_h: 2.56,
      price_52w_l: 1.81,
      price_pc_1d: -1.036,
      price_pc_1w: -4.975,
      price_pc_1m: -8.173,
      price_pc_6m: 0.0,
      price_pc_1y: -3.046,
      price_pc_ytd: -3.046,
    },
    "0259": {
      id: "0259",
      c: 2.47,
      ma50: 2.625,
      ma100: 2.651,
      ma150: 2.532,
      ma200: 2.626,
      maw30: 2.54,
      rs: 79.0,
      rsd1d: -2.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 2.5,
      price_5d_L: 2.42,
      price_1m_h: 2.7,
      price_1m_l: 2.16,
      price_3m_h: 3.1,
      price_3m_l: 2.16,
      price_6m_h: 3.1,
      price_6m_l: 2.0,
      price_52w_h: 3.31,
      price_52w_l: 1.94,
      price_pc_1d: -0.803,
      price_pc_1w: -0.803,
      price_pc_1m: -8.519,
      price_pc_6m: 18.182,
      price_pc_1y: 25.381,
      price_pc_ytd: 25.381,
    },
    "0260": {
      id: "0260",
      c: 0.024,
      ma50: 0.022,
      ma100: 0.022,
      ma150: 0.022,
      ma200: 0.024,
      maw30: 0.022,
      rs: 76.0,
      rsd1d: -3.0,
      rsd5d: -12.0,
      rsd10d: 11.0,
      price_5d_h: 0.026,
      price_5d_L: 0.021,
      price_1m_h: 0.029,
      price_1m_l: 0.018,
      price_3m_h: 0.031,
      price_3m_l: 0.017,
      price_6m_h: 0.031,
      price_6m_l: 0.017,
      price_52w_h: 0.04,
      price_52w_l: 0.017,
      price_pc_1d: 0.0,
      price_pc_1w: -11.111,
      price_pc_1m: -7.692,
      price_pc_6m: 14.286,
      price_pc_1y: -17.241,
      price_pc_ytd: -17.241,
    },
    "0262": {
      id: "0262",
      c: 0.103,
      ma50: 0.108,
      ma100: 0.104,
      ma150: 0.105,
      ma200: 0.11,
      maw30: 0.106,
      rs: 53.0,
      rsd1d: -1.0,
      rsd5d: -11.0,
      rsd10d: -10.0,
      price_5d_h: 0.108,
      price_5d_L: 0.1,
      price_1m_h: 0.126,
      price_1m_l: 0.088,
      price_3m_h: 0.142,
      price_3m_l: 0.086,
      price_6m_h: 0.142,
      price_6m_l: 0.086,
      price_52w_h: 0.16,
      price_52w_l: 0.086,
      price_pc_1d: -1.905,
      price_pc_1w: -7.207,
      price_pc_1m: -4.63,
      price_pc_6m: -5.505,
      price_pc_1y: -21.374,
      price_pc_ytd: -21.374,
    },
    "0264": {
      id: "0264",
      c: 0.88,
      ma50: 0.884,
      ma100: 0.81,
      ma150: 0.763,
      ma200: 0.795,
      maw30: 0.773,
      rs: 87.0,
      rsd1d: 21.0,
      rsd5d: -4.0,
      rsd10d: 20.0,
      price_5d_h: 0.88,
      price_5d_L: 0.72,
      price_1m_h: 1.01,
      price_1m_l: 0.63,
      price_3m_h: 1.04,
      price_3m_l: 0.61,
      price_6m_h: 1.04,
      price_6m_l: 0.6,
      price_52w_h: 1.1,
      price_52w_l: 0.6,
      price_pc_1d: 17.333,
      price_pc_1w: -10.204,
      price_pc_1m: -8.333,
      price_pc_6m: 29.412,
      price_pc_1y: -4.348,
      price_pc_ytd: -4.348,
    },
    "0265": {
      id: "0265",
      c: 0.039,
      ma50: 0.046,
      ma100: 0.048,
      ma150: 0.052,
      ma200: 0.058,
      maw30: 0.052,
      rs: 20.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -3.0,
      price_5d_h: 0.04,
      price_5d_L: 0.036,
      price_1m_h: 0.048,
      price_1m_l: 0.036,
      price_3m_h: 0.057,
      price_3m_l: 0.036,
      price_6m_h: 0.089,
      price_6m_l: 0.036,
      price_52w_h: 0.089,
      price_52w_l: 0.036,
      price_pc_1d: -2.5,
      price_pc_1w: -2.5,
      price_pc_1m: -18.75,
      price_pc_6m: -33.898,
      price_pc_1y: -37.097,
      price_pc_ytd: -37.097,
    },
    "0267": {
      id: "0267",
      c: 8.71,
      ma50: 8.758,
      ma100: 8.194,
      ma150: 8.333,
      ma200: 8.326,
      maw30: 8.347,
      rs: 85.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: -2.0,
      price_5d_h: 8.79,
      price_5d_L: 8.4,
      price_1m_h: 9.2,
      price_1m_l: 7.98,
      price_3m_h: 9.26,
      price_3m_l: 7.7,
      price_6m_h: 9.26,
      price_6m_l: 6.96,
      price_52w_h: 10.24,
      price_52w_l: 6.96,
      price_pc_1d: 2.35,
      price_pc_1w: 3.199,
      price_pc_1m: -4.391,
      price_pc_6m: 4.94,
      price_pc_1y: 18.182,
      price_pc_ytd: 18.182,
    },
    "0268": {
      id: "0268",
      c: 17.42,
      ma50: 18.8,
      ma100: 21.404,
      ma150: 23.071,
      ma200: 23.673,
      maw30: 22.938,
      rs: 22.0,
      rsd1d: 4.0,
      rsd5d: 5.0,
      rsd10d: -1.0,
      price_5d_h: 18.4,
      price_5d_L: 15.84,
      price_1m_h: 20.05,
      price_1m_l: 11.82,
      price_3m_h: 25.45,
      price_3m_l: 11.82,
      price_6m_h: 29.2,
      price_6m_l: 11.82,
      price_52w_h: 32.15,
      price_52w_l: 11.82,
      price_pc_1d: 2.11,
      price_pc_1w: 1.397,
      price_pc_1m: -9.176,
      price_pc_6m: -33.129,
      price_pc_1y: -30.459,
      price_pc_ytd: -30.459,
    },
    "0269": {
      id: "0269",
      c: 0.021,
      ma50: 0.023,
      ma100: 0.021,
      ma150: 0.019,
      ma200: 0.018,
      maw30: 0.019,
      rs: 80.0,
      rsd1d: 0.0,
      rsd5d: 21.0,
      rsd10d: -8.0,
      price_5d_h: 0.021,
      price_5d_L: 0.02,
      price_1m_h: 0.023,
      price_1m_l: 0.018,
      price_3m_h: 0.032,
      price_3m_l: 0.018,
      price_6m_h: 0.032,
      price_6m_l: 0.011,
      price_52w_h: 0.035,
      price_52w_l: 0.011,
      price_pc_1d: 0.0,
      price_pc_1w: 10.526,
      price_pc_1m: -8.696,
      price_pc_6m: 61.538,
      price_pc_1y: -8.696,
      price_pc_ytd: -8.696,
    },
    "0270": {
      id: "0270",
      c: 10.72,
      ma50: 10.608,
      ma100: 10.35,
      ma150: 10.332,
      ma200: 10.512,
      maw30: 10.344,
      rs: 75.0,
      rsd1d: -2.0,
      rsd5d: -2.0,
      rsd10d: 0.0,
      price_5d_h: 11.02,
      price_5d_L: 10.52,
      price_1m_h: 11.18,
      price_1m_l: 9.6,
      price_3m_h: 11.4,
      price_3m_l: 9.6,
      price_6m_h: 11.4,
      price_6m_l: 9.5,
      price_52w_h: 12.68,
      price_52w_l: 9.5,
      price_pc_1d: -1.289,
      price_pc_1w: -2.368,
      price_pc_1m: 2.095,
      price_pc_6m: 5.512,
      price_pc_1y: -13.548,
      price_pc_ytd: -13.548,
    },
    "0271": {
      id: "0271",
      c: 0.48,
      ma50: 0.501,
      ma100: 0.591,
      ma150: 0.921,
      ma200: 1.079,
      maw30: 0.903,
      rs: 9.0,
      rsd1d: 3.0,
      rsd5d: 0.0,
      rsd10d: -1.0,
      price_5d_h: 0.5,
      price_5d_L: 0.405,
      price_1m_h: 0.55,
      price_1m_l: 0.405,
      price_3m_h: 0.58,
      price_3m_l: 0.405,
      price_6m_h: 1.94,
      price_6m_l: 0.405,
      price_52w_h: 2.2,
      price_52w_l: 0.405,
      price_pc_1d: 12.941,
      price_pc_1w: -4.0,
      price_pc_1m: 2.128,
      price_pc_6m: -69.032,
      price_pc_1y: -59.664,
      price_pc_ytd: -59.664,
    },
    "0272": {
      id: "0272",
      c: 1.2,
      ma50: 1.128,
      ma100: 1.091,
      ma150: 1.132,
      ma200: 1.168,
      maw30: 1.131,
      rs: 82.0,
      rsd1d: 2.0,
      rsd5d: 17.0,
      rsd10d: 24.0,
      price_5d_h: 1.21,
      price_5d_L: 1.13,
      price_1m_h: 1.21,
      price_1m_l: 0.89,
      price_3m_h: 1.21,
      price_3m_l: 0.89,
      price_6m_h: 1.23,
      price_6m_l: 0.89,
      price_52w_h: 1.45,
      price_52w_l: 0.89,
      price_pc_1d: 2.564,
      price_pc_1w: 10.092,
      price_pc_1m: 5.263,
      price_pc_6m: 2.564,
      price_pc_1y: -0.826,
      price_pc_ytd: -0.826,
    },
    "0273": {
      id: "0273",
      c: 0.018,
      ma50: 0.02,
      ma100: 0.021,
      ma150: 0.022,
      ma200: 0.024,
      maw30: 0.022,
      rs: 25.0,
      rsd1d: -4.0,
      rsd5d: 2.0,
      rsd10d: 5.0,
      price_5d_h: 0.02,
      price_5d_L: 0.018,
      price_1m_h: 0.021,
      price_1m_l: 0.017,
      price_3m_h: 0.024,
      price_3m_l: 0.017,
      price_6m_h: 0.034,
      price_6m_l: 0.017,
      price_52w_h: 0.042,
      price_52w_l: 0.017,
      price_pc_1d: -5.263,
      price_pc_1w: 0.0,
      price_pc_1m: -10.0,
      price_pc_6m: -21.739,
      price_pc_1y: -55.0,
      price_pc_ytd: -55.0,
    },
    "0276": {
      id: "0276",
      c: 0.91,
      ma50: 1.011,
      ma100: 1.042,
      ma150: 1.004,
      ma200: 0.975,
      maw30: 1.013,
      rs: 50.0,
      rsd1d: -9.0,
      rsd5d: -2.0,
      rsd10d: 10.0,
      price_5d_h: 0.97,
      price_5d_L: 0.84,
      price_1m_h: 1.1,
      price_1m_l: 0.74,
      price_3m_h: 1.3,
      price_3m_l: 0.74,
      price_6m_h: 1.53,
      price_6m_l: 0.74,
      price_52w_h: 1.53,
      price_52w_l: 0.65,
      price_pc_1d: -4.211,
      price_pc_1w: -2.151,
      price_pc_1m: -10.784,
      price_pc_6m: 2.247,
      price_pc_1y: -16.514,
      price_pc_ytd: -16.514,
    },
    "0278": {
      id: "0278",
      c: 4.4,
      ma50: 4.669,
      ma100: 4.832,
      ma150: 4.857,
      ma200: 4.889,
      maw30: 4.876,
      rs: 56.0,
      rsd1d: 5.0,
      rsd5d: 14.0,
      rsd10d: 15.0,
      price_5d_h: 4.4,
      price_5d_L: 4.14,
      price_1m_h: 4.79,
      price_1m_l: 2.76,
      price_3m_h: 5.0,
      price_3m_l: 2.76,
      price_6m_h: 5.15,
      price_6m_l: 2.76,
      price_52w_h: 5.18,
      price_52w_l: 2.76,
      price_pc_1d: 1.149,
      price_pc_1w: 4.762,
      price_pc_1m: -8.333,
      price_pc_6m: -5.983,
      price_pc_1y: -10.569,
      price_pc_ytd: -10.569,
    },
    "0279": {
      id: "0279",
      c: 0.176,
      ma50: 0.2,
      ma100: 0.215,
      ma150: 0.18,
      ma200: 0.157,
      maw30: 0.18,
      rs: 94.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: -1.0,
      price_5d_h: 0.19,
      price_5d_L: 0.173,
      price_1m_h: 0.206,
      price_1m_l: 0.165,
      price_3m_h: 0.248,
      price_3m_l: 0.165,
      price_6m_h: 0.335,
      price_6m_l: 0.09,
      price_52w_h: 0.335,
      price_52w_l: 0.09,
      price_pc_1d: -1.124,
      price_pc_1w: -3.297,
      price_pc_1m: -14.146,
      price_pc_6m: 95.556,
      price_pc_1y: 95.556,
      price_pc_ytd: 95.556,
    },
    "0280": {
      id: "0280",
      c: 0.41,
      ma50: 0.384,
      ma100: 0.41,
      ma150: 0.419,
      ma200: 0.436,
      maw30: 0.42,
      rs: 77.0,
      rsd1d: 5.0,
      rsd5d: 18.0,
      rsd10d: 38.0,
      price_5d_h: 0.41,
      price_5d_L: 0.385,
      price_1m_h: 0.41,
      price_1m_l: 0.305,
      price_3m_h: 0.44,
      price_3m_l: 0.305,
      price_6m_h: 0.54,
      price_6m_l: 0.305,
      price_52w_h: 0.63,
      price_52w_l: 0.305,
      price_pc_1d: 1.235,
      price_pc_1w: 7.895,
      price_pc_1m: 6.494,
      price_pc_6m: -4.651,
      price_pc_1y: 30.159,
      price_pc_ytd: 30.159,
    },
    "0285": {
      id: "0285",
      c: 15.8,
      ma50: 21.376,
      ma100: 25.062,
      ma150: 26.523,
      ma200: 30.827,
      maw30: 26.368,
      rs: 7.0,
      rsd1d: 1.0,
      rsd5d: -2.0,
      rsd10d: -4.0,
      price_5d_h: 18.76,
      price_5d_L: 15.32,
      price_1m_h: 23.35,
      price_1m_l: 15.32,
      price_3m_h: 29.4,
      price_3m_l: 15.32,
      price_6m_h: 34.6,
      price_6m_l: 15.32,
      price_52w_h: 55.0,
      price_52w_l: 15.32,
      price_pc_1d: -3.776,
      price_pc_1w: -13.567,
      price_pc_1m: -27.854,
      price_pc_6m: -42.441,
      price_pc_1y: -68.959,
      price_pc_ytd: -68.959,
    },
    "0286": {
      id: "0286",
      c: 0.495,
      ma50: 0.545,
      ma100: 0.717,
      ma150: 0.784,
      ma200: 0.895,
      maw30: 0.783,
      rs: 13.0,
      rsd1d: 2.0,
      rsd5d: 2.0,
      rsd10d: 6.0,
      price_5d_h: 0.5,
      price_5d_L: 0.435,
      price_1m_h: 0.58,
      price_1m_l: 0.3,
      price_3m_h: 0.89,
      price_3m_l: 0.3,
      price_6m_h: 1.1,
      price_6m_l: 0.3,
      price_52w_h: 1.55,
      price_52w_l: 0.3,
      price_pc_1d: 1.02,
      price_pc_1w: 1.02,
      price_pc_1m: -10.0,
      price_pc_6m: -45.0,
      price_pc_1y: -14.655,
      price_pc_ytd: -14.655,
    },
    "0288": {
      id: "0288",
      c: 4.95,
      ma50: 5.197,
      ma100: 5.122,
      ma150: 5.283,
      ma200: 5.599,
      maw30: 5.258,
      rs: 52.0,
      rsd1d: 6.0,
      rsd5d: 12.0,
      rsd10d: 11.0,
      price_5d_h: 5.05,
      price_5d_L: 4.63,
      price_1m_h: 5.6,
      price_1m_l: 4.28,
      price_3m_h: 5.78,
      price_3m_l: 4.28,
      price_6m_h: 5.78,
      price_6m_l: 4.28,
      price_52w_h: 7.54,
      price_52w_l: 4.28,
      price_pc_1d: -0.802,
      price_pc_1w: 2.911,
      price_pc_1m: -9.174,
      price_pc_6m: -10.811,
      price_pc_1y: -26.119,
      price_pc_ytd: -26.119,
    },
    "0290": {
      id: "0290",
      c: 0.179,
      ma50: 0.199,
      ma100: 0.219,
      ma150: 0.237,
      ma200: 0.255,
      maw30: 0.235,
      rs: 19.0,
      rsd1d: 1.0,
      rsd5d: -11.0,
      rsd10d: 5.0,
      price_5d_h: 0.202,
      price_5d_L: 0.163,
      price_1m_h: 0.221,
      price_1m_l: 0.16,
      price_3m_h: 0.224,
      price_3m_l: 0.16,
      price_6m_h: 0.325,
      price_6m_l: 0.16,
      price_52w_h: 0.59,
      price_52w_l: 0.16,
      price_pc_1d: -5.789,
      price_pc_1w: -10.945,
      price_pc_1m: -10.05,
      price_pc_6m: -33.704,
      price_pc_1y: -55.25,
      price_pc_ytd: -55.25,
    },
    "0291": {
      id: "0291",
      c: 48.05,
      ma50: 56.599,
      ma100: 59.711,
      ma150: 59.901,
      ma200: 61.258,
      maw30: 60.302,
      rs: 30.0,
      rsd1d: 0.0,
      rsd5d: 3.0,
      rsd10d: -9.0,
      price_5d_h: 49.8,
      price_5d_L: 44.4,
      price_1m_h: 64.95,
      price_1m_l: 41.95,
      price_3m_h: 64.95,
      price_3m_l: 41.95,
      price_6m_h: 68.7,
      price_6m_l: 41.95,
      price_52w_h: 76.0,
      price_52w_l: 41.95,
      price_pc_1d: -1.537,
      price_pc_1w: 0.209,
      price_pc_1m: -22.312,
      price_pc_6m: -16.435,
      price_pc_1y: -22.249,
      price_pc_ytd: -22.249,
    },
    "0293": {
      id: "0293",
      c: 7.71,
      ma50: 7.02,
      ma100: 6.828,
      ma150: 6.829,
      ma200: 6.752,
      maw30: 6.835,
      rs: 89.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 7.82,
      price_5d_L: 7.4,
      price_1m_h: 8.11,
      price_1m_l: 6.3,
      price_3m_h: 8.11,
      price_3m_l: 6.25,
      price_6m_h: 8.11,
      price_6m_l: 6.11,
      price_52w_h: 8.11,
      price_52w_l: 6.1,
      price_pc_1d: -0.772,
      price_pc_1w: 0.916,
      price_pc_1m: 10.776,
      price_pc_6m: 17.71,
      price_pc_1y: 6.492,
      price_pc_ytd: 6.492,
    },
    "0296": {
      id: "0296",
      c: 0.69,
      ma50: 0.779,
      ma100: 0.824,
      ma150: 0.854,
      ma200: 0.903,
      maw30: 0.854,
      rs: 27.0,
      rsd1d: 0.0,
      rsd5d: -2.0,
      rsd10d: -3.0,
      price_5d_h: 0.75,
      price_5d_L: 0.69,
      price_1m_h: 0.81,
      price_1m_l: 0.66,
      price_3m_h: 0.94,
      price_3m_l: 0.66,
      price_6m_h: 0.98,
      price_6m_l: 0.66,
      price_52w_h: 1.29,
      price_52w_l: 0.66,
      price_pc_1d: -1.429,
      price_pc_1w: -5.479,
      price_pc_1m: -14.815,
      price_pc_6m: -19.767,
      price_pc_1y: -37.838,
      price_pc_ytd: -37.838,
    },
    "0297": {
      id: "0297",
      c: 1.33,
      ma50: 1.154,
      ma100: 1.159,
      ma150: 1.236,
      ma200: 1.263,
      maw30: 1.225,
      rs: 88.0,
      rsd1d: -2.0,
      rsd5d: 1.0,
      rsd10d: 29.0,
      price_5d_h: 1.42,
      price_5d_L: 1.28,
      price_1m_h: 1.42,
      price_1m_l: 0.94,
      price_3m_h: 1.42,
      price_3m_l: 0.94,
      price_6m_h: 1.51,
      price_6m_l: 0.94,
      price_52w_h: 1.78,
      price_52w_l: 0.94,
      price_pc_1d: -2.92,
      price_pc_1w: 3.101,
      price_pc_1m: 18.75,
      price_pc_6m: -0.746,
      price_pc_1y: 26.667,
      price_pc_ytd: 26.667,
    },
    "0298": {
      id: "0298",
      c: 0.39,
      ma50: 0.409,
      ma100: 0.402,
      ma150: 0.408,
      ma200: 0.424,
      maw30: 0.408,
      rs: 60.0,
      rsd1d: 2.0,
      rsd5d: -2.0,
      rsd10d: 0.0,
      price_5d_h: 0.395,
      price_5d_L: 0.385,
      price_1m_h: 0.405,
      price_1m_l: 0.36,
      price_3m_h: 0.455,
      price_3m_l: 0.36,
      price_6m_h: 0.455,
      price_6m_l: 0.36,
      price_52w_h: 0.53,
      price_52w_l: 0.36,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: -4.878,
      price_pc_6m: -2.5,
      price_pc_1y: -13.333,
      price_pc_ytd: -13.333,
    },
    "0299": {
      id: "0299",
      c: 0.034,
      ma50: 0.04,
      ma100: 0.094,
      ma150: 0.134,
      ma200: 0.166,
      maw30: 0.132,
      rs: 1.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.037,
      price_5d_L: 0.029,
      price_1m_h: 0.047,
      price_1m_l: 0.022,
      price_3m_h: 0.105,
      price_3m_l: 0.022,
      price_6m_h: 0.221,
      price_6m_l: 0.022,
      price_52w_h: 0.355,
      price_52w_l: 0.022,
      price_pc_1d: 3.03,
      price_pc_1w: 17.241,
      price_pc_1m: -17.073,
      price_pc_6m: -84.186,
      price_pc_1y: -87.857,
      price_pc_ytd: -87.857,
    },
    "0302": {
      id: "0302",
      c: 2.28,
      ma50: 2.758,
      ma100: 2.992,
      ma150: 3.084,
      ma200: 3.316,
      maw30: 3.093,
      rs: 24.0,
      rsd1d: 1.0,
      rsd5d: -5.0,
      rsd10d: -3.0,
      price_5d_h: 2.47,
      price_5d_L: 2.25,
      price_1m_h: 2.87,
      price_1m_l: 1.96,
      price_3m_h: 3.4,
      price_3m_l: 1.96,
      price_6m_h: 3.78,
      price_6m_l: 1.96,
      price_52w_h: 5.09,
      price_52w_l: 1.96,
      price_pc_1d: -0.437,
      price_pc_1w: -8.8,
      price_pc_1m: -17.391,
      price_pc_6m: -25.733,
      price_pc_1y: -24.503,
      price_pc_ytd: -24.503,
    },
    "0303": {
      id: "0303",
      c: 57.0,
      ma50: 59.64,
      ma100: 60.742,
      ma150: 62.326,
      ma200: 66.183,
      maw30: 61.972,
      rs: 51.0,
      rsd1d: 0.0,
      rsd5d: 4.0,
      rsd10d: 10.0,
      price_5d_h: 58.0,
      price_5d_L: 56.3,
      price_1m_h: 64.3,
      price_1m_l: 50.35,
      price_3m_h: 66.0,
      price_3m_l: 50.35,
      price_6m_h: 66.0,
      price_6m_l: 50.35,
      price_52w_h: 84.3,
      price_52w_l: 50.35,
      price_pc_1d: -1.213,
      price_pc_1w: 0.885,
      price_pc_1m: -11.284,
      price_pc_6m: 0.176,
      price_pc_1y: -19.492,
      price_pc_ytd: -19.492,
    },
    "0305": {
      id: "0305",
      c: 1.05,
      ma50: 1.219,
      ma100: 1.457,
      ma150: 1.429,
      ma200: 1.502,
      maw30: 1.438,
      rs: 18.0,
      rsd1d: -1.0,
      rsd5d: 1.0,
      rsd10d: 3.0,
      price_5d_h: 1.12,
      price_5d_L: 1.04,
      price_1m_h: 1.22,
      price_1m_l: 0.84,
      price_3m_h: 1.74,
      price_3m_l: 0.84,
      price_6m_h: 2.03,
      price_6m_l: 0.84,
      price_52w_h: 2.37,
      price_52w_l: 0.84,
      price_pc_1d: -4.545,
      price_pc_1w: -3.67,
      price_pc_1m: -10.256,
      price_pc_6m: -11.017,
      price_pc_1y: -44.149,
      price_pc_ytd: -44.149,
    },
    "0306": {
      id: "0306",
      c: 1.8,
      ma50: 1.815,
      ma100: 1.99,
      ma150: 2.033,
      ma200: 2.095,
      maw30: 2.032,
      rs: 39.0,
      rsd1d: 2.0,
      rsd5d: 3.0,
      rsd10d: 18.0,
      price_5d_h: 1.85,
      price_5d_L: 1.78,
      price_1m_h: 1.85,
      price_1m_l: 1.5,
      price_3m_h: 2.15,
      price_3m_l: 1.5,
      price_6m_h: 2.43,
      price_6m_l: 1.5,
      price_52w_h: 2.7,
      price_52w_l: 1.5,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: 5.263,
      price_pc_6m: -13.876,
      price_pc_1y: -22.747,
      price_pc_ytd: -22.747,
    },
    "0308": {
      id: "0308",
      c: 1.49,
      ma50: 1.541,
      ma100: 1.509,
      ma150: 1.388,
      ma200: 1.335,
      maw30: 1.399,
      rs: 85.0,
      rsd1d: -2.0,
      rsd5d: -2.0,
      rsd10d: -2.0,
      price_5d_h: 1.51,
      price_5d_L: 1.43,
      price_1m_h: 1.55,
      price_1m_l: 1.25,
      price_3m_h: 1.8,
      price_3m_l: 1.25,
      price_6m_h: 1.8,
      price_6m_l: 1.11,
      price_52w_h: 1.8,
      price_52w_l: 1.03,
      price_pc_1d: -0.667,
      price_pc_1w: -0.667,
      price_pc_1m: -2.614,
      price_pc_6m: 29.565,
      price_pc_1y: 11.194,
      price_pc_ytd: 11.194,
    },
    "0309": {
      id: "0309",
      c: 0.062,
      ma50: 0.067,
      ma100: 0.069,
      ma150: 0.072,
      ma200: 0.074,
      maw30: 0.072,
      rs: 38.0,
      rsd1d: 3.0,
      rsd5d: -6.0,
      rsd10d: -2.0,
      price_5d_h: 0.065,
      price_5d_L: 0.055,
      price_1m_h: 0.079,
      price_1m_l: 0.05,
      price_3m_h: 0.085,
      price_3m_l: 0.05,
      price_6m_h: 0.089,
      price_6m_l: 0.05,
      price_52w_h: 0.097,
      price_52w_l: 0.05,
      price_pc_1d: 0.0,
      price_pc_1w: -8.824,
      price_pc_1m: -19.481,
      price_pc_6m: -26.19,
      price_pc_1y: -20.513,
      price_pc_ytd: -20.513,
    },
    "0310": {
      id: "0310",
      c: 0.027,
      ma50: 0.028,
      ma100: 0.029,
      ma150: 0.031,
      ma200: 0.032,
      maw30: 0.031,
      rs: 46.0,
      rsd1d: 9.0,
      rsd5d: 2.0,
      rsd10d: 2.0,
      price_5d_h: 0.027,
      price_5d_L: 0.024,
      price_1m_h: 0.029,
      price_1m_l: 0.023,
      price_3m_h: 0.031,
      price_3m_l: 0.023,
      price_6m_h: 0.042,
      price_6m_l: 0.023,
      price_52w_h: 0.07,
      price_52w_l: 0.023,
      price_pc_1d: 3.846,
      price_pc_1w: 0.0,
      price_pc_1m: -6.897,
      price_pc_6m: -20.588,
      price_pc_1y: -22.857,
      price_pc_ytd: -22.857,
    },
    "0312": {
      id: "0312",
      c: 0.115,
      ma50: 0.129,
      ma100: 0.141,
      ma150: 0.16,
      ma200: 0.193,
      maw30: 0.159,
      rs: 11.0,
      rsd1d: 2.0,
      rsd5d: 1.0,
      rsd10d: 1.0,
      price_5d_h: 0.124,
      price_5d_L: 0.105,
      price_1m_h: 0.139,
      price_1m_l: 0.093,
      price_3m_h: 0.152,
      price_3m_l: 0.093,
      price_6m_h: 0.206,
      price_6m_l: 0.093,
      price_52w_h: 0.455,
      price_52w_l: 0.093,
      price_pc_1d: 0.0,
      price_pc_1w: -4.167,
      price_pc_1m: -9.449,
      price_pc_6m: -39.474,
      price_pc_1y: -68.919,
      price_pc_ytd: -68.919,
    },
    "0313": {
      id: "0313",
      c: 0.011,
      ma50: 0.011,
      ma100: 0.011,
      ma150: 0.011,
      ma200: 0.012,
      maw30: 0.011,
      rs: 51.0,
      rsd1d: -9.0,
      rsd5d: -6.0,
      rsd10d: -21.0,
      price_5d_h: 0.011,
      price_5d_L: 0.01,
      price_1m_h: 0.012,
      price_1m_l: 0.01,
      price_3m_h: 0.016,
      price_3m_l: 0.01,
      price_6m_h: 0.029,
      price_6m_l: 0.01,
      price_52w_h: 0.03,
      price_52w_l: 0.01,
      price_pc_1d: 0.0,
      price_pc_1w: 10.0,
      price_pc_1m: -8.333,
      price_pc_6m: -8.333,
      price_pc_1y: -8.333,
      price_pc_ytd: -8.333,
    },
    "0315": {
      id: "0315",
      c: 4.18,
      ma50: 4.319,
      ma100: 4.268,
      ma150: 4.347,
      ma200: 4.392,
      maw30: 4.362,
      rs: 67.0,
      rsd1d: 4.0,
      rsd5d: 5.0,
      rsd10d: 2.0,
      price_5d_h: 4.2,
      price_5d_L: 4.11,
      price_1m_h: 4.45,
      price_1m_l: 3.97,
      price_3m_h: 4.59,
      price_3m_l: 3.97,
      price_6m_h: 4.85,
      price_6m_l: 3.97,
      price_52w_h: 4.85,
      price_52w_l: 3.97,
      price_pc_1d: 1.211,
      price_pc_1w: 0.966,
      price_pc_1m: -6.067,
      price_pc_6m: -5.215,
      price_pc_1y: -8.932,
      price_pc_ytd: -8.932,
    },
    "0316": {
      id: "0316",
      c: 209.4,
      ma50: 209.262,
      ma100: 186.843,
      ma150: 173.758,
      ma200: 169.6,
      maw30: 173.607,
      rs: 93.0,
      rsd1d: -1.0,
      rsd5d: -3.0,
      rsd10d: -4.0,
      price_5d_h: 234.0,
      price_5d_L: 177.0,
      price_1m_h: 249.0,
      price_1m_l: 177.0,
      price_3m_h: 249.0,
      price_3m_l: 176.5,
      price_6m_h: 249.0,
      price_6m_l: 111.3,
      price_52w_h: 249.0,
      price_52w_l: 91.35,
      price_pc_1d: 1.453,
      price_pc_1w: -9.819,
      price_pc_1m: -4.818,
      price_pc_6m: 54.882,
      price_pc_1y: 58.516,
      price_pc_ytd: 58.516,
    },
    "0317": {
      id: "0317",
      c: 5.72,
      ma50: 6.653,
      ma100: 6.945,
      ma150: 7.03,
      ma200: 6.963,
      maw30: 7.058,
      rs: 28.0,
      rsd1d: 2.0,
      rsd5d: 2.0,
      rsd10d: -1.0,
      price_5d_h: 5.83,
      price_5d_L: 5.6,
      price_1m_h: 7.18,
      price_1m_l: 5.3,
      price_3m_h: 7.97,
      price_3m_l: 5.3,
      price_6m_h: 8.58,
      price_6m_l: 5.3,
      price_52w_h: 8.95,
      price_52w_l: 5.3,
      price_pc_1d: -1.379,
      price_pc_1w: -1.209,
      price_pc_1m: -18.75,
      price_pc_6m: -19.888,
      price_pc_1y: -30.498,
      price_pc_ytd: -30.498,
    },
    "0320": {
      id: "0320",
      c: 0.7,
      ma50: 0.808,
      ma100: 0.862,
      ma150: 0.83,
      ma200: 0.838,
      maw30: 0.834,
      rs: 77.0,
      rsd1d: -2.0,
      rsd5d: -3.0,
      rsd10d: -7.0,
      price_5d_h: 0.72,
      price_5d_L: 0.68,
      price_1m_h: 0.8,
      price_1m_l: 0.67,
      price_3m_h: 0.98,
      price_3m_l: 0.67,
      price_6m_h: 0.99,
      price_6m_l: 0.67,
      price_52w_h: 1.15,
      price_52w_l: 0.38,
      price_pc_1d: 0.0,
      price_pc_1w: -2.778,
      price_pc_1m: -7.895,
      price_pc_6m: 0.0,
      price_pc_1y: 57.303,
      price_pc_ytd: 57.303,
    },
    "0321": {
      id: "0321",
      c: 1.46,
      ma50: 1.556,
      ma100: 1.583,
      ma150: 1.596,
      ma200: 1.642,
      maw30: 1.595,
      rs: 54.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: 3.0,
      price_5d_h: 1.55,
      price_5d_L: 1.45,
      price_1m_h: 1.64,
      price_1m_l: 1.35,
      price_3m_h: 1.73,
      price_3m_l: 1.35,
      price_6m_h: 1.73,
      price_6m_l: 1.35,
      price_52w_h: 2.11,
      price_52w_l: 1.35,
      price_pc_1d: -1.351,
      price_pc_1w: -3.311,
      price_pc_1m: -10.429,
      price_pc_6m: -8.75,
      price_pc_1y: -18.889,
      price_pc_ytd: -18.889,
    },
    "0322": {
      id: "0322",
      c: 13.2,
      ma50: 15.849,
      ma100: 15.777,
      ma150: 15.311,
      ma200: 15.163,
      maw30: 15.405,
      rs: 48.0,
      rsd1d: 9.0,
      rsd5d: -11.0,
      rsd10d: -19.0,
      price_5d_h: 14.06,
      price_5d_L: 12.22,
      price_1m_h: 17.36,
      price_1m_l: 11.7,
      price_3m_h: 18.48,
      price_3m_l: 11.7,
      price_6m_h: 18.48,
      price_6m_l: 11.7,
      price_52w_h: 18.48,
      price_52w_l: 11.7,
      price_pc_1d: 3.937,
      price_pc_1w: -4.07,
      price_pc_1m: -23.611,
      price_pc_6m: -8.84,
      price_pc_1y: -7.95,
      price_pc_ytd: -7.95,
    },
    "0323": {
      id: "0323",
      c: 3.15,
      ma50: 3.171,
      ma100: 3.058,
      ma150: 3.321,
      ma200: 3.358,
      maw30: 3.303,
      rs: 80.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 26.0,
      price_5d_h: 3.18,
      price_5d_L: 2.98,
      price_1m_h: 3.39,
      price_1m_l: 2.53,
      price_3m_h: 3.57,
      price_3m_l: 2.53,
      price_6m_h: 3.82,
      price_6m_l: 2.53,
      price_52w_h: 4.8,
      price_52w_l: 2.53,
      price_pc_1d: 1.286,
      price_pc_1w: 0.0,
      price_pc_1m: -1.869,
      price_pc_6m: -11.268,
      price_pc_1y: 16.236,
      price_pc_ytd: 16.236,
    },
    "0326": {
      id: "0326",
      c: 0.85,
      ma50: 0.87,
      ma100: 0.901,
      ma150: 0.951,
      ma200: 1.005,
      maw30: 0.947,
      rs: 44.0,
      rsd1d: 11.0,
      rsd5d: 4.0,
      rsd10d: -9.0,
      price_5d_h: 0.9,
      price_5d_L: 0.78,
      price_1m_h: 0.9,
      price_1m_l: 0.63,
      price_3m_h: 0.9,
      price_3m_l: 0.63,
      price_6m_h: 1.38,
      price_6m_l: 0.63,
      price_52w_h: 1.47,
      price_52w_l: 0.63,
      price_pc_1d: 6.25,
      price_pc_1w: 0.0,
      price_pc_1m: -5.556,
      price_pc_6m: -6.593,
      price_pc_1y: -40.141,
      price_pc_ytd: -40.141,
    },
    "0327": {
      id: "0327",
      c: 6.45,
      ma50: 5.79,
      ma100: 5.803,
      ma150: 6.897,
      ma200: 7.442,
      maw30: 6.887,
      rs: 55.0,
      rsd1d: 5.0,
      rsd5d: 5.0,
      rsd10d: 20.0,
      price_5d_h: 6.48,
      price_5d_L: 5.75,
      price_1m_h: 6.59,
      price_1m_l: 4.6,
      price_3m_h: 6.59,
      price_3m_l: 4.6,
      price_6m_h: 9.99,
      price_6m_l: 4.3,
      price_52w_h: 11.0,
      price_52w_l: 4.3,
      price_pc_1d: 2.381,
      price_pc_1w: 1.896,
      price_pc_1m: 10.825,
      price_pc_6m: -34.518,
      price_pc_1y: -23.214,
      price_pc_ytd: -23.214,
    },
    "0328": {
      id: "0328",
      c: 0.104,
      ma50: 0.113,
      ma100: 0.151,
      ma150: 0.185,
      ma200: 0.2,
      maw30: 0.186,
      rs: 7.0,
      rsd1d: 2.0,
      rsd5d: 4.0,
      rsd10d: 4.0,
      price_5d_h: 0.108,
      price_5d_L: 0.083,
      price_1m_h: 0.119,
      price_1m_l: 0.07,
      price_3m_h: 0.17,
      price_3m_l: 0.07,
      price_6m_h: 0.275,
      price_6m_l: 0.07,
      price_52w_h: 0.29,
      price_52w_l: 0.07,
      price_pc_1d: 4.0,
      price_pc_1w: 25.301,
      price_pc_1m: -7.143,
      price_pc_6m: -61.481,
      price_pc_1y: -55.745,
      price_pc_ytd: -55.745,
    },
    "0329": {
      id: "0329",
      c: 3.48,
      ma50: 3.392,
      ma100: 3.288,
      ma150: 3.501,
      ma200: 3.527,
      maw30: 3.501,
      rs: 74.0,
      rsd1d: 6.0,
      rsd5d: -12.0,
      rsd10d: -9.0,
      price_5d_h: 3.58,
      price_5d_L: 3.1,
      price_1m_h: 3.95,
      price_1m_l: 3.1,
      price_3m_h: 3.95,
      price_3m_l: 3.0,
      price_6m_h: 4.33,
      price_6m_l: 2.51,
      price_52w_h: 4.74,
      price_52w_l: 2.51,
      price_pc_1d: 7.077,
      price_pc_1w: -4.658,
      price_pc_1m: -3.064,
      price_pc_6m: -4.132,
      price_pc_1y: 11.182,
      price_pc_ytd: 11.182,
    },
    "0330": {
      id: "0330",
      c: 0.89,
      ma50: 0.867,
      ma100: 0.78,
      ma150: 0.759,
      ma200: 0.746,
      maw30: 0.754,
      rs: 91.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: 0.0,
      price_5d_h: 0.94,
      price_5d_L: 0.85,
      price_1m_h: 0.96,
      price_1m_l: 0.82,
      price_3m_h: 0.98,
      price_3m_l: 0.71,
      price_6m_h: 0.98,
      price_6m_l: 0.64,
      price_52w_h: 0.98,
      price_52w_l: 0.6,
      price_pc_1d: 0.0,
      price_pc_1w: 2.299,
      price_pc_1m: 0.0,
      price_pc_6m: 28.986,
      price_pc_1y: 12.658,
      price_pc_ytd: 12.658,
    },
    "0331": {
      id: "0331",
      c: 5.3,
      ma50: 5.731,
      ma100: 5.926,
      ma150: 6.108,
      ma200: 6.108,
      maw30: 6.149,
      rs: 57.0,
      rsd1d: 1.0,
      rsd5d: -4.0,
      rsd10d: -1.0,
      price_5d_h: 5.5,
      price_5d_L: 5.26,
      price_1m_h: 5.98,
      price_1m_l: 5.0,
      price_3m_h: 6.26,
      price_3m_l: 5.0,
      price_6m_h: 7.35,
      price_6m_l: 5.0,
      price_52w_h: 8.85,
      price_52w_l: 4.79,
      price_pc_1d: -0.935,
      price_pc_1w: -1.487,
      price_pc_1m: -7.986,
      price_pc_6m: -23.3,
      price_pc_1y: 10.879,
      price_pc_ytd: 10.879,
    },
    "0332": {
      id: "0332",
      c: 0.45,
      ma50: 0.513,
      ma100: 0.513,
      ma150: 0.513,
      ma200: 0.476,
      maw30: 0.52,
      rs: 85.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: -2.0,
      price_5d_h: 0.46,
      price_5d_L: 0.435,
      price_1m_h: 0.55,
      price_1m_l: 0.39,
      price_3m_h: 0.63,
      price_3m_l: 0.39,
      price_6m_h: 0.63,
      price_6m_l: 0.39,
      price_52w_h: 0.76,
      price_52w_l: 0.25,
      price_pc_1d: 1.124,
      price_pc_1w: 4.651,
      price_pc_1m: -19.643,
      price_pc_6m: -16.667,
      price_pc_1y: 63.636,
      price_pc_ytd: 63.636,
    },
    "0333": {
      id: "0333",
      c: 0.5,
      ma50: 0.491,
      ma100: 0.499,
      ma150: 0.523,
      ma200: 0.546,
      maw30: 0.521,
      rs: 60.0,
      rsd1d: 4.0,
      rsd5d: 27.0,
      rsd10d: 20.0,
      price_5d_h: 0.5,
      price_5d_L: 0.475,
      price_1m_h: 0.51,
      price_1m_l: 0.44,
      price_3m_h: 0.6,
      price_3m_l: 0.44,
      price_6m_h: 0.61,
      price_6m_l: 0.44,
      price_52w_h: 0.72,
      price_52w_l: 0.44,
      price_pc_1d: 0.0,
      price_pc_1w: 11.111,
      price_pc_1m: -1.961,
      price_pc_6m: -13.793,
      price_pc_1y: -13.793,
      price_pc_ytd: -13.793,
    },
    "0334": {
      id: "0334",
      c: 0.44,
      ma50: 0.481,
      ma100: 0.507,
      ma150: 0.533,
      ma200: 0.551,
      maw30: 0.534,
      rs: 40.0,
      rsd1d: -8.0,
      rsd5d: -10.0,
      rsd10d: -20.0,
      price_5d_h: 0.48,
      price_5d_L: 0.44,
      price_1m_h: 0.55,
      price_1m_l: 0.42,
      price_3m_h: 0.55,
      price_3m_l: 0.42,
      price_6m_h: 0.64,
      price_6m_l: 0.42,
      price_52w_h: 0.92,
      price_52w_l: 0.42,
      price_pc_1d: -4.348,
      price_pc_1w: -5.376,
      price_pc_1m: -5.376,
      price_pc_6m: -24.138,
      price_pc_1y: -11.111,
      price_pc_ytd: -11.111,
    },
    "0335": {
      id: "0335",
      c: 0.65,
      ma50: 0.603,
      ma100: 0.696,
      ma150: 0.748,
      ma200: 0.768,
      maw30: 0.748,
      rs: 33.0,
      rsd1d: 5.0,
      rsd5d: -6.0,
      rsd10d: -8.0,
      price_5d_h: 0.67,
      price_5d_L: 0.61,
      price_1m_h: 0.7,
      price_1m_l: 0.47,
      price_3m_h: 0.82,
      price_3m_l: 0.47,
      price_6m_h: 0.95,
      price_6m_l: 0.47,
      price_52w_h: 0.95,
      price_52w_l: 0.47,
      price_pc_1d: 3.175,
      price_pc_1w: -4.412,
      price_pc_1m: 25.0,
      price_pc_6m: -21.687,
      price_pc_1y: -26.966,
      price_pc_ytd: -26.966,
    },
    "0336": {
      id: "0336",
      c: 4.37,
      ma50: 5.232,
      ma100: 11.226,
      ma150: 12.795,
      ma200: 14.12,
      maw30: 12.863,
      rs: 3.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: 1.0,
      price_5d_h: 5.08,
      price_5d_L: 3.95,
      price_1m_h: 5.08,
      price_1m_l: 3.21,
      price_3m_h: 15.4,
      price_3m_l: 3.21,
      price_6m_h: 26.15,
      price_6m_l: 3.21,
      price_52w_h: 26.15,
      price_52w_l: 3.21,
      price_pc_1d: -0.228,
      price_pc_1w: -11.895,
      price_pc_1m: -2.237,
      price_pc_6m: -71.363,
      price_pc_1y: -46.446,
      price_pc_ytd: -46.446,
    },
    "0337": {
      id: "0337",
      c: 1.77,
      ma50: 1.437,
      ma100: 1.504,
      ma150: 1.639,
      ma200: 1.767,
      maw30: 1.631,
      rs: 60.0,
      rsd1d: 8.0,
      rsd5d: 43.0,
      rsd10d: 40.0,
      price_5d_h: 1.82,
      price_5d_L: 1.24,
      price_1m_h: 1.82,
      price_1m_l: 1.01,
      price_3m_h: 1.82,
      price_3m_l: 1.01,
      price_6m_h: 1.97,
      price_6m_l: 1.01,
      price_52w_h: 2.96,
      price_52w_l: 1.01,
      price_pc_1d: 2.312,
      price_pc_1w: 37.209,
      price_pc_1m: 32.09,
      price_pc_6m: -9.231,
      price_pc_1y: -37.676,
      price_pc_ytd: -37.676,
    },
    "0338": {
      id: "0338",
      c: 1.63,
      ma50: 1.712,
      ma100: 1.763,
      ma150: 1.808,
      ma200: 1.789,
      maw30: 1.818,
      rs: 54.0,
      rsd1d: 4.0,
      rsd5d: 6.0,
      rsd10d: 13.0,
      price_5d_h: 1.64,
      price_5d_L: 1.54,
      price_1m_h: 1.76,
      price_1m_l: 1.44,
      price_3m_h: 1.92,
      price_3m_l: 1.44,
      price_6m_h: 2.05,
      price_6m_l: 1.44,
      price_52w_h: 2.25,
      price_52w_l: 1.44,
      price_pc_1d: 0.617,
      price_pc_1w: 1.875,
      price_pc_1m: -7.386,
      price_pc_6m: -14.211,
      price_pc_1y: -11.413,
      price_pc_ytd: -11.413,
    },
    "0340": {
      id: "0340",
      c: 0.89,
      ma50: 0.754,
      ma100: 0.745,
      ma150: 0.742,
      ma200: 0.666,
      maw30: 0.744,
      rs: 97.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: 1.0,
      price_5d_h: 0.99,
      price_5d_L: 0.8,
      price_1m_h: 0.99,
      price_1m_l: 0.5,
      price_3m_h: 0.99,
      price_3m_l: 0.5,
      price_6m_h: 0.99,
      price_6m_l: 0.5,
      price_52w_h: 1.2,
      price_52w_l: 0.295,
      price_pc_1d: 1.136,
      price_pc_1w: 9.877,
      price_pc_1m: 23.611,
      price_pc_6m: 20.27,
      price_pc_1y: 187.097,
      price_pc_ytd: 187.097,
    },
    "0341": {
      id: "0341",
      c: 13.1,
      ma50: 12.775,
      ma100: 13.32,
      ma150: 13.584,
      ma200: 14.087,
      maw30: 13.594,
      rs: 51.0,
      rsd1d: 1.0,
      rsd5d: 10.0,
      rsd10d: 8.0,
      price_5d_h: 13.24,
      price_5d_L: 12.24,
      price_1m_h: 13.24,
      price_1m_l: 10.58,
      price_3m_h: 14.1,
      price_3m_l: 10.58,
      price_6m_h: 14.6,
      price_6m_l: 10.58,
      price_52w_h: 17.46,
      price_52w_l: 10.58,
      price_pc_1d: -0.908,
      price_pc_1w: 4.968,
      price_pc_1m: 9.349,
      price_pc_6m: -8.392,
      price_pc_1y: -22.577,
      price_pc_ytd: -22.577,
    },
    "0342": {
      id: "0342",
      c: 0.039,
      ma50: 0.054,
      ma100: 0.06,
      ma150: 0.146,
      ma200: 0.211,
      maw30: 0.144,
      rs: 2.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.048,
      price_5d_L: 0.039,
      price_1m_h: 0.06,
      price_1m_l: 0.039,
      price_3m_h: 0.074,
      price_3m_l: 0.039,
      price_6m_h: 0.375,
      price_6m_l: 0.039,
      price_52w_h: 0.62,
      price_52w_l: 0.039,
      price_pc_1d: -9.302,
      price_pc_1w: -13.333,
      price_pc_1m: -30.357,
      price_pc_6m: -88.182,
      price_pc_1y: -93.71,
      price_pc_ytd: -93.71,
    },
    "0343": {
      id: "0343",
      c: 0.71,
      ma50: 0.735,
      ma100: 0.761,
      ma150: 0.755,
      ma200: 0.746,
      maw30: 0.756,
      rs: 93.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: -2.0,
      price_5d_h: 0.73,
      price_5d_L: 0.7,
      price_1m_h: 0.75,
      price_1m_l: 0.69,
      price_3m_h: 0.8,
      price_3m_l: 0.69,
      price_6m_h: 0.88,
      price_6m_l: 0.69,
      price_52w_h: 0.88,
      price_52w_l: 0.219,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: -4.054,
      price_pc_6m: -2.74,
      price_pc_1y: 186.29,
      price_pc_ytd: 186.29,
    },
    "0345": {
      id: "0345",
      c: 14.86,
      ma50: 14.609,
      ma100: 15.555,
      ma150: 16.813,
      ma200: 18.631,
      maw30: 16.803,
      rs: 25.0,
      rsd1d: 5.0,
      rsd5d: 9.0,
      rsd10d: 3.0,
      price_5d_h: 15.28,
      price_5d_L: 13.62,
      price_1m_h: 15.28,
      price_1m_l: 12.22,
      price_3m_h: 16.36,
      price_3m_l: 12.22,
      price_6m_h: 19.8,
      price_6m_l: 12.22,
      price_52w_h: 31.35,
      price_52w_l: 12.22,
      price_pc_1d: 2.06,
      price_pc_1w: 7.681,
      price_pc_1m: 2.483,
      price_pc_6m: -24.949,
      price_pc_1y: -50.134,
      price_pc_ytd: -50.134,
    },
    "0346": {
      id: "0346",
      c: 0.057,
      ma50: 0.051,
      ma100: 0.055,
      ma150: 0.063,
      ma200: 0.064,
      maw30: 0.063,
      rs: 50.0,
      rsd1d: 5.0,
      rsd5d: -31.0,
      rsd10d: 18.0,
      price_5d_h: 0.073,
      price_5d_L: 0.054,
      price_1m_h: 0.103,
      price_1m_l: 0.038,
      price_3m_h: 0.103,
      price_3m_l: 0.037,
      price_6m_h: 0.103,
      price_6m_l: 0.037,
      price_52w_h: 0.105,
      price_52w_l: 0.037,
      price_pc_1d: 0.0,
      price_pc_1w: -17.391,
      price_pc_1m: 50.0,
      price_pc_6m: -34.483,
      price_pc_1y: -35.955,
      price_pc_ytd: -35.955,
    },
    "0347": {
      id: "0347",
      c: 3.61,
      ma50: 3.732,
      ma100: 3.701,
      ma150: 4.159,
      ma200: 4.358,
      maw30: 4.113,
      rs: 56.0,
      rsd1d: -1.0,
      rsd5d: 6.0,
      rsd10d: 16.0,
      price_5d_h: 3.82,
      price_5d_L: 3.53,
      price_1m_h: 4.04,
      price_1m_l: 2.98,
      price_3m_h: 4.19,
      price_3m_l: 2.98,
      price_6m_h: 4.82,
      price_6m_l: 2.98,
      price_52w_h: 6.83,
      price_52w_l: 2.98,
      price_pc_1d: -3.217,
      price_pc_1w: 1.69,
      price_pc_1m: -6.959,
      price_pc_6m: -24.792,
      price_pc_1y: -9.524,
      price_pc_ytd: -9.524,
    },
    "0348": {
      id: "0348",
      c: 0.211,
      ma50: 0.228,
      ma100: 0.245,
      ma150: 0.249,
      ma200: 0.267,
      maw30: 0.25,
      rs: 28.0,
      rsd1d: 3.0,
      rsd5d: 11.0,
      rsd10d: 8.0,
      price_5d_h: 0.212,
      price_5d_L: 0.19,
      price_1m_h: 0.24,
      price_1m_l: 0.17,
      price_3m_h: 0.26,
      price_3m_l: 0.17,
      price_6m_h: 0.32,
      price_6m_l: 0.17,
      price_52w_h: 0.395,
      price_52w_l: 0.17,
      price_pc_1d: 0.476,
      price_pc_1w: 9.326,
      price_pc_1m: -12.083,
      price_pc_6m: -10.213,
      price_pc_1y: -29.667,
      price_pc_ytd: -29.667,
    },
    "0351": {
      id: "0351",
      c: 0.355,
      ma50: 0.327,
      ma100: 0.373,
      ma150: 0.401,
      ma200: 0.418,
      maw30: 0.401,
      rs: 38.0,
      rsd1d: 20.0,
      rsd5d: 21.0,
      rsd10d: 27.0,
      price_5d_h: 0.355,
      price_5d_L: 0.26,
      price_1m_h: 0.355,
      price_1m_l: 0.21,
      price_3m_h: 0.405,
      price_3m_l: 0.21,
      price_6m_h: 0.45,
      price_6m_l: 0.21,
      price_52w_h: 0.55,
      price_52w_l: 0.21,
      price_pc_1d: 18.333,
      price_pc_1w: 18.333,
      price_pc_1m: 9.231,
      price_pc_6m: -19.318,
      price_pc_1y: -27.551,
      price_pc_ytd: -27.551,
    },
    "0353": {
      id: "0353",
      c: 0.049,
      ma50: 0.052,
      ma100: 0.059,
      ma150: 0.063,
      ma200: 0.065,
      maw30: 0.062,
      rs: 28.0,
      rsd1d: -13.0,
      rsd5d: -4.0,
      rsd10d: 15.0,
      price_5d_h: 0.058,
      price_5d_L: 0.047,
      price_1m_h: 0.069,
      price_1m_l: 0.041,
      price_3m_h: 0.069,
      price_3m_l: 0.041,
      price_6m_h: 0.073,
      price_6m_l: 0.041,
      price_52w_h: 0.09,
      price_52w_l: 0.041,
      price_pc_1d: -10.909,
      price_pc_1w: -7.547,
      price_pc_1m: -9.259,
      price_pc_6m: -30.986,
      price_pc_1y: -28.986,
      price_pc_ytd: -28.986,
    },
    "0354": {
      id: "0354",
      c: 6.49,
      ma50: 7.297,
      ma100: 9.351,
      ma150: 10.605,
      ma200: 11.482,
      maw30: 10.626,
      rs: 13.0,
      rsd1d: -3.0,
      rsd5d: -3.0,
      rsd10d: -1.0,
      price_5d_h: 7.16,
      price_5d_L: 6.33,
      price_1m_h: 7.3,
      price_1m_l: 5.28,
      price_3m_h: 10.36,
      price_3m_l: 5.28,
      price_6m_h: 14.16,
      price_6m_l: 5.28,
      price_52w_h: 16.28,
      price_52w_l: 5.28,
      price_pc_1d: -7.153,
      price_pc_1w: -5.942,
      price_pc_1m: -8.592,
      price_pc_6m: -52.834,
      price_pc_1y: -23.013,
      price_pc_ytd: -23.013,
    },
    "0355": {
      id: "0355",
      c: 0.345,
      ma50: 0.361,
      ma100: 0.39,
      ma150: 0.408,
      ma200: 0.415,
      maw30: 0.408,
      rs: 39.0,
      rsd1d: 7.0,
      rsd5d: 8.0,
      rsd10d: 8.0,
      price_5d_h: 0.345,
      price_5d_L: 0.32,
      price_1m_h: 0.36,
      price_1m_l: 0.3,
      price_3m_h: 0.45,
      price_3m_l: 0.3,
      price_6m_h: 0.475,
      price_6m_l: 0.3,
      price_52w_h: 0.48,
      price_52w_l: 0.3,
      price_pc_1d: 2.985,
      price_pc_1w: 2.985,
      price_pc_1m: -2.817,
      price_pc_6m: -22.472,
      price_pc_1y: -22.472,
      price_pc_ytd: -22.472,
    },
    "0356": {
      id: "0356",
      c: 0.044,
      ma50: 0.039,
      ma100: 0.04,
      ma150: 0.04,
      ma200: 0.04,
      maw30: 0.04,
      rs: 81.0,
      rsd1d: 3.0,
      rsd5d: 12.0,
      rsd10d: 22.0,
      price_5d_h: 0.047,
      price_5d_L: 0.04,
      price_1m_h: 0.047,
      price_1m_l: 0.033,
      price_3m_h: 0.047,
      price_3m_l: 0.03,
      price_6m_h: 0.065,
      price_6m_l: 0.03,
      price_52w_h: 0.065,
      price_52w_l: 0.03,
      price_pc_1d: 0.0,
      price_pc_1w: 10.0,
      price_pc_1m: 10.0,
      price_pc_6m: 0.0,
      price_pc_1y: -2.222,
      price_pc_ytd: -2.222,
    },
    "0357": {
      id: "0357",
      c: 17.94,
      ma50: 20.687,
      ma100: 22.623,
      ma150: 25.124,
      ma200: 25.793,
      maw30: 25.421,
      rs: 15.0,
      rsd1d: 1.0,
      rsd5d: 2.0,
      rsd10d: -4.0,
      price_5d_h: 18.94,
      price_5d_L: 16.6,
      price_1m_h: 24.0,
      price_1m_l: 14.52,
      price_3m_h: 25.8,
      price_3m_l: 14.52,
      price_6m_h: 34.0,
      price_6m_l: 14.52,
      price_52w_h: 37.55,
      price_52w_l: 14.52,
      price_pc_1d: -3.548,
      price_pc_1w: 0.448,
      price_pc_1m: -18.639,
      price_pc_6m: -44.969,
      price_pc_1y: -47.001,
      price_pc_ytd: -47.001,
    },
    "0358": {
      id: "0358",
      c: 13.14,
      ma50: 13.601,
      ma100: 13.269,
      ma150: 13.804,
      ma200: 14.287,
      maw30: 13.811,
      rs: 65.0,
      rsd1d: -2.0,
      rsd5d: -3.0,
      rsd10d: 12.0,
      price_5d_h: 13.72,
      price_5d_L: 13.04,
      price_1m_h: 15.0,
      price_1m_l: 10.72,
      price_3m_h: 15.0,
      price_3m_l: 10.72,
      price_6m_h: 15.72,
      price_6m_l: 10.72,
      price_52w_h: 22.8,
      price_52w_l: 10.72,
      price_pc_1d: -1.94,
      price_pc_1w: -2.667,
      price_pc_1m: -6.41,
      price_pc_6m: -6.543,
      price_pc_1y: -14.23,
      price_pc_ytd: -14.23,
    },
    "0360": {
      id: "0360",
      c: 0.062,
      ma50: 0.06,
      ma100: 0.07,
      ma150: 0.08,
      ma200: 0.083,
      maw30: 0.079,
      rs: 47.0,
      rsd1d: 3.0,
      rsd5d: 1.0,
      rsd10d: 35.0,
      price_5d_h: 0.083,
      price_5d_L: 0.061,
      price_1m_h: 0.083,
      price_1m_l: 0.042,
      price_3m_h: 0.083,
      price_3m_l: 0.042,
      price_6m_h: 0.12,
      price_6m_l: 0.042,
      price_52w_h: 0.16,
      price_52w_l: 0.042,
      price_pc_1d: -1.587,
      price_pc_1w: -3.125,
      price_pc_1m: 6.897,
      price_pc_6m: -33.333,
      price_pc_1y: -6.061,
      price_pc_ytd: -6.061,
    },
    "0361": {
      id: "0361",
      c: 0.053,
      ma50: 0.063,
      ma100: 0.105,
      ma150: 0.137,
      ma200: 0.163,
      maw30: 0.136,
      rs: 5.0,
      rsd1d: 0.0,
      rsd5d: -2.0,
      rsd10d: 2.0,
      price_5d_h: 0.066,
      price_5d_L: 0.051,
      price_1m_h: 0.067,
      price_1m_l: 0.044,
      price_3m_h: 0.139,
      price_3m_l: 0.044,
      price_6m_h: 0.228,
      price_6m_l: 0.044,
      price_52w_h: 0.34,
      price_52w_l: 0.044,
      price_pc_1d: -3.636,
      price_pc_1w: -15.873,
      price_pc_1m: -11.667,
      price_pc_6m: -68.263,
      price_pc_1y: -23.188,
      price_pc_ytd: -23.188,
    },
    "0362": {
      id: "0362",
      c: 0.089,
      ma50: 0.099,
      ma100: 0.107,
      ma150: 0.143,
      ma200: 0.175,
      maw30: 0.14,
      rs: 10.0,
      rsd1d: 2.0,
      rsd5d: 2.0,
      rsd10d: -2.0,
      price_5d_h: 0.091,
      price_5d_L: 0.083,
      price_1m_h: 0.102,
      price_1m_l: 0.079,
      price_3m_h: 0.134,
      price_3m_l: 0.079,
      price_6m_h: 0.205,
      price_6m_l: 0.079,
      price_52w_h: 0.41,
      price_52w_l: 0.079,
      price_pc_1d: 1.136,
      price_pc_1w: 1.136,
      price_pc_1m: -11.0,
      price_pc_6m: -55.941,
      price_pc_1y: -73.03,
      price_pc_ytd: -73.03,
    },
    "0363": {
      id: "0363",
      c: 11.76,
      ma50: 11.505,
      ma100: 11.441,
      ma150: 11.621,
      ma200: 11.594,
      maw30: 11.65,
      rs: 78.0,
      rsd1d: 9.0,
      rsd5d: 7.0,
      rsd10d: 12.0,
      price_5d_h: 11.78,
      price_5d_L: 11.02,
      price_1m_h: 11.88,
      price_1m_l: 10.08,
      price_3m_h: 12.52,
      price_3m_l: 10.08,
      price_6m_h: 12.52,
      price_6m_l: 10.08,
      price_52w_h: 13.16,
      price_52w_l: 10.08,
      price_pc_1d: 4.626,
      price_pc_1w: 4.626,
      price_pc_1m: -1.01,
      price_pc_6m: 0.685,
      price_pc_1y: 2.618,
      price_pc_ytd: 2.618,
    },
    "0367": {
      id: "0367",
      c: 0.92,
      ma50: 0.888,
      ma100: 0.886,
      ma150: 0.882,
      ma200: 0.91,
      maw30: 0.884,
      rs: 67.0,
      rsd1d: 11.0,
      rsd5d: -3.0,
      rsd10d: 3.0,
      price_5d_h: 0.94,
      price_5d_L: 0.88,
      price_1m_h: 0.94,
      price_1m_l: 0.8,
      price_3m_h: 0.94,
      price_3m_l: 0.8,
      price_6m_h: 1.02,
      price_6m_l: 0.8,
      price_52w_h: 1.11,
      price_52w_l: 0.8,
      price_pc_1d: 4.545,
      price_pc_1w: -1.075,
      price_pc_1m: 5.747,
      price_pc_6m: 6.977,
      price_pc_1y: -13.208,
      price_pc_ytd: -13.208,
    },
    "0368": {
      id: "0368",
      c: 0.43,
      ma50: 0.44,
      ma100: 0.466,
      ma150: 0.484,
      ma200: 0.506,
      maw30: 0.484,
      rs: 34.0,
      rsd1d: 4.0,
      rsd5d: 6.0,
      rsd10d: 3.0,
      price_5d_h: 0.43,
      price_5d_L: 0.425,
      price_1m_h: 0.435,
      price_1m_l: 0.405,
      price_3m_h: 0.495,
      price_3m_l: 0.405,
      price_6m_h: 0.55,
      price_6m_l: 0.405,
      price_52w_h: 0.7,
      price_52w_l: 0.405,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: -3.371,
      price_pc_6m: -15.686,
      price_pc_1y: -34.848,
      price_pc_ytd: -34.848,
    },
    "0369": {
      id: "0369",
      c: 4.2,
      ma50: 4.258,
      ma100: 4.28,
      ma150: 4.282,
      ma200: 4.348,
      maw30: 4.296,
      rs: 69.0,
      rsd1d: -3.0,
      rsd5d: 2.0,
      rsd10d: -5.0,
      price_5d_h: 4.25,
      price_5d_L: 4.1,
      price_1m_h: 4.25,
      price_1m_l: 3.85,
      price_3m_h: 4.45,
      price_3m_l: 3.85,
      price_6m_h: 4.5,
      price_6m_l: 3.85,
      price_52w_h: 4.89,
      price_52w_l: 3.85,
      price_pc_1d: -1.176,
      price_pc_1w: 0.0,
      price_pc_1m: 0.719,
      price_pc_6m: 0.0,
      price_pc_1y: -2.778,
      price_pc_ytd: -2.778,
    },
    "0370": {
      id: "0370",
      c: 0.81,
      ma50: 0.69,
      ma100: 0.571,
      ma150: 0.504,
      ma200: 0.459,
      maw30: 0.503,
      rs: 98.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 1.0,
      price_5d_h: 0.81,
      price_5d_L: 0.76,
      price_1m_h: 0.83,
      price_1m_l: 0.63,
      price_3m_h: 0.83,
      price_3m_l: 0.465,
      price_6m_h: 0.83,
      price_6m_l: 0.345,
      price_52w_h: 0.83,
      price_52w_l: 0.19,
      price_pc_1d: 0.0,
      price_pc_1w: 1.25,
      price_pc_1m: -1.22,
      price_pc_6m: 131.429,
      price_pc_1y: 154.553,
      price_pc_ytd: 154.553,
    },
    "0371": {
      id: "0371",
      c: 2.41,
      ma50: 2.974,
      ma100: 2.966,
      ma150: 3.026,
      ma200: 3.007,
      maw30: 3.029,
      rs: 36.0,
      rsd1d: -19.0,
      rsd5d: -25.0,
      rsd10d: -28.0,
      price_5d_h: 2.83,
      price_5d_L: 2.39,
      price_1m_h: 3.16,
      price_1m_l: 2.39,
      price_3m_h: 3.22,
      price_3m_l: 2.39,
      price_6m_h: 3.6,
      price_6m_l: 2.39,
      price_52w_h: 3.6,
      price_52w_l: 2.39,
      price_pc_1d: -12.044,
      price_pc_1w: -14.539,
      price_pc_1m: -21.753,
      price_pc_6m: -21.242,
      price_pc_1y: -18.305,
      price_pc_ytd: -18.305,
    },
    "0372": {
      id: "0372",
      c: 0.215,
      ma50: 0.227,
      ma100: 0.255,
      ma150: 0.269,
      ma200: 0.282,
      maw30: 0.27,
      rs: 21.0,
      rsd1d: 2.0,
      rsd5d: 0.0,
      rsd10d: -3.0,
      price_5d_h: 0.219,
      price_5d_L: 0.195,
      price_1m_h: 0.219,
      price_1m_l: 0.182,
      price_3m_h: 0.375,
      price_3m_l: 0.182,
      price_6m_h: 0.375,
      price_6m_l: 0.182,
      price_52w_h: 0.43,
      price_52w_l: 0.182,
      price_pc_1d: 0.0,
      price_pc_1w: 4.878,
      price_pc_1m: 3.365,
      price_pc_6m: -31.746,
      price_pc_1y: -45.57,
      price_pc_ytd: -45.57,
    },
    "0373": {
      id: "0373",
      c: 2.77,
      ma50: 2.764,
      ma100: 2.752,
      ma150: 2.805,
      ma200: 2.885,
      maw30: 2.812,
      rs: 66.0,
      rsd1d: 6.0,
      rsd5d: 4.0,
      rsd10d: 6.0,
      price_5d_h: 2.8,
      price_5d_L: 2.69,
      price_1m_h: 2.85,
      price_1m_l: 2.46,
      price_3m_h: 2.97,
      price_3m_l: 2.46,
      price_6m_h: 3.03,
      price_6m_l: 2.46,
      price_52w_h: 3.46,
      price_52w_l: 2.46,
      price_pc_1d: 1.465,
      price_pc_1w: 0.362,
      price_pc_1m: 0.727,
      price_pc_6m: -1.071,
      price_pc_1y: -15.291,
      price_pc_ytd: -15.291,
    },
    "0376": {
      id: "0376",
      c: 1.44,
      ma50: 1.646,
      ma100: 1.637,
      ma150: 1.808,
      ma200: 2.0,
      maw30: 1.796,
      rs: 29.0,
      rsd1d: -2.0,
      rsd5d: -14.0,
      rsd10d: 3.0,
      price_5d_h: 1.6,
      price_5d_L: 1.41,
      price_1m_h: 1.7,
      price_1m_l: 1.28,
      price_3m_h: 2.0,
      price_3m_l: 1.27,
      price_6m_h: 2.28,
      price_6m_l: 1.27,
      price_52w_h: 3.52,
      price_52w_l: 1.27,
      price_pc_1d: -4.636,
      price_pc_1w: -9.434,
      price_pc_1m: -14.286,
      price_pc_6m: -33.945,
      price_pc_1y: -51.02,
      price_pc_ytd: -51.02,
    },
    "0379": {
      id: "0379",
      c: 0.109,
      ma50: 0.139,
      ma100: 0.135,
      ma150: 0.129,
      ma200: 0.133,
      maw30: 0.13,
      rs: 35.0,
      rsd1d: 2.0,
      rsd5d: -38.0,
      rsd10d: 3.0,
      price_5d_h: 0.121,
      price_5d_L: 0.105,
      price_1m_h: 0.155,
      price_1m_l: 0.095,
      price_3m_h: 0.16,
      price_3m_l: 0.095,
      price_6m_h: 0.18,
      price_6m_l: 0.094,
      price_52w_h: 0.198,
      price_52w_l: 0.092,
      price_pc_1d: -0.909,
      price_pc_1w: -19.259,
      price_pc_1m: -31.013,
      price_pc_6m: 6.863,
      price_pc_1y: -21.014,
      price_pc_ytd: -21.014,
    },
    "0381": {
      id: "0381",
      c: 0.049,
      ma50: 0.055,
      ma100: 0.056,
      ma150: 0.058,
      ma200: 0.059,
      maw30: 0.058,
      rs: 27.0,
      rsd1d: 5.0,
      rsd5d: -12.0,
      rsd10d: 8.0,
      price_5d_h: 0.053,
      price_5d_L: 0.045,
      price_1m_h: 0.058,
      price_1m_l: 0.044,
      price_3m_h: 0.07,
      price_3m_l: 0.044,
      price_6m_h: 0.079,
      price_6m_l: 0.044,
      price_52w_h: 0.142,
      price_52w_l: 0.041,
      price_pc_1d: 2.083,
      price_pc_1w: -9.259,
      price_pc_1m: -14.035,
      price_pc_6m: -26.866,
      price_pc_1y: -51.485,
      price_pc_ytd: -51.485,
    },
    "0382": {
      id: "0382",
      c: 2.84,
      ma50: 3.268,
      ma100: 4.051,
      ma150: 4.401,
      ma200: 4.934,
      maw30: 4.39,
      rs: 8.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: 3.0,
      price_5d_h: 2.95,
      price_5d_L: 2.64,
      price_1m_h: 3.33,
      price_1m_l: 1.99,
      price_3m_h: 4.78,
      price_3m_l: 1.99,
      price_6m_h: 5.9,
      price_6m_l: 1.99,
      price_52w_h: 8.9,
      price_52w_l: 1.99,
      price_pc_1d: -0.351,
      price_pc_1w: -2.069,
      price_pc_1m: -11.526,
      price_pc_6m: -40.084,
      price_pc_1y: -62.973,
      price_pc_ytd: -62.973,
    },
    "0383": {
      id: "0383",
      c: 0.082,
      ma50: 0.075,
      ma100: 0.077,
      ma150: 0.085,
      ma200: 0.092,
      maw30: 0.084,
      rs: 62.0,
      rsd1d: 12.0,
      rsd5d: 20.0,
      rsd10d: 25.0,
      price_5d_h: 0.083,
      price_5d_L: 0.067,
      price_1m_h: 0.083,
      price_1m_l: 0.067,
      price_3m_h: 0.09,
      price_3m_l: 0.057,
      price_6m_h: 0.105,
      price_6m_l: 0.057,
      price_52w_h: 0.127,
      price_52w_l: 0.057,
      price_pc_1d: 5.128,
      price_pc_1w: 6.494,
      price_pc_1m: 2.5,
      price_pc_6m: -19.608,
      price_pc_1y: -25.455,
      price_pc_ytd: -25.455,
    },
    "0384": {
      id: "0384",
      c: 10.04,
      ma50: 12.257,
      ma100: 14.198,
      ma150: 16.522,
      ma200: 18.448,
      maw30: 16.517,
      rs: 7.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: -1.0,
      price_5d_h: 10.38,
      price_5d_L: 9.61,
      price_1m_h: 12.8,
      price_1m_l: 8.9,
      price_3m_h: 16.6,
      price_3m_l: 8.9,
      price_6m_h: 23.05,
      price_6m_l: 8.9,
      price_52w_h: 35.0,
      price_52w_l: 8.9,
      price_pc_1d: -2.144,
      price_pc_1w: -3.276,
      price_pc_1m: -18.77,
      price_pc_6m: -56.348,
      price_pc_1y: -68.228,
      price_pc_ytd: -68.228,
    },
    "0386": {
      id: "0386",
      c: 3.93,
      ma50: 3.91,
      ma100: 3.817,
      ma150: 3.832,
      ma200: 3.812,
      maw30: 3.844,
      rs: 78.0,
      rsd1d: 0.0,
      rsd5d: 12.0,
      rsd10d: 17.0,
      price_5d_h: 4.0,
      price_5d_L: 3.69,
      price_1m_h: 4.0,
      price_1m_l: 3.25,
      price_3m_h: 4.28,
      price_3m_l: 3.25,
      price_6m_h: 4.28,
      price_6m_l: 3.25,
      price_52w_h: 4.33,
      price_52w_l: 3.25,
      price_pc_1d: 0.512,
      price_pc_1w: 5.08,
      price_pc_1m: 1.813,
      price_pc_6m: 1.813,
      price_pc_1y: -5.755,
      price_pc_ytd: -5.755,
    },
    "0388": {
      id: "0388",
      c: 371.4,
      ma50: 404.064,
      ma100: 429.868,
      ma150: 447.211,
      ma200: 458.204,
      maw30: 448.06,
      rs: 36.0,
      rsd1d: 1.0,
      rsd5d: -5.0,
      rsd10d: -6.0,
      price_5d_h: 385.0,
      price_5d_L: 367.0,
      price_1m_h: 394.0,
      price_1m_l: 314.0,
      price_3m_h: 483.6,
      price_3m_l: 314.0,
      price_6m_h: 495.0,
      price_6m_l: 314.0,
      price_52w_h: 544.0,
      price_52w_l: 314.0,
      price_pc_1d: -1.276,
      price_pc_1w: -3.281,
      price_pc_1m: -1.642,
      price_pc_6m: -22.593,
      price_pc_1y: -20.743,
      price_pc_ytd: -20.743,
    },
    "0389": {
      id: "0389",
      c: 0.098,
      ma50: 0.089,
      ma100: 0.093,
      ma150: 0.095,
      ma200: 0.094,
      maw30: 0.096,
      rs: 86.0,
      rsd1d: -1.0,
      rsd5d: -2.0,
      rsd10d: 0.0,
      price_5d_h: 0.104,
      price_5d_L: 0.091,
      price_1m_h: 0.104,
      price_1m_l: 0.078,
      price_3m_h: 0.105,
      price_3m_l: 0.076,
      price_6m_h: 0.129,
      price_6m_l: 0.076,
      price_52w_h: 0.129,
      price_52w_l: 0.058,
      price_pc_1d: -2.0,
      price_pc_1w: 0.0,
      price_pc_1m: 22.5,
      price_pc_6m: -6.667,
      price_pc_1y: 58.065,
      price_pc_ytd: 58.065,
    },
    "0390": {
      id: "0390",
      c: 4.39,
      ma50: 4.671,
      ma100: 4.335,
      ma150: 4.229,
      ma200: 4.126,
      maw30: 4.259,
      rs: 84.0,
      rsd1d: -3.0,
      rsd5d: -2.0,
      rsd10d: -3.0,
      price_5d_h: 4.53,
      price_5d_L: 4.31,
      price_1m_h: 4.69,
      price_1m_l: 3.97,
      price_3m_h: 5.2,
      price_3m_l: 3.97,
      price_6m_h: 5.2,
      price_6m_l: 3.61,
      price_52w_h: 5.2,
      price_52w_l: 3.53,
      price_pc_1d: -2.227,
      price_pc_1w: -2.661,
      price_pc_1m: -4.357,
      price_pc_6m: 13.144,
      price_pc_1y: 4.524,
      price_pc_ytd: 4.524,
    },
    "0391": {
      id: "0391",
      c: 0.092,
      ma50: 0.096,
      ma100: 0.096,
      ma150: 0.096,
      ma200: 0.096,
      maw30: 0.096,
      rs: 61.0,
      rsd1d: -15.0,
      rsd5d: -2.0,
      rsd10d: -11.0,
      price_5d_h: 0.1,
      price_5d_L: 0.091,
      price_1m_h: 0.1,
      price_1m_l: 0.09,
      price_3m_h: 0.101,
      price_3m_l: 0.09,
      price_6m_h: 0.103,
      price_6m_l: 0.09,
      price_52w_h: 0.111,
      price_52w_l: 0.089,
      price_pc_1d: -7.071,
      price_pc_1w: 1.099,
      price_pc_1m: -3.158,
      price_pc_6m: -6.122,
      price_pc_1y: -5.155,
      price_pc_ytd: -5.155,
    },
    "0392": {
      id: "0392",
      c: 24.85,
      ma50: 26.576,
      ma100: 26.938,
      ma150: 27.806,
      ma200: 27.321,
      maw30: 28.047,
      rs: 53.0,
      rsd1d: -4.0,
      rsd5d: -2.0,
      rsd10d: 0.0,
      price_5d_h: 26.0,
      price_5d_L: 24.55,
      price_1m_h: 27.75,
      price_1m_l: 21.3,
      price_3m_h: 29.5,
      price_3m_l: 21.3,
      price_6m_h: 32.9,
      price_6m_l: 21.3,
      price_52w_h: 32.9,
      price_52w_l: 21.3,
      price_pc_1d: -2.93,
      price_pc_1w: -0.996,
      price_pc_1m: -6.754,
      price_pc_6m: -20.225,
      price_pc_1y: -4.423,
      price_pc_ytd: -4.423,
    },
    "0396": {
      id: "0396",
      c: 0.109,
      ma50: 0.128,
      ma100: 0.162,
      ma150: 0.159,
      ma200: 0.153,
      maw30: 0.165,
      rs: 28.0,
      rsd1d: -3.0,
      rsd5d: 2.0,
      rsd10d: 4.0,
      price_5d_h: 0.115,
      price_5d_L: 0.109,
      price_1m_h: 0.149,
      price_1m_l: 0.1,
      price_3m_h: 0.165,
      price_3m_l: 0.1,
      price_6m_h: 0.395,
      price_6m_l: 0.1,
      price_52w_h: 0.395,
      price_52w_l: 0.1,
      price_pc_1d: -5.217,
      price_pc_1w: -5.217,
      price_pc_1m: -21.583,
      price_pc_6m: -27.333,
      price_pc_1y: -9.167,
      price_pc_ytd: -9.167,
    },
    "0397": {
      id: "0397",
      c: 0.144,
      ma50: 0.131,
      ma100: 0.149,
      ma150: 0.149,
      ma200: 0.137,
      maw30: 0.151,
      rs: 86.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: 7.0,
      price_5d_h: 0.155,
      price_5d_L: 0.131,
      price_1m_h: 0.155,
      price_1m_l: 0.112,
      price_3m_h: 0.184,
      price_3m_l: 0.038,
      price_6m_h: 0.205,
      price_6m_l: 0.038,
      price_52w_h: 0.219,
      price_52w_l: 0.038,
      price_pc_1d: 2.857,
      price_pc_1w: 5.882,
      price_pc_1m: 18.033,
      price_pc_6m: -28.0,
      price_pc_1y: 56.522,
      price_pc_ytd: 56.522,
    },
    "0398": {
      id: "0398",
      c: 4.17,
      ma50: 4.283,
      ma100: 4.543,
      ma150: 4.313,
      ma200: 4.299,
      maw30: 4.333,
      rs: 85.0,
      rsd1d: 1.0,
      rsd5d: -1.0,
      rsd10d: 5.0,
      price_5d_h: 4.28,
      price_5d_L: 4.01,
      price_1m_h: 4.41,
      price_1m_l: 3.71,
      price_3m_h: 4.95,
      price_3m_l: 3.71,
      price_6m_h: 5.42,
      price_6m_l: 3.13,
      price_52w_h: 5.42,
      price_52w_l: 2.66,
      price_pc_1d: 2.457,
      price_pc_1w: -0.95,
      price_pc_1m: -2.57,
      price_pc_6m: 27.914,
      price_pc_1y: 55.019,
      price_pc_ytd: 55.019,
    },
    "0399": {
      id: "0399",
      c: 0.149,
      ma50: 0.145,
      ma100: 0.162,
      ma150: 0.175,
      ma200: 0.191,
      maw30: 0.175,
      rs: 26.0,
      rsd1d: 6.0,
      rsd5d: 14.0,
      rsd10d: 9.0,
      price_5d_h: 0.15,
      price_5d_L: 0.129,
      price_1m_h: 0.15,
      price_1m_l: 0.12,
      price_3m_h: 0.188,
      price_3m_l: 0.112,
      price_6m_h: 0.22,
      price_6m_l: 0.112,
      price_52w_h: 0.28,
      price_52w_l: 0.112,
      price_pc_1d: 2.759,
      price_pc_1w: 15.504,
      price_pc_1m: 13.74,
      price_pc_6m: -25.871,
      price_pc_1y: -40.161,
      price_pc_ytd: -40.161,
    },
    "0400": {
      id: "0400",
      c: 2.0,
      ma50: 2.238,
      ma100: 2.572,
      ma150: 2.631,
      ma200: 2.616,
      maw30: 2.64,
      rs: 31.0,
      rsd1d: -3.0,
      rsd5d: -5.0,
      rsd10d: 5.0,
      price_5d_h: 2.16,
      price_5d_L: 1.97,
      price_1m_h: 2.36,
      price_1m_l: 1.6,
      price_3m_h: 2.72,
      price_3m_l: 1.6,
      price_6m_h: 3.93,
      price_6m_l: 1.6,
      price_52w_h: 3.93,
      price_52w_l: 1.6,
      price_pc_1d: -3.382,
      price_pc_1w: -6.103,
      price_pc_1m: -14.894,
      price_pc_6m: -20.635,
      price_pc_1y: -6.103,
      price_pc_ytd: -6.103,
    },
    "0403": {
      id: "0403",
      c: 0.295,
      ma50: 0.308,
      ma100: 0.343,
      ma150: 0.352,
      ma200: 0.346,
      maw30: 0.354,
      rs: 54.0,
      rsd1d: 7.0,
      rsd5d: 9.0,
      rsd10d: -7.0,
      price_5d_h: 0.315,
      price_5d_L: 0.285,
      price_1m_h: 0.315,
      price_1m_l: 0.27,
      price_3m_h: 0.35,
      price_3m_l: 0.27,
      price_6m_h: 0.49,
      price_6m_l: 0.27,
      price_52w_h: 0.49,
      price_52w_l: 0.27,
      price_pc_1d: 3.509,
      price_pc_1w: 0.0,
      price_pc_1m: -4.839,
      price_pc_6m: -19.178,
      price_pc_1y: 1.724,
      price_pc_ytd: 1.724,
    },
    "0405": {
      id: "0405",
      c: 3.37,
      ma50: 3.286,
      ma100: 3.289,
      ma150: 3.428,
      ma200: 3.573,
      maw30: 3.418,
      rs: 64.0,
      rsd1d: 2.0,
      rsd5d: 1.0,
      rsd10d: 3.0,
      price_5d_h: 3.43,
      price_5d_L: 3.33,
      price_1m_h: 3.43,
      price_1m_l: 3.07,
      price_3m_h: 3.43,
      price_3m_l: 3.07,
      price_6m_h: 3.73,
      price_6m_l: 3.07,
      price_52w_h: 4.2,
      price_52w_l: 3.07,
      price_pc_1d: -0.296,
      price_pc_1w: -0.59,
      price_pc_1m: 5.975,
      price_pc_6m: -7.923,
      price_pc_1y: -15.539,
      price_pc_ytd: -15.539,
    },
    "0408": {
      id: "0408",
      c: 4.03,
      ma50: 4.114,
      ma100: 4.202,
      ma150: 4.364,
      ma200: 4.47,
      maw30: 4.367,
      rs: 63.0,
      rsd1d: 4.0,
      rsd5d: -3.0,
      rsd10d: 13.0,
      price_5d_h: 4.1,
      price_5d_L: 3.88,
      price_1m_h: 4.18,
      price_1m_l: 3.41,
      price_3m_h: 4.57,
      price_3m_l: 3.41,
      price_6m_h: 4.8,
      price_6m_l: 3.41,
      price_52w_h: 5.76,
      price_52w_l: 3.41,
      price_pc_1d: 0.75,
      price_pc_1w: -2.892,
      price_pc_1m: -0.494,
      price_pc_6m: -16.39,
      price_pc_1y: 6.332,
      price_pc_ytd: 6.332,
    },
    "0410": {
      id: "0410",
      c: 1.52,
      ma50: 1.626,
      ma100: 1.697,
      ma150: 1.912,
      ma200: 2.392,
      maw30: 1.902,
      rs: 22.0,
      rsd1d: 3.0,
      rsd5d: -1.0,
      rsd10d: -1.0,
      price_5d_h: 1.56,
      price_5d_L: 1.42,
      price_1m_h: 1.76,
      price_1m_l: 1.28,
      price_3m_h: 1.82,
      price_3m_l: 1.28,
      price_6m_h: 2.15,
      price_6m_l: 1.28,
      price_52w_h: 4.78,
      price_52w_l: 1.28,
      price_pc_1d: 1.333,
      price_pc_1w: -3.185,
      price_pc_1m: -4.403,
      price_pc_6m: -26.57,
      price_pc_1y: -36.929,
      price_pc_ytd: -36.929,
    },
    "0411": {
      id: "0411",
      c: 14.0,
      ma50: 14.644,
      ma100: 15.003,
      ma150: 15.065,
      ma200: 15.054,
      maw30: 15.113,
      rs: 61.0,
      rsd1d: 5.0,
      rsd5d: 4.0,
      rsd10d: 1.0,
      price_5d_h: 14.2,
      price_5d_L: 14.0,
      price_1m_h: 14.5,
      price_1m_l: 12.8,
      price_3m_h: 15.4,
      price_3m_l: 12.8,
      price_6m_h: 16.2,
      price_6m_l: 12.8,
      price_52w_h: 16.54,
      price_52w_l: 12.8,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: -0.709,
      price_pc_6m: -6.667,
      price_pc_1y: -13.043,
      price_pc_ytd: -13.043,
    },
    "0412": {
      id: "0412",
      c: 0.84,
      ma50: 0.789,
      ma100: 0.76,
      ma150: 0.682,
      ma200: 0.626,
      maw30: 0.683,
      rs: 94.0,
      rsd1d: -1.0,
      rsd5d: 1.0,
      rsd10d: -1.0,
      price_5d_h: 0.88,
      price_5d_L: 0.77,
      price_1m_h: 0.88,
      price_1m_l: 0.72,
      price_3m_h: 0.9,
      price_3m_l: 0.65,
      price_6m_h: 0.93,
      price_6m_l: 0.49,
      price_52w_h: 0.93,
      price_52w_l: 0.39,
      price_pc_1d: 0.0,
      price_pc_1w: 5.0,
      price_pc_1m: 6.329,
      price_pc_6m: 68.0,
      price_pc_1y: 69.697,
      price_pc_ytd: 69.697,
    },
    "0413": {
      id: "0413",
      c: 0.073,
      ma50: 0.091,
      ma100: 0.088,
      ma150: 0.09,
      ma200: 0.093,
      maw30: 0.09,
      rs: 31.0,
      rsd1d: -2.0,
      rsd5d: 8.0,
      rsd10d: -47.0,
      price_5d_h: 0.076,
      price_5d_L: 0.067,
      price_1m_h: 0.108,
      price_1m_l: 0.067,
      price_3m_h: 0.11,
      price_3m_l: 0.067,
      price_6m_h: 0.11,
      price_6m_l: 0.067,
      price_52w_h: 0.127,
      price_52w_l: 0.067,
      price_pc_1d: -3.947,
      price_pc_1w: 4.286,
      price_pc_1m: -18.889,
      price_pc_6m: -25.51,
      price_pc_1y: -37.607,
      price_pc_ytd: -37.607,
    },
    "0416": {
      id: "0416",
      c: 1.23,
      ma50: 1.471,
      ma100: 1.62,
      ma150: 1.689,
      ma200: 1.719,
      maw30: 1.687,
      rs: 20.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: -6.0,
      price_5d_h: 1.3,
      price_5d_L: 1.0,
      price_1m_h: 1.5,
      price_1m_l: 1.0,
      price_3m_h: 1.7,
      price_3m_l: 1.0,
      price_6m_h: 1.85,
      price_6m_l: 1.0,
      price_52w_h: 2.1,
      price_52w_l: 1.0,
      price_pc_1d: -2.381,
      price_pc_1w: -5.385,
      price_pc_1m: -18.0,
      price_pc_6m: -33.514,
      price_pc_1y: -38.5,
      price_pc_ytd: -38.5,
    },
    "0418": {
      id: "0418",
      c: 0.67,
      ma50: 0.729,
      ma100: 0.797,
      ma150: 0.798,
      ma200: 0.76,
      maw30: 0.81,
      rs: 75.0,
      rsd1d: -3.0,
      rsd5d: -5.0,
      rsd10d: 0.0,
      price_5d_h: 0.71,
      price_5d_L: 0.66,
      price_1m_h: 0.74,
      price_1m_l: 0.59,
      price_3m_h: 0.91,
      price_3m_l: 0.59,
      price_6m_h: 1.1,
      price_6m_l: 0.59,
      price_52w_h: 1.1,
      price_52w_l: 0.415,
      price_pc_1d: 0.0,
      price_pc_1w: 1.515,
      price_pc_1m: -6.944,
      price_pc_6m: -12.987,
      price_pc_1y: 57.647,
      price_pc_ytd: 57.647,
    },
    "0419": {
      id: "0419",
      c: 0.167,
      ma50: 0.177,
      ma100: 0.191,
      ma150: 0.192,
      ma200: 0.218,
      maw30: 0.192,
      rs: 17.0,
      rsd1d: 4.0,
      rsd5d: 2.0,
      rsd10d: -2.0,
      price_5d_h: 0.171,
      price_5d_L: 0.159,
      price_1m_h: 0.198,
      price_1m_l: 0.139,
      price_3m_h: 0.218,
      price_3m_l: 0.139,
      price_6m_h: 0.285,
      price_6m_l: 0.139,
      price_52w_h: 0.56,
      price_52w_l: 0.139,
      price_pc_1d: 2.454,
      price_pc_1w: 0.0,
      price_pc_1m: -8.242,
      price_pc_6m: -9.73,
      price_pc_1y: -68.491,
      price_pc_ytd: -68.491,
    },
    "0420": {
      id: "0420",
      c: 1.39,
      ma50: 1.371,
      ma100: 1.358,
      ma150: 1.359,
      ma200: 1.35,
      maw30: 1.365,
      rs: 83.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: -2.0,
      price_5d_h: 1.4,
      price_5d_L: 1.37,
      price_1m_h: 1.44,
      price_1m_l: 1.25,
      price_3m_h: 1.45,
      price_3m_l: 1.25,
      price_6m_h: 1.45,
      price_6m_l: 1.25,
      price_52w_h: 1.48,
      price_52w_l: 1.06,
      price_pc_1d: 0.725,
      price_pc_1w: -0.714,
      price_pc_1m: -0.714,
      price_pc_6m: 2.963,
      price_pc_1y: 29.907,
      price_pc_ytd: 29.907,
    },
    "0425": {
      id: "0425",
      c: 19.32,
      ma50: 28.686,
      ma100: 31.772,
      ma150: 31.11,
      ma200: 31.801,
      maw30: 31.416,
      rs: 13.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: -5.0,
      price_5d_h: 20.3,
      price_5d_L: 18.22,
      price_1m_h: 28.8,
      price_1m_l: 17.0,
      price_3m_h: 38.0,
      price_3m_l: 17.0,
      price_6m_h: 38.55,
      price_6m_l: 17.0,
      price_52w_h: 38.55,
      price_52w_l: 17.0,
      price_pc_1d: -4.356,
      price_pc_1w: 0.312,
      price_pc_1m: -31.246,
      price_pc_6m: -27.368,
      price_pc_1y: -40.37,
      price_pc_ytd: -40.37,
    },
    "0431": {
      id: "0431",
      c: 0.18,
      ma50: 0.194,
      ma100: 0.196,
      ma150: 0.195,
      ma200: 0.197,
      maw30: 0.195,
      rs: 53.0,
      rsd1d: 4.0,
      rsd5d: 3.0,
      rsd10d: -5.0,
      price_5d_h: 0.18,
      price_5d_L: 0.177,
      price_1m_h: 0.204,
      price_1m_l: 0.17,
      price_3m_h: 0.21,
      price_3m_l: 0.17,
      price_6m_h: 0.21,
      price_6m_l: 0.17,
      price_52w_h: 0.225,
      price_52w_l: 0.17,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: -10.0,
      price_pc_6m: -7.216,
      price_pc_1y: -16.279,
      price_pc_ytd: -16.279,
    },
    "0432": {
      id: "0432",
      c: 0.495,
      ma50: 0.547,
      ma100: 0.573,
      ma150: 0.592,
      ma200: 0.617,
      maw30: 0.594,
      rs: 29.0,
      rsd1d: -2.0,
      rsd5d: -1.0,
      rsd10d: 5.0,
      price_5d_h: 0.51,
      price_5d_L: 0.485,
      price_1m_h: 0.58,
      price_1m_l: 0.42,
      price_3m_h: 0.62,
      price_3m_l: 0.42,
      price_6m_h: 0.68,
      price_6m_l: 0.42,
      price_52w_h: 0.78,
      price_52w_l: 0.42,
      price_pc_1d: -2.941,
      price_pc_1w: -2.941,
      price_pc_1m: -13.158,
      price_pc_6m: -17.5,
      price_pc_1y: -34.868,
      price_pc_ytd: -34.868,
    },
    "0434": {
      id: "0434",
      c: 0.47,
      ma50: 0.47,
      ma100: 0.491,
      ma150: 0.493,
      ma200: 0.497,
      maw30: 0.495,
      rs: 56.0,
      rsd1d: 2.0,
      rsd5d: 24.0,
      rsd10d: 25.0,
      price_5d_h: 0.54,
      price_5d_L: 0.455,
      price_1m_h: 0.54,
      price_1m_l: 0.38,
      price_3m_h: 0.54,
      price_3m_l: 0.38,
      price_6m_h: 0.6,
      price_6m_l: 0.38,
      price_52w_h: 0.72,
      price_52w_l: 0.38,
      price_pc_1d: -2.083,
      price_pc_1w: 10.588,
      price_pc_1m: -3.093,
      price_pc_6m: -3.093,
      price_pc_1y: -18.966,
      price_pc_ytd: -18.966,
    },
    "0435": {
      id: "0435",
      c: 3.86,
      ma50: 4.102,
      ma100: 4.23,
      ma150: 4.363,
      ma200: 4.462,
      maw30: 4.365,
      rs: 53.0,
      rsd1d: 2.0,
      rsd5d: -1.0,
      rsd10d: -6.0,
      price_5d_h: 3.91,
      price_5d_L: 3.82,
      price_1m_h: 4.1,
      price_1m_l: 3.68,
      price_3m_h: 4.41,
      price_3m_l: 3.68,
      price_6m_h: 4.68,
      price_6m_l: 3.68,
      price_52w_h: 5.07,
      price_52w_l: 3.68,
      price_pc_1d: -0.258,
      price_pc_1w: -0.258,
      price_pc_1m: -4.455,
      price_pc_6m: -14.031,
      price_pc_1y: -7.434,
      price_pc_ytd: -7.434,
    },
    "0436": {
      id: "0436",
      c: 0.275,
      ma50: 0.297,
      ma100: 0.309,
      ma150: 0.302,
      ma200: 0.291,
      maw30: 0.305,
      rs: 60.0,
      rsd1d: 0.0,
      rsd5d: -12.0,
      rsd10d: -19.0,
      price_5d_h: 0.285,
      price_5d_L: 0.275,
      price_1m_h: 0.3,
      price_1m_l: 0.27,
      price_3m_h: 0.315,
      price_3m_l: 0.27,
      price_6m_h: 0.345,
      price_6m_l: 0.27,
      price_52w_h: 0.345,
      price_52w_l: 0.243,
      price_pc_1d: 0.0,
      price_pc_1w: -5.172,
      price_pc_1m: -6.78,
      price_pc_6m: -12.698,
      price_pc_1y: -11.29,
      price_pc_ytd: -11.29,
    },
    "0438": {
      id: "0438",
      c: 11.6,
      ma50: 16.104,
      ma100: 18.586,
      ma150: 21.37,
      ma200: 23.049,
      maw30: 21.26,
      rs: 7.0,
      rsd1d: 0.0,
      rsd5d: -4.0,
      rsd10d: -3.0,
      price_5d_h: 14.1,
      price_5d_L: 11.32,
      price_1m_h: 16.96,
      price_1m_l: 10.96,
      price_3m_h: 24.2,
      price_3m_l: 10.96,
      price_6m_h: 27.5,
      price_6m_l: 10.96,
      price_52w_h: 37.0,
      price_52w_l: 10.96,
      price_pc_1d: -3.494,
      price_pc_1w: -18.425,
      price_pc_1m: -29.009,
      price_pc_6m: -54.15,
      price_pc_1y: -50.743,
      price_pc_ytd: -50.743,
    },
    "0439": {
      id: "0439",
      c: 0.146,
      ma50: 0.179,
      ma100: 0.192,
      ma150: 0.208,
      ma200: 0.22,
      maw30: 0.207,
      rs: 17.0,
      rsd1d: 1.0,
      rsd5d: -1.0,
      rsd10d: -6.0,
      price_5d_h: 0.153,
      price_5d_L: 0.14,
      price_1m_h: 0.2,
      price_1m_l: 0.13,
      price_3m_h: 0.225,
      price_3m_l: 0.13,
      price_6m_h: 0.246,
      price_6m_l: 0.13,
      price_52w_h: 0.315,
      price_52w_l: 0.13,
      price_pc_1d: -0.68,
      price_pc_1w: -5.195,
      price_pc_1m: -25.51,
      price_pc_6m: -37.069,
      price_pc_1y: -44.906,
      price_pc_ytd: -44.906,
    },
    "0440": {
      id: "0440",
      c: 25.5,
      ma50: 25.5,
      ma100: 24.799,
      ma150: 24.656,
      ma200: 24.792,
      maw30: 24.732,
      rs: 80.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: -2.0,
      price_5d_h: 25.7,
      price_5d_L: 24.7,
      price_1m_h: 25.7,
      price_1m_l: 22.4,
      price_3m_h: 28.15,
      price_3m_l: 22.4,
      price_6m_h: 28.15,
      price_6m_l: 22.4,
      price_52w_h: 33.5,
      price_52w_l: 22.4,
      price_pc_1d: 0.196,
      price_pc_1w: 1.392,
      price_pc_1m: 0.99,
      price_pc_6m: 9.677,
      price_pc_1y: -3.774,
      price_pc_ytd: -3.774,
    },
    "0450": {
      id: "0450",
      c: 1.27,
      ma50: 1.265,
      ma100: 1.276,
      ma150: 1.292,
      ma200: 1.295,
      maw30: 1.291,
      rs: 72.0,
      rsd1d: 4.0,
      rsd5d: 1.0,
      rsd10d: 4.0,
      price_5d_h: 1.29,
      price_5d_L: 1.19,
      price_1m_h: 1.33,
      price_1m_l: 1.09,
      price_3m_h: 1.33,
      price_3m_l: 1.09,
      price_6m_h: 1.35,
      price_6m_l: 1.09,
      price_52w_h: 1.44,
      price_52w_l: 1.09,
      price_pc_1d: 1.6,
      price_pc_1w: 0.794,
      price_pc_1m: -2.308,
      price_pc_6m: -1.55,
      price_pc_1y: -4.511,
      price_pc_ytd: -4.511,
    },
    "0451": {
      id: "0451",
      c: 0.164,
      ma50: 0.18,
      ma100: 0.221,
      ma150: 0.259,
      ma200: 0.274,
      maw30: 0.256,
      rs: 14.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -2.0,
      price_5d_h: 0.176,
      price_5d_L: 0.161,
      price_1m_h: 0.224,
      price_1m_l: 0.148,
      price_3m_h: 0.255,
      price_3m_l: 0.148,
      price_6m_h: 0.395,
      price_6m_l: 0.148,
      price_52w_h: 0.415,
      price_52w_l: 0.148,
      price_pc_1d: -4.094,
      price_pc_1w: -4.651,
      price_pc_1m: -4.651,
      price_pc_6m: -45.333,
      price_pc_1y: -44.407,
      price_pc_ytd: -44.407,
    },
    "0456": {
      id: "0456",
      c: 0.033,
      ma50: 0.031,
      ma100: 0.049,
      ma150: 0.061,
      ma200: 0.07,
      maw30: 0.061,
      rs: 4.0,
      rsd1d: 0.0,
      rsd5d: -3.0,
      rsd10d: 0.0,
      price_5d_h: 0.038,
      price_5d_L: 0.029,
      price_1m_h: 0.042,
      price_1m_l: 0.018,
      price_3m_h: 0.078,
      price_3m_l: 0.018,
      price_6m_h: 0.113,
      price_6m_l: 0.018,
      price_52w_h: 0.113,
      price_52w_l: 0.018,
      price_pc_1d: -5.714,
      price_pc_1w: -21.429,
      price_pc_1m: 43.478,
      price_pc_6m: -59.756,
      price_pc_1y: -65.263,
      price_pc_ytd: -65.263,
    },
    "0458": {
      id: "0458",
      c: 0.99,
      ma50: 0.877,
      ma100: 0.887,
      ma150: 0.908,
      ma200: 0.922,
      maw30: 0.906,
      rs: 84.0,
      rsd1d: -2.0,
      rsd5d: 20.0,
      rsd10d: 18.0,
      price_5d_h: 1.0,
      price_5d_L: 0.87,
      price_1m_h: 1.0,
      price_1m_l: 0.86,
      price_3m_h: 1.0,
      price_3m_l: 0.85,
      price_6m_h: 1.0,
      price_6m_l: 0.85,
      price_52w_h: 1.0,
      price_52w_l: 0.85,
      price_pc_1d: -1.0,
      price_pc_1w: 13.793,
      price_pc_1m: 15.116,
      price_pc_6m: 4.211,
      price_pc_1y: 4.211,
      price_pc_ytd: 4.211,
    },
    "0459": {
      id: "0459",
      c: 0.099,
      ma50: 0.112,
      ma100: 0.121,
      ma150: 0.129,
      ma200: 0.138,
      maw30: 0.13,
      rs: 21.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: 1.0,
      price_5d_h: 0.104,
      price_5d_L: 0.092,
      price_1m_h: 0.114,
      price_1m_l: 0.089,
      price_3m_h: 0.136,
      price_3m_l: 0.089,
      price_6m_h: 0.149,
      price_6m_l: 0.089,
      price_52w_h: 0.2,
      price_52w_l: 0.089,
      price_pc_1d: -1.0,
      price_pc_1w: -3.883,
      price_pc_1m: -10.811,
      price_pc_6m: -28.777,
      price_pc_1y: -34.0,
      price_pc_ytd: -34.0,
    },
    "0460": {
      id: "0460",
      c: 1.57,
      ma50: 1.62,
      ma100: 1.571,
      ma150: 1.673,
      ma200: 1.966,
      maw30: 1.656,
      rs: 47.0,
      rsd1d: -8.0,
      rsd5d: -7.0,
      rsd10d: 2.0,
      price_5d_h: 1.75,
      price_5d_L: 1.49,
      price_1m_h: 1.78,
      price_1m_l: 1.27,
      price_3m_h: 1.99,
      price_3m_l: 1.27,
      price_6m_h: 1.99,
      price_6m_l: 1.27,
      price_52w_h: 3.95,
      price_52w_l: 1.27,
      price_pc_1d: -4.848,
      price_pc_1w: -8.187,
      price_pc_1m: -5.988,
      price_pc_6m: -5.422,
      price_pc_1y: -26.291,
      price_pc_ytd: -26.291,
    },
    "0464": {
      id: "0464",
      c: 0.45,
      ma50: 0.464,
      ma100: 0.458,
      ma150: 0.482,
      ma200: 0.503,
      maw30: 0.479,
      rs: 48.0,
      rsd1d: 0.0,
      rsd5d: 4.0,
      rsd10d: -21.0,
      price_5d_h: 0.5,
      price_5d_L: 0.41,
      price_1m_h: 0.52,
      price_1m_l: 0.41,
      price_3m_h: 0.62,
      price_3m_l: 0.39,
      price_6m_h: 0.62,
      price_6m_l: 0.39,
      price_52w_h: 0.68,
      price_52w_l: 0.39,
      price_pc_1d: 0.0,
      price_pc_1w: 4.651,
      price_pc_1m: -5.263,
      price_pc_6m: -18.182,
      price_pc_1y: -22.414,
      price_pc_ytd: -22.414,
    },
    "0465": {
      id: "0465",
      c: 0.4,
      ma50: 0.441,
      ma100: 0.515,
      ma150: 0.564,
      ma200: 0.599,
      maw30: 0.56,
      rs: 16.0,
      rsd1d: 0.0,
      rsd5d: -5.0,
      rsd10d: -5.0,
      price_5d_h: 0.48,
      price_5d_L: 0.38,
      price_1m_h: 0.48,
      price_1m_l: 0.36,
      price_3m_h: 0.77,
      price_3m_l: 0.36,
      price_6m_h: 0.78,
      price_6m_l: 0.36,
      price_52w_h: 0.85,
      price_52w_l: 0.36,
      price_pc_1d: -4.762,
      price_pc_1w: -4.762,
      price_pc_1m: 0.0,
      price_pc_6m: -35.484,
      price_pc_1y: -41.176,
      price_pc_ytd: -41.176,
    },
    "0467": {
      id: "0467",
      c: 0.9,
      ma50: 1.015,
      ma100: 0.855,
      ma150: 0.893,
      ma200: 0.963,
      maw30: 0.895,
      rs: 77.0,
      rsd1d: 9.0,
      rsd5d: -11.0,
      rsd10d: -8.0,
      price_5d_h: 0.92,
      price_5d_L: 0.81,
      price_1m_h: 1.17,
      price_1m_l: 0.64,
      price_3m_h: 1.3,
      price_3m_l: 0.58,
      price_6m_h: 1.3,
      price_6m_l: 0.46,
      price_52w_h: 1.67,
      price_52w_l: 0.46,
      price_pc_1d: 4.651,
      price_pc_1w: 3.448,
      price_pc_1m: -23.729,
      price_pc_6m: -10.0,
      price_pc_1y: -39.597,
      price_pc_ytd: -39.597,
    },
    "0468": {
      id: "0468",
      c: 2.22,
      ma50: 2.823,
      ma100: 2.815,
      ma150: 2.901,
      ma200: 3.032,
      maw30: 2.924,
      rs: 23.0,
      rsd1d: 2.0,
      rsd5d: -28.0,
      rsd10d: -19.0,
      price_5d_h: 2.86,
      price_5d_L: 2.1,
      price_1m_h: 2.86,
      price_1m_l: 2.1,
      price_3m_h: 3.26,
      price_3m_l: 2.1,
      price_6m_h: 3.26,
      price_6m_l: 2.1,
      price_52w_h: 4.15,
      price_52w_l: 2.1,
      price_pc_1d: -0.893,
      price_pc_1w: -22.105,
      price_pc_1m: -20.144,
      price_pc_6m: -23.448,
      price_pc_1y: -44.776,
      price_pc_ytd: -44.776,
    },
    "0471": {
      id: "0471",
      c: 1.15,
      ma50: 0.902,
      ma100: 0.731,
      ma150: 0.609,
      ma200: 0.522,
      maw30: 0.612,
      rs: 98.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 1.22,
      price_5d_L: 1.06,
      price_1m_h: 1.22,
      price_1m_l: 0.81,
      price_3m_h: 1.22,
      price_3m_l: 0.62,
      price_6m_h: 1.22,
      price_6m_l: 0.365,
      price_52w_h: 1.22,
      price_52w_l: 0.224,
      price_pc_1d: -1.709,
      price_pc_1w: 2.679,
      price_pc_1m: 33.721,
      price_pc_6m: 139.583,
      price_pc_1y: 289.831,
      price_pc_ytd: 289.831,
    },
    "0472": {
      id: "0472",
      c: 0.097,
      ma50: 0.101,
      ma100: 0.113,
      ma150: 0.124,
      ma200: 0.138,
      maw30: 0.125,
      rs: 19.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: 4.0,
      price_5d_h: 0.102,
      price_5d_L: 0.085,
      price_1m_h: 0.106,
      price_1m_l: 0.078,
      price_3m_h: 0.167,
      price_3m_l: 0.078,
      price_6m_h: 0.167,
      price_6m_l: 0.078,
      price_52w_h: 0.212,
      price_52w_l: 0.078,
      price_pc_1d: -3.0,
      price_pc_1w: -2.02,
      price_pc_1m: 14.118,
      price_pc_6m: -33.103,
      price_pc_1y: -39.375,
      price_pc_ytd: -39.375,
    },
    "0474": {
      id: "0474",
      c: 0.064,
      ma50: 0.136,
      ma100: 0.165,
      ma150: 0.18,
      ma200: 0.188,
      maw30: 0.184,
      rs: 2.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -1.0,
      price_5d_h: 0.072,
      price_5d_L: 0.055,
      price_1m_h: 0.111,
      price_1m_l: 0.042,
      price_3m_h: 0.199,
      price_3m_l: 0.042,
      price_6m_h: 0.23,
      price_6m_l: 0.042,
      price_52w_h: 0.23,
      price_52w_l: 0.042,
      price_pc_1d: -1.538,
      price_pc_1w: -8.571,
      price_pc_1m: -9.859,
      price_pc_6m: -69.524,
      price_pc_1y: -71.429,
      price_pc_ytd: -71.429,
    },
    "0475": {
      id: "0475",
      c: 0.71,
      ma50: 0.79,
      ma100: 0.877,
      ma150: 0.944,
      ma200: 0.913,
      maw30: 0.946,
      rs: 39.0,
      rsd1d: -20.0,
      rsd5d: 0.0,
      rsd10d: -4.0,
      price_5d_h: 0.9,
      price_5d_L: 0.65,
      price_1m_h: 0.9,
      price_1m_l: 0.62,
      price_3m_h: 1.07,
      price_3m_l: 0.62,
      price_6m_h: 1.45,
      price_6m_l: 0.62,
      price_52w_h: 1.45,
      price_52w_l: 0.62,
      price_pc_1d: -12.346,
      price_pc_1w: -2.74,
      price_pc_1m: -5.333,
      price_pc_6m: -39.831,
      price_pc_1y: 4.412,
      price_pc_ytd: 4.412,
    },
    "0476": {
      id: "0476",
      c: 0.051,
      ma50: 0.074,
      ma100: 0.084,
      ma150: 0.092,
      ma200: 0.103,
      maw30: 0.092,
      rs: 6.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: -3.0,
      price_5d_h: 0.052,
      price_5d_L: 0.048,
      price_1m_h: 0.081,
      price_1m_l: 0.048,
      price_3m_h: 0.099,
      price_3m_l: 0.048,
      price_6m_h: 0.123,
      price_6m_l: 0.048,
      price_52w_h: 0.185,
      price_52w_l: 0.048,
      price_pc_1d: 0.0,
      price_pc_1w: -1.923,
      price_pc_1m: -35.443,
      price_pc_6m: -50.485,
      price_pc_1y: -61.069,
      price_pc_ytd: -61.069,
    },
    "0480": {
      id: "0480",
      c: 2.87,
      ma50: 2.999,
      ma100: 3.014,
      ma150: 3.031,
      ma200: 3.124,
      maw30: 3.041,
      rs: 53.0,
      rsd1d: 4.0,
      rsd5d: 5.0,
      rsd10d: 0.0,
      price_5d_h: 2.98,
      price_5d_L: 2.79,
      price_1m_h: 3.04,
      price_1m_l: 2.65,
      price_3m_h: 3.16,
      price_3m_l: 2.65,
      price_6m_h: 3.2,
      price_6m_l: 2.65,
      price_52w_h: 3.95,
      price_52w_l: 2.65,
      price_pc_1d: 0.702,
      price_pc_1w: 1.413,
      price_pc_1m: -4.651,
      price_pc_6m: -6.818,
      price_pc_1y: -13.03,
      price_pc_ytd: -13.03,
    },
    "0483": {
      id: "0483",
      c: 0.57,
      ma50: 0.653,
      ma100: 0.781,
      ma150: 0.878,
      ma200: 0.956,
      maw30: 0.884,
      rs: 24.0,
      rsd1d: 4.0,
      rsd5d: 1.0,
      rsd10d: 7.0,
      price_5d_h: 0.64,
      price_5d_L: 0.53,
      price_1m_h: 0.68,
      price_1m_l: 0.48,
      price_3m_h: 0.8,
      price_3m_l: 0.48,
      price_6m_h: 1.34,
      price_6m_l: 0.48,
      price_52w_h: 1.45,
      price_52w_l: 0.48,
      price_pc_1d: 3.636,
      price_pc_1w: 1.786,
      price_pc_1m: -13.636,
      price_pc_6m: -45.714,
      price_pc_1y: 5.556,
      price_pc_ytd: 5.556,
    },
    "0484": {
      id: "0484",
      c: 0.88,
      ma50: 1.088,
      ma100: 1.144,
      ma150: 1.177,
      ma200: 1.253,
      maw30: 1.175,
      rs: 15.0,
      rsd1d: 3.0,
      rsd5d: -1.0,
      rsd10d: -4.0,
      price_5d_h: 0.99,
      price_5d_L: 0.83,
      price_1m_h: 1.15,
      price_1m_l: 0.81,
      price_3m_h: 1.32,
      price_3m_l: 0.81,
      price_6m_h: 1.32,
      price_6m_l: 0.81,
      price_52w_h: 2.13,
      price_52w_l: 0.81,
      price_pc_1d: 3.529,
      price_pc_1w: -4.348,
      price_pc_1m: -22.807,
      price_pc_6m: -25.424,
      price_pc_1y: -54.167,
      price_pc_ytd: -54.167,
    },
    "0486": {
      id: "0486",
      c: 4.9,
      ma50: 6.011,
      ma100: 6.729,
      ma150: 6.96,
      ma200: 6.558,
      maw30: 7.071,
      rs: 34.0,
      rsd1d: 4.0,
      rsd5d: 10.0,
      rsd10d: 8.0,
      price_5d_h: 5.19,
      price_5d_L: 4.18,
      price_1m_h: 5.5,
      price_1m_l: 3.23,
      price_3m_h: 9.2,
      price_3m_l: 3.23,
      price_6m_h: 9.2,
      price_6m_l: 3.23,
      price_52w_h: 9.2,
      price_52w_l: 3.23,
      price_pc_1d: 0.204,
      price_pc_1w: 4.255,
      price_pc_1m: -10.42,
      price_pc_6m: -32.785,
      price_pc_1y: -1.408,
      price_pc_ytd: -1.408,
    },
    "0488": {
      id: "0488",
      c: 3.7,
      ma50: 3.963,
      ma100: 3.986,
      ma150: 4.197,
      ma200: 4.672,
      maw30: 4.174,
      rs: 27.0,
      rsd1d: 4.0,
      rsd5d: 1.0,
      rsd10d: -3.0,
      price_5d_h: 3.8,
      price_5d_L: 3.43,
      price_1m_h: 4.25,
      price_1m_l: 3.3,
      price_3m_h: 4.47,
      price_3m_l: 3.3,
      price_6m_h: 4.74,
      price_6m_l: 3.3,
      price_52w_h: 6.98,
      price_52w_l: 3.3,
      price_pc_1d: 1.928,
      price_pc_1w: 2.778,
      price_pc_1m: -8.867,
      price_pc_6m: -19.037,
      price_pc_1y: -43.683,
      price_pc_ytd: -43.683,
    },
    "0489": {
      id: "0489",
      c: 5.89,
      ma50: 6.582,
      ma100: 6.81,
      ma150: 7.051,
      ma200: 7.038,
      maw30: 7.056,
      rs: 48.0,
      rsd1d: -6.0,
      rsd5d: -4.0,
      rsd10d: 4.0,
      price_5d_h: 6.18,
      price_5d_L: 5.8,
      price_1m_h: 6.92,
      price_1m_l: 5.09,
      price_3m_h: 7.5,
      price_3m_l: 5.09,
      price_6m_h: 7.83,
      price_6m_l: 5.09,
      price_52w_h: 8.98,
      price_52w_l: 5.09,
      price_pc_1d: -3.284,
      price_pc_1w: -3.758,
      price_pc_1m: -12.221,
      price_pc_6m: -15.252,
      price_pc_1y: -18.081,
      price_pc_ytd: -18.081,
    },
    "0493": {
      id: "0493",
      c: 0.49,
      ma50: 0.57,
      ma100: 0.629,
      ma150: 0.685,
      ma200: 0.748,
      maw30: 0.682,
      rs: 13.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: 0.0,
      price_5d_h: 0.51,
      price_5d_L: 0.445,
      price_1m_h: 0.6,
      price_1m_l: 0.38,
      price_3m_h: 0.69,
      price_3m_l: 0.38,
      price_6m_h: 0.93,
      price_6m_l: 0.38,
      price_52w_h: 1.67,
      price_52w_l: 0.38,
      price_pc_1d: -2.0,
      price_pc_1w: -1.01,
      price_pc_1m: -15.517,
      price_pc_6m: -32.877,
      price_pc_1y: -67.55,
      price_pc_ytd: -67.55,
    },
    "0496": {
      id: "0496",
      c: 0.63,
      ma50: 0.669,
      ma100: 0.664,
      ma150: 0.682,
      ma200: 0.727,
      maw30: 0.684,
      rs: 39.0,
      rsd1d: 3.0,
      rsd5d: 5.0,
      rsd10d: 1.0,
      price_5d_h: 0.66,
      price_5d_L: 0.6,
      price_1m_h: 0.7,
      price_1m_l: 0.55,
      price_3m_h: 0.81,
      price_3m_l: 0.55,
      price_6m_h: 0.85,
      price_6m_l: 0.53,
      price_52w_h: 1.37,
      price_52w_l: 0.5,
      price_pc_1d: -1.563,
      price_pc_1w: 0.0,
      price_pc_1m: -4.545,
      price_pc_6m: 6.78,
      price_pc_1y: -35.714,
      price_pc_ytd: -35.714,
    },
    "0497": {
      id: "0497",
      c: 0.195,
      ma50: 0.202,
      ma100: 0.209,
      ma150: 0.216,
      ma200: 0.226,
      maw30: 0.216,
      rs: 45.0,
      rsd1d: 0.0,
      rsd5d: 6.0,
      rsd10d: 8.0,
      price_5d_h: 0.202,
      price_5d_L: 0.182,
      price_1m_h: 0.209,
      price_1m_l: 0.182,
      price_3m_h: 0.225,
      price_3m_l: 0.182,
      price_6m_h: 0.255,
      price_6m_l: 0.182,
      price_52w_h: 0.265,
      price_52w_l: 0.182,
      price_pc_1d: -1.015,
      price_pc_1w: 3.175,
      price_pc_1m: -5.34,
      price_pc_6m: -13.333,
      price_pc_1y: -23.529,
      price_pc_ytd: -23.529,
    },
    "0498": {
      id: "0498",
      c: 0.111,
      ma50: 0.113,
      ma100: 0.125,
      ma150: 0.165,
      ma200: 0.2,
      maw30: 0.163,
      rs: 19.0,
      rsd1d: 3.0,
      rsd5d: 8.0,
      rsd10d: 0.0,
      price_5d_h: 0.111,
      price_5d_L: 0.1,
      price_1m_h: 0.13,
      price_1m_l: 0.09,
      price_3m_h: 0.153,
      price_3m_l: 0.09,
      price_6m_h: 0.3,
      price_6m_l: 0.09,
      price_52w_h: 0.45,
      price_52w_l: 0.09,
      price_pc_1d: 1.835,
      price_pc_1w: 6.731,
      price_pc_1m: -14.615,
      price_pc_6m: -54.878,
      price_pc_1y: -70.789,
      price_pc_ytd: -70.789,
    },
    "0499": {
      id: "0499",
      c: 0.239,
      ma50: 0.257,
      ma100: 0.251,
      ma150: 0.257,
      ma200: 0.27,
      maw30: 0.258,
      rs: 42.0,
      rsd1d: -6.0,
      rsd5d: 10.0,
      rsd10d: 10.0,
      price_5d_h: 0.3,
      price_5d_L: 0.224,
      price_1m_h: 0.3,
      price_1m_l: 0.22,
      price_3m_h: 0.32,
      price_3m_l: 0.22,
      price_6m_h: 0.32,
      price_6m_l: 0.22,
      price_52w_h: 0.57,
      price_52w_l: 0.22,
      price_pc_1d: -4.4,
      price_pc_1w: 3.017,
      price_pc_1m: -11.481,
      price_pc_6m: -11.481,
      price_pc_1y: -40.448,
      price_pc_ytd: -40.448,
    },
    "0500": {
      id: "0500",
      c: 0.57,
      ma50: 0.613,
      ma100: 0.626,
      ma150: 0.669,
      ma200: 0.708,
      maw30: 0.673,
      rs: 43.0,
      rsd1d: 10.0,
      rsd5d: 0.0,
      rsd10d: -14.0,
      price_5d_h: 0.58,
      price_5d_L: 0.52,
      price_1m_h: 0.59,
      price_1m_l: 0.45,
      price_3m_h: 0.85,
      price_3m_l: 0.45,
      price_6m_h: 0.85,
      price_6m_l: 0.45,
      price_52w_h: 1.0,
      price_52w_l: 0.45,
      price_pc_1d: 5.556,
      price_pc_1w: -1.724,
      price_pc_1m: -3.39,
      price_pc_6m: -29.63,
      price_pc_1y: 5.556,
      price_pc_ytd: 5.556,
    },
    "0503": {
      id: "0503",
      c: 0.98,
      ma50: 1.078,
      ma100: 1.212,
      ma150: 1.429,
      ma200: 1.765,
      maw30: 1.428,
      rs: 16.0,
      rsd1d: 4.0,
      rsd5d: 2.0,
      rsd10d: -1.0,
      price_5d_h: 1.03,
      price_5d_L: 0.92,
      price_1m_h: 1.21,
      price_1m_l: 0.85,
      price_3m_h: 1.25,
      price_3m_l: 0.85,
      price_6m_h: 1.94,
      price_6m_l: 0.85,
      price_52w_h: 4.15,
      price_52w_l: 0.85,
      price_pc_1d: 2.083,
      price_pc_1w: -1.01,
      price_pc_1m: -19.008,
      price_pc_6m: -37.975,
      price_pc_1y: -42.353,
      price_pc_ytd: -42.353,
    },
    "0505": {
      id: "0505",
      c: 1.14,
      ma50: 1.155,
      ma100: 1.134,
      ma150: 1.12,
      ma200: 1.112,
      maw30: 1.125,
      rs: 77.0,
      rsd1d: -1.0,
      rsd5d: -3.0,
      rsd10d: -3.0,
      price_5d_h: 1.19,
      price_5d_L: 1.1,
      price_1m_h: 1.2,
      price_1m_l: 1.02,
      price_3m_h: 1.23,
      price_3m_l: 1.02,
      price_6m_h: 1.23,
      price_6m_l: 1.02,
      price_52w_h: 1.29,
      price_52w_l: 0.95,
      price_pc_1d: -0.87,
      price_pc_1w: -2.564,
      price_pc_1m: 0.0,
      price_pc_6m: 4.587,
      price_pc_1y: 0.0,
      price_pc_ytd: 0.0,
    },
    "0506": {
      id: "0506",
      c: 2.71,
      ma50: 3.175,
      ma100: 3.064,
      ma150: 2.998,
      ma200: 2.921,
      maw30: 3.019,
      rs: 57.0,
      rsd1d: -4.0,
      rsd5d: -14.0,
      rsd10d: -16.0,
      price_5d_h: 2.99,
      price_5d_L: 2.67,
      price_1m_h: 3.69,
      price_1m_l: 2.61,
      price_3m_h: 3.69,
      price_3m_l: 2.61,
      price_6m_h: 3.69,
      price_6m_l: 2.61,
      price_52w_h: 3.69,
      price_52w_l: 2.45,
      price_pc_1d: -3.214,
      price_pc_1w: -7.192,
      price_pc_1m: -19.585,
      price_pc_6m: -3.559,
      price_pc_1y: -18.127,
      price_pc_ytd: -18.127,
    },
    "0508": {
      id: "0508",
      c: 0.08,
      ma50: 0.085,
      ma100: 0.09,
      ma150: 0.093,
      ma200: 0.093,
      maw30: 0.094,
      rs: 54.0,
      rsd1d: 2.0,
      rsd5d: 11.0,
      rsd10d: 0.0,
      price_5d_h: 0.08,
      price_5d_L: 0.078,
      price_1m_h: 0.087,
      price_1m_l: 0.064,
      price_3m_h: 0.097,
      price_3m_l: 0.064,
      price_6m_h: 0.112,
      price_6m_l: 0.064,
      price_52w_h: 0.128,
      price_52w_l: 0.064,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: -8.046,
      price_pc_6m: -20.792,
      price_pc_1y: -9.091,
      price_pc_ytd: -9.091,
    },
    "0509": {
      id: "0509",
      c: 0.032,
      ma50: 0.032,
      ma100: 0.032,
      ma150: 0.034,
      ma200: 0.037,
      maw30: 0.034,
      rs: 52.0,
      rsd1d: 13.0,
      rsd5d: 9.0,
      rsd10d: 13.0,
      price_5d_h: 0.032,
      price_5d_L: 0.029,
      price_1m_h: 0.035,
      price_1m_l: 0.026,
      price_3m_h: 0.042,
      price_3m_l: 0.026,
      price_6m_h: 0.05,
      price_6m_l: 0.026,
      price_52w_h: 0.065,
      price_52w_l: 0.026,
      price_pc_1d: 10.345,
      price_pc_1w: 3.226,
      price_pc_1m: -5.882,
      price_pc_6m: -8.571,
      price_pc_1y: -49.206,
      price_pc_ytd: -49.206,
    },
    "0510": {
      id: "0510",
      c: 0.27,
      ma50: 0.275,
      ma100: 0.342,
      ma150: 0.424,
      ma200: 0.475,
      maw30: 0.422,
      rs: 19.0,
      rsd1d: 5.0,
      rsd5d: 16.0,
      rsd10d: 14.0,
      price_5d_h: 0.27,
      price_5d_L: 0.241,
      price_1m_h: 0.285,
      price_1m_l: 0.23,
      price_3m_h: 0.32,
      price_3m_l: 0.221,
      price_6m_h: 0.9,
      price_6m_l: 0.221,
      price_52w_h: 0.9,
      price_52w_l: 0.221,
      price_pc_1d: 5.882,
      price_pc_1w: 3.846,
      price_pc_1m: -1.818,
      price_pc_6m: -55.0,
      price_pc_1y: -65.823,
      price_pc_ytd: -65.823,
    },
    "0511": {
      id: "0511",
      c: 4.5,
      ma50: 4.918,
      ma100: 4.895,
      ma150: 5.381,
      ma200: 5.799,
      maw30: 5.364,
      rs: 30.0,
      rsd1d: 1.0,
      rsd5d: 4.0,
      rsd10d: -1.0,
      price_5d_h: 4.66,
      price_5d_L: 4.2,
      price_1m_h: 5.17,
      price_1m_l: 4.18,
      price_3m_h: 5.74,
      price_3m_l: 4.18,
      price_6m_h: 6.43,
      price_6m_l: 4.18,
      price_52w_h: 9.86,
      price_52w_l: 4.18,
      price_pc_1d: -2.808,
      price_pc_1w: 0.223,
      price_pc_1m: -11.765,
      price_pc_6m: -26.829,
      price_pc_1y: -45.783,
      price_pc_ytd: -45.783,
    },
    "0512": {
      id: "0512",
      c: 5.56,
      ma50: 5.882,
      ma100: 6.104,
      ma150: 6.143,
      ma200: 6.284,
      maw30: 6.155,
      rs: 45.0,
      rsd1d: -2.0,
      rsd5d: -15.0,
      rsd10d: -2.0,
      price_5d_h: 6.03,
      price_5d_L: 5.48,
      price_1m_h: 6.34,
      price_1m_l: 4.84,
      price_3m_h: 6.64,
      price_3m_l: 4.84,
      price_6m_h: 6.8,
      price_6m_l: 4.84,
      price_52w_h: 8.45,
      price_52w_l: 4.84,
      price_pc_1d: -1.593,
      price_pc_1w: -7.488,
      price_pc_1m: -9.887,
      price_pc_6m: -10.467,
      price_pc_1y: -12.164,
      price_pc_ytd: -12.164,
    },
    "0517": {
      id: "0517",
      c: 2.41,
      ma50: 2.491,
      ma100: 2.458,
      ma150: 2.483,
      ma200: 2.501,
      maw30: 2.486,
      rs: 69.0,
      rsd1d: 1.0,
      rsd5d: -9.0,
      rsd10d: -10.0,
      price_5d_h: 2.54,
      price_5d_L: 2.3,
      price_1m_h: 2.55,
      price_1m_l: 2.3,
      price_3m_h: 2.64,
      price_3m_l: 2.3,
      price_6m_h: 2.64,
      price_6m_l: 2.3,
      price_52w_h: 2.8,
      price_52w_l: 2.3,
      price_pc_1d: 0.0,
      price_pc_1w: -3.984,
      price_pc_1m: -2.033,
      price_pc_6m: -3.213,
      price_pc_1y: -10.409,
      price_pc_ytd: -10.409,
    },
    "0518": {
      id: "0518",
      c: 0.285,
      ma50: 0.301,
      ma100: 0.324,
      ma150: 0.339,
      ma200: 0.351,
      maw30: 0.338,
      rs: 33.0,
      rsd1d: -2.0,
      rsd5d: -3.0,
      rsd10d: -5.0,
      price_5d_h: 0.295,
      price_5d_L: 0.265,
      price_1m_h: 0.32,
      price_1m_l: 0.265,
      price_3m_h: 0.345,
      price_3m_l: 0.265,
      price_6m_h: 0.385,
      price_6m_l: 0.265,
      price_52w_h: 0.415,
      price_52w_l: 0.265,
      price_pc_1d: -1.724,
      price_pc_1w: -5.0,
      price_pc_1m: -5.0,
      price_pc_6m: -18.571,
      price_pc_1y: -29.63,
      price_pc_ytd: -29.63,
    },
    "0519": {
      id: "0519",
      c: 0.098,
      ma50: 0.11,
      ma100: 0.113,
      ma150: 0.11,
      ma200: 0.112,
      maw30: 0.111,
      rs: 52.0,
      rsd1d: -4.0,
      rsd5d: -25.0,
      rsd10d: -28.0,
      price_5d_h: 0.11,
      price_5d_L: 0.098,
      price_1m_h: 0.126,
      price_1m_l: 0.098,
      price_3m_h: 0.126,
      price_3m_l: 0.095,
      price_6m_h: 0.159,
      price_6m_l: 0.095,
      price_52w_h: 0.159,
      price_52w_l: 0.092,
      price_pc_1d: -3.922,
      price_pc_1w: -13.274,
      price_pc_1m: -8.411,
      price_pc_6m: -2.97,
      price_pc_1y: -26.316,
      price_pc_ytd: -26.316,
    },
    "0520": {
      id: "0520",
      c: 3.96,
      ma50: 4.683,
      ma100: 5.148,
      ma150: 5.696,
      ma200: 6.166,
      maw30: 5.753,
      rs: 8.0,
      rsd1d: 2.0,
      rsd5d: 1.0,
      rsd10d: 1.0,
      price_5d_h: 4.23,
      price_5d_L: 3.55,
      price_1m_h: 5.03,
      price_1m_l: 3.01,
      price_3m_h: 6.13,
      price_3m_l: 3.01,
      price_6m_h: 7.73,
      price_6m_l: 3.01,
      price_52w_h: 17.32,
      price_52w_l: 3.01,
      price_pc_1d: 4.762,
      price_pc_1w: -1.0,
      price_pc_1m: -19.348,
      price_pc_6m: -48.235,
      price_pc_1y: -76.977,
      price_pc_ytd: -76.977,
    },
    "0521": {
      id: "0521",
      c: 0.08,
      ma50: 0.082,
      ma100: 0.086,
      ma150: 0.086,
      ma200: 0.085,
      maw30: 0.086,
      rs: 64.0,
      rsd1d: 20.0,
      rsd5d: 4.0,
      rsd10d: 12.0,
      price_5d_h: 0.086,
      price_5d_L: 0.074,
      price_1m_h: 0.086,
      price_1m_l: 0.071,
      price_3m_h: 0.1,
      price_3m_l: 0.071,
      price_6m_h: 0.108,
      price_6m_l: 0.071,
      price_52w_h: 0.108,
      price_52w_l: 0.07,
      price_pc_1d: 3.896,
      price_pc_1w: -2.439,
      price_pc_1m: -2.439,
      price_pc_6m: -8.046,
      price_pc_1y: -9.091,
      price_pc_ytd: -9.091,
    },
    "0522": {
      id: "0522",
      c: 79.5,
      ma50: 80.807,
      ma100: 81.999,
      ma150: 83.975,
      ma200: 88.368,
      maw30: 84.063,
      rs: 50.0,
      rsd1d: -4.0,
      rsd5d: -7.0,
      rsd10d: -10.0,
      price_5d_h: 84.5,
      price_5d_L: 78.2,
      price_1m_h: 87.75,
      price_1m_l: 71.85,
      price_3m_h: 89.0,
      price_3m_l: 71.85,
      price_6m_h: 89.2,
      price_6m_l: 71.85,
      price_52w_h: 125.0,
      price_52w_l: 71.85,
      price_pc_1d: -3.226,
      price_pc_1w: -4.447,
      price_pc_1m: -6.909,
      price_pc_6m: -7.719,
      price_pc_1y: -20.5,
      price_pc_ytd: -20.5,
    },
    "0524": {
      id: "0524",
      c: 0.075,
      ma50: 0.076,
      ma100: 0.085,
      ma150: 0.097,
      ma200: 0.112,
      maw30: 0.096,
      rs: 17.0,
      rsd1d: -5.0,
      rsd5d: 6.0,
      rsd10d: 5.0,
      price_5d_h: 0.089,
      price_5d_L: 0.07,
      price_1m_h: 0.089,
      price_1m_l: 0.061,
      price_3m_h: 0.095,
      price_3m_l: 0.061,
      price_6m_h: 0.133,
      price_6m_l: 0.061,
      price_52w_h: 0.233,
      price_52w_l: 0.061,
      price_pc_1d: -3.846,
      price_pc_1w: 7.143,
      price_pc_1m: -2.597,
      price_pc_6m: -39.024,
      price_pc_1y: -66.908,
      price_pc_ytd: -66.908,
    },
    "0525": {
      id: "0525",
      c: 1.33,
      ma50: 1.384,
      ma100: 1.357,
      ma150: 1.387,
      ma200: 1.395,
      maw30: 1.393,
      rs: 63.0,
      rsd1d: 0.0,
      rsd5d: -8.0,
      rsd10d: 1.0,
      price_5d_h: 1.4,
      price_5d_L: 1.31,
      price_1m_h: 1.42,
      price_1m_l: 1.2,
      price_3m_h: 1.51,
      price_3m_l: 1.2,
      price_6m_h: 1.54,
      price_6m_l: 1.2,
      price_52w_h: 1.7,
      price_52w_l: 1.2,
      price_pc_1d: -1.481,
      price_pc_1w: -5.0,
      price_pc_1m: -5.0,
      price_pc_6m: -7.639,
      price_pc_1y: -13.636,
      price_pc_ytd: -13.636,
    },
    "0526": {
      id: "0526",
      c: 0.042,
      ma50: 0.042,
      ma100: 0.043,
      ma150: 0.045,
      ma200: 0.051,
      maw30: 0.045,
      rs: 38.0,
      rsd1d: 4.0,
      rsd5d: 19.0,
      rsd10d: 12.0,
      price_5d_h: 0.047,
      price_5d_L: 0.036,
      price_1m_h: 0.047,
      price_1m_l: 0.035,
      price_3m_h: 0.052,
      price_3m_l: 0.035,
      price_6m_h: 0.06,
      price_6m_l: 0.035,
      price_52w_h: 0.095,
      price_52w_l: 0.034,
      price_pc_1d: 0.0,
      price_pc_1w: 13.514,
      price_pc_1m: 5.0,
      price_pc_6m: -2.326,
      price_pc_1y: -52.273,
      price_pc_ytd: -52.273,
    },
    "0527": {
      id: "0527",
      c: 0.177,
      ma50: 0.175,
      ma100: 0.18,
      ma150: 0.188,
      ma200: 0.196,
      maw30: 0.188,
      rs: 43.0,
      rsd1d: -5.0,
      rsd5d: -8.0,
      rsd10d: -6.0,
      price_5d_h: 0.18,
      price_5d_L: 0.172,
      price_1m_h: 0.184,
      price_1m_l: 0.161,
      price_3m_h: 0.192,
      price_3m_l: 0.158,
      price_6m_h: 0.22,
      price_6m_l: 0.158,
      price_52w_h: 0.25,
      price_52w_l: 0.158,
      price_pc_1d: 0.0,
      price_pc_1w: -1.117,
      price_pc_1m: 7.273,
      price_pc_6m: -17.674,
      price_pc_1y: -26.25,
      price_pc_ytd: -26.25,
    },
    "0528": {
      id: "0528",
      c: 1.22,
      ma50: 1.181,
      ma100: 1.182,
      ma150: 1.201,
      ma200: 1.217,
      maw30: 1.2,
      rs: 75.0,
      rsd1d: -4.0,
      rsd5d: -1.0,
      rsd10d: 20.0,
      price_5d_h: 1.24,
      price_5d_L: 1.11,
      price_1m_h: 1.28,
      price_1m_l: 1.02,
      price_3m_h: 1.28,
      price_3m_l: 1.02,
      price_6m_h: 1.28,
      price_6m_l: 1.02,
      price_52w_h: 1.41,
      price_52w_l: 1.02,
      price_pc_1d: -1.613,
      price_pc_1w: -1.613,
      price_pc_1m: 19.608,
      price_pc_6m: -3.175,
      price_pc_1y: 1.667,
      price_pc_ytd: 1.667,
    },
    "0530": {
      id: "0530",
      c: 0.148,
      ma50: 0.164,
      ma100: 0.175,
      ma150: 0.2,
      ma200: 0.228,
      maw30: 0.199,
      rs: 21.0,
      rsd1d: 1.0,
      rsd5d: -1.0,
      rsd10d: -3.0,
      price_5d_h: 0.15,
      price_5d_L: 0.145,
      price_1m_h: 0.177,
      price_1m_l: 0.14,
      price_3m_h: 0.199,
      price_3m_l: 0.14,
      price_6m_h: 0.245,
      price_6m_l: 0.14,
      price_52w_h: 0.43,
      price_52w_l: 0.14,
      price_pc_1d: 0.0,
      price_pc_1w: -3.268,
      price_pc_1m: -11.905,
      price_pc_6m: -35.652,
      price_pc_1y: -44.151,
      price_pc_ytd: -44.151,
    },
    "0531": {
      id: "0531",
      c: 0.38,
      ma50: 0.371,
      ma100: 0.31,
      ma150: 0.294,
      ma200: 0.299,
      maw30: 0.295,
      rs: 93.0,
      rsd1d: 0.0,
      rsd5d: -4.0,
      rsd10d: -1.0,
      price_5d_h: 0.45,
      price_5d_L: 0.365,
      price_1m_h: 0.49,
      price_1m_l: 0.31,
      price_3m_h: 0.49,
      price_3m_l: 0.25,
      price_6m_h: 0.49,
      price_6m_l: 0.2,
      price_52w_h: 0.49,
      price_52w_l: 0.2,
      price_pc_1d: 0.0,
      price_pc_1w: -22.449,
      price_pc_1m: -2.564,
      price_pc_6m: 35.714,
      price_pc_1y: 15.152,
      price_pc_ytd: 15.152,
    },
    "0532": {
      id: "0532",
      c: 0.93,
      ma50: 0.996,
      ma100: 1.01,
      ma150: 1.0,
      ma200: 1.009,
      maw30: 1.006,
      rs: 58.0,
      rsd1d: -2.0,
      rsd5d: -3.0,
      rsd10d: -22.0,
      price_5d_h: 0.99,
      price_5d_L: 0.88,
      price_1m_h: 1.03,
      price_1m_l: 0.87,
      price_3m_h: 1.05,
      price_3m_l: 0.87,
      price_6m_h: 1.06,
      price_6m_l: 0.87,
      price_52w_h: 1.59,
      price_52w_l: 0.73,
      price_pc_1d: -2.105,
      price_pc_1w: 0.0,
      price_pc_1m: -5.102,
      price_pc_6m: -5.102,
      price_pc_1y: 22.368,
      price_pc_ytd: 22.368,
    },
    "0533": {
      id: "0533",
      c: 1.59,
      ma50: 1.598,
      ma100: 1.625,
      ma150: 1.642,
      ma200: 1.676,
      maw30: 1.645,
      rs: 64.0,
      rsd1d: 1.0,
      rsd5d: 3.0,
      rsd10d: 12.0,
      price_5d_h: 1.62,
      price_5d_L: 1.56,
      price_1m_h: 1.65,
      price_1m_l: 1.46,
      price_3m_h: 1.68,
      price_3m_l: 1.46,
      price_6m_h: 1.75,
      price_6m_l: 1.46,
      price_52w_h: 1.93,
      price_52w_l: 1.46,
      price_pc_1d: -1.242,
      price_pc_1w: 0.633,
      price_pc_1m: -0.625,
      price_pc_6m: -4.217,
      price_pc_1y: -11.667,
      price_pc_ytd: -11.667,
    },
    "0535": {
      id: "0535",
      c: 0.84,
      ma50: 0.851,
      ma100: 0.819,
      ma150: 0.821,
      ma200: 0.848,
      maw30: 0.829,
      rs: 59.0,
      rsd1d: 5.0,
      rsd5d: 1.0,
      rsd10d: 5.0,
      price_5d_h: 0.87,
      price_5d_L: 0.77,
      price_1m_h: 0.87,
      price_1m_l: 0.61,
      price_3m_h: 0.98,
      price_3m_l: 0.61,
      price_6m_h: 0.98,
      price_6m_l: 0.61,
      price_52w_h: 1.23,
      price_52w_l: 0.61,
      price_pc_1d: 2.439,
      price_pc_1w: 2.439,
      price_pc_1m: -2.326,
      price_pc_6m: 0.0,
      price_pc_1y: -28.205,
      price_pc_ytd: -28.205,
    },
    "0536": {
      id: "0536",
      c: 1.18,
      ma50: 1.181,
      ma100: 1.166,
      ma150: 1.161,
      ma200: 1.163,
      maw30: 1.161,
      rs: 77.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: 1.0,
      price_5d_h: 1.19,
      price_5d_L: 1.15,
      price_1m_h: 1.21,
      price_1m_l: 1.1,
      price_3m_h: 1.25,
      price_3m_l: 1.1,
      price_6m_h: 1.25,
      price_6m_l: 1.07,
      price_52w_h: 1.31,
      price_52w_l: 1.07,
      price_pc_1d: 1.724,
      price_pc_1w: 2.609,
      price_pc_1m: -0.84,
      price_pc_6m: 4.425,
      price_pc_1y: 3.509,
      price_pc_ytd: 3.509,
    },
    "0538": {
      id: "0538",
      c: 1.14,
      ma50: 1.215,
      ma100: 1.237,
      ma150: 1.278,
      ma200: 1.339,
      maw30: 1.28,
      rs: 42.0,
      rsd1d: 2.0,
      rsd5d: 0.0,
      rsd10d: -5.0,
      price_5d_h: 1.19,
      price_5d_L: 1.14,
      price_1m_h: 1.25,
      price_1m_l: 1.06,
      price_3m_h: 1.34,
      price_3m_l: 1.06,
      price_6m_h: 1.42,
      price_6m_l: 1.06,
      price_52w_h: 1.85,
      price_52w_l: 1.06,
      price_pc_1d: -0.87,
      price_pc_1w: 0.885,
      price_pc_1m: -6.557,
      price_pc_6m: -14.925,
      price_pc_1y: -11.628,
      price_pc_ytd: -11.628,
    },
    "0542": {
      id: "0542",
      c: 0.12,
      ma50: 0.128,
      ma100: 0.118,
      ma150: 0.121,
      ma200: 0.121,
      maw30: 0.121,
      rs: 82.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: 6.0,
      price_5d_h: 0.12,
      price_5d_L: 0.12,
      price_1m_h: 0.15,
      price_1m_l: 0.089,
      price_3m_h: 0.15,
      price_3m_l: 0.076,
      price_6m_h: 0.15,
      price_6m_l: 0.076,
      price_52w_h: 0.155,
      price_52w_l: 0.076,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: -7.692,
      price_pc_6m: -11.111,
      price_pc_1y: -13.043,
      price_pc_ytd: -13.043,
    },
    "0543": {
      id: "0543",
      c: 1.29,
      ma50: 1.318,
      ma100: 1.369,
      ma150: 1.412,
      ma200: 1.497,
      maw30: 1.406,
      rs: 50.0,
      rsd1d: 2.0,
      rsd5d: 0.0,
      rsd10d: -5.0,
      price_5d_h: 1.32,
      price_5d_L: 1.26,
      price_1m_h: 1.39,
      price_1m_l: 1.23,
      price_3m_h: 1.4,
      price_3m_l: 1.23,
      price_6m_h: 1.62,
      price_6m_l: 1.23,
      price_52w_h: 2.12,
      price_52w_l: 1.23,
      price_pc_1d: 0.0,
      price_pc_1w: -0.769,
      price_pc_1m: -2.273,
      price_pc_6m: -13.423,
      price_pc_1y: -14.0,
      price_pc_ytd: -14.0,
    },
    "0546": {
      id: "0546",
      c: 3.11,
      ma50: 2.96,
      ma100: 2.887,
      ma150: 2.841,
      ma200: 2.749,
      maw30: 2.833,
      rs: 88.0,
      rsd1d: -3.0,
      rsd5d: -2.0,
      rsd10d: 18.0,
      price_5d_h: 3.39,
      price_5d_L: 2.95,
      price_1m_h: 3.45,
      price_1m_l: 2.42,
      price_3m_h: 3.45,
      price_3m_l: 2.42,
      price_6m_h: 3.46,
      price_6m_l: 2.42,
      price_52w_h: 3.46,
      price_52w_l: 2.31,
      price_pc_1d: -4.893,
      price_pc_1w: -7.715,
      price_pc_1m: 6.507,
      price_pc_6m: 12.681,
      price_pc_1y: 9.123,
      price_pc_ytd: 9.123,
    },
    "0547": {
      id: "0547",
      c: 0.55,
      ma50: 0.645,
      ma100: 0.693,
      ma150: 0.669,
      ma200: 0.655,
      maw30: 0.674,
      rs: 42.0,
      rsd1d: -5.0,
      rsd5d: 2.0,
      rsd10d: 13.0,
      price_5d_h: 0.59,
      price_5d_L: 0.52,
      price_1m_h: 0.69,
      price_1m_l: 0.46,
      price_3m_h: 1.06,
      price_3m_l: 0.46,
      price_6m_h: 1.06,
      price_6m_l: 0.46,
      price_52w_h: 1.06,
      price_52w_l: 0.46,
      price_pc_1d: -3.509,
      price_pc_1w: -1.786,
      price_pc_1m: -9.836,
      price_pc_6m: -9.836,
      price_pc_1y: -36.047,
      price_pc_ytd: -36.047,
    },
    "0548": {
      id: "0548",
      c: 8.25,
      ma50: 7.846,
      ma100: 7.661,
      ma150: 7.605,
      ma200: 7.562,
      maw30: 7.624,
      rs: 83.0,
      rsd1d: 5.0,
      rsd5d: 5.0,
      rsd10d: -1.0,
      price_5d_h: 8.25,
      price_5d_L: 7.6,
      price_1m_h: 8.25,
      price_1m_l: 6.9,
      price_3m_h: 8.29,
      price_3m_l: 6.9,
      price_6m_h: 8.29,
      price_6m_l: 6.9,
      price_52w_h: 8.64,
      price_52w_l: 6.9,
      price_pc_1d: 4.035,
      price_pc_1w: 7.562,
      price_pc_1m: 4.828,
      price_pc_6m: 6.865,
      price_pc_1y: 1.103,
      price_pc_ytd: 1.103,
    },
    "0551": {
      id: "0551",
      c: 12.64,
      ma50: 12.835,
      ma100: 13.374,
      ma150: 14.328,
      ma200: 15.025,
      maw30: 14.375,
      rs: 37.0,
      rsd1d: 2.0,
      rsd5d: -2.0,
      rsd10d: 8.0,
      price_5d_h: 13.18,
      price_5d_L: 12.26,
      price_1m_h: 13.8,
      price_1m_l: 10.44,
      price_3m_h: 14.0,
      price_3m_l: 10.44,
      price_6m_h: 17.42,
      price_6m_l: 10.44,
      price_52w_h: 20.95,
      price_52w_l: 10.44,
      price_pc_1d: -0.472,
      price_pc_1w: -1.095,
      price_pc_1m: -4.242,
      price_pc_6m: -19.695,
      price_pc_1y: -35.113,
      price_pc_ytd: -35.113,
    },
    "0552": {
      id: "0552",
      c: 3.55,
      ma50: 4.1,
      ma100: 3.927,
      ma150: 4.008,
      ma200: 3.894,
      maw30: 4.036,
      rs: 60.0,
      rsd1d: -18.0,
      rsd5d: -27.0,
      rsd10d: -27.0,
      price_5d_h: 4.29,
      price_5d_L: 3.55,
      price_1m_h: 4.36,
      price_1m_l: 3.55,
      price_3m_h: 4.5,
      price_3m_l: 3.55,
      price_6m_h: 4.57,
      price_6m_l: 3.55,
      price_52w_h: 4.57,
      price_52w_l: 3.22,
      price_pc_1d: -8.74,
      price_pc_1w: -15.476,
      price_pc_1m: -17.249,
      price_pc_6m: -17.824,
      price_pc_1y: 0.852,
      price_pc_ytd: 0.852,
    },
    "0553": {
      id: "0553",
      c: 3.52,
      ma50: 4.122,
      ma100: 4.088,
      ma150: 3.824,
      ma200: 3.814,
      maw30: 3.84,
      rs: 48.0,
      rsd1d: 0.0,
      rsd5d: -7.0,
      rsd10d: -17.0,
      price_5d_h: 3.73,
      price_5d_L: 3.52,
      price_1m_h: 4.34,
      price_1m_l: 3.31,
      price_3m_h: 4.95,
      price_3m_l: 3.31,
      price_6m_h: 5.04,
      price_6m_l: 2.99,
      price_52w_h: 5.15,
      price_52w_l: 2.99,
      price_pc_1d: -1.124,
      price_pc_1w: -5.882,
      price_pc_1m: -17.757,
      price_pc_6m: 5.706,
      price_pc_1y: -10.886,
      price_pc_ytd: -10.886,
    },
    "0554": {
      id: "0554",
      c: 0.165,
      ma50: 0.195,
      ma100: 0.304,
      ma150: 0.326,
      ma200: 0.34,
      maw30: 0.326,
      rs: 4.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -1.0,
      price_5d_h: 0.188,
      price_5d_L: 0.152,
      price_1m_h: 0.2,
      price_1m_l: 0.152,
      price_3m_h: 0.52,
      price_3m_l: 0.098,
      price_6m_h: 0.53,
      price_6m_l: 0.098,
      price_52w_h: 0.53,
      price_52w_l: 0.098,
      price_pc_1d: -4.07,
      price_pc_1w: -1.198,
      price_pc_1m: -12.698,
      price_pc_6m: -52.174,
      price_pc_1y: -52.174,
      price_pc_ytd: -52.174,
    },
    "0556": {
      id: "0556",
      c: 0.94,
      ma50: 0.945,
      ma100: 0.928,
      ma150: 0.934,
      ma200: 0.852,
      maw30: 0.933,
      rs: 93.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: -1.0,
      price_5d_h: 0.96,
      price_5d_L: 0.91,
      price_1m_h: 0.99,
      price_1m_l: 0.89,
      price_3m_h: 0.99,
      price_3m_l: 0.84,
      price_6m_h: 1.02,
      price_6m_l: 0.78,
      price_52w_h: 1.08,
      price_52w_l: 0.47,
      price_pc_1d: -1.053,
      price_pc_1w: -2.083,
      price_pc_1m: 0.0,
      price_pc_6m: -5.051,
      price_pc_1y: 89.899,
      price_pc_ytd: 89.899,
    },
    "0558": {
      id: "0558",
      c: 11.9,
      ma50: 11.916,
      ma100: 14.534,
      ma150: 16.369,
      ma200: 16.249,
      maw30: 16.226,
      rs: 40.0,
      rsd1d: 1.0,
      rsd5d: 3.0,
      rsd10d: 12.0,
      price_5d_h: 12.1,
      price_5d_L: 9.92,
      price_1m_h: 13.48,
      price_1m_l: 7.64,
      price_3m_h: 16.76,
      price_3m_l: 7.64,
      price_6m_h: 23.0,
      price_6m_l: 7.64,
      price_52w_h: 26.182,
      price_52w_l: 7.64,
      price_pc_1d: 1.884,
      price_pc_1w: 4.021,
      price_pc_1m: -9.16,
      price_pc_6m: -29.835,
      price_pc_1y: 9.448,
      price_pc_ytd: 9.448,
    },
    "0559": {
      id: "0559",
      c: 0.023,
      ma50: 0.025,
      ma100: 0.028,
      ma150: 0.035,
      ma200: 0.038,
      maw30: 0.035,
      rs: 12.0,
      rsd1d: 5.0,
      rsd5d: 0.0,
      rsd10d: -1.0,
      price_5d_h: 0.025,
      price_5d_L: 0.018,
      price_1m_h: 0.025,
      price_1m_l: 0.017,
      price_3m_h: 0.042,
      price_3m_l: 0.017,
      price_6m_h: 0.057,
      price_6m_l: 0.017,
      price_52w_h: 0.076,
      price_52w_l: 0.017,
      price_pc_1d: 21.053,
      price_pc_1w: 9.524,
      price_pc_1m: -8.0,
      price_pc_6m: -46.512,
      price_pc_1y: -68.056,
      price_pc_ytd: -68.056,
    },
    "0560": {
      id: "0560",
      c: 0.96,
      ma50: 1.01,
      ma100: 0.987,
      ma150: 0.99,
      ma200: 0.995,
      maw30: 0.99,
      rs: 64.0,
      rsd1d: 2.0,
      rsd5d: -2.0,
      rsd10d: -1.0,
      price_5d_h: 1.0,
      price_5d_L: 0.93,
      price_1m_h: 1.07,
      price_1m_l: 0.89,
      price_3m_h: 1.28,
      price_3m_l: 0.89,
      price_6m_h: 1.28,
      price_6m_l: 0.89,
      price_52w_h: 1.28,
      price_52w_l: 0.89,
      price_pc_1d: 0.0,
      price_pc_1w: -3.03,
      price_pc_1m: -7.692,
      price_pc_6m: -4.0,
      price_pc_1y: -15.044,
      price_pc_ytd: -15.044,
    },
    "0563": {
      id: "0563",
      c: 0.71,
      ma50: 0.714,
      ma100: 0.724,
      ma150: 0.7,
      ma200: 0.699,
      maw30: 0.704,
      rs: 68.0,
      rsd1d: 2.0,
      rsd5d: 18.0,
      rsd10d: 15.0,
      price_5d_h: 0.73,
      price_5d_L: 0.64,
      price_1m_h: 0.73,
      price_1m_l: 0.56,
      price_3m_h: 0.78,
      price_3m_l: 0.56,
      price_6m_h: 0.81,
      price_6m_l: 0.56,
      price_52w_h: 0.91,
      price_52w_l: 0.56,
      price_pc_1d: 0.0,
      price_pc_1w: 7.576,
      price_pc_1m: -4.054,
      price_pc_6m: 4.412,
      price_pc_1y: -8.974,
      price_pc_ytd: -8.974,
    },
    "0564": {
      id: "0564",
      c: 8.69,
      ma50: 8.112,
      ma100: 8.155,
      ma150: 8.444,
      ma200: 8.582,
      maw30: 8.374,
      rs: 69.0,
      rsd1d: -11.0,
      rsd5d: 2.0,
      rsd10d: 25.0,
      price_5d_h: 8.99,
      price_5d_L: 7.72,
      price_1m_h: 8.99,
      price_1m_l: 6.15,
      price_3m_h: 8.99,
      price_3m_l: 6.15,
      price_6m_h: 9.84,
      price_6m_l: 6.15,
      price_52w_h: 12.7,
      price_52w_l: 6.15,
      price_pc_1d: -1.808,
      price_pc_1w: 4.322,
      price_pc_1m: 6.365,
      price_pc_6m: 7.284,
      price_pc_1y: -22.133,
      price_pc_ytd: -22.133,
    },
    "0568": {
      id: "0568",
      c: 3.71,
      ma50: 4.105,
      ma100: 4.25,
      ma150: 4.389,
      ma200: 4.596,
      maw30: 4.412,
      rs: 57.0,
      rsd1d: -4.0,
      rsd5d: -8.0,
      rsd10d: 19.0,
      price_5d_h: 4.0,
      price_5d_L: 3.7,
      price_1m_h: 4.97,
      price_1m_l: 3.05,
      price_3m_h: 5.62,
      price_3m_l: 3.05,
      price_6m_h: 6.21,
      price_6m_l: 3.05,
      price_52w_h: 10.38,
      price_52w_l: 2.38,
      price_pc_1d: -3.886,
      price_pc_1w: -7.018,
      price_pc_1m: -20.896,
      price_pc_6m: -17.556,
      price_pc_1y: 56.54,
      price_pc_ytd: 56.54,
    },
    "0570": {
      id: "0570",
      c: 4.1,
      ma50: 4.359,
      ma100: 4.298,
      ma150: 4.092,
      ma200: 4.255,
      maw30: 4.102,
      rs: 44.0,
      rsd1d: -9.0,
      rsd5d: -25.0,
      rsd10d: -26.0,
      price_5d_h: 4.42,
      price_5d_L: 3.85,
      price_1m_h: 4.47,
      price_1m_l: 3.28,
      price_3m_h: 6.74,
      price_3m_l: 3.28,
      price_6m_h: 6.74,
      price_6m_l: 3.28,
      price_52w_h: 6.74,
      price_52w_l: 3.28,
      price_pc_1d: -1.442,
      price_pc_1w: -0.243,
      price_pc_1m: -7.865,
      price_pc_6m: 3.797,
      price_pc_1y: -14.405,
      price_pc_ytd: -14.405,
    },
    "0573": {
      id: "0573",
      c: 0.94,
      ma50: 0.866,
      ma100: 0.879,
      ma150: 0.89,
      ma200: 0.92,
      maw30: 0.891,
      rs: 74.0,
      rsd1d: -1.0,
      rsd5d: 5.0,
      rsd10d: 29.0,
      price_5d_h: 0.95,
      price_5d_L: 0.91,
      price_1m_h: 0.95,
      price_1m_l: 0.73,
      price_3m_h: 0.99,
      price_3m_l: 0.73,
      price_6m_h: 1.0,
      price_6m_l: 0.73,
      price_52w_h: 1.11,
      price_52w_l: 0.73,
      price_pc_1d: 1.075,
      price_pc_1w: 2.174,
      price_pc_1m: 11.905,
      price_pc_6m: 3.297,
      price_pc_1y: 0.0,
      price_pc_ytd: 0.0,
    },
    "0574": {
      id: "0574",
      c: 0.062,
      ma50: 0.068,
      ma100: 0.067,
      ma150: 0.069,
      ma200: 0.071,
      maw30: 0.069,
      rs: 41.0,
      rsd1d: 4.0,
      rsd5d: -9.0,
      rsd10d: -5.0,
      price_5d_h: 0.068,
      price_5d_L: 0.06,
      price_1m_h: 0.069,
      price_1m_l: 0.058,
      price_3m_h: 0.092,
      price_3m_l: 0.058,
      price_6m_h: 0.092,
      price_6m_l: 0.054,
      price_52w_h: 0.102,
      price_52w_l: 0.054,
      price_pc_1d: 0.0,
      price_pc_1w: -6.061,
      price_pc_1m: -7.463,
      price_pc_6m: -16.216,
      price_pc_1y: -21.519,
      price_pc_ytd: -21.519,
    },
    "0575": {
      id: "0575",
      c: 0.117,
      ma50: 0.129,
      ma100: 0.137,
      ma150: 0.147,
      ma200: 0.152,
      maw30: 0.147,
      rs: 22.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: 2.0,
      price_5d_h: 0.135,
      price_5d_L: 0.108,
      price_1m_h: 0.148,
      price_1m_l: 0.105,
      price_3m_h: 0.151,
      price_3m_l: 0.105,
      price_6m_h: 0.173,
      price_6m_l: 0.105,
      price_52w_h: 0.205,
      price_52w_l: 0.105,
      price_pc_1d: 2.632,
      price_pc_1w: 0.0,
      price_pc_1m: -8.594,
      price_pc_6m: -26.875,
      price_pc_1y: -42.927,
      price_pc_ytd: -42.927,
    },
    "0576": {
      id: "0576",
      c: 6.58,
      ma50: 6.892,
      ma100: 6.945,
      ma150: 6.855,
      ma200: 6.82,
      maw30: 6.854,
      rs: 68.0,
      rsd1d: -10.0,
      rsd5d: -9.0,
      rsd10d: -15.0,
      price_5d_h: 6.97,
      price_5d_L: 6.55,
      price_1m_h: 7.14,
      price_1m_l: 6.4,
      price_3m_h: 7.14,
      price_3m_l: 6.4,
      price_6m_h: 7.38,
      price_6m_l: 6.16,
      price_52w_h: 7.63,
      price_52w_l: 6.05,
      price_pc_1d: -4.913,
      price_pc_1w: -3.942,
      price_pc_1m: -6.534,
      price_pc_6m: 2.492,
      price_pc_1y: -5.866,
      price_pc_ytd: -5.866,
    },
    "0579": {
      id: "0579",
      c: 1.78,
      ma50: 2.236,
      ma100: 2.254,
      ma150: 2.31,
      ma200: 2.201,
      maw30: 2.347,
      rs: 37.0,
      rsd1d: -5.0,
      rsd5d: -25.0,
      rsd10d: -43.0,
      price_5d_h: 2.07,
      price_5d_L: 1.7,
      price_1m_h: 2.42,
      price_1m_l: 1.7,
      price_3m_h: 2.72,
      price_3m_l: 1.7,
      price_6m_h: 2.93,
      price_6m_l: 1.7,
      price_52w_h: 2.93,
      price_52w_l: 1.6,
      price_pc_1d: -4.813,
      price_pc_1w: -13.171,
      price_pc_1m: -25.21,
      price_pc_6m: -34.074,
      price_pc_1y: 0.0,
      price_pc_ytd: 0.0,
    },
    "0580": {
      id: "0580",
      c: 2.77,
      ma50: 3.097,
      ma100: 3.813,
      ma150: 3.92,
      ma200: 3.792,
      maw30: 3.968,
      rs: 35.0,
      rsd1d: -11.0,
      rsd5d: -7.0,
      rsd10d: 0.0,
      price_5d_h: 2.97,
      price_5d_L: 2.6,
      price_1m_h: 3.24,
      price_1m_l: 2.16,
      price_3m_h: 4.42,
      price_3m_l: 2.16,
      price_6m_h: 5.6,
      price_6m_l: 2.16,
      price_52w_h: 5.6,
      price_52w_l: 2.15,
      price_pc_1d: -6.102,
      price_pc_1w: -3.819,
      price_pc_1m: -12.618,
      price_pc_6m: -30.402,
      price_pc_1y: 7.364,
      price_pc_ytd: 7.364,
    },
    "0581": {
      id: "0581",
      c: 2.23,
      ma50: 2.321,
      ma100: 2.277,
      ma150: 2.357,
      ma200: 2.377,
      maw30: 2.36,
      rs: 62.0,
      rsd1d: 3.0,
      rsd5d: -4.0,
      rsd10d: 8.0,
      price_5d_h: 2.44,
      price_5d_L: 2.2,
      price_1m_h: 2.44,
      price_1m_l: 1.88,
      price_3m_h: 2.59,
      price_3m_l: 1.88,
      price_6m_h: 2.59,
      price_6m_l: 1.88,
      price_52w_h: 3.27,
      price_52w_l: 1.88,
      price_pc_1d: 0.905,
      price_pc_1w: -3.043,
      price_pc_1m: -1.762,
      price_pc_6m: -7.469,
      price_pc_1y: -3.879,
      price_pc_ytd: -3.879,
    },
    "0582": {
      id: "0582",
      c: 0.226,
      ma50: 0.223,
      ma100: 0.231,
      ma150: 0.235,
      ma200: 0.237,
      maw30: 0.235,
      rs: 56.0,
      rsd1d: 2.0,
      rsd5d: -3.0,
      rsd10d: 1.0,
      price_5d_h: 0.243,
      price_5d_L: 0.223,
      price_1m_h: 0.25,
      price_1m_l: 0.208,
      price_3m_h: 0.25,
      price_3m_l: 0.196,
      price_6m_h: 0.255,
      price_6m_l: 0.196,
      price_52w_h: 0.365,
      price_52w_l: 0.196,
      price_pc_1d: -0.877,
      price_pc_1w: -2.165,
      price_pc_1m: 0.444,
      price_pc_6m: -5.833,
      price_pc_1y: -30.462,
      price_pc_ytd: -30.462,
    },
    "0585": {
      id: "0585",
      c: 0.52,
      ma50: 0.611,
      ma100: 0.632,
      ma150: 0.758,
      ma200: 0.834,
      maw30: 0.749,
      rs: 13.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: -12.0,
      price_5d_h: 0.58,
      price_5d_L: 0.51,
      price_1m_h: 0.66,
      price_1m_l: 0.51,
      price_3m_h: 0.75,
      price_3m_l: 0.51,
      price_6m_h: 1.17,
      price_6m_l: 0.51,
      price_52w_h: 2.42,
      price_52w_l: 0.51,
      price_pc_1d: 0.0,
      price_pc_1w: 1.961,
      price_pc_1m: -17.46,
      price_pc_6m: -53.571,
      price_pc_1y: -68.293,
      price_pc_ytd: -68.293,
    },
    "0586": {
      id: "0586",
      c: 22.9,
      ma50: 34.922,
      ma100: 36.5,
      ma150: 36.19,
      ma200: 35.016,
      maw30: 36.733,
      rs: 16.0,
      rsd1d: 2.0,
      rsd5d: 4.0,
      rsd10d: -69.0,
      price_5d_h: 24.2,
      price_5d_L: 21.1,
      price_1m_h: 38.25,
      price_1m_l: 21.1,
      price_3m_h: 38.5,
      price_3m_l: 21.1,
      price_6m_h: 39.5,
      price_6m_l: 21.1,
      price_52w_h: 39.5,
      price_52w_l: 21.1,
      price_pc_1d: -1.08,
      price_pc_1w: 5.287,
      price_pc_1m: -39.096,
      price_pc_6m: -36.477,
      price_pc_1y: -37.088,
      price_pc_ytd: -37.088,
    },
    "0587": {
      id: "0587",
      c: 9.8,
      ma50: null,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 75.0,
      rsd1d: 1.0,
      rsd5d: null,
      rsd10d: null,
      price_5d_h: 15.1,
      price_5d_L: 7.18,
      price_1m_h: 15.1,
      price_1m_l: 7.18,
      price_3m_h: 15.1,
      price_3m_l: 7.18,
      price_6m_h: 15.1,
      price_6m_l: 7.18,
      price_52w_h: 15.1,
      price_52w_l: 7.18,
      price_pc_1d: 0.307,
      price_pc_1w: 0.307,
      price_pc_1m: 0.307,
      price_pc_6m: 0.307,
      price_pc_1y: 0.307,
      price_pc_ytd: 0.307,
    },
    "0588": {
      id: "0588",
      c: 1.17,
      ma50: 1.187,
      ma100: 1.19,
      ma150: 1.233,
      ma200: 1.272,
      maw30: 1.233,
      rs: 56.0,
      rsd1d: 3.0,
      rsd5d: 11.0,
      rsd10d: 7.0,
      price_5d_h: 1.24,
      price_5d_L: 1.1,
      price_1m_h: 1.24,
      price_1m_l: 1.0,
      price_3m_h: 1.36,
      price_3m_l: 1.0,
      price_6m_h: 1.36,
      price_6m_l: 1.0,
      price_52w_h: 1.56,
      price_52w_l: 1.0,
      price_pc_1d: 0.0,
      price_pc_1w: 3.54,
      price_pc_1m: -1.681,
      price_pc_6m: -11.364,
      price_pc_1y: -19.863,
      price_pc_ytd: -19.863,
    },
    "0589": {
      id: "0589",
      c: 0.7,
      ma50: 0.963,
      ma100: 1.0,
      ma150: 1.055,
      ma200: 1.07,
      maw30: 1.063,
      rs: 14.0,
      rsd1d: -1.0,
      rsd5d: -7.0,
      rsd10d: -23.0,
      price_5d_h: 0.93,
      price_5d_L: 0.65,
      price_1m_h: 1.01,
      price_1m_l: 0.65,
      price_3m_h: 1.05,
      price_3m_l: 0.65,
      price_6m_h: 1.22,
      price_6m_l: 0.65,
      price_52w_h: 1.55,
      price_52w_l: 0.65,
      price_pc_1d: -5.405,
      price_pc_1w: -14.634,
      price_pc_1m: -30.693,
      price_pc_6m: -39.655,
      price_pc_1y: -51.724,
      price_pc_ytd: -51.724,
    },
    "0590": {
      id: "0590",
      c: 19.08,
      ma50: 20.079,
      ma100: 21.232,
      ma150: 21.329,
      ma200: 22.224,
      maw30: 21.375,
      rs: 50.0,
      rsd1d: 1.0,
      rsd5d: -9.0,
      rsd10d: -6.0,
      price_5d_h: 19.98,
      price_5d_L: 18.92,
      price_1m_h: 21.05,
      price_1m_l: 17.7,
      price_3m_h: 22.35,
      price_3m_l: 17.7,
      price_6m_h: 25.8,
      price_6m_l: 17.7,
      price_52w_h: 28.5,
      price_52w_l: 17.7,
      price_pc_1d: -0.935,
      price_pc_1w: -1.953,
      price_pc_1m: -8.048,
      price_pc_6m: -4.6,
      price_pc_1y: -8.489,
      price_pc_ytd: -8.489,
    },
    "0592": {
      id: "0592",
      c: 0.64,
      ma50: 0.68,
      ma100: 0.836,
      ma150: 0.874,
      ma200: 0.823,
      maw30: 0.896,
      rs: 60.0,
      rsd1d: -9.0,
      rsd5d: -18.0,
      rsd10d: 13.0,
      price_5d_h: 0.67,
      price_5d_L: 0.62,
      price_1m_h: 0.73,
      price_1m_l: 0.41,
      price_3m_h: 0.89,
      price_3m_l: 0.41,
      price_6m_h: 1.39,
      price_6m_l: 0.41,
      price_52w_h: 1.39,
      price_52w_l: 0.39,
      price_pc_1d: -4.478,
      price_pc_1w: -4.478,
      price_pc_1m: 4.918,
      price_pc_6m: -27.273,
      price_pc_1y: 56.098,
      price_pc_ytd: 56.098,
    },
    "0593": {
      id: "0593",
      c: 0.95,
      ma50: 0.795,
      ma100: 0.89,
      ma150: 1.047,
      ma200: 1.207,
      maw30: 1.047,
      rs: 41.0,
      rsd1d: 17.0,
      rsd5d: 19.0,
      rsd10d: 30.0,
      price_5d_h: 0.95,
      price_5d_L: 0.84,
      price_1m_h: 1.05,
      price_1m_l: 0.65,
      price_3m_h: 1.05,
      price_3m_l: 0.6,
      price_6m_h: 1.45,
      price_6m_l: 0.6,
      price_52w_h: 3.8,
      price_52w_l: 0.6,
      price_pc_1d: 11.765,
      price_pc_1w: 11.765,
      price_pc_1m: 35.714,
      price_pc_6m: -34.483,
      price_pc_1y: -37.086,
      price_pc_ytd: -37.086,
    },
    "0595": {
      id: "0595",
      c: 0.52,
      ma50: 0.513,
      ma100: 0.539,
      ma150: 0.552,
      ma200: 0.574,
      maw30: 0.553,
      rs: 64.0,
      rsd1d: -1.0,
      rsd5d: 20.0,
      rsd10d: 28.0,
      price_5d_h: 0.53,
      price_5d_L: 0.495,
      price_1m_h: 0.53,
      price_1m_l: 0.44,
      price_3m_h: 0.57,
      price_3m_l: 0.44,
      price_6m_h: 0.67,
      price_6m_l: 0.44,
      price_52w_h: 0.88,
      price_52w_l: 0.44,
      price_pc_1d: -1.887,
      price_pc_1w: 4.0,
      price_pc_1m: 0.0,
      price_pc_6m: -8.772,
      price_pc_1y: -3.704,
      price_pc_ytd: -3.704,
    },
    "0596": {
      id: "0596",
      c: 3.3,
      ma50: 3.592,
      ma100: 3.841,
      ma150: 3.947,
      ma200: 3.605,
      maw30: 4.005,
      rs: 83.0,
      rsd1d: 24.0,
      rsd5d: 16.0,
      rsd10d: 30.0,
      price_5d_h: 3.44,
      price_5d_L: 2.7,
      price_1m_h: 3.72,
      price_1m_l: 2.0,
      price_3m_h: 5.12,
      price_3m_l: 2.0,
      price_6m_h: 5.15,
      price_6m_l: 2.0,
      price_52w_h: 5.3,
      price_52w_l: 1.85,
      price_pc_1d: 14.983,
      price_pc_1w: 10.368,
      price_pc_1m: -11.29,
      price_pc_6m: -30.526,
      price_pc_1y: 43.478,
      price_pc_ytd: 43.478,
    },
    "0598": {
      id: "0598",
      c: 2.45,
      ma50: 2.485,
      ma100: 2.486,
      ma150: 2.678,
      ma200: 2.781,
      maw30: 2.673,
      rs: 50.0,
      rsd1d: 1.0,
      rsd5d: 13.0,
      rsd10d: 13.0,
      price_5d_h: 2.5,
      price_5d_L: 2.21,
      price_1m_h: 2.75,
      price_1m_l: 1.99,
      price_3m_h: 2.8,
      price_3m_l: 1.99,
      price_6m_h: 3.23,
      price_6m_l: 1.99,
      price_52w_h: 3.87,
      price_52w_l: 1.99,
      price_pc_1d: 0.0,
      price_pc_1w: 5.603,
      price_pc_1m: 0.41,
      price_pc_6m: -19.935,
      price_pc_1y: -19.935,
      price_pc_ytd: -19.935,
    },
    "0600": {
      id: "0600",
      c: 0.41,
      ma50: 0.403,
      ma100: 0.4,
      ma150: 0.396,
      ma200: 0.363,
      maw30: 0.397,
      rs: 99.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.53,
      price_5d_L: 0.4,
      price_1m_h: 0.53,
      price_1m_l: 0.32,
      price_3m_h: 0.53,
      price_3m_l: 0.32,
      price_6m_h: 0.72,
      price_6m_l: 0.06,
      price_52w_h: 0.72,
      price_52w_l: 0.021,
      price_pc_1d: -6.818,
      price_pc_1w: 2.5,
      price_pc_1m: 2.5,
      price_pc_6m: -16.327,
      price_pc_1y: 1540.0,
      price_pc_ytd: 1540.0,
    },
    "0601": {
      id: "0601",
      c: 0.04,
      ma50: 0.043,
      ma100: 0.054,
      ma150: 0.056,
      ma200: 0.056,
      maw30: 0.056,
      rs: 14.0,
      rsd1d: 1.0,
      rsd5d: 3.0,
      rsd10d: -3.0,
      price_5d_h: 0.041,
      price_5d_L: 0.035,
      price_1m_h: 0.047,
      price_1m_l: 0.028,
      price_3m_h: 0.068,
      price_3m_l: 0.028,
      price_6m_h: 0.1,
      price_6m_l: 0.028,
      price_52w_h: 0.1,
      price_52w_l: 0.028,
      price_pc_1d: 0.0,
      price_pc_1w: 5.263,
      price_pc_1m: 0.0,
      price_pc_6m: -29.825,
      price_pc_1y: -52.941,
      price_pc_ytd: -52.941,
    },
    "0603": {
      id: "0603",
      c: 0.365,
      ma50: 0.431,
      ma100: 0.435,
      ma150: 0.458,
      ma200: 0.446,
      maw30: 0.465,
      rs: 33.0,
      rsd1d: -17.0,
      rsd5d: -43.0,
      rsd10d: -35.0,
      price_5d_h: 0.445,
      price_5d_L: 0.365,
      price_1m_h: 0.46,
      price_1m_l: 0.355,
      price_3m_h: 0.495,
      price_3m_l: 0.355,
      price_6m_h: 0.64,
      price_6m_l: 0.355,
      price_52w_h: 0.64,
      price_52w_l: 0.355,
      price_pc_1d: -7.595,
      price_pc_1w: -16.092,
      price_pc_1m: -15.116,
      price_pc_6m: -34.821,
      price_pc_1y: -13.095,
      price_pc_ytd: -13.095,
    },
    "0604": {
      id: "0604",
      c: 1.75,
      ma50: 1.778,
      ma100: 1.775,
      ma150: 1.871,
      ma200: 1.98,
      maw30: 1.862,
      rs: 49.0,
      rsd1d: -2.0,
      rsd5d: 1.0,
      rsd10d: -6.0,
      price_5d_h: 1.78,
      price_5d_L: 1.65,
      price_1m_h: 1.82,
      price_1m_l: 1.51,
      price_3m_h: 1.94,
      price_3m_l: 1.51,
      price_6m_h: 2.07,
      price_6m_l: 1.51,
      price_52w_h: 2.98,
      price_52w_l: 1.51,
      price_pc_1d: -1.13,
      price_pc_1w: 0.0,
      price_pc_1m: -0.568,
      price_pc_6m: -10.714,
      price_pc_1y: -36.364,
      price_pc_ytd: -36.364,
    },
    "0606": {
      id: "0606",
      c: 2.0,
      ma50: 2.419,
      ma100: 2.992,
      ma150: 3.357,
      ma200: null,
      maw30: 3.355,
      rs: 8.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: -3.0,
      price_5d_h: 2.1,
      price_5d_L: 1.79,
      price_1m_h: 2.45,
      price_1m_l: 1.76,
      price_3m_h: 3.69,
      price_3m_l: 1.76,
      price_6m_h: 4.28,
      price_6m_l: 1.76,
      price_52w_h: 4.55,
      price_52w_l: 1.76,
      price_pc_1d: -0.498,
      price_pc_1w: 4.167,
      price_pc_1m: -11.894,
      price_pc_6m: -52.607,
      price_pc_1y: -50.0,
      price_pc_ytd: -50.0,
    },
    "0607": {
      id: "0607",
      c: 0.116,
      ma50: 0.134,
      ma100: 0.121,
      ma150: 0.117,
      ma200: 0.119,
      maw30: 0.118,
      rs: 67.0,
      rsd1d: 6.0,
      rsd5d: 5.0,
      rsd10d: -18.0,
      price_5d_h: 0.118,
      price_5d_L: 0.106,
      price_1m_h: 0.146,
      price_1m_l: 0.106,
      price_3m_h: 0.168,
      price_3m_l: 0.106,
      price_6m_h: 0.168,
      price_6m_l: 0.1,
      price_52w_h: 0.187,
      price_52w_l: 0.099,
      price_pc_1d: 3.571,
      price_pc_1w: 1.754,
      price_pc_1m: -18.31,
      price_pc_6m: 9.434,
      price_pc_1y: -30.12,
      price_pc_ytd: -30.12,
    },
    "0609": {
      id: "0609",
      c: 1.5,
      ma50: 1.29,
      ma100: 1.24,
      ma150: 1.17,
      ma200: 1.086,
      maw30: 1.174,
      rs: 95.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: 1.0,
      price_5d_h: 1.57,
      price_5d_L: 1.38,
      price_1m_h: 1.57,
      price_1m_l: 0.99,
      price_3m_h: 1.57,
      price_3m_l: 0.99,
      price_6m_h: 1.57,
      price_6m_l: 0.96,
      price_52w_h: 1.57,
      price_52w_l: 0.63,
      price_pc_1d: 0.671,
      price_pc_1w: 6.383,
      price_pc_1m: 15.385,
      price_pc_6m: 40.187,
      price_pc_1y: 134.375,
      price_pc_ytd: 134.375,
    },
    "0610": {
      id: "0610",
      c: 3.36,
      ma50: 3.766,
      ma100: 3.754,
      ma150: 3.777,
      ma200: 3.856,
      maw30: 3.792,
      rs: 49.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: -17.0,
      price_5d_h: 3.64,
      price_5d_L: 3.15,
      price_1m_h: 3.9,
      price_1m_l: 3.15,
      price_3m_h: 3.95,
      price_3m_l: 3.15,
      price_6m_h: 3.95,
      price_6m_l: 3.15,
      price_52w_h: 4.43,
      price_52w_l: 3.15,
      price_pc_1d: -1.754,
      price_pc_1w: -2.326,
      price_pc_1m: -13.846,
      price_pc_6m: -8.447,
      price_pc_1y: -20.19,
      price_pc_ytd: -20.19,
    },
    "0611": {
      id: "0611",
      c: 0.7,
      ma50: 0.745,
      ma100: 0.837,
      ma150: 0.805,
      ma200: 0.753,
      maw30: 0.814,
      rs: 65.0,
      rsd1d: -4.0,
      rsd5d: 10.0,
      rsd10d: 7.0,
      price_5d_h: 0.75,
      price_5d_L: 0.62,
      price_1m_h: 0.77,
      price_1m_l: 0.58,
      price_3m_h: 0.97,
      price_3m_l: 0.58,
      price_6m_h: 1.32,
      price_6m_l: 0.58,
      price_52w_h: 1.32,
      price_52w_l: 0.48,
      price_pc_1d: -2.778,
      price_pc_1w: 7.692,
      price_pc_1m: -10.256,
      price_pc_6m: 14.754,
      price_pc_1y: -2.778,
      price_pc_ytd: -2.778,
    },
    "0612": {
      id: "0612",
      c: 3.98,
      ma50: 3.67,
      ma100: 3.403,
      ma150: 3.394,
      ma200: 3.397,
      maw30: 3.364,
      rs: 92.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 4.18,
      price_5d_L: 3.75,
      price_1m_h: 4.19,
      price_1m_l: 3.44,
      price_3m_h: 4.19,
      price_3m_l: 3.14,
      price_6m_h: 4.19,
      price_6m_l: 2.43,
      price_52w_h: 5.0,
      price_52w_l: 2.43,
      price_pc_1d: 0.759,
      price_pc_1w: -3.398,
      price_pc_1m: 15.698,
      price_pc_6m: 11.798,
      price_pc_1y: 44.203,
      price_pc_ytd: 44.203,
    },
    "0616": {
      id: "0616",
      c: 0.124,
      ma50: 0.131,
      ma100: 0.201,
      ma150: 0.28,
      ma200: 0.323,
      maw30: 0.275,
      rs: 5.0,
      rsd1d: 2.0,
      rsd5d: 2.0,
      rsd10d: 2.0,
      price_5d_h: 0.131,
      price_5d_L: 0.102,
      price_1m_h: 0.135,
      price_1m_l: 0.1,
      price_3m_h: 0.199,
      price_3m_l: 0.1,
      price_6m_h: 0.49,
      price_6m_l: 0.1,
      price_52w_h: 0.53,
      price_52w_l: 0.1,
      price_pc_1d: 7.826,
      price_pc_1w: 3.333,
      price_pc_1m: -3.125,
      price_pc_6m: -71.494,
      price_pc_1y: -74.949,
      price_pc_ytd: -74.949,
    },
    "0617": {
      id: "0617",
      c: 2.11,
      ma50: 2.123,
      ma100: 2.085,
      ma150: 2.078,
      ma200: 2.094,
      maw30: 2.073,
      rs: 79.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: 16.0,
      price_5d_h: 2.11,
      price_5d_L: 1.98,
      price_1m_h: 2.15,
      price_1m_l: 1.88,
      price_3m_h: 2.38,
      price_3m_l: 1.88,
      price_6m_h: 2.38,
      price_6m_l: 1.88,
      price_52w_h: 2.38,
      price_52w_l: 1.88,
      price_pc_1d: 0.476,
      price_pc_1w: -0.472,
      price_pc_1m: 0.0,
      price_pc_6m: 4.975,
      price_pc_1y: 0.0,
      price_pc_ytd: 0.0,
    },
    "0620": {
      id: "0620",
      c: 1.85,
      ma50: 2.394,
      ma100: 2.963,
      ma150: 3.177,
      ma200: 3.587,
      maw30: 3.217,
      rs: 7.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: -3.0,
      price_5d_h: 1.98,
      price_5d_L: 1.78,
      price_1m_h: 2.43,
      price_1m_l: 1.78,
      price_3m_h: 3.6,
      price_3m_l: 1.32,
      price_6m_h: 4.6,
      price_6m_l: 1.32,
      price_52w_h: 6.98,
      price_52w_l: 1.32,
      price_pc_1d: 0.0,
      price_pc_1w: -1.596,
      price_pc_1m: -17.778,
      price_pc_6m: -46.064,
      price_pc_1y: -58.613,
      price_pc_ytd: -58.613,
    },
    "0621": {
      id: "0621",
      c: 0.026,
      ma50: 0.029,
      ma100: 0.028,
      ma150: 0.027,
      ma200: 0.028,
      maw30: 0.027,
      rs: 59.0,
      rsd1d: 7.0,
      rsd5d: 3.0,
      rsd10d: -11.0,
      price_5d_h: 0.028,
      price_5d_L: 0.026,
      price_1m_h: 0.029,
      price_1m_l: 0.025,
      price_3m_h: 0.038,
      price_3m_l: 0.025,
      price_6m_h: 0.038,
      price_6m_l: 0.022,
      price_52w_h: 0.046,
      price_52w_l: 0.022,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: -7.143,
      price_pc_6m: -7.143,
      price_pc_1y: -21.212,
      price_pc_ytd: -21.212,
    },
    "0622": {
      id: "0622",
      c: 0.5,
      ma50: 0.517,
      ma100: 0.535,
      ma150: 0.533,
      ma200: 0.532,
      maw30: 0.533,
      rs: 56.0,
      rsd1d: 5.0,
      rsd5d: 2.0,
      rsd10d: -8.0,
      price_5d_h: 0.52,
      price_5d_L: 0.49,
      price_1m_h: 0.52,
      price_1m_l: 0.445,
      price_3m_h: 0.62,
      price_3m_l: 0.445,
      price_6m_h: 0.67,
      price_6m_l: 0.445,
      price_52w_h: 0.67,
      price_52w_l: 0.44,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: 0.0,
      price_pc_6m: -1.961,
      price_pc_1y: -21.875,
      price_pc_ytd: -21.875,
    },
    "0623": {
      id: "0623",
      c: 0.93,
      ma50: 1.062,
      ma100: 1.095,
      ma150: 1.14,
      ma200: 1.146,
      maw30: 1.149,
      rs: 39.0,
      rsd1d: 6.0,
      rsd5d: -10.0,
      rsd10d: -13.0,
      price_5d_h: 0.98,
      price_5d_L: 0.9,
      price_1m_h: 1.03,
      price_1m_l: 0.85,
      price_3m_h: 1.19,
      price_3m_l: 0.85,
      price_6m_h: 1.29,
      price_6m_l: 0.85,
      price_52w_h: 1.42,
      price_52w_l: 0.85,
      price_pc_1d: 1.087,
      price_pc_1w: -5.102,
      price_pc_1m: -7.0,
      price_pc_6m: -27.907,
      price_pc_1y: -13.084,
      price_pc_ytd: -13.084,
    },
    "0626": {
      id: "0626",
      c: 2.44,
      ma50: 2.507,
      ma100: 2.498,
      ma150: 2.49,
      ma200: 2.484,
      maw30: 2.497,
      rs: 72.0,
      rsd1d: -5.0,
      rsd5d: -11.0,
      rsd10d: -11.0,
      price_5d_h: 2.53,
      price_5d_L: 2.4,
      price_1m_h: 2.57,
      price_1m_l: 2.28,
      price_3m_h: 2.76,
      price_3m_l: 2.28,
      price_6m_h: 2.76,
      price_6m_l: 2.28,
      price_52w_h: 2.76,
      price_52w_l: 2.15,
      price_pc_1d: -2.008,
      price_pc_1w: -4.314,
      price_pc_1m: -0.813,
      price_pc_6m: -1.613,
      price_pc_1y: 10.909,
      price_pc_ytd: 10.909,
    },
    "0627": {
      id: "0627",
      c: 0.012,
      ma50: 0.014,
      ma100: 0.017,
      ma150: 0.018,
      ma200: 0.018,
      maw30: 0.018,
      rs: 18.0,
      rsd1d: 0.0,
      rsd5d: 11.0,
      rsd10d: 2.0,
      price_5d_h: 0.014,
      price_5d_L: 0.01,
      price_1m_h: 0.016,
      price_1m_l: 0.01,
      price_3m_h: 0.033,
      price_3m_l: 0.01,
      price_6m_h: 0.033,
      price_6m_l: 0.01,
      price_52w_h: 0.033,
      price_52w_l: 0.01,
      price_pc_1d: -7.692,
      price_pc_1w: 20.0,
      price_pc_1m: -20.0,
      price_pc_6m: -40.0,
      price_pc_1y: -40.0,
      price_pc_ytd: -40.0,
    },
    "0628": {
      id: "0628",
      c: 0.345,
      ma50: 0.3,
      ma100: 0.288,
      ma150: 0.314,
      ma200: 0.354,
      maw30: 0.314,
      rs: 66.0,
      rsd1d: 4.0,
      rsd5d: 19.0,
      rsd10d: 15.0,
      price_5d_h: 0.365,
      price_5d_L: 0.315,
      price_1m_h: 0.38,
      price_1m_l: 0.265,
      price_3m_h: 0.38,
      price_3m_l: 0.241,
      price_6m_h: 0.39,
      price_6m_l: 0.241,
      price_52w_h: 0.88,
      price_52w_l: 0.241,
      price_pc_1d: 1.471,
      price_pc_1w: 2.985,
      price_pc_1m: 7.812,
      price_pc_6m: -1.429,
      price_pc_1y: -56.329,
      price_pc_ytd: -56.329,
    },
    "0630": {
      id: "0630",
      c: 0.08,
      ma50: 0.078,
      ma100: 0.079,
      ma150: 0.1,
      ma200: 0.108,
      maw30: 0.098,
      rs: 19.0,
      rsd1d: 3.0,
      rsd5d: -3.0,
      rsd10d: -13.0,
      price_5d_h: 0.084,
      price_5d_L: 0.07,
      price_1m_h: 0.084,
      price_1m_l: 0.067,
      price_3m_h: 0.099,
      price_3m_l: 0.063,
      price_6m_h: 0.159,
      price_6m_l: 0.06,
      price_52w_h: 0.3,
      price_52w_l: 0.06,
      price_pc_1d: 1.266,
      price_pc_1w: 0.0,
      price_pc_1m: -3.614,
      price_pc_6m: -50.0,
      price_pc_1y: -59.391,
      price_pc_ytd: -59.391,
    },
    "0631": {
      id: "0631",
      c: 8.18,
      ma50: 8.164,
      ma100: 8.0,
      ma150: 8.504,
      ma200: 8.518,
      maw30: 8.505,
      rs: 74.0,
      rsd1d: -2.0,
      rsd5d: -8.0,
      rsd10d: -5.0,
      price_5d_h: 8.61,
      price_5d_L: 7.96,
      price_1m_h: 8.65,
      price_1m_l: 6.96,
      price_3m_h: 8.82,
      price_3m_l: 6.96,
      price_6m_h: 9.85,
      price_6m_l: 6.93,
      price_52w_h: 10.8,
      price_52w_l: 6.93,
      price_pc_1d: -1.327,
      price_pc_1w: -4.327,
      price_pc_1m: 1.238,
      price_pc_6m: -11.28,
      price_pc_1y: -13.439,
      price_pc_ytd: -13.439,
    },
    "0632": {
      id: "0632",
      c: 0.285,
      ma50: 0.273,
      ma100: 0.27,
      ma150: 0.276,
      ma200: 0.273,
      maw30: 0.275,
      rs: 81.0,
      rsd1d: -4.0,
      rsd5d: -6.0,
      rsd10d: 18.0,
      price_5d_h: 0.31,
      price_5d_L: 0.285,
      price_1m_h: 0.375,
      price_1m_l: 0.245,
      price_3m_h: 0.375,
      price_3m_l: 0.245,
      price_6m_h: 0.425,
      price_6m_l: 0.245,
      price_52w_h: 0.425,
      price_52w_l: 0.24,
      price_pc_1d: -3.39,
      price_pc_1w: -6.557,
      price_pc_1m: 7.547,
      price_pc_6m: 5.556,
      price_pc_1y: -5.0,
      price_pc_ytd: -5.0,
    },
    "0635": {
      id: "0635",
      c: 0.72,
      ma50: 0.768,
      ma100: 0.779,
      ma150: 0.793,
      ma200: 0.821,
      maw30: 0.791,
      rs: 51.0,
      rsd1d: 3.0,
      rsd5d: -5.0,
      rsd10d: 0.0,
      price_5d_h: 0.75,
      price_5d_L: 0.7,
      price_1m_h: 0.79,
      price_1m_l: 0.69,
      price_3m_h: 0.87,
      price_3m_l: 0.69,
      price_6m_h: 0.87,
      price_6m_l: 0.69,
      price_52w_h: 0.98,
      price_52w_l: 0.69,
      price_pc_1d: 0.0,
      price_pc_1w: -4.0,
      price_pc_1m: -7.692,
      price_pc_6m: -10.0,
      price_pc_1y: -18.182,
      price_pc_ytd: -18.182,
    },
    "0636": {
      id: "0636",
      c: 17.96,
      ma50: 18.638,
      ma100: 19.599,
      ma150: 19.835,
      ma200: 20.766,
      maw30: 19.92,
      rs: 55.0,
      rsd1d: -4.0,
      rsd5d: 17.0,
      rsd10d: 9.0,
      price_5d_h: 18.9,
      price_5d_L: 16.66,
      price_1m_h: 19.44,
      price_1m_l: 15.06,
      price_3m_h: 22.55,
      price_3m_l: 15.06,
      price_6m_h: 25.15,
      price_6m_l: 15.06,
      price_52w_h: 27.15,
      price_52w_l: 15.06,
      price_pc_1d: -3.233,
      price_pc_1w: 4.784,
      price_pc_1m: -5.274,
      price_pc_6m: 7.803,
      price_pc_1y: -24.219,
      price_pc_ytd: -24.219,
    },
    "0637": {
      id: "0637",
      c: 0.27,
      ma50: 0.281,
      ma100: 0.29,
      ma150: 0.301,
      ma200: 0.309,
      maw30: 0.304,
      rs: 49.0,
      rsd1d: -1.0,
      rsd5d: 5.0,
      rsd10d: 1.0,
      price_5d_h: 0.28,
      price_5d_L: 0.265,
      price_1m_h: 0.295,
      price_1m_l: 0.25,
      price_3m_h: 0.3,
      price_3m_l: 0.25,
      price_6m_h: 0.36,
      price_6m_l: 0.25,
      price_52w_h: 0.49,
      price_52w_l: 0.25,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: 1.887,
      price_pc_6m: -14.286,
      price_pc_1y: -12.903,
      price_pc_ytd: -12.903,
    },
    "0638": {
      id: "0638",
      c: 0.65,
      ma50: 0.725,
      ma100: 0.769,
      ma150: 0.79,
      ma200: 0.847,
      maw30: 0.789,
      rs: 22.0,
      rsd1d: 2.0,
      rsd5d: -2.0,
      rsd10d: -2.0,
      price_5d_h: 0.72,
      price_5d_L: 0.64,
      price_1m_h: 0.76,
      price_1m_l: 0.6,
      price_3m_h: 0.83,
      price_3m_l: 0.6,
      price_6m_h: 0.91,
      price_6m_l: 0.6,
      price_52w_h: 1.45,
      price_52w_l: 0.6,
      price_pc_1d: 0.0,
      price_pc_1w: -5.797,
      price_pc_1m: -12.162,
      price_pc_6m: -19.753,
      price_pc_1y: -50.758,
      price_pc_ytd: -50.758,
    },
    "0639": {
      id: "0639",
      c: 3.08,
      ma50: 2.531,
      ma100: 2.351,
      ma150: 2.373,
      ma200: 2.316,
      maw30: 2.374,
      rs: 92.0,
      rsd1d: -1.0,
      rsd5d: 2.0,
      rsd10d: 2.0,
      price_5d_h: 3.08,
      price_5d_L: 2.8,
      price_1m_h: 3.08,
      price_1m_l: 2.09,
      price_3m_h: 3.08,
      price_3m_l: 2.09,
      price_6m_h: 3.08,
      price_6m_l: 1.88,
      price_52w_h: 3.08,
      price_52w_l: 1.88,
      price_pc_1d: 3.01,
      price_pc_1w: 12.821,
      price_pc_1m: 19.38,
      price_pc_6m: 29.958,
      price_pc_1y: 58.763,
      price_pc_ytd: 58.763,
    },
    "0643": {
      id: "0643",
      c: 0.2,
      ma50: 0.187,
      ma100: 0.192,
      ma150: 0.188,
      ma200: 0.183,
      maw30: 0.188,
      rs: 88.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 7.0,
      price_5d_h: 0.21,
      price_5d_L: 0.199,
      price_1m_h: 0.21,
      price_1m_l: 0.151,
      price_3m_h: 0.228,
      price_3m_l: 0.151,
      price_6m_h: 0.236,
      price_6m_l: 0.151,
      price_52w_h: 0.28,
      price_52w_l: 0.12,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: 11.111,
      price_pc_6m: 12.994,
      price_pc_1y: 60.0,
      price_pc_ytd: 60.0,
    },
    "0650": {
      id: "0650",
      c: 1.29,
      ma50: 1.113,
      ma100: 1.167,
      ma150: 1.144,
      ma200: 1.123,
      maw30: 1.144,
      rs: 87.0,
      rsd1d: 7.0,
      rsd5d: 25.0,
      rsd10d: 37.0,
      price_5d_h: 1.3,
      price_5d_L: 1.0,
      price_1m_h: 1.3,
      price_1m_l: 0.76,
      price_3m_h: 1.36,
      price_3m_l: 0.76,
      price_6m_h: 1.67,
      price_6m_l: 0.76,
      price_52w_h: 1.67,
      price_52w_l: 0.66,
      price_pc_1d: 9.322,
      price_pc_1w: 21.698,
      price_pc_1m: 6.612,
      price_pc_6m: 31.633,
      price_pc_1y: 29.0,
      price_pc_ytd: 29.0,
    },
    "0653": {
      id: "0653",
      c: 0.137,
      ma50: 0.147,
      ma100: 0.166,
      ma150: 0.165,
      ma200: 0.18,
      maw30: 0.167,
      rs: 24.0,
      rsd1d: 3.0,
      rsd5d: 0.0,
      rsd10d: 6.0,
      price_5d_h: 0.148,
      price_5d_L: 0.135,
      price_1m_h: 0.158,
      price_1m_l: 0.115,
      price_3m_h: 0.183,
      price_3m_l: 0.115,
      price_6m_h: 0.213,
      price_6m_l: 0.115,
      price_52w_h: 0.385,
      price_52w_l: 0.115,
      price_pc_1d: 0.735,
      price_pc_1w: 0.0,
      price_pc_1m: -3.521,
      price_pc_6m: -13.291,
      price_pc_1y: -27.895,
      price_pc_ytd: -27.895,
    },
    "0656": {
      id: "0656",
      c: 8.53,
      ma50: 8.494,
      ma100: 8.578,
      ma150: 8.892,
      ma200: 9.362,
      maw30: 8.912,
      rs: 54.0,
      rsd1d: 0.0,
      rsd5d: -4.0,
      rsd10d: 6.0,
      price_5d_h: 8.92,
      price_5d_L: 8.43,
      price_1m_h: 8.96,
      price_1m_l: 6.5,
      price_3m_h: 9.34,
      price_3m_l: 6.5,
      price_6m_h: 9.6,
      price_6m_l: 6.5,
      price_52w_h: 13.42,
      price_52w_l: 6.5,
      price_pc_1d: -0.814,
      price_pc_1w: -2.067,
      price_pc_1m: 0.708,
      price_pc_6m: -9.926,
      price_pc_1y: -22.736,
      price_pc_ytd: -22.736,
    },
    "0657": {
      id: "0657",
      c: 0.059,
      ma50: 0.064,
      ma100: 0.064,
      ma150: 0.065,
      ma200: 0.07,
      maw30: 0.065,
      rs: 42.0,
      rsd1d: -2.0,
      rsd5d: 0.0,
      rsd10d: 2.0,
      price_5d_h: 0.062,
      price_5d_L: 0.053,
      price_1m_h: 0.073,
      price_1m_l: 0.053,
      price_3m_h: 0.074,
      price_3m_l: 0.05,
      price_6m_h: 0.084,
      price_6m_l: 0.05,
      price_52w_h: 0.13,
      price_52w_l: 0.042,
      price_pc_1d: -3.279,
      price_pc_1w: -4.839,
      price_pc_1m: -16.901,
      price_pc_6m: -13.235,
      price_pc_1y: -25.316,
      price_pc_ytd: -25.316,
    },
    "0658": {
      id: "0658",
      c: 4.75,
      ma50: 5.323,
      ma100: 5.679,
      ma150: 5.874,
      ma200: 5.66,
      maw30: 5.928,
      rs: 35.0,
      rsd1d: -2.0,
      rsd5d: 2.0,
      rsd10d: 1.0,
      price_5d_h: 4.91,
      price_5d_L: 4.37,
      price_1m_h: 6.15,
      price_1m_l: 4.08,
      price_3m_h: 6.15,
      price_3m_l: 4.08,
      price_6m_h: 7.3,
      price_6m_l: 4.08,
      price_52w_h: 7.79,
      price_52w_l: 4.08,
      price_pc_1d: -1.656,
      price_pc_1w: -1.042,
      price_pc_1m: -19.899,
      price_pc_6m: -27.37,
      price_pc_1y: -33.936,
      price_pc_ytd: -33.936,
    },
    "0659": {
      id: "0659",
      c: 7.2,
      ma50: 7.587,
      ma100: 7.508,
      ma150: 7.508,
      ma200: 7.618,
      maw30: 7.525,
      rs: 64.0,
      rsd1d: 2.0,
      rsd5d: -1.0,
      rsd10d: -9.0,
      price_5d_h: 7.33,
      price_5d_L: 7.14,
      price_1m_h: 7.67,
      price_1m_l: 6.88,
      price_3m_h: 8.13,
      price_3m_l: 6.88,
      price_6m_h: 8.13,
      price_6m_l: 6.88,
      price_52w_h: 9.16,
      price_52w_l: 6.77,
      price_pc_1d: -0.277,
      price_pc_1w: 0.0,
      price_pc_1m: -6.128,
      price_pc_6m: -0.139,
      price_pc_1y: -13.043,
      price_pc_ytd: -13.043,
    },
    "0660": {
      id: "0660",
      c: 0.043,
      ma50: 0.044,
      ma100: 0.05,
      ma150: 0.053,
      ma200: 0.057,
      maw30: 0.053,
      rs: 20.0,
      rsd1d: 1.0,
      rsd5d: 4.0,
      rsd10d: 11.0,
      price_5d_h: 0.05,
      price_5d_L: 0.041,
      price_1m_h: 0.05,
      price_1m_l: 0.03,
      price_3m_h: 0.06,
      price_3m_l: 0.03,
      price_6m_h: 0.067,
      price_6m_l: 0.03,
      price_52w_h: 0.117,
      price_52w_l: 0.03,
      price_pc_1d: 0.0,
      price_pc_1w: 4.878,
      price_pc_1m: 2.381,
      price_pc_6m: -20.37,
      price_pc_1y: -45.57,
      price_pc_ytd: -45.57,
    },
    "0661": {
      id: "0661",
      c: 0.086,
      ma50: 0.085,
      ma100: 0.084,
      ma150: 0.093,
      ma200: 0.1,
      maw30: 0.092,
      rs: 56.0,
      rsd1d: -7.0,
      rsd5d: 1.0,
      rsd10d: 9.0,
      price_5d_h: 0.09,
      price_5d_L: 0.083,
      price_1m_h: 0.109,
      price_1m_l: 0.074,
      price_3m_h: 0.109,
      price_3m_l: 0.074,
      price_6m_h: 0.122,
      price_6m_l: 0.074,
      price_52w_h: 0.181,
      price_52w_l: 0.074,
      price_pc_1d: -4.444,
      price_pc_1w: -1.149,
      price_pc_1m: -1.149,
      price_pc_6m: -19.626,
      price_pc_1y: -25.862,
      price_pc_ytd: -25.862,
    },
    "0662": {
      id: "0662",
      c: 3.6,
      ma50: 3.528,
      ma100: 3.562,
      ma150: 3.528,
      ma200: 3.541,
      maw30: 3.541,
      rs: 72.0,
      rsd1d: 6.0,
      rsd5d: 3.0,
      rsd10d: 5.0,
      price_5d_h: 3.6,
      price_5d_L: 3.43,
      price_1m_h: 3.65,
      price_1m_l: 3.2,
      price_3m_h: 3.65,
      price_3m_l: 3.2,
      price_6m_h: 3.65,
      price_6m_l: 3.2,
      price_52w_h: 3.95,
      price_52w_l: 3.2,
      price_pc_1d: 2.857,
      price_pc_1w: 2.857,
      price_pc_1m: 2.273,
      price_pc_6m: 10.092,
      price_pc_1y: -8.861,
      price_pc_ytd: -8.861,
    },
    "0663": {
      id: "0663",
      c: 0.249,
      ma50: 0.311,
      ma100: 0.367,
      ma150: 0.4,
      ma200: 0.455,
      maw30: 0.402,
      rs: 5.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: 0.0,
      price_5d_h: 0.255,
      price_5d_L: 0.24,
      price_1m_h: 0.32,
      price_1m_l: 0.195,
      price_3m_h: 0.45,
      price_3m_l: 0.195,
      price_6m_h: 0.54,
      price_6m_l: 0.048,
      price_52w_h: 1.66,
      price_52w_l: 0.048,
      price_pc_1d: -0.4,
      price_pc_1w: -0.4,
      price_pc_1m: -20.952,
      price_pc_6m: -44.667,
      price_pc_1y: -84.909,
      price_pc_ytd: -84.909,
    },
    "0665": {
      id: "0665",
      c: 1.41,
      ma50: 1.719,
      ma100: 1.705,
      ma150: 1.821,
      ma200: 1.904,
      maw30: 1.823,
      rs: 24.0,
      rsd1d: 1.0,
      rsd5d: -9.0,
      rsd10d: -29.0,
      price_5d_h: 1.54,
      price_5d_L: 1.4,
      price_1m_h: 1.75,
      price_1m_l: 1.4,
      price_3m_h: 1.91,
      price_3m_l: 1.4,
      price_6m_h: 2.06,
      price_6m_l: 1.4,
      price_52w_h: 2.65,
      price_52w_l: 1.4,
      price_pc_1d: -2.759,
      price_pc_1w: -7.843,
      price_pc_1m: -18.023,
      price_pc_6m: -31.553,
      price_pc_1y: -45.349,
      price_pc_ytd: -45.349,
    },
    "0667": {
      id: "0667",
      c: 4.57,
      ma50: 4.425,
      ma100: 5.215,
      ma150: 6.234,
      ma200: 7.267,
      maw30: 6.214,
      rs: 14.0,
      rsd1d: -5.0,
      rsd5d: 2.0,
      rsd10d: 4.0,
      price_5d_h: 5.28,
      price_5d_L: 4.28,
      price_1m_h: 5.28,
      price_1m_l: 3.16,
      price_3m_h: 5.28,
      price_3m_l: 3.16,
      price_6m_h: 10.24,
      price_6m_l: 3.16,
      price_52w_h: 19.5,
      price_52w_l: 3.16,
      price_pc_1d: -9.505,
      price_pc_1w: 0.883,
      price_pc_1m: 0.661,
      price_pc_6m: -43.088,
      price_pc_1y: -72.635,
      price_pc_ytd: -72.635,
    },
    "0669": {
      id: "0669",
      c: 126.9,
      ma50: 130.524,
      ma100: 144.894,
      ma150: 150.212,
      ma200: 148.156,
      maw30: 149.943,
      rs: 49.0,
      rsd1d: -2.0,
      rsd5d: -5.0,
      rsd10d: -7.0,
      price_5d_h: 130.8,
      price_5d_L: 121.2,
      price_1m_h: 141.6,
      price_1m_l: 112.1,
      price_3m_h: 162.2,
      price_3m_l: 112.1,
      price_6m_h: 174.5,
      price_6m_l: 112.1,
      price_52w_h: 180.0,
      price_52w_l: 112.1,
      price_pc_1d: -2.008,
      price_pc_1w: -2.385,
      price_pc_1m: -2.759,
      price_pc_6m: -18.182,
      price_pc_1y: -5.651,
      price_pc_ytd: -5.651,
    },
    "0670": {
      id: "0670",
      c: 2.64,
      ma50: 3.009,
      ma100: 2.981,
      ma150: 3.005,
      ma200: 3.018,
      maw30: 3.026,
      rs: 42.0,
      rsd1d: 1.0,
      rsd5d: -2.0,
      rsd10d: -21.0,
      price_5d_h: 2.7,
      price_5d_L: 2.57,
      price_1m_h: 3.13,
      price_1m_l: 2.53,
      price_3m_h: 3.38,
      price_3m_l: 2.53,
      price_6m_h: 3.38,
      price_6m_l: 2.53,
      price_52w_h: 3.8,
      price_52w_l: 2.53,
      price_pc_1d: -1.493,
      price_pc_1w: -1.493,
      price_pc_1m: -12.871,
      price_pc_6m: -11.706,
      price_pc_1y: -27.671,
      price_pc_ytd: -27.671,
    },
    "0672": {
      id: "0672",
      c: 0.295,
      ma50: 0.3,
      ma100: 0.302,
      ma150: 0.312,
      ma200: 0.326,
      maw30: 0.312,
      rs: 46.0,
      rsd1d: 1.0,
      rsd5d: 6.0,
      rsd10d: 4.0,
      price_5d_h: 0.295,
      price_5d_L: 0.275,
      price_1m_h: 0.315,
      price_1m_l: 0.248,
      price_3m_h: 0.325,
      price_3m_l: 0.248,
      price_6m_h: 0.345,
      price_6m_l: 0.248,
      price_52w_h: 0.425,
      price_52w_l: 0.248,
      price_pc_1d: 0.0,
      price_pc_1w: 1.724,
      price_pc_1m: -6.349,
      price_pc_6m: -10.606,
      price_pc_1y: -23.377,
      price_pc_ytd: -23.377,
    },
    "0673": {
      id: "0673",
      c: 0.051,
      ma50: 0.053,
      ma100: 0.057,
      ma150: 0.061,
      ma200: 0.064,
      maw30: 0.061,
      rs: 53.0,
      rsd1d: 8.0,
      rsd5d: 17.0,
      rsd10d: 7.0,
      price_5d_h: 0.052,
      price_5d_L: 0.047,
      price_1m_h: 0.072,
      price_1m_l: 0.043,
      price_3m_h: 0.072,
      price_3m_l: 0.043,
      price_6m_h: 0.074,
      price_6m_l: 0.043,
      price_52w_h: 0.088,
      price_52w_l: 0.043,
      price_pc_1d: 2.0,
      price_pc_1w: 2.0,
      price_pc_1m: 2.0,
      price_pc_6m: -27.143,
      price_pc_1y: -15.0,
      price_pc_ytd: -15.0,
    },
    "0675": {
      id: "0675",
      c: 0.88,
      ma50: 0.919,
      ma100: 0.9,
      ma150: 0.907,
      ma200: 0.89,
      maw30: 0.91,
      rs: 77.0,
      rsd1d: 0.0,
      rsd5d: -4.0,
      rsd10d: 4.0,
      price_5d_h: 0.91,
      price_5d_L: 0.87,
      price_1m_h: 0.93,
      price_1m_l: 0.84,
      price_3m_h: 1.02,
      price_3m_l: 0.84,
      price_6m_h: 1.02,
      price_6m_l: 0.82,
      price_52w_h: 1.05,
      price_52w_l: 0.75,
      price_pc_1d: 0.0,
      price_pc_1w: -2.222,
      price_pc_1m: -4.348,
      price_pc_6m: 1.149,
      price_pc_1y: -1.124,
      price_pc_ytd: -1.124,
    },
    "0677": {
      id: "0677",
      c: 0.46,
      ma50: 0.496,
      ma100: 0.505,
      ma150: 0.529,
      ma200: 0.54,
      maw30: 0.528,
      rs: 42.0,
      rsd1d: 1.0,
      rsd5d: 4.0,
      rsd10d: 0.0,
      price_5d_h: 0.48,
      price_5d_L: 0.45,
      price_1m_h: 0.52,
      price_1m_l: 0.44,
      price_3m_h: 0.53,
      price_3m_l: 0.44,
      price_6m_h: 0.63,
      price_6m_l: 0.44,
      price_52w_h: 0.7,
      price_52w_l: 0.44,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: -9.804,
      price_pc_6m: -20.69,
      price_pc_1y: -25.806,
      price_pc_ytd: -25.806,
    },
    "0679": {
      id: "0679",
      c: 1.07,
      ma50: 1.184,
      ma100: 1.222,
      ma150: 1.205,
      ma200: 1.198,
      maw30: 1.213,
      rs: 46.0,
      rsd1d: 5.0,
      rsd5d: -8.0,
      rsd10d: -20.0,
      price_5d_h: 1.16,
      price_5d_L: 1.02,
      price_1m_h: 1.23,
      price_1m_l: 1.01,
      price_3m_h: 1.27,
      price_3m_l: 1.01,
      price_6m_h: 1.31,
      price_6m_l: 1.01,
      price_52w_h: 1.38,
      price_52w_l: 1.01,
      price_pc_1d: 0.0,
      price_pc_1w: -7.759,
      price_pc_1m: -10.833,
      price_pc_6m: -4.464,
      price_pc_1y: -23.022,
      price_pc_ytd: -23.022,
    },
    "0680": {
      id: "0680",
      c: 0.035,
      ma50: 0.037,
      ma100: 0.037,
      ma150: 0.039,
      ma200: 0.043,
      maw30: 0.039,
      rs: 38.0,
      rsd1d: -6.0,
      rsd5d: 2.0,
      rsd10d: 0.0,
      price_5d_h: 0.037,
      price_5d_L: 0.033,
      price_1m_h: 0.039,
      price_1m_l: 0.033,
      price_3m_h: 0.044,
      price_3m_l: 0.033,
      price_6m_h: 0.045,
      price_6m_l: 0.033,
      price_52w_h: 0.065,
      price_52w_l: 0.033,
      price_pc_1d: -5.405,
      price_pc_1w: 0.0,
      price_pc_1m: 0.0,
      price_pc_6m: -18.605,
      price_pc_1y: -33.962,
      price_pc_ytd: -33.962,
    },
    "0681": {
      id: "0681",
      c: 0.043,
      ma50: 0.046,
      ma100: 0.048,
      ma150: 0.051,
      ma200: 0.051,
      maw30: 0.051,
      rs: 37.0,
      rsd1d: 3.0,
      rsd5d: -2.0,
      rsd10d: 0.0,
      price_5d_h: 0.043,
      price_5d_L: 0.041,
      price_1m_h: 0.051,
      price_1m_l: 0.039,
      price_3m_h: 0.054,
      price_3m_l: 0.039,
      price_6m_h: 0.059,
      price_6m_l: 0.039,
      price_52w_h: 0.074,
      price_52w_l: 0.039,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: -6.522,
      price_pc_6m: -25.862,
      price_pc_1y: -24.561,
      price_pc_ytd: -24.561,
    },
    "0682": {
      id: "0682",
      c: 0.049,
      ma50: 0.045,
      ma100: 0.043,
      ma150: 0.044,
      ma200: 0.046,
      maw30: 0.044,
      rs: 74.0,
      rsd1d: -2.0,
      rsd5d: 21.0,
      rsd10d: 12.0,
      price_5d_h: 0.052,
      price_5d_L: 0.045,
      price_1m_h: 0.052,
      price_1m_l: 0.041,
      price_3m_h: 0.052,
      price_3m_l: 0.039,
      price_6m_h: 0.052,
      price_6m_l: 0.037,
      price_52w_h: 0.086,
      price_52w_l: 0.037,
      price_pc_1d: 0.0,
      price_pc_1w: 11.364,
      price_pc_1m: 8.889,
      price_pc_6m: 4.255,
      price_pc_1y: -43.023,
      price_pc_ytd: -43.023,
    },
    "0683": {
      id: "0683",
      c: 22.15,
      ma50: 21.945,
      ma100: 21.248,
      ma150: 21.878,
      ma200: 22.53,
      maw30: 21.828,
      rs: 74.0,
      rsd1d: -1.0,
      rsd5d: 6.0,
      rsd10d: 2.0,
      price_5d_h: 22.65,
      price_5d_L: 20.85,
      price_1m_h: 22.65,
      price_1m_l: 18.74,
      price_3m_h: 24.0,
      price_3m_l: 18.74,
      price_6m_h: 24.0,
      price_6m_l: 18.74,
      price_52w_h: 27.5,
      price_52w_l: 18.74,
      price_pc_1d: -0.449,
      price_pc_1w: 4.235,
      price_pc_1m: 3.505,
      price_pc_6m: 7.786,
      price_pc_1y: -11.044,
      price_pc_ytd: -11.044,
    },
    "0684": {
      id: "0684",
      c: 1.57,
      ma50: 1.627,
      ma100: 1.646,
      ma150: 1.687,
      ma200: 1.72,
      maw30: 1.693,
      rs: 60.0,
      rsd1d: 4.0,
      rsd5d: -6.0,
      rsd10d: -7.0,
      price_5d_h: 1.6,
      price_5d_L: 1.55,
      price_1m_h: 1.64,
      price_1m_l: 1.38,
      price_3m_h: 1.71,
      price_3m_l: 1.38,
      price_6m_h: 1.75,
      price_6m_l: 1.38,
      price_52w_h: 1.97,
      price_52w_l: 1.38,
      price_pc_1d: 1.29,
      price_pc_1w: -1.875,
      price_pc_1m: -4.268,
      price_pc_6m: -10.286,
      price_pc_1y: -1.258,
      price_pc_ytd: -1.258,
    },
    "0686": {
      id: "0686",
      c: 0.25,
      ma50: 0.263,
      ma100: 0.267,
      ma150: 0.278,
      ma200: 0.275,
      maw30: 0.28,
      rs: 51.0,
      rsd1d: 4.0,
      rsd5d: -1.0,
      rsd10d: -11.0,
      price_5d_h: 0.255,
      price_5d_L: 0.245,
      price_1m_h: 0.275,
      price_1m_l: 0.24,
      price_3m_h: 0.295,
      price_3m_l: 0.24,
      price_6m_h: 0.33,
      price_6m_l: 0.24,
      price_52w_h: 0.36,
      price_52w_l: 0.24,
      price_pc_1d: 0.0,
      price_pc_1w: -1.961,
      price_pc_1m: -3.846,
      price_pc_6m: -18.033,
      price_pc_1y: -1.961,
      price_pc_ytd: -1.961,
    },
    "0687": {
      id: "0687",
      c: 0.38,
      ma50: 0.375,
      ma100: 0.382,
      ma150: 0.391,
      ma200: 0.411,
      maw30: 0.39,
      rs: 58.0,
      rsd1d: 2.0,
      rsd5d: 3.0,
      rsd10d: 16.0,
      price_5d_h: 0.395,
      price_5d_L: 0.335,
      price_1m_h: 0.395,
      price_1m_l: 0.31,
      price_3m_h: 0.41,
      price_3m_l: 0.31,
      price_6m_h: 0.465,
      price_6m_l: 0.31,
      price_52w_h: 0.56,
      price_52w_l: 0.31,
      price_pc_1d: 0.0,
      price_pc_1w: -1.299,
      price_pc_1m: 4.11,
      price_pc_6m: -5.0,
      price_pc_1y: -16.484,
      price_pc_ytd: -16.484,
    },
    "0688": {
      id: "0688",
      c: 23.45,
      ma50: 23.597,
      ma100: 21.349,
      ma150: 20.052,
      ma200: 19.538,
      maw30: 20.174,
      rs: 91.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: -1.0,
      price_5d_h: 24.7,
      price_5d_L: 23.05,
      price_1m_h: 25.4,
      price_1m_l: 19.18,
      price_3m_h: 25.65,
      price_3m_l: 18.16,
      price_6m_h: 25.65,
      price_6m_l: 16.1,
      price_52w_h: 25.65,
      price_52w_l: 15.42,
      price_pc_1d: -1.883,
      price_pc_1w: -0.636,
      price_pc_1m: -1.677,
      price_pc_6m: 32.187,
      price_pc_1y: 16.089,
      price_pc_ytd: 16.089,
    },
    "0689": {
      id: "0689",
      c: 0.057,
      ma50: 0.044,
      ma100: 0.037,
      ma150: 0.048,
      ma200: 0.052,
      maw30: 0.048,
      rs: 95.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: 2.0,
      price_5d_h: 0.072,
      price_5d_L: 0.054,
      price_1m_h: 0.072,
      price_1m_l: 0.04,
      price_3m_h: 0.072,
      price_3m_l: 0.019,
      price_6m_h: 0.093,
      price_6m_l: 0.016,
      price_52w_h: 0.105,
      price_52w_l: 0.016,
      price_pc_1d: 0.0,
      price_pc_1w: 5.556,
      price_pc_1m: 35.714,
      price_pc_6m: -21.918,
      price_pc_1y: -35.227,
      price_pc_ytd: -35.227,
    },
    "0690": {
      id: "0690",
      c: 0.08,
      ma50: 0.086,
      ma100: 0.089,
      ma150: 0.091,
      ma200: 0.095,
      maw30: 0.092,
      rs: 49.0,
      rsd1d: 6.0,
      rsd5d: -12.0,
      rsd10d: 16.0,
      price_5d_h: 0.09,
      price_5d_L: 0.075,
      price_1m_h: 0.095,
      price_1m_l: 0.07,
      price_3m_h: 0.108,
      price_3m_l: 0.07,
      price_6m_h: 0.108,
      price_6m_l: 0.07,
      price_52w_h: 0.124,
      price_52w_l: 0.07,
      price_pc_1d: 1.266,
      price_pc_1w: -10.112,
      price_pc_1m: -9.091,
      price_pc_6m: -13.978,
      price_pc_1y: -20.0,
      price_pc_ytd: -20.0,
    },
    "0691": {
      id: "0691",
      c: 2.35,
      ma50: 2.098,
      ma100: 2.042,
      ma150: 2.072,
      ma200: 2.114,
      maw30: 2.057,
      rs: 89.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 8.0,
      price_5d_h: 2.44,
      price_5d_L: 2.23,
      price_1m_h: 2.45,
      price_1m_l: 1.71,
      price_3m_h: 2.45,
      price_3m_l: 1.71,
      price_6m_h: 2.45,
      price_6m_l: 1.71,
      price_52w_h: 2.49,
      price_52w_l: 1.71,
      price_pc_1d: -0.844,
      price_pc_1w: -1.674,
      price_pc_1m: 11.905,
      price_pc_6m: 14.634,
      price_pc_1y: 14.634,
      price_pc_ytd: 14.634,
    },
    "0694": {
      id: "0694",
      c: 4.59,
      ma50: 4.937,
      ma100: 4.896,
      ma150: 4.854,
      ma200: 4.832,
      maw30: 4.9,
      rs: 58.0,
      rsd1d: 0.0,
      rsd5d: 9.0,
      rsd10d: 8.0,
      price_5d_h: 4.67,
      price_5d_L: 4.34,
      price_1m_h: 5.23,
      price_1m_l: 3.8,
      price_3m_h: 5.67,
      price_3m_l: 3.8,
      price_6m_h: 5.67,
      price_6m_l: 3.8,
      price_52w_h: 6.26,
      price_52w_l: 3.8,
      price_pc_1d: -1.078,
      price_pc_1w: 4.082,
      price_pc_1m: -8.2,
      price_pc_6m: 0.0,
      price_pc_1y: -24.132,
      price_pc_ytd: -24.132,
    },
    "0695": {
      id: "0695",
      c: 4.73,
      ma50: 4.665,
      ma100: 4.618,
      ma150: 4.806,
      ma200: 4.651,
      maw30: 4.765,
      rs: 94.0,
      rsd1d: -1.0,
      rsd5d: -2.0,
      rsd10d: -2.0,
      price_5d_h: 4.97,
      price_5d_L: 4.56,
      price_1m_h: 5.47,
      price_1m_l: 4.49,
      price_3m_h: 5.47,
      price_3m_l: 3.83,
      price_6m_h: 5.6,
      price_6m_l: 3.83,
      price_52w_h: 6.98,
      price_52w_l: 1.69,
      price_pc_1d: -1.253,
      price_pc_1w: -0.421,
      price_pc_1m: 4.646,
      price_pc_6m: -4.444,
      price_pc_1y: 120.0,
      price_pc_ytd: 120.0,
    },
    "0696": {
      id: "0696",
      c: 11.3,
      ma50: 13.908,
      ma100: 13.645,
      ma150: 14.023,
      ma200: 14.13,
      maw30: 14.078,
      rs: 28.0,
      rsd1d: -1.0,
      rsd5d: -15.0,
      rsd10d: -14.0,
      price_5d_h: 12.8,
      price_5d_L: 11.16,
      price_1m_h: 15.36,
      price_1m_l: 10.4,
      price_3m_h: 16.6,
      price_3m_l: 10.4,
      price_6m_h: 16.76,
      price_6m_l: 10.4,
      price_52w_h: 19.42,
      price_52w_l: 10.4,
      price_pc_1d: -2.921,
      price_pc_1w: -11.719,
      price_pc_1m: -23.234,
      price_pc_6m: -24.667,
      price_pc_1y: -39.313,
      price_pc_ytd: -39.313,
    },
    "0697": {
      id: "0697",
      c: 1.16,
      ma50: 1.298,
      ma100: 1.479,
      ma150: 1.54,
      ma200: 1.594,
      maw30: 1.545,
      rs: 21.0,
      rsd1d: 0.0,
      rsd5d: -3.0,
      rsd10d: 4.0,
      price_5d_h: 1.27,
      price_5d_L: 1.15,
      price_1m_h: 1.29,
      price_1m_l: 0.91,
      price_3m_h: 1.62,
      price_3m_l: 0.91,
      price_6m_h: 1.97,
      price_6m_l: 0.91,
      price_52w_h: 1.97,
      price_52w_l: 0.91,
      price_pc_1d: -4.132,
      price_pc_1w: -7.2,
      price_pc_1m: -10.078,
      price_pc_6m: -28.834,
      price_pc_1y: -37.297,
      price_pc_ytd: -37.297,
    },
    "0698": {
      id: "0698",
      c: 0.165,
      ma50: 0.174,
      ma100: 0.215,
      ma150: 0.23,
      ma200: 0.267,
      maw30: 0.23,
      rs: 10.0,
      rsd1d: 4.0,
      rsd5d: 2.0,
      rsd10d: 7.0,
      price_5d_h: 0.174,
      price_5d_L: 0.145,
      price_1m_h: 0.189,
      price_1m_l: 0.108,
      price_3m_h: 0.255,
      price_3m_l: 0.108,
      price_6m_h: 0.31,
      price_6m_l: 0.108,
      price_52w_h: 0.66,
      price_52w_l: 0.108,
      price_pc_1d: 10.738,
      price_pc_1w: -1.786,
      price_pc_1m: 8.553,
      price_pc_6m: -32.653,
      price_pc_1y: -70.0,
      price_pc_ytd: -70.0,
    },
    "0700": {
      id: "0700",
      c: 374.2,
      ma50: 424.046,
      ma100: 443.476,
      ma150: 455.316,
      ma200: 471.314,
      maw30: 457.286,
      rs: 27.0,
      rsd1d: 0.0,
      rsd5d: 5.0,
      rsd10d: -7.0,
      price_5d_h: 388.6,
      price_5d_L: 351.0,
      price_1m_h: 439.0,
      price_1m_l: 297.0,
      price_3m_h: 488.0,
      price_3m_l: 297.0,
      price_6m_h: 515.0,
      price_6m_l: 297.0,
      price_52w_h: 658.0,
      price_52w_l: 297.0,
      price_pc_1d: -1.682,
      price_pc_1w: 2.24,
      price_pc_1m: -11.159,
      price_pc_6m: -18.899,
      price_pc_1y: -42.783,
      price_pc_ytd: -42.783,
    },
    "0702": {
      id: "0702",
      c: 0.134,
      ma50: 0.164,
      ma100: 0.169,
      ma150: 0.192,
      ma200: 0.211,
      maw30: 0.193,
      rs: 14.0,
      rsd1d: 1.0,
      rsd5d: -13.0,
      rsd10d: -13.0,
      price_5d_h: 0.152,
      price_5d_L: 0.128,
      price_1m_h: 0.198,
      price_1m_l: 0.128,
      price_3m_h: 0.36,
      price_3m_l: 0.128,
      price_6m_h: 0.36,
      price_6m_l: 0.12,
      price_52w_h: 0.46,
      price_52w_l: 0.12,
      price_pc_1d: -0.741,
      price_pc_1w: -8.219,
      price_pc_1m: -26.776,
      price_pc_6m: -42.489,
      price_pc_1y: -53.793,
      price_pc_ytd: -53.793,
    },
    "0707": {
      id: "0707",
      c: 0.012,
      ma50: 0.016,
      ma100: 0.02,
      ma150: 0.024,
      ma200: 0.028,
      maw30: 0.023,
      rs: 6.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: 1.0,
      price_5d_h: 0.013,
      price_5d_L: 0.012,
      price_1m_h: 0.019,
      price_1m_l: 0.011,
      price_3m_h: 0.026,
      price_3m_l: 0.011,
      price_6m_h: 0.029,
      price_6m_l: 0.011,
      price_52w_h: 0.053,
      price_52w_l: 0.011,
      price_pc_1d: -7.692,
      price_pc_1w: -7.692,
      price_pc_1m: -25.0,
      price_pc_6m: -52.0,
      price_pc_1y: -68.421,
      price_pc_ytd: -68.421,
    },
    "0708": {
      id: "0708",
      c: 3.2,
      ma50: 3.472,
      ma100: 3.467,
      ma150: 3.723,
      ma200: 7.614,
      maw30: 3.624,
      rs: 14.0,
      rsd1d: 0.0,
      rsd5d: -29.0,
      rsd10d: -14.0,
      price_5d_h: 3.52,
      price_5d_L: 3.01,
      price_1m_h: 3.56,
      price_1m_l: 2.56,
      price_3m_h: 4.22,
      price_3m_l: 2.56,
      price_6m_h: 5.13,
      price_6m_l: 2.18,
      price_52w_h: 70.85,
      price_52w_l: 1.66,
      price_pc_1d: 1.911,
      price_pc_1w: -9.091,
      price_pc_1m: -5.882,
      price_pc_6m: 5.96,
      price_pc_1y: -94.797,
      price_pc_ytd: -94.797,
    },
    "0709": {
      id: "0709",
      c: 1.59,
      ma50: 1.588,
      ma100: 1.544,
      ma150: 1.546,
      ma200: 1.563,
      maw30: 1.544,
      rs: 79.0,
      rsd1d: -1.0,
      rsd5d: -4.0,
      rsd10d: 1.0,
      price_5d_h: 1.63,
      price_5d_L: 1.57,
      price_1m_h: 1.63,
      price_1m_l: 1.44,
      price_3m_h: 1.69,
      price_3m_l: 1.44,
      price_6m_h: 1.69,
      price_6m_l: 1.4,
      price_52w_h: 1.91,
      price_52w_l: 1.4,
      price_pc_1d: 0.0,
      price_pc_1w: -1.242,
      price_pc_1m: 0.633,
      price_pc_6m: 4.605,
      price_pc_1y: 2.581,
      price_pc_ytd: 2.581,
    },
    "0710": {
      id: "0710",
      c: 9.29,
      ma50: 8.897,
      ma100: 9.382,
      ma150: 9.076,
      ma200: 8.717,
      maw30: 9.161,
      rs: 94.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: 2.0,
      price_5d_h: 9.42,
      price_5d_L: 8.3,
      price_1m_h: 9.6,
      price_1m_l: 5.5,
      price_3m_h: 12.58,
      price_3m_l: 5.5,
      price_6m_h: 12.58,
      price_6m_l: 5.5,
      price_52w_h: 12.8,
      price_52w_l: 3.33,
      price_pc_1d: 2.313,
      price_pc_1w: 7.151,
      price_pc_1m: 2.088,
      price_pc_6m: 24.866,
      price_pc_1y: 171.637,
      price_pc_ytd: 171.637,
    },
    "0711": {
      id: "0711",
      c: 0.6,
      ma50: 0.598,
      ma100: 0.601,
      ma150: 0.6,
      ma200: 0.598,
      maw30: 0.6,
      rs: 75.0,
      rsd1d: 4.0,
      rsd5d: 3.0,
      rsd10d: -1.0,
      price_5d_h: 0.6,
      price_5d_L: 0.58,
      price_1m_h: 0.6,
      price_1m_l: 0.58,
      price_3m_h: 0.61,
      price_3m_l: 0.58,
      price_6m_h: 0.64,
      price_6m_l: 0.57,
      price_52w_h: 0.65,
      price_52w_l: 0.53,
      price_pc_1d: 1.695,
      price_pc_1w: 0.0,
      price_pc_1m: 0.0,
      price_pc_6m: 0.0,
      price_pc_1y: 0.0,
      price_pc_ytd: 0.0,
    },
    "0712": {
      id: "0712",
      c: 0.29,
      ma50: 0.309,
      ma100: 0.352,
      ma150: 0.4,
      ma200: 0.44,
      maw30: 0.399,
      rs: 24.0,
      rsd1d: 0.0,
      rsd5d: -6.0,
      rsd10d: 0.0,
      price_5d_h: 0.315,
      price_5d_L: 0.285,
      price_1m_h: 0.36,
      price_1m_l: 0.255,
      price_3m_h: 0.38,
      price_3m_l: 0.255,
      price_6m_h: 0.51,
      price_6m_l: 0.255,
      price_52w_h: 0.76,
      price_52w_l: 0.255,
      price_pc_1d: -4.918,
      price_pc_1w: -7.937,
      price_pc_1m: -3.333,
      price_pc_6m: -41.414,
      price_pc_1y: -18.31,
      price_pc_ytd: -18.31,
    },
    "0713": {
      id: "0713",
      c: 0.89,
      ma50: 0.913,
      ma100: 0.897,
      ma150: 0.853,
      ma200: 0.851,
      maw30: 0.86,
      rs: 83.0,
      rsd1d: 1.0,
      rsd5d: -2.0,
      rsd10d: 0.0,
      price_5d_h: 0.93,
      price_5d_L: 0.84,
      price_1m_h: 0.95,
      price_1m_l: 0.74,
      price_3m_h: 0.99,
      price_3m_l: 0.74,
      price_6m_h: 1.21,
      price_6m_l: 0.63,
      price_52w_h: 1.21,
      price_52w_l: 0.58,
      price_pc_1d: 1.136,
      price_pc_1w: -2.198,
      price_pc_1m: -3.261,
      price_pc_6m: 36.923,
      price_pc_1y: -3.261,
      price_pc_ytd: -3.261,
    },
    "0715": {
      id: "0715",
      c: 0.043,
      ma50: 0.093,
      ma100: 0.116,
      ma150: 0.122,
      ma200: 0.132,
      maw30: 0.123,
      rs: 2.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -1.0,
      price_5d_h: 0.05,
      price_5d_L: 0.04,
      price_1m_h: 0.094,
      price_1m_l: 0.04,
      price_3m_h: 0.178,
      price_3m_l: 0.04,
      price_6m_h: 0.178,
      price_6m_l: 0.04,
      price_52w_h: 0.202,
      price_52w_l: 0.04,
      price_pc_1d: -2.273,
      price_pc_1w: -14.0,
      price_pc_1m: -53.763,
      price_pc_6m: -66.923,
      price_pc_1y: -72.258,
      price_pc_ytd: -72.258,
    },
    "0716": {
      id: "0716",
      c: 1.15,
      ma50: 1.11,
      ma100: 1.1,
      ma150: 1.07,
      ma200: 1.044,
      maw30: 1.072,
      rs: 92.0,
      rsd1d: -1.0,
      rsd5d: -2.0,
      rsd10d: 0.0,
      price_5d_h: 1.2,
      price_5d_L: 1.09,
      price_1m_h: 1.2,
      price_1m_l: 0.93,
      price_3m_h: 1.3,
      price_3m_l: 0.93,
      price_6m_h: 1.42,
      price_6m_l: 0.81,
      price_52w_h: 1.42,
      price_52w_l: 0.61,
      price_pc_1d: -2.542,
      price_pc_1w: -0.862,
      price_pc_1m: 5.505,
      price_pc_6m: 26.374,
      price_pc_1y: 82.54,
      price_pc_ytd: 82.54,
    },
    "0717": {
      id: "0717",
      c: 0.063,
      ma50: 0.07,
      ma100: 0.081,
      ma150: 0.094,
      ma200: 0.101,
      maw30: 0.094,
      rs: 14.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: -1.0,
      price_5d_h: 0.065,
      price_5d_L: 0.057,
      price_1m_h: 0.071,
      price_1m_l: 0.053,
      price_3m_h: 0.085,
      price_3m_l: 0.053,
      price_6m_h: 0.127,
      price_6m_l: 0.053,
      price_52w_h: 0.151,
      price_52w_l: 0.053,
      price_pc_1d: -1.563,
      price_pc_1w: -1.563,
      price_pc_1m: -10.0,
      price_pc_6m: -45.69,
      price_pc_1y: -55.319,
      price_pc_ytd: -55.319,
    },
    "0719": {
      id: "0719",
      c: 4.08,
      ma50: 4.324,
      ma100: 4.352,
      ma150: 4.192,
      ma200: 4.127,
      maw30: 4.186,
      rs: 66.0,
      rsd1d: -1.0,
      rsd5d: 2.0,
      rsd10d: -2.0,
      price_5d_h: 4.48,
      price_5d_L: 4.04,
      price_1m_h: 4.6,
      price_1m_l: 3.67,
      price_3m_h: 5.12,
      price_3m_l: 3.67,
      price_6m_h: 5.56,
      price_6m_l: 3.67,
      price_52w_h: 5.56,
      price_52w_l: 3.61,
      price_pc_1d: -2.857,
      price_pc_1w: -0.244,
      price_pc_1m: -8.315,
      price_pc_6m: 5.974,
      price_pc_1y: -8.725,
      price_pc_ytd: -8.725,
    },
    "0721": {
      id: "0721",
      c: 0.089,
      ma50: 0.1,
      ma100: 0.103,
      ma150: 0.11,
      ma200: 0.113,
      maw30: 0.111,
      rs: 29.0,
      rsd1d: 4.0,
      rsd5d: -4.0,
      rsd10d: -7.0,
      price_5d_h: 0.103,
      price_5d_L: 0.082,
      price_1m_h: 0.108,
      price_1m_l: 0.076,
      price_3m_h: 0.119,
      price_3m_l: 0.076,
      price_6m_h: 0.132,
      price_6m_l: 0.076,
      price_52w_h: 0.145,
      price_52w_l: 0.076,
      price_pc_1d: 2.299,
      price_pc_1w: -8.247,
      price_pc_1m: -18.349,
      price_pc_6m: -31.008,
      price_pc_1y: -34.074,
      price_pc_ytd: -34.074,
    },
    "0722": {
      id: "0722",
      c: 0.8,
      ma50: 0.825,
      ma100: 0.825,
      ma150: 0.832,
      ma200: 0.857,
      maw30: 0.832,
      rs: 61.0,
      rsd1d: 4.0,
      rsd5d: -6.0,
      rsd10d: 3.0,
      price_5d_h: 0.8,
      price_5d_L: 0.79,
      price_1m_h: 0.9,
      price_1m_l: 0.73,
      price_3m_h: 0.93,
      price_3m_l: 0.73,
      price_6m_h: 0.97,
      price_6m_l: 0.73,
      price_52w_h: 1.24,
      price_52w_l: 0.65,
      price_pc_1d: 1.266,
      price_pc_1w: -2.439,
      price_pc_1m: -8.046,
      price_pc_6m: -2.439,
      price_pc_1y: -1.235,
      price_pc_ytd: -1.235,
    },
    "0723": {
      id: "0723",
      c: 0.031,
      ma50: 0.026,
      ma100: 0.023,
      ma150: 0.023,
      ma200: 0.024,
      maw30: 0.023,
      rs: 87.0,
      rsd1d: -4.0,
      rsd5d: -9.0,
      rsd10d: 15.0,
      price_5d_h: 0.04,
      price_5d_L: 0.029,
      price_1m_h: 0.04,
      price_1m_l: 0.019,
      price_3m_h: 0.04,
      price_3m_l: 0.019,
      price_6m_h: 0.04,
      price_6m_l: 0.015,
      price_52w_h: 0.064,
      price_52w_l: 0.015,
      price_pc_1d: -3.125,
      price_pc_1w: -18.421,
      price_pc_1m: 34.783,
      price_pc_6m: 47.619,
      price_pc_1y: 29.167,
      price_pc_ytd: 29.167,
    },
    "0724": {
      id: "0724",
      c: 0.028,
      ma50: 0.031,
      ma100: 0.035,
      ma150: 0.038,
      ma200: 0.041,
      maw30: 0.038,
      rs: 20.0,
      rsd1d: 3.0,
      rsd5d: 4.0,
      rsd10d: 5.0,
      price_5d_h: 0.038,
      price_5d_L: 0.025,
      price_1m_h: 0.038,
      price_1m_l: 0.023,
      price_3m_h: 0.041,
      price_3m_l: 0.023,
      price_6m_h: 0.052,
      price_6m_l: 0.023,
      price_52w_h: 0.076,
      price_52w_l: 0.023,
      price_pc_1d: 0.0,
      price_pc_1w: 3.704,
      price_pc_1m: -15.152,
      price_pc_6m: -33.333,
      price_pc_1y: -33.333,
      price_pc_ytd: -33.333,
    },
    "0726": {
      id: "0726",
      c: 0.67,
      ma50: 0.734,
      ma100: 0.772,
      ma150: 0.795,
      ma200: 0.796,
      maw30: 0.793,
      rs: 41.0,
      rsd1d: 1.0,
      rsd5d: 5.0,
      rsd10d: 9.0,
      price_5d_h: 0.68,
      price_5d_L: 0.61,
      price_1m_h: 0.74,
      price_1m_l: 0.56,
      price_3m_h: 0.9,
      price_3m_l: 0.56,
      price_6m_h: 0.9,
      price_6m_l: 0.56,
      price_52w_h: 1.08,
      price_52w_l: 0.56,
      price_pc_1d: 0.0,
      price_pc_1w: -1.471,
      price_pc_1m: -8.219,
      price_pc_6m: -17.284,
      price_pc_1y: -30.208,
      price_pc_ytd: -30.208,
    },
    "0727": {
      id: "0727",
      c: 0.109,
      ma50: 0.123,
      ma100: 0.13,
      ma150: 0.139,
      ma200: 0.142,
      maw30: 0.139,
      rs: 17.0,
      rsd1d: 4.0,
      rsd5d: 1.0,
      rsd10d: 6.0,
      price_5d_h: 0.12,
      price_5d_L: 0.109,
      price_1m_h: 0.127,
      price_1m_l: 0.109,
      price_3m_h: 0.141,
      price_3m_l: 0.105,
      price_6m_h: 0.185,
      price_6m_l: 0.105,
      price_52w_h: 0.47,
      price_52w_l: 0.105,
      price_pc_1d: -1.802,
      price_pc_1w: -0.909,
      price_pc_1m: -19.259,
      price_pc_6m: -42.632,
      price_pc_1y: -76.559,
      price_pc_ytd: -76.559,
    },
    "0728": {
      id: "0728",
      c: 3.1,
      ma50: 3.009,
      ma100: 2.819,
      ma150: 2.779,
      ma200: 2.808,
      maw30: 2.78,
      rs: 88.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: -3.0,
      price_5d_h: 3.2,
      price_5d_L: 3.05,
      price_1m_h: 3.22,
      price_1m_l: 2.72,
      price_3m_h: 3.22,
      price_3m_l: 2.57,
      price_6m_h: 3.22,
      price_6m_l: 2.49,
      price_52w_h: 3.24,
      price_52w_l: 2.3,
      price_pc_1d: 0.977,
      price_pc_1w: -1.274,
      price_pc_1m: 3.333,
      price_pc_6m: 19.691,
      price_pc_1y: 13.971,
      price_pc_ytd: 13.971,
    },
    "0730": {
      id: "0730",
      c: 0.157,
      ma50: 0.141,
      ma100: 0.149,
      ma150: 0.146,
      ma200: 0.145,
      maw30: 0.146,
      rs: 71.0,
      rsd1d: 0.0,
      rsd5d: 11.0,
      rsd10d: 40.0,
      price_5d_h: 0.157,
      price_5d_L: 0.134,
      price_1m_h: 0.157,
      price_1m_l: 0.111,
      price_3m_h: 0.168,
      price_3m_l: 0.111,
      price_6m_h: 0.184,
      price_6m_l: 0.111,
      price_52w_h: 0.194,
      price_52w_l: 0.111,
      price_pc_1d: 1.948,
      price_pc_1w: 6.803,
      price_pc_1m: 13.768,
      price_pc_6m: 12.143,
      price_pc_1y: -14.208,
      price_pc_ytd: -14.208,
    },
    "0731": {
      id: "0731",
      c: 0.43,
      ma50: 0.978,
      ma100: 0.803,
      ma150: 0.657,
      ma200: 0.584,
      maw30: 0.682,
      rs: 89.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -1.0,
      price_5d_h: 0.45,
      price_5d_L: 0.39,
      price_1m_h: 0.5,
      price_1m_l: 0.39,
      price_3m_h: 3.65,
      price_3m_l: 0.365,
      price_6m_h: 3.65,
      price_6m_l: 0.365,
      price_52w_h: 3.65,
      price_52w_l: 0.365,
      price_pc_1d: 1.176,
      price_pc_1w: 0.0,
      price_pc_1m: -8.511,
      price_pc_6m: 17.808,
      price_pc_1y: 17.808,
      price_pc_ytd: 17.808,
    },
    "0732": {
      id: "0732",
      c: 2.23,
      ma50: 2.733,
      ma100: 2.726,
      ma150: 2.643,
      ma200: 2.588,
      maw30: 2.659,
      rs: 80.0,
      rsd1d: -1.0,
      rsd5d: -10.0,
      rsd10d: -5.0,
      price_5d_h: 2.55,
      price_5d_L: 2.18,
      price_1m_h: 2.74,
      price_1m_l: 1.77,
      price_3m_h: 3.77,
      price_3m_l: 1.77,
      price_6m_h: 3.77,
      price_6m_l: 1.77,
      price_52w_h: 3.77,
      price_52w_l: 1.05,
      price_pc_1d: -3.043,
      price_pc_1w: -9.717,
      price_pc_1m: -17.407,
      price_pc_6m: -0.889,
      price_pc_1y: 74.219,
      price_pc_ytd: 74.219,
    },
    "0733": {
      id: "0733",
      c: 1.14,
      ma50: 1.319,
      ma100: 1.485,
      ma150: 1.885,
      ma200: 2.152,
      maw30: 1.872,
      rs: 13.0,
      rsd1d: 2.0,
      rsd5d: 0.0,
      rsd10d: -6.0,
      price_5d_h: 1.29,
      price_5d_L: 1.12,
      price_1m_h: 1.43,
      price_1m_l: 1.12,
      price_3m_h: 1.59,
      price_3m_l: 1.12,
      price_6m_h: 3.1,
      price_6m_l: 1.12,
      price_52w_h: 3.9,
      price_52w_l: 1.12,
      price_pc_1d: 0.885,
      price_pc_1w: -10.236,
      price_pc_1m: -20.28,
      price_pc_6m: -60.69,
      price_pc_1y: -48.182,
      price_pc_ytd: -48.182,
    },
    "0737": {
      id: "0737",
      c: 2.79,
      ma50: 2.815,
      ma100: 2.8,
      ma150: 2.823,
      ma200: 2.848,
      maw30: 2.821,
      rs: 71.0,
      rsd1d: 0.0,
      rsd5d: 2.0,
      rsd10d: 3.0,
      price_5d_h: 2.86,
      price_5d_L: 2.77,
      price_1m_h: 2.9,
      price_1m_l: 2.68,
      price_3m_h: 2.95,
      price_3m_l: 2.68,
      price_6m_h: 2.95,
      price_6m_l: 2.68,
      price_52w_h: 3.15,
      price_52w_l: 2.68,
      price_pc_1d: 0.0,
      price_pc_1w: -0.712,
      price_pc_1m: -2.105,
      price_pc_6m: -1.761,
      price_pc_1y: -6.689,
      price_pc_ytd: -6.689,
    },
    "0743": {
      id: "0743",
      c: 5.03,
      ma50: 5.14,
      ma100: 5.183,
      ma150: 5.52,
      ma200: 5.582,
      maw30: 5.544,
      rs: 46.0,
      rsd1d: -1.0,
      rsd5d: -4.0,
      rsd10d: 10.0,
      price_5d_h: 5.26,
      price_5d_L: 4.88,
      price_1m_h: 5.26,
      price_1m_l: 4.39,
      price_3m_h: 5.79,
      price_3m_l: 4.39,
      price_6m_h: 6.42,
      price_6m_l: 4.39,
      price_52w_h: 7.97,
      price_52w_l: 4.39,
      price_pc_1d: -0.789,
      price_pc_1w: -2.14,
      price_pc_1m: 3.074,
      price_pc_6m: -18.476,
      price_pc_1y: -36.888,
      price_pc_ytd: -36.888,
    },
    "0745": {
      id: "0745",
      c: 0.173,
      ma50: 0.174,
      ma100: 0.176,
      ma150: 0.171,
      ma200: 0.172,
      maw30: 0.173,
      rs: 73.0,
      rsd1d: 10.0,
      rsd5d: 15.0,
      rsd10d: 5.0,
      price_5d_h: 0.196,
      price_5d_L: 0.163,
      price_1m_h: 0.196,
      price_1m_l: 0.153,
      price_3m_h: 0.196,
      price_3m_l: 0.153,
      price_6m_h: 0.209,
      price_6m_l: 0.15,
      price_52w_h: 0.23,
      price_52w_l: 0.139,
      price_pc_1d: 2.976,
      price_pc_1w: -1.143,
      price_pc_1m: -4.42,
      price_pc_6m: 2.976,
      price_pc_1y: 19.31,
      price_pc_ytd: 19.31,
    },
    "0746": {
      id: "0746",
      c: 6.84,
      ma50: 7.028,
      ma100: 7.506,
      ma150: 7.891,
      ma200: 7.286,
      maw30: 7.925,
      rs: 86.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: 7.0,
      price_5d_h: 7.0,
      price_5d_L: 6.6,
      price_1m_h: 7.43,
      price_1m_l: 5.83,
      price_3m_h: 8.65,
      price_3m_l: 5.83,
      price_6m_h: 10.48,
      price_6m_l: 5.83,
      price_52w_h: 13.78,
      price_52w_l: 4.06,
      price_pc_1d: 1.634,
      price_pc_1w: -1.724,
      price_pc_1m: -4.469,
      price_pc_6m: -25.0,
      price_pc_1y: 66.829,
      price_pc_ytd: 66.829,
    },
    "0747": {
      id: "0747",
      c: 0.102,
      ma50: 0.117,
      ma100: 0.12,
      ma150: 0.128,
      ma200: 0.132,
      maw30: 0.128,
      rs: 25.0,
      rsd1d: 1.0,
      rsd5d: -10.0,
      rsd10d: -16.0,
      price_5d_h: 0.114,
      price_5d_L: 0.095,
      price_1m_h: 0.121,
      price_1m_l: 0.095,
      price_3m_h: 0.136,
      price_3m_l: 0.095,
      price_6m_h: 0.149,
      price_6m_l: 0.095,
      price_52w_h: 0.181,
      price_52w_l: 0.095,
      price_pc_1d: -0.971,
      price_pc_1w: -10.526,
      price_pc_1m: -15.702,
      price_pc_6m: -31.081,
      price_pc_1y: -41.04,
      price_pc_ytd: -41.04,
    },
    "0750": {
      id: "0750",
      c: 1.15,
      ma50: 1.329,
      ma100: 1.566,
      ma150: 1.725,
      ma200: 1.681,
      maw30: 1.759,
      rs: 24.0,
      rsd1d: -1.0,
      rsd5d: -4.0,
      rsd10d: -14.0,
      price_5d_h: 1.22,
      price_5d_L: 1.07,
      price_1m_h: 1.42,
      price_1m_l: 0.99,
      price_3m_h: 1.89,
      price_3m_l: 0.99,
      price_6m_h: 2.91,
      price_6m_l: 0.99,
      price_52w_h: 2.91,
      price_52w_l: 0.99,
      price_pc_1d: -3.361,
      price_pc_1w: 0.0,
      price_pc_1m: -16.058,
      price_pc_6m: -55.078,
      price_pc_1y: -1.709,
      price_pc_ytd: -1.709,
    },
    "0751": {
      id: "0751",
      c: 4.02,
      ma50: 4.354,
      ma100: 4.833,
      ma150: 4.116,
      ma200: 3.649,
      maw30: 4.152,
      rs: 92.0,
      rsd1d: -2.0,
      rsd5d: -3.0,
      rsd10d: -2.0,
      price_5d_h: 4.42,
      price_5d_L: 3.9,
      price_1m_h: 4.64,
      price_1m_l: 3.31,
      price_3m_h: 5.9,
      price_3m_l: 3.31,
      price_6m_h: 6.61,
      price_6m_l: 2.1,
      price_52w_h: 6.61,
      price_52w_l: 1.97,
      price_pc_1d: -7.159,
      price_pc_1w: -7.373,
      price_pc_1m: -8.636,
      price_pc_6m: 71.795,
      price_pc_1y: 51.698,
      price_pc_ytd: 51.698,
    },
    "0752": {
      id: "0752",
      c: 1.09,
      ma50: 1.221,
      ma100: 1.252,
      ma150: 1.247,
      ma200: 1.26,
      maw30: 1.253,
      rs: 47.0,
      rsd1d: 5.0,
      rsd5d: -9.0,
      rsd10d: -1.0,
      price_5d_h: 1.15,
      price_5d_L: 1.06,
      price_1m_h: 1.25,
      price_1m_l: 1.03,
      price_3m_h: 1.37,
      price_3m_l: 1.03,
      price_6m_h: 1.37,
      price_6m_l: 1.03,
      price_52w_h: 1.49,
      price_52w_l: 1.03,
      price_pc_1d: 1.869,
      price_pc_1w: -5.217,
      price_pc_1m: -10.656,
      price_pc_6m: -9.917,
      price_pc_1y: -11.382,
      price_pc_ytd: -11.382,
    },
    "0753": {
      id: "0753",
      c: 5.49,
      ma50: 5.903,
      ma100: 5.658,
      ma150: 5.548,
      ma200: 5.502,
      maw30: 5.589,
      rs: 69.0,
      rsd1d: -1.0,
      rsd5d: -6.0,
      rsd10d: -12.0,
      price_5d_h: 5.73,
      price_5d_L: 5.29,
      price_1m_h: 6.3,
      price_1m_l: 4.73,
      price_3m_h: 6.75,
      price_3m_l: 4.73,
      price_6m_h: 6.75,
      price_6m_l: 4.73,
      price_52w_h: 6.9,
      price_52w_l: 4.67,
      price_pc_1d: -1.436,
      price_pc_1w: -2.139,
      price_pc_1m: -8.5,
      price_pc_6m: 6.602,
      price_pc_1y: -19.027,
      price_pc_ytd: -19.027,
    },
    "0754": {
      id: "0754",
      c: 14.9,
      ma50: 15.493,
      ma100: 16.405,
      ma150: 19.252,
      ma200: 21.591,
      maw30: 19.1,
      rs: 21.0,
      rsd1d: 2.0,
      rsd5d: -2.0,
      rsd10d: 2.0,
      price_5d_h: 15.42,
      price_5d_L: 13.68,
      price_1m_h: 16.34,
      price_1m_l: 11.12,
      price_3m_h: 17.78,
      price_3m_l: 11.12,
      price_6m_h: 28.1,
      price_6m_l: 11.12,
      price_52w_h: 36.182,
      price_52w_l: 11.12,
      price_pc_1d: 0.54,
      price_pc_1w: -1.974,
      price_pc_1m: -6.991,
      price_pc_6m: -40.83,
      price_pc_1y: -41.464,
      price_pc_ytd: -41.464,
    },
    "0755": {
      id: "0755",
      c: 0.027,
      ma50: 0.03,
      ma100: 0.031,
      ma150: 0.035,
      ma200: 0.04,
      maw30: 0.035,
      rs: 15.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -2.0,
      price_5d_h: 0.028,
      price_5d_L: 0.026,
      price_1m_h: 0.033,
      price_1m_l: 0.025,
      price_3m_h: 0.036,
      price_3m_l: 0.025,
      price_6m_h: 0.043,
      price_6m_l: 0.025,
      price_52w_h: 0.081,
      price_52w_l: 0.025,
      price_pc_1d: -3.571,
      price_pc_1w: -3.571,
      price_pc_1m: -18.182,
      price_pc_6m: -34.146,
      price_pc_1y: -65.823,
      price_pc_ytd: -65.823,
    },
    "0756": {
      id: "0756",
      c: 0.097,
      ma50: 0.08,
      ma100: 0.091,
      ma150: 0.097,
      ma200: 0.11,
      maw30: 0.096,
      rs: 48.0,
      rsd1d: 29.0,
      rsd5d: 30.0,
      rsd10d: 31.0,
      price_5d_h: 0.1,
      price_5d_L: 0.079,
      price_1m_h: 0.1,
      price_1m_l: 0.063,
      price_3m_h: 0.113,
      price_3m_l: 0.063,
      price_6m_h: 0.118,
      price_6m_l: 0.063,
      price_52w_h: 0.33,
      price_52w_l: 0.063,
      price_pc_1d: 21.25,
      price_pc_1w: 22.785,
      price_pc_1m: 25.974,
      price_pc_6m: -8.491,
      price_pc_1y: -32.168,
      price_pc_ytd: -32.168,
    },
    "0757": {
      id: "0757",
      c: 0.385,
      ma50: 0.371,
      ma100: 0.398,
      ma150: 0.431,
      ma200: 0.44,
      maw30: 0.432,
      rs: 58.0,
      rsd1d: -9.0,
      rsd5d: -11.0,
      rsd10d: 6.0,
      price_5d_h: 0.44,
      price_5d_L: 0.38,
      price_1m_h: 0.45,
      price_1m_l: 0.295,
      price_3m_h: 0.45,
      price_3m_l: 0.295,
      price_6m_h: 0.53,
      price_6m_l: 0.295,
      price_52w_h: 0.59,
      price_52w_l: 0.295,
      price_pc_1d: -6.098,
      price_pc_1w: -4.938,
      price_pc_1m: 10.0,
      price_pc_6m: -22.222,
      price_pc_1y: 0.0,
      price_pc_ytd: 0.0,
    },
    "0759": {
      id: "0759",
      c: 0.62,
      ma50: 0.67,
      ma100: 0.658,
      ma150: 0.671,
      ma200: 0.697,
      maw30: 0.673,
      rs: 54.0,
      rsd1d: -3.0,
      rsd5d: -1.0,
      rsd10d: -6.0,
      price_5d_h: 0.64,
      price_5d_L: 0.6,
      price_1m_h: 0.73,
      price_1m_l: 0.59,
      price_3m_h: 0.74,
      price_3m_l: 0.59,
      price_6m_h: 0.74,
      price_6m_l: 0.59,
      price_52w_h: 0.87,
      price_52w_l: 0.59,
      price_pc_1d: -1.587,
      price_pc_1w: -1.587,
      price_pc_1m: -8.824,
      price_pc_6m: -10.145,
      price_pc_1y: -20.513,
      price_pc_ytd: -20.513,
    },
    "0762": {
      id: "0762",
      c: 3.89,
      ma50: 4.092,
      ma100: 4.019,
      ma150: 4.035,
      ma200: 4.087,
      maw30: 4.033,
      rs: 67.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: 2.0,
      price_5d_h: 3.97,
      price_5d_L: 3.86,
      price_1m_h: 4.23,
      price_1m_l: 3.6,
      price_3m_h: 4.43,
      price_3m_l: 3.6,
      price_6m_h: 4.43,
      price_6m_l: 3.6,
      price_52w_h: 4.73,
      price_52w_l: 3.6,
      price_pc_1d: -0.256,
      price_pc_1w: -1.018,
      price_pc_1m: -5.811,
      price_pc_6m: -1.519,
      price_pc_1y: -11.791,
      price_pc_ytd: -11.791,
    },
    "0763": {
      id: "0763",
      c: 16.02,
      ma50: 18.762,
      ma100: 20.285,
      ma150: 22.078,
      ma200: 23.006,
      maw30: 22.079,
      rs: 24.0,
      rsd1d: 0.0,
      rsd5d: -8.0,
      rsd10d: 3.0,
      price_5d_h: 17.46,
      price_5d_L: 15.88,
      price_1m_h: 24.9,
      price_1m_l: 12.7,
      price_3m_h: 24.9,
      price_3m_l: 12.7,
      price_6m_h: 26.9,
      price_6m_l: 12.7,
      price_52w_h: 31.75,
      price_52w_l: 12.7,
      price_pc_1d: -2.909,
      price_pc_1w: -7.931,
      price_pc_1m: -16.562,
      price_pc_6m: -37.422,
      price_pc_1y: -19.9,
      price_pc_ytd: -19.9,
    },
    "0764": {
      id: "0764",
      c: 0.154,
      ma50: 0.157,
      ma100: 0.173,
      ma150: 0.191,
      ma200: 0.21,
      maw30: 0.19,
      rs: 21.0,
      rsd1d: 1.0,
      rsd5d: 3.0,
      rsd10d: 10.0,
      price_5d_h: 0.155,
      price_5d_L: 0.131,
      price_1m_h: 0.155,
      price_1m_l: 0.111,
      price_3m_h: 0.187,
      price_3m_l: 0.111,
      price_6m_h: 0.232,
      price_6m_l: 0.111,
      price_52w_h: 0.345,
      price_52w_l: 0.111,
      price_pc_1d: -0.645,
      price_pc_1w: 3.356,
      price_pc_1m: -1.282,
      price_pc_6m: -30.0,
      price_pc_1y: -41.887,
      price_pc_ytd: -41.887,
    },
    "0767": {
      id: "0767",
      c: 0.059,
      ma50: 0.135,
      ma100: 0.209,
      ma150: 0.313,
      ma200: 0.381,
      maw30: 0.311,
      rs: 1.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: -1.0,
      price_5d_h: 0.108,
      price_5d_L: 0.05,
      price_1m_h: 0.12,
      price_1m_l: 0.05,
      price_3m_h: 0.214,
      price_3m_l: 0.05,
      price_6m_h: 0.61,
      price_6m_l: 0.05,
      price_52w_h: 0.83,
      price_52w_l: 0.05,
      price_pc_1d: -41.0,
      price_pc_1w: -43.269,
      price_pc_1m: -46.364,
      price_pc_6m: -87.447,
      price_pc_1y: -92.133,
      price_pc_ytd: -92.133,
    },
    "0768": {
      id: "0768",
      c: 0.04,
      ma50: 0.046,
      ma100: 0.045,
      ma150: 0.047,
      ma200: 0.051,
      maw30: 0.047,
      rs: 40.0,
      rsd1d: -23.0,
      rsd5d: -14.0,
      rsd10d: 13.0,
      price_5d_h: 0.046,
      price_5d_L: 0.04,
      price_1m_h: 0.056,
      price_1m_l: 0.038,
      price_3m_h: 0.062,
      price_3m_l: 0.03,
      price_6m_h: 0.064,
      price_6m_l: 0.03,
      price_52w_h: 0.114,
      price_52w_l: 0.03,
      price_pc_1d: -13.043,
      price_pc_1w: -11.111,
      price_pc_1m: -28.571,
      price_pc_6m: -20.0,
      price_pc_1y: -25.926,
      price_pc_ytd: -25.926,
    },
    "0769": {
      id: "0769",
      c: 0.69,
      ma50: 0.786,
      ma100: 0.817,
      ma150: 0.83,
      ma200: 0.853,
      maw30: 0.832,
      rs: 31.0,
      rsd1d: 2.0,
      rsd5d: 5.0,
      rsd10d: -3.0,
      price_5d_h: 0.71,
      price_5d_L: 0.66,
      price_1m_h: 0.82,
      price_1m_l: 0.58,
      price_3m_h: 0.97,
      price_3m_l: 0.58,
      price_6m_h: 1.04,
      price_6m_l: 0.58,
      price_52w_h: 1.13,
      price_52w_l: 0.58,
      price_pc_1d: -2.817,
      price_pc_1w: -1.429,
      price_pc_1m: -15.854,
      price_pc_6m: -18.824,
      price_pc_1y: -25.806,
      price_pc_ytd: -25.806,
    },
    "0771": {
      id: "0771",
      c: 1.06,
      ma50: 1.257,
      ma100: 1.307,
      ma150: 1.339,
      ma200: 1.348,
      maw30: 1.341,
      rs: 29.0,
      rsd1d: -15.0,
      rsd5d: -10.0,
      rsd10d: -9.0,
      price_5d_h: 1.18,
      price_5d_L: 1.05,
      price_1m_h: 1.27,
      price_1m_l: 0.99,
      price_3m_h: 1.6,
      price_3m_l: 0.99,
      price_6m_h: 1.6,
      price_6m_l: 0.99,
      price_52w_h: 1.68,
      price_52w_l: 0.99,
      price_pc_1d: -10.169,
      price_pc_1w: -7.018,
      price_pc_1m: -12.397,
      price_pc_6m: -12.397,
      price_pc_1y: -33.333,
      price_pc_ytd: -33.333,
    },
    "0772": {
      id: "0772",
      c: 32.65,
      ma50: 40.981,
      ma100: 47.224,
      ma150: 51.826,
      ma200: 58.419,
      maw30: 51.882,
      rs: 10.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: 3.0,
      price_5d_h: 34.55,
      price_5d_L: 29.5,
      price_1m_h: 42.7,
      price_1m_l: 23.0,
      price_3m_h: 55.0,
      price_3m_l: 23.0,
      price_6m_h: 62.0,
      price_6m_l: 23.0,
      price_52w_h: 94.8,
      price_52w_l: 23.0,
      price_pc_1d: -2.099,
      price_pc_1w: -3.829,
      price_pc_1m: -22.539,
      price_pc_6m: -45.034,
      price_pc_1y: -58.697,
      price_pc_ytd: -58.697,
    },
    "0775": {
      id: "0775",
      c: 0.62,
      ma50: 0.686,
      ma100: 0.712,
      ma150: 0.725,
      ma200: 0.751,
      maw30: 0.725,
      rs: 35.0,
      rsd1d: 1.0,
      rsd5d: -1.0,
      rsd10d: 0.0,
      price_5d_h: 0.65,
      price_5d_L: 0.62,
      price_1m_h: 0.7,
      price_1m_l: 0.56,
      price_3m_h: 0.76,
      price_3m_l: 0.56,
      price_6m_h: 0.8,
      price_6m_l: 0.56,
      price_52w_h: 0.98,
      price_52w_l: 0.56,
      price_pc_1d: -1.587,
      price_pc_1w: -3.125,
      price_pc_1m: -11.429,
      price_pc_6m: -15.068,
      price_pc_1y: -26.19,
      price_pc_ytd: -26.19,
    },
    "0776": {
      id: "0776",
      c: 16.16,
      ma50: 16.148,
      ma100: 17.131,
      ma150: 15.542,
      ma200: 14.744,
      maw30: 15.669,
      rs: 94.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: -2.0,
      price_5d_h: 16.38,
      price_5d_L: 15.7,
      price_1m_h: 16.66,
      price_1m_l: 9.66,
      price_3m_h: 18.06,
      price_3m_l: 9.66,
      price_6m_h: 34.7,
      price_6m_l: 9.66,
      price_52w_h: 34.7,
      price_52w_l: 5.5,
      price_pc_1d: 0.124,
      price_pc_1w: 2.149,
      price_pc_1m: -0.247,
      price_pc_6m: 33.114,
      price_pc_1y: 172.054,
      price_pc_ytd: 172.054,
    },
    "0777": {
      id: "0777",
      c: 16.4,
      ma50: 18.031,
      ma100: 18.041,
      ma150: 17.977,
      ma200: 18.065,
      maw30: 18.136,
      rs: 49.0,
      rsd1d: -3.0,
      rsd5d: -1.0,
      rsd10d: 5.0,
      price_5d_h: 16.86,
      price_5d_L: 15.74,
      price_1m_h: 18.06,
      price_1m_l: 13.62,
      price_3m_h: 21.0,
      price_3m_l: 13.62,
      price_6m_h: 21.0,
      price_6m_l: 13.62,
      price_52w_h: 23.4,
      price_52w_l: 13.62,
      price_pc_1d: -1.679,
      price_pc_1w: -0.846,
      price_pc_1m: -6.499,
      price_pc_6m: -6.818,
      price_pc_1y: -20.0,
      price_pc_ytd: -20.0,
    },
    "0778": {
      id: "0778",
      c: 7.05,
      ma50: 7.587,
      ma100: 7.828,
      ma150: 7.889,
      ma200: 8.04,
      maw30: 7.89,
      rs: 54.0,
      rsd1d: 3.0,
      rsd5d: 0.0,
      rsd10d: -3.0,
      price_5d_h: 7.15,
      price_5d_L: 7.02,
      price_1m_h: 7.48,
      price_1m_l: 6.7,
      price_3m_h: 8.3,
      price_3m_l: 6.7,
      price_6m_h: 8.45,
      price_6m_l: 6.7,
      price_52w_h: 8.88,
      price_52w_l: 6.7,
      price_pc_1d: -0.424,
      price_pc_1w: -0.142,
      price_pc_1m: -3.951,
      price_pc_6m: -8.915,
      price_pc_1y: -5.114,
      price_pc_ytd: -5.114,
    },
    "0780": {
      id: "0780",
      c: 14.0,
      ma50: 14.735,
      ma100: 15.343,
      ma150: 16.219,
      ma200: 16.609,
      maw30: 16.351,
      rs: 43.0,
      rsd1d: -7.0,
      rsd5d: 4.0,
      rsd10d: 6.0,
      price_5d_h: 14.7,
      price_5d_L: 12.62,
      price_1m_h: 15.4,
      price_1m_l: 9.0,
      price_3m_h: 17.68,
      price_3m_l: 9.0,
      price_6m_h: 19.5,
      price_6m_l: 9.0,
      price_52w_h: 21.35,
      price_52w_l: 9.0,
      price_pc_1d: -3.448,
      price_pc_1w: 2.041,
      price_pc_1m: -3.448,
      price_pc_6m: -25.611,
      price_pc_1y: -21.525,
      price_pc_ytd: -21.525,
    },
    "0784": {
      id: "0784",
      c: 0.134,
      ma50: 0.138,
      ma100: 0.143,
      ma150: 0.146,
      ma200: 0.146,
      maw30: 0.146,
      rs: 57.0,
      rsd1d: -5.0,
      rsd5d: -2.0,
      rsd10d: -10.0,
      price_5d_h: 0.142,
      price_5d_L: 0.132,
      price_1m_h: 0.143,
      price_1m_l: 0.125,
      price_3m_h: 0.149,
      price_3m_l: 0.124,
      price_6m_h: 0.172,
      price_6m_l: 0.124,
      price_52w_h: 0.248,
      price_52w_l: 0.124,
      price_pc_1d: -2.899,
      price_pc_1w: -3.597,
      price_pc_1m: 0.0,
      price_pc_6m: -11.258,
      price_pc_1y: -30.928,
      price_pc_ytd: -30.928,
    },
    "0788": {
      id: "0788",
      c: 0.88,
      ma50: 0.911,
      ma100: 0.924,
      ma150: 0.959,
      ma200: 0.983,
      maw30: 0.961,
      rs: 56.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: 13.0,
      price_5d_h: 0.9,
      price_5d_L: 0.85,
      price_1m_h: 0.93,
      price_1m_l: 0.74,
      price_3m_h: 1.0,
      price_3m_l: 0.74,
      price_6m_h: 1.06,
      price_6m_l: 0.74,
      price_52w_h: 1.19,
      price_52w_l: 0.74,
      price_pc_1d: -1.124,
      price_pc_1w: -1.124,
      price_pc_1m: -3.297,
      price_pc_6m: -13.725,
      price_pc_1y: -24.138,
      price_pc_ytd: -24.138,
    },
    "0789": {
      id: "0789",
      c: 0.076,
      ma50: 0.08,
      ma100: 0.083,
      ma150: 0.089,
      ma200: 0.098,
      maw30: 0.088,
      rs: 29.0,
      rsd1d: -4.0,
      rsd5d: 2.0,
      rsd10d: -16.0,
      price_5d_h: 0.08,
      price_5d_L: 0.075,
      price_1m_h: 0.112,
      price_1m_l: 0.065,
      price_3m_h: 0.128,
      price_3m_l: 0.065,
      price_6m_h: 0.128,
      price_6m_l: 0.065,
      price_52w_h: 0.139,
      price_52w_l: 0.065,
      price_pc_1d: -5.0,
      price_pc_1w: -1.299,
      price_pc_1m: 1.333,
      price_pc_6m: -28.302,
      price_pc_1y: -41.985,
      price_pc_ytd: -41.985,
    },
    "0798": {
      id: "0798",
      c: 0.38,
      ma50: 0.386,
      ma100: 0.398,
      ma150: 0.409,
      ma200: 0.425,
      maw30: 0.411,
      rs: 53.0,
      rsd1d: 0.0,
      rsd5d: 5.0,
      rsd10d: 6.0,
      price_5d_h: 0.4,
      price_5d_L: 0.375,
      price_1m_h: 0.4,
      price_1m_l: 0.3,
      price_3m_h: 0.42,
      price_3m_l: 0.3,
      price_6m_h: 0.44,
      price_6m_l: 0.3,
      price_52w_h: 0.61,
      price_52w_l: 0.3,
      price_pc_1d: 0.0,
      price_pc_1w: 1.333,
      price_pc_1m: 0.0,
      price_pc_6m: -5.0,
      price_pc_1y: -10.588,
      price_pc_ytd: -10.588,
    },
    "0799": {
      id: "0799",
      c: 3.74,
      ma50: 4.509,
      ma100: 5.69,
      ma150: 6.302,
      ma200: 7.028,
      maw30: 6.331,
      rs: 7.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: -3.0,
      price_5d_h: 4.23,
      price_5d_L: 3.66,
      price_1m_h: 4.79,
      price_1m_l: 3.52,
      price_3m_h: 6.99,
      price_3m_l: 3.52,
      price_6m_h: 8.1,
      price_6m_l: 3.52,
      price_52w_h: 13.68,
      price_52w_l: 3.52,
      price_pc_1d: -1.837,
      price_pc_1w: -10.312,
      price_pc_1m: -19.914,
      price_pc_6m: -48.977,
      price_pc_1y: -64.916,
      price_pc_ytd: -64.916,
    },
    "0800": {
      id: "0800",
      c: 0.28,
      ma50: 0.335,
      ma100: 0.321,
      ma150: 0.298,
      ma200: 0.277,
      maw30: 0.3,
      rs: 82.0,
      rsd1d: 1.0,
      rsd5d: -6.0,
      rsd10d: -9.0,
      price_5d_h: 0.295,
      price_5d_L: 0.27,
      price_1m_h: 0.34,
      price_1m_l: 0.27,
      price_3m_h: 0.42,
      price_3m_l: 0.27,
      price_6m_h: 0.42,
      price_6m_l: 0.232,
      price_52w_h: 0.42,
      price_52w_l: 0.172,
      price_pc_1d: 0.0,
      price_pc_1w: -3.448,
      price_pc_1m: -12.5,
      price_pc_6m: 9.804,
      price_pc_1y: 22.807,
      price_pc_ytd: 22.807,
    },
    "0802": {
      id: "0802",
      c: 0.065,
      ma50: 0.068,
      ma100: 0.083,
      ma150: 0.091,
      ma200: 0.096,
      maw30: 0.09,
      rs: 13.0,
      rsd1d: 2.0,
      rsd5d: 2.0,
      rsd10d: -5.0,
      price_5d_h: 0.071,
      price_5d_L: 0.062,
      price_1m_h: 0.071,
      price_1m_l: 0.06,
      price_3m_h: 0.115,
      price_3m_l: 0.051,
      price_6m_h: 0.115,
      price_6m_l: 0.051,
      price_52w_h: 0.135,
      price_52w_l: 0.051,
      price_pc_1d: 1.563,
      price_pc_1w: 3.175,
      price_pc_1m: 3.175,
      price_pc_6m: -37.5,
      price_pc_1y: -39.252,
      price_pc_ytd: -39.252,
    },
    "0804": {
      id: "0804",
      c: 0.042,
      ma50: 0.046,
      ma100: 0.046,
      ma150: 0.052,
      ma200: 0.053,
      maw30: 0.051,
      rs: 32.0,
      rsd1d: 3.0,
      rsd5d: -20.0,
      rsd10d: -10.0,
      price_5d_h: 0.043,
      price_5d_L: 0.04,
      price_1m_h: 0.048,
      price_1m_l: 0.033,
      price_3m_h: 0.067,
      price_3m_l: 0.033,
      price_6m_h: 0.083,
      price_6m_l: 0.033,
      price_52w_h: 0.083,
      price_52w_l: 0.026,
      price_pc_1d: 0.0,
      price_pc_1w: 2.439,
      price_pc_1m: -6.667,
      price_pc_6m: -40.845,
      price_pc_1y: -4.545,
      price_pc_ytd: -4.545,
    },
    "0806": {
      id: "0806",
      c: 3.38,
      ma50: 3.669,
      ma100: 3.813,
      ma150: 3.907,
      ma200: 4.098,
      maw30: 3.925,
      rs: 33.0,
      rsd1d: -2.0,
      rsd5d: -2.0,
      rsd10d: 2.0,
      price_5d_h: 3.47,
      price_5d_L: 3.2,
      price_1m_h: 3.82,
      price_1m_l: 2.79,
      price_3m_h: 4.13,
      price_3m_l: 2.79,
      price_6m_h: 4.38,
      price_6m_l: 2.79,
      price_52w_h: 5.61,
      price_52w_l: 2.79,
      price_pc_1d: -1.744,
      price_pc_1w: -1.458,
      price_pc_1m: -11.518,
      price_pc_6m: -14.213,
      price_pc_1y: -38.657,
      price_pc_ytd: -38.657,
    },
    "0807": {
      id: "0807",
      c: 1.23,
      ma50: 1.366,
      ma100: 1.388,
      ma150: 1.373,
      ma200: 1.324,
      maw30: 1.383,
      rs: 58.0,
      rsd1d: -2.0,
      rsd5d: -6.0,
      rsd10d: -11.0,
      price_5d_h: 1.28,
      price_5d_L: 1.21,
      price_1m_h: 1.38,
      price_1m_l: 1.13,
      price_3m_h: 1.67,
      price_3m_l: 1.13,
      price_6m_h: 1.9,
      price_6m_l: 1.13,
      price_52w_h: 1.9,
      price_52w_l: 1.08,
      price_pc_1d: -0.806,
      price_pc_1w: -3.906,
      price_pc_1m: -9.559,
      price_pc_6m: -3.15,
      price_pc_1y: -5.385,
      price_pc_ytd: -5.385,
    },
    "0808": {
      id: "0808",
      c: 2.85,
      ma50: 2.966,
      ma100: 3.014,
      ma150: 3.031,
      ma200: 3.029,
      maw30: 3.046,
      rs: 68.0,
      rsd1d: 1.0,
      rsd5d: -10.0,
      rsd10d: -3.0,
      price_5d_h: 2.96,
      price_5d_L: 2.83,
      price_1m_h: 2.99,
      price_1m_l: 2.72,
      price_3m_h: 3.12,
      price_3m_l: 2.72,
      price_6m_h: 3.13,
      price_6m_l: 2.72,
      price_52w_h: 3.2,
      price_52w_l: 2.57,
      price_pc_1d: 0.0,
      price_pc_1w: -4.682,
      price_pc_1m: -1.724,
      price_pc_6m: -8.065,
      price_pc_1y: 9.195,
      price_pc_ytd: 9.195,
    },
    "0810": {
      id: "0810",
      c: 1.3,
      ma50: 1.448,
      ma100: 1.441,
      ma150: 1.431,
      ma200: 1.194,
      maw30: 1.453,
      rs: 97.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: 0.0,
      price_5d_h: 1.59,
      price_5d_L: 1.3,
      price_1m_h: 1.59,
      price_1m_l: 1.3,
      price_3m_h: 1.79,
      price_3m_l: 1.3,
      price_6m_h: 1.79,
      price_6m_l: 1.25,
      price_52w_h: 1.82,
      price_52w_l: 0.25,
      price_pc_1d: -3.704,
      price_pc_1w: -7.143,
      price_pc_1m: -9.091,
      price_pc_6m: -22.619,
      price_pc_1y: 241.135,
      price_pc_ytd: 241.135,
    },
    "0811": {
      id: "0811",
      c: 5.69,
      ma50: 5.777,
      ma100: 5.824,
      ma150: 5.74,
      ma200: 5.621,
      maw30: 5.779,
      rs: 73.0,
      rsd1d: -3.0,
      rsd5d: 6.0,
      rsd10d: 3.0,
      price_5d_h: 5.87,
      price_5d_L: 5.14,
      price_1m_h: 5.9,
      price_1m_l: 4.85,
      price_3m_h: 6.4,
      price_3m_l: 4.85,
      price_6m_h: 6.4,
      price_6m_l: 4.85,
      price_52w_h: 6.4,
      price_52w_l: 4.85,
      price_pc_1d: -1.727,
      price_pc_1w: 4.596,
      price_pc_1m: -2.401,
      price_pc_6m: -3.231,
      price_pc_1y: 6.157,
      price_pc_ytd: 6.157,
    },
    "0812": {
      id: "0812",
      c: 0.032,
      ma50: 0.039,
      ma100: 0.047,
      ma150: 0.052,
      ma200: 0.056,
      maw30: 0.052,
      rs: 8.0,
      rsd1d: 1.0,
      rsd5d: 2.0,
      rsd10d: 1.0,
      price_5d_h: 0.043,
      price_5d_L: 0.031,
      price_1m_h: 0.043,
      price_1m_l: 0.028,
      price_3m_h: 0.054,
      price_3m_l: 0.028,
      price_6m_h: 0.076,
      price_6m_l: 0.028,
      price_52w_h: 0.097,
      price_52w_l: 0.028,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: -3.03,
      price_pc_6m: -50.769,
      price_pc_1y: -64.444,
      price_pc_ytd: -64.444,
    },
    "0813": {
      id: "0813",
      c: 4.42,
      ma50: 5.157,
      ma100: 6.292,
      ma150: 8.872,
      ma200: 11.047,
      maw30: 8.813,
      rs: 7.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: -5.0,
      price_5d_h: 4.74,
      price_5d_L: 3.78,
      price_1m_h: 5.57,
      price_1m_l: 3.3,
      price_3m_h: 7.18,
      price_3m_l: 3.3,
      price_6m_h: 15.1,
      price_6m_l: 3.3,
      price_52w_h: 24.15,
      price_52w_l: 3.3,
      price_pc_1d: -2.212,
      price_pc_1w: -3.913,
      price_pc_1m: -5.353,
      price_pc_6m: -69.004,
      price_pc_1y: -81.66,
      price_pc_ytd: -81.66,
    },
    "0814": {
      id: "0814",
      c: 0.81,
      ma50: 0.938,
      ma100: 0.965,
      ma150: 1.01,
      ma200: 1.052,
      maw30: 1.011,
      rs: 28.0,
      rsd1d: -6.0,
      rsd5d: -8.0,
      rsd10d: -12.0,
      price_5d_h: 0.92,
      price_5d_L: 0.8,
      price_1m_h: 0.96,
      price_1m_l: 0.8,
      price_3m_h: 1.0,
      price_3m_l: 0.8,
      price_6m_h: 1.14,
      price_6m_l: 0.8,
      price_52w_h: 1.3,
      price_52w_l: 0.8,
      price_pc_1d: -6.897,
      price_pc_1w: -10.0,
      price_pc_1m: -15.625,
      price_pc_6m: -26.364,
      price_pc_1y: -36.719,
      price_pc_ytd: -36.719,
    },
    "0815": {
      id: "0815",
      c: 0.59,
      ma50: 0.591,
      ma100: 0.598,
      ma150: 0.591,
      ma200: 0.608,
      maw30: 0.591,
      rs: 60.0,
      rsd1d: 4.0,
      rsd5d: -1.0,
      rsd10d: 7.0,
      price_5d_h: 0.64,
      price_5d_L: 0.57,
      price_1m_h: 0.65,
      price_1m_l: 0.53,
      price_3m_h: 0.65,
      price_3m_l: 0.53,
      price_6m_h: 0.72,
      price_6m_l: 0.53,
      price_52w_h: 1.15,
      price_52w_l: 0.53,
      price_pc_1d: 0.0,
      price_pc_1w: -3.279,
      price_pc_1m: 0.0,
      price_pc_6m: 9.259,
      price_pc_1y: -35.87,
      price_pc_ytd: -35.87,
    },
    "0816": {
      id: "0816",
      c: 4.96,
      ma50: null,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 45.0,
      rsd1d: -8.0,
      rsd5d: -7.0,
      rsd10d: -4.0,
      price_5d_h: 5.4,
      price_5d_L: 4.61,
      price_1m_h: 7.4,
      price_1m_l: 3.31,
      price_3m_h: 7.4,
      price_3m_l: 3.31,
      price_6m_h: 7.4,
      price_6m_l: 3.31,
      price_52w_h: 7.4,
      price_52w_l: 3.31,
      price_pc_1d: -4.798,
      price_pc_1w: -4.247,
      price_pc_1m: -14.483,
      price_pc_6m: -14.483,
      price_pc_1y: -14.483,
      price_pc_ytd: -14.483,
    },
    "0817": {
      id: "0817",
      c: 2.33,
      ma50: 2.634,
      ma100: 2.556,
      ma150: 2.536,
      ma200: 2.521,
      maw30: 2.571,
      rs: 52.0,
      rsd1d: 1.0,
      rsd5d: -1.0,
      rsd10d: -11.0,
      price_5d_h: 2.41,
      price_5d_L: 2.0,
      price_1m_h: 2.68,
      price_1m_l: 1.72,
      price_3m_h: 3.24,
      price_3m_l: 1.72,
      price_6m_h: 3.24,
      price_6m_l: 1.72,
      price_52w_h: 3.27,
      price_52w_l: 1.72,
      price_pc_1d: -0.851,
      price_pc_1w: -0.427,
      price_pc_1m: -10.385,
      price_pc_6m: -16.786,
      price_pc_1y: -24.595,
      price_pc_ytd: -24.595,
    },
    "0818": {
      id: "0818",
      c: 0.99,
      ma50: 1.002,
      ma100: 1.022,
      ma150: 1.106,
      ma200: 1.164,
      maw30: 1.106,
      rs: 40.0,
      rsd1d: -6.0,
      rsd5d: -4.0,
      rsd10d: 10.0,
      price_5d_h: 1.03,
      price_5d_L: 0.98,
      price_1m_h: 1.05,
      price_1m_l: 0.8,
      price_3m_h: 1.09,
      price_3m_l: 0.8,
      price_6m_h: 1.32,
      price_6m_l: 0.8,
      price_52w_h: 1.63,
      price_52w_l: 0.8,
      price_pc_1d: -3.883,
      price_pc_1w: -3.883,
      price_pc_1m: 0.0,
      price_pc_6m: -22.656,
      price_pc_1y: -34.437,
      price_pc_ytd: -34.437,
    },
    "0819": {
      id: "0819",
      c: 6.74,
      ma50: 7.421,
      ma100: 7.912,
      ma150: 8.43,
      ma200: 9.831,
      maw30: 8.354,
      rs: 19.0,
      rsd1d: 3.0,
      rsd5d: -3.0,
      rsd10d: 3.0,
      price_5d_h: 7.23,
      price_5d_L: 6.56,
      price_1m_h: 7.35,
      price_1m_l: 5.5,
      price_3m_h: 8.69,
      price_3m_l: 5.5,
      price_6m_h: 10.14,
      price_6m_l: 5.5,
      price_52w_h: 16.42,
      price_52w_l: 5.5,
      price_pc_1d: -0.443,
      price_pc_1w: -5.734,
      price_pc_1m: -5.866,
      price_pc_6m: -22.706,
      price_pc_1y: -57.125,
      price_pc_ytd: -57.125,
    },
    "0820": {
      id: "0820",
      c: 15.22,
      ma50: 16.44,
      ma100: 17.388,
      ma150: 17.248,
      ma200: 16.971,
      maw30: 17.325,
      rs: 53.0,
      rsd1d: 2.0,
      rsd5d: 1.0,
      rsd10d: -1.0,
      price_5d_h: 15.22,
      price_5d_L: 15.1,
      price_1m_h: 16.64,
      price_1m_l: 14.78,
      price_3m_h: 18.5,
      price_3m_l: 14.78,
      price_6m_h: 19.0,
      price_6m_l: 14.78,
      price_52w_h: 19.0,
      price_52w_l: 14.68,
      price_pc_1d: 0.0,
      price_pc_1w: 0.132,
      price_pc_1m: -7.758,
      price_pc_6m: -9.834,
      price_pc_1y: -4.875,
      price_pc_ytd: -4.875,
    },
    "0821": {
      id: "0821",
      c: 0.245,
      ma50: 0.282,
      ma100: 0.326,
      ma150: 0.347,
      ma200: 0.354,
      maw30: 0.344,
      rs: 31.0,
      rsd1d: -3.0,
      rsd5d: 0.0,
      rsd10d: -3.0,
      price_5d_h: 0.265,
      price_5d_L: 0.245,
      price_1m_h: 0.295,
      price_1m_l: 0.24,
      price_3m_h: 0.37,
      price_3m_l: 0.24,
      price_6m_h: 0.425,
      price_6m_l: 0.24,
      price_52w_h: 0.64,
      price_52w_l: 0.235,
      price_pc_1d: -3.922,
      price_pc_1w: -5.769,
      price_pc_1m: -16.949,
      price_pc_6m: -30.986,
      price_pc_1y: -18.333,
      price_pc_ytd: -18.333,
    },
    "0822": {
      id: "0822",
      c: 0.58,
      ma50: 0.644,
      ma100: 0.785,
      ma150: 0.73,
      ma200: 0.705,
      maw30: 0.731,
      rs: 55.0,
      rsd1d: 2.0,
      rsd5d: 9.0,
      rsd10d: 6.0,
      price_5d_h: 0.6,
      price_5d_L: 0.56,
      price_1m_h: 0.65,
      price_1m_l: 0.51,
      price_3m_h: 0.91,
      price_3m_l: 0.51,
      price_6m_h: 1.32,
      price_6m_l: 0.51,
      price_52w_h: 1.32,
      price_52w_l: 0.495,
      price_pc_1d: 0.0,
      price_pc_1w: -1.695,
      price_pc_1m: -6.452,
      price_pc_6m: 3.571,
      price_pc_1y: 7.407,
      price_pc_ytd: 7.407,
    },
    "0823": {
      id: "0823",
      c: 67.0,
      ma50: 65.678,
      ma100: 67.068,
      ma150: 67.456,
      ma200: 69.349,
      maw30: 67.432,
      rs: 67.0,
      rsd1d: 1.0,
      rsd5d: 3.0,
      rsd10d: 1.0,
      price_5d_h: 68.0,
      price_5d_L: 66.2,
      price_1m_h: 68.0,
      price_1m_l: 59.75,
      price_3m_h: 70.4,
      price_3m_l: 59.75,
      price_6m_h: 73.0,
      price_6m_l: 59.75,
      price_52w_h: 78.7,
      price_52w_l: 59.75,
      price_pc_1d: -0.961,
      price_pc_1w: 0.676,
      price_pc_1m: 6.181,
      price_pc_6m: 0.375,
      price_pc_1y: -4.762,
      price_pc_ytd: -4.762,
    },
    "0825": {
      id: "0825",
      c: 1.09,
      ma50: 1.185,
      ma100: 1.229,
      ma150: 1.27,
      ma200: 1.3,
      maw30: 1.275,
      rs: 45.0,
      rsd1d: 3.0,
      rsd5d: 3.0,
      rsd10d: 10.0,
      price_5d_h: 1.13,
      price_5d_L: 1.05,
      price_1m_h: 1.23,
      price_1m_l: 0.9,
      price_3m_h: 1.38,
      price_3m_l: 0.9,
      price_6m_h: 1.43,
      price_6m_l: 0.9,
      price_52w_h: 1.83,
      price_52w_l: 0.9,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: -9.917,
      price_pc_6m: -21.583,
      price_pc_1y: 1.869,
      price_pc_ytd: 1.869,
    },
    "0826": {
      id: "0826",
      c: 3.2,
      ma50: 3.584,
      ma100: 4.079,
      ma150: 4.364,
      ma200: 4.221,
      maw30: 4.363,
      rs: 31.0,
      rsd1d: 5.0,
      rsd5d: 6.0,
      rsd10d: 6.0,
      price_5d_h: 3.25,
      price_5d_L: 2.87,
      price_1m_h: 3.78,
      price_1m_l: 2.36,
      price_3m_h: 4.9,
      price_3m_l: 2.36,
      price_6m_h: 5.11,
      price_6m_l: 2.36,
      price_52w_h: 5.66,
      price_52w_l: 2.36,
      price_pc_1d: 2.564,
      price_pc_1w: 2.564,
      price_pc_1m: -13.279,
      price_pc_6m: -31.183,
      price_pc_1y: -19.598,
      price_pc_ytd: -19.598,
    },
    "0827": {
      id: "0827",
      c: 0.245,
      ma50: 0.289,
      ma100: 0.237,
      ma150: 0.203,
      ma200: 0.178,
      maw30: 0.208,
      rs: 96.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: -1.0,
      price_5d_h: 0.26,
      price_5d_L: 0.224,
      price_1m_h: 0.365,
      price_1m_l: 0.21,
      price_3m_h: 0.375,
      price_3m_l: 0.184,
      price_6m_h: 0.375,
      price_6m_l: 0.128,
      price_52w_h: 0.375,
      price_52w_l: 0.08,
      price_pc_1d: -3.922,
      price_pc_1w: 13.953,
      price_pc_1m: -25.758,
      price_pc_6m: 88.462,
      price_pc_1y: 107.627,
      price_pc_ytd: 107.627,
    },
    "0828": {
      id: "0828",
      c: 0.37,
      ma50: 0.354,
      ma100: 0.357,
      ma150: 0.377,
      ma200: 0.406,
      maw30: 0.376,
      rs: 42.0,
      rsd1d: -11.0,
      rsd5d: -3.0,
      rsd10d: 9.0,
      price_5d_h: 0.38,
      price_5d_L: 0.315,
      price_1m_h: 0.385,
      price_1m_l: 0.28,
      price_3m_h: 0.41,
      price_3m_l: 0.28,
      price_6m_h: 0.445,
      price_6m_l: 0.28,
      price_52w_h: 0.63,
      price_52w_l: 0.28,
      price_pc_1d: 0.0,
      price_pc_1w: 4.225,
      price_pc_1m: -3.896,
      price_pc_6m: -8.642,
      price_pc_1y: -26.0,
      price_pc_ytd: -26.0,
    },
    "0829": {
      id: "0829",
      c: 0.47,
      ma50: 0.447,
      ma100: 0.432,
      ma150: 0.426,
      ma200: 0.424,
      maw30: 0.424,
      rs: 88.0,
      rsd1d: -2.0,
      rsd5d: -1.0,
      rsd10d: 0.0,
      price_5d_h: 0.48,
      price_5d_L: 0.45,
      price_1m_h: 0.48,
      price_1m_l: 0.41,
      price_3m_h: 0.49,
      price_3m_l: 0.38,
      price_6m_h: 0.49,
      price_6m_l: 0.38,
      price_52w_h: 0.58,
      price_52w_l: 0.365,
      price_pc_1d: 1.075,
      price_pc_1w: 3.297,
      price_pc_1m: 2.174,
      price_pc_6m: 18.987,
      price_pc_1y: 27.027,
      price_pc_ytd: 27.027,
    },
    "0830": {
      id: "0830",
      c: 2.12,
      ma50: 1.971,
      ma100: 1.804,
      ma150: 1.772,
      ma200: 1.718,
      maw30: 1.768,
      rs: 96.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: -1.0,
      price_5d_h: 2.15,
      price_5d_L: 2.03,
      price_1m_h: 2.2,
      price_1m_l: 1.69,
      price_3m_h: 2.2,
      price_3m_l: 1.57,
      price_6m_h: 2.2,
      price_6m_l: 1.53,
      price_52w_h: 2.2,
      price_52w_l: 0.7,
      price_pc_1d: -1.395,
      price_pc_1w: 0.952,
      price_pc_1m: 0.474,
      price_pc_6m: 26.946,
      price_pc_1y: 182.667,
      price_pc_ytd: 182.667,
    },
    "0831": {
      id: "0831",
      c: 1.0,
      ma50: 0.776,
      ma100: 0.765,
      ma150: 0.766,
      ma200: 0.767,
      maw30: 0.764,
      rs: 93.0,
      rsd1d: 0.0,
      rsd5d: 3.0,
      rsd10d: 16.0,
      price_5d_h: 1.06,
      price_5d_L: 0.89,
      price_1m_h: 1.06,
      price_1m_l: 0.69,
      price_3m_h: 1.06,
      price_3m_l: 0.69,
      price_6m_h: 1.06,
      price_6m_l: 0.69,
      price_52w_h: 1.06,
      price_52w_l: 0.69,
      price_pc_1d: -0.99,
      price_pc_1w: 11.111,
      price_pc_1m: 33.333,
      price_pc_6m: 28.205,
      price_pc_1y: 36.986,
      price_pc_ytd: 36.986,
    },
    "0832": {
      id: "0832",
      c: 0.76,
      ma50: 0.786,
      ma100: 0.883,
      ma150: 1.08,
      ma200: 1.278,
      maw30: 1.073,
      rs: 10.0,
      rsd1d: 1.0,
      rsd5d: -2.0,
      rsd10d: 2.0,
      price_5d_h: 0.87,
      price_5d_L: 0.67,
      price_1m_h: 0.88,
      price_1m_l: 0.5,
      price_3m_h: 1.01,
      price_3m_l: 0.5,
      price_6m_h: 1.52,
      price_6m_l: 0.5,
      price_52w_h: 4.56,
      price_52w_l: 0.5,
      price_pc_1d: -1.299,
      price_pc_1w: -9.524,
      price_pc_1m: 18.75,
      price_pc_6m: -49.333,
      price_pc_1y: -78.889,
      price_pc_ytd: -78.889,
    },
    "0833": {
      id: "0833",
      c: 0.26,
      ma50: 0.301,
      ma100: 0.304,
      ma150: 0.309,
      ma200: 0.32,
      maw30: 0.31,
      rs: 28.0,
      rsd1d: -18.0,
      rsd5d: -13.0,
      rsd10d: -23.0,
      price_5d_h: 0.305,
      price_5d_L: 0.26,
      price_1m_h: 0.335,
      price_1m_l: 0.26,
      price_3m_h: 0.335,
      price_3m_l: 0.26,
      price_6m_h: 0.335,
      price_6m_l: 0.26,
      price_52w_h: 0.47,
      price_52w_l: 0.26,
      price_pc_1d: -14.754,
      price_pc_1w: -11.864,
      price_pc_1m: -13.333,
      price_pc_6m: -21.212,
      price_pc_1y: -45.263,
      price_pc_ytd: -45.263,
    },
    "0836": {
      id: "0836",
      c: 14.74,
      ma50: 17.334,
      ma100: 19.773,
      ma150: 19.862,
      ma200: 17.956,
      maw30: 20.149,
      rs: 60.0,
      rsd1d: 1.0,
      rsd5d: 3.0,
      rsd10d: -23.0,
      price_5d_h: 14.96,
      price_5d_L: 13.76,
      price_1m_h: 17.96,
      price_1m_l: 12.4,
      price_3m_h: 27.85,
      price_3m_l: 12.4,
      price_6m_h: 28.7,
      price_6m_l: 12.4,
      price_52w_h: 28.7,
      price_52w_l: 9.18,
      price_pc_1d: -1.206,
      price_pc_1w: 0.136,
      price_pc_1m: -15.092,
      price_pc_6m: -34.343,
      price_pc_1y: 41.459,
      price_pc_ytd: 41.459,
    },
    "0837": {
      id: "0837",
      c: 3.4,
      ma50: 3.472,
      ma100: 3.522,
      ma150: 3.56,
      ma200: 3.586,
      maw30: 3.563,
      rs: 62.0,
      rsd1d: 5.0,
      rsd5d: 11.0,
      rsd10d: 12.0,
      price_5d_h: 3.4,
      price_5d_L: 3.25,
      price_1m_h: 3.52,
      price_1m_l: 3.16,
      price_3m_h: 3.65,
      price_3m_l: 3.16,
      price_6m_h: 3.79,
      price_6m_l: 3.16,
      price_52w_h: 4.08,
      price_52w_l: 3.16,
      price_pc_1d: 0.0,
      price_pc_1w: 3.03,
      price_pc_1m: -2.579,
      price_pc_6m: -3.683,
      price_pc_1y: -15.423,
      price_pc_ytd: -15.423,
    },
    "0838": {
      id: "0838",
      c: 1.3,
      ma50: 1.446,
      ma100: 1.694,
      ma150: 1.442,
      ma200: 1.303,
      maw30: 1.444,
      rs: 92.0,
      rsd1d: -1.0,
      rsd5d: -2.0,
      rsd10d: -1.0,
      price_5d_h: 1.39,
      price_5d_L: 1.23,
      price_1m_h: 1.55,
      price_1m_l: 0.97,
      price_3m_h: 2.1,
      price_3m_l: 0.97,
      price_6m_h: 2.58,
      price_6m_l: 0.7,
      price_52w_h: 2.58,
      price_52w_l: 0.67,
      price_pc_1d: -3.704,
      price_pc_1w: -1.515,
      price_pc_1m: -12.752,
      price_pc_6m: 75.676,
      price_pc_1y: 85.714,
      price_pc_ytd: 85.714,
    },
    "0839": {
      id: "0839",
      c: 6.84,
      ma50: 7.311,
      ma100: 10.212,
      ma150: 11.441,
      ma200: 12.494,
      maw30: 11.405,
      rs: 8.0,
      rsd1d: 1.0,
      rsd5d: 2.0,
      rsd10d: 3.0,
      price_5d_h: 7.1,
      price_5d_L: 6.16,
      price_1m_h: 7.1,
      price_1m_l: 4.68,
      price_3m_h: 13.08,
      price_3m_l: 4.62,
      price_6m_h: 18.3,
      price_6m_l: 4.62,
      price_52w_h: 20.8,
      price_52w_l: 4.62,
      price_pc_1d: -1.724,
      price_pc_1w: 1.333,
      price_pc_1m: 2.703,
      price_pc_6m: -49.031,
      price_pc_1y: -50.933,
      price_pc_ytd: -50.933,
    },
    "0841": {
      id: "0841",
      c: 0.218,
      ma50: 0.263,
      ma100: 0.277,
      ma150: 0.28,
      ma200: 0.289,
      maw30: 0.283,
      rs: 24.0,
      rsd1d: 0.0,
      rsd5d: -2.0,
      rsd10d: -5.0,
      price_5d_h: 0.23,
      price_5d_L: 0.218,
      price_1m_h: 0.275,
      price_1m_l: 0.202,
      price_3m_h: 0.31,
      price_3m_l: 0.202,
      price_6m_h: 0.345,
      price_6m_l: 0.202,
      price_52w_h: 0.37,
      price_52w_l: 0.202,
      price_pc_1d: -3.111,
      price_pc_1w: -5.217,
      price_pc_1m: -22.143,
      price_pc_6m: -24.828,
      price_pc_1y: -32.923,
      price_pc_ytd: -32.923,
    },
    "0842": {
      id: "0842",
      c: 0.69,
      ma50: 0.755,
      ma100: 0.82,
      ma150: 0.826,
      ma200: 0.848,
      maw30: 0.823,
      rs: 37.0,
      rsd1d: -2.0,
      rsd5d: -3.0,
      rsd10d: 7.0,
      price_5d_h: 0.71,
      price_5d_L: 0.63,
      price_1m_h: 0.77,
      price_1m_l: 0.55,
      price_3m_h: 0.87,
      price_3m_l: 0.55,
      price_6m_h: 1.05,
      price_6m_l: 0.55,
      price_52w_h: 1.31,
      price_52w_l: 0.55,
      price_pc_1d: -1.429,
      price_pc_1w: -4.167,
      price_pc_1m: -8.0,
      price_pc_6m: -12.658,
      price_pc_1y: -34.286,
      price_pc_ytd: -34.286,
    },
    "0845": {
      id: "0845",
      c: 0.198,
      ma50: 0.224,
      ma100: 0.229,
      ma150: 0.229,
      ma200: 0.228,
      maw30: 0.232,
      rs: 41.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: -12.0,
      price_5d_h: 0.207,
      price_5d_L: 0.185,
      price_1m_h: 0.24,
      price_1m_l: 0.185,
      price_3m_h: 0.244,
      price_3m_l: 0.185,
      price_6m_h: 0.25,
      price_6m_l: 0.185,
      price_52w_h: 0.265,
      price_52w_l: 0.183,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: -11.607,
      price_pc_6m: -15.385,
      price_pc_1y: -19.838,
      price_pc_ytd: -19.838,
    },
    "0846": {
      id: "0846",
      c: 0.26,
      ma50: 0.386,
      ma100: 0.427,
      ma150: 0.445,
      ma200: 0.463,
      maw30: 0.448,
      rs: 9.0,
      rsd1d: 1.0,
      rsd5d: -6.0,
      rsd10d: 2.0,
      price_5d_h: 0.28,
      price_5d_L: 0.26,
      price_1m_h: 0.37,
      price_1m_l: 0.243,
      price_3m_h: 0.6,
      price_3m_l: 0.243,
      price_6m_h: 0.6,
      price_6m_l: 0.243,
      price_52w_h: 0.75,
      price_52w_l: 0.243,
      price_pc_1d: -7.143,
      price_pc_1w: -23.529,
      price_pc_1m: -25.714,
      price_pc_6m: -41.573,
      price_pc_1y: -62.857,
      price_pc_ytd: -62.857,
    },
    "0848": {
      id: "0848",
      c: 0.246,
      ma50: 0.244,
      ma100: 0.248,
      ma150: 0.258,
      ma200: 0.274,
      maw30: 0.258,
      rs: 55.0,
      rsd1d: 6.0,
      rsd5d: 1.0,
      rsd10d: 14.0,
      price_5d_h: 0.25,
      price_5d_L: 0.23,
      price_1m_h: 0.255,
      price_1m_l: 0.216,
      price_3m_h: 0.275,
      price_3m_l: 0.216,
      price_6m_h: 0.3,
      price_6m_l: 0.216,
      price_52w_h: 0.365,
      price_52w_l: 0.216,
      price_pc_1d: 2.5,
      price_pc_1w: -1.205,
      price_pc_1m: 0.408,
      price_pc_6m: -8.889,
      price_pc_1y: -28.696,
      price_pc_ytd: -28.696,
    },
    "0851": {
      id: "0851",
      c: 0.044,
      ma50: 0.041,
      ma100: 0.04,
      ma150: 0.044,
      ma200: 0.046,
      maw30: 0.043,
      rs: 84.0,
      rsd1d: -8.0,
      rsd5d: -4.0,
      rsd10d: -2.0,
      price_5d_h: 0.055,
      price_5d_L: 0.035,
      price_1m_h: 0.055,
      price_1m_l: 0.035,
      price_3m_h: 0.055,
      price_3m_l: 0.03,
      price_6m_h: 0.055,
      price_6m_l: 0.03,
      price_52w_h: 0.07,
      price_52w_l: 0.03,
      price_pc_1d: -15.385,
      price_pc_1w: 2.326,
      price_pc_1m: -8.333,
      price_pc_6m: -16.981,
      price_pc_1y: 46.667,
      price_pc_ytd: 46.667,
    },
    "0852": {
      id: "0852",
      c: 0.2,
      ma50: 0.229,
      ma100: 0.235,
      ma150: 0.247,
      ma200: 0.264,
      maw30: 0.246,
      rs: 22.0,
      rsd1d: 3.0,
      rsd5d: -13.0,
      rsd10d: -10.0,
      price_5d_h: 0.22,
      price_5d_L: 0.2,
      price_1m_h: 0.25,
      price_1m_l: 0.2,
      price_3m_h: 0.255,
      price_3m_l: 0.2,
      price_6m_h: 0.44,
      price_6m_l: 0.2,
      price_52w_h: 0.44,
      price_52w_l: 0.2,
      price_pc_1d: -0.99,
      price_pc_1w: -9.091,
      price_pc_1m: -14.894,
      price_pc_6m: -24.528,
      price_pc_1y: -42.857,
      price_pc_ytd: -42.857,
    },
    "0853": {
      id: "0853",
      c: 17.78,
      ma50: 21.043,
      ma100: 26.204,
      ma150: 32.016,
      ma200: 39.231,
      maw30: 31.782,
      rs: 6.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: 0.0,
      price_5d_h: 19.68,
      price_5d_L: 16.8,
      price_1m_h: 23.55,
      price_1m_l: 12.24,
      price_3m_h: 29.0,
      price_3m_l: 12.24,
      price_6m_h: 44.5,
      price_6m_l: 12.24,
      price_52w_h: 72.85,
      price_52w_l: 12.24,
      price_pc_1d: -7.106,
      price_pc_1w: -8.256,
      price_pc_1m: -17.685,
      price_pc_6m: -59.499,
      price_pc_1y: -63.302,
      price_pc_ytd: -63.302,
    },
    "0855": {
      id: "0855",
      c: 8.58,
      ma50: 8.927,
      ma100: 8.953,
      ma150: 8.742,
      ma200: 8.088,
      maw30: 8.807,
      rs: 82.0,
      rsd1d: -5.0,
      rsd5d: -7.0,
      rsd10d: -6.0,
      price_5d_h: 9.27,
      price_5d_L: 8.46,
      price_1m_h: 9.27,
      price_1m_l: 7.24,
      price_3m_h: 11.56,
      price_3m_l: 7.24,
      price_6m_h: 11.56,
      price_6m_l: 7.11,
      price_52w_h: 11.56,
      price_52w_l: 5.71,
      price_pc_1d: -2.278,
      price_pc_1w: -3.596,
      price_pc_1m: -0.464,
      price_pc_6m: -1.152,
      price_pc_1y: 35.545,
      price_pc_ytd: 35.545,
    },
    "0856": {
      id: "0856",
      c: 7.38,
      ma50: 7.696,
      ma100: 7.429,
      ma150: 7.323,
      ma200: 7.088,
      maw30: 7.379,
      rs: 82.0,
      rsd1d: -2.0,
      rsd5d: -7.0,
      rsd10d: -6.0,
      price_5d_h: 8.2,
      price_5d_L: 7.03,
      price_1m_h: 8.3,
      price_1m_l: 6.74,
      price_3m_h: 8.38,
      price_3m_l: 6.74,
      price_6m_h: 8.38,
      price_6m_l: 6.6,
      price_52w_h: 8.38,
      price_52w_l: 5.59,
      price_pc_1d: -1.072,
      price_pc_1w: -7.403,
      price_pc_1m: -8.209,
      price_pc_6m: 5.278,
      price_pc_1y: 1.096,
      price_pc_ytd: 1.096,
    },
    "0857": {
      id: "0857",
      c: 4.06,
      ma50: 4.041,
      ma100: 3.791,
      ma150: 3.77,
      ma200: 3.683,
      maw30: 3.773,
      rs: 90.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: 3.0,
      price_5d_h: 4.15,
      price_5d_L: 3.92,
      price_1m_h: 4.49,
      price_1m_l: 3.46,
      price_3m_h: 4.49,
      price_3m_l: 3.43,
      price_6m_h: 4.49,
      price_6m_l: 3.33,
      price_52w_h: 4.49,
      price_52w_l: 2.68,
      price_pc_1d: -1.456,
      price_pc_1w: 1.5,
      price_pc_1m: -2.638,
      price_pc_6m: 10.027,
      price_pc_1y: 45.0,
      price_pc_ytd: 45.0,
    },
    "0858": {
      id: "0858",
      c: 0.087,
      ma50: 0.09,
      ma100: 0.093,
      ma150: 0.098,
      ma200: 0.105,
      maw30: 0.097,
      rs: 34.0,
      rsd1d: 1.0,
      rsd5d: -4.0,
      rsd10d: 9.0,
      price_5d_h: 0.095,
      price_5d_L: 0.086,
      price_1m_h: 0.11,
      price_1m_l: 0.077,
      price_3m_h: 0.113,
      price_3m_l: 0.077,
      price_6m_h: 0.113,
      price_6m_l: 0.077,
      price_52w_h: 0.166,
      price_52w_l: 0.077,
      price_pc_1d: -1.136,
      price_pc_1w: -6.452,
      price_pc_1m: 3.571,
      price_pc_6m: -16.346,
      price_pc_1y: -43.871,
      price_pc_ytd: -43.871,
    },
    "0860": {
      id: "0860",
      c: 0.37,
      ma50: 0.41,
      ma100: 0.449,
      ma150: 0.48,
      ma200: 0.462,
      maw30: 0.483,
      rs: 35.0,
      rsd1d: -4.0,
      rsd5d: 4.0,
      rsd10d: 10.0,
      price_5d_h: 0.395,
      price_5d_L: 0.365,
      price_1m_h: 0.415,
      price_1m_l: 0.305,
      price_3m_h: 0.485,
      price_3m_l: 0.305,
      price_6m_h: 0.63,
      price_6m_l: 0.305,
      price_52w_h: 0.63,
      price_52w_l: 0.305,
      price_pc_1d: -3.896,
      price_pc_1w: -1.333,
      price_pc_1m: -8.642,
      price_pc_6m: -27.451,
      price_pc_1y: -27.451,
      price_pc_ytd: -27.451,
    },
    "0861": {
      id: "0861",
      c: 3.81,
      ma50: 4.103,
      ma100: 4.212,
      ma150: 4.279,
      ma200: 4.403,
      maw30: 4.291,
      rs: 32.0,
      rsd1d: 9.0,
      rsd5d: -3.0,
      rsd10d: 1.0,
      price_5d_h: 3.99,
      price_5d_L: 3.48,
      price_1m_h: 4.3,
      price_1m_l: 3.05,
      price_3m_h: 5.07,
      price_3m_l: 3.05,
      price_6m_h: 5.23,
      price_6m_l: 3.05,
      price_52w_h: 6.07,
      price_52w_l: 3.05,
      price_pc_1d: 5.833,
      price_pc_1w: 3.252,
      price_pc_1m: -10.981,
      price_pc_6m: -10.981,
      price_pc_1y: -31.72,
      price_pc_ytd: -31.72,
    },
    "0862": {
      id: "0862",
      c: 0.17,
      ma50: 0.191,
      ma100: 0.199,
      ma150: 0.203,
      ma200: 0.2,
      maw30: 0.205,
      rs: 34.0,
      rsd1d: -3.0,
      rsd5d: -28.0,
      rsd10d: -30.0,
      price_5d_h: 0.185,
      price_5d_L: 0.17,
      price_1m_h: 0.212,
      price_1m_l: 0.168,
      price_3m_h: 0.285,
      price_3m_l: 0.168,
      price_6m_h: 0.29,
      price_6m_l: 0.168,
      price_52w_h: 0.29,
      price_52w_l: 0.165,
      price_pc_1d: -4.494,
      price_pc_1w: -8.108,
      price_pc_1m: -15.842,
      price_pc_6m: -29.167,
      price_pc_1y: -34.615,
      price_pc_ytd: -34.615,
    },
    "0863": {
      id: "0863",
      c: 6.84,
      ma50: 8.02,
      ma100: 9.36,
      ma150: 10.736,
      ma200: 12.288,
      maw30: 10.739,
      rs: 12.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: 6.0,
      price_5d_h: 7.58,
      price_5d_L: 6.75,
      price_1m_h: 8.39,
      price_1m_l: 5.24,
      price_3m_h: 10.86,
      price_3m_l: 5.24,
      price_6m_h: 14.5,
      price_6m_l: 5.24,
      price_52w_h: 22.0,
      price_52w_l: 5.24,
      price_pc_1d: -1.724,
      price_pc_1w: -6.812,
      price_pc_1m: -13.636,
      price_pc_6m: -44.39,
      price_pc_1y: -55.871,
      price_pc_ytd: -55.871,
    },
    "0865": {
      id: "0865",
      c: 0.08,
      ma50: 0.094,
      ma100: 0.086,
      ma150: 0.08,
      ma200: 0.079,
      maw30: 0.081,
      rs: 74.0,
      rsd1d: -10.0,
      rsd5d: -10.0,
      rsd10d: -17.0,
      price_5d_h: 0.09,
      price_5d_L: 0.078,
      price_1m_h: 0.099,
      price_1m_l: 0.075,
      price_3m_h: 0.11,
      price_3m_l: 0.073,
      price_6m_h: 0.11,
      price_6m_l: 0.06,
      price_52w_h: 0.11,
      price_52w_l: 0.06,
      price_pc_1d: -3.614,
      price_pc_1w: -5.882,
      price_pc_1m: -19.192,
      price_pc_6m: 15.942,
      price_pc_1y: 0.0,
      price_pc_ytd: 0.0,
    },
    "0866": {
      id: "0866",
      c: 0.5,
      ma50: 0.338,
      ma100: 0.26,
      ma150: 0.258,
      ma200: 0.23,
      maw30: 0.256,
      rs: 98.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: 1.0,
      price_5d_h: 0.61,
      price_5d_L: 0.355,
      price_1m_h: 0.61,
      price_1m_l: 0.315,
      price_3m_h: 0.61,
      price_3m_l: 0.17,
      price_6m_h: 0.61,
      price_6m_l: 0.165,
      price_52w_h: 0.61,
      price_52w_l: 0.12,
      price_pc_1d: -5.66,
      price_pc_1w: 35.135,
      price_pc_1m: 35.135,
      price_pc_6m: 75.439,
      price_pc_1y: 281.679,
      price_pc_ytd: 281.679,
    },
    "0867": {
      id: "0867",
      c: 12.34,
      ma50: 13.208,
      ma100: 13.125,
      ma150: 13.547,
      ma200: 14.76,
      maw30: 13.586,
      rs: 41.0,
      rsd1d: -8.0,
      rsd5d: -7.0,
      rsd10d: -12.0,
      price_5d_h: 13.32,
      price_5d_L: 12.2,
      price_1m_h: 13.92,
      price_1m_l: 10.04,
      price_3m_h: 14.8,
      price_3m_l: 10.04,
      price_6m_h: 15.26,
      price_6m_l: 10.04,
      price_52w_h: 22.8,
      price_52w_l: 10.04,
      price_pc_1d: -4.341,
      price_pc_1w: -6.088,
      price_pc_1m: -10.967,
      price_pc_6m: -13.099,
      price_pc_1y: -25.121,
      price_pc_ytd: -25.121,
    },
    "0868": {
      id: "0868",
      c: 19.0,
      ma50: 20.362,
      ma100: 20.019,
      ma150: 21.736,
      ma200: 23.981,
      maw30: 21.518,
      rs: 39.0,
      rsd1d: -2.0,
      rsd5d: -6.0,
      rsd10d: -11.0,
      price_5d_h: 19.82,
      price_5d_L: 18.72,
      price_1m_h: 21.95,
      price_1m_l: 17.04,
      price_3m_h: 23.2,
      price_3m_l: 17.04,
      price_6m_h: 23.8,
      price_6m_l: 17.04,
      price_52w_h: 34.2,
      price_52w_l: 17.04,
      price_pc_1d: -3.259,
      price_pc_1w: -2.664,
      price_pc_1m: -8.434,
      price_pc_6m: -18.455,
      price_pc_1y: -25.49,
      price_pc_ytd: -25.49,
    },
    "0869": {
      id: "0869",
      c: 0.46,
      ma50: 0.486,
      ma100: 0.491,
      ma150: 0.505,
      ma200: 0.552,
      maw30: 0.504,
      rs: 61.0,
      rsd1d: -1.0,
      rsd5d: -6.0,
      rsd10d: -6.0,
      price_5d_h: 0.485,
      price_5d_L: 0.46,
      price_1m_h: 0.52,
      price_1m_l: 0.455,
      price_3m_h: 0.56,
      price_3m_l: 0.455,
      price_6m_h: 0.62,
      price_6m_l: 0.455,
      price_52w_h: 0.76,
      price_52w_l: 0.39,
      price_pc_1d: -2.128,
      price_pc_1w: -4.167,
      price_pc_1m: -3.158,
      price_pc_6m: -8.0,
      price_pc_1y: 19.481,
      price_pc_ytd: 19.481,
    },
    "0871": {
      id: "0871",
      c: 0.169,
      ma50: 0.135,
      ma100: 0.133,
      ma150: 0.131,
      ma200: 0.129,
      maw30: 0.131,
      rs: 92.0,
      rsd1d: 1.0,
      rsd5d: 8.0,
      rsd10d: 6.0,
      price_5d_h: 0.18,
      price_5d_L: 0.108,
      price_1m_h: 0.18,
      price_1m_l: 0.1,
      price_3m_h: 0.18,
      price_3m_l: 0.1,
      price_6m_h: 0.18,
      price_6m_l: 0.1,
      price_52w_h: 0.18,
      price_52w_l: 0.1,
      price_pc_1d: 5.625,
      price_pc_1w: 18.182,
      price_pc_1m: 30.0,
      price_pc_6m: 45.69,
      price_pc_1y: 8.333,
      price_pc_ytd: 8.333,
    },
    "0872": {
      id: "0872",
      c: 0.064,
      ma50: 0.07,
      ma100: 0.081,
      ma150: 0.088,
      ma200: 0.093,
      maw30: 0.087,
      rs: 19.0,
      rsd1d: 8.0,
      rsd5d: 6.0,
      rsd10d: 6.0,
      price_5d_h: 0.065,
      price_5d_L: 0.056,
      price_1m_h: 0.075,
      price_1m_l: 0.044,
      price_3m_h: 0.082,
      price_3m_l: 0.044,
      price_6m_h: 0.119,
      price_6m_l: 0.044,
      price_52w_h: 0.19,
      price_52w_l: 0.044,
      price_pc_1d: 14.286,
      price_pc_1w: 8.475,
      price_pc_1m: -13.514,
      price_pc_6m: -40.741,
      price_pc_1y: -43.363,
      price_pc_ytd: -43.363,
    },
    "0873": {
      id: "0873",
      c: 4.2,
      ma50: 5.598,
      ma100: 6.731,
      ma150: 9.857,
      ma200: 12.873,
      maw30: 9.727,
      rs: 5.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: -10.0,
      price_5d_h: 4.51,
      price_5d_L: 3.74,
      price_1m_h: 5.96,
      price_1m_l: 3.35,
      price_3m_h: 7.57,
      price_3m_l: 3.35,
      price_6m_h: 17.06,
      price_6m_l: 3.35,
      price_52w_h: 27.55,
      price_52w_l: 3.35,
      price_pc_1d: -4.11,
      price_pc_1w: -3.67,
      price_pc_1m: -26.316,
      price_pc_6m: -73.913,
      price_pc_1y: -75.862,
      price_pc_ytd: -75.862,
    },
    "0874": {
      id: "0874",
      c: 20.75,
      ma50: 20.732,
      ma100: 20.45,
      ma150: 20.291,
      ma200: 20.739,
      maw30: 20.349,
      rs: 69.0,
      rsd1d: -6.0,
      rsd5d: -12.0,
      rsd10d: -14.0,
      price_5d_h: 21.3,
      price_5d_L: 19.96,
      price_1m_h: 22.5,
      price_1m_l: 18.02,
      price_3m_h: 24.0,
      price_3m_l: 18.02,
      price_6m_h: 24.0,
      price_6m_l: 18.02,
      price_52w_h: 26.2,
      price_52w_l: 18.02,
      price_pc_1d: -1.19,
      price_pc_1w: -2.123,
      price_pc_1m: -0.48,
      price_pc_6m: 5.117,
      price_pc_1y: 9.672,
      price_pc_ytd: 9.672,
    },
    "0875": {
      id: "0875",
      c: 4.25,
      ma50: 3.666,
      ma100: 3.183,
      ma150: 2.869,
      ma200: 2.592,
      maw30: 2.862,
      rs: 97.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -1.0,
      price_5d_h: 5.0,
      price_5d_L: 3.8,
      price_1m_h: 5.0,
      price_1m_l: 2.94,
      price_3m_h: 5.0,
      price_3m_l: 2.66,
      price_6m_h: 5.0,
      price_6m_l: 1.79,
      price_52w_h: 5.0,
      price_52w_l: 1.37,
      price_pc_1d: 5.985,
      price_pc_1w: 4.167,
      price_pc_1m: 18.056,
      price_pc_6m: 70.683,
      price_pc_1y: 165.625,
      price_pc_ytd: 165.625,
    },
    "0876": {
      id: "0876",
      c: 0.08,
      ma50: 0.079,
      ma100: 0.086,
      ma150: 0.126,
      ma200: 0.152,
      maw30: 0.122,
      rs: 21.0,
      rsd1d: 5.0,
      rsd5d: 3.0,
      rsd10d: 7.0,
      price_5d_h: 0.094,
      price_5d_L: 0.076,
      price_1m_h: 0.114,
      price_1m_l: 0.06,
      price_3m_h: 0.114,
      price_3m_l: 0.06,
      price_6m_h: 0.237,
      price_6m_l: 0.06,
      price_52w_h: 0.51,
      price_52w_l: 0.06,
      price_pc_1d: 3.896,
      price_pc_1w: -2.439,
      price_pc_1m: 1.266,
      price_pc_6m: -63.134,
      price_pc_1y: -45.205,
      price_pc_ytd: -45.205,
    },
    "0878": {
      id: "0878",
      c: 7.2,
      ma50: 7.362,
      ma100: 7.496,
      ma150: 7.609,
      ma200: 7.809,
      maw30: 7.616,
      rs: 54.0,
      rsd1d: 8.0,
      rsd5d: 4.0,
      rsd10d: -10.0,
      price_5d_h: 7.2,
      price_5d_L: 6.73,
      price_1m_h: 7.55,
      price_1m_l: 6.43,
      price_3m_h: 8.0,
      price_3m_l: 6.43,
      price_6m_h: 8.0,
      price_6m_l: 6.43,
      price_52w_h: 9.15,
      price_52w_l: 6.43,
      price_pc_1d: 2.857,
      price_pc_1w: 1.408,
      price_pc_1m: -3.096,
      price_pc_6m: -7.574,
      price_pc_1y: -17.714,
      price_pc_ytd: -17.714,
    },
    "0880": {
      id: "0880",
      c: 3.89,
      ma50: 4.487,
      ma100: 4.972,
      ma150: 5.304,
      ma200: 5.845,
      maw30: 5.29,
      rs: 14.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: 2.0,
      price_5d_h: 4.06,
      price_5d_L: 3.59,
      price_1m_h: 4.36,
      price_1m_l: 2.83,
      price_3m_h: 5.7,
      price_3m_l: 2.83,
      price_6m_h: 6.6,
      price_6m_l: 2.83,
      price_52w_h: 10.56,
      price_52w_l: 2.83,
      price_pc_1d: -1.269,
      price_pc_1w: 0.258,
      price_pc_1m: -11.591,
      price_pc_6m: -26.604,
      price_pc_1y: -62.596,
      price_pc_ytd: -62.596,
    },
    "0881": {
      id: "0881",
      c: 55.4,
      ma50: 55.863,
      ma100: 59.702,
      ma150: 61.655,
      ma200: 63.498,
      maw30: 61.892,
      rs: 58.0,
      rsd1d: -1.0,
      rsd5d: -7.0,
      rsd10d: 12.0,
      price_5d_h: 56.9,
      price_5d_L: 53.0,
      price_1m_h: 57.6,
      price_1m_l: 43.0,
      price_3m_h: 63.9,
      price_3m_l: 43.0,
      price_6m_h: 72.7,
      price_6m_l: 43.0,
      price_52w_h: 77.6,
      price_52w_l: 43.0,
      price_pc_1d: -1.16,
      price_pc_1w: -2.551,
      price_pc_1m: 1.838,
      price_pc_6m: -11.643,
      price_pc_1y: -1.071,
      price_pc_ytd: -1.071,
    },
    "0882": {
      id: "0882",
      c: 1.74,
      ma50: 1.776,
      ma100: 1.726,
      ma150: 1.741,
      ma200: 1.722,
      maw30: 1.747,
      rs: 79.0,
      rsd1d: -4.0,
      rsd5d: -2.0,
      rsd10d: 6.0,
      price_5d_h: 1.82,
      price_5d_L: 1.72,
      price_1m_h: 1.85,
      price_1m_l: 1.56,
      price_3m_h: 2.0,
      price_3m_l: 1.56,
      price_6m_h: 2.0,
      price_6m_l: 1.55,
      price_52w_h: 2.01,
      price_52w_l: 1.54,
      price_pc_1d: -2.793,
      price_pc_1w: -2.247,
      price_pc_1m: -5.946,
      price_pc_6m: -2.793,
      price_pc_1y: 7.407,
      price_pc_ytd: 7.407,
    },
    "0883": {
      id: "0883",
      c: 10.74,
      ma50: 9.903,
      ma100: 9.001,
      ma150: 8.779,
      ma200: 8.664,
      maw30: 8.761,
      rs: 92.0,
      rsd1d: -1.0,
      rsd5d: 1.0,
      rsd10d: 2.0,
      price_5d_h: 11.2,
      price_5d_L: 10.2,
      price_1m_h: 11.2,
      price_1m_l: 8.54,
      price_3m_h: 11.2,
      price_3m_l: 8.03,
      price_6m_h: 11.2,
      price_6m_l: 7.55,
      price_52w_h: 11.2,
      price_52w_l: 7.55,
      price_pc_1d: -3.417,
      price_pc_1w: 3.868,
      price_pc_1m: 7.723,
      price_pc_6m: 23.448,
      price_pc_1y: 32.429,
      price_pc_ytd: 32.429,
    },
    "0884": {
      id: "0884",
      c: 4.61,
      ma50: 5.019,
      ma100: 4.799,
      ma150: 4.821,
      ma200: 5.001,
      maw30: 4.875,
      rs: 43.0,
      rsd1d: 5.0,
      rsd5d: 13.0,
      rsd10d: -6.0,
      price_5d_h: 4.77,
      price_5d_L: 3.88,
      price_1m_h: 5.88,
      price_1m_l: 2.31,
      price_3m_h: 6.3,
      price_3m_l: 2.31,
      price_6m_h: 6.3,
      price_6m_l: 2.31,
      price_52w_h: 7.85,
      price_52w_l: 2.31,
      price_pc_1d: 1.991,
      price_pc_1w: 11.622,
      price_pc_1m: -15.257,
      price_pc_6m: -13.019,
      price_pc_1y: -38.533,
      price_pc_ytd: -38.533,
    },
    "0886": {
      id: "0886",
      c: 0.035,
      ma50: 0.04,
      ma100: 0.051,
      ma150: 0.077,
      ma200: 0.101,
      maw30: 0.077,
      rs: 3.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.035,
      price_5d_L: 0.028,
      price_1m_h: 0.054,
      price_1m_l: 0.027,
      price_3m_h: 0.07,
      price_3m_l: 0.027,
      price_6m_h: 0.135,
      price_6m_l: 0.027,
      price_52w_h: 0.255,
      price_52w_l: 0.027,
      price_pc_1d: 20.69,
      price_pc_1w: 0.0,
      price_pc_1m: -25.532,
      price_pc_6m: -72.868,
      price_pc_1y: -77.987,
      price_pc_ytd: -77.987,
    },
    "0889": {
      id: "0889",
      c: 0.49,
      ma50: 0.526,
      ma100: 0.526,
      ma150: 0.543,
      ma200: 0.579,
      maw30: 0.537,
      rs: 44.0,
      rsd1d: -1.0,
      rsd5d: 15.0,
      rsd10d: -1.0,
      price_5d_h: 0.51,
      price_5d_L: 0.46,
      price_1m_h: 0.58,
      price_1m_l: 0.46,
      price_3m_h: 0.7,
      price_3m_l: 0.46,
      price_6m_h: 0.7,
      price_6m_l: 0.46,
      price_52w_h: 1.3,
      price_52w_l: 0.46,
      price_pc_1d: -2.0,
      price_pc_1w: 6.522,
      price_pc_1m: -15.517,
      price_pc_6m: -12.5,
      price_pc_1y: -22.222,
      price_pc_ytd: -22.222,
    },
    "0893": {
      id: "0893",
      c: 0.159,
      ma50: 0.159,
      ma100: 0.162,
      ma150: 0.177,
      ma200: 0.183,
      maw30: 0.175,
      rs: 50.0,
      rsd1d: -13.0,
      rsd5d: -2.0,
      rsd10d: -12.0,
      price_5d_h: 0.164,
      price_5d_L: 0.151,
      price_1m_h: 0.168,
      price_1m_l: 0.138,
      price_3m_h: 0.17,
      price_3m_l: 0.138,
      price_6m_h: 0.219,
      price_6m_l: 0.138,
      price_52w_h: 0.33,
      price_52w_l: 0.138,
      price_pc_1d: -3.049,
      price_pc_1w: 2.581,
      price_pc_1m: -0.625,
      price_pc_6m: -22.816,
      price_pc_1y: -8.621,
      price_pc_ytd: -8.621,
    },
    "0894": {
      id: "0894",
      c: 0.71,
      ma50: 1.014,
      ma100: 1.075,
      ma150: 1.156,
      ma200: 1.23,
      maw30: 1.147,
      rs: 17.0,
      rsd1d: 0.0,
      rsd5d: 4.0,
      rsd10d: 2.0,
      price_5d_h: 0.75,
      price_5d_L: 0.71,
      price_1m_h: 1.2,
      price_1m_l: 0.6,
      price_3m_h: 1.33,
      price_3m_l: 0.6,
      price_6m_h: 1.39,
      price_6m_l: 0.6,
      price_52w_h: 2.37,
      price_52w_l: 0.6,
      price_pc_1d: -4.054,
      price_pc_1w: -2.74,
      price_pc_1m: -36.607,
      price_pc_6m: -40.336,
      price_pc_1y: -15.476,
      price_pc_ytd: -15.476,
    },
    "0895": {
      id: "0895",
      c: 3.29,
      ma50: 3.551,
      ma100: 3.575,
      ma150: 3.649,
      ma200: 3.795,
      maw30: 3.659,
      rs: 36.0,
      rsd1d: 3.0,
      rsd5d: 3.0,
      rsd10d: 0.0,
      price_5d_h: 3.32,
      price_5d_L: 3.2,
      price_1m_h: 3.69,
      price_1m_l: 3.08,
      price_3m_h: 3.82,
      price_3m_l: 3.08,
      price_6m_h: 3.95,
      price_6m_l: 3.08,
      price_52w_h: 5.48,
      price_52w_l: 3.08,
      price_pc_1d: 0.305,
      price_pc_1w: -0.604,
      price_pc_1m: -9.615,
      price_pc_6m: -16.497,
      price_pc_1y: -38.619,
      price_pc_ytd: -38.619,
    },
    "0896": {
      id: "0896",
      c: 1.22,
      ma50: 1.258,
      ma100: 1.274,
      ma150: 1.29,
      ma200: 1.293,
      maw30: 1.292,
      rs: 66.0,
      rsd1d: 1.0,
      rsd5d: -3.0,
      rsd10d: -1.0,
      price_5d_h: 1.24,
      price_5d_L: 1.22,
      price_1m_h: 1.26,
      price_1m_l: 1.16,
      price_3m_h: 1.33,
      price_3m_l: 1.16,
      price_6m_h: 1.35,
      price_6m_l: 1.16,
      price_52w_h: 1.47,
      price_52w_l: 1.16,
      price_pc_1d: -0.813,
      price_pc_1w: -1.613,
      price_pc_1m: -1.613,
      price_pc_6m: -6.87,
      price_pc_1y: -3.937,
      price_pc_ytd: -3.937,
    },
    "0897": {
      id: "0897",
      c: 0.29,
      ma50: 0.291,
      ma100: 0.309,
      ma150: 0.313,
      ma200: 0.315,
      maw30: 0.313,
      rs: 52.0,
      rsd1d: -7.0,
      rsd5d: 5.0,
      rsd10d: 3.0,
      price_5d_h: 0.31,
      price_5d_L: 0.265,
      price_1m_h: 0.31,
      price_1m_l: 0.25,
      price_3m_h: 0.33,
      price_3m_l: 0.25,
      price_6m_h: 0.51,
      price_6m_l: 0.25,
      price_52w_h: 0.51,
      price_52w_l: 0.25,
      price_pc_1d: -4.918,
      price_pc_1w: 3.571,
      price_pc_1m: 0.0,
      price_pc_6m: -15.942,
      price_pc_1y: -13.433,
      price_pc_ytd: -13.433,
    },
    "0898": {
      id: "0898",
      c: 1.12,
      ma50: 1.075,
      ma100: 1.076,
      ma150: 1.089,
      ma200: 1.108,
      maw30: 1.087,
      rs: 68.0,
      rsd1d: 10.0,
      rsd5d: 22.0,
      rsd10d: 11.0,
      price_5d_h: 1.13,
      price_5d_L: 1.0,
      price_1m_h: 1.13,
      price_1m_l: 0.96,
      price_3m_h: 1.13,
      price_3m_l: 0.96,
      price_6m_h: 1.3,
      price_6m_l: 0.96,
      price_52w_h: 1.6,
      price_52w_l: 0.96,
      price_pc_1d: 4.673,
      price_pc_1w: 12.0,
      price_pc_1m: 1.818,
      price_pc_6m: 0.901,
      price_pc_1y: -17.037,
      price_pc_ytd: -17.037,
    },
    "0899": {
      id: "0899",
      c: 0.071,
      ma50: 0.08,
      ma100: 0.079,
      ma150: 0.072,
      ma200: 0.063,
      maw30: 0.073,
      rs: 91.0,
      rsd1d: -2.0,
      rsd5d: 0.0,
      rsd10d: -1.0,
      price_5d_h: 0.08,
      price_5d_L: 0.066,
      price_1m_h: 0.082,
      price_1m_l: 0.057,
      price_3m_h: 0.092,
      price_3m_l: 0.057,
      price_6m_h: 0.105,
      price_6m_l: 0.056,
      price_52w_h: 0.105,
      price_52w_l: 0.026,
      price_pc_1d: -10.127,
      price_pc_1w: -4.054,
      price_pc_1m: -14.458,
      price_pc_6m: -2.74,
      price_pc_1y: 65.116,
      price_pc_ytd: 65.116,
    },
    "0900": {
      id: "0900",
      c: 5.05,
      ma50: 5.048,
      ma100: 5.015,
      ma150: 5.005,
      ma200: 5.01,
      maw30: 5.015,
      rs: 73.0,
      rsd1d: 2.0,
      rsd5d: 1.0,
      rsd10d: 0.0,
      price_5d_h: 5.08,
      price_5d_L: 5.01,
      price_1m_h: 5.11,
      price_1m_l: 4.87,
      price_3m_h: 5.13,
      price_3m_l: 4.87,
      price_6m_h: 5.19,
      price_6m_l: 4.81,
      price_52w_h: 5.6,
      price_52w_l: 4.71,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: 0.198,
      price_pc_6m: 0.0,
      price_pc_1y: -8.182,
      price_pc_ytd: -8.182,
    },
    "0902": {
      id: "0902",
      c: 3.35,
      ma50: 4.071,
      ma100: 4.135,
      ma150: 4.087,
      ma200: 3.788,
      maw30: 4.152,
      rs: 52.0,
      rsd1d: -7.0,
      rsd5d: 4.0,
      rsd10d: -31.0,
      price_5d_h: 3.55,
      price_5d_L: 3.2,
      price_1m_h: 4.8,
      price_1m_l: 3.2,
      price_3m_h: 5.66,
      price_3m_l: 3.2,
      price_6m_h: 5.72,
      price_6m_l: 3.08,
      price_52w_h: 5.72,
      price_52w_l: 2.53,
      price_pc_1d: -4.011,
      price_pc_1w: -2.616,
      price_pc_1m: -26.696,
      price_pc_6m: -20.048,
      price_pc_1y: 22.263,
      price_pc_ytd: 22.263,
    },
    "0904": {
      id: "0904",
      c: 0.087,
      ma50: 0.099,
      ma100: 0.106,
      ma150: 0.122,
      ma200: 0.145,
      maw30: 0.12,
      rs: 17.0,
      rsd1d: 1.0,
      rsd5d: -1.0,
      rsd10d: 3.0,
      price_5d_h: 0.115,
      price_5d_L: 0.087,
      price_1m_h: 0.115,
      price_1m_l: 0.081,
      price_3m_h: 0.137,
      price_3m_l: 0.081,
      price_6m_h: 0.148,
      price_6m_l: 0.081,
      price_52w_h: 0.295,
      price_52w_l: 0.081,
      price_pc_1d: -3.333,
      price_pc_1w: -10.309,
      price_pc_1m: -14.706,
      price_pc_6m: -38.298,
      price_pc_1y: -55.385,
      price_pc_ytd: -55.385,
    },
    "0905": {
      id: "0905",
      c: 0.095,
      ma50: 0.115,
      ma100: 0.131,
      ma150: 0.145,
      ma200: 0.148,
      maw30: 0.144,
      rs: 22.0,
      rsd1d: -12.0,
      rsd5d: 5.0,
      rsd10d: -13.0,
      price_5d_h: 0.138,
      price_5d_L: 0.087,
      price_1m_h: 0.138,
      price_1m_l: 0.087,
      price_3m_h: 0.156,
      price_3m_l: 0.087,
      price_6m_h: 0.32,
      price_6m_l: 0.087,
      price_52w_h: 0.34,
      price_52w_l: 0.087,
      price_pc_1d: -12.037,
      price_pc_1w: 0.0,
      price_pc_1m: -18.103,
      price_pc_6m: -36.667,
      price_pc_1y: -16.667,
      price_pc_ytd: -16.667,
    },
    "0906": {
      id: "0906",
      c: 4.15,
      ma50: 4.354,
      ma100: 4.215,
      ma150: 4.2,
      ma200: 4.305,
      maw30: 4.198,
      rs: 67.0,
      rsd1d: -3.0,
      rsd5d: -10.0,
      rsd10d: -3.0,
      price_5d_h: 4.25,
      price_5d_L: 4.04,
      price_1m_h: 4.42,
      price_1m_l: 3.83,
      price_3m_h: 5.13,
      price_3m_l: 3.83,
      price_6m_h: 5.13,
      price_6m_l: 3.7,
      price_52w_h: 5.27,
      price_52w_l: 3.7,
      price_pc_1d: -2.123,
      price_pc_1w: -2.123,
      price_pc_1m: -3.712,
      price_pc_6m: 1.467,
      price_pc_1y: -8.186,
      price_pc_ytd: -8.186,
    },
    "0907": {
      id: "0907",
      c: 0.58,
      ma50: 0.543,
      ma100: 0.702,
      ma150: 0.887,
      ma200: 1.028,
      maw30: 0.874,
      rs: 12.0,
      rsd1d: 2.0,
      rsd5d: -3.0,
      rsd10d: 3.0,
      price_5d_h: 0.68,
      price_5d_L: 0.55,
      price_1m_h: 0.7,
      price_1m_l: 0.34,
      price_3m_h: 0.78,
      price_3m_l: 0.34,
      price_6m_h: 1.36,
      price_6m_l: 0.34,
      price_52w_h: 1.81,
      price_52w_l: 0.34,
      price_pc_1d: 0.0,
      price_pc_1w: -10.769,
      price_pc_1m: 27.473,
      price_pc_6m: -56.716,
      price_pc_1y: -61.842,
      price_pc_ytd: -61.842,
    },
    "0909": {
      id: "0909",
      c: 10.78,
      ma50: 14.279,
      ma100: 17.641,
      ma150: 20.816,
      ma200: 24.138,
      maw30: 20.996,
      rs: 5.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: -1.0,
      price_5d_h: 11.22,
      price_5d_L: 9.38,
      price_1m_h: 16.3,
      price_1m_l: 7.0,
      price_3m_h: 19.78,
      price_3m_l: 7.0,
      price_6m_h: 31.1,
      price_6m_l: 7.0,
      price_52w_h: 42.35,
      price_52w_l: 7.0,
      price_pc_1d: -0.185,
      price_pc_1w: -1.821,
      price_pc_1m: -29.726,
      price_pc_6m: -60.074,
      price_pc_1y: -73.117,
      price_pc_ytd: -73.117,
    },
    "0911": {
      id: "0911",
      c: 0.089,
      ma50: 0.085,
      ma100: 0.081,
      ma150: 0.086,
      ma200: 0.092,
      maw30: 0.084,
      rs: 65.0,
      rsd1d: -4.0,
      rsd5d: -27.0,
      rsd10d: 11.0,
      price_5d_h: 0.114,
      price_5d_L: 0.088,
      price_1m_h: 0.138,
      price_1m_l: 0.061,
      price_3m_h: 0.138,
      price_3m_l: 0.061,
      price_6m_h: 0.138,
      price_6m_l: 0.061,
      price_52w_h: 0.149,
      price_52w_l: 0.061,
      price_pc_1d: -2.198,
      price_pc_1w: -28.8,
      price_pc_1m: 2.299,
      price_pc_6m: -3.261,
      price_pc_1y: -34.074,
      price_pc_ytd: -34.074,
    },
    "0913": {
      id: "0913",
      c: 0.89,
      ma50: 0.951,
      ma100: 0.824,
      ma150: 0.661,
      ma200: 0.571,
      maw30: 0.67,
      rs: 97.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: -1.0,
      price_5d_h: 0.98,
      price_5d_L: 0.81,
      price_1m_h: 1.1,
      price_1m_l: 0.64,
      price_3m_h: 1.3,
      price_3m_l: 0.64,
      price_6m_h: 1.3,
      price_6m_l: 0.28,
      price_52w_h: 1.3,
      price_52w_l: 0.25,
      price_pc_1d: 4.706,
      price_pc_1w: 3.488,
      price_pc_1m: -5.319,
      price_pc_6m: 165.672,
      price_pc_1y: 169.697,
      price_pc_ytd: 169.697,
    },
    "0914": {
      id: "0914",
      c: 40.3,
      ma50: 41.196,
      ma100: 39.978,
      ma150: 41.012,
      ma200: 40.942,
      maw30: 41.168,
      rs: 67.0,
      rsd1d: 2.0,
      rsd5d: 15.0,
      rsd10d: 5.0,
      price_5d_h: 41.0,
      price_5d_L: 37.7,
      price_1m_h: 42.9,
      price_1m_l: 34.05,
      price_3m_h: 45.6,
      price_3m_l: 34.05,
      price_6m_h: 45.6,
      price_6m_l: 34.05,
      price_52w_h: 50.9,
      price_52w_l: 34.05,
      price_pc_1d: 0.124,
      price_pc_1w: 5.359,
      price_pc_1m: -3.819,
      price_pc_6m: -4.048,
      price_pc_1y: -20.356,
      price_pc_ytd: -20.356,
    },
    "0915": {
      id: "0915",
      c: 0.086,
      ma50: 0.094,
      ma100: 0.117,
      ma150: 0.164,
      ma200: 0.215,
      maw30: 0.162,
      rs: 17.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: 6.0,
      price_5d_h: 0.103,
      price_5d_L: 0.084,
      price_1m_h: 0.11,
      price_1m_l: 0.072,
      price_3m_h: 0.125,
      price_3m_l: 0.072,
      price_6m_h: 0.29,
      price_6m_l: 0.072,
      price_52w_h: 0.66,
      price_52w_l: 0.06,
      price_pc_1d: -11.34,
      price_pc_1w: -14.0,
      price_pc_1m: -4.444,
      price_pc_6m: -65.6,
      price_pc_1y: -4.444,
      price_pc_ytd: -4.444,
    },
    "0916": {
      id: "0916",
      c: 17.84,
      ma50: 16.255,
      ma100: 16.572,
      ma150: 16.702,
      ma200: 15.906,
      maw30: 16.792,
      rs: 89.0,
      rsd1d: -1.0,
      rsd5d: 4.0,
      rsd10d: -2.0,
      price_5d_h: 18.18,
      price_5d_L: 15.5,
      price_1m_h: 19.12,
      price_1m_l: 14.24,
      price_3m_h: 19.8,
      price_3m_l: 14.24,
      price_6m_h: 19.8,
      price_6m_l: 14.24,
      price_52w_h: 20.25,
      price_52w_l: 9.71,
      price_pc_1d: 0.112,
      price_pc_1w: 9.852,
      price_pc_1m: 11.5,
      price_pc_6m: -7.277,
      price_pc_1y: 65.185,
      price_pc_ytd: 65.185,
    },
    "0918": {
      id: "0918",
      c: 0.32,
      ma50: 0.331,
      ma100: 0.333,
      ma150: 0.346,
      ma200: 0.356,
      maw30: 0.344,
      rs: 55.0,
      rsd1d: 1.0,
      rsd5d: 5.0,
      rsd10d: 9.0,
      price_5d_h: 0.335,
      price_5d_L: 0.32,
      price_1m_h: 0.345,
      price_1m_l: 0.28,
      price_3m_h: 0.36,
      price_3m_l: 0.28,
      price_6m_h: 0.48,
      price_6m_l: 0.28,
      price_52w_h: 0.55,
      price_52w_l: 0.26,
      price_pc_1d: 0.0,
      price_pc_1w: -4.478,
      price_pc_1m: -7.246,
      price_pc_6m: -1.538,
      price_pc_1y: -26.627,
      price_pc_ytd: -26.627,
    },
    "0921": {
      id: "0921",
      c: 7.46,
      ma50: 9.033,
      ma100: 8.947,
      ma150: 8.886,
      ma200: 9.164,
      maw30: 8.932,
      rs: 28.0,
      rsd1d: -4.0,
      rsd5d: -9.0,
      rsd10d: -10.0,
      price_5d_h: 8.16,
      price_5d_L: 7.45,
      price_1m_h: 9.07,
      price_1m_l: 6.6,
      price_3m_h: 11.38,
      price_3m_l: 6.6,
      price_6m_h: 11.38,
      price_6m_l: 6.6,
      price_52w_h: 14.56,
      price_52w_l: 6.6,
      price_pc_1d: -4.236,
      price_pc_1w: -5.808,
      price_pc_1m: -14.743,
      price_pc_6m: -11.611,
      price_pc_1y: -46.562,
      price_pc_ytd: -46.562,
    },
    "0922": {
      id: "0922",
      c: 0.169,
      ma50: 0.172,
      ma100: 0.185,
      ma150: 0.194,
      ma200: 0.2,
      maw30: 0.193,
      rs: 57.0,
      rsd1d: 5.0,
      rsd5d: 9.0,
      rsd10d: 19.0,
      price_5d_h: 0.171,
      price_5d_L: 0.16,
      price_1m_h: 0.171,
      price_1m_l: 0.148,
      price_3m_h: 0.201,
      price_3m_l: 0.148,
      price_6m_h: 0.222,
      price_6m_l: 0.148,
      price_52w_h: 0.27,
      price_52w_l: 0.141,
      price_pc_1d: 0.595,
      price_pc_1w: 2.424,
      price_pc_1m: 0.0,
      price_pc_6m: -16.749,
      price_pc_1y: 14.966,
      price_pc_ytd: 14.966,
    },
    "0923": {
      id: "0923",
      c: 0.036,
      ma50: 0.042,
      ma100: 0.044,
      ma150: 0.047,
      ma200: 0.049,
      maw30: 0.047,
      rs: 23.0,
      rsd1d: 2.0,
      rsd5d: 4.0,
      rsd10d: 0.0,
      price_5d_h: 0.036,
      price_5d_L: 0.035,
      price_1m_h: 0.042,
      price_1m_l: 0.031,
      price_3m_h: 0.052,
      price_3m_l: 0.031,
      price_6m_h: 0.056,
      price_6m_l: 0.031,
      price_52w_h: 0.069,
      price_52w_l: 0.031,
      price_pc_1d: 0.0,
      price_pc_1w: 2.857,
      price_pc_1m: -23.404,
      price_pc_6m: -29.412,
      price_pc_1y: -40.984,
      price_pc_ytd: -40.984,
    },
    "0925": {
      id: "0925",
      c: 0.084,
      ma50: 0.091,
      ma100: 0.094,
      ma150: 0.097,
      ma200: 0.101,
      maw30: 0.098,
      rs: 39.0,
      rsd1d: 4.0,
      rsd5d: 2.0,
      rsd10d: 6.0,
      price_5d_h: 0.088,
      price_5d_L: 0.075,
      price_1m_h: 0.107,
      price_1m_l: 0.065,
      price_3m_h: 0.107,
      price_3m_l: 0.065,
      price_6m_h: 0.108,
      price_6m_l: 0.065,
      price_52w_h: 0.131,
      price_52w_l: 0.065,
      price_pc_1d: 2.439,
      price_pc_1w: 1.205,
      price_pc_1m: -13.402,
      price_pc_6m: -19.231,
      price_pc_1y: -21.495,
      price_pc_ytd: -21.495,
    },
    "0926": {
      id: "0926",
      c: 0.11,
      ma50: 0.145,
      ma100: 0.17,
      ma150: 0.209,
      ma200: 0.25,
      maw30: 0.208,
      rs: 5.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: -2.0,
      price_5d_h: 0.131,
      price_5d_L: 0.106,
      price_1m_h: 0.159,
      price_1m_l: 0.106,
      price_3m_h: 0.192,
      price_3m_l: 0.106,
      price_6m_h: 0.32,
      price_6m_l: 0.106,
      price_52w_h: 0.49,
      price_52w_l: 0.106,
      price_pc_1d: -5.172,
      price_pc_1w: -1.786,
      price_pc_1m: -28.105,
      price_pc_6m: -64.516,
      price_pc_1y: -66.667,
      price_pc_ytd: -66.667,
    },
    "0928": {
      id: "0928",
      c: 0.121,
      ma50: 0.103,
      ma100: 0.112,
      ma150: 0.119,
      ma200: 0.119,
      maw30: 0.12,
      rs: 68.0,
      rsd1d: -3.0,
      rsd5d: 50.0,
      rsd10d: 51.0,
      price_5d_h: 0.125,
      price_5d_L: 0.074,
      price_1m_h: 0.125,
      price_1m_l: 0.05,
      price_3m_h: 0.128,
      price_3m_l: 0.05,
      price_6m_h: 0.171,
      price_6m_l: 0.05,
      price_52w_h: 0.28,
      price_52w_l: 0.05,
      price_pc_1d: -2.419,
      price_pc_1w: 30.108,
      price_pc_1m: 10.0,
      price_pc_6m: -27.108,
      price_pc_1y: -42.381,
      price_pc_ytd: -42.381,
    },
    "0929": {
      id: "0929",
      c: 0.76,
      ma50: 0.891,
      ma100: 0.894,
      ma150: 0.913,
      ma200: 0.856,
      maw30: 0.936,
      rs: 60.0,
      rsd1d: -8.0,
      rsd5d: -24.0,
      rsd10d: -23.0,
      price_5d_h: 0.83,
      price_5d_L: 0.76,
      price_1m_h: 0.93,
      price_1m_l: 0.72,
      price_3m_h: 1.15,
      price_3m_l: 0.72,
      price_6m_h: 1.15,
      price_6m_l: 0.72,
      price_52w_h: 1.32,
      price_52w_l: 0.6,
      price_pc_1d: -5.0,
      price_pc_1w: -14.607,
      price_pc_1m: -15.556,
      price_pc_6m: -25.49,
      price_pc_1y: 7.042,
      price_pc_ytd: 7.042,
    },
    "0931": {
      id: "0931",
      c: 0.28,
      ma50: 0.337,
      ma100: 0.36,
      ma150: 0.374,
      ma200: 0.381,
      maw30: 0.374,
      rs: 22.0,
      rsd1d: 1.0,
      rsd5d: -2.0,
      rsd10d: -8.0,
      price_5d_h: 0.31,
      price_5d_L: 0.275,
      price_1m_h: 0.365,
      price_1m_l: 0.275,
      price_3m_h: 0.4,
      price_3m_l: 0.275,
      price_6m_h: 0.57,
      price_6m_l: 0.275,
      price_52w_h: 0.57,
      price_52w_l: 0.275,
      price_pc_1d: -1.754,
      price_pc_1w: -6.667,
      price_pc_1m: -21.127,
      price_pc_6m: -20.0,
      price_pc_1y: -40.426,
      price_pc_ytd: -40.426,
    },
    "0932": {
      id: "0932",
      c: 0.168,
      ma50: 0.196,
      ma100: 0.245,
      ma150: 0.275,
      ma200: 0.243,
      maw30: 0.28,
      rs: 33.0,
      rsd1d: -1.0,
      rsd5d: -39.0,
      rsd10d: -24.0,
      price_5d_h: 0.187,
      price_5d_L: 0.156,
      price_1m_h: 0.218,
      price_1m_l: 0.156,
      price_3m_h: 0.3,
      price_3m_l: 0.156,
      price_6m_h: 0.44,
      price_6m_l: 0.156,
      price_52w_h: 0.44,
      price_52w_l: 0.107,
      price_pc_1d: -1.176,
      price_pc_1w: -10.638,
      price_pc_1m: -15.578,
      price_pc_6m: -57.468,
      price_pc_1y: 16.667,
      price_pc_ytd: 16.667,
    },
    "0934": {
      id: "0934",
      c: 2.72,
      ma50: 2.928,
      ma100: 2.958,
      ma150: 2.963,
      ma200: 2.945,
      maw30: 2.97,
      rs: 57.0,
      rsd1d: 1.0,
      rsd5d: -8.0,
      rsd10d: -8.0,
      price_5d_h: 2.89,
      price_5d_L: 2.6,
      price_1m_h: 2.94,
      price_1m_l: 2.47,
      price_3m_h: 3.34,
      price_3m_l: 2.47,
      price_6m_h: 3.34,
      price_6m_l: 2.47,
      price_52w_h: 3.34,
      price_52w_l: 2.47,
      price_pc_1d: -0.73,
      price_pc_1w: -5.882,
      price_pc_1m: -6.849,
      price_pc_6m: -8.725,
      price_pc_1y: -8.418,
      price_pc_ytd: -8.418,
    },
    "0935": {
      id: "0935",
      c: 1.28,
      ma50: 1.25,
      ma100: 1.231,
      ma150: 1.218,
      ma200: 1.148,
      maw30: 1.219,
      rs: 92.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -1.0,
      price_5d_h: 1.28,
      price_5d_L: 1.27,
      price_1m_h: 1.28,
      price_1m_l: 1.25,
      price_3m_h: 1.28,
      price_3m_l: 1.17,
      price_6m_h: 1.28,
      price_6m_l: 1.17,
      price_52w_h: 1.28,
      price_52w_l: 0.68,
      price_pc_1d: 0.787,
      price_pc_1w: 0.0,
      price_pc_1m: 2.4,
      price_pc_6m: 8.475,
      price_pc_1y: 77.778,
      price_pc_ytd: 77.778,
    },
    "0938": {
      id: "0938",
      c: 1.4,
      ma50: 1.511,
      ma100: 1.571,
      ma150: 1.584,
      ma200: 1.607,
      maw30: 1.592,
      rs: 37.0,
      rsd1d: -9.0,
      rsd5d: -8.0,
      rsd10d: -8.0,
      price_5d_h: 1.49,
      price_5d_L: 1.39,
      price_1m_h: 1.56,
      price_1m_l: 1.33,
      price_3m_h: 1.71,
      price_3m_l: 1.33,
      price_6m_h: 1.71,
      price_6m_l: 1.33,
      price_52w_h: 2.36,
      price_52w_l: 1.33,
      price_pc_1d: -6.04,
      price_pc_1w: -4.11,
      price_pc_1m: -3.448,
      price_pc_6m: -17.16,
      price_pc_1y: -33.333,
      price_pc_ytd: -33.333,
    },
    "0939": {
      id: "0939",
      c: 5.89,
      ma50: 5.845,
      ma100: 5.602,
      ma150: 5.572,
      ma200: 5.611,
      maw30: 5.58,
      rs: 77.0,
      rsd1d: 2.0,
      rsd5d: 6.0,
      rsd10d: 5.0,
      price_5d_h: 5.95,
      price_5d_L: 5.65,
      price_1m_h: 5.95,
      price_1m_l: 5.35,
      price_3m_h: 6.23,
      price_3m_l: 5.35,
      price_6m_h: 6.23,
      price_6m_l: 5.03,
      price_52w_h: 6.6,
      price_52w_l: 5.03,
      price_pc_1d: 1.552,
      price_pc_1w: 4.064,
      price_pc_1m: 0.512,
      price_pc_6m: 5.556,
      price_pc_1y: -9.663,
      price_pc_ytd: -9.663,
    },
    "0941": {
      id: "0941",
      c: 54.15,
      ma50: 53.385,
      ma100: 50.482,
      ma150: 49.703,
      ma200: 49.52,
      maw30: 49.732,
      rs: 87.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: 1.0,
      price_5d_h: 56.6,
      price_5d_L: 53.9,
      price_1m_h: 57.1,
      price_1m_l: 47.8,
      price_3m_h: 58.65,
      price_3m_l: 46.8,
      price_6m_h: 58.65,
      price_6m_l: 45.9,
      price_52w_h: 58.65,
      price_52w_l: 45.9,
      price_pc_1d: -0.824,
      price_pc_1w: -3.304,
      price_pc_1m: 2.557,
      price_pc_6m: 15.335,
      price_pc_1y: 6.072,
      price_pc_ytd: 6.072,
    },
    "0943": {
      id: "0943",
      c: 0.02,
      ma50: 0.021,
      ma100: 0.022,
      ma150: 0.023,
      ma200: 0.023,
      maw30: 0.023,
      rs: 40.0,
      rsd1d: 11.0,
      rsd5d: 3.0,
      rsd10d: -6.0,
      price_5d_h: 0.02,
      price_5d_L: 0.018,
      price_1m_h: 0.022,
      price_1m_l: 0.016,
      price_3m_h: 0.025,
      price_3m_l: 0.016,
      price_6m_h: 0.031,
      price_6m_l: 0.016,
      price_52w_h: 0.055,
      price_52w_l: 0.016,
      price_pc_1d: 5.263,
      price_pc_1w: 5.263,
      price_pc_1m: -9.091,
      price_pc_6m: -25.926,
      price_pc_1y: -9.091,
      price_pc_ytd: -9.091,
    },
    "0945": {
      id: "0945",
      c: 167.0,
      ma50: 159.514,
      ma100: 154.774,
      ma150: 153.979,
      ma200: 153.292,
      maw30: 154.0,
      rs: 84.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: 1.0,
      price_5d_h: 167.5,
      price_5d_L: 161.5,
      price_1m_h: 167.5,
      price_1m_l: 149.6,
      price_3m_h: 169.7,
      price_3m_l: 146.5,
      price_6m_h: 169.7,
      price_6m_l: 140.2,
      price_52w_h: 170.8,
      price_52w_l: 140.2,
      price_pc_1d: 0.845,
      price_pc_1w: 2.833,
      price_pc_1m: 7.742,
      price_pc_6m: 10.669,
      price_pc_1y: 0.602,
      price_pc_ytd: 0.602,
    },
    "0947": {
      id: "0947",
      c: 0.247,
      ma50: 0.312,
      ma100: 0.342,
      ma150: 0.355,
      ma200: 0.383,
      maw30: 0.357,
      rs: 12.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: -1.0,
      price_5d_h: 0.285,
      price_5d_L: 0.238,
      price_1m_h: 0.32,
      price_1m_l: 0.227,
      price_3m_h: 0.38,
      price_3m_l: 0.227,
      price_6m_h: 0.425,
      price_6m_l: 0.227,
      price_52w_h: 0.62,
      price_52w_l: 0.227,
      price_pc_1d: -3.137,
      price_pc_1w: -6.792,
      price_pc_1m: -20.323,
      price_pc_6m: -34.133,
      price_pc_1y: -55.091,
      price_pc_ytd: -55.091,
    },
    "0948": {
      id: "0948",
      c: 1.37,
      ma50: 1.497,
      ma100: 1.404,
      ma150: 1.314,
      ma200: 1.192,
      maw30: 1.345,
      rs: 93.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: -1.0,
      price_5d_h: 1.5,
      price_5d_L: 1.37,
      price_1m_h: 1.57,
      price_1m_l: 1.3,
      price_3m_h: 1.78,
      price_3m_l: 1.2,
      price_6m_h: 2.09,
      price_6m_l: 1.01,
      price_52w_h: 2.09,
      price_52w_l: 0.64,
      price_pc_1d: -0.725,
      price_pc_1w: -8.667,
      price_pc_1m: -12.739,
      price_pc_6m: 16.102,
      price_pc_1y: 82.667,
      price_pc_ytd: 82.667,
    },
    "0950": {
      id: "0950",
      c: 2.42,
      ma50: 2.775,
      ma100: 3.075,
      ma150: 3.357,
      ma200: 3.605,
      maw30: 3.351,
      rs: 13.0,
      rsd1d: 2.0,
      rsd5d: -1.0,
      rsd10d: -2.0,
      price_5d_h: 2.55,
      price_5d_L: 2.3,
      price_1m_h: 2.85,
      price_1m_l: 2.05,
      price_3m_h: 3.5,
      price_3m_l: 2.05,
      price_6m_h: 4.14,
      price_6m_l: 2.05,
      price_52w_h: 6.35,
      price_52w_l: 2.05,
      price_pc_1d: 0.833,
      price_pc_1w: -4.724,
      price_pc_1m: -15.088,
      price_pc_6m: -37.789,
      price_pc_1y: -59.801,
      price_pc_ytd: -59.801,
    },
    "0951": {
      id: "0951",
      c: 1.61,
      ma50: 2.0,
      ma100: 2.042,
      ma150: 2.154,
      ma200: 2.286,
      maw30: 2.149,
      rs: 22.0,
      rsd1d: 0.0,
      rsd5d: -10.0,
      rsd10d: -10.0,
      price_5d_h: 1.87,
      price_5d_L: 1.58,
      price_1m_h: 2.03,
      price_1m_l: 1.48,
      price_3m_h: 2.36,
      price_3m_l: 1.48,
      price_6m_h: 2.52,
      price_6m_l: 1.48,
      price_52w_h: 3.88,
      price_52w_l: 1.48,
      price_pc_1d: -1.227,
      price_pc_1w: -11.538,
      price_pc_1m: -17.436,
      price_pc_6m: -27.803,
      price_pc_1y: -45.791,
      price_pc_ytd: -45.791,
    },
    "0953": {
      id: "0953",
      c: 0.114,
      ma50: 0.112,
      ma100: 0.111,
      ma150: 0.115,
      ma200: 0.12,
      maw30: 0.115,
      rs: 62.0,
      rsd1d: 2.0,
      rsd5d: -2.0,
      rsd10d: 4.0,
      price_5d_h: 0.118,
      price_5d_L: 0.11,
      price_1m_h: 0.122,
      price_1m_l: 0.098,
      price_3m_h: 0.122,
      price_3m_l: 0.098,
      price_6m_h: 0.129,
      price_6m_l: 0.095,
      price_52w_h: 0.16,
      price_52w_l: 0.095,
      price_pc_1d: 0.0,
      price_pc_1w: -3.39,
      price_pc_1m: 1.786,
      price_pc_6m: -8.8,
      price_pc_1y: -5.0,
      price_pc_ytd: -5.0,
    },
    "0954": {
      id: "0954",
      c: 0.71,
      ma50: 0.688,
      ma100: 0.684,
      ma150: 0.681,
      ma200: 0.683,
      maw30: 0.683,
      rs: 78.0,
      rsd1d: -4.0,
      rsd5d: 7.0,
      rsd10d: 14.0,
      price_5d_h: 0.72,
      price_5d_L: 0.68,
      price_1m_h: 0.72,
      price_1m_l: 0.61,
      price_3m_h: 0.73,
      price_3m_l: 0.61,
      price_6m_h: 0.77,
      price_6m_l: 0.61,
      price_52w_h: 0.79,
      price_52w_l: 0.61,
      price_pc_1d: -1.389,
      price_pc_1w: 4.412,
      price_pc_1m: 4.412,
      price_pc_6m: 0.0,
      price_pc_1y: 5.97,
      price_pc_ytd: 5.97,
    },
    "0956": {
      id: "0956",
      c: 4.43,
      ma50: 4.789,
      ma100: 5.311,
      ma150: 5.703,
      ma200: 5.277,
      maw30: 5.803,
      rs: 63.0,
      rsd1d: -3.0,
      rsd5d: -4.0,
      rsd10d: -9.0,
      price_5d_h: 4.62,
      price_5d_L: 4.2,
      price_1m_h: 5.32,
      price_1m_l: 3.64,
      price_3m_h: 6.77,
      price_3m_l: 3.64,
      price_6m_h: 8.2,
      price_6m_l: 3.64,
      price_52w_h: 8.23,
      price_52w_l: 2.8,
      price_pc_1d: -3.063,
      price_pc_1w: -4.113,
      price_pc_1m: -14.479,
      price_pc_6m: -43.205,
      price_pc_1y: 49.662,
      price_pc_ytd: 49.662,
    },
    "0959": {
      id: "0959",
      c: 0.385,
      ma50: 0.384,
      ma100: 0.4,
      ma150: 0.405,
      ma200: 0.41,
      maw30: 0.403,
      rs: 54.0,
      rsd1d: 2.0,
      rsd5d: 10.0,
      rsd10d: 36.0,
      price_5d_h: 0.39,
      price_5d_L: 0.31,
      price_1m_h: 0.4,
      price_1m_l: 0.21,
      price_3m_h: 0.43,
      price_3m_l: 0.21,
      price_6m_h: 0.45,
      price_6m_l: 0.21,
      price_52w_h: 0.56,
      price_52w_l: 0.21,
      price_pc_1d: -1.282,
      price_pc_1w: 2.667,
      price_pc_1m: -3.75,
      price_pc_6m: -9.412,
      price_pc_1y: -28.704,
      price_pc_ytd: -28.704,
    },
    "0960": {
      id: "0960",
      c: 40.3,
      ma50: 41.796,
      ma100: 40.19,
      ma150: 38.542,
      ma200: 39.062,
      maw30: 39.048,
      rs: 75.0,
      rsd1d: 6.0,
      rsd5d: 3.0,
      rsd10d: 3.0,
      price_5d_h: 41.0,
      price_5d_L: 37.0,
      price_1m_h: 42.35,
      price_1m_l: 27.55,
      price_3m_h: 47.75,
      price_3m_l: 27.55,
      price_6m_h: 47.75,
      price_6m_l: 27.55,
      price_52w_h: 52.8,
      price_52w_l: 27.55,
      price_pc_1d: 2.545,
      price_pc_1w: 4.404,
      price_pc_1m: -3.589,
      price_pc_6m: 12.256,
      price_pc_1y: -20.119,
      price_pc_ytd: -20.119,
    },
    "0966": {
      id: "0966",
      c: 9.59,
      ma50: 10.155,
      ma100: 10.646,
      ma150: 11.107,
      ma200: 11.337,
      maw30: 11.155,
      rs: 34.0,
      rsd1d: 2.0,
      rsd5d: 0.0,
      rsd10d: 5.0,
      price_5d_h: 9.84,
      price_5d_L: 9.3,
      price_1m_h: 9.99,
      price_1m_l: 7.8,
      price_3m_h: 12.1,
      price_3m_l: 7.8,
      price_6m_h: 13.0,
      price_6m_l: 7.8,
      price_52w_h: 16.2,
      price_52w_l: 7.8,
      price_pc_1d: -0.725,
      price_pc_1w: -2.342,
      price_pc_1m: 3.9,
      price_pc_6m: -19.003,
      price_pc_1y: -39.686,
      price_pc_ytd: -39.686,
    },
    "0968": {
      id: "0968",
      c: 13.82,
      ma50: 13.511,
      ma100: 13.589,
      ma150: 14.525,
      ma200: 14.847,
      maw30: 14.439,
      rs: 69.0,
      rsd1d: -3.0,
      rsd5d: 0.0,
      rsd10d: -2.0,
      price_5d_h: 14.32,
      price_5d_L: 13.02,
      price_1m_h: 14.84,
      price_1m_l: 12.32,
      price_3m_h: 14.84,
      price_3m_l: 11.84,
      price_6m_h: 16.98,
      price_6m_l: 11.84,
      price_52w_h: 19.36,
      price_52w_l: 9.9,
      price_pc_1d: -2.263,
      price_pc_1w: -0.861,
      price_pc_1m: -2.125,
      price_pc_6m: -13.409,
      price_pc_1y: -3.626,
      price_pc_ytd: -3.626,
    },
    "0970": {
      id: "0970",
      c: 0.12,
      ma50: 0.145,
      ma100: 0.165,
      ma150: 0.166,
      ma200: 0.176,
      maw30: 0.167,
      rs: 15.0,
      rsd1d: 3.0,
      rsd5d: 3.0,
      rsd10d: 0.0,
      price_5d_h: 0.13,
      price_5d_L: 0.114,
      price_1m_h: 0.143,
      price_1m_l: 0.11,
      price_3m_h: 0.2,
      price_3m_l: 0.11,
      price_6m_h: 0.207,
      price_6m_l: 0.11,
      price_52w_h: 0.255,
      price_52w_l: 0.11,
      price_pc_1d: 0.84,
      price_pc_1w: 0.0,
      price_pc_1m: -14.286,
      price_pc_6m: -29.412,
      price_pc_1y: -51.417,
      price_pc_ytd: -51.417,
    },
    "0973": {
      id: "0973",
      c: 25.45,
      ma50: 27.058,
      ma100: 29.063,
      ma150: 28.232,
      ma200: 28.234,
      maw30: 28.36,
      rs: 62.0,
      rsd1d: -2.0,
      rsd5d: 2.0,
      rsd10d: 13.0,
      price_5d_h: 26.5,
      price_5d_L: 23.8,
      price_1m_h: 27.0,
      price_1m_l: 20.5,
      price_3m_h: 34.35,
      price_3m_l: 20.5,
      price_6m_h: 34.35,
      price_6m_l: 20.5,
      price_52w_h: 34.35,
      price_52w_l: 20.5,
      price_pc_1d: -3.232,
      price_pc_1w: 1.193,
      price_pc_1m: -5.741,
      price_pc_6m: -3.048,
      price_pc_1y: 15.42,
      price_pc_ytd: 15.42,
    },
    "0975": {
      id: "0975",
      c: 2.94,
      ma50: 2.088,
      ma100: 1.95,
      ma150: 2.237,
      ma200: 2.231,
      maw30: 2.215,
      rs: 94.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: 29.0,
      price_5d_h: 3.12,
      price_5d_L: 2.52,
      price_1m_h: 3.42,
      price_1m_l: 1.75,
      price_3m_h: 3.42,
      price_3m_l: 1.41,
      price_6m_h: 3.42,
      price_6m_l: 1.41,
      price_52w_h: 4.35,
      price_52w_l: 1.41,
      price_pc_1d: 1.031,
      price_pc_1w: -5.161,
      price_pc_1m: 50.769,
      price_pc_6m: -8.125,
      price_pc_1y: 56.383,
      price_pc_ytd: 56.383,
    },
    "0976": {
      id: "0976",
      c: 0.81,
      ma50: 0.805,
      ma100: 0.936,
      ma150: 1.018,
      ma200: 0.976,
      maw30: 1.036,
      rs: 83.0,
      rsd1d: -1.0,
      rsd5d: -2.0,
      rsd10d: -1.0,
      price_5d_h: 0.86,
      price_5d_L: 0.69,
      price_1m_h: 1.0,
      price_1m_l: 0.63,
      price_3m_h: 1.06,
      price_3m_l: 0.63,
      price_6m_h: 1.37,
      price_6m_l: 0.63,
      price_52w_h: 1.65,
      price_52w_l: 0.29,
      price_pc_1d: -1.22,
      price_pc_1w: -3.571,
      price_pc_1m: 15.714,
      price_pc_6m: -36.719,
      price_pc_1y: 134.783,
      price_pc_ytd: 134.783,
    },
    "0978": {
      id: "0978",
      c: 0.73,
      ma50: 0.791,
      ma100: 0.789,
      ma150: 0.831,
      ma200: 0.89,
      maw30: 0.831,
      rs: 39.0,
      rsd1d: -4.0,
      rsd5d: -9.0,
      rsd10d: -18.0,
      price_5d_h: 0.77,
      price_5d_L: 0.72,
      price_1m_h: 0.81,
      price_1m_l: 0.65,
      price_3m_h: 0.87,
      price_3m_l: 0.65,
      price_6m_h: 0.91,
      price_6m_l: 0.65,
      price_52w_h: 1.25,
      price_52w_l: 0.65,
      price_pc_1d: -2.667,
      price_pc_1w: -5.195,
      price_pc_1m: -6.41,
      price_pc_6m: -17.978,
      price_pc_1y: -37.069,
      price_pc_ytd: -37.069,
    },
    "0979": {
      id: "0979",
      c: 0.225,
      ma50: 0.24,
      ma100: 0.245,
      ma150: 0.248,
      ma200: 0.253,
      maw30: 0.248,
      rs: 44.0,
      rsd1d: -4.0,
      rsd5d: 1.0,
      rsd10d: 0.0,
      price_5d_h: 0.231,
      price_5d_L: 0.211,
      price_1m_h: 0.239,
      price_1m_l: 0.21,
      price_3m_h: 0.28,
      price_3m_l: 0.202,
      price_6m_h: 0.28,
      price_6m_l: 0.201,
      price_52w_h: 0.325,
      price_52w_l: 0.201,
      price_pc_1d: -2.174,
      price_pc_1w: -3.433,
      price_pc_1m: -1.316,
      price_pc_6m: -8.537,
      price_pc_1y: -22.414,
      price_pc_ytd: -22.414,
    },
    "0980": {
      id: "0980",
      c: 0.61,
      ma50: 0.648,
      ma100: 0.753,
      ma150: 0.82,
      ma200: 0.866,
      maw30: 0.818,
      rs: 18.0,
      rsd1d: 5.0,
      rsd5d: 6.0,
      rsd10d: 8.0,
      price_5d_h: 0.61,
      price_5d_L: 0.51,
      price_1m_h: 0.67,
      price_1m_l: 0.45,
      price_3m_h: 0.89,
      price_3m_l: 0.45,
      price_6m_h: 1.01,
      price_6m_l: 0.45,
      price_52w_h: 1.19,
      price_52w_l: 0.45,
      price_pc_1d: 5.172,
      price_pc_1w: 7.018,
      price_pc_1m: -8.955,
      price_pc_6m: -35.789,
      price_pc_1y: -47.863,
      price_pc_ytd: -47.863,
    },
    "0981": {
      id: "0981",
      c: 17.14,
      ma50: 18.62,
      ma100: 19.478,
      ma150: 20.535,
      ma200: 21.386,
      maw30: 20.482,
      rs: 36.0,
      rsd1d: 0.0,
      rsd5d: -4.0,
      rsd10d: 1.0,
      price_5d_h: 18.12,
      price_5d_L: 16.52,
      price_1m_h: 19.78,
      price_1m_l: 14.96,
      price_3m_h: 20.95,
      price_3m_l: 14.96,
      price_6m_h: 24.3,
      price_6m_l: 14.96,
      price_52w_h: 30.8,
      price_52w_l: 14.96,
      price_pc_1d: -1.153,
      price_pc_1w: -2.834,
      price_pc_1m: -12.551,
      price_pc_6m: -22.268,
      price_pc_1y: -33.822,
      price_pc_ytd: -33.822,
    },
    "0982": {
      id: "0982",
      c: 0.136,
      ma50: 0.146,
      ma100: 0.155,
      ma150: 0.165,
      ma200: 0.173,
      maw30: 0.165,
      rs: 31.0,
      rsd1d: -2.0,
      rsd5d: -7.0,
      rsd10d: 9.0,
      price_5d_h: 0.155,
      price_5d_L: 0.125,
      price_1m_h: 0.155,
      price_1m_l: 0.105,
      price_3m_h: 0.167,
      price_3m_l: 0.105,
      price_6m_h: 0.191,
      price_6m_l: 0.105,
      price_52w_h: 0.245,
      price_52w_l: 0.105,
      price_pc_1d: -4.895,
      price_pc_1w: -9.333,
      price_pc_1m: -4.225,
      price_pc_6m: -26.486,
      price_pc_1y: -38.462,
      price_pc_ytd: -38.462,
    },
    "0983": {
      id: "0983",
      c: 1.35,
      ma50: 1.285,
      ma100: 1.313,
      ma150: 1.362,
      ma200: 1.407,
      maw30: 1.36,
      rs: 73.0,
      rsd1d: 24.0,
      rsd5d: 31.0,
      rsd10d: 25.0,
      price_5d_h: 1.36,
      price_5d_L: 1.17,
      price_1m_h: 1.36,
      price_1m_l: 1.08,
      price_3m_h: 1.47,
      price_3m_l: 1.08,
      price_6m_h: 1.68,
      price_6m_l: 1.08,
      price_52w_h: 1.8,
      price_52w_l: 1.08,
      price_pc_1d: 13.445,
      price_pc_1w: 16.379,
      price_pc_1m: 1.504,
      price_pc_6m: -6.897,
      price_pc_1y: 8.871,
      price_pc_ytd: 8.871,
    },
    "0984": {
      id: "0984",
      c: 1.25,
      ma50: 1.285,
      ma100: 1.327,
      ma150: 1.379,
      ma200: 1.451,
      maw30: 1.385,
      rs: 37.0,
      rsd1d: 2.0,
      rsd5d: 5.0,
      rsd10d: 2.0,
      price_5d_h: 1.25,
      price_5d_L: 1.2,
      price_1m_h: 1.32,
      price_1m_l: 1.15,
      price_3m_h: 1.4,
      price_3m_l: 1.15,
      price_6m_h: 1.53,
      price_6m_l: 1.15,
      price_52w_h: 2.0,
      price_52w_l: 1.15,
      price_pc_1d: 0.0,
      price_pc_1w: -1.575,
      price_pc_1m: -3.846,
      price_pc_6m: -17.763,
      price_pc_1y: -36.548,
      price_pc_ytd: -36.548,
    },
    "0985": {
      id: "0985",
      c: 2.4,
      ma50: 2.405,
      ma100: 2.278,
      ma150: 2.032,
      ma200: 1.98,
      maw30: 2.04,
      rs: 89.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: -4.0,
      price_5d_h: 2.41,
      price_5d_L: 2.35,
      price_1m_h: 2.6,
      price_1m_l: 2.34,
      price_3m_h: 2.66,
      price_3m_l: 2.12,
      price_6m_h: 2.66,
      price_6m_l: 1.23,
      price_52w_h: 3.4,
      price_52w_l: 1.23,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: -0.415,
      price_pc_6m: 51.899,
      price_pc_1y: 21.212,
      price_pc_ytd: 21.212,
    },
    "0986": {
      id: "0986",
      c: 0.087,
      ma50: 0.083,
      ma100: 0.09,
      ma150: 0.099,
      ma200: 0.102,
      maw30: 0.099,
      rs: 28.0,
      rsd1d: 0.0,
      rsd5d: 4.0,
      rsd10d: 15.0,
      price_5d_h: 0.088,
      price_5d_L: 0.075,
      price_1m_h: 0.102,
      price_1m_l: 0.058,
      price_3m_h: 0.102,
      price_3m_l: 0.058,
      price_6m_h: 0.142,
      price_6m_l: 0.058,
      price_52w_h: 0.305,
      price_52w_l: 0.058,
      price_pc_1d: -1.136,
      price_pc_1w: -1.136,
      price_pc_1m: 3.571,
      price_pc_6m: -23.684,
      price_pc_1y: -71.0,
      price_pc_ytd: -71.0,
    },
    "0987": {
      id: "0987",
      c: 0.275,
      ma50: 0.297,
      ma100: 0.301,
      ma150: 0.315,
      ma200: 0.295,
      maw30: 0.317,
      rs: 70.0,
      rsd1d: -13.0,
      rsd5d: -14.0,
      rsd10d: -19.0,
      price_5d_h: 0.285,
      price_5d_L: 0.265,
      price_1m_h: 0.305,
      price_1m_l: 0.24,
      price_3m_h: 0.35,
      price_3m_l: 0.24,
      price_6m_h: 0.415,
      price_6m_l: 0.24,
      price_52w_h: 0.435,
      price_52w_l: 0.2,
      price_pc_1d: -3.509,
      price_pc_1w: 0.0,
      price_pc_1m: -11.29,
      price_pc_6m: -32.099,
      price_pc_1y: 33.495,
      price_pc_ytd: 33.495,
    },
    "0988": {
      id: "0988",
      c: 0.205,
      ma50: 0.155,
      ma100: 0.144,
      ma150: 0.15,
      ma200: 0.157,
      maw30: 0.152,
      rs: 89.0,
      rsd1d: -1.0,
      rsd5d: -3.0,
      rsd10d: 78.0,
      price_5d_h: 0.26,
      price_5d_L: 0.153,
      price_1m_h: 0.26,
      price_1m_l: 0.098,
      price_3m_h: 0.26,
      price_3m_l: 0.098,
      price_6m_h: 0.35,
      price_6m_l: 0.046,
      price_52w_h: 0.35,
      price_52w_l: 0.046,
      price_pc_1d: 2.5,
      price_pc_1w: -21.154,
      price_pc_1m: 57.692,
      price_pc_6m: 28.125,
      price_pc_1y: -37.879,
      price_pc_ytd: -37.879,
    },
    "0989": {
      id: "0989",
      c: 0.41,
      ma50: 0.455,
      ma100: 0.507,
      ma150: 0.469,
      ma200: 0.45,
      maw30: 0.476,
      rs: 45.0,
      rsd1d: -3.0,
      rsd5d: 11.0,
      rsd10d: 5.0,
      price_5d_h: 0.425,
      price_5d_L: 0.38,
      price_1m_h: 0.455,
      price_1m_l: 0.36,
      price_3m_h: 0.65,
      price_3m_l: 0.36,
      price_6m_h: 0.85,
      price_6m_l: 0.36,
      price_52w_h: 0.85,
      price_52w_l: 0.305,
      price_pc_1d: 1.235,
      price_pc_1w: -1.205,
      price_pc_1m: -8.889,
      price_pc_6m: 1.235,
      price_pc_1y: 1.235,
      price_pc_ytd: 1.235,
    },
    "0990": {
      id: "0990",
      c: 1.1,
      ma50: 1.008,
      ma100: 0.906,
      ma150: 0.944,
      ma200: 1.055,
      maw30: 0.916,
      rs: 93.0,
      rsd1d: -1.0,
      rsd5d: -2.0,
      rsd10d: -3.0,
      price_5d_h: 1.14,
      price_5d_L: 1.05,
      price_1m_h: 1.32,
      price_1m_l: 0.91,
      price_3m_h: 1.32,
      price_3m_l: 0.71,
      price_6m_h: 1.32,
      price_6m_l: 0.67,
      price_52w_h: 1.7,
      price_52w_l: 0.45,
      price_pc_1d: -1.786,
      price_pc_1w: -0.901,
      price_pc_1m: -13.386,
      price_pc_6m: 20.879,
      price_pc_1y: 144.444,
      price_pc_ytd: 144.444,
    },
    "0991": {
      id: "0991",
      c: 1.13,
      ma50: 1.388,
      ma100: 1.427,
      ma150: 1.423,
      ma200: 1.379,
      maw30: 1.438,
      rs: 30.0,
      rsd1d: -4.0,
      rsd5d: 3.0,
      rsd10d: -16.0,
      price_5d_h: 1.2,
      price_5d_L: 1.11,
      price_1m_h: 1.57,
      price_1m_l: 1.08,
      price_3m_h: 1.87,
      price_3m_l: 1.08,
      price_6m_h: 1.92,
      price_6m_l: 1.08,
      price_52w_h: 1.92,
      price_52w_l: 1.08,
      price_pc_1d: -5.833,
      price_pc_1w: -2.586,
      price_pc_1m: -26.623,
      price_pc_6m: -24.667,
      price_pc_1y: -8.871,
      price_pc_ytd: -8.871,
    },
    "0992": {
      id: "0992",
      c: 8.52,
      ma50: 8.372,
      ma100: 8.424,
      ma150: 8.379,
      ma200: 8.344,
      maw30: 8.391,
      rs: 62.0,
      rsd1d: 3.0,
      rsd5d: 0.0,
      rsd10d: 3.0,
      price_5d_h: 8.63,
      price_5d_L: 8.26,
      price_1m_h: 8.96,
      price_1m_l: 7.05,
      price_3m_h: 9.44,
      price_3m_l: 7.05,
      price_6m_h: 9.65,
      price_6m_l: 7.05,
      price_52w_h: 11.38,
      price_52w_l: 6.96,
      price_pc_1d: 0.472,
      price_pc_1w: 0.828,
      price_pc_1m: -1.956,
      price_pc_6m: 1.792,
      price_pc_1y: -21.111,
      price_pc_ytd: -21.111,
    },
    "0994": {
      id: "0994",
      c: 1.47,
      ma50: 1.994,
      ma100: 2.132,
      ma150: 2.178,
      ma200: 2.201,
      maw30: 2.19,
      rs: 17.0,
      rsd1d: 2.0,
      rsd5d: 2.0,
      rsd10d: 4.0,
      price_5d_h: 1.65,
      price_5d_L: 1.45,
      price_1m_h: 2.27,
      price_1m_l: 1.16,
      price_3m_h: 2.27,
      price_3m_l: 1.16,
      price_6m_h: 2.27,
      price_6m_l: 1.16,
      price_52w_h: 2.5,
      price_52w_l: 1.16,
      price_pc_1d: -0.676,
      price_pc_1w: -1.342,
      price_pc_1m: -35.242,
      price_pc_6m: -35.242,
      price_pc_1y: -38.494,
      price_pc_ytd: -38.494,
    },
    "0995": {
      id: "0995",
      c: 7.65,
      ma50: 7.174,
      ma100: 6.568,
      ma150: 5.971,
      ma200: 5.684,
      maw30: 5.997,
      rs: 94.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: 1.0,
      price_5d_h: 7.94,
      price_5d_L: 7.18,
      price_1m_h: 7.94,
      price_1m_l: 6.6,
      price_3m_h: 7.94,
      price_3m_l: 6.2,
      price_6m_h: 7.94,
      price_6m_l: 4.3,
      price_52w_h: 7.94,
      price_52w_l: 4.3,
      price_pc_1d: -0.649,
      price_pc_1w: 6.398,
      price_pc_1m: 2.685,
      price_pc_6m: 60.377,
      price_pc_1y: 38.587,
      price_pc_ytd: 38.587,
    },
    "0997": {
      id: "0997",
      c: 0.066,
      ma50: 0.059,
      ma100: 0.072,
      ma150: 0.179,
      ma200: 0.202,
      maw30: 0.166,
      rs: 9.0,
      rsd1d: 1.0,
      rsd5d: -1.0,
      rsd10d: 6.0,
      price_5d_h: 0.082,
      price_5d_L: 0.062,
      price_1m_h: 0.12,
      price_1m_l: 0.045,
      price_3m_h: 0.12,
      price_3m_l: 0.045,
      price_6m_h: 0.98,
      price_6m_l: 0.045,
      price_52w_h: 0.98,
      price_52w_l: 0.045,
      price_pc_1d: -2.941,
      price_pc_1w: -12.0,
      price_pc_1m: 11.864,
      price_pc_6m: -83.5,
      price_pc_1y: -86.667,
      price_pc_ytd: -86.667,
    },
    "0998": {
      id: "0998",
      c: 3.97,
      ma50: 3.73,
      ma100: 3.588,
      ma150: 3.564,
      ma200: 3.592,
      maw30: 3.566,
      rs: 86.0,
      rsd1d: 0.0,
      rsd5d: 6.0,
      rsd10d: 7.0,
      price_5d_h: 4.01,
      price_5d_L: 3.74,
      price_1m_h: 4.01,
      price_1m_l: 3.37,
      price_3m_h: 4.01,
      price_3m_l: 3.37,
      price_6m_h: 4.01,
      price_6m_l: 3.34,
      price_52w_h: 4.29,
      price_52w_l: 3.28,
      price_pc_1d: 0.761,
      price_pc_1w: 7.297,
      price_pc_1m: 8.174,
      price_pc_6m: 12.784,
      price_pc_1y: 0.0,
      price_pc_ytd: 0.0,
    },
    1001: {
      id: "1001",
      c: 0.395,
      ma50: 0.418,
      ma100: 0.424,
      ma150: 0.388,
      ma200: 0.356,
      maw30: 0.392,
      rs: 90.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: -2.0,
      price_5d_h: 0.395,
      price_5d_L: 0.375,
      price_1m_h: 0.43,
      price_1m_l: 0.375,
      price_3m_h: 0.47,
      price_3m_l: 0.375,
      price_6m_h: 0.65,
      price_6m_l: 0.26,
      price_52w_h: 0.65,
      price_52w_l: 0.231,
      price_pc_1d: 1.282,
      price_pc_1w: 0.0,
      price_pc_1m: -5.952,
      price_pc_6m: 19.697,
      price_pc_1y: 61.224,
      price_pc_ytd: 61.224,
    },
    1003: {
      id: "1003",
      c: 1.32,
      ma50: 1.381,
      ma100: 1.444,
      ma150: 1.468,
      ma200: 1.54,
      maw30: 1.467,
      rs: 39.0,
      rsd1d: 2.0,
      rsd5d: -9.0,
      rsd10d: 2.0,
      price_5d_h: 1.44,
      price_5d_L: 1.29,
      price_1m_h: 1.45,
      price_1m_l: 1.19,
      price_3m_h: 1.61,
      price_3m_l: 1.19,
      price_6m_h: 1.85,
      price_6m_l: 1.19,
      price_52w_h: 2.52,
      price_52w_l: 1.19,
      price_pc_1d: 0.0,
      price_pc_1w: -7.042,
      price_pc_1m: -9.589,
      price_pc_6m: -12.583,
      price_pc_1y: -36.538,
      price_pc_ytd: -36.538,
    },
    1007: {
      id: "1007",
      c: 0.074,
      ma50: 0.111,
      ma100: 0.112,
      ma150: 0.11,
      ma200: 0.111,
      maw30: 0.111,
      rs: 14.0,
      rsd1d: 2.0,
      rsd5d: -1.0,
      rsd10d: -7.0,
      price_5d_h: 0.093,
      price_5d_L: 0.071,
      price_1m_h: 0.114,
      price_1m_l: 0.071,
      price_3m_h: 0.147,
      price_3m_l: 0.071,
      price_6m_h: 0.147,
      price_6m_l: 0.071,
      price_52w_h: 0.158,
      price_52w_l: 0.071,
      price_pc_1d: 0.0,
      price_pc_1w: -8.642,
      price_pc_1m: -38.843,
      price_pc_6m: -37.288,
      price_pc_1y: -50.336,
      price_pc_ytd: -50.336,
    },
    1008: {
      id: "1008",
      c: 0.345,
      ma50: 0.339,
      ma100: 0.333,
      ma150: 0.338,
      ma200: 0.354,
      maw30: 0.337,
      rs: 69.0,
      rsd1d: 0.0,
      rsd5d: 2.0,
      rsd10d: 8.0,
      price_5d_h: 0.345,
      price_5d_L: 0.33,
      price_1m_h: 0.365,
      price_1m_l: 0.305,
      price_3m_h: 0.41,
      price_3m_l: 0.305,
      price_6m_h: 0.41,
      price_6m_l: 0.305,
      price_52w_h: 0.53,
      price_52w_l: 0.305,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: -5.479,
      price_pc_6m: -2.817,
      price_pc_1y: -13.75,
      price_pc_ytd: -13.75,
    },
    1009: {
      id: "1009",
      c: 0.255,
      ma50: 0.278,
      ma100: 0.305,
      ma150: 0.32,
      ma200: 0.335,
      maw30: 0.322,
      rs: 22.0,
      rsd1d: 4.0,
      rsd5d: 0.0,
      rsd10d: 2.0,
      price_5d_h: 0.26,
      price_5d_L: 0.24,
      price_1m_h: 0.295,
      price_1m_l: 0.235,
      price_3m_h: 0.33,
      price_3m_l: 0.235,
      price_6m_h: 0.47,
      price_6m_l: 0.235,
      price_52w_h: 0.51,
      price_52w_l: 0.235,
      price_pc_1d: 2.0,
      price_pc_1w: 0.0,
      price_pc_1m: -15.0,
      price_pc_6m: -29.167,
      price_pc_1y: -45.161,
      price_pc_ytd: -45.161,
    },
    1010: {
      id: "1010",
      c: 2.0,
      ma50: 3.26,
      ma100: 4.407,
      ma150: 3.862,
      ma200: 3.664,
      maw30: 3.896,
      rs: 16.0,
      rsd1d: 3.0,
      rsd5d: -2.0,
      rsd10d: 2.0,
      price_5d_h: 2.08,
      price_5d_L: 1.85,
      price_1m_h: 2.7,
      price_1m_l: 1.8,
      price_3m_h: 5.91,
      price_3m_l: 1.8,
      price_6m_h: 7.3,
      price_6m_l: 1.8,
      price_52w_h: 7.3,
      price_52w_l: 1.52,
      price_pc_1d: 1.01,
      price_pc_1w: -4.762,
      price_pc_1m: -21.569,
      price_pc_6m: -24.242,
      price_pc_1y: 5.263,
      price_pc_ytd: 5.263,
    },
    1011: {
      id: "1011",
      c: 0.067,
      ma50: 0.068,
      ma100: 0.07,
      ma150: 0.075,
      ma200: 0.084,
      maw30: 0.075,
      rs: 28.0,
      rsd1d: 1.0,
      rsd5d: -6.0,
      rsd10d: -11.0,
      price_5d_h: 0.073,
      price_5d_L: 0.066,
      price_1m_h: 0.084,
      price_1m_l: 0.061,
      price_3m_h: 0.084,
      price_3m_l: 0.061,
      price_6m_h: 0.093,
      price_6m_l: 0.061,
      price_52w_h: 0.144,
      price_52w_l: 0.061,
      price_pc_1d: 0.0,
      price_pc_1w: -6.944,
      price_pc_1m: -6.944,
      price_pc_6m: -22.093,
      price_pc_1y: -50.735,
      price_pc_ytd: -50.735,
    },
    1013: {
      id: "1013",
      c: 0.049,
      ma50: 0.062,
      ma100: 0.064,
      ma150: 0.067,
      ma200: 0.07,
      maw30: 0.067,
      rs: 15.0,
      rsd1d: -2.0,
      rsd5d: -1.0,
      rsd10d: -3.0,
      price_5d_h: 0.056,
      price_5d_L: 0.049,
      price_1m_h: 0.06,
      price_1m_l: 0.043,
      price_3m_h: 0.1,
      price_3m_l: 0.043,
      price_6m_h: 0.1,
      price_6m_l: 0.04,
      price_52w_h: 0.132,
      price_52w_l: 0.04,
      price_pc_1d: -7.547,
      price_pc_1w: -3.922,
      price_pc_1m: -23.438,
      price_pc_6m: -42.353,
      price_pc_1y: -49.485,
      price_pc_ytd: -49.485,
    },
    1020: {
      id: "1020",
      c: 0.135,
      ma50: 0.158,
      ma100: 0.157,
      ma150: 0.179,
      ma200: 0.182,
      maw30: 0.179,
      rs: 85.0,
      rsd1d: -2.0,
      rsd5d: -3.0,
      rsd10d: -5.0,
      price_5d_h: 0.14,
      price_5d_L: 0.121,
      price_1m_h: 0.174,
      price_1m_l: 0.121,
      price_3m_h: 0.194,
      price_3m_l: 0.1,
      price_6m_h: 0.234,
      price_6m_l: 0.1,
      price_52w_h: 0.28,
      price_52w_l: 0.061,
      price_pc_1d: -1.46,
      price_pc_1w: -5.594,
      price_pc_1m: -22.414,
      price_pc_6m: -40.789,
      price_pc_1y: 75.325,
      price_pc_ytd: 75.325,
    },
    1022: {
      id: "1022",
      c: 0.28,
      ma50: 0.305,
      ma100: 0.366,
      ma150: 0.416,
      ma200: 0.503,
      maw30: 0.414,
      rs: 11.0,
      rsd1d: -3.0,
      rsd5d: 1.0,
      rsd10d: 0.0,
      price_5d_h: 0.33,
      price_5d_L: 0.25,
      price_1m_h: 0.335,
      price_1m_l: 0.21,
      price_3m_h: 0.4,
      price_3m_l: 0.21,
      price_6m_h: 0.59,
      price_6m_l: 0.21,
      price_52w_h: 1.42,
      price_52w_l: 0.21,
      price_pc_1d: -9.677,
      price_pc_1w: 0.0,
      price_pc_1m: -5.085,
      price_pc_6m: -42.268,
      price_pc_1y: -44.0,
      price_pc_ytd: -44.0,
    },
    1023: {
      id: "1023",
      c: 0.485,
      ma50: 0.505,
      ma100: 0.516,
      ma150: 0.527,
      ma200: 0.545,
      maw30: 0.524,
      rs: 62.0,
      rsd1d: 2.0,
      rsd5d: 3.0,
      rsd10d: 0.0,
      price_5d_h: 0.49,
      price_5d_L: 0.465,
      price_1m_h: 0.52,
      price_1m_l: 0.45,
      price_3m_h: 0.61,
      price_3m_l: 0.45,
      price_6m_h: 0.63,
      price_6m_l: 0.45,
      price_52w_h: 0.68,
      price_52w_l: 0.43,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: -6.731,
      price_pc_6m: -3.0,
      price_pc_1y: 1.042,
      price_pc_ytd: 1.042,
    },
    1024: {
      id: "1024",
      c: 74.2,
      ma50: 82.442,
      ma100: 83.62,
      ma150: 86.121,
      ma200: 98.698,
      maw30: 86.568,
      rs: 24.0,
      rsd1d: 0.0,
      rsd5d: 2.0,
      rsd10d: 6.0,
      price_5d_h: 85.0,
      price_5d_L: 69.75,
      price_1m_h: 90.9,
      price_1m_l: 54.65,
      price_3m_h: 99.4,
      price_3m_l: 54.65,
      price_6m_h: 110.5,
      price_6m_l: 54.65,
      price_52w_h: 287.8,
      price_52w_l: 54.65,
      price_pc_1d: 0.815,
      price_pc_1w: -3.574,
      price_pc_1m: -17.141,
      price_pc_6m: -10.278,
      price_pc_1y: -73.965,
      price_pc_ytd: -73.965,
    },
    1025: {
      id: "1025",
      c: 0.405,
      ma50: 0.394,
      ma100: 0.434,
      ma150: 0.428,
      ma200: 0.392,
      maw30: 0.43,
      rs: 93.0,
      rsd1d: -1.0,
      rsd5d: 4.0,
      rsd10d: -1.0,
      price_5d_h: 0.41,
      price_5d_L: 0.37,
      price_1m_h: 0.41,
      price_1m_l: 0.3,
      price_3m_h: 0.55,
      price_3m_l: 0.3,
      price_6m_h: 0.84,
      price_6m_l: 0.28,
      price_52w_h: 0.84,
      price_52w_l: 0.157,
      price_pc_1d: 0.0,
      price_pc_1w: 5.195,
      price_pc_1m: 12.5,
      price_pc_6m: 35.0,
      price_pc_1y: 131.429,
      price_pc_ytd: 131.429,
    },
    1027: {
      id: "1027",
      c: 0.52,
      ma50: 0.535,
      ma100: 0.565,
      ma150: 0.591,
      ma200: 0.627,
      maw30: 0.591,
      rs: 33.0,
      rsd1d: 4.0,
      rsd5d: -7.0,
      rsd10d: 0.0,
      price_5d_h: 0.52,
      price_5d_L: 0.47,
      price_1m_h: 0.6,
      price_1m_l: 0.47,
      price_3m_h: 0.62,
      price_3m_l: 0.45,
      price_6m_h: 0.74,
      price_6m_l: 0.45,
      price_52w_h: 0.97,
      price_52w_l: 0.45,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: -13.333,
      price_pc_6m: -16.129,
      price_pc_1y: -27.778,
      price_pc_ytd: -27.778,
    },
    1029: {
      id: "1029",
      c: 0.21,
      ma50: 0.223,
      ma100: 0.231,
      ma150: 0.243,
      ma200: 0.25,
      maw30: 0.243,
      rs: 58.0,
      rsd1d: 0.0,
      rsd5d: -5.0,
      rsd10d: 23.0,
      price_5d_h: 0.218,
      price_5d_L: 0.186,
      price_1m_h: 0.218,
      price_1m_l: 0.15,
      price_3m_h: 0.275,
      price_3m_l: 0.15,
      price_6m_h: 0.275,
      price_6m_l: 0.15,
      price_52w_h: 0.495,
      price_52w_l: 0.15,
      price_pc_1d: -1.869,
      price_pc_1w: -2.778,
      price_pc_1m: 2.941,
      price_pc_6m: -15.663,
      price_pc_1y: 6.599,
      price_pc_ytd: 6.599,
    },
    1030: {
      id: "1030",
      c: 4.22,
      ma50: 4.604,
      ma100: 5.068,
      ma150: 5.503,
      ma200: 5.869,
      maw30: 5.541,
      rs: 18.0,
      rsd1d: 2.0,
      rsd5d: 6.0,
      rsd10d: -5.0,
      price_5d_h: 4.47,
      price_5d_L: 3.53,
      price_1m_h: 4.53,
      price_1m_l: 2.33,
      price_3m_h: 6.21,
      price_3m_l: 2.33,
      price_6m_h: 7.34,
      price_6m_l: 2.33,
      price_52w_h: 10.14,
      price_52w_l: 2.33,
      price_pc_1d: -0.236,
      price_pc_1w: 10.471,
      price_pc_1m: 2.427,
      price_pc_6m: -32.263,
      price_pc_1y: -55.011,
      price_pc_ytd: -55.011,
    },
    1031: {
      id: "1031",
      c: 0.255,
      ma50: 0.354,
      ma100: 0.409,
      ma150: 0.434,
      ma200: 0.455,
      maw30: 0.436,
      rs: 12.0,
      rsd1d: -2.0,
      rsd5d: 0.0,
      rsd10d: 3.0,
      price_5d_h: 0.31,
      price_5d_L: 0.25,
      price_1m_h: 0.36,
      price_1m_l: 0.22,
      price_3m_h: 0.47,
      price_3m_l: 0.22,
      price_6m_h: 0.63,
      price_6m_l: 0.22,
      price_52w_h: 0.63,
      price_52w_l: 0.22,
      price_pc_1d: -10.526,
      price_pc_1w: -10.526,
      price_pc_1m: -29.167,
      price_pc_6m: -35.443,
      price_pc_1y: -56.78,
      price_pc_ytd: -56.78,
    },
    1033: {
      id: "1033",
      c: 0.64,
      ma50: 0.683,
      ma100: 0.673,
      ma150: 0.707,
      ma200: 0.701,
      maw30: 0.71,
      rs: 59.0,
      rsd1d: 7.0,
      rsd5d: 8.0,
      rsd10d: 8.0,
      price_5d_h: 0.65,
      price_5d_L: 0.62,
      price_1m_h: 0.76,
      price_1m_l: 0.53,
      price_3m_h: 0.8,
      price_3m_l: 0.53,
      price_6m_h: 0.96,
      price_6m_l: 0.53,
      price_52w_h: 0.96,
      price_52w_l: 0.53,
      price_pc_1d: 0.0,
      price_pc_1w: 1.587,
      price_pc_1m: -12.329,
      price_pc_6m: -21.951,
      price_pc_1y: -8.571,
      price_pc_ytd: -8.571,
    },
    1034: {
      id: "1034",
      c: 2.09,
      ma50: 2.137,
      ma100: 2.123,
      ma150: 2.103,
      ma200: 1.992,
      maw30: 2.116,
      rs: 86.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: -4.0,
      price_5d_h: 2.09,
      price_5d_L: 1.99,
      price_1m_h: 2.21,
      price_1m_l: 1.91,
      price_3m_h: 2.57,
      price_3m_l: 1.84,
      price_6m_h: 2.57,
      price_6m_l: 1.84,
      price_52w_h: 2.57,
      price_52w_l: 1.25,
      price_pc_1d: 3.98,
      price_pc_1w: 1.951,
      price_pc_1m: -3.687,
      price_pc_6m: 7.732,
      price_pc_1y: 33.974,
      price_pc_ytd: 33.974,
    },
    1036: {
      id: "1036",
      c: 2.38,
      ma50: 2.435,
      ma100: 2.394,
      ma150: 2.458,
      ma200: 2.474,
      maw30: 2.456,
      rs: 68.0,
      rsd1d: 5.0,
      rsd5d: -9.0,
      rsd10d: 8.0,
      price_5d_h: 2.41,
      price_5d_L: 2.35,
      price_1m_h: 2.62,
      price_1m_l: 2.09,
      price_3m_h: 2.69,
      price_3m_l: 2.09,
      price_6m_h: 2.69,
      price_6m_l: 2.09,
      price_52w_h: 3.49,
      price_52w_l: 2.09,
      price_pc_1d: 1.277,
      price_pc_1w: -4.418,
      price_pc_1m: -5.929,
      price_pc_6m: -2.857,
      price_pc_1y: -14.388,
      price_pc_ytd: -14.388,
    },
    1037: {
      id: "1037",
      c: 0.305,
      ma50: 0.357,
      ma100: 0.364,
      ma150: 0.383,
      ma200: 0.395,
      maw30: 0.385,
      rs: 20.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: -5.0,
      price_5d_h: 0.345,
      price_5d_L: 0.295,
      price_1m_h: 0.38,
      price_1m_l: 0.295,
      price_3m_h: 0.39,
      price_3m_l: 0.295,
      price_6m_h: 0.46,
      price_6m_l: 0.295,
      price_52w_h: 0.72,
      price_52w_l: 0.295,
      price_pc_1d: -3.175,
      price_pc_1w: -1.613,
      price_pc_1m: -17.568,
      price_pc_6m: -29.07,
      price_pc_1y: -58.219,
      price_pc_ytd: -58.219,
    },
    1038: {
      id: "1038",
      c: 52.45,
      ma50: 50.293,
      ma100: 48.772,
      ma150: 47.411,
      ma200: 47.289,
      maw30: 47.443,
      rs: 86.0,
      rsd1d: -2.0,
      rsd5d: -2.0,
      rsd10d: -2.0,
      price_5d_h: 54.35,
      price_5d_L: 52.15,
      price_1m_h: 54.35,
      price_1m_l: 47.05,
      price_3m_h: 54.35,
      price_3m_l: 47.05,
      price_6m_h: 54.35,
      price_6m_l: 42.3,
      price_52w_h: 54.35,
      price_52w_l: 42.3,
      price_pc_1d: -1.595,
      price_pc_1w: -1.038,
      price_pc_1m: 8.144,
      price_pc_6m: 20.575,
      price_pc_1y: 13.528,
      price_pc_ytd: 13.528,
    },
    1044: {
      id: "1044",
      c: 36.2,
      ma50: 39.501,
      ma100: 39.654,
      ma150: 40.466,
      ma200: 42.525,
      maw30: 40.417,
      rs: 38.0,
      rsd1d: 3.0,
      rsd5d: 7.0,
      rsd10d: -13.0,
      price_5d_h: 36.65,
      price_5d_L: 35.05,
      price_1m_h: 41.95,
      price_1m_l: 34.4,
      price_3m_h: 43.4,
      price_3m_l: 34.4,
      price_6m_h: 44.2,
      price_6m_l: 34.4,
      price_52w_h: 54.4,
      price_52w_l: 34.4,
      price_pc_1d: 0.416,
      price_pc_1w: 2.841,
      price_pc_1m: -12.666,
      price_pc_6m: -13.189,
      price_pc_1y: -28.88,
      price_pc_ytd: -28.88,
    },
    1045: {
      id: "1045",
      c: 2.38,
      ma50: 2.33,
      ma100: 2.313,
      ma150: 2.333,
      ma200: 2.336,
      maw30: 2.333,
      rs: 79.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: 7.0,
      price_5d_h: 2.42,
      price_5d_L: 2.33,
      price_1m_h: 2.45,
      price_1m_l: 1.9,
      price_3m_h: 2.53,
      price_3m_l: 1.9,
      price_6m_h: 2.53,
      price_6m_l: 1.9,
      price_52w_h: 2.95,
      price_52w_l: 1.9,
      price_pc_1d: -0.418,
      price_pc_1w: -0.418,
      price_pc_1m: 1.709,
      price_pc_6m: 3.478,
      price_pc_1y: -0.833,
      price_pc_ytd: -0.833,
    },
    1046: {
      id: "1046",
      c: 0.55,
      ma50: 0.618,
      ma100: 0.585,
      ma150: 0.539,
      ma200: 0.502,
      maw30: 0.542,
      rs: 83.0,
      rsd1d: -6.0,
      rsd5d: -6.0,
      rsd10d: -5.0,
      price_5d_h: 0.6,
      price_5d_L: 0.54,
      price_1m_h: 0.71,
      price_1m_l: 0.52,
      price_3m_h: 0.76,
      price_3m_l: 0.52,
      price_6m_h: 0.76,
      price_6m_l: 0.405,
      price_52w_h: 0.76,
      price_52w_l: 0.335,
      price_pc_1d: -6.78,
      price_pc_1w: -6.78,
      price_pc_1m: -21.429,
      price_pc_6m: 30.952,
      price_pc_1y: -5.172,
      price_pc_ytd: -5.172,
    },
    1050: {
      id: "1050",
      c: 1.48,
      ma50: 1.531,
      ma100: 1.559,
      ma150: 1.556,
      ma200: 1.594,
      maw30: 1.553,
      rs: 65.0,
      rsd1d: 2.0,
      rsd5d: 10.0,
      rsd10d: 2.0,
      price_5d_h: 1.5,
      price_5d_L: 1.42,
      price_1m_h: 1.59,
      price_1m_l: 1.37,
      price_3m_h: 1.65,
      price_3m_l: 1.37,
      price_6m_h: 1.68,
      price_6m_l: 1.37,
      price_52w_h: 1.92,
      price_52w_l: 1.37,
      price_pc_1d: 0.0,
      price_pc_1w: 2.778,
      price_pc_1m: -3.268,
      price_pc_6m: 0.68,
      price_pc_1y: -0.671,
      price_pc_ytd: -0.671,
    },
    1051: {
      id: "1051",
      c: 2.65,
      ma50: 2.608,
      ma100: 2.709,
      ma150: 2.824,
      ma200: 2.852,
      maw30: 2.816,
      rs: 58.0,
      rsd1d: 11.0,
      rsd5d: 16.0,
      rsd10d: 16.0,
      price_5d_h: 2.7,
      price_5d_L: 2.33,
      price_1m_h: 2.82,
      price_1m_l: 2.04,
      price_3m_h: 2.94,
      price_3m_l: 2.04,
      price_6m_h: 3.25,
      price_6m_l: 2.04,
      price_52w_h: 3.4,
      price_52w_l: 2.04,
      price_pc_1d: 3.922,
      price_pc_1w: 9.504,
      price_pc_1m: -4.676,
      price_pc_6m: -10.169,
      price_pc_1y: -9.862,
      price_pc_ytd: -9.862,
    },
    1052: {
      id: "1052",
      c: 5.29,
      ma50: 5.012,
      ma100: 4.888,
      ma150: 4.888,
      ma200: 4.764,
      maw30: 4.893,
      rs: 86.0,
      rsd1d: -1.0,
      rsd5d: 1.0,
      rsd10d: -1.0,
      price_5d_h: 5.32,
      price_5d_L: 5.1,
      price_1m_h: 5.45,
      price_1m_l: 4.76,
      price_3m_h: 5.45,
      price_3m_l: 4.58,
      price_6m_h: 5.45,
      price_6m_l: 4.51,
      price_52w_h: 5.45,
      price_52w_l: 4.0,
      price_pc_1d: 0.189,
      price_pc_1w: 3.119,
      price_pc_1m: 9.072,
      price_pc_6m: 2.124,
      price_pc_1y: 7.959,
      price_pc_ytd: 7.959,
    },
    1053: {
      id: "1053",
      c: 1.2,
      ma50: 1.298,
      ma100: 1.31,
      ma150: 1.481,
      ma200: 1.594,
      maw30: 1.472,
      rs: 43.0,
      rsd1d: -1.0,
      rsd5d: -3.0,
      rsd10d: 10.0,
      price_5d_h: 1.26,
      price_5d_L: 1.19,
      price_1m_h: 1.38,
      price_1m_l: 1.04,
      price_3m_h: 1.49,
      price_3m_l: 1.04,
      price_6m_h: 1.92,
      price_6m_l: 1.04,
      price_52w_h: 3.21,
      price_52w_l: 1.04,
      price_pc_1d: -2.439,
      price_pc_1w: -4.0,
      price_pc_1m: -8.397,
      price_pc_6m: -34.066,
      price_pc_1y: 3.448,
      price_pc_ytd: 3.448,
    },
    1055: {
      id: "1055",
      c: 4.56,
      ma50: 4.9,
      ma100: 4.777,
      ma150: 4.704,
      ma200: 4.661,
      maw30: 4.739,
      rs: 65.0,
      rsd1d: 0.0,
      rsd5d: -2.0,
      rsd10d: -10.0,
      price_5d_h: 4.66,
      price_5d_L: 4.36,
      price_1m_h: 5.14,
      price_1m_l: 3.83,
      price_3m_h: 5.54,
      price_3m_l: 3.83,
      price_6m_h: 5.54,
      price_6m_l: 3.83,
      price_52w_h: 5.92,
      price_52w_l: 3.83,
      price_pc_1d: -1.085,
      price_pc_1w: -0.654,
      price_pc_1m: -7.505,
      price_pc_6m: 3.636,
      price_pc_1y: -20.0,
      price_pc_ytd: -20.0,
    },
    1057: {
      id: "1057",
      c: 1.63,
      ma50: 1.876,
      ma100: 1.968,
      ma150: 1.876,
      ma200: 1.929,
      maw30: 1.886,
      rs: 48.0,
      rsd1d: 0.0,
      rsd5d: -14.0,
      rsd10d: -1.0,
      price_5d_h: 1.78,
      price_5d_L: 1.6,
      price_1m_h: 2.26,
      price_1m_l: 1.53,
      price_3m_h: 2.49,
      price_3m_l: 1.53,
      price_6m_h: 2.55,
      price_6m_l: 1.45,
      price_52w_h: 3.2,
      price_52w_l: 1.25,
      price_pc_1d: -2.395,
      price_pc_1w: -7.386,
      price_pc_1m: -26.577,
      price_pc_6m: 0.0,
      price_pc_1y: 18.978,
      price_pc_ytd: 18.978,
    },
    1058: {
      id: "1058",
      c: 0.73,
      ma50: 0.849,
      ma100: 0.83,
      ma150: 0.772,
      ma200: 0.653,
      maw30: 0.798,
      rs: 96.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: -1.0,
      price_5d_h: 0.79,
      price_5d_L: 0.72,
      price_1m_h: 0.9,
      price_1m_l: 0.68,
      price_3m_h: 1.03,
      price_3m_l: 0.68,
      price_6m_h: 1.51,
      price_6m_l: 0.5,
      price_52w_h: 1.51,
      price_52w_l: 0.24,
      price_pc_1d: -5.195,
      price_pc_1w: -2.667,
      price_pc_1m: -17.978,
      price_pc_6m: 53.684,
      price_pc_1y: 180.769,
      price_pc_ytd: 180.769,
    },
    1059: {
      id: "1059",
      c: 0.385,
      ma50: 0.438,
      ma100: 0.488,
      ma150: 0.499,
      ma200: 0.477,
      maw30: 0.498,
      rs: 50.0,
      rsd1d: 3.0,
      rsd5d: -12.0,
      rsd10d: 4.0,
      price_5d_h: 0.48,
      price_5d_L: 0.375,
      price_1m_h: 0.48,
      price_1m_l: 0.37,
      price_3m_h: 0.53,
      price_3m_l: 0.37,
      price_6m_h: 0.69,
      price_6m_l: 0.37,
      price_52w_h: 0.9,
      price_52w_l: 0.3,
      price_pc_1d: 0.0,
      price_pc_1w: -8.333,
      price_pc_1m: -15.385,
      price_pc_6m: -23.0,
      price_pc_1y: 6.944,
      price_pc_ytd: 6.944,
    },
    1060: {
      id: "1060",
      c: 0.65,
      ma50: 0.749,
      ma100: 0.775,
      ma150: 0.797,
      ma200: 0.848,
      maw30: 0.799,
      rs: 30.0,
      rsd1d: -2.0,
      rsd5d: -2.0,
      rsd10d: 0.0,
      price_5d_h: 0.69,
      price_5d_L: 0.62,
      price_1m_h: 0.82,
      price_1m_l: 0.495,
      price_3m_h: 0.88,
      price_3m_l: 0.495,
      price_6m_h: 0.93,
      price_6m_l: 0.495,
      price_52w_h: 1.19,
      price_52w_l: 0.495,
      price_pc_1d: -1.515,
      price_pc_1w: -4.412,
      price_pc_1m: -16.667,
      price_pc_6m: -26.136,
      price_pc_1y: -35.644,
      price_pc_ytd: -35.644,
    },
    1061: {
      id: "1061",
      c: 4.34,
      ma50: 4.583,
      ma100: 4.84,
      ma150: 5.184,
      ma200: 5.63,
      maw30: 5.187,
      rs: 33.0,
      rsd1d: 3.0,
      rsd5d: -2.0,
      rsd10d: 11.0,
      price_5d_h: 4.59,
      price_5d_L: 4.06,
      price_1m_h: 4.8,
      price_1m_l: 3.23,
      price_3m_h: 5.2,
      price_3m_l: 3.23,
      price_6m_h: 5.9,
      price_6m_l: 3.23,
      price_52w_h: 8.29,
      price_52w_l: 3.23,
      price_pc_1d: -0.913,
      price_pc_1w: -3.125,
      price_pc_1m: -8.245,
      price_pc_6m: -24.653,
      price_pc_1y: -17.017,
      price_pc_ytd: -17.017,
    },
    1063: {
      id: "1063",
      c: 0.315,
      ma50: 0.331,
      ma100: 0.357,
      ma150: 0.366,
      ma200: 0.355,
      maw30: 0.366,
      rs: 94.0,
      rsd1d: -2.0,
      rsd5d: -2.0,
      rsd10d: -1.0,
      price_5d_h: 0.34,
      price_5d_L: 0.3,
      price_1m_h: 0.345,
      price_1m_l: 0.255,
      price_3m_h: 0.39,
      price_3m_l: 0.255,
      price_6m_h: 0.46,
      price_6m_l: 0.255,
      price_52w_h: 0.58,
      price_52w_l: 0.091,
      price_pc_1d: -5.97,
      price_pc_1w: -5.97,
      price_pc_1m: -1.563,
      price_pc_6m: 1.613,
      price_pc_1y: 197.17,
      price_pc_ytd: 197.17,
    },
    1064: {
      id: "1064",
      c: 0.085,
      ma50: 0.088,
      ma100: 0.101,
      ma150: 0.11,
      ma200: 0.119,
      maw30: 0.109,
      rs: 23.0,
      rsd1d: 1.0,
      rsd5d: 7.0,
      rsd10d: 7.0,
      price_5d_h: 0.086,
      price_5d_L: 0.077,
      price_1m_h: 0.093,
      price_1m_l: 0.075,
      price_3m_h: 0.102,
      price_3m_l: 0.075,
      price_6m_h: 0.163,
      price_6m_l: 0.075,
      price_52w_h: 0.163,
      price_52w_l: 0.075,
      price_pc_1d: -1.163,
      price_pc_1w: 6.25,
      price_pc_1m: 3.659,
      price_pc_6m: -24.779,
      price_pc_1y: -43.333,
      price_pc_ytd: -43.333,
    },
    1065: {
      id: "1065",
      c: 3.35,
      ma50: 3.526,
      ma100: 3.694,
      ma150: 3.636,
      ma200: 3.516,
      maw30: 3.662,
      rs: 51.0,
      rsd1d: 1.0,
      rsd5d: -6.0,
      rsd10d: -11.0,
      price_5d_h: 3.48,
      price_5d_L: 3.2,
      price_1m_h: 3.65,
      price_1m_l: 2.91,
      price_3m_h: 4.28,
      price_3m_l: 2.91,
      price_6m_h: 4.29,
      price_6m_l: 2.91,
      price_52w_h: 4.29,
      price_52w_l: 2.71,
      price_pc_1d: 1.208,
      price_pc_1w: 3.395,
      price_pc_1m: -6.944,
      price_pc_6m: -13.882,
      price_pc_1y: 2.446,
      price_pc_ytd: 2.446,
    },
    1066: {
      id: "1066",
      c: 8.39,
      ma50: 9.769,
      ma100: 10.191,
      ma150: 11.277,
      ma200: 12.388,
      maw30: 11.261,
      rs: 19.0,
      rsd1d: -1.0,
      rsd5d: -19.0,
      rsd10d: -24.0,
      price_5d_h: 9.92,
      price_5d_L: 8.19,
      price_1m_h: 10.82,
      price_1m_l: 8.19,
      price_3m_h: 10.98,
      price_3m_l: 8.19,
      price_6m_h: 14.86,
      price_6m_l: 8.19,
      price_52w_h: 20.65,
      price_52w_l: 8.19,
      price_pc_1d: -4.659,
      price_pc_1w: -16.1,
      price_pc_1m: -22.026,
      price_pc_6m: -39.727,
      price_pc_1y: -45.02,
      price_pc_ytd: -45.02,
    },
    1068: {
      id: "1068",
      c: 0.65,
      ma50: 0.713,
      ma100: 0.781,
      ma150: 0.819,
      ma200: 0.842,
      maw30: 0.814,
      rs: 29.0,
      rsd1d: 0.0,
      rsd5d: 4.0,
      rsd10d: 14.0,
      price_5d_h: 0.68,
      price_5d_L: 0.59,
      price_1m_h: 0.71,
      price_1m_l: 0.485,
      price_3m_h: 1.16,
      price_3m_l: 0.485,
      price_6m_h: 1.16,
      price_6m_l: 0.485,
      price_52w_h: 1.18,
      price_52w_l: 0.485,
      price_pc_1d: 1.563,
      price_pc_1w: 3.175,
      price_pc_1m: -2.985,
      price_pc_6m: -25.287,
      price_pc_1y: -27.778,
      price_pc_ytd: -27.778,
    },
    1070: {
      id: "1070",
      c: 3.35,
      ma50: 3.646,
      ma100: 3.916,
      ma150: 3.957,
      ma200: 4.055,
      maw30: 3.983,
      rs: 30.0,
      rsd1d: 2.0,
      rsd5d: 5.0,
      rsd10d: 6.0,
      price_5d_h: 3.38,
      price_5d_L: 3.13,
      price_1m_h: 3.7,
      price_1m_l: 2.57,
      price_3m_h: 4.37,
      price_3m_l: 2.57,
      price_6m_h: 4.72,
      price_6m_l: 2.57,
      price_52w_h: 6.38,
      price_52w_l: 2.57,
      price_pc_1d: 0.0,
      price_pc_1w: 0.299,
      price_pc_1m: -8.47,
      price_pc_6m: -13.66,
      price_pc_1y: -46.228,
      price_pc_ytd: -46.228,
    },
    1071: {
      id: "1071",
      c: 2.41,
      ma50: 2.747,
      ma100: 2.833,
      ma150: 2.876,
      ma200: 2.741,
      maw30: 2.912,
      rs: 40.0,
      rsd1d: -1.0,
      rsd5d: 2.0,
      rsd10d: -19.0,
      price_5d_h: 2.48,
      price_5d_L: 2.31,
      price_1m_h: 3.02,
      price_1m_l: 2.22,
      price_3m_h: 3.68,
      price_3m_l: 2.22,
      price_6m_h: 3.68,
      price_6m_l: 2.22,
      price_52w_h: 3.68,
      price_52w_l: 2.08,
      price_pc_1d: -0.413,
      price_pc_1w: -1.23,
      price_pc_1m: -18.305,
      price_pc_6m: -25.387,
      price_pc_1y: 1.688,
      price_pc_ytd: 1.688,
    },
    1072: {
      id: "1072",
      c: 8.77,
      ma50: 9.758,
      ma100: 11.15,
      ma150: 11.392,
      ma200: 10.243,
      maw30: 11.513,
      rs: 62.0,
      rsd1d: 4.0,
      rsd5d: 3.0,
      rsd10d: 5.0,
      price_5d_h: 8.95,
      price_5d_L: 8.21,
      price_1m_h: 10.1,
      price_1m_l: 7.37,
      price_3m_h: 14.08,
      price_3m_l: 7.37,
      price_6m_h: 14.82,
      price_6m_l: 7.37,
      price_52w_h: 14.82,
      price_52w_l: 5.61,
      price_pc_1d: 0.458,
      price_pc_1w: -0.904,
      price_pc_1m: -10.419,
      price_pc_6m: -31.91,
      price_pc_1y: 15.395,
      price_pc_ytd: 15.395,
    },
    1073: {
      id: "1073",
      c: 0.26,
      ma50: 0.243,
      ma100: 0.246,
      ma150: 0.247,
      ma200: 0.251,
      maw30: 0.247,
      rs: 68.0,
      rsd1d: 3.0,
      rsd5d: -13.0,
      rsd10d: 4.0,
      price_5d_h: 0.27,
      price_5d_L: 0.26,
      price_1m_h: 0.3,
      price_1m_l: 0.245,
      price_3m_h: 0.3,
      price_3m_l: 0.21,
      price_6m_h: 0.31,
      price_6m_l: 0.21,
      price_52w_h: 0.34,
      price_52w_l: 0.21,
      price_pc_1d: 0.0,
      price_pc_1w: -3.704,
      price_pc_1m: 6.122,
      price_pc_6m: -1.887,
      price_pc_1y: -20.0,
      price_pc_ytd: -20.0,
    },
    1076: {
      id: "1076",
      c: 0.064,
      ma50: 0.058,
      ma100: 0.076,
      ma150: 0.091,
      ma200: 0.118,
      maw30: 0.085,
      rs: 26.0,
      rsd1d: 9.0,
      rsd5d: 21.0,
      rsd10d: 23.0,
      price_5d_h: 0.069,
      price_5d_L: 0.045,
      price_1m_h: 0.072,
      price_1m_l: 0.036,
      price_3m_h: 0.093,
      price_3m_l: 0.036,
      price_6m_h: 0.2,
      price_6m_l: 0.036,
      price_52w_h: 0.34,
      price_52w_l: 0.036,
      price_pc_1d: 6.667,
      price_pc_1w: 39.13,
      price_pc_1m: 1.587,
      price_pc_6m: 60.0,
      price_pc_1y: -68.0,
      price_pc_ytd: -68.0,
    },
    1080: {
      id: "1080",
      c: 0.062,
      ma50: 0.073,
      ma100: 0.075,
      ma150: 0.079,
      ma200: 0.08,
      maw30: 0.079,
      rs: 27.0,
      rsd1d: -15.0,
      rsd5d: -1.0,
      rsd10d: -21.0,
      price_5d_h: 0.072,
      price_5d_L: 0.059,
      price_1m_h: 0.081,
      price_1m_l: 0.059,
      price_3m_h: 0.087,
      price_3m_l: 0.059,
      price_6m_h: 0.114,
      price_6m_l: 0.059,
      price_52w_h: 0.114,
      price_52w_l: 0.059,
      price_pc_1d: -11.429,
      price_pc_1w: -1.587,
      price_pc_1m: -19.481,
      price_pc_6m: -26.19,
      price_pc_1y: -29.545,
      price_pc_ytd: -29.545,
    },
    1082: {
      id: "1082",
      c: 1.57,
      ma50: 1.604,
      ma100: 1.59,
      ma150: 1.597,
      ma200: 1.596,
      maw30: 1.61,
      rs: 70.0,
      rsd1d: -9.0,
      rsd5d: -12.0,
      rsd10d: -13.0,
      price_5d_h: 1.58,
      price_5d_L: 1.5,
      price_1m_h: 1.7,
      price_1m_l: 1.5,
      price_3m_h: 1.73,
      price_3m_l: 1.42,
      price_6m_h: 1.73,
      price_6m_l: 1.38,
      price_52w_h: 1.75,
      price_52w_l: 1.25,
      price_pc_1d: -0.633,
      price_pc_1w: 0.641,
      price_pc_1m: -2.484,
      price_pc_6m: -4.848,
      price_pc_1y: 9.028,
      price_pc_ytd: 9.028,
    },
    1083: {
      id: "1083",
      c: 4.12,
      ma50: 5.29,
      ma100: 5.642,
      ma150: 5.443,
      ma200: 5.411,
      maw30: 5.452,
      rs: 31.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: -36.0,
      price_5d_h: 4.34,
      price_5d_L: 4.06,
      price_1m_h: 5.62,
      price_1m_l: 4.02,
      price_3m_h: 7.29,
      price_3m_l: 4.02,
      price_6m_h: 7.29,
      price_6m_l: 4.02,
      price_52w_h: 7.29,
      price_52w_l: 3.73,
      price_pc_1d: -2.37,
      price_pc_1w: -2.37,
      price_pc_1m: -25.632,
      price_pc_6m: -15.226,
      price_pc_1y: 5.102,
      price_pc_ytd: 5.102,
    },
    1084: {
      id: "1084",
      c: 1.47,
      ma50: 1.233,
      ma100: 1.181,
      ma150: 1.165,
      ma200: 1.167,
      maw30: 1.164,
      rs: 92.0,
      rsd1d: 0.0,
      rsd5d: 2.0,
      rsd10d: 4.0,
      price_5d_h: 1.47,
      price_5d_L: 1.38,
      price_1m_h: 1.47,
      price_1m_l: 1.2,
      price_3m_h: 1.47,
      price_3m_l: 1.07,
      price_6m_h: 1.47,
      price_6m_l: 1.03,
      price_52w_h: 1.47,
      price_52w_l: 1.03,
      price_pc_1d: 0.0,
      price_pc_1w: 5.755,
      price_pc_1m: 17.6,
      price_pc_6m: 31.25,
      price_pc_1y: 24.576,
      price_pc_ytd: 24.576,
    },
    1085: {
      id: "1085",
      c: 3.56,
      ma50: 3.838,
      ma100: 3.677,
      ma150: 3.47,
      ma200: 3.212,
      maw30: 3.499,
      rs: 90.0,
      rsd1d: -1.0,
      rsd5d: -4.0,
      rsd10d: -4.0,
      price_5d_h: 3.95,
      price_5d_L: 3.55,
      price_1m_h: 4.06,
      price_1m_l: 3.41,
      price_3m_h: 4.38,
      price_3m_l: 3.37,
      price_6m_h: 4.38,
      price_6m_l: 2.79,
      price_52w_h: 4.38,
      price_52w_l: 1.98,
      price_pc_1d: -1.928,
      price_pc_1w: -9.873,
      price_pc_1m: -9.645,
      price_pc_6m: 11.95,
      price_pc_1y: 65.581,
      price_pc_ytd: 65.581,
    },
    1086: {
      id: "1086",
      c: 1.02,
      ma50: 0.991,
      ma100: 1.059,
      ma150: 1.113,
      ma200: 1.261,
      maw30: 1.113,
      rs: 42.0,
      rsd1d: 3.0,
      rsd5d: 9.0,
      rsd10d: 16.0,
      price_5d_h: 1.05,
      price_5d_L: 0.91,
      price_1m_h: 1.05,
      price_1m_l: 0.8,
      price_3m_h: 1.2,
      price_3m_l: 0.8,
      price_6m_h: 1.39,
      price_6m_l: 0.8,
      price_52w_h: 2.57,
      price_52w_l: 0.8,
      price_pc_1d: 2.0,
      price_pc_1w: 5.155,
      price_pc_1m: 8.511,
      price_pc_6m: -13.559,
      price_pc_1y: -33.333,
      price_pc_ytd: -33.333,
    },
    1087: {
      id: "1087",
      c: 2.04,
      ma50: 1.844,
      ma100: 1.839,
      ma150: 1.828,
      ma200: 1.757,
      maw30: 1.843,
      rs: 85.0,
      rsd1d: 4.0,
      rsd5d: -3.0,
      rsd10d: -5.0,
      price_5d_h: 2.05,
      price_5d_L: 1.8,
      price_1m_h: 2.2,
      price_1m_l: 1.7,
      price_3m_h: 2.2,
      price_3m_l: 1.52,
      price_6m_h: 2.37,
      price_6m_l: 1.52,
      price_52w_h: 2.48,
      price_52w_l: 1.18,
      price_pc_1d: 4.615,
      price_pc_1w: 3.553,
      price_pc_1m: 10.87,
      price_pc_6m: -10.132,
      price_pc_1y: -5.556,
      price_pc_ytd: -5.556,
    },
    1088: {
      id: "1088",
      c: 25.1,
      ma50: 21.565,
      ma100: 19.662,
      ma150: 18.979,
      ma200: 18.131,
      maw30: 18.89,
      rs: 94.0,
      rsd1d: -1.0,
      rsd5d: 1.0,
      rsd10d: 3.0,
      price_5d_h: 25.9,
      price_5d_L: 22.05,
      price_1m_h: 25.9,
      price_1m_l: 18.2,
      price_3m_h: 25.9,
      price_3m_l: 18.2,
      price_6m_h: 25.9,
      price_6m_l: 15.74,
      price_52w_h: 25.9,
      price_52w_l: 14.3,
      price_pc_1d: 1.414,
      price_pc_1w: 11.062,
      price_pc_1m: 16.744,
      price_pc_6m: 37.761,
      price_pc_1y: 56.679,
      price_pc_ytd: 56.679,
    },
    1090: {
      id: "1090",
      c: 2.68,
      ma50: 2.847,
      ma100: 2.944,
      ma150: 3.166,
      ma200: 3.159,
      maw30: 3.17,
      rs: 52.0,
      rsd1d: -9.0,
      rsd5d: -20.0,
      rsd10d: -22.0,
      price_5d_h: 2.89,
      price_5d_L: 2.68,
      price_1m_h: 3.07,
      price_1m_l: 2.5,
      price_3m_h: 3.18,
      price_3m_l: 2.5,
      price_6m_h: 3.77,
      price_6m_l: 2.5,
      price_52w_h: 4.25,
      price_52w_l: 2.5,
      price_pc_1d: -4.286,
      price_pc_1w: -6.944,
      price_pc_1m: -9.459,
      price_pc_6m: -25.556,
      price_pc_1y: -8.219,
      price_pc_ytd: -8.219,
    },
    1091: {
      id: "1091",
      c: 1.01,
      ma50: 1.089,
      ma100: 1.061,
      ma150: 1.139,
      ma200: 1.078,
      maw30: 1.122,
      rs: 94.0,
      rsd1d: 0.0,
      rsd5d: -3.0,
      rsd10d: 1.0,
      price_5d_h: 1.3,
      price_5d_L: 0.96,
      price_1m_h: 1.69,
      price_1m_l: 0.79,
      price_3m_h: 1.69,
      price_3m_l: 0.79,
      price_6m_h: 1.69,
      price_6m_l: 0.79,
      price_52w_h: 1.89,
      price_52w_l: 0.42,
      price_pc_1d: -1.942,
      price_pc_1w: -19.2,
      price_pc_1m: -27.338,
      price_pc_6m: -14.407,
      price_pc_1y: 137.647,
      price_pc_ytd: 137.647,
    },
    1093: {
      id: "1093",
      c: 9.05,
      ma50: 9.159,
      ma100: 8.79,
      ma150: 8.93,
      ma200: 9.413,
      maw30: 8.924,
      rs: 70.0,
      rsd1d: -11.0,
      rsd5d: -9.0,
      rsd10d: 3.0,
      price_5d_h: 9.62,
      price_5d_L: 8.9,
      price_1m_h: 9.62,
      price_1m_l: 7.28,
      price_3m_h: 10.1,
      price_3m_l: 7.28,
      price_6m_h: 10.1,
      price_6m_l: 7.28,
      price_52w_h: 12.68,
      price_52w_l: 7.28,
      price_pc_1d: -4.937,
      price_pc_1w: -3.209,
      price_pc_1m: -2.162,
      price_pc_6m: -3.105,
      price_pc_1y: -3.621,
      price_pc_ytd: -3.621,
    },
    1097: {
      id: "1097",
      c: 0.042,
      ma50: 0.051,
      ma100: 0.06,
      ma150: 0.063,
      ma200: 0.068,
      maw30: 0.063,
      rs: 12.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: -3.0,
      price_5d_h: 0.045,
      price_5d_L: 0.041,
      price_1m_h: 0.051,
      price_1m_l: 0.04,
      price_3m_h: 0.071,
      price_3m_l: 0.04,
      price_6m_h: 0.095,
      price_6m_l: 0.04,
      price_52w_h: 0.1,
      price_52w_l: 0.04,
      price_pc_1d: -4.545,
      price_pc_1w: -2.326,
      price_pc_1m: -12.5,
      price_pc_6m: -38.235,
      price_pc_1y: -34.375,
      price_pc_ytd: -34.375,
    },
    1098: {
      id: "1098",
      c: 6.75,
      ma50: 7.517,
      ma100: 7.438,
      ma150: 7.682,
      ma200: 8.121,
      maw30: 7.692,
      rs: 39.0,
      rsd1d: 1.0,
      rsd5d: -3.0,
      rsd10d: -23.0,
      price_5d_h: 7.0,
      price_5d_L: 6.52,
      price_1m_h: 7.95,
      price_1m_l: 6.52,
      price_3m_h: 8.24,
      price_3m_l: 6.52,
      price_6m_h: 8.24,
      price_6m_l: 6.52,
      price_52w_h: 11.24,
      price_52w_l: 6.52,
      price_pc_1d: -0.735,
      price_pc_1w: -4.255,
      price_pc_1m: -8.907,
      price_pc_6m: -14.231,
      price_pc_1y: -34.082,
      price_pc_ytd: -34.082,
    },
    1099: {
      id: "1099",
      c: 17.88,
      ma50: 18.424,
      ma100: 17.947,
      ma150: 18.667,
      ma200: 19.483,
      maw30: 18.684,
      rs: 64.0,
      rsd1d: -3.0,
      rsd5d: -3.0,
      rsd10d: -12.0,
      price_5d_h: 18.36,
      price_5d_L: 17.38,
      price_1m_h: 19.52,
      price_1m_l: 16.4,
      price_3m_h: 19.9,
      price_3m_l: 16.4,
      price_6m_h: 22.2,
      price_6m_l: 16.22,
      price_52w_h: 28.0,
      price_52w_l: 16.22,
      price_pc_1d: -1.65,
      price_pc_1w: -2.188,
      price_pc_1m: -5.297,
      price_pc_6m: -11.921,
      price_pc_1y: -7.835,
      price_pc_ytd: -7.835,
    },
    1101: {
      id: "1101",
      c: 0.074,
      ma50: 0.088,
      ma100: 0.098,
      ma150: 0.103,
      ma200: 0.103,
      maw30: 0.104,
      rs: 21.0,
      rsd1d: 0.0,
      rsd5d: -2.0,
      rsd10d: 0.0,
      price_5d_h: 0.082,
      price_5d_L: 0.071,
      price_1m_h: 0.099,
      price_1m_l: 0.065,
      price_3m_h: 0.104,
      price_3m_l: 0.065,
      price_6m_h: 0.127,
      price_6m_l: 0.065,
      price_52w_h: 0.144,
      price_52w_l: 0.065,
      price_pc_1d: -2.632,
      price_pc_1w: -3.896,
      price_pc_1m: -24.49,
      price_pc_6m: -32.727,
      price_pc_1y: -41.732,
      price_pc_ytd: -41.732,
    },
    1104: {
      id: "1104",
      c: 1.16,
      ma50: 1.059,
      ma100: 1.128,
      ma150: 1.194,
      ma200: 1.228,
      maw30: 1.19,
      rs: 67.0,
      rsd1d: 3.0,
      rsd5d: 3.0,
      rsd10d: 27.0,
      price_5d_h: 1.16,
      price_5d_L: 1.1,
      price_1m_h: 1.16,
      price_1m_l: 0.91,
      price_3m_h: 1.16,
      price_3m_l: 0.91,
      price_6m_h: 1.34,
      price_6m_l: 0.91,
      price_52w_h: 1.5,
      price_52w_l: 0.91,
      price_pc_1d: 1.754,
      price_pc_1w: 6.422,
      price_pc_1m: 9.434,
      price_pc_6m: -14.074,
      price_pc_1y: -4.918,
      price_pc_ytd: -4.918,
    },
    1105: {
      id: "1105",
      c: 0.4,
      ma50: 0.481,
      ma100: 0.496,
      ma150: 0.587,
      ma200: 0.667,
      maw30: 0.582,
      rs: 12.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -3.0,
      price_5d_h: 0.425,
      price_5d_L: 0.4,
      price_1m_h: 0.5,
      price_1m_l: 0.37,
      price_3m_h: 0.65,
      price_3m_l: 0.37,
      price_6m_h: 0.81,
      price_6m_l: 0.37,
      price_52w_h: 0.99,
      price_52w_l: 0.37,
      price_pc_1d: -2.439,
      price_pc_1w: -4.762,
      price_pc_1m: -19.192,
      price_pc_6m: -51.22,
      price_pc_1y: -56.044,
      price_pc_ytd: -56.044,
    },
    1107: {
      id: "1107",
      c: 0.107,
      ma50: 0.137,
      ma100: 0.247,
      ma150: 0.346,
      ma200: 0.438,
      maw30: 0.341,
      rs: 1.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: -1.0,
      price_5d_h: 0.135,
      price_5d_L: 0.093,
      price_1m_h: 0.141,
      price_1m_l: 0.089,
      price_3m_h: 0.45,
      price_3m_l: 0.089,
      price_6m_h: 0.61,
      price_6m_l: 0.089,
      price_52w_h: 0.86,
      price_52w_l: 0.089,
      price_pc_1d: -2.727,
      price_pc_1w: -19.549,
      price_pc_1m: -15.079,
      price_pc_6m: -81.552,
      price_pc_1y: -86.951,
      price_pc_ytd: -86.951,
    },
    1108: {
      id: "1108",
      c: 11.72,
      ma50: 14.049,
      ma100: 14.838,
      ma150: 13.604,
      ma200: 12.976,
      maw30: 13.697,
      rs: 81.0,
      rsd1d: -2.0,
      rsd5d: -13.0,
      rsd10d: -14.0,
      price_5d_h: 14.92,
      price_5d_L: 11.28,
      price_1m_h: 16.5,
      price_1m_l: 11.28,
      price_3m_h: 20.25,
      price_3m_l: 11.28,
      price_6m_h: 20.25,
      price_6m_l: 8.75,
      price_52w_h: 20.25,
      price_52w_l: 5.58,
      price_pc_1d: -4.405,
      price_pc_1w: -21.024,
      price_pc_1m: -12.798,
      price_pc_6m: 17.671,
      price_pc_1y: 54.617,
      price_pc_ytd: 54.617,
    },
    1109: {
      id: "1109",
      c: 36.5,
      ma50: 37.422,
      ma100: 35.624,
      ma150: 33.875,
      ma200: 33.009,
      maw30: 34.312,
      rs: 84.0,
      rsd1d: -2.0,
      rsd5d: -1.0,
      rsd10d: -4.0,
      price_5d_h: 37.9,
      price_5d_L: 34.9,
      price_1m_h: 38.55,
      price_1m_l: 30.5,
      price_3m_h: 41.05,
      price_3m_l: 30.5,
      price_6m_h: 41.05,
      price_6m_l: 28.2,
      price_52w_h: 41.05,
      price_52w_l: 25.5,
      price_pc_1d: -1.484,
      price_pc_1w: 0.137,
      price_pc_1m: -3.947,
      price_pc_6m: 11.111,
      price_pc_1y: -1.882,
      price_pc_ytd: -1.882,
    },
    1110: {
      id: "1110",
      c: 0.53,
      ma50: 0.542,
      ma100: 0.645,
      ma150: 0.683,
      ma200: 0.71,
      maw30: 0.686,
      rs: 20.0,
      rsd1d: 2.0,
      rsd5d: -3.0,
      rsd10d: 4.0,
      price_5d_h: 0.58,
      price_5d_L: 0.5,
      price_1m_h: 0.58,
      price_1m_l: 0.39,
      price_3m_h: 0.89,
      price_3m_l: 0.39,
      price_6m_h: 0.89,
      price_6m_l: 0.39,
      price_52w_h: 1.1,
      price_52w_l: 0.39,
      price_pc_1d: 0.0,
      price_pc_1w: -5.357,
      price_pc_1m: 10.417,
      price_pc_6m: -29.333,
      price_pc_1y: -33.75,
      price_pc_ytd: -33.75,
    },
    1112: {
      id: "1112",
      c: 11.02,
      ma50: 11.548,
      ma100: 12.688,
      ma150: 14.977,
      ma200: 18.408,
      maw30: 14.841,
      rs: 15.0,
      rsd1d: 5.0,
      rsd5d: 7.0,
      rsd10d: 5.0,
      price_5d_h: 11.32,
      price_5d_L: 8.81,
      price_1m_h: 12.5,
      price_1m_l: 7.89,
      price_3m_h: 13.78,
      price_3m_l: 7.89,
      price_6m_h: 19.8,
      price_6m_l: 7.89,
      price_52w_h: 32.8,
      price_52w_l: 7.89,
      price_pc_1d: 8.679,
      price_pc_1w: 16.614,
      price_pc_1m: -8.926,
      price_pc_6m: -40.625,
      price_pc_1y: -63.389,
      price_pc_ytd: -63.389,
    },
    1113: {
      id: "1113",
      c: 53.7,
      ma50: 51.737,
      ma100: 49.674,
      ma150: 48.824,
      ma200: 49.853,
      maw30: 48.75,
      rs: 85.0,
      rsd1d: -2.0,
      rsd5d: -1.0,
      rsd10d: 0.0,
      price_5d_h: 54.6,
      price_5d_L: 52.5,
      price_1m_h: 54.6,
      price_1m_l: 47.2,
      price_3m_h: 54.6,
      price_3m_l: 47.2,
      price_6m_h: 54.6,
      price_6m_l: 43.75,
      price_52w_h: 56.05,
      price_52w_l: 41.35,
      price_pc_1d: -1.468,
      price_pc_1w: 1.608,
      price_pc_1m: 8.815,
      price_pc_6m: 19.069,
      price_pc_1y: 14.013,
      price_pc_ytd: 14.013,
    },
    1115: {
      id: "1115",
      c: 0.55,
      ma50: 0.533,
      ma100: 0.556,
      ma150: 0.565,
      ma200: 0.626,
      maw30: 0.57,
      rs: 43.0,
      rsd1d: 6.0,
      rsd5d: 14.0,
      rsd10d: 19.0,
      price_5d_h: 0.62,
      price_5d_L: 0.54,
      price_1m_h: 0.62,
      price_1m_l: 0.44,
      price_3m_h: 0.62,
      price_3m_l: 0.44,
      price_6m_h: 0.66,
      price_6m_l: 0.44,
      price_52w_h: 1.06,
      price_52w_l: 0.44,
      price_pc_1d: 0.0,
      price_pc_1w: 1.852,
      price_pc_1m: 1.852,
      price_pc_6m: 0.0,
      price_pc_1y: -38.202,
      price_pc_ytd: -38.202,
    },
    1116: {
      id: "1116",
      c: 0.185,
      ma50: 0.196,
      ma100: 0.193,
      ma150: 0.193,
      ma200: 0.2,
      maw30: 0.193,
      rs: 57.0,
      rsd1d: -11.0,
      rsd5d: -14.0,
      rsd10d: -9.0,
      price_5d_h: 0.199,
      price_5d_L: 0.175,
      price_1m_h: 0.2,
      price_1m_l: 0.175,
      price_3m_h: 0.21,
      price_3m_l: 0.175,
      price_6m_h: 0.21,
      price_6m_l: 0.148,
      price_52w_h: 0.246,
      price_52w_l: 0.148,
      price_pc_1d: -6.566,
      price_pc_1w: -6.566,
      price_pc_1m: 0.543,
      price_pc_6m: -5.612,
      price_pc_1y: -19.214,
      price_pc_ytd: -19.214,
    },
    1117: {
      id: "1117",
      c: 1.27,
      ma50: 1.371,
      ma100: 1.361,
      ma150: 1.398,
      ma200: 1.434,
      maw30: 1.398,
      rs: 43.0,
      rsd1d: -1.0,
      rsd5d: -16.0,
      rsd10d: 8.0,
      price_5d_h: 1.58,
      price_5d_L: 1.24,
      price_1m_h: 1.58,
      price_1m_l: 1.1,
      price_3m_h: 1.58,
      price_3m_l: 1.1,
      price_6m_h: 1.86,
      price_6m_l: 1.1,
      price_52w_h: 2.17,
      price_52w_l: 1.1,
      price_pc_1d: -3.053,
      price_pc_1w: -7.971,
      price_pc_1m: -11.806,
      price_pc_6m: -14.189,
      price_pc_1y: -33.854,
      price_pc_ytd: -33.854,
    },
    1118: {
      id: "1118",
      c: 1.04,
      ma50: 1.057,
      ma100: 1.021,
      ma150: 0.987,
      ma200: 0.946,
      maw30: 0.985,
      rs: 87.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: 1.0,
      price_5d_h: 1.08,
      price_5d_L: 0.97,
      price_1m_h: 1.08,
      price_1m_l: 0.96,
      price_3m_h: 1.17,
      price_3m_l: 0.96,
      price_6m_h: 1.17,
      price_6m_l: 0.86,
      price_52w_h: 1.17,
      price_52w_l: 0.8,
      price_pc_1d: -0.952,
      price_pc_1w: 0.0,
      price_pc_1m: 0.0,
      price_pc_6m: 14.286,
      price_pc_1y: 22.353,
      price_pc_ytd: 22.353,
    },
    1119: {
      id: "1119",
      c: 4.49,
      ma50: 5.152,
      ma100: 5.608,
      ma150: 5.28,
      ma200: 5.343,
      maw30: 5.316,
      rs: 60.0,
      rsd1d: 3.0,
      rsd5d: -9.0,
      rsd10d: -22.0,
      price_5d_h: 4.72,
      price_5d_L: 4.27,
      price_1m_h: 5.8,
      price_1m_l: 3.88,
      price_3m_h: 6.29,
      price_3m_l: 3.88,
      price_6m_h: 6.94,
      price_6m_l: 3.88,
      price_52w_h: 8.42,
      price_52w_l: 3.54,
      price_pc_1d: 0.673,
      price_pc_1w: -3.024,
      price_pc_1m: -20.107,
      price_pc_6m: 7.674,
      price_pc_1y: 18.783,
      price_pc_ytd: 18.783,
    },
    1121: {
      id: "1121",
      c: 7.32,
      ma50: 8.844,
      ma100: 9.395,
      ma150: 10.222,
      ma200: 9.317,
      maw30: 10.149,
      rs: 77.0,
      rsd1d: -6.0,
      rsd5d: 19.0,
      rsd10d: -8.0,
      price_5d_h: 7.99,
      price_5d_L: 6.4,
      price_1m_h: 11.1,
      price_1m_l: 5.9,
      price_3m_h: 11.74,
      price_3m_l: 5.9,
      price_6m_h: 11.98,
      price_6m_l: 5.9,
      price_52w_h: 17.68,
      price_52w_l: 4.59,
      price_pc_1d: -3.046,
      price_pc_1w: 7.965,
      price_pc_1m: -27.953,
      price_pc_6m: -21.206,
      price_pc_1y: 53.138,
      price_pc_ytd: 53.138,
    },
    1122: {
      id: "1122",
      c: 1.48,
      ma50: 1.517,
      ma100: 1.575,
      ma150: 1.636,
      ma200: 1.643,
      maw30: 1.632,
      rs: 62.0,
      rsd1d: 5.0,
      rsd5d: 7.0,
      rsd10d: 11.0,
      price_5d_h: 1.52,
      price_5d_L: 1.45,
      price_1m_h: 1.54,
      price_1m_l: 1.32,
      price_3m_h: 1.64,
      price_3m_l: 1.32,
      price_6m_h: 1.81,
      price_6m_l: 1.32,
      price_52w_h: 2.1,
      price_52w_l: 1.32,
      price_pc_1d: 1.37,
      price_pc_1w: -1.333,
      price_pc_1m: -1.987,
      price_pc_6m: -9.202,
      price_pc_1y: -3.896,
      price_pc_ytd: -3.896,
    },
    1126: {
      id: "1126",
      c: 2.47,
      ma50: 2.568,
      ma100: 2.684,
      ma150: 2.729,
      ma200: 2.796,
      maw30: 2.732,
      rs: 48.0,
      rsd1d: 0.0,
      rsd5d: 23.0,
      rsd10d: 22.0,
      price_5d_h: 2.54,
      price_5d_L: 2.15,
      price_1m_h: 2.65,
      price_1m_l: 2.01,
      price_3m_h: 2.9,
      price_3m_l: 2.01,
      price_6m_h: 3.0,
      price_6m_l: 2.01,
      price_52w_h: 3.52,
      price_52w_l: 2.01,
      price_pc_1d: -0.403,
      price_pc_1w: 15.962,
      price_pc_1m: -5.0,
      price_pc_6m: -6.439,
      price_pc_1y: -19.016,
      price_pc_ytd: -19.016,
    },
    1127: {
      id: "1127",
      c: 0.97,
      ma50: 0.894,
      ma100: 0.864,
      ma150: 0.841,
      ma200: 0.841,
      maw30: 0.84,
      rs: 89.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: 7.0,
      price_5d_h: 0.97,
      price_5d_L: 0.91,
      price_1m_h: 0.97,
      price_1m_l: 0.82,
      price_3m_h: 0.97,
      price_3m_l: 0.82,
      price_6m_h: 0.97,
      price_6m_l: 0.74,
      price_52w_h: 0.97,
      price_52w_l: 0.74,
      price_pc_1d: 2.105,
      price_pc_1w: 2.105,
      price_pc_1m: 10.227,
      price_pc_6m: 25.974,
      price_pc_1y: 16.867,
      price_pc_ytd: 16.867,
    },
    1128: {
      id: "1128",
      c: 5.74,
      ma50: 6.582,
      ma100: 6.592,
      ma150: 6.861,
      ma200: 7.825,
      maw30: 6.831,
      rs: 23.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: -1.0,
      price_5d_h: 5.9,
      price_5d_L: 5.19,
      price_1m_h: 6.87,
      price_1m_l: 4.23,
      price_3m_h: 7.8,
      price_3m_l: 4.23,
      price_6m_h: 8.47,
      price_6m_l: 4.23,
      price_52w_h: 15.78,
      price_52w_l: 4.23,
      price_pc_1d: -1.544,
      price_pc_1w: -0.52,
      price_pc_1m: -13.814,
      price_pc_6m: -11.963,
      price_pc_1y: -62.484,
      price_pc_ytd: -62.484,
    },
    1130: {
      id: "1130",
      c: 0.051,
      ma50: 0.053,
      ma100: 0.059,
      ma150: 0.061,
      ma200: 0.063,
      maw30: 0.06,
      rs: 29.0,
      rsd1d: -10.0,
      rsd5d: -12.0,
      rsd10d: 7.0,
      price_5d_h: 0.057,
      price_5d_L: 0.051,
      price_1m_h: 0.067,
      price_1m_l: 0.043,
      price_3m_h: 0.067,
      price_3m_l: 0.043,
      price_6m_h: 0.076,
      price_6m_l: 0.043,
      price_52w_h: 0.088,
      price_52w_l: 0.043,
      price_pc_1d: -8.929,
      price_pc_1w: -12.069,
      price_pc_1m: -5.556,
      price_pc_6m: -12.069,
      price_pc_1y: -31.081,
      price_pc_ytd: -31.081,
    },
    1132: {
      id: "1132",
      c: 0.07,
      ma50: 0.08,
      ma100: 0.094,
      ma150: 0.102,
      ma200: 0.109,
      maw30: 0.103,
      rs: 12.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: 3.0,
      price_5d_h: 0.078,
      price_5d_L: 0.064,
      price_1m_h: 0.078,
      price_1m_l: 0.046,
      price_3m_h: 0.115,
      price_3m_l: 0.046,
      price_6m_h: 0.122,
      price_6m_l: 0.046,
      price_52w_h: 0.17,
      price_52w_l: 0.046,
      price_pc_1d: 1.449,
      price_pc_1w: 0.0,
      price_pc_1m: -15.663,
      price_pc_6m: -36.937,
      price_pc_1y: -41.667,
      price_pc_ytd: -41.667,
    },
    1133: {
      id: "1133",
      c: 2.23,
      ma50: 2.636,
      ma100: 3.045,
      ma150: 3.088,
      ma200: 2.847,
      maw30: 3.115,
      rs: 35.0,
      rsd1d: -3.0,
      rsd5d: -16.0,
      rsd10d: -7.0,
      price_5d_h: 2.57,
      price_5d_L: 2.12,
      price_1m_h: 2.85,
      price_1m_l: 1.96,
      price_3m_h: 3.88,
      price_3m_l: 1.96,
      price_6m_h: 4.02,
      price_6m_l: 1.96,
      price_52w_h: 4.08,
      price_52w_l: 1.86,
      price_pc_1d: -3.043,
      price_pc_1w: -11.155,
      price_pc_1m: -19.203,
      price_pc_6m: -39.73,
      price_pc_1y: 6.19,
      price_pc_ytd: 6.19,
    },
    1134: {
      id: "1134",
      c: 0.82,
      ma50: 1.01,
      ma100: 1.173,
      ma150: 1.39,
      ma200: 1.432,
      maw30: 1.388,
      rs: 11.0,
      rsd1d: 2.0,
      rsd5d: 2.0,
      rsd10d: -6.0,
      price_5d_h: 0.91,
      price_5d_L: 0.79,
      price_1m_h: 1.1,
      price_1m_l: 0.79,
      price_3m_h: 1.22,
      price_3m_l: 0.79,
      price_6m_h: 2.2,
      price_6m_l: 0.79,
      price_52w_h: 2.74,
      price_52w_l: 0.79,
      price_pc_1d: -1.205,
      price_pc_1w: -3.529,
      price_pc_1m: -19.608,
      price_pc_6m: -51.765,
      price_pc_1y: -60.766,
      price_pc_ytd: -60.766,
    },
    1137: {
      id: "1137",
      c: 6.95,
      ma50: 7.705,
      ma100: 8.341,
      ma150: 9.224,
      ma200: 9.465,
      maw30: 9.256,
      rs: 23.0,
      rsd1d: -3.0,
      rsd5d: -8.0,
      rsd10d: -17.0,
      price_5d_h: 7.45,
      price_5d_L: 6.71,
      price_1m_h: 9.21,
      price_1m_l: 6.0,
      price_3m_h: 9.5,
      price_3m_l: 6.0,
      price_6m_h: 12.58,
      price_6m_l: 6.0,
      price_52w_h: 12.58,
      price_52w_l: 6.0,
      price_pc_1d: -5.571,
      price_pc_1w: -5.571,
      price_pc_1m: -16.265,
      price_pc_6m: -36.933,
      price_pc_1y: -37.724,
      price_pc_ytd: -37.724,
    },
    1138: {
      id: "1138",
      c: 3.63,
      ma50: 3.335,
      ma100: 3.182,
      ma150: 3.283,
      ma200: 3.279,
      maw30: 3.282,
      rs: 84.0,
      rsd1d: -2.0,
      rsd5d: 0.0,
      rsd10d: 8.0,
      price_5d_h: 3.86,
      price_5d_L: 3.29,
      price_1m_h: 3.86,
      price_1m_l: 2.9,
      price_3m_h: 3.86,
      price_3m_l: 2.88,
      price_6m_h: 4.54,
      price_6m_l: 2.88,
      price_52w_h: 4.54,
      price_52w_l: 2.81,
      price_pc_1d: -1.09,
      price_pc_1w: 3.714,
      price_pc_1m: -2.419,
      price_pc_6m: -4.974,
      price_pc_1y: 7.396,
      price_pc_ytd: 7.396,
    },
    1140: {
      id: "1140",
      c: 0.6,
      ma50: 0.663,
      ma100: 0.696,
      ma150: 0.721,
      ma200: 0.73,
      maw30: 0.723,
      rs: 27.0,
      rsd1d: 2.0,
      rsd5d: 1.0,
      rsd10d: -6.0,
      price_5d_h: 0.6,
      price_5d_L: 0.58,
      price_1m_h: 0.68,
      price_1m_l: 0.54,
      price_3m_h: 0.85,
      price_3m_l: 0.54,
      price_6m_h: 0.85,
      price_6m_l: 0.54,
      price_52w_h: 0.89,
      price_52w_l: 0.54,
      price_pc_1d: 1.695,
      price_pc_1w: 3.448,
      price_pc_1m: -11.765,
      price_pc_6m: -25.0,
      price_pc_1y: -32.584,
      price_pc_ytd: -32.584,
    },
    1141: {
      id: "1141",
      c: 1.72,
      ma50: 2.292,
      ma100: 2.774,
      ma150: 3.096,
      ma200: 3.312,
      maw30: 3.148,
      rs: 4.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: -3.0,
      price_5d_h: 1.79,
      price_5d_L: 1.5,
      price_1m_h: 2.37,
      price_1m_l: 1.38,
      price_3m_h: 4.59,
      price_3m_l: 1.38,
      price_6m_h: 4.6,
      price_6m_l: 0.094,
      price_52w_h: 4.6,
      price_52w_l: 0.094,
      price_pc_1d: -1.149,
      price_pc_1w: 0.585,
      price_pc_1m: -25.862,
      price_pc_6m: -56.122,
      price_pc_1y: -59.813,
      price_pc_ytd: -59.813,
    },
    1142: {
      id: "1142",
      c: 1.28,
      ma50: 1.102,
      ma100: 1.062,
      ma150: 1.096,
      ma200: 1.146,
      maw30: 1.1,
      rs: 86.0,
      rsd1d: -2.0,
      rsd5d: -2.0,
      rsd10d: -5.0,
      price_5d_h: 1.3,
      price_5d_L: 1.3,
      price_1m_h: 1.62,
      price_1m_l: 0.68,
      price_3m_h: 1.62,
      price_3m_l: 0.68,
      price_6m_h: 1.62,
      price_6m_l: 0.68,
      price_52w_h: 2.3,
      price_52w_l: 0.68,
      price_pc_1d: -1.538,
      price_pc_1w: -1.538,
      price_pc_1m: 16.364,
      price_pc_6m: -0.775,
      price_pc_1y: 8.475,
      price_pc_ytd: 8.475,
    },
    1143: {
      id: "1143",
      c: 0.107,
      ma50: 0.142,
      ma100: 0.169,
      ma150: 0.188,
      ma200: 0.208,
      maw30: 0.188,
      rs: 5.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: -5.0,
      price_5d_h: 0.121,
      price_5d_L: 0.097,
      price_1m_h: 0.15,
      price_1m_l: 0.097,
      price_3m_h: 0.202,
      price_3m_l: 0.097,
      price_6m_h: 0.28,
      price_6m_l: 0.097,
      price_52w_h: 1.97,
      price_52w_l: 0.097,
      price_pc_1d: -5.31,
      price_pc_1w: -10.084,
      price_pc_1m: -27.211,
      price_pc_6m: -52.655,
      price_pc_1y: -87.108,
      price_pc_ytd: -87.108,
    },
    1145: {
      id: "1145",
      c: 0.244,
      ma50: 0.23,
      ma100: 0.251,
      ma150: 0.237,
      ma200: 0.244,
      maw30: 0.241,
      rs: 59.0,
      rsd1d: 17.0,
      rsd5d: 7.0,
      rsd10d: 11.0,
      price_5d_h: 0.25,
      price_5d_L: 0.193,
      price_1m_h: 0.345,
      price_1m_l: 0.183,
      price_3m_h: 0.345,
      price_3m_l: 0.179,
      price_6m_h: 0.345,
      price_6m_l: 0.179,
      price_52w_h: 0.44,
      price_52w_l: 0.179,
      price_pc_1d: 10.909,
      price_pc_1w: 1.667,
      price_pc_1m: 17.308,
      price_pc_6m: 28.421,
      price_pc_1y: -39.0,
      price_pc_ytd: -39.0,
    },
    1147: {
      id: "1147",
      c: 0.088,
      ma50: 0.092,
      ma100: 0.1,
      ma150: 0.104,
      ma200: 0.108,
      maw30: 0.104,
      rs: 35.0,
      rsd1d: 2.0,
      rsd5d: 9.0,
      rsd10d: 12.0,
      price_5d_h: 0.093,
      price_5d_L: 0.08,
      price_1m_h: 0.099,
      price_1m_l: 0.068,
      price_3m_h: 0.118,
      price_3m_l: 0.068,
      price_6m_h: 0.126,
      price_6m_l: 0.068,
      price_52w_h: 0.148,
      price_52w_l: 0.068,
      price_pc_1d: 0.0,
      price_pc_1w: 4.762,
      price_pc_1m: -5.376,
      price_pc_6m: -22.124,
      price_pc_1y: -19.266,
      price_pc_ytd: -19.266,
    },
    1148: {
      id: "1148",
      c: 0.46,
      ma50: 0.641,
      ma100: 0.748,
      ma150: 0.826,
      ma200: 0.749,
      maw30: 0.835,
      rs: 31.0,
      rsd1d: 0.0,
      rsd5d: -13.0,
      rsd10d: -8.0,
      price_5d_h: 0.53,
      price_5d_L: 0.45,
      price_1m_h: 0.68,
      price_1m_l: 0.45,
      price_3m_h: 0.86,
      price_3m_l: 0.45,
      price_6m_h: 1.25,
      price_6m_l: 0.45,
      price_52w_h: 1.45,
      price_52w_l: 0.345,
      price_pc_1d: -2.128,
      price_pc_1w: -9.804,
      price_pc_1m: -30.303,
      price_pc_6m: -54.455,
      price_pc_1y: 31.429,
      price_pc_ytd: 31.429,
    },
    1153: {
      id: "1153",
      c: 3.4,
      ma50: 3.375,
      ma100: 3.537,
      ma150: 3.763,
      ma200: 3.845,
      maw30: 3.755,
      rs: 57.0,
      rsd1d: 2.0,
      rsd5d: 9.0,
      rsd10d: 5.0,
      price_5d_h: 3.41,
      price_5d_L: 3.35,
      price_1m_h: 3.41,
      price_1m_l: 3.15,
      price_3m_h: 3.5,
      price_3m_l: 3.15,
      price_6m_h: 4.3,
      price_6m_l: 3.15,
      price_52w_h: 4.3,
      price_52w_l: 3.15,
      price_pc_1d: 0.0,
      price_pc_1w: 3.03,
      price_pc_1m: 1.19,
      price_pc_6m: -18.269,
      price_pc_1y: -17.275,
      price_pc_ytd: -17.275,
    },
    1157: {
      id: "1157",
      c: 4.99,
      ma50: 5.198,
      ma100: 5.194,
      ma150: 5.83,
      ma200: 6.266,
      maw30: 5.815,
      rs: 30.0,
      rsd1d: -2.0,
      rsd5d: 0.0,
      rsd10d: 2.0,
      price_5d_h: 5.25,
      price_5d_L: 4.85,
      price_1m_h: 5.35,
      price_1m_l: 4.16,
      price_3m_h: 5.84,
      price_3m_l: 4.16,
      price_6m_h: 7.17,
      price_6m_l: 4.16,
      price_52w_h: 12.1,
      price_52w_l: 4.16,
      price_pc_1d: -3.482,
      price_pc_1w: -3.295,
      price_pc_1m: -3.668,
      price_pc_6m: -28.407,
      price_pc_1y: -57.423,
      price_pc_ytd: -57.423,
    },
    1159: {
      id: "1159",
      c: 0.42,
      ma50: 0.632,
      ma100: 0.782,
      ma150: 0.894,
      ma200: 1.035,
      maw30: 0.885,
      rs: 3.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: -1.0,
      price_5d_h: 0.52,
      price_5d_L: 0.4,
      price_1m_h: 0.72,
      price_1m_l: 0.4,
      price_3m_h: 1.13,
      price_3m_l: 0.4,
      price_6m_h: 1.2,
      price_6m_l: 0.4,
      price_52w_h: 1.98,
      price_52w_l: 0.4,
      price_pc_1d: -3.448,
      price_pc_1w: -13.402,
      price_pc_1m: -40.0,
      price_pc_6m: -61.468,
      price_pc_1y: -72.903,
      price_pc_ytd: -72.903,
    },
    1161: {
      id: "1161",
      c: 1.27,
      ma50: 1.511,
      ma100: 1.462,
      ma150: 1.386,
      ma200: 1.352,
      maw30: 1.412,
      rs: 77.0,
      rsd1d: -11.0,
      rsd5d: -12.0,
      rsd10d: -16.0,
      price_5d_h: 1.28,
      price_5d_L: 1.2,
      price_1m_h: 1.53,
      price_1m_l: 1.2,
      price_3m_h: 2.0,
      price_3m_l: 1.2,
      price_6m_h: 2.0,
      price_6m_l: 1.1,
      price_52w_h: 2.0,
      price_52w_l: 0.72,
      price_pc_1d: 1.6,
      price_pc_1w: 0.794,
      price_pc_1m: -12.414,
      price_pc_6m: 11.404,
      price_pc_1y: 56.79,
      price_pc_ytd: 56.79,
    },
    1162: {
      id: "1162",
      c: 0.18,
      ma50: 0.193,
      ma100: 0.201,
      ma150: 0.206,
      ma200: 0.201,
      maw30: 0.205,
      rs: 65.0,
      rsd1d: 2.0,
      rsd5d: 4.0,
      rsd10d: 2.0,
      price_5d_h: 0.186,
      price_5d_L: 0.143,
      price_1m_h: 0.194,
      price_1m_l: 0.143,
      price_3m_h: 0.21,
      price_3m_l: 0.143,
      price_6m_h: 0.435,
      price_6m_l: 0.143,
      price_52w_h: 0.435,
      price_52w_l: 0.143,
      price_pc_1d: -0.552,
      price_pc_1w: -3.226,
      price_pc_1m: -3.743,
      price_pc_6m: -10.448,
      price_pc_1y: -0.552,
      price_pc_ytd: -0.552,
    },
    1163: {
      id: "1163",
      c: 0.74,
      ma50: 0.872,
      ma100: 0.836,
      ma150: 0.808,
      ma200: 0.834,
      maw30: 0.808,
      rs: 48.0,
      rsd1d: -8.0,
      rsd5d: -14.0,
      rsd10d: 6.0,
      price_5d_h: 0.84,
      price_5d_L: 0.74,
      price_1m_h: 0.93,
      price_1m_l: 0.62,
      price_3m_h: 0.99,
      price_3m_l: 0.62,
      price_6m_h: 0.99,
      price_6m_l: 0.55,
      price_52w_h: 1.09,
      price_52w_l: 0.55,
      price_pc_1d: -5.128,
      price_pc_1w: -11.905,
      price_pc_1m: -21.277,
      price_pc_6m: 1.37,
      price_pc_1y: -16.854,
      price_pc_ytd: -16.854,
    },
    1164: {
      id: "1164",
      c: 0.86,
      ma50: 0.817,
      ma100: 0.865,
      ma150: 0.911,
      ma200: 0.845,
      maw30: 0.917,
      rs: 89.0,
      rsd1d: 5.0,
      rsd5d: 13.0,
      rsd10d: 32.0,
      price_5d_h: 0.86,
      price_5d_L: 0.74,
      price_1m_h: 0.97,
      price_1m_l: 0.71,
      price_3m_h: 0.97,
      price_3m_l: 0.71,
      price_6m_h: 1.43,
      price_6m_l: 0.71,
      price_52w_h: 1.53,
      price_52w_l: 0.53,
      price_pc_1d: 8.861,
      price_pc_1w: 4.878,
      price_pc_1m: -5.495,
      price_pc_6m: -13.131,
      price_pc_1y: 45.763,
      price_pc_ytd: 45.763,
    },
    1165: {
      id: "1165",
      c: 0.049,
      ma50: 0.055,
      ma100: 0.067,
      ma150: 0.088,
      ma200: 0.103,
      maw30: 0.088,
      rs: 5.0,
      rsd1d: 1.0,
      rsd5d: -1.0,
      rsd10d: -1.0,
      price_5d_h: 0.054,
      price_5d_L: 0.047,
      price_1m_h: 0.057,
      price_1m_l: 0.046,
      price_3m_h: 0.11,
      price_3m_l: 0.046,
      price_6m_h: 0.165,
      price_6m_l: 0.046,
      price_52w_h: 0.219,
      price_52w_l: 0.046,
      price_pc_1d: -2.0,
      price_pc_1w: -7.547,
      price_pc_1m: -9.259,
      price_pc_6m: -63.971,
      price_pc_1y: -75.5,
      price_pc_ytd: -75.5,
    },
    1167: {
      id: "1167",
      c: 7.94,
      ma50: 8.751,
      ma100: 11.687,
      ma150: 14.208,
      ma200: 15.667,
      maw30: 14.137,
      rs: 7.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: 3.0,
      price_5d_h: 8.2,
      price_5d_L: 7.19,
      price_1m_h: 8.79,
      price_1m_l: 6.38,
      price_3m_h: 14.5,
      price_3m_l: 6.38,
      price_6m_h: 20.2,
      price_6m_l: 6.38,
      price_52w_h: 24.6,
      price_52w_l: 6.38,
      price_pc_1d: -1.854,
      price_pc_1w: 3.117,
      price_pc_1m: -6.368,
      price_pc_6m: -57.676,
      price_pc_1y: -52.169,
      price_pc_ytd: -52.169,
    },
    1168: {
      id: "1168",
      c: 0.23,
      ma50: 0.229,
      ma100: 0.237,
      ma150: 0.246,
      ma200: 0.26,
      maw30: 0.245,
      rs: 37.0,
      rsd1d: 3.0,
      rsd5d: 0.0,
      rsd10d: -9.0,
      price_5d_h: 0.237,
      price_5d_L: 0.223,
      price_1m_h: 0.245,
      price_1m_l: 0.191,
      price_3m_h: 0.25,
      price_3m_l: 0.191,
      price_6m_h: 0.29,
      price_6m_l: 0.191,
      price_52w_h: 0.425,
      price_52w_l: 0.191,
      price_pc_1d: 1.322,
      price_pc_1w: -1.288,
      price_pc_1m: 2.679,
      price_pc_6m: -9.804,
      price_pc_1y: -39.474,
      price_pc_ytd: -39.474,
    },
    1171: {
      id: "1171",
      c: 23.25,
      ma50: 20.001,
      ma100: 17.14,
      ma150: 16.111,
      ma200: 14.846,
      maw30: 16.057,
      rs: 97.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 2.0,
      price_5d_h: 25.5,
      price_5d_L: 22.8,
      price_1m_h: 25.5,
      price_1m_l: 16.84,
      price_3m_h: 25.5,
      price_3m_l: 15.32,
      price_6m_h: 25.5,
      price_6m_l: 10.96,
      price_52w_h: 25.5,
      price_52w_l: 9.16,
      price_pc_1d: -4.124,
      price_pc_1w: -0.428,
      price_pc_1m: 21.347,
      price_pc_6m: 57.734,
      price_pc_1y: 146.554,
      price_pc_ytd: 146.554,
    },
    1173: {
      id: "1173",
      c: 0.046,
      ma50: 0.055,
      ma100: 0.056,
      ma150: 0.056,
      ma200: 0.057,
      maw30: 0.056,
      rs: 33.0,
      rsd1d: -2.0,
      rsd5d: -29.0,
      rsd10d: -16.0,
      price_5d_h: 0.049,
      price_5d_L: 0.043,
      price_1m_h: 0.059,
      price_1m_l: 0.043,
      price_3m_h: 0.079,
      price_3m_l: 0.043,
      price_6m_h: 0.079,
      price_6m_l: 0.043,
      price_52w_h: 0.1,
      price_52w_l: 0.041,
      price_pc_1d: -2.128,
      price_pc_1w: -14.815,
      price_pc_1m: -23.333,
      price_pc_6m: -14.815,
      price_pc_1y: -8.0,
      price_pc_ytd: -8.0,
    },
    1176: {
      id: "1176",
      c: 1.31,
      ma50: 1.505,
      ma100: 1.545,
      ma150: 1.604,
      ma200: 1.624,
      maw30: 1.615,
      rs: 27.0,
      rsd1d: 4.0,
      rsd5d: -9.0,
      rsd10d: -14.0,
      price_5d_h: 1.36,
      price_5d_L: 1.25,
      price_1m_h: 1.53,
      price_1m_l: 1.24,
      price_3m_h: 1.77,
      price_3m_l: 1.24,
      price_6m_h: 1.91,
      price_6m_l: 1.24,
      price_52w_h: 2.73,
      price_52w_l: 1.24,
      price_pc_1d: 0.769,
      price_pc_1w: -2.963,
      price_pc_1m: -13.816,
      price_pc_6m: -25.143,
      price_pc_1y: -34.5,
      price_pc_ytd: -34.5,
    },
    1177: {
      id: "1177",
      c: 4.89,
      ma50: 5.126,
      ma100: 5.39,
      ma150: 5.654,
      ma200: 6.006,
      maw30: 5.672,
      rs: 30.0,
      rsd1d: 0.0,
      rsd5d: -2.0,
      rsd10d: 6.0,
      price_5d_h: 5.08,
      price_5d_L: 4.73,
      price_1m_h: 5.12,
      price_1m_l: 3.71,
      price_3m_h: 6.3,
      price_3m_l: 3.71,
      price_6m_h: 6.46,
      price_6m_l: 3.71,
      price_52w_h: 9.69,
      price_52w_l: 3.71,
      price_pc_1d: -1.61,
      price_pc_1w: -3.74,
      price_pc_1m: -1.807,
      price_pc_6m: -24.303,
      price_pc_1y: -36.985,
      price_pc_ytd: -36.985,
    },
    1179: {
      id: "1179",
      c: 26.75,
      ma50: 29.403,
      ma100: 30.208,
      ma150: 32.504,
      ma200: 33.778,
      maw30: 32.433,
      rs: 32.0,
      rsd1d: 4.0,
      rsd5d: 3.0,
      rsd10d: -1.0,
      price_5d_h: 27.05,
      price_5d_L: 24.35,
      price_1m_h: 34.2,
      price_1m_l: 17.54,
      price_3m_h: 36.45,
      price_3m_l: 17.54,
      price_6m_h: 39.5,
      price_6m_l: 17.54,
      price_52w_h: 48.3,
      price_52w_l: 17.54,
      price_pc_1d: 3.482,
      price_pc_1w: 1.134,
      price_pc_1m: -15.881,
      price_pc_6m: -25.384,
      price_pc_1y: -38.05,
      price_pc_ytd: -38.05,
    },
    1180: {
      id: "1180",
      c: 0.87,
      ma50: 0.967,
      ma100: 0.99,
      ma150: 0.969,
      ma200: 0.977,
      maw30: 0.967,
      rs: 44.0,
      rsd1d: 2.0,
      rsd5d: -15.0,
      rsd10d: -4.0,
      price_5d_h: 0.89,
      price_5d_L: 0.86,
      price_1m_h: 1.01,
      price_1m_l: 0.8,
      price_3m_h: 1.3,
      price_3m_l: 0.8,
      price_6m_h: 1.3,
      price_6m_l: 0.8,
      price_52w_h: 1.62,
      price_52w_l: 0.8,
      price_pc_1d: 0.0,
      price_pc_1w: -2.247,
      price_pc_1m: -13.0,
      price_pc_6m: -4.396,
      price_pc_1y: -17.925,
      price_pc_ytd: -17.925,
    },
    1181: {
      id: "1181",
      c: 0.65,
      ma50: 0.714,
      ma100: 0.715,
      ma150: 0.719,
      ma200: 0.746,
      maw30: 0.721,
      rs: 43.0,
      rsd1d: 1.0,
      rsd5d: -6.0,
      rsd10d: 0.0,
      price_5d_h: 0.68,
      price_5d_L: 0.61,
      price_1m_h: 0.73,
      price_1m_l: 0.56,
      price_3m_h: 0.83,
      price_3m_l: 0.56,
      price_6m_h: 0.83,
      price_6m_l: 0.56,
      price_52w_h: 0.99,
      price_52w_l: 0.56,
      price_pc_1d: 0.0,
      price_pc_1w: -2.985,
      price_pc_1m: -8.451,
      price_pc_6m: -10.959,
      price_pc_1y: -26.136,
      price_pc_ytd: -26.136,
    },
    1183: {
      id: "1183",
      c: 3.6,
      ma50: 3.445,
      ma100: 3.49,
      ma150: 3.558,
      ma200: 3.646,
      maw30: 3.549,
      rs: 78.0,
      rsd1d: -2.0,
      rsd5d: 0.0,
      rsd10d: 17.0,
      price_5d_h: 3.63,
      price_5d_L: 3.42,
      price_1m_h: 3.63,
      price_1m_l: 2.95,
      price_3m_h: 3.78,
      price_3m_l: 2.95,
      price_6m_h: 3.83,
      price_6m_l: 2.95,
      price_52w_h: 4.4,
      price_52w_l: 2.527,
      price_pc_1d: -0.277,
      price_pc_1w: 3.746,
      price_pc_1m: 5.572,
      price_pc_6m: 3.746,
      price_pc_1y: 13.924,
      price_pc_ytd: 13.924,
    },
    1184: {
      id: "1184",
      c: 4.89,
      ma50: 5.118,
      ma100: 4.989,
      ma150: 4.821,
      ma200: 4.519,
      maw30: 4.869,
      rs: 91.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -2.0,
      price_5d_h: 4.99,
      price_5d_L: 4.7,
      price_1m_h: 5.0,
      price_1m_l: 4.2,
      price_3m_h: 5.7,
      price_3m_l: 4.2,
      price_6m_h: 5.7,
      price_6m_l: 4.1,
      price_52w_h: 5.7,
      price_52w_l: 2.95,
      price_pc_1d: 0.825,
      price_pc_1w: -2.004,
      price_pc_1m: 4.043,
      price_pc_6m: 6.304,
      price_pc_1y: 65.763,
      price_pc_ytd: 65.763,
    },
    1186: {
      id: "1186",
      c: 4.79,
      ma50: 5.299,
      ma100: 5.187,
      ma150: 5.211,
      ma200: 5.147,
      maw30: 5.237,
      rs: 64.0,
      rsd1d: -3.0,
      rsd5d: 0.0,
      rsd10d: -4.0,
      price_5d_h: 4.96,
      price_5d_L: 4.7,
      price_1m_h: 5.35,
      price_1m_l: 4.39,
      price_3m_h: 5.95,
      price_3m_l: 4.39,
      price_6m_h: 5.95,
      price_6m_l: 4.39,
      price_52w_h: 5.99,
      price_52w_l: 4.39,
      price_pc_1d: -2.245,
      price_pc_1w: -1.033,
      price_pc_1m: -8.238,
      price_pc_6m: -5.709,
      price_pc_1y: -6.99,
      price_pc_ytd: -6.99,
    },
    1193: {
      id: "1193",
      c: 33.25,
      ma50: 35.406,
      ma100: 38.584,
      ma150: 40.047,
      ma200: 42.009,
      maw30: 39.895,
      rs: 30.0,
      rsd1d: 2.0,
      rsd5d: 7.0,
      rsd10d: 12.0,
      price_5d_h: 34.2,
      price_5d_L: 31.05,
      price_1m_h: 35.55,
      price_1m_l: 26.0,
      price_3m_h: 45.15,
      price_3m_l: 26.0,
      price_6m_h: 45.85,
      price_6m_l: 26.0,
      price_52w_h: 52.25,
      price_52w_l: 26.0,
      price_pc_1d: -1.773,
      price_pc_1w: 3.421,
      price_pc_1m: -7.252,
      price_pc_6m: -18.704,
      price_pc_1y: -22.584,
      price_pc_ytd: -22.584,
    },
    1194: {
      id: "1194",
      c: 0.35,
      ma50: 0.374,
      ma100: 0.374,
      ma150: 0.378,
      ma200: 0.417,
      maw30: 0.379,
      rs: 33.0,
      rsd1d: 3.0,
      rsd5d: 4.0,
      rsd10d: 16.0,
      price_5d_h: 0.35,
      price_5d_L: 0.32,
      price_1m_h: 0.4,
      price_1m_l: 0.29,
      price_3m_h: 0.455,
      price_3m_l: 0.29,
      price_6m_h: 0.455,
      price_6m_l: 0.29,
      price_52w_h: 1.19,
      price_52w_l: 0.29,
      price_pc_1d: 0.0,
      price_pc_1w: -5.405,
      price_pc_1m: -11.392,
      price_pc_6m: -2.778,
      price_pc_1y: -58.824,
      price_pc_ytd: -58.824,
    },
    1195: {
      id: "1195",
      c: 0.036,
      ma50: 0.042,
      ma100: 0.043,
      ma150: 0.044,
      ma200: 0.044,
      maw30: 0.045,
      rs: 38.0,
      rsd1d: 4.0,
      rsd5d: 7.0,
      rsd10d: 11.0,
      price_5d_h: 0.036,
      price_5d_L: 0.033,
      price_1m_h: 0.046,
      price_1m_l: 0.025,
      price_3m_h: 0.049,
      price_3m_l: 0.025,
      price_6m_h: 0.066,
      price_6m_l: 0.025,
      price_52w_h: 0.07,
      price_52w_l: 0.025,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: -16.279,
      price_pc_6m: -14.286,
      price_pc_1y: -21.739,
      price_pc_ytd: -21.739,
    },
    1196: {
      id: "1196",
      c: 10.12,
      ma50: 9.148,
      ma100: 9.5,
      ma150: 9.913,
      ma200: 10.232,
      maw30: 9.905,
      rs: 75.0,
      rsd1d: 14.0,
      rsd5d: 13.0,
      rsd10d: 19.0,
      price_5d_h: 10.16,
      price_5d_L: 9.06,
      price_1m_h: 10.16,
      price_1m_l: 7.88,
      price_3m_h: 10.38,
      price_3m_l: 7.88,
      price_6m_h: 14.0,
      price_6m_l: 7.88,
      price_52w_h: 14.0,
      price_52w_l: 7.88,
      price_pc_1d: 6.977,
      price_pc_1w: 9.524,
      price_pc_1m: 3.901,
      price_pc_6m: 1.606,
      price_pc_1y: 3.265,
      price_pc_ytd: 3.265,
    },
    1198: {
      id: "1198",
      c: 2.92,
      ma50: 2.942,
      ma100: 2.727,
      ma150: 2.572,
      ma200: 2.457,
      maw30: 2.585,
      rs: 93.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: -2.0,
      price_5d_h: 2.99,
      price_5d_L: 2.92,
      price_1m_h: 3.0,
      price_1m_l: 2.68,
      price_3m_h: 3.1,
      price_3m_l: 2.68,
      price_6m_h: 3.1,
      price_6m_l: 2.0,
      price_52w_h: 3.1,
      price_52w_l: 1.22,
      price_pc_1d: -1.017,
      price_pc_1w: -1.684,
      price_pc_1m: 3.18,
      price_pc_6m: 23.729,
      price_pc_1y: 110.072,
      price_pc_ytd: 110.072,
    },
    1199: {
      id: "1199",
      c: 6.1,
      ma50: 6.362,
      ma100: 6.419,
      ma150: 6.42,
      ma200: 6.317,
      maw30: 6.462,
      rs: 67.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: -7.0,
      price_5d_h: 6.38,
      price_5d_L: 6.05,
      price_1m_h: 6.64,
      price_1m_l: 5.35,
      price_3m_h: 7.2,
      price_3m_l: 5.35,
      price_6m_h: 7.2,
      price_6m_l: 5.35,
      price_52w_h: 7.42,
      price_52w_l: 5.35,
      price_pc_1d: -1.135,
      price_pc_1w: -3.175,
      price_pc_1m: -5.132,
      price_pc_6m: -9.496,
      price_pc_1y: -3.937,
      price_pc_ytd: -3.937,
    },
    1200: {
      id: "1200",
      c: 0.84,
      ma50: 0.932,
      ma100: 0.998,
      ma150: 1.091,
      ma200: 1.188,
      maw30: 1.083,
      rs: 32.0,
      rsd1d: -3.0,
      rsd5d: -1.0,
      rsd10d: 0.0,
      price_5d_h: 0.93,
      price_5d_L: 0.83,
      price_1m_h: 0.98,
      price_1m_l: 0.79,
      price_3m_h: 1.05,
      price_3m_l: 0.79,
      price_6m_h: 1.29,
      price_6m_l: 0.79,
      price_52w_h: 1.75,
      price_52w_l: 0.79,
      price_pc_1d: -7.692,
      price_pc_1w: -8.696,
      price_pc_1m: -14.286,
      price_pc_6m: -29.412,
      price_pc_1y: -14.286,
      price_pc_ytd: -14.286,
    },
    1201: {
      id: "1201",
      c: 0.39,
      ma50: 0.413,
      ma100: 0.44,
      ma150: 0.472,
      ma200: 0.492,
      maw30: 0.468,
      rs: 44.0,
      rsd1d: 2.0,
      rsd5d: 12.0,
      rsd10d: 1.0,
      price_5d_h: 0.395,
      price_5d_L: 0.36,
      price_1m_h: 0.43,
      price_1m_l: 0.34,
      price_3m_h: 0.5,
      price_3m_l: 0.34,
      price_6m_h: 0.55,
      price_6m_l: 0.34,
      price_52w_h: 0.74,
      price_52w_l: 0.305,
      price_pc_1d: 1.299,
      price_pc_1w: 4.0,
      price_pc_1m: -4.878,
      price_pc_6m: -22.0,
      price_pc_1y: 2.632,
      price_pc_ytd: 2.632,
    },
    1203: {
      id: "1203",
      c: 0.65,
      ma50: 0.678,
      ma100: 0.677,
      ma150: 0.683,
      ma200: 0.687,
      maw30: 0.686,
      rs: 67.0,
      rsd1d: 4.0,
      rsd5d: 0.0,
      rsd10d: 3.0,
      price_5d_h: 0.67,
      price_5d_L: 0.64,
      price_1m_h: 0.69,
      price_1m_l: 0.6,
      price_3m_h: 0.72,
      price_3m_l: 0.6,
      price_6m_h: 0.78,
      price_6m_l: 0.6,
      price_52w_h: 0.8,
      price_52w_l: 0.6,
      price_pc_1d: 0.0,
      price_pc_1w: -2.985,
      price_pc_1m: -5.797,
      price_pc_6m: -7.143,
      price_pc_1y: -1.515,
      price_pc_ytd: -1.515,
    },
    1205: {
      id: "1205",
      c: 0.53,
      ma50: 0.524,
      ma100: 0.507,
      ma150: 0.527,
      ma200: 0.497,
      maw30: 0.528,
      rs: 91.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: 12.0,
      price_5d_h: 0.56,
      price_5d_L: 0.51,
      price_1m_h: 0.61,
      price_1m_l: 0.405,
      price_3m_h: 0.61,
      price_3m_l: 0.405,
      price_6m_h: 0.69,
      price_6m_l: 0.405,
      price_52w_h: 0.73,
      price_52w_l: 0.31,
      price_pc_1d: -3.636,
      price_pc_1w: -5.357,
      price_pc_1m: -1.852,
      price_pc_6m: -1.852,
      price_pc_1y: 60.606,
      price_pc_ytd: 60.606,
    },
    1206: {
      id: "1206",
      c: 0.6,
      ma50: 0.812,
      ma100: 0.814,
      ma150: 0.77,
      ma200: 0.764,
      maw30: 0.781,
      rs: 36.0,
      rsd1d: -1.0,
      rsd5d: 11.0,
      rsd10d: -8.0,
      price_5d_h: 0.63,
      price_5d_L: 0.55,
      price_1m_h: 0.87,
      price_1m_l: 0.53,
      price_3m_h: 0.97,
      price_3m_l: 0.53,
      price_6m_h: 1.55,
      price_6m_l: 0.53,
      price_52w_h: 1.55,
      price_52w_l: 0.53,
      price_pc_1d: -1.639,
      price_pc_1w: 1.695,
      price_pc_1m: -27.711,
      price_pc_6m: -10.448,
      price_pc_1y: -10.448,
      price_pc_ytd: -10.448,
    },
    1207: {
      id: "1207",
      c: 0.027,
      ma50: 0.032,
      ma100: 0.035,
      ma150: 0.038,
      ma200: 0.039,
      maw30: 0.038,
      rs: 18.0,
      rsd1d: -2.0,
      rsd5d: -2.0,
      rsd10d: 5.0,
      price_5d_h: 0.029,
      price_5d_L: 0.023,
      price_1m_h: 0.035,
      price_1m_l: 0.021,
      price_3m_h: 0.04,
      price_3m_l: 0.021,
      price_6m_h: 0.048,
      price_6m_l: 0.021,
      price_52w_h: 0.056,
      price_52w_l: 0.021,
      price_pc_1d: -3.571,
      price_pc_1w: -6.897,
      price_pc_1m: -22.857,
      price_pc_6m: -35.714,
      price_pc_1y: -32.5,
      price_pc_ytd: -32.5,
    },
    1208: {
      id: "1208",
      c: 3.16,
      ma50: 2.873,
      ma100: 2.805,
      ma150: 3.059,
      ma200: 3.186,
      maw30: 3.069,
      rs: 79.0,
      rsd1d: -3.0,
      rsd5d: 10.0,
      rsd10d: 29.0,
      price_5d_h: 3.36,
      price_5d_L: 2.97,
      price_1m_h: 3.41,
      price_1m_l: 2.12,
      price_3m_h: 3.41,
      price_3m_l: 2.12,
      price_6m_h: 4.07,
      price_6m_l: 2.12,
      price_52w_h: 6.1,
      price_52w_l: 2.12,
      price_pc_1d: -0.315,
      price_pc_1w: 5.686,
      price_pc_1m: 12.057,
      price_pc_6m: -3.951,
      price_pc_1y: -29.778,
      price_pc_ytd: -29.778,
    },
    1209: {
      id: "1209",
      c: 38.75,
      ma50: 40.373,
      ma100: 39.11,
      ma150: 39.688,
      ma200: 41.312,
      maw30: 39.998,
      rs: 59.0,
      rsd1d: 1.0,
      rsd5d: -1.0,
      rsd10d: -10.0,
      price_5d_h: 40.65,
      price_5d_L: 34.15,
      price_1m_h: 40.8,
      price_1m_l: 28.6,
      price_3m_h: 46.9,
      price_3m_l: 28.6,
      price_6m_h: 46.9,
      price_6m_l: 28.6,
      price_52w_h: 55.25,
      price_52w_l: 28.6,
      price_pc_1d: 0.911,
      price_pc_1w: 3.333,
      price_pc_1m: -3.607,
      price_pc_6m: -10.405,
      price_pc_1y: -14.365,
      price_pc_ytd: -14.365,
    },
    1211: {
      id: "1211",
      c: 224.4,
      ma50: 227.782,
      ma100: 254.777,
      ma150: 258.857,
      ma200: 253.484,
      maw30: 258.023,
      rs: 72.0,
      rsd1d: 2.0,
      rsd5d: 0.0,
      rsd10d: 14.0,
      price_5d_h: 231.0,
      price_5d_L: 203.6,
      price_1m_h: 248.2,
      price_1m_l: 165.0,
      price_3m_h: 279.6,
      price_3m_l: 165.0,
      price_6m_h: 324.6,
      price_6m_l: 165.0,
      price_52w_h: 324.6,
      price_52w_l: 138.4,
      price_pc_1d: 0.268,
      price_pc_1w: -0.355,
      price_pc_1m: -5.476,
      price_pc_6m: -7.654,
      price_pc_1y: 26.423,
      price_pc_ytd: 26.423,
    },
    1212: {
      id: "1212",
      c: 3.79,
      ma50: 4.253,
      ma100: 4.293,
      ma150: 4.396,
      ma200: 4.722,
      maw30: 4.373,
      rs: 31.0,
      rsd1d: -1.0,
      rsd5d: -13.0,
      rsd10d: -5.0,
      price_5d_h: 4.04,
      price_5d_L: 3.79,
      price_1m_h: 4.53,
      price_1m_l: 3.38,
      price_3m_h: 4.6,
      price_3m_l: 3.38,
      price_6m_h: 5.14,
      price_6m_l: 3.38,
      price_52w_h: 7.19,
      price_52w_l: 3.38,
      price_pc_1d: -2.32,
      price_pc_1w: -7.335,
      price_pc_1m: -16.52,
      price_pc_6m: -10.402,
      price_pc_1y: -44.428,
      price_pc_ytd: -44.428,
    },
    1213: {
      id: "1213",
      c: 0.375,
      ma50: 0.454,
      ma100: 0.464,
      ma150: 0.467,
      ma200: 0.461,
      maw30: 0.469,
      rs: 35.0,
      rsd1d: -21.0,
      rsd5d: -17.0,
      rsd10d: -26.0,
      price_5d_h: 0.43,
      price_5d_L: 0.33,
      price_1m_h: 0.475,
      price_1m_l: 0.33,
      price_3m_h: 0.475,
      price_3m_l: 0.33,
      price_6m_h: 0.5,
      price_6m_l: 0.33,
      price_52w_h: 0.77,
      price_52w_l: 0.33,
      price_pc_1d: -12.791,
      price_pc_1w: -12.791,
      price_pc_1m: -16.667,
      price_pc_6m: -17.582,
      price_pc_1y: -29.245,
      price_pc_ytd: -29.245,
    },
    1215: {
      id: "1215",
      c: 0.026,
      ma50: 0.027,
      ma100: 0.03,
      ma150: 0.033,
      ma200: 0.034,
      maw30: 0.033,
      rs: 24.0,
      rsd1d: 3.0,
      rsd5d: 2.0,
      rsd10d: -2.0,
      price_5d_h: 0.026,
      price_5d_L: 0.024,
      price_1m_h: 0.027,
      price_1m_l: 0.024,
      price_3m_h: 0.035,
      price_3m_l: 0.024,
      price_6m_h: 0.044,
      price_6m_l: 0.024,
      price_52w_h: 0.047,
      price_52w_l: 0.024,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: -3.704,
      price_pc_6m: -33.333,
      price_pc_1y: -36.585,
      price_pc_ytd: -36.585,
    },
    1216: {
      id: "1216",
      c: 0.83,
      ma50: 0.842,
      ma100: 0.889,
      ma150: 0.946,
      ma200: 0.985,
      maw30: 0.943,
      rs: 47.0,
      rsd1d: 3.0,
      rsd5d: 0.0,
      rsd10d: 6.0,
      price_5d_h: 0.83,
      price_5d_L: 0.76,
      price_1m_h: 0.85,
      price_1m_l: 0.72,
      price_3m_h: 0.91,
      price_3m_l: 0.72,
      price_6m_h: 1.1,
      price_6m_l: 0.72,
      price_52w_h: 1.15,
      price_52w_l: 0.72,
      price_pc_1d: 0.0,
      price_pc_1w: 1.22,
      price_pc_1m: 1.22,
      price_pc_6m: -23.148,
      price_pc_1y: -22.43,
      price_pc_ytd: -22.43,
    },
    1217: {
      id: "1217",
      c: 0.01,
      ma50: 0.01,
      ma100: 0.01,
      ma150: 0.01,
      ma200: 0.01,
      maw30: 0.01,
      rs: 74.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 1.0,
      price_5d_h: 0.01,
      price_5d_L: 0.01,
      price_1m_h: 0.01,
      price_1m_l: 0.01,
      price_3m_h: 0.01,
      price_3m_l: 0.01,
      price_6m_h: 0.01,
      price_6m_l: 0.01,
      price_52w_h: 0.015,
      price_52w_l: 0.01,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: 0.0,
      price_pc_6m: 0.0,
      price_pc_1y: 0.0,
      price_pc_ytd: 0.0,
    },
    1218: {
      id: "1218",
      c: 4.01,
      ma50: 4.2,
      ma100: 4.391,
      ma150: 4.224,
      ma200: 4.195,
      maw30: 4.233,
      rs: 60.0,
      rsd1d: -9.0,
      rsd5d: -8.0,
      rsd10d: -2.0,
      price_5d_h: 4.2,
      price_5d_L: 4.01,
      price_1m_h: 4.3,
      price_1m_l: 4.0,
      price_3m_h: 4.7,
      price_3m_l: 4.0,
      price_6m_h: 5.21,
      price_6m_l: 3.7,
      price_52w_h: 5.21,
      price_52w_l: 3.7,
      price_pc_1d: -4.524,
      price_pc_1w: -4.524,
      price_pc_1m: -4.976,
      price_pc_6m: 4.974,
      price_pc_1y: -0.988,
      price_pc_ytd: -0.988,
    },
    1220: {
      id: "1220",
      c: 0.081,
      ma50: 0.097,
      ma100: 0.097,
      ma150: 0.089,
      ma200: 0.088,
      maw30: 0.09,
      rs: 47.0,
      rsd1d: 8.0,
      rsd5d: -11.0,
      rsd10d: -13.0,
      price_5d_h: 0.081,
      price_5d_L: 0.07,
      price_1m_h: 0.107,
      price_1m_l: 0.06,
      price_3m_h: 0.136,
      price_3m_l: 0.06,
      price_6m_h: 0.136,
      price_6m_l: 0.06,
      price_52w_h: 0.245,
      price_52w_l: 0.06,
      price_pc_1d: 3.846,
      price_pc_1w: -2.41,
      price_pc_1m: -19.802,
      price_pc_6m: 12.5,
      price_pc_1y: -1.22,
      price_pc_ytd: -1.22,
    },
    1222: {
      id: "1222",
      c: 0.07,
      ma50: 0.074,
      ma100: 0.074,
      ma150: 0.072,
      ma200: 0.071,
      maw30: 0.072,
      rs: 74.0,
      rsd1d: -2.0,
      rsd5d: -1.0,
      rsd10d: -1.0,
      price_5d_h: 0.071,
      price_5d_L: 0.067,
      price_1m_h: 0.076,
      price_1m_l: 0.062,
      price_3m_h: 0.083,
      price_3m_l: 0.062,
      price_6m_h: 0.083,
      price_6m_l: 0.062,
      price_52w_h: 0.083,
      price_52w_l: 0.058,
      price_pc_1d: 0.0,
      price_pc_1w: 1.449,
      price_pc_1m: -5.405,
      price_pc_6m: 1.449,
      price_pc_1y: 6.061,
      price_pc_ytd: 6.061,
    },
    1223: {
      id: "1223",
      c: 1.05,
      ma50: 1.076,
      ma100: 1.022,
      ma150: 0.987,
      ma200: 0.962,
      maw30: 0.989,
      rs: 87.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 1.09,
      price_5d_L: 1.0,
      price_1m_h: 1.14,
      price_1m_l: 0.96,
      price_3m_h: 1.29,
      price_3m_l: 0.96,
      price_6m_h: 1.29,
      price_6m_l: 0.86,
      price_52w_h: 1.29,
      price_52w_l: 0.83,
      price_pc_1d: 0.0,
      price_pc_1w: 1.942,
      price_pc_1m: -1.869,
      price_pc_6m: 15.385,
      price_pc_1y: 23.529,
      price_pc_ytd: 23.529,
    },
    1224: {
      id: "1224",
      c: 1.89,
      ma50: 1.84,
      ma100: 1.805,
      ma150: 1.796,
      ma200: 1.82,
      maw30: 1.793,
      rs: 82.0,
      rsd1d: -2.0,
      rsd5d: -1.0,
      rsd10d: -2.0,
      price_5d_h: 1.95,
      price_5d_L: 1.86,
      price_1m_h: 1.95,
      price_1m_l: 1.72,
      price_3m_h: 1.99,
      price_3m_l: 1.7,
      price_6m_h: 1.99,
      price_6m_l: 1.63,
      price_52w_h: 2.0,
      price_52w_l: 1.63,
      price_pc_1d: -0.526,
      price_pc_1w: 1.07,
      price_pc_1m: 3.846,
      price_pc_6m: 6.78,
      price_pc_1y: 3.846,
      price_pc_ytd: 3.846,
    },
    1225: {
      id: "1225",
      c: 0.28,
      ma50: 0.257,
      ma100: 0.282,
      ma150: 0.317,
      ma200: 0.374,
      maw30: 0.316,
      rs: 82.0,
      rsd1d: -2.0,
      rsd5d: 2.0,
      rsd10d: 35.0,
      price_5d_h: 0.285,
      price_5d_L: 0.26,
      price_1m_h: 0.325,
      price_1m_l: 0.216,
      price_3m_h: 0.325,
      price_3m_l: 0.216,
      price_6m_h: 0.4,
      price_6m_l: 0.035,
      price_52w_h: 2.17,
      price_52w_l: 0.035,
      price_pc_1d: -1.754,
      price_pc_1w: 0.0,
      price_pc_1m: 7.692,
      price_pc_6m: -26.316,
      price_pc_1y: 120.472,
      price_pc_ytd: 120.472,
    },
    1226: {
      id: "1226",
      c: 0.48,
      ma50: 0.579,
      ma100: 0.746,
      ma150: 0.743,
      ma200: 0.67,
      maw30: 0.759,
      rs: 27.0,
      rsd1d: 2.0,
      rsd5d: -5.0,
      rsd10d: -32.0,
      price_5d_h: 0.52,
      price_5d_L: 0.48,
      price_1m_h: 0.56,
      price_1m_l: 0.48,
      price_3m_h: 0.88,
      price_3m_l: 0.48,
      price_6m_h: 1.11,
      price_6m_l: 0.48,
      price_52w_h: 1.11,
      price_52w_l: 0.35,
      price_pc_1d: 0.0,
      price_pc_1w: -7.692,
      price_pc_1m: -14.286,
      price_pc_6m: -27.273,
      price_pc_1y: 7.865,
      price_pc_ytd: 7.865,
    },
    1230: {
      id: "1230",
      c: 0.97,
      ma50: 0.627,
      ma100: 0.582,
      ma150: 0.572,
      ma200: 0.589,
      maw30: 0.559,
      rs: 96.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 1.0,
      price_5d_h: 1.0,
      price_5d_L: 0.96,
      price_1m_h: 1.0,
      price_1m_l: 0.425,
      price_3m_h: 1.0,
      price_3m_l: 0.425,
      price_6m_h: 1.0,
      price_6m_l: 0.425,
      price_52w_h: 1.0,
      price_52w_l: 0.425,
      price_pc_1d: -1.02,
      price_pc_1w: -1.02,
      price_pc_1m: 97.959,
      price_pc_6m: 76.364,
      price_pc_1y: 42.647,
      price_pc_ytd: 42.647,
    },
    1232: {
      id: "1232",
      c: 0.174,
      ma50: 0.153,
      ma100: 0.17,
      ma150: 0.199,
      ma200: 0.261,
      maw30: 0.196,
      rs: 20.0,
      rsd1d: 2.0,
      rsd5d: 5.0,
      rsd10d: 12.0,
      price_5d_h: 0.175,
      price_5d_L: 0.174,
      price_1m_h: 0.175,
      price_1m_l: 0.109,
      price_3m_h: 0.186,
      price_3m_l: 0.109,
      price_6m_h: 0.25,
      price_6m_l: 0.109,
      price_52w_h: 0.62,
      price_52w_l: 0.109,
      price_pc_1d: -0.571,
      price_pc_1w: -0.571,
      price_pc_1m: 30.827,
      price_pc_6m: -15.534,
      price_pc_1y: -68.929,
      price_pc_ytd: -68.929,
    },
    1233: {
      id: "1233",
      c: 2.61,
      ma50: 2.903,
      ma100: 3.585,
      ma150: 4.5,
      ma200: 5.469,
      maw30: 4.452,
      rs: 7.0,
      rsd1d: 0.0,
      rsd5d: 3.0,
      rsd10d: 0.0,
      price_5d_h: 3.01,
      price_5d_L: 2.3,
      price_1m_h: 3.01,
      price_1m_l: 1.66,
      price_3m_h: 4.53,
      price_3m_l: 1.66,
      price_6m_h: 6.77,
      price_6m_l: 1.66,
      price_52w_h: 11.7,
      price_52w_l: 1.66,
      price_pc_1d: -6.452,
      price_pc_1w: 0.772,
      price_pc_1m: 3.162,
      price_pc_6m: -59.66,
      price_pc_1y: -75.607,
      price_pc_ytd: -75.607,
    },
    1234: {
      id: "1234",
      c: 3.8,
      ma50: 4.253,
      ma100: 4.289,
      ma150: 4.413,
      ma200: 4.589,
      maw30: 4.424,
      rs: 39.0,
      rsd1d: 2.0,
      rsd5d: 2.0,
      rsd10d: -14.0,
      price_5d_h: 3.88,
      price_5d_L: 3.63,
      price_1m_h: 4.41,
      price_1m_l: 3.63,
      price_3m_h: 4.65,
      price_3m_l: 3.63,
      price_6m_h: 4.78,
      price_6m_l: 3.63,
      price_52w_h: 6.75,
      price_52w_l: 3.63,
      price_pc_1d: -1.299,
      price_pc_1w: -1.299,
      price_pc_1m: -12.24,
      price_pc_6m: -16.115,
      price_pc_1y: -24.752,
      price_pc_ytd: -24.752,
    },
    1238: {
      id: "1238",
      c: 2.07,
      ma50: 3.67,
      ma100: 4.064,
      ma150: 4.584,
      ma200: 5.007,
      maw30: 4.606,
      rs: 3.0,
      rsd1d: -1.0,
      rsd5d: -2.0,
      rsd10d: -13.0,
      price_5d_h: 2.46,
      price_5d_L: 1.95,
      price_1m_h: 3.97,
      price_1m_l: 1.95,
      price_3m_h: 4.73,
      price_3m_l: 1.95,
      price_6m_h: 6.24,
      price_6m_l: 1.95,
      price_52w_h: 8.73,
      price_52w_l: 1.95,
      price_pc_1d: -6.757,
      price_pc_1w: -15.51,
      price_pc_1m: -44.504,
      price_pc_6m: -64.615,
      price_pc_1y: -72.799,
      price_pc_ytd: -72.799,
    },
    1239: {
      id: "1239",
      c: 0.095,
      ma50: 0.094,
      ma100: 0.145,
      ma150: 0.183,
      ma200: 0.206,
      maw30: 0.18,
      rs: 5.0,
      rsd1d: 1.0,
      rsd5d: 2.0,
      rsd10d: 2.0,
      price_5d_h: 0.096,
      price_5d_L: 0.081,
      price_1m_h: 0.109,
      price_1m_l: 0.071,
      price_3m_h: 0.178,
      price_3m_l: 0.048,
      price_6m_h: 0.29,
      price_6m_l: 0.048,
      price_52w_h: 0.33,
      price_52w_l: 0.048,
      price_pc_1d: 0.0,
      price_pc_1w: 10.465,
      price_pc_1m: -3.061,
      price_pc_6m: -61.224,
      price_pc_1y: -70.769,
      price_pc_ytd: -70.769,
    },
    1240: {
      id: "1240",
      c: 0.72,
      ma50: 0.675,
      ma100: 0.679,
      ma150: 0.692,
      ma200: 0.717,
      maw30: 0.689,
      rs: 73.0,
      rsd1d: 3.0,
      rsd5d: 10.0,
      rsd10d: 12.0,
      price_5d_h: 0.72,
      price_5d_L: 0.66,
      price_1m_h: 0.73,
      price_1m_l: 0.62,
      price_3m_h: 0.73,
      price_3m_l: 0.62,
      price_6m_h: 0.75,
      price_6m_l: 0.61,
      price_52w_h: 1.09,
      price_52w_l: 0.61,
      price_pc_1d: 2.857,
      price_pc_1w: 4.348,
      price_pc_1m: 5.882,
      price_pc_6m: 2.857,
      price_pc_1y: -7.692,
      price_pc_ytd: -7.692,
    },
    1243: {
      id: "1243",
      c: 0.07,
      ma50: 0.076,
      ma100: 0.079,
      ma150: 0.084,
      ma200: 0.088,
      maw30: 0.084,
      rs: 31.0,
      rsd1d: 3.0,
      rsd5d: 0.0,
      rsd10d: -14.0,
      price_5d_h: 0.072,
      price_5d_L: 0.066,
      price_1m_h: 0.08,
      price_1m_l: 0.065,
      price_3m_h: 0.084,
      price_3m_l: 0.065,
      price_6m_h: 0.095,
      price_6m_l: 0.065,
      price_52w_h: 0.108,
      price_52w_l: 0.065,
      price_pc_1d: 0.0,
      price_pc_1w: -2.778,
      price_pc_1m: -7.895,
      price_pc_6m: -24.731,
      price_pc_1y: -30.0,
      price_pc_ytd: -30.0,
    },
    1245: {
      id: "1245",
      c: 0.28,
      ma50: 0.249,
      ma100: 0.262,
      ma150: 0.288,
      ma200: 0.284,
      maw30: 0.287,
      rs: 71.0,
      rsd1d: -4.0,
      rsd5d: 24.0,
      rsd10d: 38.0,
      price_5d_h: 0.3,
      price_5d_L: 0.235,
      price_1m_h: 0.3,
      price_1m_l: 0.195,
      price_3m_h: 0.3,
      price_3m_l: 0.195,
      price_6m_h: 0.38,
      price_6m_l: 0.195,
      price_52w_h: 0.55,
      price_52w_l: 0.195,
      price_pc_1d: 0.0,
      price_pc_1w: 14.286,
      price_pc_1m: 14.286,
      price_pc_6m: -21.127,
      price_pc_1y: 7.692,
      price_pc_ytd: 7.692,
    },
    1247: {
      id: "1247",
      c: 0.36,
      ma50: 0.384,
      ma100: 0.432,
      ma150: 0.464,
      ma200: 0.483,
      maw30: 0.463,
      rs: 21.0,
      rsd1d: 0.0,
      rsd5d: 2.0,
      rsd10d: 10.0,
      price_5d_h: 0.36,
      price_5d_L: 0.35,
      price_1m_h: 0.38,
      price_1m_l: 0.305,
      price_3m_h: 0.47,
      price_3m_l: 0.305,
      price_6m_h: 0.6,
      price_6m_l: 0.305,
      price_52w_h: 0.85,
      price_52w_l: 0.305,
      price_pc_1d: 2.857,
      price_pc_1w: 2.857,
      price_pc_1m: -5.263,
      price_pc_6m: -30.769,
      price_pc_1y: -36.842,
      price_pc_ytd: -36.842,
    },
    1250: {
      id: "1250",
      c: 0.075,
      ma50: 0.097,
      ma100: 0.096,
      ma150: 0.098,
      ma200: 0.098,
      maw30: 0.098,
      rs: 24.0,
      rsd1d: -7.0,
      rsd5d: -17.0,
      rsd10d: -9.0,
      price_5d_h: 0.093,
      price_5d_L: 0.072,
      price_1m_h: 0.107,
      price_1m_l: 0.07,
      price_3m_h: 0.113,
      price_3m_l: 0.07,
      price_6m_h: 0.119,
      price_6m_l: 0.07,
      price_52w_h: 0.127,
      price_52w_l: 0.07,
      price_pc_1d: -8.537,
      price_pc_1w: -12.791,
      price_pc_1m: -27.184,
      price_pc_6m: -24.242,
      price_pc_1y: -38.525,
      price_pc_ytd: -38.525,
    },
    1251: {
      id: "1251",
      c: 0.275,
      ma50: 0.285,
      ma100: 0.274,
      ma150: 0.286,
      ma200: 0.281,
      maw30: 0.287,
      rs: 64.0,
      rsd1d: 11.0,
      rsd5d: 4.0,
      rsd10d: 14.0,
      price_5d_h: 0.295,
      price_5d_L: 0.255,
      price_1m_h: 0.31,
      price_1m_l: 0.225,
      price_3m_h: 0.335,
      price_3m_l: 0.225,
      price_6m_h: 0.45,
      price_6m_l: 0.225,
      price_52w_h: 0.45,
      price_52w_l: 0.202,
      price_pc_1d: 3.774,
      price_pc_1w: 1.852,
      price_pc_1m: -5.172,
      price_pc_6m: -17.91,
      price_pc_1y: -19.118,
      price_pc_ytd: -19.118,
    },
    1252: {
      id: "1252",
      c: 6.8,
      ma50: 6.514,
      ma100: 6.338,
      ma150: 6.315,
      ma200: 6.238,
      maw30: 6.343,
      rs: 86.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: 2.0,
      price_5d_h: 6.85,
      price_5d_L: 6.53,
      price_1m_h: 6.86,
      price_1m_l: 5.49,
      price_3m_h: 7.05,
      price_3m_l: 5.49,
      price_6m_h: 7.25,
      price_6m_l: 5.26,
      price_52w_h: 7.25,
      price_52w_l: 5.26,
      price_pc_1d: 0.295,
      price_pc_1w: 0.89,
      price_pc_1m: 4.615,
      price_pc_6m: 14.865,
      price_pc_1y: 14.286,
      price_pc_ytd: 14.286,
    },
    1253: {
      id: "1253",
      c: 0.25,
      ma50: 0.176,
      ma100: 0.198,
      ma150: 0.221,
      ma200: 0.239,
      maw30: 0.218,
      rs: 81.0,
      rsd1d: 16.0,
      rsd5d: 68.0,
      rsd10d: 67.0,
      price_5d_h: 0.3,
      price_5d_L: 0.163,
      price_1m_h: 0.3,
      price_1m_l: 0.163,
      price_3m_h: 0.3,
      price_3m_l: 0.163,
      price_6m_h: 0.39,
      price_6m_l: 0.163,
      price_52w_h: 0.39,
      price_52w_l: 0.163,
      price_pc_1d: 4.167,
      price_pc_1w: 53.374,
      price_pc_1m: 51.515,
      price_pc_6m: -7.407,
      price_pc_1y: -29.577,
      price_pc_ytd: -29.577,
    },
    1255: {
      id: "1255",
      c: 1.24,
      ma50: 1.259,
      ma100: 1.334,
      ma150: 1.884,
      ma200: 3.101,
      maw30: 1.724,
      rs: 8.0,
      rsd1d: -1.0,
      rsd5d: 3.0,
      rsd10d: 1.0,
      price_5d_h: 1.3,
      price_5d_L: 0.92,
      price_1m_h: 1.3,
      price_1m_l: 0.92,
      price_3m_h: 1.78,
      price_3m_l: 0.92,
      price_6m_h: 2.3,
      price_6m_l: 0.92,
      price_52w_h: 8.5,
      price_52w_l: 0.92,
      price_pc_1d: 0.813,
      price_pc_1w: 21.569,
      price_pc_1m: 7.826,
      price_pc_6m: -40.385,
      price_pc_1y: -81.297,
      price_pc_ytd: -81.297,
    },
    1257: {
      id: "1257",
      c: 2.27,
      ma50: 2.488,
      ma100: 2.623,
      ma150: 2.723,
      ma200: 2.742,
      maw30: 2.743,
      rs: 29.0,
      rsd1d: 5.0,
      rsd5d: 7.0,
      rsd10d: 1.0,
      price_5d_h: 2.43,
      price_5d_L: 2.03,
      price_1m_h: 2.63,
      price_1m_l: 1.87,
      price_3m_h: 3.03,
      price_3m_l: 1.87,
      price_6m_h: 3.5,
      price_6m_l: 1.87,
      price_52w_h: 3.5,
      price_52w_l: 1.87,
      price_pc_1d: 3.653,
      price_pc_1w: 5.581,
      price_pc_1m: -12.016,
      price_pc_6m: -25.574,
      price_pc_1y: -28.391,
      price_pc_ytd: -28.391,
    },
    1258: {
      id: "1258",
      c: 3.94,
      ma50: 3.596,
      ma100: 3.287,
      ma150: 3.453,
      ma200: 3.555,
      maw30: 3.456,
      rs: 92.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: 2.0,
      price_5d_h: 4.09,
      price_5d_L: 3.63,
      price_1m_h: 4.5,
      price_1m_l: 2.75,
      price_3m_h: 4.5,
      price_3m_l: 2.75,
      price_6m_h: 4.5,
      price_6m_l: 2.58,
      price_52w_h: 5.41,
      price_52w_l: 2.26,
      price_pc_1d: -1.005,
      price_pc_1w: 2.338,
      price_pc_1m: 4.787,
      price_pc_6m: 4.233,
      price_pc_1y: 75.111,
      price_pc_ytd: 75.111,
    },
    1259: {
      id: "1259",
      c: 0.069,
      ma50: 0.074,
      ma100: 0.066,
      ma150: 0.069,
      ma200: 0.071,
      maw30: 0.068,
      rs: 72.0,
      rsd1d: -7.0,
      rsd5d: -8.0,
      rsd10d: -13.0,
      price_5d_h: 0.074,
      price_5d_L: 0.06,
      price_1m_h: 0.084,
      price_1m_l: 0.056,
      price_3m_h: 0.088,
      price_3m_l: 0.044,
      price_6m_h: 0.092,
      price_6m_l: 0.044,
      price_52w_h: 0.11,
      price_52w_l: 0.044,
      price_pc_1d: 0.0,
      price_pc_1w: 4.545,
      price_pc_1m: -11.538,
      price_pc_6m: -6.757,
      price_pc_1y: -15.854,
      price_pc_ytd: -15.854,
    },
    1262: {
      id: "1262",
      c: 0.35,
      ma50: 0.467,
      ma100: 0.481,
      ma150: 0.464,
      ma200: 0.424,
      maw30: 0.471,
      rs: 62.0,
      rsd1d: -15.0,
      rsd5d: -19.0,
      rsd10d: -29.0,
      price_5d_h: 0.4,
      price_5d_L: 0.32,
      price_1m_h: 0.51,
      price_1m_l: 0.32,
      price_3m_h: 0.53,
      price_3m_l: 0.32,
      price_6m_h: 0.57,
      price_6m_l: 0.32,
      price_52w_h: 0.57,
      price_52w_l: 0.242,
      price_pc_1d: -7.895,
      price_pc_1w: -10.256,
      price_pc_1m: -27.083,
      price_pc_6m: -23.913,
      price_pc_1y: 34.615,
      price_pc_ytd: 34.615,
    },
    1263: {
      id: "1263",
      c: 11.54,
      ma50: 10.522,
      ma100: 12.252,
      ma150: 11.04,
      ma200: 9.504,
      maw30: 11.128,
      rs: 96.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 3.0,
      price_5d_h: 12.58,
      price_5d_L: 10.8,
      price_1m_h: 12.58,
      price_1m_l: 7.35,
      price_3m_h: 14.9,
      price_3m_l: 7.35,
      price_6m_h: 17.96,
      price_6m_l: 7.35,
      price_52w_h: 17.96,
      price_52w_l: 3.41,
      price_pc_1d: 2.305,
      price_pc_1w: -2.203,
      price_pc_1m: 20.083,
      price_pc_6m: 54.278,
      price_pc_1y: 197.423,
      price_pc_ytd: 197.423,
    },
    1265: {
      id: "1265",
      c: 0.415,
      ma50: 0.453,
      ma100: 0.442,
      ma150: 0.438,
      ma200: 0.446,
      maw30: 0.44,
      rs: 51.0,
      rsd1d: -3.0,
      rsd5d: -2.0,
      rsd10d: 3.0,
      price_5d_h: 0.415,
      price_5d_L: 0.395,
      price_1m_h: 0.47,
      price_1m_l: 0.36,
      price_3m_h: 0.56,
      price_3m_l: 0.36,
      price_6m_h: 0.56,
      price_6m_l: 0.36,
      price_52w_h: 0.67,
      price_52w_l: 0.36,
      price_pc_1d: 0.0,
      price_pc_1w: 1.22,
      price_pc_1m: -9.783,
      price_pc_6m: -5.682,
      price_pc_1y: -13.542,
      price_pc_ytd: -13.542,
    },
    1266: {
      id: "1266",
      c: 0.32,
      ma50: 0.372,
      ma100: 0.393,
      ma150: 0.441,
      ma200: 0.42,
      maw30: 0.443,
      rs: 41.0,
      rsd1d: -10.0,
      rsd5d: -24.0,
      rsd10d: 3.0,
      price_5d_h: 0.385,
      price_5d_L: 0.315,
      price_1m_h: 0.395,
      price_1m_l: 0.28,
      price_3m_h: 0.435,
      price_3m_l: 0.28,
      price_6m_h: 0.71,
      price_6m_l: 0.28,
      price_52w_h: 0.88,
      price_52w_l: 0.28,
      price_pc_1d: -8.571,
      price_pc_1w: -16.883,
      price_pc_1m: -12.329,
      price_pc_6m: -40.741,
      price_pc_1y: -7.246,
      price_pc_ytd: -7.246,
    },
    1268: {
      id: "1268",
      c: 30.0,
      ma50: 34.165,
      ma100: 36.142,
      ma150: 37.543,
      ma200: 38.668,
      maw30: 37.673,
      rs: 30.0,
      rsd1d: 2.0,
      rsd5d: 4.0,
      rsd10d: 5.0,
      price_5d_h: 31.45,
      price_5d_L: 27.85,
      price_1m_h: 37.35,
      price_1m_l: 24.7,
      price_3m_h: 41.5,
      price_3m_l: 24.7,
      price_6m_h: 45.0,
      price_6m_l: 24.7,
      price_52w_h: 48.4,
      price_52w_l: 24.7,
      price_pc_1d: -0.662,
      price_pc_1w: 2.564,
      price_pc_1m: -18.145,
      price_pc_6m: -23.274,
      price_pc_1y: -18.919,
      price_pc_ytd: -18.919,
    },
    1269: {
      id: "1269",
      c: 0.285,
      ma50: 0.253,
      ma100: 0.239,
      ma150: 0.244,
      ma200: 0.25,
      maw30: 0.243,
      rs: 84.0,
      rsd1d: -1.0,
      rsd5d: -2.0,
      rsd10d: 0.0,
      price_5d_h: 0.295,
      price_5d_L: 0.27,
      price_1m_h: 0.295,
      price_1m_l: 0.24,
      price_3m_h: 0.33,
      price_3m_l: 0.19,
      price_6m_h: 0.33,
      price_6m_l: 0.19,
      price_52w_h: 0.39,
      price_52w_l: 0.19,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: 11.765,
      price_pc_6m: 14.0,
      price_pc_1y: -28.75,
      price_pc_ytd: -28.75,
    },
    1270: {
      id: "1270",
      c: 0.91,
      ma50: 0.868,
      ma100: 0.865,
      ma150: 0.87,
      ma200: 0.889,
      maw30: 0.868,
      rs: 71.0,
      rsd1d: -6.0,
      rsd5d: -11.0,
      rsd10d: 8.0,
      price_5d_h: 0.95,
      price_5d_L: 0.91,
      price_1m_h: 0.97,
      price_1m_l: 0.79,
      price_3m_h: 0.97,
      price_3m_l: 0.79,
      price_6m_h: 0.97,
      price_6m_l: 0.79,
      price_52w_h: 1.21,
      price_52w_l: 0.79,
      price_pc_1d: -2.151,
      price_pc_1w: -4.211,
      price_pc_1m: 4.598,
      price_pc_6m: 5.814,
      price_pc_1y: -23.529,
      price_pc_ytd: -23.529,
    },
    1271: {
      id: "1271",
      c: 7.52,
      ma50: 7.799,
      ma100: 7.867,
      ma150: 7.854,
      ma200: 7.851,
      maw30: 7.866,
      rs: 72.0,
      rsd1d: 2.0,
      rsd5d: 2.0,
      rsd10d: 2.0,
      price_5d_h: 7.65,
      price_5d_L: 7.45,
      price_1m_h: 7.9,
      price_1m_l: 7.4,
      price_3m_h: 8.2,
      price_3m_l: 7.4,
      price_6m_h: 8.3,
      price_6m_l: 7.4,
      price_52w_h: 8.85,
      price_52w_l: 6.7,
      price_pc_1d: 0.0,
      price_pc_1w: -0.397,
      price_pc_1m: -4.569,
      price_pc_6m: -3.959,
      price_pc_1y: 8.514,
      price_pc_ytd: 8.514,
    },
    1272: {
      id: "1272",
      c: 0.9,
      ma50: 0.906,
      ma100: 0.963,
      ma150: 0.981,
      ma200: 0.983,
      maw30: 0.99,
      rs: 52.0,
      rsd1d: -4.0,
      rsd5d: 3.0,
      rsd10d: 4.0,
      price_5d_h: 0.9,
      price_5d_L: 0.84,
      price_1m_h: 0.9,
      price_1m_l: 0.75,
      price_3m_h: 1.09,
      price_3m_l: 0.75,
      price_6m_h: 1.15,
      price_6m_l: 0.75,
      price_52w_h: 1.28,
      price_52w_l: 0.75,
      price_pc_1d: 1.124,
      price_pc_1w: 4.651,
      price_pc_1m: 1.124,
      price_pc_6m: -10.0,
      price_pc_1y: -6.25,
      price_pc_ytd: -6.25,
    },
    1273: {
      id: "1273",
      c: 0.38,
      ma50: 0.408,
      ma100: 0.424,
      ma150: 0.445,
      ma200: 0.462,
      maw30: 0.447,
      rs: 42.0,
      rsd1d: 3.0,
      rsd5d: -2.0,
      rsd10d: -9.0,
      price_5d_h: 0.395,
      price_5d_L: 0.365,
      price_1m_h: 0.405,
      price_1m_l: 0.355,
      price_3m_h: 0.435,
      price_3m_l: 0.355,
      price_6m_h: 0.49,
      price_6m_l: 0.355,
      price_52w_h: 0.57,
      price_52w_l: 0.355,
      price_pc_1d: 0.0,
      price_pc_1w: -2.564,
      price_pc_1m: -2.564,
      price_pc_6m: -22.449,
      price_pc_1y: -15.556,
      price_pc_ytd: -15.556,
    },
    1277: {
      id: "1277",
      c: 0.6,
      ma50: 0.581,
      ma100: 0.568,
      ma150: 0.617,
      ma200: 0.614,
      maw30: 0.613,
      rs: 83.0,
      rsd1d: -1.0,
      rsd5d: 1.0,
      rsd10d: 8.0,
      price_5d_h: 0.63,
      price_5d_L: 0.56,
      price_1m_h: 0.63,
      price_1m_l: 0.495,
      price_3m_h: 0.65,
      price_3m_l: 0.48,
      price_6m_h: 0.77,
      price_6m_l: 0.475,
      price_52w_h: 0.86,
      price_52w_l: 0.475,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: 3.448,
      price_pc_6m: -16.667,
      price_pc_1y: 20.0,
      price_pc_ytd: 20.0,
    },
    1278: {
      id: "1278",
      c: 0.087,
      ma50: 0.089,
      ma100: 0.086,
      ma150: 0.089,
      ma200: 0.093,
      maw30: 0.088,
      rs: 51.0,
      rsd1d: 10.0,
      rsd5d: 14.0,
      rsd10d: 9.0,
      price_5d_h: 0.092,
      price_5d_L: 0.081,
      price_1m_h: 0.096,
      price_1m_l: 0.076,
      price_3m_h: 0.1,
      price_3m_l: 0.076,
      price_6m_h: 0.107,
      price_6m_l: 0.061,
      price_52w_h: 0.179,
      price_52w_l: 0.061,
      price_pc_1d: 3.571,
      price_pc_1w: 4.819,
      price_pc_1m: -9.375,
      price_pc_6m: -7.447,
      price_pc_1y: -43.137,
      price_pc_ytd: -43.137,
    },
    1280: {
      id: "1280",
      c: 0.63,
      ma50: 0.596,
      ma100: 0.696,
      ma150: 0.827,
      ma200: 0.884,
      maw30: 0.815,
      rs: 23.0,
      rsd1d: 8.0,
      rsd5d: 12.0,
      rsd10d: 9.0,
      price_5d_h: 0.65,
      price_5d_L: 0.5,
      price_1m_h: 0.73,
      price_1m_l: 0.5,
      price_3m_h: 0.8,
      price_3m_l: 0.495,
      price_6m_h: 1.23,
      price_6m_l: 0.495,
      price_52w_h: 3.51,
      price_52w_l: 0.495,
      price_pc_1d: 12.5,
      price_pc_1w: 23.529,
      price_pc_1m: 26.0,
      price_pc_6m: -43.243,
      price_pc_1y: -41.667,
      price_pc_ytd: -41.667,
    },
    1281: {
      id: "1281",
      c: 0.135,
      ma50: 0.137,
      ma100: 0.143,
      ma150: 0.154,
      ma200: 0.161,
      maw30: 0.154,
      rs: 35.0,
      rsd1d: -2.0,
      rsd5d: -3.0,
      rsd10d: -15.0,
      price_5d_h: 0.14,
      price_5d_L: 0.134,
      price_1m_h: 0.185,
      price_1m_l: 0.12,
      price_3m_h: 0.185,
      price_3m_l: 0.113,
      price_6m_h: 0.189,
      price_6m_l: 0.113,
      price_52w_h: 0.225,
      price_52w_l: 0.113,
      price_pc_1d: -3.571,
      price_pc_1w: -0.735,
      price_pc_1m: -2.878,
      price_pc_6m: -28.571,
      price_pc_1y: -36.62,
      price_pc_ytd: -36.62,
    },
    1282: {
      id: "1282",
      c: 0.125,
      ma50: 0.169,
      ma100: 0.193,
      ma150: 0.216,
      ma200: 0.22,
      maw30: 0.216,
      rs: 11.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: -2.0,
      price_5d_h: 0.138,
      price_5d_L: 0.113,
      price_1m_h: 0.182,
      price_1m_l: 0.113,
      price_3m_h: 0.23,
      price_3m_l: 0.113,
      price_6m_h: 0.28,
      price_6m_l: 0.113,
      price_52w_h: 0.36,
      price_52w_l: 0.113,
      price_pc_1d: 0.0,
      price_pc_1w: -6.015,
      price_pc_1m: -28.161,
      price_pc_6m: -49.799,
      price_pc_1y: -52.83,
      price_pc_ytd: -52.83,
    },
    1283: {
      id: "1283",
      c: 1.52,
      ma50: 1.578,
      ma100: 1.573,
      ma150: 1.577,
      ma200: 1.562,
      maw30: 1.58,
      rs: 70.0,
      rsd1d: 2.0,
      rsd5d: 5.0,
      rsd10d: 7.0,
      price_5d_h: 1.54,
      price_5d_L: 1.46,
      price_1m_h: 1.7,
      price_1m_l: 1.35,
      price_3m_h: 1.73,
      price_3m_l: 1.35,
      price_6m_h: 1.74,
      price_6m_l: 1.35,
      price_52w_h: 1.92,
      price_52w_l: 1.35,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: -4.403,
      price_pc_6m: -0.654,
      price_pc_1y: -7.879,
      price_pc_ytd: -7.879,
    },
    1285: {
      id: "1285",
      c: 1.33,
      ma50: 1.511,
      ma100: 1.451,
      ma150: 1.504,
      ma200: 1.555,
      maw30: 1.509,
      rs: 44.0,
      rsd1d: -16.0,
      rsd5d: -16.0,
      rsd10d: -13.0,
      price_5d_h: 1.5,
      price_5d_L: 1.31,
      price_1m_h: 1.59,
      price_1m_l: 1.3,
      price_3m_h: 1.63,
      price_3m_l: 1.3,
      price_6m_h: 1.68,
      price_6m_l: 1.27,
      price_52w_h: 1.88,
      price_52w_l: 1.27,
      price_pc_1d: -8.904,
      price_pc_1w: -8.276,
      price_pc_1m: -14.194,
      price_pc_6m: -18.405,
      price_pc_1y: -18.902,
      price_pc_ytd: -18.902,
    },
    1286: {
      id: "1286",
      c: 1.98,
      ma50: 1.869,
      ma100: 1.91,
      ma150: 2.076,
      ma200: 2.129,
      maw30: 2.057,
      rs: 64.0,
      rsd1d: 3.0,
      rsd5d: 21.0,
      rsd10d: 20.0,
      price_5d_h: 2.0,
      price_5d_L: 1.82,
      price_1m_h: 2.0,
      price_1m_l: 1.68,
      price_3m_h: 2.03,
      price_3m_l: 1.68,
      price_6m_h: 2.57,
      price_6m_l: 1.68,
      price_52w_h: 2.73,
      price_52w_l: 1.68,
      price_pc_1d: -0.503,
      price_pc_1w: 5.319,
      price_pc_1m: 8.197,
      price_pc_6m: -12.389,
      price_pc_1y: -13.158,
      price_pc_ytd: -13.158,
    },
    1288: {
      id: "1288",
      c: 3.01,
      ma50: 2.947,
      ma100: 2.818,
      ma150: 2.767,
      ma200: 2.737,
      maw30: 2.776,
      rs: 84.0,
      rsd1d: 0.0,
      rsd5d: 2.0,
      rsd10d: 0.0,
      price_5d_h: 3.04,
      price_5d_L: 2.88,
      price_1m_h: 3.04,
      price_1m_l: 2.66,
      price_3m_h: 3.15,
      price_3m_l: 2.66,
      price_6m_h: 3.15,
      price_6m_l: 2.57,
      price_52w_h: 3.18,
      price_52w_l: 2.54,
      price_pc_1d: 0.669,
      price_pc_1w: 3.793,
      price_pc_1m: 1.689,
      price_pc_6m: 12.313,
      price_pc_1y: -3.526,
      price_pc_ytd: -3.526,
    },
    1290: {
      id: "1290",
      c: 1.05,
      ma50: 1.062,
      ma100: 1.061,
      ma150: 1.103,
      ma200: 1.11,
      maw30: 1.106,
      rs: 69.0,
      rsd1d: -2.0,
      rsd5d: -6.0,
      rsd10d: -7.0,
      price_5d_h: 1.07,
      price_5d_L: 1.05,
      price_1m_h: 1.1,
      price_1m_l: 1.01,
      price_3m_h: 1.1,
      price_3m_l: 0.96,
      price_6m_h: 1.32,
      price_6m_l: 0.9,
      price_52w_h: 1.32,
      price_52w_l: 0.9,
      price_pc_1d: -0.943,
      price_pc_1w: -1.869,
      price_pc_1m: -3.67,
      price_pc_6m: -13.223,
      price_pc_1y: 0.0,
      price_pc_ytd: 0.0,
    },
    1292: {
      id: "1292",
      c: 2.38,
      ma50: 2.281,
      ma100: 2.052,
      ma150: 2.061,
      ma200: 2.215,
      maw30: 2.044,
      rs: 85.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: 1.0,
      price_5d_h: 2.39,
      price_5d_L: 2.36,
      price_1m_h: 2.45,
      price_1m_l: 1.81,
      price_3m_h: 2.45,
      price_3m_l: 1.75,
      price_6m_h: 2.45,
      price_6m_l: 1.71,
      price_52w_h: 3.2,
      price_52w_l: 1.71,
      price_pc_1d: 0.847,
      price_pc_1w: -0.418,
      price_pc_1m: -1.653,
      price_pc_6m: 17.241,
      price_pc_1y: -2.459,
      price_pc_ytd: -2.459,
    },
    1293: {
      id: "1293",
      c: 0.52,
      ma50: 0.6,
      ma100: 0.668,
      ma150: 0.754,
      ma200: 0.892,
      maw30: 0.752,
      rs: 16.0,
      rsd1d: -14.0,
      rsd5d: 1.0,
      rsd10d: 6.0,
      price_5d_h: 0.63,
      price_5d_L: 0.5,
      price_1m_h: 0.63,
      price_1m_l: 0.42,
      price_3m_h: 0.74,
      price_3m_l: 0.42,
      price_6m_h: 1.03,
      price_6m_l: 0.42,
      price_52w_h: 1.87,
      price_52w_l: 0.42,
      price_pc_1d: -17.46,
      price_pc_1w: -3.704,
      price_pc_1m: -14.754,
      price_pc_6m: -40.23,
      price_pc_1y: -35.802,
      price_pc_ytd: -35.802,
    },
    1296: {
      id: "1296",
      c: 1.06,
      ma50: 0.984,
      ma100: 0.752,
      ma150: 0.698,
      ma200: 0.629,
      maw30: 0.704,
      rs: 97.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: -1.0,
      price_5d_h: 1.06,
      price_5d_L: 1.02,
      price_1m_h: 1.06,
      price_1m_l: 0.99,
      price_3m_h: 1.06,
      price_3m_l: 0.43,
      price_6m_h: 1.06,
      price_6m_l: 0.425,
      price_52w_h: 1.06,
      price_52w_l: 0.305,
      price_pc_1d: 1.923,
      price_pc_1w: 2.913,
      price_pc_1m: 2.913,
      price_pc_6m: 49.296,
      price_pc_1y: 186.486,
      price_pc_ytd: 186.486,
    },
    1297: {
      id: "1297",
      c: 0.58,
      ma50: 0.714,
      ma100: 0.798,
      ma150: 0.879,
      ma200: 0.991,
      maw30: 0.88,
      rs: 11.0,
      rsd1d: 2.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.6,
      price_5d_L: 0.54,
      price_1m_h: 0.73,
      price_1m_l: 0.54,
      price_3m_h: 0.94,
      price_3m_l: 0.54,
      price_6m_h: 1.11,
      price_6m_l: 0.54,
      price_52w_h: 1.93,
      price_52w_l: 0.54,
      price_pc_1d: 0.0,
      price_pc_1w: -3.333,
      price_pc_1m: -19.444,
      price_pc_6m: -44.231,
      price_pc_1y: -65.06,
      price_pc_ytd: -65.06,
    },
    1298: {
      id: "1298",
      c: 1.12,
      ma50: 1.049,
      ma100: 1.084,
      ma150: 1.156,
      ma200: 1.273,
      maw30: 1.157,
      rs: 52.0,
      rsd1d: 8.0,
      rsd5d: 9.0,
      rsd10d: 22.0,
      price_5d_h: 1.12,
      price_5d_L: 1.1,
      price_1m_h: 1.19,
      price_1m_l: 0.6,
      price_3m_h: 1.19,
      price_3m_l: 0.6,
      price_6m_h: 1.33,
      price_6m_l: 0.6,
      price_52w_h: 1.8,
      price_52w_l: 0.6,
      price_pc_1d: 1.818,
      price_pc_1w: -0.885,
      price_pc_1m: 12.0,
      price_pc_6m: -6.667,
      price_pc_1y: -25.333,
      price_pc_ytd: -25.333,
    },
    1299: {
      id: "1299",
      c: 82.45,
      ma50: 82.423,
      ma100: 82.182,
      ma150: 84.818,
      ma200: 87.24,
      maw30: 84.672,
      rs: 65.0,
      rsd1d: 3.0,
      rsd5d: 0.0,
      rsd10d: 5.0,
      price_5d_h: 84.0,
      price_5d_L: 78.35,
      price_1m_h: 84.3,
      price_1m_l: 70.15,
      price_3m_h: 92.5,
      price_3m_l: 70.15,
      price_6m_h: 92.5,
      price_6m_l: 70.15,
      price_52w_h: 104.8,
      price_52w_l: 70.15,
      price_pc_1d: -0.061,
      price_pc_1w: -0.423,
      price_pc_1m: 1.477,
      price_pc_6m: -8.338,
      price_pc_1y: -13.119,
      price_pc_ytd: -13.119,
    },
    1300: {
      id: "1300",
      c: 0.475,
      ma50: 0.52,
      ma100: 0.563,
      ma150: 0.605,
      ma200: 0.627,
      maw30: 0.604,
      rs: 25.0,
      rsd1d: -2.0,
      rsd5d: -5.0,
      rsd10d: -1.0,
      price_5d_h: 0.55,
      price_5d_L: 0.475,
      price_1m_h: 0.55,
      price_1m_l: 0.415,
      price_3m_h: 0.59,
      price_3m_l: 0.415,
      price_6m_h: 0.7,
      price_6m_l: 0.415,
      price_52w_h: 0.83,
      price_52w_l: 0.415,
      price_pc_1d: -5.0,
      price_pc_1w: -8.654,
      price_pc_1m: -10.377,
      price_pc_6m: -30.147,
      price_pc_1y: -37.5,
      price_pc_ytd: -37.5,
    },
    1301: {
      id: "1301",
      c: 1.23,
      ma50: 1.092,
      ma100: 1.139,
      ma150: 1.176,
      ma200: 1.227,
      maw30: 1.168,
      rs: 76.0,
      rsd1d: 6.0,
      rsd5d: 23.0,
      rsd10d: 23.0,
      price_5d_h: 1.28,
      price_5d_L: 1.09,
      price_1m_h: 1.28,
      price_1m_l: 1.03,
      price_3m_h: 1.28,
      price_3m_l: 1.0,
      price_6m_h: 1.32,
      price_6m_l: 1.0,
      price_52w_h: 1.42,
      price_52w_l: 1.0,
      price_pc_1d: 2.5,
      price_pc_1w: 11.818,
      price_pc_1m: 10.811,
      price_pc_6m: 0.82,
      price_pc_1y: 4.237,
      price_pc_ytd: 4.237,
    },
    1302: {
      id: "1302",
      c: 2.62,
      ma50: 3.033,
      ma100: 3.369,
      ma150: 3.567,
      ma200: 3.839,
      maw30: 3.567,
      rs: 18.0,
      rsd1d: -5.0,
      rsd5d: -7.0,
      rsd10d: -7.0,
      price_5d_h: 3.02,
      price_5d_L: 2.59,
      price_1m_h: 3.12,
      price_1m_l: 2.25,
      price_3m_h: 3.73,
      price_3m_l: 2.25,
      price_6m_h: 4.36,
      price_6m_l: 2.25,
      price_52w_h: 5.86,
      price_52w_l: 2.25,
      price_pc_1d: -7.092,
      price_pc_1w: -7.42,
      price_pc_1m: -15.21,
      price_pc_6m: -35.784,
      price_pc_1y: -28.997,
      price_pc_ytd: -28.997,
    },
    1303: {
      id: "1303",
      c: 0.465,
      ma50: 0.345,
      ma100: 0.253,
      ma150: 0.228,
      ma200: 0.221,
      maw30: 0.222,
      rs: 98.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.5,
      price_5d_L: 0.44,
      price_1m_h: 0.56,
      price_1m_l: 0.325,
      price_3m_h: 0.56,
      price_3m_l: 0.164,
      price_6m_h: 0.56,
      price_6m_l: 0.145,
      price_52w_h: 0.56,
      price_52w_l: 0.14,
      price_pc_1d: -2.105,
      price_pc_1w: 5.682,
      price_pc_1m: -5.102,
      price_pc_6m: 187.037,
      price_pc_1y: 158.333,
      price_pc_ytd: 158.333,
    },
    1305: {
      id: "1305",
      c: 1.07,
      ma50: 1.187,
      ma100: 1.236,
      ma150: 1.07,
      ma200: 0.949,
      maw30: 1.083,
      rs: 94.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: -2.0,
      price_5d_h: 1.15,
      price_5d_L: 1.01,
      price_1m_h: 1.19,
      price_1m_l: 0.94,
      price_3m_h: 1.34,
      price_3m_l: 0.94,
      price_6m_h: 1.6,
      price_6m_l: 0.56,
      price_52w_h: 1.6,
      price_52w_l: 0.45,
      price_pc_1d: -2.727,
      price_pc_1w: -6.957,
      price_pc_1m: -9.322,
      price_pc_6m: 98.148,
      price_pc_1y: 72.581,
      price_pc_ytd: 72.581,
    },
    1308: {
      id: "1308",
      c: 27.75,
      ma50: 30.307,
      ma100: 29.578,
      ma150: 29.483,
      ma200: 29.946,
      maw30: 29.443,
      rs: 69.0,
      rsd1d: -2.0,
      rsd5d: -7.0,
      rsd10d: -6.0,
      price_5d_h: 29.45,
      price_5d_L: 26.0,
      price_1m_h: 34.75,
      price_1m_l: 25.35,
      price_3m_h: 34.75,
      price_3m_l: 25.35,
      price_6m_h: 34.75,
      price_6m_l: 22.8,
      price_52w_h: 36.0,
      price_52w_l: 22.8,
      price_pc_1d: -2.632,
      price_pc_1w: -6.879,
      price_pc_1m: -13.551,
      price_pc_6m: -0.716,
      price_pc_1y: 3.16,
      price_pc_ytd: 3.16,
    },
    1310: {
      id: "1310",
      c: 8.99,
      ma50: 9.712,
      ma100: 9.685,
      ma150: 9.56,
      ma200: 9.424,
      maw30: 9.608,
      rs: 60.0,
      rsd1d: 0.0,
      rsd5d: -2.0,
      rsd10d: -5.0,
      price_5d_h: 9.43,
      price_5d_L: 8.93,
      price_1m_h: 9.95,
      price_1m_l: 8.52,
      price_3m_h: 11.1,
      price_3m_l: 8.52,
      price_6m_h: 11.1,
      price_6m_l: 8.52,
      price_52w_h: 12.22,
      price_52w_l: 8.23,
      price_pc_1d: -1.209,
      price_pc_1w: -2.706,
      price_pc_1m: -8.545,
      price_pc_6m: -2.176,
      price_pc_1y: -20.863,
      price_pc_ytd: -20.863,
    },
    1312: {
      id: "1312",
      c: 0.053,
      ma50: 0.045,
      ma100: 0.05,
      ma150: 0.054,
      ma200: 0.058,
      maw30: 0.054,
      rs: 55.0,
      rsd1d: 11.0,
      rsd5d: 17.0,
      rsd10d: 36.0,
      price_5d_h: 0.056,
      price_5d_L: 0.044,
      price_1m_h: 0.056,
      price_1m_l: 0.029,
      price_3m_h: 0.056,
      price_3m_l: 0.029,
      price_6m_h: 0.077,
      price_6m_l: 0.029,
      price_52w_h: 0.09,
      price_52w_l: 0.029,
      price_pc_1d: 3.922,
      price_pc_1w: 10.417,
      price_pc_1m: 17.778,
      price_pc_6m: -23.188,
      price_pc_1y: -15.873,
      price_pc_ytd: -15.873,
    },
    1313: {
      id: "1313",
      c: 6.53,
      ma50: 6.674,
      ma100: 6.374,
      ma150: 6.769,
      ma200: 6.894,
      maw30: 6.79,
      rs: 65.0,
      rsd1d: 2.0,
      rsd5d: 12.0,
      rsd10d: 3.0,
      price_5d_h: 6.64,
      price_5d_L: 6.22,
      price_1m_h: 6.83,
      price_1m_l: 5.63,
      price_3m_h: 7.3,
      price_3m_l: 5.63,
      price_6m_h: 7.74,
      price_6m_l: 5.61,
      price_52w_h: 9.1,
      price_52w_l: 5.61,
      price_pc_1d: -0.457,
      price_pc_1w: 2.997,
      price_pc_1m: -2.246,
      price_pc_6m: -13.049,
      price_pc_1y: -24.334,
      price_pc_ytd: -24.334,
    },
    1314: {
      id: "1314",
      c: 0.218,
      ma50: 0.225,
      ma100: 0.232,
      ma150: 0.248,
      ma200: 0.261,
      maw30: 0.249,
      rs: 34.0,
      rsd1d: 3.0,
      rsd5d: 6.0,
      rsd10d: 9.0,
      price_5d_h: 0.218,
      price_5d_L: 0.205,
      price_1m_h: 0.235,
      price_1m_l: 0.193,
      price_3m_h: 0.244,
      price_3m_l: 0.193,
      price_6m_h: 0.29,
      price_6m_l: 0.193,
      price_52w_h: 0.335,
      price_52w_l: 0.193,
      price_pc_1d: 0.0,
      price_pc_1w: 2.347,
      price_pc_1m: -1.802,
      price_pc_6m: -22.143,
      price_pc_1y: -31.875,
      price_pc_ytd: -31.875,
    },
    1315: {
      id: "1315",
      c: 0.022,
      ma50: 0.024,
      ma100: 0.027,
      ma150: 0.028,
      ma200: 0.028,
      maw30: 0.028,
      rs: 23.0,
      rsd1d: -2.0,
      rsd5d: 2.0,
      rsd10d: 4.0,
      price_5d_h: 0.026,
      price_5d_L: 0.021,
      price_1m_h: 0.027,
      price_1m_l: 0.019,
      price_3m_h: 0.033,
      price_3m_l: 0.019,
      price_6m_h: 0.038,
      price_6m_l: 0.019,
      price_52w_h: 0.065,
      price_52w_l: 0.019,
      price_pc_1d: -4.348,
      price_pc_1w: 0.0,
      price_pc_1m: -15.385,
      price_pc_6m: -26.667,
      price_pc_1y: -61.404,
      price_pc_ytd: -61.404,
    },
    1316: {
      id: "1316",
      c: 5.0,
      ma50: 7.372,
      ma100: 8.639,
      ma150: 8.654,
      ma200: 9.059,
      maw30: 8.725,
      rs: 9.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 1.0,
      price_5d_h: 5.28,
      price_5d_L: 4.61,
      price_1m_h: 7.96,
      price_1m_l: 4.4,
      price_3m_h: 10.9,
      price_3m_l: 4.4,
      price_6m_h: 11.18,
      price_6m_l: 4.4,
      price_52w_h: 12.9,
      price_52w_l: 4.4,
      price_pc_1d: -3.846,
      price_pc_1w: -4.762,
      price_pc_1m: -35.897,
      price_pc_6m: -37.811,
      price_pc_1y: -49.341,
      price_pc_ytd: -49.341,
    },
    1317: {
      id: "1317",
      c: 0.42,
      ma50: 0.446,
      ma100: 0.634,
      ma150: 0.903,
      ma200: 1.112,
      maw30: 0.894,
      rs: 6.0,
      rsd1d: 2.0,
      rsd5d: 3.0,
      rsd10d: 4.0,
      price_5d_h: 0.445,
      price_5d_L: 0.405,
      price_1m_h: 0.46,
      price_1m_l: 0.31,
      price_3m_h: 0.65,
      price_3m_l: 0.31,
      price_6m_h: 1.54,
      price_6m_l: 0.31,
      price_52w_h: 2.24,
      price_52w_l: 0.31,
      price_pc_1d: 1.205,
      price_pc_1w: -2.326,
      price_pc_1m: 2.439,
      price_pc_6m: -69.784,
      price_pc_1y: -79.71,
      price_pc_ytd: -79.71,
    },
    1321: {
      id: "1321",
      c: 1.09,
      ma50: 1.078,
      ma100: 1.025,
      ma150: 0.963,
      ma200: 0.946,
      maw30: 0.962,
      rs: 88.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: -1.0,
      price_5d_h: 1.16,
      price_5d_L: 1.05,
      price_1m_h: 1.16,
      price_1m_l: 0.95,
      price_3m_h: 1.16,
      price_3m_l: 0.86,
      price_6m_h: 1.16,
      price_6m_l: 0.78,
      price_52w_h: 1.16,
      price_52w_l: 0.72,
      price_pc_1d: 3.81,
      price_pc_1w: 0.0,
      price_pc_1m: 0.926,
      price_pc_6m: 36.25,
      price_pc_1y: 15.957,
      price_pc_ytd: 15.957,
    },
    1323: {
      id: "1323",
      c: 0.128,
      ma50: 0.13,
      ma100: 0.833,
      ma150: 0.954,
      ma200: 0.996,
      maw30: 0.942,
      rs: 1.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.133,
      price_5d_L: 0.119,
      price_1m_h: 0.151,
      price_1m_l: 0.108,
      price_3m_h: 2.08,
      price_3m_l: 0.067,
      price_6m_h: 2.1,
      price_6m_l: 0.067,
      price_52w_h: 2.1,
      price_52w_l: 0.067,
      price_pc_1d: 0.787,
      price_pc_1w: -2.29,
      price_pc_1m: 3.226,
      price_pc_6m: -87.692,
      price_pc_1y: -84.39,
      price_pc_ytd: -84.39,
    },
    1327: {
      id: "1327",
      c: 0.31,
      ma50: 0.35,
      ma100: 0.346,
      ma150: 0.334,
      ma200: 0.282,
      maw30: 0.335,
      rs: 95.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -1.0,
      price_5d_h: 0.34,
      price_5d_L: 0.305,
      price_1m_h: 0.43,
      price_1m_l: 0.305,
      price_3m_h: 0.445,
      price_3m_l: 0.305,
      price_6m_h: 0.445,
      price_6m_l: 0.29,
      price_52w_h: 0.445,
      price_52w_l: 0.092,
      price_pc_1d: -1.587,
      price_pc_1w: -8.824,
      price_pc_1m: -12.676,
      price_pc_6m: -3.125,
      price_pc_1y: 169.565,
      price_pc_ytd: 169.565,
    },
    1329: {
      id: "1329",
      c: 0.77,
      ma50: 0.818,
      ma100: 0.956,
      ma150: 0.987,
      ma200: 1.022,
      maw30: 0.976,
      rs: 29.0,
      rsd1d: 6.0,
      rsd5d: 6.0,
      rsd10d: 2.0,
      price_5d_h: 0.77,
      price_5d_L: 0.77,
      price_1m_h: 0.79,
      price_1m_l: 0.58,
      price_3m_h: 1.13,
      price_3m_l: 0.58,
      price_6m_h: 1.28,
      price_6m_l: 0.58,
      price_52w_h: 1.46,
      price_52w_l: 0.58,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: -2.532,
      price_pc_6m: -23.0,
      price_pc_1y: -30.0,
      price_pc_ytd: -30.0,
    },
    1330: {
      id: "1330",
      c: 3.6,
      ma50: 3.796,
      ma100: 3.921,
      ma150: 4.02,
      ma200: 3.901,
      maw30: 4.045,
      rs: 56.0,
      rsd1d: -4.0,
      rsd5d: -2.0,
      rsd10d: -5.0,
      price_5d_h: 3.76,
      price_5d_L: 3.52,
      price_1m_h: 3.92,
      price_1m_l: 3.2,
      price_3m_h: 4.24,
      price_3m_l: 3.2,
      price_6m_h: 5.36,
      price_6m_l: 3.2,
      price_52w_h: 5.36,
      price_52w_l: 3.2,
      price_pc_1d: -0.826,
      price_pc_1w: -0.552,
      price_pc_1m: -6.25,
      price_pc_6m: -22.414,
      price_pc_1y: -2.703,
      price_pc_ytd: -2.703,
    },
    1332: {
      id: "1332",
      c: 0.92,
      ma50: 0.948,
      ma100: 1.14,
      ma150: 1.053,
      ma200: 1.029,
      maw30: 1.048,
      rs: 39.0,
      rsd1d: -1.0,
      rsd5d: -17.0,
      rsd10d: 5.0,
      price_5d_h: 0.97,
      price_5d_L: 0.9,
      price_1m_h: 1.03,
      price_1m_l: 0.78,
      price_3m_h: 1.4,
      price_3m_l: 0.78,
      price_6m_h: 1.69,
      price_6m_l: 0.78,
      price_52w_h: 1.69,
      price_52w_l: 0.77,
      price_pc_1d: -1.075,
      price_pc_1w: -6.122,
      price_pc_1m: 9.524,
      price_pc_6m: 6.977,
      price_pc_1y: -23.967,
      price_pc_ytd: -23.967,
    },
    1336: {
      id: "1336",
      c: 21.9,
      ma50: 22.492,
      ma100: 22.012,
      ma150: 22.421,
      ma200: 22.757,
      maw30: 22.518,
      rs: 60.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: 1.0,
      price_5d_h: 22.3,
      price_5d_L: 20.9,
      price_1m_h: 22.95,
      price_1m_l: 19.06,
      price_3m_h: 24.7,
      price_3m_l: 19.06,
      price_6m_h: 25.15,
      price_6m_l: 19.06,
      price_52w_h: 30.95,
      price_52w_l: 19.06,
      price_pc_1d: -0.455,
      price_pc_1w: 0.0,
      price_pc_1m: -2.013,
      price_pc_6m: -4.783,
      price_pc_1y: -27.961,
      price_pc_ytd: -27.961,
    },
    1337: {
      id: "1337",
      c: 2.56,
      ma50: 2.389,
      ma100: 2.426,
      ma150: 2.217,
      ma200: 2.156,
      maw30: 2.211,
      rs: 89.0,
      rsd1d: -1.0,
      rsd5d: 2.0,
      rsd10d: -1.0,
      price_5d_h: 2.63,
      price_5d_L: 2.4,
      price_1m_h: 2.63,
      price_1m_l: 2.1,
      price_3m_h: 2.63,
      price_3m_l: 2.1,
      price_6m_h: 3.1,
      price_6m_l: 1.5,
      price_52w_h: 3.1,
      price_52w_l: 1.5,
      price_pc_1d: -1.158,
      price_pc_1w: 6.667,
      price_pc_1m: 1.186,
      price_pc_6m: 61.006,
      price_pc_1y: -5.185,
      price_pc_ytd: -5.185,
    },
    1338: {
      id: "1338",
      c: 0.076,
      ma50: 0.08,
      ma100: 0.088,
      ma150: 0.086,
      ma200: 0.089,
      maw30: 0.086,
      rs: 36.0,
      rsd1d: 3.0,
      rsd5d: 3.0,
      rsd10d: 6.0,
      price_5d_h: 0.084,
      price_5d_L: 0.072,
      price_1m_h: 0.093,
      price_1m_l: 0.07,
      price_3m_h: 0.099,
      price_3m_l: 0.07,
      price_6m_h: 0.128,
      price_6m_l: 0.069,
      price_52w_h: 0.128,
      price_52w_l: 0.067,
      price_pc_1d: 2.703,
      price_pc_1w: 0.0,
      price_pc_1m: -3.797,
      price_pc_6m: 4.11,
      price_pc_1y: -36.667,
      price_pc_ytd: -36.667,
    },
    1339: {
      id: "1339",
      c: 2.57,
      ma50: 2.494,
      ma100: 2.429,
      ma150: 2.436,
      ma200: 2.462,
      maw30: 2.439,
      rs: 81.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: 7.0,
      price_5d_h: 2.58,
      price_5d_L: 2.39,
      price_1m_h: 2.58,
      price_1m_l: 2.18,
      price_3m_h: 2.68,
      price_3m_l: 2.18,
      price_6m_h: 2.68,
      price_6m_l: 2.18,
      price_52w_h: 2.95,
      price_52w_l: 2.18,
      price_pc_1d: 1.181,
      price_pc_1w: 2.39,
      price_pc_1m: 2.39,
      price_pc_6m: 6.198,
      price_pc_1y: 1.581,
      price_pc_ytd: 1.581,
    },
    1340: {
      id: "1340",
      c: 0.065,
      ma50: 0.072,
      ma100: 0.082,
      ma150: 0.084,
      ma200: 0.084,
      maw30: 0.085,
      rs: 26.0,
      rsd1d: -2.0,
      rsd5d: 2.0,
      rsd10d: 9.0,
      price_5d_h: 0.077,
      price_5d_L: 0.063,
      price_1m_h: 0.079,
      price_1m_l: 0.053,
      price_3m_h: 0.1,
      price_3m_l: 0.053,
      price_6m_h: 0.119,
      price_6m_l: 0.053,
      price_52w_h: 0.128,
      price_52w_l: 0.053,
      price_pc_1d: -5.797,
      price_pc_1w: -2.985,
      price_pc_1m: -1.515,
      price_pc_6m: -27.778,
      price_pc_1y: -30.851,
      price_pc_ytd: -30.851,
    },
    1343: {
      id: "1343",
      c: 0.109,
      ma50: 0.108,
      ma100: 0.114,
      ma150: 0.114,
      ma200: 0.116,
      maw30: 0.115,
      rs: 50.0,
      rsd1d: -1.0,
      rsd5d: -8.0,
      rsd10d: 16.0,
      price_5d_h: 0.114,
      price_5d_L: 0.101,
      price_1m_h: 0.118,
      price_1m_l: 0.088,
      price_3m_h: 0.118,
      price_3m_l: 0.088,
      price_6m_h: 0.138,
      price_6m_l: 0.088,
      price_52w_h: 0.226,
      price_52w_l: 0.088,
      price_pc_1d: -1.802,
      price_pc_1w: -5.217,
      price_pc_1m: 2.83,
      price_pc_6m: 0.926,
      price_pc_1y: -47.087,
      price_pc_ytd: -47.087,
    },
    1345: {
      id: "1345",
      c: 2.43,
      ma50: 2.274,
      ma100: 1.837,
      ma150: 1.674,
      ma200: 1.578,
      maw30: 1.662,
      rs: 96.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: -1.0,
      price_5d_h: 2.71,
      price_5d_L: 2.3,
      price_1m_h: 2.76,
      price_1m_l: 2.01,
      price_3m_h: 2.76,
      price_3m_l: 1.5,
      price_6m_h: 2.76,
      price_6m_l: 1.1,
      price_52w_h: 2.76,
      price_52w_l: 0.93,
      price_pc_1d: 2.532,
      price_pc_1w: -11.314,
      price_pc_1m: 2.101,
      price_pc_6m: 82.707,
      price_pc_1y: 133.654,
      price_pc_ytd: 133.654,
    },
    1347: {
      id: "1347",
      c: 33.3,
      ma50: 36.573,
      ma100: 40.591,
      ma150: 40.961,
      ma200: 41.603,
      maw30: 41.05,
      rs: 33.0,
      rsd1d: -1.0,
      rsd5d: -3.0,
      rsd10d: -4.0,
      price_5d_h: 36.0,
      price_5d_L: 31.9,
      price_1m_h: 40.9,
      price_1m_l: 25.2,
      price_3m_h: 43.1,
      price_3m_l: 25.2,
      price_6m_h: 53.75,
      price_6m_l: 25.2,
      price_52w_h: 57.0,
      price_52w_l: 25.2,
      price_pc_1d: -1.77,
      price_pc_1w: -2.915,
      price_pc_1m: -17.778,
      price_pc_6m: -17.574,
      price_pc_1y: -27.609,
      price_pc_ytd: -27.609,
    },
    1349: {
      id: "1349",
      c: 3.73,
      ma50: 3.796,
      ma100: 3.876,
      ma150: 3.928,
      ma200: 4.152,
      maw30: 3.931,
      rs: 55.0,
      rsd1d: 4.0,
      rsd5d: 11.0,
      rsd10d: 8.0,
      price_5d_h: 3.81,
      price_5d_L: 3.5,
      price_1m_h: 3.96,
      price_1m_l: 3.28,
      price_3m_h: 4.16,
      price_3m_l: 3.28,
      price_6m_h: 4.18,
      price_6m_l: 3.28,
      price_52w_h: 5.73,
      price_52w_l: 3.28,
      price_pc_1d: 2.192,
      price_pc_1w: 3.611,
      price_pc_1m: -3.618,
      price_pc_6m: -4.113,
      price_pc_1y: -15.034,
      price_pc_ytd: -15.034,
    },
    1351: {
      id: "1351",
      c: 0.8,
      ma50: 0.684,
      ma100: 0.735,
      ma150: 0.8,
      ma200: 0.887,
      maw30: 0.799,
      rs: 32.0,
      rsd1d: 6.0,
      rsd5d: 10.0,
      rsd10d: 18.0,
      price_5d_h: 0.88,
      price_5d_L: 0.74,
      price_1m_h: 0.88,
      price_1m_l: 0.475,
      price_3m_h: 0.88,
      price_3m_l: 0.475,
      price_6m_h: 1.06,
      price_6m_l: 0.475,
      price_52w_h: 2.31,
      price_52w_l: 0.475,
      price_pc_1d: 1.266,
      price_pc_1w: 5.263,
      price_pc_1m: 33.333,
      price_pc_6m: -20.0,
      price_pc_1y: -60.784,
      price_pc_ytd: -60.784,
    },
    1357: {
      id: "1357",
      c: 0.93,
      ma50: 1.298,
      ma100: 1.47,
      ma150: 1.561,
      ma200: 1.585,
      maw30: 1.566,
      rs: 10.0,
      rsd1d: -4.0,
      rsd5d: -4.0,
      rsd10d: 0.0,
      price_5d_h: 1.2,
      price_5d_L: 0.91,
      price_1m_h: 1.33,
      price_1m_l: 0.79,
      price_3m_h: 1.73,
      price_3m_l: 0.79,
      price_6m_h: 2.02,
      price_6m_l: 0.79,
      price_52w_h: 3.14,
      price_52w_l: 0.79,
      price_pc_1d: -14.679,
      price_pc_1w: -19.13,
      price_pc_1m: -25.6,
      price_pc_6m: -38.816,
      price_pc_1y: -64.906,
      price_pc_ytd: -64.906,
    },
    1358: {
      id: "1358",
      c: 0.87,
      ma50: 0.966,
      ma100: 1.011,
      ma150: 1.097,
      ma200: 1.118,
      maw30: 1.105,
      rs: 58.0,
      rsd1d: -6.0,
      rsd5d: -7.0,
      rsd10d: -16.0,
      price_5d_h: 0.94,
      price_5d_L: 0.8,
      price_1m_h: 1.03,
      price_1m_l: 0.8,
      price_3m_h: 1.14,
      price_3m_l: 0.8,
      price_6m_h: 1.4,
      price_6m_l: 0.8,
      price_52w_h: 1.67,
      price_52w_l: 0.56,
      price_pc_1d: -5.435,
      price_pc_1w: 0.0,
      price_pc_1m: -6.452,
      price_pc_6m: -32.031,
      price_pc_1y: 35.937,
      price_pc_ytd: 35.937,
    },
    1359: {
      id: "1359",
      c: 1.34,
      ma50: 1.417,
      ma100: 1.374,
      ma150: 1.367,
      ma200: 1.389,
      maw30: 1.367,
      rs: 61.0,
      rsd1d: -4.0,
      rsd5d: -12.0,
      rsd10d: -14.0,
      price_5d_h: 1.46,
      price_5d_L: 1.34,
      price_1m_h: 1.47,
      price_1m_l: 1.23,
      price_3m_h: 1.53,
      price_3m_l: 1.23,
      price_6m_h: 1.62,
      price_6m_l: 1.22,
      price_52w_h: 1.68,
      price_52w_l: 1.22,
      price_pc_1d: -2.899,
      price_pc_1w: -4.965,
      price_pc_1m: -4.965,
      price_pc_6m: 1.515,
      price_pc_1y: -18.293,
      price_pc_ytd: -18.293,
    },
    1361: {
      id: "1361",
      c: 4.01,
      ma50: 3.765,
      ma100: 3.768,
      ma150: 3.776,
      ma200: 3.766,
      maw30: 3.8,
      rs: 89.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: 9.0,
      price_5d_h: 4.12,
      price_5d_L: 3.72,
      price_1m_h: 4.12,
      price_1m_l: 3.0,
      price_3m_h: 4.57,
      price_3m_l: 3.0,
      price_6m_h: 4.57,
      price_6m_l: 3.0,
      price_52w_h: 4.57,
      price_52w_l: 2.03,
      price_pc_1d: 2.558,
      price_pc_1w: 3.085,
      price_pc_1m: 4.7,
      price_pc_6m: 6.649,
      price_pc_1y: 79.821,
      price_pc_ytd: 79.821,
    },
    1366: {
      id: "1366",
      c: 0.275,
      ma50: 0.268,
      ma100: 0.275,
      ma150: 0.292,
      ma200: 0.315,
      maw30: 0.289,
      rs: 43.0,
      rsd1d: -9.0,
      rsd5d: -3.0,
      rsd10d: 24.0,
      price_5d_h: 0.325,
      price_5d_L: 0.24,
      price_1m_h: 0.325,
      price_1m_l: 0.18,
      price_3m_h: 0.325,
      price_3m_l: 0.18,
      price_6m_h: 0.33,
      price_6m_l: 0.18,
      price_52w_h: 0.41,
      price_52w_l: 0.18,
      price_pc_1d: -1.786,
      price_pc_1w: 0.0,
      price_pc_1m: 3.774,
      price_pc_6m: -9.836,
      price_pc_1y: -25.676,
      price_pc_ytd: -25.676,
    },
    1368: {
      id: "1368",
      c: 11.88,
      ma50: 12.515,
      ma100: 11.942,
      ma150: 11.848,
      ma200: 12.124,
      maw30: 11.881,
      rs: 91.0,
      rsd1d: 0.0,
      rsd5d: -2.0,
      rsd10d: -2.0,
      price_5d_h: 12.32,
      price_5d_L: 10.88,
      price_1m_h: 13.34,
      price_1m_l: 8.08,
      price_3m_h: 14.8,
      price_3m_l: 8.08,
      price_6m_h: 14.8,
      price_6m_l: 8.08,
      price_52w_h: 16.32,
      price_52w_l: 4.58,
      price_pc_1d: 1.193,
      price_pc_1w: -2.941,
      price_pc_1m: -7.907,
      price_pc_6m: 8.991,
      price_pc_1y: 159.389,
      price_pc_ytd: 159.389,
    },
    1371: {
      id: "1371",
      c: 0.076,
      ma50: 0.083,
      ma100: 0.09,
      ma150: 0.096,
      ma200: 0.101,
      maw30: 0.096,
      rs: 28.0,
      rsd1d: -6.0,
      rsd5d: 2.0,
      rsd10d: -5.0,
      price_5d_h: 0.08,
      price_5d_L: 0.069,
      price_1m_h: 0.09,
      price_1m_l: 0.06,
      price_3m_h: 0.1,
      price_3m_l: 0.06,
      price_6m_h: 0.13,
      price_6m_l: 0.06,
      price_52w_h: 0.152,
      price_52w_l: 0.06,
      price_pc_1d: -5.0,
      price_pc_1w: -2.564,
      price_pc_1m: -12.644,
      price_pc_6m: -24.0,
      price_pc_1y: -19.047,
      price_pc_ytd: -19.047,
    },
    1372: {
      id: "1372",
      c: 3.8,
      ma50: 4.141,
      ma100: 4.21,
      ma150: 4.341,
      ma200: 4.661,
      maw30: 4.341,
      rs: 24.0,
      rsd1d: 0.0,
      rsd5d: -9.0,
      rsd10d: -2.0,
      price_5d_h: 4.44,
      price_5d_L: 3.6,
      price_1m_h: 4.5,
      price_1m_l: 3.52,
      price_3m_h: 5.2,
      price_3m_l: 3.52,
      price_6m_h: 5.8,
      price_6m_l: 3.52,
      price_52w_h: 7.6,
      price_52w_l: 3.52,
      price_pc_1d: -1.809,
      price_pc_1w: -12.037,
      price_pc_1m: -6.634,
      price_pc_6m: -13.636,
      price_pc_1y: -47.945,
      price_pc_ytd: -47.945,
    },
    1373: {
      id: "1373",
      c: 2.6,
      ma50: 2.59,
      ma100: 2.657,
      ma150: 2.696,
      ma200: 2.782,
      maw30: 2.694,
      rs: 60.0,
      rsd1d: 0.0,
      rsd5d: 3.0,
      rsd10d: 1.0,
      price_5d_h: 2.63,
      price_5d_L: 2.58,
      price_1m_h: 2.65,
      price_1m_l: 2.35,
      price_3m_h: 2.86,
      price_3m_l: 2.35,
      price_6m_h: 2.86,
      price_6m_l: 2.35,
      price_52w_h: 3.4,
      price_52w_l: 2.35,
      price_pc_1d: -0.763,
      price_pc_1w: 0.386,
      price_pc_1m: 0.0,
      price_pc_6m: -3.346,
      price_pc_1y: -1.887,
      price_pc_ytd: -1.887,
    },
    1375: {
      id: "1375",
      c: 1.4,
      ma50: 1.449,
      ma100: 1.434,
      ma150: 1.446,
      ma200: 1.45,
      maw30: 1.447,
      rs: 70.0,
      rsd1d: 5.0,
      rsd5d: 5.0,
      rsd10d: 2.0,
      price_5d_h: 1.41,
      price_5d_L: 1.37,
      price_1m_h: 1.45,
      price_1m_l: 1.28,
      price_3m_h: 1.58,
      price_3m_l: 1.28,
      price_6m_h: 1.58,
      price_6m_l: 1.28,
      price_52w_h: 1.68,
      price_52w_l: 1.28,
      price_pc_1d: 0.719,
      price_pc_1w: 0.0,
      price_pc_1m: -2.098,
      price_pc_6m: -1.408,
      price_pc_1y: -7.895,
      price_pc_ytd: -7.895,
    },
    1378: {
      id: "1378",
      c: 10.46,
      ma50: 10.041,
      ma100: 8.985,
      ma150: 9.477,
      ma200: 9.708,
      maw30: 9.429,
      rs: 86.0,
      rsd1d: 0.0,
      rsd5d: -2.0,
      rsd10d: 14.0,
      price_5d_h: 10.98,
      price_5d_L: 10.1,
      price_1m_h: 11.9,
      price_1m_l: 7.91,
      price_3m_h: 11.9,
      price_3m_l: 7.91,
      price_6m_h: 11.9,
      price_6m_l: 6.88,
      price_52w_h: 14.4,
      price_52w_l: 6.88,
      price_pc_1d: 1.553,
      price_pc_1w: -4.212,
      price_pc_1m: -3.683,
      price_pc_6m: 4.391,
      price_pc_1y: -3.148,
      price_pc_ytd: -3.148,
    },
    1379: {
      id: "1379",
      c: 4.06,
      ma50: 4.789,
      ma100: 4.904,
      ma150: 4.995,
      ma200: 5.069,
      maw30: 4.987,
      rs: 30.0,
      rsd1d: 2.0,
      rsd5d: -5.0,
      rsd10d: -8.0,
      price_5d_h: 4.33,
      price_5d_L: 4.01,
      price_1m_h: 5.29,
      price_1m_l: 4.01,
      price_3m_h: 5.29,
      price_3m_l: 4.01,
      price_6m_h: 5.37,
      price_6m_l: 4.01,
      price_52w_h: 6.53,
      price_52w_l: 4.01,
      price_pc_1d: -0.733,
      price_pc_1w: -6.667,
      price_pc_1m: -17.98,
      price_pc_6m: -18.962,
      price_pc_1y: -33.984,
      price_pc_ytd: -33.984,
    },
    1380: {
      id: "1380",
      c: 0.018,
      ma50: 0.022,
      ma100: 0.024,
      ma150: 0.025,
      ma200: 0.027,
      maw30: 0.025,
      rs: 16.0,
      rsd1d: -9.0,
      rsd5d: -24.0,
      rsd10d: -16.0,
      price_5d_h: 0.023,
      price_5d_L: 0.017,
      price_1m_h: 0.026,
      price_1m_l: 0.017,
      price_3m_h: 0.027,
      price_3m_l: 0.017,
      price_6m_h: 0.039,
      price_6m_l: 0.017,
      price_52w_h: 0.039,
      price_52w_l: 0.017,
      price_pc_1d: -14.286,
      price_pc_1w: -21.739,
      price_pc_1m: -21.739,
      price_pc_6m: -33.333,
      price_pc_1y: -45.455,
      price_pc_ytd: -45.455,
    },
    1381: {
      id: "1381",
      c: 3.46,
      ma50: 3.727,
      ma100: 3.904,
      ma150: 4.076,
      ma200: 4.116,
      maw30: 4.083,
      rs: 43.0,
      rsd1d: 2.0,
      rsd5d: 6.0,
      rsd10d: -1.0,
      price_5d_h: 3.55,
      price_5d_L: 3.3,
      price_1m_h: 3.89,
      price_1m_l: 3.13,
      price_3m_h: 4.21,
      price_3m_l: 3.13,
      price_6m_h: 4.94,
      price_6m_l: 3.13,
      price_52w_h: 4.94,
      price_52w_l: 3.13,
      price_pc_1d: -0.288,
      price_pc_1w: -0.575,
      price_pc_1m: -8.947,
      price_pc_6m: -19.535,
      price_pc_1y: -3.889,
      price_pc_ytd: -3.889,
    },
    1382: {
      id: "1382",
      c: 3.63,
      ma50: 3.824,
      ma100: 3.843,
      ma150: 3.898,
      ma200: 4.051,
      maw30: 3.914,
      rs: 52.0,
      rsd1d: 3.0,
      rsd5d: 3.0,
      rsd10d: 4.0,
      price_5d_h: 3.79,
      price_5d_L: 3.55,
      price_1m_h: 3.84,
      price_1m_l: 3.19,
      price_3m_h: 4.12,
      price_3m_l: 3.19,
      price_6m_h: 4.35,
      price_6m_l: 3.19,
      price_52w_h: 5.78,
      price_52w_l: 3.19,
      price_pc_1d: -0.82,
      price_pc_1w: -1.892,
      price_pc_1m: -4.222,
      price_pc_6m: -7.868,
      price_pc_1y: -27.833,
      price_pc_ytd: -27.833,
    },
    1383: {
      id: "1383",
      c: 0.07,
      ma50: 0.093,
      ma100: 0.135,
      ma150: 0.19,
      ma200: 0.24,
      maw30: 0.19,
      rs: 2.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: -2.0,
      price_5d_h: 0.091,
      price_5d_L: 0.06,
      price_1m_h: 0.124,
      price_1m_l: 0.06,
      price_3m_h: 0.137,
      price_3m_l: 0.06,
      price_6m_h: 0.34,
      price_6m_l: 0.06,
      price_52w_h: 0.64,
      price_52w_l: 0.06,
      price_pc_1d: -2.778,
      price_pc_1w: -14.634,
      price_pc_1m: -11.392,
      price_pc_6m: -76.667,
      price_pc_1y: -88.333,
      price_pc_ytd: -88.333,
    },
    1385: {
      id: "1385",
      c: 23.5,
      ma50: 20.921,
      ma100: 23.333,
      ma150: 22.829,
      ma200: 22.972,
      maw30: 22.618,
      rs: 91.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: 2.0,
      price_5d_h: 24.05,
      price_5d_L: 20.5,
      price_1m_h: 24.05,
      price_1m_l: 17.72,
      price_3m_h: 25.25,
      price_3m_l: 17.72,
      price_6m_h: 29.8,
      price_6m_l: 17.72,
      price_52w_h: 33.2,
      price_52w_l: 9.6,
      price_pc_1d: -1.879,
      price_pc_1w: 6.095,
      price_pc_1m: 12.172,
      price_pc_6m: 18.09,
      price_pc_1y: 104.348,
      price_pc_ytd: 104.348,
    },
    1387: {
      id: "1387",
      c: 2.16,
      ma50: 2.145,
      ma100: 2.043,
      ma150: 2.015,
      ma200: 2.026,
      maw30: 2.011,
      rs: 82.0,
      rsd1d: 4.0,
      rsd5d: 5.0,
      rsd10d: -6.0,
      price_5d_h: 2.25,
      price_5d_L: 1.96,
      price_1m_h: 2.49,
      price_1m_l: 1.96,
      price_3m_h: 2.49,
      price_3m_l: 1.55,
      price_6m_h: 2.49,
      price_6m_l: 1.55,
      price_52w_h: 2.51,
      price_52w_l: 1.55,
      price_pc_1d: 2.37,
      price_pc_1w: 1.887,
      price_pc_1m: -10.744,
      price_pc_6m: 13.089,
      price_pc_1y: -6.087,
      price_pc_ytd: -6.087,
    },
    1389: {
      id: "1389",
      c: 0.031,
      ma50: 0.038,
      ma100: 0.039,
      ma150: 0.039,
      ma200: 0.039,
      maw30: 0.039,
      rs: 32.0,
      rsd1d: 3.0,
      rsd5d: -4.0,
      rsd10d: -23.0,
      price_5d_h: 0.035,
      price_5d_L: 0.03,
      price_1m_h: 0.038,
      price_1m_l: 0.029,
      price_3m_h: 0.066,
      price_3m_l: 0.029,
      price_6m_h: 0.066,
      price_6m_l: 0.029,
      price_52w_h: 0.066,
      price_52w_l: 0.029,
      price_pc_1d: 0.0,
      price_pc_1w: -6.061,
      price_pc_1m: -18.421,
      price_pc_6m: -22.5,
      price_pc_1y: -13.889,
      price_pc_ytd: -13.889,
    },
    1393: {
      id: "1393",
      c: 0.57,
      ma50: 0.474,
      ma100: 0.451,
      ma150: 0.417,
      ma200: 0.367,
      maw30: 0.418,
      rs: 96.0,
      rsd1d: -1.0,
      rsd5d: 1.0,
      rsd10d: 0.0,
      price_5d_h: 0.59,
      price_5d_L: 0.475,
      price_1m_h: 0.59,
      price_1m_l: 0.43,
      price_3m_h: 0.59,
      price_3m_l: 0.43,
      price_6m_h: 0.59,
      price_6m_l: 0.36,
      price_52w_h: 0.67,
      price_52w_l: 0.22,
      price_pc_1d: -1.724,
      price_pc_1w: 18.75,
      price_pc_1m: 22.581,
      price_pc_6m: 32.558,
      price_pc_1y: 159.091,
      price_pc_ytd: 159.091,
    },
    1395: {
      id: "1395",
      c: 0.144,
      ma50: 0.149,
      ma100: 0.136,
      ma150: 0.134,
      ma200: 0.132,
      maw30: 0.133,
      rs: 87.0,
      rsd1d: -5.0,
      rsd5d: -5.0,
      rsd10d: -6.0,
      price_5d_h: 0.17,
      price_5d_L: 0.144,
      price_1m_h: 0.19,
      price_1m_l: 0.138,
      price_3m_h: 0.197,
      price_3m_l: 0.115,
      price_6m_h: 0.197,
      price_6m_l: 0.104,
      price_52w_h: 0.24,
      price_52w_l: 0.102,
      price_pc_1d: -12.727,
      price_pc_1w: -4.0,
      price_pc_1m: -0.69,
      price_pc_6m: 10.769,
      price_pc_1y: 11.628,
      price_pc_ytd: 11.628,
    },
    1396: {
      id: "1396",
      c: 0.34,
      ma50: 0.38,
      ma100: 0.414,
      ma150: 0.435,
      ma200: 0.448,
      maw30: 0.438,
      rs: 22.0,
      rsd1d: 0.0,
      rsd5d: -9.0,
      rsd10d: -10.0,
      price_5d_h: 0.395,
      price_5d_L: 0.34,
      price_1m_h: 0.41,
      price_1m_l: 0.32,
      price_3m_h: 0.45,
      price_3m_l: 0.315,
      price_6m_h: 0.5,
      price_6m_l: 0.315,
      price_52w_h: 0.57,
      price_52w_l: 0.315,
      price_pc_1d: -2.857,
      price_pc_1w: -8.108,
      price_pc_1m: -12.821,
      price_pc_6m: -32.0,
      price_pc_1y: -37.037,
      price_pc_ytd: -37.037,
    },
    1397: {
      id: "1397",
      c: 0.5,
      ma50: 0.515,
      ma100: 0.52,
      ma150: 0.482,
      ma200: 0.454,
      maw30: 0.486,
      rs: 89.0,
      rsd1d: -2.0,
      rsd5d: -5.0,
      rsd10d: -1.0,
      price_5d_h: 0.63,
      price_5d_L: 0.48,
      price_1m_h: 0.78,
      price_1m_l: 0.465,
      price_3m_h: 0.78,
      price_3m_l: 0.465,
      price_6m_h: 0.78,
      price_6m_l: 0.385,
      price_52w_h: 0.78,
      price_52w_l: 0.305,
      price_pc_1d: -7.407,
      price_pc_1w: -20.635,
      price_pc_1m: 2.041,
      price_pc_6m: 19.048,
      price_pc_1y: 19.048,
      price_pc_ytd: 19.048,
    },
    1398: {
      id: "1398",
      c: 4.81,
      ma50: 4.689,
      ma100: 4.523,
      ma150: 4.46,
      ma200: 4.479,
      maw30: 4.467,
      rs: 80.0,
      rsd1d: 0.0,
      rsd5d: 4.0,
      rsd10d: 6.0,
      price_5d_h: 4.84,
      price_5d_L: 4.63,
      price_1m_h: 4.84,
      price_1m_l: 4.34,
      price_3m_h: 4.94,
      price_3m_l: 4.34,
      price_6m_h: 4.94,
      price_6m_l: 4.08,
      price_52w_h: 5.59,
      price_52w_l: 4.08,
      price_pc_1d: 1.05,
      price_pc_1w: 3.219,
      price_pc_1m: 2.998,
      price_pc_6m: 11.085,
      price_pc_1y: -13.489,
      price_pc_ytd: -13.489,
    },
    1399: {
      id: "1399",
      c: 0.295,
      ma50: 0.281,
      ma100: 0.286,
      ma150: 0.279,
      ma200: 0.274,
      maw30: 0.282,
      rs: 84.0,
      rsd1d: 0.0,
      rsd5d: 2.0,
      rsd10d: -1.0,
      price_5d_h: 0.295,
      price_5d_L: 0.275,
      price_1m_h: 0.295,
      price_1m_l: 0.25,
      price_3m_h: 0.32,
      price_3m_l: 0.25,
      price_6m_h: 0.32,
      price_6m_l: 0.25,
      price_52w_h: 0.32,
      price_52w_l: 0.217,
      price_pc_1d: 1.724,
      price_pc_1w: 3.509,
      price_pc_1m: 3.509,
      price_pc_6m: 13.462,
      price_pc_1y: 29.386,
      price_pc_ytd: 29.386,
    },
    1400: {
      id: "1400",
      c: 0.27,
      ma50: 0.235,
      ma100: 0.383,
      ma150: 0.458,
      ma200: 0.525,
      maw30: 0.453,
      rs: 92.0,
      rsd1d: -1.0,
      rsd5d: -3.0,
      rsd10d: -2.0,
      price_5d_h: 0.325,
      price_5d_L: 0.22,
      price_1m_h: 0.335,
      price_1m_l: 0.021,
      price_3m_h: 0.52,
      price_3m_l: 0.021,
      price_6m_h: 0.76,
      price_6m_l: 0.021,
      price_52w_h: 1.19,
      price_52w_l: 0.021,
      price_pc_1d: -3.571,
      price_pc_1w: -10.0,
      price_pc_1m: 17.391,
      price_pc_6m: -50.0,
      price_pc_1y: 116.0,
      price_pc_ytd: 116.0,
    },
    1401: {
      id: "1401",
      c: 0.81,
      ma50: 0.758,
      ma100: 0.738,
      ma150: 0.748,
      ma200: 0.748,
      maw30: 0.748,
      rs: 87.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: 2.0,
      price_5d_h: 0.81,
      price_5d_L: 0.74,
      price_1m_h: 0.82,
      price_1m_l: 0.74,
      price_3m_h: 0.82,
      price_3m_l: 0.66,
      price_6m_h: 0.82,
      price_6m_l: 0.56,
      price_52w_h: 1.01,
      price_52w_l: 0.55,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: 2.532,
      price_pc_6m: 6.579,
      price_pc_1y: 22.727,
      price_pc_ytd: 22.727,
    },
    1402: {
      id: "1402",
      c: 0.465,
      ma50: 0.479,
      ma100: 0.48,
      ma150: 0.512,
      ma200: 0.533,
      maw30: 0.512,
      rs: 43.0,
      rsd1d: 17.0,
      rsd5d: 11.0,
      rsd10d: -9.0,
      price_5d_h: 0.48,
      price_5d_L: 0.38,
      price_1m_h: 0.5,
      price_1m_l: 0.38,
      price_3m_h: 0.53,
      price_3m_l: 0.38,
      price_6m_h: 0.59,
      price_6m_l: 0.38,
      price_52w_h: 0.79,
      price_52w_l: 0.38,
      price_pc_1d: 10.714,
      price_pc_1w: 10.714,
      price_pc_1m: -7.0,
      price_pc_6m: -21.186,
      price_pc_1y: -13.889,
      price_pc_ytd: -13.889,
    },
    1406: {
      id: "1406",
      c: 1.25,
      ma50: null,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 38.0,
      rsd1d: 8.0,
      rsd5d: -17.0,
      rsd10d: 21.0,
      price_5d_h: 1.43,
      price_5d_L: 1.03,
      price_1m_h: 1.5,
      price_1m_l: 0.7,
      price_3m_h: 1.61,
      price_3m_l: 0.7,
      price_6m_h: 1.61,
      price_6m_l: 0.7,
      price_52w_h: 1.61,
      price_52w_l: 0.7,
      price_pc_1d: 4.167,
      price_pc_1w: -10.714,
      price_pc_1m: -9.42,
      price_pc_6m: -18.831,
      price_pc_1y: -18.831,
      price_pc_ytd: -18.831,
    },
    1408: {
      id: "1408",
      c: 0.315,
      ma50: 0.248,
      ma100: 0.257,
      ma150: 0.282,
      ma200: 0.297,
      maw30: 0.28,
      rs: 80.0,
      rsd1d: 7.0,
      rsd5d: 47.0,
      rsd10d: 45.0,
      price_5d_h: 0.33,
      price_5d_L: 0.23,
      price_1m_h: 0.33,
      price_1m_l: 0.215,
      price_3m_h: 0.33,
      price_3m_l: 0.215,
      price_6m_h: 0.37,
      price_6m_l: 0.215,
      price_52w_h: 0.415,
      price_52w_l: 0.215,
      price_pc_1d: 5.0,
      price_pc_1w: 35.193,
      price_pc_1m: 26.0,
      price_pc_6m: -7.353,
      price_pc_1y: -14.865,
      price_pc_ytd: -14.865,
    },
    1410: {
      id: "1410",
      c: 0.68,
      ma50: 0.717,
      ma100: 0.7,
      ma150: 0.673,
      ma200: 0.663,
      maw30: 0.678,
      rs: 83.0,
      rsd1d: -3.0,
      rsd5d: 0.0,
      rsd10d: -4.0,
      price_5d_h: 0.7,
      price_5d_L: 0.67,
      price_1m_h: 0.73,
      price_1m_l: 0.61,
      price_3m_h: 0.85,
      price_3m_l: 0.61,
      price_6m_h: 0.85,
      price_6m_l: 0.6,
      price_52w_h: 0.85,
      price_52w_l: 0.51,
      price_pc_1d: -1.449,
      price_pc_1w: -1.449,
      price_pc_1m: -1.449,
      price_pc_6m: 7.937,
      price_pc_1y: 36.0,
      price_pc_ytd: 36.0,
    },
    1413: {
      id: "1413",
      c: 0.275,
      ma50: 0.311,
      ma100: 0.312,
      ma150: 0.302,
      ma200: 0.295,
      maw30: 0.305,
      rs: 42.0,
      rsd1d: 0.0,
      rsd5d: -11.0,
      rsd10d: 10.0,
      price_5d_h: 0.3,
      price_5d_L: 0.265,
      price_1m_h: 0.31,
      price_1m_l: 0.247,
      price_3m_h: 0.37,
      price_3m_l: 0.247,
      price_6m_h: 0.38,
      price_6m_l: 0.24,
      price_52w_h: 0.4,
      price_52w_l: 0.235,
      price_pc_1d: -1.786,
      price_pc_1w: -8.333,
      price_pc_1m: -6.78,
      price_pc_6m: -3.509,
      price_pc_1y: -25.676,
      price_pc_ytd: -25.676,
    },
    1415: {
      id: "1415",
      c: 7.7,
      ma50: 8.274,
      ma100: 9.62,
      ma150: 8.256,
      ma200: 7.409,
      maw30: 8.347,
      rs: 89.0,
      rsd1d: -1.0,
      rsd5d: -2.0,
      rsd10d: 1.0,
      price_5d_h: 8.29,
      price_5d_L: 7.34,
      price_1m_h: 8.91,
      price_1m_l: 5.47,
      price_3m_h: 13.96,
      price_3m_l: 5.47,
      price_6m_h: 13.96,
      price_6m_l: 4.59,
      price_52w_h: 13.96,
      price_52w_l: 4.0,
      price_pc_1d: -0.901,
      price_pc_1w: -5.405,
      price_pc_1m: -12.698,
      price_pc_6m: 57.787,
      price_pc_1y: 60.752,
      price_pc_ytd: 60.752,
    },
    1417: {
      id: "1417",
      c: 1.57,
      ma50: 1.602,
      ma100: 1.688,
      ma150: 1.753,
      ma200: 1.788,
      maw30: 1.733,
      rs: 44.0,
      rsd1d: 4.0,
      rsd5d: -9.0,
      rsd10d: -16.0,
      price_5d_h: 1.64,
      price_5d_L: 1.52,
      price_1m_h: 1.78,
      price_1m_l: 1.42,
      price_3m_h: 1.83,
      price_3m_l: 1.4,
      price_6m_h: 2.12,
      price_6m_l: 1.4,
      price_52w_h: 2.14,
      price_52w_l: 1.4,
      price_pc_1d: 1.29,
      price_pc_1w: -4.848,
      price_pc_1m: 6.081,
      price_pc_6m: -19.487,
      price_pc_1y: -11.299,
      price_pc_ytd: -11.299,
    },
    1419: {
      id: "1419",
      c: 1.58,
      ma50: 1.534,
      ma100: 1.36,
      ma150: 1.297,
      ma200: 1.224,
      maw30: 1.295,
      rs: 94.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: -2.0,
      price_5d_h: 1.58,
      price_5d_L: 1.5,
      price_1m_h: 1.97,
      price_1m_l: 1.35,
      price_3m_h: 2.4,
      price_3m_l: 1.15,
      price_6m_h: 2.4,
      price_6m_l: 1.07,
      price_52w_h: 2.4,
      price_52w_l: 0.78,
      price_pc_1d: 3.268,
      price_pc_1w: 1.282,
      price_pc_1m: -17.708,
      price_pc_6m: 29.508,
      price_pc_1y: 102.564,
      price_pc_ytd: 102.564,
    },
    1421: {
      id: "1421",
      c: 0.062,
      ma50: 0.071,
      ma100: 0.074,
      ma150: 0.078,
      ma200: 0.081,
      maw30: 0.078,
      rs: 38.0,
      rsd1d: -12.0,
      rsd5d: -21.0,
      rsd10d: -47.0,
      price_5d_h: 0.071,
      price_5d_L: 0.062,
      price_1m_h: 0.08,
      price_1m_l: 0.047,
      price_3m_h: 0.085,
      price_3m_l: 0.03,
      price_6m_h: 0.1,
      price_6m_l: 0.03,
      price_52w_h: 0.131,
      price_52w_l: 0.03,
      price_pc_1d: -8.824,
      price_pc_1w: -10.145,
      price_pc_1m: -7.463,
      price_pc_6m: -28.736,
      price_pc_1y: -15.068,
      price_pc_ytd: -15.068,
    },
    1425: {
      id: "1425",
      c: 0.49,
      ma50: 0.421,
      ma100: 0.438,
      ma150: 0.459,
      ma200: 0.485,
      maw30: 0.456,
      rs: 78.0,
      rsd1d: 35.0,
      rsd5d: 43.0,
      rsd10d: 34.0,
      price_5d_h: 0.5,
      price_5d_L: 0.37,
      price_1m_h: 0.5,
      price_1m_l: 0.37,
      price_3m_h: 0.5,
      price_3m_l: 0.37,
      price_6m_h: 0.52,
      price_6m_l: 0.37,
      price_52w_h: 0.8,
      price_52w_l: 0.37,
      price_pc_1d: 19.512,
      price_pc_1w: 25.641,
      price_pc_1m: 13.953,
      price_pc_6m: -5.769,
      price_pc_1y: 18.072,
      price_pc_ytd: 18.072,
    },
    1426: {
      id: "1426",
      c: 2.8,
      ma50: 2.731,
      ma100: 2.698,
      ma150: 2.74,
      ma200: 2.777,
      maw30: 2.736,
      rs: 78.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: 9.0,
      price_5d_h: 2.81,
      price_5d_L: 2.76,
      price_1m_h: 2.83,
      price_1m_l: 2.5,
      price_3m_h: 2.83,
      price_3m_l: 2.5,
      price_6m_h: 2.84,
      price_6m_l: 2.5,
      price_52w_h: 3.0,
      price_52w_l: 2.5,
      price_pc_1d: 1.083,
      price_pc_1w: 0.0,
      price_pc_1m: 3.704,
      price_pc_6m: -1.754,
      price_pc_1y: -0.709,
      price_pc_ytd: -0.709,
    },
    1427: {
      id: "1427",
      c: 3.14,
      ma50: 3.409,
      ma100: 3.593,
      ma150: 3.503,
      ma200: 3.415,
      maw30: 3.517,
      rs: 55.0,
      rsd1d: 1.0,
      rsd5d: -13.0,
      rsd10d: -31.0,
      price_5d_h: 3.32,
      price_5d_L: 2.9,
      price_1m_h: 3.59,
      price_1m_l: 2.9,
      price_3m_h: 3.95,
      price_3m_l: 2.9,
      price_6m_h: 4.44,
      price_6m_l: 2.9,
      price_52w_h: 4.44,
      price_52w_l: 2.75,
      price_pc_1d: 0.319,
      price_pc_1w: -1.875,
      price_pc_1m: -6.269,
      price_pc_6m: -5.988,
      price_pc_1y: -5.136,
      price_pc_ytd: -5.136,
    },
    1428: {
      id: "1428",
      c: 1.4,
      ma50: 1.423,
      ma100: 1.46,
      ma150: 1.488,
      ma200: 1.59,
      maw30: 1.487,
      rs: 45.0,
      rsd1d: -1.0,
      rsd5d: 5.0,
      rsd10d: 8.0,
      price_5d_h: 1.42,
      price_5d_L: 1.35,
      price_1m_h: 1.45,
      price_1m_l: 1.16,
      price_3m_h: 1.57,
      price_3m_l: 1.16,
      price_6m_h: 1.71,
      price_6m_l: 1.16,
      price_52w_h: 2.3,
      price_52w_l: 1.16,
      price_pc_1d: -0.709,
      price_pc_1w: 0.0,
      price_pc_1m: -2.098,
      price_pc_6m: -3.448,
      price_pc_1y: -36.073,
      price_pc_ytd: -36.073,
    },
    1429: {
      id: "1429",
      c: 0.1,
      ma50: 0.096,
      ma100: 0.112,
      ma150: 0.122,
      ma200: 0.13,
      maw30: 0.122,
      rs: 23.0,
      rsd1d: -6.0,
      rsd5d: -21.0,
      rsd10d: 14.0,
      price_5d_h: 0.123,
      price_5d_L: 0.095,
      price_1m_h: 0.143,
      price_1m_l: 0.07,
      price_3m_h: 0.143,
      price_3m_l: 0.07,
      price_6m_h: 0.168,
      price_6m_l: 0.07,
      price_52w_h: 0.184,
      price_52w_l: 0.07,
      price_pc_1d: -7.407,
      price_pc_1w: -18.699,
      price_pc_1m: 9.89,
      price_pc_6m: -28.058,
      price_pc_1y: -42.529,
      price_pc_ytd: -42.529,
    },
    1431: {
      id: "1431",
      c: 0.249,
      ma50: 0.257,
      ma100: 0.272,
      ma150: 0.298,
      ma200: 0.345,
      maw30: 0.296,
      rs: 24.0,
      rsd1d: 3.0,
      rsd5d: -3.0,
      rsd10d: 1.0,
      price_5d_h: 0.26,
      price_5d_L: 0.241,
      price_1m_h: 0.285,
      price_1m_l: 0.221,
      price_3m_h: 0.29,
      price_3m_l: 0.221,
      price_6m_h: 0.395,
      price_6m_l: 0.221,
      price_52w_h: 0.72,
      price_52w_l: 0.221,
      price_pc_1d: 2.049,
      price_pc_1w: -4.231,
      price_pc_1m: 1.22,
      price_pc_6m: -23.385,
      price_pc_1y: -64.93,
      price_pc_ytd: -64.93,
    },
    1432: {
      id: "1432",
      c: 0.37,
      ma50: 0.464,
      ma100: 0.507,
      ma150: 0.524,
      ma200: 0.549,
      maw30: 0.523,
      rs: 17.0,
      rsd1d: 0.0,
      rsd5d: -3.0,
      rsd10d: -1.0,
      price_5d_h: 0.42,
      price_5d_L: 0.365,
      price_1m_h: 0.51,
      price_1m_l: 0.335,
      price_3m_h: 0.56,
      price_3m_l: 0.335,
      price_6m_h: 0.6,
      price_6m_l: 0.335,
      price_52w_h: 0.82,
      price_52w_l: 0.335,
      price_pc_1d: -3.896,
      price_pc_1w: -5.128,
      price_pc_1m: -25.253,
      price_pc_6m: -30.189,
      price_pc_1y: -42.188,
      price_pc_ytd: -42.188,
    },
    1433: {
      id: "1433",
      c: 0.057,
      ma50: 0.056,
      ma100: 0.06,
      ma150: 0.06,
      ma200: 0.059,
      maw30: 0.061,
      rs: 71.0,
      rsd1d: 13.0,
      rsd5d: -4.0,
      rsd10d: 12.0,
      price_5d_h: 0.06,
      price_5d_L: 0.051,
      price_1m_h: 0.06,
      price_1m_l: 0.047,
      price_3m_h: 0.066,
      price_3m_l: 0.047,
      price_6m_h: 0.079,
      price_6m_l: 0.047,
      price_52w_h: 0.079,
      price_52w_l: 0.045,
      price_pc_1d: 7.547,
      price_pc_1w: -1.724,
      price_pc_1m: 5.556,
      price_pc_6m: 1.786,
      price_pc_1y: 5.556,
      price_pc_ytd: 5.556,
    },
    1440: {
      id: "1440",
      c: 0.65,
      ma50: 0.59,
      ma100: 0.593,
      ma150: 0.658,
      ma200: 0.701,
      maw30: 0.653,
      rs: 77.0,
      rsd1d: -4.0,
      rsd5d: -3.0,
      rsd10d: 33.0,
      price_5d_h: 0.66,
      price_5d_L: 0.56,
      price_1m_h: 0.66,
      price_1m_l: 0.31,
      price_3m_h: 0.78,
      price_3m_l: 0.31,
      price_6m_h: 0.78,
      price_6m_l: 0.31,
      price_52w_h: 1.27,
      price_52w_l: 0.31,
      price_pc_1d: 1.563,
      price_pc_1w: 8.333,
      price_pc_1m: 4.839,
      price_pc_6m: -9.722,
      price_pc_1y: 14.035,
      price_pc_ytd: 14.035,
    },
    1442: {
      id: "1442",
      c: 1.27,
      ma50: 1.592,
      ma100: 1.609,
      ma150: 1.593,
      ma200: 1.506,
      maw30: 1.616,
      rs: 72.0,
      rsd1d: -11.0,
      rsd5d: -17.0,
      rsd10d: -17.0,
      price_5d_h: 1.48,
      price_5d_L: 1.26,
      price_1m_h: 1.66,
      price_1m_l: 1.26,
      price_3m_h: 1.95,
      price_3m_l: 1.26,
      price_6m_h: 1.95,
      price_6m_l: 1.26,
      price_52w_h: 1.95,
      price_52w_l: 0.54,
      price_pc_1d: -8.633,
      price_pc_1w: -10.563,
      price_pc_1m: -16.993,
      price_pc_6m: -25.294,
      price_pc_1y: 81.429,
      price_pc_ytd: 81.429,
    },
    1446: {
      id: "1446",
      c: 0.39,
      ma50: 0.397,
      ma100: 0.402,
      ma150: 0.42,
      ma200: 0.439,
      maw30: 0.418,
      rs: 51.0,
      rsd1d: 9.0,
      rsd5d: -4.0,
      rsd10d: -3.0,
      price_5d_h: 0.42,
      price_5d_L: 0.38,
      price_1m_h: 0.42,
      price_1m_l: 0.37,
      price_3m_h: 0.43,
      price_3m_l: 0.37,
      price_6m_h: 0.47,
      price_6m_l: 0.37,
      price_52w_h: 0.59,
      price_52w_l: 0.37,
      price_pc_1d: 2.632,
      price_pc_1w: -2.5,
      price_pc_1m: 1.299,
      price_pc_6m: -9.302,
      price_pc_1y: -32.759,
      price_pc_ytd: -32.759,
    },
    1448: {
      id: "1448",
      c: 5.78,
      ma50: 5.88,
      ma100: 6.042,
      ma150: 6.264,
      ma200: 6.557,
      maw30: 6.268,
      rs: 44.0,
      rsd1d: 1.0,
      rsd5d: 8.0,
      rsd10d: -12.0,
      price_5d_h: 5.86,
      price_5d_L: 5.38,
      price_1m_h: 5.9,
      price_1m_l: 4.97,
      price_3m_h: 6.44,
      price_3m_l: 4.97,
      price_6m_h: 7.05,
      price_6m_l: 4.97,
      price_52w_h: 8.68,
      price_52w_l: 4.97,
      price_pc_1d: 0.347,
      price_pc_1w: 5.861,
      price_pc_1m: -0.173,
      price_pc_6m: -10.94,
      price_pc_1y: -28.465,
      price_pc_ytd: -28.465,
    },
    1449: {
      id: "1449",
      c: 0.375,
      ma50: 0.396,
      ma100: 0.438,
      ma150: 0.462,
      ma200: 0.508,
      maw30: 0.463,
      rs: 20.0,
      rsd1d: 4.0,
      rsd5d: 3.0,
      rsd10d: 0.0,
      price_5d_h: 0.38,
      price_5d_L: 0.34,
      price_1m_h: 0.41,
      price_1m_l: 0.32,
      price_3m_h: 0.46,
      price_3m_l: 0.32,
      price_6m_h: 0.56,
      price_6m_l: 0.32,
      price_52w_h: 0.88,
      price_52w_l: 0.32,
      price_pc_1d: 2.74,
      price_pc_1w: 0.0,
      price_pc_1m: -3.846,
      price_pc_6m: -27.885,
      price_pc_1y: -45.652,
      price_pc_ytd: -45.652,
    },
    1450: {
      id: "1450",
      c: 1.91,
      ma50: 1.567,
      ma100: 1.491,
      ma150: 1.533,
      ma200: 1.286,
      maw30: 1.537,
      rs: 99.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 1.96,
      price_5d_L: 1.75,
      price_1m_h: 1.97,
      price_1m_l: 1.5,
      price_3m_h: 1.97,
      price_3m_l: 1.17,
      price_6m_h: 1.97,
      price_6m_l: 1.05,
      price_52w_h: 2.08,
      price_52w_l: 0.106,
      price_pc_1d: 0.0,
      price_pc_1w: 2.688,
      price_pc_1m: 19.375,
      price_pc_6m: -2.551,
      price_pc_1y: 1546.552,
      price_pc_ytd: 1546.552,
    },
    1452: {
      id: "1452",
      c: 0.178,
      ma50: 0.17,
      ma100: 0.175,
      ma150: 0.19,
      ma200: 0.204,
      maw30: 0.188,
      rs: 48.0,
      rsd1d: -34.0,
      rsd5d: 6.0,
      rsd10d: 0.0,
      price_5d_h: 0.24,
      price_5d_L: 0.161,
      price_1m_h: 0.24,
      price_1m_l: 0.116,
      price_3m_h: 0.24,
      price_3m_l: 0.116,
      price_6m_h: 0.24,
      price_6m_l: 0.116,
      price_52w_h: 0.32,
      price_52w_l: 0.116,
      price_pc_1d: -19.091,
      price_pc_1w: 4.094,
      price_pc_1m: 3.488,
      price_pc_6m: -17.209,
      price_pc_1y: -1.111,
      price_pc_ytd: -1.111,
    },
    1455: {
      id: "1455",
      c: 0.199,
      ma50: 0.218,
      ma100: 0.231,
      ma150: 0.24,
      ma200: 0.255,
      maw30: 0.242,
      rs: 42.0,
      rsd1d: 1.0,
      rsd5d: -9.0,
      rsd10d: -6.0,
      price_5d_h: 0.205,
      price_5d_L: 0.198,
      price_1m_h: 0.224,
      price_1m_l: 0.185,
      price_3m_h: 0.245,
      price_3m_l: 0.185,
      price_6m_h: 0.28,
      price_6m_l: 0.185,
      price_52w_h: 0.39,
      price_52w_l: 0.185,
      price_pc_1d: -0.5,
      price_pc_1w: -1.485,
      price_pc_1m: -11.556,
      price_pc_6m: -18.107,
      price_pc_1y: 3.646,
      price_pc_ytd: 3.646,
    },
    1456: {
      id: "1456",
      c: 4.08,
      ma50: 4.293,
      ma100: 4.193,
      ma150: 4.034,
      ma200: 3.937,
      maw30: 4.038,
      rs: 80.0,
      rsd1d: -2.0,
      rsd5d: -2.0,
      rsd10d: -4.0,
      price_5d_h: 4.28,
      price_5d_L: 4.0,
      price_1m_h: 4.57,
      price_1m_l: 3.61,
      price_3m_h: 4.69,
      price_3m_l: 3.61,
      price_6m_h: 4.69,
      price_6m_l: 3.6,
      price_52w_h: 4.69,
      price_52w_l: 3.27,
      price_pc_1d: -1.687,
      price_pc_1w: -0.244,
      price_pc_1m: -8.315,
      price_pc_6m: 13.333,
      price_pc_1y: 6.806,
      price_pc_ytd: 6.806,
    },
    1458: {
      id: "1458",
      c: 4.32,
      ma50: 5.067,
      ma100: 5.754,
      ma150: 6.337,
      ma200: 6.773,
      maw30: 6.378,
      rs: 13.0,
      rsd1d: -1.0,
      rsd5d: 2.0,
      rsd10d: 0.0,
      price_5d_h: 4.76,
      price_5d_L: 3.95,
      price_1m_h: 5.32,
      price_1m_l: 3.27,
      price_3m_h: 6.4,
      price_3m_l: 3.27,
      price_6m_h: 8.06,
      price_6m_l: 3.27,
      price_52w_h: 11.86,
      price_52w_l: 3.27,
      price_pc_1d: -3.786,
      price_pc_1w: 0.232,
      price_pc_1m: -16.923,
      price_pc_6m: -45.316,
      price_pc_1y: -48.325,
      price_pc_ytd: -48.325,
    },
    1460: {
      id: "1460",
      c: 0.233,
      ma50: 0.275,
      ma100: 0.337,
      ma150: 0.443,
      ma200: 0.442,
      maw30: 0.443,
      rs: 21.0,
      rsd1d: 0.0,
      rsd5d: -4.0,
      rsd10d: -7.0,
      price_5d_h: 0.3,
      price_5d_L: 0.23,
      price_1m_h: 0.355,
      price_1m_l: 0.202,
      price_3m_h: 0.395,
      price_3m_l: 0.202,
      price_6m_h: 0.79,
      price_6m_l: 0.202,
      price_52w_h: 0.9,
      price_52w_l: 0.202,
      price_pc_1d: -4.115,
      price_pc_1w: -4.508,
      price_pc_1m: -16.786,
      price_pc_6m: -61.803,
      price_pc_1y: -10.385,
      price_pc_ytd: -10.385,
    },
    1461: {
      id: "1461",
      c: 0.74,
      ma50: 0.744,
      ma100: 0.68,
      ma150: 0.654,
      ma200: 0.626,
      maw30: 0.658,
      rs: 90.0,
      rsd1d: -1.0,
      rsd5d: -3.0,
      rsd10d: -3.0,
      price_5d_h: 0.79,
      price_5d_L: 0.7,
      price_1m_h: 0.85,
      price_1m_l: 0.6,
      price_3m_h: 0.88,
      price_3m_l: 0.6,
      price_6m_h: 0.88,
      price_6m_l: 0.56,
      price_52w_h: 0.88,
      price_52w_l: 0.49,
      price_pc_1d: 0.0,
      price_pc_1w: -9.756,
      price_pc_1m: -11.905,
      price_pc_6m: 21.311,
      price_pc_1y: 10.448,
      price_pc_ytd: 10.448,
    },
    1463: {
      id: "1463",
      c: 2.33,
      ma50: 2.701,
      ma100: 3.267,
      ma150: 3.379,
      ma200: 3.383,
      maw30: 3.395,
      rs: 21.0,
      rsd1d: -3.0,
      rsd5d: 1.0,
      rsd10d: 1.0,
      price_5d_h: 2.58,
      price_5d_L: 2.22,
      price_1m_h: 2.87,
      price_1m_l: 1.9,
      price_3m_h: 3.9,
      price_3m_l: 1.9,
      price_6m_h: 4.4,
      price_6m_l: 1.9,
      price_52w_h: 4.4,
      price_52w_l: 1.9,
      price_pc_1d: -8.268,
      price_pc_1w: -2.917,
      price_pc_1m: -18.246,
      price_pc_6m: -36.164,
      price_pc_1y: 5.909,
      price_pc_ytd: 5.909,
    },
    1466: {
      id: "1466",
      c: 0.122,
      ma50: 0.124,
      ma100: 0.125,
      ma150: 0.13,
      ma200: 0.132,
      maw30: 0.13,
      rs: 65.0,
      rsd1d: 5.0,
      rsd5d: -12.0,
      rsd10d: 18.0,
      price_5d_h: 0.128,
      price_5d_L: 0.12,
      price_1m_h: 0.142,
      price_1m_l: 0.11,
      price_3m_h: 0.145,
      price_3m_l: 0.106,
      price_6m_h: 0.151,
      price_6m_l: 0.106,
      price_52w_h: 0.181,
      price_52w_l: 0.106,
      price_pc_1d: 1.667,
      price_pc_1w: -6.87,
      price_pc_1m: -1.613,
      price_pc_6m: -12.23,
      price_pc_1y: -2.4,
      price_pc_ytd: -2.4,
    },
    1468: {
      id: "1468",
      c: 0.57,
      ma50: 0.599,
      ma100: 0.641,
      ma150: 0.675,
      ma200: 0.683,
      maw30: 0.671,
      rs: 49.0,
      rsd1d: 8.0,
      rsd5d: 13.0,
      rsd10d: 16.0,
      price_5d_h: 0.57,
      price_5d_L: 0.55,
      price_1m_h: 0.62,
      price_1m_l: 0.51,
      price_3m_h: 0.67,
      price_3m_l: 0.51,
      price_6m_h: 0.79,
      price_6m_l: 0.51,
      price_52w_h: 0.89,
      price_52w_l: 0.51,
      price_pc_1d: 1.786,
      price_pc_1w: 1.786,
      price_pc_1m: -8.065,
      price_pc_6m: -14.925,
      price_pc_1y: -1.724,
      price_pc_ytd: -1.724,
    },
    1470: {
      id: "1470",
      c: 0.094,
      ma50: 0.107,
      ma100: 0.113,
      ma150: 0.119,
      ma200: 0.119,
      maw30: 0.12,
      rs: 55.0,
      rsd1d: 5.0,
      rsd5d: -17.0,
      rsd10d: -14.0,
      price_5d_h: 0.099,
      price_5d_L: 0.09,
      price_1m_h: 0.105,
      price_1m_l: 0.084,
      price_3m_h: 0.173,
      price_3m_l: 0.084,
      price_6m_h: 0.173,
      price_6m_l: 0.084,
      price_52w_h: 0.35,
      price_52w_l: 0.07,
      price_pc_1d: 1.075,
      price_pc_1w: -7.843,
      price_pc_1m: -4.082,
      price_pc_6m: -27.692,
      price_pc_1y: 13.253,
      price_pc_ytd: 13.253,
    },
    1472: {
      id: "1472",
      c: 0.129,
      ma50: 0.122,
      ma100: 0.131,
      ma150: 0.141,
      ma200: 0.153,
      maw30: 0.141,
      rs: 29.0,
      rsd1d: -1.0,
      rsd5d: -6.0,
      rsd10d: 18.0,
      price_5d_h: 0.156,
      price_5d_L: 0.125,
      price_1m_h: 0.167,
      price_1m_l: 0.09,
      price_3m_h: 0.167,
      price_3m_l: 0.09,
      price_6m_h: 0.179,
      price_6m_l: 0.09,
      price_52w_h: 1.16,
      price_52w_l: 0.09,
      price_pc_1d: -2.273,
      price_pc_1w: -7.194,
      price_pc_1m: 5.738,
      price_pc_6m: -13.423,
      price_pc_1y: -76.111,
      price_pc_ytd: -76.111,
    },
    1475: {
      id: "1475",
      c: 5.49,
      ma50: 5.676,
      ma100: 5.776,
      ma150: 5.756,
      ma200: 5.735,
      maw30: 5.783,
      rs: 59.0,
      rsd1d: 0.0,
      rsd5d: -5.0,
      rsd10d: 8.0,
      price_5d_h: 5.56,
      price_5d_L: 5.38,
      price_1m_h: 5.95,
      price_1m_l: 4.84,
      price_3m_h: 6.55,
      price_3m_l: 4.84,
      price_6m_h: 6.55,
      price_6m_l: 4.84,
      price_52w_h: 6.55,
      price_52w_l: 4.84,
      price_pc_1d: -0.543,
      price_pc_1w: -1.259,
      price_pc_1m: -3.004,
      price_pc_6m: -9.256,
      price_pc_1y: -8.5,
      price_pc_ytd: -8.5,
    },
    1476: {
      id: "1476",
      c: 2.74,
      ma50: 2.973,
      ma100: 2.982,
      ma150: 2.995,
      ma200: 2.997,
      maw30: 3.017,
      rs: 48.0,
      rsd1d: -10.0,
      rsd5d: -32.0,
      rsd10d: -31.0,
      price_5d_h: 2.88,
      price_5d_L: 2.74,
      price_1m_h: 3.01,
      price_1m_l: 2.74,
      price_3m_h: 3.12,
      price_3m_l: 2.74,
      price_6m_h: 3.73,
      price_6m_l: 2.7,
      price_52w_h: 3.73,
      price_52w_l: 2.6,
      price_pc_1d: -0.364,
      price_pc_1w: -5.517,
      price_pc_1m: -8.97,
      price_pc_6m: -9.272,
      price_pc_1y: -10.749,
      price_pc_ytd: -10.749,
    },
    1477: {
      id: "1477",
      c: 10.02,
      ma50: 10.279,
      ma100: 13.491,
      ma150: 15.255,
      ma200: 17.562,
      maw30: 15.341,
      rs: 8.0,
      rsd1d: 0.0,
      rsd5d: 2.0,
      rsd10d: 2.0,
      price_5d_h: 10.46,
      price_5d_L: 9.6,
      price_1m_h: 10.46,
      price_1m_l: 7.97,
      price_3m_h: 17.96,
      price_3m_l: 7.97,
      price_6m_h: 19.38,
      price_6m_l: 7.97,
      price_52w_h: 32.05,
      price_52w_l: 7.97,
      price_pc_1d: -3.654,
      price_pc_1w: -0.792,
      price_pc_1m: 13.093,
      price_pc_6m: -46.474,
      price_pc_1y: -56.717,
      price_pc_ytd: -56.717,
    },
    1478: {
      id: "1478",
      c: 6.06,
      ma50: 7.566,
      ma100: 9.12,
      ma150: 10.05,
      ma200: 11.069,
      maw30: 10.069,
      rs: 8.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: -2.0,
      price_5d_h: 6.58,
      price_5d_L: 5.84,
      price_1m_h: 7.53,
      price_1m_l: 5.84,
      price_3m_h: 10.18,
      price_3m_l: 5.84,
      price_6m_h: 12.4,
      price_6m_l: 5.84,
      price_52w_h: 17.46,
      price_52w_l: 5.84,
      price_pc_1d: -2.729,
      price_pc_1w: -5.901,
      price_pc_1m: -17.551,
      price_pc_6m: -45.989,
      price_pc_1y: -62.776,
      price_pc_ytd: -62.776,
    },
    1481: {
      id: "1481",
      c: 0.81,
      ma50: 0.78,
      ma100: 0.873,
      ma150: 0.695,
      ma200: 0.598,
      maw30: 0.71,
      rs: 98.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.84,
      price_5d_L: 0.76,
      price_1m_h: 0.89,
      price_1m_l: 0.55,
      price_3m_h: 1.3,
      price_3m_l: 0.55,
      price_6m_h: 1.4,
      price_6m_l: 0.2,
      price_52w_h: 1.4,
      price_52w_l: 0.183,
      price_pc_1d: 0.0,
      price_pc_1w: 3.846,
      price_pc_1m: 0.0,
      price_pc_6m: 217.647,
      price_pc_1y: 309.091,
      price_pc_ytd: 309.091,
    },
    1486: {
      id: "1486",
      c: 0.9,
      ma50: 0.964,
      ma100: 0.988,
      ma150: 1.011,
      ma200: 1.028,
      maw30: 1.012,
      rs: 41.0,
      rsd1d: 9.0,
      rsd5d: 2.0,
      rsd10d: -11.0,
      price_5d_h: 0.91,
      price_5d_L: 0.65,
      price_1m_h: 0.96,
      price_1m_l: 0.65,
      price_3m_h: 1.04,
      price_3m_l: 0.65,
      price_6m_h: 1.25,
      price_6m_l: 0.65,
      price_52w_h: 1.5,
      price_52w_l: 0.65,
      price_pc_1d: 4.651,
      price_pc_1w: -1.099,
      price_pc_1m: -6.25,
      price_pc_6m: -10.0,
      price_pc_1y: -38.356,
      price_pc_ytd: -38.356,
    },
    1488: {
      id: "1488",
      c: 0.9,
      ma50: 0.879,
      ma100: 0.918,
      ma150: 0.884,
      ma200: 0.846,
      maw30: 0.887,
      rs: 82.0,
      rsd1d: 16.0,
      rsd5d: -3.0,
      rsd10d: -7.0,
      price_5d_h: 1.03,
      price_5d_L: 0.76,
      price_1m_h: 1.03,
      price_1m_l: 0.66,
      price_3m_h: 1.24,
      price_3m_l: 0.66,
      price_6m_h: 1.24,
      price_6m_l: 0.66,
      price_52w_h: 1.24,
      price_52w_l: 0.57,
      price_pc_1d: 9.756,
      price_pc_1w: 2.273,
      price_pc_1m: 5.882,
      price_pc_6m: 7.143,
      price_pc_1y: 15.385,
      price_pc_ytd: 15.385,
    },
    1490: {
      id: "1490",
      c: 0.75,
      ma50: 0.661,
      ma100: 0.741,
      ma150: 0.711,
      ma200: 0.756,
      maw30: 0.701,
      rs: 62.0,
      rsd1d: -1.0,
      rsd5d: -4.0,
      rsd10d: -18.0,
      price_5d_h: 0.79,
      price_5d_L: 0.72,
      price_1m_h: 0.8,
      price_1m_l: 0.63,
      price_3m_h: 0.98,
      price_3m_l: 0.51,
      price_6m_h: 1.05,
      price_6m_l: 0.485,
      price_52w_h: 1.52,
      price_52w_l: 0.445,
      price_pc_1d: -2.597,
      price_pc_1w: -1.316,
      price_pc_1m: 15.385,
      price_pc_6m: 41.509,
      price_pc_1y: -38.525,
      price_pc_ytd: -38.525,
    },
    1495: {
      id: "1495",
      c: 0.71,
      ma50: 0.846,
      ma100: 0.958,
      ma150: 0.947,
      ma200: 0.863,
      maw30: 0.955,
      rs: 52.0,
      rsd1d: -4.0,
      rsd5d: -14.0,
      rsd10d: -32.0,
      price_5d_h: 0.86,
      price_5d_L: 0.69,
      price_1m_h: 0.89,
      price_1m_l: 0.69,
      price_3m_h: 1.06,
      price_3m_l: 0.69,
      price_6m_h: 1.3,
      price_6m_l: 0.69,
      price_52w_h: 1.3,
      price_52w_l: 0.56,
      price_pc_1d: -1.389,
      price_pc_1w: -10.127,
      price_pc_1m: -19.318,
      price_pc_6m: -29.0,
      price_pc_1y: 7.576,
      price_pc_ytd: 7.576,
    },
    1498: {
      id: "1498",
      c: 0.97,
      ma50: 0.886,
      ma100: 0.85,
      ma150: 0.836,
      ma200: 0.814,
      maw30: 0.835,
      rs: 92.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: 2.0,
      price_5d_h: 1.03,
      price_5d_L: 0.96,
      price_1m_h: 1.07,
      price_1m_l: 0.82,
      price_3m_h: 1.34,
      price_3m_l: 0.78,
      price_6m_h: 1.34,
      price_6m_l: 0.76,
      price_52w_h: 1.34,
      price_52w_l: 0.66,
      price_pc_1d: -2.02,
      price_pc_1w: -3.0,
      price_pc_1m: 11.494,
      price_pc_6m: 22.785,
      price_pc_1y: 40.58,
      price_pc_ytd: 40.58,
    },
    1499: {
      id: "1499",
      c: 0.225,
      ma50: 0.211,
      ma100: 0.234,
      ma150: 0.262,
      ma200: 0.274,
      maw30: 0.262,
      rs: 24.0,
      rsd1d: 1.0,
      rsd5d: 10.0,
      rsd10d: 12.0,
      price_5d_h: 0.23,
      price_5d_L: 0.207,
      price_1m_h: 0.23,
      price_1m_l: 0.155,
      price_3m_h: 0.265,
      price_3m_l: 0.155,
      price_6m_h: 0.4,
      price_6m_l: 0.155,
      price_52w_h: 0.74,
      price_52w_l: 0.155,
      price_pc_1d: 1.81,
      price_pc_1w: 11.94,
      price_pc_1m: 14.796,
      price_pc_6m: -18.182,
      price_pc_1y: -60.526,
      price_pc_ytd: -60.526,
    },
    1500: {
      id: "1500",
      c: 0.26,
      ma50: 0.271,
      ma100: 0.27,
      ma150: 0.254,
      ma200: 0.244,
      maw30: 0.255,
      rs: 89.0,
      rsd1d: 1.0,
      rsd5d: 3.0,
      rsd10d: 1.0,
      price_5d_h: 0.265,
      price_5d_L: 0.226,
      price_1m_h: 0.265,
      price_1m_l: 0.22,
      price_3m_h: 0.325,
      price_3m_l: 0.22,
      price_6m_h: 0.38,
      price_6m_l: 0.203,
      price_52w_h: 0.38,
      price_52w_l: 0.149,
      price_pc_1d: 1.961,
      price_pc_1w: 6.557,
      price_pc_1m: -5.455,
      price_pc_6m: 17.647,
      price_pc_1y: 54.762,
      price_pc_ytd: 54.762,
    },
    1501: {
      id: "1501",
      c: 16.2,
      ma50: 15.206,
      ma100: 19.465,
      ma150: 21.305,
      ma200: 24.701,
      maw30: 21.164,
      rs: 15.0,
      rsd1d: 2.0,
      rsd5d: 3.0,
      rsd10d: 11.0,
      price_5d_h: 16.8,
      price_5d_L: 14.74,
      price_1m_h: 16.8,
      price_1m_l: 10.8,
      price_3m_h: 23.75,
      price_3m_l: 10.8,
      price_6m_h: 29.9,
      price_6m_l: 10.8,
      price_52w_h: 44.8,
      price_52w_l: 10.8,
      price_pc_1d: -0.123,
      price_pc_1w: -0.613,
      price_pc_1m: 7.427,
      price_pc_6m: -22.857,
      price_pc_1y: -49.767,
      price_pc_ytd: -49.767,
    },
    1502: {
      id: "1502",
      c: 3.07,
      ma50: 3.121,
      ma100: 3.441,
      ma150: 3.638,
      ma200: 3.85,
      maw30: 3.657,
      rs: 28.0,
      rsd1d: 4.0,
      rsd5d: 8.0,
      rsd10d: 9.0,
      price_5d_h: 3.3,
      price_5d_L: 2.94,
      price_1m_h: 3.3,
      price_1m_l: 2.51,
      price_3m_h: 3.68,
      price_3m_l: 2.51,
      price_6m_h: 4.45,
      price_6m_l: 2.51,
      price_52w_h: 5.89,
      price_52w_l: 2.51,
      price_pc_1d: -0.325,
      price_pc_1w: 0.656,
      price_pc_1m: -0.968,
      price_pc_6m: -21.282,
      price_pc_1y: -36.701,
      price_pc_ytd: -36.701,
    },
    1503: {
      id: "1503",
      c: 2.84,
      ma50: 2.752,
      ma100: 2.695,
      ma150: 2.74,
      ma200: 2.757,
      maw30: 2.731,
      rs: 80.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: -1.0,
      price_5d_h: 2.84,
      price_5d_L: 2.79,
      price_1m_h: 2.84,
      price_1m_l: 2.58,
      price_3m_h: 2.85,
      price_3m_l: 2.58,
      price_6m_h: 2.85,
      price_6m_l: 2.46,
      price_52w_h: 3.04,
      price_52w_l: 2.46,
      price_pc_1d: 0.0,
      price_pc_1w: 1.429,
      price_pc_1m: 5.576,
      price_pc_6m: 1.792,
      price_pc_1y: 7.576,
      price_pc_ytd: 7.576,
    },
    1508: {
      id: "1508",
      c: 0.72,
      ma50: 0.749,
      ma100: 0.752,
      ma150: 0.783,
      ma200: 0.782,
      maw30: 0.786,
      rs: 56.0,
      rsd1d: -4.0,
      rsd5d: 3.0,
      rsd10d: 4.0,
      price_5d_h: 0.74,
      price_5d_L: 0.7,
      price_1m_h: 0.76,
      price_1m_l: 0.63,
      price_3m_h: 0.81,
      price_3m_l: 0.63,
      price_6m_h: 0.93,
      price_6m_l: 0.63,
      price_52w_h: 0.93,
      price_52w_l: 0.63,
      price_pc_1d: -2.703,
      price_pc_1w: 0.0,
      price_pc_1m: -4.0,
      price_pc_6m: -18.182,
      price_pc_1y: -14.286,
      price_pc_ytd: -14.286,
    },
    1513: {
      id: "1513",
      c: 28.15,
      ma50: 28.503,
      ma100: 27.89,
      ma150: 28.068,
      ma200: 29.084,
      maw30: 28.02,
      rs: 57.0,
      rsd1d: -7.0,
      rsd5d: -19.0,
      rsd10d: -10.0,
      price_5d_h: 31.0,
      price_5d_L: 28.15,
      price_1m_h: 31.4,
      price_1m_l: 24.0,
      price_3m_h: 31.6,
      price_3m_l: 24.0,
      price_6m_h: 31.6,
      price_6m_l: 24.0,
      price_52w_h: 42.0,
      price_52w_l: 24.0,
      price_pc_1d: -4.089,
      price_pc_1w: -8.455,
      price_pc_1m: -3.761,
      price_pc_6m: -1.573,
      price_pc_1y: -20.927,
      price_pc_ytd: -20.927,
    },
    1515: {
      id: "1515",
      c: 4.7,
      ma50: 4.411,
      ma100: 4.61,
      ma150: 5.122,
      ma200: 5.834,
      maw30: 5.093,
      rs: 37.0,
      rsd1d: 4.0,
      rsd5d: 11.0,
      rsd10d: 13.0,
      price_5d_h: 4.78,
      price_5d_L: 4.12,
      price_1m_h: 4.78,
      price_1m_l: 3.5,
      price_3m_h: 5.01,
      price_3m_l: 3.5,
      price_6m_h: 6.36,
      price_6m_l: 3.5,
      price_52w_h: 11.66,
      price_52w_l: 3.5,
      price_pc_1d: 0.213,
      price_pc_1w: 6.576,
      price_pc_1m: 7.062,
      price_pc_6m: -25.633,
      price_pc_1y: -27.469,
      price_pc_ytd: -27.469,
    },
    1516: {
      id: "1516",
      c: 4.85,
      ma50: 7.053,
      ma100: 8.817,
      ma150: 11.462,
      ma200: 14.504,
      maw30: 11.445,
      rs: 3.0,
      rsd1d: -1.0,
      rsd5d: 1.0,
      rsd10d: -2.0,
      price_5d_h: 5.29,
      price_5d_L: 3.92,
      price_1m_h: 7.15,
      price_1m_l: 2.9,
      price_3m_h: 10.14,
      price_3m_l: 2.9,
      price_6m_h: 19.76,
      price_6m_l: 2.9,
      price_52w_h: 29.4,
      price_52w_l: 2.9,
      price_pc_1d: -6.008,
      price_pc_1w: 6.593,
      price_pc_1m: -26.848,
      price_pc_6m: -69.876,
      price_pc_1y: -79.707,
      price_pc_ytd: -79.707,
    },
    1518: {
      id: "1518",
      c: 1.09,
      ma50: 1.014,
      ma100: 1.032,
      ma150: 1.047,
      ma200: 1.091,
      maw30: 1.05,
      rs: 51.0,
      rsd1d: 15.0,
      rsd5d: 8.0,
      rsd10d: 1.0,
      price_5d_h: 1.09,
      price_5d_L: 0.95,
      price_1m_h: 1.09,
      price_1m_l: 0.9,
      price_3m_h: 1.21,
      price_3m_l: 0.8,
      price_6m_h: 1.21,
      price_6m_l: 0.8,
      price_52w_h: 1.69,
      price_52w_l: 0.8,
      price_pc_1d: 9.0,
      price_pc_1w: 6.863,
      price_pc_1m: 0.926,
      price_pc_6m: 0.0,
      price_pc_1y: -35.119,
      price_pc_ytd: -35.119,
    },
    1520: {
      id: "1520",
      c: 0.29,
      ma50: 0.261,
      ma100: 0.227,
      ma150: 0.211,
      ma200: 0.218,
      maw30: 0.212,
      rs: 93.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: 2.0,
      price_5d_h: 0.29,
      price_5d_L: 0.275,
      price_1m_h: 0.305,
      price_1m_l: 0.245,
      price_3m_h: 0.305,
      price_3m_l: 0.211,
      price_6m_h: 0.305,
      price_6m_l: 0.138,
      price_52w_h: 0.355,
      price_52w_l: 0.138,
      price_pc_1d: 1.754,
      price_pc_1w: 1.754,
      price_pc_1m: 7.407,
      price_pc_6m: 71.598,
      price_pc_1y: 38.756,
      price_pc_ytd: 38.756,
    },
    1521: {
      id: "1521",
      c: 2.89,
      ma50: 3.423,
      ma100: 3.786,
      ma150: 4.209,
      ma200: 4.844,
      maw30: 4.208,
      rs: 13.0,
      rsd1d: -1.0,
      rsd5d: -4.0,
      rsd10d: -6.0,
      price_5d_h: 3.21,
      price_5d_L: 2.73,
      price_1m_h: 3.72,
      price_1m_l: 2.5,
      price_3m_h: 4.37,
      price_3m_l: 2.5,
      price_6m_h: 5.26,
      price_6m_l: 2.5,
      price_52w_h: 9.18,
      price_52w_l: 2.5,
      price_pc_1d: -2.694,
      price_pc_1w: -9.404,
      price_pc_1m: -19.722,
      price_pc_6m: -42.772,
      price_pc_1y: -39.792,
      price_pc_ytd: -39.792,
    },
    1522: {
      id: "1522",
      c: 0.4,
      ma50: 0.406,
      ma100: 0.44,
      ma150: 0.484,
      ma200: 0.47,
      maw30: 0.488,
      rs: 33.0,
      rsd1d: 1.0,
      rsd5d: -1.0,
      rsd10d: 0.0,
      price_5d_h: 0.41,
      price_5d_L: 0.39,
      price_1m_h: 0.415,
      price_1m_l: 0.35,
      price_3m_h: 0.455,
      price_3m_l: 0.35,
      price_6m_h: 0.66,
      price_6m_l: 0.35,
      price_52w_h: 0.67,
      price_52w_l: 0.35,
      price_pc_1d: 0.0,
      price_pc_1w: -2.439,
      price_pc_1m: -3.614,
      price_pc_6m: -33.333,
      price_pc_1y: -39.394,
      price_pc_ytd: -39.394,
    },
    1523: {
      id: "1523",
      c: 3.23,
      ma50: 3.484,
      ma100: 3.65,
      ma150: 3.354,
      ma200: 3.043,
      maw30: 3.366,
      rs: 94.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: 0.0,
      price_5d_h: 3.31,
      price_5d_L: 3.05,
      price_1m_h: 3.68,
      price_1m_l: 2.75,
      price_3m_h: 4.0,
      price_3m_l: 2.75,
      price_6m_h: 4.68,
      price_6m_l: 2.35,
      price_52w_h: 4.68,
      price_52w_l: 1.39,
      price_pc_1d: -1.524,
      price_pc_1w: -2.121,
      price_pc_1m: -13.405,
      price_pc_6m: 29.719,
      price_pc_1y: 134.058,
      price_pc_ytd: 134.058,
    },
    1525: {
      id: "1525",
      c: 3.79,
      ma50: 4.051,
      ma100: 4.49,
      ma150: 4.707,
      ma200: 4.945,
      maw30: 4.698,
      rs: 24.0,
      rsd1d: 2.0,
      rsd5d: 5.0,
      rsd10d: 0.0,
      price_5d_h: 3.84,
      price_5d_L: 3.62,
      price_1m_h: 4.2,
      price_1m_l: 3.54,
      price_3m_h: 4.97,
      price_3m_l: 3.54,
      price_6m_h: 5.4,
      price_6m_l: 3.54,
      price_52w_h: 6.31,
      price_52w_l: 3.54,
      price_pc_1d: 0.0,
      price_pc_1w: 0.798,
      price_pc_1m: -5.486,
      price_pc_6m: -24.351,
      price_pc_1y: -36.409,
      price_pc_ytd: -36.409,
    },
    1526: {
      id: "1526",
      c: 0.9,
      ma50: 0.85,
      ma100: 0.973,
      ma150: 1.062,
      ma200: 1.123,
      maw30: 1.063,
      rs: 26.0,
      rsd1d: 3.0,
      rsd5d: -2.0,
      rsd10d: -4.0,
      price_5d_h: 0.93,
      price_5d_L: 0.73,
      price_1m_h: 0.93,
      price_1m_l: 0.67,
      price_3m_h: 1.33,
      price_3m_l: 0.67,
      price_6m_h: 1.35,
      price_6m_l: 0.67,
      price_52w_h: 1.77,
      price_52w_l: 0.67,
      price_pc_1d: 1.124,
      price_pc_1w: 3.448,
      price_pc_1m: 7.143,
      price_pc_6m: -28.0,
      price_pc_1y: -4.255,
      price_pc_ytd: -4.255,
    },
    1528: {
      id: "1528",
      c: 3.52,
      ma50: 3.749,
      ma100: 3.831,
      ma150: 4.073,
      ma200: 4.326,
      maw30: 4.094,
      rs: 38.0,
      rsd1d: 4.0,
      rsd5d: 10.0,
      rsd10d: 10.0,
      price_5d_h: 3.75,
      price_5d_L: 3.3,
      price_1m_h: 3.84,
      price_1m_l: 3.01,
      price_3m_h: 4.32,
      price_3m_l: 3.01,
      price_6m_h: 4.76,
      price_6m_l: 3.01,
      price_52w_h: 6.2,
      price_52w_l: 3.01,
      price_pc_1d: 0.285,
      price_pc_1w: 4.451,
      price_pc_1m: -4.348,
      price_pc_6m: -22.296,
      price_pc_1y: -23.478,
      price_pc_ytd: -23.478,
    },
    1529: {
      id: "1529",
      c: 0.365,
      ma50: 0.347,
      ma100: 0.318,
      ma150: 0.307,
      ma200: 0.29,
      maw30: 0.307,
      rs: 95.0,
      rsd1d: -1.0,
      rsd5d: -2.0,
      rsd10d: -1.0,
      price_5d_h: 0.43,
      price_5d_L: 0.345,
      price_1m_h: 0.45,
      price_1m_l: 0.3,
      price_3m_h: 0.45,
      price_3m_l: 0.3,
      price_6m_h: 0.5,
      price_6m_l: 0.218,
      price_52w_h: 0.5,
      price_52w_l: 0.13,
      price_pc_1d: -3.947,
      price_pc_1w: -15.116,
      price_pc_1m: 8.955,
      price_pc_6m: 30.357,
      price_pc_1y: 180.769,
      price_pc_ytd: 180.769,
    },
    1530: {
      id: "1530",
      c: 6.4,
      ma50: 6.155,
      ma100: 6.44,
      ma150: 6.881,
      ma200: 7.243,
      maw30: 6.852,
      rs: 52.0,
      rsd1d: 0.0,
      rsd5d: 14.0,
      rsd10d: 20.0,
      price_5d_h: 6.48,
      price_5d_L: 5.76,
      price_1m_h: 6.48,
      price_1m_l: 4.72,
      price_3m_h: 6.9,
      price_3m_l: 4.72,
      price_6m_h: 7.85,
      price_6m_l: 4.72,
      price_52w_h: 12.3,
      price_52w_l: 4.72,
      price_pc_1d: 0.0,
      price_pc_1w: 0.946,
      price_pc_1m: 3.226,
      price_pc_6m: -16.34,
      price_pc_1y: -8.571,
      price_pc_ytd: -8.571,
    },
    1532: {
      id: "1532",
      c: 0.134,
      ma50: 0.169,
      ma100: 0.186,
      ma150: 0.197,
      ma200: 0.198,
      maw30: 0.2,
      rs: 16.0,
      rsd1d: 2.0,
      rsd5d: 2.0,
      rsd10d: -14.0,
      price_5d_h: 0.141,
      price_5d_L: 0.133,
      price_1m_h: 0.18,
      price_1m_l: 0.133,
      price_3m_h: 0.2,
      price_3m_l: 0.133,
      price_6m_h: 0.244,
      price_6m_l: 0.133,
      price_52w_h: 0.35,
      price_52w_l: 0.133,
      price_pc_1d: 0.752,
      price_pc_1w: -4.965,
      price_pc_1m: -19.76,
      price_pc_6m: -37.383,
      price_pc_1y: -52.143,
      price_pc_ytd: -52.143,
    },
    1533: {
      id: "1533",
      c: 9.5,
      ma50: 9.542,
      ma100: 8.892,
      ma150: 8.096,
      ma200: 7.647,
      maw30: 8.209,
      rs: 93.0,
      rsd1d: 0.0,
      rsd5d: 2.0,
      rsd10d: 0.0,
      price_5d_h: 9.8,
      price_5d_L: 9.2,
      price_1m_h: 10.5,
      price_1m_l: 8.5,
      price_3m_h: 10.5,
      price_3m_l: 8.27,
      price_6m_h: 10.94,
      price_6m_l: 5.97,
      price_52w_h: 10.94,
      price_52w_l: 5.83,
      price_pc_1d: 0.849,
      price_pc_1w: 2.592,
      price_pc_1m: 0.211,
      price_pc_6m: 47.287,
      price_pc_1y: 62.671,
      price_pc_ytd: 62.671,
    },
    1536: {
      id: "1536",
      c: 0.128,
      ma50: 0.147,
      ma100: 0.167,
      ma150: 0.18,
      ma200: 0.189,
      maw30: 0.179,
      rs: 15.0,
      rsd1d: -2.0,
      rsd5d: -8.0,
      rsd10d: -7.0,
      price_5d_h: 0.138,
      price_5d_L: 0.126,
      price_1m_h: 0.153,
      price_1m_l: 0.112,
      price_3m_h: 0.18,
      price_3m_l: 0.112,
      price_6m_h: 0.235,
      price_6m_l: 0.112,
      price_52w_h: 0.255,
      price_52w_l: 0.112,
      price_pc_1d: -7.246,
      price_pc_1w: -9.22,
      price_pc_1m: -19.497,
      price_pc_6m: -36.0,
      price_pc_1y: -48.8,
      price_pc_ytd: -48.8,
    },
    1538: {
      id: "1538",
      c: 0.52,
      ma50: 0.591,
      ma100: 0.604,
      ma150: 0.639,
      ma200: 0.662,
      maw30: 0.641,
      rs: 26.0,
      rsd1d: 3.0,
      rsd5d: -4.0,
      rsd10d: -14.0,
      price_5d_h: 0.55,
      price_5d_L: 0.495,
      price_1m_h: 0.63,
      price_1m_l: 0.495,
      price_3m_h: 0.65,
      price_3m_l: 0.495,
      price_6m_h: 0.8,
      price_6m_l: 0.495,
      price_52w_h: 0.88,
      price_52w_l: 0.495,
      price_pc_1d: 0.0,
      price_pc_1w: -5.455,
      price_pc_1m: -16.129,
      price_pc_6m: -27.778,
      price_pc_1y: -39.535,
      price_pc_ytd: -39.535,
    },
    1539: {
      id: "1539",
      c: 0.35,
      ma50: 0.597,
      ma100: 0.634,
      ma150: 0.568,
      ma200: 0.551,
      maw30: 0.573,
      rs: 81.0,
      rsd1d: 0.0,
      rsd5d: -9.0,
      rsd10d: -10.0,
      price_5d_h: 0.385,
      price_5d_L: 0.34,
      price_1m_h: 0.68,
      price_1m_l: 0.28,
      price_3m_h: 0.83,
      price_3m_l: 0.28,
      price_6m_h: 0.83,
      price_6m_l: 0.28,
      price_52w_h: 0.83,
      price_52w_l: 0.116,
      price_pc_1d: 1.449,
      price_pc_1w: -10.256,
      price_pc_1m: -47.761,
      price_pc_6m: -18.605,
      price_pc_1y: 196.61,
      price_pc_ytd: 196.61,
    },
    1540: {
      id: "1540",
      c: 0.51,
      ma50: 0.575,
      ma100: 0.614,
      ma150: 0.603,
      ma200: 0.589,
      maw30: 0.606,
      rs: 46.0,
      rsd1d: 0.0,
      rsd5d: -5.0,
      rsd10d: 4.0,
      price_5d_h: 0.51,
      price_5d_L: 0.455,
      price_1m_h: 0.61,
      price_1m_l: 0.455,
      price_3m_h: 0.67,
      price_3m_l: 0.455,
      price_6m_h: 0.75,
      price_6m_l: 0.455,
      price_52w_h: 0.75,
      price_52w_l: 0.455,
      price_pc_1d: 0.0,
      price_pc_1w: -3.774,
      price_pc_1m: -15.0,
      price_pc_6m: -13.559,
      price_pc_1y: -1.923,
      price_pc_ytd: -1.923,
    },
    1543: {
      id: "1543",
      c: 1.99,
      ma50: 1.967,
      ma100: 1.892,
      ma150: 1.891,
      ma200: 1.89,
      maw30: 1.88,
      rs: 86.0,
      rsd1d: -1.0,
      rsd5d: -3.0,
      rsd10d: -6.0,
      price_5d_h: 2.1,
      price_5d_L: 1.91,
      price_1m_h: 2.15,
      price_1m_l: 1.85,
      price_3m_h: 2.15,
      price_3m_l: 1.75,
      price_6m_h: 2.15,
      price_6m_l: 1.6,
      price_52w_h: 2.15,
      price_52w_l: 1.49,
      price_pc_1d: 1.531,
      price_pc_1w: 1.015,
      price_pc_1m: 2.051,
      price_pc_6m: 7.568,
      price_pc_1y: 11.173,
      price_pc_ytd: 11.173,
    },
    1545: {
      id: "1545",
      c: 0.108,
      ma50: 0.116,
      ma100: 0.124,
      ma150: 0.131,
      ma200: 0.138,
      maw30: 0.131,
      rs: 30.0,
      rsd1d: 2.0,
      rsd5d: -7.0,
      rsd10d: -1.0,
      price_5d_h: 0.11,
      price_5d_L: 0.105,
      price_1m_h: 0.115,
      price_1m_l: 0.087,
      price_3m_h: 0.137,
      price_3m_l: 0.087,
      price_6m_h: 0.155,
      price_6m_l: 0.087,
      price_52w_h: 0.215,
      price_52w_l: 0.087,
      price_pc_1d: 0.0,
      price_pc_1w: -4.425,
      price_pc_1m: -4.425,
      price_pc_6m: -26.531,
      price_pc_1y: -25.517,
      price_pc_ytd: -25.517,
    },
    1547: {
      id: "1547",
      c: 0.28,
      ma50: 0.284,
      ma100: 0.287,
      ma150: 0.288,
      ma200: 0.296,
      maw30: 0.288,
      rs: 71.0,
      rsd1d: 4.0,
      rsd5d: 14.0,
      rsd10d: -3.0,
      price_5d_h: 0.28,
      price_5d_L: 0.27,
      price_1m_h: 0.29,
      price_1m_l: 0.25,
      price_3m_h: 0.33,
      price_3m_l: 0.25,
      price_6m_h: 0.33,
      price_6m_l: 0.235,
      price_52w_h: 0.35,
      price_52w_l: 0.235,
      price_pc_1d: 1.818,
      price_pc_1w: 5.66,
      price_pc_1m: 0.0,
      price_pc_6m: 7.692,
      price_pc_1y: 9.804,
      price_pc_ytd: 9.804,
    },
    1548: {
      id: "1548",
      c: 25.1,
      ma50: 26.986,
      ma100: 30.985,
      ma150: 31.539,
      ma200: 32.527,
      maw30: 31.457,
      rs: 69.0,
      rsd1d: -11.0,
      rsd5d: -13.0,
      rsd10d: -7.0,
      price_5d_h: 28.05,
      price_5d_L: 24.05,
      price_1m_h: 33.55,
      price_1m_l: 19.0,
      price_3m_h: 34.45,
      price_3m_l: 19.0,
      price_6m_h: 42.5,
      price_6m_l: 19.0,
      price_52w_h: 43.4,
      price_52w_l: 14.64,
      price_pc_1d: -4.015,
      price_pc_1w: -9.874,
      price_pc_1m: -18.639,
      price_pc_6m: -16.333,
      price_pc_1y: 67.333,
      price_pc_ytd: 67.333,
    },
    1549: {
      id: "1549",
      c: 0.136,
      ma50: 0.156,
      ma100: 0.152,
      ma150: 0.184,
      ma200: 0.21,
      maw30: 0.18,
      rs: 28.0,
      rsd1d: 2.0,
      rsd5d: -10.0,
      rsd10d: -2.0,
      price_5d_h: 0.154,
      price_5d_L: 0.135,
      price_1m_h: 0.169,
      price_1m_l: 0.118,
      price_3m_h: 0.205,
      price_3m_l: 0.118,
      price_6m_h: 0.26,
      price_6m_l: 0.102,
      price_52w_h: 0.51,
      price_52w_l: 0.102,
      price_pc_1d: -0.73,
      price_pc_1w: -4.895,
      price_pc_1m: -12.258,
      price_pc_6m: -41.88,
      price_pc_1y: -30.256,
      price_pc_ytd: -30.256,
    },
    1551: {
      id: "1551",
      c: 2.87,
      ma50: 2.968,
      ma100: 3.02,
      ma150: 3.014,
      ma200: 3.034,
      maw30: 3.016,
      rs: 52.0,
      rsd1d: -5.0,
      rsd5d: -5.0,
      rsd10d: -2.0,
      price_5d_h: 2.98,
      price_5d_L: 2.83,
      price_1m_h: 3.0,
      price_1m_l: 2.8,
      price_3m_h: 3.21,
      price_3m_l: 2.8,
      price_6m_h: 3.45,
      price_6m_l: 2.76,
      price_52w_h: 3.53,
      price_52w_l: 2.64,
      price_pc_1d: -3.691,
      price_pc_1w: -1.034,
      price_pc_1m: -3.367,
      price_pc_6m: -4.333,
      price_pc_1y: -13.293,
      price_pc_ytd: -13.293,
    },
    1555: {
      id: "1555",
      c: 0.061,
      ma50: 0.057,
      ma100: 0.059,
      ma150: 0.06,
      ma200: 0.057,
      maw30: 0.061,
      rs: 78.0,
      rsd1d: -1.0,
      rsd5d: 35.0,
      rsd10d: 49.0,
      price_5d_h: 0.069,
      price_5d_L: 0.056,
      price_1m_h: 0.072,
      price_1m_l: 0.045,
      price_3m_h: 0.072,
      price_3m_l: 0.045,
      price_6m_h: 0.083,
      price_6m_l: 0.045,
      price_52w_h: 0.108,
      price_52w_l: 0.04,
      price_pc_1d: -1.613,
      price_pc_1w: 12.963,
      price_pc_1m: 8.929,
      price_pc_6m: 1.667,
      price_pc_1y: -35.106,
      price_pc_ytd: -35.106,
    },
    1557: {
      id: "1557",
      c: 0.57,
      ma50: 0.469,
      ma100: 0.476,
      ma150: 0.507,
      ma200: 0.573,
      maw30: 0.505,
      rs: 79.0,
      rsd1d: 57.0,
      rsd5d: 43.0,
      rsd10d: 37.0,
      price_5d_h: 0.57,
      price_5d_L: 0.38,
      price_1m_h: 0.57,
      price_1m_l: 0.38,
      price_3m_h: 0.76,
      price_3m_l: 0.38,
      price_6m_h: 0.76,
      price_6m_l: 0.38,
      price_52w_h: 1.02,
      price_52w_l: 0.38,
      price_pc_1d: 42.5,
      price_pc_1w: 26.667,
      price_pc_1m: 22.581,
      price_pc_6m: 14.0,
      price_pc_1y: 0.0,
      price_pc_ytd: 0.0,
    },
    1558: {
      id: "1558",
      c: 4.84,
      ma50: 6.243,
      ma100: 5.96,
      ma150: 5.478,
      ma200: 5.822,
      maw30: 5.541,
      rs: 29.0,
      rsd1d: -3.0,
      rsd5d: -12.0,
      rsd10d: -27.0,
      price_5d_h: 5.24,
      price_5d_L: 4.8,
      price_1m_h: 6.3,
      price_1m_l: 4.25,
      price_3m_h: 7.96,
      price_3m_l: 4.25,
      price_6m_h: 7.96,
      price_6m_l: 3.35,
      price_52w_h: 9.18,
      price_52w_l: 3.35,
      price_pc_1d: -2.811,
      price_pc_1w: -7.81,
      price_pc_1m: -23.296,
      price_pc_6m: 5.217,
      price_pc_1y: -43.126,
      price_pc_ytd: -43.126,
    },
    1560: {
      id: "1560",
      c: 0.32,
      ma50: 0.368,
      ma100: 0.383,
      ma150: 0.38,
      ma200: 0.393,
      maw30: 0.38,
      rs: 33.0,
      rsd1d: -8.0,
      rsd5d: -4.0,
      rsd10d: -2.0,
      price_5d_h: 0.35,
      price_5d_L: 0.32,
      price_1m_h: 0.38,
      price_1m_l: 0.31,
      price_3m_h: 0.435,
      price_3m_l: 0.31,
      price_6m_h: 0.5,
      price_6m_l: 0.31,
      price_52w_h: 0.5,
      price_52w_l: 0.31,
      price_pc_1d: -5.882,
      price_pc_1w: -4.478,
      price_pc_1m: -12.329,
      price_pc_6m: -9.859,
      price_pc_1y: -27.273,
      price_pc_ytd: -27.273,
    },
    1561: {
      id: "1561",
      c: 1.92,
      ma50: 1.993,
      ma100: 1.962,
      ma150: 2.05,
      ma200: 2.072,
      maw30: 2.051,
      rs: 64.0,
      rsd1d: 7.0,
      rsd5d: -4.0,
      rsd10d: 2.0,
      price_5d_h: 2.03,
      price_5d_L: 1.85,
      price_1m_h: 2.08,
      price_1m_l: 1.84,
      price_3m_h: 2.2,
      price_3m_l: 1.79,
      price_6m_h: 2.79,
      price_6m_l: 1.76,
      price_52w_h: 2.98,
      price_52w_l: 1.69,
      price_pc_1d: 2.128,
      price_pc_1w: -2.041,
      price_pc_1m: -5.882,
      price_pc_6m: -26.154,
      price_pc_1y: -13.901,
      price_pc_ytd: -13.901,
    },
    1563: {
      id: "1563",
      c: 3.29,
      ma50: 2.952,
      ma100: 2.706,
      ma150: 2.642,
      ma200: 2.65,
      maw30: 2.641,
      rs: 96.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -1.0,
      price_5d_h: 3.4,
      price_5d_L: 3.2,
      price_1m_h: 3.53,
      price_1m_l: 3.09,
      price_3m_h: 3.53,
      price_3m_l: 2.4,
      price_6m_h: 3.53,
      price_6m_l: 2.15,
      price_52w_h: 3.53,
      price_52w_l: 0.81,
      price_pc_1d: 0.0,
      price_pc_1w: 0.305,
      price_pc_1m: 0.305,
      price_pc_6m: 27.519,
      price_pc_1y: 232.323,
      price_pc_ytd: 232.323,
    },
    1566: {
      id: "1566",
      c: 0.138,
      ma50: 0.191,
      ma100: 0.358,
      ma150: 0.856,
      ma200: 1.348,
      maw30: 0.826,
      rs: 1.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.146,
      price_5d_L: 0.132,
      price_1m_h: 0.168,
      price_1m_l: 0.118,
      price_3m_h: 0.47,
      price_3m_l: 0.118,
      price_6m_h: 2.74,
      price_6m_l: 0.118,
      price_52w_h: 3.43,
      price_52w_l: 0.118,
      price_pc_1d: 0.0,
      price_pc_1w: -1.429,
      price_pc_1m: -15.337,
      price_pc_6m: -94.926,
      price_pc_1y: -94.792,
      price_pc_ytd: -94.792,
    },
    1568: {
      id: "1568",
      c: 0.41,
      ma50: 0.38,
      ma100: 0.542,
      ma150: 0.759,
      ma200: 0.94,
      maw30: 0.74,
      rs: 4.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: 2.0,
      price_5d_h: 0.455,
      price_5d_L: 0.395,
      price_1m_h: 0.53,
      price_1m_l: 0.28,
      price_3m_h: 0.7,
      price_3m_l: 0.28,
      price_6m_h: 1.22,
      price_6m_l: 0.28,
      price_52w_h: 2.17,
      price_52w_l: 0.28,
      price_pc_1d: 2.5,
      price_pc_1w: 1.235,
      price_pc_1m: 32.258,
      price_pc_6m: -66.393,
      price_pc_1y: -76.966,
      price_pc_ytd: -76.966,
    },
    1569: {
      id: "1569",
      c: 0.82,
      ma50: 0.836,
      ma100: 0.919,
      ma150: 0.997,
      ma200: 1.067,
      maw30: 0.999,
      rs: 26.0,
      rsd1d: 6.0,
      rsd5d: 2.0,
      rsd10d: 3.0,
      price_5d_h: 0.84,
      price_5d_L: 0.76,
      price_1m_h: 0.94,
      price_1m_l: 0.6,
      price_3m_h: 1.02,
      price_3m_l: 0.55,
      price_6m_h: 1.3,
      price_6m_l: 0.55,
      price_52w_h: 1.57,
      price_52w_l: 0.55,
      price_pc_1d: 5.128,
      price_pc_1w: 0.0,
      price_pc_1m: -2.381,
      price_pc_6m: -27.434,
      price_pc_1y: -42.657,
      price_pc_ytd: -42.657,
    },
    1571: {
      id: "1571",
      c: 2.17,
      ma50: 2.369,
      ma100: 2.647,
      ma150: 2.771,
      ma200: 2.982,
      maw30: 2.764,
      rs: 19.0,
      rsd1d: 6.0,
      rsd5d: 8.0,
      rsd10d: 9.0,
      price_5d_h: 2.2,
      price_5d_L: 2.1,
      price_1m_h: 2.62,
      price_1m_l: 1.55,
      price_3m_h: 2.86,
      price_3m_l: 1.55,
      price_6m_h: 3.58,
      price_6m_l: 1.55,
      price_52w_h: 5.7,
      price_52w_l: 1.55,
      price_pc_1d: 0.93,
      price_pc_1w: 8.5,
      price_pc_1m: -13.546,
      price_pc_6m: -26.19,
      price_pc_1y: -61.93,
      price_pc_ytd: -61.93,
    },
    1572: {
      id: "1572",
      c: 0.195,
      ma50: 0.211,
      ma100: 0.248,
      ma150: 0.273,
      ma200: 0.262,
      maw30: 0.274,
      rs: 53.0,
      rsd1d: 5.0,
      rsd5d: -3.0,
      rsd10d: -7.0,
      price_5d_h: 0.196,
      price_5d_L: 0.181,
      price_1m_h: 0.205,
      price_1m_l: 0.17,
      price_3m_h: 0.285,
      price_3m_l: 0.17,
      price_6m_h: 0.395,
      price_6m_l: 0.17,
      price_52w_h: 0.5,
      price_52w_l: 0.141,
      price_pc_1d: 2.094,
      price_pc_1w: -1.515,
      price_pc_1m: -2.01,
      price_pc_6m: -38.095,
      price_pc_1y: 30.0,
      price_pc_ytd: 30.0,
    },
    1575: {
      id: "1575",
      c: 0.137,
      ma50: 0.094,
      ma100: 0.13,
      ma150: 0.148,
      ma200: 0.156,
      maw30: 0.148,
      rs: 51.0,
      rsd1d: 17.0,
      rsd5d: 38.0,
      rsd10d: 45.0,
      price_5d_h: 0.14,
      price_5d_L: 0.096,
      price_1m_h: 0.14,
      price_1m_l: 0.07,
      price_3m_h: 0.265,
      price_3m_l: 0.07,
      price_6m_h: 0.265,
      price_6m_l: 0.07,
      price_52w_h: 0.35,
      price_52w_l: 0.07,
      price_pc_1d: 10.484,
      price_pc_1w: 30.476,
      price_pc_1m: 55.682,
      price_pc_6m: -19.883,
      price_pc_1y: -1.439,
      price_pc_ytd: -1.439,
    },
    1576: {
      id: "1576",
      c: 2.79,
      ma50: 2.88,
      ma100: 2.727,
      ma150: 2.578,
      ma200: 2.477,
      maw30: 2.594,
      rs: 84.0,
      rsd1d: -3.0,
      rsd5d: -5.0,
      rsd10d: -8.0,
      price_5d_h: 2.9,
      price_5d_L: 2.53,
      price_1m_h: 3.14,
      price_1m_l: 2.53,
      price_3m_h: 3.15,
      price_3m_l: 2.53,
      price_6m_h: 3.2,
      price_6m_l: 2.25,
      price_52w_h: 3.2,
      price_52w_l: 2.06,
      price_pc_1d: -0.712,
      price_pc_1w: -2.105,
      price_pc_1m: -6.689,
      price_pc_6m: 22.368,
      price_pc_1y: 18.723,
      price_pc_ytd: 18.723,
    },
    1578: {
      id: "1578",
      c: 2.04,
      ma50: 1.954,
      ma100: 2.408,
      ma150: 2.715,
      ma200: 2.911,
      maw30: 2.71,
      rs: 25.0,
      rsd1d: -1.0,
      rsd5d: 11.0,
      rsd10d: 9.0,
      price_5d_h: 2.05,
      price_5d_L: 1.82,
      price_1m_h: 2.05,
      price_1m_l: 1.61,
      price_3m_h: 2.24,
      price_3m_l: 1.61,
      price_6m_h: 3.57,
      price_6m_l: 1.61,
      price_52w_h: 3.66,
      price_52w_l: 1.61,
      price_pc_1d: -0.488,
      price_pc_1w: 2.513,
      price_pc_1m: 7.368,
      price_pc_6m: -40.351,
      price_pc_1y: -41.379,
      price_pc_ytd: -41.379,
    },
    1579: {
      id: "1579",
      c: 22.5,
      ma50: 29.978,
      ma100: 35.738,
      ma150: 38.433,
      ma200: 41.425,
      maw30: 39.01,
      rs: 8.0,
      rsd1d: 1.0,
      rsd5d: 3.0,
      rsd10d: 0.0,
      price_5d_h: 23.35,
      price_5d_L: 19.6,
      price_1m_h: 33.95,
      price_1m_l: 19.32,
      price_3m_h: 39.2,
      price_3m_l: 19.32,
      price_6m_h: 53.5,
      price_6m_l: 19.32,
      price_52w_h: 86.15,
      price_52w_l: 19.32,
      price_pc_1d: -2.174,
      price_pc_1w: 3.687,
      price_pc_1m: -30.982,
      price_pc_6m: -48.097,
      price_pc_1y: -73.822,
      price_pc_ytd: -73.822,
    },
    1581: {
      id: "1581",
      c: 0.086,
      ma50: 0.111,
      ma100: 0.128,
      ma150: 0.136,
      ma200: 0.129,
      maw30: 0.139,
      rs: 28.0,
      rsd1d: 5.0,
      rsd5d: 5.0,
      rsd10d: 7.0,
      price_5d_h: 0.09,
      price_5d_L: 0.085,
      price_1m_h: 0.117,
      price_1m_l: 0.076,
      price_3m_h: 0.14,
      price_3m_l: 0.076,
      price_6m_h: 0.175,
      price_6m_l: 0.076,
      price_52w_h: 0.179,
      price_52w_l: 0.076,
      price_pc_1d: 1.176,
      price_pc_1w: -4.444,
      price_pc_1m: -24.561,
      price_pc_6m: -42.667,
      price_pc_1y: -9.474,
      price_pc_ytd: -9.474,
    },
    1585: {
      id: "1585",
      c: 12.12,
      ma50: 10.86,
      ma100: 12.489,
      ma150: 12.72,
      ma200: 13.408,
      maw30: 12.689,
      rs: 35.0,
      rsd1d: -1.0,
      rsd5d: 13.0,
      rsd10d: 18.0,
      price_5d_h: 12.8,
      price_5d_L: 9.92,
      price_1m_h: 12.8,
      price_1m_l: 7.59,
      price_3m_h: 15.3,
      price_3m_l: 7.59,
      price_6m_h: 16.36,
      price_6m_l: 7.59,
      price_52w_h: 19.96,
      price_52w_l: 7.59,
      price_pc_1d: -1.783,
      price_pc_1w: 14.773,
      price_pc_1m: 5.026,
      price_pc_6m: -4.717,
      price_pc_1y: -37.718,
      price_pc_ytd: -37.718,
    },
    1586: {
      id: "1586",
      c: 1.45,
      ma50: 1.557,
      ma100: 1.645,
      ma150: 1.735,
      ma200: 1.807,
      maw30: 1.726,
      rs: 44.0,
      rsd1d: 2.0,
      rsd5d: 0.0,
      rsd10d: -18.0,
      price_5d_h: 1.45,
      price_5d_L: 1.38,
      price_1m_h: 1.58,
      price_1m_l: 1.38,
      price_3m_h: 1.82,
      price_3m_l: 1.38,
      price_6m_h: 1.95,
      price_6m_l: 1.38,
      price_52w_h: 2.49,
      price_52w_l: 1.182,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: -8.228,
      price_pc_6m: -24.479,
      price_pc_1y: 13.928,
      price_pc_ytd: 13.928,
    },
    1587: {
      id: "1587",
      c: 0.62,
      ma50: 0.816,
      ma100: 0.771,
      ma150: 0.704,
      ma200: 0.631,
      maw30: 0.717,
      rs: 90.0,
      rsd1d: 0.0,
      rsd5d: -4.0,
      rsd10d: -6.0,
      price_5d_h: 0.74,
      price_5d_L: 0.61,
      price_1m_h: 0.9,
      price_1m_l: 0.61,
      price_3m_h: 1.06,
      price_3m_l: 0.61,
      price_6m_h: 1.06,
      price_6m_l: 0.53,
      price_52w_h: 1.06,
      price_52w_l: 0.345,
      price_pc_1d: -1.587,
      price_pc_1w: -18.421,
      price_pc_1m: -31.111,
      price_pc_6m: 10.714,
      price_pc_1y: 42.529,
      price_pc_ytd: 42.529,
    },
    1588: {
      id: "1588",
      c: 7.8,
      ma50: 7.246,
      ma100: 8.258,
      ma150: 9.127,
      ma200: 9.937,
      maw30: 9.036,
      rs: 24.0,
      rsd1d: 2.0,
      rsd5d: 0.0,
      rsd10d: 6.0,
      price_5d_h: 7.91,
      price_5d_L: 7.53,
      price_1m_h: 8.0,
      price_1m_l: 5.26,
      price_3m_h: 9.3,
      price_3m_l: 5.26,
      price_6m_h: 12.4,
      price_6m_l: 5.26,
      price_52w_h: 17.033,
      price_52w_l: 5.26,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: 18.361,
      price_pc_6m: -21.844,
      price_pc_1y: -52.439,
      price_pc_ytd: -52.439,
    },
    1589: {
      id: "1589",
      c: 4.34,
      ma50: 4.327,
      ma100: 4.296,
      ma150: 4.247,
      ma200: 4.051,
      maw30: 4.255,
      rs: 81.0,
      rsd1d: -1.0,
      rsd5d: 3.0,
      rsd10d: -4.0,
      price_5d_h: 4.4,
      price_5d_L: 4.32,
      price_1m_h: 4.4,
      price_1m_l: 4.15,
      price_3m_h: 4.5,
      price_3m_l: 3.72,
      price_6m_h: 4.5,
      price_6m_l: 3.72,
      price_52w_h: 4.59,
      price_52w_l: 3.17,
      price_pc_1d: -0.23,
      price_pc_1w: 3.088,
      price_pc_1m: 0.231,
      price_pc_6m: 3.828,
      price_pc_1y: -3.982,
      price_pc_ytd: -3.982,
    },
    1591: {
      id: "1591",
      c: 0.019,
      ma50: 0.022,
      ma100: 0.024,
      ma150: 0.028,
      ma200: 0.033,
      maw30: 0.028,
      rs: 20.0,
      rsd1d: -2.0,
      rsd5d: 3.0,
      rsd10d: 2.0,
      price_5d_h: 0.02,
      price_5d_L: 0.018,
      price_1m_h: 0.022,
      price_1m_l: 0.018,
      price_3m_h: 0.031,
      price_3m_l: 0.018,
      price_6m_h: 0.04,
      price_6m_l: 0.018,
      price_52w_h: 0.132,
      price_52w_l: 0.018,
      price_pc_1d: -5.0,
      price_pc_1w: 0.0,
      price_pc_1m: -13.636,
      price_pc_6m: -50.0,
      price_pc_1y: -13.636,
      price_pc_ytd: -13.636,
    },
    1592: {
      id: "1592",
      c: 0.26,
      ma50: 0.361,
      ma100: 0.831,
      ma150: 1.187,
      ma200: 1.334,
      maw30: 1.206,
      rs: 2.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: 0.0,
      price_5d_h: 0.27,
      price_5d_L: 0.255,
      price_1m_h: 0.345,
      price_1m_l: 0.25,
      price_3m_h: 1.18,
      price_3m_l: 0.25,
      price_6m_h: 2.26,
      price_6m_l: 0.25,
      price_52w_h: 2.87,
      price_52w_l: 0.25,
      price_pc_1d: 0.0,
      price_pc_1w: -1.887,
      price_pc_1m: -7.143,
      price_pc_6m: -87.317,
      price_pc_1y: -74.0,
      price_pc_ytd: -74.0,
    },
    1593: {
      id: "1593",
      c: 2.16,
      ma50: 2.116,
      ma100: 2.178,
      ma150: 2.212,
      ma200: 2.3,
      maw30: 2.202,
      rs: 51.0,
      rsd1d: -1.0,
      rsd5d: 7.0,
      rsd10d: 7.0,
      price_5d_h: 2.22,
      price_5d_L: 2.13,
      price_1m_h: 2.28,
      price_1m_l: 1.9,
      price_3m_h: 2.48,
      price_3m_l: 1.9,
      price_6m_h: 2.52,
      price_6m_l: 1.9,
      price_52w_h: 2.88,
      price_52w_l: 1.9,
      price_pc_1d: 0.0,
      price_pc_1w: 1.408,
      price_pc_1m: 2.857,
      price_pc_6m: -1.818,
      price_pc_1y: -19.703,
      price_pc_ytd: -19.703,
    },
    1596: {
      id: "1596",
      c: 3.9,
      ma50: 4.311,
      ma100: 4.42,
      ma150: 4.399,
      ma200: 4.337,
      maw30: 4.424,
      rs: 50.0,
      rsd1d: -7.0,
      rsd5d: -11.0,
      rsd10d: -15.0,
      price_5d_h: 4.05,
      price_5d_L: 3.78,
      price_1m_h: 4.34,
      price_1m_l: 3.78,
      price_3m_h: 5.3,
      price_3m_l: 3.78,
      price_6m_h: 5.45,
      price_6m_l: 3.78,
      price_52w_h: 5.45,
      price_52w_l: 3.61,
      price_pc_1d: -1.266,
      price_pc_1w: -3.226,
      price_pc_1m: -7.583,
      price_pc_6m: -6.475,
      price_pc_1y: -0.763,
      price_pc_ytd: -0.763,
    },
    1597: {
      id: "1597",
      c: 2.0,
      ma50: 1.979,
      ma100: 1.987,
      ma150: 1.975,
      ma200: 1.885,
      maw30: 1.98,
      rs: 85.0,
      rsd1d: 0.0,
      rsd5d: 2.0,
      rsd10d: -5.0,
      price_5d_h: 2.01,
      price_5d_L: 1.91,
      price_1m_h: 2.02,
      price_1m_l: 1.91,
      price_3m_h: 2.03,
      price_3m_l: 1.88,
      price_6m_h: 2.5,
      price_6m_l: 1.88,
      price_52w_h: 2.5,
      price_52w_l: 1.31,
      price_pc_1d: 1.01,
      price_pc_1w: -0.498,
      price_pc_1m: 0.0,
      price_pc_6m: -0.99,
      price_pc_1y: 22.699,
      price_pc_ytd: 22.699,
    },
    1598: {
      id: "1598",
      c: 0.425,
      ma50: 0.494,
      ma100: 0.473,
      ma150: 0.486,
      ma200: 0.503,
      maw30: 0.49,
      rs: 32.0,
      rsd1d: 3.0,
      rsd5d: -26.0,
      rsd10d: -38.0,
      price_5d_h: 0.445,
      price_5d_L: 0.405,
      price_1m_h: 0.53,
      price_1m_l: 0.405,
      price_3m_h: 0.6,
      price_3m_l: 0.405,
      price_6m_h: 0.6,
      price_6m_l: 0.33,
      price_52w_h: 0.73,
      price_52w_l: 0.28,
      price_pc_1d: 2.41,
      price_pc_1w: -4.494,
      price_pc_1m: -12.371,
      price_pc_6m: -21.296,
      price_pc_1y: -39.286,
      price_pc_ytd: -39.286,
    },
    1599: {
      id: "1599",
      c: 2.04,
      ma50: 2.13,
      ma100: 2.09,
      ma150: 2.149,
      ma200: 2.115,
      maw30: 2.163,
      rs: 68.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 2.13,
      price_5d_L: 2.03,
      price_1m_h: 2.26,
      price_1m_l: 1.78,
      price_3m_h: 2.36,
      price_3m_l: 1.78,
      price_6m_h: 2.49,
      price_6m_l: 1.78,
      price_52w_h: 2.61,
      price_52w_l: 1.78,
      price_pc_1d: -2.392,
      price_pc_1w: -1.449,
      price_pc_1m: -6.422,
      price_pc_6m: -14.644,
      price_pc_1y: -1.923,
      price_pc_ytd: -1.923,
    },
    1600: {
      id: "1600",
      c: 5.56,
      ma50: 7.398,
      ma100: 7.602,
      ma150: 7.501,
      ma200: 7.606,
      maw30: 7.583,
      rs: 23.0,
      rsd1d: 2.0,
      rsd5d: -5.0,
      rsd10d: 0.0,
      price_5d_h: 6.01,
      price_5d_L: 5.41,
      price_1m_h: 6.54,
      price_1m_l: 4.62,
      price_3m_h: 9.77,
      price_3m_l: 4.62,
      price_6m_h: 9.77,
      price_6m_l: 4.62,
      price_52w_h: 9.77,
      price_52w_l: 4.62,
      price_pc_1d: -0.358,
      price_pc_1w: -7.023,
      price_pc_1m: -13.665,
      price_pc_6m: -20.115,
      price_pc_1y: -28.072,
      price_pc_ytd: -28.072,
    },
    1601: {
      id: "1601",
      c: 0.74,
      ma50: 0.729,
      ma100: 0.778,
      ma150: 0.835,
      ma200: 0.862,
      maw30: 0.834,
      rs: 37.0,
      rsd1d: 0.0,
      rsd5d: 12.0,
      rsd10d: 9.0,
      price_5d_h: 0.74,
      price_5d_L: 0.66,
      price_1m_h: 0.82,
      price_1m_l: 0.59,
      price_3m_h: 0.84,
      price_3m_l: 0.59,
      price_6m_h: 1.01,
      price_6m_l: 0.59,
      price_52w_h: 1.26,
      price_52w_l: 0.59,
      price_pc_1d: 1.37,
      price_pc_1w: 10.448,
      price_pc_1m: 7.246,
      price_pc_6m: -17.778,
      price_pc_1y: -32.727,
      price_pc_ytd: -32.727,
    },
    1606: {
      id: "1606",
      c: 1.15,
      ma50: 1.12,
      ma100: 1.103,
      ma150: 1.105,
      ma200: 1.107,
      maw30: 1.11,
      rs: 73.0,
      rsd1d: -4.0,
      rsd5d: 2.0,
      rsd10d: 1.0,
      price_5d_h: 1.17,
      price_5d_L: 1.09,
      price_1m_h: 1.17,
      price_1m_l: 1.01,
      price_3m_h: 1.17,
      price_3m_l: 1.01,
      price_6m_h: 1.18,
      price_6m_l: 1.01,
      price_52w_h: 1.29,
      price_52w_l: 1.01,
      price_pc_1d: 0.0,
      price_pc_1w: 4.545,
      price_pc_1m: 2.679,
      price_pc_6m: 3.604,
      price_pc_1y: 2.679,
      price_pc_ytd: 2.679,
    },
    1608: {
      id: "1608",
      c: 0.97,
      ma50: 1.143,
      ma100: 1.217,
      ma150: 1.348,
      ma200: 1.448,
      maw30: 1.347,
      rs: 16.0,
      rsd1d: 2.0,
      rsd5d: 2.0,
      rsd10d: 3.0,
      price_5d_h: 1.03,
      price_5d_L: 0.94,
      price_1m_h: 1.18,
      price_1m_l: 0.88,
      price_3m_h: 1.49,
      price_3m_l: 0.88,
      price_6m_h: 1.84,
      price_6m_l: 0.88,
      price_52w_h: 2.11,
      price_52w_l: 0.88,
      price_pc_1d: 0.0,
      price_pc_1w: -3.96,
      price_pc_1m: -16.379,
      price_pc_6m: -41.916,
      price_pc_1y: -47.568,
      price_pc_ytd: -47.568,
    },
    1609: {
      id: "1609",
      c: 0.63,
      ma50: 0.725,
      ma100: 0.714,
      ma150: 0.695,
      ma200: 0.77,
      maw30: 0.694,
      rs: 17.0,
      rsd1d: -2.0,
      rsd5d: -4.0,
      rsd10d: -9.0,
      price_5d_h: 0.64,
      price_5d_L: 0.58,
      price_1m_h: 0.74,
      price_1m_l: 0.5,
      price_3m_h: 1.14,
      price_3m_l: 0.49,
      price_6m_h: 1.14,
      price_6m_l: 0.48,
      price_52w_h: 8.95,
      price_52w_l: 0.475,
      price_pc_1d: -1.563,
      price_pc_1w: -3.077,
      price_pc_1m: -3.077,
      price_pc_6m: 6.78,
      price_pc_1y: -92.833,
      price_pc_ytd: -92.833,
    },
    1610: {
      id: "1610",
      c: 3.49,
      ma50: 3.341,
      ma100: 3.152,
      ma150: 2.973,
      ma200: 2.885,
      maw30: 3.012,
      rs: 88.0,
      rsd1d: -1.0,
      rsd5d: 2.0,
      rsd10d: 6.0,
      price_5d_h: 3.62,
      price_5d_L: 3.19,
      price_1m_h: 3.65,
      price_1m_l: 2.59,
      price_3m_h: 3.71,
      price_3m_l: 2.59,
      price_6m_h: 3.71,
      price_6m_l: 2.42,
      price_52w_h: 4.45,
      price_52w_l: 2.11,
      price_pc_1d: -1.412,
      price_pc_1w: 4.491,
      price_pc_1m: 2.647,
      price_pc_6m: 41.87,
      price_pc_1y: -19.026,
      price_pc_ytd: -19.026,
    },
    1611: {
      id: "1611",
      c: 6.4,
      ma50: 6.848,
      ma100: 7.507,
      ma150: 8.578,
      ma200: 9.448,
      maw30: 8.489,
      rs: 15.0,
      rsd1d: 0.0,
      rsd5d: 3.0,
      rsd10d: 7.0,
      price_5d_h: 6.9,
      price_5d_L: 6.1,
      price_1m_h: 7.34,
      price_1m_l: 4.71,
      price_3m_h: 8.61,
      price_3m_l: 4.71,
      price_6m_h: 11.88,
      price_6m_l: 4.71,
      price_52w_h: 27.35,
      price_52w_l: 4.71,
      price_pc_1d: -4.762,
      price_pc_1w: 3.226,
      price_pc_1m: -9.22,
      price_pc_6m: -21.472,
      price_pc_1y: -67.078,
      price_pc_ytd: -67.078,
    },
    1612: {
      id: "1612",
      c: 0.76,
      ma50: 0.831,
      ma100: 0.914,
      ma150: 0.952,
      ma200: 1.108,
      maw30: 0.945,
      rs: 16.0,
      rsd1d: 2.0,
      rsd5d: 4.0,
      rsd10d: 4.0,
      price_5d_h: 0.79,
      price_5d_L: 0.72,
      price_1m_h: 0.85,
      price_1m_l: 0.65,
      price_3m_h: 1.05,
      price_3m_l: 0.65,
      price_6m_h: 1.49,
      price_6m_l: 0.65,
      price_52w_h: 2.69,
      price_52w_l: 0.65,
      price_pc_1d: -2.564,
      price_pc_1w: 0.0,
      price_pc_1m: -7.317,
      price_pc_6m: -24.0,
      price_pc_1y: -53.086,
      price_pc_ytd: -53.086,
    },
    1613: {
      id: "1613",
      c: 0.184,
      ma50: 0.195,
      ma100: 0.195,
      ma150: 0.226,
      ma200: 0.25,
      maw30: 0.22,
      rs: 35.0,
      rsd1d: 4.0,
      rsd5d: 8.0,
      rsd10d: 3.0,
      price_5d_h: 0.189,
      price_5d_L: 0.178,
      price_1m_h: 0.2,
      price_1m_l: 0.17,
      price_3m_h: 0.224,
      price_3m_l: 0.17,
      price_6m_h: 0.25,
      price_6m_l: 0.165,
      price_52w_h: 0.8,
      price_52w_l: 0.165,
      price_pc_1d: 0.0,
      price_pc_1w: 2.222,
      price_pc_1m: -5.641,
      price_pc_6m: -13.208,
      price_pc_1y: -40.645,
      price_pc_ytd: -40.645,
    },
    1615: {
      id: "1615",
      c: 0.26,
      ma50: 0.262,
      ma100: 0.27,
      ma150: 0.278,
      ma200: 0.277,
      maw30: 0.277,
      rs: 80.0,
      rsd1d: 0.0,
      rsd5d: -2.0,
      rsd10d: 0.0,
      price_5d_h: 0.28,
      price_5d_L: 0.255,
      price_1m_h: 0.28,
      price_1m_l: 0.233,
      price_3m_h: 0.285,
      price_3m_l: 0.233,
      price_6m_h: 0.315,
      price_6m_l: 0.231,
      price_52w_h: 0.33,
      price_52w_l: 0.205,
      price_pc_1d: 0.0,
      price_pc_1w: -3.704,
      price_pc_1m: 6.122,
      price_pc_6m: -10.345,
      price_pc_1y: 30.0,
      price_pc_ytd: 30.0,
    },
    1616: {
      id: "1616",
      c: 0.152,
      ma50: 0.159,
      ma100: 0.164,
      ma150: 0.158,
      ma200: 0.155,
      maw30: 0.16,
      rs: 60.0,
      rsd1d: 17.0,
      rsd5d: 2.0,
      rsd10d: -17.0,
      price_5d_h: 0.159,
      price_5d_L: 0.141,
      price_1m_h: 0.17,
      price_1m_l: 0.122,
      price_3m_h: 0.189,
      price_3m_l: 0.122,
      price_6m_h: 0.218,
      price_6m_l: 0.12,
      price_52w_h: 0.218,
      price_52w_l: 0.1,
      price_pc_1d: 7.801,
      price_pc_1w: -4.403,
      price_pc_1m: -3.797,
      price_pc_6m: -5.0,
      price_pc_1y: -5.0,
      price_pc_ytd: -5.0,
    },
    1617: {
      id: "1617",
      c: 0.105,
      ma50: 0.114,
      ma100: 0.141,
      ma150: 0.158,
      ma200: 0.184,
      maw30: 0.154,
      rs: 11.0,
      rsd1d: 2.0,
      rsd5d: 4.0,
      rsd10d: 7.0,
      price_5d_h: 0.109,
      price_5d_L: 0.095,
      price_1m_h: 0.119,
      price_1m_l: 0.082,
      price_3m_h: 0.16,
      price_3m_l: 0.082,
      price_6m_h: 0.26,
      price_6m_l: 0.082,
      price_52w_h: 0.43,
      price_52w_l: 0.082,
      price_pc_1d: 1.942,
      price_pc_1w: -2.778,
      price_pc_1m: -1.869,
      price_pc_6m: -27.586,
      price_pc_1y: -72.0,
      price_pc_ytd: -72.0,
    },
    1618: {
      id: "1618",
      c: 2.12,
      ma50: 2.274,
      ma100: 2.171,
      ma150: 2.3,
      ma200: 2.241,
      maw30: 2.283,
      rs: 81.0,
      rsd1d: -5.0,
      rsd5d: -4.0,
      rsd10d: 1.0,
      price_5d_h: 2.28,
      price_5d_L: 2.06,
      price_1m_h: 2.73,
      price_1m_l: 1.81,
      price_3m_h: 2.74,
      price_3m_l: 1.81,
      price_6m_h: 2.74,
      price_6m_l: 1.81,
      price_52w_h: 3.42,
      price_52w_l: 1.71,
      price_pc_1d: -3.196,
      price_pc_1w: -3.196,
      price_pc_1m: -10.549,
      price_pc_6m: -11.667,
      price_pc_1y: 7.614,
      price_pc_ytd: 7.614,
    },
    1621: {
      id: "1621",
      c: 0.13,
      ma50: 0.126,
      ma100: 0.131,
      ma150: 0.133,
      ma200: 0.137,
      maw30: 0.133,
      rs: 78.0,
      rsd1d: 17.0,
      rsd5d: 14.0,
      rsd10d: 15.0,
      price_5d_h: 0.13,
      price_5d_L: 0.11,
      price_1m_h: 0.145,
      price_1m_l: 0.1,
      price_3m_h: 0.145,
      price_3m_l: 0.1,
      price_6m_h: 0.15,
      price_6m_l: 0.1,
      price_52w_h: 0.158,
      price_52w_l: 0.099,
      price_pc_1d: 8.333,
      price_pc_1w: 4.0,
      price_pc_1m: 1.563,
      price_pc_6m: -4.412,
      price_pc_1y: 31.313,
      price_pc_ytd: 31.313,
    },
    1622: {
      id: "1622",
      c: 2.43,
      ma50: 2.393,
      ma100: 2.425,
      ma150: 2.455,
      ma200: 2.446,
      maw30: 2.493,
      rs: 58.0,
      rsd1d: 2.0,
      rsd5d: -9.0,
      rsd10d: -18.0,
      price_5d_h: 2.56,
      price_5d_L: 2.3,
      price_1m_h: 2.6,
      price_1m_l: 1.95,
      price_3m_h: 2.98,
      price_3m_l: 1.95,
      price_6m_h: 3.03,
      price_6m_l: 1.95,
      price_52w_h: 3.16,
      price_52w_l: 1.76,
      price_pc_1d: 1.674,
      price_pc_1w: -2.016,
      price_pc_1m: 13.551,
      price_pc_6m: -16.207,
      price_pc_1y: -14.737,
      price_pc_ytd: -14.737,
    },
    1623: {
      id: "1623",
      c: 0.43,
      ma50: 0.357,
      ma100: 0.335,
      ma150: 0.317,
      ma200: 0.298,
      maw30: 0.32,
      rs: 95.0,
      rsd1d: 1.0,
      rsd5d: 2.0,
      rsd10d: 7.0,
      price_5d_h: 0.51,
      price_5d_L: 0.305,
      price_1m_h: 0.51,
      price_1m_l: 0.28,
      price_3m_h: 0.51,
      price_3m_l: 0.28,
      price_6m_h: 0.51,
      price_6m_l: 0.25,
      price_52w_h: 0.51,
      price_52w_l: 0.2,
      price_pc_1d: 6.173,
      price_pc_1w: 19.444,
      price_pc_1m: 24.638,
      price_pc_6m: 65.385,
      price_pc_1y: 68.627,
      price_pc_ytd: 68.627,
    },
    1626: {
      id: "1626",
      c: 2.0,
      ma50: 1.971,
      ma100: 1.894,
      ma150: 1.816,
      ma200: 1.716,
      maw30: 1.814,
      rs: 92.0,
      rsd1d: -2.0,
      rsd5d: -3.0,
      rsd10d: -5.0,
      price_5d_h: 2.13,
      price_5d_L: 2.0,
      price_1m_h: 2.3,
      price_1m_l: 1.85,
      price_3m_h: 2.3,
      price_3m_l: 1.6,
      price_6m_h: 2.3,
      price_6m_l: 1.6,
      price_52w_h: 2.3,
      price_52w_l: 1.1,
      price_pc_1d: -6.103,
      price_pc_1w: -6.103,
      price_pc_1m: 6.383,
      price_pc_6m: 21.951,
      price_pc_1y: 81.818,
      price_pc_ytd: 81.818,
    },
    1628: {
      id: "1628",
      c: 0.405,
      ma50: 0.581,
      ma100: 0.656,
      ma150: 0.824,
      ma200: 1.082,
      maw30: 0.806,
      rs: 4.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: -3.0,
      price_5d_h: 0.51,
      price_5d_L: 0.405,
      price_1m_h: 0.63,
      price_1m_l: 0.345,
      price_3m_h: 0.77,
      price_3m_l: 0.345,
      price_6m_h: 1.25,
      price_6m_l: 0.345,
      price_52w_h: 2.45,
      price_52w_l: 0.345,
      price_pc_1d: -17.347,
      price_pc_1w: -16.495,
      price_pc_1m: -34.677,
      price_pc_6m: -64.783,
      price_pc_1y: -82.0,
      price_pc_ytd: -82.0,
    },
    1629: {
      id: "1629",
      c: 0.435,
      ma50: 0.385,
      ma100: 0.483,
      ma150: 0.779,
      ma200: 1.265,
      maw30: 0.629,
      rs: 11.0,
      rsd1d: 8.0,
      rsd5d: 7.0,
      rsd10d: 9.0,
      price_5d_h: 0.44,
      price_5d_L: 0.335,
      price_1m_h: 0.44,
      price_1m_l: 0.285,
      price_3m_h: 0.48,
      price_3m_l: 0.285,
      price_6m_h: 0.78,
      price_6m_l: 0.285,
      price_52w_h: 4.72,
      price_52w_l: 0.285,
      price_pc_1d: 20.833,
      price_pc_1w: 16.0,
      price_pc_1m: 6.098,
      price_pc_6m: -39.583,
      price_pc_1y: -85.0,
      price_pc_ytd: -85.0,
    },
    1630: {
      id: "1630",
      c: 0.098,
      ma50: 0.095,
      ma100: 0.105,
      ma150: 0.111,
      ma200: 0.109,
      maw30: 0.111,
      rs: 57.0,
      rsd1d: 4.0,
      rsd5d: 14.0,
      rsd10d: 20.0,
      price_5d_h: 0.1,
      price_5d_L: 0.081,
      price_1m_h: 0.118,
      price_1m_l: 0.071,
      price_3m_h: 0.118,
      price_3m_l: 0.071,
      price_6m_h: 0.141,
      price_6m_l: 0.071,
      price_52w_h: 0.141,
      price_52w_l: 0.071,
      price_pc_1d: -2.0,
      price_pc_1w: 7.692,
      price_pc_1m: -1.01,
      price_pc_6m: -23.438,
      price_pc_1y: -13.274,
      price_pc_ytd: -13.274,
    },
    1631: {
      id: "1631",
      c: 0.77,
      ma50: 0.738,
      ma100: 0.744,
      ma150: 0.731,
      ma200: 0.703,
      maw30: 0.733,
      rs: 80.0,
      rsd1d: -2.0,
      rsd5d: -2.0,
      rsd10d: -1.0,
      price_5d_h: 0.78,
      price_5d_L: 0.73,
      price_1m_h: 0.78,
      price_1m_l: 0.68,
      price_3m_h: 0.79,
      price_3m_l: 0.64,
      price_6m_h: 0.84,
      price_6m_l: 0.64,
      price_52w_h: 0.99,
      price_52w_l: 0.5,
      price_pc_1d: 0.0,
      price_pc_1w: 2.667,
      price_pc_1m: 6.944,
      price_pc_6m: -2.532,
      price_pc_1y: -3.75,
      price_pc_ytd: -3.75,
    },
    1632: {
      id: "1632",
      c: 0.57,
      ma50: 0.604,
      ma100: 0.604,
      ma150: 0.577,
      ma200: 0.595,
      maw30: 0.578,
      rs: 55.0,
      rsd1d: -8.0,
      rsd5d: -16.0,
      rsd10d: -3.0,
      price_5d_h: 0.67,
      price_5d_L: 0.57,
      price_1m_h: 0.73,
      price_1m_l: 0.51,
      price_3m_h: 0.73,
      price_3m_l: 0.51,
      price_6m_h: 0.78,
      price_6m_l: 0.435,
      price_52w_h: 0.93,
      price_52w_l: 0.435,
      price_pc_1d: -3.39,
      price_pc_1w: -8.065,
      price_pc_1m: 3.636,
      price_pc_6m: 17.526,
      price_pc_1y: -14.925,
      price_pc_ytd: -14.925,
    },
    1636: {
      id: "1636",
      c: 0.14,
      ma50: 0.151,
      ma100: 0.166,
      ma150: 0.183,
      ma200: 0.195,
      maw30: 0.183,
      rs: 18.0,
      rsd1d: 2.0,
      rsd5d: 6.0,
      rsd10d: 7.0,
      price_5d_h: 0.141,
      price_5d_L: 0.121,
      price_1m_h: 0.144,
      price_1m_l: 0.104,
      price_3m_h: 0.2,
      price_3m_l: 0.104,
      price_6m_h: 0.23,
      price_6m_l: 0.104,
      price_52w_h: 0.38,
      price_52w_l: 0.104,
      price_pc_1d: 0.0,
      price_pc_1w: 3.704,
      price_pc_1m: -2.778,
      price_pc_6m: -33.962,
      price_pc_1y: -58.824,
      price_pc_ytd: -58.824,
    },
    1637: {
      id: "1637",
      c: 0.53,
      ma50: 0.507,
      ma100: 0.536,
      ma150: 0.53,
      ma200: 0.53,
      maw30: 0.53,
      rs: 84.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: 2.0,
      price_5d_h: 0.53,
      price_5d_L: 0.53,
      price_1m_h: 0.54,
      price_1m_l: 0.41,
      price_3m_h: 0.56,
      price_3m_l: 0.41,
      price_6m_h: 0.64,
      price_6m_l: 0.41,
      price_52w_h: 0.64,
      price_52w_l: 0.35,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: 10.417,
      price_pc_6m: 6.0,
      price_pc_1y: 45.205,
      price_pc_ytd: 45.205,
    },
    1638: {
      id: "1638",
      c: 0.84,
      ma50: 0.784,
      ma100: 0.854,
      ma150: 1.219,
      ma200: 1.564,
      maw30: 1.192,
      rs: 14.0,
      rsd1d: 5.0,
      rsd5d: 6.0,
      rsd10d: 7.0,
      price_5d_h: 0.91,
      price_5d_L: 0.65,
      price_1m_h: 0.91,
      price_1m_l: 0.5,
      price_3m_h: 1.03,
      price_3m_l: 0.5,
      price_6m_h: 2.19,
      price_6m_l: 0.5,
      price_52w_h: 3.97,
      price_52w_l: 0.5,
      price_pc_1d: 10.526,
      price_pc_1w: 12.0,
      price_pc_1m: 18.31,
      price_pc_6m: -60.377,
      price_pc_1y: -78.01,
      price_pc_ytd: -78.01,
    },
    1639: {
      id: "1639",
      c: 1.78,
      ma50: 1.755,
      ma100: 1.627,
      ma150: 1.444,
      ma200: 1.328,
      maw30: 1.445,
      rs: 95.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: -1.0,
      price_5d_h: 1.79,
      price_5d_L: 1.76,
      price_1m_h: 1.79,
      price_1m_l: 1.71,
      price_3m_h: 1.79,
      price_3m_l: 1.29,
      price_6m_h: 1.82,
      price_6m_l: 0.96,
      price_52w_h: 1.82,
      price_52w_l: 0.8,
      price_pc_1d: 0.0,
      price_pc_1w: 0.565,
      price_pc_1m: 0.565,
      price_pc_6m: 78.0,
      price_pc_1y: 72.816,
      price_pc_ytd: 72.816,
    },
    1640: {
      id: "1640",
      c: 0.355,
      ma50: 0.372,
      ma100: 0.396,
      ma150: 0.44,
      ma200: 0.483,
      maw30: 0.438,
      rs: 57.0,
      rsd1d: 11.0,
      rsd5d: -1.0,
      rsd10d: -5.0,
      price_5d_h: 0.365,
      price_5d_L: 0.33,
      price_1m_h: 0.435,
      price_1m_l: 0.32,
      price_3m_h: 0.48,
      price_3m_l: 0.32,
      price_6m_h: 0.58,
      price_6m_l: 0.305,
      price_52w_h: 0.89,
      price_52w_l: 0.216,
      price_pc_1d: 4.412,
      price_pc_1w: -1.389,
      price_pc_1m: -4.054,
      price_pc_6m: -30.392,
      price_pc_1y: 61.364,
      price_pc_ytd: 61.364,
    },
    1643: {
      id: "1643",
      c: 0.67,
      ma50: 0.749,
      ma100: 0.733,
      ma150: 0.736,
      ma200: 0.766,
      maw30: 0.733,
      rs: 36.0,
      rsd1d: -9.0,
      rsd5d: -22.0,
      rsd10d: -16.0,
      price_5d_h: 0.7,
      price_5d_L: 0.65,
      price_1m_h: 0.78,
      price_1m_l: 0.59,
      price_3m_h: 1.07,
      price_3m_l: 0.59,
      price_6m_h: 1.07,
      price_6m_l: 0.59,
      price_52w_h: 1.22,
      price_52w_l: 0.59,
      price_pc_1d: 0.0,
      price_pc_1w: -2.899,
      price_pc_1m: -10.667,
      price_pc_6m: -10.667,
      price_pc_1y: -22.093,
      price_pc_ytd: -22.093,
    },
    1645: {
      id: "1645",
      c: 0.75,
      ma50: 0.823,
      ma100: 0.834,
      ma150: 0.897,
      ma200: 0.968,
      maw30: 0.885,
      rs: 32.0,
      rsd1d: 0.0,
      rsd5d: -2.0,
      rsd10d: -12.0,
      price_5d_h: 0.8,
      price_5d_L: 0.68,
      price_1m_h: 0.85,
      price_1m_l: 0.68,
      price_3m_h: 0.93,
      price_3m_l: 0.68,
      price_6m_h: 1.08,
      price_6m_l: 0.68,
      price_52w_h: 1.37,
      price_52w_l: 0.68,
      price_pc_1d: -1.316,
      price_pc_1w: -5.063,
      price_pc_1m: -8.537,
      price_pc_6m: -23.469,
      price_pc_1y: -30.556,
      price_pc_ytd: -30.556,
    },
    1647: {
      id: "1647",
      c: 0.17,
      ma50: 0.18,
      ma100: 0.192,
      ma150: 0.206,
      ma200: 0.221,
      maw30: 0.205,
      rs: 24.0,
      rsd1d: 1.0,
      rsd5d: 5.0,
      rsd10d: 1.0,
      price_5d_h: 0.19,
      price_5d_L: 0.168,
      price_1m_h: 0.218,
      price_1m_l: 0.155,
      price_3m_h: 0.22,
      price_3m_l: 0.155,
      price_6m_h: 0.28,
      price_6m_l: 0.155,
      price_52w_h: 0.47,
      price_52w_l: 0.155,
      price_pc_1d: -1.163,
      price_pc_1w: -2.857,
      price_pc_1m: -9.091,
      price_pc_6m: -21.659,
      price_pc_1y: -58.537,
      price_pc_ytd: -58.537,
    },
    1651: {
      id: "1651",
      c: 9.0,
      ma50: 9.611,
      ma100: 9.976,
      ma150: 10.222,
      ma200: 10.386,
      maw30: 10.212,
      rs: 55.0,
      rsd1d: 4.0,
      rsd5d: -5.0,
      rsd10d: 0.0,
      price_5d_h: 9.39,
      price_5d_L: 8.95,
      price_1m_h: 9.9,
      price_1m_l: 7.7,
      price_3m_h: 11.0,
      price_3m_l: 7.7,
      price_6m_h: 12.2,
      price_6m_l: 7.7,
      price_52w_h: 12.2,
      price_52w_l: 7.7,
      price_pc_1d: 0.0,
      price_pc_1w: -3.017,
      price_pc_1m: -10.0,
      price_pc_6m: -13.295,
      price_pc_1y: -2.492,
      price_pc_ytd: -2.492,
    },
    1652: {
      id: "1652",
      c: 0.99,
      ma50: 1.092,
      ma100: 1.391,
      ma150: 1.817,
      ma200: 2.066,
      maw30: 1.802,
      rs: 8.0,
      rsd1d: 2.0,
      rsd5d: -1.0,
      rsd10d: -4.0,
      price_5d_h: 1.12,
      price_5d_L: 0.98,
      price_1m_h: 1.12,
      price_1m_l: 0.85,
      price_3m_h: 1.4,
      price_3m_l: 0.85,
      price_6m_h: 3.0,
      price_6m_l: 0.85,
      price_52w_h: 5.52,
      price_52w_l: 0.85,
      price_pc_1d: 1.02,
      price_pc_1w: -7.477,
      price_pc_1m: -8.333,
      price_pc_6m: -60.87,
      price_pc_1y: -79.588,
      price_pc_ytd: -79.588,
    },
    1658: {
      id: "1658",
      c: 6.35,
      ma50: 6.361,
      ma100: 6.021,
      ma150: 5.836,
      ma200: 5.686,
      maw30: 5.864,
      rs: 88.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 1.0,
      price_5d_h: 6.39,
      price_5d_L: 6.15,
      price_1m_h: 6.63,
      price_1m_l: 5.52,
      price_3m_h: 6.82,
      price_3m_l: 5.46,
      price_6m_h: 6.82,
      price_6m_l: 5.07,
      price_52w_h: 6.82,
      price_52w_l: 4.71,
      price_pc_1d: 1.6,
      price_pc_1w: 1.115,
      price_pc_1m: -0.936,
      price_pc_6m: 18.03,
      price_pc_1y: 13.393,
      price_pc_ytd: 13.393,
    },
    1660: {
      id: "1660",
      c: 0.485,
      ma50: 0.426,
      ma100: 0.44,
      ma150: 0.459,
      ma200: 0.496,
      maw30: 0.455,
      rs: 64.0,
      rsd1d: 21.0,
      rsd5d: 30.0,
      rsd10d: 44.0,
      price_5d_h: 0.49,
      price_5d_L: 0.395,
      price_1m_h: 0.49,
      price_1m_l: 0.32,
      price_3m_h: 0.59,
      price_3m_l: 0.32,
      price_6m_h: 0.59,
      price_6m_l: 0.32,
      price_52w_h: 0.79,
      price_52w_l: 0.32,
      price_pc_1d: 10.227,
      price_pc_1w: 12.791,
      price_pc_1m: 12.791,
      price_pc_6m: 7.778,
      price_pc_1y: -35.333,
      price_pc_ytd: -35.333,
    },
    1661: {
      id: "1661",
      c: 0.138,
      ma50: 0.126,
      ma100: 0.144,
      ma150: 0.159,
      ma200: 0.173,
      maw30: 0.158,
      rs: 31.0,
      rsd1d: -11.0,
      rsd5d: 22.0,
      rsd10d: 23.0,
      price_5d_h: 0.195,
      price_5d_L: 0.102,
      price_1m_h: 0.195,
      price_1m_l: 0.095,
      price_3m_h: 0.195,
      price_3m_l: 0.095,
      price_6m_h: 0.199,
      price_6m_l: 0.095,
      price_52w_h: 0.345,
      price_52w_l: 0.095,
      price_pc_1d: -8.0,
      price_pc_1w: 36.634,
      price_pc_1m: 8.661,
      price_pc_6m: -20.69,
      price_pc_1y: -29.231,
      price_pc_ytd: -29.231,
    },
    1662: {
      id: "1662",
      c: 1.33,
      ma50: 1.111,
      ma100: 1.251,
      ma150: 1.456,
      ma200: 1.581,
      maw30: 1.447,
      rs: 40.0,
      rsd1d: 12.0,
      rsd5d: 23.0,
      rsd10d: 28.0,
      price_5d_h: 1.39,
      price_5d_L: 1.12,
      price_1m_h: 1.39,
      price_1m_l: 0.99,
      price_3m_h: 1.39,
      price_3m_l: 0.99,
      price_6m_h: 2.08,
      price_6m_l: 0.99,
      price_52w_h: 3.42,
      price_52w_l: 0.99,
      price_pc_1d: 6.4,
      price_pc_1w: 15.652,
      price_pc_1m: 22.018,
      price_pc_6m: -27.717,
      price_pc_1y: -54.915,
      price_pc_ytd: -54.915,
    },
    1663: {
      id: "1663",
      c: 0.131,
      ma50: 0.15,
      ma100: 0.144,
      ma150: 0.139,
      ma200: 0.142,
      maw30: 0.139,
      rs: 59.0,
      rsd1d: 2.0,
      rsd5d: 2.0,
      rsd10d: 1.0,
      price_5d_h: 0.132,
      price_5d_L: 0.124,
      price_1m_h: 0.153,
      price_1m_l: 0.12,
      price_3m_h: 0.187,
      price_3m_l: 0.12,
      price_6m_h: 0.187,
      price_6m_l: 0.116,
      price_52w_h: 0.187,
      price_52w_l: 0.111,
      price_pc_1d: 0.769,
      price_pc_1w: 3.968,
      price_pc_1m: -14.379,
      price_pc_6m: 9.167,
      price_pc_1y: -12.667,
      price_pc_ytd: -12.667,
    },
    1665: {
      id: "1665",
      c: 1.09,
      ma50: 1.051,
      ma100: 1.108,
      ma150: 1.097,
      ma200: 1.095,
      maw30: 1.104,
      rs: 58.0,
      rsd1d: -3.0,
      rsd5d: -6.0,
      rsd10d: 16.0,
      price_5d_h: 1.1,
      price_5d_L: 1.03,
      price_1m_h: 1.1,
      price_1m_l: 0.9,
      price_3m_h: 1.31,
      price_3m_l: 0.9,
      price_6m_h: 1.31,
      price_6m_l: 0.9,
      price_52w_h: 1.31,
      price_52w_l: 0.9,
      price_pc_1d: -0.909,
      price_pc_1w: -0.909,
      price_pc_1m: 12.371,
      price_pc_6m: 2.83,
      price_pc_1y: -9.167,
      price_pc_ytd: -9.167,
    },
    1666: {
      id: "1666",
      c: 6.69,
      ma50: 6.78,
      ma100: 6.656,
      ma150: 6.366,
      ma200: 6.348,
      maw30: 6.423,
      rs: 72.0,
      rsd1d: -11.0,
      rsd5d: -11.0,
      rsd10d: -10.0,
      price_5d_h: 7.01,
      price_5d_L: 6.11,
      price_1m_h: 7.01,
      price_1m_l: 5.32,
      price_3m_h: 9.28,
      price_3m_l: 5.32,
      price_6m_h: 9.28,
      price_6m_l: 5.26,
      price_52w_h: 9.28,
      price_52w_l: 5.21,
      price_pc_1d: -1.182,
      price_pc_1w: -0.889,
      price_pc_1m: -0.889,
      price_pc_6m: 14.555,
      price_pc_1y: 24.119,
      price_pc_ytd: 24.119,
    },
    1668: {
      id: "1668",
      c: 0.69,
      ma50: 0.648,
      ma100: 0.613,
      ma150: 0.629,
      ma200: 0.668,
      maw30: 0.628,
      rs: 58.0,
      rsd1d: -1.0,
      rsd5d: 5.0,
      rsd10d: -18.0,
      price_5d_h: 0.7,
      price_5d_L: 0.62,
      price_1m_h: 0.7,
      price_1m_l: 0.52,
      price_3m_h: 0.76,
      price_3m_l: 0.52,
      price_6m_h: 0.76,
      price_6m_l: 0.47,
      price_52w_h: 0.89,
      price_52w_l: 0.47,
      price_pc_1d: 0.0,
      price_pc_1w: 6.154,
      price_pc_1m: 6.154,
      price_pc_6m: 4.545,
      price_pc_1y: -20.69,
      price_pc_ytd: -20.69,
    },
    1671: {
      id: "1671",
      c: 0.6,
      ma50: 0.733,
      ma100: 0.772,
      ma150: 0.836,
      ma200: 0.873,
      maw30: 0.841,
      rs: 16.0,
      rsd1d: 0.0,
      rsd5d: -4.0,
      rsd10d: -8.0,
      price_5d_h: 0.7,
      price_5d_L: 0.59,
      price_1m_h: 0.74,
      price_1m_l: 0.59,
      price_3m_h: 0.84,
      price_3m_l: 0.59,
      price_6m_h: 1.17,
      price_6m_l: 0.59,
      price_52w_h: 1.23,
      price_52w_l: 0.59,
      price_pc_1d: -3.226,
      price_pc_1w: -13.043,
      price_pc_1m: -25.0,
      price_pc_6m: -34.783,
      price_pc_1y: -50.0,
      price_pc_ytd: -50.0,
    },
    1672: {
      id: "1672",
      c: 5.2,
      ma50: 4.648,
      ma100: 3.921,
      ma150: 3.538,
      ma200: 3.459,
      maw30: 3.47,
      rs: 96.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: 0.0,
      price_5d_h: 6.1,
      price_5d_L: 5.15,
      price_1m_h: 6.15,
      price_1m_l: 3.38,
      price_3m_h: 7.26,
      price_3m_l: 3.38,
      price_6m_h: 7.26,
      price_6m_l: 2.44,
      price_52w_h: 7.26,
      price_52w_l: 2.44,
      price_pc_1d: -6.81,
      price_pc_1w: -6.977,
      price_pc_1m: 16.592,
      price_pc_6m: 96.226,
      price_pc_1y: 79.31,
      price_pc_ytd: 79.31,
    },
    1673: {
      id: "1673",
      c: 0.58,
      ma50: 0.62,
      ma100: 0.623,
      ma150: 0.734,
      ma200: 0.881,
      maw30: 0.725,
      rs: 14.0,
      rsd1d: 3.0,
      rsd5d: -2.0,
      rsd10d: -4.0,
      price_5d_h: 0.58,
      price_5d_L: 0.56,
      price_1m_h: 0.7,
      price_1m_l: 0.54,
      price_3m_h: 0.74,
      price_3m_l: 0.48,
      price_6m_h: 1.04,
      price_6m_l: 0.46,
      price_52w_h: 1.95,
      price_52w_l: 0.46,
      price_pc_1d: 3.571,
      price_pc_1w: 0.0,
      price_pc_1m: -9.375,
      price_pc_6m: -42.0,
      price_pc_1y: -66.857,
      price_pc_ytd: -66.857,
    },
    1675: {
      id: "1675",
      c: 13.8,
      ma50: 14.086,
      ma100: 13.461,
      ma150: 13.278,
      ma200: 13.072,
      maw30: 13.341,
      rs: 84.0,
      rsd1d: -1.0,
      rsd5d: -2.0,
      rsd10d: -5.0,
      price_5d_h: 14.18,
      price_5d_L: 13.72,
      price_1m_h: 14.92,
      price_1m_l: 12.04,
      price_3m_h: 15.68,
      price_3m_l: 12.04,
      price_6m_h: 15.68,
      price_6m_l: 12.04,
      price_52w_h: 15.68,
      price_52w_l: 10.78,
      price_pc_1d: -1.146,
      price_pc_1w: -0.289,
      price_pc_1m: -5.738,
      price_pc_6m: 7.143,
      price_pc_1y: 11.831,
      price_pc_ytd: 11.831,
    },
    1676: {
      id: "1676",
      c: 1.38,
      ma50: 1.016,
      ma100: 1.176,
      ma150: 1.271,
      ma200: 1.383,
      maw30: 1.257,
      rs: 69.0,
      rsd1d: -3.0,
      rsd5d: 4.0,
      rsd10d: -15.0,
      price_5d_h: 1.5,
      price_5d_L: 1.33,
      price_1m_h: 1.6,
      price_1m_l: 0.65,
      price_3m_h: 1.6,
      price_3m_l: 0.65,
      price_6m_h: 1.72,
      price_6m_l: 0.65,
      price_52w_h: 2.38,
      price_52w_l: 0.65,
      price_pc_1d: -0.719,
      price_pc_1w: -4.167,
      price_pc_1m: 89.041,
      price_pc_6m: -1.429,
      price_pc_1y: -18.824,
      price_pc_ytd: -18.824,
    },
    1679: {
      id: "1679",
      c: 0.171,
      ma50: 0.178,
      ma100: 0.187,
      ma150: 0.192,
      ma200: 0.196,
      maw30: 0.192,
      rs: 50.0,
      rsd1d: 7.0,
      rsd5d: 3.0,
      rsd10d: 9.0,
      price_5d_h: 0.172,
      price_5d_L: 0.152,
      price_1m_h: 0.185,
      price_1m_l: 0.138,
      price_3m_h: 0.201,
      price_3m_l: 0.138,
      price_6m_h: 0.223,
      price_6m_l: 0.138,
      price_52w_h: 0.265,
      price_52w_l: 0.138,
      price_pc_1d: 1.786,
      price_pc_1w: 3.636,
      price_pc_1m: -5.525,
      price_pc_6m: -16.176,
      price_pc_1y: -23.661,
      price_pc_ytd: -23.661,
    },
    1680: {
      id: "1680",
      c: 0.455,
      ma50: 0.514,
      ma100: 0.575,
      ma150: 0.675,
      ma200: 0.764,
      maw30: 0.671,
      rs: 11.0,
      rsd1d: 0.0,
      rsd5d: 4.0,
      rsd10d: -3.0,
      price_5d_h: 0.48,
      price_5d_L: 0.36,
      price_1m_h: 0.54,
      price_1m_l: 0.36,
      price_3m_h: 0.69,
      price_3m_l: 0.36,
      price_6m_h: 0.93,
      price_6m_l: 0.36,
      price_52w_h: 1.23,
      price_52w_l: 0.36,
      price_pc_1d: -2.151,
      price_pc_1w: 13.75,
      price_pc_1m: -14.151,
      price_pc_6m: -48.295,
      price_pc_1y: -59.009,
      price_pc_ytd: -59.009,
    },
    1681: {
      id: "1681",
      c: 3.82,
      ma50: 3.757,
      ma100: 3.823,
      ma150: 3.764,
      ma200: 4.044,
      maw30: 3.761,
      rs: 57.0,
      rsd1d: 6.0,
      rsd5d: -6.0,
      rsd10d: -5.0,
      price_5d_h: 3.99,
      price_5d_L: 3.56,
      price_1m_h: 3.99,
      price_1m_l: 3.0,
      price_3m_h: 4.8,
      price_3m_l: 3.0,
      price_6m_h: 4.8,
      price_6m_l: 3.0,
      price_52w_h: 6.1,
      price_52w_l: 3.0,
      price_pc_1d: 1.596,
      price_pc_1w: -1.546,
      price_pc_1m: 2.688,
      price_pc_6m: 7.91,
      price_pc_1y: -4.738,
      price_pc_ytd: -4.738,
    },
    1682: {
      id: "1682",
      c: 0.13,
      ma50: 0.127,
      ma100: 0.145,
      ma150: 0.147,
      ma200: 0.149,
      maw30: 0.147,
      rs: 33.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 2.0,
      price_5d_h: 0.13,
      price_5d_L: 0.13,
      price_1m_h: 0.132,
      price_1m_l: 0.101,
      price_3m_h: 0.17,
      price_3m_l: 0.101,
      price_6m_h: 0.38,
      price_6m_l: 0.101,
      price_52w_h: 0.38,
      price_52w_l: 0.101,
      price_pc_1d: 0.0,
      price_pc_1w: -1.515,
      price_pc_1m: 8.333,
      price_pc_6m: -9.091,
      price_pc_1y: -29.73,
      price_pc_ytd: -29.73,
    },
    1683: {
      id: "1683",
      c: 0.153,
      ma50: 0.172,
      ma100: 0.186,
      ma150: 0.159,
      ma200: 0.148,
      maw30: 0.162,
      rs: 81.0,
      rsd1d: -3.0,
      rsd5d: 2.0,
      rsd10d: 18.0,
      price_5d_h: 0.158,
      price_5d_L: 0.139,
      price_1m_h: 0.17,
      price_1m_l: 0.125,
      price_3m_h: 0.27,
      price_3m_l: 0.111,
      price_6m_h: 0.34,
      price_6m_l: 0.1,
      price_52w_h: 0.34,
      price_52w_l: 0.1,
      price_pc_1d: -3.165,
      price_pc_1w: -3.165,
      price_pc_1m: 12.5,
      price_pc_6m: 53.0,
      price_pc_1y: -11.561,
      price_pc_ytd: -11.561,
    },
    1685: {
      id: "1685",
      c: 0.315,
      ma50: 0.359,
      ma100: 0.383,
      ma150: 0.408,
      ma200: 0.423,
      maw30: 0.408,
      rs: 22.0,
      rsd1d: 9.0,
      rsd5d: 0.0,
      rsd10d: -2.0,
      price_5d_h: 0.355,
      price_5d_L: 0.27,
      price_1m_h: 0.39,
      price_1m_l: 0.27,
      price_3m_h: 0.425,
      price_3m_l: 0.27,
      price_6m_h: 0.46,
      price_6m_l: 0.27,
      price_52w_h: 0.68,
      price_52w_l: 0.27,
      price_pc_1d: 10.526,
      price_pc_1w: -5.97,
      price_pc_1m: -17.105,
      price_pc_6m: -28.409,
      price_pc_1y: -42.727,
      price_pc_ytd: -42.727,
    },
    1686: {
      id: "1686",
      c: 6.68,
      ma50: 6.861,
      ma100: 6.958,
      ma150: 7.14,
      ma200: 7.365,
      maw30: 7.132,
      rs: 51.0,
      rsd1d: 4.0,
      rsd5d: -2.0,
      rsd10d: -1.0,
      price_5d_h: 6.85,
      price_5d_L: 6.57,
      price_1m_h: 7.08,
      price_1m_l: 6.11,
      price_3m_h: 7.42,
      price_3m_l: 6.11,
      price_6m_h: 7.45,
      price_6m_l: 6.11,
      price_52w_h: 8.73,
      price_52w_l: 6.11,
      price_pc_1d: 1.059,
      price_pc_1w: -0.89,
      price_pc_1m: -4.023,
      price_pc_6m: -9.116,
      price_pc_1y: -16.186,
      price_pc_ytd: -16.186,
    },
    1689: {
      id: "1689",
      c: 1.76,
      ma50: 1.885,
      ma100: 1.963,
      ma150: 2.086,
      ma200: 2.213,
      maw30: 2.089,
      rs: 27.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -1.0,
      price_5d_h: 1.86,
      price_5d_L: 1.72,
      price_1m_h: 1.92,
      price_1m_l: 1.57,
      price_3m_h: 2.19,
      price_3m_l: 1.57,
      price_6m_h: 2.49,
      price_6m_l: 1.57,
      price_52w_h: 3.02,
      price_52w_l: 1.57,
      price_pc_1d: -1.124,
      price_pc_1w: -2.222,
      price_pc_1m: -2.762,
      price_pc_6m: -28.455,
      price_pc_1y: -34.815,
      price_pc_ytd: -34.815,
    },
    1691: {
      id: "1691",
      c: 9.21,
      ma50: 9.602,
      ma100: 11.94,
      ma150: 13.601,
      ma200: 15.394,
      maw30: 13.479,
      rs: 11.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: 4.0,
      price_5d_h: 9.38,
      price_5d_L: 7.53,
      price_1m_h: 9.38,
      price_1m_l: 6.31,
      price_3m_h: 14.24,
      price_3m_l: 6.31,
      price_6m_h: 18.68,
      price_6m_l: 6.31,
      price_52w_h: 26.5,
      price_52w_l: 6.31,
      price_pc_1d: 0.546,
      price_pc_1w: 0.327,
      price_pc_1m: 2.561,
      price_pc_6m: -49.116,
      price_pc_1y: -63.452,
      price_pc_ytd: -63.452,
    },
    1692: {
      id: "1692",
      c: 2.1,
      ma50: 2.021,
      ma100: 2.001,
      ma150: 2.049,
      ma200: 2.06,
      maw30: 2.045,
      rs: 79.0,
      rsd1d: 0.0,
      rsd5d: -3.0,
      rsd10d: 8.0,
      price_5d_h: 2.15,
      price_5d_L: 2.06,
      price_1m_h: 2.15,
      price_1m_l: 1.63,
      price_3m_h: 2.15,
      price_3m_l: 1.63,
      price_6m_h: 2.15,
      price_6m_l: 1.63,
      price_52w_h: 2.81,
      price_52w_l: 1.63,
      price_pc_1d: 0.0,
      price_pc_1w: -1.408,
      price_pc_1m: 2.941,
      price_pc_6m: 0.478,
      price_pc_1y: 2.941,
      price_pc_ytd: 2.941,
    },
    1695: {
      id: "1695",
      c: 0.066,
      ma50: 0.07,
      ma100: 0.073,
      ma150: 0.075,
      ma200: 0.081,
      maw30: 0.075,
      rs: 32.0,
      rsd1d: -3.0,
      rsd5d: 2.0,
      rsd10d: -10.0,
      price_5d_h: 0.069,
      price_5d_L: 0.064,
      price_1m_h: 0.072,
      price_1m_l: 0.061,
      price_3m_h: 0.091,
      price_3m_l: 0.061,
      price_6m_h: 0.091,
      price_6m_l: 0.061,
      price_52w_h: 0.114,
      price_52w_l: 0.061,
      price_pc_1d: -4.348,
      price_pc_1w: -1.493,
      price_pc_1m: -13.158,
      price_pc_6m: -21.429,
      price_pc_1y: -31.25,
      price_pc_ytd: -31.25,
    },
    1696: {
      id: "1696",
      c: 9.1,
      ma50: 8.059,
      ma100: 9.408,
      ma150: 10.734,
      ma200: 12.991,
      maw30: 10.676,
      rs: 80.0,
      rsd1d: -3.0,
      rsd5d: -3.0,
      rsd10d: 18.0,
      price_5d_h: 9.83,
      price_5d_L: 8.57,
      price_1m_h: 10.08,
      price_1m_l: 4.8,
      price_3m_h: 11.5,
      price_3m_l: 4.8,
      price_6m_h: 14.84,
      price_6m_l: 4.8,
      price_52w_h: 35.0,
      price_52w_l: 4.29,
      price_pc_1d: -3.294,
      price_pc_1w: -8.081,
      price_pc_1m: 14.178,
      price_pc_6m: -20.035,
      price_pc_1y: 95.699,
      price_pc_ytd: 95.699,
    },
    1699: {
      id: "1699",
      c: 0.048,
      ma50: 0.059,
      ma100: 0.067,
      ma150: 0.087,
      ma200: 0.09,
      maw30: 0.091,
      rs: 12.0,
      rsd1d: -3.0,
      rsd5d: -5.0,
      rsd10d: -2.0,
      price_5d_h: 0.058,
      price_5d_L: 0.042,
      price_1m_h: 0.062,
      price_1m_l: 0.042,
      price_3m_h: 0.102,
      price_3m_l: 0.042,
      price_6m_h: 0.38,
      price_6m_l: 0.042,
      price_52w_h: 0.38,
      price_52w_l: 0.042,
      price_pc_1d: -12.727,
      price_pc_1w: -12.727,
      price_pc_1m: -20.0,
      price_pc_6m: -65.957,
      price_pc_1y: -58.261,
      price_pc_ytd: -58.261,
    },
    1702: {
      id: "1702",
      c: 2.3,
      ma50: 2.164,
      ma100: 2.075,
      ma150: 1.944,
      ma200: 1.819,
      maw30: 1.973,
      rs: 91.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: -1.0,
      price_5d_h: 2.3,
      price_5d_L: 2.11,
      price_1m_h: 2.3,
      price_1m_l: 2.11,
      price_3m_h: 2.5,
      price_3m_l: 2.0,
      price_6m_h: 2.5,
      price_6m_l: 1.47,
      price_52w_h: 2.5,
      price_52w_l: 1.28,
      price_pc_1d: 7.981,
      price_pc_1w: 9.005,
      price_pc_1m: 5.023,
      price_pc_6m: 45.57,
      price_pc_1y: 37.725,
      price_pc_ytd: 37.725,
    },
    1703: {
      id: "1703",
      c: 0.77,
      ma50: 0.779,
      ma100: 0.827,
      ma150: 0.825,
      ma200: 0.805,
      maw30: 0.828,
      rs: 80.0,
      rsd1d: -1.0,
      rsd5d: 1.0,
      rsd10d: 7.0,
      price_5d_h: 0.79,
      price_5d_L: 0.7,
      price_1m_h: 0.83,
      price_1m_l: 0.62,
      price_3m_h: 1.07,
      price_3m_l: 0.24,
      price_6m_h: 1.07,
      price_6m_l: 0.24,
      price_52w_h: 1.07,
      price_52w_l: 0.24,
      price_pc_1d: 4.054,
      price_pc_1w: 4.054,
      price_pc_1m: 0.0,
      price_pc_6m: -3.75,
      price_pc_1y: 57.143,
      price_pc_ytd: 57.143,
    },
    1705: {
      id: "1705",
      c: 0.33,
      ma50: 0.37,
      ma100: 0.403,
      ma150: 0.441,
      ma200: 0.504,
      maw30: 0.437,
      rs: 20.0,
      rsd1d: 2.0,
      rsd5d: 4.0,
      rsd10d: 7.0,
      price_5d_h: 0.355,
      price_5d_L: 0.325,
      price_1m_h: 0.39,
      price_1m_l: 0.3,
      price_3m_h: 0.435,
      price_3m_l: 0.3,
      price_6m_h: 0.54,
      price_6m_l: 0.3,
      price_52w_h: 1.14,
      price_52w_l: 0.3,
      price_pc_1d: -1.493,
      price_pc_1w: -4.348,
      price_pc_1m: -12.0,
      price_pc_6m: -29.032,
      price_pc_1y: -35.294,
      price_pc_ytd: -35.294,
    },
    1706: {
      id: "1706",
      c: 0.249,
      ma50: 0.246,
      ma100: 0.251,
      ma150: 0.259,
      ma200: 0.256,
      maw30: 0.257,
      rs: 91.0,
      rsd1d: 2.0,
      rsd5d: 0.0,
      rsd10d: -1.0,
      price_5d_h: 0.275,
      price_5d_L: 0.23,
      price_1m_h: 0.275,
      price_1m_l: 0.22,
      price_3m_h: 0.35,
      price_3m_l: 0.22,
      price_6m_h: 0.35,
      price_6m_l: 0.22,
      price_52w_h: 0.38,
      price_52w_l: 0.093,
      price_pc_1d: 3.75,
      price_pc_1w: 0.0,
      price_pc_1m: 2.469,
      price_pc_6m: -4.231,
      price_pc_1y: 144.118,
      price_pc_ytd: 144.118,
    },
    1709: {
      id: "1709",
      c: 2.8,
      ma50: 2.677,
      ma100: 2.649,
      ma150: 2.614,
      ma200: 2.684,
      maw30: 2.63,
      rs: 72.0,
      rsd1d: 7.0,
      rsd5d: 7.0,
      rsd10d: -6.0,
      price_5d_h: 2.8,
      price_5d_L: 2.6,
      price_1m_h: 2.82,
      price_1m_l: 2.51,
      price_3m_h: 2.86,
      price_3m_l: 2.46,
      price_6m_h: 3.0,
      price_6m_l: 2.4,
      price_52w_h: 4.3,
      price_52w_l: 2.21,
      price_pc_1d: 4.869,
      price_pc_1w: 4.089,
      price_pc_1m: 1.818,
      price_pc_6m: 3.704,
      price_pc_1y: -4.11,
      price_pc_ytd: -4.11,
    },
    1710: {
      id: "1710",
      c: 0.15,
      ma50: 0.159,
      ma100: 0.167,
      ma150: 0.172,
      ma200: 0.179,
      maw30: 0.172,
      rs: 41.0,
      rsd1d: 9.0,
      rsd5d: 6.0,
      rsd10d: 7.0,
      price_5d_h: 0.164,
      price_5d_L: 0.14,
      price_1m_h: 0.164,
      price_1m_l: 0.14,
      price_3m_h: 0.175,
      price_3m_l: 0.14,
      price_6m_h: 0.19,
      price_6m_l: 0.14,
      price_52w_h: 0.27,
      price_52w_l: 0.14,
      price_pc_1d: 4.895,
      price_pc_1w: 0.671,
      price_pc_1m: -5.66,
      price_pc_6m: -16.667,
      price_pc_1y: -21.053,
      price_pc_ytd: -21.053,
    },
    1711: {
      id: "1711",
      c: 0.217,
      ma50: 0.231,
      ma100: 0.251,
      ma150: 0.259,
      ma200: 0.284,
      maw30: 0.258,
      rs: 43.0,
      rsd1d: 18.0,
      rsd5d: 19.0,
      rsd10d: 11.0,
      price_5d_h: 0.219,
      price_5d_L: 0.193,
      price_1m_h: 0.24,
      price_1m_l: 0.193,
      price_3m_h: 0.29,
      price_3m_l: 0.193,
      price_6m_h: 0.32,
      price_6m_l: 0.193,
      price_52w_h: 0.5,
      price_52w_l: 0.161,
      price_pc_1d: 12.435,
      price_pc_1w: 10.714,
      price_pc_1m: -6.061,
      price_pc_6m: -18.113,
      price_pc_1y: 19.231,
      price_pc_ytd: 19.231,
    },
    1713: {
      id: "1713",
      c: 1.74,
      ma50: 1.774,
      ma100: 1.758,
      ma150: 1.761,
      ma200: 1.759,
      maw30: 1.766,
      rs: 76.0,
      rsd1d: 0.0,
      rsd5d: 3.0,
      rsd10d: 0.0,
      price_5d_h: 1.78,
      price_5d_L: 1.73,
      price_1m_h: 1.82,
      price_1m_l: 1.64,
      price_3m_h: 1.89,
      price_3m_l: 1.64,
      price_6m_h: 1.92,
      price_6m_l: 1.64,
      price_52w_h: 2.0,
      price_52w_l: 1.54,
      price_pc_1d: 0.0,
      price_pc_1w: -0.571,
      price_pc_1m: -2.793,
      price_pc_6m: -6.452,
      price_pc_1y: 2.353,
      price_pc_ytd: 2.353,
    },
    1715: {
      id: "1715",
      c: 0.11,
      ma50: 0.136,
      ma100: 0.148,
      ma150: 0.151,
      ma200: 0.156,
      maw30: 0.152,
      rs: 12.0,
      rsd1d: 2.0,
      rsd5d: -17.0,
      rsd10d: -13.0,
      price_5d_h: 0.11,
      price_5d_L: 0.101,
      price_1m_h: 0.147,
      price_1m_l: 0.101,
      price_3m_h: 0.211,
      price_3m_l: 0.101,
      price_6m_h: 0.219,
      price_6m_l: 0.101,
      price_52w_h: 0.219,
      price_52w_l: 0.101,
      price_pc_1d: 6.796,
      price_pc_1w: -6.78,
      price_pc_1m: -28.105,
      price_pc_6m: -32.927,
      price_pc_1y: -44.162,
      price_pc_ytd: -44.162,
    },
    1716: {
      id: "1716",
      c: 0.415,
      ma50: 0.45,
      ma100: 0.482,
      ma150: 0.539,
      ma200: 0.673,
      maw30: 0.528,
      rs: 22.0,
      rsd1d: 5.0,
      rsd5d: 3.0,
      rsd10d: 6.0,
      price_5d_h: 0.42,
      price_5d_L: 0.38,
      price_1m_h: 0.475,
      price_1m_l: 0.35,
      price_3m_h: 0.58,
      price_3m_l: 0.35,
      price_6m_h: 0.67,
      price_6m_l: 0.35,
      price_52w_h: 2.0,
      price_52w_l: 0.35,
      price_pc_1d: 1.22,
      price_pc_1w: 0.0,
      price_pc_1m: -13.542,
      price_pc_6m: -25.893,
      price_pc_1y: -30.833,
      price_pc_ytd: -30.833,
    },
    1717: {
      id: "1717",
      c: 7.62,
      ma50: 9.519,
      ma100: 9.412,
      ma150: 8.964,
      ma200: 8.99,
      maw30: 9.013,
      rs: 37.0,
      rsd1d: 1.0,
      rsd5d: -8.0,
      rsd10d: -45.0,
      price_5d_h: 8.2,
      price_5d_L: 7.33,
      price_1m_h: 10.08,
      price_1m_l: 7.33,
      price_3m_h: 10.08,
      price_3m_l: 7.33,
      price_6m_h: 10.08,
      price_6m_l: 7.33,
      price_52w_h: 13.1,
      price_52w_l: 6.52,
      price_pc_1d: -1.295,
      price_pc_1w: -2.308,
      price_pc_1m: -22.561,
      price_pc_6m: -5.224,
      price_pc_1y: -26.731,
      price_pc_ytd: -26.731,
    },
    1720: {
      id: "1720",
      c: 0.26,
      ma50: 0.294,
      ma100: 0.317,
      ma150: 0.336,
      ma200: 0.368,
      maw30: 0.335,
      rs: 16.0,
      rsd1d: 2.0,
      rsd5d: -1.0,
      rsd10d: -5.0,
      price_5d_h: 0.27,
      price_5d_L: 0.24,
      price_1m_h: 0.315,
      price_1m_l: 0.24,
      price_3m_h: 0.36,
      price_3m_l: 0.24,
      price_6m_h: 0.53,
      price_6m_l: 0.24,
      price_52w_h: 0.54,
      price_52w_l: 0.24,
      price_pc_1d: 0.0,
      price_pc_1w: -5.455,
      price_pc_1m: -11.864,
      price_pc_6m: -33.333,
      price_pc_1y: -46.939,
      price_pc_ytd: -46.939,
    },
    1721: {
      id: "1721",
      c: 0.69,
      ma50: 0.664,
      ma100: 0.524,
      ma150: 0.473,
      ma200: 0.447,
      maw30: 0.474,
      rs: 97.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.69,
      price_5d_L: 0.55,
      price_1m_h: 0.7,
      price_1m_l: 0.55,
      price_3m_h: 0.8,
      price_3m_l: 0.375,
      price_6m_h: 0.8,
      price_6m_l: 0.345,
      price_52w_h: 0.8,
      price_52w_l: 0.345,
      price_pc_1d: 13.115,
      price_pc_1w: 0.0,
      price_pc_1m: -4.167,
      price_pc_6m: 86.486,
      price_pc_1y: 86.486,
      price_pc_ytd: 86.486,
    },
    1722: {
      id: "1722",
      c: 0.085,
      ma50: 0.089,
      ma100: 0.095,
      ma150: 0.098,
      ma200: 0.101,
      maw30: 0.098,
      rs: 39.0,
      rsd1d: 6.0,
      rsd5d: 12.0,
      rsd10d: 5.0,
      price_5d_h: 0.087,
      price_5d_L: 0.074,
      price_1m_h: 0.098,
      price_1m_l: 0.071,
      price_3m_h: 0.102,
      price_3m_l: 0.071,
      price_6m_h: 0.114,
      price_6m_l: 0.071,
      price_52w_h: 0.129,
      price_52w_l: 0.071,
      price_pc_1d: 2.41,
      price_pc_1w: 8.974,
      price_pc_1m: -6.593,
      price_pc_6m: -19.048,
      price_pc_1y: -19.811,
      price_pc_ytd: -19.811,
    },
    1723: {
      id: "1723",
      c: 0.33,
      ma50: 1.358,
      ma100: 3.112,
      ma150: 3.478,
      ma200: 3.647,
      maw30: 3.61,
      rs: 1.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.37,
      price_5d_L: 0.227,
      price_1m_h: 0.37,
      price_1m_l: 0.203,
      price_3m_h: 6.21,
      price_3m_l: 0.203,
      price_6m_h: 6.21,
      price_6m_l: 0.203,
      price_52w_h: 6.21,
      price_52w_l: 0.203,
      price_pc_1d: -7.042,
      price_pc_1w: 39.831,
      price_pc_1m: 37.5,
      price_pc_6m: -90.294,
      price_pc_1y: -90.0,
      price_pc_ytd: -90.0,
    },
    1725: {
      id: "1725",
      c: 13.22,
      ma50: 19.248,
      ma100: 23.77,
      ma150: 26.266,
      ma200: 27.719,
      maw30: 26.206,
      rs: 95.0,
      rsd1d: -1.0,
      rsd5d: -2.0,
      rsd10d: -2.0,
      price_5d_h: 14.5,
      price_5d_L: 12.62,
      price_1m_h: 20.35,
      price_1m_l: 12.62,
      price_3m_h: 28.15,
      price_3m_l: 12.62,
      price_6m_h: 35.0,
      price_6m_l: 12.62,
      price_52w_h: 50.0,
      price_52w_l: 2.09,
      price_pc_1d: -4.342,
      price_pc_1w: -6.901,
      price_pc_1m: -33.9,
      price_pc_6m: -57.628,
      price_pc_1y: 541.748,
      price_pc_ytd: 541.748,
    },
    1726: {
      id: "1726",
      c: 0.89,
      ma50: 0.906,
      ma100: 0.98,
      ma150: 0.803,
      ma200: 0.722,
      maw30: 0.806,
      rs: 96.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.89,
      price_5d_L: 0.82,
      price_1m_h: 0.89,
      price_1m_l: 0.72,
      price_3m_h: 1.62,
      price_3m_l: 0.72,
      price_6m_h: 1.62,
      price_6m_l: 0.41,
      price_52w_h: 1.62,
      price_52w_l: 0.26,
      price_pc_1d: 3.488,
      price_pc_1w: 8.537,
      price_pc_1m: 9.877,
      price_pc_6m: 114.458,
      price_pc_1y: 242.308,
      price_pc_ytd: 242.308,
    },
    1727: {
      id: "1727",
      c: 0.99,
      ma50: 1.349,
      ma100: 1.429,
      ma150: 1.666,
      ma200: 1.811,
      maw30: 1.664,
      rs: 11.0,
      rsd1d: 1.0,
      rsd5d: -6.0,
      rsd10d: -8.0,
      price_5d_h: 1.28,
      price_5d_L: 0.96,
      price_1m_h: 1.41,
      price_1m_l: 0.96,
      price_3m_h: 1.75,
      price_3m_l: 0.96,
      price_6m_h: 2.12,
      price_6m_l: 0.96,
      price_52w_h: 2.97,
      price_52w_l: 0.96,
      price_pc_1d: -1.98,
      price_pc_1w: -20.8,
      price_pc_1m: -29.286,
      price_pc_6m: -51.471,
      price_pc_1y: -63.869,
      price_pc_ytd: -63.869,
    },
    1728: {
      id: "1728",
      c: 0.51,
      ma50: 0.587,
      ma100: 0.743,
      ma150: 0.83,
      ma200: 0.876,
      maw30: 0.824,
      rs: 15.0,
      rsd1d: 3.0,
      rsd5d: 1.0,
      rsd10d: 3.0,
      price_5d_h: 0.53,
      price_5d_L: 0.49,
      price_1m_h: 0.59,
      price_1m_l: 0.355,
      price_3m_h: 0.9,
      price_3m_l: 0.355,
      price_6m_h: 1.11,
      price_6m_l: 0.355,
      price_52w_h: 1.38,
      price_52w_l: 0.355,
      price_pc_1d: 2.0,
      price_pc_1w: -3.774,
      price_pc_1m: -13.559,
      price_pc_6m: -42.045,
      price_pc_1y: -23.881,
      price_pc_ytd: -23.881,
    },
    1729: {
      id: "1729",
      c: 1.14,
      ma50: 1.014,
      ma100: 0.853,
      ma150: 0.721,
      ma200: 0.649,
      maw30: 0.72,
      rs: 98.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 1.25,
      price_5d_L: 1.11,
      price_1m_h: 1.45,
      price_1m_l: 0.87,
      price_3m_h: 1.45,
      price_3m_l: 0.65,
      price_6m_h: 1.45,
      price_6m_l: 0.415,
      price_52w_h: 1.45,
      price_52w_l: 0.375,
      price_pc_1d: -2.564,
      price_pc_1w: -8.8,
      price_pc_1m: 28.09,
      price_pc_6m: 150.549,
      price_pc_1y: 162.069,
      price_pc_ytd: 162.069,
    },
    1730: {
      id: "1730",
      c: 1.86,
      ma50: 1.821,
      ma100: 1.846,
      ma150: 1.832,
      ma200: 1.884,
      maw30: 1.83,
      rs: 88.0,
      rsd1d: -2.0,
      rsd5d: 1.0,
      rsd10d: -2.0,
      price_5d_h: 2.0,
      price_5d_L: 1.69,
      price_1m_h: 2.0,
      price_1m_l: 1.69,
      price_3m_h: 2.0,
      price_3m_l: 1.67,
      price_6m_h: 2.05,
      price_6m_l: 1.67,
      price_52w_h: 2.33,
      price_52w_l: 1.0,
      price_pc_1d: -3.125,
      price_pc_1w: 4.494,
      price_pc_1m: 0.0,
      price_pc_6m: 1.087,
      price_pc_1y: 73.832,
      price_pc_ytd: 73.832,
    },
    1731: {
      id: "1731",
      c: 1.11,
      ma50: 1.011,
      ma100: 1.122,
      ma150: 1.21,
      ma200: 1.259,
      maw30: 1.215,
      rs: 82.0,
      rsd1d: 3.0,
      rsd5d: -11.0,
      rsd10d: -2.0,
      price_5d_h: 1.31,
      price_5d_L: 1.0,
      price_1m_h: 1.4,
      price_1m_l: 0.85,
      price_3m_h: 1.4,
      price_3m_l: 0.85,
      price_6m_h: 1.46,
      price_6m_l: 0.85,
      price_52w_h: 1.82,
      price_52w_l: 0.63,
      price_pc_1d: 2.778,
      price_pc_1w: -15.267,
      price_pc_1m: 29.07,
      price_pc_6m: -20.714,
      price_pc_1y: 81.967,
      price_pc_ytd: 81.967,
    },
    1732: {
      id: "1732",
      c: 0.151,
      ma50: 0.145,
      ma100: 0.159,
      ma150: 0.178,
      ma200: 0.178,
      maw30: 0.177,
      rs: 73.0,
      rsd1d: 5.0,
      rsd5d: -2.0,
      rsd10d: -3.0,
      price_5d_h: 0.152,
      price_5d_L: 0.138,
      price_1m_h: 0.152,
      price_1m_l: 0.129,
      price_3m_h: 0.178,
      price_3m_l: 0.116,
      price_6m_h: 0.233,
      price_6m_l: 0.116,
      price_52w_h: 0.29,
      price_52w_l: 0.103,
      price_pc_1d: 0.667,
      price_pc_1w: 0.667,
      price_pc_1m: 17.054,
      price_pc_6m: -34.348,
      price_pc_1y: 32.456,
      price_pc_ytd: 32.456,
    },
    1733: {
      id: "1733",
      c: 1.88,
      ma50: 1.382,
      ma100: 1.17,
      ma150: 1.055,
      ma200: 0.896,
      maw30: 1.056,
      rs: 98.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 2.0,
      price_5d_L: 1.83,
      price_1m_h: 2.02,
      price_1m_l: 1.17,
      price_3m_h: 2.02,
      price_3m_l: 1.05,
      price_6m_h: 2.02,
      price_6m_l: 0.72,
      price_52w_h: 2.02,
      price_52w_l: 0.32,
      price_pc_1d: 0.535,
      price_pc_1w: -2.591,
      price_pc_1m: 54.098,
      price_pc_6m: 144.156,
      price_pc_1y: 452.941,
      price_pc_ytd: 452.941,
    },
    1735: {
      id: "1735",
      c: 14.92,
      ma50: 12.543,
      ma100: 10.296,
      ma150: 9.178,
      ma200: 7.992,
      maw30: 9.329,
      rs: 98.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 15.18,
      price_5d_L: 12.84,
      price_1m_h: 15.5,
      price_1m_l: 10.22,
      price_3m_h: 15.5,
      price_3m_l: 8.84,
      price_6m_h: 15.5,
      price_6m_l: 5.28,
      price_52w_h: 15.5,
      price_52w_l: 3.41,
      price_pc_1d: 3.181,
      price_pc_1w: 9.224,
      price_pc_1m: 10.519,
      price_pc_6m: 64.136,
      price_pc_1y: 333.721,
      price_pc_ytd: 333.721,
    },
    1736: {
      id: "1736",
      c: 0.072,
      ma50: 0.067,
      ma100: 0.073,
      ma150: 0.082,
      ma200: 0.097,
      maw30: 0.083,
      rs: 23.0,
      rsd1d: 0.0,
      rsd5d: 12.0,
      rsd10d: 11.0,
      price_5d_h: 0.075,
      price_5d_L: 0.064,
      price_1m_h: 0.075,
      price_1m_l: 0.052,
      price_3m_h: 0.078,
      price_3m_l: 0.052,
      price_6m_h: 0.107,
      price_6m_l: 0.052,
      price_52w_h: 0.204,
      price_52w_l: 0.052,
      price_pc_1d: -2.703,
      price_pc_1w: 18.033,
      price_pc_1m: 7.463,
      price_pc_6m: -37.391,
      price_pc_1y: -64.706,
      price_pc_ytd: -64.706,
    },
    1737: {
      id: "1737",
      c: 0.191,
      ma50: 0.191,
      ma100: 0.211,
      ma150: 0.199,
      ma200: 0.194,
      maw30: 0.201,
      rs: 81.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -4.0,
      price_5d_h: 0.191,
      price_5d_L: 0.18,
      price_1m_h: 0.199,
      price_1m_l: 0.163,
      price_3m_h: 0.216,
      price_3m_l: 0.163,
      price_6m_h: 0.26,
      price_6m_l: 0.16,
      price_52w_h: 0.26,
      price_52w_l: 0.136,
      price_pc_1d: 0.0,
      price_pc_1w: 1.596,
      price_pc_1m: 2.139,
      price_pc_6m: 10.405,
      price_pc_1y: 34.507,
      price_pc_ytd: 34.507,
    },
    1738: {
      id: "1738",
      c: 1.17,
      ma50: 1.145,
      ma100: 1.176,
      ma150: 1.153,
      ma200: 1.11,
      maw30: 1.154,
      rs: 83.0,
      rsd1d: -4.0,
      rsd5d: 0.0,
      rsd10d: -3.0,
      price_5d_h: 1.19,
      price_5d_L: 1.06,
      price_1m_h: 1.19,
      price_1m_l: 1.06,
      price_3m_h: 1.2,
      price_3m_l: 0.88,
      price_6m_h: 1.32,
      price_6m_l: 0.88,
      price_52w_h: 1.32,
      price_52w_l: 0.8,
      price_pc_1d: -0.847,
      price_pc_1w: 0.0,
      price_pc_1m: 0.0,
      price_pc_6m: 17.0,
      price_pc_1y: 8.333,
      price_pc_ytd: 8.333,
    },
    1739: {
      id: "1739",
      c: 0.42,
      ma50: 0.51,
      ma100: 0.771,
      ma150: 1.036,
      ma200: 1.279,
      maw30: 1.01,
      rs: 2.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.46,
      price_5d_L: 0.415,
      price_1m_h: 0.49,
      price_1m_l: 0.38,
      price_3m_h: 0.97,
      price_3m_l: 0.38,
      price_6m_h: 1.59,
      price_6m_l: 0.38,
      price_52w_h: 2.26,
      price_52w_l: 0.38,
      price_pc_1d: -4.545,
      price_pc_1w: -8.696,
      price_pc_1m: -14.286,
      price_pc_6m: -73.248,
      price_pc_1y: -80.374,
      price_pc_ytd: -80.374,
    },
    1740: {
      id: "1740",
      c: 0.151,
      ma50: 0.186,
      ma100: 0.211,
      ma150: 0.195,
      ma200: 0.181,
      maw30: 0.198,
      rs: 38.0,
      rsd1d: 3.0,
      rsd5d: -4.0,
      rsd10d: 6.0,
      price_5d_h: 0.17,
      price_5d_L: 0.15,
      price_1m_h: 0.198,
      price_1m_l: 0.131,
      price_3m_h: 0.25,
      price_3m_l: 0.131,
      price_6m_h: 0.3,
      price_6m_l: 0.131,
      price_52w_h: 0.3,
      price_52w_l: 0.118,
      price_pc_1d: 0.667,
      price_pc_1w: 0.0,
      price_pc_1m: -26.699,
      price_pc_6m: -9.036,
      price_pc_1y: -7.362,
      price_pc_ytd: -7.362,
    },
    1741: {
      id: "1741",
      c: 1.98,
      ma50: 1.802,
      ma100: 1.421,
      ma150: 1.335,
      ma200: 1.313,
      maw30: 1.374,
      rs: 95.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -2.0,
      price_5d_h: 2.09,
      price_5d_L: 1.73,
      price_1m_h: 2.25,
      price_1m_l: 1.59,
      price_3m_h: 2.25,
      price_3m_l: 0.92,
      price_6m_h: 2.25,
      price_6m_l: 0.82,
      price_52w_h: 2.25,
      price_52w_l: 0.82,
      price_pc_1d: 4.211,
      price_pc_1w: 5.319,
      price_pc_1m: 20.0,
      price_pc_6m: 53.488,
      price_pc_1y: 42.446,
      price_pc_ytd: 42.446,
    },
    1742: {
      id: "1742",
      c: 0.117,
      ma50: 0.118,
      ma100: 0.117,
      ma150: 0.111,
      ma200: 0.106,
      maw30: 0.111,
      rs: 91.0,
      rsd1d: -1.0,
      rsd5d: 3.0,
      rsd10d: 0.0,
      price_5d_h: 0.13,
      price_5d_L: 0.105,
      price_1m_h: 0.15,
      price_1m_l: 0.1,
      price_3m_h: 0.158,
      price_3m_l: 0.076,
      price_6m_h: 0.158,
      price_6m_l: 0.076,
      price_52w_h: 0.158,
      price_52w_l: 0.075,
      price_pc_1d: -2.5,
      price_pc_1w: 3.54,
      price_pc_1m: -17.021,
      price_pc_6m: 21.875,
      price_pc_1y: 18.182,
      price_pc_ytd: 18.182,
    },
    1745: {
      id: "1745",
      c: 0.455,
      ma50: 0.506,
      ma100: 0.532,
      ma150: 0.585,
      ma200: 0.673,
      maw30: 0.578,
      rs: 17.0,
      rsd1d: 3.0,
      rsd5d: 4.0,
      rsd10d: -11.0,
      price_5d_h: 0.465,
      price_5d_L: 0.45,
      price_1m_h: 0.53,
      price_1m_l: 0.43,
      price_3m_h: 0.57,
      price_3m_l: 0.43,
      price_6m_h: 0.72,
      price_6m_l: 0.43,
      price_52w_h: 1.35,
      price_52w_l: 0.43,
      price_pc_1d: -1.087,
      price_pc_1w: -1.087,
      price_pc_1m: -4.211,
      price_pc_6m: -26.613,
      price_pc_1y: -62.705,
      price_pc_ytd: -62.705,
    },
    1748: {
      id: "1748",
      c: 2.5,
      ma50: 2.356,
      ma100: 2.618,
      ma150: 2.806,
      ma200: 2.948,
      maw30: 2.814,
      rs: 56.0,
      rsd1d: 17.0,
      rsd5d: 23.0,
      rsd10d: 13.0,
      price_5d_h: 2.6,
      price_5d_L: 2.0,
      price_1m_h: 2.6,
      price_1m_l: 1.95,
      price_3m_h: 2.9,
      price_3m_l: 1.95,
      price_6m_h: 3.32,
      price_6m_l: 1.95,
      price_52w_h: 3.84,
      price_52w_l: 1.5,
      price_pc_1d: 14.155,
      price_pc_1w: 25.0,
      price_pc_1m: 0.806,
      price_pc_6m: -24.699,
      price_pc_1y: 51.515,
      price_pc_ytd: 51.515,
    },
    1749: {
      id: "1749",
      c: 0.66,
      ma50: 0.619,
      ma100: 0.668,
      ma150: 0.719,
      ma200: 0.75,
      maw30: 0.707,
      rs: 83.0,
      rsd1d: 6.0,
      rsd5d: 22.0,
      rsd10d: 9.0,
      price_5d_h: 0.66,
      price_5d_L: 0.55,
      price_1m_h: 0.66,
      price_1m_l: 0.495,
      price_3m_h: 0.71,
      price_3m_l: 0.495,
      price_6m_h: 0.9,
      price_6m_l: 0.495,
      price_52w_h: 1.48,
      price_52w_l: 0.44,
      price_pc_1d: 8.197,
      price_pc_1w: 6.452,
      price_pc_1m: 10.0,
      price_pc_6m: -13.158,
      price_pc_1y: 33.333,
      price_pc_ytd: 33.333,
    },
    1751: {
      id: "1751",
      c: 0.225,
      ma50: 0.238,
      ma100: 0.245,
      ma150: 0.261,
      ma200: 0.258,
      maw30: 0.26,
      rs: 82.0,
      rsd1d: 2.0,
      rsd5d: 1.0,
      rsd10d: 25.0,
      price_5d_h: 0.25,
      price_5d_L: 0.218,
      price_1m_h: 0.285,
      price_1m_l: 0.21,
      price_3m_h: 0.285,
      price_3m_l: 0.21,
      price_6m_h: 0.35,
      price_6m_l: 0.203,
      price_52w_h: 0.44,
      price_52w_l: 0.112,
      price_pc_1d: 2.273,
      price_pc_1w: -3.846,
      price_pc_1m: -0.881,
      price_pc_6m: -19.643,
      price_pc_1y: 59.574,
      price_pc_ytd: 59.574,
    },
    1752: {
      id: "1752",
      c: 0.092,
      ma50: 0.128,
      ma100: 0.165,
      ma150: 0.169,
      ma200: 0.172,
      maw30: 0.171,
      rs: 6.0,
      rsd1d: 2.0,
      rsd5d: 1.0,
      rsd10d: -4.0,
      price_5d_h: 0.093,
      price_5d_L: 0.088,
      price_1m_h: 0.201,
      price_1m_l: 0.085,
      price_3m_h: 0.201,
      price_3m_l: 0.085,
      price_6m_h: 0.249,
      price_6m_l: 0.085,
      price_52w_h: 0.255,
      price_52w_l: 0.085,
      price_pc_1d: 3.371,
      price_pc_1w: 2.222,
      price_pc_1m: -25.203,
      price_pc_6m: -53.535,
      price_pc_1y: -59.292,
      price_pc_ytd: -59.292,
    },
    1753: {
      id: "1753",
      c: 1.03,
      ma50: 1.137,
      ma100: 1.311,
      ma150: 1.507,
      ma200: 1.631,
      maw30: 1.5,
      rs: 14.0,
      rsd1d: -1.0,
      rsd5d: 5.0,
      rsd10d: 6.0,
      price_5d_h: 1.15,
      price_5d_L: 0.87,
      price_1m_h: 1.27,
      price_1m_l: 0.69,
      price_3m_h: 1.39,
      price_3m_l: 0.69,
      price_6m_h: 2.07,
      price_6m_l: 0.69,
      price_52w_h: 2.47,
      price_52w_l: 0.69,
      price_pc_1d: -5.505,
      price_pc_1w: 8.421,
      price_pc_1m: -16.935,
      price_pc_6m: -40.462,
      price_pc_1y: -55.411,
      price_pc_ytd: -55.411,
    },
    1755: {
      id: "1755",
      c: 9.45,
      ma50: 12.733,
      ma100: 12.722,
      ma150: 13.877,
      ma200: 15.393,
      maw30: 13.953,
      rs: 14.0,
      rsd1d: 1.0,
      rsd5d: -6.0,
      rsd10d: -13.0,
      price_5d_h: 10.8,
      price_5d_L: 8.38,
      price_1m_h: 14.1,
      price_1m_l: 7.07,
      price_3m_h: 16.86,
      price_3m_l: 7.07,
      price_6m_h: 17.8,
      price_6m_l: 7.07,
      price_52w_h: 27.8,
      price_52w_l: 7.07,
      price_pc_1d: 0.532,
      price_pc_1w: -9.828,
      price_pc_1m: -27.752,
      price_pc_6m: -43.209,
      price_pc_1y: -59.442,
      price_pc_ytd: -59.442,
    },
    1756: {
      id: "1756",
      c: 1.01,
      ma50: 1.026,
      ma100: 1.141,
      ma150: 1.245,
      ma200: 1.433,
      maw30: 1.237,
      rs: 17.0,
      rsd1d: 2.0,
      rsd5d: 7.0,
      rsd10d: 6.0,
      price_5d_h: 1.02,
      price_5d_L: 0.87,
      price_1m_h: 1.08,
      price_1m_l: 0.82,
      price_3m_h: 1.22,
      price_3m_l: 0.82,
      price_6m_h: 1.59,
      price_6m_l: 0.82,
      price_52w_h: 2.69,
      price_52w_l: 0.82,
      price_pc_1d: 0.0,
      price_pc_1w: 12.222,
      price_pc_1m: -2.885,
      price_pc_6m: -27.338,
      price_pc_1y: -60.079,
      price_pc_ytd: -60.079,
    },
    1759: {
      id: "1759",
      c: 0.77,
      ma50: 1.076,
      ma100: 1.202,
      ma150: 1.367,
      ma200: 1.314,
      maw30: 1.412,
      rs: 11.0,
      rsd1d: 0.0,
      rsd5d: -6.0,
      rsd10d: -8.0,
      price_5d_h: 0.95,
      price_5d_L: 0.76,
      price_1m_h: 1.06,
      price_1m_l: 0.76,
      price_3m_h: 1.4,
      price_3m_l: 0.76,
      price_6m_h: 2.76,
      price_6m_l: 0.76,
      price_52w_h: 3.39,
      price_52w_l: 0.76,
      price_pc_1d: -7.229,
      price_pc_1w: -18.947,
      price_pc_1m: -28.704,
      price_pc_6m: -49.007,
      price_pc_1y: -54.706,
      price_pc_ytd: -54.706,
    },
    1760: {
      id: "1760",
      c: 3.75,
      ma50: 3.909,
      ma100: 4.56,
      ma150: 4.627,
      ma200: 4.608,
      maw30: 4.602,
      rs: 36.0,
      rsd1d: 7.0,
      rsd5d: 9.0,
      rsd10d: 19.0,
      price_5d_h: 3.94,
      price_5d_L: 3.13,
      price_1m_h: 4.02,
      price_1m_l: 2.59,
      price_3m_h: 5.73,
      price_3m_l: 2.59,
      price_6m_h: 6.46,
      price_6m_l: 2.59,
      price_52w_h: 6.46,
      price_52w_l: 2.59,
      price_pc_1d: 5.337,
      price_pc_1w: 5.634,
      price_pc_1m: -6.015,
      price_pc_6m: -11.137,
      price_pc_1y: -15.919,
      price_pc_ytd: -15.919,
    },
    1761: {
      id: "1761",
      c: 0.73,
      ma50: 0.737,
      ma100: 0.783,
      ma150: 0.749,
      ma200: 0.817,
      maw30: 0.74,
      rs: 39.0,
      rsd1d: 7.0,
      rsd5d: 9.0,
      rsd10d: 25.0,
      price_5d_h: 0.74,
      price_5d_L: 0.66,
      price_1m_h: 0.81,
      price_1m_l: 0.54,
      price_3m_h: 1.09,
      price_3m_l: 0.54,
      price_6m_h: 1.25,
      price_6m_l: 0.51,
      price_52w_h: 1.74,
      price_52w_l: 0.51,
      price_pc_1d: 4.286,
      price_pc_1w: 2.817,
      price_pc_1m: -7.595,
      price_pc_6m: 21.667,
      price_pc_1y: -39.167,
      price_pc_ytd: -39.167,
    },
    1762: {
      id: "1762",
      c: 0.37,
      ma50: 0.426,
      ma100: 0.475,
      ma150: 0.528,
      ma200: 0.571,
      maw30: 0.529,
      rs: 14.0,
      rsd1d: 2.0,
      rsd5d: 3.0,
      rsd10d: 5.0,
      price_5d_h: 0.39,
      price_5d_L: 0.35,
      price_1m_h: 0.44,
      price_1m_l: 0.29,
      price_3m_h: 0.54,
      price_3m_l: 0.29,
      price_6m_h: 0.68,
      price_6m_l: 0.29,
      price_52w_h: 1.4,
      price_52w_l: 0.29,
      price_pc_1d: 1.37,
      price_pc_1w: 1.37,
      price_pc_1m: -9.756,
      price_pc_6m: -37.288,
      price_pc_1y: -58.889,
      price_pc_ytd: -58.889,
    },
    1763: {
      id: "1763",
      c: 21.65,
      ma50: 22.66,
      ma100: 22.566,
      ma150: 22.534,
      ma200: 24.266,
      maw30: 22.401,
      rs: 53.0,
      rsd1d: 6.0,
      rsd5d: -9.0,
      rsd10d: 4.0,
      price_5d_h: 23.55,
      price_5d_L: 20.8,
      price_1m_h: 23.55,
      price_1m_l: 18.36,
      price_3m_h: 26.5,
      price_3m_l: 18.36,
      price_6m_h: 26.5,
      price_6m_l: 18.36,
      price_52w_h: 32.1,
      price_52w_l: 18.36,
      price_pc_1d: 2.607,
      price_pc_1w: -3.132,
      price_pc_1m: -5.87,
      price_pc_6m: 0.464,
      price_pc_1y: -18.456,
      price_pc_ytd: -18.456,
    },
    1765: {
      id: "1765",
      c: 0.74,
      ma50: 0.893,
      ma100: 1.201,
      ma150: 1.252,
      ma200: 1.348,
      maw30: 1.262,
      rs: 6.0,
      rsd1d: 0.0,
      rsd5d: -2.0,
      rsd10d: 0.0,
      price_5d_h: 0.88,
      price_5d_L: 0.71,
      price_1m_h: 0.9,
      price_1m_l: 0.58,
      price_3m_h: 1.57,
      price_3m_l: 0.58,
      price_6m_h: 1.98,
      price_6m_l: 0.58,
      price_52w_h: 3.24,
      price_52w_l: 0.58,
      price_pc_1d: -2.632,
      price_pc_1w: -15.909,
      price_pc_1m: -6.329,
      price_pc_6m: -36.207,
      price_pc_1y: -71.538,
      price_pc_ytd: -71.538,
    },
    1766: {
      id: "1766",
      c: 3.14,
      ma50: 3.42,
      ma100: 3.47,
      ma150: 3.526,
      ma200: 3.503,
      maw30: 3.549,
      rs: 59.0,
      rsd1d: -3.0,
      rsd5d: 0.0,
      rsd10d: 3.0,
      price_5d_h: 3.26,
      price_5d_L: 3.09,
      price_1m_h: 3.52,
      price_1m_l: 2.78,
      price_3m_h: 3.73,
      price_3m_l: 2.78,
      price_6m_h: 3.8,
      price_6m_l: 2.78,
      price_52w_h: 4.04,
      price_52w_l: 2.78,
      price_pc_1d: -2.786,
      price_pc_1w: -2.484,
      price_pc_1m: -9.51,
      price_pc_6m: -11.798,
      price_pc_1y: -7.918,
      price_pc_ytd: -7.918,
    },
    1769: {
      id: "1769",
      c: 0.73,
      ma50: 0.931,
      ma100: 1.332,
      ma150: 1.443,
      ma200: 1.775,
      maw30: 1.434,
      rs: 3.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 1.0,
      price_5d_h: 0.84,
      price_5d_L: 0.71,
      price_1m_h: 1.09,
      price_1m_l: 0.59,
      price_3m_h: 1.49,
      price_3m_l: 0.59,
      price_6m_h: 3.16,
      price_6m_l: 0.59,
      price_52w_h: 9.18,
      price_52w_l: 0.59,
      price_pc_1d: -1.351,
      price_pc_1w: -14.118,
      price_pc_1m: -30.476,
      price_pc_6m: -51.333,
      price_pc_1y: -91.531,
      price_pc_ytd: -91.531,
    },
    1771: {
      id: "1771",
      c: 1.89,
      ma50: 1.927,
      ma100: 2.093,
      ma150: 2.215,
      ma200: 2.337,
      maw30: 2.193,
      rs: 46.0,
      rsd1d: 3.0,
      rsd5d: 9.0,
      rsd10d: -1.0,
      price_5d_h: 1.89,
      price_5d_L: 1.51,
      price_1m_h: 1.9,
      price_1m_l: 1.51,
      price_3m_h: 2.57,
      price_3m_l: 1.51,
      price_6m_h: 2.6,
      price_6m_l: 1.51,
      price_52w_h: 3.68,
      price_52w_l: 1.51,
      price_pc_1d: 0.0,
      price_pc_1w: 5.0,
      price_pc_1m: 0.532,
      price_pc_6m: -21.577,
      price_pc_1y: -4.061,
      price_pc_ytd: -4.061,
    },
    1772: {
      id: "1772",
      c: 112.0,
      ma50: 117.213,
      ma100: 124.955,
      ma150: 133.366,
      ma200: 135.3,
      maw30: 132.48,
      rs: 67.0,
      rsd1d: -1.0,
      rsd5d: -5.0,
      rsd10d: 12.0,
      price_5d_h: 117.2,
      price_5d_L: 106.3,
      price_1m_h: 132.7,
      price_1m_l: 86.0,
      price_3m_h: 136.8,
      price_3m_l: 86.0,
      price_6m_h: 161.0,
      price_6m_l: 86.0,
      price_52w_h: 185.0,
      price_52w_l: 85.6,
      price_pc_1d: -1.322,
      price_pc_1w: -0.267,
      price_pc_1m: -14.829,
      price_pc_6m: -19.075,
      price_pc_1y: 14.402,
      price_pc_ytd: 14.402,
    },
    1773: {
      id: "1773",
      c: 0.77,
      ma50: 1.418,
      ma100: 1.655,
      ma150: 1.714,
      ma200: 1.935,
      maw30: 1.71,
      rs: 2.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: 1.0,
      price_5d_h: 1.12,
      price_5d_L: 0.74,
      price_1m_h: 1.91,
      price_1m_l: 0.5,
      price_3m_h: 1.91,
      price_3m_l: 0.5,
      price_6m_h: 2.29,
      price_6m_l: 0.5,
      price_52w_h: 8.26,
      price_52w_l: 0.5,
      price_pc_1d: -4.938,
      price_pc_1w: -25.243,
      price_pc_1m: -59.686,
      price_pc_6m: -54.971,
      price_pc_1y: -90.166,
      price_pc_ytd: -90.166,
    },
    1775: {
      id: "1775",
      c: 0.5,
      ma50: 0.606,
      ma100: 0.629,
      ma150: 0.608,
      ma200: 0.581,
      maw30: 0.62,
      rs: 54.0,
      rsd1d: -5.0,
      rsd5d: -19.0,
      rsd10d: -26.0,
      price_5d_h: 0.56,
      price_5d_L: 0.5,
      price_1m_h: 0.65,
      price_1m_l: 0.495,
      price_3m_h: 0.75,
      price_3m_l: 0.495,
      price_6m_h: 0.8,
      price_6m_l: 0.495,
      price_52w_h: 0.8,
      price_52w_l: 0.415,
      price_pc_1d: -3.846,
      price_pc_1w: -9.091,
      price_pc_1m: -16.667,
      price_pc_6m: -16.667,
      price_pc_1y: 8.696,
      price_pc_ytd: 8.696,
    },
    1776: {
      id: "1776",
      c: 11.12,
      ma50: 12.261,
      ma100: 13.292,
      ma150: 13.503,
      ma200: 12.913,
      maw30: 13.532,
      rs: 49.0,
      rsd1d: -5.0,
      rsd5d: 2.0,
      rsd10d: -1.0,
      price_5d_h: 11.68,
      price_5d_L: 10.58,
      price_1m_h: 12.32,
      price_1m_l: 9.41,
      price_3m_h: 15.44,
      price_3m_l: 9.41,
      price_6m_h: 16.66,
      price_6m_l: 9.41,
      price_52w_h: 16.66,
      price_52w_l: 9.41,
      price_pc_1d: -3.64,
      price_pc_1w: 0.725,
      price_pc_1m: -5.119,
      price_pc_6m: -18.115,
      price_pc_1y: -10.032,
      price_pc_ytd: -10.032,
    },
    1777: {
      id: "1777",
      c: 0.2,
      ma50: 0.282,
      ma100: 0.305,
      ma150: 0.403,
      ma200: 0.507,
      maw30: 0.397,
      rs: 4.0,
      rsd1d: 0.0,
      rsd5d: -2.0,
      rsd10d: -8.0,
      price_5d_h: 0.249,
      price_5d_L: 0.196,
      price_1m_h: 0.295,
      price_1m_l: 0.196,
      price_3m_h: 0.335,
      price_3m_l: 0.196,
      price_6m_h: 0.56,
      price_6m_l: 0.196,
      price_52w_h: 1.04,
      price_52w_l: 0.196,
      price_pc_1d: -1.961,
      price_pc_1w: -17.695,
      price_pc_1m: -29.825,
      price_pc_6m: -64.286,
      price_pc_1y: -80.583,
      price_pc_ytd: -80.583,
    },
    1778: {
      id: "1778",
      c: 0.62,
      ma50: 0.806,
      ma100: 0.947,
      ma150: 1.413,
      ma200: 1.772,
      maw30: 1.383,
      rs: 4.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.73,
      price_5d_L: 0.62,
      price_1m_h: 0.86,
      price_1m_l: 0.49,
      price_3m_h: 1.01,
      price_3m_l: 0.49,
      price_6m_h: 2.53,
      price_6m_l: 0.49,
      price_52w_h: 3.55,
      price_52w_l: 0.49,
      price_pc_1d: -4.615,
      price_pc_1w: -15.068,
      price_pc_1m: -20.513,
      price_pc_6m: -75.494,
      price_pc_1y: -81.765,
      price_pc_ytd: -81.765,
    },
    1780: {
      id: "1780",
      c: 2.65,
      ma50: 2.608,
      ma100: 2.676,
      ma150: 2.661,
      ma200: 2.451,
      maw30: 2.68,
      rs: 96.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -1.0,
      price_5d_h: 2.8,
      price_5d_L: 2.16,
      price_1m_h: 3.11,
      price_1m_l: 2.16,
      price_3m_h: 3.11,
      price_3m_l: 2.16,
      price_6m_h: 3.59,
      price_6m_l: 2.12,
      price_52w_h: 3.99,
      price_52w_l: 0.67,
      price_pc_1d: 6.0,
      price_pc_1w: 2.317,
      price_pc_1m: -3.986,
      price_pc_6m: 0.76,
      price_pc_1y: 223.171,
      price_pc_ytd: 223.171,
    },
    1782: {
      id: "1782",
      c: 1.0,
      ma50: 0.755,
      ma100: 0.578,
      ma150: 0.512,
      ma200: 0.508,
      maw30: 0.501,
      rs: 98.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 2.0,
      price_5d_h: 1.02,
      price_5d_L: 0.9,
      price_1m_h: 1.08,
      price_1m_l: 0.68,
      price_3m_h: 1.08,
      price_3m_l: 0.395,
      price_6m_h: 1.08,
      price_6m_l: 0.3,
      price_52w_h: 1.08,
      price_52w_l: 0.3,
      price_pc_1d: 4.167,
      price_pc_1w: 4.167,
      price_pc_1m: 44.928,
      price_pc_6m: 185.714,
      price_pc_1y: 138.095,
      price_pc_ytd: 138.095,
    },
    1783: {
      id: "1783",
      c: 0.52,
      ma50: 0.322,
      ma100: 0.266,
      ma150: 0.286,
      ma200: 0.296,
      maw30: 0.276,
      rs: 98.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: 1.0,
      price_5d_h: 0.52,
      price_5d_L: 0.365,
      price_1m_h: 0.52,
      price_1m_l: 0.3,
      price_3m_h: 0.52,
      price_3m_l: 0.116,
      price_6m_h: 0.52,
      price_6m_l: 0.116,
      price_52w_h: 0.52,
      price_52w_l: 0.116,
      price_pc_1d: 5.051,
      price_pc_1w: 40.541,
      price_pc_1m: 40.541,
      price_pc_6m: 79.31,
      price_pc_1y: 100.0,
      price_pc_ytd: 100.0,
    },
    1785: {
      id: "1785",
      c: 2.06,
      ma50: 2.161,
      ma100: 2.154,
      ma150: 2.171,
      ma200: 2.171,
      maw30: 2.173,
      rs: 67.0,
      rsd1d: -1.0,
      rsd5d: -6.0,
      rsd10d: -4.0,
      price_5d_h: 2.12,
      price_5d_L: 1.98,
      price_1m_h: 2.2,
      price_1m_l: 1.98,
      price_3m_h: 2.27,
      price_3m_l: 1.98,
      price_6m_h: 2.34,
      price_6m_l: 1.98,
      price_52w_h: 2.4,
      price_52w_l: 1.93,
      price_pc_1d: 0.488,
      price_pc_1w: -3.738,
      price_pc_1m: -5.069,
      price_pc_6m: -6.364,
      price_pc_1y: 0.488,
      price_pc_ytd: 0.488,
    },
    1786: {
      id: "1786",
      c: 0.77,
      ma50: 0.838,
      ma100: 0.832,
      ma150: 0.842,
      ma200: 0.852,
      maw30: 0.846,
      rs: 46.0,
      rsd1d: -2.0,
      rsd5d: -7.0,
      rsd10d: -4.0,
      price_5d_h: 0.78,
      price_5d_L: 0.74,
      price_1m_h: 0.88,
      price_1m_l: 0.67,
      price_3m_h: 0.95,
      price_3m_l: 0.67,
      price_6m_h: 0.95,
      price_6m_l: 0.67,
      price_52w_h: 1.0,
      price_52w_l: 0.67,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: -10.465,
      price_pc_6m: -6.098,
      price_pc_1y: -17.204,
      price_pc_ytd: -17.204,
    },
    1787: {
      id: "1787",
      c: 15.66,
      ma50: 14.51,
      ma100: 14.089,
      ma150: 14.012,
      ma200: 13.898,
      maw30: 14.033,
      rs: 87.0,
      rsd1d: -2.0,
      rsd5d: -1.0,
      rsd10d: 4.0,
      price_5d_h: 16.18,
      price_5d_L: 15.02,
      price_1m_h: 16.18,
      price_1m_l: 13.08,
      price_3m_h: 16.18,
      price_3m_l: 12.58,
      price_6m_h: 16.18,
      price_6m_l: 12.4,
      price_52w_h: 17.78,
      price_52w_l: 12.24,
      price_pc_1d: -1.509,
      price_pc_1w: 1.425,
      price_pc_1m: 5.811,
      price_pc_6m: 13.643,
      price_pc_1y: 6.967,
      price_pc_ytd: 6.967,
    },
    1788: {
      id: "1788",
      c: 0.88,
      ma50: 1.041,
      ma100: 1.069,
      ma150: 1.134,
      ma200: 1.166,
      maw30: 1.131,
      rs: 26.0,
      rsd1d: -1.0,
      rsd5d: -11.0,
      rsd10d: -12.0,
      price_5d_h: 0.98,
      price_5d_L: 0.88,
      price_1m_h: 1.06,
      price_1m_l: 0.88,
      price_3m_h: 1.16,
      price_3m_l: 0.88,
      price_6m_h: 1.26,
      price_6m_l: 0.88,
      price_52w_h: 1.49,
      price_52w_l: 0.88,
      price_pc_1d: -3.297,
      price_pc_1w: -9.278,
      price_pc_1m: -16.19,
      price_pc_6m: -29.032,
      price_pc_1y: -40.136,
      price_pc_ytd: -40.136,
    },
    1789: {
      id: "1789",
      c: 4.66,
      ma50: 5.239,
      ma100: 5.968,
      ma150: 6.995,
      ma200: 8.176,
      maw30: 7.074,
      rs: 10.0,
      rsd1d: 2.0,
      rsd5d: 1.0,
      rsd10d: 1.0,
      price_5d_h: 4.82,
      price_5d_L: 4.01,
      price_1m_h: 5.58,
      price_1m_l: 3.5,
      price_3m_h: 7.28,
      price_3m_l: 3.5,
      price_6m_h: 10.74,
      price_6m_l: 3.5,
      price_52w_h: 16.28,
      price_52w_l: 3.5,
      price_pc_1d: 0.0,
      price_pc_1w: -1.688,
      price_pc_1m: -13.222,
      price_pc_6m: -57.011,
      price_pc_1y: -54.314,
      price_pc_ytd: -54.314,
    },
    1792: {
      id: "1792",
      c: 0.075,
      ma50: 0.078,
      ma100: 0.087,
      ma150: 0.095,
      ma200: 0.083,
      maw30: 0.1,
      rs: 73.0,
      rsd1d: -13.0,
      rsd5d: -16.0,
      rsd10d: 3.0,
      price_5d_h: 0.115,
      price_5d_L: 0.075,
      price_1m_h: 0.115,
      price_1m_l: 0.066,
      price_3m_h: 0.115,
      price_3m_l: 0.066,
      price_6m_h: 0.155,
      price_6m_l: 0.066,
      price_52w_h: 0.196,
      price_52w_l: 0.033,
      price_pc_1d: -9.639,
      price_pc_1w: -18.478,
      price_pc_1m: -6.25,
      price_pc_6m: -41.86,
      price_pc_1y: 22.951,
      price_pc_ytd: 22.951,
    },
    1795: {
      id: "1795",
      c: 0.92,
      ma50: 0.897,
      ma100: 0.87,
      ma150: 0.789,
      ma200: 0.738,
      maw30: 0.793,
      rs: 91.0,
      rsd1d: -3.0,
      rsd5d: -3.0,
      rsd10d: -3.0,
      price_5d_h: 1.05,
      price_5d_L: 0.88,
      price_1m_h: 1.05,
      price_1m_l: 0.86,
      price_3m_h: 1.08,
      price_3m_l: 0.74,
      price_6m_h: 1.08,
      price_6m_l: 0.57,
      price_52w_h: 1.08,
      price_52w_l: 0.51,
      price_pc_1d: -8.0,
      price_pc_1w: -6.122,
      price_pc_1m: 6.977,
      price_pc_6m: 48.387,
      price_pc_1y: 41.538,
      price_pc_ytd: 41.538,
    },
    1796: {
      id: "1796",
      c: 1.38,
      ma50: 1.41,
      ma100: 1.294,
      ma150: 1.245,
      ma200: 1.139,
      maw30: 1.255,
      rs: 96.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: -1.0,
      price_5d_h: 1.5,
      price_5d_L: 1.35,
      price_1m_h: 1.55,
      price_1m_l: 1.3,
      price_3m_h: 1.88,
      price_3m_l: 1.05,
      price_6m_h: 1.88,
      price_6m_l: 0.85,
      price_52w_h: 1.88,
      price_52w_l: 0.445,
      price_pc_1d: -3.497,
      price_pc_1w: -8.609,
      price_pc_1m: -3.497,
      price_pc_6m: 20.0,
      price_pc_1y: 210.112,
      price_pc_ytd: 210.112,
    },
    1797: {
      id: "1797",
      c: 3.97,
      ma50: 4.524,
      ma100: 5.46,
      ma150: 5.17,
      ma200: 5.666,
      maw30: 5.219,
      rs: 14.0,
      rsd1d: -1.0,
      rsd5d: 4.0,
      rsd10d: 5.0,
      price_5d_h: 4.31,
      price_5d_L: 3.91,
      price_1m_h: 5.19,
      price_1m_l: 3.24,
      price_3m_h: 5.81,
      price_3m_l: 3.24,
      price_6m_h: 9.72,
      price_6m_l: 3.24,
      price_52w_h: 18.6,
      price_52w_l: 3.24,
      price_pc_1d: -4.796,
      price_pc_1w: -7.243,
      price_pc_1m: -18.648,
      price_pc_6m: -5.701,
      price_pc_1y: -78.091,
      price_pc_ytd: -78.091,
    },
    1798: {
      id: "1798",
      c: 3.25,
      ma50: 3.116,
      ma100: 3.13,
      ma150: 3.114,
      ma200: 2.88,
      maw30: 3.172,
      rs: 92.0,
      rsd1d: 1.0,
      rsd5d: -1.0,
      rsd10d: -1.0,
      price_5d_h: 3.31,
      price_5d_L: 3.05,
      price_1m_h: 3.59,
      price_1m_l: 2.6,
      price_3m_h: 4.1,
      price_3m_l: 2.6,
      price_6m_h: 4.41,
      price_6m_l: 2.6,
      price_52w_h: 4.41,
      price_52w_l: 1.28,
      price_pc_1d: 3.175,
      price_pc_1w: 0.0,
      price_pc_1m: 10.169,
      price_pc_6m: -6.609,
      price_pc_1y: 112.418,
      price_pc_ytd: 112.418,
    },
    1799: {
      id: "1799",
      c: 19.3,
      ma50: 16.12,
      ma100: 16.298,
      ma150: 18.176,
      ma200: 17.945,
      maw30: 18.096,
      rs: 90.0,
      rsd1d: -1.0,
      rsd5d: 3.0,
      rsd10d: 5.0,
      price_5d_h: 20.0,
      price_5d_L: 16.5,
      price_1m_h: 20.0,
      price_1m_l: 13.5,
      price_3m_h: 20.0,
      price_3m_l: 13.5,
      price_6m_h: 25.95,
      price_6m_l: 13.3,
      price_52w_h: 27.4,
      price_52w_l: 12.46,
      price_pc_1d: 0.626,
      price_pc_1w: 12.079,
      price_pc_1m: 16.828,
      price_pc_6m: -7.212,
      price_pc_1y: 19.283,
      price_pc_ytd: 19.283,
    },
    1800: {
      id: "1800",
      c: 4.14,
      ma50: 4.514,
      ma100: 4.369,
      ma150: 4.322,
      ma200: 4.182,
      maw30: 4.35,
      rs: 78.0,
      rsd1d: -4.0,
      rsd5d: -1.0,
      rsd10d: -1.0,
      price_5d_h: 4.26,
      price_5d_L: 4.04,
      price_1m_h: 4.66,
      price_1m_l: 3.66,
      price_3m_h: 5.17,
      price_3m_l: 3.66,
      price_6m_h: 5.17,
      price_6m_l: 3.66,
      price_52w_h: 5.17,
      price_52w_l: 3.57,
      price_pc_1d: -2.588,
      price_pc_1w: -0.481,
      price_pc_1m: -7.589,
      price_pc_6m: -0.957,
      price_pc_1y: 8.094,
      price_pc_ytd: 8.094,
    },
    1801: {
      id: "1801",
      c: 26.95,
      ma50: 32.046,
      ma100: 46.178,
      ma150: 53.974,
      ma200: 60.897,
      maw30: 54.297,
      rs: 4.0,
      rsd1d: -2.0,
      rsd5d: -3.0,
      rsd10d: -1.0,
      price_5d_h: 31.5,
      price_5d_L: 26.0,
      price_1m_h: 37.95,
      price_1m_l: 21.35,
      price_3m_h: 49.5,
      price_3m_l: 21.35,
      price_6m_h: 82.0,
      price_6m_l: 21.35,
      price_52w_h: 95.75,
      price_52w_l: 21.35,
      price_pc_1d: -11.928,
      price_pc_1w: -14.984,
      price_pc_1m: -22.89,
      price_pc_6m: -64.305,
      price_pc_1y: -66.667,
      price_pc_ytd: -66.667,
    },
    1802: {
      id: "1802",
      c: 0.94,
      ma50: 1.002,
      ma100: 1.007,
      ma150: 0.988,
      ma200: 0.999,
      maw30: 0.994,
      rs: 59.0,
      rsd1d: -2.0,
      rsd5d: 3.0,
      rsd10d: -15.0,
      price_5d_h: 1.0,
      price_5d_L: 0.91,
      price_1m_h: 1.2,
      price_1m_l: 0.88,
      price_3m_h: 1.25,
      price_3m_l: 0.88,
      price_6m_h: 1.32,
      price_6m_l: 0.82,
      price_52w_h: 1.46,
      price_52w_l: 0.81,
      price_pc_1d: 1.075,
      price_pc_1w: 1.075,
      price_pc_1m: -8.738,
      price_pc_6m: 4.444,
      price_pc_1y: -22.951,
      price_pc_ytd: -22.951,
    },
    1809: {
      id: "1809",
      c: 7.44,
      ma50: 7.213,
      ma100: 7.041,
      ma150: 7.047,
      ma200: 7.366,
      maw30: 6.994,
      rs: 74.0,
      rsd1d: -1.0,
      rsd5d: 1.0,
      rsd10d: 18.0,
      price_5d_h: 7.6,
      price_5d_L: 7.33,
      price_1m_h: 7.69,
      price_1m_l: 6.69,
      price_3m_h: 7.88,
      price_3m_l: 6.42,
      price_6m_h: 7.88,
      price_6m_l: 6.02,
      price_52w_h: 9.55,
      price_52w_l: 6.02,
      price_pc_1d: -0.932,
      price_pc_1w: -0.402,
      price_pc_1m: 1.918,
      price_pc_6m: 17.536,
      price_pc_1y: -13.988,
      price_pc_ytd: -13.988,
    },
    1810: {
      id: "1810",
      c: 13.94,
      ma50: 15.362,
      ma100: 17.332,
      ma150: 19.12,
      ma200: 20.967,
      maw30: 19.013,
      rs: 16.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: -1.0,
      price_5d_h: 14.94,
      price_5d_L: 13.38,
      price_1m_h: 15.48,
      price_1m_l: 11.38,
      price_3m_h: 19.08,
      price_3m_l: 11.38,
      price_6m_h: 23.2,
      price_6m_l: 11.38,
      price_52w_h: 30.45,
      price_52w_l: 11.38,
      price_pc_1d: -1.554,
      price_pc_1w: -3.862,
      price_pc_1m: -4.911,
      price_pc_6m: -34.707,
      price_pc_1y: -47.297,
      price_pc_ytd: -47.297,
    },
    1811: {
      id: "1811",
      c: 3.44,
      ma50: 4.906,
      ma100: 5.897,
      ma150: 6.066,
      ma200: 5.343,
      maw30: 6.223,
      rs: 37.0,
      rsd1d: 1.0,
      rsd5d: 6.0,
      rsd10d: -33.0,
      price_5d_h: 3.52,
      price_5d_L: 3.0,
      price_1m_h: 5.34,
      price_1m_l: 3.0,
      price_3m_h: 8.6,
      price_3m_l: 3.0,
      price_6m_h: 8.99,
      price_6m_l: 3.0,
      price_52w_h: 8.99,
      price_52w_l: 1.85,
      price_pc_1d: -1.714,
      price_pc_1w: 6.832,
      price_pc_1m: -33.462,
      price_pc_6m: -58.354,
      price_pc_1y: 67.805,
      price_pc_ytd: 67.805,
    },
    1812: {
      id: "1812",
      c: 3.11,
      ma50: 3.676,
      ma100: 3.708,
      ma150: 3.914,
      ma200: 4.122,
      maw30: 3.916,
      rs: 20.0,
      rsd1d: -6.0,
      rsd5d: -8.0,
      rsd10d: -13.0,
      price_5d_h: 3.47,
      price_5d_L: 3.07,
      price_1m_h: 3.95,
      price_1m_l: 3.07,
      price_3m_h: 4.01,
      price_3m_l: 3.07,
      price_6m_h: 4.44,
      price_6m_l: 3.07,
      price_52w_h: 8.79,
      price_52w_l: 3.07,
      price_pc_1d: -9.593,
      price_pc_1w: -9.855,
      price_pc_1m: -17.507,
      price_pc_6m: -30.425,
      price_pc_1y: -59.025,
      price_pc_ytd: -59.025,
    },
    1813: {
      id: "1813",
      c: 3.24,
      ma50: 3.805,
      ma100: 4.785,
      ma150: 5.626,
      ma200: 6.655,
      maw30: 5.622,
      rs: 6.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: -2.0,
      price_5d_h: 3.57,
      price_5d_L: 2.92,
      price_1m_h: 3.88,
      price_1m_l: 2.21,
      price_3m_h: 5.29,
      price_3m_l: 2.21,
      price_6m_h: 8.3,
      price_6m_l: 2.21,
      price_52w_h: 14.08,
      price_52w_l: 2.21,
      price_pc_1d: -8.475,
      price_pc_1w: -1.52,
      price_pc_1m: -11.233,
      price_pc_6m: -57.424,
      price_pc_1y: -75.929,
      price_pc_ytd: -75.929,
    },
    1815: {
      id: "1815",
      c: 0.87,
      ma50: 1.13,
      ma100: 1.277,
      ma150: 1.068,
      ma200: 0.964,
      maw30: 1.099,
      rs: 76.0,
      rsd1d: 12.0,
      rsd5d: -2.0,
      rsd10d: 3.0,
      price_5d_h: 1.14,
      price_5d_L: 0.79,
      price_1m_h: 1.14,
      price_1m_l: 0.67,
      price_3m_h: 1.99,
      price_3m_l: 0.67,
      price_6m_h: 1.99,
      price_6m_l: 0.58,
      price_52w_h: 1.99,
      price_52w_l: 0.56,
      price_pc_1d: 4.819,
      price_pc_1w: 0.0,
      price_pc_1m: -13.0,
      price_pc_6m: 45.0,
      price_pc_1y: 35.937,
      price_pc_ytd: 35.937,
    },
    1816: {
      id: "1816",
      c: 2.05,
      ma50: 2.171,
      ma100: 2.19,
      ma150: 2.167,
      ma200: 2.049,
      maw30: 2.189,
      rs: 70.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: -14.0,
      price_5d_h: 2.08,
      price_5d_L: 1.97,
      price_1m_h: 2.25,
      price_1m_l: 1.89,
      price_3m_h: 2.48,
      price_3m_l: 1.89,
      price_6m_h: 2.55,
      price_6m_l: 1.89,
      price_52w_h: 2.55,
      price_52w_l: 1.59,
      price_pc_1d: 0.49,
      price_pc_1w: 0.0,
      price_pc_1m: -7.24,
      price_pc_6m: -13.502,
      price_pc_1y: 10.215,
      price_pc_ytd: 10.215,
    },
    1817: {
      id: "1817",
      c: 3.7,
      ma50: 4.588,
      ma100: 5.028,
      ma150: 5.25,
      ma200: 5.538,
      maw30: 5.24,
      rs: 18.0,
      rsd1d: 0.0,
      rsd5d: -8.0,
      rsd10d: -20.0,
      price_5d_h: 4.31,
      price_5d_L: 3.7,
      price_1m_h: 4.78,
      price_1m_l: 3.7,
      price_3m_h: 5.52,
      price_3m_l: 3.7,
      price_6m_h: 6.56,
      price_6m_l: 3.7,
      price_52w_h: 8.19,
      price_52w_l: 3.7,
      price_pc_1d: -3.896,
      price_pc_1w: -11.483,
      price_pc_1m: -18.502,
      price_pc_6m: -29.924,
      price_pc_1y: -46.609,
      price_pc_ytd: -46.609,
    },
    1818: {
      id: "1818",
      c: 6.91,
      ma50: 6.98,
      ma100: 6.869,
      ma150: 6.551,
      ma200: 6.727,
      maw30: 6.535,
      rs: 81.0,
      rsd1d: -5.0,
      rsd5d: -4.0,
      rsd10d: -8.0,
      price_5d_h: 7.61,
      price_5d_L: 6.84,
      price_1m_h: 8.44,
      price_1m_l: 6.34,
      price_3m_h: 8.44,
      price_3m_l: 5.75,
      price_6m_h: 8.44,
      price_6m_l: 5.31,
      price_52w_h: 8.74,
      price_52w_l: 5.31,
      price_pc_1d: -3.221,
      price_pc_1w: -5.472,
      price_pc_1m: -0.576,
      price_pc_6m: 26.095,
      price_pc_1y: -2.813,
      price_pc_ytd: -2.813,
    },
    1820: {
      id: "1820",
      c: 6.12,
      ma50: 6.179,
      ma100: 6.218,
      ma150: 6.234,
      ma200: 6.211,
      maw30: 6.239,
      rs: 72.0,
      rsd1d: 1.0,
      rsd5d: 2.0,
      rsd10d: -2.0,
      price_5d_h: 6.12,
      price_5d_L: 4.1,
      price_1m_h: 6.17,
      price_1m_l: 4.1,
      price_3m_h: 6.26,
      price_3m_l: 4.1,
      price_6m_h: 6.29,
      price_6m_l: 4.1,
      price_52w_h: 6.31,
      price_52w_l: 4.1,
      price_pc_1d: 0.164,
      price_pc_1w: 0.164,
      price_pc_1m: -0.81,
      price_pc_6m: -2.548,
      price_pc_1y: 1.493,
      price_pc_ytd: 1.493,
    },
    1821: {
      id: "1821",
      c: 24.35,
      ma50: 24.931,
      ma100: 25.546,
      ma150: 25.036,
      ma200: 25.139,
      maw30: 25.165,
      rs: 66.0,
      rsd1d: 1.0,
      rsd5d: -2.0,
      rsd10d: -5.0,
      price_5d_h: 25.0,
      price_5d_L: 23.2,
      price_1m_h: 26.5,
      price_1m_l: 22.0,
      price_3m_h: 27.5,
      price_3m_l: 22.0,
      price_6m_h: 27.8,
      price_6m_l: 22.0,
      price_52w_h: 28.7,
      price_52w_l: 21.9,
      price_pc_1d: -0.205,
      price_pc_1w: -0.409,
      price_pc_1m: 0.828,
      price_pc_6m: 2.96,
      price_pc_1y: -2.012,
      price_pc_ytd: -2.012,
    },
    1823: {
      id: "1823",
      c: 2.48,
      ma50: 3.598,
      ma100: 3.447,
      ma150: 4.0,
      ma200: 3.404,
      maw30: 4.104,
      rs: 93.0,
      rsd1d: 0.0,
      rsd5d: -4.0,
      rsd10d: -4.0,
      price_5d_h: 4.02,
      price_5d_L: 2.13,
      price_1m_h: 4.2,
      price_1m_l: 2.13,
      price_3m_h: 4.43,
      price_3m_l: 2.13,
      price_6m_h: 5.66,
      price_6m_l: 2.13,
      price_52w_h: 7.7,
      price_52w_l: 0.74,
      price_pc_1d: 3.333,
      price_pc_1w: -36.41,
      price_pc_1m: -37.688,
      price_pc_6m: -56.491,
      price_pc_1y: 244.444,
      price_pc_ytd: 244.444,
    },
    1825: {
      id: "1825",
      c: 0.235,
      ma50: 0.2,
      ma100: 0.193,
      ma150: 0.194,
      ma200: 0.561,
      maw30: 0.193,
      rs: 43.0,
      rsd1d: 0.0,
      rsd5d: 11.0,
      rsd10d: -17.0,
      price_5d_h: 0.245,
      price_5d_L: 0.222,
      price_1m_h: 0.35,
      price_1m_l: 0.168,
      price_3m_h: 0.35,
      price_3m_l: 0.168,
      price_6m_h: 0.35,
      price_6m_l: 0.168,
      price_52w_h: 3.68,
      price_52w_l: 0.168,
      price_pc_1d: -1.261,
      price_pc_1w: -3.689,
      price_pc_1m: 38.235,
      price_pc_6m: 19.898,
      price_pc_1y: -69.872,
      price_pc_ytd: -69.872,
    },
    1827: {
      id: "1827",
      c: 1.3,
      ma50: 1.404,
      ma100: 1.546,
      ma150: 1.573,
      ma200: 1.469,
      maw30: 1.603,
      rs: 76.0,
      rsd1d: 8.0,
      rsd5d: -6.0,
      rsd10d: 12.0,
      price_5d_h: 1.3,
      price_5d_L: 1.21,
      price_1m_h: 1.36,
      price_1m_l: 1.15,
      price_3m_h: 1.83,
      price_3m_l: 1.15,
      price_6m_h: 1.93,
      price_6m_l: 1.15,
      price_52w_h: 2.35,
      price_52w_l: 0.75,
      price_pc_1d: 4.0,
      price_pc_1w: 0.0,
      price_pc_1m: -2.256,
      price_pc_6m: -21.212,
      price_pc_1y: 73.333,
      price_pc_ytd: 73.333,
    },
    1830: {
      id: "1830",
      c: 4.85,
      ma50: 4.655,
      ma100: 5.211,
      ma150: 5.532,
      ma200: 6.27,
      maw30: 5.546,
      rs: 40.0,
      rsd1d: 2.0,
      rsd5d: 2.0,
      rsd10d: 11.0,
      price_5d_h: 4.94,
      price_5d_L: 4.53,
      price_1m_h: 4.98,
      price_1m_l: 3.66,
      price_3m_h: 5.65,
      price_3m_l: 3.66,
      price_6m_h: 6.8,
      price_6m_l: 3.66,
      price_52w_h: 10.9,
      price_52w_l: 3.66,
      price_pc_1d: 1.042,
      price_pc_1w: 2.105,
      price_pc_1m: 9.234,
      price_pc_6m: -18.212,
      price_pc_1y: 2.537,
      price_pc_ytd: 2.537,
    },
    1832: {
      id: "1832",
      c: 0.78,
      ma50: 0.832,
      ma100: 0.883,
      ma150: 0.915,
      ma200: 0.932,
      maw30: 0.915,
      rs: 55.0,
      rsd1d: 0.0,
      rsd5d: -12.0,
      rsd10d: 12.0,
      price_5d_h: 0.86,
      price_5d_L: 0.75,
      price_1m_h: 0.86,
      price_1m_l: 0.69,
      price_3m_h: 0.92,
      price_3m_l: 0.69,
      price_6m_h: 1.09,
      price_6m_l: 0.69,
      price_52w_h: 1.17,
      price_52w_l: 0.69,
      price_pc_1d: -1.266,
      price_pc_1w: -8.235,
      price_pc_1m: -11.364,
      price_pc_6m: -13.333,
      price_pc_1y: 2.632,
      price_pc_ytd: 2.632,
    },
    1833: {
      id: "1833",
      c: 20.6,
      ma50: 23.406,
      ma100: 26.563,
      ma150: 34.697,
      ma200: 46.096,
      maw30: 34.381,
      rs: 6.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: 1.0,
      price_5d_h: 22.2,
      price_5d_L: 19.1,
      price_1m_h: 24.85,
      price_1m_l: 15.18,
      price_3m_h: 29.9,
      price_3m_l: 15.18,
      price_6m_h: 56.4,
      price_6m_l: 15.18,
      price_52w_h: 105.9,
      price_52w_l: 15.18,
      price_pc_1d: -1.435,
      price_pc_1w: -7.207,
      price_pc_1m: -14.523,
      price_pc_6m: -59.289,
      price_pc_1y: -80.058,
      price_pc_ytd: -80.058,
    },
    1836: {
      id: "1836",
      c: 7.61,
      ma50: 8.368,
      ma100: 8.733,
      ma150: 9.116,
      ma200: 9.387,
      maw30: 9.148,
      rs: 29.0,
      rsd1d: 1.0,
      rsd5d: -18.0,
      rsd10d: -22.0,
      price_5d_h: 8.1,
      price_5d_L: 7.3,
      price_1m_h: 9.0,
      price_1m_l: 7.3,
      price_3m_h: 9.29,
      price_3m_l: 7.3,
      price_6m_h: 10.2,
      price_6m_l: 7.3,
      price_52w_h: 12.26,
      price_52w_l: 7.3,
      price_pc_1d: -0.653,
      price_pc_1w: -5.23,
      price_pc_1m: -4.156,
      price_pc_6m: -23.9,
      price_pc_1y: -25.246,
      price_pc_ytd: -25.246,
    },
    1837: {
      id: "1837",
      c: 0.42,
      ma50: 0.466,
      ma100: 0.509,
      ma150: 0.525,
      ma200: 0.519,
      maw30: 0.528,
      rs: 33.0,
      rsd1d: -8.0,
      rsd5d: -5.0,
      rsd10d: 3.0,
      price_5d_h: 0.48,
      price_5d_L: 0.41,
      price_1m_h: 0.49,
      price_1m_l: 0.36,
      price_3m_h: 0.52,
      price_3m_l: 0.36,
      price_6m_h: 0.67,
      price_6m_l: 0.36,
      price_52w_h: 0.68,
      price_52w_l: 0.36,
      price_pc_1d: -5.618,
      price_pc_1w: -8.696,
      price_pc_1m: -8.696,
      price_pc_6m: -23.636,
      price_pc_1y: -36.364,
      price_pc_ytd: -36.364,
    },
    1842: {
      id: "1842",
      c: 0.96,
      ma50: 0.522,
      ma100: 0.439,
      ma150: 0.378,
      ma200: 0.348,
      maw30: 0.371,
      rs: 98.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.97,
      price_5d_L: 0.64,
      price_1m_h: 0.97,
      price_1m_l: 0.38,
      price_3m_h: 0.97,
      price_3m_l: 0.28,
      price_6m_h: 0.97,
      price_6m_l: 0.23,
      price_52w_h: 0.97,
      price_52w_l: 0.22,
      price_pc_1d: 5.495,
      price_pc_1w: 35.211,
      price_pc_1m: 140.0,
      price_pc_6m: 284.0,
      price_pc_1y: 236.842,
      price_pc_ytd: 236.842,
    },
    1843: {
      id: "1843",
      c: 0.24,
      ma50: 0.243,
      ma100: 0.262,
      ma150: 0.28,
      ma200: 0.309,
      maw30: 0.28,
      rs: 25.0,
      rsd1d: 3.0,
      rsd5d: 0.0,
      rsd10d: -12.0,
      price_5d_h: 0.25,
      price_5d_L: 0.22,
      price_1m_h: 0.25,
      price_1m_l: 0.22,
      price_3m_h: 0.3,
      price_3m_l: 0.22,
      price_6m_h: 0.325,
      price_6m_l: 0.22,
      price_52w_h: 0.465,
      price_52w_l: 0.22,
      price_pc_1d: 1.266,
      price_pc_1w: 3.448,
      price_pc_1m: 4.348,
      price_pc_6m: -25.0,
      price_pc_1y: -30.435,
      price_pc_ytd: -30.435,
    },
    1845: {
      id: "1845",
      c: 0.355,
      ma50: 0.338,
      ma100: 0.364,
      ma150: 0.394,
      ma200: 0.398,
      maw30: 0.392,
      rs: 65.0,
      rsd1d: 3.0,
      rsd5d: 9.0,
      rsd10d: 1.0,
      price_5d_h: 0.365,
      price_5d_L: 0.32,
      price_1m_h: 0.375,
      price_1m_l: 0.3,
      price_3m_h: 0.41,
      price_3m_l: 0.3,
      price_6m_h: 0.51,
      price_6m_l: 0.3,
      price_52w_h: 0.66,
      price_52w_l: 0.3,
      price_pc_1d: 0.0,
      price_pc_1w: 4.412,
      price_pc_1m: 2.899,
      price_pc_6m: -21.111,
      price_pc_1y: 0.0,
      price_pc_ytd: 0.0,
    },
    1846: {
      id: "1846",
      c: 6.98,
      ma50: 7.72,
      ma100: 8.365,
      ma150: 9.251,
      ma200: 9.969,
      maw30: 9.213,
      rs: 26.0,
      rsd1d: 1.0,
      rsd5d: -3.0,
      rsd10d: -1.0,
      price_5d_h: 7.49,
      price_5d_L: 6.94,
      price_1m_h: 8.03,
      price_1m_l: 6.01,
      price_3m_h: 9.68,
      price_3m_l: 6.01,
      price_6m_h: 11.0,
      price_6m_l: 6.01,
      price_52w_h: 14.9,
      price_52w_l: 6.01,
      price_pc_1d: -1.133,
      price_pc_1w: -4.121,
      price_pc_1m: -8.877,
      price_pc_6m: -34.151,
      price_pc_1y: -33.397,
      price_pc_ytd: -33.397,
    },
    1847: {
      id: "1847",
      c: 1.08,
      ma50: 1.265,
      ma100: 1.29,
      ma150: 1.36,
      ma200: 1.426,
      maw30: 1.364,
      rs: 26.0,
      rsd1d: -9.0,
      rsd5d: -5.0,
      rsd10d: -12.0,
      price_5d_h: 1.19,
      price_5d_L: 1.07,
      price_1m_h: 1.29,
      price_1m_l: 1.0,
      price_3m_h: 1.43,
      price_3m_l: 1.0,
      price_6m_h: 1.53,
      price_6m_l: 1.0,
      price_52w_h: 1.89,
      price_52w_l: 1.0,
      price_pc_1d: -9.244,
      price_pc_1w: -7.692,
      price_pc_1m: -15.625,
      price_pc_6m: -27.517,
      price_pc_1y: -40.659,
      price_pc_ytd: -40.659,
    },
    1848: {
      id: "1848",
      c: 5.45,
      ma50: 5.469,
      ma100: 5.381,
      ma150: 5.371,
      ma200: 5.485,
      maw30: 5.377,
      rs: 65.0,
      rsd1d: 2.0,
      rsd5d: -2.0,
      rsd10d: -2.0,
      price_5d_h: 5.48,
      price_5d_L: 5.26,
      price_1m_h: 5.65,
      price_1m_l: 4.95,
      price_3m_h: 5.9,
      price_3m_l: 4.95,
      price_6m_h: 5.9,
      price_6m_l: 4.95,
      price_52w_h: 6.48,
      price_52w_l: 4.95,
      price_pc_1d: 0.926,
      price_pc_1w: -0.547,
      price_pc_1m: -3.54,
      price_pc_6m: 6.863,
      price_pc_1y: -13.766,
      price_pc_ytd: -13.766,
    },
    1850: {
      id: "1850",
      c: 0.208,
      ma50: 0.193,
      ma100: 0.336,
      ma150: 0.505,
      ma200: 0.897,
      maw30: 0.494,
      rs: 10.0,
      rsd1d: 0.0,
      rsd5d: -7.0,
      rsd10d: 3.0,
      price_5d_h: 0.275,
      price_5d_L: 0.201,
      price_1m_h: 0.32,
      price_1m_l: 0.15,
      price_3m_h: 0.32,
      price_3m_l: 0.15,
      price_6m_h: 1.5,
      price_6m_l: 0.15,
      price_52w_h: 3.87,
      price_52w_l: 0.15,
      price_pc_1d: -4.147,
      price_pc_1w: -18.431,
      price_pc_1m: 10.053,
      price_pc_6m: -68.0,
      price_pc_1y: -25.714,
      price_pc_ytd: -25.714,
    },
    1853: {
      id: "1853",
      c: 1.83,
      ma50: 1.968,
      ma100: 1.982,
      ma150: 1.996,
      ma200: 2.012,
      maw30: 2.003,
      rs: 55.0,
      rsd1d: -12.0,
      rsd5d: 8.0,
      rsd10d: 10.0,
      price_5d_h: 1.99,
      price_5d_L: 1.75,
      price_1m_h: 1.99,
      price_1m_l: 1.64,
      price_3m_h: 2.18,
      price_3m_l: 1.64,
      price_6m_h: 2.18,
      price_6m_l: 1.64,
      price_52w_h: 2.5,
      price_52w_l: 1.64,
      price_pc_1d: -6.633,
      price_pc_1w: 2.235,
      price_pc_1m: -3.684,
      price_pc_6m: -8.5,
      price_pc_1y: -15.668,
      price_pc_ytd: -15.668,
    },
    1854: {
      id: "1854",
      c: 0.29,
      ma50: 0.32,
      ma100: 0.299,
      ma150: 0.271,
      ma200: 0.245,
      maw30: 0.279,
      rs: 96.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: -1.0,
      price_5d_h: 0.305,
      price_5d_L: 0.28,
      price_1m_h: 0.33,
      price_1m_l: 0.28,
      price_3m_h: 0.365,
      price_3m_l: 0.28,
      price_6m_h: 0.365,
      price_6m_l: 0.216,
      price_52w_h: 0.365,
      price_52w_l: 0.078,
      price_pc_1d: -3.333,
      price_pc_1w: -1.695,
      price_pc_1m: -6.452,
      price_pc_6m: 9.434,
      price_pc_1y: 271.795,
      price_pc_ytd: 271.795,
    },
    1855: {
      id: "1855",
      c: 2.48,
      ma50: 2.416,
      ma100: 2.519,
      ma150: 2.366,
      ma200: 2.282,
      maw30: 2.385,
      rs: 83.0,
      rsd1d: 2.0,
      rsd5d: 2.0,
      rsd10d: 12.0,
      price_5d_h: 2.59,
      price_5d_L: 2.3,
      price_1m_h: 2.59,
      price_1m_l: 2.09,
      price_3m_h: 2.86,
      price_3m_l: 2.09,
      price_6m_h: 3.25,
      price_6m_l: 1.99,
      price_52w_h: 3.25,
      price_52w_l: 1.86,
      price_pc_1d: 0.813,
      price_pc_1w: 0.405,
      price_pc_1m: 6.438,
      price_pc_6m: 21.569,
      price_pc_1y: 6.438,
      price_pc_ytd: 6.438,
    },
    1856: {
      id: "1856",
      c: 2.53,
      ma50: 2.444,
      ma100: 2.495,
      ma150: 2.525,
      ma200: 2.547,
      maw30: 2.538,
      rs: 70.0,
      rsd1d: -8.0,
      rsd5d: 14.0,
      rsd10d: 30.0,
      price_5d_h: 2.62,
      price_5d_L: 2.26,
      price_1m_h: 2.63,
      price_1m_l: 2.14,
      price_3m_h: 2.69,
      price_3m_l: 2.14,
      price_6m_h: 2.78,
      price_6m_l: 2.14,
      price_52w_h: 2.79,
      price_52w_l: 2.14,
      price_pc_1d: -0.394,
      price_pc_1w: 5.417,
      price_pc_1m: -1.938,
      price_pc_6m: -2.692,
      price_pc_1y: -8.333,
      price_pc_ytd: -8.333,
    },
    1857: {
      id: "1857",
      c: 1.76,
      ma50: 1.794,
      ma100: 1.775,
      ma150: 1.777,
      ma200: 1.703,
      maw30: 1.79,
      rs: 81.0,
      rsd1d: -2.0,
      rsd5d: -1.0,
      rsd10d: -2.0,
      price_5d_h: 1.78,
      price_5d_L: 1.73,
      price_1m_h: 1.85,
      price_1m_l: 1.64,
      price_3m_h: 1.99,
      price_3m_l: 1.64,
      price_6m_h: 2.23,
      price_6m_l: 1.62,
      price_52w_h: 2.23,
      price_52w_l: 1.34,
      price_pc_1d: -0.565,
      price_pc_1w: 0.571,
      price_pc_1m: -3.297,
      price_pc_6m: -3.297,
      price_pc_1y: 16.556,
      price_pc_ytd: 16.556,
    },
    1858: {
      id: "1858",
      c: 9.42,
      ma50: 10.868,
      ma100: 13.791,
      ma150: 15.382,
      ma200: 17.299,
      maw30: 15.307,
      rs: 10.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: 1.0,
      price_5d_h: 10.62,
      price_5d_L: 9.09,
      price_1m_h: 11.3,
      price_1m_l: 7.63,
      price_3m_h: 15.16,
      price_3m_l: 7.63,
      price_6m_h: 22.2,
      price_6m_l: 7.63,
      price_52w_h: 34.95,
      price_52w_l: 7.63,
      price_pc_1d: -3.385,
      price_pc_1w: -10.795,
      price_pc_1m: -11.963,
      price_pc_6m: -43.593,
      price_pc_1y: -51.04,
      price_pc_ytd: -51.04,
    },
    1859: {
      id: "1859",
      c: 0.325,
      ma50: 0.526,
      ma100: 0.606,
      ma150: 0.686,
      ma200: 0.764,
      maw30: 0.682,
      rs: 4.0,
      rsd1d: 1.0,
      rsd5d: -3.0,
      rsd10d: -3.0,
      price_5d_h: 0.44,
      price_5d_L: 0.28,
      price_1m_h: 0.57,
      price_1m_l: 0.28,
      price_3m_h: 0.76,
      price_3m_l: 0.28,
      price_6m_h: 0.89,
      price_6m_l: 0.28,
      price_52w_h: 1.17,
      price_52w_l: 0.28,
      price_pc_1d: 1.563,
      price_pc_1w: -26.136,
      price_pc_1m: -37.5,
      price_pc_6m: -59.877,
      price_pc_1y: -67.5,
      price_pc_ytd: -67.5,
    },
    1860: {
      id: "1860",
      c: 5.29,
      ma50: 5.824,
      ma100: 6.328,
      ma150: 6.709,
      ma200: 7.067,
      maw30: 6.706,
      rs: 27.0,
      rsd1d: 1.0,
      rsd5d: -6.0,
      rsd10d: 6.0,
      price_5d_h: 5.5,
      price_5d_L: 4.4,
      price_1m_h: 6.24,
      price_1m_l: 3.8,
      price_3m_h: 7.29,
      price_3m_l: 3.8,
      price_6m_h: 8.65,
      price_6m_l: 3.8,
      price_52w_h: 11.3,
      price_52w_l: 3.8,
      price_pc_1d: -0.189,
      price_pc_1w: -1.306,
      price_pc_1m: -15.36,
      price_pc_6m: -26.22,
      price_pc_1y: -17.601,
      price_pc_ytd: -17.601,
    },
    1861: {
      id: "1861",
      c: 2.33,
      ma50: 2.049,
      ma100: 2.111,
      ma150: 2.323,
      ma200: 2.687,
      maw30: 2.318,
      rs: 32.0,
      rsd1d: 0.0,
      rsd5d: 5.0,
      rsd10d: 10.0,
      price_5d_h: 2.45,
      price_5d_L: 2.04,
      price_1m_h: 2.45,
      price_1m_l: 1.71,
      price_3m_h: 2.5,
      price_3m_l: 1.71,
      price_6m_h: 2.9,
      price_6m_l: 1.71,
      price_52w_h: 4.91,
      price_52w_l: 1.71,
      price_pc_1d: 1.747,
      price_pc_1w: 2.193,
      price_pc_1m: 16.5,
      price_pc_6m: -19.377,
      price_pc_1y: -39.948,
      price_pc_ytd: -39.948,
    },
    1862: {
      id: "1862",
      c: 1.66,
      ma50: 1.854,
      ma100: 1.893,
      ma150: 2.029,
      ma200: 2.092,
      maw30: 2.029,
      rs: 39.0,
      rsd1d: -1.0,
      rsd5d: -10.0,
      rsd10d: -2.0,
      price_5d_h: 1.79,
      price_5d_L: 1.65,
      price_1m_h: 1.89,
      price_1m_l: 1.52,
      price_3m_h: 2.2,
      price_3m_l: 1.52,
      price_6m_h: 2.57,
      price_6m_l: 1.52,
      price_52w_h: 2.7,
      price_52w_l: 1.52,
      price_pc_1d: -2.353,
      price_pc_1w: -4.046,
      price_pc_1m: -11.702,
      price_pc_6m: -28.755,
      price_pc_1y: -28.139,
      price_pc_ytd: -28.139,
    },
    1865: {
      id: "1865",
      c: 1.2,
      ma50: 0.985,
      ma100: 0.973,
      ma150: 1.005,
      ma200: 1.051,
      maw30: 1.001,
      rs: 87.0,
      rsd1d: -1.0,
      rsd5d: 23.0,
      rsd10d: 21.0,
      price_5d_h: 1.22,
      price_5d_L: 0.98,
      price_1m_h: 1.22,
      price_1m_l: 0.89,
      price_3m_h: 1.22,
      price_3m_l: 0.84,
      price_6m_h: 1.22,
      price_6m_l: 0.82,
      price_52w_h: 1.5,
      price_52w_l: 0.82,
      price_pc_1d: 1.695,
      price_pc_1w: 22.449,
      price_pc_1m: 25.0,
      price_pc_6m: 14.286,
      price_pc_1y: 20.0,
      price_pc_ytd: 20.0,
    },
    1866: {
      id: "1866",
      c: 7.61,
      ma50: 6.315,
      ma100: 5.776,
      ma150: 5.817,
      ma200: 5.544,
      maw30: 5.811,
      rs: 95.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: 2.0,
      price_5d_h: 8.3,
      price_5d_L: 7.38,
      price_1m_h: 8.3,
      price_1m_l: 5.08,
      price_3m_h: 8.3,
      price_3m_l: 5.08,
      price_6m_h: 8.3,
      price_6m_l: 4.55,
      price_52w_h: 8.3,
      price_52w_l: 3.33,
      price_pc_1d: -1.933,
      price_pc_1w: -2.436,
      price_pc_1m: 26.833,
      price_pc_6m: 24.754,
      price_pc_1y: 104.021,
      price_pc_ytd: 104.021,
    },
    1868: {
      id: "1868",
      c: 0.475,
      ma50: 0.482,
      ma100: 0.481,
      ma150: 0.488,
      ma200: 0.487,
      maw30: 0.491,
      rs: 70.0,
      rsd1d: 1.0,
      rsd5d: 3.0,
      rsd10d: -16.0,
      price_5d_h: 0.475,
      price_5d_L: 0.465,
      price_1m_h: 0.54,
      price_1m_l: 0.455,
      price_3m_h: 0.54,
      price_3m_l: 0.455,
      price_6m_h: 0.54,
      price_6m_l: 0.45,
      price_52w_h: 0.55,
      price_52w_l: 0.45,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: -1.042,
      price_pc_6m: -8.654,
      price_pc_1y: -1.042,
      price_pc_ytd: -1.042,
    },
    1870: {
      id: "1870",
      c: 1.24,
      ma50: 1.111,
      ma100: 1.249,
      ma150: 1.17,
      ma200: 0.948,
      maw30: 1.194,
      rs: 98.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 1.28,
      price_5d_L: 1.16,
      price_1m_h: 1.28,
      price_1m_l: 0.67,
      price_3m_h: 1.89,
      price_3m_l: 0.67,
      price_6m_h: 1.93,
      price_6m_l: 0.67,
      price_52w_h: 1.93,
      price_52w_l: 0.19,
      price_pc_1d: -0.8,
      price_pc_1w: -0.8,
      price_pc_1m: 12.727,
      price_pc_6m: 7.826,
      price_pc_1y: 532.653,
      price_pc_ytd: 532.653,
    },
    1871: {
      id: "1871",
      c: 0.232,
      ma50: 0.256,
      ma100: 0.262,
      ma150: 0.271,
      ma200: 0.281,
      maw30: 0.27,
      rs: 46.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: -10.0,
      price_5d_h: 0.245,
      price_5d_L: 0.23,
      price_1m_h: 0.26,
      price_1m_l: 0.22,
      price_3m_h: 0.285,
      price_3m_l: 0.22,
      price_6m_h: 0.31,
      price_6m_l: 0.22,
      price_52w_h: 0.385,
      price_52w_l: 0.22,
      price_pc_1d: -1.695,
      price_pc_1w: -2.929,
      price_pc_1m: -12.453,
      price_pc_6m: -14.074,
      price_pc_1y: -14.074,
      price_pc_ytd: -14.074,
    },
    1872: {
      id: "1872",
      c: 0.18,
      ma50: 0.182,
      ma100: 0.185,
      ma150: 0.183,
      ma200: 0.177,
      maw30: 0.183,
      rs: 83.0,
      rsd1d: -3.0,
      rsd5d: -2.0,
      rsd10d: 0.0,
      price_5d_h: 0.187,
      price_5d_L: 0.18,
      price_1m_h: 0.187,
      price_1m_l: 0.152,
      price_3m_h: 0.2,
      price_3m_l: 0.152,
      price_6m_h: 0.215,
      price_6m_l: 0.152,
      price_52w_h: 0.215,
      price_52w_l: 0.142,
      price_pc_1d: -1.639,
      price_pc_1w: 0.0,
      price_pc_1m: -5.263,
      price_pc_6m: 5.263,
      price_pc_1y: 18.421,
      price_pc_ytd: 18.421,
    },
    1873: {
      id: "1873",
      c: 3.1,
      ma50: 3.306,
      ma100: 4.086,
      ma150: 4.772,
      ma200: 5.742,
      maw30: 4.776,
      rs: 9.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 2.0,
      price_5d_h: 3.31,
      price_5d_L: 2.92,
      price_1m_h: 3.35,
      price_1m_l: 2.35,
      price_3m_h: 5.55,
      price_3m_l: 2.35,
      price_6m_h: 6.29,
      price_6m_l: 2.35,
      price_52w_h: 11.64,
      price_52w_l: 2.35,
      price_pc_1d: -3.427,
      price_pc_1w: -4.615,
      price_pc_1m: 3.333,
      price_pc_6m: -49.346,
      price_pc_1y: -55.072,
      price_pc_ytd: -55.072,
    },
    1875: {
      id: "1875",
      c: 3.18,
      ma50: 3.395,
      ma100: 3.756,
      ma150: 3.925,
      ma200: 4.024,
      maw30: 3.949,
      rs: 25.0,
      rsd1d: 3.0,
      rsd5d: -1.0,
      rsd10d: 4.0,
      price_5d_h: 3.2,
      price_5d_L: 3.01,
      price_1m_h: 3.53,
      price_1m_l: 2.46,
      price_3m_h: 4.29,
      price_3m_l: 2.46,
      price_6m_h: 4.75,
      price_6m_l: 2.46,
      price_52w_h: 5.0,
      price_52w_l: 2.46,
      price_pc_1d: 0.952,
      price_pc_1w: 0.952,
      price_pc_1m: -9.915,
      price_pc_6m: -29.018,
      price_pc_1y: -27.397,
      price_pc_ytd: -27.397,
    },
    1876: {
      id: "1876",
      c: 20.85,
      ma50: 22.069,
      ma100: 21.319,
      ma150: 20.765,
      ma200: 21.376,
      maw30: 20.96,
      rs: 69.0,
      rsd1d: 1.0,
      rsd5d: 3.0,
      rsd10d: -5.0,
      price_5d_h: 21.2,
      price_5d_L: 19.42,
      price_1m_h: 25.4,
      price_1m_l: 19.42,
      price_3m_h: 25.4,
      price_3m_l: 19.3,
      price_6m_h: 25.4,
      price_6m_l: 18.54,
      price_52w_h: 27.8,
      price_52w_l: 18.18,
      price_pc_1d: -0.714,
      price_pc_1w: -0.239,
      price_pc_1m: -14.021,
      price_pc_6m: 5.623,
      price_pc_1y: -11.087,
      price_pc_ytd: -11.087,
    },
    1877: {
      id: "1877",
      c: 55.7,
      ma50: 52.214,
      ma100: 48.893,
      ma150: 46.39,
      ma200: 49.113,
      maw30: 46.492,
      rs: 70.0,
      rsd1d: -17.0,
      rsd5d: -20.0,
      rsd10d: -19.0,
      price_5d_h: 63.1,
      price_5d_L: 55.5,
      price_1m_h: 64.55,
      price_1m_l: 45.0,
      price_3m_h: 64.55,
      price_3m_l: 41.2,
      price_6m_h: 64.55,
      price_6m_l: 37.1,
      price_52w_h: 87.6,
      price_52w_l: 36.5,
      price_pc_1d: -7.012,
      price_pc_1w: -10.594,
      price_pc_1m: 5.293,
      price_pc_6m: 40.657,
      price_pc_1y: -31.319,
      price_pc_ytd: -31.319,
    },
    1878: {
      id: "1878",
      c: 1.2,
      ma50: 1.309,
      ma100: 1.277,
      ma150: 1.415,
      ma200: 1.398,
      maw30: 1.435,
      rs: 52.0,
      rsd1d: -1.0,
      rsd5d: 4.0,
      rsd10d: 15.0,
      price_5d_h: 1.29,
      price_5d_L: 1.0,
      price_1m_h: 1.47,
      price_1m_l: 1.0,
      price_3m_h: 1.5,
      price_3m_l: 1.0,
      price_6m_h: 1.95,
      price_6m_l: 0.99,
      price_52w_h: 2.31,
      price_52w_l: 0.99,
      price_pc_1d: -4.0,
      price_pc_1w: 0.0,
      price_pc_1m: -11.111,
      price_pc_6m: -38.462,
      price_pc_1y: -9.774,
      price_pc_ytd: -9.774,
    },
    1881: {
      id: "1881",
      c: 1.48,
      ma50: 1.461,
      ma100: 1.45,
      ma150: 1.449,
      ma200: 1.499,
      maw30: 1.443,
      rs: 73.0,
      rsd1d: -3.0,
      rsd5d: -5.0,
      rsd10d: 0.0,
      price_5d_h: 1.52,
      price_5d_L: 1.47,
      price_1m_h: 1.52,
      price_1m_l: 1.34,
      price_3m_h: 1.53,
      price_3m_l: 1.34,
      price_6m_h: 1.53,
      price_6m_l: 1.34,
      price_52w_h: 1.8,
      price_52w_l: 1.32,
      price_pc_1d: -1.333,
      price_pc_1w: -1.987,
      price_pc_1m: 2.069,
      price_pc_6m: 8.029,
      price_pc_1y: -8.075,
      price_pc_ytd: -8.075,
    },
    1882: {
      id: "1882",
      c: 20.35,
      ma50: 20.231,
      ma100: 20.611,
      ma150: 22.378,
      ma200: 23.591,
      maw30: 22.221,
      rs: 41.0,
      rsd1d: 2.0,
      rsd5d: -6.0,
      rsd10d: 5.0,
      price_5d_h: 21.2,
      price_5d_L: 19.4,
      price_1m_h: 21.3,
      price_1m_l: 16.26,
      price_3m_h: 23.1,
      price_3m_l: 16.26,
      price_6m_h: 24.95,
      price_6m_l: 16.26,
      price_52w_h: 32.55,
      price_52w_l: 16.26,
      price_pc_1d: 0.993,
      price_pc_1w: -0.732,
      price_pc_1m: -2.398,
      price_pc_6m: -15.385,
      price_pc_1y: -34.984,
      price_pc_ytd: -34.984,
    },
    1883: {
      id: "1883",
      c: 2.88,
      ma50: 2.794,
      ma100: 2.73,
      ma150: 2.714,
      ma200: 2.676,
      maw30: 2.718,
      rs: 84.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: 0.0,
      price_5d_h: 2.93,
      price_5d_L: 2.85,
      price_1m_h: 2.93,
      price_1m_l: 2.62,
      price_3m_h: 2.93,
      price_3m_l: 2.62,
      price_6m_h: 2.93,
      price_6m_l: 2.59,
      price_52w_h: 2.93,
      price_52w_l: 2.47,
      price_pc_1d: 0.348,
      price_pc_1w: -1.031,
      price_pc_1m: 3.597,
      price_pc_6m: 8.679,
      price_pc_1y: 4.348,
      price_pc_ytd: 4.348,
    },
    1884: {
      id: "1884",
      c: 0.275,
      ma50: 0.283,
      ma100: 0.284,
      ma150: 0.285,
      ma200: 0.291,
      maw30: 0.287,
      rs: 52.0,
      rsd1d: 0.0,
      rsd5d: -2.0,
      rsd10d: -5.0,
      price_5d_h: 0.29,
      price_5d_L: 0.275,
      price_1m_h: 0.3,
      price_1m_l: 0.255,
      price_3m_h: 0.3,
      price_3m_l: 0.25,
      price_6m_h: 0.315,
      price_6m_l: 0.25,
      price_52w_h: 0.7,
      price_52w_l: 0.25,
      price_pc_1d: -3.509,
      price_pc_1w: -1.786,
      price_pc_1m: -1.786,
      price_pc_6m: -16.667,
      price_pc_1y: -15.385,
      price_pc_ytd: -15.385,
    },
    1888: {
      id: "1888",
      c: 12.9,
      ma50: 13.078,
      ma100: 13.172,
      ma150: 13.286,
      ma200: 13.986,
      maw30: 13.234,
      rs: 54.0,
      rsd1d: -2.0,
      rsd5d: -3.0,
      rsd10d: 7.0,
      price_5d_h: 13.1,
      price_5d_L: 12.68,
      price_1m_h: 13.8,
      price_1m_l: 10.88,
      price_3m_h: 14.68,
      price_3m_l: 10.88,
      price_6m_h: 14.88,
      price_6m_l: 10.88,
      price_52w_h: 19.76,
      price_52w_l: 10.88,
      price_pc_1d: -1.074,
      price_pc_1w: -1.074,
      price_pc_1m: -0.463,
      price_pc_6m: 0.155,
      price_pc_1y: -25.691,
      price_pc_ytd: -25.691,
    },
    1889: {
      id: "1889",
      c: 0.072,
      ma50: 0.078,
      ma100: 0.104,
      ma150: 0.121,
      ma200: 0.113,
      maw30: 0.124,
      rs: 15.0,
      rsd1d: 4.0,
      rsd5d: 9.0,
      rsd10d: 10.0,
      price_5d_h: 0.099,
      price_5d_L: 0.052,
      price_1m_h: 0.099,
      price_1m_l: 0.042,
      price_3m_h: 0.135,
      price_3m_l: 0.042,
      price_6m_h: 0.22,
      price_6m_l: 0.042,
      price_52w_h: 0.28,
      price_52w_l: 0.042,
      price_pc_1d: 7.463,
      price_pc_1w: 30.909,
      price_pc_1m: -4.0,
      price_pc_6m: -65.049,
      price_pc_1y: -20.0,
      price_pc_ytd: -20.0,
    },
    1890: {
      id: "1890",
      c: 2.21,
      ma50: 2.358,
      ma100: 3.012,
      ma150: 3.459,
      ma200: 3.834,
      maw30: 3.456,
      rs: 10.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: 1.0,
      price_5d_h: 2.33,
      price_5d_L: 2.07,
      price_1m_h: 2.36,
      price_1m_l: 1.62,
      price_3m_h: 3.27,
      price_3m_l: 1.62,
      price_6m_h: 4.84,
      price_6m_l: 1.62,
      price_52w_h: 6.28,
      price_52w_l: 1.62,
      price_pc_1d: -1.339,
      price_pc_1w: 0.455,
      price_pc_1m: -5.15,
      price_pc_6m: -46.489,
      price_pc_1y: -58.692,
      price_pc_ytd: -58.692,
    },
    1891: {
      id: "1891",
      c: 0.28,
      ma50: 0.26,
      ma100: 0.249,
      ma150: 0.237,
      ma200: 0.224,
      maw30: 0.236,
      rs: 94.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: 0.0,
      price_5d_h: 0.28,
      price_5d_L: 0.27,
      price_1m_h: 0.285,
      price_1m_l: 0.25,
      price_3m_h: 0.285,
      price_3m_l: 0.226,
      price_6m_h: 0.285,
      price_6m_l: 0.18,
      price_52w_h: 0.285,
      price_52w_l: 0.165,
      price_pc_1d: 1.818,
      price_pc_1w: 1.818,
      price_pc_1m: 1.818,
      price_pc_6m: 45.833,
      price_pc_1y: 60.92,
      price_pc_ytd: 60.92,
    },
    1896: {
      id: "1896",
      c: 6.52,
      ma50: 8.293,
      ma100: 8.723,
      ma150: 9.094,
      ma200: 9.564,
      maw30: 9.176,
      rs: 15.0,
      rsd1d: 0.0,
      rsd5d: -4.0,
      rsd10d: -3.0,
      price_5d_h: 7.14,
      price_5d_L: 6.13,
      price_1m_h: 8.59,
      price_1m_l: 5.3,
      price_3m_h: 10.96,
      price_3m_l: 5.3,
      price_6m_h: 10.96,
      price_6m_l: 5.3,
      price_52w_h: 15.8,
      price_52w_l: 5.3,
      price_pc_1d: -2.249,
      price_pc_1w: -8.683,
      price_pc_1m: -23.474,
      price_pc_6m: -31.078,
      price_pc_1y: -51.632,
      price_pc_ytd: -51.632,
    },
    1897: {
      id: "1897",
      c: 0.68,
      ma50: 0.763,
      ma100: 0.771,
      ma150: 0.789,
      ma200: 0.8,
      maw30: 0.792,
      rs: 40.0,
      rsd1d: 6.0,
      rsd5d: 11.0,
      rsd10d: -7.0,
      price_5d_h: 0.72,
      price_5d_L: 0.65,
      price_1m_h: 0.81,
      price_1m_l: 0.62,
      price_3m_h: 0.82,
      price_3m_l: 0.62,
      price_6m_h: 0.9,
      price_6m_l: 0.62,
      price_52w_h: 0.95,
      price_52w_l: 0.62,
      price_pc_1d: 3.03,
      price_pc_1w: 7.937,
      price_pc_1m: -16.049,
      price_pc_6m: -19.048,
      price_pc_1y: -20.93,
      price_pc_ytd: -20.93,
    },
    1898: {
      id: "1898",
      c: 5.89,
      ma50: 5.038,
      ma100: 4.773,
      ma150: 5.048,
      ma200: 4.95,
      maw30: 5.01,
      rs: 92.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: 10.0,
      price_5d_h: 6.0,
      price_5d_L: 5.41,
      price_1m_h: 6.0,
      price_1m_l: 4.11,
      price_3m_h: 6.0,
      price_3m_l: 4.11,
      price_6m_h: 6.87,
      price_6m_l: 3.92,
      price_52w_h: 6.87,
      price_52w_l: 3.49,
      price_pc_1d: 0.684,
      price_pc_1w: 4.618,
      price_pc_1m: 20.945,
      price_pc_6m: -1.008,
      price_pc_1y: 61.813,
      price_pc_ytd: 61.813,
    },
    1899: {
      id: "1899",
      c: 1.42,
      ma50: 1.482,
      ma100: 1.599,
      ma150: 1.656,
      ma200: 1.692,
      maw30: 1.666,
      rs: 34.0,
      rsd1d: 9.0,
      rsd5d: 10.0,
      rsd10d: 15.0,
      price_5d_h: 1.45,
      price_5d_L: 1.26,
      price_1m_h: 1.45,
      price_1m_l: 0.99,
      price_3m_h: 1.85,
      price_3m_l: 0.99,
      price_6m_h: 1.91,
      price_6m_l: 0.99,
      price_52w_h: 2.3,
      price_52w_l: 0.99,
      price_pc_1d: 6.767,
      price_pc_1w: 5.97,
      price_pc_1m: -1.389,
      price_pc_6m: -16.471,
      price_pc_1y: -31.068,
      price_pc_ytd: -31.068,
    },
    1900: {
      id: "1900",
      c: 0.17,
      ma50: 0.145,
      ma100: 0.154,
      ma150: 0.161,
      ma200: 0.168,
      maw30: 0.16,
      rs: 61.0,
      rsd1d: 7.0,
      rsd5d: 19.0,
      rsd10d: 39.0,
      price_5d_h: 0.17,
      price_5d_L: 0.136,
      price_1m_h: 0.17,
      price_1m_l: 0.118,
      price_3m_h: 0.17,
      price_3m_l: 0.118,
      price_6m_h: 0.178,
      price_6m_l: 0.118,
      price_52w_h: 0.229,
      price_52w_l: 0.118,
      price_pc_1d: 3.03,
      price_pc_1w: 11.842,
      price_pc_1m: 16.438,
      price_pc_6m: -3.955,
      price_pc_1y: -20.188,
      price_pc_ytd: -20.188,
    },
    1901: {
      id: "1901",
      c: 0.9,
      ma50: 1.012,
      ma100: 1.015,
      ma150: 0.968,
      ma200: 0.931,
      maw30: 0.97,
      rs: 74.0,
      rsd1d: 4.0,
      rsd5d: -13.0,
      rsd10d: -10.0,
      price_5d_h: 1.08,
      price_5d_L: 0.84,
      price_1m_h: 1.15,
      price_1m_l: 0.747,
      price_3m_h: 1.15,
      price_3m_l: 0.747,
      price_6m_h: 1.35,
      price_6m_l: 0.747,
      price_52w_h: 1.35,
      price_52w_l: 0.72,
      price_pc_1d: 0.0,
      price_pc_1w: -10.891,
      price_pc_1m: -20.354,
      price_pc_6m: 4.651,
      price_pc_1y: 9.756,
      price_pc_ytd: 9.756,
    },
    1902: {
      id: "1902",
      c: 2.53,
      ma50: 3.019,
      ma100: 2.84,
      ma150: 2.926,
      ma200: 2.946,
      maw30: 2.939,
      rs: 54.0,
      rsd1d: -18.0,
      rsd5d: -4.0,
      rsd10d: -14.0,
      price_5d_h: 2.8,
      price_5d_L: 2.4,
      price_1m_h: 3.14,
      price_1m_l: 2.27,
      price_3m_h: 4.18,
      price_3m_l: 2.27,
      price_6m_h: 4.18,
      price_6m_l: 2.21,
      price_52w_h: 7.97,
      price_52w_l: 2.21,
      price_pc_1d: -8.0,
      price_pc_1w: -2.317,
      price_pc_1m: -14.527,
      price_pc_6m: -27.714,
      price_pc_1y: -23.1,
      price_pc_ytd: -23.1,
    },
    1903: {
      id: "1903",
      c: 0.55,
      ma50: 0.543,
      ma100: 0.552,
      ma150: 0.578,
      ma200: 0.601,
      maw30: 0.581,
      rs: 66.0,
      rsd1d: 9.0,
      rsd5d: 6.0,
      rsd10d: 34.0,
      price_5d_h: 0.55,
      price_5d_L: 0.53,
      price_1m_h: 0.58,
      price_1m_l: 0.48,
      price_3m_h: 0.58,
      price_3m_l: 0.47,
      price_6m_h: 0.64,
      price_6m_l: 0.47,
      price_52w_h: 0.9,
      price_52w_l: 0.47,
      price_pc_1d: 3.774,
      price_pc_1w: 0.0,
      price_pc_1m: -1.786,
      price_pc_6m: -1.786,
      price_pc_1y: -8.333,
      price_pc_ytd: -8.333,
    },
    1905: {
      id: "1905",
      c: 0.81,
      ma50: 0.844,
      ma100: 0.93,
      ma150: 0.998,
      ma200: 1.064,
      maw30: 0.998,
      rs: 23.0,
      rsd1d: -1.0,
      rsd5d: -5.0,
      rsd10d: 9.0,
      price_5d_h: 0.85,
      price_5d_L: 0.78,
      price_1m_h: 0.89,
      price_1m_l: 0.56,
      price_3m_h: 1.05,
      price_3m_l: 0.56,
      price_6m_h: 1.19,
      price_6m_l: 0.56,
      price_52w_h: 1.82,
      price_52w_l: 0.56,
      price_pc_1d: 3.846,
      price_pc_1w: 0.0,
      price_pc_1m: -7.955,
      price_pc_6m: -24.299,
      price_pc_1y: -36.22,
      price_pc_ytd: -36.22,
    },
    1906: {
      id: "1906",
      c: 0.45,
      ma50: 0.447,
      ma100: 0.437,
      ma150: 0.437,
      ma200: 0.447,
      maw30: 0.434,
      rs: 77.0,
      rsd1d: -5.0,
      rsd5d: -6.0,
      rsd10d: 3.0,
      price_5d_h: 0.47,
      price_5d_L: 0.445,
      price_1m_h: 0.5,
      price_1m_l: 0.42,
      price_3m_h: 0.51,
      price_3m_l: 0.37,
      price_6m_h: 0.58,
      price_6m_l: 0.27,
      price_52w_h: 0.68,
      price_52w_l: 0.27,
      price_pc_1d: 0.0,
      price_pc_1w: -3.226,
      price_pc_1m: -1.099,
      price_pc_6m: 7.143,
      price_pc_1y: -13.462,
      price_pc_ytd: -13.462,
    },
    1907: {
      id: "1907",
      c: 4.15,
      ma50: 4.498,
      ma100: 4.579,
      ma150: 4.767,
      ma200: 4.844,
      maw30: 4.766,
      rs: 50.0,
      rsd1d: 0.0,
      rsd5d: -4.0,
      rsd10d: 11.0,
      price_5d_h: 4.48,
      price_5d_L: 3.97,
      price_1m_h: 4.89,
      price_1m_l: 3.58,
      price_3m_h: 5.02,
      price_3m_l: 3.58,
      price_6m_h: 5.34,
      price_6m_l: 3.58,
      price_52w_h: 6.48,
      price_52w_l: 3.58,
      price_pc_1d: -0.24,
      price_pc_1w: -5.467,
      price_pc_1m: -14.433,
      price_pc_6m: -15.992,
      price_pc_1y: -11.702,
      price_pc_ytd: -11.702,
    },
    1908: {
      id: "1908",
      c: 17.1,
      ma50: 15.045,
      ma100: 15.146,
      ma150: 15.095,
      ma200: 14.954,
      maw30: 15.143,
      rs: 87.0,
      rsd1d: -2.0,
      rsd5d: 0.0,
      rsd10d: 5.0,
      price_5d_h: 17.58,
      price_5d_L: 16.16,
      price_1m_h: 17.58,
      price_1m_l: 11.5,
      price_3m_h: 17.58,
      price_3m_l: 11.5,
      price_6m_h: 17.58,
      price_6m_l: 11.5,
      price_52w_h: 17.58,
      price_52w_l: 11.5,
      price_pc_1d: -1.042,
      price_pc_1w: 2.029,
      price_pc_1m: 17.931,
      price_pc_6m: 14.765,
      price_pc_1y: 17.284,
      price_pc_ytd: 17.284,
    },
    1909: {
      id: "1909",
      c: 0.56,
      ma50: 0.648,
      ma100: 1.149,
      ma150: 1.572,
      ma200: 1.855,
      maw30: 1.562,
      rs: 3.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: -6.0,
      price_5d_h: 0.67,
      price_5d_L: 0.435,
      price_1m_h: 1.18,
      price_1m_l: 0.36,
      price_3m_h: 1.18,
      price_3m_l: 0.36,
      price_6m_h: 2.91,
      price_6m_l: 0.36,
      price_52w_h: 3.64,
      price_52w_l: 0.36,
      price_pc_1d: 9.804,
      price_pc_1w: 16.667,
      price_pc_1m: 13.131,
      price_pc_6m: -74.312,
      price_pc_1y: -65.325,
      price_pc_ytd: -65.325,
    },
    1910: {
      id: "1910",
      c: 17.72,
      ma50: 16.576,
      ma100: 16.302,
      ma150: 16.319,
      ma200: 16.068,
      maw30: 16.361,
      rs: 86.0,
      rsd1d: -2.0,
      rsd5d: -1.0,
      rsd10d: -2.0,
      price_5d_h: 18.48,
      price_5d_L: 16.28,
      price_1m_h: 18.48,
      price_1m_l: 12.26,
      price_3m_h: 18.6,
      price_3m_l: 12.26,
      price_6m_h: 19.5,
      price_6m_l: 12.26,
      price_52w_h: 19.5,
      price_52w_l: 12.26,
      price_pc_1d: -0.561,
      price_pc_1w: 2.428,
      price_pc_1m: 1.839,
      price_pc_6m: 5.476,
      price_pc_1y: 19.892,
      price_pc_ytd: 19.892,
    },
    1911: {
      id: "1911",
      c: 9.69,
      ma50: 12.142,
      ma100: 13.946,
      ma150: 15.962,
      ma200: 17.073,
      maw30: 16.003,
      rs: 8.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: -3.0,
      price_5d_h: 10.52,
      price_5d_L: 9.35,
      price_1m_h: 11.76,
      price_1m_l: 7.98,
      price_3m_h: 15.96,
      price_3m_l: 7.98,
      price_6m_h: 21.85,
      price_6m_l: 7.98,
      price_52w_h: 32.15,
      price_52w_l: 7.98,
      price_pc_1d: -6.467,
      price_pc_1w: -6.286,
      price_pc_1m: -16.466,
      price_pc_6m: -50.103,
      price_pc_1y: -68.385,
      price_pc_ytd: -68.385,
    },
    1913: {
      id: "1913",
      c: 49.75,
      ma50: 45.963,
      ma100: 48.268,
      ma150: 47.525,
      ma200: 49.451,
      maw30: 47.778,
      rs: 75.0,
      rsd1d: -7.0,
      rsd5d: 12.0,
      rsd10d: 23.0,
      price_5d_h: 55.5,
      price_5d_L: 43.6,
      price_1m_h: 55.5,
      price_1m_l: 36.3,
      price_3m_h: 55.5,
      price_3m_l: 36.3,
      price_6m_h: 58.3,
      price_6m_l: 36.3,
      price_52w_h: 65.3,
      price_52w_l: 36.3,
      price_pc_1d: -2.927,
      price_pc_1w: 9.581,
      price_pc_1m: 2.051,
      price_pc_6m: 15.563,
      price_pc_1y: 0.709,
      price_pc_ytd: 0.709,
    },
    1915: {
      id: "1915",
      c: 1.79,
      ma50: 1.968,
      ma100: 1.996,
      ma150: 2.008,
      ma200: 1.954,
      maw30: 2.014,
      rs: 80.0,
      rsd1d: -7.0,
      rsd5d: -5.0,
      rsd10d: -7.0,
      price_5d_h: 1.95,
      price_5d_L: 1.79,
      price_1m_h: 2.08,
      price_1m_l: 1.79,
      price_3m_h: 2.12,
      price_3m_l: 1.79,
      price_6m_h: 2.2,
      price_6m_l: 1.1,
      price_52w_h: 2.25,
      price_52w_l: 1.1,
      price_pc_1d: -8.205,
      price_pc_1w: -8.205,
      price_pc_1m: -13.942,
      price_pc_6m: -16.744,
      price_pc_1y: 37.692,
      price_pc_ytd: 37.692,
    },
    1916: {
      id: "1916",
      c: 1.66,
      ma50: 2.049,
      ma100: 2.495,
      ma150: 2.793,
      ma200: 2.921,
      maw30: 2.793,
      rs: 11.0,
      rsd1d: 2.0,
      rsd5d: 2.0,
      rsd10d: -1.0,
      price_5d_h: 1.68,
      price_5d_L: 1.44,
      price_1m_h: 2.1,
      price_1m_l: 1.44,
      price_3m_h: 2.82,
      price_3m_l: 1.44,
      price_6m_h: 3.48,
      price_6m_l: 1.44,
      price_52w_h: 3.5,
      price_52w_l: 1.44,
      price_pc_1d: 0.0,
      price_pc_1w: -1.19,
      price_pc_1m: -20.952,
      price_pc_6m: -51.032,
      price_pc_1y: -49.08,
      price_pc_ytd: -49.08,
    },
    1917: {
      id: "1917",
      c: 0.05,
      ma50: 0.088,
      ma100: 0.093,
      ma150: 0.098,
      ma200: 0.105,
      maw30: 0.098,
      rs: 5.0,
      rsd1d: -1.0,
      rsd5d: -5.0,
      rsd10d: -19.0,
      price_5d_h: 0.071,
      price_5d_L: 0.049,
      price_1m_h: 0.093,
      price_1m_l: 0.049,
      price_3m_h: 0.109,
      price_3m_l: 0.049,
      price_6m_h: 0.146,
      price_6m_l: 0.049,
      price_52w_h: 0.146,
      price_52w_l: 0.049,
      price_pc_1d: -5.66,
      price_pc_1w: -20.635,
      price_pc_1m: -46.237,
      price_pc_6m: -51.923,
      price_pc_1y: -64.789,
      price_pc_ytd: -64.789,
    },
    1918: {
      id: "1918",
      c: 4.58,
      ma50: 7.702,
      ma100: 10.357,
      ma150: 12.505,
      ma200: 15.292,
      maw30: 12.624,
      rs: 2.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -1.0,
      price_5d_h: 5.51,
      price_5d_L: 3.83,
      price_1m_h: 7.32,
      price_1m_l: 2.94,
      price_3m_h: 12.82,
      price_3m_l: 2.94,
      price_6m_h: 20.65,
      price_6m_l: 2.94,
      price_52w_h: 34.2,
      price_52w_l: 2.94,
      price_pc_1d: -5.176,
      price_pc_1w: -8.4,
      price_pc_1m: -28.438,
      price_pc_6m: -72.41,
      price_pc_1y: -86.308,
      price_pc_ytd: -86.308,
    },
    1919: {
      id: "1919",
      c: 13.66,
      ma50: 14.794,
      ma100: 14.296,
      ma150: 13.798,
      ma200: 13.875,
      maw30: 13.802,
      rs: 87.0,
      rsd1d: -3.0,
      rsd5d: -5.0,
      rsd10d: -4.0,
      price_5d_h: 14.76,
      price_5d_L: 12.8,
      price_1m_h: 17.28,
      price_1m_l: 12.1,
      price_3m_h: 17.28,
      price_3m_l: 12.1,
      price_6m_h: 17.28,
      price_6m_l: 9.76,
      price_52w_h: 17.48,
      price_52w_l: 8.6,
      price_pc_1d: -2.429,
      price_pc_1w: -7.827,
      price_pc_1m: -12.994,
      price_pc_6m: 14.405,
      price_pc_1y: 67.845,
      price_pc_ytd: 67.845,
    },
    1920: {
      id: "1920",
      c: 0.027,
      ma50: 0.033,
      ma100: 0.041,
      ma150: 0.051,
      ma200: 0.064,
      maw30: 0.049,
      rs: 5.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: 0.0,
      price_5d_h: 0.03,
      price_5d_L: 0.026,
      price_1m_h: 0.032,
      price_1m_l: 0.021,
      price_3m_h: 0.048,
      price_3m_l: 0.021,
      price_6m_h: 0.079,
      price_6m_l: 0.021,
      price_52w_h: 0.229,
      price_52w_l: 0.021,
      price_pc_1d: -3.571,
      price_pc_1w: -10.0,
      price_pc_1m: -15.625,
      price_pc_6m: -55.738,
      price_pc_1y: -79.389,
      price_pc_ytd: -79.389,
    },
    1921: {
      id: "1921",
      c: 1.85,
      ma50: 2.887,
      ma100: 2.732,
      ma150: 2.475,
      ma200: 2.306,
      maw30: 2.496,
      rs: 61.0,
      rsd1d: -2.0,
      rsd5d: 17.0,
      rsd10d: 30.0,
      price_5d_h: 1.9,
      price_5d_L: 1.57,
      price_1m_h: 3.65,
      price_1m_l: 1.19,
      price_3m_h: 3.79,
      price_3m_l: 1.19,
      price_6m_h: 3.79,
      price_6m_l: 1.19,
      price_52w_h: 3.79,
      price_52w_l: 1.03,
      price_pc_1d: 1.093,
      price_pc_1w: 14.198,
      price_pc_1m: -47.293,
      price_pc_6m: -6.091,
      price_pc_1y: 71.296,
      price_pc_ytd: 71.296,
    },
    1922: {
      id: "1922",
      c: 3.59,
      ma50: 3.575,
      ma100: 3.476,
      ma150: 3.629,
      ma200: 3.842,
      maw30: 3.637,
      rs: 45.0,
      rsd1d: 13.0,
      rsd5d: 1.0,
      rsd10d: 6.0,
      price_5d_h: 3.6,
      price_5d_L: 2.94,
      price_1m_h: 3.79,
      price_1m_l: 2.51,
      price_3m_h: 4.0,
      price_3m_l: 2.51,
      price_6m_h: 4.0,
      price_6m_l: 2.51,
      price_52w_h: 5.41,
      price_52w_l: 2.51,
      price_pc_1d: 7.808,
      price_pc_1w: 4.058,
      price_pc_1m: -4.521,
      price_pc_6m: -7.949,
      price_pc_1y: -26.283,
      price_pc_ytd: -26.283,
    },
    1925: {
      id: "1925",
      c: 1.44,
      ma50: 1.325,
      ma100: 1.242,
      ma150: 1.214,
      ma200: 1.195,
      maw30: 1.213,
      rs: 90.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: -2.0,
      price_5d_h: 1.44,
      price_5d_L: 1.36,
      price_1m_h: 1.47,
      price_1m_l: 1.27,
      price_3m_h: 1.47,
      price_3m_l: 1.12,
      price_6m_h: 1.47,
      price_6m_l: 1.07,
      price_52w_h: 1.47,
      price_52w_l: 1.06,
      price_pc_1d: 1.408,
      price_pc_1w: 2.857,
      price_pc_1m: 12.5,
      price_pc_6m: 20.0,
      price_pc_1y: 10.769,
      price_pc_ytd: 10.769,
    },
    1927: {
      id: "1927",
      c: 0.23,
      ma50: 0.227,
      ma100: 0.241,
      ma150: 0.256,
      ma200: 0.28,
      maw30: 0.255,
      rs: 31.0,
      rsd1d: 3.0,
      rsd5d: 2.0,
      rsd10d: 16.0,
      price_5d_h: 0.244,
      price_5d_L: 0.22,
      price_1m_h: 0.244,
      price_1m_l: 0.177,
      price_3m_h: 0.28,
      price_3m_l: 0.177,
      price_6m_h: 0.295,
      price_6m_l: 0.177,
      price_52w_h: 0.52,
      price_52w_l: 0.177,
      price_pc_1d: 0.0,
      price_pc_1w: -1.288,
      price_pc_1m: -0.862,
      price_pc_6m: -14.815,
      price_pc_1y: -49.451,
      price_pc_ytd: -49.451,
    },
    1928: {
      id: "1928",
      c: 18.98,
      ma50: 20.374,
      ma100: 19.287,
      ma150: 19.327,
      ma200: 21.811,
      maw30: 19.13,
      rs: 49.0,
      rsd1d: -3.0,
      rsd5d: 10.0,
      rsd10d: -2.0,
      price_5d_h: 19.7,
      price_5d_L: 16.96,
      price_1m_h: 22.1,
      price_1m_l: 13.52,
      price_3m_h: 24.9,
      price_3m_l: 13.52,
      price_6m_h: 24.9,
      price_6m_l: 13.52,
      price_52w_h: 39.6,
      price_52w_l: 13.52,
      price_pc_1d: -1.76,
      price_pc_1w: 4.862,
      price_pc_1m: -7.188,
      price_pc_6m: 18.625,
      price_pc_1y: -51.52,
      price_pc_ytd: -51.52,
    },
    1929: {
      id: "1929",
      c: 14.26,
      ma50: 14.81,
      ma100: 14.676,
      ma150: 15.078,
      ma200: 15.327,
      maw30: 15.175,
      rs: 74.0,
      rsd1d: -6.0,
      rsd5d: -3.0,
      rsd10d: -10.0,
      price_5d_h: 14.94,
      price_5d_L: 13.3,
      price_1m_h: 16.3,
      price_1m_l: 12.8,
      price_3m_h: 16.7,
      price_3m_l: 12.0,
      price_6m_h: 18.54,
      price_6m_l: 12.0,
      price_52w_h: 18.54,
      price_52w_l: 11.94,
      price_pc_1d: -2.329,
      price_pc_1w: 1.422,
      price_pc_1m: -12.192,
      price_pc_6m: -4.295,
      price_pc_1y: 15.0,
      price_pc_ytd: 15.0,
    },
    1930: {
      id: "1930",
      c: 0.255,
      ma50: 0.29,
      ma100: 0.3,
      ma150: 0.31,
      ma200: 0.318,
      maw30: 0.304,
      rs: 34.0,
      rsd1d: 5.0,
      rsd5d: -29.0,
      rsd10d: -2.0,
      price_5d_h: 0.295,
      price_5d_L: 0.245,
      price_1m_h: 0.31,
      price_1m_l: 0.24,
      price_3m_h: 0.34,
      price_3m_l: 0.23,
      price_6m_h: 0.37,
      price_6m_l: 0.23,
      price_52w_h: 0.475,
      price_52w_l: 0.23,
      price_pc_1d: 2.0,
      price_pc_1w: -17.742,
      price_pc_1m: -8.929,
      price_pc_6m: -7.273,
      price_pc_1y: -41.379,
      price_pc_ytd: -41.379,
    },
    1931: {
      id: "1931",
      c: 2.8,
      ma50: 3.125,
      ma100: 3.157,
      ma150: 3.073,
      ma200: 3.086,
      maw30: 3.091,
      rs: 67.0,
      rsd1d: 10.0,
      rsd5d: -10.0,
      rsd10d: -4.0,
      price_5d_h: 2.99,
      price_5d_L: 2.68,
      price_1m_h: 3.43,
      price_1m_l: 2.68,
      price_3m_h: 4.28,
      price_3m_l: 2.68,
      price_6m_h: 4.28,
      price_6m_l: 2.68,
      price_52w_h: 4.28,
      price_52w_l: 2.06,
      price_pc_1d: 4.478,
      price_pc_1w: -4.762,
      price_pc_1m: -15.663,
      price_pc_6m: -8.197,
      price_pc_1y: 24.444,
      price_pc_ytd: 24.444,
    },
    1932: {
      id: "1932",
      c: 0.4,
      ma50: 0.434,
      ma100: 0.442,
      ma150: 0.447,
      ma200: 0.45,
      maw30: 0.446,
      rs: 49.0,
      rsd1d: -4.0,
      rsd5d: -2.0,
      rsd10d: -6.0,
      price_5d_h: 0.4,
      price_5d_L: 0.4,
      price_1m_h: 0.465,
      price_1m_l: 0.385,
      price_3m_h: 0.47,
      price_3m_l: 0.385,
      price_6m_h: 0.48,
      price_6m_l: 0.385,
      price_52w_h: 0.49,
      price_52w_l: 0.385,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: -14.894,
      price_pc_6m: -11.111,
      price_pc_1y: -16.667,
      price_pc_ytd: -16.667,
    },
    1935: {
      id: "1935",
      c: 3.32,
      ma50: 3.23,
      ma100: 2.938,
      ma150: 2.599,
      ma200: 2.526,
      maw30: 2.609,
      rs: 91.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: -1.0,
      price_5d_h: 3.39,
      price_5d_L: 3.31,
      price_1m_h: 3.42,
      price_1m_l: 2.96,
      price_3m_h: 3.48,
      price_3m_l: 2.38,
      price_6m_h: 3.48,
      price_6m_l: 1.7,
      price_52w_h: 5.21,
      price_52w_l: 1.66,
      price_pc_1d: -1.19,
      price_pc_1w: 0.0,
      price_pc_1m: 0.0,
      price_pc_6m: 77.54,
      price_pc_1y: -10.992,
      price_pc_ytd: -10.992,
    },
    1936: {
      id: "1936",
      c: 0.81,
      ma50: 0.728,
      ma100: 0.637,
      ma150: 0.687,
      ma200: 0.742,
      maw30: 0.678,
      rs: 93.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: 0.0,
      price_5d_h: 0.85,
      price_5d_L: 0.76,
      price_1m_h: 0.85,
      price_1m_l: 0.71,
      price_3m_h: 0.9,
      price_3m_l: 0.485,
      price_6m_h: 0.9,
      price_6m_l: 0.485,
      price_52w_h: 1.2,
      price_52w_l: 0.485,
      price_pc_1d: 0.0,
      price_pc_1w: 6.579,
      price_pc_1m: 8.0,
      price_pc_6m: 3.846,
      price_pc_1y: 44.643,
      price_pc_ytd: 44.643,
    },
    1937: {
      id: "1937",
      c: 0.91,
      ma50: 1.042,
      ma100: 1.102,
      ma150: 1.153,
      ma200: 0.994,
      maw30: 1.153,
      rs: 98.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: -1.0,
      price_5d_h: 1.01,
      price_5d_L: 0.91,
      price_1m_h: 1.11,
      price_1m_l: 0.89,
      price_3m_h: 1.3,
      price_3m_l: 0.89,
      price_6m_h: 1.49,
      price_6m_l: 0.89,
      price_52w_h: 1.9,
      price_52w_l: 0.104,
      price_pc_1d: -3.191,
      price_pc_1w: -6.186,
      price_pc_1m: 0.0,
      price_pc_6m: -22.222,
      price_pc_1y: 775.0,
      price_pc_ytd: 775.0,
    },
    1938: {
      id: "1938",
      c: 0.25,
      ma50: 0.267,
      ma100: 0.276,
      ma150: 0.306,
      ma200: 0.325,
      maw30: 0.308,
      rs: 30.0,
      rsd1d: 1.0,
      rsd5d: -8.0,
      rsd10d: 2.0,
      price_5d_h: 0.28,
      price_5d_L: 0.25,
      price_1m_h: 0.285,
      price_1m_l: 0.245,
      price_3m_h: 0.29,
      price_3m_l: 0.245,
      price_6m_h: 0.415,
      price_6m_l: 0.245,
      price_52w_h: 0.48,
      price_52w_l: 0.245,
      price_pc_1d: -3.846,
      price_pc_1w: -9.091,
      price_pc_1m: -5.66,
      price_pc_6m: -29.577,
      price_pc_1y: -35.065,
      price_pc_ytd: -35.065,
    },
    1939: {
      id: "1939",
      c: 0.83,
      ma50: 0.927,
      ma100: 0.943,
      ma150: 0.847,
      ma200: 0.808,
      maw30: 0.854,
      rs: 85.0,
      rsd1d: 2.0,
      rsd5d: 0.0,
      rsd10d: -6.0,
      price_5d_h: 0.81,
      price_5d_L: 0.75,
      price_1m_h: 0.94,
      price_1m_l: 0.58,
      price_3m_h: 1.5,
      price_3m_l: 0.58,
      price_6m_h: 1.5,
      price_6m_l: 0.58,
      price_52w_h: 1.5,
      price_52w_l: 0.55,
      price_pc_1d: 3.75,
      price_pc_1w: 2.469,
      price_pc_1m: -11.702,
      price_pc_6m: 27.692,
      price_pc_1y: 31.746,
      price_pc_ytd: 31.746,
    },
    1941: {
      id: "1941",
      c: 0.42,
      ma50: 0.59,
      ma100: 0.65,
      ma150: 0.679,
      ma200: 0.681,
      maw30: 0.684,
      rs: 15.0,
      rsd1d: 2.0,
      rsd5d: -9.0,
      rsd10d: -10.0,
      price_5d_h: 0.5,
      price_5d_L: 0.38,
      price_1m_h: 0.67,
      price_1m_l: 0.335,
      price_3m_h: 0.76,
      price_3m_l: 0.335,
      price_6m_h: 0.84,
      price_6m_l: 0.335,
      price_52w_h: 0.84,
      price_52w_l: 0.335,
      price_pc_1d: 0.0,
      price_pc_1w: -14.286,
      price_pc_1m: -36.364,
      price_pc_6m: -40.845,
      price_pc_1y: -40.0,
      price_pc_ytd: -40.0,
    },
    1942: {
      id: "1942",
      c: 1.13,
      ma50: 1.109,
      ma100: 1.089,
      ma150: 1.036,
      ma200: 1.003,
      maw30: 1.037,
      rs: 86.0,
      rsd1d: -4.0,
      rsd5d: 0.0,
      rsd10d: -1.0,
      price_5d_h: 1.26,
      price_5d_L: 1.11,
      price_1m_h: 1.26,
      price_1m_l: 1.1,
      price_3m_h: 1.26,
      price_3m_l: 1.06,
      price_6m_h: 1.29,
      price_6m_l: 0.88,
      price_52w_h: 1.29,
      price_52w_l: 0.79,
      price_pc_1d: -7.377,
      price_pc_1w: 1.802,
      price_pc_1m: 2.727,
      price_pc_6m: 24.176,
      price_pc_1y: -0.877,
      price_pc_ytd: -0.877,
    },
    1943: {
      id: "1943",
      c: 0.84,
      ma50: 0.838,
      ma100: 0.695,
      ma150: 0.659,
      ma200: 0.606,
      maw30: 0.658,
      rs: 97.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: -1.0,
      price_5d_h: 0.94,
      price_5d_L: 0.83,
      price_1m_h: 1.02,
      price_1m_l: 0.71,
      price_3m_h: 1.02,
      price_3m_l: 0.51,
      price_6m_h: 1.02,
      price_6m_l: 0.44,
      price_52w_h: 1.02,
      price_52w_l: 0.24,
      price_pc_1d: -1.176,
      price_pc_1w: -8.696,
      price_pc_1m: 3.704,
      price_pc_6m: 40.0,
      price_pc_1y: 223.077,
      price_pc_ytd: 223.077,
    },
    1945: {
      id: "1945",
      c: 2.35,
      ma50: 2.947,
      ma100: 3.387,
      ma150: 3.521,
      ma200: 3.602,
      maw30: 3.556,
      rs: 12.0,
      rsd1d: 2.0,
      rsd5d: 2.0,
      rsd10d: 4.0,
      price_5d_h: 2.39,
      price_5d_L: 2.2,
      price_1m_h: 3.08,
      price_1m_l: 1.72,
      price_3m_h: 3.88,
      price_3m_l: 1.72,
      price_6m_h: 4.46,
      price_6m_l: 1.72,
      price_52w_h: 5.9,
      price_52w_l: 1.72,
      price_pc_1d: 2.62,
      price_pc_1w: 3.524,
      price_pc_1m: -21.667,
      price_pc_6m: -33.428,
      price_pc_1y: -58.259,
      price_pc_ytd: -58.259,
    },
    1948: {
      id: "1948",
      c: 6.69,
      ma50: 5.92,
      ma100: 6.098,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 73.0,
      rsd1d: 12.0,
      rsd5d: 21.0,
      rsd10d: 14.0,
      price_5d_h: 6.68,
      price_5d_L: 5.98,
      price_1m_h: 6.68,
      price_1m_l: 4.4,
      price_3m_h: 7.07,
      price_3m_l: 4.4,
      price_6m_h: 7.07,
      price_6m_l: 4.4,
      price_52w_h: 7.07,
      price_52w_l: 4.4,
      price_pc_1d: 9.493,
      price_pc_1w: 8.604,
      price_pc_1m: 12.816,
      price_pc_6m: 4.368,
      price_pc_1y: 4.368,
      price_pc_ytd: 4.368,
    },
    1949: {
      id: "1949",
      c: 0.245,
      ma50: 0.269,
      ma100: 0.262,
      ma150: 0.25,
      ma200: 0.259,
      maw30: 0.249,
      rs: 57.0,
      rsd1d: 4.0,
      rsd5d: 4.0,
      rsd10d: 0.0,
      price_5d_h: 0.255,
      price_5d_L: 0.23,
      price_1m_h: 0.28,
      price_1m_l: 0.23,
      price_3m_h: 0.33,
      price_3m_l: 0.23,
      price_6m_h: 0.36,
      price_6m_l: 0.192,
      price_52w_h: 0.36,
      price_52w_l: 0.191,
      price_pc_1d: 0.0,
      price_pc_1w: -3.922,
      price_pc_1m: -3.922,
      price_pc_6m: 18.357,
      price_pc_1y: -9.259,
      price_pc_ytd: -9.259,
    },
    1950: {
      id: "1950",
      c: 0.095,
      ma50: 0.104,
      ma100: 0.118,
      ma150: 0.151,
      ma200: 0.182,
      maw30: 0.148,
      rs: 10.0,
      rsd1d: 1.0,
      rsd5d: -1.0,
      rsd10d: 5.0,
      price_5d_h: 0.109,
      price_5d_L: 0.086,
      price_1m_h: 0.119,
      price_1m_l: 0.066,
      price_3m_h: 0.129,
      price_3m_l: 0.066,
      price_6m_h: 0.25,
      price_6m_l: 0.066,
      price_52w_h: 0.87,
      price_52w_l: 0.066,
      price_pc_1d: -5.941,
      price_pc_1w: -12.037,
      price_pc_1m: -7.767,
      price_pc_6m: -52.97,
      price_pc_1y: -81.0,
      price_pc_ytd: -81.0,
    },
    1951: {
      id: "1951",
      c: 6.03,
      ma50: 7.768,
      ma100: 8.79,
      ma150: 9.962,
      ma200: 11.5,
      maw30: 9.967,
      rs: 9.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: -1.0,
      price_5d_h: 6.89,
      price_5d_L: 5.92,
      price_1m_h: 9.36,
      price_1m_l: 4.43,
      price_3m_h: 9.45,
      price_3m_l: 4.43,
      price_6m_h: 13.8,
      price_6m_l: 4.43,
      price_52w_h: 23.5,
      price_52w_l: 4.43,
      price_pc_1d: -8.359,
      price_pc_1w: -11.454,
      price_pc_1m: -33.663,
      price_pc_6m: -48.017,
      price_pc_1y: -66.124,
      price_pc_ytd: -66.124,
    },
    1952: {
      id: "1952",
      c: 25.95,
      ma50: 24.457,
      ma100: 30.528,
      ma150: 37.099,
      ma200: 44.396,
      maw30: 37.311,
      rs: 11.0,
      rsd1d: 1.0,
      rsd5d: 6.0,
      rsd10d: 9.0,
      price_5d_h: 27.3,
      price_5d_L: 20.0,
      price_1m_h: 27.3,
      price_1m_l: 13.86,
      price_3m_h: 46.8,
      price_3m_l: 13.86,
      price_6m_h: 50.05,
      price_6m_l: 13.86,
      price_52w_h: 82.8,
      price_52w_l: 13.86,
      price_pc_1d: -0.765,
      price_pc_1w: 11.613,
      price_pc_1m: 8.125,
      price_pc_6m: -47.256,
      price_pc_1y: -67.963,
      price_pc_ytd: -67.963,
    },
    1953: {
      id: "1953",
      c: 0.18,
      ma50: 0.198,
      ma100: 0.219,
      ma150: 0.221,
      ma200: 0.214,
      maw30: 0.219,
      rs: 36.0,
      rsd1d: 2.0,
      rsd5d: 2.0,
      rsd10d: -45.0,
      price_5d_h: 0.184,
      price_5d_L: 0.175,
      price_1m_h: 0.2,
      price_1m_l: 0.175,
      price_3m_h: 0.25,
      price_3m_l: 0.175,
      price_6m_h: 0.56,
      price_6m_l: 0.175,
      price_52w_h: 0.56,
      price_52w_l: 0.131,
      price_pc_1d: -0.552,
      price_pc_1w: -0.552,
      price_pc_1m: -9.548,
      price_pc_6m: -25.311,
      price_pc_1y: -6.25,
      price_pc_ytd: -6.25,
    },
    1955: {
      id: "1955",
      c: 0.98,
      ma50: 1.023,
      ma100: 1.073,
      ma150: 1.149,
      ma200: 1.183,
      maw30: 1.143,
      rs: 56.0,
      rsd1d: 0.0,
      rsd5d: -2.0,
      rsd10d: 4.0,
      price_5d_h: 1.01,
      price_5d_L: 0.95,
      price_1m_h: 1.04,
      price_1m_l: 0.95,
      price_3m_h: 1.1,
      price_3m_l: 0.94,
      price_6m_h: 1.3,
      price_6m_l: 0.94,
      price_52w_h: 1.6,
      price_52w_l: 0.85,
      price_pc_1d: 0.0,
      price_pc_1w: -2.97,
      price_pc_1m: -5.769,
      price_pc_6m: -24.031,
      price_pc_1y: 11.364,
      price_pc_ytd: 11.364,
    },
    1957: {
      id: "1957",
      c: 0.405,
      ma50: 0.376,
      ma100: 0.434,
      ma150: 0.479,
      ma200: 0.462,
      maw30: 0.473,
      rs: 54.0,
      rsd1d: 10.0,
      rsd5d: 25.0,
      rsd10d: 3.0,
      price_5d_h: 0.415,
      price_5d_L: 0.35,
      price_1m_h: 0.415,
      price_1m_l: 0.31,
      price_3m_h: 0.455,
      price_3m_l: 0.31,
      price_6m_h: 0.78,
      price_6m_l: 0.31,
      price_52w_h: 0.78,
      price_52w_l: 0.305,
      price_pc_1d: 2.532,
      price_pc_1w: 15.714,
      price_pc_1m: 9.459,
      price_pc_6m: -19.0,
      price_pc_1y: -23.585,
      price_pc_ytd: -23.585,
    },
    1958: {
      id: "1958",
      c: 2.63,
      ma50: 2.755,
      ma100: 3.026,
      ma150: 2.957,
      ma200: 2.945,
      maw30: 2.974,
      rs: 56.0,
      rsd1d: 0.0,
      rsd5d: 15.0,
      rsd10d: 24.0,
      price_5d_h: 2.69,
      price_5d_L: 2.5,
      price_1m_h: 2.76,
      price_1m_l: 2.11,
      price_3m_h: 3.44,
      price_3m_l: 2.11,
      price_6m_h: 4.03,
      price_6m_l: 2.11,
      price_52w_h: 4.03,
      price_52w_l: 2.11,
      price_pc_1d: -0.379,
      price_pc_1w: 1.544,
      price_pc_1m: -3.309,
      price_pc_6m: -2.593,
      price_pc_1y: 3.137,
      price_pc_ytd: 3.137,
    },
    1960: {
      id: "1960",
      c: 0.395,
      ma50: 0.42,
      ma100: 0.43,
      ma150: 0.425,
      ma200: 0.423,
      maw30: 0.424,
      rs: 71.0,
      rsd1d: 4.0,
      rsd5d: 4.0,
      rsd10d: -8.0,
      price_5d_h: 0.41,
      price_5d_L: 0.38,
      price_1m_h: 0.43,
      price_1m_l: 0.38,
      price_3m_h: 0.455,
      price_3m_l: 0.38,
      price_6m_h: 0.5,
      price_6m_l: 0.37,
      price_52w_h: 0.5,
      price_52w_l: 0.29,
      price_pc_1d: 1.282,
      price_pc_1w: 0.0,
      price_pc_1m: -8.14,
      price_pc_6m: 5.333,
      price_pc_1y: 16.176,
      price_pc_ytd: 16.176,
    },
    1961: {
      id: "1961",
      c: 1.18,
      ma50: 1.138,
      ma100: 0.892,
      ma150: 0.78,
      ma200: 0.723,
      maw30: 0.778,
      rs: 97.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: -1.0,
      price_5d_h: 1.37,
      price_5d_L: 1.09,
      price_1m_h: 1.78,
      price_1m_l: 1.09,
      price_3m_h: 1.78,
      price_3m_l: 0.6,
      price_6m_h: 1.78,
      price_6m_l: 0.48,
      price_52w_h: 1.78,
      price_52w_l: 0.48,
      price_pc_1d: -4.065,
      price_pc_1w: 3.509,
      price_pc_1m: -28.916,
      price_pc_6m: 122.642,
      price_pc_1y: 118.519,
      price_pc_ytd: 118.519,
    },
    1962: {
      id: "1962",
      c: 1.18,
      ma50: 1.233,
      ma100: 1.166,
      ma150: 1.01,
      ma200: 0.91,
      maw30: 1.023,
      rs: 91.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: -3.0,
      price_5d_h: 1.2,
      price_5d_L: 1.12,
      price_1m_h: 1.28,
      price_1m_l: 0.81,
      price_3m_h: 1.38,
      price_3m_l: 0.81,
      price_6m_h: 1.38,
      price_6m_l: 0.62,
      price_52w_h: 1.39,
      price_52w_l: 0.41,
      price_pc_1d: -1.667,
      price_pc_1w: -0.84,
      price_pc_1m: -5.6,
      price_pc_6m: 73.529,
      price_pc_1y: -15.108,
      price_pc_ytd: -15.108,
    },
    1963: {
      id: "1963",
      c: 4.83,
      ma50: 4.667,
      ma100: 4.534,
      ma150: 4.512,
      ma200: 4.61,
      maw30: 4.511,
      rs: 78.0,
      rsd1d: 1.0,
      rsd5d: 2.0,
      rsd10d: 4.0,
      price_5d_h: 4.86,
      price_5d_L: 4.59,
      price_1m_h: 4.86,
      price_1m_l: 4.22,
      price_3m_h: 4.89,
      price_3m_l: 4.22,
      price_6m_h: 4.89,
      price_6m_l: 4.22,
      price_52w_h: 5.53,
      price_52w_l: 4.05,
      price_pc_1d: 1.046,
      price_pc_1w: 3.648,
      price_pc_1m: 3.871,
      price_pc_6m: 11.547,
      price_pc_1y: -9.72,
      price_pc_ytd: -9.72,
    },
    1965: {
      id: "1965",
      c: 3.1,
      ma50: 3.16,
      ma100: 3.197,
      ma150: 3.206,
      ma200: null,
      maw30: 3.217,
      rs: 60.0,
      rsd1d: 3.0,
      rsd5d: 3.0,
      rsd10d: -14.0,
      price_5d_h: 3.1,
      price_5d_L: 2.92,
      price_1m_h: 3.3,
      price_1m_l: 2.92,
      price_3m_h: 3.5,
      price_3m_l: 2.92,
      price_6m_h: 3.5,
      price_6m_l: 1.61,
      price_52w_h: 7.4,
      price_52w_l: 1.61,
      price_pc_1d: 1.974,
      price_pc_1w: 3.333,
      price_pc_1m: -2.516,
      price_pc_6m: -6.061,
      price_pc_1y: -31.111,
      price_pc_ytd: -31.111,
    },
    1966: {
      id: "1966",
      c: 1.42,
      ma50: 1.607,
      ma100: 1.818,
      ma150: 2.125,
      ma200: 2.361,
      maw30: 2.11,
      rs: 12.0,
      rsd1d: 1.0,
      rsd5d: 3.0,
      rsd10d: 0.0,
      price_5d_h: 1.5,
      price_5d_L: 1.29,
      price_1m_h: 1.65,
      price_1m_l: 1.06,
      price_3m_h: 2.03,
      price_3m_l: 1.06,
      price_6m_h: 2.92,
      price_6m_l: 1.06,
      price_52w_h: 3.71,
      price_52w_l: 1.06,
      price_pc_1d: -2.069,
      price_pc_1w: 2.158,
      price_pc_1m: -9.554,
      price_pc_6m: -47.985,
      price_pc_1y: -60.882,
      price_pc_ytd: -60.882,
    },
    1967: {
      id: "1967",
      c: 26.4,
      ma50: 25.892,
      ma100: 24.208,
      ma150: 24.433,
      ma200: 22.68,
      maw30: 24.59,
      rs: 93.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: 1.0,
      price_5d_h: 27.95,
      price_5d_L: 26.2,
      price_1m_h: 27.95,
      price_1m_l: 22.2,
      price_3m_h: 28.95,
      price_3m_l: 22.2,
      price_6m_h: 28.95,
      price_6m_l: 17.02,
      price_52w_h: 36.0,
      price_52w_l: 13.64,
      price_pc_1d: -3.825,
      price_pc_1w: -3.65,
      price_pc_1m: 1.538,
      price_pc_6m: 8.197,
      price_pc_1y: 73.456,
      price_pc_ytd: 73.456,
    },
    1969: {
      id: "1969",
      c: 2.31,
      ma50: 1.931,
      ma100: 1.79,
      ma150: 1.663,
      ma200: 1.603,
      maw30: 1.669,
      rs: 94.0,
      rsd1d: -1.0,
      rsd5d: 2.0,
      rsd10d: 3.0,
      price_5d_h: 2.42,
      price_5d_L: 2.01,
      price_1m_h: 2.42,
      price_1m_l: 1.79,
      price_3m_h: 2.42,
      price_3m_l: 1.45,
      price_6m_h: 2.42,
      price_6m_l: 1.29,
      price_52w_h: 2.42,
      price_52w_l: 1.29,
      price_pc_1d: -1.702,
      price_pc_1w: 13.235,
      price_pc_1m: 19.689,
      price_pc_6m: 65.0,
      price_pc_1y: 59.31,
      price_pc_ytd: 59.31,
    },
    1970: {
      id: "1970",
      c: 9.4,
      ma50: 10.543,
      ma100: 11.401,
      ma150: 11.371,
      ma200: 11.305,
      maw30: 11.436,
      rs: 30.0,
      rsd1d: 7.0,
      rsd5d: 5.0,
      rsd10d: -11.0,
      price_5d_h: 9.77,
      price_5d_L: 8.75,
      price_1m_h: 11.28,
      price_1m_l: 8.75,
      price_3m_h: 12.4,
      price_3m_l: 8.75,
      price_6m_h: 13.02,
      price_6m_l: 8.75,
      price_52w_h: 18.6,
      price_52w_l: 8.75,
      price_pc_1d: 4.444,
      price_pc_1w: -0.948,
      price_pc_1m: -13.761,
      price_pc_6m: -17.688,
      price_pc_1y: -46.893,
      price_pc_ytd: -46.893,
    },
    1971: {
      id: "1971",
      c: 2.95,
      ma50: 3.478,
      ma100: 3.866,
      ma150: 4.047,
      ma200: 4.107,
      maw30: 4.032,
      rs: 20.0,
      rsd1d: 3.0,
      rsd5d: -1.0,
      rsd10d: -7.0,
      price_5d_h: 3.24,
      price_5d_L: 2.8,
      price_1m_h: 3.6,
      price_1m_l: 2.8,
      price_3m_h: 4.3,
      price_3m_l: 2.8,
      price_6m_h: 4.55,
      price_6m_l: 2.8,
      price_52w_h: 4.78,
      price_52w_l: 2.8,
      price_pc_1d: 0.0,
      price_pc_1w: -1.993,
      price_pc_1m: -17.367,
      price_pc_6m: -32.955,
      price_pc_1y: -32.955,
      price_pc_ytd: -32.955,
    },
    1972: {
      id: "1972",
      c: 19.46,
      ma50: 20.351,
      ma100: 20.085,
      ma150: 20.267,
      ma200: 20.898,
      maw30: 20.243,
      rs: 61.0,
      rsd1d: -3.0,
      rsd5d: -10.0,
      rsd10d: -5.0,
      price_5d_h: 21.0,
      price_5d_L: 19.36,
      price_1m_h: 21.0,
      price_1m_l: 18.02,
      price_3m_h: 21.9,
      price_3m_l: 18.02,
      price_6m_h: 21.9,
      price_6m_l: 18.02,
      price_52w_h: 24.2,
      price_52w_l: 18.02,
      price_pc_1d: -2.407,
      price_pc_1w: -5.073,
      price_pc_1m: -4.138,
      price_pc_6m: -0.307,
      price_pc_1y: -17.717,
      price_pc_ytd: -17.717,
    },
    1975: {
      id: "1975",
      c: 0.6,
      ma50: 0.699,
      ma100: 0.695,
      ma150: 0.718,
      ma200: 0.705,
      maw30: 0.725,
      rs: 52.0,
      rsd1d: 1.0,
      rsd5d: 5.0,
      rsd10d: -2.0,
      price_5d_h: 0.6,
      price_5d_L: 0.57,
      price_1m_h: 0.76,
      price_1m_l: 0.51,
      price_3m_h: 0.88,
      price_3m_l: 0.51,
      price_6m_h: 0.88,
      price_6m_l: 0.51,
      price_52w_h: 0.88,
      price_52w_l: 0.51,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: -24.051,
      price_pc_6m: -23.077,
      price_pc_1y: -7.692,
      price_pc_ytd: -7.692,
    },
    1977: {
      id: "1977",
      c: 1.69,
      ma50: 1.678,
      ma100: 1.76,
      ma150: 1.786,
      ma200: 1.839,
      maw30: 1.792,
      rs: 67.0,
      rsd1d: 6.0,
      rsd5d: 4.0,
      rsd10d: 10.0,
      price_5d_h: 1.72,
      price_5d_L: 1.64,
      price_1m_h: 1.72,
      price_1m_l: 1.41,
      price_3m_h: 1.83,
      price_3m_l: 1.41,
      price_6m_h: 1.99,
      price_6m_l: 1.41,
      price_52w_h: 2.19,
      price_52w_l: 1.41,
      price_pc_1d: 2.424,
      price_pc_1w: 0.0,
      price_pc_1m: 3.049,
      price_pc_6m: -5.056,
      price_pc_1y: 12.667,
      price_pc_ytd: 12.667,
    },
    1978: {
      id: "1978",
      c: 1.0,
      ma50: 0.934,
      ma100: 0.987,
      ma150: 0.974,
      ma200: 0.972,
      maw30: 0.978,
      rs: 80.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: -1.0,
      price_5d_h: 1.02,
      price_5d_L: 0.94,
      price_1m_h: 1.02,
      price_1m_l: 0.73,
      price_3m_h: 1.1,
      price_3m_l: 0.73,
      price_6m_h: 1.1,
      price_6m_l: 0.73,
      price_52w_h: 1.38,
      price_52w_l: 0.73,
      price_pc_1d: 0.0,
      price_pc_1w: 6.383,
      price_pc_1m: 16.279,
      price_pc_6m: 8.696,
      price_pc_1y: 20.482,
      price_pc_ytd: 20.482,
    },
    1979: {
      id: "1979",
      c: 1.83,
      ma50: 2.03,
      ma100: 1.967,
      ma150: 2.035,
      ma200: 2.09,
      maw30: 2.035,
      rs: 54.0,
      rsd1d: 2.0,
      rsd5d: -8.0,
      rsd10d: -9.0,
      price_5d_h: 1.89,
      price_5d_L: 1.8,
      price_1m_h: 2.09,
      price_1m_l: 1.63,
      price_3m_h: 2.24,
      price_3m_l: 1.63,
      price_6m_h: 2.24,
      price_6m_l: 1.63,
      price_52w_h: 2.62,
      price_52w_l: 1.63,
      price_pc_1d: 0.0,
      price_pc_1w: -5.181,
      price_pc_1m: -8.5,
      price_pc_6m: -14.486,
      price_pc_1y: -21.459,
      price_pc_ytd: -21.459,
    },
    1981: {
      id: "1981",
      c: 1.25,
      ma50: 1.73,
      ma100: 2.11,
      ma150: 2.399,
      ma200: 2.954,
      maw30: 2.437,
      rs: 4.0,
      rsd1d: 1.0,
      rsd5d: -4.0,
      rsd10d: -2.0,
      price_5d_h: 1.67,
      price_5d_L: 1.19,
      price_1m_h: 1.8,
      price_1m_l: 1.16,
      price_3m_h: 2.19,
      price_3m_l: 1.16,
      price_6m_h: 3.88,
      price_6m_l: 1.16,
      price_52w_h: 7.84,
      price_52w_l: 1.16,
      price_pc_1d: 5.042,
      price_pc_1w: -22.84,
      price_pc_1m: -29.775,
      price_pc_6m: -56.446,
      price_pc_1y: -82.04,
      price_pc_ytd: -82.04,
    },
    1982: {
      id: "1982",
      c: 0.45,
      ma50: 0.473,
      ma100: 0.489,
      ma150: 0.499,
      ma200: 0.528,
      maw30: 0.499,
      rs: 44.0,
      rsd1d: -11.0,
      rsd5d: -7.0,
      rsd10d: 3.0,
      price_5d_h: 0.47,
      price_5d_L: 0.45,
      price_1m_h: 0.48,
      price_1m_l: 0.4,
      price_3m_h: 0.55,
      price_3m_l: 0.4,
      price_6m_h: 0.56,
      price_6m_l: 0.4,
      price_52w_h: 0.73,
      price_52w_l: 0.4,
      price_pc_1d: -4.255,
      price_pc_1w: -2.174,
      price_pc_1m: -6.25,
      price_pc_6m: -9.091,
      price_pc_1y: -6.25,
      price_pc_ytd: -6.25,
    },
    1985: {
      id: "1985",
      c: 0.89,
      ma50: 0.905,
      ma100: 0.947,
      ma150: 0.951,
      ma200: 0.963,
      maw30: 0.951,
      rs: 60.0,
      rsd1d: -2.0,
      rsd5d: -3.0,
      rsd10d: 2.0,
      price_5d_h: 0.92,
      price_5d_L: 0.89,
      price_1m_h: 0.93,
      price_1m_l: 0.84,
      price_3m_h: 0.99,
      price_3m_l: 0.84,
      price_6m_h: 1.1,
      price_6m_l: 0.84,
      price_52w_h: 1.13,
      price_52w_l: 0.84,
      price_pc_1d: -2.198,
      price_pc_1w: -1.111,
      price_pc_1m: -2.198,
      price_pc_6m: -6.316,
      price_pc_1y: -1.111,
      price_pc_ytd: -1.111,
    },
    1986: {
      id: "1986",
      c: 1.42,
      ma50: 1.304,
      ma100: 1.352,
      ma150: 1.39,
      ma200: 1.389,
      maw30: 1.383,
      rs: 85.0,
      rsd1d: 8.0,
      rsd5d: 9.0,
      rsd10d: 27.0,
      price_5d_h: 1.45,
      price_5d_L: 1.23,
      price_1m_h: 1.45,
      price_1m_l: 1.09,
      price_3m_h: 1.45,
      price_3m_l: 1.09,
      price_6m_h: 1.74,
      price_6m_l: 1.09,
      price_52w_h: 1.86,
      price_52w_l: 1.09,
      price_pc_1d: 7.576,
      price_pc_1w: 10.078,
      price_pc_1m: 10.937,
      price_pc_6m: 2.899,
      price_pc_1y: 24.561,
      price_pc_ytd: 24.561,
    },
    1987: {
      id: "1987",
      c: 0.33,
      ma50: 0.196,
      ma100: 1.21,
      ma150: 1.617,
      ma200: 1.842,
      maw30: 1.605,
      rs: 1.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.4,
      price_5d_L: 0.32,
      price_1m_h: 0.47,
      price_1m_l: 0.128,
      price_3m_h: 2.38,
      price_3m_l: 0.065,
      price_6m_h: 3.84,
      price_6m_l: 0.065,
      price_52w_h: 5.07,
      price_52w_l: 0.065,
      price_pc_1d: -7.042,
      price_pc_1w: -4.348,
      price_pc_1m: 161.905,
      price_pc_6m: -83.981,
      price_pc_1y: -87.687,
      price_pc_ytd: -87.687,
    },
    1988: {
      id: "1988",
      c: 2.95,
      ma50: 3.07,
      ma100: 3.067,
      ma150: 3.097,
      ma200: 3.207,
      maw30: 3.099,
      rs: 50.0,
      rsd1d: 2.0,
      rsd5d: 2.0,
      rsd10d: -2.0,
      price_5d_h: 3.1,
      price_5d_L: 2.92,
      price_1m_h: 3.13,
      price_1m_l: 2.64,
      price_3m_h: 3.29,
      price_3m_l: 2.64,
      price_6m_h: 3.29,
      price_6m_l: 2.64,
      price_52w_h: 4.54,
      price_52w_l: 2.56,
      price_pc_1d: -1.007,
      price_pc_1w: -1.338,
      price_pc_1m: -2.961,
      price_pc_6m: -5.751,
      price_pc_1y: -33.409,
      price_pc_ytd: -33.409,
    },
    1989: {
      id: "1989",
      c: 0.315,
      ma50: 0.553,
      ma100: 0.719,
      ma150: 0.808,
      ma200: 0.88,
      maw30: 0.812,
      rs: 3.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: 1.0,
      price_5d_h: 0.35,
      price_5d_L: 0.27,
      price_1m_h: 0.7,
      price_1m_l: 0.23,
      price_3m_h: 0.96,
      price_3m_l: 0.23,
      price_6m_h: 1.22,
      price_6m_l: 0.23,
      price_52w_h: 1.4,
      price_52w_l: 0.23,
      price_pc_1d: 6.78,
      price_pc_1w: 12.5,
      price_pc_1m: -53.676,
      price_pc_6m: -65.0,
      price_pc_1y: -72.124,
      price_pc_ytd: -72.124,
    },
    1991: {
      id: "1991",
      c: 0.197,
      ma50: 0.189,
      ma100: 0.192,
      ma150: 0.177,
      ma200: 0.172,
      maw30: 0.178,
      rs: 88.0,
      rsd1d: 1.0,
      rsd5d: 6.0,
      rsd10d: 8.0,
      price_5d_h: 0.201,
      price_5d_L: 0.185,
      price_1m_h: 0.25,
      price_1m_l: 0.166,
      price_3m_h: 0.3,
      price_3m_l: 0.165,
      price_6m_h: 0.3,
      price_6m_l: 0.137,
      price_52w_h: 0.3,
      price_52w_l: 0.125,
      price_pc_1d: 3.141,
      price_pc_1w: 9.444,
      price_pc_1m: 0.0,
      price_pc_6m: 43.796,
      price_pc_1y: 11.09,
      price_pc_ytd: 11.09,
    },
    1992: {
      id: "1992",
      c: 11.06,
      ma50: 11.794,
      ma100: 10.958,
      ma150: 10.877,
      ma200: 10.928,
      maw30: 10.856,
      rs: 78.0,
      rsd1d: -5.0,
      rsd5d: -9.0,
      rsd10d: -3.0,
      price_5d_h: 12.08,
      price_5d_L: 10.7,
      price_1m_h: 13.18,
      price_1m_l: 9.11,
      price_3m_h: 14.68,
      price_3m_l: 9.11,
      price_6m_h: 14.68,
      price_6m_l: 8.99,
      price_52w_h: 16.2,
      price_52w_l: 8.5,
      price_pc_1d: -1.95,
      price_pc_1w: -7.525,
      price_pc_1m: -12.638,
      price_pc_6m: 9.505,
      price_pc_1y: -2.297,
      price_pc_ytd: -2.297,
    },
    1993: {
      id: "1993",
      c: 1.2,
      ma50: 1.433,
      ma100: 1.406,
      ma150: 1.385,
      ma200: 1.488,
      maw30: 1.397,
      rs: 19.0,
      rsd1d: -1.0,
      rsd5d: -4.0,
      rsd10d: -22.0,
      price_5d_h: 1.26,
      price_5d_L: 1.2,
      price_1m_h: 1.5,
      price_1m_l: 1.1,
      price_3m_h: 1.78,
      price_3m_l: 1.06,
      price_6m_h: 1.78,
      price_6m_l: 0.97,
      price_52w_h: 3.19,
      price_52w_l: 0.97,
      price_pc_1d: -4.762,
      price_pc_1w: -4.762,
      price_pc_1m: -17.241,
      price_pc_6m: -11.111,
      price_pc_1y: -62.382,
      price_pc_ytd: -62.382,
    },
    1995: {
      id: "1995",
      c: 10.6,
      ma50: 13.163,
      ma100: 13.126,
      ma150: 13.781,
      ma200: 14.647,
      maw30: 13.878,
      rs: 23.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: -13.0,
      price_5d_h: 11.1,
      price_5d_L: 9.41,
      price_1m_h: 14.86,
      price_1m_l: 6.59,
      price_3m_h: 16.96,
      price_3m_l: 6.59,
      price_6m_h: 17.9,
      price_6m_l: 6.59,
      price_52w_h: 22.55,
      price_52w_l: 6.59,
      price_pc_1d: -1.304,
      price_pc_1w: 1.533,
      price_pc_1m: -25.562,
      price_pc_6m: -31.701,
      price_pc_1y: -46.519,
      price_pc_ytd: -46.519,
    },
    1996: {
      id: "1996",
      c: 2.61,
      ma50: 2.414,
      ma100: 2.321,
      ma150: 2.354,
      ma200: 2.393,
      maw30: 2.353,
      rs: 83.0,
      rsd1d: 3.0,
      rsd5d: 5.0,
      rsd10d: 10.0,
      price_5d_h: 2.66,
      price_5d_L: 2.35,
      price_1m_h: 2.66,
      price_1m_l: 1.98,
      price_3m_h: 2.78,
      price_3m_l: 1.98,
      price_6m_h: 2.78,
      price_6m_l: 1.98,
      price_52w_h: 2.88,
      price_52w_l: 1.98,
      price_pc_1d: 1.163,
      price_pc_1w: 4.819,
      price_pc_1m: 16.518,
      price_pc_6m: 8.299,
      price_pc_1y: -8.741,
      price_pc_ytd: -8.741,
    },
    1997: {
      id: "1997",
      c: 38.85,
      ma50: 36.846,
      ma100: 38.752,
      ma150: 39.251,
      ma200: 40.231,
      maw30: 39.448,
      rs: 63.0,
      rsd1d: -2.0,
      rsd5d: -3.0,
      rsd10d: 4.0,
      price_5d_h: 40.65,
      price_5d_L: 38.35,
      price_1m_h: 40.65,
      price_1m_l: 32.5,
      price_3m_h: 40.65,
      price_3m_l: 32.5,
      price_6m_h: 45.8,
      price_6m_l: 32.5,
      price_52w_h: 47.8,
      price_52w_l: 32.5,
      price_pc_1d: -3.358,
      price_pc_1w: -1.019,
      price_pc_1m: 10.842,
      price_pc_6m: -3.598,
      price_pc_1y: -10.587,
      price_pc_ytd: -10.587,
    },
    1999: {
      id: "1999",
      c: 8.59,
      ma50: 10.099,
      ma100: 11.125,
      ma150: 11.656,
      ma200: 12.993,
      maw30: 11.609,
      rs: 16.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: -1.0,
      price_5d_h: 9.07,
      price_5d_L: 7.96,
      price_1m_h: 9.95,
      price_1m_l: 6.96,
      price_3m_h: 14.42,
      price_3m_l: 6.96,
      price_6m_h: 14.58,
      price_6m_l: 6.96,
      price_52w_h: 20.4,
      price_52w_l: 6.96,
      price_pc_1d: -3.266,
      price_pc_1w: -2.608,
      price_pc_1m: -6.12,
      price_pc_6m: -25.174,
      price_pc_1y: -49.172,
      price_pc_ytd: -49.172,
    },
    2000: {
      id: "2000",
      c: 0.35,
      ma50: 0.389,
      ma100: 0.411,
      ma150: 0.407,
      ma200: 0.407,
      maw30: 0.411,
      rs: 41.0,
      rsd1d: 3.0,
      rsd5d: -8.0,
      rsd10d: -5.0,
      price_5d_h: 0.36,
      price_5d_L: 0.34,
      price_1m_h: 0.395,
      price_1m_l: 0.32,
      price_3m_h: 0.485,
      price_3m_l: 0.32,
      price_6m_h: 0.485,
      price_6m_l: 0.32,
      price_52w_h: 0.485,
      price_52w_l: 0.32,
      price_pc_1d: 1.449,
      price_pc_1w: -2.778,
      price_pc_1m: -10.256,
      price_pc_6m: -14.634,
      price_pc_1y: -12.5,
      price_pc_ytd: -12.5,
    },
    2001: {
      id: "2001",
      c: 2.5,
      ma50: 2.622,
      ma100: 2.986,
      ma150: 3.259,
      ma200: 3.606,
      maw30: 3.265,
      rs: 17.0,
      rsd1d: -1.0,
      rsd5d: -2.0,
      rsd10d: 2.0,
      price_5d_h: 2.65,
      price_5d_L: 2.31,
      price_1m_h: 2.86,
      price_1m_l: 1.81,
      price_3m_h: 3.3,
      price_3m_l: 1.81,
      price_6m_h: 4.53,
      price_6m_l: 1.81,
      price_52w_h: 6.55,
      price_52w_l: 1.81,
      price_pc_1d: -2.724,
      price_pc_1w: -2.724,
      price_pc_1m: -12.587,
      price_pc_6m: -30.939,
      price_pc_1y: -44.444,
      price_pc_ytd: -44.444,
    },
    2002: {
      id: "2002",
      c: 2.36,
      ma50: 2.606,
      ma100: 2.37,
      ma150: 2.203,
      ma200: 2.107,
      maw30: 2.221,
      rs: 90.0,
      rsd1d: -2.0,
      rsd5d: 1.0,
      rsd10d: 1.0,
      price_5d_h: 2.58,
      price_5d_L: 2.3,
      price_1m_h: 2.97,
      price_1m_l: 2.04,
      price_3m_h: 3.05,
      price_3m_l: 2.04,
      price_6m_h: 3.05,
      price_6m_l: 1.46,
      price_52w_h: 3.05,
      price_52w_l: 1.46,
      price_pc_1d: -7.451,
      price_pc_1w: -1.667,
      price_pc_1m: -16.014,
      price_pc_6m: 31.111,
      price_pc_1y: 32.584,
      price_pc_ytd: 32.584,
    },
    2003: {
      id: "2003",
      c: 3.6,
      ma50: 3.652,
      ma100: 3.821,
      ma150: 4.116,
      ma200: 4.446,
      maw30: 4.083,
      rs: 35.0,
      rsd1d: -2.0,
      rsd5d: -2.0,
      rsd10d: 12.0,
      price_5d_h: 3.82,
      price_5d_L: 3.41,
      price_1m_h: 3.85,
      price_1m_l: 2.92,
      price_3m_h: 4.29,
      price_3m_l: 2.92,
      price_6m_h: 4.61,
      price_6m_l: 2.92,
      price_52w_h: 6.92,
      price_52w_l: 2.92,
      price_pc_1d: -2.703,
      price_pc_1w: -2.174,
      price_pc_1m: -1.37,
      price_pc_6m: -16.279,
      price_pc_1y: -23.404,
      price_pc_ytd: -23.404,
    },
    2005: {
      id: "2005",
      c: 3.54,
      ma50: 3.407,
      ma100: 3.587,
      ma150: 3.797,
      ma200: 4.297,
      maw30: 3.759,
      rs: 32.0,
      rsd1d: 2.0,
      rsd5d: 2.0,
      rsd10d: 7.0,
      price_5d_h: 3.58,
      price_5d_L: 3.12,
      price_1m_h: 3.66,
      price_1m_l: 2.5,
      price_3m_h: 4.2,
      price_3m_l: 2.5,
      price_6m_h: 4.2,
      price_6m_l: 2.5,
      price_52w_h: 8.29,
      price_52w_l: 2.5,
      price_pc_1d: 1.143,
      price_pc_1w: 7.927,
      price_pc_1m: -2.747,
      price_pc_6m: -15.914,
      price_pc_1y: -20.805,
      price_pc_ytd: -20.805,
    },
    2006: {
      id: "2006",
      c: 3.05,
      ma50: 2.949,
      ma100: 2.823,
      ma150: 2.431,
      ma200: 2.241,
      maw30: 2.455,
      rs: 95.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 3.06,
      price_5d_L: 2.95,
      price_1m_h: 3.06,
      price_1m_l: 2.81,
      price_3m_h: 3.06,
      price_3m_l: 2.81,
      price_6m_h: 3.06,
      price_6m_l: 1.54,
      price_52w_h: 3.06,
      price_52w_l: 1.44,
      price_pc_1d: 0.329,
      price_pc_1w: 2.694,
      price_pc_1m: 2.694,
      price_pc_6m: 95.513,
      price_pc_1y: 101.987,
      price_pc_ytd: 101.987,
    },
    2007: {
      id: "2007",
      c: 6.03,
      ma50: 6.081,
      ma100: 6.58,
      ma150: 6.994,
      ma200: 7.355,
      maw30: 7.013,
      rs: 29.0,
      rsd1d: 1.0,
      rsd5d: 9.0,
      rsd10d: 5.0,
      price_5d_h: 6.33,
      price_5d_L: 5.19,
      price_1m_h: 6.6,
      price_1m_l: 3.34,
      price_3m_h: 7.47,
      price_3m_l: 3.34,
      price_6m_h: 8.43,
      price_6m_l: 3.34,
      price_52w_h: 10.28,
      price_52w_l: 3.34,
      price_pc_1d: -0.822,
      price_pc_1w: 9.239,
      price_pc_1m: -0.495,
      price_pc_6m: -25.093,
      price_pc_1y: -39.94,
      price_pc_ytd: -39.94,
    },
    2008: {
      id: "2008",
      c: 0.38,
      ma50: 0.409,
      ma100: 0.441,
      ma150: 0.485,
      ma200: 0.526,
      maw30: 0.482,
      rs: 21.0,
      rsd1d: 3.0,
      rsd5d: 1.0,
      rsd10d: -3.0,
      price_5d_h: 0.405,
      price_5d_L: 0.365,
      price_1m_h: 0.43,
      price_1m_l: 0.35,
      price_3m_h: 0.48,
      price_3m_l: 0.35,
      price_6m_h: 0.61,
      price_6m_l: 0.32,
      price_52w_h: 1.06,
      price_52w_l: 0.32,
      price_pc_1d: 0.0,
      price_pc_1w: 1.333,
      price_pc_1m: -3.797,
      price_pc_6m: -32.143,
      price_pc_1y: -47.222,
      price_pc_ytd: -47.222,
    },
    2009: {
      id: "2009",
      c: 1.27,
      ma50: 1.247,
      ma100: 1.229,
      ma150: 1.279,
      ma200: 1.318,
      maw30: 1.282,
      rs: 64.0,
      rsd1d: 4.0,
      rsd5d: 18.0,
      rsd10d: 15.0,
      price_5d_h: 1.29,
      price_5d_L: 1.2,
      price_1m_h: 1.29,
      price_1m_l: 1.06,
      price_3m_h: 1.35,
      price_3m_l: 1.06,
      price_6m_h: 1.37,
      price_6m_l: 1.06,
      price_52w_h: 1.66,
      price_52w_l: 1.06,
      price_pc_1d: 0.794,
      price_pc_1w: 6.723,
      price_pc_1m: 1.6,
      price_pc_6m: -4.511,
      price_pc_1y: -21.605,
      price_pc_ytd: -21.605,
    },
    2011: {
      id: "2011",
      c: 1.31,
      ma50: 1.706,
      ma100: 1.997,
      ma150: 2.144,
      ma200: 2.254,
      maw30: 2.161,
      rs: 10.0,
      rsd1d: 2.0,
      rsd5d: 1.0,
      rsd10d: -1.0,
      price_5d_h: 1.34,
      price_5d_L: 1.23,
      price_1m_h: 1.49,
      price_1m_l: 1.1,
      price_3m_h: 2.5,
      price_3m_l: 1.1,
      price_6m_h: 2.64,
      price_6m_l: 1.1,
      price_52w_h: 2.85,
      price_52w_l: 1.1,
      price_pc_1d: 0.0,
      price_pc_1w: -2.239,
      price_pc_1m: -12.667,
      price_pc_6m: -46.964,
      price_pc_1y: -45.188,
      price_pc_ytd: -45.188,
    },
    2012: {
      id: "2012",
      c: 7.21,
      ma50: 5.275,
      ma100: 4.081,
      ma150: 3.819,
      ma200: 3.778,
      maw30: 3.744,
      rs: 98.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 7.88,
      price_5d_L: 7.0,
      price_1m_h: 9.65,
      price_1m_l: 4.6,
      price_3m_h: 9.65,
      price_3m_l: 2.73,
      price_6m_h: 9.65,
      price_6m_l: 2.68,
      price_52w_h: 9.65,
      price_52w_l: 1.62,
      price_pc_1d: -1.905,
      price_pc_1w: -7.564,
      price_pc_1m: 56.739,
      price_pc_6m: 125.312,
      price_pc_1y: 298.343,
      price_pc_ytd: 298.343,
    },
    2013: {
      id: "2013",
      c: 5.16,
      ma50: 5.418,
      ma100: 7.019,
      ma150: 8.613,
      ma200: 9.726,
      maw30: 8.661,
      rs: 7.0,
      rsd1d: -1.0,
      rsd5d: 1.0,
      rsd10d: 3.0,
      price_5d_h: 5.58,
      price_5d_L: 4.32,
      price_1m_h: 5.9,
      price_1m_l: 2.82,
      price_3m_h: 8.2,
      price_3m_l: 2.82,
      price_6m_h: 13.5,
      price_6m_l: 2.82,
      price_52w_h: 19.08,
      price_52w_l: 2.82,
      price_pc_1d: -3.551,
      price_pc_1w: -1.714,
      price_pc_1m: 0.781,
      price_pc_6m: -54.255,
      price_pc_1y: -72.756,
      price_pc_ytd: -72.756,
    },
    2015: {
      id: "2015",
      c: 105.3,
      ma50: 107.4,
      ma100: 114.487,
      ma150: 114.918,
      ma200: null,
      maw30: 114.925,
      rs: 69.0,
      rsd1d: -9.0,
      rsd5d: 2.0,
      rsd10d: 15.0,
      price_5d_h: 109.5,
      price_5d_L: 96.7,
      price_1m_h: 124.7,
      price_1m_l: 67.3,
      price_3m_h: 129.0,
      price_3m_l: 67.3,
      price_6m_h: 145.0,
      price_6m_l: 67.3,
      price_52w_h: 145.0,
      price_52w_l: 67.3,
      price_pc_1d: -2.68,
      price_pc_1w: -1.22,
      price_pc_1m: -4.36,
      price_pc_6m: 4.051,
      price_pc_1y: -10.0,
      price_pc_ytd: -10.0,
    },
    2016: {
      id: "2016",
      c: 3.3,
      ma50: 3.193,
      ma100: 3.199,
      ma150: 3.206,
      ma200: 3.327,
      maw30: 3.209,
      rs: 68.0,
      rsd1d: 6.0,
      rsd5d: 9.0,
      rsd10d: 7.0,
      price_5d_h: 3.32,
      price_5d_L: 2.94,
      price_1m_h: 3.32,
      price_1m_l: 2.94,
      price_3m_h: 3.33,
      price_3m_l: 2.94,
      price_6m_h: 3.37,
      price_6m_l: 2.94,
      price_52w_h: 4.3,
      price_52w_l: 2.94,
      price_pc_1d: 1.538,
      price_pc_1w: 2.484,
      price_pc_1m: 4.43,
      price_pc_6m: 5.431,
      price_pc_1y: -7.042,
      price_pc_ytd: -7.042,
    },
    2017: {
      id: "2017",
      c: 0.315,
      ma50: 0.323,
      ma100: 0.321,
      ma150: 0.334,
      ma200: 0.37,
      maw30: 0.332,
      rs: 34.0,
      rsd1d: 7.0,
      rsd5d: 14.0,
      rsd10d: -1.0,
      price_5d_h: 0.345,
      price_5d_L: 0.28,
      price_1m_h: 0.39,
      price_1m_l: 0.27,
      price_3m_h: 0.395,
      price_3m_l: 0.265,
      price_6m_h: 0.42,
      price_6m_l: 0.265,
      price_52w_h: 0.68,
      price_52w_l: 0.265,
      price_pc_1d: 3.279,
      price_pc_1w: 12.5,
      price_pc_1m: -11.268,
      price_pc_6m: -14.865,
      price_pc_1y: -31.522,
      price_pc_ytd: -31.522,
    },
    2018: {
      id: "2018",
      c: 18.98,
      ma50: 22.052,
      ma100: 27.437,
      ma150: 30.835,
      ma200: 35.932,
      maw30: 30.372,
      rs: 8.0,
      rsd1d: 0.0,
      rsd5d: 3.0,
      rsd10d: 0.0,
      price_5d_h: 19.96,
      price_5d_L: 17.5,
      price_1m_h: 22.6,
      price_1m_l: 15.78,
      price_3m_h: 34.4,
      price_3m_l: 15.78,
      price_6m_h: 37.3,
      price_6m_l: 15.78,
      price_52w_h: 60.0,
      price_52w_l: 15.78,
      price_pc_1d: -2.366,
      price_pc_1w: 7.596,
      price_pc_1m: -11.308,
      price_pc_6m: -48.91,
      price_pc_1y: -51.949,
      price_pc_ytd: -51.949,
    },
    2019: {
      id: "2019",
      c: 3.0,
      ma50: 2.838,
      ma100: 2.719,
      ma150: 2.729,
      ma200: 2.783,
      maw30: 2.719,
      rs: 83.0,
      rsd1d: -3.0,
      rsd5d: -2.0,
      rsd10d: -2.0,
      price_5d_h: 3.27,
      price_5d_L: 2.83,
      price_1m_h: 3.29,
      price_1m_l: 2.55,
      price_3m_h: 3.29,
      price_3m_l: 2.55,
      price_6m_h: 3.29,
      price_6m_l: 2.42,
      price_52w_h: 3.34,
      price_52w_l: 2.42,
      price_pc_1d: -0.332,
      price_pc_1w: 1.01,
      price_pc_1m: 3.806,
      price_pc_6m: 7.527,
      price_pc_1y: -0.662,
      price_pc_ytd: -0.662,
    },
    2020: {
      id: "2020",
      c: 98.4,
      ma50: 113.222,
      ma100: 117.682,
      ma150: 126.025,
      ma200: 138.117,
      maw30: 125.718,
      rs: 26.0,
      rsd1d: 0.0,
      rsd5d: -5.0,
      rsd10d: -11.0,
      price_5d_h: 101.6,
      price_5d_L: 93.5,
      price_1m_h: 122.2,
      price_1m_l: 79.35,
      price_3m_h: 134.2,
      price_3m_l: 79.35,
      price_6m_h: 148.5,
      price_6m_l: 79.35,
      price_52w_h: 191.9,
      price_52w_l: 79.35,
      price_pc_1d: -2.09,
      price_pc_1w: -3.245,
      price_pc_1m: -17.241,
      price_pc_6m: -32.97,
      price_pc_1y: -24.655,
      price_pc_ytd: -24.655,
    },
    2022: {
      id: "2022",
      c: 0.107,
      ma50: 0.107,
      ma100: 0.096,
      ma150: 0.09,
      ma200: 0.089,
      maw30: 0.09,
      rs: 92.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: 1.0,
      price_5d_h: 0.113,
      price_5d_L: 0.1,
      price_1m_h: 0.13,
      price_1m_l: 0.092,
      price_3m_h: 0.132,
      price_3m_l: 0.075,
      price_6m_h: 0.132,
      price_6m_l: 0.067,
      price_52w_h: 0.132,
      price_52w_l: 0.065,
      price_pc_1d: -1.835,
      price_pc_1w: -0.926,
      price_pc_1m: -5.31,
      price_pc_6m: 55.072,
      price_pc_1y: 25.882,
      price_pc_ytd: 25.882,
    },
    2023: {
      id: "2023",
      c: 1.16,
      ma50: 1.185,
      ma100: 1.187,
      ma150: 1.205,
      ma200: 1.194,
      maw30: 1.2,
      rs: 80.0,
      rsd1d: -2.0,
      rsd5d: 2.0,
      rsd10d: -3.0,
      price_5d_h: 1.19,
      price_5d_L: 1.12,
      price_1m_h: 1.22,
      price_1m_l: 1.12,
      price_3m_h: 1.26,
      price_3m_l: 0.99,
      price_6m_h: 1.26,
      price_6m_l: 0.99,
      price_52w_h: 1.35,
      price_52w_l: 0.95,
      price_pc_1d: -2.521,
      price_pc_1w: -0.855,
      price_pc_1m: -2.521,
      price_pc_6m: -6.452,
      price_pc_1y: 16.0,
      price_pc_ytd: 16.0,
    },
    2025: {
      id: "2025",
      c: 1.32,
      ma50: 1.286,
      ma100: 1.104,
      ma150: 1.054,
      ma200: 1.038,
      maw30: 1.056,
      rs: 93.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: -2.0,
      price_5d_h: 1.38,
      price_5d_L: 1.3,
      price_1m_h: 1.42,
      price_1m_l: 1.26,
      price_3m_h: 1.42,
      price_3m_l: 0.87,
      price_6m_h: 1.42,
      price_6m_l: 0.78,
      price_52w_h: 1.42,
      price_52w_l: 0.73,
      price_pc_1d: -1.493,
      price_pc_1w: 0.763,
      price_pc_1m: -0.752,
      price_pc_6m: 40.426,
      price_pc_1y: 32.0,
      price_pc_ytd: 32.0,
    },
    2030: {
      id: "2030",
      c: 2.72,
      ma50: 2.804,
      ma100: 2.896,
      ma150: 3.02,
      ma200: 3.151,
      maw30: 3.022,
      rs: 66.0,
      rsd1d: 2.0,
      rsd5d: 3.0,
      rsd10d: 7.0,
      price_5d_h: 2.77,
      price_5d_L: 2.63,
      price_1m_h: 2.9,
      price_1m_l: 2.55,
      price_3m_h: 3.09,
      price_3m_l: 2.55,
      price_6m_h: 3.46,
      price_6m_l: 2.55,
      price_52w_h: 4.32,
      price_52w_l: 2.37,
      price_pc_1d: -1.091,
      price_pc_1w: -0.366,
      price_pc_1m: 1.873,
      price_pc_6m: -10.82,
      price_pc_1y: 9.237,
      price_pc_ytd: 9.237,
    },
    2031: {
      id: "2031",
      c: 0.222,
      ma50: 0.229,
      ma100: 0.231,
      ma150: 0.244,
      ma200: 0.255,
      maw30: 0.241,
      rs: 43.0,
      rsd1d: 1.0,
      rsd5d: -4.0,
      rsd10d: -11.0,
      price_5d_h: 0.23,
      price_5d_L: 0.217,
      price_1m_h: 0.238,
      price_1m_l: 0.212,
      price_3m_h: 0.245,
      price_3m_l: 0.212,
      price_6m_h: 0.3,
      price_6m_l: 0.2,
      price_52w_h: 0.47,
      price_52w_l: 0.2,
      price_pc_1d: 0.0,
      price_pc_1w: 1.835,
      price_pc_1m: -5.932,
      price_pc_6m: -17.778,
      price_pc_1y: -23.448,
      price_pc_ytd: -23.448,
    },
    2033: {
      id: "2033",
      c: 0.53,
      ma50: 0.587,
      ma100: 0.668,
      ma150: 0.711,
      ma200: 0.747,
      maw30: 0.714,
      rs: 21.0,
      rsd1d: 5.0,
      rsd5d: 3.0,
      rsd10d: -2.0,
      price_5d_h: 0.54,
      price_5d_L: 0.455,
      price_1m_h: 0.59,
      price_1m_l: 0.455,
      price_3m_h: 0.74,
      price_3m_l: 0.455,
      price_6m_h: 0.92,
      price_6m_l: 0.455,
      price_52w_h: 0.92,
      price_52w_l: 0.455,
      price_pc_1d: 3.922,
      price_pc_1w: 0.0,
      price_pc_1m: -10.169,
      price_pc_6m: -31.169,
      price_pc_1y: -32.911,
      price_pc_ytd: -32.911,
    },
    2038: {
      id: "2038",
      c: 1.08,
      ma50: 1.129,
      ma100: 1.195,
      ma150: 1.191,
      ma200: 1.179,
      maw30: 1.197,
      rs: 49.0,
      rsd1d: 4.0,
      rsd5d: -5.0,
      rsd10d: 8.0,
      price_5d_h: 1.1,
      price_5d_L: 1.05,
      price_1m_h: 1.17,
      price_1m_l: 0.87,
      price_3m_h: 1.48,
      price_3m_l: 0.87,
      price_6m_h: 1.48,
      price_6m_l: 0.87,
      price_52w_h: 1.48,
      price_52w_l: 0.87,
      price_pc_1d: 0.0,
      price_pc_1w: -1.818,
      price_pc_1m: -6.087,
      price_pc_6m: -7.692,
      price_pc_1y: -5.263,
      price_pc_ytd: -5.263,
    },
    2039: {
      id: "2039",
      c: 12.82,
      ma50: 13.295,
      ma100: 13.846,
      ma150: 14.766,
      ma200: 15.209,
      maw30: 14.751,
      rs: 46.0,
      rsd1d: -3.0,
      rsd5d: -3.0,
      rsd10d: -1.0,
      price_5d_h: 13.24,
      price_5d_L: 11.42,
      price_1m_h: 13.3,
      price_1m_l: 10.96,
      price_3m_h: 15.2,
      price_3m_l: 10.96,
      price_6m_h: 16.54,
      price_6m_l: 10.96,
      price_52w_h: 18.9,
      price_52w_l: 10.96,
      price_pc_1d: -0.927,
      price_pc_1w: 0.0,
      price_pc_1m: -0.927,
      price_pc_6m: -22.019,
      price_pc_1y: -6.56,
      price_pc_ytd: -6.56,
    },
    2048: {
      id: "2048",
      c: 0.88,
      ma50: 1.461,
      ma100: 1.631,
      ma150: 1.618,
      ma200: 2.406,
      maw30: 1.6,
      rs: 4.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: -4.0,
      price_5d_h: 0.98,
      price_5d_L: 0.86,
      price_1m_h: 1.41,
      price_1m_l: 0.81,
      price_3m_h: 2.2,
      price_3m_l: 0.81,
      price_6m_h: 2.81,
      price_6m_l: 0.81,
      price_52w_h: 9.69,
      price_52w_l: 0.81,
      price_pc_1d: -6.383,
      price_pc_1w: -5.376,
      price_pc_1m: -35.766,
      price_pc_6m: -25.424,
      price_pc_1y: -88.267,
      price_pc_ytd: -88.267,
    },
    2051: {
      id: "2051",
      c: 0.36,
      ma50: 0.45,
      ma100: 0.519,
      ma150: 0.614,
      ma200: 0.752,
      maw30: 0.61,
      rs: 8.0,
      rsd1d: 3.0,
      rsd5d: 3.0,
      rsd10d: 5.0,
      price_5d_h: 0.39,
      price_5d_L: 0.33,
      price_1m_h: 0.495,
      price_1m_l: 0.26,
      price_3m_h: 0.58,
      price_3m_l: 0.26,
      price_6m_h: 0.97,
      price_6m_l: 0.26,
      price_52w_h: 1.72,
      price_52w_l: 0.26,
      price_pc_1d: 7.463,
      price_pc_1w: 0.0,
      price_pc_1m: -26.531,
      price_pc_6m: -48.571,
      price_pc_1y: -67.568,
      price_pc_ytd: -67.568,
    },
    2057: {
      id: "2057",
      c: 199.6,
      ma50: 218.634,
      ma100: 224.495,
      ma150: 228.035,
      ma200: 226.198,
      maw30: 231.077,
      rs: 53.0,
      rsd1d: -8.0,
      rsd5d: -9.0,
      rsd10d: 8.0,
      price_5d_h: 217.0,
      price_5d_L: 194.2,
      price_1m_h: 233.2,
      price_1m_l: 141.2,
      price_3m_h: 259.2,
      price_3m_l: 141.2,
      price_6m_h: 261.2,
      price_6m_l: 141.2,
      price_52w_h: 279.0,
      price_52w_l: 141.2,
      price_pc_1d: -3.575,
      price_pc_1w: -5.76,
      price_pc_1m: -11.368,
      price_pc_6m: -17.521,
      price_pc_1y: -11.603,
      price_pc_ytd: -11.603,
    },
    2060: {
      id: "2060",
      c: 2.93,
      ma50: 3.025,
      ma100: 3.199,
      ma150: 3.275,
      ma200: 3.345,
      maw30: 3.284,
      rs: 39.0,
      rsd1d: 0.0,
      rsd5d: -4.0,
      rsd10d: -9.0,
      price_5d_h: 3.07,
      price_5d_L: 2.82,
      price_1m_h: 3.14,
      price_1m_l: 2.65,
      price_3m_h: 3.6,
      price_3m_l: 2.65,
      price_6m_h: 3.78,
      price_6m_l: 2.65,
      price_52w_h: 4.12,
      price_52w_l: 2.65,
      price_pc_1d: 1.034,
      price_pc_1w: -0.678,
      price_pc_1m: -2.333,
      price_pc_6m: -18.611,
      price_pc_1y: -10.942,
      price_pc_ytd: -10.942,
    },
    2068: {
      id: "2068",
      c: 2.34,
      ma50: 2.464,
      ma100: 2.344,
      ma150: 2.242,
      ma200: 2.063,
      maw30: 2.278,
      rs: 92.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: 7.0,
      price_5d_h: 2.52,
      price_5d_L: 2.31,
      price_1m_h: 2.84,
      price_1m_l: 1.84,
      price_3m_h: 3.23,
      price_3m_l: 1.84,
      price_6m_h: 3.72,
      price_6m_l: 1.58,
      price_52w_h: 3.72,
      price_52w_l: 1.27,
      price_pc_1d: -2.092,
      price_pc_1w: -2.905,
      price_pc_1m: -11.364,
      price_pc_6m: 6.849,
      price_pc_1y: 77.273,
      price_pc_ytd: 77.273,
    },
    2078: {
      id: "2078",
      c: 0.23,
      ma50: 0.238,
      ma100: 0.246,
      ma150: 0.251,
      ma200: 0.256,
      maw30: 0.251,
      rs: 40.0,
      rsd1d: 0.0,
      rsd5d: 4.0,
      rsd10d: 4.0,
      price_5d_h: 0.25,
      price_5d_L: 0.218,
      price_1m_h: 0.25,
      price_1m_l: 0.175,
      price_3m_h: 0.275,
      price_3m_l: 0.175,
      price_6m_h: 0.305,
      price_6m_l: 0.175,
      price_52w_h: 0.385,
      price_52w_l: 0.175,
      price_pc_1d: 0.0,
      price_pc_1w: -3.766,
      price_pc_1m: -9.804,
      price_pc_6m: -9.804,
      price_pc_1y: -40.26,
      price_pc_ytd: -40.26,
    },
    2080: {
      id: "2080",
      c: 0.71,
      ma50: 0.698,
      ma100: 0.745,
      ma150: 0.718,
      ma200: 0.7,
      maw30: 0.719,
      rs: 91.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: 9.0,
      price_5d_h: 0.71,
      price_5d_L: 0.65,
      price_1m_h: 0.78,
      price_1m_l: 0.48,
      price_3m_h: 0.87,
      price_3m_l: 0.48,
      price_6m_h: 1.0,
      price_6m_l: 0.48,
      price_52w_h: 1.0,
      price_52w_l: 0.3,
      price_pc_1d: 7.576,
      price_pc_1w: 2.899,
      price_pc_1m: 2.899,
      price_pc_6m: 36.538,
      price_pc_1y: 91.892,
      price_pc_ytd: 91.892,
    },
    2086: {
      id: "2086",
      c: 0.81,
      ma50: 0.84,
      ma100: 0.933,
      ma150: 0.879,
      ma200: 0.811,
      maw30: 0.89,
      rs: 81.0,
      rsd1d: 11.0,
      rsd5d: 33.0,
      rsd10d: 13.0,
      price_5d_h: 0.84,
      price_5d_L: 0.62,
      price_1m_h: 0.98,
      price_1m_l: 0.62,
      price_3m_h: 1.62,
      price_3m_l: 0.62,
      price_6m_h: 1.62,
      price_6m_l: 0.62,
      price_52w_h: 1.62,
      price_52w_l: 0.5,
      price_pc_1d: 6.579,
      price_pc_1w: 15.714,
      price_pc_1m: -14.737,
      price_pc_6m: 2.532,
      price_pc_1y: 20.896,
      price_pc_ytd: 20.896,
    },
    2096: {
      id: "2096",
      c: 10.58,
      ma50: 9.221,
      ma100: 9.115,
      ma150: 8.604,
      ma200: 9.186,
      maw30: 8.533,
      rs: 90.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: 8.0,
      price_5d_h: 11.6,
      price_5d_L: 10.28,
      price_1m_h: 11.6,
      price_1m_l: 8.04,
      price_3m_h: 12.02,
      price_3m_l: 7.31,
      price_6m_h: 12.02,
      price_6m_l: 6.45,
      price_52w_h: 14.98,
      price_52w_l: 6.45,
      price_pc_1d: -3.818,
      price_pc_1w: -5.027,
      price_pc_1m: 11.958,
      price_pc_6m: 55.132,
      price_pc_1y: 27.011,
      price_pc_ytd: 27.011,
    },
    2098: {
      id: "2098",
      c: 0.485,
      ma50: 0.474,
      ma100: 0.474,
      ma150: 0.482,
      ma200: 0.501,
      maw30: 0.48,
      rs: 68.0,
      rsd1d: 3.0,
      rsd5d: 4.0,
      rsd10d: 6.0,
      price_5d_h: 0.51,
      price_5d_L: 0.48,
      price_1m_h: 0.51,
      price_1m_l: 0.46,
      price_3m_h: 0.51,
      price_3m_l: 0.41,
      price_6m_h: 0.56,
      price_6m_l: 0.405,
      price_52w_h: 0.62,
      price_52w_l: 0.405,
      price_pc_1d: 0.0,
      price_pc_1w: -1.02,
      price_pc_1m: 0.0,
      price_pc_6m: -3.0,
      price_pc_1y: -17.797,
      price_pc_ytd: -17.797,
    },
    2099: {
      id: "2099",
      c: 27.85,
      ma50: 24.119,
      ma100: 22.416,
      ma150: 22.646,
      ma200: 22.232,
      maw30: 22.541,
      rs: 92.0,
      rsd1d: -2.0,
      rsd5d: -2.0,
      rsd10d: 3.0,
      price_5d_h: 30.8,
      price_5d_L: 27.4,
      price_1m_h: 30.8,
      price_1m_l: 21.6,
      price_3m_h: 30.8,
      price_3m_l: 19.5,
      price_6m_h: 30.8,
      price_6m_l: 19.2,
      price_52w_h: 30.8,
      price_52w_l: 18.82,
      price_pc_1d: -3.966,
      price_pc_1w: -3.966,
      price_pc_1m: 19.785,
      price_pc_6m: 26.879,
      price_pc_1y: 26.879,
      price_pc_ytd: 26.879,
    },
    2100: {
      id: "2100",
      c: 0.48,
      ma50: 0.555,
      ma100: 0.592,
      ma150: 0.641,
      ma200: 0.811,
      maw30: 0.633,
      rs: 11.0,
      rsd1d: 1.0,
      rsd5d: -2.0,
      rsd10d: -2.0,
      price_5d_h: 0.52,
      price_5d_L: 0.465,
      price_1m_h: 0.6,
      price_1m_l: 0.4,
      price_3m_h: 0.69,
      price_3m_l: 0.4,
      price_6m_h: 0.85,
      price_6m_l: 0.4,
      price_52w_h: 2.37,
      price_52w_l: 0.4,
      price_pc_1d: -1.031,
      price_pc_1w: -9.434,
      price_pc_1m: -17.241,
      price_pc_6m: -35.135,
      price_pc_1y: -77.143,
      price_pc_ytd: -77.143,
    },
    2101: {
      id: "2101",
      c: 6.61,
      ma50: 6.881,
      ma100: 7.21,
      ma150: 7.279,
      ma200: 7.811,
      maw30: 7.29,
      rs: 36.0,
      rsd1d: 2.0,
      rsd5d: -1.0,
      rsd10d: -8.0,
      price_5d_h: 6.69,
      price_5d_L: 6.01,
      price_1m_h: 7.12,
      price_1m_l: 5.77,
      price_3m_h: 7.92,
      price_3m_l: 5.77,
      price_6m_h: 8.53,
      price_6m_l: 5.77,
      price_52w_h: 12.44,
      price_52w_l: 5.77,
      price_pc_1d: 1.692,
      price_pc_1w: 2.006,
      price_pc_1m: -4.48,
      price_pc_6m: -14.156,
      price_pc_1y: -25.057,
      price_pc_ytd: -25.057,
    },
    2102: {
      id: "2102",
      c: 0.27,
      ma50: 0.28,
      ma100: 0.294,
      ma150: 0.296,
      ma200: 0.291,
      maw30: 0.295,
      rs: 68.0,
      rsd1d: 1.0,
      rsd5d: 3.0,
      rsd10d: -17.0,
      price_5d_h: 0.27,
      price_5d_L: 0.255,
      price_1m_h: 0.295,
      price_1m_l: 0.255,
      price_3m_h: 0.295,
      price_3m_l: 0.25,
      price_6m_h: 0.355,
      price_6m_l: 0.25,
      price_52w_h: 0.39,
      price_52w_l: 0.24,
      price_pc_1d: 1.887,
      price_pc_1w: 3.846,
      price_pc_1m: -6.897,
      price_pc_6m: 0.0,
      price_pc_1y: 3.846,
      price_pc_ytd: 3.846,
    },
    2107: {
      id: "2107",
      c: 0.83,
      ma50: 0.937,
      ma100: 1.243,
      ma150: 1.226,
      ma200: 1.152,
      maw30: 1.224,
      rs: 19.0,
      rsd1d: 10.0,
      rsd5d: 5.0,
      rsd10d: 1.0,
      price_5d_h: 0.87,
      price_5d_L: 0.7,
      price_1m_h: 1.02,
      price_1m_l: 0.7,
      price_3m_h: 1.3,
      price_3m_l: 0.65,
      price_6m_h: 2.21,
      price_6m_l: 0.65,
      price_52w_h: 2.21,
      price_52w_l: 0.65,
      price_pc_1d: 5.063,
      price_pc_1w: 2.469,
      price_pc_1m: -18.627,
      price_pc_6m: -43.919,
      price_pc_1y: -33.6,
      price_pc_ytd: -33.6,
    },
    2108: {
      id: "2108",
      c: 0.234,
      ma50: 0.252,
      ma100: 0.269,
      ma150: 0.266,
      ma200: 0.263,
      maw30: 0.267,
      rs: 54.0,
      rsd1d: 2.0,
      rsd5d: 19.0,
      rsd10d: 17.0,
      price_5d_h: 0.234,
      price_5d_L: 0.215,
      price_1m_h: 0.25,
      price_1m_l: 0.201,
      price_3m_h: 0.315,
      price_3m_l: 0.201,
      price_6m_h: 0.315,
      price_6m_l: 0.201,
      price_52w_h: 0.325,
      price_52w_l: 0.185,
      price_pc_1d: 0.0,
      price_pc_1w: 8.837,
      price_pc_1m: -6.4,
      price_pc_6m: -6.4,
      price_pc_1y: 6.364,
      price_pc_ytd: 6.364,
    },
    2110: {
      id: "2110",
      c: 0.132,
      ma50: 0.144,
      ma100: 0.15,
      ma150: 0.147,
      ma200: 0.143,
      maw30: 0.147,
      rs: 67.0,
      rsd1d: -4.0,
      rsd5d: -2.0,
      rsd10d: 2.0,
      price_5d_h: 0.139,
      price_5d_L: 0.128,
      price_1m_h: 0.148,
      price_1m_l: 0.123,
      price_3m_h: 0.173,
      price_3m_l: 0.123,
      price_6m_h: 0.187,
      price_6m_l: 0.123,
      price_52w_h: 0.187,
      price_52w_l: 0.12,
      price_pc_1d: -2.941,
      price_pc_1w: -2.222,
      price_pc_1m: -5.714,
      price_pc_6m: -0.752,
      price_pc_1y: 4.762,
      price_pc_ytd: 4.762,
    },
    2111: {
      id: "2111",
      c: 2.2,
      ma50: 2.219,
      ma100: 2.201,
      ma150: 2.289,
      ma200: 2.344,
      maw30: 2.283,
      rs: 80.0,
      rsd1d: -3.0,
      rsd5d: 7.0,
      rsd10d: 16.0,
      price_5d_h: 2.34,
      price_5d_L: 2.15,
      price_1m_h: 2.34,
      price_1m_l: 1.88,
      price_3m_h: 2.37,
      price_3m_l: 1.88,
      price_6m_h: 2.64,
      price_6m_l: 1.88,
      price_52w_h: 3.0,
      price_52w_l: 1.77,
      price_pc_1d: -2.655,
      price_pc_1w: 0.917,
      price_pc_1m: 0.0,
      price_pc_6m: -8.333,
      price_pc_1y: 20.219,
      price_pc_ytd: 20.219,
    },
    2112: {
      id: "2112",
      c: 0.97,
      ma50: 0.277,
      ma100: 0.177,
      ma150: 0.144,
      ma200: 0.128,
      maw30: 0.126,
      rs: 99.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 1.3,
      price_5d_L: 0.53,
      price_1m_h: 1.3,
      price_1m_l: 0.127,
      price_3m_h: 1.3,
      price_3m_l: 0.071,
      price_6m_h: 1.3,
      price_6m_l: 0.058,
      price_52w_h: 1.3,
      price_52w_l: 0.058,
      price_pc_1d: 1.042,
      price_pc_1w: 42.647,
      price_pc_1m: 592.857,
      price_pc_6m: 1210.811,
      price_pc_1y: 989.888,
      price_pc_ytd: 989.888,
    },
    2113: {
      id: "2113",
      c: 0.31,
      ma50: 0.302,
      ma100: 0.326,
      ma150: 0.334,
      ma200: 0.328,
      maw30: 0.336,
      rs: 57.0,
      rsd1d: 18.0,
      rsd5d: 7.0,
      rsd10d: -11.0,
      price_5d_h: 0.31,
      price_5d_L: 0.25,
      price_1m_h: 0.31,
      price_1m_l: 0.25,
      price_3m_h: 0.395,
      price_3m_l: 0.25,
      price_6m_h: 0.42,
      price_6m_l: 0.25,
      price_52w_h: 0.43,
      price_52w_l: 0.25,
      price_pc_1d: 8.772,
      price_pc_1w: 3.333,
      price_pc_1m: 3.333,
      price_pc_6m: -16.216,
      price_pc_1y: -12.676,
      price_pc_ytd: -12.676,
    },
    2115: {
      id: "2115",
      c: 0.16,
      ma50: 0.131,
      ma100: 0.132,
      ma150: 0.129,
      ma200: 0.132,
      maw30: 0.129,
      rs: 88.0,
      rsd1d: 1.0,
      rsd5d: 29.0,
      rsd10d: 41.0,
      price_5d_h: 0.164,
      price_5d_L: 0.125,
      price_1m_h: 0.164,
      price_1m_l: 0.11,
      price_3m_h: 0.164,
      price_3m_l: 0.11,
      price_6m_h: 0.164,
      price_6m_l: 0.11,
      price_52w_h: 0.194,
      price_52w_l: 0.11,
      price_pc_1d: 7.383,
      price_pc_1w: 31.148,
      price_pc_1m: 26.984,
      price_pc_6m: 31.148,
      price_pc_1y: 5.263,
      price_pc_ytd: 5.263,
    },
    2117: {
      id: "2117",
      c: 3.86,
      ma50: 4.307,
      ma100: 4.229,
      ma150: 4.359,
      ma200: 4.328,
      maw30: 4.382,
      rs: 54.0,
      rsd1d: -7.0,
      rsd5d: -8.0,
      rsd10d: 5.0,
      price_5d_h: 4.08,
      price_5d_L: 3.8,
      price_1m_h: 4.24,
      price_1m_l: 3.56,
      price_3m_h: 5.12,
      price_3m_l: 3.56,
      price_6m_h: 5.3,
      price_6m_l: 3.07,
      price_52w_h: 6.46,
      price_52w_l: 3.07,
      price_pc_1d: -3.741,
      price_pc_1w: -3.98,
      price_pc_1m: -6.311,
      price_pc_6m: -16.81,
      price_pc_1y: -13.647,
      price_pc_ytd: -13.647,
    },
    2118: {
      id: "2118",
      c: 1.97,
      ma50: 1.953,
      ma100: 1.957,
      ma150: 1.946,
      ma200: 1.937,
      maw30: 1.948,
      rs: 74.0,
      rsd1d: -2.0,
      rsd5d: 3.0,
      rsd10d: 6.0,
      price_5d_h: 1.99,
      price_5d_L: 1.95,
      price_1m_h: 1.99,
      price_1m_l: 1.85,
      price_3m_h: 2.11,
      price_3m_l: 1.79,
      price_6m_h: 2.11,
      price_6m_l: 1.0,
      price_52w_h: 2.18,
      price_52w_l: 1.0,
      price_pc_1d: 0.0,
      price_pc_1w: -0.505,
      price_pc_1m: 7.65,
      price_pc_6m: 0.0,
      price_pc_1y: -6.635,
      price_pc_ytd: -6.635,
    },
    2119: {
      id: "2119",
      c: 0.96,
      ma50: 0.984,
      ma100: 0.987,
      ma150: 0.992,
      ma200: 1.004,
      maw30: 0.99,
      rs: 64.0,
      rsd1d: 0.0,
      rsd5d: 2.0,
      rsd10d: 1.0,
      price_5d_h: 0.98,
      price_5d_L: 0.95,
      price_1m_h: 1.0,
      price_1m_l: 0.93,
      price_3m_h: 1.02,
      price_3m_l: 0.93,
      price_6m_h: 1.02,
      price_6m_l: 0.93,
      price_52w_h: 1.12,
      price_52w_l: 0.93,
      price_pc_1d: -1.031,
      price_pc_1w: 0.0,
      price_pc_1m: -2.041,
      price_pc_6m: -3.03,
      price_pc_1y: -8.571,
      price_pc_ytd: -8.571,
    },
    2120: {
      id: "2120",
      c: 12.1,
      ma50: 13.568,
      ma100: 19.148,
      ma150: 22.817,
      ma200: 26.968,
      maw30: 23.102,
      rs: 6.0,
      rsd1d: 1.0,
      rsd5d: 2.0,
      rsd10d: 2.0,
      price_5d_h: 12.5,
      price_5d_L: 11.3,
      price_1m_h: 12.5,
      price_1m_l: 7.8,
      price_3m_h: 24.35,
      price_3m_l: 7.8,
      price_6m_h: 36.0,
      price_6m_l: 7.8,
      price_52w_h: 55.0,
      price_52w_l: 7.8,
      price_pc_1d: -0.657,
      price_pc_1w: 5.217,
      price_pc_1m: 6.14,
      price_pc_6m: -61.526,
      price_pc_1y: -51.6,
      price_pc_ytd: -51.6,
    },
    2121: {
      id: "2121",
      c: 16.02,
      ma50: null,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 38.0,
      rsd1d: 8.0,
      rsd5d: 8.0,
      rsd10d: 30.0,
      price_5d_h: 16.3,
      price_5d_L: 14.48,
      price_1m_h: 16.3,
      price_1m_l: 7.57,
      price_3m_h: 27.0,
      price_3m_l: 7.57,
      price_6m_h: 27.0,
      price_6m_l: 7.57,
      price_52w_h: 27.0,
      price_52w_l: 7.57,
      price_pc_1d: 4.297,
      price_pc_1w: 4.161,
      price_pc_1m: 2.168,
      price_pc_6m: -18.265,
      price_pc_1y: -18.265,
      price_pc_ytd: -18.265,
    },
    2122: {
      id: "2122",
      c: 0.171,
      ma50: 0.216,
      ma100: 0.224,
      ma150: 0.237,
      ma200: 0.241,
      maw30: 0.236,
      rs: 25.0,
      rsd1d: 8.0,
      rsd5d: 3.0,
      rsd10d: 10.0,
      price_5d_h: 0.178,
      price_5d_L: 0.16,
      price_1m_h: 0.26,
      price_1m_l: 0.144,
      price_3m_h: 0.275,
      price_3m_l: 0.144,
      price_6m_h: 0.275,
      price_6m_l: 0.144,
      price_52w_h: 0.335,
      price_52w_l: 0.144,
      price_pc_1d: 6.875,
      price_pc_1w: 0.0,
      price_pc_1m: -35.472,
      price_pc_6m: -30.204,
      price_pc_1y: -30.488,
      price_pc_ytd: -30.488,
    },
    2125: {
      id: "2125",
      c: 4.76,
      ma50: 4.678,
      ma100: 4.141,
      ma150: 3.865,
      ma200: 4.491,
      maw30: 3.854,
      rs: 64.0,
      rsd1d: 20.0,
      rsd5d: 42.0,
      rsd10d: -21.0,
      price_5d_h: 4.76,
      price_5d_L: 3.93,
      price_1m_h: 4.95,
      price_1m_l: 3.5,
      price_3m_h: 6.37,
      price_3m_l: 3.5,
      price_6m_h: 6.81,
      price_6m_l: 2.14,
      price_52w_h: 15.3,
      price_52w_l: 2.14,
      price_pc_1d: 12.0,
      price_pc_1w: 16.381,
      price_pc_1m: -0.833,
      price_pc_6m: 103.419,
      price_pc_1y: -64.583,
      price_pc_ytd: -64.583,
    },
    2126: {
      id: "2126",
      c: 8.12,
      ma50: 9.4,
      ma100: 10.912,
      ma150: 12.769,
      ma200: 15.248,
      maw30: 12.742,
      rs: 7.0,
      rsd1d: 0.0,
      rsd5d: 2.0,
      rsd10d: -2.0,
      price_5d_h: 9.35,
      price_5d_L: 7.79,
      price_1m_h: 10.48,
      price_1m_l: 6.81,
      price_3m_h: 13.88,
      price_3m_l: 6.81,
      price_6m_h: 15.64,
      price_6m_l: 6.81,
      price_52w_h: 36.5,
      price_52w_l: 6.81,
      price_pc_1d: -5.251,
      price_pc_1w: -13.34,
      price_pc_1m: -17.98,
      price_pc_6m: -45.576,
      price_pc_1y: -76.395,
      price_pc_ytd: -76.395,
    },
    2127: {
      id: "2127",
      c: 0.82,
      ma50: 1.212,
      ma100: 1.665,
      ma150: 1.923,
      ma200: 2.037,
      maw30: 1.927,
      rs: 3.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.92,
      price_5d_L: 0.79,
      price_1m_h: 1.28,
      price_1m_l: 0.71,
      price_3m_h: 2.17,
      price_3m_l: 0.71,
      price_6m_h: 2.61,
      price_6m_l: 0.71,
      price_52w_h: 3.48,
      price_52w_l: 0.71,
      price_pc_1d: -2.381,
      price_pc_1w: -5.747,
      price_pc_1m: -37.405,
      price_pc_6m: -66.935,
      price_pc_1y: -59.606,
      price_pc_ytd: -59.606,
    },
    2128: {
      id: "2128",
      c: 9.52,
      ma50: 12.199,
      ma100: 12.049,
      ma150: 12.48,
      ma200: 13.658,
      maw30: 12.51,
      rs: 22.0,
      rsd1d: 0.0,
      rsd5d: -12.0,
      rsd10d: -17.0,
      price_5d_h: 10.58,
      price_5d_L: 8.8,
      price_1m_h: 12.42,
      price_1m_l: 8.27,
      price_3m_h: 16.42,
      price_3m_l: 8.27,
      price_6m_h: 16.42,
      price_6m_l: 8.27,
      price_52w_h: 21.6,
      price_52w_l: 8.27,
      price_pc_1d: -2.158,
      price_pc_1w: -10.526,
      price_pc_1m: -20.534,
      price_pc_6m: -23.718,
      price_pc_1y: -47.807,
      price_pc_ytd: -47.807,
    },
    2129: {
      id: "2129",
      c: 0.105,
      ma50: 0.108,
      ma100: 0.12,
      ma150: 0.126,
      ma200: 0.135,
      maw30: 0.126,
      rs: 27.0,
      rsd1d: 3.0,
      rsd5d: 4.0,
      rsd10d: 11.0,
      price_5d_h: 0.113,
      price_5d_L: 0.103,
      price_1m_h: 0.129,
      price_1m_l: 0.085,
      price_3m_h: 0.129,
      price_3m_l: 0.085,
      price_6m_h: 0.164,
      price_6m_l: 0.085,
      price_52w_h: 0.212,
      price_52w_l: 0.085,
      price_pc_1d: -0.943,
      price_pc_1w: 0.0,
      price_pc_1m: -1.869,
      price_pc_6m: -13.223,
      price_pc_1y: -42.935,
      price_pc_ytd: -42.935,
    },
    2130: {
      id: "2130",
      c: 10.38,
      ma50: 9.733,
      ma100: 9.73,
      ma150: 9.26,
      ma200: 8.866,
      maw30: 9.288,
      rs: 90.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: -2.0,
      price_5d_h: 10.8,
      price_5d_L: 9.9,
      price_1m_h: 11.04,
      price_1m_l: 9.18,
      price_3m_h: 11.04,
      price_3m_l: 8.79,
      price_6m_h: 11.26,
      price_6m_l: 7.64,
      price_52w_h: 11.26,
      price_52w_l: 7.03,
      price_pc_1d: -1.143,
      price_pc_1w: -3.889,
      price_pc_1m: 11.613,
      price_pc_6m: 35.156,
      price_pc_1y: 26.431,
      price_pc_ytd: 26.431,
    },
    2131: {
      id: "2131",
      c: 2.11,
      ma50: 2.258,
      ma100: 2.59,
      ma150: 3.028,
      ma200: 3.526,
      maw30: 3.005,
      rs: 11.0,
      rsd1d: 1.0,
      rsd5d: -5.0,
      rsd10d: 2.0,
      price_5d_h: 2.21,
      price_5d_L: 1.93,
      price_1m_h: 2.36,
      price_1m_l: 1.55,
      price_3m_h: 3.35,
      price_3m_l: 1.55,
      price_6m_h: 4.22,
      price_6m_l: 1.55,
      price_52w_h: 8.06,
      price_52w_l: 1.55,
      price_pc_1d: -2.315,
      price_pc_1w: -4.091,
      price_pc_1m: -10.213,
      price_pc_6m: -43.733,
      price_pc_1y: -69.771,
      price_pc_ytd: -69.771,
    },
    2132: {
      id: "2132",
      c: 0.255,
      ma50: 0.247,
      ma100: 0.251,
      ma150: 0.239,
      ma200: 0.22,
      maw30: 0.24,
      rs: 92.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.26,
      price_5d_L: 0.247,
      price_1m_h: 0.265,
      price_1m_l: 0.235,
      price_3m_h: 0.275,
      price_3m_l: 0.208,
      price_6m_h: 0.315,
      price_6m_l: 0.194,
      price_52w_h: 0.315,
      price_52w_l: 0.148,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: -1.923,
      price_pc_6m: 31.443,
      price_pc_1y: 47.399,
      price_pc_ytd: 47.399,
    },
    2135: {
      id: "2135",
      c: 0.187,
      ma50: 0.218,
      ma100: 0.262,
      ma150: 0.29,
      ma200: 0.351,
      maw30: 0.287,
      rs: 11.0,
      rsd1d: 1.0,
      rsd5d: 4.0,
      rsd10d: 1.0,
      price_5d_h: 0.198,
      price_5d_L: 0.171,
      price_1m_h: 0.237,
      price_1m_l: 0.15,
      price_3m_h: 0.305,
      price_3m_l: 0.15,
      price_6m_h: 0.485,
      price_6m_l: 0.15,
      price_52w_h: 1.05,
      price_52w_l: 0.15,
      price_pc_1d: -3.109,
      price_pc_1w: 3.889,
      price_pc_1m: -13.023,
      price_pc_6m: -39.677,
      price_pc_1y: -45.0,
      price_pc_ytd: -45.0,
    },
    2136: {
      id: "2136",
      c: 0.84,
      ma50: 1.047,
      ma100: 1.1,
      ma150: 1.083,
      ma200: 1.113,
      maw30: 1.089,
      rs: 25.0,
      rsd1d: -2.0,
      rsd5d: -1.0,
      rsd10d: -4.0,
      price_5d_h: 0.88,
      price_5d_L: 0.82,
      price_1m_h: 1.1,
      price_1m_l: 0.79,
      price_3m_h: 1.23,
      price_3m_l: 0.79,
      price_6m_h: 1.35,
      price_6m_l: 0.79,
      price_52w_h: 1.38,
      price_52w_l: 0.79,
      price_pc_1d: -3.448,
      price_pc_1w: -4.545,
      price_pc_1m: -24.324,
      price_pc_6m: -14.286,
      price_pc_1y: -26.316,
      price_pc_ytd: -26.316,
    },
    2137: {
      id: "2137",
      c: 11.1,
      ma50: 14.05,
      ma100: 24.092,
      ma150: 27.938,
      ma200: null,
      maw30: 27.71,
      rs: 2.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 11.66,
      price_5d_L: 8.87,
      price_1m_h: 16.36,
      price_1m_l: 8.32,
      price_3m_h: 34.8,
      price_3m_l: 8.32,
      price_6m_h: 48.65,
      price_6m_l: 8.32,
      price_52w_h: 52.6,
      price_52w_l: 8.32,
      price_pc_1d: -0.359,
      price_pc_1w: 4.52,
      price_pc_1m: -23.868,
      price_pc_6m: -71.575,
      price_pc_1y: -51.634,
      price_pc_ytd: -51.634,
    },
    2138: {
      id: "2138",
      c: 8.34,
      ma50: 8.192,
      ma100: 9.893,
      ma150: 10.259,
      ma200: 10.948,
      maw30: 10.28,
      rs: 39.0,
      rsd1d: 1.0,
      rsd5d: 10.0,
      rsd10d: 12.0,
      price_5d_h: 8.4,
      price_5d_L: 7.41,
      price_1m_h: 8.4,
      price_1m_l: 5.8,
      price_3m_h: 11.3,
      price_3m_l: 5.8,
      price_6m_h: 13.36,
      price_6m_l: 5.8,
      price_52w_h: 16.26,
      price_52w_l: 5.8,
      price_pc_1d: 0.482,
      price_pc_1w: 4.25,
      price_pc_1m: 5.838,
      price_pc_6m: -20.873,
      price_pc_1y: 15.512,
      price_pc_ytd: 15.512,
    },
    2139: {
      id: "2139",
      c: 1.05,
      ma50: 1.069,
      ma100: 1.102,
      ma150: 1.102,
      ma200: 1.131,
      maw30: 1.107,
      rs: 35.0,
      rsd1d: -1.0,
      rsd5d: -17.0,
      rsd10d: -31.0,
      price_5d_h: 1.07,
      price_5d_L: 1.03,
      price_1m_h: 1.1,
      price_1m_l: 1.0,
      price_3m_h: 1.54,
      price_3m_l: 1.0,
      price_6m_h: 1.54,
      price_6m_l: 0.98,
      price_52w_h: 1.54,
      price_52w_l: 0.98,
      price_pc_1d: 0.0,
      price_pc_1w: -1.869,
      price_pc_1m: 0.0,
      price_pc_6m: -4.545,
      price_pc_1y: -23.358,
      price_pc_ytd: -23.358,
    },
    2142: {
      id: "2142",
      c: 3.56,
      ma50: 4.594,
      ma100: 6.295,
      ma150: 6.542,
      ma200: 7.208,
      maw30: 6.56,
      rs: 5.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -1.0,
      price_5d_h: 4.03,
      price_5d_L: 3.45,
      price_1m_h: 4.67,
      price_1m_l: 3.08,
      price_3m_h: 7.89,
      price_3m_l: 3.08,
      price_6m_h: 10.5,
      price_6m_l: 3.08,
      price_52w_h: 12.4,
      price_52w_l: 3.08,
      price_pc_1d: -2.997,
      price_pc_1w: -9.415,
      price_pc_1m: -20.889,
      price_pc_6m: -46.866,
      price_pc_1y: -60.965,
      price_pc_ytd: -60.965,
    },
    2146: {
      id: "2146",
      c: 3.96,
      ma50: 4.65,
      ma100: 5.224,
      ma150: 6.347,
      ma200: 7.1,
      maw30: 6.356,
      rs: 11.0,
      rsd1d: 2.0,
      rsd5d: 0.0,
      rsd10d: 1.0,
      price_5d_h: 4.16,
      price_5d_L: 3.9,
      price_1m_h: 4.89,
      price_1m_l: 3.3,
      price_3m_h: 6.06,
      price_3m_l: 3.3,
      price_6m_h: 9.27,
      price_6m_l: 3.3,
      price_52w_h: 11.42,
      price_52w_l: 3.3,
      price_pc_1d: -0.252,
      price_pc_1w: -0.752,
      price_pc_1m: -20.8,
      price_pc_6m: -53.136,
      price_pc_1y: -56.34,
      price_pc_ytd: -56.34,
    },
    2148: {
      id: "2148",
      c: 5.36,
      ma50: 5.861,
      ma100: 7.927,
      ma150: 9.077,
      ma200: 9.6,
      maw30: 9.072,
      rs: 7.0,
      rsd1d: 0.0,
      rsd5d: -4.0,
      rsd10d: 1.0,
      price_5d_h: 6.19,
      price_5d_L: 5.05,
      price_1m_h: 6.19,
      price_1m_l: 3.9,
      price_3m_h: 9.6,
      price_3m_l: 3.9,
      price_6m_h: 11.8,
      price_6m_l: 3.9,
      price_52w_h: 15.38,
      price_52w_l: 3.9,
      price_pc_1d: -2.722,
      price_pc_1w: -11.405,
      price_pc_1m: -1.107,
      price_pc_6m: -51.799,
      price_pc_1y: -63.237,
      price_pc_ytd: -63.237,
    },
    2150: {
      id: "2150",
      c: 4.9,
      ma50: 6.176,
      ma100: 7.419,
      ma150: 8.763,
      ma200: null,
      maw30: 8.843,
      rs: 5.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 5.21,
      price_5d_L: 4.6,
      price_1m_h: 6.36,
      price_1m_l: 3.65,
      price_3m_h: 8.55,
      price_3m_l: 3.65,
      price_6m_h: 11.6,
      price_6m_l: 3.65,
      price_52w_h: 18.98,
      price_52w_l: 3.65,
      price_pc_1d: -2.778,
      price_pc_1w: -2.39,
      price_pc_1m: -20.712,
      price_pc_6m: -56.483,
      price_pc_1y: -71.379,
      price_pc_ytd: -71.379,
    },
    2153: {
      id: "2153",
      c: 1.17,
      ma50: 1.177,
      ma100: 1.176,
      ma150: 1.225,
      ma200: 1.287,
      maw30: 1.223,
      rs: 55.0,
      rsd1d: 1.0,
      rsd5d: -3.0,
      rsd10d: 15.0,
      price_5d_h: 1.25,
      price_5d_L: 1.15,
      price_1m_h: 1.31,
      price_1m_l: 0.86,
      price_3m_h: 1.35,
      price_3m_l: 0.86,
      price_6m_h: 1.38,
      price_6m_l: 0.86,
      price_52w_h: 1.7,
      price_52w_l: 0.86,
      price_pc_1d: -0.847,
      price_pc_1w: -2.5,
      price_pc_1m: -0.847,
      price_pc_6m: -10.0,
      price_pc_1y: -20.408,
      price_pc_ytd: -20.408,
    },
    2155: {
      id: "2155",
      c: 8.6,
      ma50: 7.808,
      ma100: 9.419,
      ma150: 9.481,
      ma200: null,
      maw30: 9.388,
      rs: 74.0,
      rsd1d: 4.0,
      rsd5d: 48.0,
      rsd10d: 53.0,
      price_5d_h: 8.71,
      price_5d_L: 7.13,
      price_1m_h: 8.71,
      price_1m_l: 5.42,
      price_3m_h: 9.72,
      price_3m_l: 5.42,
      price_6m_h: 13.9,
      price_6m_l: 5.42,
      price_52w_h: 15.64,
      price_52w_l: 5.42,
      price_pc_1d: 0.821,
      price_pc_1w: 16.059,
      price_pc_1m: 10.682,
      price_pc_6m: -5.495,
      price_pc_1y: 10.54,
      price_pc_ytd: 10.54,
    },
    2156: {
      id: "2156",
      c: 4.02,
      ma50: 3.907,
      ma100: 4.032,
      ma150: 4.096,
      ma200: 4.242,
      maw30: 4.116,
      rs: 72.0,
      rsd1d: 2.0,
      rsd5d: 8.0,
      rsd10d: 38.0,
      price_5d_h: 4.05,
      price_5d_L: 3.71,
      price_1m_h: 4.05,
      price_1m_l: 2.67,
      price_3m_h: 4.49,
      price_3m_l: 2.67,
      price_6m_h: 4.5,
      price_6m_l: 2.67,
      price_52w_h: 5.44,
      price_52w_l: 2.67,
      price_pc_1d: 0.5,
      price_pc_1w: 3.077,
      price_pc_1m: 0.752,
      price_pc_6m: -5.634,
      price_pc_1y: 23.692,
      price_pc_ytd: 23.692,
    },
    2157: {
      id: "2157",
      c: 6.54,
      ma50: null,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 59.0,
      rsd1d: 4.0,
      rsd5d: 1.0,
      rsd10d: -17.0,
      price_5d_h: 6.6,
      price_5d_L: 6.45,
      price_1m_h: 7.17,
      price_1m_l: 6.29,
      price_3m_h: 7.34,
      price_3m_l: 6.29,
      price_6m_h: 7.34,
      price_6m_l: 6.29,
      price_52w_h: 7.34,
      price_52w_l: 6.29,
      price_pc_1d: 0.615,
      price_pc_1w: -0.909,
      price_pc_1m: -8.275,
      price_pc_6m: -8.403,
      price_pc_1y: -8.403,
      price_pc_ytd: -8.403,
    },
    2158: {
      id: "2158",
      c: 9.79,
      ma50: 12.608,
      ma100: 17.481,
      ma150: 21.11,
      ma200: 25.096,
      maw30: 20.85,
      rs: 3.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 1.0,
      price_5d_h: 11.04,
      price_5d_L: 8.81,
      price_1m_h: 12.34,
      price_1m_l: 7.1,
      price_3m_h: 22.45,
      price_3m_l: 7.1,
      price_6m_h: 28.8,
      price_6m_l: 7.1,
      price_52w_h: 48.4,
      price_52w_l: 7.1,
      price_pc_1d: -1.707,
      price_pc_1w: -10.512,
      price_pc_1m: -12.118,
      price_pc_6m: -62.128,
      price_pc_1y: -74.101,
      price_pc_ytd: -74.101,
    },
    2159: {
      id: "2159",
      c: 1.09,
      ma50: 1.193,
      ma100: 1.292,
      ma150: 1.363,
      ma200: 1.508,
      maw30: 1.368,
      rs: 17.0,
      rsd1d: 1.0,
      rsd5d: 4.0,
      rsd10d: 4.0,
      price_5d_h: 1.2,
      price_5d_L: 1.0,
      price_1m_h: 1.2,
      price_1m_l: 0.83,
      price_3m_h: 1.62,
      price_3m_l: 0.83,
      price_6m_h: 1.63,
      price_6m_l: 0.83,
      price_52w_h: 2.89,
      price_52w_l: 0.83,
      price_pc_1d: -0.909,
      price_pc_1w: 4.808,
      price_pc_1m: -6.838,
      price_pc_6m: -26.846,
      price_pc_1y: -62.284,
      price_pc_ytd: -62.284,
    },
    2160: {
      id: "2160",
      c: 2.85,
      ma50: 3.05,
      ma100: 3.726,
      ma150: 4.671,
      ma200: 6.738,
      maw30: 4.596,
      rs: 7.0,
      rsd1d: 3.0,
      rsd5d: 2.0,
      rsd10d: 3.0,
      price_5d_h: 2.9,
      price_5d_L: 2.42,
      price_1m_h: 3.16,
      price_1m_l: 2.05,
      price_3m_h: 4.28,
      price_3m_l: 2.05,
      price_6m_h: 6.3,
      price_6m_l: 2.05,
      price_52w_h: 18.3,
      price_52w_l: 2.05,
      price_pc_1d: 8.365,
      price_pc_1w: 2.151,
      price_pc_1m: -4.362,
      price_pc_6m: -54.327,
      price_pc_1y: -79.701,
      price_pc_ytd: -79.701,
    },
    2161: {
      id: "2161",
      c: 0.91,
      ma50: 0.942,
      ma100: 0.906,
      ma150: 0.962,
      ma200: 1.059,
      maw30: 0.961,
      rs: 33.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: -16.0,
      price_5d_h: 0.95,
      price_5d_L: 0.83,
      price_1m_h: 1.01,
      price_1m_l: 0.83,
      price_3m_h: 1.18,
      price_3m_l: 0.83,
      price_6m_h: 1.19,
      price_6m_l: 0.7,
      price_52w_h: 2.33,
      price_52w_l: 0.7,
      price_pc_1d: 1.111,
      price_pc_1w: 4.598,
      price_pc_1m: -7.143,
      price_pc_6m: -14.151,
      price_pc_1y: -30.0,
      price_pc_ytd: -30.0,
    },
    2162: {
      id: "2162",
      c: 21.65,
      ma50: 25.433,
      ma100: 31.952,
      ma150: 37.254,
      ma200: null,
      maw30: 37.279,
      rs: 8.0,
      rsd1d: 1.0,
      rsd5d: 2.0,
      rsd10d: 3.0,
      price_5d_h: 22.05,
      price_5d_L: 18.06,
      price_1m_h: 30.6,
      price_1m_l: 16.9,
      price_3m_h: 34.5,
      price_3m_l: 16.9,
      price_6m_h: 54.25,
      price_6m_l: 16.9,
      price_52w_h: 79.8,
      price_52w_l: 16.9,
      price_pc_1d: 3.589,
      price_pc_1w: 2.607,
      price_pc_1m: -24.433,
      price_pc_6m: -57.92,
      price_pc_1y: -68.162,
      price_pc_ytd: -68.162,
    },
    2163: {
      id: "2163",
      c: 9.74,
      ma50: 10.306,
      ma100: 10.496,
      ma150: 10.606,
      ma200: 11.577,
      maw30: 10.68,
      rs: 26.0,
      rsd1d: 1.0,
      rsd5d: 4.0,
      rsd10d: 6.0,
      price_5d_h: 9.9,
      price_5d_L: 8.5,
      price_1m_h: 11.0,
      price_1m_l: 7.34,
      price_3m_h: 12.38,
      price_3m_l: 7.34,
      price_6m_h: 13.4,
      price_6m_l: 7.34,
      price_52w_h: 21.9,
      price_52w_l: 7.34,
      price_pc_1d: 3.617,
      price_pc_1w: 11.06,
      price_pc_1m: -11.131,
      price_pc_6m: 1.458,
      price_pc_1y: -55.727,
      price_pc_ytd: -55.727,
    },
    2165: {
      id: "2165",
      c: 3.68,
      ma50: 3.789,
      ma100: 3.875,
      ma150: 3.96,
      ma200: null,
      maw30: 3.969,
      rs: 55.0,
      rsd1d: -3.0,
      rsd5d: 10.0,
      rsd10d: -5.0,
      price_5d_h: 3.77,
      price_5d_L: 3.32,
      price_1m_h: 3.83,
      price_1m_l: 3.04,
      price_3m_h: 3.98,
      price_3m_l: 3.04,
      price_6m_h: 4.3,
      price_6m_l: 2.48,
      price_52w_h: 5.5,
      price_52w_l: 2.48,
      price_pc_1d: -2.387,
      price_pc_1w: 4.545,
      price_pc_1m: -4.167,
      price_pc_6m: -12.381,
      price_pc_1y: -12.172,
      price_pc_ytd: -12.172,
    },
    2166: {
      id: "2166",
      c: 1.35,
      ma50: 1.429,
      ma100: 1.489,
      ma150: 1.491,
      ma200: 1.511,
      maw30: 1.479,
      rs: 63.0,
      rsd1d: 4.0,
      rsd5d: 2.0,
      rsd10d: -2.0,
      price_5d_h: 1.46,
      price_5d_L: 1.27,
      price_1m_h: 1.47,
      price_1m_l: 1.1,
      price_3m_h: 1.8,
      price_3m_l: 1.1,
      price_6m_h: 1.8,
      price_6m_l: 1.1,
      price_52w_h: 2.3,
      price_52w_l: 1.1,
      price_pc_1d: 2.273,
      price_pc_1w: 3.846,
      price_pc_1m: -11.765,
      price_pc_6m: -1.46,
      price_pc_1y: 12.5,
      price_pc_ytd: 12.5,
    },
    2168: {
      id: "2168",
      c: 8.21,
      ma50: 10.211,
      ma100: 11.517,
      ma150: 14.602,
      ma200: 17.669,
      maw30: 14.417,
      rs: 7.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: 0.0,
      price_5d_h: 8.8,
      price_5d_L: 7.6,
      price_1m_h: 10.96,
      price_1m_l: 5.38,
      price_3m_h: 12.98,
      price_3m_l: 5.38,
      price_6m_h: 23.0,
      price_6m_l: 5.38,
      price_52w_h: 34.0,
      price_52w_l: 5.38,
      price_pc_1d: -3.412,
      price_pc_1w: -1.441,
      price_pc_1m: -21.81,
      price_pc_6m: -62.079,
      price_pc_1y: -69.077,
      price_pc_ytd: -69.077,
    },
    2169: {
      id: "2169",
      c: 2.16,
      ma50: 2.297,
      ma100: 2.05,
      ma150: 1.867,
      ma200: 1.753,
      maw30: 1.87,
      rs: 93.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: -2.0,
      price_5d_h: 2.21,
      price_5d_L: 1.99,
      price_1m_h: 2.52,
      price_1m_l: 1.99,
      price_3m_h: 2.75,
      price_3m_l: 1.8,
      price_6m_h: 2.75,
      price_6m_l: 1.37,
      price_52w_h: 2.75,
      price_52w_l: 1.14,
      price_pc_1d: 0.465,
      price_pc_1w: 3.349,
      price_pc_1m: -10.373,
      price_pc_6m: 39.355,
      price_pc_1y: 67.442,
      price_pc_ytd: 67.442,
    },
    2170: {
      id: "2170",
      c: 4.95,
      ma50: 5.79,
      ma100: 6.905,
      ma150: 8.503,
      ma200: 11.243,
      maw30: 8.435,
      rs: 7.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: 3.0,
      price_5d_h: 5.25,
      price_5d_L: 4.82,
      price_1m_h: 7.18,
      price_1m_l: 3.7,
      price_3m_h: 7.8,
      price_3m_l: 3.7,
      price_6m_h: 12.4,
      price_6m_l: 3.7,
      price_52w_h: 28.9,
      price_52w_l: 3.7,
      price_pc_1d: -2.941,
      price_pc_1w: -5.714,
      price_pc_1m: -30.38,
      price_pc_6m: -57.765,
      price_pc_1y: -75.25,
      price_pc_ytd: -75.25,
    },
    2171: {
      id: "2171",
      c: 15.42,
      ma50: 16.823,
      ma100: 26.359,
      ma150: 31.955,
      ma200: null,
      maw30: 32.484,
      rs: 7.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: 5.0,
      price_5d_h: 16.78,
      price_5d_L: 14.14,
      price_1m_h: 21.5,
      price_1m_l: 10.02,
      price_3m_h: 30.15,
      price_3m_l: 10.02,
      price_6m_h: 57.25,
      price_6m_l: 10.02,
      price_52w_h: 57.25,
      price_52w_l: 10.02,
      price_pc_1d: -0.772,
      price_pc_1w: -5.515,
      price_pc_1m: -19.098,
      price_pc_6m: -71.706,
      price_pc_1y: -48.6,
      price_pc_ytd: -48.6,
    },
    2175: {
      id: "2175",
      c: 3.83,
      ma50: 3.734,
      ma100: 3.938,
      ma150: 4.026,
      ma200: null,
      maw30: 4.015,
      rs: 69.0,
      rsd1d: 4.0,
      rsd5d: 20.0,
      rsd10d: 10.0,
      price_5d_h: 3.99,
      price_5d_L: 3.52,
      price_1m_h: 4.0,
      price_1m_l: 3.5,
      price_3m_h: 4.0,
      price_3m_l: 3.49,
      price_6m_h: 4.7,
      price_6m_l: 3.49,
      price_52w_h: 4.8,
      price_52w_l: 3.29,
      price_pc_1d: 1.862,
      price_pc_1w: 5.51,
      price_pc_1m: 4.645,
      price_pc_6m: -4.489,
      price_pc_1y: 3.794,
      price_pc_ytd: 3.794,
    },
    2177: {
      id: "2177",
      c: 2.71,
      ma50: 3.192,
      ma100: 3.962,
      ma150: 4.407,
      ma200: null,
      maw30: 4.436,
      rs: 9.0,
      rsd1d: 1.0,
      rsd5d: 2.0,
      rsd10d: 3.0,
      price_5d_h: 2.85,
      price_5d_L: 2.65,
      price_1m_h: 3.13,
      price_1m_l: 1.9,
      price_3m_h: 4.8,
      price_3m_l: 1.9,
      price_6m_h: 5.55,
      price_6m_l: 1.9,
      price_52w_h: 12.5,
      price_52w_l: 1.9,
      price_pc_1d: -1.812,
      price_pc_1w: 4.231,
      price_pc_1m: -14.78,
      price_pc_6m: -50.727,
      price_pc_1y: -70.022,
      price_pc_ytd: -70.022,
    },
    2178: {
      id: "2178",
      c: 0.096,
      ma50: 0.103,
      ma100: 0.107,
      ma150: 0.107,
      ma200: 0.104,
      maw30: 0.108,
      rs: 71.0,
      rsd1d: 21.0,
      rsd5d: 5.0,
      rsd10d: 32.0,
      price_5d_h: 0.098,
      price_5d_L: 0.08,
      price_1m_h: 0.147,
      price_1m_l: 0.074,
      price_3m_h: 0.147,
      price_3m_l: 0.074,
      price_6m_h: 0.18,
      price_6m_l: 0.074,
      price_52w_h: 0.18,
      price_52w_l: 0.074,
      price_pc_1d: 10.345,
      price_pc_1w: 3.226,
      price_pc_1m: -8.571,
      price_pc_6m: -4.0,
      price_pc_1y: 3.226,
      price_pc_ytd: 3.226,
    },
    2180: {
      id: "2180",
      c: 8.61,
      ma50: 8.888,
      ma100: 8.989,
      ma150: 9.056,
      ma200: 9.084,
      maw30: 9.072,
      rs: 61.0,
      rsd1d: 10.0,
      rsd5d: 5.0,
      rsd10d: 6.0,
      price_5d_h: 8.88,
      price_5d_L: 8.35,
      price_1m_h: 9.3,
      price_1m_l: 8.02,
      price_3m_h: 9.3,
      price_3m_l: 8.02,
      price_6m_h: 9.89,
      price_6m_l: 8.02,
      price_52w_h: 10.3,
      price_52w_l: 8.02,
      price_pc_1d: 2.99,
      price_pc_1w: 2.135,
      price_pc_1m: -7.419,
      price_pc_6m: -6.413,
      price_pc_1y: -12.677,
      price_pc_ytd: -12.677,
    },
    2181: {
      id: "2181",
      c: 0.75,
      ma50: 0.895,
      ma100: 0.946,
      ma150: 0.967,
      ma200: 1.02,
      maw30: 0.968,
      rs: 23.0,
      rsd1d: -11.0,
      rsd5d: -29.0,
      rsd10d: -18.0,
      price_5d_h: 0.93,
      price_5d_L: 0.72,
      price_1m_h: 0.93,
      price_1m_l: 0.7,
      price_3m_h: 1.01,
      price_3m_l: 0.7,
      price_6m_h: 1.2,
      price_6m_l: 0.7,
      price_52w_h: 1.4,
      price_52w_l: 0.7,
      price_pc_1d: -8.537,
      price_pc_1w: -16.667,
      price_pc_1m: -17.582,
      price_pc_6m: -18.478,
      price_pc_1y: -38.525,
      price_pc_ytd: -38.525,
    },
    2182: {
      id: "2182",
      c: 0.385,
      ma50: 0.384,
      ma100: 0.405,
      ma150: 0.405,
      ma200: 0.408,
      maw30: 0.406,
      rs: 63.0,
      rsd1d: -8.0,
      rsd5d: 17.0,
      rsd10d: 27.0,
      price_5d_h: 0.43,
      price_5d_L: 0.35,
      price_1m_h: 0.43,
      price_1m_l: 0.27,
      price_3m_h: 0.445,
      price_3m_l: 0.27,
      price_6m_h: 0.52,
      price_6m_l: 0.27,
      price_52w_h: 0.56,
      price_52w_l: 0.27,
      price_pc_1d: -3.75,
      price_pc_1w: 10.0,
      price_pc_1m: 2.667,
      price_pc_6m: 0.0,
      price_pc_1y: -7.229,
      price_pc_ytd: -7.229,
    },
    2185: {
      id: "2185",
      c: 46.9,
      ma50: 38.096,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 96.0,
      rsd1d: -1.0,
      rsd5d: -2.0,
      rsd10d: -1.0,
      price_5d_h: 48.9,
      price_5d_L: 43.0,
      price_1m_h: 49.2,
      price_1m_l: 35.9,
      price_3m_h: 49.2,
      price_3m_l: 26.55,
      price_6m_h: 49.2,
      price_6m_l: 18.32,
      price_52w_h: 49.2,
      price_52w_l: 18.32,
      price_pc_1d: -1.985,
      price_pc_1w: 4.454,
      price_pc_1m: 13.973,
      price_pc_6m: 133.333,
      price_pc_1y: 133.333,
      price_pc_ytd: 133.333,
    },
    2186: {
      id: "2186",
      c: 3.0,
      ma50: 3.254,
      ma100: 3.443,
      ma150: 3.619,
      ma200: 3.851,
      maw30: 3.623,
      rs: 24.0,
      rsd1d: 7.0,
      rsd5d: -3.0,
      rsd10d: -1.0,
      price_5d_h: 3.12,
      price_5d_L: 2.6,
      price_1m_h: 3.24,
      price_1m_l: 2.55,
      price_3m_h: 3.83,
      price_3m_l: 2.55,
      price_6m_h: 4.14,
      price_6m_l: 2.55,
      price_52w_h: 5.84,
      price_52w_l: 2.55,
      price_pc_1d: 6.007,
      price_pc_1w: -3.846,
      price_pc_1m: -4.762,
      price_pc_6m: -26.29,
      price_pc_1y: -38.272,
      price_pc_ytd: -38.272,
    },
    2187: {
      id: "2187",
      c: 3.02,
      ma50: 2.559,
      ma100: 2.216,
      ma150: 1.995,
      ma200: 1.824,
      maw30: 2.008,
      rs: 97.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -1.0,
      price_5d_h: 3.2,
      price_5d_L: 2.97,
      price_1m_h: 3.57,
      price_1m_l: 2.31,
      price_3m_h: 3.57,
      price_3m_l: 1.75,
      price_6m_h: 3.57,
      price_6m_l: 1.23,
      price_52w_h: 3.57,
      price_52w_l: 0.99,
      price_pc_1d: -4.127,
      price_pc_1w: -1.629,
      price_pc_1m: 31.304,
      price_pc_6m: 100.0,
      price_pc_1y: 187.619,
      price_pc_ytd: 187.619,
    },
    2188: {
      id: "2188",
      c: 0.355,
      ma50: 0.353,
      ma100: 0.366,
      ma150: 0.374,
      ma200: 0.384,
      maw30: 0.374,
      rs: 63.0,
      rsd1d: 4.0,
      rsd5d: 7.0,
      rsd10d: 10.0,
      price_5d_h: 0.355,
      price_5d_L: 0.33,
      price_1m_h: 0.38,
      price_1m_l: 0.295,
      price_3m_h: 0.38,
      price_3m_l: 0.295,
      price_6m_h: 0.46,
      price_6m_l: 0.295,
      price_52w_h: 0.47,
      price_52w_l: 0.295,
      price_pc_1d: 0.0,
      price_pc_1w: 1.429,
      price_pc_1m: 0.0,
      price_pc_6m: -8.974,
      price_pc_1y: -11.25,
      price_pc_ytd: -11.25,
    },
    2189: {
      id: "2189",
      c: 0.62,
      ma50: 0.634,
      ma100: 0.644,
      ma150: 0.644,
      ma200: 0.645,
      maw30: 0.645,
      rs: 68.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 7.0,
      price_5d_h: 0.62,
      price_5d_L: 0.62,
      price_1m_h: 0.65,
      price_1m_l: 0.57,
      price_3m_h: 0.67,
      price_3m_l: 0.57,
      price_6m_h: 0.67,
      price_6m_l: 0.57,
      price_52w_h: 0.7,
      price_52w_l: 0.57,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: -3.125,
      price_pc_6m: -1.587,
      price_pc_1y: 1.639,
      price_pc_ytd: 1.639,
    },
    2190: {
      id: "2190",
      c: 12.68,
      ma50: 16.372,
      ma100: 20.495,
      ma150: 26.176,
      ma200: null,
      maw30: 26.099,
      rs: 3.0,
      rsd1d: 0.0,
      rsd5d: -2.0,
      rsd10d: -1.0,
      price_5d_h: 13.48,
      price_5d_L: 12.26,
      price_1m_h: 14.94,
      price_1m_l: 11.2,
      price_3m_h: 26.45,
      price_3m_l: 11.2,
      price_6m_h: 43.8,
      price_6m_l: 11.2,
      price_52w_h: 60.8,
      price_52w_l: 11.2,
      price_pc_1d: -2.462,
      price_pc_1w: -3.647,
      price_pc_1m: -6.352,
      price_pc_6m: -71.05,
      price_pc_1y: -78.867,
      price_pc_ytd: -78.867,
    },
    2191: {
      id: "2191",
      c: 3.26,
      ma50: 3.42,
      ma100: 3.439,
      ma150: 3.625,
      ma200: 3.809,
      maw30: 3.608,
      rs: 43.0,
      rsd1d: -1.0,
      rsd5d: -9.0,
      rsd10d: 0.0,
      price_5d_h: 3.42,
      price_5d_L: 3.16,
      price_1m_h: 3.5,
      price_1m_l: 3.02,
      price_3m_h: 3.67,
      price_3m_l: 3.02,
      price_6m_h: 3.94,
      price_6m_l: 3.02,
      price_52w_h: 4.74,
      price_52w_l: 3.02,
      price_pc_1d: -0.912,
      price_pc_1w: -3.55,
      price_pc_1m: -6.322,
      price_pc_6m: -17.259,
      price_pc_1y: -21.635,
      price_pc_ytd: -21.635,
    },
    2192: {
      id: "2192",
      c: 7.9,
      ma50: 11.616,
      ma100: 22.827,
      ma150: 27.346,
      ma200: null,
      maw30: 27.456,
      rs: 2.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 10.76,
      price_5d_L: 6.87,
      price_1m_h: 12.92,
      price_1m_l: 6.87,
      price_3m_h: 28.1,
      price_3m_l: 6.87,
      price_6m_h: 47.0,
      price_6m_l: 6.87,
      price_52w_h: 47.0,
      price_52w_l: 6.87,
      price_pc_1d: -2.829,
      price_pc_1w: -27.122,
      price_pc_1m: -31.542,
      price_pc_6m: -79.017,
      price_pc_1y: -74.516,
      price_pc_ytd: -74.516,
    },
    2193: {
      id: "2193",
      c: 0.3,
      ma50: 0.3,
      ma100: 0.33,
      ma150: 0.321,
      ma200: 0.316,
      maw30: 0.321,
      rs: 66.0,
      rsd1d: -4.0,
      rsd5d: -10.0,
      rsd10d: -3.0,
      price_5d_h: 0.315,
      price_5d_L: 0.3,
      price_1m_h: 0.32,
      price_1m_l: 0.27,
      price_3m_h: 0.345,
      price_3m_l: 0.27,
      price_6m_h: 0.42,
      price_6m_l: 0.265,
      price_52w_h: 0.445,
      price_52w_l: 0.255,
      price_pc_1d: -3.226,
      price_pc_1w: -6.25,
      price_pc_1m: -3.226,
      price_pc_6m: 7.143,
      price_pc_1y: 11.111,
      price_pc_ytd: 11.111,
    },
    2195: {
      id: "2195",
      c: 0.365,
      ma50: 0.318,
      ma100: 0.341,
      ma150: 0.356,
      ma200: 0.37,
      maw30: 0.353,
      rs: 73.0,
      rsd1d: 28.0,
      rsd5d: 21.0,
      rsd10d: 12.0,
      price_5d_h: 0.375,
      price_5d_L: 0.325,
      price_1m_h: 0.375,
      price_1m_l: 0.275,
      price_3m_h: 0.375,
      price_3m_l: 0.275,
      price_6m_h: 0.42,
      price_6m_l: 0.275,
      price_52w_h: 0.55,
      price_52w_l: 0.275,
      price_pc_1d: 8.955,
      price_pc_1w: 1.389,
      price_pc_1m: 21.667,
      price_pc_6m: -1.351,
      price_pc_1y: 10.606,
      price_pc_ytd: 10.606,
    },
    2196: {
      id: "2196",
      c: 38.3,
      ma50: 32.587,
      ma100: 34.404,
      ma150: 37.199,
      ma200: 43.242,
      maw30: 36.765,
      rs: 73.0,
      rsd1d: -9.0,
      rsd5d: 1.0,
      rsd10d: 28.0,
      price_5d_h: 41.25,
      price_5d_L: 35.3,
      price_1m_h: 41.95,
      price_1m_l: 25.75,
      price_3m_h: 41.95,
      price_3m_l: 25.75,
      price_6m_h: 42.7,
      price_6m_l: 25.75,
      price_52w_h: 82.0,
      price_52w_l: 25.75,
      price_pc_1d: -3.161,
      price_pc_1w: 0.525,
      price_pc_1m: 22.364,
      price_pc_6m: -4.37,
      price_pc_1y: 13.481,
      price_pc_ytd: 13.481,
    },
    2197: {
      id: "2197",
      c: 6.9,
      ma50: 9.032,
      ma100: 10.598,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 9.0,
      rsd1d: 2.0,
      rsd5d: -5.0,
      rsd10d: 3.0,
      price_5d_h: 8.38,
      price_5d_L: 6.75,
      price_1m_h: 9.38,
      price_1m_l: 5.6,
      price_3m_h: 13.58,
      price_3m_l: 5.6,
      price_6m_h: 14.8,
      price_6m_l: 5.6,
      price_52w_h: 14.8,
      price_52w_l: 5.6,
      price_pc_1d: -1.288,
      price_pc_1w: -15.337,
      price_pc_1m: -9.211,
      price_pc_6m: -46.841,
      price_pc_1y: -46.841,
      price_pc_ytd: -46.841,
    },
    2198: {
      id: "2198",
      c: 1.77,
      ma50: 2.115,
      ma100: 2.281,
      ma150: 2.432,
      ma200: 2.607,
      maw30: 2.424,
      rs: 14.0,
      rsd1d: 1.0,
      rsd5d: -3.0,
      rsd10d: 0.0,
      price_5d_h: 1.93,
      price_5d_L: 1.77,
      price_1m_h: 2.13,
      price_1m_l: 1.59,
      price_3m_h: 2.78,
      price_3m_l: 1.59,
      price_6m_h: 2.92,
      price_6m_l: 1.59,
      price_52w_h: 4.16,
      price_52w_l: 1.59,
      price_pc_1d: -1.667,
      price_pc_1w: -7.33,
      price_pc_1m: -14.078,
      price_pc_6m: -35.165,
      price_pc_1y: -51.374,
      price_pc_ytd: -51.374,
    },
    2199: {
      id: "2199",
      c: 4.76,
      ma50: 5.661,
      ma100: 5.881,
      ma150: 4.931,
      ma200: 4.396,
      maw30: 4.978,
      rs: 93.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: -1.0,
      price_5d_h: 5.1,
      price_5d_L: 4.75,
      price_1m_h: 5.7,
      price_1m_l: 4.54,
      price_3m_h: 8.0,
      price_3m_l: 4.54,
      price_6m_h: 8.0,
      price_6m_l: 2.61,
      price_52w_h: 8.0,
      price_52w_l: 2.17,
      price_pc_1d: -0.833,
      price_pc_1w: -2.857,
      price_pc_1m: -14.388,
      price_pc_6m: 72.464,
      price_pc_1y: 106.957,
      price_pc_ytd: 106.957,
    },
    2202: {
      id: "2202",
      c: 17.74,
      ma50: 18.748,
      ma100: 18.848,
      ma150: 19.376,
      ma200: 20.325,
      maw30: 19.444,
      rs: 39.0,
      rsd1d: 2.0,
      rsd5d: 7.0,
      rsd10d: 4.0,
      price_5d_h: 18.48,
      price_5d_L: 16.4,
      price_1m_h: 19.06,
      price_1m_l: 13.08,
      price_3m_h: 21.85,
      price_3m_l: 13.08,
      price_6m_h: 22.75,
      price_6m_l: 13.08,
      price_52w_h: 29.65,
      price_52w_l: 13.08,
      price_pc_1d: -1.004,
      price_pc_1w: 3.864,
      price_pc_1m: -4.212,
      price_pc_6m: -16.714,
      price_pc_1y: -39.142,
      price_pc_ytd: -39.142,
    },
    2205: {
      id: "2205",
      c: 3.33,
      ma50: 3.473,
      ma100: 3.545,
      ma150: 3.569,
      ma200: null,
      maw30: 3.58,
      rs: 63.0,
      rsd1d: 6.0,
      rsd5d: 5.0,
      rsd10d: 2.0,
      price_5d_h: 3.39,
      price_5d_L: 3.25,
      price_1m_h: 3.58,
      price_1m_l: 3.13,
      price_3m_h: 3.68,
      price_3m_l: 3.13,
      price_6m_h: 3.75,
      price_6m_l: 3.13,
      price_52w_h: 3.79,
      price_52w_l: 3.13,
      price_pc_1d: 1.524,
      price_pc_1w: 1.216,
      price_pc_1m: -5.666,
      price_pc_6m: -5.932,
      price_pc_1y: -8.516,
      price_pc_ytd: -8.516,
    },
    2207: {
      id: "2207",
      c: 4.9,
      ma50: 4.726,
      ma100: 4.82,
      ma150: 4.956,
      ma200: null,
      maw30: 4.995,
      rs: 61.0,
      rsd1d: 3.0,
      rsd5d: 9.0,
      rsd10d: -14.0,
      price_5d_h: 4.94,
      price_5d_L: 4.7,
      price_1m_h: 5.0,
      price_1m_l: 4.37,
      price_3m_h: 5.19,
      price_3m_l: 4.07,
      price_6m_h: 5.67,
      price_6m_l: 4.07,
      price_52w_h: 5.89,
      price_52w_l: 4.07,
      price_pc_1d: 0.204,
      price_pc_1w: 2.51,
      price_pc_1m: 11.617,
      price_pc_6m: -12.5,
      price_pc_1y: 0.0,
      price_pc_ytd: 0.0,
    },
    2208: {
      id: "2208",
      c: 11.82,
      ma50: 12.682,
      ma100: 14.502,
      ma150: 15.089,
      ma200: 14.547,
      maw30: 15.225,
      rs: 38.0,
      rsd1d: 1.0,
      rsd5d: 5.0,
      rsd10d: 6.0,
      price_5d_h: 12.16,
      price_5d_L: 10.38,
      price_1m_h: 13.56,
      price_1m_l: 10.12,
      price_3m_h: 16.0,
      price_3m_l: 10.12,
      price_6m_h: 19.82,
      price_6m_l: 10.12,
      price_52w_h: 19.82,
      price_52w_l: 10.12,
      price_pc_1d: -0.169,
      price_pc_1w: -1.99,
      price_pc_1m: -7.221,
      price_pc_6m: -27.927,
      price_pc_1y: -21.095,
      price_pc_ytd: -21.095,
    },
    2209: {
      id: "2209",
      c: 1.18,
      ma50: 1.301,
      ma100: 1.424,
      ma150: 1.51,
      ma200: null,
      maw30: 1.494,
      rs: 36.0,
      rsd1d: 0.0,
      rsd5d: 6.0,
      rsd10d: 15.0,
      price_5d_h: 1.24,
      price_5d_L: 1.12,
      price_1m_h: 1.3,
      price_1m_l: 1.05,
      price_3m_h: 1.61,
      price_3m_l: 1.05,
      price_6m_h: 2.02,
      price_6m_l: 1.05,
      price_52w_h: 4.97,
      price_52w_l: 1.05,
      price_pc_1d: 0.0,
      price_pc_1w: -2.479,
      price_pc_1m: -4.065,
      price_pc_6m: -18.621,
      price_pc_1y: -65.896,
      price_pc_ytd: -65.896,
    },
    2210: {
      id: "2210",
      c: 4.66,
      ma50: 4.784,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 65.0,
      rsd1d: -3.0,
      rsd5d: 16.0,
      rsd10d: 0.0,
      price_5d_h: 4.81,
      price_5d_L: 4.6,
      price_1m_h: 4.81,
      price_1m_l: 4.3,
      price_3m_h: 5.88,
      price_3m_l: 4.25,
      price_6m_h: 8.4,
      price_6m_l: 4.25,
      price_52w_h: 8.4,
      price_52w_l: 4.25,
      price_pc_1d: -3.119,
      price_pc_1w: 1.304,
      price_pc_1m: -3.719,
      price_pc_6m: -43.584,
      price_pc_1y: -43.584,
      price_pc_ytd: -43.584,
    },
    2211: {
      id: "2211",
      c: 0.13,
      ma50: 0.143,
      ma100: 0.15,
      ma150: 0.154,
      ma200: 0.162,
      maw30: 0.152,
      rs: 25.0,
      rsd1d: -2.0,
      rsd5d: -8.0,
      rsd10d: -6.0,
      price_5d_h: 0.144,
      price_5d_L: 0.127,
      price_1m_h: 0.152,
      price_1m_l: 0.127,
      price_3m_h: 0.164,
      price_3m_l: 0.125,
      price_6m_h: 0.18,
      price_6m_l: 0.125,
      price_52w_h: 0.33,
      price_52w_l: 0.125,
      price_pc_1d: -4.412,
      price_pc_1w: -10.345,
      price_pc_1m: -7.801,
      price_pc_6m: -10.959,
      price_pc_1y: -58.065,
      price_pc_ytd: -58.065,
    },
    2215: {
      id: "2215",
      c: 3.07,
      ma50: 3.069,
      ma100: 3.069,
      ma150: 3.156,
      ma200: null,
      maw30: 3.145,
      rs: 66.0,
      rsd1d: 0.0,
      rsd5d: 4.0,
      rsd10d: 0.0,
      price_5d_h: 3.12,
      price_5d_L: 3.02,
      price_1m_h: 3.12,
      price_1m_l: 3.01,
      price_3m_h: 3.18,
      price_3m_l: 2.98,
      price_6m_h: 3.5,
      price_6m_l: 2.87,
      price_52w_h: 3.82,
      price_52w_l: 2.87,
      price_pc_1d: -1.603,
      price_pc_1w: 0.327,
      price_pc_1m: 0.327,
      price_pc_6m: -9.172,
      price_pc_1y: 0.327,
      price_pc_ytd: 0.327,
    },
    2216: {
      id: "2216",
      c: 3.25,
      ma50: 4.624,
      ma100: 7.038,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 2.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -2.0,
      price_5d_h: 3.81,
      price_5d_L: 3.1,
      price_1m_h: 4.73,
      price_1m_l: 2.52,
      price_3m_h: 9.03,
      price_3m_l: 2.52,
      price_6m_h: 14.6,
      price_6m_l: 2.52,
      price_52w_h: 18.0,
      price_52w_l: 2.52,
      price_pc_1d: -2.108,
      price_pc_1w: -13.793,
      price_pc_1m: -19.951,
      price_pc_6m: -77.74,
      price_pc_1y: -78.333,
      price_pc_ytd: -78.333,
    },
    2217: {
      id: "2217",
      c: 2.66,
      ma50: 2.714,
      ma100: 2.946,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 35.0,
      rsd1d: -7.0,
      rsd5d: -3.0,
      rsd10d: 5.0,
      price_5d_h: 2.75,
      price_5d_L: 2.49,
      price_1m_h: 2.79,
      price_1m_l: 2.1,
      price_3m_h: 3.42,
      price_3m_l: 2.1,
      price_6m_h: 3.81,
      price_6m_l: 2.1,
      price_52w_h: 3.81,
      price_52w_l: 2.1,
      price_pc_1d: -2.206,
      price_pc_1w: 1.527,
      price_pc_1m: 4.724,
      price_pc_6m: -13.636,
      price_pc_1y: -13.636,
      price_pc_ytd: -13.636,
    },
    2219: {
      id: "2219",
      c: 3.76,
      ma50: 4.445,
      ma100: 5.589,
      ma150: 6.802,
      ma200: null,
      maw30: 6.645,
      rs: 7.0,
      rsd1d: 0.0,
      rsd5d: -2.0,
      rsd10d: 1.0,
      price_5d_h: 4.1,
      price_5d_L: 3.36,
      price_1m_h: 4.94,
      price_1m_l: 2.93,
      price_3m_h: 6.49,
      price_3m_l: 2.93,
      price_6m_h: 10.0,
      price_6m_l: 2.93,
      price_52w_h: 17.8,
      price_52w_l: 2.93,
      price_pc_1d: -1.312,
      price_pc_1w: -5.764,
      price_pc_1m: -20.339,
      price_pc_6m: -58.681,
      price_pc_1y: -73.333,
      price_pc_ytd: -73.333,
    },
    2221: {
      id: "2221",
      c: 0.62,
      ma50: 0.574,
      ma100: 0.662,
      ma150: 0.704,
      ma200: 0.67,
      maw30: 0.712,
      rs: 91.0,
      rsd1d: 0.0,
      rsd5d: 9.0,
      rsd10d: 20.0,
      price_5d_h: 0.75,
      price_5d_L: 0.475,
      price_1m_h: 0.75,
      price_1m_l: 0.415,
      price_3m_h: 0.75,
      price_3m_l: 0.415,
      price_6m_h: 1.38,
      price_6m_l: 0.415,
      price_52w_h: 1.38,
      price_52w_l: 0.231,
      price_pc_1d: 3.333,
      price_pc_1w: 29.167,
      price_pc_1m: 3.333,
      price_pc_6m: -21.519,
      price_pc_1y: 163.83,
      price_pc_ytd: 163.83,
    },
    2222: {
      id: "2222",
      c: 0.156,
      ma50: 0.162,
      ma100: 0.16,
      ma150: 0.17,
      ma200: 0.177,
      maw30: 0.17,
      rs: 63.0,
      rsd1d: -1.0,
      rsd5d: -20.0,
      rsd10d: 29.0,
      price_5d_h: 0.188,
      price_5d_L: 0.144,
      price_1m_h: 0.188,
      price_1m_l: 0.131,
      price_3m_h: 0.188,
      price_3m_l: 0.131,
      price_6m_h: 0.195,
      price_6m_l: 0.131,
      price_52w_h: 0.21,
      price_52w_l: 0.131,
      price_pc_1d: -1.266,
      price_pc_1w: -10.345,
      price_pc_1m: -5.455,
      price_pc_6m: -15.676,
      price_pc_1y: -14.286,
      price_pc_ytd: -14.286,
    },
    2223: {
      id: "2223",
      c: 0.5,
      ma50: 0.61,
      ma100: 0.633,
      ma150: 0.642,
      ma200: 0.656,
      maw30: 0.644,
      rs: 29.0,
      rsd1d: 6.0,
      rsd5d: -21.0,
      rsd10d: -11.0,
      price_5d_h: 0.58,
      price_5d_L: 0.455,
      price_1m_h: 0.65,
      price_1m_l: 0.455,
      price_3m_h: 0.7,
      price_3m_l: 0.455,
      price_6m_h: 0.8,
      price_6m_l: 0.455,
      price_52w_h: 0.87,
      price_52w_l: 0.455,
      price_pc_1d: 3.093,
      price_pc_1w: -13.793,
      price_pc_1m: -13.793,
      price_pc_6m: -20.635,
      price_pc_1y: -34.211,
      price_pc_ytd: -34.211,
    },
    2225: {
      id: "2225",
      c: 2.36,
      ma50: 2.388,
      ma100: 2.448,
      ma150: 2.56,
      ma200: 2.593,
      maw30: 2.555,
      rs: 69.0,
      rsd1d: -8.0,
      rsd5d: 13.0,
      rsd10d: 4.0,
      price_5d_h: 2.49,
      price_5d_L: 2.12,
      price_1m_h: 2.5,
      price_1m_l: 2.09,
      price_3m_h: 2.8,
      price_3m_l: 2.09,
      price_6m_h: 2.92,
      price_6m_l: 2.09,
      price_52w_h: 3.41,
      price_52w_l: 1.4,
      price_pc_1d: 0.0,
      price_pc_1w: 6.306,
      price_pc_1m: -2.479,
      price_pc_6m: -8.88,
      price_pc_1y: 15.122,
      price_pc_ytd: 15.122,
    },
    2227: {
      id: "2227",
      c: 0.143,
      ma50: 0.142,
      ma100: 0.159,
      ma150: 0.17,
      ma200: 0.185,
      maw30: 0.171,
      rs: 26.0,
      rsd1d: 1.0,
      rsd5d: -12.0,
      rsd10d: -6.0,
      price_5d_h: 0.144,
      price_5d_L: 0.124,
      price_1m_h: 0.148,
      price_1m_l: 0.119,
      price_3m_h: 0.173,
      price_3m_l: 0.119,
      price_6m_h: 0.195,
      price_6m_l: 0.11,
      price_52w_h: 0.55,
      price_52w_l: 0.11,
      price_pc_1d: -0.694,
      price_pc_1w: 2.143,
      price_pc_1m: 1.418,
      price_pc_6m: -9.494,
      price_pc_1y: -42.8,
      price_pc_ytd: -42.8,
    },
    2230: {
      id: "2230",
      c: 0.161,
      ma50: 0.156,
      ma100: 0.164,
      ma150: 0.173,
      ma200: 0.191,
      maw30: 0.172,
      rs: 54.0,
      rsd1d: 14.0,
      rsd5d: 13.0,
      rsd10d: 14.0,
      price_5d_h: 0.163,
      price_5d_L: 0.142,
      price_1m_h: 0.166,
      price_1m_l: 0.127,
      price_3m_h: 0.18,
      price_3m_l: 0.127,
      price_6m_h: 0.213,
      price_6m_l: 0.127,
      price_52w_h: 0.35,
      price_52w_l: 0.127,
      price_pc_1d: 3.205,
      price_pc_1w: 1.899,
      price_pc_1m: 3.871,
      price_pc_6m: -12.5,
      price_pc_1y: -1.227,
      price_pc_ytd: -1.227,
    },
    2231: {
      id: "2231",
      c: 2.14,
      ma50: 2.491,
      ma100: 2.528,
      ma150: 2.663,
      ma200: 2.768,
      maw30: 2.645,
      rs: 35.0,
      rsd1d: -3.0,
      rsd5d: -18.0,
      rsd10d: -23.0,
      price_5d_h: 2.39,
      price_5d_L: 2.1,
      price_1m_h: 2.6,
      price_1m_l: 1.37,
      price_3m_h: 2.8,
      price_3m_l: 1.37,
      price_6m_h: 3.03,
      price_6m_l: 1.37,
      price_52w_h: 3.33,
      price_52w_l: 1.37,
      price_pc_1d: -2.283,
      price_pc_1w: -9.705,
      price_pc_1m: -17.692,
      price_pc_6m: -23.571,
      price_pc_1y: -32.704,
      price_pc_ytd: -32.704,
    },
    2232: {
      id: "2232",
      c: 3.12,
      ma50: 2.578,
      ma100: 2.624,
      ma150: 2.67,
      ma200: 2.91,
      maw30: 2.658,
      rs: 76.0,
      rsd1d: 7.0,
      rsd5d: 13.0,
      rsd10d: 40.0,
      price_5d_h: 3.2,
      price_5d_L: 2.88,
      price_1m_h: 3.2,
      price_1m_l: 2.14,
      price_3m_h: 3.2,
      price_3m_l: 2.14,
      price_6m_h: 3.2,
      price_6m_l: 2.14,
      price_52w_h: 4.28,
      price_52w_l: 2.14,
      price_pc_1d: 3.654,
      price_pc_1w: 8.711,
      price_pc_1m: 18.631,
      price_pc_6m: 10.638,
      price_pc_1y: -23.153,
      price_pc_ytd: -23.153,
    },
    2233: {
      id: "2233",
      c: 1.25,
      ma50: 1.321,
      ma100: 1.336,
      ma150: 1.347,
      ma200: 1.306,
      maw30: 1.359,
      rs: 62.0,
      rsd1d: 0.0,
      rsd5d: -13.0,
      rsd10d: -9.0,
      price_5d_h: 1.3,
      price_5d_L: 1.23,
      price_1m_h: 1.37,
      price_1m_l: 1.13,
      price_3m_h: 1.45,
      price_3m_l: 1.13,
      price_6m_h: 1.5,
      price_6m_l: 1.13,
      price_52w_h: 1.5,
      price_52w_l: 1.11,
      price_pc_1d: -0.794,
      price_pc_1w: -3.101,
      price_pc_1m: -6.716,
      price_pc_6m: -13.793,
      price_pc_1y: -6.015,
      price_pc_ytd: -6.015,
    },
    2235: {
      id: "2235",
      c: 11.24,
      ma50: 14.874,
      ma100: 20.266,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 5.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: 1.0,
      price_5d_h: 12.0,
      price_5d_L: 10.52,
      price_1m_h: 15.0,
      price_1m_l: 10.52,
      price_3m_h: 24.0,
      price_3m_l: 10.52,
      price_6m_h: 31.7,
      price_6m_l: 10.52,
      price_52w_h: 31.7,
      price_52w_l: 10.52,
      price_pc_1d: -0.178,
      price_pc_1w: -3.103,
      price_pc_1m: -20.284,
      price_pc_6m: -63.148,
      price_pc_1y: -63.148,
      price_pc_ytd: -63.148,
    },
    2236: {
      id: "2236",
      c: 0.33,
      ma50: 0.357,
      ma100: 0.371,
      ma150: 0.382,
      ma200: 0.383,
      maw30: 0.383,
      rs: 41.0,
      rsd1d: 8.0,
      rsd5d: 7.0,
      rsd10d: 7.0,
      price_5d_h: 0.34,
      price_5d_L: 0.31,
      price_1m_h: 0.395,
      price_1m_l: 0.29,
      price_3m_h: 0.415,
      price_3m_l: 0.29,
      price_6m_h: 0.465,
      price_6m_l: 0.29,
      price_52w_h: 0.475,
      price_52w_l: 0.29,
      price_pc_1d: 3.125,
      price_pc_1w: 0.0,
      price_pc_1m: -17.5,
      price_pc_6m: -17.5,
      price_pc_1y: -25.0,
      price_pc_ytd: -25.0,
    },
    2238: {
      id: "2238",
      c: 6.54,
      ma50: 7.315,
      ma100: 7.608,
      ma150: 7.602,
      ma200: 7.45,
      maw30: 7.622,
      rs: 59.0,
      rsd1d: 4.0,
      rsd5d: 7.0,
      rsd10d: 14.0,
      price_5d_h: 6.59,
      price_5d_L: 6.07,
      price_1m_h: 7.94,
      price_1m_l: 5.5,
      price_3m_h: 8.57,
      price_3m_l: 5.5,
      price_6m_h: 8.75,
      price_6m_l: 5.5,
      price_52w_h: 9.25,
      price_52w_l: 5.5,
      price_pc_1d: 0.926,
      price_pc_1w: 1.082,
      price_pc_1m: -15.065,
      price_pc_6m: -4.942,
      price_pc_1y: -1.357,
      price_pc_ytd: -1.357,
    },
    2250: {
      id: "2250",
      c: 1.94,
      ma50: 1.879,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 38.0,
      rsd1d: 6.0,
      rsd5d: 15.0,
      rsd10d: 12.0,
      price_5d_h: 1.97,
      price_5d_L: 1.68,
      price_1m_h: 1.97,
      price_1m_l: 1.52,
      price_3m_h: 2.77,
      price_3m_l: 1.52,
      price_6m_h: 2.77,
      price_6m_l: 1.52,
      price_52w_h: 2.77,
      price_52w_l: 1.52,
      price_pc_1d: 2.105,
      price_pc_1w: 11.494,
      price_pc_1m: 2.105,
      price_pc_6m: -18.828,
      price_pc_1y: -18.828,
      price_pc_ytd: -18.828,
    },
    2251: {
      id: "2251",
      c: 17.68,
      ma50: 27.625,
      ma100: 38.719,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 6.0,
      rsd1d: -2.0,
      rsd5d: 0.0,
      rsd10d: 2.0,
      price_5d_h: 19.6,
      price_5d_L: 17.1,
      price_1m_h: 32.55,
      price_1m_l: 15.02,
      price_3m_h: 43.0,
      price_3m_l: 15.02,
      price_6m_h: 74.95,
      price_6m_l: 15.02,
      price_52w_h: 74.95,
      price_52w_l: 15.02,
      price_pc_1d: 0.569,
      price_pc_1w: -3.913,
      price_pc_1m: -41.067,
      price_pc_6m: -74.0,
      price_pc_1y: -74.0,
      price_pc_ytd: -74.0,
    },
    2252: {
      id: "2252",
      c: 27.2,
      ma50: 40.902,
      ma100: 49.092,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 7.0,
      rsd1d: 3.0,
      rsd5d: 3.0,
      rsd10d: 0.0,
      price_5d_h: 28.2,
      price_5d_L: 24.25,
      price_1m_h: 49.3,
      price_1m_l: 19.92,
      price_3m_h: 60.15,
      price_3m_l: 19.92,
      price_6m_h: 73.45,
      price_6m_l: 19.92,
      price_52w_h: 73.45,
      price_52w_l: 19.92,
      price_pc_1d: 5.426,
      price_pc_1w: -5.061,
      price_pc_1m: -43.975,
      price_pc_6m: -40.611,
      price_pc_1y: -40.611,
      price_pc_ytd: -40.611,
    },
    2255: {
      id: "2255",
      c: 3.9,
      ma50: 3.008,
      ma100: 2.874,
      ma150: 2.524,
      ma200: 2.078,
      maw30: 2.583,
      rs: 99.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: 1.0,
      price_5d_h: 4.03,
      price_5d_L: 3.31,
      price_1m_h: 4.03,
      price_1m_l: 2.01,
      price_3m_h: 4.03,
      price_3m_l: 2.01,
      price_6m_h: 4.03,
      price_6m_l: 1.3,
      price_52w_h: 4.03,
      price_52w_l: 0.5,
      price_pc_1d: -1.515,
      price_pc_1w: 14.706,
      price_pc_1m: 35.417,
      price_pc_6m: 88.406,
      price_pc_1y: 596.429,
      price_pc_ytd: 596.429,
    },
    2256: {
      id: "2256",
      c: 4.2,
      ma50: 5.596,
      ma100: 7.609,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 6.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: 0.0,
      price_5d_h: 4.58,
      price_5d_L: 4.2,
      price_1m_h: 5.4,
      price_1m_l: 3.99,
      price_3m_h: 9.1,
      price_3m_l: 3.99,
      price_6m_h: 12.54,
      price_6m_l: 3.99,
      price_52w_h: 12.54,
      price_52w_l: 3.99,
      price_pc_1d: -4.328,
      price_pc_1w: -8.297,
      price_pc_1m: -19.386,
      price_pc_6m: -66.129,
      price_pc_1y: -66.129,
      price_pc_ytd: -66.129,
    },
    2257: {
      id: "2257",
      c: 82.0,
      ma50: 80.711,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 57.0,
      rsd1d: -25.0,
      rsd5d: -22.0,
      rsd10d: -25.0,
      price_5d_h: 83.95,
      price_5d_L: 74.7,
      price_1m_h: 95.6,
      price_1m_l: 70.65,
      price_3m_h: 101.0,
      price_3m_l: 70.65,
      price_6m_h: 101.0,
      price_6m_l: 64.8,
      price_52w_h: 101.0,
      price_52w_l: 64.8,
      price_pc_1d: 0.0,
      price_pc_1w: 2.5,
      price_pc_1m: -8.99,
      price_pc_6m: 5.263,
      price_pc_1y: 5.263,
      price_pc_ytd: 5.263,
    },
    2260: {
      id: "2260",
      c: 1.22,
      ma50: 1.221,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 73.0,
      rsd1d: 1.0,
      rsd5d: -5.0,
      rsd10d: -2.0,
      price_5d_h: 1.25,
      price_5d_L: 1.19,
      price_1m_h: 1.29,
      price_1m_l: 1.15,
      price_3m_h: 1.48,
      price_3m_l: 1.08,
      price_6m_h: 1.48,
      price_6m_l: 1.08,
      price_52w_h: 1.48,
      price_52w_l: 1.08,
      price_pc_1d: 0.0,
      price_pc_1w: -2.4,
      price_pc_1m: 0.0,
      price_pc_6m: -0.813,
      price_pc_1y: -0.813,
      price_pc_ytd: -0.813,
    },
    2263: {
      id: "2263",
      c: 0.135,
      ma50: 0.134,
      ma100: 0.148,
      ma150: 0.162,
      ma200: 0.171,
      maw30: 0.161,
      rs: 27.0,
      rsd1d: 10.0,
      rsd5d: 14.0,
      rsd10d: 10.0,
      price_5d_h: 0.135,
      price_5d_L: 0.113,
      price_1m_h: 0.135,
      price_1m_l: 0.103,
      price_3m_h: 0.156,
      price_3m_l: 0.103,
      price_6m_h: 0.198,
      price_6m_l: 0.103,
      price_52w_h: 0.249,
      price_52w_l: 0.103,
      price_pc_1d: 9.756,
      price_pc_1w: 20.536,
      price_pc_1m: 0.0,
      price_pc_6m: -27.419,
      price_pc_1y: -43.038,
      price_pc_ytd: -43.038,
    },
    2265: {
      id: "2265",
      c: 0.8,
      ma50: 0.828,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 47.0,
      rsd1d: 6.0,
      rsd5d: 2.0,
      rsd10d: -10.0,
      price_5d_h: 0.8,
      price_5d_L: 0.73,
      price_1m_h: 0.83,
      price_1m_l: 0.7,
      price_3m_h: 0.99,
      price_3m_l: 0.7,
      price_6m_h: 1.11,
      price_6m_l: 0.7,
      price_52w_h: 1.11,
      price_52w_l: 0.7,
      price_pc_1d: 2.564,
      price_pc_1w: 3.896,
      price_pc_1m: 0.0,
      price_pc_6m: -12.088,
      price_pc_1y: -12.088,
      price_pc_ytd: -12.088,
    },
    2266: {
      id: "2266",
      c: 0.325,
      ma50: 0.324,
      ma100: 0.333,
      ma150: 0.373,
      ma200: 0.405,
      maw30: 0.37,
      rs: 36.0,
      rsd1d: 5.0,
      rsd5d: 4.0,
      rsd10d: 0.0,
      price_5d_h: 0.33,
      price_5d_L: 0.32,
      price_1m_h: 0.36,
      price_1m_l: 0.27,
      price_3m_h: 0.365,
      price_3m_l: 0.27,
      price_6m_h: 0.46,
      price_6m_l: 0.27,
      price_52w_h: 0.58,
      price_52w_l: 0.27,
      price_pc_1d: 1.563,
      price_pc_1w: 0.0,
      price_pc_1m: 1.563,
      price_pc_6m: -25.287,
      price_pc_1y: -39.815,
      price_pc_ytd: -39.815,
    },
    2269: {
      id: "2269",
      c: 65.05,
      ma50: 65.374,
      ma100: 80.695,
      ma150: 92.787,
      ma200: 101.668,
      maw30: 92.693,
      rs: 14.0,
      rsd1d: -1.0,
      rsd5d: -5.0,
      rsd10d: 2.0,
      price_5d_h: 70.05,
      price_5d_L: 61.7,
      price_1m_h: 71.5,
      price_1m_l: 40.3,
      price_3m_h: 94.65,
      price_3m_l: 40.3,
      price_6m_h: 124.8,
      price_6m_l: 40.3,
      price_52w_h: 148.0,
      price_52w_l: 40.3,
      price_pc_1d: -5.997,
      price_pc_1w: -6.268,
      price_pc_1m: 1.245,
      price_pc_6m: -48.577,
      price_pc_1y: -38.107,
      price_pc_ytd: -38.107,
    },
    2270: {
      id: "2270",
      c: 1.11,
      ma50: 1.099,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 76.0,
      rsd1d: 0.0,
      rsd5d: 4.0,
      rsd10d: -8.0,
      price_5d_h: 1.11,
      price_5d_L: 1.1,
      price_1m_h: 1.11,
      price_1m_l: 1.09,
      price_3m_h: 1.14,
      price_3m_l: 1.0,
      price_6m_h: 1.2,
      price_6m_l: 1.0,
      price_52w_h: 1.2,
      price_52w_l: 1.0,
      price_pc_1d: 0.909,
      price_pc_1w: 0.909,
      price_pc_1m: 0.909,
      price_pc_6m: 5.714,
      price_pc_1y: 5.714,
      price_pc_ytd: 5.714,
    },
    2273: {
      id: "2273",
      c: 29.5,
      ma50: 34.664,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 26.0,
      rsd1d: -20.0,
      rsd5d: -58.0,
      rsd10d: -67.0,
      price_5d_h: 31.45,
      price_5d_L: 28.0,
      price_1m_h: 44.2,
      price_1m_l: 14.86,
      price_3m_h: 49.8,
      price_3m_l: 14.86,
      price_6m_h: 49.8,
      price_6m_l: 14.86,
      price_52w_h: 49.8,
      price_52w_l: 14.86,
      price_pc_1d: -3.595,
      price_pc_1w: -6.349,
      price_pc_1m: -30.506,
      price_pc_6m: 1.724,
      price_pc_1y: 1.724,
      price_pc_ytd: 1.724,
    },
    2276: {
      id: "2276",
      c: 4.85,
      ma50: 4.474,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 84.0,
      rsd1d: -1.0,
      rsd5d: 5.0,
      rsd10d: 8.0,
      price_5d_h: 4.94,
      price_5d_L: 4.5,
      price_1m_h: 4.94,
      price_1m_l: 4.23,
      price_3m_h: 4.94,
      price_3m_l: 4.1,
      price_6m_h: 4.94,
      price_6m_l: 4.1,
      price_52w_h: 4.94,
      price_52w_l: 4.1,
      price_pc_1d: 0.622,
      price_pc_1w: 5.435,
      price_pc_1m: 8.744,
      price_pc_6m: 6.593,
      price_pc_1y: 6.593,
      price_pc_ytd: 6.593,
    },
    2279: {
      id: "2279",
      c: 14.24,
      ma50: 11.711,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 53.0,
      rsd1d: -2.0,
      rsd5d: 27.0,
      rsd10d: 35.0,
      price_5d_h: 14.76,
      price_5d_L: 11.62,
      price_1m_h: 14.76,
      price_1m_l: 6.89,
      price_3m_h: 16.16,
      price_3m_l: 6.89,
      price_6m_h: 19.0,
      price_6m_l: 6.89,
      price_52w_h: 19.0,
      price_52w_l: 6.89,
      price_pc_1d: -1.385,
      price_pc_1w: 16.721,
      price_pc_1m: 13.92,
      price_pc_6m: -14.217,
      price_pc_1y: -14.217,
      price_pc_ytd: -14.217,
    },
    2280: {
      id: "2280",
      c: 0.53,
      ma50: 0.654,
      ma100: 0.694,
      ma150: 0.739,
      ma200: 0.786,
      maw30: 0.744,
      rs: 18.0,
      rsd1d: -4.0,
      rsd5d: -9.0,
      rsd10d: -8.0,
      price_5d_h: 0.6,
      price_5d_L: 0.52,
      price_1m_h: 0.72,
      price_1m_l: 0.5,
      price_3m_h: 0.77,
      price_3m_l: 0.5,
      price_6m_h: 0.88,
      price_6m_l: 0.5,
      price_52w_h: 1.09,
      price_52w_l: 0.5,
      price_pc_1d: -7.018,
      price_pc_1w: -10.169,
      price_pc_1m: -22.059,
      price_pc_6m: -35.366,
      price_pc_1y: -48.544,
      price_pc_ytd: -48.544,
    },
    2281: {
      id: "2281",
      c: 0.9,
      ma50: 0.903,
      ma100: 0.978,
      ma150: 1.033,
      ma200: 1.068,
      maw30: 1.032,
      rs: 44.0,
      rsd1d: -2.0,
      rsd5d: 4.0,
      rsd10d: 6.0,
      price_5d_h: 0.93,
      price_5d_L: 0.83,
      price_1m_h: 0.93,
      price_1m_l: 0.83,
      price_3m_h: 1.05,
      price_3m_l: 0.83,
      price_6m_h: 1.21,
      price_6m_l: 0.83,
      price_52w_h: 1.3,
      price_52w_l: 0.83,
      price_pc_1d: -3.226,
      price_pc_1w: 0.0,
      price_pc_1m: 1.124,
      price_pc_6m: -19.643,
      price_pc_1y: -19.643,
      price_pc_ytd: -19.643,
    },
    2282: {
      id: "2282",
      c: 4.99,
      ma50: 5.103,
      ma100: 5.036,
      ma150: 5.444,
      ma200: 6.613,
      maw30: 5.371,
      rs: 33.0,
      rsd1d: 2.0,
      rsd5d: 8.0,
      rsd10d: 5.0,
      price_5d_h: 5.06,
      price_5d_L: 4.55,
      price_1m_h: 5.16,
      price_1m_l: 3.57,
      price_3m_h: 5.98,
      price_3m_l: 3.57,
      price_6m_h: 6.42,
      price_6m_l: 3.57,
      price_52w_h: 13.96,
      price_52w_l: 3.57,
      price_pc_1d: 0.201,
      price_pc_1w: 1.217,
      price_pc_1m: -0.598,
      price_pc_6m: 3.099,
      price_pc_1y: -63.577,
      price_pc_ytd: -63.577,
    },
    2283: {
      id: "2283",
      c: 2.38,
      ma50: 2.422,
      ma100: 2.512,
      ma150: 2.694,
      ma200: 2.766,
      maw30: 2.672,
      rs: 47.0,
      rsd1d: 5.0,
      rsd5d: 13.0,
      rsd10d: 15.0,
      price_5d_h: 2.47,
      price_5d_L: 2.25,
      price_1m_h: 2.5,
      price_1m_l: 2.05,
      price_3m_h: 2.62,
      price_3m_l: 2.05,
      price_6m_h: 3.08,
      price_6m_l: 2.05,
      price_52w_h: 3.59,
      price_52w_l: 2.05,
      price_pc_1d: 1.709,
      price_pc_1w: 5.778,
      price_pc_1m: 1.277,
      price_pc_6m: -19.322,
      price_pc_1y: -21.967,
      price_pc_ytd: -21.967,
    },
    2285: {
      id: "2285",
      c: 54.5,
      ma50: 59.622,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 72.0,
      rsd1d: 3.0,
      rsd5d: 2.0,
      rsd10d: -1.0,
      price_5d_h: 57.75,
      price_5d_L: 52.3,
      price_1m_h: 71.0,
      price_1m_l: 41.8,
      price_3m_h: 71.0,
      price_3m_l: 41.8,
      price_6m_h: 71.0,
      price_6m_l: 41.8,
      price_52w_h: 71.0,
      price_52w_l: 41.8,
      price_pc_1d: -0.274,
      price_pc_1w: -1.089,
      price_pc_1m: -19.019,
      price_pc_6m: -2.679,
      price_pc_1y: -2.679,
      price_pc_ytd: -2.679,
    },
    2288: {
      id: "2288",
      c: 0.52,
      ma50: 0.595,
      ma100: 0.629,
      ma150: 0.65,
      ma200: 0.661,
      maw30: 0.649,
      rs: 38.0,
      rsd1d: -17.0,
      rsd5d: 0.0,
      rsd10d: -35.0,
      price_5d_h: 0.64,
      price_5d_L: 0.52,
      price_1m_h: 0.64,
      price_1m_l: 0.52,
      price_3m_h: 0.67,
      price_3m_l: 0.52,
      price_6m_h: 0.72,
      price_6m_l: 0.52,
      price_52w_h: 0.79,
      price_52w_l: 0.52,
      price_pc_1d: -10.345,
      price_pc_1w: -1.887,
      price_pc_1m: -10.345,
      price_pc_6m: -23.529,
      price_pc_1y: -13.333,
      price_pc_ytd: -13.333,
    },
    2298: {
      id: "2298",
      c: 0.41,
      ma50: 0.462,
      ma100: 0.531,
      ma150: 0.58,
      ma200: 0.689,
      maw30: 0.575,
      rs: 13.0,
      rsd1d: -1.0,
      rsd5d: -3.0,
      rsd10d: -3.0,
      price_5d_h: 0.43,
      price_5d_L: 0.41,
      price_1m_h: 0.5,
      price_1m_l: 0.36,
      price_3m_h: 0.54,
      price_3m_l: 0.36,
      price_6m_h: 0.89,
      price_6m_l: 0.36,
      price_52w_h: 1.32,
      price_52w_l: 0.36,
      price_pc_1d: -4.651,
      price_pc_1w: -8.889,
      price_pc_1m: -12.766,
      price_pc_6m: -41.429,
      price_pc_1y: -65.833,
      price_pc_ytd: -65.833,
    },
    2308: {
      id: "2308",
      c: 1.15,
      ma50: 1.184,
      ma100: 1.219,
      ma150: 1.22,
      ma200: 1.239,
      maw30: 1.223,
      rs: 63.0,
      rsd1d: 0.0,
      rsd5d: 2.0,
      rsd10d: 10.0,
      price_5d_h: 1.18,
      price_5d_L: 1.09,
      price_1m_h: 1.21,
      price_1m_l: 0.96,
      price_3m_h: 1.29,
      price_3m_l: 0.96,
      price_6m_h: 1.39,
      price_6m_l: 0.96,
      price_52w_h: 1.46,
      price_52w_l: 0.96,
      price_pc_1d: 0.0,
      price_pc_1w: 0.877,
      price_pc_1m: -1.709,
      price_pc_6m: 1.77,
      price_pc_1y: -18.44,
      price_pc_ytd: -18.44,
    },
    2309: {
      id: "2309",
      c: 0.098,
      ma50: 0.118,
      ma100: 0.114,
      ma150: 0.122,
      ma200: 0.13,
      maw30: 0.121,
      rs: 34.0,
      rsd1d: 8.0,
      rsd5d: 17.0,
      rsd10d: -10.0,
      price_5d_h: 0.102,
      price_5d_L: 0.091,
      price_1m_h: 0.133,
      price_1m_l: 0.091,
      price_3m_h: 0.161,
      price_3m_l: 0.08,
      price_6m_h: 0.161,
      price_6m_l: 0.08,
      price_52w_h: 0.242,
      price_52w_l: 0.08,
      price_pc_1d: 0.0,
      price_pc_1w: 6.522,
      price_pc_1m: -21.6,
      price_pc_6m: -25.191,
      price_pc_1y: -49.744,
      price_pc_ytd: -49.744,
    },
    2310: {
      id: "2310",
      c: 0.075,
      ma50: 0.092,
      ma100: 0.089,
      ma150: 0.096,
      ma200: 0.094,
      maw30: 0.097,
      rs: 43.0,
      rsd1d: -17.0,
      rsd5d: -42.0,
      rsd10d: -18.0,
      price_5d_h: 0.099,
      price_5d_L: 0.075,
      price_1m_h: 0.109,
      price_1m_l: 0.072,
      price_3m_h: 0.157,
      price_3m_l: 0.072,
      price_6m_h: 0.157,
      price_6m_l: 0.07,
      price_52w_h: 0.157,
      price_52w_l: 0.07,
      price_pc_1d: -9.639,
      price_pc_1w: -23.469,
      price_pc_1m: -25.0,
      price_pc_6m: -41.406,
      price_pc_1y: -25.0,
      price_pc_ytd: -25.0,
    },
    2312: {
      id: "2312",
      c: 0.137,
      ma50: 0.154,
      ma100: 0.196,
      ma150: 0.238,
      ma200: 0.281,
      maw30: 0.241,
      rs: 4.0,
      rsd1d: 1.0,
      rsd5d: 2.0,
      rsd10d: 1.0,
      price_5d_h: 0.138,
      price_5d_L: 0.12,
      price_1m_h: 0.18,
      price_1m_l: 0.102,
      price_3m_h: 0.219,
      price_3m_l: 0.102,
      price_6m_h: 0.4,
      price_6m_l: 0.102,
      price_52w_h: 0.66,
      price_52w_l: 0.102,
      price_pc_1d: 9.6,
      price_pc_1w: 14.167,
      price_pc_1m: -17.964,
      price_pc_6m: -62.973,
      price_pc_1y: -77.541,
      price_pc_ytd: -77.541,
    },
    2313: {
      id: "2313",
      c: 104.8,
      ma50: 127.085,
      ma100: 140.467,
      ma150: 149.053,
      ma200: 156.923,
      maw30: 149.763,
      rs: 17.0,
      rsd1d: -2.0,
      rsd5d: -4.0,
      rsd10d: 1.0,
      price_5d_h: 115.0,
      price_5d_L: 104.2,
      price_1m_h: 133.2,
      price_1m_l: 84.95,
      price_3m_h: 158.7,
      price_3m_l: 84.95,
      price_6m_h: 175.5,
      price_6m_l: 84.95,
      price_52w_h: 207.6,
      price_52w_l: 84.95,
      price_pc_1d: -7.339,
      price_pc_1w: -8.311,
      price_pc_1m: -20.183,
      price_pc_6m: -36.791,
      price_pc_1y: -35.824,
      price_pc_ytd: -35.824,
    },
    2314: {
      id: "2314",
      c: 4.09,
      ma50: 4.978,
      ma100: 5.279,
      ma150: 5.65,
      ma200: 5.812,
      maw30: 5.666,
      rs: 22.0,
      rsd1d: 3.0,
      rsd5d: 4.0,
      rsd10d: -3.0,
      price_5d_h: 4.19,
      price_5d_L: 3.88,
      price_1m_h: 5.18,
      price_1m_l: 3.83,
      price_3m_h: 5.8,
      price_3m_l: 3.83,
      price_6m_h: 6.3,
      price_6m_l: 3.83,
      price_52w_h: 7.59,
      price_52w_l: 3.83,
      price_pc_1d: -0.244,
      price_pc_1w: -3.765,
      price_pc_1m: -18.526,
      price_pc_6m: -28.621,
      price_pc_1y: -44.049,
      price_pc_ytd: -44.049,
    },
    2317: {
      id: "2317",
      c: 0.61,
      ma50: 0.667,
      ma100: 0.709,
      ma150: 0.727,
      ma200: 0.735,
      maw30: 0.729,
      rs: 39.0,
      rsd1d: 2.0,
      rsd5d: -12.0,
      rsd10d: -1.0,
      price_5d_h: 0.64,
      price_5d_L: 0.6,
      price_1m_h: 0.69,
      price_1m_l: 0.6,
      price_3m_h: 0.75,
      price_3m_l: 0.6,
      price_6m_h: 0.87,
      price_6m_l: 0.6,
      price_52w_h: 0.89,
      price_52w_l: 0.6,
      price_pc_1d: 0.0,
      price_pc_1w: -7.576,
      price_pc_1m: -11.594,
      price_pc_6m: -16.438,
      price_pc_1y: -23.75,
      price_pc_ytd: -23.75,
    },
    2318: {
      id: "2318",
      c: 55.65,
      ma50: 60.066,
      ma100: 58.762,
      ma150: 58.458,
      ma200: 61.794,
      maw30: 58.652,
      rs: 50.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: 4.0,
      price_5d_h: 57.4,
      price_5d_L: 54.1,
      price_1m_h: 61.3,
      price_1m_l: 45.8,
      price_3m_h: 69.4,
      price_3m_l: 45.8,
      price_6m_h: 69.4,
      price_6m_l: 45.8,
      price_52w_h: 94.95,
      price_52w_l: 45.8,
      price_pc_1d: -1.33,
      price_pc_1w: -2.111,
      price_pc_1m: -7.864,
      price_pc_6m: 4.507,
      price_pc_1y: -40.608,
      price_pc_ytd: -40.608,
    },
    2319: {
      id: "2319",
      c: 42.2,
      ma50: 46.754,
      ma100: 46.18,
      ma150: 46.684,
      ma200: 46.157,
      maw30: 46.907,
      rs: 59.0,
      rsd1d: -6.0,
      rsd5d: -20.0,
      rsd10d: -16.0,
      price_5d_h: 46.25,
      price_5d_L: 41.3,
      price_1m_h: 51.3,
      price_1m_l: 39.85,
      price_3m_h: 51.3,
      price_3m_l: 39.85,
      price_6m_h: 51.3,
      price_6m_l: 39.85,
      price_52w_h: 51.3,
      price_52w_l: 39.85,
      price_pc_1d: -3.872,
      price_pc_1w: -9.345,
      price_pc_1m: -16.847,
      price_pc_6m: -15.852,
      price_pc_1y: -6.118,
      price_pc_ytd: -6.118,
    },
    2322: {
      id: "2322",
      c: 0.84,
      ma50: 0.885,
      ma100: 0.908,
      ma150: 0.913,
      ma200: 0.938,
      maw30: 0.911,
      rs: 48.0,
      rsd1d: 3.0,
      rsd5d: -1.0,
      rsd10d: -8.0,
      price_5d_h: 0.85,
      price_5d_L: 0.81,
      price_1m_h: 0.91,
      price_1m_l: 0.8,
      price_3m_h: 0.97,
      price_3m_l: 0.8,
      price_6m_h: 1.04,
      price_6m_l: 0.79,
      price_52w_h: 1.06,
      price_52w_l: 0.79,
      price_pc_1d: 0.0,
      price_pc_1w: -1.176,
      price_pc_1m: -5.618,
      price_pc_6m: -8.696,
      price_pc_1y: -19.231,
      price_pc_ytd: -19.231,
    },
    2326: {
      id: "2326",
      c: 0.013,
      ma50: 0.013,
      ma100: 0.014,
      ma150: 0.015,
      ma200: 0.017,
      maw30: 0.016,
      rs: 35.0,
      rsd1d: -1.0,
      rsd5d: 1.0,
      rsd10d: 11.0,
      price_5d_h: 0.013,
      price_5d_L: 0.012,
      price_1m_h: 0.013,
      price_1m_l: 0.01,
      price_3m_h: 0.016,
      price_3m_l: 0.01,
      price_6m_h: 0.021,
      price_6m_l: 0.01,
      price_52w_h: 0.031,
      price_52w_l: 0.01,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: 8.333,
      price_pc_6m: -31.579,
      price_pc_1y: -7.143,
      price_pc_ytd: -7.143,
    },
    2328: {
      id: "2328",
      c: 8.02,
      ma50: 7.75,
      ma100: 7.263,
      ma150: 7.264,
      ma200: 7.164,
      maw30: 7.273,
      rs: 90.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: 1.0,
      price_5d_h: 8.2,
      price_5d_L: 7.3,
      price_1m_h: 8.3,
      price_1m_l: 6.76,
      price_3m_h: 8.65,
      price_3m_l: 6.43,
      price_6m_h: 8.65,
      price_6m_l: 6.05,
      price_52w_h: 8.65,
      price_52w_l: 6.0,
      price_pc_1d: -1.232,
      price_pc_1w: 2.953,
      price_pc_1m: -2.788,
      price_pc_6m: 6.225,
      price_pc_1y: 20.783,
      price_pc_ytd: 20.783,
    },
    2329: {
      id: "2329",
      c: 0.222,
      ma50: 0.273,
      ma100: 0.31,
      ma150: 0.32,
      ma200: 0.332,
      maw30: 0.319,
      rs: 19.0,
      rsd1d: 2.0,
      rsd5d: 3.0,
      rsd10d: 3.0,
      price_5d_h: 0.245,
      price_5d_L: 0.214,
      price_1m_h: 0.27,
      price_1m_l: 0.195,
      price_3m_h: 0.38,
      price_3m_l: 0.195,
      price_6m_h: 0.395,
      price_6m_l: 0.195,
      price_52w_h: 0.57,
      price_52w_l: 0.195,
      price_pc_1d: -1.333,
      price_pc_1w: -9.388,
      price_pc_1m: -20.714,
      price_pc_6m: -26.0,
      price_pc_1y: -43.077,
      price_pc_ytd: -43.077,
    },
    2331: {
      id: "2331",
      c: 67.6,
      ma50: 72.546,
      ma100: 79.568,
      ma150: 84.172,
      ma200: 84.886,
      maw30: 84.023,
      rs: 51.0,
      rsd1d: 1.0,
      rsd5d: -15.0,
      rsd10d: 3.0,
      price_5d_h: 69.4,
      price_5d_L: 61.4,
      price_1m_h: 78.45,
      price_1m_l: 48.6,
      price_3m_h: 88.8,
      price_3m_l: 48.6,
      price_6m_h: 98.0,
      price_6m_l: 48.6,
      price_52w_h: 108.2,
      price_52w_l: 48.6,
      price_pc_1d: -0.368,
      price_pc_1w: -2.312,
      price_pc_1m: -12.943,
      price_pc_6m: -25.014,
      price_pc_1y: 30.881,
      price_pc_ytd: 30.881,
    },
    2333: {
      id: "2333",
      c: 12.56,
      ma50: 17.09,
      ma100: 23.42,
      ma150: 26.364,
      ma200: 27.167,
      maw30: 26.311,
      rs: 7.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: 0.0,
      price_5d_h: 13.62,
      price_5d_L: 11.42,
      price_1m_h: 16.62,
      price_1m_l: 10.42,
      price_3m_h: 27.4,
      price_3m_l: 10.42,
      price_6m_h: 37.8,
      price_6m_l: 10.42,
      price_52w_h: 39.0,
      price_52w_l: 10.42,
      price_pc_1d: -6.686,
      price_pc_1w: -2.937,
      price_pc_1m: -22.755,
      price_pc_6m: -56.237,
      price_pc_1y: -47.338,
      price_pc_ytd: -47.338,
    },
    2337: {
      id: "2337",
      c: 12.42,
      ma50: 12.584,
      ma100: 12.833,
      ma150: 11.741,
      ma200: 11.412,
      maw30: 11.82,
      rs: 86.0,
      rsd1d: -1.0,
      rsd5d: 6.0,
      rsd10d: -1.0,
      price_5d_h: 12.8,
      price_5d_L: 11.5,
      price_1m_h: 12.8,
      price_1m_l: 10.6,
      price_3m_h: 14.5,
      price_3m_l: 10.6,
      price_6m_h: 14.84,
      price_6m_l: 8.66,
      price_52w_h: 16.16,
      price_52w_l: 7.35,
      price_pc_1d: -0.957,
      price_pc_1w: 0.976,
      price_pc_1m: 1.637,
      price_pc_6m: 32.692,
      price_pc_1y: 53.333,
      price_pc_ytd: 53.333,
    },
    2338: {
      id: "2338",
      c: 12.38,
      ma50: 13.282,
      ma100: 14.117,
      ma150: 15.0,
      ma200: 15.644,
      maw30: 14.904,
      rs: 28.0,
      rsd1d: 3.0,
      rsd5d: -2.0,
      rsd10d: 3.0,
      price_5d_h: 13.02,
      price_5d_L: 11.84,
      price_1m_h: 13.58,
      price_1m_l: 10.02,
      price_3m_h: 16.64,
      price_3m_l: 10.02,
      price_6m_h: 16.98,
      price_6m_l: 10.02,
      price_52w_h: 20.75,
      price_52w_l: 10.02,
      price_pc_1d: -0.482,
      price_pc_1w: -4.769,
      price_pc_1m: -6.212,
      price_pc_6m: -23.486,
      price_pc_1y: -35.183,
      price_pc_ytd: -35.183,
    },
    2339: {
      id: "2339",
      c: 0.53,
      ma50: 0.559,
      ma100: 0.666,
      ma150: 0.713,
      ma200: 0.692,
      maw30: 0.727,
      rs: 29.0,
      rsd1d: 6.0,
      rsd5d: 5.0,
      rsd10d: -3.0,
      price_5d_h: 0.57,
      price_5d_L: 0.495,
      price_1m_h: 0.59,
      price_1m_l: 0.465,
      price_3m_h: 0.74,
      price_3m_l: 0.465,
      price_6m_h: 1.2,
      price_6m_l: 0.465,
      price_52w_h: 1.2,
      price_52w_l: 0.45,
      price_pc_1d: 1.923,
      price_pc_1w: 0.0,
      price_pc_1m: -5.357,
      price_pc_6m: -45.361,
      price_pc_1y: 1.923,
      price_pc_ytd: 1.923,
    },
    2341: {
      id: "2341",
      c: 1.9,
      ma50: 1.883,
      ma100: 1.784,
      ma150: 1.798,
      ma200: 1.892,
      maw30: 1.796,
      rs: 81.0,
      rsd1d: 3.0,
      rsd5d: 1.0,
      rsd10d: -1.0,
      price_5d_h: 1.96,
      price_5d_L: 1.82,
      price_1m_h: 1.96,
      price_1m_l: 1.7,
      price_3m_h: 2.03,
      price_3m_l: 1.66,
      price_6m_h: 2.03,
      price_6m_l: 1.55,
      price_52w_h: 2.52,
      price_52w_l: 1.55,
      price_pc_1d: 2.703,
      price_pc_1w: 2.703,
      price_pc_1m: -1.554,
      price_pc_6m: 1.604,
      price_pc_1y: 15.152,
      price_pc_ytd: 15.152,
    },
    2342: {
      id: "2342",
      c: 1.35,
      ma50: 1.67,
      ma100: 1.763,
      ma150: 1.854,
      ma200: 1.862,
      maw30: 1.866,
      rs: 22.0,
      rsd1d: 0.0,
      rsd5d: -7.0,
      rsd10d: -2.0,
      price_5d_h: 1.48,
      price_5d_L: 1.35,
      price_1m_h: 1.8,
      price_1m_l: 1.2,
      price_3m_h: 2.08,
      price_3m_l: 1.2,
      price_6m_h: 2.33,
      price_6m_l: 1.2,
      price_52w_h: 2.33,
      price_52w_l: 1.2,
      price_pc_1d: -2.878,
      price_pc_1w: -9.396,
      price_pc_1m: -28.947,
      price_pc_6m: -32.161,
      price_pc_1y: -33.824,
      price_pc_ytd: -33.824,
    },
    2343: {
      id: "2343",
      c: 4.25,
      ma50: 3.855,
      ma100: 3.416,
      ma150: 3.544,
      ma200: 3.48,
      maw30: 3.512,
      rs: 94.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: 0.0,
      price_5d_h: 4.42,
      price_5d_L: 4.01,
      price_1m_h: 4.48,
      price_1m_l: 3.61,
      price_3m_h: 4.48,
      price_3m_l: 2.77,
      price_6m_h: 4.48,
      price_6m_l: 2.6,
      price_52w_h: 4.55,
      price_52w_l: 2.09,
      price_pc_1d: -0.235,
      price_pc_1w: -1.848,
      price_pc_1m: 2.657,
      price_pc_6m: 17.403,
      price_pc_1y: 96.759,
      price_pc_ytd: 96.759,
    },
    2346: {
      id: "2346",
      c: 0.82,
      ma50: 1.314,
      ma100: 1.567,
      ma150: 1.927,
      ma200: 1.744,
      maw30: 1.953,
      rs: 65.0,
      rsd1d: 6.0,
      rsd5d: -26.0,
      rsd10d: -21.0,
      price_5d_h: 0.98,
      price_5d_L: 0.7,
      price_1m_h: 1.42,
      price_1m_l: 0.7,
      price_3m_h: 1.96,
      price_3m_l: 0.7,
      price_6m_h: 3.2,
      price_6m_l: 0.7,
      price_52w_h: 4.95,
      price_52w_l: 0.34,
      price_pc_1d: 2.5,
      price_pc_1w: -13.684,
      price_pc_1m: -33.871,
      price_pc_6m: -67.46,
      price_pc_1y: 105.0,
      price_pc_ytd: 105.0,
    },
    2348: {
      id: "2348",
      c: 1.3,
      ma50: 1.353,
      ma100: 1.505,
      ma150: 1.552,
      ma200: 1.606,
      maw30: 1.559,
      rs: 33.0,
      rsd1d: 0.0,
      rsd5d: -4.0,
      rsd10d: 4.0,
      price_5d_h: 1.47,
      price_5d_L: 1.23,
      price_1m_h: 1.47,
      price_1m_l: 1.11,
      price_3m_h: 1.69,
      price_3m_l: 1.11,
      price_6m_h: 1.76,
      price_6m_l: 1.11,
      price_52w_h: 1.93,
      price_52w_l: 1.11,
      price_pc_1d: -0.763,
      price_pc_1w: 1.563,
      price_pc_1m: -2.985,
      price_pc_6m: -22.619,
      price_pc_1y: -13.333,
      price_pc_ytd: -13.333,
    },
    2355: {
      id: "2355",
      c: 3.71,
      ma50: 3.961,
      ma100: 3.886,
      ma150: 3.879,
      ma200: 3.95,
      maw30: 3.901,
      rs: 63.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: 12.0,
      price_5d_h: 3.77,
      price_5d_L: 3.58,
      price_1m_h: 3.95,
      price_1m_l: 3.18,
      price_3m_h: 4.46,
      price_3m_l: 3.18,
      price_6m_h: 4.46,
      price_6m_l: 3.18,
      price_52w_h: 4.7,
      price_52w_l: 3.18,
      price_pc_1d: 0.0,
      price_pc_1w: 0.27,
      price_pc_1m: -2.625,
      price_pc_6m: -3.886,
      price_pc_1y: -2.368,
      price_pc_ytd: -2.368,
    },
    2356: {
      id: "2356",
      c: 6.96,
      ma50: 7.209,
      ma100: 7.092,
      ma150: 7.214,
      ma200: 7.461,
      maw30: 7.239,
      rs: 63.0,
      rsd1d: 5.0,
      rsd5d: 4.0,
      rsd10d: 2.0,
      price_5d_h: 6.99,
      price_5d_L: 6.75,
      price_1m_h: 7.16,
      price_1m_l: 6.33,
      price_3m_h: 7.94,
      price_3m_l: 6.33,
      price_6m_h: 8.26,
      price_6m_l: 6.33,
      price_52w_h: 11.26,
      price_52w_l: 6.33,
      price_pc_1d: 0.724,
      price_pc_1w: 0.578,
      price_pc_1m: -1.556,
      price_pc_6m: -2.384,
      price_pc_1y: -21.798,
      price_pc_ytd: -21.798,
    },
    2357: {
      id: "2357",
      c: 4.35,
      ma50: 4.542,
      ma100: 4.934,
      ma150: 5.082,
      ma200: 5.166,
      maw30: 5.039,
      rs: 42.0,
      rsd1d: -1.0,
      rsd5d: 3.0,
      rsd10d: 3.0,
      price_5d_h: 4.49,
      price_5d_L: 4.12,
      price_1m_h: 4.95,
      price_1m_l: 3.51,
      price_3m_h: 5.72,
      price_3m_l: 3.51,
      price_6m_h: 5.72,
      price_6m_l: 3.51,
      price_52w_h: 6.82,
      price_52w_l: 3.51,
      price_pc_1d: -2.247,
      price_pc_1w: 0.0,
      price_pc_1m: -10.124,
      price_pc_6m: -11.585,
      price_pc_1y: -16.826,
      price_pc_ytd: -16.826,
    },
    2358: {
      id: "2358",
      c: 0.084,
      ma50: 0.092,
      ma100: 0.092,
      ma150: 0.094,
      ma200: 0.096,
      maw30: 0.094,
      rs: 45.0,
      rsd1d: -4.0,
      rsd5d: -1.0,
      rsd10d: -27.0,
      price_5d_h: 0.091,
      price_5d_L: 0.072,
      price_1m_h: 0.095,
      price_1m_l: 0.069,
      price_3m_h: 0.127,
      price_3m_l: 0.069,
      price_6m_h: 0.127,
      price_6m_l: 0.069,
      price_52w_h: 0.127,
      price_52w_l: 0.069,
      price_pc_1d: -3.448,
      price_pc_1w: -4.545,
      price_pc_1m: -8.696,
      price_pc_6m: -14.286,
      price_pc_1y: -22.222,
      price_pc_ytd: -22.222,
    },
    2359: {
      id: "2359",
      c: 124.5,
      ma50: 110.277,
      ma100: 128.652,
      ma150: 141.048,
      ma200: 148.301,
      maw30: 141.748,
      rs: 42.0,
      rsd1d: -3.0,
      rsd5d: -16.0,
      rsd10d: 6.0,
      price_5d_h: 131.2,
      price_5d_L: 119.0,
      price_1m_h: 137.7,
      price_1m_l: 82.3,
      price_3m_h: 139.5,
      price_3m_l: 82.3,
      price_6m_h: 189.8,
      price_6m_l: 82.3,
      price_52w_h: 196.9,
      price_52w_l: 82.3,
      price_pc_1d: -1.347,
      price_pc_1w: -4.304,
      price_pc_1m: 12.365,
      price_pc_6m: -31.631,
      price_pc_1y: -8.118,
      price_pc_ytd: -8.118,
    },
    2360: {
      id: "2360",
      c: 1.31,
      ma50: 1.526,
      ma100: 1.612,
      ma150: 1.671,
      ma200: 1.757,
      maw30: 1.678,
      rs: 25.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: -4.0,
      price_5d_h: 1.35,
      price_5d_L: 1.3,
      price_1m_h: 1.57,
      price_1m_l: 1.16,
      price_3m_h: 1.74,
      price_3m_l: 1.16,
      price_6m_h: 1.83,
      price_6m_l: 1.16,
      price_52w_h: 2.56,
      price_52w_l: 1.16,
      price_pc_1d: -2.963,
      price_pc_1w: -2.963,
      price_pc_1m: -12.667,
      price_pc_6m: -22.024,
      price_pc_1y: -31.771,
      price_pc_ytd: -31.771,
    },
    2362: {
      id: "2362",
      c: 1.14,
      ma50: 1.302,
      ma100: 1.244,
      ma150: 1.248,
      ma200: 1.226,
      maw30: 1.259,
      rs: 46.0,
      rsd1d: -4.0,
      rsd5d: -35.0,
      rsd10d: -35.0,
      price_5d_h: 1.25,
      price_5d_L: 1.14,
      price_1m_h: 1.49,
      price_1m_l: 1.01,
      price_3m_h: 1.49,
      price_3m_l: 1.01,
      price_6m_h: 1.49,
      price_6m_l: 0.98,
      price_52w_h: 2.0,
      price_52w_l: 0.94,
      price_pc_1d: -2.564,
      price_pc_1w: -8.065,
      price_pc_1m: -17.986,
      price_pc_6m: -10.236,
      price_pc_1y: -12.977,
      price_pc_ytd: -12.977,
    },
    2368: {
      id: "2368",
      c: 4.45,
      ma50: 4.591,
      ma100: 4.626,
      ma150: 4.662,
      ma200: 4.788,
      maw30: 4.673,
      rs: 61.0,
      rsd1d: 4.0,
      rsd5d: 4.0,
      rsd10d: 2.0,
      price_5d_h: 4.44,
      price_5d_L: 4.33,
      price_1m_h: 4.75,
      price_1m_l: 4.15,
      price_3m_h: 4.87,
      price_3m_l: 4.15,
      price_6m_h: 5.05,
      price_6m_l: 4.15,
      price_52w_h: 5.92,
      price_52w_l: 4.15,
      price_pc_1d: 0.907,
      price_pc_1w: 1.136,
      price_pc_1m: -5.319,
      price_pc_6m: -6.904,
      price_pc_1y: -7.676,
      price_pc_ytd: -7.676,
    },
    2369: {
      id: "2369",
      c: 0.191,
      ma50: 0.27,
      ma100: 0.332,
      ma150: 0.342,
      ma200: 0.331,
      maw30: 0.346,
      rs: 9.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: 1.0,
      price_5d_h: 0.222,
      price_5d_L: 0.19,
      price_1m_h: 0.28,
      price_1m_l: 0.155,
      price_3m_h: 0.395,
      price_3m_l: 0.155,
      price_6m_h: 0.49,
      price_6m_l: 0.155,
      price_52w_h: 0.49,
      price_52w_l: 0.155,
      price_pc_1d: -4.5,
      price_pc_1w: -11.163,
      price_pc_1m: -30.545,
      price_pc_6m: -43.824,
      price_pc_1y: -57.581,
      price_pc_ytd: -57.581,
    },
    2371: {
      id: "2371",
      c: 0.043,
      ma50: 0.058,
      ma100: 0.074,
      ma150: 0.086,
      ma200: 0.094,
      maw30: 0.086,
      rs: 6.0,
      rsd1d: 2.0,
      rsd5d: 1.0,
      rsd10d: 1.0,
      price_5d_h: 0.047,
      price_5d_L: 0.04,
      price_1m_h: 0.055,
      price_1m_l: 0.038,
      price_3m_h: 0.092,
      price_3m_l: 0.038,
      price_6m_h: 0.123,
      price_6m_l: 0.038,
      price_52w_h: 0.157,
      price_52w_l: 0.038,
      price_pc_1d: 2.381,
      price_pc_1w: -8.511,
      price_pc_1m: -14.0,
      price_pc_6m: -60.909,
      price_pc_1y: -59.813,
      price_pc_ytd: -59.813,
    },
    2377: {
      id: "2377",
      c: 1.32,
      ma50: 1.325,
      ma100: 1.374,
      ma150: 1.41,
      ma200: 1.515,
      maw30: 1.389,
      rs: 45.0,
      rsd1d: 4.0,
      rsd5d: 1.0,
      rsd10d: 7.0,
      price_5d_h: 1.32,
      price_5d_L: 1.22,
      price_1m_h: 1.34,
      price_1m_l: 1.11,
      price_3m_h: 1.62,
      price_3m_l: 1.11,
      price_6m_h: 1.68,
      price_6m_l: 1.11,
      price_52w_h: 2.33,
      price_52w_l: 1.05,
      price_pc_1d: 1.538,
      price_pc_1w: 3.125,
      price_pc_1m: 0.0,
      price_pc_6m: 0.0,
      price_pc_1y: -5.036,
      price_pc_ytd: -5.036,
    },
    2378: {
      id: "2378",
      c: 115.1,
      ma50: 121.23,
      ma100: 130.308,
      ma150: 138.995,
      ma200: 142.312,
      maw30: 139.28,
      rs: 34.0,
      rsd1d: 3.0,
      rsd5d: 4.0,
      rsd10d: 4.0,
      price_5d_h: 117.8,
      price_5d_L: 109.2,
      price_1m_h: 120.0,
      price_1m_l: 102.1,
      price_3m_h: 144.0,
      price_3m_l: 102.1,
      price_6m_h: 160.5,
      price_6m_l: 102.1,
      price_52w_h: 174.5,
      price_52w_l: 102.1,
      price_pc_1d: -0.346,
      price_pc_1w: 1.499,
      price_pc_1m: -1.286,
      price_pc_6m: -23.318,
      price_pc_1y: -31.284,
      price_pc_ytd: -31.284,
    },
    2380: {
      id: "2380",
      c: 4.17,
      ma50: 4.113,
      ma100: 4.245,
      ma150: 4.013,
      ma200: 3.473,
      maw30: 4.073,
      rs: 94.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: -1.0,
      price_5d_h: 4.28,
      price_5d_L: 3.8,
      price_1m_h: 4.78,
      price_1m_l: 3.31,
      price_3m_h: 5.5,
      price_3m_l: 3.31,
      price_6m_h: 5.67,
      price_6m_l: 3.25,
      price_52w_h: 5.67,
      price_52w_l: 1.62,
      price_pc_1d: -2.113,
      price_pc_1w: 3.474,
      price_pc_1m: -5.442,
      price_pc_6m: 0.969,
      price_pc_1y: 131.667,
      price_pc_ytd: 131.667,
    },
    2381: {
      id: "2381",
      c: 0.101,
      ma50: 0.101,
      ma100: 0.107,
      ma150: 0.107,
      ma200: 0.111,
      maw30: 0.107,
      rs: 56.0,
      rsd1d: 9.0,
      rsd5d: 13.0,
      rsd10d: 8.0,
      price_5d_h: 0.103,
      price_5d_L: 0.09,
      price_1m_h: 0.119,
      price_1m_l: 0.075,
      price_3m_h: 0.126,
      price_3m_l: 0.075,
      price_6m_h: 0.138,
      price_6m_l: 0.075,
      price_52w_h: 0.173,
      price_52w_l: 0.075,
      price_pc_1d: 2.02,
      price_pc_1w: 6.316,
      price_pc_1m: 1.0,
      price_pc_6m: 2.02,
      price_pc_1y: -4.717,
      price_pc_ytd: -4.717,
    },
    2382: {
      id: "2382",
      c: 126.1,
      ma50: 171.684,
      ma100: 201.826,
      ma150: 206.252,
      ma200: 211.54,
      maw30: 207.36,
      rs: 12.0,
      rsd1d: 2.0,
      rsd5d: 1.0,
      rsd10d: -6.0,
      price_5d_h: 131.6,
      price_5d_L: 118.1,
      price_1m_h: 189.2,
      price_1m_l: 113.9,
      price_3m_h: 255.0,
      price_3m_l: 113.9,
      price_6m_h: 258.0,
      price_6m_l: 113.9,
      price_52w_h: 259.4,
      price_52w_l: 113.9,
      price_pc_1d: -1.33,
      price_pc_1w: -2.55,
      price_pc_1m: -32.458,
      price_pc_6m: -38.428,
      price_pc_1y: -31.018,
      price_pc_ytd: -31.018,
    },
    2383: {
      id: "2383",
      c: 0.8,
      ma50: 0.877,
      ma100: 0.86,
      ma150: 0.72,
      ma200: 0.659,
      maw30: 0.728,
      rs: 94.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: -1.0,
      price_5d_h: 0.83,
      price_5d_L: 0.74,
      price_1m_h: 0.93,
      price_1m_l: 0.69,
      price_3m_h: 1.01,
      price_3m_l: 0.69,
      price_6m_h: 1.03,
      price_6m_l: 0.395,
      price_52w_h: 1.03,
      price_52w_l: 0.345,
      price_pc_1d: 0.0,
      price_pc_1w: 1.266,
      price_pc_1m: -13.043,
      price_pc_6m: 95.122,
      price_pc_1y: 86.047,
      price_pc_ytd: 86.047,
    },
    2386: {
      id: "2386",
      c: 4.0,
      ma50: 3.744,
      ma100: 3.794,
      ma150: 3.884,
      ma200: 4.076,
      maw30: 3.877,
      rs: 68.0,
      rsd1d: 5.0,
      rsd5d: 1.0,
      rsd10d: 23.0,
      price_5d_h: 4.04,
      price_5d_L: 3.85,
      price_1m_h: 4.05,
      price_1m_l: 3.15,
      price_3m_h: 4.05,
      price_3m_l: 3.15,
      price_6m_h: 4.56,
      price_6m_l: 3.15,
      price_52w_h: 5.25,
      price_52w_l: 3.15,
      price_pc_1d: 1.523,
      price_pc_1w: 1.781,
      price_pc_1m: 7.239,
      price_pc_6m: 1.01,
      price_pc_1y: -7.834,
      price_pc_ytd: -7.834,
    },
    2388: {
      id: "2388",
      c: 29.7,
      ma50: 29.399,
      ma100: 27.386,
      ma150: 26.283,
      ma200: 26.136,
      maw30: 26.34,
      rs: 89.0,
      rsd1d: 0.0,
      rsd5d: 2.0,
      rsd10d: 1.0,
      price_5d_h: 30.35,
      price_5d_L: 28.15,
      price_1m_h: 30.35,
      price_1m_l: 26.1,
      price_3m_h: 32.75,
      price_3m_l: 25.55,
      price_6m_h: 32.75,
      price_6m_l: 23.1,
      price_52w_h: 32.75,
      price_52w_l: 22.2,
      price_pc_1d: -0.669,
      price_pc_1w: 3.665,
      price_pc_1m: 5.694,
      price_pc_6m: 26.383,
      price_pc_1y: 10.204,
      price_pc_ytd: 10.204,
    },
    2389: {
      id: "2389",
      c: 0.081,
      ma50: 0.089,
      ma100: 0.09,
      ma150: 0.096,
      ma200: 0.101,
      maw30: 0.096,
      rs: 39.0,
      rsd1d: 1.0,
      rsd5d: -12.0,
      rsd10d: -7.0,
      price_5d_h: 0.088,
      price_5d_L: 0.081,
      price_1m_h: 0.095,
      price_1m_l: 0.08,
      price_3m_h: 0.097,
      price_3m_l: 0.08,
      price_6m_h: 0.111,
      price_6m_l: 0.08,
      price_52w_h: 0.145,
      price_52w_l: 0.08,
      price_pc_1d: 0.0,
      price_pc_1w: -7.955,
      price_pc_1m: -10.0,
      price_pc_6m: -23.585,
      price_pc_1y: -23.585,
      price_pc_ytd: -23.585,
    },
    2393: {
      id: "2393",
      c: 1.03,
      ma50: 1.103,
      ma100: 1.125,
      ma150: 1.106,
      ma200: 1.11,
      maw30: 1.105,
      rs: 59.0,
      rsd1d: -5.0,
      rsd5d: -2.0,
      rsd10d: -11.0,
      price_5d_h: 1.17,
      price_5d_L: 1.03,
      price_1m_h: 1.2,
      price_1m_l: 1.03,
      price_3m_h: 1.28,
      price_3m_l: 1.0,
      price_6m_h: 1.29,
      price_6m_l: 0.97,
      price_52w_h: 1.29,
      price_52w_l: 0.97,
      price_pc_1d: -5.505,
      price_pc_1w: -3.738,
      price_pc_1m: -5.505,
      price_pc_6m: -1.905,
      price_pc_1y: -10.435,
      price_pc_ytd: -10.435,
    },
    2399: {
      id: "2399",
      c: 0.58,
      ma50: 0.569,
      ma100: 0.582,
      ma150: 0.606,
      ma200: 0.623,
      maw30: 0.599,
      rs: 34.0,
      rsd1d: 4.0,
      rsd5d: -4.0,
      rsd10d: -7.0,
      price_5d_h: 0.62,
      price_5d_L: 0.57,
      price_1m_h: 0.67,
      price_1m_l: 0.52,
      price_3m_h: 0.67,
      price_3m_l: 0.52,
      price_6m_h: 0.69,
      price_6m_l: 0.52,
      price_52w_h: 6.58,
      price_52w_l: 0.52,
      price_pc_1d: 0.0,
      price_pc_1w: -3.333,
      price_pc_1m: 3.571,
      price_pc_6m: -9.375,
      price_pc_1y: -86.091,
      price_pc_ytd: -86.091,
    },
    2400: {
      id: "2400",
      c: 24.3,
      ma50: 26.467,
      ma100: 34.632,
      ma150: 38.144,
      ma200: 42.066,
      maw30: 38.408,
      rs: 10.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: -1.0,
      price_5d_h: 27.55,
      price_5d_L: 23.5,
      price_1m_h: 28.95,
      price_1m_l: 14.2,
      price_3m_h: 39.7,
      price_3m_l: 14.2,
      price_6m_h: 56.5,
      price_6m_l: 14.2,
      price_52w_h: 72.4,
      price_52w_l: 14.2,
      price_pc_1d: -4.331,
      price_pc_1w: -7.429,
      price_pc_1m: -3.38,
      price_pc_6m: -43.945,
      price_pc_1y: -57.739,
      price_pc_ytd: -57.739,
    },
    2448: {
      id: "2448",
      c: 4.95,
      ma50: 5.333,
      ma100: 5.443,
      ma150: 5.405,
      ma200: 5.004,
      maw30: 5.456,
      rs: 90.0,
      rsd1d: -1.0,
      rsd5d: -2.0,
      rsd10d: -3.0,
      price_5d_h: 5.29,
      price_5d_L: 4.77,
      price_1m_h: 5.91,
      price_1m_l: 4.77,
      price_3m_h: 6.02,
      price_3m_l: 4.5,
      price_6m_h: 6.49,
      price_6m_l: 4.5,
      price_52w_h: 6.49,
      price_52w_l: 2.21,
      price_pc_1d: -1.0,
      price_pc_1w: -2.174,
      price_pc_1m: -12.078,
      price_pc_6m: -16.807,
      price_pc_1y: 99.597,
      price_pc_ytd: 99.597,
    },
    2500: {
      id: "2500",
      c: 16.2,
      ma50: 21.774,
      ma100: 26.742,
      ma150: 30.862,
      ma200: 37.098,
      maw30: 31.433,
      rs: 4.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 17.0,
      price_5d_L: 13.54,
      price_1m_h: 23.75,
      price_1m_l: 12.62,
      price_3m_h: 30.9,
      price_3m_l: 12.62,
      price_6m_h: 43.6,
      price_6m_l: 12.62,
      price_52w_h: 75.0,
      price_52w_l: 12.62,
      price_pc_1d: -1.699,
      price_pc_1w: -5.152,
      price_pc_1m: -25.173,
      price_pc_6m: -59.701,
      price_pc_1y: -75.096,
      price_pc_ytd: -75.096,
    },
    2518: {
      id: "2518",
      c: 61.55,
      ma50: 57.433,
      ma100: 61.284,
      ma150: 69.818,
      ma200: 78.772,
      maw30: 69.837,
      rs: 26.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: 4.0,
      price_5d_h: 64.1,
      price_5d_L: 57.55,
      price_1m_h: 66.75,
      price_1m_l: 39.8,
      price_3m_h: 73.25,
      price_3m_l: 39.8,
      price_6m_h: 101.0,
      price_6m_l: 39.8,
      price_52w_h: 190.3,
      price_52w_l: 39.8,
      price_pc_1d: 0.0,
      price_pc_1w: 1.652,
      price_pc_1m: 9.911,
      price_pc_6m: -30.452,
      price_pc_1y: -66.181,
      price_pc_ytd: -66.181,
    },
    2528: {
      id: "2528",
      c: 0.65,
      ma50: 0.879,
      ma100: 0.966,
      ma150: 1.081,
      ma200: 1.066,
      maw30: 1.08,
      rs: 14.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: -6.0,
      price_5d_h: 0.66,
      price_5d_L: 0.63,
      price_1m_h: 1.05,
      price_1m_l: 0.5,
      price_3m_h: 1.1,
      price_3m_l: 0.5,
      price_6m_h: 1.49,
      price_6m_l: 0.5,
      price_52w_h: 1.49,
      price_52w_l: 0.5,
      price_pc_1d: -1.515,
      price_pc_1w: 0.0,
      price_pc_1m: -40.367,
      price_pc_6m: -53.571,
      price_pc_1y: -32.292,
      price_pc_ytd: -32.292,
    },
    2552: {
      id: "2552",
      c: 3.86,
      ma50: 3.551,
      ma100: 3.712,
      ma150: 3.953,
      ma200: 4.033,
      maw30: 3.981,
      rs: 58.0,
      rsd1d: 17.0,
      rsd5d: 21.0,
      rsd10d: 21.0,
      price_5d_h: 3.89,
      price_5d_L: 3.37,
      price_1m_h: 3.89,
      price_1m_l: 2.4,
      price_3m_h: 4.02,
      price_3m_l: 2.4,
      price_6m_h: 4.76,
      price_6m_l: 2.4,
      price_52w_h: 5.38,
      price_52w_l: 2.4,
      price_pc_1d: 7.222,
      price_pc_1w: 4.324,
      price_pc_1m: 1.579,
      price_pc_6m: -17.697,
      price_pc_1y: -18.047,
      price_pc_ytd: -18.047,
    },
    2588: {
      id: "2588",
      c: 61.95,
      ma50: 64.135,
      ma100: 62.569,
      ma150: 63.173,
      ma200: 63.1,
      maw30: 63.672,
      rs: 71.0,
      rsd1d: 0.0,
      rsd5d: 5.0,
      rsd10d: 13.0,
      price_5d_h: 63.6,
      price_5d_L: 58.5,
      price_1m_h: 68.4,
      price_1m_l: 47.75,
      price_3m_h: 76.9,
      price_3m_l: 47.75,
      price_6m_h: 76.9,
      price_6m_l: 47.75,
      price_52w_h: 76.9,
      price_52w_l: 47.75,
      price_pc_1d: -0.562,
      price_pc_1w: 0.732,
      price_pc_1m: -8.561,
      price_pc_6m: -4.985,
      price_pc_1y: -17.013,
      price_pc_ytd: -17.013,
    },
    2599: {
      id: "2599",
      c: 0.71,
      ma50: 0.922,
      ma100: 1.279,
      ma150: 2.593,
      ma200: 3.328,
      maw30: 2.503,
      rs: 4.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: -2.0,
      price_5d_h: 0.77,
      price_5d_L: 0.65,
      price_1m_h: 0.91,
      price_1m_l: 0.63,
      price_3m_h: 1.52,
      price_3m_l: 0.63,
      price_6m_h: 5.4,
      price_6m_l: 0.63,
      price_52w_h: 6.5,
      price_52w_l: 0.63,
      price_pc_1d: -4.054,
      price_pc_1w: -4.054,
      price_pc_1m: -19.318,
      price_pc_6m: -85.996,
      price_pc_1y: -88.067,
      price_pc_ytd: -88.067,
    },
    2600: {
      id: "2600",
      c: 4.6,
      ma50: 4.882,
      ma100: 4.542,
      ma150: 4.91,
      ma200: 4.826,
      maw30: 4.912,
      rs: 83.0,
      rsd1d: -2.0,
      rsd5d: -4.0,
      rsd10d: 3.0,
      price_5d_h: 5.0,
      price_5d_L: 4.58,
      price_1m_h: 6.02,
      price_1m_l: 3.82,
      price_3m_h: 6.02,
      price_3m_l: 3.82,
      price_6m_h: 6.2,
      price_6m_l: 3.68,
      price_52w_h: 7.49,
      price_52w_l: 3.25,
      price_pc_1d: -2.335,
      price_pc_1w: -6.122,
      price_pc_1m: -14.972,
      price_pc_6m: -21.902,
      price_pc_1y: 39.818,
      price_pc_ytd: 39.818,
    },
    2601: {
      id: "2601",
      c: 19.12,
      ma50: 22.167,
      ma100: 22.499,
      ma150: 22.895,
      ma200: 23.012,
      maw30: 23.149,
      rs: 37.0,
      rsd1d: 2.0,
      rsd5d: -4.0,
      rsd10d: -8.0,
      price_5d_h: 20.25,
      price_5d_L: 18.42,
      price_1m_h: 22.1,
      price_1m_l: 17.7,
      price_3m_h: 25.9,
      price_3m_l: 17.7,
      price_6m_h: 26.2,
      price_6m_l: 17.7,
      price_52w_h: 31.5,
      price_52w_l: 17.7,
      price_pc_1d: -0.313,
      price_pc_1w: -5.112,
      price_pc_1m: -12.092,
      price_pc_6m: -17.408,
      price_pc_1y: -39.969,
      price_pc_ytd: -39.969,
    },
    2607: {
      id: "2607",
      c: 15.1,
      ma50: 14.872,
      ma100: 14.577,
      ma150: 14.782,
      ma200: 15.129,
      maw30: 14.799,
      rs: 71.0,
      rsd1d: -6.0,
      rsd5d: -13.0,
      rsd10d: -16.0,
      price_5d_h: 16.36,
      price_5d_L: 14.56,
      price_1m_h: 17.8,
      price_1m_l: 11.9,
      price_3m_h: 17.8,
      price_3m_l: 11.9,
      price_6m_h: 17.8,
      price_6m_l: 11.9,
      price_52w_h: 19.02,
      price_52w_l: 11.9,
      price_pc_1d: -1.307,
      price_pc_1w: -6.211,
      price_pc_1m: 7.244,
      price_pc_6m: -0.132,
      price_pc_1y: -1.821,
      price_pc_ytd: -1.821,
    },
    2608: {
      id: "2608",
      c: 0.37,
      ma50: 0.38,
      ma100: 0.381,
      ma150: 0.416,
      ma200: 0.589,
      maw30: 0.414,
      rs: 16.0,
      rsd1d: -8.0,
      rsd5d: -1.0,
      rsd10d: 0.0,
      price_5d_h: 0.49,
      price_5d_L: 0.3,
      price_1m_h: 0.49,
      price_1m_l: 0.295,
      price_3m_h: 0.49,
      price_3m_l: 0.295,
      price_6m_h: 0.5,
      price_6m_l: 0.05,
      price_52w_h: 1.4,
      price_52w_l: 0.05,
      price_pc_1d: 4.225,
      price_pc_1w: 23.333,
      price_pc_1m: -11.905,
      price_pc_6m: -19.565,
      price_pc_1y: -68.644,
      price_pc_ytd: -68.644,
    },
    2611: {
      id: "2611",
      c: 10.58,
      ma50: 11.411,
      ma100: 11.243,
      ma150: 11.19,
      ma200: 11.02,
      maw30: 11.282,
      rs: 59.0,
      rsd1d: 1.0,
      rsd5d: -2.0,
      rsd10d: -10.0,
      price_5d_h: 10.66,
      price_5d_L: 10.14,
      price_1m_h: 11.88,
      price_1m_l: 9.66,
      price_3m_h: 12.56,
      price_3m_l: 9.66,
      price_6m_h: 12.56,
      price_6m_l: 9.66,
      price_52w_h: 12.56,
      price_52w_l: 9.61,
      price_pc_1d: 0.38,
      price_pc_1w: 0.762,
      price_pc_1m: -8.16,
      price_pc_6m: -5.704,
      price_pc_1y: -6.702,
      price_pc_ytd: -6.702,
    },
    2616: {
      id: "2616",
      c: 5.04,
      ma50: 6.009,
      ma100: 7.491,
      ma150: 8.737,
      ma200: 10.413,
      maw30: 8.674,
      rs: 8.0,
      rsd1d: -2.0,
      rsd5d: -2.0,
      rsd10d: 1.0,
      price_5d_h: 5.89,
      price_5d_L: 5.02,
      price_1m_h: 6.9,
      price_1m_l: 4.17,
      price_3m_h: 8.62,
      price_3m_l: 4.17,
      price_6m_h: 11.68,
      price_6m_l: 4.17,
      price_52w_h: 19.12,
      price_52w_l: 4.17,
      price_pc_1d: -9.839,
      price_pc_1w: -12.348,
      price_pc_1m: -21.981,
      price_pc_6m: -53.247,
      price_pc_1y: -48.832,
      price_pc_ytd: -48.832,
    },
    2618: {
      id: "2618",
      c: 19.34,
      ma50: 23.594,
      ma100: 25.574,
      ma150: 27.672,
      ma200: 29.466,
      maw30: 27.71,
      rs: 13.0,
      rsd1d: 1.0,
      rsd5d: -8.0,
      rsd10d: -13.0,
      price_5d_h: 20.8,
      price_5d_L: 18.24,
      price_1m_h: 26.15,
      price_1m_l: 17.72,
      price_3m_h: 27.7,
      price_3m_l: 17.72,
      price_6m_h: 33.8,
      price_6m_l: 17.72,
      price_52w_h: 48.15,
      price_52w_l: 17.72,
      price_pc_1d: -1.827,
      price_pc_1w: -15.913,
      price_pc_1m: -21.7,
      price_pc_6m: -40.309,
      price_pc_1y: -53.621,
      price_pc_ytd: -53.621,
    },
    2623: {
      id: "2623",
      c: 0.092,
      ma50: 0.11,
      ma100: 0.121,
      ma150: 0.13,
      ma200: 0.147,
      maw30: 0.131,
      rs: 15.0,
      rsd1d: 2.0,
      rsd5d: -14.0,
      rsd10d: -8.0,
      price_5d_h: 0.109,
      price_5d_L: 0.082,
      price_1m_h: 0.11,
      price_1m_l: 0.082,
      price_3m_h: 0.131,
      price_3m_l: 0.082,
      price_6m_h: 0.178,
      price_6m_l: 0.082,
      price_52w_h: 0.315,
      price_52w_l: 0.082,
      price_pc_1d: 0.0,
      price_pc_1w: -13.208,
      price_pc_1m: -16.364,
      price_pc_6m: -41.401,
      price_pc_1y: -23.333,
      price_pc_ytd: -23.333,
    },
    2628: {
      id: "2628",
      c: 12.04,
      ma50: 13.075,
      ma100: 13.192,
      ma150: 13.236,
      ma200: 13.434,
      maw30: 13.308,
      rs: 49.0,
      rsd1d: 3.0,
      rsd5d: -1.0,
      rsd10d: -1.0,
      price_5d_h: 12.2,
      price_5d_L: 11.68,
      price_1m_h: 13.12,
      price_1m_l: 10.86,
      price_3m_h: 15.04,
      price_3m_l: 10.86,
      price_6m_h: 15.04,
      price_6m_l: 10.86,
      price_52w_h: 16.64,
      price_52w_l: 10.86,
      price_pc_1d: -0.166,
      price_pc_1w: -1.794,
      price_pc_1m: -7.099,
      price_pc_6m: -5.79,
      price_pc_1y: -25.403,
      price_pc_ytd: -25.403,
    },
    2633: {
      id: "2633",
      c: 0.65,
      ma50: 0.752,
      ma100: 0.655,
      ma150: 0.643,
      ma200: 0.654,
      maw30: 0.642,
      rs: 61.0,
      rsd1d: -3.0,
      rsd5d: -20.0,
      rsd10d: -31.0,
      price_5d_h: 0.73,
      price_5d_L: 0.64,
      price_1m_h: 0.83,
      price_1m_l: 0.64,
      price_3m_h: 0.91,
      price_3m_l: 0.61,
      price_6m_h: 0.91,
      price_6m_l: 0.435,
      price_52w_h: 0.99,
      price_52w_l: 0.435,
      price_pc_1d: -2.985,
      price_pc_1w: -9.722,
      price_pc_1m: -19.753,
      price_pc_6m: 0.0,
      price_pc_1y: -33.673,
      price_pc_ytd: -33.673,
    },
    2638: {
      id: "2638",
      c: 7.65,
      ma50: 7.807,
      ma100: 7.748,
      ma150: 7.73,
      ma200: 7.766,
      maw30: 7.734,
      rs: 73.0,
      rsd1d: 1.0,
      rsd5d: -4.0,
      rsd10d: -7.0,
      price_5d_h: 7.9,
      price_5d_L: 7.65,
      price_1m_h: 7.9,
      price_1m_l: 7.65,
      price_3m_h: 7.94,
      price_3m_l: 7.65,
      price_6m_h: 7.94,
      price_6m_l: 7.58,
      price_52w_h: 7.99,
      price_52w_l: 7.5,
      price_pc_1d: -0.907,
      price_pc_1w: -2.795,
      price_pc_1m: -0.778,
      price_pc_6m: -1.035,
      price_pc_1y: -0.649,
      price_pc_ytd: -0.649,
    },
    2660: {
      id: "2660",
      c: 2.13,
      ma50: 1.387,
      ma100: 1.316,
      ma150: 1.281,
      ma200: 1.297,
      maw30: 1.267,
      rs: 96.0,
      rsd1d: 0.0,
      rsd5d: 3.0,
      rsd10d: 10.0,
      price_5d_h: 2.18,
      price_5d_L: 1.54,
      price_1m_h: 2.18,
      price_1m_l: 1.07,
      price_3m_h: 2.18,
      price_3m_l: 1.07,
      price_6m_h: 2.18,
      price_6m_l: 1.07,
      price_52w_h: 2.18,
      price_52w_l: 1.07,
      price_pc_1d: 2.404,
      price_pc_1w: 26.036,
      price_pc_1m: 61.364,
      price_pc_6m: 88.496,
      price_pc_1y: 51.064,
      price_pc_ytd: 51.064,
    },
    2663: {
      id: "2663",
      c: 0.36,
      ma50: 0.356,
      ma100: 0.358,
      ma150: 0.353,
      ma200: 0.364,
      maw30: 0.352,
      rs: 75.0,
      rsd1d: 5.0,
      rsd5d: -5.0,
      rsd10d: -11.0,
      price_5d_h: 0.36,
      price_5d_L: 0.35,
      price_1m_h: 0.375,
      price_1m_l: 0.345,
      price_3m_h: 0.38,
      price_3m_l: 0.345,
      price_6m_h: 0.395,
      price_6m_l: 0.33,
      price_52w_h: 0.45,
      price_52w_l: 0.295,
      price_pc_1d: 2.857,
      price_pc_1w: 1.408,
      price_pc_1m: 2.857,
      price_pc_6m: 5.882,
      price_pc_1y: 18.033,
      price_pc_ytd: 18.033,
    },
    2666: {
      id: "2666",
      c: 5.16,
      ma50: 5.402,
      ma100: 5.466,
      ma150: 5.641,
      ma200: 5.915,
      maw30: 5.661,
      rs: 41.0,
      rsd1d: -4.0,
      rsd5d: 2.0,
      rsd10d: -3.0,
      price_5d_h: 5.26,
      price_5d_L: 4.86,
      price_1m_h: 5.58,
      price_1m_l: 4.2,
      price_3m_h: 5.9,
      price_3m_l: 4.2,
      price_6m_h: 6.18,
      price_6m_l: 4.2,
      price_52w_h: 8.19,
      price_52w_l: 4.2,
      price_pc_1d: -1.901,
      price_pc_1w: 1.775,
      price_pc_1m: -6.522,
      price_pc_6m: -15.41,
      price_pc_1y: -19.122,
      price_pc_ytd: -19.122,
    },
    2669: {
      id: "2669",
      c: 9.32,
      ma50: 8.626,
      ma100: 8.191,
      ma150: 7.664,
      ma200: 7.675,
      maw30: 7.727,
      rs: 91.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: 1.0,
      price_5d_h: 9.84,
      price_5d_L: 8.66,
      price_1m_h: 9.84,
      price_1m_l: 6.15,
      price_3m_h: 9.84,
      price_3m_l: 6.15,
      price_6m_h: 9.84,
      price_6m_l: 6.15,
      price_52w_h: 9.84,
      price_52w_l: 5.56,
      price_pc_1d: -2.101,
      price_pc_1w: 4.837,
      price_pc_1m: 10.296,
      price_pc_6m: 44.272,
      price_pc_1y: 28.198,
      price_pc_ytd: 28.198,
    },
    2678: {
      id: "2678",
      c: 9.73,
      ma50: 10.086,
      ma100: 10.356,
      ma150: 10.77,
      ma200: 11.033,
      maw30: 10.765,
      rs: 51.0,
      rsd1d: -1.0,
      rsd5d: -14.0,
      rsd10d: 3.0,
      price_5d_h: 10.64,
      price_5d_L: 9.65,
      price_1m_h: 10.94,
      price_1m_l: 8.1,
      price_3m_h: 11.7,
      price_3m_l: 8.1,
      price_6m_h: 12.8,
      price_6m_l: 8.1,
      price_52w_h: 13.7,
      price_52w_l: 8.1,
      price_pc_1d: -1.218,
      price_pc_1w: -7.51,
      price_pc_1m: 0.413,
      price_pc_6m: -13.741,
      price_pc_1y: -14.046,
      price_pc_ytd: -14.046,
    },
    2680: {
      id: "2680",
      c: 0.3,
      ma50: 0.387,
      ma100: 0.461,
      ma150: 0.544,
      ma200: 0.61,
      maw30: 0.546,
      rs: 7.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: -1.0,
      price_5d_h: 0.34,
      price_5d_L: 0.28,
      price_1m_h: 0.385,
      price_1m_l: 0.26,
      price_3m_h: 0.54,
      price_3m_l: 0.26,
      price_6m_h: 0.75,
      price_6m_l: 0.26,
      price_52w_h: 1.08,
      price_52w_l: 0.26,
      price_pc_1d: -3.226,
      price_pc_1w: 1.695,
      price_pc_1m: -22.078,
      price_pc_6m: -57.143,
      price_pc_1y: -67.033,
      price_pc_ytd: -67.033,
    },
    2682: {
      id: "2682",
      c: 0.164,
      ma50: 0.15,
      ma100: 0.155,
      ma150: 0.159,
      ma200: 0.163,
      maw30: 0.157,
      rs: 82.0,
      rsd1d: -1.0,
      rsd5d: -6.0,
      rsd10d: 15.0,
      price_5d_h: 0.176,
      price_5d_L: 0.164,
      price_1m_h: 0.176,
      price_1m_l: 0.125,
      price_3m_h: 0.176,
      price_3m_l: 0.125,
      price_6m_h: 0.18,
      price_6m_l: 0.125,
      price_52w_h: 0.228,
      price_52w_l: 0.123,
      price_pc_1d: 0.0,
      price_pc_1w: -6.818,
      price_pc_1m: 17.143,
      price_pc_6m: 1.863,
      price_pc_1y: 27.132,
      price_pc_ytd: 27.132,
    },
    2686: {
      id: "2686",
      c: 1.26,
      ma50: 1.487,
      ma100: 1.455,
      ma150: 1.638,
      ma200: 1.531,
      maw30: 1.637,
      rs: 42.0,
      rsd1d: -25.0,
      rsd5d: -25.0,
      rsd10d: -19.0,
      price_5d_h: 1.48,
      price_5d_L: 1.24,
      price_1m_h: 1.66,
      price_1m_l: 1.1,
      price_3m_h: 1.84,
      price_3m_l: 1.1,
      price_6m_h: 3.05,
      price_6m_l: 1.1,
      price_52w_h: 3.05,
      price_52w_l: 1.0,
      price_pc_1d: -12.5,
      price_pc_1w: -10.0,
      price_pc_1m: -15.436,
      price_pc_6m: -49.194,
      price_pc_1y: -5.263,
      price_pc_ytd: -5.263,
    },
    2688: {
      id: "2688",
      c: 117.5,
      ma50: 116.41,
      ma100: 129.304,
      ma150: 130.813,
      ma200: 137.669,
      maw30: 130.105,
      rs: 44.0,
      rsd1d: 0.0,
      rsd5d: -14.0,
      rsd10d: 17.0,
      price_5d_h: 122.9,
      price_5d_L: 116.5,
      price_1m_h: 125.4,
      price_1m_l: 91.0,
      price_3m_h: 151.7,
      price_3m_l: 91.0,
      price_6m_h: 159.0,
      price_6m_l: 91.0,
      price_52w_h: 178.8,
      price_52w_l: 91.0,
      price_pc_1d: -1.838,
      price_pc_1w: -6.3,
      price_pc_1m: 3.616,
      price_pc_6m: -8.489,
      price_pc_1y: -6.0,
      price_pc_ytd: -6.0,
    },
    2689: {
      id: "2689",
      c: 6.84,
      ma50: 7.626,
      ma100: 8.268,
      ma150: 8.979,
      ma200: 9.37,
      maw30: 9.007,
      rs: 25.0,
      rsd1d: -1.0,
      rsd5d: 2.0,
      rsd10d: 1.0,
      price_5d_h: 7.14,
      price_5d_L: 6.77,
      price_1m_h: 7.79,
      price_1m_l: 6.08,
      price_3m_h: 9.09,
      price_3m_l: 6.08,
      price_6m_h: 10.52,
      price_6m_l: 6.08,
      price_52w_h: 13.38,
      price_52w_l: 6.08,
      price_pc_1d: -2.425,
      price_pc_1w: -3.797,
      price_pc_1m: -8.434,
      price_pc_6m: -28.676,
      price_pc_1y: -40.0,
      price_pc_ytd: -40.0,
    },
    2696: {
      id: "2696",
      c: 20.8,
      ma50: 21.871,
      ma100: 24.306,
      ma150: 24.748,
      ma200: 27.155,
      maw30: 24.703,
      rs: 29.0,
      rsd1d: 1.0,
      rsd5d: 6.0,
      rsd10d: 13.0,
      price_5d_h: 22.9,
      price_5d_L: 20.3,
      price_1m_h: 23.1,
      price_1m_l: 14.9,
      price_3m_h: 25.4,
      price_3m_l: 14.9,
      price_6m_h: 34.05,
      price_6m_l: 14.9,
      price_52w_h: 46.45,
      price_52w_l: 14.9,
      price_pc_1d: -3.926,
      price_pc_1w: -1.655,
      price_pc_1m: -8.772,
      price_pc_6m: -15.275,
      price_pc_1y: -50.769,
      price_pc_ytd: -50.769,
    },
    2698: {
      id: "2698",
      c: 2.31,
      ma50: 2.4,
      ma100: 2.52,
      ma150: 2.637,
      ma200: 2.624,
      maw30: 2.644,
      rs: 60.0,
      rsd1d: 6.0,
      rsd5d: 7.0,
      rsd10d: 9.0,
      price_5d_h: 2.32,
      price_5d_L: 2.2,
      price_1m_h: 2.43,
      price_1m_l: 1.98,
      price_3m_h: 2.81,
      price_3m_l: 1.98,
      price_6m_h: 3.08,
      price_6m_l: 1.98,
      price_52w_h: 3.29,
      price_52w_l: 1.95,
      price_pc_1d: 0.873,
      price_pc_1w: 1.316,
      price_pc_1m: -1.282,
      price_pc_6m: -18.375,
      price_pc_1y: 10.0,
      price_pc_ytd: 10.0,
    },
    2700: {
      id: "2700",
      c: 0.35,
      ma50: 0.334,
      ma100: 0.365,
      ma150: 0.389,
      ma200: 0.412,
      maw30: 0.387,
      rs: 55.0,
      rsd1d: 7.0,
      rsd5d: 22.0,
      rsd10d: 29.0,
      price_5d_h: 0.355,
      price_5d_L: 0.345,
      price_1m_h: 0.355,
      price_1m_l: 0.29,
      price_3m_h: 0.385,
      price_3m_l: 0.29,
      price_6m_h: 0.46,
      price_6m_l: 0.29,
      price_52w_h: 0.53,
      price_52w_l: 0.29,
      price_pc_1d: 1.449,
      price_pc_1w: 4.478,
      price_pc_1m: 1.449,
      price_pc_6m: -12.5,
      price_pc_1y: -5.405,
      price_pc_ytd: -5.405,
    },
    2708: {
      id: "2708",
      c: 2.99,
      ma50: 3.186,
      ma100: 2.905,
      ma150: 2.942,
      ma200: 3.096,
      maw30: 2.937,
      rs: 68.0,
      rsd1d: -1.0,
      rsd5d: -4.0,
      rsd10d: -7.0,
      price_5d_h: 3.14,
      price_5d_L: 2.9,
      price_1m_h: 3.7,
      price_1m_l: 2.9,
      price_3m_h: 3.7,
      price_3m_l: 2.16,
      price_6m_h: 3.7,
      price_6m_l: 2.16,
      price_52w_h: 4.85,
      price_52w_l: 2.16,
      price_pc_1d: 0.0,
      price_pc_1w: -2.922,
      price_pc_1m: -7.143,
      price_pc_6m: 0.336,
      price_pc_1y: -35.421,
      price_pc_ytd: -35.421,
    },
    2718: {
      id: "2718",
      c: 0.68,
      ma50: 0.664,
      ma100: 0.781,
      ma150: 0.826,
      ma200: 0.852,
      maw30: 0.824,
      rs: 34.0,
      rsd1d: 3.0,
      rsd5d: -11.0,
      rsd10d: 11.0,
      price_5d_h: 0.69,
      price_5d_L: 0.63,
      price_1m_h: 0.78,
      price_1m_l: 0.55,
      price_3m_h: 0.87,
      price_3m_l: 0.55,
      price_6m_h: 0.99,
      price_6m_l: 0.55,
      price_52w_h: 1.28,
      price_52w_l: 0.55,
      price_pc_1d: 0.0,
      price_pc_1w: -9.333,
      price_pc_1m: -8.108,
      price_pc_6m: -24.444,
      price_pc_1y: -20.0,
      price_pc_ytd: -20.0,
    },
    2722: {
      id: "2722",
      c: 0.66,
      ma50: 0.644,
      ma100: 0.663,
      ma150: 0.675,
      ma200: 0.636,
      maw30: 0.689,
      rs: 82.0,
      rsd1d: -2.0,
      rsd5d: 14.0,
      rsd10d: 31.0,
      price_5d_h: 0.67,
      price_5d_L: 0.63,
      price_1m_h: 0.68,
      price_1m_l: 0.425,
      price_3m_h: 0.71,
      price_3m_l: 0.425,
      price_6m_h: 0.8,
      price_6m_l: 0.425,
      price_52w_h: 0.89,
      price_52w_l: 0.425,
      price_pc_1d: -1.493,
      price_pc_1w: 4.762,
      price_pc_1m: -2.941,
      price_pc_6m: -15.385,
      price_pc_1y: 20.0,
      price_pc_ytd: 20.0,
    },
    2727: {
      id: "2727",
      c: 2.06,
      ma50: 2.155,
      ma100: 2.237,
      ma150: 2.241,
      ma200: 2.168,
      maw30: 2.252,
      rs: 47.0,
      rsd1d: 4.0,
      rsd5d: 2.0,
      rsd10d: -7.0,
      price_5d_h: 2.1,
      price_5d_L: 2.01,
      price_1m_h: 2.25,
      price_1m_l: 1.84,
      price_3m_h: 2.53,
      price_3m_l: 1.84,
      price_6m_h: 2.53,
      price_6m_l: 1.84,
      price_52w_h: 2.83,
      price_52w_l: 1.66,
      price_pc_1d: -0.483,
      price_pc_1w: -1.435,
      price_pc_1m: -5.936,
      price_pc_6m: -15.574,
      price_pc_1y: -26.165,
      price_pc_ytd: -26.165,
    },
    2728: {
      id: "2728",
      c: 0.116,
      ma50: 0.124,
      ma100: 0.137,
      ma150: 0.156,
      ma200: 0.164,
      maw30: 0.157,
      rs: 32.0,
      rsd1d: 14.0,
      rsd5d: 1.0,
      rsd10d: -2.0,
      price_5d_h: 0.126,
      price_5d_L: 0.098,
      price_1m_h: 0.128,
      price_1m_l: 0.098,
      price_3m_h: 0.135,
      price_3m_l: 0.098,
      price_6m_h: 0.198,
      price_6m_l: 0.098,
      price_52w_h: 0.3,
      price_52w_l: 0.098,
      price_pc_1d: 10.476,
      price_pc_1w: -1.695,
      price_pc_1m: -9.375,
      price_pc_6m: -42.0,
      price_pc_1y: -24.183,
      price_pc_ytd: -24.183,
    },
    2738: {
      id: "2738",
      c: 2.42,
      ma50: 2.433,
      ma100: 2.438,
      ma150: 2.467,
      ma200: 2.564,
      maw30: 2.448,
      rs: 67.0,
      rsd1d: 3.0,
      rsd5d: 2.0,
      rsd10d: 8.0,
      price_5d_h: 2.65,
      price_5d_L: 2.34,
      price_1m_h: 2.79,
      price_1m_l: 2.24,
      price_3m_h: 2.79,
      price_3m_l: 2.22,
      price_6m_h: 2.79,
      price_6m_l: 2.05,
      price_52w_h: 3.21,
      price_52w_l: 1.71,
      price_pc_1d: 0.0,
      price_pc_1w: -1.224,
      price_pc_1m: -1.224,
      price_pc_6m: 0.0,
      price_pc_1y: 9.502,
      price_pc_ytd: 9.502,
    },
    2768: {
      id: "2768",
      c: 1.57,
      ma50: 2.362,
      ma100: 2.607,
      ma150: 2.746,
      ma200: 2.855,
      maw30: 2.759,
      rs: 10.0,
      rsd1d: 2.0,
      rsd5d: 0.0,
      rsd10d: -6.0,
      price_5d_h: 1.71,
      price_5d_L: 1.49,
      price_1m_h: 2.51,
      price_1m_l: 1.49,
      price_3m_h: 2.94,
      price_3m_l: 1.49,
      price_6m_h: 3.16,
      price_6m_l: 1.49,
      price_52w_h: 4.0,
      price_52w_l: 1.49,
      price_pc_1d: 0.641,
      price_pc_1w: -5.988,
      price_pc_1m: -36.694,
      price_pc_6m: -48.355,
      price_pc_1y: -51.84,
      price_pc_ytd: -51.84,
    },
    2772: {
      id: "2772",
      c: 2.16,
      ma50: 3.047,
      ma100: 3.369,
      ma150: 3.609,
      ma200: 3.866,
      maw30: 3.621,
      rs: 9.0,
      rsd1d: 1.0,
      rsd5d: -1.0,
      rsd10d: -8.0,
      price_5d_h: 2.45,
      price_5d_L: 2.09,
      price_1m_h: 3.11,
      price_1m_l: 2.02,
      price_3m_h: 4.21,
      price_3m_l: 2.02,
      price_6m_h: 4.21,
      price_6m_l: 2.02,
      price_52w_h: 5.66,
      price_52w_l: 2.02,
      price_pc_1d: 0.0,
      price_pc_1w: -10.744,
      price_pc_1m: -29.412,
      price_pc_6m: -46.269,
      price_pc_1y: -58.7,
      price_pc_ytd: -58.7,
    },
    2777: {
      id: "2777",
      c: 2.78,
      ma50: 3.419,
      ma100: 3.588,
      ma150: 4.236,
      ma200: 5.188,
      maw30: 4.207,
      rs: 13.0,
      rsd1d: 2.0,
      rsd5d: -2.0,
      rsd10d: -17.0,
      price_5d_h: 3.37,
      price_5d_L: 2.55,
      price_1m_h: 3.78,
      price_1m_l: 2.55,
      price_3m_h: 4.2,
      price_3m_l: 2.55,
      price_6m_h: 6.17,
      price_6m_l: 2.55,
      price_52w_h: 10.68,
      price_52w_l: 2.55,
      price_pc_1d: -1.418,
      price_pc_1w: -13.665,
      price_pc_1m: -16.265,
      price_pc_6m: -53.512,
      price_pc_1y: -73.01,
      price_pc_ytd: -73.01,
    },
    2778: {
      id: "2778",
      c: 3.56,
      ma50: 3.77,
      ma100: 3.896,
      ma150: 3.987,
      ma200: 4.098,
      maw30: 3.971,
      rs: 44.0,
      rsd1d: 0.0,
      rsd5d: -6.0,
      rsd10d: -11.0,
      price_5d_h: 3.68,
      price_5d_L: 3.56,
      price_1m_h: 3.8,
      price_1m_l: 3.4,
      price_3m_h: 4.1,
      price_3m_l: 3.4,
      price_6m_h: 4.22,
      price_6m_l: 3.4,
      price_52w_h: 4.9,
      price_52w_l: 3.4,
      price_pc_1d: -1.111,
      price_pc_1w: -2.997,
      price_pc_1m: 0.565,
      price_pc_6m: -12.745,
      price_pc_1y: -22.101,
      price_pc_ytd: -22.101,
    },
    2779: {
      id: "2779",
      c: 1.39,
      ma50: 1.289,
      ma100: 1.457,
      ma150: 1.521,
      ma200: 1.632,
      maw30: 1.514,
      rs: 31.0,
      rsd1d: 11.0,
      rsd5d: 12.0,
      rsd10d: 12.0,
      price_5d_h: 1.4,
      price_5d_L: 1.1,
      price_1m_h: 1.4,
      price_1m_l: 0.99,
      price_3m_h: 1.71,
      price_3m_l: 0.99,
      price_6m_h: 1.84,
      price_6m_l: 0.99,
      price_52w_h: 2.37,
      price_52w_l: 0.99,
      price_pc_1d: 10.317,
      price_pc_1w: 11.2,
      price_pc_1m: 1.46,
      price_pc_6m: -12.025,
      price_pc_1y: -40.086,
      price_pc_ytd: -40.086,
    },
    2789: {
      id: "2789",
      c: 0.033,
      ma50: 0.037,
      ma100: 0.041,
      ma150: 0.044,
      ma200: 0.046,
      maw30: 0.044,
      rs: 20.0,
      rsd1d: -5.0,
      rsd5d: 4.0,
      rsd10d: 7.0,
      price_5d_h: 0.037,
      price_5d_L: 0.031,
      price_1m_h: 0.038,
      price_1m_l: 0.028,
      price_3m_h: 0.048,
      price_3m_l: 0.028,
      price_6m_h: 0.063,
      price_6m_l: 0.028,
      price_52w_h: 0.084,
      price_52w_l: 0.028,
      price_pc_1d: -8.333,
      price_pc_1w: -2.941,
      price_pc_1m: -13.158,
      price_pc_6m: -28.261,
      price_pc_1y: -45.902,
      price_pc_ytd: -45.902,
    },
    2798: {
      id: "2798",
      c: 1.69,
      ma50: 1.693,
      ma100: 1.695,
      ma150: 1.696,
      ma200: 1.716,
      maw30: 1.692,
      rs: 40.0,
      rsd1d: 0.0,
      rsd5d: 5.0,
      rsd10d: 5.0,
      price_5d_h: 1.71,
      price_5d_L: 1.65,
      price_1m_h: 1.81,
      price_1m_l: 1.64,
      price_3m_h: 1.83,
      price_3m_l: 1.59,
      price_6m_h: 1.98,
      price_6m_l: 1.51,
      price_52w_h: 16.7,
      price_52w_l: 1.42,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: 3.049,
      price_pc_6m: 2.424,
      price_pc_1y: -89.331,
      price_pc_ytd: -89.331,
    },
    2799: {
      id: "2799",
      c: 0.365,
      ma50: 0.393,
      ma100: 0.654,
      ma150: 0.776,
      ma200: 0.837,
      maw30: 0.773,
      rs: 3.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: 1.0,
      price_5d_h: 0.38,
      price_5d_L: 0.35,
      price_1m_h: 0.41,
      price_1m_l: 0.28,
      price_3m_h: 1.02,
      price_3m_l: 0.28,
      price_6m_h: 1.02,
      price_6m_l: 0.28,
      price_52w_h: 1.02,
      price_52w_l: 0.28,
      price_pc_1d: -1.351,
      price_pc_1w: 2.817,
      price_pc_1m: -10.976,
      price_pc_6m: -64.216,
      price_pc_1y: -64.216,
      price_pc_ytd: -64.216,
    },
    2800: {
      id: "2800",
      c: 22.28,
      ma50: 23.035,
      ma100: 23.594,
      ma150: 24.345,
      ma200: 25.18,
      maw30: 24.372,
      rs: 47.0,
      rsd1d: 0.0,
      rsd5d: 2.0,
      rsd10d: 3.0,
      price_5d_h: 22.58,
      price_5d_L: 21.42,
      price_1m_h: 23.06,
      price_1m_l: 18.44,
      price_3m_h: 25.16,
      price_3m_l: 18.44,
      price_6m_h: 26.9,
      price_6m_l: 18.44,
      price_52w_h: 29.82,
      price_52w_l: 18.44,
      price_pc_1d: -0.536,
      price_pc_1w: 0.632,
      price_pc_1m: -2.622,
      price_pc_6m: -11.657,
      price_pc_1y: -23.594,
      price_pc_ytd: -23.594,
    },
    2801: {
      id: "2801",
      c: 23.2,
      ma50: 24.594,
      ma100: 26.055,
      ma150: 27.24,
      ma200: 28.438,
      maw30: 27.311,
      rs: 32.0,
      rsd1d: 0.0,
      rsd5d: 2.0,
      rsd10d: 1.0,
      price_5d_h: 23.56,
      price_5d_L: 22.04,
      price_1m_h: 25.4,
      price_1m_l: 18.36,
      price_3m_h: 28.5,
      price_3m_l: 18.36,
      price_6m_h: 31.08,
      price_6m_l: 18.36,
      price_52w_h: 36.16,
      price_52w_l: 18.36,
      price_pc_1d: -0.855,
      price_pc_1w: 0.0,
      price_pc_1m: -6.376,
      price_pc_6m: -19.444,
      price_pc_1y: -34.426,
      price_pc_ytd: -34.426,
    },
    2802: {
      id: "2802",
      c: 59.18,
      ma50: 60.143,
      ma100: 62.254,
      ma150: 63.762,
      ma200: 65.025,
      maw30: 63.828,
      rs: 53.0,
      rsd1d: 3.0,
      rsd5d: 3.0,
      rsd10d: 6.0,
      price_5d_h: 59.18,
      price_5d_L: 57.2,
      price_1m_h: 60.64,
      price_1m_l: 52.7,
      price_3m_h: 66.0,
      price_3m_l: 52.7,
      price_6m_h: 68.4,
      price_6m_l: 52.7,
      price_52w_h: 73.6,
      price_52w_l: 52.7,
      price_pc_1d: 0.0,
      price_pc_1w: 0.305,
      price_pc_1m: -0.671,
      price_pc_6m: -9.428,
      price_pc_1y: -16.882,
      price_pc_ytd: -16.882,
    },
    2803: {
      id: "2803",
      c: 9.015,
      ma50: 8.908,
      ma100: 8.845,
      ma150: 8.789,
      ma200: 8.683,
      maw30: 8.818,
      rs: 79.0,
      rsd1d: 2.0,
      rsd5d: 7.0,
      rsd10d: 11.0,
      price_5d_h: 9.025,
      price_5d_L: 8.665,
      price_1m_h: 9.21,
      price_1m_l: 8.125,
      price_3m_h: 9.31,
      price_3m_l: 8.125,
      price_6m_h: 9.31,
      price_6m_l: 8.125,
      price_52w_h: 9.31,
      price_52w_l: 7.795,
      price_pc_1d: 1.749,
      price_pc_1w: 3.087,
      price_pc_1m: -0.221,
      price_pc_6m: 3.383,
      price_pc_1y: 4.643,
      price_pc_ytd: 4.643,
    },
    2804: {
      id: "2804",
      c: 96.62,
      ma50: 98.607,
      ma100: 101.443,
      ma150: 101.423,
      ma200: 100.871,
      maw30: 101.695,
      rs: 69.0,
      rsd1d: 1.0,
      rsd5d: -4.0,
      rsd10d: -1.0,
      price_5d_h: 97.66,
      price_5d_L: 95.98,
      price_1m_h: 99.1,
      price_1m_l: 94.0,
      price_3m_h: 108.0,
      price_3m_l: 94.0,
      price_6m_h: 108.0,
      price_6m_l: 94.0,
      price_52w_h: 108.0,
      price_52w_l: 90.36,
      price_pc_1d: 0.332,
      price_pc_1w: -1.186,
      price_pc_1m: -1.287,
      price_pc_6m: -3.031,
      price_pc_1y: 4.999,
      price_pc_ytd: 4.999,
    },
    2806: {
      id: "2806",
      c: 56.2,
      ma50: 60.831,
      ma100: 65.486,
      ma150: 67.383,
      ma200: 69.672,
      maw30: 67.59,
      rs: 30.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: -1.0,
      price_5d_h: 57.02,
      price_5d_L: 53.16,
      price_1m_h: 63.8,
      price_1m_l: 49.44,
      price_3m_h: 69.38,
      price_3m_l: 49.44,
      price_6m_h: 75.0,
      price_6m_l: 49.44,
      price_52w_h: 88.22,
      price_52w_l: 49.44,
      price_pc_1d: -0.847,
      price_pc_1w: -1.404,
      price_pc_1m: -9.965,
      price_pc_6m: -20.509,
      price_pc_1y: -32.289,
      price_pc_ytd: -32.289,
    },
    2807: {
      id: "2807",
      c: 52.34,
      ma50: 57.227,
      ma100: 61.457,
      ma150: 62.073,
      ma200: 63.097,
      maw30: 62.204,
      rs: 37.0,
      rsd1d: 1.0,
      rsd5d: -3.0,
      rsd10d: -10.0,
      price_5d_h: 53.64,
      price_5d_L: 50.82,
      price_1m_h: 60.08,
      price_1m_l: 49.2,
      price_3m_h: 66.94,
      price_3m_l: 49.2,
      price_6m_h: 68.74,
      price_6m_l: 49.2,
      price_52w_h: 70.1,
      price_52w_l: 49.2,
      price_pc_1d: -0.305,
      price_pc_1w: -2.387,
      price_pc_1m: -12.679,
      price_pc_6m: -15.744,
      price_pc_1y: -14.95,
      price_pc_ytd: -14.95,
    },
    2809: {
      id: "2809",
      c: 138.25,
      ma50: 141.616,
      ma100: 147.494,
      ma150: 150.476,
      ma200: 146.973,
      maw30: 150.525,
      rs: 77.0,
      rsd1d: -5.0,
      rsd5d: -6.0,
      rsd10d: -11.0,
      price_5d_h: 141.85,
      price_5d_L: 133.1,
      price_1m_h: 152.55,
      price_1m_l: 133.1,
      price_3m_h: 156.45,
      price_3m_l: 130.0,
      price_6m_h: 169.85,
      price_6m_l: 130.0,
      price_52w_h: 169.85,
      price_52w_l: 96.32,
      price_pc_1d: -2.193,
      price_pc_1w: -1.671,
      price_pc_1m: -6.902,
      price_pc_6m: -9.788,
      price_pc_1y: 30.919,
      price_pc_ytd: 30.919,
    },
    2810: {
      id: "2810",
      c: 76.5,
      ma50: 74.822,
      ma100: 73.678,
      ma150: 72.853,
      ma200: 71.715,
      maw30: 72.921,
      rs: 83.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: -1.0,
      price_5d_h: 76.5,
      price_5d_L: 75.18,
      price_1m_h: 76.5,
      price_1m_l: 73.6,
      price_3m_h: 76.68,
      price_3m_l: 72.5,
      price_6m_h: 76.68,
      price_6m_l: 69.1,
      price_52w_h: 76.68,
      price_52w_l: 65.0,
      price_pc_1d: 0.499,
      price_pc_1w: 2.027,
      price_pc_1m: 0.526,
      price_pc_6m: 10.39,
      price_pc_1y: 7.353,
      price_pc_ytd: 7.353,
    },
    2811: {
      id: "2811",
      c: 18.4,
      ma50: 19.304,
      ma100: 20.23,
      ma150: 20.432,
      ma200: 20.635,
      maw30: 20.494,
      rs: 48.0,
      rsd1d: 3.0,
      rsd5d: 1.0,
      rsd10d: 0.0,
      price_5d_h: 18.4,
      price_5d_L: 17.89,
      price_1m_h: 19.9,
      price_1m_l: 16.84,
      price_3m_h: 21.38,
      price_3m_l: 16.84,
      price_6m_h: 22.38,
      price_6m_l: 16.84,
      price_52w_h: 22.8,
      price_52w_l: 16.84,
      price_pc_1d: 0.0,
      price_pc_1w: -0.109,
      price_pc_1m: -7.024,
      price_pc_6m: -11.283,
      price_pc_1y: -14.339,
      price_pc_ytd: -14.339,
    },
    2812: {
      id: "2812",
      c: 11.28,
      ma50: 12.188,
      ma100: 13.311,
      ma150: 14.17,
      ma200: 15.058,
      maw30: 14.202,
      rs: 22.0,
      rsd1d: -1.0,
      rsd5d: 1.0,
      rsd10d: 1.0,
      price_5d_h: 11.58,
      price_5d_L: 10.63,
      price_1m_h: 12.41,
      price_1m_l: 8.035,
      price_3m_h: 14.41,
      price_3m_l: 8.035,
      price_6m_h: 17.26,
      price_6m_l: 8.035,
      price_52w_h: 22.7,
      price_52w_l: 8.035,
      price_pc_1d: -1.742,
      price_pc_1w: -1.226,
      price_pc_1m: -7.084,
      price_pc_6m: -24.447,
      price_pc_1y: -49.733,
      price_pc_ytd: -49.733,
    },
    2813: {
      id: "2813",
      c: 132.15,
      ma50: 131.875,
      ma100: 131.125,
      ma150: 130.131,
      ma200: 129.306,
      maw30: 130.169,
      rs: 79.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: -3.0,
      price_5d_h: 132.1,
      price_5d_L: 131.45,
      price_1m_h: 134.9,
      price_1m_l: 131.3,
      price_3m_h: 134.9,
      price_3m_l: 131.15,
      price_6m_h: 134.9,
      price_6m_l: 127.95,
      price_52w_h: 134.9,
      price_52w_l: 123.4,
      price_pc_1d: 0.19,
      price_pc_1w: 0.494,
      price_pc_1m: -0.227,
      price_pc_6m: 3.525,
      price_pc_1y: 6.273,
      price_pc_ytd: 6.273,
    },
    2814: {
      id: "2814",
      c: 20.52,
      ma50: 19.244,
      ma100: 20.986,
      ma150: 21.392,
      ma200: 21.409,
      maw30: 21.4,
      rs: 61.0,
      rsd1d: 1.0,
      rsd5d: 6.0,
      rsd10d: 9.0,
      price_5d_h: 20.74,
      price_5d_L: 19.79,
      price_1m_h: 20.74,
      price_1m_l: 16.15,
      price_3m_h: 22.94,
      price_3m_l: 16.15,
      price_6m_h: 24.12,
      price_6m_l: 16.15,
      price_52w_h: 24.12,
      price_52w_l: 16.15,
      price_pc_1d: -0.195,
      price_pc_1w: 3.167,
      price_pc_1m: 10.68,
      price_pc_6m: -3.481,
      price_pc_1y: 2.806,
      price_pc_ytd: 2.806,
    },
    2817: {
      id: "2817",
      c: 129.0,
      ma50: 130.207,
      ma100: 129.731,
      ma150: 128.473,
      ma200: 127.321,
      maw30: 128.625,
      rs: 78.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: -4.0,
      price_5d_h: 129.1,
      price_5d_L: 129.0,
      price_1m_h: 130.6,
      price_1m_l: 128.4,
      price_3m_h: 132.5,
      price_3m_l: 128.4,
      price_6m_h: 132.5,
      price_6m_l: 124.6,
      price_52w_h: 132.5,
      price_52w_l: 118.15,
      price_pc_1d: 0.0,
      price_pc_1w: 0.116,
      price_pc_1m: -0.998,
      price_pc_6m: 2.381,
      price_pc_1y: 7.814,
      price_pc_ytd: 7.814,
    },
    2819: {
      id: "2819",
      c: 97.5,
      ma50: 99.124,
      ma100: 99.838,
      ma150: 100.22,
      ma200: 100.545,
      maw30: 100.24,
      rs: 68.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: -3.0,
      price_5d_h: 98.5,
      price_5d_L: 97.5,
      price_1m_h: 99.0,
      price_1m_l: 97.5,
      price_3m_h: 100.7,
      price_3m_l: 97.5,
      price_6m_h: 101.9,
      price_6m_l: 97.5,
      price_52w_h: 102.8,
      price_52w_l: 97.5,
      price_pc_1d: -0.051,
      price_pc_1w: -1.015,
      price_pc_1m: -1.316,
      price_pc_6m: -3.37,
      price_pc_1y: -4.412,
      price_pc_ytd: -4.412,
    },
    2820: {
      id: "2820",
      c: 79.86,
      ma50: 80.823,
      ma100: 91.614,
      ma150: 98.544,
      ma200: 106.005,
      maw30: 98.616,
      rs: 23.0,
      rsd1d: 0.0,
      rsd5d: -4.0,
      rsd10d: 1.0,
      price_5d_h: 83.66,
      price_5d_L: 75.68,
      price_1m_h: 84.36,
      price_1m_l: 65.86,
      price_3m_h: 98.22,
      price_3m_l: 65.86,
      price_6m_h: 116.0,
      price_6m_l: 65.86,
      price_52w_h: 142.2,
      price_52w_l: 65.86,
      price_pc_1d: -1.82,
      price_pc_1w: -4.199,
      price_pc_1m: -2.965,
      price_pc_6m: -30.557,
      price_pc_1y: -34.19,
      price_pc_ytd: -34.19,
    },
    2821: {
      id: "2821",
      c: 115.3,
      ma50: 117.102,
      ma100: 118.341,
      ma150: 118.867,
      ma200: 119.446,
      maw30: 118.843,
      rs: 67.0,
      rsd1d: 1.0,
      rsd5d: 3.0,
      rsd10d: -3.0,
      price_5d_h: 115.3,
      price_5d_L: 114.3,
      price_1m_h: 117.8,
      price_1m_l: 114.3,
      price_3m_h: 120.15,
      price_3m_l: 114.3,
      price_6m_h: 120.3,
      price_6m_l: 114.3,
      price_52w_h: 123.8,
      price_52w_l: 114.3,
      price_pc_1d: 0.0,
      price_pc_1w: 0.13,
      price_pc_1m: -1.872,
      price_pc_6m: -3.312,
      price_pc_1y: -3.917,
      price_pc_ytd: -3.917,
    },
    2822: {
      id: "2822",
      c: 15.71,
      ma50: 16.501,
      ma100: 17.262,
      ma150: 17.418,
      ma200: 17.701,
      maw30: 17.505,
      rs: 45.0,
      rsd1d: 3.0,
      rsd5d: 2.0,
      rsd10d: 2.0,
      price_5d_h: 15.76,
      price_5d_L: 14.97,
      price_1m_h: 17.17,
      price_1m_l: 14.13,
      price_3m_h: 17.99,
      price_3m_l: 14.13,
      price_6m_h: 19.44,
      price_6m_l: 14.13,
      price_52w_h: 21.26,
      price_52w_l: 14.13,
      price_pc_1d: 0.0,
      price_pc_1w: 0.191,
      price_pc_1m: -7.151,
      price_pc_6m: -11.293,
      price_pc_1y: -19.146,
      price_pc_ytd: -19.146,
    },
    2823: {
      id: "2823",
      c: 16.22,
      ma50: 17.008,
      ma100: 17.738,
      ma150: 17.855,
      ma200: 18.11,
      maw30: 17.941,
      rs: 47.0,
      rsd1d: 3.0,
      rsd5d: 3.0,
      rsd10d: -1.0,
      price_5d_h: 16.26,
      price_5d_L: 15.42,
      price_1m_h: 17.69,
      price_1m_l: 14.54,
      price_3m_h: 18.55,
      price_3m_l: 14.54,
      price_6m_h: 19.87,
      price_6m_l: 14.54,
      price_52w_h: 21.6,
      price_52w_l: 14.54,
      price_pc_1d: 0.0,
      price_pc_1w: 0.433,
      price_pc_1m: -6.835,
      price_pc_6m: -10.238,
      price_pc_1y: -18.039,
      price_pc_ytd: -18.039,
    },
    2825: {
      id: "2825",
      c: 23.16,
      ma50: 24.231,
      ma100: 24.98,
      ma150: 25.655,
      ma200: 26.472,
      maw30: 25.722,
      rs: 43.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: 2.0,
      price_5d_h: 23.22,
      price_5d_L: 22.42,
      price_1m_h: 24.24,
      price_1m_l: 19.41,
      price_3m_h: 26.48,
      price_3m_l: 19.41,
      price_6m_h: 27.94,
      price_6m_l: 19.41,
      price_52w_h: 31.68,
      price_52w_l: 19.41,
      price_pc_1d: -0.258,
      price_pc_1w: 0.521,
      price_pc_1m: -4.455,
      price_pc_6m: -12.006,
      price_pc_1y: -25.864,
      price_pc_ytd: -25.864,
    },
    2826: {
      id: "2826",
      c: 61.34,
      ma50: 66.538,
      ma100: 73.151,
      ma150: 77.778,
      ma200: 82.99,
      maw30: 77.863,
      rs: 20.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: -2.0,
      price_5d_h: 62.4,
      price_5d_L: 58.8,
      price_1m_h: 69.5,
      price_1m_l: 48.0,
      price_3m_h: 78.0,
      price_3m_l: 48.0,
      price_6m_h: 91.94,
      price_6m_l: 48.0,
      price_52w_h: 118.75,
      price_52w_l: 48.0,
      price_pc_1d: -0.809,
      price_pc_1w: -2.2,
      price_pc_1m: -12.12,
      price_pc_6m: -26.854,
      price_pc_1y: -46.892,
      price_pc_ytd: -46.892,
    },
    2827: {
      id: "2827",
      c: 43.48,
      ma50: 45.602,
      ma100: 47.555,
      ma150: 47.967,
      ma200: 48.38,
      maw30: 48.145,
      rs: 51.0,
      rsd1d: 3.0,
      rsd5d: 3.0,
      rsd10d: -1.0,
      price_5d_h: 43.48,
      price_5d_L: 42.22,
      price_1m_h: 47.32,
      price_1m_l: 39.94,
      price_3m_h: 50.0,
      price_3m_l: 39.94,
      price_6m_h: 52.26,
      price_6m_l: 39.94,
      price_52w_h: 54.36,
      price_52w_l: 39.94,
      price_pc_1d: -0.275,
      price_pc_1w: 0.231,
      price_pc_1m: -7.529,
      price_pc_6m: -10.498,
      price_pc_1y: -12.585,
      price_pc_ytd: -12.585,
    },
    2828: {
      id: "2828",
      c: 76.3,
      ma50: 80.727,
      ma100: 83.573,
      ma150: 86.177,
      ma200: 89.679,
      maw30: 86.389,
      rs: 38.0,
      rsd1d: 0.0,
      rsd5d: 2.0,
      rsd10d: 2.0,
      price_5d_h: 77.58,
      price_5d_L: 72.88,
      price_1m_h: 82.24,
      price_1m_l: 61.32,
      price_3m_h: 89.4,
      price_3m_l: 61.32,
      price_6m_h: 95.34,
      price_6m_l: 61.32,
      price_52w_h: 113.95,
      price_52w_l: 61.32,
      price_pc_1d: -1.012,
      price_pc_1w: 0.21,
      price_pc_1m: -6.15,
      price_pc_6m: -13.999,
      price_pc_1y: -32.627,
      price_pc_ytd: -32.627,
    },
    2829: {
      id: "2829",
      c: 63.38,
      ma50: 63.132,
      ma100: 62.601,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 77.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: -4.0,
      price_5d_h: 63.44,
      price_5d_L: 62.78,
      price_1m_h: 63.5,
      price_1m_l: 62.62,
      price_3m_h: 63.56,
      price_3m_l: 62.2,
      price_6m_h: 63.56,
      price_6m_l: 60.88,
      price_52w_h: 63.56,
      price_52w_l: 60.88,
      price_pc_1d: 0.38,
      price_pc_1w: 0.891,
      price_pc_1m: -0.22,
      price_pc_6m: 3.698,
      price_pc_1y: 3.698,
      price_pc_ytd: 3.698,
    },
    2832: {
      id: "2832",
      c: 9.23,
      ma50: 10.087,
      ma100: 10.958,
      ma150: 11.147,
      ma200: 11.573,
      maw30: 11.135,
      rs: 35.0,
      rsd1d: 1.0,
      rsd5d: -5.0,
      rsd10d: -10.0,
      price_5d_h: 9.35,
      price_5d_L: 9.1,
      price_1m_h: 10.48,
      price_1m_l: 9.1,
      price_3m_h: 11.82,
      price_3m_l: 9.1,
      price_6m_h: 12.46,
      price_6m_l: 9.1,
      price_52w_h: 13.45,
      price_52w_l: 9.1,
      price_pc_1d: -0.859,
      price_pc_1w: -3.351,
      price_pc_1m: -12.842,
      price_pc_6m: -18.821,
      price_pc_1y: -11.165,
      price_pc_ytd: -11.165,
    },
    2833: {
      id: "2833",
      c: 22.42,
      ma50: 23.312,
      ma100: 23.883,
      ma150: 24.496,
      ma200: 25.31,
      maw30: 24.524,
      rs: 47.0,
      rsd1d: -1.0,
      rsd5d: 1.0,
      rsd10d: 2.0,
      price_5d_h: 22.74,
      price_5d_L: 21.64,
      price_1m_h: 23.34,
      price_1m_l: 18.68,
      price_3m_h: 25.5,
      price_3m_l: 18.68,
      price_6m_h: 26.62,
      price_6m_l: 18.68,
      price_52w_h: 30.16,
      price_52w_l: 18.68,
      price_pc_1d: -0.884,
      price_pc_1w: 0.268,
      price_pc_1m: -3.111,
      price_pc_6m: -10.104,
      price_pc_1y: -24.0,
      price_pc_ytd: -24.0,
    },
    2834: {
      id: "2834",
      c: 287.3,
      ma50: 270.362,
      ma100: 287.358,
      ma150: 287.771,
      ma200: 285.069,
      maw30: 287.747,
      rs: 78.0,
      rsd1d: -1.0,
      rsd5d: 9.0,
      rsd10d: 9.0,
      price_5d_h: 289.4,
      price_5d_L: 276.5,
      price_1m_h: 289.4,
      price_1m_l: 246.8,
      price_3m_h: 311.5,
      price_3m_l: 246.8,
      price_6m_h: 313.2,
      price_6m_l: 246.8,
      price_52w_h: 313.2,
      price_52w_l: 244.1,
      price_pc_1d: -0.243,
      price_pc_1w: 4.283,
      price_pc_1m: 8.333,
      price_pc_6m: 2.717,
      price_pc_1y: 15.567,
      price_pc_ytd: 15.567,
    },
    2836: {
      id: "2836",
      c: 34.74,
      ma50: 33.645,
      ma100: 34.318,
      ma150: 34.709,
      ma200: 34.053,
      maw30: 34.796,
      rs: 81.0,
      rsd1d: 0.0,
      rsd5d: 4.0,
      rsd10d: -1.0,
      price_5d_h: 34.78,
      price_5d_L: 33.3,
      price_1m_h: 34.78,
      price_1m_l: 30.12,
      price_3m_h: 36.7,
      price_3m_l: 30.12,
      price_6m_h: 37.0,
      price_6m_l: 30.12,
      price_52w_h: 37.0,
      price_52w_l: 27.8,
      price_pc_1d: 0.289,
      price_pc_1w: 3.578,
      price_pc_1m: 5.657,
      price_pc_6m: -1.698,
      price_pc_1y: 14.427,
      price_pc_ytd: 14.427,
    },
    2838: {
      id: "2838",
      c: 147.5,
      ma50: 155.743,
      ma100: 163.008,
      ma150: 168.283,
      ma200: 173.971,
      maw30: 168.733,
      rs: 38.0,
      rsd1d: -2.0,
      rsd5d: 1.0,
      rsd10d: 2.0,
      price_5d_h: 150.5,
      price_5d_L: 141.45,
      price_1m_h: 156.45,
      price_1m_l: 116.8,
      price_3m_h: 174.05,
      price_3m_l: 116.8,
      price_6m_h: 187.1,
      price_6m_l: 116.8,
      price_52w_h: 212.1,
      price_52w_l: 116.8,
      price_pc_1d: -1.568,
      price_pc_1w: -0.237,
      price_pc_1m: -5.327,
      price_pc_6m: -14.641,
      price_pc_1y: -30.194,
      price_pc_ytd: -30.194,
    },
    2839: {
      id: "2839",
      c: 28.88,
      ma50: 30.398,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 48.0,
      rsd1d: 1.0,
      rsd5d: 2.0,
      rsd10d: -3.0,
      price_5d_h: 28.98,
      price_5d_L: 27.62,
      price_1m_h: 32.0,
      price_1m_l: 26.28,
      price_3m_h: 33.58,
      price_3m_l: 26.28,
      price_6m_h: 35.3,
      price_6m_l: 26.28,
      price_52w_h: 35.3,
      price_52w_l: 26.28,
      price_pc_1d: -0.276,
      price_pc_1w: -0.069,
      price_pc_1m: -8.317,
      price_pc_6m: -16.676,
      price_pc_1y: -16.676,
      price_pc_ytd: -16.676,
    },
    2840: {
      id: "2840",
      c: 1411.5,
      ma50: 1381.75,
      ma100: 1349.45,
      ma150: 1331.58,
      ma200: 1325.12,
      maw30: 1330.43,
      rs: 85.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: -2.0,
      price_5d_h: 1433.5,
      price_5d_L: 1400.0,
      price_1m_h: 1502.0,
      price_1m_l: 1385.0,
      price_3m_h: 1502.0,
      price_3m_l: 1300.5,
      price_6m_h: 1502.0,
      price_6m_l: 1272.0,
      price_52w_h: 1502.0,
      price_52w_l: 1255.5,
      price_pc_1d: 0.534,
      price_pc_1w: -0.599,
      price_pc_1m: 2.283,
      price_pc_6m: 11.891,
      price_pc_1y: 12.83,
      price_pc_ytd: 12.83,
    },
    2843: {
      id: "2843",
      c: 16.84,
      ma50: 17.789,
      ma100: 18.494,
      ma150: 18.614,
      ma200: 18.94,
      maw30: 18.689,
      rs: 45.0,
      rsd1d: 2.0,
      rsd5d: 2.0,
      rsd10d: 1.0,
      price_5d_h: 16.84,
      price_5d_L: 16.32,
      price_1m_h: 18.44,
      price_1m_l: 15.39,
      price_3m_h: 19.25,
      price_3m_l: 15.39,
      price_6m_h: 20.04,
      price_6m_l: 15.39,
      price_52w_h: 22.54,
      price_52w_l: 15.39,
      price_pc_1d: 0.059,
      price_pc_1w: 0.059,
      price_pc_1m: -7.675,
      price_pc_6m: -9.753,
      price_pc_1y: -19.194,
      price_pc_ytd: -19.194,
    },
    2845: {
      id: "2845",
      c: 146.25,
      ma50: 154.05,
      ma100: 173.66,
      ma150: 177.65,
      ma200: 175.635,
      maw30: 177.52,
      rs: 63.0,
      rsd1d: -2.0,
      rsd5d: -6.0,
      rsd10d: -3.0,
      price_5d_h: 151.5,
      price_5d_L: 140.5,
      price_1m_h: 168.6,
      price_1m_l: 131.9,
      price_3m_h: 186.5,
      price_3m_l: 131.9,
      price_6m_h: 213.6,
      price_6m_l: 131.9,
      price_52w_h: 213.6,
      price_52w_l: 108.1,
      price_pc_1d: -2.435,
      price_pc_1w: -2.695,
      price_pc_1m: -12.294,
      price_pc_6m: -17.86,
      price_pc_1y: 26.078,
      price_pc_ytd: 26.078,
    },
    2846: {
      id: "2846",
      c: 34.08,
      ma50: 35.896,
      ma100: 37.477,
      ma150: 37.91,
      ma200: 38.309,
      maw30: 38.062,
      rs: 47.0,
      rsd1d: 2.0,
      rsd5d: 2.0,
      rsd10d: -2.0,
      price_5d_h: 34.22,
      price_5d_L: 33.0,
      price_1m_h: 37.22,
      price_1m_l: 31.5,
      price_3m_h: 39.56,
      price_3m_l: 31.5,
      price_6m_h: 41.02,
      price_6m_l: 31.5,
      price_52w_h: 43.0,
      price_52w_l: 31.5,
      price_pc_1d: -0.176,
      price_pc_1w: 0.235,
      price_pc_1m: -7.592,
      price_pc_6m: -11.481,
      price_pc_1y: -14.586,
      price_pc_ytd: -14.586,
    },
    2848: {
      id: "2848",
      c: 657.0,
      ma50: 654.508,
      ma100: 686.752,
      ma150: 706.059,
      ma200: 734.355,
      maw30: 705.487,
      rs: 46.0,
      rsd1d: 6.0,
      rsd5d: 6.0,
      rsd10d: 4.0,
      price_5d_h: 655.0,
      price_5d_L: 645.6,
      price_1m_h: 662.6,
      price_1m_l: 608.8,
      price_3m_h: 723.8,
      price_3m_l: 608.8,
      price_6m_h: 741.4,
      price_6m_l: 608.8,
      price_52w_h: 863.6,
      price_52w_l: 608.8,
      price_pc_1d: 1.608,
      price_pc_1w: 1.546,
      price_pc_1m: 1.734,
      price_pc_6m: -10.976,
      price_pc_1y: -20.248,
      price_pc_ytd: -20.248,
    },
    2849: {
      id: "2849",
      c: 57.3,
      ma50: 55.568,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 61.0,
      rsd1d: 0.0,
      rsd5d: 8.0,
      rsd10d: 6.0,
      price_5d_h: 57.8,
      price_5d_L: 55.26,
      price_1m_h: 57.8,
      price_1m_l: 48.54,
      price_3m_h: 63.7,
      price_3m_l: 48.54,
      price_6m_h: 63.7,
      price_6m_l: 48.54,
      price_52w_h: 63.7,
      price_52w_l: 48.54,
      price_pc_1d: -0.865,
      price_pc_1w: 3.243,
      price_pc_1m: 4.562,
      price_pc_6m: -6.829,
      price_pc_1y: -6.829,
      price_pc_ytd: -6.829,
    },
    2858: {
      id: "2858",
      c: 0.82,
      ma50: 1.147,
      ma100: 1.25,
      ma150: 1.354,
      ma200: 1.526,
      maw30: 1.358,
      rs: 9.0,
      rsd1d: -2.0,
      rsd5d: -4.0,
      rsd10d: -1.0,
      price_5d_h: 1.01,
      price_5d_L: 0.8,
      price_1m_h: 1.19,
      price_1m_l: 0.71,
      price_3m_h: 1.42,
      price_3m_l: 0.71,
      price_6m_h: 1.87,
      price_6m_l: 0.71,
      price_52w_h: 2.89,
      price_52w_l: 0.71,
      price_pc_1d: -11.828,
      price_pc_1w: -18.0,
      price_pc_1m: -29.915,
      price_pc_6m: -45.333,
      price_pc_1y: -69.63,
      price_pc_ytd: -69.63,
    },
    2866: {
      id: "2866",
      c: 1.59,
      ma50: 1.409,
      ma100: 1.424,
      ma150: 1.475,
      ma200: 1.5,
      maw30: 1.472,
      rs: 84.0,
      rsd1d: 24.0,
      rsd5d: 31.0,
      rsd10d: 35.0,
      price_5d_h: 1.61,
      price_5d_L: 1.27,
      price_1m_h: 1.61,
      price_1m_l: 1.13,
      price_3m_h: 1.61,
      price_3m_l: 1.13,
      price_6m_h: 1.61,
      price_6m_l: 1.13,
      price_52w_h: 1.94,
      price_52w_l: 1.13,
      price_pc_1d: 16.058,
      price_pc_1w: 20.455,
      price_pc_1m: 9.655,
      price_pc_6m: 2.581,
      price_pc_1y: 22.308,
      price_pc_ytd: 22.308,
    },
    2869: {
      id: "2869",
      c: 7.88,
      ma50: 7.793,
      ma100: 7.584,
      ma150: 7.792,
      ma200: 8.262,
      maw30: 7.828,
      rs: 53.0,
      rsd1d: -2.0,
      rsd5d: 4.0,
      rsd10d: -4.0,
      price_5d_h: 8.0,
      price_5d_L: 6.68,
      price_1m_h: 8.0,
      price_1m_l: 5.31,
      price_3m_h: 9.28,
      price_3m_l: 5.31,
      price_6m_h: 9.35,
      price_6m_l: 5.31,
      price_52w_h: 13.76,
      price_52w_l: 5.31,
      price_pc_1d: -0.253,
      price_pc_1w: 9.902,
      price_pc_1m: 0.639,
      price_pc_6m: -6.19,
      price_pc_1y: -32.879,
      price_pc_ytd: -32.879,
    },
    2877: {
      id: "2877",
      c: 6.27,
      ma50: 6.409,
      ma100: 6.696,
      ma150: 7.022,
      ma200: 7.299,
      maw30: 7.04,
      rs: 63.0,
      rsd1d: -7.0,
      rsd5d: 10.0,
      rsd10d: 1.0,
      price_5d_h: 6.62,
      price_5d_L: 6.21,
      price_1m_h: 6.62,
      price_1m_l: 5.8,
      price_3m_h: 7.11,
      price_3m_l: 5.8,
      price_6m_h: 7.69,
      price_6m_l: 5.55,
      price_52w_h: 9.93,
      price_52w_l: 5.32,
      price_pc_1d: -0.948,
      price_pc_1w: 1.292,
      price_pc_1m: -2.031,
      price_pc_6m: -15.27,
      price_pc_1y: 16.111,
      price_pc_ytd: 16.111,
    },
    2878: {
      id: "2878",
      c: 0.6,
      ma50: 0.716,
      ma100: 0.757,
      ma150: 0.774,
      ma200: 0.838,
      maw30: 0.774,
      rs: 46.0,
      rsd1d: 3.0,
      rsd5d: -29.0,
      rsd10d: -24.0,
      price_5d_h: 0.7,
      price_5d_L: 0.59,
      price_1m_h: 0.74,
      price_1m_l: 0.48,
      price_3m_h: 0.93,
      price_3m_l: 0.48,
      price_6m_h: 0.96,
      price_6m_l: 0.48,
      price_52w_h: 1.45,
      price_52w_l: 0.47,
      price_pc_1d: -1.639,
      price_pc_1w: -10.448,
      price_pc_1m: -15.493,
      price_pc_6m: -15.493,
      price_pc_1y: 27.66,
      price_pc_ytd: 27.66,
    },
    2880: {
      id: "2880",
      c: 0.72,
      ma50: 0.734,
      ma100: 0.726,
      ma150: 0.73,
      ma200: 0.735,
      maw30: 0.733,
      rs: 66.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: 1.0,
      price_5d_h: 0.74,
      price_5d_L: 0.71,
      price_1m_h: 0.89,
      price_1m_l: 0.68,
      price_3m_h: 0.89,
      price_3m_l: 0.68,
      price_6m_h: 0.89,
      price_6m_l: 0.68,
      price_52w_h: 0.89,
      price_52w_l: 0.68,
      price_pc_1d: -1.37,
      price_pc_1w: -1.37,
      price_pc_1m: -2.703,
      price_pc_6m: -5.263,
      price_pc_1y: -13.253,
      price_pc_ytd: -13.253,
    },
    2883: {
      id: "2883",
      c: 8.03,
      ma50: 8.259,
      ma100: 7.492,
      ma150: 7.362,
      ma200: 7.092,
      maw30: 7.403,
      rs: 86.0,
      rsd1d: -1.0,
      rsd5d: -3.0,
      rsd10d: -2.0,
      price_5d_h: 8.59,
      price_5d_L: 7.93,
      price_1m_h: 9.37,
      price_1m_l: 6.78,
      price_3m_h: 9.37,
      price_3m_l: 6.73,
      price_6m_h: 9.37,
      price_6m_l: 6.1,
      price_52w_h: 9.37,
      price_52w_l: 5.47,
      price_pc_1d: -0.496,
      price_pc_1w: -2.192,
      price_pc_1m: -9.163,
      price_pc_6m: 8.075,
      price_pc_1y: -0.986,
      price_pc_ytd: -0.986,
    },
    2885: {
      id: "2885",
      c: 0.4,
      ma50: 0.392,
      ma100: 0.406,
      ma150: 0.401,
      ma200: 0.405,
      maw30: 0.403,
      rs: 66.0,
      rsd1d: -1.0,
      rsd5d: 15.0,
      rsd10d: 5.0,
      price_5d_h: 0.405,
      price_5d_L: 0.385,
      price_1m_h: 0.405,
      price_1m_l: 0.325,
      price_3m_h: 0.435,
      price_3m_l: 0.325,
      price_6m_h: 0.495,
      price_6m_l: 0.325,
      price_52w_h: 0.495,
      price_52w_l: 0.325,
      price_pc_1d: -1.235,
      price_pc_1w: 5.263,
      price_pc_1m: 2.564,
      price_pc_6m: 3.896,
      price_pc_1y: -6.977,
      price_pc_ytd: -6.977,
    },
    2886: {
      id: "2886",
      c: 1.59,
      ma50: 1.588,
      ma100: 1.563,
      ma150: 1.602,
      ma200: 1.614,
      maw30: 1.604,
      rs: 76.0,
      rsd1d: -3.0,
      rsd5d: -5.0,
      rsd10d: 2.0,
      price_5d_h: 1.59,
      price_5d_L: 1.55,
      price_1m_h: 1.63,
      price_1m_l: 1.45,
      price_3m_h: 1.75,
      price_3m_l: 1.45,
      price_6m_h: 1.75,
      price_6m_l: 1.41,
      price_52w_h: 1.99,
      price_52w_l: 1.35,
      price_pc_1d: 0.633,
      price_pc_1w: 0.0,
      price_pc_1m: -2.454,
      price_pc_6m: -1.852,
      price_pc_1y: 6.0,
      price_pc_ytd: 6.0,
    },
    2888: {
      id: "2888",
      c: 53.0,
      ma50: 54.474,
      ma100: 50.726,
      ma150: 50.158,
      ma200: 49.827,
      maw30: 50.288,
      rs: 85.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: -1.0,
      price_5d_h: 53.2,
      price_5d_L: 51.7,
      price_1m_h: 56.15,
      price_1m_l: 46.3,
      price_3m_h: 62.0,
      price_3m_l: 46.2,
      price_6m_h: 62.0,
      price_6m_l: 42.8,
      price_52w_h: 62.0,
      price_52w_l: 42.8,
      price_pc_1d: 0.856,
      price_pc_1w: 1.145,
      price_pc_1m: -6.36,
      price_pc_6m: 16.101,
      price_pc_1y: -0.188,
      price_pc_ytd: -0.188,
    },
    2899: {
      id: "2899",
      c: 12.06,
      ma50: 11.281,
      ma100: 10.622,
      ma150: 10.632,
      ma200: 10.662,
      maw30: 10.62,
      rs: 91.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: 4.0,
      price_5d_h: 12.54,
      price_5d_L: 11.84,
      price_1m_h: 12.86,
      price_1m_l: 9.39,
      price_3m_h: 12.86,
      price_3m_l: 8.36,
      price_6m_h: 12.86,
      price_6m_l: 8.36,
      price_52w_h: 13.3,
      price_52w_l: 8.36,
      price_pc_1d: -0.331,
      price_pc_1w: -1.631,
      price_pc_1m: 4.145,
      price_pc_6m: 25.364,
      price_pc_1y: 22.561,
      price_pc_ytd: 22.561,
    },
    2920: {
      id: "2920",
      c: 0.335,
      ma50: null,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 92.0,
      rsd1d: 5.0,
      rsd5d: null,
      rsd10d: null,
      price_5d_h: 0.345,
      price_5d_L: 0.245,
      price_1m_h: 0.345,
      price_1m_l: 0.245,
      price_3m_h: 0.345,
      price_3m_l: 0.245,
      price_6m_h: 0.345,
      price_6m_l: 0.245,
      price_52w_h: 0.345,
      price_52w_l: 0.245,
      price_pc_1d: 15.517,
      price_pc_1w: 28.846,
      price_pc_1m: 28.846,
      price_pc_6m: 28.846,
      price_pc_1y: 28.846,
      price_pc_ytd: 28.846,
    },
    2922: {
      id: "2922",
      c: 0.175,
      ma50: null,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 64.0,
      rsd1d: 6.0,
      rsd5d: null,
      rsd10d: null,
      price_5d_h: 0.182,
      price_5d_L: 0.156,
      price_1m_h: 0.182,
      price_1m_l: 0.156,
      price_3m_h: 0.182,
      price_3m_l: 0.156,
      price_6m_h: 0.182,
      price_6m_l: 0.156,
      price_52w_h: 0.182,
      price_52w_l: 0.156,
      price_pc_1d: 2.339,
      price_pc_1w: -5.914,
      price_pc_1m: -5.914,
      price_pc_6m: -5.914,
      price_pc_1y: -5.914,
      price_pc_ytd: -5.914,
    },
    2923: {
      id: "2923",
      c: 0.64,
      ma50: null,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 74.0,
      rsd1d: 0.0,
      rsd5d: null,
      rsd10d: null,
      price_5d_h: 0.8,
      price_5d_L: 0.63,
      price_1m_h: 0.8,
      price_1m_l: 0.63,
      price_3m_h: 0.8,
      price_3m_l: 0.63,
      price_6m_h: 0.8,
      price_6m_l: 0.63,
      price_52w_h: 0.8,
      price_52w_l: 0.63,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: 0.0,
      price_pc_6m: 0.0,
      price_pc_1y: 0.0,
      price_pc_ytd: 0.0,
    },
    2925: {
      id: "2925",
      c: 35.95,
      ma50: null,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 74.0,
      rsd1d: null,
      rsd5d: null,
      rsd10d: null,
      price_5d_h: 36.2,
      price_5d_L: 34.3,
      price_1m_h: 36.2,
      price_1m_l: 34.3,
      price_3m_h: 36.2,
      price_3m_l: 34.3,
      price_6m_h: 36.2,
      price_6m_l: 34.3,
      price_52w_h: 36.2,
      price_52w_l: 34.3,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: 0.0,
      price_pc_6m: 0.0,
      price_pc_1y: 0.0,
      price_pc_ytd: 0.0,
    },
    3001: {
      id: "3001",
      c: 191.4,
      ma50: 198.355,
      ma100: 232.794,
      ma150: 263.836,
      ma200: 291.342,
      maw30: 262.963,
      rs: 13.0,
      rsd1d: 3.0,
      rsd5d: 6.0,
      rsd10d: 4.0,
      price_5d_h: 191.55,
      price_5d_L: 158.45,
      price_1m_h: 199.0,
      price_1m_l: 132.0,
      price_3m_h: 266.6,
      price_3m_l: 132.0,
      price_6m_h: 333.0,
      price_6m_l: 132.0,
      price_52w_h: 392.1,
      price_52w_l: 132.0,
      price_pc_1d: 4.992,
      price_pc_1w: 18.148,
      price_pc_1m: -3.089,
      price_pc_6m: -42.66,
      price_pc_1y: -49.915,
      price_pc_ytd: -49.915,
    },
    3003: {
      id: "3003",
      c: 6.53,
      ma50: 6.888,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 48.0,
      rsd1d: 2.0,
      rsd5d: 3.0,
      rsd10d: -2.0,
      price_5d_h: 6.555,
      price_5d_L: 6.25,
      price_1m_h: 7.8,
      price_1m_l: 5.975,
      price_3m_h: 7.8,
      price_3m_l: 5.975,
      price_6m_h: 8.0,
      price_6m_l: 5.975,
      price_52w_h: 8.0,
      price_52w_l: 5.975,
      price_pc_1d: -0.305,
      price_pc_1w: -0.229,
      price_pc_1m: -8.351,
      price_pc_6m: -16.921,
      price_pc_1y: -16.921,
      price_pc_ytd: -16.921,
    },
    3005: {
      id: "3005",
      c: 20.96,
      ma50: 22.109,
      ma100: 23.115,
      ma150: 23.364,
      ma200: 23.152,
      maw30: 23.407,
      rs: 58.0,
      rsd1d: 2.0,
      rsd5d: 1.0,
      rsd10d: 0.0,
      price_5d_h: 21.1,
      price_5d_L: 20.3,
      price_1m_h: 23.0,
      price_1m_l: 19.35,
      price_3m_h: 24.52,
      price_3m_l: 19.35,
      price_6m_h: 24.74,
      price_6m_l: 19.35,
      price_52w_h: 25.46,
      price_52w_l: 19.35,
      price_pc_1d: 0.0,
      price_pc_1w: -0.851,
      price_pc_1m: -9.811,
      price_pc_6m: -11.186,
      price_pc_1y: 3.048,
      price_pc_ytd: 3.048,
    },
    3006: {
      id: "3006",
      c: 65.36,
      ma50: null,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 79.0,
      rsd1d: -1.0,
      rsd5d: 3.0,
      rsd10d: null,
      price_5d_h: 65.66,
      price_5d_L: 64.5,
      price_1m_h: 65.66,
      price_1m_l: 63.6,
      price_3m_h: 65.66,
      price_3m_l: 63.6,
      price_6m_h: 65.66,
      price_6m_l: 63.6,
      price_52w_h: 65.66,
      price_52w_l: 63.6,
      price_pc_1d: -0.457,
      price_pc_1w: 2.125,
      price_pc_1m: 2.767,
      price_pc_6m: 2.767,
      price_pc_1y: 2.767,
      price_pc_ytd: 2.767,
    },
    3007: {
      id: "3007",
      c: 249.6,
      ma50: 261.805,
      ma100: 273.621,
      ma150: 282.358,
      ma200: 291.465,
      maw30: 283.177,
      rs: 40.0,
      rsd1d: 1.0,
      rsd5d: 2.0,
      rsd10d: 4.0,
      price_5d_h: 249.7,
      price_5d_L: 240.2,
      price_1m_h: 262.9,
      price_1m_l: 198.15,
      price_3m_h: 291.8,
      price_3m_l: 198.15,
      price_6m_h: 313.7,
      price_6m_l: 198.15,
      price_52w_h: 348.9,
      price_52w_l: 198.15,
      price_pc_1d: -0.04,
      price_pc_1w: 0.524,
      price_pc_1m: -4.587,
      price_pc_6m: -14.138,
      price_pc_1y: -28.44,
      price_pc_ytd: -28.44,
    },
    3010: {
      id: "3010",
      c: 57.14,
      ma50: 58.472,
      ma100: 60.386,
      ma150: 61.857,
      ma200: 63.126,
      maw30: 61.977,
      rs: 52.0,
      rsd1d: 0.0,
      rsd5d: 3.0,
      rsd10d: 0.0,
      price_5d_h: 62.5,
      price_5d_L: 55.5,
      price_1m_h: 62.5,
      price_1m_l: 51.0,
      price_3m_h: 63.84,
      price_3m_l: 51.0,
      price_6m_h: 66.0,
      price_6m_l: 51.0,
      price_52w_h: 72.0,
      price_52w_l: 51.0,
      price_pc_1d: -0.764,
      price_pc_1w: 0.634,
      price_pc_1m: -2.057,
      price_pc_6m: -10.157,
      price_pc_1y: -16.584,
      price_pc_ytd: -16.584,
    },
    3011: {
      id: "3011",
      c: 8070.0,
      ma50: 8047.04,
      ma100: 8037.79,
      ma150: 8028.24,
      ma200: 8019.93,
      maw30: 8027.42,
      rs: 75.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: -4.0,
      price_5d_h: 8070.0,
      price_5d_L: 8066.25,
      price_1m_h: 8071.55,
      price_1m_l: 8050.65,
      price_3m_h: 8071.55,
      price_3m_l: 8024.1,
      price_6m_h: 8071.55,
      price_6m_l: 8006.95,
      price_52w_h: 8071.55,
      price_52w_l: 7973.75,
      price_pc_1d: 0.028,
      price_pc_1w: 0.025,
      price_pc_1m: 0.245,
      price_pc_6m: 0.7,
      price_pc_1y: 1.047,
      price_pc_ytd: 1.047,
    },
    3012: {
      id: "3012",
      c: 19.47,
      ma50: 19.729,
      ma100: 20.18,
      ma150: 20.623,
      ma200: 21.163,
      maw30: 20.654,
      rs: 57.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: 5.0,
      price_5d_h: 19.61,
      price_5d_L: 19.29,
      price_1m_h: 19.61,
      price_1m_l: 17.23,
      price_3m_h: 21.12,
      price_3m_l: 17.23,
      price_6m_h: 21.64,
      price_6m_l: 17.23,
      price_52w_h: 24.14,
      price_52w_l: 17.23,
      price_pc_1d: -0.714,
      price_pc_1w: 0.309,
      price_pc_1m: 0.103,
      price_pc_6m: -7.286,
      price_pc_1y: -14.455,
      price_pc_ytd: -14.455,
    },
    3015: {
      id: "3015",
      c: 1743.5,
      ma50: 1707.81,
      ma100: 1739.44,
      ma150: 1758.08,
      ma200: 1723.07,
      maw30: 1762.82,
      rs: 80.0,
      rsd1d: -1.0,
      rsd5d: 1.0,
      rsd10d: -2.0,
      price_5d_h: 1743.5,
      price_5d_L: 1711.0,
      price_1m_h: 1743.5,
      price_1m_l: 1542.0,
      price_3m_h: 1851.0,
      price_3m_l: 1542.0,
      price_6m_h: 1862.0,
      price_6m_l: 1542.0,
      price_52w_h: 1862.0,
      price_52w_l: 1420.0,
      price_pc_1d: 0.316,
      price_pc_1w: 1.751,
      price_pc_1m: 5.411,
      price_pc_6m: -3.434,
      price_pc_1y: 14.969,
      price_pc_ytd: 14.969,
    },
    3020: {
      id: "3020",
      c: 1038.0,
      ma50: 995.282,
      ma100: 1024.07,
      ma150: 1020.86,
      ma200: 1012.8,
      maw30: 1021.79,
      rs: 81.0,
      rsd1d: -1.0,
      rsd5d: 3.0,
      rsd10d: 5.0,
      price_5d_h: 1048.0,
      price_5d_L: 1016.5,
      price_1m_h: 1048.0,
      price_1m_l: 941.8,
      price_3m_h: 1082.5,
      price_3m_l: 932.6,
      price_6m_h: 1082.5,
      price_6m_l: 932.6,
      price_52w_h: 1082.5,
      price_52w_l: 879.2,
      price_pc_1d: -0.432,
      price_pc_1w: 2.874,
      price_pc_1m: 6.374,
      price_pc_6m: 4.743,
      price_pc_1y: 18.062,
      price_pc_ytd: 18.062,
    },
    3021: {
      id: "3021",
      c: 7.25,
      ma50: 7.375,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 58.0,
      rsd1d: 2.0,
      rsd5d: 3.0,
      rsd10d: -1.0,
      price_5d_h: 7.25,
      price_5d_L: 7.1,
      price_1m_h: 7.465,
      price_1m_l: 6.92,
      price_3m_h: 8.045,
      price_3m_l: 6.92,
      price_6m_h: 8.045,
      price_6m_l: 6.92,
      price_52w_h: 8.045,
      price_52w_l: 6.92,
      price_pc_1d: -0.275,
      price_pc_1w: 0.0,
      price_pc_1m: -1.495,
      price_pc_6m: -8.862,
      price_pc_1y: -8.862,
      price_pc_ytd: -8.862,
    },
    3022: {
      id: "3022",
      c: 517.6,
      ma50: 528.204,
      ma100: 543.292,
      ma150: 556.528,
      ma200: 566.207,
      maw30: 557.353,
      rs: 55.0,
      rsd1d: 1.0,
      rsd5d: 2.0,
      rsd10d: 4.0,
      price_5d_h: 521.4,
      price_5d_L: 510.0,
      price_1m_h: 533.8,
      price_1m_l: 464.0,
      price_3m_h: 569.4,
      price_3m_l: 464.0,
      price_6m_h: 593.8,
      price_6m_l: 464.0,
      price_52w_h: 624.8,
      price_52w_l: 464.0,
      price_pc_1d: -0.653,
      price_pc_1w: 0.194,
      price_pc_1m: -1.334,
      price_pc_6m: -9.415,
      price_pc_1y: -13.56,
      price_pc_ytd: -13.56,
    },
    3023: {
      id: "3023",
      c: 126.75,
      ma50: 123.832,
      ma100: 133.878,
      ma150: 137.36,
      ma200: 138.05,
      maw30: 137.443,
      rs: 55.0,
      rsd1d: 1.0,
      rsd5d: 9.0,
      rsd10d: 10.0,
      price_5d_h: 128.45,
      price_5d_L: 123.25,
      price_1m_h: 128.45,
      price_1m_l: 112.45,
      price_3m_h: 145.2,
      price_3m_l: 112.45,
      price_6m_h: 150.3,
      price_6m_l: 112.45,
      price_52w_h: 150.3,
      price_52w_l: 112.45,
      price_pc_1d: -1.015,
      price_pc_1w: 2.218,
      price_pc_1m: 3.723,
      price_pc_6m: -10.17,
      price_pc_1y: -7.313,
      price_pc_ytd: -7.313,
    },
    3024: {
      id: "3024",
      c: 29.62,
      ma50: 31.088,
      ma100: 32.156,
      ma150: 32.217,
      ma200: 32.505,
      maw30: 32.352,
      rs: 51.0,
      rsd1d: 3.0,
      rsd5d: 2.0,
      rsd10d: 3.0,
      price_5d_h: 29.62,
      price_5d_L: 29.12,
      price_1m_h: 31.96,
      price_1m_l: 27.22,
      price_3m_h: 33.56,
      price_3m_l: 27.22,
      price_6m_h: 34.48,
      price_6m_l: 27.22,
      price_52w_h: 37.0,
      price_52w_l: 27.22,
      price_pc_1d: 0.0,
      price_pc_1w: 0.271,
      price_pc_1m: -6.738,
      price_pc_6m: -7.437,
      price_pc_1y: -15.709,
      price_pc_ytd: -15.709,
    },
    3029: {
      id: "3029",
      c: 4.54,
      ma50: null,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 78.0,
      rsd1d: -3.0,
      rsd5d: -2.0,
      rsd10d: null,
      price_5d_h: 4.606,
      price_5d_L: 4.418,
      price_1m_h: 4.606,
      price_1m_l: 4.418,
      price_3m_h: 4.606,
      price_3m_l: 4.418,
      price_6m_h: 4.606,
      price_6m_l: 4.418,
      price_52w_h: 4.606,
      price_52w_l: 4.418,
      price_pc_1d: -1.433,
      price_pc_1w: -0.395,
      price_pc_1m: 2.715,
      price_pc_6m: 2.715,
      price_pc_1y: 2.715,
      price_pc_ytd: 2.715,
    },
    3031: {
      id: "3031",
      c: 11.39,
      ma50: 11.965,
      ma100: 12.546,
      ma150: 12.68,
      ma200: 12.775,
      maw30: 12.722,
      rs: 51.0,
      rsd1d: 3.0,
      rsd5d: 1.0,
      rsd10d: -1.0,
      price_5d_h: 11.39,
      price_5d_L: 11.09,
      price_1m_h: 12.39,
      price_1m_l: 10.57,
      price_3m_h: 13.21,
      price_3m_l: 10.57,
      price_6m_h: 13.65,
      price_6m_l: 10.57,
      price_52w_h: 13.72,
      price_52w_l: 10.57,
      price_pc_1d: 0.0,
      price_pc_1w: -0.175,
      price_pc_1m: -7.549,
      price_pc_6m: -11.154,
      price_pc_1y: -8.88,
      price_pc_ytd: -8.88,
    },
    3032: {
      id: "3032",
      c: 4.52,
      ma50: 4.994,
      ma100: 5.453,
      ma150: 5.758,
      ma200: 6.113,
      maw30: 5.768,
      rs: 21.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: -1.0,
      price_5d_h: 4.668,
      price_5d_L: 4.26,
      price_1m_h: 5.145,
      price_1m_l: 3.432,
      price_3m_h: 5.9,
      price_3m_l: 3.432,
      price_6m_h: 6.75,
      price_6m_l: 3.432,
      price_52w_h: 8.62,
      price_52w_l: 3.432,
      price_pc_1d: -1.396,
      price_pc_1w: -0.747,
      price_pc_1m: -10.848,
      price_pc_6m: -25.166,
      price_pc_1y: -46.917,
      price_pc_ytd: -46.917,
    },
    3033: {
      id: "3033",
      c: 4.48,
      ma50: 4.968,
      ma100: 5.424,
      ma150: 5.731,
      ma200: 6.087,
      maw30: 5.742,
      rs: 21.0,
      rsd1d: -1.0,
      rsd5d: 1.0,
      rsd10d: 0.0,
      price_5d_h: 4.648,
      price_5d_L: 4.238,
      price_1m_h: 5.12,
      price_1m_l: 3.408,
      price_3m_h: 5.86,
      price_3m_l: 3.408,
      price_6m_h: 6.73,
      price_6m_l: 3.408,
      price_52w_h: 8.59,
      price_52w_l: 3.408,
      price_pc_1d: -2.566,
      price_pc_1w: -1.147,
      price_pc_1m: -11.111,
      price_pc_6m: -25.705,
      price_pc_1y: -47.139,
      price_pc_ytd: -47.139,
    },
    3034: {
      id: "3034",
      c: 8.03,
      ma50: null,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 77.0,
      rsd1d: -2.0,
      rsd5d: 8.0,
      rsd10d: 12.0,
      price_5d_h: 8.16,
      price_5d_L: 7.75,
      price_1m_h: 8.16,
      price_1m_l: 6.62,
      price_3m_h: 8.2,
      price_3m_l: 6.62,
      price_6m_h: 8.2,
      price_6m_l: 6.62,
      price_52w_h: 8.2,
      price_52w_l: 6.62,
      price_pc_1d: -0.864,
      price_pc_1w: 3.546,
      price_pc_1m: 2.228,
      price_pc_6m: 1.517,
      price_pc_1y: 1.517,
      price_pc_ytd: 1.517,
    },
    3036: {
      id: "3036",
      c: 436.0,
      ma50: 444.944,
      ma100: 450.281,
      ma150: 445.175,
      ma200: 443.053,
      maw30: 446.197,
      rs: 71.0,
      rsd1d: 1.0,
      rsd5d: -2.0,
      rsd10d: -2.0,
      price_5d_h: 437.5,
      price_5d_L: 429.0,
      price_1m_h: 451.0,
      price_1m_l: 410.0,
      price_3m_h: 476.0,
      price_3m_l: 410.0,
      price_6m_h: 476.0,
      price_6m_l: 410.0,
      price_52w_h: 476.0,
      price_52w_l: 390.0,
      price_pc_1d: -0.137,
      price_pc_1w: -0.343,
      price_pc_1m: -1.625,
      price_pc_6m: 0.833,
      price_pc_1y: 4.934,
      price_pc_ytd: 4.934,
    },
    3037: {
      id: "3037",
      c: 22.58,
      ma50: 23.34,
      ma100: 23.896,
      ma150: 24.492,
      ma200: 25.236,
      maw30: 24.532,
      rs: 49.0,
      rsd1d: 0.0,
      rsd5d: 3.0,
      rsd10d: 2.0,
      price_5d_h: 22.8,
      price_5d_L: 21.78,
      price_1m_h: 23.34,
      price_1m_l: 18.71,
      price_3m_h: 25.4,
      price_3m_l: 18.71,
      price_6m_h: 26.66,
      price_6m_l: 18.71,
      price_52w_h: 29.62,
      price_52w_l: 18.71,
      price_pc_1d: -0.791,
      price_pc_1w: 0.714,
      price_pc_1m: -2.42,
      price_pc_6m: -9.463,
      price_pc_1y: -21.049,
      price_pc_ytd: -21.049,
    },
    3040: {
      id: "3040",
      c: 30.0,
      ma50: 31.885,
      ma100: 33.768,
      ma150: 35.189,
      ma200: 36.67,
      maw30: 35.267,
      rs: 33.0,
      rsd1d: -1.0,
      rsd5d: 2.0,
      rsd10d: 1.0,
      price_5d_h: 30.48,
      price_5d_L: 28.84,
      price_1m_h: 32.78,
      price_1m_l: 24.08,
      price_3m_h: 35.96,
      price_3m_l: 24.08,
      price_6m_h: 39.98,
      price_6m_l: 24.08,
      price_52w_h: 45.68,
      price_52w_l: 24.08,
      price_pc_1d: -1.575,
      price_pc_1w: -0.596,
      price_pc_1m: -7.063,
      price_pc_6m: -18.655,
      price_pc_1y: -33.599,
      price_pc_ytd: -33.599,
    },
    3047: {
      id: "3047",
      c: 16.18,
      ma50: 14.349,
      ma100: 13.117,
      ma150: 13.247,
      ma200: 14.575,
      maw30: 13.178,
      rs: 86.0,
      rsd1d: -2.0,
      rsd5d: 6.0,
      rsd10d: 1.0,
      price_5d_h: 16.42,
      price_5d_L: 15.23,
      price_1m_h: 16.42,
      price_1m_l: 12.86,
      price_3m_h: 16.42,
      price_3m_l: 12.19,
      price_6m_h: 16.42,
      price_6m_l: 9.9,
      price_52w_h: 23.08,
      price_52w_l: 9.9,
      price_pc_1d: -0.736,
      price_pc_1w: 8.518,
      price_pc_1m: 23.985,
      price_pc_6m: 18.796,
      price_pc_1y: -0.797,
      price_pc_ytd: -0.797,
    },
    3050: {
      id: "3050",
      c: 52.0,
      ma50: 56.246,
      ma100: 60.797,
      ma150: 61.59,
      ma200: 62.809,
      maw30: 61.681,
      rs: 38.0,
      rsd1d: -1.0,
      rsd5d: -4.0,
      rsd10d: -8.0,
      price_5d_h: 53.24,
      price_5d_L: 50.86,
      price_1m_h: 58.4,
      price_1m_l: 46.78,
      price_3m_h: 65.9,
      price_3m_l: 46.78,
      price_6m_h: 68.6,
      price_6m_l: 46.78,
      price_52w_h: 69.42,
      price_52w_l: 46.78,
      price_pc_1d: -2.292,
      price_pc_1w: -3.094,
      price_pc_1m: -10.714,
      price_pc_6m: -15.005,
      price_pc_1y: -14.977,
      price_pc_ytd: -14.977,
    },
    3051: {
      id: "3051",
      c: 46.94,
      ma50: 49.644,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 36.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: -2.0,
      price_5d_h: 47.12,
      price_5d_L: 45.92,
      price_1m_h: 51.22,
      price_1m_l: 41.68,
      price_3m_h: 58.36,
      price_3m_l: 41.68,
      price_6m_h: 62.26,
      price_6m_l: 41.68,
      price_52w_h: 62.26,
      price_52w_l: 41.68,
      price_pc_1d: -0.382,
      price_pc_1w: -1.345,
      price_pc_1m: -7.489,
      price_pc_6m: -23.65,
      price_pc_1y: -23.65,
      price_pc_ytd: -23.65,
    },
    3053: {
      id: "3053",
      c: 1034.5,
      ma50: 1034.08,
      ma100: 1033.82,
      ma150: 1033.9,
      ma200: 1033.94,
      maw30: 1033.88,
      rs: 74.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: -4.0,
      price_5d_h: 1034.5,
      price_5d_L: 1034.5,
      price_1m_h: 1034.95,
      price_1m_l: 1034.0,
      price_3m_h: 1034.95,
      price_3m_l: 1033.5,
      price_6m_h: 1034.95,
      price_6m_l: 1033.4,
      price_52w_h: 1034.95,
      price_52w_l: 1033.35,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: 0.048,
      price_pc_6m: 0.029,
      price_pc_1y: 0.111,
      price_pc_ytd: 0.111,
    },
    3058: {
      id: "3058",
      c: 42.08,
      ma50: 45.918,
      ma100: 50.48,
      ma150: 52.383,
      ma200: 54.765,
      maw30: 52.439,
      rs: 26.0,
      rsd1d: 1.0,
      rsd5d: -1.0,
      rsd10d: -4.0,
      price_5d_h: 42.88,
      price_5d_L: 40.72,
      price_1m_h: 47.64,
      price_1m_l: 36.32,
      price_3m_h: 54.14,
      price_3m_l: 36.32,
      price_6m_h: 58.98,
      price_6m_l: 36.32,
      price_52w_h: 71.0,
      price_52w_l: 36.32,
      price_pc_1d: -1.313,
      price_pc_1w: -2.683,
      price_pc_1m: -11.485,
      price_pc_6m: -23.574,
      price_pc_1y: -31.332,
      price_pc_ytd: -31.332,
    },
    3060: {
      id: "3060",
      c: 69.5,
      ma50: null,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 73.0,
      rsd1d: 0.0,
      rsd5d: 8.0,
      rsd10d: null,
      price_5d_h: 71.7,
      price_5d_L: 67.5,
      price_1m_h: 71.7,
      price_1m_l: 67.16,
      price_3m_h: 71.7,
      price_3m_l: 67.16,
      price_6m_h: 71.7,
      price_6m_l: 67.16,
      price_52w_h: 71.7,
      price_52w_l: 67.16,
      price_pc_1d: -0.401,
      price_pc_1w: 3.208,
      price_pc_1m: -0.828,
      price_pc_6m: -0.828,
      price_pc_1y: -0.828,
      price_pc_ytd: -0.828,
    },
    3067: {
      id: "3067",
      c: 9.525,
      ma50: 10.511,
      ma100: 11.466,
      ma150: 12.105,
      ma200: 12.85,
      maw30: 12.132,
      rs: 21.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: -1.0,
      price_5d_h: 9.83,
      price_5d_L: 8.97,
      price_1m_h: 10.83,
      price_1m_l: 7.22,
      price_3m_h: 12.39,
      price_3m_l: 7.22,
      price_6m_h: 14.19,
      price_6m_l: 7.22,
      price_52w_h: 18.06,
      price_52w_l: 7.22,
      price_pc_1d: -1.499,
      price_pc_1w: -0.781,
      price_pc_1m: -10.731,
      price_pc_6m: -25.0,
      price_pc_1y: -46.489,
      price_pc_ytd: -46.489,
    },
    3069: {
      id: "3069",
      c: 13.11,
      ma50: 13.846,
      ma100: 15.799,
      ma150: 17.521,
      ma200: 19.481,
      maw30: 17.522,
      rs: 17.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: 3.0,
      price_5d_h: 13.77,
      price_5d_L: 12.6,
      price_1m_h: 14.6,
      price_1m_l: 10.0,
      price_3m_h: 16.83,
      price_3m_l: 10.0,
      price_6m_h: 21.38,
      price_6m_l: 10.0,
      price_52w_h: 29.5,
      price_52w_l: 10.0,
      price_pc_1d: -3.815,
      price_pc_1w: -5.0,
      price_pc_1m: -7.935,
      price_pc_6m: -38.966,
      price_pc_1y: -45.96,
      price_pc_ytd: -45.96,
    },
    3070: {
      id: "3070",
      c: 27.02,
      ma50: 26.166,
      ma100: 25.311,
      ma150: 25.347,
      ma200: 25.421,
      maw30: 25.366,
      rs: 83.0,
      rsd1d: 0.0,
      rsd5d: 3.0,
      rsd10d: 8.0,
      price_5d_h: 27.2,
      price_5d_L: 26.06,
      price_1m_h: 27.2,
      price_1m_l: 23.3,
      price_3m_h: 27.6,
      price_3m_l: 23.3,
      price_6m_h: 27.6,
      price_6m_l: 23.3,
      price_52w_h: 27.96,
      price_52w_l: 23.3,
      price_pc_1d: 0.223,
      price_pc_1w: 3.525,
      price_pc_1m: 3.446,
      price_pc_6m: 5.712,
      price_pc_1y: -1.243,
      price_pc_ytd: -1.243,
    },
    3071: {
      id: "3071",
      c: 1002.7,
      ma50: 1002.14,
      ma100: 1001.87,
      ma150: 1001.73,
      ma200: 1001.59,
      maw30: 1001.72,
      rs: 74.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: -4.0,
      price_5d_h: 1002.95,
      price_5d_L: 1002.35,
      price_1m_h: 1002.95,
      price_1m_l: 1002.15,
      price_3m_h: 1002.95,
      price_3m_l: 1001.6,
      price_6m_h: 1002.95,
      price_6m_l: 1001.5,
      price_52w_h: 1002.95,
      price_52w_l: 1001.0,
      price_pc_1d: 0.0,
      price_pc_1w: 0.035,
      price_pc_1m: 0.055,
      price_pc_6m: 0.12,
      price_pc_1y: 0.13,
      price_pc_ytd: 0.13,
    },
    3072: {
      id: "3072",
      c: 118.9,
      ma50: 118.857,
      ma100: 135.768,
      ma150: 145.713,
      ma200: 150.519,
      maw30: 145.617,
      rs: 30.0,
      rsd1d: 0.0,
      rsd5d: 5.0,
      rsd10d: 4.0,
      price_5d_h: 121.15,
      price_5d_L: 115.8,
      price_1m_h: 121.15,
      price_1m_l: 95.0,
      price_3m_h: 149.0,
      price_3m_l: 95.0,
      price_6m_h: 171.7,
      price_6m_l: 95.0,
      price_52w_h: 173.1,
      price_52w_l: 95.0,
      price_pc_1d: -1.328,
      price_pc_1w: 1.321,
      price_pc_1m: 2.456,
      price_pc_6m: -25.102,
      price_pc_1y: -24.556,
      price_pc_ytd: -24.556,
    },
    3073: {
      id: "3073",
      c: 46.18,
      ma50: 48.332,
      ma100: 49.899,
      ma150: 50.768,
      ma200: 51.918,
      maw30: 50.879,
      rs: 48.0,
      rsd1d: -1.0,
      rsd5d: 1.0,
      rsd10d: 1.0,
      price_5d_h: 46.64,
      price_5d_L: 44.68,
      price_1m_h: 48.98,
      price_1m_l: 40.18,
      price_3m_h: 52.04,
      price_3m_l: 40.18,
      price_6m_h: 53.76,
      price_6m_l: 40.18,
      price_52w_h: 59.2,
      price_52w_l: 40.18,
      price_pc_1d: -0.859,
      price_pc_1w: 0.0,
      price_pc_1m: -4.823,
      price_pc_6m: -10.016,
      price_pc_1y: -18.468,
      price_pc_ytd: -18.468,
    },
    3074: {
      id: "3074",
      c: 195.9,
      ma50: 200.016,
      ma100: 202.1,
      ma150: 200.727,
      ma200: 199.939,
      maw30: 201.003,
      rs: 71.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: -3.0,
      price_5d_h: 197.3,
      price_5d_L: 191.1,
      price_1m_h: 203.4,
      price_1m_l: 184.35,
      price_3m_h: 214.7,
      price_3m_l: 184.35,
      price_6m_h: 214.7,
      price_6m_l: 184.35,
      price_52w_h: 214.7,
      price_52w_l: 174.0,
      price_pc_1d: -0.609,
      price_pc_1w: 0.102,
      price_pc_1m: -2.026,
      price_pc_6m: -0.204,
      price_pc_1y: 5.55,
      price_pc_ytd: 5.55,
    },
    3076: {
      id: "3076",
      c: 6.705,
      ma50: 7.086,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 46.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: -5.0,
      price_5d_h: 6.75,
      price_5d_L: 6.55,
      price_1m_h: 7.19,
      price_1m_l: 6.315,
      price_3m_h: 7.98,
      price_3m_l: 6.315,
      price_6m_h: 7.98,
      price_6m_l: 6.315,
      price_52w_h: 7.98,
      price_52w_l: 6.315,
      price_pc_1d: -0.667,
      price_pc_1w: -0.667,
      price_pc_1m: -4.623,
      price_pc_6m: -14.038,
      price_pc_1y: -14.038,
      price_pc_ytd: -14.038,
    },
    3077: {
      id: "3077",
      c: 3919.4,
      ma50: 3908.0,
      ma100: 3903.31,
      ma150: 3899.57,
      ma200: 3896.7,
      maw30: 3899.27,
      rs: 75.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: -4.0,
      price_5d_h: 3919.4,
      price_5d_L: 3917.8,
      price_1m_h: 3919.4,
      price_1m_l: 3910.7,
      price_3m_h: 3919.4,
      price_3m_l: 3895.8,
      price_6m_h: 3919.4,
      price_6m_l: 3889.95,
      price_52w_h: 3919.4,
      price_52w_l: 3880.35,
      price_pc_1d: 0.018,
      price_pc_1w: 0.041,
      price_pc_1m: 0.222,
      price_pc_6m: 0.646,
      price_pc_1y: 0.766,
      price_pc_ytd: 0.766,
    },
    3079: {
      id: "3079",
      c: 140.5,
      ma50: 140.093,
      ma100: 139.02,
      ma150: 137.825,
      ma200: 136.829,
      maw30: 137.897,
      rs: 80.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: -3.0,
      price_5d_h: 140.5,
      price_5d_L: 139.65,
      price_1m_h: 141.0,
      price_1m_l: 139.35,
      price_3m_h: 141.05,
      price_3m_l: 138.45,
      price_6m_h: 141.05,
      price_6m_l: 135.0,
      price_52w_h: 141.05,
      price_52w_l: 130.2,
      price_pc_1d: 0.25,
      price_pc_1w: 0.681,
      price_pc_1m: -0.39,
      price_pc_6m: 4.074,
      price_pc_1y: 8.16,
      price_pc_ytd: 8.16,
    },
    3080: {
      id: "3080",
      c: 135.15,
      ma50: 134.827,
      ma100: 134.635,
      ma150: 134.06,
      ma200: 133.37,
      maw30: 134.083,
      rs: 79.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: -1.0,
      price_5d_h: 135.15,
      price_5d_L: 134.15,
      price_1m_h: 135.55,
      price_1m_l: 133.35,
      price_3m_h: 136.5,
      price_3m_l: 133.35,
      price_6m_h: 136.5,
      price_6m_l: 132.45,
      price_52w_h: 136.5,
      price_52w_l: 127.9,
      price_pc_1d: 0.26,
      price_pc_1w: 0.67,
      price_pc_1m: -0.295,
      price_pc_6m: 1.923,
      price_pc_1y: 5.875,
      price_pc_ytd: 5.875,
    },
    3081: {
      id: "3081",
      c: 46.34,
      ma50: 45.305,
      ma100: 44.259,
      ma150: 43.678,
      ma200: 43.479,
      maw30: 43.631,
      rs: 85.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: -2.0,
      price_5d_h: 47.08,
      price_5d_L: 45.96,
      price_1m_h: 49.28,
      price_1m_l: 44.5,
      price_3m_h: 49.28,
      price_3m_l: 42.64,
      price_6m_h: 49.28,
      price_6m_l: 41.56,
      price_52w_h: 49.28,
      price_52w_l: 41.0,
      price_pc_1d: 0.652,
      price_pc_1w: -0.515,
      price_pc_1m: 2.07,
      price_pc_6m: 12.149,
      price_pc_1y: 12.64,
      price_pc_ytd: 12.64,
    },
    3086: {
      id: "3086",
      c: 30.82,
      ma50: 28.926,
      ma100: 30.787,
      ma150: 30.853,
      ma200: 30.582,
      maw30: 30.843,
      rs: 78.0,
      rsd1d: -1.0,
      rsd5d: 9.0,
      rsd10d: 11.0,
      price_5d_h: 31.0,
      price_5d_L: 29.82,
      price_1m_h: 31.0,
      price_1m_l: 26.38,
      price_3m_h: 33.46,
      price_3m_l: 26.38,
      price_6m_h: 34.88,
      price_6m_l: 26.38,
      price_52w_h: 34.88,
      price_52w_l: 26.08,
      price_pc_1d: -0.13,
      price_pc_1w: 4.475,
      price_pc_1m: 8.674,
      price_pc_6m: 2.665,
      price_pc_1y: 15.604,
      price_pc_ytd: 15.604,
    },
    3087: {
      id: "3087",
      c: 341.3,
      ma50: 344.878,
      ma100: 353.089,
      ma150: 350.125,
      ma200: 346.392,
      maw30: 350.943,
      rs: 76.0,
      rsd1d: -1.0,
      rsd5d: -4.0,
      rsd10d: -2.0,
      price_5d_h: 348.0,
      price_5d_L: 338.1,
      price_1m_h: 350.6,
      price_1m_l: 327.5,
      price_3m_h: 373.1,
      price_3m_l: 327.5,
      price_6m_h: 373.1,
      price_6m_l: 327.5,
      price_52w_h: 373.1,
      price_52w_l: 292.2,
      price_pc_1d: 0.0,
      price_pc_1w: -0.814,
      price_pc_1m: -1.072,
      price_pc_6m: 1.457,
      price_pc_1y: 17.044,
      price_pc_ytd: 17.044,
    },
    3088: {
      id: "3088",
      c: 5.75,
      ma50: 6.341,
      ma100: 6.919,
      ma150: 7.304,
      ma200: 7.753,
      maw30: 7.319,
      rs: 21.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: -1.0,
      price_5d_h: 5.935,
      price_5d_L: 5.42,
      price_1m_h: 6.535,
      price_1m_l: 4.366,
      price_3m_h: 7.46,
      price_3m_l: 4.366,
      price_6m_h: 8.565,
      price_6m_l: 4.366,
      price_52w_h: 11.0,
      price_52w_l: 4.366,
      price_pc_1d: -1.457,
      price_pc_1w: -0.691,
      price_pc_1m: -10.575,
      price_pc_6m: -24.984,
      price_pc_1y: -46.71,
      price_pc_ytd: -46.71,
    },
    3091: {
      id: "3091",
      c: 91.96,
      ma50: 92.467,
      ma100: 99.011,
      ma150: 100.306,
      ma200: 101.147,
      maw30: 100.483,
      rs: 52.0,
      rsd1d: 0.0,
      rsd5d: 5.0,
      rsd10d: 3.0,
      price_5d_h: 97.96,
      price_5d_L: 90.62,
      price_1m_h: 97.96,
      price_1m_l: 81.26,
      price_3m_h: 106.95,
      price_3m_l: 81.26,
      price_6m_h: 111.45,
      price_6m_l: 81.26,
      price_52w_h: 111.45,
      price_52w_l: 81.26,
      price_pc_1d: -1.182,
      price_pc_1w: 0.415,
      price_pc_1m: 0.569,
      price_pc_6m: -8.269,
      price_pc_1y: -10.935,
      price_pc_ytd: -10.935,
    },
    3093: {
      id: "3093",
      c: 13.46,
      ma50: 13.634,
      ma100: 13.548,
      ma150: 13.481,
      ma200: 13.337,
      maw30: 13.503,
      rs: 77.0,
      rsd1d: -1.0,
      rsd5d: 5.0,
      rsd10d: 8.0,
      price_5d_h: 13.46,
      price_5d_L: 12.92,
      price_1m_h: 14.18,
      price_1m_l: 12.05,
      price_3m_h: 14.34,
      price_3m_l: 12.05,
      price_6m_h: 14.34,
      price_6m_l: 12.05,
      price_52w_h: 14.34,
      price_52w_l: 12.05,
      price_pc_1d: 0.0,
      price_pc_1w: 1.585,
      price_pc_1m: -3.165,
      price_pc_6m: 4.26,
      price_pc_1y: 2.748,
      price_pc_ytd: 2.748,
    },
    3096: {
      id: "3096",
      c: 803.75,
      ma50: 801.587,
      ma100: 800.804,
      ma150: 800.07,
      ma200: 799.477,
      maw30: 799.993,
      rs: 75.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: -4.0,
      price_5d_h: 803.75,
      price_5d_L: 803.3,
      price_1m_h: 803.75,
      price_1m_l: 801.85,
      price_3m_h: 803.75,
      price_3m_l: 799.55,
      price_6m_h: 803.75,
      price_6m_l: 798.35,
      price_52w_h: 803.75,
      price_52w_l: 797.3,
      price_pc_1d: 0.025,
      price_pc_1w: 0.068,
      price_pc_1m: 0.237,
      price_pc_6m: 0.626,
      price_pc_1y: 0.872,
      price_pc_ytd: 0.872,
    },
    3097: {
      id: "3097",
      c: 6.11,
      ma50: 5.683,
      ma100: 4.967,
      ma150: 4.709,
      ma200: 4.491,
      maw30: 4.692,
      rs: 95.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: 0.0,
      price_5d_h: 6.685,
      price_5d_L: 5.985,
      price_1m_h: 7.27,
      price_1m_l: 5.495,
      price_3m_h: 7.27,
      price_3m_l: 4.284,
      price_6m_h: 7.27,
      price_6m_l: 3.718,
      price_52w_h: 7.27,
      price_52w_l: 3.198,
      price_pc_1d: -3.323,
      price_pc_1w: -9.948,
      price_pc_1m: 11.192,
      price_pc_6m: 46.523,
      price_pc_1y: 86.054,
      price_pc_ytd: 86.054,
    },
    3100: {
      id: "3100",
      c: 47.68,
      ma50: 52.183,
      ma100: 54.388,
      ma150: 54.89,
      ma200: 55.614,
      maw30: 54.916,
      rs: 41.0,
      rsd1d: 3.0,
      rsd5d: -5.0,
      rsd10d: -6.0,
      price_5d_h: 49.8,
      price_5d_L: 47.68,
      price_1m_h: 54.04,
      price_1m_l: 47.68,
      price_3m_h: 56.6,
      price_3m_l: 47.68,
      price_6m_h: 58.8,
      price_6m_l: 47.68,
      price_52w_h: 62.56,
      price_52w_l: 47.68,
      price_pc_1d: 0.0,
      price_pc_1w: -4.257,
      price_pc_1m: -11.769,
      price_pc_6m: -14.429,
      price_pc_1y: -19.132,
      price_pc_ytd: -19.132,
    },
    3108: {
      id: "3108",
      c: 10.12,
      ma50: 10.715,
      ma100: 11.216,
      ma150: 11.324,
      ma200: 11.438,
      maw30: 11.355,
      rs: 48.0,
      rsd1d: 3.0,
      rsd5d: 0.0,
      rsd10d: -2.0,
      price_5d_h: 10.12,
      price_5d_L: 9.87,
      price_1m_h: 11.02,
      price_1m_l: 9.68,
      price_3m_h: 11.74,
      price_3m_l: 9.68,
      price_6m_h: 12.32,
      price_6m_l: 9.68,
      price_52w_h: 12.48,
      price_52w_l: 9.68,
      price_pc_1d: 0.0,
      price_pc_1w: -0.589,
      price_pc_1m: -7.58,
      price_pc_6m: -12.0,
      price_pc_1y: -12.0,
      price_pc_ytd: -12.0,
    },
    3109: {
      id: "3109",
      c: 12.02,
      ma50: 13.096,
      ma100: 14.216,
      ma150: 14.501,
      ma200: 15.072,
      maw30: 14.503,
      rs: 35.0,
      rsd1d: 0.0,
      rsd5d: -4.0,
      rsd10d: -11.0,
      price_5d_h: 12.41,
      price_5d_L: 11.76,
      price_1m_h: 13.78,
      price_1m_l: 11.75,
      price_3m_h: 15.5,
      price_3m_l: 11.75,
      price_6m_h: 16.33,
      price_6m_l: 11.75,
      price_52w_h: 18.0,
      price_52w_l: 11.75,
      price_pc_1d: -1.151,
      price_pc_1w: -2.672,
      price_pc_1m: -13.213,
      price_pc_6m: -19.275,
      price_pc_1y: -11.357,
      price_pc_ytd: -11.357,
    },
    3110: {
      id: "3110",
      c: 26.04,
      ma50: 26.266,
      ma100: 25.78,
      ma150: 26.06,
      ma200: 26.486,
      maw30: 26.068,
      rs: 68.0,
      rsd1d: 0.0,
      rsd5d: 2.0,
      rsd10d: 4.0,
      price_5d_h: 26.3,
      price_5d_L: 25.46,
      price_1m_h: 26.5,
      price_1m_l: 22.7,
      price_3m_h: 28.08,
      price_3m_l: 22.7,
      price_6m_h: 28.08,
      price_6m_l: 22.7,
      price_52w_h: 30.92,
      price_52w_l: 22.7,
      price_pc_1d: -0.459,
      price_pc_1w: 0.618,
      price_pc_1m: -1.289,
      price_pc_6m: -0.306,
      price_pc_1y: -10.331,
      price_pc_ytd: -10.331,
    },
    3111: {
      id: "3111",
      c: 2.672,
      ma50: 2.812,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 49.0,
      rsd1d: 2.0,
      rsd5d: 4.0,
      rsd10d: -1.0,
      price_5d_h: 2.684,
      price_5d_L: 2.558,
      price_1m_h: 2.928,
      price_1m_l: 2.436,
      price_3m_h: 3.112,
      price_3m_l: 2.436,
      price_6m_h: 3.222,
      price_6m_l: 2.436,
      price_52w_h: 3.222,
      price_52w_l: 2.436,
      price_pc_1d: -0.299,
      price_pc_1w: -0.075,
      price_pc_1m: -8.052,
      price_pc_6m: -16.028,
      price_pc_1y: -16.028,
      price_pc_ytd: -16.028,
    },
    3115: {
      id: "3115",
      c: 79.4,
      ma50: 82.163,
      ma100: 84.117,
      ma150: 86.608,
      ma200: 89.536,
      maw30: 86.741,
      rs: 47.0,
      rsd1d: 0.0,
      rsd5d: 2.0,
      rsd10d: 2.0,
      price_5d_h: 80.48,
      price_5d_L: 76.4,
      price_1m_h: 82.64,
      price_1m_l: 65.9,
      price_3m_h: 90.1,
      price_3m_l: 65.9,
      price_6m_h: 95.02,
      price_6m_l: 65.9,
      price_52w_h: 106.55,
      price_52w_l: 65.9,
      price_pc_1d: -0.651,
      price_pc_1w: 0.634,
      price_pc_1m: -2.577,
      price_pc_6m: -10.706,
      price_pc_1y: -23.654,
      price_pc_ytd: -23.654,
    },
    3117: {
      id: "3117",
      c: 38.02,
      ma50: 40.544,
      ma100: 44.11,
      ma150: 45.824,
      ma200: null,
      maw30: 45.911,
      rs: 39.0,
      rsd1d: 1.0,
      rsd5d: 4.0,
      rsd10d: 5.0,
      price_5d_h: 38.04,
      price_5d_L: 36.24,
      price_1m_h: 41.3,
      price_1m_l: 29.18,
      price_3m_h: 47.3,
      price_3m_l: 29.18,
      price_6m_h: 54.76,
      price_6m_l: 29.18,
      price_52w_h: 59.96,
      price_52w_l: 29.18,
      price_pc_1d: 0.0,
      price_pc_1w: -0.679,
      price_pc_1m: -6.951,
      price_pc_6m: -18.587,
      price_pc_1y: -34.986,
      price_pc_ytd: -34.986,
    },
    3118: {
      id: "3118",
      c: 18.61,
      ma50: 19.615,
      ma100: 20.61,
      ma150: 20.737,
      ma200: 20.851,
      maw30: 20.793,
      rs: 49.0,
      rsd1d: 3.0,
      rsd5d: 1.0,
      rsd10d: 0.0,
      price_5d_h: 18.67,
      price_5d_L: 18.5,
      price_1m_h: 19.95,
      price_1m_l: 17.46,
      price_3m_h: 21.82,
      price_3m_l: 17.46,
      price_6m_h: 22.4,
      price_6m_l: 17.46,
      price_52w_h: 22.46,
      price_52w_l: 17.46,
      price_pc_1d: 0.0,
      price_pc_1w: -0.321,
      price_pc_1m: -6.717,
      price_pc_6m: -11.884,
      price_pc_1y: -9.748,
      price_pc_ytd: -9.748,
    },
    3119: {
      id: "3119",
      c: 55.48,
      ma50: 57.857,
      ma100: 60.858,
      ma150: 60.722,
      ma200: null,
      maw30: 60.812,
      rs: 56.0,
      rsd1d: 2.0,
      rsd5d: 2.0,
      rsd10d: -5.0,
      price_5d_h: 57.0,
      price_5d_L: 55.12,
      price_1m_h: 60.2,
      price_1m_l: 52.42,
      price_3m_h: 66.1,
      price_3m_l: 52.42,
      price_6m_h: 66.66,
      price_6m_l: 52.42,
      price_52w_h: 66.7,
      price_52w_l: 52.42,
      price_pc_1d: -0.858,
      price_pc_1w: -2.324,
      price_pc_1m: -5.774,
      price_pc_6m: -6.473,
      price_pc_1y: -9.642,
      price_pc_ytd: -9.642,
    },
    3121: {
      id: "3121",
      c: 8.855,
      ma50: 8.839,
      ma100: 8.943,
      ma150: 9.092,
      ma200: 9.274,
      maw30: 9.101,
      rs: 62.0,
      rsd1d: 2.0,
      rsd5d: 2.0,
      rsd10d: 2.0,
      price_5d_h: 8.855,
      price_5d_L: 8.76,
      price_1m_h: 8.855,
      price_1m_l: 8.0,
      price_3m_h: 9.2,
      price_3m_l: 8.0,
      price_6m_h: 9.6,
      price_6m_l: 8.0,
      price_52w_h: 10.44,
      price_52w_l: 8.0,
      price_pc_1d: 0.0,
      price_pc_1w: 0.682,
      price_pc_1m: 0.625,
      price_pc_6m: -5.142,
      price_pc_1y: -12.327,
      price_pc_ytd: -12.327,
    },
    3122: {
      id: "3122",
      c: 194.85,
      ma50: 194.125,
      ma100: 193.195,
      ma150: 191.933,
      ma200: 190.802,
      maw30: 192.023,
      rs: 79.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: -3.0,
      price_5d_h: 194.85,
      price_5d_L: 193.9,
      price_1m_h: 195.7,
      price_1m_l: 193.5,
      price_3m_h: 195.7,
      price_3m_l: 192.3,
      price_6m_h: 195.7,
      price_6m_l: 188.85,
      price_52w_h: 195.7,
      price_52w_l: 184.6,
      price_pc_1d: 0.231,
      price_pc_1w: 0.594,
      price_pc_1m: -0.205,
      price_pc_6m: 3.314,
      price_pc_1y: 5.495,
      price_pc_ytd: 5.495,
    },
    3124: {
      id: "3124",
      c: 47.38,
      ma50: 50.12,
      ma100: 52.45,
      ma150: 54.812,
      ma200: null,
      maw30: 54.98,
      rs: 43.0,
      rsd1d: 0.0,
      rsd5d: 5.0,
      rsd10d: 2.0,
      price_5d_h: 47.5,
      price_5d_L: 45.14,
      price_1m_h: 51.8,
      price_1m_l: 39.86,
      price_3m_h: 55.1,
      price_3m_l: 39.86,
      price_6m_h: 60.98,
      price_6m_l: 39.86,
      price_52w_h: 64.2,
      price_52w_l: 39.86,
      price_pc_1d: -0.253,
      price_pc_1w: -1.374,
      price_pc_1m: -7.171,
      price_pc_6m: -18.898,
      price_pc_1y: -20.263,
      price_pc_ytd: -20.263,
    },
    3125: {
      id: "3125",
      c: 62.5,
      ma50: 62.381,
      ma100: 61.967,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 77.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -3.0,
      price_5d_h: 62.5,
      price_5d_L: 62.22,
      price_1m_h: 62.76,
      price_1m_l: 62.22,
      price_3m_h: 62.76,
      price_3m_l: 61.12,
      price_6m_h: 62.76,
      price_6m_l: 60.96,
      price_52w_h: 62.76,
      price_52w_l: 60.96,
      price_pc_1d: 0.225,
      price_pc_1w: 0.45,
      price_pc_1m: -0.414,
      price_pc_6m: 2.526,
      price_pc_1y: 2.526,
      price_pc_ytd: 2.526,
    },
    3127: {
      id: "3127",
      c: 18.0,
      ma50: 19.012,
      ma100: 19.953,
      ma150: 20.173,
      ma200: 20.397,
      maw30: 20.237,
      rs: 46.0,
      rsd1d: 3.0,
      rsd5d: 1.0,
      rsd10d: -2.0,
      price_5d_h: 18.0,
      price_5d_L: 17.51,
      price_1m_h: 19.65,
      price_1m_l: 16.4,
      price_3m_h: 21.06,
      price_3m_l: 16.4,
      price_6m_h: 21.8,
      price_6m_l: 16.4,
      price_52w_h: 22.54,
      price_52w_l: 16.4,
      price_pc_1d: 0.056,
      price_pc_1w: -0.222,
      price_pc_1m: -7.881,
      price_pc_6m: -11.851,
      price_pc_1y: -14.04,
      price_pc_ytd: -14.04,
    },
    3128: {
      id: "3128",
      c: 60.3,
      ma50: 64.095,
      ma100: 65.953,
      ma150: 66.167,
      ma200: 67.063,
      maw30: 66.501,
      rs: 46.0,
      rsd1d: 3.0,
      rsd5d: 1.0,
      rsd10d: -2.0,
      price_5d_h: 60.3,
      price_5d_L: 58.06,
      price_1m_h: 65.78,
      price_1m_l: 55.86,
      price_3m_h: 69.6,
      price_3m_l: 55.86,
      price_6m_h: 70.92,
      price_6m_l: 55.86,
      price_52w_h: 77.42,
      price_52w_l: 55.86,
      price_pc_1d: 0.0,
      price_pc_1w: -0.199,
      price_pc_1m: -8.219,
      price_pc_6m: -10.134,
      price_pc_1y: -18.689,
      price_pc_ytd: -18.689,
    },
    3130: {
      id: "3130",
      c: 26.04,
      ma50: 27.438,
      ma100: 28.665,
      ma150: 28.981,
      ma200: 29.283,
      maw30: 29.065,
      rs: 49.0,
      rsd1d: 3.0,
      rsd5d: 4.0,
      rsd10d: -1.0,
      price_5d_h: 26.04,
      price_5d_L: 25.4,
      price_1m_h: 28.4,
      price_1m_l: 23.9,
      price_3m_h: 30.12,
      price_3m_l: 23.9,
      price_6m_h: 31.56,
      price_6m_l: 23.9,
      price_52w_h: 32.5,
      price_52w_l: 23.9,
      price_pc_1d: 0.077,
      price_pc_1w: 0.386,
      price_pc_1m: -7.66,
      price_pc_6m: -10.883,
      price_pc_1y: -13.316,
      price_pc_ytd: -13.316,
    },
    3132: {
      id: "3132",
      c: 14.77,
      ma50: 14.36,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 57.0,
      rsd1d: 1.0,
      rsd5d: 9.0,
      rsd10d: 1.0,
      price_5d_h: 15.13,
      price_5d_L: 14.58,
      price_1m_h: 15.13,
      price_1m_l: 12.85,
      price_3m_h: 16.7,
      price_3m_l: 12.85,
      price_6m_h: 16.9,
      price_6m_l: 12.85,
      price_52w_h: 16.9,
      price_52w_l: 12.85,
      price_pc_1d: -1.926,
      price_pc_1w: 3.431,
      price_pc_1m: 3.941,
      price_pc_6m: -7.34,
      price_pc_1y: -7.34,
      price_pc_ytd: -7.34,
    },
    3134: {
      id: "3134",
      c: 9.52,
      ma50: 9.964,
      ma100: 10.703,
      ma150: 11.006,
      ma200: 10.676,
      maw30: 10.988,
      rs: 61.0,
      rsd1d: -5.0,
      rsd5d: -10.0,
      rsd10d: -25.0,
      price_5d_h: 9.89,
      price_5d_L: 9.39,
      price_1m_h: 11.0,
      price_1m_l: 9.39,
      price_3m_h: 11.6,
      price_3m_l: 8.95,
      price_6m_h: 12.72,
      price_6m_l: 8.95,
      price_52w_h: 12.76,
      price_52w_l: 7.385,
      price_pc_1d: -2.708,
      price_pc_1w: -3.006,
      price_pc_1m: -11.028,
      price_pc_6m: -14.924,
      price_pc_1y: 21.506,
      price_pc_ytd: 21.506,
    },
    3136: {
      id: "3136",
      c: 13.85,
      ma50: null,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 66.0,
      rsd1d: -1.0,
      rsd5d: 2.0,
      rsd10d: 1.0,
      price_5d_h: 14.06,
      price_5d_L: 13.68,
      price_1m_h: 14.52,
      price_1m_l: 11.69,
      price_3m_h: 15.0,
      price_3m_l: 11.69,
      price_6m_h: 15.0,
      price_6m_l: 11.69,
      price_52w_h: 15.0,
      price_52w_l: 11.69,
      price_pc_1d: -1.213,
      price_pc_1w: -0.574,
      price_pc_1m: -3.753,
      price_pc_6m: -4.746,
      price_pc_1y: -4.746,
      price_pc_ytd: -4.746,
    },
    3139: {
      id: "3139",
      c: 65.74,
      ma50: null,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 79.0,
      rsd1d: -1.0,
      rsd5d: 4.0,
      rsd10d: null,
      price_5d_h: 65.88,
      price_5d_L: 62.7,
      price_1m_h: 65.88,
      price_1m_l: 62.7,
      price_3m_h: 65.88,
      price_3m_l: 62.7,
      price_6m_h: 65.88,
      price_6m_l: 62.7,
      price_52w_h: 65.88,
      price_52w_l: 62.7,
      price_pc_1d: -0.213,
      price_pc_1w: 2.847,
      price_pc_1m: 3.17,
      price_pc_6m: 3.17,
      price_pc_1y: 3.17,
      price_pc_ytd: 3.17,
    },
    3141: {
      id: "3141",
      c: 15.08,
      ma50: 15.321,
      ma100: 15.678,
      ma150: 15.838,
      ma200: 15.93,
      maw30: 15.845,
      rs: 63.0,
      rsd1d: 3.0,
      rsd5d: 4.0,
      rsd10d: 0.0,
      price_5d_h: 15.13,
      price_5d_L: 14.88,
      price_1m_h: 15.45,
      price_1m_l: 14.83,
      price_3m_h: 16.15,
      price_3m_l: 14.83,
      price_6m_h: 16.19,
      price_6m_l: 14.83,
      price_52w_h: 16.4,
      price_52w_l: 14.83,
      price_pc_1d: -0.066,
      price_pc_1w: 0.533,
      price_pc_1m: -1.695,
      price_pc_6m: -6.683,
      price_pc_1y: -6.102,
      price_pc_ytd: -6.102,
    },
    3143: {
      id: "3143",
      c: 7.575,
      ma50: 7.514,
      ma100: 7.184,
      ma150: 7.092,
      ma200: 7.103,
      maw30: 7.11,
      rs: 83.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: 2.0,
      price_5d_h: 7.595,
      price_5d_L: 7.35,
      price_1m_h: 7.595,
      price_1m_l: 6.795,
      price_3m_h: 8.09,
      price_3m_l: 6.795,
      price_6m_h: 8.09,
      price_6m_l: 6.4,
      price_52w_h: 8.09,
      price_52w_l: 6.4,
      price_pc_1d: 0.866,
      price_pc_1w: 3.131,
      price_pc_1m: 0.464,
      price_pc_6m: 9.624,
      price_pc_1y: 1.067,
      price_pc_ytd: 1.067,
    },
    3145: {
      id: "3145",
      c: 10.83,
      ma50: 10.856,
      ma100: 10.794,
      ma150: 10.828,
      ma200: 10.88,
      maw30: 10.85,
      rs: 71.0,
      rsd1d: 1.0,
      rsd5d: 2.0,
      rsd10d: 2.0,
      price_5d_h: 10.83,
      price_5d_L: 10.58,
      price_1m_h: 10.93,
      price_1m_l: 9.2,
      price_3m_h: 11.32,
      price_3m_l: 9.2,
      price_6m_h: 11.32,
      price_6m_l: 9.2,
      price_52w_h: 11.5,
      price_52w_l: 9.2,
      price_pc_1d: 0.0,
      price_pc_1w: 0.838,
      price_pc_1m: 0.092,
      price_pc_6m: -0.642,
      price_pc_1y: -3.562,
      price_pc_ytd: -3.562,
    },
    3147: {
      id: "3147",
      c: 11.64,
      ma50: 12.07,
      ma100: 13.281,
      ma150: 13.455,
      ma200: 13.666,
      maw30: 13.496,
      rs: 45.0,
      rsd1d: 3.0,
      rsd5d: -3.0,
      rsd10d: -6.0,
      price_5d_h: 11.8,
      price_5d_L: 11.1,
      price_1m_h: 12.65,
      price_1m_l: 10.6,
      price_3m_h: 14.5,
      price_3m_l: 10.6,
      price_6m_h: 15.24,
      price_6m_l: 10.6,
      price_52w_h: 15.24,
      price_52w_l: 10.6,
      price_pc_1d: 0.086,
      price_pc_1w: -0.086,
      price_pc_1m: -7.619,
      price_pc_6m: -15.53,
      price_pc_1y: -1.356,
      price_pc_ytd: -1.356,
    },
    3149: {
      id: "3149",
      c: 14.85,
      ma50: 15.739,
      ma100: 16.582,
      ma150: 16.803,
      ma200: 16.964,
      maw30: 16.858,
      rs: 47.0,
      rsd1d: 2.0,
      rsd5d: 1.0,
      rsd10d: 0.0,
      price_5d_h: 14.85,
      price_5d_L: 14.56,
      price_1m_h: 16.33,
      price_1m_l: 14.19,
      price_3m_h: 17.27,
      price_3m_l: 14.19,
      price_6m_h: 18.46,
      price_6m_l: 14.19,
      price_52w_h: 18.46,
      price_52w_l: 14.19,
      price_pc_1d: 0.0,
      price_pc_1w: -0.536,
      price_pc_1m: -8.503,
      price_pc_6m: -13.512,
      price_pc_1y: -12.078,
      price_pc_ytd: -12.078,
    },
    3151: {
      id: "3151",
      c: 8.665,
      ma50: 9.443,
      ma100: 10.241,
      ma150: 10.419,
      ma200: null,
      maw30: 10.438,
      rs: 36.0,
      rsd1d: 0.0,
      rsd5d: -4.0,
      rsd10d: -11.0,
      price_5d_h: 8.855,
      price_5d_L: 8.46,
      price_1m_h: 9.86,
      price_1m_l: 8.46,
      price_3m_h: 11.1,
      price_3m_l: 8.46,
      price_6m_h: 11.76,
      price_6m_l: 8.46,
      price_52w_h: 12.95,
      price_52w_l: 8.46,
      price_pc_1d: -0.971,
      price_pc_1w: -3.238,
      price_pc_1m: -13.089,
      price_pc_6m: -18.791,
      price_pc_1y: -25.173,
      price_pc_ytd: -25.173,
    },
    3157: {
      id: "3157",
      c: 22.18,
      ma50: 23.257,
      ma100: 24.457,
      ma150: 25.743,
      ma200: 26.848,
      maw30: 25.753,
      rs: 37.0,
      rsd1d: 2.0,
      rsd5d: 2.0,
      rsd10d: 6.0,
      price_5d_h: 22.22,
      price_5d_L: 22.06,
      price_1m_h: 23.7,
      price_1m_l: 19.99,
      price_3m_h: 24.98,
      price_3m_l: 19.99,
      price_6m_h: 28.52,
      price_6m_l: 19.99,
      price_52w_h: 32.12,
      price_52w_l: 19.99,
      price_pc_1d: -0.18,
      price_pc_1w: -1.597,
      price_pc_1m: -5.857,
      price_pc_6m: -20.955,
      price_pc_1y: -26.263,
      price_pc_ytd: -26.263,
    },
    3160: {
      id: "3160",
      c: 13.34,
      ma50: 13.062,
      ma100: 13.428,
      ma150: 13.565,
      ma200: 13.477,
      maw30: 13.594,
      rs: 70.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: 4.0,
      price_5d_h: 13.5,
      price_5d_L: 13.31,
      price_1m_h: 13.5,
      price_1m_l: 12.03,
      price_3m_h: 14.16,
      price_3m_l: 12.03,
      price_6m_h: 14.16,
      price_6m_l: 12.03,
      price_52w_h: 14.51,
      price_52w_l: 12.03,
      price_pc_1d: -0.965,
      price_pc_1w: -0.67,
      price_pc_1m: 2.773,
      price_pc_6m: -4.918,
      price_pc_1y: 0.528,
      price_pc_ytd: 0.528,
    },
    3162: {
      id: "3162",
      c: 6.425,
      ma50: 6.365,
      ma100: 7.166,
      ma150: 7.366,
      ma200: null,
      maw30: 7.359,
      rs: 44.0,
      rsd1d: -2.0,
      rsd5d: 9.0,
      rsd10d: 7.0,
      price_5d_h: 6.52,
      price_5d_L: 6.145,
      price_1m_h: 6.605,
      price_1m_l: 5.3,
      price_3m_h: 7.88,
      price_3m_l: 5.3,
      price_6m_h: 8.69,
      price_6m_l: 5.3,
      price_52w_h: 8.69,
      price_52w_l: 5.3,
      price_pc_1d: -1.457,
      price_pc_1w: 3.462,
      price_pc_1m: 0.391,
      price_pc_6m: -14.047,
      price_pc_1y: -17.628,
      price_pc_ytd: -17.628,
    },
    3163: {
      id: "3163",
      c: 33.2,
      ma50: 34.843,
      ma100: 36.15,
      ma150: 37.463,
      ma200: 38.615,
      maw30: 37.498,
      rs: 42.0,
      rsd1d: 2.0,
      rsd5d: 2.0,
      rsd10d: 3.0,
      price_5d_h: 33.2,
      price_5d_L: 32.62,
      price_1m_h: 35.56,
      price_1m_l: 29.36,
      price_3m_h: 37.42,
      price_3m_l: 29.36,
      price_6m_h: 40.66,
      price_6m_l: 29.36,
      price_52w_h: 44.62,
      price_52w_l: 29.36,
      price_pc_1d: 0.0,
      price_pc_1w: 0.06,
      price_pc_1m: -6.215,
      price_pc_6m: -15.392,
      price_pc_1y: -23.853,
      price_pc_ytd: -23.853,
    },
    3165: {
      id: "3165",
      c: 15.74,
      ma50: 15.232,
      ma100: 15.893,
      ma150: 15.978,
      ma200: 15.982,
      maw30: 15.974,
      rs: 73.0,
      rsd1d: 2.0,
      rsd5d: 5.0,
      rsd10d: 2.0,
      price_5d_h: 15.74,
      price_5d_L: 15.3,
      price_1m_h: 15.74,
      price_1m_l: 14.01,
      price_3m_h: 17.01,
      price_3m_l: 14.01,
      price_6m_h: 17.01,
      price_6m_l: 14.01,
      price_52w_h: 17.01,
      price_52w_l: 14.01,
      price_pc_1d: 0.639,
      price_pc_1w: 2.608,
      price_pc_1m: 5.78,
      price_pc_6m: -0.569,
      price_pc_1y: 10.923,
      price_pc_ytd: 10.923,
    },
    3166: {
      id: "3166",
      c: 35.12,
      ma50: 39.673,
      ma100: 42.11,
      ma150: 43.151,
      ma200: 44.791,
      maw30: 43.295,
      rs: 27.0,
      rsd1d: 1.0,
      rsd5d: -2.0,
      rsd10d: -5.0,
      price_5d_h: 35.62,
      price_5d_L: 34.9,
      price_1m_h: 40.92,
      price_1m_l: 32.2,
      price_3m_h: 44.18,
      price_3m_l: 32.2,
      price_6m_h: 46.4,
      price_6m_l: 32.2,
      price_52w_h: 54.44,
      price_52w_l: 32.2,
      price_pc_1d: -1.404,
      price_pc_1w: -3.622,
      price_pc_1m: -14.006,
      price_pc_6m: -21.572,
      price_pc_1y: -31.353,
      price_pc_ytd: -31.353,
    },
    3167: {
      id: "3167",
      c: 65.6,
      ma50: 70.255,
      ma100: 75.454,
      ma150: 78.972,
      ma200: 82.892,
      maw30: 79.211,
      rs: 27.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: 0.0,
      price_5d_h: 66.36,
      price_5d_L: 63.76,
      price_1m_h: 71.84,
      price_1m_l: 51.44,
      price_3m_h: 81.0,
      price_3m_l: 51.44,
      price_6m_h: 90.7,
      price_6m_l: 51.44,
      price_52w_h: 109.95,
      price_52w_l: 51.44,
      price_pc_1d: -1.115,
      price_pc_1w: -1.914,
      price_pc_1m: -6.152,
      price_pc_6m: -21.343,
      price_pc_1y: -39.259,
      price_pc_ytd: -39.259,
    },
    3173: {
      id: "3173",
      c: 10.3,
      ma50: 10.912,
      ma100: 11.696,
      ma150: 11.761,
      ma200: 11.938,
      maw30: 11.799,
      rs: 42.0,
      rsd1d: 1.0,
      rsd5d: -3.0,
      rsd10d: -8.0,
      price_5d_h: 10.45,
      price_5d_L: 10.0,
      price_1m_h: 11.57,
      price_1m_l: 9.7,
      price_3m_h: 12.75,
      price_3m_l: 9.7,
      price_6m_h: 13.02,
      price_6m_l: 9.7,
      price_52w_h: 13.09,
      price_52w_l: 9.7,
      price_pc_1d: -0.483,
      price_pc_1w: -1.057,
      price_pc_1m: -8.769,
      price_pc_6m: -13.3,
      price_pc_1y: -12.712,
      price_pc_ytd: -12.712,
    },
    3174: {
      id: "3174",
      c: 3.626,
      ma50: 3.837,
      ma100: 4.486,
      ma150: 5.056,
      ma200: null,
      maw30: 5.057,
      rs: 17.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: 3.0,
      price_5d_h: 3.842,
      price_5d_L: 3.476,
      price_1m_h: 4.062,
      price_1m_l: 2.72,
      price_3m_h: 4.792,
      price_3m_l: 2.72,
      price_6m_h: 6.305,
      price_6m_l: 2.72,
      price_52w_h: 8.21,
      price_52w_l: 2.72,
      price_pc_1d: -4.529,
      price_pc_1w: -5.818,
      price_pc_1m: -8.481,
      price_pc_6m: -42.536,
      price_pc_1y: -53.092,
      price_pc_ytd: -53.092,
    },
    3175: {
      id: "3175",
      c: 7.4,
      ma50: 6.841,
      ma100: 6.03,
      ma150: 5.726,
      ma200: 5.472,
      maw30: 5.708,
      rs: 95.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: 0.0,
      price_5d_h: 8.075,
      price_5d_L: 7.265,
      price_1m_h: 8.59,
      price_1m_l: 6.6,
      price_3m_h: 8.59,
      price_3m_l: 5.25,
      price_6m_h: 8.59,
      price_6m_l: 4.564,
      price_52w_h: 8.59,
      price_52w_l: 3.892,
      price_pc_1d: -2.632,
      price_pc_1w: -8.923,
      price_pc_1m: 11.952,
      price_pc_6m: 45.24,
      price_pc_1y: 85.0,
      price_pc_ytd: 85.0,
    },
    3176: {
      id: "3176",
      c: 8.97,
      ma50: 9.564,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 41.0,
      rsd1d: -2.0,
      rsd5d: 0.0,
      rsd10d: -1.0,
      price_5d_h: 9.0,
      price_5d_L: 8.73,
      price_1m_h: 9.735,
      price_1m_l: 7.5,
      price_3m_h: 10.85,
      price_3m_l: 7.5,
      price_6m_h: 12.19,
      price_6m_l: 7.5,
      price_52w_h: 12.19,
      price_52w_l: 7.5,
      price_pc_1d: -1.157,
      price_pc_1w: -1.374,
      price_pc_1m: -6.854,
      price_pc_6m: -24.937,
      price_pc_1y: -24.937,
      price_pc_ytd: -24.937,
    },
    3181: {
      id: "3181",
      c: 103.95,
      ma50: 107.923,
      ma100: 117.796,
      ma150: 122.126,
      ma200: 125.691,
      maw30: 122.129,
      rs: 36.0,
      rsd1d: 2.0,
      rsd5d: 2.0,
      rsd10d: 1.0,
      price_5d_h: 104.35,
      price_5d_L: 102.0,
      price_1m_h: 110.6,
      price_1m_l: 93.12,
      price_3m_h: 124.35,
      price_3m_l: 93.12,
      price_6m_h: 136.4,
      price_6m_l: 93.12,
      price_52w_h: 143.75,
      price_52w_l: 93.12,
      price_pc_1d: -0.192,
      price_pc_1w: -1.329,
      price_pc_1m: -4.326,
      price_pc_6m: -18.31,
      price_pc_1y: -23.846,
      price_pc_ytd: -23.846,
    },
    3182: {
      id: "3182",
      c: 11.77,
      ma50: 12.686,
      ma100: 14.069,
      ma150: 14.974,
      ma200: 15.733,
      maw30: 14.995,
      rs: 23.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: 1.0,
      price_5d_h: 11.92,
      price_5d_L: 11.31,
      price_1m_h: 13.1,
      price_1m_l: 8.85,
      price_3m_h: 14.85,
      price_3m_l: 8.85,
      price_6m_h: 17.96,
      price_6m_l: 8.85,
      price_52w_h: 20.2,
      price_52w_l: 8.85,
      price_pc_1d: -1.753,
      price_pc_1w: -1.671,
      price_pc_1m: -8.689,
      price_pc_6m: -26.483,
      price_pc_1y: -39.98,
      price_pc_ytd: -39.98,
    },
    3185: {
      id: "3185",
      c: 48.14,
      ma50: 46.477,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 38.0,
      rsd1d: -1.0,
      rsd5d: 6.0,
      rsd10d: 5.0,
      price_5d_h: 49.0,
      price_5d_L: 46.04,
      price_1m_h: 49.0,
      price_1m_l: 40.14,
      price_3m_h: 59.0,
      price_3m_l: 40.14,
      price_6m_h: 60.94,
      price_6m_l: 40.14,
      price_52w_h: 60.94,
      price_52w_l: 40.14,
      price_pc_1d: -1.715,
      price_pc_1w: 2.035,
      price_pc_1m: 5.802,
      price_pc_6m: -20.9,
      price_pc_1y: -20.9,
      price_pc_ytd: -20.9,
    },
    3186: {
      id: "3186",
      c: 44.68,
      ma50: 48.355,
      ma100: 53.516,
      ma150: 58.184,
      ma200: 63.181,
      maw30: 58.301,
      rs: 18.0,
      rsd1d: -1.0,
      rsd5d: 1.0,
      rsd10d: 1.0,
      price_5d_h: 45.82,
      price_5d_L: 43.38,
      price_1m_h: 50.3,
      price_1m_l: 30.28,
      price_3m_h: 56.44,
      price_3m_l: 30.28,
      price_6m_h: 72.02,
      price_6m_l: 30.28,
      price_52w_h: 105.85,
      price_52w_l: 30.28,
      price_pc_1d: -2.36,
      price_pc_1w: -3.08,
      price_pc_1m: -9.445,
      price_pc_6m: -29.192,
      price_pc_1y: -57.669,
      price_pc_ytd: -57.669,
    },
    3187: {
      id: "3187",
      c: 21.22,
      ma50: 20.491,
      ma100: 20.937,
      ma150: 21.311,
      ma200: 21.574,
      maw30: 21.302,
      rs: 70.0,
      rsd1d: 1.0,
      rsd5d: 4.0,
      rsd10d: 4.0,
      price_5d_h: 21.28,
      price_5d_L: 20.88,
      price_1m_h: 21.28,
      price_1m_l: 19.99,
      price_3m_h: 21.56,
      price_3m_l: 19.77,
      price_6m_h: 22.4,
      price_6m_l: 19.77,
      price_52w_h: 23.16,
      price_52w_l: 19.77,
      price_pc_1d: 0.094,
      price_pc_1w: 2.019,
      price_pc_1m: 4.326,
      price_pc_6m: -1.486,
      price_pc_1y: -1.577,
      price_pc_ytd: -1.577,
    },
    3188: {
      id: "3188",
      c: 53.0,
      ma50: 56.014,
      ma100: 58.582,
      ma150: 59.138,
      ma200: 59.741,
      maw30: 59.331,
      rs: 47.0,
      rsd1d: 2.0,
      rsd5d: 2.0,
      rsd10d: -2.0,
      price_5d_h: 53.36,
      price_5d_L: 50.8,
      price_1m_h: 58.14,
      price_1m_l: 48.74,
      price_3m_h: 62.22,
      price_3m_l: 48.74,
      price_6m_h: 64.42,
      price_6m_l: 48.74,
      price_52w_h: 66.88,
      price_52w_l: 48.74,
      price_pc_1d: -0.451,
      price_pc_1w: 0.113,
      price_pc_1m: -8.05,
      price_pc_6m: -11.519,
      price_pc_1y: -14.461,
      price_pc_ytd: -14.461,
    },
    3191: {
      id: "3191",
      c: 53.1,
      ma50: 57.83,
      ma100: 62.154,
      ma150: 63.01,
      ma200: 64.121,
      maw30: 62.998,
      rs: 46.0,
      rsd1d: -2.0,
      rsd5d: -9.0,
      rsd10d: -19.0,
      price_5d_h: 56.4,
      price_5d_L: 52.88,
      price_1m_h: 61.3,
      price_1m_l: 52.54,
      price_3m_h: 66.38,
      price_3m_l: 52.54,
      price_6m_h: 70.64,
      price_6m_l: 52.54,
      price_52w_h: 76.4,
      price_52w_l: 51.34,
      price_pc_1d: -2.102,
      price_pc_1w: -4.117,
      price_pc_1m: -13.377,
      price_pc_6m: -15.661,
      price_pc_1y: 0.836,
      price_pc_ytd: 0.836,
    },
    3193: {
      id: "3193",
      c: 6.24,
      ma50: 7.176,
      ma100: 7.729,
      ma150: 7.653,
      ma200: 7.733,
      maw30: 7.674,
      rs: 36.0,
      rsd1d: 0.0,
      rsd5d: -4.0,
      rsd10d: -13.0,
      price_5d_h: 6.5,
      price_5d_L: 6.19,
      price_1m_h: 7.53,
      price_1m_l: 6.19,
      price_3m_h: 8.585,
      price_3m_l: 6.19,
      price_6m_h: 8.64,
      price_6m_l: 6.19,
      price_52w_h: 8.64,
      price_52w_l: 6.19,
      price_pc_1d: -1.655,
      price_pc_1w: -4.587,
      price_pc_1m: -17.131,
      price_pc_6m: -15.16,
      price_pc_1y: -8.1,
      price_pc_ytd: -8.1,
    },
    3194: {
      id: "3194",
      c: 14.2,
      ma50: 14.093,
      ma100: 15.76,
      ma150: 16.558,
      ma200: 16.828,
      maw30: 16.559,
      rs: 38.0,
      rsd1d: 0.0,
      rsd5d: 6.0,
      rsd10d: 4.0,
      price_5d_h: 14.4,
      price_5d_L: 13.7,
      price_1m_h: 14.4,
      price_1m_l: 12.12,
      price_3m_h: 17.07,
      price_3m_l: 12.12,
      price_6m_h: 19.33,
      price_6m_l: 12.12,
      price_52w_h: 19.33,
      price_52w_l: 12.12,
      price_pc_1d: -1.252,
      price_pc_1w: 2.011,
      price_pc_1m: 2.675,
      price_pc_6m: -17.729,
      price_pc_1y: -8.505,
      price_pc_ytd: -8.505,
    },
    3198: {
      id: "3198",
      c: 99.02,
      ma50: 97.302,
      ma100: 99.027,
      ma150: 99.066,
      ma200: 98.889,
      maw30: 99.067,
      rs: 75.0,
      rsd1d: 0.0,
      rsd5d: 4.0,
      rsd10d: 3.0,
      price_5d_h: 99.02,
      price_5d_L: 97.36,
      price_1m_h: 99.02,
      price_1m_l: 94.46,
      price_3m_h: 102.25,
      price_3m_l: 94.46,
      price_6m_h: 102.45,
      price_6m_l: 94.46,
      price_52w_h: 102.45,
      price_52w_l: 94.46,
      price_pc_1d: 0.081,
      price_pc_1w: 2.125,
      price_pc_1m: 2.611,
      price_pc_6m: 1.144,
      price_pc_1y: 5.904,
      price_pc_ytd: 5.904,
    },
    3199: {
      id: "3199",
      c: 127.7,
      ma50: 127.536,
      ma100: 127.027,
      ma150: 126.189,
      ma200: 125.558,
      maw30: 126.24,
      rs: 78.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: -4.0,
      price_5d_h: 127.8,
      price_5d_L: 126.9,
      price_1m_h: 128.3,
      price_1m_l: 126.25,
      price_3m_h: 128.55,
      price_3m_l: 126.25,
      price_6m_h: 128.55,
      price_6m_l: 123.0,
      price_52w_h: 128.55,
      price_52w_l: 120.9,
      price_pc_1d: 0.039,
      price_pc_1w: 0.551,
      price_pc_1m: -0.273,
      price_pc_6m: 2.694,
      price_pc_1y: 5.537,
      price_pc_ytd: 5.537,
    },
    3300: {
      id: "3300",
      c: 1.56,
      ma50: 1.636,
      ma100: 1.789,
      ma150: 2.159,
      ma200: 2.29,
      maw30: 2.096,
      rs: 86.0,
      rsd1d: -5.0,
      rsd5d: -4.0,
      rsd10d: 4.0,
      price_5d_h: 1.75,
      price_5d_L: 1.54,
      price_1m_h: 1.8,
      price_1m_l: 1.22,
      price_3m_h: 1.9,
      price_3m_l: 1.22,
      price_6m_h: 2.73,
      price_6m_l: 1.22,
      price_52w_h: 4.5,
      price_52w_l: 0.66,
      price_pc_1d: -8.235,
      price_pc_1w: -6.587,
      price_pc_1m: -2.5,
      price_pc_6m: -35.27,
      price_pc_1y: 136.364,
      price_pc_ytd: 136.364,
    },
    3301: {
      id: "3301",
      c: 2.33,
      ma50: 2.697,
      ma100: 2.862,
      ma150: 3.144,
      ma200: 3.456,
      maw30: 3.152,
      rs: 15.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -6.0,
      price_5d_h: 2.43,
      price_5d_L: 2.09,
      price_1m_h: 2.61,
      price_1m_l: 1.86,
      price_3m_h: 3.52,
      price_3m_l: 1.86,
      price_6m_h: 4.09,
      price_6m_l: 1.86,
      price_52w_h: 5.63,
      price_52w_l: 1.86,
      price_pc_1d: -3.32,
      price_pc_1w: -0.427,
      price_pc_1m: -6.048,
      price_pc_6m: -40.103,
      price_pc_1y: -56.449,
      price_pc_ytd: -56.449,
    },
    3303: {
      id: "3303",
      c: 0.8,
      ma50: 0.849,
      ma100: 0.883,
      ma150: 0.932,
      ma200: 0.988,
      maw30: 0.934,
      rs: 26.0,
      rsd1d: 2.0,
      rsd5d: 0.0,
      rsd10d: -7.0,
      price_5d_h: 0.82,
      price_5d_L: 0.77,
      price_1m_h: 0.9,
      price_1m_l: 0.73,
      price_3m_h: 1.03,
      price_3m_l: 0.73,
      price_6m_h: 1.24,
      price_6m_l: 0.73,
      price_52w_h: 1.75,
      price_52w_l: 0.73,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: -9.091,
      price_pc_6m: -21.569,
      price_pc_1y: -32.203,
      price_pc_ytd: -32.203,
    },
    3306: {
      id: "3306",
      c: 9.37,
      ma50: 10.644,
      ma100: 11.692,
      ma150: 12.97,
      ma200: 13.622,
      maw30: 12.89,
      rs: 23.0,
      rsd1d: -2.0,
      rsd5d: -3.0,
      rsd10d: 1.0,
      price_5d_h: 10.28,
      price_5d_L: 9.1,
      price_1m_h: 10.9,
      price_1m_l: 8.14,
      price_3m_h: 13.28,
      price_3m_l: 8.14,
      price_6m_h: 14.82,
      price_6m_l: 8.14,
      price_52w_h: 21.5,
      price_52w_l: 8.14,
      price_pc_1d: -3.996,
      price_pc_1w: -7.594,
      price_pc_1m: -11.437,
      price_pc_6m: -30.798,
      price_pc_1y: -28.907,
      price_pc_ytd: -28.907,
    },
    3308: {
      id: "3308",
      c: 5.68,
      ma50: 5.684,
      ma100: 6.14,
      ma150: 6.369,
      ma200: 6.488,
      maw30: 6.377,
      rs: 42.0,
      rsd1d: 5.0,
      rsd5d: 8.0,
      rsd10d: 0.0,
      price_5d_h: 5.89,
      price_5d_L: 5.45,
      price_1m_h: 5.89,
      price_1m_l: 5.27,
      price_3m_h: 6.66,
      price_3m_l: 5.27,
      price_6m_h: 7.06,
      price_6m_l: 5.27,
      price_52w_h: 7.1,
      price_52w_l: 5.27,
      price_pc_1d: 2.342,
      price_pc_1w: 3.65,
      price_pc_1m: 3.085,
      price_pc_6m: -16.471,
      price_pc_1y: -18.741,
      price_pc_ytd: -18.741,
    },
    3309: {
      id: "3309",
      c: 4.62,
      ma50: 5.089,
      ma100: 6.059,
      ma150: 6.736,
      ma200: 7.217,
      maw30: 6.79,
      rs: 17.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: 0.0,
      price_5d_h: 4.8,
      price_5d_L: 4.44,
      price_1m_h: 5.46,
      price_1m_l: 3.55,
      price_3m_h: 7.35,
      price_3m_l: 3.55,
      price_6m_h: 9.15,
      price_6m_l: 3.55,
      price_52w_h: 12.06,
      price_52w_l: 3.55,
      price_pc_1d: -2.326,
      price_pc_1w: -3.347,
      price_pc_1m: -15.074,
      price_pc_6m: -42.178,
      price_pc_1y: -18.085,
      price_pc_ytd: -18.085,
    },
    3311: {
      id: "3311",
      c: 10.48,
      ma50: 10.01,
      ma100: 9.392,
      ma150: 8.684,
      ma200: 7.841,
      maw30: 8.771,
      rs: 95.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 1.0,
      price_5d_h: 10.6,
      price_5d_L: 9.26,
      price_1m_h: 11.14,
      price_1m_l: 8.44,
      price_3m_h: 11.14,
      price_3m_l: 8.44,
      price_6m_h: 11.14,
      price_6m_l: 6.62,
      price_52w_h: 11.14,
      price_52w_l: 4.72,
      price_pc_1d: 3.15,
      price_pc_1w: 1.748,
      price_pc_1m: -4.554,
      price_pc_6m: 53.666,
      price_pc_1y: 98.861,
      price_pc_ytd: 98.861,
    },
    3313: {
      id: "3313",
      c: 0.03,
      ma50: 0.034,
      ma100: 0.038,
      ma150: 0.041,
      ma200: 0.047,
      maw30: 0.041,
      rs: 14.0,
      rsd1d: 3.0,
      rsd5d: 3.0,
      rsd10d: 3.0,
      price_5d_h: 0.03,
      price_5d_L: 0.028,
      price_1m_h: 0.036,
      price_1m_l: 0.025,
      price_3m_h: 0.043,
      price_3m_l: 0.025,
      price_6m_h: 0.051,
      price_6m_l: 0.025,
      price_52w_h: 0.076,
      price_52w_l: 0.025,
      price_pc_1d: 3.448,
      price_pc_1w: 3.448,
      price_pc_1m: -14.286,
      price_pc_6m: -37.5,
      price_pc_1y: -53.125,
      price_pc_ytd: -53.125,
    },
    3315: {
      id: "3315",
      c: 2.15,
      ma50: 2.148,
      ma100: 1.953,
      ma150: 1.856,
      ma200: 1.81,
      maw30: 1.866,
      rs: 91.0,
      rsd1d: 2.0,
      rsd5d: 1.0,
      rsd10d: 1.0,
      price_5d_h: 2.16,
      price_5d_L: 1.98,
      price_1m_h: 2.55,
      price_1m_l: 1.73,
      price_3m_h: 2.56,
      price_3m_l: 1.63,
      price_6m_h: 2.56,
      price_6m_l: 1.57,
      price_52w_h: 2.56,
      price_52w_l: 1.5,
      price_pc_1d: 6.965,
      price_pc_1w: 1.896,
      price_pc_1m: -14.343,
      price_pc_6m: 35.22,
      price_pc_1y: 10.256,
      price_pc_ytd: 10.256,
    },
    3316: {
      id: "3316",
      c: 22.15,
      ma50: 22.763,
      ma100: 22.096,
      ma150: 22.051,
      ma200: 23.841,
      maw30: 22.161,
      rs: 68.0,
      rsd1d: 6.0,
      rsd5d: -5.0,
      rsd10d: -4.0,
      price_5d_h: 22.15,
      price_5d_L: 19.6,
      price_1m_h: 24.4,
      price_1m_l: 16.3,
      price_3m_h: 27.0,
      price_3m_l: 16.3,
      price_6m_h: 27.0,
      price_6m_l: 16.3,
      price_52w_h: 37.3,
      price_52w_l: 16.3,
      price_pc_1d: 4.481,
      price_pc_1w: 2.784,
      price_pc_1m: -4.32,
      price_pc_6m: 1.606,
      price_pc_1y: 4.481,
      price_pc_ytd: 4.481,
    },
    3318: {
      id: "3318",
      c: 3.24,
      ma50: 4.021,
      ma100: 4.016,
      ma150: 3.748,
      ma200: 3.846,
      maw30: 3.743,
      rs: 50.0,
      rsd1d: 4.0,
      rsd5d: 0.0,
      rsd10d: 11.0,
      price_5d_h: 3.6,
      price_5d_L: 3.1,
      price_1m_h: 4.77,
      price_1m_l: 2.95,
      price_3m_h: 5.2,
      price_3m_l: 2.95,
      price_6m_h: 5.7,
      price_6m_l: 2.45,
      price_52w_h: 5.7,
      price_52w_l: 2.45,
      price_pc_1d: -1.818,
      price_pc_1w: -10.0,
      price_pc_1m: -29.565,
      price_pc_6m: 8.725,
      price_pc_1y: -4.985,
      price_pc_ytd: -4.985,
    },
    3319: {
      id: "3319",
      c: 10.96,
      ma50: 13.981,
      ma100: 15.504,
      ma150: 19.758,
      ma200: 23.096,
      maw30: 19.69,
      rs: 9.0,
      rsd1d: 0.0,
      rsd5d: -3.0,
      rsd10d: -15.0,
      price_5d_h: 13.08,
      price_5d_L: 10.56,
      price_1m_h: 15.36,
      price_1m_l: 8.5,
      price_3m_h: 17.64,
      price_3m_l: 8.5,
      price_6m_h: 30.95,
      price_6m_l: 8.5,
      price_52w_h: 40.25,
      price_52w_l: 8.5,
      price_pc_1d: -6.164,
      price_pc_1w: -13.565,
      price_pc_1m: -21.826,
      price_pc_6m: -60.433,
      price_pc_1y: -68.908,
      price_pc_ytd: -68.908,
    },
    3320: {
      id: "3320",
      c: 4.08,
      ma50: 3.868,
      ma100: 3.749,
      ma150: 3.791,
      ma200: 3.967,
      maw30: 3.761,
      rs: 76.0,
      rsd1d: -6.0,
      rsd5d: 6.0,
      rsd10d: 14.0,
      price_5d_h: 4.24,
      price_5d_L: 3.73,
      price_1m_h: 4.24,
      price_1m_l: 3.18,
      price_3m_h: 4.79,
      price_3m_l: 3.18,
      price_6m_h: 4.79,
      price_6m_l: 3.18,
      price_52w_h: 5.67,
      price_52w_l: 3.18,
      price_pc_1d: -1.687,
      price_pc_1w: 5.426,
      price_pc_1m: 5.699,
      price_pc_6m: 6.527,
      price_pc_1y: -17.409,
      price_pc_ytd: -17.409,
    },
    3321: {
      id: "3321",
      c: 1.44,
      ma50: 1.929,
      ma100: 2.141,
      ma150: 2.453,
      ma200: 2.626,
      maw30: 2.46,
      rs: 9.0,
      rsd1d: 0.0,
      rsd5d: 2.0,
      rsd10d: 1.0,
      price_5d_h: 1.57,
      price_5d_L: 0.79,
      price_1m_h: 1.94,
      price_1m_l: 0.79,
      price_3m_h: 2.7,
      price_3m_l: 0.79,
      price_6m_h: 3.38,
      price_6m_l: 0.79,
      price_52w_h: 5.19,
      price_52w_l: 0.79,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: -25.773,
      price_pc_6m: -55.0,
      price_pc_1y: -71.372,
      price_pc_ytd: -71.372,
    },
    3322: {
      id: "3322",
      c: 0.31,
      ma50: 0.32,
      ma100: 0.325,
      ma150: 0.327,
      ma200: 0.327,
      maw30: 0.33,
      rs: 58.0,
      rsd1d: -14.0,
      rsd5d: 11.0,
      rsd10d: 13.0,
      price_5d_h: 0.335,
      price_5d_L: 0.285,
      price_1m_h: 0.335,
      price_1m_l: 0.255,
      price_3m_h: 0.35,
      price_3m_l: 0.255,
      price_6m_h: 0.35,
      price_6m_l: 0.255,
      price_52w_h: 0.37,
      price_52w_l: 0.255,
      price_pc_1d: -6.061,
      price_pc_1w: 6.897,
      price_pc_1m: -7.463,
      price_pc_6m: -10.145,
      price_pc_1y: -10.145,
      price_pc_ytd: -10.145,
    },
    3323: {
      id: "3323",
      c: 9.74,
      ma50: 10.023,
      ma100: 9.705,
      ma150: 10.036,
      ma200: 9.858,
      maw30: 10.12,
      rs: 71.0,
      rsd1d: 2.0,
      rsd5d: 15.0,
      rsd10d: 14.0,
      price_5d_h: 9.88,
      price_5d_L: 8.88,
      price_1m_h: 10.4,
      price_1m_l: 7.32,
      price_3m_h: 11.6,
      price_3m_l: 7.32,
      price_6m_h: 11.6,
      price_6m_l: 7.32,
      price_52w_h: 13.58,
      price_52w_l: 7.32,
      price_pc_1d: 0.828,
      price_pc_1w: 7.387,
      price_pc_1m: -2.794,
      price_pc_6m: -7.59,
      price_pc_1y: -13.499,
      price_pc_ytd: -13.499,
    },
    3326: {
      id: "3326",
      c: 0.234,
      ma50: 0.26,
      ma100: 0.273,
      ma150: 0.277,
      ma200: 0.285,
      maw30: 0.277,
      rs: 27.0,
      rsd1d: -3.0,
      rsd5d: -4.0,
      rsd10d: -12.0,
      price_5d_h: 0.244,
      price_5d_L: 0.224,
      price_1m_h: 0.26,
      price_1m_l: 0.206,
      price_3m_h: 0.3,
      price_3m_l: 0.206,
      price_6m_h: 0.32,
      price_6m_l: 0.206,
      price_52w_h: 0.4,
      price_52w_l: 0.206,
      price_pc_1d: -3.306,
      price_pc_1w: -0.426,
      price_pc_1m: -13.333,
      price_pc_6m: -17.895,
      price_pc_1y: -31.176,
      price_pc_ytd: -31.176,
    },
    3328: {
      id: "3328",
      c: 5.62,
      ma50: 5.33,
      ma100: 5.049,
      ma150: 4.896,
      ma200: 4.86,
      maw30: 4.915,
      rs: 89.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: 0.0,
      price_5d_h: 5.67,
      price_5d_L: 5.3,
      price_1m_h: 5.67,
      price_1m_l: 4.87,
      price_3m_h: 5.67,
      price_3m_l: 4.71,
      price_6m_h: 5.67,
      price_6m_l: 4.47,
      price_52w_h: 5.67,
      price_52w_l: 4.35,
      price_pc_1d: 2.368,
      price_pc_1w: 5.243,
      price_pc_1m: 4.851,
      price_pc_6m: 21.645,
      price_pc_1y: 13.535,
      price_pc_ytd: 13.535,
    },
    3329: {
      id: "3329",
      c: 1.14,
      ma50: 1.295,
      ma100: 1.49,
      ma150: 1.584,
      ma200: 1.628,
      maw30: 1.584,
      rs: 17.0,
      rsd1d: 2.0,
      rsd5d: -4.0,
      rsd10d: -11.0,
      price_5d_h: 1.2,
      price_5d_L: 1.14,
      price_1m_h: 1.35,
      price_1m_l: 1.13,
      price_3m_h: 1.84,
      price_3m_l: 1.13,
      price_6m_h: 1.84,
      price_6m_l: 1.13,
      price_52w_h: 1.97,
      price_52w_l: 1.13,
      price_pc_1d: 0.0,
      price_pc_1w: -4.202,
      price_pc_1m: -14.286,
      price_pc_6m: -36.667,
      price_pc_1y: -31.737,
      price_pc_ytd: -31.737,
    },
    3330: {
      id: "3330",
      c: 0.99,
      ma50: 1.031,
      ma100: 0.998,
      ma150: 0.98,
      ma200: 0.99,
      maw30: 0.984,
      rs: 75.0,
      rsd1d: 6.0,
      rsd5d: -4.0,
      rsd10d: 13.0,
      price_5d_h: 1.02,
      price_5d_L: 0.95,
      price_1m_h: 1.09,
      price_1m_l: 0.88,
      price_3m_h: 1.12,
      price_3m_l: 0.88,
      price_6m_h: 1.12,
      price_6m_l: 0.88,
      price_52w_h: 1.3,
      price_52w_l: 0.88,
      price_pc_1d: 3.125,
      price_pc_1w: -2.941,
      price_pc_1m: -5.714,
      price_pc_6m: 6.452,
      price_pc_1y: -17.5,
      price_pc_ytd: -17.5,
    },
    3331: {
      id: "3331",
      c: 17.72,
      ma50: 20.134,
      ma100: 20.31,
      ma150: 21.015,
      ma200: 21.345,
      maw30: 21.126,
      rs: 37.0,
      rsd1d: 2.0,
      rsd5d: -9.0,
      rsd10d: -11.0,
      price_5d_h: 18.82,
      price_5d_L: 17.2,
      price_1m_h: 22.5,
      price_1m_l: 16.48,
      price_3m_h: 23.05,
      price_3m_l: 16.48,
      price_6m_h: 24.7,
      price_6m_l: 16.48,
      price_52w_h: 29.35,
      price_52w_l: 16.48,
      price_pc_1d: 0.34,
      price_pc_1w: -4.32,
      price_pc_1m: -20.36,
      price_pc_6m: -22.957,
      price_pc_1y: -32.751,
      price_pc_ytd: -32.751,
    },
    3332: {
      id: "3332",
      c: 0.11,
      ma50: 0.117,
      ma100: 0.135,
      ma150: 0.143,
      ma200: 0.15,
      maw30: 0.142,
      rs: 18.0,
      rsd1d: 3.0,
      rsd5d: 3.0,
      rsd10d: 2.0,
      price_5d_h: 0.11,
      price_5d_L: 0.11,
      price_1m_h: 0.113,
      price_1m_l: 0.105,
      price_3m_h: 0.157,
      price_3m_l: 0.105,
      price_6m_h: 0.17,
      price_6m_l: 0.105,
      price_52w_h: 0.214,
      price_52w_l: 0.105,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: -9.091,
      price_pc_6m: -28.105,
      price_pc_1y: -44.444,
      price_pc_ytd: -44.444,
    },
    3336: {
      id: "3336",
      c: 1.31,
      ma50: 1.485,
      ma100: 1.377,
      ma150: 1.41,
      ma200: 1.468,
      maw30: 1.412,
      rs: 50.0,
      rsd1d: -1.0,
      rsd5d: -10.0,
      rsd10d: -9.0,
      price_5d_h: 1.43,
      price_5d_L: 1.31,
      price_1m_h: 1.59,
      price_1m_l: 1.31,
      price_3m_h: 1.65,
      price_3m_l: 1.29,
      price_6m_h: 1.65,
      price_6m_l: 1.16,
      price_52w_h: 2.3,
      price_52w_l: 1.16,
      price_pc_1d: -3.676,
      price_pc_1w: -5.755,
      price_pc_1m: -13.245,
      price_pc_6m: -9.655,
      price_pc_1y: -37.915,
      price_pc_ytd: -37.915,
    },
    3337: {
      id: "3337",
      c: 0.44,
      ma50: 0.465,
      ma100: 0.459,
      ma150: 0.471,
      ma200: 0.472,
      maw30: 0.472,
      rs: 64.0,
      rsd1d: 11.0,
      rsd5d: 10.0,
      rsd10d: 14.0,
      price_5d_h: 0.44,
      price_5d_L: 0.4,
      price_1m_h: 0.5,
      price_1m_l: 0.385,
      price_3m_h: 0.52,
      price_3m_l: 0.385,
      price_6m_h: 0.56,
      price_6m_l: 0.385,
      price_52w_h: 0.58,
      price_52w_l: 0.385,
      price_pc_1d: 3.529,
      price_pc_1w: 3.529,
      price_pc_1m: -7.368,
      price_pc_6m: -12.0,
      price_pc_1y: -12.0,
      price_pc_ytd: -12.0,
    },
    3339: {
      id: "3339",
      c: 2.14,
      ma50: 2.15,
      ma100: 2.164,
      ma150: 2.244,
      ma200: 2.307,
      maw30: 2.243,
      rs: 50.0,
      rsd1d: 1.0,
      rsd5d: -4.0,
      rsd10d: -10.0,
      price_5d_h: 2.18,
      price_5d_L: 2.07,
      price_1m_h: 2.18,
      price_1m_l: 1.95,
      price_3m_h: 2.43,
      price_3m_l: 1.95,
      price_6m_h: 2.43,
      price_6m_l: 1.94,
      price_52w_h: 3.58,
      price_52w_l: 1.94,
      price_pc_1d: -0.926,
      price_pc_1w: 0.0,
      price_pc_1m: 2.885,
      price_pc_6m: -8.936,
      price_pc_1y: -35.152,
      price_pc_ytd: -35.152,
    },
    3347: {
      id: "3347",
      c: 95.4,
      ma50: 84.58,
      ma100: 99.218,
      ma150: 117.372,
      ma200: 127.927,
      maw30: 117.133,
      rs: 25.0,
      rsd1d: -2.0,
      rsd5d: -2.0,
      rsd10d: 9.0,
      price_5d_h: 98.4,
      price_5d_L: 85.8,
      price_1m_h: 98.4,
      price_1m_l: 67.1,
      price_3m_h: 100.1,
      price_3m_l: 67.1,
      price_6m_h: 168.0,
      price_6m_l: 67.1,
      price_52w_h: 193.8,
      price_52w_l: 67.1,
      price_pc_1d: -1.293,
      price_pc_1w: 0.0,
      price_pc_1m: 9.029,
      price_pc_6m: -42.426,
      price_pc_1y: -37.36,
      price_pc_ytd: -37.36,
    },
    3348: {
      id: "3348",
      c: 0.99,
      ma50: 1.012,
      ma100: 1.022,
      ma150: 1.026,
      ma200: 1.026,
      maw30: 1.026,
      rs: 72.0,
      rsd1d: -3.0,
      rsd5d: -7.0,
      rsd10d: -1.0,
      price_5d_h: 1.02,
      price_5d_L: 0.95,
      price_1m_h: 1.1,
      price_1m_l: 0.95,
      price_3m_h: 1.1,
      price_3m_l: 0.95,
      price_6m_h: 1.1,
      price_6m_l: 0.95,
      price_52w_h: 1.19,
      price_52w_l: 0.86,
      price_pc_1d: -1.98,
      price_pc_1w: -3.883,
      price_pc_1m: -2.941,
      price_pc_6m: -2.941,
      price_pc_1y: 6.452,
      price_pc_ytd: 6.452,
    },
    3360: {
      id: "3360",
      c: 7.0,
      ma50: 6.751,
      ma100: 6.846,
      ma150: 7.261,
      ma200: 7.531,
      maw30: 7.224,
      rs: 57.0,
      rsd1d: 5.0,
      rsd5d: 6.0,
      rsd10d: 3.0,
      price_5d_h: 7.09,
      price_5d_L: 6.53,
      price_1m_h: 7.09,
      price_1m_l: 5.78,
      price_3m_h: 7.28,
      price_3m_l: 5.78,
      price_6m_h: 7.93,
      price_6m_l: 5.78,
      price_52w_h: 9.58,
      price_52w_l: 5.78,
      price_pc_1d: 2.041,
      price_pc_1w: 2.79,
      price_pc_1m: 5.105,
      price_pc_6m: -11.392,
      price_pc_1y: -25.611,
      price_pc_ytd: -25.611,
    },
    3363: {
      id: "3363",
      c: 0.81,
      ma50: 0.671,
      ma100: 0.691,
      ma150: 0.705,
      ma200: 0.692,
      maw30: 0.698,
      rs: 89.0,
      rsd1d: 1.0,
      rsd5d: 40.0,
      rsd10d: 33.0,
      price_5d_h: 0.83,
      price_5d_L: 0.61,
      price_1m_h: 0.83,
      price_1m_l: 0.58,
      price_3m_h: 0.83,
      price_3m_l: 0.58,
      price_6m_h: 0.83,
      price_6m_l: 0.58,
      price_52w_h: 0.99,
      price_52w_l: 0.58,
      price_pc_1d: 3.846,
      price_pc_1w: 32.787,
      price_pc_1m: 20.896,
      price_pc_6m: 8.0,
      price_pc_1y: 17.391,
      price_pc_ytd: 17.391,
    },
    3368: {
      id: "3368",
      c: 0.182,
      ma50: 0.195,
      ma100: 0.216,
      ma150: 0.232,
      ma200: 0.246,
      maw30: 0.231,
      rs: 20.0,
      rsd1d: -4.0,
      rsd5d: -5.0,
      rsd10d: -7.0,
      price_5d_h: 0.192,
      price_5d_L: 0.182,
      price_1m_h: 0.209,
      price_1m_l: 0.146,
      price_3m_h: 0.245,
      price_3m_l: 0.146,
      price_6m_h: 0.29,
      price_6m_l: 0.146,
      price_52w_h: 0.35,
      price_52w_l: 0.146,
      price_pc_1d: -3.191,
      price_pc_1w: -3.191,
      price_pc_1m: -13.744,
      price_pc_6m: -28.627,
      price_pc_1y: -36.14,
      price_pc_ytd: -36.14,
    },
    3369: {
      id: "3369",
      c: 1.18,
      ma50: 1.253,
      ma100: 1.253,
      ma150: 1.273,
      ma200: 1.275,
      maw30: 1.278,
      rs: 55.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -1.0,
      price_5d_h: 1.22,
      price_5d_L: 1.18,
      price_1m_h: 1.34,
      price_1m_l: 1.08,
      price_3m_h: 1.34,
      price_3m_l: 1.08,
      price_6m_h: 1.4,
      price_6m_l: 1.08,
      price_52w_h: 1.43,
      price_52w_l: 1.08,
      price_pc_1d: -0.84,
      price_pc_1w: -2.479,
      price_pc_1m: -9.231,
      price_pc_6m: -11.278,
      price_pc_1y: -13.869,
      price_pc_ytd: -13.869,
    },
    3377: {
      id: "3377",
      c: 1.67,
      ma50: 1.77,
      ma100: 1.809,
      ma150: 1.741,
      ma200: 1.729,
      maw30: 1.747,
      rs: 70.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: 0.0,
      price_5d_h: 1.7,
      price_5d_L: 1.57,
      price_1m_h: 1.71,
      price_1m_l: 1.34,
      price_3m_h: 2.25,
      price_3m_l: 1.34,
      price_6m_h: 2.25,
      price_6m_l: 1.34,
      price_52w_h: 2.25,
      price_52w_l: 1.31,
      price_pc_1d: -0.595,
      price_pc_1w: 2.454,
      price_pc_1m: -0.595,
      price_pc_6m: 9.15,
      price_pc_1y: -3.468,
      price_pc_ytd: -3.468,
    },
    3378: {
      id: "3378",
      c: 0.84,
      ma50: 0.843,
      ma100: 0.835,
      ma150: 0.85,
      ma200: 0.873,
      maw30: 0.853,
      rs: 58.0,
      rsd1d: 0.0,
      rsd5d: 4.0,
      rsd10d: 15.0,
      price_5d_h: 0.87,
      price_5d_L: 0.82,
      price_1m_h: 0.87,
      price_1m_l: 0.73,
      price_3m_h: 0.9,
      price_3m_l: 0.73,
      price_6m_h: 0.9,
      price_6m_l: 0.73,
      price_52w_h: 1.24,
      price_52w_l: 0.73,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: 0.0,
      price_pc_6m: -2.326,
      price_pc_1y: -24.324,
      price_pc_ytd: -24.324,
    },
    3380: {
      id: "3380",
      c: 2.23,
      ma50: 3.497,
      ma100: 5.146,
      ma150: 6.177,
      ma200: 7.14,
      maw30: 6.17,
      rs: 2.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 2.39,
      price_5d_L: 1.95,
      price_1m_h: 2.72,
      price_1m_l: 1.3,
      price_3m_h: 6.9,
      price_3m_l: 1.3,
      price_6m_h: 9.18,
      price_6m_l: 1.3,
      price_52w_h: 13.26,
      price_52w_l: 1.3,
      price_pc_1d: -1.327,
      price_pc_1w: -0.446,
      price_pc_1m: -14.231,
      price_pc_6m: -72.604,
      price_pc_1y: -82.977,
      price_pc_ytd: -82.977,
    },
    3382: {
      id: "3382",
      c: 0.63,
      ma50: 0.625,
      ma100: 0.625,
      ma150: 0.635,
      ma200: 0.629,
      maw30: 0.639,
      rs: 75.0,
      rsd1d: -4.0,
      rsd5d: 10.0,
      rsd10d: 12.0,
      price_5d_h: 0.64,
      price_5d_L: 0.59,
      price_1m_h: 0.65,
      price_1m_l: 0.53,
      price_3m_h: 0.66,
      price_3m_l: 0.53,
      price_6m_h: 0.68,
      price_6m_l: 0.53,
      price_52w_h: 0.73,
      price_52w_l: 0.53,
      price_pc_1d: -1.563,
      price_pc_1w: 3.279,
      price_pc_1m: 0.0,
      price_pc_6m: -3.077,
      price_pc_1y: 0.0,
      price_pc_ytd: 0.0,
    },
    3383: {
      id: "3383",
      c: 3.97,
      ma50: 4.021,
      ma100: 4.383,
      ma150: 5.312,
      ma200: 6.363,
      maw30: 5.247,
      rs: 15.0,
      rsd1d: 1.0,
      rsd5d: -1.0,
      rsd10d: -12.0,
      price_5d_h: 4.27,
      price_5d_L: 3.7,
      price_1m_h: 4.65,
      price_1m_l: 2.92,
      price_3m_h: 4.8,
      price_3m_l: 2.92,
      price_6m_h: 7.44,
      price_6m_l: 2.92,
      price_52w_h: 13.22,
      price_52w_l: 2.92,
      price_pc_1d: -0.998,
      price_pc_1w: -5.251,
      price_pc_1m: 11.517,
      price_pc_6m: -45.467,
      price_pc_1y: -69.225,
      price_pc_ytd: -69.225,
    },
    3389: {
      id: "3389",
      c: 0.265,
      ma50: 0.274,
      ma100: 0.281,
      ma150: 0.286,
      ma200: 0.292,
      maw30: 0.286,
      rs: 52.0,
      rsd1d: 5.0,
      rsd5d: -6.0,
      rsd10d: -6.0,
      price_5d_h: 0.275,
      price_5d_L: 0.255,
      price_1m_h: 0.275,
      price_1m_l: 0.255,
      price_3m_h: 0.3,
      price_3m_l: 0.255,
      price_6m_h: 0.35,
      price_6m_l: 0.255,
      price_52w_h: 0.35,
      price_52w_l: 0.255,
      price_pc_1d: 0.0,
      price_pc_1w: -1.852,
      price_pc_1m: -1.852,
      price_pc_6m: -11.667,
      price_pc_1y: -14.516,
      price_pc_ytd: -14.516,
    },
    3390: {
      id: "3390",
      c: 1.27,
      ma50: 1.303,
      ma100: 1.305,
      ma150: 1.358,
      ma200: 1.426,
      maw30: 1.353,
      rs: 47.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: -15.0,
      price_5d_h: 1.32,
      price_5d_L: 1.23,
      price_1m_h: 1.36,
      price_1m_l: 1.23,
      price_3m_h: 1.46,
      price_3m_l: 1.15,
      price_6m_h: 1.48,
      price_6m_l: 1.13,
      price_52w_h: 1.86,
      price_52w_l: 1.13,
      price_pc_1d: 0.0,
      price_pc_1w: 0.794,
      price_pc_1m: -2.308,
      price_pc_6m: -12.414,
      price_pc_1y: -19.62,
      price_pc_ytd: -19.62,
    },
    3393: {
      id: "3393",
      c: 2.82,
      ma50: 2.998,
      ma100: 3.065,
      ma150: 2.932,
      ma200: 2.831,
      maw30: 2.928,
      rs: 76.0,
      rsd1d: -7.0,
      rsd5d: 1.0,
      rsd10d: 9.0,
      price_5d_h: 2.93,
      price_5d_L: 2.72,
      price_1m_h: 3.13,
      price_1m_l: 2.25,
      price_3m_h: 3.92,
      price_3m_l: 2.25,
      price_6m_h: 4.03,
      price_6m_l: 2.25,
      price_52w_h: 4.03,
      price_52w_l: 2.13,
      price_pc_1d: -3.425,
      price_pc_1w: -2.759,
      price_pc_1m: -6.623,
      price_pc_6m: 7.224,
      price_pc_1y: 18.487,
      price_pc_ytd: 18.487,
    },
    3396: {
      id: "3396",
      c: 9.86,
      ma50: 10.705,
      ma100: 11.473,
      ma150: 12.575,
      ma200: 12.46,
      maw30: 12.682,
      rs: 34.0,
      rsd1d: -7.0,
      rsd5d: -3.0,
      rsd10d: -3.0,
      price_5d_h: 10.92,
      price_5d_L: 9.6,
      price_1m_h: 11.2,
      price_1m_l: 8.36,
      price_3m_h: 12.3,
      price_3m_l: 8.36,
      price_6m_h: 16.48,
      price_6m_l: 8.36,
      price_52w_h: 18.12,
      price_52w_l: 8.36,
      price_pc_1d: -7.331,
      price_pc_1w: -5.916,
      price_pc_1m: -10.2,
      price_pc_6m: -34.702,
      price_pc_1y: -21.246,
      price_pc_ytd: -21.246,
    },
    3398: {
      id: "3398",
      c: 0.265,
      ma50: 0.314,
      ma100: 0.323,
      ma150: 0.322,
      ma200: 0.324,
      maw30: 0.324,
      rs: 40.0,
      rsd1d: -23.0,
      rsd5d: -19.0,
      rsd10d: -33.0,
      price_5d_h: 0.305,
      price_5d_L: 0.25,
      price_1m_h: 0.33,
      price_1m_l: 0.25,
      price_3m_h: 0.345,
      price_3m_l: 0.25,
      price_6m_h: 0.35,
      price_6m_l: 0.25,
      price_52w_h: 0.38,
      price_52w_l: 0.25,
      price_pc_1d: -13.115,
      price_pc_1w: -13.115,
      price_pc_1m: -17.187,
      price_pc_6m: -17.187,
      price_pc_1y: -28.378,
      price_pc_ytd: -28.378,
    },
    3399: {
      id: "3399",
      c: 0.93,
      ma50: 1.044,
      ma100: 1.065,
      ma150: 1.136,
      ma200: 1.222,
      maw30: 1.13,
      rs: 29.0,
      rsd1d: 2.0,
      rsd5d: 0.0,
      rsd10d: -3.0,
      price_5d_h: 0.97,
      price_5d_L: 0.9,
      price_1m_h: 1.09,
      price_1m_l: 0.88,
      price_3m_h: 1.19,
      price_3m_l: 0.88,
      price_6m_h: 1.34,
      price_6m_l: 0.88,
      price_52w_h: 1.6,
      price_52w_l: 0.88,
      price_pc_1d: -1.064,
      price_pc_1w: -4.124,
      price_pc_1m: -14.679,
      price_pc_6m: -22.5,
      price_pc_1y: -32.117,
      price_pc_ytd: -32.117,
    },
    3600: {
      id: "3600",
      c: 2.99,
      ma50: 3.994,
      ma100: 4.896,
      ma150: 5.78,
      ma200: 6.232,
      maw30: 5.723,
      rs: 19.0,
      rsd1d: -5.0,
      rsd5d: -7.0,
      rsd10d: -3.0,
      price_5d_h: 3.28,
      price_5d_L: 2.92,
      price_1m_h: 4.15,
      price_1m_l: 2.71,
      price_3m_h: 5.77,
      price_3m_l: 2.71,
      price_6m_h: 7.9,
      price_6m_l: 2.71,
      price_52w_h: 11.0,
      price_52w_l: 2.22,
      price_pc_1d: -3.859,
      price_pc_1w: -8.0,
      price_pc_1m: -26.173,
      price_pc_6m: -62.247,
      price_pc_1y: 23.554,
      price_pc_ytd: 23.554,
    },
    3601: {
      id: "3601",
      c: 1.85,
      ma50: 1.57,
      ma100: 1.694,
      ma150: 1.72,
      ma200: 1.845,
      maw30: 1.71,
      rs: 67.0,
      rsd1d: 6.0,
      rsd5d: 48.0,
      rsd10d: 47.0,
      price_5d_h: 1.94,
      price_5d_L: 1.34,
      price_1m_h: 1.94,
      price_1m_l: 1.19,
      price_3m_h: 1.94,
      price_3m_l: 1.19,
      price_6m_h: 2.4,
      price_6m_l: 1.19,
      price_52w_h: 3.44,
      price_52w_l: 1.19,
      price_pc_1d: 3.933,
      price_pc_1w: 31.206,
      price_pc_1m: 15.625,
      price_pc_6m: 12.121,
      price_pc_1y: -37.288,
      price_pc_ytd: -37.288,
    },
    3603: {
      id: "3603",
      c: 0.66,
      ma50: 0.616,
      ma100: 0.691,
      ma150: 0.814,
      ma200: 0.908,
      maw30: 0.808,
      rs: 24.0,
      rsd1d: 1.0,
      rsd5d: 3.0,
      rsd10d: -1.0,
      price_5d_h: 0.69,
      price_5d_L: 0.63,
      price_1m_h: 0.71,
      price_1m_l: 0.58,
      price_3m_h: 0.72,
      price_3m_l: 0.49,
      price_6m_h: 1.17,
      price_6m_l: 0.49,
      price_52w_h: 1.64,
      price_52w_l: 0.49,
      price_pc_1d: -1.493,
      price_pc_1w: 1.538,
      price_pc_1m: 8.197,
      price_pc_6m: -39.45,
      price_pc_1y: -60.0,
      price_pc_ytd: -60.0,
    },
    3606: {
      id: "3606",
      c: 32.2,
      ma50: 37.275,
      ma100: 39.812,
      ma150: 41.292,
      ma200: 43.896,
      maw30: 41.362,
      rs: 26.0,
      rsd1d: 0.0,
      rsd5d: 3.0,
      rsd10d: -1.0,
      price_5d_h: 33.55,
      price_5d_L: 29.0,
      price_1m_h: 40.15,
      price_1m_l: 25.7,
      price_3m_h: 46.2,
      price_3m_l: 25.7,
      price_6m_h: 48.4,
      price_6m_l: 25.7,
      price_52w_h: 58.45,
      price_52w_l: 25.7,
      price_pc_1d: -2.866,
      price_pc_1w: 2.548,
      price_pc_1m: -15.707,
      price_pc_6m: -22.689,
      price_pc_1y: -29.617,
      price_pc_ytd: -29.617,
    },
    3613: {
      id: "3613",
      c: 10.86,
      ma50: 11.192,
      ma100: 11.255,
      ma150: 10.922,
      ma200: 11.11,
      maw30: 10.973,
      rs: 58.0,
      rsd1d: -12.0,
      rsd5d: -20.0,
      rsd10d: -9.0,
      price_5d_h: 11.82,
      price_5d_L: 10.8,
      price_1m_h: 11.82,
      price_1m_l: 8.99,
      price_3m_h: 14.28,
      price_3m_l: 8.99,
      price_6m_h: 14.3,
      price_6m_l: 8.99,
      price_52w_h: 14.3,
      price_52w_l: 8.99,
      price_pc_1d: -3.036,
      price_pc_1w: -6.218,
      price_pc_1m: 0.185,
      price_pc_6m: 6.262,
      price_pc_1y: -2.162,
      price_pc_ytd: -2.162,
    },
    3616: {
      id: "3616",
      c: 1.08,
      ma50: 0.99,
      ma100: 1.01,
      ma150: 1.125,
      ma200: 1.203,
      maw30: 1.114,
      rs: 52.0,
      rsd1d: 11.0,
      rsd5d: 13.0,
      rsd10d: 9.0,
      price_5d_h: 1.09,
      price_5d_L: 0.95,
      price_1m_h: 1.09,
      price_1m_l: 0.86,
      price_3m_h: 1.09,
      price_3m_l: 0.74,
      price_6m_h: 1.4,
      price_6m_l: 0.74,
      price_52w_h: 1.73,
      price_52w_l: 0.74,
      price_pc_1d: 9.091,
      price_pc_1w: 9.091,
      price_pc_1m: 14.894,
      price_pc_6m: -22.302,
      price_pc_1y: -22.302,
      price_pc_ytd: -22.302,
    },
    3618: {
      id: "3618",
      c: 3.08,
      ma50: 2.912,
      ma100: 2.831,
      ma150: 2.845,
      ma200: 2.89,
      maw30: 2.843,
      rs: 81.0,
      rsd1d: 5.0,
      rsd5d: 11.0,
      rsd10d: 14.0,
      price_5d_h: 3.11,
      price_5d_L: 2.88,
      price_1m_h: 3.11,
      price_1m_l: 2.63,
      price_3m_h: 3.11,
      price_3m_l: 2.63,
      price_6m_h: 3.11,
      price_6m_l: 2.59,
      price_52w_h: 3.62,
      price_52w_l: 2.59,
      price_pc_1d: 2.326,
      price_pc_1w: 6.207,
      price_pc_1m: 6.944,
      price_pc_6m: 8.451,
      price_pc_1y: -8.333,
      price_pc_ytd: -8.333,
    },
    3633: {
      id: "3633",
      c: 7.17,
      ma50: 7.459,
      ma100: 7.608,
      ma150: 7.346,
      ma200: 7.166,
      maw30: 7.387,
      rs: 75.0,
      rsd1d: -6.0,
      rsd5d: -3.0,
      rsd10d: 2.0,
      price_5d_h: 7.65,
      price_5d_L: 7.16,
      price_1m_h: 7.65,
      price_1m_l: 6.11,
      price_3m_h: 8.5,
      price_3m_l: 6.11,
      price_6m_h: 8.5,
      price_6m_l: 6.11,
      price_52w_h: 8.5,
      price_52w_l: 6.11,
      price_pc_1d: -2.582,
      price_pc_1w: -1.376,
      price_pc_1m: -1.646,
      price_pc_6m: 12.559,
      price_pc_1y: 5.752,
      price_pc_ytd: 5.752,
    },
    3636: {
      id: "3636",
      c: 4.78,
      ma50: 4.733,
      ma100: 4.699,
      ma150: 4.884,
      ma200: 4.744,
      maw30: 5.013,
      rs: 82.0,
      rsd1d: -5.0,
      rsd5d: 2.0,
      rsd10d: 13.0,
      price_5d_h: 5.0,
      price_5d_L: 4.51,
      price_1m_h: 5.1,
      price_1m_l: 3.8,
      price_3m_h: 5.7,
      price_3m_l: 3.8,
      price_6m_h: 5.84,
      price_6m_l: 3.8,
      price_52w_h: 7.14,
      price_52w_l: 3.8,
      price_pc_1d: -4.208,
      price_pc_1w: 0.632,
      price_pc_1m: -1.646,
      price_pc_6m: 1.271,
      price_pc_1y: 20.403,
      price_pc_ytd: 20.403,
    },
    3662: {
      id: "3662",
      c: 1.86,
      ma50: 2.228,
      ma100: 2.497,
      ma150: 3.017,
      ma200: 3.56,
      maw30: 3.006,
      rs: 10.0,
      rsd1d: 3.0,
      rsd5d: 1.0,
      rsd10d: 0.0,
      price_5d_h: 2.03,
      price_5d_L: 1.58,
      price_1m_h: 2.33,
      price_1m_l: 1.54,
      price_3m_h: 2.76,
      price_3m_l: 1.54,
      price_6m_h: 4.44,
      price_6m_l: 1.54,
      price_52w_h: 7.26,
      price_52w_l: 1.54,
      price_pc_1d: 0.0,
      price_pc_1w: -5.102,
      price_pc_1m: -18.062,
      price_pc_6m: -55.714,
      price_pc_1y: -63.813,
      price_pc_ytd: -63.813,
    },
    3666: {
      id: "3666",
      c: 0.075,
      ma50: 0.067,
      ma100: 0.064,
      ma150: 0.064,
      ma200: 0.065,
      maw30: 0.064,
      rs: 84.0,
      rsd1d: -1.0,
      rsd5d: 36.0,
      rsd10d: 17.0,
      price_5d_h: 0.079,
      price_5d_L: 0.052,
      price_1m_h: 0.079,
      price_1m_l: 0.05,
      price_3m_h: 0.079,
      price_3m_l: 0.05,
      price_6m_h: 0.079,
      price_6m_l: 0.05,
      price_52w_h: 0.085,
      price_52w_l: 0.05,
      price_pc_1d: -3.846,
      price_pc_1w: 36.364,
      price_pc_1m: 20.968,
      price_pc_6m: 27.119,
      price_pc_1y: -10.714,
      price_pc_ytd: -10.714,
    },
    3668: {
      id: "3668",
      c: 25.55,
      ma50: 22.694,
      ma100: 19.905,
      ma150: 18.99,
      ma200: 17.52,
      maw30: 19.091,
      rs: 96.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 26.8,
      price_5d_L: 24.8,
      price_1m_h: 49.4,
      price_1m_l: 20.0,
      price_3m_h: 49.4,
      price_3m_l: 17.28,
      price_6m_h: 49.4,
      price_6m_l: 14.8,
      price_52w_h: 49.4,
      price_52w_l: 11.02,
      price_pc_1d: 2.2,
      price_pc_1w: -2.107,
      price_pc_1m: 29.827,
      price_pc_6m: 75.0,
      price_pc_1y: 104.4,
      price_pc_ytd: 104.4,
    },
    3669: {
      id: "3669",
      c: 8.53,
      ma50: 9.128,
      ma100: 10.096,
      ma150: 10.755,
      ma200: 11.587,
      maw30: 10.76,
      rs: 24.0,
      rsd1d: 2.0,
      rsd5d: -1.0,
      rsd10d: 3.0,
      price_5d_h: 8.9,
      price_5d_L: 7.91,
      price_1m_h: 9.54,
      price_1m_l: 6.51,
      price_3m_h: 10.5,
      price_3m_l: 6.51,
      price_6m_h: 13.58,
      price_6m_l: 6.51,
      price_52w_h: 17.14,
      price_52w_l: 6.51,
      price_pc_1d: 0.471,
      price_pc_1w: -1.387,
      price_pc_1m: -6.264,
      price_pc_6m: -24.11,
      price_pc_1y: -40.682,
      price_pc_ytd: -40.682,
    },
    3680: {
      id: "3680",
      c: 5.32,
      ma50: 6.273,
      ma100: 6.489,
      ma150: 6.566,
      ma200: 6.604,
      maw30: 6.626,
      rs: 32.0,
      rsd1d: 4.0,
      rsd5d: -1.0,
      rsd10d: -14.0,
      price_5d_h: 6.0,
      price_5d_L: 4.85,
      price_1m_h: 6.8,
      price_1m_l: 4.71,
      price_3m_h: 9.4,
      price_3m_l: 4.71,
      price_6m_h: 9.4,
      price_6m_l: 4.71,
      price_52w_h: 9.4,
      price_52w_l: 4.71,
      price_pc_1d: 0.758,
      price_pc_1w: 0.377,
      price_pc_1m: -16.088,
      price_pc_6m: -20.833,
      price_pc_1y: -20.833,
      price_pc_ytd: -20.833,
    },
    3681: {
      id: "3681",
      c: 2.2,
      ma50: 2.499,
      ma100: 2.685,
      ma150: 2.799,
      ma200: 2.928,
      maw30: 2.795,
      rs: 21.0,
      rsd1d: 1.0,
      rsd5d: -1.0,
      rsd10d: 3.0,
      price_5d_h: 2.29,
      price_5d_L: 2.15,
      price_1m_h: 2.63,
      price_1m_l: 1.98,
      price_3m_h: 3.0,
      price_3m_l: 1.98,
      price_6m_h: 3.25,
      price_6m_l: 1.98,
      price_52w_h: 4.19,
      price_52w_l: 1.98,
      price_pc_1d: -2.222,
      price_pc_1w: -3.93,
      price_pc_1m: -14.062,
      price_pc_6m: -24.915,
      price_pc_1y: -47.494,
      price_pc_ytd: -47.494,
    },
    3686: {
      id: "3686",
      c: 0.47,
      ma50: 0.492,
      ma100: 0.501,
      ma150: 0.511,
      ma200: 0.522,
      maw30: 0.51,
      rs: 50.0,
      rsd1d: -5.0,
      rsd5d: -6.0,
      rsd10d: -14.0,
      price_5d_h: 0.51,
      price_5d_L: 0.47,
      price_1m_h: 0.52,
      price_1m_l: 0.47,
      price_3m_h: 0.52,
      price_3m_l: 0.47,
      price_6m_h: 0.54,
      price_6m_l: 0.47,
      price_52w_h: 0.67,
      price_52w_l: 0.47,
      price_pc_1d: -3.093,
      price_pc_1w: -3.093,
      price_pc_1m: -7.843,
      price_pc_6m: -9.615,
      price_pc_1y: -18.966,
      price_pc_ytd: -18.966,
    },
    3688: {
      id: "3688",
      c: 0.86,
      ma50: 0.969,
      ma100: 1.018,
      ma150: 1.074,
      ma200: 1.131,
      maw30: 1.077,
      rs: 28.0,
      rsd1d: -3.0,
      rsd5d: -21.0,
      rsd10d: -4.0,
      price_5d_h: 0.99,
      price_5d_L: 0.86,
      price_1m_h: 1.06,
      price_1m_l: 0.83,
      price_3m_h: 1.11,
      price_3m_l: 0.83,
      price_6m_h: 1.31,
      price_6m_l: 0.83,
      price_52w_h: 1.55,
      price_52w_l: 0.83,
      price_pc_1d: -4.444,
      price_pc_1w: -16.505,
      price_pc_1m: -14.0,
      price_pc_6m: -26.496,
      price_pc_1y: -29.508,
      price_pc_ytd: -29.508,
    },
    3690: {
      id: "3690",
      c: 155.6,
      ma50: 182.644,
      ma100: 213.131,
      ma150: 227.299,
      ma200: 236.014,
      maw30: 229.183,
      rs: 13.0,
      rsd1d: 0.0,
      rsd5d: 3.0,
      rsd10d: -2.0,
      price_5d_h: 165.4,
      price_5d_L: 132.6,
      price_1m_h: 181.9,
      price_1m_l: 103.5,
      price_3m_h: 242.4,
      price_3m_l: 103.5,
      price_6m_h: 298.0,
      price_6m_l: 103.5,
      price_52w_h: 338.0,
      price_52w_l: 103.5,
      price_pc_1d: -2.811,
      price_pc_1w: 5.85,
      price_pc_1m: -9.64,
      price_pc_6m: -36.902,
      price_pc_1y: -52.241,
      price_pc_ytd: -52.241,
    },
    3692: {
      id: "3692",
      c: 13.12,
      ma50: 16.242,
      ma100: 17.104,
      ma150: 18.02,
      ma200: 21.013,
      maw30: 18.066,
      rs: 11.0,
      rsd1d: -4.0,
      rsd5d: -8.0,
      rsd10d: -10.0,
      price_5d_h: 16.2,
      price_5d_L: 12.6,
      price_1m_h: 17.12,
      price_1m_l: 12.36,
      price_3m_h: 19.5,
      price_3m_l: 12.36,
      price_6m_h: 20.95,
      price_6m_l: 12.36,
      price_52w_h: 37.95,
      price_52w_l: 12.36,
      price_pc_1d: -14.024,
      price_pc_1w: -19.509,
      price_pc_1m: -21.531,
      price_pc_6m: -33.737,
      price_pc_1y: -64.779,
      price_pc_ytd: -64.779,
    },
    3698: {
      id: "3698",
      c: 2.54,
      ma50: 2.566,
      ma100: 2.583,
      ma150: 2.581,
      ma200: 2.576,
      maw30: 2.578,
      rs: 71.0,
      rsd1d: 2.0,
      rsd5d: 5.0,
      rsd10d: -4.0,
      price_5d_h: 2.54,
      price_5d_L: 2.5,
      price_1m_h: 2.62,
      price_1m_l: 2.38,
      price_3m_h: 2.63,
      price_3m_l: 2.38,
      price_6m_h: 2.69,
      price_6m_l: 2.38,
      price_52w_h: 2.78,
      price_52w_l: 2.38,
      price_pc_1d: 1.195,
      price_pc_1w: 1.195,
      price_pc_1m: -1.167,
      price_pc_6m: -0.781,
      price_pc_1y: -0.781,
      price_pc_ytd: -0.781,
    },
    3699: {
      id: "3699",
      c: 0.475,
      ma50: 0.498,
      ma100: 0.504,
      ma150: 0.516,
      ma200: 0.535,
      maw30: 0.514,
      rs: 54.0,
      rsd1d: 0.0,
      rsd5d: -6.0,
      rsd10d: -2.0,
      price_5d_h: 0.5,
      price_5d_L: 0.475,
      price_1m_h: 0.5,
      price_1m_l: 0.47,
      price_3m_h: 0.62,
      price_3m_l: 0.47,
      price_6m_h: 0.62,
      price_6m_l: 0.47,
      price_52w_h: 0.71,
      price_52w_l: 0.47,
      price_pc_1d: -2.062,
      price_pc_1w: -5.0,
      price_pc_1m: -4.04,
      price_pc_6m: -10.377,
      price_pc_1y: -15.179,
      price_pc_ytd: -15.179,
    },
    3700: {
      id: "3700",
      c: 1.58,
      ma50: 1.803,
      ma100: 1.656,
      ma150: 1.633,
      ma200: 1.696,
      maw30: 1.637,
      rs: 49.0,
      rsd1d: -4.0,
      rsd5d: 8.0,
      rsd10d: 10.0,
      price_5d_h: 1.63,
      price_5d_L: 1.45,
      price_1m_h: 2.17,
      price_1m_l: 1.12,
      price_3m_h: 2.3,
      price_3m_l: 1.12,
      price_6m_h: 2.3,
      price_6m_l: 1.12,
      price_52w_h: 2.99,
      price_52w_l: 1.12,
      price_pc_1d: -1.25,
      price_pc_1w: 3.947,
      price_pc_1m: -21.782,
      price_pc_6m: 5.333,
      price_pc_1y: -32.189,
      price_pc_ytd: -32.189,
    },
    3709: {
      id: "3709",
      c: 13.1,
      ma50: 12.236,
      ma100: 11.562,
      ma150: 11.469,
      ma200: 11.214,
      maw30: 11.575,
      rs: 84.0,
      rsd1d: 2.0,
      rsd5d: -6.0,
      rsd10d: -4.0,
      price_5d_h: 13.1,
      price_5d_L: 11.62,
      price_1m_h: 13.4,
      price_1m_l: 10.62,
      price_3m_h: 13.4,
      price_3m_l: 10.62,
      price_6m_h: 13.5,
      price_6m_l: 10.0,
      price_52w_h: 13.5,
      price_52w_l: 8.93,
      price_pc_1d: 9.167,
      price_pc_1w: 3.476,
      price_pc_1m: 3.476,
      price_pc_6m: 14.111,
      price_pc_1y: 40.86,
      price_pc_ytd: 40.86,
    },
    3718: {
      id: "3718",
      c: 0.74,
      ma50: 0.6,
      ma100: 0.588,
      ma150: 0.664,
      ma200: 0.725,
      maw30: 0.665,
      rs: 76.0,
      rsd1d: 21.0,
      rsd5d: 46.0,
      rsd10d: 50.0,
      price_5d_h: 0.76,
      price_5d_L: 0.56,
      price_1m_h: 0.76,
      price_1m_l: 0.48,
      price_3m_h: 0.76,
      price_3m_l: 0.48,
      price_6m_h: 0.87,
      price_6m_l: 0.47,
      price_52w_h: 1.43,
      price_52w_l: 0.47,
      price_pc_1d: 12.121,
      price_pc_1w: 27.586,
      price_pc_1m: 10.448,
      price_pc_6m: -8.642,
      price_pc_1y: -40.323,
      price_pc_ytd: -40.323,
    },
    3728: {
      id: "3728",
      c: 0.167,
      ma50: 0.159,
      ma100: 0.165,
      ma150: 0.169,
      ma200: 0.173,
      maw30: 0.171,
      rs: 66.0,
      rsd1d: 9.0,
      rsd5d: 5.0,
      rsd10d: -5.0,
      price_5d_h: 0.167,
      price_5d_L: 0.161,
      price_1m_h: 0.169,
      price_1m_l: 0.135,
      price_3m_h: 0.185,
      price_3m_l: 0.135,
      price_6m_h: 0.197,
      price_6m_l: 0.135,
      price_52w_h: 0.2,
      price_52w_l: 0.135,
      price_pc_1d: 3.727,
      price_pc_1w: 0.0,
      price_pc_1m: 12.838,
      price_pc_6m: -6.704,
      price_pc_1y: -13.918,
      price_pc_ytd: -13.918,
    },
    3737: {
      id: "3737",
      c: 1.07,
      ma50: 0.938,
      ma100: 0.971,
      ma150: 1.036,
      ma200: 1.125,
      maw30: 1.024,
      rs: 63.0,
      rsd1d: 16.0,
      rsd5d: 26.0,
      rsd10d: 29.0,
      price_5d_h: 1.1,
      price_5d_L: 0.82,
      price_1m_h: 1.15,
      price_1m_l: 0.7,
      price_3m_h: 1.27,
      price_3m_l: 0.7,
      price_6m_h: 1.27,
      price_6m_l: 0.7,
      price_52w_h: 1.66,
      price_52w_l: 0.7,
      price_pc_1d: 13.83,
      price_pc_1w: 16.304,
      price_pc_1m: 7.0,
      price_pc_6m: -3.604,
      price_pc_1y: -13.71,
      price_pc_ytd: -13.71,
    },
    3738: {
      id: "3738",
      c: 4.78,
      ma50: 5.152,
      ma100: 5.994,
      ma150: 6.339,
      ma200: 6.648,
      maw30: 6.278,
      rs: 25.0,
      rsd1d: 0.0,
      rsd5d: 3.0,
      rsd10d: 7.0,
      price_5d_h: 4.96,
      price_5d_L: 4.38,
      price_1m_h: 5.85,
      price_1m_l: 3.23,
      price_3m_h: 6.57,
      price_3m_l: 3.23,
      price_6m_h: 8.49,
      price_6m_l: 3.23,
      price_52w_h: 9.7,
      price_52w_l: 3.23,
      price_pc_1d: -1.035,
      price_pc_1w: -2.049,
      price_pc_1m: -16.287,
      price_pc_6m: -23.397,
      price_pc_1y: -23.673,
      price_pc_ytd: -23.673,
    },
    3759: {
      id: "3759",
      c: 95.45,
      ma50: 93.622,
      ma100: 115.702,
      ma150: 134.257,
      ma200: 145.872,
      maw30: 133.863,
      rs: 16.0,
      rsd1d: -1.0,
      rsd5d: -2.0,
      rsd10d: 2.0,
      price_5d_h: 102.0,
      price_5d_L: 93.1,
      price_1m_h: 102.0,
      price_1m_l: 68.0,
      price_3m_h: 125.0,
      price_3m_l: 68.0,
      price_6m_h: 192.9,
      price_6m_l: 68.0,
      price_52w_h: 212.0,
      price_52w_l: 68.0,
      price_pc_1d: -3.829,
      price_pc_1w: -2.503,
      price_pc_1m: 1.112,
      price_pc_6m: -48.765,
      price_pc_1y: -36.154,
      price_pc_ytd: -36.154,
    },
    3768: {
      id: "3768",
      c: 1.78,
      ma50: 1.86,
      ma100: 1.843,
      ma150: 1.869,
      ma200: 1.896,
      maw30: 1.873,
      rs: 62.0,
      rsd1d: 1.0,
      rsd5d: -5.0,
      rsd10d: -3.0,
      price_5d_h: 1.88,
      price_5d_L: 1.71,
      price_1m_h: 1.9,
      price_1m_l: 1.68,
      price_3m_h: 1.94,
      price_3m_l: 1.68,
      price_6m_h: 2.29,
      price_6m_l: 1.68,
      price_52w_h: 2.29,
      price_52w_l: 1.68,
      price_pc_1d: -0.559,
      price_pc_1w: -2.732,
      price_pc_1m: -5.319,
      price_pc_6m: -7.292,
      price_pc_1y: -9.184,
      price_pc_ytd: -9.184,
    },
    3773: {
      id: "3773",
      c: 0.84,
      ma50: 0.724,
      ma100: 0.677,
      ma150: 0.594,
      ma200: 0.547,
      maw30: 0.592,
      rs: 96.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: 1.0,
      price_5d_h: 0.9,
      price_5d_L: 0.75,
      price_1m_h: 0.93,
      price_1m_l: 0.63,
      price_3m_h: 0.93,
      price_3m_l: 0.6,
      price_6m_h: 0.93,
      price_6m_l: 0.34,
      price_52w_h: 0.93,
      price_52w_l: 0.34,
      price_pc_1d: 6.329,
      price_pc_1w: 9.091,
      price_pc_1m: 13.514,
      price_pc_6m: 121.053,
      price_pc_1y: 93.103,
      price_pc_ytd: 93.103,
    },
    3778: {
      id: "3778",
      c: 0.485,
      ma50: 0.477,
      ma100: 0.48,
      ma150: 0.48,
      ma200: 0.48,
      maw30: 0.481,
      rs: 77.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: 10.0,
      price_5d_h: 0.485,
      price_5d_L: 0.48,
      price_1m_h: 0.485,
      price_1m_l: 0.4,
      price_3m_h: 0.57,
      price_3m_l: 0.4,
      price_6m_h: 0.59,
      price_6m_l: 0.4,
      price_52w_h: 1.0,
      price_52w_l: 0.4,
      price_pc_1d: 1.042,
      price_pc_1w: 1.042,
      price_pc_1m: 0.0,
      price_pc_6m: 0.0,
      price_pc_1y: 3.191,
      price_pc_ytd: 3.191,
    },
    3788: {
      id: "3788",
      c: 1.52,
      ma50: 1.48,
      ma100: 1.42,
      ma150: 1.488,
      ma200: 1.461,
      maw30: 1.49,
      rs: 85.0,
      rsd1d: -1.0,
      rsd5d: 1.0,
      rsd10d: 24.0,
      price_5d_h: 1.52,
      price_5d_L: 1.4,
      price_1m_h: 1.53,
      price_1m_l: 1.14,
      price_3m_h: 1.63,
      price_3m_l: 1.14,
      price_6m_h: 1.65,
      price_6m_l: 1.14,
      price_52w_h: 2.37,
      price_52w_l: 1.14,
      price_pc_1d: 0.0,
      price_pc_1w: 1.333,
      price_pc_1m: 3.401,
      price_pc_6m: -1.935,
      price_pc_1y: 10.145,
      price_pc_ytd: 10.145,
    },
    3789: {
      id: "3789",
      c: 0.122,
      ma50: 0.14,
      ma100: 0.157,
      ma150: 0.155,
      ma200: 0.148,
      maw30: 0.157,
      rs: 58.0,
      rsd1d: 6.0,
      rsd5d: 16.0,
      rsd10d: 21.0,
      price_5d_h: 0.129,
      price_5d_L: 0.108,
      price_1m_h: 0.155,
      price_1m_l: 0.104,
      price_3m_h: 0.158,
      price_3m_l: 0.104,
      price_6m_h: 0.305,
      price_6m_l: 0.104,
      price_52w_h: 0.305,
      price_52w_l: 0.103,
      price_pc_1d: -2.4,
      price_pc_1w: -6.154,
      price_pc_1m: -18.667,
      price_pc_6m: 5.172,
      price_pc_1y: -17.568,
      price_pc_ytd: -17.568,
    },
    3798: {
      id: "3798",
      c: 1.42,
      ma50: 1.388,
      ma100: 1.531,
      ma150: 1.712,
      ma200: 2.248,
      maw30: 1.705,
      rs: 10.0,
      rsd1d: 2.0,
      rsd5d: 1.0,
      rsd10d: 1.0,
      price_5d_h: 1.58,
      price_5d_L: 1.31,
      price_1m_h: 1.58,
      price_1m_l: 1.0,
      price_3m_h: 1.91,
      price_3m_l: 1.0,
      price_6m_h: 2.21,
      price_6m_l: 1.0,
      price_52w_h: 6.26,
      price_52w_l: 1.0,
      price_pc_1d: 2.158,
      price_pc_1w: 2.899,
      price_pc_1m: 6.767,
      price_pc_6m: -33.953,
      price_pc_1y: -73.004,
      price_pc_ytd: -73.004,
    },
    3799: {
      id: "3799",
      c: 4.11,
      ma50: 4.22,
      ma100: 4.184,
      ma150: 4.235,
      ma200: 4.279,
      maw30: 4.234,
      rs: 64.0,
      rsd1d: 0.0,
      rsd5d: 3.0,
      rsd10d: -2.0,
      price_5d_h: 4.2,
      price_5d_L: 3.89,
      price_1m_h: 4.21,
      price_1m_l: 3.77,
      price_3m_h: 4.82,
      price_3m_l: 3.77,
      price_6m_h: 4.82,
      price_6m_l: 3.77,
      price_52w_h: 4.98,
      price_52w_l: 3.77,
      price_pc_1d: -1.439,
      price_pc_1w: 1.481,
      price_pc_1m: 0.0,
      price_pc_6m: -12.553,
      price_pc_1y: -6.591,
      price_pc_ytd: -6.591,
    },
    3800: {
      id: "3800",
      c: 2.69,
      ma50: 2.604,
      ma100: 2.709,
      ma150: 2.502,
      ma200: 2.372,
      maw30: 2.491,
      rs: 90.0,
      rsd1d: -2.0,
      rsd5d: 1.0,
      rsd10d: 2.0,
      price_5d_h: 2.9,
      price_5d_L: 2.35,
      price_1m_h: 3.07,
      price_1m_l: 2.27,
      price_3m_h: 3.08,
      price_3m_l: 2.24,
      price_6m_h: 3.74,
      price_6m_l: 1.98,
      price_52w_h: 3.74,
      price_52w_l: 1.98,
      price_pc_1d: -6.92,
      price_pc_1w: 6.746,
      price_pc_1m: -5.944,
      price_pc_6m: 35.859,
      price_pc_1y: 35.859,
      price_pc_ytd: 35.859,
    },
    3808: {
      id: "3808",
      c: 12.0,
      ma50: 11.82,
      ma100: 11.843,
      ma150: 12.179,
      ma200: 12.927,
      maw30: 12.069,
      rs: 45.0,
      rsd1d: 1.0,
      rsd5d: -7.0,
      rsd10d: 7.0,
      price_5d_h: 12.86,
      price_5d_L: 11.64,
      price_1m_h: 13.0,
      price_1m_l: 9.16,
      price_3m_h: 13.44,
      price_3m_l: 9.16,
      price_6m_h: 13.5,
      price_6m_l: 9.16,
      price_52w_h: 24.2,
      price_52w_l: 9.16,
      price_pc_1d: 0.167,
      price_pc_1w: -6.977,
      price_pc_1m: 3.093,
      price_pc_6m: 3.448,
      price_pc_1y: -48.936,
      price_pc_ytd: -48.936,
    },
    3813: {
      id: "3813",
      c: 0.9,
      ma50: 1.104,
      ma100: 1.134,
      ma150: 1.22,
      ma200: 1.334,
      maw30: 1.216,
      rs: 18.0,
      rsd1d: 4.0,
      rsd5d: -1.0,
      rsd10d: 3.0,
      price_5d_h: 0.96,
      price_5d_L: 0.87,
      price_1m_h: 1.19,
      price_1m_l: 0.7,
      price_3m_h: 1.35,
      price_3m_l: 0.7,
      price_6m_h: 1.45,
      price_6m_l: 0.7,
      price_52w_h: 2.01,
      price_52w_l: 0.7,
      price_pc_1d: 2.273,
      price_pc_1w: -3.226,
      price_pc_1m: -21.739,
      price_pc_6m: -31.818,
      price_pc_1y: -48.864,
      price_pc_ytd: -48.864,
    },
    3816: {
      id: "3816",
      c: 0.249,
      ma50: 0.265,
      ma100: 0.272,
      ma150: 0.28,
      ma200: 0.281,
      maw30: 0.281,
      rs: 63.0,
      rsd1d: -3.0,
      rsd5d: 0.0,
      rsd10d: -4.0,
      price_5d_h: 0.265,
      price_5d_L: 0.248,
      price_1m_h: 0.275,
      price_1m_l: 0.23,
      price_3m_h: 0.285,
      price_3m_l: 0.23,
      price_6m_h: 0.295,
      price_6m_l: 0.23,
      price_52w_h: 0.34,
      price_52w_l: 0.214,
      price_pc_1d: -2.353,
      price_pc_1w: -2.353,
      price_pc_1m: -2.353,
      price_pc_6m: -15.593,
      price_pc_1y: 10.667,
      price_pc_ytd: 10.667,
    },
    3818: {
      id: "3818",
      c: 0.5,
      ma50: 0.589,
      ma100: 0.648,
      ma150: 0.725,
      ma200: 0.83,
      maw30: 0.722,
      rs: 13.0,
      rsd1d: -1.0,
      rsd5d: -4.0,
      rsd10d: 3.0,
      price_5d_h: 0.55,
      price_5d_L: 0.48,
      price_1m_h: 0.61,
      price_1m_l: 0.405,
      price_3m_h: 0.73,
      price_3m_l: 0.405,
      price_6m_h: 0.92,
      price_6m_l: 0.405,
      price_52w_h: 1.42,
      price_52w_l: 0.405,
      price_pc_1d: -3.846,
      price_pc_1w: -9.091,
      price_pc_1m: -15.254,
      price_pc_6m: -41.176,
      price_pc_1y: -43.82,
      price_pc_ytd: -43.82,
    },
    3822: {
      id: "3822",
      c: 0.084,
      ma50: 0.086,
      ma100: 0.09,
      ma150: 0.094,
      ma200: 0.097,
      maw30: 0.094,
      rs: 42.0,
      rsd1d: 3.0,
      rsd5d: 6.0,
      rsd10d: -13.0,
      price_5d_h: 0.084,
      price_5d_L: 0.08,
      price_1m_h: 0.088,
      price_1m_l: 0.077,
      price_3m_h: 0.092,
      price_3m_l: 0.073,
      price_6m_h: 0.109,
      price_6m_l: 0.073,
      price_52w_h: 0.129,
      price_52w_l: 0.073,
      price_pc_1d: 0.0,
      price_pc_1w: 1.205,
      price_pc_1m: -3.448,
      price_pc_6m: -18.447,
      price_pc_1y: -28.814,
      price_pc_ytd: -28.814,
    },
    3828: {
      id: "3828",
      c: 0.395,
      ma50: 0.38,
      ma100: 0.417,
      ma150: 0.451,
      ma200: 0.499,
      maw30: 0.447,
      rs: 28.0,
      rsd1d: 8.0,
      rsd5d: 13.0,
      rsd10d: 11.0,
      price_5d_h: 0.395,
      price_5d_L: 0.355,
      price_1m_h: 0.395,
      price_1m_l: 0.3,
      price_3m_h: 0.435,
      price_3m_l: 0.3,
      price_6m_h: 0.59,
      price_6m_l: 0.3,
      price_52w_h: 0.8,
      price_52w_l: 0.3,
      price_pc_1d: 5.333,
      price_pc_1w: 12.857,
      price_pc_1m: 3.947,
      price_pc_6m: -21.0,
      price_pc_1y: -43.571,
      price_pc_ytd: -43.571,
    },
    3830: {
      id: "3830",
      c: 0.118,
      ma50: 0.154,
      ma100: 0.158,
      ma150: 0.162,
      ma200: 0.167,
      maw30: 0.163,
      rs: 22.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: -22.0,
      price_5d_h: 0.12,
      price_5d_L: 0.105,
      price_1m_h: 0.17,
      price_1m_l: 0.105,
      price_3m_h: 0.18,
      price_3m_l: 0.105,
      price_6m_h: 0.19,
      price_6m_l: 0.105,
      price_52w_h: 0.205,
      price_52w_l: 0.105,
      price_pc_1d: -1.667,
      price_pc_1w: -1.667,
      price_pc_1m: -30.588,
      price_pc_6m: -26.25,
      price_pc_1y: -36.216,
      price_pc_ytd: -36.216,
    },
    3833: {
      id: "3833",
      c: 1.4,
      ma50: 1.449,
      ma100: 1.348,
      ma150: 1.445,
      ma200: 1.461,
      maw30: 1.438,
      rs: 88.0,
      rsd1d: -2.0,
      rsd5d: -5.0,
      rsd10d: -2.0,
      price_5d_h: 1.71,
      price_5d_L: 1.4,
      price_1m_h: 2.2,
      price_1m_l: 1.21,
      price_3m_h: 2.2,
      price_3m_l: 1.1,
      price_6m_h: 2.2,
      price_6m_l: 1.1,
      price_52w_h: 2.2,
      price_52w_l: 0.96,
      price_pc_1d: -4.762,
      price_pc_1w: -15.152,
      price_pc_1m: -13.58,
      price_pc_6m: -7.895,
      price_pc_1y: 40.0,
      price_pc_ytd: 40.0,
    },
    3836: {
      id: "3836",
      c: 3.99,
      ma50: 3.894,
      ma100: 4.152,
      ma150: 4.179,
      ma200: 4.062,
      maw30: 4.187,
      rs: 65.0,
      rsd1d: 4.0,
      rsd5d: 3.0,
      rsd10d: 28.0,
      price_5d_h: 4.02,
      price_5d_L: 3.67,
      price_1m_h: 4.02,
      price_1m_l: 3.02,
      price_3m_h: 5.06,
      price_3m_l: 3.02,
      price_6m_h: 5.06,
      price_6m_l: 3.02,
      price_52w_h: 5.06,
      price_52w_l: 3.02,
      price_pc_1d: 1.527,
      price_pc_1w: 3.101,
      price_pc_1m: 8.13,
      price_pc_6m: -2.92,
      price_pc_1y: -4.773,
      price_pc_ytd: -4.773,
    },
    3838: {
      id: "3838",
      c: 0.194,
      ma50: 0.191,
      ma100: 0.19,
      ma150: 0.185,
      ma200: 0.184,
      maw30: 0.185,
      rs: 79.0,
      rsd1d: 0.0,
      rsd5d: -11.0,
      rsd10d: 4.0,
      price_5d_h: 0.216,
      price_5d_L: 0.186,
      price_1m_h: 0.22,
      price_1m_l: 0.158,
      price_3m_h: 0.28,
      price_3m_l: 0.158,
      price_6m_h: 0.28,
      price_6m_l: 0.156,
      price_52w_h: 0.28,
      price_52w_l: 0.156,
      price_pc_1d: -0.513,
      price_pc_1w: -8.057,
      price_pc_1m: 5.435,
      price_pc_6m: 12.139,
      price_pc_1y: 8.38,
      price_pc_ytd: 8.38,
    },
    3839: {
      id: "3839",
      c: 1.25,
      ma50: 1.329,
      ma100: 1.367,
      ma150: 1.406,
      ma200: 1.45,
      maw30: 1.41,
      rs: 47.0,
      rsd1d: -1.0,
      rsd5d: 7.0,
      rsd10d: 7.0,
      price_5d_h: 1.27,
      price_5d_L: 1.21,
      price_1m_h: 1.38,
      price_1m_l: 1.0,
      price_3m_h: 1.52,
      price_3m_l: 1.0,
      price_6m_h: 1.55,
      price_6m_l: 1.0,
      price_52w_h: 1.8,
      price_52w_l: 1.0,
      price_pc_1d: -1.575,
      price_pc_1w: 0.0,
      price_pc_1m: -7.407,
      price_pc_6m: -13.194,
      price_pc_1y: -10.714,
      price_pc_ytd: -10.714,
    },
    3848: {
      id: "3848",
      c: 6.29,
      ma50: 6.37,
      ma100: 6.65,
      ma150: 6.656,
      ma200: 6.82,
      maw30: 6.627,
      rs: 59.0,
      rsd1d: -1.0,
      rsd5d: 4.0,
      rsd10d: 4.0,
      price_5d_h: 6.4,
      price_5d_L: 6.22,
      price_1m_h: 6.45,
      price_1m_l: 6.0,
      price_3m_h: 7.16,
      price_3m_l: 6.0,
      price_6m_h: 7.16,
      price_6m_l: 4.58,
      price_52w_h: 7.52,
      price_52w_l: 4.11,
      price_pc_1d: -1.101,
      price_pc_1w: -1.256,
      price_pc_1m: -1.565,
      price_pc_6m: 4.312,
      price_pc_1y: -9.366,
      price_pc_ytd: -9.366,
    },
    3860: {
      id: "3860",
      c: 1.4,
      ma50: 1.443,
      ma100: 1.509,
      ma150: 1.568,
      ma200: 1.62,
      maw30: 1.563,
      rs: 71.0,
      rsd1d: 2.0,
      rsd5d: 1.0,
      rsd10d: -5.0,
      price_5d_h: 1.4,
      price_5d_L: 1.27,
      price_1m_h: 1.57,
      price_1m_l: 1.14,
      price_3m_h: 1.57,
      price_3m_l: 1.14,
      price_6m_h: 1.88,
      price_6m_l: 1.14,
      price_52w_h: 2.68,
      price_52w_l: 1.03,
      price_pc_1d: 2.19,
      price_pc_1w: -0.709,
      price_pc_1m: -0.709,
      price_pc_6m: -17.647,
      price_pc_1y: 38.614,
      price_pc_ytd: 38.614,
    },
    3866: {
      id: "3866",
      c: 3.8,
      ma50: 3.904,
      ma100: 4.05,
      ma150: 4.052,
      ma200: 4.154,
      maw30: 4.055,
      rs: 44.0,
      rsd1d: 1.0,
      rsd5d: -15.0,
      rsd10d: -17.0,
      price_5d_h: 3.93,
      price_5d_L: 3.7,
      price_1m_h: 4.04,
      price_1m_l: 3.6,
      price_3m_h: 4.67,
      price_3m_l: 3.6,
      price_6m_h: 4.679,
      price_6m_l: 3.6,
      price_52w_h: 5.21,
      price_52w_l: 2.7,
      price_pc_1d: 1.877,
      price_pc_1w: -4.523,
      price_pc_1m: -3.308,
      price_pc_6m: -9.524,
      price_pc_1y: -12.037,
      price_pc_ytd: -12.037,
    },
    3868: {
      id: "3868",
      c: 4.76,
      ma50: 4.14,
      ma100: 4.154,
      ma150: 4.276,
      ma200: 4.403,
      maw30: 4.272,
      rs: 86.0,
      rsd1d: -2.0,
      rsd5d: 1.0,
      rsd10d: 11.0,
      price_5d_h: 4.98,
      price_5d_L: 4.39,
      price_1m_h: 4.98,
      price_1m_l: 3.83,
      price_3m_h: 4.98,
      price_3m_l: 3.66,
      price_6m_h: 4.98,
      price_6m_l: 3.66,
      price_52w_h: 5.84,
      price_52w_l: 3.21,
      price_pc_1d: -2.058,
      price_pc_1w: 1.062,
      price_pc_1m: 19.0,
      price_pc_6m: 4.615,
      price_pc_1y: 19.0,
      price_pc_ytd: 19.0,
    },
    3869: {
      id: "3869",
      c: 8.8,
      ma50: 9.145,
      ma100: 9.571,
      ma150: 9.83,
      ma200: 10.101,
      maw30: 9.818,
      rs: 38.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -6.0,
      price_5d_h: 8.92,
      price_5d_L: 8.8,
      price_1m_h: 8.92,
      price_1m_l: 8.66,
      price_3m_h: 9.99,
      price_3m_l: 8.2,
      price_6m_h: 10.74,
      price_6m_l: 8.2,
      price_52w_h: 12.3,
      price_52w_l: 8.2,
      price_pc_1d: -1.345,
      price_pc_1w: -1.345,
      price_pc_1m: 1.617,
      price_pc_6m: -19.854,
      price_pc_1y: -28.455,
      price_pc_ytd: -28.455,
    },
    3877: {
      id: "3877",
      c: 1.08,
      ma50: 1.128,
      ma100: 1.11,
      ma150: 1.155,
      ma200: 1.209,
      maw30: 1.155,
      rs: 56.0,
      rsd1d: -2.0,
      rsd5d: -1.0,
      rsd10d: 7.0,
      price_5d_h: 1.11,
      price_5d_L: 1.04,
      price_1m_h: 1.16,
      price_1m_l: 0.93,
      price_3m_h: 1.28,
      price_3m_l: 0.93,
      price_6m_h: 1.28,
      price_6m_l: 0.93,
      price_52w_h: 1.76,
      price_52w_l: 0.93,
      price_pc_1d: -2.703,
      price_pc_1w: -0.917,
      price_pc_1m: -3.571,
      price_pc_6m: -11.475,
      price_pc_1y: -10.0,
      price_pc_ytd: -10.0,
    },
    3878: {
      id: "3878",
      c: 0.6,
      ma50: 0.534,
      ma100: 0.554,
      ma150: 0.569,
      ma200: 0.531,
      maw30: 0.565,
      rs: 94.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.64,
      price_5d_L: 0.54,
      price_1m_h: 0.65,
      price_1m_l: 0.48,
      price_3m_h: 0.65,
      price_3m_l: 0.46,
      price_6m_h: 0.66,
      price_6m_l: 0.46,
      price_52w_h: 0.79,
      price_52w_l: 0.26,
      price_pc_1d: 0.0,
      price_pc_1w: -3.226,
      price_pc_1m: 9.091,
      price_pc_6m: 17.647,
      price_pc_1y: 79.104,
      price_pc_ytd: 79.104,
    },
    3882: {
      id: "3882",
      c: 0.17,
      ma50: 0.161,
      ma100: 0.169,
      ma150: 0.175,
      ma200: 0.18,
      maw30: 0.174,
      rs: 57.0,
      rsd1d: 4.0,
      rsd5d: -3.0,
      rsd10d: 32.0,
      price_5d_h: 0.175,
      price_5d_L: 0.168,
      price_1m_h: 0.175,
      price_1m_l: 0.09,
      price_3m_h: 0.19,
      price_3m_l: 0.09,
      price_6m_h: 0.19,
      price_6m_l: 0.09,
      price_52w_h: 0.236,
      price_52w_l: 0.09,
      price_pc_1d: 0.0,
      price_pc_1w: 1.19,
      price_pc_1m: 1.19,
      price_pc_6m: -10.526,
      price_pc_1y: -8.602,
      price_pc_ytd: -8.602,
    },
    3883: {
      id: "3883",
      c: 1.18,
      ma50: 1.407,
      ma100: 1.628,
      ma150: 2.363,
      ma200: 3.262,
      maw30: 2.336,
      rs: 6.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: -2.0,
      price_5d_h: 1.36,
      price_5d_L: 0.98,
      price_1m_h: 1.47,
      price_1m_l: 0.89,
      price_3m_h: 1.9,
      price_3m_l: 0.89,
      price_6m_h: 4.23,
      price_6m_l: 0.89,
      price_52w_h: 9.34,
      price_52w_l: 0.89,
      price_pc_1d: 0.0,
      price_pc_1w: -8.527,
      price_pc_1m: -9.231,
      price_pc_6m: -71.29,
      price_pc_1y: -85.714,
      price_pc_ytd: -85.714,
    },
    3886: {
      id: "3886",
      c: 0.36,
      ma50: 0.378,
      ma100: 0.408,
      ma150: 0.464,
      ma200: 0.483,
      maw30: 0.462,
      rs: 34.0,
      rsd1d: 3.0,
      rsd5d: -7.0,
      rsd10d: -16.0,
      price_5d_h: 0.37,
      price_5d_L: 0.345,
      price_1m_h: 0.42,
      price_1m_l: 0.32,
      price_3m_h: 0.46,
      price_3m_l: 0.32,
      price_6m_h: 0.65,
      price_6m_l: 0.32,
      price_52w_h: 0.74,
      price_52w_l: 0.255,
      price_pc_1d: 1.408,
      price_pc_1w: 0.0,
      price_pc_1m: -12.195,
      price_pc_6m: -37.931,
      price_pc_1y: -5.263,
      price_pc_ytd: -5.263,
    },
    3888: {
      id: "3888",
      c: 25.4,
      ma50: 29.791,
      ma100: 32.062,
      ma150: 31.959,
      ma200: 34.26,
      maw30: 32.277,
      rs: 19.0,
      rsd1d: 2.0,
      rsd5d: 4.0,
      rsd10d: -1.0,
      price_5d_h: 26.1,
      price_5d_L: 23.65,
      price_1m_h: 29.9,
      price_1m_l: 19.52,
      price_3m_h: 39.2,
      price_3m_l: 19.52,
      price_6m_h: 39.2,
      price_6m_l: 19.52,
      price_52w_h: 61.25,
      price_52w_l: 19.52,
      price_pc_1d: 1.195,
      price_pc_1w: 4.959,
      price_pc_1m: -12.414,
      price_pc_6m: -18.196,
      price_pc_1y: -54.234,
      price_pc_ytd: -54.234,
    },
    3893: {
      id: "3893",
      c: 0.112,
      ma50: 0.097,
      ma100: 0.589,
      ma150: 0.532,
      ma200: 0.49,
      maw30: 0.543,
      rs: 1.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.138,
      price_5d_L: 0.081,
      price_1m_h: 0.138,
      price_1m_l: 0.069,
      price_3m_h: 2.65,
      price_3m_l: 0.069,
      price_6m_h: 2.65,
      price_6m_l: 0.069,
      price_52w_h: 2.65,
      price_52w_l: 0.069,
      price_pc_1d: -6.667,
      price_pc_1w: 30.233,
      price_pc_1m: 21.739,
      price_pc_6m: -72.683,
      price_pc_1y: -60.0,
      price_pc_ytd: -60.0,
    },
    3898: {
      id: "3898",
      c: 30.75,
      ma50: 37.053,
      ma100: 41.3,
      ma150: 40.976,
      ma200: 43.148,
      maw30: 40.727,
      rs: 34.0,
      rsd1d: -1.0,
      rsd5d: -5.0,
      rsd10d: -12.0,
      price_5d_h: 34.15,
      price_5d_L: 30.0,
      price_1m_h: 40.95,
      price_1m_l: 27.7,
      price_3m_h: 48.85,
      price_3m_l: 27.7,
      price_6m_h: 53.4,
      price_6m_l: 27.7,
      price_52w_h: 65.3,
      price_52w_l: 27.7,
      price_pc_1d: -5.963,
      price_pc_1w: -7.796,
      price_pc_1m: -24.074,
      price_pc_6m: -14.465,
      price_pc_1y: 1.653,
      price_pc_ytd: 1.653,
    },
    3899: {
      id: "3899",
      c: 9.75,
      ma50: 9.371,
      ma100: 10.01,
      ma150: 10.075,
      ma200: 9.632,
      maw30: 9.985,
      rs: 88.0,
      rsd1d: -1.0,
      rsd5d: 1.0,
      rsd10d: 18.0,
      price_5d_h: 9.98,
      price_5d_L: 8.94,
      price_1m_h: 9.98,
      price_1m_l: 7.28,
      price_3m_h: 12.6,
      price_3m_l: 7.28,
      price_6m_h: 12.6,
      price_6m_l: 7.28,
      price_52w_h: 12.6,
      price_52w_l: 5.54,
      price_pc_1d: -1.015,
      price_pc_1w: 3.066,
      price_pc_1m: 4.839,
      price_pc_6m: 4.502,
      price_pc_1y: 75.045,
      price_pc_ytd: 75.045,
    },
    3900: {
      id: "3900",
      c: 14.32,
      ma50: 12.45,
      ma100: 12.262,
      ma150: 11.944,
      ma200: 11.498,
      maw30: 12.035,
      rs: 91.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: 5.0,
      price_5d_h: 14.72,
      price_5d_L: 13.44,
      price_1m_h: 14.72,
      price_1m_l: 8.61,
      price_3m_h: 14.72,
      price_3m_l: 8.61,
      price_6m_h: 14.72,
      price_6m_l: 8.61,
      price_52w_h: 14.72,
      price_52w_l: 8.09,
      price_pc_1d: -2.452,
      price_pc_1w: 3.618,
      price_pc_1m: 22.184,
      price_pc_6m: 21.562,
      price_pc_1y: 43.487,
      price_pc_ytd: 43.487,
    },
    3908: {
      id: "3908",
      c: 17.48,
      ma50: 19.336,
      ma100: 19.955,
      ma150: 19.979,
      ma200: 19.94,
      maw30: 20.158,
      rs: 44.0,
      rsd1d: -1.0,
      rsd5d: -8.0,
      rsd10d: -8.0,
      price_5d_h: 18.16,
      price_5d_L: 16.84,
      price_1m_h: 19.46,
      price_1m_l: 14.64,
      price_3m_h: 22.95,
      price_3m_l: 14.64,
      price_6m_h: 23.75,
      price_6m_l: 14.64,
      price_52w_h: 23.8,
      price_52w_l: 14.64,
      price_pc_1d: -1.019,
      price_pc_1w: -3.104,
      price_pc_1m: -8.0,
      price_pc_6m: -14.523,
      price_pc_1y: -11.449,
      price_pc_ytd: -11.449,
    },
    3913: {
      id: "3913",
      c: 2.92,
      ma50: 3.243,
      ma100: 3.657,
      ma150: 4.368,
      ma200: 5.313,
      maw30: 4.337,
      rs: 13.0,
      rsd1d: 1.0,
      rsd5d: 2.0,
      rsd10d: -7.0,
      price_5d_h: 3.06,
      price_5d_L: 2.7,
      price_1m_h: 3.38,
      price_1m_l: 2.16,
      price_3m_h: 4.05,
      price_3m_l: 2.16,
      price_6m_h: 6.29,
      price_6m_l: 2.16,
      price_52w_h: 10.5,
      price_52w_l: 2.16,
      price_pc_1d: -3.63,
      price_pc_1w: -2.013,
      price_pc_1m: -9.598,
      price_pc_6m: -49.306,
      price_pc_1y: -64.172,
      price_pc_ytd: -64.172,
    },
    3918: {
      id: "3918",
      c: 6.99,
      ma50: 6.769,
      ma100: 6.794,
      ma150: 6.772,
      ma200: 6.71,
      maw30: 6.793,
      rs: 67.0,
      rsd1d: -8.0,
      rsd5d: -1.0,
      rsd10d: 8.0,
      price_5d_h: 7.31,
      price_5d_L: 6.84,
      price_1m_h: 7.31,
      price_1m_l: 5.35,
      price_3m_h: 7.78,
      price_3m_l: 5.35,
      price_6m_h: 7.8,
      price_6m_l: 5.35,
      price_52w_h: 9.6,
      price_52w_l: 5.15,
      price_pc_1d: -1.826,
      price_pc_1w: -0.143,
      price_pc_1m: -2.917,
      price_pc_6m: 5.113,
      price_pc_1y: -24.677,
      price_pc_ytd: -24.677,
    },
    3919: {
      id: "3919",
      c: 0.2,
      ma50: 0.205,
      ma100: 0.213,
      ma150: 0.224,
      ma200: 0.236,
      maw30: 0.222,
      rs: 43.0,
      rsd1d: 1.0,
      rsd5d: 10.0,
      rsd10d: 10.0,
      price_5d_h: 0.206,
      price_5d_L: 0.196,
      price_1m_h: 0.223,
      price_1m_l: 0.19,
      price_3m_h: 0.224,
      price_3m_l: 0.18,
      price_6m_h: 0.3,
      price_6m_l: 0.18,
      price_52w_h: 0.47,
      price_52w_l: 0.18,
      price_pc_1d: -3.382,
      price_pc_1w: 0.0,
      price_pc_1m: -3.846,
      price_pc_6m: -16.667,
      price_pc_1y: -49.248,
      price_pc_ytd: -49.248,
    },
    3933: {
      id: "3933",
      c: 4.12,
      ma50: 4.33,
      ma100: 4.542,
      ma150: 4.776,
      ma200: 5.065,
      maw30: 4.78,
      rs: 37.0,
      rsd1d: 0.0,
      rsd5d: 6.0,
      rsd10d: 8.0,
      price_5d_h: 4.23,
      price_5d_L: 3.95,
      price_1m_h: 4.38,
      price_1m_l: 3.45,
      price_3m_h: 4.9,
      price_3m_l: 3.45,
      price_6m_h: 5.68,
      price_6m_l: 3.45,
      price_52w_h: 7.6,
      price_52w_l: 3.45,
      price_pc_1d: -1.435,
      price_pc_1w: -0.483,
      price_pc_1m: -4.63,
      price_pc_6m: -20.463,
      price_pc_1y: -28.843,
      price_pc_ytd: -28.843,
    },
    3939: {
      id: "3939",
      c: 2.0,
      ma50: 1.899,
      ma100: 1.958,
      ma150: 1.988,
      ma200: 2.012,
      maw30: 1.972,
      rs: 76.0,
      rsd1d: 12.0,
      rsd5d: 21.0,
      rsd10d: 17.0,
      price_5d_h: 2.0,
      price_5d_L: 1.8,
      price_1m_h: 2.1,
      price_1m_l: 1.8,
      price_3m_h: 2.1,
      price_3m_l: 1.45,
      price_6m_h: 2.22,
      price_6m_l: 1.45,
      price_52w_h: 2.4,
      price_52w_l: 1.45,
      price_pc_1d: 3.627,
      price_pc_1w: 8.696,
      price_pc_1m: 3.093,
      price_pc_6m: 1.523,
      price_pc_1y: -3.382,
      price_pc_ytd: -3.382,
    },
    3948: {
      id: "3948",
      c: 9.14,
      ma50: 6.928,
      ma100: 6.398,
      ma150: 6.344,
      ma200: 6.09,
      maw30: 6.326,
      rs: 95.0,
      rsd1d: 0.0,
      rsd5d: 4.0,
      rsd10d: 9.0,
      price_5d_h: 9.14,
      price_5d_L: 6.73,
      price_1m_h: 9.14,
      price_1m_l: 5.4,
      price_3m_h: 9.14,
      price_3m_l: 5.4,
      price_6m_h: 9.14,
      price_6m_l: 5.22,
      price_52w_h: 9.14,
      price_52w_l: 4.71,
      price_pc_1d: 9.856,
      price_pc_1w: 28.914,
      price_pc_1m: 34.214,
      price_pc_6m: 45.541,
      price_pc_1y: 94.055,
      price_pc_ytd: 94.055,
    },
    3958: {
      id: "3958",
      c: 5.13,
      ma50: 5.692,
      ma100: 6.207,
      ma150: 6.616,
      ma200: 6.558,
      maw30: 6.601,
      rs: 41.0,
      rsd1d: 5.0,
      rsd5d: 4.0,
      rsd10d: 2.0,
      price_5d_h: 5.19,
      price_5d_L: 4.78,
      price_1m_h: 5.8,
      price_1m_l: 4.21,
      price_3m_h: 6.92,
      price_3m_l: 4.21,
      price_6m_h: 7.95,
      price_6m_l: 4.21,
      price_52w_h: 8.99,
      price_52w_l: 4.21,
      price_pc_1d: 0.588,
      price_pc_1w: -0.195,
      price_pc_1m: -9.683,
      price_pc_6m: -32.5,
      price_pc_1y: 0.391,
      price_pc_ytd: 0.391,
    },
    3968: {
      id: "3968",
      c: 61.55,
      ma50: 64.161,
      ma100: 63.598,
      ma150: 63.721,
      ma200: 63.802,
      maw30: 63.955,
      rs: 74.0,
      rsd1d: 3.0,
      rsd5d: 1.0,
      rsd10d: 7.0,
      price_5d_h: 61.85,
      price_5d_L: 58.95,
      price_1m_h: 66.75,
      price_1m_l: 50.75,
      price_3m_h: 70.8,
      price_3m_l: 50.75,
      price_6m_h: 70.8,
      price_6m_l: 50.75,
      price_52w_h: 72.45,
      price_52w_l: 50.75,
      price_pc_1d: 0.819,
      price_pc_1w: 0.163,
      price_pc_1m: -6.459,
      price_pc_6m: -0.806,
      price_pc_1y: 1.988,
      price_pc_ytd: 1.988,
    },
    3969: {
      id: "3969",
      c: 2.49,
      ma50: 2.751,
      ma100: 2.761,
      ma150: 2.8,
      ma200: 2.801,
      maw30: 2.81,
      rs: 45.0,
      rsd1d: -4.0,
      rsd5d: -9.0,
      rsd10d: -9.0,
      price_5d_h: 2.61,
      price_5d_L: 2.49,
      price_1m_h: 2.84,
      price_1m_l: 2.36,
      price_3m_h: 2.98,
      price_3m_l: 2.36,
      price_6m_h: 2.99,
      price_6m_l: 2.36,
      price_52w_h: 3.46,
      price_52w_l: 2.36,
      price_pc_1d: -3.113,
      price_pc_1w: -4.231,
      price_pc_1m: -12.324,
      price_pc_6m: -11.702,
      price_pc_1y: -22.91,
      price_pc_ytd: -22.91,
    },
    3978: {
      id: "3978",
      c: 0.25,
      ma50: 0.274,
      ma100: 0.336,
      ma150: 0.416,
      ma200: 0.559,
      maw30: 0.417,
      rs: 6.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: 1.0,
      price_5d_h: 0.27,
      price_5d_L: 0.246,
      price_1m_h: 0.29,
      price_1m_l: 0.19,
      price_3m_h: 0.34,
      price_3m_l: 0.19,
      price_6m_h: 0.65,
      price_6m_l: 0.19,
      price_52w_h: 2.85,
      price_52w_l: 0.19,
      price_pc_1d: 0.0,
      price_pc_1w: -3.846,
      price_pc_1m: -12.281,
      price_pc_6m: -56.897,
      price_pc_1y: -90.809,
      price_pc_ytd: -90.809,
    },
    3983: {
      id: "3983",
      c: 2.69,
      ma50: 2.473,
      ma100: 2.372,
      ma150: 2.471,
      ma200: 2.489,
      maw30: 2.473,
      rs: 90.0,
      rsd1d: -2.0,
      rsd5d: -3.0,
      rsd10d: 3.0,
      price_5d_h: 3.04,
      price_5d_L: 2.65,
      price_1m_h: 3.04,
      price_1m_l: 2.08,
      price_3m_h: 3.04,
      price_3m_l: 2.08,
      price_6m_h: 3.58,
      price_6m_l: 1.96,
      price_52w_h: 3.58,
      price_52w_l: 1.92,
      price_pc_1d: -5.282,
      price_pc_1w: -7.241,
      price_pc_1m: 15.451,
      price_pc_6m: -5.944,
      price_pc_1y: 37.245,
      price_pc_ytd: 37.245,
    },
    3988: {
      id: "3988",
      c: 3.15,
      ma50: 3.037,
      ma100: 2.92,
      ma150: 2.862,
      ma200: 2.834,
      maw30: 2.868,
      rs: 86.0,
      rsd1d: 0.0,
      rsd5d: 2.0,
      rsd10d: 1.0,
      price_5d_h: 3.17,
      price_5d_L: 2.99,
      price_1m_h: 3.17,
      price_1m_l: 2.8,
      price_3m_h: 3.2,
      price_3m_l: 2.8,
      price_6m_h: 3.2,
      price_6m_l: 2.7,
      price_52w_h: 3.2,
      price_52w_l: 2.67,
      price_pc_1d: 0.962,
      price_pc_1w: 4.651,
      price_pc_1m: 3.618,
      price_pc_6m: 14.13,
      price_pc_1y: 6.78,
      price_pc_ytd: 6.78,
    },
    3989: {
      id: "3989",
      c: 0.167,
      ma50: 0.159,
      ma100: 0.171,
      ma150: 0.178,
      ma200: 0.174,
      maw30: 0.178,
      rs: 72.0,
      rsd1d: -1.0,
      rsd5d: -5.0,
      rsd10d: 19.0,
      price_5d_h: 0.18,
      price_5d_L: 0.152,
      price_1m_h: 0.18,
      price_1m_l: 0.121,
      price_3m_h: 0.205,
      price_3m_l: 0.121,
      price_6m_h: 0.213,
      price_6m_l: 0.121,
      price_52w_h: 0.24,
      price_52w_l: 0.121,
      price_pc_1d: 0.0,
      price_pc_1w: -3.468,
      price_pc_1m: 6.369,
      price_pc_6m: -13.021,
      price_pc_1y: 14.384,
      price_pc_ytd: 14.384,
    },
    3990: {
      id: "3990",
      c: 15.74,
      ma50: 12.608,
      ma100: 12.64,
      ma150: 12.984,
      ma200: 13.622,
      maw30: 12.879,
      rs: 87.0,
      rsd1d: -3.0,
      rsd5d: 4.0,
      rsd10d: 15.0,
      price_5d_h: 16.8,
      price_5d_L: 13.94,
      price_1m_h: 16.8,
      price_1m_l: 10.0,
      price_3m_h: 16.8,
      price_3m_l: 10.0,
      price_6m_h: 16.8,
      price_6m_l: 10.0,
      price_52w_h: 20.75,
      price_52w_l: 10.0,
      price_pc_1d: -4.49,
      price_pc_1w: 7.075,
      price_pc_1m: 37.587,
      price_pc_6m: 17.463,
      price_pc_1y: -3.907,
      price_pc_ytd: -3.907,
    },
    3991: {
      id: "3991",
      c: 0.63,
      ma50: 0.682,
      ma100: 0.698,
      ma150: 0.715,
      ma200: 0.745,
      maw30: 0.715,
      rs: 41.0,
      rsd1d: 2.0,
      rsd5d: -12.0,
      rsd10d: -7.0,
      price_5d_h: 0.64,
      price_5d_L: 0.6,
      price_1m_h: 0.72,
      price_1m_l: 0.6,
      price_3m_h: 0.77,
      price_3m_l: 0.6,
      price_6m_h: 0.8,
      price_6m_l: 0.6,
      price_52w_h: 0.96,
      price_52w_l: 0.6,
      price_pc_1d: 0.0,
      price_pc_1w: -7.353,
      price_pc_1m: -5.97,
      price_pc_6m: -14.865,
      price_pc_1y: -26.744,
      price_pc_ytd: -26.744,
    },
    3993: {
      id: "3993",
      c: 4.09,
      ma50: 4.229,
      ma100: 4.299,
      ma150: 4.643,
      ma200: 4.824,
      maw30: 4.614,
      rs: 59.0,
      rsd1d: 2.0,
      rsd5d: -2.0,
      rsd10d: 20.0,
      price_5d_h: 4.32,
      price_5d_L: 3.93,
      price_1m_h: 4.82,
      price_1m_l: 3.05,
      price_3m_h: 4.9,
      price_3m_l: 3.05,
      price_6m_h: 5.58,
      price_6m_l: 3.05,
      price_52w_h: 6.68,
      price_52w_l: 3.05,
      price_pc_1d: -0.728,
      price_pc_1w: -1.918,
      price_pc_1m: -12.607,
      price_pc_6m: -15.67,
      price_pc_1y: -16.189,
      price_pc_ytd: -16.189,
    },
    3996: {
      id: "3996",
      c: 1.11,
      ma50: 1.254,
      ma100: 1.226,
      ma150: 1.238,
      ma200: 1.137,
      maw30: 1.246,
      rs: 83.0,
      rsd1d: -3.0,
      rsd5d: -1.0,
      rsd10d: -3.0,
      price_5d_h: 1.18,
      price_5d_L: 1.09,
      price_1m_h: 1.31,
      price_1m_l: 0.98,
      price_3m_h: 1.48,
      price_3m_l: 0.98,
      price_6m_h: 1.54,
      price_6m_l: 0.87,
      price_52w_h: 1.65,
      price_52w_l: 0.68,
      price_pc_1d: -3.478,
      price_pc_1w: -4.31,
      price_pc_1m: -13.281,
      price_pc_6m: -11.905,
      price_pc_1y: 37.037,
      price_pc_ytd: 37.037,
    },
    3997: {
      id: "3997",
      c: 2.16,
      ma50: 1.477,
      ma100: 1.429,
      ma150: 1.378,
      ma200: 1.316,
      maw30: 1.378,
      rs: 96.0,
      rsd1d: -1.0,
      rsd5d: 1.0,
      rsd10d: 1.0,
      price_5d_h: 2.32,
      price_5d_L: 1.85,
      price_1m_h: 2.32,
      price_1m_l: 1.11,
      price_3m_h: 2.32,
      price_3m_l: 1.11,
      price_6m_h: 2.32,
      price_6m_l: 1.11,
      price_52w_h: 2.32,
      price_52w_l: 0.91,
      price_pc_1d: -2.262,
      price_pc_1w: 23.429,
      price_pc_1m: 68.75,
      price_pc_6m: 60.0,
      price_pc_1y: 137.363,
      price_pc_ytd: 137.363,
    },
    3998: {
      id: "3998",
      c: 3.66,
      ma50: 3.967,
      ma100: 4.539,
      ma150: 5.045,
      ma200: 5.082,
      maw30: 5.033,
      rs: 31.0,
      rsd1d: -4.0,
      rsd5d: -5.0,
      rsd10d: -4.0,
      price_5d_h: 3.79,
      price_5d_L: 3.41,
      price_1m_h: 4.54,
      price_1m_l: 3.11,
      price_3m_h: 4.94,
      price_3m_l: 3.11,
      price_6m_h: 6.48,
      price_6m_l: 3.11,
      price_52w_h: 6.93,
      price_52w_l: 3.11,
      price_pc_1d: -3.175,
      price_pc_1w: -2.66,
      price_pc_1m: -17.568,
      price_pc_6m: -33.935,
      price_pc_1y: 1.95,
      price_pc_ytd: 1.95,
    },
    3999: {
      id: "3999",
      c: 0.61,
      ma50: 0.632,
      ma100: 0.687,
      ma150: 0.716,
      ma200: 0.752,
      maw30: 0.715,
      rs: 29.0,
      rsd1d: 1.0,
      rsd5d: 11.0,
      rsd10d: 13.0,
      price_5d_h: 0.64,
      price_5d_L: 0.57,
      price_1m_h: 0.65,
      price_1m_l: 0.47,
      price_3m_h: 0.83,
      price_3m_l: 0.47,
      price_6m_h: 0.89,
      price_6m_l: 0.47,
      price_52w_h: 0.99,
      price_52w_l: 0.47,
      price_pc_1d: 0.0,
      price_pc_1w: 8.929,
      price_pc_1m: -7.576,
      price_pc_6m: -23.75,
      price_pc_1y: -31.461,
      price_pc_ytd: -31.461,
    },
    6030: {
      id: "6030",
      c: 18.08,
      ma50: 19.107,
      ma100: 19.445,
      ma150: 19.564,
      ma200: 19.306,
      maw30: 19.668,
      rs: 61.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: -5.0,
      price_5d_h: 18.58,
      price_5d_L: 17.18,
      price_1m_h: 19.18,
      price_1m_l: 15.74,
      price_3m_h: 21.35,
      price_3m_l: 15.74,
      price_6m_h: 21.8,
      price_6m_l: 15.74,
      price_52w_h: 22.0,
      price_52w_l: 15.74,
      price_pc_1d: -1.31,
      price_pc_1w: 0.333,
      price_pc_1m: -4.541,
      price_pc_6m: -8.871,
      price_pc_1y: -3.316,
      price_pc_ytd: -3.316,
    },
    6033: {
      id: "6033",
      c: 2.87,
      ma50: 3.241,
      ma100: 3.318,
      ma150: 3.313,
      ma200: 3.26,
      maw30: 3.318,
      rs: 62.0,
      rsd1d: 4.0,
      rsd5d: 1.0,
      rsd10d: -7.0,
      price_5d_h: 2.91,
      price_5d_L: 2.8,
      price_1m_h: 3.56,
      price_1m_l: 2.6,
      price_3m_h: 3.58,
      price_3m_l: 2.6,
      price_6m_h: 3.58,
      price_6m_l: 2.6,
      price_52w_h: 3.58,
      price_52w_l: 2.46,
      price_pc_1d: 1.056,
      price_pc_1w: -0.347,
      price_pc_1m: -18.697,
      price_pc_6m: -12.232,
      price_pc_1y: 16.194,
      price_pc_ytd: 16.194,
    },
    6036: {
      id: "6036",
      c: 0.335,
      ma50: 0.325,
      ma100: 0.306,
      ma150: 0.309,
      ma200: 0.304,
      maw30: 0.307,
      rs: 93.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: 2.0,
      price_5d_h: 0.365,
      price_5d_L: 0.32,
      price_1m_h: 0.375,
      price_1m_l: 0.28,
      price_3m_h: 0.38,
      price_3m_l: 0.275,
      price_6m_h: 0.38,
      price_6m_l: 0.26,
      price_52w_h: 0.45,
      price_52w_l: 0.182,
      price_pc_1d: 0.0,
      price_pc_1w: 1.515,
      price_pc_1m: -2.899,
      price_pc_6m: 8.065,
      price_pc_1y: 70.918,
      price_pc_ytd: 70.918,
    },
    6049: {
      id: "6049",
      c: 56.7,
      ma50: 55.829,
      ma100: 55.182,
      ma150: 52.02,
      ma200: 51.261,
      maw30: 52.45,
      rs: 80.0,
      rsd1d: -4.0,
      rsd5d: -2.0,
      rsd10d: -4.0,
      price_5d_h: 58.0,
      price_5d_L: 53.8,
      price_1m_h: 58.0,
      price_1m_l: 40.05,
      price_3m_h: 65.9,
      price_3m_l: 40.05,
      price_6m_h: 65.9,
      price_6m_l: 39.8,
      price_52w_h: 66.8,
      price_52w_l: 38.1,
      price_pc_1d: -0.787,
      price_pc_1w: 1.16,
      price_pc_1m: 2.624,
      price_pc_6m: 22.198,
      price_pc_1y: 5.981,
      price_pc_ytd: 5.981,
    },
    6055: {
      id: "6055",
      c: 13.34,
      ma50: 14.503,
      ma100: 15.704,
      ma150: 15.925,
      ma200: 15.996,
      maw30: 16.017,
      rs: 34.0,
      rsd1d: 2.0,
      rsd5d: -7.0,
      rsd10d: -16.0,
      price_5d_h: 14.06,
      price_5d_L: 13.04,
      price_1m_h: 14.74,
      price_1m_l: 12.32,
      price_3m_h: 16.06,
      price_3m_l: 12.32,
      price_6m_h: 19.6,
      price_6m_l: 12.32,
      price_52w_h: 19.6,
      price_52w_l: 12.32,
      price_pc_1d: -1.039,
      price_pc_1w: -4.441,
      price_pc_1m: -8.63,
      price_pc_6m: -19.735,
      price_pc_1y: -30.881,
      price_pc_ytd: -30.881,
    },
    6060: {
      id: "6060",
      c: 26.7,
      ma50: 26.954,
      ma100: 27.159,
      ma150: 28.992,
      ma200: 32.194,
      maw30: 28.892,
      rs: 35.0,
      rsd1d: -3.0,
      rsd5d: -4.0,
      rsd10d: 8.0,
      price_5d_h: 27.9,
      price_5d_L: 25.3,
      price_1m_h: 28.25,
      price_1m_l: 18.2,
      price_3m_h: 33.85,
      price_3m_l: 18.2,
      price_6m_h: 33.85,
      price_6m_l: 18.2,
      price_52w_h: 50.85,
      price_52w_l: 18.2,
      price_pc_1d: -1.657,
      price_pc_1w: -3.784,
      price_pc_1m: 4.297,
      price_pc_6m: -16.693,
      price_pc_1y: -45.732,
      price_pc_ytd: -45.732,
    },
    6063: {
      id: "6063",
      c: 0.101,
      ma50: 0.089,
      ma100: 0.091,
      ma150: 0.094,
      ma200: 0.099,
      maw30: 0.094,
      rs: 57.0,
      rsd1d: -25.0,
      rsd5d: -25.0,
      rsd10d: -31.0,
      price_5d_h: 0.11,
      price_5d_L: 0.097,
      price_1m_h: 0.134,
      price_1m_l: 0.093,
      price_3m_h: 0.134,
      price_3m_l: 0.04,
      price_6m_h: 0.134,
      price_6m_l: 0.04,
      price_52w_h: 0.135,
      price_52w_l: 0.04,
      price_pc_1d: -5.607,
      price_pc_1w: -3.81,
      price_pc_1m: 10.989,
      price_pc_6m: -6.481,
      price_pc_1y: -11.404,
      price_pc_ytd: -11.404,
    },
    6066: {
      id: "6066",
      c: 7.42,
      ma50: 8.293,
      ma100: 8.396,
      ma150: 8.518,
      ma200: 8.541,
      maw30: 8.572,
      rs: 39.0,
      rsd1d: -1.0,
      rsd5d: -2.0,
      rsd10d: -7.0,
      price_5d_h: 7.64,
      price_5d_L: 7.26,
      price_1m_h: 8.39,
      price_1m_l: 6.67,
      price_3m_h: 9.34,
      price_3m_l: 6.67,
      price_6m_h: 9.37,
      price_6m_l: 6.67,
      price_52w_h: 10.76,
      price_52w_l: 6.67,
      price_pc_1d: -1.852,
      price_pc_1w: -2.752,
      price_pc_1m: -10.602,
      price_pc_6m: -15.006,
      price_pc_1y: -29.868,
      price_pc_ytd: -29.868,
    },
    6068: {
      id: "6068",
      c: 0.3,
      ma50: 0.429,
      ma100: 0.764,
      ma150: 1.009,
      ma200: 1.227,
      maw30: 1.011,
      rs: 2.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 1.0,
      price_5d_h: 0.345,
      price_5d_L: 0.29,
      price_1m_h: 0.465,
      price_1m_l: 0.243,
      price_3m_h: 0.69,
      price_3m_l: 0.243,
      price_6m_h: 1.62,
      price_6m_l: 0.243,
      price_52w_h: 4.37,
      price_52w_l: 0.243,
      price_pc_1d: -6.25,
      price_pc_1w: -9.091,
      price_pc_1m: -30.233,
      price_pc_6m: -79.73,
      price_pc_1y: -91.525,
      price_pc_ytd: -91.525,
    },
    6069: {
      id: "6069",
      c: 5.9,
      ma50: 6.981,
      ma100: 7.262,
      ma150: 7.913,
      ma200: 8.129,
      maw30: 7.909,
      rs: 32.0,
      rsd1d: 2.0,
      rsd5d: -8.0,
      rsd10d: -5.0,
      price_5d_h: 6.4,
      price_5d_L: 5.81,
      price_1m_h: 7.32,
      price_1m_l: 5.8,
      price_3m_h: 8.25,
      price_3m_l: 5.8,
      price_6m_h: 9.5,
      price_6m_l: 5.8,
      price_52w_h: 11.02,
      price_52w_l: 5.79,
      price_pc_1d: -1.993,
      price_pc_1w: -5.901,
      price_pc_1m: -18.733,
      price_pc_6m: -36.285,
      price_pc_1y: -4.992,
      price_pc_ytd: -4.992,
    },
    6078: {
      id: "6078",
      c: 30.55,
      ma50: 36.838,
      ma100: 46.747,
      ma150: 52.038,
      ma200: 60.518,
      maw30: 52.227,
      rs: 9.0,
      rsd1d: -3.0,
      rsd5d: -2.0,
      rsd10d: -2.0,
      price_5d_h: 35.0,
      price_5d_L: 29.6,
      price_1m_h: 45.2,
      price_1m_l: 16.2,
      price_3m_h: 52.6,
      price_3m_l: 16.2,
      price_6m_h: 76.75,
      price_6m_l: 16.2,
      price_52w_h: 110.0,
      price_52w_l: 16.2,
      price_pc_1d: -10.933,
      price_pc_1w: -7.284,
      price_pc_1m: -27.948,
      price_pc_6m: -47.418,
      price_pc_1y: -49.083,
      price_pc_ytd: -49.083,
    },
    6083: {
      id: "6083",
      c: 0.375,
      ma50: 0.375,
      ma100: 0.399,
      ma150: 0.432,
      ma200: 0.455,
      maw30: 0.429,
      rs: 53.0,
      rsd1d: 8.0,
      rsd5d: 10.0,
      rsd10d: 14.0,
      price_5d_h: 0.375,
      price_5d_L: 0.365,
      price_1m_h: 0.375,
      price_1m_l: 0.33,
      price_3m_h: 0.465,
      price_3m_l: 0.33,
      price_6m_h: 0.5,
      price_6m_l: 0.33,
      price_52w_h: 0.66,
      price_52w_l: 0.33,
      price_pc_1d: 1.351,
      price_pc_1w: 4.167,
      price_pc_1m: -1.316,
      price_pc_6m: -24.242,
      price_pc_1y: -8.537,
      price_pc_ytd: -8.537,
    },
    6088: {
      id: "6088",
      c: 1.17,
      ma50: 1.292,
      ma100: 1.448,
      ma150: 1.506,
      ma200: 1.586,
      maw30: 1.507,
      rs: 18.0,
      rsd1d: 2.0,
      rsd5d: 3.0,
      rsd10d: 6.0,
      price_5d_h: 1.19,
      price_5d_L: 1.1,
      price_1m_h: 1.59,
      price_1m_l: 0.86,
      price_3m_h: 1.61,
      price_3m_l: 0.86,
      price_6m_h: 1.84,
      price_6m_l: 0.86,
      price_52w_h: 2.7,
      price_52w_l: 0.86,
      price_pc_1d: -1.681,
      price_pc_1w: 0.862,
      price_pc_1m: -17.021,
      price_pc_6m: -28.221,
      price_pc_1y: -53.571,
      price_pc_ytd: -53.571,
    },
    6093: {
      id: "6093",
      c: 4.12,
      ma50: 4.111,
      ma100: 4.148,
      ma150: 4.157,
      ma200: 4.174,
      maw30: 4.15,
      rs: 82.0,
      rsd1d: -2.0,
      rsd5d: -3.0,
      rsd10d: -7.0,
      price_5d_h: 4.17,
      price_5d_L: 4.1,
      price_1m_h: 4.4,
      price_1m_l: 4.0,
      price_3m_h: 4.4,
      price_3m_l: 4.0,
      price_6m_h: 4.4,
      price_6m_l: 4.0,
      price_52w_h: 4.6,
      price_52w_l: 2.95,
      price_pc_1d: -1.199,
      price_pc_1w: -0.723,
      price_pc_1m: 0.0,
      price_pc_6m: -0.723,
      price_pc_1y: 34.202,
      price_pc_ytd: 34.202,
    },
    6098: {
      id: "6098",
      c: 33.6,
      ma50: 43.229,
      ma100: 46.327,
      ma150: 50.552,
      ma200: 55.5,
      maw30: 51.162,
      rs: 11.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: -11.0,
      price_5d_h: 35.3,
      price_5d_L: 30.15,
      price_1m_h: 48.45,
      price_1m_l: 22.8,
      price_3m_h: 54.6,
      price_3m_l: 22.8,
      price_6m_h: 70.3,
      price_6m_l: 22.8,
      price_52w_h: 85.2,
      price_52w_l: 22.8,
      price_pc_1d: -2.183,
      price_pc_1w: 0.0,
      price_pc_1m: -28.051,
      price_pc_6m: -45.366,
      price_pc_1y: -57.061,
      price_pc_ytd: -57.061,
    },
    6099: {
      id: "6099",
      c: 9.24,
      ma50: 10.171,
      ma100: 11.023,
      ma150: 11.457,
      ma200: 11.35,
      maw30: 11.561,
      rs: 36.0,
      rsd1d: 1.0,
      rsd5d: 2.0,
      rsd10d: 3.0,
      price_5d_h: 9.5,
      price_5d_L: 8.8,
      price_1m_h: 10.0,
      price_1m_l: 7.75,
      price_3m_h: 12.18,
      price_3m_l: 7.75,
      price_6m_h: 13.06,
      price_6m_l: 7.75,
      price_52w_h: 13.06,
      price_52w_l: 7.75,
      price_pc_1d: -1.597,
      price_pc_1w: 0.873,
      price_pc_1m: -6.193,
      price_pc_6m: -25.843,
      price_pc_1y: -15.847,
      price_pc_ytd: -15.847,
    },
    6100: {
      id: "6100",
      c: 17.66,
      ma50: 18.371,
      ma100: 17.356,
      ma150: 15.588,
      ma200: 15.742,
      maw30: 15.646,
      rs: 82.0,
      rsd1d: -1.0,
      rsd5d: -2.0,
      rsd10d: 2.0,
      price_5d_h: 18.3,
      price_5d_L: 16.66,
      price_1m_h: 18.82,
      price_1m_l: 14.5,
      price_3m_h: 19.98,
      price_3m_l: 14.5,
      price_6m_h: 21.4,
      price_6m_l: 9.62,
      price_52w_h: 28.4,
      price_52w_l: 9.62,
      price_pc_1d: 0.569,
      price_pc_1w: -2.753,
      price_pc_1m: -6.064,
      price_pc_6m: 62.018,
      price_pc_1y: -22.713,
      price_pc_ytd: -22.713,
    },
    6108: {
      id: "6108",
      c: 0.125,
      ma50: null,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 2.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -75.0,
      price_5d_h: 0.161,
      price_5d_L: 0.104,
      price_1m_h: 0.425,
      price_1m_l: 0.103,
      price_3m_h: 0.425,
      price_3m_l: 0.103,
      price_6m_h: 0.425,
      price_6m_l: 0.103,
      price_52w_h: 0.425,
      price_52w_l: 0.103,
      price_pc_1d: -0.794,
      price_pc_1w: 20.192,
      price_pc_1m: -70.588,
      price_pc_6m: -70.588,
      price_pc_1y: -70.588,
      price_pc_ytd: -70.588,
    },
    6110: {
      id: "6110",
      c: 6.55,
      ma50: 7.324,
      ma100: 7.948,
      ma150: 8.424,
      ma200: 9.166,
      maw30: 8.403,
      rs: 21.0,
      rsd1d: 2.0,
      rsd5d: 2.0,
      rsd10d: 2.0,
      price_5d_h: 6.61,
      price_5d_L: 6.02,
      price_1m_h: 8.34,
      price_1m_l: 5.38,
      price_3m_h: 8.95,
      price_3m_l: 5.38,
      price_6m_h: 10.06,
      price_6m_l: 5.38,
      price_52w_h: 13.5,
      price_52w_l: 5.38,
      price_pc_1d: 1.55,
      price_pc_1w: -0.908,
      price_pc_1m: -19.036,
      price_pc_6m: -26.404,
      price_pc_1y: -43.825,
      price_pc_ytd: -43.825,
    },
    6111: {
      id: "6111",
      c: 3.88,
      ma50: 3.829,
      ma100: 4.155,
      ma150: 4.632,
      ma200: 4.978,
      maw30: 4.59,
      rs: 26.0,
      rsd1d: -10.0,
      rsd5d: 13.0,
      rsd10d: 14.0,
      price_5d_h: 4.2,
      price_5d_L: 3.5,
      price_1m_h: 4.2,
      price_1m_l: 2.8,
      price_3m_h: 4.62,
      price_3m_l: 2.8,
      price_6m_h: 5.14,
      price_6m_l: 2.8,
      price_52w_h: 6.85,
      price_52w_l: 2.8,
      price_pc_1d: -6.954,
      price_pc_1w: 19.385,
      price_pc_1m: 4.021,
      price_pc_6m: -22.709,
      price_pc_1y: -39.375,
      price_pc_ytd: -39.375,
    },
    6116: {
      id: "6116",
      c: 0.4,
      ma50: 0.438,
      ma100: 0.489,
      ma150: 0.552,
      ma200: 0.592,
      maw30: 0.549,
      rs: 25.0,
      rsd1d: -5.0,
      rsd5d: 10.0,
      rsd10d: 10.0,
      price_5d_h: 0.495,
      price_5d_L: 0.32,
      price_1m_h: 0.495,
      price_1m_l: 0.32,
      price_3m_h: 0.7,
      price_3m_l: 0.32,
      price_6m_h: 0.72,
      price_6m_l: 0.32,
      price_52w_h: 0.93,
      price_52w_l: 0.32,
      price_pc_1d: -6.977,
      price_pc_1w: 5.263,
      price_pc_1m: -10.112,
      price_pc_6m: -44.444,
      price_pc_1y: -28.571,
      price_pc_ytd: -28.571,
    },
    6118: {
      id: "6118",
      c: 2.82,
      ma50: 2.798,
      ma100: 8.739,
      ma150: 14.23,
      ma200: 14.907,
      maw30: 14.183,
      rs: 2.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 3.64,
      price_5d_L: 2.79,
      price_1m_h: 3.86,
      price_1m_l: 2.28,
      price_3m_h: 15.46,
      price_3m_l: 1.88,
      price_6m_h: 30.9,
      price_6m_l: 1.88,
      price_52w_h: 30.9,
      price_52w_l: 1.88,
      price_pc_1d: -4.407,
      price_pc_1w: -18.023,
      price_pc_1m: 9.728,
      price_pc_6m: -89.594,
      price_pc_1y: -50.088,
      price_pc_ytd: -50.088,
    },
    6119: {
      id: "6119",
      c: 0.38,
      ma50: 0.351,
      ma100: 0.352,
      ma150: 0.369,
      ma200: 0.379,
      maw30: 0.367,
      rs: 79.0,
      rsd1d: 1.0,
      rsd5d: -2.0,
      rsd10d: 10.0,
      price_5d_h: 0.39,
      price_5d_L: 0.35,
      price_1m_h: 0.395,
      price_1m_l: 0.33,
      price_3m_h: 0.395,
      price_3m_l: 0.33,
      price_6m_h: 0.44,
      price_6m_l: 0.33,
      price_52w_h: 0.59,
      price_52w_l: 0.33,
      price_pc_1d: 1.333,
      price_pc_1w: 0.0,
      price_pc_1m: 8.571,
      price_pc_6m: -1.299,
      price_pc_1y: -6.173,
      price_pc_ytd: -6.173,
    },
    6122: {
      id: "6122",
      c: 2.4,
      ma50: 2.4,
      ma100: 2.398,
      ma150: 2.398,
      ma200: 2.394,
      maw30: 2.398,
      rs: 74.0,
      rsd1d: 1.0,
      rsd5d: -1.0,
      rsd10d: -1.0,
      price_5d_h: 2.4,
      price_5d_L: 2.4,
      price_1m_h: 2.41,
      price_1m_l: 2.4,
      price_3m_h: 2.6,
      price_3m_l: 2.39,
      price_6m_h: 2.6,
      price_6m_l: 2.2,
      price_52w_h: 2.724,
      price_52w_l: 2.2,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: 0.0,
      price_pc_6m: 0.0,
      price_pc_1y: -4.182,
      price_pc_ytd: -4.182,
    },
    6123: {
      id: "6123",
      c: 2.36,
      ma50: 2.552,
      ma100: 2.797,
      ma150: 2.925,
      ma200: 3.135,
      maw30: 2.94,
      rs: 19.0,
      rsd1d: 2.0,
      rsd5d: 2.0,
      rsd10d: 5.0,
      price_5d_h: 2.41,
      price_5d_L: 2.23,
      price_1m_h: 2.62,
      price_1m_l: 1.85,
      price_3m_h: 3.3,
      price_3m_l: 1.85,
      price_6m_h: 3.73,
      price_6m_l: 1.85,
      price_52w_h: 5.42,
      price_52w_l: 1.85,
      price_pc_1d: -0.422,
      price_pc_1w: 0.0,
      price_pc_1m: -10.266,
      price_pc_6m: -22.112,
      price_pc_1y: -55.805,
      price_pc_ytd: -55.805,
    },
    6127: {
      id: "6127",
      c: 72.45,
      ma50: 62.157,
      ma100: 69.558,
      ma150: 80.48,
      ma200: 90.337,
      maw30: 79.915,
      rs: 45.0,
      rsd1d: -10.0,
      rsd5d: 2.0,
      rsd10d: 24.0,
      price_5d_h: 74.95,
      price_5d_L: 66.65,
      price_1m_h: 74.95,
      price_1m_l: 48.5,
      price_3m_h: 74.95,
      price_3m_l: 48.5,
      price_6m_h: 110.9,
      price_6m_l: 48.5,
      price_52w_h: 138.0,
      price_52w_l: 48.5,
      price_pc_1d: -3.142,
      price_pc_1w: -1.092,
      price_pc_1m: 11.29,
      price_pc_6m: -31.262,
      price_pc_1y: -27.029,
      price_pc_ytd: -27.029,
    },
    6128: {
      id: "6128",
      c: 1.5,
      ma50: 1.247,
      ma100: 1.036,
      ma150: 0.943,
      ma200: 0.924,
      maw30: 0.953,
      rs: 96.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 3.0,
      price_5d_h: 1.67,
      price_5d_L: 1.3,
      price_1m_h: 1.67,
      price_1m_l: 1.02,
      price_3m_h: 1.69,
      price_3m_l: 0.87,
      price_6m_h: 1.69,
      price_6m_l: 0.62,
      price_52w_h: 1.69,
      price_52w_l: 0.62,
      price_pc_1d: 0.0,
      price_pc_1w: -1.961,
      price_pc_1m: 33.929,
      price_pc_6m: 120.588,
      price_pc_1y: 51.515,
      price_pc_ytd: 51.515,
    },
    6133: {
      id: "6133",
      c: 0.225,
      ma50: 0.237,
      ma100: 0.265,
      ma150: 0.283,
      ma200: 0.275,
      maw30: 0.286,
      rs: 30.0,
      rsd1d: 0.0,
      rsd5d: 10.0,
      rsd10d: 0.0,
      price_5d_h: 0.225,
      price_5d_L: 0.205,
      price_1m_h: 0.255,
      price_1m_l: 0.201,
      price_3m_h: 0.28,
      price_3m_l: 0.2,
      price_6m_h: 0.385,
      price_6m_l: 0.2,
      price_52w_h: 0.385,
      price_52w_l: 0.2,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: -9.274,
      price_pc_6m: -28.571,
      price_pc_1y: -37.5,
      price_pc_ytd: -37.5,
    },
    6136: {
      id: "6136",
      c: 0.7,
      ma50: 0.762,
      ma100: 0.759,
      ma150: 0.792,
      ma200: 0.764,
      maw30: 0.799,
      rs: 60.0,
      rsd1d: 2.0,
      rsd5d: -4.0,
      rsd10d: -7.0,
      price_5d_h: 0.74,
      price_5d_L: 0.68,
      price_1m_h: 0.79,
      price_1m_l: 0.64,
      price_3m_h: 0.88,
      price_3m_l: 0.64,
      price_6m_h: 1.06,
      price_6m_l: 0.64,
      price_52w_h: 1.06,
      price_52w_l: 0.63,
      price_pc_1d: 0.0,
      price_pc_1w: -5.405,
      price_pc_1m: -11.392,
      price_pc_6m: -19.54,
      price_pc_1y: 1.449,
      price_pc_ytd: 1.449,
    },
    6138: {
      id: "6138",
      c: 0.75,
      ma50: 0.764,
      ma100: 0.783,
      ma150: 0.801,
      ma200: 0.84,
      maw30: 0.799,
      rs: 49.0,
      rsd1d: 8.0,
      rsd5d: 5.0,
      rsd10d: 13.0,
      price_5d_h: 0.75,
      price_5d_L: 0.71,
      price_1m_h: 0.79,
      price_1m_l: 0.64,
      price_3m_h: 0.88,
      price_3m_l: 0.64,
      price_6m_h: 0.9,
      price_6m_l: 0.64,
      price_52w_h: 1.03,
      price_52w_l: 0.64,
      price_pc_1d: 2.74,
      price_pc_1w: 1.351,
      price_pc_1m: -2.597,
      price_pc_6m: -7.407,
      price_pc_1y: -24.242,
      price_pc_ytd: -24.242,
    },
    6158: {
      id: "6158",
      c: 0.61,
      ma50: 1.586,
      ma100: 2.767,
      ma150: 3.217,
      ma200: 3.536,
      maw30: 3.234,
      rs: 1.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.64,
      price_5d_L: 0.53,
      price_1m_h: 0.71,
      price_1m_l: 0.415,
      price_3m_h: 4.08,
      price_3m_l: 0.415,
      price_6m_h: 4.78,
      price_6m_l: 0.415,
      price_52w_h: 5.59,
      price_52w_l: 0.415,
      price_pc_1d: -3.175,
      price_pc_1w: 0.0,
      price_pc_1m: -8.955,
      price_pc_6m: -85.647,
      price_pc_1y: -88.869,
      price_pc_ytd: -88.869,
    },
    6160: {
      id: "6160",
      c: 121.2,
      ma50: 123.499,
      ma100: 153.736,
      ma150: 173.872,
      ma200: 178.031,
      maw30: 175.88,
      rs: 17.0,
      rsd1d: 0.0,
      rsd5d: 3.0,
      rsd10d: 4.0,
      price_5d_h: 126.9,
      price_5d_L: 110.0,
      price_1m_h: 132.1,
      price_1m_l: 82.5,
      price_3m_h: 164.6,
      price_3m_l: 82.5,
      price_6m_h: 239.6,
      price_6m_l: 82.5,
      price_52w_h: 278.6,
      price_52w_l: 82.5,
      price_pc_1d: -3.272,
      price_pc_1w: 2.365,
      price_pc_1m: -4.416,
      price_pc_6m: -45.158,
      price_pc_1y: -43.099,
      price_pc_ytd: -43.099,
    },
    6162: {
      id: "6162",
      c: 0.109,
      ma50: 0.136,
      ma100: 0.149,
      ma150: 0.17,
      ma200: 0.178,
      maw30: 0.163,
      rs: 25.0,
      rsd1d: -3.0,
      rsd5d: -1.0,
      rsd10d: 1.0,
      price_5d_h: 0.12,
      price_5d_L: 0.102,
      price_1m_h: 0.189,
      price_1m_l: 0.095,
      price_3m_h: 0.189,
      price_3m_l: 0.095,
      price_6m_h: 0.238,
      price_6m_l: 0.095,
      price_52w_h: 0.49,
      price_52w_l: 0.095,
      price_pc_1d: -6.034,
      price_pc_1w: -5.217,
      price_pc_1m: -26.351,
      price_pc_6m: -44.103,
      price_pc_1y: -19.853,
      price_pc_ytd: -19.853,
    },
    6163: {
      id: "6163",
      c: 0.72,
      ma50: 0.685,
      ma100: 0.682,
      ma150: 0.643,
      ma200: 0.651,
      maw30: 0.646,
      rs: 76.0,
      rsd1d: 24.0,
      rsd5d: 4.0,
      rsd10d: 6.0,
      price_5d_h: 0.75,
      price_5d_L: 0.51,
      price_1m_h: 0.75,
      price_1m_l: 0.285,
      price_3m_h: 0.75,
      price_3m_l: 0.285,
      price_6m_h: 0.75,
      price_6m_l: 0.285,
      price_52w_h: 0.91,
      price_52w_l: 0.285,
      price_pc_1d: 12.5,
      price_pc_1w: 5.882,
      price_pc_1m: 5.882,
      price_pc_6m: 14.286,
      price_pc_1y: -20.0,
      price_pc_ytd: -20.0,
    },
    6166: {
      id: "6166",
      c: 1.73,
      ma50: 1.583,
      ma100: 1.639,
      ma150: 1.89,
      ma200: 2.129,
      maw30: 1.861,
      rs: 40.0,
      rsd1d: -4.0,
      rsd5d: 13.0,
      rsd10d: 1.0,
      price_5d_h: 1.8,
      price_5d_L: 1.61,
      price_1m_h: 1.8,
      price_1m_l: 1.37,
      price_3m_h: 1.8,
      price_3m_l: 1.33,
      price_6m_h: 2.51,
      price_6m_l: 1.18,
      price_52w_h: 3.15,
      price_52w_l: 1.18,
      price_pc_1d: -1.143,
      price_pc_1w: 9.494,
      price_pc_1m: 2.367,
      price_pc_6m: -27.917,
      price_pc_1y: -42.333,
      price_pc_ytd: -42.333,
    },
    6169: {
      id: "6169",
      c: 1.78,
      ma50: 1.914,
      ma100: 2.482,
      ma150: 2.972,
      ma200: 3.69,
      maw30: 2.931,
      rs: 6.0,
      rsd1d: 1.0,
      rsd5d: -2.0,
      rsd10d: 1.0,
      price_5d_h: 2.01,
      price_5d_L: 1.67,
      price_1m_h: 2.15,
      price_1m_l: 1.37,
      price_3m_h: 2.89,
      price_3m_l: 1.37,
      price_6m_h: 5.25,
      price_6m_l: 1.37,
      price_52w_h: 8.54,
      price_52w_l: 1.37,
      price_pc_1d: -1.111,
      price_pc_1w: -9.184,
      price_pc_1m: -11.881,
      price_pc_6m: -52.91,
      price_pc_1y: -70.234,
      price_pc_ytd: -70.234,
    },
    6178: {
      id: "6178",
      c: 5.37,
      ma50: 5.736,
      ma100: 5.883,
      ma150: 5.996,
      ma200: 6.078,
      maw30: 6.003,
      rs: 44.0,
      rsd1d: 2.0,
      rsd5d: 2.0,
      rsd10d: 1.0,
      price_5d_h: 5.46,
      price_5d_L: 5.12,
      price_1m_h: 5.89,
      price_1m_l: 4.71,
      price_3m_h: 6.29,
      price_3m_l: 4.71,
      price_6m_h: 6.35,
      price_6m_l: 4.71,
      price_52w_h: 7.35,
      price_52w_l: 4.71,
      price_pc_1d: -0.923,
      price_pc_1w: -0.371,
      price_pc_1m: -7.414,
      price_pc_6m: -10.945,
      price_pc_1y: -23.395,
      price_pc_ytd: -23.395,
    },
    6185: {
      id: "6185",
      c: 125.9,
      ma50: 133.7,
      ma100: 148.844,
      ma150: 184.207,
      ma200: 223.18,
      maw30: 181.93,
      rs: 9.0,
      rsd1d: 0.0,
      rsd5d: -6.0,
      rsd10d: 0.0,
      price_5d_h: 154.2,
      price_5d_L: 121.0,
      price_1m_h: 157.0,
      price_1m_l: 102.1,
      price_3m_h: 184.9,
      price_3m_l: 102.1,
      price_6m_h: 266.8,
      price_6m_l: 102.1,
      price_52w_h: 437.0,
      price_52w_l: 102.1,
      price_pc_1d: -5.551,
      price_pc_1w: -18.617,
      price_pc_1m: -10.519,
      price_pc_6m: -54.218,
      price_pc_1y: -60.209,
      price_pc_ytd: -60.209,
    },
    6186: {
      id: "6186",
      c: 7.76,
      ma50: 9.647,
      ma100: 10.287,
      ma150: 11.329,
      ma200: 12.497,
      maw30: 11.341,
      rs: 12.0,
      rsd1d: 2.0,
      rsd5d: -2.0,
      rsd10d: -4.0,
      price_5d_h: 8.73,
      price_5d_L: 7.36,
      price_1m_h: 9.46,
      price_1m_l: 7.34,
      price_3m_h: 12.34,
      price_3m_l: 7.34,
      price_6m_h: 14.2,
      price_6m_l: 7.34,
      price_52w_h: 23.45,
      price_52w_l: 7.34,
      price_pc_1d: 1.971,
      price_pc_1w: -8.706,
      price_pc_1m: -16.738,
      price_pc_6m: -40.944,
      price_pc_1y: -65.202,
      price_pc_ytd: -65.202,
    },
    6189: {
      id: "6189",
      c: 0.395,
      ma50: 0.587,
      ma100: 0.641,
      ma150: 0.68,
      ma200: 0.701,
      maw30: 0.688,
      rs: 9.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -7.0,
      price_5d_h: 0.43,
      price_5d_L: 0.4,
      price_1m_h: 0.64,
      price_1m_l: 0.37,
      price_3m_h: 0.72,
      price_3m_l: 0.37,
      price_6m_h: 0.76,
      price_6m_l: 0.37,
      price_52w_h: 1.08,
      price_52w_l: 0.37,
      price_pc_1d: -3.659,
      price_pc_1w: -7.059,
      price_pc_1m: -37.302,
      price_pc_6m: -49.359,
      price_pc_1y: -56.593,
      price_pc_ytd: -56.593,
    },
    6193: {
      id: "6193",
      c: 0.345,
      ma50: 0.37,
      ma100: 0.377,
      ma150: 0.396,
      ma200: 0.46,
      maw30: 0.394,
      rs: 29.0,
      rsd1d: 3.0,
      rsd5d: 4.0,
      rsd10d: 4.0,
      price_5d_h: 0.36,
      price_5d_L: 0.32,
      price_1m_h: 0.38,
      price_1m_l: 0.32,
      price_3m_h: 0.395,
      price_3m_l: 0.32,
      price_6m_h: 0.455,
      price_6m_l: 0.32,
      price_52w_h: 1.13,
      price_52w_l: 0.32,
      price_pc_1d: -1.429,
      price_pc_1w: 2.985,
      price_pc_1m: -10.39,
      price_pc_6m: -24.176,
      price_pc_1y: -28.866,
      price_pc_ytd: -28.866,
    },
    6196: {
      id: "6196",
      c: 1.63,
      ma50: 1.702,
      ma100: 1.692,
      ma150: 1.642,
      ma200: 1.624,
      maw30: 1.652,
      rs: 72.0,
      rsd1d: 1.0,
      rsd5d: -4.0,
      rsd10d: -4.0,
      price_5d_h: 1.68,
      price_5d_L: 1.59,
      price_1m_h: 1.72,
      price_1m_l: 1.55,
      price_3m_h: 1.77,
      price_3m_l: 1.55,
      price_6m_h: 1.77,
      price_6m_l: 1.479,
      price_52w_h: 1.77,
      price_52w_l: 1.463,
      price_pc_1d: -0.61,
      price_pc_1w: -1.807,
      price_pc_1m: -5.233,
      price_pc_6m: 4.91,
      price_pc_1y: -2.361,
      price_pc_ytd: -2.361,
    },
    6198: {
      id: "6198",
      c: 4.02,
      ma50: 4.209,
      ma100: 4.172,
      ma150: 4.131,
      ma200: 4.149,
      maw30: 4.149,
      rs: 61.0,
      rsd1d: -2.0,
      rsd5d: -3.0,
      rsd10d: -3.0,
      price_5d_h: 4.14,
      price_5d_L: 4.0,
      price_1m_h: 4.3,
      price_1m_l: 3.73,
      price_3m_h: 4.5,
      price_3m_l: 3.73,
      price_6m_h: 4.5,
      price_6m_l: 3.73,
      price_52w_h: 4.9,
      price_52w_l: 3.73,
      price_pc_1d: -1.471,
      price_pc_1w: -1.951,
      price_pc_1m: -4.286,
      price_pc_6m: -1.229,
      price_pc_1y: -14.831,
      price_pc_ytd: -14.831,
    },
    6199: {
      id: "6199",
      c: 2.6,
      ma50: 2.524,
      ma100: 2.556,
      ma150: 2.559,
      ma200: 2.564,
      maw30: 2.569,
      rs: 70.0,
      rsd1d: 19.0,
      rsd5d: 6.0,
      rsd10d: -6.0,
      price_5d_h: 2.6,
      price_5d_L: 2.38,
      price_1m_h: 2.6,
      price_1m_l: 2.38,
      price_3m_h: 2.7,
      price_3m_l: 2.38,
      price_6m_h: 2.8,
      price_6m_l: 2.38,
      price_52w_h: 2.92,
      price_52w_l: 2.38,
      price_pc_1d: 8.333,
      price_pc_1w: 6.557,
      price_pc_1m: -2.622,
      price_pc_6m: 1.961,
      price_pc_1y: -0.763,
      price_pc_ytd: -0.763,
    },
    6600: {
      id: "6600",
      c: 8.13,
      ma50: 7.977,
      ma100: 8.411,
      ma150: 9.164,
      ma200: 10.177,
      maw30: 9.186,
      rs: 28.0,
      rsd1d: -2.0,
      rsd5d: 9.0,
      rsd10d: 10.0,
      price_5d_h: 8.8,
      price_5d_L: 7.63,
      price_1m_h: 8.8,
      price_1m_l: 6.6,
      price_3m_h: 8.97,
      price_3m_l: 6.6,
      price_6m_h: 11.34,
      price_6m_l: 6.6,
      price_52w_h: 16.6,
      price_52w_l: 6.6,
      price_pc_1d: -2.635,
      price_pc_1w: 7.54,
      price_pc_1m: 3.567,
      price_pc_6m: -21.827,
      price_pc_1y: -48.868,
      price_pc_ytd: -48.868,
    },
    6601: {
      id: "6601",
      c: 2.64,
      ma50: 2.307,
      ma100: 2.765,
      ma150: 3.239,
      ma200: 4.187,
      maw30: 3.194,
      rs: 13.0,
      rsd1d: 2.0,
      rsd5d: 9.0,
      rsd10d: 7.0,
      price_5d_h: 2.66,
      price_5d_L: 1.99,
      price_1m_h: 2.66,
      price_1m_l: 1.73,
      price_3m_h: 3.16,
      price_3m_l: 1.73,
      price_6m_h: 4.32,
      price_6m_l: 1.73,
      price_52w_h: 9.1,
      price_52w_l: 1.73,
      price_pc_1d: 1.931,
      price_pc_1w: 27.536,
      price_pc_1m: 15.284,
      price_pc_6m: -37.143,
      price_pc_1y: -70.667,
      price_pc_ytd: -70.667,
    },
    6606: {
      id: "6606",
      c: 22.8,
      ma50: 24.245,
      ma100: 25.062,
      ma150: 28.943,
      ma200: 39.166,
      maw30: 28.313,
      rs: 20.0,
      rsd1d: 5.0,
      rsd5d: 6.0,
      rsd10d: 11.0,
      price_5d_h: 24.25,
      price_5d_L: 19.9,
      price_1m_h: 29.7,
      price_1m_l: 14.52,
      price_3m_h: 34.45,
      price_3m_l: 14.52,
      price_6m_h: 36.35,
      price_6m_l: 14.52,
      price_52w_h: 89.65,
      price_52w_l: 14.52,
      price_pc_1d: 1.559,
      price_pc_1w: -4.0,
      price_pc_1m: -22.051,
      price_pc_6m: -33.04,
      price_pc_1y: -62.032,
      price_pc_ytd: -62.032,
    },
    6608: {
      id: "6608",
      c: 9.73,
      ma50: 8.925,
      ma100: 9.669,
      ma150: 10.796,
      ma200: 12.202,
      maw30: 10.898,
      rs: 23.0,
      rsd1d: -3.0,
      rsd5d: -9.0,
      rsd10d: 2.0,
      price_5d_h: 10.4,
      price_5d_L: 8.65,
      price_1m_h: 10.62,
      price_1m_l: 6.39,
      price_3m_h: 10.88,
      price_3m_l: 6.39,
      price_6m_h: 13.86,
      price_6m_l: 6.39,
      price_52w_h: 27.8,
      price_52w_l: 6.39,
      price_pc_1d: 1.354,
      price_pc_1w: -5.534,
      price_pc_1m: 16.249,
      price_pc_6m: -21.659,
      price_pc_1y: -63.626,
      price_pc_ytd: -63.626,
    },
    6609: {
      id: "6609",
      c: 40.1,
      ma50: 56.501,
      ma100: 73.546,
      ma150: 85.64,
      ma200: null,
      maw30: 85.538,
      rs: 3.0,
      rsd1d: 0.0,
      rsd5d: -2.0,
      rsd10d: -4.0,
      price_5d_h: 45.0,
      price_5d_L: 39.0,
      price_1m_h: 49.45,
      price_1m_l: 35.75,
      price_3m_h: 105.7,
      price_3m_l: 35.75,
      price_6m_h: 116.1,
      price_6m_l: 35.75,
      price_52w_h: 147.5,
      price_52w_l: 35.75,
      price_pc_1d: -2.906,
      price_pc_1w: -11.283,
      price_pc_1m: -13.484,
      price_pc_6m: -67.131,
      price_pc_1y: -68.915,
      price_pc_ytd: -68.915,
    },
    6616: {
      id: "6616",
      c: 4.64,
      ma50: 5.388,
      ma100: 6.515,
      ma150: 6.321,
      ma200: null,
      maw30: 6.353,
      rs: 15.0,
      rsd1d: -4.0,
      rsd5d: -3.0,
      rsd10d: -15.0,
      price_5d_h: 5.3,
      price_5d_L: 4.33,
      price_1m_h: 5.82,
      price_1m_l: 3.8,
      price_3m_h: 11.36,
      price_3m_l: 3.8,
      price_6m_h: 11.36,
      price_6m_l: 3.8,
      price_52w_h: 11.36,
      price_52w_l: 3.2,
      price_pc_1d: -7.014,
      price_pc_1w: -5.691,
      price_pc_1m: -19.723,
      price_pc_6m: -22.667,
      price_pc_1y: 25.405,
      price_pc_ytd: 25.405,
    },
    6618: {
      id: "6618",
      c: 48.1,
      ma50: 55.937,
      ma100: 61.116,
      ma150: 65.619,
      ma200: 72.328,
      maw30: 65.813,
      rs: 14.0,
      rsd1d: -1.0,
      rsd5d: 4.0,
      rsd10d: 2.0,
      price_5d_h: 52.2,
      price_5d_L: 38.3,
      price_1m_h: 59.5,
      price_1m_l: 31.4,
      price_3m_h: 71.85,
      price_3m_l: 31.4,
      price_6m_h: 80.6,
      price_6m_l: 31.4,
      price_52w_h: 129.0,
      price_52w_l: 31.4,
      price_pc_1d: -4.658,
      price_pc_1w: 7.486,
      price_pc_1m: -16.926,
      price_pc_6m: -35.952,
      price_pc_1y: -57.955,
      price_pc_ytd: -57.955,
    },
    6622: {
      id: "6622",
      c: 3.5,
      ma50: 4.407,
      ma100: 5.178,
      ma150: 5.913,
      ma200: 6.933,
      maw30: 5.943,
      rs: 6.0,
      rsd1d: 1.0,
      rsd5d: 2.0,
      rsd10d: 0.0,
      price_5d_h: 3.57,
      price_5d_L: 3.3,
      price_1m_h: 4.77,
      price_1m_l: 3.07,
      price_3m_h: 5.84,
      price_3m_l: 3.07,
      price_6m_h: 7.7,
      price_6m_l: 3.07,
      price_52w_h: 15.6,
      price_52w_l: 3.07,
      price_pc_1d: -1.961,
      price_pc_1w: 1.744,
      price_pc_1m: -22.907,
      price_pc_6m: -53.519,
      price_pc_1y: -75.559,
      price_pc_ytd: -75.559,
    },
    6626: {
      id: "6626",
      c: 4.0,
      ma50: 4.026,
      ma100: 3.726,
      ma150: 3.705,
      ma200: null,
      maw30: 3.721,
      rs: 70.0,
      rsd1d: -7.0,
      rsd5d: -21.0,
      rsd10d: -18.0,
      price_5d_h: 4.15,
      price_5d_L: 3.71,
      price_1m_h: 4.24,
      price_1m_l: 3.22,
      price_3m_h: 4.55,
      price_3m_l: 3.22,
      price_6m_h: 4.55,
      price_6m_l: 3.0,
      price_52w_h: 5.73,
      price_52w_l: 3.0,
      price_pc_1d: -2.676,
      price_pc_1w: 0.0,
      price_pc_1m: 0.0,
      price_pc_6m: 10.497,
      price_pc_1y: -18.033,
      price_pc_ytd: -18.033,
    },
    6628: {
      id: "6628",
      c: 5.5,
      ma50: 6.606,
      ma100: 7.587,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 5.0,
      rsd1d: 1.0,
      rsd5d: -7.0,
      rsd10d: -15.0,
      price_5d_h: 5.65,
      price_5d_L: 4.88,
      price_1m_h: 7.0,
      price_1m_l: 4.0,
      price_3m_h: 10.2,
      price_3m_l: 4.0,
      price_6m_h: 15.98,
      price_6m_l: 4.0,
      price_52w_h: 16.18,
      price_52w_l: 4.0,
      price_pc_1d: -0.181,
      price_pc_1w: 0.917,
      price_pc_1m: -11.433,
      price_pc_6m: -65.625,
      price_pc_1y: -58.459,
      price_pc_ytd: -58.459,
    },
    6633: {
      id: "6633",
      c: 4.38,
      ma50: 5.619,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 3.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 4.7,
      price_5d_L: 4.15,
      price_1m_h: 5.33,
      price_1m_l: 3.43,
      price_3m_h: 11.84,
      price_3m_l: 3.43,
      price_6m_h: 11.84,
      price_6m_l: 3.43,
      price_52w_h: 11.84,
      price_52w_l: 3.43,
      price_pc_1d: -1.351,
      price_pc_1w: -0.455,
      price_pc_1m: -17.358,
      price_pc_6m: -58.989,
      price_pc_1y: -58.989,
      price_pc_ytd: -58.989,
    },
    6639: {
      id: "6639",
      c: 14.48,
      ma50: null,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 81.0,
      rsd1d: 0.0,
      rsd5d: 3.0,
      rsd10d: null,
      price_5d_h: 14.54,
      price_5d_L: 14.1,
      price_1m_h: 14.6,
      price_1m_l: 12.82,
      price_3m_h: 14.6,
      price_3m_l: 12.82,
      price_6m_h: 14.6,
      price_6m_l: 12.82,
      price_52w_h: 14.6,
      price_52w_l: 12.82,
      price_pc_1d: 0.695,
      price_pc_1w: 2.695,
      price_pc_1m: 4.928,
      price_pc_6m: 4.928,
      price_pc_1y: 4.928,
      price_pc_ytd: 4.928,
    },
    6655: {
      id: "6655",
      c: 13.58,
      ma50: null,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 71.0,
      rsd1d: -7.0,
      rsd5d: null,
      rsd10d: null,
      price_5d_h: 14.74,
      price_5d_L: 11.2,
      price_1m_h: 14.74,
      price_1m_l: 11.2,
      price_3m_h: 14.74,
      price_3m_l: 11.2,
      price_6m_h: 14.74,
      price_6m_l: 11.2,
      price_52w_h: 14.74,
      price_52w_l: 11.2,
      price_pc_1d: -3.414,
      price_pc_1w: -1.594,
      price_pc_1m: -1.594,
      price_pc_6m: -1.594,
      price_pc_1y: -1.594,
      price_pc_ytd: -1.594,
    },
    6663: {
      id: "6663",
      c: 0.83,
      ma50: 0.983,
      ma100: 0.889,
      ma150: 0.832,
      ma200: null,
      maw30: 0.829,
      rs: 86.0,
      rsd1d: 8.0,
      rsd5d: -5.0,
      rsd10d: -9.0,
      price_5d_h: 1.0,
      price_5d_L: 0.69,
      price_1m_h: 1.49,
      price_1m_l: 0.69,
      price_3m_h: 1.49,
      price_3m_l: 0.69,
      price_6m_h: 1.49,
      price_6m_l: 0.61,
      price_52w_h: 1.49,
      price_52w_l: 0.61,
      price_pc_1d: 9.211,
      price_pc_1w: -10.753,
      price_pc_1m: -33.065,
      price_pc_6m: 18.571,
      price_pc_1y: 7.792,
      price_pc_ytd: 7.792,
    },
    6668: {
      id: "6668",
      c: 2.68,
      ma50: 3.267,
      ma100: 3.379,
      ma150: 3.517,
      ma200: 3.855,
      maw30: 3.525,
      rs: 19.0,
      rsd1d: 3.0,
      rsd5d: -1.0,
      rsd10d: -7.0,
      price_5d_h: 2.88,
      price_5d_L: 2.41,
      price_1m_h: 3.74,
      price_1m_l: 2.0,
      price_3m_h: 3.97,
      price_3m_l: 2.0,
      price_6m_h: 4.09,
      price_6m_l: 2.0,
      price_52w_h: 6.15,
      price_52w_l: 2.0,
      price_pc_1d: 3.077,
      price_pc_1w: 0.0,
      price_pc_1m: -24.294,
      price_pc_6m: -26.171,
      price_pc_1y: -29.474,
      price_pc_ytd: -29.474,
    },
    6669: {
      id: "6669",
      c: 5.81,
      ma50: 8.801,
      ma100: 10.37,
      ma150: 12.019,
      ma200: null,
      maw30: 11.972,
      rs: 5.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: 1.0,
      price_5d_h: 6.16,
      price_5d_L: 5.22,
      price_1m_h: 10.68,
      price_1m_l: 4.21,
      price_3m_h: 12.1,
      price_3m_l: 4.21,
      price_6m_h: 15.3,
      price_6m_l: 4.21,
      price_52w_h: 19.36,
      price_52w_l: 4.21,
      price_pc_1d: 3.381,
      price_pc_1w: 1.396,
      price_pc_1m: -45.497,
      price_pc_6m: -61.267,
      price_pc_1y: -66.989,
      price_pc_ytd: -66.989,
    },
    6677: {
      id: "6677",
      c: 3.82,
      ma50: 4.065,
      ma100: 4.069,
      ma150: 4.251,
      ma200: 4.584,
      maw30: 4.26,
      rs: 31.0,
      rsd1d: 2.0,
      rsd5d: -6.0,
      rsd10d: -10.0,
      price_5d_h: 3.95,
      price_5d_L: 3.67,
      price_1m_h: 4.1,
      price_1m_l: 3.03,
      price_3m_h: 4.71,
      price_3m_l: 3.03,
      price_6m_h: 5.0,
      price_6m_l: 3.03,
      price_52w_h: 6.88,
      price_52w_l: 3.03,
      price_pc_1d: -1.546,
      price_pc_1w: -1.546,
      price_pc_1m: -3.291,
      price_pc_6m: -14.922,
      price_pc_1y: -21.399,
      price_pc_ytd: -21.399,
    },
    6680: {
      id: "6680",
      c: 24.8,
      ma50: 27.82,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 51.0,
      rsd1d: -2.0,
      rsd5d: -6.0,
      rsd10d: -5.0,
      price_5d_h: 26.45,
      price_5d_L: 24.6,
      price_1m_h: 30.05,
      price_1m_l: 23.6,
      price_3m_h: 33.35,
      price_3m_l: 23.6,
      price_6m_h: 33.35,
      price_6m_l: 23.6,
      price_52w_h: 33.35,
      price_52w_l: 23.6,
      price_pc_1d: -2.362,
      price_pc_1w: -4.432,
      price_pc_1m: -17.057,
      price_pc_6m: -12.057,
      price_pc_1y: -12.057,
      price_pc_ytd: -12.057,
    },
    6690: {
      id: "6690",
      c: 25.45,
      ma50: 27.567,
      ma100: 29.37,
      ma150: 28.994,
      ma200: 28.758,
      maw30: 29.292,
      rs: 43.0,
      rsd1d: -1.0,
      rsd5d: -3.0,
      rsd10d: -2.0,
      price_5d_h: 25.8,
      price_5d_L: 23.35,
      price_1m_h: 28.6,
      price_1m_l: 21.9,
      price_3m_h: 33.85,
      price_3m_l: 21.9,
      price_6m_h: 33.85,
      price_6m_l: 21.9,
      price_52w_h: 34.8,
      price_52w_l: 21.9,
      price_pc_1d: 0.593,
      price_pc_1w: 1.193,
      price_pc_1m: -6.606,
      price_pc_6m: -7.286,
      price_pc_1y: -23.112,
      price_pc_ytd: -23.112,
    },
    6699: {
      id: "6699",
      c: 134.8,
      ma50: 165.96,
      ma100: 233.186,
      ma150: 270.348,
      ma200: null,
      maw30: 264.3,
      rs: 5.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 150.3,
      price_5d_L: 111.0,
      price_1m_h: 194.7,
      price_1m_l: 111.0,
      price_3m_h: 262.0,
      price_3m_l: 111.0,
      price_6m_h: 376.0,
      price_6m_l: 111.0,
      price_52w_h: 490.0,
      price_52w_l: 111.0,
      price_pc_1d: -6.454,
      price_pc_1w: -10.313,
      price_pc_1m: -28.068,
      price_pc_6m: -59.398,
      price_pc_1y: -66.384,
      price_pc_ytd: -66.384,
    },
    6806: {
      id: "6806",
      c: 1.75,
      ma50: 1.867,
      ma100: 1.909,
      ma150: 1.946,
      ma200: 1.966,
      maw30: 1.96,
      rs: 48.0,
      rsd1d: 1.0,
      rsd5d: 4.0,
      rsd10d: 0.0,
      price_5d_h: 1.77,
      price_5d_L: 1.68,
      price_1m_h: 1.9,
      price_1m_l: 1.57,
      price_3m_h: 2.03,
      price_3m_l: 1.57,
      price_6m_h: 2.09,
      price_6m_l: 1.57,
      price_52w_h: 2.41,
      price_52w_l: 1.57,
      price_pc_1d: -0.568,
      price_pc_1w: 0.0,
      price_pc_1m: -6.915,
      price_pc_6m: -15.049,
      price_pc_1y: -21.525,
      price_pc_ytd: -21.525,
    },
    6808: {
      id: "6808",
      c: 2.85,
      ma50: 3.004,
      ma100: 3.088,
      ma150: 3.423,
      ma200: 3.89,
      maw30: 3.387,
      rs: 23.0,
      rsd1d: 1.0,
      rsd5d: -7.0,
      rsd10d: -10.0,
      price_5d_h: 3.1,
      price_5d_L: 2.78,
      price_1m_h: 3.26,
      price_1m_l: 2.49,
      price_3m_h: 3.72,
      price_3m_l: 2.49,
      price_6m_h: 4.85,
      price_6m_l: 2.49,
      price_52w_h: 7.54,
      price_52w_l: 2.49,
      price_pc_1d: -1.042,
      price_pc_1w: -6.863,
      price_pc_1m: 1.423,
      price_pc_6m: -20.833,
      price_pc_1y: -56.555,
      price_pc_ytd: -56.555,
    },
    6811: {
      id: "6811",
      c: 1.23,
      ma50: 1.213,
      ma100: 1.306,
      ma150: 1.405,
      ma200: 1.542,
      maw30: 1.403,
      rs: 26.0,
      rsd1d: -2.0,
      rsd5d: 3.0,
      rsd10d: 7.0,
      price_5d_h: 1.3,
      price_5d_L: 1.13,
      price_1m_h: 1.3,
      price_1m_l: 0.96,
      price_3m_h: 1.35,
      price_3m_l: 0.96,
      price_6m_h: 1.65,
      price_6m_l: 0.96,
      price_52w_h: 2.45,
      price_52w_l: 0.96,
      price_pc_1d: -3.15,
      price_pc_1w: 5.128,
      price_pc_1m: 1.653,
      price_pc_6m: -25.0,
      price_pc_1y: -43.318,
      price_pc_ytd: -43.318,
    },
    6818: {
      id: "6818",
      c: 2.97,
      ma50: 2.936,
      ma100: 2.858,
      ma150: 2.827,
      ma200: 2.839,
      maw30: 2.837,
      rs: 76.0,
      rsd1d: 4.0,
      rsd5d: 6.0,
      rsd10d: 4.0,
      price_5d_h: 2.98,
      price_5d_L: 2.84,
      price_1m_h: 2.98,
      price_1m_l: 2.65,
      price_3m_h: 3.11,
      price_3m_l: 2.65,
      price_6m_h: 3.11,
      price_6m_l: 2.65,
      price_52w_h: 3.49,
      price_52w_l: 2.63,
      price_pc_1d: 1.712,
      price_pc_1w: 3.484,
      price_pc_1m: 2.414,
      price_pc_6m: 8.0,
      price_pc_1y: -12.389,
      price_pc_ytd: -12.389,
    },
    6819: {
      id: "6819",
      c: 6.59,
      ma50: 6.958,
      ma100: 7.126,
      ma150: 7.337,
      ma200: 7.591,
      maw30: 7.335,
      rs: 47.0,
      rsd1d: 4.0,
      rsd5d: -2.0,
      rsd10d: -9.0,
      price_5d_h: 6.7,
      price_5d_L: 6.45,
      price_1m_h: 6.99,
      price_1m_l: 6.2,
      price_3m_h: 8.14,
      price_3m_l: 6.2,
      price_6m_h: 8.55,
      price_6m_l: 6.2,
      price_52w_h: 9.28,
      price_52w_l: 6.2,
      price_pc_1d: 0.457,
      price_pc_1w: -1.495,
      price_pc_1m: -0.152,
      price_pc_6m: -19.732,
      price_pc_1y: 1.385,
      price_pc_ytd: 1.385,
    },
    6820: {
      id: "6820",
      c: 1.3,
      ma50: 1.297,
      ma100: 1.305,
      ma150: 1.301,
      ma200: 1.449,
      maw30: 1.294,
      rs: 34.0,
      rsd1d: -1.0,
      rsd5d: -4.0,
      rsd10d: -3.0,
      price_5d_h: 1.33,
      price_5d_L: 1.22,
      price_1m_h: 1.35,
      price_1m_l: 1.0,
      price_3m_h: 1.71,
      price_3m_l: 1.0,
      price_6m_h: 1.71,
      price_6m_l: 1.0,
      price_52w_h: 3.1,
      price_52w_l: 1.0,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: 5.691,
      price_pc_6m: -0.763,
      price_pc_1y: -51.311,
      price_pc_ytd: -51.311,
    },
    6821: {
      id: "6821",
      c: 278.0,
      ma50: 256.044,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 49.0,
      rsd1d: -1.0,
      rsd5d: 16.0,
      rsd10d: 20.0,
      price_5d_h: 288.0,
      price_5d_L: 245.2,
      price_1m_h: 288.0,
      price_1m_l: 210.0,
      price_3m_h: 333.8,
      price_3m_l: 210.0,
      price_6m_h: 388.0,
      price_6m_l: 210.0,
      price_52w_h: 388.0,
      price_52w_l: 210.0,
      price_pc_1d: -1.767,
      price_pc_1w: 9.191,
      price_pc_1m: 4.276,
      price_pc_6m: -24.865,
      price_pc_1y: -24.865,
      price_pc_ytd: -24.865,
    },
    6822: {
      id: "6822",
      c: 1.19,
      ma50: 1.277,
      ma100: 1.29,
      ma150: 1.308,
      ma200: 1.332,
      maw30: 1.308,
      rs: 51.0,
      rsd1d: 9.0,
      rsd5d: 4.0,
      rsd10d: -10.0,
      price_5d_h: 1.19,
      price_5d_L: 1.12,
      price_1m_h: 1.3,
      price_1m_l: 1.12,
      price_3m_h: 1.39,
      price_3m_l: 1.12,
      price_6m_h: 1.45,
      price_6m_l: 1.12,
      price_52w_h: 1.53,
      price_52w_l: 1.12,
      price_pc_1d: 2.586,
      price_pc_1w: 0.0,
      price_pc_1m: -7.031,
      price_pc_6m: -10.526,
      price_pc_1y: -18.493,
      price_pc_ytd: -18.493,
    },
    6823: {
      id: "6823",
      c: 10.76,
      ma50: 10.693,
      ma100: 10.64,
      ma150: 10.638,
      ma200: 10.634,
      maw30: 10.652,
      rs: 76.0,
      rsd1d: -1.0,
      rsd5d: -2.0,
      rsd10d: -2.0,
      price_5d_h: 10.96,
      price_5d_L: 10.74,
      price_1m_h: 11.0,
      price_1m_l: 10.28,
      price_3m_h: 11.0,
      price_3m_l: 10.28,
      price_6m_h: 11.0,
      price_6m_l: 10.28,
      price_52w_h: 11.5,
      price_52w_l: 10.28,
      price_pc_1d: -0.738,
      price_pc_1w: -1.284,
      price_pc_1m: 2.087,
      price_pc_6m: 0.938,
      price_pc_1y: -3.237,
      price_pc_ytd: -3.237,
    },
    6826: {
      id: "6826",
      c: 39.6,
      ma50: 42.022,
      ma100: 47.22,
      ma150: 53.063,
      ma200: 62.183,
      maw30: 53.228,
      rs: 17.0,
      rsd1d: 1.0,
      rsd5d: -2.0,
      rsd10d: -2.0,
      price_5d_h: 40.75,
      price_5d_L: 36.3,
      price_1m_h: 42.0,
      price_1m_l: 31.5,
      price_3m_h: 56.7,
      price_3m_l: 31.5,
      price_6m_h: 67.3,
      price_6m_l: 31.5,
      price_52w_h: 150.0,
      price_52w_l: 31.5,
      price_pc_1d: -1.124,
      price_pc_1w: -1.737,
      price_pc_1m: -5.714,
      price_pc_6m: -36.842,
      price_pc_1y: -34.491,
      price_pc_ytd: -34.491,
    },
    6829: {
      id: "6829",
      c: 0.23,
      ma50: 0.247,
      ma100: 0.209,
      ma150: 0.187,
      ma200: 0.173,
      maw30: 0.19,
      rs: 93.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: 1.0,
      price_5d_h: 0.238,
      price_5d_L: 0.215,
      price_1m_h: 0.275,
      price_1m_l: 0.206,
      price_3m_h: 0.31,
      price_3m_l: 0.18,
      price_6m_h: 0.31,
      price_6m_l: 0.133,
      price_52w_h: 0.31,
      price_52w_l: 0.1,
      price_pc_1d: -3.361,
      price_pc_1w: 4.545,
      price_pc_1m: -20.69,
      price_pc_6m: 54.362,
      price_pc_1y: 37.725,
      price_pc_ytd: 37.725,
    },
    6830: {
      id: "6830",
      c: 2.72,
      ma50: 2.933,
      ma100: 3.09,
      ma150: 3.322,
      ma200: 3.092,
      maw30: 3.305,
      rs: 88.0,
      rsd1d: -1.0,
      rsd5d: 3.0,
      rsd10d: 1.0,
      price_5d_h: 2.9,
      price_5d_L: 2.68,
      price_1m_h: 3.04,
      price_1m_l: 2.63,
      price_3m_h: 3.51,
      price_3m_l: 2.63,
      price_6m_h: 5.09,
      price_6m_l: 2.63,
      price_52w_h: 5.09,
      price_52w_l: 1.14,
      price_pc_1d: -1.091,
      price_pc_1w: -1.091,
      price_pc_1m: -9.03,
      price_pc_6m: -25.069,
      price_pc_1y: 56.322,
      price_pc_ytd: 56.322,
    },
    6833: {
      id: "6833",
      c: 2.14,
      ma50: 1.748,
      ma100: 1.731,
      ma150: 1.406,
      ma200: 1.201,
      maw30: 1.42,
      rs: 98.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 2.16,
      price_5d_L: 1.9,
      price_1m_h: 2.16,
      price_1m_l: 1.48,
      price_3m_h: 2.32,
      price_3m_l: 1.48,
      price_6m_h: 2.32,
      price_6m_l: 0.7,
      price_52w_h: 2.32,
      price_52w_l: 0.495,
      price_pc_1d: 6.468,
      price_pc_1w: 2.392,
      price_pc_1m: 27.381,
      price_pc_6m: 214.706,
      price_pc_1y: 250.82,
      price_pc_ytd: 250.82,
    },
    6836: {
      id: "6836",
      c: 1.53,
      ma50: 1.478,
      ma100: 1.499,
      ma150: 1.495,
      ma200: 1.52,
      maw30: 1.498,
      rs: 71.0,
      rsd1d: 4.0,
      rsd5d: 8.0,
      rsd10d: 1.0,
      price_5d_h: 1.54,
      price_5d_L: 1.45,
      price_1m_h: 1.6,
      price_1m_l: 1.3,
      price_3m_h: 1.61,
      price_3m_l: 1.3,
      price_6m_h: 1.62,
      price_6m_l: 1.3,
      price_52w_h: 1.83,
      price_52w_l: 1.3,
      price_pc_1d: 1.325,
      price_pc_1w: 3.378,
      price_pc_1m: 8.511,
      price_pc_6m: 2.685,
      price_pc_1y: -11.561,
      price_pc_ytd: -11.561,
    },
    6837: {
      id: "6837",
      c: 5.98,
      ma50: 6.701,
      ma100: 6.773,
      ma150: 6.891,
      ma200: 6.854,
      maw30: 6.923,
      rs: 45.0,
      rsd1d: -7.0,
      rsd5d: -8.0,
      rsd10d: -10.0,
      price_5d_h: 6.31,
      price_5d_L: 5.98,
      price_1m_h: 6.85,
      price_1m_l: 5.56,
      price_3m_h: 7.34,
      price_3m_l: 5.56,
      price_6m_h: 7.34,
      price_6m_l: 5.56,
      price_52w_h: 7.81,
      price_52w_l: 5.56,
      price_pc_1d: -4.167,
      price_pc_1w: -3.704,
      price_pc_1m: -11.012,
      price_pc_6m: -16.129,
      price_pc_1y: -16.944,
      price_pc_ytd: -16.944,
    },
    6839: {
      id: "6839",
      c: 0.71,
      ma50: 0.826,
      ma100: 0.882,
      ma150: 0.939,
      ma200: 0.998,
      maw30: 0.94,
      rs: 20.0,
      rsd1d: 2.0,
      rsd5d: 0.0,
      rsd10d: -1.0,
      price_5d_h: 0.74,
      price_5d_L: 0.7,
      price_1m_h: 0.87,
      price_1m_l: 0.6,
      price_3m_h: 0.93,
      price_3m_l: 0.6,
      price_6m_h: 1.19,
      price_6m_l: 0.6,
      price_52w_h: 1.34,
      price_52w_l: 0.6,
      price_pc_1d: -1.389,
      price_pc_1w: -2.74,
      price_pc_1m: -16.471,
      price_pc_6m: -32.381,
      price_pc_1y: -37.168,
      price_pc_ytd: -37.168,
    },
    6855: {
      id: "6855",
      c: 16.88,
      ma50: 18.742,
      ma100: 23.831,
      ma150: 27.298,
      ma200: 31.846,
      maw30: 27.081,
      rs: 9.0,
      rsd1d: 1.0,
      rsd5d: -1.0,
      rsd10d: 1.0,
      price_5d_h: 18.42,
      price_5d_L: 14.5,
      price_1m_h: 18.9,
      price_1m_l: 11.28,
      price_3m_h: 28.85,
      price_3m_l: 11.28,
      price_6m_h: 38.0,
      price_6m_l: 11.28,
      price_52w_h: 58.2,
      price_52w_l: 11.28,
      price_pc_1d: 2.179,
      price_pc_1w: -7.355,
      price_pc_1m: -7.659,
      price_pc_6m: -51.143,
      price_pc_1y: -49.233,
      price_pc_ytd: -49.233,
    },
    6858: {
      id: "6858",
      c: 3.13,
      ma50: 3.279,
      ma100: 3.32,
      ma150: 3.344,
      ma200: 3.54,
      maw30: 3.349,
      rs: 40.0,
      rsd1d: 3.0,
      rsd5d: 4.0,
      rsd10d: -1.0,
      price_5d_h: 3.24,
      price_5d_L: 2.95,
      price_1m_h: 3.38,
      price_1m_l: 2.86,
      price_3m_h: 3.55,
      price_3m_l: 2.86,
      price_6m_h: 3.96,
      price_6m_l: 2.86,
      price_52w_h: 5.92,
      price_52w_l: 2.86,
      price_pc_1d: -0.318,
      price_pc_1w: -1.572,
      price_pc_1m: -7.396,
      price_pc_6m: -2.795,
      price_pc_1y: -45.565,
      price_pc_ytd: -45.565,
    },
    6860: {
      id: "6860",
      c: 0.415,
      ma50: 0.232,
      ma100: 0.244,
      ma150: 0.229,
      ma200: 0.223,
      maw30: 0.221,
      rs: 95.0,
      rsd1d: -1.0,
      rsd5d: 1.0,
      rsd10d: 80.0,
      price_5d_h: 0.47,
      price_5d_L: 0.32,
      price_1m_h: 0.48,
      price_1m_l: 0.136,
      price_3m_h: 0.8,
      price_3m_l: 0.136,
      price_6m_h: 0.8,
      price_6m_l: 0.136,
      price_52w_h: 0.8,
      price_52w_l: 0.136,
      price_pc_1d: -1.19,
      price_pc_1w: 16.901,
      price_pc_1m: 162.658,
      price_pc_6m: 104.433,
      price_pc_1y: -23.148,
      price_pc_ytd: -23.148,
    },
    6862: {
      id: "6862",
      c: 15.36,
      ma50: 16.707,
      ma100: 17.453,
      ma150: 21.12,
      ma200: 24.975,
      maw30: 21.21,
      rs: 12.0,
      rsd1d: 0.0,
      rsd5d: 2.0,
      rsd10d: 3.0,
      price_5d_h: 15.72,
      price_5d_L: 12.5,
      price_1m_h: 18.9,
      price_1m_l: 10.0,
      price_3m_h: 21.3,
      price_3m_l: 10.0,
      price_6m_h: 30.7,
      price_6m_l: 10.0,
      price_52w_h: 55.2,
      price_52w_l: 10.0,
      price_pc_1d: -0.389,
      price_pc_1w: 6.077,
      price_pc_1m: -14.286,
      price_pc_6m: -48.37,
      price_pc_1y: -71.868,
      price_pc_ytd: -71.868,
    },
    6865: {
      id: "6865",
      c: 30.3,
      ma50: 32.238,
      ma100: 33.886,
      ma150: 35.251,
      ma200: 34.545,
      maw30: 35.325,
      rs: 59.0,
      rsd1d: -14.0,
      rsd5d: -22.0,
      rsd10d: -30.0,
      price_5d_h: 32.0,
      price_5d_L: 29.0,
      price_1m_h: 38.0,
      price_1m_l: 29.0,
      price_3m_h: 40.85,
      price_3m_l: 28.4,
      price_6m_h: 43.3,
      price_6m_l: 28.4,
      price_52w_h: 45.4,
      price_52w_l: 17.2,
      price_pc_1d: -4.265,
      price_pc_1w: -4.717,
      price_pc_1m: -5.901,
      price_pc_6m: -17.551,
      price_pc_1y: 18.824,
      price_pc_ytd: 18.824,
    },
    6866: {
      id: "6866",
      c: 0.4,
      ma50: 0.39,
      ma100: 0.377,
      ma150: 0.377,
      ma200: 0.379,
      maw30: 0.382,
      rs: 82.0,
      rsd1d: 1.0,
      rsd5d: -1.0,
      rsd10d: -1.0,
      price_5d_h: 0.405,
      price_5d_L: 0.37,
      price_1m_h: 0.51,
      price_1m_l: 0.34,
      price_3m_h: 0.51,
      price_3m_l: 0.34,
      price_6m_h: 0.51,
      price_6m_l: 0.31,
      price_52w_h: 0.51,
      price_52w_l: 0.31,
      price_pc_1d: 2.564,
      price_pc_1w: 0.0,
      price_pc_1m: 1.266,
      price_pc_6m: 8.108,
      price_pc_1y: 2.564,
      price_pc_ytd: 2.564,
    },
    6868: {
      id: "6868",
      c: 5.69,
      ma50: 5.463,
      ma100: 5.477,
      ma150: 5.406,
      ma200: 5.383,
      maw30: 5.402,
      rs: 81.0,
      rsd1d: -2.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 5.71,
      price_5d_L: 5.5,
      price_1m_h: 5.71,
      price_1m_l: 5.21,
      price_3m_h: 5.71,
      price_3m_l: 5.0,
      price_6m_h: 5.75,
      price_6m_l: 5.0,
      price_52w_h: 5.75,
      price_52w_l: 4.53,
      price_pc_1d: 0.0,
      price_pc_1w: 1.246,
      price_pc_1m: 2.708,
      price_pc_6m: 11.35,
      price_pc_1y: 3.832,
      price_pc_ytd: 3.832,
    },
    6869: {
      id: "6869",
      c: 10.8,
      ma50: 11.375,
      ma100: 12.071,
      ma150: 12.236,
      ma200: 11.711,
      maw30: 12.277,
      rs: 66.0,
      rsd1d: 1.0,
      rsd5d: 7.0,
      rsd10d: 4.0,
      price_5d_h: 11.02,
      price_5d_L: 10.04,
      price_1m_h: 12.36,
      price_1m_l: 9.36,
      price_3m_h: 13.56,
      price_3m_l: 9.36,
      price_6m_h: 15.26,
      price_6m_l: 9.36,
      price_52w_h: 15.88,
      price_52w_l: 9.0,
      price_pc_1d: -1.46,
      price_pc_1w: 0.559,
      price_pc_1m: -12.195,
      price_pc_6m: -9.85,
      price_pc_1y: 8.981,
      price_pc_ytd: 8.981,
    },
    6878: {
      id: "6878",
      c: 1.96,
      ma50: 2.213,
      ma100: 2.025,
      ma150: 2.062,
      ma200: 2.097,
      maw30: 2.01,
      rs: 90.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: 2.0,
      price_5d_h: 2.08,
      price_5d_L: 1.79,
      price_1m_h: 2.69,
      price_1m_l: 1.55,
      price_3m_h: 2.92,
      price_3m_l: 1.55,
      price_6m_h: 2.92,
      price_6m_l: 0.7,
      price_52w_h: 3.84,
      price_52w_l: 0.7,
      price_pc_1d: 0.513,
      price_pc_1w: -4.39,
      price_pc_1m: -18.672,
      price_pc_6m: 8.287,
      price_pc_1y: 108.511,
      price_pc_ytd: 108.511,
    },
    6881: {
      id: "6881",
      c: 4.39,
      ma50: 4.499,
      ma100: 4.471,
      ma150: 4.472,
      ma200: 4.436,
      maw30: 4.492,
      rs: 67.0,
      rsd1d: 1.0,
      rsd5d: -2.0,
      rsd10d: 0.0,
      price_5d_h: 4.51,
      price_5d_L: 4.28,
      price_1m_h: 4.53,
      price_1m_l: 3.79,
      price_3m_h: 4.88,
      price_3m_l: 3.79,
      price_6m_h: 4.88,
      price_6m_l: 3.79,
      price_52w_h: 5.08,
      price_52w_l: 3.79,
      price_pc_1d: -0.227,
      price_pc_1w: -0.454,
      price_pc_1m: -1.79,
      price_pc_6m: -3.091,
      price_pc_1y: -9.856,
      price_pc_ytd: -9.856,
    },
    6882: {
      id: "6882",
      c: 0.495,
      ma50: 0.427,
      ma100: 0.425,
      ma150: 0.427,
      ma200: 0.448,
      maw30: 0.427,
      rs: 83.0,
      rsd1d: 6.0,
      rsd5d: 6.0,
      rsd10d: 26.0,
      price_5d_h: 0.495,
      price_5d_L: 0.44,
      price_1m_h: 0.495,
      price_1m_l: 0.38,
      price_3m_h: 0.495,
      price_3m_l: 0.375,
      price_6m_h: 0.495,
      price_6m_l: 0.375,
      price_52w_h: 0.65,
      price_52w_l: 0.375,
      price_pc_1d: 5.319,
      price_pc_1w: 5.319,
      price_pc_1m: 23.75,
      price_pc_6m: 19.277,
      price_pc_1y: -21.429,
      price_pc_ytd: -21.429,
    },
    6885: {
      id: "6885",
      c: 3.82,
      ma50: 3.986,
      ma100: 3.962,
      ma150: 4.131,
      ma200: 4.06,
      maw30: 4.158,
      rs: 66.0,
      rsd1d: 0.0,
      rsd5d: -6.0,
      rsd10d: -7.0,
      price_5d_h: 3.94,
      price_5d_L: 3.78,
      price_1m_h: 3.98,
      price_1m_l: 3.46,
      price_3m_h: 4.41,
      price_3m_l: 3.46,
      price_6m_h: 4.74,
      price_6m_l: 3.46,
      price_52w_h: 5.6,
      price_52w_l: 3.31,
      price_pc_1d: -0.521,
      price_pc_1w: -3.535,
      price_pc_1m: -1.292,
      price_pc_6m: -15.859,
      price_pc_1y: 8.523,
      price_pc_ytd: 8.523,
    },
    6886: {
      id: "6886",
      c: 12.06,
      ma50: 12.879,
      ma100: 12.568,
      ma150: 12.333,
      ma200: 11.987,
      maw30: 12.434,
      rs: 72.0,
      rsd1d: 0.0,
      rsd5d: 2.0,
      rsd10d: -8.0,
      price_5d_h: 12.16,
      price_5d_L: 11.48,
      price_1m_h: 12.92,
      price_1m_l: 10.76,
      price_3m_h: 14.3,
      price_3m_l: 10.76,
      price_6m_h: 14.3,
      price_6m_l: 10.76,
      price_52w_h: 14.3,
      price_52w_l: 10.02,
      price_pc_1d: -0.495,
      price_pc_1w: 1.515,
      price_pc_1m: -5.634,
      price_pc_6m: 0.333,
      price_pc_1y: 0.5,
      price_pc_ytd: 0.5,
    },
    6888: {
      id: "6888",
      c: 0.25,
      ma50: 0.261,
      ma100: 0.275,
      ma150: 0.28,
      ma200: 0.285,
      maw30: 0.279,
      rs: 48.0,
      rsd1d: -5.0,
      rsd5d: 0.0,
      rsd10d: -3.0,
      price_5d_h: 0.265,
      price_5d_L: 0.236,
      price_1m_h: 0.28,
      price_1m_l: 0.219,
      price_3m_h: 0.29,
      price_3m_l: 0.219,
      price_6m_h: 0.345,
      price_6m_l: 0.219,
      price_52w_h: 0.345,
      price_52w_l: 0.219,
      price_pc_1d: -3.846,
      price_pc_1w: -1.961,
      price_pc_1m: -1.961,
      price_pc_6m: -13.793,
      price_pc_1y: -10.714,
      price_pc_ytd: -10.714,
    },
    6889: {
      id: "6889",
      c: 7.3,
      ma50: 7.164,
      ma100: 7.078,
      ma150: 7.234,
      ma200: 7.258,
      maw30: 7.204,
      rs: 78.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: 4.0,
      price_5d_h: 7.41,
      price_5d_L: 7.2,
      price_1m_h: 7.41,
      price_1m_l: 6.91,
      price_3m_h: 7.41,
      price_3m_l: 6.67,
      price_6m_h: 7.67,
      price_6m_l: 6.21,
      price_52w_h: 8.2,
      price_52w_l: 6.21,
      price_pc_1d: 0.137,
      price_pc_1w: 0.69,
      price_pc_1m: 1.53,
      price_pc_6m: 2.384,
      price_pc_1y: -4.575,
      price_pc_ytd: -4.575,
    },
    6890: {
      id: "6890",
      c: 0.6,
      ma50: 0.597,
      ma100: 0.612,
      ma150: 0.626,
      ma200: 0.639,
      maw30: 0.627,
      rs: 63.0,
      rsd1d: 6.0,
      rsd5d: 15.0,
      rsd10d: 8.0,
      price_5d_h: 0.61,
      price_5d_L: 0.55,
      price_1m_h: 0.61,
      price_1m_l: 0.5,
      price_3m_h: 0.63,
      price_3m_l: 0.5,
      price_6m_h: 0.74,
      price_6m_l: 0.5,
      price_52w_h: 1.35,
      price_52w_l: 0.5,
      price_pc_1d: 1.695,
      price_pc_1w: 5.263,
      price_pc_1m: 0.0,
      price_pc_6m: -7.692,
      price_pc_1y: -14.286,
      price_pc_ytd: -14.286,
    },
    6893: {
      id: "6893",
      c: 0.29,
      ma50: 0.308,
      ma100: 0.328,
      ma150: 0.331,
      ma200: 0.338,
      maw30: 0.332,
      rs: 32.0,
      rsd1d: 0.0,
      rsd5d: 2.0,
      rsd10d: 5.0,
      price_5d_h: 0.305,
      price_5d_L: 0.275,
      price_1m_h: 0.33,
      price_1m_l: 0.248,
      price_3m_h: 0.345,
      price_3m_l: 0.248,
      price_6m_h: 0.38,
      price_6m_l: 0.248,
      price_52w_h: 0.75,
      price_52w_l: 0.248,
      price_pc_1d: -1.695,
      price_pc_1w: -1.695,
      price_pc_1m: -7.937,
      price_pc_6m: -15.942,
      price_pc_1y: -41.414,
      price_pc_ytd: -41.414,
    },
    6896: {
      id: "6896",
      c: 1.79,
      ma50: 1.585,
      ma100: 1.784,
      ma150: 2.069,
      ma200: 2.04,
      maw30: 2.052,
      rs: 77.0,
      rsd1d: 15.0,
      rsd5d: 26.0,
      rsd10d: 19.0,
      price_5d_h: 1.82,
      price_5d_L: 1.57,
      price_1m_h: 1.82,
      price_1m_l: 1.35,
      price_3m_h: 1.82,
      price_3m_l: 1.35,
      price_6m_h: 2.74,
      price_6m_l: 1.35,
      price_52w_h: 2.74,
      price_52w_l: 1.35,
      price_pc_1d: 7.186,
      price_pc_1w: 11.18,
      price_pc_1m: 9.146,
      price_pc_6m: -31.679,
      price_pc_1y: 13.291,
      price_pc_ytd: 13.291,
    },
    6898: {
      id: "6898",
      c: 0.465,
      ma50: 0.474,
      ma100: 0.434,
      ma150: 0.412,
      ma200: 0.427,
      maw30: 0.414,
      rs: 78.0,
      rsd1d: -6.0,
      rsd5d: -9.0,
      rsd10d: -13.0,
      price_5d_h: 0.49,
      price_5d_L: 0.45,
      price_1m_h: 0.49,
      price_1m_l: 0.425,
      price_3m_h: 0.51,
      price_3m_l: 0.4,
      price_6m_h: 0.51,
      price_6m_l: 0.34,
      price_52w_h: 0.58,
      price_52w_l: 0.33,
      price_pc_1d: -1.064,
      price_pc_1w: -3.125,
      price_pc_1m: -3.125,
      price_pc_6m: 24.0,
      price_pc_1y: -7.0,
      price_pc_ytd: -7.0,
    },
    6899: {
      id: "6899",
      c: 0.2,
      ma50: 0.295,
      ma100: 0.381,
      ma150: 0.401,
      ma200: 0.449,
      maw30: 0.398,
      rs: 5.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: -1.0,
      price_5d_h: 0.213,
      price_5d_L: 0.19,
      price_1m_h: 0.305,
      price_1m_l: 0.19,
      price_3m_h: 0.455,
      price_3m_l: 0.19,
      price_6m_h: 0.6,
      price_6m_l: 0.19,
      price_52w_h: 1.09,
      price_52w_l: 0.19,
      price_pc_1d: 0.0,
      price_pc_1w: -2.439,
      price_pc_1m: -34.426,
      price_pc_6m: -45.205,
      price_pc_1y: -60.0,
      price_pc_ytd: -60.0,
    },
    6900: {
      id: "6900",
      c: 1.79,
      ma50: 2.024,
      ma100: 2.154,
      ma150: 2.27,
      ma200: 2.342,
      maw30: 2.276,
      rs: 30.0,
      rsd1d: -7.0,
      rsd5d: -11.0,
      rsd10d: -14.0,
      price_5d_h: 2.05,
      price_5d_L: 1.78,
      price_1m_h: 2.05,
      price_1m_l: 1.56,
      price_3m_h: 2.84,
      price_3m_l: 1.56,
      price_6m_h: 2.84,
      price_6m_l: 1.56,
      price_52w_h: 2.84,
      price_52w_l: 1.56,
      price_pc_1d: -4.787,
      price_pc_1w: -8.673,
      price_pc_1m: -5.789,
      price_pc_6m: -30.078,
      price_pc_1y: -27.823,
      price_pc_ytd: -27.823,
    },
    6908: {
      id: "6908",
      c: 4.68,
      ma50: 5.799,
      ma100: 6.301,
      ma150: 6.639,
      ma200: 6.935,
      maw30: 6.704,
      rs: 17.0,
      rsd1d: 1.0,
      rsd5d: -4.0,
      rsd10d: -19.0,
      price_5d_h: 5.38,
      price_5d_L: 4.47,
      price_1m_h: 6.2,
      price_1m_l: 4.47,
      price_3m_h: 7.27,
      price_3m_l: 4.47,
      price_6m_h: 7.75,
      price_6m_l: 4.47,
      price_52w_h: 8.99,
      price_52w_l: 4.47,
      price_pc_1d: 0.0,
      price_pc_1w: -6.4,
      price_pc_1m: -23.404,
      price_pc_6m: -34.177,
      price_pc_1y: -32.855,
      price_pc_ytd: -32.855,
    },
    6913: {
      id: "6913",
      c: 1.05,
      ma50: 1.033,
      ma100: 1.117,
      ma150: 1.176,
      ma200: null,
      maw30: 1.181,
      rs: 46.0,
      rsd1d: -9.0,
      rsd5d: -3.0,
      rsd10d: 8.0,
      price_5d_h: 1.11,
      price_5d_L: 0.98,
      price_1m_h: 1.11,
      price_1m_l: 0.93,
      price_3m_h: 1.18,
      price_3m_l: 0.93,
      price_6m_h: 1.44,
      price_6m_l: 0.93,
      price_52w_h: 1.59,
      price_52w_l: 0.93,
      price_pc_1d: -4.545,
      price_pc_1w: -2.778,
      price_pc_1m: 8.247,
      price_pc_6m: -18.605,
      price_pc_1y: -20.455,
      price_pc_ytd: -20.455,
    },
    6918: {
      id: "6918",
      c: 0.86,
      ma50: 0.885,
      ma100: 1.011,
      ma150: 1.126,
      ma200: 1.226,
      maw30: 1.117,
      rs: 19.0,
      rsd1d: 4.0,
      rsd5d: 2.0,
      rsd10d: 6.0,
      price_5d_h: 0.9,
      price_5d_L: 0.83,
      price_1m_h: 0.9,
      price_1m_l: 0.7,
      price_3m_h: 1.07,
      price_3m_l: 0.7,
      price_6m_h: 1.54,
      price_6m_l: 0.7,
      price_52w_h: 2.46,
      price_52w_l: 0.7,
      price_pc_1d: 0.0,
      price_pc_1w: -3.371,
      price_pc_1m: 6.173,
      price_pc_6m: -34.351,
      price_pc_1y: -62.771,
      price_pc_ytd: -62.771,
    },
    6919: {
      id: "6919",
      c: 8.3,
      ma50: 8.489,
      ma100: 8.425,
      ma150: 8.78,
      ma200: 9.908,
      maw30: 8.797,
      rs: 25.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: -1.0,
      price_5d_h: 8.85,
      price_5d_L: 7.83,
      price_1m_h: 8.85,
      price_1m_l: 7.83,
      price_3m_h: 9.0,
      price_3m_l: 7.83,
      price_6m_h: 9.92,
      price_6m_l: 7.61,
      price_52w_h: 28.8,
      price_52w_l: 7.61,
      price_pc_1d: -1.542,
      price_pc_1w: -5.251,
      price_pc_1m: 6.003,
      price_pc_6m: -11.325,
      price_pc_1y: -69.485,
      price_pc_ytd: -69.485,
    },
    6928: {
      id: "6928",
      c: 2.8,
      ma50: 3.292,
      ma100: 3.509,
      ma150: 3.652,
      ma200: 3.657,
      maw30: 3.639,
      rs: 88.0,
      rsd1d: -4.0,
      rsd5d: -2.0,
      rsd10d: -4.0,
      price_5d_h: 3.1,
      price_5d_L: 2.77,
      price_1m_h: 3.45,
      price_1m_l: 2.77,
      price_3m_h: 4.0,
      price_3m_l: 2.77,
      price_6m_h: 4.01,
      price_6m_l: 2.77,
      price_52w_h: 5.8,
      price_52w_l: 0.74,
      price_pc_1d: -6.667,
      price_pc_1w: -7.285,
      price_pc_1m: -14.634,
      price_pc_6m: -21.348,
      price_pc_1y: 182.828,
      price_pc_ytd: 182.828,
    },
    6933: {
      id: "6933",
      c: 6.67,
      ma50: 5.314,
      ma100: 4.826,
      ma150: 3.903,
      ma200: 3.347,
      maw30: 3.937,
      rs: 98.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: 1.0,
      price_5d_h: 6.67,
      price_5d_L: 4.45,
      price_1m_h: 6.67,
      price_1m_l: 4.45,
      price_3m_h: 6.67,
      price_3m_l: 4.45,
      price_6m_h: 7.88,
      price_6m_l: 1.72,
      price_52w_h: 7.88,
      price_52w_l: 0.78,
      price_pc_1d: 8.987,
      price_pc_1w: 30.784,
      price_pc_1m: 9.524,
      price_pc_6m: 270.556,
      price_pc_1y: 214.623,
      price_pc_ytd: 214.623,
    },
    6939: {
      id: "6939",
      c: 1.26,
      ma50: 1.193,
      ma100: 1.231,
      ma150: 1.295,
      ma200: 1.371,
      maw30: 1.285,
      rs: 54.0,
      rsd1d: 5.0,
      rsd5d: 3.0,
      rsd10d: 0.0,
      price_5d_h: 1.35,
      price_5d_L: 1.18,
      price_1m_h: 1.4,
      price_1m_l: 1.11,
      price_3m_h: 1.4,
      price_3m_l: 1.04,
      price_6m_h: 1.44,
      price_6m_l: 1.04,
      price_52w_h: 2.0,
      price_52w_l: 1.04,
      price_pc_1d: -4.545,
      price_pc_1w: -1.563,
      price_pc_1m: 7.692,
      price_pc_6m: -9.353,
      price_pc_1y: -5.263,
      price_pc_ytd: -5.263,
    },
    6958: {
      id: "6958",
      c: 0.96,
      ma50: 2.07,
      ma100: 3.294,
      ma150: 3.824,
      ma200: 4.107,
      maw30: 3.849,
      rs: 1.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 1.11,
      price_5d_L: 0.84,
      price_1m_h: 1.34,
      price_1m_l: 0.84,
      price_3m_h: 4.7,
      price_3m_l: 0.52,
      price_6m_h: 5.23,
      price_6m_l: 0.52,
      price_52w_h: 5.63,
      price_52w_l: 0.52,
      price_pc_1d: 1.053,
      price_pc_1w: -11.927,
      price_pc_1m: -25.581,
      price_pc_6m: -80.8,
      price_pc_1y: -81.714,
      price_pc_ytd: -81.714,
    },
    6968: {
      id: "6968",
      c: 4.62,
      ma50: 4.435,
      ma100: 4.34,
      ma150: 4.337,
      ma200: 4.367,
      maw30: 4.359,
      rs: 79.0,
      rsd1d: 3.0,
      rsd5d: 14.0,
      rsd10d: 8.0,
      price_5d_h: 4.65,
      price_5d_L: 4.06,
      price_1m_h: 4.77,
      price_1m_l: 3.96,
      price_3m_h: 5.0,
      price_3m_l: 3.96,
      price_6m_h: 5.0,
      price_6m_l: 3.94,
      price_52w_h: 5.0,
      price_52w_l: 3.94,
      price_pc_1d: 4.289,
      price_pc_1w: 10.263,
      price_pc_1m: -1.702,
      price_pc_6m: 8.197,
      price_pc_1y: 2.212,
      price_pc_ytd: 2.212,
    },
    6969: {
      id: "6969",
      c: 18.8,
      ma50: 28.107,
      ma100: 34.342,
      ma150: 35.382,
      ma200: 36.975,
      maw30: 35.633,
      rs: 6.0,
      rsd1d: -2.0,
      rsd5d: -1.0,
      rsd10d: 1.0,
      price_5d_h: 21.8,
      price_5d_L: 18.32,
      price_1m_h: 29.2,
      price_1m_l: 14.22,
      price_3m_h: 39.9,
      price_3m_l: 14.22,
      price_6m_h: 53.5,
      price_6m_l: 14.22,
      price_52w_h: 62.3,
      price_52w_l: 14.22,
      price_pc_1d: -13.761,
      price_pc_1w: -9.615,
      price_pc_1m: -34.722,
      price_pc_6m: -48.209,
      price_pc_1y: -63.173,
      price_pc_ytd: -63.173,
    },
    6978: {
      id: "6978",
      c: 5.57,
      ma50: 6.531,
      ma100: 10.758,
      ma150: 12.512,
      ma200: 13.815,
      maw30: 12.488,
      rs: 2.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: -1.0,
      price_5d_h: 6.24,
      price_5d_L: 5.11,
      price_1m_h: 6.61,
      price_1m_l: 4.42,
      price_3m_h: 17.5,
      price_3m_l: 4.42,
      price_6m_h: 17.5,
      price_6m_l: 4.42,
      price_52w_h: 22.45,
      price_52w_l: 4.42,
      price_pc_1d: -1.416,
      price_pc_1w: -10.737,
      price_pc_1m: -16.617,
      price_pc_6m: -64.295,
      price_pc_1y: -66.845,
      price_pc_ytd: -66.845,
    },
    6988: {
      id: "6988",
      c: 2.29,
      ma50: 2.227,
      ma100: 2.26,
      ma150: 2.272,
      ma200: 2.401,
      maw30: 2.282,
      rs: 40.0,
      rsd1d: -1.0,
      rsd5d: -10.0,
      rsd10d: 6.0,
      price_5d_h: 2.29,
      price_5d_L: 2.11,
      price_1m_h: 2.29,
      price_1m_l: 1.69,
      price_3m_h: 2.72,
      price_3m_l: 1.69,
      price_6m_h: 2.72,
      price_6m_l: 1.69,
      price_52w_h: 3.5,
      price_52w_l: 1.69,
      price_pc_1d: 2.691,
      price_pc_1w: 3.62,
      price_pc_1m: 0.439,
      price_pc_6m: -6.911,
      price_pc_1y: -33.041,
      price_pc_ytd: -33.041,
    },
    6989: {
      id: "6989",
      c: 3.98,
      ma50: 4.604,
      ma100: 4.607,
      ma150: 4.941,
      ma200: 5.531,
      maw30: 4.91,
      rs: 19.0,
      rsd1d: -2.0,
      rsd5d: -2.0,
      rsd10d: -4.0,
      price_5d_h: 4.15,
      price_5d_L: 3.66,
      price_1m_h: 4.87,
      price_1m_l: 2.7,
      price_3m_h: 5.99,
      price_3m_l: 2.7,
      price_6m_h: 6.32,
      price_6m_l: 2.7,
      price_52w_h: 11.3,
      price_52w_l: 2.7,
      price_pc_1d: -2.211,
      price_pc_1w: -1.241,
      price_pc_1m: -15.139,
      price_pc_6m: -23.608,
      price_pc_1y: -57.524,
      price_pc_ytd: -57.524,
    },
    6993: {
      id: "6993",
      c: 5.6,
      ma50: 5.991,
      ma100: 6.64,
      ma150: 6.726,
      ma200: 7.189,
      maw30: 6.76,
      rs: 19.0,
      rsd1d: 3.0,
      rsd5d: 7.0,
      rsd10d: 5.0,
      price_5d_h: 5.69,
      price_5d_L: 4.77,
      price_1m_h: 6.14,
      price_1m_l: 4.46,
      price_3m_h: 7.54,
      price_3m_l: 4.46,
      price_6m_h: 8.22,
      price_6m_l: 4.46,
      price_52w_h: 11.9,
      price_52w_l: 4.46,
      price_pc_1d: 1.266,
      price_pc_1w: 6.464,
      price_pc_1m: -8.197,
      price_pc_6m: -17.768,
      price_pc_1y: -50.877,
      price_pc_ytd: -50.877,
    },
    6996: {
      id: "6996",
      c: 6.66,
      ma50: 6.379,
      ma100: 8.451,
      ma150: 9.542,
      ma200: 11.237,
      maw30: 9.485,
      rs: 11.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 4.0,
      price_5d_h: 7.6,
      price_5d_L: 6.44,
      price_1m_h: 7.6,
      price_1m_l: 5.17,
      price_3m_h: 10.0,
      price_3m_l: 5.17,
      price_6m_h: 13.06,
      price_6m_l: 5.17,
      price_52w_h: 20.3,
      price_52w_l: 5.17,
      price_pc_1d: -6.329,
      price_pc_1w: -6.723,
      price_pc_1m: 13.652,
      price_pc_6m: -35.838,
      price_pc_1y: -59.041,
      price_pc_ytd: -59.041,
    },
    6998: {
      id: "6998",
      c: 5.0,
      ma50: 5.238,
      ma100: 6.532,
      ma150: 8.0,
      ma200: 9.686,
      maw30: 8.02,
      rs: 6.0,
      rsd1d: 3.0,
      rsd5d: 3.0,
      rsd10d: 2.0,
      price_5d_h: 5.0,
      price_5d_L: 3.98,
      price_1m_h: 6.31,
      price_1m_l: 3.52,
      price_3m_h: 7.23,
      price_3m_l: 3.52,
      price_6m_h: 14.18,
      price_6m_l: 3.52,
      price_52w_h: 19.0,
      price_52w_l: 3.52,
      price_pc_1d: 13.122,
      price_pc_1w: 12.36,
      price_pc_1m: -8.257,
      price_pc_6m: -65.035,
      price_pc_1y: -69.952,
      price_pc_ytd: -69.952,
    },
    6999: {
      id: "6999",
      c: 4.07,
      ma50: 5.062,
      ma100: 4.725,
      ma150: 5.155,
      ma200: 5.412,
      maw30: 5.167,
      rs: 27.0,
      rsd1d: -9.0,
      rsd5d: -14.0,
      rsd10d: -24.0,
      price_5d_h: 4.5,
      price_5d_L: 3.47,
      price_1m_h: 4.99,
      price_1m_l: 3.47,
      price_3m_h: 5.98,
      price_3m_l: 3.47,
      price_6m_h: 7.3,
      price_6m_l: 3.0,
      price_52w_h: 9.23,
      price_52w_l: 3.0,
      price_pc_1d: -7.5,
      price_pc_1w: -8.539,
      price_pc_1m: -14.854,
      price_pc_6m: -42.433,
      price_pc_1y: -33.497,
      price_pc_ytd: -33.497,
    },
    7200: {
      id: "7200",
      c: 5.91,
      ma50: 6.499,
      ma100: 6.856,
      ma150: 7.243,
      ma200: 7.759,
      maw30: 7.264,
      rs: 26.0,
      rsd1d: 0.0,
      rsd5d: 2.0,
      rsd10d: 3.0,
      price_5d_h: 6.085,
      price_5d_L: 5.465,
      price_1m_h: 6.52,
      price_1m_l: 4.104,
      price_3m_h: 7.78,
      price_3m_l: 4.104,
      price_6m_h: 8.64,
      price_6m_l: 4.104,
      price_52w_h: 10.9,
      price_52w_l: 4.104,
      price_pc_1d: -1.5,
      price_pc_1w: 1.112,
      price_pc_1m: -7.512,
      price_pc_6m: -21.929,
      price_pc_1y: -42.899,
      price_pc_ytd: -42.899,
    },
    7226: {
      id: "7226",
      c: 1.814,
      ma50: 2.414,
      ma100: 2.957,
      ma150: 3.353,
      ma200: 3.9,
      maw30: 3.362,
      rs: 5.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: 1.0,
      price_5d_h: 1.934,
      price_5d_L: 1.608,
      price_1m_h: 2.556,
      price_1m_l: 1.1,
      price_3m_h: 3.404,
      price_3m_l: 1.1,
      price_6m_h: 4.612,
      price_6m_l: 1.1,
      price_52w_h: 8.19,
      price_52w_l: 1.1,
      price_pc_1d: -2.891,
      price_pc_1w: -2.052,
      price_pc_1m: -26.914,
      price_pc_6m: -51.445,
      price_pc_1y: -77.211,
      price_pc_ytd: -77.211,
    },
    7230: {
      id: "7230",
      c: 3.522,
      ma50: 4.096,
      ma100: 4.441,
      ma150: 4.755,
      ma200: 5.196,
      maw30: 4.774,
      rs: 16.0,
      rsd1d: 1.0,
      rsd5d: 2.0,
      rsd10d: 2.0,
      price_5d_h: 3.622,
      price_5d_L: 3.216,
      price_1m_h: 4.212,
      price_1m_l: 2.346,
      price_3m_h: 5.02,
      price_3m_l: 2.346,
      price_6m_h: 5.8,
      price_6m_l: 2.346,
      price_52w_h: 8.265,
      price_52w_l: 2.346,
      price_pc_1d: -1.675,
      price_pc_1w: 0.285,
      price_pc_1m: -15.051,
      price_pc_6m: -30.669,
      price_pc_1y: -57.77,
      price_pc_ytd: -57.77,
    },
    7231: {
      id: "7231",
      c: 6.165,
      ma50: 6.752,
      ma100: 7.111,
      ma150: 7.504,
      ma200: 8.035,
      maw30: 7.528,
      rs: 26.0,
      rsd1d: 0.0,
      rsd5d: 2.0,
      rsd10d: 3.0,
      price_5d_h: 6.34,
      price_5d_L: 5.715,
      price_1m_h: 6.89,
      price_1m_l: 4.306,
      price_3m_h: 8.025,
      price_3m_l: 4.306,
      price_6m_h: 8.905,
      price_6m_l: 4.306,
      price_52w_h: 11.26,
      price_52w_l: 4.306,
      price_pc_1d: -1.518,
      price_pc_1w: 1.148,
      price_pc_1m: -7.363,
      price_pc_6m: -21.264,
      price_pc_1y: -42.811,
      price_pc_ytd: -42.811,
    },
    7232: {
      id: "7232",
      c: 9.14,
      ma50: 9.159,
      ma100: 9.251,
      ma150: 8.985,
      ma200: 8.869,
      maw30: 9.002,
      rs: 76.0,
      rsd1d: -1.0,
      rsd5d: -3.0,
      rsd10d: -5.0,
      price_5d_h: 9.185,
      price_5d_L: 8.89,
      price_1m_h: 9.48,
      price_1m_l: 8.3,
      price_3m_h: 10.0,
      price_3m_l: 8.3,
      price_6m_h: 10.0,
      price_6m_l: 7.62,
      price_52w_h: 10.0,
      price_52w_l: 7.62,
      price_pc_1d: -0.436,
      price_pc_1w: 0.11,
      price_pc_1m: 0.0,
      price_pc_6m: 8.939,
      price_pc_1y: 12.492,
      price_pc_ytd: 12.492,
    },
    7233: {
      id: "7233",
      c: 7.14,
      ma50: 8.119,
      ma100: 8.946,
      ma150: 9.179,
      ma200: 9.43,
      maw30: 9.233,
      rs: 23.0,
      rsd1d: 2.0,
      rsd5d: 1.0,
      rsd10d: -3.0,
      price_5d_h: 7.24,
      price_5d_L: 6.59,
      price_1m_h: 8.67,
      price_1m_l: 6.115,
      price_3m_h: 10.01,
      price_3m_l: 6.115,
      price_6m_h: 10.81,
      price_6m_l: 6.115,
      price_52w_h: 11.95,
      price_52w_l: 6.115,
      price_pc_1d: -1.108,
      price_pc_1w: -0.418,
      price_pc_1m: -16.295,
      price_pc_6m: -25.353,
      price_pc_1y: -31.74,
      price_pc_ytd: -31.74,
    },
    7248: {
      id: "7248",
      c: 4.216,
      ma50: 4.7,
      ma100: 5.138,
      ma150: 5.246,
      ma200: 5.458,
      maw30: 5.298,
      rs: 22.0,
      rsd1d: 3.0,
      rsd5d: 2.0,
      rsd10d: 0.0,
      price_5d_h: 4.236,
      price_5d_L: 3.84,
      price_1m_h: 5.03,
      price_1m_l: 3.4,
      price_3m_h: 5.64,
      price_3m_l: 3.4,
      price_6m_h: 6.35,
      price_6m_l: 3.4,
      price_52w_h: 7.865,
      price_52w_l: 3.4,
      price_pc_1d: -0.237,
      price_pc_1w: 0.477,
      price_pc_1m: -13.677,
      price_pc_6m: -23.066,
      price_pc_1y: -39.338,
      price_pc_ytd: -39.338,
    },
    7261: {
      id: "7261",
      c: 24.34,
      ma50: 21.835,
      ma100: 25.084,
      ma150: 25.353,
      ma200: 25.042,
      maw30: 25.325,
      rs: 75.0,
      rsd1d: 0.0,
      rsd5d: 18.0,
      rsd10d: 24.0,
      price_5d_h: 24.6,
      price_5d_L: 22.8,
      price_1m_h: 24.6,
      price_1m_l: 17.95,
      price_3m_h: 29.6,
      price_3m_l: 17.95,
      price_6m_h: 30.16,
      price_6m_l: 17.95,
      price_52w_h: 30.16,
      price_52w_l: 17.95,
      price_pc_1d: -0.246,
      price_pc_1w: 9.05,
      price_pc_1m: 16.126,
      price_pc_6m: 0.247,
      price_pc_1y: 24.184,
      price_pc_ytd: 24.184,
    },
    7266: {
      id: "7266",
      c: 19.03,
      ma50: 17.041,
      ma100: 19.487,
      ma150: 19.692,
      ma200: 19.453,
      maw30: 19.672,
      rs: 76.0,
      rsd1d: 0.0,
      rsd5d: 18.0,
      rsd10d: 24.0,
      price_5d_h: 19.24,
      price_5d_L: 17.84,
      price_1m_h: 19.24,
      price_1m_l: 14.07,
      price_3m_h: 22.84,
      price_3m_l: 14.07,
      price_6m_h: 23.36,
      price_6m_l: 14.07,
      price_52w_h: 23.36,
      price_52w_l: 14.07,
      price_pc_1d: -0.21,
      price_pc_1w: 8.93,
      price_pc_1m: 16.107,
      price_pc_6m: 0.528,
      price_pc_1y: 24.055,
      price_pc_ytd: 24.055,
    },
    7272: {
      id: "7272",
      c: 8.69,
      ma50: 9.755,
      ma100: 10.71,
      ma150: 10.899,
      ma200: 11.123,
      maw30: 10.976,
      rs: 27.0,
      rsd1d: 3.0,
      rsd5d: 2.0,
      rsd10d: -1.0,
      price_5d_h: 8.635,
      price_5d_L: 8.13,
      price_1m_h: 10.59,
      price_1m_l: 7.34,
      price_3m_h: 11.89,
      price_3m_l: 7.34,
      price_6m_h: 12.9,
      price_6m_l: 7.34,
      price_52w_h: 13.82,
      price_52w_l: 7.34,
      price_pc_1d: -0.515,
      price_pc_1w: 0.0,
      price_pc_1m: -16.039,
      price_pc_6m: -22.063,
      price_pc_1y: -25.663,
      price_pc_ytd: -25.663,
    },
    7288: {
      id: "7288",
      c: 3.516,
      ma50: 4.095,
      ma100: 4.434,
      ma150: 4.74,
      ma200: 5.176,
      maw30: 4.761,
      rs: 16.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: 2.0,
      price_5d_h: 3.63,
      price_5d_L: 3.206,
      price_1m_h: 4.25,
      price_1m_l: 2.324,
      price_3m_h: 5.04,
      price_3m_l: 2.324,
      price_6m_h: 5.835,
      price_6m_l: 2.324,
      price_52w_h: 8.35,
      price_52w_l: 2.324,
      price_pc_1d: -1.733,
      price_pc_1w: 0.171,
      price_pc_1m: -15.195,
      price_pc_6m: -30.307,
      price_pc_1y: -57.408,
      price_pc_ytd: -57.408,
    },
    7299: {
      id: "7299",
      c: 8.59,
      ma50: 8.322,
      ma100: 7.983,
      ma150: 7.81,
      ma200: 7.771,
      maw30: 7.789,
      rs: 89.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -1.0,
      price_5d_h: 8.9,
      price_5d_L: 8.45,
      price_1m_h: 9.86,
      price_1m_l: 8.36,
      price_3m_h: 9.86,
      price_3m_l: 7.4,
      price_6m_h: 9.86,
      price_6m_l: 7.15,
      price_52w_h: 9.86,
      price_52w_l: 7.0,
      price_pc_1d: 0.999,
      price_pc_1w: -1.378,
      price_pc_1m: 2.874,
      price_pc_6m: 21.758,
      price_pc_1y: 20.901,
      price_pc_ytd: 20.901,
    },
    7300: {
      id: "7300",
      c: 5.305,
      ma50: 5.284,
      ma100: 5.204,
      ma150: 5.113,
      ma200: 5.003,
      maw30: 5.101,
      rs: 84.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -5.0,
      price_5d_h: 5.525,
      price_5d_L: 5.23,
      price_1m_h: 6.52,
      price_1m_l: 5.23,
      price_3m_h: 6.52,
      price_3m_l: 4.866,
      price_6m_h: 6.52,
      price_6m_l: 4.724,
      price_52w_h: 6.52,
      price_52w_l: 4.35,
      price_pc_1d: 0.76,
      price_pc_1w: -0.562,
      price_pc_1m: -0.282,
      price_pc_6m: 4.635,
      price_pc_1y: 17.837,
      price_pc_ytd: 17.837,
    },
    7322: {
      id: "7322",
      c: 5.835,
      ma50: 6.199,
      ma100: 6.051,
      ma150: 6.102,
      ma200: 6.183,
      maw30: 6.096,
      rs: 62.0,
      rsd1d: 3.0,
      rsd5d: -1.0,
      rsd10d: -11.0,
      price_5d_h: 5.96,
      price_5d_L: 5.775,
      price_1m_h: 6.585,
      price_1m_l: 5.775,
      price_3m_h: 6.62,
      price_3m_l: 5.745,
      price_6m_h: 6.62,
      price_6m_l: 5.745,
      price_52w_h: 7.115,
      price_52w_l: 5.745,
      price_pc_1d: 0.258,
      price_pc_1w: -3.234,
      price_pc_1m: -8.11,
      price_pc_6m: -7.965,
      price_pc_1y: -17.99,
      price_pc_ytd: -17.99,
    },
    7331: {
      id: "7331",
      c: 3.58,
      ma50: 3.847,
      ma100: 3.659,
      ma150: 3.668,
      ma200: 3.717,
      maw30: 3.666,
      rs: 66.0,
      rsd1d: 2.0,
      rsd5d: -6.0,
      rsd10d: -16.0,
      price_5d_h: 3.7,
      price_5d_L: 3.558,
      price_1m_h: 4.2,
      price_1m_l: 3.558,
      price_3m_h: 4.2,
      price_3m_l: 3.384,
      price_6m_h: 4.2,
      price_6m_l: 3.372,
      price_52w_h: 4.424,
      price_52w_l: 3.372,
      price_pc_1d: 0.168,
      price_pc_1w: -4.38,
      price_pc_1m: -8.58,
      price_pc_6m: -5.938,
      price_pc_1y: -18.153,
      price_pc_ytd: -18.153,
    },
    7332: {
      id: "7332",
      c: 6.78,
      ma50: 6.824,
      ma100: 6.831,
      ma150: 6.985,
      ma200: 7.082,
      maw30: 6.97,
      rs: 68.0,
      rsd1d: 2.0,
      rsd5d: 5.0,
      rsd10d: -2.0,
      price_5d_h: 6.835,
      price_5d_L: 6.775,
      price_1m_h: 7.27,
      price_1m_l: 6.735,
      price_3m_h: 7.27,
      price_3m_l: 6.545,
      price_6m_h: 7.66,
      price_6m_l: 6.545,
      price_52w_h: 14.5,
      price_52w_l: 6.545,
      price_pc_1d: 0.074,
      price_pc_1w: -0.074,
      price_pc_1m: -1.022,
      price_pc_6m: -7.755,
      price_pc_1y: -10.789,
      price_pc_ytd: -10.789,
    },
    7333: {
      id: "7333",
      c: 7.87,
      ma50: 7.529,
      ma100: 7.218,
      ma150: 7.135,
      ma200: 7.056,
      maw30: 7.111,
      rs: 88.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -2.0,
      price_5d_h: 8.115,
      price_5d_L: 7.8,
      price_1m_h: 8.52,
      price_1m_l: 7.315,
      price_3m_h: 8.52,
      price_3m_l: 6.82,
      price_6m_h: 8.52,
      price_6m_l: 6.57,
      price_52w_h: 8.52,
      price_52w_l: 6.47,
      price_pc_1d: 0.833,
      price_pc_1w: 0.511,
      price_pc_1m: 6.495,
      price_pc_6m: 12.832,
      price_pc_1y: 16.939,
      price_pc_ytd: 16.939,
    },
    7336: {
      id: "7336",
      c: 5.34,
      ma50: 5.317,
      ma100: 5.242,
      ma150: 5.162,
      ma200: 5.063,
      maw30: 5.15,
      rs: 83.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -5.0,
      price_5d_h: 5.55,
      price_5d_L: 5.295,
      price_1m_h: 6.53,
      price_1m_l: 5.28,
      price_3m_h: 6.53,
      price_3m_l: 4.908,
      price_6m_h: 6.53,
      price_6m_l: 4.798,
      price_52w_h: 6.53,
      price_52w_l: 4.464,
      price_pc_1d: 0.66,
      price_pc_1w: -0.743,
      price_pc_1m: -0.373,
      price_pc_6m: 3.891,
      price_pc_1y: 15.435,
      price_pc_ytd: 15.435,
    },
    7345: {
      id: "7345",
      c: 4.298,
      ma50: 4.956,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 17.0,
      rsd1d: 4.0,
      rsd5d: 8.0,
      rsd10d: -2.0,
      price_5d_h: 4.396,
      price_5d_L: 3.962,
      price_1m_h: 5.07,
      price_1m_l: 3.79,
      price_3m_h: 6.72,
      price_3m_l: 3.79,
      price_6m_h: 7.845,
      price_6m_l: 3.79,
      price_52w_h: 7.845,
      price_52w_l: 3.79,
      price_pc_1d: 2.921,
      price_pc_1w: 10.036,
      price_pc_1m: -15.808,
      price_pc_6m: -43.111,
      price_pc_1y: -43.111,
      price_pc_ytd: -43.111,
    },
    7348: {
      id: "7348",
      c: 9.295,
      ma50: 8.952,
      ma100: 8.64,
      ma150: 8.605,
      ma200: 8.524,
      maw30: 8.556,
      rs: 87.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: -3.0,
      price_5d_h: 9.69,
      price_5d_L: 9.25,
      price_1m_h: 10.31,
      price_1m_l: 8.645,
      price_3m_h: 10.31,
      price_3m_l: 8.245,
      price_6m_h: 10.31,
      price_6m_l: 7.79,
      price_52w_h: 10.31,
      price_52w_l: 7.44,
      price_pc_1d: 0.378,
      price_pc_1w: -0.161,
      price_pc_1m: 5.866,
      price_pc_6m: 9.032,
      price_pc_1y: 16.845,
      price_pc_ytd: 16.845,
    },
    7362: {
      id: "7362",
      c: 6.38,
      ma50: 6.292,
      ma100: 6.143,
      ma150: 6.021,
      ma200: 5.878,
      maw30: 6.003,
      rs: 87.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -4.0,
      price_5d_h: 6.69,
      price_5d_L: 6.34,
      price_1m_h: 8.085,
      price_1m_l: 6.23,
      price_3m_h: 8.085,
      price_3m_l: 5.725,
      price_6m_h: 8.085,
      price_6m_l: 5.515,
      price_52w_h: 8.085,
      price_52w_l: 4.94,
      price_pc_1d: 0.87,
      price_pc_1w: -0.39,
      price_pc_1m: 1.836,
      price_pc_6m: 7.227,
      price_pc_1y: 29.045,
      price_pc_ytd: 29.045,
    },
    7373: {
      id: "7373",
      c: 6.035,
      ma50: 5.798,
      ma100: 5.626,
      ma150: 5.64,
      ma200: 5.66,
      maw30: 5.614,
      rs: 83.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: -3.0,
      price_5d_h: 6.24,
      price_5d_L: 6.0,
      price_1m_h: 6.57,
      price_1m_l: 5.605,
      price_3m_h: 6.57,
      price_3m_l: 5.345,
      price_6m_h: 6.57,
      price_6m_l: 5.18,
      price_52w_h: 6.57,
      price_52w_l: 5.18,
      price_pc_1d: 0.249,
      price_pc_1w: -0.083,
      price_pc_1m: 6.909,
      price_pc_6m: 6.25,
      price_pc_1y: 0.166,
      price_pc_ytd: 0.166,
    },
    7374: {
      id: "7374",
      c: 7.075,
      ma50: 7.225,
      ma100: 7.417,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 63.0,
      rsd1d: 3.0,
      rsd5d: 5.0,
      rsd10d: 1.0,
      price_5d_h: 7.135,
      price_5d_L: 6.945,
      price_1m_h: 7.185,
      price_1m_l: 6.63,
      price_3m_h: 7.695,
      price_3m_l: 6.63,
      price_6m_h: 7.8,
      price_6m_l: 6.63,
      price_52w_h: 7.8,
      price_52w_l: 6.63,
      price_pc_1d: 0.0,
      price_pc_1w: 0.355,
      price_pc_1m: -1.531,
      price_pc_6m: -9.237,
      price_pc_1y: -9.237,
      price_pc_ytd: -9.237,
    },
    7500: {
      id: "7500",
      c: 5.68,
      ma50: 5.762,
      ma100: 5.622,
      ma150: 5.449,
      ma200: 5.243,
      maw30: 5.421,
      rs: 87.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -5.0,
      price_5d_h: 6.145,
      price_5d_L: 5.5,
      price_1m_h: 8.72,
      price_1m_l: 5.5,
      price_3m_h: 8.72,
      price_3m_l: 4.884,
      price_6m_h: 8.72,
      price_6m_l: 4.66,
      price_52w_h: 8.72,
      price_52w_l: 4.016,
      price_pc_1d: 1.61,
      price_pc_1w: -1.56,
      price_pc_1m: -2.906,
      price_pc_6m: 5.283,
      price_pc_1y: 31.178,
      price_pc_ytd: 31.178,
    },
    7522: {
      id: "7522",
      c: 1.744,
      ma50: 2.033,
      ma100: 1.846,
      ma150: 1.856,
      ma200: 1.906,
      maw30: 1.855,
      rs: 56.0,
      rsd1d: 1.0,
      rsd5d: -15.0,
      rsd10d: -27.0,
      price_5d_h: 1.91,
      price_5d_L: 1.72,
      price_1m_h: 2.414,
      price_1m_l: 1.72,
      price_3m_h: 2.42,
      price_3m_l: 1.564,
      price_6m_h: 2.42,
      price_6m_l: 1.56,
      price_52w_h: 2.696,
      price_52w_l: 1.56,
      price_pc_1d: 0.115,
      price_pc_1w: -8.691,
      price_pc_1m: -16.873,
      price_pc_6m: -12.887,
      price_pc_1y: -33.638,
      price_pc_ytd: -33.638,
    },
    7552: {
      id: "7552",
      c: 10.55,
      ma50: 11.408,
      ma100: 10.299,
      ma150: 9.668,
      ma200: 9.147,
      maw30: 9.602,
      rs: 93.0,
      rsd1d: 2.0,
      rsd5d: 1.0,
      rsd10d: -2.0,
      price_5d_h: 11.94,
      price_5d_L: 9.855,
      price_1m_h: 22.98,
      price_1m_l: 9.275,
      price_3m_h: 22.98,
      price_3m_l: 8.83,
      price_6m_h: 22.98,
      price_6m_l: 7.24,
      price_52w_h: 22.98,
      price_52w_l: 5.645,
      price_pc_1d: 3.027,
      price_pc_1w: 0.285,
      price_pc_1m: -7.212,
      price_pc_6m: 13.441,
      price_pc_1y: 81.116,
      price_pc_ytd: 81.116,
    },
    7568: {
      id: "7568",
      c: 11.97,
      ma50: 3.453,
      ma100: 2.296,
      ma150: 1.961,
      ma200: 1.824,
      maw30: 1.652,
      rs: 99.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 16.0,
      price_5d_h: 12.83,
      price_5d_L: 11.81,
      price_1m_h: 13.97,
      price_1m_l: 1.378,
      price_3m_h: 13.97,
      price_3m_l: 1.072,
      price_6m_h: 13.97,
      price_6m_l: 1.072,
      price_52w_h: 13.97,
      price_52w_l: 1.072,
      price_pc_1d: 0.0,
      price_pc_1w: -8.626,
      price_pc_1m: 727.801,
      price_pc_6m: 769.913,
      price_pc_1y: 563.525,
      price_pc_ytd: 563.525,
    },
    7588: {
      id: "7588",
      c: 5.865,
      ma50: 5.942,
      ma100: 5.713,
      ma150: 5.51,
      ma200: 5.272,
      maw30: 5.475,
      rs: 90.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -4.0,
      price_5d_h: 6.45,
      price_5d_L: 5.69,
      price_1m_h: 9.855,
      price_1m_l: 5.585,
      price_3m_h: 9.855,
      price_3m_l: 4.928,
      price_6m_h: 9.855,
      price_6m_l: 4.582,
      price_52w_h: 9.855,
      price_52w_l: 3.722,
      price_pc_1d: 1.646,
      price_pc_1w: -1.759,
      price_pc_1m: -0.593,
      price_pc_6m: 8.611,
      price_pc_1y: 56.734,
      price_pc_ytd: 56.734,
    },
    7836: {
      id: "7836",
      c: 9.28,
      ma50: null,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 67.0,
      rsd1d: -1.0,
      rsd5d: -9.0,
      rsd10d: null,
      price_5d_h: 9.58,
      price_5d_L: 9.28,
      price_1m_h: 9.7,
      price_1m_l: 9.28,
      price_3m_h: 9.7,
      price_3m_l: 9.28,
      price_6m_h: 9.7,
      price_6m_l: 9.28,
      price_52w_h: 9.7,
      price_52w_l: 9.28,
      price_pc_1d: -1.066,
      price_pc_1w: -3.132,
      price_pc_1m: -4.132,
      price_pc_6m: -4.132,
      price_pc_1y: -4.132,
      price_pc_ytd: -4.132,
    },
    8001: {
      id: "8001",
      c: 0.093,
      ma50: 0.1,
      ma100: 0.198,
      ma150: 0.17,
      ma200: 0.18,
      maw30: 0.166,
      rs: 18.0,
      rsd1d: -7.0,
      rsd5d: 1.0,
      rsd10d: 13.0,
      price_5d_h: 0.1,
      price_5d_L: 0.091,
      price_1m_h: 0.102,
      price_1m_l: 0.078,
      price_3m_h: 0.127,
      price_3m_l: 0.078,
      price_6m_h: 0.8,
      price_6m_l: 0.063,
      price_52w_h: 0.8,
      price_52w_l: 0.063,
      price_pc_1d: -6.061,
      price_pc_1w: -1.064,
      price_pc_1m: -7.0,
      price_pc_6m: -12.264,
      price_pc_1y: -57.727,
      price_pc_ytd: -57.727,
    },
    8005: {
      id: "8005",
      c: 0.27,
      ma50: 0.315,
      ma100: 0.363,
      ma150: 0.374,
      ma200: 0.388,
      maw30: 0.378,
      rs: 16.0,
      rsd1d: 0.0,
      rsd5d: 6.0,
      rsd10d: 5.0,
      price_5d_h: 0.32,
      price_5d_L: 0.25,
      price_1m_h: 0.33,
      price_1m_l: 0.239,
      price_3m_h: 0.425,
      price_3m_l: 0.239,
      price_6m_h: 0.5,
      price_6m_l: 0.239,
      price_52w_h: 0.69,
      price_52w_l: 0.239,
      price_pc_1d: 0.0,
      price_pc_1w: 8.0,
      price_pc_1m: -12.903,
      price_pc_6m: -29.87,
      price_pc_1y: -49.057,
      price_pc_ytd: -49.057,
    },
    8006: {
      id: "8006",
      c: 0.072,
      ma50: 0.077,
      ma100: 0.075,
      ma150: 0.077,
      ma200: 0.077,
      maw30: 0.076,
      rs: 67.0,
      rsd1d: 4.0,
      rsd5d: -9.0,
      rsd10d: 27.0,
      price_5d_h: 0.072,
      price_5d_L: 0.07,
      price_1m_h: 0.085,
      price_1m_l: 0.06,
      price_3m_h: 0.112,
      price_3m_l: 0.06,
      price_6m_h: 0.112,
      price_6m_l: 0.06,
      price_52w_h: 0.112,
      price_52w_l: 0.06,
      price_pc_1d: 0.0,
      price_pc_1w: -10.0,
      price_pc_1m: -15.294,
      price_pc_6m: -13.253,
      price_pc_1y: -10.0,
      price_pc_ytd: -10.0,
    },
    8007: {
      id: "8007",
      c: 0.285,
      ma50: 0.283,
      ma100: 0.288,
      ma150: 0.302,
      ma200: 0.277,
      maw30: 0.307,
      rs: 85.0,
      rsd1d: -1.0,
      rsd5d: -7.0,
      rsd10d: -5.0,
      price_5d_h: 0.305,
      price_5d_L: 0.28,
      price_1m_h: 0.315,
      price_1m_l: 0.265,
      price_3m_h: 0.315,
      price_3m_l: 0.232,
      price_6m_h: 0.46,
      price_6m_l: 0.218,
      price_52w_h: 0.46,
      price_52w_l: 0.156,
      price_pc_1d: -1.724,
      price_pc_1w: -8.065,
      price_pc_1m: -5.0,
      price_pc_6m: -30.488,
      price_pc_1y: 31.944,
      price_pc_ytd: 31.944,
    },
    8011: {
      id: "8011",
      c: 0.162,
      ma50: 0.156,
      ma100: 0.153,
      ma150: 0.124,
      ma200: 0.111,
      maw30: 0.124,
      rs: 96.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 4.0,
      price_5d_h: 0.17,
      price_5d_L: 0.142,
      price_1m_h: 0.172,
      price_1m_l: 0.121,
      price_3m_h: 0.197,
      price_3m_l: 0.121,
      price_6m_h: 0.197,
      price_6m_l: 0.056,
      price_52w_h: 0.197,
      price_52w_l: 0.051,
      price_pc_1d: 0.0,
      price_pc_1w: 5.195,
      price_pc_1m: 1.25,
      price_pc_6m: 92.857,
      price_pc_1y: 116.0,
      price_pc_ytd: 116.0,
    },
    8017: {
      id: "8017",
      c: 1.22,
      ma50: 1.003,
      ma100: 0.895,
      ma150: 0.809,
      ma200: 0.77,
      maw30: 0.811,
      rs: 97.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: 0.0,
      price_5d_h: 1.23,
      price_5d_L: 1.02,
      price_1m_h: 1.27,
      price_1m_l: 0.99,
      price_3m_h: 1.27,
      price_3m_l: 0.75,
      price_6m_h: 1.27,
      price_6m_l: 0.62,
      price_52w_h: 1.27,
      price_52w_l: 0.345,
      price_pc_1d: 1.667,
      price_pc_1w: 17.308,
      price_pc_1m: 4.274,
      price_pc_6m: 79.412,
      price_pc_1y: 253.623,
      price_pc_ytd: 253.623,
    },
    8018: {
      id: "8018",
      c: 0.17,
      ma50: 0.225,
      ma100: 0.236,
      ma150: 0.248,
      ma200: 0.265,
      maw30: 0.246,
      rs: 15.0,
      rsd1d: 3.0,
      rsd5d: -13.0,
      rsd10d: -6.0,
      price_5d_h: 0.188,
      price_5d_L: 0.17,
      price_1m_h: 0.22,
      price_1m_l: 0.17,
      price_3m_h: 0.295,
      price_3m_l: 0.17,
      price_6m_h: 0.295,
      price_6m_l: 0.17,
      price_52w_h: 0.54,
      price_52w_l: 0.17,
      price_pc_1d: 0.0,
      price_pc_1w: -18.269,
      price_pc_1m: -22.727,
      price_pc_6m: -38.182,
      price_pc_1y: -50.0,
      price_pc_ytd: -50.0,
    },
    8019: {
      id: "8019",
      c: 0.25,
      ma50: 0.314,
      ma100: 0.301,
      ma150: 0.279,
      ma200: 0.272,
      maw30: 0.28,
      rs: 66.0,
      rsd1d: -14.0,
      rsd5d: -25.0,
      rsd10d: -27.0,
      price_5d_h: 0.315,
      price_5d_L: 0.25,
      price_1m_h: 0.375,
      price_1m_l: 0.25,
      price_3m_h: 0.375,
      price_3m_l: 0.25,
      price_6m_h: 0.375,
      price_6m_l: 0.2,
      price_52w_h: 0.375,
      price_52w_l: 0.16,
      price_pc_1d: -5.66,
      price_pc_1w: -20.635,
      price_pc_1m: -23.077,
      price_pc_6m: 4.167,
      price_pc_1y: 21.951,
      price_pc_ytd: 21.951,
    },
    8021: {
      id: "8021",
      c: 0.049,
      ma50: 0.046,
      ma100: 0.046,
      ma150: 0.046,
      ma200: 0.048,
      maw30: 0.047,
      rs: 79.0,
      rsd1d: -5.0,
      rsd5d: -4.0,
      rsd10d: -9.0,
      price_5d_h: 0.049,
      price_5d_L: 0.041,
      price_1m_h: 0.051,
      price_1m_l: 0.032,
      price_3m_h: 0.055,
      price_3m_l: 0.032,
      price_6m_h: 0.058,
      price_6m_l: 0.032,
      price_52w_h: 0.066,
      price_52w_l: 0.031,
      price_pc_1d: 0.0,
      price_pc_1w: 2.083,
      price_pc_1m: 11.364,
      price_pc_6m: 4.255,
      price_pc_1y: 32.432,
      price_pc_ytd: 32.432,
    },
    8026: {
      id: "8026",
      c: 0.34,
      ma50: 0.351,
      ma100: 0.392,
      ma150: 0.439,
      ma200: 0.473,
      maw30: 0.438,
      rs: 20.0,
      rsd1d: 1.0,
      rsd5d: -4.0,
      rsd10d: 5.0,
      price_5d_h: 0.375,
      price_5d_L: 0.295,
      price_1m_h: 0.395,
      price_1m_l: 0.25,
      price_3m_h: 0.69,
      price_3m_l: 0.25,
      price_6m_h: 0.69,
      price_6m_l: 0.25,
      price_52w_h: 0.72,
      price_52w_l: 0.25,
      price_pc_1d: -1.449,
      price_pc_1w: -5.556,
      price_pc_1m: -2.857,
      price_pc_6m: -39.286,
      price_pc_1y: -41.379,
      price_pc_ytd: -41.379,
    },
    8027: {
      id: "8027",
      c: 0.495,
      ma50: 0.509,
      ma100: 0.476,
      ma150: 0.511,
      ma200: 0.528,
      maw30: 0.504,
      rs: 90.0,
      rsd1d: 0.0,
      rsd5d: -4.0,
      rsd10d: -5.0,
      price_5d_h: 0.56,
      price_5d_L: 0.5,
      price_1m_h: 0.58,
      price_1m_l: 0.5,
      price_3m_h: 0.58,
      price_3m_l: 0.38,
      price_6m_h: 0.6,
      price_6m_l: 0.38,
      price_52w_h: 0.8,
      price_52w_l: 0.265,
      price_pc_1d: -1.0,
      price_pc_1w: -11.607,
      price_pc_1m: -11.607,
      price_pc_6m: -11.607,
      price_pc_1y: 65.0,
      price_pc_ytd: 65.0,
    },
    8028: {
      id: "8028",
      c: 0.029,
      ma50: 0.028,
      ma100: 0.031,
      ma150: 0.034,
      ma200: 0.037,
      maw30: 0.034,
      rs: 33.0,
      rsd1d: 4.0,
      rsd5d: 1.0,
      rsd10d: 18.0,
      price_5d_h: 0.029,
      price_5d_L: 0.027,
      price_1m_h: 0.032,
      price_1m_l: 0.022,
      price_3m_h: 0.034,
      price_3m_l: 0.022,
      price_6m_h: 0.042,
      price_6m_l: 0.022,
      price_52w_h: 0.056,
      price_52w_l: 0.022,
      price_pc_1d: 0.0,
      price_pc_1w: -3.333,
      price_pc_1m: 7.407,
      price_pc_6m: -29.268,
      price_pc_1y: -34.091,
      price_pc_ytd: -34.091,
    },
    8032: {
      id: "8032",
      c: 0.98,
      ma50: 1.259,
      ma100: 1.413,
      ma150: 1.452,
      ma200: 1.442,
      maw30: 1.462,
      rs: 44.0,
      rsd1d: -1.0,
      rsd5d: -25.0,
      rsd10d: -12.0,
      price_5d_h: 1.07,
      price_5d_L: 0.95,
      price_1m_h: 1.41,
      price_1m_l: 0.73,
      price_3m_h: 1.55,
      price_3m_l: 0.73,
      price_6m_h: 1.84,
      price_6m_l: 0.73,
      price_52w_h: 1.97,
      price_52w_l: 0.57,
      price_pc_1d: -2.97,
      price_pc_1w: -7.547,
      price_pc_1m: -27.941,
      price_pc_6m: -32.877,
      price_pc_1y: 66.102,
      price_pc_ytd: 66.102,
    },
    8035: {
      id: "8035",
      c: 0.217,
      ma50: 0.196,
      ma100: 0.208,
      ma150: 0.217,
      ma200: 0.23,
      maw30: 0.215,
      rs: 52.0,
      rsd1d: -2.0,
      rsd5d: -8.0,
      rsd10d: 8.0,
      price_5d_h: 0.22,
      price_5d_L: 0.2,
      price_1m_h: 0.238,
      price_1m_l: 0.175,
      price_3m_h: 0.238,
      price_3m_l: 0.175,
      price_6m_h: 0.25,
      price_6m_l: 0.175,
      price_52w_h: 0.31,
      price_52w_l: 0.175,
      price_pc_1d: 0.0,
      price_pc_1w: -1.364,
      price_pc_1m: 20.556,
      price_pc_6m: -9.583,
      price_pc_1y: -23.86,
      price_pc_ytd: -23.86,
    },
    8036: {
      id: "8036",
      c: 0.088,
      ma50: 0.087,
      ma100: 0.09,
      ma150: 0.094,
      ma200: 0.096,
      maw30: 0.093,
      rs: 57.0,
      rsd1d: 3.0,
      rsd5d: 6.0,
      rsd10d: 8.0,
      price_5d_h: 0.088,
      price_5d_L: 0.085,
      price_1m_h: 0.09,
      price_1m_l: 0.082,
      price_3m_h: 0.098,
      price_3m_l: 0.082,
      price_6m_h: 0.107,
      price_6m_l: 0.082,
      price_52w_h: 0.119,
      price_52w_l: 0.082,
      price_pc_1d: 0.0,
      price_pc_1w: 3.529,
      price_pc_1m: 2.326,
      price_pc_6m: -11.111,
      price_pc_1y: -6.383,
      price_pc_ytd: -6.383,
    },
    8037: {
      id: "8037",
      c: 1.37,
      ma50: 1.405,
      ma100: 1.389,
      ma150: 1.374,
      ma200: 1.424,
      maw30: 1.384,
      rs: 71.0,
      rsd1d: -10.0,
      rsd5d: -12.0,
      rsd10d: 7.0,
      price_5d_h: 1.59,
      price_5d_L: 1.35,
      price_1m_h: 1.67,
      price_1m_l: 1.21,
      price_3m_h: 1.79,
      price_3m_l: 1.05,
      price_6m_h: 2.64,
      price_6m_l: 1.0,
      price_52w_h: 2.64,
      price_52w_l: 0.99,
      price_pc_1d: -2.837,
      price_pc_1w: -14.375,
      price_pc_1m: -4.196,
      price_pc_6m: 0.0,
      price_pc_1y: 10.484,
      price_pc_ytd: 10.484,
    },
    8039: {
      id: "8039",
      c: 2.0,
      ma50: 0.954,
      ma100: 0.712,
      ma150: 0.674,
      ma200: 0.574,
      maw30: 0.664,
      rs: 99.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: 1.0,
      price_5d_h: 2.05,
      price_5d_L: 1.3,
      price_1m_h: 2.05,
      price_1m_l: 0.8,
      price_3m_h: 2.05,
      price_3m_l: 0.395,
      price_6m_h: 2.05,
      price_6m_l: 0.35,
      price_52w_h: 2.05,
      price_52w_l: 0.165,
      price_pc_1d: 0.503,
      price_pc_1w: 38.889,
      price_pc_1m: 132.558,
      price_pc_6m: 150.0,
      price_pc_1y: 163.158,
      price_pc_ytd: 163.158,
    },
    8040: {
      id: "8040",
      c: 0.37,
      ma50: 0.346,
      ma100: 0.378,
      ma150: 0.334,
      ma200: 0.292,
      maw30: 0.34,
      rs: 97.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.375,
      price_5d_L: 0.31,
      price_1m_h: 0.38,
      price_1m_l: 0.3,
      price_3m_h: 0.39,
      price_3m_l: 0.3,
      price_6m_h: 0.52,
      price_6m_l: 0.18,
      price_52w_h: 0.52,
      price_52w_l: 0.107,
      price_pc_1d: 0.0,
      price_pc_1w: -1.333,
      price_pc_1m: 8.824,
      price_pc_6m: 72.897,
      price_pc_1y: 150.0,
      price_pc_ytd: 150.0,
    },
    8041: {
      id: "8041",
      c: 0.345,
      ma50: 0.385,
      ma100: 0.4,
      ma150: 0.391,
      ma200: 0.36,
      maw30: 0.398,
      rs: 70.0,
      rsd1d: -6.0,
      rsd5d: 3.0,
      rsd10d: -10.0,
      price_5d_h: 0.36,
      price_5d_L: 0.34,
      price_1m_h: 0.395,
      price_1m_l: 0.34,
      price_3m_h: 0.495,
      price_3m_l: 0.315,
      price_6m_h: 0.495,
      price_6m_l: 0.315,
      price_52w_h: 0.495,
      price_52w_l: 0.237,
      price_pc_1d: -1.429,
      price_pc_1w: -1.429,
      price_pc_1m: -9.211,
      price_pc_6m: -11.538,
      price_pc_1y: 2.985,
      price_pc_ytd: 2.985,
    },
    8042: {
      id: "8042",
      c: 0.061,
      ma50: 0.056,
      ma100: 0.061,
      ma150: 0.058,
      ma200: 0.055,
      maw30: 0.059,
      rs: 92.0,
      rsd1d: 0.0,
      rsd5d: 6.0,
      rsd10d: 8.0,
      price_5d_h: 0.063,
      price_5d_L: 0.056,
      price_1m_h: 0.063,
      price_1m_l: 0.042,
      price_3m_h: 0.065,
      price_3m_l: 0.042,
      price_6m_h: 0.084,
      price_6m_l: 0.042,
      price_52w_h: 0.084,
      price_52w_l: 0.032,
      price_pc_1d: 0.0,
      price_pc_1w: 8.929,
      price_pc_1m: 22.0,
      price_pc_6m: 32.609,
      price_pc_1y: 56.41,
      price_pc_ytd: 56.41,
    },
    8043: {
      id: "8043",
      c: 0.16,
      ma50: 0.172,
      ma100: 0.196,
      ma150: 0.202,
      ma200: 0.195,
      maw30: 0.203,
      rs: 48.0,
      rsd1d: 7.0,
      rsd5d: 13.0,
      rsd10d: 14.0,
      price_5d_h: 0.178,
      price_5d_L: 0.141,
      price_1m_h: 0.189,
      price_1m_l: 0.141,
      price_3m_h: 0.229,
      price_3m_l: 0.141,
      price_6m_h: 0.265,
      price_6m_l: 0.141,
      price_52w_h: 0.3,
      price_52w_l: 0.131,
      price_pc_1d: 2.564,
      price_pc_1w: 3.226,
      price_pc_1m: -9.091,
      price_pc_6m: -25.926,
      price_pc_1y: 14.286,
      price_pc_ytd: 14.286,
    },
    8045: {
      id: "8045",
      c: 0.04,
      ma50: 0.048,
      ma100: 0.046,
      ma150: 0.047,
      ma200: 0.047,
      maw30: 0.047,
      rs: 40.0,
      rsd1d: 13.0,
      rsd5d: -8.0,
      rsd10d: -5.0,
      price_5d_h: 0.044,
      price_5d_L: 0.037,
      price_1m_h: 0.05,
      price_1m_l: 0.035,
      price_3m_h: 0.067,
      price_3m_l: 0.035,
      price_6m_h: 0.067,
      price_6m_l: 0.035,
      price_52w_h: 0.071,
      price_52w_l: 0.035,
      price_pc_1d: 8.108,
      price_pc_1w: -4.762,
      price_pc_1m: -27.273,
      price_pc_6m: -13.043,
      price_pc_1y: -27.273,
      price_pc_ytd: -27.273,
    },
    8047: {
      id: "8047",
      c: 0.189,
      ma50: 0.185,
      ma100: 0.127,
      ma150: 0.11,
      ma200: 0.107,
      maw30: 0.108,
      rs: 97.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: -1.0,
      price_5d_h: 0.2,
      price_5d_L: 0.182,
      price_1m_h: 0.25,
      price_1m_l: 0.182,
      price_3m_h: 0.25,
      price_3m_l: 0.083,
      price_6m_h: 0.25,
      price_6m_l: 0.042,
      price_52w_h: 0.25,
      price_52w_l: 0.042,
      price_pc_1d: 0.0,
      price_pc_1w: 2.717,
      price_pc_1m: -5.5,
      price_pc_6m: 204.839,
      price_pc_1y: 8.0,
      price_pc_ytd: 8.0,
    },
    8048: {
      id: "8048",
      c: 0.04,
      ma50: 0.044,
      ma100: 0.045,
      ma150: 0.046,
      ma200: 0.047,
      maw30: 0.046,
      rs: 33.0,
      rsd1d: -9.0,
      rsd5d: 9.0,
      rsd10d: -14.0,
      price_5d_h: 0.044,
      price_5d_L: 0.037,
      price_1m_h: 0.053,
      price_1m_l: 0.035,
      price_3m_h: 0.055,
      price_3m_l: 0.031,
      price_6m_h: 0.055,
      price_6m_l: 0.031,
      price_52w_h: 0.07,
      price_52w_l: 0.031,
      price_pc_1d: -2.439,
      price_pc_1w: 8.108,
      price_pc_1m: -25.926,
      price_pc_6m: -13.043,
      price_pc_1y: -42.857,
      price_pc_ytd: -42.857,
    },
    8049: {
      id: "8049",
      c: 1.0,
      ma50: 1.126,
      ma100: 1.147,
      ma150: 1.125,
      ma200: 1.13,
      maw30: 1.134,
      rs: 42.0,
      rsd1d: -2.0,
      rsd5d: -5.0,
      rsd10d: 2.0,
      price_5d_h: 1.09,
      price_5d_L: 1.0,
      price_1m_h: 1.15,
      price_1m_l: 0.92,
      price_3m_h: 1.3,
      price_3m_l: 0.92,
      price_6m_h: 1.3,
      price_6m_l: 0.92,
      price_52w_h: 1.33,
      price_52w_l: 0.92,
      price_pc_1d: -1.961,
      price_pc_1w: 0.0,
      price_pc_1m: -12.281,
      price_pc_6m: -10.714,
      price_pc_1y: -18.033,
      price_pc_ytd: -18.033,
    },
    8053: {
      id: "8053",
      c: 0.425,
      ma50: 0.43,
      ma100: 0.47,
      ma150: 0.499,
      ma200: 0.535,
      maw30: 0.5,
      rs: 25.0,
      rsd1d: 2.0,
      rsd5d: 2.0,
      rsd10d: 1.0,
      price_5d_h: 0.47,
      price_5d_L: 0.42,
      price_1m_h: 0.475,
      price_1m_l: 0.41,
      price_3m_h: 0.53,
      price_3m_l: 0.405,
      price_6m_h: 0.59,
      price_6m_l: 0.405,
      price_52w_h: 0.79,
      price_52w_l: 0.405,
      price_pc_1d: -1.163,
      price_pc_1w: 0.0,
      price_pc_1m: 4.938,
      price_pc_6m: -26.724,
      price_pc_1y: -45.513,
      price_pc_ytd: -45.513,
    },
    8057: {
      id: "8057",
      c: 1.15,
      ma50: 1.163,
      ma100: 1.107,
      ma150: 1.163,
      ma200: 1.23,
      maw30: 1.149,
      rs: 97.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: -1.0,
      price_5d_h: 1.18,
      price_5d_L: 1.0,
      price_1m_h: 1.38,
      price_1m_l: 0.83,
      price_3m_h: 1.38,
      price_3m_l: 0.82,
      price_6m_h: 1.38,
      price_6m_l: 0.51,
      price_52w_h: 1.82,
      price_52w_l: 0.14,
      price_pc_1d: -0.862,
      price_pc_1w: -0.862,
      price_pc_1m: -4.167,
      price_pc_6m: -3.361,
      price_pc_1y: 538.889,
      price_pc_ytd: 538.889,
    },
    8059: {
      id: "8059",
      c: 0.021,
      ma50: 0.029,
      ma100: 0.031,
      ma150: 0.034,
      ma200: 0.039,
      maw30: 0.033,
      rs: 10.0,
      rsd1d: 2.0,
      rsd5d: 1.0,
      rsd10d: -2.0,
      price_5d_h: 0.021,
      price_5d_L: 0.021,
      price_1m_h: 0.035,
      price_1m_l: 0.02,
      price_3m_h: 0.036,
      price_3m_l: 0.02,
      price_6m_h: 0.043,
      price_6m_l: 0.02,
      price_52w_h: 0.079,
      price_52w_l: 0.02,
      price_pc_1d: 0.0,
      price_pc_1w: -4.545,
      price_pc_1m: -38.235,
      price_pc_6m: -36.364,
      price_pc_1y: -69.118,
      price_pc_ytd: -69.118,
    },
    8063: {
      id: "8063",
      c: 0.073,
      ma50: 0.086,
      ma100: 0.098,
      ma150: 0.109,
      ma200: 0.118,
      maw30: 0.109,
      rs: 8.0,
      rsd1d: 0.0,
      rsd5d: 2.0,
      rsd10d: -1.0,
      price_5d_h: 0.076,
      price_5d_L: 0.073,
      price_1m_h: 0.094,
      price_1m_l: 0.051,
      price_3m_h: 0.116,
      price_3m_l: 0.051,
      price_6m_h: 0.139,
      price_6m_l: 0.051,
      price_52w_h: 2.2,
      price_52w_l: 0.051,
      price_pc_1d: -2.667,
      price_pc_1w: 0.0,
      price_pc_1m: -20.652,
      price_pc_6m: -38.655,
      price_pc_1y: -92.626,
      price_pc_ytd: -92.626,
    },
    8065: {
      id: "8065",
      c: 0.238,
      ma50: 0.292,
      ma100: 0.316,
      ma150: 0.332,
      ma200: 0.343,
      maw30: 0.335,
      rs: 19.0,
      rsd1d: -1.0,
      rsd5d: 1.0,
      rsd10d: 3.0,
      price_5d_h: 0.255,
      price_5d_L: 0.232,
      price_1m_h: 0.315,
      price_1m_l: 0.2,
      price_3m_h: 0.355,
      price_3m_l: 0.2,
      price_6m_h: 0.38,
      price_6m_l: 0.2,
      price_52w_h: 0.42,
      price_52w_l: 0.2,
      price_pc_1d: -2.857,
      price_pc_1w: 4.386,
      price_pc_1m: -24.444,
      price_pc_6m: -32.958,
      price_pc_1y: -26.769,
      price_pc_ytd: -26.769,
    },
    8066: {
      id: "8066",
      c: 0.038,
      ma50: 0.039,
      ma100: 0.04,
      ma150: 0.042,
      ma200: 0.043,
      maw30: 0.042,
      rs: 66.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: 14.0,
      price_5d_h: 0.04,
      price_5d_L: 0.037,
      price_1m_h: 0.041,
      price_1m_l: 0.037,
      price_3m_h: 0.041,
      price_3m_l: 0.035,
      price_6m_h: 0.068,
      price_6m_l: 0.035,
      price_52w_h: 0.084,
      price_52w_l: 0.029,
      price_pc_1d: 0.0,
      price_pc_1w: -5.0,
      price_pc_1m: -7.317,
      price_pc_6m: -11.628,
      price_pc_1y: -22.449,
      price_pc_ytd: -22.449,
    },
    8067: {
      id: "8067",
      c: 0.6,
      ma50: 0.687,
      ma100: 0.76,
      ma150: 0.765,
      ma200: 0.863,
      maw30: 0.77,
      rs: 16.0,
      rsd1d: 1.0,
      rsd5d: 3.0,
      rsd10d: 1.0,
      price_5d_h: 0.6,
      price_5d_L: 0.42,
      price_1m_h: 0.69,
      price_1m_l: 0.42,
      price_3m_h: 0.8,
      price_3m_l: 0.42,
      price_6m_h: 1.83,
      price_6m_l: 0.42,
      price_52w_h: 1.83,
      price_52w_l: 0.42,
      price_pc_1d: 0.0,
      price_pc_1w: 3.448,
      price_pc_1m: -14.286,
      price_pc_6m: -28.571,
      price_pc_1y: -58.042,
      price_pc_ytd: -58.042,
    },
    8071: {
      id: "8071",
      c: 0.03,
      ma50: 0.032,
      ma100: 0.033,
      ma150: 0.036,
      ma200: 0.041,
      maw30: 0.037,
      rs: 25.0,
      rsd1d: 7.0,
      rsd5d: -14.0,
      rsd10d: -10.0,
      price_5d_h: 0.033,
      price_5d_L: 0.027,
      price_1m_h: 0.035,
      price_1m_l: 0.027,
      price_3m_h: 0.039,
      price_3m_l: 0.026,
      price_6m_h: 0.046,
      price_6m_l: 0.025,
      price_52w_h: 0.067,
      price_52w_l: 0.025,
      price_pc_1d: 11.111,
      price_pc_1w: -11.765,
      price_pc_1m: -9.091,
      price_pc_6m: -31.818,
      price_pc_1y: -31.818,
      price_pc_ytd: -31.818,
    },
    8072: {
      id: "8072",
      c: 0.295,
      ma50: 0.287,
      ma100: 0.341,
      ma150: 0.33,
      ma200: 0.29,
      maw30: 0.328,
      rs: 96.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: 2.0,
      price_5d_h: 0.31,
      price_5d_L: 0.26,
      price_1m_h: 0.31,
      price_1m_l: 0.245,
      price_3m_h: 0.44,
      price_3m_l: 0.245,
      price_6m_h: 0.55,
      price_6m_l: 0.19,
      price_52w_h: 0.55,
      price_52w_l: 0.101,
      price_pc_1d: 0.0,
      price_pc_1w: 7.273,
      price_pc_1m: 7.273,
      price_pc_6m: 55.263,
      price_pc_1y: 112.23,
      price_pc_ytd: 112.23,
    },
    8075: {
      id: "8075",
      c: 0.79,
      ma50: 0.927,
      ma100: 1.102,
      ma150: 1.247,
      ma200: 1.328,
      maw30: 1.251,
      rs: 12.0,
      rsd1d: 0.0,
      rsd5d: 3.0,
      rsd10d: -3.0,
      price_5d_h: 1.08,
      price_5d_L: 0.79,
      price_1m_h: 1.08,
      price_1m_l: 0.75,
      price_3m_h: 1.2,
      price_3m_l: 0.75,
      price_6m_h: 1.67,
      price_6m_l: 0.75,
      price_52w_h: 2.44,
      price_52w_l: 0.75,
      price_pc_1d: -2.469,
      price_pc_1w: 5.333,
      price_pc_1m: -7.059,
      price_pc_6m: -47.682,
      price_pc_1y: -41.481,
      price_pc_ytd: -41.481,
    },
    8078: {
      id: "8078",
      c: 0.036,
      ma50: 0.033,
      ma100: 0.04,
      ma150: 0.043,
      ma200: 0.051,
      maw30: 0.044,
      rs: 18.0,
      rsd1d: 5.0,
      rsd5d: 9.0,
      rsd10d: 7.0,
      price_5d_h: 0.039,
      price_5d_L: 0.025,
      price_1m_h: 0.039,
      price_1m_l: 0.021,
      price_3m_h: 0.047,
      price_3m_l: 0.021,
      price_6m_h: 0.068,
      price_6m_l: 0.021,
      price_52w_h: 0.155,
      price_52w_l: 0.021,
      price_pc_1d: 5.882,
      price_pc_1w: 28.571,
      price_pc_1m: 20.0,
      price_pc_6m: -30.769,
      price_pc_1y: -52.632,
      price_pc_ytd: -52.632,
    },
    8081: {
      id: "8081",
      c: 0.112,
      ma50: 0.1,
      ma100: 0.104,
      ma150: 0.119,
      ma200: 0.11,
      maw30: 0.12,
      rs: 90.0,
      rsd1d: -2.0,
      rsd5d: 4.0,
      rsd10d: 6.0,
      price_5d_h: 0.115,
      price_5d_L: 0.095,
      price_1m_h: 0.115,
      price_1m_l: 0.077,
      price_3m_h: 0.119,
      price_3m_l: 0.077,
      price_6m_h: 0.17,
      price_6m_l: 0.072,
      price_52w_h: 0.178,
      price_52w_l: 0.05,
      price_pc_1d: -0.885,
      price_pc_1w: 12.0,
      price_pc_1m: 19.149,
      price_pc_6m: -31.707,
      price_pc_1y: 60.0,
      price_pc_ytd: 60.0,
    },
    8082: {
      id: "8082",
      c: 0.049,
      ma50: 0.066,
      ma100: 0.086,
      ma150: 0.125,
      ma200: 0.168,
      maw30: 0.12,
      rs: 3.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: -3.0,
      price_5d_h: 0.051,
      price_5d_L: 0.049,
      price_1m_h: 0.078,
      price_1m_l: 0.049,
      price_3m_h: 0.09,
      price_3m_l: 0.049,
      price_6m_h: 0.203,
      price_6m_l: 0.049,
      price_52w_h: 0.33,
      price_52w_l: 0.049,
      price_pc_1d: -3.922,
      price_pc_1w: -3.922,
      price_pc_1m: -28.986,
      price_pc_6m: -77.523,
      price_pc_1y: -82.5,
      price_pc_ytd: -82.5,
    },
    8083: {
      id: "8083",
      c: 0.162,
      ma50: 0.26,
      ma100: 0.454,
      ma150: 0.636,
      ma200: 0.783,
      maw30: 0.641,
      rs: 1.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.208,
      price_5d_L: 0.158,
      price_1m_h: 0.305,
      price_1m_l: 0.15,
      price_3m_h: 0.54,
      price_3m_l: 0.15,
      price_6m_h: 1.15,
      price_6m_l: 0.15,
      price_52w_h: 2.84,
      price_52w_l: 0.15,
      price_pc_1d: 1.25,
      price_pc_1w: -18.182,
      price_pc_1m: -34.94,
      price_pc_6m: -83.469,
      price_pc_1y: -94.0,
      price_pc_ytd: -94.0,
    },
    8091: {
      id: "8091",
      c: 0.042,
      ma50: 0.061,
      ma100: 0.116,
      ma150: 0.115,
      ma200: 0.112,
      maw30: 0.114,
      rs: 3.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: 1.0,
      price_5d_h: 0.049,
      price_5d_L: 0.04,
      price_1m_h: 0.049,
      price_1m_l: 0.038,
      price_3m_h: 0.213,
      price_3m_l: 0.038,
      price_6m_h: 0.23,
      price_6m_l: 0.038,
      price_52w_h: 0.23,
      price_52w_l: 0.038,
      price_pc_1d: 5.0,
      price_pc_1w: 2.439,
      price_pc_1m: 2.439,
      price_pc_6m: -54.348,
      price_pc_1y: -75.862,
      price_pc_ytd: -75.862,
    },
    8092: {
      id: "8092",
      c: 0.033,
      ma50: 0.035,
      ma100: 0.04,
      ma150: 0.045,
      ma200: 0.049,
      maw30: 0.044,
      rs: 19.0,
      rsd1d: 4.0,
      rsd5d: -2.0,
      rsd10d: 3.0,
      price_5d_h: 0.033,
      price_5d_L: 0.028,
      price_1m_h: 0.037,
      price_1m_l: 0.027,
      price_3m_h: 0.049,
      price_3m_l: 0.027,
      price_6m_h: 0.06,
      price_6m_l: 0.027,
      price_52w_h: 0.074,
      price_52w_l: 0.027,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: 3.125,
      price_pc_6m: -36.538,
      price_pc_1y: -26.667,
      price_pc_ytd: -26.667,
    },
    8095: {
      id: "8095",
      c: 0.6,
      ma50: 0.582,
      ma100: 0.562,
      ma150: 0.534,
      ma200: 0.525,
      maw30: 0.538,
      rs: 88.0,
      rsd1d: 1.0,
      rsd5d: 2.0,
      rsd10d: 7.0,
      price_5d_h: 0.6,
      price_5d_L: 0.53,
      price_1m_h: 0.63,
      price_1m_l: 0.475,
      price_3m_h: 0.63,
      price_3m_l: 0.475,
      price_6m_h: 0.63,
      price_6m_l: 0.465,
      price_52w_h: 0.63,
      price_52w_l: 0.45,
      price_pc_1d: 3.448,
      price_pc_1w: 3.448,
      price_pc_1m: 3.448,
      price_pc_6m: 26.316,
      price_pc_1y: 1.695,
      price_pc_ytd: 1.695,
    },
    8096: {
      id: "8096",
      c: 0.36,
      ma50: 0.186,
      ma100: 0.245,
      ma150: 0.249,
      ma200: 0.23,
      maw30: 0.248,
      rs: 98.0,
      rsd1d: 1.0,
      rsd5d: 9.0,
      rsd10d: 8.0,
      price_5d_h: 0.38,
      price_5d_L: 0.15,
      price_1m_h: 0.38,
      price_1m_l: 0.15,
      price_3m_h: 0.38,
      price_3m_l: 0.15,
      price_6m_h: 0.5,
      price_6m_l: 0.11,
      price_52w_h: 0.5,
      price_52w_l: 0.095,
      price_pc_1d: 12.5,
      price_pc_1w: 111.765,
      price_pc_1m: 100.0,
      price_pc_6m: 60.0,
      price_pc_1y: 256.436,
      price_pc_ytd: 256.436,
    },
    8098: {
      id: "8098",
      c: 0.076,
      ma50: 0.073,
      ma100: 0.074,
      ma150: 0.075,
      ma200: 0.077,
      maw30: 0.075,
      rs: 72.0,
      rsd1d: 6.0,
      rsd5d: 0.0,
      rsd10d: -19.0,
      price_5d_h: 0.082,
      price_5d_L: 0.074,
      price_1m_h: 0.094,
      price_1m_l: 0.059,
      price_3m_h: 0.094,
      price_3m_l: 0.059,
      price_6m_h: 0.1,
      price_6m_l: 0.059,
      price_52w_h: 0.1,
      price_52w_l: 0.059,
      price_pc_1d: 0.0,
      price_pc_1w: -5.0,
      price_pc_1m: 24.59,
      price_pc_6m: -12.644,
      price_pc_1y: 15.152,
      price_pc_ytd: 15.152,
    },
    8100: {
      id: "8100",
      c: 0.37,
      ma50: 0.381,
      ma100: 0.381,
      ma150: 0.394,
      ma200: 0.391,
      maw30: 0.395,
      rs: 66.0,
      rsd1d: -14.0,
      rsd5d: 27.0,
      rsd10d: -6.0,
      price_5d_h: 0.4,
      price_5d_L: 0.35,
      price_1m_h: 0.4,
      price_1m_l: 0.345,
      price_3m_h: 0.4,
      price_3m_l: 0.335,
      price_6m_h: 0.46,
      price_6m_l: 0.335,
      price_52w_h: 0.69,
      price_52w_l: 0.305,
      price_pc_1d: -7.5,
      price_pc_1w: 7.246,
      price_pc_1m: -1.333,
      price_pc_6m: -7.5,
      price_pc_1y: -11.905,
      price_pc_ytd: -11.905,
    },
    8115: {
      id: "8115",
      c: 0.495,
      ma50: 0.616,
      ma100: 0.629,
      ma150: 0.628,
      ma200: 0.62,
      maw30: 0.623,
      rs: 42.0,
      rsd1d: -22.0,
      rsd5d: 14.0,
      rsd10d: 9.0,
      price_5d_h: 0.56,
      price_5d_L: 0.485,
      price_1m_h: 0.65,
      price_1m_l: 0.485,
      price_3m_h: 0.65,
      price_3m_l: 0.485,
      price_6m_h: 0.65,
      price_6m_l: 0.455,
      price_52w_h: 0.72,
      price_52w_l: 0.455,
      price_pc_1d: -11.607,
      price_pc_1w: 1.02,
      price_pc_1m: -23.846,
      price_pc_6m: 5.319,
      price_pc_1y: -18.852,
      price_pc_ytd: -18.852,
    },
    8118: {
      id: "8118",
      c: 0.49,
      ma50: 0.522,
      ma100: 0.394,
      ma150: 0.347,
      ma200: 0.346,
      maw30: 0.351,
      rs: 95.0,
      rsd1d: 1.0,
      rsd5d: -1.0,
      rsd10d: -1.0,
      price_5d_h: 0.52,
      price_5d_L: 0.4,
      price_1m_h: 0.59,
      price_1m_l: 0.4,
      price_3m_h: 0.67,
      price_3m_l: 0.275,
      price_6m_h: 0.67,
      price_6m_l: 0.163,
      price_52w_h: 0.67,
      price_52w_l: 0.163,
      price_pc_1d: 1.031,
      price_pc_1w: -2.0,
      price_pc_1m: -2.0,
      price_pc_6m: 88.462,
      price_pc_1y: 7.692,
      price_pc_ytd: 7.692,
    },
    8120: {
      id: "8120",
      c: 0.153,
      ma50: 0.145,
      ma100: 0.134,
      ma150: 0.129,
      ma200: 0.129,
      maw30: 0.129,
      rs: 90.0,
      rsd1d: 0.0,
      rsd5d: -4.0,
      rsd10d: -5.0,
      price_5d_h: 0.159,
      price_5d_L: 0.144,
      price_1m_h: 0.178,
      price_1m_l: 0.14,
      price_3m_h: 0.178,
      price_3m_l: 0.113,
      price_6m_h: 0.178,
      price_6m_l: 0.107,
      price_52w_h: 0.265,
      price_52w_l: 0.104,
      price_pc_1d: 0.0,
      price_pc_1w: -4.375,
      price_pc_1m: -1.29,
      price_pc_6m: 33.043,
      price_pc_1y: -5.556,
      price_pc_ytd: -5.556,
    },
    8121: {
      id: "8121",
      c: 0.143,
      ma50: 0.15,
      ma100: 0.193,
      ma150: 0.239,
      ma200: 0.332,
      maw30: 0.24,
      rs: 8.0,
      rsd1d: 2.0,
      rsd5d: -1.0,
      rsd10d: -4.0,
      price_5d_h: 0.158,
      price_5d_L: 0.129,
      price_1m_h: 0.2,
      price_1m_l: 0.112,
      price_3m_h: 0.219,
      price_3m_l: 0.112,
      price_6m_h: 0.365,
      price_6m_l: 0.112,
      price_52w_h: 1.44,
      price_52w_l: 0.112,
      price_pc_1d: 2.878,
      price_pc_1w: -9.494,
      price_pc_1m: 10.0,
      price_pc_6m: -59.718,
      price_pc_1y: -42.8,
      price_pc_ytd: -42.8,
    },
    8123: {
      id: "8123",
      c: 0.011,
      ma50: 0.012,
      ma100: 0.013,
      ma150: 0.013,
      ma200: 0.015,
      maw30: 0.013,
      rs: 27.0,
      rsd1d: 4.0,
      rsd5d: 4.0,
      rsd10d: 4.0,
      price_5d_h: 0.012,
      price_5d_L: 0.01,
      price_1m_h: 0.012,
      price_1m_l: 0.01,
      price_3m_h: 0.013,
      price_3m_l: 0.01,
      price_6m_h: 0.023,
      price_6m_l: 0.01,
      price_52w_h: 0.03,
      price_52w_l: 0.01,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: -8.333,
      price_pc_6m: -26.667,
      price_pc_1y: -38.889,
      price_pc_ytd: -38.889,
    },
    8125: {
      id: "8125",
      c: 0.172,
      ma50: 0.177,
      ma100: 0.182,
      ma150: 0.185,
      ma200: 0.187,
      maw30: 0.186,
      rs: 43.0,
      rsd1d: 19.0,
      rsd5d: 14.0,
      rsd10d: 23.0,
      price_5d_h: 0.172,
      price_5d_L: 0.16,
      price_1m_h: 0.172,
      price_1m_l: 0.151,
      price_3m_h: 0.237,
      price_3m_l: 0.151,
      price_6m_h: 0.237,
      price_6m_l: 0.151,
      price_52w_h: 0.315,
      price_52w_l: 0.135,
      price_pc_1d: 1.176,
      price_pc_1w: 0.585,
      price_pc_1m: 1.176,
      price_pc_6m: -11.34,
      price_pc_1y: -45.397,
      price_pc_ytd: -45.397,
    },
    8128: {
      id: "8128",
      c: 0.07,
      ma50: 0.082,
      ma100: 0.086,
      ma150: 0.085,
      ma200: 0.082,
      maw30: 0.087,
      rs: 41.0,
      rsd1d: -15.0,
      rsd5d: -7.0,
      rsd10d: -22.0,
      price_5d_h: 0.075,
      price_5d_L: 0.07,
      price_1m_h: 0.081,
      price_1m_l: 0.07,
      price_3m_h: 0.101,
      price_3m_l: 0.067,
      price_6m_h: 0.106,
      price_6m_l: 0.067,
      price_52w_h: 0.106,
      price_52w_l: 0.056,
      price_pc_1d: -5.405,
      price_pc_1w: -2.778,
      price_pc_1m: -10.256,
      price_pc_6m: -4.11,
      price_pc_1y: -4.11,
      price_pc_ytd: -4.11,
    },
    8132: {
      id: "8132",
      c: 0.101,
      ma50: 0.223,
      ma100: 0.299,
      ma150: 0.333,
      ma200: 0.341,
      maw30: 0.334,
      rs: 3.0,
      rsd1d: 1.0,
      rsd5d: -4.0,
      rsd10d: -26.0,
      price_5d_h: 0.124,
      price_5d_L: 0.071,
      price_1m_h: 0.22,
      price_1m_l: 0.071,
      price_3m_h: 0.35,
      price_3m_l: 0.071,
      price_6m_h: 0.5,
      price_6m_l: 0.071,
      price_52w_h: 0.79,
      price_52w_l: 0.071,
      price_pc_1d: 16.092,
      price_pc_1w: -17.886,
      price_pc_1m: -56.466,
      price_pc_6m: -75.952,
      price_pc_1y: -35.256,
      price_pc_ytd: -35.256,
    },
    8137: {
      id: "8137",
      c: 0.285,
      ma50: 0.318,
      ma100: 0.341,
      ma150: 0.391,
      ma200: 0.422,
      maw30: 0.387,
      rs: 21.0,
      rsd1d: 2.0,
      rsd5d: -3.0,
      rsd10d: 1.0,
      price_5d_h: 0.31,
      price_5d_L: 0.28,
      price_1m_h: 0.38,
      price_1m_l: 0.237,
      price_3m_h: 0.38,
      price_3m_l: 0.237,
      price_6m_h: 0.53,
      price_6m_l: 0.237,
      price_52w_h: 0.66,
      price_52w_l: 0.237,
      price_pc_1d: -1.724,
      price_pc_1w: -5.0,
      price_pc_1m: -12.308,
      price_pc_6m: -40.625,
      price_pc_1y: -36.667,
      price_pc_ytd: -36.667,
    },
    8139: {
      id: "8139",
      c: 1.69,
      ma50: 1.761,
      ma100: 1.845,
      ma150: 1.842,
      ma200: 1.878,
      maw30: 1.843,
      rs: 41.0,
      rsd1d: 4.0,
      rsd5d: 0.0,
      rsd10d: 4.0,
      price_5d_h: 1.7,
      price_5d_L: 1.43,
      price_1m_h: 1.76,
      price_1m_l: 1.43,
      price_3m_h: 2.15,
      price_3m_l: 1.43,
      price_6m_h: 2.48,
      price_6m_l: 1.43,
      price_52w_h: 2.48,
      price_52w_l: 1.43,
      price_pc_1d: 1.198,
      price_pc_1w: -1.744,
      price_pc_1m: -5.056,
      price_pc_6m: -8.152,
      price_pc_1y: -15.5,
      price_pc_ytd: -15.5,
    },
    8146: {
      id: "8146",
      c: 0.172,
      ma50: 0.18,
      ma100: 0.185,
      ma150: 0.186,
      ma200: 0.187,
      maw30: 0.187,
      rs: 63.0,
      rsd1d: -4.0,
      rsd5d: -7.0,
      rsd10d: 1.0,
      price_5d_h: 0.172,
      price_5d_L: 0.172,
      price_1m_h: 0.197,
      price_1m_l: 0.147,
      price_3m_h: 0.198,
      price_3m_l: 0.147,
      price_6m_h: 0.22,
      price_6m_l: 0.147,
      price_52w_h: 0.305,
      price_52w_l: 0.147,
      price_pc_1d: 0.0,
      price_pc_1w: -6.011,
      price_pc_1m: -12.69,
      price_pc_6m: -4.972,
      price_pc_1y: 0.585,
      price_pc_ytd: 0.585,
    },
    8147: {
      id: "8147",
      c: 0.037,
      ma50: 0.034,
      ma100: 0.034,
      ma150: 0.034,
      ma200: 0.039,
      maw30: 0.034,
      rs: 16.0,
      rsd1d: -25.0,
      rsd5d: -16.0,
      rsd10d: -21.0,
      price_5d_h: 0.042,
      price_5d_L: 0.033,
      price_1m_h: 0.043,
      price_1m_l: 0.026,
      price_3m_h: 0.058,
      price_3m_l: 0.026,
      price_6m_h: 0.095,
      price_6m_l: 0.026,
      price_52w_h: 0.15,
      price_52w_l: 0.026,
      price_pc_1d: -2.632,
      price_pc_1w: 5.714,
      price_pc_1m: 8.824,
      price_pc_6m: 15.625,
      price_pc_1y: -52.564,
      price_pc_ytd: -52.564,
    },
    8152: {
      id: "8152",
      c: 0.076,
      ma50: 0.077,
      ma100: 0.08,
      ma150: 0.092,
      ma200: 0.092,
      maw30: 0.09,
      rs: 53.0,
      rsd1d: 17.0,
      rsd5d: 20.0,
      rsd10d: 18.0,
      price_5d_h: 0.078,
      price_5d_L: 0.061,
      price_1m_h: 0.078,
      price_1m_l: 0.06,
      price_3m_h: 0.09,
      price_3m_l: 0.06,
      price_6m_h: 0.111,
      price_6m_l: 0.06,
      price_52w_h: 0.21,
      price_52w_l: 0.06,
      price_pc_1d: 8.571,
      price_pc_1w: 8.571,
      price_pc_1m: -8.434,
      price_pc_6m: -25.49,
      price_pc_1y: -5.0,
      price_pc_ytd: -5.0,
    },
    8153: {
      id: "8153",
      c: 0.06,
      ma50: 0.062,
      ma100: 0.066,
      ma150: 0.069,
      ma200: 0.071,
      maw30: 0.069,
      rs: 34.0,
      rsd1d: 3.0,
      rsd5d: -1.0,
      rsd10d: -20.0,
      price_5d_h: 0.066,
      price_5d_L: 0.059,
      price_1m_h: 0.08,
      price_1m_l: 0.059,
      price_3m_h: 0.081,
      price_3m_l: 0.051,
      price_6m_h: 0.084,
      price_6m_l: 0.051,
      price_52w_h: 0.112,
      price_52w_l: 0.051,
      price_pc_1d: 0.0,
      price_pc_1w: -4.762,
      price_pc_1m: -17.808,
      price_pc_6m: -25.0,
      price_pc_1y: -44.444,
      price_pc_ytd: -44.444,
    },
    8156: {
      id: "8156",
      c: 0.103,
      ma50: 0.118,
      ma100: 0.153,
      ma150: 0.173,
      ma200: 0.191,
      maw30: 0.172,
      rs: 8.0,
      rsd1d: 1.0,
      rsd5d: -1.0,
      rsd10d: -4.0,
      price_5d_h: 0.114,
      price_5d_L: 0.1,
      price_1m_h: 0.132,
      price_1m_l: 0.098,
      price_3m_h: 0.17,
      price_3m_l: 0.098,
      price_6m_h: 0.26,
      price_6m_l: 0.098,
      price_52w_h: 0.345,
      price_52w_l: 0.098,
      price_pc_1d: -1.905,
      price_pc_1w: -1.905,
      price_pc_1m: -8.036,
      price_pc_6m: -54.626,
      price_pc_1y: -58.3,
      price_pc_ytd: -58.3,
    },
    8158: {
      id: "8158",
      c: 0.23,
      ma50: 0.25,
      ma100: 0.263,
      ma150: 0.265,
      ma200: 0.291,
      maw30: 0.262,
      rs: 22.0,
      rsd1d: 2.0,
      rsd5d: 1.0,
      rsd10d: -6.0,
      price_5d_h: 0.25,
      price_5d_L: 0.22,
      price_1m_h: 0.26,
      price_1m_l: 0.2,
      price_3m_h: 0.315,
      price_3m_l: 0.2,
      price_6m_h: 0.34,
      price_6m_l: 0.2,
      price_52w_h: 0.58,
      price_52w_l: 0.2,
      price_pc_1d: 0.0,
      price_pc_1w: -2.542,
      price_pc_1m: -11.538,
      price_pc_6m: -14.815,
      price_pc_1y: -54.902,
      price_pc_ytd: -54.902,
    },
    8159: {
      id: "8159",
      c: 0.23,
      ma50: 0.144,
      ma100: 0.136,
      ma150: 0.136,
      ma200: 0.135,
      maw30: 0.134,
      rs: 96.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 2.0,
      price_5d_h: 0.234,
      price_5d_L: 0.185,
      price_1m_h: 0.245,
      price_1m_l: 0.107,
      price_3m_h: 0.245,
      price_3m_l: 0.106,
      price_6m_h: 0.245,
      price_6m_l: 0.1,
      price_52w_h: 0.245,
      price_52w_l: 0.1,
      price_pc_1d: 0.877,
      price_pc_1w: -0.433,
      price_pc_1m: 114.953,
      price_pc_6m: 91.667,
      price_pc_1y: 48.387,
      price_pc_ytd: 48.387,
    },
    8160: {
      id: "8160",
      c: 0.065,
      ma50: 0.075,
      ma100: 0.073,
      ma150: 0.074,
      ma200: 0.079,
      maw30: 0.074,
      rs: 32.0,
      rsd1d: 4.0,
      rsd5d: -19.0,
      rsd10d: 9.0,
      price_5d_h: 0.07,
      price_5d_L: 0.064,
      price_1m_h: 0.085,
      price_1m_l: 0.061,
      price_3m_h: 0.097,
      price_3m_l: 0.047,
      price_6m_h: 0.097,
      price_6m_l: 0.047,
      price_52w_h: 0.63,
      price_52w_l: 0.047,
      price_pc_1d: 0.0,
      price_pc_1w: -7.143,
      price_pc_1m: -17.722,
      price_pc_6m: -14.474,
      price_pc_1y: -87.255,
      price_pc_ytd: -87.255,
    },
    8166: {
      id: "8166",
      c: 0.189,
      ma50: 0.218,
      ma100: 0.279,
      ma150: 0.342,
      ma200: 0.355,
      maw30: 0.344,
      rs: 8.0,
      rsd1d: 2.0,
      rsd5d: 4.0,
      rsd10d: 0.0,
      price_5d_h: 0.19,
      price_5d_L: 0.166,
      price_1m_h: 0.195,
      price_1m_l: 0.15,
      price_3m_h: 0.365,
      price_3m_l: 0.15,
      price_6m_h: 0.62,
      price_6m_l: 0.15,
      price_52w_h: 0.62,
      price_52w_l: 0.15,
      price_pc_1d: 1.07,
      price_pc_1w: 11.176,
      price_pc_1m: 2.162,
      price_pc_6m: -65.636,
      price_pc_1y: -58.0,
      price_pc_ytd: -58.0,
    },
    8167: {
      id: "8167",
      c: 0.185,
      ma50: 0.188,
      ma100: 0.175,
      ma150: 0.169,
      ma200: 0.169,
      maw30: 0.17,
      rs: 83.0,
      rsd1d: -2.0,
      rsd5d: 0.0,
      rsd10d: -7.0,
      price_5d_h: 0.195,
      price_5d_L: 0.174,
      price_1m_h: 0.214,
      price_1m_l: 0.165,
      price_3m_h: 0.235,
      price_3m_l: 0.15,
      price_6m_h: 0.235,
      price_6m_l: 0.11,
      price_52w_h: 0.235,
      price_52w_l: 0.11,
      price_pc_1d: -2.116,
      price_pc_1w: 2.778,
      price_pc_1m: -1.07,
      price_pc_6m: 19.355,
      price_pc_1y: 0.543,
      price_pc_ytd: 0.543,
    },
    8170: {
      id: "8170",
      c: 3.21,
      ma50: 2.791,
      ma100: 2.9,
      ma150: 2.5,
      ma200: 2.088,
      maw30: 2.497,
      rs: 98.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 3.3,
      price_5d_L: 3.06,
      price_1m_h: 3.3,
      price_1m_l: 2.9,
      price_3m_h: 3.3,
      price_3m_l: 0.93,
      price_6m_h: 3.46,
      price_6m_l: 0.93,
      price_52w_h: 3.46,
      price_52w_l: 0.51,
      price_pc_1d: 0.312,
      price_pc_1w: 1.582,
      price_pc_1m: 2.885,
      price_pc_6m: 136.029,
      price_pc_1y: 36.596,
      price_pc_ytd: 36.596,
    },
    8172: {
      id: "8172",
      c: 0.205,
      ma50: 0.204,
      ma100: 0.237,
      ma150: 0.245,
      ma200: 0.257,
      maw30: 0.243,
      rs: 29.0,
      rsd1d: -11.0,
      rsd5d: -3.0,
      rsd10d: -31.0,
      price_5d_h: 0.25,
      price_5d_L: 0.171,
      price_1m_h: 0.265,
      price_1m_l: 0.16,
      price_3m_h: 0.265,
      price_3m_l: 0.113,
      price_6m_h: 0.385,
      price_6m_l: 0.113,
      price_52w_h: 0.53,
      price_52w_l: 0.113,
      price_pc_1d: -9.692,
      price_pc_1w: 3.015,
      price_pc_1m: 2.5,
      price_pc_6m: -10.87,
      price_pc_1y: -41.429,
      price_pc_ytd: -41.429,
    },
    8178: {
      id: "8178",
      c: 0.185,
      ma50: 0.215,
      ma100: 0.235,
      ma150: 0.252,
      ma200: 0.274,
      maw30: 0.254,
      rs: 20.0,
      rsd1d: 5.0,
      rsd5d: 11.0,
      rsd10d: 9.0,
      price_5d_h: 0.186,
      price_5d_L: 0.158,
      price_1m_h: 0.22,
      price_1m_l: 0.12,
      price_3m_h: 0.355,
      price_3m_l: 0.12,
      price_6m_h: 0.355,
      price_6m_l: 0.12,
      price_52w_h: 0.455,
      price_52w_l: 0.12,
      price_pc_1d: 5.714,
      price_pc_1w: 20.915,
      price_pc_1m: -15.909,
      price_pc_6m: -35.088,
      price_pc_1y: -23.868,
      price_pc_ytd: -23.868,
    },
    8181: {
      id: "8181",
      c: 0.096,
      ma50: 0.1,
      ma100: 0.115,
      ma150: 0.13,
      ma200: 0.144,
      maw30: 0.128,
      rs: 19.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: 3.0,
      price_5d_h: 0.1,
      price_5d_L: 0.086,
      price_1m_h: 0.105,
      price_1m_l: 0.08,
      price_3m_h: 0.113,
      price_3m_l: 0.08,
      price_6m_h: 0.155,
      price_6m_l: 0.08,
      price_52w_h: 0.27,
      price_52w_l: 0.08,
      price_pc_1d: -4.0,
      price_pc_1w: -4.0,
      price_pc_1m: -8.571,
      price_pc_6m: -38.065,
      price_pc_1y: -49.474,
      price_pc_ytd: -49.474,
    },
    8186: {
      id: "8186",
      c: 0.54,
      ma50: 0.636,
      ma100: 0.768,
      ma150: 0.812,
      ma200: 0.834,
      maw30: 0.816,
      rs: 13.0,
      rsd1d: 3.0,
      rsd5d: 5.0,
      rsd10d: 3.0,
      price_5d_h: 0.59,
      price_5d_L: 0.33,
      price_1m_h: 0.59,
      price_1m_l: 0.33,
      price_3m_h: 0.9,
      price_3m_l: 0.33,
      price_6m_h: 0.9,
      price_6m_l: 0.33,
      price_52w_h: 0.9,
      price_52w_l: 0.33,
      price_pc_1d: 5.882,
      price_pc_1w: 12.5,
      price_pc_1m: -1.818,
      price_pc_6m: -40.0,
      price_pc_1y: -40.0,
      price_pc_ytd: -40.0,
    },
    8187: {
      id: "8187",
      c: 0.06,
      ma50: 0.068,
      ma100: 0.15,
      ma150: 0.207,
      ma200: 0.212,
      maw30: 0.206,
      rs: 2.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.068,
      price_5d_L: 0.053,
      price_1m_h: 0.068,
      price_1m_l: 0.04,
      price_3m_h: 0.35,
      price_3m_l: 0.04,
      price_6m_h: 0.9,
      price_6m_l: 0.04,
      price_52w_h: 0.9,
      price_52w_l: 0.04,
      price_pc_1d: 0.0,
      price_pc_1w: 3.448,
      price_pc_1m: 5.263,
      price_pc_6m: -79.31,
      price_pc_1y: -82.857,
      price_pc_ytd: -82.857,
    },
    8191: {
      id: "8191",
      c: 0.89,
      ma50: 0.938,
      ma100: 1.169,
      ma150: 1.295,
      ma200: 1.532,
      maw30: 1.284,
      rs: 87.0,
      rsd1d: 1.0,
      rsd5d: -8.0,
      rsd10d: -9.0,
      price_5d_h: 1.0,
      price_5d_L: 0.82,
      price_1m_h: 1.02,
      price_1m_l: 0.65,
      price_3m_h: 1.42,
      price_3m_l: 0.65,
      price_6m_h: 3.32,
      price_6m_l: 0.65,
      price_52w_h: 3.62,
      price_52w_l: 0.167,
      price_pc_1d: 0.0,
      price_pc_1w: -11.0,
      price_pc_1m: -6.316,
      price_pc_6m: -36.429,
      price_pc_1y: 212.281,
      price_pc_ytd: 212.281,
    },
    8193: {
      id: "8193",
      c: 0.064,
      ma50: 0.062,
      ma100: 0.063,
      ma150: 0.067,
      ma200: 0.064,
      maw30: 0.067,
      rs: 64.0,
      rsd1d: 28.0,
      rsd5d: 39.0,
      rsd10d: 6.0,
      price_5d_h: 0.069,
      price_5d_L: 0.051,
      price_1m_h: 0.07,
      price_1m_l: 0.051,
      price_3m_h: 0.07,
      price_3m_l: 0.051,
      price_6m_h: 0.083,
      price_6m_l: 0.051,
      price_52w_h: 0.162,
      price_52w_l: 0.041,
      price_pc_1d: 14.286,
      price_pc_1w: 20.755,
      price_pc_1m: -1.538,
      price_pc_6m: -3.03,
      price_pc_1y: -33.333,
      price_pc_ytd: -33.333,
    },
    8196: {
      id: "8196",
      c: 0.53,
      ma50: 0.49,
      ma100: 0.411,
      ma150: 0.347,
      ma200: 0.314,
      maw30: 0.343,
      rs: 97.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.6,
      price_5d_L: 0.5,
      price_1m_h: 0.7,
      price_1m_l: 0.34,
      price_3m_h: 0.82,
      price_3m_l: 0.27,
      price_6m_h: 0.82,
      price_6m_l: 0.2,
      price_52w_h: 0.82,
      price_52w_l: 0.11,
      price_pc_1d: -3.636,
      price_pc_1w: 6.0,
      price_pc_1m: 45.205,
      price_pc_6m: 152.381,
      price_pc_1y: 65.625,
      price_pc_ytd: 65.625,
    },
    8198: {
      id: "8198",
      c: 0.38,
      ma50: 0.4,
      ma100: 0.392,
      ma150: 0.406,
      ma200: 0.452,
      maw30: 0.406,
      rs: 23.0,
      rsd1d: -2.0,
      rsd5d: -10.0,
      rsd10d: -5.0,
      price_5d_h: 0.41,
      price_5d_L: 0.315,
      price_1m_h: 0.42,
      price_1m_l: 0.315,
      price_3m_h: 0.59,
      price_3m_l: 0.315,
      price_6m_h: 0.86,
      price_6m_l: 0.25,
      price_52w_h: 0.86,
      price_52w_l: 0.25,
      price_pc_1d: -5.0,
      price_pc_1w: -7.317,
      price_pc_1m: -6.173,
      price_pc_6m: -30.909,
      price_pc_1y: -51.282,
      price_pc_ytd: -51.282,
    },
    8200: {
      id: "8200",
      c: 1.28,
      ma50: 1.33,
      ma100: 1.391,
      ma150: 1.468,
      ma200: 1.525,
      maw30: 1.464,
      rs: 46.0,
      rsd1d: 4.0,
      rsd5d: -2.0,
      rsd10d: 0.0,
      price_5d_h: 1.28,
      price_5d_L: 1.09,
      price_1m_h: 1.44,
      price_1m_l: 1.09,
      price_3m_h: 1.52,
      price_3m_l: 1.09,
      price_6m_h: 1.76,
      price_6m_l: 1.09,
      price_52w_h: 2.38,
      price_52w_l: 1.09,
      price_pc_1d: 0.0,
      price_pc_1w: 2.4,
      price_pc_1m: -11.111,
      price_pc_6m: -15.789,
      price_pc_1y: -12.925,
      price_pc_ytd: -12.925,
    },
    8201: {
      id: "8201",
      c: 0.088,
      ma50: 0.094,
      ma100: 0.099,
      ma150: 0.097,
      ma200: 0.097,
      maw30: 0.096,
      rs: 62.0,
      rsd1d: 3.0,
      rsd5d: 23.0,
      rsd10d: 18.0,
      price_5d_h: 0.098,
      price_5d_L: 0.088,
      price_1m_h: 0.098,
      price_1m_l: 0.082,
      price_3m_h: 0.114,
      price_3m_l: 0.082,
      price_6m_h: 0.128,
      price_6m_l: 0.077,
      price_52w_h: 0.133,
      price_52w_l: 0.076,
      price_pc_1d: 0.0,
      price_pc_1w: 1.149,
      price_pc_1m: -8.333,
      price_pc_6m: 8.642,
      price_pc_1y: -16.19,
      price_pc_ytd: -16.19,
    },
    8203: {
      id: "8203",
      c: 0.295,
      ma50: 0.287,
      ma100: 0.294,
      ma150: 0.292,
      ma200: 0.268,
      maw30: 0.296,
      rs: 95.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: -1.0,
      price_5d_h: 0.3,
      price_5d_L: 0.245,
      price_1m_h: 0.32,
      price_1m_l: 0.199,
      price_3m_h: 0.32,
      price_3m_l: 0.199,
      price_6m_h: 0.35,
      price_6m_l: 0.199,
      price_52w_h: 0.35,
      price_52w_l: 0.085,
      price_pc_1d: -1.667,
      price_pc_1w: -1.667,
      price_pc_1m: 1.724,
      price_pc_6m: 3.509,
      price_pc_1y: 227.778,
      price_pc_ytd: 227.778,
    },
    8208: {
      id: "8208",
      c: 0.068,
      ma50: 0.069,
      ma100: 0.082,
      ma150: 0.098,
      ma200: 0.149,
      maw30: 0.097,
      rs: 11.0,
      rsd1d: 2.0,
      rsd5d: 1.0,
      rsd10d: -1.0,
      price_5d_h: 0.072,
      price_5d_L: 0.066,
      price_1m_h: 0.072,
      price_1m_l: 0.062,
      price_3m_h: 0.088,
      price_3m_l: 0.06,
      price_6m_h: 0.149,
      price_6m_l: 0.06,
      price_52w_h: 0.65,
      price_52w_l: 0.06,
      price_pc_1d: 0.0,
      price_pc_1w: -2.857,
      price_pc_1m: -1.449,
      price_pc_6m: -51.773,
      price_pc_1y: -51.773,
      price_pc_ytd: -51.773,
    },
    8210: {
      id: "8210",
      c: 0.047,
      ma50: 0.054,
      ma100: 0.057,
      ma150: 0.058,
      ma200: 0.057,
      maw30: 0.058,
      rs: 37.0,
      rsd1d: 1.0,
      rsd5d: 4.0,
      rsd10d: 6.0,
      price_5d_h: 0.049,
      price_5d_L: 0.044,
      price_1m_h: 0.057,
      price_1m_l: 0.043,
      price_3m_h: 0.068,
      price_3m_l: 0.043,
      price_6m_h: 0.069,
      price_6m_l: 0.043,
      price_52w_h: 0.069,
      price_52w_l: 0.043,
      price_pc_1d: 0.0,
      price_pc_1w: 2.174,
      price_pc_1m: -17.544,
      price_pc_6m: -25.397,
      price_pc_1y: -12.963,
      price_pc_ytd: -12.963,
    },
    8211: {
      id: "8211",
      c: 0.06,
      ma50: 0.058,
      ma100: 0.061,
      ma150: 0.063,
      ma200: 0.069,
      maw30: 0.062,
      rs: 33.0,
      rsd1d: 12.0,
      rsd5d: 6.0,
      rsd10d: 6.0,
      price_5d_h: 0.06,
      price_5d_L: 0.055,
      price_1m_h: 0.061,
      price_1m_l: 0.055,
      price_3m_h: 0.089,
      price_3m_l: 0.052,
      price_6m_h: 0.089,
      price_6m_l: 0.052,
      price_52w_h: 0.111,
      price_52w_l: 0.052,
      price_pc_1d: 7.143,
      price_pc_1w: 9.091,
      price_pc_1m: -9.091,
      price_pc_6m: -7.692,
      price_pc_1y: -45.455,
      price_pc_ytd: -45.455,
    },
    8217: {
      id: "8217",
      c: 0.167,
      ma50: 0.18,
      ma100: 0.195,
      ma150: 0.222,
      ma200: 0.239,
      maw30: 0.221,
      rs: 13.0,
      rsd1d: 2.0,
      rsd5d: -1.0,
      rsd10d: -4.0,
      price_5d_h: 0.169,
      price_5d_L: 0.164,
      price_1m_h: 0.184,
      price_1m_l: 0.139,
      price_3m_h: 0.243,
      price_3m_l: 0.139,
      price_6m_h: 0.33,
      price_6m_l: 0.139,
      price_52w_h: 0.525,
      price_52w_l: 0.139,
      price_pc_1d: -1.183,
      price_pc_1w: 3.727,
      price_pc_1m: -2.339,
      price_pc_6m: -41.404,
      price_pc_1y: -57.775,
      price_pc_ytd: -57.775,
    },
    8218: {
      id: "8218",
      c: 0.132,
      ma50: 0.196,
      ma100: 0.229,
      ma150: 0.252,
      ma200: 0.276,
      maw30: 0.255,
      rs: 4.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: -1.0,
      price_5d_h: 0.177,
      price_5d_L: 0.121,
      price_1m_h: 0.204,
      price_1m_l: 0.12,
      price_3m_h: 0.41,
      price_3m_l: 0.12,
      price_6m_h: 0.41,
      price_6m_l: 0.12,
      price_52w_h: 0.49,
      price_52w_l: 0.12,
      price_pc_1d: -7.692,
      price_pc_1w: 10.0,
      price_pc_1m: -35.294,
      price_pc_6m: -58.75,
      price_pc_1y: -64.324,
      price_pc_ytd: -64.324,
    },
    8219: {
      id: "8219",
      c: 0.435,
      ma50: 0.318,
      ma100: 0.304,
      ma150: 0.362,
      ma200: 0.452,
      maw30: 0.359,
      rs: 87.0,
      rsd1d: -1.0,
      rsd5d: 37.0,
      rsd10d: 67.0,
      price_5d_h: 0.45,
      price_5d_L: 0.395,
      price_1m_h: 0.45,
      price_1m_l: 0.265,
      price_3m_h: 0.45,
      price_3m_l: 0.26,
      price_6m_h: 0.61,
      price_6m_l: 0.238,
      price_52w_h: 2.19,
      price_52w_l: 0.238,
      price_pc_1d: 0.0,
      price_pc_1w: 8.75,
      price_pc_1m: 52.632,
      price_pc_6m: -7.447,
      price_pc_1y: -36.029,
      price_pc_ytd: -36.029,
    },
    8220: {
      id: "8220",
      c: 0.265,
      ma50: 0.095,
      ma100: 0.064,
      ma150: 0.062,
      ma200: 0.078,
      maw30: 0.056,
      rs: 99.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.98,
      price_5d_L: 0.214,
      price_1m_h: 0.98,
      price_1m_l: 0.02,
      price_3m_h: 0.98,
      price_3m_l: 0.02,
      price_6m_h: 0.98,
      price_6m_l: 0.02,
      price_52w_h: 0.98,
      price_52w_l: 0.02,
      price_pc_1d: -7.018,
      price_pc_1w: 23.832,
      price_pc_1m: 1004.167,
      price_pc_6m: 419.608,
      price_pc_1y: 191.209,
      price_pc_ytd: 191.209,
    },
    8223: {
      id: "8223",
      c: 2.34,
      ma50: 2.436,
      ma100: 2.301,
      ma150: 2.244,
      ma200: 2.103,
      maw30: 2.253,
      rs: 93.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: -2.0,
      price_5d_h: 2.5,
      price_5d_L: 2.34,
      price_1m_h: 2.55,
      price_1m_l: 2.27,
      price_3m_h: 2.57,
      price_3m_l: 2.21,
      price_6m_h: 2.57,
      price_6m_l: 1.71,
      price_52w_h: 2.57,
      price_52w_l: 1.01,
      price_pc_1d: -4.098,
      price_pc_1w: -5.263,
      price_pc_1m: -4.878,
      price_pc_6m: 6.849,
      price_pc_1y: 112.727,
      price_pc_ytd: 112.727,
    },
    8225: {
      id: "8225",
      c: 0.375,
      ma50: 0.362,
      ma100: 0.387,
      ma150: 0.412,
      ma200: 0.41,
      maw30: 0.416,
      rs: 50.0,
      rsd1d: -3.0,
      rsd5d: -30.0,
      rsd10d: -37.0,
      price_5d_h: 0.445,
      price_5d_L: 0.375,
      price_1m_h: 0.475,
      price_1m_l: 0.315,
      price_3m_h: 0.475,
      price_3m_l: 0.3,
      price_6m_h: 0.51,
      price_6m_l: 0.3,
      price_52w_h: 0.53,
      price_52w_l: 0.3,
      price_pc_1d: -2.597,
      price_pc_1w: -15.73,
      price_pc_1m: 10.294,
      price_pc_6m: -25.0,
      price_pc_1y: -21.875,
      price_pc_ytd: -21.875,
    },
    8227: {
      id: "8227",
      c: 0.68,
      ma50: 0.847,
      ma100: 1.108,
      ma150: 1.227,
      ma200: 1.328,
      maw30: 1.224,
      rs: 6.0,
      rsd1d: 2.0,
      rsd5d: 2.0,
      rsd10d: 1.0,
      price_5d_h: 0.7,
      price_5d_L: 0.55,
      price_1m_h: 0.8,
      price_1m_l: 0.55,
      price_3m_h: 1.4,
      price_3m_l: 0.55,
      price_6m_h: 1.5,
      price_6m_l: 0.55,
      price_52w_h: 2.0,
      price_52w_l: 0.55,
      price_pc_1d: 0.0,
      price_pc_1w: 6.25,
      price_pc_1m: -12.821,
      price_pc_6m: -53.425,
      price_pc_1y: -57.5,
      price_pc_ytd: -57.5,
    },
    8228: {
      id: "8228",
      c: 0.044,
      ma50: 0.072,
      ma100: 0.085,
      ma150: 0.09,
      ma200: 0.126,
      maw30: 0.089,
      rs: 3.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.052,
      price_5d_L: 0.041,
      price_1m_h: 0.09,
      price_1m_l: 0.04,
      price_3m_h: 0.135,
      price_3m_l: 0.04,
      price_6m_h: 0.169,
      price_6m_l: 0.04,
      price_52w_h: 0.7,
      price_52w_l: 0.04,
      price_pc_1d: 0.0,
      price_pc_1w: -12.0,
      price_pc_1m: -51.111,
      price_pc_6m: -51.111,
      price_pc_1y: -92.787,
      price_pc_ytd: -92.787,
    },
    8232: {
      id: "8232",
      c: 0.052,
      ma50: 0.054,
      ma100: 0.056,
      ma150: 0.059,
      ma200: 0.06,
      maw30: 0.058,
      rs: 59.0,
      rsd1d: 13.0,
      rsd5d: 27.0,
      rsd10d: 25.0,
      price_5d_h: 0.06,
      price_5d_L: 0.052,
      price_1m_h: 0.06,
      price_1m_l: 0.045,
      price_3m_h: 0.06,
      price_3m_l: 0.041,
      price_6m_h: 0.079,
      price_6m_l: 0.04,
      price_52w_h: 0.135,
      price_52w_l: 0.04,
      price_pc_1d: 0.0,
      price_pc_1w: -1.887,
      price_pc_1m: 8.333,
      price_pc_6m: -28.767,
      price_pc_1y: -21.212,
      price_pc_ytd: -21.212,
    },
    8237: {
      id: "8237",
      c: 0.012,
      ma50: 0.015,
      ma100: 0.017,
      ma150: 0.02,
      ma200: 0.022,
      maw30: 0.02,
      rs: 11.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -2.0,
      price_5d_h: 0.014,
      price_5d_L: 0.012,
      price_1m_h: 0.02,
      price_1m_l: 0.012,
      price_3m_h: 0.02,
      price_3m_l: 0.011,
      price_6m_h: 0.036,
      price_6m_l: 0.011,
      price_52w_h: 0.055,
      price_52w_l: 0.011,
      price_pc_1d: -7.692,
      price_pc_1w: -7.692,
      price_pc_1m: -29.412,
      price_pc_6m: -52.0,
      price_pc_1y: -45.455,
      price_pc_ytd: -45.455,
    },
    8238: {
      id: "8238",
      c: 0.172,
      ma50: 0.131,
      ma100: 0.121,
      ma150: 0.12,
      ma200: 0.119,
      maw30: 0.12,
      rs: 96.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: 2.0,
      price_5d_h: 0.197,
      price_5d_L: 0.168,
      price_1m_h: 0.243,
      price_1m_l: 0.085,
      price_3m_h: 0.243,
      price_3m_l: 0.069,
      price_6m_h: 0.25,
      price_6m_l: 0.069,
      price_52w_h: 0.25,
      price_52w_l: 0.069,
      price_pc_1d: -8.511,
      price_pc_1w: -8.511,
      price_pc_1m: 89.011,
      price_pc_6m: 49.565,
      price_pc_1y: 49.565,
      price_pc_ytd: 49.565,
    },
    8245: {
      id: "8245",
      c: 0.07,
      ma50: 0.064,
      ma100: 0.064,
      ma150: 0.067,
      ma200: 0.07,
      maw30: 0.067,
      rs: 89.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: 10.0,
      price_5d_h: 0.075,
      price_5d_L: 0.064,
      price_1m_h: 0.08,
      price_1m_l: 0.051,
      price_3m_h: 0.08,
      price_3m_l: 0.04,
      price_6m_h: 0.09,
      price_6m_l: 0.04,
      price_52w_h: 0.09,
      price_52w_l: 0.04,
      price_pc_1d: -5.405,
      price_pc_1w: -9.091,
      price_pc_1m: 2.941,
      price_pc_6m: -5.405,
      price_pc_1y: -5.405,
      price_pc_ytd: -5.405,
    },
    8249: {
      id: "8249",
      c: 0.133,
      ma50: 0.11,
      ma100: 0.125,
      ma150: 0.135,
      ma200: 0.127,
      maw30: 0.131,
      rs: 91.0,
      rsd1d: 22.0,
      rsd5d: -4.0,
      rsd10d: 27.0,
      price_5d_h: 0.159,
      price_5d_L: 0.101,
      price_1m_h: 0.18,
      price_1m_l: 0.07,
      price_3m_h: 0.18,
      price_3m_l: 0.07,
      price_6m_h: 0.222,
      price_6m_l: 0.07,
      price_52w_h: 0.247,
      price_52w_l: 0.07,
      price_pc_1d: 30.392,
      price_pc_1w: -16.875,
      price_pc_1m: 15.652,
      price_pc_6m: 6.4,
      price_pc_1y: 56.471,
      price_pc_ytd: 56.471,
    },
    8250: {
      id: "8250",
      c: 0.018,
      ma50: 0.021,
      ma100: 0.02,
      ma150: 0.019,
      ma200: 0.019,
      maw30: 0.02,
      rs: 67.0,
      rsd1d: 24.0,
      rsd5d: 29.0,
      rsd10d: -4.0,
      price_5d_h: 0.024,
      price_5d_L: 0.015,
      price_1m_h: 0.024,
      price_1m_l: 0.014,
      price_3m_h: 0.028,
      price_3m_l: 0.014,
      price_6m_h: 0.028,
      price_6m_l: 0.014,
      price_52w_h: 0.039,
      price_52w_l: 0.014,
      price_pc_1d: 12.5,
      price_pc_1w: 20.0,
      price_pc_1m: -18.182,
      price_pc_6m: 0.0,
      price_pc_1y: -5.263,
      price_pc_ytd: -5.263,
    },
    8257: {
      id: "8257",
      c: 0.187,
      ma50: 0.176,
      ma100: 0.183,
      ma150: 0.191,
      ma200: 0.205,
      maw30: 0.19,
      rs: 53.0,
      rsd1d: 2.0,
      rsd5d: 13.0,
      rsd10d: 14.0,
      price_5d_h: 0.188,
      price_5d_L: 0.163,
      price_1m_h: 0.188,
      price_1m_l: 0.16,
      price_3m_h: 0.2,
      price_3m_l: 0.152,
      price_6m_h: 0.229,
      price_6m_l: 0.152,
      price_52w_h: 0.32,
      price_52w_l: 0.152,
      price_pc_1d: 0.0,
      price_pc_1w: 5.65,
      price_pc_1m: 5.65,
      price_pc_6m: -5.076,
      price_pc_1y: -38.689,
      price_pc_ytd: -38.689,
    },
    8275: {
      id: "8275",
      c: 0.75,
      ma50: 0.311,
      ma100: 0.244,
      ma150: 0.234,
      ma200: 0.233,
      maw30: 0.23,
      rs: 99.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.9,
      price_5d_L: 0.65,
      price_1m_h: 0.94,
      price_1m_l: 0.125,
      price_3m_h: 0.94,
      price_3m_l: 0.125,
      price_6m_h: 0.94,
      price_6m_l: 0.125,
      price_52w_h: 0.94,
      price_52w_l: 0.125,
      price_pc_1d: -12.791,
      price_pc_1w: 1.351,
      price_pc_1m: 447.445,
      price_pc_6m: 275.0,
      price_pc_1y: 188.462,
      price_pc_ytd: 188.462,
    },
    8277: {
      id: "8277",
      c: 0.222,
      ma50: 0.247,
      ma100: 0.234,
      ma150: 0.247,
      ma200: 0.275,
      maw30: 0.245,
      rs: 59.0,
      rsd1d: 2.0,
      rsd5d: -5.0,
      rsd10d: -9.0,
      price_5d_h: 0.222,
      price_5d_L: 0.222,
      price_1m_h: 0.235,
      price_1m_l: 0.222,
      price_3m_h: 0.31,
      price_3m_l: 0.21,
      price_6m_h: 0.35,
      price_6m_l: 0.163,
      price_52w_h: 0.5,
      price_52w_l: 0.163,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: -5.532,
      price_pc_6m: -11.2,
      price_pc_1y: 0.909,
      price_pc_ytd: 0.909,
    },
    8279: {
      id: "8279",
      c: 0.32,
      ma50: 0.236,
      ma100: 0.26,
      ma150: 0.271,
      ma200: 0.277,
      maw30: 0.272,
      rs: 89.0,
      rsd1d: -1.0,
      rsd5d: 14.0,
      rsd10d: 23.0,
      price_5d_h: 0.33,
      price_5d_L: 0.26,
      price_1m_h: 0.33,
      price_1m_l: 0.18,
      price_3m_h: 0.33,
      price_3m_l: 0.18,
      price_6m_h: 0.37,
      price_6m_l: 0.18,
      price_52w_h: 0.395,
      price_52w_l: 0.18,
      price_pc_1d: 1.587,
      price_pc_1w: 23.077,
      price_pc_1m: 53.11,
      price_pc_6m: 18.519,
      price_pc_1y: 55.34,
      price_pc_ytd: 55.34,
    },
    8280: {
      id: "8280",
      c: 0.103,
      ma50: 0.115,
      ma100: 0.114,
      ma150: 0.115,
      ma200: 0.114,
      maw30: 0.116,
      rs: 62.0,
      rsd1d: 1.0,
      rsd5d: -22.0,
      rsd10d: -24.0,
      price_5d_h: 0.13,
      price_5d_L: 0.102,
      price_1m_h: 0.13,
      price_1m_l: 0.099,
      price_3m_h: 0.21,
      price_3m_l: 0.099,
      price_6m_h: 0.21,
      price_6m_l: 0.098,
      price_52w_h: 0.21,
      price_52w_l: 0.086,
      price_pc_1d: 0.98,
      price_pc_1w: -11.207,
      price_pc_1m: -6.364,
      price_pc_6m: -16.935,
      price_pc_1y: -6.364,
      price_pc_ytd: -6.364,
    },
    8282: {
      id: "8282",
      c: 0.43,
      ma50: 0.572,
      ma100: 0.579,
      ma150: 0.54,
      ma200: 0.511,
      maw30: 0.549,
      rs: 69.0,
      rsd1d: -7.0,
      rsd5d: 28.0,
      rsd10d: 22.0,
      price_5d_h: 0.435,
      price_5d_L: 0.35,
      price_1m_h: 0.63,
      price_1m_l: 0.335,
      price_3m_h: 0.76,
      price_3m_l: 0.335,
      price_6m_h: 0.84,
      price_6m_l: 0.335,
      price_52w_h: 0.84,
      price_52w_l: 0.26,
      price_pc_1d: -3.371,
      price_pc_1w: 17.808,
      price_pc_1m: -27.119,
      price_pc_6m: 1.176,
      price_pc_1y: 19.444,
      price_pc_ytd: 19.444,
    },
    8283: {
      id: "8283",
      c: 0.14,
      ma50: 0.141,
      ma100: 0.161,
      ma150: 0.163,
      ma200: 0.16,
      maw30: 0.163,
      rs: 46.0,
      rsd1d: 3.0,
      rsd5d: 27.0,
      rsd10d: 17.0,
      price_5d_h: 0.145,
      price_5d_L: 0.125,
      price_1m_h: 0.152,
      price_1m_l: 0.091,
      price_3m_h: 0.175,
      price_3m_l: 0.091,
      price_6m_h: 0.28,
      price_6m_l: 0.091,
      price_52w_h: 0.28,
      price_52w_l: 0.091,
      price_pc_1d: 0.0,
      price_pc_1w: 25.0,
      price_pc_1m: -5.405,
      price_pc_6m: -8.497,
      price_pc_1y: -8.497,
      price_pc_ytd: -8.497,
    },
    8287: {
      id: "8287",
      c: 0.08,
      ma50: 0.085,
      ma100: 0.08,
      ma150: 0.09,
      ma200: 0.116,
      maw30: 0.087,
      rs: 36.0,
      rsd1d: 18.0,
      rsd5d: 6.0,
      rsd10d: 12.0,
      price_5d_h: 0.08,
      price_5d_L: 0.067,
      price_1m_h: 0.082,
      price_1m_l: 0.066,
      price_3m_h: 0.127,
      price_3m_l: 0.066,
      price_6m_h: 0.127,
      price_6m_l: 0.066,
      price_52w_h: 0.32,
      price_52w_l: 0.056,
      price_pc_1d: 15.942,
      price_pc_1w: 2.564,
      price_pc_1m: -3.014,
      price_pc_6m: -22.33,
      price_pc_1y: -33.333,
      price_pc_ytd: -33.333,
    },
    8292: {
      id: "8292",
      c: 0.89,
      ma50: 0.962,
      ma100: 0.666,
      ma150: 0.492,
      ma200: 0.407,
      maw30: 0.51,
      rs: 99.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.93,
      price_5d_L: 0.85,
      price_1m_h: 1.02,
      price_1m_l: 0.85,
      price_3m_h: 1.12,
      price_3m_l: 0.255,
      price_6m_h: 1.12,
      price_6m_l: 0.111,
      price_52w_h: 1.12,
      price_52w_l: 0.1,
      price_pc_1d: -2.198,
      price_pc_1w: 0.0,
      price_pc_1m: -11.0,
      price_pc_6m: 589.922,
      price_pc_1y: 391.713,
      price_pc_ytd: 391.713,
    },
    8293: {
      id: "8293",
      c: 0.049,
      ma50: 0.047,
      ma100: 0.051,
      ma150: 0.055,
      ma200: 0.054,
      maw30: 0.054,
      rs: 45.0,
      rsd1d: 8.0,
      rsd5d: 16.0,
      rsd10d: 0.0,
      price_5d_h: 0.052,
      price_5d_L: 0.04,
      price_1m_h: 0.052,
      price_1m_l: 0.036,
      price_3m_h: 0.063,
      price_3m_l: 0.036,
      price_6m_h: 0.079,
      price_6m_l: 0.036,
      price_52w_h: 0.079,
      price_52w_l: 0.036,
      price_pc_1d: 4.255,
      price_pc_1w: 11.364,
      price_pc_1m: -2.0,
      price_pc_6m: -18.333,
      price_pc_1y: -12.5,
      price_pc_ytd: -12.5,
    },
    8296: {
      id: "8296",
      c: 0.14,
      ma50: 0.159,
      ma100: 0.175,
      ma150: 0.179,
      ma200: 0.176,
      maw30: 0.18,
      rs: 40.0,
      rsd1d: 3.0,
      rsd5d: -3.0,
      rsd10d: -2.0,
      price_5d_h: 0.15,
      price_5d_L: 0.14,
      price_1m_h: 0.155,
      price_1m_l: 0.126,
      price_3m_h: 0.202,
      price_3m_l: 0.126,
      price_6m_h: 0.235,
      price_6m_l: 0.126,
      price_52w_h: 0.235,
      price_52w_l: 0.126,
      price_pc_1d: 0.0,
      price_pc_1w: -3.448,
      price_pc_1m: -6.04,
      price_pc_6m: -20.0,
      price_pc_1y: -12.5,
      price_pc_ytd: -12.5,
    },
    8297: {
      id: "8297",
      c: 0.199,
      ma50: 0.208,
      ma100: 0.283,
      ma150: 0.614,
      ma200: 0.735,
      maw30: 0.592,
      rs: 2.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.216,
      price_5d_L: 0.195,
      price_1m_h: 0.245,
      price_1m_l: 0.139,
      price_3m_h: 0.67,
      price_3m_l: 0.13,
      price_6m_h: 2.13,
      price_6m_l: 0.13,
      price_52w_h: 2.13,
      price_52w_l: 0.13,
      price_pc_1d: 0.505,
      price_pc_1w: -2.451,
      price_pc_1m: 32.667,
      price_pc_6m: -84.809,
      price_pc_1y: -82.544,
      price_pc_ytd: -82.544,
    },
    8299: {
      id: "8299",
      c: 0.039,
      ma50: 0.038,
      ma100: 0.039,
      ma150: 0.04,
      ma200: 0.042,
      maw30: 0.04,
      rs: 73.0,
      rsd1d: 6.0,
      rsd5d: 23.0,
      rsd10d: -1.0,
      price_5d_h: 0.04,
      price_5d_L: 0.036,
      price_1m_h: 0.049,
      price_1m_l: 0.034,
      price_3m_h: 0.052,
      price_3m_l: 0.034,
      price_6m_h: 0.058,
      price_6m_l: 0.034,
      price_52w_h: 0.08,
      price_52w_l: 0.032,
      price_pc_1d: 0.0,
      price_pc_1w: 5.405,
      price_pc_1m: 2.632,
      price_pc_6m: -2.5,
      price_pc_1y: -2.5,
      price_pc_ytd: -2.5,
    },
    8305: {
      id: "8305",
      c: 0.26,
      ma50: 0.286,
      ma100: 0.239,
      ma150: 0.208,
      ma200: 0.177,
      maw30: 0.212,
      rs: 98.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.29,
      price_5d_L: 0.26,
      price_1m_h: 0.3,
      price_1m_l: 0.243,
      price_3m_h: 0.37,
      price_3m_l: 0.18,
      price_6m_h: 0.37,
      price_6m_l: 0.116,
      price_52w_h: 0.37,
      price_52w_l: 0.045,
      price_pc_1d: -7.143,
      price_pc_1w: -5.455,
      price_pc_1m: -10.345,
      price_pc_6m: 120.339,
      price_pc_1y: 420.0,
      price_pc_ytd: 420.0,
    },
    8307: {
      id: "8307",
      c: 0.218,
      ma50: 0.238,
      ma100: 0.238,
      ma150: 0.243,
      ma200: 0.254,
      maw30: 0.235,
      rs: 64.0,
      rsd1d: -20.0,
      rsd5d: -14.0,
      rsd10d: -1.0,
      price_5d_h: 0.35,
      price_5d_L: 0.213,
      price_1m_h: 0.6,
      price_1m_l: 0.193,
      price_3m_h: 0.6,
      price_3m_l: 0.19,
      price_6m_h: 0.6,
      price_6m_l: 0.169,
      price_52w_h: 0.6,
      price_52w_l: 0.151,
      price_pc_1d: -12.8,
      price_pc_1w: 3.318,
      price_pc_1m: -22.143,
      price_pc_6m: 1.395,
      price_pc_1y: 6.341,
      price_pc_ytd: 6.341,
    },
    8309: {
      id: "8309",
      c: 0.09,
      ma50: 0.095,
      ma100: 0.104,
      ma150: 0.112,
      ma200: 0.118,
      maw30: 0.111,
      rs: 56.0,
      rsd1d: -6.0,
      rsd5d: -4.0,
      rsd10d: -7.0,
      price_5d_h: 0.095,
      price_5d_L: 0.09,
      price_1m_h: 0.098,
      price_1m_l: 0.088,
      price_3m_h: 0.107,
      price_3m_l: 0.088,
      price_6m_h: 0.128,
      price_6m_l: 0.088,
      price_52w_h: 0.155,
      price_52w_l: 0.062,
      price_pc_1d: -4.255,
      price_pc_1w: -6.25,
      price_pc_1m: -6.25,
      price_pc_6m: -26.829,
      price_pc_1y: 42.857,
      price_pc_ytd: 42.857,
    },
    8313: {
      id: "8313",
      c: 0.07,
      ma50: 0.043,
      ma100: 0.046,
      ma150: 0.053,
      ma200: 0.052,
      maw30: 0.052,
      rs: 93.0,
      rsd1d: 0.0,
      rsd5d: 7.0,
      rsd10d: 42.0,
      price_5d_h: 0.075,
      price_5d_L: 0.057,
      price_1m_h: 0.075,
      price_1m_l: 0.036,
      price_3m_h: 0.075,
      price_3m_l: 0.035,
      price_6m_h: 0.076,
      price_6m_l: 0.035,
      price_52w_h: 0.093,
      price_52w_l: 0.035,
      price_pc_1d: 1.449,
      price_pc_1w: 22.807,
      price_pc_1m: 94.444,
      price_pc_6m: -11.392,
      price_pc_1y: -1.408,
      price_pc_ytd: -1.408,
    },
    8315: {
      id: "8315",
      c: 0.59,
      ma50: 0.607,
      ma100: 0.524,
      ma150: 0.427,
      ma200: 0.392,
      maw30: 0.429,
      rs: 97.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: 1.0,
      price_5d_h: 0.6,
      price_5d_L: 0.5,
      price_1m_h: 0.66,
      price_1m_l: 0.5,
      price_3m_h: 0.8,
      price_3m_l: 0.5,
      price_6m_h: 0.8,
      price_6m_l: 0.186,
      price_52w_h: 0.8,
      price_52w_l: 0.186,
      price_pc_1d: 7.273,
      price_pc_1w: -3.279,
      price_pc_1m: -9.231,
      price_pc_6m: 156.522,
      price_pc_1y: 107.018,
      price_pc_ytd: 107.018,
    },
    8316: {
      id: "8316",
      c: 0.52,
      ma50: 0.762,
      ma100: 1.158,
      ma150: 1.22,
      ma200: 1.221,
      maw30: 1.196,
      rs: 9.0,
      rsd1d: 1.0,
      rsd5d: 3.0,
      rsd10d: -1.0,
      price_5d_h: 0.61,
      price_5d_L: 0.51,
      price_1m_h: 0.7,
      price_1m_l: 0.45,
      price_3m_h: 1.55,
      price_3m_l: 0.45,
      price_6m_h: 2.46,
      price_6m_l: 0.45,
      price_52w_h: 2.46,
      price_52w_l: 0.45,
      price_pc_1d: -5.455,
      price_pc_1w: 0.0,
      price_pc_1m: -24.638,
      price_pc_6m: -63.636,
      price_pc_1y: -17.46,
      price_pc_ytd: -17.46,
    },
    8319: {
      id: "8319",
      c: 0.115,
      ma50: 0.11,
      ma100: 0.122,
      ma150: 0.131,
      ma200: 0.139,
      maw30: 0.13,
      rs: 41.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: 3.0,
      price_5d_h: 0.115,
      price_5d_L: 0.115,
      price_1m_h: 0.118,
      price_1m_l: 0.105,
      price_3m_h: 0.133,
      price_3m_l: 0.1,
      price_6m_h: 0.152,
      price_6m_l: 0.1,
      price_52w_h: 0.185,
      price_52w_l: 0.1,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: 11.65,
      price_pc_6m: -22.819,
      price_pc_1y: -6.504,
      price_pc_ytd: -6.504,
    },
    8320: {
      id: "8320",
      c: 0.091,
      ma50: 0.082,
      ma100: 0.09,
      ma150: 0.093,
      ma200: 0.099,
      maw30: 0.094,
      rs: 50.0,
      rsd1d: 12.0,
      rsd5d: 16.0,
      rsd10d: 40.0,
      price_5d_h: 0.091,
      price_5d_L: 0.087,
      price_1m_h: 0.092,
      price_1m_l: 0.051,
      price_3m_h: 0.12,
      price_3m_l: 0.051,
      price_6m_h: 0.12,
      price_6m_l: 0.051,
      price_52w_h: 0.15,
      price_52w_l: 0.051,
      price_pc_1d: 4.598,
      price_pc_1w: 4.598,
      price_pc_1m: 16.667,
      price_pc_6m: -5.208,
      price_pc_1y: -18.75,
      price_pc_ytd: -18.75,
    },
    8325: {
      id: "8325",
      c: 0.136,
      ma50: 0.157,
      ma100: 0.166,
      ma150: 0.166,
      ma200: 0.173,
      maw30: 0.166,
      rs: 26.0,
      rsd1d: 2.0,
      rsd5d: -3.0,
      rsd10d: -13.0,
      price_5d_h: 0.137,
      price_5d_L: 0.13,
      price_1m_h: 0.163,
      price_1m_l: 0.13,
      price_3m_h: 0.185,
      price_3m_l: 0.13,
      price_6m_h: 0.191,
      price_6m_l: 0.13,
      price_52w_h: 0.27,
      price_52w_l: 0.13,
      price_pc_1d: 0.741,
      price_pc_1w: -1.449,
      price_pc_1m: -15.528,
      price_pc_6m: -13.924,
      price_pc_1y: -31.313,
      price_pc_ytd: -31.313,
    },
    8326: {
      id: "8326",
      c: 0.087,
      ma50: 0.165,
      ma100: 0.181,
      ma150: 0.191,
      ma200: 0.191,
      maw30: 0.192,
      rs: 7.0,
      rsd1d: 3.0,
      rsd5d: 2.0,
      rsd10d: -6.0,
      price_5d_h: 0.087,
      price_5d_L: 0.07,
      price_1m_h: 0.198,
      price_1m_l: 0.07,
      price_3m_h: 0.218,
      price_3m_l: 0.07,
      price_6m_h: 0.25,
      price_6m_l: 0.07,
      price_52w_h: 0.25,
      price_52w_l: 0.07,
      price_pc_1d: 12.987,
      price_pc_1w: 0.0,
      price_pc_1m: -47.904,
      price_pc_6m: -57.767,
      price_pc_1y: -29.839,
      price_pc_ytd: -29.839,
    },
    8328: {
      id: "8328",
      c: 4.0,
      ma50: 4.064,
      ma100: 4.08,
      ma150: 4.521,
      ma200: 4.79,
      maw30: 4.45,
      rs: 48.0,
      rsd1d: -2.0,
      rsd5d: 10.0,
      rsd10d: 15.0,
      price_5d_h: 4.15,
      price_5d_L: 3.8,
      price_1m_h: 4.35,
      price_1m_l: 3.65,
      price_3m_h: 4.55,
      price_3m_l: 3.65,
      price_6m_h: 5.34,
      price_6m_l: 3.65,
      price_52w_h: 8.0,
      price_52w_l: 3.65,
      price_pc_1d: -1.961,
      price_pc_1w: -0.498,
      price_pc_1m: -3.382,
      price_pc_6m: -22.179,
      price_pc_1y: -25.234,
      price_pc_ytd: -25.234,
    },
    8329: {
      id: "8329",
      c: 0.194,
      ma50: 0.189,
      ma100: 0.197,
      ma150: 0.211,
      ma200: 0.22,
      maw30: 0.21,
      rs: 53.0,
      rsd1d: 6.0,
      rsd5d: 7.0,
      rsd10d: 6.0,
      price_5d_h: 0.2,
      price_5d_L: 0.18,
      price_1m_h: 0.2,
      price_1m_l: 0.165,
      price_3m_h: 0.204,
      price_3m_l: 0.164,
      price_6m_h: 0.249,
      price_6m_l: 0.164,
      price_52w_h: 0.275,
      price_52w_l: 0.164,
      price_pc_1d: 0.0,
      price_pc_1w: 0.518,
      price_pc_1m: -0.513,
      price_pc_6m: -20.165,
      price_pc_1y: -22.4,
      price_pc_ytd: -22.4,
    },
    8340: {
      id: "8340",
      c: 0.061,
      ma50: 0.061,
      ma100: 0.055,
      ma150: 0.05,
      ma200: 0.049,
      maw30: 0.05,
      rs: 88.0,
      rsd1d: 0.0,
      rsd5d: 26.0,
      rsd10d: 1.0,
      price_5d_h: 0.073,
      price_5d_L: 0.055,
      price_1m_h: 0.073,
      price_1m_l: 0.05,
      price_3m_h: 0.074,
      price_3m_l: 0.05,
      price_6m_h: 0.087,
      price_6m_l: 0.036,
      price_52w_h: 0.087,
      price_52w_l: 0.03,
      price_pc_1d: 0.0,
      price_pc_1w: 8.929,
      price_pc_1m: -6.154,
      price_pc_6m: 45.238,
      price_pc_1y: 3.39,
      price_pc_ytd: 3.39,
    },
    8341: {
      id: "8341",
      c: 0.031,
      ma50: 0.04,
      ma100: 0.042,
      ma150: 0.044,
      ma200: 0.046,
      maw30: 0.044,
      rs: 15.0,
      rsd1d: -11.0,
      rsd5d: -6.0,
      rsd10d: -29.0,
      price_5d_h: 0.04,
      price_5d_L: 0.031,
      price_1m_h: 0.046,
      price_1m_l: 0.031,
      price_3m_h: 0.046,
      price_3m_l: 0.031,
      price_6m_h: 0.055,
      price_6m_l: 0.031,
      price_52w_h: 0.066,
      price_52w_l: 0.031,
      price_pc_1d: -18.421,
      price_pc_1w: -16.216,
      price_pc_1m: -29.545,
      price_pc_6m: -39.216,
      price_pc_1y: -50.794,
      price_pc_ytd: -50.794,
    },
    8347: {
      id: "8347",
      c: 0.04,
      ma50: 0.038,
      ma100: 0.042,
      ma150: 0.061,
      ma200: 0.072,
      maw30: 0.059,
      rs: 13.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: 4.0,
      price_5d_h: 0.041,
      price_5d_L: 0.038,
      price_1m_h: 0.041,
      price_1m_l: 0.032,
      price_3m_h: 0.044,
      price_3m_l: 0.032,
      price_6m_h: 0.189,
      price_6m_l: 0.032,
      price_52w_h: 0.189,
      price_52w_l: 0.032,
      price_pc_1d: -2.439,
      price_pc_1w: 0.0,
      price_pc_1m: 5.263,
      price_pc_6m: -60.0,
      price_pc_1y: -77.143,
      price_pc_ytd: -77.143,
    },
    8348: {
      id: "8348",
      c: 0.32,
      ma50: 0.332,
      ma100: 0.335,
      ma150: 0.335,
      ma200: 0.333,
      maw30: 0.335,
      rs: 73.0,
      rsd1d: -2.0,
      rsd5d: -2.0,
      rsd10d: 6.0,
      price_5d_h: 0.32,
      price_5d_L: 0.32,
      price_1m_h: 0.335,
      price_1m_l: 0.275,
      price_3m_h: 0.36,
      price_3m_l: 0.275,
      price_6m_h: 0.4,
      price_6m_l: 0.275,
      price_52w_h: 0.425,
      price_52w_l: 0.275,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: -4.478,
      price_pc_6m: -4.478,
      price_pc_1y: 3.226,
      price_pc_ytd: 3.226,
    },
    8349: {
      id: "8349",
      c: 2.38,
      ma50: 2.186,
      ma100: 2.422,
      ma150: 2.699,
      ma200: 2.717,
      maw30: 2.686,
      rs: 97.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: 1.0,
      price_5d_h: 2.78,
      price_5d_L: 2.2,
      price_1m_h: 3.4,
      price_1m_l: 1.91,
      price_3m_h: 3.4,
      price_3m_l: 1.9,
      price_6m_h: 3.58,
      price_6m_l: 1.9,
      price_52w_h: 5.5,
      price_52w_l: 0.5,
      price_pc_1d: 5.31,
      price_pc_1w: -13.455,
      price_pc_1m: 18.408,
      price_pc_6m: -27.879,
      price_pc_1y: 283.871,
      price_pc_ytd: 283.871,
    },
    8350: {
      id: "8350",
      c: 0.135,
      ma50: 0.199,
      ma100: 0.209,
      ma150: 0.209,
      ma200: 0.213,
      maw30: 0.206,
      rs: 15.0,
      rsd1d: 4.0,
      rsd5d: -31.0,
      rsd10d: -21.0,
      price_5d_h: 0.189,
      price_5d_L: 0.12,
      price_1m_h: 0.48,
      price_1m_l: 0.12,
      price_3m_h: 0.48,
      price_3m_l: 0.12,
      price_6m_h: 0.48,
      price_6m_l: 0.12,
      price_52w_h: 0.48,
      price_52w_l: 0.12,
      price_pc_1d: 3.846,
      price_pc_1w: -24.157,
      price_pc_1m: -27.419,
      price_pc_6m: -31.818,
      price_pc_1y: -41.304,
      price_pc_ytd: -41.304,
    },
    8357: {
      id: "8357",
      c: 0.23,
      ma50: 0.293,
      ma100: 0.361,
      ma150: 0.339,
      ma200: 0.304,
      maw30: 0.338,
      rs: 90.0,
      rsd1d: -1.0,
      rsd5d: -3.0,
      rsd10d: 3.0,
      price_5d_h: 0.275,
      price_5d_L: 0.221,
      price_1m_h: 0.3,
      price_1m_l: 0.2,
      price_3m_h: 0.405,
      price_3m_l: 0.2,
      price_6m_h: 0.66,
      price_6m_l: 0.2,
      price_52w_h: 0.66,
      price_52w_l: 0.091,
      price_pc_1d: -0.862,
      price_pc_1w: -4.564,
      price_pc_1m: -24.59,
      price_pc_6m: -25.806,
      price_pc_1y: 75.573,
      price_pc_ytd: 75.573,
    },
    8360: {
      id: "8360",
      c: 0.4,
      ma50: 0.421,
      ma100: 0.462,
      ma150: 0.435,
      ma200: 0.394,
      maw30: 0.437,
      rs: 85.0,
      rsd1d: 1.0,
      rsd5d: -1.0,
      rsd10d: 17.0,
      price_5d_h: 0.415,
      price_5d_L: 0.375,
      price_1m_h: 0.52,
      price_1m_l: 0.275,
      price_3m_h: 0.52,
      price_3m_l: 0.275,
      price_6m_h: 0.66,
      price_6m_l: 0.275,
      price_52w_h: 0.66,
      price_52w_l: 0.228,
      price_pc_1d: 3.896,
      price_pc_1w: 1.266,
      price_pc_1m: -13.043,
      price_pc_6m: 26.984,
      price_pc_1y: 10.01,
      price_pc_ytd: 10.01,
    },
    8363: {
      id: "8363",
      c: 0.121,
      ma50: 0.154,
      ma100: 0.231,
      ma150: 0.295,
      ma200: 0.344,
      maw30: 0.29,
      rs: 2.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.121,
      price_5d_L: 0.103,
      price_1m_h: 0.138,
      price_1m_l: 0.096,
      price_3m_h: 0.335,
      price_3m_l: 0.096,
      price_6m_h: 0.55,
      price_6m_l: 0.096,
      price_52w_h: 1.19,
      price_52w_l: 0.096,
      price_pc_1d: 17.476,
      price_pc_1w: 6.14,
      price_pc_1m: -6.923,
      price_pc_6m: -72.5,
      price_pc_1y: -89.386,
      price_pc_ytd: -89.386,
    },
    8365: {
      id: "8365",
      c: 0.71,
      ma50: 0.631,
      ma100: 0.559,
      ma150: 0.49,
      ma200: 0.48,
      maw30: 0.488,
      rs: 97.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: -1.0,
      price_5d_h: 0.73,
      price_5d_L: 0.69,
      price_1m_h: 0.75,
      price_1m_l: 0.64,
      price_3m_h: 0.75,
      price_3m_l: 0.45,
      price_6m_h: 0.75,
      price_6m_l: 0.115,
      price_52w_h: 0.75,
      price_52w_l: 0.115,
      price_pc_1d: 0.0,
      price_pc_1w: 2.899,
      price_pc_1m: 5.97,
      price_pc_6m: 77.5,
      price_pc_1y: 343.75,
      price_pc_ytd: 343.75,
    },
    8367: {
      id: "8367",
      c: 0.295,
      ma50: 0.386,
      ma100: 0.464,
      ma150: 0.604,
      ma200: 0.685,
      maw30: 0.591,
      rs: 89.0,
      rsd1d: 2.0,
      rsd5d: -5.0,
      rsd10d: -8.0,
      price_5d_h: 0.39,
      price_5d_L: 0.28,
      price_1m_h: 0.5,
      price_1m_l: 0.28,
      price_3m_h: 0.64,
      price_3m_l: 0.214,
      price_6m_h: 0.95,
      price_6m_l: 0.214,
      price_52w_h: 1.8,
      price_52w_l: 0.055,
      price_pc_1d: 3.509,
      price_pc_1w: -20.27,
      price_pc_1m: -25.316,
      price_pc_6m: -63.58,
      price_pc_1y: 195.0,
      price_pc_ytd: 195.0,
    },
    8370: {
      id: "8370",
      c: 0.223,
      ma50: 0.272,
      ma100: 0.302,
      ma150: 0.324,
      ma200: 0.34,
      maw30: 0.323,
      rs: 16.0,
      rsd1d: 3.0,
      rsd5d: -4.0,
      rsd10d: -7.0,
      price_5d_h: 0.24,
      price_5d_L: 0.214,
      price_1m_h: 0.275,
      price_1m_l: 0.214,
      price_3m_h: 0.365,
      price_3m_l: 0.214,
      price_6m_h: 0.385,
      price_6m_l: 0.214,
      price_52w_h: 0.48,
      price_52w_l: 0.214,
      price_pc_1d: 2.765,
      price_pc_1w: -6.695,
      price_pc_1m: -15.849,
      price_pc_6m: -39.73,
      price_pc_1y: -28.065,
      price_pc_ytd: -28.065,
    },
    8373: {
      id: "8373",
      c: 0.249,
      ma50: 0.259,
      ma100: 0.271,
      ma150: 0.273,
      ma200: 0.281,
      maw30: 0.272,
      rs: 46.0,
      rsd1d: 4.0,
      rsd5d: -5.0,
      rsd10d: 6.0,
      price_5d_h: 0.255,
      price_5d_L: 0.231,
      price_1m_h: 0.265,
      price_1m_l: 0.21,
      price_3m_h: 0.28,
      price_3m_l: 0.21,
      price_6m_h: 0.325,
      price_6m_l: 0.21,
      price_52w_h: 0.38,
      price_52w_l: 0.21,
      price_pc_1d: 0.0,
      price_pc_1w: -4.231,
      price_pc_1m: -0.4,
      price_pc_6m: -7.778,
      price_pc_1y: -26.765,
      price_pc_ytd: -26.765,
    },
    8375: {
      id: "8375",
      c: 0.223,
      ma50: 0.24,
      ma100: 0.256,
      ma150: 0.34,
      ma200: 0.384,
      maw30: 0.33,
      rs: 87.0,
      rsd1d: -1.0,
      rsd5d: 1.0,
      rsd10d: 1.0,
      price_5d_h: 0.229,
      price_5d_L: 0.214,
      price_1m_h: 0.295,
      price_1m_l: 0.201,
      price_3m_h: 0.32,
      price_3m_l: 0.182,
      price_6m_h: 0.456,
      price_6m_l: 0.182,
      price_52w_h: 0.856,
      price_52w_l: 0.067,
      price_pc_1d: 0.0,
      price_pc_1w: 1.826,
      price_pc_1m: -5.508,
      price_pc_6m: -40.106,
      price_pc_1y: 137.234,
      price_pc_ytd: 137.234,
    },
    8383: {
      id: "8383",
      c: 0.13,
      ma50: 0.153,
      ma100: 0.146,
      ma150: 0.145,
      ma200: 0.142,
      maw30: 0.146,
      rs: 50.0,
      rsd1d: 3.0,
      rsd5d: -26.0,
      rsd10d: -38.0,
      price_5d_h: 0.16,
      price_5d_L: 0.13,
      price_1m_h: 0.16,
      price_1m_l: 0.13,
      price_3m_h: 0.165,
      price_3m_l: 0.113,
      price_6m_h: 0.165,
      price_6m_l: 0.089,
      price_52w_h: 0.2,
      price_52w_l: 0.089,
      price_pc_1d: 0.0,
      price_pc_1w: -6.475,
      price_pc_1m: -18.75,
      price_pc_6m: -8.451,
      price_pc_1y: -22.619,
      price_pc_ytd: -22.619,
    },
    8385: {
      id: "8385",
      c: 0.033,
      ma50: 0.048,
      ma100: 0.059,
      ma150: 0.083,
      ma200: 0.114,
      maw30: 0.082,
      rs: 3.0,
      rsd1d: 0.0,
      rsd5d: -2.0,
      rsd10d: -8.0,
      price_5d_h: 0.051,
      price_5d_L: 0.03,
      price_1m_h: 0.064,
      price_1m_l: 0.03,
      price_3m_h: 0.064,
      price_3m_l: 0.03,
      price_6m_h: 0.15,
      price_6m_l: 0.03,
      price_52w_h: 0.95,
      price_52w_l: 0.03,
      price_pc_1d: -8.333,
      price_pc_1w: -32.653,
      price_pc_1m: -28.879,
      price_pc_6m: -77.397,
      price_pc_1y: -79.755,
      price_pc_ytd: -79.755,
    },
    8391: {
      id: "8391",
      c: 0.93,
      ma50: 0.793,
      ma100: 0.795,
      ma150: 0.848,
      ma200: 0.911,
      maw30: 0.843,
      rs: 85.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: 31.0,
      price_5d_h: 0.96,
      price_5d_L: 0.88,
      price_1m_h: 0.96,
      price_1m_l: 0.68,
      price_3m_h: 0.96,
      price_3m_l: 0.68,
      price_6m_h: 1.04,
      price_6m_l: 0.68,
      price_52w_h: 1.46,
      price_52w_l: 0.58,
      price_pc_1d: 1.087,
      price_pc_1w: 3.333,
      price_pc_1m: 17.722,
      price_pc_6m: -7.921,
      price_pc_1y: 55.0,
      price_pc_ytd: 55.0,
    },
    8392: {
      id: "8392",
      c: 0.098,
      ma50: 0.109,
      ma100: 0.112,
      ma150: 0.109,
      ma200: 0.109,
      maw30: 0.109,
      rs: 53.0,
      rsd1d: 8.0,
      rsd5d: 17.0,
      rsd10d: 4.0,
      price_5d_h: 0.102,
      price_5d_L: 0.088,
      price_1m_h: 0.116,
      price_1m_l: 0.08,
      price_3m_h: 0.126,
      price_3m_l: 0.08,
      price_6m_h: 0.129,
      price_6m_l: 0.08,
      price_52w_h: 0.137,
      price_52w_l: 0.078,
      price_pc_1d: 1.031,
      price_pc_1w: 5.376,
      price_pc_1m: -16.239,
      price_pc_6m: -4.854,
      price_pc_1y: 0.0,
      price_pc_ytd: 0.0,
    },
    8400: {
      id: "8400",
      c: 0.044,
      ma50: 0.045,
      ma100: 0.047,
      ma150: 0.049,
      ma200: 0.049,
      maw30: 0.048,
      rs: 80.0,
      rsd1d: -6.0,
      rsd5d: -4.0,
      rsd10d: 24.0,
      price_5d_h: 0.047,
      price_5d_L: 0.042,
      price_1m_h: 0.048,
      price_1m_l: 0.035,
      price_3m_h: 0.071,
      price_3m_l: 0.035,
      price_6m_h: 0.071,
      price_6m_l: 0.035,
      price_52w_h: 0.088,
      price_52w_l: 0.035,
      price_pc_1d: -4.348,
      price_pc_1w: -6.383,
      price_pc_1m: -10.204,
      price_pc_6m: -6.383,
      price_pc_1y: 2.326,
      price_pc_ytd: 2.326,
    },
    8401: {
      id: "8401",
      c: 0.5,
      ma50: 0.45,
      ma100: 0.509,
      ma150: 0.611,
      ma200: 0.8,
      maw30: 0.592,
      rs: 20.0,
      rsd1d: 4.0,
      rsd5d: 7.0,
      rsd10d: 10.0,
      price_5d_h: 0.51,
      price_5d_L: 0.42,
      price_1m_h: 0.51,
      price_1m_l: 0.395,
      price_3m_h: 0.52,
      price_3m_l: 0.395,
      price_6m_h: 0.98,
      price_6m_l: 0.395,
      price_52w_h: 2.49,
      price_52w_l: 0.395,
      price_pc_1d: 1.01,
      price_pc_1w: 13.636,
      price_pc_1m: 20.482,
      price_pc_6m: -33.333,
      price_pc_1y: -76.636,
      price_pc_ytd: -76.636,
    },
    8402: {
      id: "8402",
      c: 2.47,
      ma50: 2.565,
      ma100: 2.433,
      ma150: 2.415,
      ma200: 2.402,
      maw30: 2.443,
      rs: 90.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: -2.0,
      price_5d_h: 2.6,
      price_5d_L: 2.39,
      price_1m_h: 2.7,
      price_1m_l: 2.38,
      price_3m_h: 3.0,
      price_3m_l: 2.12,
      price_6m_h: 3.0,
      price_6m_l: 1.98,
      price_52w_h: 3.0,
      price_52w_l: 1.59,
      price_pc_1d: -3.137,
      price_pc_1w: -4.264,
      price_pc_1m: -5.0,
      price_pc_6m: 11.261,
      price_pc_1y: 51.534,
      price_pc_ytd: 51.534,
    },
    8405: {
      id: "8405",
      c: 0.88,
      ma50: 0.876,
      ma100: 0.904,
      ma150: 0.896,
      ma200: 0.904,
      maw30: 0.899,
      rs: 70.0,
      rsd1d: 3.0,
      rsd5d: 8.0,
      rsd10d: 14.0,
      price_5d_h: 0.88,
      price_5d_L: 0.85,
      price_1m_h: 0.88,
      price_1m_l: 0.8,
      price_3m_h: 0.95,
      price_3m_l: 0.8,
      price_6m_h: 1.2,
      price_6m_l: 0.8,
      price_52w_h: 1.2,
      price_52w_l: 0.78,
      price_pc_1d: 1.149,
      price_pc_1w: 1.149,
      price_pc_1m: 0.0,
      price_pc_6m: 10.0,
      price_pc_1y: -11.111,
      price_pc_ytd: -11.111,
    },
    8406: {
      id: "8406",
      c: 0.89,
      ma50: 0.941,
      ma100: 0.854,
      ma150: 0.79,
      ma200: 0.637,
      maw30: 0.792,
      rs: 98.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -1.0,
      price_5d_h: 0.96,
      price_5d_L: 0.89,
      price_1m_h: 1.04,
      price_1m_l: 0.87,
      price_3m_h: 1.11,
      price_3m_l: 0.82,
      price_6m_h: 1.98,
      price_6m_l: 0.395,
      price_52w_h: 1.98,
      price_52w_l: 0.135,
      price_pc_1d: -3.261,
      price_pc_1w: -4.301,
      price_pc_1m: -12.745,
      price_pc_6m: 14.103,
      price_pc_1y: 313.953,
      price_pc_ytd: 313.953,
    },
    8412: {
      id: "8412",
      c: 0.029,
      ma50: 0.029,
      ma100: 0.03,
      ma150: 0.062,
      ma200: 0.16,
      maw30: 0.044,
      rs: 10.0,
      rsd1d: 1.0,
      rsd5d: 6.0,
      rsd10d: 6.0,
      price_5d_h: 0.031,
      price_5d_L: 0.023,
      price_1m_h: 0.031,
      price_1m_l: 0.02,
      price_3m_h: 0.035,
      price_3m_l: 0.02,
      price_6m_h: 0.056,
      price_6m_l: 0.02,
      price_52w_h: 0.72,
      price_52w_l: 0.02,
      price_pc_1d: 0.0,
      price_pc_1w: 26.087,
      price_pc_1m: 3.571,
      price_pc_6m: -35.556,
      price_pc_1y: -94.63,
      price_pc_ytd: -94.63,
    },
    8413: {
      id: "8413",
      c: 0.17,
      ma50: 0.187,
      ma100: 0.188,
      ma150: 0.2,
      ma200: 0.224,
      maw30: 0.2,
      rs: 30.0,
      rsd1d: 2.0,
      rsd5d: 2.0,
      rsd10d: 5.0,
      price_5d_h: 0.17,
      price_5d_L: 0.146,
      price_1m_h: 0.196,
      price_1m_l: 0.146,
      price_3m_h: 0.218,
      price_3m_l: 0.146,
      price_6m_h: 0.28,
      price_6m_l: 0.126,
      price_52w_h: 0.45,
      price_52w_l: 0.126,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: -5.556,
      price_pc_6m: -15.0,
      price_pc_1y: -32.0,
      price_pc_ytd: -32.0,
    },
    8416: {
      id: "8416",
      c: 0.082,
      ma50: 0.107,
      ma100: 0.115,
      ma150: 0.116,
      ma200: 0.111,
      maw30: 0.117,
      rs: 36.0,
      rsd1d: -29.0,
      rsd5d: 5.0,
      rsd10d: -1.0,
      price_5d_h: 0.095,
      price_5d_L: 0.078,
      price_1m_h: 0.113,
      price_1m_l: 0.076,
      price_3m_h: 0.128,
      price_3m_l: 0.076,
      price_6m_h: 0.139,
      price_6m_l: 0.076,
      price_52w_h: 0.145,
      price_52w_l: 0.073,
      price_pc_1d: -13.684,
      price_pc_1w: 5.128,
      price_pc_1m: -30.508,
      price_pc_6m: -28.696,
      price_pc_1y: 3.797,
      price_pc_ytd: 3.797,
    },
    8418: {
      id: "8418",
      c: 0.97,
      ma50: 0.999,
      ma100: 1.059,
      ma150: 1.121,
      ma200: 1.204,
      maw30: 1.116,
      rs: 28.0,
      rsd1d: 3.0,
      rsd5d: 8.0,
      rsd10d: 3.0,
      price_5d_h: 1.09,
      price_5d_L: 0.83,
      price_1m_h: 1.14,
      price_1m_l: 0.83,
      price_3m_h: 1.27,
      price_3m_l: 0.83,
      price_6m_h: 1.62,
      price_6m_l: 0.7,
      price_52w_h: 2.1,
      price_52w_l: 0.7,
      price_pc_1d: 0.0,
      price_pc_1w: 7.778,
      price_pc_1m: -3.96,
      price_pc_6m: -17.094,
      price_pc_1y: -45.506,
      price_pc_ytd: -45.506,
    },
    8422: {
      id: "8422",
      c: 0.025,
      ma50: 0.032,
      ma100: 0.038,
      ma150: 0.042,
      ma200: 0.066,
      maw30: 0.043,
      rs: 4.0,
      rsd1d: -2.0,
      rsd5d: 0.0,
      rsd10d: -1.0,
      price_5d_h: 0.026,
      price_5d_L: 0.024,
      price_1m_h: 0.036,
      price_1m_l: 0.02,
      price_3m_h: 0.043,
      price_3m_l: 0.02,
      price_6m_h: 0.063,
      price_6m_l: 0.02,
      price_52w_h: 0.44,
      price_52w_l: 0.02,
      price_pc_1d: -3.846,
      price_pc_1w: 4.167,
      price_pc_1m: -30.556,
      price_pc_6m: -52.83,
      price_pc_1y: -68.354,
      price_pc_ytd: -68.354,
    },
    8423: {
      id: "8423",
      c: 0.186,
      ma50: 0.195,
      ma100: 0.211,
      ma150: 0.219,
      ma200: 0.233,
      maw30: 0.219,
      rs: 40.0,
      rsd1d: 3.0,
      rsd5d: 7.0,
      rsd10d: -4.0,
      price_5d_h: 0.19,
      price_5d_L: 0.184,
      price_1m_h: 0.195,
      price_1m_l: 0.18,
      price_3m_h: 0.22,
      price_3m_l: 0.18,
      price_6m_h: 0.3,
      price_6m_l: 0.18,
      price_52w_h: 0.3,
      price_52w_l: 0.18,
      price_pc_1d: -1.064,
      price_pc_1w: 3.333,
      price_pc_1m: -4.615,
      price_pc_6m: -11.005,
      price_pc_1y: -29.811,
      price_pc_ytd: -29.811,
    },
    8426: {
      id: "8426",
      c: 0.15,
      ma50: 0.148,
      ma100: 0.144,
      ma150: 0.147,
      ma200: 0.148,
      maw30: 0.147,
      rs: 77.0,
      rsd1d: -3.0,
      rsd5d: 2.0,
      rsd10d: -2.0,
      price_5d_h: 0.155,
      price_5d_L: 0.14,
      price_1m_h: 0.162,
      price_1m_l: 0.139,
      price_3m_h: 0.164,
      price_3m_l: 0.129,
      price_6m_h: 0.164,
      price_6m_l: 0.129,
      price_52w_h: 0.18,
      price_52w_l: 0.129,
      price_pc_1d: -1.316,
      price_pc_1w: 3.448,
      price_pc_1m: 3.448,
      price_pc_6m: -3.846,
      price_pc_1y: -9.091,
      price_pc_ytd: -9.091,
    },
    8427: {
      id: "8427",
      c: 0.195,
      ma50: 0.199,
      ma100: 0.233,
      ma150: 0.279,
      ma200: 0.355,
      maw30: 0.276,
      rs: 9.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: 2.0,
      price_5d_h: 0.21,
      price_5d_L: 0.195,
      price_1m_h: 0.21,
      price_1m_l: 0.18,
      price_3m_h: 0.238,
      price_3m_l: 0.18,
      price_6m_h: 0.395,
      price_6m_l: 0.18,
      price_52w_h: 1.248,
      price_52w_l: 0.18,
      price_pc_1d: -1.515,
      price_pc_1w: -1.515,
      price_pc_1m: -4.412,
      price_pc_6m: -48.0,
      price_pc_1y: -80.343,
      price_pc_ytd: -80.343,
    },
    8428: {
      id: "8428",
      c: 0.055,
      ma50: 0.074,
      ma100: 0.13,
      ma150: 0.166,
      ma200: 0.169,
      maw30: 0.168,
      rs: 2.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: 0.0,
      price_5d_h: 0.066,
      price_5d_L: 0.053,
      price_1m_h: 0.081,
      price_1m_l: 0.052,
      price_3m_h: 0.305,
      price_3m_l: 0.052,
      price_6m_h: 0.345,
      price_6m_l: 0.052,
      price_52w_h: 0.79,
      price_52w_l: 0.052,
      price_pc_1d: -8.333,
      price_pc_1w: -6.78,
      price_pc_1m: -22.535,
      price_pc_6m: -64.968,
      price_pc_1y: -76.922,
      price_pc_ytd: -76.922,
    },
    8429: {
      id: "8429",
      c: 0.15,
      ma50: 0.155,
      ma100: 0.158,
      ma150: 0.184,
      ma200: 0.19,
      maw30: 0.179,
      rs: 29.0,
      rsd1d: -2.0,
      rsd5d: 4.0,
      rsd10d: 6.0,
      price_5d_h: 0.161,
      price_5d_L: 0.134,
      price_1m_h: 0.162,
      price_1m_l: 0.131,
      price_3m_h: 0.164,
      price_3m_l: 0.131,
      price_6m_h: 0.244,
      price_6m_l: 0.131,
      price_52w_h: 0.4,
      price_52w_l: 0.131,
      price_pc_1d: -4.459,
      price_pc_1w: 0.671,
      price_pc_1m: -5.063,
      price_pc_6m: -36.975,
      price_pc_1y: -34.498,
      price_pc_ytd: -34.498,
    },
    8430: {
      id: "8430",
      c: 0.065,
      ma50: 0.086,
      ma100: 0.101,
      ma150: 0.112,
      ma200: 0.119,
      maw30: 0.111,
      rs: 10.0,
      rsd1d: 3.0,
      rsd5d: 3.0,
      rsd10d: 4.0,
      price_5d_h: 0.076,
      price_5d_L: 0.056,
      price_1m_h: 0.096,
      price_1m_l: 0.055,
      price_3m_h: 0.125,
      price_3m_l: 0.055,
      price_6m_h: 0.148,
      price_6m_l: 0.055,
      price_52w_h: 0.36,
      price_52w_l: 0.055,
      price_pc_1d: 0.0,
      price_pc_1w: 1.563,
      price_pc_1m: -35.0,
      price_pc_6m: -56.376,
      price_pc_1y: -59.119,
      price_pc_ytd: -59.119,
    },
    8431: {
      id: "8431",
      c: 0.028,
      ma50: 0.028,
      ma100: 0.028,
      ma150: 0.028,
      ma200: 0.03,
      maw30: 0.028,
      rs: 51.0,
      rsd1d: 8.0,
      rsd5d: 23.0,
      rsd10d: 8.0,
      price_5d_h: 0.029,
      price_5d_L: 0.025,
      price_1m_h: 0.035,
      price_1m_l: 0.023,
      price_3m_h: 0.047,
      price_3m_l: 0.023,
      price_6m_h: 0.047,
      price_6m_l: 0.023,
      price_52w_h: 0.048,
      price_52w_l: 0.023,
      price_pc_1d: 3.704,
      price_pc_1w: 12.0,
      price_pc_1m: 21.739,
      price_pc_6m: 0.0,
      price_pc_1y: -30.0,
      price_pc_ytd: -30.0,
    },
    8432: {
      id: "8432",
      c: 0.056,
      ma50: 0.057,
      ma100: 0.056,
      ma150: 0.058,
      ma200: 0.062,
      maw30: 0.058,
      rs: 54.0,
      rsd1d: -9.0,
      rsd5d: -3.0,
      rsd10d: 18.0,
      price_5d_h: 0.061,
      price_5d_L: 0.056,
      price_1m_h: 0.062,
      price_1m_l: 0.05,
      price_3m_h: 0.063,
      price_3m_l: 0.05,
      price_6m_h: 0.065,
      price_6m_l: 0.05,
      price_52w_h: 0.093,
      price_52w_l: 0.05,
      price_pc_1d: -6.667,
      price_pc_1w: -9.677,
      price_pc_1m: 1.818,
      price_pc_6m: -11.111,
      price_pc_1y: -37.079,
      price_pc_ytd: -37.079,
    },
    8436: {
      id: "8436",
      c: 0.199,
      ma50: 0.193,
      ma100: 0.218,
      ma150: 0.23,
      ma200: 0.238,
      maw30: 0.23,
      rs: 36.0,
      rsd1d: 4.0,
      rsd5d: 18.0,
      rsd10d: 8.0,
      price_5d_h: 0.199,
      price_5d_L: 0.17,
      price_1m_h: 0.2,
      price_1m_l: 0.154,
      price_3m_h: 0.24,
      price_3m_l: 0.154,
      price_6m_h: 0.3,
      price_6m_l: 0.154,
      price_52w_h: 0.3,
      price_52w_l: 0.154,
      price_pc_1d: 0.0,
      price_pc_1w: 13.714,
      price_pc_1m: 0.0,
      price_pc_6m: -20.4,
      price_pc_1y: -21.961,
      price_pc_ytd: -21.961,
    },
    8437: {
      id: "8437",
      c: 0.137,
      ma50: 0.11,
      ma100: 0.126,
      ma150: 0.146,
      ma200: 0.177,
      maw30: 0.141,
      rs: 31.0,
      rsd1d: -3.0,
      rsd5d: 10.0,
      rsd10d: 23.0,
      price_5d_h: 0.152,
      price_5d_L: 0.119,
      price_1m_h: 0.152,
      price_1m_l: 0.08,
      price_3m_h: 0.152,
      price_3m_l: 0.08,
      price_6m_h: 0.188,
      price_6m_l: 0.08,
      price_52w_h: 0.45,
      price_52w_l: 0.08,
      price_pc_1d: -2.143,
      price_pc_1w: 14.167,
      price_pc_1m: 52.222,
      price_pc_6m: -11.039,
      price_pc_1y: -49.259,
      price_pc_ytd: -49.259,
    },
    8446: {
      id: "8446",
      c: 1.94,
      ma50: 1.372,
      ma100: 0.833,
      ma150: 0.616,
      ma200: 0.506,
      maw30: 0.627,
      rs: 99.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 2.05,
      price_5d_L: 1.72,
      price_1m_h: 2.5,
      price_1m_l: 1.23,
      price_3m_h: 2.5,
      price_3m_l: 0.31,
      price_6m_h: 2.5,
      price_6m_l: 0.2,
      price_52w_h: 2.5,
      price_52w_l: 0.11,
      price_pc_1d: 0.518,
      price_pc_1w: 12.139,
      price_pc_1m: 45.865,
      price_pc_6m: 921.053,
      price_pc_1y: 1014.943,
      price_pc_ytd: 1014.943,
    },
    8451: {
      id: "8451",
      c: 0.056,
      ma50: 0.06,
      ma100: 0.06,
      ma150: 0.061,
      ma200: 0.06,
      maw30: 0.061,
      rs: 71.0,
      rsd1d: 6.0,
      rsd5d: -9.0,
      rsd10d: -2.0,
      price_5d_h: 0.057,
      price_5d_L: 0.055,
      price_1m_h: 0.07,
      price_1m_l: 0.055,
      price_3m_h: 0.077,
      price_3m_l: 0.055,
      price_6m_h: 0.088,
      price_6m_l: 0.051,
      price_52w_h: 0.088,
      price_52w_l: 0.051,
      price_pc_1d: 1.818,
      price_pc_1w: -3.448,
      price_pc_1m: -5.085,
      price_pc_6m: -9.677,
      price_pc_1y: 3.704,
      price_pc_ytd: 3.704,
    },
    8456: {
      id: "8456",
      c: 0.425,
      ma50: 0.444,
      ma100: 0.435,
      ma150: 0.458,
      ma200: 0.456,
      maw30: 0.453,
      rs: 63.0,
      rsd1d: -17.0,
      rsd5d: 12.0,
      rsd10d: -11.0,
      price_5d_h: 0.5,
      price_5d_L: 0.42,
      price_1m_h: 0.65,
      price_1m_l: 0.375,
      price_3m_h: 0.7,
      price_3m_l: 0.315,
      price_6m_h: 0.85,
      price_6m_l: 0.315,
      price_52w_h: 1.22,
      price_52w_l: 0.26,
      price_pc_1d: -9.574,
      price_pc_1w: 0.0,
      price_pc_1m: -3.409,
      price_pc_6m: -14.141,
      price_pc_1y: -58.333,
      price_pc_ytd: -58.333,
    },
    8460: {
      id: "8460",
      c: 0.023,
      ma50: 0.031,
      ma100: 0.034,
      ma150: 0.04,
      ma200: 0.042,
      maw30: 0.04,
      rs: 10.0,
      rsd1d: 3.0,
      rsd5d: 4.0,
      rsd10d: -13.0,
      price_5d_h: 0.023,
      price_5d_L: 0.02,
      price_1m_h: 0.034,
      price_1m_l: 0.02,
      price_3m_h: 0.038,
      price_3m_l: 0.02,
      price_6m_h: 0.061,
      price_6m_l: 0.02,
      price_52w_h: 0.085,
      price_52w_l: 0.02,
      price_pc_1d: 4.545,
      price_pc_1w: 4.545,
      price_pc_1m: -34.286,
      price_pc_6m: -58.929,
      price_pc_1y: -65.152,
      price_pc_ytd: -65.152,
    },
    8462: {
      id: "8462",
      c: 0.135,
      ma50: 0.141,
      ma100: 0.162,
      ma150: 0.204,
      ma200: 0.228,
      maw30: 0.201,
      rs: 10.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: -2.0,
      price_5d_h: 0.163,
      price_5d_L: 0.133,
      price_1m_h: 0.163,
      price_1m_l: 0.13,
      price_3m_h: 0.214,
      price_3m_l: 0.13,
      price_6m_h: 0.29,
      price_6m_l: 0.13,
      price_52w_h: 0.64,
      price_52w_l: 0.13,
      price_pc_1d: 0.0,
      price_pc_1w: -0.735,
      price_pc_1m: -6.897,
      price_pc_6m: -52.632,
      price_pc_1y: -78.906,
      price_pc_ytd: -78.906,
    },
    8471: {
      id: "8471",
      c: 0.165,
      ma50: 0.216,
      ma100: 0.35,
      ma150: 0.438,
      ma200: 0.461,
      maw30: 0.432,
      rs: 4.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.35,
      price_5d_L: 0.164,
      price_1m_h: 0.35,
      price_1m_l: 0.16,
      price_3m_h: 0.35,
      price_3m_l: 0.16,
      price_6m_h: 0.72,
      price_6m_l: 0.16,
      price_52w_h: 0.95,
      price_52w_l: 0.16,
      price_pc_1d: -2.941,
      price_pc_1w: -9.341,
      price_pc_1m: -20.29,
      price_pc_6m: -74.219,
      price_pc_1y: -69.444,
      price_pc_ytd: -69.444,
    },
    8472: {
      id: "8472",
      c: 3.19,
      ma50: 2.057,
      ma100: 1.5,
      ma150: 1.438,
      ma200: 1.278,
      maw30: 1.406,
      rs: 99.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 3.35,
      price_5d_L: 2.77,
      price_1m_h: 3.35,
      price_1m_l: 1.73,
      price_3m_h: 3.35,
      price_3m_l: 0.7,
      price_6m_h: 3.35,
      price_6m_l: 0.1,
      price_52w_h: 3.35,
      price_52w_l: 0.1,
      price_pc_1d: -0.932,
      price_pc_1w: 9.247,
      price_pc_1m: 29.15,
      price_pc_6m: 200.943,
      price_pc_1y: 697.5,
      price_pc_ytd: 697.5,
    },
    8475: {
      id: "8475",
      c: 0.084,
      ma50: 0.084,
      ma100: 0.086,
      ma150: 0.093,
      ma200: 0.1,
      maw30: 0.093,
      rs: 24.0,
      rsd1d: 6.0,
      rsd5d: -1.0,
      rsd10d: -6.0,
      price_5d_h: 0.104,
      price_5d_L: 0.067,
      price_1m_h: 0.104,
      price_1m_l: 0.061,
      price_3m_h: 0.109,
      price_3m_l: 0.061,
      price_6m_h: 0.115,
      price_6m_l: 0.061,
      price_52w_h: 0.246,
      price_52w_l: 0.061,
      price_pc_1d: 3.704,
      price_pc_1w: -4.545,
      price_pc_1m: 9.091,
      price_pc_6m: -20.0,
      price_pc_1y: -56.923,
      price_pc_ytd: -56.923,
    },
    8476: {
      id: "8476",
      c: 0.64,
      ma50: 0.752,
      ma100: 0.81,
      ma150: 0.815,
      ma200: 0.814,
      maw30: 0.823,
      rs: 33.0,
      rsd1d: 10.0,
      rsd5d: 6.0,
      rsd10d: -7.0,
      price_5d_h: 0.65,
      price_5d_L: 0.59,
      price_1m_h: 0.76,
      price_1m_l: 0.59,
      price_3m_h: 0.93,
      price_3m_l: 0.59,
      price_6m_h: 0.93,
      price_6m_l: 0.59,
      price_52w_h: 0.93,
      price_52w_l: 0.59,
      price_pc_1d: 6.667,
      price_pc_1w: 1.587,
      price_pc_1m: -16.883,
      price_pc_6m: -20.0,
      price_pc_1y: -20.988,
      price_pc_ytd: -20.988,
    },
    8480: {
      id: "8480",
      c: 0.205,
      ma50: 0.235,
      ma100: 0.253,
      ma150: 0.257,
      ma200: 0.254,
      maw30: 0.258,
      rs: 22.0,
      rsd1d: 4.0,
      rsd5d: 5.0,
      rsd10d: 0.0,
      price_5d_h: 0.212,
      price_5d_L: 0.2,
      price_1m_h: 0.23,
      price_1m_l: 0.19,
      price_3m_h: 0.295,
      price_3m_l: 0.09,
      price_6m_h: 0.325,
      price_6m_l: 0.09,
      price_52w_h: 0.55,
      price_52w_l: 0.09,
      price_pc_1d: 2.5,
      price_pc_1w: 1.485,
      price_pc_1m: -10.87,
      price_pc_6m: -24.074,
      price_pc_1y: -54.444,
      price_pc_ytd: -54.444,
    },
    8481: {
      id: "8481",
      c: 0.219,
      ma50: 0.202,
      ma100: 0.207,
      ma150: 0.211,
      ma200: 0.212,
      maw30: 0.21,
      rs: 70.0,
      rsd1d: 34.0,
      rsd5d: -11.0,
      rsd10d: -14.0,
      price_5d_h: 0.239,
      price_5d_L: 0.18,
      price_1m_h: 0.275,
      price_1m_l: 0.175,
      price_3m_h: 0.275,
      price_3m_l: 0.174,
      price_6m_h: 0.275,
      price_6m_l: 0.174,
      price_52w_h: 0.79,
      price_52w_l: 0.174,
      price_pc_1d: 21.667,
      price_pc_1w: -2.667,
      price_pc_1m: 9.5,
      price_pc_6m: -5.603,
      price_pc_1y: -6.809,
      price_pc_ytd: -6.809,
    },
    8482: {
      id: "8482",
      c: 0.223,
      ma50: 0.295,
      ma100: 0.337,
      ma150: 0.335,
      ma200: 0.329,
      maw30: 0.338,
      rs: 23.0,
      rsd1d: -2.0,
      rsd5d: -10.0,
      rsd10d: -6.0,
      price_5d_h: 0.249,
      price_5d_L: 0.2,
      price_1m_h: 0.295,
      price_1m_l: 0.191,
      price_3m_h: 0.39,
      price_3m_l: 0.191,
      price_6m_h: 0.445,
      price_6m_l: 0.191,
      price_52w_h: 0.65,
      price_52w_l: 0.165,
      price_pc_1d: -2.62,
      price_pc_1w: -10.8,
      price_pc_1m: -20.357,
      price_pc_6m: -29.206,
      price_pc_1y: 1.364,
      price_pc_ytd: 1.364,
    },
    8483: {
      id: "8483",
      c: 0.065,
      ma50: 0.097,
      ma100: 0.114,
      ma150: 0.116,
      ma200: 0.109,
      maw30: 0.119,
      rs: 13.0,
      rsd1d: 3.0,
      rsd5d: 0.0,
      rsd10d: 2.0,
      price_5d_h: 0.067,
      price_5d_L: 0.057,
      price_1m_h: 0.106,
      price_1m_l: 0.05,
      price_3m_h: 0.148,
      price_3m_l: 0.05,
      price_6m_h: 0.15,
      price_6m_l: 0.05,
      price_52w_h: 0.15,
      price_52w_l: 0.05,
      price_pc_1d: 10.169,
      price_pc_1w: -2.985,
      price_pc_1m: -41.441,
      price_pc_6m: -47.581,
      price_pc_1y: -29.348,
      price_pc_ytd: -29.348,
    },
    8487: {
      id: "8487",
      c: 0.181,
      ma50: 0.208,
      ma100: 0.271,
      ma150: 0.359,
      ma200: 0.464,
      maw30: 0.352,
      rs: 3.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.185,
      price_5d_L: 0.16,
      price_1m_h: 0.221,
      price_1m_l: 0.159,
      price_3m_h: 0.48,
      price_3m_l: 0.159,
      price_6m_h: 0.63,
      price_6m_l: 0.159,
      price_52w_h: 1.21,
      price_52w_l: 0.159,
      price_pc_1d: 10.366,
      price_pc_1w: 6.471,
      price_pc_1m: -15.421,
      price_pc_6m: -72.154,
      price_pc_1y: -84.53,
      price_pc_ytd: -84.53,
    },
    8490: {
      id: "8490",
      c: 0.265,
      ma50: 0.305,
      ma100: 0.32,
      ma150: 0.323,
      ma200: 0.319,
      maw30: 0.325,
      rs: 52.0,
      rsd1d: 16.0,
      rsd5d: -2.0,
      rsd10d: -3.0,
      price_5d_h: 0.295,
      price_5d_L: 0.25,
      price_1m_h: 0.305,
      price_1m_l: 0.25,
      price_3m_h: 0.36,
      price_3m_l: 0.25,
      price_6m_h: 0.4,
      price_6m_l: 0.25,
      price_52w_h: 0.475,
      price_52w_l: 0.25,
      price_pc_1d: 6.0,
      price_pc_1w: -3.636,
      price_pc_1m: -5.357,
      price_pc_6m: -19.697,
      price_pc_1y: -23.188,
      price_pc_ytd: -23.188,
    },
    8491: {
      id: "8491",
      c: 0.73,
      ma50: 0.84,
      ma100: 0.896,
      ma150: 0.872,
      ma200: 0.904,
      maw30: 0.87,
      rs: 31.0,
      rsd1d: -7.0,
      rsd5d: -5.0,
      rsd10d: 9.0,
      price_5d_h: 0.8,
      price_5d_L: 0.73,
      price_1m_h: 0.86,
      price_1m_l: 0.71,
      price_3m_h: 1.02,
      price_3m_l: 0.71,
      price_6m_h: 1.27,
      price_6m_l: 0.71,
      price_52w_h: 2.2,
      price_52w_l: 0.71,
      price_pc_1d: -7.595,
      price_pc_1w: -9.877,
      price_pc_1m: -13.095,
      price_pc_6m: -2.667,
      price_pc_1y: -51.333,
      price_pc_ytd: -51.333,
    },
    8495: {
      id: "8495",
      c: 0.4,
      ma50: 0.34,
      ma100: 0.339,
      ma150: 0.349,
      ma200: 0.309,
      maw30: 0.355,
      rs: 95.0,
      rsd1d: 0.0,
      rsd5d: 5.0,
      rsd10d: 2.0,
      price_5d_h: 0.4,
      price_5d_L: 0.31,
      price_1m_h: 0.4,
      price_1m_l: 0.3,
      price_3m_h: 0.4,
      price_3m_l: 0.28,
      price_6m_h: 0.53,
      price_6m_l: 0.28,
      price_52w_h: 0.53,
      price_52w_l: 0.174,
      price_pc_1d: 0.0,
      price_pc_1w: 29.032,
      price_pc_1m: 11.111,
      price_pc_6m: -2.439,
      price_pc_1y: 116.216,
      price_pc_ytd: 116.216,
    },
    8507: {
      id: "8507",
      c: 0.295,
      ma50: 0.191,
      ma100: 0.193,
      ma150: 0.189,
      ma200: 0.189,
      maw30: 0.189,
      rs: 94.0,
      rsd1d: 1.0,
      rsd5d: 8.0,
      rsd10d: 13.0,
      price_5d_h: 0.33,
      price_5d_L: 0.185,
      price_1m_h: 0.33,
      price_1m_l: 0.15,
      price_3m_h: 0.33,
      price_3m_l: 0.15,
      price_6m_h: 0.33,
      price_6m_l: 0.15,
      price_52w_h: 0.58,
      price_52w_l: 0.15,
      price_pc_1d: 7.273,
      price_pc_1w: 46.04,
      price_pc_1m: 55.263,
      price_pc_6m: 62.088,
      price_pc_1y: 56.915,
      price_pc_ytd: 56.915,
    },
    8509: {
      id: "8509",
      c: 0.405,
      ma50: 0.447,
      ma100: 0.476,
      ma150: 0.523,
      ma200: 0.577,
      maw30: 0.519,
      rs: 24.0,
      rsd1d: 4.0,
      rsd5d: 9.0,
      rsd10d: -4.0,
      price_5d_h: 0.41,
      price_5d_L: 0.385,
      price_1m_h: 0.435,
      price_1m_l: 0.305,
      price_3m_h: 0.55,
      price_3m_l: 0.305,
      price_6m_h: 0.66,
      price_6m_l: 0.305,
      price_52w_h: 1.03,
      price_52w_l: 0.305,
      price_pc_1d: 1.25,
      price_pc_1w: 6.579,
      price_pc_1m: -5.814,
      price_pc_6m: -38.636,
      price_pc_1y: -13.83,
      price_pc_ytd: -13.83,
    },
    8510: {
      id: "8510",
      c: 0.04,
      ma50: 0.055,
      ma100: 0.062,
      ma150: 0.069,
      ma200: 0.075,
      maw30: 0.069,
      rs: 8.0,
      rsd1d: -6.0,
      rsd5d: -5.0,
      rsd10d: -5.0,
      price_5d_h: 0.05,
      price_5d_L: 0.04,
      price_1m_h: 0.067,
      price_1m_l: 0.037,
      price_3m_h: 0.078,
      price_3m_l: 0.037,
      price_6m_h: 0.13,
      price_6m_l: 0.037,
      price_52w_h: 0.15,
      price_52w_l: 0.037,
      price_pc_1d: -20.0,
      price_pc_1w: -20.0,
      price_pc_1m: -35.484,
      price_pc_6m: -56.989,
      price_pc_1y: -63.303,
      price_pc_ytd: -63.303,
    },
    8511: {
      id: "8511",
      c: 0.29,
      ma50: 0.415,
      ma100: 0.395,
      ma150: 0.321,
      ma200: 0.283,
      maw30: 0.323,
      rs: 95.0,
      rsd1d: -2.0,
      rsd5d: -2.0,
      rsd10d: -2.0,
      price_5d_h: 0.43,
      price_5d_L: 0.28,
      price_1m_h: 0.47,
      price_1m_l: 0.26,
      price_3m_h: 0.52,
      price_3m_l: 0.26,
      price_6m_h: 0.52,
      price_6m_l: 0.139,
      price_52w_h: 0.54,
      price_52w_l: 0.1,
      price_pc_1d: -17.143,
      price_pc_1w: -27.5,
      price_pc_1m: -27.5,
      price_pc_6m: 84.713,
      price_pc_1y: 104.225,
      price_pc_ytd: 104.225,
    },
    8513: {
      id: "8513",
      c: 0.13,
      ma50: 0.197,
      ma100: 0.264,
      ma150: 0.303,
      ma200: 0.332,
      maw30: 0.304,
      rs: 2.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: -1.0,
      price_5d_h: 0.149,
      price_5d_L: 0.13,
      price_1m_h: 0.209,
      price_1m_l: 0.101,
      price_3m_h: 0.42,
      price_3m_l: 0.101,
      price_6m_h: 0.42,
      price_6m_l: 0.101,
      price_52w_h: 0.65,
      price_52w_l: 0.101,
      price_pc_1d: -2.256,
      price_pc_1w: 8.333,
      price_pc_1m: -34.673,
      price_pc_6m: -64.384,
      price_pc_1y: -74.51,
      price_pc_ytd: -74.51,
    },
    8516: {
      id: "8516",
      c: 3.1,
      ma50: 1.91,
      ma100: 1.389,
      ma150: 1.248,
      ma200: 1.252,
      maw30: 1.208,
      rs: 98.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: 0.0,
      price_5d_h: 3.1,
      price_5d_L: 2.25,
      price_1m_h: 3.1,
      price_1m_l: 2.01,
      price_3m_h: 3.1,
      price_3m_l: 0.8,
      price_6m_h: 3.1,
      price_6m_l: 0.68,
      price_52w_h: 3.1,
      price_52w_l: 0.68,
      price_pc_1d: 2.99,
      price_pc_1w: 34.783,
      price_pc_1m: 12.727,
      price_pc_6m: 219.588,
      price_pc_1y: 160.504,
      price_pc_ytd: 160.504,
    },
    8519: {
      id: "8519",
      c: 0.088,
      ma50: 0.092,
      ma100: 0.098,
      ma150: 0.102,
      ma200: 0.105,
      maw30: 0.103,
      rs: 27.0,
      rsd1d: -4.0,
      rsd5d: -7.0,
      rsd10d: 1.0,
      price_5d_h: 0.094,
      price_5d_L: 0.082,
      price_1m_h: 0.094,
      price_1m_l: 0.073,
      price_3m_h: 0.11,
      price_3m_l: 0.073,
      price_6m_h: 0.129,
      price_6m_l: 0.073,
      price_52w_h: 0.16,
      price_52w_l: 0.073,
      price_pc_1d: -3.297,
      price_pc_1w: -3.297,
      price_pc_1m: -6.383,
      price_pc_6m: -22.807,
      price_pc_1y: -35.294,
      price_pc_ytd: -35.294,
    },
    8523: {
      id: "8523",
      c: 0.205,
      ma50: 0.211,
      ma100: 0.252,
      ma150: 0.289,
      ma200: 0.318,
      maw30: 0.285,
      rs: 14.0,
      rsd1d: 2.0,
      rsd5d: 3.0,
      rsd10d: -2.0,
      price_5d_h: 0.345,
      price_5d_L: 0.196,
      price_1m_h: 0.345,
      price_1m_l: 0.196,
      price_3m_h: 0.345,
      price_3m_l: 0.196,
      price_6m_h: 0.485,
      price_6m_l: 0.189,
      price_52w_h: 0.62,
      price_52w_l: 0.189,
      price_pc_1d: 0.0,
      price_pc_1w: 2.5,
      price_pc_1m: -2.381,
      price_pc_6m: -41.429,
      price_pc_1y: -66.935,
      price_pc_ytd: -66.935,
    },
    8526: {
      id: "8526",
      c: 0.86,
      ma50: 1.114,
      ma100: 0.962,
      ma150: 0.878,
      ma200: 0.87,
      maw30: 0.895,
      rs: 91.0,
      rsd1d: -2.0,
      rsd5d: -5.0,
      rsd10d: -5.0,
      price_5d_h: 1.15,
      price_5d_L: 0.86,
      price_1m_h: 1.23,
      price_1m_l: 0.86,
      price_3m_h: 1.72,
      price_3m_l: 0.796,
      price_6m_h: 1.72,
      price_6m_l: 0.564,
      price_52w_h: 1.76,
      price_52w_l: 0.35,
      price_pc_1d: -6.522,
      price_pc_1w: -23.214,
      price_pc_1m: -28.333,
      price_pc_6m: 35.22,
      price_pc_1y: 120.513,
      price_pc_ytd: 120.513,
    },
    8527: {
      id: "8527",
      c: 1.88,
      ma50: 2.842,
      ma100: 2.849,
      ma150: 2.984,
      ma200: 2.881,
      maw30: 3.021,
      rs: 86.0,
      rsd1d: 3.0,
      rsd5d: -10.0,
      rsd10d: -12.0,
      price_5d_h: 2.44,
      price_5d_L: 1.76,
      price_1m_h: 3.04,
      price_1m_l: 1.76,
      price_3m_h: 3.35,
      price_3m_l: 1.76,
      price_6m_h: 3.4,
      price_6m_l: 1.76,
      price_52w_h: 3.47,
      price_52w_l: 0.68,
      price_pc_1d: 1.622,
      price_pc_1w: -21.339,
      price_pc_1m: -37.333,
      price_pc_6m: -42.857,
      price_pc_1y: 208.197,
      price_pc_ytd: 208.197,
    },
    8532: {
      id: "8532",
      c: 0.085,
      ma50: 0.087,
      ma100: 0.091,
      ma150: 0.092,
      ma200: 0.089,
      maw30: 0.093,
      rs: 75.0,
      rsd1d: 15.0,
      rsd5d: 31.0,
      rsd10d: 17.0,
      price_5d_h: 0.085,
      price_5d_L: 0.072,
      price_1m_h: 0.087,
      price_1m_l: 0.066,
      price_3m_h: 0.115,
      price_3m_l: 0.066,
      price_6m_h: 0.138,
      price_6m_l: 0.066,
      price_52w_h: 0.138,
      price_52w_l: 0.066,
      price_pc_1d: 7.595,
      price_pc_1w: 16.438,
      price_pc_1m: -4.494,
      price_pc_6m: -5.556,
      price_pc_1y: 11.842,
      price_pc_ytd: 11.842,
    },
    8536: {
      id: "8536",
      c: 0.228,
      ma50: 0.286,
      ma100: 0.352,
      ma150: 2.598,
      ma200: 2.487,
      maw30: 2.771,
      rs: 5.0,
      rsd1d: 2.0,
      rsd5d: 2.0,
      rsd10d: 0.0,
      price_5d_h: 0.235,
      price_5d_L: 0.208,
      price_1m_h: 0.29,
      price_1m_l: 0.194,
      price_3m_h: 0.7,
      price_3m_l: 0.194,
      price_6m_h: 11.68,
      price_6m_l: 0.194,
      price_52w_h: 11.68,
      price_52w_l: 0.194,
      price_pc_1d: 3.636,
      price_pc_1w: 3.167,
      price_pc_1m: -21.379,
      price_pc_6m: -97.946,
      price_pc_1y: -43.0,
      price_pc_ytd: -43.0,
    },
    8545: {
      id: "8545",
      c: 0.049,
      ma50: 0.056,
      ma100: 0.078,
      ma150: 0.088,
      ma200: 0.089,
      maw30: 0.089,
      rs: 9.0,
      rsd1d: 1.0,
      rsd5d: -1.0,
      rsd10d: -3.0,
      price_5d_h: 0.054,
      price_5d_L: 0.046,
      price_1m_h: 0.064,
      price_1m_l: 0.045,
      price_3m_h: 0.089,
      price_3m_l: 0.045,
      price_6m_h: 0.145,
      price_6m_l: 0.045,
      price_52w_h: 0.165,
      price_52w_l: 0.045,
      price_pc_1d: 0.0,
      price_pc_1w: -7.547,
      price_pc_1m: -12.5,
      price_pc_6m: -56.25,
      price_pc_1y: -48.421,
      price_pc_ytd: -48.421,
    },
    8547: {
      id: "8547",
      c: 0.11,
      ma50: 0.081,
      ma100: 0.068,
      ma150: 0.1,
      ma200: 0.115,
      maw30: 0.1,
      rs: 94.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: 3.0,
      price_5d_h: 0.13,
      price_5d_L: 0.081,
      price_1m_h: 0.149,
      price_1m_l: 0.072,
      price_3m_h: 0.149,
      price_3m_l: 0.04,
      price_6m_h: 0.25,
      price_6m_l: 0.038,
      price_52w_h: 0.25,
      price_52w_l: 0.038,
      price_pc_1d: -2.655,
      price_pc_1w: 0.0,
      price_pc_1m: 32.53,
      price_pc_6m: -34.132,
      price_pc_1y: -9.836,
      price_pc_ytd: -9.836,
    },
    8562: {
      id: "8562",
      c: 0.195,
      ma50: null,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 73.0,
      rsd1d: -5.0,
      rsd5d: 1.0,
      rsd10d: null,
      price_5d_h: 0.216,
      price_5d_L: 0.181,
      price_1m_h: 0.216,
      price_1m_l: 0.156,
      price_3m_h: 0.216,
      price_3m_l: 0.156,
      price_6m_h: 0.216,
      price_6m_l: 0.156,
      price_52w_h: 0.216,
      price_52w_l: 0.156,
      price_pc_1d: -2.5,
      price_pc_1w: -1.515,
      price_pc_1m: -0.51,
      price_pc_6m: -0.51,
      price_pc_1y: -0.51,
      price_pc_ytd: -0.51,
    },
    8563: {
      id: "8563",
      c: 0.32,
      ma50: null,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 95.0,
      rsd1d: 2.0,
      rsd5d: 19.0,
      rsd10d: null,
      price_5d_h: 0.98,
      price_5d_L: 0.214,
      price_1m_h: 0.98,
      price_1m_l: 0.19,
      price_3m_h: 0.98,
      price_3m_l: 0.19,
      price_6m_h: 0.98,
      price_6m_l: 0.19,
      price_52w_h: 0.98,
      price_52w_l: 0.19,
      price_pc_1d: 12.281,
      price_pc_1w: 49.533,
      price_pc_1m: 50.943,
      price_pc_6m: 50.943,
      price_pc_1y: 50.943,
      price_pc_ytd: 50.943,
    },
    8568: {
      id: "8568",
      c: 0.8,
      ma50: null,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 70.0,
      rsd1d: -12.0,
      rsd5d: 17.0,
      rsd10d: null,
      price_5d_h: 0.9,
      price_5d_L: 0.65,
      price_1m_h: 0.94,
      price_1m_l: 0.65,
      price_3m_h: 0.94,
      price_3m_l: 0.65,
      price_6m_h: 0.94,
      price_6m_l: 0.65,
      price_52w_h: 0.94,
      price_52w_l: 0.65,
      price_pc_1d: -6.977,
      price_pc_1w: 8.108,
      price_pc_1m: -2.439,
      price_pc_6m: -2.439,
      price_pc_1y: -2.439,
      price_pc_ytd: -2.439,
    },
    8601: {
      id: "8601",
      c: 0.25,
      ma50: 0.288,
      ma100: 0.325,
      ma150: 0.343,
      ma200: 0.329,
      maw30: 0.342,
      rs: 44.0,
      rsd1d: -6.0,
      rsd5d: -37.0,
      rsd10d: -9.0,
      price_5d_h: 0.38,
      price_5d_L: 0.25,
      price_1m_h: 0.38,
      price_1m_l: 0.23,
      price_3m_h: 0.38,
      price_3m_l: 0.23,
      price_6m_h: 0.9,
      price_6m_l: 0.23,
      price_52w_h: 0.9,
      price_52w_l: 0.2,
      price_pc_1d: -3.846,
      price_pc_1w: -19.355,
      price_pc_1m: -13.793,
      price_pc_6m: -28.571,
      price_pc_1y: -3.846,
      price_pc_ytd: -3.846,
    },
    8606: {
      id: "8606",
      c: 0.113,
      ma50: 0.16,
      ma100: 0.193,
      ma150: 0.19,
      ma200: 0.236,
      maw30: 0.191,
      rs: 4.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: 1.0,
      price_5d_h: 0.12,
      price_5d_L: 0.108,
      price_1m_h: 0.16,
      price_1m_l: 0.105,
      price_3m_h: 0.232,
      price_3m_l: 0.105,
      price_6m_h: 0.32,
      price_6m_l: 0.105,
      price_52w_h: 1.62,
      price_52w_l: 0.105,
      price_pc_1d: 0.0,
      price_pc_1w: -4.237,
      price_pc_1m: -29.375,
      price_pc_6m: -22.069,
      price_pc_1y: -93.152,
      price_pc_ytd: -93.152,
    },
    8609: {
      id: "8609",
      c: 0.246,
      ma50: 0.26,
      ma100: 0.254,
      ma150: 0.267,
      ma200: 0.282,
      maw30: 0.267,
      rs: 49.0,
      rsd1d: 4.0,
      rsd5d: 7.0,
      rsd10d: 4.0,
      price_5d_h: 0.248,
      price_5d_L: 0.246,
      price_1m_h: 0.3,
      price_1m_l: 0.24,
      price_3m_h: 0.3,
      price_3m_l: 0.222,
      price_6m_h: 0.305,
      price_6m_l: 0.221,
      price_52w_h: 0.385,
      price_52w_l: 0.221,
      price_pc_1d: -0.806,
      price_pc_1w: 0.0,
      price_pc_1m: -16.61,
      price_pc_6m: -20.645,
      price_pc_1y: -31.667,
      price_pc_ytd: -31.667,
    },
    8611: {
      id: "8611",
      c: 0.1,
      ma50: 0.106,
      ma100: 0.106,
      ma150: 0.1,
      ma200: 0.096,
      maw30: 0.099,
      rs: 89.0,
      rsd1d: -2.0,
      rsd5d: -2.0,
      rsd10d: -4.0,
      price_5d_h: 0.118,
      price_5d_L: 0.097,
      price_1m_h: 0.118,
      price_1m_l: 0.092,
      price_3m_h: 0.135,
      price_3m_l: 0.085,
      price_6m_h: 0.184,
      price_6m_l: 0.076,
      price_52w_h: 0.184,
      price_52w_l: 0.071,
      price_pc_1d: -3.846,
      price_pc_1w: -5.66,
      price_pc_1m: -13.043,
      price_pc_6m: 31.579,
      price_pc_1y: 17.647,
      price_pc_ytd: 17.647,
    },
    8612: {
      id: "8612",
      c: 0.095,
      ma50: 0.103,
      ma100: 0.165,
      ma150: 0.181,
      ma200: 0.207,
      maw30: 0.173,
      rs: 16.0,
      rsd1d: 4.0,
      rsd5d: 8.0,
      rsd10d: 12.0,
      price_5d_h: 0.103,
      price_5d_L: 0.089,
      price_1m_h: 0.135,
      price_1m_l: 0.086,
      price_3m_h: 0.135,
      price_3m_l: 0.066,
      price_6m_h: 0.64,
      price_6m_l: 0.066,
      price_52w_h: 0.64,
      price_52w_l: 0.066,
      price_pc_1d: 5.556,
      price_pc_1w: -4.04,
      price_pc_1m: -11.215,
      price_pc_6m: -52.5,
      price_pc_1y: -47.222,
      price_pc_ytd: -47.222,
    },
    8613: {
      id: "8613",
      c: 0.188,
      ma50: 0.165,
      ma100: 0.138,
      ma150: 0.119,
      ma200: 0.11,
      maw30: 0.119,
      rs: 97.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.2,
      price_5d_L: 0.18,
      price_1m_h: 0.232,
      price_1m_l: 0.168,
      price_3m_h: 0.24,
      price_3m_l: 0.08,
      price_6m_h: 0.24,
      price_6m_l: 0.077,
      price_52w_h: 0.24,
      price_52w_l: 0.075,
      price_pc_1d: 0.0,
      price_pc_1w: -3.093,
      price_pc_1m: -20.0,
      price_pc_6m: 135.0,
      price_pc_1y: 111.236,
      price_pc_ytd: 111.236,
    },
    8616: {
      id: "8616",
      c: 0.11,
      ma50: 0.116,
      ma100: 0.122,
      ma150: 0.127,
      ma200: 0.121,
      maw30: 0.127,
      rs: 75.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: 26.0,
      price_5d_h: 0.115,
      price_5d_L: 0.106,
      price_1m_h: 0.122,
      price_1m_l: 0.087,
      price_3m_h: 0.13,
      price_3m_l: 0.087,
      price_6m_h: 0.155,
      price_6m_l: 0.087,
      price_52w_h: 0.169,
      price_52w_l: 0.078,
      price_pc_1d: 0.0,
      price_pc_1w: 1.852,
      price_pc_1m: -8.333,
      price_pc_6m: -21.429,
      price_pc_1y: 42.857,
      price_pc_ytd: 42.857,
    },
    8617: {
      id: "8617",
      c: 1.2,
      ma50: 0.695,
      ma100: 0.608,
      ma150: 0.594,
      ma200: 0.6,
      maw30: 0.58,
      rs: 98.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: 3.0,
      price_5d_h: 1.3,
      price_5d_L: 1.03,
      price_1m_h: 1.3,
      price_1m_l: 0.61,
      price_3m_h: 1.3,
      price_3m_l: 0.51,
      price_6m_h: 1.3,
      price_6m_l: 0.5,
      price_52w_h: 1.3,
      price_52w_l: 0.5,
      price_pc_1d: -4.0,
      price_pc_1w: 12.15,
      price_pc_1m: 96.721,
      price_pc_6m: 126.415,
      price_pc_1y: 126.415,
      price_pc_ytd: 126.415,
    },
    8619: {
      id: "8619",
      c: 0.083,
      ma50: 0.099,
      ma100: 0.148,
      ma150: 0.144,
      ma200: 0.134,
      maw30: 0.145,
      rs: 18.0,
      rsd1d: 6.0,
      rsd5d: -8.0,
      rsd10d: -16.0,
      price_5d_h: 0.114,
      price_5d_L: 0.079,
      price_1m_h: 0.12,
      price_1m_l: 0.079,
      price_3m_h: 0.187,
      price_3m_l: 0.066,
      price_6m_h: 0.32,
      price_6m_l: 0.066,
      price_52w_h: 0.32,
      price_52w_l: 0.066,
      price_pc_1d: 3.75,
      price_pc_1w: -20.952,
      price_pc_1m: -16.162,
      price_pc_6m: -24.545,
      price_pc_1y: -53.107,
      price_pc_ytd: -53.107,
    },
    8620: {
      id: "8620",
      c: 0.209,
      ma50: 0.233,
      ma100: 0.243,
      ma150: 0.23,
      ma200: 0.211,
      maw30: 0.234,
      rs: 85.0,
      rsd1d: -2.0,
      rsd5d: -2.0,
      rsd10d: -5.0,
      price_5d_h: 0.212,
      price_5d_L: 0.209,
      price_1m_h: 0.244,
      price_1m_l: 0.19,
      price_3m_h: 0.28,
      price_3m_l: 0.19,
      price_6m_h: 0.385,
      price_6m_l: 0.17,
      price_52w_h: 0.385,
      price_52w_l: 0.14,
      price_pc_1d: 0.0,
      price_pc_1w: -1.415,
      price_pc_1m: -12.552,
      price_pc_6m: 16.111,
      price_pc_1y: 30.625,
      price_pc_ytd: 30.625,
    },
    8622: {
      id: "8622",
      c: 0.218,
      ma50: 0.235,
      ma100: 0.242,
      ma150: 0.243,
      ma200: 0.227,
      maw30: 0.243,
      rs: 93.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: -2.0,
      price_5d_h: 0.232,
      price_5d_L: 0.2,
      price_1m_h: 0.239,
      price_1m_l: 0.195,
      price_3m_h: 0.265,
      price_3m_l: 0.195,
      price_6m_h: 0.3,
      price_6m_l: 0.195,
      price_52w_h: 0.375,
      price_52w_l: 0.091,
      price_pc_1d: -2.679,
      price_pc_1w: 0.0,
      price_pc_1m: -12.8,
      price_pc_6m: -14.51,
      price_pc_1y: 129.474,
      price_pc_ytd: 129.474,
    },
    8623: {
      id: "8623",
      c: 0.079,
      ma50: 0.079,
      ma100: 0.08,
      ma150: 0.083,
      ma200: 0.081,
      maw30: 0.083,
      rs: 80.0,
      rsd1d: -1.0,
      rsd5d: 1.0,
      rsd10d: 9.0,
      price_5d_h: 0.079,
      price_5d_L: 0.079,
      price_1m_h: 0.08,
      price_1m_l: 0.068,
      price_3m_h: 0.088,
      price_3m_l: 0.068,
      price_6m_h: 0.105,
      price_6m_l: 0.068,
      price_52w_h: 0.108,
      price_52w_l: 0.065,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: 2.597,
      price_pc_6m: -5.952,
      price_pc_1y: 9.722,
      price_pc_ytd: 9.722,
    },
    8627: {
      id: "8627",
      c: 0.204,
      ma50: 0.2,
      ma100: 0.201,
      ma150: 0.218,
      ma200: 0.226,
      maw30: 0.217,
      rs: 58.0,
      rsd1d: 9.0,
      rsd5d: 6.0,
      rsd10d: 16.0,
      price_5d_h: 0.205,
      price_5d_L: 0.197,
      price_1m_h: 0.212,
      price_1m_l: 0.18,
      price_3m_h: 0.22,
      price_3m_l: 0.145,
      price_6m_h: 0.26,
      price_6m_l: 0.145,
      price_52w_h: 0.33,
      price_52w_l: 0.145,
      price_pc_1d: 1.493,
      price_pc_1w: 2.0,
      price_pc_1m: -3.318,
      price_pc_6m: -18.072,
      price_pc_1y: -33.115,
      price_pc_ytd: -33.115,
    },
    8645: {
      id: "8645",
      c: 0.94,
      ma50: 0.804,
      ma100: 0.656,
      ma150: 0.572,
      ma200: 0.506,
      maw30: 0.578,
      rs: 98.0,
      rsd1d: 0.0,
      rsd5d: -1.0,
      rsd10d: -1.0,
      price_5d_h: 1.01,
      price_5d_L: 0.85,
      price_1m_h: 1.12,
      price_1m_l: 0.72,
      price_3m_h: 1.12,
      price_3m_l: 0.5,
      price_6m_h: 1.12,
      price_6m_l: 0.355,
      price_52w_h: 1.12,
      price_52w_l: 0.11,
      price_pc_1d: 1.075,
      price_pc_1w: -4.082,
      price_pc_1m: 20.513,
      price_pc_6m: 141.026,
      price_pc_1y: 576.259,
      price_pc_ytd: 576.259,
    },
    8646: {
      id: "8646",
      c: 0.37,
      ma50: 0.338,
      ma100: 0.342,
      ma150: 0.324,
      ma200: 0.322,
      maw30: 0.322,
      rs: 87.0,
      rsd1d: 2.0,
      rsd5d: 17.0,
      rsd10d: 15.0,
      price_5d_h: 0.38,
      price_5d_L: 0.31,
      price_1m_h: 0.39,
      price_1m_l: 0.29,
      price_3m_h: 0.39,
      price_3m_l: 0.27,
      price_6m_h: 0.39,
      price_6m_l: 0.25,
      price_52w_h: 0.395,
      price_52w_l: 0.25,
      price_pc_1d: 0.0,
      price_pc_1w: 13.846,
      price_pc_1m: 15.625,
      price_pc_6m: 27.586,
      price_pc_1y: 5.714,
      price_pc_ytd: 5.714,
    },
    8657: {
      id: "8657",
      c: 0.88,
      ma50: 0.865,
      ma100: 0.907,
      ma150: 0.922,
      ma200: 0.944,
      maw30: 0.917,
      rs: 48.0,
      rsd1d: 8.0,
      rsd5d: 20.0,
      rsd10d: 17.0,
      price_5d_h: 0.93,
      price_5d_L: 0.8,
      price_1m_h: 0.93,
      price_1m_l: 0.7,
      price_3m_h: 0.96,
      price_3m_l: 0.7,
      price_6m_h: 1.0,
      price_6m_l: 0.7,
      price_52w_h: 1.41,
      price_52w_l: 0.7,
      price_pc_1d: 3.529,
      price_pc_1w: 10.0,
      price_pc_1m: 2.326,
      price_pc_6m: -11.111,
      price_pc_1y: -29.032,
      price_pc_ytd: -29.032,
    },
    8659: {
      id: "8659",
      c: 0.285,
      ma50: 0.204,
      ma100: 0.204,
      ma150: 0.194,
      ma200: 0.189,
      maw30: 0.193,
      rs: 94.0,
      rsd1d: 0.0,
      rsd5d: 9.0,
      rsd10d: 8.0,
      price_5d_h: 0.31,
      price_5d_L: 0.205,
      price_1m_h: 0.31,
      price_1m_l: 0.19,
      price_3m_h: 0.31,
      price_3m_l: 0.171,
      price_6m_h: 0.31,
      price_6m_l: 0.162,
      price_52w_h: 0.31,
      price_52w_l: 0.156,
      price_pc_1d: 9.615,
      price_pc_1w: 38.35,
      price_pc_1m: 46.907,
      price_pc_6m: 69.643,
      price_pc_1y: 53.226,
      price_pc_ytd: 53.226,
    },
    9001: {
      id: "9001",
      c: 24.2,
      ma50: 25.382,
      ma100: 29.823,
      ma150: 33.837,
      ma200: 37.398,
      maw30: 33.703,
      rs: 13.0,
      rsd1d: 3.0,
      rsd5d: 6.0,
      rsd10d: 4.0,
      price_5d_h: 24.2,
      price_5d_L: 20.5,
      price_1m_h: 25.5,
      price_1m_l: 17.9,
      price_3m_h: 34.1,
      price_3m_l: 17.9,
      price_6m_h: 42.65,
      price_6m_l: 17.9,
      price_52w_h: 50.5,
      price_52w_l: 17.9,
      price_pc_1d: 4.31,
      price_pc_1w: 17.191,
      price_pc_1m: -4.536,
      price_pc_6m: -43.59,
      price_pc_1y: -51.6,
      price_pc_ytd: -51.6,
    },
    9010: {
      id: "9010",
      c: 7.33,
      ma50: 7.49,
      ma100: 7.742,
      ma150: 7.938,
      ma200: 8.107,
      maw30: 7.95,
      rs: 52.0,
      rsd1d: 2.0,
      rsd5d: 5.0,
      rsd10d: 1.0,
      price_5d_h: 7.335,
      price_5d_L: 7.19,
      price_1m_h: 7.55,
      price_1m_l: 6.64,
      price_3m_h: 8.13,
      price_3m_l: 6.64,
      price_6m_h: 8.49,
      price_6m_l: 6.64,
      price_52w_h: 9.15,
      price_52w_l: 6.64,
      price_pc_1d: -0.068,
      price_pc_1w: 1.103,
      price_pc_1m: -1.808,
      price_pc_6m: -10.061,
      price_pc_1y: -17.222,
      price_pc_ytd: -17.222,
    },
    9011: {
      id: "9011",
      c: 1030.7,
      ma50: 1030.65,
      ma100: 1030.13,
      ma150: 1029.76,
      ma200: 1029.41,
      maw30: 1029.8,
      rs: 74.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: -4.0,
      price_5d_h: 1030.7,
      price_5d_L: 1030.65,
      price_1m_h: 1030.7,
      price_1m_l: 1030.6,
      price_3m_h: 1030.7,
      price_3m_l: 1029.8,
      price_6m_h: 1030.7,
      price_6m_l: 1029.05,
      price_52w_h: 1030.7,
      price_52w_l: 1000.0,
      price_pc_1d: 0.0,
      price_pc_1w: 0.01,
      price_pc_1m: 0.0,
      price_pc_6m: 0.165,
      price_pc_1y: 0.341,
      price_pc_ytd: 0.341,
    },
    9022: {
      id: "9022",
      c: 66.12,
      ma50: 67.634,
      ma100: 69.603,
      ma150: 71.374,
      ma200: 72.67,
      maw30: 71.509,
      rs: 54.0,
      rsd1d: 1.0,
      rsd5d: 3.0,
      rsd10d: 4.0,
      price_5d_h: 66.42,
      price_5d_L: 65.04,
      price_1m_h: 68.3,
      price_1m_l: 58.82,
      price_3m_h: 73.1,
      price_3m_l: 58.82,
      price_6m_h: 76.28,
      price_6m_l: 58.82,
      price_52w_h: 80.1,
      price_52w_l: 58.82,
      price_pc_1d: -0.241,
      price_pc_1w: 0.916,
      price_pc_1m: -1.753,
      price_pc_6m: -10.065,
      price_pc_1y: -14.197,
      price_pc_ytd: -14.197,
    },
    9031: {
      id: "9031",
      c: 1.454,
      ma50: 1.533,
      ma100: 1.608,
      ma150: 1.627,
      ma200: 1.64,
      maw30: 1.632,
      rs: 50.0,
      rsd1d: 3.0,
      rsd5d: 1.0,
      rsd10d: 0.0,
      price_5d_h: 1.454,
      price_5d_L: 1.418,
      price_1m_h: 1.584,
      price_1m_l: 1.334,
      price_3m_h: 1.694,
      price_3m_l: 1.334,
      price_6m_h: 1.75,
      price_6m_l: 1.334,
      price_52w_h: 1.768,
      price_52w_l: 1.334,
      price_pc_1d: 0.0,
      price_pc_1w: -0.274,
      price_pc_1m: -7.858,
      price_pc_6m: -11.665,
      price_pc_1y: -9.577,
      price_pc_ytd: -9.577,
    },
    9047: {
      id: "9047",
      c: 2.024,
      ma50: 1.842,
      ma100: 1.68,
      ma150: 1.695,
      ma200: 1.871,
      maw30: 1.688,
      rs: 84.0,
      rsd1d: -3.0,
      rsd5d: 2.0,
      rsd10d: -2.0,
      price_5d_h: 2.024,
      price_5d_L: 1.96,
      price_1m_h: 2.026,
      price_1m_l: 1.638,
      price_3m_h: 2.026,
      price_3m_l: 1.58,
      price_6m_h: 2.026,
      price_6m_l: 1.282,
      price_52w_h: 2.884,
      price_52w_l: 1.282,
      price_pc_1d: 0.0,
      price_pc_1w: 5.198,
      price_pc_1m: 23.565,
      price_pc_6m: 15.657,
      price_pc_1y: -4.887,
      price_pc_ytd: -4.887,
    },
    9060: {
      id: "9060",
      c: 8.85,
      ma50: null,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 72.0,
      rsd1d: -1.0,
      rsd5d: 7.0,
      rsd10d: null,
      price_5d_h: 9.0,
      price_5d_L: 8.6,
      price_1m_h: 9.015,
      price_1m_l: 8.6,
      price_3m_h: 9.015,
      price_3m_l: 8.6,
      price_6m_h: 9.015,
      price_6m_l: 8.6,
      price_52w_h: 9.015,
      price_52w_l: 8.6,
      price_pc_1d: -1.007,
      price_pc_1w: 2.787,
      price_pc_1m: -1.117,
      price_pc_6m: -1.117,
      price_pc_1y: -1.117,
      price_pc_ytd: -1.117,
    },
    9067: {
      id: "9067",
      c: 1.214,
      ma50: 1.345,
      ma100: 1.469,
      ma150: 1.552,
      ma200: 1.648,
      maw30: 1.555,
      rs: 21.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: 0.0,
      price_5d_h: 1.256,
      price_5d_L: 1.154,
      price_1m_h: 1.382,
      price_1m_l: 0.926,
      price_3m_h: 1.578,
      price_3m_l: 0.926,
      price_6m_h: 1.824,
      price_6m_l: 0.926,
      price_52w_h: 2.408,
      price_52w_l: 0.926,
      price_pc_1d: -1.461,
      price_pc_1w: -1.14,
      price_pc_1m: -10.997,
      price_pc_6m: -25.521,
      price_pc_1y: -46.987,
      price_pc_ytd: -46.987,
    },
    9069: {
      id: "9069",
      c: 1.676,
      ma50: 1.774,
      ma100: 2.027,
      ma150: 2.251,
      ma200: null,
      maw30: 2.251,
      rs: 20.0,
      rsd1d: -2.0,
      rsd5d: -1.0,
      rsd10d: 3.0,
      price_5d_h: 1.738,
      price_5d_L: 1.65,
      price_1m_h: 1.842,
      price_1m_l: 1.296,
      price_3m_h: 2.144,
      price_3m_l: 1.296,
      price_6m_h: 2.74,
      price_6m_l: 1.296,
      price_52w_h: 3.588,
      price_52w_l: 1.296,
      price_pc_1d: -3.567,
      price_pc_1w: -4.556,
      price_pc_1m: -8.415,
      price_pc_6m: -38.966,
      price_pc_1y: -53.289,
      price_pc_ytd: -53.289,
    },
    9072: {
      id: "9072",
      c: 15.26,
      ma50: 15.222,
      ma100: 17.407,
      ma150: 18.698,
      ma200: 19.329,
      maw30: 18.689,
      rs: 31.0,
      rsd1d: 2.0,
      rsd5d: 6.0,
      rsd10d: 5.0,
      price_5d_h: 15.36,
      price_5d_L: 14.74,
      price_1m_h: 15.36,
      price_1m_l: 12.25,
      price_3m_h: 18.94,
      price_3m_l: 12.25,
      price_6m_h: 21.98,
      price_6m_l: 12.25,
      price_52w_h: 22.14,
      price_52w_l: 12.25,
      price_pc_1d: -0.651,
      price_pc_1w: 1.733,
      price_pc_1m: 2.761,
      price_pc_6m: -25.342,
      price_pc_1y: -24.679,
      price_pc_ytd: -24.679,
    },
    9074: {
      id: "9074",
      c: 25.0,
      ma50: 25.605,
      ma100: 25.896,
      ma150: 25.744,
      ma200: 25.677,
      maw30: 25.77,
      rs: 70.0,
      rsd1d: 1.0,
      rsd5d: -2.0,
      rsd10d: -3.0,
      price_5d_h: 25.28,
      price_5d_L: 24.58,
      price_1m_h: 26.02,
      price_1m_l: 23.54,
      price_3m_h: 27.5,
      price_3m_l: 23.54,
      price_6m_h: 27.5,
      price_6m_l: 23.54,
      price_52w_h: 27.5,
      price_52w_l: 22.68,
      price_pc_1d: -0.398,
      price_pc_1w: -0.398,
      price_pc_1m: -2.267,
      price_pc_6m: -0.951,
      price_pc_1y: 3.993,
      price_pc_ytd: 3.993,
    },
    9077: {
      id: "9077",
      c: 500.65,
      ma50: 500.397,
      ma100: 500.188,
      ma150: 500.137,
      ma200: 500.146,
      maw30: 500.136,
      rs: 74.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: -4.0,
      price_5d_h: 500.65,
      price_5d_L: 500.6,
      price_1m_h: 500.65,
      price_1m_l: 500.5,
      price_3m_h: 500.65,
      price_3m_l: 500.0,
      price_6m_h: 500.65,
      price_6m_l: 499.96,
      price_52w_h: 500.65,
      price_52w_l: 499.96,
      price_pc_1d: 0.0,
      price_pc_1w: 0.02,
      price_pc_1m: 0.03,
      price_pc_6m: 0.12,
      price_pc_1y: 0.08,
      price_pc_ytd: 0.08,
    },
    9078: {
      id: "9078",
      c: 507.4,
      ma50: 507.148,
      ma100: 506.935,
      ma150: 506.85,
      ma200: 506.81,
      maw30: 506.863,
      rs: 74.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: -4.0,
      price_5d_h: 507.4,
      price_5d_L: 507.35,
      price_1m_h: 507.4,
      price_1m_l: 506.0,
      price_3m_h: 507.4,
      price_3m_l: 506.0,
      price_6m_h: 507.4,
      price_6m_l: 506.0,
      price_52w_h: 507.4,
      price_52w_l: 506.0,
      price_pc_1d: 0.0,
      price_pc_1w: 0.02,
      price_pc_1m: 0.02,
      price_pc_6m: 0.138,
      price_pc_1y: 0.148,
      price_pc_ytd: 0.148,
    },
    9081: {
      id: "9081",
      c: 5.905,
      ma50: 5.802,
      ma100: 5.673,
      ma150: 5.604,
      ma200: 5.582,
      maw30: 5.599,
      rs: 84.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: -2.0,
      price_5d_h: 5.98,
      price_5d_L: 5.885,
      price_1m_h: 6.24,
      price_1m_l: 5.83,
      price_3m_h: 6.24,
      price_3m_l: 5.48,
      price_6m_h: 6.24,
      price_6m_l: 5.37,
      price_52w_h: 6.24,
      price_52w_l: 5.32,
      price_pc_1d: 0.34,
      price_pc_1w: -0.589,
      price_pc_1m: 1.286,
      price_pc_6m: 10.996,
      price_pc_1y: 11.837,
      price_pc_ytd: 11.837,
    },
    9086: {
      id: "9086",
      c: 3.936,
      ma50: 3.704,
      ma100: 3.945,
      ma150: 3.958,
      ma200: 3.925,
      maw30: 3.957,
      rs: 77.0,
      rsd1d: 0.0,
      rsd5d: 9.0,
      rsd10d: 11.0,
      price_5d_h: 3.936,
      price_5d_L: 3.822,
      price_1m_h: 3.936,
      price_1m_l: 3.38,
      price_3m_h: 4.276,
      price_3m_l: 3.38,
      price_6m_h: 4.308,
      price_6m_l: 3.38,
      price_52w_h: 4.308,
      price_52w_l: 3.38,
      price_pc_1d: 0.0,
      price_pc_1w: 4.403,
      price_pc_1m: 8.37,
      price_pc_6m: 1.969,
      price_pc_1y: 14.752,
      price_pc_ytd: 14.752,
    },
    9088: {
      id: "9088",
      c: 0.735,
      ma50: 0.812,
      ma100: 0.887,
      ma150: 0.937,
      ma200: 0.996,
      maw30: 0.939,
      rs: 21.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: -1.0,
      price_5d_h: 0.744,
      price_5d_L: 0.706,
      price_1m_h: 0.829,
      price_1m_l: 0.594,
      price_3m_h: 0.95,
      price_3m_l: 0.594,
      price_6m_h: 1.094,
      price_6m_l: 0.594,
      price_52w_h: 1.396,
      price_52w_l: 0.594,
      price_pc_1d: -1.21,
      price_pc_1w: -0.81,
      price_pc_1m: -10.801,
      price_pc_6m: -25.456,
      price_pc_1y: -46.739,
      price_pc_ytd: -46.739,
    },
    9091: {
      id: "9091",
      c: 11.75,
      ma50: 11.846,
      ma100: 12.695,
      ma150: 12.872,
      ma200: 12.988,
      maw30: 12.888,
      rs: 51.0,
      rsd1d: 0.0,
      rsd5d: 5.0,
      rsd10d: 2.0,
      price_5d_h: 11.87,
      price_5d_L: 11.54,
      price_1m_h: 11.88,
      price_1m_l: 10.45,
      price_3m_h: 13.72,
      price_3m_l: 10.45,
      price_6m_h: 14.31,
      price_6m_l: 10.45,
      price_52w_h: 14.31,
      price_52w_l: 10.45,
      price_pc_1d: -1.011,
      price_pc_1w: 0.599,
      price_pc_1m: 0.427,
      price_pc_6m: -8.773,
      price_pc_1y: -11.521,
      price_pc_ytd: -11.521,
    },
    9096: {
      id: "9096",
      c: 102.7,
      ma50: 102.614,
      ma100: 102.586,
      ma150: 102.574,
      ma200: 102.557,
      maw30: 102.573,
      rs: 74.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: -4.0,
      price_5d_h: 102.7,
      price_5d_L: 102.7,
      price_1m_h: 102.7,
      price_1m_l: 102.6,
      price_3m_h: 102.7,
      price_3m_l: 102.55,
      price_6m_h: 102.7,
      price_6m_l: 102.55,
      price_52w_h: 102.7,
      price_52w_l: 102.45,
      price_pc_1d: 0.0,
      price_pc_1w: 0.0,
      price_pc_1m: 0.097,
      price_pc_6m: 0.146,
      price_pc_1y: 0.195,
      price_pc_ytd: 0.195,
    },
    9115: {
      id: "9115",
      c: 10.15,
      ma50: 10.524,
      ma100: 10.784,
      ma150: 11.113,
      ma200: 11.497,
      maw30: 11.134,
      rs: 47.0,
      rsd1d: 1.0,
      rsd5d: 3.0,
      rsd10d: 4.0,
      price_5d_h: 10.27,
      price_5d_L: 9.85,
      price_1m_h: 10.44,
      price_1m_l: 8.4,
      price_3m_h: 11.54,
      price_3m_l: 8.4,
      price_6m_h: 12.2,
      price_6m_l: 8.4,
      price_52w_h: 13.73,
      price_52w_l: 8.4,
      price_pc_1d: -0.49,
      price_pc_1w: 0.595,
      price_pc_1m: -2.591,
      price_pc_6m: -10.965,
      price_pc_1y: -24.027,
      price_pc_ytd: -24.027,
    },
    9125: {
      id: "9125",
      c: 7.975,
      ma50: 7.988,
      ma100: 7.94,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 76.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -3.0,
      price_5d_h: 7.975,
      price_5d_L: 7.955,
      price_1m_h: 8.025,
      price_1m_l: 7.94,
      price_3m_h: 8.025,
      price_3m_l: 7.925,
      price_6m_h: 8.025,
      price_6m_l: 7.82,
      price_52w_h: 8.025,
      price_52w_l: 7.82,
      price_pc_1d: 0.126,
      price_pc_1w: 0.251,
      price_pc_1m: -0.623,
      price_pc_6m: 1.982,
      price_pc_1y: 1.982,
      price_pc_ytd: 1.982,
    },
    9141: {
      id: "9141",
      c: 1.926,
      ma50: 1.962,
      ma100: 2.01,
      ma150: 2.032,
      ma200: 2.045,
      maw30: 2.033,
      rs: 61.0,
      rsd1d: 2.0,
      rsd5d: 4.0,
      rsd10d: -1.0,
      price_5d_h: 1.926,
      price_5d_L: 1.898,
      price_1m_h: 1.976,
      price_1m_l: 1.894,
      price_3m_h: 2.068,
      price_3m_l: 1.894,
      price_6m_h: 2.078,
      price_6m_l: 1.894,
      price_52w_h: 2.104,
      price_52w_l: 1.894,
      price_pc_1d: 0.313,
      price_pc_1w: 0.627,
      price_pc_1m: -2.134,
      price_pc_6m: -7.315,
      price_pc_1y: -6.776,
      price_pc_ytd: -6.776,
    },
    9151: {
      id: "9151",
      c: 1.106,
      ma50: 1.21,
      ma100: 1.313,
      ma150: 1.337,
      ma200: null,
      maw30: 1.337,
      rs: 36.0,
      rsd1d: 0.0,
      rsd5d: -2.0,
      rsd10d: -10.0,
      price_5d_h: 1.116,
      price_5d_L: 1.082,
      price_1m_h: 1.266,
      price_1m_l: 1.082,
      price_3m_h: 1.414,
      price_3m_l: 1.082,
      price_6m_h: 1.498,
      price_6m_l: 1.082,
      price_52w_h: 1.612,
      price_52w_l: 1.082,
      price_pc_1d: -1.073,
      price_pc_1w: -2.982,
      price_pc_1m: -13.323,
      price_pc_6m: -18.915,
      price_pc_1y: -27.859,
      price_pc_ytd: -27.859,
    },
    9167: {
      id: "9167",
      c: 8.47,
      ma50: 9.003,
      ma100: 9.681,
      ma150: 10.139,
      ma200: 10.65,
      maw30: 10.162,
      rs: 28.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: 1.0,
      price_5d_h: 8.47,
      price_5d_L: 8.15,
      price_1m_h: 9.14,
      price_1m_l: 6.56,
      price_3m_h: 10.37,
      price_3m_l: 6.56,
      price_6m_h: 11.66,
      price_6m_l: 6.56,
      price_52w_h: 14.05,
      price_52w_l: 6.56,
      price_pc_1d: 0.0,
      price_pc_1w: -0.994,
      price_pc_1m: -5.732,
      price_pc_6m: -20.989,
      price_pc_1y: -38.933,
      price_pc_ytd: -38.933,
    },
    9173: {
      id: "9173",
      c: 1.316,
      ma50: 1.399,
      ma100: 1.499,
      ma150: 1.509,
      ma200: 1.532,
      maw30: 1.514,
      rs: 42.0,
      rsd1d: 2.0,
      rsd5d: -1.0,
      rsd10d: -6.0,
      price_5d_h: 1.332,
      price_5d_L: 1.284,
      price_1m_h: 1.458,
      price_1m_l: 1.23,
      price_3m_h: 1.61,
      price_3m_l: 1.23,
      price_6m_h: 1.66,
      price_6m_l: 1.23,
      price_52w_h: 1.67,
      price_52w_l: 1.23,
      price_pc_1d: 0.0,
      price_pc_1w: -0.754,
      price_pc_1m: -8.99,
      price_pc_6m: -13.761,
      price_pc_1y: -12.732,
      price_pc_ytd: -12.732,
    },
    9181: {
      id: "9181",
      c: 13.29,
      ma50: 13.832,
      ma100: 15.111,
      ma150: 15.673,
      ma200: 16.14,
      maw30: 15.686,
      rs: 35.0,
      rsd1d: 2.0,
      rsd5d: 1.0,
      rsd10d: 1.0,
      price_5d_h: 13.3,
      price_5d_L: 13.07,
      price_1m_h: 14.15,
      price_1m_l: 11.59,
      price_3m_h: 16.0,
      price_3m_l: 11.59,
      price_6m_h: 17.48,
      price_6m_l: 11.59,
      price_52w_h: 18.5,
      price_52w_l: 11.59,
      price_pc_1d: -0.075,
      price_pc_1w: -1.336,
      price_pc_1m: -4.388,
      price_pc_6m: -18.864,
      price_pc_1y: -24.317,
      price_pc_ytd: -24.317,
    },
    9186: {
      id: "9186",
      c: 5.75,
      ma50: 6.199,
      ma100: 6.867,
      ma150: 7.473,
      ma200: 8.12,
      maw30: 7.49,
      rs: 18.0,
      rsd1d: 0.0,
      rsd5d: 2.0,
      rsd10d: 2.0,
      price_5d_h: 5.795,
      price_5d_L: 5.59,
      price_1m_h: 6.36,
      price_1m_l: 3.934,
      price_3m_h: 7.265,
      price_3m_l: 3.934,
      price_6m_h: 9.27,
      price_6m_l: 3.934,
      price_52w_h: 13.57,
      price_52w_l: 3.934,
      price_pc_1d: -0.777,
      price_pc_1w: -2.377,
      price_pc_1m: -9.019,
      price_pc_6m: -29.577,
      price_pc_1y: -57.627,
      price_pc_ytd: -57.627,
    },
    9187: {
      id: "9187",
      c: 2.71,
      ma50: 2.623,
      ma100: 2.683,
      ma150: 2.734,
      ma200: 2.769,
      maw30: 2.733,
      rs: 69.0,
      rsd1d: 1.0,
      rsd5d: 5.0,
      rsd10d: 5.0,
      price_5d_h: 2.716,
      price_5d_L: 2.67,
      price_1m_h: 2.716,
      price_1m_l: 2.56,
      price_3m_h: 2.76,
      price_3m_l: 2.538,
      price_6m_h: 2.872,
      price_6m_l: 2.538,
      price_52w_h: 2.972,
      price_52w_l: 2.538,
      price_pc_1d: 0.222,
      price_pc_1w: 2.033,
      price_pc_1m: 4.391,
      price_pc_6m: -2.095,
      price_pc_1y: -2.448,
      price_pc_ytd: -2.448,
    },
    9191: {
      id: "9191",
      c: 6.785,
      ma50: 7.409,
      ma100: 7.965,
      ma150: 8.083,
      ma200: 8.225,
      maw30: 8.083,
      rs: 45.0,
      rsd1d: -1.0,
      rsd5d: -9.0,
      rsd10d: -20.0,
      price_5d_h: 7.18,
      price_5d_L: 6.76,
      price_1m_h: 7.845,
      price_1m_l: 6.715,
      price_3m_h: 8.49,
      price_3m_l: 6.715,
      price_6m_h: 9.06,
      price_6m_l: 6.715,
      price_52w_h: 9.815,
      price_52w_l: 6.64,
      price_pc_1d: -1.667,
      price_pc_1w: -4.167,
      price_pc_1m: -13.622,
      price_pc_6m: -15.767,
      price_pc_1y: 0.0,
      price_pc_ytd: 0.0,
    },
    9600: {
      id: "9600",
      c: 0.98,
      ma50: 1.459,
      ma100: 1.775,
      ma150: 1.802,
      ma200: 1.899,
      maw30: 1.816,
      rs: 7.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 1.0,
      price_5d_L: 0.92,
      price_1m_h: 1.6,
      price_1m_l: 0.81,
      price_3m_h: 2.02,
      price_3m_l: 0.81,
      price_6m_h: 2.35,
      price_6m_l: 0.81,
      price_52w_h: 2.85,
      price_52w_l: 0.81,
      price_pc_1d: 0.0,
      price_pc_1w: -1.01,
      price_pc_1m: -38.75,
      price_pc_6m: -40.964,
      price_pc_1y: -58.298,
      price_pc_ytd: -58.298,
    },
    9608: {
      id: "9608",
      c: 0.096,
      ma50: 0.107,
      ma100: 0.125,
      ma150: 0.124,
      ma200: 0.118,
      maw30: 0.125,
      rs: 37.0,
      rsd1d: 11.0,
      rsd5d: -15.0,
      rsd10d: -6.0,
      price_5d_h: 0.101,
      price_5d_L: 0.092,
      price_1m_h: 0.11,
      price_1m_l: 0.085,
      price_3m_h: 0.138,
      price_3m_l: 0.085,
      price_6m_h: 0.167,
      price_6m_l: 0.085,
      price_52w_h: 0.167,
      price_52w_l: 0.072,
      price_pc_1d: 4.348,
      price_pc_1w: -9.434,
      price_pc_1m: -9.434,
      price_pc_6m: -11.111,
      price_pc_1y: -23.2,
      price_pc_ytd: -23.2,
    },
    9616: {
      id: "9616",
      c: 3.71,
      ma50: 4.422,
      ma100: 4.856,
      ma150: 5.024,
      ma200: 5.211,
      maw30: 5.061,
      rs: 20.0,
      rsd1d: 4.0,
      rsd5d: -2.0,
      rsd10d: 1.0,
      price_5d_h: 3.84,
      price_5d_L: 3.45,
      price_1m_h: 4.66,
      price_1m_l: 3.35,
      price_3m_h: 5.4,
      price_3m_l: 3.35,
      price_6m_h: 6.15,
      price_6m_l: 3.35,
      price_52w_h: 7.06,
      price_52w_l: 3.35,
      price_pc_1d: 2.77,
      price_pc_1w: -3.385,
      price_pc_1m: -20.386,
      price_pc_6m: -30.0,
      price_pc_1y: -27.255,
      price_pc_ytd: -27.255,
    },
    9618: {
      id: "9618",
      c: 234.0,
      ma50: 264.518,
      ma100: 283.731,
      ma150: 291.082,
      ma200: 288.089,
      maw30: 292.34,
      rs: 37.0,
      rsd1d: -3.0,
      rsd5d: -6.0,
      rsd10d: -14.0,
      price_5d_h: 248.6,
      price_5d_L: 212.0,
      price_1m_h: 291.6,
      price_1m_l: 160.1,
      price_3m_h: 308.2,
      price_3m_l: 160.1,
      price_6m_h: 365.0,
      price_6m_l: 160.1,
      price_52w_h: 365.0,
      price_52w_l: 160.1,
      price_pc_1d: -1.681,
      price_pc_1w: -3.146,
      price_pc_1m: -16.189,
      price_pc_6m: -17.373,
      price_pc_1y: -30.399,
      price_pc_ytd: -30.399,
    },
    9626: {
      id: "9626",
      c: 216.4,
      ma50: 237.068,
      ma100: 346.821,
      ma150: 429.605,
      ma200: 514.273,
      maw30: 424.88,
      rs: 4.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: 1.0,
      price_5d_h: 240.0,
      price_5d_L: 205.4,
      price_1m_h: 252.6,
      price_1m_l: 125.0,
      price_3m_h: 369.4,
      price_3m_l: 125.0,
      price_6m_h: 710.0,
      price_6m_l: 125.0,
      price_52w_h: 1052.0,
      price_52w_l: 125.0,
      price_pc_1d: -1.726,
      price_pc_1w: -11.384,
      price_pc_1m: -8.692,
      price_pc_6m: -57.318,
      price_pc_1y: -75.126,
      price_pc_ytd: -75.126,
    },
    9633: {
      id: "9633",
      c: 41.75,
      ma50: 43.668,
      ma100: 45.176,
      ma150: 43.126,
      ma200: 42.634,
      maw30: 43.583,
      rs: 68.0,
      rsd1d: -2.0,
      rsd5d: 5.0,
      rsd10d: 16.0,
      price_5d_h: 42.85,
      price_5d_L: 37.2,
      price_1m_h: 49.95,
      price_1m_l: 32.35,
      price_3m_h: 52.7,
      price_3m_l: 32.35,
      price_6m_h: 52.7,
      price_6m_l: 32.35,
      price_52w_h: 52.7,
      price_52w_l: 32.35,
      price_pc_1d: -2.225,
      price_pc_1w: 3.856,
      price_pc_1m: -14.709,
      price_pc_6m: 5.296,
      price_pc_1y: 3.985,
      price_pc_ytd: 3.985,
    },
    9666: {
      id: "9666",
      c: 28.3,
      ma50: 33.995,
      ma100: 33.405,
      ma150: 37.046,
      ma200: 42.544,
      maw30: 37.21,
      rs: 15.0,
      rsd1d: 0.0,
      rsd5d: 1.0,
      rsd10d: -9.0,
      price_5d_h: 29.75,
      price_5d_L: 23.8,
      price_1m_h: 35.7,
      price_1m_l: 17.64,
      price_3m_h: 44.3,
      price_3m_l: 17.64,
      price_6m_h: 49.75,
      price_6m_l: 17.64,
      price_52w_h: 75.7,
      price_52w_l: 17.64,
      price_pc_1d: 0.0,
      price_pc_1w: 4.815,
      price_pc_1m: -18.444,
      price_pc_6m: -35.24,
      price_pc_1y: -58.443,
      price_pc_ytd: -58.443,
    },
    9668: {
      id: "9668",
      c: 1.3,
      ma50: 1.574,
      ma100: 1.969,
      ma150: 2.322,
      ma200: 2.489,
      maw30: 2.34,
      rs: 5.0,
      rsd1d: 0.0,
      rsd5d: -3.0,
      rsd10d: -4.0,
      price_5d_h: 1.39,
      price_5d_L: 1.24,
      price_1m_h: 1.55,
      price_1m_l: 0.93,
      price_3m_h: 2.96,
      price_3m_l: 0.93,
      price_6m_h: 3.28,
      price_6m_l: 0.93,
      price_52w_h: 3.74,
      price_52w_l: 0.93,
      price_pc_1d: 0.0,
      price_pc_1w: -1.515,
      price_pc_1m: 0.0,
      price_pc_6m: -57.237,
      price_pc_1y: -63.173,
      price_pc_ytd: -63.173,
    },
    9698: {
      id: "9698",
      c: 39.95,
      ma50: 39.411,
      ma100: 44.564,
      ma150: 48.927,
      ma200: 53.154,
      maw30: 49.377,
      rs: 22.0,
      rsd1d: -2.0,
      rsd5d: 0.0,
      rsd10d: 3.0,
      price_5d_h: 41.8,
      price_5d_L: 34.8,
      price_1m_h: 45.15,
      price_1m_l: 18.9,
      price_3m_h: 46.5,
      price_3m_l: 18.9,
      price_6m_h: 62.5,
      price_6m_l: 18.9,
      price_52w_h: 86.3,
      price_52w_l: 18.9,
      price_pc_1d: -1.48,
      price_pc_1w: -1.114,
      price_pc_1m: -6.985,
      price_pc_6m: -28.018,
      price_pc_1y: -51.012,
      price_pc_ytd: -51.012,
    },
    9699: {
      id: "9699",
      c: 7.03,
      ma50: 9.781,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 5.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: -4.0,
      price_5d_h: 7.7,
      price_5d_L: 6.82,
      price_1m_h: 10.7,
      price_1m_l: 6.49,
      price_3m_h: 16.42,
      price_3m_l: 6.49,
      price_6m_h: 16.42,
      price_6m_l: 6.49,
      price_52w_h: 16.42,
      price_52w_l: 6.49,
      price_pc_1d: -5.0,
      price_pc_1w: -3.434,
      price_pc_1m: -32.534,
      price_pc_6m: -52.819,
      price_pc_1y: -52.819,
      price_pc_ytd: -52.819,
    },
    9801: {
      id: "9801",
      c: 2.964,
      ma50: 3.149,
      ma100: 3.339,
      ma150: 3.495,
      ma200: null,
      maw30: 3.502,
      rs: 41.0,
      rsd1d: 0.0,
      rsd5d: 2.0,
      rsd10d: 3.0,
      price_5d_h: 2.99,
      price_5d_L: 2.872,
      price_1m_h: 3.202,
      price_1m_l: 2.372,
      price_3m_h: 3.532,
      price_3m_l: 2.372,
      price_6m_h: 3.982,
      price_6m_l: 2.372,
      price_52w_h: 4.014,
      price_52w_l: 2.372,
      price_pc_1d: -0.87,
      price_pc_1w: -0.135,
      price_pc_1m: -6.498,
      price_pc_6m: -19.016,
      price_pc_1y: -20.451,
      price_pc_ytd: -20.451,
    },
    9803: {
      id: "9803",
      c: 1.142,
      ma50: null,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 79.0,
      rsd1d: 1.0,
      rsd5d: 3.0,
      rsd10d: 9.0,
      price_5d_h: 1.142,
      price_5d_L: 1.106,
      price_1m_h: 1.18,
      price_1m_l: 1.026,
      price_3m_h: 1.194,
      price_3m_l: 1.026,
      price_6m_h: 1.194,
      price_6m_l: 1.026,
      price_52w_h: 1.194,
      price_52w_l: 1.026,
      price_pc_1d: 0.883,
      price_pc_1w: 2.147,
      price_pc_1m: -1.552,
      price_pc_6m: 2.883,
      price_pc_1y: 2.883,
      price_pc_ytd: 2.883,
    },
    9804: {
      id: "9804",
      c: 12.2,
      ma50: 12.639,
      ma100: 13.012,
      ma150: 13.019,
      ma200: 12.956,
      maw30: 13.045,
      rs: 66.0,
      rsd1d: 0.0,
      rsd5d: -5.0,
      rsd10d: -3.0,
      price_5d_h: 12.45,
      price_5d_L: 12.17,
      price_1m_h: 12.63,
      price_1m_l: 12.14,
      price_3m_h: 13.57,
      price_3m_l: 12.14,
      price_6m_h: 13.68,
      price_6m_l: 12.14,
      price_52w_h: 13.68,
      price_52w_l: 11.94,
      price_pc_1d: -0.732,
      price_pc_1w: -2.008,
      price_pc_1m: -3.405,
      price_pc_6m: -4.464,
      price_pc_1y: 3.565,
      price_pc_ytd: 3.565,
    },
    9806: {
      id: "9806",
      c: 7.17,
      ma50: 7.794,
      ma100: 8.394,
      ma150: 8.644,
      ma200: 8.945,
      maw30: 8.676,
      rs: 29.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: -1.0,
      price_5d_h: 7.3,
      price_5d_L: 6.85,
      price_1m_h: 8.13,
      price_1m_l: 6.325,
      price_3m_h: 8.88,
      price_3m_l: 6.325,
      price_6m_h: 9.64,
      price_6m_l: 6.325,
      price_52w_h: 11.5,
      price_52w_l: 6.325,
      price_pc_1d: -0.967,
      price_pc_1w: -1.511,
      price_pc_1m: -10.263,
      price_pc_6m: -21.165,
      price_pc_1y: -32.613,
      price_pc_ytd: -32.613,
    },
    9807: {
      id: "9807",
      c: 6.705,
      ma50: 7.333,
      ma100: 7.882,
      ma150: 7.966,
      ma200: 8.103,
      maw30: 7.983,
      rs: 37.0,
      rsd1d: 2.0,
      rsd5d: -3.0,
      rsd10d: -9.0,
      price_5d_h: 6.845,
      price_5d_L: 6.52,
      price_1m_h: 7.665,
      price_1m_l: 6.32,
      price_3m_h: 8.545,
      price_3m_l: 6.32,
      price_6m_h: 8.795,
      price_6m_l: 6.32,
      price_52w_h: 9.0,
      price_52w_l: 6.32,
      price_pc_1d: -0.297,
      price_pc_1w: -2.188,
      price_pc_1m: -12.581,
      price_pc_6m: -16.188,
      price_pc_1y: -15.18,
      price_pc_ytd: -15.18,
    },
    9809: {
      id: "9809",
      c: 17.62,
      ma50: 18.134,
      ma100: 18.894,
      ma150: 19.294,
      ma200: 18.856,
      maw30: 19.289,
      rs: 75.0,
      rsd1d: -6.0,
      rsd5d: -8.0,
      rsd10d: -13.0,
      price_5d_h: 18.11,
      price_5d_L: 17.2,
      price_1m_h: 19.38,
      price_1m_l: 16.8,
      price_3m_h: 20.04,
      price_3m_l: 16.8,
      price_6m_h: 21.7,
      price_6m_l: 16.8,
      price_52w_h: 21.7,
      price_52w_l: 12.75,
      price_pc_1d: -2.165,
      price_pc_1w: -1.948,
      price_pc_1m: -7.312,
      price_pc_6m: -10.331,
      price_pc_1y: 29.75,
      price_pc_ytd: 29.75,
    },
    9810: {
      id: "9810",
      c: 9.725,
      ma50: 9.582,
      ma100: 9.44,
      ma150: 9.343,
      ma200: 9.203,
      maw30: 9.348,
      rs: 82.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: -2.0,
      price_5d_h: 9.725,
      price_5d_L: 9.6,
      price_1m_h: 9.755,
      price_1m_l: 9.36,
      price_3m_h: 9.82,
      price_3m_l: 9.32,
      price_6m_h: 9.82,
      price_6m_l: 8.94,
      price_52w_h: 9.82,
      price_52w_l: 8.41,
      price_pc_1d: 0.103,
      price_pc_1w: 1.302,
      price_pc_1m: 0.0,
      price_pc_6m: 9.393,
      price_pc_1y: 6.284,
      price_pc_ytd: 6.284,
    },
    9812: {
      id: "9812",
      c: 1.446,
      ma50: 1.56,
      ma100: 1.706,
      ma150: 1.818,
      ma200: 1.933,
      maw30: 1.823,
      rs: 21.0,
      rsd1d: -1.0,
      rsd5d: 1.0,
      rsd10d: 0.0,
      price_5d_h: 1.462,
      price_5d_L: 1.382,
      price_1m_h: 1.566,
      price_1m_l: 1.028,
      price_3m_h: 1.832,
      price_3m_l: 1.028,
      price_6m_h: 2.2,
      price_6m_l: 1.028,
      price_52w_h: 2.854,
      price_52w_l: 1.028,
      price_pc_1d: -1.094,
      price_pc_1w: -0.55,
      price_pc_1m: -6.589,
      price_pc_6m: -24.451,
      price_pc_1y: -49.652,
      price_pc_ytd: -49.652,
    },
    9813: {
      id: "9813",
      c: 16.87,
      ma50: 16.891,
      ma100: 16.808,
      ma150: 16.694,
      ma200: null,
      maw30: 16.702,
      rs: 77.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: -4.0,
      price_5d_h: 16.87,
      price_5d_L: 16.8,
      price_1m_h: 16.95,
      price_1m_l: 16.78,
      price_3m_h: 16.98,
      price_3m_l: 16.78,
      price_6m_h: 16.98,
      price_6m_l: 16.46,
      price_52w_h: 16.98,
      price_52w_l: 16.25,
      price_pc_1d: 0.119,
      price_pc_1w: 0.536,
      price_pc_1m: -0.472,
      price_pc_6m: 2.866,
      price_pc_1y: 3.815,
      price_pc_ytd: 3.815,
    },
    9814: {
      id: "9814",
      c: 2.626,
      ma50: 2.465,
      ma100: 2.691,
      ma150: 2.745,
      ma200: 2.748,
      maw30: 2.746,
      rs: 61.0,
      rsd1d: 1.0,
      rsd5d: 6.0,
      rsd10d: 11.0,
      price_5d_h: 2.644,
      price_5d_L: 2.536,
      price_1m_h: 2.644,
      price_1m_l: 2.098,
      price_3m_h: 2.928,
      price_3m_l: 2.098,
      price_6m_h: 3.084,
      price_6m_l: 2.098,
      price_52w_h: 3.084,
      price_52w_l: 2.098,
      price_pc_1d: -0.379,
      price_pc_1w: 3.142,
      price_pc_1m: 10.802,
      price_pc_6m: -3.81,
      price_pc_1y: 2.779,
      price_pc_ytd: 2.779,
    },
    9817: {
      id: "9817",
      c: 16.5,
      ma50: 16.673,
      ma100: 16.626,
      ma150: 16.475,
      ma200: 16.339,
      maw30: 16.495,
      rs: 77.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: -4.0,
      price_5d_h: 16.5,
      price_5d_L: 16.5,
      price_1m_h: 16.7,
      price_1m_l: 16.45,
      price_3m_h: 17.0,
      price_3m_l: 16.45,
      price_6m_h: 17.0,
      price_6m_l: 16.05,
      price_52w_h: 17.0,
      price_52w_l: 15.32,
      price_pc_1d: 0.0,
      price_pc_1w: 0.304,
      price_pc_1m: -1.198,
      price_pc_6m: 2.167,
      price_pc_1y: 7.702,
      price_pc_ytd: 7.702,
    },
    9820: {
      id: "9820",
      c: 10.2,
      ma50: 10.354,
      ma100: 11.745,
      ma150: 12.648,
      ma200: 13.614,
      maw30: 12.66,
      rs: 23.0,
      rsd1d: 0.0,
      rsd5d: -3.0,
      rsd10d: 1.0,
      price_5d_h: 10.52,
      price_5d_L: 9.975,
      price_1m_h: 10.75,
      price_1m_l: 8.36,
      price_3m_h: 12.62,
      price_3m_l: 8.36,
      price_6m_h: 14.76,
      price_6m_l: 8.36,
      price_52w_h: 18.31,
      price_52w_l: 8.36,
      price_pc_1d: -1.923,
      price_pc_1w: -4.405,
      price_pc_1m: -3.683,
      price_pc_6m: -30.988,
      price_pc_1y: -34.573,
      price_pc_ytd: -34.573,
    },
    9826: {
      id: "9826",
      c: 7.83,
      ma50: 8.522,
      ma100: 9.374,
      ma150: 9.974,
      ma200: 10.652,
      maw30: 9.989,
      rs: 20.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: -2.0,
      price_5d_h: 7.98,
      price_5d_L: 7.595,
      price_1m_h: 8.89,
      price_1m_l: 6.165,
      price_3m_h: 9.995,
      price_3m_l: 6.165,
      price_6m_h: 11.8,
      price_6m_l: 6.165,
      price_52w_h: 15.27,
      price_52w_l: 6.165,
      price_pc_1d: -0.949,
      price_pc_1w: -2.491,
      price_pc_1m: -10.514,
      price_pc_6m: -27.163,
      price_pc_1y: -47.273,
      price_pc_ytd: -47.273,
    },
    9829: {
      id: "9829",
      c: 8.085,
      ma50: 8.078,
      ma100: 8.017,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 76.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: -4.0,
      price_5d_h: 8.085,
      price_5d_L: 8.035,
      price_1m_h: 8.11,
      price_1m_l: 8.02,
      price_3m_h: 8.115,
      price_3m_l: 7.98,
      price_6m_h: 8.115,
      price_6m_l: 7.86,
      price_52w_h: 8.115,
      price_52w_l: 7.86,
      price_pc_1d: 0.31,
      price_pc_1w: 0.622,
      price_pc_1m: -0.308,
      price_pc_6m: 2.863,
      price_pc_1y: 2.863,
      price_pc_ytd: 2.863,
    },
    9834: {
      id: "9834",
      c: 36.72,
      ma50: 34.628,
      ma100: 36.827,
      ma150: 36.906,
      ma200: 36.582,
      maw30: 36.913,
      rs: 77.0,
      rsd1d: -1.0,
      rsd5d: 9.0,
      rsd10d: 9.0,
      price_5d_h: 36.72,
      price_5d_L: 35.72,
      price_1m_h: 36.72,
      price_1m_l: 31.82,
      price_3m_h: 39.98,
      price_3m_l: 31.82,
      price_6m_h: 40.14,
      price_6m_l: 31.82,
      price_52w_h: 40.14,
      price_52w_l: 31.42,
      price_pc_1d: 0.0,
      price_pc_1w: 4.556,
      price_pc_1m: 8.383,
      price_pc_6m: 2.227,
      price_pc_1y: 15.11,
      price_pc_ytd: 15.11,
    },
    9836: {
      id: "9836",
      c: 4.432,
      ma50: 4.309,
      ma100: 4.398,
      ma150: 4.451,
      ma200: 4.37,
      maw30: 4.46,
      rs: 80.0,
      rsd1d: -1.0,
      rsd5d: 3.0,
      rsd10d: -1.0,
      price_5d_h: 4.432,
      price_5d_L: 4.284,
      price_1m_h: 4.432,
      price_1m_l: 3.876,
      price_3m_h: 4.686,
      price_3m_l: 3.876,
      price_6m_h: 4.744,
      price_6m_l: 3.876,
      price_52w_h: 4.744,
      price_52w_l: 3.602,
      price_pc_1d: 0.408,
      price_pc_1w: 2.783,
      price_pc_1m: 5.826,
      price_pc_6m: -2.25,
      price_pc_1y: 13.235,
      price_pc_ytd: 13.235,
    },
    9839: {
      id: "9839",
      c: 3.688,
      ma50: 3.893,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 47.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: -3.0,
      price_5d_h: 3.688,
      price_5d_L: 3.57,
      price_1m_h: 4.036,
      price_1m_l: 3.492,
      price_3m_h: 4.306,
      price_3m_l: 3.492,
      price_6m_h: 4.522,
      price_6m_l: 3.492,
      price_52w_h: 4.522,
      price_52w_l: 3.492,
      price_pc_1d: -0.216,
      price_pc_1w: -0.162,
      price_pc_1m: -8.35,
      price_pc_6m: -17.086,
      price_pc_1y: -17.086,
      price_pc_ytd: -17.086,
    },
    9845: {
      id: "9845",
      c: 18.71,
      ma50: 19.73,
      ma100: 22.258,
      ma150: 22.786,
      ma200: 22.538,
      maw30: 22.764,
      rs: 62.0,
      rsd1d: -1.0,
      rsd5d: -6.0,
      rsd10d: -3.0,
      price_5d_h: 19.3,
      price_5d_L: 17.96,
      price_1m_h: 21.58,
      price_1m_l: 16.93,
      price_3m_h: 24.0,
      price_3m_l: 16.93,
      price_6m_h: 27.46,
      price_6m_l: 16.93,
      price_52w_h: 27.46,
      price_52w_l: 13.5,
      price_pc_1d: -2.144,
      price_pc_1w: -2.4,
      price_pc_1m: -12.406,
      price_pc_6m: -18.082,
      price_pc_1y: 25.318,
      price_pc_ytd: 25.318,
    },
    9846: {
      id: "9846",
      c: 4.352,
      ma50: 4.599,
      ma100: 4.802,
      ma150: 4.863,
      ma200: 4.917,
      maw30: 4.878,
      rs: 47.0,
      rsd1d: 2.0,
      rsd5d: 2.0,
      rsd10d: -1.0,
      price_5d_h: 4.352,
      price_5d_L: 4.242,
      price_1m_h: 4.752,
      price_1m_l: 4.038,
      price_3m_h: 5.06,
      price_3m_l: 4.038,
      price_6m_h: 5.21,
      price_6m_l: 4.038,
      price_52w_h: 5.415,
      price_52w_l: 4.038,
      price_pc_1d: 0.0,
      price_pc_1w: 0.092,
      price_pc_1m: -7.797,
      price_pc_6m: -11.652,
      price_pc_1y: -15.083,
      price_pc_ytd: -15.083,
    },
    9858: {
      id: "9858",
      c: 3.6,
      ma50: 4.184,
      ma100: 4.256,
      ma150: 4.397,
      ma200: null,
      maw30: 4.423,
      rs: 25.0,
      rsd1d: -7.0,
      rsd5d: -12.0,
      rsd10d: -17.0,
      price_5d_h: 4.16,
      price_5d_L: 3.59,
      price_1m_h: 4.34,
      price_1m_l: 3.34,
      price_3m_h: 5.0,
      price_3m_l: 3.34,
      price_6m_h: 5.0,
      price_6m_l: 3.34,
      price_52w_h: 6.49,
      price_52w_l: 3.34,
      price_pc_1d: -7.216,
      price_pc_1w: -11.98,
      price_pc_1m: -15.294,
      price_pc_6m: -23.89,
      price_pc_1y: -41.463,
      price_pc_ytd: -41.463,
    },
    9866: {
      id: "9866",
      c: 175.2,
      ma50: null,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 85.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: 25.0,
      price_5d_h: 179.3,
      price_5d_L: 150.0,
      price_1m_h: 180.0,
      price_1m_l: 108.0,
      price_3m_h: 180.0,
      price_3m_l: 108.0,
      price_6m_h: 180.0,
      price_6m_l: 108.0,
      price_52w_h: 180.0,
      price_52w_l: 108.0,
      price_pc_1d: -0.341,
      price_pc_1w: 0.459,
      price_pc_1m: 10.258,
      price_pc_6m: 10.258,
      price_pc_1y: 10.258,
      price_pc_ytd: 10.258,
    },
    9868: {
      id: "9868",
      c: 112.5,
      ma50: 131.543,
      ma100: 158.475,
      ma150: 158.376,
      ma200: null,
      maw30: 158.63,
      rs: 27.0,
      rsd1d: -2.0,
      rsd5d: 5.0,
      rsd10d: 8.0,
      price_5d_h: 115.0,
      price_5d_L: 100.4,
      price_1m_h: 144.5,
      price_1m_l: 71.85,
      price_3m_h: 199.5,
      price_3m_l: 71.85,
      price_6m_h: 220.0,
      price_6m_l: 71.85,
      price_52w_h: 220.0,
      price_52w_l: 71.85,
      price_pc_1d: -1.918,
      price_pc_1w: 1.078,
      price_pc_1m: -16.728,
      price_pc_6m: -16.667,
      price_pc_1y: -31.818,
      price_pc_ytd: -31.818,
    },
    9869: {
      id: "9869",
      c: 13.82,
      ma50: 16.741,
      ma100: 17.571,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 18.0,
      rsd1d: -1.0,
      rsd5d: 5.0,
      rsd10d: 6.0,
      price_5d_h: 15.0,
      price_5d_L: 12.02,
      price_1m_h: 19.88,
      price_1m_l: 8.35,
      price_3m_h: 21.9,
      price_3m_l: 8.35,
      price_6m_h: 24.8,
      price_6m_l: 8.35,
      price_52w_h: 25.75,
      price_52w_l: 8.35,
      price_pc_1d: -6.114,
      price_pc_1w: -1.847,
      price_pc_1m: -26.175,
      price_pc_6m: -39.253,
      price_pc_1y: -43.128,
      price_pc_ytd: -43.128,
    },
    9878: {
      id: "9878",
      c: 46.2,
      ma50: null,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 78.0,
      rsd1d: 11.0,
      rsd5d: 24.0,
      rsd10d: 19.0,
      price_5d_h: 47.0,
      price_5d_L: 39.25,
      price_1m_h: 47.8,
      price_1m_l: 37.0,
      price_3m_h: 49.0,
      price_3m_l: 37.0,
      price_6m_h: 49.0,
      price_6m_l: 37.0,
      price_52w_h: 49.0,
      price_52w_l: 37.0,
      price_pc_1d: 5.963,
      price_pc_1w: 13.235,
      price_pc_1m: -3.75,
      price_pc_6m: 2.439,
      price_pc_1y: 2.439,
      price_pc_ytd: 2.439,
    },
    9888: {
      id: "9888",
      c: 141.5,
      ma50: 147.088,
      ma100: 147.24,
      ma150: 150.871,
      ma200: 155.594,
      maw30: 151.97,
      rs: 45.0,
      rsd1d: -14.0,
      rsd5d: -9.0,
      rsd10d: -11.0,
      price_5d_h: 148.4,
      price_5d_L: 138.1,
      price_1m_h: 161.9,
      price_1m_l: 99.0,
      price_3m_h: 165.6,
      price_3m_l: 99.0,
      price_6m_h: 178.9,
      price_6m_l: 99.0,
      price_52w_h: 224.0,
      price_52w_l: 99.0,
      price_pc_1d: -3.215,
      price_pc_1w: -5.667,
      price_pc_1m: -4.003,
      price_pc_6m: -4.65,
      price_pc_1y: -35.151,
      price_pc_ytd: -35.151,
    },
    9889: {
      id: "9889",
      c: 7.84,
      ma50: 7.74,
      ma100: 7.579,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 75.0,
      rsd1d: -2.0,
      rsd5d: -1.0,
      rsd10d: -10.0,
      price_5d_h: 8.0,
      price_5d_L: 7.5,
      price_1m_h: 8.0,
      price_1m_l: 7.32,
      price_3m_h: 8.0,
      price_3m_l: 7.31,
      price_6m_h: 8.19,
      price_6m_l: 6.98,
      price_52w_h: 8.4,
      price_52w_l: 6.51,
      price_pc_1d: -1.135,
      price_pc_1w: 3.43,
      price_pc_1m: 0.513,
      price_pc_6m: -2.0,
      price_pc_1y: -4.97,
      price_pc_ytd: -4.97,
    },
    9898: {
      id: "9898",
      c: 189.9,
      ma50: 224.716,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 34.0,
      rsd1d: -8.0,
      rsd5d: -21.0,
      rsd10d: -21.0,
      price_5d_h: 211.8,
      price_5d_L: 186.5,
      price_1m_h: 221.0,
      price_1m_l: 154.0,
      price_3m_h: 275.0,
      price_3m_l: 154.0,
      price_6m_h: 275.0,
      price_6m_l: 154.0,
      price_52w_h: 275.0,
      price_52w_l: 154.0,
      price_pc_1d: -0.991,
      price_pc_1w: -8.172,
      price_pc_1m: -17.435,
      price_pc_6m: -25.0,
      price_pc_1y: -25.0,
      price_pc_ytd: -25.0,
    },
    9899: {
      id: "9899",
      c: 92.0,
      ma50: 105.999,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 12.0,
      rsd1d: -1.0,
      rsd5d: 4.0,
      rsd10d: 2.0,
      price_5d_h: 99.0,
      price_5d_L: 74.05,
      price_1m_h: 119.0,
      price_1m_l: 55.8,
      price_3m_h: 154.9,
      price_3m_l: 55.8,
      price_6m_h: 205.8,
      price_6m_l: 55.8,
      price_52w_h: 205.8,
      price_52w_l: 55.8,
      price_pc_1d: 1.657,
      price_pc_1w: 18.786,
      price_pc_1m: -14.339,
      price_pc_6m: -53.977,
      price_pc_1y: -53.977,
      price_pc_ytd: -53.977,
    },
    9901: {
      id: "9901",
      c: 9.63,
      ma50: 10.752,
      ma100: 13.373,
      ma150: 14.346,
      ma200: 20.793,
      maw30: 14.334,
      rs: 4.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: 1.0,
      price_5d_h: 10.24,
      price_5d_L: 9.16,
      price_1m_h: 11.6,
      price_1m_l: 6.62,
      price_3m_h: 16.48,
      price_3m_l: 6.62,
      price_6m_h: 19.7,
      price_6m_l: 6.62,
      price_52w_h: 129.2,
      price_52w_l: 6.62,
      price_pc_1d: -0.104,
      price_pc_1w: -4.464,
      price_pc_1m: -9.151,
      price_pc_6m: -33.218,
      price_pc_1y: -91.386,
      price_pc_ytd: -91.386,
    },
    9906: {
      id: "9906",
      c: 2.12,
      ma50: 2.248,
      ma100: 2.284,
      ma150: 2.243,
      ma200: 2.179,
      maw30: 2.258,
      rs: 68.0,
      rsd1d: -2.0,
      rsd5d: -9.0,
      rsd10d: -2.0,
      price_5d_h: 2.27,
      price_5d_L: 2.1,
      price_1m_h: 2.27,
      price_1m_l: 2.04,
      price_3m_h: 2.52,
      price_3m_l: 2.04,
      price_6m_h: 2.52,
      price_6m_l: 1.98,
      price_52w_h: 2.52,
      price_52w_l: 1.74,
      price_pc_1d: -0.935,
      price_pc_1w: -1.852,
      price_pc_1m: 0.952,
      price_pc_6m: -2.752,
      price_pc_1y: 16.484,
      price_pc_ytd: 16.484,
    },
    9908: {
      id: "9908",
      c: 9.86,
      ma50: 10.951,
      ma100: 11.26,
      ma150: 10.887,
      ma200: 10.702,
      maw30: 10.959,
      rs: 57.0,
      rsd1d: 0.0,
      rsd5d: 3.0,
      rsd10d: 1.0,
      price_5d_h: 9.95,
      price_5d_L: 9.52,
      price_1m_h: 11.0,
      price_1m_l: 9.02,
      price_3m_h: 12.3,
      price_3m_l: 9.02,
      price_6m_h: 12.3,
      price_6m_l: 9.02,
      price_52w_h: 12.3,
      price_52w_l: 9.02,
      price_pc_1d: -0.905,
      price_pc_1w: 1.232,
      price_pc_1m: -7.85,
      price_pc_6m: -3.143,
      price_pc_1y: -4.826,
      price_pc_ytd: -4.826,
    },
    9909: {
      id: "9909",
      c: 6.23,
      ma50: 12.702,
      ma100: 14.111,
      ma150: 15.705,
      ma200: 18.01,
      maw30: 15.706,
      rs: 3.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: -4.0,
      price_5d_h: 6.6,
      price_5d_L: 5.57,
      price_1m_h: 13.76,
      price_1m_l: 4.99,
      price_3m_h: 19.0,
      price_3m_l: 4.99,
      price_6m_h: 20.4,
      price_6m_l: 4.99,
      price_52w_h: 31.65,
      price_52w_l: 4.99,
      price_pc_1d: 0.646,
      price_pc_1w: -11.631,
      price_pc_1m: -54.326,
      price_pc_6m: -65.769,
      price_pc_1y: -75.759,
      price_pc_ytd: -75.759,
    },
    9911: {
      id: "9911",
      c: 3.46,
      ma50: 4.039,
      ma100: 3.928,
      ma150: 4.151,
      ma200: 4.558,
      maw30: 4.169,
      rs: 35.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 5.0,
      price_5d_h: 3.66,
      price_5d_L: 3.31,
      price_1m_h: 4.67,
      price_1m_l: 2.58,
      price_3m_h: 4.95,
      price_3m_l: 2.58,
      price_6m_h: 4.99,
      price_6m_l: 2.58,
      price_52w_h: 8.45,
      price_52w_l: 2.58,
      price_pc_1d: -0.86,
      price_pc_1w: -2.26,
      price_pc_1m: -20.824,
      price_pc_6m: -18.396,
      price_pc_1y: -32.816,
      price_pc_ytd: -32.816,
    },
    9913: {
      id: "9913",
      c: 3.02,
      ma50: 3.224,
      ma100: 3.367,
      ma150: 3.412,
      ma200: 3.363,
      maw30: 3.416,
      rs: 62.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: -7.0,
      price_5d_h: 3.12,
      price_5d_L: 2.96,
      price_1m_h: 3.31,
      price_1m_l: 2.92,
      price_3m_h: 3.54,
      price_3m_l: 2.92,
      price_6m_h: 3.67,
      price_6m_l: 2.92,
      price_52w_h: 3.67,
      price_52w_l: 2.58,
      price_pc_1d: -0.658,
      price_pc_1w: -1.629,
      price_pc_1m: -7.362,
      price_pc_6m: -11.176,
      price_pc_1y: 7.473,
      price_pc_ytd: 7.473,
    },
    9916: {
      id: "9916",
      c: 0.91,
      ma50: 0.931,
      ma100: 0.947,
      ma150: 0.975,
      ma200: 0.995,
      maw30: 0.975,
      rs: 53.0,
      rsd1d: 10.0,
      rsd5d: 3.0,
      rsd10d: 13.0,
      price_5d_h: 0.95,
      price_5d_L: 0.86,
      price_1m_h: 0.98,
      price_1m_l: 0.63,
      price_3m_h: 1.0,
      price_3m_l: 0.63,
      price_6m_h: 1.07,
      price_6m_l: 0.63,
      price_52w_h: 1.18,
      price_52w_l: 0.63,
      price_pc_1d: 3.409,
      price_pc_1w: 1.111,
      price_pc_1m: -6.186,
      price_pc_6m: -11.65,
      price_pc_1y: -22.881,
      price_pc_ytd: -22.881,
    },
    9918: {
      id: "9918",
      c: 0.1,
      ma50: 0.094,
      ma100: 0.094,
      ma150: 0.097,
      ma200: 0.106,
      maw30: 0.096,
      rs: 71.0,
      rsd1d: 17.0,
      rsd5d: 9.0,
      rsd10d: 19.0,
      price_5d_h: 0.128,
      price_5d_L: 0.09,
      price_1m_h: 0.128,
      price_1m_l: 0.08,
      price_3m_h: 0.128,
      price_3m_l: 0.08,
      price_6m_h: 0.128,
      price_6m_l: 0.08,
      price_52w_h: 0.265,
      price_52w_l: 0.08,
      price_pc_1d: 9.89,
      price_pc_1w: 5.263,
      price_pc_1m: 7.527,
      price_pc_6m: 5.263,
      price_pc_1y: 12.36,
      price_pc_ytd: 12.36,
    },
    9919: {
      id: "9919",
      c: 1.24,
      ma50: 1.449,
      ma100: 1.414,
      ma150: 1.456,
      ma200: 1.357,
      maw30: 1.46,
      rs: 84.0,
      rsd1d: -1.0,
      rsd5d: -7.0,
      rsd10d: -7.0,
      price_5d_h: 1.34,
      price_5d_L: 1.21,
      price_1m_h: 1.44,
      price_1m_l: 1.17,
      price_3m_h: 1.8,
      price_3m_l: 1.17,
      price_6m_h: 1.8,
      price_6m_l: 1.12,
      price_52w_h: 2.03,
      price_52w_l: 0.7,
      price_pc_1d: 0.0,
      price_pc_1w: -4.615,
      price_pc_1m: -11.429,
      price_pc_6m: -18.954,
      price_pc_1y: 55.0,
      price_pc_ytd: 55.0,
    },
    9922: {
      id: "9922",
      c: 16.8,
      ma50: 16.695,
      ma100: 16.092,
      ma150: 18.405,
      ma200: 20.851,
      maw30: 18.275,
      rs: 43.0,
      rsd1d: -6.0,
      rsd5d: 9.0,
      rsd10d: 8.0,
      price_5d_h: 17.18,
      price_5d_L: 13.6,
      price_1m_h: 18.8,
      price_1m_l: 10.82,
      price_3m_h: 21.75,
      price_3m_l: 10.82,
      price_6m_h: 24.65,
      price_6m_l: 10.82,
      price_52w_h: 34.2,
      price_52w_l: 10.82,
      price_pc_1d: -0.119,
      price_pc_1w: 8.527,
      price_pc_1m: -3.67,
      price_pc_6m: -28.662,
      price_pc_1y: -48.068,
      price_pc_ytd: -48.068,
    },
    9923: {
      id: "9923",
      c: 24.1,
      ma50: 22.455,
      ma100: 24.348,
      ma150: 25.708,
      ma200: 29.66,
      maw30: 25.687,
      rs: 28.0,
      rsd1d: 1.0,
      rsd5d: 4.0,
      rsd10d: 6.0,
      price_5d_h: 24.5,
      price_5d_L: 22.0,
      price_1m_h: 25.3,
      price_1m_l: 15.78,
      price_3m_h: 27.55,
      price_3m_l: 15.78,
      price_6m_h: 34.0,
      price_6m_l: 15.78,
      price_52w_h: 73.0,
      price_52w_l: 15.78,
      price_pc_1d: 1.048,
      price_pc_1w: 1.261,
      price_pc_1m: 11.833,
      price_pc_6m: -4.175,
      price_pc_1y: -59.462,
      price_pc_ytd: -59.462,
    },
    9926: {
      id: "9926",
      c: 16.68,
      ma50: 19.598,
      ma100: 29.368,
      ma150: 33.694,
      ma200: 38.701,
      maw30: 33.871,
      rs: 4.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: 1.0,
      price_5d_h: 19.42,
      price_5d_L: 15.72,
      price_1m_h: 22.1,
      price_1m_l: 11.52,
      price_3m_h: 34.2,
      price_3m_l: 11.52,
      price_6m_h: 52.5,
      price_6m_l: 11.52,
      price_52w_h: 69.9,
      price_52w_l: 11.52,
      price_pc_1d: -13.306,
      price_pc_1w: -11.559,
      price_pc_1m: -18.634,
      price_pc_6m: -61.028,
      price_pc_1y: -68.939,
      price_pc_ytd: -68.939,
    },
    9928: {
      id: "9928",
      c: 2.32,
      ma50: 2.695,
      ma100: 2.95,
      ma150: 3.346,
      ma200: 3.952,
      maw30: 3.329,
      rs: 12.0,
      rsd1d: 1.0,
      rsd5d: -1.0,
      rsd10d: -1.0,
      price_5d_h: 2.53,
      price_5d_L: 2.12,
      price_1m_h: 2.74,
      price_1m_l: 1.58,
      price_3m_h: 3.36,
      price_3m_l: 1.58,
      price_6m_h: 4.4,
      price_6m_l: 1.58,
      price_52w_h: 7.16,
      price_52w_l: 1.58,
      price_pc_1d: -2.521,
      price_pc_1w: -7.2,
      price_pc_1m: -13.109,
      price_pc_6m: -42.0,
      price_pc_1y: -62.399,
      price_pc_ytd: -62.399,
    },
    9929: {
      id: "9929",
      c: 0.057,
      ma50: 0.062,
      ma100: 0.07,
      ma150: 0.071,
      ma200: 0.069,
      maw30: 0.07,
      rs: 46.0,
      rsd1d: 5.0,
      rsd5d: -11.0,
      rsd10d: 11.0,
      price_5d_h: 0.06,
      price_5d_L: 0.055,
      price_1m_h: 0.063,
      price_1m_l: 0.038,
      price_3m_h: 0.08,
      price_3m_l: 0.038,
      price_6m_h: 0.1,
      price_6m_l: 0.038,
      price_52w_h: 0.1,
      price_52w_l: 0.038,
      price_pc_1d: 1.786,
      price_pc_1w: -5.0,
      price_pc_1m: -8.065,
      price_pc_6m: -16.176,
      price_pc_1y: -10.937,
      price_pc_ytd: -10.937,
    },
    9933: {
      id: "9933",
      c: 0.445,
      ma50: 0.407,
      ma100: 0.361,
      ma150: 0.323,
      ma200: 0.295,
      maw30: 0.324,
      rs: 97.0,
      rsd1d: 0.0,
      rsd5d: 0.0,
      rsd10d: 0.0,
      price_5d_h: 0.47,
      price_5d_L: 0.405,
      price_1m_h: 0.52,
      price_1m_l: 0.295,
      price_3m_h: 0.52,
      price_3m_l: 0.285,
      price_6m_h: 0.52,
      price_6m_l: 0.193,
      price_52w_h: 0.52,
      price_52w_l: 0.127,
      price_pc_1d: 2.299,
      price_pc_1w: 4.706,
      price_pc_1m: -1.111,
      price_pc_6m: 97.778,
      price_pc_1y: 209.028,
      price_pc_ytd: 209.028,
    },
    9936: {
      id: "9936",
      c: 4.5,
      ma50: 4.929,
      ma100: 4.903,
      ma150: 4.926,
      ma200: 5.012,
      maw30: 4.955,
      rs: 81.0,
      rsd1d: -3.0,
      rsd5d: 0.0,
      rsd10d: -7.0,
      price_5d_h: 4.65,
      price_5d_L: 4.24,
      price_1m_h: 5.67,
      price_1m_l: 4.18,
      price_3m_h: 6.17,
      price_3m_l: 4.18,
      price_6m_h: 6.17,
      price_6m_l: 4.18,
      price_52w_h: 6.43,
      price_52w_l: 2.76,
      price_pc_1d: -0.442,
      price_pc_1w: 0.0,
      price_pc_1m: -20.071,
      price_pc_6m: -5.858,
      price_pc_1y: 52.542,
      price_pc_ytd: 52.542,
    },
    9939: {
      id: "9939",
      c: 9.75,
      ma50: 10.963,
      ma100: 26.115,
      ma150: 36.503,
      ma200: 43.682,
      maw30: 35.354,
      rs: 5.0,
      rsd1d: -2.0,
      rsd5d: 3.0,
      rsd10d: 4.0,
      price_5d_h: 12.18,
      price_5d_L: 9.75,
      price_1m_h: 13.48,
      price_1m_l: 8.66,
      price_3m_h: 13.7,
      price_3m_l: 7.0,
      price_6m_h: 64.0,
      price_6m_l: 6.91,
      price_52w_h: 89.0,
      price_52w_l: 6.91,
      price_pc_1d: -11.844,
      price_pc_1w: -17.929,
      price_pc_1m: -23.107,
      price_pc_6m: -81.34,
      price_pc_1y: -71.657,
      price_pc_ytd: -71.657,
    },
    9956: {
      id: "9956",
      c: 4.41,
      ma50: 5.653,
      ma100: null,
      ma150: null,
      ma200: null,
      maw30: null,
      rs: 6.0,
      rsd1d: 1.0,
      rsd5d: -2.0,
      rsd10d: -2.0,
      price_5d_h: 4.6,
      price_5d_L: 3.69,
      price_1m_h: 6.14,
      price_1m_l: 3.69,
      price_3m_h: 10.3,
      price_3m_l: 3.69,
      price_6m_h: 13.88,
      price_6m_l: 3.69,
      price_52w_h: 13.88,
      price_52w_l: 3.69,
      price_pc_1d: -0.676,
      price_pc_1w: 3.037,
      price_pc_1m: -21.109,
      price_pc_6m: -67.285,
      price_pc_1y: -67.285,
      price_pc_ytd: -67.285,
    },
    9958: {
      id: "9958",
      c: 1.13,
      ma50: 1.151,
      ma100: 1.235,
      ma150: 1.304,
      ma200: 1.345,
      maw30: 1.301,
      rs: 34.0,
      rsd1d: 4.0,
      rsd5d: 6.0,
      rsd10d: -6.0,
      price_5d_h: 1.15,
      price_5d_L: 1.01,
      price_1m_h: 1.27,
      price_1m_l: 0.92,
      price_3m_h: 1.35,
      price_3m_l: 0.92,
      price_6m_h: 1.74,
      price_6m_l: 0.92,
      price_52w_h: 1.75,
      price_52w_l: 0.92,
      price_pc_1d: 1.802,
      price_pc_1w: 2.727,
      price_pc_1m: -6.612,
      price_pc_6m: -23.649,
      price_pc_1y: -26.623,
      price_pc_ytd: -26.623,
    },
    9959: {
      id: "9959",
      c: 7.45,
      ma50: 7.355,
      ma100: 7.058,
      ma150: 7.846,
      ma200: 9.394,
      maw30: 7.808,
      rs: 27.0,
      rsd1d: 0.0,
      rsd5d: 2.0,
      rsd10d: -14.0,
      price_5d_h: 7.48,
      price_5d_L: 6.16,
      price_1m_h: 8.65,
      price_1m_l: 4.89,
      price_3m_h: 8.65,
      price_3m_l: 4.89,
      price_6m_h: 10.24,
      price_6m_l: 4.89,
      price_52w_h: 25.1,
      price_52w_l: 4.89,
      price_pc_1d: 4.93,
      price_pc_1w: 7.659,
      price_pc_1m: -3.121,
      price_pc_6m: -7.107,
      price_pc_1y: -61.439,
      price_pc_ytd: -61.439,
    },
    9960: {
      id: "9960",
      c: 2.65,
      ma50: 3.379,
      ma100: 4.255,
      ma150: 4.838,
      ma200: null,
      maw30: 4.817,
      rs: 6.0,
      rsd1d: 1.0,
      rsd5d: 2.0,
      rsd10d: 2.0,
      price_5d_h: 2.74,
      price_5d_L: 2.51,
      price_1m_h: 3.03,
      price_1m_l: 2.15,
      price_3m_h: 6.36,
      price_3m_l: 2.15,
      price_6m_h: 6.36,
      price_6m_l: 2.15,
      price_52w_h: 9.78,
      price_52w_l: 2.15,
      price_pc_1d: 0.0,
      price_pc_1w: -1.852,
      price_pc_1m: -14.239,
      price_pc_6m: -55.537,
      price_pc_1y: -70.686,
      price_pc_ytd: -70.686,
    },
    9961: {
      id: "9961",
      c: 188.6,
      ma50: 199.896,
      ma100: 203.52,
      ma150: 214.02,
      ma200: 219.856,
      maw30: 215.837,
      rs: 40.0,
      rsd1d: -6.0,
      rsd5d: -16.0,
      rsd10d: -1.0,
      price_5d_h: 202.0,
      price_5d_L: 172.0,
      price_1m_h: 223.6,
      price_1m_l: 123.3,
      price_3m_h: 238.2,
      price_3m_l: 123.3,
      price_6m_h: 261.2,
      price_6m_l: 123.3,
      price_52w_h: 326.0,
      price_52w_l: 123.3,
      price_pc_1d: -1.463,
      price_pc_1w: -8.268,
      price_pc_1m: -9.761,
      price_pc_6m: -19.676,
      price_pc_1y: -32.691,
      price_pc_ytd: -32.691,
    },
    9966: {
      id: "9966",
      c: 7.95,
      ma50: 9.241,
      ma100: 12.34,
      ma150: 14.458,
      ma200: 16.589,
      maw30: 14.353,
      rs: 10.0,
      rsd1d: -1.0,
      rsd5d: -1.0,
      rsd10d: 1.0,
      price_5d_h: 9.19,
      price_5d_L: 7.62,
      price_1m_h: 9.76,
      price_1m_l: 6.12,
      price_3m_h: 13.88,
      price_3m_l: 6.12,
      price_6m_h: 20.5,
      price_6m_l: 6.12,
      price_52w_h: 28.7,
      price_52w_l: 6.12,
      price_pc_1d: -7.986,
      price_pc_1w: -10.674,
      price_pc_1m: -13.868,
      price_pc_6m: -54.932,
      price_pc_1y: -30.507,
      price_pc_ytd: -30.507,
    },
    9968: {
      id: "9968",
      c: 1.88,
      ma50: 1.911,
      ma100: 1.925,
      ma150: 1.931,
      ma200: 1.936,
      maw30: 1.932,
      rs: 68.0,
      rsd1d: 2.0,
      rsd5d: -1.0,
      rsd10d: -2.0,
      price_5d_h: 1.93,
      price_5d_L: 1.86,
      price_1m_h: 1.94,
      price_1m_l: 1.67,
      price_3m_h: 1.96,
      price_3m_l: 1.67,
      price_6m_h: 2.05,
      price_6m_l: 1.67,
      price_52w_h: 2.05,
      price_52w_l: 1.67,
      price_pc_1d: 0.535,
      price_pc_1w: -1.053,
      price_pc_1m: -2.591,
      price_pc_6m: -2.591,
      price_pc_1y: -1.571,
      price_pc_ytd: -1.571,
    },
    9969: {
      id: "9969",
      c: 12.22,
      ma50: 11.982,
      ma100: 14.736,
      ma150: 16.511,
      ma200: 18.932,
      maw30: 16.43,
      rs: 18.0,
      rsd1d: -1.0,
      rsd5d: 0.0,
      rsd10d: 5.0,
      price_5d_h: 13.3,
      price_5d_L: 11.72,
      price_1m_h: 13.54,
      price_1m_l: 8.86,
      price_3m_h: 15.7,
      price_3m_l: 8.86,
      price_6m_h: 22.3,
      price_6m_l: 8.86,
      price_52w_h: 32.05,
      price_52w_l: 8.86,
      price_pc_1d: -5.124,
      price_pc_1w: -2.24,
      price_pc_1m: -1.133,
      price_pc_6m: -41.948,
      price_pc_1y: -34.017,
      price_pc_ytd: -34.017,
    },
    9977: {
      id: "9977",
      c: 0.8,
      ma50: 1.383,
      ma100: 1.535,
      ma150: 1.626,
      ma200: 1.718,
      maw30: 1.648,
      rs: 4.0,
      rsd1d: 1.0,
      rsd5d: -2.0,
      rsd10d: -1.0,
      price_5d_h: 0.94,
      price_5d_L: 0.68,
      price_1m_h: 1.6,
      price_1m_l: 0.68,
      price_3m_h: 1.83,
      price_3m_l: 0.68,
      price_6m_h: 1.92,
      price_6m_l: 0.68,
      price_52w_h: 2.93,
      price_52w_l: 0.68,
      price_pc_1d: 8.108,
      price_pc_1w: -15.789,
      price_pc_1m: -48.052,
      price_pc_6m: -58.333,
      price_pc_1y: -68.0,
      price_pc_ytd: -68.0,
    },
    9978: {
      id: "9978",
      c: 0.395,
      ma50: 0.411,
      ma100: 0.441,
      ma150: 0.471,
      ma200: 0.498,
      maw30: 0.471,
      rs: 31.0,
      rsd1d: 7.0,
      rsd5d: 10.0,
      rsd10d: 4.0,
      price_5d_h: 0.42,
      price_5d_L: 0.325,
      price_1m_h: 0.42,
      price_1m_l: 0.315,
      price_3m_h: 0.5,
      price_3m_l: 0.315,
      price_6m_h: 0.55,
      price_6m_l: 0.315,
      price_52w_h: 0.72,
      price_52w_l: 0.315,
      price_pc_1d: 3.947,
      price_pc_1w: 9.722,
      price_pc_1m: -4.819,
      price_pc_6m: -26.852,
      price_pc_1y: -26.852,
      price_pc_ytd: -26.852,
    },
    9979: {
      id: "9979",
      c: 6.71,
      ma50: 6.198,
      ma100: 5.379,
      ma150: 4.77,
      ma200: 4.723,
      maw30: 4.781,
      rs: 95.0,
      rsd1d: -1.0,
      rsd5d: 1.0,
      rsd10d: 0.0,
      price_5d_h: 7.15,
      price_5d_L: 6.37,
      price_1m_h: 7.15,
      price_1m_l: 4.43,
      price_3m_h: 7.2,
      price_3m_l: 4.43,
      price_6m_h: 7.2,
      price_6m_l: 3.13,
      price_52w_h: 7.2,
      price_52w_l: 3.06,
      price_pc_1d: -3.73,
      price_pc_1w: 5.836,
      price_pc_1m: 5.503,
      price_pc_6m: 107.099,
      price_pc_1y: 80.376,
      price_pc_ytd: 80.376,
    },
    9982: {
      id: "9982",
      c: 1.24,
      ma50: 1.201,
      ma100: 1.305,
      ma150: 1.344,
      ma200: 1.518,
      maw30: 1.339,
      rs: 23.0,
      rsd1d: 3.0,
      rsd5d: -2.0,
      rsd10d: 5.0,
      price_5d_h: 1.24,
      price_5d_L: 1.11,
      price_1m_h: 1.25,
      price_1m_l: 0.8,
      price_3m_h: 1.65,
      price_3m_l: 0.8,
      price_6m_h: 1.72,
      price_6m_l: 0.8,
      price_52w_h: 2.95,
      price_52w_l: 0.8,
      price_pc_1d: 3.333,
      price_pc_1w: 1.639,
      price_pc_1m: 18.095,
      price_pc_6m: -8.824,
      price_pc_1y: -53.03,
      price_pc_ytd: -53.03,
    },
    9983: {
      id: "9983",
      c: 4.35,
      ma50: 4.699,
      ma100: 4.873,
      ma150: 5.096,
      ma200: 5.561,
      maw30: 5.121,
      rs: 27.0,
      rsd1d: 1.0,
      rsd5d: 1.0,
      rsd10d: -5.0,
      price_5d_h: 4.61,
      price_5d_L: 3.9,
      price_1m_h: 4.88,
      price_1m_l: 3.03,
      price_3m_h: 5.61,
      price_3m_l: 3.03,
      price_6m_h: 6.24,
      price_6m_l: 3.03,
      price_52w_h: 8.38,
      price_52w_l: 3.03,
      price_pc_1d: -0.911,
      price_pc_1w: 0.694,
      price_pc_1m: -8.996,
      price_pc_6m: -21.622,
      price_pc_1y: -40.083,
      price_pc_ytd: -40.083,
    },
    9986: {
      id: "9986",
      c: 0.275,
      ma50: 0.311,
      ma100: 0.386,
      ma150: 0.419,
      ma200: 0.486,
      maw30: 0.416,
      rs: 8.0,
      rsd1d: 2.0,
      rsd5d: 1.0,
      rsd10d: 3.0,
      price_5d_h: 0.28,
      price_5d_L: 0.27,
      price_1m_h: 0.315,
      price_1m_l: 0.201,
      price_3m_h: 0.485,
      price_3m_l: 0.201,
      price_6m_h: 0.53,
      price_6m_l: 0.201,
      price_52w_h: 0.9,
      price_52w_l: 0.201,
      price_pc_1d: 0.0,
      price_pc_1w: -1.786,
      price_pc_1m: -8.333,
      price_pc_6m: -45.0,
      price_pc_1y: -58.955,
      price_pc_ytd: -58.955,
    },
    9987: {
      id: "9987",
      c: 347.6,
      ma50: 365.988,
      ma100: 381.298,
      ma150: 408.616,
      ma200: 430.529,
      maw30: 409.38,
      rs: 36.0,
      rsd1d: 1.0,
      rsd5d: 7.0,
      rsd10d: 2.0,
      price_5d_h: 352.0,
      price_5d_L: 314.8,
      price_1m_h: 428.0,
      price_1m_l: 281.0,
      price_3m_h: 428.0,
      price_3m_l: 281.0,
      price_6m_h: 478.2,
      price_6m_l: 281.0,
      price_52w_h: 544.0,
      price_52w_l: 281.0,
      price_pc_1d: 0.579,
      price_pc_1w: 4.01,
      price_pc_1m: -12.355,
      price_pc_6m: -24.533,
      price_pc_1y: -24.664,
      price_pc_ytd: -24.664,
    },
    9988: {
      id: "9988",
      c: 112.1,
      ma50: 109.789,
      ma100: 119.666,
      ma150: 132.881,
      ma200: 148.917,
      maw30: 132.248,
      rs: 26.0,
      rsd1d: -4.0,
      rsd5d: -2.0,
      rsd10d: 5.0,
      price_5d_h: 115.7,
      price_5d_L: 104.8,
      price_1m_h: 119.4,
      price_1m_l: 71.0,
      price_3m_h: 135.0,
      price_3m_l: 71.0,
      price_6m_h: 179.9,
      price_6m_l: 71.0,
      price_52w_h: 241.6,
      price_52w_l: 71.0,
      price_pc_1d: -1.58,
      price_pc_1w: -1.494,
      price_pc_1m: 7.582,
      price_pc_6m: -21.167,
      price_pc_1y: -50.31,
      price_pc_ytd: -50.31,
    },
    9989: {
      id: "9989",
      c: 5.76,
      ma50: 7.021,
      ma100: 7.574,
      ma150: 7.936,
      ma200: 8.457,
      maw30: 7.949,
      rs: 15.0,
      rsd1d: 1.0,
      rsd5d: 0.0,
      rsd10d: -5.0,
      price_5d_h: 5.98,
      price_5d_L: 5.69,
      price_1m_h: 7.4,
      price_1m_l: 5.53,
      price_3m_h: 8.79,
      price_3m_l: 5.53,
      price_6m_h: 8.94,
      price_6m_l: 5.53,
      price_52w_h: 11.92,
      price_52w_l: 5.53,
      price_pc_1d: -1.201,
      price_pc_1w: -2.538,
      price_pc_1m: -20.442,
      price_pc_6m: -31.834,
      price_pc_1y: -50.345,
      price_pc_ytd: -50.345,
    },
    9990: {
      id: "9990",
      c: 8.12,
      ma50: 8.07,
      ma100: 8.882,
      ma150: 9.117,
      ma200: 9.701,
      maw30: 9.093,
      rs: 33.0,
      rsd1d: -6.0,
      rsd5d: 3.0,
      rsd10d: -6.0,
      price_5d_h: 8.79,
      price_5d_L: 7.29,
      price_1m_h: 8.79,
      price_1m_l: 6.29,
      price_3m_h: 9.31,
      price_3m_l: 6.29,
      price_6m_h: 13.3,
      price_6m_l: 6.29,
      price_52w_h: 16.3,
      price_52w_l: 6.29,
      price_pc_1d: -5.361,
      price_pc_1w: 2.915,
      price_pc_1m: 0.247,
      price_pc_6m: -16.973,
      price_pc_1y: -49.502,
      price_pc_ytd: -49.502,
    },
    9991: {
      id: "9991",
      c: 22.8,
      ma50: 26.973,
      ma100: 32.654,
      ma150: 39.003,
      ma200: 48.448,
      maw30: 38.515,
      rs: 6.0,
      rsd1d: 0.0,
      rsd5d: 3.0,
      rsd10d: 3.0,
      price_5d_h: 23.9,
      price_5d_L: 19.92,
      price_1m_h: 28.25,
      price_1m_l: 14.02,
      price_3m_h: 37.5,
      price_3m_l: 14.02,
      price_6m_h: 53.9,
      price_6m_l: 14.02,
      price_52w_h: 100.0,
      price_52w_l: 14.02,
      price_pc_1d: 0.22,
      price_pc_1w: 12.871,
      price_pc_1m: -16.636,
      price_pc_6m: -49.89,
      price_pc_1y: -76.853,
      price_pc_ytd: -76.853,
    },
    9992: {
      id: "9992",
      c: 34.4,
      ma50: 38.2,
      ma100: 44.051,
      ma150: 47.787,
      ma200: 51.643,
      maw30: 47.332,
      rs: 16.0,
      rsd1d: -2.0,
      rsd5d: 0.0,
      rsd10d: 3.0,
      price_5d_h: 37.1,
      price_5d_L: 29.7,
      price_1m_h: 42.35,
      price_1m_l: 27.45,
      price_3m_h: 49.4,
      price_3m_l: 27.45,
      price_6m_h: 61.5,
      price_6m_l: 27.45,
      price_52w_h: 78.15,
      price_52w_l: 27.45,
      price_pc_1d: -5.365,
      price_pc_1w: -1.994,
      price_pc_1m: -12.133,
      price_pc_6m: -35.399,
      price_pc_1y: -46.708,
      price_pc_ytd: -46.708,
    },
    9993: {
      id: "9993",
      c: 4.4,
      ma50: 4.348,
      ma100: 4.129,
      ma150: 4.166,
      ma200: 4.217,
      maw30: 4.168,
      rs: 78.0,
      rsd1d: -1.0,
      rsd5d: 8.0,
      rsd10d: -2.0,
      price_5d_h: 4.48,
      price_5d_L: 4.06,
      price_1m_h: 4.5,
      price_1m_l: 3.93,
      price_3m_h: 4.63,
      price_3m_l: 3.8,
      price_6m_h: 4.63,
      price_6m_l: 3.6,
      price_52w_h: 5.2,
      price_52w_l: 3.6,
      price_pc_1d: 1.852,
      price_pc_1w: 6.538,
      price_pc_1m: 1.852,
      price_pc_6m: 3.774,
      price_pc_1y: -3.297,
      price_pc_ytd: -3.297,
    },
    9995: {
      id: "9995",
      c: 46.5,
      ma50: 54.539,
      ma100: 71.068,
      ma150: 80.889,
      ma200: 87.9,
      maw30: 80.673,
      rs: 8.0,
      rsd1d: -2.0,
      rsd5d: -7.0,
      rsd10d: -6.0,
      price_5d_h: 58.0,
      price_5d_L: 45.0,
      price_1m_h: 72.6,
      price_1m_l: 40.05,
      price_3m_h: 76.6,
      price_3m_l: 40.05,
      price_6m_h: 112.6,
      price_6m_l: 40.05,
      price_52w_h: 145.5,
      price_52w_l: 40.05,
      price_pc_1d: -8.012,
      price_pc_1w: -17.407,
      price_pc_1m: -30.855,
      price_pc_6m: -52.84,
      price_pc_1y: -56.338,
      price_pc_ytd: -56.338,
    },
    9996: {
      id: "9996",
      c: 7.1,
      ma50: 10.727,
      ma100: 12.966,
      ma150: 15.664,
      ma200: 19.584,
      maw30: 15.588,
      rs: 3.0,
      rsd1d: -1.0,
      rsd5d: -2.0,
      rsd10d: -3.0,
      price_5d_h: 8.93,
      price_5d_L: 6.73,
      price_1m_h: 13.02,
      price_1m_l: 6.73,
      price_3m_h: 13.58,
      price_3m_l: 6.73,
      price_6m_h: 21.95,
      price_6m_l: 6.73,
      price_52w_h: 39.1,
      price_52w_l: 6.73,
      price_pc_1d: -6.209,
      price_pc_1w: -17.538,
      price_pc_1m: -42.276,
      price_pc_6m: -65.196,
      price_pc_1y: -71.713,
      price_pc_ytd: -71.713,
    },
    9997: {
      id: "9997",
      c: 7.76,
      ma50: 7.867,
      ma100: 8.045,
      ma150: 8.68,
      ma200: 9.47,
      maw30: 8.742,
      rs: 40.0,
      rsd1d: -2.0,
      rsd5d: -2.0,
      rsd10d: 9.0,
      price_5d_h: 8.02,
      price_5d_L: 7.31,
      price_1m_h: 8.08,
      price_1m_l: 5.98,
      price_3m_h: 9.15,
      price_3m_l: 5.98,
      price_6m_h: 10.28,
      price_6m_l: 5.98,
      price_52w_h: 14.42,
      price_52w_l: 5.98,
      price_pc_1d: -0.767,
      price_pc_1w: -2.757,
      price_pc_1m: -0.767,
      price_pc_6m: -25.241,
      price_pc_1y: -17.005,
      price_pc_ytd: -17.005,
    },
    9999: {
      id: "9999",
      c: 143.8,
      ma50: 147.484,
      ma100: 155.805,
      ma150: 151.627,
      ma200: 153.326,
      maw30: 151.767,
      rs: 62.0,
      rsd1d: -3.0,
      rsd5d: -9.0,
      rsd10d: -1.0,
      price_5d_h: 149.8,
      price_5d_L: 138.7,
      price_1m_h: 154.6,
      price_1m_l: 108.3,
      price_3m_h: 169.7,
      price_3m_l: 108.3,
      price_6m_h: 188.9,
      price_6m_l: 108.3,
      price_52w_h: 190.7,
      price_52w_l: 108.3,
      price_pc_1d: -1.236,
      price_pc_1w: -2.969,
      price_pc_1m: -5.705,
      price_pc_6m: 9.354,
      price_pc_1y: -13.217,
      price_pc_ytd: -13.217,
    },
  },
};

const stockSector = {
  topicInfo: { lastUpdate: "2022/03/30 23:00", name: "stockSectorInfo2" },
  data: {
    "0001": { id: "0001", i: 0, s: 0, ss: 0 },
    "0002": { id: "0002", i: 1, s: 1, ss: 1 },
    "0003": { id: "0003", i: 1, s: 1, ss: 2 },
    "0004": { id: "0004", i: 2, s: 2, ss: 3 },
    "0005": { id: "0005", i: 3, s: 3, ss: 4 },
    "0006": { id: "0006", i: 1, s: 1, ss: 1 },
    "0007": { id: "0007", i: 2, s: 2, ss: 5 },
    "0008": { id: "0008", i: 4, s: 4, ss: 6 },
    "0009": { id: "0009", i: 2, s: 2, ss: 3 },
    "0010": { id: "0010", i: 2, s: 2, ss: 5 },
    "0011": { id: "0011", i: 3, s: 3, ss: 4 },
    "0012": { id: "0012", i: 2, s: 2, ss: 3 },
    "0013": { id: "0013", i: 5, s: 5, ss: 7 },
    "0014": { id: "0014", i: 2, s: 2, ss: 5 },
    "0016": { id: "0016", i: 2, s: 2, ss: 3 },
    "0017": { id: "0017", i: 2, s: 2, ss: 3 },
    "0018": { id: "0018", i: 6, s: 6, ss: 8 },
    "0019": { id: "0019", i: 0, s: 0, ss: 0 },
    "0020": { id: "0020", i: 7, s: 7, ss: 9 },
    "0021": { id: "0021", i: 2, s: 2, ss: 3 },
    "0022": { id: "0022", i: 6, s: 8, ss: 10 },
    "0023": { id: "0023", i: 3, s: 3, ss: 4 },
    "0024": { id: "0024", i: 8, s: 9, ss: 11 },
    "0025": { id: "0025", i: 0, s: 0, ss: 0 },
    "0026": { id: "0026", i: 2, s: 2, ss: 5 },
    "0027": { id: "0027", i: 6, s: 8, ss: 12 },
    "0028": { id: "0028", i: 2, s: 2, ss: 3 },
    "0029": { id: "0029", i: 2, s: 2, ss: 5 },
    "0030": { id: "0030", i: 9, s: 10, ss: 13 },
    "0031": { id: "0031", i: 10, s: 11, ss: 14 },
    "0032": { id: "0032", i: 6, s: 12, ss: 15 },
    "0033": { id: "0033", i: 6, s: 13, ss: 16 },
    "0034": { id: "0034", i: 2, s: 2, ss: 3 },
    "0035": { id: "0035", i: 2, s: 2, ss: 3 },
    "0036": { id: "0036", i: 2, s: 2, ss: 5 },
    "0037": { id: "0037", i: 2, s: 2, ss: 5 },
    "0038": { id: "0038", i: 10, s: 11, ss: 17 },
    "0039": { id: "0039", i: 9, s: 14, ss: 18 },
    "0040": { id: "0040", i: 6, s: 13, ss: 19 },
    "0041": { id: "0041", i: 2, s: 2, ss: 5 },
    "0042": { id: "0042", i: 10, s: 11, ss: 20 },
    "0043": { id: "0043", i: 9, s: 15, ss: 21 },
    "0045": { id: "0045", i: 6, s: 8, ss: 10 },
    "0046": { id: "0046", i: 7, s: 7, ss: 22 },
    "0047": { id: "0047", i: 6, s: 8, ss: 23 },
    "0048": { id: "0048", i: 6, s: 16, ss: 24 },
    "0050": { id: "0050", i: 2, s: 2, ss: 5 },
    "0051": { id: "0051", i: 6, s: 8, ss: 10 },
    "0052": { id: "0052", i: 6, s: 8, ss: 23 },
    "0053": { id: "0053", i: 2, s: 2, ss: 3 },
    "0055": { id: "0055", i: 10, s: 17, ss: 25 },
    "0057": { id: "0057", i: 10, s: 11, ss: 20 },
    "0058": { id: "0058", i: 2, s: 18, ss: 26 },
    "0059": { id: "0059", i: 2, s: 2, ss: 3 },
    "0060": { id: "0060", i: 9, s: 10, ss: 27 },
    "0061": { id: "0061", i: 11, s: 19, ss: 28 },
    "0062": { id: "0062", i: 6, s: 8, ss: 29 },
    "0063": { id: "0063", i: 2, s: 2, ss: 5 },
    "0064": { id: "0064", i: 3, s: 20, ss: 30 },
    "0065": { id: "0065", i: 11, s: 19, ss: 28 },
    "0066": { id: "0066", i: 6, s: 8, ss: 29 },
    "0067": { id: "0067", i: 8, s: 21, ss: 31 },
    "0068": { id: "0068", i: 3, s: 20, ss: 32 },
    "0069": { id: "0069", i: 6, s: 8, ss: 10 },
    "0070": { id: "0070", i: 6, s: 8, ss: 12 },
    "0071": { id: "0071", i: 6, s: 8, ss: 33 },
    "0072": { id: "0072", i: 6, s: 6, ss: 8 },
    "0073": { id: "0073", i: 9, s: 15, ss: 34 },
    "0075": { id: "0075", i: 2, s: 2, ss: 3 },
    "0076": { id: "0076", i: 10, s: 11, ss: 20 },
    "0077": { id: "0077", i: 6, s: 8, ss: 29 },
    "0078": { id: "0078", i: 6, s: 8, ss: 10 },
    "0079": { id: "0079", i: 6, s: 12, ss: 35 },
    "0080": { id: "0080", i: 3, s: 20, ss: 32 },
    "0081": { id: "0081", i: 2, s: 2, ss: 3 },
    "0082": { id: "0082", i: 7, s: 7, ss: 9 },
    "0083": { id: "0083", i: 2, s: 2, ss: 3 },
    "0084": { id: "0084", i: 6, s: 22, ss: 36 },
    "0085": { id: "0085", i: 7, s: 23, ss: 37 },
    "0086": { id: "0086", i: 3, s: 20, ss: 38 },
    "0087": { id: "0087", i: 0, s: 0, ss: 0 },
    "0088": { id: "0088", i: 2, s: 2, ss: 3 },
    "0089": { id: "0089", i: 2, s: 2, ss: 5 },
    "0090": { id: "0090", i: 1, s: 1, ss: 39 },
    "0091": { id: "0091", i: 11, s: 24, ss: 40 },
    "0092": { id: "0092", i: 11, s: 24, ss: 40 },
    "0093": { id: "0093", i: 2, s: 2, ss: 5 },
    "0094": { id: "0094", i: 8, s: 21, ss: 41 },
    "0095": { id: "0095", i: 2, s: 2, ss: 3 },
    "0096": { id: "0096", i: 10, s: 11, ss: 20 },
    "0097": { id: "0097", i: 6, s: 22, ss: 36 },
    "0098": { id: "0098", i: 8, s: 9, ss: 42 },
    "0099": { id: "0099", i: 10, s: 11, ss: 43 },
    "0100": { id: "0100", i: 6, s: 6, ss: 44 },
    "0101": { id: "0101", i: 2, s: 2, ss: 5 },
    "0102": { id: "0102", i: 6, s: 8, ss: 12 },
    "0103": { id: "0103", i: 10, s: 11, ss: 45 },
    "0104": { id: "0104", i: 6, s: 22, ss: 36 },
    "0105": { id: "0105", i: 2, s: 2, ss: 5 },
    "0106": { id: "0106", i: 2, s: 2, ss: 3 },
    "0107": { id: "0107", i: 10, s: 25, ss: 46 },
    "0108": { id: "0108", i: 2, s: 2, ss: 47 },
    "0109": { id: "0109", i: 3, s: 20, ss: 38 },
    "0110": { id: "0110", i: 7, s: 26, ss: 48 },
    "0111": { id: "0111", i: 3, s: 20, ss: 32 },
    "0112": { id: "0112", i: 2, s: 2, ss: 3 },
    "0113": { id: "0113", i: 6, s: 27, ss: 49 },
    "0114": { id: "0114", i: 6, s: 13, ss: 16 },
    "0115": { id: "0115", i: 2, s: 2, ss: 3 },
    "0116": { id: "0116", i: 6, s: 22, ss: 36 },
    "0117": { id: "0117", i: 10, s: 11, ss: 20 },
    "0118": { id: "0118", i: 10, s: 11, ss: 20 },
    "0119": { id: "0119", i: 2, s: 2, ss: 3 },
    "0120": { id: "0120", i: 2, s: 2, ss: 3 },
    "0122": { id: "0122", i: 6, s: 27, ss: 50 },
    "0123": { id: "0123", i: 2, s: 2, ss: 3 },
    "0124": { id: "0124", i: 2, s: 2, ss: 3 },
    "0125": { id: "0125", i: 6, s: 27, ss: 49 },
    "0126": { id: "0126", i: 6, s: 8, ss: 23 },
    "0127": { id: "0127", i: 2, s: 2, ss: 5 },
    "0128": { id: "0128", i: 6, s: 22, ss: 51 },
    "0129": { id: "0129", i: 3, s: 20, ss: 32 },
    "0130": { id: "0130", i: 6, s: 27, ss: 50 },
    "0131": { id: "0131", i: 2, s: 2, ss: 3 },
    "0132": { id: "0132", i: 0, s: 0, ss: 0 },
    "0133": { id: "0133", i: 3, s: 20, ss: 32 },
    "0135": { id: "0135", i: 1, s: 1, ss: 2 },
    "0136": { id: "0136", i: 7, s: 7, ss: 52 },
    "0137": { id: "0137", i: 10, s: 25, ss: 53 },
    "0138": { id: "0138", i: 6, s: 22, ss: 54 },
    "0139": { id: "0139", i: 3, s: 20, ss: 30 },
    "0141": { id: "0141", i: 2, s: 2, ss: 5 },
    "0142": { id: "0142", i: 9, s: 10, ss: 27 },
    "0143": { id: "0143", i: 7, s: 26, ss: 48 },
    "0144": { id: "0144", i: 10, s: 25, ss: 53 },
    "0145": { id: "0145", i: 6, s: 12, ss: 35 },
    "0146": { id: "0146", i: 6, s: 27, ss: 55 },
    "0147": { id: "0147", i: 2, s: 2, ss: 3 },
    "0148": { id: "0148", i: 10, s: 11, ss: 20 },
    "0149": { id: "0149", i: 2, s: 2, ss: 3 },
    "0150": { id: "0150", i: 6, s: 6, ss: 44 },
    "0151": { id: "0151", i: 9, s: 10, ss: 27 },
    "0152": { id: "0152", i: 10, s: 25, ss: 46 },
    "0153": { id: "0153", i: 2, s: 18, ss: 56 },
    "0154": { id: "0154", i: 10, s: 11, ss: 57 },
    "0155": { id: "0155", i: 10, s: 11, ss: 58 },
    "0156": { id: "0156", i: 6, s: 8, ss: 23 },
    "0157": { id: "0157", i: 6, s: 22, ss: 36 },
    "0158": { id: "0158", i: 2, s: 2, ss: 5 },
    "0159": { id: "0159", i: 8, s: 9, ss: 11 },
    "0160": { id: "0160", i: 2, s: 2, ss: 5 },
    "0162": { id: "0162", i: 6, s: 22, ss: 59 },
    "0163": { id: "0163", i: 2, s: 2, ss: 5 },
    "0164": { id: "0164", i: 6, s: 6, ss: 44 },
    "0165": { id: "0165", i: 3, s: 20, ss: 32 },
    "0166": { id: "0166", i: 8, s: 9, ss: 60 },
    "0167": { id: "0167", i: 6, s: 13, ss: 19 },
    "0168": { id: "0168", i: 9, s: 10, ss: 61 },
    "0169": { id: "0169", i: 6, s: 8, ss: 10 },
    "0171": { id: "0171", i: 2, s: 2, ss: 5 },
    "0172": { id: "0172", i: 3, s: 20, ss: 38 },
    "0173": { id: "0173", i: 2, s: 2, ss: 3 },
    "0174": { id: "0174", i: 3, s: 20, ss: 32 },
    "0175": { id: "0175", i: 6, s: 16, ss: 62 },
    "0176": { id: "0176", i: 6, s: 13, ss: 19 },
    "0177": { id: "0177", i: 10, s: 25, ss: 46 },
    "0178": { id: "0178", i: 6, s: 22, ss: 36 },
    "0179": { id: "0179", i: 6, s: 16, ss: 24 },
    "0180": { id: "0180", i: 6, s: 13, ss: 16 },
    "0181": { id: "0181", i: 6, s: 8, ss: 10 },
    "0182": { id: "0182", i: 1, s: 1, ss: 39 },
    "0183": { id: "0183", i: 2, s: 2, ss: 3 },
    "0184": { id: "0184", i: 6, s: 8, ss: 10 },
    "0185": { id: "0185", i: 2, s: 2, ss: 3 },
    "0186": { id: "0186", i: 6, s: 13, ss: 19 },
    "0187": { id: "0187", i: 10, s: 11, ss: 20 },
    "0188": { id: "0188", i: 3, s: 20, ss: 63 },
    "0189": { id: "0189", i: 8, s: 21, ss: 31 },
    "0190": { id: "0190", i: 2, s: 2, ss: 3 },
    "0191": { id: "0191", i: 0, s: 0, ss: 0 },
    "0193": { id: "0193", i: 2, s: 2, ss: 3 },
    "0194": { id: "0194", i: 2, s: 2, ss: 5 },
    "0195": { id: "0195", i: 8, s: 9, ss: 60 },
    "0196": { id: "0196", i: 11, s: 24, ss: 64 },
    "0197": { id: "0197", i: 9, s: 10, ss: 27 },
    "0199": { id: "0199", i: 2, s: 2, ss: 3 },
    "0200": { id: "0200", i: 6, s: 8, ss: 12 },
    "0201": { id: "0201", i: 6, s: 8, ss: 10 },
    "0202": { id: "0202", i: 0, s: 0, ss: 0 },
    "0204": { id: "0204", i: 3, s: 20, ss: 32 },
    "0205": { id: "0205", i: 6, s: 6, ss: 44 },
    "0206": { id: "0206", i: 11, s: 24, ss: 64 },
    "0207": { id: "0207", i: 2, s: 2, ss: 5 },
    "0208": { id: "0208", i: 2, s: 2, ss: 3 },
    "0209": { id: "0209", i: 6, s: 13, ss: 16 },
    "0210": { id: "0210", i: 6, s: 27, ss: 65 },
    "0211": { id: "0211", i: 3, s: 20, ss: 30 },
    "0212": { id: "0212", i: 2, s: 2, ss: 5 },
    "0213": { id: "0213", i: 10, s: 11, ss: 20 },
    "0214": { id: "0214", i: 3, s: 20, ss: 32 },
    "0215": { id: "0215", i: 4, s: 4, ss: 6 },
    "0216": { id: "0216", i: 2, s: 2, ss: 3 },
    "0217": { id: "0217", i: 8, s: 9, ss: 60 },
    "0218": { id: "0218", i: 3, s: 20, ss: 30 },
    "0219": { id: "0219", i: 6, s: 8, ss: 10 },
    "0220": { id: "0220", i: 9, s: 10, ss: 66 },
    "0222": { id: "0222", i: 3, s: 28, ss: 67 },
    "0223": { id: "0223", i: 7, s: 7, ss: 52 },
    "0224": { id: "0224", i: 2, s: 2, ss: 5 },
    "0225": { id: "0225", i: 2, s: 2, ss: 5 },
    "0226": { id: "0226", i: 6, s: 8, ss: 23 },
    "0227": { id: "0227", i: 3, s: 20, ss: 30 },
    "0228": { id: "0228", i: 11, s: 24, ss: 40 },
    "0229": { id: "0229", i: 6, s: 13, ss: 68 },
    "0230": { id: "0230", i: 2, s: 2, ss: 3 },
    "0231": { id: "0231", i: 3, s: 20, ss: 30 },
    "0232": { id: "0232", i: 10, s: 11, ss: 14 },
    "0234": { id: "0234", i: 6, s: 8, ss: 33 },
    "0235": { id: "0235", i: 8, s: 9, ss: 60 },
    "0236": { id: "0236", i: 9, s: 10, ss: 61 },
    "0237": { id: "0237", i: 2, s: 2, ss: 5 },
    "0238": { id: "0238", i: 6, s: 27, ss: 50 },
    "0239": { id: "0239", i: 5, s: 5, ss: 7 },
    "0240": { id: "0240", i: 2, s: 18, ss: 69 },
    "0241": { id: "0241", i: 5, s: 5, ss: 7 },
    "0242": { id: "0242", i: 0, s: 0, ss: 0 },
    "0243": { id: "0243", i: 7, s: 23, ss: 37 },
    "0244": { id: "0244", i: 6, s: 22, ss: 59 },
    "0245": { id: "0245", i: 3, s: 20, ss: 30 },
    "0247": { id: "0247", i: 2, s: 2, ss: 3 },
    "0248": { id: "0248", i: 7, s: 26, ss: 48 },
    "0250": { id: "0250", i: 7, s: 7, ss: 22 },
    "0251": { id: "0251", i: 2, s: 2, ss: 5 },
    "0252": { id: "0252", i: 10, s: 17, ss: 25 },
    "0253": { id: "0253", i: 6, s: 8, ss: 10 },
    "0254": { id: "0254", i: 6, s: 8, ss: 33 },
    "0255": { id: "0255", i: 10, s: 11, ss: 20 },
    "0256": { id: "0256", i: 6, s: 27, ss: 70 },
    "0257": { id: "0257", i: 1, s: 1, ss: 39 },
    "0258": { id: "0258", i: 2, s: 2, ss: 3 },
    "0259": { id: "0259", i: 6, s: 13, ss: 19 },
    "0260": { id: "0260", i: 3, s: 20, ss: 38 },
    "0261": { id: "0261", i: 2, s: 2, ss: 3 },
    "0262": { id: "0262", i: 2, s: 2, ss: 3 },
    "0263": { id: "0263", i: 3, s: 20, ss: 30 },
    "0264": { id: "0264", i: 6, s: 27, ss: 49 },
    "0265": { id: "0265", i: 6, s: 8, ss: 33 },
    "0266": { id: "0266", i: 2, s: 2, ss: 5 },
    "0267": { id: "0267", i: 0, s: 0, ss: 0 },
    "0268": { id: "0268", i: 7, s: 7, ss: 9 },
    "0269": { id: "0269", i: 10, s: 25, ss: 46 },
    "0270": { id: "0270", i: 1, s: 1, ss: 71 },
    "0271": { id: "0271", i: 2, s: 2, ss: 5 },
    "0272": { id: "0272", i: 2, s: 2, ss: 3 },
    "0273": { id: "0273", i: 3, s: 20, ss: 32 },
    "0274": { id: "0274", i: 8, s: 29, ss: 72 },
    "0276": { id: "0276", i: 11, s: 19, ss: 28 },
    "0277": { id: "0277", i: 2, s: 2, ss: 5 },
    "0278": { id: "0278", i: 2, s: 2, ss: 5 },
    "0279": { id: "0279", i: 3, s: 20, ss: 30 },
    "0280": { id: "0280", i: 6, s: 27, ss: 70 },
    "0281": { id: "0281", i: 2, s: 2, ss: 3 },
    "0282": { id: "0282", i: 6, s: 6, ss: 8 },
    "0285": { id: "0285", i: 7, s: 26, ss: 48 },
    "0286": { id: "0286", i: 5, s: 30, ss: 73 },
    "0287": { id: "0287", i: 2, s: 2, ss: 5 },
    "0288": { id: "0288", i: 9, s: 15, ss: 74 },
    "0289": { id: "0289", i: 6, s: 22, ss: 59 },
    "0290": { id: "0290", i: 3, s: 20, ss: 30 },
    "0291": { id: "0291", i: 9, s: 10, ss: 61 },
    "0292": { id: "0292", i: 3, s: 20, ss: 32 },
    "0293": { id: "0293", i: 6, s: 8, ss: 75 },
    "0294": { id: "0294", i: 6, s: 27, ss: 50 },
    "0295": { id: "0295", i: 1, s: 1, ss: 39 },
    "0296": { id: "0296", i: 6, s: 8, ss: 12 },
    "0297": { id: "0297", i: 8, s: 21, ss: 76 },
    "0298": { id: "0298", i: 2, s: 2, ss: 3 },
    "0299": { id: "0299", i: 2, s: 2, ss: 3 },
    "0301": { id: "0301", i: 8, s: 21, ss: 31 },
    "0302": { id: "0302", i: 7, s: 7, ss: 9 },
    "0303": { id: "0303", i: 7, s: 26, ss: 48 },
    "0305": { id: "0305", i: 6, s: 16, ss: 24 },
    "0306": { id: "0306", i: 6, s: 8, ss: 29 },
    "0307": { id: "0307", i: 11, s: 19, ss: 28 },
    "0308": { id: "0308", i: 6, s: 8, ss: 33 },
    "0309": { id: "0309", i: 6, s: 12, ss: 35 },
    "0310": { id: "0310", i: 3, s: 20, ss: 32 },
    "0311": { id: "0311", i: 6, s: 27, ss: 50 },
    "0312": { id: "0312", i: 6, s: 22, ss: 59 },
    "0313": { id: "0313", i: 2, s: 2, ss: 3 },
    "0315": { id: "0315", i: 4, s: 4, ss: 6 },
    "0316": { id: "0316", i: 10, s: 25, ss: 53 },
    "0317": { id: "0317", i: 10, s: 11, ss: 45 },
    "0318": { id: "0318", i: 7, s: 7, ss: 22 },
    "0320": { id: "0320", i: 10, s: 11, ss: 20 },
    "0321": { id: "0321", i: 6, s: 27, ss: 55 },
    "0322": { id: "0322", i: 9, s: 10, ss: 66 },
    "0323": { id: "0323", i: 8, s: 9, ss: 11 },
    "0326": { id: "0326", i: 6, s: 6, ss: 77 },
    "0327": { id: "0327", i: 7, s: 7, ss: 52 },
    "0328": { id: "0328", i: 6, s: 13, ss: 19 },
    "0329": { id: "0329", i: 3, s: 20, ss: 30 },
    "0330": { id: "0330", i: 6, s: 27, ss: 50 },
    "0331": { id: "0331", i: 2, s: 18, ss: 56 },
    "0332": { id: "0332", i: 11, s: 24, ss: 40 },
    "0333": { id: "0333", i: 6, s: 27, ss: 50 },
    "0334": { id: "0334", i: 7, s: 26, ss: 78 },
    "0335": { id: "0335", i: 3, s: 20, ss: 38 },
    "0336": { id: "0336", i: 9, s: 10, ss: 13 },
    "0337": { id: "0337", i: 2, s: 2, ss: 3 },
    "0338": { id: "0338", i: 8, s: 21, ss: 31 },
    "0339": { id: "0339", i: 3, s: 20, ss: 32 },
    "0340": { id: "0340", i: 8, s: 29, ss: 72 },
    "0341": { id: "0341", i: 6, s: 8, ss: 23 },
    "0342": { id: "0342", i: 11, s: 24, ss: 40 },
    "0343": { id: "0343", i: 6, s: 6, ss: 44 },
    "0345": { id: "0345", i: 9, s: 10, ss: 66 },
    "0346": { id: "0346", i: 11, s: 24, ss: 40 },
    "0347": { id: "0347", i: 8, s: 9, ss: 11 },
    "0348": { id: "0348", i: 5, s: 5, ss: 79 },
    "0351": { id: "0351", i: 10, s: 25, ss: 53 },
    "0352": { id: "0352", i: 2, s: 2, ss: 80 },
    "0353": { id: "0353", i: 11, s: 24, ss: 64 },
    "0354": { id: "0354", i: 7, s: 7, ss: 22 },
    "0355": { id: "0355", i: 6, s: 8, ss: 10 },
    "0356": { id: "0356", i: 3, s: 20, ss: 32 },
    "0357": { id: "0357", i: 6, s: 8, ss: 75 },
    "0358": { id: "0358", i: 8, s: 9, ss: 81 },
    "0359": { id: "0359", i: 9, s: 10, ss: 66 },
    "0360": { id: "0360", i: 6, s: 16, ss: 24 },
    "0361": { id: "0361", i: 6, s: 13, ss: 16 },
    "0362": { id: "0362", i: 8, s: 21, ss: 31 },
    "0363": { id: "0363", i: 0, s: 0, ss: 0 },
    "0364": { id: "0364", i: 9, s: 10, ss: 66 },
    "0365": { id: "0365", i: 10, s: 11, ss: 20 },
    "0366": { id: "0366", i: 2, s: 18, ss: 26 },
    "0367": { id: "0367", i: 2, s: 2, ss: 3 },
    "0368": { id: "0368", i: 2, s: 18, ss: 56 },
    "0369": { id: "0369", i: 2, s: 2, ss: 3 },
    "0370": { id: "0370", i: 6, s: 13, ss: 19 },
    "0371": { id: "0371", i: 1, s: 1, ss: 71 },
    "0372": { id: "0372", i: 3, s: 20, ss: 32 },
    "0373": { id: "0373", i: 3, s: 20, ss: 38 },
    "0374": { id: "0374", i: 9, s: 10, ss: 27 },
    "0375": { id: "0375", i: 6, s: 27, ss: 50 },
    "0376": { id: "0376", i: 3, s: 28, ss: 67 },
    "0377": { id: "0377", i: 10, s: 17, ss: 82 },
    "0378": { id: "0378", i: 8, s: 21, ss: 31 },
    "0379": { id: "0379", i: 3, s: 20, ss: 38 },
    "0380": { id: "0380", i: 2, s: 18, ss: 26 },
    "0381": { id: "0381", i: 6, s: 13, ss: 16 },
    "0382": { id: "0382", i: 6, s: 12, ss: 15 },
    "0383": { id: "0383", i: 5, s: 30, ss: 73 },
    "0384": { id: "0384", i: 1, s: 1, ss: 2 },
    "0385": { id: "0385", i: 2, s: 18, ss: 56 },
    "0386": { id: "0386", i: 11, s: 24, ss: 40 },
    "0387": { id: "0387", i: 10, s: 11, ss: 20 },
    "0388": { id: "0388", i: 3, s: 20, ss: 63 },
    "0389": { id: "0389", i: 9, s: 10, ss: 61 },
    "0390": { id: "0390", i: 2, s: 18, ss: 69 },
    "0391": { id: "0391", i: 6, s: 6, ss: 77 },
    "0392": { id: "0392", i: 1, s: 1, ss: 2 },
    "0393": { id: "0393", i: 6, s: 27, ss: 50 },
    "0395": { id: "0395", i: 7, s: 7, ss: 52 },
    "0396": { id: "0396", i: 6, s: 13, ss: 83 },
    "0397": { id: "0397", i: 3, s: 20, ss: 38 },
    "0398": { id: "0398", i: 6, s: 22, ss: 36 },
    "0399": { id: "0399", i: 9, s: 14, ss: 84 },
    "0400": { id: "0400", i: 7, s: 7, ss: 52 },
    "0401": { id: "0401", i: 5, s: 5, ss: 7 },
    "0403": { id: "0403", i: 10, s: 17, ss: 25 },
    "0405": { id: "0405", i: "", s: "", ss: 85 },
    "0406": { id: "0406", i: 2, s: 18, ss: 56 },
    "0408": { id: "0408", i: 8, s: 21, ss: 31 },
    "0410": { id: "0410", i: 2, s: 2, ss: 5 },
    "0411": { id: "0411", i: 9, s: 15, ss: 34 },
    "0412": { id: "0412", i: 3, s: 20, ss: 38 },
    "0413": { id: "0413", i: 6, s: 13, ss: 16 },
    "0416": { id: "0416", i: 3, s: 3, ss: 4 },
    "0417": { id: "0417", i: 6, s: 22, ss: 36 },
    "0418": { id: "0418", i: 7, s: 7, ss: 9 },
    "0419": { id: "0419", i: 6, s: 8, ss: 86 },
    "0420": { id: "0420", i: 6, s: 27, ss: 55 },
    "0422": { id: "0422", i: 6, s: 16, ss: 62 },
    "0423": { id: "0423", i: 6, s: 6, ss: 8 },
    "0425": { id: "0425", i: 6, s: 16, ss: 24 },
    "0426": { id: "0426", i: 6, s: 6, ss: 8 },
    "0428": { id: "0428", i: 3, s: 20, ss: 32 },
    "0430": { id: "0430", i: 3, s: 20, ss: 32 },
    "0431": { id: "0431", i: 3, s: 20, ss: 38 },
    "0432": { id: "0432", i: 2, s: 2, ss: 3 },
    "0433": { id: "0433", i: 8, s: 9, ss: 60 },
    "0434": { id: "0434", i: 7, s: 7, ss: 9 },
    "0435": { id: "0435", i: "", s: "", ss: 85 },
    "0436": { id: "0436", i: 10, s: 11, ss: 57 },
    "0438": { id: "0438", i: 10, s: 11, ss: 58 },
    "0439": { id: "0439", i: 10, s: 11, ss: 43 },
    "0440": { id: "0440", i: 3, s: 3, ss: 4 },
    "0442": { id: "0442", i: 6, s: 27, ss: 70 },
    "0444": { id: "0444", i: 6, s: 22, ss: 36 },
    "0449": { id: "0449", i: 6, s: 13, ss: 68 },
    "0450": { id: "0450", i: 10, s: 17, ss: 25 },
    "0451": { id: "0451", i: 1, s: 1, ss: 39 },
    "0455": { id: "0455", i: 5, s: 5, ss: 7 },
    "0456": { id: "0456", i: 2, s: 2, ss: 5 },
    "0458": { id: "0458", i: 6, s: 27, ss: 50 },
    "0459": { id: "0459", i: 2, s: 2, ss: 80 },
    "0460": { id: "0460", i: 5, s: 5, ss: 7 },
    "0462": { id: "0462", i: 9, s: 10, ss: 87 },
    "0464": { id: "0464", i: 6, s: 13, ss: 68 },
    "0465": { id: "0465", i: 7, s: 7, ss: 22 },
    "0467": { id: "0467", i: 11, s: 24, ss: 40 },
    "0468": { id: "0468", i: 10, s: 17, ss: 25 },
    "0471": { id: "0471", i: 4, s: 4, ss: 88 },
    "0472": { id: "0472", i: 9, s: 10, ss: 61 },
    "0474": { id: "0474", i: 10, s: 11, ss: 17 },
    "0475": { id: "0475", i: 10, s: 11, ss: 58 },
    "0476": { id: "0476", i: 10, s: 11, ss: 45 },
    "0479": { id: "0479", i: 6, s: 16, ss: 62 },
    "0480": { id: "0480", i: 2, s: 2, ss: 3 },
    "0482": { id: "0482", i: 6, s: 13, ss: 19 },
    "0483": { id: "0483", i: 6, s: 22, ss: 51 },
    "0484": { id: "0484", i: 7, s: 7, ss: 9 },
    "0485": { id: "0485", i: 6, s: 13, ss: 19 },
    "0486": { id: "0486", i: 8, s: 9, ss: 42 },
    "0487": { id: "0487", i: 6, s: 8, ss: 33 },
    "0488": { id: "0488", i: 0, s: 0, ss: 0 },
    "0489": { id: "0489", i: 10, s: 11, ss: 45 },
    "0491": { id: "0491", i: 6, s: 6, ss: 77 },
    "0493": { id: "0493", i: 6, s: 22, ss: 89 },
    "0495": { id: "0495", i: 2, s: 2, ss: 5 },
    "0496": { id: "0496", i: 6, s: 13, ss: 83 },
    "0497": { id: "0497", i: 2, s: 2, ss: 3 },
    "0498": { id: "0498", i: 2, s: 18, ss: 56 },
    "0499": { id: "0499", i: 6, s: 12, ss: 15 },
    "0500": { id: "0500", i: 10, s: 25, ss: 90 },
    "0503": { id: "0503", i: 5, s: 5, ss: 7 },
    "0505": { id: "0505", i: 8, s: 9, ss: 81 },
    "0506": { id: "0506", i: 9, s: 10, ss: 66 },
    "0508": { id: "0508", i: 2, s: 2, ss: 3 },
    "0509": { id: "0509", i: 8, s: 21, ss: 76 },
    "0510": { id: "0510", i: 3, s: 20, ss: 30 },
    "0511": { id: "0511", i: 6, s: 6, ss: 91 },
    "0512": { id: "0512", i: 5, s: 5, ss: 7 },
    "0513": { id: "0513", i: 6, s: 27, ss: 70 },
    "0515": { id: "0515", i: 10, s: 11, ss: 20 },
    "0517": { id: "0517", i: 10, s: 17, ss: 82 },
    "0518": { id: "0518", i: 6, s: 27, ss: 50 },
    "0519": { id: "0519", i: 2, s: 2, ss: 3 },
    "0520": { id: "0520", i: 6, s: 8, ss: 23 },
    "0521": { id: "0521", i: 8, s: 9, ss: 60 },
    "0522": { id: "0522", i: 7, s: 23, ss: 37 },
    "0524": { id: "0524", i: 4, s: 4, ss: 6 },
    "0525": { id: "0525", i: 6, s: 8, ss: 29 },
    "0526": { id: "0526", i: 6, s: 22, ss: 54 },
    "0527": { id: "0527", i: 1, s: 1, ss: 39 },
    "0528": { id: "0528", i: 6, s: 27, ss: 55 },
    "0529": { id: "0529", i: 7, s: 26, ss: 48 },
    "0530": { id: "0530", i: 0, s: 0, ss: 0 },
    "0531": { id: "0531", i: 6, s: 13, ss: 83 },
    "0532": { id: "0532", i: 10, s: 11, ss: 20 },
    "0533": { id: "0533", i: 6, s: 27, ss: 50 },
    "0535": { id: "0535", i: 2, s: 2, ss: 3 },
    "0536": { id: "0536", i: 7, s: 7, ss: 52 },
    "0538": { id: "0538", i: 6, s: 8, ss: 23 },
    "0539": { id: "0539", i: 6, s: 27, ss: 55 },
    "0540": { id: "0540", i: 6, s: 27, ss: 50 },
    "0542": { id: "0542", i: 6, s: 8, ss: 10 },
    "0543": { id: "0543", i: 6, s: 22, ss: 54 },
    "0544": { id: "0544", i: 10, s: 25, ss: 90 },
    "0546": { id: "0546", i: 9, s: 10, ss: 13 },
    "0547": { id: "0547", i: 6, s: 6, ss: 77 },
    "0548": { id: "0548", i: 10, s: 25, ss: 46 },
    "0550": { id: "0550", i: 6, s: 6, ss: 44 },
    "0551": { id: "0551", i: 6, s: 27, ss: 65 },
    "0552": { id: "0552", i: 7, s: 26, ss: 48 },
    "0553": { id: "0553", i: 7, s: 26, ss: 48 },
    "0554": { id: "0554", i: 11, s: 24, ss: 64 },
    "0555": { id: "0555", i: 6, s: 8, ss: 12 },
    "0556": { id: "0556", i: 10, s: 11, ss: 57 },
    "0557": { id: "0557", i: 5, s: 30, ss: 73 },
    "0558": { id: "0558", i: 10, s: 11, ss: 20 },
    "0559": { id: "0559", i: 6, s: 8, ss: 10 },
    "0560": { id: "0560", i: 10, s: 25, ss: 53 },
    "0563": { id: "0563", i: 2, s: 2, ss: 3 },
    "0564": { id: "0564", i: 6, s: 16, ss: 24 },
    "0565": { id: "0565", i: 2, s: 2, ss: 5 },
    "0567": { id: "0567", i: 11, s: 24, ss: 40 },
    "0568": { id: "0568", i: 11, s: 24, ss: 64 },
    "0570": { id: "0570", i: 5, s: 5, ss: 79 },
    "0571": { id: "0571", i: 6, s: 6, ss: 77 },
    "0572": { id: "0572", i: 3, s: 20, ss: 32 },
    "0573": { id: "0573", i: 6, s: 8, ss: 23 },
    "0574": { id: "0574", i: 5, s: 5, ss: 7 },
    "0575": { id: "0575", i: 5, s: 5, ss: 7 },
    "0576": { id: "0576", i: 10, s: 25, ss: 46 },
    "0577": { id: "0577", i: 2, s: 18, ss: 56 },
    "0578": { id: "0578", i: 11, s: 19, ss: 28 },
    "0579": { id: "0579", i: 1, s: 1, ss: 1 },
    "0580": { id: "0580", i: 10, s: 11, ss: 20 },
    "0581": { id: "0581", i: 8, s: 9, ss: 11 },
    "0582": { id: "0582", i: 6, s: 8, ss: 10 },
    "0583": { id: "0583", i: 2, s: 2, ss: 5 },
    "0585": { id: "0585", i: 3, s: 20, ss: 30 },
    "0586": { id: "0586", i: 10, s: 11, ss: 57 },
    "0588": { id: "0588", i: 2, s: 2, ss: 3 },
    "0589": { id: "0589", i: 2, s: 18, ss: 69 },
    "0590": { id: "0590", i: 6, s: 22, ss: 36 },
    "0591": { id: "0591", i: 10, s: 11, ss: 20 },
    "0592": { id: "0592", i: 6, s: 27, ss: 50 },
    "0593": { id: "0593", i: 2, s: 2, ss: 3 },
    "0595": { id: "0595", i: 7, s: 23, ss: 37 },
    "0596": { id: "0596", i: 7, s: 7, ss: 9 },
    "0598": { id: "0598", i: 10, s: 25, ss: 53 },
    "0599": { id: "0599", i: 2, s: 18, ss: 56 },
    "0600": { id: "0600", i: 11, s: 24, ss: 64 },
    "0601": { id: "0601", i: 8, s: 9, ss: 60 },
    "0602": { id: "0602", i: 6, s: 22, ss: 59 },
    "0603": { id: "0603", i: 1, s: 1, ss: 2 },
    "0604": { id: "0604", i: 2, s: 2, ss: 3 },
    "0605": { id: "0605", i: 3, s: 20, ss: 38 },
    "0606": { id: "0606", i: 2, s: 2, ss: 47 },
    "0607": { id: "0607", i: 10, s: 11, ss: 57 },
    "0608": { id: "0608", i: 6, s: 27, ss: 50 },
    "0609": { id: "0609", i: 8, s: 21, ss: 31 },
    "0610": { id: "0610", i: 2, s: 18, ss: 69 },
    "0611": { id: "0611", i: 1, s: 1, ss: 39 },
    "0612": { id: "0612", i: 3, s: 20, ss: 32 },
    "0613": { id: "0613", i: 3, s: 20, ss: 32 },
    "0616": { id: "0616", i: 2, s: 2, ss: 5 },
    "0617": { id: "0617", i: 6, s: 8, ss: 10 },
    "0618": { id: "0618", i: 2, s: 2, ss: 3 },
    "0619": { id: "0619", i: 3, s: 20, ss: 30 },
    "0620": { id: "0620", i: 0, s: 0, ss: 0 },
    "0621": { id: "0621", i: 8, s: 29, ss: 72 },
    "0622": { id: "0622", i: 3, s: 20, ss: 30 },
    "0623": { id: "0623", i: 6, s: 6, ss: 44 },
    "0625": { id: "0625", i: "", s: "", ss: 85 },
    "0626": { id: "0626", i: 3, s: 3, ss: 4 },
    "0627": { id: "0627", i: 2, s: 2, ss: 3 },
    "0628": { id: "0628", i: 3, s: 20, ss: 38 },
    "0629": { id: "0629", i: 3, s: 20, ss: 38 },
    "0630": { id: "0630", i: 2, s: 18, ss: 56 },
    "0631": { id: "0631", i: 10, s: 11, ss: 17 },
    "0632": { id: "0632", i: 11, s: 24, ss: 40 },
    "0633": { id: "0633", i: 4, s: 4, ss: 88 },
    "0635": { id: "0635", i: 6, s: 13, ss: 16 },
    "0636": { id: "0636", i: 10, s: 25, ss: 90 },
    "0637": { id: "0637", i: 8, s: 9, ss: 60 },
    "0638": { id: "0638", i: 6, s: 13, ss: 68 },
    "0639": { id: "0639", i: 11, s: 19, ss: 28 },
    "0640": { id: "0640", i: 8, s: 21, ss: 31 },
    "0641": { id: "0641", i: 10, s: 11, ss: 20 },
    "0643": { id: "0643", i: 6, s: 27, ss: 50 },
    "0645": { id: "0645", i: 11, s: 19, ss: 28 },
    "0646": { id: "0646", i: 1, s: 1, ss: 71 },
    "0648": { id: "0648", i: 5, s: 30, ss: 92 },
    "0650": { id: "0650", i: 11, s: 24, ss: 40 },
    "0651": { id: "0651", i: 10, s: 11, ss: 17 },
    "0653": { id: "0653", i: 9, s: 14, ss: 84 },
    "0655": { id: "0655", i: 2, s: 2, ss: 5 },
    "0656": { id: "0656", i: 0, s: 0, ss: 0 },
    "0657": { id: "0657", i: 6, s: 8, ss: 23 },
    "0658": { id: "0658", i: 10, s: 11, ss: 57 },
    "0659": { id: "0659", i: 0, s: 0, ss: 0 },
    "0660": { id: "0660", i: 8, s: 21, ss: 31 },
    "0661": { id: "0661", i: 8, s: 9, ss: 81 },
    "0662": { id: "0662", i: 3, s: 28, ss: 67 },
    "0663": { id: "0663", i: 0, s: 0, ss: 0 },
    "0665": { id: "0665", i: 3, s: 20, ss: 30 },
    "0666": { id: "0666", i: 3, s: 20, ss: 32 },
    "0667": { id: "0667", i: 6, s: 12, ss: 15 },
    "0668": { id: "0668", i: 3, s: 20, ss: 38 },
    "0669": { id: "0669", i: 6, s: 13, ss: 68 },
    "0670": { id: "0670", i: 6, s: 8, ss: 75 },
    "0672": { id: "0672", i: 2, s: 2, ss: 3 },
    "0673": { id: "0673", i: 5, s: 30, ss: 92 },
    "0674": { id: "0674", i: 2, s: 2, ss: 5 },
    "0675": { id: "0675", i: 10, s: 11, ss: 20 },
    "0676": { id: "0676", i: 6, s: 27, ss: 65 },
    "0677": { id: "0677", i: 9, s: 15, ss: 34 },
    "0678": { id: "0678", i: 6, s: 8, ss: 33 },
    "0679": { id: "0679", i: 10, s: 11, ss: 20 },
    "0680": { id: "0680", i: 2, s: 2, ss: 3 },
    "0681": { id: "0681", i: 1, s: 1, ss: 2 },
    "0682": { id: "0682", i: 9, s: 15, ss: 34 },
    "0683": { id: "0683", i: 2, s: 2, ss: 3 },
    "0684": { id: "0684", i: 6, s: 13, ss: 68 },
    "0685": { id: "0685", i: 6, s: 6, ss: 8 },
    "0686": { id: "0686", i: 1, s: 1, ss: 39 },
    "0687": { id: "0687", i: 2, s: 18, ss: 69 },
    "0688": { id: "0688", i: 2, s: 2, ss: 3 },
    "0689": { id: "0689", i: 3, s: 20, ss: 38 },
    "0690": { id: "0690", i: 5, s: 5, ss: 7 },
    "0691": { id: "0691", i: 2, s: 18, ss: 26 },
    "0693": { id: "0693", i: 6, s: 22, ss: 54 },
    "0694": { id: "0694", i: 6, s: 8, ss: 75 },
    "0695": { id: "0695", i: 2, s: 18, ss: 26 },
    "0696": { id: "0696", i: 7, s: 7, ss: 22 },
    "0697": { id: "0697", i: 3, s: 20, ss: 32 },
    "0698": { id: "0698", i: 10, s: 11, ss: 43 },
    "0699": { id: "0699", i: 6, s: 8, ss: 33 },
    "0700": { id: "0700", i: 7, s: 7, ss: 52 },
    "0701": { id: "0701", i: 2, s: 18, ss: 26 },
    "0702": { id: "0702", i: 11, s: 24, ss: 40 },
    "0703": { id: "0703", i: 6, s: 8, ss: 23 },
    "0704": { id: "0704", i: 11, s: 19, ss: 28 },
    "0706": { id: "0706", i: 8, s: 21, ss: 41 },
    "0707": { id: "0707", i: 6, s: 27, ss: 55 },
    "0708": { id: "0708", i: 5, s: 30, ss: 73 },
    "0709": { id: "0709", i: 6, s: 27, ss: 50 },
    "0710": { id: "0710", i: 7, s: 26, ss: 78 },
    "0711": { id: "0711", i: 2, s: 18, ss: 69 },
    "0712": { id: "0712", i: 10, s: 11, ss: 58 },
    "0713": { id: "0713", i: 6, s: 13, ss: 83 },
    "0715": { id: "0715", i: 2, s: 2, ss: 5 },
    "0716": { id: "0716", i: 10, s: 11, ss: 45 },
    "0717": { id: "0717", i: 3, s: 20, ss: 30 },
    "0718": { id: "0718", i: 5, s: 30, ss: 73 },
    "0719": { id: "0719", i: 5, s: 5, ss: 7 },
    "0720": { id: "0720", i: 6, s: 16, ss: 62 },
    "0721": { id: "0721", i: 3, s: 20, ss: 32 },
    "0722": { id: "0722", i: 5, s: 30, ss: 73 },
    "0723": { id: "0723", i: 8, s: 21, ss: 41 },
    "0724": { id: "0724", i: 7, s: 23, ss: 37 },
    "0725": { id: "0725", i: 10, s: 11, ss: 20 },
    "0726": { id: "0726", i: 2, s: 2, ss: 3 },
    "0727": { id: "0727", i: 2, s: 2, ss: 5 },
    "0728": { id: "0728", i: 4, s: 4, ss: 6 },
    "0729": { id: "0729", i: 10, s: 11, ss: 45 },
    "0730": { id: "0730", i: 2, s: 2, ss: 5 },
    "0731": { id: "0731", i: 8, s: 21, ss: 93 },
    "0732": { id: "0732", i: 10, s: 11, ss: 43 },
    "0733": { id: "0733", i: 2, s: 2, ss: 80 },
    "0736": { id: "0736", i: 3, s: 20, ss: 38 },
    "0737": { id: "0737", i: 10, s: 25, ss: 46 },
    "0738": { id: "0738", i: 6, s: 27, ss: 65 },
    "0743": { id: "0743", i: 2, s: 18, ss: 26 },
    "0745": { id: "0745", i: 6, s: 6, ss: 44 },
    "0746": { id: "0746", i: 8, s: 21, ss: 31 },
    "0747": { id: "0747", i: 2, s: 18, ss: 56 },
    "0750": { id: "0750", i: 2, s: 18, ss: 56 },
    "0751": { id: "0751", i: 6, s: 13, ss: 19 },
    "0752": { id: "0752", i: 6, s: 6, ss: 44 },
    "0753": { id: "0753", i: 6, s: 8, ss: 75 },
    "0754": { id: "0754", i: 2, s: 2, ss: 3 },
    "0755": { id: "0755", i: 2, s: 2, ss: 3 },
    "0756": { id: "0756", i: 9, s: 10, ss: 66 },
    "0757": { id: "0757", i: 10, s: 11, ss: 58 },
    "0758": { id: "0758", i: 8, s: 9, ss: 60 },
    "0759": { id: "0759", i: 9, s: 14, ss: 18 },
    "0760": { id: "0760", i: 2, s: 2, ss: 3 },
    "0762": { id: "0762", i: 4, s: 4, ss: 6 },
    "0763": { id: "0763", i: 7, s: 26, ss: 48 },
    "0764": { id: "0764", i: 0, s: 0, ss: 0 },
    "0765": { id: "0765", i: 6, s: 13, ss: 16 },
    "0766": { id: "0766", i: 3, s: 20, ss: 38 },
    "0767": { id: "0767", i: 3, s: 20, ss: 38 },
    "0768": { id: "0768", i: 3, s: 20, ss: 32 },
    "0769": { id: "0769", i: 8, s: 9, ss: 60 },
    "0770": { id: "0770", i: 3, s: 20, ss: 32 },
    "0771": { id: "0771", i: 7, s: 7, ss: 22 },
    "0772": { id: "0772", i: 6, s: 6, ss: 8 },
    "0775": { id: "0775", i: 5, s: 5, ss: 7 },
    "0776": { id: "0776", i: 6, s: 13, ss: 83 },
    "0777": { id: "0777", i: 7, s: 7, ss: 9 },
    "0778": { id: "0778", i: "", s: "", ss: 85 },
    "0780": { id: "0780", i: 6, s: 8, ss: 33 },
    "0784": { id: "0784", i: 2, s: 18, ss: 56 },
    "0787": { id: "0787", i: 6, s: 27, ss: 50 },
    "0788": { id: "0788", i: 4, s: 4, ss: 88 },
    "0789": { id: "0789", i: 6, s: 27, ss: 70 },
    "0794": { id: "0794", i: 10, s: 17, ss: 25 },
    "0797": { id: "0797", i: 7, s: 7, ss: 9 },
    "0798": { id: "0798", i: 2, s: 2, ss: 47 },
    "0799": { id: "0799", i: 7, s: 7, ss: 9 },
    "0800": { id: "0800", i: 2, s: 2, ss: 5 },
    "0802": { id: "0802", i: 7, s: 7, ss: 9 },
    "0803": { id: "0803", i: 8, s: 9, ss: 11 },
    "0804": { id: "0804", i: 3, s: 20, ss: 30 },
    "0806": { id: "0806", i: 3, s: 20, ss: 32 },
    "0807": { id: "0807", i: 1, s: 1, ss: 71 },
    "0808": { id: "0808", i: "", s: "", ss: 85 },
    "0809": { id: "0809", i: 8, s: 21, ss: 76 },
    "0810": { id: "0810", i: 3, s: 20, ss: 32 },
    "0811": { id: "0811", i: 6, s: 6, ss: 8 },
    "0812": { id: "0812", i: 3, s: 20, ss: 32 },
    "0813": { id: "0813", i: 2, s: 2, ss: 3 },
    "0814": { id: "0814", i: 9, s: 14, ss: 18 },
    "0815": { id: "0815", i: 8, s: 29, ss: 72 },
    "0816": { id: "0816", i: 2, s: 2, ss: 47 },
    "0817": { id: "0817", i: 2, s: 2, ss: 3 },
    "0818": { id: "0818", i: 7, s: 7, ss: 52 },
    "0819": { id: "0819", i: 10, s: 11, ss: 58 },
    "0820": { id: "0820", i: "", s: "", ss: 85 },
    "0821": { id: "0821", i: 3, s: 20, ss: 30 },
    "0822": { id: "0822", i: 10, s: 11, ss: 20 },
    "0823": { id: "0823", i: "", s: "", ss: 85 },
    "0825": { id: "0825", i: 6, s: 22, ss: 59 },
    "0826": { id: "0826", i: 8, s: 9, ss: 11 },
    "0827": { id: "0827", i: 8, s: 21, ss: 76 },
    "0828": { id: "0828", i: 9, s: 10, ss: 61 },
    "0829": { id: "0829", i: 9, s: 10, ss: 27 },
    "0830": { id: "0830", i: 2, s: 18, ss: 56 },
    "0831": { id: "0831", i: 9, s: 14, ss: 18 },
    "0832": { id: "0832", i: 2, s: 2, ss: 3 },
    "0833": { id: "0833", i: 6, s: 13, ss: 68 },
    "0834": { id: "0834", i: 9, s: 10, ss: 27 },
    "0836": { id: "0836", i: 1, s: 1, ss: 1 },
    "0837": { id: "0837", i: 6, s: 13, ss: 83 },
    "0838": { id: "0838", i: 10, s: 11, ss: 20 },
    "0839": { id: "0839", i: 6, s: 12, ss: 15 },
    "0840": { id: "0840", i: 10, s: 11, ss: 20 },
    "0841": { id: "0841", i: 10, s: 11, ss: 58 },
    "0842": { id: "0842", i: 10, s: 11, ss: 58 },
    "0844": { id: "0844", i: 6, s: 27, ss: 50 },
    "0845": { id: "0845", i: 2, s: 2, ss: 3 },
    "0846": { id: "0846", i: 2, s: 2, ss: 3 },
    "0848": { id: "0848", i: 6, s: 22, ss: 59 },
    "0850": { id: "0850", i: 10, s: 11, ss: 20 },
    "0851": { id: "0851", i: 3, s: 20, ss: 30 },
    "0852": { id: "0852", i: 11, s: 24, ss: 40 },
    "0853": { id: "0853", i: 5, s: 30, ss: 92 },
    "0854": { id: "0854", i: 10, s: 11, ss: 20 },
    "0855": { id: "0855", i: 1, s: 1, ss: 71 },
    "0856": { id: "0856", i: 7, s: 7, ss: 22 },
    "0857": { id: "0857", i: 11, s: 24, ss: 40 },
    "0858": { id: "0858", i: 5, s: 5, ss: 7 },
    "0859": { id: "0859", i: 2, s: 2, ss: 5 },
    "0860": { id: "0860", i: 6, s: 27, ss: 70 },
    "0861": { id: "0861", i: 7, s: 7, ss: 22 },
    "0862": { id: "0862", i: 7, s: 7, ss: 22 },
    "0863": { id: "0863", i: 3, s: 20, ss: 63 },
    "0864": { id: "0864", i: 2, s: 2, ss: 5 },
    "0865": { id: "0865", i: 2, s: 2, ss: 3 },
    "0866": { id: "0866", i: 11, s: 19, ss: 28 },
    "0867": { id: "0867", i: 5, s: 5, ss: 7 },
    "0868": { id: "0868", i: 10, s: 11, ss: 20 },
    "0869": { id: "0869", i: 6, s: 13, ss: 16 },
    "0871": { id: "0871", i: 2, s: 18, ss: 69 },
    "0872": { id: "0872", i: 6, s: 16, ss: 24 },
    "0873": { id: "0873", i: 2, s: 2, ss: 47 },
    "0874": { id: "0874", i: 5, s: 5, ss: 79 },
    "0875": { id: "0875", i: 9, s: 15, ss: 34 },
    "0876": { id: "0876", i: 5, s: 30, ss: 92 },
    "0878": { id: "0878", i: 2, s: 2, ss: 5 },
    "0880": { id: "0880", i: 6, s: 8, ss: 12 },
    "0881": { id: "0881", i: 6, s: 22, ss: 54 },
    "0882": { id: "0882", i: 0, s: 0, ss: 0 },
    "0883": { id: "0883", i: 11, s: 24, ss: 40 },
    "0884": { id: "0884", i: 2, s: 2, ss: 3 },
    "0885": { id: "0885", i: 7, s: 7, ss: 9 },
    "0886": { id: "0886", i: 9, s: 10, ss: 61 },
    "0887": { id: "0887", i: 6, s: 22, ss: 36 },
    "0888": { id: "0888", i: 6, s: 6, ss: 91 },
    "0889": { id: "0889", i: 10, s: 11, ss: 20 },
    "0891": { id: "0891", i: 6, s: 22, ss: 51 },
    "0893": { id: "0893", i: 8, s: 9, ss: 11 },
    "0894": { id: "0894", i: 10, s: 11, ss: 20 },
    "0895": { id: "0895", i: 10, s: 11, ss: 57 },
    "0896": { id: "0896", i: 2, s: 18, ss: 56 },
    "0897": { id: "0897", i: 5, s: 5, ss: 79 },
    "0898": { id: "0898", i: 2, s: 2, ss: 5 },
    "0899": { id: "0899", i: 2, s: 2, ss: 5 },
    "0900": { id: "0900", i: 3, s: 20, ss: 38 },
    "0901": { id: "0901", i: 3, s: 20, ss: 32 },
    "0902": { id: "0902", i: 1, s: 1, ss: 1 },
    "0904": { id: "0904", i: 9, s: 15, ss: 34 },
    "0905": { id: "0905", i: 3, s: 20, ss: 32 },
    "0906": { id: "0906", i: 10, s: 17, ss: 25 },
    "0907": { id: "0907", i: 6, s: 27, ss: 49 },
    "0908": { id: "0908", i: 0, s: 0, ss: 0 },
    "0909": { id: "0909", i: 7, s: 7, ss: 22 },
    "0910": { id: "0910", i: 2, s: 2, ss: 3 },
    "0911": { id: "0911", i: 5, s: 5, ss: 7 },
    "0912": { id: "0912", i: 6, s: 13, ss: 19 },
    "0913": { id: "0913", i: 3, s: 20, ss: 32 },
    "0914": { id: "0914", i: 2, s: 18, ss: 26 },
    "0915": { id: "0915", i: 10, s: 17, ss: 82 },
    "0916": { id: "0916", i: 1, s: 1, ss: 39 },
    "0918": { id: "0918", i: 6, s: 27, ss: 50 },
    "0919": { id: "0919", i: 5, s: 30, ss: 73 },
    "0921": { id: "0921", i: 6, s: 13, ss: 68 },
    "0922": { id: "0922", i: 6, s: 12, ss: 35 },
    "0923": { id: "0923", i: 8, s: 21, ss: 93 },
    "0924": { id: "0924", i: 2, s: 18, ss: 56 },
    "0925": { id: "0925", i: 10, s: 25, ss: 90 },
    "0926": { id: "0926", i: 9, s: 15, ss: 34 },
    "0927": { id: "0927", i: 6, s: 13, ss: 19 },
    "0928": { id: "0928", i: 5, s: 5, ss: 94 },
    "0929": { id: "0929", i: 10, s: 11, ss: 20 },
    "0931": { id: "0931", i: 1, s: 1, ss: 39 },
    "0932": { id: "0932", i: 5, s: 5, ss: 7 },
    "0934": { id: "0934", i: 11, s: 24, ss: 64 },
    "0935": { id: "0935", i: 10, s: 25, ss: 53 },
    "0936": { id: "0936", i: 10, s: 11, ss: 17 },
    "0938": { id: "0938", i: 2, s: 2, ss: 3 },
    "0939": { id: "0939", i: 3, s: 3, ss: 4 },
    "0941": { id: "0941", i: 4, s: 4, ss: 6 },
    "0943": { id: "0943", i: 6, s: 13, ss: 83 },
    "0945": { id: "0945", i: 3, s: 28, ss: 67 },
    "0947": { id: "0947", i: 7, s: 26, ss: 48 },
    "0948": { id: "0948", i: 9, s: 10, ss: 87 },
    "0950": { id: "0950", i: 5, s: 5, ss: 7 },
    "0951": { id: "0951", i: 10, s: 11, ss: 58 },
    "0952": { id: "0952", i: 3, s: 20, ss: 30 },
    "0953": { id: "0953", i: 6, s: 6, ss: 77 },
    "0954": { id: "0954", i: 9, s: 10, ss: 13 },
    "0956": { id: "0956", i: 1, s: 1, ss: 2 },
    "0959": { id: "0959", i: 6, s: 8, ss: 12 },
    "0960": { id: "0960", i: 2, s: 2, ss: 3 },
    "0966": { id: "0966", i: 3, s: 28, ss: 67 },
    "0967": { id: "0967", i: 1, s: 1, ss: 71 },
    "0968": { id: "0968", i: 10, s: 11, ss: 58 },
    "0969": { id: "0969", i: 9, s: 15, ss: 34 },
    "0970": { id: "0970", i: 6, s: 22, ss: 54 },
    "0973": { id: "0973", i: 6, s: 22, ss: 36 },
    "0974": { id: "0974", i: 6, s: 22, ss: 59 },
    "0975": { id: "0975", i: 11, s: 19, ss: 28 },
    "0976": { id: "0976", i: 8, s: 9, ss: 81 },
    "0978": { id: "0978", i: 2, s: 2, ss: 3 },
    "0979": { id: "0979", i: 10, s: 11, ss: 58 },
    "0980": { id: "0980", i: 9, s: 14, ss: 18 },
    "0981": { id: "0981", i: 7, s: 23, ss: 37 },
    "0982": { id: "0982", i: 2, s: 2, ss: 47 },
    "0983": { id: "0983", i: 2, s: 18, ss: 56 },
    "0984": { id: "0984", i: 9, s: 14, ss: 18 },
    "0985": { id: "0985", i: 3, s: 20, ss: 32 },
    "0986": { id: "0986", i: 6, s: 27, ss: 70 },
    "0987": { id: "0987", i: 1, s: 1, ss: 39 },
    "0988": { id: "0988", i: 10, s: 25, ss: 90 },
    "0989": { id: "0989", i: 2, s: 2, ss: 3 },
    "0990": { id: "0990", i: 8, s: 21, ss: 31 },
    "0991": { id: "0991", i: 1, s: 1, ss: 1 },
    "0992": { id: "0992", i: 7, s: 26, ss: 78 },
    "0993": { id: "0993", i: 3, s: 20, ss: 32 },
    "0994": { id: "0994", i: 2, s: 18, ss: 56 },
    "0995": { id: "0995", i: 10, s: 25, ss: 46 },
    "0996": { id: "0996", i: 2, s: 2, ss: 3 },
    "0997": { id: "0997", i: 10, s: 17, ss: 82 },
    "0998": { id: "0998", i: 3, s: 3, ss: 4 },
    "0999": { id: "0999", i: 6, s: 22, ss: 51 },
    1000: { id: "1000", i: 6, s: 6, ss: 8 },
    1001: { id: "1001", i: 8, s: 9, ss: 11 },
    1002: { id: "1002", i: 10, s: 11, ss: 20 },
    1003: { id: "1003", i: 6, s: 6, ss: 77 },
    1004: { id: "1004", i: 1, s: 1, ss: 39 },
    1005: { id: "1005", i: 6, s: 13, ss: 16 },
    1007: { id: "1007", i: 6, s: 8, ss: 23 },
    1008: { id: "1008", i: 10, s: 17, ss: 25 },
    1009: { id: "1009", i: 2, s: 2, ss: 5 },
    1010: { id: "1010", i: 7, s: 23, ss: 37 },
    1011: { id: "1011", i: 5, s: 5, ss: 7 },
    1013: { id: "1013", i: 7, s: 26, ss: 48 },
    1019: { id: "1019", i: 3, s: 28, ss: 67 },
    1020: { id: "1020", i: 7, s: 7, ss: 52 },
    1022: { id: "1022", i: 7, s: 7, ss: 9 },
    1023: { id: "1023", i: 6, s: 27, ss: 49 },
    1024: { id: "1024", i: 7, s: 7, ss: 9 },
    1025: { id: "1025", i: 6, s: 27, ss: 50 },
    1026: { id: "1026", i: 1, s: 1, ss: 71 },
    1027: { id: "1027", i: 6, s: 13, ss: 83 },
    1028: { id: "1028", i: 6, s: 27, ss: 65 },
    1029: { id: "1029", i: 8, s: 9, ss: 11 },
    1030: { id: "1030", i: 2, s: 2, ss: 3 },
    1031: { id: "1031", i: 3, s: 20, ss: 30 },
    1033: { id: "1033", i: 11, s: 24, ss: 64 },
    1034: { id: "1034", i: 2, s: 18, ss: 69 },
    1036: { id: "1036", i: 2, s: 2, ss: 5 },
    1037: { id: "1037", i: 7, s: 7, ss: 22 },
    1038: { id: "1038", i: 1, s: 1, ss: 1 },
    1039: { id: "1039", i: 7, s: 7, ss: 52 },
    1041: { id: "1041", i: 3, s: 20, ss: 32 },
    1043: { id: "1043", i: 10, s: 11, ss: 58 },
    1044: { id: "1044", i: 9, s: 14, ss: 84 },
    1045: { id: "1045", i: 4, s: 4, ss: 88 },
    1046: { id: "1046", i: 6, s: 6, ss: 77 },
    1047: { id: "1047", i: 8, s: 21, ss: 31 },
    1049: { id: "1049", i: 6, s: 22, ss: 89 },
    1050: { id: "1050", i: 7, s: 26, ss: 78 },
    1051: { id: "1051", i: 3, s: 20, ss: 32 },
    1052: { id: "1052", i: 10, s: 25, ss: 46 },
    1053: { id: "1053", i: 8, s: 9, ss: 11 },
    1054: { id: "1054", i: 3, s: 20, ss: 30 },
    1055: { id: "1055", i: 6, s: 8, ss: 75 },
    1057: { id: "1057", i: 6, s: 16, ss: 24 },
    1058: { id: "1058", i: 6, s: 27, ss: 49 },
    1059: { id: "1059", i: 7, s: 7, ss: 22 },
    1060: { id: "1060", i: 6, s: 6, ss: 77 },
    1061: { id: "1061", i: 5, s: 5, ss: 7 },
    1062: { id: "1062", i: 3, s: 20, ss: 32 },
    1063: { id: "1063", i: 7, s: 26, ss: 48 },
    1064: { id: "1064", i: 2, s: 2, ss: 5 },
    1065: { id: "1065", i: 1, s: 1, ss: 71 },
    1066: { id: "1066", i: 5, s: 30, ss: 92 },
    1068: { id: "1068", i: 9, s: 15, ss: 74 },
    1069: { id: "1069", i: 8, s: 21, ss: 41 },
    1070: { id: "1070", i: 6, s: 13, ss: 19 },
    1071: { id: "1071", i: 1, s: 1, ss: 1 },
    1072: { id: "1072", i: 10, s: 11, ss: 57 },
    1073: { id: "1073", i: 3, s: 20, ss: 63 },
    1074: { id: "1074", i: 8, s: 9, ss: 11 },
    1075: { id: "1075", i: 7, s: 7, ss: 22 },
    1076: { id: "1076", i: 6, s: 8, ss: 12 },
    1079: { id: "1079", i: 7, s: 26, ss: 78 },
    1080: { id: "1080", i: 11, s: 24, ss: 64 },
    1082: { id: "1082", i: 6, s: 12, ss: 15 },
    1083: { id: "1083", i: 1, s: 1, ss: 2 },
    1084: { id: "1084", i: 9, s: 10, ss: 13 },
    1085: { id: "1085", i: 7, s: 26, ss: 48 },
    1086: { id: "1086", i: 6, s: 13, ss: 83 },
    1087: { id: "1087", i: 7, s: 7, ss: 22 },
    1088: { id: "1088", i: 11, s: 19, ss: 28 },
    1090: { id: "1090", i: 8, s: 9, ss: 11 },
    1091: { id: "1091", i: 8, s: 9, ss: 60 },
    1093: { id: "1093", i: 5, s: 5, ss: 7 },
    1094: { id: "1094", i: 7, s: 7, ss: 52 },
    1096: { id: "1096", i: 1, s: 1, ss: 2 },
    1097: { id: "1097", i: 6, s: 6, ss: 91 },
    1098: { id: "1098", i: 2, s: 2, ss: 3 },
    1099: { id: "1099", i: 5, s: 5, ss: 7 },
    1100: { id: "1100", i: 6, s: 27, ss: 49 },
    1101: { id: "1101", i: 11, s: 24, ss: 40 },
    1102: { id: "1102", i: 2, s: 18, ss: 26 },
    1103: { id: "1103", i: 8, s: 21, ss: 76 },
    1104: { id: "1104", i: 8, s: 9, ss: 11 },
    1105: { id: "1105", i: 6, s: 6, ss: 8 },
    1106: { id: "1106", i: 10, s: 17, ss: 25 },
    1107: { id: "1107", i: 2, s: 2, ss: 3 },
    1108: { id: "1108", i: 10, s: 11, ss: 20 },
    1109: { id: "1109", i: 2, s: 2, ss: 3 },
    1110: { id: "1110", i: 5, s: 5, ss: 7 },
    1111: { id: "1111", i: 3, s: 3, ss: 4 },
    1112: { id: "1112", i: 9, s: 14, ss: 84 },
    1113: { id: "1113", i: 2, s: 2, ss: 3 },
    1114: { id: "1114", i: 6, s: 16, ss: 62 },
    1115: { id: "1115", i: 9, s: 10, ss: 66 },
    1116: { id: "1116", i: 8, s: 9, ss: 11 },
    1117: { id: "1117", i: 9, s: 10, ss: 87 },
    1118: { id: "1118", i: 2, s: 18, ss: 26 },
    1119: { id: "1119", i: 7, s: 7, ss: 9 },
    1120: { id: "1120", i: 6, s: 27, ss: 49 },
    1121: { id: "1121", i: 6, s: 27, ss: 65 },
    1122: { id: "1122", i: 10, s: 11, ss: 45 },
    1123: { id: "1123", i: 6, s: 13, ss: 19 },
    1124: { id: "1124", i: 2, s: 2, ss: 3 },
    1125: { id: "1125", i: 2, s: 2, ss: 5 },
    1126: { id: "1126", i: 6, s: 13, ss: 16 },
    1127: { id: "1127", i: 10, s: 17, ss: 25 },
    1128: { id: "1128", i: 6, s: 8, ss: 12 },
    1129: { id: "1129", i: 1, s: 1, ss: 71 },
    1130: { id: "1130", i: 6, s: 16, ss: 62 },
    1131: { id: "1131", i: 11, s: 19, ss: 28 },
    1132: { id: "1132", i: 6, s: 6, ss: 77 },
    1133: { id: "1133", i: 10, s: 11, ss: 57 },
    1134: { id: "1134", i: 6, s: 27, ss: 49 },
    1137: { id: "1137", i: 7, s: 7, ss: 52 },
    1138: { id: "1138", i: 10, s: 25, ss: 53 },
    1139: { id: "1139", i: 6, s: 22, ss: 54 },
    1140: { id: "1140", i: 3, s: 20, ss: 32 },
    1141: { id: "1141", i: 3, s: 20, ss: 30 },
    1142: { id: "1142", i: 8, s: 9, ss: 60 },
    1143: { id: "1143", i: 6, s: 13, ss: 19 },
    1145: { id: "1145", i: 10, s: 25, ss: 53 },
    1146: { id: "1146", i: 6, s: 27, ss: 50 },
    1147: { id: "1147", i: 7, s: 7, ss: 22 },
    1148: { id: "1148", i: 6, s: 16, ss: 24 },
    1150: { id: "1150", i: 6, s: 27, ss: 49 },
    1152: { id: "1152", i: 9, s: 10, ss: 27 },
    1153: { id: "1153", i: 2, s: 2, ss: 47 },
    1156: { id: "1156", i: 11, s: 24, ss: 64 },
    1157: { id: "1157", i: 10, s: 11, ss: 17 },
    1158: { id: "1158", i: 6, s: 8, ss: 10 },
    1159: { id: "1159", i: 6, s: 6, ss: 77 },
    1160: { id: "1160", i: 3, s: 20, ss: 32 },
    1161: { id: "1161", i: 5, s: 30, ss: 73 },
    1162: { id: "1162", i: 2, s: 18, ss: 56 },
    1163: { id: "1163", i: 6, s: 6, ss: 44 },
    1164: { id: "1164", i: 8, s: 9, ss: 60 },
    1165: { id: "1165", i: 1, s: 1, ss: 39 },
    1166: { id: "1166", i: 10, s: 11, ss: 20 },
    1167: { id: "1167", i: 5, s: 5, ss: 94 },
    1168: { id: "1168", i: 2, s: 2, ss: 5 },
    1170: { id: "1170", i: 6, s: 27, ss: 65 },
    1171: { id: "1171", i: 11, s: 19, ss: 28 },
    1172: { id: "1172", i: 10, s: 17, ss: 25 },
    1173: { id: "1173", i: 9, s: 14, ss: 84 },
    1175: { id: "1175", i: 9, s: 10, ss: 27 },
    1176: { id: "1176", i: 2, s: 2, ss: 3 },
    1177: { id: "1177", i: 5, s: 5, ss: 7 },
    1178: { id: "1178", i: 0, s: 0, ss: 0 },
    1179: { id: "1179", i: 6, s: 8, ss: 10 },
    1180: { id: "1180", i: 6, s: 8, ss: 12 },
    1181: { id: "1181", i: 6, s: 8, ss: 23 },
    1182: { id: "1182", i: 6, s: 8, ss: 12 },
    1183: { id: "1183", i: 2, s: 18, ss: 56 },
    1184: { id: "1184", i: 10, s: 11, ss: 20 },
    1185: { id: "1185", i: 10, s: 11, ss: 57 },
    1186: { id: "1186", i: 2, s: 18, ss: 69 },
    1188: { id: "1188", i: 6, s: 16, ss: 24 },
    1189: { id: "1189", i: 6, s: 8, ss: 10 },
    1191: { id: "1191", i: 3, s: 20, ss: 32 },
    1192: { id: "1192", i: 10, s: 11, ss: 45 },
    1193: { id: "1193", i: 1, s: 1, ss: 2 },
    1194: { id: "1194", i: 8, s: 29, ss: 72 },
    1195: { id: "1195", i: 2, s: 2, ss: 3 },
    1196: { id: "1196", i: 10, s: 11, ss: 57 },
    1198: { id: "1198", i: 6, s: 13, ss: 83 },
    1199: { id: "1199", i: 10, s: 25, ss: 53 },
    1200: { id: "1200", i: 2, s: 2, ss: 80 },
    1201: { id: "1201", i: 2, s: 2, ss: 3 },
    1202: { id: "1202", i: 7, s: 26, ss: 48 },
    1203: { id: "1203", i: 8, s: 9, ss: 11 },
    1205: { id: "1205", i: 8, s: 9, ss: 60 },
    1206: { id: "1206", i: 7, s: 7, ss: 22 },
    1207: { id: "1207", i: 2, s: 2, ss: 3 },
    1208: { id: "1208", i: 8, s: 9, ss: 81 },
    1209: { id: "1209", i: 2, s: 2, ss: 47 },
    1210: { id: "1210", i: 9, s: 10, ss: 27 },
    1211: { id: "1211", i: 6, s: 16, ss: 62 },
    1212: { id: "1212", i: 6, s: 22, ss: 59 },
    1213: { id: "1213", i: 10, s: 11, ss: 20 },
    1215: { id: "1215", i: 6, s: 8, ss: 10 },
    1216: { id: "1216", i: 3, s: 3, ss: 4 },
    1217: { id: "1217", i: 3, s: 20, ss: 32 },
    1218: { id: "1218", i: 2, s: 2, ss: 3 },
    1220: { id: "1220", i: 2, s: 18, ss: 56 },
    1221: { id: "1221", i: 6, s: 8, ss: 10 },
    1222: { id: "1222", i: 2, s: 2, ss: 3 },
    1223: { id: "1223", i: 6, s: 22, ss: 51 },
    1224: { id: "1224", i: 2, s: 2, ss: 3 },
    1225: { id: "1225", i: 3, s: 20, ss: 38 },
    1226: { id: "1226", i: 3, s: 20, ss: 32 },
    1227: { id: "1227", i: 3, s: 20, ss: 32 },
    1228: { id: "1228", i: 5, s: 5, ss: 94 },
    1229: { id: "1229", i: 11, s: 19, ss: 28 },
    1230: { id: "1230", i: 9, s: 10, ss: 87 },
    1231: { id: "1231", i: 8, s: 9, ss: 11 },
    1232: { id: "1232", i: 2, s: 2, ss: 3 },
    1233: { id: "1233", i: 2, s: 2, ss: 3 },
    1234: { id: "1234", i: 6, s: 27, ss: 50 },
    1235: { id: "1235", i: 6, s: 8, ss: 33 },
    1237: { id: "1237", i: 6, s: 13, ss: 83 },
    1238: { id: "1238", i: 2, s: 2, ss: 3 },
    1239: { id: "1239", i: 10, s: 17, ss: 25 },
    1240: { id: "1240", i: 2, s: 2, ss: 3 },
    1241: { id: "1241", i: 6, s: 16, ss: 24 },
    1243: { id: "1243", i: 2, s: 2, ss: 3 },
    1245: { id: "1245", i: 6, s: 8, ss: 12 },
    1246: { id: "1246", i: 2, s: 2, ss: 3 },
    1247: { id: "1247", i: 6, s: 27, ss: 50 },
    1250: { id: "1250", i: 1, s: 1, ss: 39 },
    1251: { id: "1251", i: 11, s: 24, ss: 64 },
    1252: { id: "1252", i: 2, s: 18, ss: 26 },
    1253: { id: "1253", i: 2, s: 18, ss: 56 },
    1255: { id: "1255", i: 6, s: 22, ss: 51 },
    1257: { id: "1257", i: 1, s: 1, ss: 39 },
    1258: { id: "1258", i: 8, s: 9, ss: 81 },
    1259: { id: "1259", i: 9, s: 14, ss: 84 },
    1260: { id: "1260", i: 3, s: 20, ss: 63 },
    1262: { id: "1262", i: 9, s: 10, ss: 27 },
    1263: { id: "1263", i: 7, s: 26, ss: 78 },
    1265: { id: "1265", i: 1, s: 1, ss: 2 },
    1266: { id: "1266", i: 8, s: 9, ss: 11 },
    1268: { id: "1268", i: 6, s: 22, ss: 54 },
    1269: { id: "1269", i: 6, s: 16, ss: 24 },
    1270: { id: "1270", i: 6, s: 8, ss: 10 },
    1271: { id: "1271", i: 2, s: 18, ss: 56 },
    1272: { id: "1272", i: 10, s: 11, ss: 57 },
    1273: { id: "1273", i: 3, s: 20, ss: 38 },
    1275: { id: "1275", i: "", s: "", ss: 85 },
    1277: { id: "1277", i: 11, s: 19, ss: 28 },
    1278: { id: "1278", i: 2, s: 2, ss: 3 },
    1280: { id: "1280", i: 6, s: 22, ss: 89 },
    1281: { id: "1281", i: 1, s: 1, ss: 39 },
    1282: { id: "1282", i: 3, s: 20, ss: 30 },
    1283: { id: "1283", i: 2, s: 18, ss: 56 },
    1285: { id: "1285", i: 9, s: 10, ss: 27 },
    1286: { id: "1286", i: 10, s: 11, ss: 20 },
    1288: { id: "1288", i: 3, s: 3, ss: 4 },
    1289: { id: "1289", i: 10, s: 11, ss: 20 },
    1290: { id: "1290", i: 3, s: 20, ss: 38 },
    1292: { id: "1292", i: 10, s: 25, ss: 90 },
    1293: { id: "1293", i: 6, s: 22, ss: 54 },
    1296: { id: "1296", i: 10, s: 11, ss: 57 },
    1297: { id: "1297", i: 7, s: 7, ss: 22 },
    1298: { id: "1298", i: 5, s: 30, ss: 92 },
    1299: { id: "1299", i: 3, s: 28, ss: 67 },
    1300: { id: "1300", i: 7, s: 26, ss: 48 },
    1301: { id: "1301", i: 10, s: 11, ss: 17 },
    1302: { id: "1302", i: 5, s: 30, ss: 92 },
    1303: { id: "1303", i: 11, s: 19, ss: 28 },
    1305: { id: "1305", i: 10, s: 11, ss: 20 },
    1308: { id: "1308", i: 10, s: 25, ss: 53 },
    1310: { id: "1310", i: 4, s: 4, ss: 6 },
    1312: { id: "1312", i: 2, s: 18, ss: 26 },
    1313: { id: "1313", i: 2, s: 18, ss: 26 },
    1314: { id: "1314", i: 6, s: 8, ss: 23 },
    1315: { id: "1315", i: 2, s: 18, ss: 56 },
    1316: { id: "1316", i: 6, s: 16, ss: 24 },
    1317: { id: "1317", i: 6, s: 12, ss: 15 },
    1319: { id: "1319", i: 3, s: 20, ss: 38 },
    1321: { id: "1321", i: 2, s: 2, ss: 3 },
    1323: { id: "1323", i: 0, s: 0, ss: 0 },
    1326: { id: "1326", i: 6, s: 6, ss: 77 },
    1327: { id: "1327", i: 6, s: 27, ss: 70 },
    1328: { id: "1328", i: 6, s: 12, ss: 35 },
    1329: { id: "1329", i: 2, s: 2, ss: 3 },
    1330: { id: "1330", i: 10, s: 11, ss: 57 },
    1332: { id: "1332", i: 10, s: 17, ss: 25 },
    1333: { id: "1333", i: 8, s: 9, ss: 42 },
    1335: { id: "1335", i: 1, s: 1, ss: 1 },
    1336: { id: "1336", i: 3, s: 28, ss: 67 },
    1337: { id: "1337", i: 7, s: 26, ss: 78 },
    1338: { id: "1338", i: 9, s: 14, ss: 84 },
    1339: { id: "1339", i: 3, s: 28, ss: 67 },
    1340: { id: "1340", i: 9, s: 15, ss: 74 },
    1341: { id: "1341", i: 10, s: 11, ss: 17 },
    1343: { id: "1343", i: 2, s: 18, ss: 69 },
    1345: { id: "1345", i: 5, s: 5, ss: 7 },
    1346: { id: "1346", i: 6, s: 27, ss: 50 },
    1347: { id: "1347", i: 7, s: 23, ss: 37 },
    1348: { id: "1348", i: 6, s: 13, ss: 16 },
    1349: { id: "1349", i: 5, s: 5, ss: 94 },
    1351: { id: "1351", i: 6, s: 6, ss: 44 },
    1355: { id: "1355", i: 6, s: 8, ss: 10 },
    1357: { id: "1357", i: 7, s: 7, ss: 52 },
    1358: { id: "1358", i: 5, s: 30, ss: 92 },
    1359: { id: "1359", i: 3, s: 20, ss: 32 },
    1360: { id: "1360", i: 6, s: 6, ss: 44 },
    1361: { id: "1361", i: 6, s: 27, ss: 65 },
    1362: { id: "1362", i: 7, s: 26, ss: 48 },
    1363: { id: "1363", i: 1, s: 1, ss: 71 },
    1365: { id: "1365", i: 6, s: 16, ss: 62 },
    1366: { id: "1366", i: 10, s: 11, ss: 20 },
    1367: { id: "1367", i: 6, s: 22, ss: 51 },
    1368: { id: "1368", i: 6, s: 27, ss: 65 },
    1370: { id: "1370", i: 8, s: 9, ss: 11 },
    1371: { id: "1371", i: 6, s: 8, ss: 12 },
    1372: { id: "1372", i: 2, s: 18, ss: 69 },
    1373: { id: "1373", i: 6, s: 22, ss: 89 },
    1375: { id: "1375", i: 3, s: 20, ss: 30 },
    1376: { id: "1376", i: 2, s: 18, ss: 56 },
    1378: { id: "1378", i: 8, s: 9, ss: 42 },
    1379: { id: "1379", i: 2, s: 2, ss: 5 },
    1380: { id: "1380", i: 8, s: 9, ss: 60 },
    1381: { id: "1381", i: 1, s: 1, ss: 39 },
    1382: { id: "1382", i: 6, s: 27, ss: 55 },
    1383: { id: "1383", i: 6, s: 8, ss: 33 },
    1385: { id: "1385", i: 7, s: 23, ss: 37 },
    1386: { id: "1386", i: 6, s: 22, ss: 36 },
    1387: { id: "1387", i: 2, s: 2, ss: 5 },
    1388: { id: "1388", i: 6, s: 27, ss: 50 },
    1389: { id: "1389", i: 9, s: 10, ss: 61 },
    1393: { id: "1393", i: 11, s: 19, ss: 28 },
    1395: { id: "1395", i: 1, s: 1, ss: 71 },
    1396: { id: "1396", i: 2, s: 2, ss: 3 },
    1397: { id: "1397", i: 6, s: 12, ss: 35 },
    1398: { id: "1398", i: 3, s: 3, ss: 4 },
    1399: { id: "1399", i: 10, s: 11, ss: 58 },
    1400: { id: "1400", i: 6, s: 27, ss: 55 },
    1401: { id: "1401", i: 10, s: 11, ss: 43 },
    1402: { id: "1402", i: 7, s: 7, ss: 22 },
    1406: { id: "1406", i: 5, s: 30, ss: 73 },
    1408: { id: "1408", i: 2, s: 18, ss: 56 },
    1410: { id: "1410", i: 7, s: 7, ss: 22 },
    1412: { id: "1412", i: 10, s: 17, ss: 25 },
    1413: { id: "1413", i: 2, s: 18, ss: 69 },
    1415: { id: "1415", i: 10, s: 11, ss: 43 },
    1416: { id: "1416", i: 2, s: 18, ss: 56 },
    1417: { id: "1417", i: 2, s: 2, ss: 47 },
    1418: { id: "1418", i: 6, s: 13, ss: 83 },
    1419: { id: "1419", i: 5, s: 30, ss: 73 },
    1420: { id: "1420", i: 2, s: 18, ss: 56 },
    1421: { id: "1421", i: 2, s: 18, ss: 69 },
    1425: { id: "1425", i: 6, s: 27, ss: 50 },
    1426: { id: "1426", i: "", s: "", ss: 85 },
    1427: { id: "1427", i: 2, s: 18, ss: 56 },
    1428: { id: "1428", i: 3, s: 20, ss: 30 },
    1429: { id: "1429", i: 2, s: 18, ss: 56 },
    1430: { id: "1430", i: 1, s: 1, ss: 2 },
    1431: { id: "1431", i: 9, s: 10, ss: 87 },
    1432: { id: "1432", i: 9, s: 10, ss: 87 },
    1433: { id: "1433", i: 10, s: 17, ss: 25 },
    1439: { id: "1439", i: 10, s: 17, ss: 25 },
    1440: { id: "1440", i: 6, s: 27, ss: 55 },
    1442: { id: "1442", i: 10, s: 25, ss: 90 },
    1443: { id: "1443", i: 6, s: 8, ss: 23 },
    1446: { id: "1446", i: 9, s: 10, ss: 66 },
    1447: { id: "1447", i: 2, s: 18, ss: 56 },
    1448: { id: "1448", i: 6, s: 12, ss: 35 },
    1449: { id: "1449", i: 6, s: 12, ss: 15 },
    1450: { id: "1450", i: 7, s: 7, ss: 22 },
    1451: { id: "1451", i: 10, s: 17, ss: 25 },
    1452: { id: "1452", i: 10, s: 11, ss: 57 },
    1455: { id: "1455", i: 6, s: 13, ss: 68 },
    1456: { id: "1456", i: 3, s: 20, ss: 30 },
    1458: { id: "1458", i: 9, s: 10, ss: 27 },
    1459: { id: "1459", i: 2, s: 18, ss: 56 },
    1460: { id: "1460", i: 7, s: 7, ss: 22 },
    1461: { id: "1461", i: 3, s: 20, ss: 30 },
    1463: { id: "1463", i: 6, s: 12, ss: 35 },
    1466: { id: "1466", i: 6, s: 22, ss: 36 },
    1468: { id: "1468", i: 0, s: 0, ss: 0 },
    1469: { id: "1469", i: 3, s: 20, ss: 30 },
    1470: { id: "1470", i: 6, s: 22, ss: 36 },
    1472: { id: "1472", i: 2, s: 18, ss: 69 },
    1473: { id: "1473", i: 7, s: 26, ss: 48 },
    1475: { id: "1475", i: 9, s: 10, ss: 27 },
    1476: { id: "1476", i: 3, s: 20, ss: 30 },
    1477: { id: "1477", i: 5, s: 5, ss: 94 },
    1478: { id: "1478", i: 10, s: 11, ss: 43 },
    1480: { id: "1480", i: 10, s: 11, ss: 20 },
    1481: { id: "1481", i: 8, s: 21, ss: 93 },
    1483: { id: "1483", i: 6, s: 12, ss: 35 },
    1486: { id: "1486", i: 2, s: 18, ss: 56 },
    1488: { id: "1488", i: 6, s: 8, ss: 23 },
    1490: { id: "1490", i: 6, s: 6, ss: 44 },
    1492: { id: "1492", i: 9, s: 10, ss: 87 },
    1495: { id: "1495", i: 2, s: 18, ss: 56 },
    1496: { id: "1496", i: 2, s: 18, ss: 69 },
    1498: { id: "1498", i: 5, s: 5, ss: 7 },
    1499: { id: "1499", i: 2, s: 18, ss: 56 },
    1500: { id: "1500", i: 2, s: 18, ss: 56 },
    1501: { id: "1501", i: 5, s: 30, ss: 92 },
    1502: { id: "1502", i: 2, s: 2, ss: 47 },
    1503: { id: "1503", i: "", s: "", ss: 85 },
    1508: { id: "1508", i: 3, s: 28, ss: 67 },
    1509: { id: "1509", i: 5, s: 30, ss: 73 },
    1513: { id: "1513", i: 5, s: 5, ss: 7 },
    1515: { id: "1515", i: 5, s: 30, ss: 73 },
    1516: { id: "1516", i: 2, s: 2, ss: 47 },
    1518: { id: "1518", i: 5, s: 30, ss: 73 },
    1520: { id: "1520", i: 6, s: 27, ss: 50 },
    1521: { id: "1521", i: 5, s: 5, ss: 94 },
    1522: { id: "1522", i: 7, s: 7, ss: 22 },
    1523: { id: "1523", i: 7, s: 26, ss: 78 },
    1525: { id: "1525", i: 6, s: 12, ss: 15 },
    1526: { id: "1526", i: 5, s: 30, ss: 73 },
    1527: { id: "1527", i: 10, s: 11, ss: 57 },
    1528: { id: "1528", i: 2, s: 2, ss: 5 },
    1529: { id: "1529", i: 10, s: 25, ss: 90 },
    1530: { id: "1530", i: 5, s: 5, ss: 7 },
    1532: { id: "1532", i: 6, s: 27, ss: 50 },
    1533: { id: "1533", i: 9, s: 10, ss: 87 },
    1536: { id: "1536", i: 10, s: 11, ss: 20 },
    1538: { id: "1538", i: 2, s: 2, ss: 47 },
    1539: { id: "1539", i: 10, s: 11, ss: 57 },
    1540: { id: "1540", i: 10, s: 17, ss: 25 },
    1542: { id: "1542", i: 1, s: 1, ss: 71 },
    1543: { id: "1543", i: 3, s: 20, ss: 38 },
    1545: { id: "1545", i: 6, s: 22, ss: 89 },
    1546: { id: "1546", i: 2, s: 18, ss: 56 },
    1547: { id: "1547", i: 2, s: 18, ss: 56 },
    1548: { id: "1548", i: 5, s: 5, ss: 94 },
    1549: { id: "1549", i: 10, s: 25, ss: 53 },
    1551: { id: "1551", i: 3, s: 3, ss: 4 },
    1552: { id: "1552", i: 2, s: 18, ss: 56 },
    1553: { id: "1553", i: 8, s: 9, ss: 11 },
    1555: { id: "1555", i: 11, s: 24, ss: 40 },
    1556: { id: "1556", i: 2, s: 18, ss: 56 },
    1557: { id: "1557", i: 2, s: 18, ss: 69 },
    1558: { id: "1558", i: 5, s: 5, ss: 7 },
    1559: { id: "1559", i: 2, s: 18, ss: 69 },
    1560: { id: "1560", i: 2, s: 2, ss: 3 },
    1561: { id: "1561", i: 8, s: 21, ss: 31 },
    1563: { id: "1563", i: 3, s: 20, ss: 38 },
    1565: { id: "1565", i: 6, s: 12, ss: 15 },
    1566: { id: "1566", i: 6, s: 8, ss: 86 },
    1568: { id: "1568", i: 2, s: 18, ss: 56 },
    1569: { id: "1569", i: 6, s: 12, ss: 15 },
    1570: { id: "1570", i: 2, s: 2, ss: 3 },
    1571: { id: "1571", i: 6, s: 16, ss: 24 },
    1572: { id: "1572", i: 3, s: 20, ss: 38 },
    1573: { id: "1573", i: 11, s: 19, ss: 28 },
    1575: { id: "1575", i: 6, s: 13, ss: 83 },
    1576: { id: "1576", i: 10, s: 25, ss: 46 },
    1577: { id: "1577", i: 3, s: 20, ss: 38 },
    1578: { id: "1578", i: 3, s: 3, ss: 4 },
    1579: { id: "1579", i: 9, s: 10, ss: 13 },
    1580: { id: "1580", i: 8, s: 21, ss: 41 },
    1581: { id: "1581", i: 2, s: 18, ss: 56 },
    1582: { id: "1582", i: 2, s: 18, ss: 56 },
    1583: { id: "1583", i: 9, s: 10, ss: 27 },
    1585: { id: "1585", i: 6, s: 16, ss: 62 },
    1586: { id: "1586", i: 6, s: 12, ss: 35 },
    1587: { id: "1587", i: 9, s: 10, ss: 13 },
    1588: { id: "1588", i: 7, s: 7, ss: 9 },
    1589: { id: "1589", i: 2, s: 2, ss: 5 },
    1591: { id: "1591", i: 2, s: 18, ss: 56 },
    1592: { id: "1592", i: 2, s: 18, ss: 26 },
    1593: { id: "1593", i: 6, s: 12, ss: 15 },
    1596: { id: "1596", i: 10, s: 11, ss: 45 },
    1597: { id: "1597", i: 10, s: 11, ss: 57 },
    1598: { id: "1598", i: 6, s: 12, ss: 15 },
    1599: { id: "1599", i: 2, s: 18, ss: 56 },
    1600: { id: "1600", i: 1, s: 1, ss: 2 },
    1601: { id: "1601", i: 3, s: 20, ss: 38 },
    1606: { id: "1606", i: 3, s: 20, ss: 38 },
    1608: { id: "1608", i: 1, s: 1, ss: 1 },
    1609: { id: "1609", i: 2, s: 18, ss: 56 },
    1610: { id: "1610", i: 9, s: 15, ss: 74 },
    1611: { id: "1611", i: 10, s: 11, ss: 20 },
    1612: { id: "1612", i: 5, s: 30, ss: 92 },
    1613: { id: "1613", i: 7, s: 7, ss: 22 },
    1615: { id: "1615", i: 2, s: 18, ss: 56 },
    1616: { id: "1616", i: 6, s: 6, ss: 77 },
    1617: { id: "1617", i: 7, s: 26, ss: 48 },
    1618: { id: "1618", i: 2, s: 18, ss: 69 },
    1620: { id: "1620", i: 6, s: 8, ss: 33 },
    1621: { id: "1621", i: 11, s: 24, ss: 40 },
    1622: { id: "1622", i: 2, s: 2, ss: 3 },
    1623: { id: "1623", i: 11, s: 24, ss: 64 },
    1626: { id: "1626", i: 10, s: 17, ss: 25 },
    1627: { id: "1627", i: 2, s: 18, ss: 56 },
    1628: { id: "1628", i: 2, s: 2, ss: 3 },
    1629: { id: "1629", i: 10, s: 17, ss: 25 },
    1630: { id: "1630", i: 2, s: 18, ss: 56 },
    1631: { id: "1631", i: 10, s: 17, ss: 25 },
    1632: { id: "1632", i: 6, s: 8, ss: 23 },
    1633: { id: "1633", i: 2, s: 18, ss: 56 },
    1635: { id: "1635", i: 1, s: 1, ss: 2 },
    1636: { id: "1636", i: 8, s: 9, ss: 81 },
    1637: { id: "1637", i: 2, s: 18, ss: 56 },
    1638: { id: "1638", i: 2, s: 2, ss: 3 },
    1639: { id: "1639", i: 10, s: 11, ss: 20 },
    1640: { id: "1640", i: 6, s: 6, ss: 44 },
    1642: { id: "1642", i: 2, s: 18, ss: 56 },
    1643: { id: "1643", i: 5, s: 5, ss: 79 },
    1645: { id: "1645", i: 10, s: 11, ss: 20 },
    1647: { id: "1647", i: 2, s: 18, ss: 56 },
    1649: { id: "1649", i: 2, s: 18, ss: 69 },
    1650: { id: "1650", i: 6, s: 12, ss: 35 },
    1651: { id: "1651", i: 10, s: 11, ss: 20 },
    1652: { id: "1652", i: 5, s: 5, ss: 7 },
    1653: { id: "1653", i: 2, s: 18, ss: 26 },
    1655: { id: "1655", i: 6, s: 8, ss: 12 },
    1656: { id: "1656", i: 10, s: 11, ss: 20 },
    1657: { id: "1657", i: 6, s: 27, ss: 50 },
    1658: { id: "1658", i: 3, s: 3, ss: 4 },
    1660: { id: "1660", i: 10, s: 11, ss: 17 },
    1661: { id: "1661", i: 6, s: 8, ss: 86 },
    1662: { id: "1662", i: 2, s: 18, ss: 69 },
    1663: { id: "1663", i: 2, s: 2, ss: 3 },
    1665: { id: "1665", i: 7, s: 7, ss: 22 },
    1666: { id: "1666", i: 5, s: 5, ss: 79 },
    1667: { id: "1667", i: 2, s: 18, ss: 56 },
    1668: { id: "1668", i: 2, s: 2, ss: 3 },
    1669: { id: "1669", i: 3, s: 20, ss: 38 },
    1671: { id: "1671", i: 1, s: 1, ss: 1 },
    1672: { id: "1672", i: 5, s: 5, ss: 94 },
    1673: { id: "1673", i: 10, s: 11, ss: 20 },
    1675: { id: "1675", i: 7, s: 7, ss: 9 },
    1676: { id: "1676", i: 9, s: 10, ss: 27 },
    1678: { id: "1678", i: 6, s: 13, ss: 83 },
    1679: { id: "1679", i: 7, s: 7, ss: 9 },
    1680: { id: "1680", i: 6, s: 8, ss: 12 },
    1681: { id: "1681", i: 5, s: 5, ss: 7 },
    1682: { id: "1682", i: 6, s: 27, ss: 50 },
    1683: { id: "1683", i: 2, s: 18, ss: 56 },
    1685: { id: "1685", i: 10, s: 11, ss: 57 },
    1686: { id: "1686", i: 7, s: 7, ss: 22 },
    1689: { id: "1689", i: 10, s: 17, ss: 25 },
    1690: { id: "1690", i: 2, s: 18, ss: 56 },
    1691: { id: "1691", i: 6, s: 13, ss: 68 },
    1692: { id: "1692", i: 6, s: 13, ss: 68 },
    1693: { id: "1693", i: 2, s: 18, ss: 56 },
    1695: { id: "1695", i: 9, s: 10, ss: 13 },
    1696: { id: "1696", i: 5, s: 30, ss: 92 },
    1697: { id: "1697", i: 3, s: 20, ss: 32 },
    1699: { id: "1699", i: 9, s: 15, ss: 74 },
    1701: { id: "1701", i: 6, s: 8, ss: 33 },
    1702: { id: "1702", i: 8, s: 21, ss: 76 },
    1703: { id: "1703", i: 6, s: 8, ss: 23 },
    1705: { id: "1705", i: 9, s: 10, ss: 27 },
    1706: { id: "1706", i: 2, s: 18, ss: 69 },
    1707: { id: "1707", i: 2, s: 18, ss: 69 },
    1708: { id: "1708", i: 7, s: 7, ss: 22 },
    1709: { id: "1709", i: 3, s: 20, ss: 30 },
    1710: { id: "1710", i: 10, s: 11, ss: 20 },
    1711: { id: "1711", i: 6, s: 22, ss: 89 },
    1712: { id: "1712", i: 8, s: 29, ss: 72 },
    1713: { id: "1713", i: 1, s: 1, ss: 1 },
    1715: { id: "1715", i: 6, s: 13, ss: 83 },
    1716: { id: "1716", i: 6, s: 6, ss: 44 },
    1717: { id: "1717", i: 9, s: 10, ss: 87 },
    1718: { id: "1718", i: 2, s: 18, ss: 69 },
    1719: { id: "1719", i: 10, s: 17, ss: 82 },
    1720: { id: "1720", i: 7, s: 26, ss: 48 },
    1721: { id: "1721", i: 8, s: 9, ss: 11 },
    1722: { id: "1722", i: 2, s: 18, ss: 56 },
    1723: { id: "1723", i: 6, s: 22, ss: 59 },
    1725: { id: "1725", i: 10, s: 11, ss: 20 },
    1726: { id: "1726", i: 5, s: 30, ss: 92 },
    1727: { id: "1727", i: 2, s: 18, ss: 56 },
    1728: { id: "1728", i: 6, s: 22, ss: 54 },
    1729: { id: "1729", i: 7, s: 26, ss: 48 },
    1730: { id: "1730", i: 2, s: 2, ss: 5 },
    1731: { id: "1731", i: 6, s: 27, ss: 49 },
    1732: { id: "1732", i: 10, s: 25, ss: 53 },
    1733: { id: "1733", i: 11, s: 19, ss: 28 },
    1735: { id: "1735", i: 2, s: 18, ss: 56 },
    1736: { id: "1736", i: 7, s: 7, ss: 52 },
    1737: { id: "1737", i: 10, s: 25, ss: 90 },
    1738: { id: "1738", i: 11, s: 19, ss: 28 },
    1739: { id: "1739", i: 7, s: 7, ss: 52 },
    1740: { id: "1740", i: 6, s: 6, ss: 77 },
    1741: { id: "1741", i: 2, s: 18, ss: 56 },
    1742: { id: "1742", i: 2, s: 18, ss: 56 },
    1743: { id: "1743", i: 10, s: 11, ss: 20 },
    1745: { id: "1745", i: 7, s: 7, ss: 9 },
    1746: { id: "1746", i: 2, s: 18, ss: 56 },
    1747: { id: "1747", i: 6, s: 13, ss: 19 },
    1748: { id: "1748", i: 10, s: 25, ss: 53 },
    1749: { id: "1749", i: 6, s: 27, ss: 50 },
    1750: { id: "1750", i: 10, s: 11, ss: 20 },
    1751: { id: "1751", i: 2, s: 18, ss: 56 },
    1752: { id: "1752", i: 6, s: 12, ss: 15 },
    1753: { id: "1753", i: 6, s: 6, ss: 44 },
    1755: { id: "1755", i: 2, s: 2, ss: 47 },
    1756: { id: "1756", i: 6, s: 12, ss: 15 },
    1757: { id: "1757", i: 2, s: 18, ss: 69 },
    1758: { id: "1758", i: 6, s: 12, ss: 15 },
    1759: { id: "1759", i: 1, s: 1, ss: 2 },
    1760: { id: "1760", i: 6, s: 16, ss: 24 },
    1761: { id: "1761", i: 6, s: 6, ss: 44 },
    1762: { id: "1762", i: 6, s: 6, ss: 44 },
    1763: { id: "1763", i: 5, s: 5, ss: 7 },
    1765: { id: "1765", i: 6, s: 12, ss: 15 },
    1766: { id: "1766", i: 10, s: 11, ss: 45 },
    1767: { id: "1767", i: 9, s: 10, ss: 27 },
    1769: { id: "1769", i: 6, s: 12, ss: 15 },
    1771: { id: "1771", i: 6, s: 22, ss: 54 },
    1772: { id: "1772", i: 8, s: 9, ss: 60 },
    1773: { id: "1773", i: 6, s: 12, ss: 15 },
    1775: { id: "1775", i: 6, s: 12, ss: 15 },
    1776: { id: "1776", i: 3, s: 20, ss: 30 },
    1777: { id: "1777", i: 2, s: 2, ss: 3 },
    1778: { id: "1778", i: 2, s: 2, ss: 47 },
    1780: { id: "1780", i: 2, s: 18, ss: 56 },
    1781: { id: "1781", i: 6, s: 13, ss: 83 },
    1782: { id: "1782", i: 7, s: 7, ss: 22 },
    1783: { id: "1783", i: 2, s: 18, ss: 56 },
    1785: { id: "1785", i: 10, s: 25, ss: 46 },
    1786: { id: "1786", i: 10, s: 11, ss: 17 },
    1787: { id: "1787", i: 8, s: 29, ss: 72 },
    1788: { id: "1788", i: 3, s: 20, ss: 30 },
    1789: { id: "1789", i: 5, s: 30, ss: 92 },
    1790: { id: "1790", i: 1, s: 1, ss: 71 },
    1792: { id: "1792", i: 6, s: 13, ss: 16 },
    1793: { id: "1793", i: 2, s: 18, ss: 56 },
    1795: { id: "1795", i: 6, s: 27, ss: 55 },
    1796: { id: "1796", i: 2, s: 18, ss: 56 },
    1797: { id: "1797", i: 6, s: 12, ss: 15 },
    1798: { id: "1798", i: 1, s: 1, ss: 39 },
    1799: { id: "1799", i: 1, s: 1, ss: 39 },
    1800: { id: "1800", i: 2, s: 18, ss: 69 },
    1801: { id: "1801", i: 5, s: 5, ss: 7 },
    1802: { id: "1802", i: 2, s: 18, ss: 56 },
    1803: { id: "1803", i: 6, s: 8, ss: 86 },
    1806: { id: "1806", i: 7, s: 7, ss: 52 },
    1808: { id: "1808", i: 7, s: 7, ss: 22 },
    1809: { id: "1809", i: 6, s: 16, ss: 24 },
    1810: { id: "1810", i: 7, s: 26, ss: 48 },
    1811: { id: "1811", i: 1, s: 1, ss: 39 },
    1812: { id: "1812", i: 8, s: 21, ss: 93 },
    1813: { id: "1813", i: 2, s: 2, ss: 3 },
    1815: { id: "1815", i: 6, s: 22, ss: 36 },
    1816: { id: "1816", i: 1, s: 1, ss: 1 },
    1817: { id: "1817", i: 6, s: 22, ss: 51 },
    1818: { id: "1818", i: 8, s: 29, ss: 72 },
    1820: { id: "1820", i: 10, s: 17, ss: 25 },
    1821: { id: "1821", i: 3, s: 20, ss: 32 },
    1822: { id: "1822", i: 8, s: 21, ss: 41 },
    1823: { id: "1823", i: 10, s: 25, ss: 46 },
    1825: { id: "1825", i: 6, s: 27, ss: 50 },
    1826: { id: "1826", i: 2, s: 18, ss: 56 },
    1827: { id: "1827", i: 5, s: 30, ss: 73 },
    1829: { id: "1829", i: 2, s: 18, ss: 69 },
    1830: { id: "1830", i: 5, s: 30, ss: 73 },
    1831: { id: "1831", i: 6, s: 6, ss: 44 },
    1832: { id: "1832", i: 6, s: 8, ss: 10 },
    1833: { id: "1833", i: 5, s: 30, ss: 73 },
    1835: { id: "1835", i: 3, s: 20, ss: 32 },
    1836: { id: "1836", i: 6, s: 27, ss: 65 },
    1837: { id: "1837", i: 9, s: 10, ss: 27 },
    1838: { id: "1838", i: 2, s: 2, ss: 3 },
    1839: { id: "1839", i: 10, s: 11, ss: 45 },
    1841: { id: "1841", i: 10, s: 17, ss: 25 },
    1842: { id: "1842", i: 6, s: 27, ss: 49 },
    1843: { id: "1843", i: 6, s: 8, ss: 23 },
    1845: { id: "1845", i: 1, s: 1, ss: 39 },
    1846: { id: "1846", i: 5, s: 30, ss: 73 },
    1847: { id: "1847", i: 2, s: 18, ss: 26 },
    1848: { id: "1848", i: 6, s: 8, ss: 75 },
    1849: { id: "1849", i: 6, s: 6, ss: 44 },
    1850: { id: "1850", i: 2, s: 18, ss: 56 },
    1851: { id: "1851", i: 6, s: 12, ss: 15 },
    1853: { id: "1853", i: 1, s: 1, ss: 39 },
    1854: { id: "1854", i: 9, s: 15, ss: 34 },
    1855: { id: "1855", i: 2, s: 18, ss: 56 },
    1856: { id: "1856", i: 6, s: 27, ss: 70 },
    1857: { id: "1857", i: 1, s: 1, ss: 71 },
    1858: { id: "1858", i: 5, s: 30, ss: 92 },
    1859: { id: "1859", i: 6, s: 6, ss: 91 },
    1860: { id: "1860", i: 6, s: 6, ss: 44 },
    1861: { id: "1861", i: 8, s: 21, ss: 31 },
    1862: { id: "1862", i: 2, s: 2, ss: 3 },
    1863: { id: "1863", i: 8, s: 21, ss: 31 },
    1865: { id: "1865", i: 2, s: 18, ss: 69 },
    1866: { id: "1866", i: 8, s: 21, ss: 76 },
    1867: { id: "1867", i: 2, s: 18, ss: 56 },
    1868: { id: "1868", i: 6, s: 13, ss: 83 },
    1869: { id: "1869", i: 6, s: 8, ss: 23 },
    1870: { id: "1870", i: 2, s: 18, ss: 56 },
    1871: { id: "1871", i: 6, s: 12, ss: 15 },
    1872: { id: "1872", i: 6, s: 22, ss: 54 },
    1873: { id: "1873", i: 5, s: 5, ss: 94 },
    1875: { id: "1875", i: 5, s: 5, ss: 94 },
    1876: { id: "1876", i: 9, s: 10, ss: 61 },
    1877: { id: "1877", i: 5, s: 5, ss: 94 },
    1878: { id: "1878", i: 11, s: 19, ss: 28 },
    1881: { id: "1881", i: "", s: "", ss: 85 },
    1882: { id: "1882", i: 10, s: 11, ss: 20 },
    1883: { id: "1883", i: 4, s: 4, ss: 6 },
    1884: { id: "1884", i: 10, s: 17, ss: 25 },
    1885: { id: "1885", i: 8, s: 21, ss: 41 },
    1888: { id: "1888", i: 10, s: 11, ss: 20 },
    1889: { id: "1889", i: 5, s: 5, ss: 7 },
    1890: { id: "1890", i: 6, s: 12, ss: 15 },
    1891: { id: "1891", i: 8, s: 9, ss: 60 },
    1894: { id: "1894", i: 2, s: 18, ss: 26 },
    1895: { id: "1895", i: 2, s: 2, ss: 47 },
    1896: { id: "1896", i: 6, s: 6, ss: 77 },
    1897: { id: "1897", i: 2, s: 18, ss: 56 },
    1898: { id: "1898", i: 11, s: 19, ss: 28 },
    1899: { id: "1899", i: 10, s: 11, ss: 45 },
    1900: { id: "1900", i: 7, s: 7, ss: 22 },
    1901: { id: "1901", i: 6, s: 8, ss: 33 },
    1902: { id: "1902", i: 2, s: 2, ss: 3 },
    1903: { id: "1903", i: 10, s: 25, ss: 53 },
    1905: { id: "1905", i: 3, s: 20, ss: 38 },
    1906: { id: "1906", i: 6, s: 27, ss: 50 },
    1907: { id: "1907", i: 8, s: 21, ss: 31 },
    1908: { id: "1908", i: 2, s: 2, ss: 3 },
    1909: { id: "1909", i: 7, s: 7, ss: 9 },
    1910: { id: "1910", i: 6, s: 27, ss: 49 },
    1911: { id: "1911", i: 3, s: 20, ss: 32 },
    1912: { id: "1912", i: 7, s: 23, ss: 37 },
    1913: { id: "1913", i: 6, s: 27, ss: 49 },
    1915: { id: "1915", i: 3, s: 20, ss: 38 },
    1916: { id: "1916", i: 3, s: 3, ss: 4 },
    1917: { id: "1917", i: 6, s: 6, ss: 44 },
    1918: { id: "1918", i: 2, s: 2, ss: 3 },
    1919: { id: "1919", i: 10, s: 25, ss: 53 },
    1920: { id: "1920", i: 2, s: 18, ss: 56 },
    1921: { id: "1921", i: 11, s: 24, ss: 64 },
    1922: { id: "1922", i: 2, s: 2, ss: 47 },
    1925: { id: "1925", i: 6, s: 13, ss: 83 },
    1927: { id: "1927", i: 9, s: 10, ss: 27 },
    1928: { id: "1928", i: 6, s: 8, ss: 12 },
    1929: { id: "1929", i: 6, s: 22, ss: 36 },
    1930: { id: "1930", i: 10, s: 11, ss: 20 },
    1931: { id: "1931", i: 5, s: 30, ss: 92 },
    1932: { id: "1932", i: 8, s: 21, ss: 31 },
    1933: { id: "1933", i: 7, s: 7, ss: 9 },
    1935: { id: "1935", i: 6, s: 12, ss: 15 },
    1936: { id: "1936", i: 9, s: 15, ss: 21 },
    1937: { id: "1937", i: 2, s: 18, ss: 26 },
    1938: { id: "1938", i: 11, s: 24, ss: 64 },
    1939: { id: "1939", i: 6, s: 12, ss: 35 },
    1940: { id: "1940", i: 11, s: 24, ss: 40 },
    1941: { id: "1941", i: 2, s: 2, ss: 47 },
    1942: { id: "1942", i: 6, s: 22, ss: 36 },
    1943: { id: "1943", i: 2, s: 18, ss: 56 },
    1945: { id: "1945", i: 3, s: 20, ss: 63 },
    1948: { id: "1948", i: 6, s: 6, ss: 44 },
    1949: { id: "1949", i: 7, s: 7, ss: 22 },
    1950: { id: "1950", i: 8, s: 21, ss: 31 },
    1951: { id: "1951", i: 5, s: 5, ss: 94 },
    1952: { id: "1952", i: 5, s: 5, ss: 94 },
    1953: { id: "1953", i: 2, s: 18, ss: 56 },
    1955: { id: "1955", i: 6, s: 12, ss: 35 },
    1957: { id: "1957", i: 6, s: 27, ss: 50 },
    1958: { id: "1958", i: 6, s: 16, ss: 62 },
    1959: { id: "1959", i: 6, s: 22, ss: 54 },
    1960: { id: "1960", i: 2, s: 18, ss: 69 },
    1961: { id: "1961", i: 7, s: 7, ss: 9 },
    1962: { id: "1962", i: 6, s: 27, ss: 49 },
    1963: { id: "1963", i: 3, s: 3, ss: 4 },
    1965: { id: "1965", i: 2, s: 2, ss: 47 },
    1966: { id: "1966", i: 2, s: 2, ss: 3 },
    1967: { id: "1967", i: 10, s: 11, ss: 20 },
    1968: { id: "1968", i: 6, s: 27, ss: 55 },
    1969: { id: "1969", i: 6, s: 12, ss: 15 },
    1970: { id: "1970", i: 6, s: 6, ss: 77 },
    1971: { id: "1971", i: 2, s: 2, ss: 47 },
    1972: { id: "1972", i: 2, s: 2, ss: 5 },
    1975: { id: "1975", i: 10, s: 17, ss: 25 },
    1977: { id: "1977", i: 2, s: 18, ss: 56 },
    1978: { id: "1978", i: 6, s: 8, ss: 23 },
    1979: { id: "1979", i: 10, s: 11, ss: 43 },
    1980: { id: "1980", i: 7, s: 7, ss: 9 },
    1981: { id: "1981", i: 6, s: 6, ss: 77 },
    1982: { id: "1982", i: 6, s: 27, ss: 50 },
    1983: { id: "1983", i: 3, s: 3, ss: 4 },
    1985: { id: "1985", i: 7, s: 7, ss: 22 },
    1986: { id: "1986", i: 8, s: 21, ss: 31 },
    1987: { id: "1987", i: 2, s: 18, ss: 69 },
    1988: { id: "1988", i: 3, s: 3, ss: 4 },
    1989: { id: "1989", i: 6, s: 12, ss: 35 },
    1991: { id: "1991", i: 10, s: 11, ss: 20 },
    1992: { id: "1992", i: 6, s: 8, ss: 10 },
    1993: { id: "1993", i: 6, s: 6, ss: 44 },
    1995: { id: "1995", i: 2, s: 2, ss: 47 },
    1996: { id: "1996", i: 2, s: 2, ss: 3 },
    1997: { id: "1997", i: 2, s: 2, ss: 5 },
    1999: { id: "1999", i: 6, s: 13, ss: 83 },
    2000: { id: "2000", i: 7, s: 26, ss: 48 },
    2001: { id: "2001", i: 6, s: 12, ss: 15 },
    2002: { id: "2002", i: 8, s: 21, ss: 93 },
    2003: { id: "2003", i: 3, s: 20, ss: 38 },
    2005: { id: "2005", i: 5, s: 5, ss: 7 },
    2006: { id: "2006", i: 6, s: 8, ss: 10 },
    2007: { id: "2007", i: 2, s: 2, ss: 3 },
    2008: { id: "2008", i: 6, s: 6, ss: 91 },
    2009: { id: "2009", i: 2, s: 18, ss: 26 },
    2011: { id: "2011", i: 6, s: 27, ss: 49 },
    2012: { id: "2012", i: 11, s: 24, ss: 40 },
    2013: { id: "2013", i: 6, s: 6, ss: 44 },
    2014: { id: "2014", i: 6, s: 13, ss: 68 },
    2015: { id: "2015", i: 6, s: 16, ss: 62 },
    2016: { id: "2016", i: 3, s: 3, ss: 4 },
    2017: { id: "2017", i: 2, s: 18, ss: 56 },
    2018: { id: "2018", i: 10, s: 11, ss: 43 },
    2019: { id: "2019", i: 2, s: 2, ss: 3 },
    2020: { id: "2020", i: 6, s: 27, ss: 50 },
    2022: { id: "2022", i: 7, s: 7, ss: 9 },
    2023: { id: "2023", i: 6, s: 13, ss: 83 },
    2025: { id: "2025", i: 6, s: 16, ss: 24 },
    2028: { id: "2028", i: 7, s: 26, ss: 78 },
    2030: { id: "2030", i: 6, s: 27, ss: 50 },
    2031: { id: "2031", i: 5, s: 5, ss: 7 },
    2033: { id: "2033", i: 6, s: 27, ss: 70 },
    2038: { id: "2038", i: 7, s: 26, ss: 48 },
    2039: { id: "2039", i: 10, s: 11, ss: 45 },
    2048: { id: "2048", i: 2, s: 2, ss: 80 },
    2051: { id: "2051", i: 3, s: 20, ss: 38 },
    2057: { id: "2057", i: 10, s: 25, ss: 90 },
    2060: { id: "2060", i: 2, s: 18, ss: 26 },
    2066: { id: "2066", i: 3, s: 3, ss: 4 },
    2068: { id: "2068", i: 2, s: 18, ss: 69 },
    2078: { id: "2078", i: 8, s: 9, ss: 42 },
    2080: { id: "2080", i: 2, s: 2, ss: 47 },
    2083: { id: "2083", i: 6, s: 13, ss: 83 },
    2086: { id: "2086", i: 7, s: 26, ss: 78 },
    2088: { id: "2088", i: 2, s: 2, ss: 3 },
    2096: { id: "2096", i: 5, s: 5, ss: 7 },
    2098: { id: "2098", i: 10, s: 17, ss: 82 },
    2099: { id: "2099", i: 8, s: 29, ss: 72 },
    2100: { id: "2100", i: 7, s: 7, ss: 9 },
    2101: { id: "2101", i: 7, s: 7, ss: 52 },
    2102: { id: "2102", i: 10, s: 11, ss: 17 },
    2103: { id: "2103", i: 2, s: 2, ss: 3 },
    2107: { id: "2107", i: 2, s: 2, ss: 47 },
    2108: { id: "2108", i: 6, s: 8, ss: 23 },
    2110: { id: "2110", i: 2, s: 18, ss: 69 },
    2111: { id: "2111", i: 6, s: 27, ss: 55 },
    2112: { id: "2112", i: 8, s: 9, ss: 11 },
    2113: { id: "2113", i: 2, s: 18, ss: 56 },
    2115: { id: "2115", i: 2, s: 18, ss: 56 },
    2116: { id: "2116", i: 8, s: 21, ss: 31 },
    2117: { id: "2117", i: 2, s: 2, ss: 3 },
    2118: { id: "2118", i: 2, s: 2, ss: 3 },
    2119: { id: "2119", i: 9, s: 10, ss: 66 },
    2120: { id: "2120", i: 5, s: 30, ss: 73 },
    2121: { id: "2121", i: 7, s: 7, ss: 9 },
    2122: { id: "2122", i: 6, s: 22, ss: 36 },
    2123: { id: "2123", i: 6, s: 27, ss: 55 },
    2125: { id: "2125", i: 6, s: 6, ss: 77 },
    2126: { id: "2126", i: 5, s: 5, ss: 94 },
    2127: { id: "2127", i: 6, s: 13, ss: 83 },
    2128: { id: "2128", i: 2, s: 18, ss: 26 },
    2129: { id: "2129", i: 10, s: 25, ss: 90 },
    2130: { id: "2130", i: 10, s: 25, ss: 90 },
    2131: { id: "2131", i: 6, s: 6, ss: 44 },
    2132: { id: "2132", i: 2, s: 18, ss: 69 },
    2133: { id: "2133", i: 8, s: 9, ss: 60 },
    2135: { id: "2135", i: 5, s: 30, ss: 73 },
    2136: { id: "2136", i: 6, s: 22, ss: 59 },
    2137: { id: "2137", i: 5, s: 5, ss: 94 },
    2138: { id: "2138", i: 5, s: 30, ss: 73 },
    2139: { id: "2139", i: 3, s: 3, ss: 4 },
    2142: { id: "2142", i: 5, s: 5, ss: 94 },
    2146: { id: "2146", i: 2, s: 2, ss: 47 },
    2148: { id: "2148", i: 6, s: 13, ss: 68 },
    2150: { id: "2150", i: 6, s: 8, ss: 23 },
    2153: { id: "2153", i: 10, s: 11, ss: 17 },
    2155: { id: "2155", i: 10, s: 11, ss: 17 },
    2156: { id: "2156", i: 2, s: 2, ss: 47 },
    2157: { id: "2157", i: 5, s: 5, ss: 94 },
    2158: { id: "2158", i: 5, s: 30, ss: 73 },
    2159: { id: "2159", i: 5, s: 30, ss: 73 },
    2160: { id: "2160", i: 5, s: 30, ss: 92 },
    2161: { id: "2161", i: 5, s: 5, ss: 79 },
    2162: { id: "2162", i: 5, s: 5, ss: 94 },
    2163: { id: "2163", i: 2, s: 18, ss: 26 },
    2165: { id: "2165", i: 2, s: 2, ss: 47 },
    2166: { id: "2166", i: 7, s: 23, ss: 37 },
    2168: { id: "2168", i: 2, s: 2, ss: 47 },
    2169: { id: "2169", i: 10, s: 25, ss: 46 },
    2170: { id: "2170", i: 5, s: 5, ss: 94 },
    2171: { id: "2171", i: 5, s: 5, ss: 94 },
    2175: { id: "2175", i: 6, s: 12, ss: 15 },
    2176: { id: "2176", i: 7, s: 7, ss: 22 },
    2177: { id: "2177", i: 7, s: 7, ss: 52 },
    2178: { id: "2178", i: 11, s: 24, ss: 64 },
    2180: { id: "2180", i: 6, s: 12, ss: 35 },
    2181: { id: "2181", i: 5, s: 5, ss: 94 },
    2182: { id: "2182", i: 9, s: 10, ss: 27 },
    2183: { id: "2183", i: 2, s: 2, ss: 3 },
    2185: { id: "2185", i: 5, s: 30, ss: 92 },
    2186: { id: "2186", i: 5, s: 5, ss: 7 },
    2187: { id: "2187", i: 2, s: 18, ss: 26 },
    2188: { id: "2188", i: 10, s: 11, ss: 57 },
    2189: { id: "2189", i: 6, s: 12, ss: 35 },
    2190: { id: "2190", i: 5, s: 30, ss: 92 },
    2191: { id: "2191", i: "", s: "", ss: 85 },
    2192: { id: "2192", i: 5, s: 30, ss: 73 },
    2193: { id: "2193", i: 2, s: 18, ss: 69 },
    2195: { id: "2195", i: 2, s: 18, ss: 56 },
    2196: { id: "2196", i: 5, s: 5, ss: 7 },
    2197: { id: "2197", i: 5, s: 5, ss: 94 },
    2198: { id: "2198", i: 8, s: 21, ss: 31 },
    2199: { id: "2199", i: 6, s: 27, ss: 50 },
    2202: { id: "2202", i: 2, s: 2, ss: 3 },
    2203: { id: "2203", i: 7, s: 23, ss: 37 },
    2205: { id: "2205", i: 2, s: 2, ss: 47 },
    2207: { id: "2207", i: 2, s: 2, ss: 47 },
    2208: { id: "2208", i: 10, s: 11, ss: 57 },
    2209: { id: "2209", i: 7, s: 7, ss: 52 },
    2210: { id: "2210", i: 2, s: 2, ss: 47 },
    2211: { id: "2211", i: 5, s: 5, ss: 7 },
    2212: { id: "2212", i: 8, s: 9, ss: 60 },
    2213: { id: "2213", i: 6, s: 22, ss: 59 },
    2215: { id: "2215", i: 2, s: 2, ss: 47 },
    2216: { id: "2216", i: 5, s: 30, ss: 92 },
    2217: { id: "2217", i: 6, s: 8, ss: 23 },
    2218: { id: "2218", i: 9, s: 10, ss: 66 },
    2219: { id: "2219", i: 5, s: 30, ss: 73 },
    2221: { id: "2221", i: 2, s: 18, ss: 69 },
    2222: { id: "2222", i: 6, s: 13, ss: 83 },
    2223: { id: "2223", i: 6, s: 13, ss: 83 },
    2225: { id: "2225", i: 6, s: 12, ss: 35 },
    2226: { id: "2226", i: 9, s: 10, ss: 13 },
    2227: { id: "2227", i: 2, s: 18, ss: 56 },
    2228: { id: "2228", i: 6, s: 27, ss: 55 },
    2230: { id: "2230", i: 6, s: 6, ss: 77 },
    2231: { id: "2231", i: 2, s: 2, ss: 3 },
    2232: { id: "2232", i: 6, s: 27, ss: 50 },
    2233: { id: "2233", i: 2, s: 18, ss: 26 },
    2235: { id: "2235", i: 5, s: 30, ss: 92 },
    2236: { id: "2236", i: 11, s: 24, ss: 64 },
    2238: { id: "2238", i: 6, s: 16, ss: 62 },
    2239: { id: "2239", i: 7, s: 26, ss: 48 },
    2250: { id: "2250", i: 7, s: 7, ss: 52 },
    2251: { id: "2251", i: 5, s: 30, ss: 92 },
    2252: { id: "2252", i: 5, s: 30, ss: 92 },
    2255: { id: "2255", i: 6, s: 8, ss: 86 },
    2256: { id: "2256", i: 5, s: 5, ss: 94 },
    2257: { id: "2257", i: 5, s: 5, ss: 94 },
    2258: { id: "2258", i: 2, s: 18, ss: 69 },
    2260: { id: "2260", i: 10, s: 11, ss: 20 },
    2262: { id: "2262", i: 2, s: 18, ss: 56 },
    2263: { id: "2263", i: 3, s: 20, ss: 30 },
    2265: { id: "2265", i: 10, s: 11, ss: 57 },
    2266: { id: "2266", i: 2, s: 18, ss: 56 },
    2269: { id: "2269", i: 5, s: 5, ss: 94 },
    2270: { id: "2270", i: 2, s: 2, ss: 47 },
    2273: { id: "2273", i: 5, s: 30, ss: 73 },
    2276: { id: "2276", i: 10, s: 11, ss: 20 },
    2278: { id: "2278", i: 2, s: 2, ss: 3 },
    2279: { id: "2279", i: 5, s: 30, ss: 73 },
    2280: { id: "2280", i: 7, s: 7, ss: 52 },
    2281: { id: "2281", i: 1, s: 1, ss: 71 },
    2282: { id: "2282", i: 6, s: 8, ss: 12 },
    2283: { id: "2283", i: 10, s: 11, ss: 20 },
    2285: { id: "2285", i: 6, s: 13, ss: 68 },
    2286: { id: "2286", i: 2, s: 2, ss: 3 },
    2288: { id: "2288", i: 2, s: 2, ss: 3 },
    2289: { id: "2289", i: 5, s: 5, ss: 7 },
    2292: { id: "2292", i: 2, s: 2, ss: 5 },
    2293: { id: "2293", i: 5, s: 30, ss: 73 },
    2295: { id: "2295", i: 2, s: 18, ss: 69 },
    2296: { id: "2296", i: 2, s: 18, ss: 56 },
    2298: { id: "2298", i: 6, s: 27, ss: 50 },
    2299: { id: "2299", i: 6, s: 27, ss: 55 },
    2300: { id: "2300", i: 10, s: 17, ss: 25 },
    2302: { id: "2302", i: 8, s: 9, ss: 60 },
    2307: { id: "2307", i: 6, s: 27, ss: 55 },
    2308: { id: "2308", i: 7, s: 26, ss: 78 },
    2309: { id: "2309", i: 6, s: 8, ss: 86 },
    2310: { id: "2310", i: 2, s: 2, ss: 47 },
    2312: { id: "2312", i: 3, s: 20, ss: 32 },
    2313: { id: "2313", i: 6, s: 27, ss: 50 },
    2314: { id: "2314", i: 8, s: 21, ss: 93 },
    2317: { id: "2317", i: 9, s: 10, ss: 13 },
    2318: { id: "2318", i: 3, s: 28, ss: 67 },
    2319: { id: "2319", i: 9, s: 10, ss: 87 },
    2320: { id: "2320", i: 8, s: 21, ss: 93 },
    2322: { id: "2322", i: 8, s: 21, ss: 31 },
    2323: { id: "2323", i: 10, s: 11, ss: 20 },
    2324: { id: "2324", i: 3, s: 20, ss: 32 },
    2326: { id: "2326", i: 8, s: 9, ss: 60 },
    2327: { id: "2327", i: 0, s: 0, ss: 0 },
    2328: { id: "2328", i: 3, s: 28, ss: 67 },
    2329: { id: "2329", i: 2, s: 2, ss: 3 },
    2330: { id: "2330", i: 2, s: 2, ss: 3 },
    2331: { id: "2331", i: 6, s: 27, ss: 50 },
    2333: { id: "2333", i: 6, s: 16, ss: 62 },
    2336: { id: "2336", i: 8, s: 9, ss: 81 },
    2337: { id: "2337", i: 1, s: 1, ss: 2 },
    2338: { id: "2338", i: 10, s: 11, ss: 45 },
    2339: { id: "2339", i: 6, s: 16, ss: 24 },
    2340: { id: "2340", i: 2, s: 2, ss: 47 },
    2341: { id: "2341", i: 8, s: 21, ss: 31 },
    2342: { id: "2342", i: 7, s: 26, ss: 48 },
    2343: { id: "2343", i: 10, s: 25, ss: 53 },
    2346: { id: "2346", i: 10, s: 11, ss: 20 },
    2348: { id: "2348", i: 5, s: 5, ss: 7 },
    2349: { id: "2349", i: 2, s: 2, ss: 5 },
    2355: { id: "2355", i: 2, s: 18, ss: 56 },
    2356: { id: "2356", i: 3, s: 3, ss: 4 },
    2357: { id: "2357", i: 10, s: 11, ss: 14 },
    2358: { id: "2358", i: 6, s: 13, ss: 19 },
    2359: { id: "2359", i: 5, s: 5, ss: 94 },
    2360: { id: "2360", i: 9, s: 14, ss: 18 },
    2362: { id: "2362", i: 8, s: 9, ss: 60 },
    2363: { id: "2363", i: 7, s: 26, ss: 78 },
    2366: { id: "2366", i: 6, s: 6, ss: 77 },
    2368: { id: "2368", i: 6, s: 27, ss: 50 },
    2369: { id: "2369", i: 7, s: 26, ss: 48 },
    2371: { id: "2371", i: 6, s: 12, ss: 15 },
    2377: { id: "2377", i: 10, s: 11, ss: 57 },
    2378: { id: "2378", i: 3, s: 28, ss: 67 },
    2379: { id: "2379", i: 2, s: 2, ss: 5 },
    2380: { id: "2380", i: 1, s: 1, ss: 1 },
    2381: { id: "2381", i: 6, s: 13, ss: 68 },
    2382: { id: "2382", i: 10, s: 11, ss: 43 },
    2383: { id: "2383", i: 6, s: 6, ss: 8 },
    2386: { id: "2386", i: 11, s: 24, ss: 64 },
    2388: { id: "2388", i: 3, s: 3, ss: 4 },
    2389: { id: "2389", i: 5, s: 30, ss: 73 },
    2393: { id: "2393", i: 5, s: 30, ss: 92 },
    2398: { id: "2398", i: 10, s: 11, ss: 20 },
    2399: { id: "2399", i: 6, s: 27, ss: 50 },
    2400: { id: "2400", i: 7, s: 7, ss: 9 },
    2448: { id: "2448", i: 2, s: 18, ss: 56 },
    2488: { id: "2488", i: 6, s: 16, ss: 24 },
    2500: { id: "2500", i: 5, s: 30, ss: 92 },
    2518: { id: "2518", i: 7, s: 7, ss: 52 },
    2528: { id: "2528", i: 6, s: 22, ss: 51 },
    2552: { id: "2552", i: 5, s: 5, ss: 94 },
    2558: { id: "2558", i: 3, s: 3, ss: 4 },
    2588: { id: "2588", i: 6, s: 8, ss: 75 },
    2599: { id: "2599", i: 2, s: 2, ss: 3 },
    2600: { id: "2600", i: 8, s: 9, ss: 42 },
    2601: { id: "2601", i: 3, s: 28, ss: 67 },
    2606: { id: "2606", i: 2, s: 2, ss: 47 },
    2607: { id: "2607", i: 5, s: 5, ss: 7 },
    2608: { id: "2608", i: 2, s: 2, ss: 3 },
    2611: { id: "2611", i: 3, s: 20, ss: 30 },
    2616: { id: "2616", i: 5, s: 5, ss: 94 },
    2618: { id: "2618", i: 10, s: 17, ss: 82 },
    2623: { id: "2623", i: 8, s: 9, ss: 11 },
    2628: { id: "2628", i: 3, s: 28, ss: 67 },
    2633: { id: "2633", i: 5, s: 5, ss: 7 },
    2638: { id: "2638", i: 1, s: 1, ss: 1 },
    2660: { id: "2660", i: 7, s: 7, ss: 9 },
    2662: { id: "2662", i: 6, s: 12, ss: 35 },
    2663: { id: "2663", i: 2, s: 18, ss: 56 },
    2666: { id: "2666", i: 3, s: 20, ss: 38 },
    2668: { id: "2668", i: 10, s: 17, ss: 82 },
    2669: { id: "2669", i: 2, s: 2, ss: 47 },
    2678: { id: "2678", i: 6, s: 27, ss: 55 },
    2680: { id: "2680", i: 3, s: 20, ss: 63 },
    2682: { id: "2682", i: 10, s: 25, ss: 53 },
    2683: { id: "2683", i: 6, s: 27, ss: 49 },
    2686: { id: "2686", i: 11, s: 24, ss: 40 },
    2688: { id: "2688", i: 1, s: 1, ss: 2 },
    2689: { id: "2689", i: 8, s: 21, ss: 93 },
    2696: { id: "2696", i: 5, s: 5, ss: 94 },
    2698: { id: "2698", i: 6, s: 27, ss: 55 },
    2699: { id: "2699", i: 2, s: 2, ss: 3 },
    2700: { id: "2700", i: 5, s: 30, ss: 73 },
    2708: { id: "2708", i: 7, s: 7, ss: 22 },
    2718: { id: "2718", i: 3, s: 20, ss: 38 },
    2722: { id: "2722", i: 0, s: 0, ss: 0 },
    2727: { id: "2727", i: 10, s: 11, ss: 57 },
    2728: { id: "2728", i: 11, s: 24, ss: 40 },
    2738: { id: "2738", i: 8, s: 9, ss: 11 },
    2768: { id: "2768", i: 2, s: 2, ss: 3 },
    2772: { id: "2772", i: 2, s: 2, ss: 3 },
    2777: { id: "2777", i: 2, s: 2, ss: 3 },
    2778: { id: "2778", i: "", s: "", ss: 85 },
    2779: { id: "2779", i: 6, s: 12, ss: 15 },
    2788: { id: "2788", i: 10, s: 11, ss: 20 },
    2789: { id: "2789", i: 2, s: 18, ss: 56 },
    2798: { id: "2798", i: 11, s: 19, ss: 28 },
    2799: { id: "2799", i: 3, s: 20, ss: 32 },
    2800: { id: "2800", i: "", s: "", ss: 85 },
    2801: { id: "2801", i: "", s: "", ss: 85 },
    2802: { id: "2802", i: "", s: "", ss: 85 },
    2803: { id: "2803", i: "", s: "", ss: 85 },
    2804: { id: "2804", i: "", s: "", ss: 85 },
    2806: { id: "2806", i: "", s: "", ss: 85 },
    2807: { id: "2807", i: "", s: "", ss: 85 },
    2809: { id: "2809", i: "", s: "", ss: 85 },
    2810: { id: "2810", i: "", s: "", ss: 85 },
    2811: { id: "2811", i: "", s: "", ss: 85 },
    2812: { id: "2812", i: "", s: "", ss: 85 },
    2813: { id: "2813", i: "", s: "", ss: 85 },
    2814: { id: "2814", i: "", s: "", ss: 85 },
    2817: { id: "2817", i: "", s: "", ss: 85 },
    2819: { id: "2819", i: "", s: "", ss: 85 },
    2820: { id: "2820", i: "", s: "", ss: 85 },
    2821: { id: "2821", i: "", s: "", ss: 85 },
    2822: { id: "2822", i: "", s: "", ss: 85 },
    2823: { id: "2823", i: "", s: "", ss: 85 },
    2824: { id: "2824", i: "", s: "", ss: 85 },
    2825: { id: "2825", i: "", s: "", ss: 85 },
    2826: { id: "2826", i: "", s: "", ss: 85 },
    2827: { id: "2827", i: "", s: "", ss: 85 },
    2828: { id: "2828", i: "", s: "", ss: 85 },
    2829: { id: "2829", i: "", s: "", ss: 85 },
    2832: { id: "2832", i: "", s: "", ss: 85 },
    2833: { id: "2833", i: "", s: "", ss: 85 },
    2834: { id: "2834", i: "", s: "", ss: 85 },
    2836: { id: "2836", i: "", s: "", ss: 85 },
    2838: { id: "2838", i: "", s: "", ss: 85 },
    2839: { id: "2839", i: "", s: "", ss: 85 },
    2840: { id: "2840", i: "", s: "", ss: 85 },
    2843: { id: "2843", i: "", s: "", ss: 85 },
    2845: { id: "2845", i: "", s: "", ss: 85 },
    2846: { id: "2846", i: "", s: "", ss: 85 },
    2848: { id: "2848", i: "", s: "", ss: 85 },
    2849: { id: "2849", i: "", s: "", ss: 85 },
    2858: { id: "2858", i: 3, s: 20, ss: 38 },
    2863: { id: "2863", i: 2, s: 18, ss: 56 },
    2866: { id: "2866", i: 10, s: 25, ss: 53 },
    2868: { id: "2868", i: 2, s: 2, ss: 3 },
    2869: { id: "2869", i: 2, s: 2, ss: 47 },
    2877: { id: "2877", i: 5, s: 5, ss: 79 },
    2878: { id: "2878", i: 7, s: 23, ss: 37 },
    2880: { id: "2880", i: 10, s: 25, ss: 53 },
    2882: { id: "2882", i: 6, s: 22, ss: 36 },
    2883: { id: "2883", i: 11, s: 24, ss: 64 },
    2885: { id: "2885", i: 10, s: 11, ss: 20 },
    2886: { id: "2886", i: 1, s: 1, ss: 2 },
    2888: { id: "2888", i: 3, s: 3, ss: 4 },
    2892: { id: "2892", i: 2, s: 2, ss: 3 },
    2898: { id: "2898", i: 9, s: 10, ss: 66 },
    2899: { id: "2899", i: 8, s: 29, ss: 72 },
    2902: { id: "2902", i: 11, s: 24, ss: 64 },
    2905: { id: "2905", i: 8, s: 21, ss: 93 },
    2908: { id: "2908", i: 7, s: 7, ss: 52 },
    2914: { id: "2914", i: 6, s: 27, ss: 55 },
    2915: { id: "2915", i: 3, s: 20, ss: 32 },
    2916: { id: "2916", i: 11, s: 19, ss: 28 },
    2917: { id: "2917", i: 5, s: 30, ss: 73 },
    2920: { id: "2920", i: 7, s: 26, ss: 78 },
    2922: { id: "2922", i: 10, s: 25, ss: 90 },
    2923: { id: "2923", i: 7, s: 7, ss: 22 },
    2971: { id: "2971", i: 6, s: 8, ss: 12 },
    2972: { id: "2972", i: 1, s: 1, ss: 71 },
    2973: { id: "2973", i: 6, s: 27, ss: 50 },
    2974: { id: "2974", i: 10, s: 25, ss: 90 },
    2975: { id: "2975", i: 6, s: 6, ss: 77 },
    2981: { id: "2981", i: 5, s: 30, ss: 92 },
    2983: { id: "2983", i: 3, s: 20, ss: 38 },
    2989: { id: "2989", i: 2, s: 18, ss: 56 },
    2995: { id: "2995", i: 3, s: 20, ss: 30 },
    2996: { id: "2996", i: 0, s: 0, ss: 0 },
    3001: { id: "3001", i: "", s: "", ss: 85 },
    3003: { id: "3003", i: "", s: "", ss: 85 },
    3005: { id: "3005", i: "", s: "", ss: 85 },
    3006: { id: "3006", i: "", s: "", ss: 85 },
    3007: { id: "3007", i: "", s: "", ss: 85 },
    3010: { id: "3010", i: "", s: "", ss: 85 },
    3011: { id: "3011", i: "", s: "", ss: 85 },
    3012: { id: "3012", i: "", s: "", ss: 85 },
    3015: { id: "3015", i: "", s: "", ss: 85 },
    3016: { id: "3016", i: "", s: "", ss: 85 },
    3019: { id: "3019", i: "", s: "", ss: 85 },
    3020: { id: "3020", i: "", s: "", ss: 85 },
    3021: { id: "3021", i: "", s: "", ss: 85 },
    3022: { id: "3022", i: "", s: "", ss: 85 },
    3023: { id: "3023", i: "", s: "", ss: 85 },
    3024: { id: "3024", i: "", s: "", ss: 85 },
    3027: { id: "3027", i: "", s: "", ss: 85 },
    3029: { id: "3029", i: "", s: "", ss: 85 },
    3031: { id: "3031", i: "", s: "", ss: 85 },
    3032: { id: "3032", i: "", s: "", ss: 85 },
    3033: { id: "3033", i: "", s: "", ss: 85 },
    3034: { id: "3034", i: "", s: "", ss: 85 },
    3036: { id: "3036", i: "", s: "", ss: 85 },
    3037: { id: "3037", i: "", s: "", ss: 85 },
    3040: { id: "3040", i: "", s: "", ss: 85 },
    3043: { id: "3043", i: "", s: "", ss: 85 },
    3047: { id: "3047", i: "", s: "", ss: 85 },
    3048: { id: "3048", i: "", s: "", ss: 85 },
    3049: { id: "3049", i: "", s: "", ss: 85 },
    3050: { id: "3050", i: "", s: "", ss: 85 },
    3051: { id: "3051", i: "", s: "", ss: 85 },
    3053: { id: "3053", i: "", s: "", ss: 85 },
    3055: { id: "3055", i: "", s: "", ss: 85 },
    3058: { id: "3058", i: "", s: "", ss: 85 },
    3060: { id: "3060", i: "", s: "", ss: 85 },
    3065: { id: "3065", i: "", s: "", ss: 85 },
    3067: { id: "3067", i: "", s: "", ss: 85 },
    3069: { id: "3069", i: "", s: "", ss: 85 },
    3070: { id: "3070", i: "", s: "", ss: 85 },
    3071: { id: "3071", i: "", s: "", ss: 85 },
    3072: { id: "3072", i: "", s: "", ss: 85 },
    3073: { id: "3073", i: "", s: "", ss: 85 },
    3074: { id: "3074", i: "", s: "", ss: 85 },
    3076: { id: "3076", i: "", s: "", ss: 85 },
    3077: { id: "3077", i: "", s: "", ss: 85 },
    3079: { id: "3079", i: "", s: "", ss: 85 },
    3080: { id: "3080", i: "", s: "", ss: 85 },
    3081: { id: "3081", i: "", s: "", ss: 85 },
    3082: { id: "3082", i: "", s: "", ss: 85 },
    3086: { id: "3086", i: "", s: "", ss: 85 },
    3087: { id: "3087", i: "", s: "", ss: 85 },
    3088: { id: "3088", i: "", s: "", ss: 85 },
    3091: { id: "3091", i: "", s: "", ss: 85 },
    3092: { id: "3092", i: "", s: "", ss: 85 },
    3093: { id: "3093", i: "", s: "", ss: 85 },
    3096: { id: "3096", i: "", s: "", ss: 85 },
    3097: { id: "3097", i: "", s: "", ss: 85 },
    3099: { id: "3099", i: "", s: "", ss: 85 },
    3100: { id: "3100", i: "", s: "", ss: 85 },
    3108: { id: "3108", i: "", s: "", ss: 85 },
    3109: { id: "3109", i: "", s: "", ss: 85 },
    3110: { id: "3110", i: "", s: "", ss: 85 },
    3111: { id: "3111", i: "", s: "", ss: 85 },
    3115: { id: "3115", i: "", s: "", ss: 85 },
    3117: { id: "3117", i: "", s: "", ss: 85 },
    3118: { id: "3118", i: "", s: "", ss: 85 },
    3119: { id: "3119", i: "", s: "", ss: 85 },
    3121: { id: "3121", i: "", s: "", ss: 85 },
    3122: { id: "3122", i: "", s: "", ss: 85 },
    3124: { id: "3124", i: "", s: "", ss: 85 },
    3125: { id: "3125", i: "", s: "", ss: 85 },
    3127: { id: "3127", i: "", s: "", ss: 85 },
    3128: { id: "3128", i: "", s: "", ss: 85 },
    3130: { id: "3130", i: "", s: "", ss: 85 },
    3132: { id: "3132", i: "", s: "", ss: 85 },
    3134: { id: "3134", i: "", s: "", ss: 85 },
    3136: { id: "3136", i: "", s: "", ss: 85 },
    3139: { id: "3139", i: "", s: "", ss: 85 },
    3141: { id: "3141", i: "", s: "", ss: 85 },
    3143: { id: "3143", i: "", s: "", ss: 85 },
    3145: { id: "3145", i: "", s: "", ss: 85 },
    3147: { id: "3147", i: "", s: "", ss: 85 },
    3149: { id: "3149", i: "", s: "", ss: 85 },
    3151: { id: "3151", i: "", s: "", ss: 85 },
    3157: { id: "3157", i: "", s: "", ss: 85 },
    3160: { id: "3160", i: "", s: "", ss: 85 },
    3162: { id: "3162", i: "", s: "", ss: 85 },
    3163: { id: "3163", i: "", s: "", ss: 85 },
    3165: { id: "3165", i: "", s: "", ss: 85 },
    3166: { id: "3166", i: "", s: "", ss: 85 },
    3167: { id: "3167", i: "", s: "", ss: 85 },
    3173: { id: "3173", i: "", s: "", ss: 85 },
    3174: { id: "3174", i: "", s: "", ss: 85 },
    3175: { id: "3175", i: "", s: "", ss: 85 },
    3176: { id: "3176", i: "", s: "", ss: 85 },
    3181: { id: "3181", i: "", s: "", ss: 85 },
    3182: { id: "3182", i: "", s: "", ss: 85 },
    3185: { id: "3185", i: "", s: "", ss: 85 },
    3186: { id: "3186", i: "", s: "", ss: 85 },
    3187: { id: "3187", i: "", s: "", ss: 85 },
    3188: { id: "3188", i: "", s: "", ss: 85 },
    3191: { id: "3191", i: "", s: "", ss: 85 },
    3193: { id: "3193", i: "", s: "", ss: 85 },
    3194: { id: "3194", i: "", s: "", ss: 85 },
    3197: { id: "3197", i: "", s: "", ss: 85 },
    3198: { id: "3198", i: "", s: "", ss: 85 },
    3199: { id: "3199", i: "", s: "", ss: 85 },
    3300: { id: "3300", i: 10, s: 11, ss: 20 },
    3301: { id: "3301", i: 2, s: 2, ss: 3 },
    3302: { id: "3302", i: 10, s: 11, ss: 20 },
    3303: { id: "3303", i: 11, s: 24, ss: 64 },
    3306: { id: "3306", i: 6, s: 27, ss: 50 },
    3308: { id: "3308", i: 6, s: 22, ss: 59 },
    3309: { id: "3309", i: 5, s: 30, ss: 73 },
    3311: { id: "3311", i: 2, s: 18, ss: 69 },
    3313: { id: "3313", i: 2, s: 18, ss: 26 },
    3315: { id: "3315", i: 7, s: 7, ss: 9 },
    3316: { id: "3316", i: 2, s: 2, ss: 47 },
    3318: { id: "3318", i: 9, s: 10, ss: 13 },
    3319: { id: "3319", i: 2, s: 2, ss: 47 },
    3320: { id: "3320", i: 5, s: 5, ss: 7 },
    3321: { id: "3321", i: 2, s: 18, ss: 56 },
    3322: { id: "3322", i: 6, s: 27, ss: 50 },
    3323: { id: "3323", i: 2, s: 18, ss: 26 },
    3326: { id: "3326", i: 6, s: 27, ss: 70 },
    3328: { id: "3328", i: 3, s: 3, ss: 4 },
    3329: { id: "3329", i: 3, s: 20, ss: 38 },
    3330: { id: "3330", i: 8, s: 29, ss: 72 },
    3331: { id: "3331", i: 9, s: 14, ss: 84 },
    3332: { id: "3332", i: 9, s: 14, ss: 84 },
    3333: { id: "3333", i: 2, s: 2, ss: 3 },
    3336: { id: "3336", i: 7, s: 26, ss: 78 },
    3337: { id: "3337", i: 11, s: 24, ss: 64 },
    3339: { id: "3339", i: 10, s: 11, ss: 17 },
    3344: { id: "3344", i: 6, s: 27, ss: 50 },
    3347: { id: "3347", i: 5, s: 5, ss: 94 },
    3348: { id: "3348", i: 10, s: 11, ss: 17 },
    3358: { id: "3358", i: 6, s: 13, ss: 16 },
    3360: { id: "3360", i: 3, s: 20, ss: 38 },
    3363: { id: "3363", i: 8, s: 21, ss: 93 },
    3366: { id: "3366", i: 2, s: 2, ss: 3 },
    3368: { id: "3368", i: 6, s: 22, ss: 59 },
    3369: { id: "3369", i: 10, s: 25, ss: 53 },
    3377: { id: "3377", i: 2, s: 2, ss: 3 },
    3378: { id: "3378", i: 10, s: 25, ss: 53 },
    3380: { id: "3380", i: 2, s: 2, ss: 3 },
    3382: { id: "3382", i: 10, s: 25, ss: 53 },
    3383: { id: "3383", i: 2, s: 2, ss: 3 },
    3389: { id: "3389", i: 6, s: 22, ss: 36 },
    3390: { id: "3390", i: 5, s: 5, ss: 7 },
    3393: { id: "3393", i: 10, s: 11, ss: 20 },
    3395: { id: "3395", i: 11, s: 24, ss: 40 },
    3396: { id: "3396", i: 7, s: 26, ss: 78 },
    3398: { id: "3398", i: 6, s: 27, ss: 50 },
    3399: { id: "3399", i: 6, s: 8, ss: 33 },
    3600: { id: "3600", i: 5, s: 30, ss: 92 },
    3601: { id: "3601", i: 7, s: 7, ss: 9 },
    3603: { id: "3603", i: 2, s: 2, ss: 5 },
    3606: { id: "3606", i: 6, s: 16, ss: 24 },
    3608: { id: "3608", i: 6, s: 27, ss: 55 },
    3613: { id: "3613", i: 5, s: 5, ss: 79 },
    3616: { id: "3616", i: 2, s: 2, ss: 3 },
    3618: { id: "3618", i: 3, s: 3, ss: 4 },
    3623: { id: "3623", i: 3, s: 20, ss: 63 },
    3626: { id: "3626", i: 10, s: 17, ss: 25 },
    3628: { id: "3628", i: 10, s: 11, ss: 20 },
    3633: { id: "3633", i: 1, s: 1, ss: 2 },
    3636: { id: "3636", i: 6, s: 6, ss: 77 },
    3638: { id: "3638", i: 7, s: 26, ss: 78 },
    3639: { id: "3639", i: 2, s: 2, ss: 3 },
    3658: { id: "3658", i: 2, s: 2, ss: 47 },
    3662: { id: "3662", i: 2, s: 2, ss: 47 },
    3663: { id: "3663", i: 6, s: 16, ss: 24 },
    3666: { id: "3666", i: 6, s: 8, ss: 23 },
    3668: { id: "3668", i: 11, s: 19, ss: 28 },
    3669: { id: "3669", i: 6, s: 22, ss: 54 },
    3678: { id: "3678", i: 3, s: 20, ss: 30 },
    3680: { id: "3680", i: 7, s: 7, ss: 22 },
    3681: { id: "3681", i: 5, s: 5, ss: 94 },
    3683: { id: "3683", i: 10, s: 25, ss: 53 },
    3686: { id: "3686", i: 0, s: 0, ss: 0 },
    3688: { id: "3688", i: 2, s: 2, ss: 5 },
    3689: { id: "3689", i: 5, s: 30, ss: 73 },
    3690: { id: "3690", i: 7, s: 7, ss: 52 },
    3692: { id: "3692", i: 5, s: 5, ss: 7 },
    3698: { id: "3698", i: 3, s: 3, ss: 4 },
    3699: { id: "3699", i: 2, s: 2, ss: 5 },
    3700: { id: "3700", i: 6, s: 6, ss: 91 },
    3708: { id: "3708", i: 2, s: 18, ss: 56 },
    3709: { id: "3709", i: 6, s: 27, ss: 50 },
    3718: { id: "3718", i: 10, s: 11, ss: 57 },
    3728: { id: "3728", i: 2, s: 18, ss: 56 },
    3737: { id: "3737", i: 5, s: 5, ss: 7 },
    3738: { id: "3738", i: 7, s: 7, ss: 9 },
    3759: { id: "3759", i: 5, s: 5, ss: 7 },
    3768: { id: "3768", i: 1, s: 1, ss: 71 },
    3773: { id: "3773", i: 7, s: 7, ss: 52 },
    3778: { id: "3778", i: 6, s: 27, ss: 55 },
    3788: { id: "3788", i: 8, s: 9, ss: 11 },
    3789: { id: "3789", i: 2, s: 18, ss: 56 },
    3798: { id: "3798", i: 7, s: 7, ss: 9 },
    3799: { id: "3799", i: 9, s: 10, ss: 27 },
    3800: { id: "3800", i: 10, s: 11, ss: 58 },
    3808: { id: "3808", i: 10, s: 11, ss: 45 },
    3813: { id: "3813", i: 6, s: 22, ss: 51 },
    3816: { id: "3816", i: 10, s: 11, ss: 20 },
    3818: { id: "3818", i: 6, s: 27, ss: 50 },
    3822: { id: "3822", i: 2, s: 18, ss: 69 },
    3828: { id: "3828", i: 9, s: 14, ss: 84 },
    3830: { id: "3830", i: 6, s: 13, ss: 16 },
    3833: { id: "3833", i: 8, s: 9, ss: 60 },
    3836: { id: "3836", i: 6, s: 22, ss: 54 },
    3838: { id: "3838", i: 9, s: 15, ss: 34 },
    3839: { id: "3839", i: 5, s: 5, ss: 94 },
    3848: { id: "3848", i: 3, s: 20, ss: 38 },
    3860: { id: "3860", i: 6, s: 27, ss: 50 },
    3866: { id: "3866", i: 3, s: 3, ss: 4 },
    3868: { id: "3868", i: 1, s: 1, ss: 39 },
    3869: { id: "3869", i: 5, s: 30, ss: 73 },
    3877: { id: "3877", i: 3, s: 20, ss: 38 },
    3878: { id: "3878", i: 2, s: 18, ss: 56 },
    3882: { id: "3882", i: 6, s: 13, ss: 19 },
    3883: { id: "3883", i: 2, s: 2, ss: 3 },
    3886: { id: "3886", i: 5, s: 30, ss: 73 },
    3888: { id: "3888", i: 7, s: 7, ss: 9 },
    3889: { id: "3889", i: 9, s: 15, ss: 34 },
    3893: { id: "3893", i: 6, s: 13, ss: 83 },
    3898: { id: "3898", i: 10, s: 11, ss: 45 },
    3899: { id: "3899", i: 11, s: 24, ss: 64 },
    3900: { id: "3900", i: 2, s: 2, ss: 3 },
    3903: { id: "3903", i: 3, s: 20, ss: 38 },
    3908: { id: "3908", i: 3, s: 20, ss: 30 },
    3913: { id: "3913", i: 2, s: 2, ss: 47 },
    3918: { id: "3918", i: 6, s: 8, ss: 12 },
    3919: { id: "3919", i: 10, s: 11, ss: 20 },
    3928: { id: "3928", i: 2, s: 18, ss: 69 },
    3933: { id: "3933", i: 5, s: 5, ss: 7 },
    3938: { id: "3938", i: 3, s: 20, ss: 63 },
    3939: { id: "3939", i: 8, s: 9, ss: 81 },
    3948: { id: "3948", i: 11, s: 19, ss: 28 },
    3958: { id: "3958", i: 3, s: 20, ss: 30 },
    3963: { id: "3963", i: 3, s: 20, ss: 38 },
    3968: { id: "3968", i: 3, s: 3, ss: 4 },
    3969: { id: "3969", i: 7, s: 7, ss: 22 },
    3978: { id: "3978", i: 6, s: 12, ss: 15 },
    3983: { id: "3983", i: 8, s: 21, ss: 76 },
    3988: { id: "3988", i: 3, s: 3, ss: 4 },
    3989: { id: "3989", i: 1, s: 1, ss: 39 },
    3990: { id: "3990", i: 2, s: 2, ss: 3 },
    3991: { id: "3991", i: 7, s: 26, ss: 78 },
    3992: { id: "3992", i: 2, s: 2, ss: 47 },
    3993: { id: "3993", i: 8, s: 9, ss: 60 },
    3996: { id: "3996", i: 10, s: 11, ss: 57 },
    3997: { id: "3997", i: 7, s: 26, ss: 48 },
    3998: { id: "3998", i: 6, s: 27, ss: 50 },
    3999: { id: "3999", i: 9, s: 15, ss: 21 },
    4332: { id: "4332", i: "", s: "", ss: 85 },
    4333: { id: "4333", i: "", s: "", ss: 85 },
    4335: { id: "4335", i: "", s: "", ss: 85 },
    4336: { id: "4336", i: "", s: "", ss: 85 },
    4337: { id: "4337", i: "", s: "", ss: 85 },
    4338: { id: "4338", i: "", s: "", ss: 85 },
    4836: { id: "4836", i: "", s: "", ss: 85 },
    6030: { id: "6030", i: 3, s: 20, ss: 32 },
    6033: { id: "6033", i: 7, s: 26, ss: 48 },
    6036: { id: "6036", i: 10, s: 11, ss: 20 },
    6038: { id: "6038", i: 2, s: 18, ss: 56 },
    6049: { id: "6049", i: 2, s: 2, ss: 47 },
    6055: { id: "6055", i: 9, s: 10, ss: 95 },
    6058: { id: "6058", i: 3, s: 20, ss: 30 },
    6060: { id: "6060", i: 3, s: 28, ss: 67 },
    6063: { id: "6063", i: 2, s: 18, ss: 56 },
    6066: { id: "6066", i: 3, s: 20, ss: 30 },
    6068: { id: "6068", i: 6, s: 12, ss: 15 },
    6069: { id: "6069", i: 3, s: 20, ss: 38 },
    6078: { id: "6078", i: 5, s: 30, ss: 73 },
    6080: { id: "6080", i: 2, s: 18, ss: 56 },
    6083: { id: "6083", i: 10, s: 25, ss: 90 },
    6088: { id: "6088", i: 7, s: 26, ss: 48 },
    6090: { id: "6090", i: 2, s: 2, ss: 5 },
    6093: { id: "6093", i: 2, s: 2, ss: 47 },
    6098: { id: "6098", i: 2, s: 2, ss: 47 },
    6099: { id: "6099", i: 3, s: 20, ss: 30 },
    6100: { id: "6100", i: 6, s: 12, ss: 35 },
    6108: { id: "6108", i: 5, s: 5, ss: 7 },
    6110: { id: "6110", i: 6, s: 22, ss: 51 },
    6111: { id: "6111", i: 2, s: 2, ss: 3 },
    6113: { id: "6113", i: 6, s: 6, ss: 44 },
    6116: { id: "6116", i: 6, s: 27, ss: 50 },
    6117: { id: "6117", i: 10, s: 25, ss: 53 },
    6118: { id: "6118", i: 5, s: 5, ss: 94 },
    6119: { id: "6119", i: 10, s: 25, ss: 53 },
    6122: { id: "6122", i: 3, s: 3, ss: 4 },
    6123: { id: "6123", i: 10, s: 25, ss: 90 },
    6127: { id: "6127", i: 5, s: 5, ss: 94 },
    6128: { id: "6128", i: 8, s: 9, ss: 60 },
    6133: { id: "6133", i: 7, s: 26, ss: 48 },
    6136: { id: "6136", i: 1, s: 1, ss: 71 },
    6138: { id: "6138", i: 3, s: 3, ss: 4 },
    6158: { id: "6158", i: 2, s: 2, ss: 3 },
    6160: { id: "6160", i: 5, s: 5, ss: 94 },
    6161: { id: "6161", i: 3, s: 28, ss: 67 },
    6162: { id: "6162", i: 6, s: 16, ss: 24 },
    6163: { id: "6163", i: 10, s: 11, ss: 45 },
    6166: { id: "6166", i: 2, s: 2, ss: 3 },
    6168: { id: "6168", i: 7, s: 26, ss: 48 },
    6169: { id: "6169", i: 6, s: 12, ss: 15 },
    6178: { id: "6178", i: 3, s: 20, ss: 30 },
    6182: { id: "6182", i: 2, s: 18, ss: 56 },
    6183: { id: "6183", i: 9, s: 15, ss: 34 },
    6185: { id: "6185", i: 5, s: 5, ss: 94 },
    6186: { id: "6186", i: 9, s: 10, ss: 87 },
    6188: { id: "6188", i: 7, s: 26, ss: 48 },
    6189: { id: "6189", i: 2, s: 18, ss: 56 },
    6190: { id: "6190", i: 3, s: 3, ss: 4 },
    6193: { id: "6193", i: 2, s: 18, ss: 26 },
    6196: { id: "6196", i: 3, s: 3, ss: 4 },
    6198: { id: "6198", i: 10, s: 25, ss: 53 },
    6199: { id: "6199", i: 3, s: 3, ss: 4 },
    6288: { id: "6288", i: 6, s: 27, ss: 50 },
    6600: { id: "6600", i: 5, s: 5, ss: 7 },
    6601: { id: "6601", i: 6, s: 12, ss: 35 },
    6606: { id: "6606", i: 5, s: 30, ss: 92 },
    6608: { id: "6608", i: 7, s: 7, ss: 22 },
    6609: { id: "6609", i: 5, s: 30, ss: 92 },
    6611: { id: "6611", i: 2, s: 2, ss: 3 },
    6616: { id: "6616", i: 8, s: 21, ss: 31 },
    6618: { id: "6618", i: 5, s: 5, ss: 7 },
    6622: { id: "6622", i: 5, s: 5, ss: 7 },
    6626: { id: "6626", i: 2, s: 2, ss: 47 },
    6628: { id: "6628", i: 5, s: 5, ss: 94 },
    6633: { id: "6633", i: 7, s: 7, ss: 9 },
    6639: { id: "6639", i: 5, s: 30, ss: 73 },
    6655: { id: "6655", i: 2, s: 18, ss: 26 },
    6663: { id: "6663", i: 6, s: 12, ss: 35 },
    6666: { id: "6666", i: 2, s: 2, ss: 47 },
    6668: { id: "6668", i: 2, s: 2, ss: 47 },
    6669: { id: "6669", i: 5, s: 30, ss: 92 },
    6677: { id: "6677", i: 2, s: 2, ss: 47 },
    6680: { id: "6680", i: 8, s: 9, ss: 60 },
    6690: { id: "6690", i: 6, s: 13, ss: 68 },
    6699: { id: "6699", i: 5, s: 30, ss: 73 },
    6805: { id: "6805", i: 10, s: 11, ss: 57 },
    6806: { id: "6806", i: 3, s: 20, ss: 30 },
    6808: { id: "6808", i: 9, s: 14, ss: 18 },
    6811: { id: "6811", i: 6, s: 8, ss: 23 },
    6812: { id: "6812", i: 6, s: 12, ss: 35 },
    6816: { id: "6816", i: 2, s: 18, ss: 69 },
    6818: { id: "6818", i: 3, s: 3, ss: 4 },
    6819: { id: "6819", i: 6, s: 12, ss: 35 },
    6820: { id: "6820", i: 7, s: 7, ss: 9 },
    6821: { id: "6821", i: 5, s: 5, ss: 7 },
    6822: { id: "6822", i: 6, s: 13, ss: 83 },
    6823: { id: "6823", i: 4, s: 4, ss: 6 },
    6826: { id: "6826", i: 5, s: 5, ss: 94 },
    6828: { id: "6828", i: 1, s: 1, ss: 2 },
    6829: { id: "6829", i: 2, s: 18, ss: 56 },
    6830: { id: "6830", i: 6, s: 16, ss: 24 },
    6833: { id: "6833", i: 5, s: 5, ss: 7 },
    6836: { id: "6836", i: 9, s: 10, ss: 27 },
    6837: { id: "6837", i: 3, s: 20, ss: 30 },
    6838: { id: "6838", i: 6, s: 27, ss: 49 },
    6839: { id: "6839", i: 1, s: 1, ss: 71 },
    6855: { id: "6855", i: 5, s: 5, ss: 94 },
    6858: { id: "6858", i: 6, s: 13, ss: 16 },
    6860: { id: "6860", i: 7, s: 7, ss: 9 },
    6862: { id: "6862", i: 6, s: 8, ss: 23 },
    6865: { id: "6865", i: 10, s: 11, ss: 58 },
    6866: { id: "6866", i: 3, s: 20, ss: 38 },
    6868: { id: "6868", i: 9, s: 10, ss: 66 },
    6869: { id: "6869", i: 7, s: 26, ss: 48 },
    6877: { id: "6877", i: 3, s: 20, ss: 30 },
    6878: { id: "6878", i: 2, s: 2, ss: 3 },
    6880: { id: "6880", i: 10, s: 25, ss: 90 },
    6881: { id: "6881", i: 3, s: 20, ss: 30 },
    6882: { id: "6882", i: 6, s: 8, ss: 33 },
    6885: { id: "6885", i: 11, s: 19, ss: 28 },
    6886: { id: "6886", i: 3, s: 20, ss: 30 },
    6888: { id: "6888", i: 2, s: 18, ss: 69 },
    6889: { id: "6889", i: 6, s: 8, ss: 12 },
    6890: { id: "6890", i: 8, s: 9, ss: 11 },
    6893: { id: "6893", i: 5, s: 5, ss: 7 },
    6896: { id: "6896", i: 5, s: 5, ss: 7 },
    6898: { id: "6898", i: 10, s: 17, ss: 25 },
    6899: { id: "6899", i: 7, s: 7, ss: 9 },
    6900: { id: "6900", i: 2, s: 2, ss: 3 },
    6908: { id: "6908", i: 7, s: 23, ss: 37 },
    6909: { id: "6909", i: 6, s: 22, ss: 54 },
    6913: { id: "6913", i: 6, s: 12, ss: 15 },
    6918: { id: "6918", i: 6, s: 13, ss: 16 },
    6919: { id: "6919", i: 6, s: 12, ss: 35 },
    6928: { id: "6928", i: 6, s: 16, ss: 24 },
    6933: { id: "6933", i: 7, s: 7, ss: 9 },
    6939: { id: "6939", i: 10, s: 11, ss: 20 },
    6958: { id: "6958", i: 2, s: 2, ss: 47 },
    6966: { id: "6966", i: 6, s: 12, ss: 35 },
    6968: { id: "6968", i: 2, s: 2, ss: 3 },
    6969: { id: "6969", i: 6, s: 13, ss: 19 },
    6978: { id: "6978", i: 5, s: 5, ss: 94 },
    6988: { id: "6988", i: 6, s: 6, ss: 44 },
    6989: { id: "6989", i: 2, s: 2, ss: 47 },
    6993: { id: "6993", i: 6, s: 13, ss: 83 },
    6996: { id: "6996", i: 5, s: 5, ss: 7 },
    6998: { id: "6998", i: 5, s: 5, ss: 94 },
    6999: { id: "6999", i: 2, s: 2, ss: 3 },
    7200: { id: "7200", i: "", s: "", ss: 85 },
    7226: { id: "7226", i: "", s: "", ss: 85 },
    7230: { id: "7230", i: "", s: "", ss: 85 },
    7231: { id: "7231", i: "", s: "", ss: 85 },
    7232: { id: "7232", i: "", s: "", ss: 85 },
    7233: { id: "7233", i: "", s: "", ss: 85 },
    7248: { id: "7248", i: "", s: "", ss: 85 },
    7261: { id: "7261", i: "", s: "", ss: 85 },
    7266: { id: "7266", i: "", s: "", ss: 85 },
    7272: { id: "7272", i: "", s: "", ss: 85 },
    7288: { id: "7288", i: "", s: "", ss: 85 },
    7299: { id: "7299", i: "", s: "", ss: 85 },
    7300: { id: "7300", i: "", s: "", ss: 85 },
    7321: { id: "7321", i: "", s: "", ss: 85 },
    7322: { id: "7322", i: "", s: "", ss: 85 },
    7331: { id: "7331", i: "", s: "", ss: 85 },
    7332: { id: "7332", i: "", s: "", ss: 85 },
    7333: { id: "7333", i: "", s: "", ss: 85 },
    7336: { id: "7336", i: "", s: "", ss: 85 },
    7345: { id: "7345", i: "", s: "", ss: 85 },
    7348: { id: "7348", i: "", s: "", ss: 85 },
    7362: { id: "7362", i: "", s: "", ss: 85 },
    7373: { id: "7373", i: "", s: "", ss: 85 },
    7374: { id: "7374", i: "", s: "", ss: 85 },
    7500: { id: "7500", i: "", s: "", ss: 85 },
    7522: { id: "7522", i: "", s: "", ss: 85 },
    7552: { id: "7552", i: "", s: "", ss: 85 },
    7568: { id: "7568", i: "", s: "", ss: 85 },
    7588: { id: "7588", i: "", s: "", ss: 85 },
    7836: { id: "7836", i: "", s: "", ss: 85 },
    8001: { id: "8001", i: 3, s: 20, ss: 38 },
    8003: { id: "8003", i: 6, s: 6, ss: 44 },
    8005: { id: "8005", i: 6, s: 13, ss: 19 },
    8006: { id: "8006", i: 6, s: 6, ss: 8 },
    8007: { id: "8007", i: 8, s: 9, ss: 81 },
    8009: { id: "8009", i: 11, s: 24, ss: 40 },
    8011: { id: "8011", i: 11, s: 24, ss: 40 },
    8013: { id: "8013", i: 7, s: 7, ss: 22 },
    8017: { id: "8017", i: 7, s: 7, ss: 22 },
    8018: { id: "8018", i: 7, s: 7, ss: 9 },
    8019: { id: "8019", i: 3, s: 20, ss: 38 },
    8020: { id: "8020", i: 10, s: 25, ss: 53 },
    8021: { id: "8021", i: 2, s: 18, ss: 56 },
    8022: { id: "8022", i: 2, s: 2, ss: 3 },
    8023: { id: "8023", i: 2, s: 18, ss: 56 },
    8025: { id: "8025", i: 3, s: 20, ss: 32 },
    8026: { id: "8026", i: 5, s: 5, ss: 7 },
    8027: { id: "8027", i: 6, s: 12, ss: 35 },
    8028: { id: "8028", i: 8, s: 9, ss: 60 },
    8029: { id: "8029", i: 3, s: 20, ss: 30 },
    8030: { id: "8030", i: 2, s: 2, ss: 5 },
    8031: { id: "8031", i: 6, s: 12, ss: 35 },
    8032: { id: "8032", i: 6, s: 8, ss: 86 },
    8033: { id: "8033", i: 7, s: 7, ss: 22 },
    8035: { id: "8035", i: 10, s: 25, ss: 90 },
    8036: { id: "8036", i: 7, s: 7, ss: 22 },
    8037: { id: "8037", i: 5, s: 30, ss: 73 },
    8039: { id: "8039", i: 2, s: 18, ss: 56 },
    8040: { id: "8040", i: 2, s: 18, ss: 56 },
    8041: { id: "8041", i: 7, s: 7, ss: 52 },
    8042: { id: "8042", i: 6, s: 12, ss: 35 },
    8043: { id: "8043", i: 7, s: 26, ss: 48 },
    8045: { id: "8045", i: 7, s: 7, ss: 22 },
    8047: { id: "8047", i: 10, s: 17, ss: 82 },
    8048: { id: "8048", i: 7, s: 7, ss: 22 },
    8049: { id: "8049", i: 5, s: 5, ss: 7 },
    8050: { id: "8050", i: 7, s: 7, ss: 22 },
    8051: { id: "8051", i: 7, s: 26, ss: 78 },
    8052: { id: "8052", i: 6, s: 8, ss: 86 },
    8053: { id: "8053", i: 2, s: 18, ss: 56 },
    8055: { id: "8055", i: 6, s: 12, ss: 15 },
    8056: { id: "8056", i: 6, s: 8, ss: 23 },
    8057: { id: "8057", i: 0, s: 0, ss: 0 },
    8059: { id: "8059", i: 2, s: 18, ss: 69 },
    8060: { id: "8060", i: 7, s: 7, ss: 22 },
    8062: { id: "8062", i: 7, s: 7, ss: 22 },
    8063: { id: "8063", i: 3, s: 20, ss: 38 },
    8065: { id: "8065", i: 7, s: 7, ss: 22 },
    8066: { id: "8066", i: 7, s: 26, ss: 78 },
    8067: { id: "8067", i: 6, s: 12, ss: 35 },
    8069: { id: "8069", i: 6, s: 8, ss: 33 },
    8070: { id: "8070", i: 10, s: 11, ss: 20 },
    8071: { id: "8071", i: 7, s: 7, ss: 52 },
    8072: { id: "8072", i: 3, s: 20, ss: 63 },
    8073: { id: "8073", i: 10, s: 11, ss: 20 },
    8075: { id: "8075", i: 6, s: 6, ss: 77 },
    8076: { id: "8076", i: 7, s: 7, ss: 52 },
    8078: { id: "8078", i: 6, s: 6, ss: 77 },
    8079: { id: "8079", i: 10, s: 17, ss: 82 },
    8080: { id: "8080", i: 10, s: 11, ss: 20 },
    8081: { id: "8081", i: 7, s: 7, ss: 22 },
    8082: { id: "8082", i: 6, s: 6, ss: 77 },
    8083: { id: "8083", i: 7, s: 7, ss: 52 },
    8086: { id: "8086", i: 7, s: 7, ss: 52 },
    8087: { id: "8087", i: 6, s: 6, ss: 44 },
    8088: { id: "8088", i: 7, s: 7, ss: 9 },
    8089: { id: "8089", i: 9, s: 10, ss: 66 },
    8091: { id: "8091", i: 6, s: 6, ss: 44 },
    8092: { id: "8092", i: 7, s: 7, ss: 22 },
    8093: { id: "8093", i: 6, s: 6, ss: 44 },
    8095: { id: "8095", i: 6, s: 8, ss: 33 },
    8096: { id: "8096", i: 6, s: 8, ss: 23 },
    8098: { id: "8098", i: 3, s: 20, ss: 30 },
    8100: { id: "8100", i: 7, s: 7, ss: 52 },
    8101: { id: "8101", i: 6, s: 22, ss: 89 },
    8103: { id: "8103", i: 6, s: 6, ss: 77 },
    8106: { id: "8106", i: 7, s: 7, ss: 9 },
    8107: { id: "8107", i: 6, s: 27, ss: 50 },
    8108: { id: "8108", i: 6, s: 12, ss: 35 },
    8109: { id: "8109", i: 3, s: 28, ss: 67 },
    8111: { id: "8111", i: 10, s: 11, ss: 57 },
    8112: { id: "8112", i: 6, s: 6, ss: 44 },
    8113: { id: "8113", i: 10, s: 11, ss: 20 },
    8115: { id: "8115", i: 10, s: 11, ss: 20 },
    8116: { id: "8116", i: 9, s: 10, ss: 61 },
    8117: { id: "8117", i: 1, s: 1, ss: 2 },
    8118: { id: "8118", i: 6, s: 13, ss: 83 },
    8119: { id: "8119", i: 7, s: 7, ss: 9 },
    8120: { id: "8120", i: 6, s: 8, ss: 23 },
    8121: { id: "8121", i: 6, s: 6, ss: 44 },
    8123: { id: "8123", i: 6, s: 22, ss: 54 },
    8125: { id: "8125", i: 2, s: 18, ss: 56 },
    8126: { id: "8126", i: 6, s: 22, ss: 54 },
    8128: { id: "8128", i: 10, s: 11, ss: 57 },
    8130: { id: "8130", i: 6, s: 6, ss: 8 },
    8131: { id: "8131", i: 7, s: 7, ss: 9 },
    8132: { id: "8132", i: 11, s: 24, ss: 40 },
    8133: { id: "8133", i: 10, s: 11, ss: 20 },
    8135: { id: "8135", i: 6, s: 16, ss: 24 },
    8136: { id: "8136", i: 6, s: 13, ss: 83 },
    8137: { id: "8137", i: 10, s: 11, ss: 58 },
    8139: { id: "8139", i: 8, s: 21, ss: 31 },
    8140: { id: "8140", i: 2, s: 18, ss: 69 },
    8143: { id: "8143", i: 5, s: 30, ss: 73 },
    8146: { id: "8146", i: 9, s: 10, ss: 61 },
    8147: { id: "8147", i: 4, s: 4, ss: 88 },
    8148: { id: "8148", i: 7, s: 7, ss: 9 },
    8149: { id: "8149", i: 3, s: 20, ss: 63 },
    8150: { id: "8150", i: 6, s: 13, ss: 83 },
    8151: { id: "8151", i: 10, s: 11, ss: 20 },
    8152: { id: "8152", i: 10, s: 11, ss: 17 },
    8153: { id: "8153", i: 6, s: 6, ss: 91 },
    8155: { id: "8155", i: 2, s: 2, ss: 3 },
    8156: { id: "8156", i: 6, s: 8, ss: 12 },
    8158: { id: "8158", i: 5, s: 30, ss: 92 },
    8159: { id: "8159", i: 7, s: 26, ss: 78 },
    8160: { id: "8160", i: 6, s: 12, ss: 15 },
    8161: { id: "8161", i: 5, s: 30, ss: 73 },
    8162: { id: "8162", i: 8, s: 29, ss: 72 },
    8163: { id: "8163", i: 9, s: 14, ss: 84 },
    8166: { id: "8166", i: 10, s: 17, ss: 82 },
    8167: { id: "8167", i: 7, s: 26, ss: 48 },
    8168: { id: "8168", i: 3, s: 20, ss: 63 },
    8169: { id: "8169", i: 10, s: 11, ss: 57 },
    8170: { id: "8170", i: 2, s: 18, ss: 56 },
    8171: { id: "8171", i: 7, s: 26, ss: 78 },
    8172: { id: "8172", i: 6, s: 6, ss: 77 },
    8173: { id: "8173", i: 2, s: 18, ss: 56 },
    8175: { id: "8175", i: 6, s: 6, ss: 77 },
    8176: { id: "8176", i: 5, s: 30, ss: 73 },
    8178: { id: "8178", i: 7, s: 7, ss: 22 },
    8179: { id: "8179", i: 9, s: 15, ss: 74 },
    8181: { id: "8181", i: 2, s: 2, ss: 47 },
    8186: { id: "8186", i: 8, s: 21, ss: 41 },
    8187: { id: "8187", i: 3, s: 20, ss: 38 },
    8188: { id: "8188", i: 2, s: 18, ss: 69 },
    8189: { id: "8189", i: 8, s: 21, ss: 76 },
    8191: { id: "8191", i: 8, s: 21, ss: 41 },
    8192: { id: "8192", i: 7, s: 26, ss: 78 },
    8193: { id: "8193", i: 3, s: 20, ss: 63 },
    8195: { id: "8195", i: 6, s: 27, ss: 50 },
    8196: { id: "8196", i: 1, s: 1, ss: 71 },
    8198: { id: "8198", i: 7, s: 7, ss: 22 },
    8200: { id: "8200", i: 6, s: 22, ss: 36 },
    8201: { id: "8201", i: 6, s: 12, ss: 35 },
    8202: { id: "8202", i: 6, s: 6, ss: 44 },
    8203: { id: "8203", i: 10, s: 17, ss: 82 },
    8205: { id: "8205", i: 7, s: 7, ss: 22 },
    8206: { id: "8206", i: 6, s: 12, ss: 15 },
    8208: { id: "8208", i: 2, s: 18, ss: 56 },
    8209: { id: "8209", i: 2, s: 18, ss: 56 },
    8210: { id: "8210", i: 3, s: 20, ss: 30 },
    8211: { id: "8211", i: 6, s: 27, ss: 55 },
    8213: { id: "8213", i: 6, s: 8, ss: 23 },
    8215: { id: "8215", i: 3, s: 20, ss: 38 },
    8216: { id: "8216", i: 2, s: 18, ss: 69 },
    8217: { id: "8217", i: 2, s: 18, ss: 69 },
    8218: { id: "8218", i: 10, s: 11, ss: 20 },
    8219: { id: "8219", i: 6, s: 27, ss: 70 },
    8220: { id: "8220", i: 6, s: 6, ss: 77 },
    8221: { id: "8221", i: 3, s: 20, ss: 63 },
    8222: { id: "8222", i: 6, s: 13, ss: 83 },
    8223: { id: "8223", i: 3, s: 20, ss: 38 },
    8225: { id: "8225", i: 5, s: 5, ss: 7 },
    8226: { id: "8226", i: 3, s: 20, ss: 30 },
    8227: { id: "8227", i: 2, s: 18, ss: 26 },
    8228: { id: "8228", i: 6, s: 6, ss: 77 },
    8229: { id: "8229", i: 7, s: 7, ss: 22 },
    8231: { id: "8231", i: 7, s: 23, ss: 37 },
    8232: { id: "8232", i: 6, s: 8, ss: 23 },
    8235: { id: "8235", i: 7, s: 7, ss: 22 },
    8237: { id: "8237", i: 6, s: 8, ss: 10 },
    8238: { id: "8238", i: 6, s: 6, ss: 44 },
    8239: { id: "8239", i: 3, s: 20, ss: 38 },
    8241: { id: "8241", i: 9, s: 10, ss: 66 },
    8242: { id: "8242", i: 2, s: 2, ss: 47 },
    8245: { id: "8245", i: 7, s: 26, ss: 48 },
    8246: { id: "8246", i: 10, s: 11, ss: 58 },
    8247: { id: "8247", i: 5, s: 5, ss: 94 },
    8249: { id: "8249", i: 7, s: 7, ss: 22 },
    8250: { id: "8250", i: 11, s: 19, ss: 28 },
    8255: { id: "8255", i: 3, s: 20, ss: 38 },
    8257: { id: "8257", i: 7, s: 23, ss: 37 },
    8258: { id: "8258", i: 10, s: 11, ss: 58 },
    8259: { id: "8259", i: 2, s: 18, ss: 56 },
    8260: { id: "8260", i: 6, s: 12, ss: 35 },
    8262: { id: "8262", i: 2, s: 18, ss: 56 },
    8265: { id: "8265", i: 6, s: 16, ss: 62 },
    8266: { id: "8266", i: 7, s: 26, ss: 48 },
    8267: { id: "8267", i: 7, s: 7, ss: 9 },
    8268: { id: "8268", i: 2, s: 18, ss: 56 },
    8269: { id: "8269", i: 6, s: 27, ss: 49 },
    8270: { id: "8270", i: 11, s: 24, ss: 40 },
    8271: { id: "8271", i: 2, s: 2, ss: 5 },
    8272: { id: "8272", i: 6, s: 8, ss: 23 },
    8275: { id: "8275", i: 2, s: 18, ss: 56 },
    8277: { id: "8277", i: 8, s: 21, ss: 41 },
    8279: { id: "8279", i: 6, s: 8, ss: 12 },
    8280: { id: "8280", i: 6, s: 6, ss: 91 },
    8281: { id: "8281", i: 9, s: 14, ss: 84 },
    8282: { id: "8282", i: 7, s: 7, ss: 9 },
    8283: { id: "8283", i: 6, s: 12, ss: 35 },
    8285: { id: "8285", i: 6, s: 27, ss: 49 },
    8286: { id: "8286", i: 7, s: 26, ss: 48 },
    8287: { id: "8287", i: 7, s: 26, ss: 78 },
    8290: { id: "8290", i: 7, s: 7, ss: 9 },
    8291: { id: "8291", i: 10, s: 17, ss: 25 },
    8292: { id: "8292", i: 10, s: 25, ss: 90 },
    8293: { id: "8293", i: 6, s: 12, ss: 35 },
    8295: { id: "8295", i: 3, s: 20, ss: 63 },
    8296: { id: "8296", i: 6, s: 12, ss: 35 },
    8297: { id: "8297", i: 6, s: 27, ss: 50 },
    8299: { id: "8299", i: 8, s: 29, ss: 72 },
    8300: { id: "8300", i: 6, s: 8, ss: 23 },
    8301: { id: "8301", i: 9, s: 10, ss: 61 },
    8305: { id: "8305", i: 2, s: 18, ss: 69 },
    8307: { id: "8307", i: 5, s: 30, ss: 73 },
    8308: { id: "8308", i: 6, s: 8, ss: 10 },
    8309: { id: "8309", i: 6, s: 12, ss: 35 },
    8310: { id: "8310", i: 10, s: 17, ss: 82 },
    8311: { id: "8311", i: 7, s: 26, ss: 48 },
    8313: { id: "8313", i: 0, s: 0, ss: 0 },
    8315: { id: "8315", i: 6, s: 12, ss: 35 },
    8316: { id: "8316", i: 2, s: 18, ss: 69 },
    8317: { id: "8317", i: 6, s: 6, ss: 8 },
    8319: { id: "8319", i: 7, s: 7, ss: 22 },
    8320: { id: "8320", i: 6, s: 12, ss: 35 },
    8321: { id: "8321", i: 2, s: 18, ss: 69 },
    8325: { id: "8325", i: 7, s: 7, ss: 52 },
    8326: { id: "8326", i: 1, s: 1, ss: 39 },
    8328: { id: "8328", i: 6, s: 16, ss: 24 },
    8329: { id: "8329", i: 5, s: 5, ss: 7 },
    8331: { id: "8331", i: 8, s: 9, ss: 60 },
    8333: { id: "8333", i: 3, s: 20, ss: 63 },
    8337: { id: "8337", i: 4, s: 4, ss: 6 },
    8340: { id: "8340", i: 3, s: 20, ss: 63 },
    8341: { id: "8341", i: 2, s: 18, ss: 56 },
    8347: { id: "8347", i: 11, s: 24, ss: 40 },
    8348: { id: "8348", i: 10, s: 17, ss: 82 },
    8349: { id: "8349", i: 2, s: 18, ss: 26 },
    8350: { id: "8350", i: 3, s: 20, ss: 30 },
    8351: { id: "8351", i: 9, s: 10, ss: 27 },
    8353: { id: "8353", i: 7, s: 7, ss: 9 },
    8356: { id: "8356", i: 2, s: 18, ss: 69 },
    8357: { id: "8357", i: 5, s: 30, ss: 73 },
    8360: { id: "8360", i: 2, s: 18, ss: 56 },
    8362: { id: "8362", i: 9, s: 10, ss: 27 },
    8363: { id: "8363", i: 6, s: 12, ss: 15 },
    8365: { id: "8365", i: 3, s: 20, ss: 63 },
    8366: { id: "8366", i: 2, s: 18, ss: 69 },
    8367: { id: "8367", i: 6, s: 8, ss: 23 },
    8368: { id: "8368", i: 6, s: 6, ss: 77 },
    8370: { id: "8370", i: 6, s: 13, ss: 83 },
    8371: { id: "8371", i: 6, s: 8, ss: 23 },
    8372: { id: "8372", i: 5, s: 5, ss: 7 },
    8373: { id: "8373", i: 2, s: 18, ss: 56 },
    8375: { id: "8375", i: 10, s: 11, ss: 20 },
    8377: { id: "8377", i: 6, s: 27, ss: 55 },
    8379: { id: "8379", i: 7, s: 26, ss: 78 },
    8383: { id: "8383", i: 10, s: 17, ss: 25 },
    8385: { id: "8385", i: 6, s: 6, ss: 8 },
    8391: { id: "8391", i: 10, s: 17, ss: 25 },
    8392: { id: "8392", i: 6, s: 13, ss: 83 },
    8395: { id: "8395", i: 6, s: 13, ss: 83 },
    8400: { id: "8400", i: 6, s: 8, ss: 12 },
    8401: { id: "8401", i: 6, s: 6, ss: 44 },
    8402: { id: "8402", i: 2, s: 18, ss: 56 },
    8403: { id: "8403", i: 6, s: 6, ss: 44 },
    8405: { id: "8405", i: 6, s: 12, ss: 35 },
    8406: { id: "8406", i: 6, s: 13, ss: 16 },
    8411: { id: "8411", i: 2, s: 18, ss: 56 },
    8412: { id: "8412", i: 6, s: 8, ss: 23 },
    8413: { id: "8413", i: 9, s: 10, ss: 27 },
    8416: { id: "8416", i: 10, s: 17, ss: 25 },
    8417: { id: "8417", i: 6, s: 12, ss: 15 },
    8418: { id: "8418", i: 6, s: 12, ss: 35 },
    8419: { id: "8419", i: 6, s: 13, ss: 19 },
    8420: { id: "8420", i: 7, s: 7, ss: 22 },
    8422: { id: "8422", i: 2, s: 18, ss: 56 },
    8423: { id: "8423", i: 2, s: 18, ss: 56 },
    8425: { id: "8425", i: 10, s: 11, ss: 17 },
    8426: { id: "8426", i: 2, s: 2, ss: 47 },
    8427: { id: "8427", i: 2, s: 18, ss: 26 },
    8428: { id: "8428", i: 6, s: 8, ss: 23 },
    8429: { id: "8429", i: 6, s: 6, ss: 44 },
    8430: { id: "8430", i: 10, s: 25, ss: 90 },
    8431: { id: "8431", i: 6, s: 8, ss: 86 },
    8432: { id: "8432", i: 6, s: 8, ss: 23 },
    8436: { id: "8436", i: 6, s: 22, ss: 36 },
    8437: { id: "8437", i: 5, s: 30, ss: 73 },
    8439: { id: "8439", i: 3, s: 20, ss: 63 },
    8441: { id: "8441", i: 6, s: 12, ss: 35 },
    8445: { id: "8445", i: 2, s: 18, ss: 56 },
    8446: { id: "8446", i: 6, s: 6, ss: 77 },
    8447: { id: "8447", i: 6, s: 8, ss: 23 },
    8448: { id: "8448", i: 10, s: 17, ss: 25 },
    8450: { id: "8450", i: 10, s: 17, ss: 25 },
    8451: { id: "8451", i: 9, s: 14, ss: 84 },
    8452: { id: "8452", i: 3, s: 20, ss: 63 },
    8455: { id: "8455", i: 2, s: 18, ss: 56 },
    8456: { id: "8456", i: 6, s: 27, ss: 50 },
    8460: { id: "8460", i: 2, s: 18, ss: 56 },
    8462: { id: "8462", i: 6, s: 12, ss: 35 },
    8471: { id: "8471", i: 6, s: 27, ss: 55 },
    8472: { id: "8472", i: 6, s: 12, ss: 35 },
    8473: { id: "8473", i: 6, s: 22, ss: 36 },
    8475: { id: "8475", i: 6, s: 8, ss: 23 },
    8476: { id: "8476", i: 9, s: 10, ss: 27 },
    8479: { id: "8479", i: 11, s: 24, ss: 40 },
    8480: { id: "8480", i: 6, s: 27, ss: 55 },
    8481: { id: "8481", i: 10, s: 17, ss: 25 },
    8482: { id: "8482", i: 10, s: 25, ss: 90 },
    8483: { id: "8483", i: 6, s: 12, ss: 35 },
    8487: { id: "8487", i: 7, s: 26, ss: 48 },
    8489: { id: "8489", i: 10, s: 25, ss: 90 },
    8490: { id: "8490", i: 10, s: 11, ss: 20 },
    8491: { id: "8491", i: 9, s: 10, ss: 27 },
    8493: { id: "8493", i: 6, s: 8, ss: 23 },
    8495: { id: "8495", i: 6, s: 8, ss: 23 },
    8496: { id: "8496", i: 9, s: 10, ss: 27 },
    8500: { id: "8500", i: 6, s: 6, ss: 44 },
    8501: { id: "8501", i: 2, s: 18, ss: 56 },
    8502: { id: "8502", i: 10, s: 25, ss: 53 },
    8506: { id: "8506", i: 10, s: 11, ss: 20 },
    8507: { id: "8507", i: 6, s: 27, ss: 50 },
    8509: { id: "8509", i: 9, s: 10, ss: 61 },
    8510: { id: "8510", i: 6, s: 8, ss: 23 },
    8511: { id: "8511", i: 10, s: 11, ss: 20 },
    8512: { id: "8512", i: 6, s: 13, ss: 83 },
    8513: { id: "8513", i: 5, s: 30, ss: 92 },
    8516: { id: "8516", i: 2, s: 18, ss: 69 },
    8519: { id: "8519", i: 6, s: 8, ss: 23 },
    8521: { id: "8521", i: 6, s: 27, ss: 55 },
    8523: { id: "8523", i: 2, s: 18, ss: 69 },
    8525: { id: "8525", i: 3, s: 20, ss: 38 },
    8526: { id: "8526", i: 2, s: 18, ss: 56 },
    8527: { id: "8527", i: 6, s: 8, ss: 23 },
    8532: { id: "8532", i: 2, s: 18, ss: 56 },
    8535: { id: "8535", i: 2, s: 18, ss: 56 },
    8536: { id: "8536", i: 11, s: 24, ss: 40 },
    8537: { id: "8537", i: 6, s: 27, ss: 70 },
    8540: { id: "8540", i: 3, s: 20, ss: 30 },
    8545: { id: "8545", i: 6, s: 13, ss: 16 },
    8547: { id: "8547", i: 6, s: 13, ss: 83 },
    8551: { id: "8551", i: 6, s: 6, ss: 77 },
    8553: { id: "8553", i: 2, s: 18, ss: 69 },
    8555: { id: "8555", i: 10, s: 11, ss: 20 },
    8557: { id: "8557", i: 8, s: 21, ss: 41 },
    8559: { id: "8559", i: 0, s: 0, ss: 0 },
    8560: { id: "8560", i: 2, s: 18, ss: 56 },
    8562: { id: "8562", i: 6, s: 13, ss: 83 },
    8563: { id: "8563", i: 6, s: 6, ss: 77 },
    8568: { id: "8568", i: 2, s: 18, ss: 56 },
    8570: { id: "8570", i: 6, s: 8, ss: 23 },
    8573: { id: "8573", i: 2, s: 18, ss: 56 },
    8575: { id: "8575", i: 2, s: 18, ss: 26 },
    8579: { id: "8579", i: 2, s: 18, ss: 56 },
    8580: { id: "8580", i: 2, s: 18, ss: 69 },
    8581: { id: "8581", i: 6, s: 6, ss: 77 },
    8582: { id: "8582", i: 6, s: 12, ss: 35 },
    8585: { id: "8585", i: 6, s: 27, ss: 50 },
    8589: { id: "8589", i: 10, s: 17, ss: 82 },
    8590: { id: "8590", i: 6, s: 27, ss: 50 },
    8591: { id: "8591", i: 8, s: 9, ss: 60 },
    8593: { id: "8593", i: 6, s: 12, ss: 35 },
    8595: { id: "8595", i: 3, s: 20, ss: 30 },
    8596: { id: "8596", i: 10, s: 11, ss: 20 },
    8597: { id: "8597", i: 6, s: 27, ss: 70 },
    8599: { id: "8599", i: 6, s: 8, ss: 23 },
    8601: { id: "8601", i: 2, s: 18, ss: 69 },
    8603: { id: "8603", i: 5, s: 30, ss: 73 },
    8606: { id: "8606", i: 7, s: 7, ss: 22 },
    8607: { id: "8607", i: 6, s: 27, ss: 55 },
    8609: { id: "8609", i: 9, s: 15, ss: 34 },
    8611: { id: "8611", i: 7, s: 7, ss: 22 },
    8612: { id: "8612", i: 10, s: 11, ss: 17 },
    8613: { id: "8613", i: 7, s: 7, ss: 52 },
    8616: { id: "8616", i: 2, s: 18, ss: 56 },
    8617: { id: "8617", i: 10, s: 11, ss: 20 },
    8619: { id: "8619", i: 2, s: 18, ss: 56 },
    8620: { id: "8620", i: 10, s: 25, ss: 90 },
    8621: { id: "8621", i: 3, s: 20, ss: 38 },
    8622: { id: "8622", i: 5, s: 5, ss: 94 },
    8623: { id: "8623", i: 10, s: 11, ss: 20 },
    8627: { id: "8627", i: 6, s: 6, ss: 44 },
    8631: { id: "8631", i: 11, s: 24, ss: 40 },
    8635: { id: "8635", i: 7, s: 7, ss: 22 },
    8645: { id: "8645", i: 7, s: 7, ss: 22 },
    8646: { id: "8646", i: 10, s: 11, ss: 20 },
    8657: { id: "8657", i: 3, s: 20, ss: 32 },
    8659: { id: "8659", i: 10, s: 17, ss: 25 },
    8668: { id: "8668", i: 6, s: 8, ss: 33 },
    9001: { id: "9001", i: "", s: "", ss: 85 },
    9010: { id: "9010", i: "", s: "", ss: 85 },
    9011: { id: "9011", i: "", s: "", ss: 85 },
    9022: { id: "9022", i: "", s: "", ss: 85 },
    9031: { id: "9031", i: "", s: "", ss: 85 },
    9047: { id: "9047", i: "", s: "", ss: 85 },
    9060: { id: "9060", i: "", s: "", ss: 85 },
    9067: { id: "9067", i: "", s: "", ss: 85 },
    9069: { id: "9069", i: "", s: "", ss: 85 },
    9072: { id: "9072", i: "", s: "", ss: 85 },
    9074: { id: "9074", i: "", s: "", ss: 85 },
    9077: { id: "9077", i: "", s: "", ss: 85 },
    9078: { id: "9078", i: "", s: "", ss: 85 },
    9081: { id: "9081", i: "", s: "", ss: 85 },
    9085: { id: "9085", i: "", s: "", ss: 85 },
    9086: { id: "9086", i: "", s: "", ss: 85 },
    9088: { id: "9088", i: "", s: "", ss: 85 },
    9091: { id: "9091", i: "", s: "", ss: 85 },
    9096: { id: "9096", i: "", s: "", ss: 85 },
    9101: { id: "9101", i: "", s: "", ss: 85 },
    9115: { id: "9115", i: "", s: "", ss: 85 },
    9125: { id: "9125", i: "", s: "", ss: 85 },
    9126: { id: "9126", i: "", s: "", ss: 85 },
    9140: { id: "9140", i: "", s: "", ss: 85 },
    9141: { id: "9141", i: "", s: "", ss: 85 },
    9151: { id: "9151", i: "", s: "", ss: 85 },
    9167: { id: "9167", i: "", s: "", ss: 85 },
    9169: { id: "9169", i: "", s: "", ss: 85 },
    9170: { id: "9170", i: "", s: "", ss: 85 },
    9173: { id: "9173", i: "", s: "", ss: 85 },
    9181: { id: "9181", i: "", s: "", ss: 85 },
    9186: { id: "9186", i: "", s: "", ss: 85 },
    9187: { id: "9187", i: "", s: "", ss: 85 },
    9191: { id: "9191", i: "", s: "", ss: 85 },
    9600: { id: "9600", i: 7, s: 7, ss: 22 },
    9608: { id: "9608", i: 2, s: 2, ss: 47 },
    9616: { id: "9616", i: 6, s: 12, ss: 15 },
    9618: { id: "9618", i: 7, s: 7, ss: 52 },
    9626: { id: "9626", i: 7, s: 7, ss: 9 },
    9633: { id: "9633", i: 9, s: 10, ss: 66 },
    9666: { id: "9666", i: 2, s: 2, ss: 47 },
    9668: { id: "9668", i: 3, s: 3, ss: 4 },
    9677: { id: "9677", i: 3, s: 3, ss: 4 },
    9688: { id: "9688", i: 5, s: 5, ss: 94 },
    9698: { id: "9698", i: 7, s: 7, ss: 22 },
    9699: { id: "9699", i: 10, s: 25, ss: 90 },
    9801: { id: "9801", i: "", s: "", ss: 85 },
    9803: { id: "9803", i: "", s: "", ss: 85 },
    9804: { id: "9804", i: "", s: "", ss: 85 },
    9805: { id: "9805", i: "", s: "", ss: 85 },
    9806: { id: "9806", i: "", s: "", ss: 85 },
    9807: { id: "9807", i: "", s: "", ss: 85 },
    9809: { id: "9809", i: "", s: "", ss: 85 },
    9810: { id: "9810", i: "", s: "", ss: 85 },
    9812: { id: "9812", i: "", s: "", ss: 85 },
    9813: { id: "9813", i: "", s: "", ss: 85 },
    9814: { id: "9814", i: "", s: "", ss: 85 },
    9817: { id: "9817", i: "", s: "", ss: 85 },
    9820: { id: "9820", i: "", s: "", ss: 85 },
    9826: { id: "9826", i: "", s: "", ss: 85 },
    9829: { id: "9829", i: "", s: "", ss: 85 },
    9834: { id: "9834", i: "", s: "", ss: 85 },
    9836: { id: "9836", i: "", s: "", ss: 85 },
    9839: { id: "9839", i: "", s: "", ss: 85 },
    9845: { id: "9845", i: "", s: "", ss: 85 },
    9846: { id: "9846", i: "", s: "", ss: 85 },
    9858: { id: "9858", i: 9, s: 10, ss: 87 },
    9866: { id: "9866", i: 6, s: 16, ss: 62 },
    9868: { id: "9868", i: 6, s: 16, ss: 62 },
    9869: { id: "9869", i: 6, s: 8, ss: 23 },
    9878: { id: "9878", i: 7, s: 7, ss: 52 },
    9888: { id: "9888", i: 7, s: 7, ss: 52 },
    9889: { id: "9889", i: 3, s: 3, ss: 4 },
    9898: { id: "9898", i: 7, s: 7, ss: 52 },
    9899: { id: "9899", i: 6, s: 6, ss: 77 },
    9900: { id: "9900", i: 2, s: 18, ss: 56 },
    9901: { id: "9901", i: 6, s: 12, ss: 15 },
    9906: { id: "9906", i: 5, s: 30, ss: 73 },
    9908: { id: "9908", i: 1, s: 1, ss: 2 },
    9909: { id: "9909", i: 2, s: 2, ss: 47 },
    9911: { id: "9911", i: 7, s: 7, ss: 52 },
    9913: { id: "9913", i: 2, s: 18, ss: 56 },
    9916: { id: "9916", i: 2, s: 2, ss: 47 },
    9918: { id: "9918", i: 10, s: 11, ss: 43 },
    9919: { id: "9919", i: 6, s: 6, ss: 44 },
    9922: { id: "9922", i: 6, s: 8, ss: 23 },
    9923: { id: "9923", i: 7, s: 7, ss: 52 },
    9926: { id: "9926", i: 5, s: 5, ss: 94 },
    9928: { id: "9928", i: 2, s: 2, ss: 47 },
    9929: { id: "9929", i: 2, s: 18, ss: 56 },
    9933: { id: "9933", i: 8, s: 21, ss: 31 },
    9936: { id: "9936", i: 8, s: 21, ss: 31 },
    9938: { id: "9938", i: 2, s: 18, ss: 56 },
    9939: { id: "9939", i: 5, s: 5, ss: 7 },
    9956: { id: "9956", i: 10, s: 25, ss: 90 },
    9958: { id: "9958", i: 6, s: 6, ss: 77 },
    9959: { id: "9959", i: 7, s: 7, ss: 22 },
    9960: { id: "9960", i: 5, s: 30, ss: 73 },
    9961: { id: "9961", i: 6, s: 8, ss: 33 },
    9963: { id: "9963", i: 7, s: 26, ss: 48 },
    9966: { id: "9966", i: 5, s: 5, ss: 94 },
    9968: { id: "9968", i: 2, s: 2, ss: 3 },
    9969: { id: "9969", i: 5, s: 5, ss: 94 },
    9977: { id: "9977", i: 9, s: 15, ss: 74 },
    9978: { id: "9978", i: 2, s: 2, ss: 80 },
    9979: { id: "9979", i: 2, s: 2, ss: 47 },
    9982: { id: "9982", i: 2, s: 2, ss: 3 },
    9983: { id: "9983", i: 2, s: 2, ss: 47 },
    9986: { id: "9986", i: 6, s: 12, ss: 15 },
    9987: { id: "9987", i: 6, s: 8, ss: 23 },
    9988: { id: "9988", i: 7, s: 7, ss: 52 },
    9989: { id: "9989", i: 5, s: 5, ss: 7 },
    9990: { id: "9990", i: 7, s: 7, ss: 9 },
    9991: { id: "9991", i: 7, s: 7, ss: 52 },
    9992: { id: "9992", i: 6, s: 13, ss: 16 },
    9993: { id: "9993", i: 2, s: 2, ss: 3 },
    9995: { id: "9995", i: 5, s: 5, ss: 94 },
    9996: { id: "9996", i: 5, s: 30, ss: 92 },
    9997: { id: "9997", i: 5, s: 30, ss: 92 },
    9998: { id: "9998", i: 2, s: 18, ss: 56 },
    9999: { id: "9999", i: 7, s: 7, ss: 9 },
  },
  iDict: {
    0: "綜合企業",
    1: "公用事業",
    2: "地產建築業",
    3: "金融業",
    4: "電訊業",
    5: "醫療保健業",
    6: "非必需性消費",
    7: "資訊科技業",
    8: "原材料業",
    9: "必需性消費",
    10: "工業",
    11: "能源業",
  },
  sDict: {
    0: "綜合企業",
    1: "公用事業",
    2: "地產",
    3: "銀行",
    4: "電訊",
    5: "藥品及生物科技",
    6: "媒體及娛樂",
    7: "軟件服務",
    8: "旅遊及消閒設施",
    9: "一般金屬及礦石",
    10: "食物飲品",
    11: "工業工程",
    12: "支援服務",
    13: "家庭電器及用品",
    14: "消費者主要零售商",
    15: "農業產品",
    16: "汽車",
    17: "工用支援",
    18: "建築",
    19: "煤炭",
    20: "其他金融",
    21: "原材料",
    22: "專業零售",
    23: "半導體",
    24: "石油及天然氣",
    25: "工用運輸",
    26: "資訊科技器材",
    27: "紡織及服飾",
    28: "保險",
    29: "黃金及貴金屬",
    30: "醫療保健設備和服務",
  },
  ssDict: {
    0: "綜合企業",
    1: "電力",
    2: "燃氣供應",
    3: "地產發展商",
    4: "銀行",
    5: "地產投資",
    6: "電訊服務",
    7: "藥品",
    8: "出版",
    9: "軟件開發",
    10: "酒店及度假村",
    11: "鋼鐵",
    12: "賭場及博彩",
    13: "食品添加劑",
    14: "航空航天與國防",
    15: "教育",
    16: "玩具及消閒用品",
    17: "重機械",
    18: "超市及便利店",
    19: "消費電子產品",
    20: "工業零件及器材",
    21: "禽畜飼料",
    22: "系統開發及資訊科技顧問",
    23: "餐飲",
    24: "汽車零件",
    25: "印刷及包裝",
    26: "建築材料",
    27: "包裝食品",
    28: "煤炭",
    29: "公共運輸",
    30: "證券及經紀",
    31: "特殊化工用品",
    32: "投資及資產管理",
    33: "旅遊及觀光",
    34: "農產品",
    35: "其他支援服務",
    36: "其他零售商",
    37: "半導體",
    38: "信貸",
    39: "非傳統/ 可再生能源",
    40: "氣油生產商",
    41: "林業及木材",
    42: "鋁",
    43: "電子零件",
    44: "廣告及宣傳",
    45: "商業用車及貨車",
    46: "鐵路及公路",
    47: "物業服務及管理",
    48: "電訊設備",
    49: "其他服飾配件",
    50: "服裝",
    51: "服裝零售商",
    52: "電子商貿及互聯網服務",
    53: "航運及港口",
    54: "汽車零售商",
    55: "紡織品及布料",
    56: "樓宇建造",
    57: "環保工程",
    58: "新能源物料",
    59: "多元化零售商",
    60: "其他金屬及礦物",
    61: "酒精飲料",
    62: "汽車",
    63: "其他金融",
    64: "氣油設備與服務",
    65: "鞋類",
    66: "非酒精飲料",
    67: "保險",
    68: "家庭電器",
    69: "重型基建",
    70: "珠寶鐘錶",
    71: "水務",
    72: "黃金及貴金屬",
    73: "醫療及醫學美容服務",
    74: "禽畜肉類",
    75: "航空服務",
    76: "化肥及農用化合物",
    77: "影視娛樂",
    78: "電腦及週邊器材",
    79: "中醫藥",
    80: "地產代理",
    81: "銅",
    82: "採購及供應鏈管理",
    83: "家具",
    84: "個人護理",
    85: "-",
    86: "消閒及文娛設施",
    87: "乳製品",
    88: "衛星及無線通訊",
    89: "家居裝修零售商",
    90: "航空貨運及物流",
    91: "廣播",
    92: "醫療保健設備",
    93: "紙及紙製品",
    94: "生物技術",
    95: "煙草",
  },
  hsi: [
    "0968",
    "0992",
    "1038",
    "1044",
    "1093",
    "1109",
    "1113",
    "1177",
    "1211",
    "1299",
    "1398",
    "1810",
    "1876",
    "1928",
    "1997",
    "2007",
    "2018",
    "2020",
    "2269",
    "2313",
    "2318",
    "2319",
    "2331",
    "2382",
    "2388",
    "2628",
    "2688",
    "3690",
    "3968",
    "3988",
    "6098",
    "6862",
    "9618",
    "9633",
    "9988",
    "9999",
    "0001",
    "0002",
    "0003",
    "0005",
    "0006",
    "0011",
    "0012",
    "0016",
    "0017",
    "0027",
    "0066",
    "0101",
    "0175",
    "0241",
    "0267",
    "0288",
    "0291",
    "0386",
    "0388",
    "0669",
    "0688",
    "0700",
    "0762",
    "0823",
    "0857",
    "0868",
    "0883",
    "0939",
    "0941",
    "0960",
  ],
  htech: [
    "0020",
    "0241",
    "0268",
    "0285",
    "0522",
    "0700",
    "0772",
    "0909",
    "0981",
    "0992",
    "1024",
    "1347",
    "1810",
    "1833",
    "2015",
    "2018",
    "2382",
    "3690",
    "3888",
    "6060",
    "6618",
    "6690",
    "9618",
    "9626",
    "9698",
    "9868",
    "9888",
    "9961",
    "9988",
    "9999",
  ],
};
