import React, { useEffect } from "react";
import { Table, Loader } from "semantic-ui-react";
import { Link } from "react-router-dom";
import "./TableWidget.css";
import {
  selectAllTopic,
  fetchTopic,
  toIdle,
} from "../../features/topic/topicSlice";

import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

const TableWidget = (props) => {
  const dispatch = useDispatch();
  const topics = useSelector(selectAllTopic);
  const topicStatus = useSelector((state) => state.topic.status);
  const error = useSelector((state) => state.topic.error);

  useEffect(() => {
    if (topicStatus === "idle") {
      if (
        topics.find((item) => item.topicInfo.name === "stockRank") === undefined
      ) {
        dispatch(fetchTopic("stockRank"));
      }
    }
  }, [topicStatus, topics, dispatch]);
  let content;

  if (topicStatus === "loading") {
    content = (
      <div className="table-widget">
        <Loader />
      </div>
    );
  } else if (topicStatus === "succeeded") {
    const data = topics.find((item) => item.topicInfo.name === "stockRank");
    if (data) {
      content = (
        <div>
          <DashTable props={props} data={data} />
        </div>
      );
    } else {
      dispatch(toIdle({}));
    }
  } else if (topicStatus === "error") {
    content = (
      <div>
        <section className="posts-list">
          <div>{error}</div>
        </section>
      </div>
    );
  }
  return <>{content}</>;
};

function DashTable(props) {
  function obj2Array(n) {
    return Object.values(n);
  }
  const tableIndex = props.props.table;
  const dashdata = props.data;
  var data = _.map(dashdata[tableIndex].data, obj2Array);
  var cols = String(props.props.cols).split(",").map(Number);
  var colswidth = String(props.props.colswidth).split(",").map(Number);
  var class_name;
  return (
    <div className="table-widget">
      <Table unstackable compact="very" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              className="table-title"
              colSpan={colswidth.length - 1}
            >
              {props.props.title}
            </Table.HeaderCell>
            <Table.HeaderCell className="table-title-right">
              <Link to={props.props.to}>更多</Link>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Header>
          <Table.Row>
            {cols.map((index, col) => {
              class_name = "table-header";
              if (cols.length - 1 === col) {
                class_name = "table-header-right";
              }
              if (index < dashdata[tableIndex].Header.length) {
                return (
                  <Table.HeaderCell
                    key={index}
                    className={class_name}
                    width={colswidth[col]}
                  >
                    {dashdata[tableIndex].Header[index]}
                  </Table.HeaderCell>
                );
              }
              return <></>;
            })}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {data.slice(0, props.props.maxRow).map((item, row) => {
            return (
              <Table.Row key={row}>
                {cols.slice(0, dashdata[tableIndex].Header.length).map((index, col) => {
                  class_name = "table-body";
                  if (cols.slice(0, dashdata[tableIndex].Header.length).length - 1 === col) {
                    class_name = "table-body-right";
                  }
                  if (index === 0 || index === 1) {
                    return (
                      <Table.Cell key={col} className={class_name}>
                        <Link to={"/stock/" + data[row][0][0]}>
                          {data[row][0][index]}
                        </Link>
                      </Table.Cell>
                    );
                  } else {
                    return (
                      <Table.Cell key={col} className={class_name}>
                        {data[row][0][index]}
                      </Table.Cell>
                    );
                  }
                })}
              </Table.Row>
            );
          })}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.Cell className="table-footer" colSpan={colswidth.length}>
              最後更新時間 :{dashdata[tableIndex].lastUpdate}
            </Table.Cell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </div>
  );
}

// if (topicStatus === "succeeded") {
//   const tableIndex = props.table;
//   const dashdata = topics.find((item) => item.topicInfo.name === "stockRank");
//   if (typeof value === "undefined") {
//     dispatch(toIdle({}));
//   }
//   var data = _.map(dashdata[tableIndex].data, obj2Array);
//   var cols = String(props.cols).split(",").map(Number);
//   var colswidth = String(props.colswidth).split(",").map(Number);
//   var class_name;
//   return (
//     <div className="table-widget">
//       <Table unstackable compact="very" size="small">
//         <Table.Header>
//           <Table.Row>
//             <Table.HeaderCell
//               className="table-title"
//               colSpan={colswidth.length - 1}
//             >
//               {props.title}
//             </Table.HeaderCell>
//             <Table.HeaderCell className="table-title-right">
//               <Link to={props.to}>更多</Link>
//             </Table.HeaderCell>
//           </Table.Row>
//         </Table.Header>
//         <Table.Header>
//           <Table.Row>
//             {cols.map((index, col) => {
//               class_name = "table-header";
//               if (cols.length - 1 === col) {
//                 class_name = "table-header-right";
//               }
//               if (index < dashdata[tableIndex].Header.length) {
//                 return (
//                   <Table.HeaderCell
//                     className={class_name}
//                     width={colswidth[col]}
//                   >
//                     {dashdata[tableIndex].Header[index]}
//                   </Table.HeaderCell>
//                 );
//               }
//               return <></>;
//             })}
//           </Table.Row>
//         </Table.Header>

//         <Table.Body>
//           {data.map((item, row) => {
//             if (row < props.maxRow) {
//               return (
//                 <Table.Row>
//                   {cols.map((index, col) => {
//                     class_name = "table-body";
//                     if (cols.length - 1 === col) {
//                       class_name = "table-body-right";
//                     }
//                     if (index < dashdata[tableIndex].Header.length) {
//                       if (index === 0 || index === 1) {
//                         return (
//                           <Table.Cell className={class_name}>
//                             <Link to={"/stock/" + data[row][0][0]}>
//                               {data[row][0][index]}
//                             </Link>
//                           </Table.Cell>
//                         );
//                       } else {
//                         return (
//                           <Table.Cell className={class_name}>
//                             {data[row][0][index]}
//                           </Table.Cell>
//                         );
//                       }
//                     }
//                     return <></>;
//                   })}
//                 </Table.Row>
//               );
//             }
//             return <></>;
//           })}
//         </Table.Body>
//         <Table.Footer>
//           <Table.Row>
//             <Table.Cell className="table-footer" colSpan={colswidth.length}>
//               最後更新時間 :{dashdata[tableIndex].lastUpdate}
//             </Table.Cell>
//           </Table.Row>
//         </Table.Footer>
//       </Table>
//     </div>
//   );
// } else {
//   return (
//     <div className="table-widget">
//       <Loader />
//     </div>
//   );
// }
// };

export default TableWidget;
