import _ from "lodash";
import React, { useEffect, useState, useReducer } from "react";
import {
  Loader,
  Table,
  Pagination,
  Container,
  Header,
  Dropdown,
  Icon,
  Input,
  Form,
  Checkbox,
  Menu,
  Button,
} from "semantic-ui-react";
// import { createStore, combineReducers } from "redux";
import { isMobile } from "react-device-detect";
// import source from "../../../mock_source";
// import { Link } from "react-router-dom";
// import { formatNumber } from "../../../util/dataFormatter";
// import PriceLine from "../../charts/PriceLine2";
// import Slider from "@material-ui/core/Slider";
import {
  WhatsappShareButton,
  TelegramShareButton,
  FacebookShareButton,
} from "react-share";
import { WhatsappIcon, TelegramIcon, FacebookIcon } from "react-share";

const initialState = {
  // direction: "descending",
  // column: "rs",
  // filterText: { value: "" },
  // filterInd: "所有",
  // filterSector: "",
  // filterSubSector: "",
  // indOption: [],
  // sectorOption: [],
  // activePage: "1",
  activeDate: "",
  displayMode: "0",
  // totalPage: "4",
  // topN: "10",
  // timePeriod: "m1",
  // sliderFilter_p: [0, 100],
  // sliderFilter_hkc: [0, 100],
  // sliderFilter_np: [0, 100],
  // sliderFilter_top10: [0, 100],
  // sliderFilter_nc: [0, 100],
};

function resultTableReducer(state = initialState, action) {
  switch (action.type) {
    case "CHANGE_SORT": {
      if (state.column === action.column) {
        return {
          ...state,
          direction:
            state.direction === "ascending" ? "descending" : "ascending",
          activePage: 1,
        };
      }
      return {
        ...state,
        column: action.column,
        direction: "ascending",
        activePage: 1,
      };
    }
    case "SET_ACTIVE_PAGE": {
      const string = action.text;
      // window.scrollTo(0, 0);
      return {
        ...state,
        activePage: string,
      };
    }
    case "SET_TOPN_DROPDOWN": {
      return {
        ...state,
        topN: action.state,
      };
    }
    case "SET_TIMEPERIOD": {
      return {
        ...state,
        timePeriod: action.state,
      };
    }
    case "SET_ACTIVEDATE": {
      return {
        ...state,
        activeDate: action.state,
      };
    }
    case "TOGGLE_DISPLAY": {
      // console.log("here to set");
      // console.log(action);
      return {
        ...state,
        displayMode: action.text,
      };
    }
    default: {
      return state;
    }
  }
}

function obj2Array(n) {
  return Object.values(n);
}

function CovidTestTable(props) {
  const { topicInfo, data, dict } = props.data; //data
  const header = data.header;
  const [tableData, setTableData] = useState([]);
  var data1 = _.map(data.data, obj2Array);

  useEffect(() => {
    var buffer = data1.map((v) => {
      var d = {};
      let a = _.find(nameDict, function (o) {
        return o.cid === v[0].id;
      });
      return {
        ...d,
        date: dict["Date"][v[0].date],
        dnzh: a["dnzh"],
        dnen: a["dnen"],
        cnzh: a["cnzh"],
        cnen: a["cnen"],
        value: v[0].value,
        vac: _.sum(Object.values(v[0].value)),
      };
    });
    setTableData(buffer);
  }, []);

  let newData = tableData;

  // console.log(newData);

  return (
    <Container
      style={{
        widht: "100%",
        maxWidth: "1000px",
        margin: "auto",
        alignItems: "center",
      }}
    >
      <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
        <Header as="h4" textAlign="right" color="grey">
          {" "}
          數據至 : {topicInfo.lastUpdate} <br />
        </Header>
      </div>
      <div style={{ textAlign: "center", width: isMobile ? "100%" : "100%" }}>
        <SortableTableb data={newData} dict={dict} />
      </div>
    </Container>
  );
}

function SortableTableb(props) {
  const [fontSize, setFontSize] = useState(1);
  const [state, dispatch] = useReducer(resultTableReducer, initialState);
  const {
    // direction,
    // column,
    // activePage,
    // timePeriod,
    // sliderFilter_p,
    displayMode,
    activeDate,
  } = state;
  const { data, dict } = props;
  const dateOption = Object.values(dict.Date);
  const timeOption = Object.values(dict.Time);
  const timeKey = Object.keys(dict.Time);
  useEffect(() => {
    dispatch({ type: "SET_ACTIVEDATE", state: dateOption[0] });
  }, []);

  let newData = data;

  newData = _.filter(newData, function (d) {
    return activeDate !== d["Date"];
  });

  let newData2 = _.filter(newData, function (d) {
    // console.log(d);
    return d["date"] === activeDate;
  });

  if (displayMode) {
    newData2 = _.filter(newData2, function (o) {
      return o.vac > 0;
    });
  }

  // console.log(newData2);

  let totalVac = _.sumBy(newData2, "vac");

  const normalSize = ["0.85rem", "1rem", "1.3rem"];
  const smallSize = ["0.85rem", "0.85rem", "0.85rem"];

  const timeHeaderStyle = {
    position: "sticky",
    top: "0px",
    zIndex: "10",
    backgroundColor: "rgb(240,240,240)",
    fontSize: normalSize[fontSize],
  };
  const placeHeaderStyle = {
    position: "sticky",
    left: "0px",
    zIndex: "5",
    backgroundColor: "rgb(240,240,240)",
    fontSize: normalSize[fontSize],
  };
  const contentStyle = {
    fontSize: normalSize[fontSize],
    zIndex: "0",
  };
  const districtLabelStyle = {
    fontSize: smallSize[fontSize],
    color: "grey",
  };

  const url = "https://daashk.com/topics/covidtest";
  const title = "瞬間看清社區檢測中心預約情況";
  const size = "2.5rem";

  // console.log("dispaly mode");
  // console.log(displayMode);
  // console.log("totalVac");
  // console.log(totalVac);

  return (
    <div
      style={{
        whiteSpace: "nowrap",
        overflowX: "hidden",
        display: "inline-block",
        width: "100%",
        textAlign: "center",
      }}
    >
      <Button.Group>
        <Button
          style={{ width: "60pt", fontSize: "0.8rem" }}
          onClick={() => setFontSize(0)}
          color={fontSize === 0 ? "green" : "darkgreen"}
        >
          A
        </Button>
        <Button
          style={{ width: "60pt", fontSize: "1.0rem" }}
          onClick={() => setFontSize(1)}
          color={fontSize === 1 ? "green" : "darkgreen"}
        >
          A
        </Button>
        <Button
          style={{ width: "60pt", fontSize: "1.2rem" }}
          onClick={() => setFontSize(2)}
          color={fontSize === 2 ? "green" : "darkgreen"}
        >
          A
        </Button>
      </Button.Group>
      <div style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <Form>
          <Form.Field inline>
            <label>顯示： </label>
            <Checkbox
              label="只顯示尚能預約之中心"
              style={{ paddingRight: "10px" }}
              checked={displayMode}
              onChange={() => {
                dispatch({ type: "TOGGLE_DISPLAY", text: !displayMode });
              }}
            />
          </Form.Field>
        </Form>
      </div>
      <div style={{ textAlign: "left", overflowX: "auto" }}>
        <Menu pointing secondary compact>
          <Menu.Item header>日期:</Menu.Item>
          {dateOption.map((d, index) => (
            <Menu.Item
              name={d}
              active={activeDate === d}
              onClick={() => {
                dispatch({ type: "SET_ACTIVEDATE", state: d });
              }}
            >
              {d.slice(5, 7) + "/" + d.slice(8, 10)}
            </Menu.Item>
          ))}
        </Menu>
      </div>
      <div
        style={{
          overflow: "auto",
          maxHeight: "600px",
          overscrollBehavior: "auto",
          minHeight: "50vh",
        }}
      >
        <Table sortable selectable celled unstackable compact>
          <Table.Header>
            <Table.HeaderCell
              style={{
                position: "sticky",
                top: "0px",
                left: "0px",
                zIndex: "15",
                backgroundColor: "rgb(240,240,240)",
              }}
              textAlign="left"
              rowSpan="2"
              // sorted={column === "id" ? direction : null}
              // onClick={() => {
              //   dispatch({ type: "CHANGE_SORT", column: "id" });
              // }}
            >
              名稱
            </Table.HeaderCell>
            {timeKey.map((t, index) => (
              <Table.HeaderCell
                style={timeHeaderStyle}
                textAlign="center"
                rowSpan="2"
              >
                {/* <div
                  onClick={() => {
                    dispatch({ type: "CHANGE_SORT", column: "C" });
                  }}
                > */}
                {dict.Time[t]}
                {/* </div> */}
              </Table.HeaderCell>
            ))}
          </Table.Header>
          <Table.Body>
            {newData2.length === 0 ? (
              <Table.Row key={"empty"}>
                <Table.Cell colSpan={20}>
                  <Header as="h2"> {activeDate} 當日全部中心預約巳滿</Header>
                </Table.Cell>
              </Table.Row>
            ) : (
              newData2.map((d, index) => {
                let name = d.cnzh;
                const pos = name.search(/\(/);
                if (pos >= 0) {
                  name = name.slice(0, pos);
                }
                if (isMobile) {
                  if (name.length > 10) {
                    name = (
                      <>
                        {name.slice(0, name.length / 3)}
                        <br />
                        {name.slice(name.length / 3, (name.length * 2) / 3)}
                        <br />
                        {name.slice((name.length * 2) / 3)}
                      </>
                    );
                  } else {
                    name = (
                      <>
                        {name.slice(0, name.length / 2)}
                        <br />
                        {name.slice(name.length / 2)}
                      </>
                    );
                  }
                } else {
                  name = (
                    <>{name.length > 10 ? name.slice(0, 9) + "..." : name}</>
                  );
                }
                return (
                  <>
                    <Table.Row key={d.id}>
                      <Table.Cell style={placeHeaderStyle} textAlign="left">
                        {name}
                        <br />
                        <span style={districtLabelStyle}>{d.dnzh}</span>
                        <span style={{ color: d.vac > 0 ? "green" : "red" }}>
                          {" "}
                          ({d.vac}){" "}
                        </span>
                      </Table.Cell>
                      {timeKey.map((t, index) => (
                        <Table.Cell style={contentStyle} textAlign="center">
                          {d.value[t] ? (
                            <Icon name="circle thin" color="green" />
                          ) : (
                            // d.value[t]
                            "-"
                          )}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  </>
                );
              })
            )}
          </Table.Body>
        </Table>
      </div>
      <div
        style={{
          float: isMobile ? false : "right",
          textAlign: "right",
          paddingTop: "15px",
        }}
      >
        <WhatsappShareButton url={url} title={title} separator=": ">
          <WhatsappIcon size={size} round />
        </WhatsappShareButton>
        <TelegramShareButton url={url} title={title}>
          <TelegramIcon size={size} round />
        </TelegramShareButton>

        <FacebookShareButton url={url} quote={title} description={title}>
          <FacebookIcon size={size} round />
        </FacebookShareButton>
      </div>
      <br />
      <div style={{ textAlign: "left" }}>
        *(括號內數字為該中心尚能預約之時間數目)
        <br />
        SOURCE：
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.communitytest.gov.hk/zh-HK/"
        >
          https://www.communitytest.gov.hk/zh-HK/
        </a>
        <br />
        DISCLAIMER：一切以官方數據為準
      </div>
      {/* </div> */}
    </div>
  );
}

const nameDict = [
  {
    did: "15",
    dnzh: "中西區",
    dnen: "Central and Western",
    cid: "15",
    cnzh: "石塘咀體育館",
    cnen: "Shek Tong Tsui Sports Centre",
  },
  {
    did: "16",
    dnzh: "東區",
    dnen: "Eastern",
    cid: "1",
    cnzh: "鰂魚涌社區會堂",
    cnen: "Quarry Bay Community Hall",
  },
  {
    did: "17",
    dnzh: "南區",
    dnen: "Southern",
    cid: "20",
    cnzh: "利東社區會堂",
    cnen: "Lei Tung Community Hall",
  },
  {
    did: "18",
    dnzh: "灣仔區",
    dnen: "Wan Chai",
    cid: "10",
    cnzh: "禮頓山社區會堂",
    cnen: "Leighton Hill Community Hall",
  },
  {
    did: "10",
    dnzh: "九龍城區",
    dnen: "Kowloon City",
    cid: "16",
    cnzh: "土瓜灣體育館",
    cnen: "To Kwa Wan Sports Centre",
  },
  {
    did: "11",
    dnzh: "觀塘區",
    dnen: "Kwun Tong",
    cid: "5",
    cnzh: "油塘社區會堂",
    cnen: "Yau Tong Community Hall",
  },
  {
    did: "11",
    dnzh: "觀塘區",
    dnen: "Kwun Tong",
    cid: "17",
    cnzh: "牛頭角道體育館",
    cnen: "Ngau Tau Kok Road Sports Centre",
  },
  {
    did: "12",
    dnzh: "深水埗區",
    dnen: "Sham Shui Po",
    cid: "11",
    cnzh: "白田社區會堂 (白雲街白田社區綜合大樓地下)",
    cnen: "Pak Tin Community Hall (G/F Pak Tin Community Complex at Pak Wan Street)",
  },
  {
    did: "13",
    dnzh: "黃大仙區",
    dnen: "Wong Tai Sin",
    cid: "22",
    cnzh: "東頭社區中心",
    cnen: "Tung Tau Community Centre",
  },
  {
    did: "14",
    dnzh: "油尖旺區",
    dnen: "Yau Tsim Mong",
    cid: "2",
    cnzh: "梁顯利油麻地社區中心",
    cnen: "Henry G. Leong Yau Ma Tei Community Centre",
  },
  {
    did: "1",
    dnzh: "離島區",
    dnen: "Islands",
    cid: "23",
    cnzh: "香港國際機場地面運輸候車室",
    cnen: "Ground Transportation Lounge, Hong Kong International Airport",
  },
  {
    did: "2",
    dnzh: "葵青區",
    dnen: "Kwai Tsing",
    cid: "9",
    cnzh: "荔景社區會堂",
    cnen: "Lai King Community Hall",
  },
  {
    did: "3",
    dnzh: "北區",
    dnen: "North",
    cid: "7",
    cnzh: "和興社區會堂",
    cnen: "Wo Hing Community Hall",
  },
  {
    did: "4",
    dnzh: "西貢區",
    dnen: "Sai Kung",
    cid: "6",
    cnzh: "坑口社區會堂",
    cnen: "Hang Hau Community Hall",
  },
  {
    did: "5",
    dnzh: "沙田區",
    dnen: "Sha Tin",
    cid: "3",
    cnzh: "瀝源社區會堂",
    cnen: "Lek Yuen Community Hall",
  },
  {
    did: "6",
    dnzh: "大埔區",
    dnen: "Tai Po",
    cid: "14",
    cnzh: "太和體育館",
    cnen: "Tai Wo Sports Centre",
  },
  {
    did: "7",
    dnzh: "荃灣區",
    dnen: "Tsuen Wan",
    cid: "13",
    cnzh: "蕙荃體育館",
    cnen: "Wai Tsuen Sports Centre",
  },
  {
    did: "8",
    dnzh: "屯門區",
    dnen: "Tuen Mun",
    cid: "8",
    cnzh: "兆麟社區會堂",
    cnen: "Siu Lun Community Hall",
  },
  {
    did: "9",
    dnzh: "元朗區",
    dnen: "Yuen Long",
    cid: "4",
    cnzh: "元朗市東社區會堂",
    cnen: "Yuen Long Town East Community Hall",
  },
];
export default CovidTestTable;
