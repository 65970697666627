import { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import TransportRouteTable from './components/TransportRouteTable'
import RouteStopTable from './components/RouteStopTable'
import MetaTags from 'react-meta-tags';
import RouteShortCut from './components/RouteShortCut';
import { ETAFavourites } from './ETAFavourite';
import useWindowSize from '../../../util/useWidowSize';
import SearchRouteTable from './components/SearchRouteTable';
import DisplayAdsBanner from '../../ads/DisplayAdsBanner';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    width: '100%',
    minHeight: '75vh',
    border: 0,
    boxShadow: 0,
  },
  tab: {
      minWidth: 60, // a number of your choice
      width: 60, // a number of your choice
  }
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export default function ETAHome() {
  const classes = useStyles();

  const { height, width } = useWindowSize();
  const [ etaViewState, setETAViewState ] = useState(
    {
      transport_name: "FAV",
      route_code: "",
      route_name: "",
      route_bound: "",
      route_service_type: "",
      favourites: [],
    }
  )

  useEffect(() => {
    let etaFavourites = new ETAFavourites();
    setETAViewState({
      ...etaViewState,
      favourites: etaFavourites.favourites,
    })
  }, []);

  const onStateChange = (newState) => {
    setETAViewState(newState)
  };

  let shortCut = <RouteShortCut state={etaViewState} onStateChange={(state) => onStateChange(state)}></RouteShortCut>;
  if (width < 800) {
    shortCut =
      <Container maxWidth="md">
        { shortCut }
      </Container>;
  }

  const tabList = [
    {
      transport_name: "FAV",
      display_name: "收藏",
      component: <>
        { <SearchRouteTable  type="SRCH" state={etaViewState} onStateChange={(state) => onStateChange(state)}></SearchRouteTable> }
        { <div style={{marginBottom: 30}}></div> }
        { <RouteStopTable state={etaViewState} onStateChange={(state) => onStateChange(state)}></RouteStopTable> }
        { shortCut } 
        { <div style={{marginBottom: 30}}></div> }
        { etaViewState.favourites.length === 0 && <RouteStopTable type="NEAR" state={etaViewState} onStateChange={(state) => onStateChange(state)}></RouteStopTable>}
      </>,
    },
    {
      transport_name: "NEAR",
      display_name: "附近",
      component: <RouteStopTable state={etaViewState} onStateChange={(state) => onStateChange(state)}></RouteStopTable>,
    },
    {
      transport_name: "SRCH",
      display_name: "搜尋",
      component: <SearchRouteTable state={etaViewState} onStateChange={(state) => onStateChange(state)}></SearchRouteTable>,
    },
    {
      transport_name: "KMB",
      display_name: "九巴",
      component: <TransportRouteTable state={etaViewState} onStateChange={(state) => onStateChange(state)}></TransportRouteTable>,
    },
    {
      transport_name: "NWFB",
      display_name: "新巴",
      component: <TransportRouteTable state={etaViewState} onStateChange={(state) => onStateChange(state)}></TransportRouteTable>,
    },
    {
      transport_name: "CTB",
      display_name: "城巴",
      component: <TransportRouteTable state={etaViewState} onStateChange={(state) => onStateChange(state)}></TransportRouteTable>,
    },
    {
      transport_name: "MTR",
      display_name: "港鐵",
      component: <TransportRouteTable state={etaViewState} onStateChange={(state) => onStateChange(state)}></TransportRouteTable>,
    },
    {
      transport_name: "LR",
      display_name: "輕鐵",
      component: <TransportRouteTable state={etaViewState} onStateChange={(state) => onStateChange(state)}></TransportRouteTable>,
    },
    {
      transport_name: "GMB",
      display_name: "小巴",
      component: <TransportRouteTable state={etaViewState} onStateChange={(state) => onStateChange(state)}></TransportRouteTable>,
    },
    {
      transport_name: "MTRB",
      display_name: "港鐵巴士",
      component: <TransportRouteTable state={etaViewState} onStateChange={(state) => onStateChange(state)}></TransportRouteTable>,
    },
    {
      transport_name: "NLB",
      display_name: "新大嶼山巴士",
      component: <TransportRouteTable state={etaViewState} onStateChange={(state) => onStateChange(state)}></TransportRouteTable>,
    },
  ]

  const handleChange = (event, newValue) => {
      setETAViewState({
        ...etaViewState,
        transport_name: tabList[newValue].transport_name,
        route_code: "",
        route_name: "",
        route_bound: "",
        route_service_type: "",
      })
  };

  var transport_name_map = {};
  for (let item in tabList) {
    transport_name_map[tabList[item].transport_name] = parseInt(item);
  }
  const tabValue = transport_name_map[etaViewState.transport_name];

  var content = <Paper elevation={0} square className={classes.root}>
    <Tabs
      value={tabValue}
      onChange={handleChange}
      variant="scrollable"
      scrollButtons="on"
      indicatorColor="primary"
      textColor="secondary"
      aria-label="scrollable auto tabs example"
    >
      {tabList.map((item, index) => (
        <Tab key={index} className={classes.tab} label={item.display_name} {...a11yProps(index)} />
      ))}
    </Tabs>
    {tabList.map((item, index) => (
      <TabPanel key={index} value={tabValue} index={index}>
        {item.component}
      </TabPanel>
    ))}
  </Paper>;

  if (width >= 800) {
    content =
      <Container maxWidth="md">
        <DisplayAdsBanner></DisplayAdsBanner>
        {content}
        <DisplayAdsBanner></DisplayAdsBanner>
      </Container>;
  }
  return (
    <>
      <MetaTags>
        <title>交通工具到站預報</title>
        <meta name="description" content="交通工具到站預報" />
        <meta property="og:title" content="交通工具到站預報" />
        <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
      </MetaTags>
      <div style={{marginBottom: 40}}>
        { content }
      </div>
    </>
  );
}