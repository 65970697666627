import './ShareholdingChangeDev.css';
import React, { useState } from 'react';
import MetaTags from 'react-meta-tags';
import { Header, Divider, Menu } from 'semantic-ui-react'
import ShareholdingChangePerDayContainer from './ShareholdingChangePerDayContainer';
import ShareholdingChangeContainer from './ShareholdingChangeContainer';

export default function ShareholdingChangeDev() {

  const [ activeItem, setActiveItem ] = useState("每日持倉變動");

  const handleItemClick = (event, data) => {
    setActiveItem(data.name);
  }

  return (
    <>
      <MetaTags>
        <title>持倉異動</title>
        <meta name="description" content= "持倉異動" />
        <meta property="og:title" content= "持倉異動" />
        <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
      </MetaTags>
      
      <Divider horizontal>
        <Header as='h1'>
          持倉異動
        </Header>
      </Divider>
      <div className='tabDiv'>
        <div style={{ display: 'inline-block' }} >
          <Menu compact pointing secondary size='large' style={{margin:'auto'}}>
            <Menu.Item
              name="每日持倉變動"
              active={activeItem === "每日持倉變動"}
              onClick={handleItemClick}
            >
            每日持倉變動
            </Menu.Item>
            <Menu.Item
              name="長期持倉變動"
              active={activeItem === "長期持倉變動"}
              onClick={handleItemClick}
            >
            長期持倉變動
            </Menu.Item>
          </Menu>
        </div>
      </div>
      { activeItem === "每日持倉變動" && <ShareholdingChangePerDayContainer /> }
      { activeItem === "長期持倉變動" && <ShareholdingChangeContainer /> }
    </>
  );
}