import React, { useEffect, useState } from "react";
import { Container, Header, Loader } from "semantic-ui-react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import MetaTags from "react-meta-tags";
import axios from "axios";
import DisplayAdsBanner from "../ads/DisplayAdsBanner";
import GoogleAd from "../ads/Google_ad";
import _ from "lodash";

function zip(arrays) {
  return Array.apply(null, Array(arrays[0].length)).map(function (_, i) {
    return arrays.map(function (array) {
      return array[i];
    });
  });
}

const TopicMonetary = () => (
  <>
    <MetaTags>
      <title>每日金融數據</title>
      <meta name="description" content="每日金融數據" />
      <meta property="og:title" content="每日金融數據" />
      <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
    </MetaTags>
    <Container textAlign="center">
      <DisplayAdsBanner />
    </Container>
    <Container
      style={{
        paddingTop: "25px",
        paddingBottom: "25px",
        minHeight: "75vh",
      }}
    >
      <div style={{ textAlign: "center", paddingTop: "25px" }}>
        <Header as="h1">每日金融數據 </Header>
      </div>

      <MoneyChartContainer />
    </Container>
    <Container
      textAlign="center"
      style={{ paddingTop: "30px", paddingBottom: "0px" }}
    >
      <GoogleAd
        client="ca-pub-8753358837449417"
        slot="2486257452"
        format="auto"
        wrapperDivStyle={{
          marginTop: "30px",
          marginBottom: "20px",
        }}
      />
    </Container>
  </>
);

export default TopicMonetary;

function MoneyChartContainer() {
  const [data, setData] = useState(null);
  console.log(data);

  useEffect(() => {
    axios
      .get(
        `https://api.hkma.gov.hk/public/market-data-and-statistics/daily-monetary-statistics/daily-figures-interbank-liquidity?pagesize=1000`
      )
      .then((response) => setData(response.data));
  }, []);

  // `https://api.hkma.gov.hk/public/market-data-and-statistics/daily-monetary-statistics/daily-figures-interbank-liquidity?pagesize=1000

  return (
    <div>
      {data !== null ? (
        <ChartView data={data} />
      ) : (
        <Loader active inline="centered">
          {" "}
          Loading
        </Loader>
      )}
    </div>
  );
}

function ChartView(data) {
  const chartData1 = data.data.result.records;
  // const [dataSet, setDataSet] = useState({});
  var end_of_date = [],
    hibor_fixing_1m = [],
    closing_balance = [],
    disc_win_base_rate = [],
    balanceChange = [],
    i = 0;
  let chartData = _.sortBy(chartData1, [
    function (o) {
      return o.end_of_date;
    },
  ]);
  // console.log(sChartData);
  for (i; i < chartData.length; i += 1) {
    let d = chartData[i];
    end_of_date.push(d["end_of_date"]);
    if (d["hibor_fixing_1m"] < 100) {
      hibor_fixing_1m.push(d["hibor_fixing_1m"]);
    } else {
      hibor_fixing_1m.push(null);
    }
    closing_balance.push(d["closing_balance"] / 100);
    balanceChange.push((d["closing_balance"] - d["opening_balance"]) / 100);
    disc_win_base_rate.push(d["disc_win_base_rate"]);
  }

  const lastUpdate = chartData[chartData.length - 1].end_of_date;

  let dates = end_of_date.map((item) => new Date(item).getTime());

  // console.log(dates);
  // console.log(closing_balance);

  return (
    <>
      <Header as="h5" textAlign="right" color="grey">
        {" "}
        數據截至 {lastUpdate} <br />
        數據來源:
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={
            "https://www.hkma.gov.hk/chi/data-publications-and-research/data-and-statistics/daily-monetary-statistics/"
          }
        >
          HKMA
        </a>
      </Header>
      <div style={{ paddingTop: "50px" }}>
        <Header as="h2">
          銀行體系總結餘
          <Header.Subheader>*單位： 港元</Header.Subheader>
        </Header>
      </div>
      <MoneyChart
        dates={dates}
        // hibor_fixing_1m={hibor_fixing_1m}
        closing_balance={closing_balance}
        balanceChange={balanceChange}
      />

      <Container textAlign="center">
        <DisplayAdsBanner />
      </Container>

      <div style={{ paddingTop: "50px" }}>
        <Header as="h2">香港銀行公會一個月期港元銀行同業拆息</Header>
      </div>
      <HiborChart
        dates={dates}
        hibor_fixing_1m={hibor_fixing_1m}
        // closing_balance={closing_balance}
        // balanceChange={balanceChange}
      />

      <Container textAlign="center">
        <DisplayAdsBanner />
      </Container>

      <div style={{ paddingTop: "50px" }}>
        <Header as="h2">貼現窗基本利率</Header>
      </div>
      <BaseRateChart dates={dates} disc_win_base_rate={disc_win_base_rate} />
    </>
  );
}

function MoneyChart(props) {
  const { dates, hibor_fixing_1m, closing_balance, balanceChange } = props;
  let chartOption = {};
  chartOption = {
    rangeSelector: {
      selected: 1,
    },

    navigator: {
      series: {
        lineWidth: 1,
        lineColor: "#2F4F4F",
        color: "#2F4F4F",
        fillOpacity: 0.2,
      },
    },

    legend: {
      enabled: true,
      align: "center",
      verticalAlign: "bottom",
      x: 0,
      y: 0,
    },

    chart: {
      height: 700,
      // marginTop: 10,
      // marginBottom: 0,
      marginLeft: 10,
      marginRight: 10,
    },

    // yAxis: [
    //   {
    //     // Primary yAxis
    //     labels: {
    //       style: {
    //         color: "black",
    //       },
    //       //align: 'left',
    //       align: "right",
    //       x: -2,
    //     },
    //     title: {
    //       style: {
    //         color: Highcharts.getOptions().colors[1],
    //       },
    //     },
    //     opposite: true,
    //     // startOnTick: false,
    //     endOnTick: false,
    //   },
    //   {
    //     opposite: false,
    //     min: 0,
    //     labels: {
    //       style: {
    //         color: "black",
    //       },
    //       align: "left",
    //       x: 3.5,
    //     },
    //     title: {
    //       text: "",
    //     },
    //     top: "75%",
    //     height: "25%",
    //     offset: 0,
    //     gridLineWidth: 0,
    //     // lineWidth: 2
    //   },
    // ],

    // yAxis: [
    //   {
    //     labels: {
    //       style: {
    //         color: "black",
    //       },
    //       //align: 'left',
    //       align: "right",
    //       x: -2,
    //     },
    //     title: {
    //       style: {
    //         color: Highcharts.getOptions().colors[1],
    //       },
    //     },
    //     opposite: true,
    //     // startOnTick: false,
    //     endOnTick: false,
    //     height: "80%",
    //   },
    //   {
    //     labels: {
    //       style: {
    //         color: "black",
    //       },
    //       align: "left",
    //       x: -2,
    //     },
    //     title: {
    //       text: "",
    //     },
    //     top: "80%",
    //     height: "20%",
    //     offset: 0,
    //   },
    // ],

    yAxis: [
      {
        labels: {
          formatter: function () {
            return this.axis.defaultLabelFormatter.call(this) + "億";
          },
          style: {
            color: "black",
          },
          align: "right",
          x: -2,
        },
        height: "80%",
        title: {
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        opposite: true,
        endOnTick: false,
      },
      {
        labels: {
          formatter: function () {
            return this.axis.defaultLabelFormatter.call(this) + "億";
          },
          style: {
            color: "black",
          },
          align: "right",
          x: -2,
        },
        title: {
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        opposite: true,
        top: "80%",
        height: "20%",
        offset: 0,
      },
    ],

    tooltip: {
      xDateFormat: "%Y/%m/%d",
      shared: true,
      split: false,
      enabled: true,
      distance: 50,
      valueDecimals: 1,
      formatter: function () {
        return this.points.reduce(function (s, point) {
          return s + "<br/>" + point.series.name + ": " + point.y + "億";
        }, "<b>" + Highcharts.dateFormat("%Y/%m/%d", this.x) + "</b>");
      },
    },

    scrollbar: {
      enabled: true,
    },

    series: [
      {
        type: "line",
        id: "Aggregate Balance",
        name: "銀行體系總結餘",
        data: zip([dates, closing_balance]),
      },
      {
        type: "column",
        id: "Aggregate Balance Change",
        name: "變化",
        data: zip([dates, balanceChange]),
        yAxis: 1,
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 800,
          },
          chartOptions: {
            rangeSelector: {
              inputEnabled: false,
            },
          },
        },
      ],
    },
    credits: {
      text: "DaaSHK",
      style: {
        fontSize: "0.8em",
      },
    },
  };

  return (
    <div>
      <HighchartsReact
        constructorType={"stockChart"}
        highcharts={Highcharts}
        options={chartOption}
      />
    </div>
  );
}

function HiborChart(props) {
  const { dates, hibor_fixing_1m } = props;
  let chartOption = {};
  chartOption = {
    rangeSelector: {
      selected: 1,
    },

    navigator: {
      series: {
        lineWidth: 1,
        lineColor: "#2F4F4F",
        color: "#2F4F4F",
        fillOpacity: 0.2,
      },
    },

    legend: {
      enabled: true,
      align: "center",
      verticalAlign: "bottom",
      x: 0,
      y: 0,
    },

    chart: {
      height: 700,
      // marginTop: 10,
      // marginBottom: 0,
      marginLeft: 10,
      marginRight: 10,
    },

    yAxis: [
      {
        labels: {
          formatter: function () {
            return this.axis.defaultLabelFormatter.call(this) + "%";
          },
          style: {
            color: "black",
          },
          align: "right",
          x: -2,
        },
        // height: "80%",
        title: {
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        opposite: true,
        // endOnTick: false,
      },
    ],

    tooltip: {
      xDateFormat: "%Y/%m/%d",
      shared: true,
      split: false,
      enabled: true,
      distance: 50,
      valueDecimals: 1,
      formatter: function () {
        return this.points.reduce(function (s, point) {
          return s + "<br/>" + point.series.name + ": " + point.y + "%";
        }, "<b>" + Highcharts.dateFormat("%Y/%m/%d", this.x) + "</b>");
      },
    },

    scrollbar: {
      enabled: true,
    },

    series: [
      {
        type: "line",
        id: "HIBOR",
        name: "1-Month HIBOR",
        data: zip([dates, hibor_fixing_1m]),
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 800,
          },
          chartOptions: {
            rangeSelector: {
              inputEnabled: false,
            },
          },
        },
      ],
    },
    credits: {
      text: "DaaSHK",
      style: {
        fontSize: "0.8em",
      },
    },
  };

  return (
    <div>
      <HighchartsReact
        constructorType={"stockChart"}
        highcharts={Highcharts}
        options={chartOption}
      />
    </div>
  );
}

function BaseRateChart(props) {
  const { dates, disc_win_base_rate } = props;
  let chartOption = {};
  chartOption = {
    rangeSelector: {
      selected: 1,
    },

    navigator: {
      series: {
        lineWidth: 1,
        lineColor: "#2F4F4F",
        color: "#2F4F4F",
        fillOpacity: 0.2,
      },
    },

    legend: {
      enabled: true,
      align: "center",
      verticalAlign: "bottom",
      x: 0,
      y: 0,
    },

    chart: {
      height: 700,
      // marginTop: 10,
      // marginBottom: 0,
      marginLeft: 10,
      marginRight: 10,
    },

    yAxis: [
      {
        labels: {
          formatter: function () {
            return this.axis.defaultLabelFormatter.call(this) + "%";
          },
          style: {
            color: "black",
          },
          align: "right",
          x: -2,
        },
        // height: "80%",
        title: {
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        opposite: true,
        // endOnTick: false,
      },
    ],

    tooltip: {
      xDateFormat: "%Y/%m/%d",
      shared: true,
      split: false,
      enabled: true,
      distance: 50,
      valueDecimals: 1,
      formatter: function () {
        return this.points.reduce(function (s, point) {
          return s + "<br/>" + point.series.name + ": " + point.y + "%";
        }, "<b>" + Highcharts.dateFormat("%Y/%m/%d", this.x) + "</b>");
      },
    },

    scrollbar: {
      enabled: true,
    },

    series: [
      {
        type: "line",
        id: "Base Rate",
        name: "貼現窗基本利率",
        data: zip([dates, disc_win_base_rate]),
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 800,
          },
          chartOptions: {
            rangeSelector: {
              inputEnabled: false,
            },
          },
        },
      ],
    },
    credits: {
      text: "DaaSHK",
      style: {
        fontSize: "0.8em",
      },
    },
  };

  return (
    <div>
      <HighchartsReact
        constructorType={"stockChart"}
        highcharts={Highcharts}
        options={chartOption}
      />
    </div>
  );
}
