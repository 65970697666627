import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux'

import RouteTable from './RouteTable';
import RouteStopTable from './RouteStopTable';
import { useFetchData } from '../lib/ETAUtility';

export default function TransportRouteTabl(props) {
  const dispatch = useDispatch()

  const [ fetchData, dataStatus, routeData ] = useFetchData(props.state);

  const state = props.state;
  const setState = props.onStateChange;

  useEffect(() => {
    if (dataStatus === 'idle') {
      dispatch(fetchData());
    } else if (dataStatus === 'succeeded') {
    }
  }, [dataStatus, dispatch]);

  const show_route_stop = state.route_code && state.route_name && state.route_bound;
  return (
    <>
      { show_route_stop &&
        <>
          <RouteStopTable state={state} onStateChange={(state) => setState(state)}></RouteStopTable> 
        </>
      }
      { !show_route_stop &&
        <>
          <RouteTable loading={dataStatus === 'idle' || dataStatus === 'loading'} state={state} onStateChange={(state) => setState(state)} routes={ routeData.route.data }></RouteTable>
        </>
      }
    </>
  );
}
