import React, { useEffect } from "react";
import { Loader } from "semantic-ui-react";
import _ from "lodash";
import {
  selectAllTopic,
  fetchTopic,
  toIdle,
} from "../../../features/topic/topicSlice";
import { useSelector, useDispatch } from "react-redux";
import CovidTestTable from "./CovidTestTable";

// function CovidTestView() {
//   return (
//     <div>
//       <CovidTestTable data={tableData} />
//     </div>
//   );
// }

export const CovidTestView = () => {
  const dispatch = useDispatch();
  const topics = useSelector(selectAllTopic);
  const topicStatus = useSelector((state) => state.topic.status);
  const error = useSelector((state) => state.topic.error);
  useEffect(() => {
    if (topicStatus === "idle") {
      if (
        topics.find((item) => item.topicInfo.name === "covidTest") === undefined
      ) {
        dispatch(fetchTopic("covidTest"));
      }
    }
  }, [topicStatus, topics, dispatch]);
  let content;

  if (topicStatus === "loading") {
    content = (
      <div>
        <section className="posts-list">
          <div
            style={{
              padding: "100px",
              margin: "auto",
              alignItems: "center",
              height: "75vh",
            }}
          >
            <Loader active inline="centered">
              {" "}
              Loading
            </Loader>
          </div>
        </section>
      </div>
    );
  } else if (topicStatus === "succeeded") {
    const data = topics.find((item) => item.topicInfo.name === "covidTest");
    if (data) {
      content = (
        <div>
          <CovidTestTable data={data} />
        </div>
      );
    } else {
      dispatch(toIdle({}));
    }
  } else if (topicStatus === "error") {
    content = (
      <div>
        <section className="posts-list">
          <div>{error}</div>
        </section>
      </div>
    );
  }
  return <>{content}</>;
};

export default CovidTestView;
