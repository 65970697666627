import React from "react";
import MetaTags from "react-meta-tags";
import BuyBackTable from "../tables/BuyBackTable";
import DisplayAdsBanner from "../ads/DisplayAdsBanner";
import GoogleAd from "../ads/Google_ad";
import { Container, Header } from "semantic-ui-react";

export const TopicBuyBack = () => {
  return (
    <>
      <MetaTags>
        <title>股份回購</title>
        <meta name="description" content="股份回購" />
        <meta property="og:title" content="股份回購" />
        <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
      </MetaTags>
      <Container textAlign="center">
        <DisplayAdsBanner />
      </Container>

      <div style={{ paddingTop: "25px", paddingBottom: "25px" }}>
        <div style={{ textAlign: "center" }}>
          <Header as="h1">
            股份回購
            <Header.Subheader>一文看清股份回購紀錄(2021年)</Header.Subheader>
          </Header>
        </div>
        <BuyBackTable />
      </div>
      <Container
        textAlign="center"
        style={{ paddingTop: "30px", paddingBottom: "0px" }}
      >
        <GoogleAd
          client="ca-pub-8753358837449417"
          slot="2486257452"
          format="auto"
          wrapperDivStyle={{
            marginTop: "30px",
            marginBottom: "20px",
          }}
        />
      </Container>
    </>
  );
};

export default TopicBuyBack;
