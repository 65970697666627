import React, {
  useEffect,
  useState,
  useRef,
  // useReducer,
  // useCallback,
} from "react";
import {
  Table,
  Pagination,
  Container,
  Header,
  Menu,
  Icon,
  Label,
  Button,
  Card,
  Modal,
  // Image,
  Loader,
  Dimmer,
} from "semantic-ui-react";
import _ from "lodash";
// import { createStore, combineReducers } from "redux";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import InFeedAdsWhite from "../../ads/InFeedAdsWhite";
import source from "../../../mock_source";
import PriceLine from "../../charts/PriceLine2";
// import Slider from "@material-ui/core/Slider";
import { formatNumber } from "../../../util/dataFormatter";
// import { Field, reduxForm } from "redux-form";
// import StockChart2T from "../../charts/StockChart2T";
// import InTableChart from "../FavouritePage/InTableChart";
// import RSChartContainer from "../FavouritePage/RSChartContainer";
import StockChartContainer from "../FavouritePage/StockChartContainer";
import RSChart from "../../charts/RSChart";
import RSChartBatch from "../../charts/RSChart3";
import { AddFavModal, FavBtns } from "components/widget/MyFav";
import { change } from "redux-form";
import {
  setDisplayMode,
  setActivePage,
  changeSort,
  showMoreCharts,
  selectAllRS,
  // selectRSById,
  // fetchRS,
  fetchBatchRS,
  toIdle,
  // setNChartsToShow,
} from "features/rs/rsSlice";
import { useSelector, useDispatch } from "react-redux";
import store from "Store";
import "./RSTable.css";
// import { TestPlanCard } from "../../widget/SubscriptionWidget";

function obj2Array(n) {
  return Object.values(n);
}

function RSTable(props) {
  const { data, filters, stockDict } = props;
  const header = data.rstable.header;
  const rsData = data.rstable.data;
  const { allowedBehindPaywall, stockSector, wishList } = props;
  var lastUpdate = props.data.topicInfo.lastUpdate;
  var ind = stockSector.ind;
  var iDict = stockSector.iDict;
  var sDict = stockSector.sDict;
  var ssDict = stockSector.ssDict;
  const [tableData, setTableData] = useState([]);
  let column2Show = [];
  var data1 = _.map(data.rstable.data, obj2Array);
  // console.log(stockDict);
  useEffect(() => {
    var tableData1 = data1.map((v) => {
      var d = {};
      var e = v[0];
      for (var i = 0; i < header.length; i++) {
        d[header[i]] = e[i];
      }
      let a = _.find(stockDict, function (o) {
        return o.id === d.id;
      });
      let ii = ind[d.id];
      return {
        ...d,
        name_zh: a ? a["zh"] : "",
        name_en: a ? a["en"] : "",
        i: ii ? iDict[ii["i"]] : "",
        s: ii ? sDict[ii["s"]] : "",
        ss: ii ? ssDict[ii["ss"]] : "",
        isPlgma50: d.C >= d.ma50 ? true : false,
        isPlgma200: d.C >= d.ma200 ? true : false,
        isPlgwma30: d.C >= d.maw30 ? true : false,
        isMa50lgma150: d.ma50 >= d.ma150 ? true : false,
        isMa50lgma200: d.ma50 >= d.ma200 ? true : false,
        isMa150lgma200: d.ma150 >= d.ma200 ? true : false,
        d52wHPct:
          Math.round(((d.C - d.high_52_week) / d.high_52_week) * 1000) / 10,
      };
    });
    tableData1 = _.sortBy(tableData1, ["rs"]).reverse();
    setTableData(tableData1);
  }, []);

  let newData = tableData;

  if (filters !== undefined) {
    if (filters.title !== undefined) {
      newData = _.filter(newData, function (d) {
        return (
          (typeof d.id === "string" &&
            d.id.toLowerCase().includes(filters.title.toLowerCase())) ||
          (typeof d.name_zh === "string" &&
            d.name_zh.toLowerCase().includes(filters.title.toLowerCase())) ||
          (typeof d.name_en === "string" &&
            d.name_en.toLowerCase().includes(filters.title.toLowerCase()))
        );
      });
    }

    if (filters["ipoType"] !== undefined) {
      if (filters["ipoType"] !== 0) {
        if (filters["ipoType"] === 1) {
          newData = _.filter(newData, function (d) {
            return String(d.ipoType) === String(filters["ipoType"]);
          });
        } else {
          newData = _.filter(newData, function (d) {
            return (String(d.ipoType) === "1") | (String(d.ipoType) === "2");
          });
        }
      }
    }

    if (filters["catType"] !== undefined) {
      if (filters["catType"] !== 0) {
        if (filters["catType"] === 2) {
          newData = _.filter(newData, function (d) {
            return String(d.cat) === "G";
          });
        } else {
          newData = _.filter(newData, function (d) {
            return String(d.cat) !== "G";
          });
        }
      }
    }

    if (filters["sector"] && filters["sector"] !== "所有") {
      newData = _.filter(newData, function (d) {
        return d.s === filters["sector"];
      });
    }
    [
      "isMa50lgma150",
      "isMa50lgma200",
      "isMa150lgma200",
      "isPlgma50",
      "isPlgma200",
      "isPlgwma30",
    ].forEach(function (f, i) {
      if (filters[f]) {
        newData = _.filter(newData, function (d) {
          return d[f] === true;
        });
      }
    });

    [
      { n: "price", i: "C" },
      { n: "cap", i: "cap" },
      { n: "t", i: "t" },
      { n: "rs", i: "rs" },
    ].forEach(function (f, i) {
      if (
        parseFloat(filters[f["n"] + "h"]) > 0 ||
        parseFloat(filters[f["n"] + "l"]) > 0
      ) {
        const low =
          parseFloat(filters[f["n"] + "l"]) > 0
            ? parseFloat(filters[f["n"] + "l"])
            : 0;
        const high =
          parseFloat(filters[f["n"] + "h"]) > 0
            ? parseFloat(filters[f["n"] + "h"])
            : 99999999;
        i = f["i"];
        // console.log(i);
        newData = _.filter(newData, function (d) {
          return d[i] >= low && d[i] <= high;
        });
      }

      if (
        parseFloat(filters["d52wHPctl"]) <= 0 ||
        parseFloat(filters["d52wHPcth"]) <= 0
      ) {
        const low =
          parseFloat(filters["d52wHPctl"]) <= 0
            ? parseFloat(filters["d52wHPctl"])
            : -99999999;
        const high =
          parseFloat(filters["d52wHPcth"]) <= 0
            ? parseFloat(filters["d52wHPcth"])
            : 0;
        i = "d52wHPct";
        column2Show.push(i);
        // console.log(column2Show);
        newData = _.filter(newData, function (d) {
          return d[i] >= low && d[i] <= high;
        });
      }
    });
  }
  return (
    <Container
      style={{
        width: "100%",
        maxWidth: "950px",
        margin: "auto",
        alignItems: "center",
      }}
    >
      <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
        <Header as="h4" textAlign="right" color="grey">
          {" "}
          數據至 : {lastUpdate} <br />
          總數 : {newData.length}{" "}
        </Header>
      </div>

      <div>
        <RSTableDisplay
          data={newData}
          column2Show={column2Show}
          allowedBehindPaywall={allowedBehindPaywall}
        />
      </div>
    </Container>
  );
}

function RSTableDisplay(props) {
  const { data, allowedBehindPaywall, column2Show } = props;
  // const [state, dispatch] = useReducer(resultTableReducer, initialState);
  const dispatch = useDispatch();
  // const [newData, setNewData] = useState([]);
  // useEffect(() => {
  //   setNewData(data);
  // }, [data]);
  // const topics = useSelector(selectAllTopic);
  let newData = [];
  const tableSetting = useSelector((state) => state.rs.tableSetting);
  const { displayMode, direction, column } = tableSetting;
  if (direction === "ascending") {
    newData = _.orderBy(data, [column], ["asc"]);
  } else {
    newData = _.orderBy(data, [column], ["desc"]);
  }
  // console.log(newData);
  // console.log("newData Feeded");

  return (
    <>
      <div>
        <Menu pointing secondary compact size="large">
          <Menu.Item
            name="列表"
            active={tableSetting.displayMode === "0"}
            // onClick={() => {
            //   dispatch({ type: "SET_DISPLAYMODE", text: "0" });
            // }}
            onClick={() => {
              dispatch(setDisplayMode("0"));
              dispatch(toIdle());
            }}
          >
            <Icon name="table" />
            列表模式
          </Menu.Item>
          <Menu.Item
            name="圖表"
            active={tableSetting.displayMode === "1"}
            onClick={() => {
              dispatch(setDisplayMode("1"));
              dispatch(toIdle());
            }}
          >
            <Icon name="chart bar" />
            圖表模式
          </Menu.Item>
        </Menu>
      </div>

      <div style={{ textAlign: "center" }}>
        {displayMode === "0" ? (
          <SortableTable
            data={newData}
            allowedBehindPaywall={allowedBehindPaywall}
            dispatch={dispatch}
            tableSetting={tableSetting}
            column2Show={column2Show}
          />
        ) : allowedBehindPaywall ? (
          <Modal
            closeIcon
            centered={false}
            size="fullscreen"
            onClose={() => dispatch(setDisplayMode("0"))}
            onOpen={() => dispatch(setDisplayMode("1"))}
            open={displayMode === "1"}
            // trigger={<Button>Basic Modal</Button>}
          >
            <Modal.Header>圖表模式</Modal.Header>
            <Modal.Content image>
              <ChartTable
                data={newData}
                allowedBehindPaywall={allowedBehindPaywall}
                dispatch={dispatch}
                tableSetting={tableSetting}
                column2Show={column2Show}
              />
            </Modal.Content>
          </Modal>
        ) : (
          <div style={{ paddingTop: "25px" }}>
            <AskForSubscribe />
          </div>
        )}
      </div>
    </>
  );
}

function ChartTable(props) {
  const { data, dispatch, column2Show } = props;
  const scrollRef = useRef(null);
  const [chartLeft, setChartLeft] = useState(0);
  const [chartWidth, setChartWidth] = useState(10);
  const { direction, column, nChartsToShow } = props.tableSetting;
  const rs = useSelector(selectAllRS);

  const idInList = rs.map((d) => d.topicInfo.stockCode);
  // console.log("this is ID IN List");
  // console.log(rs);
  const rsStatus = useSelector((state) => state.rs.status);
  const error = useSelector((state) => state.rs.error);
  // console.log(data);
  let newData = data;
  let newData2 = newData.slice(0, nChartsToShow);
  useEffect(() => {
    dispatch(showMoreCharts(nChartsToShow));
  }, []);
  useEffect(() => {
    // console.log(newData2);
    let idToFetch = [];
    for (let index = 0; index < newData2.length; index++) {
      const idToCheck = ("0000" + newData2[index].id).slice(-5);
      if (idInList.includes(idToCheck) === false) {
        idToFetch.push(idToCheck);
      }
    }
    // console.log(idToFetch.length);
    if (idToFetch.length > 0 && rsStatus === "idle") {
      dispatch(fetchBatchRS(idToFetch));
    }
  }, [newData2, dispatch, idInList, rsStatus]);

  const resize = (event) => {
    if (scrollRef && scrollRef.current) {
      setChartWidth(scrollRef.current.clientWidth - 20);
      // console.log("this is chart width charttable");
      // console.log(scrollRef.current.clientWidth - 20);
    }
  };
  const handleScroll = (event) => {
    setChartLeft(scrollRef.current.scrollLeft);
  };
  useEffect(() => {
    resize();
    window.addEventListener("resize", resize);
    return () => {
      window.addEventListener("resize", resize);
    };
  }, [scrollRef]);
  useEffect(() => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.addEventListener("scroll", handleScroll);
      return () => {
        if (scrollRef && scrollRef.current) {
          scrollRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [scrollRef]);

  const topRowHeaderStyle = {
    position: "sticky",
    top: "0px",
    zIndex: "10",
    backgroundColor: "rgb(240,240,240)",
    // fontSize: normalSize[fontSize],
  };
  const firstColHeaderStyle = {
    position: "sticky",
    left: "0px",
    zIndex: "5",
    // backgroundColor: "rgb(240,240,240)",
    backgroundColor: "rgb(255,255,255)",
    // fontSize: normalSize[fontSize],
  };
  const contentStyle = {
    // fontSize: normalSize[fontSize],
    zIndex: "0",
  };

  return (
    <div
      style={{
        whiteSpace: "nowrap",
        overflowX: "hidden",
        display: "inline-block",
        width: "100%",
        textAlign: "center",
      }}
    >
      <div
        style={{
          overflow: "auto",
          // maxHeight: "85vh",
          overscrollBehavior: "auto",
        }}
        ref={scrollRef}
      >
        <Table sortable selectable celled unstackable compact structured>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                style={{
                  position: "sticky",
                  top: "0px",
                  left: "0px",
                  zIndex: "15",
                  backgroundColor: "rgb(240,240,240)",
                  borderWidth: "0px",
                }}
                textAlign="left"
                rowSpan="2"
                sorted={column === "id" ? direction : null}
                // onClick={() => {
                //   dispatch(changeSort("id"));
                // }}
              >
                名稱
                {isMobile ? <br /> : null}
                (編號)
              </Table.HeaderCell>
              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
                sorted={column === "C" ? direction : null}
                // onClick={() => {
                //   dispatch(changeSort("C"));
                // }}
              >
                價格
              </Table.HeaderCell>
              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
                sorted={column === "rs" ? direction : null}
                // onClick={() => {
                //   dispatch(changeSort("rs"));
                // }}
              >
                RS
              </Table.HeaderCell>
              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
                sorted={column === "rsd10d" ? direction : null}
                // onClick={() => {
                //   dispatch(changeSort("rsd10d"));
                // }}
              >
                RS變化 <br /> (10天)
              </Table.HeaderCell>
              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
                sorted={column === "t" ? direction : null}
                // onClick={() => {
                //   dispatch(changeSort("t"));
                // }}
              >
                成交額
              </Table.HeaderCell>
              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
                sorted={column === "cap" ? direction : null}
                // onClick={() => {
                //   dispatch(changeSort("cap"));
                // }}
              >
                市值
              </Table.HeaderCell>
              {column2Show.includes("d52wHPct") ? (
                <Table.HeaderCell
                  style={topRowHeaderStyle}
                  textAlign="center"
                  rowSpan="2"
                  sorted={column === "d52wHPct" ? direction : null}
                  // onClick={() => {
                  //   dispatch(changeSort("d52wHPct"));
                  // }}
                >
                  離52W高%
                </Table.HeaderCell>
              ) : null}
              {/* <Table.HeaderCell
              style={topRowHeaderStyle}
              textAlign="center"
              rowSpan="2"
            >
              <div>
                <Icon
                  name="star"
                  // style={{ color: "#FFCA28" }}
                />
              </div>
            </Table.HeaderCell> */}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {newData2.map((d, index) => {
              return (
                <>
                  {index !== 0 && index % 10 === 0 && (
                    <Table.Row key={index}>
                      <Table.Cell colSpan={7} textAlign="center">
                        <InFeedAdsWhite></InFeedAdsWhite>
                      </Table.Cell>
                    </Table.Row>
                  )}
                  {d.name_zh !== "" ? (
                    <>
                      <Table.Row key={d.id + "t"}>
                        <Table.Cell
                          style={firstColHeaderStyle}
                          textAlign="left"
                        >
                          {d.name_zh !== "" ? (
                            <Link to={"/stock/" + d.id}>
                              <span style={{ fontSize: "0.8rem" }}>
                                {d.name_zh.length > 8
                                  ? d.name_zh.substring(0, 8) + "..."
                                  : d.name_zh}
                              </span>
                              {isMobile ? <br /> : null}({d.id})
                            </Link>
                          ) : (
                            <>({d.id})</>
                          )}
                          <br />
                          {d.s !== undefined && d.s !== "-" ? (
                            <Label
                              size="tiny"
                              basic
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                store.dispatch(
                                  change("rstableT", "sector", d.s)
                                );
                              }}
                            >
                              {d.s}
                            </Label>
                          ) : null}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          <div style={{ marginBottom: "-0.75rem" }}>
                            {" "}
                            ${d.C}{" "}
                          </div>
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          <div style={{ marginBottom: "-0.75rem" }}>
                            {" "}
                            {d.rs}{" "}
                          </div>
                          <div style={{ width: "100px", margin: "auto" }}>
                            <PriceLine
                              min={0}
                              secondMin={0}
                              mid={d.rs}
                              secondMax={d.rs}
                              max={100}
                              col={"#2471A3"}
                            />
                          </div>
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          <div style={{ marginBottom: "-0.75rem" }}>
                            {" "}
                            {d.rsd10d}{" "}
                          </div>
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          <div style={{ marginBottom: "-0.75rem" }}>
                            {" "}
                            {formatNumber(d.t * 10000)}{" "}
                          </div>
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          <div style={{ marginBottom: "-0.75rem" }}>
                            {" "}
                            {d.cap === 0
                              ? "-"
                              : formatNumber(d.cap * 100000000)}{" "}
                          </div>
                        </Table.Cell>
                        {column2Show.includes("d52wHPct") ? (
                          <Table.Cell textAlign="center">
                            <div style={{ marginBottom: "-0.75rem" }}>
                              {" "}
                              {d.d52wHPct === 0 ? "-" : d.d52wHPct}
                            </div>
                          </Table.Cell>
                        ) : null}

                        {/* <Table.Cell textAlign="center">
                    {favBtns.get(d.id)}
                  </Table.Cell> */}
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell
                          style={{
                            paddingBottom: "10px",
                            paddingTop: "10px",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                          }}
                          colSpan={7}
                        >
                          <div
                            style={{
                              position: "sticky",
                              left: "10px",
                              width: chartWidth,
                              zIndex: "9",
                              minHeight: "250px",
                            }}
                          >
                            {rs.find(
                              (item) =>
                                item.topicInfo.stockCode ===
                                ("0000" + d.id).slice(-5)
                            ) ? (
                              <InTableChartContainer id={d.id} mode="Chart" />
                            ) : (
                              <Dimmer active>
                                <Loader active inline="centered">
                                  {" "}
                                  Loading
                                </Loader>
                              </Dimmer>
                            )}
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    </>
                  ) : null}
                </>
              );
            })}
          </Table.Body>
        </Table>
      </div>
      {data.length > nChartsToShow ? (
        <div className="center" style={{ padding: "0 0 20px 0" }}>
          <Button
            circular
            onClick={(e) => {
              dispatch(showMoreCharts(nChartsToShow));
              dispatch(toIdle());
            }}
            // onClick={() => {
            //   dispatch({ type: "SetNChartsToShow", text: nChartsToShow + 10 });
            //   // setnChartsToShow(nChartsToShow + 10);
            //   // console.log(nChartsToShow);
            // }}
            icon={<Icon name="angle double down" size="big" />}
            name="more-stock"
          />
        </div>
      ) : null}
    </div>
  );
}

function SortableTable(props) {
  const allowedBehindPaywall = props.allowedBehindPaywall;
  const scrollRef = useRef(null);
  const [chartLeft, setChartLeft] = useState(0);
  const [chartWidth, setChartWidth] = useState(10);
  const { direction, column, activePage } = props.tableSetting;
  const { data, dispatch, column2Show } = props;
  let newData = data;

  const [selected, setSelected] = React.useState([]);
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const resize = (event) => {
    if (scrollRef && scrollRef.current) {
      setChartWidth(scrollRef.current.clientWidth - 20);
    }
  };
  const handleScroll = (event) => {
    setChartLeft(scrollRef.current.scrollLeft);
  };
  useEffect(() => {
    resize();
    window.addEventListener("resize", resize);
    return () => {
      window.addEventListener("resize", resize);
    };
  }, [scrollRef]);
  useEffect(() => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.addEventListener("scroll", handleScroll);
      return () => {
        if (scrollRef && scrollRef.current) {
          scrollRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [scrollRef]);

  const pos_pagination = isMobile ? "center" : "right";
  // if (direction === "ascending") {
  //   newData = _.orderBy(newData, [column], ["asc"]);
  // } else {
  //   newData = _.orderBy(newData, [column], ["desc"]);
  // }

  const nPerPage = 25;
  const totalPage = Math.ceil(newData.length / nPerPage);
  let newData2 = [];
  newData2 = _.slice(
    newData,
    (activePage - 1) * nPerPage,
    activePage * nPerPage
  );
  let favBtns = FavBtns(newData2.map((d) => d.id));

  const handlePaginationChange = (e, { activePage }) => {
    // dispatch({
    //   type: "SET_ACTIVE_PAGE",
    //   text: activePage,
    // });
    dispatch(setActivePage(activePage));
  };

  const topRowHeaderStyle = {
    position: "sticky",
    top: "0px",
    zIndex: "10",
    backgroundColor: "rgb(240,240,240)",
    // fontSize: normalSize[fontSize],
  };
  const firstColHeaderStyle = {
    position: "sticky",
    left: "0px",
    zIndex: "5",
    // backgroundColor: "rgb(240,240,240)",
    backgroundColor: "rgb(255,255,255)",
    // fontSize: normalSize[fontSize],
  };
  const contentStyle = {
    // fontSize: normalSize[fontSize],
    zIndex: "0",
  };
  // const rowHeaderStyle = {
  //   // fontSize: smallSize[fontSize],
  //   color: "grey",
  // };

  return (
    <div
      style={{
        whiteSpace: "nowrap",
        overflowX: "hidden",
        display: "inline-block",
        width: "100%",
        textAlign: "center",
      }}
    >
      <div
        style={{
          overflow: "auto",
          // maxHeight: "85vh",
          overscrollBehavior: "auto",
        }}
        ref={scrollRef}
      >
        <Table sortable selectable celled unstackable compact structured>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                style={{
                  position: "sticky",
                  top: "0px",
                  left: "0px",
                  zIndex: "15",
                  backgroundColor: "rgb(240,240,240)",
                  borderWidth: "0px",
                }}
                textAlign="left"
                rowSpan="2"
                sorted={column === "id" ? direction : null}
                onClick={() => {
                  dispatch(changeSort("id"));
                }}
              >
                名稱
                {isMobile ? <br /> : null}
                (編號)
              </Table.HeaderCell>
              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
                sorted={column === "C" ? direction : null}
                onClick={() => {
                  dispatch(changeSort("C"));
                }}
              >
                價格
              </Table.HeaderCell>
              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
                sorted={column === "rs" ? direction : null}
                onClick={() => {
                  dispatch(changeSort("rs"));
                }}
              >
                RS
              </Table.HeaderCell>
              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
                sorted={column === "rsd10d" ? direction : null}
                onClick={() => {
                  dispatch(changeSort("rsd10d"));
                }}
              >
                RS變化 <br /> (10天)
              </Table.HeaderCell>
              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
                sorted={column === "t" ? direction : null}
                onClick={() => {
                  dispatch(changeSort("t"));
                }}
              >
                成交額
              </Table.HeaderCell>
              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
                sorted={column === "cap" ? direction : null}
                onClick={() => {
                  dispatch(changeSort("cap"));
                }}
              >
                市值
              </Table.HeaderCell>
              {column2Show.includes("d52wHPct") ? (
                <Table.HeaderCell
                  style={topRowHeaderStyle}
                  textAlign="center"
                  rowSpan="2"
                  sorted={column === "d52wHPct" ? direction : null}
                  onClick={() => {
                    dispatch(changeSort("d52wHPct"));
                  }}
                >
                  離52W高%
                </Table.HeaderCell>
              ) : null}

              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
              >
                <div>
                  <Icon
                    name="star"
                    // style={{ color: "#FFCA28" }}
                  />
                </div>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {newData2.map((d, index) => {
              const isItemSelected = isSelected(d.id);
              return (
                <>
                  {index !== 0 && index % 10 === 0 && (
                    <Table.Row key={index}>
                      <Table.Cell colSpan={7} textAlign="center">
                        <InFeedAdsWhite></InFeedAdsWhite>
                      </Table.Cell>
                    </Table.Row>
                  )}
                  <Table.Row
                    key={d.id}
                    onClick={(event) => handleClick(event, d.id)}
                  >
                    <Table.Cell style={firstColHeaderStyle} textAlign="left">
                      {d.name_zh !== "" ? (
                        <Icon
                          name={isItemSelected ? "caret down" : "caret right"}
                        />
                      ) : null}

                      {d.name_zh !== "" ? (
                        <Link to={"/stock/" + d.id}>
                          <span style={{ fontSize: "0.8rem" }}>
                            {d.name_zh.length > 8
                              ? d.name_zh.substring(0, 8) + "..."
                              : d.name_zh}
                          </span>
                          {isMobile ? <br /> : null}({d.id})
                        </Link>
                      ) : (
                        <>({d.id})</>
                      )}
                      <br />
                      {d.s !== undefined && d.s !== "-" ? (
                        <Label
                          size="tiny"
                          basic
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            store.dispatch(change("rstableT", "sector", d.s));
                          }}
                        >
                          {d.s}
                        </Label>
                      ) : null}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <div style={{ marginBottom: "-0.75rem" }}> ${d.C} </div>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <div style={{ marginBottom: "-0.75rem" }}> {d.rs} </div>
                      <div style={{ width: "100px", margin: "auto" }}>
                        <PriceLine
                          min={0}
                          secondMin={0}
                          mid={d.rs}
                          secondMax={d.rs}
                          max={100}
                          col={"#2471A3"}
                        />
                      </div>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <div style={{ marginBottom: "-0.75rem" }}>
                        {" "}
                        {d.rsd10d}{" "}
                      </div>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <div style={{ marginBottom: "-0.75rem" }}>
                        {" "}
                        {formatNumber(d.t * 10000)}{" "}
                      </div>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <div style={{ marginBottom: "-0.75rem" }}>
                        {" "}
                        {d.cap === 0
                          ? "-"
                          : formatNumber(d.cap * 100000000)}{" "}
                      </div>
                    </Table.Cell>

                    {column2Show.includes("d52wHPct") ? (
                      <Table.Cell textAlign="center">
                        <div style={{ marginBottom: "-0.75rem" }}>
                          {" "}
                          {d.d52wHPct === 0 ? "-" : d.d52wHPct}
                        </div>
                      </Table.Cell>
                    ) : null}

                    <Table.Cell textAlign="center">
                      {favBtns.get(d.id)}
                    </Table.Cell>
                  </Table.Row>

                  {isItemSelected ? (
                    <Table.Row>
                      <Table.Cell
                        style={{
                          paddingBottom: "10px",
                          paddingTop: "10px",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                        }}
                        colSpan={7}
                      >
                        <div
                          style={{
                            position: "sticky",
                            left: "10px",
                            width: chartWidth,
                            zIndex: "9",
                          }}
                        >
                          {allowedBehindPaywall ? (
                            <InTableChartContainer id={d.id} mode="table" />
                          ) : (
                            <AskForSubscribe />
                          )}
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                </>
              );
            })}
          </Table.Body>
        </Table>
      </div>

      <div
        style={{
          paddingTop: "25px",
          paddingBottom: "25px",
          float: pos_pagination,
        }}
      >
        <Pagination
          boundaryRange={0}
          activePage={activePage}
          size={isMobile ? "small" : "big"}
          siblingRange={1}
          totalPages={totalPage}
          onPageChange={handlePaginationChange}
        />
      </div>
    </div>
  );
}

export const InTableChartContainer = (props) => {
  const stockId = props.id;
  const mode = props.mode;
  const [activeChart, setActiveChart] = useState("1");
  return (
    <>
      <Container
        style={{
          //   paddingLeft: "15px",
          //   paddingTop: "px",
          paddingBottom: "5px",
        }}
      >
        <Button
          style={{ borderRadius: "15px" }}
          active={activeChart === "0"}
          onClick={() => {
            setActiveChart("0");
          }}
          size="tiny"
        >
          CCASS
        </Button>
        <Button
          style={{ borderRadius: "15px" }}
          active={activeChart === "1"}
          onClick={() => {
            setActiveChart("1");
          }}
          size="tiny"
        >
          RS
        </Button>
      </Container>
      {activeChart === "0" ? (
        <StockChartContainer id={stockId} />
      ) : // <>hello </>
      mode === "table" ? (
        <RSChart id={stockId} />
      ) : (
        <RSChartBatch id={stockId} />
      )}
    </>
  );
};

function AskForSubscribe() {
  return (
    <div style={{ margin: "auto" }}>
      <Card centered style={{ textAlign: "center" }}>
        <Card.Content>
          <Card.Header>加入付費版，以體驗進階功能</Card.Header>
        </Card.Content>
        <Card.Content extra>
          <Link to="/subscription">
            <Button basic color="blue">
              了解更多
            </Button>
          </Link>
        </Card.Content>
      </Card>
    </div>
  );
}

export default RSTable;
