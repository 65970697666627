import React from "react";
import MetaTags from "react-meta-tags";
// import RSTable from "../../tables/RSTable3";
// import DisplayAdsBanner from "../../ads/DisplayAdsBanner";
// import GoogleAd from "../../ads/Google_ad";
import { Container, Header, Icon, Popup } from "semantic-ui-react";
import CCASSTableContainer from "./CCASSTableContainer";

export const TopicCCASSTable = () => {
  return (
    <>
      <MetaTags>
        <title>CCASS Table</title>
        <meta name="description" content="" />
        <meta property="og:title" content="" />
        <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
      </MetaTags>
      {/* <Container textAlign="center">
        <DisplayAdsBanner />
      </Container> */}

      <div
        style={{ paddingTop: "25px", paddingBottom: "25px", minHeight: "75vh" }}
      >
        <Container style={{ textAlign: "center" }}>
          <Header as="h1">
            CCASS Table
            {/* <Header.Subheader>
              (Relative Strength Ranking)-Beta
            </Header.Subheader> */}
          </Header>
        </Container>
        <CCASSTableContainer />
      </div>
      <Container
        textAlign="center"
        style={{ paddingTop: "30px", paddingBottom: "0px" }}
      >
        {/* <GoogleAd
          client="ca-pub-8753358837449417"
          slot="2486257452"
          format="auto"
          wrapperDivStyle={{
            marginTop: "30px",
            marginBottom: "20px",
          }}
        /> */}
      </Container>
    </>
  );
};

export default TopicCCASSTable;
