import React, { useEffect } from "react";
import { Loader } from 'semantic-ui-react'
import _ from 'lodash'
import MetaTags from 'react-meta-tags';
import VoucherTable from '../tables/VoucherTable'
import { selectAllTopic, fetchTopic, toIdle } from '../../features/topic/topicSlice'
import { useSelector, useDispatch } from 'react-redux'
import DisplayAdsBanner from '../ads/DisplayAdsBanner';
import GoogleAd from '../ads/Google_ad';
import {Container, Header} from 'semantic-ui-react'

// import DisplayAdsBanner from '../ads/DisplayAdsBanner';


export const TopicConsumptionVoucher = () => {
  const dispatch = useDispatch()
  const topics = useSelector(selectAllTopic)
  const topicStatus = useSelector((state) => state.topic.status)
  const error = useSelector((state) => state.topic.error)

  useEffect(() => {
    if (topicStatus === 'idle') {
      dispatch(fetchTopic('voucher'))
    }
  }, [topicStatus, dispatch])

  let content

  if (topicStatus === 'loading') {
    content =
      <div>
        <section className="posts-list">
          <div
            style={{
              padding: "100px",
              margin: "auto",
              alignItems: "center",
              height: "75vh",
              // overflow: "auto",
            }}
          >
            <Loader active inline='centered' > Loading</Loader>
          </div>
        </section>
      </div>
  } else if (topicStatus === 'succeeded') {
    const data = topics.find((item) => item.topicInfo.name === 'voucher')
    if (data) {
      content = <VoucherView result={data} />
    }
    else {
      dispatch(toIdle({}))
    }
  } else if (topicStatus === 'error') {
    content = 
      <div>
        <section className="posts-list">
          <div>{error}</div>
        </section>
      </div>
  }
  return (
    <>
      <MetaTags>
          <title>$5000消費券優惠</title>
          <meta name="description" content= '$5000 消費劵及優惠' />
          <meta name="keywords" content='$5000消費劵,一文看清' />
          <meta property="og:title" content= '$5000 消費劵及優惠' />
          <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
      </MetaTags>
      <Container textAlign='center'>
        <DisplayAdsBanner />
      </Container>
      <div style={{ textAlign: 'center'}}>
        <Header as='h1'>
          $5000消費券優惠
        </Header>
      </div>
      {content}
      <Container textAlign='center' style={{paddingTop:'30px', paddingBottom:'0px'}}>
          <GoogleAd 
              client="ca-pub-8753358837449417" 
              slot="2486257452" 
              format="auto" 
              wrapperDivStyle={{
              marginTop: '30px',
              marginBottom: '20px'
              }}
          />
      </Container>
    </>
  )
}

function VoucherView(data) {
  let result = data.result
  return (
    <>
      <div style={{ paddingTop: '25px', paddingBottom: '25px' }}>
        {typeof result !== 'undefined' ? <VoucherTable data={result} /> : <NotFound />}
      </div>
    </>
  )
}

function NotFound() {
  return (
    <div
      style={{
        padding: "100px",
        margin: "auto",
        alignItems: "center",
        height: "75vh",
        // overflow: "auto",
      }}
    >
      <Loader active inline='centered' > Loading</Loader>
    </div>
  )
}

export default TopicConsumptionVoucher;