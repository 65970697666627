import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import _ from "lodash";

import { useEffect, useState } from "react";
import { db, analytics} from '../../../config/fbConfig';
import { collection, doc, getDoc } from "firebase/firestore";
import { logEvent } from "firebase/analytics";

function roundData3(number) {
  return Math.round(number * 1000) / 1000;
}

function roundData1(number) {
  return Math.round(number * 10) / 10;
}

function zip(arrays) {
  return Array.apply(null, Array(arrays[0].length)).map(function (_, i) {
    return arrays.map(function (array) {
      return array[i];
    });
  });
}

function StockMarketRSChart(props) {

  const stockCode = props.stockCode;
  const stockCodeStr = ("0000" + stockCode).slice(-5)
  const [ rsData, setRSData ] = useState({
    loaded: false,
    error: false,
    data: null,
  })

  useEffect(() => {
    let colRef = collection(db, "equity_stat_from_aws")
    logEvent(analytics, "select_content", {
      content_type: "colRef" + stockCodeStr,
    });
    getDoc(doc(colRef, stockCodeStr))
    .then(response => {
      setRSData({
        loaded: true,
        error: false,
        data: response.data().data,
        topicInfo: response.data().topicInfo,
      });
    })
  }, [stockCode])
  
  var windowWidth = window.innerWidth;
  const chartHeight =
    0.656 * windowWidth < 550
      ? 550
      : 0.656 * windowWidth > 633
      ? 633
      : 0.656 * windowWidth;

  let data = [];
  let options = {};

  let startDate = new Date();
  startDate.setFullYear(startDate.getFullYear() - 1);

  if (rsData.data && rsData.data["date"].length > 0) {

    let dates = rsData.data["date"].map((item) => new Date(item).getTime());
    let index = 0;
    for (index; index < dates.length && dates[index] < startDate; index++);
    data.push({
      yAxis: 0,
      type: "ohlc",
      name:
        ("0000" + stockCode).slice(-4),
      useOhlcData: true,
      data: zip([
        dates,
        rsData.data["open"],
        rsData.data["high"],
        rsData.data["low"],
        rsData.data["close"],
      ]),
    });
    data.push({
      yAxis: 0,
      name: "5天平均線",
      data: zip([dates, _.map(rsData.data["sma_5_day"], roundData3)]),
    });
    data.push({
      yAxis: 0,
      name: "10天平均線",
      data: zip([dates, _.map(rsData.data["sma_10_day"], roundData3)]),
    });
    data.push({
      yAxis: 0,
      name: "20天平均線",
      data: zip([dates, _.map(rsData.data["sma_20_day"], roundData3)]),
    });
    data.push({
      yAxis: 0,
      name: "50天平均線",
      data: zip([dates, _.map(rsData.data["sma_50_day"], roundData3)]),
    });
    data.push({
      yAxis: 0,
      name: "150天平均線",
      data: zip([dates, _.map(rsData.data["sma_150_day"], roundData3)]),
    });
    data.push({
      yAxis: 0,
      name: "200天平均線",
      data: zip([dates, _.map(rsData.data["sma_200_day"], roundData3)]),
    });

    data.push({
      yAxis: 1,
      name: "RS",
      data: zip([dates.slice(index), _.map(rsData.data["rs"].slice(index), roundData1)]),
    });
    options = {
      chart: {
        height: chartHeight,
        // plotBackgroundImage: `${process.env.PUBLIC_URL}/images/watermark.svg`
      },
  
      rangeSelector: {
        selected: 1,
        dropdown: "never",
      },
  
      legend: {
        enabled: true,
        align: "center",
        /* layout: 'vertical', */
        verticalAlign: "bottom",
        x: 0,
        y: 0,
      },
  
      yAxis: [
        {
          // Primary yAxis
          labels: {
            style: {
              color: "black",
            },
            //align: 'left',
            align: "right",
            x: -2,
          },
          title: {
            style: {
              color: "blue",
            },
            text: "股價",
          },
          opposite: true,
          // startOnTick: false,
          endOnTick: false,
        },
        {
          // Secondary yAxis
          labels: {
            enabled: true,
            style: {
              color: "#ff7215",
            },
          },
          opposite: false,
          endOnTick: false,
          gridLineWidth: 0,
          title: {
            style: {
              color: "#ff7215",
            },
            text: "RS",
          },
        },
      ],
  
      plotOptions: {
        candlestick: {
          lineColor: "red",
          color: "white",
          upLineColor: "black",
          upColor: "Green",
        },
      },
  
      navigator: {
        series: {
          lineWidth: 1,
          lineColor: "#2F4F4F",
          color: "#2F4F4F",
          fillOpacity: 0.2,
        },
      },
  
      tooltip: {
        shared: true,
      },
  
      scrollbar: {
        enabled: true,
      },
  
      series: [
        {
          type: "candlestick",
          name: ("0000" + rsData.topicInfo.stockCode).slice(-5) + ".HK " + rsData.topicInfo.stockName,
          data: data[0]["data"],
          yAxis: 0,
          showInLegend: false,
        },
        {
          type: "line",
          name: "MA5",
          data: data[1]["data"],
          yAxis: 0,
          color: "#004578",
        },
        {
          type: "line",
          name: "MA10",
          data: data[2]["data"],
          yAxis: 0,
          color: "#005a9e",
        },
        {
          type: "line",
          name: "MA20",
          data: data[3]["data"],
          yAxis: 0,
          color: "#106ebe",
        },
        {
          type: "line",
          name: "MA50",
          data: data[4]["data"],
          yAxis: 0,
          color: "#0078d4",
        },
        {
          type: "line",
          name: "MA150",
          data: data[5]["data"],
          yAxis: 0,
          color: "#2b88d8",
        },
        {
          type: "line",
          name: "MA200",
          data: data[6]["data"],
          yAxis: 0,
          color: "#71afe5",
        },
        {
          type: "line",
          name: "RS",
          data: data[7]["data"],
          yAxis: 1,
          color: "#ff7215",
        },
      ],
      credits: {
        text: "DaaSHK",
        href: "https://daashk.com/",
        style: {
          fontSize: "1em",
        },
      },
    };
  }


  return (
    <div className="chart">
      { data.length > 0 && <HighchartsReact
        highcharts={Highcharts}
        constructorType={"stockChart"}
        options={options}
      />} 
    </div>
  );
}

export default StockMarketRSChart;