import React from 'react'
import {
  Container,
  // Grid,
  Header,
  Segment,
} from 'semantic-ui-react'

import './Footer.css';

const Footer = () => (
  <Segment inverted vertical style={{ padding: '2em 0em 5em' }}>
    <Container>
      {/* <Grid divided inverted stackable> */}
        {/* <Grid.Row> */}
          <Header as='h4' inverted>
            免責聲明：
          </Header>
          <p>
            本網站包含的內容和信息乃根據公開資料分析和演釋，DaaSHK並不保證他們的準確性、完整性、實時性或者正確性.
          </p>
          <Header as='h4' inverted>
            無關聯通知:
          </Header>
          <p>
            本網站與CCASS、香港交易及結算所有限公司、香港中央結算有限公司或任何其附屬或聯屬公司之間並無任何附屬、關聯、授權、合作、贊助、認可或任何方式的其他正式關係。
          </p>
            {/* <a target="_blank" rel="noopener noreferrer" href=' https://finance.yahoo.com/'> Yahoo Finance </a>
            及
            <a target="_blank" rel="noopener noreferrer" href='https://www.hkexnews.hk/sdw/search/searchsdw.aspx'> HKEXNEWS.HK </a> */}
          <p>
            ©2021 DaaSHK All rights reserved.
          </p>
        {/* </Grid.Row> */}
      {/* </Grid> */}
    </Container>
  </Segment>
)

export default Footer