export const formatSharePercentage = (percentage) => {
  return percentage.toFixed(2) + " %";
};

export const formatStockCode = (stockCode) => {
  return ("000" + stockCode).slice(-4);
};

export const formatShare = (share) => {
  let fixedCount = 0;
  let unit = " 股";

  if (Math.abs(share) > 1000) {
    share = share / 1000.0;
    unit = " 千股";
    fixedCount = 3;
  }
  if (Math.abs(share) > 1000) {
    share = share / 1000.0;
    unit = " 百萬股";
  }
  if (Math.abs(share) > 1000) {
    share = share / 1000.0;
    unit = " 十億";
  }
  return share.toFixed(fixedCount) + unit;
};

export const formatNumber = (share) => {
  let fixedCount = 0;
  let unit = "";

  if (Math.abs(share) > 10000) {
    share = share / 10000.0;
    unit = "萬";
    fixedCount = 2;
  }
  // if (Math.abs(share) > 1000) {
  //   share = share / 1000.0;
  //   unit = "百萬";
  // }
  if (Math.abs(share) > 10000) {
    share = share / 10000.0;
    unit = "億";
    fixedCount = 2;
  }

  if (Math.abs(share) > 10000) {
    share = share / 10000.0;
    unit = "萬億";
    fixedCount = 2;
  }
  return share.toFixed(fixedCount) + unit;
};
