import React from "react";
import AppContainer from "./components/views/AppContainer";
import "./App.css";
import "semantic-ui-css/semantic.min.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
// import Routes from './Routes';
import Footer from "./components/pages/Footer";
import ScrollToTop from "./ScrollToTop";

import Home from "./components/pages/Home";
// import Home2 from './components/pages/Home2';
import Topics from "./components/pages/Topics";
import Topics2 from "./components/pages/Topics2";
import About from "./components/pages/About";
import ContactUs from "./components/pages/ContactUs";
import TopicVaccineZh from "./components/pages/TopicVaccineZh";
import TopicVaccineEn from "./components/pages/TopicVaccineEn";
import TopicConsumptionVoucher from "./components/pages/TopicConsumptionVoucher";
import TopicsVacPop from "./components/pages/TopicsVacPop";
import TopicStockRank from "./components/pages/TopicStockRank";
import TopicCompanyResultSchedule from "./components/pages/TopicCompanyResultSchedule";
import TopicListedIPO from "./components/pages/TopicListedIPO";
import TopicsParking from "./components/pages/TopicsParking";
import TopicBuyBack from "./components/pages/TopicBuyBack";
import TopicHKConnect from "./components/pages/TopicHKConnect";
import TopicAlibaba from "./components/pages/TopicAlibaba";
import TopicRs from "./components/pages/TopicRs";
import TopicRs2 from "./components/pages/RSTable/TopicRS";
import TopicRsT from "./components/pages/RSTableT/TopicRS";
import TopicRsUS from "./components/pages/RSTableUS/TopicRsUS";
import TopicHKC from "./components/pages/HKCTable/TopicHKC";
import TopicHKCActive from "./components/pages/TopicHKC/TopicHKC";
import TopicCCASSTable from "./components/pages/CCASSTable/TopicCCASSTable";
import TopicCovidTest from "./components/pages/CovidTest/TopicCovidTest";
import TopicStockMarket from "./components/pages/StockMarket/TopicStockMarket";
import StockViewContainer8T from "./components/pages/StockViewContainer8T";
import StockViewContainer9T from "./components/pages/StockViewContainer9T";
import StockViewContainer10T from "./components/pages/StockViewContainer10T";
import RRGChart from "./components/charts/RRGChart";
import TopicRRG from "./components/pages/RRG/TopicRRG";
import TopicRRGUS from "./components/pages/RRG/TopicRRGUS";
import RSTableBySector from "./components/pages/RSTableT/RSTableBySector";
import RSChartPivot from "./components/charts/RSChart_pivot";
import TopicMonetary from "./components/pages/TopicMonetary";

// import StockZoom from "./components/pages/StockZoom";
// import AddFilterForm from "./features/zoom/AddFilterForm";
import FilterPage from "./features/zoom/FilterListPage";
// import SingleFilterPage from "./features/zoom/SingleFilterPage";
import Test from "./features/zoom/Test";
// import FilterForm from "./features/zoom/FilterForm";
import EditFilterPage from "./features/zoom/EditFilterPage";
// import AddFilterPage from "./features/zoom/AddFilterPage";
import ZoomPicker from "./features/zoom/ZoomPicker";

import TableExperiment from "./components/pages/TableExperiment";
import TableExperiment2 from "./components/tables/TableExperiment2";
import About2 from "./components/pages/About2";

import DateList from "./components/pages/shareholding/DateList";
import StockList from "./components/pages/shareholding/StockList";
import ParticipantList from "./components/pages/shareholding/ParticipantList";
import ShareholdingByParticipant from "./components/pages/shareholding/ShareholdingByParticipant";
import ShareholdingByStock from "./components/pages/shareholding/ShareholdingByStock";
import ShareholdingChangeByParticipant from "./components/pages/shareholding/ShareholdingChangeByParticipant";
import ShareholdingChangeByStock from "./components/pages/shareholding/ShareholdingChangeByStock";
import ShareholdingChange from "./components/pages/shareholding/ShareholdingChange";
import ShareholdingChangeDev from "./components/pages/shareholding/ShareholdingChangeDev";

import ETAHome from "./components/pages/eta/ETAHome";
import MyAccount from "components/pages/MyAccount";
import Subscription from "components/pages/Subscription";
import TopicStockZoom from "./components/pages/stockzoom/TopicStockZoom";
import StockStatPage from "./components/pages/StockStatPage";
// import ZoomFilter from "./components/pages/stockzoom/ZoomFilter";
import RSChartPage from "./components/pages/StockMarket/RSChartPage";
import TopicIndexTable from "./components/pages/IndexTable/TopicIndexTable";
import CovidKeyStat from "./components/charts/CovidKeyStat";
import HKMarket from "./components/pages/StockMarket/HKMarket";
import USMarket from "./components/pages/StockMarket/USMarket";
import FavouritePage from "./components/pages/FavouritePage/FavouritePage";
import CovidCaseChart from "./components/charts/CovidCaseChart";
import TopicCovidCaseStat from "./components/pages/TopicCovidCaseStat";
import NHNLChart from "./components/pages/StockMarket/NHNLChart";
import Blog from "./components/pages/blog/Blog";
import SearchBarTest from "./components/pages/SearchBarTest2";

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <AppContainer>
          {/* <Routes /> */}
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/searchbartest" exact component={SearchBarTest} />
            <Route exact path="/home" children={<Home />} />
            <Route exact path="/about" children={<About />} />
            <Route exact path="/contact" children={<ContactUs />} />
            <Route exact path="/myaccount" children={<MyAccount />} />
            <Route exact path="/subscription" children={<Subscription />} />

            <Route exact path="/topics2" component={Topics} />
            <Route exact path="/topics" children={<Topics2 />} />

            <Route
              exact
              path="/topics/vaccine_luckydraw_info"
              children={<TopicVaccineZh />}
            />
            <Route
              exact
              path="/topics/vaccine_luckydraw_info_zh"
              children={<TopicVaccineZh />}
            />
            <Route
              exact
              path="/topics/vaccine_luckydraw_info_en"
              children={<TopicVaccineEn />}
            />

            <Route exact path="/topics/consumption_voucher">
              <TopicConsumptionVoucher />
            </Route>

            <Route exact path="/topics/topicAlibaba">
              <TopicAlibaba />
            </Route>

            <Route exact path="/topics/vaccination">
              <TopicsVacPop />
            </Route>

            <Route exact path="/topics/hkmonetary">
              <TopicMonetary />
            </Route>

            <Route exact path="/rank">
              <Redirect to="/rank/active" />
            </Route>
            <Route exact path="/rank/:type">
              <TopicStockRank />
            </Route>

            <Route exact path="/topics/resultcalendar">
              <TopicCompanyResultSchedule />
            </Route>

            <Route exact path="/topics/listedipo">
              <TopicListedIPO />
            </Route>

            <Route exact path="/topics/parking">
              <TopicsParking />
            </Route>

            <Route exact path="/topics/buyback">
              <TopicBuyBack />
            </Route>

            <Route exact path="/topics/hkconnect">
              <TopicHKConnect />
            </Route>

            <Route exact path="/topics/hkcactive">
              <TopicHKCActive />
            </Route>

            {/* <Route exact path="/topics/rrg">
              <RRGChart />
            </Route> */}

            <Route exact path="/topics/rrghk">
              <TopicRRG />
            </Route>

            <Route exact path="/topics/rrgus">
              <TopicRRGUS />
            </Route>

            <Route exact path="/topics/rss">
              <RSTableBySector />
            </Route>

            <Route exact path="/topics/rsOld">
              <TopicRs />
            </Route>

            <Route exact path="/topics/rs">
              <TopicRsT />
            </Route>

            <Route exact path="/topics/usrs">
              <TopicRsUS />
            </Route>

            <Route exact path="/topics/ccasstable">
              <TopicCCASSTable />
            </Route>

            <Route exact path="/topics/rs2222">
              <TopicRs2 />
            </Route>

            <Route exact path="/topics/stockzoom">
              <TopicStockZoom />
            </Route>

            <Route exact path="/stock_old/:stockId">
              <StockViewContainer8T />
            </Route>

            <Route exact path="/stock/:stockId">
              <StockViewContainer10T />
            </Route>

            <Route exact path="/stock_new/:stockId">
              <StockViewContainer9T />
            </Route>

            <Route exact path="/pivot">
              <RSChartPivot id={"00001"} />
            </Route>

            <Route exact path="/stock_stat">
              <StockStatPage />
            </Route>

            <Route exact path="/tableTest">
              <TableExperiment />
            </Route>

            <Route exact path="/tableTest2">
              <TableExperiment2 />
            </Route>

            <Route exact path="/about2" children={<About2 />} />

            <Route exact path="/shareholding/date_list">
              <DateList />
            </Route>

            <Route exact path="/shareholding/stock_list">
              <StockList />
            </Route>

            <Route exact path="/shareholding/participant_list">
              <ParticipantList />
            </Route>

            <Route
              exact
              path="/shareholding/shareholding_by_participant/:participant_code"
            >
              <ShareholdingByParticipant />
            </Route>

            <Route exact path="/shareholding/shareholding_by_stock/:stock_code">
              <ShareholdingByStock />
            </Route>

            <Route
              exact
              path="/shareholding/shareholding_change_by_participant/:participant_code"
            >
              <ShareholdingChangeByParticipant />
            </Route>

            <Route
              exact
              path="/shareholding/shareholding_change_by_stock/:stock_code"
            >
              <ShareholdingChangeByStock />
            </Route>

            <Route exact path="/shareholding/shareholding_change">
              <ShareholdingChange />
            </Route>

            <Route exact path="/shareholding/shareholding_change_dev">
              <ShareholdingChangeDev />
            </Route>

            <Route exact path="/eta">
              <ETAHome />
            </Route>

            {/* <Route exact path="/zoom">
              <StockZoom />
            </Route> */}

            <Route exact path="/topics/hkc" component={TopicHKC} />
            <Route exact path="/topics/covidTest" component={TopicCovidTest} />
            <Route exact path="/topics/covidkeystat" component={CovidKeyStat} />

            <Route exact path="/hkmarket">
              <Redirect to="/hkmarket/hsi" />
            </Route>
            <Route exact path="/hkmarket/:type">
              <HKMarket />
            </Route>

            {/* <Route exact path="/usmarket">
              <Redirect to="/usmarket/index" />
            </Route> */}
            {/* <Route exact path="/hkmarket/:type"> */}
            <Route exact path="/usmarket" component={USMarket} />

            <Route exact path="/nhnlChart">
              <NHNLChart />
            </Route>

            <Route
              exact
              path="/topics/indextable"
              component={TopicIndexTable}
            />

            <Route exact path="/topics/stockzoom/:id">
              <TopicStockZoom />
            </Route>

            <Route exact path="/topics/RSChart/:stockCode">
              <RSChartPage></RSChartPage>
            </Route>

            <Route exact path="/topics/market" component={TopicStockMarket} />
            <Route exact path="/zoom/filterlist" component={FilterPage} />
            <Route exact path="/zoom/filter/:id" component={EditFilterPage} />
            <Route exact path="/zoom/picker/:id" component={ZoomPicker} />
            <Redirect from="/zoom" to="/zoom/filterlist" />

            <Route exact path="/favourite" component={FavouritePage} />
            <Route exact path="/covidcasechart" component={CovidCaseChart} />
            <Route
              exact
              path="/topics/covidcasestat"
              component={TopicCovidCaseStat}
            />

            <Route exact path="/blog" component={Blog} />

            {/* <Route exact path="/zoom" component={FilterPage} />
            <Route exact path="/zoom/filter" component={FilterPage} /> */}
            {/* <Route exact path="/zoom/addfilter" component={AddFilterPage} /> */}
            {/* <Route exact path="/zoom/picker" component={ZoomPicker} />
            <Route exact path="/zoomFilter" component={ZoomFilter} />
            <Redirect from="/zoom" to="/zoom/filter" /> */}

            {/* <Route exact path="/filter/:id" component={SingleFilterPage} /> */}
            <Route exact path="/test" component={Test} />
          </Switch>
          <Footer />
        </AppContainer>
      </Router>
    </>
  );
}

export default App;
