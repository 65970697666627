import { makeStyles, IconButton } from '@material-ui/core';
import { db } from '../../../../config/fbConfig';
import { doc, setDoc } from "firebase/firestore";
import EmailIcon from '@material-ui/icons/Email';

import { useState } from 'react';
import { PostMenu } from './PostMenu';
import { useUser } from 'features/user/userSlice';
import { EditPost } from './EditPost';
import { linkRenderer } from "../../../../util/linkRenderer";

const useStyles = makeStyles(theme => ({
  post_container: {
    width: '100%',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  content_container: {
    width: '100%',
    display: 'flex',
    padding: '10px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid gray',
    borderRadius: '20px',
  },
  title_container: {
    display: 'flex',
    flexDirection: 'row',
  },
  content_item: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '5px',
    marginBottom: '5px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'block'
  },
  image_list: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
  },
  image_list_item: {
    margin: '5px',
    padding: '5px',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '10px',
    border: '1px solid gray',
    "&:hover": {
      cursor: 'pointer',
      backgroundColor: 'lightgray',
    },
  },
  image_list_image: {
    height: '180px',
    objectFit: 'contain',
  },
  content: {
    whiteSpace: 'pre-line',
  },
  title: {
    flexGrow: '1',
    marginTop: 'auto',
    marginBottom: 'auto',
    fontSize: 'x-large',
    textAlign: 'center',
    fontWeight: 600,
  },
  title_info: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    fontSize: 'large',
    fontWeight: 700,
  },
  author: {
    fontSize: 'large',
    flexGrow: '1',
  },
  date: {
    fontSize: 'large',
    color: 'gray',
  },
  post_menu: {
  },
}));

export const Post = (props) => {
  const content = props.content;
  const reload = props.reload;
  const setImageModalContent = props.setImageModalContent;
  const classes = useStyles();
  const user = useUser();
  const [ editing, setEditing ] = useState(false);

  const handleEdit = (postID) => {
    setEditing(true)
  }

  const handleDelete = (postID) => {
    const postRef = doc(db, "posts", postID);
    setDoc(postRef, {valid: false}, {merge: true}).then(() => {
      reload();
    });
  }

  let menuOptions = [];
  if (user.uid === content.uid) {
    menuOptions.push({
      value: "修改",
      action: handleEdit,
    });
    menuOptions.push({
      value: "刪除",
      action: handleDelete,
    });
  }

  return (
    <>
      { editing && <EditPost post={content} setEditing={setEditing} reload={reload}/> }
      { !editing &&
        <div className={classes.post_container}>
            <div className={classes.content_container}>
              <div className={classes.content_item}>
                <div className={classes.title_container}>
                  <div>
                    <IconButton disabled={true}><EmailIcon></EmailIcon></IconButton>
                  </div>
                  <div className={classes.title}>
                  { content.title }
                  </div>
                  <div className={classes.post_menu}>
                    <PostMenu options={menuOptions} postID={content.postID}></PostMenu>
                  </div>
                </div>
              </div>
              <div className={classes.content_item}>
                <div className={classes.title_info}>
                  <div className={classes.author}>
                    { content.author }
                  </div>
                  <div className={classes.date}>
                    { (new Date(content.date.seconds*1000)).toLocaleString() }
                  </div>
                </div>
              </div>

              <div className={classes.content_item}>
                <div className={classes.image_list}>
                  {content.images.map((item, index) => (
                    <div className={classes.image_list_item} key={index} onClick={() => setImageModalContent(item)}>
                      <img className={classes.image_list_image} src={item.url} alt={item.description} />
                      <div>
                        {item.description}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className={classes.content_item}>
                <div className={classes.content}>
                  { linkRenderer(content.content) }
                </div>
              </div>
            </div>
        </div>
      }
    </>
  )
}