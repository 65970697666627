import { makeStyles } from '@material-ui/core/styles';
import { useState, useEffect } from "react";

const useStyles = makeStyles((theme => ({
  time: {
    fontWeight: 'bold',
    fontSize: 'large',
  },
  remainTime: {
    fontWeight: 'bold',
    fontSize: 'large',
  },
  dest: {

  },
})))

export default function ETAView(props) {
  const [ dummy, setDummy ] = useState(false);
  const classes = useStyles()

  const eta_time = props.eta ? props.eta.time : null;
  const eta_dest = props.eta ? props.eta.dest : null;
  const remain_min = props.eta ? props.eta.remain_min : null;

  useEffect(() => {
    const interval = setInterval(() => setDummy(!dummy), 500);
    return () => {
      clearInterval(interval);
    }
  });

  const getRemain = (ms => {
    if (Date.parse(eta_time) - (Date.now()) > 0) {
      return getMinus(Date.parse(eta_time) - Date.now()) + " 分 " + getSecond(Date.parse(eta_time) - Date.now()) + " 秒";
    } else {
      return "0 分 0 秒";
    }
  })

  const getMinus = (ms => {
    return '' + parseInt(ms / 60000)
  })

  const getSecond = (ms => {
    let ans = parseInt(parseInt(ms % 60000) / 1000)
    return ("00" + ans).slice(-2);
  })

  let naString = "沒有提供";

  let timeString = "";
  let remainString = "";
  let destString = "";

  if (eta_time && eta_time !== "") {
    timeString = (new Date(Date.parse(eta_time))).toLocaleTimeString('zh-hk', {hour12: false});
    remainString = getRemain(Date.parse(eta_time) - (Date.now()));
    timeString = (new Date(Date.parse(eta_time))).toLocaleTimeString('zh-hk', {hour12: false});
  } else if (remain_min) {
    
    let target_time = new Date();
    let wanted = target_time.getTime() + 60000 * remain_min;
    target_time = new Date(wanted);

    timeString = target_time.toLocaleTimeString('zh-hk', {hour12: false});
    remainString = parseInt(remain_min) + " 分 " + (parseInt(remain_min * 60) % 60) + " 秒";
  }
  if (eta_dest) {
    destString = eta_dest;
  }

  return <>
    <div className={classes.remainTime}>{(remainString !== "") ? remainString : "　" }</div>
    <div className={classes.time}>{(timeString !== "") ? timeString : naString}</div>
    <div className={classes.dest}>{destString !== "" && timeString}</div>
  </>;
}