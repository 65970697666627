import React, { useState, useEffect, Fragment } from "react";
import { Image, Header, Card, Container, Grid } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import MetaTags from "react-meta-tags";
import GoogleAd from "../ads/DisplayAdsLargeBanner";
import DisplayAdsBanner from "../ads/DisplayAdsBanner";
import HomeWidget from "../widget/HomeWidget";
// import { isMobile } from "react-device-detect";

const topicsInfo = [
  {
    title: "港股排行榜",
    src: "images/topics/T_rank.png",
    href: "/rank/active",
    update: "定期更新",
    cat: "stock",
  },
  {
    title: "港股相對強度評級(RS)",
    src: "images/topics/T_RS_HK.png",
    href: "/topics/rs",
    update: "定期更新",
    cat: "stock",
  },
  {
    title: "美股相對強度評級(RS)",
    src: "images/topics/T_RS_US.png",
    href: "/topics/usrs",
    update: "定期更新",
    cat: "stock",
  },
  {
    title: "板塊輪動圖(港股)",
    src: "images/topics/topics_rrg.png",
    href: "/topics/rrghk",
    update: "定期更新",
    cat: "stock",
  },
  {
    title: "股份回購",
    src: "images/topics/topics_buyback.png",
    href: "/topics/buyback",
    update: "定期更新",
    cat: "stock",
  },
  {
    title: "業績公佈時間表",
    src: "images/topics/T_companyResultA.png",
    href: "/topics/resultcalendar",
    update: "定期更新",
    cat: "stock",
  },
  {
    title: "持倉異動",
    src: "images/topics/topics_shareholding_change.png",
    href: "/shareholding/shareholding_change",
    update: "定期更新",
    cat: "stock",
  },
  {
    title: "半新股資訊",
    src: "images/topics/T_listedipo.png",
    href: "/topics/listedipo",
    update: "定期更新",
    cat: "stock",
  },
  {
    title: "交通工具到站預報",
    src: "images/topics/topics_eta.png",
    href: "/eta",
    update: "建設中",
    cat: "life",
  },
  {
    title: "每日金融數據",
    src: "images/topics/T_money.png",
    href: "/topics/hkmonetary",
    update: "定期更新",
    cat: "life",
  },
  // {
  //   title: "社區檢測中心預約情況",
  //   src: "images/topics/topics_covidtest.png",
  //   href: "/topics/covidtest",
  //   update: "定期更新",
  //   cat: "life",
  // },
  // {
  //   title: "疫苗接種統計",
  //   src: "images/topics/T_vaccineStat.png",
  //   href: "/topics/vaccination",
  //   update: "定期更新",
  //   cat: "life",
  // },
  // {
  //   title: "疫苗抽獎及優惠詳情",
  //   src: "images/topics/T_vaccine.png",
  //   href: "/topics/vaccine_luckydraw_info",
  //   update: "定期更新",
  //   cat: "life",
  // },

  // {
  //   title:'$5000 消費劵及優惠',
  //   src:'images/topics/topics_consumption_voucher.png',
  //   href:'/topics/consumption_voucher'
  // },
];

function CardGroupContent() {
  const history = useHistory();

  return (
    <>
      <Card.Group centered>
        {topicsInfo.map((item, index) => (
          <Fragment key={index}>
            <Card
              centered
              key={index}
              onClick={() => {
                history.push(item.href);
              }}
            >
              <Image
                alt={item.title}
                size="big"
                src={item.src}
                centered
                wrapped
                ui={false}
              />
              <Card.Content>
                <Card.Header>{item.title}</Card.Header>
                <Card.Meta>
                  <span className="date">{item.update}</span>
                </Card.Meta>
              </Card.Content>
            </Card>
          </Fragment>
        ))}
      </Card.Group>
    </>
  );
}

function CardGroupContentMobile() {
  const history = useHistory();

  return (
    <div style={{ paddingTop: "25px" }}>
      {topicsInfo.map((item, index) => (
        <>
          <Card
            key={index}
            fluid
            style={{ boxShadow: "none" }}
            onClick={() => {
              history.push(item.href);
            }}
          >
            <Grid verticalAlign="middle">
              <Grid.Column width={6}>
                <Image src={item.src} />
              </Grid.Column>
              <Grid.Column width={10}>
                <Header size="medium">
                  {" "}
                  {item.title}
                  <Header.Subheader>{item.update}</Header.Subheader>
                </Header>
              </Grid.Column>
            </Grid>
          </Card>
        </>
      ))}
    </div>
  );
}

function Topics() {
  const [isMobile, setMobile] = useState(false);
  const [sWidth, setSWidth] = useState(null);

  const handleResize = () => {
    const widths = [window.innerWidth];
    if (window.screen?.width) {
      widths.push(window.screen?.width);
    }
    const width = Math.min(...widths);

    var isMobile = false;
    if (width < 400) {
      isMobile = true;
    }
    setMobile(isMobile);
    setSWidth(width);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <MetaTags>
        <title>專題</title>
        <meta name="description" content="專題" />
        <meta property="og:title" content="專題" />
        <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
      </MetaTags>
      <Container textAlign="center">
        <DisplayAdsBanner />
      </Container>
      <Container
        style={{
          paddingTop: "25px",
          paddingBottom: "25px",
          minHeight: "75vh",
        }}
      >
        <div style={{ textAlign: "center", paddingTop: "25px" }}>
          <Header as="h1">專題</Header>
        </div>
        <div>
          {isMobile ? <CardGroupContentMobile /> : <CardGroupContent />}
        </div>
      </Container>
      <Container
        textAlign="center"
        style={{ paddingTop: "30px", paddingBottom: "0px" }}
      >
        <GoogleAd
          client="ca-pub-8753358837449417"
          slot="2486257452"
          format="auto"
          wrapperDivStyle={{
            marginTop: "0px",
            marginBottom: "0px",
          }}
        />
      </Container>
    </>
  );
}

export default Topics;
