import { useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { Loader, Header } from "semantic-ui-react";
import {
  selectAllTopic,
  fetchTopic,
  toIdle,
} from "../../features/topic/topicSlice";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

function zip(arrays) {
  return Array.apply(null, Array(arrays[0].length)).map(function (_, i) {
    return arrays.map(function (array) {
      return array[i];
    });
  });
}

function CovidCaseChart() {
  const dispatch = useDispatch();
  const topics = useSelector(selectAllTopic);
  const topicStatus = useSelector((state) => state.topic.status);
  const error = useSelector((state) => state.topic.error);
  const [covidStat, setCovidStat] = useState({
    data: null,
    loaded: false,
  });

  useEffect(() => {
    if (topicStatus === "idle" && !covidStat.loaded) {
      dispatch(fetchTopic("covidCaseSummary"));
    } else if (topicStatus === "loading") {
    } else if (topicStatus === "succeeded") {
      const data = topics.find(
        (item) => item.topicInfo.name === "covidCaseSummary"
      );
      if (data) {
        setCovidStat({
          ...covidStat,
          data: data.data,
          loaded: true,
        });
      } else {
        dispatch(toIdle({}));
      }
    } else if (topicStatus === "error") {
    }
  }, [topicStatus, dispatch]);

  //   var windowWidth = window.innerWidth;
  const chartHeight = 550;
  let cumData = [];
  let dailyData = [];
  let chartOption = {};

  if (covidStat.loaded && covidStat.data.date.length > 0) {
    // console.log(covidStat);
    let dates = covidStat.data["date"].map((item) => new Date(item).getTime());
    var groupingUnits = [
      [
        "day", // unit name
        [1], // allowed multiples
      ],
      ["month", [1, 2, 3, 4, 6]],
    ];
    cumData.push({
      yAxis: 0,
      name: "cumData",
      data: zip([dates, covidStat.data["total"]]),
    });
    dailyData.push({
      yAxis: 1,
      name: "dailyData",
      data: zip([dates, covidStat.data["d2"]]),
    });

    chartOption = {
      rangeSelector: {
        selected: 1,
      },

      navigator: {
        series: {
          lineWidth: 1,
          lineColor: "#2F4F4F",
          color: "#2F4F4F",
          fillOpacity: 0.2,
        },
      },

      legend: {
        enabled: true,
        align: "center",
        verticalAlign: "bottom",
        x: 0,
        y: 0,
      },

      chart: {
        height: chartHeight,
        marginLeft: 10,
        marginRight: 10,
      },

      xAxis: {
        type: "datetime",
      },

      yAxis: [
        {
          endOnTick: false,
          startOnTick: false,
          labels: {
            style: {
              color: "black",
            },
            align: "right",
            x: -2,
          },
          title: {
            text: "累計個案",
            align: "high",
            rotation: 0,
            x: -50,
            style: {
              color: Highcharts.getOptions().colors[1],
            },
          },
          resize: {
            enabled: true,
          },
        },
        {
          opposite: false,
          min: 0,
          labels: {
            style: {
              color: "black",
            },
            align: "left",
            x: 10,
          },
          title: {
            text: "每日新增",
            align: "high",
            rotation: 0,
            x: 60,
            style: {
              color: Highcharts.getOptions().colors[1],
            },
          },
          top: "65%",
          height: "35%",
          offset: 0,
          resize: {
            enabled: true,
          },
          gridLineWidth: 0,
        },
      ],

      tooltip: {
        shared: true,
      },

      scrollbar: {
        enabled: true,
      },

      series: [
        {
          type: "line",
          name: "累計個案",
          data: cumData[0].data,
          yAxis: 0,
          dataGrouping: {
            units: groupingUnits,
          },
          color: "#EB7E30",
          visible: true,
        },
        {
          type: "column",
          name: "每日新增",
          data: dailyData[0].data,
          yAxis: 1,
          dataGrouping: {
            units: groupingUnits,
          },
          color: "grey",
        },
      ],
      credits: {
        text: "DaaSHK",
        style: {
          fontSize: "0.8em",
        },
      },
    };
  }
  return (
    <>
      {covidStat.data && covidStat.data.date.length > 0 ? (
        <div style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <Header as="h3">疫情個案統計</Header>
          <div>
            <HighchartsReact
              constructorType={"stockChart"}
              highcharts={Highcharts}
              options={chartOption}
            />
          </div>
        </div>
      ) : !covidStat.loaded ? (
        <Loader active inline="centered" />
      ) : (
        <div>沒有資料</div>
      )}
    </>
  );
}

export default CovidCaseChart;
