import React, { useEffect } from "react";
import { Loader, Container} from 'semantic-ui-react'
// import _ from 'lodash'
import MetaTags from 'react-meta-tags';
import VaccineTableEn from '../tables/VaccineTableEn'
import { selectAllTopic, fetchTopic, toIdle } from '../../features/topic/topicSlice'
import { useSelector, useDispatch } from 'react-redux'
import DisplayAdsBanner from '../ads/DisplayAdsBanner_vac';


export const TopicVaccineEn = () => {
  const dispatch = useDispatch()
  const topics = useSelector(selectAllTopic)
  const topicStatus = useSelector((state) => state.topic.status)
  const error = useSelector((state) => state.topic.error)

  useEffect(() => {
    if (topicStatus === 'idle') {
      dispatch(fetchTopic('vaccine_en'))
    }
  }, [topicStatus, dispatch])

  let content

  if (topicStatus === 'loading') {
    content =
      <div>
        <section className="posts-list">
          <div
            style={{
              padding: "100px",
              margin: "auto",
              alignItems: "center",
              height: "75vh",
              // overflow: "auto",
            }}
          >
            <Loader active inline='centered' > Loading</Loader>
          </div>
        </section>
      </div>
  } else if (topicStatus === 'succeeded') {
    const data = topics.find((item) => item.topicInfo.name === 'vaccine_en')
    if (data) {
      content = <VaccineView result={data} />
    }
    else {
      dispatch(toIdle({}))
    }
  } else if (topicStatus === 'error') {
    content = 
      <div>
        <section className="posts-list">
          <div>{error}</div>
        </section>
      </div>
  }
  return (
    <>
      <MetaTags>
        <title>Vaccination Lucky Draw and Discount Details</title>
        <meta name="description" content='Vaccination Lucky Draw and Discount Details' />
        <meta name="keywords" content='Covid vaccination perks, Vaccination Lucky Draw, Vaccination Discount, covid vaccine, covid vaccine hong kong, covid vaccine incentives' />
        <meta property="og:description" content="Vaccination Lucky Draw and Discount Details" />
        <meta property="og:title" content='Vaccination Lucky Draw and Discount Details' />
        <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
      </MetaTags>
      {content}
    </>
  )
}

function VaccineView(data) {
  let result = data.result
  return (
    <>
      <Container textAlign='center'>
        <DisplayAdsBanner />
      </Container>
      <div style={{ paddingTop: '25px', paddingBottom: '25px' }}>
        {typeof result !== 'undefined' ? <VaccineTableEn data={result} /> : <NotFound />}
      </div>
    </>
  )
}

function NotFound() {
  return (
    <div
      style={{
        padding: "100px",
        margin: "auto",
        alignItems: "center",
        height: "75vh",
        // overflow: "auto",
      }}
    >
      <Loader active inline='centered' > Loading</Loader>
    </div>
  )
}

export default TopicVaccineEn;