import React, { useEffect } from "react";
import { Loader, Card, Button, Container } from "semantic-ui-react";
import {
  selectAllTopic,
  fetchTopic,
  fetchTopicwM,
  toIdle,
} from "../../../features/topic/topicSlice";
import { useSelector, useDispatch } from "react-redux";
import {
  // useIsLoggedIn,
  useUserFavourites,
  useAllowedBehindPaywall,
} from "features/user/userSlice";
import { Link } from "react-router-dom";
// import { FirebaseLoginCard } from "components/auth/FirebaseLoginForm";
import RSTableContainer from "./RSTableContainer";
import _ from "lodash";

function RSView() {
  const allowedBehindPaywall = useAllowedBehindPaywall();
  return (
    <div style={{ paddingTop: "25px" }}>
      {/* <>{allowedBehindPaywall ? <RSViewContainer /> : <AskForSubscribe />}</> */}
      <RSViewContainer />
    </div>
  );
}

// export const RSViewContainer = () => {
//   const allowedBehindPaywall = useAllowedBehindPaywall();
//   const wishList = useUserFavourites();
//   return (
//     <RSTableContainer
//       data={data}
//       stockSector={stockSector}
//       wishList={wishList}
//       allowedBehindPaywall={allowedBehindPaywall}
//     />
//   );
// };

export const RSViewContainer = () => {
  // const isLoggedIn = useIsLoggedIn();
  const allowedBehindPaywall = useAllowedBehindPaywall();
  const wishList = useUserFavourites();
  const dispatch = useDispatch();
  const topics = useSelector(selectAllTopic);
  const topicStatus = useSelector((state) => state.topic.status);
  const error = useSelector((state) => state.topic.error);
  useEffect(() => {
    if (topicStatus === "idle") {
      if (
        topics.find((item) => item.topicInfo.name === "us_rs_from_aws") ===
        undefined
      ) {
        dispatch(fetchTopic("us_rs_from_aws"));
      }
      if (
        topics.find((item) => item.topicInfo.name === "stockDict_all_n") ===
        undefined
      ) {
        dispatch(fetchTopicwM("stockDict_all_n"));
      }
    }
  }, [topicStatus, topics, dispatch]);

  let content;
  if (topicStatus === "loading") {
    content = (
      <div>
        <section className="posts-list">
          <div
            style={{
              padding: "100px",
              margin: "auto",
              alignItems: "center",
              height: "75vh",
            }}
          >
            <Loader active inline="centered">
              {" "}
              Loading
            </Loader>
          </div>
        </section>
      </div>
    );
  } else if (topicStatus === "succeeded") {
    const data = topics.find(
      (item) => item.topicInfo.name === "us_rs_from_aws"
    );
    const stockDict = topics.find(
      (item) => item.topicInfo.name === "stockDict_all_n"
    );
    if (data && stockSector && stockDict) {
      content = (
        <RSTableContainer
          data={data}
          stockDict={_.filter(stockDict["data"], function (o) {
            return o.m === "0";
          })}
          stockSector={stockSector}
          wishList={wishList}
          allowedBehindPaywall={allowedBehindPaywall}
        />
      );
    } else {
      dispatch(toIdle({}));
    }
  } else if (topicStatus === "error") {
    content = (
      <div>
        <section className="posts-list">
          <div>{error}</div>
        </section>
      </div>
    );
  }
  return <>{content}</>;
};

export default RSView;

function AskForSubscribe() {
  return (
    <div style={{ margin: "auto" }}>
      <Card centered style={{ textAlign: "center" }}>
        <Card.Content>
          <Card.Header>加入付費版，以體驗進階功能</Card.Header>
        </Card.Content>
        <Card.Content extra>
          <Link to="/subscription">
            <Button basic color="blue">
              了解更多
            </Button>
          </Link>
        </Card.Content>
      </Card>
    </div>
  );
}

const stockSector = {
  ind: {
    iDict: {
      0: "",
      1: "Accident &Health Insurance",
      2: "Advertising",
      3: "Aerospace",
      4: "Agricultural Chemicals",
      5: "Air Freight/Delivery Services",
      6: "Aluminum",
      7: "Apparel",
      8: "Assisted Living Services",
      9: "Auto & Home Supply Stores",
      10: "Auto Manufacturing",
      11: "Auto Parts:O.E.M.",
      12: "Automotive Aftermarket",
      13: "Banks",
      14: "Beverages (Production/Distribution)",
      15: "Beverages - Non-Alcoholic",
      16: "Biotechnology: Biological Products (No Diagnostic Substances)",
      17: "Biotechnology: Commercial Physical & Biological Resarch",
      18: "Biotechnology: Electromedical & Electrotherapeutic Apparatus",
      19: "Biotechnology: In Vitro & In Vivo Diagnostic Substances",
      20: "Biotechnology: Laboratory Analytical Instruments",
      21: "Biotechnology: Pharmaceutical Preparations",
      22: "Books",
      23: "Broadcasting",
      24: "Building Materials",
      25: "Building Products",
      26: "Building operators",
      27: "Business Services",
      28: "Cable & Other Pay Television Services",
      29: "Catalog/Specialty Distribution",
      30: "Clothing/Shoe/Accessory Stores",
      31: "Coal Mining",
      32: "Commercial Banks",
      33: "Computer Communications Equipment",
      34: "Computer Manufacturing",
      35: "Computer Software: Prepackaged Software",
      36: "Computer Software: Programming Data Processing",
      37: "Computer peripheral equipment",
      38: "Construction Materials",
      39: "Construction/Ag Equipment/Trucks",
      40: "Consumer Electronics/Appliances",
      41: "Consumer Electronics/Video Chains",
      42: "Consumer Specialties",
      43: "Containers/Packaging",
      44: "Department/Specialty Retail Stores",
      45: "Diversified Commercial Services",
      46: "Diversified Electronic Products",
      47: "Diversified Financial Services",
      48: "Diversified Manufacture",
      49: "Durable Goods",
      50: "EDP Peripherals",
      51: "EDP Services",
      52: "Electric Utilities: Central",
      53: "Electrical Products",
      54: "Electronic Components",
      55: "Electronics Distribution",
      56: "Engineering & Construction",
      57: "Environmental Services",
      58: "Farming/Seeds/Milling",
      59: "Finance Companies",
      60: "Finance/Investors Services",
      61: "Finance: Consumer Services",
      62: "Fluid Controls",
      63: "Food Chains",
      64: "Food Distributors",
      65: "Forest Products",
      66: "Home Furnishings",
      67: "Homebuilding",
      68: "Hospital/Nursing Management",
      69: "Hotels/Resorts",
      70: "Industrial Machinery/Components",
      71: "Industrial Specialties",
      72: "Integrated oil Companies",
      73: "Interactive Media",
      74: "Internet and Information Services",
      75: "Investment Bankers/Brokers/Service",
      76: "Investment Managers",
      77: "Life Insurance",
      78: "Major Banks",
      79: "Major Chemicals",
      80: "Managed Health Care",
      81: "Marine Transportation",
      82: "Meat/Poultry/Fish",
      83: "Medical Electronics",
      84: "Medical Specialities",
      85: "Medical/Dental Instruments",
      86: "Medical/Nursing Services",
      87: "Medicinal Chemicals and Botanical Products",
      88: "Metal Fabrications",
      89: "Metal Mining",
      90: "Military/Government/Technical",
      91: "Miscellaneous manufacturing industries",
      92: "Motor Vehicles",
      93: "Movies/Entertainment",
      94: "Multi-Sector Companies",
      95: "Natural Gas Distribution",
      96: "Newspapers/Magazines",
      97: "Office Equipment/Supplies/Services",
      98: "Oil & Gas Production",
      99: "Oil Refining/Marketing",
      100: "Oil/Gas Transmission",
      101: "Oilfield Services/Equipment",
      102: "Ophthalmic Goods",
      103: "Ordnance And Accessories",
      104: "Other Consumer Services",
      105: "Other Metals and Minerals",
      106: "Other Pharmaceuticals",
      107: "Other Specialty Stores",
      108: "Package Goods/Cosmetics",
      109: "Packaged Foods",
      110: "Paints/Coatings",
      111: "Paper",
      112: "Plastic Products",
      113: "Pollution Control Equipment",
      114: "Power Generation",
      115: "Precious Metals",
      116: "Precision Instruments",
      117: "Professional Services",
      118: "Property-Casualty Insurers",
      119: "Publishing",
      120: "RETAIL: Building Materials",
      121: "Radio And Television Broadcasting And Communications Equipment",
      122: "Railroads",
      123: "Real Estate",
      124: "Real Estate Investment Trusts",
      125: "Recreational Games/Products/Toys",
      126: "Rental/Leasing Companies",
      127: "Restaurants",
      128: "Retail-Auto Dealers and Gas Stations",
      129: "Retail-Drug Stores and Proprietary Stores",
      130: "Retail: Computer Software & Peripheral Equipment",
      131: "Savings Institutions",
      132: "Security Systems Services",
      133: "Semiconductors",
      134: "Service to the Health Industry",
      135: "Services-Misc. Amusement & Recreation",
      136: "Shoe Manufacturing",
      137: "Specialty Chemicals",
      138: "Specialty Foods",
      139: "Specialty Insurers",
      140: "Steel/Iron Ore",
      141: "Telecommunications Equipment",
      142: "Textiles",
      143: "Tobacco",
      144: "Tools/Hardware",
      145: "Transportation Services",
      146: "Trucking Freight/Courier Services",
      147: "Trusts Except Educational Religious and Charitable",
      148: "Water Sewer Pipeline Comm & Power Line Construction",
      149: "Water Supply",
      150: "Wholesale Distributors",
    },
    sDict: {
      0: "",
      1: "Basic Materials",
      2: "Consumer Discretionary",
      3: "Consumer Staples",
      4: "Energy",
      5: "Finance",
      6: "Health Care",
      7: "Industrials",
      8: "Miscellaneous",
      9: "Real Estate",
      10: "Technology",
      11: "Telecommunications",
      12: "Utilities",
    },
    cDict: {
      0: "",
      1: "Argentina",
      2: "Australia",
      3: "Bahamas",
      4: "Belgium",
      5: "Bermuda",
      6: "Brazil",
      7: "Canada",
      8: "Cayman Islands",
      9: "Chile",
      10: "China",
      11: "Colombia",
      12: "Costa Rica",
      13: "Curacao",
      14: "Cyprus",
      15: "Denmark",
      16: "Finland",
      17: "France",
      18: "Germany",
      19: "Gibraltar",
      20: "Greece",
      21: "Guernsey",
      22: "Hong Kong",
      23: "India",
      24: "Indonesia",
      25: "Ireland",
      26: "Isle of Man",
      27: "Israel",
      28: "Italy",
      29: "Japan",
      30: "Jersey",
      31: "Jordan",
      32: "Kazakhstan",
      33: "Luxembourg",
      34: "Macau",
      35: "Malaysia",
      36: "Malta",
      37: "Mexico",
      38: "Monaco",
      39: "Netherlands",
      40: "New Zealand",
      41: "Norway",
      42: "Panama",
      43: "Peru",
      44: "Philippines",
      45: "Puerto Rico",
      46: "Singapore",
      47: "South Africa",
      48: "South Korea",
      49: "Spain",
      50: "Sweden",
      51: "Switzerland",
      52: "Taiwan",
      53: "Turkey",
      54: "United Arab Emirates",
      55: "United Kingdom",
      56: "United States",
      57: "Uruguay",
    },
  },
  topicInfo: { lastUpdate: "2022/09/22 17:54", name: "USstockSectorInfo" },
};
