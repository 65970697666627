import { Input, TextField, makeStyles, Button, IconButton } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useState } from 'react';
import { useUser } from '../../../../features/user/userSlice';
import { storage, db } from '../../../../config/fbConfig';
import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { LinearProgressWithLabel } from './LinearProgressWithLabel';

const useStyles = makeStyles(theme => ({
  new_post_container: {
    width: '100%',
  },
  form_container: {
    width: '100%',
    display: 'flex',
    paddingLeft: '10px',
    paddingRight: '10px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid gray',
    borderRadius: '20px',
  },
  file: {
    display: 'none',
  },
  file_button: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '50%',
  },
  form_item: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '5px',
    marginBottom: '5px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'block'
  },
  general_button_container: {
    textAlign: 'left',
  },
  general_button: {
    width: '200px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  close_button: {
    marginLeft: 'auto',
    float: 'right',
  },
  image_list: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
  },
  image_list_item: {
    margin: '5px',
    padding: '5px',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '10px',
    border: '1px solid gray',
  },
  image_list_image: {
    height: '180px',
    objectFit: 'cover',
  },
  close_icon: {
    color: theme.palette.primary.light,
  },
}));

const addUserPost = async (uid, post) => {
  try {
    const count = await getPostCount(uid);
    const postName = uid + "_" + ("000000000" + (count)).slice(-10);
    const postRef = doc(db, "posts", postName);
    await setDoc(postRef, post);
    await setPostCount(uid, count + 1);
  } catch (error) {
    console.log("addUserPost,Error");
    console.log(post);
    console.log(error);
  }
}
const getPostCount = async (uid) => {
  let count = 0;
  try {
    const snapshot = await getDoc(doc(db, "users", uid));
    if (snapshot.exists()) {
      const data = snapshot.data();
      if (data["post_count"]) {
        count = data["post_count"];
      }
    }
  } catch (error) {
    console.log("getPostCount,Error");
    console.log(error);
  }
  return count;
}

const setPostCount = async (uid, count) => {
  try {
    const citiesRef = collection(db, "users");
    await setDoc(doc(citiesRef, uid), {post_count: count}, { merge: true });
  } catch (error) {
    console.log("setPostCount,Error");
    console.log(count);
    console.log(error);
  }
}

export const NewPost = (props) => {
  const reload = props.reload;
  const user = useUser();
  const [ images, setImages ] = useState([]);
  const [ title, setTitle ] = useState("");
  const [ content, setContent ] = useState("");
  const [ completedUploadedBytes, setCompletedUploadedBytes ] = useState([]);
  const [ totalUploadedBytes, setTotalUploadedBytes ] = useState([]);
  const [ posting, setPosting ] = useState(false);
  const [ visible, setVisible ] = useState(false);
  const classes = useStyles();

  const handleTitle = (event) => {
    setTitle(event.target.value);
  };

  const handleContent = (event) => {
    setContent(event.target.value);
  };

  const removeImage = (event, index) => {
    let temp = [...images];
    temp.splice(index, 1);
    setImages(temp);
  };

  const handleImageDescription = (event, index) => {
    let temp = [...images];
    temp[index] = {
      ...temp[index],
      description: event.target.value,
    }
    setImages(temp);
  };

  const handleImage = (event) => {
    let file = event.target.files[0];
    let ext = file.name.split(".").pop().toLowerCase();
    let reader = new FileReader();
      reader.onload = function(event_internal) {
        let temp = {
          src: event_internal.target.result,
          file: file,
          ref: "images/" + user.uid + "/" + Date.now() + "." + ext,
          description: "",
        };
        event.target.value = "";
        setImages([...images, temp]);
    }
    reader.readAsDataURL(file);
  }

  const handleClick = () => {
    setPosting(true);

    let tempUploadedSize = [];
    let tempTotalSize = [];
    images.forEach((image, i) => {
      tempUploadedSize[i] = 0;
      tempTotalSize[i] = image.file.size;
    });
    setTotalUploadedBytes(tempTotalSize);
    setCompletedUploadedBytes(tempUploadedSize);

    let promises = [];

    if (images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        const storageRef = ref(storage, images[i].ref);
        const uploadTask = uploadBytesResumable(storageRef, images[i].file);
  
        uploadTask.on('state_changed',
          (snapshot) => {
            let temp = [...completedUploadedBytes];
            temp[i] = snapshot.bytesTransferred;
            setCompletedUploadedBytes(temp);
            switch (snapshot.state) {
              case 'paused':
                break;
              case 'running':
                break;
              default:
                break;
            }
          }, 
          (error) => {
            console.log(error);
          }
        )
  
        promises.push(uploadTask.then(() => {
          return getDownloadURL(uploadTask.snapshot.ref);
        }));
      }
      Promise.all(promises).then((downloadURLs) => {
  
        let temp = [];
        for (let index = 0; index < downloadURLs.length; index++) {
          temp.push({
            url: downloadURLs[index],
            ref: images[index].ref,
            description: images[index].description,
          })
        }
        
        addUserPost(user.uid, {
          date: new Date(),
          author: user.displayName,
          uid: user.uid,
          email: user.email,
          title: title,
          photoURL: user.photoURL,
          content: content,
          images: temp,
          valid: true,
        }).then(() => {
          setImages([]);
          setTitle("");
          setContent("");
          setCompletedUploadedBytes([]);
          setTotalUploadedBytes([]);
          setPosting(false);
          setVisible(false);
          reload();
        })
      })
    } else {
      addUserPost(user.uid, {
        date: new Date(),
        author: user.displayName,
        uid: user.uid,
        title: title,
        content: content,
        images: [],
        valid: true,
      }).then(() => {
        setImages([]);
        setTitle("");
        setContent("");
        setCompletedUploadedBytes([]);
        setTotalUploadedBytes([]);
        setPosting(false);
        setVisible(false);
        reload();
      });
    }
  };

  let progress = 0;
  let completed = 0;
  for (let i in completedUploadedBytes) {
    completed += completedUploadedBytes[i];
  }
  let total = 0;
  for (let i in totalUploadedBytes) {
    total += totalUploadedBytes[i];
  }
  if (total === 0) {
    progress = 0;
  } else {
    progress = completed * 100 / total;
  }

  const canPost = (title.length > 0) && (content.length > 0);

  return (
    <>
      <div className={classes.new_post_container}>
        { !visible && <>
          <div className={classes.form_item}>
            <div className={classes.general_button_container}>
              <Button className={classes.general_button} onClick={() => setVisible(true)} variant="contained" color="primary">分享</Button>
            </div>
          </div>
        </> }
        { visible && <>
          <div className={classes.form_container}>
            <div className={classes.form_item}>
              <IconButton className={classes.close_button} onClick={(event) => setVisible(false)}>
                <HighlightOffIcon className={classes.close_icon} />
              </IconButton>
            </div>
            <div className={classes.form_item}>
              <TextField
                value={title}
                onChange={handleTitle}
                fullWidth
                label="標題"
                variant="outlined" />
            </div>

            <div className={classes.form_item}>
              <div className={classes.image_list}>
                {images.map((item, index) => (
                  <div className={classes.image_list_item} key={index}>
                    <IconButton className={classes.close_button} onClick={(event) => removeImage(event, index)}>
                      <HighlightOffIcon className={classes.title} />
                    </IconButton>
                    <img className={classes.image_list_image} src={item.src} alt={item.file.name} />
                    <Input 
                      value={item.description}
                      onChange={(event) => handleImageDescription(event, index)}
                      fullWidth
                      label="內容"
                      variant="standard" />
                  </div>
                ))}
              </div>
            </div>
            
            <div className={classes.form_item}>
              <input
                className={classes.file}
                id="file_input_add_photo"
                onChange={handleImage}
                type="file"
                accept="image/png, image/jpeg"/>
              <div className={classes.general_button_container}>
                <Button className={classes.general_button} onClick={() => document.getElementById("file_input_add_photo").click()} variant="contained" color="primary">加入照片</Button>
              </div>
            </div>
            <div className={classes.form_item}>
              <TextField
                value={content}
                onChange={handleContent}
                fullWidth
                label="內容"
                multiline
                rows={4}
                variant="outlined"
                />
            </div>
            <div className={classes.form_item}>
              <div className={classes.general_button_container}>
                <Button className={classes.general_button} disabled={!canPost} onClick={() => handleClick()} variant="contained" color="primary">發送</Button>
              </div>
            </div>
            { posting && 
              <div className={classes.form_item}>
                <div className={classes.general_button_container}>
                  <LinearProgressWithLabel value={progress} />
                </div>
              </div>
            }
          </div>
        </> }
      </div>
    </>
  )
}