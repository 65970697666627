import React, { useEffect } from "react";
import { Loader } from "semantic-ui-react";
import {
  selectAllTopic,
  fetchTopic,
  toIdle,
} from "../../../features/topic/topicSlice";
import { useSelector, useDispatch } from "react-redux";
import RRGView from "./RRGView";
import { useAllowedBehindPaywall } from "features/user/userSlice";

function RRGContainer() {
  const dispatch = useDispatch();
  const allowedBehindPaywall = useAllowedBehindPaywall();
  const topics = useSelector(selectAllTopic);
  const topicStatus = useSelector((state) => state.topic.status);
  const error = useSelector((state) => state.topic.error);
  useEffect(() => {
    if (topicStatus === "idle") {
      if (topics.find((item) => item.topicInfo.name === "rrg") === undefined) {
        dispatch(fetchTopic("rrg"));
      }
    }
  }, [topicStatus, topics, dispatch]);
  let content;

  if (topicStatus === "loading") {
    content = (
      <div>
        <section className="posts-list">
          <div
            style={{
              padding: "100px",
              margin: "auto",
              alignItems: "center",
              height: "75vh",
              // overflow: "auto",
            }}
          >
            <Loader active inline="centered">
              {" "}
              Loading
            </Loader>
          </div>
        </section>
      </div>
    );
  } else if (topicStatus === "succeeded") {
    const data = topics.find((item) => item.topicInfo.name === "rrg");
    // console.log(data);
    if (data) {
      content = (
        <RRGView
          data={data}
          allowedBehindPaywall={allowedBehindPaywall}
          market={"hk"}
        />
      );
    } else {
      dispatch(toIdle({}));
    }
  } else if (topicStatus === "error") {
    content = (
      <div>
        <section className="posts-list">
          <div>{error}</div>
        </section>
      </div>
    );
  }
  return <>{content}</>;
}

export default RRGContainer;
