import React from "react";
import "./StockChart.css";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { isMobile } from "react-device-detect";
import _ from "lodash";
import { Link } from "react-router-dom";

const fontSizePct = isMobile ? "85%" : "100%";

function StockChart2T(chartData) {
  var olddata = chartData.chartData.mainChartData;
  var stockInfo = chartData.chartData.stockInfo;
  var isHKConnect = stockInfo.isHKConnect;
  // var isHKConnect = 0
  var windowWidth = window.innerWidth;
  // const chartHeight =
  //   0.656 * windowWidth < 550
  //     ? 550
  //     : 0.656 * windowWidth > 633
  //     ? 633
  //     : 0.656 * windowWidth;
  const chartHeight =
    0.656 * windowWidth < 600
      ? 600
      : 0.656 * windowWidth > 675
      ? 675
      : 0.656 * windowWidth;
  function obj2Array(n) {
    return Object.values(n);
  }
  function volumeBarColor(open, close) {
    if (open < close) return "Green";
    if (open > close) return "red";
    return "grey";
  }
  var data = _.map(olddata, obj2Array);

  var ohlc = [],
    volume = [],
    nPart = [],
    conc3 = [],
    conc5 = [],
    conc10 = [],
    nonccass = [],
    ncip = [],
    totShare = [],
    hkConnectPct = [],
    dataLength = data.length,
    groupingUnits = [
      [
        "day", // unit name
        [1], // allowed multiples
      ],
      ["week", [1]],
      // ["month", [1, 2, 3, 4, 6]],
    ],
    i = 0;

  for (i; i < dataLength; i += 1) {
    let d = data[i][0];
    // console.log('check HkConnect')
    // console.log( d.length)
    // console.log(d)
    ohlc.push([
      d[0], // the date
      d[3], // open
      d[1], // high
      d[2], // low
      d[4], // close
    ]);
    nPart.push([
      d[0], // the date
      d[7], // the nPart
    ]);

    volume.push({
      x: d[0], // the date
      y: d[5], // the volume
      color: volumeBarColor(d[3], d[4]),
    });

    conc3.push([d[0], d[8]]);

    conc5.push([d[0], d[9]]);

    conc10.push([d[0], d[10]]);

    nonccass.push([d[0], d[11]]);

    ncip.push([d[0], Math.round(d[12] * 10) / 10]);

    totShare.push([d[0], Math.round((d[13] / 10000) * 100) / 100]);

    if (isHKConnect) {
      hkConnectPct.push([d[0], d[14]]);
    }
  }

  const options = {
    chart: {
      height: chartHeight,
      // plotBackgroundImage: `${process.env.PUBLIC_URL}/images/watermark.svg`
    },

    rangeSelector: {
      selected: isMobile ? 1 : 2,
      dropdown: "never",
    },

    legend: {
      enabled: true,
      align: "center",
      /* layout: 'vertical', */
      verticalAlign: "bottom",
      x: 0,
      y: 0,
    },

    yAxis: [
      {
        // Primary yAxis
        labels: {
          style: {
            color: "black",
          },
          //align: 'left',
          align: "right",
          x: -2,
        },
        title: {
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        opposite: true,
        // startOnTick: false,
        endOnTick: false,
      },
      {
        // Secondary yAxis
        labels: {
          enabled: false,
        },
        opposite: false,
        endOnTick: false,
        gridLineWidth: 0,
      },
      {
        // third yAxis
        labels: {
          enabled: false,
        },
        opposite: false,
        endOnTick: false,
        gridLineWidth: 0,
      },
      {
        // fourth yAxis
        labels: {
          enabled: false,
        },
        opposite: false,
        endOnTick: false,
        gridLineWidth: 0,
      },
      {
        // fifth yAxis
        labels: {
          enabled: false,
        },
        opposite: false,
        endOnTick: false,
        gridLineWidth: 0,
      },
      {
        // Sixth yAxis
        labels: {
          enabled: false,
        },
        opposite: false,
        endOnTick: false,
        gridLineWidth: 0,
      },
      {
        //Seventh
        labels: {
          enabled: false,
        },
        opposite: false,
        endOnTick: false,
        gridLineWidth: 0,
      },
      {
        // eightth
        labels: {
          enabled: false,
        },
        opposite: false,
        endOnTick: false,
        gridLineWidth: 0,
      },
      {
        // nineth
        labels: {
          enabled: false,
        },
        opposite: false,
        endOnTick: false,
        gridLineWidth: 0,
      },
      {
        opposite: false,
        min: 0,
        labels: {
          style: {
            color: "black",
          },
          align: "left",
          x: 3.5,
        },
        title: {
          text: "",
        },
        top: "75%",
        height: "25%",
        offset: 0,
        gridLineWidth: 0,
        // lineWidth: 2
      },
    ],

    plotOptions: {
      candlestick: {
        lineColor: "red",
        color: "white",
        upLineColor: "black",
        upColor: "Green",
      },
    },

    navigator: {
      series: {
        lineWidth: 1,
        lineColor: "#2F4F4F",
        color: "#2F4F4F",
        fillOpacity: 0.2,
      },
    },

    tooltip: {
      // split: true
      shared: true,
    },

    scrollbar: {
      enabled: true,
    },

    series: [
      {
        type: "candlestick",
        name: stockInfo.name_zh,
        data: ohlc,
        yAxis: 0,
        dataGrouping: {
          units: groupingUnits,
        },
        showInLegend: false,
      },
      {
        type: "line",
        name: "參與者數目",
        data: nPart,
        yAxis: 1,
        dataGrouping: {
          units: groupingUnits,
        },
        color: "#0072ce",
      },
      {
        type: "line",
        name: "股權-Top3",
        data: conc3,
        yAxis: 2,
        visible: false,
        dataGrouping: {
          units: groupingUnits,
        },
        color: "red",
        tooltip: {
          valueSuffix: " %",
        },
      },
      {
        type: "line",
        name: "股權-Top5",
        data: conc5,
        yAxis: 3,
        visible: true,
        dataGrouping: {
          units: groupingUnits,
        },
        color: "#EB7E30",
        tooltip: {
          valueSuffix: " %",
        },
      },
      {
        type: "line",
        name: "股權-Top10",
        data: conc10,
        yAxis: 4,
        visible: false,
        dataGrouping: {
          units: groupingUnits,
        },
        color: "#F8C753",
        tooltip: {
          valueSuffix: " %",
        },
      },
      {
        type: "line",
        name: "股權-nonCCASS",
        data: nonccass,
        yAxis: 5,
        visible: false,
        dataGrouping: {
          units: groupingUnits,
        },
        color: "grey",
        tooltip: {
          valueSuffix: " %",
        },
      },
      {
        type: "line",
        name: "股權-NCIP",
        data: ncip,
        yAxis: 6,
        visible: false,
        dataGrouping: {
          units: groupingUnits,
        },
        color: "brown",
        tooltip: {
          valueSuffix: " %",
        },
      },
      {
        type: "line",
        name: "總股數",
        data: totShare,
        yAxis: 7,
        visible: false,
        dataGrouping: {
          units: groupingUnits,
        },
        color: "black",
        tooltip: {
          valueSuffix: " 億股",
        },
      },
      {
        type: "column",
        name: "成交量",
        data: volume,
        yAxis: 9,
        dataGrouping: {
          units: groupingUnits,
        },
        color: "grey",
        opacity: 0.6,
        showInLegend: false,
      },
    ],
    credits: {
      text: "DaaSHK",
      href: "https://daashk.com/",
      style: {
        fontSize: "1em",
      },
    },
  };

  if (isHKConnect) {
    options.series.push({
      type: "line",
      name: "港股通%",
      data: hkConnectPct,
      yAxis: 8,
      visible: true,
      dataGrouping: {
        units: groupingUnits,
      },
      color: "purple",
      tooltip: {
        valueSuffix: " %",
      },
    });
  }

  return (
    <div className="chart">
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"stockChart"}
        options={options}
      />

      <div style={{ fontSize: fontSizePct }}>
        <span style={{ color: "#0072ce" }}>藍線</span>
        :股票在中央結算系統紀錄的參與者數目變化{" "}
        <Link to="/about">(更多詳情)</Link> <br />
        <span style={{ color: "red" }}>紅</span>/
        <span style={{ color: "#EB7E30" }}>橙</span>/
        <span style={{ color: "#F8C753" }}>黃</span>/
        <span style={{ color: "purple" }}>紫</span>
        線:分別代表股票頭3/5/10大持券商合共/港股通的持股比例 <br />
        <span style={{ color: "grey" }}>灰</span>/
        <span style={{ color: "brown" }}>啡</span>/
        <span style={{ color: "black" }}>黑</span>
        線:分別代表不在中央結算(nonCCASS)/不願意披露的投資者(NCIP)的持股比例及總巳發行股份
      </div>
    </div>
  );
}

export default StockChart2T;
