import { useEffect, useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { makeStyles } from "@material-ui/core/styles";
import { Loader } from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { toDateString } from "../../util/datetime";

const useStyles = makeStyles((theme) => ({
  input_grid: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 20,
    marginRight: 20,
  },
}));

const today = new Date();
const lastYear = new Date();
lastYear.setFullYear(lastYear.getFullYear() - 1);

export default function StockStat(props) {
  const classes = useStyles();

  const [stockStat, setStockStat] = useState({
    loaded: false,
    data: null,
  });

  const [dateList, setDateList] = useState({
    loaded: false,
    data: null,
  });

  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  function zip(arrays) {
    return Array.apply(null, Array(arrays[0].length)).map(function (_, i) {
      return arrays.map(function (array) {
        return array[i];
      });
    });
  }

  function handleDateFrom(event, data) {
    setDateFrom(data.value);
    setStockStat({
      ...stockStat,
      loaded: false,
      data: null,
    });
  }
  function handleDateTo(event, data) {
    setDateTo(data.value);
    setStockStat({
      ...stockStat,
      loaded: false,
      data: null,
    });
  }
  function datePickerFilterDateFrom(date) {
    const firstDay = new Date(dateList.data[dateList.data.length - 1].date);
    firstDay.setFullYear(firstDay.getFullYear() - 5);
    if (toDateString(date).localeCompare(toDateString(firstDay)) < 0) {
      return false;
    }
    if (toDateString(date).localeCompare(toDateString(dateTo)) > 0) {
      return false;
    }
    for (let index in dateList.data) {
      if (
        toDateString(new Date(dateList.data[index].date)) === toDateString(date)
      ) {
        return true;
      }
    }
    return false;
  }
  function datePickerFilterDateTo(date) {
    const firstDay = new Date(dateList.data[dateList.data.length - 1].date);
    firstDay.setFullYear(firstDay.getFullYear() - 5);
    if (toDateString(date).localeCompare(toDateString(firstDay)) < 0) {
      return false;
    }
    if (toDateString(date).localeCompare(toDateString(dateFrom)) < 0) {
      return false;
    }
    for (let index in dateList.data) {
      if (
        toDateString(new Date(dateList.data[index].date)) === toDateString(date)
      ) {
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    if (!dateList.loaded) {
      const tempBody = JSON.stringify({
        mode: "cors",
        request: "equity_price_available_date",
        lang: "zh-HK",
      });
      fetch(
        "https://gmdux1cuu9.execute-api.ap-east-1.amazonaws.com/default/daashk-stock-function-OFIqWRaOuhig",
        {
          method: "POST",
          body: tempBody,
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (json) {
          setDateList({
            ...dateList,
            loaded: true,
            data: json.data,
          });
          const tempDateFrom = new Date(
            json.data.length > 250
              ? json.data[json.data.length - 251].date
              : json.data.length > 0
              ? json.data[0].date
              : null
          );
          const tempDateTo = new Date(
            json.data.length > 0 ? json.data[json.data.length - 1].date : null
          );

          setDateFrom(tempDateFrom);
          setDateTo(tempDateTo);
        })
        .catch(function (error) {
          console.log(error);
          setDateList({
            ...dateList,
            loaded: true,
            data: null,
          });
        });
    }
  }, [dateList]);

  useEffect(() => {
    if (!stockStat.loaded && dateFrom && dateTo) {
      const tempBody = JSON.stringify({
        mode: "cors",
        request: "market_stat",
        from_date: toDateString(dateFrom),
        to_date: toDateString(dateTo),
        lang: "zh-HK",
      });
      fetch(
        "https://gmdux1cuu9.execute-api.ap-east-1.amazonaws.com/default/daashk-stock-function-OFIqWRaOuhig",
        {
          method: "POST",
          body: tempBody,
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (json) {
          setStockStat({
            ...stockStat,
            loaded: true,
            data: json.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          setStockStat({
            ...stockStat,
            loaded: true,
            data: null,
          });
        });
    }
  }, [stockStat, dateFrom, dateTo]);

  const hsiColor = "#999999";
  const breadthColor = "#999999";
  let hsiData = [];
  let marketData = [];
  if (stockStat.data) {
    let dates = stockStat.data["date"].map((item) => new Date(item).getTime());
    hsiData.push({
      yAxis: 0,
      type: "ohlc",
      name: "HSI",
      useOhlcData: true,
      data: zip([
        dates,
        stockStat.data["open"],
        stockStat.data["high"],
        stockStat.data["low"],
        stockStat.data["close"],
      ]),
    });
    marketData.push({
      yAxis: 0,
      type: "ohlc",
      name: "HSI",
      useOhlcData: true,
      data: zip([
        dates,
        stockStat.data["open"],
        stockStat.data["high"],
        stockStat.data["low"],
        stockStat.data["close"],
      ]),
    });
    hsiData.push({
      yAxis: 1,
      name: "HSI 50",
      data: zip([dates, stockStat.data["hsi_breadth_50_day"]]),
    });
    hsiData.push({
      yAxis: 1,
      name: "HSI 100",
      data: zip([dates, stockStat.data["hsi_breadth_100_day"]]),
    });
    hsiData.push({
      yAxis: 1,
      name: "HSI 150",
      data: zip([dates, stockStat.data["hsi_breadth_150_day"]]),
    });
    hsiData.push({
      yAxis: 1,
      name: "HSI 200",
      data: zip([dates, stockStat.data["hsi_breadth_200_day"]]),
    });
    marketData.push({
      yAxis: 1,
      name: "Market 50",
      data: zip([dates, stockStat.data["mkt_breadth_50_day"]]),
    });
    marketData.push({
      yAxis: 1,
      name: "Market 100",
      data: zip([dates, stockStat.data["mkt_breadth_100_day"]]),
    });
    marketData.push({
      yAxis: 1,
      name: "Market 150",
      data: zip([dates, stockStat.data["mkt_breadth_150_day"]]),
    });
    marketData.push({
      yAxis: 1,
      name: "Market 200",
      data: zip([dates, stockStat.data["mkt_breadth_200_day"]]),
    });
  }

  const hsiOptions = {
    title: {
      text: "HSI Market Breadth",
    },
    legend: {
      enabled: true,
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
    },
    xAxis: {
      type: "datetime",
    },
    yAxis: [
      {
        labels: {
          format: "{value}",
          align: "left",
          style: {
            color: hsiColor,
          },
        },
        title: {
          text: "恒生指數",
          align: "high",
          offset: 0,
          rotation: 0,
        },
        opposite: true,
        startOnTick: false,
        endOnTick: false,
        gridLineColor: hsiColor,
        min: 22000,
        max: 32000,
      },
      {
        labels: {
          format: "{value} %",
          align: "right",
          style: {
            color: breadthColor,
          },
        },
        title: {
          text: "HSI Breadth",
          align: "high",
          offset: 0,
          rotation: 0,
        },
        opposite: false,
        startOnTick: false,
        endOnTick: false,
        gridLineColor: breadthColor,
        min: 0,
        max: 100,
      },
    ],

    rangeSelector: {
      enabled: false,
    },

    navigator: {
      enabled: false,
    },

    scrollbar: {
      enabled: false,
    },

    series: hsiData,

    tooltip: {
      split: true,
    },

    credits: {
      text: "DaaSHK",
      style: {
        fontSize: "1em",
      },
    },
  };

  const marketOptions = {
    title: {
      text: "Market Breadth",
    },
    legend: {
      enabled: true,
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
    },
    xAxis: {
      type: "datetime",
    },
    yAxis: [
      {
        labels: {
          format: "{value}",
          align: "left",
          style: {
            color: hsiColor,
          },
        },
        title: {
          text: "恒生指數",
          align: "high",
          offset: 0,
          rotation: 0,
        },
        opposite: true,
        startOnTick: false,
        endOnTick: false,
        gridLineColor: hsiColor,
        min: 22000,
        max: 32000,
      },
      {
        labels: {
          format: "{value} %",
          align: "right",
          style: {
            color: breadthColor,
          },
        },
        title: {
          text: "Market Breadth",
          align: "high",
          offset: 0,
          rotation: 0,
        },
        opposite: false,
        startOnTick: false,
        endOnTick: false,
        gridLineColor: breadthColor,
        min: 0,
        max: 100,
      },
    ],

    rangeSelector: {
      enabled: false,
    },

    navigator: {
      enabled: false,
    },

    scrollbar: {
      enabled: false,
    },

    series: marketData,

    tooltip: {
      split: true,
    },

    credits: {
      text: "DaaSHK",
      style: {
        fontSize: "1em",
      },
    },
  };

  return (
    <>
      <Grid container className={classes.input_grid} spacing={2}>
        <Grid item>
          <SemanticDatepicker
            filterDate={datePickerFilterDateFrom}
            onChange={handleDateFrom}
            clearable={false}
            showToday={false}
            datePickerOnly={true}
            value={dateFrom}
            clearOnSameDateClick={false}
            disabled={!dateList.loaded}
          />
        </Grid>
        <Grid item className={classes.item}>
          至
        </Grid>
        <Grid item>
          <SemanticDatepicker
            filterDate={datePickerFilterDateTo}
            onChange={handleDateTo}
            clearable={false}
            showToday={false}
            datePickerOnly={true}
            value={dateTo}
            clearOnSameDateClick={false}
            disabled={!dateList.loaded}
          />
        </Grid>
      </Grid>
      {stockStat.data ? (
        <>
          <HighchartsReact
            highcharts={Highcharts}
            constructorType={"stockChart"}
            options={hsiOptions}
          />
          <HighchartsReact
            highcharts={Highcharts}
            constructorType={"stockChart"}
            options={marketOptions}
          />
        </>
      ) : !stockStat.loaded ? (
        <Loader active inline="centered" />
      ) : (
        <div>沒有資料</div>
      )}
    </>
  );
}
