import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { analytics, functions} from '../../config/fbConfig';
import { logEvent } from "firebase/analytics";
import { httpsCallable } from "firebase/functions";

const initialState = {
  latestShareholdingTopChange: {},
  status: 'idle',
  error: null,
}

const getLatestShareholdingTopChange = httpsCallable(functions, 'latest_shareholding_top_change');

export const fetchLatestShareholdingTopChangeData = createAsyncThunk('index/fetchLatestShareholdingTopChange', async () => {
  logEvent(analytics, "select_content", {
    content_type: "latest_shareholding_top_change",
  });
  const result = await getLatestShareholdingTopChange({});
  return (result.data.data);
});

const latestShareholdingTopChangeSlice = createSlice({
  name: 'latestShareholdingTopChange',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchLatestShareholdingTopChangeData.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchLatestShareholdingTopChangeData.fulfilled]: (state, action) => {
      state.latestShareholdingTopChange = action.payload;
      state.status = 'succeeded';
    },
    [fetchLatestShareholdingTopChangeData.rejected]: (state, action) => {
      state.error = action.payload;
      state.status = 'failed';
    },
  },
})

export default latestShareholdingTopChangeSlice.reducer;
