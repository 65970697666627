import React from "react";
import MetaTags from "react-meta-tags";
import DisplayAdsBanner from "../ads/DisplayAdsBanner";
import GoogleAd from "../ads/Google_ad";
import { Container, Header } from "semantic-ui-react";
import { isMobile } from "react-device-detect";
import CovidCaseChart from "../charts/CovidCaseChart";
import CovidKeyStat from "../charts/CovidKeyStat";

export const TopicIndexTable = () => {
  return (
    <>
      <MetaTags>
        <title>疫情統計數字</title>
        <meta name="description" content="疫情統計數字" />
        <meta property="og:title" content="疫情統計數字" />
        <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
      </MetaTags>
      <Container textAlign="center">
        <DisplayAdsBanner />
      </Container>

      <div
        style={{
          paddingTop: "25px",
          paddingBottom: "25px",
          minHeight: "75vh",
          width: "90%",
          margin: "auto",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <Header as="h1">
            疫情統計數字
            {/* <Header.Subheader>CovidTest</Header.Subheader> */}
          </Header>
        </div>
        <Header as="h3">每日數字</Header>
        <CovidKeyStat />
        <CovidCaseChart />
      </div>

      <Container
        textAlign="center"
        style={{ paddingTop: "30px", paddingBottom: "0px" }}
      >
        <GoogleAd
          client="ca-pub-8753358837449417"
          slot="2486257452"
          format="auto"
          wrapperDivStyle={{
            marginTop: "30px",
            marginBottom: "20px",
          }}
        />
      </Container>
    </>
  );
};

export default TopicIndexTable;
