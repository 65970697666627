import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { analytics, functions} from '../../config/fbConfig';
import { logEvent } from "firebase/analytics";
import { httpsCallable } from "firebase/functions";

const initialState = {
  dateList: {},
  status: 'idle',
  error: null,
}

const getDateList = httpsCallable(functions, 'shareholding_change_per_day_date_list');

export const fetchShareholdingChangeDateListData = createAsyncThunk('index/fetchShareholdingChangeDateList', async () => {
  logEvent(analytics, "select_content", {
    content_type: "shareholding_change_date_list",
  });
  const result = await getDateList({});
  return (result.data.data)
});

const shareholdingChangeDateListSlice = createSlice({
  name: 'shareholdingChangeDateList',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchShareholdingChangeDateListData.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchShareholdingChangeDateListData.fulfilled]: (state, action) => {
      state.dateList = action.payload
      state.status = 'succeeded'
    },
    [fetchShareholdingChangeDateListData.rejected]: (state, action) => {
      state.error = action.payload
      state.status = 'failed'
    },
  },
})

export default shareholdingChangeDateListSlice.reducer
