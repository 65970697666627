import React from "react";
import MetaTags from "react-meta-tags";
import RRGContainer from "./RRGContainer";
import DisplayAdsBanner from "../../ads/DisplayAdsBanner";
import GoogleAd from "../../ads/Google_ad";
import { Container, Header } from "semantic-ui-react";
// import { Header } from "semantic-ui-react";

export const TopicRRG = () => {
  return (
    <>
      <MetaTags>
        <title>板塊輪動圖(港股)</title>
        <meta name="description" content="板塊輪動圖(港股)" />
        <meta property="og:title" content="板塊輪動圖(港股)" />
        <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
      </MetaTags>
      <Container textAlign="center">
        <DisplayAdsBanner />
      </Container>

      <div
        style={{ paddingTop: "25px", paddingBottom: "25px", minHeight: "75vh" }}
      >
        <div style={{ textAlign: "center" }}>
          <Header as="h1">板塊輪動圖(港股)</Header>
        </div>
        <RRGContainer />
      </div>
      <Container
        textAlign="center"
        style={{ paddingTop: "30px", paddingBottom: "0px" }}
      >
        <GoogleAd
          client="ca-pub-8753358837449417"
          slot="2486257452"
          format="auto"
          wrapperDivStyle={{
            marginTop: "30px",
            marginBottom: "20px",
          }}
        />
      </Container>
    </>
  );
};

export default TopicRRG;
