import _ from "lodash";
import React, { useEffect, useState, useReducer, useRef } from "react";
import {
  Loader,
  Table,
  Pagination,
  Container,
  Header,
  Dropdown,
  Icon,
  Input,
  Form,
  Checkbox,
  Menu,
} from "semantic-ui-react";
// import { createStore, combineReducers } from "redux";
import { isMobile } from "react-device-detect";
import source from "../../../mock_source";
import { Link } from "react-router-dom";
import { formatNumber } from "../../../util/dataFormatter";
import StockChart2T from "../../charts/StockChart2T";
import RSChart from "../../charts/RSChart";
import { useSelector, useDispatch } from "react-redux";
import {
  selectAllStock,
  fetchStock,
  toIdle,
} from "../../../features/stock/stockSlice";

const initialState = {
  direction: "descending",
  column: "id",
  activePage: "1",
  totalPage: "4",
};

function resultTableReducer(state = initialState, action) {
  switch (action.type) {
    case "CHANGE_SORT": {
      if (state.column === action.column) {
        return {
          ...state,
          direction:
            state.direction === "ascending" ? "descending" : "ascending",
          activePage: 1,
        };
      }
      return {
        ...state,
        column: action.column,
        direction: "ascending",
        activePage: 1,
      };
    }
    case "SET_ACTIVE_PAGE": {
      const string = action.text;
      // window.scrollTo(0, 0);
      return {
        ...state,
        activePage: string,
      };
    }
    default: {
      return state;
    }
  }
}

function StockMarketTable(props) {
  const { topicInfo, data } = props.data; //data
  const header = data.header;
  const { hsiList, htech } = props.stockSector.indexCons;
  const [tableData, setTableData] = useState([]);

  function obj2Array(n) {
    return Object.values(n);
  }
  var data1 = _.map(data.data, obj2Array);
  //   console.log(header);
  console.log(props.stockSector);

  useEffect(() => {
    var buffer = data1
      .map((v) => {
        var d = {};
        for (var i = 0; i < header.length; i++) {
          d[header[i]] = v[0][i];
        }
        let a = _.find(source, function (o) {
          return o.no === d.id;
        });
        return {
          ...d,
          name_zh: a ? a["name_zh"] : "",
          name_en: a ? a["name_en"] : "",
          isHSI: hsiList.includes(d.id) ? "1" : "0",
          isHTECH: htech.includes(d.id) ? "1" : "0",
          // isMain: d["cat"] === "EM" ? "1" : "0",
          // isGEM: d["cat"] === "G" ? "1" : "0",
        };
      })
      .filter((data) => data.name_zh !== "");
    setTableData(buffer);
  }, []);
  console.log(tableData);

  // let newData = _.sortBy(tableData, ["id"]);
  let newData = _.sortBy(tableData, ["id"]).reverse();

  //   // if (filters) {
  //   //   // Filter for Price
  //   //   if (parseFloat(pricel) > 0 || parseFloat(priceh) > 0) {
  //   //     newData = dataFilter(newData, pricel, priceh, "C");
  //   //   }

  //   //   // Filter for Cap

  //   //   if (parseFloat(capRl) > 0 || parseFloat(capRh) > 0) {
  //   //     newData = dataFilter(newData, capRl, capRh, "cap");
  //   //   }

  //   //   // Filter for RS
  //   //   if (parseFloat(rsl) > 0 || parseFloat(rsh) > 0) {
  //   //     newData = dataFilter(newData, rsl, rsh, "rs");
  //   //   }

  //   //   // Filter for nonCCASS
  //   //   if (parseFloat(ncPctl) > 0 || parseFloat(ncPcth) > 0) {
  //   //     newData = dataFilter(newData, ncPctl, ncPcth, "ncC");
  //   //   }

  //   //   // Filter for topNpct
  //   //   console.log("nTopNConc");
  //   //   console.log(nTopNConc);
  //   //   console.log(TopNConcl);

  //   //   if (parseFloat(nTopNConc) > 0 && parseFloat(TopNConcl) > 0) {
  //   //     newData = dataFilter(newData, TopNConcl, 100, "t" + nTopNConc + "C");
  //   //   }

  //   //   // Filter for HKC pct
  //   //   if (parseFloat(hkcPctl) > 0 || parseFloat(hkcPcth) > 0) {
  //   //     newData = dataFilter(newData, hkcPctl, hkcPcth, "hkcC");
  //   //   }
  //   // }

  return (
    <div
      style={{
        widht: "100%",
        maxWidth: "1000px",
        margin: "auto",
        alignItems: "center",
      }}
    >
      <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
        <Header as="h4" textAlign="right" color="grey">
          {" "}
          數據至 : {topicInfo.lastUpdate} <br />
          總數 : {newData.length}{" "}
        </Header>
      </div>
      <div style={{ textAlign: "center" }}>
        <SortableTable data={newData} />
      </div>
    </div>
  );
}

function SortableTable(props) {
  const scrollRef = useRef(null);
  const [chartLeft, setChartLeft] = useState(0);
  const [chartWidth, setChartWidth] = useState(10);
  const [state, dispatch] = useReducer(resultTableReducer, initialState);
  const { direction, column, activePage } = state;
  const { data } = props;
  let newData = data;

  const [selected, setSelected] = React.useState([]);
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const resize = (event) => {
    if (scrollRef && scrollRef.current) {
      setChartWidth(scrollRef.current.clientWidth - 20);
    }
  };
  const handleScroll = (event) => {
    setChartLeft(scrollRef.current.scrollLeft);
  };
  useEffect(() => {
    resize();
    window.addEventListener("resize", resize);
    return () => {
      window.addEventListener("resize", resize);
    };
  }, [scrollRef]);
  useEffect(() => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.addEventListener("scroll", handleScroll);
      return () => {
        if (scrollRef && scrollRef.current) {
          scrollRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [scrollRef]);

  const pos_pagination = isMobile ? "center" : "right";
  if (direction === "ascending") {
    newData = _.sortBy(newData, [column]);
    console.log(newData);
  } else {
    newData = _.sortBy(newData, [column]).reverse();
    console.log(newData);
  }

  const nPerPage = 25;
  const totalPage = Math.ceil(newData.length / nPerPage);
  let newData2 = [];
  newData2 = _.slice(
    newData,
    (activePage - 1) * nPerPage,
    activePage * nPerPage
  );
  const handlePaginationChange = (e, { activePage }) => {
    dispatch({
      type: "SET_ACTIVE_PAGE",
      text: activePage,
    });
  };

  const topRowHeaderStyle = {
    position: "sticky",
    top: "0px",
    zIndex: "10",
    backgroundColor: "rgb(240,240,240)",
    // fontSize: normalSize[fontSize],
  };
  const firstColHeaderStyle = {
    position: "sticky",
    left: "0px",
    zIndex: "5",
    // backgroundColor: "rgb(240,240,240)",
    backgroundColor: "rgb(255,255,255)",
    // fontSize: normalSize[fontSize],
  };
  const contentStyle = {
    // fontSize: normalSize[fontSize],
    zIndex: "0",
  };
  // const rowHeaderStyle = {
  //   // fontSize: smallSize[fontSize],
  //   color: "grey",
  // };

  return (
    <div
      style={{
        whiteSpace: "nowrap",
        overflowX: "hidden",
        display: "inline-block",
        width: "100%",
        textAlign: "center",
      }}
    >
      <div
        style={{
          overflow: "auto",
          maxHeight: "85vh",
          overscrollBehavior: "auto",
        }}
        ref={scrollRef}
      >
        <Table sortable selectable celled unstackable compact structured>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                style={{
                  position: "sticky",
                  top: "0px",
                  left: "0px",
                  zIndex: "15",
                  backgroundColor: "rgb(240,240,240)",
                  borderWidth: "0px",
                }}
                textAlign="left"
                rowSpan="2"
                sorted={column === "id" ? direction : null}
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "id" });
                }}
              >
                名稱
                {isMobile ? <br /> : null}
                (編號)
              </Table.HeaderCell>
              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
                sorted={column === "ls" ? direction : null}
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "ls" });
                }}
              >
                價格
              </Table.HeaderCell>
              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
                sorted={column === "nc" ? direction : null}
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "nc" });
                }}
              >
                升跌
              </Table.HeaderCell>
              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
                sorted={column === "pc" ? direction : null}
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "pc" });
                }}
              >
                升跌(%)
              </Table.HeaderCell>
              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
                sorted={column === "turnover" ? direction : null}
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "turnover" });
                }}
              >
                成交額
              </Table.HeaderCell>
              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
                sorted={column === "pe" ? direction : null}
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "pe" });
                }}
              >
                PE
              </Table.HeaderCell>
              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
                sorted={column === "yld" ? direction : null}
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "yld" });
                }}
              >
                收益率
              </Table.HeaderCell>
              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
                sorted={column === "cap" ? direction : null}
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "cap" });
                }}
              >
                市值
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row></Table.Row>
          </Table.Header>
          <Table.Body>
            {newData2.map((d, index) => {
              const isItemSelected = isSelected(d.id);
              return (
                <>
                  <Table.Row
                    key={d.id}
                    onClick={(event) => handleClick(event, d.id)}
                  >
                    <Table.Cell style={firstColHeaderStyle} textAlign="left">
                      <Icon
                        name={isItemSelected ? "caret down" : "caret right"}
                      />
                      {d.name_zh !== "" ? (
                        <Link to={"/stock/" + d.id}>
                          {d.name_zh.length > 8
                            ? d.name_zh.substring(0, 8) + "..."
                            : d.name_zh}
                          {isMobile ? <br /> : null}({d.id})
                        </Link>
                      ) : (
                        <>({d.id})</>
                      )}
                    </Table.Cell>
                    <Table.Cell style={contentStyle} textAlign="center">
                      {d.ls}{" "}
                    </Table.Cell>
                    <Table.Cell style={contentStyle} textAlign="center">
                      {d.nc > 0 ? (
                        <span style={{ color: "green" }}>{"+" + d.nc} </span>
                      ) : d.nc < 0 ? (
                        <span style={{ color: "red" }}>{d.nc}</span>
                      ) : (
                        d.nc
                      )}
                    </Table.Cell>
                    <Table.Cell style={contentStyle} textAlign="center">
                      {d.pc > 0 ? (
                        <span style={{ color: "green" }}>
                          {"+" + d.pc + "%"}{" "}
                        </span>
                      ) : d.pc < 0 ? (
                        <span style={{ color: "red" }}>{d.pc + "%"}</span>
                      ) : (
                        d.pc + "%"
                      )}
                    </Table.Cell>
                    <Table.Cell style={contentStyle} textAlign="center">
                      {d.turnover === "-" ? "-" : formatNumber(d.turnover)}
                    </Table.Cell>
                    <Table.Cell style={contentStyle} textAlign="center">
                      {d.pe}
                    </Table.Cell>
                    <Table.Cell style={contentStyle} textAlign="center">
                      {d.yld + "%"}
                    </Table.Cell>
                    <Table.Cell style={contentStyle} textAlign="center">
                      {d.cap === "-" ? "-" : formatNumber(d.cap)}
                    </Table.Cell>
                  </Table.Row>
                  {isItemSelected ? (
                    <Table.Row>
                      <Table.Cell
                        style={{
                          paddingBottom: "10px",
                          paddingTop: "10px",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                        }}
                        colSpan={8}
                      >
                        <div
                          style={{
                            position: "sticky",
                            left: "10px",
                            width: chartWidth,
                            zIndex: "9",
                          }}
                        >
                          <InTableChartContainer id={d.id} />
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                </>
              );
            })}
          </Table.Body>
        </Table>
      </div>
      <div
        style={{
          paddingTop: "25px",
          paddingBottom: "25px",
          float: pos_pagination,
        }}
      >
        <Pagination
          boundaryRange={0}
          activePage={activePage}
          size={isMobile ? "small" : "big"}
          siblingRange={1}
          totalPages={totalPage}
          onPageChange={handlePaginationChange}
        />
      </div>
    </div>
  );
}

// export const InTableChartContainer = (props) => {
//   const stockId = props.id;
//   const dispatch = useDispatch();
//   const stocks = useSelector(selectAllStock);
//   const stockStatus = useSelector((state) => state.stock.status);
//   const error = useSelector((state) => state.stock.error);
//   const [data, setData] = useState(null);
//   const [content, setContent] = useState(null);
//   console.log("render");

//   useEffect(() => {
//     const stockData = stocks.find((item) => item.stockInfo.no === stockId);
//     console.log("stockData1");
//     console.log(stockData);
//     if (!stockData) {
//       if (stockStatus === "idle") {
//         if (true) {
//           dispatch(fetchStock(stockId));
//         }
//       } else if (stockStatus === "loading") {
//         console.log("stockStatus123");
//         setContent(
//           <div>
//             <section className="posts-list">
//               <div
//                 style={{
//                   padding: "100px",
//                   margin: "auto",
//                   alignItems: "center",
//                   height: "75vh",
//                   // overflow: "auto",
//                 }}
//               >
//                 <Loader active inline="centered">
//                   {" "}
//                   Loading
//                 </Loader>
//               </div>
//             </section>
//           </div>
//         );
//       } else if (stockStatus === "error") {
//         setContent(
//           <div>
//             <section className="posts-list">
//               <div> Stock Not Found</div>
//             </section>
//           </div>
//         );
//       }
//     } else if (!data) {
//       setData(stockData);
//       console.log("stockData2");
//       console.log(stockData);
//     }
//   }, [stockId, stocks, data, stockStatus, dispatch]);

//   useEffect(() => {
//     console.log("2nd effect");
//     console.log(data);

//     if (data) {
//       setContent(<StockChart2T chartData={data} />);
//     }

//     dispatch(toIdle({}));
//   }, [data, dispatch]);

//   return <>{content}</>;
// };

export const InTableChartContainer = (props) => {
  const stockId = props.id;
  return (
    <>
      {" "}
      <RSChart />
    </>
  );
};
export default StockMarketTable;
