import React from "react";
import MetaTags from "react-meta-tags";
import CovidTestView from "./CovidTestView";
import DisplayAdsBanner from "../../ads/DisplayAdsBanner";
import GoogleAd from "../../ads/Google_ad";
import {
  Container,
  Header,
  Table,
  Icon,
  Grid,
  Segment,
} from "semantic-ui-react";
import { isMobile } from "react-device-detect";
import CovidKeyStat from "../../charts/CovidKeyStat";
import { Link } from "react-router-dom";

export const TopicCovidTest = () => {
  const disclosureLink = "abc";
  return (
    <>
      <MetaTags>
        <title>社區檢測中心預約情況</title>
        <meta name="description" content="社區檢測中心預約情況" />
        <meta property="og:title" content="社區檢測中心預約情況" />
        <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
      </MetaTags>
      <Container textAlign="center">
        <DisplayAdsBanner />
      </Container>

      <div
        style={{ paddingTop: "25px", paddingBottom: "25px", minHeight: "75vh" }}
      >
        <CovidKeyStat />
        <Container style={{ textAlign: "right", paddingTop: "5px" }}>
          <Link to="/topics/covidcasestat">
            <Header as="h5" textAlign="right" color="blue">
              觀看更多疫情數據 <Icon name="arrow alternate circle right" />
            </Header>
          </Link>
        </Container>
        <Container textAlign="center">
          <DisplayAdsBanner />
        </Container>
        <div style={{ textAlign: "center", paddingTop: "15px" }}>
          <Header as="h1">
            社區檢測中心預約情況
            {/* <Header.Subheader>CovidTest</Header.Subheader> */}
          </Header>
        </div>
        <CovidTestView />
      </div>
      {/* <Container textAlign="center">
        <DisplayAdsBanner />
      </Container> */}
      <Container>
        <Grid stackable columns={3} textAlign="center">
          <Grid.Column>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://booking.communitytest.gov.hk/_data/mscs.jsp"
            >
              <Segment inverted color="teal">
                <Header as="h4">
                  <Icon name="truck" />
                  各區流動採樣站
                </Header>
              </Segment>
            </a>
          </Grid.Column>
          <Grid.Column>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.communitytest.gov.hk/zh-HK/info"
            >
              <Segment inverted color="teal">
                <Header as="h4">
                  <Icon name="location arrow" />
                  社區檢測中心位置
                </Header>
              </Segment>
            </a>
          </Grid.Column>
          <Grid.Column>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://event.thegulu.com/site_list/TESTING_CENTER"
            >
              <Segment inverted color="teal">
                <Header as="h4">
                  <Icon name="clock" />
                  籌號排隊狀況
                </Header>
              </Segment>
            </a>
          </Grid.Column>
        </Grid>
      </Container>

      <Container
        textAlign="center"
        style={{ paddingTop: "30px", paddingBottom: "0px" }}
      >
        <GoogleAd
          client="ca-pub-8753358837449417"
          slot="2486257452"
          format="auto"
          wrapperDivStyle={{
            marginTop: "30px",
            marginBottom: "20px",
          }}
        />
      </Container>
    </>
  );
};

export default TopicCovidTest;
