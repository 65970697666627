import _ from "lodash";
import React, { useEffect, useState, useReducer } from "react";
import {
  Loader,
  Table,
  Pagination,
  Container,
  Header,
  Dropdown,
  Icon,
  Input,
  Form,
  Checkbox,
} from "semantic-ui-react";
import { createStore, combineReducers } from "redux";
import { isMobile } from "react-device-detect";
import source from "../../../mock_source";
import { Link } from "react-router-dom";
import { formatNumber } from "../../../util/dataFormatter";
import store from "../../../Store";
import { convHK1980GridToWGS84LatLong } from "../eta/lib/ETAUtility";

const initialState = {
  direction: "descending",
  column: "rs",
  filterText: { value: "" },
  filterInd: "所有",
  filterSector: "",
  filterSubSector: "",
  indOption: [],
  sectorOption: [],
  activePage: "1",
  totalPage: "4",
  topN: "5",
};

const initFilter = {
  pricel: "",
  priceh: "",
  isMain: false,
  isGEM: false,
  isHSI: false,
  isPlgma50: false,
  isPlgma200: false,
  isPlgwma30: false,
  isMa50lgma150: false,
  isMa50lgma200: false,
  isMa150lgma200: false,
  ipoType: "0",
  capRl: "",
  capRh: "",
  nVolDay: "",
  voll: "",
  nTurnDay: "",
  turnl: "",
  rsl: "",
  rsh: "",
  ncPctl: "",
  ncPcth: "",
  nTopNConc: "",
  TopNConcl: "",
  hkcPctl: "",
  hkcPcth: "",
};

function resultTableReducer(state = initialState, action) {
  switch (action.type) {
    case "CHANGE_SORT": {
      console.log("change sort fire");
      if (state.column === action.column) {
        return {
          ...state,
          direction:
            state.direction === "ascending" ? "descending" : "ascending",
          activePage: 1,
        };
      }
      return {
        ...state,
        column: action.column,
        direction: "ascending",
        activePage: 1,
      };
    }
    case "SET_ACTIVE_PAGE": {
      const string = action.text;
      // window.scrollTo(0, 0);
      return {
        ...state,
        activePage: string,
      };
    }
    case "SET_TOPN_DROPDOWN": {
      return {
        ...state,
        topN: action.state,
      };
    }

    default: {
      return state;
    }
  }
}

function obj2Array(n) {
  return Object.values(n);
}

function CCASSTable(props) {
  const { priceStatDataSet, filters, stockSector } = props;
  const priceStat = priceStatDataSet.data;
  const [tableData, setTableData] = useState([]);
  const [filter, setFilter] = useState(filters);
  console.log(stockSector);
  useEffect(() => {
    var buffer = [];
    const keys = Object.keys(priceStat);
    keys.forEach((key, index) => {
      var d = priceStat[key];
      var a = _.find(source, function (o) {
        return o.no === d.id;
      });
      buffer.push({
        ...d,
        name_zh: a ? a["name_zh"] : "",
        name_en: a ? a["name_en"] : "",
        isPlgma50: d.c >= d.ma50 ? true : false,
        isPlgma200: d.c >= d.ma200 ? true : false,
        isPlgwma30: d.c >= d.maw30 ? true : false,
        isMa50lgma150: d.ma50 >= d.ma150 ? true : false,
        isMa50lgma200: d.ma50 >= d.ma200 ? true : false,
        isMa150lgma200: d.ma150 >= d.ma200 ? true : false,
        industry: stockSector.iDict[stockSector.data[d.id]["i"]],
        // sector: stockSector.sDict[stockSector.data[d.id]["s"]],
        // subsector: stockSector.ssDict[stockSector.data[d.id]["ss"]],
        // isHSI: stockSector.hsi.includes(d.id),
        // isHTECH: stockSector.htech.includes(d.id),
      });
    });
    setTableData(buffer);
  }, []);

  // var buffer = data1.map((v) => {
  //   var d = {};
  // let a = _.find(source, function (o) {
  //   return o.no === d.id;
  // });
  //   return {
  //     ...d,
  // name_zh: a ? a["name_zh"] : "",
  // name_en: a ? a["name_en"] : "",
  // isPlgma50: d.np >= 50 ? true : false,
  // isPlgma200: d.np >= 500 ? true : false,
  // isPlgwma30: d.np >= 1000 ? true : false,
  // isMa50lgma150: d.np >= 60 ? true : false,
  // isMa50lgma200: d.np >= 120 ? true : false,
  // isMa150lgma200: d.np >= 200 ? true : false,
  // industry: stockSector.iDict[stockSector.data[d.id]["i"]],
  // sector: stockSector.sDict[stockSector.data[d.id]["s"]],
  // subsector: stockSector.ssDict[stockSector.data[d.id]["ss"]],
  // isHSI: stockSector.hsi.includes(d.id),
  // isHTECH: stockSector.htech.includes(d.id),
  //   };
  // });
  // setTableData(buffer);

  console.log(tableData);

  let newData = tableData;

  // const binaryFilter = ["isPlgma50"]

  if (filters) {
    if (filters.title) {
      newData = _.filter(newData, function (d) {
        return (
          (typeof d.id === "string" &&
            d.id.toLowerCase().includes(filters.title.toLowerCase())) ||
          (typeof d.name_zh === "string" &&
            d.name_zh.toLowerCase().includes(filters.title.toLowerCase())) ||
          (typeof d.name_en === "string" &&
            d.name_en.toLowerCase().includes(filters.title.toLowerCase()))
        );
      });
    }
    // if (filters.isGEM || filters.isMain || filters.isHSI || filters.isHTECH) {
    //   newData = _.filter(newData, function (d) {
    //     ["isHSI"].forEach(function (f, i) {
    //       console.log(f);
    //       console.log(d[f]);
    //       if (filters[f]) {
    //         if (d[f] === true) return true;
    //       } else {
    //         return false
    //       }
    //     });
    //   });
    //   console.log("first");
    // }

    [
      "isMa50lgma150",
      "isMa50lgma200",
      "isMa150lgma200",
      "isPlgma50",
      "isPlgma200",
      "isPlgwma30",
    ].forEach(function (f, i) {
      if (filters[f]) {
        newData = _.filter(newData, function (d) {
          return d[f] === true;
        });
      }
    });
    // if (filter.ipoType) {
    //   newData = _.filter(newData, function (d) {
    //     return d.ipoType === filter.ipoType;
    //   });
    // }
  }

  console.log(newData);

  return (
    <div style={{ textAlign: "center", width: isMobile ? "95%" : "100%" }}>
      <TableComponent data={newData} />
    </div>
  );
}

function TableComponent(props) {
  // console.log(props);
  return (
    <>
      {props.data.map((d, index) => (
        <>
          {d.id} <br />
        </>
      ))}
    </>
  );
}

function CCASSTableOld(props) {
  const { data, filters } = props;
  // const { data, stockSector, filters } = props;

  const header = data.rstable.header;
  const rsData = data.rstable.data;
  const [tableData, setTableData] = useState([]);
  const [filter, setFilter] = useState({ ...initFilter, ...filters });
  var data1 = _.map(rsData, obj2Array);

  useEffect(() => {
    var buffer = data1.map((v) => {
      var d = {};
      for (var i = 0; i < header.length; i++) {
        d[header[i]] = v[0][i];
      }
      console.log(d);
      let a = _.find(source, function (o) {
        return o.no === d.id;
      });
      return {
        ...d,
        name_zh: a ? a["name_zh"] : "",
        name_en: a ? a["name_en"] : "",
        isPlgma50: d.c >= d.ma50 ? true : false,
        isPlgma200: d.c >= d.ma200 ? true : false,
        isPlgwma30: d.c >= d.maw30 ? true : false,
        isMa50lgma150: d.ma50 >= d.ma150 ? true : false,
        isMa50lgma200: d.ma50 >= d.ma200 ? true : false,
        isMa150lgma200: d.ma150 >= d.ma200 ? true : false,
      };
    });
    setTableData(buffer);
  }, []);

  console.log(tableData);

  useEffect(() => {
    if (filters) {
      setFilter({
        ...initFilter,
        ...filters,
      });
    } else {
      setFilter({
        initFilter,
      });
    }
  }, [filters]);

  let newData = _.sortBy(tableData, ["rs"]).reverse();
  console.log(filter);

  const {
    pricel,
    priceh,
    isMain,
    isGEM,
    isHSI,
    isIPO,
    isPlgma50,
    isPlgma200,
    isPlgwma30,
    isMa50lgma150,
    isMa50lgma200,
    isMa150lgma200,
    capRl,
    capRh,
    nVolDay,
    voll,
    nTurnDay,
    turnl,
    rsl,
    rsh,
    ncPctl,
    ncPcth,
    nTopNConc,
    TopNConcl,
    hkcPctl,
    hkcPcth,
  } = filter;

  if (filters) {
    // Filter for Price
    if (parseFloat(pricel) > 0 || parseFloat(priceh) > 0) {
      newData = dataFilter(newData, pricel, priceh, "C");
    }
    // Filter for MA
    if (
      isPlgma50 === true ||
      isPlgma200 === true ||
      isPlgwma30 === true ||
      isMa50lgma150 === true ||
      isMa50lgma200 === true ||
      isMa150lgma200 === true
    ) {
      if (isPlgma50 === true) {
        newData = _.filter(newData, function (d) {
          return d.isPlgma50 === isPlgma50;
        });
      }
      if (isPlgma200 === true) {
        newData = _.filter(newData, function (d) {
          return d.isPlgma200 === isPlgma200;
        });
      }
      if (isPlgwma30 === true) {
        newData = _.filter(newData, function (d) {
          return d.isPlgwma30 === isPlgwma30;
        });
      }
      if (isMa50lgma150 === true) {
        newData = _.filter(newData, function (d) {
          return d.isMa50lgma150 === isMa50lgma150;
        });
      }
      if (isMa50lgma200 === true) {
        newData = _.filter(newData, function (d) {
          return d.isMa50lgma200 === isMa50lgma200;
        });
      }
      if (isMa150lgma200 === true) {
        newData = _.filter(newData, function (d) {
          return d.isMa150lgma200 === isMa150lgma200;
        });
      }
    }
    // Filter for Cap

    if (parseFloat(capRl) > 0 || parseFloat(capRh) > 0) {
      newData = dataFilter(newData, capRl, capRh, "cap");
    }

    // Filter for RS
    if (parseFloat(rsl) > 0 || parseFloat(rsh) > 0) {
      newData = dataFilter(newData, rsl, rsh, "rs");
    }
  }

  return (
    <Container
      style={{
        widht: "100%",
        maxWidth: "950px",
        margin: "auto",
        alignItems: "center",
      }}
    >
      <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
        <Header as="h4" textAlign="right" color="grey">
          {" "}
          {/* 數據至 : {topicInfo.lastUpdate} <br /> */}
          總數 : {newData.length}{" "}
        </Header>
      </div>
      <div style={{ textAlign: "center", width: isMobile ? "95%" : "100%" }}>
        <SortableTable data={newData} filter={filter} />
      </div>
    </Container>
  );
}

const rankOptions = [
  { key: "3", text: "Top3", value: "3" },
  { key: "5", text: "Top5", value: "5" },
  { key: "10", text: "Top10", value: "10" },
];

function SortableTable(props) {
  const [state, dispatch] = useReducer(resultTableReducer, initialState);
  const { direction, column, activePage, topN } = state;
  const { data } = props;
  let newData = data;
  const pos_pagination = isMobile ? "center" : "right";

  if (direction === "ascending") {
    newData = _.sortBy(newData, [column]);
  } else {
    newData = _.sortBy(newData, [column]).reverse();
  }

  const nPerPage = 25;
  const totalPage = Math.ceil(newData.length / nPerPage);
  let newData2 = [];
  newData2 = _.slice(
    newData,
    (activePage - 1) * nPerPage,
    activePage * nPerPage
  );
  const handlePaginationChange = (e, { activePage }) => {
    dispatch({
      type: "SET_ACTIVE_PAGE",
      text: activePage,
    });
  };
  return (
    <div
      style={{
        whiteSpace: "nowrap",
        overflowX: "auto",
        display: "inline-block",
        width: "100%",
        textAlign: "center",
      }}
    >
      <Table sortable selectable celled unstackable compact structured>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              textAlign="left"
              rowSpan="2"
              sorted={column === "id" ? direction : null}
              onClick={() => {
                dispatch({ type: "CHANGE_SORT", column: "id" });
              }}
            >
              名稱
              {isMobile ? <br /> : null}
              (編號)
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              rowSpan="2"
              // sorted={column === "cur" ? direction : null}
            >
              <div
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "c" });
                }}
              >
                價格{" "}
                {column === "c" &&
                  (direction === "descending" ? (
                    <Icon link name="sort down" />
                  ) : (
                    <Icon link name="sort up" />
                  ))}
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              rowSpan="2"
              // sorted={column === "rs" ? direction : null}
            >
              <div
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "rs" });
                }}
              >
                RS{" "}
                {column === "rs" &&
                  (direction === "descending" ? (
                    <Icon link name="sort down" />
                  ) : (
                    <Icon link name="sort up" />
                  ))}
              </div>
              <div
                style={{
                  margin: "auto",
                  maxWidth: "100px",
                  maxHeight: "1.5rem",
                }}
              >
                {/* <SliderFilterRS range={sliderFilter_RS} />{" "} */}
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              rowSpan="2"
              // sorted={column === "rs" ? direction : null}
            >
              <div
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "rsd10d" });
                }}
              >
                RS變化
                {column === "rsd10d" &&
                  (direction === "descending" ? (
                    <Icon link name="sort down" />
                  ) : (
                    <Icon link name="sort up" />
                  ))}
              </div>
              <div
                style={{
                  margin: "auto",
                  maxWidth: "100px",
                  maxHeight: "1.5rem",
                }}
              >
                (10天)
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              rowSpan="2"
              style={{ minWidth: "100px" }}
            >
              <div
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "t" });
                }}
              >
                成交額{" "}
                {column === "t" &&
                  (direction === "descending" ? (
                    <Icon link name="sort down" />
                  ) : (
                    <Icon link name="sort up" />
                  ))}
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              rowSpan="2"
              style={{ minWidth: "100px" }}
              // sorted={column === "cur" ? direction : null}
            >
              <div
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "cap" });
                }}
              >
                市值{" "}
                {column === "cap" &&
                  (direction === "descending" ? (
                    <Icon link name="sort down" />
                  ) : (
                    <Icon link name="sort up" />
                  ))}
              </div>
            </Table.HeaderCell>
            {/* <Table.HeaderCell
              textAlign="center"
              rowSpan="2"
            >
              <div>
                <Icon name="star" style={{ color: "#FFCA28" }} />
              </div>
            </Table.HeaderCell> */}
          </Table.Row>
          <Table.Row></Table.Row>
        </Table.Header>
        <Table.Body>
          {newData2.map(
            (
              {
                id,
                c,
                V,
                rs,
                ma50,
                ma150,
                ma200,
                name_zh,
                name_en,
                cap,
                t,
                rsd1d,
                rsd5d,
                rsd10d,
                s,
                ss,
              },
              index
            ) => (
              <>
                {index !== 0 && index % 10 === 0 && (
                  <Table.Row key={index}>
                    <Table.Cell colSpan={6} textAlign="center">
                      {/* <InFeedAdsWhite></InFeedAdsWhite> */}
                    </Table.Cell>
                  </Table.Row>
                )}
                <Table.Row key={id}>
                  <Table.Cell textAlign="left">
                    {name_zh !== "" ? (
                      <Link to={"/stock/" + id}>
                        {name_zh.length > 8
                          ? name_zh.substring(0, 8) + "..."
                          : name_zh}
                        {isMobile ? <br /> : null}({id})
                      </Link>
                    ) : (
                      <>({id})</>
                    )}
                    <br />
                    <span
                      style={{
                        color: "grey",
                        fontSize: "0.85rem",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        store.dispatch({ type: "SET_IND_FILTER", state: s });
                      }}
                    >
                      {s}
                    </span>
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <div style={{ marginBottom: "-0.75rem" }}> ${c} </div>
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <div style={{ marginBottom: "-0.75rem" }}> {rs} </div>
                    <div style={{ width: "100px", margin: "auto" }}>
                      {/* <PriceLine
                        min={0}
                        secondMin={0}
                        mid={rs}
                        secondMax={rs}
                        max={100}
                        col={"#2471A3"}
                      /> */}
                    </div>
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <div style={{ marginBottom: "-0.75rem" }}> {rsd10d} </div>
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <div style={{ marginBottom: "-0.75rem" }}>
                      {" "}
                      {formatNumber(t * 10000)}{" "}
                    </div>
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <div style={{ marginBottom: "-0.75rem" }}>
                      {" "}
                      {cap === 0 ? "-" : formatNumber(cap * 100000000)}{" "}
                    </div>
                  </Table.Cell>
                  {/* <Table.Cell textAlign="center">
                    <span>{FavBtn(id)}</span>
                  </Table.Cell> */}
                </Table.Row>
              </>
            )
          )}
        </Table.Body>
      </Table>
      <div
        style={{
          paddingTop: "25px",
          paddingBottom: "25px",
          float: pos_pagination,
        }}
      >
        <Pagination
          boundaryRange={0}
          activePage={activePage}
          size={isMobile ? "small" : "big"}
          siblingRange={1}
          totalPages={totalPage}
          onPageChange={handlePaginationChange}
        />
      </div>
    </div>
  );
}

function dataFilter(data, filterLow, filterHigh, q) {
  const low = parseFloat(filterLow) > 0 ? parseFloat(filterLow) : 0;
  const high = parseFloat(filterHigh) > 0 ? parseFloat(filterHigh) : 99999999;
  const newData = _.filter(data, function (d) {
    return d[q] >= low && d[q] <= high;
  });
  return newData;
}

export default CCASSTable;
