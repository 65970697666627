import React from "react";
import RSTable from "./RSTable";
import RSTableFilter from "./RSTableFilter";
import { connect } from "react-redux";
import _ from "lodash";
import { Container } from "semantic-ui-react";
// import { isMobile } from "react-device-detect";

function RSTableContainer(props) {
  const { data, stockSector, wishList, allowedBehindPaywall, stockDict } =
    props;
  // console.log(stockDict);
  const sector_options = [{ key: "所有", text: "所有", value: "所有" }];
  let j = 0;
  var iDictArry = _.values(stockSector.ind.sDict);
  for (j; j < iDictArry.length; j += 1) {
    if (iDictArry[j] !== "") {
      sector_options.push({
        key: iDictArry[j],
        text: iDictArry[j],
        value: iDictArry[j],
      });
    }
  }
  return (
    <Container style={{ maxWidth: "1000px", width: "95%" }}>
      <RSTableFilter
        initialValues={{
          isPlgma50: false,
          isPlgma200: false,
          isPlgwma30: false,
          isMa50lgma150: false,
          isMa50lgma200: false,
          isMa150lgma200: false,
          // ipoType: 0,
          // catType: 0,
        }}
        sector_options={sector_options}
      />
      {props.values ? (
        <RSTable
          data={data}
          stockSector={stockSector}
          stockDict={stockDict}
          wishList={wishList}
          allowedBehindPaywall={allowedBehindPaywall}
          filters={props.values}
        />
      ) : null}
    </Container>
  );
}

const mapStateToProps = (state) => {
  return state.form.rstableUS
    ? {
        values: state.form.rstableUS.values,
        submitSucceeded: state.form.rstableUS.submitSucceeded,
      }
    : {};
};

export default connect(mapStateToProps)(RSTableContainer);
