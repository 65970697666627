import React from "react";
import {
  Loader,
  Card,
  Feed,
  Icon,
  Button,
  Container,
  Grid,
  Segment,
} from "semantic-ui-react";

function Test() {
  return (
    <div
      style={{
        maxWidth: "600px",
        margin: "auto",
        paddingTop: "25px",
        paddingBottom: "25px",
      }}
    >
      <Card centered compact>
        <Card.Content textAlign="center">
          <Icon name="add" />
        </Card.Content>
      </Card>

      <Segment compact textAlign="center" style={{ margin: "auto" }}>
        <Icon name="add" style={{ paddingLeft: "0.25rem" }} />
      </Segment>
    </div>
  );
}

export default Test;
