import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { Loader, Statistic, Container } from 'semantic-ui-react'

function VacPopSummaryContainer(){
  const [data, setData] = useState(null);

  useEffect(() => {
      axios.get(`https://vcd.cvpss.hk/data/summary.json`)
          .then(response => setData(response.data))
  }, []);

  return(
      <div>
          {data !== null ? <SummaryView data = {data}/> : <Loader active inline='centered' > Loading</Loader> }
      </div>
  )
}

function SummaryView(data){
  const res = data.data
  return(
    <Container style={{ paddingTop:'25px'}}>
        <Statistic.Group widths='two'>
          <Statistic color='green'>
            <Statistic.Value text>
            {res.firstDoseTotal.toLocaleString()} <br/>
            {res.firstDosePercent}
            </Statistic.Value>
            <Statistic.Label>已接種第一針人口</Statistic.Label>
          </Statistic>
          <Statistic color='teal'>
            <Statistic.Value text>
            {res.secondDoseTotal.toLocaleString()} <br/>
            {res.secondDosePercent}
            </Statistic.Value>
            <Statistic.Label>已接種第二針人口</Statistic.Label>
          </Statistic>
        </Statistic.Group>
    </Container>
  )
}


export default VacPopSummaryContainer;