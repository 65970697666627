import React from 'react'
import { SubscriptionPlans } from 'components/widget/SubscriptionWidget'
import { Container, Header } from 'semantic-ui-react'
import MetaTags from 'react-meta-tags';

function Subscription() {
  return (
    <div>
      <MetaTags>
        <title>訂閱計劃</title>
        <meta name="description" content='訂閱計劃' />
        <meta property="og:title" content='訂閱計劃' />
        <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
      </MetaTags>
      <Container
        style={{
          paddingTop: "25px",
          paddingBottom: "25px",
          minHeight: "75vh",
        }}
      >
        <div style={{ textAlign: 'center', paddingTop: '25px' }}>
          <Header as='h1'>訂閱計劃</Header>
        </div>
        <div style={{ paddingTop: "0px" }} >
          <SubscriptionPlans />
        </div>
      </Container>
    </div>
  )
}

export default Subscription
