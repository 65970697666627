import React, { useEffect, useReducer } from "react";
import { Loader } from "semantic-ui-react";
import _ from "lodash";
import {
  selectAllTopic,
  fetchTopic,
  toIdle,
} from "../../../features/topic/topicSlice";
import { useSelector, useDispatch } from "react-redux";
import RSTable from "./RSTable";
import RSTableFilter from "./RSTableFilter";
import { connect } from "react-redux";

function RSTableContainer(props) {
  console.log(props);
  return (
    <div>
      <RSTableFilter />
      {/* <RSTable data={data} stockSector={stockSector} filters ={props.values}/> */}
    </div>
  );
}

const mapStateToProps = (state) => {
  return state.form.rstable
    ? {
        values: state.form.rstable.values,
        submitSucceeded: state.form.rstable.submitSucceeded,
      }
    : {};
};

export default connect(mapStateToProps)(RSTableContainer);

// const data = {
//   topicInfo: { lastUpdate: "2022-03-14", name: "rstable3" },
//   rstable: {
//     header: [
//       "id",
//       "c",
//       "t",
//       "ma50",
//       "ma100",
//       "ma150",
//       "ma200",
//       "maw30",
//       "cap",
//       "rs",
//       "rsd1d",
//       "rsd5d",
//       "rsd10d",
//       "ipotype",
//       "cat",
//     ],
//     data: [
//       {
//         0: [
//           "0037",
//           0.45,
//           90.156,
//           0.40558,
//           0.26979,
//           0.22308,
//           0.201435,
//           "0.253",
//           3.3023,
//           99.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1: [
//           "2012",
//           8.9,
//           406.0,
//           4.0712,
//           3.5248,
//           3.48387,
//           3.5501,
//           "3.64",
//           21.6,
//           99.0,
//           0.0,
//           1.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2: [
//           "0600",
//           0.375,
//           6.942,
//           0.4015,
//           0.39855,
//           0.385767,
//           0.354975,
//           "0.393",
//           1.6012,
//           99.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         3: [
//           "2112",
//           0.39,
//           197.0,
//           0.13178,
//           0.10281,
//           0.0948333,
//           0.09168,
//           "0.106",
//           5.85,
//           99.0,
//           0.0,
//           2.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         4: [
//           "8292",
//           0.9,
//           332.0,
//           0.9108,
//           0.57427,
//           0.428596,
//           0.359652,
//           "0.486",
//           5.7024,
//           99.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         5: [
//           "8047",
//           0.216,
//           193.0,
//           0.16106,
//           0.10846,
//           0.101527,
//           0.103635,
//           "0.107",
//           12.3,
//           99.0,
//           1.0,
//           1.0,
//           1.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         6: [
//           "8645",
//           0.79,
//           44.544,
//           0.7246,
//           0.59,
//           0.527333,
//           0.45553,
//           "0.554",
//           4.74,
//           99.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         7: [
//           "1937",
//           0.93,
//           780.0,
//           1.0708,
//           1.144,
//           1.17673,
//           0.9413,
//           "1.165",
//           9.3,
//           99.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         8: [
//           "1094",
//           1.26,
//           23.582,
//           1.3538,
//           1.4924,
//           1.33104,
//           1.04774,
//           "1.437",
//           3.6928,
//           99.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         9: [
//           "8406",
//           0.93,
//           46.29,
//           0.9352,
//           0.8884,
//           0.725607,
//           0.58646,
//           "0.766",
//           7.44,
//           99.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         10: [
//           "8472",
//           1.92,
//           126.0,
//           1.6918,
//           1.3023,
//           1.3468,
//           1.13497,
//           "1.355",
//           7.68,
//           99.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         11: [
//           "1450",
//           1.5,
//           203.0,
//           1.4244,
//           1.473,
//           1.466,
//           1.18169,
//           "1.506",
//           16.3,
//           99.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         12: [
//           "1842",
//           0.67,
//           1482.0,
//           0.4057,
//           0.37468,
//           0.33398,
//           0.316045,
//           "0.353",
//           6.7,
//           98.0,
//           0.0,
//           2.0,
//           8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         13: [
//           "8170",
//           3.17,
//           1.591,
//           2.7332,
//           2.8529,
//           2.30747,
//           1.94385,
//           "2.424",
//           13.0,
//           98.0,
//           0.0,
//           1.0,
//           1.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         14: [
//           "1296",
//           1.01,
//           1846.0,
//           0.8564,
//           0.68795,
//           0.650633,
//           0.585875,
//           "0.687",
//           13.2,
//           98.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         15: [
//           "8365",
//           0.69,
//           705.0,
//           0.5789,
//           0.49464,
//           0.469293,
//           0.445245,
//           "0.478",
//           4.9762,
//           98.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         16: [
//           "8039",
//           0.99,
//           173.0,
//           0.7121,
//           0.59105,
//           0.58374,
//           0.506465,
//           "0.625",
//           4.8807,
//           98.0,
//           0.0,
//           1.0,
//           1.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         17: [
//           "1961",
//           1.52,
//           65.08,
//           1.0086,
//           0.7947,
//           0.719067,
//           0.67865,
//           "0.761",
//           8.2992,
//           98.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         18: [
//           "8527",
//           2.57,
//           14.115,
//           2.9956,
//           2.9597,
//           3.02393,
//           2.84105,
//           "3.056",
//           12.8,
//           98.0,
//           0.0,
//           -1.0,
//           -1.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         19: [
//           "8516",
//           2.56,
//           472.0,
//           1.5078,
//           1.1755,
//           1.13913,
//           1.1815,
//           "-",
//           2.924,
//           98.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         20: [
//           "8305",
//           0.255,
//           27.131,
//           0.28806,
//           0.22581,
//           0.19598,
//           0.163025,
//           "0.207",
//           2.6775,
//           98.0,
//           0.0,
//           -1.0,
//           -1.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         21: [
//           "7552",
//           19.78,
//           58005.0,
//           10.9311,
//           9.79235,
//           9.41627,
//           8.78378,
//           "9.804",
//           0.0,
//           98.0,
//           0.0,
//           1.0,
//           3.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         22: [
//           "8057",
//           1.25,
//           12.781,
//           1.1102,
//           1.1181,
//           1.1846,
//           1.2565,
//           "-",
//           7.789,
//           98.0,
//           0.0,
//           1.0,
//           0.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         23: [
//           "1729",
//           1.41,
//           2952.0,
//           0.9006,
//           0.7611,
//           0.6535,
//           0.59875,
//           "0.702",
//           26.9,
//           98.0,
//           0.0,
//           0.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         24: [
//           "0810",
//           1.39,
//           3.794,
//           1.484,
//           1.4644,
//           1.37223,
//           1.124,
//           "1.435",
//           1.9657,
//           98.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "-",
//         ],
//       },
//       {
//         25: [
//           "2187",
//           3.04,
//           1756.0,
//           2.27,
//           2.0294,
//           1.85013,
//           1.70275,
//           "1.941",
//           22.7,
//           98.0,
//           1.0,
//           1.0,
//           3.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         26: [
//           "0471",
//           1.0,
//           111.0,
//           0.8106,
//           0.64365,
//           0.535207,
//           0.470645,
//           "0.579",
//           9.2465,
//           98.0,
//           -1.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         27: [
//           "0827",
//           0.25,
//           103.0,
//           0.28352,
//           0.22425,
//           0.195127,
//           0.16819,
//           "0.205",
//           14.3,
//           98.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         28: [
//           "2255",
//           2.5,
//           11178.0,
//           2.9158,
//           2.7168,
//           2.32913,
//           1.90535,
//           "2.49",
//           100.0,
//           98.0,
//           -1.0,
//           -1.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         29: [
//           "8196",
//           0.62,
//           30.692,
//           0.4435,
//           0.36771,
//           0.317853,
//           0.293435,
//           "0.336",
//           1.86,
//           98.0,
//           0.0,
//           3.0,
//           8.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         30: [
//           "0913",
//           0.79,
//           4.546,
//           0.9784,
//           0.7642,
//           0.6135,
//           0.539675,
//           "0.647",
//           2.1772,
//           98.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "-",
//         ],
//       },
//       {
//         31: [
//           "1735",
//           11.12,
//           1637.9999999999998,
//           11.5378,
//           9.4483,
//           8.53653,
//           7.38905,
//           "9.074",
//           117.4,
//           98.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         32: [
//           "0866",
//           0.36,
//           83.987,
//           0.28644,
//           0.24267,
//           0.23818,
//           0.213745,
//           "0.251",
//           8.9762,
//           98.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         33: [
//           "9933",
//           0.46,
//           125.0,
//           0.3796,
//           0.34195,
//           0.30812,
//           0.2762,
//           "0.318",
//           4.6,
//           98.0,
//           1.0,
//           1.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         34: [
//           "1733",
//           1.39,
//           12081.0,
//           1.236,
//           1.0517,
//           0.943067,
//           0.806875,
//           "1.0",
//           39.8,
//           98.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         35: [
//           "1721",
//           0.64,
//           0.64,
//           0.6029,
//           0.4855,
//           0.447,
//           0.42775,
//           "-",
//           6.4,
//           97.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         36: [
//           "7588",
//           8.7,
//           3040.0,
//           5.75294,
//           5.51669,
//           5.38998,
//           5.10714,
//           "5.523",
//           0.0,
//           97.0,
//           1.0,
//           2.0,
//           6.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         37: [
//           "1481",
//           0.6,
//           93.04,
//           0.8602,
//           0.84622,
//           0.65564,
//           0.56052,
//           "0.688",
//           6.0,
//           97.0,
//           0.0,
//           -1.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         38: [
//           "1780",
//           2.71,
//           5.377,
//           2.638,
//           2.7394,
//           2.65193,
//           2.3419,
//           "2.659",
//           16.8,
//           97.0,
//           0.0,
//           0.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         39: [
//           "8367",
//           0.38,
//           9.57,
//           0.3913,
//           0.5191,
//           0.688133,
//           0.666935,
//           "0.617",
//           3.648,
//           97.0,
//           1.0,
//           2.0,
//           -1.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         40: [
//           "8349",
//           2.05,
//           144.0,
//           2.1146,
//           2.4862,
//           2.81547,
//           2.59335,
//           "2.704",
//           8.2,
//           97.0,
//           0.0,
//           0.0,
//           1.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         41: [
//           "1741",
//           1.97,
//           180.0,
//           1.5782,
//           1.3141,
//           1.26467,
//           1.27205,
//           "1.346",
//           15.7,
//           97.0,
//           0.0,
//           2.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         42: [
//           "8315",
//           0.64,
//           13.04,
//           0.6141,
//           0.47317,
//           0.404847,
//           0.381035,
//           "0.418",
//           3.7212,
//           97.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         43: [
//           "1563",
//           3.4,
//           664.0,
//           2.7402,
//           2.5933,
//           2.5824,
//           2.5899,
//           "2.618",
//           51.0,
//           97.0,
//           0.0,
//           1.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         44: [
//           "3668",
//           30.0,
//           504.0,
//           20.7468,
//           18.9716,
//           17.9781,
//           16.7543,
//           "18.885",
//           396.1,
//           97.0,
//           0.0,
//           -1.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         45: [
//           "8613",
//           0.187,
//           27.803,
//           0.14622,
//           0.12392,
//           0.108967,
//           0.10291,
//           "0.115",
//           2.244,
//           97.0,
//           -1.0,
//           -1.0,
//           -1.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         46: [
//           "1058",
//           0.76,
//           6.84,
//           0.8886,
//           0.8552,
//           0.731833,
//           0.62255,
//           "0.782",
//           4.0889,
//           97.0,
//           0.0,
//           -1.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         47: [
//           "1943",
//           0.77,
//           70.2,
//           0.7642,
//           0.65245,
//           0.638633,
//           0.56775,
//           "0.645",
//           7.7,
//           97.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         48: [
//           "2185",
//           38.9,
//           987.0,
//           35.035,
//           "-",
//           "-",
//           "-",
//           "-",
//           23.9,
//           97.0,
//           0.0,
//           0.0,
//           0.0,
//           1.0,
//           "M",
//         ],
//       },
//       {
//         49: [
//           "6833",
//           1.72,
//           1195.0,
//           1.7502,
//           1.5965,
//           1.2872,
//           1.1112,
//           "1.364",
//           34.9,
//           97.0,
//           -1.0,
//           0.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         50: [
//           "0316",
//           222.4,
//           46837.0,
//           202.656,
//           175.82,
//           169.663,
//           162.769,
//           "171.643",
//           1468.6,
//           97.0,
//           1.0,
//           1.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         51: [
//           "0875",
//           3.57,
//           13.535,
//           3.3156,
//           3.0109,
//           2.67213,
//           2.4244,
//           "2.78",
//           13.5,
//           97.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         52: [
//           "6933",
//           4.81,
//           8.094,
//           5.2526,
//           4.461,
//           3.6012,
//           3.1347,
//           "3.84",
//           19.6,
//           97.0,
//           -1.0,
//           -1.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         53: [
//           "0274",
//           0.445,
//           42.685,
//           0.4688,
//           0.4543,
//           0.4159,
//           0.37381,
//           "0.429",
//           6.7428,
//           97.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         54: [
//           "1870",
//           0.7,
//           97.527,
//           1.233,
//           1.2584,
//           1.1103,
//           0.888995,
//           "1.16",
//           4.368,
//           97.0,
//           -1.0,
//           -1.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         55: [
//           "0145",
//           0.3,
//           7.864,
//           0.167,
//           0.155,
//           0.15124,
//           0.1496,
//           "0.158",
//           1.5699,
//           97.0,
//           0.0,
//           -1.0,
//           10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         56: [
//           "1854",
//           0.305,
//           12.855,
//           0.3278,
//           0.29253,
//           0.259773,
//           0.23636,
//           "0.274",
//           4.6116,
//           97.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         57: [
//           "0370",
//           0.77,
//           97.827,
//           0.6161,
//           0.52315,
//           0.465533,
//           0.426965,
//           "0.489",
//           11.7,
//           97.0,
//           -1.0,
//           -1.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         58: [
//           "1345",
//           2.14,
//           119.0,
//           2.1282,
//           1.6854,
//           1.58393,
//           1.49365,
//           "1.626",
//           26.9,
//           97.0,
//           0.0,
//           1.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         59: [
//           "0279",
//           0.191,
//           155.0,
//           0.21234,
//           0.21277,
//           0.171847,
//           0.151385,
//           "0.177",
//           35.6,
//           96.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         60: [
//           "1726",
//           0.82,
//           16.885,
//           1.0228,
//           0.9359,
//           0.770033,
//           0.7003,
//           "0.795",
//           7.872,
//           96.0,
//           -1.0,
//           -1.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         61: [
//           "1725",
//           15.0,
//           677.0,
//           22.4632,
//           26.1041,
//           27.8564,
//           28.479,
//           "26.792",
//           46.3,
//           96.0,
//           -1.0,
//           -1.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         62: [
//           "3097",
//           6.07,
//           192.0,
//           5.21396,
//           4.74984,
//           4.48584,
//           4.32222,
//           "4.597",
//           0.0,
//           96.0,
//           0.0,
//           -1.0,
//           2.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         63: [
//           "2383",
//           0.84,
//           11.566,
//           0.9032,
//           0.82425,
//           0.684967,
//           0.639675,
//           "0.715",
//           33.2,
//           96.0,
//           1.0,
//           0.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         64: [
//           "1796",
//           1.39,
//           21.19,
//           1.3598,
//           1.24,
//           1.21913,
//           1.08505,
//           "1.245",
//           6.672,
//           96.0,
//           -1.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         65: [
//           "2199",
//           5.15,
//           959.0,
//           6.148,
//           5.7062,
//           4.77907,
//           4.23675,
//           "4.925",
//           63.0,
//           96.0,
//           1.0,
//           2.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         66: [
//           "7500",
//           7.72,
//           85503.0,
//           5.60992,
//           5.4591,
//           5.34561,
//           5.11341,
//           "5.446",
//           0.0,
//           96.0,
//           1.0,
//           2.0,
//           7.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         67: [
//           "0340",
//           0.77,
//           161.0,
//           0.7238,
//           0.7397,
//           0.726233,
//           0.634225,
//           "0.739",
//           26.1,
//           96.0,
//           0.0,
//           1.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         68: [
//           "8040",
//           0.32,
//           1.44,
//           0.3508,
//           0.38005,
//           0.31996,
//           0.278355,
//           "0.334",
//           1.024,
//           96.0,
//           1.0,
//           0.0,
//           -1.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         69: [
//           "8017",
//           1.09,
//           254.0,
//           0.9208,
//           0.8516,
//           0.760167,
//           0.746725,
//           "0.793",
//           6.54,
//           96.0,
//           -1.0,
//           -2.0,
//           -1.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         70: [
//           "1823",
//           3.12,
//           77.486,
//           3.5992,
//           3.5487,
//           3.86873,
//           3.30735,
//           "4.091",
//           12.8,
//           96.0,
//           0.0,
//           -1.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         71: [
//           "8118",
//           0.51,
//           80.464,
//           0.4865,
//           0.35992,
//           0.329007,
//           0.34053,
//           "0.343",
//           2.55,
//           96.0,
//           -1.0,
//           -1.0,
//           -1.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         72: [
//           "0695",
//           5.13,
//           348.0,
//           4.513,
//           4.5941,
//           4.91633,
//           4.4575,
//           "4.802",
//           28.3,
//           96.0,
//           1.0,
//           1.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         73: [
//           "1230",
//           0.91,
//           8606.0,
//           0.518,
//           0.5366,
//           0.542067,
//           0.57775,
//           "0.547",
//           43.1,
//           96.0,
//           58.0,
//           58.0,
//           65.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         74: [
//           "8238",
//           0.175,
//           4.644,
//           0.11104,
//           0.1154,
//           0.115267,
//           0.1152,
//           "0.119",
//           0.9072,
//           96.0,
//           -1.0,
//           -1.0,
//           70.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         75: [
//           "0830",
//           1.9,
//           707.0,
//           1.8814,
//           1.7581,
//           1.75173,
//           1.66675,
//           "1.757",
//           40.9,
//           96.0,
//           -1.0,
//           0.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         76: [
//           "0990",
//           1.02,
//           3291.0,
//           0.9424,
//           0.8871,
//           0.9648,
//           1.0432,
//           "0.922",
//           137.4,
//           96.0,
//           -1.0,
//           -1.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         77: [
//           "1327",
//           0.35,
//           12.025,
//           0.3513,
//           0.3449,
//           0.316887,
//           0.26833,
//           "0.333",
//           1.8869,
//           96.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         78: [
//           "8526",
//           1.1,
//           8.89,
//           1.0754,
//           0.9139,
//           0.86532,
//           0.87983,
//           "0.887",
//           1.7847,
//           96.0,
//           -1.0,
//           1.0,
//           -1.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         79: [
//           "8511",
//           0.34,
//           5.504,
//           0.4312,
//           0.37671,
//           0.30754,
//           0.269235,
//           "0.316",
//           1.36,
//           96.0,
//           0.0,
//           -2.0,
//           -1.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         80: [
//           "1393",
//           0.45,
//           101.0,
//           0.4745,
//           0.44,
//           0.394,
//           0.3505,
//           "0.409",
//           9.2051,
//           96.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         81: [
//           "1626",
//           2.0,
//           4.0,
//           1.9228,
//           1.8421,
//           1.76367,
//           1.6496,
//           "1.79",
//           6.0,
//           96.0,
//           1.0,
//           1.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         82: [
//           "2380",
//           3.8,
//           72485.0,
//           4.2802,
//           4.2192,
//           3.8568,
//           3.33595,
//           "4.031",
//           411.6,
//           95.0,
//           -1.0,
//           -1.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         83: [
//           "1783",
//           0.325,
//           19.585,
//           0.26478,
//           0.25822,
//           0.28448,
//           0.29071,
//           "0.274",
//           2.6893,
//           95.0,
//           -1.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         84: [
//           "0343",
//           0.73,
//           379.0,
//           0.7484,
//           0.7664,
//           0.7594,
//           0.73385,
//           "0.758",
//           12.1,
//           95.0,
//           1.0,
//           1.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         85: [
//           "1198",
//           2.83,
//           41.268,
//           2.939,
//           2.6334,
//           2.52167,
//           2.38055,
//           "2.563",
//           73.5,
//           95.0,
//           1.0,
//           1.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         86: [
//           "1085",
//           3.95,
//           133.0,
//           3.8578,
//           3.6189,
//           3.36967,
//           3.10825,
//           "3.471",
//           15.3,
//           95.0,
//           2.0,
//           1.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         87: [
//           "1587",
//           0.7,
//           39.696,
//           0.8564,
//           0.77,
//           0.681367,
//           0.610625,
//           "0.705",
//           4.76,
//           95.0,
//           -1.0,
//           -1.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         88: [
//           "1305",
//           1.16,
//           0.349,
//           1.219,
//           1.2194,
//           1.02233,
//           0.91565,
//           "1.064",
//           2.5151,
//           95.0,
//           0.0,
//           1.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         89: [
//           "1063",
//           0.315,
//           3.72,
//           0.342,
//           0.3686,
//           0.376433,
//           0.34321,
//           "0.369",
//           4.7027,
//           95.0,
//           -1.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         90: [
//           "0899",
//           0.066,
//           0.396,
//           0.0794,
//           0.07935,
//           0.06812,
//           0.06155,
//           "0.072",
//           5.0237,
//           95.0,
//           -1.0,
//           -1.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         91: [
//           "0020",
//           6.29,
//           13737.0,
//           6.74,
//           "-",
//           "-",
//           "-",
//           "-",
//           2107.6,
//           95.0,
//           -1.0,
//           -1.0,
//           0.0,
//           1.0,
//           "M",
//         ],
//       },
//       {
//         92: [
//           "1719",
//           1.14,
//           174.0,
//           1.1294,
//           0.9775,
//           0.900467,
//           0.8686,
//           "0.925",
//           19.6,
//           95.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         93: [
//           "3175",
//           7.29,
//           2488.0,
//           6.3119,
//           5.7739,
//           5.46483,
//           5.27653,
//           "5.598",
//           0.0,
//           95.0,
//           -1.0,
//           -1.0,
//           1.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         94: [
//           "1171",
//           18.96,
//           69109.0,
//           18.503,
//           15.9211,
//           15.1278,
//           14.1201,
//           "15.629",
//           360.2,
//           95.0,
//           -2.0,
//           -2.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         95: [
//           "1520",
//           0.265,
//           106.0,
//           0.25148,
//           0.21518,
//           0.204113,
//           0.212795,
//           "0.209",
//           4.7442,
//           95.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         96: [
//           "2169",
//           2.25,
//           227.0,
//           2.266,
//           1.97,
//           1.81667,
//           1.69425,
//           "1.857",
//           22.5,
//           95.0,
//           -1.0,
//           -1.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         97: [
//           "1529",
//           0.33,
//           3.25,
//           0.3385,
//           0.30772,
//           0.29758,
//           0.279755,
//           "0.302",
//           2.9185,
//           95.0,
//           -1.0,
//           0.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         98: [
//           "8159",
//           0.185,
//           2.86,
//           0.12576,
//           0.13088,
//           0.13086,
//           0.130065,
//           "0.131",
//           1.3024,
//           95.0,
//           2.0,
//           8.0,
//           61.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         99: [
//           "8268",
//           0.7,
//           7.984,
//           0.7036,
//           0.7243,
//           0.684567,
//           0.6097,
//           "0.701",
//           1.4,
//           95.0,
//           3.0,
//           3.0,
//           2.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         100: [
//           "0195",
//           0.15,
//           179.0,
//           0.11316,
//           0.10438,
//           0.1032,
//           0.103495,
//           "0.104",
//           10.2,
//           95.0,
//           1.0,
//           1.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         101: [
//           "1533",
//           9.76,
//           469.0,
//           9.5574,
//           8.5645,
//           7.84287,
//           7.4543,
//           "8.132",
//           3.4286,
//           95.0,
//           0.0,
//           0.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         102: [
//           "1108",
//           14.9,
//           10738.0,
//           14.7084,
//           14.3574,
//           13.37,
//           12.458,
//           "13.632",
//           37.2,
//           95.0,
//           -1.0,
//           2.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         103: [
//           "1527",
//           1.6,
//           3.711,
//           1.0796,
//           1.0346,
//           1.04553,
//           1.04195,
//           "1.078",
//           0.56,
//           95.0,
//           0.0,
//           -1.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         104: [
//           "8395",
//           0.93,
//           64.3,
//           0.9238,
//           0.8866,
//           0.8086,
//           0.735475,
//           "0.824",
//           14.7,
//           95.0,
//           1.0,
//           2.0,
//           3.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         105: [
//           "1025",
//           0.375,
//           10.852,
//           0.416404,
//           0.458224,
//           0.418716,
//           0.386812,
//           "0.428",
//           3.1591,
//           94.0,
//           0.0,
//           1.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         106: [
//           "6663",
//           1.07,
//           46.89,
//           0.9486,
//           0.8585,
//           0.812667,
//           "-",
//           "-",
//           8.56,
//           94.0,
//           0.0,
//           -1.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         107: [
//           "1706",
//           0.243,
//           15.86,
//           0.25012,
//           0.25442,
//           0.260007,
//           0.248225,
//           "0.26",
//           2.43,
//           94.0,
//           -1.0,
//           1.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         108: [
//           "2006",
//           2.9,
//           3503.0,
//           2.9392,
//           2.6709,
//           2.30967,
//           2.17115,
//           "2.405",
//           40.30000000000001,
//           94.0,
//           -1.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         109: [
//           "1962",
//           1.22,
//           15.65,
//           1.223,
//           1.1342,
//           0.949367,
//           0.884725,
//           "1.002",
//           8.3702,
//           94.0,
//           0.0,
//           0.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         110: [
//           "0646",
//           0.64,
//           2.844,
//           0.6906,
//           0.57056,
//           0.489073,
//           0.492305,
//           "0.512",
//           1.4017,
//           94.0,
//           -1.0,
//           -4.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         111: [
//           "2343",
//           4.22,
//           21457.0,
//           3.5632,
//           3.3314,
//           3.50527,
//           3.3978,
//           "3.516",
//           203.7,
//           94.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         112: [
//           "6928",
//           3.37,
//           41.996,
//           3.4762,
//           3.5854,
//           3.74173,
//           3.66365,
//           "3.692",
//           15.1,
//           94.0,
//           0.0,
//           0.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         113: [
//           "8191",
//           0.76,
//           107.0,
//           0.9548,
//           1.2748,
//           1.4128,
//           1.6547,
//           "-",
//           0.4001,
//           94.0,
//           -2.0,
//           -2.0,
//           -3.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         114: [
//           "0556",
//           0.95,
//           551.0,
//           0.9328,
//           0.9233,
//           0.917867,
//           0.823025,
//           "0.935",
//           7.98,
//           94.0,
//           1.0,
//           1.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         115: [
//           "2914",
//           0.265,
//           4.355,
//           "-",
//           "-",
//           "-",
//           "-",
//           "-",
//           0.1385,
//           94.0,
//           0.0,
//           "-",
//           "-",
//           "-",
//           "-",
//         ],
//       },
//       {
//         116: [
//           "8223",
//           2.34,
//           53.368,
//           2.4452,
//           2.2702,
//           2.19953,
//           2.02725,
//           "2.237",
//           9.36,
//           94.0,
//           0.0,
//           -1.0,
//           -1.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         117: [
//           "0401",
//           0.165,
//           8.052,
//           0.18368,
//           0.17076,
//           0.15572,
//           0.14581,
//           "0.16",
//           0.9243,
//           94.0,
//           -1.0,
//           -1.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         118: [
//           "8072",
//           0.255,
//           93.954,
//           0.3136,
//           0.36515,
//           0.32736,
//           0.28005,
//           "0.328",
//           1.7903,
//           94.0,
//           1.0,
//           1.0,
//           2.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         119: [
//           "8375",
//           0.245,
//           21.456,
//           0.243579,
//           0.274583,
//           0.375703,
//           0.400084,
//           "0.346",
//           0.7056,
//           94.0,
//           0.0,
//           1.0,
//           11.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         120: [
//           "3311",
//           9.5,
//           17152.0,
//           9.9632,
//           9.1202,
//           8.3022,
//           7.554,
//           "8.626",
//           478.5,
//           94.0,
//           -1.0,
//           -1.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         121: [
//           "1782",
//           0.69,
//           65.21,
//           0.6381,
//           0.5089,
//           0.4732,
//           0.480725,
//           "0.482",
//           3.5052,
//           94.0,
//           -2.0,
//           -2.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         122: [
//           "3773",
//           0.69,
//           45.264,
//           0.6926,
//           0.6471,
//           0.5626,
//           0.524175,
//           "0.578",
//           2.8635,
//           94.0,
//           -1.0,
//           -1.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         123: [
//           "0776",
//           14.52,
//           104.0,
//           16.4388,
//           16.8461,
//           15.2965,
//           14.5786,
//           "15.52",
//           45.1,
//           94.0,
//           -1.0,
//           -1.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         124: [
//           "7362",
//           7.56,
//           85.686,
//           6.1572,
//           6.01695,
//           5.94303,
//           5.7734,
//           "6.011",
//           0.0,
//           94.0,
//           1.0,
//           4.0,
//           8.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         125: [
//           "8120",
//           0.157,
//           3.091,
//           0.13576,
//           0.12927,
//           0.125847,
//           0.131872,
//           "0.127",
//           0.961,
//           94.0,
//           0.0,
//           3.0,
//           4.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         126: [
//           "0228",
//           0.162,
//           98.316,
//           0.15352,
//           0.15263,
//           0.14058,
//           0.131205,
//           "0.144",
//           15.3,
//           94.0,
//           -1.0,
//           -1.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         127: [
//           "1639",
//           1.76,
//           253.0,
//           1.6842,
//           1.5509,
//           1.37387,
//           1.27615,
//           "1.417",
//           27.0,
//           94.0,
//           -1.0,
//           -1.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         128: [
//           "8622",
//           0.216,
//           0.82,
//           0.24168,
//           0.24683,
//           0.2483,
//           0.21927,
//           "0.242",
//           0.864,
//           94.0,
//           0.0,
//           2.0,
//           -2.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         129: [
//           "2633",
//           0.79,
//           129.0,
//           0.746,
//           0.63825,
//           0.636167,
//           0.659375,
//           "0.642",
//           15.2,
//           93.0,
//           0.0,
//           3.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         130: [
//           "2448",
//           5.24,
//           56.355,
//           5.5054,
//           5.532,
//           5.3198,
//           4.877,
//           "5.437",
//           42.2,
//           93.0,
//           0.0,
//           0.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         131: [
//           "0442",
//           1.5,
//           5.064,
//           1.7068,
//           1.7204,
//           1.61947,
//           1.3965,
//           "1.697",
//           2.589,
//           93.0,
//           -1.0,
//           0.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         132: [
//           "0935",
//           1.27,
//           42.324,
//           1.2346,
//           1.2223,
//           1.1994,
//           1.12145,
//           "1.213",
//           15.5,
//           93.0,
//           0.0,
//           2.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         133: [
//           "0948",
//           1.4,
//           1.664,
//           1.4712,
//           1.3731,
//           1.26713,
//           1.15785,
//           "1.32",
//           4.401,
//           93.0,
//           1.0,
//           -2.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         134: [
//           "0995",
//           7.0,
//           1806.0,
//           6.9738,
//           6.231,
//           5.74293,
//           5.55085,
//           "5.907",
//           34.5,
//           93.0,
//           1.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         135: [
//           "1001",
//           0.41,
//           6.492,
//           0.4312,
//           0.4156,
//           0.3777,
//           0.347235,
//           "0.389",
//           2.6256,
//           93.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         136: [
//           "1891",
//           0.265,
//           49.818,
//           0.24954,
//           0.24384,
//           0.229947,
//           0.217405,
//           "0.234",
//           2.65,
//           93.0,
//           0.0,
//           1.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         137: [
//           "1262",
//           0.48,
//           2.872,
//           0.4935,
//           0.4957,
//           0.4556,
//           0.418425,
//           "0.471",
//           6.379,
//           93.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         138: [
//           "1419",
//           1.38,
//           22.64,
//           1.4394,
//           1.318,
//           1.25093,
//           1.18695,
//           "1.271",
//           5.2378,
//           93.0,
//           -1.0,
//           -1.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         139: [
//           "1795",
//           0.9,
//           21.681,
//           0.8962,
//           0.833,
//           0.757133,
//           0.71625,
//           "0.778",
//           5.4,
//           93.0,
//           -1.0,
//           0.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         140: [
//           "1523",
//           2.9,
//           218.0,
//           3.6754,
//           3.609,
//           3.30627,
//           2.94705,
//           "3.367",
//           31.6,
//           93.0,
//           -1.0,
//           0.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         141: [
//           "1576",
//           2.92,
//           136.0,
//           2.9174,
//           2.6688,
//           2.5238,
//           2.44345,
//           "2.577",
//           32.1,
//           93.0,
//           2.0,
//           2.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         142: [
//           "1633",
//           0.235,
//           0.705,
//           0.18464,
//           0.18817,
//           0.1962,
//           0.184695,
//           "0.198",
//           1.6091,
//           93.0,
//           -2.0,
//           0.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         143: [
//           "1798",
//           3.15,
//           15157.0,
//           3.1984,
//           3.1707,
//           3.03033,
//           2.79175,
//           "3.142",
//           78.7,
//           93.0,
//           -2.0,
//           -1.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         144: [
//           "0076",
//           0.036,
//           32.355,
//           0.045,
//           0.03977,
//           0.03508,
//           0.032875,
//           "0.037",
//           2.5223,
//           93.0,
//           -1.0,
//           -1.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         145: [
//           "8011",
//           0.139,
//           13.899,
//           0.16018,
//           0.14277,
//           0.116193,
//           0.10682,
//           "0.121",
//           5.333400000000001,
//           93.0,
//           -1.0,
//           0.0,
//           -2.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         146: [
//           "8617",
//           0.75,
//           44.865,
//           0.5766,
//           0.5571,
//           0.562267,
//           0.5797,
//           "0.564",
//           3.0,
//           93.0,
//           1.0,
//           8.0,
//           13.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         147: [
//           "8096",
//           0.175,
//           12.978,
//           0.18406,
//           0.25964,
//           0.250853,
//           0.22345,
//           "0.251",
//           0.875,
//           93.0,
//           12.0,
//           11.0,
//           18.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         148: [
//           "7300",
//           6.125,
//           4769.0,
//           5.1968,
//           5.11738,
//           5.05591,
//           4.93192,
//           "5.105",
//           0.0,
//           93.0,
//           2.0,
//           3.0,
//           9.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         149: [
//           "8603",
//           0.38,
//           11.02,
//           0.3697,
//           0.3795,
//           0.360467,
//           0.331725,
//           "0.368",
//           3.04,
//           93.0,
//           7.0,
//           4.0,
//           1.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         150: [
//           "6128",
//           1.13,
//           52.174,
//           1.1608,
//           0.9601,
//           0.907467,
//           0.9058,
//           "0.922",
//           5.7799,
//           93.0,
//           0.0,
//           1.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         151: [
//           "8495",
//           0.32,
//           2.932,
//           0.3329,
//           0.3585,
//           0.336947,
//           0.30018,
//           "0.351",
//           1.2288,
//           93.0,
//           -1.0,
//           0.0,
//           -2.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         152: [
//           "8379",
//           0.139,
//           0.657,
//           0.16554,
//           0.1748,
//           0.14622,
//           0.138655,
//           "0.149",
//           1.112,
//           92.0,
//           3.0,
//           0.0,
//           -1.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         153: [
//           "0269",
//           0.021,
//           37.491,
//           0.02476,
//           0.02009,
//           0.01808,
//           0.01802,
//           "0.019",
//           2.2352,
//           92.0,
//           -1.0,
//           0.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         154: [
//           "0531",
//           0.335,
//           84.914,
//           0.3387,
//           0.28646,
//           0.285507,
//           0.292855,
//           "0.288",
//           10.4,
//           92.0,
//           -2.0,
//           0.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         155: [
//           "1867",
//           0.3,
//           197.0,
//           0.3028,
//           0.3151,
//           0.312519,
//           0.313,
//           "0.324",
//           4.032,
//           92.0,
//           -1.0,
//           -1.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         156: [
//           "1442",
//           1.4,
//           216.0,
//           1.6674,
//           1.647,
//           1.57787,
//           1.488,
//           "1.608",
//           28.8,
//           92.0,
//           1.0,
//           -4.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         157: [
//           "8402",
//           2.54,
//           15.835,
//           2.4914,
//           2.4226,
//           2.40973,
//           2.35575,
//           "2.441",
//           12.1,
//           92.0,
//           0.0,
//           -2.0,
//           -3.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         158: [
//           "8611",
//           0.11,
//           0.0,
//           0.10378,
//           0.10528,
//           0.0989,
//           0.09436,
//           "0.099",
//           0.429,
//           92.0,
//           6.0,
//           8.0,
//           1.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         159: [
//           "8340",
//           0.058,
//           2.784,
//           0.06094,
//           0.05284,
//           0.04858,
//           0.049495,
//           "0.05",
//           0.3712,
//           92.0,
//           -1.0,
//           1.0,
//           -1.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         160: [
//           "1161",
//           1.33,
//           242.0,
//           1.6266,
//           1.4674,
//           1.37893,
//           1.3528,
//           "1.407",
//           9.0513,
//           92.0,
//           0.0,
//           1.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         161: [
//           "2132",
//           0.24,
//           68.444,
//           0.2438,
//           0.2513,
//           0.232167,
//           0.214735,
//           "0.237",
//           3.84,
//           92.0,
//           -1.0,
//           2.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         162: [
//           "2022",
//           0.107,
//           20.347,
//           0.10378,
//           0.09304,
//           0.08822,
//           0.08845,
//           "0.089",
//           2.14,
//           92.0,
//           0.0,
//           2.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         163: [
//           "8173",
//           0.5,
//           75.87,
//           0.5132,
//           0.6696,
//           0.568033,
//           0.507775,
//           "0.6",
//           1.0767,
//           92.0,
//           -4.0,
//           -3.0,
//           -3.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         164: [
//           "1672",
//           3.75,
//           5268.0,
//           4.3462,
//           3.6113,
//           3.3414,
//           3.3618,
//           "3.33",
//           40.70000000000001,
//           92.0,
//           2.0,
//           -3.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         165: [
//           "1969",
//           1.9,
//           156.0,
//           1.8912,
//           1.7115,
//           1.6118,
//           1.5668,
//           "1.647",
//           22.8,
//           92.0,
//           1.0,
//           0.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         166: [
//           "0151",
//           7.78,
//           13307.0,
//           7.7726,
//           7.1179,
//           6.5696,
//           6.3228,
//           "6.731",
//           925.7,
//           92.0,
//           0.0,
//           0.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         167: [
//           "1939",
//           0.9,
//           1.78,
//           0.965,
//           0.9281,
//           0.834333,
//           0.79995,
//           "0.852",
//           4.5,
//           92.0,
//           0.0,
//           1.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         168: [
//           "1936",
//           0.79,
//           6.316,
//           0.6614,
//           0.6159,
//           0.698867,
//           0.7463,
//           "0.682",
//           3.7288,
//           92.0,
//           0.0,
//           2.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         169: [
//           "0187",
//           3.3,
//           1590.0,
//           3.8878,
//           3.5007,
//           3.1482,
//           2.8971,
//           "3.247",
//           3.3,
//           92.0,
//           -3.0,
//           -4.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         170: [
//           "7336",
//           6.145,
//           21.575,
//           5.2307,
//           5.15808,
//           5.10988,
//           4.99671,
//           "5.155",
//           0.0,
//           92.0,
//           1.0,
//           3.0,
//           9.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         171: [
//           "0865",
//           0.093,
//           0.743,
//           0.09618,
//           0.08257,
//           0.07838,
//           0.07814,
//           "0.08",
//           5.4293,
//           92.0,
//           -1.0,
//           -1.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         172: [
//           "0609",
//           1.1,
//           42.38,
//           1.2884,
//           1.1995,
//           1.13367,
//           1.0444,
//           "1.152",
//           9.3714,
//           92.0,
//           -1.0,
//           -1.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         173: [
//           "9919",
//           1.28,
//           114.0,
//           1.5396,
//           1.4324,
//           1.4854,
//           1.32745,
//           "1.467",
//           9.6475,
//           92.0,
//           0.0,
//           2.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         174: [
//           "0612",
//           3.9,
//           1066.0,
//           3.4948,
//           3.2328,
//           3.43753,
//           3.3198,
//           "3.355",
//           52.6,
//           92.0,
//           -1.0,
//           2.0,
//           1.0,
//           0.0,
//           "-",
//         ],
//       },
//       {
//         175: [
//           "9979",
//           4.9,
//           3310.0,
//           5.9114,
//           5.0551,
//           4.56767,
//           4.66335,
//           "4.65",
//           95.9,
//           91.0,
//           -2.0,
//           -4.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         176: [
//           "2130",
//           9.99,
//           1082.0,
//           9.4842,
//           9.642,
//           9.03587,
//           8.69545,
//           "9.17",
//           27.5,
//           91.0,
//           0.0,
//           6.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         177: [
//           "1395",
//           0.149,
//           0.149,
//           0.14164,
//           0.13334,
//           0.133133,
//           0.130075,
//           "0.132",
//           1.6498,
//           91.0,
//           2.0,
//           3.0,
//           7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         178: [
//           "0985",
//           2.4,
//           9.273,
//           2.409,
//           2.1467,
//           1.9616,
//           1.95355,
//           "2.014",
//           11.6,
//           91.0,
//           0.0,
//           2.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         179: [
//           "1385",
//           21.2,
//           6028.0,
//           21.6538,
//           23.3556,
//           23.2275,
//           22.4865,
//           "22.845",
//           60.2,
//           91.0,
//           0.0,
//           2.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         180: [
//           "1539",
//           0.395,
//           98.49,
//           0.6995,
//           0.6422,
//           0.571433,
//           0.56175,
//           "0.573",
//           9.4233,
//           91.0,
//           -2.0,
//           -3.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         181: [
//           "8282",
//           0.55,
//           0.88,
//           0.629,
//           0.58255,
//           0.541667,
//           0.5035,
//           "0.56",
//           0.88,
//           91.0,
//           0.0,
//           -3.0,
//           -1.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         182: [
//           "0988",
//           0.136,
//           2.76,
//           0.143,
//           0.14094,
//           0.14782,
//           0.163125,
//           "-",
//           0.8728,
//           91.0,
//           -1.0,
//           0.0,
//           80.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         183: [
//           "0330",
//           0.89,
//           1162.0,
//           0.8282,
//           0.7527,
//           0.739533,
//           0.73935,
//           "0.749",
//           25.1,
//           91.0,
//           0.0,
//           1.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         184: [
//           "0033",
//           1.74,
//           112.0,
//           1.5174,
//           1.3795,
//           1.3472,
//           1.37855,
//           "1.381",
//           9.348,
//           91.0,
//           -1.0,
//           -1.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         185: [
//           "6036",
//           0.305,
//           3.932,
//           0.3176,
//           0.30215,
//           0.313167,
//           0.29699,
//           "0.308",
//           3.0791,
//           91.0,
//           1.0,
//           -3.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         186: [
//           "1967",
//           24.0,
//           204.0,
//           25.678,
//           23.6584,
//           23.9723,
//           22.1395,
//           "24.41",
//           60.0,
//           91.0,
//           1.0,
//           0.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         187: [
//           "0800",
//           0.3,
//           61.004,
//           0.3554,
//           0.31433,
//           0.293,
//           0.271325,
//           "0.298",
//           8.1311,
//           91.0,
//           -1.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         188: [
//           "1091",
//           0.91,
//           8097.0,
//           1.1172,
//           1.09,
//           1.17807,
//           1.0472,
//           "1.14",
//           31.1,
//           91.0,
//           -3.0,
//           -6.0,
//           -5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         189: [
//           "1925",
//           1.36,
//           9.7,
//           1.2716,
//           1.2123,
//           1.1954,
//           1.1807,
//           "1.205",
//           5.5085,
//           91.0,
//           1.0,
//           3.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         190: [
//           "3878",
//           0.54,
//           129.0,
//           0.5147,
//           0.54785,
//           0.573967,
//           0.5116,
//           "0.563",
//           2.5898000000000003,
//           91.0,
//           -1.0,
//           8.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         191: [
//           "0751",
//           3.78,
//           7802.0,
//           4.6286,
//           4.7791,
//           3.9706,
//           3.5282,
//           "4.086",
//           100.3,
//           91.0,
//           -2.0,
//           -1.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         192: [
//           "0731",
//           0.43,
//           4.3,
//           1.2242,
//           0.7946,
//           0.6514,
//           0.5798,
//           "-",
//           6.0827,
//           91.0,
//           1.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         193: [
//           "7348",
//           9.765,
//           88.69,
//           8.6776,
//           8.4844,
//           8.53193,
//           8.40057,
//           "8.55",
//           0.0,
//           91.0,
//           1.0,
//           2.0,
//           10.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         194: [
//           "7333",
//           8.095,
//           857.0,
//           7.2754,
//           7.08845,
//           7.04973,
//           6.96672,
//           "7.089",
//           0.0,
//           91.0,
//           1.0,
//           2.0,
//           9.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         195: [
//           "0139",
//           0.016,
//           35.14,
//           0.0138,
//           0.01228,
//           0.0124667,
//           0.013415,
//           "0.012",
//           2.5551,
//           91.0,
//           -2.0,
//           1.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         196: [
//           "7299",
//           9.03,
//           3595.0,
//           8.0707,
//           7.84165,
//           7.70877,
//           7.76918,
//           "7.764",
//           0.0,
//           91.0,
//           0.0,
//           1.0,
//           10.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         197: [
//           "1919",
//           13.74,
//           124000.0,
//           15.0632,
//           13.9448,
//           13.7622,
//           13.7635,
//           "13.774",
//           460.9,
//           91.0,
//           -1.0,
//           -3.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         198: [
//           "1866",
//           5.6,
//           2809.0,
//           5.9162,
//           5.5545,
//           5.65907,
//           5.3262,
//           "5.695",
//           65.6,
//           90.0,
//           -2.0,
//           -3.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         199: [
//           "0838",
//           1.09,
//           2323.0,
//           1.6006,
//           1.7106,
//           1.4154,
//           1.2675,
//           "1.427",
//           19.0,
//           90.0,
//           -2.0,
//           -1.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         200: [
//           "7522",
//           2.282,
//           1532.0,
//           1.96196,
//           1.82366,
//           1.85815,
//           1.93471,
//           "1.864",
//           0.0,
//           90.0,
//           1.0,
//           4.0,
//           11.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         201: [
//           "0159",
//           0.27,
//           2046.0,
//           0.2923,
//           0.28689,
//           0.284893,
//           0.26996,
//           "0.29",
//           25.0,
//           90.0,
//           -2.0,
//           -1.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         202: [
//           "2125",
//           4.19,
//           64.626,
//           5.0564,
//           3.9194,
//           3.987,
//           4.8658,
//           "3.888",
//           29.1,
//           90.0,
//           0.0,
//           2.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         203: [
//           "7568",
//           1.57,
//           11615.0,
//           1.3464,
//           1.25246,
//           1.27643,
//           1.3292,
//           "1.281",
//           0.0,
//           90.0,
//           1.0,
//           4.0,
//           11.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         204: [
//           "0688",
//           21.55,
//           110000.0,
//           23.0118,
//           20.6019,
//           19.6175,
//           19.2603,
//           "19.896",
//           2358.6,
//           90.0,
//           0.0,
//           -2.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         205: [
//           "1623",
//           0.34,
//           60.621,
//           0.3497,
//           0.33335,
//           0.30538,
//           0.294285,
//           "0.315",
//           5.7678,
//           90.0,
//           -2.0,
//           -3.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         206: [
//           "1935",
//           3.22,
//           227.0,
//           3.1516,
//           2.7524,
//           2.4794,
//           2.62485,
//           "2.562",
//           51.5,
//           90.0,
//           1.0,
//           11.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         207: [
//           "8167",
//           0.178,
//           2.047,
//           0.19366,
//           0.17166,
//           0.168207,
//           0.16917,
//           "0.169",
//           16.9,
//           90.0,
//           -1.0,
//           1.0,
//           6.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         208: [
//           "6830",
//           2.71,
//           759.0,
//           3.0122,
//           3.1963,
//           3.42007,
//           3.0486,
//           "3.356",
//           47.9,
//           90.0,
//           1.0,
//           1.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         209: [
//           "1498",
//           0.86,
//           59.057,
//           0.875,
//           0.8343,
//           0.824133,
//           0.7972,
//           "0.824",
//           3.396,
//           90.0,
//           0.0,
//           5.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         210: [
//           "1038",
//           52.5,
//           19131.0,
//           49.512,
//           47.96,
//           46.992,
//           47.044,
//           "47.27",
//           1322.7000000000003,
//           90.0,
//           1.0,
//           2.0,
//           12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         211: [
//           "1223",
//           1.04,
//           63.61,
//           1.0822,
//           1.0118,
//           0.975133,
//           0.94995,
//           "0.984",
//           30.9,
//           90.0,
//           0.0,
//           -1.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         212: [
//           "1088",
//           20.1,
//           88712.0,
//           20.6946,
//           19.0069,
//           18.3818,
//           17.8178,
//           "18.662",
//           678.7999999999998,
//           90.0,
//           -2.0,
//           -2.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         213: [
//           "1263",
//           7.9,
//           1981.0,
//           11.1368,
//           12.1618,
//           10.5936,
//           9.07205,
//           "10.881",
//           30.6,
//           90.0,
//           -2.0,
//           -3.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         214: [
//           "1121",
//           8.83,
//           3726.0,
//           9.5576,
//           9.8363,
//           10.4265,
//           9.2179,
//           "10.394",
//           151.1,
//           90.0,
//           -1.0,
//           -1.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         215: [
//           "8620",
//           0.213,
//           1.551,
//           0.24398,
//           0.25001,
//           0.226593,
//           0.207385,
//           "0.233",
//           1.0224,
//           90.0,
//           1.0,
//           3.0,
//           0.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         216: [
//           "1321",
//           1.1,
//           0.22,
//           1.0576,
//           0.9947,
//           0.944267,
//           0.9375,
//           "0.954",
//           22.1,
//           90.0,
//           -1.0,
//           1.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         217: [
//           "1368",
//           9.19,
//           33723.0,
//           12.8238,
//           11.854,
//           11.9368,
//           11.9784,
//           "11.858",
//           241.7,
//           90.0,
//           -4.0,
//           -5.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         218: [
//           "1328",
//           0.105,
//           1.042,
//           0.10602,
//           0.10159,
//           0.0971067,
//           0.096135,
//           "0.098",
//           11.9,
//           90.0,
//           2.0,
//           0.0,
//           6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         219: [
//           "1084",
//           1.33,
//           68.472,
//           1.1716,
//           1.1461,
//           1.14307,
//           1.15855,
//           "1.155",
//           10.9,
//           90.0,
//           0.0,
//           4.0,
//           8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         220: [
//           "0050",
//           6.68,
//           277.0,
//           7.1486,
//           6.3158,
//           6.087,
//           6.0287,
//           "6.155",
//           23.7,
//           90.0,
//           -1.0,
//           0.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         221: [
//           "0883",
//           9.47,
//           183000.0,
//           9.5008,
//           8.7866,
//           8.59987,
//           8.57025,
//           "8.664",
//           4228.1,
//           90.0,
//           0.0,
//           -2.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         222: [
//           "0710",
//           6.62,
//           2741.0,
//           9.4684,
//           9.3992,
//           9.06473,
//           8.45245,
//           "9.103",
//           48.7,
//           89.0,
//           -2.0,
//           -3.0,
//           -5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         223: [
//           "0426",
//           0.166,
//           4.728,
//           0.19012,
//           0.18657,
//           0.188813,
//           0.18904,
//           "0.189",
//           0.6654000000000001,
//           89.0,
//           -3.0,
//           -3.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         224: [
//           "7373",
//           6.22,
//           10.23,
//           5.6291,
//           5.5542,
//           5.61107,
//           5.61928,
//           "5.613",
//           0.0,
//           89.0,
//           2.0,
//           5.0,
//           17.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         225: [
//           "0716",
//           0.98,
//           1338.0,
//           1.1248,
//           1.0717,
//           1.07847,
//           1.01365,
//           "1.067",
//           23.6,
//           89.0,
//           1.0,
//           -1.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         226: [
//           "8042",
//           0.051,
//           9.89,
//           0.05614,
//           0.06198,
//           0.0576267,
//           0.054175,
//           "0.058",
//           0.408,
//           89.0,
//           3.0,
//           25.0,
//           28.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         227: [
//           "0255",
//           3.8,
//           35.424,
//           3.8512,
//           3.7803,
//           3.75127,
//           3.6917,
//           "3.76",
//           24.0,
//           89.0,
//           0.0,
//           2.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         228: [
//           "0728",
//           2.87,
//           34111.0,
//           2.936,
//           2.7801,
//           2.77133,
//           2.7718,
//           "2.762",
//           398.2,
//           89.0,
//           0.0,
//           1.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         229: [
//           "2840",
//           1442.5,
//           8185.0,
//           1357.8,
//           1335.32,
//           1321.14,
//           1322.85,
//           "1327.6",
//           0.0,
//           89.0,
//           1.0,
//           2.0,
//           11.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         230: [
//           "3997",
//           1.25,
//           0.25,
//           1.3764,
//           1.3616,
//           1.3104,
//           1.26075,
//           "1.336",
//           1.6042,
//           89.0,
//           1.0,
//           4.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         231: [
//           "1184",
//           4.4,
//           55.146,
//           5.204,
//           4.9384,
//           4.76287,
//           4.40945,
//           "4.822",
//           27.5,
//           89.0,
//           0.0,
//           0.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         232: [
//           "2883",
//           7.85,
//           10400.0,
//           8.0208,
//           7.3839,
//           7.16513,
//           7.05675,
//           "7.325",
//           142.1,
//           89.0,
//           0.0,
//           -3.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         233: [
//           "1488",
//           0.88,
//           2.102,
//           0.9282,
//           0.9153,
//           0.876867,
//           0.82895,
//           "0.884",
//           13.8,
//           89.0,
//           4.0,
//           1.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         234: [
//           "0005",
//           49.6,
//           148000.0,
//           53.641,
//           49.695,
//           47.1823,
//           46.791,
//           "47.762",
//           10189.1,
//           89.0,
//           3.0,
//           6.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         235: [
//           "1597",
//           1.96,
//           10.906,
//           1.977,
//           1.9946,
//           1.94947,
//           1.8625,
//           "1.97",
//           4.9,
//           89.0,
//           0.0,
//           1.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         236: [
//           "1461",
//           0.69,
//           45.125,
//           0.717,
//           0.6573,
//           0.638667,
//           0.6155,
//           "0.649",
//           1.9119,
//           89.0,
//           -2.0,
//           -4.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         237: [
//           "1052",
//           5.22,
//           4460.0,
//           4.9022,
//           4.8695,
//           4.8334,
//           4.73275,
//           "4.879",
//           87.3,
//           89.0,
//           0.0,
//           13.0,
//           27.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         238: [
//           "0166",
//           0.072,
//           49.0,
//           0.06496,
//           0.06978,
//           0.06886,
//           0.06872,
//           "0.07",
//           6.3063,
//           89.0,
//           1.0,
//           0.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         239: [
//           "0916",
//           15.5,
//           55378.0,
//           16.4184,
//           16.7604,
//           16.4797,
//           15.5649,
//           "16.717",
//           517.7,
//           89.0,
//           -2.0,
//           -2.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         240: [
//           "0987",
//           0.28,
//           15.975,
//           0.3104,
//           0.31275,
//           0.317167,
//           0.29044,
//           "0.318",
//           7.0172,
//           89.0,
//           0.0,
//           4.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         241: [
//           "0332",
//           0.465,
//           282.0,
//           0.5291,
//           0.5201,
//           0.510433,
//           0.465975,
//           "0.52",
//           30.4,
//           89.0,
//           9.0,
//           -3.0,
//           -5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         242: [
//           "1580",
//           0.14,
//           18.033,
//           0.12004,
//           0.11817,
//           0.11611,
//           0.117103,
//           "0.119",
//           1.3641,
//           89.0,
//           -2.0,
//           0.0,
//           36.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         243: [
//           "3800",
//           2.51,
//           122000.0,
//           2.6482,
//           2.6882,
//           2.45213,
//           2.3341,
//           "2.472",
//           680.1000000000001,
//           89.0,
//           -2.0,
//           0.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         244: [
//           "2346",
//           1.0,
//           159.0,
//           1.5472,
//           1.7194,
//           2.03327,
//           1.72242,
//           "2.001",
//           5.9511,
//           89.0,
//           -4.0,
//           -4.0,
//           -5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         245: [
//           "0006",
//           51.95,
//           24383.0,
//           49.343,
//           48.3645,
//           48.0707,
//           48.2103,
//           "48.055",
//           1108.7,
//           88.0,
//           1.0,
//           3.0,
//           10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         246: [
//           "1034",
//           2.03,
//           81.44,
//           2.144,
//           2.1409,
//           2.08747,
//           1.9577,
//           "2.116",
//           32.4,
//           88.0,
//           -2.0,
//           -3.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         247: [
//           "9047",
//           1.98,
//           0.0,
//           1.762,
//           1.63274,
//           1.70273,
//           1.90237,
//           "1.692",
//           0.0,
//           88.0,
//           1.0,
//           4.0,
//           42.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         248: [
//           "8112",
//           0.385,
//           84.541,
//           0.4146,
//           0.39225,
//           0.372667,
//           0.3557,
//           "0.377",
//           0.2208,
//           88.0,
//           2.0,
//           10.0,
//           0.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         249: [
//           "8659",
//           0.2,
//           8.171,
//           0.1994,
//           0.19825,
//           0.18974,
//           0.18624,
//           "0.191",
//           1.2,
//           88.0,
//           2.0,
//           4.0,
//           9.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         250: [
//           "1082",
//           1.63,
//           218.0,
//           1.605,
//           1.592,
//           1.59807,
//           1.5812,
//           "1.611",
//           9.7218,
//           88.0,
//           3.0,
//           0.0,
//           6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         251: [
//           "0855",
//           7.91,
//           3351.0,
//           9.5052,
//           8.925,
//           8.5832,
//           7.93405,
//           "8.772",
//           129.1,
//           88.0,
//           2.0,
//           3.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         252: [
//           "0857",
//           3.79,
//           59164.0,
//           3.9712,
//           3.775,
//           3.70853,
//           3.64335,
//           "3.742",
//           799.6,
//           88.0,
//           -2.0,
//           -4.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         253: [
//           "9081",
//           6.05,
//           0.0,
//           5.7067,
//           5.6179,
//           5.56343,
//           5.57578,
//           "5.588",
//           0.0,
//           88.0,
//           0.0,
//           2.0,
//           9.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         254: [
//           "1020",
//           0.145,
//           40.347,
//           0.15896,
//           0.16374,
//           0.187287,
//           0.178595,
//           "0.182",
//           5.7643,
//           88.0,
//           1.0,
//           -3.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         255: [
//           "0829",
//           0.425,
//           47.605,
//           0.4373,
//           0.4265,
//           0.4245,
//           0.424025,
//           "0.422",
//           13.7,
//           88.0,
//           0.0,
//           4.0,
//           6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         256: [
//           "1113",
//           51.9,
//           36440.0,
//           51.21,
//           49.0855,
//           48.7723,
//           49.7853,
//           "48.658",
//           1891.0,
//           88.0,
//           3.0,
//           5.0,
//           13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         257: [
//           "0099",
//           2.37,
//           0.238,
//           2.1896,
//           2.1252,
//           2.17147,
//           2.23155,
//           "2.172",
//           11.3,
//           88.0,
//           3.0,
//           6.0,
//           6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         258: [
//           "1949",
//           0.26,
//           1.245,
//           0.2818,
//           0.25619,
//           0.251547,
//           0.261235,
//           "0.249",
//           1.04,
//           88.0,
//           1.0,
//           2.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         259: [
//           "3948",
//           6.15,
//           46.818,
//           6.6696,
//           6.2815,
//           6.197,
//           5.98065,
//           "6.294",
//           20.0,
//           88.0,
//           -3.0,
//           -2.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         260: [
//           "2388",
//           28.3,
//           67618.0,
//           29.133,
//           26.899,
//           25.9797,
//           26.1155,
//           "26.178",
//           2992.0,
//           88.0,
//           2.0,
//           7.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         261: [
//           "0267",
//           8.7,
//           17172.0,
//           8.6696,
//           8.0916,
//           8.31673,
//           8.35845,
//           "8.344",
//           2530.8,
//           88.0,
//           3.0,
//           1.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         262: [
//           "3328",
//           5.21,
//           15000.0,
//           5.2102,
//           4.9621,
//           4.8258,
//           4.8496,
//           "4.874",
//           1824.1,
//           88.0,
//           1.0,
//           4.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         263: [
//           "3081",
//           47.2,
//           474.0,
//           44.5172,
//           43.7976,
//           43.3437,
//           43.4091,
//           "43.533",
//           0.0,
//           88.0,
//           0.0,
//           2.0,
//           9.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         264: [
//           "6093",
//           4.04,
//           16.198,
//           4.1198,
//           4.1525,
//           4.16773,
//           4.15525,
//           "4.151",
//           22.6,
//           88.0,
//           2.0,
//           1.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         265: [
//           "6865",
//           33.9,
//           38919.0,
//           33.213,
//           34.838,
//           35.498,
//           33.9352,
//           "35.572",
//           152.5,
//           88.0,
//           -2.0,
//           3.0,
//           7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         266: [
//           "2099",
//           24.3,
//           5425.0,
//           22.359,
//           21.8729,
//           22.1423,
//           22.0846,
//           "22.246",
//           96.3,
//           88.0,
//           2.0,
//           -2.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         267: [
//           "3709",
//           11.4,
//           383.0,
//           12.0856,
//           11.5628,
//           11.3014,
//           11.1253,
//           "11.493",
//           80.2,
//           88.0,
//           0.0,
//           -2.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         268: [
//           "0902",
//           3.81,
//           51801.0,
//           4.3542,
//           4.1826,
//           4.05607,
//           3.74275,
//           "4.17",
//           179.0,
//           87.0,
//           -5.0,
//           -5.0,
//           -7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         269: [
//           "2337",
//           11.7,
//           475.0,
//           12.9664,
//           12.4971,
//           11.4477,
//           11.2461,
//           "11.692",
//           43.8,
//           87.0,
//           -1.0,
//           -1.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         270: [
//           "1495",
//           0.82,
//           3.958,
//           0.883,
//           1.0095,
//           0.933733,
//           0.851,
//           "0.951",
//           7.6009,
//           87.0,
//           2.0,
//           1.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         271: [
//           "0226",
//           2.6,
//           47.96,
//           2.621,
//           2.5392,
//           2.5182,
//           2.4902,
//           "2.537",
//           12.8,
//           87.0,
//           -1.0,
//           -2.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         272: [
//           "6626",
//           3.76,
//           2391.0,
//           3.933,
//           3.6941,
//           3.71347,
//           "-",
//           "3.712",
//           57.2,
//           87.0,
//           -1.0,
//           6.0,
//           2.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         273: [
//           "2221",
//           0.49,
//           60.034,
//           0.6112,
//           0.699,
//           0.704133,
//           0.6765,
//           "0.721",
//           4.6747,
//           87.0,
//           -4.0,
//           -5.0,
//           -5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         274: [
//           "2068",
//           2.17,
//           363.0,
//           2.5292,
//           2.2994,
//           2.1798,
//           2.013,
//           "2.251",
//           8.6686,
//           87.0,
//           -5.0,
//           -8.0,
//           -9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         275: [
//           "1401",
//           0.79,
//           9.58,
//           0.7366,
//           0.7283,
//           0.7406,
//           0.7457,
//           "0.746",
//           7.9,
//           87.0,
//           -1.0,
//           1.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         276: [
//           "0120",
//           1.28,
//           126.0,
//           1.387,
//           1.3004,
//           1.2442,
//           1.2426,
//           "1.252",
//           81.8,
//           87.0,
//           4.0,
//           7.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         277: [
//           "7331",
//           4.082,
//           49.271,
//           3.7714,
//           3.63434,
//           3.66816,
//           3.74145,
//           "3.674",
//           0.0,
//           87.0,
//           3.0,
//           5.0,
//           12.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         278: [
//           "0639",
//           2.41,
//           3542.0,
//           2.439,
//           2.278,
//           2.326,
//           2.28045,
//           "2.35",
//           121.7,
//           87.0,
//           -3.0,
//           -3.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         279: [
//           "3983",
//           2.37,
//           2641.0,
//           2.373,
//           2.3708,
//           2.45907,
//           2.47325,
//           "2.447",
//           41.90000000000001,
//           87.0,
//           3.0,
//           -5.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         280: [
//           "1142",
//           1.29,
//           1.56,
//           1.0094,
//           1.033,
//           1.08213,
//           1.16455,
//           "1.093",
//           1.8707,
//           87.0,
//           -6.0,
//           50.0,
//           48.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         281: [
//           "9809",
//           18.33,
//           250.0,
//           18.2688,
//           19.2955,
//           19.3593,
//           18.6366,
//           "19.364",
//           0.0,
//           87.0,
//           0.0,
//           2.0,
//           3.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         282: [
//           "1855",
//           2.36,
//           25.522,
//           2.4758,
//           2.4854,
//           2.32927,
//           2.2686,
//           "2.372",
//           6.49,
//           87.0,
//           9.0,
//           15.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         283: [
//           "6063",
//           0.109,
//           6.748,
//           0.08118,
//           0.0908,
//           0.09502,
//           0.099055,
//           "0.094",
//           2.18,
//           87.0,
//           -2.0,
//           3.0,
//           50.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         284: [
//           "1259",
//           0.075,
//           0.135,
//           0.07004,
//           0.06595,
//           0.06976,
//           0.072165,
//           "0.069",
//           1.7055,
//           87.0,
//           -1.0,
//           -1.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         285: [
//           "1046",
//           0.56,
//           2.8,
//           0.633,
//           0.5754,
//           0.523733,
//           0.496475,
//           "0.537",
//           5.0771,
//           87.0,
//           2.0,
//           -5.0,
//           -7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         286: [
//           "1205",
//           0.475,
//           432.0,
//           0.518,
//           0.5148,
//           0.520133,
//           0.486975,
//           "0.524",
//           37.3,
//           87.0,
//           -2.0,
//           -5.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         287: [
//           "0552",
//           3.91,
//           1593.0,
//           4.044,
//           3.9575,
//           3.97407,
//           3.85395,
//           "4.014",
//           93.5,
//           87.0,
//           1.0,
//           7.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         288: [
//           "0018",
//           0.67,
//           12.398,
//           0.6808,
//           0.6873,
//           0.694867,
//           0.6986,
//           "0.692",
//           16.0,
//           87.0,
//           1.0,
//           5.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         289: [
//           "3996",
//           1.11,
//           2146.0,
//           1.2994,
//           1.2242,
//           1.23307,
//           1.11265,
//           "1.241",
//           102.8,
//           87.0,
//           -3.0,
//           -2.0,
//           -7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         290: [
//           "2809",
//           143.0,
//           1480.0,
//           142.521,
//           150.505,
//           150.921,
//           145.203,
//           "151.082",
//           0.0,
//           87.0,
//           -1.0,
//           1.0,
//           3.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         291: [
//           "1589",
//           4.35,
//           641.0,
//           4.328,
//           4.282,
//           4.18027,
//           4.03605,
//           "4.243",
//           151.1,
//           86.0,
//           3.0,
//           8.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         292: [
//           "0586",
//           37.65,
//           10758.0,
//           37.728,
//           37.922,
//           36.5273,
//           35.4943,
//           "37.018",
//           687.7,
//           86.0,
//           2.0,
//           4.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         293: [
//           "3988",
//           2.95,
//           170000.0,
//           3.0184,
//           2.8878,
//           2.83673,
//           2.82865,
//           "2.857",
//           2466.8,
//           86.0,
//           2.0,
//           4.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         294: [
//           "9936",
//           4.48,
//           974.0,
//           4.9652,
//           4.9894,
//           4.99927,
//           5.02765,
//           "4.969",
//           13.4,
//           86.0,
//           -2.0,
//           -5.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         295: [
//           "1281",
//           0.18,
//           18.774,
//           0.13858,
//           0.14707,
//           0.157753,
//           0.164835,
//           "0.156",
//           2.6722,
//           86.0,
//           31.0,
//           57.0,
//           56.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         296: [
//           "9889",
//           7.63,
//           14.373,
//           7.7148,
//           7.5298,
//           "-",
//           "-",
//           "-",
//           87.5,
//           86.0,
//           2.0,
//           5.0,
//           7.0,
//           1.0,
//           "M",
//         ],
//       },
//       {
//         297: [
//           "1658",
//           5.91,
//           48370.0,
//           6.3036,
//           5.9432,
//           5.785,
//           5.6418,
//           "5.828",
//           1173.4,
//           86.0,
//           0.0,
//           0.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         298: [
//           "1170",
//           0.98,
//           2.528,
//           1.011,
//           0.9614,
//           0.93448,
//           0.93176,
//           "0.937",
//           6.6821,
//           86.0,
//           2.0,
//           -1.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         299: [
//           "2212",
//           0.032,
//           12.48,
//           0.03398,
//           0.03804,
//           0.0352533,
//           0.03211,
//           "0.037",
//           1.4043,
//           86.0,
//           -1.0,
//           1.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         300: [
//           "0941",
//           50.55,
//           205000.0,
//           52.316,
//           49.8655,
//           49.4723,
//           49.221,
//           "49.392",
//           10798.9,
//           86.0,
//           1.0,
//           4.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         301: [
//           "1410",
//           0.65,
//           57.04,
//           0.7278,
//           0.6923,
//           0.665533,
//           0.65865,
//           "0.673",
//           6.5444,
//           86.0,
//           -4.0,
//           2.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         302: [
//           "1387",
//           2.21,
//           720.0,
//           2.0766,
//           2.0131,
//           2.0032,
//           2.02205,
//           "2.013",
//           196.6,
//           86.0,
//           0.0,
//           -1.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         303: [
//           "2328",
//           7.33,
//           29465.0,
//           7.5264,
//           7.2137,
//           7.1884,
//           7.14845,
//           "7.228",
//           505.7,
//           86.0,
//           0.0,
//           0.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         304: [
//           "2341",
//           1.83,
//           359.0,
//           1.853,
//           1.7675,
//           1.8168,
//           1.89865,
//           "1.8",
//           14.1,
//           86.0,
//           -3.0,
//           -2.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         305: [
//           "0397",
//           0.121,
//           7.319,
//           0.13488,
//           0.15282,
//           0.1467,
//           0.13488,
//           "0.149",
//           3.368,
//           86.0,
//           0.0,
//           4.0,
//           6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         306: [
//           "0390",
//           4.26,
//           8034.0,
//           4.6304,
//           4.2581,
//           4.1594,
//           4.1033,
//           "4.225",
//           179.2,
//           86.0,
//           1.0,
//           1.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         307: [
//           "1751",
//           0.235,
//           3.862,
//           0.24172,
//           0.25315,
//           0.265433,
//           0.257965,
//           "0.263",
//           1.895,
//           86.0,
//           -3.0,
//           -2.0,
//           10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         308: [
//           "1717",
//           10.02,
//           4000.0,
//           9.8278,
//           9.5151,
//           8.8816,
//           9.2081,
//           "9.045",
//           181.2,
//           86.0,
//           2.0,
//           3.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         309: [
//           "0417",
//           1.09,
//           54.5,
//           1.147,
//           1.1273,
//           1.1096,
//           1.11585,
//           "1.105",
//           2.716,
//           86.0,
//           4.0,
//           -1.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         310: [
//           "1738",
//           1.16,
//           0.877,
//           1.142,
//           1.1883,
//           1.1392,
//           1.10475,
//           "1.152",
//           16.0,
//           86.0,
//           10.0,
//           5.0,
//           13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         311: [
//           "0108",
//           1.04,
//           116.0,
//           1.0598,
//           1.0492,
//           1.04967,
//           1.03295,
//           "1.043",
//           33.2,
//           86.0,
//           1.0,
//           3.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         312: [
//           "8007",
//           0.265,
//           99.307,
//           0.27582,
//           0.29974,
//           0.294567,
//           0.272327,
//           "0.302",
//           1.208,
//           86.0,
//           -3.0,
//           -5.0,
//           -5.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         313: [
//           "8098",
//           0.077,
//           5.544,
//           0.07246,
//           0.07302,
//           0.0755667,
//           0.07751,
//           "0.075",
//           1.694,
//           86.0,
//           3.0,
//           4.0,
//           58.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         314: [
//           "8041",
//           0.37,
//           15.33,
//           0.3923,
//           0.406,
//           0.385433,
//           0.35317,
//           "0.397",
//           2.922,
//           86.0,
//           6.0,
//           12.0,
//           1.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         315: [
//           "0045",
//           7.85,
//           228.0,
//           7.9016,
//           7.5143,
//           7.38547,
//           7.5122,
//           "7.451",
//           129.4,
//           85.0,
//           0.0,
//           1.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         316: [
//           "0107",
//           1.91,
//           833.0,
//           2.0792,
//           1.9545,
//           1.90527,
//           1.87705,
//           "1.919",
//           17.1,
//           85.0,
//           -2.0,
//           -3.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         317: [
//           "8172",
//           0.229,
//           520.0,
//           0.21154,
//           0.25131,
//           0.250793,
//           0.267865,
//           "0.244",
//           9.6389,
//           85.0,
//           31.0,
//           31.0,
//           45.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         318: [
//           "0004",
//           25.05,
//           2532.0,
//           26.452,
//           26.3345,
//           26.1647,
//           26.6478,
//           "26.293",
//           765.5,
//           85.0,
//           3.0,
//           -5.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         319: [
//           "2669",
//           7.04,
//           6659.0,
//           8.5378,
//           8.0105,
//           7.57227,
//           7.65855,
//           "7.613",
//           231.3,
//           85.0,
//           -2.0,
//           -5.0,
//           -5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         320: [
//           "1809",
//           7.54,
//           158.0,
//           7.2138,
//           7.037,
//           7.1022,
//           7.4688,
//           "7.025",
//           47.9,
//           85.0,
//           7.0,
//           28.0,
//           16.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         321: [
//           "0607",
//           0.127,
//           362.0,
//           0.13184,
//           0.12002,
//           0.115527,
//           0.121615,
//           "0.118",
//           25.0,
//           85.0,
//           1.0,
//           5.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         322: [
//           "8479",
//           0.65,
//           64.164,
//           0.6542,
//           0.6521,
//           0.6378,
//           0.6306,
//           "0.638",
//           6.045,
//           85.0,
//           1.0,
//           11.0,
//           4.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         323: [
//           "0197",
//           0.115,
//           65.489,
//           0.12568,
//           0.13103,
//           0.11856,
//           0.11279,
//           "0.121",
//           2.4166,
//           85.0,
//           3.0,
//           1.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         324: [
//           "2270",
//           1.1,
//           1.76,
//           1.0994,
//           "-",
//           "-",
//           "-",
//           "-",
//           6.7466,
//           85.0,
//           2.0,
//           3.0,
//           6.0,
//           1.0,
//           "M",
//         ],
//       },
//       {
//         325: [
//           "2002",
//           2.25,
//           1359.0,
//           2.5706,
//           2.2883,
//           2.15393,
//           2.0732,
//           "2.201",
//           22.9,
//           85.0,
//           -3.0,
//           -9.0,
//           -8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         326: [
//           "0036",
//           0.139,
//           6.66,
//           0.13792,
//           0.13068,
//           0.131967,
//           0.135955,
//           "0.133",
//           1.5138,
//           85.0,
//           2.0,
//           0.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         327: [
//           "1703",
//           0.75,
//           79.695,
//           0.8084,
//           0.8472,
//           0.824867,
//           0.79985,
//           "0.83",
//           8.625,
//           85.0,
//           5.0,
//           -2.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         328: [
//           "1292",
//           2.3,
//           1.127,
//           2.1494,
//           1.9908,
//           2.0706,
//           2.2488,
//           "2.045",
//           1.265,
//           85.0,
//           -2.0,
//           -2.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         329: [
//           "0091",
//           0.216,
//           97.288,
//           0.2081,
//           0.2109,
//           0.217727,
//           0.219575,
//           "0.22",
//           9.621,
//           85.0,
//           14.0,
//           22.0,
//           31.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         330: [
//           "1631",
//           0.72,
//           7.28,
//           0.7298,
//           0.7384,
//           0.721267,
//           0.6904,
//           "0.73",
//           1.8432,
//           85.0,
//           -3.0,
//           -4.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         331: [
//           "1901",
//           0.96,
//           191.0,
//           1.0366,
//           1.0049,
//           0.961,
//           0.92425,
//           "0.966",
//           5.76,
//           85.0,
//           6.0,
//           -2.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         332: [
//           "0532",
//           0.95,
//           3.83,
//           1.015,
//           1.0139,
//           1.00027,
//           1.00705,
//           "1.007",
//           6.934,
//           85.0,
//           0.0,
//           -3.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         333: [
//           "0320",
//           0.73,
//           0.716,
//           0.8666,
//           0.8697,
//           0.839933,
//           0.8372,
//           "0.841",
//           6.1505,
//           85.0,
//           -2.0,
//           -1.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         334: [
//           "0856",
//           7.3,
//           813.0000000000001,
//           7.5706,
//           7.3865,
//           7.2138,
//           7.05995,
//           "7.338",
//           106.2,
//           85.0,
//           -3.0,
//           -1.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         335: [
//           "0746",
//           6.56,
//           833.0,
//           7.3674,
//           7.7945,
//           7.8232,
//           7.1541,
//           "7.885",
//           54.1,
//           85.0,
//           -4.0,
//           -5.0,
//           -7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         336: [
//           "3079",
//           140.2,
//           12.093,
//           139.795,
//           138.566,
//           137.354,
//           136.48,
//           "137.735",
//           0.0,
//           85.0,
//           3.0,
//           4.0,
//           7.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         337: [
//           "0034",
//           9.9,
//           142.0,
//           10.3022,
//           10.1575,
//           9.78933,
//           9.65995,
//           "9.837",
//           116.4,
//           85.0,
//           1.0,
//           3.0,
//           6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         338: [
//           "0675",
//           0.89,
//           5.518,
//           0.9176,
//           0.9048,
//           0.903467,
//           0.88685,
//           "0.91",
//           2.3763,
//           84.0,
//           4.0,
//           6.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         339: [
//           "0002",
//           77.95,
//           30599.0,
//           78.727,
//           77.455,
//           77.1243,
//           77.418,
//           "77.017",
//           1969.3,
//           84.0,
//           4.0,
//           5.0,
//           8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         340: [
//           "0293",
//           6.89,
//           4927.0,
//           6.7228,
//           6.777,
//           6.71773,
//           6.704,
//           "6.767",
//           443.5,
//           84.0,
//           1.0,
//           15.0,
//           8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         341: [
//           "6090",
//           1.97,
//           0.59,
//           1.9282,
//           1.9099,
//           1.9082,
//           1.9121,
//           "1.906",
//           16.5,
//           84.0,
//           5.0,
//           3.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         342: [
//           "9810",
//           9.51,
//           0.0,
//           9.5183,
//           9.41295,
//           9.25017,
//           9.18657,
//           "9.32",
//           0.0,
//           84.0,
//           2.0,
//           4.0,
//           4.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         343: [
//           "1857",
//           1.7,
//           237.0,
//           1.8324,
//           1.7851,
//           1.7606,
//           1.68735,
//           "1.781",
//           48.6,
//           84.0,
//           -3.0,
//           -2.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         344: [
//           "1456",
//           3.98,
//           859.0,
//           4.3806,
//           4.1633,
//           3.9992,
//           3.9225,
//           "4.022",
//           17.6,
//           84.0,
//           -2.0,
//           -3.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         345: [
//           "0379",
//           0.148,
//           0.149,
//           0.14674,
//           0.1346,
//           0.13004,
//           0.135475,
//           "0.132",
//           2.4972,
//           84.0,
//           0.0,
//           -6.0,
//           -5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         346: [
//           "0428",
//           0.3,
//           21.858,
//           0.3118,
//           0.3028,
//           0.2967,
//           0.29486,
//           "0.298",
//           1.295,
//           84.0,
//           -3.0,
//           -4.0,
//           -1.0,
//           0.0,
//           "-",
//         ],
//       },
//       {
//         347: [
//           "6889",
//           7.23,
//           282.0,
//           7.0806,
//           7.0861,
//           7.24753,
//           7.24485,
//           "7.212",
//           52.6,
//           84.0,
//           8.0,
//           9.0,
//           19.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         348: [
//           "2023",
//           1.18,
//           1.908,
//           1.1808,
//           1.1944,
//           1.20427,
//           1.1939,
//           "1.206",
//           5.8032,
//           84.0,
//           1.0,
//           4.0,
//           6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         349: [
//           "2817",
//           129.9,
//           4.431,
//           130.69,
//           129.372,
//           128.146,
//           126.964,
//           "128.55",
//           0.0,
//           84.0,
//           2.0,
//           4.0,
//           7.0,
//           2.0,
//           "E",
//         ],
//       },
//       {
//         350: [
//           "3833",
//           1.31,
//           1468.0,
//           1.3968,
//           1.3561,
//           1.47053,
//           1.4566,
//           "1.441",
//           9.9429,
//           84.0,
//           -4.0,
//           -11.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         351: [
//           "1898",
//           4.59,
//           18722.0,
//           4.8906,
//           4.7347,
//           4.97907,
//           4.9138,
//           "4.986",
//           188.4,
//           84.0,
//           -4.0,
//           -7.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         352: [
//           "3122",
//           194.25,
//           0.0,
//           193.756,
//           192.803,
//           191.375,
//           190.497,
//           "191.823",
//           0.0,
//           84.0,
//           2.0,
//           4.0,
//           7.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         353: [
//           "0008",
//           4.23,
//           6203.0,
//           4.1546,
//           4.073,
//           4.067,
//           4.08175,
//           "4.072",
//           326.9,
//           84.0,
//           1.0,
//           2.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         354: [
//           "2813",
//           131.9,
//           0.0,
//           131.8,
//           130.722,
//           129.759,
//           129.032,
//           "130.043",
//           0.0,
//           84.0,
//           2.0,
//           4.0,
//           6.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         355: [
//           "2810",
//           74.12,
//           1.111,
//           74.2588,
//           73.4088,
//           72.1045,
//           71.5538,
//           "72.688",
//           0.0,
//           84.0,
//           2.0,
//           3.0,
//           3.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         356: [
//           "0579",
//           2.08,
//           1778.0,
//           2.3492,
//           2.3063,
//           2.31367,
//           2.18335,
//           "2.344",
//           58.8,
//           84.0,
//           -2.0,
//           -2.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         357: [
//           "2888",
//           49.6,
//           28904.0,
//           54.081,
//           50.6925,
//           49.899,
//           50.0588,
//           "50.133",
//           1514.6,
//           84.0,
//           3.0,
//           14.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         358: [
//           "0420",
//           1.29,
//           149.0,
//           1.3656,
//           1.3573,
//           1.351,
//           1.35395,
//           "1.36",
//           15.8,
//           84.0,
//           -2.0,
//           -1.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         359: [
//           "1818",
//           7.22,
//           11374.0,
//           6.7358,
//           6.6796,
//           6.51733,
//           6.797,
//           "6.489",
//           188.4,
//           84.0,
//           -2.0,
//           -2.0,
//           30.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         360: [
//           "0548",
//           7.58,
//           1866.0,
//           7.8364,
//           7.6265,
//           7.57633,
//           7.5636,
//           "7.615",
//           56.6,
//           84.0,
//           1.0,
//           1.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         361: [
//           "1127",
//           0.84,
//           4.032,
//           0.8954,
//           0.8463,
//           0.835,
//           0.83915,
//           "0.836",
//           6.468,
//           83.0,
//           0.0,
//           0.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         362: [
//           "9813",
//           16.85,
//           0.0,
//           16.8994,
//           16.7692,
//           16.6565,
//           "-",
//           "16.688",
//           0.0,
//           83.0,
//           3.0,
//           4.0,
//           7.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         363: [
//           "0720",
//           0.177,
//           125.0,
//           0.17444,
//           0.1697,
//           0.170093,
//           0.17524,
//           "0.17",
//           9.3677,
//           83.0,
//           7.0,
//           3.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         364: [
//           "1785",
//           2.19,
//           1.316,
//           2.1766,
//           2.1646,
//           2.18013,
//           2.1817,
//           "2.18",
//           9.9886,
//           83.0,
//           3.0,
//           13.0,
//           14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         365: [
//           "2708",
//           3.25,
//           541.0,
//           3.0424,
//           2.8871,
//           2.96967,
//           3.1505,
//           "2.95",
//           18.8,
//           83.0,
//           0.0,
//           -5.0,
//           26.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         366: [
//           "1667",
//           0.3,
//           26.565,
//           0.2995,
//           0.3009,
//           0.2993,
//           0.29331,
//           "0.301",
//           2.88,
//           83.0,
//           11.0,
//           11.0,
//           19.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         367: [
//           "3080",
//           134.85,
//           0.0,
//           134.608,
//           134.535,
//           133.841,
//           133.246,
//           "134.028",
//           0.0,
//           83.0,
//           3.0,
//           5.0,
//           8.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         368: [
//           "3199",
//           127.8,
//           154.0,
//           127.606,
//           126.696,
//           125.913,
//           125.39,
//           "126.162",
//           0.0,
//           83.0,
//           2.0,
//           3.0,
//           7.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         369: [
//           "1616",
//           0.16,
//           0.16,
//           0.1643,
//           0.16498,
//           0.15634,
//           0.15496,
//           "0.159",
//           3.4425,
//           83.0,
//           7.0,
//           3.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         370: [
//           "1615",
//           0.26,
//           11.575,
//           0.26078,
//           0.27379,
//           0.280627,
//           0.27451,
//           "0.278",
//           1.56,
//           83.0,
//           4.0,
//           5.0,
//           26.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         371: [
//           "1598",
//           0.495,
//           103.0,
//           0.5014,
//           0.48145,
//           0.490467,
//           0.5127,
//           "0.492",
//           5.7777,
//           83.0,
//           3.0,
//           14.0,
//           38.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         372: [
//           "0869",
//           0.495,
//           86.892,
//           0.4927,
//           0.50085,
//           0.519333,
//           0.5636,
//           "0.509",
//           5.841,
//           83.0,
//           8.0,
//           6.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         373: [
//           "3134",
//           10.1,
//           264.0,
//           10.1525,
//           11.0003,
//           11.0904,
//           "-",
//           "11.067",
//           0.0,
//           83.0,
//           -1.0,
//           -1.0,
//           35.0,
//           2.0,
//           "E",
//         ],
//       },
//       {
//         374: [
//           "0308",
//           1.36,
//           1389.0,
//           1.5878,
//           1.4688,
//           1.3542,
//           1.323,
//           "1.382",
//           75.2,
//           83.0,
//           3.0,
//           3.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         375: [
//           "3315",
//           1.81,
//           375.0,
//           2.1108,
//           1.9156,
//           1.8168,
//           1.79915,
//           "1.844",
//           14.9,
//           83.0,
//           -5.0,
//           -4.0,
//           -11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         376: [
//           "7322",
//           6.43,
//           242.0,
//           6.1408,
//           6.0494,
//           6.12273,
//           6.22328,
//           "6.115",
//           0.0,
//           83.0,
//           4.0,
//           7.0,
//           16.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         377: [
//           "2829",
//           63.22,
//           15.81,
//           62.99,
//           "-",
//           "-",
//           "-",
//           "-",
//           0.0,
//           83.0,
//           2.0,
//           4.0,
//           7.0,
//           1.0,
//           "E",
//         ],
//       },
//       {
//         378: [
//           "0945",
//           155.6,
//           413.0,
//           157.654,
//           154.041,
//           153.408,
//           153.314,
//           "153.623",
//           3013.0,
//           83.0,
//           7.0,
//           9.0,
//           12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         379: [
//           "0517",
//           2.51,
//           202.0,
//           2.491,
//           2.4638,
//           2.49507,
//           2.51365,
//           "2.489",
//           38.4,
//           83.0,
//           5.0,
//           15.0,
//           19.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         380: [
//           "9817",
//           16.6,
//           31.267,
//           16.757,
//           16.593,
//           16.4413,
//           16.304,
//           "16.487",
//           0.0,
//           83.0,
//           2.0,
//           4.0,
//           6.0,
//           2.0,
//           "E",
//         ],
//       },
//       {
//         381: [
//           "1337",
//           2.22,
//           3151.0,
//           2.3642,
//           2.3746,
//           2.16913,
//           2.15475,
//           "2.188",
//           194.4,
//           83.0,
//           1.0,
//           9.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         382: [
//           "0976",
//           0.71,
//           75.366,
//           0.8318,
//           0.9809,
//           1.02333,
//           0.957375,
//           "1.035",
//           11.3,
//           83.0,
//           -4.0,
//           -5.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         383: [
//           "1288",
//           2.84,
//           53592.0,
//           2.923,
//           2.7894,
//           2.742,
//           2.74775,
//           "2.764",
//           872.9,
//           83.0,
//           2.0,
//           6.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         384: [
//           "9993",
//           4.15,
//           902.0,
//           4.3128,
//           4.1068,
//           4.17593,
//           4.22665,
//           "4.173",
//           167.80000000000004,
//           83.0,
//           -2.0,
//           0.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         385: [
//           "3011",
//           8066.95,
//           0.0,
//           8037.93,
//           8030.23,
//           8023.33,
//           8014.25,
//           "8025.863",
//           0.0,
//           82.0,
//           3.0,
//           5.0,
//           10.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         386: [
//           "0924",
//           0.55,
//           0.88,
//           0.6246,
//           0.637,
//           0.648433,
//           0.5961,
//           "0.643",
//           5.5,
//           82.0,
//           4.0,
//           4.0,
//           7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         387: [
//           "0640",
//           0.66,
//           7.96,
//           0.6802,
//           0.6857,
//           0.683333,
//           0.6796,
//           "0.68",
//           3.7181,
//           82.0,
//           3.0,
//           9.0,
//           7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         388: [
//           "3125",
//           62.36,
//           0.0,
//           62.2612,
//           "-",
//           "-",
//           "-",
//           "-",
//           0.0,
//           82.0,
//           2.0,
//           3.0,
//           7.0,
//           1.0,
//           "E",
//         ],
//       },
//       {
//         389: [
//           "9125",
//           7.975,
//           0.0,
//           7.9806,
//           "-",
//           "-",
//           "-",
//           "-",
//           0.0,
//           82.0,
//           2.0,
//           3.0,
//           8.0,
//           1.0,
//           "E",
//         ],
//       },
//       {
//         390: [
//           "3077",
//           3918.2,
//           0.0,
//           3903.49,
//           3899.78,
//           3897.45,
//           3894.46,
//           "3898.582",
//           0.0,
//           82.0,
//           3.0,
//           5.0,
//           10.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         391: [
//           "9829",
//           8.08,
//           0.0,
//           8.0672,
//           "-",
//           "-",
//           "-",
//           "-",
//           0.0,
//           82.0,
//           2.0,
//           3.0,
//           7.0,
//           1.0,
//           "E",
//         ],
//       },
//       {
//         392: [
//           "1872",
//           0.166,
//           13.572,
//           0.1809,
//           0.18655,
//           0.181813,
//           0.17535,
//           "0.183",
//           1.494,
//           82.0,
//           -1.0,
//           -2.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         393: [
//           "1787",
//           14.34,
//           12227.0,
//           14.0244,
//           13.9962,
//           13.8705,
//           13.9932,
//           "13.919",
//           123.1,
//           82.0,
//           -2.0,
//           -4.0,
//           8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         394: [
//           "1713",
//           1.72,
//           4.166,
//           1.786,
//           1.7621,
//           1.7684,
//           1.75955,
//           "1.767",
//           4.6233,
//           82.0,
//           -1.0,
//           -1.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         395: [
//           "0142",
//           2.84,
//           1403.0,
//           3.0458,
//           2.9999,
//           2.93933,
//           2.87975,
//           "2.964",
//           121.2,
//           82.0,
//           -1.0,
//           -1.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         396: [
//           "1596",
//           4.26,
//           48.37,
//           4.5128,
//           4.5196,
//           4.43853,
//           4.3463,
//           "4.449",
//           9.5619,
//           82.0,
//           -2.0,
//           0.0,
//           10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         397: [
//           "0088",
//           5.0,
//           58.612,
//           4.9176,
//           4.8662,
//           4.95553,
//           4.9691,
//           "4.917",
//           30.8,
//           82.0,
//           8.0,
//           14.0,
//           13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         398: [
//           "1906",
//           0.44,
//           15.41,
//           0.4327,
//           0.43015,
//           0.443267,
//           0.44935,
//           "0.436",
//           5.28,
//           82.0,
//           13.0,
//           26.0,
//           26.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         399: [
//           "1109",
//           33.55,
//           76577.0,
//           37.146,
//           34.9655,
//           33.2063,
//           33.0325,
//           "33.947",
//           2392.4,
//           82.0,
//           -1.0,
//           -5.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         400: [
//           "2638",
//           7.79,
//           3255.0,
//           7.7886,
//           7.7399,
//           7.74007,
//           7.7706,
//           "7.731",
//           688.3,
//           82.0,
//           2.0,
//           5.0,
//           12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         401: [
//           "1929",
//           13.9,
//           27411.0,
//           14.5548,
//           14.9322,
//           15.1349,
//           15.3646,
//           "15.169",
//           1390.0,
//           82.0,
//           -3.0,
//           -4.0,
//           -7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         402: [
//           "1675",
//           12.76,
//           801.0,
//           13.8492,
//           13.3786,
//           13.1697,
//           13.0139,
//           "13.266",
//           118.3,
//           82.0,
//           -2.0,
//           2.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         403: [
//           "1931",
//           2.92,
//           136.0,
//           3.2256,
//           3.1687,
//           3.0704,
//           3.1044,
//           "3.094",
//           39.5,
//           82.0,
//           -2.0,
//           -2.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         404: [
//           "0389",
//           0.09,
//           37.789,
//           0.08826,
//           0.09488,
//           0.09332,
//           0.094095,
//           "0.096",
//           2.3764,
//           82.0,
//           0.0,
//           19.0,
//           32.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         405: [
//           "8081",
//           0.096,
//           3.115,
//           0.0995,
//           0.10975,
//           0.11802,
//           0.108155,
//           "0.121",
//           5.1227,
//           82.0,
//           8.0,
//           2.0,
//           15.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         406: [
//           "1500",
//           0.243,
//           4.338,
//           0.2758,
//           0.26848,
//           0.25146,
//           0.24221,
//           "0.255",
//           2.0169,
//           82.0,
//           -2.0,
//           1.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         407: [
//           "0182",
//           0.68,
//           3051.0,
//           0.7438,
//           0.7687,
//           0.780533,
//           0.7279,
//           "0.783",
//           60.9,
//           82.0,
//           -3.0,
//           -6.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         408: [
//           "9096",
//           102.6,
//           0.0,
//           102.595,
//           102.573,
//           102.564,
//           102.547,
//           "102.568",
//           0.0,
//           81.0,
//           3.0,
//           5.0,
//           10.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         409: [
//           "3096",
//           803.45,
//           0.0,
//           800.781,
//           800.148,
//           799.641,
//           799.088,
//           "799.862",
//           0.0,
//           81.0,
//           2.0,
//           4.0,
//           9.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         410: [
//           "8269",
//           0.159,
//           0.477,
//           0.19144,
//           0.18646,
//           0.165893,
//           0.165145,
//           "0.171",
//           1.1432,
//           81.0,
//           1.0,
//           7.0,
//           1.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         411: [
//           "1669",
//           0.54,
//           0.54,
//           0.5544,
//           0.54765,
//           0.5681,
//           0.589475,
//           "0.563",
//           2.16,
//           81.0,
//           0.0,
//           11.0,
//           20.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         412: [
//           "8103",
//           2.0,
//           516.0,
//           1.5032,
//           1.6922,
//           1.9048,
//           2.03545,
//           "1.903",
//           2.1574,
//           81.0,
//           -2.0,
//           75.0,
//           78.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         413: [
//           "1690",
//           0.063,
//           12.431,
//           0.06326,
//           0.06308,
//           0.0648933,
//           0.0655,
//           "0.065",
//           0.9639,
//           81.0,
//           -1.0,
//           15.0,
//           20.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         414: [
//           "1991",
//           0.175,
//           0.175,
//           0.19232,
//           0.18776,
//           0.17476,
//           0.170555,
//           "0.177",
//           2.2868,
//           81.0,
//           -3.0,
//           -3.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         415: [
//           "3143",
//           7.105,
//           109.0,
//           7.4777,
//           7.1368,
//           7.07303,
//           7.12435,
//           "7.09",
//           0.0,
//           81.0,
//           3.0,
//           9.0,
//           0.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         416: [
//           "0257",
//           5.38,
//           12034.0,
//           5.8758,
//           5.6922,
//           5.678,
//           5.35195,
//           "5.718",
//           330.4,
//           81.0,
//           -2.0,
//           -2.0,
//           -5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         417: [
//           "0205",
//           0.32,
//           2.18,
//           0.4565,
//           0.52565,
//           0.5925,
//           0.567355,
//           "0.576",
//           1.9716,
//           81.0,
//           0.0,
//           -3.0,
//           -8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         418: [
//           "1816",
//           2.0,
//           8894.0,
//           2.2358,
//           2.2095,
//           2.137,
//           2.02885,
//           "2.176",
//           223.2,
//           81.0,
//           -4.0,
//           -6.0,
//           -8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         419: [
//           "8271",
//           0.1,
//           16.694,
//           0.10972,
//           0.10815,
//           0.104307,
//           0.10293,
//           "0.105",
//           1.5082,
//           81.0,
//           1.0,
//           8.0,
//           7.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         420: [
//           "2157",
//           7.14,
//           94.147,
//           "-",
//           "-",
//           "-",
//           "-",
//           "-",
//           114.5,
//           81.0,
//           5.0,
//           7.0,
//           11.0,
//           1.0,
//           "M",
//         ],
//       },
//       {
//         421: [
//           "3071",
//           1002.25,
//           0.0,
//           1001.95,
//           1001.75,
//           1001.62,
//           1001.53,
//           "1001.68",
//           0.0,
//           81.0,
//           3.0,
//           5.0,
//           10.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         422: [
//           "2183",
//           4.08,
//           30.694,
//           4.2844,
//           4.1241,
//           4.22893,
//           4.3418,
//           "4.184",
//           20.0,
//           81.0,
//           0.0,
//           0.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         423: [
//           "1503",
//           2.68,
//           381.0,
//           2.7366,
//           2.6952,
//           2.75113,
//           2.7573,
//           "2.736",
//           30.2,
//           81.0,
//           2.0,
//           3.0,
//           9.0,
//           0.0,
//           "R",
//         ],
//       },
//       {
//         424: [
//           "9078",
//           507.3,
//           0.0,
//           507.029,
//           506.866,
//           506.801,
//           506.772,
//           "506.838",
//           0.0,
//           81.0,
//           3.0,
//           5.0,
//           9.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         425: [
//           "2899",
//           10.74,
//           62652.0,
//           10.7076,
//           10.5369,
//           10.5935,
//           10.6725,
//           "10.545",
//           616.1,
//           81.0,
//           -3.0,
//           -8.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         426: [
//           "9077",
//           500.55,
//           0.0,
//           500.253,
//           500.118,
//           500.087,
//           500.125,
//           "500.116",
//           0.0,
//           81.0,
//           3.0,
//           5.0,
//           10.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         427: [
//           "3053",
//           1034.1,
//           0.0,
//           1033.8,
//           1033.7,
//           1033.85,
//           1033.95,
//           "1033.833",
//           0.0,
//           81.0,
//           3.0,
//           5.0,
//           10.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         428: [
//           "0289",
//           17.7,
//           17.72,
//           17.976,
//           17.952,
//           18.0037,
//           17.9122,
//           "17.954",
//           51.5,
//           81.0,
//           1.0,
//           8.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         429: [
//           "9011",
//           1030.7,
//           0.0,
//           1030.43,
//           1029.94,
//           1029.59,
//           1029.26,
//           "1029.738",
//           0.0,
//           81.0,
//           3.0,
//           5.0,
//           9.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         430: [
//           "0311",
//           0.395,
//           0.197,
//           0.4325,
//           0.42405,
//           0.418133,
//           0.41205,
//           "0.419",
//           4.0847,
//           81.0,
//           2.0,
//           7.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         431: [
//           "6868",
//           5.4,
//           6.5040000000000004,
//           5.416,
//           5.4639,
//           5.3744,
//           5.3729,
//           "5.387",
//           59.1,
//           80.0,
//           5.0,
//           7.0,
//           7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         432: [
//           "1883",
//           2.66,
//           3091.0,
//           2.7492,
//           2.7117,
//           2.69053,
//           2.6589,
//           "2.703",
//           97.9,
//           80.0,
//           -1.0,
//           -1.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         433: [
//           "0711",
//           0.59,
//           37.582,
//           0.602,
//           0.6026,
//           0.600333,
//           0.5983,
//           "0.6",
//           10.6,
//           80.0,
//           5.0,
//           7.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         434: [
//           "1087",
//           1.75,
//           1.776,
//           1.7774,
//           1.8442,
//           1.78907,
//           1.72995,
//           "1.821",
//           1.4742,
//           80.0,
//           -4.0,
//           8.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         435: [
//           "1180",
//           0.94,
//           1.682,
//           1.0322,
//           0.9945,
//           0.9772,
//           0.99045,
//           "0.972",
//           9.8905,
//           80.0,
//           4.0,
//           3.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         436: [
//           "8623",
//           0.076,
//           2.002,
//           0.07986,
//           0.08178,
//           0.0830667,
//           0.08105,
//           "0.083",
//           0.6992,
//           80.0,
//           6.0,
//           21.0,
//           20.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         437: [
//           "2393",
//           1.13,
//           3.965,
//           1.1106,
//           1.1185,
//           1.10727,
//           1.11375,
//           "1.106",
//           26.3,
//           80.0,
//           9.0,
//           13.0,
//           28.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         438: [
//           "1258",
//           3.11,
//           8242.0,
//           3.4588,
//           3.2478,
//           3.4616,
//           3.57555,
//           "3.429",
//           116.2,
//           80.0,
//           -5.0,
//           -12.0,
//           -10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         439: [
//           "2273",
//           28.85,
//           1030.0,
//           37.861,
//           "-",
//           "-",
//           "-",
//           "-",
//           66.4,
//           80.0,
//           -7.0,
//           -14.0,
//           -14.0,
//           1.0,
//           "M",
//         ],
//       },
//       {
//         440: [
//           "2260",
//           1.22,
//           9.272,
//           "-",
//           "-",
//           "-",
//           "-",
//           "-",
//           5.8979,
//           80.0,
//           5.0,
//           7.0,
//           12.0,
//           1.0,
//           "M",
//         ],
//       },
//       {
//         441: [
//           "1417",
//           1.76,
//           5.306,
//           1.6494,
//           1.7045,
//           1.77547,
//           1.80765,
//           "1.75",
//           7.128,
//           80.0,
//           18.0,
//           40.0,
//           52.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         442: [
//           "2225",
//           2.38,
//           127.0,
//           2.4204,
//           2.5065,
//           2.62053,
//           2.5832,
//           "2.585",
//           29.2,
//           80.0,
//           7.0,
//           -4.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         443: [
//           "7232",
//           8.7,
//           3.655,
//           9.3928,
//           9.1728,
//           8.92507,
//           "-",
//           "8.978",
//           0.0,
//           80.0,
//           6.0,
//           12.0,
//           -2.0,
//           2.0,
//           "E",
//         ],
//       },
//       {
//         444: [
//           "6136",
//           0.74,
//           45.951,
//           0.7842,
//           0.7711,
//           0.791333,
//           0.76335,
//           "0.803",
//           15.8,
//           80.0,
//           4.0,
//           -1.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         445: [
//           "3087",
//           331.0,
//           160.0,
//           350.832,
//           354.811,
//           350.001,
//           345.764,
//           "350.403",
//           0.0,
//           80.0,
//           0.0,
//           -1.0,
//           2.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         446: [
//           "2102",
//           0.275,
//           13.62,
//           0.2851,
//           0.30115,
//           0.2961,
//           0.2903,
//           "0.296",
//           2.75,
//           80.0,
//           -3.0,
//           12.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         447: [
//           "0001",
//           54.3,
//           56140.0,
//           55.274,
//           53.3275,
//           53.8177,
//           55.1625,
//           "53.475",
//           2082.1,
//           80.0,
//           5.0,
//           14.0,
//           14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         448: [
//           "0506",
//           2.91,
//           670.0,
//           3.2308,
//           3.0832,
//           2.9704,
//           2.9374,
//           "3.008",
//           81.30000000000003,
//           80.0,
//           -6.0,
//           -9.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         449: [
//           "0519",
//           0.118,
//           0.224,
//           0.10914,
//           0.11151,
//           0.109633,
//           0.113,
//           "0.111",
//           2.956,
//           80.0,
//           8.0,
//           39.0,
//           48.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         450: [
//           "0329",
//           3.4,
//           275.0,
//           3.297,
//           3.3452,
//           3.50347,
//           3.56185,
//           "3.497",
//           50.9,
//           80.0,
//           4.0,
//           0.0,
//           -7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         451: [
//           "0391",
//           0.094,
//           0.196,
//           0.0968,
//           0.09586,
//           0.0959467,
//           0.096185,
//           "0.096",
//           5.5683,
//           80.0,
//           1.0,
//           9.0,
//           10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         452: [
//           "0576",
//           6.8,
//           6762.0,
//           6.917,
//           6.9538,
//           6.83407,
//           6.8103,
//           "6.841",
//           97.5,
//           80.0,
//           -1.0,
//           11.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         453: [
//           "0144",
//           12.86,
//           14094.0,
//           14.8204,
//           13.9254,
//           13.4737,
//           13.0125,
//           "13.725",
//           486.8,
//           80.0,
//           -3.0,
//           -1.0,
//           -10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         454: [
//           "1412",
//           1.23,
//           1.23,
//           1.2366,
//           1.2387,
//           1.24787,
//           1.26645,
//           "1.244",
//           6.5436,
//           79.0,
//           4.0,
//           5.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         455: [
//           "1800",
//           4.07,
//           13144.0,
//           4.5748,
//           4.3685,
//           4.29707,
//           4.1636,
//           "4.336",
//           179.8,
//           79.0,
//           -3.0,
//           -6.0,
//           -7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         456: [
//           "9906",
//           2.11,
//           33.182,
//           2.317,
//           2.2863,
//           2.23067,
//           2.1675,
//           "2.253",
//           12.6,
//           79.0,
//           -1.0,
//           6.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         457: [
//           "8280",
//           0.102,
//           2.055,
//           0.11632,
//           0.11519,
//           0.115313,
//           0.113935,
//           "0.116",
//           0.6429,
//           79.0,
//           19.0,
//           14.0,
//           13.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         458: [
//           "1979",
//           2.0,
//           201.0,
//           2.0546,
//           1.9801,
//           2.07793,
//           2.09365,
//           "2.051",
//           20.0,
//           79.0,
//           6.0,
//           4.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         459: [
//           "1746",
//           0.53,
//           119.0,
//           0.5073,
//           0.52475,
//           0.5426,
//           0.57195,
//           "0.532",
//           5.3,
//           79.0,
//           -1.0,
//           15.0,
//           18.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         460: [
//           "3698",
//           2.56,
//           18.797,
//           2.579,
//           2.5908,
//           2.5842,
//           2.57935,
//           "2.579",
//           89.0,
//           79.0,
//           3.0,
//           2.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         461: [
//           "1398",
//           4.6,
//           155000.0,
//           4.67,
//           4.4809,
//           4.4368,
//           4.5062,
//           "4.454",
//           3992.5,
//           79.0,
//           8.0,
//           11.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         462: [
//           "0202",
//           0.182,
//           2.419,
//           0.18458,
//           0.18389,
//           0.18462,
//           0.184745,
//           "0.185",
//           13.2,
//           79.0,
//           5.0,
//           0.0,
//           6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         463: [
//           "1820",
//           6.14,
//           11.025,
//           6.2124,
//           6.2384,
//           6.2314,
//           6.20945,
//           "6.242",
//           18.4,
//           79.0,
//           5.0,
//           3.0,
//           7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         464: [
//           "3816",
//           0.26,
//           2.868,
//           0.2721,
//           0.27745,
//           0.284467,
//           0.281715,
//           "0.283",
//           1.56,
//           79.0,
//           3.0,
//           3.0,
//           22.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         465: [
//           "0831",
//           0.73,
//           5.686,
//           0.7436,
//           0.751,
//           0.759867,
//           0.75585,
//           "0.756",
//           5.6665,
//           79.0,
//           5.0,
//           2.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         466: [
//           "0505",
//           1.12,
//           27.912,
//           1.1538,
//           1.1264,
//           1.1104,
//           1.11435,
//           "1.12",
//           10.0,
//           79.0,
//           1.0,
//           0.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         467: [
//           "2915",
//           1.0,
//           0.1,
//           "-",
//           "-",
//           "-",
//           "-",
//           "-",
//           1.3674,
//           79.0,
//           8.0,
//           22.0,
//           10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         468: [
//           "8143",
//           0.051,
//           0.632,
//           0.05808,
//           0.05634,
//           0.05896,
//           0.061945,
//           "0.057",
//           1.4373,
//           79.0,
//           9.0,
//           7.0,
//           14.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         469: [
//           "9803",
//           1.092,
//           0.0,
//           "-",
//           "-",
//           "-",
//           "-",
//           "-",
//           0.0,
//           79.0,
//           1.0,
//           -2.0,
//           1.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         470: [
//           "1965",
//           3.18,
//           16.218,
//           3.223,
//           3.2132,
//           3.21587,
//           "-",
//           "3.226",
//           13.0,
//           79.0,
//           6.0,
//           6.0,
//           10.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         471: [
//           "6122",
//           2.4,
//           1.2,
//           2.3988,
//           2.3979,
//           2.39527,
//           2.40048,
//           "2.398",
//           21.0,
//           79.0,
//           5.0,
//           7.0,
//           12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         472: [
//           "1868",
//           0.475,
//           2.728,
//           0.4801,
//           0.48085,
//           0.4879,
//           0.48735,
//           "0.49",
//           9.9487,
//           79.0,
//           7.0,
//           9.0,
//           13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         473: [
//           "1271",
//           7.64,
//           47.125,
//           7.9052,
//           7.9153,
//           7.8806,
//           7.8833,
//           "7.88",
//           108.4,
//           79.0,
//           -1.0,
//           1.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         474: [
//           "1252",
//           5.89,
//           541.0,
//           6.4566,
//           6.3514,
//           6.29527,
//           6.23225,
//           "6.313",
//           173.0,
//           79.0,
//           -3.0,
//           -8.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         475: [
//           "0011",
//           144.0,
//           58701.0,
//           152.766,
//           148.72,
//           146.062,
//           148.33,
//           "146.37",
//           2753.0,
//           79.0,
//           12.0,
//           21.0,
//           15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         476: [
//           "3047",
//           13.98,
//           228.0,
//           13.7716,
//           12.7431,
//           13.2931,
//           14.8345,
//           "13.157",
//           0.0,
//           79.0,
//           -5.0,
//           -6.0,
//           23.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         477: [
//           "1222",
//           0.068,
//           39.523,
//           0.07554,
//           0.07429,
//           0.0716333,
//           0.070905,
//           "0.072",
//           11.3,
//           79.0,
//           0.0,
//           -1.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         478: [
//           "0138",
//           0.194,
//           0.137,
//           0.17718,
//           0.18314,
//           0.18448,
//           0.185305,
//           "0.185",
//           1.6938,
//           78.0,
//           3.0,
//           26.0,
//           36.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         479: [
//           "1737",
//           0.173,
//           0.522,
//           0.1951,
//           0.21361,
//           0.199447,
//           0.19323,
//           "0.199",
//           1.73,
//           78.0,
//           8.0,
//           -8.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         480: [
//           "0251",
//           6.48,
//           96.694,
//           5.8782,
//           5.8532,
//           6.0688,
//           6.38145,
//           "6.031",
//           39.0,
//           78.0,
//           7.0,
//           15.0,
//           20.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         481: [
//           "0418",
//           0.67,
//           136.0,
//           0.7706,
//           0.8307,
//           0.791,
//           0.7652,
//           "0.808",
//           8.0383,
//           78.0,
//           2.0,
//           -7.0,
//           -7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         482: [
//           "7332",
//           7.02,
//           2.177,
//           6.7626,
//           6.88215,
//           7.03653,
//           "-",
//           "6.992",
//           0.0,
//           78.0,
//           6.0,
//           6.0,
//           23.0,
//           2.0,
//           "E",
//         ],
//       },
//       {
//         483: [
//           "1876",
//           20.9,
//           41890.0,
//           21.9722,
//           21.2346,
//           20.7651,
//           21.7318,
//           "20.904",
//           2767.8,
//           78.0,
//           -1.0,
//           -9.0,
//           -7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         484: [
//           "0900",
//           4.96,
//           48.71,
//           5.0458,
//           5.0146,
//           4.9986,
//           5.02025,
//           "5.009",
//           20.7,
//           78.0,
//           5.0,
//           8.0,
//           10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         485: [
//           "0398",
//           3.84,
//           355.0,
//           4.4492,
//           4.565,
//           4.31227,
//           4.2895,
//           "4.326",
//           18.7,
//           78.0,
//           0.0,
//           -5.0,
//           -5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         486: [
//           "3860",
//           1.32,
//           29.145,
//           1.4678,
//           1.5509,
//           1.59893,
//           1.65085,
//           "1.572",
//           6.6,
//           78.0,
//           0.0,
//           -13.0,
//           -11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         487: [
//           "2207",
//           4.75,
//           188.0,
//           4.746,
//           4.884,
//           4.961,
//           "-",
//           "4.998",
//           24.1,
//           78.0,
//           5.0,
//           6.0,
//           32.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         488: [
//           "0264",
//           0.79,
//           11.612,
//           0.8594,
//           0.7841,
//           0.760667,
//           0.79815,
//           "0.767",
//           3.0233,
//           78.0,
//           -12.0,
//           -4.0,
//           -12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         489: [
//           "6823",
//           10.48,
//           6637.999999999999,
//           10.6404,
//           10.6208,
//           10.6324,
//           10.6142,
//           "10.622",
//           793.9,
//           78.0,
//           4.0,
//           5.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         490: [
//           "0808",
//           2.89,
//           236.0,
//           3.015,
//           3.0306,
//           3.042,
//           3.0283,
//           "3.049",
//           43.4,
//           78.0,
//           6.0,
//           11.0,
//           8.0,
//           0.0,
//           "R",
//         ],
//       },
//       {
//         491: [
//           "2276",
//           4.45,
//           332.0,
//           4.4266,
//           "-",
//           "-",
//           "-",
//           "-",
//           18.9,
//           78.0,
//           4.0,
//           5.0,
//           13.0,
//           1.0,
//           "M",
//         ],
//       },
//       {
//         492: [
//           "2215",
//           3.06,
//           75.626,
//           3.0688,
//           3.0921,
//           3.176,
//           "-",
//           "3.157",
//           31.1,
//           78.0,
//           8.0,
//           14.0,
//           20.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         493: [
//           "1399",
//           0.265,
//           68.411,
//           0.2835,
//           0.2851,
//           0.277367,
//           0.27241,
//           "0.28",
//           2.8885,
//           78.0,
//           -5.0,
//           5.0,
//           -5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         494: [
//           "8353",
//           0.42,
//           1.26,
//           0.423,
//           0.4217,
//           0.419667,
//           0.415025,
//           "0.416",
//           1.6921,
//           78.0,
//           -3.0,
//           -10.0,
//           -10.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         495: [
//           "1361",
//           3.15,
//           689.0,
//           3.8296,
//           3.7869,
//           3.7572,
//           3.75395,
//           "3.787",
//           65.1,
//           78.0,
//           -1.0,
//           -8.0,
//           -13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         496: [
//           "1960",
//           0.395,
//           14.087,
//           0.4313,
//           0.43795,
//           0.427767,
//           0.42155,
//           "0.425",
//           3.95,
//           78.0,
//           -4.0,
//           -3.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         497: [
//           "0040",
//           0.63,
//           77.036,
//           0.679582,
//           0.652691,
//           0.652527,
//           0.655695,
//           "0.655",
//           5.7674,
//           78.0,
//           0.0,
//           6.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         498: [
//           "2819",
//           98.9,
//           2.97,
//           99.649,
//           100.156,
//           100.492,
//           100.745,
//           "100.357",
//           0.0,
//           78.0,
//           6.0,
//           8.0,
//           13.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         499: [
//           "0939",
//           5.63,
//           255000.0,
//           5.842,
//           5.5593,
//           5.566,
//           5.6506,
//           "5.574",
//           13535.4,
//           78.0,
//           7.0,
//           6.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         500: [
//           "1217",
//           0.01,
//           0.2,
//           0.01,
//           0.01,
//           0.01,
//           0.01,
//           "0.01",
//           1.2801,
//           78.0,
//           5.0,
//           10.0,
//           19.0,
//           0.0,
//           "-",
//         ],
//       },
//       {
//         501: [
//           "6878",
//           1.68,
//           2834.0,
//           2.2494,
//           2.0302,
//           2.18447,
//           2.0932,
//           "2.041",
//           121.1,
//           77.0,
//           -10.0,
//           -17.0,
//           -18.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         502: [
//           "0626",
//           2.36,
//           114.0,
//           2.5544,
//           2.4971,
//           2.48673,
//           2.4903,
//           "2.495",
//           25.9,
//           77.0,
//           1.0,
//           7.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         503: [
//           "6196",
//           1.62,
//           53.182,
//           1.7228,
//           1.68131,
//           1.63206,
//           1.62669,
//           "1.726",
//           29.7,
//           77.0,
//           4.0,
//           -2.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         504: [
//           "0998",
//           3.57,
//           13084.0,
//           3.6738,
//           3.5534,
//           3.54907,
//           3.622,
//           "3.552",
//           531.2,
//           77.0,
//           7.0,
//           7.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         505: [
//           "1164",
//           0.8,
//           2561.0,
//           0.8354,
//           0.8989,
//           0.896733,
//           0.83865,
//           "0.912",
//           52.8,
//           77.0,
//           -14.0,
//           0.0,
//           -11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         506: [
//           "1610",
//           2.84,
//           22876.0,
//           3.3588,
//           3.1145,
//           2.89687,
//           2.89705,
//           "2.961",
//           110.8,
//           77.0,
//           -9.0,
//           -11.0,
//           -7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         507: [
//           "0072",
//           0.172,
//           8.179,
//           0.18574,
//           0.18677,
//           0.183227,
//           0.184045,
//           "0.183",
//           0.7539,
//           77.0,
//           -9.0,
//           -3.0,
//           8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         508: [
//           "1586",
//           1.53,
//           46.748,
//           1.628,
//           1.6918,
//           1.7828,
//           1.81394,
//           "1.746",
//           6.7856,
//           77.0,
//           8.0,
//           8.0,
//           12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         509: [
//           "6119",
//           0.36,
//           12.295,
//           0.344,
//           0.3577,
//           0.373433,
//           0.383875,
//           "0.369",
//           2.16,
//           77.0,
//           16.0,
//           15.0,
//           23.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         510: [
//           "6190",
//           10.62,
//           1.272,
//           10.7792,
//           10.8844,
//           10.9191,
//           10.9088,
//           "10.906",
//           43.2,
//           77.0,
//           6.0,
//           9.0,
//           13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         511: [
//           "3036",
//           426.8,
//           0.0,
//           455.266,
//           449.947,
//           445.027,
//           443.316,
//           "445.983",
//           0.0,
//           77.0,
//           5.0,
//           8.0,
//           2.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         512: [
//           "3015",
//           1642.0,
//           0.0,
//           1734.1,
//           1753.44,
//           1753.94,
//           1716.25,
//           "1759.733",
//           0.0,
//           77.0,
//           6.0,
//           16.0,
//           5.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         513: [
//           "3198",
//           95.74,
//           0.0,
//           98.475,
//           99.3274,
//           99.2749,
//           98.8416,
//           "99.12",
//           0.0,
//           77.0,
//           4.0,
//           3.0,
//           10.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         514: [
//           "1224",
//           1.76,
//           53.194,
//           1.8262,
//           1.7885,
//           1.7976,
//           1.82205,
//           "1.789",
//           68.3,
//           77.0,
//           2.0,
//           3.0,
//           8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         515: [
//           "0536",
//           1.13,
//           115.0,
//           1.183,
//           1.1634,
//           1.16347,
//           1.1639,
//           "1.161",
//           8.9793,
//           77.0,
//           2.0,
//           -2.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         516: [
//           "2836",
//           32.58,
//           171.0,
//           34.1624,
//           34.6078,
//           34.6612,
//           33.9234,
//           "34.751",
//           0.0,
//           77.0,
//           8.0,
//           18.0,
//           5.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         517: [
//           "0732",
//           2.01,
//           3474.0,
//           3.0044,
//           2.7373,
//           2.67393,
//           2.55205,
//           "2.658",
//           66.1,
//           77.0,
//           -8.0,
//           -12.0,
//           -14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         518: [
//           "0655",
//           0.6,
//           0.999,
//           0.6002,
//           0.5909,
//           0.594067,
//           0.6052,
//           "0.593",
//           11.9,
//           77.0,
//           5.0,
//           -3.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         519: [
//           "1732",
//           0.139,
//           154.0,
//           0.14716,
//           0.1663,
//           0.183413,
//           0.176545,
//           "0.18",
//           1.39,
//           77.0,
//           12.0,
//           20.0,
//           42.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         520: [
//           "8070",
//           0.195,
//           0.195,
//           0.21174,
//           0.21162,
//           0.210387,
//           0.20709,
//           "0.213",
//           0.39,
//           77.0,
//           -7.0,
//           13.0,
//           5.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         521: [
//           "8512",
//           0.245,
//           42.579,
//           0.24972,
//           0.27821,
//           0.292507,
//           0.294485,
//           "0.288",
//           2.695,
//           77.0,
//           12.0,
//           6.0,
//           -7.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         522: [
//           "2600",
//           4.37,
//           55482.0,
//           4.8388,
//           4.5791,
//           4.91367,
//           4.80985,
//           "4.917",
//           172.3,
//           77.0,
//           -7.0,
//           -15.0,
//           -14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         523: [
//           "0709",
//           1.51,
//           256.0,
//           1.5676,
//           1.5395,
//           1.54727,
//           1.57045,
//           "1.544",
//           23.8,
//           77.0,
//           -2.0,
//           3.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         524: [
//           "8646",
//           0.32,
//           12.967,
//           0.3356,
//           0.3393,
//           0.319867,
//           0.320775,
//           "0.321",
//           0.96,
//           76.0,
//           5.0,
//           -3.0,
//           10.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         525: [
//           "0874",
//           19.44,
//           2854.0,
//           21.2538,
//           20.3052,
//           20.3089,
//           20.8755,
//           "20.242",
//           42.7,
//           76.0,
//           3.0,
//           -3.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         526: [
//           "2118",
//           1.92,
//           1.94,
//           1.962,
//           1.9459,
//           1.94167,
//           1.934,
//           "1.945",
//           19.3,
//           76.0,
//           6.0,
//           12.0,
//           29.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         527: [
//           "1447",
//           0.82,
//           1.286,
//           0.8422,
//           0.827,
//           0.8508,
//           0.88015,
//           "0.833",
//           3.28,
//           76.0,
//           10.0,
//           11.0,
//           13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         528: [
//           "0123",
//           7.39,
//           5069.0,
//           7.7464,
//           7.3742,
//           7.34213,
//           7.60205,
//           "7.384",
//           228.8,
//           76.0,
//           1.0,
//           -2.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         529: [
//           "9074",
//           24.46,
//           106.0,
//           26.2084,
//           25.952,
//           25.7761,
//           25.7,
//           "25.768",
//           0.0,
//           76.0,
//           5.0,
//           9.0,
//           0.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         530: [
//           "6866",
//           0.36,
//           11.241,
//           0.387,
//           0.37725,
//           0.375033,
//           0.37925,
//           "-",
//           1.08,
//           76.0,
//           3.0,
//           -3.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         531: [
//           "1129",
//           0.275,
//           8.666,
//           0.2933,
//           0.29125,
//           0.292433,
//           0.297175,
//           "0.292",
//           5.2682,
//           76.0,
//           -3.0,
//           17.0,
//           17.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         532: [
//           "2803",
//           8.54,
//           47.635,
//           9.0126,
//           8.8349,
//           8.74853,
//           8.70915,
//           "8.804",
//           0.0,
//           76.0,
//           3.0,
//           -4.0,
//           -3.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         533: [
//           "1065",
//           3.27,
//           57.202,
//           3.7182,
//           3.7624,
//           3.6214,
//           3.5059,
//           "3.657",
//           11.1,
//           76.0,
//           0.0,
//           -2.0,
//           -7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         534: [
//           "8500",
//           0.63,
//           0.63,
//           0.5373,
//           0.5533,
//           0.5786,
//           0.61865,
//           "0.567",
//           1.134,
//           76.0,
//           4.0,
//           13.0,
//           -1.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         535: [
//           "1709",
//           2.65,
//           184.0,
//           2.6604,
//           2.6287,
//           2.60533,
//           2.7356,
//           "2.626",
//           37.8,
//           76.0,
//           0.0,
//           5.0,
//           15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         536: [
//           "0713",
//           0.82,
//           86.29,
//           0.916,
//           0.8975,
//           0.845467,
//           0.8534,
//           "0.855",
//           6.3641,
//           76.0,
//           3.0,
//           -5.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         537: [
//           "9836",
//           4.158,
//           0.0,
//           4.37924,
//           4.43798,
//           4.44697,
//           4.35645,
//           "4.455",
//           0.0,
//           76.0,
//           7.0,
//           18.0,
//           7.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         538: [
//           "3020",
//           960.0,
//           34.479,
//           1010.83,
//           1027.7,
//           1020.67,
//           1009.16,
//           "1020.867",
//           0.0,
//           76.0,
//           3.0,
//           3.0,
//           6.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         539: [
//           "0387",
//           0.87,
//           0.692,
//           0.8282,
//           0.8536,
//           0.882933,
//           0.90655,
//           "0.875",
//           2.0016,
//           76.0,
//           21.0,
//           23.0,
//           32.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         540: [
//           "3070",
//           24.96,
//           102.0,
//           25.898,
//           25.2114,
//           25.2799,
//           25.5413,
//           "25.304",
//           0.0,
//           76.0,
//           3.0,
//           0.0,
//           0.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         541: [
//           "2180",
//           8.98,
//           2.024,
//           9.0744,
//           9.109,
//           9.11293,
//           9.14225,
//           "9.112",
//           18.6,
//           76.0,
//           6.0,
//           5.0,
//           10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         542: [
//           "0003",
//           11.48,
//           22805.0,
//           12.0056,
//           11.9142,
//           12.0416,
//           12.1367,
//           "11.955",
//           2142.1,
//           76.0,
//           6.0,
//           5.0,
//           10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         543: [
//           "8400",
//           0.045,
//           0.36,
//           0.04648,
//           0.04803,
//           0.0505333,
//           0.04817,
//           "0.049",
//           0.45,
//           76.0,
//           9.0,
//           15.0,
//           8.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         544: [
//           "3074",
//           190.7,
//           32.572,
//           204.474,
//           202.389,
//           200.834,
//           200.006,
//           "200.948",
//           0.0,
//           76.0,
//           4.0,
//           7.0,
//           -1.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         545: [
//           "9804",
//           12.3,
//           0.0,
//           12.9372,
//           13.1581,
//           13.0757,
//           12.9888,
//           "13.057",
//           0.0,
//           76.0,
//           3.0,
//           2.0,
//           6.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         546: [
//           "1811",
//           3.49,
//           15314.0,
//           5.8316,
//           6.4202,
//           6.0876,
//           5.2738,
//           "6.259",
//           149.7,
//           76.0,
//           -13.0,
//           -17.0,
//           -18.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         547: [
//           "2821",
//           116.25,
//           50.113,
//           118.117,
//           118.81,
//           119.273,
//           119.953,
//           "119.018",
//           0.0,
//           75.0,
//           5.0,
//           6.0,
//           11.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         548: [
//           "0213",
//           1.02,
//           187.0,
//           1.0454,
//           1.05875,
//           1.06017,
//           1.07337,
//           "1.057",
//           9.7766,
//           75.0,
//           4.0,
//           10.0,
//           13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         549: [
//           "3145",
//           10.56,
//           60736.0,
//           10.9436,
//           10.8125,
//           10.8629,
//           10.9286,
//           "10.856",
//           0.0,
//           75.0,
//           7.0,
//           7.0,
//           7.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         550: [
//           "0956",
//           4.03,
//           11441.0,
//           5.1888,
//           5.6206,
//           5.71167,
//           5.20595,
//           "5.798",
//           74.1,
//           75.0,
//           -12.0,
//           -15.0,
//           -17.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         551: [
//           "0066",
//           41.2,
//           18255.0,
//           42.052,
//           41.975,
//           42.4957,
//           42.9345,
//           "42.283",
//           2551.7,
//           75.0,
//           10.0,
//           17.0,
//           22.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         552: [
//           "0691",
//           1.96,
//           8.878,
//           2.0412,
//           2.0221,
//           2.08593,
//           2.1027,
//           "2.05",
//           85.29999999999998,
//           75.0,
//           11.0,
//           2.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         553: [
//           "0369",
//           4.14,
//           0.828,
//           4.293,
//           4.2974,
//           4.30507,
//           4.3829,
//           "4.301",
//           56.1,
//           75.0,
//           6.0,
//           14.0,
//           18.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         554: [
//           "8426",
//           0.148,
//           1.019,
//           0.14544,
//           0.14403,
//           0.147007,
//           0.14897,
//           "0.147",
//           1.184,
//           75.0,
//           8.0,
//           -3.0,
//           16.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         555: [
//           "1358",
//           0.89,
//           11.11,
//           0.991,
//           1.0576,
//           1.11953,
//           1.1203,
//           "1.119",
//           13.9,
//           75.0,
//           5.0,
//           -7.0,
//           15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         556: [
//           "8502",
//           0.255,
//           2.04,
//           0.2663,
//           0.2799,
//           0.2857,
//           0.28081,
//           "0.282",
//           2.04,
//           75.0,
//           7.0,
//           12.0,
//           6.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         557: [
//           "2886",
//           1.5,
//           26.086,
//           1.6124,
//           1.5745,
//           1.61167,
//           1.60565,
//           "1.604",
//           20.2,
//           75.0,
//           -7.0,
//           -6.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         558: [
//           "2295",
//           1.31,
//           63.15,
//           1.3394,
//           1.3369,
//           1.3382,
//           1.3378,
//           "-",
//           5.24,
//           75.0,
//           8.0,
//           3.0,
//           12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         559: [
//           "0259",
//           2.25,
//           41.316,
//           2.756,
//           2.6427,
//           2.5634,
//           2.6266,
//           "2.533",
//           22.3,
//           75.0,
//           0.0,
//           -8.0,
//           -11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         560: [
//           "0188",
//           0.355,
//           3.55,
//           0.3837,
//           0.3837,
//           0.3758,
//           0.3767,
//           "0.377",
//           2.5928,
//           75.0,
//           3.0,
//           -3.0,
//           10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         561: [
//           "0464",
//           0.495,
//           60.79,
//           0.4634,
//           0.45535,
//           0.492633,
//           0.50565,
//           "0.484",
//           2.2059,
//           75.0,
//           4.0,
//           15.0,
//           15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         562: [
//           "6199",
//           2.45,
//           0.245,
//           2.5604,
//           2.5674,
//           2.5644,
//           2.56965,
//           "2.569",
//           53.9,
//           75.0,
//           5.0,
//           4.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         563: [
//           "2804",
//           95.0,
//           74.281,
//           100.802,
//           102.46,
//           101.783,
//           101.049,
//           "101.714",
//           0.0,
//           75.0,
//           1.0,
//           1.0,
//           7.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         564: [
//           "0089",
//           4.01,
//           4.425,
//           4.08,
//           4.0805,
//           4.12987,
//           4.1853,
//           "4.109",
//           11.5,
//           75.0,
//           -3.0,
//           4.0,
//           8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         565: [
//           "0440",
//           23.8,
//           796.0,
//           25.375,
//           24.7725,
//           24.5897,
//           24.905,
//           "24.665",
//           76.0,
//           75.0,
//           4.0,
//           4.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         566: [
//           "1666",
//           5.79,
//           929.0,
//           7.2342,
//           6.5519,
//           6.3218,
//           6.42075,
//           "6.381",
//           36.4,
//           75.0,
//           -6.0,
//           -7.0,
//           -9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         567: [
//           "0896",
//           1.22,
//           30.672,
//           1.2748,
//           1.2872,
//           1.301,
//           1.3007,
//           "1.295",
//           13.4,
//           75.0,
//           4.0,
//           5.0,
//           13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         568: [
//           "3678",
//           0.86,
//           13.636,
//           0.9504,
//           0.9841,
//           0.9678,
//           0.9396,
//           "0.958",
//           2.1474,
//           75.0,
//           11.0,
//           6.0,
//           20.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         569: [
//           "6968",
//           4.14,
//           519.0,
//           4.4964,
//           4.3532,
//           4.3634,
//           4.3854,
//           "4.375",
//           67.3,
//           75.0,
//           -7.0,
//           -3.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         570: [
//           "0929",
//           0.78,
//           50.445,
//           0.9298,
//           0.9128,
//           0.9014,
//           0.847,
//           "0.931",
//           8.2075,
//           75.0,
//           -7.0,
//           -12.0,
//           -13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         571: [
//           "2738",
//           2.47,
//           77.286,
//           2.4814,
//           2.4165,
//           2.5064,
//           2.58165,
//           "2.462",
//           14.8,
//           74.0,
//           -2.0,
//           9.0,
//           -5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         572: [
//           "1090",
//           2.65,
//           41.596,
//           2.8504,
//           3.0409,
//           3.19713,
//           3.16745,
//           "3.185",
//           33.7,
//           74.0,
//           0.0,
//           -5.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         573: [
//           "0313",
//           0.011,
//           2.255,
//           0.01126,
//           0.01087,
//           0.0112667,
//           0.011875,
//           "0.011",
//           2.567,
//           74.0,
//           19.0,
//           10.0,
//           21.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         574: [
//           "1202",
//           1.22,
//           29.474,
//           1.5042,
//           1.4109,
//           1.29907,
//           1.2313,
//           "1.314",
//           1.952,
//           74.0,
//           -14.0,
//           -13.0,
//           -16.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         575: [
//           "0906",
//           4.02,
//           155.0,
//           4.4166,
//           4.1771,
//           4.23033,
//           4.33815,
//           "4.211",
//           44.7,
//           74.0,
//           4.0,
//           4.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         576: [
//           "1073",
//           0.25,
//           0.272,
//           0.23862,
//           0.24451,
//           0.24602,
//           0.25374,
//           "0.247",
//           2.8483,
//           74.0,
//           6.0,
//           16.0,
//           24.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         577: [
//           "6886",
//           11.54,
//           7028.0,
//           13.1556,
//           12.5418,
//           12.2443,
//           11.9745,
//           "12.386",
//           198.3,
//           74.0,
//           2.0,
//           -4.0,
//           -10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         578: [
//           "0621",
//           0.027,
//           31.238,
//           0.03038,
//           0.02773,
//           0.0272467,
//           0.027775,
//           "0.027",
//           4.9008,
//           74.0,
//           19.0,
//           14.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         579: [
//           "1420",
//           0.141,
//           0.112,
//           0.15322,
//           0.17692,
//           0.175287,
//           0.16885,
//           "0.173",
//           1.4614,
//           74.0,
//           13.0,
//           -6.0,
//           -8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         580: [
//           "0189",
//           8.26,
//           53045.00000000001,
//           10.3222,
//           12.6011,
//           15.2306,
//           13.6514,
//           "14.562",
//           186.1,
//           74.0,
//           -14.0,
//           -7.0,
//           -11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         581: [
//           "0431",
//           0.196,
//           16.75,
//           0.20004,
//           0.19687,
//           0.196053,
//           0.198985,
//           "0.196",
//           15.2,
//           74.0,
//           8.0,
//           6.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         582: [
//           "0804",
//           0.041,
//           26.896,
//           0.05,
//           0.04758,
//           0.05378,
//           0.052385,
//           "0.052",
//           1.8498,
//           74.0,
//           18.0,
//           7.0,
//           17.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         583: [
//           "2257",
//           74.0,
//           841.0,
//           81.791,
//           "-",
//           "-",
//           "-",
//           "-",
//           65.8,
//           74.0,
//           -2.0,
//           -12.0,
//           -13.0,
//           1.0,
//           "M",
//         ],
//       },
//       {
//         584: [
//           "3778",
//           0.445,
//           8.708,
//           0.4823,
//           0.4826,
//           0.4829,
//           0.481525,
//           "0.481",
//           5.5729,
//           74.0,
//           -4.0,
//           -5.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         585: [
//           "3393",
//           2.57,
//           1100.0,
//           3.1606,
//           3.0602,
//           2.9336,
//           2.817,
//           "2.923",
//           25.5,
//           74.0,
//           -8.0,
//           -12.0,
//           -10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         586: [
//           "6818",
//           2.83,
//           4294.0,
//           2.934,
//           2.8423,
//           2.81567,
//           2.8645,
//           "2.829",
//           358.8,
//           74.0,
//           6.0,
//           7.0,
//           8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         587: [
//           "2558",
//           1.41,
//           0.141,
//           1.4142,
//           1.4321,
//           1.45747,
//           1.4662,
//           "1.447",
//           13.6,
//           74.0,
//           6.0,
//           15.0,
//           19.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         588: [
//           "1978",
//           0.79,
//           45.712,
//           0.9588,
//           0.9947,
//           0.9762,
//           0.9882,
//           "0.977",
//           6.32,
//           74.0,
//           -2.0,
//           2.0,
//           6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         589: [
//           "2119",
//           0.97,
//           24.946,
//           0.993,
//           0.9903,
//           0.997933,
//           1.009,
//           "0.994",
//           6.991,
//           74.0,
//           6.0,
//           5.0,
//           12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         590: [
//           "3093",
//           12.91,
//           571.0,
//           13.802,
//           13.563,
//           13.4666,
//           13.3521,
//           "13.499",
//           0.0,
//           74.0,
//           1.0,
//           -3.0,
//           -5.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         591: [
//           "0322",
//           14.44,
//           16187.0,
//           16.5072,
//           15.9138,
//           15.3281,
//           15.2408,
//           "15.413",
//           813.2,
//           74.0,
//           3.0,
//           -9.0,
//           -13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         592: [
//           "0128",
//           0.57,
//           36.436,
//           0.6314,
//           0.6065,
//           0.6088,
//           0.61905,
//           "0.606",
//           9.4087,
//           74.0,
//           -1.0,
//           -1.0,
//           -9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         593: [
//           "0703",
//           0.16,
//           0.1,
//           0.1572,
//           0.16276,
//           0.164607,
//           0.16692,
//           "0.165",
//           1.1108,
//           74.0,
//           14.0,
//           25.0,
//           38.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         594: [
//           "8299",
//           0.038,
//           2.275,
//           0.0385,
//           0.03963,
//           0.0405867,
//           0.04279,
//           "0.04",
//           0.5687,
//           73.0,
//           20.0,
//           -1.0,
//           12.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         595: [
//           "3868",
//           4.01,
//           3447.0,
//           4.0654,
//           4.1393,
//           4.3204,
//           4.35495,
//           "4.264",
//           285.1,
//           73.0,
//           -2.0,
//           2.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         596: [
//           "8241",
//           0.22,
//           4.946,
//           0.23566,
//           0.24371,
//           0.251313,
//           0.2511,
//           "0.246",
//           0.7951,
//           73.0,
//           -5.0,
//           14.0,
//           -10.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         597: [
//           "9998",
//           0.184,
//           1.05,
//           0.18972,
//           0.19365,
//           0.199713,
//           0.20461,
//           "0.198",
//           1.472,
//           73.0,
//           3.0,
//           10.0,
//           10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         598: [
//           "1731",
//           0.95,
//           105.0,
//           1.0112,
//           1.1596,
//           1.24013,
//           1.2632,
//           "1.218",
//           10.6,
//           73.0,
//           -8.0,
//           -4.0,
//           22.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         599: [
//           "1799",
//           15.42,
//           12980.0,
//           15.416,
//           16.9881,
//           18.2883,
//           17.8608,
//           "18.168",
//           58.0,
//           73.0,
//           -6.0,
//           20.0,
//           27.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         600: [
//           "0684",
//           1.58,
//           2.518,
//           1.6488,
//           1.6608,
//           1.71453,
//           1.72545,
//           "1.699",
//           5.2668,
//           73.0,
//           2.0,
//           3.0,
//           7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         601: [
//           "2293",
//           0.76,
//           8.064,
//           0.8424,
//           0.8255,
//           0.857067,
//           0.8491,
//           "0.873",
//           3.04,
//           73.0,
//           -1.0,
//           -6.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         602: [
//           "8291",
//           0.098,
//           1.176,
//           0.11888,
//           0.10246,
//           0.0927533,
//           0.09417,
//           "0.096",
//           0.3136,
//           73.0,
//           10.0,
//           -9.0,
//           -14.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         603: [
//           "0603",
//           0.415,
//           61.134,
//           0.4409,
//           0.44375,
//           0.458233,
//           0.4466,
//           "0.464",
//           23.9,
//           73.0,
//           -2.0,
//           -6.0,
//           6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         604: [
//           "1865",
//           0.95,
//           1111.0,
//           0.9686,
//           0.971,
//           1.01433,
//           1.05995,
//           "1.006",
//           8.74,
//           73.0,
//           6.0,
//           7.0,
//           16.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         605: [
//           "0086",
//           3.8,
//           274.0,
//           4.0852,
//           4.0696,
//           4.0796,
//           4.0955,
//           "4.079",
//           74.9,
//           73.0,
//           4.0,
//           6.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         606: [
//           "1269",
//           0.244,
//           49.624,
//           0.23612,
//           0.23606,
//           0.243367,
//           0.25,
//           "0.242",
//           3.2822,
//           73.0,
//           12.0,
//           11.0,
//           25.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         607: [
//           "0444",
//           0.054,
//           9.833,
//           0.05324,
//           0.05205,
//           0.05324,
//           0.054685,
//           "0.053",
//           3.2637,
//           73.0,
//           4.0,
//           26.0,
//           31.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         608: [
//           "2362",
//           1.11,
//           3549.0,
//           1.3334,
//           1.2528,
//           1.2426,
//           1.2279,
//           "1.257",
//           139.9,
//           73.0,
//           -11.0,
//           -16.0,
//           -12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         609: [
//           "0553",
//           3.8,
//           135.0,
//           4.3744,
//           4.0227,
//           3.8152,
//           3.84895,
//           "3.838",
//           9.196,
//           73.0,
//           2.0,
//           -5.0,
//           -9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         610: [
//           "0882",
//           1.66,
//           182.0,
//           1.7722,
//           1.726,
//           1.72927,
//           1.7242,
//           "1.745",
//           17.8,
//           73.0,
//           -6.0,
//           -5.0,
//           -12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         611: [
//           "2019",
//           2.64,
//           160.0,
//           2.7952,
//           2.6802,
//           2.73193,
//           2.7997,
//           "2.71",
//           71.3,
//           73.0,
//           4.0,
//           8.0,
//           -5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         612: [
//           "1339",
//           2.35,
//           10954.0,
//           2.4828,
//           2.4283,
//           2.4322,
//           2.489,
//           "2.434",
//           205.0,
//           73.0,
//           5.0,
//           4.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         613: [
//           "2028",
//           0.244,
//           0.14,
//           0.24468,
//           0.24562,
//           0.24568,
//           0.248565,
//           "0.247",
//           1.4954,
//           73.0,
//           20.0,
//           19.0,
//           19.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         614: [
//           "0836",
//           14.06,
//           96457.0,
//           19.3788,
//           20.426,
//           19.9518,
//           17.6558,
//           "20.216",
//           676.2999999999998,
//           73.0,
//           -14.0,
//           -14.0,
//           -18.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         615: [
//           "1138",
//           3.11,
//           5552.0,
//           3.2434,
//           3.1855,
//           3.23987,
//           3.29025,
//           "3.262",
//           40.30000000000001,
//           73.0,
//           -12.0,
//           -9.0,
//           -15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         616: [
//           "1415",
//           6.3,
//           2216.0,
//           9.5206,
//           9.4251,
//           8.04653,
//           7.21595,
//           "8.231",
//           52.5,
//           73.0,
//           -13.0,
//           -10.0,
//           -19.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         617: [
//           "3165",
//           14.79,
//           4.737,
//           15.5416,
//           16.0038,
//           16.0764,
//           15.9701,
//           "15.996",
//           0.0,
//           72.0,
//           10.0,
//           17.0,
//           9.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         618: [
//           "0230",
//           0.78,
//           23.468,
//           0.8622,
//           0.8288,
//           0.813267,
//           0.81925,
//           "0.816",
//           26.1,
//           72.0,
//           -4.0,
//           -10.0,
//           -9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         619: [
//           "0356",
//           0.039,
//           3.177,
//           0.03982,
//           0.04005,
//           0.0402733,
//           0.040315,
//           "0.04",
//           1.0667,
//           72.0,
//           11.0,
//           15.0,
//           15.0,
//           0.0,
//           "-",
//         ],
//       },
//       {
//         620: [
//           "1308",
//           27.8,
//           21219.0,
//           30.619,
//           29.2615,
//           29.674,
//           29.9395,
//           "29.595",
//           745.7,
//           72.0,
//           -3.0,
//           -16.0,
//           -17.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         621: [
//           "1378",
//           9.19,
//           27372.0,
//           9.668,
//           8.8116,
//           9.5342,
//           9.81005,
//           "9.429",
//           850.4,
//           72.0,
//           -9.0,
//           -19.0,
//           -15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         622: [
//           "2110",
//           0.129,
//           62.109,
//           0.1506,
//           0.15268,
//           0.147127,
//           0.14239,
//           "0.147",
//           2.8689,
//           72.0,
//           2.0,
//           -7.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         623: [
//           "0258",
//           1.92,
//           79.53,
//           2.0588,
//           2.0555,
//           2.03593,
//           2.0042,
//           "2.035",
//           37.8,
//           72.0,
//           3.0,
//           2.0,
//           15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         624: [
//           "3300",
//           1.41,
//           2928.0,
//           1.6704,
//           1.8891,
//           2.3384,
//           2.2722,
//           "2.177",
//           25.8,
//           72.0,
//           -13.0,
//           3.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         625: [
//           "1963",
//           4.43,
//           578.0,
//           4.644,
//           4.5033,
//           4.5158,
//           4.6478,
//           "4.499",
//           69.9,
//           72.0,
//           3.0,
//           3.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         626: [
//           "1821",
//           23.65,
//           13976.0,
//           25.429,
//           25.619,
//           24.98,
//           25.1493,
//           "25.078",
//           1057.4,
//           72.0,
//           6.0,
//           10.0,
//           10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         627: [
//           "1426",
//           2.61,
//           31.237,
//           2.7074,
//           2.6982,
//           2.751,
//           2.78495,
//           "2.738",
//           38.4,
//           72.0,
//           1.0,
//           6.0,
//           7.0,
//           0.0,
//           "R",
//         ],
//       },
//       {
//         628: [
//           "2880",
//           0.72,
//           351.0,
//           0.735,
//           0.7261,
//           0.730333,
//           0.74095,
//           "0.733",
//           37.1,
//           72.0,
//           4.0,
//           3.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         629: [
//           "8279",
//           0.237,
//           135.0,
//           0.23604,
//           0.26857,
//           0.268047,
//           0.27891,
//           "0.27",
//           27.6,
//           72.0,
//           9.0,
//           19.0,
//           46.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         630: [
//           "3633",
//           6.61,
//           622.0,
//           7.7742,
//           7.6631,
//           7.3156,
//           7.1464,
//           "7.357",
//           187.3,
//           72.0,
//           -2.0,
//           -1.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         631: [
//           "1199",
//           6.0,
//           2016.0,
//           6.5082,
//           6.4463,
//           6.4014,
//           6.3302,
//           "6.447",
//           198.9,
//           72.0,
//           8.0,
//           9.0,
//           -9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         632: [
//           "1606",
//           1.05,
//           154.0,
//           1.1278,
//           1.1044,
//           1.1052,
//           1.111,
//           "1.108",
//           29.0,
//           72.0,
//           -1.0,
//           0.0,
//           -5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         633: [
//           "0194",
//           7.36,
//           81.438,
//           7.754,
//           7.805,
//           7.86767,
//           7.96175,
//           "7.808",
//           27.8,
//           72.0,
//           7.0,
//           8.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         634: [
//           "1283",
//           1.53,
//           158.0,
//           1.606,
//           1.5835,
//           1.5756,
//           1.5711,
//           "1.579",
//           12.2,
//           72.0,
//           7.0,
//           5.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         635: [
//           "0762",
//           3.88,
//           30893.0,
//           4.1276,
//           4.0402,
//           4.07707,
//           4.1095,
//           "4.044",
//           1187.2,
//           72.0,
//           -1.0,
//           3.0,
//           8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         636: [
//           "0632",
//           0.26,
//           5.217,
//           0.2675,
//           0.2735,
//           0.274067,
//           0.272385,
//           "0.273",
//           2.1888,
//           72.0,
//           -10.0,
//           11.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         637: [
//           "0025",
//           8.98,
//           10.77,
//           9.3144,
//           9.4159,
//           9.47233,
//           9.5449,
//           "9.423",
//           27.1,
//           72.0,
//           3.0,
//           3.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         638: [
//           "0811",
//           5.22,
//           360.0,
//           5.9378,
//           5.8474,
//           5.7036,
//           5.6325,
//           "5.768",
//           23.0,
//           72.0,
//           0.0,
//           -8.0,
//           -8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         639: [
//           "2611",
//           10.36,
//           2076.0,
//           11.7272,
//           11.295,
//           11.188,
//           11.0908,
//           "11.3",
//           144.1,
//           72.0,
//           3.0,
//           -4.0,
//           -8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         640: [
//           "9908",
//           10.1,
//           9.633,
//           11.4832,
//           11.3172,
//           10.9161,
//           10.7337,
//           "10.984",
//           3.8222,
//           72.0,
//           3.0,
//           -2.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         641: [
//           "1992",
//           10.02,
//           1014.0,
//           11.6056,
//           10.843,
//           10.7418,
//           11.1848,
//           "10.809",
//           124.1,
//           71.0,
//           -8.0,
//           -11.0,
//           -15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         642: [
//           "0954",
//           0.64,
//           2.048,
//           0.6896,
//           0.6835,
//           0.678533,
//           0.6872,
//           "0.68",
//           1.1756,
//           71.0,
//           -2.0,
//           1.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         643: [
//           "3141",
//           15.08,
//           48362.0,
//           15.5594,
//           15.8123,
//           15.9509,
//           16.0035,
//           "15.893",
//           0.0,
//           71.0,
//           6.0,
//           6.0,
//           12.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         644: [
//           "1277",
//           0.54,
//           132.0,
//           0.5606,
//           0.5794,
//           0.623133,
//           0.6099,
//           "0.614",
//           45.5,
//           71.0,
//           3.0,
//           -17.0,
//           -10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         645: [
//           "2381",
//           0.102,
//           1.216,
//           0.1038,
//           0.11056,
//           0.108933,
//           0.113705,
//           "0.108",
//           2.04,
//           71.0,
//           6.0,
//           9.0,
//           20.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         646: [
//           "1881",
//           1.38,
//           124.0,
//           1.4502,
//           1.4463,
//           1.46013,
//           1.5114,
//           "1.443",
//           44.9,
//           71.0,
//           5.0,
//           7.0,
//           15.0,
//           0.0,
//           "R",
//         ],
//       },
//       {
//         647: [
//           "1547",
//           0.27,
//           2.332,
//           0.2895,
//           0.28685,
//           0.29126,
//           0.29682,
//           "0.289",
//           2.16,
//           71.0,
//           5.0,
//           2.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         648: [
//           "0689",
//           0.043,
//           75.525,
//           0.0356,
//           0.03948,
//           0.0479933,
//           0.053435,
//           "0.047",
//           2.2533,
//           71.0,
//           -25.0,
//           -25.0,
//           -19.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         649: [
//           "9913",
//           3.1,
//           223.0,
//           3.3264,
//           3.4329,
//           3.45373,
//           3.36655,
//           "3.435",
//           31.0,
//           71.0,
//           4.0,
//           -2.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         650: [
//           "1555",
//           0.059,
//           26.685,
//           0.05758,
//           0.06049,
//           0.0590667,
//           0.05631,
//           "0.061",
//           1.9289,
//           71.0,
//           0.0,
//           13.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         651: [
//           "2139",
//           1.05,
//           38.702,
//           1.1398,
//           1.1003,
//           1.11447,
//           1.144,
//           "1.109",
//           39.8,
//           71.0,
//           11.0,
//           13.0,
//           23.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         652: [
//           "1218",
//           4.05,
//           72.91,
//           4.3174,
//           4.3619,
//           4.2102,
//           4.20565,
//           "4.223",
//           3.0054,
//           71.0,
//           0.0,
//           5.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         653: [
//           "1618",
//           1.96,
//           7207.0,
//           2.2778,
//           2.1741,
//           2.31333,
//           2.2232,
//           "2.3",
//           56.2,
//           71.0,
//           -1.0,
//           -18.0,
//           -12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         654: [
//           "0528",
//           1.14,
//           13.426,
//           1.1828,
//           1.1822,
//           1.20473,
//           1.22515,
//           "1.202",
//           7.1783,
//           71.0,
//           8.0,
//           17.0,
//           38.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         655: [
//           "8226",
//           0.35,
//           2.765,
//           0.3696,
//           0.3599,
//           0.336267,
//           0.33037,
//           "0.343",
//           0.487,
//           71.0,
//           10.0,
//           13.0,
//           2.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         656: [
//           "0932",
//           0.178,
//           21.107,
//           0.2158,
//           0.27275,
//           0.277273,
//           0.23925,
//           "0.281",
//           5.532,
//           71.0,
//           2.0,
//           -8.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         657: [
//           "0270",
//           10.22,
//           11029.0,
//           10.3968,
//           10.2377,
//           10.3478,
//           10.552,
//           "10.318",
//           668.1,
//           71.0,
//           11.0,
//           10.0,
//           21.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         658: [
//           "2319",
//           42.05,
//           96609.0,
//           47.134,
//           46.6775,
//           46.6357,
//           46.2323,
//           "46.875",
//           1662.3,
//           71.0,
//           0.0,
//           -11.0,
//           -15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         659: [
//           "0845",
//           0.214,
//           5.893,
//           0.2331,
//           0.23359,
//           0.23172,
//           0.23106,
//           "0.233",
//           16.6,
//           71.0,
//           9.0,
//           -1.0,
//           17.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         660: [
//           "8456",
//           0.475,
//           41.17,
//           0.4348,
//           0.435383,
//           0.473522,
//           0.469616,
//           "0.464",
//           1.0047,
//           71.0,
//           -13.0,
//           -18.0,
//           52.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         661: [
//           "3618",
//           2.8,
//           3105.0,
//           2.9016,
//           2.8184,
//           2.85213,
//           2.9207,
//           "2.84",
//           70.3,
//           71.0,
//           6.0,
//           7.0,
//           10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         662: [
//           "0650",
//           0.9,
//           373.0,
//           1.1568,
//           1.2051,
//           1.14413,
//           1.12615,
//           "1.146",
//           62.7,
//           71.0,
//           10.0,
//           -17.0,
//           -20.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         663: [
//           "2682",
//           0.145,
//           6.31,
//           0.14862,
//           0.15503,
//           0.161173,
//           0.16249,
//           "0.157",
//           1.45,
//           71.0,
//           1.0,
//           22.0,
//           26.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         664: [
//           "0560",
//           0.95,
//           54.726,
//           1.0168,
//           0.9879,
//           0.992667,
//           1.00165,
//           "0.991",
//           10.6,
//           70.0,
//           -4.0,
//           -6.0,
//           -8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         665: [
//           "8140",
//           0.147,
//           1.447,
//           0.14832,
//           0.15163,
//           0.153567,
//           0.156165,
//           "0.152",
//           1.176,
//           70.0,
//           1.0,
//           13.0,
//           -1.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         666: [
//           "8136",
//           0.056,
//           0.0,
//           0.05292,
//           0.07218,
//           0.0918667,
//           0.087665,
//           "0.088",
//           0.56,
//           70.0,
//           8.0,
//           15.0,
//           37.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         667: [
//           "3187",
//           20.28,
//           164.0,
//           20.5782,
//           21.1057,
//           21.4282,
//           21.6905,
//           "21.328",
//           0.0,
//           70.0,
//           5.0,
//           9.0,
//           15.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         668: [
//           "0371",
//           2.79,
//           4510.0,
//           3.0674,
//           2.9999,
//           3.0496,
//           3.02865,
//           "3.039",
//           282.7,
//           70.0,
//           -1.0,
//           -1.0,
//           -7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         669: [
//           "0090",
//           0.74,
//           8.91,
//           0.7902,
//           0.784,
//           0.827133,
//           0.86035,
//           "0.818",
//           3.3936,
//           70.0,
//           5.0,
//           3.0,
//           7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         670: [
//           "3389",
//           0.27,
//           8.416,
//           0.2785,
//           0.2841,
//           0.288967,
//           0.2943,
//           "0.286",
//           12.0,
//           70.0,
//           10.0,
//           11.0,
//           20.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         671: [
//           "0737",
//           2.77,
//           40.764,
//           2.8048,
//           2.8062,
//           2.8338,
//           2.8602,
//           "2.824",
//           85.29999999999998,
//           70.0,
//           6.0,
//           4.0,
//           8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         672: [
//           "1050",
//           1.48,
//           75.598,
//           1.559,
//           1.5778,
//           1.57113,
//           1.61645,
//           "1.559",
//           29.8,
//           70.0,
//           7.0,
//           8.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         673: [
//           "1827",
//           1.21,
//           38.95,
//           1.4898,
//           1.5915,
//           1.55987,
//           1.45405,
//           "1.601",
//           4.84,
//           70.0,
//           0.0,
//           -4.0,
//           -15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         674: [
//           "1099",
//           17.58,
//           7867.0,
//           18.3928,
//           18.0999,
//           18.9361,
//           20.0366,
//           "18.707",
//           235.8,
//           70.0,
//           4.0,
//           8.0,
//           8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         675: [
//           "1186",
//           4.72,
//           7573.0,
//           5.4352,
//           5.2213,
//           5.21153,
//           5.1635,
//           "5.234",
//           98.0,
//           70.0,
//           1.0,
//           0.0,
//           -9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         676: [
//           "0631",
//           7.85,
//           1981.0,
//           8.0124,
//           8.0971,
//           8.6332,
//           8.56445,
//           "8.525",
//           247.6,
//           70.0,
//           11.0,
//           9.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         677: [
//           "0759",
//           0.65,
//           37.768,
//           0.6774,
//           0.6657,
//           0.679933,
//           0.7085,
//           "0.677",
//           4.3302,
//           70.0,
//           9.0,
//           0.0,
//           15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         678: [
//           "2233",
//           1.22,
//           3447.0,
//           1.353,
//           1.349,
//           1.34433,
//           1.30655,
//           "1.355",
//           66.3,
//           70.0,
//           3.0,
//           -7.0,
//           -7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         679: [
//           "9141",
//           1.93,
//           0.0,
//           1.99508,
//           2.02858,
//           2.04733,
//           2.05596,
//           "2.039",
//           0.0,
//           70.0,
//           6.0,
//           5.0,
//           11.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         680: [
//           "0315",
//           4.11,
//           262.0,
//           4.3482,
//           4.3215,
//           4.37107,
//           4.4212,
//           "4.368",
//           45.5,
//           70.0,
//           7.0,
//           6.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         681: [
//           "0807",
//           1.22,
//           421.0,
//           1.433,
//           1.4003,
//           1.3664,
//           1.32165,
//           "1.379",
//           31.4,
//           70.0,
//           -8.0,
//           -12.0,
//           -15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         682: [
//           "2189",
//           0.6,
//           56.448,
//           0.6484,
//           0.651,
//           0.647467,
//           0.64745,
//           "0.647",
//           6.0,
//           70.0,
//           2.0,
//           5.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         683: [
//           "2096",
//           8.59,
//           7506.0,
//           8.953,
//           8.8286,
//           8.45107,
//           9.2271,
//           "8.389",
//           226.7,
//           70.0,
//           6.0,
//           -12.0,
//           -11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         684: [
//           "1877",
//           49.6,
//           44226.0,
//           49.844,
//           46.5845,
//           45.5887,
//           50.4017,
//           "45.608",
//           108.7,
//           70.0,
//           -13.0,
//           -8.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         685: [
//           "2188",
//           0.36,
//           53.131,
//           0.359,
//           0.3716,
//           0.381767,
//           0.386325,
//           "0.376",
//           3.3302,
//           70.0,
//           11.0,
//           38.0,
//           30.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         686: [
//           "0525",
//           1.33,
//           946.0,
//           1.3874,
//           1.3654,
//           1.3872,
//           1.413,
//           "1.393",
//           19.0,
//           70.0,
//           1.0,
//           10.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         687: [
//           "1326",
//           0.124,
//           0.2,
//           0.12842,
//           0.12852,
//           0.130533,
//           0.132875,
//           "0.129",
//           3.2185,
//           69.0,
//           2.0,
//           4.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         688: [
//           "0260",
//           0.023,
//           6.8,
//           0.02094,
//           0.0216,
//           0.02236,
//           0.02409,
//           "0.022",
//           1.367,
//           69.0,
//           4.0,
//           4.0,
//           -9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         689: [
//           "0148",
//           35.3,
//           6589.0,
//           37.595,
//           37.145,
//           36.9913,
//           38.2965,
//           "36.87",
//           391.4,
//           69.0,
//           18.0,
//           19.0,
//           16.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         690: [
//           "0053",
//           83.0,
//           8.3,
//           86.022,
//           87.5305,
//           88.383,
//           88.7763,
//           "88.122",
//           273.1,
//           69.0,
//           7.0,
//           5.0,
//           13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         691: [
//           "1802",
//           0.97,
//           427.0,
//           1.003,
//           1.0024,
//           0.992533,
//           1.0108,
//           "0.993",
//           5.7618,
//           69.0,
//           3.0,
//           8.0,
//           25.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         692: [
//           "0367",
//           0.87,
//           38.215,
//           0.891,
//           0.8819,
//           0.8832,
//           0.9221,
//           "0.883",
//           14.5,
//           69.0,
//           6.0,
//           14.0,
//           19.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         693: [
//           "9900",
//           1.78,
//           35.916,
//           2.354,
//           2.1836,
//           2.1674,
//           2.04085,
//           "2.187",
//           6.6216,
//           69.0,
//           -22.0,
//           -22.0,
//           -22.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         694: [
//           "1036",
//           2.28,
//           4.56,
//           2.4334,
//           2.4005,
//           2.48413,
//           2.4878,
//           "2.47",
//           8.8812,
//           69.0,
//           8.0,
//           7.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         695: [
//           "0083",
//           9.82,
//           4543.0,
//           9.9354,
//           9.915,
//           10.3569,
//           10.8781,
//           "10.151",
//           747.8,
//           69.0,
//           8.0,
//           10.0,
//           21.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         696: [
//           "3336",
//           1.41,
//           1.4,
//           1.4828,
//           1.3703,
//           1.43293,
//           1.49515,
//           "1.42",
//           16.9,
//           69.0,
//           0.0,
//           -3.0,
//           10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         697: [
//           "1116",
//           0.19,
//           39.8,
//           0.19526,
//           0.19082,
//           0.19522,
//           0.20288,
//           "0.193",
//           4.1002,
//           69.0,
//           10.0,
//           3.0,
//           30.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         698: [
//           "0852",
//           0.246,
//           1.4,
//           0.23656,
//           0.24293,
//           0.253227,
//           0.27162,
//           "0.249",
//           5.223400000000001,
//           69.0,
//           4.0,
//           24.0,
//           33.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         699: [
//           "3330",
//           0.96,
//           45.086,
//           1.0338,
//           0.9959,
//           0.979733,
//           1.00395,
//           "0.983",
//           2.8538,
//           69.0,
//           -4.0,
//           -11.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         700: [
//           "0719",
//           3.93,
//           297.0,
//           4.5212,
//           4.3213,
//           4.17407,
//           4.1507,
//           "4.171",
//           7.6635,
//           69.0,
//           1.0,
//           -2.0,
//           -10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         701: [
//           "1815",
//           0.76,
//           25.705,
//           1.344,
//           1.2639,
//           1.04747,
//           0.96075,
//           "1.086",
//           9.4078,
//           69.0,
//           -12.0,
//           -18.0,
//           -14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         702: [
//           "9968",
//           1.79,
//           1036.0,
//           1.9288,
//           1.9328,
//           1.93753,
//           1.9421,
//           "1.929",
//           94.0,
//           69.0,
//           3.0,
//           -1.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         703: [
//           "8095",
//           0.5,
//           52.125,
//           0.5796,
//           0.55225,
//           0.5287,
//           0.523875,
//           "0.533",
//           4.0723,
//           69.0,
//           -12.0,
//           -14.0,
//           -11.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         704: [
//           "0130",
//           0.29,
//           3.881,
//           0.2778,
//           0.2886,
//           0.304667,
//           0.315475,
//           "0.3",
//           0.8349,
//           69.0,
//           5.0,
//           7.0,
//           17.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         705: [
//           "0177",
//           7.75,
//           2173.0,
//           8.3342,
//           7.9924,
//           8.01093,
//           8.21905,
//           "7.973",
//           94.7,
//           69.0,
//           3.0,
//           -3.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         706: [
//           "0450",
//           1.18,
//           25.742000000000004,
//           1.2802,
//           1.282,
//           1.3036,
//           1.29835,
//           "1.295",
//           10.7,
//           69.0,
//           4.0,
//           13.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         707: [
//           "6812",
//           0.39,
//           8.73,
//           0.4223,
//           0.43285,
//           0.450433,
//           0.449475,
//           "0.442",
//           2.34,
//           69.0,
//           -12.0,
//           0.0,
//           18.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         708: [
//           "0546",
//           2.65,
//           2035.0,
//           2.9438,
//           2.8397,
//           2.78913,
//           2.7257,
//           "2.807",
//           67.1,
//           69.0,
//           2.0,
//           -1.0,
//           -10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         709: [
//           "9187",
//           2.596,
//           0.0,
//           2.63792,
//           2.70688,
//           2.75015,
//           2.78562,
//           "2.737",
//           0.0,
//           69.0,
//           5.0,
//           9.0,
//           16.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         710: [
//           "1599",
//           1.95,
//           386.0,
//           2.1356,
//           2.1037,
//           2.1442,
//           2.12645,
//           "2.159",
//           7.5647,
//           68.0,
//           -4.0,
//           -9.0,
//           -11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         711: [
//           "3899",
//           8.09,
//           5348.0,
//           9.9676,
//           10.1175,
//           10.1275,
//           9.5228,
//           "10.025",
//           163.99999999999997,
//           68.0,
//           -14.0,
//           -5.0,
//           -21.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         712: [
//           "3369",
//           1.19,
//           147.0,
//           1.2774,
//           1.2651,
//           1.27827,
//           1.28415,
//           "1.28",
//           9.8752,
//           68.0,
//           3.0,
//           1.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         713: [
//           "0196",
//           0.237,
//           170.0,
//           0.26232,
//           0.25783,
//           0.250453,
//           0.251585,
//           "0.253",
//           12.6,
//           68.0,
//           -13.0,
//           -17.0,
//           -15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         714: [
//           "2834",
//           254.0,
//           109.0,
//           277.594,
//           290.172,
//           288.6,
//           284.008,
//           "287.603",
//           0.0,
//           68.0,
//           4.0,
//           5.0,
//           7.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         715: [
//           "2098",
//           0.48,
//           551.0,
//           0.4667,
//           0.47385,
//           0.4912,
//           0.50745,
//           "0.482",
//           56.5,
//           68.0,
//           7.0,
//           5.0,
//           21.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         716: [
//           "1173",
//           0.054,
//           2.3,
//           0.0576,
//           0.05644,
//           0.0554867,
//           0.05799,
//           "0.057",
//           1.3597,
//           68.0,
//           23.0,
//           -1.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         717: [
//           "9834",
//           32.54,
//           27.896,
//           35.5948,
//           37.2126,
//           37.0267,
//           36.4636,
//           "36.904",
//           0.0,
//           68.0,
//           5.0,
//           5.0,
//           9.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         718: [
//           "0113",
//           3.95,
//           14.128,
//           4.2004,
//           4.2269,
//           4.22307,
//           4.28505,
//           "4.204",
//           15.5,
//           68.0,
//           7.0,
//           2.0,
//           10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         719: [
//           "0096",
//           0.99,
//           0.208,
//           0.9208,
//           0.9739,
//           0.982333,
//           0.99235,
//           "0.982",
//           6.3018,
//           68.0,
//           30.0,
//           28.0,
//           30.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         720: [
//           "0823",
//           64.1,
//           43455.0,
//           66.145,
//           67.3755,
//           68.0633,
//           69.93,
//           "67.518",
//           1352.5999999999997,
//           68.0,
//           8.0,
//           20.0,
//           24.0,
//           0.0,
//           "R",
//         ],
//       },
//       {
//         721: [
//           "0837",
//           3.43,
//           115.0,
//           3.5348,
//           3.5724,
//           3.57787,
//           3.62065,
//           "3.579",
//           8.5308,
//           68.0,
//           8.0,
//           5.0,
//           14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         722: [
//           "1470",
//           0.095,
//           1.107,
//           0.1179,
//           0.1173,
//           0.123813,
//           0.119325,
//           "0.122",
//           0.76,
//           68.0,
//           0.0,
//           -6.0,
//           29.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         723: [
//           "2060",
//           3.05,
//           74.812,
//           3.1288,
//           3.2785,
//           3.31593,
//           3.37225,
//           "3.302",
//           24.7,
//           68.0,
//           13.0,
//           14.0,
//           19.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         724: [
//           "1359",
//           1.31,
//           5184.0,
//           1.4328,
//           1.3624,
//           1.36973,
//           1.4055,
//           "1.363",
//           177.7,
//           68.0,
//           7.0,
//           1.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         725: [
//           "1848",
//           5.19,
//           117.0,
//           5.4962,
//           5.3757,
//           5.3856,
//           5.54025,
//           "5.377",
//           38.5,
//           68.0,
//           4.0,
//           0.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         726: [
//           "1884",
//           0.28,
//           1.7,
//           0.2819,
//           0.28465,
//           0.285867,
//           0.295125,
//           "0.287",
//           1.54,
//           68.0,
//           5.0,
//           12.0,
//           12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         727: [
//           "3322",
//           0.3,
//           9.25,
//           0.3289,
//           0.3295,
//           0.327367,
//           0.329825,
//           "0.331",
//           3.8532,
//           68.0,
//           12.0,
//           3.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         728: [
//           "8403",
//           0.86,
//           43.62,
//           0.9976,
//           1.0299,
//           1.065,
//           1.02895,
//           "-",
//           0.86,
//           68.0,
//           45.0,
//           -13.0,
//           -9.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         729: [
//           "0012",
//           31.25,
//           15440.0,
//           33.451,
//           33.204,
//           33.246,
//           34.1343,
//           "33.025",
//           1512.9,
//           68.0,
//           8.0,
//           7.0,
//           10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         730: [
//           "2193",
//           0.29,
//           2.074,
//           0.3055,
//           0.3322,
//           0.320667,
//           0.314625,
//           "0.321",
//           1.2174,
//           68.0,
//           13.0,
//           15.0,
//           10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         731: [
//           "3160",
//           12.46,
//           36198.0,
//           13.2382,
//           13.5115,
//           13.564,
//           13.4782,
//           "13.571",
//           0.0,
//           68.0,
//           7.0,
//           12.0,
//           3.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         732: [
//           "0685",
//           0.28,
//           3.194,
//           0.2827,
//           0.29285,
//           0.3008,
//           0.30495,
//           "0.298",
//           4.7242,
//           68.0,
//           11.0,
//           12.0,
//           17.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         733: [
//           "0081",
//           4.01,
//           2522.0,
//           4.5446,
//           4.1539,
//           4.37387,
//           4.4763,
//           "4.32",
//           137.2,
//           68.0,
//           0.0,
//           -10.0,
//           -13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         734: [
//           "0035",
//           2.46,
//           229.0,
//           2.7892,
//           2.6698,
//           2.7106,
//           2.78205,
//           "2.672",
//           59.5,
//           67.0,
//           0.0,
//           0.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         735: [
//           "0051",
//           6.65,
//           1.995,
//           6.7968,
//           6.8852,
//           7.15227,
//           7.43955,
//           "7.039",
//           47.1,
//           67.0,
//           14.0,
//           15.0,
//           22.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         736: [
//           "3866",
//           3.8,
//           2.281,
//           4.03026,
//           4.09105,
//           4.09464,
//           4.21333,
//           "4.065",
//           87.0,
//           67.0,
//           3.0,
//           23.0,
//           17.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         737: [
//           "1663",
//           0.126,
//           14.491,
//           0.1578,
//           0.14422,
//           0.140973,
//           0.14397,
//           "0.14",
//           3.1046,
//           67.0,
//           9.0,
//           -5.0,
//           -18.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         738: [
//           "0753",
//           5.03,
//           16397.0,
//           5.9364,
//           5.6481,
//           5.4994,
//           5.5534,
//           "5.552",
//           229.5,
//           67.0,
//           0.0,
//           0.0,
//           -13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         739: [
//           "0393",
//           0.75,
//           26.6,
//           0.8048,
//           0.8157,
//           0.8124,
//           0.8101,
//           "0.813",
//           11.4,
//           67.0,
//           3.0,
//           2.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         740: [
//           "0363",
//           10.7,
//           2260.0,
//           11.6304,
//           11.5178,
//           11.6727,
//           11.6589,
//           "11.677",
//           116.3,
//           67.0,
//           5.0,
//           2.0,
//           -8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         741: [
//           "1203",
//           0.63,
//           67.652,
//           0.6868,
//           0.679,
//           0.687067,
//           0.6911,
//           "0.687",
//           5.7178,
//           67.0,
//           0.0,
//           1.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         742: [
//           "0211",
//           0.245,
//           0.365,
//           0.2807,
//           0.27498,
//           0.28732,
//           0.289015,
//           "0.284",
//           1.7378,
//           67.0,
//           0.0,
//           1.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         743: [
//           "0032",
//           10.9,
//           49.349,
//           11.006,
//           11.1564,
//           11.2675,
//           11.651,
//           "11.131",
//           40.6,
//           67.0,
//           8.0,
//           13.0,
//           18.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         744: [
//           "6885",
//           3.61,
//           197.0,
//           4.0806,
//           3.9872,
//           4.17993,
//           4.0411,
//           "4.166",
//           19.3,
//           67.0,
//           4.0,
//           0.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         745: [
//           "6198",
//           3.91,
//           1006.0,
//           4.2882,
//           4.1611,
//           4.1312,
//           4.1791,
//           "4.143",
//           42.9,
//           67.0,
//           0.0,
//           -5.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         746: [
//           "8417",
//           0.027,
//           1.134,
//           0.02976,
//           0.02932,
//           0.02946,
//           0.03001,
//           "0.03",
//           0.4726,
//           67.0,
//           -5.0,
//           -1.0,
//           -18.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         747: [
//           "0659",
//           7.17,
//           2715.0,
//           7.633,
//           7.5711,
//           7.53033,
//           7.7065,
//           "7.518",
//           280.4,
//           67.0,
//           3.0,
//           8.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         748: [
//           "1972",
//           19.06,
//           5248.0,
//           20.3622,
//           20.2047,
//           20.4549,
//           21.0982,
//           "20.22",
//           1115.0,
//           67.0,
//           11.0,
//           5.0,
//           12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         749: [
//           "8229",
//           0.173,
//           3.23,
//           0.24246,
//           0.25807,
//           0.239167,
//           0.22634,
//           "0.239",
//           0.692,
//           67.0,
//           -20.0,
//           -24.0,
//           -21.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         750: [
//           "0158",
//           140.2,
//           27.99,
//           144.524,
//           145.698,
//           147.837,
//           150.495,
//           "146.807",
//           35.0,
//           67.0,
//           7.0,
//           4.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         751: [
//           "8225",
//           0.395,
//           89.687,
//           0.3505,
//           0.3939,
//           0.409833,
//           0.4107,
//           "0.412",
//           3.9316,
//           67.0,
//           36.0,
//           33.0,
//           39.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         752: [
//           "0745",
//           0.171,
//           2.434,
//           0.1759,
//           0.17463,
//           0.17012,
//           0.17368,
//           "0.172",
//           1.2503,
//           67.0,
//           4.0,
//           1.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         753: [
//           "2320",
//           0.235,
//           2.784,
//           0.25512,
//           0.24627,
//           0.24732,
//           0.257465,
//           "0.243",
//           1.9214,
//           67.0,
//           2.0,
//           -12.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         754: [
//           "3086",
//           27.14,
//           51.602,
//           29.7144,
//           31.1004,
//           30.9504,
//           30.4742,
//           "30.829",
//           0.0,
//           67.0,
//           5.0,
//           4.0,
//           7.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         755: [
//           "2030",
//           2.7,
//           20.136,
//           2.846,
//           2.9635,
//           3.08007,
//           3.1832,
//           "3.043",
//           18.0,
//           67.0,
//           9.0,
//           9.0,
//           27.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         756: [
//           "3395",
//           0.4,
//           10.5,
//           0.4979,
//           0.49955,
//           0.489967,
//           0.465875,
//           "0.495",
//           1.5915,
//           67.0,
//           -19.0,
//           2.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         757: [
//           "0215",
//           1.21,
//           337.0,
//           1.2832,
//           1.2792,
//           1.28187,
//           1.3486,
//           "1.263",
//           58.3,
//           66.0,
//           3.0,
//           5.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         758: [
//           "3900",
//           10.04,
//           8557.0,
//           12.5024,
//           12.0808,
//           11.7133,
//           11.2634,
//           "11.912",
//           251.1,
//           66.0,
//           -8.0,
//           -20.0,
//           -16.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         759: [
//           "0062",
//           12.5,
//           46.68,
//           12.802,
//           12.873,
//           13.2381,
//           13.7115,
//           "13.125",
//           58.1,
//           66.0,
//           6.0,
//           15.0,
//           22.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         760: [
//           "1668",
//           0.58,
//           615.0,
//           0.6622,
//           0.60575,
//           0.638433,
//           0.682725,
//           "0.63",
//           46.9,
//           66.0,
//           -1.0,
//           2.0,
//           8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         761: [
//           "3768",
//           1.72,
//           25.516,
//           1.876,
//           1.8579,
//           1.8806,
//           1.91455,
//           "1.871",
//           5.8483,
//           66.0,
//           1.0,
//           -1.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         762: [
//           "1210",
//           0.15,
//           0.483,
//           0.16976,
//           0.16954,
//           0.162627,
//           0.164,
//           "0.162",
//           1.5152,
//           66.0,
//           24.0,
//           2.0,
//           -11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         763: [
//           "9086",
//           3.466,
//           0.542,
//           3.80856,
//           3.98864,
//           3.97229,
//           3.91375,
//           "3.956",
//           0.0,
//           66.0,
//           5.0,
//           5.0,
//           7.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         764: [
//           "6100",
//           15.5,
//           394.0,
//           18.6832,
//           16.6276,
//           15.3656,
//           16.0809,
//           "15.454",
//           81.09999999999998,
//           66.0,
//           -8.0,
//           -16.0,
//           -23.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         765: [
//           "0366",
//           1.18,
//           13.494,
//           1.296,
//           1.2852,
//           1.31153,
//           1.348,
//           "1.294",
//           5.9301,
//           66.0,
//           3.0,
//           8.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         766: [
//           "3005",
//           21.02,
//           26.843,
//           22.99,
//           23.464,
//           23.5557,
//           23.2428,
//           "23.504",
//           0.0,
//           66.0,
//           3.0,
//           -2.0,
//           -8.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         767: [
//           "0686",
//           0.25,
//           181.0,
//           0.2698,
//           0.27173,
//           0.27852,
//           0.27672,
//           "0.28",
//           56.0,
//           66.0,
//           -1.0,
//           1.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         768: [
//           "2878",
//           0.57,
//           685.0,
//           0.767,
//           0.7745,
//           0.809,
//           0.8485,
//           "0.782",
//           14.2,
//           66.0,
//           -5.0,
//           -10.0,
//           -15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         769: [
//           "6033",
//           2.74,
//           110.0,
//           3.3938,
//           3.3848,
//           3.35353,
//           3.26075,
//           "3.329",
//           11.0,
//           66.0,
//           -4.0,
//           3.0,
//           -22.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         770: [
//           "6881",
//           4.18,
//           12783.0,
//           4.5484,
//           4.4845,
//           4.46313,
//           4.46965,
//           "4.485",
//           154.2,
//           66.0,
//           5.0,
//           6.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         771: [
//           "0237",
//           3.03,
//           9.29,
//           3.2506,
//           3.2956,
//           3.34847,
//           3.43345,
//           "3.301",
//           12.2,
//           66.0,
//           2.0,
//           4.0,
//           10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         772: [
//           "2016",
//           3.11,
//           64.96,
//           3.2064,
//           3.186,
//           3.2156,
//           3.3596,
//           "3.208",
//           141.6,
//           66.0,
//           7.0,
//           1.0,
//           12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         773: [
//           "8246",
//           0.195,
//           1.108,
//           0.19628,
//           0.20238,
//           0.20704,
//           0.212025,
//           "0.206",
//           7.1505,
//           66.0,
//           9.0,
//           8.0,
//           15.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         774: [
//           "1786",
//           0.78,
//           48.005,
//           0.8712,
//           0.8467,
//           0.852,
//           0.86215,
//           "0.849",
//           4.1488,
//           66.0,
//           3.0,
//           3.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         775: [
//           "3398",
//           0.3,
//           6.66,
//           0.3169,
//           0.3279,
//           0.325233,
//           0.32425,
//           "0.325",
//           6.2994,
//           66.0,
//           -2.0,
//           -8.0,
//           6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         776: [
//           "8249",
//           0.1,
//           0.65,
//           0.10906,
//           0.13152,
//           0.134427,
//           0.125605,
//           "0.133",
//           0.13,
//           66.0,
//           43.0,
//           34.0,
//           21.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         777: [
//           "3666",
//           0.06,
//           5.312,
//           0.06806,
//           0.06496,
//           0.0638467,
//           0.06559,
//           "0.064",
//           1.3278,
//           66.0,
//           12.0,
//           -11.0,
//           6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         778: [
//           "0087",
//           7.42,
//           975.0,
//           7.6942,
//           7.7568,
//           7.9204,
//           8.1552,
//           "7.847",
//           221.2,
//           66.0,
//           7.0,
//           21.0,
//           25.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         779: [
//           "3636",
//           4.31,
//           36.915,
//           4.7432,
//           4.7973,
//           4.83367,
//           4.73595,
//           "4.968",
//           3.8551,
//           66.0,
//           -1.0,
//           -4.0,
//           -20.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         780: [
//           "0048",
//           0.73,
//           4.94,
//           0.829,
//           0.8506,
//           0.857667,
//           0.8426,
//           "0.845",
//           1.2206,
//           65.0,
//           11.0,
//           9.0,
//           -12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         781: [
//           "1921",
//           1.6,
//           965.0,
//           3.3156,
//           2.7767,
//           2.49667,
//           2.3219,
//           "2.52",
//           24.0,
//           65.0,
//           -29.0,
//           -32.0,
//           -32.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         782: [
//           "8032",
//           0.88,
//           781.0,
//           1.3842,
//           1.48,
//           1.49153,
//           1.4335,
//           "1.472",
//           84.70000000000002,
//           65.0,
//           -16.0,
//           -27.0,
//           -27.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         783: [
//           "2322",
//           0.87,
//           237.0,
//           0.9072,
//           0.9128,
//           0.926,
//           0.9486,
//           "0.916",
//           35.8,
//           65.0,
//           3.0,
//           12.0,
//           10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         784: [
//           "0887",
//           0.171,
//           39.894,
//           0.19702,
//           0.20019,
//           0.200067,
//           0.204075,
//           "0.198",
//           11.5,
//           65.0,
//           -1.0,
//           5.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         785: [
//           "1561",
//           1.88,
//           48.46,
//           1.9852,
//           1.9774,
//           2.0912,
//           2.0746,
//           "2.056",
//           14.3,
//           65.0,
//           -1.0,
//           4.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         786: [
//           "7374",
//           6.97,
//           12.234,
//           7.3589,
//           7.5003,
//           "-",
//           "-",
//           "-",
//           0.0,
//           65.0,
//           9.0,
//           12.0,
//           7.0,
//           1.0,
//           "E",
//         ],
//       },
//       {
//         787: [
//           "8035",
//           0.22,
//           0.44,
//           0.19476,
//           0.20931,
//           0.219567,
//           0.233575,
//           "0.216",
//           1.32,
//           65.0,
//           5.0,
//           19.0,
//           42.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         788: [
//           "0408",
//           3.78,
//           108.0,
//           4.2082,
//           4.2867,
//           4.48753,
//           4.50305,
//           "4.394",
//           21.4,
//           65.0,
//           1.0,
//           -1.0,
//           -7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         789: [
//           "9878",
//           40.5,
//           901.0,
//           "-",
//           "-",
//           "-",
//           "-",
//           "-",
//           72.0,
//           65.0,
//           -3.0,
//           -1.0,
//           -15.0,
//           1.0,
//           "M",
//         ],
//       },
//       {
//         790: [
//           "1330",
//           3.53,
//           1248.0,
//           3.9224,
//           3.9831,
//           4.0192,
//           3.9019,
//           "4.043",
//           14.2,
//           65.0,
//           -6.0,
//           -8.0,
//           -12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         791: [
//           "1572",
//           0.189,
//           1.442,
//           0.22828,
//           0.26348,
//           0.28612,
//           0.260235,
//           "0.277",
//           3.1714,
//           65.0,
//           3.0,
//           2.0,
//           19.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         792: [
//           "1083",
//           4.53,
//           3808.0,
//           5.8696,
//           5.7531,
//           5.515,
//           5.46775,
//           "5.502",
//           143.1,
//           65.0,
//           -11.0,
//           -16.0,
//           -22.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         793: [
//           "0334",
//           0.47,
//           85.912,
//           0.4932,
//           0.52035,
//           0.546433,
//           0.555475,
//           "0.537",
//           9.9372,
//           65.0,
//           2.0,
//           26.0,
//           25.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         794: [
//           "0778",
//           7.1,
//           3366.0,
//           7.8626,
//           7.958,
//           8.03193,
//           8.12295,
//           "7.933",
//           140.1,
//           65.0,
//           8.0,
//           13.0,
//           15.0,
//           0.0,
//           "R",
//         ],
//       },
//       {
//         795: [
//           "1692",
//           1.85,
//           5.188,
//           2.005,
//           1.9923,
//           2.05953,
//           2.0666,
//           "2.045",
//           6.6415,
//           65.0,
//           0.0,
//           -4.0,
//           -15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         796: [
//           "2080",
//           0.56,
//           20.024,
//           0.7328,
//           0.7436,
//           0.7268,
//           0.684875,
//           "0.717",
//           2.7607,
//           65.0,
//           -20.0,
//           -22.0,
//           -25.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         797: [
//           "3390",
//           1.27,
//           183.0,
//           1.2964,
//           1.3261,
//           1.3826,
//           1.45375,
//           "1.364",
//           10.1,
//           65.0,
//           6.0,
//           4.0,
//           28.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         798: [
//           "0026",
//           91.5,
//           43.992,
//           94.79,
//           97.617,
//           100.619,
//           102.594,
//           "99.763",
//           41.4,
//           65.0,
//           5.0,
//           10.0,
//           14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         799: [
//           "0533",
//           1.53,
//           5.042,
//           1.613,
//           1.6401,
//           1.65673,
//           1.69005,
//           "1.647",
//           15.0,
//           65.0,
//           5.0,
//           5.0,
//           12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         800: [
//           "0610",
//           3.51,
//           33.054,
//           3.8246,
//           3.7903,
//           3.82087,
//           3.9004,
//           "3.794",
//           27.8,
//           65.0,
//           4.0,
//           -3.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         801: [
//           "1897",
//           0.7,
//           2.302,
//           0.8002,
//           0.79068,
//           0.804053,
//           0.81069,
//           "0.799",
//           2.9307,
//           65.0,
//           -3.0,
//           -19.0,
//           -15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         802: [
//           "0133",
//           9.0,
//           292.0,
//           9.5956,
//           9.4722,
//           9.46433,
//           9.57425,
//           "9.469",
//           13.7,
//           65.0,
//           2.0,
//           1.0,
//           2.0,
//           0.0,
//           "-",
//         ],
//       },
//       {
//         803: [
//           "1792",
//           0.07,
//           5.19,
//           0.07754,
//           0.09118,
//           0.09182,
//           0.08122,
//           "0.098",
//           1.2642,
//           65.0,
//           14.0,
//           18.0,
//           -18.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         804: [
//           "2368",
//           4.3,
//           113.0,
//           4.6568,
//           4.6557,
//           4.7232,
//           4.82525,
//           "4.69",
//           22.9,
//           64.0,
//           4.0,
//           -3.0,
//           -8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         805: [
//           "0206",
//           0.25,
//           13.362,
//           0.362,
//           0.3346,
//           0.327067,
//           0.30689,
//           "0.334",
//           8.1085,
//           64.0,
//           -8.0,
//           -25.0,
//           -29.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         806: [
//           "0673",
//           0.056,
//           0.0,
//           0.05316,
//           0.05881,
//           0.0626,
//           0.06505,
//           "0.062",
//           2.4583,
//           64.0,
//           5.0,
//           17.0,
//           39.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         807: [
//           "1490",
//           0.72,
//           487.0,
//           0.6854,
//           0.7247,
//           0.7222,
//           0.7695,
//           "0.707",
//           8.8891,
//           64.0,
//           12.0,
//           25.0,
//           45.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         808: [
//           "8450",
//           0.06,
//           3.302,
//           0.06476,
//           0.06576,
//           0.0674133,
//           0.067435,
//           "0.067",
//           0.6,
//           64.0,
//           7.0,
//           1.0,
//           0.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         809: [
//           "6680",
//           25.2,
//           985.0,
//           "-",
//           "-",
//           "-",
//           "-",
//           "-",
//           31.6,
//           64.0,
//           -5.0,
//           -3.0,
//           -16.0,
//           1.0,
//           "M",
//         ],
//       },
//       {
//         810: [
//           "1433",
//           0.052,
//           4.003,
//           0.0582,
//           0.06142,
//           0.0601333,
//           0.05896,
//           "0.06",
//           1.04,
//           64.0,
//           5.0,
//           5.0,
//           22.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         811: [
//           "3788",
//           1.27,
//           536.0,
//           1.4684,
//           1.4242,
//           1.4888,
//           1.4565,
//           "1.484",
//           24.8,
//           64.0,
//           -6.0,
//           -5.0,
//           -17.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         812: [
//           "6819",
//           6.6,
//           233.0,
//           7.246,
//           7.1367,
//           7.42933,
//           7.6747,
//           "7.368",
//           29.8,
//           64.0,
//           6.0,
//           8.0,
//           10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         813: [
//           "0683",
//           20.3,
//           7268.0,
//           21.736,
//           21.3198,
//           22.1193,
//           22.8242,
//           "21.96",
//           295.2,
//           64.0,
//           -2.0,
//           1.0,
//           14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         814: [
//           "3838",
//           0.167,
//           123.0,
//           0.2011,
//           0.18675,
//           0.184833,
//           0.18276,
//           "0.183",
//           10.0,
//           64.0,
//           -5.0,
//           -3.0,
//           -10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         815: [
//           "3799",
//           3.92,
//           1848.0,
//           4.2676,
//           4.2203,
//           4.24227,
//           4.3309,
//           "4.237",
//           536.8,
//           64.0,
//           6.0,
//           4.0,
//           7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         816: [
//           "2660",
//           1.12,
//           144.0,
//           1.2944,
//           1.2789,
//           1.25633,
//           1.27855,
//           "1.249",
//           11.3,
//           64.0,
//           -8.0,
//           -7.0,
//           -13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         817: [
//           "1059",
//           0.38,
//           0.231,
//           0.4666,
//           0.50485,
//           0.5007,
//           0.47745,
//           "0.504",
//           0.8247,
//           64.0,
//           8.0,
//           9.0,
//           -9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         818: [
//           "0199",
//           1.0,
//           14.907,
//           1.0994,
//           1.1173,
//           1.07327,
//           1.04825,
//           "1.084",
//           9.6017,
//           64.0,
//           5.0,
//           3.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         819: [
//           "1985",
//           0.88,
//           2.998,
//           0.9206,
//           0.9671,
//           0.956933,
//           0.9709,
//           "0.952",
//           2.64,
//           64.0,
//           2.0,
//           3.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         820: [
//           "3110",
//           24.5,
//           3423.0,
//           26.4188,
//           25.7682,
//           26.2223,
//           26.774,
//           "26.089",
//           0.0,
//           64.0,
//           2.0,
//           -1.0,
//           -3.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         821: [
//           "3021",
//           7.095,
//           0.283,
//           "-",
//           "-",
//           "-",
//           "-",
//           "-",
//           0.0,
//           64.0,
//           6.0,
//           12.0,
//           10.0,
//           1.0,
//           "E",
//         ],
//       },
//       {
//         822: [
//           "1071",
//           2.44,
//           6221.0,
//           2.932,
//           2.892,
//           2.8742,
//           2.7416,
//           "2.921",
//           41.90000000000001,
//           64.0,
//           -8.0,
//           -17.0,
//           -26.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         823: [
//           "0622",
//           0.5,
//           63.211,
//           0.5324,
//           0.5424,
//           0.532467,
//           0.535975,
//           "0.533",
//           30.5,
//           64.0,
//           12.0,
//           12.0,
//           19.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         824: [
//           "0071",
//           12.3,
//           29.666,
//           12.9228,
//           12.893,
//           13.1557,
//           13.6711,
//           "13.013",
//           84.9,
//           64.0,
//           6.0,
//           7.0,
//           8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         825: [
//           "8126",
//           0.219,
//           1.051,
//           0.25502,
//           0.2574,
//           0.248433,
//           0.244845,
//           "0.25",
//           1.043,
//           64.0,
//           1.0,
//           3.0,
//           -9.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         826: [
//           "0723",
//           0.021,
//           45.594,
//           0.0258,
//           0.02215,
//           0.0222667,
//           0.025745,
//           "0.022",
//           1.9136,
//           64.0,
//           -15.0,
//           -14.0,
//           -11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         827: [
//           "0080",
//           0.214,
//           80.818,
//           0.221341,
//           0.23511,
//           0.214627,
//           0.213025,
//           "0.219",
//           2.7741,
//           63.0,
//           -5.0,
//           15.0,
//           -17.0,
//           0.0,
//           "-",
//         ],
//       },
//       {
//         828: [
//           "2205",
//           3.23,
//           56.32,
//           3.558,
//           3.591,
//           3.5948,
//           "-",
//           "3.59",
//           22.6,
//           63.0,
//           -6.0,
//           -6.0,
//           0.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         829: [
//           "8392",
//           0.1,
//           55.908,
//           0.1144,
//           0.11319,
//           0.10836,
//           0.109355,
//           "0.11",
//           1.0,
//           63.0,
//           2.0,
//           4.0,
//           -21.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         830: [
//           "3848",
//           6.28,
//           3.768,
//           6.5598,
//           6.6701,
//           6.75653,
//           6.87,
//           "6.635",
//           9.8334,
//           63.0,
//           8.0,
//           12.0,
//           15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         831: [
//           "2686",
//           1.26,
//           2358.0,
//           1.5122,
//           1.5203,
//           1.62253,
//           1.5255,
//           "1.64",
//           42.7,
//           63.0,
//           -5.0,
//           -21.0,
//           -21.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         832: [
//           "3316",
//           18.9,
//           1483.0,
//           22.914,
//           22.2968,
//           22.3231,
//           24.3686,
//           "22.139",
//           52.2,
//           63.0,
//           -10.0,
//           -21.0,
//           -24.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         833: [
//           "0959",
//           0.395,
//           1.93,
//           0.3985,
//           0.40555,
//           0.4106,
//           0.417975,
//           "0.409",
//           0.5065,
//           63.0,
//           7.0,
//           22.0,
//           20.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         834: [
//           "3191",
//           55.28,
//           704.0,
//           59.766,
//           63.419,
//           64.2395,
//           64.3209,
//           "63.461",
//           0.0,
//           63.0,
//           3.0,
//           9.0,
//           0.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         835: [
//           "1388",
//           0.96,
//           2.2,
//           1.031,
//           1.0347,
//           1.05313,
//           1.11445,
//           "1.046",
//           4.0551,
//           63.0,
//           9.0,
//           8.0,
//           13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         836: [
//           "8405",
//           0.82,
//           0.324,
//           0.8832,
//           0.9193,
//           0.8958,
//           0.9103,
//           "0.899",
//           3.28,
//           63.0,
//           4.0,
//           -3.0,
//           1.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         837: [
//           "8250",
//           0.016,
//           34.904,
//           0.02174,
//           0.02033,
//           0.0194933,
//           0.018895,
//           "0.02",
//           1.1988,
//           63.0,
//           -18.0,
//           -8.0,
//           -25.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         838: [
//           "3686",
//           0.48,
//           10.23,
//           0.498,
//           0.5068,
//           0.516,
//           0.529,
//           "0.511",
//           4.8756,
//           63.0,
//           3.0,
//           6.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         839: [
//           "6030",
//           17.22,
//           31532.0,
//           19.7119,
//           19.6704,
//           19.6436,
//           19.5235,
//           "19.728",
//           451.1,
//           63.0,
//           3.0,
//           8.0,
//           -10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         840: [
//           "0331",
//           5.16,
//           510.0,
//           5.8378,
//           6.111,
//           6.14547,
//           6.2603,
//           "6.137",
//           23.2,
//           63.0,
//           -1.0,
//           1.0,
//           -14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         841: [
//           "0508",
//           0.08,
//           0.395,
//           0.088,
//           0.09159,
//           0.0943867,
//           0.093105,
//           "0.094",
//           5.8854,
//           63.0,
//           5.0,
//           22.0,
//           8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         842: [
//           "1119",
//           4.38,
//           2958.0,
//           5.4318,
//           5.6962,
//           5.30627,
//           5.34295,
//           "5.324",
//           62.2,
//           63.0,
//           -8.0,
//           -13.0,
//           -22.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         843: [
//           "0662",
//           3.25,
//           15.69,
//           3.5784,
//           3.5701,
//           3.5248,
//           3.55345,
//           "3.536",
//           30.5,
//           63.0,
//           -5.0,
//           -2.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         844: [
//           "0232",
//           0.112,
//           48.284,
//           0.12336,
//           0.12107,
//           0.12492,
//           0.127145,
//           "0.123",
//           10.4,
//           63.0,
//           -10.0,
//           -12.0,
//           -5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         845: [
//           "1044",
//           40.05,
//           16749.0,
//           40.447,
//           40.119,
//           41.0803,
//           43.4943,
//           "40.678",
//           465.4,
//           63.0,
//           11.0,
//           10.0,
//           6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         846: [
//           "0455",
//           0.205,
//           18.114,
//           0.2471,
//           0.25762,
//           0.2405,
//           0.23468,
//           "0.246",
//           4.4075,
//           63.0,
//           -4.0,
//           -15.0,
//           -11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         847: [
//           "0968",
//           13.7,
//           83831.0,
//           13.1848,
//           13.816,
//           14.7175,
//           14.8362,
//           "14.554",
//           1218.1,
//           63.0,
//           -8.0,
//           4.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         848: [
//           "1047",
//           0.52,
//           20.28,
//           0.6134,
//           0.6439,
//           0.6338,
//           0.59965,
//           "0.635",
//           1.9198,
//           63.0,
//           0.0,
//           -5.0,
//           -18.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         849: [
//           "0991",
//           1.21,
//           7624.0,
//           1.5062,
//           1.4533,
//           1.43353,
//           1.3846,
//           "1.445",
//           73.9,
//           63.0,
//           -19.0,
//           -20.0,
//           -27.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         850: [
//           "1425",
//           0.39,
//           141.0,
//           0.4328,
//           0.4494,
//           0.4748,
//           0.488375,
//           "0.462",
//           4.875,
//           62.0,
//           11.0,
//           -2.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         851: [
//           "0570",
//           3.65,
//           10288.0,
//           4.7824,
//           4.2649,
//           4.11047,
//           4.3254,
//           "4.081",
//           183.8,
//           62.0,
//           -4.0,
//           -15.0,
//           -20.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         852: [
//           "3118",
//           19.08,
//           0.0,
//           20.4212,
//           20.9544,
//           20.944,
//           21.0949,
//           "20.861",
//           0.0,
//           62.0,
//           7.0,
//           7.0,
//           7.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         853: [
//           "1310",
//           8.87,
//           4644.0,
//           9.818,
//           9.6921,
//           9.535,
//           9.4969,
//           "9.581",
//           116.3,
//           62.0,
//           4.0,
//           0.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         854: [
//           "3699",
//           0.495,
//           2.168,
//           0.5031,
//           0.50925,
//           0.524233,
//           0.54656,
//           "0.517",
//           2.1849,
//           62.0,
//           15.0,
//           11.0,
//           15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         855: [
//           "0568",
//           3.46,
//           551.0,
//           4.3954,
//           4.2791,
//           4.43427,
//           4.81445,
//           "4.432",
//           8.8619,
//           62.0,
//           -4.0,
//           -22.0,
//           -30.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         856: [
//           "0055",
//           0.39,
//           3.02,
//           0.3897,
//           0.41595,
//           0.431933,
//           0.446425,
//           "0.424",
//           0.9891,
//           62.0,
//           26.0,
//           28.0,
//           26.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         857: [
//           "1825",
//           0.234,
//           73.372,
//           0.18346,
//           0.18495,
//           0.19338,
//           0.631385,
//           "0.192",
//           0.468,
//           62.0,
//           20.0,
//           37.0,
//           48.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         858: [
//           "0101",
//           15.52,
//           8501.0,
//           16.3444,
//           16.5348,
//           17.2633,
//           17.7918,
//           "16.945",
//           698.2,
//           62.0,
//           -1.0,
//           -4.0,
//           14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         859: [
//           "0294",
//           1.85,
//           5.735,
//           1.9908,
//           1.9916,
//           2.01287,
//           2.04555,
//           "1.999",
//           3.8248,
//           62.0,
//           6.0,
//           0.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         860: [
//           "3689",
//           2.65,
//           0.809,
//           2.5774,
//           2.6311,
//           2.79613,
//           3.10825,
//           "2.674",
//           2.2364,
//           62.0,
//           4.0,
//           35.0,
//           34.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         861: [
//           "2175",
//           3.63,
//           316.0,
//           3.7818,
//           3.9988,
//           4.09147,
//           "-",
//           "4.047",
//           18.3,
//           62.0,
//           7.0,
//           16.0,
//           32.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         862: [
//           "2307",
//           0.36,
//           0.77,
//           0.3856,
//           0.4,
//           0.395467,
//           0.3981,
//           "0.392",
//           3.1317,
//           62.0,
//           8.0,
//           25.0,
//           13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         863: [
//           "1045",
//           2.05,
//           319.0,
//           2.3498,
//           2.3167,
//           2.3374,
//           2.35915,
//           "2.328",
//           19.0,
//           62.0,
//           -2.0,
//           -7.0,
//           -8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         864: [
//           "0405",
//           3.24,
//           2334.0,
//           3.2654,
//           3.29997,
//           3.48678,
//           3.62483,
//           "3.436",
//           150.7,
//           62.0,
//           4.0,
//           20.0,
//           23.0,
//           0.0,
//           "R",
//         ],
//       },
//       {
//         865: [
//           "0019",
//           43.4,
//           7801.0,
//           45.697,
//           46.0435,
//           47.4403,
//           49.1113,
//           "46.96",
//           392.8,
//           62.0,
//           7.0,
//           20.0,
//           26.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         866: [
//           "0682",
//           0.043,
//           7.656,
//           0.04368,
//           0.04292,
//           0.0447333,
//           0.047845,
//           "0.044",
//           1.4171,
//           62.0,
//           -4.0,
//           17.0,
//           25.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         867: [
//           "0160",
//           2.69,
//           16.146,
//           2.8312,
//           2.8653,
//           2.99747,
//           3.16885,
//           "2.94",
//           19.3,
//           62.0,
//           3.0,
//           9.0,
//           12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         868: [
//           "1251",
//           0.25,
//           129.0,
//           0.2868,
//           0.28205,
//           0.282773,
//           0.28318,
//           "0.286",
//           4.6344,
//           62.0,
//           -7.0,
//           -10.0,
//           -18.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         869: [
//           "3613",
//           9.76,
//           1067.0,
//           11.7604,
//           11.1708,
//           10.9907,
//           11.2429,
//           "10.936",
//           81.7,
//           62.0,
//           -13.0,
//           0.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         870: [
//           "8635",
//           0.109,
//           1.168,
//           0.1333,
//           0.13591,
//           0.1358,
//           0.131945,
//           "0.133",
//           0.436,
//           62.0,
//           -1.0,
//           -9.0,
//           -19.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         871: [
//           "1240",
//           0.65,
//           7.13,
//           0.6794,
//           0.6802,
//           0.7034,
//           0.7258,
//           "0.694",
//           9.869,
//           62.0,
//           2.0,
//           6.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         872: [
//           "1749",
//           0.57,
//           1.415,
//           0.6222,
//           0.6908,
//           0.7486,
//           0.742475,
//           "0.714",
//           0.1903,
//           62.0,
//           7.0,
//           16.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         873: [
//           "0298",
//           0.38,
//           25.905,
//           0.4099,
//           0.40235,
//           0.4155,
//           0.428925,
//           "0.41",
//           8.9187,
//           61.0,
//           10.0,
//           7.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         874: [
//           "6080",
//           0.1,
//           2.904,
//           0.10504,
//           0.11659,
//           0.1229,
//           0.119175,
//           "0.12",
//           0.9337,
//           61.0,
//           26.0,
//           4.0,
//           20.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         875: [
//           "0023",
//           11.76,
//           2883.0,
//           12.7824,
//           12.3508,
//           12.5115,
//           12.9745,
//           "12.449",
//           343.7,
//           61.0,
//           8.0,
//           3.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         876: [
//           "0480",
//           2.81,
//           42.428,
//           3.0704,
//           3.0483,
//           3.05407,
//           3.18215,
//           "3.049",
//           41.7,
//           61.0,
//           4.0,
//           0.0,
//           6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         877: [
//           "1651",
//           8.88,
//           15.348,
//           9.8306,
//           10.2643,
//           10.3913,
//           10.4743,
//           "10.282",
//           33.8,
//           61.0,
//           3.0,
//           0.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         878: [
//           "0297",
//           1.04,
//           3943.0,
//           1.1466,
//           1.157,
//           1.26327,
//           1.25525,
//           "1.228",
//           73.0,
//           61.0,
//           -6.0,
//           -19.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         879: [
//           "1986",
//           1.19,
//           222.0,
//           1.3188,
//           1.3919,
//           1.39667,
//           1.39205,
//           "1.39",
//           12.2,
//           61.0,
//           -1.0,
//           3.0,
//           18.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         880: [
//           "3034",
//           6.95,
//           88.702,
//           "-",
//           "-",
//           "-",
//           "-",
//           "-",
//           0.0,
//           61.0,
//           0.0,
//           4.0,
//           -7.0,
//           1.0,
//           "E",
//         ],
//       },
//       {
//         881: [
//           "1375",
//           1.32,
//           571.0,
//           1.463,
//           1.4417,
//           1.4512,
//           1.46145,
//           "1.447",
//           15.7,
//           61.0,
//           1.0,
//           1.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         882: [
//           "0679",
//           1.12,
//           11.23,
//           1.2074,
//           1.2363,
//           1.2068,
//           1.2031,
//           "1.21",
//           4.7763,
//           61.0,
//           3.0,
//           0.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         883: [
//           "0323",
//           2.88,
//           5204.0,
//           3.189,
//           3.1048,
//           3.3788,
//           3.403,
//           "3.338",
//           49.9,
//           61.0,
//           -4.0,
//           -18.0,
//           -17.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         884: [
//           "0229",
//           0.94,
//           3.76,
//           0.987,
//           1.0076,
//           1.02193,
//           1.04935,
//           "1.016",
//           4.7112,
//           61.0,
//           2.0,
//           3.0,
//           10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         885: [
//           "8507",
//           0.157,
//           0.32,
//           0.18844,
//           0.18867,
//           0.188173,
//           0.18719,
//           "0.186",
//           0.628,
//           61.0,
//           11.0,
//           10.0,
//           -14.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         886: [
//           "2668",
//           0.2,
//           18.086,
//           0.2155,
//           0.21894,
//           0.220553,
//           0.221425,
//           "0.22",
//           7.8,
//           61.0,
//           -19.0,
//           -7.0,
//           -5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         887: [
//           "1278",
//           0.083,
//           2.357,
//           0.08976,
//           0.08731,
//           0.0902867,
//           0.098325,
//           "0.089",
//           8.0727,
//           61.0,
//           3.0,
//           28.0,
//           15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         888: [
//           "1265",
//           0.415,
//           77.085,
//           0.4723,
//           0.44485,
//           0.439533,
//           0.4568,
//           "0.441",
//           2.0752,
//           61.0,
//           -8.0,
//           -20.0,
//           -11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         889: [
//           "1285",
//           1.4,
//           19.189,
//           1.5102,
//           1.4626,
//           1.52087,
//           1.57895,
//           "1.511",
//           5.81,
//           61.0,
//           -19.0,
//           -7.0,
//           -5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         890: [
//           "2161",
//           0.92,
//           27.35,
//           0.9688,
//           0.9237,
//           0.985067,
//           1.1208,
//           "0.968",
//           8.2219,
//           61.0,
//           7.0,
//           6.0,
//           19.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         891: [
//           "2722",
//           0.57,
//           141.0,
//           0.6618,
//           0.6777,
//           0.668333,
//           0.632375,
//           "0.684",
//           6.271,
//           61.0,
//           -12.0,
//           -23.0,
//           -24.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         892: [
//           "0521",
//           0.077,
//           28.683,
//           0.08338,
//           0.08699,
//           0.08608,
//           0.08601,
//           "0.087",
//           8.7779,
//           61.0,
//           6.0,
//           9.0,
//           18.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         893: [
//           "0039",
//           0.051,
//           9.104,
//           0.04572,
//           0.04902,
//           0.0530667,
//           0.056925,
//           "0.052",
//           3.1748,
//           61.0,
//           17.0,
//           19.0,
//           46.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         894: [
//           "8045",
//           0.042,
//           3.673,
//           0.04998,
//           0.04754,
//           0.0473667,
//           0.047645,
//           "0.047",
//           0.2121,
//           61.0,
//           14.0,
//           10.0,
//           -24.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         895: [
//           "2607",
//           13.18,
//           2920.0,
//           14.8868,
//           14.4518,
//           14.8172,
//           15.2736,
//           "14.693",
//           121.1,
//           61.0,
//           3.0,
//           9.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         896: [
//           "1299",
//           77.55,
//           352000.0,
//           82.48,
//           83.262,
//           86.0613,
//           88.4447,
//           "85.088",
//           9381.4,
//           61.0,
//           13.0,
//           20.0,
//           17.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         897: [
//           "1513",
//           26.3,
//           1956.0,
//           28.727,
//           27.5495,
//           28.0353,
//           29.7153,
//           "27.912",
//           82.3,
//           60.0,
//           -1.0,
//           -4.0,
//           -13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         898: [
//           "0380",
//           0.11,
//           5.11,
//           0.12418,
//           0.11946,
//           0.119227,
//           0.121885,
//           "0.118",
//           1.4654,
//           60.0,
//           -5.0,
//           -12.0,
//           -15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         899: [
//           "2165",
//           3.6,
//           20.914,
//           3.837,
//           3.9061,
//           4.012,
//           "-",
//           "3.983",
//           10.2,
//           60.0,
//           9.0,
//           6.0,
//           7.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         900: [
//           "6869",
//           9.84,
//           2541.0,
//           11.9348,
//           12.3908,
//           12.2737,
//           11.6567,
//           "12.24",
//           34.5,
//           60.0,
//           0.0,
//           -5.0,
//           -23.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         901: [
//           "0435",
//           3.8,
//           217.0,
//           4.2124,
//           4.3115,
//           4.4498,
//           4.50145,
//           "4.39",
//           63.7,
//           60.0,
//           6.0,
//           11.0,
//           11.0,
//           0.0,
//           "R",
//         ],
//       },
//       {
//         902: [
//           "1455",
//           0.202,
//           12.477,
//           0.2265,
//           0.23771,
//           0.247227,
//           0.256495,
//           "0.244",
//           2.5826,
//           60.0,
//           5.0,
//           0.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         903: [
//           "0582",
//           0.234,
//           61.099,
//           0.22312,
//           0.23259,
//           0.235173,
//           0.238515,
//           "0.235",
//           9.8894,
//           60.0,
//           20.0,
//           25.0,
//           25.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         904: [
//           "0131",
//           2.36,
//           4.996,
//           2.4836,
//           2.5119,
//           2.57293,
//           2.62305,
//           "2.562",
//           15.4,
//           60.0,
//           4.0,
//           3.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         905: [
//           "1195",
//           0.038,
//           5.205,
//           0.04406,
//           0.04602,
//           0.04492,
//           0.04539,
//           "0.045",
//           1.0997,
//           60.0,
//           7.0,
//           1.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         906: [
//           "1955",
//           1.0,
//           0.796,
//           1.028,
//           1.104,
//           1.17227,
//           1.196,
//           "1.157",
//           5.0,
//           60.0,
//           6.0,
//           10.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         907: [
//           "1496",
//           0.147,
//           2.074,
//           0.16878,
//           0.16526,
//           0.162947,
//           0.162875,
//           "0.164",
//           1.27,
//           60.0,
//           -13.0,
//           -14.0,
//           -17.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         908: [
//           "1518",
//           1.06,
//           0.261,
//           1.0364,
//           1.0376,
//           1.06433,
//           1.11165,
//           "1.054",
//           5.1942,
//           60.0,
//           23.0,
//           11.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         909: [
//           "6049",
//           45.2,
//           11148.0,
//           57.185,
//           54.047,
//           51.2843,
//           51.728,
//           "51.883",
//           69.3,
//           60.0,
//           -7.0,
//           -14.0,
//           -20.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         910: [
//           "2265",
//           0.77,
//           40.03,
//           0.869,
//           "-",
//           "-",
//           "-",
//           "-",
//           7.7,
//           60.0,
//           3.0,
//           -1.0,
//           -7.0,
//           1.0,
//           "M",
//         ],
//       },
//       {
//         911: [
//           "1163",
//           0.72,
//           1.927,
//           0.9056,
//           0.824,
//           0.8286,
//           0.8434,
//           "0.809",
//           4.482,
//           60.0,
//           -8.0,
//           -25.0,
//           -27.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         912: [
//           "1373",
//           2.41,
//           163.0,
//           2.6386,
//           2.6774,
//           2.72647,
//           2.80465,
//           "2.696",
//           17.4,
//           60.0,
//           6.0,
//           4.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         913: [
//           "0752",
//           1.14,
//           68.846,
//           1.2628,
//           1.2725,
//           1.2528,
//           1.27765,
//           "1.257",
//           14.1,
//           60.0,
//           7.0,
//           -3.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         914: [
//           "0538",
//           1.16,
//           64.327,
//           1.2474,
//           1.2626,
//           1.2984,
//           1.35885,
//           "1.287",
//           12.6,
//           60.0,
//           9.0,
//           3.0,
//           10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         915: [
//           "3382",
//           0.57,
//           180.0,
//           0.6328,
//           0.6311,
//           0.6352,
//           0.6334,
//           "0.638",
//           35.1,
//           60.0,
//           -2.0,
//           -6.0,
//           -8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         916: [
//           "1582",
//           0.49,
//           0.242,
//           0.5264,
//           0.52485,
//           0.5391,
//           0.552075,
//           "0.535",
//           2.45,
//           60.0,
//           1.0,
//           2.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         917: [
//           "8036",
//           0.088,
//           0.18,
//           0.08888,
//           0.09211,
//           0.0955867,
//           0.096775,
//           "0.094",
//           1.0824,
//           60.0,
//           3.0,
//           14.0,
//           17.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         918: [
//           "1023",
//           0.475,
//           8.464,
//           0.5215,
//           0.5217,
//           0.5366,
//           0.5535,
//           "0.527",
//           4.5857,
//           60.0,
//           11.0,
//           11.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         919: [
//           "1000",
//           0.6,
//           0.349,
//           0.7408,
//           0.6979,
//           0.6888,
//           0.70795,
//           "0.685",
//           0.3294,
//           60.0,
//           1.0,
//           -20.0,
//           -29.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         920: [
//           "0392",
//           24.0,
//           7209.0,
//           27.441,
//           27.5985,
//           27.8637,
//           27.5077,
//           "28.027",
//           302.8,
//           59.0,
//           -6.0,
//           -12.0,
//           -11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         921: [
//           "2399",
//           0.64,
//           2321.0,
//           0.567,
//           0.5874,
//           0.6294,
//           0.6258,
//           "0.606",
//           13.3,
//           59.0,
//           15.0,
//           34.0,
//           41.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         922: [
//           "0975",
//           1.95,
//           367.0,
//           1.8432,
//           1.8769,
//           2.21127,
//           2.22545,
//           "2.185",
//           20.1,
//           59.0,
//           -3.0,
//           7.0,
//           17.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         923: [
//           "0386",
//           3.43,
//           54105.0,
//           3.9484,
//           3.835,
//           3.82287,
//           3.8445,
//           "3.83",
//           875.1,
//           59.0,
//           2.0,
//           -7.0,
//           -15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         924: [
//           "0122",
//           0.34,
//           23.075,
//           0.3617,
//           0.38245,
//           0.400833,
//           0.4033,
//           "0.393",
//           3.2216,
//           59.0,
//           14.0,
//           2.0,
//           15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         925: [
//           "2117",
//           3.77,
//           27.496,
//           4.3932,
//           4.3015,
//           4.37227,
//           4.4006,
//           "4.391",
//           51.4,
//           59.0,
//           3.0,
//           1.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         926: [
//           "0897",
//           0.285,
//           3.357,
//           0.2952,
//           0.31645,
//           0.314233,
//           0.316425,
//           "0.314",
//           3.5101,
//           59.0,
//           8.0,
//           11.0,
//           15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         927: [
//           "1836",
//           8.04,
//           45.43,
//           8.5932,
//           8.9072,
//           9.24827,
//           9.4897,
//           "9.198",
//           63.8,
//           59.0,
//           11.0,
//           15.0,
//           24.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         928: [
//           "1469",
//           0.76,
//           9.318,
//           0.8186,
//           0.8142,
//           0.816333,
//           0.8362,
//           "0.812",
//           19.0,
//           59.0,
//           6.0,
//           0.0,
//           12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         929: [
//           "0149",
//           0.102,
//           13.284,
//           0.11044,
//           0.11172,
//           0.11284,
//           0.115675,
//           "0.112",
//           10.1,
//           59.0,
//           -10.0,
//           -3.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         930: [
//           "1301",
//           1.07,
//           33.574,
//           1.0958,
//           1.1491,
//           1.19967,
//           1.2387,
//           "1.176",
//           6.8416,
//           59.0,
//           8.0,
//           17.0,
//           16.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         931: [
//           "0914",
//           37.75,
//           25824.0,
//           41.681,
//           40.1215,
//           41.3863,
//           41.3628,
//           "41.193",
//           490.5,
//           59.0,
//           3.0,
//           -3.0,
//           -13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         932: [
//           "1093",
//           8.27,
//           40451.0,
//           9.1724,
//           8.7218,
//           9.09773,
//           9.62345,
//           "8.923",
//           986.8,
//           59.0,
//           5.0,
//           -1.0,
//           -16.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         933: [
//           "2356",
//           6.62,
//           1117.0,
//           7.2472,
//           7.1725,
//           7.30233,
//           7.5868,
//           "7.258",
//           93.0,
//           59.0,
//           5.0,
//           8.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         934: [
//           "8325",
//           0.148,
//           7.33,
//           0.165,
//           0.1687,
//           0.169653,
//           0.175515,
//           "0.167",
//           3.504,
//           59.0,
//           9.0,
//           14.0,
//           2.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         935: [
//           "0820",
//           15.16,
//           41.627,
//           17.2336,
//           17.6972,
//           17.3359,
//           17.0673,
//           "17.358",
//           0.0,
//           59.0,
//           -4.0,
//           0.0,
//           1.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         936: [
//           "6908",
//           5.6,
//           971.0,
//           6.1404,
//           6.5401,
//           6.8648,
//           7.05855,
//           "6.811",
//           31.5,
//           59.0,
//           -9.0,
//           -18.0,
//           -31.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         937: [
//           "9191",
//           7.045,
//           594.0,
//           7.663,
//           8.13215,
//           8.24347,
//           8.25453,
//           "8.142",
//           0.0,
//           59.0,
//           0.0,
//           6.0,
//           -3.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         938: [
//           "1008",
//           0.315,
//           5.004,
//           0.3365,
//           0.33405,
//           0.341267,
//           0.364575,
//           "0.337",
//           4.9388,
//           59.0,
//           -3.0,
//           12.0,
//           -5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         939: [
//           "0527",
//           0.174,
//           3.623,
//           0.17652,
//           0.18274,
//           0.190933,
//           0.19826,
//           "0.189",
//           3.4437,
//           59.0,
//           5.0,
//           3.0,
//           22.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         940: [
//           "0515",
//           0.049,
//           10.33,
//           0.05032,
//           0.04916,
//           0.0504867,
//           0.051795,
//           "0.05",
//           1.5068,
//           59.0,
//           -8.0,
//           -6.0,
//           23.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         941: [
//           "1319",
//           0.235,
//           0.376,
//           0.24452,
//           0.24854,
//           0.253107,
//           0.262885,
//           "0.251",
//           4.5327,
//           59.0,
//           9.0,
//           4.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         942: [
//           "0422",
//           0.226,
//           0.699,
//           0.25044,
//           0.25417,
//           0.2552,
//           0.2596,
//           "0.254",
//           2.0513,
//           59.0,
//           9.0,
//           14.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         943: [
//           "8163",
//           0.145,
//           26.35,
//           0.17676,
//           0.17856,
//           0.165647,
//           0.163096,
//           "0.168",
//           0.7033,
//           58.0,
//           2.0,
//           0.0,
//           -24.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         944: [
//           "0878",
//           6.85,
//           54.529,
//           7.5182,
//           7.5877,
//           7.69827,
//           7.9223,
//           "7.627",
//           19.4,
//           58.0,
//           5.0,
//           8.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         945: [
//           "0910",
//           0.45,
//           0.756,
//           0.4766,
//           0.4844,
//           0.492367,
//           0.5077,
//           "0.491",
//           22.8,
//           58.0,
//           11.0,
//           8.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         946: [
//           "0487",
//           0.117,
//           4.788,
//           0.12354,
//           0.12603,
//           0.13134,
//           0.136275,
//           "0.13",
//           5.7639,
//           58.0,
//           -1.0,
//           2.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         947: [
//           "1289",
//           0.9,
//           3.328,
//           0.9376,
//           0.9546,
//           0.958533,
//           0.97045,
//           "0.953",
//           0.288,
//           58.0,
//           17.0,
//           10.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         948: [
//           "1686",
//           6.52,
//           503.0,
//           7.0156,
//           7.0262,
//           7.25227,
//           7.44425,
//           "7.162",
//           152.4,
//           58.0,
//           6.0,
//           6.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         949: [
//           "0097",
//           0.335,
//           15.36,
//           0.3631,
//           0.3653,
//           0.369367,
//           0.379,
//           "0.365",
//           10.2,
//           58.0,
//           6.0,
//           9.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         950: [
//           "1183",
//           3.05,
//           852.0,
//           3.4978,
//           3.5056,
//           3.6124,
//           3.66093,
//           "3.556",
//           54.3,
//           58.0,
//           7.0,
//           17.0,
//           10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         951: [
//           "1270",
//           0.82,
//           154.0,
//           0.8486,
//           0.8602,
//           0.869133,
//           0.8927,
//           "0.865",
//           26.6,
//           58.0,
//           1.0,
//           5.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         952: [
//           "1627",
//           0.355,
//           3.358,
//           0.3999,
//           0.41225,
//           0.4028,
//           0.40245,
//           "0.401",
//           7.1,
//           58.0,
//           -2.0,
//           -2.0,
//           7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         953: [
//           "0321",
//           1.47,
//           101.0,
//           1.5712,
//           1.5984,
//           1.62293,
//           1.6713,
//           "1.604",
//           20.3,
//           58.0,
//           7.0,
//           5.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         954: [
//           "8066",
//           0.038,
//           0.322,
//           0.03862,
//           0.0407,
//           0.04302,
//           0.04358,
//           "0.042",
//           0.1996,
//           58.0,
//           -1.0,
//           9.0,
//           -1.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         955: [
//           "1953",
//           0.191,
//           4.542,
//           0.2093,
//           0.22077,
//           0.22356,
//           0.211465,
//           "0.221",
//           2.4066,
//           58.0,
//           -24.0,
//           -21.0,
//           -9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         956: [
//           "0522",
//           77.85,
//           16169.0,
//           81.215,
//           82.23,
//           85.1853,
//           89.672,
//           "84.095",
//           321.2,
//           58.0,
//           9.0,
//           20.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         957: [
//           "1336",
//           20.85,
//           10107.0,
//           22.576,
//           22.2415,
//           22.493,
//           23.2202,
//           "22.515",
//           215.6,
//           58.0,
//           3.0,
//           5.0,
//           6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         958: [
//           "1033",
//           0.62,
//           502.0,
//           0.6972,
//           0.6918,
//           0.707667,
//           0.7096,
//           "0.711",
//           33.5,
//           58.0,
//           -3.0,
//           -13.0,
//           -22.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         959: [
//           "3969",
//           2.54,
//           3688.0,
//           2.8328,
//           2.7903,
//           2.82213,
//           2.8407,
//           "2.817",
//           50.0,
//           58.0,
//           5.0,
//           7.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         960: [
//           "3121",
//           8.28,
//           0.0,
//           8.905,
//           9.03685,
//           9.1637,
//           9.37568,
//           "9.107",
//           0.0,
//           58.0,
//           6.0,
//           2.0,
//           6.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         961: [
//           "0168",
//           60.2,
//           45398.0,
//           71.565,
//           69.2175,
//           67.2623,
//           69.9895,
//           "67.35",
//           394.3,
//           58.0,
//           -9.0,
//           -24.0,
//           -26.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         962: [
//           "2727",
//           1.99,
//           2187.0,
//           2.2244,
//           2.256,
//           2.23447,
//           2.18055,
//           "2.25",
//           58.1,
//           58.0,
//           1.0,
//           1.0,
//           6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         963: [
//           "3119",
//           54.38,
//           38.144,
//           59.9688,
//           61.4368,
//           61.0845,
//           "-",
//           "60.865",
//           0.0,
//           58.0,
//           6.0,
//           11.0,
//           -2.0,
//           2.0,
//           "E",
//         ],
//       },
//       {
//         964: [
//           "6999",
//           4.57,
//           15.243,
//           5.1062,
//           4.8537,
//           5.29007,
//           5.6333,
//           "5.219",
//           46.9,
//           58.0,
//           1.0,
//           9.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         965: [
//           "3839",
//           1.28,
//           0.528,
//           1.369,
//           1.3998,
//           1.43053,
//           1.47855,
//           "1.419",
//           3.0811,
//           58.0,
//           9.0,
//           3.0,
//           10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         966: [
//           "2108",
//           0.22,
//           0.704,
//           0.27352,
//           0.27596,
//           0.267767,
//           0.26628,
//           "0.269",
//           1.76,
//           57.0,
//           13.0,
//           10.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         967: [
//           "0010",
//           15.8,
//           1141.0,
//           17.0168,
//           17.32,
//           17.8431,
//           18.3113,
//           "17.589",
//           215.1,
//           57.0,
//           4.0,
//           1.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         968: [
//           "8021",
//           0.038,
//           5.308,
//           0.04652,
//           0.04751,
//           0.0462333,
//           0.04818,
//           "0.046",
//           5.4594,
//           57.0,
//           -2.0,
//           -7.0,
//           -25.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         969: [
//           "0862",
//           0.171,
//           3.954,
//           0.19544,
//           0.20603,
//           0.2033,
//           0.20211,
//           "0.204",
//           6.7103,
//           57.0,
//           1.0,
//           -11.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         970: [
//           "0840",
//           0.216,
//           9.93,
//           0.21648,
//           0.22953,
//           0.236467,
//           0.24323,
//           "0.235",
//           0.4371,
//           57.0,
//           10.0,
//           9.0,
//           10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         971: [
//           "3339",
//           2.08,
//           2895.0,
//           2.195,
//           2.1871,
//           2.2854,
//           2.3573,
//           "2.253",
//           89.0,
//           57.0,
//           13.0,
//           21.0,
//           18.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         972: [
//           "3076",
//           6.7,
//           11.632,
//           "-",
//           "-",
//           "-",
//           "-",
//           "-",
//           0.0,
//           57.0,
//           5.0,
//           12.0,
//           10.0,
//           1.0,
//           "E",
//         ],
//       },
//       {
//         973: [
//           "0116",
//           9.9,
//           605.0,
//           10.8448,
//           11.0124,
//           11.2932,
//           11.8007,
//           "11.143",
//           67.0,
//           57.0,
//           7.0,
//           -2.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         974: [
//           "8153",
//           0.066,
//           201.0,
//           0.06074,
//           0.0672,
//           0.0699067,
//           0.07163,
//           "0.07",
//           5.6043,
//           57.0,
//           -18.0,
//           24.0,
//           8.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         975: [
//           "3968",
//           56.05,
//           177000.0,
//           64.755,
//           64.368,
//           64.1237,
//           64.4785,
//           "63.982",
//           2573.2,
//           57.0,
//           1.0,
//           -5.0,
//           -19.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         976: [
//           "0722",
//           0.78,
//           29.362,
//           0.8386,
//           0.8297,
//           0.8386,
//           0.87285,
//           "0.831",
//           6.1827,
//           57.0,
//           1.0,
//           -14.0,
//           -17.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         977: [
//           "1871",
//           0.236,
//           1.912,
//           0.26272,
//           0.26748,
//           0.27712,
//           0.285465,
//           "0.272",
//           0.944,
//           57.0,
//           11.0,
//           14.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         978: [
//           "1853",
//           1.78,
//           2.67,
//           2.0176,
//           1.9991,
//           2.0124,
//           2.03615,
//           "2.005",
//           2.0772,
//           57.0,
//           1.0,
//           1.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         979: [
//           "6836",
//           1.4,
//           163.0,
//           1.4714,
//           1.496,
//           1.5026,
//           1.5358,
//           "1.494",
//           13.8,
//           57.0,
//           3.0,
//           7.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         980: [
//           "6837",
//           6.05,
//           8343.0,
//           6.9212,
//           6.8847,
//           6.95213,
//           6.92875,
//           "6.946",
//           206.2,
//           57.0,
//           2.0,
//           -1.0,
//           -5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         981: [
//           "2166",
//           1.22,
//           17.028,
//           1.4866,
//           1.5131,
//           1.52587,
//           1.51745,
//           "1.491",
//           5.9619,
//           57.0,
//           1.0,
//           4.0,
//           -18.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         982: [
//           "1845",
//           0.335,
//           8.221,
//           0.3381,
//           0.38065,
//           0.4033,
//           0.4007,
//           "0.395",
//           4.4666,
//           57.0,
//           3.0,
//           17.0,
//           14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         983: [
//           "1803",
//           0.178,
//           6.979,
//           0.19694,
//           0.19905,
//           0.203727,
//           0.206575,
//           "0.201",
//           2.5062,
//           57.0,
//           4.0,
//           -1.0,
//           -19.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         984: [
//           "1055",
//           4.08,
//           9734.0,
//           4.9996,
//           4.8001,
//           4.67967,
//           4.71935,
//           "4.716",
//           174.4,
//           57.0,
//           -1.0,
//           -5.0,
//           -17.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         985: [
//           "8360",
//           0.32,
//           33.597,
//           0.4404,
//           0.47435,
//           0.428308,
//           0.389766,
//           "0.431",
//           1.1371,
//           57.0,
//           -21.0,
//           -25.0,
//           -32.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         986: [
//           "9918",
//           0.089,
//           9.812,
//           0.09402,
//           0.09457,
//           0.0997,
//           0.11121,
//           "0.097",
//           1.78,
//           57.0,
//           -2.0,
//           7.0,
//           19.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         987: [
//           "8270",
//           0.047,
//           6.392,
//           0.05378,
//           0.05807,
//           0.0535,
//           0.052545,
//           "0.054",
//           0.9766,
//           57.0,
//           -7.0,
//           6.0,
//           -27.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         988: [
//           "2488",
//           2.83,
//           19.781,
//           3.2058,
//           3.0279,
//           3.15973,
//           3.4937,
//           "3.11",
//           5.0559,
//           57.0,
//           11.0,
//           17.0,
//           14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         989: [
//           "0953",
//           0.101,
//           23.277,
//           0.11196,
//           0.11037,
//           0.116253,
//           0.12248,
//           "0.115",
//           1.4338,
//           57.0,
//           -1.0,
//           2.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         990: [
//           "1665",
//           0.95,
//           143.0,
//           1.1036,
//           1.1177,
//           1.0956,
//           1.09788,
//           "1.103",
//           22.8,
//           56.0,
//           4.0,
//           1.0,
//           15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         991: [
//           "0670",
//           2.68,
//           4398.0,
//           3.1044,
//           3.0159,
//           3.01493,
//           3.07065,
//           "3.026",
//           138.7,
//           56.0,
//           0.0,
//           2.0,
//           -7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         992: [
//           "2288",
//           0.57,
//           0.969,
//           0.6144,
//           0.6422,
//           0.6654,
//           0.6645,
//           "0.655",
//           2.14,
//           56.0,
//           -14.0,
//           -12.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         993: [
//           "0016",
//           90.75,
//           61508.0,
//           94.668,
//           96.489,
//           99.5617,
//           103.867,
//           "98.455",
//           2629.7000000000007,
//           56.0,
//           9.0,
//           13.0,
//           21.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         994: [
//           "1997",
//           35.45,
//           15748.0,
//           36.982,
//           39.327,
//           39.3273,
//           40.688,
//           "39.302",
//           1076.3,
//           56.0,
//           15.0,
//           26.0,
//           22.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         995: [
//           "0017",
//           30.05,
//           15260.0,
//           31.329,
//           31.8775,
//           32.9223,
//           34.56,
//           "32.472",
//           756.2,
//           56.0,
//           9.0,
//           10.0,
//           15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         996: [
//           "3991",
//           0.65,
//           14.262,
//           0.7004,
//           0.7118,
//           0.730067,
//           0.75885,
//           "0.721",
//           9.4552,
//           56.0,
//           -1.0,
//           2.0,
//           17.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         997: [
//           "8616",
//           0.093,
//           2.114,
//           0.12228,
//           0.12534,
//           0.128707,
//           0.120155,
//           "0.128",
//           0.93,
//           56.0,
//           -7.0,
//           -23.0,
//           -28.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         998: [
//           "6816",
//           0.53,
//           0.206,
//           0.5416,
//           0.564,
//           0.558467,
//           0.59145,
//           "-",
//           4.24,
//           56.0,
//           5.0,
//           9.0,
//           21.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         999: [
//           "0992",
//           7.46,
//           38850.0,
//           8.575,
//           8.4379,
//           8.35593,
//           8.4166,
//           "8.36",
//           898.3,
//           56.0,
//           2.0,
//           7.0,
//           -21.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1000: [
//           "0028",
//           3.82,
//           4.975,
//           4.075,
//           4.2091,
//           4.325,
//           4.39025,
//           "4.291",
//           56.0,
//           56.0,
//           9.0,
//           8.0,
//           10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1001: [
//           "3136",
//           12.4,
//           102.0,
//           "-",
//           "-",
//           "-",
//           "-",
//           "-",
//           0.0,
//           56.0,
//           -2.0,
//           -2.0,
//           -12.0,
//           1.0,
//           "E",
//         ],
//       },
//       {
//         1002: [
//           "2698",
//           2.13,
//           499.0,
//           2.4972,
//           2.6025,
//           2.6744,
//           2.61085,
//           "2.656",
//           8.81,
//           56.0,
//           4.0,
//           -9.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1003: [
//           "0373",
//           2.52,
//           15.626,
//           2.7678,
//           2.7674,
//           2.8396,
//           2.9145,
//           "2.814",
//           88.5,
//           56.0,
//           0.0,
//           -2.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1004: [
//           "2866",
//           1.25,
//           2619.0,
//           1.4446,
//           1.4425,
//           1.4896,
//           1.5118,
//           "1.481",
//           45.9,
//           56.0,
//           4.0,
//           -8.0,
//           -17.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1005: [
//           "0938",
//           1.45,
//           6.364,
//           1.5636,
//           1.5937,
//           1.60047,
//           1.626,
//           "-",
//           9.3843,
//           56.0,
//           0.0,
//           -4.0,
//           17.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1006: [
//           "0934",
//           2.62,
//           1032.0,
//           3.0262,
//           2.9814,
//           2.96693,
//           2.96995,
//           "2.97",
//           65.1,
//           56.0,
//           0.0,
//           -6.0,
//           -12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1007: [
//           "2877",
//           5.91,
//           127.0,
//           6.5312,
//           6.8351,
//           7.17733,
//           7.4129,
//           "7.074",
//           48.8,
//           56.0,
//           4.0,
//           9.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1008: [
//           "0989",
//           0.395,
//           5.902,
//           0.4932,
//           0.50505,
//           0.467467,
//           0.4521,
//           "0.474",
//           26.7,
//           56.0,
//           13.0,
//           10.0,
//           -16.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1009: [
//           "0590",
//           18.52,
//           592.0,
//           20.3712,
//           21.6216,
//           21.6936,
//           22.4265,
//           "21.378",
//           108.7,
//           56.0,
//           4.0,
//           6.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1010: [
//           "3108",
//           10.3,
//           0.0,
//           11.1028,
//           11.4377,
//           11.4559,
//           11.5862,
//           "11.403",
//           0.0,
//           56.0,
//           7.0,
//           8.0,
//           4.0,
//           2.0,
//           "E",
//         ],
//       },
//       {
//         1011: [
//           "1273",
//           0.375,
//           3.338,
//           0.41834,
//           0.43527,
//           0.455347,
//           0.465735,
//           "0.45",
//           1.5562,
//           56.0,
//           3.0,
//           10.0,
//           18.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1012: [
//           "1341",
//           0.34,
//           6.992,
//           0.3775,
//           0.3941,
//           0.428567,
//           0.452775,
//           "0.414",
//           25.7,
//           56.0,
//           13.0,
//           1.0,
//           31.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1013: [
//           "1910",
//           13.4,
//           7535.0,
//           16.3632,
//           16.3292,
//           16.1585,
//           16.0205,
//           "16.227",
//           192.5,
//           55.0,
//           5.0,
//           5.0,
//           -31.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1014: [
//           "2266",
//           0.35,
//           0.359,
//           0.3203,
//           0.34815,
//           0.387467,
//           0.41855,
//           "0.376",
//           1.4,
//           55.0,
//           17.0,
//           27.0,
//           35.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1015: [
//           "1902",
//           2.41,
//           100.0,
//           3.0716,
//           2.8762,
//           2.95687,
//           3.00575,
//           "2.944",
//           34.8,
//           55.0,
//           -9.0,
//           -17.0,
//           -11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1016: [
//           "0059",
//           0.78,
//           479.0,
//           0.859,
//           0.872,
//           0.886733,
//           0.9025,
//           "0.878",
//           65.8,
//           55.0,
//           5.0,
//           6.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1017: [
//           "1766",
//           3.04,
//           7257.0,
//           3.4994,
//           3.5268,
//           3.54573,
//           3.5202,
//           "3.553",
//           132.8,
//           55.0,
//           5.0,
//           0.0,
//           -8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1018: [
//           "3132",
//           13.4,
//           62.894,
//           14.8474,
//           "-",
//           "-",
//           "-",
//           "-",
//           0.0,
//           55.0,
//           8.0,
//           12.0,
//           7.0,
//           1.0,
//           "E",
//         ],
//       },
//       {
//         1019: [
//           "0061",
//           0.11,
//           8.68,
//           0.1331,
//           0.12949,
//           0.127727,
//           0.13058,
//           "0.127",
//           0.5788,
//           55.0,
//           -11.0,
//           -18.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1020: [
//           "1771",
//           1.82,
//           1.092,
//           2.038,
//           2.1573,
//           2.31953,
//           2.36165,
//           "2.224",
//           10.9,
//           55.0,
//           6.0,
//           14.0,
//           19.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1021: [
//           "3320",
//           3.44,
//           3452.0,
//           3.9596,
//           3.7402,
//           3.815,
//           4.07415,
//           "3.756",
//           216.1,
//           55.0,
//           4.0,
//           3.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1022: [
//           "1676",
//           1.04,
//           47.308,
//           0.9662,
//           1.1867,
//           1.3032,
//           1.4109,
//           "1.245",
//           1.1232,
//           55.0,
//           23.0,
//           21.0,
//           46.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1023: [
//           "0581",
//           2.06,
//           1187.0,
//           2.37,
//           2.2755,
//           2.37807,
//           2.39655,
//           "2.36",
//           76.6,
//           55.0,
//           1.0,
//           1.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1024: [
//           "8080",
//           0.62,
//           0.248,
//           0.6628,
//           0.6915,
//           0.7182,
//           0.7429,
//           "0.706",
//           2.6357,
//           55.0,
//           6.0,
//           18.0,
//           21.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1025: [
//           "3337",
//           0.42,
//           305.0,
//           0.4763,
//           0.4722,
//           0.471933,
//           0.47945,
//           "0.473",
//           12.6,
//           55.0,
//           -12.0,
//           -18.0,
//           -9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1026: [
//           "1062",
//           0.122,
//           2.294,
//           0.12968,
//           0.13322,
//           0.13572,
//           0.140215,
//           "0.135",
//           3.5407,
//           55.0,
//           8.0,
//           12.0,
//           22.0,
//           0.0,
//           "-",
//         ],
//       },
//       {
//         1027: [
//           "0661",
//           0.084,
//           35.344,
//           0.0836,
//           0.08667,
//           0.09602,
//           0.102005,
//           "0.093",
//           15.0,
//           55.0,
//           8.0,
//           -26.0,
//           27.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1028: [
//           "0815",
//           0.57,
//           411.00000000000006,
//           0.5936,
//           0.5965,
//           0.592933,
//           0.63035,
//           "0.591",
//           9.2818,
//           55.0,
//           1.0,
//           3.0,
//           23.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1029: [
//           "1540",
//           0.51,
//           0.765,
//           0.6108,
//           0.6232,
//           0.6078,
//           0.5919,
//           "0.61",
//           2.5432,
//           55.0,
//           0.0,
//           -1.0,
//           -10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1030: [
//           "2845",
//           138.75,
//           6695.0,
//           160.698,
//           180.432,
//           180.665,
//           175.294,
//           "178.487",
//           0.0,
//           55.0,
//           -1.0,
//           3.0,
//           -20.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1031: [
//           "6083",
//           0.37,
//           2.044,
//           0.3843,
//           0.4126,
//           0.451067,
//           0.459825,
//           "0.435",
//           1.8568,
//           55.0,
//           17.0,
//           20.0,
//           16.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1032: [
//           "2031",
//           0.222,
//           7.999,
//           0.23338,
//           0.23651,
//           0.24968,
//           0.25976,
//           "0.244",
//           1.6916,
//           55.0,
//           0.0,
//           13.0,
//           8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1033: [
//           "1508",
//           0.69,
//           806.0000000000001,
//           0.762,
//           0.7659,
//           0.788267,
//           0.7904,
//           "0.788",
//           46.0,
//           55.0,
//           -1.0,
//           -3.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1034: [
//           "6939",
//           1.16,
//           37.934,
//           1.1874,
//           1.2391,
//           1.3132,
//           1.3819,
//           "1.29",
//           6.0175,
//           55.0,
//           14.0,
//           13.0,
//           20.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         1035: [
//           "0132",
//           0.4,
//           0.766,
//           0.3883,
//           0.42345,
//           0.4234,
//           0.4552,
//           "0.426",
//           6.8493,
//           55.0,
//           2.0,
//           4.0,
//           15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1036: [
//           "0103",
//           0.265,
//           42.488,
//           0.3218,
//           0.3238,
//           0.307227,
//           0.29967,
//           "0.311",
//           5.2241,
//           54.0,
//           1.0,
//           -12.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1037: [
//           "0911",
//           0.079,
//           35.781,
//           0.078,
//           0.08,
//           0.0857267,
//           0.09328,
//           "0.083",
//           1.3386,
//           54.0,
//           33.0,
//           -1.0,
//           12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1038: [
//           "0064",
//           0.146,
//           300.0,
//           0.16078,
//           0.16228,
//           0.166107,
//           0.17068,
//           "0.165",
//           14.1,
//           54.0,
//           8.0,
//           6.0,
//           7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1039: [
//           "0135",
//           6.52,
//           15246.0,
//           7.676,
//           7.4191,
//           7.5302,
//           7.39685,
//           "7.549",
//           564.5,
//           54.0,
//           -14.0,
//           -13.0,
//           -19.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1040: [
//           "2178",
//           0.087,
//           75.697,
//           0.10766,
//           0.11043,
//           0.10768,
//           0.104485,
//           "0.108",
//           1.5022,
//           54.0,
//           -36.0,
//           -20.0,
//           -22.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1041: [
//           "0317",
//           6.18,
//           566.0,
//           7.1736,
//           7.1033,
//           7.09127,
//           7.07945,
//           "7.108",
//           36.5,
//           54.0,
//           -2.0,
//           -2.0,
//           -11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1042: [
//           "9845",
//           17.69,
//           1437.0,
//           20.6014,
//           23.1409,
//           23.1782,
//           22.5056,
//           "22.89",
//           0.0,
//           54.0,
//           0.0,
//           3.0,
//           -20.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1043: [
//           "2039",
//           12.44,
//           1338.0,
//           13.8088,
//           14.2166,
//           15.1171,
//           15.4056,
//           "14.904",
//           256.2,
//           54.0,
//           7.0,
//           12.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1044: [
//           "2666",
//           5.06,
//           2269.0,
//           5.5788,
//           5.5827,
//           5.75573,
//           6.06595,
//           "5.704",
//           95.7,
//           54.0,
//           2.0,
//           4.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1045: [
//           "3024",
//           29.12,
//           3.544,
//           32.1076,
//           32.6736,
//           32.4628,
//           32.9831,
//           "32.405",
//           0.0,
//           54.0,
//           3.0,
//           0.0,
//           -1.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1046: [
//           "1622",
//           2.12,
//           794.0,
//           2.4664,
//           2.4317,
//           2.45007,
//           2.4664,
//           "2.487",
//           75.2,
//           54.0,
//           9.0,
//           21.0,
//           17.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1047: [
//           "1098",
//           7.09,
//           248.0,
//           7.5476,
//           7.5385,
//           7.862,
//           8.32255,
//           "7.739",
//           53.1,
//           54.0,
//           5.0,
//           7.0,
//           18.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1048: [
//           "3377",
//           1.46,
//           1365.0,
//           1.8546,
//           1.8266,
//           1.7482,
//           1.73535,
//           "1.741",
//           111.1,
//           54.0,
//           -1.0,
//           -3.0,
//           -12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1049: [
//           "1763",
//           19.84,
//           1037.0,
//           23.0908,
//           22.6294,
//           23.1709,
//           24.8369,
//           "22.56",
//           15.8,
//           54.0,
//           1.0,
//           17.0,
//           14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1050: [
//           "8627",
//           0.185,
//           51.405,
//           0.19698,
//           0.20759,
//           0.222233,
//           0.22952,
//           "0.218",
//           1.48,
//           54.0,
//           8.0,
//           1.0,
//           10.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1051: [
//           "1360",
//           0.115,
//           10.977,
//           0.14112,
//           0.14936,
//           0.150053,
//           0.144105,
//           "0.149",
//           2.1342,
//           54.0,
//           -10.0,
//           2.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1052: [
//           "1272",
//           0.86,
//           1.017,
//           0.945,
//           0.9821,
//           0.992467,
//           0.99045,
//           "0.994",
//           5.3689,
//           54.0,
//           6.0,
//           8.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1053: [
//           "0272",
//           0.99,
//           1051.0,
//           1.122,
//           1.0999,
//           1.1486,
//           1.1821,
//           "1.133",
//           79.8,
//           54.0,
//           -4.0,
//           -14.0,
//           -13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1054: [
//           "3100",
//           49.8,
//           0.0,
//           53.9716,
//           55.227,
//           55.5157,
//           56.4373,
//           "55.107",
//           0.0,
//           54.0,
//           7.0,
//           8.0,
//           1.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1055: [
//           "1635",
//           1.6,
//           34.39,
//           1.7074,
//           1.7147,
//           1.75193,
//           1.81325,
//           "1.747",
//           8.5382,
//           54.0,
//           3.0,
//           3.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1056: [
//           "0022",
//           0.101,
//           14.388,
//           0.11954,
//           0.12169,
//           0.120373,
//           0.120575,
//           "0.121",
//           1.986,
//           54.0,
//           -10.0,
//           -10.0,
//           -14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1057: [
//           "1996",
//           2.06,
//           149.0,
//           2.4128,
//           2.2951,
//           2.37407,
//           2.4085,
//           "2.349",
//           68.7,
//           54.0,
//           8.0,
//           6.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1058: [
//           "8049",
//           0.96,
//           11.032,
//           1.184,
//           1.1579,
//           1.1332,
//           1.14735,
//           "1.135",
//           1.656,
//           54.0,
//           2.0,
//           -2.0,
//           -9.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1059: [
//           "8069",
//           0.29,
//           9.235,
//           0.3037,
//           0.3045,
//           0.304933,
//           0.31945,
//           "0.307",
//           1.16,
//           54.0,
//           9.0,
//           -5.0,
//           -5.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1060: [
//           "2778",
//           3.54,
//           496.0,
//           3.8712,
//           3.9608,
//           4.05487,
//           4.16065,
//           "3.996",
//           210.8,
//           53.0,
//           7.0,
//           15.0,
//           22.0,
//           0.0,
//           "R",
//         ],
//       },
//       {
//         1061: [
//           "0912",
//           1.5,
//           22.78,
//           1.5722,
//           1.6569,
//           1.74693,
//           1.8335,
//           "1.703",
//           4.2718,
//           53.0,
//           -1.0,
//           12.0,
//           13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1062: [
//           "0635",
//           0.71,
//           24.262,
//           0.7844,
//           0.7891,
//           0.807133,
//           0.8332,
//           "0.795",
//           14.8,
//           53.0,
//           0.0,
//           4.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1063: [
//           "2000",
//           0.345,
//           9.378,
//           0.4188,
//           0.419,
//           0.410333,
//           0.41045,
//           "0.411",
//           7.7573,
//           53.0,
//           4.0,
//           0.0,
//           -21.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1064: [
//           "1856",
//           2.22,
//           15.619,
//           2.4868,
//           2.5218,
//           2.5454,
//           2.5691,
//           "2.544",
//           7.7131,
//           53.0,
//           5.0,
//           3.0,
//           -14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1065: [
//           "1475",
//           4.97,
//           621.0,
//           5.9088,
//           5.8276,
//           5.75113,
//           5.79025,
//           "5.768",
//           51.8,
//           53.0,
//           5.0,
//           -10.0,
//           -13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1066: [
//           "0111",
//           0.395,
//           21.497,
//           0.4236,
//           0.437,
//           0.449367,
//           0.455225,
//           "0.447",
//           2.5327,
//           53.0,
//           10.0,
//           7.0,
//           7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1067: [
//           "1153",
//           3.25,
//           74.95,
//           3.3756,
//           3.6446,
//           3.83733,
//           3.89595,
//           "3.779",
//           19.8,
//           53.0,
//           9.0,
//           12.0,
//           18.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1068: [
//           "8257",
//           0.18,
//           0.539,
//           0.17542,
//           0.1887,
//           0.19598,
//           0.21093,
//           "0.191",
//           1.8,
//           53.0,
//           16.0,
//           13.0,
//           27.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1069: [
//           "1245",
//           0.225,
//           0.865,
//           0.25422,
//           0.27061,
//           0.29464,
//           0.284,
//           "0.291",
//           2.6906,
//           53.0,
//           5.0,
//           6.0,
//           12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1070: [
//           "0254",
//           0.119,
//           129.0,
//           0.64018,
//           0.39109,
//           0.30806,
//           0.266545,
//           "-",
//           3.6623,
//           53.0,
//           -11.0,
//           -46.0,
//           -46.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1071: [
//           "3031",
//           11.09,
//           1.482,
//           12.4032,
//           12.7912,
//           12.823,
//           12.9252,
//           "12.764",
//           0.0,
//           53.0,
//           1.0,
//           1.0,
//           -4.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1072: [
//           "3918",
//           6.1,
//           1482.0,
//           6.717,
//           6.8366,
//           6.67493,
//           6.78255,
//           "6.743",
//           264.8,
//           53.0,
//           11.0,
//           21.0,
//           -9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1073: [
//           "1181",
//           0.64,
//           10.586,
//           0.743,
//           0.7247,
//           0.7256,
//           0.76325,
//           "0.725",
//           6.8865,
//           53.0,
//           6.0,
//           2.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1074: [
//           "0052",
//           14.2,
//           90.842,
//           15.2472,
//           15.863,
//           16.2911,
//           16.6635,
//           "16.109",
//           18.3,
//           53.0,
//           12.0,
//           13.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1075: [
//           "1681",
//           3.25,
//           144.0,
//           3.9826,
//           3.8007,
//           3.84233,
//           4.17265,
//           "3.757",
//           26.4,
//           53.0,
//           -6.0,
//           -4.0,
//           -13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1076: [
//           "1593",
//           2.03,
//           302.0,
//           2.1528,
//           2.1875,
//           2.2494,
//           2.3199,
//           "2.209",
//           20.3,
//           53.0,
//           4.0,
//           6.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1077: [
//           "0234",
//           0.053,
//           6.437000000000001,
//           0.05778,
//           0.06226,
//           0.0623667,
//           0.06243,
//           "0.062",
//           3.0635,
//           53.0,
//           10.0,
//           10.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1078: [
//           "1679",
//           0.17,
//           6.235,
//           0.1848,
//           0.19157,
//           0.196247,
//           0.19832,
//           "0.194",
//           3.019,
//           53.0,
//           9.0,
//           9.0,
//           12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1079: [
//           "3128",
//           60.04,
//           40.484,
//           66.4316,
//           66.907,
//           66.7752,
//           68.1806,
//           "66.641",
//           0.0,
//           53.0,
//           5.0,
//           3.0,
//           -3.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1080: [
//           "0309",
//           0.064,
//           0.956,
//           0.0689,
//           0.07006,
//           0.07338,
//           0.075365,
//           "0.073",
//           1.1551,
//           53.0,
//           23.0,
//           24.0,
//           -11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1081: [
//           "3157",
//           23.7,
//           0.0,
//           24.0416,
//           25.1838,
//           26.374,
//           27.4963,
//           "26.042",
//           0.0,
//           53.0,
//           10.0,
//           12.0,
//           22.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1082: [
//           "0288",
//           4.86,
//           15041.0,
//           5.2634,
//           5.1971,
//           5.4152,
//           5.74412,
//           "5.312",
//           623.5,
//           53.0,
//           2.0,
//           -1.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1083: [
//           "0784",
//           0.13,
//           0.776,
//           0.13812,
//           0.14597,
//           0.146893,
//           0.147765,
//           "0.146",
//           1.04,
//           52.0,
//           8.0,
//           17.0,
//           17.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1084: [
//           "3149",
//           14.84,
//           0.0,
//           16.3146,
//           16.9316,
//           17.0143,
//           17.1859,
//           "16.932",
//           0.0,
//           52.0,
//           5.0,
//           1.0,
//           0.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1085: [
//           "1313",
//           6.12,
//           8269.0,
//           6.6606,
//           6.4096,
//           6.87487,
//           7.00855,
//           "6.809",
//           427.3,
//           52.0,
//           -1.0,
//           0.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1086: [
//           "1206",
//           0.67,
//           87.984,
//           0.856,
//           0.8212,
//           0.773933,
//           0.78135,
//           "0.782",
//           5.2406,
//           52.0,
//           -27.0,
//           -33.0,
//           -20.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1087: [
//           "0588",
//           1.09,
//           281.0,
//           1.2134,
//           1.2085,
//           1.25073,
//           1.29465,
//           "1.239",
//           7.7065,
//           52.0,
//           -3.0,
//           -5.0,
//           6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1088: [
//           "1381",
//           3.33,
//           591.0,
//           3.8472,
//           4.0483,
//           4.12887,
//           4.15695,
//           "4.11",
//           81.19999999999997,
//           52.0,
//           4.0,
//           -4.0,
//           -9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1089: [
//           "0694",
//           4.22,
//           5988.0,
//           5.1032,
//           4.9816,
//           4.8488,
//           4.8977,
//           "4.897",
//           79.3,
//           52.0,
//           -8.0,
//           -7.0,
//           -17.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1090: [
//           "2628",
//           11.8,
//           56070.0,
//           13.3988,
//           13.4368,
//           13.3263,
//           13.7048,
//           "13.323",
//           878.0,
//           52.0,
//           6.0,
//           4.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1091: [
//           "1201",
//           0.38,
//           9.456,
//           0.4304,
//           0.4549,
//           0.491033,
//           0.49565,
//           "0.477",
//           4.6986,
//           52.0,
//           4.0,
//           -9.0,
//           10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1092: [
//           "8006",
//           0.066,
//           0.189,
//           0.07576,
//           0.07613,
//           0.07676,
//           0.077975,
//           "0.077",
//           0.3157,
//           52.0,
//           -3.0,
//           -8.0,
//           -26.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1093: [
//           "2017",
//           0.31,
//           0.186,
//           0.3298,
//           0.3269,
//           0.348133,
//           0.3825,
//           "0.336",
//           1.9173,
//           52.0,
//           -10.0,
//           -7.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1094: [
//           "0725",
//           0.7,
//           3.452,
//           0.7428,
//           0.7901,
//           0.876,
//           0.88065,
//           "0.853",
//           1.3927,
//           52.0,
//           8.0,
//           13.0,
//           12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1095: [
//           "2849",
//           50.76,
//           13.711,
//           57.6156,
//           "-",
//           "-",
//           "-",
//           "-",
//           0.0,
//           52.0,
//           6.0,
//           13.0,
//           7.0,
//           1.0,
//           "E",
//         ],
//       },
//       {
//         1096: [
//           "0867",
//           12.06,
//           9304.0,
//           13.5012,
//           13.2704,
//           13.9401,
//           15.2949,
//           "13.638",
//           296.3,
//           52.0,
//           2.0,
//           -8.0,
//           -13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1097: [
//           "1234",
//           3.99,
//           327.0,
//           4.3872,
//           4.3738,
//           4.513,
//           4.66285,
//           "4.463",
//           47.7,
//           52.0,
//           3.0,
//           -3.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1098: [
//           "3869",
//           8.92,
//           5.201,
//           9.4232,
//           9.7128,
//           9.99093,
//           10.2752,
//           "9.886",
//           12.3,
//           52.0,
//           13.0,
//           16.0,
//           24.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1099: [
//           "0069",
//           5.76,
//           1963.0,
//           6.4614,
//           6.426,
//           6.43667,
//           6.64225,
//           "6.379",
//           206.5,
//           52.0,
//           -2.0,
//           -2.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1100: [
//           "0543",
//           1.25,
//           31.581,
//           1.3198,
//           1.3892,
//           1.44487,
//           1.53405,
//           "1.416",
//           14.1,
//           52.0,
//           1.0,
//           2.0,
//           16.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1101: [
//           "1172",
//           0.06,
//           3.0,
//           0.0652,
//           0.06712,
//           0.0692667,
//           0.07087,
//           "0.069",
//           3.4675,
//           52.0,
//           6.0,
//           25.0,
//           27.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1102: [
//           "1459",
//           0.67,
//           3.366,
//           0.712,
//           0.7561,
//           0.779933,
//           0.797,
//           "0.769",
//           0.8935,
//           52.0,
//           9.0,
//           7.0,
//           13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1103: [
//           "9031",
//           1.416,
//           22.609,
//           1.59024,
//           1.64078,
//           1.64573,
//           1.66034,
//           "1.637",
//           0.0,
//           52.0,
//           2.0,
//           1.0,
//           -4.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1104: [
//           "2101",
//           6.49,
//           105.0,
//           7.1458,
//           7.401,
//           7.4384,
//           8.083,
//           "7.315",
//           26.1,
//           52.0,
//           7.0,
//           6.0,
//           16.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1105: [
//           "3173",
//           10.21,
//           37.444,
//           11.441,
//           11.9317,
//           11.9216,
//           12.0907,
//           "11.85",
//           0.0,
//           52.0,
//           1.0,
//           6.0,
//           1.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1106: [
//           "2115",
//           0.117,
//           12.077,
//           0.13486,
//           0.13114,
//           0.129973,
//           0.133225,
//           "0.129",
//           1.638,
//           51.0,
//           4.0,
//           4.0,
//           6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1107: [
//           "0573",
//           0.77,
//           38.206,
//           0.8678,
//           0.8801,
//           0.8996,
//           0.9269,
//           "0.89",
//           7.8104,
//           51.0,
//           -6.0,
//           1.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1108: [
//           "1011",
//           0.075,
//           77.613,
//           0.06756,
//           0.07076,
//           0.0771667,
//           0.087075,
//           "0.076",
//           1.4284,
//           51.0,
//           25.0,
//           28.0,
//           25.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1109: [
//           "0589",
//           0.98,
//           6.666,
//           1.0066,
//           1.0362,
//           1.0708,
//           1.0965,
//           "1.07",
//           6.125,
//           51.0,
//           5.0,
//           7.0,
//           6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1110: [
//           "6806",
//           1.72,
//           1833.0,
//           1.9226,
//           1.944,
//           1.96707,
//           2.0021,
//           "1.965",
//           43.0,
//           51.0,
//           3.0,
//           4.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1111: [
//           "1908",
//           12.86,
//           3058.0,
//           15.022,
//           14.9768,
//           14.9939,
//           14.8633,
//           "15.015",
//           177.1,
//           51.0,
//           -8.0,
//           -26.0,
//           -17.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1112: [
//           "1948",
//           4.83,
//           266.0,
//           6.088,
//           "-",
//           "-",
//           "-",
//           "-",
//           28.9,
//           51.0,
//           -14.0,
//           -22.0,
//           -10.0,
//           1.0,
//           "M",
//         ],
//       },
//       {
//         1113: [
//           "3193",
//           6.47,
//           15.548,
//           7.6498,
//           7.8745,
//           7.7873,
//           7.79868,
//           "7.713",
//           0.0,
//           51.0,
//           2.0,
//           1.0,
//           -11.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1114: [
//           "2846",
//           33.3,
//           236.0,
//           37.2376,
//           38.205,
//           38.3539,
//           38.8348,
//           "38.198",
//           0.0,
//           51.0,
//           1.0,
//           2.0,
//           -3.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1115: [
//           "6882",
//           0.395,
//           9.14,
//           0.4155,
//           0.42345,
//           0.429633,
//           0.4572,
//           "0.425",
//           1.9846,
//           51.0,
//           9.0,
//           12.0,
//           21.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1116: [
//           "0611",
//           0.63,
//           87.614,
//           0.786,
//           0.8754,
//           0.7955,
//           0.747775,
//           "0.809",
//           8.2724,
//           51.0,
//           -10.0,
//           5.0,
//           -30.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1117: [
//           "0665",
//           1.61,
//           3040.0,
//           1.7734,
//           1.7468,
//           1.8764,
//           1.95335,
//           "1.843",
//           97.2,
//           51.0,
//           2.0,
//           8.0,
//           12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1118: [
//           "2088",
//           0.092,
//           2.356,
//           0.09566,
//           0.09809,
//           0.107453,
//           0.111295,
//           "0.106",
//           1.296,
//           51.0,
//           -5.0,
//           -8.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1119: [
//           "0721",
//           0.097,
//           11.218,
//           0.10192,
//           0.10682,
//           0.11194,
//           0.1145,
//           "0.112",
//           10.6,
//           51.0,
//           6.0,
//           5.0,
//           4.0,
//           0.0,
//           "-",
//         ],
//       },
//       {
//         1120: [
//           "0057",
//           2.09,
//           113.0,
//           2.3824,
//           2.489,
//           2.52213,
//           2.5794,
//           "2.496",
//           13.1,
//           51.0,
//           2.0,
//           4.0,
//           14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1121: [
//           "8151",
//           0.099,
//           0.198,
//           0.11486,
//           0.1291,
//           0.133327,
//           0.12547,
//           "0.133",
//           0.4158,
//           51.0,
//           8.0,
//           10.0,
//           -30.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1122: [
//           "3680",
//           5.22,
//           2033.0,
//           6.9752,
//           6.6789,
//           6.6926,
//           6.69945,
//           "6.679",
//           21.2,
//           51.0,
//           -13.0,
//           -26.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1123: [
//           "1630",
//           0.093,
//           21.043,
//           0.09672,
//           0.10982,
//           0.11188,
//           0.1089,
//           "0.112",
//           1.395,
//           51.0,
//           10.0,
//           18.0,
//           7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1124: [
//           "0184",
//           2.8,
//           4.38,
//           3.0112,
//           3.1176,
//           3.44793,
//           3.63155,
//           "3.333",
//           9.5256,
//           51.0,
//           13.0,
//           20.0,
//           12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1125: [
//           "2009",
//           1.14,
//           1937.0,
//           1.258,
//           1.242,
//           1.296,
//           1.3378,
//           "1.284",
//           26.6,
//           51.0,
//           0.0,
//           -2.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1126: [
//           "2827",
//           42.16,
//           29.204,
//           47.1416,
//           48.3748,
//           48.4565,
//           49.0095,
//           "48.267",
//           0.0,
//           51.0,
//           1.0,
//           1.0,
//           -5.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1127: [
//           "1196",
//           7.97,
//           1043.0,
//           9.2662,
//           9.8472,
//           10.0471,
//           10.4061,
//           "9.927",
//           114.7,
//           51.0,
//           5.0,
//           5.0,
//           -14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1128: [
//           "8037",
//           1.3,
//           244.0,
//           1.3526,
//           1.3619,
//           1.3822,
//           1.40665,
//           "1.379",
//           12.5,
//           51.0,
//           1.0,
//           -21.0,
//           7.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1129: [
//           "2283",
//           2.3,
//           44.16,
//           2.5018,
//           2.6022,
//           2.76393,
//           2.81455,
//           "2.71",
//           19.1,
//           50.0,
//           7.0,
//           8.0,
//           20.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1130: [
//           "9916",
//           0.84,
//           16.662,
//           0.951,
//           0.9697,
//           0.992867,
//           1.00915,
//           "0.98",
//           3.36,
//           50.0,
//           5.0,
//           -6.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1131: [
//           "3188",
//           51.76,
//           25533.0,
//           58.128,
//           59.6804,
//           59.8147,
//           60.5883,
//           "59.535",
//           0.0,
//           50.0,
//           1.0,
//           1.0,
//           -4.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1132: [
//           "9846",
//           4.258,
//           1.688,
//           4.77158,
//           4.89825,
//           4.92115,
//           4.98666,
//           "4.896",
//           0.0,
//           50.0,
//           2.0,
//           1.0,
//           -4.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1133: [
//           "1451",
//           0.485,
//           4.598,
//           0.523,
//           0.5603,
//           0.580867,
//           0.6011,
//           "0.57",
//           0.97,
//           50.0,
//           3.0,
//           25.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1134: [
//           "1988",
//           2.87,
//           8931.0,
//           3.0986,
//           3.0898,
//           3.1256,
//           3.284,
//           "3.106",
//           238.7,
//           50.0,
//           8.0,
//           11.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1135: [
//           "1977",
//           1.48,
//           116.0,
//           1.7226,
//           1.7842,
//           1.8178,
//           1.8502,
//           "1.794",
//           20.7,
//           50.0,
//           0.0,
//           -4.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1136: [
//           "1072",
//           8.04,
//           3762.0,
//           10.7746,
//           11.5943,
//           11.3825,
//           10.127,
//           "11.538",
//           27.3,
//           50.0,
//           -8.0,
//           -15.0,
//           -33.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1137: [
//           "3822",
//           0.086,
//           1.72,
//           0.08704,
//           0.09145,
//           0.0955867,
//           0.098975,
//           "0.094",
//           1.4448,
//           50.0,
//           12.0,
//           10.0,
//           12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1138: [
//           "2308",
//           1.06,
//           50.216,
//           1.215,
//           1.2364,
//           1.237,
//           1.25125,
//           "1.226",
//           3.2685,
//           50.0,
//           -2.0,
//           3.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1139: [
//           "0672",
//           0.28,
//           141.0,
//           0.3059,
//           0.3051,
//           0.3172,
//           0.33195,
//           "0.314",
//           15.7,
//           50.0,
//           13.0,
//           3.0,
//           -8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1140: [
//           "3130",
//           25.42,
//           22.341,
//           28.4332,
//           29.1932,
//           29.3249,
//           29.6969,
//           "29.169",
//           0.0,
//           50.0,
//           2.0,
//           1.0,
//           -3.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1141: [
//           "3877",
//           1.01,
//           358.0,
//           1.1518,
//           1.1212,
//           1.1722,
//           1.2387,
//           "1.16",
//           61.9,
//           50.0,
//           1.0,
//           -7.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1142: [
//           "1645",
//           0.79,
//           20.408,
//           0.832,
//           0.8522,
//           0.928133,
//           0.9904,
//           "0.897",
//           4.4554,
//           50.0,
//           5.0,
//           0.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1143: [
//           "2191",
//           3.22,
//           243.0,
//           3.4476,
//           3.4916,
//           3.7162,
//           3.88365,
//           "3.639",
//           25.7,
//           50.0,
//           6.0,
//           6.0,
//           7.0,
//           2.0,
//           "R",
//         ],
//       },
//       {
//         1144: [
//           "1621",
//           0.111,
//           1.089,
//           0.12914,
//           0.13279,
//           0.135473,
//           0.139205,
//           "0.133",
//           1.11,
//           50.0,
//           -7.0,
//           -36.0,
//           -19.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1145: [
//           "0467",
//           0.77,
//           3209.0000000000005,
//           0.9966,
//           0.8395,
//           0.9198,
//           0.99995,
//           "0.9",
//           202.4,
//           50.0,
//           -3.0,
//           -23.0,
//           -37.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1146: [
//           "1851",
//           1.08,
//           6.256,
//           1.0938,
//           1.1684,
//           1.22733,
//           1.27425,
//           "1.204",
//           5.4,
//           50.0,
//           9.0,
//           19.0,
//           21.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1147: [
//           "0893",
//           0.153,
//           5.422,
//           0.15968,
//           0.16688,
//           0.180333,
//           0.18631,
//           "0.176",
//           3.4409,
//           50.0,
//           12.0,
//           5.0,
//           14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1148: [
//           "1122",
//           1.39,
//           127.0,
//           1.5532,
//           1.6078,
//           1.65593,
//           1.6606,
//           "1.648",
//           17.2,
//           50.0,
//           2.0,
//           3.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1149: [
//           "8200",
//           1.2,
//           5.348,
//           1.3876,
//           1.4417,
//           1.5148,
//           1.57925,
//           "1.477",
//           0.9005,
//           50.0,
//           5.0,
//           9.0,
//           -22.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1150: [
//           "6069",
//           6.14,
//           892.0,
//           7.3428,
//           7.5661,
//           8.22573,
//           8.2055,
//           "8.015",
//           61.6,
//           50.0,
//           2.0,
//           -2.0,
//           -16.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1151: [
//           "3323",
//           8.44,
//           46508.0,
//           10.3132,
//           9.7894,
//           10.1267,
//           9.9325,
//           "10.119",
//           384.7,
//           50.0,
//           -5.0,
//           -21.0,
//           -26.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1152: [
//           "0485",
//           0.214,
//           12.578,
//           0.22456,
//           0.22527,
//           0.22748,
//           0.24303,
//           "0.225",
//           0.4554,
//           50.0,
//           5.0,
//           10.0,
//           20.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1153: [
//           "1117",
//           1.27,
//           2752.0,
//           1.3816,
//           1.3845,
//           1.4022,
//           1.4729,
//           "1.398",
//           100.5,
//           49.0,
//           5.0,
//           1.0,
//           7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1154: [
//           "3022",
//           476.8,
//           1.92,
//           541.692,
//           553.626,
//           562.779,
//           573.387,
//           "558.933",
//           0.0,
//           49.0,
//           4.0,
//           5.0,
//           0.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1155: [
//           "1695",
//           0.072,
//           0.354,
//           0.07154,
//           0.07386,
//           0.07796,
//           0.082855,
//           "0.076",
//           0.7776,
//           49.0,
//           15.0,
//           5.0,
//           8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1156: [
//           "1349",
//           3.54,
//           368.0,
//           3.8954,
//           3.9153,
//           4.00873,
//           4.25555,
//           "3.945",
//           12.0,
//           49.0,
//           -1.0,
//           2.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1157: [
//           "0358",
//           12.26,
//           14421.0,
//           13.5844,
//           13.4148,
//           13.9885,
//           14.5873,
//           "13.831",
//           170.10000000000005,
//           49.0,
//           0.0,
//           -25.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1158: [
//           "3012",
//           17.98,
//           8.109,
//           20.0616,
//           20.4997,
//           20.9121,
//           21.4811,
//           "20.718",
//           0.0,
//           49.0,
//           6.0,
//           8.0,
//           6.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1159: [
//           "1075",
//           0.34,
//           38.922,
//           0.3937,
//           0.42125,
//           0.430267,
//           0.418275,
//           "0.435",
//           2.6332,
//           49.0,
//           -3.0,
//           -13.0,
//           -14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1160: [
//           "1643",
//           0.63,
//           45.801,
//           0.801,
//           0.7359,
//           0.745333,
//           0.78775,
//           "0.735",
//           3.78,
//           49.0,
//           -2.0,
//           -15.0,
//           -17.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1161: [
//           "0960",
//           33.45,
//           72327.0,
//           42.214,
//           40.0875,
//           38.4853,
//           39.577,
//           "38.777",
//           2032.1,
//           49.0,
//           -10.0,
//           -22.0,
//           -27.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1162: [
//           "0760",
//           0.019,
//           1.71,
//           0.01984,
//           0.02025,
//           0.02118,
//           0.02305,
//           "0.021",
//           1.9556,
//           49.0,
//           10.0,
//           12.0,
//           21.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1163: [
//           "0292",
//           0.175,
//           0.175,
//           0.18654,
//           0.19374,
//           0.196847,
//           0.20745,
//           "0.194",
//           3.5315,
//           49.0,
//           10.0,
//           22.0,
//           15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1164: [
//           "0114",
//           0.5,
//           1.25,
//           0.5454,
//           0.5484,
//           0.5768,
//           0.59805,
//           "0.567",
//           3.0224,
//           49.0,
//           8.0,
//           -3.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1165: [
//           "6966",
//           0.4,
//           2.4,
//           0.4223,
//           0.4333,
//           0.434,
//           0.4415,
//           "0.435",
//           4.0,
//           49.0,
//           -13.0,
//           -28.0,
//           -18.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1166: [
//           "2811",
//           17.87,
//           0.0,
//           20.023,
//           20.6129,
//           20.6545,
//           20.9268,
//           "20.565",
//           0.0,
//           49.0,
//           2.0,
//           0.0,
//           -3.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1167: [
//           "1166",
//           0.06,
//           0.118,
//           0.06634,
//           0.0733,
//           0.0739,
//           0.07564,
//           "0.074",
//           1.4247,
//           49.0,
//           -3.0,
//           -9.0,
//           -7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1168: [
//           "0563",
//           0.61,
//           122.0,
//           0.7392,
//           0.7231,
//           0.698933,
//           0.7067,
//           "0.702",
//           29.3,
//           49.0,
//           -6.0,
//           -15.0,
//           -28.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1169: [
//           "2309",
//           0.118,
//           1.356,
//           0.115,
//           0.11769,
//           0.12434,
//           0.13524,
//           "0.123",
//           22.7,
//           49.0,
//           -4.0,
//           10.0,
//           15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1170: [
//           "1640",
//           0.34,
//           0.67,
//           0.384,
//           0.41425,
//           0.4639,
//           0.500875,
//           "0.445",
//           1.36,
//           49.0,
//           -1.0,
//           -9.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1171: [
//           "0119",
//           1.7,
//           3574.0,
//           2.1072,
//           2.039,
//           2.0336,
//           2.0384,
//           "2.025",
//           63.1,
//           49.0,
//           0.0,
//           -17.0,
//           -23.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1172: [
//           "1632",
//           0.54,
//           5.668,
//           0.624,
//           0.5926,
//           0.5793,
//           0.601275,
//           "0.573",
//           4.6381,
//           49.0,
//           -10.0,
//           -39.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1173: [
//           "0757",
//           0.365,
//           510.0,
//           0.3732,
//           0.411,
//           0.4405,
//           0.43965,
//           "0.435",
//           12.1,
//           49.0,
//           -16.0,
//           22.0,
//           23.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1174: [
//           "3989",
//           0.136,
//           64.087,
//           0.16476,
//           0.17434,
//           0.18078,
//           0.1727,
//           "0.178",
//           19.4,
//           49.0,
//           1.0,
//           -13.0,
//           -9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1175: [
//           "3127",
//           17.56,
//           0.0,
//           19.7524,
//           20.35,
//           20.4209,
//           20.6886,
//           "20.309",
//           0.0,
//           49.0,
//           2.0,
//           2.0,
//           -3.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1176: [
//           "0041",
//           18.54,
//           1499.0,
//           20.9316,
//           20.7409,
//           21.5171,
//           22.6228,
//           "21.153",
//           135.5,
//           48.0,
//           7.0,
//           7.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1177: [
//           "3658",
//           2.0,
//           23.101,
//           2.2484,
//           2.1635,
//           2.1578,
//           2.371,
//           "2.159",
//           16.2,
//           48.0,
//           7.0,
//           3.0,
//           7.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         1178: [
//           "0038",
//           3.32,
//           700.0,
//           3.9152,
//           3.8829,
//           3.96813,
//           4.1104,
//           "3.918",
//           13.0,
//           48.0,
//           2.0,
//           -4.0,
//           -14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1179: [
//           "6893",
//           0.31,
//           2.665,
//           0.3219,
//           0.33285,
//           0.3366,
//           0.3461,
//           "0.334",
//           3.3845,
//           48.0,
//           11.0,
//           2.0,
//           15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1180: [
//           "6896",
//           1.41,
//           53.592,
//           1.576,
//           1.9238,
//           2.13453,
//           2.0508,
//           "2.079",
//           10.4,
//           48.0,
//           -3.0,
//           -1.0,
//           24.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1181: [
//           "1225",
//           0.228,
//           5.598,
//           0.26714,
//           0.29427,
//           0.336947,
//           0.43961,
//           "-",
//           0.5251,
//           48.0,
//           5.0,
//           -14.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1182: [
//           "3003",
//           6.335,
//           1727.0,
//           7.1319,
//           "-",
//           "-",
//           "-",
//           "-",
//           0.0,
//           48.0,
//           2.0,
//           5.0,
//           -1.0,
//           1.0,
//           "E",
//         ],
//       },
//       {
//         1183: [
//           "0333",
//           0.455,
//           1.667,
//           0.4999,
//           0.51075,
//           0.532367,
//           0.555875,
//           "0.524",
//           1.3697,
//           48.0,
//           7.0,
//           9.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1184: [
//           "8496",
//           0.45,
//           0.338,
//           0.4901,
//           0.4956,
//           0.4886,
//           0.48955,
//           "0.488",
//           1.08,
//           48.0,
//           -1.0,
//           -15.0,
//           10.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1185: [
//           "0240",
//           0.83,
//           73.82,
//           0.9128,
//           0.9499,
//           0.966133,
//           0.9993,
//           "0.955",
//           10.3,
//           48.0,
//           -2.0,
//           3.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1186: [
//           "1057",
//           1.64,
//           369.0,
//           2.016,
//           1.9511,
//           1.89267,
//           1.9785,
//           "1.885",
//           3.5552,
//           48.0,
//           -1.0,
//           -26.0,
//           -38.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1187: [
//           "1975",
//           0.57,
//           64.928,
//           0.7136,
//           0.7163,
//           0.723533,
//           0.70855,
//           "0.727",
//           2.736,
//           48.0,
//           -5.0,
//           -26.0,
//           -38.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1188: [
//           "2116",
//           0.31,
//           0.96,
//           0.3401,
//           0.35835,
//           0.367033,
//           0.3768,
//           "0.364",
//           1.488,
//           48.0,
//           2.0,
//           -12.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1189: [
//           "9173",
//           1.298,
//           27.237,
//           1.46812,
//           1.53086,
//           1.52984,
//           1.55262,
//           "1.52",
//           0.0,
//           48.0,
//           0.0,
//           2.0,
//           -1.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1190: [
//           "3111",
//           2.592,
//           1896.0,
//           2.91056,
//           "-",
//           "-",
//           "-",
//           "-",
//           0.0,
//           48.0,
//           0.0,
//           3.0,
//           -2.0,
//           1.0,
//           "E",
//         ],
//       },
//       {
//         1191: [
//           "1841",
//           0.365,
//           0.73,
//           0.4201,
//           0.4491,
//           0.4855,
//           0.485275,
//           "0.469",
//           1.46,
//           48.0,
//           0.0,
//           7.0,
//           -7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1192: [
//           "2839",
//           27.98,
//           7311.0,
//           31.4556,
//           "-",
//           "-",
//           "-",
//           "-",
//           0.0,
//           48.0,
//           2.0,
//           4.0,
//           -2.0,
//           1.0,
//           "E",
//         ],
//       },
//       {
//         1193: [
//           "2223",
//           0.54,
//           2.708,
//           0.6344,
//           0.6476,
//           0.653667,
//           0.66945,
//           "0.648",
//           1.3924,
//           48.0,
//           -5.0,
//           -4.0,
//           14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1194: [
//           "6988",
//           2.05,
//           1691.0,
//           2.3138,
//           2.2659,
//           2.30187,
//           2.44055,
//           "2.289",
//           44.7,
//           48.0,
//           2.0,
//           5.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1195: [
//           "2832",
//           9.48,
//           0.948,
//           10.5779,
//           11.2424,
//           11.4468,
//           11.7349,
//           "11.232",
//           0.0,
//           48.0,
//           3.0,
//           3.0,
//           3.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1196: [
//           "0973",
//           22.25,
//           482.0,
//           29.186,
//           29.414,
//           28.5203,
//           28.3795,
//           "28.362",
//           328.6,
//           48.0,
//           1.0,
//           11.0,
//           -29.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1197: [
//           "9022",
//           60.92,
//           0.0,
//           69.4772,
//           70.9914,
//           72.216,
//           73.6319,
//           "71.696",
//           0.0,
//           48.0,
//           4.0,
//           4.0,
//           -1.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1198: [
//           "9839",
//           3.574,
//           57.165,
//           4.03256,
//           "-",
//           "-",
//           "-",
//           "-",
//           0.0,
//           48.0,
//           2.0,
//           5.0,
//           0.0,
//           1.0,
//           "E",
//         ],
//       },
//       {
//         1199: [
//           "2678",
//           8.76,
//           1158.0,
//           10.1934,
//           10.5923,
//           10.8733,
//           11.1388,
//           "10.743",
//           80.39999999999998,
//           47.0,
//           2.0,
//           7.0,
//           10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1200: [
//           "1355",
//           0.275,
//           41.85,
//           0.3049,
//           0.32685,
//           0.330167,
//           0.330825,
//           "0.329",
//           1.233,
//           47.0,
//           -10.0,
//           -20.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1201: [
//           "0154",
//           0.47,
//           18.447,
//           0.5435,
//           0.57765,
//           0.588567,
//           0.577725,
//           "0.591",
//           7.0516,
//           47.0,
//           3.0,
//           5.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1202: [
//           "0092",
//           0.088,
//           196.0,
//           0.10268,
//           0.105042,
//           0.102575,
//           0.101186,
//           "0.103",
//           2.407,
//           47.0,
//           -6.0,
//           1.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1203: [
//           "0475",
//           0.72,
//           31.548,
//           0.842,
//           0.9262,
//           0.9584,
//           0.91235,
//           "0.951",
//           2.7904,
//           47.0,
//           -7.0,
//           -7.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1204: [
//           "1980",
//           0.81,
//           5.175,
//           0.8894,
//           0.8809,
//           0.928667,
//           0.999,
//           "0.909",
//           10.2,
//           47.0,
//           4.0,
//           -3.0,
//           7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1205: [
//           "0677",
//           0.46,
//           65.627,
//           0.5083,
//           0.51755,
//           0.539767,
//           0.551675,
//           "0.532",
//           7.808,
//           47.0,
//           1.0,
//           -4.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1206: [
//           "0276",
//           0.88,
//           13.655,
//           1.0702,
//           1.0484,
//           0.9918,
//           0.9871,
//           "1.006",
//           1.6555,
//           47.0,
//           4.0,
//           -5.0,
//           -31.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1207: [
//           "3308",
//           5.51,
//           65.015,
//           5.902,
//           6.2931,
//           6.4764,
//           6.5716,
//           "6.413",
//           91.4,
//           47.0,
//           8.0,
//           10.0,
//           17.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1208: [
//           "0495",
//           0.111,
//           2.07,
//           0.13808,
//           0.13751,
//           0.145753,
//           0.143725,
//           "0.142",
//           1.5555,
//           47.0,
//           -35.0,
//           -31.0,
//           -34.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1209: [
//           "9858",
//           3.68,
//           1566.0,
//           4.3158,
//           4.3365,
//           4.4754,
//           "-",
//           "4.425",
//           139.6,
//           47.0,
//           2.0,
//           -4.0,
//           -16.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         1210: [
//           "0173",
//           2.74,
//           712.0,
//           3.0288,
//           3.058,
//           3.15507,
//           3.2992,
//           "3.108",
//           85.8,
//           47.0,
//           6.0,
//           5.0,
//           8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1211: [
//           "2153",
//           1.08,
//           44.122,
//           1.1788,
//           1.1911,
//           1.2496,
//           1.31345,
//           "1.226",
//           12.6,
//           47.0,
//           -2.0,
//           -2.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1212: [
//           "0338",
//           1.54,
//           4874.0,
//           1.7794,
//           1.7942,
//           1.81333,
//           1.8143,
//           "1.82",
//           53.8,
//           47.0,
//           2.0,
//           3.0,
//           -12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1213: [
//           "0210",
//           0.135,
//           5.161,
//           0.1642,
//           0.16178,
//           0.165267,
//           0.16675,
//           "0.164",
//           2.4489,
//           47.0,
//           -14.0,
//           11.0,
//           -5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1214: [
//           "8668",
//           0.122,
//           0.233,
//           0.13744,
//           0.14699,
//           0.1517,
//           0.151765,
//           "0.151",
//           1.464,
//           47.0,
//           5.0,
//           -2.0,
//           10.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1215: [
//           "1176",
//           1.34,
//           581.0,
//           1.5792,
//           1.589,
//           1.62667,
//           1.6818,
//           "1.627",
//           96.8,
//           47.0,
//           0.0,
//           -3.0,
//           7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1216: [
//           "3023",
//           115.4,
//           0.0,
//           128.428,
//           136.802,
//           138.891,
//           139.027,
//           "137.823",
//           0.0,
//           47.0,
//           5.0,
//           8.0,
//           6.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1217: [
//           "0262",
//           0.093,
//           16.912,
//           0.10732,
//           0.104488,
//           0.106539,
//           0.111724,
//           "0.106",
//           1.3641,
//           47.0,
//           -13.0,
//           -2.0,
//           -14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1218: [
//           "0124",
//           0.7,
//           15.954,
//           0.833,
//           0.7915,
//           0.8018,
//           0.85415,
//           "0.795",
//           11.9,
//           47.0,
//           -10.0,
//           -5.0,
//           -17.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1219: [
//           "3331",
//           17.94,
//           2412.0,
//           20.5632,
//           20.7695,
//           21.3023,
//           21.8175,
//           "21.221",
//           215.7,
//           47.0,
//           5.0,
//           0.0,
//           -21.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1220: [
//           "1750",
//           0.042,
//           0.691,
//           0.04578,
//           0.04888,
//           0.0507733,
//           0.05213,
//           "0.05",
//           0.756,
//           47.0,
//           8.0,
//           14.0,
//           15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1221: [
//           "0918",
//           0.32,
//           20.16,
//           0.3334,
//           0.33665,
//           0.351167,
//           0.360887,
//           "0.345",
//           2.4481,
//           47.0,
//           9.0,
//           10.0,
//           7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1222: [
//           "2588",
//           51.1,
//           12340.0,
//           65.275,
//           63.824,
//           63.3793,
//           63.7842,
//           "63.358",
//           354.6,
//           46.0,
//           3.0,
//           -7.0,
//           -34.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1223: [
//           "2238",
//           6.25,
//           14678.0,
//           7.7024,
//           7.7431,
//           7.65713,
//           7.48235,
//           "7.664",
//           193.6,
//           46.0,
//           2.0,
//           0.0,
//           -31.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1224: [
//           "2848",
//           620.0,
//           6.2,
//           673.236,
//           697.334,
//           718.501,
//           747.364,
//           "708.633",
//           0.0,
//           46.0,
//           7.0,
//           10.0,
//           8.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1225: [
//           "2807",
//           51.72,
//           147.0,
//           60.2568,
//           63.0052,
//           63.2087,
//           63.8525,
//           "62.563",
//           0.0,
//           46.0,
//           -1.0,
//           1.0,
//           -9.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1226: [
//           "2843",
//           16.4,
//           1.333,
//           18.3974,
//           18.8488,
//           18.8215,
//           19.3055,
//           "18.733",
//           0.0,
//           46.0,
//           1.0,
//           1.0,
//           -4.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1227: [
//           "0280",
//           0.33,
//           32.356,
//           0.3945,
//           0.42055,
//           0.429967,
//           0.435775,
//           "0.422",
//           3.0084,
//           46.0,
//           -7.0,
//           -3.0,
//           -10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1228: [
//           "3010",
//           52.94,
//           85.71,
//           59.9924,
//           61.5542,
//           62.5888,
//           64.0401,
//           "62.137",
//           0.0,
//           46.0,
//           2.0,
//           5.0,
//           0.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1229: [
//           "1982",
//           0.415,
//           10.699,
//           0.4891,
//           0.49885,
//           0.5074,
//           0.5369,
//           "0.5",
//           9.4594,
//           46.0,
//           -1.0,
//           -2.0,
//           -8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1230: [
//           "2377",
//           1.21,
//           21.767,
//           1.3886,
//           1.3761,
//           1.47167,
//           1.5522,
//           "1.418",
//           12.1,
//           46.0,
//           12.0,
//           4.0,
//           13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1231: [
//           "0687",
//           0.35,
//           2.249,
//           0.3787,
//           0.3868,
//           0.3987,
//           0.4195,
//           "0.392",
//           11.7,
//           46.0,
//           5.0,
//           4.0,
//           15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1232: [
//           "0383",
//           0.078,
//           8.671,
//           0.07212,
//           0.07976,
//           0.0880867,
//           0.094375,
//           "0.085",
//           11.2,
//           46.0,
//           4.0,
//           4.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1233: [
//           "1372",
//           3.76,
//           103.0,
//           4.355,
//           4.2505,
//           4.46767,
//           4.787,
//           "4.38",
//           11.4,
//           46.0,
//           -4.0,
//           -3.0,
//           -15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1234: [
//           "0185",
//           3.5,
//           517.0,
//           4.2764,
//           4.1553,
//           4.55227,
//           4.9227,
//           "4.374",
//           66.9,
//           46.0,
//           -1.0,
//           8.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1235: [
//           "8609",
//           0.245,
//           3.69,
//           0.25824,
//           0.25533,
//           0.274553,
//           0.28939,
//           "0.27",
//           1.225,
//           46.0,
//           6.0,
//           12.0,
//           -15.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1236: [
//           "8490",
//           0.275,
//           0.137,
//           0.3108,
//           0.3288,
//           0.32538,
//           0.321985,
//           "0.325",
//           1.9401,
//           46.0,
//           6.0,
//           -4.0,
//           16.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1237: [
//           "3151",
//           8.89,
//           217.0,
//           9.8899,
//           10.5086,
//           10.6954,
//           "-",
//           "10.525",
//           0.0,
//           46.0,
//           2.0,
//           4.0,
//           4.0,
//           2.0,
//           "E",
//         ],
//       },
//       {
//         1238: [
//           "0564",
//           7.17,
//           1332.0,
//           8.214,
//           8.2205,
//           8.49493,
//           8.65155,
//           "8.424",
//           17.4,
//           46.0,
//           2.0,
//           3.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1239: [
//           "1212",
//           4.1,
//           188.0,
//           4.3452,
//           4.3415,
//           4.5236,
//           4.8684,
//           "4.414",
//           61.5,
//           46.0,
//           3.0,
//           2.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1240: [
//           "0814",
//           0.88,
//           7.261,
//           0.9592,
//           0.9899,
//           1.038,
//           1.07005,
//           "1.02",
//           1.603,
//           46.0,
//           5.0,
//           2.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1241: [
//           "0943",
//           0.019,
//           13.816,
//           0.02148,
//           0.0226,
//           0.0228733,
//           0.02394,
//           "0.023",
//           2.0371,
//           46.0,
//           8.0,
//           3.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1242: [
//           "1382",
//           3.45,
//           2161.0,
//           3.8536,
//           3.8779,
//           3.952,
//           4.1479,
//           "3.915",
//           48.6,
//           46.0,
//           2.0,
//           10.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1243: [
//           "3109",
//           12.18,
//           873.0,
//           13.7126,
//           14.5896,
//           14.9,
//           15.2892,
//           "14.626",
//           0.0,
//           46.0,
//           0.0,
//           3.0,
//           0.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1244: [
//           "6913",
//           0.97,
//           45.078,
//           1.059,
//           1.1428,
//           1.2032,
//           "-",
//           "1.186",
//           12.9,
//           46.0,
//           12.0,
//           19.0,
//           22.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         1245: [
//           "0788",
//           0.82,
//           17808.0,
//           0.9184,
//           0.945,
//           0.974733,
//           0.997,
//           "0.964",
//           382.6,
//           46.0,
//           4.0,
//           7.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1246: [
//           "7261",
//           19.18,
//           397.0,
//           23.27,
//           25.7182,
//           25.6096,
//           24.9644,
//           "25.36",
//           0.0,
//           45.0,
//           4.0,
//           2.0,
//           1.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1247: [
//           "1817",
//           4.36,
//           23.287,
//           4.7692,
//           5.2792,
//           5.46227,
//           5.6406,
//           "5.31",
//           41.4,
//           45.0,
//           13.0,
//           18.0,
//           22.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1248: [
//           "0353",
//           0.057,
//           1.771,
//           0.05378,
//           0.06191,
//           0.06494,
//           0.06596,
//           "0.064",
//           4.1072,
//           45.0,
//           6.0,
//           24.0,
//           23.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1249: [
//           "1408",
//           0.24,
//           2.036,
//           0.24498,
//           0.26607,
//           0.289147,
//           0.305185,
//           "0.283",
//           1.2,
//           45.0,
//           12.0,
//           22.0,
//           21.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1250: [
//           "2802",
//           54.46,
//           9.911,
//           61.8808,
//           63.5111,
//           64.5201,
//           65.9405,
//           "64.012",
//           0.0,
//           45.0,
//           4.0,
//           3.0,
//           1.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1251: [
//           "3360",
//           6.14,
//           2985.0,
//           6.8342,
//           6.9491,
//           7.439,
//           7.6456,
//           "7.278",
//           264.8,
//           45.0,
//           2.0,
//           0.0,
//           8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1252: [
//           "0978",
//           0.74,
//           41.092,
//           0.7898,
//           0.8027,
//           0.8544,
//           0.91345,
//           "0.839",
//           36.2,
//           45.0,
//           6.0,
//           9.0,
//           14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1253: [
//           "0165",
//           7.63,
//           2587.0,
//           8.8888,
//           8.8444,
//           9.03427,
//           9.06435,
//           "9.004",
//           128.5,
//           45.0,
//           4.0,
//           4.0,
//           -11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1254: [
//           "0497",
//           0.193,
//           0.705,
//           0.2073,
//           0.21329,
//           0.221987,
//           0.230175,
//           "0.218",
//           18.1,
//           45.0,
//           0.0,
//           -3.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1255: [
//           "2823",
//           15.52,
//           26890.0,
//           17.5688,
//           18.0923,
//           18.0313,
//           18.4445,
//           "17.973",
//           0.0,
//           45.0,
//           0.0,
//           1.0,
//           -5.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1256: [
//           "0126",
//           0.485,
//           7.214,
//           0.6099,
//           0.63005,
//           0.6743,
//           0.673225,
//           "0.657",
//           7.621,
//           45.0,
//           6.0,
//           6.0,
//           -18.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1257: [
//           "9608",
//           0.096,
//           1.659,
//           0.11522,
//           0.13136,
//           0.12326,
//           0.118455,
//           "0.125",
//           3.072,
//           45.0,
//           4.0,
//           -3.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1258: [
//           "9151",
//           1.136,
//           14.497,
//           1.2686,
//           1.34832,
//           1.3732,
//           "-",
//           "1.349",
//           0.0,
//           45.0,
//           1.0,
//           2.0,
//           3.0,
//           2.0,
//           "E",
//         ],
//       },
//       {
//         1259: [
//           "2281",
//           0.85,
//           16.955,
//           0.938,
//           1.01,
//           1.05907,
//           1.08223,
//           "1.039",
//           1.8269,
//           45.0,
//           4.0,
//           8.0,
//           14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1260: [
//           "0789",
//           0.082,
//           67.469,
//           0.08024,
//           0.08489,
//           0.0925133,
//           0.10177,
//           "0.089",
//           0.9052,
//           45.0,
//           13.0,
//           18.0,
//           27.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1261: [
//           "9807",
//           6.605,
//           136.0,
//           7.7257,
//           8.0835,
//           8.11447,
//           8.20263,
//           "8.03",
//           0.0,
//           45.0,
//           -1.0,
//           1.0,
//           -9.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1262: [
//           "0669",
//           121.0,
//           68559.0,
//           135.672,
//           149.103,
//           153.07,
//           149.016,
//           "150.997",
//           2219.7,
//           45.0,
//           -2.0,
//           2.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1263: [
//           "1907",
//           3.91,
//           2288.0,
//           4.6228,
//           4.6475,
//           4.84793,
//           4.96145,
//           "4.796",
//           173.6,
//           45.0,
//           -3.0,
//           -8.0,
//           -18.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1264: [
//           "1888",
//           11.7,
//           8774.0,
//           13.222,
//           13.1386,
//           13.4479,
//           14.273,
//           "13.241",
//           365.0,
//           45.0,
//           12.0,
//           14.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1265: [
//           "6133",
//           0.24,
//           1.042,
//           0.24452,
//           0.28084,
//           0.286527,
//           0.27878,
//           "0.288",
//           2.04,
//           45.0,
//           13.0,
//           8.0,
//           13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1266: [
//           "9010",
//           6.76,
//           2778.0,
//           7.6909,
//           7.8962,
//           8.0361,
//           8.22725,
//           "7.971",
//           0.0,
//           45.0,
//           2.0,
//           5.0,
//           0.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1267: [
//           "0922",
//           0.159,
//           6.68,
//           0.17772,
//           0.19097,
//           0.1974,
//           0.203245,
//           "0.195",
//           3.5319,
//           45.0,
//           2.0,
//           5.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1268: [
//           "0895",
//           3.33,
//           81.366,
//           3.6642,
//           3.6139,
//           3.71573,
//           3.87555,
//           "3.679",
//           6.6645,
//           45.0,
//           3.0,
//           2.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1269: [
//           "1133",
//           2.2,
//           1198.0,
//           2.921,
//           3.1554,
//           3.0854,
//           2.83635,
//           "3.101",
//           14.8,
//           44.0,
//           -9.0,
//           -12.0,
//           -37.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1270: [
//           "2310",
//           0.075,
//           0.165,
//           0.09622,
//           0.09052,
//           0.09686,
//           0.0949,
//           "0.097",
//           0.8196,
//           44.0,
//           17.0,
//           11.0,
//           -30.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1271: [
//           "1448",
//           5.34,
//           4875.0,
//           6.0178,
//           6.1819,
//           6.4262,
//           6.72825,
//           "6.308",
//           123.9,
//           44.0,
//           -2.0,
//           5.0,
//           14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1272: [
//           "0798",
//           0.33,
//           35.088,
//           0.3961,
//           0.4056,
//           0.415467,
//           0.42755,
//           "0.412",
//           24.9,
//           44.0,
//           8.0,
//           -9.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1273: [
//           "0512",
//           5.15,
//           2876.0,
//           6.0784,
//           6.1814,
//           6.21687,
//           6.42835,
//           "6.143",
//           182.8,
//           44.0,
//           -2.0,
//           -9.0,
//           -10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1274: [
//           "0513",
//           0.355,
//           3.315,
//           0.408,
//           0.41095,
//           0.411833,
//           0.431475,
//           "-",
//           2.425,
//           44.0,
//           -7.0,
//           3.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1275: [
//           "0031",
//           0.485,
//           169.0,
//           0.5249,
//           0.5346,
//           0.569333,
//           0.601,
//           "0.56",
//           14.9,
//           44.0,
//           5.0,
//           0.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1276: [
//           "8373",
//           0.245,
//           24.27,
//           0.26548,
//           0.27424,
//           0.277227,
//           0.286045,
//           "0.273",
//           0.98,
//           44.0,
//           4.0,
//           7.0,
//           12.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1277: [
//           "9091",
//           10.77,
//           0.0,
//           12.3,
//           12.9346,
//           12.9847,
//           13.1313,
//           "12.907",
//           0.0,
//           44.0,
//           4.0,
//           6.0,
//           3.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1278: [
//           "7266",
//           14.87,
//           206.0,
//           18.093,
//           19.9623,
//           19.881,
//           19.3897,
//           "19.689",
//           0.0,
//           44.0,
//           2.0,
//           0.0,
//           -1.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1279: [
//           "1343",
//           0.099,
//           1.111,
//           0.11012,
//           0.11637,
//           0.115393,
//           0.11839,
//           "0.115",
//           1.0533,
//           44.0,
//           1.0,
//           -21.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1280: [
//           "1243",
//           0.072,
//           33.51,
//           0.07808,
//           0.0816,
//           0.0865133,
//           0.090085,
//           "0.085",
//           10.9,
//           44.0,
//           5.0,
//           1.0,
//           14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1281: [
//           "0303",
//           54.0,
//           7438.0,
//           61.211,
//           61.3655,
//           64.011,
//           67.7243,
//           "62.552",
//           136.2,
//           44.0,
//           11.0,
//           8.0,
//           -10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1282: [
//           "0070",
//           0.068,
//           0.68,
//           0.07576,
//           0.0787,
//           0.0821667,
//           0.091335,
//           "0.08",
//           1.3183,
//           44.0,
//           9.0,
//           9.0,
//           13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1283: [
//           "1216",
//           0.82,
//           3.449,
//           0.8562,
//           0.9146,
//           0.971,
//           1.0021,
//           "0.953",
//           31.1,
//           44.0,
//           6.0,
//           12.0,
//           17.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1284: [
//           "2814",
//           16.96,
//           614.0,
//           19.974,
//           21.4974,
//           21.5639,
//           21.4599,
//           "21.395",
//           0.0,
//           44.0,
//           1.0,
//           4.0,
//           3.0,
//           2.0,
//           "E",
//         ],
//       },
//       {
//         1285: [
//           "2236",
//           0.325,
//           1.98,
//           0.3668,
//           0.38125,
//           0.387633,
//           0.387475,
//           "0.386",
//           13.2,
//           44.0,
//           -14.0,
//           8.0,
//           -17.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1286: [
//           "0927",
//           0.73,
//           16.672,
//           0.7932,
//           0.836,
//           0.8676,
//           0.88375,
//           "0.854",
//           3.1086,
//           44.0,
//           6.0,
//           5.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1287: [
//           "2318",
//           53.05,
//           210000.0,
//           61.192,
//           59.2155,
//           59.5597,
//           63.6205,
//           "58.782",
//           3950.9,
//           44.0,
//           5.0,
//           7.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1288: [
//           "3378",
//           0.78,
//           226.0,
//           0.8504,
//           0.8394,
//           0.8554,
//           0.8967,
//           "0.854",
//           7.6962,
//           44.0,
//           2.0,
//           4.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1289: [
//           "3091",
//           84.3,
//           1.862,
//           95.8916,
//           100.788,
//           101.129,
//           102.226,
//           "100.607",
//           0.0,
//           44.0,
//           4.0,
//           6.0,
//           3.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1290: [
//           "3990",
//           11.46,
//           2393.0,
//           12.1372,
//           12.5368,
//           13.0317,
//           13.9283,
//           "12.84",
//           141.4,
//           44.0,
//           5.0,
//           7.0,
//           21.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1291: [
//           "3789",
//           0.125,
//           4.092,
//           0.14352,
//           0.16421,
//           0.154907,
//           0.149685,
//           "0.157",
//           1.5,
//           44.0,
//           9.0,
//           -8.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1292: [
//           "1080",
//           0.065,
//           1.736,
//           0.07616,
//           0.07742,
//           0.0812067,
//           0.081,
//           "0.08",
//           2.5183,
//           43.0,
//           4.0,
//           -15.0,
//           -10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1293: [
//           "1389",
//           0.032,
//           0.5,
//           0.0389,
//           0.03998,
//           0.0390267,
//           0.039245,
//           "0.039",
//           1.0643,
//           43.0,
//           -8.0,
//           -8.0,
//           -19.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1294: [
//           "1332",
//           0.95,
//           156.0,
//           1.0132,
//           1.1379,
//           1.053,
//           1.0346,
//           "1.048",
//           26.6,
//           43.0,
//           7.0,
//           24.0,
//           24.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1295: [
//           "0535",
//           0.7,
//           4435.0,
//           0.8664,
//           0.8185,
//           0.8284,
//           0.8678,
//           "0.827",
//           116.1,
//           43.0,
//           -6.0,
//           -16.0,
//           -15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1296: [
//           "2822",
//           15.07,
//           19153.0,
//           17.0546,
//           17.6297,
//           17.6094,
//           18.0451,
//           "17.544",
//           0.0,
//           43.0,
//           0.0,
//           2.0,
//           -5.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1297: [
//           "8043",
//           0.155,
//           1.791,
//           0.18404,
//           0.20356,
//           0.204513,
//           0.19396,
//           "0.204",
//           0.62,
//           43.0,
//           3.0,
//           4.0,
//           -8.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1298: [
//           "3600",
//           3.04,
//           1433.0,
//           4.5744,
//           5.3081,
//           6.20233,
//           6.51555,
//           "5.943",
//           29.0,
//           43.0,
//           -14.0,
//           -22.0,
//           -32.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1299: [
//           "1126",
//           2.28,
//           4.56,
//           2.7142,
//           2.7656,
//           2.79647,
//           2.8395,
//           "2.765",
//           15.4,
//           43.0,
//           6.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1300: [
//           "0060",
//           0.54,
//           2.12,
//           0.6372,
//           0.6486,
//           0.650533,
//           0.67065,
//           "0.644",
//           1.4017,
//           43.0,
//           -1.0,
//           -7.0,
//           10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1301: [
//           "6890",
//           0.52,
//           6.4959999999999996,
//           0.604,
//           0.6219,
//           0.6342,
//           0.64775,
//           "0.628",
//           3.1525,
//           43.0,
//           -14.0,
//           -14.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1302: [
//           "1788",
//           0.97,
//           684.0,
//           1.0802,
//           1.0981,
//           1.16167,
//           1.1937,
//           "1.145",
//           93.3,
//           43.0,
//           4.0,
//           6.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1303: [
//           "2601",
//           19.6,
//           31772.0,
//           22.7998,
//           23.1574,
//           23.1666,
//           23.556,
//           "23.2",
//           543.9,
//           43.0,
//           5.0,
//           9.0,
//           8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1304: [
//           "2386",
//           3.3,
//           2297.0,
//           3.7866,
//           3.8425,
//           3.95007,
//           4.15635,
//           "3.868",
//           48.2,
//           43.0,
//           6.0,
//           2.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1305: [
//           "9814",
//           2.168,
//           16.941,
//           2.56172,
//           2.75802,
//           2.768,
//           2.75622,
//           "2.746",
//           0.0,
//           43.0,
//           1.0,
//           3.0,
//           4.0,
//           2.0,
//           "E",
//         ],
//       },
//       {
//         1306: [
//           "0014",
//           21.4,
//           5211.0,
//           23.688,
//           24.5325,
//           25.327,
//           26.6963,
//           "24.915",
//           221.3,
//           43.0,
//           8.0,
//           2.0,
//           10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1307: [
//           "2285",
//           44.3,
//           10125.0,
//           60.281,
//           "-",
//           "-",
//           "-",
//           "-",
//           217.1,
//           43.0,
//           -32.0,
//           -45.0,
//           -46.0,
//           1.0,
//           "M",
//         ],
//       },
//       {
//         1308: [
//           "9866",
//           125.7,
//           3949.0,
//           "-",
//           "-",
//           "-",
//           "-",
//           "-",
//           2127.2,
//           43.0,
//           -19.0,
//           "-",
//           "-",
//           "-",
//           "-",
//         ],
//       },
//       {
//         1309: [
//           "1421",
//           0.06,
//           16.784,
//           0.0708,
//           0.07525,
//           0.0795867,
//           0.08174,
//           "0.078",
//           0.8341,
//           43.0,
//           5.0,
//           9.0,
//           13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1310: [
//           "0468",
//           2.59,
//           273.0,
//           2.8874,
//           2.8746,
//           2.9664,
//           3.1102,
//           "2.934",
//           34.6,
//           43.0,
//           3.0,
//           4.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1311: [
//           "1468",
//           0.56,
//           95.41,
//           0.6214,
//           0.6624,
//           0.688133,
//           0.6921,
//           "0.678",
//           37.6,
//           43.0,
//           11.0,
//           -9.0,
//           -17.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1312: [
//           "6888",
//           0.24,
//           6.484,
//           0.2671,
//           0.2804,
//           0.283167,
//           0.289175,
//           "0.28",
//           2.5896,
//           43.0,
//           3.0,
//           -1.0,
//           8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1313: [
//           "1532",
//           0.176,
//           0.144,
//           0.17788,
//           0.19533,
//           0.200793,
//           0.2013,
//           "0.203",
//           1.8959,
//           43.0,
//           12.0,
//           11.0,
//           13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1314: [
//           "2317",
//           0.61,
//           13.672,
//           0.6906,
//           0.7302,
//           0.737733,
//           0.744,
//           "0.733",
//           9.2887,
//           43.0,
//           -5.0,
//           -8.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1315: [
//           "6178",
//           5.13,
//           692.0,
//           5.9446,
//           5.9894,
//           6.08113,
//           6.17925,
//           "6.036",
//           36.1,
//           43.0,
//           -1.0,
//           -4.0,
//           -8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1316: [
//           "8201",
//           0.086,
//           0.716,
//           0.09702,
//           0.10002,
//           0.0978333,
//           0.098095,
//           "0.097",
//           0.4644,
//           42.0,
//           3.0,
//           -7.0,
//           -17.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1317: [
//           "0355",
//           0.34,
//           6.8,
//           0.3772,
//           0.40355,
//           0.4163,
//           0.422225,
//           "0.412",
//           10.5,
//           42.0,
//           6.0,
//           7.0,
//           12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1318: [
//           "3073",
//           42.5,
//           130.0,
//           49.8444,
//           50.9162,
//           51.4176,
//           52.765,
//           "51.007",
//           0.0,
//           42.0,
//           2.0,
//           -1.0,
//           -4.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1319: [
//           "1613",
//           0.183,
//           191.0,
//           0.19992,
//           0.19576,
//           0.239687,
//           0.25918,
//           "0.224",
//           2.2918,
//           42.0,
//           2.0,
//           13.0,
//           12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1320: [
//           "3688",
//           0.89,
//           1.492,
//           0.9982,
//           1.0512,
//           1.09687,
//           1.1626,
//           "1.083",
//           12.5,
//           42.0,
//           10.0,
//           8.0,
//           7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1321: [
//           "2210",
//           4.31,
//           1.899,
//           4.8238,
//           "-",
//           "-",
//           "-",
//           "-",
//           1.5803,
//           42.0,
//           12.0,
//           24.0,
//           32.0,
//           1.0,
//           "M",
//         ],
//       },
//       {
//         1322: [
//           "0220",
//           6.37,
//           4165.0,
//           7.6682,
//           7.4621,
//           7.42273,
//           7.65225,
//           "7.4",
//           275.1,
//           42.0,
//           1.0,
//           -17.0,
//           -32.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1323: [
//           "0027",
//           39.0,
//           122000.0,
//           44.003,
//           43.3845,
//           43.9983,
//           47.8517,
//           "43.432",
//           1700.2,
//           42.0,
//           5.0,
//           13.0,
//           10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1324: [
//           "3616",
//           0.95,
//           39.736,
//           0.991,
//           1.0293,
//           1.17733,
//           1.22895,
//           "1.135",
//           11.4,
//           42.0,
//           7.0,
//           4.0,
//           19.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1325: [
//           "6677",
//           3.45,
//           372.0,
//           4.2584,
//           4.1333,
//           4.35173,
//           4.72185,
//           "4.293",
//           40.8,
//           42.0,
//           -9.0,
//           -4.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1326: [
//           "1959",
//           0.56,
//           5.624,
//           0.5936,
//           0.6554,
//           0.705267,
//           0.72625,
//           "0.692",
//           2.8291,
//           42.0,
//           9.0,
//           21.0,
//           25.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1327: [
//           "8148",
//           0.018,
//           0.104,
//           0.01986,
//           0.02152,
//           0.02162,
//           0.021875,
//           "0.022",
//           0.229,
//           42.0,
//           1.0,
//           17.0,
//           13.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1328: [
//           "3147",
//           10.81,
//           736.0,
//           12.6592,
//           13.6431,
//           13.6834,
//           13.8274,
//           "13.548",
//           0.0,
//           42.0,
//           -3.0,
//           2.0,
//           -7.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1329: [
//           "1168",
//           0.222,
//           32.825,
//           0.23266,
//           0.23997,
//           0.250293,
//           0.263295,
//           "0.246",
//           14.1,
//           42.0,
//           6.0,
//           5.0,
//           13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1330: [
//           "1970",
//           9.99,
//           558.0,
//           11.113,
//           11.6335,
//           11.449,
//           11.5024,
//           "11.492",
//           34.1,
//           42.0,
//           12.0,
//           10.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1331: [
//           "1548",
//           20.7,
//           47795.0,
//           28.699,
//           31.9015,
//           32.443,
//           32.8947,
//           "31.72",
//           435.3,
//           42.0,
//           -18.0,
//           -38.0,
//           -45.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1332: [
//           "1653",
//           0.27,
//           2.74,
//           0.2996,
//           0.31965,
//           0.32,
//           0.32825,
//           "0.318",
//           0.648,
//           42.0,
//           0.0,
//           10.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1333: [
//           "3882",
//           0.145,
//           3.286,
//           0.16682,
//           0.17158,
//           0.178133,
//           0.181295,
//           "0.174",
//           1.3814,
//           42.0,
//           18.0,
//           12.0,
//           -8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1334: [
//           "0595",
//           0.475,
//           19.266,
//           0.5207,
//           0.54795,
//           0.560367,
//           0.586675,
//           "0.555",
//           4.3161,
//           42.0,
//           11.0,
//           5.0,
//           8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1335: [
//           "1027",
//           0.53,
//           5.312,
//           0.5454,
//           0.5754,
//           0.608267,
//           0.6307,
//           "0.597",
//           1.6052,
//           42.0,
//           2.0,
//           6.0,
//           6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1336: [
//           "2195",
//           0.295,
//           32.935,
//           0.319,
//           0.34345,
//           0.364633,
//           0.37135,
//           "0.355",
//           2.95,
//           42.0,
//           -1.0,
//           5.0,
//           13.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         1337: [
//           "2339",
//           0.53,
//           12.092,
//           0.602,
//           0.7028,
//           0.717933,
//           0.70195,
//           "0.728",
//           3.0439,
//           42.0,
//           7.0,
//           9.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1338: [
//           "6058",
//           0.164,
//           21.26,
//           0.17454,
//           0.18434,
//           0.18828,
//           0.19229,
//           "0.189",
//           6.56,
//           42.0,
//           2.0,
//           8.0,
//           14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1339: [
//           "1148",
//           0.495,
//           96.427,
//           0.6975,
//           0.82345,
//           0.8249,
//           0.741275,
//           "0.836",
//           6.3469,
//           41.0,
//           -22.0,
//           -30.0,
//           -46.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1340: [
//           "8476",
//           0.65,
//           31.952,
//           0.8048,
//           0.8411,
//           0.828867,
//           0.825,
//           "0.827",
//           1.82,
//           41.0,
//           -3.0,
//           -2.0,
//           -10.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1341: [
//           "1776",
//           10.26,
//           8211.0,
//           13.1784,
//           13.5844,
//           13.6917,
//           12.8987,
//           "13.605",
//           174.6,
//           41.0,
//           -1.0,
//           1.0,
//           -13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1342: [
//           "3303",
//           0.8,
//           52.472,
//           0.8834,
//           0.9163,
//           0.946867,
//           1.029,
//           "0.94",
//           13.4,
//           41.0,
//           -7.0,
//           -5.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1343: [
//           "3399",
//           0.99,
//           0.578,
//           1.0784,
//           1.0967,
//           1.18307,
//           1.2498,
//           "1.145",
//           2.0493,
//           41.0,
//           6.0,
//           9.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1344: [
//           "0952",
//           0.171,
//           3.116,
//           0.19326,
//           0.19571,
//           0.203493,
//           0.21563,
//           "0.201",
//           10.5,
//           41.0,
//           -9.0,
//           6.0,
//           -13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1345: [
//           "1832",
//           0.71,
//           23.05,
//           0.8512,
//           0.9114,
//           0.933,
//           0.9414,
//           "0.918",
//           2.556,
//           41.0,
//           8.0,
//           15.0,
//           -19.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1346: [
//           "1528",
//           3.4,
//           229.0,
//           3.9044,
//           3.9559,
//           4.20053,
//           4.4215,
//           "4.153",
//           25.2,
//           41.0,
//           3.0,
//           -4.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1347: [
//           "3326",
//           0.235,
//           1.245,
//           0.27322,
//           0.27816,
//           0.282173,
//           0.291005,
//           "0.278",
//           3.1725,
//           41.0,
//           -1.0,
//           2.0,
//           -7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1348: [
//           "0171",
//           0.445,
//           0.0,
//           0.4786,
//           0.4892,
//           0.517733,
//           0.54625,
//           "0.507",
//           10.2,
//           41.0,
//           6.0,
//           3.0,
//           13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1349: [
//           "3938",
//           0.215,
//           0.301,
//           0.23218,
//           0.24275,
//           0.254833,
//           0.275025,
//           "0.251",
//           0.8728,
//           41.0,
//           11.0,
//           18.0,
//           8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1350: [
//           "0775",
//           0.62,
//           283.0,
//           0.7138,
//           0.7252,
//           0.7392,
//           0.7643,
//           "0.728",
//           59.5,
//           41.0,
//           4.0,
//           4.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1351: [
//           "1683",
//           0.128,
//           18.843,
//           0.18694,
//           0.17913,
//           0.155107,
//           0.146685,
//           "0.159",
//           0.9216,
//           41.0,
//           -16.0,
//           -13.0,
//           -9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1352: [
//           "0163",
//           0.82,
//           32.526,
//           0.9038,
//           0.9548,
//           0.9926,
//           1.03015,
//           "0.978",
//           30.1,
//           41.0,
//           5.0,
//           6.0,
//           7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1353: [
//           "0690",
//           0.074,
//           5.128,
//           0.0883,
//           0.08995,
//           0.0930733,
//           0.095685,
//           "0.092",
//           4.6988,
//           41.0,
//           -1.0,
//           8.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1354: [
//           "6066",
//           7.27,
//           5625.0,
//           8.5678,
//           8.5077,
//           8.58687,
//           8.69465,
//           "8.583",
//           91.6,
//           41.0,
//           2.0,
//           3.0,
//           -14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1355: [
//           "0730",
//           0.126,
//           12.846,
//           0.14548,
//           0.15081,
//           0.146173,
//           0.14666,
//           "0.146",
//           5.0206,
//           41.0,
//           5.0,
//           -3.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1356: [
//           "0499",
//           0.23,
//           12.208,
//           0.25708,
//           0.25442,
//           0.260913,
//           0.276735,
//           "0.259",
//           2.2966,
//           41.0,
//           4.0,
//           -4.0,
//           -21.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1357: [
//           "8391",
//           0.69,
//           16.74,
//           0.7734,
//           0.7905,
//           0.862933,
//           0.9277,
//           "0.846",
//           4.1937,
//           41.0,
//           1.0,
//           -16.0,
//           -18.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1358: [
//           "0191",
//           3.48,
//           1.358,
//           3.7088,
//           3.6978,
//           4.1264,
//           4.28903,
//           "4.082",
//           20.4,
//           41.0,
//           5.0,
//           11.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1359: [
//           "1235",
//           0.175,
//           5.654,
//           0.19698,
//           0.21122,
//           0.213,
//           0.220745,
//           "0.211",
//           0.8922,
//           41.0,
//           -24.0,
//           -14.0,
//           18.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1360: [
//           "0825",
//           1.04,
//           31.008,
//           1.2238,
//           1.2639,
//           1.28847,
//           1.3384,
//           "1.28",
//           17.5,
//           41.0,
//           -1.0,
//           -12.0,
//           -7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1361: [
//           "0984",
//           1.23,
//           1.821,
//           1.3106,
//           1.3533,
//           1.41027,
//           1.4925,
//           "1.395",
//           3.198,
//           41.0,
//           5.0,
//           10.0,
//           13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1362: [
//           "0817",
//           2.0,
//           10081.0,
//           2.7178,
//           2.5675,
//           2.54407,
//           2.56945,
//           "2.563",
//           253.7,
//           40.0,
//           -5.0,
//           -17.0,
//           -24.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1363: [
//           "9633",
//           33.85,
//           70956.0,
//           45.679,
//           45.2015,
//           43.25,
//           42.963,
//           "43.48",
//           1704.2,
//           40.0,
//           -9.0,
//           -17.0,
//           -42.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1364: [
//           "0868",
//           18.22,
//           31525.0,
//           20.3356,
//           20.3568,
//           22.6395,
//           24.6801,
//           "21.891",
//           732.2,
//           40.0,
//           0.0,
//           8.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1365: [
//           "2327",
//           0.36,
//           81.705,
//           0.4449,
//           0.44285,
//           0.430233,
//           0.4285,
//           "0.434",
//           15.0,
//           40.0,
//           -4.0,
//           -28.0,
//           -33.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1366: [
//           "0347",
//           3.36,
//           4896.0,
//           3.7802,
//           3.7868,
//           4.298,
//           4.4494,
//           "4.173",
//           47.4,
//           40.0,
//           -2.0,
//           -13.0,
//           -15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1367: [
//           "0747",
//           0.107,
//           0.0,
//           0.11882,
//           0.12416,
//           0.129953,
//           0.134635,
//           "0.129",
//           0.6476999999999999,
//           40.0,
//           7.0,
//           -6.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1368: [
//           "0822",
//           0.55,
//           93.598,
//           0.698,
//           0.8029,
//           0.733867,
//           0.7093,
//           "0.73",
//           4.9156,
//           40.0,
//           -2.0,
//           -5.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1369: [
//           "9938",
//           0.105,
//           0.458,
//           0.11816,
//           0.1263,
//           0.128627,
//           0.13138,
//           "0.127",
//           1.05,
//           40.0,
//           6.0,
//           1.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1370: [
//           "0979",
//           0.21,
//           2.226,
//           0.247,
//           0.24619,
//           0.25068,
//           0.25636,
//           "0.248",
//           2.3862,
//           40.0,
//           0.0,
//           -16.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1371: [
//           "8621",
//           0.064,
//           0.153,
//           0.06608,
//           0.07361,
//           0.0755467,
//           0.077395,
//           "0.075",
//           0.6144,
//           40.0,
//           11.0,
//           25.0,
//           11.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1372: [
//           "8631",
//           0.077,
//           1.155,
//           0.08922,
//           0.10108,
//           0.107287,
//           0.10284,
//           "0.103",
//           0.308,
//           40.0,
//           9.0,
//           15.0,
//           16.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1373: [
//           "1930",
//           0.27,
//           4.88,
//           0.2954,
//           0.3052,
//           0.318133,
//           0.326025,
//           "0.307",
//           1.782,
//           40.0,
//           0.0,
//           11.0,
//           13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1374: [
//           "3908",
//           16.0,
//           24962.0,
//           20.3838,
//           20.1938,
//           20.1154,
//           20.2519,
//           "20.104",
//           304.5,
//           40.0,
//           -1.0,
//           -5.0,
//           -32.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1375: [
//           "3836",
//           3.23,
//           305.0,
//           4.201,
//           4.198,
//           4.21787,
//           4.05735,
//           "4.202",
//           50.4,
//           40.0,
//           -2.0,
//           -9.0,
//           -19.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1376: [
//           "1140",
//           0.6,
//           1.432,
//           0.708,
//           0.7121,
//           0.734467,
//           0.74125,
//           "0.73",
//           24.3,
//           40.0,
//           6.0,
//           0.0,
//           -5.0,
//           0.0,
//           "-",
//         ],
//       },
//       {
//         1377: [
//           "1371",
//           0.08,
//           8.026,
//           0.08806,
//           0.09481,
//           0.0992,
//           0.10397,
//           "0.097",
//           2.4707,
//           40.0,
//           8.0,
//           8.0,
//           8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1378: [
//           "0326",
//           0.79,
//           40.66,
//           0.8716,
//           0.9358,
//           0.972467,
//           1.0343,
//           "0.951",
//           19.6,
//           40.0,
//           -10.0,
//           2.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1379: [
//           "2728",
//           0.116,
//           0.0,
//           0.12674,
//           0.14631,
//           0.163667,
//           0.165695,
//           "0.158",
//           5.1678,
//           40.0,
//           15.0,
//           6.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1380: [
//           "1209",
//           30.7,
//           24771.0,
//           40.033,
//           39.636,
//           40.1557,
//           41.8255,
//           "39.947",
//           700.7,
//           40.0,
//           -10.0,
//           -20.0,
//           -19.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1381: [
//           "0152",
//           7.54,
//           6068.0,
//           8.2444,
//           8.4631,
//           9.09153,
//           9.52495,
//           "8.887",
//           170.9,
//           40.0,
//           7.0,
//           11.0,
//           12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1382: [
//           "1286",
//           1.78,
//           76.858,
//           1.8568,
//           1.9744,
//           2.11933,
//           2.1652,
//           "2.079",
//           33.5,
//           40.0,
//           3.0,
//           7.0,
//           12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1383: [
//           "2182",
//           0.325,
//           0.91,
//           0.4003,
//           0.41045,
//           0.4096,
//           0.413425,
//           "0.407",
//           2.015,
//           40.0,
//           7.0,
//           -5.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1384: [
//           "1314",
//           0.215,
//           1.639,
//           0.23196,
//           0.23872,
//           0.25478,
//           0.268135,
//           "0.251",
//           3.0341,
//           40.0,
//           6.0,
//           4.0,
//           13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1385: [
//           "0489",
//           5.6,
//           7157.0,
//           6.834,
//           6.9967,
//           7.15467,
//           7.1381,
//           "7.116",
//           159.9,
//           39.0,
//           4.0,
//           1.0,
//           -8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1386: [
//           "1938",
//           0.25,
//           22.932,
//           0.2693,
//           0.2867,
//           0.315967,
//           0.329825,
//           "0.309",
//           2.5278,
//           39.0,
//           2.0,
//           6.0,
//           8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1387: [
//           "3898",
//           30.0,
//           32009.0,
//           39.778,
//           42.415,
//           42.4567,
//           43.5008,
//           "41.185",
//           164.1,
//           39.0,
//           -5.0,
//           -3.0,
//           -18.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1388: [
//           "0434",
//           0.425,
//           5.307,
//           0.4871,
//           0.50065,
//           0.4946,
//           0.506575,
//           "0.494",
//           3.0169,
//           39.0,
//           3.0,
//           8.0,
//           -8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1389: [
//           "0403",
//           0.29,
//           21.606,
//           0.3184,
//           0.3517,
//           0.355233,
//           0.34765,
//           "0.355",
//           1.5185,
//           39.0,
//           2.0,
//           7.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1390: [
//           "2232",
//           2.29,
//           143.0,
//           2.5244,
//           2.5967,
//           2.71647,
//           2.9699,
//           "2.65",
//           65.3,
//           39.0,
//           3.0,
//           0.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1391: [
//           "6166",
//           1.64,
//           69.551,
//           1.5642,
//           1.725,
//           1.98507,
//           2.21885,
//           "1.899",
//           27.0,
//           39.0,
//           4.0,
//           13.0,
//           15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1392: [
//           "3050",
//           49.7,
//           79.519,
//           59.1264,
//           62.3892,
//           62.7277,
//           63.6805,
//           "61.982",
//           0.0,
//           39.0,
//           0.0,
//           1.0,
//           -7.0,
//           2.0,
//           "E",
//         ],
//       },
//       {
//         1393: [
//           "0848",
//           0.226,
//           26.829,
//           0.24356,
//           0.25288,
//           0.263587,
//           0.279915,
//           "0.258",
//           11.6,
//           39.0,
//           -7.0,
//           4.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1394: [
//           "2222",
//           0.134,
//           7.341,
//           0.1612,
//           0.1636,
//           0.173073,
//           0.180145,
//           "0.17",
//           5.6645,
//           39.0,
//           -6.0,
//           9.0,
//           -11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1395: [
//           "3886",
//           0.32,
//           219.0,
//           0.3921,
//           0.4317,
//           0.487067,
//           0.482625,
//           "0.47",
//           24.0,
//           39.0,
//           -5.0,
//           5.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1396: [
//           "0981",
//           17.06,
//           75639.0,
//           18.9928,
//           20.1852,
//           21.2011,
//           21.8481,
//           "20.727",
//           1348.4,
//           39.0,
//           3.0,
//           11.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1397: [
//           "8071",
//           0.03,
//           2.579,
//           0.03262,
//           0.03419,
//           0.0385467,
//           0.04236,
//           "0.037",
//           1.4058,
//           39.0,
//           -8.0,
//           -9.0,
//           11.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1398: [
//           "2326",
//           0.012,
//           5.611,
//           0.01322,
//           0.01495,
//           0.0160733,
//           0.0173,
//           "0.016",
//           2.53,
//           39.0,
//           19.0,
//           10.0,
//           24.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1399: [
//           "0511",
//           4.58,
//           332.0,
//           4.9974,
//           5.1016,
//           5.5842,
//           6.01655,
//           "5.436",
//           20.0,
//           39.0,
//           1.0,
//           3.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1400: [
//           "3919",
//           0.197,
//           15.978,
//           0.20384,
//           0.21858,
//           0.228567,
//           0.247425,
//           "0.224",
//           0.7092,
//           39.0,
//           3.0,
//           14.0,
//           18.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1401: [
//           "0604",
//           1.61,
//           1728.0,
//           1.7858,
//           1.8024,
//           1.91793,
//           2.0452,
//           "1.877",
//           143.2,
//           39.0,
//           5.0,
//           9.0,
//           12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1402: [
//           "1037",
//           0.335,
//           5.614,
//           0.3671,
//           0.37445,
//           0.390033,
//           0.40465,
//           "0.39",
//           2.3501,
//           39.0,
//           2.0,
//           1.0,
//           8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1403: [
//           "1051",
//           2.21,
//           53.197,
//           2.6868,
//           2.783,
//           2.87833,
//           2.87334,
//           "2.838",
//           9.9629,
//           39.0,
//           -3.0,
//           -13.0,
//           -15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1404: [
//           "0680",
//           0.034,
//           24.89,
//           0.03686,
//           0.0379,
//           0.0408133,
//           0.044595,
//           "0.04",
//           23.3,
//           39.0,
//           -1.0,
//           8.0,
//           13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1405: [
//           "6138",
//           0.69,
//           14.358,
//           0.7834,
//           0.7938,
//           0.819067,
//           0.8572,
//           "0.803",
//           20.8,
//           39.0,
//           4.0,
//           -3.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1406: [
//           "8232",
//           0.052,
//           3.212,
//           0.05264,
//           0.05772,
//           0.06028,
//           0.06165,
//           "0.059",
//           0.2319,
//           39.0,
//           10.0,
//           13.0,
//           20.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1407: [
//           "0636",
//           16.06,
//           2109.0,
//           19.2674,
//           19.8562,
//           20.4236,
//           21.1695,
//           "20.127",
//           290.2,
//           39.0,
//           10.0,
//           7.0,
//           6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1408: [
//           "0743",
//           4.71,
//           1681.0,
//           5.1976,
//           5.3347,
//           5.58213,
//           5.74205,
//           "5.554",
//           73.7,
//           39.0,
//           2.0,
//           6.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1409: [
//           "0696",
//           11.46,
//           8606.0,
//           14.2976,
//           13.9982,
//           14.1291,
//           14.4388,
//           "14.126",
//           106.8,
//           38.0,
//           -11.0,
//           -12.0,
//           -29.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1410: [
//           "0771",
//           1.04,
//           48.172,
//           1.3522,
//           1.3385,
//           1.36787,
//           1.35705,
//           "1.352",
//           8.6696,
//           38.0,
//           1.0,
//           2.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1411: [
//           "0777",
//           14.9,
//           2042.0,
//           18.5298,
//           18.2171,
//           17.9333,
//           18.4769,
//           "18.128",
//           80.50000000000001,
//           38.0,
//           -3.0,
//           -13.0,
//           -20.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1412: [
//           "1413",
//           0.265,
//           32.015,
//           0.3297,
//           0.31269,
//           0.29968,
//           0.29826,
//           "0.304",
//           2.65,
//           38.0,
//           4.0,
//           -25.0,
//           -43.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         1413: [
//           "1396",
//           0.37,
//           0.441,
//           0.396,
//           0.42885,
//           0.443967,
//           0.457,
//           "0.44",
//           16.7,
//           38.0,
//           6.0,
//           5.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1414: [
//           "0245",
//           0.06,
//           2.874,
//           0.06576,
//           0.06979,
//           0.07452,
//           0.07968,
//           "0.073",
//           20.8,
//           38.0,
//           14.0,
//           18.0,
//           19.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1415: [
//           "0768",
//           0.04,
//           1.677,
//           0.04522,
//           0.04631,
//           0.04934,
//           0.05319,
//           "0.048",
//           0.5086,
//           38.0,
//           -27.0,
//           -16.0,
//           -45.0,
//           0.0,
//           "-",
//         ],
//       },
//       {
//         1416: [
//           "2833",
//           20.1,
//           4529.0,
//           23.9108,
//           24.4216,
//           24.9051,
//           25.8201,
//           "24.602",
//           0.0,
//           38.0,
//           3.0,
//           3.0,
//           -3.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1417: [
//           "0239",
//           1.34,
//           2.68,
//           1.4562,
//           1.5455,
//           1.61488,
//           1.67077,
//           "1.588",
//           4.1759,
//           38.0,
//           6.0,
//           8.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1418: [
//           "1767",
//           0.249,
//           0.5,
//           0.26144,
//           0.27697,
//           0.291013,
//           0.310035,
//           "0.284",
//           2.49,
//           38.0,
//           11.0,
//           14.0,
//           21.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1419: [
//           "1862",
//           1.59,
//           37.416,
//           1.9298,
//           1.9649,
//           2.09273,
//           2.1329,
//           "2.052",
//           24.4,
//           38.0,
//           4.0,
//           5.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1420: [
//           "1812",
//           3.51,
//           2918.0,
//           3.7874,
//           3.7568,
//           4.01107,
//           4.3054,
//           "3.957",
//           18.5,
//           38.0,
//           1.0,
//           0.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1421: [
//           "0628",
//           0.305,
//           28.469,
//           0.28,
//           0.28795,
//           0.319867,
//           0.3747,
//           "0.314",
//           8.2384,
//           38.0,
//           -12.0,
//           5.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1422: [
//           "0341",
//           11.42,
//           915.0,
//           13.112,
//           13.5312,
//           13.7847,
//           14.3751,
//           "13.621",
//           66.8,
//           38.0,
//           2.0,
//           4.0,
//           10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1423: [
//           "0881",
//           46.3,
//           24037.0,
//           57.744,
//           61.941,
//           62.9713,
//           64.325,
//           "62.088",
//           1118.1,
//           38.0,
//           -2.0,
//           -6.0,
//           -8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1424: [
//           "8062",
//           0.26,
//           0.13,
//           0.2858,
//           0.30224,
//           0.335927,
//           0.35997,
//           "0.329",
//           1.248,
//           38.0,
//           1.0,
//           1.0,
//           -9.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1425: [
//           "0400",
//           1.97,
//           336.0,
//           2.3982,
//           2.6882,
//           2.69913,
//           2.6254,
//           "2.672",
//           27.8,
//           38.0,
//           -2.0,
//           4.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1426: [
//           "1053",
//           1.16,
//           277.0,
//           1.3334,
//           1.353,
//           1.53667,
//           1.6595,
//           "1.494",
//           6.2422,
//           38.0,
//           6.0,
//           -2.0,
//           -5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1427: [
//           "2136",
//           0.91,
//           179.0,
//           1.1288,
//           1.1224,
//           1.10813,
//           1.1333,
//           "1.097",
//           13.3,
//           38.0,
//           4.0,
//           -8.0,
//           -20.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1428: [
//           "1003",
//           1.23,
//           139.0,
//           1.4072,
//           1.4606,
//           1.49833,
//           1.5766,
//           "1.467",
//           44.9,
//           38.0,
//           1.0,
//           3.0,
//           8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1429: [
//           "0218",
//           0.78,
//           13.545,
//           0.896,
//           0.9351,
//           0.981267,
//           0.98595,
//           "0.971",
//           12.1,
//           38.0,
//           6.0,
//           -5.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1430: [
//           "1722",
//           0.082,
//           7.3,
//           0.09246,
//           0.09705,
//           0.100347,
//           0.10317,
//           "0.099",
//           0.82,
//           38.0,
//           6.0,
//           -13.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1431: [
//           "3037",
//           19.97,
//           426.0,
//           23.9166,
//           24.4337,
//           24.8717,
//           25.7071,
//           "24.594",
//           0.0,
//           38.0,
//           1.0,
//           1.0,
//           -4.0,
//           2.0,
//           "E",
//         ],
//       },
//       {
//         1432: [
//           "8293",
//           0.042,
//           40.882,
//           0.05078,
//           0.05276,
//           0.05504,
//           0.05451,
//           "0.055",
//           0.63,
//           37.0,
//           10.0,
//           11.0,
//           -18.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1433: [
//           "9958",
//           1.11,
//           16.861,
//           1.1792,
//           1.2678,
//           1.3356,
//           1.3749,
//           "1.316",
//           3.33,
//           37.0,
//           2.0,
//           1.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1434: [
//           "2718",
//           0.67,
//           15.138,
//           0.7018,
//           0.8161,
//           0.854,
//           0.86215,
//           "0.835",
//           3.6156,
//           37.0,
//           5.0,
//           -3.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1435: [
//           "3115",
//           70.32,
//           2005.0,
//           84.2528,
//           86.1656,
//           88.0667,
//           91.3422,
//           "87.011",
//           0.0,
//           37.0,
//           1.0,
//           2.0,
//           -4.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1436: [
//           "3163",
//           30.7,
//           0.0,
//           35.9804,
//           37.1276,
//           38.1561,
//           39.3645,
//           "37.691",
//           0.0,
//           37.0,
//           1.0,
//           -2.0,
//           -5.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1437: [
//           "2800",
//           19.8,
//           748000.0,
//           23.6088,
//           24.168,
//           24.7688,
//           25.6838,
//           "24.456",
//           0.0,
//           37.0,
//           2.0,
//           2.0,
//           -4.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1438: [
//           "0242",
//           1.71,
//           763.0,
//           2.0464,
//           2.0859,
//           2.1238,
//           2.19515,
//           "2.102",
//           51.6,
//           37.0,
//           0.0,
//           -5.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1439: [
//           "9115",
//           9.0,
//           814.0000000000001,
//           10.8015,
//           11.0559,
//           11.3054,
//           11.736,
//           "11.171",
//           0.0,
//           37.0,
//           2.0,
//           3.0,
//           -3.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1440: [
//           "2378",
//           110.5,
//           2490.0,
//           127.582,
//           135.997,
//           142.764,
//           145.614,
//           "140.78",
//           3034.7,
//           37.0,
//           6.0,
//           13.0,
//           12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1441: [
//           "6193",
//           0.36,
//           7.178,
//           0.3772,
//           0.38285,
//           0.4167,
//           0.487425,
//           "0.4",
//           1.44,
//           37.0,
//           3.0,
//           2.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1442: [
//           "9999",
//           118.5,
//           143000.0,
//           151.828,
//           157.952,
//           151.123,
//           155.863,
//           "151.187",
//           4094.3,
//           37.0,
//           -1.0,
//           3.0,
//           -12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1443: [
//           "1124",
//           0.042,
//           4.662,
//           0.04802,
//           0.049,
//           0.0510133,
//           0.05426,
//           "0.051",
//           1.7413,
//           37.0,
//           9.0,
//           4.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1444: [
//           "1549",
//           0.129,
//           16.924,
//           0.16522,
//           0.16129,
//           0.195793,
//           0.21733,
//           "0.185",
//           1.9995,
//           37.0,
//           0.0,
//           -2.0,
//           -25.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1445: [
//           "0931",
//           0.31,
//           16.555,
//           0.3588,
//           0.37405,
//           0.381467,
//           0.389175,
//           "0.378",
//           17.4,
//           37.0,
//           1.0,
//           -5.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1446: [
//           "1957",
//           0.355,
//           3.793,
//           0.3908,
//           0.4643,
//           0.493367,
//           0.464875,
//           "0.483",
//           2.2294,
//           37.0,
//           8.0,
//           16.0,
//           13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1447: [
//           "0630",
//           0.078,
//           0.435,
//           0.08176,
//           0.08038,
//           0.10866,
//           0.11073,
//           "0.101",
//           1.8867,
//           37.0,
//           1.0,
//           7.0,
//           10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1448: [
//           "0547",
//           0.53,
//           125.0,
//           0.7132,
//           0.7026,
//           0.676,
//           0.66525,
//           "0.677",
//           22.9,
//           37.0,
//           -1.0,
//           -14.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1449: [
//           "1609",
//           0.68,
//           0.148,
//           0.7804,
//           0.7111,
//           0.722067,
//           1.0426,
//           "0.707",
//           7.4486,
//           37.0,
//           11.0,
//           18.0,
//           27.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1450: [
//           "2038",
//           0.92,
//           1123.0,
//           1.2134,
//           1.2166,
//           1.19287,
//           1.1894,
//           "1.196",
//           73.5,
//           37.0,
//           -3.0,
//           -8.0,
//           -30.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1451: [
//           "1104",
//           1.0,
//           41.6,
//           1.056,
//           1.1529,
//           1.2168,
//           1.24765,
//           "1.196",
//           13.0,
//           37.0,
//           7.0,
//           6.0,
//           7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1452: [
//           "1747",
//           0.6,
//           1.162,
//           0.722,
//           0.7318,
//           0.762533,
//           0.77175,
//           "0.755",
//           3.0098,
//           37.0,
//           0.0,
//           -4.0,
//           -8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1453: [
//           "1538",
//           0.53,
//           11.382,
//           0.6064,
//           0.6253,
//           0.651533,
//           0.6787,
//           "0.645",
//           4.5291,
//           37.0,
//           -6.0,
//           -14.0,
//           -14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1454: [
//           "0821",
//           0.255,
//           58.016,
//           0.3051,
//           0.3454,
//           0.361067,
//           0.354215,
//           "0.35",
//           5.3004,
//           37.0,
//           6.0,
//           9.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1455: [
//           "6858",
//           2.95,
//           16.223,
//           3.3064,
//           3.3451,
//           3.37947,
//           3.61825,
//           "3.346",
//           17.8,
//           36.0,
//           -2.0,
//           0.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1456: [
//           "1878",
//           1.1,
//           21.12,
//           1.338,
//           1.3182,
//           1.42027,
//           1.43125,
//           "1.436",
//           3.0159,
//           36.0,
//           -16.0,
//           -41.0,
//           -39.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1457: [
//           "2227",
//           0.139,
//           2.557,
//           0.14898,
//           0.16422,
//           0.18118,
//           0.188375,
//           "0.175",
//           1.2726,
//           36.0,
//           13.0,
//           13.0,
//           21.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1458: [
//           "0346",
//           0.055,
//           154.0,
//           0.04814,
//           0.05747,
//           0.0636867,
//           0.0651,
//           "0.063",
//           10.0,
//           36.0,
//           -4.0,
//           8.0,
//           28.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1459: [
//           "8482",
//           0.24,
//           1.49,
//           0.32908,
//           0.35339,
//           0.338147,
//           0.332585,
//           "0.339",
//           2.016,
//           36.0,
//           -5.0,
//           -30.0,
//           -18.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1460: [
//           "6909",
//           5.0,
//           27.756,
//           5.6304,
//           5.84,
//           6.2572,
//           "-",
//           "6.148",
//           31.1,
//           36.0,
//           3.0,
//           1.0,
//           7.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         1461: [
//           "3998",
//           3.44,
//           15495.0,
//           4.1852,
//           4.8525,
//           5.2276,
//           5.1231,
//           "5.11",
//           374.4,
//           36.0,
//           -2.0,
//           -18.0,
//           -22.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1462: [
//           "8341",
//           0.04,
//           1.12,
//           0.0412,
//           0.04312,
//           0.0449533,
//           0.04796,
//           "0.045",
//           0.32,
//           36.0,
//           -14.0,
//           -8.0,
//           4.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1463: [
//           "1849",
//           0.44,
//           5.48,
//           0.4893,
//           0.54275,
//           0.556567,
//           0.564525,
//           "0.554",
//           3.52,
//           36.0,
//           5.0,
//           -3.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1464: [
//           "0496",
//           0.61,
//           1.357,
//           0.7076,
//           0.6789,
//           0.6992,
//           0.75445,
//           "0.69",
//           9.1111,
//           36.0,
//           1.0,
//           -2.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1465: [
//           "1913",
//           37.9,
//           9228.0,
//           46.655,
//           48.679,
//           48.3057,
//           49.945,
//           "47.562",
//           969.7,
//           36.0,
//           4.0,
//           5.0,
//           -35.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1466: [
//           "8519",
//           0.083,
//           27.209,
//           0.09462,
//           0.10127,
//           0.103573,
//           0.108595,
//           "0.104",
//           0.9627,
//           36.0,
//           2.0,
//           10.0,
//           6.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1467: [
//           "0021",
//           0.13,
//           2.572,
//           0.133,
//           0.14421,
//           0.154173,
//           0.166085,
//           "0.151",
//           5.1678,
//           36.0,
//           9.0,
//           6.0,
//           13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1468: [
//           "3700",
//           1.32,
//           567.0,
//           1.8266,
//           1.6633,
//           1.64607,
//           1.74885,
//           "1.637",
//           26.4,
//           36.0,
//           -12.0,
//           -48.0,
//           -50.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1469: [
//           "9929",
//           0.055,
//           5.647,
//           0.06576,
//           0.07239,
//           0.0718333,
//           0.07007,
//           "0.071",
//           1.1,
//           36.0,
//           5.0,
//           -2.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1470: [
//           "1839",
//           5.0,
//           34.595,
//           5.8884,
//           5.8093,
//           6.01747,
//           6.2685,
//           "5.938",
//           28.1,
//           36.0,
//           5.0,
//           1.0,
//           -24.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1471: [
//           "0658",
//           4.55,
//           1427.0,
//           5.5572,
//           5.8689,
//           5.91813,
//           5.69895,
//           "5.953",
//           74.4,
//           36.0,
//           -8.0,
//           -8.0,
//           -31.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1472: [
//           "8319",
//           0.109,
//           10.791,
//           0.11414,
//           0.12549,
//           0.13554,
//           0.1418,
//           "0.132",
//           0.8755,
//           36.0,
//           -2.0,
//           5.0,
//           14.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1473: [
//           "0193",
//           0.27,
//           0.443,
//           0.2952,
//           0.31965,
//           0.334367,
//           0.3491,
//           "0.328",
//           0.5247,
//           36.0,
//           2.0,
//           -8.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1474: [
//           "1211",
//           179.0,
//           247000.0,
//           239.684,
//           266.082,
//           263.331,
//           252.158,
//           "258.617",
//           1965.4,
//           36.0,
//           -2.0,
//           -9.0,
//           -36.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1475: [
//           "0518",
//           0.28,
//           1.68,
//           0.3113,
//           0.33385,
//           0.3477,
//           0.356675,
//           "0.341",
//           1.2629,
//           36.0,
//           5.0,
//           -2.0,
//           7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1476: [
//           "1208",
//           2.44,
//           7173.0,
//           2.7692,
//           2.8862,
//           3.11027,
//           3.2693,
//           "3.06",
//           210.7,
//           36.0,
//           -1.0,
//           -31.0,
//           10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1477: [
//           "8107",
//           0.21,
//           0.422,
//           0.22388,
//           0.23915,
//           0.257767,
//           0.27365,
//           "0.25",
//           0.21,
//           36.0,
//           -9.0,
//           11.0,
//           16.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1478: [
//           "0828",
//           0.305,
//           8.228,
//           0.3624,
//           0.3636,
//           0.387267,
//           0.4182,
//           "0.377",
//           3.807,
//           35.0,
//           8.0,
//           3.0,
//           -8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1479: [
//           "2156",
//           3.03,
//           473.0,
//           4.0828,
//           4.1012,
//           4.15527,
//           4.31415,
//           "4.12",
//           40.4,
//           35.0,
//           -11.0,
//           -23.0,
//           -46.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1480: [
//           "2825",
//           20.54,
//           0.0,
//           24.9412,
//           25.63,
//           26.0849,
//           27.0466,
//           "25.808",
//           0.0,
//           35.0,
//           1.0,
//           2.0,
//           -5.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1481: [
//           "1480",
//           1.15,
//           0.46,
//           1.3088,
//           1.3683,
//           1.39253,
//           1.4736,
//           "1.382",
//           2.76,
//           35.0,
//           2.0,
//           0.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1482: [
//           "9616",
//           3.97,
//           15.656,
//           4.8166,
//           5.0967,
//           5.17793,
//           5.39715,
//           "5.129",
//           26.5,
//           35.0,
//           -1.0,
//           -7.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1483: [
//           "0598",
//           2.19,
//           1291.0,
//           2.5516,
//           2.5253,
//           2.72653,
//           2.8712,
//           "2.7",
//           46.9,
//           35.0,
//           3.0,
//           -5.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1484: [
//           "2078",
//           0.209,
//           6.252,
//           0.24396,
//           0.24841,
//           0.254293,
//           0.260245,
//           "0.251",
//           2.508,
//           35.0,
//           7.0,
//           1.0,
//           -8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1485: [
//           "0500",
//           0.5,
//           485.0,
//           0.6383,
//           0.64565,
//           0.690033,
//           0.730975,
//           "0.678",
//           11.7,
//           35.0,
//           -17.0,
//           3.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1486: [
//           "1338",
//           0.073,
//           34.809,
//           0.0837,
//           0.08956,
//           0.0867733,
//           0.091695,
//           "0.086",
//           2.3085,
//           35.0,
//           7.0,
//           7.0,
//           14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1487: [
//           "1428",
//           1.26,
//           274.0,
//           1.443,
//           1.4886,
//           1.52333,
//           1.64195,
//           "1.492",
//           21.3,
//           35.0,
//           2.0,
//           3.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1488: [
//           "1958",
//           2.28,
//           5113.0,
//           2.9494,
//           3.0599,
//           2.9798,
//           2.96935,
//           "2.976",
//           57.4,
//           35.0,
//           -3.0,
//           -7.0,
//           -13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1489: [
//           "8328",
//           3.86,
//           43.412,
//           4.0598,
//           4.12742,
//           4.77948,
//           4.85881,
//           "4.547",
//           27.5,
//           35.0,
//           -6.0,
//           -10.0,
//           3.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1490: [
//           "2211",
//           0.142,
//           1.191,
//           0.14568,
//           0.15149,
//           0.157147,
//           0.167235,
//           "0.154",
//           0.6581999999999999,
//           35.0,
//           4.0,
//           11.0,
//           18.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1491: [
//           "0769",
//           0.68,
//           937.0,
//           0.8414,
//           0.8349,
//           0.849667,
//           0.8684,
//           "0.838",
//           15.9,
//           35.0,
//           -2.0,
//           0.0,
//           -30.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1492: [
//           "0368",
//           0.43,
//           1.72,
//           0.4517,
//           0.4763,
//           0.493433,
//           0.520275,
//           "0.487",
//           3.44,
//           35.0,
//           5.0,
//           5.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1493: [
//           "1843",
//           0.22,
//           11.978,
//           0.25702,
//           0.27056,
//           0.29254,
//           0.32113,
//           "0.284",
//           1.76,
//           35.0,
//           -1.0,
//           8.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1494: [
//           "1522",
//           0.375,
//           35.632,
//           0.4177,
//           0.468,
//           0.488967,
//           0.471975,
//           "0.489",
//           7.8643,
//           35.0,
//           5.0,
//           0.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1495: [
//           "0925",
//           0.077,
//           9.969,
//           0.09474,
//           0.09637,
//           0.0997333,
//           0.102905,
//           "0.099",
//           5.3663,
//           35.0,
//           9.0,
//           -21.0,
//           -22.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1496: [
//           "1558",
//           4.54,
//           1062.0,
//           6.5332,
//           5.8098,
//           5.52673,
//           5.97225,
//           "5.515",
//           29.6,
//           35.0,
//           -11.0,
//           -12.0,
//           -33.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1497: [
//           "1740",
//           0.139,
//           1.517,
//           0.20672,
//           0.21801,
//           0.1932,
//           0.181865,
//           "0.197",
//           1.4421,
//           35.0,
//           3.0,
//           -26.0,
//           -54.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1498: [
//           "3329",
//           1.24,
//           88.59,
//           1.439,
//           1.5586,
//           1.6286,
//           1.6732,
//           "1.603",
//           33.9,
//           35.0,
//           9.0,
//           8.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1499: [
//           "6055",
//           12.4,
//           3498.0,
//           14.9908,
//           16.2378,
//           16.0593,
//           16.2723,
//           "16.035",
//           85.7,
//           35.0,
//           -6.0,
//           1.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1500: [
//           "0842",
//           0.67,
//           7.181,
//           0.796,
//           0.8433,
//           0.8602,
//           0.8532,
//           "0.832",
//           9.0976,
//           35.0,
//           1.0,
//           3.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1501: [
//           "8657",
//           0.77,
//           9.192,
//           0.8952,
//           0.9161,
//           0.938667,
//           0.9636,
//           "0.924",
//           3.08,
//           35.0,
//           -4.0,
//           -2.0,
//           0.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1502: [
//           "3718",
//           0.54,
//           302.0,
//           0.6084,
//           0.61455,
//           0.684033,
//           0.764775,
//           "0.671",
//           19.4,
//           34.0,
//           0.0,
//           3.0,
//           -11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1503: [
//           "0430",
//           0.37,
//           1.948,
//           0.4183,
//           0.41545,
//           0.426933,
//           0.455225,
//           "-",
//           1.4362,
//           34.0,
//           -1.0,
//           -14.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1504: [
//           "0738",
//           0.58,
//           4.992,
//           0.602,
//           0.6289,
//           0.665467,
//           0.8138,
//           "0.65",
//           4.0941,
//           34.0,
//           3.0,
//           11.0,
//           14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1505: [
//           "1200",
//           0.87,
//           33.524,
//           0.9532,
//           1.0348,
//           1.13367,
//           1.2176,
//           "1.097",
//           6.2386,
//           34.0,
//           5.0,
//           8.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1506: [
//           "0110",
//           0.345,
//           1.056,
//           0.3916,
//           0.41025,
//           0.419167,
//           0.425225,
//           "-",
//           0.6332,
//           34.0,
//           -15.0,
//           5.0,
//           -5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1507: [
//           "8211",
//           0.055,
//           0.22,
//           0.06046,
//           0.06207,
//           0.06424,
//           0.07139,
//           "0.063",
//           0.2615,
//           34.0,
//           10.0,
//           5.0,
//           -7.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1508: [
//           "9898",
//           162.0,
//           1242.0,
//           238.77,
//           "-",
//           "-",
//           "-",
//           "-",
//           394.1,
//           34.0,
//           -4.0,
//           4.0,
//           -15.0,
//           1.0,
//           "M",
//         ],
//       },
//       {
//         1509: [
//           "2798",
//           1.68,
//           259.0,
//           1.6944,
//           1.6864,
//           1.70193,
//           1.9877,
//           "1.691",
//           26.8,
//           34.0,
//           4.0,
//           5.0,
//           18.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1510: [
//           "8147",
//           0.032,
//           53.821,
//           0.03464,
//           0.03318,
//           0.03374,
//           0.0433,
//           "0.034",
//           0.3298,
//           34.0,
//           5.0,
//           1.0,
//           -4.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1511: [
//           "0029",
//           8.82,
//           51.998,
//           9.8714,
//           10.538,
//           11.1288,
//           11.7387,
//           "10.979",
//           20.9,
//           34.0,
//           3.0,
//           1.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1512: [
//           "3366",
//           1.07,
//           1.076,
//           1.2634,
//           1.2956,
//           1.34827,
//           1.4029,
//           "1.327",
//           8.0075,
//           34.0,
//           -5.0,
//           -1.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1513: [
//           "8152",
//           0.063,
//           0.996,
//           0.0791,
//           0.08353,
//           0.0951267,
//           0.092785,
//           "0.092",
//           0.378,
//           34.0,
//           1.0,
//           -5.0,
//           -18.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1514: [
//           "0750",
//           1.09,
//           788.0,
//           1.4654,
//           1.6906,
//           1.76807,
//           1.6744,
//           "1.764",
//           27.4,
//           34.0,
//           -4.0,
//           -2.0,
//           -31.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1515: [
//           "1689",
//           1.68,
//           95.57,
//           1.9608,
//           2.0069,
//           2.1434,
//           2.2843,
//           "2.109",
//           11.7,
//           34.0,
//           4.0,
//           2.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1516: [
//           "6690",
//           22.85,
//           32480.0,
//           29.613,
//           29.8155,
//           29.3593,
//           29.243,
//           "29.393",
//           655.0,
//           34.0,
//           1.0,
//           -2.0,
//           -15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1517: [
//           "3124",
//           43.26,
//           62.778,
//           51.888,
//           54.0208,
//           55.69,
//           "-",
//           "55.273",
//           0.0,
//           34.0,
//           2.0,
//           -6.0,
//           -10.0,
//           2.0,
//           "E",
//         ],
//       },
//       {
//         1518: [
//           "2181",
//           0.77,
//           1.826,
//           0.9288,
//           0.9841,
//           0.9914,
//           1.04175,
//           "0.971",
//           31.7,
//           34.0,
//           2.0,
//           -2.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1519: [
//           "2357",
//           3.9,
//           7601.0,
//           4.8462,
//           5.0317,
//           5.21847,
//           5.23555,
//           "5.087",
//           242.2,
//           34.0,
//           1.0,
//           -7.0,
//           -12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1520: [
//           "0306",
//           1.61,
//           33.266,
//           1.8978,
//           2.0472,
//           2.06813,
//           2.13855,
//           "2.037",
//           7.6761,
//           34.0,
//           -9.0,
//           6.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1521: [
//           "0841",
//           0.231,
//           3.676,
//           0.2779,
//           0.2879,
//           0.2847,
//           0.295825,
//           "0.284",
//           1.3507,
//           34.0,
//           4.0,
//           -2.0,
//           -25.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1522: [
//           "1882",
//           17.6,
//           2913.0,
//           20.864,
//           21.032,
//           23.2223,
//           24.176,
//           "22.518",
//           280.8,
//           34.0,
//           1.0,
//           4.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1523: [
//           "8370",
//           0.248,
//           58.635,
//           0.29638,
//           0.31694,
//           0.336193,
//           0.347145,
//           "0.328",
//           2.2501,
//           34.0,
//           9.0,
//           9.0,
//           6.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1524: [
//           "6900",
//           1.73,
//           202.0,
//           2.1566,
//           2.2098,
//           2.32293,
//           2.3845,
//           "2.287",
//           35.8,
//           34.0,
//           -3.0,
//           -1.0,
//           7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1525: [
//           "0488",
//           3.55,
//           50.21,
//           4.1508,
//           4.0524,
//           4.39376,
//           4.82252,
//           "4.252",
//           34.3,
//           33.0,
//           6.0,
//           9.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1526: [
//           "8125",
//           0.165,
//           0.513,
//           0.18474,
//           0.18367,
//           0.1898,
//           0.18857,
//           "0.187",
//           0.2586,
//           33.0,
//           6.0,
//           12.0,
//           6.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1527: [
//           "1146",
//           0.127,
//           0.118,
//           0.18284,
//           0.19339,
//           0.188733,
//           0.182945,
//           "0.184",
//           4.3757,
//           33.0,
//           0.0,
//           -1.0,
//           -11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1528: [
//           "0169",
//           0.26,
//           1.56,
//           0.2916,
//           0.3045,
//           0.327667,
//           0.365125,
//           "0.314",
//           12.2,
//           33.0,
//           1.0,
//           11.0,
//           8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1529: [
//           "3162",
//           5.605,
//           23.264,
//           6.7391,
//           7.44995,
//           "-",
//           "-",
//           "7.39",
//           0.0,
//           33.0,
//           2.0,
//           7.0,
//           1.0,
//           2.0,
//           "E",
//         ],
//       },
//       {
//         1530: [
//           "3993",
//           3.55,
//           16946.0,
//           4.2588,
//           4.434,
//           4.81167,
//           4.90265,
//           "4.652",
//           139.6,
//           33.0,
//           0.0,
//           -9.0,
//           -14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1531: [
//           "2122",
//           0.181,
//           28.552,
//           0.23314,
//           0.23541,
//           0.24832,
//           0.246725,
//           "0.241",
//           1.448,
//           33.0,
//           3.0,
//           -23.0,
//           -39.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1532: [
//           "1257",
//           2.14,
//           962.0,
//           2.6704,
//           2.7104,
//           2.77113,
//           2.8008,
//           "2.759",
//           44.2,
//           33.0,
//           1.0,
//           -17.0,
//           -15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1533: [
//           "0162",
//           0.121,
//           4.84,
//           0.15124,
//           0.15366,
//           0.15702,
//           0.162525,
//           "0.157",
//           1.3911,
//           33.0,
//           4.0,
//           1.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1534: [
//           "0460",
//           1.43,
//           14416.0,
//           1.6116,
//           1.5786,
//           1.74427,
//           2.0921,
//           "1.666",
//           133.4,
//           33.0,
//           -4.0,
//           -2.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1535: [
//           "1601",
//           0.65,
//           9.728,
//           0.7662,
//           0.8091,
//           0.8572,
//           0.8844,
//           "0.848",
//           3.2066,
//           33.0,
//           -11.0,
//           -1.0,
//           8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1536: [
//           "1847",
//           1.08,
//           1.535,
//           1.31,
//           1.327,
//           1.3962,
//           1.4624,
//           "1.374",
//           1.4459,
//           33.0,
//           -17.0,
//           -10.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1537: [
//           "1697",
//           0.375,
//           5.268,
//           0.4035,
//           0.4008,
//           0.471133,
//           0.5188,
//           "0.455",
//           4.3677,
//           33.0,
//           3.0,
//           2.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1538: [
//           "3194",
//           12.55,
//           44.568,
//           14.7558,
//           16.4242,
//           16.9105,
//           17.0091,
//           "16.676",
//           0.0,
//           33.0,
//           3.0,
//           3.0,
//           4.0,
//           2.0,
//           "E",
//         ],
//       },
//       {
//         1539: [
//           "1837",
//           0.4,
//           101.0,
//           0.4812,
//           0.52415,
//           0.532433,
//           0.52755,
//           "0.531",
//           8.754,
//           33.0,
//           -1.0,
//           2.0,
//           8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1540: [
//           "1772",
//           90.3,
//           64786.0,
//           120.124,
//           130.441,
//           137.641,
//           135.468,
//           "133.653",
//           260.3,
//           33.0,
//           -7.0,
//           -29.0,
//           -45.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1541: [
//           "2208",
//           11.28,
//           5413.0,
//           13.402,
//           15.204,
//           15.2747,
//           14.6346,
//           "15.272",
//           87.2,
//           33.0,
//           -1.0,
//           0.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1542: [
//           "0656",
//           7.15,
//           3410.0,
//           8.559,
//           8.688,
//           9.0312,
//           9.6179,
//           "8.916",
//           594.2,
//           33.0,
//           3.0,
//           1.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1543: [
//           "0871",
//           0.1,
//           1.195,
//           0.13158,
//           0.13222,
//           0.128767,
//           0.12965,
//           "0.129",
//           1.5038,
//           33.0,
//           -8.0,
//           -17.0,
//           -23.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1544: [
//           "8245",
//           0.054,
//           2.384,
//           0.06018,
//           0.06482,
//           0.0680133,
//           0.07128,
//           "0.066",
//           0.32310000000000005,
//           33.0,
//           8.0,
//           -23.0,
//           -18.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1545: [
//           "2699",
//           0.039,
//           1.461,
//           0.04114,
//           0.04311,
//           0.0451067,
//           0.051315,
//           "0.044",
//           0.7326,
//           33.0,
//           7.0,
//           9.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1546: [
//           "2217",
//           2.27,
//           482.0,
//           2.8684,
//           2.9945,
//           "-",
//           "-",
//           "-",
//           30.4,
//           33.0,
//           2.0,
//           1.0,
//           1.0,
//           1.0,
//           "M",
//         ],
//       },
//       {
//         1547: [
//           "0296",
//           0.71,
//           33.19,
//           0.8076,
//           0.8509,
//           0.876267,
//           0.9323,
//           "0.862",
//           8.5008,
//           33.0,
//           -2.0,
//           1.0,
//           7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1548: [
//           "1971",
//           3.14,
//           15.081,
//           3.7914,
//           4.0543,
//           4.17247,
//           4.19235,
//           "4.094",
//           13.0,
//           32.0,
//           2.0,
//           -5.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1549: [
//           "8059",
//           0.031,
//           15.08,
//           0.03106,
//           0.03358,
//           0.0358667,
//           0.04096,
//           "0.034",
//           0.3132,
//           32.0,
//           21.0,
//           9.0,
//           8.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1550: [
//           "2700",
//           0.29,
//           2.32,
//           0.349,
//           0.3799,
//           0.4039,
//           0.418325,
//           "-",
//           1.9136,
//           32.0,
//           5.0,
//           -17.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1551: [
//           "2107",
//           0.88,
//           49.025,
//           0.9612,
//           1.3481,
//           1.23093,
//           1.1699,
//           "1.228",
//           8.8,
//           32.0,
//           1.0,
//           3.0,
//           -8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1552: [
//           "0129",
//           0.7,
//           28.16,
//           0.8502,
//           0.8713,
//           0.898733,
//           0.98015,
//           "0.882",
//           9.2384,
//           32.0,
//           4.0,
//           -6.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1553: [
//           "1266",
//           0.3,
//           323.0,
//           0.3876,
//           0.4171,
//           0.442467,
//           0.423275,
//           "0.443",
//           7.1073,
//           32.0,
//           -12.0,
//           -32.0,
//           -13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1554: [
//           "2348",
//           1.14,
//           34.52,
//           1.4594,
//           1.5633,
//           1.59133,
//           1.64335,
//           "1.564",
//           17.0,
//           32.0,
//           0.0,
//           -1.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1555: [
//           "0921",
//           7.14,
//           1114.0,
//           9.6616,
//           9.0554,
//           9.05853,
//           9.4236,
//           "8.953",
//           32.8,
//           32.0,
//           2.0,
//           -4.0,
//           -21.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1556: [
//           "8326",
//           0.128,
//           0.475,
//           0.1911,
//           0.19575,
//           0.199853,
//           0.195935,
//           "0.198",
//           1.047,
//           32.0,
//           -2.0,
//           -55.0,
//           -41.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1557: [
//           "3958",
//           4.63,
//           4501.0,
//           6.0928,
//           6.4186,
//           6.8654,
//           6.578,
//           "6.679",
//           47.5,
//           32.0,
//           -2.0,
//           -5.0,
//           -21.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1558: [
//           "0207",
//           0.315,
//           260.0,
//           0.3842,
//           0.39245,
//           0.4032,
//           0.418125,
//           "0.398",
//           44.8,
//           32.0,
//           -4.0,
//           -5.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1559: [
//           "6099",
//           8.36,
//           4403.0,
//           10.8676,
//           11.4548,
//           11.6896,
//           11.5359,
//           "11.627",
//           106.5,
//           32.0,
//           5.0,
//           1.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1560: [
//           "3808",
//           10.74,
//           7594.0,
//           11.9956,
//           11.771,
//           12.5372,
//           13.3174,
//           "12.095",
//           296.5,
//           32.0,
//           -8.0,
//           3.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1561: [
//           "1039",
//           0.218,
//           0.679,
//           0.23974,
//           0.26762,
//           0.28498,
//           0.29926,
//           "0.275",
//           3.9478,
//           32.0,
//           14.0,
//           21.0,
//           22.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1562: [
//           "8132",
//           0.18,
//           1.83,
//           0.2708,
//           0.33055,
//           0.356867,
//           0.343165,
//           "0.344",
//           4.5602,
//           32.0,
//           -1.0,
//           -1.0,
//           -21.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1563: [
//           "1123",
//           0.111,
//           5.678,
//           0.13084,
//           0.13724,
//           0.142833,
//           0.15062,
//           "0.139",
//           1.3157,
//           32.0,
//           -3.0,
//           1.0,
//           -12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1564: [
//           "1460",
//           0.243,
//           18.244,
//           0.29906,
//           0.38053,
//           0.476353,
//           0.44654,
//           "0.454",
//           2.1325,
//           32.0,
//           7.0,
//           0.0,
//           12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1565: [
//           "0993",
//           0.139,
//           0.2,
//           0.16988,
//           0.17754,
//           0.18448,
//           0.19436,
//           "0.178",
//           12.1,
//           32.0,
//           5.0,
//           -1.0,
//           6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1566: [
//           "0164",
//           0.31,
//           31.862,
//           0.3188,
//           0.33335,
//           0.349533,
//           0.4023,
//           "0.354",
//           1.8235,
//           32.0,
//           9.0,
//           3.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1567: [
//           "8329",
//           0.17,
//           2.386,
//           0.18748,
//           0.20302,
//           0.21548,
//           0.224475,
//           "0.212",
//           0.7242,
//           32.0,
//           -2.0,
//           1.0,
//           0.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1568: [
//           "0291",
//           46.1,
//           185000.0,
//           59.39,
//           61.424,
//           61.1117,
//           62.6838,
//           "60.732",
//           1495.5,
//           32.0,
//           -6.0,
//           -28.0,
//           -30.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1569: [
//           "8316",
//           0.57,
//           6.459999999999999,
//           0.9996,
//           1.2512,
//           1.29673,
//           1.23865,
//           "1.225",
//           4.56,
//           32.0,
//           -8.0,
//           -14.0,
//           -35.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1570: [
//           "1536",
//           0.148,
//           0.0,
//           0.1531,
//           0.17846,
//           0.185387,
//           0.194435,
//           "0.181",
//           0.5624,
//           32.0,
//           10.0,
//           19.0,
//           14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1571: [
//           "1941",
//           0.47,
//           8.739,
//           0.6461,
//           0.69625,
//           0.6997,
//           0.695975,
//           "0.691",
//           1.9049,
//           32.0,
//           15.0,
//           -19.0,
//           -11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1572: [
//           "0178",
//           1.27,
//           550.0,
//           1.4868,
//           1.6165,
//           1.65247,
//           1.73855,
//           "1.634",
//           39.4,
//           31.0,
//           2.0,
//           7.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1573: [
//           "0150",
//           0.78,
//           47.897,
//           1.0008,
//           1.0772,
//           1.0568,
//           1.0718,
//           "1.049",
//           16.0,
//           31.0,
//           -1.0,
//           6.0,
//           -12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1574: [
//           "0348",
//           0.21,
//           9.445,
//           0.24608,
//           0.2522,
//           0.258667,
//           0.2764,
//           "0.253",
//           1.618,
//           31.0,
//           -4.0,
//           -2.0,
//           -12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1575: [
//           "1545",
//           0.097,
//           2.049,
//           0.12298,
//           0.12964,
//           0.135227,
//           0.142715,
//           "0.132",
//           1.94,
//           31.0,
//           5.0,
//           0.0,
//           -9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1576: [
//           "8445",
//           0.245,
//           3.185,
//           0.30592,
//           0.31076,
//           0.347407,
//           0.34288,
//           "0.331",
//           0.513,
//           31.0,
//           3.0,
//           -10.0,
//           -6.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1577: [
//           "2196",
//           29.3,
//           43204.0,
//           31.953,
//           34.614,
//           39.3007,
//           44.9567,
//           "37.127",
//           161.70000000000005,
//           31.0,
//           7.0,
//           8.0,
//           12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1578: [
//           "8429",
//           0.145,
//           14.606,
//           0.1587,
//           0.15965,
//           0.187873,
//           0.19487,
//           "0.183",
//           0.696,
//           31.0,
//           4.0,
//           5.0,
//           3.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1579: [
//           "6820",
//           1.16,
//           119.0,
//           1.3364,
//           1.2928,
//           1.3264,
//           1.52605,
//           "1.298",
//           25.2,
//           31.0,
//           4.0,
//           8.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1580: [
//           "1220",
//           0.068,
//           29.436,
//           0.10798,
//           0.09548,
//           0.08952,
//           0.090635,
//           "0.09",
//           1.3464,
//           31.0,
//           6.0,
//           -27.0,
//           -59.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1581: [
//           "0551",
//           11.1,
//           791.0,
//           13.0364,
//           13.93,
//           14.6253,
//           15.5263,
//           "14.441",
//           178.9,
//           31.0,
//           4.0,
//           0.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1582: [
//           "0327",
//           5.06,
//           1969.0,
//           5.6534,
//           5.9483,
//           7.1822,
//           7.6819,
//           "6.971",
//           54.9,
//           31.0,
//           4.0,
//           5.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1583: [
//           "2263",
//           0.131,
//           3.065,
//           0.14278,
//           0.15693,
//           0.169187,
//           0.17705,
//           "0.164",
//           1.31,
//           31.0,
//           14.0,
//           16.0,
//           13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1584: [
//           "1194",
//           0.35,
//           1.17,
//           0.3916,
//           0.3804,
//           0.3904,
//           0.441475,
//           "0.382",
//           1.0438,
//           31.0,
//           7.0,
//           -5.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1585: [
//           "3396",
//           9.0,
//           2994.0,
//           11.1822,
//           11.9613,
//           12.747,
//           12.6365,
//           "12.705",
//           114.4,
//           31.0,
//           2.0,
//           5.0,
//           -21.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1586: [
//           "0702",
//           0.145,
//           86.528,
//           0.16692,
//           0.18086,
//           0.20348,
//           0.22288,
//           "0.195",
//           4.8508,
//           31.0,
//           1.0,
//           0.0,
//           7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1587: [
//           "0818",
//           0.89,
//           194.0,
//           1.0174,
//           1.0456,
//           1.14087,
//           1.1928,
//           "1.114",
//           24.7,
//           31.0,
//           5.0,
//           6.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1588: [
//           "0388",
//           333.4,
//           407000.0000000001,
//           424.876,
//           443.13,
//           457.977,
//           465.043,
//           "450.227",
//           4226.9,
//           31.0,
//           3.0,
//           8.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1589: [
//           "2015",
//           84.1,
//           110000.0,
//           111.288,
//           117.41,
//           "-",
//           "-",
//           "114.718",
//           1737.2,
//           31.0,
//           -21.0,
//           -15.0,
//           -21.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         1590: [
//           "0030",
//           0.24,
//           120.0,
//           0.2813,
//           0.27155,
//           0.283367,
//           0.3099,
//           "0.281",
//           15.4,
//           31.0,
//           -1.0,
//           -15.0,
//           -7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1591: [
//           "3318",
//           3.23,
//           557.0,
//           4.1408,
//           3.9481,
//           3.79467,
//           3.9135,
//           "3.753",
//           34.9,
//           31.0,
//           2.0,
//           -14.0,
//           -36.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1592: [
//           "2033",
//           0.55,
//           0.11,
//           0.6366,
//           0.7088,
//           0.735,
//           0.7699,
//           "0.724",
//           11.4,
//           31.0,
//           8.0,
//           12.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1593: [
//           "0923",
//           0.038,
//           0.0,
//           0.04428,
//           0.04607,
//           0.04852,
//           0.0506,
//           "0.048",
//           1.8327,
//           31.0,
//           5.0,
//           -2.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1594: [
//           "8123",
//           0.012,
//           0.0,
//           0.01208,
//           0.0131,
//           0.0140267,
//           0.01587,
//           "0.013",
//           0.9298,
//           31.0,
//           1.0,
//           10.0,
//           10.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1595: [
//           "9911",
//           3.18,
//           4112.0,
//           4.0918,
//           4.0703,
//           4.27627,
//           4.769,
//           "4.201",
//           37.8,
//           30.0,
//           -11.0,
//           -25.0,
//           -22.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1596: [
//           "8331",
//           0.3,
//           1.2,
//           0.3723,
//           0.413325,
//           0.45445,
//           0.47355,
//           "-",
//           0.4773,
//           30.0,
//           6.0,
//           10.0,
//           14.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1597: [
//           "6919",
//           8.44,
//           465.0,
//           8.458,
//           8.4858,
//           8.87587,
//           10.892,
//           "8.833",
//           13.2,
//           30.0,
//           6.0,
//           10.0,
//           15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1598: [
//           "0432",
//           0.465,
//           205.0,
//           0.5777,
//           0.59395,
//           0.6077,
//           0.632775,
//           "0.6",
//           9.4778,
//           30.0,
//           2.0,
//           -8.0,
//           -7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1599: [
//           "9181",
//           12.02,
//           19.023,
//           14.4938,
//           15.6209,
//           16.0006,
//           16.4347,
//           "15.772",
//           0.0,
//           30.0,
//           1.0,
//           3.0,
//           0.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1600: [
//           "0295",
//           0.047,
//           0.462,
//           0.05508,
//           0.06109,
//           0.0624467,
//           0.068035,
//           "0.062",
//           7.0332,
//           30.0,
//           3.0,
//           14.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1601: [
//           "6288",
//           37.5,
//           33.271,
//           42.981,
//           46.209,
//           48.3403,
//           50.6017,
//           "47.628",
//           187.5,
//           30.0,
//           3.0,
//           4.0,
//           8.0,
//           0.0,
//           "-",
//         ],
//       },
//       {
//         1602: [
//           "1137",
//           6.68,
//           3857.0,
//           7.9834,
//           8.9502,
//           9.45547,
//           9.62925,
//           "9.323",
//           61.4,
//           30.0,
//           4.0,
//           -4.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1603: [
//           "1900",
//           0.125,
//           0.625,
//           0.1496,
//           0.15742,
//           0.164013,
//           0.17078,
//           "0.161",
//           2.0675,
//           30.0,
//           -3.0,
//           8.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1604: [
//           "6808",
//           2.8,
//           10444.0,
//           3.0454,
//           3.2541,
//           3.57193,
//           4.0778,
//           "3.43",
//           267.1,
//           30.0,
//           12.0,
//           14.0,
//           14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1605: [
//           "2331",
//           52.25,
//           245000.0,
//           76.549,
//           82.9335,
//           86.347,
//           85.4492,
//           "84.843",
//           1367.1000000000004,
//           30.0,
//           -4.0,
//           -40.0,
//           -53.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1606: [
//           "2003",
//           3.22,
//           2.534,
//           3.8496,
//           3.9447,
//           4.24427,
//           4.55315,
//           "4.148",
//           15.7,
//           30.0,
//           2.0,
//           1.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1607: [
//           "3181",
//           94.02,
//           93.353,
//           112.896,
//           121.708,
//           124.625,
//           127.931,
//           "122.788",
//           0.0,
//           30.0,
//           1.0,
//           2.0,
//           -1.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1608: [
//           "3176",
//           7.95,
//           98.654,
//           10.0383,
//           "-",
//           "-",
//           "-",
//           "-",
//           0.0,
//           30.0,
//           -1.0,
//           -3.0,
//           -5.0,
//           1.0,
//           "E",
//         ],
//       },
//       {
//         1609: [
//           "0596",
//           2.31,
//           777.0,
//           3.9872,
//           3.9325,
//           3.97813,
//           3.5618,
//           "4.02",
//           26.3,
//           30.0,
//           -9.0,
//           -30.0,
//           -59.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1610: [
//           "2230",
//           0.136,
//           21.087,
//           0.15922,
//           0.1675,
//           0.180233,
//           0.19839,
//           "0.174",
//           2.7091,
//           30.0,
//           -2.0,
//           4.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1611: [
//           "0592",
//           0.465,
//           278.0,
//           0.7357,
//           0.90875,
//           0.875767,
//           0.82605,
//           "0.888",
//           11.4,
//           30.0,
//           -3.0,
//           -30.0,
//           -23.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1612: [
//           "1846",
//           6.7,
//           323.0,
//           8.1302,
//           8.7477,
//           9.69233,
//           10.2212,
//           "9.365",
//           22.3,
//           30.0,
//           -1.0,
//           6.0,
//           6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1613: [
//           "1007",
//           0.085,
//           11.082,
//           0.11902,
//           0.11558,
//           0.112247,
//           0.11483,
//           "0.112",
//           0.5959,
//           30.0,
//           -8.0,
//           -18.0,
//           -45.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1614: [
//           "2239",
//           2.6,
//           0.497,
//           2.8998,
//           3.2918,
//           3.691,
//           3.70105,
//           "3.504",
//           8.3291,
//           30.0,
//           -1.0,
//           0.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1615: [
//           "3051",
//           43.34,
//           27.436,
//           52.2872,
//           "-",
//           "-",
//           "-",
//           "-",
//           0.0,
//           30.0,
//           1.0,
//           3.0,
//           -4.0,
//           1.0,
//           "E",
//         ],
//       },
//       {
//         1616: [
//           "8053",
//           0.42,
//           44.86,
//           0.447,
//           0.4833,
//           0.515133,
//           0.55095,
//           "0.505",
//           14.9,
//           30.0,
//           2.0,
//           5.0,
//           13.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1617: [
//           "2176",
//           0.285,
//           4.815,
//           0.3568,
//           0.3857,
//           0.397267,
//           0.41575,
//           "0.395",
//           0.5956,
//           30.0,
//           -18.0,
//           -17.0,
//           -10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1618: [
//           "0860",
//           0.35,
//           285.0,
//           0.4292,
//           0.47415,
//           0.484233,
//           0.4684,
//           "0.486",
//           27.9,
//           29.0,
//           5.0,
//           -2.0,
//           -7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1619: [
//           "0806",
//           3.04,
//           1535.0,
//           3.8092,
//           3.9118,
//           3.99553,
//           4.2104,
//           "3.942",
//           56.2,
//           29.0,
//           1.0,
//           -1.0,
//           -8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1620: [
//           "2250",
//           1.66,
//           280.0,
//           "-",
//           "-",
//           "-",
//           "-",
//           "-",
//           16.6,
//           29.0,
//           2.0,
//           3.0,
//           0.0,
//           1.0,
//           "M",
//         ],
//       },
//       {
//         1621: [
//           "0951",
//           1.66,
//           678.0,
//           2.103,
//           2.1232,
//           2.2576,
//           2.35135,
//           "2.186",
//           18.3,
//           29.0,
//           1.0,
//           0.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1622: [
//           "8521",
//           0.1,
//           0.45,
//           0.12596,
//           0.12936,
//           0.14258,
//           0.14604,
//           "0.138",
//           0.48,
//           29.0,
//           3.0,
//           8.0,
//           13.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1623: [
//           "3166",
//           33.44,
//           0.345,
//           41.7396,
//           43.3924,
//           44.1371,
//           46.0081,
//           "43.543",
//           0.0,
//           29.0,
//           -1.0,
//           -2.0,
//           -8.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1624: [
//           "1525",
//           3.8,
//           118.0,
//           4.2938,
//           4.6652,
//           4.8482,
//           5.0925,
//           "4.753",
//           15.7,
//           29.0,
//           1.0,
//           7.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1625: [
//           "2340",
//           0.27,
//           3.002,
//           0.5689,
//           0.6668,
//           0.548733,
//           0.4756,
//           "0.566",
//           1.147,
//           29.0,
//           4.0,
//           3.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1626: [
//           "3185",
//           41.46,
//           0.829,
//           48.9892,
//           "-",
//           "-",
//           "-",
//           "-",
//           0.0,
//           29.0,
//           4.0,
//           8.0,
//           6.0,
//           1.0,
//           "E",
//         ],
//       },
//       {
//         1627: [
//           "1115",
//           0.48,
//           0.972,
//           0.5477,
//           0.55815,
//           0.586033,
//           0.651425,
//           "0.573",
//           12.4,
//           29.0,
//           5.0,
//           3.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1628: [
//           "0265",
//           0.04,
//           2.433,
//           0.04812,
//           0.05077,
//           0.0555933,
//           0.059585,
//           "0.053",
//           5.1688,
//           29.0,
//           -6.0,
//           -3.0,
//           -5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1629: [
//           "1268",
//           27.15,
//           9740.0,
//           36.092,
//           37.7775,
//           38.6407,
//           39.501,
//           "38.027",
//           344.9,
//           29.0,
//           -13.0,
//           -31.0,
//           -41.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1630: [
//           "9801",
//           2.568,
//           3896.0,
//           3.28968,
//           3.46772,
//           "-",
//           "-",
//           "3.521",
//           0.0,
//           29.0,
//           0.0,
//           -2.0,
//           -10.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1631: [
//           "1066",
//           8.7,
//           6760.0,
//           9.9336,
//           10.6709,
//           11.6397,
//           12.8805,
//           "11.402",
//           393.4,
//           29.0,
//           1.0,
//           0.0,
//           -19.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1632: [
//           "2869",
//           5.87,
//           11239.0,
//           7.8272,
//           7.7025,
//           7.90893,
//           8.5965,
//           "7.824",
//           190.5,
//           29.0,
//           0.0,
//           -18.0,
//           -31.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1633: [
//           "1086",
//           0.87,
//           197.0,
//           1.0188,
//           1.0906,
//           1.16947,
//           1.3309,
//           "1.123",
//           14.5,
//           29.0,
//           -1.0,
//           4.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1634: [
//           "0575",
//           0.115,
//           18.597,
//           0.1364,
//           0.14238,
//           0.151913,
//           0.156645,
//           "0.149",
//           2.7593,
//           29.0,
//           -2.0,
//           9.0,
//           8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1635: [
//           "2163",
//           8.63,
//           130.0,
//           11.1076,
//           10.8053,
//           11.0225,
//           12.1161,
//           "10.805",
//           26.7,
//           29.0,
//           1.0,
//           -7.0,
//           -22.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1636: [
//           "2806",
//           52.48,
//           506.0,
//           63.964,
//           67.7144,
//           68.9613,
//           71.6339,
//           "68.004",
//           0.0,
//           29.0,
//           1.0,
//           3.0,
//           -3.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1637: [
//           "2828",
//           66.42,
//           793000.0,
//           83.3856,
//           85.9142,
//           87.8991,
//           92.0178,
//           "86.707",
//           0.0,
//           29.0,
//           0.0,
//           -1.0,
//           -7.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1638: [
//           "2688",
//           96.35,
//           41292.0,
//           124.142,
//           131.257,
//           135.614,
//           139.583,
//           "130.765",
//           1088.8,
//           29.0,
//           0.0,
//           3.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1639: [
//           "1150",
//           0.19,
//           1.259,
//           0.25422,
//           0.26826,
//           0.289073,
//           0.285855,
//           "0.283",
//           1.3526,
//           29.0,
//           -4.0,
//           2.0,
//           -13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1640: [
//           "2057",
//           169.6,
//           6221.0,
//           226.106,
//           229.629,
//           229.117,
//           229.464,
//           "230.23",
//           1450.5,
//           29.0,
//           -11.0,
//           -24.0,
//           -29.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1641: [
//           "0726",
//           0.6,
//           14.995,
//           0.766,
//           0.7904,
//           0.804267,
//           0.8119,
//           "0.799",
//           18.6,
//           28.0,
//           6.0,
//           3.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1642: [
//           "8491",
//           0.76,
//           44.14,
//           0.8756,
//           0.9048,
//           0.880933,
//           0.95595,
//           "0.873",
//           0.5012,
//           28.0,
//           -2.0,
//           -15.0,
//           8.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1643: [
//           "1463",
//           2.53,
//           9.18,
//           3.074,
//           3.4496,
//           3.444,
//           3.43605,
//           "3.427",
//           20.2,
//           28.0,
//           6.0,
//           12.0,
//           -12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1644: [
//           "3007",
//           212.4,
//           0.0,
//           271.43,
//           282.184,
//           287.665,
//           298.135,
//           "284.17",
//           0.0,
//           28.0,
//           0.0,
//           -2.0,
//           -7.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1645: [
//           "9888",
//           108.1,
//           146000.0,
//           148.218,
//           150.387,
//           151.559,
//           158.615,
//           "150.863",
//           3057.9,
//           28.0,
//           -11.0,
//           -17.0,
//           -22.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         1646: [
//           "0928",
//           0.085,
//           5.8,
//           0.10518,
//           0.12009,
//           0.121553,
//           0.12373,
//           "0.121",
//           1.0922,
//           28.0,
//           -3.0,
//           4.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1647: [
//           "0063",
//           0.06,
//           1.072,
//           0.06342,
//           0.07429,
//           0.0839267,
//           0.08965,
//           "0.081",
//           1.6914,
//           28.0,
//           6.0,
//           9.0,
//           20.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1648: [
//           "2768",
//           2.15,
//           581.0,
//           2.6116,
//           2.7702,
//           2.85673,
//           2.99625,
//           "2.815",
//           105.5,
//           28.0,
//           4.0,
//           4.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1649: [
//           "3933",
//           3.7,
//           2173.0,
//           4.4632,
//           4.6674,
//           4.90647,
//           5.2682,
//           "4.816",
//           67.5,
//           28.0,
//           1.0,
//           1.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1650: [
//           "1830",
//           3.92,
//           815.0,
//           4.8184,
//           5.3954,
//           5.74307,
//           6.5119,
//           "5.584",
//           48.6,
//           28.0,
//           4.0,
//           5.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1651: [
//           "0558",
//           8.75,
//           7041.0,
//           12.9278,
//           15.7127,
//           17.1985,
//           16.2542,
//           "16.488",
//           120.4,
//           28.0,
//           -5.0,
//           4.0,
//           -29.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1652: [
//           "0765",
//           0.6,
//           5.618,
//           0.68,
//           0.78,
//           0.849867,
//           0.9232,
//           "0.813",
//           1.9615,
//           28.0,
//           5.0,
//           11.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1653: [
//           "0273",
//           0.019,
//           1.802,
//           0.02056,
//           0.02197,
//           0.02278,
//           0.024555,
//           "0.022",
//           8.4293,
//           28.0,
//           6.0,
//           9.0,
//           7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1654: [
//           "6611",
//           2.9,
//           0.871,
//           3.0032,
//           3.5684,
//           3.89267,
//           "-",
//           "3.775",
//           19.5,
//           28.0,
//           4.0,
//           9.0,
//           15.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         1655: [
//           "1757",
//           0.17,
//           0.51,
//           0.22522,
//           0.24266,
//           0.26758,
//           0.279835,
//           "0.253",
//           2.04,
//           28.0,
//           -2.0,
//           -6.0,
//           -12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1656: [
//           "0302",
//           2.22,
//           4078.0,
//           2.9654,
//           3.1017,
//           3.21927,
//           3.3905,
//           "3.126",
//           61.4,
//           28.0,
//           -1.0,
//           -8.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1657: [
//           "2280",
//           0.6,
//           53.804,
//           0.6922,
//           0.7233,
//           0.765533,
//           0.8143,
//           "0.753",
//           7.8595,
//           28.0,
//           5.0,
//           6.0,
//           6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1658: [
//           "8160",
//           0.068,
//           16.534,
//           0.0712,
//           0.07286,
//           0.0756,
//           0.08052,
//           "0.074",
//           0.3553,
//           28.0,
//           -17.0,
//           -3.0,
//           5.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1659: [
//           "1029",
//           0.172,
//           291.0,
//           0.2398,
//           0.2376,
//           0.2472,
//           0.256435,
//           "0.245",
//           12.2,
//           28.0,
//           -2.0,
//           -20.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1660: [
//           "8418",
//           0.93,
//           33.848,
//           1.0376,
//           1.0784,
//           1.15533,
//           1.2496,
//           "1.134",
//           7.905,
//           28.0,
//           7.0,
//           -1.0,
//           5.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1661: [
//           "8619",
//           0.102,
//           46.41,
//           0.10268,
//           0.16494,
//           0.14422,
//           0.135475,
//           "0.145",
//           1.175,
//           28.0,
//           7.0,
//           20.0,
//           17.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1662: [
//           "2186",
//           2.85,
//           2830.0,
//           3.434,
//           3.5447,
//           3.71633,
//           4.0077,
//           "3.647",
//           100.9,
//           28.0,
//           2.0,
//           4.0,
//           6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1663: [
//           "9806",
//           6.69,
//           95.811,
//           8.2006,
//           8.68455,
//           8.8494,
//           9.2012,
//           "8.729",
//           0.0,
//           28.0,
//           0.0,
//           1.0,
//           -4.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1664: [
//           "1101",
//           0.076,
//           0.0,
//           0.09438,
//           0.10428,
//           0.10498,
//           0.105905,
//           "0.105",
//           3.6255,
//           28.0,
//           -5.0,
//           6.0,
//           -17.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1665: [
//           "8283",
//           0.111,
//           7.529,
//           0.14986,
//           0.1714,
//           0.165267,
//           0.1622,
//           "0.163",
//           2.22,
//           27.0,
//           -11.0,
//           -35.0,
//           -18.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1666: [
//           "0372",
//           0.195,
//           1.17,
//           0.24354,
//           0.27092,
//           0.27658,
//           0.29286,
//           "0.273",
//           3.9356,
//           27.0,
//           6.0,
//           1.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1667: [
//           "9983",
//           3.99,
//           2015.0,
//           4.885,
//           5.0587,
//           5.28687,
//           5.7686,
//           "5.161",
//           50.6,
//           27.0,
//           3.0,
//           -4.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1668: [
//           "1620",
//           0.138,
//           8.032,
//           0.17088,
//           0.1774,
//           0.190047,
//           0.20114,
//           "0.185",
//           1.656,
//           27.0,
//           3.0,
//           -7.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1669: [
//           "3737",
//           0.8,
//           2.4,
//           0.9646,
//           0.9999,
//           1.07553,
//           1.15845,
//           "1.034",
//           6.9088,
//           27.0,
//           2.0,
//           3.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1670: [
//           "2838",
//           126.5,
//           39.979,
//           161.564,
//           168.118,
//           171.507,
//           178.114,
//           "169.37",
//           0.0,
//           27.0,
//           -1.0,
//           -2.0,
//           -7.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1671: [
//           "0638",
//           0.65,
//           4.75,
//           0.7584,
//           0.7833,
//           0.8134,
//           0.8731,
//           "0.797",
//           2.8532,
//           27.0,
//           5.0,
//           9.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1672: [
//           "1671",
//           0.64,
//           0.648,
//           0.7728,
//           0.816,
//           0.864067,
//           0.8978,
//           "0.849",
//           1.0234,
//           27.0,
//           -2.0,
//           -1.0,
//           -9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1673: [
//           "2202",
//           14.78,
//           26215.0,
//           19.477,
//           19.1797,
//           19.8999,
//           20.9502,
//           "19.53",
//           280.9,
//           27.0,
//           1.0,
//           -3.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1674: [
//           "1064",
//           0.085,
//           9.123,
//           0.0927,
//           0.10601,
//           0.11598,
//           0.12208,
//           "0.111",
//           0.6065,
//           27.0,
//           2.0,
//           13.0,
//           15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1675: [
//           "0966",
//           8.49,
//           6648.000000000001,
//           10.6478,
//           11.0217,
//           11.3027,
//           11.6515,
//           "11.198",
//           305.1,
//           27.0,
//           2.0,
//           4.0,
//           6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1676: [
//           "6839",
//           0.73,
//           29.121,
//           0.8784,
//           0.922,
//           0.9724,
//           1.02825,
//           "0.95",
//           2.6551,
//           27.0,
//           2.0,
//           -8.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1677: [
//           "1440",
//           0.415,
//           110.0,
//           0.6101,
//           0.60875,
//           0.696167,
//           0.706275,
//           "0.66",
//           5.229,
//           27.0,
//           -29.0,
//           -33.0,
//           -39.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1678: [
//           "8420",
//           0.154,
//           3.613,
//           0.16804,
//           0.19822,
//           0.2088,
//           0.222345,
//           "0.201",
//           1.1088,
//           27.0,
//           5.0,
//           11.0,
//           18.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1679: [
//           "8473",
//           0.176,
//           2.835,
//           0.21766,
//           0.23064,
//           0.236493,
//           0.245185,
//           "0.232",
//           1.9712,
//           27.0,
//           5.0,
//           -1.0,
//           2.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1680: [
//           "1060",
//           0.58,
//           4104.0,
//           0.788,
//           0.8039,
//           0.822933,
//           0.8755,
//           "0.805",
//           156.4,
//           27.0,
//           -4.0,
//           -11.0,
//           -9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1681: [
//           "7272",
//           8.25,
//           25.636,
//           10.5028,
//           11.1037,
//           11.1379,
//           11.4334,
//           "11.042",
//           0.0,
//           27.0,
//           -1.0,
//           -1.0,
//           -14.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1682: [
//           "1215",
//           0.025,
//           1.194,
//           0.02772,
//           0.03159,
//           0.03376,
//           0.035185,
//           "0.033",
//           3.1947,
//           27.0,
//           -1.0,
//           2.0,
//           7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1683: [
//           "0526",
//           0.038,
//           3.816,
//           0.04268,
//           0.04456,
//           0.0479,
//           0.053435,
//           "0.046",
//           3.0567,
//           27.0,
//           3.0,
//           7.0,
//           12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1684: [
//           "0530",
//           0.15,
//           53.786,
//           0.17,
//           0.18631,
//           0.213133,
//           0.240325,
//           "0.203",
//           10.4,
//           27.0,
//           -6.0,
//           3.0,
//           12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1685: [
//           "0127",
//           2.31,
//           45.342,
//           2.6624,
//           3.1629,
//           3.08267,
//           3.20005,
//           "3.061",
//           44.0,
//           27.0,
//           5.0,
//           7.0,
//           7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1686: [
//           "2005",
//           2.72,
//           4589.0,
//           3.6426,
//           3.6662,
//           3.9906,
//           4.43235,
//           "3.809",
//           81.30000000000003,
//           27.0,
//           0.0,
//           0.0,
//           -11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1687: [
//           "2008",
//           0.39,
//           22.12,
//           0.4252,
//           0.46685,
//           0.5057,
//           0.549025,
//           "0.49",
//           19.4,
//           27.0,
//           1.0,
//           5.0,
//           13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1688: [
//           "0994",
//           1.51,
//           266.0,
//           2.2054,
//           2.2377,
//           2.24847,
//           2.25385,
//           "2.221",
//           11.4,
//           26.0,
//           1.0,
//           -57.0,
//           -54.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1689: [
//           "0571",
//           0.295,
//           0.118,
//           0.3307,
//           0.34375,
//           0.462433,
//           0.522025,
//           "0.434",
//           4.4009,
//           26.0,
//           7.0,
//           11.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1690: [
//           "1515",
//           3.86,
//           1714.0,
//           4.5146,
//           4.7743,
//           5.35127,
//           6.1308,
//           "5.16",
//           50.0,
//           26.0,
//           1.0,
//           3.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1691: [
//           "0861",
//           3.21,
//           1134.0,
//           4.4004,
//           4.3028,
//           4.37047,
//           4.51925,
//           "4.318",
//           53.7,
//           26.0,
//           -2.0,
//           -3.0,
//           -25.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1692: [
//           "8198",
//           0.335,
//           0.54,
//           0.4083,
//           0.38805,
//           0.419133,
//           0.4736,
//           "0.409",
//           1.837,
//           26.0,
//           -9.0,
//           -12.0,
//           -3.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1693: [
//           "1660",
//           0.35,
//           232.0,
//           0.4374,
//           0.4518,
//           0.475633,
//           0.513475,
//           "0.461",
//           21.6,
//           26.0,
//           0.0,
//           3.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1694: [
//           "0503",
//           0.97,
//           14.699,
//           1.1468,
//           1.2994,
//           1.56113,
//           1.85805,
//           "1.47",
//           3.6599,
//           26.0,
//           13.0,
//           12.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1695: [
//           "8356",
//           0.01,
//           5.772,
//           0.01182,
//           0.01299,
//           0.01352,
//           0.01384,
//           "0.013",
//           0.4055,
//           26.0,
//           -3.0,
//           -1.0,
//           5.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1696: [
//           "0082",
//           0.3,
//           583.0,
//           0.3363,
//           0.35115,
//           0.396267,
//           0.466325,
//           "0.38",
//           13.5,
//           26.0,
//           1.0,
//           2.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1697: [
//           "1432",
//           0.39,
//           105.0,
//           0.5056,
//           0.5291,
//           0.544467,
//           0.56305,
//           "0.53",
//           32.6,
//           26.0,
//           4.0,
//           -7.0,
//           -7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1698: [
//           "7233",
//           6.95,
//           2888.0,
//           8.7745,
//           9.30485,
//           9.40827,
//           9.72025,
//           "9.306",
//           0.0,
//           26.0,
//           0.0,
//           1.0,
//           -8.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1699: [
//           "1569",
//           0.76,
//           20.344,
//           0.8782,
//           0.9662,
//           1.0388,
//           1.10135,
//           "1.01",
//           32.0,
//           26.0,
//           7.0,
//           9.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1700: [
//           "1366",
//           0.221,
//           5.157,
//           0.27752,
//           0.28231,
//           0.303207,
//           0.324205,
//           "0.293",
//           13.5,
//           26.0,
//           2.0,
//           -12.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1701: [
//           "0858",
//           0.08,
//           2.679,
//           0.09144,
//           0.09494,
//           0.0993533,
//           0.108265,
//           "0.097",
//           1.912,
//           26.0,
//           -2.0,
//           -2.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1702: [
//           "0256",
//           0.96,
//           59.93,
//           1.287,
//           1.3089,
//           1.34213,
//           1.3893,
//           "1.323",
//           41.7,
//           26.0,
//           5.0,
//           5.0,
//           6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1703: [
//           "3117",
//           32.12,
//           209.0,
//           42.9848,
//           45.847,
//           46.8233,
//           "-",
//           "46.173",
//           0.0,
//           26.0,
//           1.0,
//           0.0,
//           -9.0,
//           2.0,
//           "E",
//         ],
//       },
//       {
//         1704: [
//           "9997",
//           6.5,
//           2118.0,
//           8.106,
//           8.2433,
//           8.9462,
//           9.8628,
//           "8.789",
//           80.9,
//           26.0,
//           -1.0,
//           -1.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1705: [
//           "2128",
//           9.43,
//           6218.0,
//           13.0898,
//           12.3317,
//           12.9643,
//           14.3144,
//           "12.628",
//           292.5,
//           26.0,
//           1.0,
//           -8.0,
//           -15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1706: [
//           "1928",
//           15.56,
//           95115.0,
//           20.3772,
//           19.2549,
//           19.9305,
//           22.8962,
//           "19.236",
//           1259.3,
//           26.0,
//           1.0,
//           -2.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1707: [
//           "1917",
//           0.076,
//           11.154,
//           0.09504,
//           0.09709,
//           0.10286,
//           0.108685,
//           "0.1",
//           1.748,
//           26.0,
//           -5.0,
//           -7.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1708: [
//           "9989",
//           6.23,
//           383.0,
//           7.6156,
//           7.8977,
//           8.2522,
//           8.80115,
//           "8.06",
//           13.7,
//           26.0,
//           3.0,
//           2.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1709: [
//           "2552",
//           2.78,
//           205.0,
//           3.6458,
//           3.7957,
//           3.99687,
//           4.1295,
//           "3.989",
//           29.3,
//           26.0,
//           1.0,
//           -21.0,
//           -12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1710: [
//           "3040",
//           26.14,
//           55.942,
//           33.1952,
//           34.9832,
//           35.9689,
//           37.6634,
//           "35.441",
//           0.0,
//           26.0,
//           0.0,
//           -1.0,
//           -5.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1711: [
//           "9990",
//           6.85,
//           1371.0,
//           8.2642,
//           9.1397,
//           9.21067,
//           10.1199,
//           "9.101",
//           55.6,
//           25.0,
//           -2.0,
//           6.0,
//           8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1712: [
//           "0376",
//           1.4,
//           734.0,
//           1.631,
//           1.7135,
//           1.8736,
//           2.0911,
//           "1.824",
//           54.1,
//           25.0,
//           1.0,
//           2.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1713: [
//           "2801",
//           20.0,
//           1590.0,
//           25.6644,
//           27.0314,
//           27.8731,
//           29.2407,
//           "27.452",
//           0.0,
//           25.0,
//           0.0,
//           -2.0,
//           -5.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1714: [
//           "0982",
//           0.127,
//           5.112,
//           0.15096,
//           0.16031,
//           0.169487,
//           0.17847,
//           "0.167",
//           12.7,
//           25.0,
//           0.0,
//           3.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1715: [
//           "3058",
//           38.64,
//           68.309,
//           48.6332,
//           52.4328,
//           53.7836,
//           56.1484,
//           "52.803",
//           0.0,
//           25.0,
//           0.0,
//           2.0,
//           -6.0,
//           2.0,
//           "E",
//         ],
//       },
//       {
//         1716: [
//           "2209",
//           1.2,
//           25.544,
//           1.383,
//           1.4493,
//           1.63287,
//           "-",
//           "1.526",
//           4.7511,
//           25.0,
//           1.0,
//           7.0,
//           10.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         1717: [
//           "8065",
//           0.23,
//           0.822,
//           0.3214,
//           0.33335,
//           0.343433,
//           0.3537,
//           "0.34",
//           0.9257,
//           25.0,
//           0.0,
//           -23.0,
//           -26.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1718: [
//           "1701",
//           0.133,
//           6.048,
//           0.14418,
//           0.15997,
//           0.169487,
//           0.183345,
//           "0.168",
//           1.33,
//           25.0,
//           6.0,
//           15.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1719: [
//           "0816",
//           3.83,
//           3850.0,
//           "-",
//           "-",
//           "-",
//           "-",
//           "-",
//           34.5,
//           25.0,
//           -27.0,
//           "-",
//           "-",
//           "-",
//           "-",
//         ],
//       },
//       {
//         1720: [
//           "1347",
//           28.5,
//           27285.0,
//           38.557,
//           41.621,
//           42.106,
//           42.203,
//           "41.31",
//           370.9,
//           25.0,
//           0.0,
//           -2.0,
//           -18.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1721: [
//           "0084",
//           0.052,
//           0.473,
//           0.06902,
//           0.0717,
//           0.0731933,
//           0.076965,
//           "0.072",
//           0.5441,
//           25.0,
//           -1.0,
//           -15.0,
//           -10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1722: [
//           "8311",
//           0.18,
//           1.8,
//           0.2237,
//           0.22138,
//           0.22984,
//           0.246225,
//           "0.225",
//           2.6706,
//           25.0,
//           5.0,
//           1.0,
//           7.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1723: [
//           "0214",
//           0.65,
//           54.725,
//           0.8572,
//           0.8831,
//           0.9278,
//           1.0243,
//           "0.898",
//           5.4656,
//           25.0,
//           -5.0,
//           -7.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1724: [
//           "0708",
//           2.99,
//           13249.0,
//           3.5824,
//           3.5059,
//           4.43753,
//           9.71275,
//           "3.706",
//           324.2,
//           25.0,
//           14.0,
//           20.0,
//           21.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1725: [
//           "3681",
//           2.14,
//           12.955,
//           2.6574,
//           2.7684,
//           2.90627,
//           3.0143,
//           "2.832",
//           21.5,
//           25.0,
//           5.0,
//           0.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1726: [
//           "3306",
//           8.9,
//           1429.0,
//           11.3558,
//           12.2057,
//           13.6817,
//           13.9426,
//           "13.185",
//           46.1,
//           25.0,
//           6.0,
//           7.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1727: [
//           "1647",
//           0.173,
//           30.916,
//           0.1832,
//           0.19965,
//           0.211133,
//           0.22807,
//           "0.207",
//           1.8944,
//           25.0,
//           -6.0,
//           2.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1728: [
//           "0085",
//           0.51,
//           168.0,
//           0.6672,
//           0.7043,
//           0.742933,
//           0.77085,
//           "0.723",
//           10.3,
//           25.0,
//           -4.0,
//           -5.0,
//           -13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1729: [
//           "8607",
//           0.136,
//           0.205,
//           0.16072,
//           0.16943,
//           0.181853,
//           0.195285,
//           "0.176",
//           1.088,
//           25.0,
//           10.0,
//           -2.0,
//           -13.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1730: [
//           "6668",
//           2.54,
//           226.0,
//           3.4838,
//           3.5256,
//           3.64253,
//           4.05585,
//           "3.564",
//           25.9,
//           25.0,
//           -4.0,
//           -12.0,
//           -28.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1731: [
//           "0884",
//           3.49,
//           33417.0,
//           5.2126,
//           4.85561,
//           4.9246,
//           5.17675,
//           "4.878",
//           306.6,
//           25.0,
//           -12.0,
//           -52.0,
//           -50.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1732: [
//           "0007",
//           0.084,
//           30.873,
//           0.09466,
//           0.11083,
//           0.11622,
//           0.118915,
//           "0.114",
//           3.36,
//           25.0,
//           4.0,
//           4.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1733: [
//           "1927",
//           0.202,
//           35.407,
//           0.23808,
//           0.24854,
//           0.264827,
//           0.293945,
//           "0.257",
//           1.5998,
//           25.0,
//           3.0,
//           5.0,
//           4.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         1734: [
//           "0227",
//           0.178,
//           13.059,
//           0.19256,
//           0.212526,
//           0.233161,
//           0.256204,
//           "0.228",
//           2.7852,
//           25.0,
//           7.0,
//           7.0,
//           7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1735: [
//           "0580",
//           2.33,
//           2394.0,
//           3.3904,
//           4.0306,
//           4.02153,
//           3.7902,
//           "3.995",
//           38.1,
//           24.0,
//           -4.0,
//           -5.0,
//           -13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1736: [
//           "2138",
//           6.62,
//           1382.0,
//           8.8302,
//           10.3863,
//           10.549,
//           11.3277,
//           "10.354",
//           78.0,
//           24.0,
//           0.0,
//           1.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1737: [
//           "6162",
//           0.106,
//           3.766,
//           0.14508,
//           0.15549,
//           0.187207,
//           0.18083,
//           "0.169",
//           2.12,
//           24.0,
//           0.0,
//           9.0,
//           -8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1738: [
//           "1431",
//           0.255,
//           248.0,
//           0.25712,
//           0.28639,
//           0.313627,
//           0.368395,
//           "0.3",
//           11.9,
//           24.0,
//           10.0,
//           10.0,
//           14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1739: [
//           "1315",
//           0.021,
//           1.171,
//           0.0254,
//           0.02782,
//           0.0284333,
//           0.029005,
//           "0.028",
//           1.5749,
//           24.0,
//           8.0,
//           12.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1740: [
//           "2689",
//           6.64,
//           5953.0,
//           8.0122,
//           8.6437,
//           9.2824,
//           9.66545,
//           "9.097",
//           311.5,
//           24.0,
//           5.0,
//           4.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1741: [
//           "1302",
//           2.55,
//           2197.0,
//           3.2706,
//           3.4995,
//           3.6964,
//           3.9879,
//           "3.61",
//           118.0,
//           24.0,
//           -1.0,
//           1.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1742: [
//           "2342",
//           1.27,
//           1541.0,
//           1.7804,
//           1.8577,
//           1.89127,
//           1.90105,
//           "1.871",
//           35.2,
//           24.0,
//           1.0,
//           6.0,
//           -40.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1743: [
//           "6116",
//           0.4,
//           1.167,
//           0.466,
//           0.51755,
//           0.585033,
//           0.611375,
//           "0.56",
//           0.8591,
//           24.0,
//           6.0,
//           10.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1744: [
//           "2623",
//           0.092,
//           1.296,
//           0.11656,
//           0.12927,
//           0.134487,
//           0.15791,
//           "0.132",
//           4.8348,
//           24.0,
//           1.0,
//           -6.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1745: [
//           "0809",
//           0.116,
//           42.638,
//           0.15084,
//           0.19461,
//           0.2288,
//           0.22984,
//           "0.225",
//           10.3,
//           24.0,
//           9.0,
//           4.0,
//           16.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1746: [
//           "1483",
//           1.0,
//           13.742,
//           1.252,
//           1.3459,
//           1.34247,
//           1.3669,
//           "1.339",
//           7.95,
//           24.0,
//           5.0,
//           3.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1747: [
//           "0486",
//           4.0,
//           4373.0,
//           6.8868,
//           7.2071,
//           7.05127,
//           6.66225,
//           "7.093",
//           607.7,
//           24.0,
//           2.0,
//           -14.0,
//           -35.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1748: [
//           "1449",
//           0.38,
//           15.852,
//           0.4149,
//           0.4554,
//           0.475133,
//           0.5329,
//           "0.468",
//           2.5333,
//           24.0,
//           6.0,
//           6.0,
//           10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1749: [
//           "2360",
//           1.18,
//           4.898,
//           1.6214,
//           1.6727,
//           1.72633,
//           1.8098,
//           "1.69",
//           11.8,
//           24.0,
//           -12.0,
//           -14.0,
//           -8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1750: [
//           "0459",
//           0.095,
//           1.425,
//           0.12016,
//           0.12643,
//           0.134273,
//           0.1433,
//           "0.131",
//           1.715,
//           24.0,
//           4.0,
//           4.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1751: [
//           "1716",
//           0.415,
//           10.881,
//           0.4685,
//           0.50705,
//           0.587767,
//           0.723075,
//           "0.545",
//           1.1205,
//           24.0,
//           0.0,
//           3.0,
//           7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1752: [
//           "0451",
//           0.17,
//           1138.0,
//           0.19302,
//           0.24407,
//           0.27398,
//           0.27773,
//           "0.261",
//           35.8,
//           24.0,
//           -5.0,
//           1.0,
//           15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1753: [
//           "1899",
//           1.2,
//           510.0,
//           1.6018,
//           1.6645,
//           1.69,
//           1.7488,
//           "1.674",
//           19.9,
//           24.0,
//           1.0,
//           -2.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1754: [
//           "1530",
//           5.14,
//           6764.0,
//           6.3134,
//           6.6067,
//           6.9874,
//           7.4664,
//           "6.896",
//           125.3,
//           24.0,
//           -3.0,
//           -4.0,
//           -10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1755: [
//           "9987",
//           290.0,
//           52590.0,
//           377.484,
//           397.16,
//           420.431,
//           442.81,
//           "412.467",
//           1215.4,
//           24.0,
//           -13.0,
//           -15.0,
//           -19.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1756: [
//           "2528",
//           0.67,
//           18.02,
//           0.9562,
//           1.0532,
//           1.12213,
//           1.08355,
//           "1.102",
//           2.68,
//           24.0,
//           4.0,
//           -38.0,
//           -41.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1757: [
//           "6877",
//           0.103,
//           56.19,
//           0.1142,
//           0.12374,
//           0.139753,
//           0.15179,
//           "-",
//           2.0942,
//           24.0,
//           8.0,
//           11.0,
//           13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1758: [
//           "1608",
//           1.0,
//           341.0,
//           1.2296,
//           1.2765,
//           1.40713,
//           1.5032,
//           "1.373",
//           27.0,
//           23.0,
//           5.0,
//           8.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1759: [
//           "8275",
//           0.13,
//           3.632,
//           0.1389,
//           0.16317,
//           0.18238,
//           0.19792,
//           "0.177",
//           0.351,
//           23.0,
//           3.0,
//           12.0,
//           12.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1760: [
//           "2789",
//           0.035,
//           5.638,
//           0.03774,
//           0.04289,
//           0.0449667,
//           0.04871,
//           "0.044",
//           2.173,
//           23.0,
//           2.0,
//           6.0,
//           7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1761: [
//           "2779",
//           1.16,
//           3.015,
//           1.384,
//           1.5193,
//           1.56433,
//           1.70025,
//           "1.531",
//           18.6,
//           23.0,
//           3.0,
//           3.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1762: [
//           "0250",
//           0.025,
//           1.71,
//           0.0294,
//           0.03104,
//           0.0333733,
//           0.03712,
//           "0.033",
//           5.4301,
//           23.0,
//           3.0,
//           4.0,
//           7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1763: [
//           "0755",
//           0.028,
//           60.701,
//           0.03124,
//           0.03265,
//           0.0366267,
//           0.04205,
//           "0.035",
//           4.1662,
//           23.0,
//           1.0,
//           -1.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1764: [
//           "0700",
//           331.8,
//           2316000.0,
//           444.727,
//           458.799,
//           462.708,
//           486.926,
//           "459.73",
//           31866.8,
//           23.0,
//           -1.0,
//           -2.0,
//           -8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1765: [
//           "2314",
//           4.09,
//           3195.0,
//           5.3164,
//           5.4941,
//           5.8622,
//           5.97095,
//           "5.736",
//           177.1,
//           23.0,
//           3.0,
//           -6.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1766: [
//           "8452",
//           0.305,
//           0.0,
//           0.4335,
//           0.44705,
//           0.446,
//           0.4727,
//           "0.441",
//           0.274,
//           23.0,
//           -1.0,
//           6.0,
//           -6.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1767: [
//           "8425",
//           0.325,
//           5.326,
//           0.3435,
//           0.3318,
//           0.3679,
//           0.481425,
//           "0.351",
//           1.3,
//           23.0,
//           0.0,
//           8.0,
//           2.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1768: [
//           "9072",
//           12.84,
//           11.872,
//           16.1868,
//           18.3169,
//           19.2397,
//           19.7167,
//           "18.849",
//           0.0,
//           23.0,
//           2.0,
//           3.0,
//           3.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1769: [
//           "1761",
//           0.6,
//           113.0,
//           0.805,
//           0.7749,
//           0.7728,
//           0.858,
//           "0.743",
//           9.9642,
//           23.0,
//           -10.0,
//           -17.0,
//           -27.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1770: [
//           "6821",
//           228.0,
//           1585.0,
//           265.896,
//           "-",
//           "-",
//           "-",
//           "-",
//           44.8,
//           23.0,
//           2.0,
//           6.0,
//           3.0,
//           1.0,
//           "M",
//         ],
//       },
//       {
//         1771: [
//           "2338",
//           11.06,
//           7163.0,
//           14.1404,
//           14.4102,
//           15.6238,
//           15.9974,
//           "15.129",
//           214.9,
//           23.0,
//           3.0,
//           5.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1772: [
//           "0653",
//           0.124,
//           102.0,
//           0.1582,
//           0.17157,
//           0.168873,
//           0.188375,
//           "0.167",
//           4.3555,
//           23.0,
//           1.0,
//           3.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1773: [
//           "3603",
//           0.61,
//           380.0,
//           0.6096,
//           0.7332,
//           0.855533,
//           0.94555,
//           "0.824",
//           9.15,
//           23.0,
//           4.0,
//           4.0,
//           14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1774: [
//           "3072",
//           100.4,
//           1.412,
//           126.223,
//           142.753,
//           149.876,
//           153.472,
//           "146.828",
//           0.0,
//           23.0,
//           1.0,
//           2.0,
//           3.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1775: [
//           "6600",
//           7.15,
//           487.0,
//           8.1724,
//           8.8061,
//           9.4836,
//           10.6259,
//           "9.228",
//           48.7,
//           23.0,
//           2.0,
//           2.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1776: [
//           "1147",
//           0.074,
//           33.64,
//           0.09744,
//           0.10469,
//           0.106807,
//           0.109575,
//           "0.105",
//           1.48,
//           23.0,
//           -2.0,
//           -13.0,
//           -9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1777: [
//           "0483",
//           0.52,
//           26.948,
//           0.6846,
//           0.8407,
//           0.933733,
//           0.96515,
//           "0.895",
//           1.9103,
//           23.0,
//           -6.0,
//           6.0,
//           -11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1778: [
//           "1026",
//           0.132,
//           0.264,
//           0.15822,
//           0.18719,
//           0.196487,
//           0.2181,
//           "0.193",
//           7.2771,
//           23.0,
//           6.0,
//           9.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1779: [
//           "1061",
//           3.66,
//           303.0,
//           4.8174,
//           5.0129,
//           5.40973,
//           5.7395,
//           "5.255",
//           21.0,
//           23.0,
//           4.0,
//           -4.0,
//           -23.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1780: [
//           "1340",
//           0.059,
//           0.684,
//           0.07768,
//           0.08569,
//           0.0858467,
//           0.086295,
//           "0.085",
//           0.5196,
//           23.0,
//           4.0,
//           -2.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1781: [
//           "8067",
//           0.55,
//           0.155,
//           0.735,
//           0.7899,
//           0.793133,
//           0.92005,
//           "0.776",
//           0.99,
//           22.0,
//           4.0,
//           -10.0,
//           -3.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1782: [
//           "8137",
//           0.26,
//           56.493,
//           0.3325,
//           0.3609,
//           0.417433,
//           0.434425,
//           "0.395",
//           25.6,
//           22.0,
//           3.0,
//           3.0,
//           5.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1783: [
//           "1157",
//           4.35,
//           4650.0,
//           5.2546,
//           5.3378,
//           6.07067,
//           6.5324,
//           "5.863",
//           68.8,
//           22.0,
//           3.0,
//           2.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1784: [
//           "1300",
//           0.43,
//           27.363,
//           0.5383,
//           0.58255,
//           0.6211,
//           0.642325,
//           "0.607",
//           7.7034,
//           22.0,
//           0.0,
//           -3.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1785: [
//           "0491",
//           0.041,
//           12.334,
//           0.04944,
//           0.0542,
//           0.0584267,
//           0.064325,
//           "0.056",
//           1.3174,
//           22.0,
//           1.0,
//           -3.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1786: [
//           "1591",
//           0.02,
//           15.744,
//           0.02342,
//           0.02596,
//           0.0297867,
//           0.035755,
//           "0.028",
//           0.8,
//           22.0,
//           0.0,
//           0.0,
//           7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1787: [
//           "2020",
//           86.6,
//           187000.0,
//           116.772,
//           121.08,
//           131.99,
//           141.891,
//           "127.145",
//           2341.0,
//           22.0,
//           -2.0,
//           -13.0,
//           -22.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1788: [
//           "0712",
//           0.275,
//           71.57,
//           0.3228,
//           0.37535,
//           0.422167,
//           0.4491,
//           "0.406",
//           2.1402,
//           22.0,
//           -2.0,
//           8.0,
//           8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1789: [
//           "0484",
//           0.89,
//           25.564,
//           1.1882,
//           1.178,
//           1.21813,
//           1.30535,
//           "1.19",
//           1.3058,
//           22.0,
//           -1.0,
//           -10.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1790: [
//           "1189",
//           0.11,
//           48.265,
//           0.14994,
//           0.16312,
//           0.167267,
//           0.173715,
//           "0.163",
//           0.8681,
//           22.0,
//           6.0,
//           4.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1791: [
//           "1875",
//           2.6,
//           6.616,
//           3.698,
//           3.923,
//           4.0354,
//           4.12175,
//           "3.973",
//           15.9,
//           22.0,
//           -4.0,
//           -13.0,
//           -14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1792: [
//           "0359",
//           0.05,
//           1.8,
//           0.0682,
//           0.07081,
//           0.0743667,
//           0.0781,
//           "0.072",
//           0.6448,
//           22.0,
//           7.0,
//           -32.0,
//           -16.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1793: [
//           "1228",
//           5.48,
//           7.039,
//           7.1456,
//           "-",
//           "-",
//           "-",
//           "-",
//           23.2,
//           22.0,
//           3.0,
//           -1.0,
//           8.0,
//           1.0,
//           "M",
//         ],
//       },
//       {
//         1794: [
//           "1105",
//           0.425,
//           17.011,
//           0.5084,
//           0.52625,
//           0.6269,
//           0.699025,
//           "0.598",
//           3.7423,
//           22.0,
//           3.0,
//           3.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1795: [
//           "3828",
//           0.345,
//           8.256,
//           0.3973,
//           0.4342,
//           0.472133,
//           0.5208,
//           "0.455",
//           2.5332,
//           22.0,
//           3.0,
//           0.0,
//           7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1796: [
//           "0763",
//           14.4,
//           12433.0,
//           20.318,
//           21.3265,
//           23.1183,
//           23.369,
//           "22.389",
//           108.7,
//           22.0,
//           -1.0,
//           -3.0,
//           -20.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1797: [
//           "1745",
//           0.445,
//           15.746,
//           0.5254,
//           0.5513,
//           0.614667,
//           0.71855,
//           "0.587",
//           6.5132,
//           22.0,
//           2.0,
//           5.0,
//           10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1798: [
//           "1600",
//           4.92,
//           1081.0,
//           8.1286,
//           7.7895,
//           7.6872,
//           7.78445,
//           "7.637",
//           49.6,
//           22.0,
//           -1.0,
//           -3.0,
//           -15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1799: [
//           "1685",
//           0.29,
//           13.53,
//           0.3839,
//           0.39625,
//           0.425267,
//           0.43395,
//           "0.413",
//           2.2439,
//           22.0,
//           -9.0,
//           -4.0,
//           -7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1800: [
//           "1049",
//           1.1,
//           1.98,
//           1.6018,
//           1.7097,
//           1.71967,
//           1.64985,
//           "1.728",
//           0.8879,
//           22.0,
//           -4.0,
//           -15.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1801: [
//           "8489",
//           0.275,
//           2.61,
//           0.2998,
//           0.31985,
//           0.3406,
//           0.389275,
//           "0.331",
//           0.825,
//           22.0,
//           3.0,
//           6.0,
//           11.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1802: [
//           "8475",
//           0.072,
//           1.513,
//           0.0838,
//           0.08817,
//           0.09714,
//           0.10382,
//           "0.093",
//           0.3168,
//           22.0,
//           -2.0,
//           11.0,
//           9.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1803: [
//           "0619",
//           0.23,
//           0.331,
//           0.29806,
//           0.31793,
//           0.342087,
//           0.371115,
//           "0.333",
//           0.6929,
//           22.0,
//           1.0,
//           2.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1804: [
//           "1070",
//           2.91,
//           3791.0,
//           3.8874,
//           4.0275,
//           4.04527,
//           4.1781,
//           "3.997",
//           72.1,
//           21.0,
//           -1.0,
//           -2.0,
//           -8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1805: [
//           "3167",
//           56.4,
//           90.032,
//           73.6236,
//           78.5828,
//           80.9209,
//           85.5911,
//           "79.67",
//           0.0,
//           21.0,
//           -1.0,
//           -1.0,
//           -3.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1806: [
//           "7248",
//           3.888,
//           134.0,
//           5.04844,
//           5.35367,
//           5.36718,
//           5.67779,
//           "5.324",
//           0.0,
//           21.0,
//           -1.0,
//           0.0,
//           -10.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1807: [
//           "8371",
//           0.64,
//           2.56,
//           0.825,
//           0.8896,
//           0.960133,
//           1.0094,
//           "0.939",
//           2.4763,
//           21.0,
//           4.0,
//           10.0,
//           -5.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1808: [
//           "1699",
//           0.052,
//           0.593,
//           0.06322,
//           0.0738,
//           0.0909733,
//           0.094145,
//           "0.093",
//           0.9822,
//           21.0,
//           7.0,
//           7.0,
//           10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1809: [
//           "1010",
//           2.08,
//           11.026,
//           4.1944,
//           4.5331,
//           3.9474,
//           3.75735,
//           "3.934",
//           7.701,
//           21.0,
//           -6.0,
//           0.0,
//           -35.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1810: [
//           "8436",
//           0.16,
//           7.718,
//           0.20714,
//           0.22707,
//           0.235633,
//           0.24395,
//           "0.231",
//           0.64,
//           21.0,
//           2.0,
//           -9.0,
//           -7.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1811: [
//           "1705",
//           0.34,
//           5.028,
//           0.3856,
//           0.4218,
//           0.463433,
//           0.534925,
//           "0.445",
//           1.36,
//           21.0,
//           3.0,
//           3.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1812: [
//           "1282",
//           0.152,
//           824.0,
//           0.18578,
//           0.211489,
//           0.224319,
//           0.227094,
//           "0.221",
//           47.7,
//           21.0,
//           -1.0,
//           8.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1813: [
//           "0410",
//           1.37,
//           2579.0,
//           1.6844,
//           1.7578,
//           2.07153,
//           2.47215,
//           "1.954",
//           71.2,
//           21.0,
//           4.0,
//           1.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1814: [
//           "0970",
//           0.122,
//           29.073,
//           0.1596,
//           0.17161,
//           0.171687,
//           0.18288,
//           "0.169",
//           6.6757,
//           21.0,
//           6.0,
//           6.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1815: [
//           "6628",
//           4.87,
//           9.137,
//           7.7284,
//           8.2065,
//           "-",
//           "-",
//           "-",
//           21.6,
//           21.0,
//           -1.0,
//           -10.0,
//           4.0,
//           1.0,
//           "M",
//         ],
//       },
//       {
//         1816: [
//           "0802",
//           0.062,
//           4.464,
//           0.07868,
//           0.08741,
//           0.0944333,
//           0.099405,
//           "0.091",
//           1.8709,
//           21.0,
//           4.0,
//           8.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1817: [
//           "3606",
//           27.65,
//           15232.0,
//           40.139,
//           41.8125,
//           42.973,
//           45.2137,
//           "41.858",
//           167.7,
//           21.0,
//           1.0,
//           0.0,
//           -12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1818: [
//           "3368",
//           0.159,
//           3.993,
//           0.20184,
//           0.2259,
//           0.24076,
//           0.250785,
//           "0.234",
//           4.1889,
//           21.0,
//           -5.0,
//           -9.0,
//           -7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1819: [
//           "0826",
//           2.7,
//           3471.0,
//           4.0022,
//           4.306,
//           4.48673,
//           4.25915,
//           "4.42",
//           75.4,
//           21.0,
//           1.0,
//           -5.0,
//           -18.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1820: [
//           "3999",
//           0.5,
//           11.064,
//           0.6802,
//           0.7127,
//           0.736667,
//           0.7765,
//           "0.72",
//           5.0809,
//           21.0,
//           -1.0,
//           -6.0,
//           -10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1821: [
//           "0335",
//           0.5,
//           103.0,
//           0.6294,
//           0.7273,
//           0.7636,
//           0.78045,
//           "0.747",
//           13.4,
//           21.0,
//           4.0,
//           5.0,
//           8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1822: [
//           "9961",
//           142.6,
//           28064.0,
//           202.462,
//           209.736,
//           215.078,
//           227.942,
//           "215.867",
//           909.0,
//           21.0,
//           -4.0,
//           -7.0,
//           -13.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         1823: [
//           "8267",
//           0.27,
//           24.848,
//           0.2728,
//           0.29875,
//           0.3265,
//           0.393,
//           "0.314",
//           0.9935,
//           21.0,
//           0.0,
//           3.0,
//           9.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1824: [
//           "0754",
//           13.0,
//           8715.0,
//           16.0248,
//           17.4393,
//           20.1465,
//           22.8392,
//           "19.49",
//           308.6,
//           21.0,
//           1.0,
//           1.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1825: [
//           "1182",
//           0.35,
//           8.05,
//           0.3701,
//           0.39085,
//           0.445633,
//           0.524925,
//           "-",
//           0.4893,
//           21.0,
//           4.0,
//           10.0,
//           13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1826: [
//           "1860",
//           4.38,
//           903.0,
//           6.3546,
//           6.6999,
//           6.90187,
//           7.28795,
//           "6.776",
//           72.3,
//           21.0,
//           -6.0,
//           -14.0,
//           -17.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1827: [
//           "1128",
//           5.12,
//           10643.0,
//           6.8352,
//           6.7961,
//           7.12233,
//           8.33195,
//           "6.915",
//           266.5,
//           21.0,
//           0.0,
//           0.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1828: [
//           "0627",
//           0.013,
//           6.317,
//           0.0152,
//           0.01769,
//           0.01846,
//           0.018845,
//           "0.018",
//           1.4775,
//           20.0,
//           6.0,
//           3.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1829: [
//           "0399",
//           0.128,
//           13.286,
//           0.1571,
//           0.17014,
//           0.182767,
//           0.197815,
//           "0.177",
//           1.8741,
//           20.0,
//           3.0,
//           1.0,
//           8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1830: [
//           "6616",
//           4.04,
//           1698.0,
//           6.305,
//           6.6265,
//           6.42167,
//           "-",
//           "6.364",
//           48.1,
//           20.0,
//           -6.0,
//           -8.0,
//           -36.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         1831: [
//           "1193",
//           27.3,
//           11524.0,
//           38.659,
//           39.8395,
//           41.5723,
//           43.1543,
//           "40.392",
//           631.7,
//           20.0,
//           0.0,
//           -4.0,
//           -14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1832: [
//           "2129",
//           0.095,
//           13.385,
//           0.11424,
//           0.12659,
//           0.130907,
//           0.14012,
//           "0.128",
//           1.1875,
//           20.0,
//           2.0,
//           2.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1833: [
//           "1756",
//           0.95,
//           34.117,
//           1.1002,
//           1.2169,
//           1.3048,
//           1.5381,
//           "1.261",
//           11.4,
//           20.0,
//           3.0,
//           4.0,
//           7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1834: [
//           "3309",
//           4.12,
//           2184.0,
//           5.6528,
//           6.4928,
//           6.9844,
//           7.33775,
//           "6.865",
//           50.9,
//           20.0,
//           -1.0,
//           1.0,
//           -10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1835: [
//           "1097",
//           0.044,
//           9.372,
//           0.05686,
//           0.06295,
//           0.06566,
//           0.0713,
//           "0.064",
//           3.1392,
//           20.0,
//           2.0,
//           6.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1836: [
//           "2820",
//           70.06,
//           547.0,
//           85.2168,
//           95.6429,
//           102.174,
//           109.905,
//           "99.43",
//           0.0,
//           20.0,
//           1.0,
//           1.0,
//           2.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1837: [
//           "0425",
//           20.35,
//           5473.0,
//           32.609,
//           33.2515,
//           32.2917,
//           32.7072,
//           "31.862",
//           236.4,
//           20.0,
//           0.0,
//           -3.0,
//           -13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1838: [
//           "1177",
//           4.09,
//           25496.0,
//           5.4304,
//           5.5289,
//           5.8206,
//           6.2619,
//           "5.703",
//           770.4,
//           20.0,
//           0.0,
//           1.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1839: [
//           "6811",
//           1.01,
//           221.0,
//           1.2514,
//           1.3562,
//           1.45733,
//           1.60675,
//           "1.416",
//           10.1,
//           20.0,
//           5.0,
//           1.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1840: [
//           "1502",
//           2.71,
//           750.0,
//           3.2582,
//           3.5818,
//           3.72947,
//           3.9899,
//           "3.676",
//           2.8048,
//           20.0,
//           -5.0,
//           -6.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1841: [
//           "0756",
//           0.074,
//           0.207,
//           0.08434,
//           0.09419,
//           0.10042,
//           0.11583,
//           "0.098",
//           1.6887,
//           20.0,
//           -1.0,
//           5.0,
//           6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1842: [
//           "9922",
//           11.88,
//           51542.0,
//           16.4894,
//           16.6839,
//           19.2343,
//           21.8132,
//           "18.516",
//           172.6,
//           20.0,
//           -2.0,
//           -8.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1843: [
//           "8320",
//           0.068,
//           0.544,
//           0.0868,
//           0.09357,
//           0.0962867,
//           0.101095,
//           "-",
//           0.477,
//           20.0,
//           -4.0,
//           -3.0,
//           2.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1844: [
//           "0439",
//           0.15,
//           276.0,
//           0.19094,
//           0.20198,
//           0.2181,
//           0.22478,
//           "0.211",
//           9.2353,
//           20.0,
//           2.0,
//           4.0,
//           -10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1845: [
//           "0073",
//           0.13,
//           1.745,
//           0.15208,
//           0.16558,
//           0.184067,
//           0.211825,
//           "0.177",
//           3.2495,
//           20.0,
//           4.0,
//           11.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1846: [
//           "7345",
//           4.422,
//           62.788,
//           5.52054,
//           "-",
//           "-",
//           "-",
//           "-",
//           0.0,
//           20.0,
//           5.0,
//           12.0,
//           4.0,
//           1.0,
//           "E",
//         ],
//       },
//       {
//         1847: [
//           "1673",
//           0.57,
//           62.922,
//           0.6304,
//           0.6297,
//           0.7844,
//           0.9528,
//           "0.747",
//           5.0544,
//           20.0,
//           5.0,
//           -1.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1848: [
//           "9820",
//           8.96,
//           310.0,
//           10.9276,
//           12.2726,
//           13.1191,
//           14.121,
//           "12.77",
//           0.0,
//           20.0,
//           1.0,
//           1.0,
//           2.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1849: [
//           "0360",
//           0.046,
//           2.718,
//           0.06216,
//           0.07548,
//           0.08334,
//           0.085405,
//           "0.08",
//           3.1131,
//           20.0,
//           4.0,
//           -2.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1850: [
//           "9167",
//           7.14,
//           26.116000000000003,
//           9.4429,
//           10.089,
//           10.3925,
//           11.002,
//           "10.219",
//           0.0,
//           20.0,
//           -1.0,
//           -2.0,
//           -4.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1851: [
//           "0167",
//           0.023,
//           0.0,
//           0.02682,
//           0.03024,
//           0.03326,
//           0.03735,
//           "0.032",
//           0.5979,
//           19.0,
//           4.0,
//           7.0,
//           7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1852: [
//           "2282",
//           4.06,
//           5244.0,
//           5.0986,
//           5.1521,
//           5.735,
//           7.1009,
//           "5.437",
//           154.2,
//           19.0,
//           2.0,
//           5.0,
//           6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1853: [
//           "2279",
//           10.36,
//           1303.0,
//           12.1732,
//           "-",
//           "-",
//           "-",
//           "-",
//           54.6,
//           19.0,
//           -4.0,
//           -8.0,
//           -5.0,
//           1.0,
//           "M",
//         ],
//       },
//       {
//         1854: [
//           "8285",
//           0.054,
//           4.547,
//           0.08144,
//           0.091,
//           0.09134,
//           0.09123,
//           "0.091",
//           0.3024,
//           19.0,
//           -1.0,
//           -19.0,
//           -3.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1855: [
//           "0919",
//           0.112,
//           0.346,
//           0.14256,
//           0.16743,
//           0.190093,
//           0.21445,
//           "0.182",
//           1.013,
//           19.0,
//           0.0,
//           2.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1856: [
//           "6989",
//           3.69,
//           3117.0,
//           4.7182,
//           4.7871,
//           5.13353,
//           5.9133,
//           "4.983",
//           45.0,
//           19.0,
//           -2.0,
//           -7.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1857: [
//           "8545",
//           0.055,
//           9.09,
//           0.0597,
//           0.08699,
//           0.0917867,
//           0.091225,
//           "0.09",
//           0.55,
//           19.0,
//           6.0,
//           11.0,
//           11.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1858: [
//           "8237",
//           0.014,
//           5.518,
//           0.01614,
//           0.01853,
//           0.02104,
//           0.023195,
//           "0.02",
//           0.4886,
//           19.0,
//           -2.0,
//           1.0,
//           1.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1859: [
//           "2155",
//           6.0,
//           1839.0,
//           8.1548,
//           9.7101,
//           9.71473,
//           "-",
//           "9.457",
//           62.2,
//           19.0,
//           3.0,
//           -1.0,
//           2.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         1860: [
//           "1207",
//           0.024,
//           4.715,
//           0.03448,
//           0.03682,
//           0.0384467,
//           0.040095,
//           "0.038",
//           4.9355,
//           19.0,
//           5.0,
//           5.0,
//           -15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1861: [
//           "8028",
//           0.024,
//           0.144,
//           0.0284,
//           0.03189,
//           0.0359333,
//           0.03861,
//           "0.034",
//           0.675,
//           19.0,
//           -1.0,
//           0.0,
//           4.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1862: [
//           "0345",
//           12.38,
//           1512.0,
//           14.9852,
//           16.1172,
//           17.2969,
//           19.6609,
//           "16.902",
//           132.4,
//           19.0,
//           3.0,
//           2.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1863: [
//           "0980",
//           0.57,
//           29.829,
//           0.7118,
//           0.8052,
//           0.856733,
//           0.89335,
//           "0.832",
//           2.1238,
//           19.0,
//           4.0,
//           1.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1864: [
//           "2359",
//           93.25,
//           25947.000000000004,
//           112.819,
//           134.669,
//           144.49,
//           151.663,
//           "141.853",
//           365.6,
//           19.0,
//           0.0,
//           0.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1865: [
//           "0733",
//           1.23,
//           3.704,
//           1.3232,
//           1.6602,
//           1.99807,
//           2.30385,
//           "1.917",
//           8.292,
//           19.0,
//           -1.0,
//           2.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1866: [
//           "1680",
//           0.495,
//           13.89,
//           0.5549,
//           0.61625,
//           0.722633,
//           0.803825,
//           "0.689",
//           30.6,
//           19.0,
//           2.0,
//           6.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1867: [
//           "3601",
//           1.35,
//           53.277,
//           1.639,
//           1.7285,
//           1.76373,
//           1.93055,
//           "1.726",
//           3.6315,
//           19.0,
//           -4.0,
//           0.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1868: [
//           "6111",
//           3.13,
//           52.674,
//           4.0728,
//           4.3665,
//           4.8754,
//           5.16115,
//           "4.691",
//           25.8,
//           19.0,
//           7.0,
//           -3.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1869: [
//           "0780",
//           10.18,
//           10759.0,
//           15.0112,
//           15.876,
//           16.4292,
//           17.0975,
//           "16.367",
//           225.7,
//           19.0,
//           2.0,
//           -8.0,
//           -14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1870: [
//           "0764",
//           0.132,
//           1.74,
//           0.16624,
//           0.18312,
//           0.19942,
//           0.220245,
//           "0.193",
//           5.0418,
//           19.0,
//           3.0,
//           -2.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1871: [
//           "1521",
//           2.94,
//           2496.0,
//           3.6714,
//           3.9869,
//           4.4278,
//           5.05385,
//           "4.284",
//           61.0,
//           19.0,
//           3.0,
//           6.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1872: [
//           "3692",
//           13.72,
//           7112.0,
//           17.0988,
//           17.4715,
//           18.971,
//           22.205,
//           "18.122",
//           812.5,
//           19.0,
//           0.0,
//           -2.0,
//           -5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1873: [
//           "1526",
//           0.67,
//           24.374,
//           0.9274,
//           1.0128,
//           1.09613,
//           1.14625,
//           "1.07",
//           10.6,
//           19.0,
//           -11.0,
//           -11.0,
//           -12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1874: [
//           "1755",
//           9.15,
//           4790.0,
//           13.1582,
//           13.4483,
//           14.5401,
//           16.2887,
//           "14.167",
//           79.6,
//           18.0,
//           -3.0,
//           -10.0,
//           -9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1875: [
//           "2329",
//           0.226,
//           20.653,
//           0.29476,
//           0.32478,
//           0.327487,
//           0.337665,
//           "0.321",
//           10.0,
//           18.0,
//           -2.0,
//           5.0,
//           -5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1876: [
//           "3669",
//           6.89,
//           6703.0,
//           9.4682,
//           10.6315,
//           11.1843,
//           11.9437,
//           "10.867",
//           135.5,
//           18.0,
//           -2.0,
//           -4.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1877: [
//           "1179",
//           20.2,
//           8633.0,
//           30.181,
//           31.689,
//           33.3087,
//           35.0514,
//           "32.59",
//           660.0,
//           18.0,
//           -2.0,
//           -18.0,
//           -24.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1878: [
//           "1068",
//           0.53,
//           197.0,
//           0.7948,
//           0.8217,
//           0.840067,
//           0.8668,
//           "0.819",
//           9.6606,
//           18.0,
//           1.0,
//           -3.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1879: [
//           "8130",
//           0.03,
//           3.445,
//           0.0421,
//           0.04223,
//           0.0431733,
//           0.048175,
//           "0.043",
//           1.0921,
//           18.0,
//           -1.0,
//           3.0,
//           -12.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1880: [
//           "9959",
//           5.55,
//           8228.0,
//           7.3432,
//           7.3522,
//           8.30547,
//           10.0994,
//           "7.864",
//           127.6,
//           18.0,
//           -2.0,
//           -5.0,
//           -13.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         1881: [
//           "6186",
//           8.1,
//           30592.0,
//           10.3288,
//           10.9129,
//           11.8489,
//           13.331,
//           "11.525",
//           721.3,
//           18.0,
//           3.0,
//           5.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1882: [
//           "1662",
//           1.06,
//           6.52,
//           1.1166,
//           1.3519,
//           1.5178,
//           1.6708,
//           "1.469",
//           5.3,
//           18.0,
//           1.0,
//           7.0,
//           10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1883: [
//           "0660",
//           0.037,
//           12.171,
//           0.0484,
//           0.05223,
//           0.05562,
//           0.059405,
//           "0.054",
//           0.6218,
//           18.0,
//           0.0,
//           4.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1884: [
//           "0697",
//           1.02,
//           889.0,
//           1.405,
//           1.5431,
//           1.58647,
//           1.63465,
//           "1.556",
//           74.3,
//           18.0,
//           -1.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1885: [
//           "0337",
//           1.15,
//           206.0,
//           1.4934,
//           1.5466,
//           1.6934,
//           1.85075,
//           "1.656",
//           32.1,
//           18.0,
//           2.0,
//           3.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1886: [
//           "1661",
//           0.117,
//           5.733,
//           0.13486,
//           0.15294,
//           0.16794,
//           0.180595,
//           "0.162",
//           1.8637,
//           18.0,
//           1.0,
//           7.0,
//           7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1887: [
//           "1260",
//           0.214,
//           2.304,
//           0.27738,
//           0.30234,
//           0.322527,
//           0.34692,
//           "0.314",
//           2.4641,
//           18.0,
//           -5.0,
//           -4.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1888: [
//           "9618",
//           180.0,
//           502000.0,
//           275.032,
//           294.83,
//           293.808,
//           291.745,
//           "292.74",
//           5633.6,
//           18.0,
//           -4.0,
//           -15.0,
//           -22.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1889: [
//           "0465",
//           0.36,
//           0.864,
//           0.5022,
//           0.548,
//           0.590867,
//           0.6178,
//           "0.569",
//           1.1205,
//           18.0,
//           3.0,
//           -4.0,
//           6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1890: [
//           "1293",
//           0.5,
//           321.0,
//           0.6396,
//           0.7147,
//           0.7988,
//           0.9101,
//           "0.77",
//           14.1,
//           18.0,
//           0.0,
//           0.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1891: [
//           "1905",
//           0.66,
//           84.856,
//           0.899,
//           0.9748,
//           1.04013,
//           1.1161,
//           "1.01",
//           38.2,
//           18.0,
//           4.0,
//           -6.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1892: [
//           "8005",
//           0.25,
//           7.015,
//           0.3484,
//           0.3816,
//           0.3862,
//           0.4042,
//           "0.383",
//           6.2192,
//           18.0,
//           3.0,
//           -1.0,
//           2.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1893: [
//           "3313",
//           0.03,
//           24.121,
//           0.03722,
//           0.03992,
//           0.0437467,
//           0.04942,
//           "0.042",
//           1.3106,
//           18.0,
//           3.0,
//           3.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1894: [
//           "0601",
//           0.036,
//           0.972,
//           0.04942,
//           0.05873,
//           0.0572733,
//           0.057255,
//           "0.057",
//           2.3703,
//           18.0,
//           5.0,
//           2.0,
//           8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1895: [
//           "2198",
//           1.69,
//           733.0,
//           2.325,
//           2.3637,
//           2.54873,
//           2.6978,
//           "2.459",
//           20.1,
//           18.0,
//           2.0,
//           -2.0,
//           -5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1896: [
//           "0904",
//           0.09,
//           1.152,
//           0.10094,
//           0.11156,
//           0.131793,
//           0.14911,
//           "0.123",
//           0.3943,
//           18.0,
//           4.0,
//           4.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1897: [
//           "7200",
//           4.712,
//           134000.0,
//           6.86584,
//           7.19647,
//           7.49108,
//           8.08121,
//           "7.318",
//           0.0,
//           17.0,
//           1.0,
//           0.0,
//           -7.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1898: [
//           "2382",
//           123.5,
//           136000.0,
//           197.292,
//           211.968,
//           214.209,
//           215.732,
//           "210.123",
//           1354.6000000000004,
//           17.0,
//           0.0,
//           -6.0,
//           -26.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1899: [
//           "1134",
//           0.88,
//           3.644,
//           1.0854,
//           1.3056,
//           1.4454,
//           1.4825,
//           "1.414",
//           4.4,
//           17.0,
//           -2.0,
//           6.0,
//           6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1900: [
//           "7231",
//           4.928,
//           960.0,
//           7.12026,
//           7.45613,
//           7.75615,
//           8.36691,
//           "7.584",
//           0.0,
//           17.0,
//           0.0,
//           0.0,
//           -7.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1901: [
//           "3738",
//           3.87,
//           7410.0,
//           5.552,
//           6.299,
//           6.54487,
//           6.92674,
//           "6.359",
//           81.89999999999998,
//           17.0,
//           6.0,
//           -5.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1902: [
//           "1810",
//           12.48,
//           216000.0,
//           16.5616,
//           18.3464,
//           20.1266,
//           21.9337,
//           "19.326",
//           3121.3,
//           17.0,
//           0.0,
//           3.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1903: [
//           "0950",
//           2.33,
//           46.836,
//           2.9976,
//           3.2484,
//           3.49593,
//           3.7562,
//           "3.396",
//           13.7,
//           17.0,
//           -1.0,
//           -2.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1904: [
//           "1229",
//           0.057,
//           0.125,
//           0.07714,
//           0.09172,
//           0.10082,
//           0.101905,
//           "0.101",
//           0.4362,
//           17.0,
//           5.0,
//           -3.0,
//           -7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1905: [
//           "1312",
//           0.036,
//           1.548,
//           0.04538,
//           0.05202,
//           0.05576,
//           0.059845,
//           "0.054",
//           2.0118,
//           17.0,
//           3.0,
//           4.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1906: [
//           "6127",
//           55.45,
//           5138.0,
//           60.346,
//           73.5715,
//           84.6337,
//           93.1205,
//           "80.737",
//           33.6,
//           17.0,
//           0.0,
//           0.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1907: [
//           "0381",
//           0.044,
//           7.301,
//           0.05752,
//           0.05798,
//           0.0597333,
//           0.061775,
//           "0.058",
//           0.5068,
//           17.0,
//           2.0,
//           2.0,
//           -15.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1908: [
//           "1995",
//           8.58,
//           17282.0,
//           13.5588,
//           13.702,
//           14.2421,
//           15.329,
//           "13.999",
//           150.3,
//           17.0,
//           -9.0,
//           -25.0,
//           -27.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1909: [
//           "6110",
//           5.76,
//           12495.0,
//           7.6772,
//           8.3143,
//           8.81893,
//           9.5366,
//           "8.507",
//           357.1,
//           17.0,
//           -4.0,
//           -8.0,
//           -12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1910: [
//           "0947",
//           0.25,
//           6.516,
//           0.3413,
//           0.35765,
//           0.366933,
//           0.400825,
//           "0.361",
//           2.0412,
//           17.0,
//           -1.0,
//           -5.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1911: [
//           "8219",
//           0.275,
//           23.15,
//           0.295618,
//           0.303494,
//           0.373329,
//           0.538647,
//           "0.362",
//           0.4125,
//           17.0,
//           3.0,
//           1.0,
//           6.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1912: [
//           "0819",
//           5.83,
//           2497.0,
//           7.869,
//           8.2216,
//           9.13287,
//           10.3132,
//           "8.567",
//           65.6,
//           17.0,
//           2.0,
//           0.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1913: [
//           "6918",
//           0.84,
//           258.0,
//           0.9152,
//           1.0649,
//           1.1788,
//           1.26965,
//           "1.135",
//           4.3754,
//           17.0,
//           4.0,
//           5.0,
//           8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1914: [
//           "8092",
//           0.028,
//           0.772,
//           0.03858,
//           0.04285,
//           0.0475667,
//           0.04953,
//           "0.045",
//           0.2591,
//           17.0,
//           -6.0,
//           -11.0,
//           1.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1915: [
//           "1636",
//           0.122,
//           0.521,
//           0.16458,
//           0.17608,
//           0.190747,
//           0.20177,
//           "0.186",
//           5.4674,
//           17.0,
//           4.0,
//           2.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1916: [
//           "0476",
//           0.063,
//           26.489,
//           0.08342,
//           0.09134,
//           0.0981333,
//           0.11032,
//           "0.095",
//           5.676,
//           17.0,
//           -2.0,
//           -8.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1917: [
//           "6060",
//           19.92,
//           22942.0,
//           27.6184,
//           27.6622,
//           30.1135,
//           33.1773,
//           "29.124",
//           282.8,
//           17.0,
//           -4.0,
//           -10.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1918: [
//           "6860",
//           0.158,
//           203.0,
//           0.24626,
//           0.23218,
//           0.219773,
//           0.22605,
//           "0.215",
//           3.0515,
//           17.0,
//           9.0,
//           11.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1919: [
//           "1896",
//           5.95,
//           1939.0,
//           9.0316,
//           9.1048,
//           9.36233,
//           9.9934,
//           "9.262",
//           67.9,
//           17.0,
//           0.0,
//           -5.0,
//           -11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1920: [
//           "0894",
//           0.68,
//           186.0,
//           1.1166,
//           1.1225,
//           1.2164,
//           1.24365,
//           "1.178",
//           3.2337,
//           17.0,
//           2.0,
//           -21.0,
//           -57.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1921: [
//           "1406",
//           0.86,
//           44.137,
//           "-",
//           "-",
//           "-",
//           "-",
//           "-",
//           4.3279,
//           16.0,
//           0.0,
//           0.0,
//           -30.0,
//           1.0,
//           "M",
//         ],
//       },
//       {
//         1922: [
//           "9699",
//           8.36,
//           95.386,
//           11.5916,
//           "-",
//           "-",
//           "-",
//           "-",
//           19.3,
//           16.0,
//           2.0,
//           0.0,
//           -2.0,
//           1.0,
//           "M",
//         ],
//       },
//       {
//         1923: [
//           "3032",
//           3.742,
//           8142.0,
//           5.29634,
//           5.71607,
//           5.92921,
//           6.34824,
//           "5.809",
//           0.0,
//           16.0,
//           0.0,
//           -2.0,
//           -6.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1924: [
//           "8385",
//           0.05,
//           13.81,
//           0.0506643,
//           0.0694522,
//           0.0924214,
//           0.122011,
//           "0.085",
//           1.0,
//           16.0,
//           -6.0,
//           9.0,
//           11.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1925: [
//           "6123",
//           2.03,
//           107.0,
//           2.738,
//           2.9466,
//           3.014,
//           3.3051,
//           "2.964",
//           8.5298,
//           16.0,
//           1.0,
//           0.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1926: [
//           "0727",
//           0.11,
//           1.94,
//           0.12714,
//           0.13589,
//           0.13982,
//           0.15557,
//           "0.14",
//           3.773,
//           16.0,
//           -10.0,
//           -6.0,
//           -7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1927: [
//           "3301",
//           2.06,
//           944.0,
//           2.9356,
//           2.985,
//           3.3328,
//           3.6457,
//           "3.206",
//           34.6,
//           16.0,
//           1.0,
//           -1.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1928: [
//           "2159",
//           1.0,
//           82.513,
//           1.2616,
//           1.3389,
//           1.41573,
//           1.59595,
//           "1.383",
//           2.0,
//           16.0,
//           3.0,
//           1.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1929: [
//           "2826",
//           51.76,
//           565.0,
//           70.4232,
//           76.6498,
//           80.0551,
//           86.421,
//           "78.461",
//           0.0,
//           16.0,
//           0.0,
//           0.0,
//           -5.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1930: [
//           "2313",
//           92.75,
//           98814.0,
//           137.341,
//           148.639,
//           155.465,
//           162.791,
//           "151.228",
//           1394.2,
//           16.0,
//           2.0,
//           0.0,
//           -13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1931: [
//           "9666",
//           22.0,
//           21731.0,
//           35.576,
//           35.433,
//           38.9407,
//           45.1745,
//           "37.815",
//           143.6,
//           16.0,
//           1.0,
//           -6.0,
//           -10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1932: [
//           "0179",
//           10.56,
//           705.0,
//           14.1748,
//           15.7518,
//           16.3653,
//           17.2554,
//           "15.936",
//           95.6,
//           16.0,
//           2.0,
//           1.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1933: [
//           "9826",
//           6.61,
//           245.0,
//           9.0268,
//           9.82725,
//           10.2702,
//           11.0985,
//           "10.065",
//           0.0,
//           16.0,
//           0.0,
//           0.0,
//           -3.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1934: [
//           "1889",
//           0.063,
//           11.701,
//           0.0935,
//           0.11611,
//           0.124033,
//           0.115525,
//           "0.125",
//           1.9323,
//           16.0,
//           4.0,
//           -3.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1935: [
//           "6880",
//           0.153,
//           0.365,
//           0.19598,
//           0.21448,
//           0.22662,
//           0.23774,
//           "-",
//           0.6679,
//           16.0,
//           4.0,
//           -6.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1936: [
//           "9992",
//           30.7,
//           60266.0,
//           41.058,
//           46.3385,
//           49.489,
//           53.9655,
//           "47.893",
//           430.3,
//           16.0,
//           -2.0,
//           -3.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1937: [
//           "3088",
//           4.738,
//           2491.0,
//           6.72136,
//           7.25268,
//           7.52029,
//           8.04937,
//           "7.37",
//           0.0,
//           16.0,
//           0.0,
//           -2.0,
//           -6.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1938: [
//           "3067",
//           7.87,
//           25337.0,
//           11.1447,
//           12.0196,
//           12.4646,
//           13.3429,
//           "12.218",
//           0.0,
//           16.0,
//           0.0,
//           -2.0,
//           -6.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1939: [
//           "2369",
//           0.205,
//           176.0,
//           0.31268,
//           0.35779,
//           0.34936,
//           0.339245,
//           "0.349",
//           28.3,
//           16.0,
//           2.0,
//           2.0,
//           -7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1940: [
//           "0986",
//           0.069,
//           12.464,
//           0.08544,
//           0.09487,
//           0.101373,
//           0.103396,
//           "0.1",
//           0.862,
//           16.0,
//           0.0,
//           -8.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1941: [
//           "1429",
//           0.079,
//           4.308,
//           0.10192,
//           0.1178,
//           0.12688,
//           0.13405,
//           "0.123",
//           1.264,
//           16.0,
//           3.0,
//           4.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1942: [
//           "3888",
//           21.55,
//           32960.0,
//           32.406,
//           33.2095,
//           32.964,
//           36.1823,
//           "32.302",
//           295.2,
//           16.0,
//           3.0,
//           2.0,
//           -8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1943: [
//           "1727",
//           1.12,
//           100.0,
//           1.4108,
//           1.5359,
//           1.74747,
//           1.9182,
//           "1.694",
//           5.1674,
//           16.0,
//           2.0,
//           3.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1944: [
//           "1143",
//           0.131,
//           19.677,
//           0.15676,
//           0.182057,
//           0.199283,
//           0.216567,
//           "0.193",
//           1.2109,
//           15.0,
//           3.0,
//           3.0,
//           8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1945: [
//           "8208",
//           0.068,
//           2.242,
//           0.0718,
//           0.08761,
//           0.103193,
//           0.15592,
//           "0.099",
//           0.4896,
//           15.0,
//           2.0,
//           5.0,
//           5.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1946: [
//           "3182",
//           9.375,
//           158.0,
//           13.4459,
//           14.8329,
//           15.419,
//           16.2401,
//           "15.105",
//           0.0,
//           15.0,
//           -1.0,
//           -3.0,
//           -6.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1947: [
//           "2197",
//           7.6,
//           5814.0,
//           10.4838,
//           "-",
//           "-",
//           "-",
//           "-",
//           88.0,
//           15.0,
//           5.0,
//           -3.0,
//           1.0,
//           1.0,
//           "M",
//         ],
//       },
//       {
//         1948: [
//           "8078",
//           0.029,
//           0.534,
//           0.03746,
//           0.04218,
//           0.0457533,
//           0.05436,
//           "0.045",
//           0.1132,
//           15.0,
//           5.0,
//           3.0,
//           8.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1949: [
//           "2696",
//           17.1,
//           66.572,
//           22.5492,
//           24.9636,
//           25.6844,
//           28.5026,
//           "24.808",
//           27.9,
//           15.0,
//           1.0,
//           -6.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1950: [
//           "0717",
//           0.057,
//           35.254,
//           0.07386,
//           0.08747,
//           0.0986133,
//           0.105885,
//           "0.095",
//           3.8422,
//           15.0,
//           3.0,
//           2.0,
//           6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1951: [
//           "1696",
//           5.42,
//           908.0,
//           8.3628,
//           10.0719,
//           11.4399,
//           13.7154,
//           "10.758",
//           25.2,
//           15.0,
//           -2.0,
//           -5.0,
//           -5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1952: [
//           "3813",
//           0.78,
//           920.0,
//           1.1738,
//           1.1901,
//           1.28313,
//           1.40285,
//           "1.235",
//           41.50000000000001,
//           15.0,
//           -5.0,
//           -9.0,
//           -16.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1953: [
//           "2100",
//           0.495,
//           316.0,
//           0.5763,
//           0.61115,
//           0.681767,
//           0.893275,
//           "0.645",
//           13.7,
//           15.0,
//           3.0,
//           0.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1954: [
//           "1612",
//           0.7,
//           22.256,
//           0.8918,
//           0.9338,
//           0.987733,
//           1.19635,
//           "0.958",
//           4.5787,
//           15.0,
//           3.0,
//           3.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1955: [
//           "1297",
//           0.59,
//           32.894,
//           0.778,
//           0.8541,
//           0.9286,
//           1.0565,
//           "0.896",
//           7.212,
//           15.0,
//           4.0,
//           0.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1956: [
//           "0268",
//           13.36,
//           35469.0,
//           20.592,
//           22.717,
//           23.7837,
//           24.5068,
//           "23.2",
//           463.3,
//           15.0,
//           0.0,
//           -11.0,
//           -7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1957: [
//           "6189",
//           0.43,
//           23.81,
//           0.6502,
//           0.6736,
//           0.707333,
//           0.72635,
//           "0.698",
//           0.2698,
//           15.0,
//           -5.0,
//           -2.0,
//           -10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1958: [
//           "3383",
//           3.4,
//           6843.0,
//           4.0152,
//           4.678,
//           5.7346,
//           6.8123,
//           "5.362",
//           133.1,
//           15.0,
//           5.0,
//           6.0,
//           10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1959: [
//           "6826",
//           33.65,
//           969.0,
//           46.043,
//           50.0005,
//           56.454,
//           65.3938,
//           "54.223",
//           12.7,
//           15.0,
//           1.0,
//           2.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1960: [
//           "0357",
//           16.0,
//           3044.0,
//           21.7924,
//           24.1942,
//           25.6875,
//           26.7953,
//           "25.523",
//           36.3,
//           15.0,
//           -1.0,
//           -10.0,
//           -14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1961: [
//           "9067",
//           1.004,
//           424.0,
//           1.42736,
//           1.54086,
//           1.59845,
//           1.71202,
//           "1.566",
//           0.0,
//           15.0,
//           -1.0,
//           -3.0,
//           -6.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1962: [
//           "2349",
//           0.094,
//           1.179,
//           0.14586,
//           0.17811,
//           0.194307,
//           0.1776,
//           "0.195",
//           2.9405,
//           15.0,
//           -2.0,
//           -3.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1963: [
//           "1951",
//           6.63,
//           26880.0,
//           8.3654,
//           9.4876,
//           10.5728,
//           12.3897,
//           "10.162",
//           165.79999999999995,
//           15.0,
//           -1.0,
//           -3.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1964: [
//           "9088",
//           0.606,
//           49.92,
//           0.86176,
//           0.93055,
//           0.96546,
//           1.03431,
//           "0.946",
//           0.0,
//           15.0,
//           -1.0,
//           -3.0,
//           -6.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1965: [
//           "2618",
//           18.38,
//           24632.0,
//           24.7168,
//           26.8789,
//           28.2276,
//           "-",
//           "27.878",
//           1136.4,
//           15.0,
//           3.0,
//           -7.0,
//           -11.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         1966: [
//           "3033",
//           3.71,
//           125000.0,
//           5.26984,
//           5.68777,
//           5.90251,
//           6.32198,
//           "5.782",
//           0.0,
//           15.0,
//           -1.0,
//           -2.0,
//           -7.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1967: [
//           "2001",
//           2.07,
//           1058.0,
//           2.8168,
//           3.1728,
//           3.40733,
//           3.8325,
//           "3.316",
//           32.4,
//           14.0,
//           0.0,
//           -2.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1968: [
//           "1578",
//           1.7,
//           113.0,
//           1.9912,
//           2.5514,
//           2.8414,
//           3.0187,
//           "2.754",
//           29.9,
//           14.0,
//           4.0,
//           4.0,
//           6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1969: [
//           "0907",
//           0.62,
//           554.0,
//           0.5451,
//           0.77085,
//           0.961967,
//           1.07302,
//           "0.899",
//           5.4117,
//           14.0,
//           2.0,
//           12.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1970: [
//           "0351",
//           0.235,
//           10.31,
//           0.3609,
//           0.3967,
//           0.4163,
//           0.431975,
//           "0.407",
//           3.9831,
//           14.0,
//           -1.0,
//           -4.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1971: [
//           "1652",
//           1.0,
//           30.7,
//           1.154,
//           1.5948,
//           1.94133,
//           2.22365,
//           "1.848",
//           7.6993,
//           14.0,
//           4.0,
//           8.0,
//           11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1972: [
//           "0846",
//           0.3,
//           0.626,
//           0.4287,
//           0.45045,
//           0.463233,
//           0.487075,
//           "0.457",
//           18.2,
//           14.0,
//           2.0,
//           -3.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1973: [
//           "8121",
//           0.165,
//           16.556,
//           0.15828,
//           0.21088,
//           0.246953,
//           0.34717,
//           "0.243",
//           0.275,
//           14.0,
//           1.0,
//           4.0,
//           8.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1974: [
//           "1472",
//           0.101,
//           14.47,
//           0.1239,
//           0.13692,
//           0.1455,
//           0.195165,
//           "0.142",
//           1.01,
//           14.0,
//           0.0,
//           6.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1975: [
//           "0200",
//           5.94,
//           2753.0,
//           8.8536,
//           9.3931,
//           9.78727,
//           10.7049,
//           "9.542",
//           90.0,
//           14.0,
//           2.0,
//           3.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1976: [
//           "1575",
//           0.078,
//           9.893,
//           0.11086,
//           0.14697,
//           0.154287,
//           0.157845,
//           "0.149",
//           0.78,
//           14.0,
//           4.0,
//           2.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1977: [
//           "9069",
//           1.402,
//           0.0,
//           1.888,
//           2.1475,
//           2.36123,
//           "-",
//           "2.279",
//           0.0,
//           14.0,
//           0.0,
//           -2.0,
//           -4.0,
//           2.0,
//           "E",
//         ],
//       },
//       {
//         1978: [
//           "1588",
//           5.89,
//           36.022,
//           7.7032,
//           8.6725,
//           9.45642,
//           10.3598,
//           "9.133",
//           4.8592,
//           14.0,
//           2.0,
//           7.0,
//           6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1979: [
//           "1110",
//           0.415,
//           55.256,
//           0.5981,
//           0.67595,
//           0.708033,
//           0.727925,
//           "0.691",
//           2.5833,
//           14.0,
//           1.0,
//           1.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1980: [
//           "1909",
//           0.84,
//           7506.0,
//           0.7406,
//           1.3937,
//           1.71607,
//           2.00165,
//           "1.626",
//           32.2,
//           14.0,
//           5.0,
//           13.0,
//           12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1981: [
//           "1912",
//           0.131,
//           8.661,
//           0.17472,
//           0.19467,
//           0.212613,
//           0.23036,
//           "0.203",
//           1.048,
//           14.0,
//           0.0,
//           -3.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1982: [
//           "1031",
//           0.255,
//           14.176,
//           0.3945,
//           0.4489,
//           0.452167,
//           0.47515,
//           "0.441",
//           34.7,
//           14.0,
//           -4.0,
//           -2.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1983: [
//           "8113",
//           0.2,
//           19.731,
//           0.28056,
//           0.32433,
//           0.349687,
//           0.37329,
//           "0.335",
//           1.3055,
//           14.0,
//           -2.0,
//           -1.0,
//           2.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         1984: [
//           "0667",
//           4.37,
//           2872.0,
//           4.4842,
//           5.6567,
//           6.5618,
//           8.03675,
//           "6.33",
//           95.0,
//           14.0,
//           6.0,
//           7.0,
//           9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1985: [
//           "0419",
//           0.149,
//           162.0,
//           0.18594,
//           0.19117,
//           0.197973,
//           0.23039,
//           "0.193",
//           20.1,
//           14.0,
//           0.0,
//           0.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1986: [
//           "0305",
//           0.93,
//           1035.0,
//           1.3636,
//           1.5139,
//           1.47167,
//           1.56105,
//           "1.446",
//           30.6,
//           14.0,
//           0.0,
//           0.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1987: [
//           "0362",
//           0.087,
//           32.649,
//           0.10628,
//           0.11958,
//           0.158767,
//           0.18923,
//           "0.147",
//           2.3146,
//           14.0,
//           2.0,
//           2.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1988: [
//           "6993",
//           4.58,
//           2766.0,
//           6.506,
//           6.8713,
//           6.84027,
//           7.57175,
//           "6.791",
//           268.4,
//           14.0,
//           1.0,
//           -1.0,
//           -5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1989: [
//           "0550",
//           0.28,
//           0.565,
//           0.3417,
//           0.37915,
//           0.438733,
//           0.54945,
//           "0.417",
//           1.2505,
//           14.0,
//           3.0,
//           6.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1990: [
//           "3818",
//           0.445,
//           1117.0,
//           0.6386,
//           0.6928,
//           0.769733,
//           0.86315,
//           "0.735",
//           26.1,
//           14.0,
//           1.0,
//           0.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1991: [
//           "0559",
//           0.02,
//           36.72,
//           0.027,
//           0.03165,
//           0.0373867,
//           0.04068,
//           "0.036",
//           3.1391,
//           13.0,
//           -2.0,
//           -4.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1992: [
//           "6098",
//           29.25,
//           219000.0,
//           45.738,
//           49.9135,
//           52.7907,
//           58.4728,
//           "51.828",
//           984.5,
//           13.0,
//           -4.0,
//           -16.0,
//           -19.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1993: [
//           "9812",
//           1.13,
//           0.184,
//           1.64792,
//           1.7988,
//           1.86733,
//           2.01592,
//           "1.834",
//           0.0,
//           13.0,
//           -1.0,
//           -2.0,
//           -4.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         1994: [
//           "1238",
//           2.82,
//           5669.0,
//           4.0842,
//           4.4365,
//           4.89867,
//           5.34685,
//           "4.732",
//           116.7,
//           13.0,
//           2.0,
//           -3.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1995: [
//           "1762",
//           0.34,
//           54.018,
//           0.4612,
//           0.5053,
//           0.548533,
//           0.6082,
//           "0.54",
//           5.0512,
//           13.0,
//           1.0,
//           2.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1996: [
//           "2007",
//           4.21,
//           107000.0,
//           6.4524,
//           6.851,
//           7.244,
//           7.63345,
//           "7.07",
//           974.5,
//           13.0,
//           -4.0,
//           -21.0,
//           -9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1997: [
//           "9923",
//           18.44,
//           5298.0,
//           23.2956,
//           24.6483,
//           26.6862,
//           31.5084,
//           "25.791",
//           83.3,
//           13.0,
//           2.0,
//           4.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         1998: [
//           "9982",
//           0.95,
//           448.0,
//           1.2864,
//           1.3144,
//           1.4072,
//           "-",
//           "1.354",
//           31.2,
//           13.0,
//           2.0,
//           7.0,
//           -1.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         1999: [
//           "1999",
//           7.38,
//           16986.0,
//           10.9004,
//           11.5974,
//           12.3064,
//           13.663,
//           "11.794",
//           290.2,
//           13.0,
//           0.0,
//           0.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2000: [
//           "1585",
//           8.14,
//           5513.0,
//           11.7774,
//           12.9095,
//           13.0779,
//           13.8871,
//           "12.772",
//           243.7,
//           13.0,
//           0.0,
//           -4.0,
//           -14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2001: [
//           "2772",
//           2.25,
//           1415.0,
//           3.4372,
//           3.5387,
//           3.80107,
//           4.03235,
//           "3.682",
//           80.50000000000001,
//           13.0,
//           2.0,
//           0.0,
//           -7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2002: [
//           "0493",
//           0.46,
//           6891.0,
//           0.618,
//           0.6714,
//           0.714067,
//           0.79585,
//           "0.692",
//           155.2,
//           13.0,
//           2.0,
//           2.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2003: [
//           "1760",
//           2.83,
//           696.0,
//           4.3988,
//           4.7447,
//           4.78887,
//           4.7085,
//           "4.653",
//           30.7,
//           13.0,
//           1.0,
//           -5.0,
//           -12.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2004: [
//           "2882",
//           0.21,
//           0.419,
//           0.26096,
//           0.28343,
//           0.31682,
//           0.358415,
//           "0.3",
//           0.5663,
//           13.0,
//           0.0,
//           0.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2005: [
//           "1916",
//           1.66,
//           6.674,
//           2.303,
//           2.7089,
//           2.93727,
//           3.0188,
//           "2.847",
//           22.3,
//           13.0,
//           1.0,
//           3.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2006: [
//           "8483",
//           0.057,
//           1.184,
//           0.11538,
//           0.12184,
//           0.118427,
//           0.11077,
//           "0.119",
//           0.456,
//           13.0,
//           -10.0,
//           -15.0,
//           -71.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         2007: [
//           "9988",
//           80.9,
//           826000.0,
//           114.593,
//           127.731,
//           139.324,
//           155.77,
//           "133.287",
//           17545.0,
//           13.0,
//           0.0,
//           0.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2008: [
//           "6899",
//           0.26,
//           16.266,
//           0.3484,
//           0.4093,
//           0.431367,
//           0.488875,
//           "0.41",
//           2.8022,
//           13.0,
//           1.0,
//           -1.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2009: [
//           "3347",
//           73.6,
//           19669.0,
//           85.543,
//           106.695,
//           122.026,
//           132.872,
//           "118.3",
//           90.6,
//           13.0,
//           2.0,
//           2.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2010: [
//           "2812",
//           8.835,
//           2510.0,
//           12.8575,
//           14.022,
//           14.5488,
//           15.6892,
//           "14.285",
//           0.0,
//           13.0,
//           -1.0,
//           -3.0,
//           -5.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         2011: [
//           "6608",
//           7.39,
//           2002.0,
//           9.1134,
//           10.0873,
//           11.0283,
//           12.9727,
//           "10.927",
//           36.8,
//           13.0,
//           1.0,
//           5.0,
//           1.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         2012: [
//           "1316",
//           5.31,
//           6227.0,
//           8.6138,
//           9.2145,
//           9.0812,
//           9.4133,
//           "8.863",
//           133.2,
//           13.0,
//           2.0,
//           0.0,
//           -16.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2013: [
//           "8401",
//           0.445,
//           21.446,
//           0.4582,
//           0.5417,
//           0.691933,
//           0.89415,
//           "0.617",
//           0.89,
//           13.0,
//           3.0,
//           6.0,
//           9.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         2014: [
//           "2146",
//           3.67,
//           32.669,
//           4.8966,
//           5.8114,
//           6.77487,
//           7.4787,
//           "6.518",
//           3.4498,
//           12.0,
//           3.0,
//           2.0,
//           -5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2015: [
//           "1797",
//           3.81,
//           4592.0,
//           4.7464,
//           5.5269,
//           5.22213,
//           6.1581,
//           "5.213",
//           38.1,
//           12.0,
//           4.0,
//           4.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2016: [
//           "8368",
//           0.22,
//           3.3,
//           0.30304,
//           0.37262,
//           0.442347,
//           0.440435,
//           "0.423",
//           0.7413,
//           12.0,
//           2.0,
//           2.0,
//           3.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         2017: [
//           "9868",
//           82.0,
//           83972.0,
//           151.642,
//           168.103,
//           162.69,
//           "-",
//           "159.75",
//           1404.5,
//           12.0,
//           -7.0,
//           -8.0,
//           -13.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         2018: [
//           "1112",
//           9.11,
//           1245.0,
//           12.3638,
//           13.8073,
//           16.3485,
//           19.7871,
//           "15.269",
//           58.7,
//           12.0,
//           3.0,
//           2.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2019: [
//           "8437",
//           0.089,
//           39.34,
//           0.116102,
//           0.132021,
//           0.159127,
//           0.185956,
//           "0.148",
//           1.1534,
//           12.0,
//           3.0,
//           6.0,
//           8.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         2020: [
//           "2323",
//           0.076,
//           0.0,
//           0.0988,
//           0.11507,
//           0.11954,
//           0.13649,
//           "0.115",
//           1.678,
//           12.0,
//           4.0,
//           8.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2021: [
//           "0876",
//           0.062,
//           3.388,
//           0.08106,
//           0.0935,
//           0.144853,
//           0.15691,
//           "0.128",
//           3.1261,
//           12.0,
//           3.0,
//           4.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2022: [
//           "1024",
//           60.2,
//           347000.0,
//           83.068,
//           87.022,
//           86.3367,
//           107.203,
//           "86.035",
//           2564.4,
//           12.0,
//           -1.0,
//           -5.0,
//           -17.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2023: [
//           "0438",
//           13.18,
//           62.32,
//           18.1938,
//           19.9129,
//           22.9503,
//           23.8452,
//           "21.826",
//           12.6,
//           12.0,
//           -1.0,
//           -1.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2024: [
//           "2777",
//           2.81,
//           4946.0,
//           3.4182,
//           3.8329,
//           4.58287,
//           5.6332,
//           "4.295",
//           105.4,
//           12.0,
//           2.0,
//           3.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2025: [
//           "3662",
//           1.83,
//           427.0,
//           2.3734,
//           2.7457,
//           3.24767,
//           3.82915,
//           "3.085",
//           13.2,
//           12.0,
//           3.0,
//           4.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2026: [
//           "3174",
//           2.952,
//           61.923,
//           4.103,
//           4.78833,
//           5.31652,
//           "-",
//           "5.121",
//           0.0,
//           12.0,
//           0.0,
//           -1.0,
//           -4.0,
//           2.0,
//           "E",
//         ],
//       },
//       {
//         2027: [
//           "0136",
//           1.95,
//           10654.0,
//           2.3498,
//           2.5157,
//           2.74667,
//           3.49855,
//           "2.56",
//           180.0,
//           12.0,
//           3.0,
//           2.0,
//           7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2028: [
//           "6185",
//           113.6,
//           15575.0,
//           143.086,
//           158.741,
//           200.379,
//           236.057,
//           "186.077",
//           150.7,
//           12.0,
//           1.0,
//           0.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2029: [
//           "1132",
//           0.059,
//           11.659,
//           0.09052,
//           0.10117,
//           0.10684,
//           0.11382,
//           "0.104",
//           1.6518,
//           12.0,
//           -1.0,
//           1.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2030: [
//           "0354",
//           5.61,
//           12927.0,
//           7.9388,
//           10.1743,
//           11.2985,
//           11.7552,
//           "10.81",
//           173.5,
//           12.0,
//           0.0,
//           1.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2031: [
//           "6088",
//           0.92,
//           991.0,
//           1.407,
//           1.5137,
//           1.55227,
//           1.6542,
//           "1.52",
//           65.6,
//           12.0,
//           1.0,
//           1.0,
//           -10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2032: [
//           "1945",
//           2.12,
//           48.174,
//           3.313,
//           3.607,
//           3.62707,
//           3.77235,
//           "3.584",
//           6.6433,
//           12.0,
//           0.0,
//           0.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2033: [
//           "3069",
//           10.98,
//           152.0,
//           14.7266,
//           16.721,
//           18.3717,
//           20.4508,
//           "17.737",
//           0.0,
//           12.0,
//           1.0,
//           0.0,
//           -2.0,
//           2.0,
//           "E",
//         ],
//       },
//       {
//         2034: [
//           "1478",
//           6.09,
//           3552.0,
//           8.4686,
//           9.7969,
//           10.6359,
//           11.507,
//           "10.301",
//           71.9,
//           12.0,
//           3.0,
//           4.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2035: [
//           "1859",
//           0.445,
//           276.0,
//           0.5893,
//           0.64555,
//           0.736833,
//           0.802625,
//           "0.701",
//           7.12,
//           12.0,
//           1.0,
//           0.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2036: [
//           "8178",
//           0.146,
//           71.768,
//           0.234521,
//           0.250711,
//           0.266207,
//           0.28678,
//           "-",
//           0.7514,
//           12.0,
//           4.0,
//           -9.0,
//           -14.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         2037: [
//           "1966",
//           1.25,
//           1528.0,
//           1.7268,
//           1.964,
//           2.26487,
//           2.50505,
//           "2.164",
//           52.7,
//           11.0,
//           2.0,
//           1.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2038: [
//           "2168",
//           8.3,
//           970.0,
//           11.13,
//           12.673,
//           15.9261,
//           19.1131,
//           "15.005",
//           12.7,
//           11.0,
//           3.0,
//           2.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2039: [
//           "1571",
//           1.7,
//           279.0,
//           2.5878,
//           2.7883,
//           2.91493,
//           3.08115,
//           "2.804",
//           17.0,
//           11.0,
//           2.0,
//           -8.0,
//           -9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2040: [
//           "8079",
//           0.09,
//           0.541,
//           0.1469,
//           0.15212,
//           0.16628,
//           0.17478,
//           "0.161",
//           0.2163,
//           11.0,
//           0.0,
//           -1.0,
//           -22.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         2041: [
//           "0620",
//           2.01,
//           20.964,
//           2.7026,
//           3.2014,
//           3.33987,
//           3.8858,
//           "3.284",
//           13.4,
//           11.0,
//           1.0,
//           1.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2042: [
//           "8455",
//           0.04,
//           1.104,
//           0.04776,
//           0.05727,
//           0.0672,
//           0.076375,
//           "0.064",
//           0.32,
//           11.0,
//           0.0,
//           -1.0,
//           5.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         2043: [
//           "2680",
//           0.3,
//           40.165,
//           0.4355,
//           0.51035,
//           0.585433,
//           0.637775,
//           "0.562",
//           1.2,
//           11.0,
//           0.0,
//           4.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2044: [
//           "1728",
//           0.44,
//           101.0,
//           0.6624,
//           0.8067,
//           0.881933,
//           0.8876,
//           "0.846",
//           12.0,
//           11.0,
//           1.0,
//           -1.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2045: [
//           "1736",
//           0.055,
//           1.981,
//           0.069,
//           0.0769,
//           0.0856267,
//           0.103115,
//           "0.084",
//           0.5641,
//           11.0,
//           1.0,
//           1.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2046: [
//           "2363",
//           0.123,
//           0.52,
//           0.14585,
//           0.166995,
//           0.18497,
//           0.211823,
//           "0.179",
//           0.8373,
//           11.0,
//           5.0,
//           2.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2047: [
//           "8513",
//           0.18,
//           1.368,
//           0.2563,
//           0.2941,
//           0.325833,
//           0.3477,
//           "0.314",
//           0.8574,
//           11.0,
//           3.0,
//           4.0,
//           4.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         2048: [
//           "2121",
//           9.5,
//           283.0,
//           "-",
//           "-",
//           "-",
//           "-",
//           "-",
//           53.1,
//           11.0,
//           1.0,
//           0.0,
//           -19.0,
//           1.0,
//           "M",
//         ],
//       },
//       {
//         2049: [
//           "2518",
//           41.9,
//           695.0,
//           59.105,
//           64.9325,
//           71.61,
//           84.5717,
//           "70.127",
//           213.4,
//           11.0,
//           1.0,
//           0.0,
//           -1.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         2050: [
//           "0606",
//           1.91,
//           4177.0,
//           2.7796,
//           3.2606,
//           3.53767,
//           "-",
//           "3.426",
//           39.6,
//           11.0,
//           3.0,
//           3.0,
//           2.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         2051: [
//           "3759",
//           74.95,
//           10783.0,
//           100.06,
//           125.204,
//           140.93,
//           151.997,
//           "135.802",
//           100.4,
//           11.0,
//           1.0,
//           -1.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2052: [
//           "0384",
//           9.87,
//           46464.0,
//           13.7486,
//           15.3671,
//           17.7444,
//           19.6931,
//           "16.93",
//           546.3,
//           11.0,
//           -2.0,
//           -2.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2053: [
//           "1351",
//           0.52,
//           14.566,
//           0.6954,
//           0.7603,
//           0.8196,
//           0.9515,
//           "0.8",
//           2.6,
//           11.0,
//           2.0,
//           1.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2054: [
//           "1473",
//           0.209,
//           8.165,
//           0.2732,
//           0.31525,
//           0.372,
//           0.4217,
//           "0.356",
//           2.09,
//           11.0,
//           0.0,
//           0.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2055: [
//           "0873",
//           4.13,
//           12972.0,
//           5.8258,
//           8.0467,
//           11.0563,
//           13.9529,
//           "10.162",
//           101.9,
//           11.0,
//           6.0,
//           7.0,
//           7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2056: [
//           "8150",
//           0.023,
//           9.277,
//           0.0271,
//           0.03168,
//           0.0385267,
//           0.04728,
//           "0.036",
//           0.434,
//           11.0,
//           4.0,
//           5.0,
//           4.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         2057: [
//           "2298",
//           0.39,
//           14.102,
//           0.4785,
//           0.5486,
//           0.619733,
//           0.73235,
//           "0.584",
//           8.7728,
//           11.0,
//           3.0,
//           3.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2058: [
//           "8166",
//           0.186,
//           0.378,
//           0.25008,
//           0.31499,
//           0.361527,
//           0.370345,
//           "0.353",
//           0.197,
//           11.0,
//           4.0,
//           5.0,
//           7.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         2059: [
//           "0880",
//           3.21,
//           20338.0,
//           4.854,
//           5.2388,
//           5.52913,
//           6.1791,
//           "5.362",
//           182.3,
//           11.0,
//           1.0,
//           2.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2060: [
//           "2135",
//           0.171,
//           63.642,
//           0.24596,
//           0.27808,
//           0.315887,
//           0.390615,
//           "0.294",
//           3.5722,
//           10.0,
//           2.0,
//           0.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2061: [
//           "1255",
//           1.0,
//           12.05,
//           1.3998,
//           1.4194,
//           2.35187,
//           3.50855,
//           "1.896",
//           2.4284,
//           10.0,
//           2.0,
//           3.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2062: [
//           "8009",
//           0.325,
//           12.04,
//           0.5219,
//           0.63575,
//           0.694433,
//           0.739575,
//           "0.671",
//           0.1914,
//           10.0,
//           -1.0,
//           1.0,
//           -3.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         2063: [
//           "3186",
//           34.2,
//           170.0,
//           51.0604,
//           56.8526,
//           60.0752,
//           66.5304,
//           "58.779",
//           0.0,
//           10.0,
//           -1.0,
//           -2.0,
//           -4.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         2064: [
//           "2131",
//           1.85,
//           498.0,
//           2.453,
//           2.808,
//           3.19733,
//           3.8581,
//           "3.069",
//           14.7,
//           10.0,
//           2.0,
//           2.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2065: [
//           "6160",
//           91.95,
//           17357.0,
//           132.445,
//           167.962,
//           179.294,
//           184.529,
//           "177.135",
//           1226.1,
//           10.0,
//           -3.0,
//           0.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2066: [
//           "0863",
//           6.2,
//           112.0,
//           8.7082,
//           10.1905,
//           11.4221,
//           13.1193,
//           "11.023",
//           26.2,
//           10.0,
//           0.0,
//           -1.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2067: [
//           "8133",
//           0.153,
//           1.074,
//           0.20086,
//           0.250448,
//           0.349632,
//           0.380024,
//           "0.314",
//           0.2386,
//           10.0,
//           3.0,
//           2.0,
//           5.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         2068: [
//           "9186",
//           4.368,
//           0.0,
//           6.55106,
//           7.29998,
//           7.71935,
//           8.55384,
//           "7.553",
//           0.0,
//           10.0,
//           0.0,
//           -2.0,
//           -4.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         2069: [
//           "9928",
//           1.91,
//           574.0,
//           2.8262,
//           3.156,
//           3.59153,
//           4.20235,
//           "3.397",
//           18.8,
//           10.0,
//           2.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2070: [
//           "1499",
//           0.163,
//           13.839,
//           0.22602,
//           0.24745,
//           0.273433,
//           0.286125,
//           "0.265",
//           8.7539,
//           10.0,
//           -3.0,
//           1.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2071: [
//           "7230",
//           2.738,
//           420.0,
//           4.40292,
//           4.71786,
//           4.95651,
//           5.48653,
//           "4.822",
//           0.0,
//           10.0,
//           0.0,
//           -2.0,
//           -8.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         2072: [
//           "9600",
//           0.98,
//           138.0,
//           1.6994,
//           1.8955,
//           1.9304,
//           1.96815,
//           "1.848",
//           7.7078,
//           10.0,
//           -1.0,
//           -9.0,
//           -9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2073: [
//           "3333",
//           1.37,
//           4776.0,
//           1.6368,
//           1.9312,
//           2.5654,
//           4.3303,
//           "2.252",
//           180.8,
//           10.0,
//           4.0,
//           5.0,
//           7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2074: [
//           "9977",
//           0.94,
//           94.182,
//           1.5974,
//           1.645,
//           1.71827,
//           1.81995,
//           "1.681",
//           3.337,
//           10.0,
//           -2.0,
//           -4.0,
//           -14.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2075: [
//           "9996",
//           9.37,
//           507.0,
//           11.861,
//           14.3784,
//           17.3508,
//           20.9718,
//           "16.132",
//           63.3,
//           10.0,
//           0.0,
//           0.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2076: [
//           "7288",
//           2.736,
//           5168.0,
//           4.4004,
//           4.70811,
//           4.93963,
//           5.46343,
//           "4.807",
//           0.0,
//           10.0,
//           -1.0,
//           -3.0,
//           -8.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         2077: [
//           "9956",
//           4.12,
//           415.00000000000006,
//           6.834,
//           "-",
//           "-",
//           "-",
//           "-",
//           47.8,
//           10.0,
//           1.0,
//           -1.0,
//           1.0,
//           1.0,
//           "M",
//         ],
//       },
//       {
//         2078: [
//           "1752",
//           0.1,
//           9.464,
//           0.15438,
//           0.17631,
//           0.176587,
//           0.17966,
//           "0.174",
//           2.4333,
//           10.0,
//           2.0,
//           -1.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2079: [
//           "3913",
//           2.33,
//           1940.0,
//           3.2882,
//           3.9958,
//           4.697,
//           5.6413,
//           "4.436",
//           46.9,
//           10.0,
//           1.0,
//           2.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2080: [
//           "2162",
//           22.6,
//           956.0,
//           28.146,
//           34.5265,
//           40.9637,
//           "-",
//           "38.458",
//           63.2,
//           10.0,
//           -1.0,
//           0.0,
//           1.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         2081: [
//           "0235",
//           0.031,
//           96.041,
//           0.0426,
//           0.04591,
//           0.0504,
//           0.064295,
//           "0.048",
//           6.3194,
//           10.0,
//           1.0,
//           1.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2082: [
//           "6078",
//           28.9,
//           35502.0,
//           41.067,
//           51.458,
//           55.6377,
//           64.0417,
//           "53.105",
//           178.6,
//           10.0,
//           -5.0,
//           -10.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2083: [
//           "2858",
//           0.84,
//           572.0,
//           1.2498,
//           1.3278,
//           1.43067,
//           1.62805,
//           "1.377",
//           54.7,
//           10.0,
//           1.0,
//           -3.0,
//           -5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2084: [
//           "2616",
//           4.69,
//           1679.0,
//           6.6458,
//           8.0379,
//           9.43567,
//           10.9377,
//           "8.91",
//           55.7,
//           9.0,
//           0.0,
//           -2.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2085: [
//           "0799",
//           3.82,
//           1865.0,
//           4.9714,
//           6.1212,
//           6.623,
//           7.51675,
//           "6.44",
//           45.6,
//           9.0,
//           1.0,
//           3.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2086: [
//           "8111",
//           0.145,
//           0.793,
//           0.1862,
//           0.23225,
//           0.257633,
//           0.29085,
//           "-",
//           0.6498,
//           9.0,
//           3.0,
//           1.0,
//           3.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         2087: [
//           "2051",
//           0.38,
//           18.059,
//           0.5047,
//           0.56895,
//           0.668367,
//           0.820625,
//           "0.633",
//           5.1616,
//           9.0,
//           0.0,
//           -2.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2088: [
//           "1439",
//           0.024,
//           3.369,
//           0.03374,
//           0.03975,
//           0.0440133,
//           0.05007,
//           "0.042",
//           0.3305,
//           9.0,
//           -5.0,
//           5.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2089: [
//           "0812",
//           0.031,
//           7.86,
//           0.0442,
//           0.05097,
//           0.05438,
//           0.05896,
//           "0.053",
//           1.1351,
//           9.0,
//           2.0,
//           1.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2090: [
//           "9969",
//           9.32,
//           4409.0,
//           12.6448,
//           15.6781,
//           17.5125,
//           19.7293,
//           "16.646",
//           139.7,
//           9.0,
//           0.0,
//           -2.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2091: [
//           "0286",
//           0.38,
//           1824.0,
//           0.6247,
//           0.77605,
//           0.852033,
//           0.922925,
//           "0.8",
//           16.2,
//           9.0,
//           -3.0,
//           -3.0,
//           -10.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2092: [
//           "3001",
//           148.6,
//           269.0,
//           217.82,
//           249.505,
//           280.951,
//           305.853,
//           "269.002",
//           0.0,
//           9.0,
//           2.0,
//           0.0,
//           -1.0,
//           2.0,
//           "E",
//         ],
//       },
//       {
//         2093: [
//           "1165",
//           0.052,
//           8.998,
//           0.05956,
//           0.07569,
//           0.0956067,
//           0.11081,
//           "0.091",
//           2.5908,
//           9.0,
//           2.0,
//           5.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2094: [
//           "0772",
//           31.15,
//           31465.0,
//           45.866,
//           50.458,
//           55.162,
//           62.0018,
//           "52.997",
//           318.3,
//           9.0,
//           1.0,
//           -3.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2095: [
//           "2371",
//           0.042,
//           103.0,
//           0.066,
//           0.08365,
//           0.0916133,
//           0.0995,
//           "0.088",
//           2.8359,
//           9.0,
//           2.0,
//           2.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2096: [
//           "0312",
//           0.102,
//           7.262,
//           0.13774,
//           0.14721,
//           0.172587,
//           0.20814,
//           "0.162",
//           2.5449,
//           9.0,
//           -1.0,
//           -1.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2097: [
//           "3319",
//           10.18,
//           6639.0,
//           14.264,
//           17.3332,
//           21.3408,
//           24.7369,
//           "20.175",
//           144.5,
//           9.0,
//           2.0,
//           0.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2098: [
//           "6606",
//           16.7,
//           11519.0,
//           24.3378,
//           26.1519,
//           31.7979,
//           42.7692,
//           "28.925",
//           71.7,
//           9.0,
//           -5.0,
//           -7.0,
//           -9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2099: [
//           "1458",
//           3.48,
//           19777.0,
//           5.3956,
//           6.1204,
//           6.515,
//           7.1861,
//           "6.451",
//           82.89999999999998,
//           9.0,
//           -2.0,
//           -6.0,
//           -5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2100: [
//           "9001",
//           19.15,
//           1.542,
//           27.9186,
//           31.9988,
//           36.0402,
//           39.2814,
//           "34.478",
//           0.0,
//           9.0,
//           2.0,
//           0.0,
//           -1.0,
//           2.0,
//           "E",
//         ],
//       },
//       {
//         2101: [
//           "1950",
//           0.08,
//           13.924,
//           0.1089,
//           0.13183,
//           0.164147,
//           0.201315,
//           "0.153",
//           0.96,
//           9.0,
//           3.0,
//           0.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2102: [
//           "0285",
//           16.72,
//           34857.0,
//           23.822,
//           26.065,
//           28.2623,
//           32.9793,
//           "26.881",
//           376.7,
//           9.0,
//           1.0,
//           0.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2103: [
//           "1030",
//           2.83,
//           13352.0,
//           5.011,
//           5.393,
//           5.79827,
//           6.1816,
//           "5.608",
//           184.1,
//           9.0,
//           0.0,
//           -5.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2104: [
//           "1357",
//           0.87,
//           1880.0,
//           1.4442,
//           1.567,
//           1.6124,
//           1.64865,
//           "1.582",
//           38.3,
//           9.0,
//           -1.0,
//           -1.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2105: [
//           "0926",
//           0.113,
//           85.647,
//           0.16204,
//           0.18657,
//           0.23328,
//           0.263685,
//           "0.215",
//           1.8421,
//           9.0,
//           2.0,
//           4.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2106: [
//           "6622",
//           3.53,
//           121.0,
//           4.923,
//           5.6266,
//           6.30347,
//           7.5465,
//           "6.078",
//           19.1,
//           9.0,
//           1.0,
//           0.0,
//           1.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         2107: [
//           "2235",
//           12.02,
//           25.775,
//           17.4112,
//           22.7471,
//           "-",
//           "-",
//           "-",
//           7.9022,
//           8.0,
//           1.0,
//           1.0,
//           1.0,
//           1.0,
//           "M",
//         ],
//       },
//       {
//         2108: [
//           "1753",
//           0.8,
//           132.0,
//           1.233,
//           1.4279,
//           1.59973,
//           1.7037,
//           "1.541",
//           8.6145,
//           8.0,
//           0.0,
//           -1.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2109: [
//           "8471",
//           0.197,
//           73.569,
//           0.23972,
//           0.39781,
//           0.456807,
//           0.50048,
//           "0.45",
//           1.6745,
//           8.0,
//           4.0,
//           3.0,
//           4.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         2110: [
//           "1890",
//           1.84,
//           611.0,
//           2.613,
//           3.303,
//           3.66113,
//           4.0761,
//           "3.531",
//           37.0,
//           8.0,
//           1.0,
//           2.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2111: [
//           "2251",
//           19.12,
//           111.0,
//           32.2164,
//           "-",
//           "-",
//           "-",
//           "-",
//           4.9586,
//           8.0,
//           1.0,
//           1.0,
//           3.0,
//           1.0,
//           "M",
//         ],
//       },
//       {
//         2112: [
//           "8536",
//           0.228,
//           0.704,
//           0.32304,
//           0.41067,
//           3.03818,
//           2.49826,
//           "3.019",
//           0.4041,
//           8.0,
//           1.0,
//           0.0,
//           1.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         2113: [
//           "6618",
//           36.25,
//           84419.0,
//           60.096,
//           64.799,
//           68.0493,
//           76.256,
//           "66.507",
//           1158.0,
//           8.0,
//           -1.0,
//           -6.0,
//           -9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2114: [
//           "0042",
//           0.31,
//           51.537,
//           0.574,
//           0.6201,
//           0.627667,
//           0.63,
//           "0.622",
//           0.7996,
//           8.0,
//           1.0,
//           -12.0,
//           -20.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2115: [
//           "1911",
//           8.3,
//           2448.0,
//           13.4712,
//           15.1733,
//           16.6721,
//           17.8321,
//           "16.242",
//           45.6,
//           8.0,
//           1.0,
//           -1.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2116: [
//           "8430",
//           0.055,
//           0.982,
//           0.0981,
//           0.1091,
//           0.12022,
//           0.124925,
//           "0.114",
//           0.4509,
//           8.0,
//           2.0,
//           -4.0,
//           -11.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         2117: [
//           "3798",
//           1.06,
//           43.858,
//           1.5298,
//           1.5765,
//           1.8366,
//           2.48695,
//           "1.738",
//           13.6,
//           8.0,
//           3.0,
//           2.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2118: [
//           "0175",
//           10.56,
//           91352.0,
//           16.9544,
//           20.8087,
//           22.3351,
//           22.6695,
//           "21.461",
//           1058.2,
//           8.0,
//           1.0,
//           0.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2119: [
//           "1501",
//           12.1,
//           72.106,
//           17.007,
//           21.145,
//           22.689,
//           26.4708,
//           "21.59",
//           12.6,
//           8.0,
//           1.0,
//           0.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2120: [
//           "2018",
//           17.22,
//           24769.0,
//           25.6514,
//           29.3692,
//           33.2248,
//           37.9859,
//           "31.081",
//           208.1,
//           8.0,
//           0.0,
//           1.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2121: [
//           "9960",
//           2.5,
//           385.0,
//           4.2216,
//           4.5592,
//           5.31627,
//           "-",
//           "4.965",
//           24.8,
//           8.0,
//           2.0,
//           2.0,
//           1.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         2122: [
//           "1022",
//           0.235,
//           29.361,
//           0.3308,
//           0.39115,
//           0.444667,
//           0.55405,
//           "0.427",
//           4.0392,
//           8.0,
//           1.0,
//           1.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2123: [
//           "0520",
//           3.33,
//           6220.0,
//           5.0836,
//           5.4245,
//           5.9514,
//           6.51645,
//           "5.827",
//           36.1,
//           8.0,
//           0.0,
//           -4.0,
//           -7.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2124: [
//           "0554",
//           0.176,
//           3.216,
//           0.23484,
//           0.33182,
//           0.34368,
//           0.35261,
//           "0.332",
//           6.9636,
//           8.0,
//           2.0,
//           3.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2125: [
//           "0106",
//           0.205,
//           22.531,
//           0.28148,
//           0.35609,
//           0.392327,
//           0.432145,
//           "0.384",
//           9.6807,
//           8.0,
//           1.0,
//           1.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2126: [
//           "9869",
//           9.84,
//           11334.0,
//           18.1176,
//           17.9695,
//           "-",
//           "-",
//           "-",
//           124.6,
//           8.0,
//           -5.0,
//           -72.0,
//           -73.0,
//           1.0,
//           "M",
//         ],
//       },
//       {
//         2127: [
//           "8218",
//           0.13,
//           0.854,
//           0.2278,
//           0.2497,
//           0.266533,
//           0.296625,
//           "0.259",
//           0.2051,
//           8.0,
//           0.0,
//           1.0,
//           -7.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         2128: [
//           "1789",
//           3.72,
//           3011.0,
//           5.7892,
//           6.4638,
//           7.46847,
//           8.7637,
//           "7.165",
//           41.50000000000001,
//           8.0,
//           0.0,
//           -5.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2129: [
//           "9995",
//           46.5,
//           9739.0,
//           59.009,
//           76.934,
//           85.5233,
//           91.7995,
//           "81.932",
//           88.1,
//           8.0,
//           -1.0,
//           -2.0,
//           -5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2130: [
//           "9986",
//           0.24,
//           1.503,
//           0.3565,
//           0.4107,
//           0.449533,
//           0.52325,
//           "0.426",
//           1.92,
//           7.0,
//           -3.0,
//           -2.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2131: [
//           "1579",
//           21.6,
//           16479.0,
//           33.315,
//           38.956,
//           40.6557,
//           43.8807,
//           "39.598",
//           226.1,
//           7.0,
//           1.0,
//           -1.0,
//           -5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2132: [
//           "2269",
//           44.9,
//           289000.0,
//           72.376,
//           87.6405,
//           97.947,
//           105.599,
//           "94.157",
//           1892.7,
//           7.0,
//           -2.0,
//           -3.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2133: [
//           "1777",
//           0.226,
//           84.418,
//           0.30014,
//           0.34652,
//           0.447947,
//           0.55661,
//           "0.411",
//           13.0,
//           7.0,
//           0.0,
//           -1.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2134: [
//           "2333",
//           11.24,
//           57427.0,
//           20.475,
//           26.221,
//           28.2053,
//           27.8137,
//           "26.99",
//           348.3,
//           7.0,
//           0.0,
//           1.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2135: [
//           "1611",
//           5.5,
//           170.0,
//           7.343,
//           8.0513,
//           9.04587,
//           10.0268,
//           "8.649",
//           16.9,
//           7.0,
//           -5.0,
//           -9.0,
//           -5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2136: [
//           "6666",
//           1.92,
//           8708.0,
//           2.5594,
//           3.1424,
//           3.87767,
//           5.0279,
//           "-",
//           207.5,
//           7.0,
//           2.0,
//           3.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2137: [
//           "2177",
//           2.1,
//           9.182,
//           3.6882,
//           4.2696,
//           4.6648,
//           "-",
//           "4.514",
//           3.4837,
//           7.0,
//           1.0,
//           0.0,
//           -4.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         2138: [
//           "8050",
//           0.275,
//           1.155,
//           0.3568,
//           0.3872,
//           0.460867,
//           0.5461,
//           "0.435",
//           3.7296,
//           7.0,
//           2.0,
//           1.0,
//           -2.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         2139: [
//           "1141",
//           1.58,
//           162.0,
//           2.7468,
//           3.027,
//           3.29943,
//           3.46517,
//           "-",
//           18.4,
//           7.0,
//           -2.0,
//           -2.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2140: [
//           "1981",
//           1.29,
//           343.0,
//           1.8688,
//           2.3173,
//           2.58373,
//           3.28715,
//           "2.481",
//           21.3,
//           7.0,
//           1.0,
//           0.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2141: [
//           "9668",
//           1.06,
//           3110.0,
//           1.8862,
//           2.1747,
//           2.47967,
//           2.61785,
//           "2.389",
//           65.7,
//           7.0,
//           2.0,
//           3.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2142: [
//           "6996",
//           5.5,
//           1056.0,
//           7.1164,
//           8.9601,
//           10.1705,
//           11.8883,
//           "9.636",
//           36.5,
//           7.0,
//           1.0,
//           2.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2143: [
//           "0510",
//           0.26,
//           0.73,
//           0.27262,
//           0.37911,
//           0.447807,
//           0.504605,
//           "0.435",
//           0.679,
//           7.0,
//           2.0,
//           2.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2144: [
//           "0382",
//           2.45,
//           745.0,
//           3.74,
//           4.3636,
//           4.6488,
//           5.28675,
//           "4.471",
//           26.2,
//           7.0,
//           0.0,
//           -1.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2145: [
//           "0832",
//           0.59,
//           627.0,
//           0.8226,
//           0.9387,
//           1.17673,
//           1.36475,
//           "1.102",
//           17.4,
//           7.0,
//           1.0,
//           1.0,
//           4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2146: [
//           "2256",
//           3.98,
//           6.368,
//           6.683,
//           8.5644,
//           "-",
//           "-",
//           "-",
//           27.9,
//           7.0,
//           1.0,
//           1.0,
//           1.0,
//           1.0,
//           "M",
//         ],
//       },
//       {
//         2147: [
//           "0204",
//           0.054,
//           16.952,
//           0.058,
//           0.1006,
//           0.139093,
//           0.157088,
//           "0.126",
//           1.2342,
//           7.0,
//           2.0,
//           3.0,
//           5.0,
//           0.0,
//           "-",
//         ],
//       },
//       {
//         2148: [
//           "2252",
//           22.15,
//           1441.0,
//           48.05,
//           "-",
//           "-",
//           "-",
//           "-",
//           210.8,
//           7.0,
//           -1.0,
//           -13.0,
//           -20.0,
//           1.0,
//           "M",
//         ],
//       },
//       {
//         2149: [
//           "6601",
//           1.86,
//           755.0,
//           2.5194,
//           2.9801,
//           3.54613,
//           4.618,
//           "3.282",
//           24.8,
//           7.0,
//           1.0,
//           2.0,
//           1.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         2150: [
//           "6862",
//           11.22,
//           55506.0,
//           17.701,
//           18.8019,
//           22.4996,
//           26.9459,
//           "21.63",
//           625.4,
//           7.0,
//           -1.0,
//           -3.0,
//           -8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2151: [
//           "6169",
//           1.7,
//           7306.0,
//           2.1174,
//           2.7715,
//           3.2102,
//           4.07785,
//           "3.006",
//           61.1,
//           7.0,
//           2.0,
//           3.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2152: [
//           "9899",
//           65.35,
//           103.0,
//           123.534,
//           "-",
//           "-",
//           "-",
//           "-",
//           135.7,
//           7.0,
//           2.0,
//           2.0,
//           0.0,
//           1.0,
//           "M",
//         ],
//       },
//       {
//         2153: [
//           "0342",
//           0.046,
//           10.882,
//           0.05692,
//           0.08474,
//           0.174327,
//           0.236995,
//           "0.154",
//           0.6753,
//           6.0,
//           1.0,
//           0.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2154: [
//           "3690",
//           112.6,
//           1230000.0,
//           200.162,
//           230.257,
//           233.426,
//           245.477,
//           "230.853",
//           6910.2,
//           6.0,
//           -2.0,
//           -3.0,
//           -8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2155: [
//           "8227",
//           0.65,
//           6.57,
//           1.0316,
//           1.209,
//           1.30207,
//           1.4014,
//           "1.251",
//           4.1974,
//           6.0,
//           0.0,
//           0.0,
//           -1.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         2156: [
//           "8422",
//           0.024,
//           13.385,
//           0.03694,
//           0.04109,
//           0.0435933,
//           0.08311,
//           "0.044",
//           0.288,
//           6.0,
//           1.0,
//           -1.0,
//           -7.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         2157: [
//           "8228",
//           0.056,
//           130.0,
//           0.08144,
//           0.09244,
//           0.102547,
//           0.14956,
//           "0.091",
//           0.5593,
//           6.0,
//           -1.0,
//           -3.0,
//           -3.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         2158: [
//           "3978",
//           0.228,
//           7.109,
//           0.2925,
//           0.37995,
//           0.438733,
//           0.657,
//           "0.425",
//           1.9316,
//           6.0,
//           2.0,
//           1.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2159: [
//           "1477",
//           8.23,
//           572.0,
//           12.1194,
//           14.4561,
//           16.2831,
//           18.7391,
//           "15.597",
//           54.8,
//           6.0,
//           1.0,
//           2.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2160: [
//           "0013",
//           24.1,
//           9848.0,
//           43.916,
//           48.1045,
//           51.8053,
//           "-",
//           "49.53",
//           208.3,
//           6.0,
//           0.0,
//           -1.0,
//           -17.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         2161: [
//           "1383",
//           0.094,
//           202.0,
//           0.10196,
//           0.16217,
//           0.21348,
//           0.267085,
//           "0.198",
//           6.2678,
//           6.0,
//           1.0,
//           3.0,
//           5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2162: [
//           "1873",
//           2.54,
//           6486.0,
//           3.67,
//           4.4344,
//           5.12647,
//           6.21785,
//           "4.876",
//           49.1,
//           6.0,
//           1.0,
//           2.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2163: [
//           "1858",
//           7.98,
//           1435.0,
//           12.0388,
//           14.967,
//           16.5414,
//           18.459,
//           "15.592",
//           7.6489,
//           6.0,
//           0.0,
//           0.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2164: [
//           "0663",
//           0.233,
//           3.556,
//           0.350437,
//           0.394218,
//           0.428199,
//           0.486199,
//           "0.41",
//           2.7663,
//           6.0,
//           2.0,
//           1.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2165: [
//           "1167",
//           6.97,
//           195.0,
//           10.168,
//           13.065,
//           15.1413,
//           16.5919,
//           "14.485",
//           53.7,
//           6.0,
//           0.0,
//           3.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2166: [
//           "1617",
//           0.098,
//           143.0,
//           0.12508,
//           0.15362,
//           0.1681,
//           0.198895,
//           "0.158",
//           1.4488,
//           6.0,
//           1.0,
//           1.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2167: [
//           "2126",
//           7.27,
//           2388.0,
//           10.3038,
//           11.5955,
//           13.6507,
//           16.4701,
//           "12.974",
//           29.6,
//           6.0,
//           0.0,
//           -3.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2168: [
//           "0698",
//           0.127,
//           178.0,
//           0.19932,
//           0.22805,
//           0.242155,
//           0.296223,
//           "0.234",
//           12.3,
//           6.0,
//           2.0,
//           1.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2169: [
//           "6855",
//           12.86,
//           3102.0,
//           21.6192,
//           25.6101,
//           29.5127,
//           33.8563,
//           "27.707",
//           33.8,
//           6.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2170: [
//           "9966",
//           6.49,
//           3848.0,
//           10.5114,
//           13.4171,
//           15.5399,
//           17.3832,
//           "14.636",
//           60.8,
//           6.0,
//           1.0,
//           0.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2171: [
//           "8487",
//           0.2,
//           111.0,
//           0.23668,
//           0.30889,
//           0.405527,
//           0.514595,
//           "0.366",
//           1.76,
//           6.0,
//           1.0,
//           2.0,
//           3.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         2172: [
//           "1239",
//           0.096,
//           11.542,
//           0.09902,
//           0.16708,
//           0.19964,
//           0.21635,
//           "0.186",
//           0.6323,
//           6.0,
//           2.0,
//           4.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2173: [
//           "0209",
//           0.023,
//           58.957,
//           0.03868,
//           0.05051,
//           0.05536,
//           0.054255,
//           "0.053",
//           0.8422,
//           6.0,
//           -4.0,
//           -8.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2174: [
//           "8612",
//           0.108,
//           1.09,
//           0.10026,
//           0.17833,
//           0.195487,
//           0.219845,
//           "0.178",
//           0.8856,
//           6.0,
//           1.0,
//           0.0,
//           -3.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         2175: [
//           "2219",
//           3.36,
//           801.0,
//           5.058,
//           6.1829,
//           7.54193,
//           "-",
//           "6.897",
//           23.7,
//           6.0,
//           -1.0,
//           -1.0,
//           0.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         2176: [
//           "9698",
//           23.95,
//           15484.0,
//           40.934,
//           47.481,
//           50.4693,
//           55.4707,
//           "49.423",
//           358.0,
//           6.0,
//           -4.0,
//           -10.0,
//           -11.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2177: [
//           "9909",
//           6.89,
//           3331.0000000000005,
//           14.7068,
//           15.5264,
//           17.0447,
//           19.4563,
//           "16.225",
//           44.2,
//           5.0,
//           -1.0,
//           -6.0,
//           -9.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2178: [
//           "0707",
//           0.012,
//           25.717000000000002,
//           0.01836,
//           0.02188,
//           0.0258933,
//           0.030215,
//           "0.108",
//           1.191,
//           5.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2179: [
//           "0813",
//           3.77,
//           28709.0,
//           5.3046,
//           7.3161,
//           9.86273,
//           12.2051,
//           "9.132",
//           143.1,
//           5.0,
//           2.0,
//           3.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2180: [
//           "0715",
//           0.059,
//           0.24,
//           0.1188,
//           0.12545,
//           0.131587,
//           0.1399,
//           "0.127",
//           9.5241,
//           5.0,
//           0.0,
//           -3.0,
//           -8.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2181: [
//           "6699",
//           128.7,
//           4260.0,
//           192.57,
//           256.703,
//           292.574,
//           "-",
//           "272.13",
//           216.6,
//           5.0,
//           0.0,
//           -2.0,
//           -1.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         2182: [
//           "1691",
//           6.47,
//           12933.0,
//           10.9358,
//           12.7751,
//           14.6327,
//           16.3064,
//           "13.806",
//           226.1,
//           5.0,
//           1.0,
//           0.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2183: [
//           "2190",
//           12.26,
//           55.646,
//           19.4062,
//           23.0678,
//           28.8515,
//           "-",
//           "26.877",
//           39.7,
//           5.0,
//           1.0,
//           1.0,
//           1.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         2184: [
//           "1765",
//           0.65,
//           15189.0,
//           1.0664,
//           1.2908,
//           1.30327,
//           1.4453,
//           "1.272",
//           52.1,
//           5.0,
//           0.0,
//           0.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2185: [
//           "8001",
//           0.087,
//           3.576,
//           0.10352,
//           0.20187,
//           0.177,
//           0.18775,
//           "0.166",
//           0.3758,
//           5.0,
//           1.0,
//           2.0,
//           -3.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         2186: [
//           "2120",
//           9.71,
//           39.842,
//           16.6778,
//           21.5774,
//           24.8146,
//           28.1182,
//           "23.656",
//           1.8779,
//           5.0,
//           1.0,
//           0.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2187: [
//           "2142",
//           3.34,
//           782.0,
//           5.4164,
//           6.6551,
//           6.9572,
//           7.6783,
//           "6.68",
//           25.6,
//           5.0,
//           -1.0,
//           0.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2188: [
//           "0339",
//           0.175,
//           0.175,
//           0.2833,
//           0.3362,
//           0.403633,
//           0.433725,
//           "0.381",
//           0.4208,
//           5.0,
//           1.0,
//           2.0,
//           -2.0,
//           0.0,
//           "-",
//         ],
//       },
//       {
//         2189: [
//           "6669",
//           5.33,
//           404.0,
//           10.2134,
//           11.3981,
//           "-",
//           "-",
//           "12.4",
//           16.7,
//           5.0,
//           0.0,
//           -2.0,
//           -10.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         2190: [
//           "6609",
//           40.1,
//           190.0,
//           72.222,
//           79.554,
//           "-",
//           "-",
//           "88.088",
//           12.6,
//           5.0,
//           1.0,
//           1.0,
//           1.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         2191: [
//           "0095",
//           0.65,
//           2661.0,
//           1.368,
//           1.4685,
//           1.5794,
//           1.6851,
//           "1.53",
//           33.1,
//           5.0,
//           -2.0,
//           -9.0,
//           -18.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2192: [
//           "1920",
//           0.026,
//           1.9,
//           0.03614,
//           0.0458,
//           0.0562467,
//           0.070935,
//           "0.051",
//           0.676,
//           5.0,
//           2.0,
//           2.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2193: [
//           "8220",
//           0.024,
//           0.594,
//           0.02374,
//           0.03182,
//           0.0424533,
//           0.06448,
//           "0.038",
//           0.2463,
//           5.0,
//           2.0,
//           -2.0,
//           1.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         2194: [
//           "2170",
//           4.44,
//           103.0,
//           6.2528,
//           7.6492,
//           9.4268,
//           12.5926,
//           "8.628",
//           3.5934,
//           5.0,
//           0.0,
//           -2.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2195: [
//           "1850",
//           0.15,
//           46.263,
//           0.20594,
//           0.43373,
//           0.627153,
//           0.911715,
//           "0.515",
//           1.2,
//           5.0,
//           0.0,
//           -3.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2196: [
//           "1833",
//           18.26,
//           22546.0,
//           25.3532,
//           29.5041,
//           39.0087,
//           50.626,
//           "35.46",
//           206.2,
//           5.0,
//           -1.0,
//           -1.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2197: [
//           "0997",
//           0.054,
//           0.899,
//           0.06014,
//           0.12744,
//           0.197227,
//           0.221655,
//           "0.176",
//           0.6314,
//           5.0,
//           1.0,
//           1.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2198: [
//           "1808",
//           0.035,
//           6.262,
//           0.04716,
//           0.06272,
//           0.0690067,
//           0.073635,
//           "0.066",
//           0.4762,
//           5.0,
//           0.0,
//           1.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2199: [
//           "2312",
//           0.13,
//           0.528,
//           0.172,
//           0.2171,
//           0.252533,
//           0.3074,
//           "0.247",
//           0.3006,
//           5.0,
//           1.0,
//           2.0,
//           -1.0,
//           0.0,
//           "-",
//         ],
//       },
//       {
//         2200: [
//           "0361",
//           0.047,
//           2.068,
//           0.0797,
//           0.12526,
//           0.1543,
//           0.17667,
//           "0.142",
//           2.4445,
//           4.0,
//           1.0,
//           -1.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2201: [
//           "6998",
//           3.88,
//           312.0,
//           5.909,
//           7.2875,
//           8.6394,
//           10.4823,
//           "8.185",
//           19.5,
//           4.0,
//           0.0,
//           -1.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2202: [
//           "1813",
//           2.64,
//           8538.0,
//           4.2294,
//           5.2833,
//           6.1172,
//           7.2203,
//           "5.764",
//           84.0,
//           4.0,
//           1.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2203: [
//           "6633",
//           3.8,
//           4.534,
//           7.2426,
//           "-",
//           "-",
//           "-",
//           "-",
//           26.2,
//           4.0,
//           0.0,
//           1.0,
//           -2.0,
//           1.0,
//           "M",
//         ],
//       },
//       {
//         2204: [
//           "1628",
//           0.4,
//           1502.0,
//           0.6346,
//           0.7088,
//           0.9258,
//           1.2028,
//           "0.838",
//           26.1,
//           4.0,
//           1.0,
//           -1.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2205: [
//           "1629",
//           0.35,
//           2.138,
//           0.4102,
//           0.526,
//           0.961267,
//           1.4279,
//           "0.761",
//           1.9113,
//           4.0,
//           1.0,
//           1.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2206: [
//           "6969",
//           15.72,
//           140000.0,
//           32.3704,
//           36.6717,
//           37.3025,
//           39.0866,
//           "36.252",
//           944.8,
//           4.0,
//           -2.0,
//           -3.0,
//           -5.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2207: [
//           "0886",
//           0.033,
//           24.558,
//           0.0452,
//           0.06294,
//           0.0861867,
//           0.112845,
//           "0.08",
//           0.7458,
//           4.0,
//           -2.0,
//           -1.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2208: [
//           "1638",
//           0.55,
//           1513.0,
//           0.8138,
//           0.9331,
//           1.35993,
//           1.7445,
//           "1.246",
//           38.5,
//           4.0,
//           0.0,
//           0.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2209: [
//           "0241",
//           3.76,
//           32021.0,
//           5.896,
//           7.231,
//           8.7146,
//           10.578,
//           "8.305",
//           508.2,
//           4.0,
//           0.0,
//           0.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2210: [
//           "3883",
//           0.99,
//           2970.0,
//           1.4846,
//           1.862,
//           2.7198,
//           3.734,
//           "2.44",
//           29.3,
//           4.0,
//           0.0,
//           1.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2211: [
//           "2048",
//           0.92,
//           2323.0,
//           1.7008,
//           1.6974,
//           1.80607,
//           2.85595,
//           "1.64",
//           16.0,
//           4.0,
//           1.0,
//           1.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2212: [
//           "2127",
//           0.79,
//           810.0,
//           1.4864,
//           1.8539,
//           2.0304,
//           2.1461,
//           "1.964",
//           24.2,
//           4.0,
//           0.0,
//           0.0,
//           -6.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2213: [
//           "2148",
//           4.02,
//           558.0,
//           6.881,
//           8.6585,
//           9.7124,
//           9.98285,
//           "9.276",
//           46.8,
//           4.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2214: [
//           "2400",
//           16.76,
//           9302.0,
//           29.5916,
//           37.4288,
//           39.8495,
//           44.3964,
//           "38.645",
//           80.50000000000001,
//           4.0,
//           0.0,
//           1.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2215: [
//           "2150",
//           3.99,
//           2057.0,
//           6.9098,
//           8.075,
//           9.22667,
//           "-",
//           "9.016",
//           68.4,
//           4.0,
//           -1.0,
//           -2.0,
//           -7.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         2216: [
//           "0102",
//           0.092,
//           70.854,
//           0.11544,
//           0.23407,
//           0.34288,
//           0.42966,
//           "0.311",
//           4.1486,
//           4.0,
//           1.0,
//           2.0,
//           3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2217: [
//           "0839",
//           5.15,
//           7538.0,
//           8.8404,
//           11.19,
//           12.0945,
//           13.3431,
//           "11.641",
//           123.0,
//           4.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2218: [
//           "8262",
//           0.1,
//           1.504,
//           0.15998,
//           0.21617,
//           0.240847,
//           0.259935,
//           "0.23",
//           0.8,
//           4.0,
//           1.0,
//           -1.0,
//           -1.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         2219: [
//           "0328",
//           0.08,
//           0.432,
//           0.12978,
//           0.16928,
//           0.198627,
//           0.21044,
//           "0.191",
//           0.5785,
//           4.0,
//           0.0,
//           -3.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2220: [
//           "2599",
//           0.7,
//           381.0,
//           1.0018,
//           1.7976,
//           3.022,
//           3.6622,
//           "2.675",
//           21.3,
//           4.0,
//           1.0,
//           1.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2221: [
//           "0616",
//           0.115,
//           4.312,
//           0.14406,
//           0.23945,
//           0.308267,
//           0.346375,
//           "0.286",
//           1.0711,
//           4.0,
//           0.0,
//           1.0,
//           2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2222: [
//           "8412",
//           0.024,
//           0.12,
//           0.03004,
//           0.03219,
//           0.101407,
//           0.18668,
//           "0.063",
//           0.2397,
//           4.0,
//           1.0,
//           1.0,
//           1.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         2223: [
//           "1778",
//           0.54,
//           78.861,
//           0.8756,
//           1.093,
//           1.5802,
//           1.9453,
//           "1.446",
//           8.0326,
//           3.0,
//           0.0,
//           0.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2224: [
//           "2500",
//           14.2,
//           5529.0,
//           24.95,
//           29.401,
//           33.1427,
//           40.7477,
//           "32.105",
//           62.6,
//           3.0,
//           -1.0,
//           -5.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2225: [
//           "9901",
//           7.76,
//           2556.0,
//           12.0928,
//           14.3374,
//           14.8725,
//           25.1786,
//           "14.499",
//           133.0,
//           3.0,
//           0.0,
//           0.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2226: [
//           "2013",
//           3.33,
//           16239.0,
//           6.1286,
//           7.977,
//           9.0882,
//           10.5596,
//           "8.824",
//           84.8,
//           3.0,
//           1.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2227: [
//           "1801",
//           23.45,
//           48649.0,
//           36.079,
//           51.894,
//           57.7543,
//           65.0,
//           "55.29",
//           343.8,
//           3.0,
//           -1.0,
//           -2.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2228: [
//           "2216",
//           2.93,
//           45.558,
//           6.0002,
//           8.0291,
//           "-",
//           "-",
//           "-",
//           15.4,
//           3.0,
//           0.0,
//           0.0,
//           0.0,
//           1.0,
//           "M",
//         ],
//       },
//       {
//         2229: [
//           "9926",
//           14.12,
//           11091.0,
//           22.9578,
//           32.8154,
//           36.0559,
//           41.7314,
//           "34.433",
//           115.3,
//           3.0,
//           0.0,
//           -1.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2230: [
//           "2799",
//           0.325,
//           2072.0,
//           0.462,
//           0.741,
//           0.834,
//           0.8805,
//           "0.794",
//           87.7,
//           3.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2231: [
//           "2160",
//           2.17,
//           937.0,
//           3.3546,
//           4.0843,
//           5.1848,
//           7.6794,
//           "4.751",
//           52.1,
//           3.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2232: [
//           "1952",
//           16.5,
//           1930.0,
//           27.691,
//           33.424,
//           40.4533,
//           47.7475,
//           "38.066",
//           49.2,
//           3.0,
//           -1.0,
//           -2.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2233: [
//           "9991",
//           16.36,
//           971.0,
//           30.9602,
//           36.2721,
//           42.3544,
//           53.1521,
//           "39.809",
//           40.2,
//           3.0,
//           0.0,
//           -1.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2234: [
//           "0853",
//           13.58,
//           22870.0,
//           23.5038,
//           28.7489,
//           34.8863,
//           42.2612,
//           "32.568",
//           247.4,
//           3.0,
//           0.0,
//           -2.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2235: [
//           "7226",
//           1.306,
//           66289.0,
//           2.7592,
//           3.27658,
//           3.57193,
//           4.2411,
//           "3.426",
//           0.0,
//           3.0,
//           0.0,
//           -1.0,
//           -4.0,
//           0.0,
//           "E",
//         ],
//       },
//       {
//         2236: [
//           "1568",
//           0.325,
//           29.958,
//           0.4545,
//           0.62365,
//           0.851433,
//           1.01853,
//           "0.774",
//           7.0141,
//           3.0,
//           0.0,
//           0.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2237: [
//           "9688",
//           223.6,
//           1741.0,
//           397.372,
//           516.244,
//           682.506,
//           830.125,
//           "620.93",
//           196.8,
//           3.0,
//           0.0,
//           -1.0,
//           -3.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2238: [
//           "1233",
//           1.98,
//           3321.0,
//           3.2118,
//           3.9575,
//           4.9852,
//           5.9942,
//           "4.602",
//           41.6,
//           3.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2239: [
//           "1516",
//           4.2,
//           19867.0,
//           7.7628,
//           10.2987,
//           12.8127,
//           15.7192,
//           "11.951",
//           129.2,
//           3.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2240: [
//           "6978",
//           5.06,
//           651.0,
//           8.633,
//           12.1117,
//           13.443,
//           14.7002,
//           "12.802",
//           26.0,
//           3.0,
//           0.0,
//           0.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2241: [
//           "1317",
//           0.35,
//           378.0,
//           0.4913,
//           0.74885,
//           1.00403,
//           1.19597,
//           "0.93",
//           10.4,
//           3.0,
//           1.0,
//           1.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2242: [
//           "0456",
//           0.026,
//           11.822,
//           0.03582,
//           0.05603,
//           0.06636,
//           0.07294,
//           "0.062",
//           1.125,
//           3.0,
//           1.0,
//           1.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2243: [
//           "8308",
//           0.125,
//           0.0,
//           0.18296,
//           0.2434,
//           0.283233,
//           0.32465,
//           "0.263",
//           1.225,
//           3.0,
//           1.0,
//           -2.0,
//           -2.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         2244: [
//           "8428",
//           0.055,
//           0.831,
//           0.11492,
//           0.13849,
//           0.175227,
//           0.178921,
//           "0.172",
//           0.2138,
//           3.0,
//           0.0,
//           0.0,
//           1.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         2245: [
//           "8606",
//           0.12,
//           9.887,
//           0.18564,
//           0.20838,
//           0.192667,
//           0.28173,
//           "0.192",
//           1.1566,
//           3.0,
//           -3.0,
//           -4.0,
//           -5.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         2246: [
//           "0909",
//           7.65,
//           19203.0,
//           16.0042,
//           19.7433,
//           22.5185,
//           26.0274,
//           "21.384",
//           150.5,
//           3.0,
//           0.0,
//           -2.0,
//           -4.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2247: [
//           "8297",
//           0.16,
//           24.153,
//           0.26194,
//           0.41482,
//           0.689613,
//           0.79011,
//           "0.618",
//           0.8448,
//           2.0,
//           0.0,
//           -1.0,
//           0.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         2248: [
//           "1076",
//           0.041,
//           52.973,
//           0.06748,
//           0.08503,
//           0.10404,
//           0.12833,
//           "0.09",
//           4.3661,
//           2.0,
//           0.0,
//           0.0,
//           -2.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2249: [
//           "2192",
//           7.82,
//           1235.0,
//           15.2464,
//           26.8417,
//           29.4888,
//           "-",
//           "28.151",
//           55.8,
//           2.0,
//           0.0,
//           0.0,
//           0.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         2250: [
//           "8091",
//           0.041,
//           1.565,
//           0.0907,
//           0.12542,
//           0.122573,
//           0.11686,
//           "0.117",
//           0.2952,
//           2.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         2251: [
//           "2158",
//           8.0,
//           2955.0,
//           15.5046,
//           19.5974,
//           22.8833,
//           27.0415,
//           "21.551",
//           78.8,
//           2.0,
//           0.0,
//           -1.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2252: [
//           "9626",
//           133.9,
//           97885.0,
//           265.314,
//           399.776,
//           461.685,
//           555.044,
//           "435.807",
//           514.6,
//           2.0,
//           0.0,
//           0.0,
//           -1.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         2253: [
//           "8083",
//           0.188,
//           3744.0,
//           0.32606,
//           0.55793,
//           0.703087,
//           0.876515,
//           "0.657",
//           34.6,
//           2.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         2254: [
//           "2137",
//           9.22,
//           4364.0,
//           18.8028,
//           26.2672,
//           30.0401,
//           "-",
//           "28.578",
//           66.5,
//           2.0,
//           0.0,
//           0.0,
//           -1.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         2255: [
//           "8020",
//           0.016,
//           0.176,
//           0.03094,
//           0.04129,
//           0.05722,
//           0.06378,
//           "0.052",
//           0.418,
//           2.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         2256: [
//           "8187",
//           0.049,
//           34.105,
//           0.10182,
//           0.19565,
//           0.227233,
//           0.22538,
//           "0.215",
//           0.2949,
//           2.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         2257: [
//           "1739",
//           0.4,
//           2.687,
//           0.6096,
//           0.8918,
//           1.16327,
//           1.36895,
//           "1.056",
//           4.6162,
//           2.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2258: [
//           "2171",
//           11.94,
//           1836.0,
//           19.689,
//           29.7365,
//           33.4463,
//           "-",
//           "33.086",
//           67.8,
//           2.0,
//           0.0,
//           -2.0,
//           -4.0,
//           2.0,
//           "M",
//         ],
//       },
//       {
//         2259: [
//           "1592",
//           0.3,
//           1.677,
//           0.4251,
//           1.05415,
//           1.29777,
//           1.42087,
//           "1.256",
//           3.7416,
//           2.0,
//           0.0,
//           0.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2260: [
//           "1989",
//           0.285,
//           37.656,
//           0.71148,
//           0.80564,
//           0.876027,
//           0.93742,
//           "0.839",
//           2.5732,
//           2.0,
//           0.0,
//           0.0,
//           -13.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2261: [
//           "9939",
//           9.35,
//           11130.0,
//           11.3014,
//           30.0203,
//           41.6039,
//           47.1209,
//           "37.368",
//           36.2,
//           2.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2262: [
//           "0336",
//           3.43,
//           5445.0,
//           7.8444,
//           12.6208,
//           13.9556,
//           14.7058,
//           "13.335",
//           110.7,
//           2.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2263: [
//           "6958",
//           1.06,
//           385.0,
//           2.9424,
//           3.7938,
//           4.16007,
//           4.3756,
//           "3.984",
//           10.9,
//           2.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2264: [
//           "0176",
//           0.036,
//           25.521,
//           0.05868,
//           0.1176,
//           0.138867,
//           0.141815,
//           "0.132",
//           0.7317,
//           2.0,
//           1.0,
//           1.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2265: [
//           "1918",
//           4.44,
//           130000.0,
//           9.1628,
//           11.8554,
//           13.8397,
//           16.8378,
//           "13.04",
//           241.9,
//           2.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2266: [
//           "6118",
//           2.48,
//           195.0,
//           5.3178,
//           10.9991,
//           15.6093,
//           15.438,
//           "14.848",
//           12.7,
//           2.0,
//           0.0,
//           1.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2267: [
//           "1769",
//           0.7,
//           109.0,
//           1.0694,
//           1.4275,
//           1.54173,
//           2.08175,
//           "1.468",
//           3.8899,
//           2.0,
//           0.0,
//           0.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2268: [
//           "1773",
//           0.56,
//           1510.0,
//           1.7272,
//           1.7692,
//           1.83393,
//           2.1612,
//           "1.746",
//           12.1,
//           2.0,
//           0.0,
//           0.0,
//           -20.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2269: [
//           "0767",
//           0.092,
//           30.894,
//           0.15926,
//           0.26246,
//           0.355807,
//           0.41978,
//           "0.326",
//           3.5604,
//           2.0,
//           0.0,
//           0.0,
//           1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2270: [
//           "3380",
//           1.47,
//           7998.0,
//           4.5034,
//           5.9056,
//           6.7792,
//           7.81045,
//           "6.383",
//           83.59999999999998,
//           1.0,
//           0.0,
//           -1.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2271: [
//           "0299",
//           0.028,
//           15.778,
//           0.05164,
//           0.11716,
//           0.153327,
//           0.18017,
//           "0.139",
//           1.5288,
//           1.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2272: [
//           "3893",
//           0.075,
//           15.598,
//           0.4218,
//           0.63135,
//           0.562233,
//           0.501765,
//           "0.553",
//           0.54,
//           1.0,
//           0.0,
//           0.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2273: [
//           "1723",
//           0.213,
//           182.0,
//           2.66268,
//           3.66074,
//           3.82183,
//           3.90752,
//           "3.735",
//           0.852,
//           1.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2274: [
//           "6068",
//           0.285,
//           41.34,
//           0.5193,
//           0.90545,
//           1.12517,
//           1.36627,
//           "1.052",
//           6.2077,
//           1.0,
//           0.0,
//           0.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2275: [
//           "1107",
//           0.1,
//           56.957,
//           0.1822,
//           0.2811,
//           0.395433,
//           0.480825,
//           "0.361",
//           2.7949,
//           1.0,
//           0.0,
//           -1.0,
//           -1.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2276: [
//           "8239",
//           0.028,
//           5.533,
//           0.05524,
//           0.11745,
//           0.135833,
//           0.1413,
//           "0.129",
//           0.2833,
//           1.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "G",
//         ],
//       },
//       {
//         2277: [
//           "1323",
//           0.116,
//           187.0,
//           0.3065,
//           0.98165,
//           1.04377,
//           1.04367,
//           "0.978",
//           4.0589,
//           1.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2278: [
//           "6158",
//           0.52,
//           4454.0,
//           2.4286,
//           3.1824,
//           3.57073,
//           3.8146,
//           "3.358",
//           22.7,
//           1.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2279: [
//           "1987",
//           0.204,
//           207.0,
//           0.35656,
//           1.57418,
//           1.81032,
//           1.97824,
//           "1.662",
//           2.04,
//           1.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//       {
//         2280: [
//           "1566",
//           0.142,
//           226.0,
//           0.26586,
//           0.41958,
//           1.07985,
//           1.55284,
//           "0.907",
//           1.6784,
//           1.0,
//           0.0,
//           0.0,
//           0.0,
//           0.0,
//           "M",
//         ],
//       },
//     ],
//   },
// };

// const stockSector = {
//   ind: {
//     "0001": { id: "0001", i: 0, s: 0, ss: 0 },
//     "0002": { id: "0002", i: 1, s: 1, ss: 1 },
//     "0003": { id: "0003", i: 1, s: 1, ss: 2 },
//     "0004": { id: "0004", i: 2, s: 2, ss: 3 },
//     "0005": { id: "0005", i: 3, s: 3, ss: 4 },
//     "0006": { id: "0006", i: 1, s: 1, ss: 1 },
//     "0007": { id: "0007", i: 2, s: 2, ss: 5 },
//     "0008": { id: "0008", i: 4, s: 4, ss: 6 },
//     "0009": { id: "0009", i: 2, s: 2, ss: 3 },
//     "0010": { id: "0010", i: 2, s: 2, ss: 5 },
//     "0011": { id: "0011", i: 3, s: 3, ss: 4 },
//     "0012": { id: "0012", i: 2, s: 2, ss: 3 },
//     "0013": { id: "0013", i: 5, s: 5, ss: 7 },
//     "0014": { id: "0014", i: 2, s: 2, ss: 5 },
//     "0016": { id: "0016", i: 2, s: 2, ss: 3 },
//     "0017": { id: "0017", i: 2, s: 2, ss: 3 },
//     "0018": { id: "0018", i: 6, s: 6, ss: 8 },
//     "0019": { id: "0019", i: 0, s: 0, ss: 0 },
//     "0020": { id: "0020", i: 7, s: 7, ss: 9 },
//     "0021": { id: "0021", i: 2, s: 2, ss: 3 },
//     "0022": { id: "0022", i: 6, s: 8, ss: 10 },
//     "0023": { id: "0023", i: 3, s: 3, ss: 4 },
//     "0024": { id: "0024", i: 8, s: 9, ss: 11 },
//     "0025": { id: "0025", i: 0, s: 0, ss: 0 },
//     "0026": { id: "0026", i: 2, s: 2, ss: 5 },
//     "0027": { id: "0027", i: 6, s: 8, ss: 12 },
//     "0028": { id: "0028", i: 2, s: 2, ss: 3 },
//     "0029": { id: "0029", i: 2, s: 2, ss: 5 },
//     "0030": { id: "0030", i: 9, s: 10, ss: 13 },
//     "0031": { id: "0031", i: 10, s: 11, ss: 14 },
//     "0032": { id: "0032", i: 6, s: 12, ss: 15 },
//     "0033": { id: "0033", i: 6, s: 13, ss: 16 },
//     "0034": { id: "0034", i: 2, s: 2, ss: 3 },
//     "0035": { id: "0035", i: 2, s: 2, ss: 3 },
//     "0036": { id: "0036", i: 2, s: 2, ss: 5 },
//     "0037": { id: "0037", i: 2, s: 2, ss: 5 },
//     "0038": { id: "0038", i: 10, s: 11, ss: 17 },
//     "0039": { id: "0039", i: 9, s: 14, ss: 18 },
//     "0040": { id: "0040", i: 6, s: 13, ss: 19 },
//     "0041": { id: "0041", i: 2, s: 2, ss: 5 },
//     "0042": { id: "0042", i: 10, s: 11, ss: 20 },
//     "0043": { id: "0043", i: 9, s: 15, ss: 21 },
//     "0045": { id: "0045", i: 6, s: 8, ss: 10 },
//     "0046": { id: "0046", i: 7, s: 7, ss: 22 },
//     "0047": { id: "0047", i: 6, s: 8, ss: 23 },
//     "0048": { id: "0048", i: 6, s: 16, ss: 24 },
//     "0050": { id: "0050", i: 2, s: 2, ss: 5 },
//     "0051": { id: "0051", i: 6, s: 8, ss: 10 },
//     "0052": { id: "0052", i: 6, s: 8, ss: 23 },
//     "0053": { id: "0053", i: 2, s: 2, ss: 3 },
//     "0055": { id: "0055", i: 10, s: 17, ss: 25 },
//     "0057": { id: "0057", i: 10, s: 11, ss: 20 },
//     "0058": { id: "0058", i: 2, s: 18, ss: 26 },
//     "0059": { id: "0059", i: 2, s: 2, ss: 3 },
//     "0060": { id: "0060", i: 9, s: 10, ss: 27 },
//     "0061": { id: "0061", i: 11, s: 19, ss: 28 },
//     "0062": { id: "0062", i: 6, s: 8, ss: 29 },
//     "0063": { id: "0063", i: 2, s: 2, ss: 5 },
//     "0064": { id: "0064", i: 3, s: 20, ss: 30 },
//     "0065": { id: "0065", i: 11, s: 19, ss: 28 },
//     "0066": { id: "0066", i: 6, s: 8, ss: 29 },
//     "0067": { id: "0067", i: 8, s: 21, ss: 31 },
//     "0068": { id: "0068", i: 3, s: 20, ss: 32 },
//     "0069": { id: "0069", i: 6, s: 8, ss: 10 },
//     "0070": { id: "0070", i: 6, s: 8, ss: 12 },
//     "0071": { id: "0071", i: 6, s: 8, ss: 33 },
//     "0072": { id: "0072", i: 6, s: 6, ss: 8 },
//     "0073": { id: "0073", i: 9, s: 15, ss: 34 },
//     "0075": { id: "0075", i: 2, s: 2, ss: 3 },
//     "0076": { id: "0076", i: 10, s: 11, ss: 20 },
//     "0077": { id: "0077", i: 6, s: 8, ss: 29 },
//     "0078": { id: "0078", i: 6, s: 8, ss: 10 },
//     "0079": { id: "0079", i: 6, s: 12, ss: 35 },
//     "0080": { id: "0080", i: 3, s: 20, ss: 32 },
//     "0081": { id: "0081", i: 2, s: 2, ss: 3 },
//     "0082": { id: "0082", i: 7, s: 7, ss: 9 },
//     "0083": { id: "0083", i: 2, s: 2, ss: 5 },
//     "0084": { id: "0084", i: 6, s: 22, ss: 36 },
//     "0085": { id: "0085", i: 7, s: 23, ss: 37 },
//     "0086": { id: "0086", i: 3, s: 20, ss: 38 },
//     "0087": { id: "0087", i: 0, s: 0, ss: 0 },
//     "0088": { id: "0088", i: 2, s: 2, ss: 3 },
//     "0089": { id: "0089", i: 2, s: 2, ss: 5 },
//     "0090": { id: "0090", i: 1, s: 1, ss: 39 },
//     "0091": { id: "0091", i: 11, s: 24, ss: 40 },
//     "0092": { id: "0092", i: 11, s: 24, ss: 40 },
//     "0093": { id: "0093", i: 2, s: 2, ss: 5 },
//     "0094": { id: "0094", i: 8, s: 21, ss: 41 },
//     "0095": { id: "0095", i: 2, s: 2, ss: 3 },
//     "0096": { id: "0096", i: 10, s: 11, ss: 20 },
//     "0097": { id: "0097", i: 6, s: 22, ss: 36 },
//     "0098": { id: "0098", i: 8, s: 9, ss: 42 },
//     "0099": { id: "0099", i: 10, s: 11, ss: 43 },
//     "0100": { id: "0100", i: 6, s: 6, ss: 44 },
//     "0101": { id: "0101", i: 2, s: 2, ss: 5 },
//     "0102": { id: "0102", i: 6, s: 8, ss: 12 },
//     "0103": { id: "0103", i: 10, s: 11, ss: 45 },
//     "0104": { id: "0104", i: 6, s: 22, ss: 36 },
//     "0105": { id: "0105", i: 2, s: 2, ss: 5 },
//     "0106": { id: "0106", i: 2, s: 2, ss: 3 },
//     "0107": { id: "0107", i: 10, s: 25, ss: 46 },
//     "0108": { id: "0108", i: 2, s: 2, ss: 47 },
//     "0109": { id: "0109", i: 3, s: 20, ss: 38 },
//     "0110": { id: "0110", i: 7, s: 26, ss: 48 },
//     "0111": { id: "0111", i: 3, s: 20, ss: 32 },
//     "0112": { id: "0112", i: 2, s: 2, ss: 3 },
//     "0113": { id: "0113", i: 6, s: 27, ss: 49 },
//     "0114": { id: "0114", i: 6, s: 13, ss: 16 },
//     "0115": { id: "0115", i: 2, s: 2, ss: 3 },
//     "0116": { id: "0116", i: 6, s: 22, ss: 36 },
//     "0117": { id: "0117", i: 10, s: 11, ss: 20 },
//     "0118": { id: "0118", i: 10, s: 11, ss: 20 },
//     "0119": { id: "0119", i: 2, s: 2, ss: 3 },
//     "0120": { id: "0120", i: 2, s: 2, ss: 3 },
//     "0122": { id: "0122", i: 6, s: 27, ss: 50 },
//     "0123": { id: "0123", i: 2, s: 2, ss: 3 },
//     "0124": { id: "0124", i: 2, s: 2, ss: 3 },
//     "0125": { id: "0125", i: 6, s: 27, ss: 49 },
//     "0126": { id: "0126", i: 6, s: 8, ss: 23 },
//     "0127": { id: "0127", i: 2, s: 2, ss: 5 },
//     "0128": { id: "0128", i: 6, s: 22, ss: 51 },
//     "0129": { id: "0129", i: 3, s: 20, ss: 32 },
//     "0130": { id: "0130", i: 6, s: 27, ss: 50 },
//     "0131": { id: "0131", i: 2, s: 2, ss: 3 },
//     "0132": { id: "0132", i: 0, s: 0, ss: 0 },
//     "0133": { id: "0133", i: 3, s: 20, ss: 32 },
//     "0135": { id: "0135", i: 1, s: 1, ss: 2 },
//     "0136": { id: "0136", i: 7, s: 7, ss: 52 },
//     "0137": { id: "0137", i: 10, s: 25, ss: 53 },
//     "0138": { id: "0138", i: 6, s: 22, ss: 54 },
//     "0139": { id: "0139", i: 3, s: 20, ss: 30 },
//     "0141": { id: "0141", i: 2, s: 2, ss: 5 },
//     "0142": { id: "0142", i: 9, s: 10, ss: 27 },
//     "0143": { id: "0143", i: 7, s: 26, ss: 48 },
//     "0144": { id: "0144", i: 10, s: 25, ss: 53 },
//     "0145": { id: "0145", i: 6, s: 12, ss: 35 },
//     "0146": { id: "0146", i: 6, s: 27, ss: 55 },
//     "0147": { id: "0147", i: 2, s: 2, ss: 3 },
//     "0148": { id: "0148", i: 10, s: 11, ss: 20 },
//     "0149": { id: "0149", i: 2, s: 2, ss: 3 },
//     "0150": { id: "0150", i: 6, s: 6, ss: 44 },
//     "0151": { id: "0151", i: 9, s: 10, ss: 27 },
//     "0152": { id: "0152", i: 10, s: 25, ss: 46 },
//     "0153": { id: "0153", i: 2, s: 18, ss: 56 },
//     "0154": { id: "0154", i: 10, s: 11, ss: 57 },
//     "0155": { id: "0155", i: 10, s: 11, ss: 58 },
//     "0156": { id: "0156", i: 6, s: 8, ss: 23 },
//     "0157": { id: "0157", i: 6, s: 22, ss: 36 },
//     "0158": { id: "0158", i: 2, s: 2, ss: 5 },
//     "0159": { id: "0159", i: 8, s: 9, ss: 11 },
//     "0160": { id: "0160", i: 2, s: 2, ss: 5 },
//     "0162": { id: "0162", i: 6, s: 22, ss: 59 },
//     "0163": { id: "0163", i: 2, s: 2, ss: 5 },
//     "0164": { id: "0164", i: 6, s: 6, ss: 44 },
//     "0165": { id: "0165", i: 3, s: 20, ss: 32 },
//     "0166": { id: "0166", i: 8, s: 9, ss: 60 },
//     "0167": { id: "0167", i: 6, s: 13, ss: 19 },
//     "0168": { id: "0168", i: 9, s: 10, ss: 61 },
//     "0169": { id: "0169", i: 6, s: 8, ss: 10 },
//     "0171": { id: "0171", i: 2, s: 2, ss: 5 },
//     "0172": { id: "0172", i: 3, s: 20, ss: 38 },
//     "0173": { id: "0173", i: 2, s: 2, ss: 3 },
//     "0174": { id: "0174", i: 3, s: 20, ss: 32 },
//     "0175": { id: "0175", i: 6, s: 16, ss: 62 },
//     "0176": { id: "0176", i: 6, s: 13, ss: 19 },
//     "0177": { id: "0177", i: 10, s: 25, ss: 46 },
//     "0178": { id: "0178", i: 6, s: 22, ss: 36 },
//     "0179": { id: "0179", i: 6, s: 16, ss: 24 },
//     "0180": { id: "0180", i: 6, s: 13, ss: 16 },
//     "0181": { id: "0181", i: 6, s: 8, ss: 10 },
//     "0182": { id: "0182", i: 1, s: 1, ss: 39 },
//     "0183": { id: "0183", i: 2, s: 2, ss: 3 },
//     "0184": { id: "0184", i: 6, s: 8, ss: 10 },
//     "0185": { id: "0185", i: 2, s: 2, ss: 3 },
//     "0186": { id: "0186", i: 6, s: 13, ss: 19 },
//     "0187": { id: "0187", i: 10, s: 11, ss: 20 },
//     "0188": { id: "0188", i: 3, s: 20, ss: 63 },
//     "0189": { id: "0189", i: 8, s: 21, ss: 31 },
//     "0190": { id: "0190", i: 2, s: 2, ss: 3 },
//     "0191": { id: "0191", i: 0, s: 0, ss: 0 },
//     "0193": { id: "0193", i: 2, s: 2, ss: 3 },
//     "0194": { id: "0194", i: 2, s: 2, ss: 5 },
//     "0195": { id: "0195", i: 8, s: 9, ss: 60 },
//     "0196": { id: "0196", i: 11, s: 24, ss: 64 },
//     "0197": { id: "0197", i: 9, s: 10, ss: 27 },
//     "0199": { id: "0199", i: 2, s: 2, ss: 3 },
//     "0200": { id: "0200", i: 6, s: 8, ss: 12 },
//     "0201": { id: "0201", i: 6, s: 8, ss: 10 },
//     "0202": { id: "0202", i: 0, s: 0, ss: 0 },
//     "0204": { id: "0204", i: 3, s: 20, ss: 32 },
//     "0205": { id: "0205", i: 6, s: 6, ss: 44 },
//     "0206": { id: "0206", i: 11, s: 24, ss: 64 },
//     "0207": { id: "0207", i: 2, s: 2, ss: 5 },
//     "0208": { id: "0208", i: 2, s: 2, ss: 3 },
//     "0209": { id: "0209", i: 6, s: 13, ss: 16 },
//     "0210": { id: "0210", i: 6, s: 27, ss: 65 },
//     "0211": { id: "0211", i: 3, s: 20, ss: 30 },
//     "0212": { id: "0212", i: 2, s: 2, ss: 5 },
//     "0213": { id: "0213", i: 10, s: 11, ss: 20 },
//     "0214": { id: "0214", i: 3, s: 20, ss: 32 },
//     "0215": { id: "0215", i: 4, s: 4, ss: 6 },
//     "0216": { id: "0216", i: 2, s: 2, ss: 3 },
//     "0217": { id: "0217", i: 8, s: 9, ss: 60 },
//     "0218": { id: "0218", i: 3, s: 20, ss: 30 },
//     "0219": { id: "0219", i: 6, s: 8, ss: 10 },
//     "0220": { id: "0220", i: 9, s: 10, ss: 66 },
//     "0222": { id: "0222", i: 3, s: 28, ss: 67 },
//     "0223": { id: "0223", i: 7, s: 7, ss: 52 },
//     "0224": { id: "0224", i: 2, s: 2, ss: 5 },
//     "0225": { id: "0225", i: 2, s: 2, ss: 5 },
//     "0226": { id: "0226", i: 6, s: 8, ss: 23 },
//     "0227": { id: "0227", i: 3, s: 20, ss: 30 },
//     "0228": { id: "0228", i: 11, s: 24, ss: 40 },
//     "0229": { id: "0229", i: 6, s: 13, ss: 68 },
//     "0230": { id: "0230", i: 2, s: 2, ss: 3 },
//     "0231": { id: "0231", i: 3, s: 20, ss: 30 },
//     "0232": { id: "0232", i: 10, s: 11, ss: 14 },
//     "0234": { id: "0234", i: 6, s: 8, ss: 33 },
//     "0235": { id: "0235", i: 8, s: 9, ss: 60 },
//     "0236": { id: "0236", i: 9, s: 10, ss: 61 },
//     "0237": { id: "0237", i: 2, s: 2, ss: 5 },
//     "0238": { id: "0238", i: 6, s: 27, ss: 50 },
//     "0239": { id: "0239", i: 5, s: 5, ss: 7 },
//     "0240": { id: "0240", i: 2, s: 18, ss: 69 },
//     "0241": { id: "0241", i: 5, s: 5, ss: 7 },
//     "0242": { id: "0242", i: 0, s: 0, ss: 0 },
//     "0243": { id: "0243", i: 7, s: 23, ss: 37 },
//     "0244": { id: "0244", i: 6, s: 22, ss: 59 },
//     "0245": { id: "0245", i: 3, s: 20, ss: 30 },
//     "0247": { id: "0247", i: 2, s: 2, ss: 5 },
//     "0248": { id: "0248", i: 7, s: 26, ss: 48 },
//     "0250": { id: "0250", i: 7, s: 7, ss: 22 },
//     "0251": { id: "0251", i: 2, s: 2, ss: 5 },
//     "0252": { id: "0252", i: 10, s: 17, ss: 25 },
//     "0253": { id: "0253", i: 6, s: 8, ss: 10 },
//     "0254": { id: "0254", i: 6, s: 8, ss: 33 },
//     "0255": { id: "0255", i: 10, s: 11, ss: 20 },
//     "0256": { id: "0256", i: 6, s: 27, ss: 70 },
//     "0257": { id: "0257", i: 1, s: 1, ss: 39 },
//     "0258": { id: "0258", i: 2, s: 2, ss: 3 },
//     "0259": { id: "0259", i: 6, s: 13, ss: 19 },
//     "0260": { id: "0260", i: 3, s: 20, ss: 38 },
//     "0261": { id: "0261", i: 2, s: 2, ss: 3 },
//     "0262": { id: "0262", i: 2, s: 2, ss: 3 },
//     "0263": { id: "0263", i: 3, s: 20, ss: 30 },
//     "0264": { id: "0264", i: 6, s: 27, ss: 49 },
//     "0265": { id: "0265", i: 6, s: 8, ss: 33 },
//     "0266": { id: "0266", i: 2, s: 2, ss: 5 },
//     "0267": { id: "0267", i: 0, s: 0, ss: 0 },
//     "0268": { id: "0268", i: 7, s: 7, ss: 9 },
//     "0269": { id: "0269", i: 10, s: 25, ss: 46 },
//     "0270": { id: "0270", i: 1, s: 1, ss: 71 },
//     "0271": { id: "0271", i: 2, s: 2, ss: 5 },
//     "0272": { id: "0272", i: 2, s: 2, ss: 3 },
//     "0273": { id: "0273", i: 3, s: 20, ss: 32 },
//     "0274": { id: "0274", i: 8, s: 29, ss: 72 },
//     "0276": { id: "0276", i: 11, s: 19, ss: 28 },
//     "0277": { id: "0277", i: 2, s: 2, ss: 5 },
//     "0278": { id: "0278", i: 2, s: 2, ss: 5 },
//     "0279": { id: "0279", i: 3, s: 20, ss: 30 },
//     "0280": { id: "0280", i: 6, s: 27, ss: 70 },
//     "0281": { id: "0281", i: 2, s: 2, ss: 3 },
//     "0282": { id: "0282", i: 6, s: 6, ss: 8 },
//     "0285": { id: "0285", i: 7, s: 26, ss: 48 },
//     "0286": { id: "0286", i: 5, s: 30, ss: 73 },
//     "0287": { id: "0287", i: 2, s: 2, ss: 5 },
//     "0288": { id: "0288", i: 9, s: 15, ss: 74 },
//     "0289": { id: "0289", i: 6, s: 22, ss: 59 },
//     "0290": { id: "0290", i: 3, s: 20, ss: 30 },
//     "0291": { id: "0291", i: 9, s: 10, ss: 61 },
//     "0292": { id: "0292", i: 6, s: 8, ss: 10 },
//     "0293": { id: "0293", i: 6, s: 8, ss: 75 },
//     "0294": { id: "0294", i: 6, s: 27, ss: 50 },
//     "0295": { id: "0295", i: 1, s: 1, ss: 39 },
//     "0296": { id: "0296", i: 6, s: 8, ss: 12 },
//     "0297": { id: "0297", i: 8, s: 21, ss: 76 },
//     "0298": { id: "0298", i: 2, s: 2, ss: 3 },
//     "0299": { id: "0299", i: 2, s: 2, ss: 3 },
//     "0301": { id: "0301", i: 8, s: 21, ss: 31 },
//     "0302": { id: "0302", i: 7, s: 7, ss: 9 },
//     "0303": { id: "0303", i: 7, s: 26, ss: 48 },
//     "0305": { id: "0305", i: 6, s: 16, ss: 24 },
//     "0306": { id: "0306", i: 6, s: 8, ss: 29 },
//     "0307": { id: "0307", i: 11, s: 19, ss: 28 },
//     "0308": { id: "0308", i: 6, s: 8, ss: 33 },
//     "0309": { id: "0309", i: 6, s: 12, ss: 35 },
//     "0310": { id: "0310", i: 3, s: 20, ss: 32 },
//     "0311": { id: "0311", i: 6, s: 27, ss: 50 },
//     "0312": { id: "0312", i: 6, s: 22, ss: 59 },
//     "0313": { id: "0313", i: 2, s: 2, ss: 3 },
//     "0315": { id: "0315", i: 4, s: 4, ss: 6 },
//     "0316": { id: "0316", i: 10, s: 25, ss: 53 },
//     "0317": { id: "0317", i: 10, s: 11, ss: 45 },
//     "0318": { id: "0318", i: 0, s: 0, ss: 0 },
//     "0320": { id: "0320", i: 10, s: 11, ss: 20 },
//     "0321": { id: "0321", i: 6, s: 27, ss: 55 },
//     "0322": { id: "0322", i: 9, s: 10, ss: 66 },
//     "0323": { id: "0323", i: 8, s: 9, ss: 11 },
//     "0326": { id: "0326", i: 6, s: 6, ss: 77 },
//     "0327": { id: "0327", i: 7, s: 7, ss: 52 },
//     "0328": { id: "0328", i: 6, s: 13, ss: 19 },
//     "0329": { id: "0329", i: 3, s: 20, ss: 30 },
//     "0330": { id: "0330", i: 6, s: 27, ss: 50 },
//     "0331": { id: "0331", i: 2, s: 18, ss: 56 },
//     "0332": { id: "0332", i: 11, s: 24, ss: 40 },
//     "0333": { id: "0333", i: 6, s: 27, ss: 50 },
//     "0334": { id: "0334", i: 7, s: 26, ss: 78 },
//     "0335": { id: "0335", i: 3, s: 20, ss: 38 },
//     "0336": { id: "0336", i: 9, s: 10, ss: 13 },
//     "0337": { id: "0337", i: 2, s: 2, ss: 3 },
//     "0338": { id: "0338", i: 8, s: 21, ss: 31 },
//     "0339": { id: "0339", i: 3, s: 20, ss: 32 },
//     "0340": { id: "0340", i: 8, s: 29, ss: 72 },
//     "0341": { id: "0341", i: 6, s: 8, ss: 23 },
//     "0342": { id: "0342", i: 11, s: 24, ss: 40 },
//     "0343": { id: "0343", i: 6, s: 6, ss: 8 },
//     "0345": { id: "0345", i: 9, s: 10, ss: 66 },
//     "0346": { id: "0346", i: 11, s: 24, ss: 40 },
//     "0347": { id: "0347", i: 8, s: 9, ss: 11 },
//     "0348": { id: "0348", i: 5, s: 5, ss: 79 },
//     "0351": { id: "0351", i: 10, s: 25, ss: 53 },
//     "0352": { id: "0352", i: 2, s: 2, ss: 80 },
//     "0353": { id: "0353", i: 11, s: 24, ss: 64 },
//     "0354": { id: "0354", i: 7, s: 7, ss: 22 },
//     "0355": { id: "0355", i: 6, s: 8, ss: 10 },
//     "0356": { id: "0356", i: 3, s: 20, ss: 32 },
//     "0357": { id: "0357", i: 6, s: 8, ss: 75 },
//     "0358": { id: "0358", i: 8, s: 9, ss: 81 },
//     "0359": { id: "0359", i: 9, s: 10, ss: 66 },
//     "0360": { id: "0360", i: 6, s: 16, ss: 24 },
//     "0361": { id: "0361", i: 6, s: 13, ss: 16 },
//     "0362": { id: "0362", i: 8, s: 21, ss: 31 },
//     "0363": { id: "0363", i: 0, s: 0, ss: 0 },
//     "0364": { id: "0364", i: 9, s: 10, ss: 66 },
//     "0365": { id: "0365", i: 10, s: 11, ss: 20 },
//     "0366": { id: "0366", i: 2, s: 18, ss: 26 },
//     "0367": { id: "0367", i: 2, s: 2, ss: 3 },
//     "0368": { id: "0368", i: 2, s: 18, ss: 56 },
//     "0369": { id: "0369", i: 2, s: 2, ss: 3 },
//     "0370": { id: "0370", i: 6, s: 13, ss: 19 },
//     "0371": { id: "0371", i: 1, s: 1, ss: 71 },
//     "0372": { id: "0372", i: 3, s: 20, ss: 32 },
//     "0373": { id: "0373", i: 3, s: 20, ss: 38 },
//     "0374": { id: "0374", i: 9, s: 10, ss: 27 },
//     "0375": { id: "0375", i: 6, s: 27, ss: 50 },
//     "0376": { id: "0376", i: 3, s: 28, ss: 67 },
//     "0377": { id: "0377", i: 10, s: 17, ss: 82 },
//     "0378": { id: "0378", i: 8, s: 21, ss: 31 },
//     "0379": { id: "0379", i: 3, s: 20, ss: 38 },
//     "0380": { id: "0380", i: 2, s: 18, ss: 26 },
//     "0381": { id: "0381", i: 6, s: 13, ss: 16 },
//     "0382": { id: "0382", i: 6, s: 12, ss: 15 },
//     "0383": { id: "0383", i: 5, s: 30, ss: 73 },
//     "0384": { id: "0384", i: 1, s: 1, ss: 2 },
//     "0385": { id: "0385", i: 2, s: 18, ss: 56 },
//     "0386": { id: "0386", i: 11, s: 24, ss: 40 },
//     "0387": { id: "0387", i: 10, s: 11, ss: 20 },
//     "0388": { id: "0388", i: 3, s: 20, ss: 63 },
//     "0389": { id: "0389", i: 9, s: 10, ss: 61 },
//     "0390": { id: "0390", i: 2, s: 18, ss: 69 },
//     "0391": { id: "0391", i: 6, s: 6, ss: 77 },
//     "0392": { id: "0392", i: 1, s: 1, ss: 2 },
//     "0393": { id: "0393", i: 6, s: 27, ss: 50 },
//     "0395": { id: "0395", i: 7, s: 7, ss: 52 },
//     "0396": { id: "0396", i: 6, s: 13, ss: 83 },
//     "0397": { id: "0397", i: 3, s: 20, ss: 38 },
//     "0398": { id: "0398", i: 6, s: 22, ss: 36 },
//     "0399": { id: "0399", i: 9, s: 14, ss: 84 },
//     "0400": { id: "0400", i: 7, s: 7, ss: 52 },
//     "0401": { id: "0401", i: 5, s: 5, ss: 7 },
//     "0403": { id: "0403", i: 10, s: 17, ss: 25 },
//     "0405": { id: "0405", i: "", s: "", ss: 85 },
//     "0406": { id: "0406", i: 2, s: 18, ss: 56 },
//     "0408": { id: "0408", i: 8, s: 21, ss: 31 },
//     "0410": { id: "0410", i: 2, s: 2, ss: 5 },
//     "0411": { id: "0411", i: 9, s: 15, ss: 34 },
//     "0412": { id: "0412", i: 3, s: 20, ss: 38 },
//     "0413": { id: "0413", i: 6, s: 13, ss: 16 },
//     "0416": { id: "0416", i: 3, s: 3, ss: 4 },
//     "0417": { id: "0417", i: 6, s: 22, ss: 36 },
//     "0418": { id: "0418", i: 7, s: 7, ss: 9 },
//     "0419": { id: "0419", i: 6, s: 8, ss: 86 },
//     "0420": { id: "0420", i: 6, s: 27, ss: 55 },
//     "0422": { id: "0422", i: 6, s: 16, ss: 62 },
//     "0423": { id: "0423", i: 6, s: 6, ss: 8 },
//     "0425": { id: "0425", i: 6, s: 16, ss: 24 },
//     "0426": { id: "0426", i: 6, s: 6, ss: 8 },
//     "0428": { id: "0428", i: 3, s: 20, ss: 32 },
//     "0430": { id: "0430", i: 3, s: 20, ss: 32 },
//     "0431": { id: "0431", i: 3, s: 20, ss: 38 },
//     "0432": { id: "0432", i: 2, s: 2, ss: 3 },
//     "0433": { id: "0433", i: 8, s: 9, ss: 60 },
//     "0434": { id: "0434", i: 7, s: 7, ss: 9 },
//     "0435": { id: "0435", i: "", s: "", ss: 85 },
//     "0436": { id: "0436", i: 10, s: 11, ss: 57 },
//     "0438": { id: "0438", i: 10, s: 11, ss: 58 },
//     "0439": { id: "0439", i: 10, s: 11, ss: 43 },
//     "0440": { id: "0440", i: 3, s: 3, ss: 4 },
//     "0442": { id: "0442", i: 6, s: 27, ss: 70 },
//     "0444": { id: "0444", i: 6, s: 22, ss: 36 },
//     "0449": { id: "0449", i: 6, s: 13, ss: 68 },
//     "0450": { id: "0450", i: 10, s: 17, ss: 25 },
//     "0451": { id: "0451", i: 1, s: 1, ss: 39 },
//     "0455": { id: "0455", i: 5, s: 5, ss: 7 },
//     "0456": { id: "0456", i: 2, s: 2, ss: 5 },
//     "0458": { id: "0458", i: 6, s: 27, ss: 50 },
//     "0459": { id: "0459", i: 2, s: 2, ss: 80 },
//     "0460": { id: "0460", i: 5, s: 5, ss: 7 },
//     "0462": { id: "0462", i: 9, s: 10, ss: 87 },
//     "0464": { id: "0464", i: 6, s: 13, ss: 68 },
//     "0465": { id: "0465", i: 7, s: 7, ss: 22 },
//     "0467": { id: "0467", i: 11, s: 24, ss: 40 },
//     "0468": { id: "0468", i: 10, s: 17, ss: 25 },
//     "0471": { id: "0471", i: 4, s: 4, ss: 88 },
//     "0472": { id: "0472", i: 9, s: 10, ss: 61 },
//     "0474": { id: "0474", i: 10, s: 11, ss: 17 },
//     "0475": { id: "0475", i: 10, s: 11, ss: 58 },
//     "0476": { id: "0476", i: 10, s: 11, ss: 45 },
//     "0479": { id: "0479", i: 6, s: 16, ss: 62 },
//     "0480": { id: "0480", i: 2, s: 2, ss: 3 },
//     "0482": { id: "0482", i: 6, s: 13, ss: 19 },
//     "0483": { id: "0483", i: 6, s: 22, ss: 51 },
//     "0484": { id: "0484", i: 7, s: 7, ss: 9 },
//     "0485": { id: "0485", i: 6, s: 13, ss: 19 },
//     "0486": { id: "0486", i: 8, s: 9, ss: 42 },
//     "0487": { id: "0487", i: 6, s: 8, ss: 33 },
//     "0488": { id: "0488", i: 0, s: 0, ss: 0 },
//     "0489": { id: "0489", i: 10, s: 11, ss: 45 },
//     "0491": { id: "0491", i: 6, s: 6, ss: 77 },
//     "0493": { id: "0493", i: 6, s: 22, ss: 89 },
//     "0495": { id: "0495", i: 2, s: 2, ss: 5 },
//     "0496": { id: "0496", i: 6, s: 13, ss: 83 },
//     "0497": { id: "0497", i: 2, s: 2, ss: 3 },
//     "0498": { id: "0498", i: 2, s: 18, ss: 56 },
//     "0499": { id: "0499", i: 6, s: 12, ss: 15 },
//     "0500": { id: "0500", i: 10, s: 25, ss: 90 },
//     "0503": { id: "0503", i: 5, s: 5, ss: 7 },
//     "0505": { id: "0505", i: 8, s: 9, ss: 81 },
//     "0506": { id: "0506", i: 9, s: 10, ss: 66 },
//     "0508": { id: "0508", i: 2, s: 2, ss: 3 },
//     "0509": { id: "0509", i: 8, s: 21, ss: 76 },
//     "0510": { id: "0510", i: 3, s: 20, ss: 30 },
//     "0511": { id: "0511", i: 6, s: 6, ss: 91 },
//     "0512": { id: "0512", i: 5, s: 5, ss: 7 },
//     "0513": { id: "0513", i: 6, s: 27, ss: 70 },
//     "0515": { id: "0515", i: 10, s: 11, ss: 20 },
//     "0517": { id: "0517", i: 10, s: 17, ss: 82 },
//     "0518": { id: "0518", i: 6, s: 27, ss: 50 },
//     "0519": { id: "0519", i: 2, s: 2, ss: 3 },
//     "0520": { id: "0520", i: 6, s: 8, ss: 23 },
//     "0521": { id: "0521", i: 8, s: 9, ss: 60 },
//     "0522": { id: "0522", i: 7, s: 23, ss: 37 },
//     "0524": { id: "0524", i: 4, s: 4, ss: 6 },
//     "0525": { id: "0525", i: 6, s: 8, ss: 29 },
//     "0526": { id: "0526", i: 6, s: 22, ss: 54 },
//     "0527": { id: "0527", i: 1, s: 1, ss: 39 },
//     "0528": { id: "0528", i: 6, s: 27, ss: 55 },
//     "0529": { id: "0529", i: 7, s: 26, ss: 48 },
//     "0530": { id: "0530", i: 0, s: 0, ss: 0 },
//     "0531": { id: "0531", i: 6, s: 13, ss: 83 },
//     "0532": { id: "0532", i: 10, s: 11, ss: 20 },
//     "0533": { id: "0533", i: 6, s: 27, ss: 50 },
//     "0535": { id: "0535", i: 2, s: 2, ss: 3 },
//     "0536": { id: "0536", i: 7, s: 7, ss: 52 },
//     "0538": { id: "0538", i: 6, s: 8, ss: 23 },
//     "0539": { id: "0539", i: 6, s: 27, ss: 55 },
//     "0540": { id: "0540", i: 6, s: 27, ss: 50 },
//     "0542": { id: "0542", i: 6, s: 8, ss: 10 },
//     "0543": { id: "0543", i: 6, s: 22, ss: 54 },
//     "0544": { id: "0544", i: 10, s: 25, ss: 90 },
//     "0546": { id: "0546", i: 9, s: 10, ss: 13 },
//     "0547": { id: "0547", i: 6, s: 6, ss: 77 },
//     "0548": { id: "0548", i: 10, s: 25, ss: 46 },
//     "0550": { id: "0550", i: 6, s: 6, ss: 44 },
//     "0551": { id: "0551", i: 6, s: 27, ss: 65 },
//     "0552": { id: "0552", i: 7, s: 26, ss: 48 },
//     "0553": { id: "0553", i: 7, s: 26, ss: 48 },
//     "0554": { id: "0554", i: 11, s: 24, ss: 64 },
//     "0555": { id: "0555", i: 6, s: 8, ss: 12 },
//     "0556": { id: "0556", i: 10, s: 11, ss: 57 },
//     "0557": { id: "0557", i: 5, s: 30, ss: 73 },
//     "0558": { id: "0558", i: 10, s: 11, ss: 20 },
//     "0559": { id: "0559", i: 6, s: 8, ss: 10 },
//     "0560": { id: "0560", i: 10, s: 25, ss: 53 },
//     "0563": { id: "0563", i: 2, s: 2, ss: 3 },
//     "0564": { id: "0564", i: 6, s: 16, ss: 24 },
//     "0565": { id: "0565", i: 2, s: 2, ss: 5 },
//     "0567": { id: "0567", i: 11, s: 24, ss: 40 },
//     "0568": { id: "0568", i: 11, s: 24, ss: 64 },
//     "0570": { id: "0570", i: 5, s: 5, ss: 79 },
//     "0571": { id: "0571", i: 6, s: 6, ss: 77 },
//     "0572": { id: "0572", i: 3, s: 20, ss: 32 },
//     "0573": { id: "0573", i: 6, s: 8, ss: 23 },
//     "0574": { id: "0574", i: 5, s: 5, ss: 7 },
//     "0575": { id: "0575", i: 5, s: 5, ss: 7 },
//     "0576": { id: "0576", i: 10, s: 25, ss: 46 },
//     "0577": { id: "0577", i: 2, s: 18, ss: 56 },
//     "0578": { id: "0578", i: 11, s: 19, ss: 28 },
//     "0579": { id: "0579", i: 1, s: 1, ss: 1 },
//     "0580": { id: "0580", i: 10, s: 11, ss: 20 },
//     "0581": { id: "0581", i: 8, s: 9, ss: 11 },
//     "0582": { id: "0582", i: 6, s: 8, ss: 10 },
//     "0583": { id: "0583", i: 2, s: 2, ss: 5 },
//     "0585": { id: "0585", i: 3, s: 20, ss: 30 },
//     "0586": { id: "0586", i: 10, s: 11, ss: 57 },
//     "0588": { id: "0588", i: 2, s: 2, ss: 3 },
//     "0589": { id: "0589", i: 2, s: 18, ss: 69 },
//     "0590": { id: "0590", i: 6, s: 22, ss: 36 },
//     "0591": { id: "0591", i: 10, s: 11, ss: 20 },
//     "0592": { id: "0592", i: 6, s: 27, ss: 50 },
//     "0593": { id: "0593", i: 2, s: 2, ss: 3 },
//     "0595": { id: "0595", i: 7, s: 23, ss: 37 },
//     "0596": { id: "0596", i: 7, s: 7, ss: 9 },
//     "0598": { id: "0598", i: 10, s: 25, ss: 53 },
//     "0599": { id: "0599", i: 2, s: 18, ss: 56 },
//     "0600": { id: "0600", i: 11, s: 24, ss: 64 },
//     "0601": { id: "0601", i: 8, s: 9, ss: 60 },
//     "0602": { id: "0602", i: 6, s: 22, ss: 59 },
//     "0603": { id: "0603", i: 1, s: 1, ss: 2 },
//     "0604": { id: "0604", i: 2, s: 2, ss: 3 },
//     "0605": { id: "0605", i: 3, s: 20, ss: 38 },
//     "0606": { id: "0606", i: 2, s: 2, ss: 47 },
//     "0607": { id: "0607", i: 10, s: 11, ss: 57 },
//     "0608": { id: "0608", i: 6, s: 27, ss: 50 },
//     "0609": { id: "0609", i: 8, s: 21, ss: 31 },
//     "0610": { id: "0610", i: 2, s: 18, ss: 69 },
//     "0611": { id: "0611", i: 1, s: 1, ss: 39 },
//     "0612": { id: "0612", i: 3, s: 20, ss: 32 },
//     "0613": { id: "0613", i: 3, s: 20, ss: 32 },
//     "0616": { id: "0616", i: 2, s: 2, ss: 5 },
//     "0617": { id: "0617", i: 6, s: 8, ss: 10 },
//     "0618": { id: "0618", i: 2, s: 2, ss: 3 },
//     "0619": { id: "0619", i: 3, s: 20, ss: 30 },
//     "0620": { id: "0620", i: 0, s: 0, ss: 0 },
//     "0621": { id: "0621", i: 8, s: 29, ss: 72 },
//     "0622": { id: "0622", i: 3, s: 20, ss: 30 },
//     "0623": { id: "0623", i: 6, s: 6, ss: 44 },
//     "0625": { id: "0625", i: "", s: "", ss: 85 },
//     "0626": { id: "0626", i: 3, s: 3, ss: 4 },
//     "0627": { id: "0627", i: 2, s: 2, ss: 3 },
//     "0628": { id: "0628", i: 3, s: 20, ss: 38 },
//     "0629": { id: "0629", i: 3, s: 20, ss: 38 },
//     "0630": { id: "0630", i: 2, s: 18, ss: 56 },
//     "0631": { id: "0631", i: 10, s: 11, ss: 17 },
//     "0632": { id: "0632", i: 11, s: 24, ss: 40 },
//     "0633": { id: "0633", i: 4, s: 4, ss: 88 },
//     "0635": { id: "0635", i: 6, s: 13, ss: 16 },
//     "0636": { id: "0636", i: 10, s: 25, ss: 90 },
//     "0637": { id: "0637", i: 8, s: 9, ss: 60 },
//     "0638": { id: "0638", i: 6, s: 13, ss: 68 },
//     "0639": { id: "0639", i: 11, s: 19, ss: 28 },
//     "0640": { id: "0640", i: 8, s: 21, ss: 31 },
//     "0641": { id: "0641", i: 10, s: 11, ss: 20 },
//     "0643": { id: "0643", i: 6, s: 27, ss: 50 },
//     "0645": { id: "0645", i: 11, s: 19, ss: 28 },
//     "0646": { id: "0646", i: 1, s: 1, ss: 71 },
//     "0648": { id: "0648", i: 5, s: 30, ss: 92 },
//     "0650": { id: "0650", i: 11, s: 24, ss: 40 },
//     "0651": { id: "0651", i: 10, s: 11, ss: 17 },
//     "0653": { id: "0653", i: 9, s: 14, ss: 84 },
//     "0655": { id: "0655", i: 2, s: 2, ss: 5 },
//     "0656": { id: "0656", i: 0, s: 0, ss: 0 },
//     "0657": { id: "0657", i: 6, s: 8, ss: 23 },
//     "0658": { id: "0658", i: 10, s: 11, ss: 57 },
//     "0659": { id: "0659", i: 0, s: 0, ss: 0 },
//     "0660": { id: "0660", i: 8, s: 21, ss: 31 },
//     "0661": { id: "0661", i: 8, s: 9, ss: 81 },
//     "0662": { id: "0662", i: 3, s: 28, ss: 67 },
//     "0663": { id: "0663", i: 0, s: 0, ss: 0 },
//     "0665": { id: "0665", i: 3, s: 20, ss: 30 },
//     "0666": { id: "0666", i: 3, s: 20, ss: 32 },
//     "0667": { id: "0667", i: 6, s: 12, ss: 15 },
//     "0668": { id: "0668", i: 3, s: 20, ss: 38 },
//     "0669": { id: "0669", i: 6, s: 13, ss: 68 },
//     "0670": { id: "0670", i: 6, s: 8, ss: 75 },
//     "0672": { id: "0672", i: 2, s: 2, ss: 3 },
//     "0673": { id: "0673", i: 5, s: 30, ss: 92 },
//     "0674": { id: "0674", i: 2, s: 2, ss: 5 },
//     "0675": { id: "0675", i: 10, s: 11, ss: 20 },
//     "0676": { id: "0676", i: 6, s: 27, ss: 65 },
//     "0677": { id: "0677", i: 9, s: 15, ss: 34 },
//     "0678": { id: "0678", i: 6, s: 8, ss: 33 },
//     "0679": { id: "0679", i: 10, s: 11, ss: 20 },
//     "0680": { id: "0680", i: 2, s: 2, ss: 3 },
//     "0681": { id: "0681", i: 1, s: 1, ss: 2 },
//     "0682": { id: "0682", i: 9, s: 15, ss: 34 },
//     "0683": { id: "0683", i: 2, s: 2, ss: 3 },
//     "0684": { id: "0684", i: 6, s: 13, ss: 68 },
//     "0685": { id: "0685", i: 6, s: 6, ss: 8 },
//     "0686": { id: "0686", i: 1, s: 1, ss: 39 },
//     "0687": { id: "0687", i: 2, s: 18, ss: 69 },
//     "0688": { id: "0688", i: 2, s: 2, ss: 3 },
//     "0689": { id: "0689", i: 3, s: 20, ss: 38 },
//     "0690": { id: "0690", i: 5, s: 5, ss: 7 },
//     "0691": { id: "0691", i: 2, s: 18, ss: 26 },
//     "0693": { id: "0693", i: 6, s: 22, ss: 54 },
//     "0694": { id: "0694", i: 6, s: 8, ss: 75 },
//     "0695": { id: "0695", i: 2, s: 18, ss: 26 },
//     "0696": { id: "0696", i: 7, s: 7, ss: 22 },
//     "0697": { id: "0697", i: 3, s: 20, ss: 32 },
//     "0698": { id: "0698", i: 10, s: 11, ss: 43 },
//     "0699": { id: "0699", i: 6, s: 8, ss: 33 },
//     "0700": { id: "0700", i: 7, s: 7, ss: 52 },
//     "0701": { id: "0701", i: 2, s: 18, ss: 26 },
//     "0702": { id: "0702", i: 11, s: 24, ss: 40 },
//     "0703": { id: "0703", i: 6, s: 8, ss: 23 },
//     "0704": { id: "0704", i: 11, s: 19, ss: 28 },
//     "0706": { id: "0706", i: 8, s: 21, ss: 41 },
//     "0707": { id: "0707", i: 6, s: 27, ss: 55 },
//     "0708": { id: "0708", i: 5, s: 30, ss: 73 },
//     "0709": { id: "0709", i: 6, s: 27, ss: 50 },
//     "0710": { id: "0710", i: 7, s: 26, ss: 78 },
//     "0711": { id: "0711", i: 2, s: 18, ss: 69 },
//     "0712": { id: "0712", i: 10, s: 11, ss: 58 },
//     "0713": { id: "0713", i: 6, s: 13, ss: 83 },
//     "0715": { id: "0715", i: 2, s: 2, ss: 5 },
//     "0716": { id: "0716", i: 10, s: 11, ss: 45 },
//     "0717": { id: "0717", i: 3, s: 20, ss: 30 },
//     "0718": { id: "0718", i: 5, s: 30, ss: 73 },
//     "0719": { id: "0719", i: 5, s: 5, ss: 7 },
//     "0720": { id: "0720", i: 6, s: 16, ss: 62 },
//     "0721": { id: "0721", i: 3, s: 20, ss: 32 },
//     "0722": { id: "0722", i: 5, s: 30, ss: 73 },
//     "0723": { id: "0723", i: 8, s: 21, ss: 41 },
//     "0724": { id: "0724", i: 7, s: 23, ss: 37 },
//     "0725": { id: "0725", i: 10, s: 11, ss: 20 },
//     "0726": { id: "0726", i: 2, s: 2, ss: 3 },
//     "0727": { id: "0727", i: 2, s: 2, ss: 5 },
//     "0728": { id: "0728", i: 4, s: 4, ss: 6 },
//     "0729": { id: "0729", i: 10, s: 11, ss: 45 },
//     "0730": { id: "0730", i: 2, s: 2, ss: 5 },
//     "0731": { id: "0731", i: 8, s: 21, ss: 93 },
//     "0732": { id: "0732", i: 10, s: 11, ss: 43 },
//     "0733": { id: "0733", i: 2, s: 2, ss: 80 },
//     "0736": { id: "0736", i: 3, s: 20, ss: 38 },
//     "0737": { id: "0737", i: 10, s: 25, ss: 46 },
//     "0738": { id: "0738", i: 6, s: 27, ss: 65 },
//     "0743": { id: "0743", i: 2, s: 18, ss: 26 },
//     "0745": { id: "0745", i: 6, s: 6, ss: 44 },
//     "0746": { id: "0746", i: 8, s: 21, ss: 31 },
//     "0747": { id: "0747", i: 2, s: 18, ss: 56 },
//     "0750": { id: "0750", i: 2, s: 18, ss: 56 },
//     "0751": { id: "0751", i: 6, s: 13, ss: 19 },
//     "0752": { id: "0752", i: 6, s: 6, ss: 44 },
//     "0753": { id: "0753", i: 6, s: 8, ss: 75 },
//     "0754": { id: "0754", i: 2, s: 2, ss: 3 },
//     "0755": { id: "0755", i: 2, s: 2, ss: 3 },
//     "0756": { id: "0756", i: 9, s: 10, ss: 66 },
//     "0757": { id: "0757", i: 10, s: 11, ss: 58 },
//     "0758": { id: "0758", i: 8, s: 9, ss: 60 },
//     "0759": { id: "0759", i: 9, s: 14, ss: 18 },
//     "0760": { id: "0760", i: 2, s: 2, ss: 3 },
//     "0762": { id: "0762", i: 4, s: 4, ss: 6 },
//     "0763": { id: "0763", i: 7, s: 26, ss: 48 },
//     "0764": { id: "0764", i: 0, s: 0, ss: 0 },
//     "0765": { id: "0765", i: 6, s: 13, ss: 16 },
//     "0766": { id: "0766", i: 3, s: 20, ss: 38 },
//     "0767": { id: "0767", i: 3, s: 20, ss: 38 },
//     "0768": { id: "0768", i: 3, s: 20, ss: 32 },
//     "0769": { id: "0769", i: 8, s: 9, ss: 60 },
//     "0770": { id: "0770", i: 3, s: 20, ss: 32 },
//     "0771": { id: "0771", i: 7, s: 7, ss: 22 },
//     "0772": { id: "0772", i: 6, s: 6, ss: 8 },
//     "0775": { id: "0775", i: 5, s: 5, ss: 7 },
//     "0776": { id: "0776", i: 6, s: 13, ss: 83 },
//     "0777": { id: "0777", i: 7, s: 7, ss: 9 },
//     "0778": { id: "0778", i: "", s: "", ss: 85 },
//     "0780": { id: "0780", i: 6, s: 8, ss: 33 },
//     "0784": { id: "0784", i: 2, s: 18, ss: 56 },
//     "0787": { id: "0787", i: 6, s: 27, ss: 50 },
//     "0788": { id: "0788", i: 4, s: 4, ss: 88 },
//     "0789": { id: "0789", i: 6, s: 27, ss: 70 },
//     "0794": { id: "0794", i: 10, s: 17, ss: 25 },
//     "0797": { id: "0797", i: 7, s: 7, ss: 9 },
//     "0798": { id: "0798", i: 2, s: 2, ss: 47 },
//     "0799": { id: "0799", i: 7, s: 7, ss: 9 },
//     "0800": { id: "0800", i: 2, s: 2, ss: 5 },
//     "0802": { id: "0802", i: 7, s: 7, ss: 9 },
//     "0803": { id: "0803", i: 8, s: 9, ss: 11 },
//     "0804": { id: "0804", i: 3, s: 20, ss: 30 },
//     "0806": { id: "0806", i: 3, s: 20, ss: 32 },
//     "0807": { id: "0807", i: 1, s: 1, ss: 71 },
//     "0808": { id: "0808", i: "", s: "", ss: 85 },
//     "0809": { id: "0809", i: 8, s: 21, ss: 76 },
//     "0810": { id: "0810", i: 3, s: 20, ss: 32 },
//     "0811": { id: "0811", i: 6, s: 6, ss: 8 },
//     "0812": { id: "0812", i: 3, s: 20, ss: 32 },
//     "0813": { id: "0813", i: 2, s: 2, ss: 3 },
//     "0814": { id: "0814", i: 9, s: 14, ss: 18 },
//     "0815": { id: "0815", i: 8, s: 29, ss: 72 },
//     "0816": { id: "0816", i: 2, s: 2, ss: 47 },
//     "0817": { id: "0817", i: 2, s: 2, ss: 3 },
//     "0818": { id: "0818", i: 7, s: 7, ss: 52 },
//     "0819": { id: "0819", i: 10, s: 11, ss: 58 },
//     "0820": { id: "0820", i: "", s: "", ss: 85 },
//     "0821": { id: "0821", i: 3, s: 20, ss: 30 },
//     "0822": { id: "0822", i: 10, s: 11, ss: 20 },
//     "0823": { id: "0823", i: "", s: "", ss: 85 },
//     "0825": { id: "0825", i: 6, s: 22, ss: 59 },
//     "0826": { id: "0826", i: 8, s: 9, ss: 11 },
//     "0827": { id: "0827", i: 8, s: 21, ss: 76 },
//     "0828": { id: "0828", i: 9, s: 10, ss: 61 },
//     "0829": { id: "0829", i: 9, s: 10, ss: 27 },
//     "0830": { id: "0830", i: 2, s: 18, ss: 56 },
//     "0831": { id: "0831", i: 9, s: 14, ss: 18 },
//     "0832": { id: "0832", i: 2, s: 2, ss: 3 },
//     "0833": { id: "0833", i: 6, s: 13, ss: 68 },
//     "0834": { id: "0834", i: 9, s: 10, ss: 27 },
//     "0836": { id: "0836", i: 1, s: 1, ss: 1 },
//     "0837": { id: "0837", i: 6, s: 13, ss: 83 },
//     "0838": { id: "0838", i: 10, s: 11, ss: 20 },
//     "0839": { id: "0839", i: 6, s: 12, ss: 15 },
//     "0840": { id: "0840", i: 10, s: 11, ss: 20 },
//     "0841": { id: "0841", i: 10, s: 11, ss: 58 },
//     "0842": { id: "0842", i: 10, s: 11, ss: 58 },
//     "0844": { id: "0844", i: 6, s: 27, ss: 50 },
//     "0845": { id: "0845", i: 2, s: 2, ss: 3 },
//     "0846": { id: "0846", i: 2, s: 2, ss: 3 },
//     "0848": { id: "0848", i: 6, s: 22, ss: 59 },
//     "0850": { id: "0850", i: 10, s: 11, ss: 20 },
//     "0851": { id: "0851", i: 3, s: 20, ss: 30 },
//     "0852": { id: "0852", i: 11, s: 24, ss: 40 },
//     "0853": { id: "0853", i: 5, s: 30, ss: 92 },
//     "0854": { id: "0854", i: 10, s: 11, ss: 20 },
//     "0855": { id: "0855", i: 1, s: 1, ss: 71 },
//     "0856": { id: "0856", i: 7, s: 7, ss: 22 },
//     "0857": { id: "0857", i: 11, s: 24, ss: 40 },
//     "0858": { id: "0858", i: 5, s: 5, ss: 7 },
//     "0859": { id: "0859", i: 2, s: 2, ss: 5 },
//     "0860": { id: "0860", i: 6, s: 27, ss: 70 },
//     "0861": { id: "0861", i: 7, s: 7, ss: 22 },
//     "0862": { id: "0862", i: 7, s: 7, ss: 22 },
//     "0863": { id: "0863", i: 3, s: 20, ss: 63 },
//     "0864": { id: "0864", i: 2, s: 2, ss: 5 },
//     "0865": { id: "0865", i: 2, s: 2, ss: 3 },
//     "0866": { id: "0866", i: 11, s: 19, ss: 28 },
//     "0867": { id: "0867", i: 5, s: 5, ss: 7 },
//     "0868": { id: "0868", i: 10, s: 11, ss: 20 },
//     "0869": { id: "0869", i: 6, s: 13, ss: 16 },
//     "0871": { id: "0871", i: 2, s: 18, ss: 69 },
//     "0872": { id: "0872", i: 6, s: 16, ss: 24 },
//     "0873": { id: "0873", i: 2, s: 2, ss: 47 },
//     "0874": { id: "0874", i: 5, s: 5, ss: 79 },
//     "0875": { id: "0875", i: 9, s: 15, ss: 34 },
//     "0876": { id: "0876", i: 5, s: 30, ss: 92 },
//     "0878": { id: "0878", i: 2, s: 2, ss: 5 },
//     "0880": { id: "0880", i: 6, s: 8, ss: 12 },
//     "0881": { id: "0881", i: 6, s: 22, ss: 54 },
//     "0882": { id: "0882", i: 0, s: 0, ss: 0 },
//     "0883": { id: "0883", i: 11, s: 24, ss: 40 },
//     "0884": { id: "0884", i: 2, s: 2, ss: 3 },
//     "0885": { id: "0885", i: 7, s: 7, ss: 9 },
//     "0886": { id: "0886", i: 9, s: 10, ss: 61 },
//     "0887": { id: "0887", i: 6, s: 22, ss: 36 },
//     "0888": { id: "0888", i: 6, s: 6, ss: 91 },
//     "0889": { id: "0889", i: 10, s: 11, ss: 20 },
//     "0891": { id: "0891", i: 6, s: 22, ss: 51 },
//     "0893": { id: "0893", i: 8, s: 9, ss: 11 },
//     "0894": { id: "0894", i: 10, s: 11, ss: 20 },
//     "0895": { id: "0895", i: 10, s: 11, ss: 57 },
//     "0896": { id: "0896", i: 2, s: 18, ss: 56 },
//     "0897": { id: "0897", i: 5, s: 5, ss: 79 },
//     "0898": { id: "0898", i: 2, s: 2, ss: 5 },
//     "0899": { id: "0899", i: 2, s: 2, ss: 5 },
//     "0900": { id: "0900", i: 3, s: 20, ss: 38 },
//     "0901": { id: "0901", i: 3, s: 20, ss: 32 },
//     "0902": { id: "0902", i: 1, s: 1, ss: 1 },
//     "0904": { id: "0904", i: 9, s: 15, ss: 34 },
//     "0905": { id: "0905", i: 3, s: 20, ss: 32 },
//     "0906": { id: "0906", i: 10, s: 17, ss: 25 },
//     "0907": { id: "0907", i: 6, s: 27, ss: 49 },
//     "0908": { id: "0908", i: 0, s: 0, ss: 0 },
//     "0909": { id: "0909", i: 7, s: 7, ss: 22 },
//     "0910": { id: "0910", i: 2, s: 2, ss: 3 },
//     "0911": { id: "0911", i: 5, s: 5, ss: 7 },
//     "0912": { id: "0912", i: 6, s: 13, ss: 19 },
//     "0913": { id: "0913", i: 3, s: 20, ss: 32 },
//     "0914": { id: "0914", i: 2, s: 18, ss: 26 },
//     "0915": { id: "0915", i: 10, s: 17, ss: 82 },
//     "0916": { id: "0916", i: 1, s: 1, ss: 39 },
//     "0918": { id: "0918", i: 6, s: 27, ss: 50 },
//     "0919": { id: "0919", i: 5, s: 30, ss: 73 },
//     "0921": { id: "0921", i: 6, s: 13, ss: 68 },
//     "0922": { id: "0922", i: 6, s: 12, ss: 35 },
//     "0923": { id: "0923", i: 8, s: 21, ss: 93 },
//     "0924": { id: "0924", i: 2, s: 18, ss: 56 },
//     "0925": { id: "0925", i: 10, s: 25, ss: 90 },
//     "0926": { id: "0926", i: 9, s: 15, ss: 34 },
//     "0927": { id: "0927", i: 6, s: 13, ss: 19 },
//     "0928": { id: "0928", i: 5, s: 5, ss: 94 },
//     "0929": { id: "0929", i: 10, s: 11, ss: 20 },
//     "0931": { id: "0931", i: 1, s: 1, ss: 39 },
//     "0932": { id: "0932", i: 5, s: 5, ss: 7 },
//     "0934": { id: "0934", i: 11, s: 24, ss: 64 },
//     "0935": { id: "0935", i: 10, s: 25, ss: 53 },
//     "0936": { id: "0936", i: 10, s: 11, ss: 17 },
//     "0938": { id: "0938", i: 2, s: 2, ss: 3 },
//     "0939": { id: "0939", i: 3, s: 3, ss: 4 },
//     "0941": { id: "0941", i: 4, s: 4, ss: 6 },
//     "0943": { id: "0943", i: 6, s: 13, ss: 83 },
//     "0945": { id: "0945", i: 3, s: 28, ss: 67 },
//     "0947": { id: "0947", i: 7, s: 26, ss: 48 },
//     "0948": { id: "0948", i: 7, s: 26, ss: 48 },
//     "0950": { id: "0950", i: 5, s: 5, ss: 7 },
//     "0951": { id: "0951", i: 10, s: 11, ss: 58 },
//     "0952": { id: "0952", i: 3, s: 20, ss: 30 },
//     "0953": { id: "0953", i: 6, s: 6, ss: 77 },
//     "0954": { id: "0954", i: 9, s: 10, ss: 13 },
//     "0956": { id: "0956", i: 1, s: 1, ss: 2 },
//     "0959": { id: "0959", i: 6, s: 8, ss: 12 },
//     "0960": { id: "0960", i: 2, s: 2, ss: 3 },
//     "0966": { id: "0966", i: 3, s: 28, ss: 67 },
//     "0967": { id: "0967", i: 1, s: 1, ss: 71 },
//     "0968": { id: "0968", i: 10, s: 11, ss: 58 },
//     "0969": { id: "0969", i: 9, s: 15, ss: 34 },
//     "0970": { id: "0970", i: 6, s: 22, ss: 54 },
//     "0973": { id: "0973", i: 6, s: 22, ss: 36 },
//     "0974": { id: "0974", i: 6, s: 22, ss: 59 },
//     "0975": { id: "0975", i: 11, s: 19, ss: 28 },
//     "0976": { id: "0976", i: 8, s: 9, ss: 81 },
//     "0978": { id: "0978", i: 2, s: 2, ss: 3 },
//     "0979": { id: "0979", i: 10, s: 11, ss: 58 },
//     "0980": { id: "0980", i: 9, s: 14, ss: 18 },
//     "0981": { id: "0981", i: 7, s: 23, ss: 37 },
//     "0982": { id: "0982", i: 2, s: 2, ss: 47 },
//     "0983": { id: "0983", i: 2, s: 18, ss: 56 },
//     "0984": { id: "0984", i: 9, s: 14, ss: 18 },
//     "0985": { id: "0985", i: 8, s: 9, ss: 81 },
//     "0986": { id: "0986", i: 6, s: 27, ss: 70 },
//     "0987": { id: "0987", i: 1, s: 1, ss: 39 },
//     "0988": { id: "0988", i: 10, s: 25, ss: 90 },
//     "0989": { id: "0989", i: 2, s: 2, ss: 3 },
//     "0990": { id: "0990", i: 8, s: 21, ss: 31 },
//     "0991": { id: "0991", i: 1, s: 1, ss: 1 },
//     "0992": { id: "0992", i: 7, s: 26, ss: 78 },
//     "0993": { id: "0993", i: 3, s: 20, ss: 32 },
//     "0994": { id: "0994", i: 2, s: 18, ss: 56 },
//     "0995": { id: "0995", i: 10, s: 25, ss: 46 },
//     "0996": { id: "0996", i: 2, s: 2, ss: 3 },
//     "0997": { id: "0997", i: 10, s: 17, ss: 82 },
//     "0998": { id: "0998", i: 3, s: 3, ss: 4 },
//     "0999": { id: "0999", i: 6, s: 22, ss: 51 },
//     1000: { id: "1000", i: 6, s: 6, ss: 8 },
//     1001: { id: "1001", i: 8, s: 9, ss: 11 },
//     1002: { id: "1002", i: 10, s: 11, ss: 20 },
//     1003: { id: "1003", i: 6, s: 6, ss: 77 },
//     1004: { id: "1004", i: 1, s: 1, ss: 39 },
//     1005: { id: "1005", i: 6, s: 13, ss: 16 },
//     1007: { id: "1007", i: 6, s: 8, ss: 23 },
//     1008: { id: "1008", i: 10, s: 17, ss: 25 },
//     1009: { id: "1009", i: 2, s: 2, ss: 5 },
//     1010: { id: "1010", i: 7, s: 23, ss: 37 },
//     1011: { id: "1011", i: 5, s: 5, ss: 7 },
//     1013: { id: "1013", i: 7, s: 26, ss: 48 },
//     1019: { id: "1019", i: 3, s: 28, ss: 67 },
//     1020: { id: "1020", i: 7, s: 7, ss: 52 },
//     1022: { id: "1022", i: 7, s: 7, ss: 9 },
//     1023: { id: "1023", i: 6, s: 27, ss: 49 },
//     1024: { id: "1024", i: 7, s: 7, ss: 9 },
//     1025: { id: "1025", i: 6, s: 27, ss: 50 },
//     1026: { id: "1026", i: 1, s: 1, ss: 71 },
//     1027: { id: "1027", i: 6, s: 13, ss: 83 },
//     1028: { id: "1028", i: 6, s: 27, ss: 65 },
//     1029: { id: "1029", i: 8, s: 9, ss: 11 },
//     1030: { id: "1030", i: 2, s: 2, ss: 3 },
//     1031: { id: "1031", i: 3, s: 20, ss: 30 },
//     1033: { id: "1033", i: 11, s: 24, ss: 64 },
//     1034: { id: "1034", i: 2, s: 18, ss: 69 },
//     1036: { id: "1036", i: 2, s: 2, ss: 5 },
//     1037: { id: "1037", i: 7, s: 7, ss: 22 },
//     1038: { id: "1038", i: 1, s: 1, ss: 1 },
//     1039: { id: "1039", i: 7, s: 7, ss: 52 },
//     1041: { id: "1041", i: 3, s: 20, ss: 32 },
//     1043: { id: "1043", i: 10, s: 11, ss: 58 },
//     1044: { id: "1044", i: 9, s: 14, ss: 84 },
//     1045: { id: "1045", i: 4, s: 4, ss: 88 },
//     1046: { id: "1046", i: 6, s: 6, ss: 77 },
//     1047: { id: "1047", i: 8, s: 21, ss: 31 },
//     1049: { id: "1049", i: 6, s: 22, ss: 89 },
//     1050: { id: "1050", i: 7, s: 26, ss: 78 },
//     1051: { id: "1051", i: 3, s: 20, ss: 32 },
//     1052: { id: "1052", i: 10, s: 25, ss: 46 },
//     1053: { id: "1053", i: 8, s: 9, ss: 11 },
//     1054: { id: "1054", i: 3, s: 20, ss: 30 },
//     1055: { id: "1055", i: 6, s: 8, ss: 75 },
//     1057: { id: "1057", i: 6, s: 16, ss: 24 },
//     1058: { id: "1058", i: 6, s: 27, ss: 49 },
//     1059: { id: "1059", i: 7, s: 7, ss: 22 },
//     1060: { id: "1060", i: 6, s: 6, ss: 77 },
//     1061: { id: "1061", i: 5, s: 5, ss: 7 },
//     1062: { id: "1062", i: 3, s: 20, ss: 32 },
//     1063: { id: "1063", i: 7, s: 26, ss: 48 },
//     1064: { id: "1064", i: 2, s: 2, ss: 5 },
//     1065: { id: "1065", i: 1, s: 1, ss: 71 },
//     1066: { id: "1066", i: 5, s: 30, ss: 92 },
//     1068: { id: "1068", i: 9, s: 15, ss: 74 },
//     1069: { id: "1069", i: 8, s: 21, ss: 41 },
//     1070: { id: "1070", i: 6, s: 13, ss: 19 },
//     1071: { id: "1071", i: 1, s: 1, ss: 1 },
//     1072: { id: "1072", i: 10, s: 11, ss: 57 },
//     1073: { id: "1073", i: 3, s: 20, ss: 63 },
//     1074: { id: "1074", i: 8, s: 9, ss: 11 },
//     1075: { id: "1075", i: 7, s: 7, ss: 22 },
//     1076: { id: "1076", i: 6, s: 8, ss: 12 },
//     1079: { id: "1079", i: 7, s: 26, ss: 78 },
//     1080: { id: "1080", i: 11, s: 24, ss: 64 },
//     1082: { id: "1082", i: 6, s: 12, ss: 15 },
//     1083: { id: "1083", i: 1, s: 1, ss: 2 },
//     1084: { id: "1084", i: 9, s: 10, ss: 13 },
//     1085: { id: "1085", i: 7, s: 26, ss: 48 },
//     1086: { id: "1086", i: 6, s: 13, ss: 83 },
//     1087: { id: "1087", i: 7, s: 7, ss: 22 },
//     1088: { id: "1088", i: 11, s: 19, ss: 28 },
//     1090: { id: "1090", i: 8, s: 9, ss: 11 },
//     1091: { id: "1091", i: 8, s: 9, ss: 60 },
//     1093: { id: "1093", i: 5, s: 5, ss: 7 },
//     1094: { id: "1094", i: 7, s: 7, ss: 52 },
//     1096: { id: "1096", i: 1, s: 1, ss: 2 },
//     1097: { id: "1097", i: 6, s: 6, ss: 91 },
//     1098: { id: "1098", i: 2, s: 2, ss: 3 },
//     1099: { id: "1099", i: 5, s: 5, ss: 7 },
//     1100: { id: "1100", i: 6, s: 27, ss: 49 },
//     1101: { id: "1101", i: 11, s: 24, ss: 40 },
//     1102: { id: "1102", i: 2, s: 18, ss: 26 },
//     1103: { id: "1103", i: 8, s: 21, ss: 76 },
//     1104: { id: "1104", i: 8, s: 9, ss: 11 },
//     1105: { id: "1105", i: 6, s: 6, ss: 8 },
//     1106: { id: "1106", i: 10, s: 17, ss: 25 },
//     1107: { id: "1107", i: 2, s: 2, ss: 3 },
//     1108: { id: "1108", i: 10, s: 11, ss: 20 },
//     1109: { id: "1109", i: 2, s: 2, ss: 3 },
//     1110: { id: "1110", i: 5, s: 5, ss: 7 },
//     1111: { id: "1111", i: 3, s: 3, ss: 4 },
//     1112: { id: "1112", i: 9, s: 14, ss: 84 },
//     1113: { id: "1113", i: 2, s: 2, ss: 3 },
//     1114: { id: "1114", i: 6, s: 16, ss: 62 },
//     1115: { id: "1115", i: 9, s: 10, ss: 66 },
//     1116: { id: "1116", i: 8, s: 9, ss: 11 },
//     1117: { id: "1117", i: 9, s: 10, ss: 87 },
//     1118: { id: "1118", i: 2, s: 18, ss: 26 },
//     1119: { id: "1119", i: 7, s: 7, ss: 9 },
//     1120: { id: "1120", i: 6, s: 27, ss: 49 },
//     1121: { id: "1121", i: 6, s: 27, ss: 65 },
//     1122: { id: "1122", i: 10, s: 11, ss: 45 },
//     1123: { id: "1123", i: 6, s: 13, ss: 19 },
//     1124: { id: "1124", i: 2, s: 2, ss: 5 },
//     1125: { id: "1125", i: 2, s: 2, ss: 5 },
//     1126: { id: "1126", i: 6, s: 13, ss: 16 },
//     1127: { id: "1127", i: 10, s: 17, ss: 25 },
//     1128: { id: "1128", i: 6, s: 8, ss: 12 },
//     1129: { id: "1129", i: 1, s: 1, ss: 71 },
//     1130: { id: "1130", i: 6, s: 16, ss: 62 },
//     1131: { id: "1131", i: 11, s: 19, ss: 28 },
//     1132: { id: "1132", i: 6, s: 6, ss: 77 },
//     1133: { id: "1133", i: 10, s: 11, ss: 57 },
//     1134: { id: "1134", i: 6, s: 27, ss: 49 },
//     1137: { id: "1137", i: 7, s: 7, ss: 52 },
//     1138: { id: "1138", i: 10, s: 25, ss: 53 },
//     1139: { id: "1139", i: 6, s: 22, ss: 54 },
//     1140: { id: "1140", i: 3, s: 20, ss: 32 },
//     1141: { id: "1141", i: 3, s: 20, ss: 30 },
//     1142: { id: "1142", i: 8, s: 9, ss: 60 },
//     1143: { id: "1143", i: 6, s: 13, ss: 19 },
//     1145: { id: "1145", i: 10, s: 25, ss: 53 },
//     1146: { id: "1146", i: 6, s: 27, ss: 50 },
//     1147: { id: "1147", i: 7, s: 7, ss: 22 },
//     1148: { id: "1148", i: 6, s: 16, ss: 24 },
//     1150: { id: "1150", i: 6, s: 27, ss: 49 },
//     1152: { id: "1152", i: 9, s: 10, ss: 27 },
//     1153: { id: "1153", i: 2, s: 2, ss: 47 },
//     1156: { id: "1156", i: 11, s: 24, ss: 64 },
//     1157: { id: "1157", i: 10, s: 11, ss: 17 },
//     1158: { id: "1158", i: 6, s: 8, ss: 10 },
//     1159: { id: "1159", i: 6, s: 6, ss: 77 },
//     1160: { id: "1160", i: 3, s: 20, ss: 32 },
//     1161: { id: "1161", i: 5, s: 30, ss: 73 },
//     1162: { id: "1162", i: 2, s: 18, ss: 56 },
//     1163: { id: "1163", i: 6, s: 6, ss: 44 },
//     1164: { id: "1164", i: 8, s: 9, ss: 60 },
//     1165: { id: "1165", i: 1, s: 1, ss: 39 },
//     1166: { id: "1166", i: 10, s: 11, ss: 20 },
//     1167: { id: "1167", i: 5, s: 5, ss: 94 },
//     1168: { id: "1168", i: 2, s: 2, ss: 5 },
//     1170: { id: "1170", i: 6, s: 27, ss: 65 },
//     1171: { id: "1171", i: 11, s: 19, ss: 28 },
//     1172: { id: "1172", i: 10, s: 17, ss: 25 },
//     1173: { id: "1173", i: 9, s: 14, ss: 84 },
//     1175: { id: "1175", i: 9, s: 10, ss: 27 },
//     1176: { id: "1176", i: 2, s: 2, ss: 3 },
//     1177: { id: "1177", i: 5, s: 5, ss: 7 },
//     1178: { id: "1178", i: 0, s: 0, ss: 0 },
//     1179: { id: "1179", i: 6, s: 8, ss: 10 },
//     1180: { id: "1180", i: 6, s: 8, ss: 12 },
//     1181: { id: "1181", i: 6, s: 8, ss: 23 },
//     1182: { id: "1182", i: 6, s: 8, ss: 12 },
//     1183: { id: "1183", i: 2, s: 18, ss: 56 },
//     1184: { id: "1184", i: 10, s: 11, ss: 20 },
//     1185: { id: "1185", i: 10, s: 11, ss: 57 },
//     1186: { id: "1186", i: 2, s: 18, ss: 69 },
//     1188: { id: "1188", i: 6, s: 16, ss: 24 },
//     1189: { id: "1189", i: 6, s: 8, ss: 10 },
//     1191: { id: "1191", i: 3, s: 20, ss: 32 },
//     1192: { id: "1192", i: 10, s: 11, ss: 45 },
//     1193: { id: "1193", i: 1, s: 1, ss: 2 },
//     1194: { id: "1194", i: 8, s: 29, ss: 72 },
//     1195: { id: "1195", i: 2, s: 2, ss: 3 },
//     1196: { id: "1196", i: 10, s: 11, ss: 57 },
//     1198: { id: "1198", i: 6, s: 13, ss: 83 },
//     1199: { id: "1199", i: 10, s: 25, ss: 53 },
//     1200: { id: "1200", i: 2, s: 2, ss: 80 },
//     1201: { id: "1201", i: 2, s: 2, ss: 3 },
//     1202: { id: "1202", i: 7, s: 26, ss: 48 },
//     1203: { id: "1203", i: 8, s: 9, ss: 11 },
//     1205: { id: "1205", i: 8, s: 9, ss: 60 },
//     1206: { id: "1206", i: 7, s: 7, ss: 22 },
//     1207: { id: "1207", i: 2, s: 2, ss: 3 },
//     1208: { id: "1208", i: 8, s: 9, ss: 81 },
//     1209: { id: "1209", i: 2, s: 2, ss: 47 },
//     1210: { id: "1210", i: 9, s: 10, ss: 27 },
//     1211: { id: "1211", i: 6, s: 16, ss: 62 },
//     1212: { id: "1212", i: 6, s: 22, ss: 59 },
//     1213: { id: "1213", i: 10, s: 11, ss: 20 },
//     1215: { id: "1215", i: 6, s: 8, ss: 10 },
//     1216: { id: "1216", i: 3, s: 3, ss: 4 },
//     1217: { id: "1217", i: 3, s: 20, ss: 32 },
//     1218: { id: "1218", i: 2, s: 2, ss: 3 },
//     1220: { id: "1220", i: 2, s: 18, ss: 56 },
//     1221: { id: "1221", i: 6, s: 8, ss: 10 },
//     1222: { id: "1222", i: 2, s: 2, ss: 3 },
//     1223: { id: "1223", i: 6, s: 22, ss: 51 },
//     1224: { id: "1224", i: 2, s: 2, ss: 3 },
//     1225: { id: "1225", i: 3, s: 20, ss: 38 },
//     1226: { id: "1226", i: 3, s: 20, ss: 32 },
//     1227: { id: "1227", i: 3, s: 20, ss: 32 },
//     1228: { id: "1228", i: 5, s: 5, ss: 94 },
//     1229: { id: "1229", i: 11, s: 19, ss: 28 },
//     1230: { id: "1230", i: 9, s: 10, ss: 87 },
//     1231: { id: "1231", i: 8, s: 9, ss: 11 },
//     1232: { id: "1232", i: 2, s: 2, ss: 3 },
//     1233: { id: "1233", i: 2, s: 2, ss: 3 },
//     1234: { id: "1234", i: 6, s: 27, ss: 50 },
//     1235: { id: "1235", i: 6, s: 8, ss: 33 },
//     1237: { id: "1237", i: 6, s: 13, ss: 83 },
//     1238: { id: "1238", i: 2, s: 2, ss: 3 },
//     1239: { id: "1239", i: 10, s: 17, ss: 25 },
//     1240: { id: "1240", i: 2, s: 2, ss: 3 },
//     1241: { id: "1241", i: 6, s: 16, ss: 24 },
//     1243: { id: "1243", i: 2, s: 2, ss: 3 },
//     1245: { id: "1245", i: 6, s: 8, ss: 12 },
//     1246: { id: "1246", i: 2, s: 2, ss: 3 },
//     1247: { id: "1247", i: 6, s: 27, ss: 50 },
//     1250: { id: "1250", i: 1, s: 1, ss: 39 },
//     1251: { id: "1251", i: 11, s: 24, ss: 64 },
//     1252: { id: "1252", i: 2, s: 18, ss: 26 },
//     1253: { id: "1253", i: 2, s: 18, ss: 56 },
//     1255: { id: "1255", i: 6, s: 22, ss: 51 },
//     1257: { id: "1257", i: 1, s: 1, ss: 39 },
//     1258: { id: "1258", i: 8, s: 9, ss: 81 },
//     1259: { id: "1259", i: 9, s: 14, ss: 84 },
//     1260: { id: "1260", i: 3, s: 20, ss: 63 },
//     1262: { id: "1262", i: 9, s: 10, ss: 27 },
//     1263: { id: "1263", i: 7, s: 26, ss: 78 },
//     1265: { id: "1265", i: 1, s: 1, ss: 2 },
//     1266: { id: "1266", i: 8, s: 9, ss: 11 },
//     1268: { id: "1268", i: 6, s: 22, ss: 54 },
//     1269: { id: "1269", i: 6, s: 16, ss: 24 },
//     1270: { id: "1270", i: 6, s: 8, ss: 10 },
//     1271: { id: "1271", i: 2, s: 18, ss: 56 },
//     1272: { id: "1272", i: 10, s: 11, ss: 57 },
//     1273: { id: "1273", i: 3, s: 20, ss: 38 },
//     1275: { id: "1275", i: "", s: "", ss: 85 },
//     1277: { id: "1277", i: 11, s: 19, ss: 28 },
//     1278: { id: "1278", i: 2, s: 2, ss: 3 },
//     1280: { id: "1280", i: 6, s: 22, ss: 89 },
//     1281: { id: "1281", i: 1, s: 1, ss: 39 },
//     1282: { id: "1282", i: 3, s: 20, ss: 30 },
//     1283: { id: "1283", i: 2, s: 18, ss: 56 },
//     1285: { id: "1285", i: 9, s: 10, ss: 27 },
//     1286: { id: "1286", i: 10, s: 11, ss: 20 },
//     1288: { id: "1288", i: 3, s: 3, ss: 4 },
//     1289: { id: "1289", i: 10, s: 11, ss: 20 },
//     1290: { id: "1290", i: 3, s: 20, ss: 38 },
//     1292: { id: "1292", i: 10, s: 25, ss: 90 },
//     1293: { id: "1293", i: 6, s: 22, ss: 54 },
//     1296: { id: "1296", i: 10, s: 11, ss: 57 },
//     1297: { id: "1297", i: 7, s: 7, ss: 22 },
//     1298: { id: "1298", i: 5, s: 30, ss: 92 },
//     1299: { id: "1299", i: 3, s: 28, ss: 67 },
//     1300: { id: "1300", i: 7, s: 26, ss: 48 },
//     1301: { id: "1301", i: 10, s: 11, ss: 17 },
//     1302: { id: "1302", i: 5, s: 30, ss: 92 },
//     1303: { id: "1303", i: 11, s: 19, ss: 28 },
//     1305: { id: "1305", i: 10, s: 11, ss: 20 },
//     1308: { id: "1308", i: 10, s: 25, ss: 53 },
//     1310: { id: "1310", i: 4, s: 4, ss: 6 },
//     1312: { id: "1312", i: 2, s: 18, ss: 26 },
//     1313: { id: "1313", i: 2, s: 18, ss: 26 },
//     1314: { id: "1314", i: 6, s: 8, ss: 23 },
//     1315: { id: "1315", i: 2, s: 18, ss: 56 },
//     1316: { id: "1316", i: 6, s: 16, ss: 24 },
//     1317: { id: "1317", i: 6, s: 12, ss: 15 },
//     1319: { id: "1319", i: 3, s: 20, ss: 38 },
//     1321: { id: "1321", i: 2, s: 2, ss: 3 },
//     1323: { id: "1323", i: 0, s: 0, ss: 0 },
//     1326: { id: "1326", i: 6, s: 6, ss: 77 },
//     1327: { id: "1327", i: 6, s: 27, ss: 70 },
//     1328: { id: "1328", i: 6, s: 12, ss: 35 },
//     1329: { id: "1329", i: 2, s: 2, ss: 3 },
//     1330: { id: "1330", i: 10, s: 11, ss: 57 },
//     1332: { id: "1332", i: 10, s: 17, ss: 25 },
//     1333: { id: "1333", i: 8, s: 9, ss: 42 },
//     1335: { id: "1335", i: 1, s: 1, ss: 1 },
//     1336: { id: "1336", i: 3, s: 28, ss: 67 },
//     1337: { id: "1337", i: 7, s: 26, ss: 78 },
//     1338: { id: "1338", i: 9, s: 14, ss: 84 },
//     1339: { id: "1339", i: 3, s: 28, ss: 67 },
//     1340: { id: "1340", i: 9, s: 15, ss: 74 },
//     1341: { id: "1341", i: 10, s: 11, ss: 17 },
//     1343: { id: "1343", i: 2, s: 18, ss: 69 },
//     1345: { id: "1345", i: 5, s: 5, ss: 7 },
//     1346: { id: "1346", i: 6, s: 27, ss: 50 },
//     1347: { id: "1347", i: 7, s: 23, ss: 37 },
//     1348: { id: "1348", i: 6, s: 13, ss: 16 },
//     1349: { id: "1349", i: 5, s: 5, ss: 94 },
//     1351: { id: "1351", i: 6, s: 6, ss: 44 },
//     1355: { id: "1355", i: 6, s: 8, ss: 10 },
//     1357: { id: "1357", i: 7, s: 7, ss: 52 },
//     1358: { id: "1358", i: 5, s: 30, ss: 92 },
//     1359: { id: "1359", i: 3, s: 20, ss: 32 },
//     1360: { id: "1360", i: 6, s: 6, ss: 44 },
//     1361: { id: "1361", i: 6, s: 27, ss: 65 },
//     1362: { id: "1362", i: 7, s: 26, ss: 48 },
//     1363: { id: "1363", i: 1, s: 1, ss: 71 },
//     1365: { id: "1365", i: 6, s: 16, ss: 62 },
//     1366: { id: "1366", i: 10, s: 11, ss: 20 },
//     1367: { id: "1367", i: 6, s: 22, ss: 51 },
//     1368: { id: "1368", i: 6, s: 27, ss: 65 },
//     1370: { id: "1370", i: 8, s: 9, ss: 11 },
//     1371: { id: "1371", i: 6, s: 8, ss: 12 },
//     1372: { id: "1372", i: 2, s: 18, ss: 69 },
//     1373: { id: "1373", i: 6, s: 22, ss: 89 },
//     1375: { id: "1375", i: 3, s: 20, ss: 30 },
//     1376: { id: "1376", i: 2, s: 18, ss: 56 },
//     1378: { id: "1378", i: 8, s: 9, ss: 42 },
//     1379: { id: "1379", i: 2, s: 2, ss: 5 },
//     1380: { id: "1380", i: 8, s: 9, ss: 60 },
//     1381: { id: "1381", i: 1, s: 1, ss: 39 },
//     1382: { id: "1382", i: 6, s: 27, ss: 55 },
//     1383: { id: "1383", i: 6, s: 8, ss: 33 },
//     1385: { id: "1385", i: 7, s: 23, ss: 37 },
//     1386: { id: "1386", i: 6, s: 22, ss: 36 },
//     1387: { id: "1387", i: 2, s: 2, ss: 5 },
//     1388: { id: "1388", i: 6, s: 27, ss: 50 },
//     1389: { id: "1389", i: 9, s: 10, ss: 61 },
//     1393: { id: "1393", i: 11, s: 19, ss: 28 },
//     1395: { id: "1395", i: 1, s: 1, ss: 71 },
//     1396: { id: "1396", i: 2, s: 2, ss: 3 },
//     1397: { id: "1397", i: 6, s: 12, ss: 35 },
//     1398: { id: "1398", i: 3, s: 3, ss: 4 },
//     1399: { id: "1399", i: 10, s: 11, ss: 58 },
//     1400: { id: "1400", i: 6, s: 27, ss: 55 },
//     1401: { id: "1401", i: 10, s: 11, ss: 43 },
//     1402: { id: "1402", i: 7, s: 7, ss: 22 },
//     1406: { id: "1406", i: 5, s: 30, ss: 73 },
//     1408: { id: "1408", i: 2, s: 18, ss: 56 },
//     1410: { id: "1410", i: 7, s: 7, ss: 22 },
//     1412: { id: "1412", i: 10, s: 17, ss: 25 },
//     1413: { id: "1413", i: 2, s: 18, ss: 69 },
//     1415: { id: "1415", i: 10, s: 11, ss: 43 },
//     1416: { id: "1416", i: 2, s: 18, ss: 56 },
//     1417: { id: "1417", i: 2, s: 2, ss: 47 },
//     1418: { id: "1418", i: 6, s: 13, ss: 83 },
//     1419: { id: "1419", i: 5, s: 30, ss: 73 },
//     1420: { id: "1420", i: 2, s: 18, ss: 56 },
//     1421: { id: "1421", i: 2, s: 18, ss: 69 },
//     1425: { id: "1425", i: 6, s: 27, ss: 50 },
//     1426: { id: "1426", i: "", s: "", ss: 85 },
//     1427: { id: "1427", i: 2, s: 18, ss: 56 },
//     1428: { id: "1428", i: 3, s: 20, ss: 30 },
//     1429: { id: "1429", i: 2, s: 18, ss: 56 },
//     1430: { id: "1430", i: 1, s: 1, ss: 2 },
//     1431: { id: "1431", i: 9, s: 10, ss: 87 },
//     1432: { id: "1432", i: 9, s: 10, ss: 87 },
//     1433: { id: "1433", i: 10, s: 17, ss: 25 },
//     1439: { id: "1439", i: 10, s: 17, ss: 25 },
//     1440: { id: "1440", i: 6, s: 27, ss: 55 },
//     1442: { id: "1442", i: 10, s: 25, ss: 90 },
//     1443: { id: "1443", i: 6, s: 8, ss: 23 },
//     1446: { id: "1446", i: 9, s: 10, ss: 66 },
//     1447: { id: "1447", i: 2, s: 18, ss: 56 },
//     1448: { id: "1448", i: 6, s: 12, ss: 35 },
//     1449: { id: "1449", i: 6, s: 12, ss: 15 },
//     1450: { id: "1450", i: 7, s: 7, ss: 22 },
//     1451: { id: "1451", i: 10, s: 17, ss: 25 },
//     1452: { id: "1452", i: 10, s: 11, ss: 57 },
//     1455: { id: "1455", i: 6, s: 13, ss: 68 },
//     1456: { id: "1456", i: 3, s: 20, ss: 30 },
//     1458: { id: "1458", i: 9, s: 10, ss: 27 },
//     1459: { id: "1459", i: 2, s: 18, ss: 56 },
//     1460: { id: "1460", i: 7, s: 7, ss: 22 },
//     1461: { id: "1461", i: 3, s: 20, ss: 30 },
//     1463: { id: "1463", i: 6, s: 12, ss: 35 },
//     1466: { id: "1466", i: 6, s: 22, ss: 36 },
//     1468: { id: "1468", i: 0, s: 0, ss: 0 },
//     1469: { id: "1469", i: 3, s: 20, ss: 30 },
//     1470: { id: "1470", i: 6, s: 22, ss: 36 },
//     1472: { id: "1472", i: 2, s: 18, ss: 69 },
//     1473: { id: "1473", i: 7, s: 26, ss: 48 },
//     1475: { id: "1475", i: 9, s: 10, ss: 27 },
//     1476: { id: "1476", i: 3, s: 20, ss: 30 },
//     1477: { id: "1477", i: 5, s: 5, ss: 94 },
//     1478: { id: "1478", i: 10, s: 11, ss: 43 },
//     1480: { id: "1480", i: 10, s: 11, ss: 20 },
//     1481: { id: "1481", i: 8, s: 21, ss: 93 },
//     1483: { id: "1483", i: 6, s: 12, ss: 35 },
//     1486: { id: "1486", i: 2, s: 18, ss: 56 },
//     1488: { id: "1488", i: 6, s: 8, ss: 23 },
//     1490: { id: "1490", i: 6, s: 6, ss: 44 },
//     1492: { id: "1492", i: 9, s: 10, ss: 87 },
//     1495: { id: "1495", i: 2, s: 18, ss: 56 },
//     1496: { id: "1496", i: 2, s: 18, ss: 69 },
//     1498: { id: "1498", i: 5, s: 5, ss: 7 },
//     1499: { id: "1499", i: 2, s: 18, ss: 56 },
//     1500: { id: "1500", i: 2, s: 18, ss: 56 },
//     1501: { id: "1501", i: 5, s: 30, ss: 92 },
//     1502: { id: "1502", i: 2, s: 2, ss: 47 },
//     1503: { id: "1503", i: "", s: "", ss: 85 },
//     1508: { id: "1508", i: 3, s: 28, ss: 67 },
//     1509: { id: "1509", i: 5, s: 30, ss: 73 },
//     1513: { id: "1513", i: 5, s: 5, ss: 7 },
//     1515: { id: "1515", i: 5, s: 30, ss: 73 },
//     1516: { id: "1516", i: 2, s: 2, ss: 47 },
//     1518: { id: "1518", i: 5, s: 30, ss: 73 },
//     1520: { id: "1520", i: 6, s: 27, ss: 50 },
//     1521: { id: "1521", i: 5, s: 5, ss: 94 },
//     1522: { id: "1522", i: 7, s: 7, ss: 22 },
//     1523: { id: "1523", i: 7, s: 26, ss: 78 },
//     1525: { id: "1525", i: 6, s: 12, ss: 15 },
//     1526: { id: "1526", i: 5, s: 30, ss: 73 },
//     1527: { id: "1527", i: 10, s: 11, ss: 57 },
//     1528: { id: "1528", i: 2, s: 2, ss: 5 },
//     1529: { id: "1529", i: 10, s: 25, ss: 90 },
//     1530: { id: "1530", i: 5, s: 5, ss: 7 },
//     1532: { id: "1532", i: 6, s: 27, ss: 50 },
//     1533: { id: "1533", i: 9, s: 10, ss: 87 },
//     1536: { id: "1536", i: 10, s: 11, ss: 20 },
//     1538: { id: "1538", i: 2, s: 2, ss: 47 },
//     1539: { id: "1539", i: 10, s: 11, ss: 57 },
//     1540: { id: "1540", i: 10, s: 17, ss: 25 },
//     1542: { id: "1542", i: 1, s: 1, ss: 71 },
//     1543: { id: "1543", i: 3, s: 20, ss: 38 },
//     1545: { id: "1545", i: 6, s: 22, ss: 89 },
//     1546: { id: "1546", i: 2, s: 18, ss: 56 },
//     1547: { id: "1547", i: 2, s: 18, ss: 56 },
//     1548: { id: "1548", i: 5, s: 5, ss: 94 },
//     1549: { id: "1549", i: 10, s: 25, ss: 53 },
//     1551: { id: "1551", i: 3, s: 3, ss: 4 },
//     1552: { id: "1552", i: 2, s: 18, ss: 56 },
//     1553: { id: "1553", i: 8, s: 9, ss: 11 },
//     1555: { id: "1555", i: 11, s: 24, ss: 40 },
//     1556: { id: "1556", i: 2, s: 18, ss: 56 },
//     1557: { id: "1557", i: 2, s: 18, ss: 69 },
//     1558: { id: "1558", i: 5, s: 5, ss: 7 },
//     1559: { id: "1559", i: 2, s: 18, ss: 69 },
//     1560: { id: "1560", i: 2, s: 2, ss: 3 },
//     1561: { id: "1561", i: 8, s: 21, ss: 31 },
//     1563: { id: "1563", i: 3, s: 20, ss: 38 },
//     1565: { id: "1565", i: 6, s: 12, ss: 15 },
//     1566: { id: "1566", i: 6, s: 8, ss: 86 },
//     1568: { id: "1568", i: 2, s: 18, ss: 56 },
//     1569: { id: "1569", i: 6, s: 12, ss: 15 },
//     1570: { id: "1570", i: 2, s: 2, ss: 3 },
//     1571: { id: "1571", i: 6, s: 16, ss: 24 },
//     1572: { id: "1572", i: 3, s: 20, ss: 38 },
//     1573: { id: "1573", i: 11, s: 19, ss: 28 },
//     1575: { id: "1575", i: 6, s: 13, ss: 83 },
//     1576: { id: "1576", i: 10, s: 25, ss: 46 },
//     1577: { id: "1577", i: 3, s: 20, ss: 38 },
//     1578: { id: "1578", i: 3, s: 3, ss: 4 },
//     1579: { id: "1579", i: 9, s: 10, ss: 13 },
//     1580: { id: "1580", i: 8, s: 21, ss: 41 },
//     1581: { id: "1581", i: 2, s: 18, ss: 56 },
//     1582: { id: "1582", i: 2, s: 18, ss: 56 },
//     1583: { id: "1583", i: 9, s: 10, ss: 27 },
//     1585: { id: "1585", i: 6, s: 16, ss: 62 },
//     1586: { id: "1586", i: 6, s: 12, ss: 35 },
//     1587: { id: "1587", i: 9, s: 10, ss: 13 },
//     1588: { id: "1588", i: 7, s: 7, ss: 9 },
//     1589: { id: "1589", i: 2, s: 2, ss: 5 },
//     1591: { id: "1591", i: 2, s: 18, ss: 56 },
//     1592: { id: "1592", i: 2, s: 18, ss: 26 },
//     1593: { id: "1593", i: 6, s: 12, ss: 15 },
//     1596: { id: "1596", i: 10, s: 11, ss: 45 },
//     1597: { id: "1597", i: 10, s: 11, ss: 57 },
//     1598: { id: "1598", i: 6, s: 12, ss: 15 },
//     1599: { id: "1599", i: 2, s: 18, ss: 56 },
//     1600: { id: "1600", i: 1, s: 1, ss: 2 },
//     1601: { id: "1601", i: 3, s: 20, ss: 38 },
//     1606: { id: "1606", i: 3, s: 20, ss: 38 },
//     1608: { id: "1608", i: 1, s: 1, ss: 1 },
//     1609: { id: "1609", i: 2, s: 18, ss: 56 },
//     1610: { id: "1610", i: 9, s: 15, ss: 74 },
//     1611: { id: "1611", i: 10, s: 11, ss: 20 },
//     1612: { id: "1612", i: 5, s: 30, ss: 92 },
//     1613: { id: "1613", i: 7, s: 7, ss: 22 },
//     1615: { id: "1615", i: 2, s: 18, ss: 56 },
//     1616: { id: "1616", i: 6, s: 6, ss: 77 },
//     1617: { id: "1617", i: 7, s: 26, ss: 48 },
//     1618: { id: "1618", i: 2, s: 18, ss: 69 },
//     1620: { id: "1620", i: 6, s: 8, ss: 33 },
//     1621: { id: "1621", i: 11, s: 24, ss: 40 },
//     1622: { id: "1622", i: 2, s: 2, ss: 3 },
//     1623: { id: "1623", i: 11, s: 24, ss: 64 },
//     1626: { id: "1626", i: 10, s: 17, ss: 25 },
//     1627: { id: "1627", i: 2, s: 18, ss: 56 },
//     1628: { id: "1628", i: 2, s: 2, ss: 3 },
//     1629: { id: "1629", i: 10, s: 17, ss: 25 },
//     1630: { id: "1630", i: 2, s: 18, ss: 56 },
//     1631: { id: "1631", i: 10, s: 17, ss: 25 },
//     1632: { id: "1632", i: 6, s: 8, ss: 23 },
//     1633: { id: "1633", i: 2, s: 18, ss: 56 },
//     1635: { id: "1635", i: 1, s: 1, ss: 2 },
//     1636: { id: "1636", i: 8, s: 9, ss: 81 },
//     1637: { id: "1637", i: 2, s: 18, ss: 56 },
//     1638: { id: "1638", i: 2, s: 2, ss: 3 },
//     1639: { id: "1639", i: 10, s: 11, ss: 20 },
//     1640: { id: "1640", i: 6, s: 6, ss: 44 },
//     1642: { id: "1642", i: 2, s: 18, ss: 56 },
//     1643: { id: "1643", i: 5, s: 5, ss: 79 },
//     1645: { id: "1645", i: 10, s: 11, ss: 20 },
//     1647: { id: "1647", i: 2, s: 18, ss: 56 },
//     1649: { id: "1649", i: 2, s: 18, ss: 69 },
//     1650: { id: "1650", i: 6, s: 12, ss: 35 },
//     1651: { id: "1651", i: 10, s: 11, ss: 20 },
//     1652: { id: "1652", i: 5, s: 5, ss: 7 },
//     1653: { id: "1653", i: 2, s: 18, ss: 26 },
//     1655: { id: "1655", i: 6, s: 8, ss: 12 },
//     1656: { id: "1656", i: 10, s: 11, ss: 20 },
//     1657: { id: "1657", i: 6, s: 27, ss: 50 },
//     1658: { id: "1658", i: 3, s: 3, ss: 4 },
//     1660: { id: "1660", i: 10, s: 11, ss: 17 },
//     1661: { id: "1661", i: 6, s: 8, ss: 86 },
//     1662: { id: "1662", i: 2, s: 18, ss: 69 },
//     1663: { id: "1663", i: 2, s: 2, ss: 3 },
//     1665: { id: "1665", i: 7, s: 7, ss: 22 },
//     1666: { id: "1666", i: 5, s: 5, ss: 79 },
//     1667: { id: "1667", i: 2, s: 18, ss: 56 },
//     1668: { id: "1668", i: 2, s: 2, ss: 3 },
//     1669: { id: "1669", i: 3, s: 20, ss: 38 },
//     1671: { id: "1671", i: 1, s: 1, ss: 1 },
//     1672: { id: "1672", i: 5, s: 5, ss: 94 },
//     1673: { id: "1673", i: 10, s: 11, ss: 20 },
//     1675: { id: "1675", i: 7, s: 7, ss: 9 },
//     1676: { id: "1676", i: 9, s: 10, ss: 27 },
//     1678: { id: "1678", i: 6, s: 13, ss: 83 },
//     1679: { id: "1679", i: 7, s: 7, ss: 9 },
//     1680: { id: "1680", i: 6, s: 8, ss: 12 },
//     1681: { id: "1681", i: 5, s: 5, ss: 7 },
//     1682: { id: "1682", i: 6, s: 27, ss: 50 },
//     1683: { id: "1683", i: 2, s: 18, ss: 56 },
//     1685: { id: "1685", i: 10, s: 11, ss: 57 },
//     1686: { id: "1686", i: 7, s: 7, ss: 22 },
//     1689: { id: "1689", i: 10, s: 17, ss: 25 },
//     1690: { id: "1690", i: 2, s: 18, ss: 56 },
//     1691: { id: "1691", i: 6, s: 13, ss: 68 },
//     1692: { id: "1692", i: 6, s: 13, ss: 68 },
//     1693: { id: "1693", i: 2, s: 18, ss: 56 },
//     1695: { id: "1695", i: 9, s: 10, ss: 13 },
//     1696: { id: "1696", i: 5, s: 30, ss: 92 },
//     1697: { id: "1697", i: 3, s: 20, ss: 32 },
//     1699: { id: "1699", i: 9, s: 15, ss: 74 },
//     1701: { id: "1701", i: 6, s: 8, ss: 33 },
//     1702: { id: "1702", i: 8, s: 21, ss: 76 },
//     1703: { id: "1703", i: 6, s: 8, ss: 23 },
//     1705: { id: "1705", i: 9, s: 10, ss: 27 },
//     1706: { id: "1706", i: 2, s: 18, ss: 69 },
//     1707: { id: "1707", i: 2, s: 18, ss: 69 },
//     1708: { id: "1708", i: 7, s: 7, ss: 22 },
//     1709: { id: "1709", i: 3, s: 20, ss: 30 },
//     1710: { id: "1710", i: 10, s: 11, ss: 20 },
//     1711: { id: "1711", i: 6, s: 22, ss: 89 },
//     1712: { id: "1712", i: 8, s: 29, ss: 72 },
//     1713: { id: "1713", i: 1, s: 1, ss: 1 },
//     1715: { id: "1715", i: 6, s: 13, ss: 83 },
//     1716: { id: "1716", i: 6, s: 6, ss: 44 },
//     1717: { id: "1717", i: 9, s: 10, ss: 87 },
//     1718: { id: "1718", i: 2, s: 18, ss: 69 },
//     1719: { id: "1719", i: 10, s: 17, ss: 82 },
//     1720: { id: "1720", i: 7, s: 26, ss: 48 },
//     1721: { id: "1721", i: 8, s: 9, ss: 11 },
//     1722: { id: "1722", i: 2, s: 18, ss: 56 },
//     1723: { id: "1723", i: 6, s: 22, ss: 59 },
//     1725: { id: "1725", i: 10, s: 11, ss: 20 },
//     1726: { id: "1726", i: 5, s: 30, ss: 92 },
//     1727: { id: "1727", i: 2, s: 18, ss: 56 },
//     1728: { id: "1728", i: 6, s: 22, ss: 54 },
//     1729: { id: "1729", i: 7, s: 26, ss: 48 },
//     1730: { id: "1730", i: 2, s: 2, ss: 5 },
//     1731: { id: "1731", i: 6, s: 27, ss: 49 },
//     1732: { id: "1732", i: 10, s: 25, ss: 53 },
//     1733: { id: "1733", i: 11, s: 19, ss: 28 },
//     1735: { id: "1735", i: 2, s: 18, ss: 56 },
//     1736: { id: "1736", i: 7, s: 7, ss: 52 },
//     1737: { id: "1737", i: 10, s: 25, ss: 90 },
//     1738: { id: "1738", i: 11, s: 19, ss: 28 },
//     1739: { id: "1739", i: 7, s: 7, ss: 52 },
//     1740: { id: "1740", i: 6, s: 6, ss: 77 },
//     1741: { id: "1741", i: 2, s: 18, ss: 56 },
//     1742: { id: "1742", i: 2, s: 18, ss: 56 },
//     1743: { id: "1743", i: 10, s: 11, ss: 20 },
//     1745: { id: "1745", i: 7, s: 7, ss: 9 },
//     1746: { id: "1746", i: 2, s: 18, ss: 56 },
//     1747: { id: "1747", i: 6, s: 13, ss: 19 },
//     1748: { id: "1748", i: 10, s: 25, ss: 53 },
//     1749: { id: "1749", i: 6, s: 27, ss: 50 },
//     1750: { id: "1750", i: 10, s: 11, ss: 20 },
//     1751: { id: "1751", i: 2, s: 18, ss: 56 },
//     1752: { id: "1752", i: 6, s: 12, ss: 15 },
//     1753: { id: "1753", i: 6, s: 6, ss: 44 },
//     1755: { id: "1755", i: 2, s: 2, ss: 47 },
//     1756: { id: "1756", i: 6, s: 12, ss: 15 },
//     1757: { id: "1757", i: 2, s: 18, ss: 69 },
//     1758: { id: "1758", i: 6, s: 12, ss: 15 },
//     1759: { id: "1759", i: 1, s: 1, ss: 2 },
//     1760: { id: "1760", i: 6, s: 16, ss: 24 },
//     1761: { id: "1761", i: 6, s: 6, ss: 44 },
//     1762: { id: "1762", i: 6, s: 6, ss: 44 },
//     1763: { id: "1763", i: 5, s: 5, ss: 7 },
//     1765: { id: "1765", i: 6, s: 12, ss: 15 },
//     1766: { id: "1766", i: 10, s: 11, ss: 45 },
//     1767: { id: "1767", i: 9, s: 10, ss: 27 },
//     1769: { id: "1769", i: 6, s: 12, ss: 15 },
//     1771: { id: "1771", i: 6, s: 22, ss: 54 },
//     1772: { id: "1772", i: 8, s: 9, ss: 60 },
//     1773: { id: "1773", i: 6, s: 12, ss: 15 },
//     1775: { id: "1775", i: 6, s: 12, ss: 15 },
//     1776: { id: "1776", i: 3, s: 20, ss: 30 },
//     1777: { id: "1777", i: 2, s: 2, ss: 3 },
//     1778: { id: "1778", i: 2, s: 2, ss: 47 },
//     1780: { id: "1780", i: 2, s: 18, ss: 56 },
//     1781: { id: "1781", i: 6, s: 13, ss: 83 },
//     1782: { id: "1782", i: 7, s: 7, ss: 22 },
//     1783: { id: "1783", i: 2, s: 18, ss: 56 },
//     1785: { id: "1785", i: 10, s: 25, ss: 46 },
//     1786: { id: "1786", i: 10, s: 11, ss: 17 },
//     1787: { id: "1787", i: 8, s: 29, ss: 72 },
//     1788: { id: "1788", i: 3, s: 20, ss: 30 },
//     1789: { id: "1789", i: 5, s: 30, ss: 92 },
//     1790: { id: "1790", i: 1, s: 1, ss: 71 },
//     1792: { id: "1792", i: 6, s: 13, ss: 16 },
//     1793: { id: "1793", i: 2, s: 18, ss: 56 },
//     1795: { id: "1795", i: 6, s: 27, ss: 55 },
//     1796: { id: "1796", i: 2, s: 18, ss: 56 },
//     1797: { id: "1797", i: 6, s: 12, ss: 15 },
//     1798: { id: "1798", i: 1, s: 1, ss: 39 },
//     1799: { id: "1799", i: 1, s: 1, ss: 39 },
//     1800: { id: "1800", i: 2, s: 18, ss: 69 },
//     1801: { id: "1801", i: 5, s: 5, ss: 7 },
//     1802: { id: "1802", i: 2, s: 18, ss: 56 },
//     1803: { id: "1803", i: 6, s: 8, ss: 86 },
//     1806: { id: "1806", i: 7, s: 7, ss: 52 },
//     1808: { id: "1808", i: 7, s: 7, ss: 22 },
//     1809: { id: "1809", i: 6, s: 16, ss: 24 },
//     1810: { id: "1810", i: 7, s: 26, ss: 48 },
//     1811: { id: "1811", i: 1, s: 1, ss: 39 },
//     1812: { id: "1812", i: 8, s: 21, ss: 93 },
//     1813: { id: "1813", i: 2, s: 2, ss: 3 },
//     1815: { id: "1815", i: 6, s: 22, ss: 36 },
//     1816: { id: "1816", i: 1, s: 1, ss: 1 },
//     1817: { id: "1817", i: 6, s: 22, ss: 51 },
//     1818: { id: "1818", i: 8, s: 29, ss: 72 },
//     1820: { id: "1820", i: 10, s: 17, ss: 25 },
//     1821: { id: "1821", i: 3, s: 20, ss: 32 },
//     1822: { id: "1822", i: 8, s: 21, ss: 41 },
//     1823: { id: "1823", i: 10, s: 25, ss: 46 },
//     1825: { id: "1825", i: 6, s: 27, ss: 50 },
//     1826: { id: "1826", i: 2, s: 18, ss: 56 },
//     1827: { id: "1827", i: 5, s: 30, ss: 73 },
//     1829: { id: "1829", i: 2, s: 18, ss: 69 },
//     1830: { id: "1830", i: 5, s: 30, ss: 73 },
//     1831: { id: "1831", i: 6, s: 6, ss: 44 },
//     1832: { id: "1832", i: 6, s: 8, ss: 10 },
//     1833: { id: "1833", i: 5, s: 30, ss: 73 },
//     1835: { id: "1835", i: 3, s: 20, ss: 32 },
//     1836: { id: "1836", i: 6, s: 27, ss: 65 },
//     1837: { id: "1837", i: 9, s: 10, ss: 27 },
//     1838: { id: "1838", i: 2, s: 2, ss: 3 },
//     1839: { id: "1839", i: 10, s: 11, ss: 45 },
//     1841: { id: "1841", i: 10, s: 17, ss: 25 },
//     1842: { id: "1842", i: 6, s: 27, ss: 49 },
//     1843: { id: "1843", i: 6, s: 8, ss: 23 },
//     1845: { id: "1845", i: 1, s: 1, ss: 39 },
//     1846: { id: "1846", i: 5, s: 30, ss: 73 },
//     1847: { id: "1847", i: 2, s: 18, ss: 26 },
//     1848: { id: "1848", i: 6, s: 8, ss: 75 },
//     1849: { id: "1849", i: 6, s: 6, ss: 44 },
//     1850: { id: "1850", i: 2, s: 18, ss: 56 },
//     1851: { id: "1851", i: 6, s: 12, ss: 15 },
//     1853: { id: "1853", i: 1, s: 1, ss: 39 },
//     1854: { id: "1854", i: 9, s: 15, ss: 34 },
//     1855: { id: "1855", i: 2, s: 18, ss: 56 },
//     1856: { id: "1856", i: 6, s: 27, ss: 70 },
//     1857: { id: "1857", i: 1, s: 1, ss: 71 },
//     1858: { id: "1858", i: 5, s: 30, ss: 92 },
//     1859: { id: "1859", i: 6, s: 6, ss: 91 },
//     1860: { id: "1860", i: 6, s: 6, ss: 44 },
//     1861: { id: "1861", i: 8, s: 21, ss: 31 },
//     1862: { id: "1862", i: 2, s: 2, ss: 3 },
//     1863: { id: "1863", i: 8, s: 21, ss: 31 },
//     1865: { id: "1865", i: 2, s: 18, ss: 69 },
//     1866: { id: "1866", i: 8, s: 21, ss: 76 },
//     1867: { id: "1867", i: 2, s: 18, ss: 56 },
//     1868: { id: "1868", i: 6, s: 13, ss: 83 },
//     1869: { id: "1869", i: 6, s: 8, ss: 23 },
//     1870: { id: "1870", i: 2, s: 18, ss: 56 },
//     1871: { id: "1871", i: 6, s: 12, ss: 15 },
//     1872: { id: "1872", i: 6, s: 22, ss: 54 },
//     1873: { id: "1873", i: 5, s: 5, ss: 94 },
//     1875: { id: "1875", i: 5, s: 5, ss: 94 },
//     1876: { id: "1876", i: 9, s: 10, ss: 61 },
//     1877: { id: "1877", i: 5, s: 5, ss: 94 },
//     1878: { id: "1878", i: 11, s: 19, ss: 28 },
//     1881: { id: "1881", i: "", s: "", ss: 85 },
//     1882: { id: "1882", i: 10, s: 11, ss: 20 },
//     1883: { id: "1883", i: 4, s: 4, ss: 6 },
//     1884: { id: "1884", i: 10, s: 17, ss: 25 },
//     1885: { id: "1885", i: 8, s: 21, ss: 41 },
//     1888: { id: "1888", i: 10, s: 11, ss: 20 },
//     1889: { id: "1889", i: 5, s: 5, ss: 7 },
//     1890: { id: "1890", i: 6, s: 12, ss: 15 },
//     1891: { id: "1891", i: 8, s: 9, ss: 60 },
//     1894: { id: "1894", i: 2, s: 18, ss: 26 },
//     1895: { id: "1895", i: 2, s: 2, ss: 47 },
//     1896: { id: "1896", i: 6, s: 6, ss: 77 },
//     1897: { id: "1897", i: 2, s: 18, ss: 56 },
//     1898: { id: "1898", i: 11, s: 19, ss: 28 },
//     1899: { id: "1899", i: 10, s: 11, ss: 45 },
//     1900: { id: "1900", i: 7, s: 7, ss: 22 },
//     1901: { id: "1901", i: 6, s: 8, ss: 33 },
//     1902: { id: "1902", i: 2, s: 2, ss: 3 },
//     1903: { id: "1903", i: 10, s: 25, ss: 53 },
//     1905: { id: "1905", i: 3, s: 20, ss: 38 },
//     1906: { id: "1906", i: 6, s: 27, ss: 50 },
//     1907: { id: "1907", i: 8, s: 21, ss: 31 },
//     1908: { id: "1908", i: 2, s: 2, ss: 3 },
//     1909: { id: "1909", i: 7, s: 7, ss: 9 },
//     1910: { id: "1910", i: 6, s: 27, ss: 49 },
//     1911: { id: "1911", i: 3, s: 20, ss: 32 },
//     1912: { id: "1912", i: 7, s: 23, ss: 37 },
//     1913: { id: "1913", i: 6, s: 27, ss: 49 },
//     1915: { id: "1915", i: 3, s: 20, ss: 38 },
//     1916: { id: "1916", i: 3, s: 3, ss: 4 },
//     1917: { id: "1917", i: 6, s: 6, ss: 44 },
//     1918: { id: "1918", i: 2, s: 2, ss: 3 },
//     1919: { id: "1919", i: 10, s: 25, ss: 53 },
//     1920: { id: "1920", i: 2, s: 18, ss: 56 },
//     1921: { id: "1921", i: 11, s: 24, ss: 64 },
//     1922: { id: "1922", i: 2, s: 2, ss: 47 },
//     1925: { id: "1925", i: 6, s: 13, ss: 83 },
//     1927: { id: "1927", i: 9, s: 10, ss: 27 },
//     1928: { id: "1928", i: 6, s: 8, ss: 12 },
//     1929: { id: "1929", i: 6, s: 22, ss: 36 },
//     1930: { id: "1930", i: 10, s: 11, ss: 20 },
//     1931: { id: "1931", i: 5, s: 30, ss: 92 },
//     1932: { id: "1932", i: 8, s: 21, ss: 31 },
//     1933: { id: "1933", i: 7, s: 7, ss: 9 },
//     1935: { id: "1935", i: 6, s: 12, ss: 15 },
//     1936: { id: "1936", i: 9, s: 15, ss: 21 },
//     1937: { id: "1937", i: 2, s: 18, ss: 26 },
//     1938: { id: "1938", i: 11, s: 24, ss: 64 },
//     1939: { id: "1939", i: 6, s: 12, ss: 35 },
//     1940: { id: "1940", i: 11, s: 24, ss: 40 },
//     1941: { id: "1941", i: 2, s: 2, ss: 47 },
//     1942: { id: "1942", i: 6, s: 22, ss: 36 },
//     1943: { id: "1943", i: 2, s: 18, ss: 56 },
//     1945: { id: "1945", i: 3, s: 20, ss: 63 },
//     1948: { id: "1948", i: 6, s: 6, ss: 44 },
//     1949: { id: "1949", i: 7, s: 7, ss: 22 },
//     1950: { id: "1950", i: 8, s: 21, ss: 31 },
//     1951: { id: "1951", i: 5, s: 5, ss: 94 },
//     1952: { id: "1952", i: 5, s: 5, ss: 94 },
//     1953: { id: "1953", i: 2, s: 18, ss: 56 },
//     1955: { id: "1955", i: 6, s: 12, ss: 35 },
//     1957: { id: "1957", i: 6, s: 27, ss: 50 },
//     1958: { id: "1958", i: 6, s: 16, ss: 62 },
//     1959: { id: "1959", i: 6, s: 22, ss: 54 },
//     1960: { id: "1960", i: 2, s: 18, ss: 69 },
//     1961: { id: "1961", i: 7, s: 7, ss: 9 },
//     1962: { id: "1962", i: 6, s: 27, ss: 49 },
//     1963: { id: "1963", i: 3, s: 3, ss: 4 },
//     1965: { id: "1965", i: 2, s: 2, ss: 47 },
//     1966: { id: "1966", i: 2, s: 2, ss: 3 },
//     1967: { id: "1967", i: 10, s: 11, ss: 20 },
//     1968: { id: "1968", i: 6, s: 27, ss: 55 },
//     1969: { id: "1969", i: 6, s: 12, ss: 15 },
//     1970: { id: "1970", i: 6, s: 6, ss: 77 },
//     1971: { id: "1971", i: 2, s: 2, ss: 47 },
//     1972: { id: "1972", i: 2, s: 2, ss: 5 },
//     1975: { id: "1975", i: 10, s: 17, ss: 25 },
//     1977: { id: "1977", i: 2, s: 18, ss: 56 },
//     1978: { id: "1978", i: 6, s: 8, ss: 23 },
//     1979: { id: "1979", i: 10, s: 11, ss: 43 },
//     1980: { id: "1980", i: 7, s: 7, ss: 9 },
//     1981: { id: "1981", i: 6, s: 6, ss: 77 },
//     1982: { id: "1982", i: 6, s: 27, ss: 50 },
//     1983: { id: "1983", i: 3, s: 3, ss: 4 },
//     1985: { id: "1985", i: 7, s: 7, ss: 22 },
//     1986: { id: "1986", i: 8, s: 21, ss: 31 },
//     1987: { id: "1987", i: 2, s: 18, ss: 69 },
//     1988: { id: "1988", i: 3, s: 3, ss: 4 },
//     1989: { id: "1989", i: 6, s: 12, ss: 35 },
//     1991: { id: "1991", i: 10, s: 11, ss: 20 },
//     1992: { id: "1992", i: 6, s: 8, ss: 10 },
//     1993: { id: "1993", i: 6, s: 6, ss: 44 },
//     1995: { id: "1995", i: 2, s: 2, ss: 47 },
//     1996: { id: "1996", i: 2, s: 2, ss: 3 },
//     1997: { id: "1997", i: 2, s: 2, ss: 5 },
//     1999: { id: "1999", i: 6, s: 13, ss: 83 },
//     2000: { id: "2000", i: 7, s: 26, ss: 48 },
//     2001: { id: "2001", i: 6, s: 12, ss: 15 },
//     2002: { id: "2002", i: 8, s: 21, ss: 93 },
//     2003: { id: "2003", i: 3, s: 20, ss: 38 },
//     2005: { id: "2005", i: 5, s: 5, ss: 7 },
//     2006: { id: "2006", i: 6, s: 8, ss: 10 },
//     2007: { id: "2007", i: 2, s: 2, ss: 3 },
//     2008: { id: "2008", i: 6, s: 6, ss: 91 },
//     2009: { id: "2009", i: 2, s: 18, ss: 26 },
//     2011: { id: "2011", i: 6, s: 27, ss: 49 },
//     2012: { id: "2012", i: 11, s: 24, ss: 40 },
//     2013: { id: "2013", i: 6, s: 6, ss: 44 },
//     2014: { id: "2014", i: 6, s: 13, ss: 68 },
//     2015: { id: "2015", i: 6, s: 16, ss: 62 },
//     2016: { id: "2016", i: 3, s: 3, ss: 4 },
//     2017: { id: "2017", i: 2, s: 18, ss: 56 },
//     2018: { id: "2018", i: 10, s: 11, ss: 43 },
//     2019: { id: "2019", i: 2, s: 2, ss: 3 },
//     2020: { id: "2020", i: 6, s: 27, ss: 50 },
//     2022: { id: "2022", i: 7, s: 7, ss: 9 },
//     2023: { id: "2023", i: 6, s: 13, ss: 83 },
//     2025: { id: "2025", i: 6, s: 16, ss: 24 },
//     2028: { id: "2028", i: 7, s: 26, ss: 78 },
//     2030: { id: "2030", i: 6, s: 27, ss: 50 },
//     2031: { id: "2031", i: 5, s: 5, ss: 7 },
//     2033: { id: "2033", i: 6, s: 27, ss: 70 },
//     2038: { id: "2038", i: 7, s: 26, ss: 48 },
//     2039: { id: "2039", i: 10, s: 11, ss: 45 },
//     2048: { id: "2048", i: 2, s: 2, ss: 80 },
//     2051: { id: "2051", i: 3, s: 20, ss: 38 },
//     2057: { id: "2057", i: 10, s: 25, ss: 90 },
//     2060: { id: "2060", i: 2, s: 18, ss: 26 },
//     2066: { id: "2066", i: 3, s: 3, ss: 4 },
//     2068: { id: "2068", i: 2, s: 18, ss: 69 },
//     2078: { id: "2078", i: 8, s: 9, ss: 42 },
//     2080: { id: "2080", i: 2, s: 2, ss: 47 },
//     2083: { id: "2083", i: 6, s: 13, ss: 83 },
//     2086: { id: "2086", i: 7, s: 26, ss: 78 },
//     2088: { id: "2088", i: 2, s: 2, ss: 3 },
//     2096: { id: "2096", i: 5, s: 5, ss: 7 },
//     2098: { id: "2098", i: 10, s: 17, ss: 82 },
//     2099: { id: "2099", i: 8, s: 29, ss: 72 },
//     2100: { id: "2100", i: 7, s: 7, ss: 9 },
//     2101: { id: "2101", i: 7, s: 7, ss: 52 },
//     2102: { id: "2102", i: 10, s: 11, ss: 17 },
//     2103: { id: "2103", i: 2, s: 2, ss: 3 },
//     2107: { id: "2107", i: 2, s: 2, ss: 47 },
//     2108: { id: "2108", i: 6, s: 8, ss: 23 },
//     2110: { id: "2110", i: 2, s: 18, ss: 69 },
//     2111: { id: "2111", i: 6, s: 27, ss: 55 },
//     2112: { id: "2112", i: 8, s: 9, ss: 11 },
//     2113: { id: "2113", i: 2, s: 18, ss: 56 },
//     2115: { id: "2115", i: 2, s: 18, ss: 56 },
//     2116: { id: "2116", i: 8, s: 21, ss: 31 },
//     2117: { id: "2117", i: 2, s: 2, ss: 3 },
//     2118: { id: "2118", i: 2, s: 2, ss: 3 },
//     2119: { id: "2119", i: 9, s: 10, ss: 66 },
//     2120: { id: "2120", i: 5, s: 30, ss: 73 },
//     2121: { id: "2121", i: 7, s: 7, ss: 9 },
//     2122: { id: "2122", i: 6, s: 22, ss: 36 },
//     2123: { id: "2123", i: 6, s: 27, ss: 55 },
//     2125: { id: "2125", i: 6, s: 6, ss: 77 },
//     2126: { id: "2126", i: 5, s: 5, ss: 94 },
//     2127: { id: "2127", i: 6, s: 13, ss: 83 },
//     2128: { id: "2128", i: 2, s: 18, ss: 26 },
//     2129: { id: "2129", i: 10, s: 25, ss: 90 },
//     2130: { id: "2130", i: 10, s: 25, ss: 90 },
//     2131: { id: "2131", i: 6, s: 6, ss: 44 },
//     2132: { id: "2132", i: 2, s: 18, ss: 69 },
//     2133: { id: "2133", i: 8, s: 9, ss: 60 },
//     2135: { id: "2135", i: 5, s: 30, ss: 73 },
//     2136: { id: "2136", i: 6, s: 22, ss: 59 },
//     2137: { id: "2137", i: 5, s: 5, ss: 94 },
//     2138: { id: "2138", i: 5, s: 30, ss: 73 },
//     2139: { id: "2139", i: 3, s: 3, ss: 4 },
//     2142: { id: "2142", i: 5, s: 5, ss: 94 },
//     2146: { id: "2146", i: 2, s: 2, ss: 47 },
//     2148: { id: "2148", i: 6, s: 13, ss: 68 },
//     2150: { id: "2150", i: 6, s: 8, ss: 23 },
//     2153: { id: "2153", i: 10, s: 11, ss: 17 },
//     2155: { id: "2155", i: 10, s: 11, ss: 17 },
//     2156: { id: "2156", i: 2, s: 2, ss: 47 },
//     2157: { id: "2157", i: 5, s: 5, ss: 94 },
//     2158: { id: "2158", i: 5, s: 30, ss: 73 },
//     2159: { id: "2159", i: 5, s: 30, ss: 73 },
//     2160: { id: "2160", i: 5, s: 30, ss: 92 },
//     2161: { id: "2161", i: 5, s: 5, ss: 79 },
//     2162: { id: "2162", i: 5, s: 5, ss: 94 },
//     2163: { id: "2163", i: 2, s: 18, ss: 26 },
//     2165: { id: "2165", i: 2, s: 2, ss: 47 },
//     2166: { id: "2166", i: 7, s: 23, ss: 37 },
//     2168: { id: "2168", i: 2, s: 2, ss: 47 },
//     2169: { id: "2169", i: 10, s: 25, ss: 46 },
//     2170: { id: "2170", i: 5, s: 5, ss: 94 },
//     2171: { id: "2171", i: 5, s: 5, ss: 94 },
//     2175: { id: "2175", i: 6, s: 12, ss: 15 },
//     2176: { id: "2176", i: 7, s: 7, ss: 22 },
//     2177: { id: "2177", i: 7, s: 7, ss: 52 },
//     2178: { id: "2178", i: 11, s: 24, ss: 64 },
//     2180: { id: "2180", i: 6, s: 12, ss: 35 },
//     2181: { id: "2181", i: 5, s: 5, ss: 94 },
//     2182: { id: "2182", i: 9, s: 10, ss: 27 },
//     2183: { id: "2183", i: 2, s: 2, ss: 3 },
//     2185: { id: "2185", i: 5, s: 30, ss: 92 },
//     2186: { id: "2186", i: 5, s: 5, ss: 7 },
//     2187: { id: "2187", i: 2, s: 18, ss: 26 },
//     2188: { id: "2188", i: 10, s: 11, ss: 57 },
//     2189: { id: "2189", i: 6, s: 12, ss: 35 },
//     2190: { id: "2190", i: 5, s: 30, ss: 92 },
//     2191: { id: "2191", i: "", s: "", ss: 85 },
//     2192: { id: "2192", i: 5, s: 30, ss: 73 },
//     2193: { id: "2193", i: 2, s: 18, ss: 69 },
//     2195: { id: "2195", i: 2, s: 18, ss: 56 },
//     2196: { id: "2196", i: 5, s: 5, ss: 7 },
//     2197: { id: "2197", i: 5, s: 5, ss: 94 },
//     2198: { id: "2198", i: 8, s: 21, ss: 31 },
//     2199: { id: "2199", i: 6, s: 27, ss: 50 },
//     2202: { id: "2202", i: 2, s: 2, ss: 3 },
//     2203: { id: "2203", i: 7, s: 23, ss: 37 },
//     2205: { id: "2205", i: 2, s: 2, ss: 47 },
//     2207: { id: "2207", i: 2, s: 2, ss: 47 },
//     2208: { id: "2208", i: 10, s: 11, ss: 57 },
//     2209: { id: "2209", i: 7, s: 7, ss: 52 },
//     2210: { id: "2210", i: 2, s: 2, ss: 47 },
//     2211: { id: "2211", i: 5, s: 5, ss: 7 },
//     2212: { id: "2212", i: 8, s: 9, ss: 60 },
//     2213: { id: "2213", i: 6, s: 22, ss: 59 },
//     2215: { id: "2215", i: 2, s: 2, ss: 47 },
//     2216: { id: "2216", i: 5, s: 30, ss: 92 },
//     2217: { id: "2217", i: 6, s: 8, ss: 23 },
//     2218: { id: "2218", i: 9, s: 10, ss: 66 },
//     2219: { id: "2219", i: 5, s: 30, ss: 73 },
//     2221: { id: "2221", i: 2, s: 18, ss: 69 },
//     2222: { id: "2222", i: 6, s: 13, ss: 83 },
//     2223: { id: "2223", i: 6, s: 13, ss: 83 },
//     2225: { id: "2225", i: 6, s: 12, ss: 35 },
//     2226: { id: "2226", i: 9, s: 10, ss: 13 },
//     2227: { id: "2227", i: 2, s: 18, ss: 56 },
//     2228: { id: "2228", i: 6, s: 27, ss: 55 },
//     2230: { id: "2230", i: 6, s: 6, ss: 77 },
//     2231: { id: "2231", i: 2, s: 2, ss: 3 },
//     2232: { id: "2232", i: 6, s: 27, ss: 50 },
//     2233: { id: "2233", i: 2, s: 18, ss: 26 },
//     2235: { id: "2235", i: 5, s: 30, ss: 92 },
//     2236: { id: "2236", i: 11, s: 24, ss: 64 },
//     2238: { id: "2238", i: 6, s: 16, ss: 62 },
//     2239: { id: "2239", i: 7, s: 26, ss: 48 },
//     2250: { id: "2250", i: 7, s: 7, ss: 52 },
//     2251: { id: "2251", i: 5, s: 30, ss: 92 },
//     2252: { id: "2252", i: 5, s: 30, ss: 92 },
//     2255: { id: "2255", i: 6, s: 8, ss: 86 },
//     2256: { id: "2256", i: 5, s: 5, ss: 94 },
//     2257: { id: "2257", i: 5, s: 5, ss: 94 },
//     2258: { id: "2258", i: 2, s: 18, ss: 69 },
//     2260: { id: "2260", i: 10, s: 11, ss: 20 },
//     2262: { id: "2262", i: 2, s: 18, ss: 56 },
//     2263: { id: "2263", i: 3, s: 20, ss: 30 },
//     2265: { id: "2265", i: 10, s: 11, ss: 57 },
//     2266: { id: "2266", i: 2, s: 18, ss: 56 },
//     2269: { id: "2269", i: 5, s: 5, ss: 94 },
//     2270: { id: "2270", i: 2, s: 2, ss: 47 },
//     2273: { id: "2273", i: 5, s: 30, ss: 73 },
//     2276: { id: "2276", i: 10, s: 11, ss: 20 },
//     2278: { id: "2278", i: 2, s: 2, ss: 3 },
//     2279: { id: "2279", i: 5, s: 30, ss: 73 },
//     2280: { id: "2280", i: 7, s: 7, ss: 52 },
//     2281: { id: "2281", i: 1, s: 1, ss: 71 },
//     2282: { id: "2282", i: 6, s: 8, ss: 12 },
//     2283: { id: "2283", i: 10, s: 11, ss: 20 },
//     2285: { id: "2285", i: 6, s: 13, ss: 68 },
//     2286: { id: "2286", i: 2, s: 2, ss: 3 },
//     2288: { id: "2288", i: 2, s: 2, ss: 3 },
//     2289: { id: "2289", i: 5, s: 5, ss: 7 },
//     2292: { id: "2292", i: 2, s: 2, ss: 5 },
//     2293: { id: "2293", i: 5, s: 30, ss: 73 },
//     2295: { id: "2295", i: 2, s: 18, ss: 69 },
//     2296: { id: "2296", i: 2, s: 18, ss: 56 },
//     2298: { id: "2298", i: 6, s: 27, ss: 50 },
//     2299: { id: "2299", i: 6, s: 27, ss: 55 },
//     2300: { id: "2300", i: 10, s: 17, ss: 25 },
//     2302: { id: "2302", i: 8, s: 9, ss: 60 },
//     2307: { id: "2307", i: 6, s: 27, ss: 55 },
//     2308: { id: "2308", i: 7, s: 26, ss: 78 },
//     2309: { id: "2309", i: 6, s: 8, ss: 86 },
//     2310: { id: "2310", i: 2, s: 2, ss: 47 },
//     2312: { id: "2312", i: 3, s: 20, ss: 32 },
//     2313: { id: "2313", i: 6, s: 27, ss: 50 },
//     2314: { id: "2314", i: 8, s: 21, ss: 93 },
//     2317: { id: "2317", i: 9, s: 10, ss: 13 },
//     2318: { id: "2318", i: 3, s: 28, ss: 67 },
//     2319: { id: "2319", i: 9, s: 10, ss: 87 },
//     2320: { id: "2320", i: 8, s: 21, ss: 93 },
//     2322: { id: "2322", i: 8, s: 21, ss: 31 },
//     2323: { id: "2323", i: 10, s: 11, ss: 20 },
//     2324: { id: "2324", i: 3, s: 20, ss: 32 },
//     2326: { id: "2326", i: 8, s: 9, ss: 60 },
//     2327: { id: "2327", i: 0, s: 0, ss: 0 },
//     2328: { id: "2328", i: 3, s: 28, ss: 67 },
//     2329: { id: "2329", i: 2, s: 2, ss: 3 },
//     2330: { id: "2330", i: 2, s: 2, ss: 3 },
//     2331: { id: "2331", i: 6, s: 27, ss: 50 },
//     2333: { id: "2333", i: 6, s: 16, ss: 62 },
//     2336: { id: "2336", i: 8, s: 9, ss: 81 },
//     2337: { id: "2337", i: 1, s: 1, ss: 2 },
//     2338: { id: "2338", i: 10, s: 11, ss: 45 },
//     2339: { id: "2339", i: 6, s: 16, ss: 24 },
//     2340: { id: "2340", i: 2, s: 2, ss: 47 },
//     2341: { id: "2341", i: 8, s: 21, ss: 31 },
//     2342: { id: "2342", i: 7, s: 26, ss: 48 },
//     2343: { id: "2343", i: 10, s: 25, ss: 53 },
//     2346: { id: "2346", i: 10, s: 11, ss: 20 },
//     2348: { id: "2348", i: 5, s: 5, ss: 7 },
//     2349: { id: "2349", i: 2, s: 2, ss: 5 },
//     2355: { id: "2355", i: 2, s: 18, ss: 56 },
//     2356: { id: "2356", i: 3, s: 3, ss: 4 },
//     2357: { id: "2357", i: 10, s: 11, ss: 14 },
//     2358: { id: "2358", i: 6, s: 13, ss: 19 },
//     2359: { id: "2359", i: 5, s: 5, ss: 94 },
//     2360: { id: "2360", i: 9, s: 14, ss: 18 },
//     2362: { id: "2362", i: 8, s: 9, ss: 60 },
//     2363: { id: "2363", i: 7, s: 26, ss: 78 },
//     2366: { id: "2366", i: 6, s: 6, ss: 77 },
//     2368: { id: "2368", i: 6, s: 27, ss: 50 },
//     2369: { id: "2369", i: 7, s: 26, ss: 48 },
//     2371: { id: "2371", i: 6, s: 12, ss: 15 },
//     2377: { id: "2377", i: 10, s: 11, ss: 57 },
//     2378: { id: "2378", i: 3, s: 28, ss: 67 },
//     2379: { id: "2379", i: 2, s: 2, ss: 5 },
//     2380: { id: "2380", i: 1, s: 1, ss: 1 },
//     2381: { id: "2381", i: 6, s: 13, ss: 68 },
//     2382: { id: "2382", i: 10, s: 11, ss: 43 },
//     2383: { id: "2383", i: 6, s: 6, ss: 8 },
//     2386: { id: "2386", i: 11, s: 24, ss: 64 },
//     2388: { id: "2388", i: 3, s: 3, ss: 4 },
//     2389: { id: "2389", i: 5, s: 30, ss: 73 },
//     2393: { id: "2393", i: 5, s: 30, ss: 92 },
//     2398: { id: "2398", i: 10, s: 11, ss: 20 },
//     2399: { id: "2399", i: 6, s: 27, ss: 50 },
//     2400: { id: "2400", i: 7, s: 7, ss: 9 },
//     2448: { id: "2448", i: 2, s: 18, ss: 56 },
//     2488: { id: "2488", i: 6, s: 16, ss: 24 },
//     2500: { id: "2500", i: 5, s: 30, ss: 92 },
//     2518: { id: "2518", i: 7, s: 7, ss: 52 },
//     2528: { id: "2528", i: 6, s: 22, ss: 51 },
//     2552: { id: "2552", i: 5, s: 5, ss: 94 },
//     2558: { id: "2558", i: 3, s: 3, ss: 4 },
//     2588: { id: "2588", i: 6, s: 8, ss: 75 },
//     2599: { id: "2599", i: 2, s: 2, ss: 3 },
//     2600: { id: "2600", i: 8, s: 9, ss: 42 },
//     2601: { id: "2601", i: 3, s: 28, ss: 67 },
//     2606: { id: "2606", i: 2, s: 2, ss: 47 },
//     2607: { id: "2607", i: 5, s: 5, ss: 7 },
//     2608: { id: "2608", i: 2, s: 2, ss: 3 },
//     2611: { id: "2611", i: 3, s: 20, ss: 30 },
//     2616: { id: "2616", i: 5, s: 5, ss: 94 },
//     2618: { id: "2618", i: 10, s: 17, ss: 82 },
//     2623: { id: "2623", i: 8, s: 9, ss: 11 },
//     2628: { id: "2628", i: 3, s: 28, ss: 67 },
//     2633: { id: "2633", i: 5, s: 5, ss: 7 },
//     2638: { id: "2638", i: 1, s: 1, ss: 1 },
//     2660: { id: "2660", i: 7, s: 7, ss: 9 },
//     2662: { id: "2662", i: 6, s: 12, ss: 35 },
//     2663: { id: "2663", i: 2, s: 18, ss: 56 },
//     2666: { id: "2666", i: 3, s: 20, ss: 38 },
//     2668: { id: "2668", i: 10, s: 17, ss: 82 },
//     2669: { id: "2669", i: 2, s: 2, ss: 47 },
//     2678: { id: "2678", i: 6, s: 27, ss: 55 },
//     2680: { id: "2680", i: 3, s: 20, ss: 63 },
//     2682: { id: "2682", i: 10, s: 25, ss: 53 },
//     2683: { id: "2683", i: 6, s: 27, ss: 49 },
//     2686: { id: "2686", i: 11, s: 24, ss: 40 },
//     2688: { id: "2688", i: 1, s: 1, ss: 2 },
//     2689: { id: "2689", i: 8, s: 21, ss: 93 },
//     2696: { id: "2696", i: 5, s: 5, ss: 94 },
//     2698: { id: "2698", i: 6, s: 27, ss: 55 },
//     2699: { id: "2699", i: 2, s: 2, ss: 3 },
//     2700: { id: "2700", i: 5, s: 30, ss: 73 },
//     2708: { id: "2708", i: 7, s: 7, ss: 22 },
//     2718: { id: "2718", i: 3, s: 20, ss: 38 },
//     2722: { id: "2722", i: 0, s: 0, ss: 0 },
//     2727: { id: "2727", i: 10, s: 11, ss: 57 },
//     2728: { id: "2728", i: 11, s: 24, ss: 40 },
//     2738: { id: "2738", i: 8, s: 9, ss: 11 },
//     2768: { id: "2768", i: 2, s: 2, ss: 3 },
//     2772: { id: "2772", i: 2, s: 2, ss: 3 },
//     2777: { id: "2777", i: 2, s: 2, ss: 3 },
//     2778: { id: "2778", i: "", s: "", ss: 85 },
//     2779: { id: "2779", i: 6, s: 12, ss: 15 },
//     2788: { id: "2788", i: 10, s: 11, ss: 20 },
//     2789: { id: "2789", i: 2, s: 18, ss: 56 },
//     2798: { id: "2798", i: 11, s: 19, ss: 28 },
//     2799: { id: "2799", i: 3, s: 20, ss: 32 },
//     2800: { id: "2800", i: "", s: "", ss: 85 },
//     2801: { id: "2801", i: "", s: "", ss: 85 },
//     2802: { id: "2802", i: "", s: "", ss: 85 },
//     2803: { id: "2803", i: "", s: "", ss: 85 },
//     2804: { id: "2804", i: "", s: "", ss: 85 },
//     2806: { id: "2806", i: "", s: "", ss: 85 },
//     2807: { id: "2807", i: "", s: "", ss: 85 },
//     2809: { id: "2809", i: "", s: "", ss: 85 },
//     2810: { id: "2810", i: "", s: "", ss: 85 },
//     2811: { id: "2811", i: "", s: "", ss: 85 },
//     2812: { id: "2812", i: "", s: "", ss: 85 },
//     2813: { id: "2813", i: "", s: "", ss: 85 },
//     2814: { id: "2814", i: "", s: "", ss: 85 },
//     2817: { id: "2817", i: "", s: "", ss: 85 },
//     2819: { id: "2819", i: "", s: "", ss: 85 },
//     2820: { id: "2820", i: "", s: "", ss: 85 },
//     2821: { id: "2821", i: "", s: "", ss: 85 },
//     2822: { id: "2822", i: "", s: "", ss: 85 },
//     2823: { id: "2823", i: "", s: "", ss: 85 },
//     2824: { id: "2824", i: "", s: "", ss: 85 },
//     2825: { id: "2825", i: "", s: "", ss: 85 },
//     2826: { id: "2826", i: "", s: "", ss: 85 },
//     2827: { id: "2827", i: "", s: "", ss: 85 },
//     2828: { id: "2828", i: "", s: "", ss: 85 },
//     2829: { id: "2829", i: "", s: "", ss: 85 },
//     2832: { id: "2832", i: "", s: "", ss: 85 },
//     2833: { id: "2833", i: "", s: "", ss: 85 },
//     2834: { id: "2834", i: "", s: "", ss: 85 },
//     2836: { id: "2836", i: "", s: "", ss: 85 },
//     2838: { id: "2838", i: "", s: "", ss: 85 },
//     2839: { id: "2839", i: "", s: "", ss: 85 },
//     2840: { id: "2840", i: "", s: "", ss: 85 },
//     2843: { id: "2843", i: "", s: "", ss: 85 },
//     2845: { id: "2845", i: "", s: "", ss: 85 },
//     2846: { id: "2846", i: "", s: "", ss: 85 },
//     2848: { id: "2848", i: "", s: "", ss: 85 },
//     2849: { id: "2849", i: "", s: "", ss: 85 },
//     2858: { id: "2858", i: 3, s: 20, ss: 38 },
//     2863: { id: "2863", i: 2, s: 18, ss: 56 },
//     2866: { id: "2866", i: 10, s: 25, ss: 53 },
//     2868: { id: "2868", i: 2, s: 2, ss: 3 },
//     2869: { id: "2869", i: 2, s: 2, ss: 47 },
//     2877: { id: "2877", i: 5, s: 5, ss: 79 },
//     2878: { id: "2878", i: 7, s: 23, ss: 37 },
//     2880: { id: "2880", i: 10, s: 25, ss: 53 },
//     2882: { id: "2882", i: 6, s: 22, ss: 36 },
//     2883: { id: "2883", i: 11, s: 24, ss: 64 },
//     2885: { id: "2885", i: 10, s: 11, ss: 20 },
//     2886: { id: "2886", i: 1, s: 1, ss: 2 },
//     2888: { id: "2888", i: 3, s: 3, ss: 4 },
//     2892: { id: "2892", i: 2, s: 2, ss: 3 },
//     2898: { id: "2898", i: 9, s: 10, ss: 66 },
//     2899: { id: "2899", i: 8, s: 29, ss: 72 },
//     2902: { id: "2902", i: 11, s: 24, ss: 64 },
//     2905: { id: "2905", i: 8, s: 21, ss: 93 },
//     2908: { id: "2908", i: 7, s: 7, ss: 52 },
//     2914: { id: "2914", i: 6, s: 27, ss: 55 },
//     2915: { id: "2915", i: 3, s: 20, ss: 32 },
//     2916: { id: "2916", i: 11, s: 19, ss: 28 },
//     2917: { id: "2917", i: 5, s: 30, ss: 73 },
//     2971: { id: "2971", i: 6, s: 8, ss: 12 },
//     2972: { id: "2972", i: 1, s: 1, ss: 71 },
//     2973: { id: "2973", i: 6, s: 27, ss: 50 },
//     2974: { id: "2974", i: 10, s: 25, ss: 90 },
//     2975: { id: "2975", i: 6, s: 6, ss: 77 },
//     2981: { id: "2981", i: 5, s: 30, ss: 92 },
//     2983: { id: "2983", i: 3, s: 20, ss: 38 },
//     2989: { id: "2989", i: 2, s: 18, ss: 56 },
//     2995: { id: "2995", i: 3, s: 20, ss: 30 },
//     2996: { id: "2996", i: 0, s: 0, ss: 0 },
//     3001: { id: "3001", i: "", s: "", ss: 85 },
//     3003: { id: "3003", i: "", s: "", ss: 85 },
//     3005: { id: "3005", i: "", s: "", ss: 85 },
//     3007: { id: "3007", i: "", s: "", ss: 85 },
//     3010: { id: "3010", i: "", s: "", ss: 85 },
//     3011: { id: "3011", i: "", s: "", ss: 85 },
//     3012: { id: "3012", i: "", s: "", ss: 85 },
//     3015: { id: "3015", i: "", s: "", ss: 85 },
//     3016: { id: "3016", i: "", s: "", ss: 85 },
//     3019: { id: "3019", i: "", s: "", ss: 85 },
//     3020: { id: "3020", i: "", s: "", ss: 85 },
//     3021: { id: "3021", i: "", s: "", ss: 85 },
//     3022: { id: "3022", i: "", s: "", ss: 85 },
//     3023: { id: "3023", i: "", s: "", ss: 85 },
//     3024: { id: "3024", i: "", s: "", ss: 85 },
//     3027: { id: "3027", i: "", s: "", ss: 85 },
//     3031: { id: "3031", i: "", s: "", ss: 85 },
//     3032: { id: "3032", i: "", s: "", ss: 85 },
//     3033: { id: "3033", i: "", s: "", ss: 85 },
//     3034: { id: "3034", i: "", s: "", ss: 85 },
//     3036: { id: "3036", i: "", s: "", ss: 85 },
//     3037: { id: "3037", i: "", s: "", ss: 85 },
//     3040: { id: "3040", i: "", s: "", ss: 85 },
//     3043: { id: "3043", i: "", s: "", ss: 85 },
//     3047: { id: "3047", i: "", s: "", ss: 85 },
//     3048: { id: "3048", i: "", s: "", ss: 85 },
//     3049: { id: "3049", i: "", s: "", ss: 85 },
//     3050: { id: "3050", i: "", s: "", ss: 85 },
//     3051: { id: "3051", i: "", s: "", ss: 85 },
//     3053: { id: "3053", i: "", s: "", ss: 85 },
//     3055: { id: "3055", i: "", s: "", ss: 85 },
//     3058: { id: "3058", i: "", s: "", ss: 85 },
//     3065: { id: "3065", i: "", s: "", ss: 85 },
//     3067: { id: "3067", i: "", s: "", ss: 85 },
//     3069: { id: "3069", i: "", s: "", ss: 85 },
//     3070: { id: "3070", i: "", s: "", ss: 85 },
//     3071: { id: "3071", i: "", s: "", ss: 85 },
//     3072: { id: "3072", i: "", s: "", ss: 85 },
//     3073: { id: "3073", i: "", s: "", ss: 85 },
//     3074: { id: "3074", i: "", s: "", ss: 85 },
//     3076: { id: "3076", i: "", s: "", ss: 85 },
//     3077: { id: "3077", i: "", s: "", ss: 85 },
//     3079: { id: "3079", i: "", s: "", ss: 85 },
//     3080: { id: "3080", i: "", s: "", ss: 85 },
//     3081: { id: "3081", i: "", s: "", ss: 85 },
//     3082: { id: "3082", i: "", s: "", ss: 85 },
//     3086: { id: "3086", i: "", s: "", ss: 85 },
//     3087: { id: "3087", i: "", s: "", ss: 85 },
//     3088: { id: "3088", i: "", s: "", ss: 85 },
//     3091: { id: "3091", i: "", s: "", ss: 85 },
//     3092: { id: "3092", i: "", s: "", ss: 85 },
//     3093: { id: "3093", i: "", s: "", ss: 85 },
//     3096: { id: "3096", i: "", s: "", ss: 85 },
//     3097: { id: "3097", i: "", s: "", ss: 85 },
//     3099: { id: "3099", i: "", s: "", ss: 85 },
//     3100: { id: "3100", i: "", s: "", ss: 85 },
//     3108: { id: "3108", i: "", s: "", ss: 85 },
//     3109: { id: "3109", i: "", s: "", ss: 85 },
//     3110: { id: "3110", i: "", s: "", ss: 85 },
//     3111: { id: "3111", i: "", s: "", ss: 85 },
//     3115: { id: "3115", i: "", s: "", ss: 85 },
//     3117: { id: "3117", i: "", s: "", ss: 85 },
//     3118: { id: "3118", i: "", s: "", ss: 85 },
//     3119: { id: "3119", i: "", s: "", ss: 85 },
//     3121: { id: "3121", i: "", s: "", ss: 85 },
//     3122: { id: "3122", i: "", s: "", ss: 85 },
//     3124: { id: "3124", i: "", s: "", ss: 85 },
//     3125: { id: "3125", i: "", s: "", ss: 85 },
//     3127: { id: "3127", i: "", s: "", ss: 85 },
//     3128: { id: "3128", i: "", s: "", ss: 85 },
//     3130: { id: "3130", i: "", s: "", ss: 85 },
//     3132: { id: "3132", i: "", s: "", ss: 85 },
//     3134: { id: "3134", i: "", s: "", ss: 85 },
//     3136: { id: "3136", i: "", s: "", ss: 85 },
//     3141: { id: "3141", i: "", s: "", ss: 85 },
//     3143: { id: "3143", i: "", s: "", ss: 85 },
//     3145: { id: "3145", i: "", s: "", ss: 85 },
//     3147: { id: "3147", i: "", s: "", ss: 85 },
//     3149: { id: "3149", i: "", s: "", ss: 85 },
//     3151: { id: "3151", i: "", s: "", ss: 85 },
//     3157: { id: "3157", i: "", s: "", ss: 85 },
//     3160: { id: "3160", i: "", s: "", ss: 85 },
//     3162: { id: "3162", i: "", s: "", ss: 85 },
//     3163: { id: "3163", i: "", s: "", ss: 85 },
//     3165: { id: "3165", i: "", s: "", ss: 85 },
//     3166: { id: "3166", i: "", s: "", ss: 85 },
//     3167: { id: "3167", i: "", s: "", ss: 85 },
//     3173: { id: "3173", i: "", s: "", ss: 85 },
//     3174: { id: "3174", i: "", s: "", ss: 85 },
//     3175: { id: "3175", i: "", s: "", ss: 85 },
//     3176: { id: "3176", i: "", s: "", ss: 85 },
//     3181: { id: "3181", i: "", s: "", ss: 85 },
//     3182: { id: "3182", i: "", s: "", ss: 85 },
//     3185: { id: "3185", i: "", s: "", ss: 85 },
//     3186: { id: "3186", i: "", s: "", ss: 85 },
//     3187: { id: "3187", i: "", s: "", ss: 85 },
//     3188: { id: "3188", i: "", s: "", ss: 85 },
//     3191: { id: "3191", i: "", s: "", ss: 85 },
//     3193: { id: "3193", i: "", s: "", ss: 85 },
//     3194: { id: "3194", i: "", s: "", ss: 85 },
//     3197: { id: "3197", i: "", s: "", ss: 85 },
//     3198: { id: "3198", i: "", s: "", ss: 85 },
//     3199: { id: "3199", i: "", s: "", ss: 85 },
//     3300: { id: "3300", i: 10, s: 11, ss: 20 },
//     3301: { id: "3301", i: 2, s: 2, ss: 3 },
//     3302: { id: "3302", i: 10, s: 11, ss: 20 },
//     3303: { id: "3303", i: 11, s: 24, ss: 64 },
//     3306: { id: "3306", i: 6, s: 27, ss: 50 },
//     3308: { id: "3308", i: 6, s: 22, ss: 59 },
//     3309: { id: "3309", i: 5, s: 30, ss: 73 },
//     3311: { id: "3311", i: 2, s: 18, ss: 69 },
//     3313: { id: "3313", i: 2, s: 18, ss: 26 },
//     3315: { id: "3315", i: 7, s: 7, ss: 9 },
//     3316: { id: "3316", i: 2, s: 2, ss: 47 },
//     3318: { id: "3318", i: 9, s: 10, ss: 13 },
//     3319: { id: "3319", i: 2, s: 2, ss: 47 },
//     3320: { id: "3320", i: 5, s: 5, ss: 7 },
//     3321: { id: "3321", i: 2, s: 18, ss: 56 },
//     3322: { id: "3322", i: 6, s: 27, ss: 50 },
//     3323: { id: "3323", i: 2, s: 18, ss: 26 },
//     3326: { id: "3326", i: 6, s: 27, ss: 70 },
//     3328: { id: "3328", i: 3, s: 3, ss: 4 },
//     3329: { id: "3329", i: 3, s: 20, ss: 38 },
//     3330: { id: "3330", i: 8, s: 29, ss: 72 },
//     3331: { id: "3331", i: 9, s: 14, ss: 84 },
//     3332: { id: "3332", i: 9, s: 14, ss: 84 },
//     3333: { id: "3333", i: 2, s: 2, ss: 3 },
//     3336: { id: "3336", i: 7, s: 26, ss: 78 },
//     3337: { id: "3337", i: 11, s: 24, ss: 64 },
//     3339: { id: "3339", i: 10, s: 11, ss: 17 },
//     3344: { id: "3344", i: 6, s: 27, ss: 50 },
//     3347: { id: "3347", i: 5, s: 5, ss: 94 },
//     3348: { id: "3348", i: 10, s: 11, ss: 17 },
//     3358: { id: "3358", i: 6, s: 13, ss: 16 },
//     3360: { id: "3360", i: 3, s: 20, ss: 38 },
//     3363: { id: "3363", i: 8, s: 21, ss: 93 },
//     3366: { id: "3366", i: 2, s: 2, ss: 3 },
//     3368: { id: "3368", i: 6, s: 22, ss: 59 },
//     3369: { id: "3369", i: 10, s: 25, ss: 53 },
//     3377: { id: "3377", i: 2, s: 2, ss: 3 },
//     3378: { id: "3378", i: 10, s: 25, ss: 53 },
//     3380: { id: "3380", i: 2, s: 2, ss: 3 },
//     3382: { id: "3382", i: 10, s: 25, ss: 53 },
//     3383: { id: "3383", i: 2, s: 2, ss: 3 },
//     3389: { id: "3389", i: 6, s: 22, ss: 36 },
//     3390: { id: "3390", i: 5, s: 5, ss: 7 },
//     3393: { id: "3393", i: 10, s: 11, ss: 20 },
//     3395: { id: "3395", i: 11, s: 24, ss: 40 },
//     3396: { id: "3396", i: 7, s: 26, ss: 78 },
//     3398: { id: "3398", i: 6, s: 27, ss: 50 },
//     3399: { id: "3399", i: 6, s: 8, ss: 33 },
//     3600: { id: "3600", i: 5, s: 30, ss: 92 },
//     3601: { id: "3601", i: 7, s: 7, ss: 9 },
//     3603: { id: "3603", i: 2, s: 2, ss: 5 },
//     3606: { id: "3606", i: 6, s: 16, ss: 24 },
//     3608: { id: "3608", i: 6, s: 27, ss: 55 },
//     3613: { id: "3613", i: 5, s: 5, ss: 79 },
//     3616: { id: "3616", i: 2, s: 2, ss: 3 },
//     3618: { id: "3618", i: 3, s: 3, ss: 4 },
//     3623: { id: "3623", i: 3, s: 20, ss: 63 },
//     3626: { id: "3626", i: 10, s: 17, ss: 25 },
//     3628: { id: "3628", i: 10, s: 11, ss: 20 },
//     3633: { id: "3633", i: 1, s: 1, ss: 2 },
//     3636: { id: "3636", i: 6, s: 6, ss: 77 },
//     3638: { id: "3638", i: 7, s: 26, ss: 78 },
//     3639: { id: "3639", i: 2, s: 2, ss: 3 },
//     3658: { id: "3658", i: 2, s: 2, ss: 47 },
//     3662: { id: "3662", i: 2, s: 2, ss: 47 },
//     3663: { id: "3663", i: 6, s: 16, ss: 24 },
//     3666: { id: "3666", i: 6, s: 8, ss: 23 },
//     3668: { id: "3668", i: 11, s: 19, ss: 28 },
//     3669: { id: "3669", i: 6, s: 22, ss: 54 },
//     3678: { id: "3678", i: 3, s: 20, ss: 30 },
//     3680: { id: "3680", i: 7, s: 7, ss: 22 },
//     3681: { id: "3681", i: 5, s: 5, ss: 94 },
//     3683: { id: "3683", i: 10, s: 25, ss: 53 },
//     3686: { id: "3686", i: 0, s: 0, ss: 0 },
//     3688: { id: "3688", i: 2, s: 2, ss: 5 },
//     3689: { id: "3689", i: 5, s: 30, ss: 73 },
//     3690: { id: "3690", i: 7, s: 7, ss: 52 },
//     3692: { id: "3692", i: 5, s: 5, ss: 7 },
//     3698: { id: "3698", i: 3, s: 3, ss: 4 },
//     3699: { id: "3699", i: 2, s: 2, ss: 5 },
//     3700: { id: "3700", i: 6, s: 6, ss: 91 },
//     3708: { id: "3708", i: 2, s: 18, ss: 56 },
//     3709: { id: "3709", i: 6, s: 27, ss: 50 },
//     3718: { id: "3718", i: 10, s: 11, ss: 57 },
//     3728: { id: "3728", i: 2, s: 18, ss: 56 },
//     3737: { id: "3737", i: 5, s: 5, ss: 7 },
//     3738: { id: "3738", i: 7, s: 7, ss: 9 },
//     3759: { id: "3759", i: 5, s: 5, ss: 7 },
//     3768: { id: "3768", i: 1, s: 1, ss: 71 },
//     3773: { id: "3773", i: 7, s: 7, ss: 52 },
//     3778: { id: "3778", i: 6, s: 27, ss: 55 },
//     3788: { id: "3788", i: 8, s: 9, ss: 11 },
//     3789: { id: "3789", i: 2, s: 18, ss: 56 },
//     3798: { id: "3798", i: 7, s: 7, ss: 9 },
//     3799: { id: "3799", i: 9, s: 10, ss: 27 },
//     3800: { id: "3800", i: 10, s: 11, ss: 58 },
//     3808: { id: "3808", i: 10, s: 11, ss: 45 },
//     3813: { id: "3813", i: 6, s: 22, ss: 51 },
//     3816: { id: "3816", i: 10, s: 11, ss: 20 },
//     3818: { id: "3818", i: 6, s: 27, ss: 50 },
//     3822: { id: "3822", i: 2, s: 18, ss: 69 },
//     3828: { id: "3828", i: 9, s: 14, ss: 84 },
//     3830: { id: "3830", i: 6, s: 13, ss: 16 },
//     3833: { id: "3833", i: 8, s: 9, ss: 60 },
//     3836: { id: "3836", i: 6, s: 22, ss: 54 },
//     3838: { id: "3838", i: 9, s: 15, ss: 34 },
//     3839: { id: "3839", i: 5, s: 5, ss: 94 },
//     3848: { id: "3848", i: 3, s: 20, ss: 38 },
//     3860: { id: "3860", i: 6, s: 27, ss: 50 },
//     3866: { id: "3866", i: 3, s: 3, ss: 4 },
//     3868: { id: "3868", i: 1, s: 1, ss: 39 },
//     3869: { id: "3869", i: 5, s: 30, ss: 73 },
//     3877: { id: "3877", i: 3, s: 20, ss: 38 },
//     3878: { id: "3878", i: 2, s: 18, ss: 56 },
//     3882: { id: "3882", i: 6, s: 13, ss: 19 },
//     3883: { id: "3883", i: 2, s: 2, ss: 3 },
//     3886: { id: "3886", i: 5, s: 30, ss: 73 },
//     3888: { id: "3888", i: 7, s: 7, ss: 9 },
//     3889: { id: "3889", i: 9, s: 15, ss: 34 },
//     3893: { id: "3893", i: 6, s: 13, ss: 83 },
//     3898: { id: "3898", i: 10, s: 11, ss: 45 },
//     3899: { id: "3899", i: 11, s: 24, ss: 64 },
//     3900: { id: "3900", i: 2, s: 2, ss: 3 },
//     3903: { id: "3903", i: 3, s: 20, ss: 38 },
//     3908: { id: "3908", i: 3, s: 20, ss: 30 },
//     3913: { id: "3913", i: 2, s: 2, ss: 47 },
//     3918: { id: "3918", i: 6, s: 8, ss: 12 },
//     3919: { id: "3919", i: 10, s: 11, ss: 20 },
//     3928: { id: "3928", i: 2, s: 18, ss: 69 },
//     3933: { id: "3933", i: 5, s: 5, ss: 7 },
//     3938: { id: "3938", i: 3, s: 20, ss: 63 },
//     3939: { id: "3939", i: 8, s: 9, ss: 81 },
//     3948: { id: "3948", i: 11, s: 19, ss: 28 },
//     3958: { id: "3958", i: 3, s: 20, ss: 30 },
//     3963: { id: "3963", i: 3, s: 20, ss: 38 },
//     3968: { id: "3968", i: 3, s: 3, ss: 4 },
//     3969: { id: "3969", i: 7, s: 7, ss: 22 },
//     3978: { id: "3978", i: 6, s: 12, ss: 15 },
//     3983: { id: "3983", i: 8, s: 21, ss: 76 },
//     3988: { id: "3988", i: 3, s: 3, ss: 4 },
//     3989: { id: "3989", i: 1, s: 1, ss: 39 },
//     3990: { id: "3990", i: 2, s: 2, ss: 3 },
//     3991: { id: "3991", i: 7, s: 26, ss: 78 },
//     3992: { id: "3992", i: 2, s: 2, ss: 47 },
//     3993: { id: "3993", i: 8, s: 9, ss: 60 },
//     3996: { id: "3996", i: 10, s: 11, ss: 57 },
//     3997: { id: "3997", i: 7, s: 26, ss: 48 },
//     3998: { id: "3998", i: 6, s: 27, ss: 50 },
//     3999: { id: "3999", i: 9, s: 15, ss: 21 },
//     4332: { id: "4332", i: "", s: "", ss: 85 },
//     4333: { id: "4333", i: "", s: "", ss: 85 },
//     4335: { id: "4335", i: "", s: "", ss: 85 },
//     4336: { id: "4336", i: "", s: "", ss: 85 },
//     4337: { id: "4337", i: "", s: "", ss: 85 },
//     4338: { id: "4338", i: "", s: "", ss: 85 },
//     6030: { id: "6030", i: 3, s: 20, ss: 32 },
//     6033: { id: "6033", i: 7, s: 26, ss: 48 },
//     6036: { id: "6036", i: 10, s: 11, ss: 20 },
//     6038: { id: "6038", i: 2, s: 18, ss: 56 },
//     6049: { id: "6049", i: 2, s: 2, ss: 47 },
//     6055: { id: "6055", i: 9, s: 10, ss: 95 },
//     6058: { id: "6058", i: 3, s: 20, ss: 30 },
//     6060: { id: "6060", i: 3, s: 28, ss: 67 },
//     6063: { id: "6063", i: 2, s: 18, ss: 56 },
//     6066: { id: "6066", i: 3, s: 20, ss: 30 },
//     6068: { id: "6068", i: 6, s: 12, ss: 15 },
//     6069: { id: "6069", i: 3, s: 20, ss: 38 },
//     6078: { id: "6078", i: 5, s: 30, ss: 73 },
//     6080: { id: "6080", i: 2, s: 18, ss: 56 },
//     6083: { id: "6083", i: 10, s: 25, ss: 90 },
//     6088: { id: "6088", i: 7, s: 26, ss: 48 },
//     6090: { id: "6090", i: 2, s: 2, ss: 5 },
//     6093: { id: "6093", i: 2, s: 2, ss: 47 },
//     6098: { id: "6098", i: 2, s: 2, ss: 47 },
//     6099: { id: "6099", i: 3, s: 20, ss: 30 },
//     6100: { id: "6100", i: 6, s: 12, ss: 35 },
//     6108: { id: "6108", i: 5, s: 5, ss: 7 },
//     6110: { id: "6110", i: 6, s: 22, ss: 51 },
//     6111: { id: "6111", i: 2, s: 2, ss: 3 },
//     6113: { id: "6113", i: 6, s: 6, ss: 44 },
//     6116: { id: "6116", i: 6, s: 27, ss: 50 },
//     6117: { id: "6117", i: 10, s: 25, ss: 53 },
//     6118: { id: "6118", i: 5, s: 5, ss: 94 },
//     6119: { id: "6119", i: 10, s: 25, ss: 53 },
//     6122: { id: "6122", i: 3, s: 3, ss: 4 },
//     6123: { id: "6123", i: 10, s: 25, ss: 90 },
//     6127: { id: "6127", i: 5, s: 5, ss: 94 },
//     6128: { id: "6128", i: 8, s: 9, ss: 60 },
//     6133: { id: "6133", i: 7, s: 26, ss: 48 },
//     6136: { id: "6136", i: 1, s: 1, ss: 71 },
//     6138: { id: "6138", i: 3, s: 3, ss: 4 },
//     6158: { id: "6158", i: 2, s: 2, ss: 3 },
//     6160: { id: "6160", i: 5, s: 5, ss: 94 },
//     6161: { id: "6161", i: 3, s: 28, ss: 67 },
//     6162: { id: "6162", i: 6, s: 16, ss: 24 },
//     6163: { id: "6163", i: 10, s: 11, ss: 45 },
//     6166: { id: "6166", i: 2, s: 2, ss: 3 },
//     6168: { id: "6168", i: 7, s: 26, ss: 48 },
//     6169: { id: "6169", i: 6, s: 12, ss: 15 },
//     6178: { id: "6178", i: 3, s: 20, ss: 30 },
//     6182: { id: "6182", i: 2, s: 18, ss: 56 },
//     6183: { id: "6183", i: 9, s: 15, ss: 34 },
//     6185: { id: "6185", i: 5, s: 5, ss: 94 },
//     6186: { id: "6186", i: 9, s: 10, ss: 87 },
//     6188: { id: "6188", i: 7, s: 26, ss: 48 },
//     6189: { id: "6189", i: 2, s: 18, ss: 56 },
//     6190: { id: "6190", i: 3, s: 3, ss: 4 },
//     6193: { id: "6193", i: 2, s: 18, ss: 26 },
//     6196: { id: "6196", i: 3, s: 3, ss: 4 },
//     6198: { id: "6198", i: 10, s: 25, ss: 53 },
//     6199: { id: "6199", i: 3, s: 3, ss: 4 },
//     6288: { id: "6288", i: 6, s: 27, ss: 50 },
//     6600: { id: "6600", i: 5, s: 5, ss: 7 },
//     6601: { id: "6601", i: 6, s: 12, ss: 35 },
//     6606: { id: "6606", i: 5, s: 30, ss: 92 },
//     6608: { id: "6608", i: 7, s: 7, ss: 22 },
//     6609: { id: "6609", i: 5, s: 30, ss: 92 },
//     6611: { id: "6611", i: 2, s: 2, ss: 3 },
//     6616: { id: "6616", i: 8, s: 21, ss: 31 },
//     6618: { id: "6618", i: 5, s: 5, ss: 7 },
//     6622: { id: "6622", i: 5, s: 5, ss: 7 },
//     6626: { id: "6626", i: 2, s: 2, ss: 47 },
//     6628: { id: "6628", i: 5, s: 5, ss: 94 },
//     6633: { id: "6633", i: 7, s: 7, ss: 9 },
//     6663: { id: "6663", i: 6, s: 12, ss: 35 },
//     6666: { id: "6666", i: 2, s: 2, ss: 47 },
//     6668: { id: "6668", i: 2, s: 2, ss: 47 },
//     6669: { id: "6669", i: 5, s: 30, ss: 92 },
//     6677: { id: "6677", i: 2, s: 2, ss: 47 },
//     6680: { id: "6680", i: 8, s: 9, ss: 60 },
//     6690: { id: "6690", i: 6, s: 13, ss: 68 },
//     6699: { id: "6699", i: 5, s: 30, ss: 73 },
//     6805: { id: "6805", i: 10, s: 11, ss: 57 },
//     6806: { id: "6806", i: 3, s: 20, ss: 30 },
//     6808: { id: "6808", i: 9, s: 14, ss: 18 },
//     6811: { id: "6811", i: 6, s: 8, ss: 23 },
//     6812: { id: "6812", i: 6, s: 12, ss: 35 },
//     6816: { id: "6816", i: 2, s: 18, ss: 69 },
//     6818: { id: "6818", i: 3, s: 3, ss: 4 },
//     6819: { id: "6819", i: 6, s: 12, ss: 35 },
//     6820: { id: "6820", i: 7, s: 7, ss: 9 },
//     6821: { id: "6821", i: 5, s: 5, ss: 7 },
//     6822: { id: "6822", i: 6, s: 13, ss: 83 },
//     6823: { id: "6823", i: 4, s: 4, ss: 6 },
//     6826: { id: "6826", i: 5, s: 5, ss: 94 },
//     6828: { id: "6828", i: 1, s: 1, ss: 2 },
//     6829: { id: "6829", i: 2, s: 18, ss: 56 },
//     6830: { id: "6830", i: 6, s: 16, ss: 24 },
//     6833: { id: "6833", i: 5, s: 5, ss: 7 },
//     6836: { id: "6836", i: 9, s: 10, ss: 27 },
//     6837: { id: "6837", i: 3, s: 20, ss: 30 },
//     6838: { id: "6838", i: 6, s: 27, ss: 49 },
//     6839: { id: "6839", i: 1, s: 1, ss: 71 },
//     6855: { id: "6855", i: 5, s: 5, ss: 94 },
//     6858: { id: "6858", i: 6, s: 13, ss: 16 },
//     6860: { id: "6860", i: 7, s: 7, ss: 9 },
//     6862: { id: "6862", i: 6, s: 8, ss: 23 },
//     6865: { id: "6865", i: 10, s: 11, ss: 58 },
//     6866: { id: "6866", i: 3, s: 20, ss: 38 },
//     6868: { id: "6868", i: 9, s: 10, ss: 66 },
//     6869: { id: "6869", i: 7, s: 26, ss: 48 },
//     6877: { id: "6877", i: 3, s: 20, ss: 30 },
//     6878: { id: "6878", i: 2, s: 2, ss: 3 },
//     6880: { id: "6880", i: 10, s: 25, ss: 90 },
//     6881: { id: "6881", i: 3, s: 20, ss: 30 },
//     6882: { id: "6882", i: 6, s: 8, ss: 33 },
//     6885: { id: "6885", i: 11, s: 19, ss: 28 },
//     6886: { id: "6886", i: 3, s: 20, ss: 30 },
//     6888: { id: "6888", i: 2, s: 18, ss: 69 },
//     6889: { id: "6889", i: 6, s: 8, ss: 12 },
//     6890: { id: "6890", i: 8, s: 9, ss: 11 },
//     6893: { id: "6893", i: 5, s: 5, ss: 7 },
//     6896: { id: "6896", i: 5, s: 5, ss: 7 },
//     6898: { id: "6898", i: 10, s: 17, ss: 25 },
//     6899: { id: "6899", i: 7, s: 7, ss: 9 },
//     6900: { id: "6900", i: 2, s: 2, ss: 3 },
//     6908: { id: "6908", i: 7, s: 23, ss: 37 },
//     6909: { id: "6909", i: 6, s: 22, ss: 54 },
//     6913: { id: "6913", i: 6, s: 12, ss: 15 },
//     6918: { id: "6918", i: 6, s: 13, ss: 16 },
//     6919: { id: "6919", i: 6, s: 12, ss: 35 },
//     6928: { id: "6928", i: 6, s: 16, ss: 24 },
//     6933: { id: "6933", i: 7, s: 7, ss: 9 },
//     6939: { id: "6939", i: 10, s: 11, ss: 20 },
//     6958: { id: "6958", i: 2, s: 2, ss: 47 },
//     6966: { id: "6966", i: 6, s: 12, ss: 35 },
//     6968: { id: "6968", i: 2, s: 2, ss: 3 },
//     6969: { id: "6969", i: 6, s: 13, ss: 19 },
//     6978: { id: "6978", i: 5, s: 5, ss: 94 },
//     6988: { id: "6988", i: 6, s: 6, ss: 44 },
//     6989: { id: "6989", i: 2, s: 2, ss: 47 },
//     6993: { id: "6993", i: 6, s: 13, ss: 83 },
//     6996: { id: "6996", i: 5, s: 5, ss: 7 },
//     6998: { id: "6998", i: 5, s: 5, ss: 94 },
//     6999: { id: "6999", i: 2, s: 2, ss: 3 },
//     7200: { id: "7200", i: "", s: "", ss: 85 },
//     7226: { id: "7226", i: "", s: "", ss: 85 },
//     7230: { id: "7230", i: "", s: "", ss: 85 },
//     7231: { id: "7231", i: "", s: "", ss: 85 },
//     7232: { id: "7232", i: "", s: "", ss: 85 },
//     7233: { id: "7233", i: "", s: "", ss: 85 },
//     7248: { id: "7248", i: "", s: "", ss: 85 },
//     7261: { id: "7261", i: "", s: "", ss: 85 },
//     7266: { id: "7266", i: "", s: "", ss: 85 },
//     7272: { id: "7272", i: "", s: "", ss: 85 },
//     7288: { id: "7288", i: "", s: "", ss: 85 },
//     7299: { id: "7299", i: "", s: "", ss: 85 },
//     7300: { id: "7300", i: "", s: "", ss: 85 },
//     7321: { id: "7321", i: "", s: "", ss: 85 },
//     7322: { id: "7322", i: "", s: "", ss: 85 },
//     7331: { id: "7331", i: "", s: "", ss: 85 },
//     7332: { id: "7332", i: "", s: "", ss: 85 },
//     7333: { id: "7333", i: "", s: "", ss: 85 },
//     7336: { id: "7336", i: "", s: "", ss: 85 },
//     7345: { id: "7345", i: "", s: "", ss: 85 },
//     7348: { id: "7348", i: "", s: "", ss: 85 },
//     7362: { id: "7362", i: "", s: "", ss: 85 },
//     7373: { id: "7373", i: "", s: "", ss: 85 },
//     7374: { id: "7374", i: "", s: "", ss: 85 },
//     7500: { id: "7500", i: "", s: "", ss: 85 },
//     7522: { id: "7522", i: "", s: "", ss: 85 },
//     7552: { id: "7552", i: "", s: "", ss: 85 },
//     7568: { id: "7568", i: "", s: "", ss: 85 },
//     7588: { id: "7588", i: "", s: "", ss: 85 },
//     8001: { id: "8001", i: 3, s: 20, ss: 38 },
//     8003: { id: "8003", i: 6, s: 6, ss: 44 },
//     8005: { id: "8005", i: 6, s: 13, ss: 19 },
//     8006: { id: "8006", i: 6, s: 6, ss: 8 },
//     8007: { id: "8007", i: 8, s: 9, ss: 81 },
//     8009: { id: "8009", i: 11, s: 24, ss: 40 },
//     8011: { id: "8011", i: 11, s: 24, ss: 40 },
//     8013: { id: "8013", i: 7, s: 7, ss: 22 },
//     8017: { id: "8017", i: 7, s: 7, ss: 22 },
//     8018: { id: "8018", i: 7, s: 7, ss: 9 },
//     8019: { id: "8019", i: 3, s: 20, ss: 38 },
//     8020: { id: "8020", i: 10, s: 25, ss: 53 },
//     8021: { id: "8021", i: 2, s: 18, ss: 56 },
//     8022: { id: "8022", i: 2, s: 2, ss: 3 },
//     8023: { id: "8023", i: 2, s: 18, ss: 56 },
//     8025: { id: "8025", i: 3, s: 20, ss: 32 },
//     8026: { id: "8026", i: 5, s: 5, ss: 7 },
//     8027: { id: "8027", i: 6, s: 12, ss: 35 },
//     8028: { id: "8028", i: 8, s: 9, ss: 60 },
//     8029: { id: "8029", i: 3, s: 20, ss: 30 },
//     8030: { id: "8030", i: 2, s: 2, ss: 5 },
//     8031: { id: "8031", i: 6, s: 12, ss: 35 },
//     8032: { id: "8032", i: 6, s: 8, ss: 86 },
//     8033: { id: "8033", i: 7, s: 7, ss: 22 },
//     8035: { id: "8035", i: 10, s: 25, ss: 90 },
//     8036: { id: "8036", i: 7, s: 7, ss: 22 },
//     8037: { id: "8037", i: 5, s: 30, ss: 73 },
//     8039: { id: "8039", i: 2, s: 18, ss: 56 },
//     8040: { id: "8040", i: 2, s: 18, ss: 56 },
//     8041: { id: "8041", i: 7, s: 7, ss: 52 },
//     8042: { id: "8042", i: 6, s: 12, ss: 35 },
//     8043: { id: "8043", i: 7, s: 26, ss: 48 },
//     8045: { id: "8045", i: 7, s: 7, ss: 22 },
//     8047: { id: "8047", i: 10, s: 17, ss: 82 },
//     8048: { id: "8048", i: 7, s: 7, ss: 22 },
//     8049: { id: "8049", i: 5, s: 5, ss: 7 },
//     8050: { id: "8050", i: 7, s: 7, ss: 22 },
//     8051: { id: "8051", i: 7, s: 26, ss: 78 },
//     8052: { id: "8052", i: 6, s: 8, ss: 86 },
//     8053: { id: "8053", i: 2, s: 18, ss: 56 },
//     8055: { id: "8055", i: 6, s: 12, ss: 15 },
//     8056: { id: "8056", i: 6, s: 8, ss: 23 },
//     8057: { id: "8057", i: 0, s: 0, ss: 0 },
//     8059: { id: "8059", i: 2, s: 18, ss: 69 },
//     8060: { id: "8060", i: 7, s: 7, ss: 22 },
//     8062: { id: "8062", i: 7, s: 7, ss: 22 },
//     8063: { id: "8063", i: 3, s: 20, ss: 38 },
//     8065: { id: "8065", i: 7, s: 7, ss: 22 },
//     8066: { id: "8066", i: 7, s: 26, ss: 78 },
//     8067: { id: "8067", i: 6, s: 12, ss: 35 },
//     8069: { id: "8069", i: 6, s: 8, ss: 33 },
//     8070: { id: "8070", i: 10, s: 11, ss: 20 },
//     8071: { id: "8071", i: 7, s: 7, ss: 52 },
//     8072: { id: "8072", i: 3, s: 20, ss: 63 },
//     8073: { id: "8073", i: 10, s: 11, ss: 20 },
//     8075: { id: "8075", i: 6, s: 6, ss: 77 },
//     8076: { id: "8076", i: 7, s: 7, ss: 52 },
//     8078: { id: "8078", i: 6, s: 6, ss: 77 },
//     8079: { id: "8079", i: 10, s: 17, ss: 82 },
//     8080: { id: "8080", i: 10, s: 11, ss: 20 },
//     8081: { id: "8081", i: 7, s: 7, ss: 22 },
//     8082: { id: "8082", i: 6, s: 6, ss: 77 },
//     8083: { id: "8083", i: 7, s: 7, ss: 52 },
//     8086: { id: "8086", i: 7, s: 7, ss: 52 },
//     8087: { id: "8087", i: 6, s: 6, ss: 44 },
//     8088: { id: "8088", i: 7, s: 7, ss: 9 },
//     8089: { id: "8089", i: 9, s: 10, ss: 66 },
//     8091: { id: "8091", i: 6, s: 6, ss: 44 },
//     8092: { id: "8092", i: 7, s: 7, ss: 22 },
//     8093: { id: "8093", i: 6, s: 6, ss: 44 },
//     8095: { id: "8095", i: 6, s: 8, ss: 33 },
//     8096: { id: "8096", i: 6, s: 8, ss: 23 },
//     8098: { id: "8098", i: 3, s: 20, ss: 30 },
//     8100: { id: "8100", i: 7, s: 7, ss: 52 },
//     8101: { id: "8101", i: 6, s: 22, ss: 89 },
//     8103: { id: "8103", i: 6, s: 6, ss: 77 },
//     8106: { id: "8106", i: 7, s: 7, ss: 9 },
//     8107: { id: "8107", i: 6, s: 27, ss: 50 },
//     8108: { id: "8108", i: 6, s: 12, ss: 35 },
//     8109: { id: "8109", i: 3, s: 28, ss: 67 },
//     8111: { id: "8111", i: 10, s: 11, ss: 57 },
//     8112: { id: "8112", i: 6, s: 6, ss: 44 },
//     8113: { id: "8113", i: 10, s: 11, ss: 20 },
//     8115: { id: "8115", i: 10, s: 11, ss: 20 },
//     8116: { id: "8116", i: 9, s: 10, ss: 61 },
//     8117: { id: "8117", i: 1, s: 1, ss: 2 },
//     8118: { id: "8118", i: 6, s: 13, ss: 83 },
//     8119: { id: "8119", i: 7, s: 7, ss: 9 },
//     8120: { id: "8120", i: 6, s: 8, ss: 23 },
//     8121: { id: "8121", i: 6, s: 6, ss: 44 },
//     8123: { id: "8123", i: 6, s: 22, ss: 54 },
//     8125: { id: "8125", i: 2, s: 18, ss: 56 },
//     8126: { id: "8126", i: 6, s: 22, ss: 54 },
//     8128: { id: "8128", i: 10, s: 11, ss: 57 },
//     8130: { id: "8130", i: 6, s: 6, ss: 8 },
//     8131: { id: "8131", i: 7, s: 7, ss: 9 },
//     8132: { id: "8132", i: 11, s: 24, ss: 40 },
//     8133: { id: "8133", i: 10, s: 11, ss: 20 },
//     8135: { id: "8135", i: 6, s: 16, ss: 24 },
//     8136: { id: "8136", i: 6, s: 13, ss: 83 },
//     8137: { id: "8137", i: 10, s: 11, ss: 58 },
//     8139: { id: "8139", i: 8, s: 21, ss: 31 },
//     8140: { id: "8140", i: 2, s: 18, ss: 69 },
//     8143: { id: "8143", i: 5, s: 30, ss: 73 },
//     8146: { id: "8146", i: 9, s: 10, ss: 61 },
//     8147: { id: "8147", i: 4, s: 4, ss: 88 },
//     8148: { id: "8148", i: 7, s: 7, ss: 9 },
//     8149: { id: "8149", i: 3, s: 20, ss: 63 },
//     8150: { id: "8150", i: 6, s: 13, ss: 83 },
//     8151: { id: "8151", i: 10, s: 11, ss: 20 },
//     8152: { id: "8152", i: 10, s: 11, ss: 17 },
//     8153: { id: "8153", i: 6, s: 6, ss: 91 },
//     8155: { id: "8155", i: 2, s: 2, ss: 3 },
//     8156: { id: "8156", i: 6, s: 8, ss: 12 },
//     8158: { id: "8158", i: 5, s: 30, ss: 92 },
//     8159: { id: "8159", i: 7, s: 26, ss: 78 },
//     8160: { id: "8160", i: 6, s: 12, ss: 15 },
//     8161: { id: "8161", i: 5, s: 30, ss: 73 },
//     8162: { id: "8162", i: 8, s: 29, ss: 72 },
//     8163: { id: "8163", i: 9, s: 14, ss: 84 },
//     8166: { id: "8166", i: 10, s: 17, ss: 82 },
//     8167: { id: "8167", i: 7, s: 26, ss: 48 },
//     8168: { id: "8168", i: 3, s: 20, ss: 63 },
//     8169: { id: "8169", i: 10, s: 11, ss: 57 },
//     8170: { id: "8170", i: 2, s: 18, ss: 56 },
//     8171: { id: "8171", i: 7, s: 26, ss: 78 },
//     8172: { id: "8172", i: 6, s: 6, ss: 77 },
//     8173: { id: "8173", i: 2, s: 18, ss: 56 },
//     8175: { id: "8175", i: 6, s: 6, ss: 77 },
//     8176: { id: "8176", i: 5, s: 30, ss: 73 },
//     8178: { id: "8178", i: 7, s: 7, ss: 22 },
//     8179: { id: "8179", i: 9, s: 15, ss: 74 },
//     8181: { id: "8181", i: 2, s: 2, ss: 47 },
//     8186: { id: "8186", i: 8, s: 21, ss: 41 },
//     8187: { id: "8187", i: 3, s: 20, ss: 38 },
//     8188: { id: "8188", i: 2, s: 18, ss: 69 },
//     8189: { id: "8189", i: 8, s: 21, ss: 76 },
//     8191: { id: "8191", i: 8, s: 21, ss: 41 },
//     8192: { id: "8192", i: 7, s: 26, ss: 78 },
//     8193: { id: "8193", i: 3, s: 20, ss: 63 },
//     8195: { id: "8195", i: 6, s: 27, ss: 50 },
//     8196: { id: "8196", i: 1, s: 1, ss: 71 },
//     8198: { id: "8198", i: 7, s: 7, ss: 22 },
//     8200: { id: "8200", i: 6, s: 22, ss: 36 },
//     8201: { id: "8201", i: 6, s: 12, ss: 35 },
//     8202: { id: "8202", i: 6, s: 6, ss: 44 },
//     8203: { id: "8203", i: 10, s: 17, ss: 82 },
//     8205: { id: "8205", i: 7, s: 7, ss: 22 },
//     8206: { id: "8206", i: 6, s: 12, ss: 15 },
//     8208: { id: "8208", i: 2, s: 18, ss: 56 },
//     8209: { id: "8209", i: 2, s: 18, ss: 56 },
//     8210: { id: "8210", i: 3, s: 20, ss: 30 },
//     8211: { id: "8211", i: 6, s: 27, ss: 55 },
//     8213: { id: "8213", i: 6, s: 8, ss: 23 },
//     8215: { id: "8215", i: 3, s: 20, ss: 38 },
//     8216: { id: "8216", i: 2, s: 18, ss: 69 },
//     8217: { id: "8217", i: 2, s: 18, ss: 69 },
//     8218: { id: "8218", i: 10, s: 11, ss: 20 },
//     8219: { id: "8219", i: 6, s: 27, ss: 70 },
//     8220: { id: "8220", i: 6, s: 6, ss: 77 },
//     8221: { id: "8221", i: 3, s: 20, ss: 63 },
//     8222: { id: "8222", i: 6, s: 13, ss: 83 },
//     8223: { id: "8223", i: 3, s: 20, ss: 38 },
//     8225: { id: "8225", i: 5, s: 5, ss: 7 },
//     8226: { id: "8226", i: 3, s: 20, ss: 30 },
//     8227: { id: "8227", i: 2, s: 18, ss: 26 },
//     8228: { id: "8228", i: 6, s: 6, ss: 77 },
//     8229: { id: "8229", i: 7, s: 7, ss: 22 },
//     8231: { id: "8231", i: 7, s: 23, ss: 37 },
//     8232: { id: "8232", i: 6, s: 8, ss: 23 },
//     8235: { id: "8235", i: 7, s: 7, ss: 22 },
//     8237: { id: "8237", i: 6, s: 8, ss: 10 },
//     8238: { id: "8238", i: 6, s: 6, ss: 44 },
//     8239: { id: "8239", i: 3, s: 20, ss: 38 },
//     8241: { id: "8241", i: 9, s: 10, ss: 66 },
//     8242: { id: "8242", i: 2, s: 2, ss: 47 },
//     8245: { id: "8245", i: 7, s: 26, ss: 48 },
//     8246: { id: "8246", i: 10, s: 11, ss: 58 },
//     8247: { id: "8247", i: 5, s: 5, ss: 94 },
//     8249: { id: "8249", i: 7, s: 7, ss: 22 },
//     8250: { id: "8250", i: 11, s: 19, ss: 28 },
//     8255: { id: "8255", i: 3, s: 20, ss: 38 },
//     8257: { id: "8257", i: 7, s: 23, ss: 37 },
//     8258: { id: "8258", i: 10, s: 11, ss: 58 },
//     8259: { id: "8259", i: 2, s: 18, ss: 56 },
//     8260: { id: "8260", i: 6, s: 12, ss: 35 },
//     8262: { id: "8262", i: 2, s: 18, ss: 56 },
//     8265: { id: "8265", i: 6, s: 16, ss: 62 },
//     8266: { id: "8266", i: 7, s: 26, ss: 48 },
//     8267: { id: "8267", i: 7, s: 7, ss: 9 },
//     8268: { id: "8268", i: 2, s: 18, ss: 56 },
//     8269: { id: "8269", i: 6, s: 27, ss: 49 },
//     8270: { id: "8270", i: 11, s: 24, ss: 40 },
//     8271: { id: "8271", i: 2, s: 2, ss: 5 },
//     8272: { id: "8272", i: 6, s: 8, ss: 23 },
//     8275: { id: "8275", i: 2, s: 18, ss: 56 },
//     8277: { id: "8277", i: 8, s: 21, ss: 41 },
//     8279: { id: "8279", i: 6, s: 8, ss: 12 },
//     8280: { id: "8280", i: 6, s: 6, ss: 91 },
//     8281: { id: "8281", i: 9, s: 14, ss: 84 },
//     8282: { id: "8282", i: 7, s: 7, ss: 9 },
//     8283: { id: "8283", i: 6, s: 12, ss: 35 },
//     8285: { id: "8285", i: 6, s: 27, ss: 49 },
//     8286: { id: "8286", i: 7, s: 26, ss: 48 },
//     8287: { id: "8287", i: 7, s: 26, ss: 78 },
//     8290: { id: "8290", i: 7, s: 7, ss: 9 },
//     8291: { id: "8291", i: 10, s: 17, ss: 25 },
//     8292: { id: "8292", i: 10, s: 25, ss: 90 },
//     8293: { id: "8293", i: 6, s: 12, ss: 35 },
//     8295: { id: "8295", i: 3, s: 20, ss: 63 },
//     8296: { id: "8296", i: 6, s: 12, ss: 35 },
//     8297: { id: "8297", i: 6, s: 27, ss: 50 },
//     8299: { id: "8299", i: 8, s: 29, ss: 72 },
//     8300: { id: "8300", i: 6, s: 8, ss: 23 },
//     8301: { id: "8301", i: 9, s: 10, ss: 61 },
//     8305: { id: "8305", i: 2, s: 18, ss: 69 },
//     8307: { id: "8307", i: 5, s: 30, ss: 73 },
//     8308: { id: "8308", i: 6, s: 8, ss: 10 },
//     8309: { id: "8309", i: 6, s: 12, ss: 35 },
//     8310: { id: "8310", i: 10, s: 17, ss: 82 },
//     8311: { id: "8311", i: 7, s: 26, ss: 48 },
//     8313: { id: "8313", i: 0, s: 0, ss: 0 },
//     8315: { id: "8315", i: 6, s: 12, ss: 35 },
//     8316: { id: "8316", i: 2, s: 18, ss: 69 },
//     8317: { id: "8317", i: 6, s: 6, ss: 8 },
//     8319: { id: "8319", i: 7, s: 7, ss: 22 },
//     8320: { id: "8320", i: 6, s: 12, ss: 35 },
//     8321: { id: "8321", i: 2, s: 18, ss: 69 },
//     8325: { id: "8325", i: 7, s: 7, ss: 52 },
//     8326: { id: "8326", i: 1, s: 1, ss: 39 },
//     8328: { id: "8328", i: 6, s: 16, ss: 24 },
//     8329: { id: "8329", i: 5, s: 5, ss: 7 },
//     8331: { id: "8331", i: 8, s: 9, ss: 60 },
//     8333: { id: "8333", i: 3, s: 20, ss: 63 },
//     8337: { id: "8337", i: 4, s: 4, ss: 6 },
//     8340: { id: "8340", i: 3, s: 20, ss: 63 },
//     8341: { id: "8341", i: 2, s: 18, ss: 56 },
//     8347: { id: "8347", i: 11, s: 24, ss: 40 },
//     8348: { id: "8348", i: 10, s: 17, ss: 82 },
//     8349: { id: "8349", i: 2, s: 18, ss: 26 },
//     8350: { id: "8350", i: 3, s: 20, ss: 30 },
//     8351: { id: "8351", i: 9, s: 10, ss: 27 },
//     8353: { id: "8353", i: 7, s: 7, ss: 9 },
//     8356: { id: "8356", i: 2, s: 18, ss: 69 },
//     8357: { id: "8357", i: 5, s: 30, ss: 73 },
//     8360: { id: "8360", i: 2, s: 18, ss: 56 },
//     8362: { id: "8362", i: 9, s: 10, ss: 27 },
//     8363: { id: "8363", i: 6, s: 12, ss: 15 },
//     8365: { id: "8365", i: 3, s: 20, ss: 63 },
//     8366: { id: "8366", i: 2, s: 18, ss: 69 },
//     8367: { id: "8367", i: 6, s: 8, ss: 23 },
//     8368: { id: "8368", i: 6, s: 6, ss: 77 },
//     8370: { id: "8370", i: 6, s: 13, ss: 83 },
//     8371: { id: "8371", i: 6, s: 8, ss: 23 },
//     8372: { id: "8372", i: 5, s: 5, ss: 7 },
//     8373: { id: "8373", i: 2, s: 18, ss: 56 },
//     8375: { id: "8375", i: 10, s: 11, ss: 20 },
//     8377: { id: "8377", i: 6, s: 27, ss: 55 },
//     8379: { id: "8379", i: 7, s: 26, ss: 78 },
//     8383: { id: "8383", i: 10, s: 17, ss: 25 },
//     8385: { id: "8385", i: 6, s: 6, ss: 8 },
//     8391: { id: "8391", i: 10, s: 17, ss: 25 },
//     8392: { id: "8392", i: 6, s: 13, ss: 83 },
//     8395: { id: "8395", i: 6, s: 13, ss: 83 },
//     8400: { id: "8400", i: 6, s: 8, ss: 12 },
//     8401: { id: "8401", i: 6, s: 6, ss: 44 },
//     8402: { id: "8402", i: 2, s: 18, ss: 56 },
//     8403: { id: "8403", i: 6, s: 6, ss: 44 },
//     8405: { id: "8405", i: 6, s: 12, ss: 35 },
//     8406: { id: "8406", i: 6, s: 13, ss: 16 },
//     8411: { id: "8411", i: 2, s: 18, ss: 56 },
//     8412: { id: "8412", i: 6, s: 8, ss: 23 },
//     8413: { id: "8413", i: 9, s: 10, ss: 27 },
//     8416: { id: "8416", i: 10, s: 17, ss: 25 },
//     8417: { id: "8417", i: 6, s: 12, ss: 15 },
//     8418: { id: "8418", i: 6, s: 12, ss: 35 },
//     8419: { id: "8419", i: 6, s: 13, ss: 19 },
//     8420: { id: "8420", i: 7, s: 7, ss: 22 },
//     8422: { id: "8422", i: 2, s: 18, ss: 56 },
//     8423: { id: "8423", i: 2, s: 18, ss: 56 },
//     8425: { id: "8425", i: 10, s: 11, ss: 17 },
//     8426: { id: "8426", i: 2, s: 2, ss: 47 },
//     8427: { id: "8427", i: 2, s: 18, ss: 26 },
//     8428: { id: "8428", i: 6, s: 8, ss: 23 },
//     8429: { id: "8429", i: 6, s: 6, ss: 44 },
//     8430: { id: "8430", i: 10, s: 25, ss: 90 },
//     8431: { id: "8431", i: 6, s: 8, ss: 86 },
//     8432: { id: "8432", i: 6, s: 8, ss: 23 },
//     8436: { id: "8436", i: 6, s: 22, ss: 36 },
//     8437: { id: "8437", i: 5, s: 30, ss: 73 },
//     8439: { id: "8439", i: 3, s: 20, ss: 63 },
//     8441: { id: "8441", i: 6, s: 12, ss: 35 },
//     8445: { id: "8445", i: 2, s: 18, ss: 56 },
//     8446: { id: "8446", i: 6, s: 6, ss: 77 },
//     8447: { id: "8447", i: 6, s: 8, ss: 23 },
//     8448: { id: "8448", i: 10, s: 17, ss: 25 },
//     8450: { id: "8450", i: 10, s: 17, ss: 25 },
//     8451: { id: "8451", i: 9, s: 14, ss: 84 },
//     8452: { id: "8452", i: 3, s: 20, ss: 63 },
//     8455: { id: "8455", i: 2, s: 18, ss: 56 },
//     8456: { id: "8456", i: 6, s: 27, ss: 50 },
//     8460: { id: "8460", i: 2, s: 18, ss: 56 },
//     8462: { id: "8462", i: 6, s: 12, ss: 35 },
//     8471: { id: "8471", i: 6, s: 27, ss: 55 },
//     8472: { id: "8472", i: 6, s: 12, ss: 35 },
//     8473: { id: "8473", i: 6, s: 22, ss: 36 },
//     8475: { id: "8475", i: 6, s: 8, ss: 23 },
//     8476: { id: "8476", i: 9, s: 10, ss: 27 },
//     8479: { id: "8479", i: 11, s: 24, ss: 40 },
//     8480: { id: "8480", i: 6, s: 27, ss: 55 },
//     8481: { id: "8481", i: 10, s: 17, ss: 25 },
//     8482: { id: "8482", i: 10, s: 25, ss: 90 },
//     8483: { id: "8483", i: 6, s: 12, ss: 35 },
//     8487: { id: "8487", i: 7, s: 26, ss: 48 },
//     8489: { id: "8489", i: 10, s: 25, ss: 90 },
//     8490: { id: "8490", i: 10, s: 11, ss: 20 },
//     8491: { id: "8491", i: 9, s: 10, ss: 27 },
//     8493: { id: "8493", i: 6, s: 8, ss: 23 },
//     8495: { id: "8495", i: 6, s: 8, ss: 23 },
//     8496: { id: "8496", i: 9, s: 10, ss: 27 },
//     8500: { id: "8500", i: 6, s: 6, ss: 44 },
//     8501: { id: "8501", i: 2, s: 18, ss: 56 },
//     8502: { id: "8502", i: 10, s: 25, ss: 53 },
//     8506: { id: "8506", i: 10, s: 11, ss: 20 },
//     8507: { id: "8507", i: 6, s: 27, ss: 50 },
//     8509: { id: "8509", i: 9, s: 10, ss: 61 },
//     8510: { id: "8510", i: 6, s: 8, ss: 23 },
//     8511: { id: "8511", i: 10, s: 11, ss: 20 },
//     8512: { id: "8512", i: 6, s: 13, ss: 83 },
//     8513: { id: "8513", i: 5, s: 30, ss: 92 },
//     8516: { id: "8516", i: 2, s: 18, ss: 69 },
//     8519: { id: "8519", i: 6, s: 8, ss: 23 },
//     8521: { id: "8521", i: 6, s: 27, ss: 55 },
//     8523: { id: "8523", i: 2, s: 18, ss: 69 },
//     8525: { id: "8525", i: 3, s: 20, ss: 38 },
//     8526: { id: "8526", i: 2, s: 18, ss: 56 },
//     8527: { id: "8527", i: 6, s: 8, ss: 23 },
//     8532: { id: "8532", i: 2, s: 18, ss: 56 },
//     8535: { id: "8535", i: 2, s: 18, ss: 56 },
//     8536: { id: "8536", i: 11, s: 24, ss: 40 },
//     8537: { id: "8537", i: 6, s: 27, ss: 70 },
//     8540: { id: "8540", i: 3, s: 20, ss: 30 },
//     8545: { id: "8545", i: 6, s: 13, ss: 16 },
//     8547: { id: "8547", i: 6, s: 13, ss: 83 },
//     8551: { id: "8551", i: 6, s: 6, ss: 77 },
//     8553: { id: "8553", i: 2, s: 18, ss: 69 },
//     8555: { id: "8555", i: 10, s: 11, ss: 20 },
//     8557: { id: "8557", i: 8, s: 21, ss: 41 },
//     8559: { id: "8559", i: 0, s: 0, ss: 0 },
//     8560: { id: "8560", i: 2, s: 18, ss: 56 },
//     8570: { id: "8570", i: 6, s: 8, ss: 23 },
//     8573: { id: "8573", i: 2, s: 18, ss: 56 },
//     8575: { id: "8575", i: 2, s: 18, ss: 26 },
//     8579: { id: "8579", i: 2, s: 18, ss: 56 },
//     8580: { id: "8580", i: 2, s: 18, ss: 69 },
//     8581: { id: "8581", i: 6, s: 6, ss: 77 },
//     8582: { id: "8582", i: 6, s: 12, ss: 35 },
//     8585: { id: "8585", i: 6, s: 27, ss: 50 },
//     8589: { id: "8589", i: 10, s: 17, ss: 82 },
//     8590: { id: "8590", i: 6, s: 27, ss: 50 },
//     8591: { id: "8591", i: 8, s: 9, ss: 60 },
//     8593: { id: "8593", i: 6, s: 12, ss: 35 },
//     8595: { id: "8595", i: 3, s: 20, ss: 30 },
//     8596: { id: "8596", i: 10, s: 11, ss: 20 },
//     8597: { id: "8597", i: 6, s: 27, ss: 70 },
//     8599: { id: "8599", i: 6, s: 8, ss: 23 },
//     8601: { id: "8601", i: 2, s: 18, ss: 69 },
//     8603: { id: "8603", i: 5, s: 30, ss: 73 },
//     8606: { id: "8606", i: 7, s: 7, ss: 22 },
//     8607: { id: "8607", i: 6, s: 27, ss: 55 },
//     8609: { id: "8609", i: 9, s: 15, ss: 34 },
//     8611: { id: "8611", i: 7, s: 7, ss: 22 },
//     8612: { id: "8612", i: 10, s: 11, ss: 17 },
//     8613: { id: "8613", i: 7, s: 7, ss: 52 },
//     8616: { id: "8616", i: 2, s: 18, ss: 56 },
//     8617: { id: "8617", i: 10, s: 11, ss: 20 },
//     8619: { id: "8619", i: 2, s: 18, ss: 56 },
//     8620: { id: "8620", i: 10, s: 25, ss: 90 },
//     8621: { id: "8621", i: 3, s: 20, ss: 38 },
//     8622: { id: "8622", i: 5, s: 5, ss: 94 },
//     8623: { id: "8623", i: 10, s: 11, ss: 20 },
//     8627: { id: "8627", i: 6, s: 6, ss: 44 },
//     8631: { id: "8631", i: 11, s: 24, ss: 40 },
//     8635: { id: "8635", i: 7, s: 7, ss: 22 },
//     8645: { id: "8645", i: 7, s: 7, ss: 22 },
//     8646: { id: "8646", i: 10, s: 11, ss: 20 },
//     8657: { id: "8657", i: 3, s: 20, ss: 32 },
//     8659: { id: "8659", i: 10, s: 17, ss: 25 },
//     8668: { id: "8668", i: 6, s: 8, ss: 33 },
//     9001: { id: "9001", i: "", s: "", ss: 85 },
//     9010: { id: "9010", i: "", s: "", ss: 85 },
//     9011: { id: "9011", i: "", s: "", ss: 85 },
//     9022: { id: "9022", i: "", s: "", ss: 85 },
//     9031: { id: "9031", i: "", s: "", ss: 85 },
//     9047: { id: "9047", i: "", s: "", ss: 85 },
//     9067: { id: "9067", i: "", s: "", ss: 85 },
//     9069: { id: "9069", i: "", s: "", ss: 85 },
//     9072: { id: "9072", i: "", s: "", ss: 85 },
//     9074: { id: "9074", i: "", s: "", ss: 85 },
//     9077: { id: "9077", i: "", s: "", ss: 85 },
//     9078: { id: "9078", i: "", s: "", ss: 85 },
//     9081: { id: "9081", i: "", s: "", ss: 85 },
//     9085: { id: "9085", i: "", s: "", ss: 85 },
//     9086: { id: "9086", i: "", s: "", ss: 85 },
//     9088: { id: "9088", i: "", s: "", ss: 85 },
//     9091: { id: "9091", i: "", s: "", ss: 85 },
//     9096: { id: "9096", i: "", s: "", ss: 85 },
//     9101: { id: "9101", i: "", s: "", ss: 85 },
//     9115: { id: "9115", i: "", s: "", ss: 85 },
//     9125: { id: "9125", i: "", s: "", ss: 85 },
//     9126: { id: "9126", i: "", s: "", ss: 85 },
//     9140: { id: "9140", i: "", s: "", ss: 85 },
//     9141: { id: "9141", i: "", s: "", ss: 85 },
//     9151: { id: "9151", i: "", s: "", ss: 85 },
//     9167: { id: "9167", i: "", s: "", ss: 85 },
//     9169: { id: "9169", i: "", s: "", ss: 85 },
//     9170: { id: "9170", i: "", s: "", ss: 85 },
//     9173: { id: "9173", i: "", s: "", ss: 85 },
//     9181: { id: "9181", i: "", s: "", ss: 85 },
//     9186: { id: "9186", i: "", s: "", ss: 85 },
//     9187: { id: "9187", i: "", s: "", ss: 85 },
//     9191: { id: "9191", i: "", s: "", ss: 85 },
//     9600: { id: "9600", i: 7, s: 7, ss: 22 },
//     9608: { id: "9608", i: 2, s: 2, ss: 47 },
//     9616: { id: "9616", i: 6, s: 12, ss: 15 },
//     9618: { id: "9618", i: 7, s: 7, ss: 52 },
//     9626: { id: "9626", i: 7, s: 7, ss: 9 },
//     9633: { id: "9633", i: 9, s: 10, ss: 66 },
//     9666: { id: "9666", i: 2, s: 2, ss: 47 },
//     9668: { id: "9668", i: 3, s: 3, ss: 4 },
//     9677: { id: "9677", i: 3, s: 3, ss: 4 },
//     9688: { id: "9688", i: 5, s: 5, ss: 94 },
//     9698: { id: "9698", i: 7, s: 7, ss: 22 },
//     9699: { id: "9699", i: 10, s: 25, ss: 90 },
//     9801: { id: "9801", i: "", s: "", ss: 85 },
//     9803: { id: "9803", i: "", s: "", ss: 85 },
//     9804: { id: "9804", i: "", s: "", ss: 85 },
//     9805: { id: "9805", i: "", s: "", ss: 85 },
//     9806: { id: "9806", i: "", s: "", ss: 85 },
//     9807: { id: "9807", i: "", s: "", ss: 85 },
//     9809: { id: "9809", i: "", s: "", ss: 85 },
//     9810: { id: "9810", i: "", s: "", ss: 85 },
//     9812: { id: "9812", i: "", s: "", ss: 85 },
//     9813: { id: "9813", i: "", s: "", ss: 85 },
//     9814: { id: "9814", i: "", s: "", ss: 85 },
//     9817: { id: "9817", i: "", s: "", ss: 85 },
//     9820: { id: "9820", i: "", s: "", ss: 85 },
//     9826: { id: "9826", i: "", s: "", ss: 85 },
//     9829: { id: "9829", i: "", s: "", ss: 85 },
//     9834: { id: "9834", i: "", s: "", ss: 85 },
//     9836: { id: "9836", i: "", s: "", ss: 85 },
//     9839: { id: "9839", i: "", s: "", ss: 85 },
//     9845: { id: "9845", i: "", s: "", ss: 85 },
//     9846: { id: "9846", i: "", s: "", ss: 85 },
//     9858: { id: "9858", i: 9, s: 10, ss: 87 },
//     9866: { id: "9866", i: 6, s: 16, ss: 62 },
//     9868: { id: "9868", i: 6, s: 16, ss: 62 },
//     9869: { id: "9869", i: 6, s: 8, ss: 23 },
//     9878: { id: "9878", i: 7, s: 7, ss: 52 },
//     9888: { id: "9888", i: 7, s: 7, ss: 52 },
//     9889: { id: "9889", i: 3, s: 3, ss: 4 },
//     9898: { id: "9898", i: 7, s: 7, ss: 52 },
//     9899: { id: "9899", i: 6, s: 6, ss: 77 },
//     9900: { id: "9900", i: 2, s: 18, ss: 56 },
//     9901: { id: "9901", i: 6, s: 12, ss: 15 },
//     9906: { id: "9906", i: 5, s: 30, ss: 73 },
//     9908: { id: "9908", i: 1, s: 1, ss: 2 },
//     9909: { id: "9909", i: 2, s: 2, ss: 47 },
//     9911: { id: "9911", i: 7, s: 7, ss: 52 },
//     9913: { id: "9913", i: 2, s: 18, ss: 56 },
//     9916: { id: "9916", i: 2, s: 2, ss: 47 },
//     9918: { id: "9918", i: 10, s: 11, ss: 43 },
//     9919: { id: "9919", i: 6, s: 6, ss: 44 },
//     9922: { id: "9922", i: 6, s: 8, ss: 23 },
//     9923: { id: "9923", i: 7, s: 7, ss: 52 },
//     9926: { id: "9926", i: 5, s: 5, ss: 94 },
//     9928: { id: "9928", i: 2, s: 2, ss: 47 },
//     9929: { id: "9929", i: 2, s: 18, ss: 56 },
//     9933: { id: "9933", i: 8, s: 21, ss: 31 },
//     9936: { id: "9936", i: 8, s: 21, ss: 31 },
//     9938: { id: "9938", i: 2, s: 18, ss: 56 },
//     9939: { id: "9939", i: 5, s: 5, ss: 7 },
//     9956: { id: "9956", i: 10, s: 25, ss: 90 },
//     9958: { id: "9958", i: 6, s: 6, ss: 77 },
//     9959: { id: "9959", i: 7, s: 7, ss: 22 },
//     9960: { id: "9960", i: 5, s: 30, ss: 73 },
//     9961: { id: "9961", i: 6, s: 8, ss: 33 },
//     9963: { id: "9963", i: 7, s: 26, ss: 48 },
//     9966: { id: "9966", i: 5, s: 5, ss: 94 },
//     9968: { id: "9968", i: 2, s: 2, ss: 3 },
//     9969: { id: "9969", i: 5, s: 5, ss: 94 },
//     9977: { id: "9977", i: 9, s: 15, ss: 74 },
//     9978: { id: "9978", i: 2, s: 2, ss: 80 },
//     9979: { id: "9979", i: 2, s: 2, ss: 47 },
//     9982: { id: "9982", i: 2, s: 2, ss: 3 },
//     9983: { id: "9983", i: 2, s: 2, ss: 47 },
//     9986: { id: "9986", i: 6, s: 12, ss: 15 },
//     9987: { id: "9987", i: 6, s: 8, ss: 23 },
//     9988: { id: "9988", i: 7, s: 7, ss: 52 },
//     9989: { id: "9989", i: 5, s: 5, ss: 7 },
//     9990: { id: "9990", i: 7, s: 7, ss: 9 },
//     9991: { id: "9991", i: 7, s: 7, ss: 52 },
//     9992: { id: "9992", i: 6, s: 13, ss: 16 },
//     9993: { id: "9993", i: 2, s: 2, ss: 3 },
//     9995: { id: "9995", i: 5, s: 5, ss: 94 },
//     9996: { id: "9996", i: 5, s: 30, ss: 92 },
//     9997: { id: "9997", i: 5, s: 30, ss: 92 },
//     9998: { id: "9998", i: 2, s: 18, ss: 56 },
//     9999: { id: "9999", i: 7, s: 7, ss: 9 },
//   },
//   iDict: {
//     0: "綜合企業",
//     1: "公用事業",
//     2: "地產建築業",
//     3: "金融業",
//     4: "電訊業",
//     5: "醫療保健業",
//     6: "非必需性消費",
//     7: "資訊科技業",
//     8: "原材料業",
//     9: "必需性消費",
//     10: "工業",
//     11: "能源業",
//   },
//   sDict: {
//     0: "綜合企業",
//     1: "公用事業",
//     2: "地產",
//     3: "銀行",
//     4: "電訊",
//     5: "藥品及生物科技",
//     6: "媒體及娛樂",
//     7: "軟件服務",
//     8: "旅遊及消閒設施",
//     9: "一般金屬及礦石",
//     10: "食物飲品",
//     11: "工業工程",
//     12: "支援服務",
//     13: "家庭電器及用品",
//     14: "消費者主要零售商",
//     15: "農業產品",
//     16: "汽車",
//     17: "工用支援",
//     18: "建築",
//     19: "煤炭",
//     20: "其他金融",
//     21: "原材料",
//     22: "專業零售",
//     23: "半導體",
//     24: "石油及天然氣",
//     25: "工用運輸",
//     26: "資訊科技器材",
//     27: "紡織及服飾",
//     28: "保險",
//     29: "黃金及貴金屬",
//     30: "醫療保健設備和服務",
//   },
//   ssDict: {
//     0: "綜合企業",
//     1: "電力",
//     2: "燃氣供應",
//     3: "地產發展商",
//     4: "銀行",
//     5: "地產投資",
//     6: "電訊服務",
//     7: "藥品",
//     8: "出版",
//     9: "軟件開發",
//     10: "酒店及度假村",
//     11: "鋼鐵",
//     12: "賭場及博彩",
//     13: "食品添加劑",
//     14: "航空航天與國防",
//     15: "教育",
//     16: "玩具及消閒用品",
//     17: "重機械",
//     18: "超市及便利店",
//     19: "消費電子產品",
//     20: "工業零件及器材",
//     21: "禽畜飼料",
//     22: "系統開發及資訊科技顧問",
//     23: "餐飲",
//     24: "汽車零件",
//     25: "印刷及包裝",
//     26: "建築材料",
//     27: "包裝食品",
//     28: "煤炭",
//     29: "公共運輸",
//     30: "證券及經紀",
//     31: "特殊化工用品",
//     32: "投資及資產管理",
//     33: "旅遊及觀光",
//     34: "農產品",
//     35: "其他支援服務",
//     36: "其他零售商",
//     37: "半導體",
//     38: "信貸",
//     39: "非傳統/ 可再生能源",
//     40: "氣油生產商",
//     41: "林業及木材",
//     42: "鋁",
//     43: "電子零件",
//     44: "廣告及宣傳",
//     45: "商業用車及貨車",
//     46: "鐵路及公路",
//     47: "物業服務及管理",
//     48: "電訊設備",
//     49: "其他服飾配件",
//     50: "服裝",
//     51: "服裝零售商",
//     52: "電子商貿及互聯網服務",
//     53: "航運及港口",
//     54: "汽車零售商",
//     55: "紡織品及布料",
//     56: "樓宇建造",
//     57: "環保工程",
//     58: "新能源物料",
//     59: "多元化零售商",
//     60: "其他金屬及礦物",
//     61: "酒精飲料",
//     62: "汽車",
//     63: "其他金融",
//     64: "氣油設備與服務",
//     65: "鞋類",
//     66: "非酒精飲料",
//     67: "保險",
//     68: "家庭電器",
//     69: "重型基建",
//     70: "珠寶鐘錶",
//     71: "水務",
//     72: "黃金及貴金屬",
//     73: "醫療及醫學美容服務",
//     74: "禽畜肉類",
//     75: "航空服務",
//     76: "化肥及農用化合物",
//     77: "影視娛樂",
//     78: "電腦及週邊器材",
//     79: "中醫藥",
//     80: "地產代理",
//     81: "銅",
//     82: "採購及供應鏈管理",
//     83: "家具",
//     84: "個人護理",
//     85: "-",
//     86: "消閒及文娛設施",
//     87: "乳製品",
//     88: "衛星及無線通訊",
//     89: "家居裝修零售商",
//     90: "航空貨運及物流",
//     91: "廣播",
//     92: "醫療保健設備",
//     93: "紙及紙製品",
//     94: "生物技術",
//     95: "煙草",
//   },
//   topicInfo: { lastUpdate: "2022/03/14 22:05", name: "stockSectorInfo" },
// };
