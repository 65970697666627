import React from "react";
// import { Field, reduxForm } from "redux-form";
import { Form } from "semantic-ui-react";
import Slider from "@material-ui/core/Slider";

export const renderCheckbox = (field) => (
  <Form.Checkbox
    checked={!!field.input.value}
    name={field.input.name}
    label={field.label}
    onChange={(e, { checked }) => field.input.onChange(checked)}
  />
);

export const renderRadio = (field) => (
  <Form.Radio
    checked={field.input.value === field.radioValue}
    label={field.label}
    name={field.input.name}
    onChange={(e, { checked }) => field.input.onChange(field.radioValue)}
  />
);

export const renderSelect = (field) => (
  <Form.Select
    label={field.label}
    name={field.input.name}
    onChange={(e, { value }) => field.input.onChange(value)}
    options={field.options}
    placeholder={field.placeholder}
    // value={field.input.value}
  />
);

export const renderDropDown = (field) => (
  <Form.Dropdown
    label={field.label}
    name={field.input.name}
    onChange={(e, { value }) => field.input.onChange(value)}
    options={field.options}
    placeholder={field.placeholder}
    defaultValue={field.defaultValue}
    value={field.input.value}
  />
);

export const renderSlider = ({
  input: { onDragStart, onChange, name, value },
  onChange: onChangeFromField,
  defaultValue,
  meta,
  ...props
}) => (
  <Slider
    onChange={(event, value) => {
      onChange(value);
      if (onChangeFromField) {
        onChangeFromField(value);
      }
    }}
    // defaultValue={[0, 100]}
  />
);
