import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { arrayUnion, arrayRemove, collection, doc, getDoc, setDoc } from "firebase/firestore";
import { db, analytics } from '../../config/fbConfig';
import { logEvent } from "firebase/analytics";
import { useSelector } from 'react-redux';

const initialState = {
  user: null,
  subscriptions: null, // customer's subscriptions
  data: null, // user's data
  status: 'idle',
  error: null,
}

export const useUser = () => {
  return useSelector((state) => state.user.user)
}

export const useSubscriptions = () => {
  return useSelector((state) => JSON.parse(state.user.subscriptions))
}

export const useIsLoggedIn = () => (useUser() != null)

export const useIsLoadingSubscriptions = () => (useSubscriptions == null)

export const useIsSubscribed = () => {
  const subscriptions = useSubscriptions()
  return subscriptions && (subscriptions.length > 0)
}

export const useIsAdmin = () => {
  return useSelector((state) => state.user.data?.isAdmin)
}

export const useAllowedBehindPaywall = () => {
  const isSubscribed = useIsSubscribed()
  const isAdmin = useIsAdmin()
  return isAdmin || isSubscribed
}

export const useUserData = () => {
  return useSelector(state => state.user.data)
}

export const useUserFavourites = () => {
  return useSelector(state => state.user.data?.favourites ?? [])
}

export const useUserCanCommentToBlog = () => {
  return useSelector(state => state.user.data? state.user.data.canCommentToBlog: undefined)
}

export const useUserCanPostToBlog = () => {
  return useSelector(state => state.user.data? state.user.data.canPostToBlog: undefined)
}

export const useUserCanViewBlog = () => {
  return useSelector(state => state.user.data? state.user.data.canViewBlog: undefined)
}

export const fetchUserData = createAsyncThunk('index/fetchRecent', async (uid) => {
  let userRef = collection(db, 'users')
  logEvent(analytics, "select_content", {
    content_type: "users_" + uid,
  });
  const snapshot = await getDoc(doc(userRef, uid));
  return (snapshot.data())
})

export const addFavourite = async (uid, stock_ids) => {
  let userRef = collection(db, 'users')

  await setDoc(doc(userRef, uid), { favourites: arrayUnion(...stock_ids) }, { merge: true })
}

export const removeFavourite = async(uid, stock_ids) => {
  let userRef = collection(db, 'users')

  await setDoc(doc(userRef, uid), { favourites: arrayRemove(...stock_ids) }, { merge: true });
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login(state, action) { state.user = action.payload },
    logout(state) { state.user = null; state.subscriptions = null },
    setUserSubscriptions(state, action) { state.subscriptions = action.payload },
    setUserFavourites(state, action) { state.data = { ...state.data, favourites: action.payload } },
    setUserPosts(state, action) { state.data = { ...state.data, posts: action.payload } }
  },
  extraReducers: {
    [fetchUserData.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchUserData.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.data = action.payload
    },
    [fetchUserData.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    },
  },
})

export const { login, logout, setUserSubscriptions, setUserFavourites, setUserPosts } = userSlice.actions
export default userSlice.reducer
