import React, { useEffect, useState } from "react";
import {
  Loader,
  Table,
  Pagination,
  Menu,
  Container,
  Header,
  Icon,
  Input,
} from "semantic-ui-react";
import _ from "lodash";
import { createStore, combineReducers } from "redux";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import {
  selectAllTopic,
  fetchTopic,
  toIdle,
} from "../../features/topic/topicSlice";
import { useSelector, useDispatch } from "react-redux";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
import InFeedAdsWhite from "../ads/InFeedAdsWhite";
import source from "mock_source";

export const BuyBackTable = () => {
  const dispatch = useDispatch();
  const topics = useSelector(selectAllTopic);
  const topicStatus = useSelector((state) => state.topic.status);
  const error = useSelector((state) => state.topic.error);

  useEffect(() => {
    if (topicStatus === "idle") {
      dispatch(fetchTopic("buyback"));
    }
  }, [topicStatus, dispatch]);

  let content;

  if (topicStatus === "loading") {
    content = (
      <div>
        <section className="posts-list">
          <div
            style={{
              padding: "100px",
              margin: "auto",
              alignItems: "center",
              height: "75vh",
              // overflow: "auto",
            }}
          >
            <Loader active inline="centered">
              {" "}
              Loading
            </Loader>
          </div>
        </section>
      </div>
    );
  } else if (topicStatus === "succeeded") {
    const data = topics.find((item) => item.topicInfo.name === "buyback");
    if (data) {
      content = <BuyBackView result={data} />;
    } else {
      dispatch(toIdle({}));
    }
  } else if (topicStatus === "error") {
    content = (
      <div>
        <section className="posts-list">
          <div>{error}</div>
        </section>
      </div>
    );
  }
  return <>{content}</>;
  // return (
  //   <>
  //     <BuyBackView data = {data} />
  //   </>
  // )
};

export default BuyBackTable;

function BuyBackView(data) {
  let result = data.result;
  return (
    <>
      <div style={{ paddingTop: "25px", paddingBottom: "25px" }}>
        {typeof result !== "undefined" ? (
          <SortableTable data={result} />
        ) : (
          <NotFound />
        )}
      </div>
    </>
  );
}

function NotFound() {
  return (
    <div
      style={{
        padding: "100px",
        margin: "auto",
        alignItems: "center",
        height: "75vh",
        // overflow: "auto",
      }}
    >
      <Loader active inline="centered">
        {" "}
        Loading
      </Loader>
    </div>
  );
}

const initialState = {
  data: [],
  data_gp: [],
  options: [],
  direction: "descending",
  direction_gp: "descending",
  column: "total_paid",
  column_gp: "total_paid",
  filterText: { value: "" },
  filterInd: "",
  filterDate: new Date(),
  activePage: "1",
  isFocus: "1",
  totalPage: "4",
  itemType: "0",
  filterTs: new Date().getTime() - 1 * 28 * 24 * 60 * 60 * 1000,
  lastUpdate: { value: "" },
};

const reducer = combineReducers({
  resultTable: resultTableReducer,
});

function resultTableReducer(state = initialState, action) {
  switch (action.type) {
    case "CHANGE_SORT": {
      if (state.column === action.column) {
        return {
          ...state,
          data: state.data.reverse(),
          direction:
            state.direction === "ascending" ? "descending" : "ascending",
        };
      }
      return {
        ...state,
        column: action.column,
        data: _.sortBy(state.data, [action.column]),
        direction: "ascending",
      };
    }
    case "CHANGE_SORT_G": {
      if (state.column === action.column) {
        return {
          ...state,
          data_gp: state.data_gp.reverse(),
          direction:
            state.direction === "ascending" ? "descending" : "ascending",
        };
      }
      return {
        ...state,
        column: action.column,
        data_gp: _.sortBy(state.data_gp, [action.column], "desc"),
        direction_gp: "descending",
      };
    }
    case "FILTER_DATA_BY_TS": {
      const string = action.text;

      let newDatagp = state.data.filter((d) => {
        return d.t_date_ts >= string;
      });
      // console.log(newDatagp)
      newDatagp = _(newDatagp)
        .groupBy("code")
        .map((info, id) => ({
          code: id,
          cur: info[0].cur,
          n_en: info[0].n_en,
          n_zh: info[0].n_zh,
          total_paid: _.sumBy(info, "total_paid"),
          nShare: _.sumBy(info, "nShare"),
          p_avg: _.sumBy(info, "total_paid") / _.sumBy(info, "nShare"),
        }))
        .value();
      newDatagp = _.sortBy(newDatagp, ["total_paid"]).reverse();
      return {
        ...state,
        data_gp: newDatagp,
      };
    }
    case "Filter_MESSAGE": {
      const string = action.text;
      return {
        ...state,
        filterText: string,
      };
    }
    case "Filter_Date": {
      const string = action.text;
      // console.log(string)
      return {
        ...state,
        filterDate: string,
      };
    }
    case "Filter_TS": {
      const string = action.text;
      // console.log(string)
      return {
        ...state,
        filterTs: string,
      };
    }
    case "TOGGLE_TYPE": {
      const string = action.text;
      return {
        ...state,
        itemType: string,
      };
    }
    case "Toggle_isFocus": {
      const string = action.text;
      return {
        ...state,
        isFocus: string,
      };
    }
    case "SET_IND_FILTER": {
      return {
        ...state,
        filterInd: action.state,
      };
    }
    case "SET_ACTIVE_PAGE": {
      const string = action.text;
      window.scrollTo(0, 0);
      return {
        ...state,
        activePage: string,
      };
    }
    case "ADD_MESSAGE": {
      return {
        ...state,
        data: action.state,
      };
    }
    case "ADD_OPTIONS": {
      return {
        ...state,
        options: action.state,
      };
    }
    case "ADD_LASTUPDATE": {
      return {
        ...state,
        lastUpdate: action.state,
      };
    }
    default: {
      return state;
    }
  }
}

const store = createStore(reducer);

class SortableTable extends React.Component {
  constructor(props) {
    super(props);
    this.setData();
  }

  setData() {
    var data1 = this.props.data;
    // console.log(data1)
    const code = data1.code;
    // const n_zh = data1.n_zh;
    // const n_en = data1.n_en;
    // const t_date = data1.t_date
    const t_date_ts = data1.t_date_ts;
    const nShare = data1.nShare;
    const p_range = data1.p_range;
    const p_avg = data1.p_avg;
    const cur = data1.cur;
    const total_paid = data1.total_paid;
    const lastUpdate = data1.topicInfo.lastUpdate;
    // const options = [{ key: '所有', text: '所有', value: '所有' }]
    // const currentTs = new Date().getTime()/1000
    let tableData1 = [];
    let i = 0;
    for (i; i < code.length; i += 1) {
      let a = _.find(source, function (o) {
        return o.no === code[i];
      });
      tableData1.push({
        no: i + 1,
        code: code[i],
        n_zh: a ? a["name_zh"] : "",
        n_en: a ? a["name_en"] : "",
        // n_zh: n_zh[i],
        // n_en: n_en[i],
        t_date_ts: t_date_ts[i],
        nShare: nShare[i],
        p_range: p_range[i],
        p_avg: p_avg[i],
        cur: cur[i],
        total_paid: total_paid[i],
      });
    }
    tableData1 = _.sortBy(tableData1, ["total_paid"]).reverse();
    // console.log(tableData1)
    store.dispatch({
      type: "ADD_MESSAGE",
      state: tableData1,
    });
    store.dispatch({
      type: "ADD_LASTUPDATE",
      state: lastUpdate,
    });
  }

  componentDidMount() {
    store.subscribe(() => this.forceUpdate());
  }

  render() {
    const state = store.getState();
    const { data, filterText, activePage, itemType, filterDate, lastUpdate } =
      state.resultTable;
    let newData = data;
    let newDatagp = [];
    let aDate_ts = _.map(data, "t_date_ts");
    // var today = new Date();
    // var ts_1w = today.getTime() - 7*24*60*60
    // var ts_1M = today.getTime() - 1*28*24*60*60
    // var ts_3M = today.getTime() - 3*28*24*60*60
    // var ts_ytd = new Date(today.getFullYear(), 0, 1);

    if (itemType === "0") {
      if (filterDate) {
        // console.log(data)
        newData = data.filter((d) => {
          return d.t_date_ts === filterDate.getTime();
        });
      }
    }

    if (itemType === "1") {
      if (filterText.value && filterText.value !== "") {
        newData = data.filter((d) => {
          return (
            (typeof d.code === "string" &&
              d.code.toLowerCase().includes(filterText.value.toLowerCase())) ||
            (typeof d.n_zh === "string" &&
              d.n_zh.toLowerCase().includes(filterText.value.toLowerCase())) ||
            (typeof d.n_en === "string" &&
              d.n_en.toLowerCase().includes(filterText.value.toLowerCase()))
          );
        });
      } else if (filterText.value === "") {
        newData = [];
      }
    }
    // if (itemType === "2") {
    //   if (filterTs && filterTs !== "" ){
    //     newDatagp = data.filter((d) => {
    //       return (
    //         ( d.t_date_ts >=  filterTs )
    //       )
    //     })
    //     newDatagp = _(newData)
    //       .groupBy('code')
    //       .map((info, id) => (
    //         {
    //         code: id,
    //         cur: info[0].cur,
    //         n_en: info[0].n_en,
    //         n_zh: info[0].n_zh,
    //         total_paid: _.sumBy(info, 'total_paid'),
    //         nShare: _.sumBy(info, 'nShare'),
    //         p_avg: _.sumBy(info, 'total_paid')/_.sumBy(info, 'nShare')
    //       })).value()
    //     newDatagp = _.sortBy(newDatagp, ['total_paid']).reverse()
    //   }
    // }

    const nPerPage = 25;
    const totalPage = Math.ceil(newData.length / nPerPage);
    newData = _.slice(
      newData,
      (activePage - 1) * nPerPage,
      activePage * nPerPage
    );

    return (
      <Container
        style={{
          widht: "100%",
          maxWidth: "950px",
          margin: "auto",
          alignItems: "center",
        }}
      >
        <div style={{ paddingBottom: "10px", paddingTop: "10px" }}>
          <ToggleType />
        </div>

        {itemType === "0" ? (
          <div style={{ paddingBottom: "25px", paddingTop: "10px" }}>
            <DatePicker aDate={aDate_ts} />
          </div>
        ) : null}

        {itemType === "1" ? (
          <div
            style={{
              paddingBottom: "25px",
              paddingTop: "10px",
              textAlign: "left",
            }}
          >
            <FilterInput value={filterText} />
          </div>
        ) : null}
        {itemType === "2" ? (
          <div>
            <div
              style={{
                paddingBottom: "10px",
                paddingTop: "10px",
                textAlign: "left",
              }}
            >
              <ToggleTs />
            </div>
            <div
              style={{
                paddingBottom: "10px",
                paddingTop: "10px",
                textAlign: "left",
              }}
            >
              <FilterInput value={filterText} />
            </div>
          </div>
        ) : null}

        <div>
          {/* <Header as="h4"  textAlign='right' color ='grey'> 最後更新 : {this.props.data.topicInfo.lastUpdate.slice(0,10)} </Header> */}
          <Header as="h4" textAlign="right" color="grey">
            {" "}
            最後更新 : {lastUpdate}{" "}
          </Header>
        </div>

        <div style={{ textAlign: "center", width: isMobile ? "95%" : "100%" }}>
          <TableComponent data={newData} />
        </div>
        {itemType !== "2" ? (
          <div style={{ paddingTop: "25px", paddingBottom: "25px" }}>
            <PaginationContainer totalPage={totalPage} />
          </div>
        ) : null}
      </Container>
    );
  }
}

function TableComponent(data) {
  // const newData = data.data
  const state = store.getState();
  const { column, direction, itemType } = state.resultTable;

  let tableComponent = null;
  if (itemType === "0") {
    tableComponent = <TableSortedByDate data={data.data} />;
  }
  // else if (itemType === "1" ){
  //   tableComponent = <TableSortedByStock data ={data.data}/>
  // }
  else if (itemType === "2") {
    tableComponent = <TableSortedByPeriod />;
  }
  return (
    <div
      style={{
        whiteSpace: "nowrap",
        overflowX: "auto",
        display: "inline-block",
        width: "100%",
        textAlign: "center",
      }}
    >
      {tableComponent}
    </div>
  );
}

function TableSortedByDate(data) {
  const newData = data.data;
  const state = store.getState();
  const { column, direction } = state.resultTable;
  return (
    <Table sortable selectable celled unstackable compact>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            textAlign="left"
            sorted={column === "code" ? direction : null}
            onClick={() =>
              store.dispatch({ type: "CHANGE_SORT", column: "code" })
            }
          >
            名稱
            {isMobile ? <br /> : null}
            (編號)
          </Table.HeaderCell>
          <Table.HeaderCell
            textAlign="center"
            sorted={column === "p_range" ? direction : null}
            onClick={() =>
              store.dispatch({ type: "CHANGE_SORT", column: "p_range" })
            }
          >
            價格範圍
          </Table.HeaderCell>
          <Table.HeaderCell
            textAlign="center"
            sorted={column === "p_avg" ? direction : null}
            onClick={() =>
              store.dispatch({ type: "CHANGE_SORT", column: "p_avg" })
            }
          >
            平均價格
          </Table.HeaderCell>
          <Table.HeaderCell
            textAlign="center"
            sorted={column === "nShare" ? direction : null}
            onClick={() =>
              store.dispatch({ type: "CHANGE_SORT", column: "nShare" })
            }
          >
            回購股數
          </Table.HeaderCell>
          <Table.HeaderCell
            textAlign="center"
            sorted={column === "total_paid" ? direction : null}
            onClick={() =>
              store.dispatch({ type: "CHANGE_SORT", column: "total_paid" })
            }
          >
            回購金額
          </Table.HeaderCell>
          <Table.HeaderCell
            textAlign="center"
            sorted={column === "cur" ? direction : null}
            onClick={() =>
              store.dispatch({ type: "CHANGE_SORT", column: "cur" })
            }
          >
            貨幣
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {newData.map(
          (
            { code, n_zh, n_en, nShare, p_range, p_avg, total_paid, cur },
            index
          ) => (
            <>
              {index !== 0 && index % 10 === 0 && (
                <Table.Row>
                  <Table.Cell colSpan={6} textAlign="center">
                    <InFeedAdsWhite></InFeedAdsWhite>
                  </Table.Cell>
                </Table.Row>
              )}
              <Table.Row key={code}>
                <Table.Cell textAlign="left">
                  <Link to={"/stock/" + code}>
                    {n_zh.length > 8 ? n_zh.substring(0, 8) + "..." : n_zh}
                    {isMobile ? <br /> : null}({code})
                  </Link>
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {p_range !== 0 ? "$" + p_range : "-"}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {p_avg !== 0 ? "$" + Math.round(p_avg * 1000) / 1000 : "-"}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {nShare !== 0 ? nShare.toLocaleString() : "-"}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {total_paid !== 0 ? "$" + total_paid.toLocaleString() : "-"}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {cur !== 0 ? cur : "-"}
                </Table.Cell>
              </Table.Row>
            </>
          )
        )}
      </Table.Body>
    </Table>
  );
}

// function TableSortedByStock(data){
//   console.log(data)
//   const newData = data.data
//   const state = store.getState();
//   const { column, direction} = state.resultTable
//   return (
//     <Table sortable celled unstackable compact >
//       <Table.Header>
//         <Table.Row>
//             <Table.HeaderCell textAlign='left'
//               sorted={column === "code" ? direction : null}
//               onClick={() => store.dispatch({ type: 'CHANGE_SORT', column: "code" })}
//             >
//               名稱(編號)
//             </Table.HeaderCell>
//             <Table.HeaderCell textAlign='center'
//               sorted={column === "t_date" ? direction : null}
//               onClick={() => store.dispatch({ type: 'CHANGE_SORT', column: "t_date" })}
//             >
//               回購日期
//             </Table.HeaderCell>
//             <Table.HeaderCell textAlign='center'
//               sorted={column === "cur" ? direction : null}
//               onClick={() => store.dispatch({ type: 'CHANGE_SORT', column: "cur" })}
//             >
//               貨幣
//             </Table.HeaderCell>
//             <Table.HeaderCell textAlign='center'
//               sorted={column === "p_range" ? direction : null}
//               onClick={() => store.dispatch({ type: 'CHANGE_SORT', column: "p_range" })}
//             >
//               價格範圍
//             </Table.HeaderCell>
//             <Table.HeaderCell textAlign='center'
//               sorted={column === "p_avg" ? direction : null}
//               onClick={() => store.dispatch({ type: 'CHANGE_SORT', column: "p_avg" })}
//             >
//               平均價格
//             </Table.HeaderCell>
//             <Table.HeaderCell textAlign='center'
//               sorted={column === "nShare" ? direction : null}
//               onClick={() => store.dispatch({ type: 'CHANGE_SORT', column: "nShare" })}
//             >
//               回購股數
//             </Table.HeaderCell>
//             <Table.HeaderCell textAlign='center'
//               sorted={column === "total_paid" ? direction : null}
//               onClick={() => store.dispatch({ type: 'CHANGE_SORT', column: "total_paid" })}
//             >
//               回購金額
//             </Table.HeaderCell>
//           </Table.Row>
//       </Table.Header>
//       <Table.Body>
//         {newData.map(({ code, n_zh, n_en, t_date, nShare, p_range,
//             p_avg, total_paid, cur, t_date_ts }, index) => (
//           <>
//             <Table.Row key={code}>
//               <Table.Cell textAlign='left'>
//                 <Link to={"/stock/" + code} >
//                   {n_zh}
//                 </Link>  <br/>
//                 ({code})
//               </Table.Cell>
//               <Table.Cell textAlign='center'>
//                 {timeConverter(t_date_ts)}
//               </Table.Cell>
//               <Table.Cell textAlign='center'>
//                 {cur !== 0 ? cur : "-"}
//               </Table.Cell>
//               <Table.Cell textAlign='center'>
//                 {p_range !== 0 ? p_range : "-"}
//               </Table.Cell>
//               <Table.Cell textAlign='center'>
//                 {p_avg !== 0 ? Math.round( p_avg * 1000) /1000 :"-"}
//               </Table.Cell>
//               <Table.Cell textAlign='center'>
//                 {nShare !== 0? nShare.toLocaleString() : "-"}
//               </Table.Cell>
//               <Table.Cell textAlign='center'>
//                 {total_paid !== 0 ? '$' + total_paid.toLocaleString() : "-"}
//               </Table.Cell>
//             </Table.Row>
//           </>
//         ))}
//       </Table.Body>
//     </Table>
//   )
// }

function TableSortedByPeriod() {
  let state = store.getState();
  let { column, direction, data_gp, filterTs, filterText } = state.resultTable;
  if (filterText.value && filterText.value !== "") {
    data_gp = data_gp.filter((d) => {
      return (
        (typeof d.code === "string" &&
          d.code.toLowerCase().includes(filterText.value.toLowerCase())) ||
        (typeof d.n_zh === "string" &&
          d.n_zh.toLowerCase().includes(filterText.value.toLowerCase())) ||
        (typeof d.n_en === "string" &&
          d.n_en.toLowerCase().includes(filterText.value.toLowerCase()))
      );
    });
  }

  const [selected, setSelected] = React.useState([]);
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  return (
    <Table
      sortable
      selectable
      celled
      unstackable
      compact
      className="StockTable"
    >
      <Table.Header className="sticky-top">
        <Table.Row>
          <Table.HeaderCell
            textAlign="left"
            sorted={column === "code" ? direction : null}
            onClick={() =>
              store.dispatch({ type: "CHANGE_SORT_G", column: "code" })
            }
          >
            名稱 {isMobile ? <br /> : null} (編號)
          </Table.HeaderCell>
          <Table.HeaderCell
            textAlign="center"
            sorted={column === "p_avg" ? direction : null}
            onClick={() =>
              store.dispatch({ type: "CHANGE_SORT_G", column: "p_avg" })
            }
          >
            平均價格
          </Table.HeaderCell>
          <Table.HeaderCell
            textAlign="center"
            sorted={column === "nShare" ? direction : null}
            onClick={() =>
              store.dispatch({ type: "CHANGE_SORT_G", column: "nShare" })
            }
          >
            回購股數
          </Table.HeaderCell>
          <Table.HeaderCell
            textAlign="center"
            sorted={column === "total_paid" ? direction : null}
            onClick={() =>
              store.dispatch({ type: "CHANGE_SORT_G", column: "total_paid" })
            }
          >
            回購金額
          </Table.HeaderCell>
          <Table.HeaderCell
            textAlign="center"
            sorted={column === "cur" ? direction : null}
            onClick={() =>
              store.dispatch({ type: "CHANGE_SORT_G", column: "cur" })
            }
          >
            貨幣
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data_gp.map(
          (
            { code, n_zh, n_en, nShare, p_range, p_avg, total_paid, cur },
            index
          ) => {
            const isItemSelected = isSelected(code);
            return (
              <>
                {index !== 0 && index % 10 === 0 && (
                  <Table.Row>
                    <Table.Cell colSpan={5} textAlign="center">
                      <InFeedAdsWhite></InFeedAdsWhite>
                    </Table.Cell>
                  </Table.Row>
                )}
                <Table.Row
                  key={code}
                  onClick={(event) => handleClick(event, code)}
                >
                  <Table.Cell textAlign="left">
                    <Icon
                      name={isItemSelected ? "caret down" : "caret right"}
                    />
                    <Link to={"/stock/" + code}>
                      {n_zh.length > 8 ? n_zh.substring(0, 8) + "..." : n_zh}
                      {isMobile ? <br /> : null}({code})
                    </Link>
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {p_avg !== 0 ? "$" + Math.round(p_avg * 1000) / 1000 : "-"}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {nShare !== 0 ? nShare.toLocaleString() : "-"}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {total_paid !== 0 ? "$" + total_paid.toLocaleString() : "-"}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {cur !== 0 ? cur : "-"}
                  </Table.Cell>
                </Table.Row>
                {isItemSelected ? (
                  <Table.Row>
                    <Table.Cell
                      style={{ paddingBottom: "10px", paddingTop: "10px" }}
                      colSpan={5}
                    >
                      <Row
                        code_={code}
                        filterTs_={filterTs}
                        style={{ margin: "auto" }}
                      />
                    </Table.Cell>
                  </Table.Row>
                ) : null}
              </>
            );
          }
        )}
      </Table.Body>
    </Table>
  );
}

function Row(input) {
  let code = input.code_;
  let filterTs = input.filterTs_;
  // console.log(input)
  const state = store.getState();
  const { data } = state.resultTable;

  let newData = [];
  newData = data.filter((d) => {
    return d.code.toLowerCase().includes(code) && d.t_date_ts >= filterTs;
  });
  newData = _.sortBy(newData, ["t_date_ts"]).reverse();

  return (
    <Table unstackable compact>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell textAlign="center">回購日期</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">價格範圍</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">平均價格</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">回購股數</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">回購金額</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">貨幣</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {newData.map(
          (
            {
              code,
              n_zh,
              n_en,
              nShare,
              p_range,
              p_avg,
              total_paid,
              cur,
              t_date_ts,
            },
            index
          ) => (
            <>
              <Table.Row key={code}>
                <Table.Cell textAlign="center">
                  {timeConverter(t_date_ts)}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {p_range !== 0 ? "$" + p_range : "-"}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {p_avg !== 0 ? "$" + Math.round(p_avg * 1000) / 1000 : "-"}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {nShare !== 0 ? nShare.toLocaleString() : "-"}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {total_paid !== 0 ? "$" + total_paid.toLocaleString() : "-"}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {cur !== 0 ? cur : "-"}
                </Table.Cell>
              </Table.Row>
            </>
          )
        )}
      </Table.Body>
    </Table>
  );
}

class PaginationContainer extends React.Component {
  componentDidMount() {
    store.subscribe(() => this.forceUpdate());
  }

  handlePaginationChange = (e, { activePage }) => {
    store.dispatch({
      type: "SET_ACTIVE_PAGE",
      text: activePage,
    });
  };

  render() {
    const pos = isMobile ? "center" : "right";
    const state = store.getState();
    const { activePage } = state.resultTable;

    return (
      <div style={{ float: pos }}>
        <Pagination
          boundaryRange={0}
          activePage={activePage}
          size={isMobile ? "small" : "big"}
          siblingRange={1}
          totalPages={this.props.totalPage}
          onPageChange={this.handlePaginationChange}
        />
      </div>
    );
  }
}

const getDateString = (date) => {
  var temp =
    "" +
    date.getFullYear() +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    ("0" + date.getDate()).slice(-2);
  return temp;
};

const DatePicker = (date) => {
  const aDate = date.aDate;
  const [selectDate, setSelectDate] = useState(new Date(_.max(aDate)));

  useEffect(() => {
    if (aDate) {
      store.dispatch({
        type: "Filter_Date",
        text: new Date(_.max(aDate)),
      });
    }
  }, []);

  const datePickerFilterDate = (date) => {
    let dateStr = getDateString(date);
    for (var i = 0; i < aDate.length; i++) {
      let candDateStr = getDateString(new Date(aDate[i]));
      if (candDateStr === dateStr) {
        return true;
      }
    }
  };

  const handleDate = (event, data) => {
    if (data.value) {
      if (getDateString(selectDate) === getDateString(data.value)) {
        return;
      }
      if (!datePickerFilterDate(data.value)) {
        return;
      }
      setSelectDate(data.value);
      store.dispatch({
        type: "Filter_Date",
        text: data.value,
      });
    }
  };

  return (
    <div>
      <SemanticDatepicker
        filterDate={datePickerFilterDate}
        onChange={handleDate}
        clearable={false}
        showToday={false}
        datePickerOnly={true}
        value={selectDate}
        clearOnSameDateClick={false}
      />
    </div>
  );
};

class ToggleType extends React.Component {
  componentDidMount() {
    store.subscribe(() => this.forceUpdate());
    // let state_ = store.getState();
    // let itemType = state_.resultTable.itemType
  }

  // state = { activeItem: itemType === "0"? "按回購日期" : '按時期' }

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
    if (name === "按回購日期") {
      store.dispatch({
        type: "SET_ACTIVE_PAGE",
        text: "1",
      });
      store.dispatch({
        type: "TOGGLE_TYPE",
        text: "0",
      });
    } else if (name === "按股份") {
      store.dispatch({
        type: "SET_ACTIVE_PAGE",
        text: "1",
      });
      store.dispatch({
        type: "TOGGLE_TYPE",
        text: "1",
      });
    } else if (name === "按時期") {
      store.dispatch({
        type: "SET_ACTIVE_PAGE",
        text: "1",
      });
      store.dispatch({
        type: "TOGGLE_TYPE",
        text: "2",
      });
      store.dispatch({
        type: "FILTER_DATA_BY_TS",
        text: new Date().getTime() - 1 * 28 * 24 * 60 * 60 * 1000,
      });
    }
  };
  render() {
    const state = store.getState();
    const activeItem =
      state.resultTable.itemType === "0" ? "按回購日期" : "按時期";
    return (
      <div>
        <Menu pointing secondary compact>
          <Menu.Item
            name="按回購日期"
            active={activeItem === "按回購日期"}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name="按時期"
            active={activeItem === "按時期"}
            onClick={this.handleItemClick}
          />
          {/* <Menu.Item
                        name='按股份'
                        active={activeItem === '按股份'}
                        onClick={this.handleItemClick}
                    /> */}
        </Menu>
        {/* <div style={{float:'right', textAlign:'right'}}>
                <Popup
                    on='click'
                    pinned
                    position='bottom right'
                    size='small'
                    trigger={
                     <div style={{paddingTop:'1em'}}>
                        <Icon  color='grey' name='question circle' />
                     </div>
                    
                    }
                >
                    <p>
                    </p>
                </Popup>
              </div> */}
      </div>
    );
  }
}

class ToggleTs extends React.Component {
  componentDidMount() {
    store.subscribe(() => this.forceUpdate());
  }

  today = new Date();
  state = { activeItem: "一個月" };

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
    if (name === "一星期") {
      store.dispatch({
        type: "FILTER_DATA_BY_TS",
        text: new Date().getTime() - 7 * 24 * 60 * 60 * 1000,
      });
      store.dispatch({
        type: "Filter_TS",
        text: new Date().getTime() - 7 * 24 * 60 * 60 * 1000,
      });
    } else if (name === "一個月") {
      store.dispatch({
        type: "FILTER_DATA_BY_TS",
        text: new Date().getTime() - 1 * 28 * 24 * 60 * 60 * 1000,
      });
      store.dispatch({
        type: "Filter_TS",
        text: new Date().getTime() - 1 * 28 * 24 * 60 * 60 * 1000,
      });
    } else if (name === "三個月") {
      store.dispatch({
        type: "FILTER_DATA_BY_TS",
        text: new Date().getTime() - 3 * 28 * 24 * 60 * 60 * 1000,
      });
      store.dispatch({
        type: "Filter_TS",
        text: new Date().getTime() - 3 * 28 * 24 * 60 * 60 * 1000,
      });
    } else if (name === "一年") {
      store.dispatch({
        type: "FILTER_DATA_BY_TS",
        text: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
      });
      store.dispatch({
        type: "Filter_TS",
        text: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
      });
    } else if (name === "年初至今") {
      store.dispatch({
        type: "FILTER_DATA_BY_TS",
        text: new Date(new Date().getFullYear(), 0, 1),
      });
      store.dispatch({
        type: "Filter_TS",
        text: new Date(new Date().getFullYear(), 0, 1),
      });
    }
  };
  render() {
    const { activeItem } = this.state;
    return (
      <div>
        <Menu pointing secondary compact>
          <Menu.Item
            name="一星期"
            active={activeItem === "一星期"}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name="一個月"
            active={activeItem === "一個月"}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name="三個月"
            active={activeItem === "三個月"}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name="一年"
            active={activeItem === "一年"}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name="年初至今"
            active={activeItem === "年初至今"}
            onClick={this.handleItemClick}
          />
        </Menu>
      </div>
    );
  }
}

class FilterInput extends React.Component {
  componentDidMount() {
    store.subscribe(() => this.forceUpdate());
  }

  state = {
    value: store.getState().resultTable.filterText.value,
  };

  onChangeFilter = (e) => {
    this.setState({
      value: e.target.value,
    });

    store.dispatch({
      type: "Filter_MESSAGE",
      text: { value: e.target.value },
    });
  };

  onClear = (e) => {
    this.setState({
      value: "",
    });

    store.dispatch({
      type: "Filter_MESSAGE",
      text: { value: e.target.value },
    });
  };

  render() {
    return (
      <div>
        <Input
          style={{ width: "100%", maxWidth: "800px" }}
          icon={
            <Icon name="close" inverted circular link onClick={this.onClear} />
          }
          onChange={this.onChangeFilter}
          // value={this.props.value.value}
          value={this.state.value}
          type="text"
          placeholder="輸入股份代號或名稱"
        />
      </div>
    );
  }
}

function timeConverter(UNIX_timestamp) {
  var a = new Date(UNIX_timestamp);
  // var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  var year = a.getFullYear();
  // var month = months[a.getMonth()];
  var month = ("0" + (a.getMonth() + 1)).slice(-2);
  var date = ("0" + a.getDate()).slice(-2);
  var time = year + "/" + month + "/" + date;
  return time;
}
