import { makeStyles, IconButton, Button } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useState } from 'react';

const useStyles = makeStyles(theme => ({
  menu_list_container: {
    position: 'relative',
    backgroundColor: 'lightgray',
  },
  menu_list: {
    zIndex: 10,
    position: 'absolute',
    right: 0,
    backgroundColor: 'lightyellow',
    display: 'flex',
    flexDirection: 'column',
    fontSize: 'large',
  },
  menu_list_item: {
    padding: '5px',
  },
  menu_list_item_button: {

  }
}));

export const PostMenu = (props) => {
  const classes = useStyles();
  const [ open, setOpen ] = useState(false);
  const options = props.options;
  const postID = props.postID;

  return (
    <div>
      <IconButton onClick={() => {setOpen(!open)}}>
        <MenuIcon></MenuIcon>
      </IconButton>
      { open &&
        <div className={classes.menu_list_container}>
          <div className={classes.menu_list}>
            { options && options.map((item, index) => {
              return (
                <div key={index} className={classes.menu_list_item}>
                  <Button className={classes.menu_list_item_button} onClick={(event) => {
                    item.action(postID);
                    setOpen(false);
                  }} variant="contained" color="primary">
                    <div>{item.value}</div>
                  </Button>
                </div>
              )
            })}
          </div>
        </div>
      }
    </div>
  )
}