import { useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { Loader, Header } from "semantic-ui-react";
import {
  selectAllTopic,
  fetchTopic,
  toIdle,
} from "../../../features/topic/topicSlice";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

function zip(arrays) {
  return Array.apply(null, Array(arrays[0].length)).map(function (_, i) {
    return arrays.map(function (array) {
      return array[i];
    });
  });
}

function roundData(d) {
  return Math.round(d * 100) / 100;
}

function roundData2(d) {
  return Math.round(d * 10) / 10;
}

export default function NHNLChart() {
  const dispatch = useDispatch();
  const topics = useSelector(selectAllTopic);
  const topicStatus = useSelector((state) => state.topic.status);
  const [stockStat, setStockStat] = useState({
    data: null,
    loaded: false,
  });

  useEffect(() => {
    if (topicStatus === "idle" && !stockStat.loaded) {
      dispatch(fetchTopic("nhnl_from_aws"));
    } else if (topicStatus === "loading") {
    } else if (topicStatus === "succeeded") {
      const data = topics.find(
        (item) => item.topicInfo.name === "nhnl_from_aws"
      );
      if (data) {
        setStockStat({
          ...stockStat,
          data: data.data,
          loaded: true,
        });
      } else {
        dispatch(toIdle({}));
      }
    } else if (topicStatus === "error") {
    }
  }, [topicStatus, dispatch]);

  const chartHeight = 750;
  let nhnlData = [];
  let chartOption = {};

  if (stockStat.loaded && stockStat.data.date.length > 0) {
    let dates = stockStat.data["date"].map((item) => new Date(item).getTime());
    nhnlData.push({
      name: "New Highs New Lows",
      data: zip([
        dates,
        _.map(stockStat.data["mkt_new_highs_new_lows"], roundData2),
      ]),
    });
    nhnlData.push({
      name: "New Highs New Lows 10 days moving average",
      data: zip([
        dates,
        _.map(stockStat.data["mkt_new_highs_new_lows_10_day"], roundData2),
      ]),
    });
    chartOption = {
      rangeSelector: {
        selected: 1,
      },

      navigator: {
        series: {
          lineWidth: 1,
          lineColor: "#2F4F4F",
          color: "#2F4F4F",
          fillOpacity: 0.2,
        },
      },

      legend: {
        enabled: true,
        align: "center",
        verticalAlign: "bottom",
        x: 0,
        y: 0,
      },

      chart: {
        height: chartHeight,
      },

      xAxis: {
        type: "datetime",
      },

      yAxis: [
        {
          title: {
            text: "New Highs - New Lows",
            rotation: 90,
            style: {
              color: Highcharts.getOptions().colors[1],
            },
          },
        },
      ],

      tooltip: {
        shared: true,
      },

      scrollbar: {
        enabled: true,
      },

      series: [
        {
          type: "line",
          name: "NHNL",
          data: nhnlData[0].data,
          color: "blue",
          visible: true,
        },
        {
          type: "line",
          name: "NHNL-MA10",
          data: nhnlData[1].data,
          color: "green",
          visible: true,
        },
      ],
      credits: {
        text: "DaaSHK",
        style: {
          fontSize: "0.8em",
        },
      },
    };
  }

  return (
    <>
      {stockStat.data && stockStat.data.date.length > 0 ? (
        <>
          <Header as="h3" textAlign="center">
            New Highs and New Lows
          </Header>
          <div>
            <HighchartsReact
              constructorType={"stockChart"}
              highcharts={Highcharts}
              options={chartOption}
            />
          </div>
        </>
      ) : !stockStat.loaded ? (
        <Loader active inline="centered" />
      ) : (
        <div>沒有資料</div>
      )}
    </>
  );
}
