import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { collection, doc, getDoc } from "firebase/firestore";
import { db, analytics } from "../../config/fbConfig";
import { logEvent } from "firebase/analytics";

const initialState = {
  stock: [],
  status: "idle",
  error: null,
};
export const addNewStock = createAsyncThunk("stock/addNewStock", async (id) => {
  let stocksRef = collection(db, "stocks");
  logEvent(analytics, "select_content", {
    content_type: "stocks_" + id,
  });
  const snapshot = await getDoc(doc(stocksRef, id));
  // console.log(snapshot.data())
  return snapshot.data();
});

export const fetchActive = createAsyncThunk("index/fetchActive", async () => {
  let stocksRef = collection(db, "index");
  logEvent(analytics, "select_content", {
    content_type: "index_activeStock",
  });
  const snapshot = await getDoc(doc(stocksRef, "activeStock"));
  // console.log(snapshot.data().index)
  return snapshot.data().activeStock;
});

export const fetchStock = createAsyncThunk("users/fetchStock", async (id) => {
  let stocksRef = collection(db, "stocks");
  logEvent(analytics, "select_content", {
    content_type: "stocks_" + id,
  });
  const snapshot = await getDoc(doc(stocksRef, id));
  // console.log(snapshot)
  // console.log(snapshot.data())
  return snapshot.data();
});

export const fetchEPS = createAsyncThunk("users/fetchEPS", async (id) => {
  console.log(id);
  let stocksRef = collection(db, "stock_extra");
  logEvent(analytics, "select_content", {
    content_type: "stocks_" + id,
  });
  const snapshot = await getDoc(
    doc(collection(doc(stocksRef, id), "Fundamental"), "eps")
  );
  console.log(snapshot);
  // console.log(snapshot)const snapshot = await getDoc(doc(stocksRef, id));
  console.log(snapshot.data());
  // return snapshot.data();
});

export const fetchUSStock = createAsyncThunk(
  "users/fetchUSStock",
  async (id) => {
    let stocksRef = collection(db, "us_equity_stat_from_aws");
    logEvent(analytics, "select_content", {
      content_type: "stocks_" + id,
    });
    const snapshot = await getDoc(doc(stocksRef, id));
    let newTopicInfo = {
      ...snapshot.data().topicInfo,
      no: snapshot.data().topicInfo.stockCode,
    };
    return {
      data: snapshot.data().data,
      topicInfo: newTopicInfo,
      stockInfo: newTopicInfo,
    };
  }
);

const stockSlice = createSlice({
  name: "stock",
  initialState,
  reducers: {
    toIdle(state, action) {
      state.status = "idle";
    },
  },
  extraReducers: {
    [fetchStock.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchStock.fulfilled]: (state, action) => {
      state.status = "succeeded";
      // console.log('hihihih')
      // console.log(action)
      // if (action.payload){
      state.stock.push(action.payload);
      // }
    },
    [fetchEPS.fulfilled]: (state, action) => {
      state.status = "succeeded";
      // console.log('hihihih')
      // console.log(action)
      // if (action.payload){
      // state.stock.push(action.payload);
      // }
    },
    [fetchStock.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [fetchUSStock.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchUSStock.fulfilled]: (state, action) => {
      state.status = "succeeded";
      // console.log('hihihih')
      // console.log(action.payload);
      // if (action.payload){
      state.stock.push(action.payload);
      // }
    },
    [fetchUSStock.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [addNewStock.fulfilled]: (state, action) => {
      // console.log(state)
      state.stock.push(action.payload);
    },
    [fetchActive.fulfilled]: (state, action) => {
      // console.log(action.payload)
      action.payload.map((item) => state.stock.push(item));
    },
  },
});

export const { toIdle } = stockSlice.actions;
export default stockSlice.reducer;

export const selectStockById = (state, stockId) => {
  // console.log('hi')
  let wanted = state.stock.stock.find((item) => item.id === stockId);
  if (wanted) {
    return wanted;
  } else {
    // console.log('toFetch')
    fetchStock(stockId);
    return state.stock.stock.find((item) => item.id === stockId);
  }
};

export const selectStockById2 = (state, stockId) =>
  state.stock.stock.find((item) => item.id === stockId);

export const selectAllStock = (state) => state.stock.stock;
