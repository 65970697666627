import _ from "lodash";
import React, { useEffect, useState, useReducer } from "react";
import {
  Loader,
  Table,
  Pagination,
  Container,
  Header,
  Dropdown,
  Icon,
  Input,
  Form,
  Checkbox,
  Menu,
} from "semantic-ui-react";
import { createStore, combineReducers } from "redux";
import { isMobile } from "react-device-detect";
import source from "../../../mock_source";
import { Link } from "react-router-dom";
import { formatNumber } from "../../../util/dataFormatter";
import PriceLine from "../../charts/PriceLine2";
import Slider from "@material-ui/core/Slider";

const initialState = {
  direction: "descending",
  column: "rs",
  filterText: { value: "" },
  filterInd: "所有",
  filterSector: "",
  filterSubSector: "",
  indOption: [],
  sectorOption: [],
  activePage: "1",
  totalPage: "4",
  topN: "10",
  timePeriod: "m1",
  sliderFilter_p: [0, 100],
  sliderFilter_hkc: [0, 100],
  sliderFilter_np: [0, 100],
  sliderFilter_top10: [0, 100],
  sliderFilter_nc: [0, 100],
};
// isFocus: "1",
// itemType: "0",
// lastUpdate: { value: "" },
// priceFilter: ["", ""],
// capFilter: ["", ""],
// volFilter: "",
// filterCondition: { c1: false, c2: false, c3: false, c4: false },
// rangeFilter_PRICE: [0, 10000],
// };

function resultTableReducer(state = initialState, action) {
  switch (action.type) {
    case "CHANGE_SORT": {
      if (state.column === action.column) {
        return {
          ...state,
          direction:
            state.direction === "ascending" ? "descending" : "ascending",
          activePage: 1,
        };
      }
      return {
        ...state,
        column: action.column,
        direction: "ascending",
        activePage: 1,
      };
    }
    case "SET_ACTIVE_PAGE": {
      const string = action.text;
      // window.scrollTo(0, 0);
      return {
        ...state,
        activePage: string,
      };
    }
    case "SET_TOPN_DROPDOWN": {
      return {
        ...state,
        topN: action.state,
      };
    }
    case "SET_TIMEPERIOD": {
      return {
        ...state,
        timePeriod: action.state,
      };
    }
    case "SET_SLIDER_FILTER": {
      console.log("here to set");
      console.log(action);
      return {
        ...state,
        sliderFilter_p: action.text.value,
      };
    }
    default: {
      return state;
    }
  }
}

function HKCTable(props) {
  const { topicInfo, nwtable } = props.data; //data
  const header = nwtable.header;

  // const data1 = _.values(nwtable);
  const [tableData, setTableData] = useState([]);

  function obj2Array(n) {
    return Object.values(n);
  }
  var data1 = _.map(nwtable.data, obj2Array);
  // console.log(header);
  // console.log(data1);

  useEffect(() => {
    var buffer = data1.map((v) => {
      var d = {};
      for (var i = 0; i < header.length; i++) {
        d[header[i]] = v[0][i];
      }
      let a = _.find(source, function (o) {
        return o.no === d.id;
      });
      return {
        ...d,
        name_zh: a ? a["name_zh"] : "",
        name_en: a ? a["name_en"] : "",
        p_w1_pct: calPriceLinePct(d.C, d.p_w1_L, d.p_w1_H),
        p_m1_pct: calPriceLinePct(d.C, d.p_m1_L, d.p_m1_H),
        p_m3_pct: calPriceLinePct(d.C, d.p_m3_L, d.p_m3_H),
        p_m6_pct: calPriceLinePct(d.C, d.p_m6_L, d.p_m6_H),
        p_m12_pct: calPriceLinePct(d.C, d.p_m12_L, d.p_m12_H),
        hkc_w1_pct: calPriceLinePct(d.C, d.p_w1_L, d.p_w1_H),
        hkc_m1_pct: calPriceLinePct(d.C, d.p_m1_L, d.p_m1_H),
        hkc_m3_pct: calPriceLinePct(d.C, d.p_m3_L, d.p_m3_H),
        hkc_m6_pct: calPriceLinePct(d.C, d.p_m6_L, d.p_m6_H),
        hkc_m12_pct: calPriceLinePct(d.C, d.p_m12_L, d.p_m12_H),
        np_w1_pct: calPriceLinePct(d.C, d.p_w1_L, d.p_w1_H),
        np_m1_pct: calPriceLinePct(d.C, d.p_m1_L, d.p_m1_H),
        np_m3_pct: calPriceLinePct(d.C, d.p_m3_L, d.p_m3_H),
        np_m6_pct: calPriceLinePct(d.C, d.p_m6_L, d.p_m6_H),
        np_m12_pct: calPriceLinePct(d.C, d.p_m12_L, d.p_m12_H),
        nc_w1_pct: calPriceLinePct(d.C, d.p_w1_L, d.p_w1_H),
        nc_m1_pct: calPriceLinePct(d.C, d.p_m1_L, d.p_m1_H),
        nc_m3_pct: calPriceLinePct(d.C, d.p_m3_L, d.p_m3_H),
        nc_m6_pct: calPriceLinePct(d.C, d.p_m6_L, d.p_m6_H),
        nc_m12_pct: calPriceLinePct(d.C, d.p_m12_L, d.p_m12_H),
        t10_w1_pct: calPriceLinePct(d.C, d.p_w1_L, d.p_w1_H),
        t10_m1_pct: calPriceLinePct(d.C, d.p_m1_L, d.p_m1_H),
        t10_m3_pct: calPriceLinePct(d.C, d.p_m3_L, d.p_m3_H),
        t10_m6_pct: calPriceLinePct(d.C, d.p_m6_L, d.p_m6_H),
        t10_m12_pct: calPriceLinePct(d.C, d.p_m12_L, d.p_m12_H),
        // hkc, nc, np, t10
      };
    });
    setTableData(buffer);
  }, []);

  let newData = _.sortBy(tableData, ["rs"]).reverse();

  // if (filters) {
  //   // Filter for Price
  //   if (parseFloat(pricel) > 0 || parseFloat(priceh) > 0) {
  //     newData = dataFilter(newData, pricel, priceh, "C");
  //   }

  //   // Filter for Cap

  //   if (parseFloat(capRl) > 0 || parseFloat(capRh) > 0) {
  //     newData = dataFilter(newData, capRl, capRh, "cap");
  //   }

  //   // Filter for RS
  //   if (parseFloat(rsl) > 0 || parseFloat(rsh) > 0) {
  //     newData = dataFilter(newData, rsl, rsh, "rs");
  //   }

  //   // Filter for nonCCASS
  //   if (parseFloat(ncPctl) > 0 || parseFloat(ncPcth) > 0) {
  //     newData = dataFilter(newData, ncPctl, ncPcth, "ncC");
  //   }

  //   // Filter for topNpct
  //   console.log("nTopNConc");
  //   console.log(nTopNConc);
  //   console.log(TopNConcl);

  //   if (parseFloat(nTopNConc) > 0 && parseFloat(TopNConcl) > 0) {
  //     newData = dataFilter(newData, TopNConcl, 100, "t" + nTopNConc + "C");
  //   }

  //   // Filter for HKC pct
  //   if (parseFloat(hkcPctl) > 0 || parseFloat(hkcPcth) > 0) {
  //     newData = dataFilter(newData, hkcPctl, hkcPcth, "hkcC");
  //   }
  // }

  return (
    <Container
      style={{
        widht: "100%",
        maxWidth: "1000px",
        margin: "auto",
        alignItems: "center",
      }}
    >
      <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
        <Header as="h4" textAlign="right" color="grey">
          {" "}
          數據至 : {topicInfo.lastUpdate} <br />
          總數 : {newData.length}{" "}
        </Header>
      </div>
      <div style={{ textAlign: "center", width: isMobile ? "95%" : "100%" }}>
        <SortableTable data={newData} />
      </div>
    </Container>
  );
}

function dataFilter(data, filterLow, filterHigh, q) {
  const low = parseFloat(filterLow) > 0 ? parseFloat(filterLow) : 0;
  const high = parseFloat(filterHigh) > 0 ? parseFloat(filterHigh) : 99999999;
  const newData = _.filter(data, function (d) {
    return d[q] >= low && d[q] <= high;
  });
  return newData;
}

function calPriceLinePct(now, low, high) {
  return parseInt((((now - low) / (high - low)) * 100 * 100) / 100);
}

function PriceLineDisplay(props) {
  const { now, high, low, pre, suf } = props.data;
  return (
    <div>
      <div style={{ marginBottom: "-1rem", fontSize: "0.9rem" }}>
        {`${pre}${now}${suf}`}
      </div>
      <div style={{ width: "100px", margin: "auto" }}>
        <PriceLine min={low} mid={now} max={high} col={"#2471A3"} />
      </div>
      <div
        style={{
          marginTop: "-0.55rem",
          fontSize: "0.65rem",
        }}
      >
        <div style={{ float: "left" }}>{low}</div>
        <div style={{ float: "right" }}>{high}</div>
      </div>
    </div>
  );
}

function SortableTable(props) {
  const [state, dispatch] = useReducer(resultTableReducer, initialState);
  const { direction, column, activePage, timePeriod, sliderFilter_p } = state;
  const { data } = props;
  const [slider_p, setSlider_p] = useState(sliderFilter_p);

  let newData = data;
  const timeOptions = [
    { k: "w1", v: "一週" },
    { k: "m1", v: "一月" },
    { k: "m3", v: "三月" },
    { k: "m6", v: "半年" },
    { k: "m12", v: "一年" },
  ];
  const pos_pagination = isMobile ? "center" : "right";
  if (direction === "ascending") {
    newData = _.sortBy(newData, [column]);
  } else {
    newData = _.sortBy(newData, [column]).reverse();
  }

  newData = _.filter(newData, function (d) {
    return (
      sliderFilter_p[0] <= d[`p_${timePeriod}_pct`] &&
      sliderFilter_p[1] >= d[`p_${timePeriod}_pct`]
    );
  });
  console.log("reload");

  const nPerPage = 25;
  const totalPage = Math.ceil(newData.length / nPerPage);
  let newData2 = [];
  newData2 = _.slice(
    newData,
    (activePage - 1) * nPerPage,
    activePage * nPerPage
  );
  const handlePaginationChange = (e, { activePage }) => {
    dispatch({
      type: "SET_ACTIVE_PAGE",
      text: activePage,
    });
  };
  const handleSliderFilter = (value, name) => {
    dispatch({
      type: "SET_SLIDER_FILTER",
      text: { value: value, name: name },
    });
  };

  return (
    <div
      style={{
        whiteSpace: "nowrap",
        overflowX: "auto",
        display: "inline-block",
        width: "100%",
        textAlign: "center",
      }}
    >
      <div style={{ textAlign: "left" }}>
        <Menu pointing secondary compact>
          <Menu.Item header>時期:</Menu.Item>
          {timeOptions.map((d, index) => (
            <Menu.Item
              name={d.k}
              active={timePeriod === d.k}
              onClick={() => {
                dispatch({ type: "SET_TIMEPERIOD", state: d.k });
              }}
            >
              {d.v}
            </Menu.Item>
          ))}
        </Menu>
      </div>
      <Table sortable selectable celled unstackable compact structured>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              textAlign="left"
              rowSpan="2"
              sorted={column === "id" ? direction : null}
              onClick={() => {
                dispatch({ type: "CHANGE_SORT", column: "id" });
              }}
            >
              名稱
              {isMobile ? <br /> : null}
              (編號)
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" rowSpan="2">
              <div
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "C" });
                }}
              >
                價格{" "}
                {column === "C" &&
                  (direction === "descending" ? (
                    <Icon link name="sort down" />
                  ) : (
                    <Icon link name="sort up" />
                  ))}
              </div>
              <div
                style={{
                  margin: "auto",
                  maxWidth: "100px",
                  maxHeight: "1.5rem",
                }}
              >
                <SliderFilter
                  slider_p={slider_p}
                  setSlider_p={setSlider_p}
                  handleSliderFilter={handleSliderFilter}
                />
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" rowSpan="2">
              <div
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "hkc" });
                }}
              >
                港股通持倉%{" "}
                {column === "hkc" &&
                  (direction === "descending" ? (
                    <Icon link name="sort down" />
                  ) : (
                    <Icon link name="sort up" />
                  ))}
              </div>
              <div
                style={{
                  margin: "auto",
                  maxWidth: "100px",
                  maxHeight: "1.5rem",
                }}
              >
                {/* <SliderFilterRS range={sliderFilter_RS} />{" "} */}
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" rowSpan="2">
              <div
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "np" });
                }}
              >
                參與者人數{" "}
                {column === "np" &&
                  (direction === "descending" ? (
                    <Icon link name="sort down" />
                  ) : (
                    <Icon link name="sort up" />
                  ))}
              </div>
              <div
                style={{
                  margin: "auto",
                  maxWidth: "100px",
                  maxHeight: "1.5rem",
                }}
              >
                {/* <SliderFilterRS range={sliderFilter_RS} />{" "} */}
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" rowSpan="2">
              <div
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "t10" });
                }}
              >
                持倉集中% <br /> Top10
                {column === "t10" &&
                  (direction === "descending" ? (
                    <Icon link name="sort down" />
                  ) : (
                    <Icon link name="sort up" />
                  ))}
              </div>
              <div
                style={{
                  margin: "auto",
                  maxWidth: "100px",
                  maxHeight: "1.5rem",
                }}
              >
                {/* <SliderFilterRS range={sliderFilter_RS} />{" "} */}
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" rowSpan="2">
              <div
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "nc" });
                }}
              >
                Non-CCASS%
                {column === "nc" &&
                  (direction === "descending" ? (
                    <Icon link name="sort down" />
                  ) : (
                    <Icon link name="sort up" />
                  ))}
              </div>
              <div
                style={{
                  margin: "auto",
                  maxWidth: "100px",
                  maxHeight: "1.5rem",
                }}
              >
                {/* <SliderFilterRS range={sliderFilter_RS} />{" "} */}
              </div>
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row></Table.Row>
        </Table.Header>
        <Table.Body>
          {newData2.map((d, index) => (
            <>
              {d.name_zh ? (
                <Table.Row key={d.id}>
                  <Table.Cell textAlign="left">
                    {d.name_zh !== "" ? (
                      <Link to={"/stock/" + d.id}>
                        {d.name_zh.length > 8
                          ? d.name_zh.substring(0, 8) + "..."
                          : d.name_zh}
                        {isMobile ? <br /> : null}({d.id})
                      </Link>
                    ) : (
                      <>({d.id})</>
                    )}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <PriceLineDisplay
                      data={{
                        now: d["C"],
                        high: d[`p_${timePeriod}_H`],
                        low: d[`p_${timePeriod}_L`],
                        pre: "$",
                        suf: "",
                      }}
                    />
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <PriceLineDisplay
                      data={{
                        now: d["hkc"],
                        high: d[`hkc_${timePeriod}_H`],
                        low: d[`hkc_${timePeriod}_L`],
                        pre: "",
                        suf: "%",
                      }}
                    />
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <PriceLineDisplay
                      data={{
                        now: d["np"],
                        high: d[`np_${timePeriod}_H`],
                        low: d[`np_${timePeriod}_L`],
                        pre: "",
                        suf: "",
                      }}
                    />
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <PriceLineDisplay
                      data={{
                        now: d["t10"],
                        high: d[`t10_${timePeriod}_H`],
                        low: d[`t10_${timePeriod}_L`],
                        pre: "",
                        suf: "%",
                      }}
                    />
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <PriceLineDisplay
                      data={{
                        now: d["nc"],
                        high: d[`nc_${timePeriod}_H`],
                        low: d[`nc_${timePeriod}_L`],
                        pre: "",
                        suf: "%",
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              ) : null}
            </>
          ))}
        </Table.Body>
      </Table>
      <div
        style={{
          paddingTop: "25px",
          paddingBottom: "25px",
          float: pos_pagination,
        }}
      >
        <Pagination
          boundaryRange={0}
          activePage={activePage}
          size={isMobile ? "small" : "big"}
          siblingRange={1}
          totalPages={totalPage}
          onPageChange={handlePaginationChange}
        />
      </div>
    </div>
  );
}

function valueLabelFormat(value) {
  return value + "%";
}

function SliderFilter(props) {
  const handleChange = (event, newValue) => {
    props.setSlider_p(newValue);
  };

  const handleChangeCommitted = (event, value) => {
    props.handleSliderFilter(value, "p");
    // store.dispatch({
    //   type: "SET_SLIDER_FILTER_RS",
    //   text: { value },
    // });
  };

  return (
    <form>
      <Slider
        valueLabelDisplay="auto"
        value={props.slider_p}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
        valueLabelFormat={valueLabelFormat}
        // marks={[
        //   { value: 0, label: "0%" },
        //   { value: 100, label: "100%" },
        // ]}
      />
    </form>
  );
}

export default HKCTable;
