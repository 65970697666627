import React, { useEffect, useState, Component, useCallback } from "react";
import {
  Loader,
  Table,
  Pagination,
  Menu,
  Container,
  Header,
  Dropdown,
  Popup,
  Icon,
  Input,
} from "semantic-ui-react";
import _ from "lodash";
import { createStore, combineReducers } from "redux";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
// import { selectAllTopic, fetchTopic, toIdle } from '../../features/topic/topicSlice'
// import { useSelector, useDispatch } from 'react-redux'
import SemanticDatepicker from "react-semantic-ui-datepickers";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
import InFeedAdsWhite from "../ads/InFeedAdsWhite";
import source from "../../mock_source";
import PriceLine from "../charts/PriceLine2";
import Slider from "@material-ui/core/Slider";

export const HKConnectTable = () => {
  // const dispatch = useDispatch()
  // const topics = useSelector(selectAllTopic)
  // const topicStatus = useSelector((state) => state.topic.status)
  // const error = useSelector((state) => state.topic.error)

  // useEffect(() => {
  //   if (topicStatus === 'idle') {
  //     dispatch(fetchTopic('buyback'))
  //   }
  // }, [topicStatus, dispatch])

  // let content

  // if (topicStatus === 'loading') {
  //   content =
  //     <div>
  //       <section className="posts-list">
  //         <div
  //           style={{
  //             padding: "100px",
  //             margin: "auto",
  //             alignItems: "center",
  //             height: "75vh",
  //             // overflow: "auto",
  //           }}
  //         >
  //           <Loader active inline='centered' > Loading</Loader>
  //         </div>
  //       </section>
  //     </div>
  // } else if (topicStatus === 'succeeded') {
  //   const data = topics.find((item) => item.topicInfo.name === 'buyback')
  //   if (data) {
  //     content = <BuyBackView result={data} />
  //   }
  //   else {
  //     dispatch(toIdle({}))
  //   }
  // } else if (topicStatus === 'error') {
  //   content =
  //     <div>
  //       <section className="posts-list">
  //         <div>{error}</div>
  //       </section>
  //     </div>
  // }
  // return (
  //   <>
  //     {content}
  //   </>
  // )
  return (
    <>
      <HKConnectView data={data} stockSector={stockSector} />
    </>
  );
};

export default HKConnectTable;

function HKConnectView(data) {
  let result = data.data;
  let stockSector = data.stockSector;
  return (
    <>
      <div style={{ paddingTop: "25px", paddingBottom: "25px" }}>
        {/* hello */}
        {typeof result !== "undefined" ? (
          <SortableTable data={result} stockSector={stockSector} />
        ) : (
          <NotFound />
        )}
      </div>
    </>
  );
}

function NotFound() {
  return (
    <div
      style={{
        padding: "100px",
        margin: "auto",
        alignItems: "center",
        height: "75vh",
        // overflow: "auto",
      }}
    >
      <Loader active inline="centered">
        {" "}
        Loading
      </Loader>
    </div>
  );
}

const initialState = {
  data: [],
  direction: "descending",
  //   direction_gp: "descending",
  column: "d_3d",
  filterText: { value: "" },
  filterInd: "所有",
  filterSector: "",
  filterSubSector: "",
  indOption: [],
  sectorOption: [],
  activePage: "1",
  isFocus: "1",
  totalPage: "4",
  itemType: "0",
  lastUpdate: { value: "" },
  sliderFilter: [50, 80],
};

const reducer = combineReducers({
  resultTable: resultTableReducer,
});

function resultTableReducer(state = initialState, action) {
  switch (action.type) {
    case "CHANGE_SORT": {
      if (state.column === action.column) {
        // console.log(state.data);
        return {
          ...state,
          //   data:
          //     state.direction === "ascending"
          //       ? _.sortBy(state.data, [action.column]).reverse()
          //       : _.sortBy(state.data, [action.column]),
          //   state.data.reverse(),
          direction:
            state.direction === "ascending" ? "descending" : "ascending",
          activePage: 1,
        };
      }
      return {
        ...state,
        column: action.column,
        // data: _.sortBy(state.data, [action.column]),
        direction: "ascending",
        activePage: 1,
      };
    }
    case "Filter_MESSAGE": {
      const string = action.text;
      return {
        ...state,
        filterText: string,
        activePage: 1,
      };
    }
    case "TOGGLE_TYPE": {
      const string = action.text;
      return {
        ...state,
        itemType: string,
      };
    }

    case "SET_IND_FILTER": {
      if (state.direction === "ascending") {
        return {
          ...state,
          filterInd: action.state,
          activePage: 1,
          data: _.sortBy(state.data, state.column),
        };
      } else {
        return {
          ...state,
          filterInd: action.state,
          activePage: 1,
          data: _.sortBy(state.data, state.column).reverse(),
        };
      }
    }
    case "SET_SLIDER_FILTER": {
      const string = action.text;
      return {
        ...state,
        sliderFilter: string.value,
      };
    }
    case "SET_ACTIVE_PAGE": {
      const string = action.text;
      window.scrollTo(0, 0);
      return {
        ...state,
        activePage: string,
      };
    }
    case "ADD_MESSAGE": {
      return {
        ...state,
        data: action.state,
      };
    }
    case "ADD_OPTIONS": {
      return {
        ...state,
        options: action.state,
      };
    }
    case "ADD_LASTUPDATE": {
      return {
        ...state,
        lastUpdate: action.state,
      };
    }
    default: {
      return state;
    }
  }
}

const store = createStore(reducer);

class SortableTable extends React.Component {
  constructor(props) {
    super(props);
    this.setData();
  }

  setData() {
    var data1 = _.values(this.props.data.data);
    var lastUpdate = this.props.data.topicInfo.lastUpdate;
    var ind = this.props.stockSector.ind;
    var iDict = this.props.stockSector.iDict;
    var sDict = this.props.stockSector.sDict;
    var ssDict = this.props.stockSector.ssDict;
    const options = [{ key: "所有", text: "所有", value: "所有" }];
    // console.log("hi");
    // console.log(source);

    var tableData1 = data1.map((v) => {
      let a = _.find(source, function (o) {
        return o.no === v.id;
      });
      let ii = ind[v.id];
      return {
        ...v,
        name_zh: a ? a["name_zh"] : "-",
        name_en: a ? a["name_en"] : "-",
        i: iDict[ii["i"]],
        s: sDict[ii["s"]],
        ss: ssDict[ii["ss"]],
      };
    });
    tableData1 = _.sortBy(tableData1, ["d_3d"]).reverse();
    store.dispatch({
      type: "ADD_MESSAGE",
      state: tableData1,
    });
    let j = 0;
    var ssDictArr = _.values(ssDict);
    for (j; j < ssDictArr.length; j += 1) {
      options.push({
        key: ssDictArr[j],
        text: ssDictArr[j],
        value: ssDictArr[j],
      });
    }
    // store.dispatch({
    //   type: 'ADD_LASTUPDATE',
    //   state: lastUpdate,
    // });
    store.dispatch({
      type: "ADD_OPTIONS",
      state: options,
    });
    store.dispatch({
      type: "ADD_LASTUPDATE",
      state: lastUpdate,
    });
  }

  componentDidMount() {
    store.subscribe(() => this.forceUpdate());
  }

  render() {
    const state = store.getState();
    // const { data, filterText, activePage, itemType, filterDate, lastUpdate} = state.resultTable
    const {
      data,
      activePage,
      filterText,
      options,
      filterInd,
      lastUpdate,
      direction,
      column,
      sliderFilter,
    } = state.resultTable;
    let newData = data;

    // if (itemType === "1") {
    if (filterText.value && filterText.value !== "") {
      newData = _.filter(newData, function (d) {
        return (
          (typeof d.id === "string" &&
            d.id.toLowerCase().includes(filterText.value.toLowerCase())) ||
          (typeof d.name_zh === "string" &&
            d.name_zh.toLowerCase().includes(filterText.value.toLowerCase())) ||
          (typeof d.name_en === "string" &&
            d.name_en.toLowerCase().includes(filterText.value.toLowerCase()))
        );
      });
    }

    if (filterInd && filterInd !== "所有") {
      newData = _.filter(newData, function (d) {
        return d.ss === filterInd;
      });
    }

    if (direction === "ascending") {
      newData = _.sortBy(newData, [column]);
    } else {
      newData = _.sortBy(newData, [column]).reverse();
    }

    newData = _.filter(newData, function (d) {
      const c = (d.cur - d.L_3m) / (d.H_3m - d.L_3m);
      console.log(c);
      return sliderFilter[0] / 100 <= c && sliderFilter[1] / 100 >= c;
    });

    const nPerPage = 25;
    const totalPage = Math.ceil(newData.length / nPerPage);
    let newData2 = [];
    newData2 = _.slice(
      newData,
      (activePage - 1) * nPerPage,
      activePage * nPerPage
    );

    return (
      <Container
        style={{
          widht: "100%",
          maxWidth: "950px",
          margin: "auto",
          alignItems: "center",
        }}
      >
        {/* <div style={{ paddingBottom: '10px', paddingTop: '10px' }}>
        <ToggleType />
      </div> */}

        <div style={{ width: isMobile ? "95%" : "100%", maxWidth: "450px" }}>
          <FilterInput value={filterText} />
          <br />
          <DropdownExampleControlled options={options} value={filterInd} />
        </div>

        <div style={{ paddingBottom: "10px" }}>
          {/* <Header as="h4" textAlign="right" color="grey">
            {" "}
            最後更新 :{" "}
          </Header> */}
          {/* <Header as="h4"  textAlign='right' color ='grey'> 最後更新 : {this.props.data.topicInfo.lastUpdate.slice(0,10)} </Header> */}
          <Header as="h4" textAlign="right" color="grey">
            {" "}
            數據至 : {lastUpdate} <br />
            總數 : {newData.length}{" "}
          </Header>
        </div>

        <div style={{ textAlign: "center", width: isMobile ? "95%" : "100%" }}>
          <TableComponent data={newData2} />
        </div>

        <div style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <PaginationContainer totalPage={totalPage} />
        </div>
      </Container>
    );
  }
}

function TableComponent(data) {
  const newData = data.data;
  const state = store.getState();
  const { column, direction } = state.resultTable;
  //   console.log(newData)
  return (
    <div
      style={{
        whiteSpace: "nowrap",
        overflowX: "auto",
        display: "inline-block",
        width: "100%",
        textAlign: "center",
      }}
    >
      <Table sortable selectable celled unstackable compact structured>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              textAlign="left"
              rowSpan="2"
              sorted={column === "id" ? direction : null}
              onClick={() => {
                store.dispatch({ type: "CHANGE_SORT", column: "id" });
              }}
            >
              名稱
              {isMobile ? <br /> : null}
              (編號)
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              rowSpan="1"
              sorted={column === "cur" ? direction : null}
              onClick={() => {
                store.dispatch({ type: "CHANGE_SORT", column: "cur" });
              }}
            >
              持倉比例
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" colSpan="5">
              港股通持倉比例變化 (N個交易天)
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>
              <div
                style={{
                  margin: "auto",
                  maxWidth: "100px",
                  // padding: "0px",
                  maxHeight: "1.5rem",
                }}
              >
                {" "}
                <SliderFilter range={[50, 80]} />{" "}
              </div>
            </Table.HeaderCell>

            <Table.HeaderCell
              textAlign="center"
              sorted={column === "d_1d" ? direction : null}
              onClick={() => {
                store.dispatch({ type: "CHANGE_SORT", column: "d_1d" });
              }}
            >
              1
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              sorted={column === "d_3d" ? direction : null}
              onClick={() => {
                store.dispatch({ type: "CHANGE_SORT", column: "d_3d" });
              }}
            >
              3
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              sorted={column === "d_5d" ? direction : null}
              onClick={() => {
                store.dispatch({ type: "CHANGE_SORT", column: "d_5d" });
              }}
            >
              5
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              sorted={column === "d_10d" ? direction : null}
              onClick={() => {
                store.dispatch({ type: "CHANGE_SORT", column: "d_10d" });
              }}
            >
              10
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              sorted={column === "d_20d" ? direction : null}
              onClick={() => {
                store.dispatch({ type: "CHANGE_SORT", column: "d_20d" });
              }}
            >
              20
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {newData.map(
            (
              {
                id,
                cur,
                d_1d,
                d_3d,
                d_5d,
                d_10d,
                d_20d,
                L_5d,
                H_5d,
                L_1m,
                H_1m,
                L_3m,
                H_3m,
                L_52w,
                H_52w,
                name_zh,
                name_en,
              },
              index
            ) => (
              <>
                {/* {(index !== 0) && (index % 10 === 0) && (
                <Table.Row>
                  <Table.Cell colSpan={6} textAlign='center'>
                    <InFeedAdsWhite></InFeedAdsWhite>
                  </Table.Cell>
                </Table.Row>
              )} */}
                <Table.Row key={id}>
                  <Table.Cell textAlign="left">
                    <Link to={"/stock/" + id}>
                      {name_zh.length > 8
                        ? name_zh.substring(0, 8) + "..."
                        : name_zh}
                      {isMobile ? <br /> : null}({id})
                    </Link>
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <div style={{ marginBottom: "-0.75rem" }}> {cur}% </div>
                    {/* <br/> */}
                    <div style={{ maxWidth: "100px", margin: "auto" }}>
                      <PriceLine
                        min={L_3m}
                        secondMin={L_5d}
                        mid={cur}
                        secondMax={H_5d}
                        max={H_3m}
                      />
                    </div>
                  </Table.Cell>
                  {d_1d === "-" ? (
                    <Table.Cell textAlign="center"> - </Table.Cell>
                  ) : d_1d > 0 ? (
                    <Table.Cell positive textAlign="center">
                      {" "}
                      +{d_1d}%
                    </Table.Cell>
                  ) : d_1d === 0 ? (
                    <Table.Cell active textAlign="center">
                      {" "}
                      {d_1d}%
                    </Table.Cell>
                  ) : (
                    <Table.Cell negative textAlign="center">
                      {" "}
                      {d_1d}%{" "}
                    </Table.Cell>
                  )}
                  {d_3d === "-" ? (
                    <Table.Cell textAlign="center"> - </Table.Cell>
                  ) : d_3d > 0 ? (
                    <Table.Cell positive textAlign="center">
                      {" "}
                      +{d_3d}%
                    </Table.Cell>
                  ) : d_3d === 0 ? (
                    <Table.Cell active textAlign="center">
                      {" "}
                      {d_3d}%
                    </Table.Cell>
                  ) : (
                    <Table.Cell negative textAlign="center">
                      {" "}
                      {d_3d}%{" "}
                    </Table.Cell>
                  )}
                  {d_5d === "-" ? (
                    <Table.Cell textAlign="center"> - </Table.Cell>
                  ) : d_5d > 0 ? (
                    <Table.Cell positive textAlign="center">
                      {" "}
                      +{d_5d}%
                    </Table.Cell>
                  ) : d_5d === 0 ? (
                    <Table.Cell active textAlign="center">
                      {" "}
                      {d_5d}%
                    </Table.Cell>
                  ) : (
                    <Table.Cell negative textAlign="center">
                      {" "}
                      {d_5d}%{" "}
                    </Table.Cell>
                  )}
                  {d_10d === "-" ? (
                    <Table.Cell textAlign="center"> - </Table.Cell>
                  ) : d_10d > 0 ? (
                    <Table.Cell positive textAlign="center">
                      {" "}
                      +{d_10d}%
                    </Table.Cell>
                  ) : d_10d === 0 ? (
                    <Table.Cell active textAlign="center">
                      {" "}
                      {d_10d}%
                    </Table.Cell>
                  ) : (
                    <Table.Cell negative textAlign="center">
                      {" "}
                      {d_10d}%{" "}
                    </Table.Cell>
                  )}
                  {d_20d === "-" ? (
                    <Table.Cell textAlign="center"> - </Table.Cell>
                  ) : d_20d > 0 ? (
                    <Table.Cell positive textAlign="center">
                      {" "}
                      +{d_20d}%
                    </Table.Cell>
                  ) : d_20d === 0 ? (
                    <Table.Cell active textAlign="center">
                      {" "}
                      {d_20d}%
                    </Table.Cell>
                  ) : (
                    <Table.Cell negative textAlign="center">
                      {" "}
                      {d_20d}%{" "}
                    </Table.Cell>
                  )}
                </Table.Row>
              </>
            )
          )}
        </Table.Body>
      </Table>
    </div>
  );
}

function Row(input) {
  let code = input.code_;
  let filterTs = input.filterTs_;
  // console.log(input)
  const state = store.getState();
  const { data } = state.resultTable;

  let newData = [];
  newData = data.filter((d) => {
    return d.code.toLowerCase().includes(code) && d.t_date_ts >= filterTs;
  });
  newData = _.sortBy(newData, ["t_date_ts"]).reverse();

  return (
    <Table unstackable compact>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell textAlign="center">回購日期</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">價格範圍</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">平均價格</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">回購股數</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">回購金額</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">貨幣</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {newData.map(
          (
            {
              code,
              n_zh,
              n_en,
              nShare,
              p_range,
              p_avg,
              total_paid,
              cur,
              t_date_ts,
            },
            index
          ) => (
            <>
              <Table.Row key={code}>
                <Table.Cell textAlign="center">
                  {p_range !== 0 ? "$" + p_range : "-"}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {p_avg !== 0 ? "$" + Math.round(p_avg * 1000) / 1000 : "-"}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {nShare !== 0 ? nShare.toLocaleString() : "-"}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {total_paid !== 0 ? "$" + total_paid.toLocaleString() : "-"}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {cur !== 0 ? cur : "-"}
                </Table.Cell>
              </Table.Row>
            </>
          )
        )}
      </Table.Body>
    </Table>
  );
}

class PaginationContainer extends React.Component {
  componentDidMount() {
    store.subscribe(() => this.forceUpdate());
  }

  handlePaginationChange = (e, { activePage }) => {
    store.dispatch({
      type: "SET_ACTIVE_PAGE",
      text: activePage,
    });
  };

  render() {
    const pos = isMobile ? "center" : "right";
    const state = store.getState();
    const { activePage } = state.resultTable;

    return (
      <div style={{ float: pos }}>
        <Pagination
          boundaryRange={0}
          activePage={activePage}
          size={isMobile ? "small" : "big"}
          siblingRange={1}
          totalPages={this.props.totalPage}
          onPageChange={this.handlePaginationChange}
        />
      </div>
    );
  }
}

class FilterInput extends React.Component {
  componentDidMount() {
    store.subscribe(() => this.forceUpdate());
  }

  state = {
    value: store.getState().resultTable.filterText.value,
  };

  onChangeFilter = (e) => {
    this.setState({
      value: e.target.value,
    });

    store.dispatch({
      type: "Filter_MESSAGE",
      text: { value: e.target.value },
    });
  };

  onClear = (e) => {
    this.setState({
      value: "",
    });

    store.dispatch({
      type: "Filter_MESSAGE",
      text: { value: e.target.value },
    });
  };

  render() {
    return (
      <div>
        <Input
          style={{ width: "100%", maxWidth: "800px" }}
          icon={
            <Icon name="close" inverted circular link onClick={this.onClear} />
          }
          onChange={this.onChangeFilter}
          // value={this.props.value.value}
          value={this.state.value}
          type="text"
          placeholder="輸入股份代號或名稱"
        />
      </div>
    );
  }
}

function valueLabelFormat(value) {
  return value + "%";
}

function SliderFilter({ range }) {
  const [value, setValue] = useState(range);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeCommitted = (event, value) => {
    store.dispatch({
      type: "SET_SLIDER_FILTER",
      text: { value },
    });
  };

  return (
    <form>
      <Slider
        valueLabelDisplay="auto"
        value={value}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
        valueLabelFormat={valueLabelFormat}
        // marks={[
        //   { value: 0, label: "0%" },
        //   { value: 100, label: "100%" },
        // ]}
      />
    </form>
  );
}

class DropdownExampleControlled extends Component {
  state = {};

  handleChange = (e, { value }) => {
    this.setState({ value });
    //   store.dispatch({
    //     type: "SET_ACTIVE_PAGE",
    //     text: "1",
    //   });
    store.dispatch({
      type: "SET_IND_FILTER",
      state: value,
    });
  };

  render() {
    return (
      <div>
        <Dropdown
          onChange={this.handleChange}
          placeholder="行業"
          clearable
          scrolling
          selection
          value={this.props.value}
          options={this.props.options}
        />
      </div>
    );
  }
}

const data = {
  data: {
    1024: {
      d_1d: 0.11,
      d_3d: 0.22,
      d_5d: 0.45,
      d_10d: 0.99,
      d_20d: 1.38,
      L_5d: 3.12,
      H_5d: 3.41,
      L_1m: 2.08,
      H_1m: 3.41,
      L_3m: 0.29,
      H_3m: 3.41,
      L_52w: 0.11,
      H_52w: 3.41,
      cur: 3.41,
      id: "1024",
    },
    1028: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 6.76,
      H_5d: 6.76,
      L_1m: 6.76,
      H_1m: 6.76,
      L_3m: 6.76,
      H_3m: 6.76,
      L_52w: 6.76,
      H_52w: 6.93,
      cur: 6.76,
      id: "1028",
    },
    1030: {
      d_1d: 0,
      d_3d: 0.03,
      d_5d: 0.03,
      d_10d: 0.04,
      d_20d: 0.03,
      L_5d: 6.17,
      H_5d: 6.2,
      L_1m: 6.16,
      H_1m: 6.24,
      L_3m: 5.61,
      H_3m: 6.24,
      L_52w: 4.68,
      H_52w: 6.24,
      cur: 6.2,
      id: "1030",
    },
    1031: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0,
      H_5d: 0,
      L_1m: 0,
      H_1m: 0,
      L_3m: 0,
      H_3m: 0,
      L_52w: 0,
      H_52w: 0,
      cur: 0,
      id: "1031",
    },
    1033: {
      d_1d: -0.01,
      d_3d: 0.08,
      d_5d: 0.14,
      d_10d: 0.43,
      d_20d: 0.76,
      L_5d: 7.7,
      H_5d: 7.83,
      L_1m: 7.06,
      H_1m: 7.83,
      L_3m: 7.02,
      H_3m: 7.83,
      L_52w: 6.8,
      H_52w: 7.91,
      cur: 7.82,
      id: "1033",
    },
    1038: {
      d_1d: -0.01,
      d_3d: -0.02,
      d_5d: -0.06,
      d_10d: -0.07,
      d_20d: -0.08,
      L_5d: 0.26,
      H_5d: 0.3,
      L_1m: 0.26,
      H_1m: 0.33,
      L_3m: 0.26,
      H_3m: 0.37,
      L_52w: 0.26,
      H_52w: 0.4,
      cur: 0.26,
      id: "1038",
    },
    1044: {
      d_1d: -0.02,
      d_3d: 0,
      d_5d: 0.01,
      d_10d: 0,
      d_20d: 0.02,
      L_5d: 1.65,
      H_5d: 1.67,
      L_1m: 1.62,
      H_1m: 1.67,
      L_3m: 1.52,
      H_3m: 1.67,
      L_52w: 1.24,
      H_52w: 1.71,
      cur: 1.65,
      id: "1044",
    },
    1052: {
      d_1d: -0.04,
      d_3d: -0.07,
      d_5d: -0.09,
      d_10d: -0.14,
      d_20d: -0.12,
      L_5d: 3.78,
      H_5d: 3.85,
      L_1m: 3.78,
      H_1m: 3.94,
      L_3m: 3.78,
      H_3m: 4.23,
      L_52w: 3.78,
      H_52w: 4.95,
      cur: 3.78,
      id: "1052",
    },
    1053: {
      d_1d: 0.01,
      d_3d: -0.05,
      d_5d: 0.1,
      d_10d: 0.12,
      d_20d: 0.52,
      L_5d: 11.07,
      H_5d: 11.2,
      L_1m: 10.7,
      H_1m: 11.3,
      L_3m: 10.53,
      H_3m: 14.74,
      L_52w: 10.53,
      H_52w: 19.73,
      cur: 11.12,
      id: "1053",
    },
    1055: {
      d_1d: 0.12,
      d_3d: 0.23,
      d_5d: 0.29,
      d_10d: 0.33,
      d_20d: 0.48,
      L_5d: 7.51,
      H_5d: 7.79,
      L_1m: 7.31,
      H_1m: 7.79,
      L_3m: 6.51,
      H_3m: 7.79,
      L_52w: 4.23,
      H_52w: 7.79,
      cur: 7.79,
      id: "1055",
    },
    1057: {
      d_1d: -0.84,
      d_3d: -0.28,
      d_5d: 0.08,
      d_10d: -1.64,
      d_20d: -1.61,
      L_5d: 27.74,
      H_5d: 28.81,
      L_1m: 27.74,
      H_1m: 30.53,
      L_3m: 27.74,
      H_3m: 30.91,
      L_52w: 24.66,
      H_52w: 36.24,
      cur: 27.97,
      id: "1057",
    },
    1060: {
      d_1d: 0.01,
      d_3d: 0,
      d_5d: 0.01,
      d_10d: 0.08,
      d_20d: -0.01,
      L_5d: 15.45,
      H_5d: 15.48,
      L_1m: 15.38,
      H_1m: 15.5,
      L_3m: 15.38,
      H_3m: 15.71,
      L_52w: 12.98,
      H_52w: 15.71,
      cur: 15.48,
      id: "1060",
    },
    1065: {
      d_1d: 0.01,
      d_3d: 0.07,
      d_5d: 0.05,
      d_10d: 0.03,
      d_20d: 1.09,
      L_5d: 47.05,
      H_5d: 47.12,
      L_1m: 46.14,
      H_1m: 47.13,
      L_3m: 45.4,
      H_3m: 47.13,
      L_52w: 36.01,
      H_52w: 47.13,
      cur: 47.12,
      id: "1065",
    },
    1066: {
      d_1d: -0.01,
      d_3d: -0.04,
      d_5d: -0.04,
      d_10d: -0.33,
      d_20d: -0.78,
      L_5d: 11.47,
      H_5d: 11.51,
      L_1m: 11.47,
      H_1m: 12.17,
      L_3m: 11.47,
      H_3m: 13.08,
      L_52w: 11.47,
      H_52w: 17.08,
      cur: 11.47,
      id: "1066",
    },
    1068: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.84,
      H_5d: 0.84,
      L_1m: 0.84,
      H_1m: 0.84,
      L_3m: 0.84,
      H_3m: 0.85,
      L_52w: 0.84,
      H_52w: 0.99,
      cur: 0.84,
      id: "1068",
    },
    1070: {
      d_1d: 0.04,
      d_3d: 0.07,
      d_5d: -0.04,
      d_10d: -0.05,
      d_20d: -0.07,
      L_5d: 4.24,
      H_5d: 4.31,
      L_1m: 4.24,
      H_1m: 4.38,
      L_3m: 4.04,
      H_3m: 4.43,
      L_52w: 3.49,
      H_52w: 5.15,
      cur: 4.31,
      id: "1070",
    },
    1071: {
      d_1d: -0.28,
      d_3d: -0.39,
      d_5d: -0.32,
      d_10d: -0.23,
      d_20d: -3.6,
      L_5d: 44.93,
      H_5d: 45.33,
      L_1m: 44.93,
      H_1m: 48.69,
      L_3m: 41.45,
      H_3m: 49.4,
      L_52w: 36.11,
      H_52w: 49.4,
      cur: 44.93,
      id: "1071",
    },
    1072: {
      d_1d: 0,
      d_3d: -0.4,
      d_5d: -0.26,
      d_10d: 0.46,
      d_20d: 2.82,
      L_5d: 27.03,
      H_5d: 27.43,
      L_1m: 24.63,
      H_1m: 27.43,
      L_3m: 23.09,
      H_3m: 27.43,
      L_52w: 21.08,
      H_52w: 27.43,
      cur: 27.03,
      id: "1072",
    },
    1076: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.27,
      H_5d: 0.27,
      L_1m: 0.27,
      H_1m: 0.27,
      L_3m: 0.27,
      H_3m: 5.4,
      L_52w: 0.27,
      H_52w: 5.4,
      cur: 0.27,
      id: "1076",
    },
    1083: {
      d_1d: -0.45,
      d_3d: -0.57,
      d_5d: -0.61,
      d_10d: -0.85,
      d_20d: -0.92,
      L_5d: 2,
      H_5d: 2.6,
      L_1m: 2,
      H_1m: 3.1,
      L_3m: 1.6,
      H_3m: 3.1,
      L_52w: 0.57,
      H_52w: 3.1,
      cur: 2,
      id: "1083",
    },
    1086: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 1.09,
      H_5d: 1.09,
      L_1m: 1.09,
      H_1m: 1.09,
      L_3m: 1.09,
      H_3m: 1.09,
      L_52w: 1.09,
      H_52w: 1.27,
      cur: 1.09,
      id: "1086",
    },
    1088: {
      d_1d: -0.02,
      d_3d: -0.02,
      d_5d: 0.04,
      d_10d: 0.26,
      d_20d: -0.15,
      L_5d: 30.18,
      H_5d: 30.27,
      L_1m: 29.8,
      H_1m: 30.42,
      L_3m: 28.97,
      H_3m: 31.27,
      L_52w: 27.59,
      H_52w: 31.27,
      cur: 30.22,
      id: "1088",
    },
    1093: {
      d_1d: 0.02,
      d_3d: 0.13,
      d_5d: 0.1,
      d_10d: 0.16,
      d_20d: -0.02,
      L_5d: 4.09,
      H_5d: 4.24,
      L_1m: 4.08,
      H_1m: 4.27,
      L_3m: 4.08,
      H_3m: 5.18,
      L_52w: 4.08,
      H_52w: 6.34,
      cur: 4.24,
      id: "1093",
    },
    1097: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0,
      H_5d: 0,
      L_1m: 0,
      H_1m: 0,
      L_3m: 0,
      H_3m: 0,
      L_52w: 0,
      H_52w: 0,
      cur: 0,
      id: "1097",
    },
    1098: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: -0.01,
      L_5d: 0.55,
      H_5d: 0.55,
      L_1m: 0.55,
      H_1m: 0.56,
      L_3m: 0.54,
      H_3m: 0.59,
      L_52w: 0.54,
      H_52w: 1.39,
      cur: 0.55,
      id: "1098",
    },
    1099: {
      d_1d: 0.03,
      d_3d: 0.19,
      d_5d: 0.17,
      d_10d: 0.17,
      d_20d: 0.36,
      L_5d: 2.85,
      H_5d: 3.04,
      L_1m: 2.74,
      H_1m: 3.04,
      L_3m: 2.51,
      H_3m: 3.04,
      L_52w: 2.51,
      H_52w: 6.84,
      cur: 3.04,
      id: "1099",
    },
    1108: {
      d_1d: 0.16,
      d_3d: 0.18,
      d_5d: 0.97,
      d_10d: -0.09,
      d_20d: -0.38,
      L_5d: 29.88,
      H_5d: 30.9,
      L_1m: 29.88,
      H_1m: 31.05,
      L_3m: 26.43,
      H_3m: 31.25,
      L_52w: 26.43,
      H_52w: 36.81,
      cur: 30.87,
      id: "1108",
    },
    1109: {
      d_1d: -0.03,
      d_3d: -0.11,
      d_5d: -0.15,
      d_10d: -0.25,
      d_20d: -0.15,
      L_5d: 2.33,
      H_5d: 2.46,
      L_1m: 2.33,
      H_1m: 2.64,
      L_3m: 2.24,
      H_3m: 2.65,
      L_52w: 1.77,
      H_52w: 2.66,
      cur: 2.33,
      id: "1109",
    },
    1112: {
      d_1d: 0.01,
      d_3d: -0.01,
      d_5d: 0.02,
      d_10d: 0.08,
      d_20d: 0.07,
      L_5d: 3.59,
      H_5d: 3.61,
      L_1m: 3.5,
      H_1m: 3.61,
      L_3m: 3.42,
      H_3m: 3.61,
      L_52w: 3.42,
      H_52w: 5.71,
      cur: 3.6,
      id: "1112",
    },
    1113: {
      d_1d: 0,
      d_3d: 0.01,
      d_5d: 0.01,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.26,
      H_5d: 0.27,
      L_1m: 0.26,
      H_1m: 0.27,
      L_3m: 0.26,
      H_3m: 0.28,
      L_52w: 0.26,
      H_52w: 0.51,
      cur: 0.27,
      id: "1113",
    },
    1114: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 1.96,
      H_5d: 1.96,
      L_1m: 1.96,
      H_1m: 1.96,
      L_3m: 1.96,
      H_3m: 1.96,
      L_52w: 1.67,
      H_52w: 1.99,
      cur: 1.96,
      id: "1114",
    },
    1115: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 6.17,
      H_5d: 6.17,
      L_1m: 6.17,
      H_1m: 6.17,
      L_3m: 6.17,
      H_3m: 6.21,
      L_52w: 6.17,
      H_52w: 6.26,
      cur: 6.17,
      id: "1115",
    },
    1117: {
      d_1d: -0.2,
      d_3d: -0.43,
      d_5d: -1.68,
      d_10d: -1.85,
      d_20d: -1.14,
      L_5d: 8.19,
      H_5d: 9.66,
      L_1m: 8.19,
      H_1m: 10.04,
      L_3m: 8.19,
      H_3m: 10.24,
      L_52w: 4.35,
      H_52w: 16.46,
      cur: 8.19,
      id: "1117",
    },
    1119: {
      d_1d: -0.07,
      d_3d: -0.13,
      d_5d: -0.19,
      d_10d: -0.37,
      d_20d: -1.38,
      L_5d: 9.47,
      H_5d: 9.62,
      L_1m: 9.47,
      H_1m: 10.85,
      L_3m: 9.47,
      H_3m: 13.23,
      L_52w: 3.52,
      H_52w: 14.43,
      cur: 9.47,
      id: "1119",
    },
    1128: {
      d_1d: 0.04,
      d_3d: 0.09,
      d_5d: 0.16,
      d_10d: 0.16,
      d_20d: 0.15,
      L_5d: 0.79,
      H_5d: 0.89,
      L_1m: 0.73,
      H_1m: 0.89,
      L_3m: 0.53,
      H_3m: 0.89,
      L_52w: 0.25,
      H_52w: 0.89,
      cur: 0.89,
      id: "1128",
    },
    1131: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 9.15,
      H_5d: 9.15,
      L_1m: 9.15,
      H_1m: 9.15,
      L_3m: 9.15,
      H_3m: 9.15,
      L_52w: 9.15,
      H_52w: 9.15,
      cur: 9.15,
      id: "1131",
    },
    1137: {
      d_1d: 0.03,
      d_3d: 0.09,
      d_5d: 0.1,
      d_10d: 0.1,
      d_20d: 0.12,
      L_5d: 0.16,
      H_5d: 0.25,
      L_1m: 0.13,
      H_1m: 0.25,
      L_3m: 0,
      H_3m: 0.25,
      L_52w: 0,
      H_52w: 0.25,
      cur: 0.25,
      id: "1137",
    },
    1138: {
      d_1d: -0.02,
      d_3d: 0.59,
      d_5d: 0.59,
      d_10d: 0.94,
      d_20d: 1.69,
      L_5d: 17.82,
      H_5d: 18.45,
      L_1m: 16.87,
      H_1m: 18.45,
      L_3m: 15.15,
      H_3m: 18.45,
      L_52w: 14.25,
      H_52w: 18.45,
      cur: 18.43,
      id: "1138",
    },
    1140: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: -0.36,
      d_20d: -0.36,
      L_5d: 2.15,
      H_5d: 2.15,
      L_1m: 2.15,
      H_1m: 2.51,
      L_3m: 2.15,
      H_3m: 2.53,
      L_52w: 2.15,
      H_52w: 3.36,
      cur: 2.15,
      id: "1140",
    },
    1141: {
      d_1d: -0.01,
      d_3d: 0,
      d_5d: 0.03,
      d_10d: 0.07,
      d_20d: 0.26,
      L_5d: 8.3,
      H_5d: 8.33,
      L_1m: 8.1,
      H_1m: 8.33,
      L_3m: 7.56,
      H_3m: 8.33,
      L_52w: 1.92,
      H_52w: 8.33,
      cur: 8.32,
      id: "1141",
    },
    1157: {
      d_1d: 0.13,
      d_3d: 0.15,
      d_5d: 0.2,
      d_10d: 0.52,
      d_20d: 0.6,
      L_5d: 3.03,
      H_5d: 3.22,
      L_1m: 2.63,
      H_1m: 3.22,
      L_3m: 2.18,
      H_3m: 3.22,
      L_52w: 1.49,
      H_52w: 5.5,
      cur: 3.22,
      id: "1157",
    },
    1165: {
      d_1d: 0,
      d_3d: 0,
      d_5d: -0.01,
      d_10d: -0.01,
      d_20d: -0.05,
      L_5d: 6.11,
      H_5d: 6.11,
      L_1m: 6.11,
      H_1m: 6.16,
      L_3m: 6.11,
      H_3m: 6.17,
      L_52w: 6.11,
      H_52w: 6.42,
      cur: 6.11,
      id: "1165",
    },
    1167: {
      d_1d: 0.01,
      d_3d: 0.01,
      d_5d: 0.02,
      d_10d: 0.07,
      d_20d: 0.21,
      L_5d: 0.84,
      H_5d: 0.86,
      L_1m: 0.66,
      H_1m: 0.86,
      L_3m: 0.62,
      H_3m: 0.86,
      L_52w: 0,
      H_52w: 0.86,
      cur: 0.86,
      id: "1167",
    },
    1171: {
      d_1d: 0.61,
      d_3d: 0.52,
      d_5d: 0.7,
      d_10d: 1.12,
      d_20d: 2.29,
      L_5d: 30.31,
      H_5d: 30.92,
      L_1m: 29.02,
      H_1m: 30.92,
      L_3m: 22.83,
      H_3m: 30.92,
      L_52w: 15.23,
      H_52w: 30.92,
      cur: 30.92,
      id: "1171",
    },
    1176: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0.02,
      d_10d: 0.04,
      d_20d: -0.04,
      L_5d: 4.9,
      H_5d: 4.92,
      L_1m: 4.87,
      H_1m: 4.96,
      L_3m: 4.87,
      H_3m: 5.17,
      L_52w: 3.23,
      H_52w: 5.18,
      cur: 4.91,
      id: "1176",
    },
    1177: {
      d_1d: 0,
      d_3d: 0.01,
      d_5d: 0.01,
      d_10d: 0,
      d_20d: 0,
      L_5d: 3.22,
      H_5d: 3.24,
      L_1m: 3.21,
      H_1m: 3.24,
      L_3m: 3.16,
      H_3m: 3.24,
      L_52w: 3.16,
      H_52w: 7.33,
      cur: 3.24,
      id: "1177",
    },
    1186: {
      d_1d: -0.12,
      d_3d: 0.01,
      d_5d: 0.12,
      d_10d: 0.26,
      d_20d: 0.47,
      L_5d: 27.46,
      H_5d: 27.65,
      L_1m: 27.07,
      H_1m: 27.65,
      L_3m: 26.84,
      H_3m: 27.65,
      L_52w: 8.01,
      H_52w: 27.67,
      cur: 27.53,
      id: "1186",
    },
    1188: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.49,
      H_5d: 0.49,
      L_1m: 0.49,
      H_1m: 0.49,
      L_3m: 0.49,
      H_3m: 0.49,
      L_52w: 0.49,
      H_52w: 0.51,
      cur: 0.49,
      id: "1188",
    },
    1193: {
      d_1d: 0.01,
      d_3d: 0.03,
      d_5d: 0.05,
      d_10d: 0.07,
      d_20d: 0.11,
      L_5d: 0.26,
      H_5d: 0.3,
      L_1m: 0.19,
      H_1m: 0.3,
      L_3m: 0.16,
      H_3m: 0.3,
      L_52w: 0.16,
      H_52w: 0.41,
      cur: 0.3,
      id: "1193",
    },
    1196: {
      d_1d: 0.03,
      d_3d: 0.07,
      d_5d: 0.14,
      d_10d: 0.28,
      d_20d: 0.02,
      L_5d: 8.38,
      H_5d: 8.48,
      L_1m: 8.13,
      H_1m: 8.48,
      L_3m: 8.13,
      H_3m: 8.57,
      L_52w: 7.99,
      H_52w: 8.67,
      cur: 8.48,
      id: "1196",
    },
    1199: {
      d_1d: 0,
      d_3d: 0,
      d_5d: -0.01,
      d_10d: -0.03,
      d_20d: -0.03,
      L_5d: 0.24,
      H_5d: 0.25,
      L_1m: 0.24,
      H_1m: 0.28,
      L_3m: 0.24,
      H_3m: 0.31,
      L_52w: 0.24,
      H_52w: 0.62,
      cur: 0.24,
      id: "1199",
    },
    1205: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: -0.01,
      d_20d: -0.01,
      L_5d: 1.5,
      H_5d: 1.5,
      L_1m: 1.5,
      H_1m: 1.51,
      L_3m: 1.5,
      H_3m: 1.64,
      L_52w: 1.5,
      H_52w: 2.04,
      cur: 1.5,
      id: "1205",
    },
    1208: {
      d_1d: 0,
      d_3d: 0.04,
      d_5d: 0.06,
      d_10d: 0.12,
      d_20d: 0.15,
      L_5d: 4.31,
      H_5d: 4.37,
      L_1m: 4.23,
      H_1m: 4.37,
      L_3m: 4.12,
      H_3m: 4.51,
      L_52w: 3.64,
      H_52w: 5,
      cur: 4.36,
      id: "1208",
    },
    1209: {
      d_1d: 0,
      d_3d: -0.02,
      d_5d: -0.02,
      d_10d: 0.02,
      d_20d: 0.02,
      L_5d: 2.77,
      H_5d: 2.8,
      L_1m: 2.69,
      H_1m: 2.8,
      L_3m: 2.58,
      H_3m: 2.81,
      L_52w: 0,
      H_52w: 2.81,
      cur: 2.77,
      id: "1209",
    },
    1211: {
      d_1d: -0.08,
      d_3d: -0.22,
      d_5d: -0.35,
      d_10d: -0.38,
      d_20d: -0.92,
      L_5d: 5.6,
      H_5d: 5.84,
      L_1m: 5.6,
      H_1m: 6.57,
      L_3m: 5.6,
      H_3m: 6.82,
      L_52w: 2.31,
      H_52w: 7.23,
      cur: 5.6,
      id: "1211",
    },
    1212: {
      d_1d: 0,
      d_3d: 0.02,
      d_5d: 0.03,
      d_10d: 0.04,
      d_20d: 0.05,
      L_5d: 0.33,
      H_5d: 0.36,
      L_1m: 0.31,
      H_1m: 0.36,
      L_3m: 0.31,
      H_3m: 0.36,
      L_52w: 0.25,
      H_52w: 0.5,
      cur: 0.36,
      id: "1212",
    },
    1230: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 1.49,
      H_5d: 1.49,
      L_1m: 1.49,
      H_1m: 1.49,
      L_3m: 1.49,
      H_3m: 1.5,
      L_52w: 1.49,
      H_52w: 1.64,
      cur: 1.49,
      id: "1230",
    },
    1233: {
      d_1d: -0.04,
      d_3d: -0.09,
      d_5d: -0.08,
      d_10d: -0.1,
      d_20d: -0.22,
      L_5d: 3.35,
      H_5d: 3.48,
      L_1m: 3.35,
      H_1m: 3.68,
      L_3m: 2.93,
      H_3m: 3.68,
      L_52w: 2.84,
      H_52w: 4.52,
      cur: 3.35,
      id: "1233",
    },
    1234: {
      d_1d: 0.01,
      d_3d: 0.06,
      d_5d: 0.06,
      d_10d: 0.1,
      d_20d: 0.15,
      L_5d: 6.12,
      H_5d: 6.18,
      L_1m: 6.02,
      H_1m: 6.18,
      L_3m: 5.46,
      H_3m: 6.18,
      L_52w: 5.26,
      H_52w: 6.18,
      cur: 6.18,
      id: "1234",
    },
    1238: {
      d_1d: 0,
      d_3d: 0.01,
      d_5d: 0.01,
      d_10d: 0,
      d_20d: 0,
      L_5d: 1.51,
      H_5d: 1.52,
      L_1m: 1.51,
      H_1m: 1.56,
      L_3m: 1.47,
      H_3m: 1.56,
      L_52w: 1.42,
      H_52w: 3.01,
      cur: 1.52,
      id: "1238",
    },
    1250: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 1.04,
      H_5d: 1.04,
      L_1m: 1.04,
      H_1m: 1.04,
      L_3m: 1.04,
      H_3m: 1.06,
      L_52w: 1.04,
      H_52w: 2.52,
      cur: 1.04,
      id: "1250",
    },
    1253: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.19,
      H_5d: 0.19,
      L_1m: 0.19,
      H_1m: 0.19,
      L_3m: 0.19,
      H_3m: 0.19,
      L_52w: 0.19,
      H_52w: 0.19,
      cur: 0.19,
      id: "1253",
    },
    1257: {
      d_1d: -0.03,
      d_3d: -0.01,
      d_5d: -0.02,
      d_10d: 0,
      d_20d: 0.15,
      L_5d: 10.02,
      H_5d: 10.06,
      L_1m: 9.88,
      H_1m: 10.07,
      L_3m: 9.27,
      H_3m: 10.07,
      L_52w: 7.62,
      H_52w: 10.07,
      cur: 10.03,
      id: "1257",
    },
    1268: {
      d_1d: -0.01,
      d_3d: -0.04,
      d_5d: -0.01,
      d_10d: 0.12,
      d_20d: 0.15,
      L_5d: 5.73,
      H_5d: 5.78,
      L_1m: 5.55,
      H_1m: 5.78,
      L_3m: 5.5,
      H_3m: 5.78,
      L_52w: 3.33,
      H_52w: 5.86,
      cur: 5.73,
      id: "1268",
    },
    1269: {
      d_1d: -0.01,
      d_3d: -0.02,
      d_5d: -4.59,
      d_10d: -4.74,
      d_20d: -5.02,
      L_5d: 25.9,
      H_5d: 25.92,
      L_1m: 25.9,
      H_1m: 30.84,
      L_3m: 25.9,
      H_3m: 32.07,
      L_52w: 25.9,
      H_52w: 162.59,
      cur: 25.9,
      id: "1269",
    },
    1282: {
      d_1d: 0,
      d_3d: 0.01,
      d_5d: 0.01,
      d_10d: 0.02,
      d_20d: 0.03,
      L_5d: 15.38,
      H_5d: 15.39,
      L_1m: 15.36,
      H_1m: 15.39,
      L_3m: 15.35,
      H_3m: 15.48,
      L_52w: 13.68,
      H_52w: 15.48,
      cur: 15.39,
      id: "1282",
    },
    1286: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.04,
      H_5d: 0.04,
      L_1m: 0.04,
      H_1m: 0.04,
      L_3m: 0.04,
      H_3m: 0.04,
      L_52w: 0.04,
      H_52w: 0.06,
      cur: 0.04,
      id: "1286",
    },
    1288: {
      d_1d: -0.02,
      d_3d: -0.09,
      d_5d: -0.18,
      d_10d: -0.26,
      d_20d: -0.88,
      L_5d: 20.71,
      H_5d: 20.85,
      L_1m: 20.71,
      H_1m: 21.52,
      L_3m: 20.71,
      H_3m: 22.77,
      L_52w: 20.71,
      H_52w: 27.61,
      cur: 20.71,
      id: "1288",
    },
    1293: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: -0.01,
      d_20d: -0.01,
      L_5d: 0.72,
      H_5d: 0.72,
      L_1m: 0.72,
      H_1m: 0.73,
      L_3m: 0.72,
      H_3m: 0.73,
      L_52w: 0.72,
      H_52w: 4.53,
      cur: 0.72,
      id: "1293",
    },
    1299: {
      d_1d: 0.01,
      d_3d: 0.01,
      d_5d: 0.02,
      d_10d: 0.06,
      d_20d: 0.11,
      L_5d: 0.71,
      H_5d: 0.72,
      L_1m: 0.61,
      H_1m: 0.72,
      L_3m: 0.59,
      H_3m: 0.72,
      L_52w: 0.59,
      H_52w: 1.1,
      cur: 0.72,
      id: "1299",
    },
    1302: {
      d_1d: 0.03,
      d_3d: 0.09,
      d_5d: 0.21,
      d_10d: 0.34,
      d_20d: 0.39,
      L_5d: 20.33,
      H_5d: 20.44,
      L_1m: 19.98,
      H_1m: 20.44,
      L_3m: 19.83,
      H_3m: 20.44,
      L_52w: 15.9,
      H_52w: 20.44,
      cur: 20.44,
      id: "1302",
    },
    1308: {
      d_1d: 0.08,
      d_3d: 0.05,
      d_5d: -0.01,
      d_10d: -0.08,
      d_20d: -0.1,
      L_5d: 1.17,
      H_5d: 1.25,
      L_1m: 1.17,
      H_1m: 1.36,
      L_3m: 1.17,
      H_3m: 2.34,
      L_52w: 1.17,
      H_52w: 3.66,
      cur: 1.25,
      id: "1308",
    },
    1310: {
      d_1d: 0,
      d_3d: -0.01,
      d_5d: 0,
      d_10d: 0,
      d_20d: -0.03,
      L_5d: 0.14,
      H_5d: 0.15,
      L_1m: 0.14,
      H_1m: 0.15,
      L_3m: 0.14,
      H_3m: 0.18,
      L_52w: 0.07,
      H_52w: 0.27,
      cur: 0.14,
      id: "1310",
    },
    1313: {
      d_1d: 0,
      d_3d: 0.01,
      d_5d: 0,
      d_10d: 0,
      d_20d: -0.06,
      L_5d: 1.04,
      H_5d: 1.05,
      L_1m: 1.04,
      H_1m: 1.1,
      L_3m: 1.04,
      H_3m: 2.27,
      L_52w: 0.54,
      H_52w: 2.27,
      cur: 1.05,
      id: "1313",
    },
    1315: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 10.65,
      H_5d: 10.65,
      L_1m: 10.65,
      H_1m: 10.65,
      L_3m: 10.65,
      H_3m: 10.65,
      L_52w: 10.65,
      H_52w: 13.32,
      cur: 10.65,
      id: "1315",
    },
    1316: {
      d_1d: 0.03,
      d_3d: 0.37,
      d_5d: 0.56,
      d_10d: 0.87,
      d_20d: 1.1,
      L_5d: 6.27,
      H_5d: 6.73,
      L_1m: 5.64,
      H_1m: 6.73,
      L_3m: 5.35,
      H_3m: 6.73,
      L_52w: 1.96,
      H_52w: 6.73,
      cur: 6.73,
      id: "1316",
    },
    1317: {
      d_1d: 0,
      d_3d: 3.29,
      d_5d: 3.87,
      d_10d: 4.67,
      d_20d: 4.75,
      L_5d: 20.93,
      H_5d: 24.52,
      L_1m: 19.74,
      H_1m: 24.52,
      L_3m: 19.47,
      H_3m: 24.52,
      L_52w: 15.18,
      H_52w: 24.52,
      cur: 24.52,
      id: "1317",
    },
    1330: {
      d_1d: -0.3,
      d_3d: -0.3,
      d_5d: -0.49,
      d_10d: -0.24,
      d_20d: 1.38,
      L_5d: 43.69,
      H_5d: 44.08,
      L_1m: 42.39,
      H_1m: 44.51,
      L_3m: 42.15,
      H_3m: 44.51,
      L_52w: 38.41,
      H_52w: 44.52,
      cur: 43.69,
      id: "1330",
    },
    1333: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 6.59,
      H_5d: 6.59,
      L_1m: 6.59,
      H_1m: 6.59,
      L_3m: 6.59,
      H_3m: 6.59,
      L_52w: 6.42,
      H_52w: 6.93,
      cur: 6.59,
      id: "1333",
    },
    1336: {
      d_1d: 0.05,
      d_3d: 0.23,
      d_5d: 0.27,
      d_10d: 0.01,
      d_20d: -0.08,
      L_5d: 27.94,
      H_5d: 28.21,
      L_1m: 27.94,
      H_1m: 28.32,
      L_3m: 27.92,
      H_3m: 28.9,
      L_52w: 27.92,
      H_52w: 32.94,
      cur: 28.21,
      id: "1336",
    },
    1339: {
      d_1d: 0.06,
      d_3d: 0.28,
      d_5d: 0.34,
      d_10d: 0.36,
      d_20d: 0.64,
      L_5d: 20.42,
      H_5d: 20.74,
      L_1m: 20.16,
      H_1m: 20.74,
      L_3m: 19.97,
      H_3m: 21.98,
      L_52w: 19.97,
      H_52w: 24.05,
      cur: 20.74,
      id: "1339",
    },
    1347: {
      d_1d: 0.28,
      d_3d: 1.29,
      d_5d: 1.54,
      d_10d: 1.51,
      d_20d: 2.2,
      L_5d: 9.33,
      H_5d: 10.74,
      L_1m: 8.6,
      H_1m: 10.74,
      L_3m: 8.43,
      H_3m: 10.74,
      L_52w: 7.61,
      H_52w: 13.42,
      cur: 10.74,
      id: "1347",
    },
    1349: {
      d_1d: 0.12,
      d_3d: 0.23,
      d_5d: 0.31,
      d_10d: 0.62,
      d_20d: 0.65,
      L_5d: 10.45,
      H_5d: 10.75,
      L_1m: 10.08,
      H_1m: 10.75,
      L_3m: 8.53,
      H_3m: 10.75,
      L_52w: 0,
      H_52w: 10.75,
      cur: 10.75,
      id: "1349",
    },
    1357: {
      d_1d: 0.01,
      d_3d: 0.06,
      d_5d: 0.07,
      d_10d: 0.09,
      d_20d: 0.18,
      L_5d: 9.27,
      H_5d: 9.34,
      L_1m: 9.23,
      H_1m: 9.43,
      L_3m: 9.08,
      H_3m: 9.43,
      L_52w: 7.68,
      H_52w: 9.53,
      cur: 9.34,
      id: "1357",
    },
    1359: {
      d_1d: -0.02,
      d_3d: 0.06,
      d_5d: 0.14,
      d_10d: 0.23,
      d_20d: 0.19,
      L_5d: 18.92,
      H_5d: 19.05,
      L_1m: 18.79,
      H_1m: 19.05,
      L_3m: 18.57,
      H_3m: 19.15,
      L_52w: 18.57,
      H_52w: 21.12,
      cur: 19.03,
      id: "1359",
    },
    1361: {
      d_1d: -0.01,
      d_3d: -0.01,
      d_5d: -0.01,
      d_10d: -0.01,
      d_20d: -0.01,
      L_5d: 0.63,
      H_5d: 0.64,
      L_1m: 0.63,
      H_1m: 0.64,
      L_3m: 0.63,
      H_3m: 0.67,
      L_52w: 0.63,
      H_52w: 1.38,
      cur: 0.63,
      id: "1361",
    },
    1368: {
      d_1d: -0.15,
      d_3d: -0.18,
      d_5d: -0.18,
      d_10d: 0.01,
      d_20d: 0,
      L_5d: 15.6,
      H_5d: 15.79,
      L_1m: 15.37,
      H_1m: 15.79,
      L_3m: 15.37,
      H_3m: 17.49,
      L_52w: 3.23,
      H_52w: 17.92,
      cur: 15.6,
      id: "1368",
    },
    1375: {
      d_1d: 0.01,
      d_3d: 0.3,
      d_5d: 0.31,
      d_10d: 0.55,
      d_20d: 0.77,
      L_5d: 42.79,
      H_5d: 43.1,
      L_1m: 42.3,
      H_1m: 43.1,
      L_3m: 40.26,
      H_3m: 43.1,
      L_52w: 25.99,
      H_52w: 43.1,
      cur: 43.1,
      id: "1375",
    },
    1378: {
      d_1d: 0.02,
      d_3d: -0.01,
      d_5d: -0.03,
      d_10d: -0.04,
      d_20d: -0.47,
      L_5d: 7.01,
      H_5d: 7.07,
      L_1m: 7.01,
      H_1m: 7.51,
      L_3m: 7.01,
      H_3m: 8.7,
      L_52w: 5.49,
      H_52w: 8.81,
      cur: 7.04,
      id: "1378",
    },
    1381: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0.01,
      d_10d: 0.04,
      d_20d: -0.03,
      L_5d: 2.09,
      H_5d: 2.09,
      L_1m: 2.03,
      H_1m: 2.12,
      L_3m: 1.95,
      H_3m: 2.26,
      L_52w: 1.24,
      H_52w: 2.26,
      cur: 2.09,
      id: "1381",
    },
    1382: {
      d_1d: 0,
      d_3d: -0.02,
      d_5d: -0.03,
      d_10d: 0.18,
      d_20d: 0.27,
      L_5d: 2.85,
      H_5d: 2.9,
      L_1m: 2.56,
      H_1m: 2.9,
      L_3m: 2.49,
      H_3m: 2.9,
      L_52w: 1.82,
      H_52w: 2.9,
      cur: 2.85,
      id: "1382",
    },
    1383: {
      d_1d: 0,
      d_3d: -0.02,
      d_5d: -0.04,
      d_10d: -0.04,
      d_20d: -0.08,
      L_5d: 1.19,
      H_5d: 1.23,
      L_1m: 1.19,
      H_1m: 1.26,
      L_3m: 1.19,
      H_3m: 1.49,
      L_52w: 0.52,
      H_52w: 1.49,
      cur: 1.19,
      id: "1383",
    },
    1385: {
      d_1d: -0.01,
      d_3d: 0.45,
      d_5d: 0.96,
      d_10d: 1.76,
      d_20d: 7.5,
      L_5d: 17.57,
      H_5d: 18.38,
      L_1m: 11.21,
      H_1m: 18.38,
      L_3m: 7.92,
      H_3m: 18.38,
      L_52w: 0,
      H_52w: 18.38,
      cur: 18.37,
      id: "1385",
    },
    1387: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: -0.01,
      L_5d: 1.44,
      H_5d: 1.44,
      L_1m: 1.44,
      H_1m: 1.45,
      L_3m: 1.44,
      H_3m: 1.46,
      L_52w: 1.44,
      H_52w: 1.76,
      cur: 1.44,
      id: "1387",
    },
    1398: {
      d_1d: -0.01,
      d_3d: 0,
      d_5d: 0.03,
      d_10d: 0.08,
      d_20d: 0.07,
      L_5d: 27.32,
      H_5d: 27.34,
      L_1m: 27.21,
      H_1m: 27.34,
      L_3m: 27.21,
      H_3m: 27.67,
      L_52w: 27.21,
      H_52w: 29.88,
      cur: 27.33,
      id: "1398",
    },
    1432: {
      d_1d: 0,
      d_3d: 0,
      d_5d: -0.01,
      d_10d: -0.01,
      d_20d: -0.01,
      L_5d: 1,
      H_5d: 1.01,
      L_1m: 1,
      H_1m: 1.01,
      L_3m: 1,
      H_3m: 1.03,
      L_52w: 1,
      H_52w: 1.14,
      cur: 1,
      id: "1432",
    },
    1448: {
      d_1d: 0.04,
      d_3d: 0.17,
      d_5d: 0.09,
      d_10d: 0.2,
      d_20d: 0.34,
      L_5d: 10.89,
      H_5d: 11.06,
      L_1m: 10.66,
      H_1m: 11.06,
      L_3m: 9.96,
      H_3m: 11.06,
      L_52w: 9.96,
      H_52w: 13.79,
      cur: 11.06,
      id: "1448",
    },
    1456: {
      d_1d: 0.08,
      d_3d: 0.58,
      d_5d: 0.65,
      d_10d: 0.86,
      d_20d: 1.84,
      L_5d: 30.41,
      H_5d: 31.03,
      L_1m: 29.37,
      H_1m: 31.03,
      L_3m: 28.16,
      H_3m: 31.03,
      L_52w: 15.32,
      H_52w: 31.03,
      cur: 31.03,
      id: "1456",
    },
    1458: {
      d_1d: 0.01,
      d_3d: -0.16,
      d_5d: -0.25,
      d_10d: -0.41,
      d_20d: -0.37,
      L_5d: 16.61,
      H_5d: 16.83,
      L_1m: 16.61,
      H_1m: 17.05,
      L_3m: 16.61,
      H_3m: 19.3,
      L_52w: 16.02,
      H_52w: 19.66,
      cur: 16.62,
      id: "1458",
    },
    1475: {
      d_1d: 0,
      d_3d: -0.01,
      d_5d: -0.02,
      d_10d: -0.07,
      d_20d: -0.14,
      L_5d: 0.44,
      H_5d: 0.45,
      L_1m: 0.44,
      H_1m: 0.58,
      L_3m: 0.44,
      H_3m: 0.63,
      L_52w: 0.25,
      H_52w: 1.15,
      cur: 0.44,
      id: "1475",
    },
    1477: {
      d_1d: -0.04,
      d_3d: -0.03,
      d_5d: -0.03,
      d_10d: -0.03,
      d_20d: 0.04,
      L_5d: 1.87,
      H_5d: 1.91,
      L_1m: 1.83,
      H_1m: 1.91,
      L_3m: 1.7,
      H_3m: 2.13,
      L_52w: 0,
      H_52w: 2.41,
      cur: 1.87,
      id: "1477",
    },
    1478: {
      d_1d: -0.01,
      d_3d: 0.04,
      d_5d: 0.04,
      d_10d: -0.04,
      d_20d: -0.43,
      L_5d: 9.4,
      H_5d: 9.49,
      L_1m: 9.4,
      H_1m: 9.93,
      L_3m: 9.4,
      H_3m: 10.04,
      L_52w: 4.36,
      H_52w: 10.17,
      cur: 9.47,
      id: "1478",
    },
    1508: {
      d_1d: 0,
      d_3d: -0.01,
      d_5d: -0.01,
      d_10d: -0.11,
      d_20d: -0.17,
      L_5d: 18.34,
      H_5d: 18.35,
      L_1m: 18.34,
      H_1m: 18.51,
      L_3m: 18.34,
      H_3m: 21.33,
      L_52w: 13.85,
      H_52w: 22.49,
      cur: 18.34,
      id: "1508",
    },
    1513: {
      d_1d: 0.01,
      d_3d: 0.13,
      d_5d: 0.18,
      d_10d: 0.22,
      d_20d: 0.23,
      L_5d: 12.83,
      H_5d: 13,
      L_1m: 12.75,
      H_1m: 13,
      L_3m: 12.64,
      H_3m: 13.38,
      L_52w: 11.04,
      H_52w: 13.38,
      cur: 13,
      id: "1513",
    },
    1515: {
      d_1d: -0.01,
      d_3d: 1.91,
      d_5d: 1.98,
      d_10d: 1.97,
      d_20d: 2.26,
      L_5d: 7.35,
      H_5d: 9.3,
      L_1m: 7.06,
      H_1m: 9.3,
      L_3m: 7.03,
      H_3m: 9.3,
      L_52w: 5.77,
      H_52w: 9.92,
      cur: 9.29,
      id: "1515",
    },
    1516: {
      d_1d: -0.05,
      d_3d: -0.08,
      d_5d: -0.13,
      d_10d: -0.16,
      d_20d: 0.47,
      L_5d: 4.3,
      H_5d: 4.44,
      L_1m: 3.56,
      H_1m: 4.62,
      L_3m: 3.56,
      H_3m: 4.62,
      L_52w: 0.39,
      H_52w: 4.62,
      cur: 4.3,
      id: "1516",
    },
    1521: {
      d_1d: 0.24,
      d_3d: 0.3,
      d_5d: 0.33,
      d_10d: 0.46,
      d_20d: 0.09,
      L_5d: 9.03,
      H_5d: 9.36,
      L_1m: 8.89,
      H_1m: 9.36,
      L_3m: 8.89,
      H_3m: 10.76,
      L_52w: 8.89,
      H_52w: 13.06,
      cur: 9.36,
      id: "1521",
    },
    1528: {
      d_1d: 0,
      d_3d: 0.02,
      d_5d: 0.09,
      d_10d: 0.16,
      d_20d: 0.12,
      L_5d: 22.4,
      H_5d: 22.45,
      L_1m: 22.29,
      H_1m: 22.45,
      L_3m: 21.77,
      H_3m: 22.45,
      L_52w: 18.44,
      H_52w: 23.35,
      cur: 22.45,
      id: "1528",
    },
    1530: {
      d_1d: -0.02,
      d_3d: 0.01,
      d_5d: -0.22,
      d_10d: -0.24,
      d_20d: -0.38,
      L_5d: 14.41,
      H_5d: 14.46,
      L_1m: 14.41,
      H_1m: 14.83,
      L_3m: 14.41,
      H_3m: 16.37,
      L_52w: 7.45,
      H_52w: 16.51,
      cur: 14.42,
      id: "1530",
    },
    1533: {
      d_1d: 0.01,
      d_3d: 0,
      d_5d: -0.04,
      d_10d: -0.38,
      d_20d: -1.08,
      L_5d: 23.08,
      H_5d: 23.12,
      L_1m: 23.08,
      H_1m: 24.04,
      L_3m: 23.08,
      H_3m: 25.37,
      L_52w: 22.64,
      H_52w: 27.18,
      cur: 23.09,
      id: "1533",
    },
    1548: {
      d_1d: 0.01,
      d_3d: 0.17,
      d_5d: 0.29,
      d_10d: 0.49,
      d_20d: 1.19,
      L_5d: 18.36,
      H_5d: 18.61,
      L_1m: 17.48,
      H_1m: 18.61,
      L_3m: 16.77,
      H_3m: 18.61,
      L_52w: 11.65,
      H_52w: 18.61,
      cur: 18.61,
      id: "1548",
    },
    1551: {
      d_1d: 0,
      d_3d: -0.02,
      d_5d: -0.02,
      d_10d: -0.02,
      d_20d: -0.02,
      L_5d: 0.11,
      H_5d: 0.13,
      L_1m: 0.11,
      H_1m: 0.13,
      L_3m: 0.11,
      H_3m: 0.13,
      L_52w: 0.11,
      H_52w: 0.13,
      cur: 0.11,
      id: "1551",
    },
    1558: {
      d_1d: -0.01,
      d_3d: -0.13,
      d_5d: -0.16,
      d_10d: -0.2,
      d_20d: -0.64,
      L_5d: 20.28,
      H_5d: 20.42,
      L_1m: 20.28,
      H_1m: 20.85,
      L_3m: 20.28,
      H_3m: 23.42,
      L_52w: 20.28,
      H_52w: 29.73,
      cur: 20.28,
      id: "1558",
    },
    1569: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: -0.01,
      d_20d: -0.05,
      L_5d: 2.81,
      H_5d: 2.81,
      L_1m: 2.81,
      H_1m: 2.86,
      L_3m: 2.81,
      H_3m: 3.16,
      L_52w: 2.81,
      H_52w: 5.62,
      cur: 2.81,
      id: "1569",
    },
    1578: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0,
      H_5d: 0,
      L_1m: 0,
      H_1m: 0,
      L_3m: 0,
      H_3m: 0,
      L_52w: 0,
      H_52w: 0,
      cur: 0,
      id: "1578",
    },
    1579: {
      d_1d: 0,
      d_3d: -0.15,
      d_5d: -0.12,
      d_10d: -0.08,
      d_20d: 0.15,
      L_5d: 12.38,
      H_5d: 12.53,
      L_1m: 12.38,
      H_1m: 12.69,
      L_3m: 10.34,
      H_3m: 12.69,
      L_52w: 9.94,
      H_52w: 13.98,
      cur: 12.38,
      id: "1579",
    },
    1583: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0,
      H_5d: 0,
      L_1m: 0,
      H_1m: 0,
      L_3m: 0,
      H_3m: 0,
      L_52w: 0,
      H_52w: 0,
      cur: 0,
      id: "1583",
    },
    1585: {
      d_1d: 0,
      d_3d: -0.05,
      d_5d: -0.03,
      d_10d: 0.03,
      d_20d: -0.05,
      L_5d: 9.21,
      H_5d: 9.26,
      L_1m: 9.16,
      H_1m: 9.26,
      L_3m: 9.11,
      H_3m: 9.57,
      L_52w: 9.11,
      H_52w: 10.35,
      cur: 9.21,
      id: "1585",
    },
    1589: {
      d_1d: 0.01,
      d_3d: 0.01,
      d_5d: 0.01,
      d_10d: 0.03,
      d_20d: -0.01,
      L_5d: 0.35,
      H_5d: 0.37,
      L_1m: 0.33,
      H_1m: 0.39,
      L_3m: 0.33,
      H_3m: 1.15,
      L_52w: 0.04,
      H_52w: 1.49,
      cur: 0.37,
      id: "1589",
    },
    1600: {
      d_1d: 0,
      d_3d: 0.02,
      d_5d: 0,
      d_10d: 0.01,
      d_20d: 0,
      L_5d: 0.9,
      H_5d: 0.92,
      L_1m: 0.9,
      H_1m: 0.92,
      L_3m: 0.9,
      H_3m: 1.16,
      L_52w: 0.9,
      H_52w: 1.74,
      cur: 0.92,
      id: "1600",
    },
    1606: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.01,
      H_5d: 0.01,
      L_1m: 0.01,
      H_1m: 0.01,
      L_3m: 0.01,
      H_3m: 0.01,
      L_52w: 0.01,
      H_52w: 0.01,
      cur: 0.01,
      id: "1606",
    },
    1608: {
      d_1d: 0,
      d_3d: 0.04,
      d_5d: 0.06,
      d_10d: 0.07,
      d_20d: 0.11,
      L_5d: 0.58,
      H_5d: 0.65,
      L_1m: 0.55,
      H_1m: 0.65,
      L_3m: 0.42,
      H_3m: 0.65,
      L_52w: 0.17,
      H_52w: 0.65,
      cur: 0.65,
      id: "1608",
    },
    1610: {
      d_1d: 0.02,
      d_3d: -0.34,
      d_5d: -0.25,
      d_10d: -0.37,
      d_20d: -0.63,
      L_5d: 27.28,
      H_5d: 27.68,
      L_1m: 27.28,
      H_1m: 28.12,
      L_3m: 24.74,
      H_3m: 28.12,
      L_52w: 17.17,
      H_52w: 28.12,
      cur: 27.3,
      id: "1610",
    },
    1618: {
      d_1d: 0.01,
      d_3d: 0.14,
      d_5d: 0.3,
      d_10d: 0.66,
      d_20d: -0.05,
      L_5d: 22.39,
      H_5d: 22.58,
      L_1m: 21.77,
      H_1m: 22.68,
      L_3m: 21.77,
      H_3m: 26.21,
      L_52w: 17.89,
      H_52w: 26.21,
      cur: 22.58,
      id: "1618",
    },
    1622: {
      d_1d: 0,
      d_3d: 0.03,
      d_5d: 0.05,
      d_10d: 0.14,
      d_20d: 0.28,
      L_5d: 6.86,
      H_5d: 6.9,
      L_1m: 6.63,
      H_1m: 6.9,
      L_3m: 6.44,
      H_3m: 6.9,
      L_52w: 6.44,
      H_52w: 6.9,
      cur: 6.9,
      id: "1622",
    },
    1628: {
      d_1d: 0.08,
      d_3d: 0.25,
      d_5d: 0.12,
      d_10d: 0.32,
      d_20d: 0.61,
      L_5d: 2.68,
      H_5d: 3.01,
      L_1m: 2.39,
      H_1m: 3.01,
      L_3m: 1.85,
      H_3m: 3.01,
      L_52w: 1.12,
      H_52w: 3.01,
      cur: 3.01,
      id: "1628",
    },
    1635: {
      d_1d: 0,
      d_3d: 0.05,
      d_5d: 0.06,
      d_10d: 0.38,
      d_20d: 0.96,
      L_5d: 21.39,
      H_5d: 21.46,
      L_1m: 20.51,
      H_1m: 21.46,
      L_3m: 18.23,
      H_3m: 21.46,
      L_52w: 15.41,
      H_52w: 21.46,
      cur: 21.46,
      id: "1635",
    },
    1636: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.99,
      H_5d: 0.99,
      L_1m: 0.99,
      H_1m: 0.99,
      L_3m: 0.99,
      H_3m: 1.04,
      L_52w: 0.99,
      H_52w: 1.31,
      cur: 0.99,
      id: "1636",
    },
    1638: {
      d_1d: 0.14,
      d_3d: 0.15,
      d_5d: 0.31,
      d_10d: 0.31,
      d_20d: 0.86,
      L_5d: 14.18,
      H_5d: 14.48,
      L_1m: 14.17,
      H_1m: 14.48,
      L_3m: 10.69,
      H_3m: 14.48,
      L_52w: 8.95,
      H_52w: 14.48,
      cur: 14.48,
      id: "1638",
    },
    1658: {
      d_1d: -0.02,
      d_3d: -0.04,
      d_5d: -0.02,
      d_10d: -0.13,
      d_20d: -0.14,
      L_5d: 4.36,
      H_5d: 4.41,
      L_1m: 4.33,
      H_1m: 4.51,
      L_3m: 4.33,
      H_3m: 5.9,
      L_52w: 3.07,
      H_52w: 6.11,
      cur: 4.36,
      id: "1658",
    },
    1661: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.38,
      H_5d: 0.38,
      L_1m: 0.38,
      H_1m: 0.38,
      L_3m: 0.38,
      H_3m: 0.38,
      L_52w: 0.38,
      H_52w: 0.39,
      cur: 0.38,
      id: "1661",
    },
    1666: {
      d_1d: -0.02,
      d_3d: -0.02,
      d_5d: -0.03,
      d_10d: -0.05,
      d_20d: -0.13,
      L_5d: 12.71,
      H_5d: 12.73,
      L_1m: 12.71,
      H_1m: 12.81,
      L_3m: 12.71,
      H_3m: 13.28,
      L_52w: 12.71,
      H_52w: 21.72,
      cur: 12.71,
      id: "1666",
    },
    1668: {
      d_1d: 0.15,
      d_3d: 0.23,
      d_5d: 0.29,
      d_10d: 0.35,
      d_20d: 0.6,
      L_5d: 13.32,
      H_5d: 13.56,
      L_1m: 12.99,
      H_1m: 13.56,
      L_3m: 12.81,
      H_3m: 13.56,
      L_52w: 11.15,
      H_52w: 13.56,
      cur: 13.56,
      id: "1668",
    },
    1675: {
      d_1d: 0.01,
      d_3d: 0.04,
      d_5d: 0.06,
      d_10d: 0.07,
      d_20d: 0.09,
      L_5d: 0.79,
      H_5d: 0.84,
      L_1m: 0.75,
      H_1m: 0.84,
      L_3m: 0.71,
      H_3m: 0.84,
      L_52w: 0.27,
      H_52w: 1.17,
      cur: 0.84,
      id: "1675",
    },
    1680: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 1.57,
      H_5d: 1.57,
      L_1m: 1.57,
      H_1m: 1.57,
      L_3m: 1.57,
      H_3m: 1.57,
      L_52w: 1.57,
      H_52w: 1.67,
      cur: 1.57,
      id: "1680",
    },
    1681: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.9,
      H_5d: 0.9,
      L_1m: 0.9,
      H_1m: 0.9,
      L_3m: 0.9,
      H_3m: 1.25,
      L_52w: 0.9,
      H_52w: 2.49,
      cur: 0.9,
      id: "1681",
    },
    1685: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.19,
      H_5d: 0.19,
      L_1m: 0.19,
      H_1m: 0.19,
      L_3m: 0.19,
      H_3m: 0.19,
      L_52w: 0.19,
      H_52w: 0.28,
      cur: 0.19,
      id: "1685",
    },
    1686: {
      d_1d: 0.01,
      d_3d: 0.04,
      d_5d: 0.04,
      d_10d: 0.04,
      d_20d: 0.06,
      L_5d: 0.32,
      H_5d: 0.36,
      L_1m: 0.3,
      H_1m: 0.36,
      L_3m: 0.25,
      H_3m: 0.36,
      L_52w: 0.1,
      H_52w: 0.39,
      cur: 0.36,
      id: "1686",
    },
    1691: {
      d_1d: 0.03,
      d_3d: -0.02,
      d_5d: 0.02,
      d_10d: 0.55,
      d_20d: 0.77,
      L_5d: 7.65,
      H_5d: 7.74,
      L_1m: 6.83,
      H_1m: 7.74,
      L_3m: 6.83,
      H_3m: 7.81,
      L_52w: 1.22,
      H_52w: 8.48,
      cur: 7.68,
      id: "1691",
    },
    1717: {
      d_1d: 0,
      d_3d: -0.02,
      d_5d: -0.03,
      d_10d: -0.04,
      d_20d: -0.2,
      L_5d: 2.19,
      H_5d: 2.22,
      L_1m: 2.19,
      H_1m: 2.35,
      L_3m: 2.19,
      H_3m: 2.73,
      L_52w: 2.19,
      H_52w: 5.74,
      cur: 2.19,
      id: "1717",
    },
    1728: {
      d_1d: 0,
      d_3d: -0.01,
      d_5d: -0.03,
      d_10d: -0.17,
      d_20d: -0.18,
      L_5d: 6.11,
      H_5d: 6.13,
      L_1m: 6.11,
      H_1m: 6.29,
      L_3m: 6.11,
      H_3m: 6.69,
      L_52w: 6.11,
      H_52w: 14.56,
      cur: 6.11,
      id: "1728",
    },
    1755: {
      d_1d: -0.03,
      d_3d: -0.08,
      d_5d: -0.02,
      d_10d: 0.08,
      d_20d: 0.14,
      L_5d: 6.01,
      H_5d: 6.09,
      L_1m: 5.92,
      H_1m: 6.09,
      L_3m: 5.32,
      H_3m: 6.09,
      L_52w: 0,
      H_52w: 6.09,
      cur: 6.01,
      id: "1755",
    },
    1761: {
      d_1d: 0,
      d_3d: 0,
      d_5d: -0.02,
      d_10d: -0.02,
      d_20d: -0.02,
      L_5d: 0.23,
      H_5d: 0.25,
      L_1m: 0.23,
      H_1m: 0.25,
      L_3m: 0.23,
      H_3m: 0.25,
      L_52w: 0.23,
      H_52w: 0.35,
      cur: 0.23,
      id: "1761",
    },
    1765: {
      d_1d: -0.12,
      d_3d: 0.88,
      d_5d: 1.6,
      d_10d: 1.81,
      d_20d: 2.25,
      L_5d: 17.8,
      H_5d: 19.15,
      L_1m: 16.81,
      H_1m: 19.15,
      L_3m: 16,
      H_3m: 19.15,
      L_52w: 11.22,
      H_52w: 19.15,
      cur: 19.03,
      id: "1765",
    },
    1766: {
      d_1d: -0.09,
      d_3d: -0.02,
      d_5d: -0.23,
      d_10d: -0.2,
      d_20d: -0.36,
      L_5d: 21.26,
      H_5d: 21.53,
      L_1m: 21.26,
      H_1m: 21.7,
      L_3m: 21.26,
      H_3m: 23.3,
      L_52w: 10.42,
      H_52w: 28.23,
      cur: 21.34,
      id: "1766",
    },
    1769: {
      d_1d: -0.62,
      d_3d: -0.22,
      d_5d: 1.03,
      d_10d: 2.4,
      d_20d: 2.09,
      L_5d: 9.93,
      H_5d: 10.64,
      L_1m: 7.62,
      H_1m: 10.64,
      L_3m: 6.7,
      H_3m: 10.64,
      L_52w: 0,
      H_52w: 10.64,
      cur: 10.02,
      id: "1769",
    },
    1772: {
      d_1d: 0.1,
      d_3d: -0.16,
      d_5d: -0.15,
      d_10d: -0.05,
      d_20d: -0.68,
      L_5d: 22.81,
      H_5d: 23.07,
      L_1m: 22.81,
      H_1m: 23.89,
      L_3m: 22.81,
      H_3m: 27.82,
      L_52w: 22.81,
      H_52w: 56.33,
      cur: 22.91,
      id: "1772",
    },
    1773: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0.15,
      d_10d: 0.3,
      d_20d: -0.14,
      L_5d: 15.7,
      H_5d: 15.77,
      L_1m: 15.45,
      H_1m: 16.02,
      L_3m: 15.45,
      H_3m: 16.66,
      L_52w: 11.6,
      H_52w: 19.65,
      cur: 15.77,
      id: "1773",
    },
    1776: {
      d_1d: -0.18,
      d_3d: 0.09,
      d_5d: 0.07,
      d_10d: 0.71,
      d_20d: 0.84,
      L_5d: 38.41,
      H_5d: 38.7,
      L_1m: 37.44,
      H_1m: 38.7,
      L_3m: 37.23,
      H_3m: 38.7,
      L_52w: 30.35,
      H_52w: 38.7,
      cur: 38.52,
      id: "1776",
    },
    1777: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0.29,
      d_10d: 0.24,
      d_20d: -1.15,
      L_5d: 4.37,
      H_5d: 4.54,
      L_1m: 4.07,
      H_1m: 5.69,
      L_3m: 4.07,
      H_3m: 5.8,
      L_52w: 4.07,
      H_52w: 7.37,
      cur: 4.54,
      id: "1777",
    },
    1778: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: -0.01,
      d_20d: -0.04,
      L_5d: 3.74,
      H_5d: 3.74,
      L_1m: 3.74,
      H_1m: 3.78,
      L_3m: 3.74,
      H_3m: 4.08,
      L_52w: 3.74,
      H_52w: 4.39,
      cur: 3.74,
      id: "1778",
    },
    1787: {
      d_1d: -0.11,
      d_3d: -0.21,
      d_5d: -0.1,
      d_10d: -0.23,
      d_20d: -0.71,
      L_5d: 19.23,
      H_5d: 19.44,
      L_1m: 19.23,
      H_1m: 19.99,
      L_3m: 18.45,
      H_3m: 19.99,
      L_52w: 15.83,
      H_52w: 22.67,
      cur: 19.23,
      id: "1787",
    },
    1788: {
      d_1d: 0.01,
      d_3d: 0,
      d_5d: 0.01,
      d_10d: 0.02,
      d_20d: 0.06,
      L_5d: 1.92,
      H_5d: 1.93,
      L_1m: 1.9,
      H_1m: 1.93,
      L_3m: 1.79,
      H_3m: 1.93,
      L_52w: 1.79,
      H_52w: 2.72,
      cur: 1.93,
      id: "1788",
    },
    1789: {
      d_1d: 0.14,
      d_3d: 0.24,
      d_5d: 0.28,
      d_10d: 0.55,
      d_20d: 0.67,
      L_5d: 6.74,
      H_5d: 7.02,
      L_1m: 6.38,
      H_1m: 7.02,
      L_3m: 6.09,
      H_3m: 7.44,
      L_52w: 6.09,
      H_52w: 9.91,
      cur: 7.02,
      id: "1789",
    },
    1797: {
      d_1d: -0.66,
      d_3d: -0.74,
      d_5d: -1.06,
      d_10d: 0.8,
      d_20d: 0.45,
      L_5d: 15.64,
      H_5d: 16.95,
      L_1m: 14.43,
      H_1m: 16.95,
      L_3m: 14.3,
      H_3m: 16.95,
      L_52w: 11.19,
      H_52w: 17.05,
      cur: 15.64,
      id: "1797",
    },
    1800: {
      d_1d: -0.07,
      d_3d: 0.13,
      d_5d: 0.17,
      d_10d: 0.37,
      d_20d: 0.57,
      L_5d: 30.36,
      H_5d: 30.57,
      L_1m: 29.95,
      H_1m: 30.57,
      L_3m: 28.85,
      H_3m: 30.57,
      L_52w: 15.67,
      H_52w: 30.57,
      cur: 30.5,
      id: "1800",
    },
    1801: {
      d_1d: 0.08,
      d_3d: -0.06,
      d_5d: 0.05,
      d_10d: -0.02,
      d_20d: 0.52,
      L_5d: 17.57,
      H_5d: 17.71,
      L_1m: 17.06,
      H_1m: 17.76,
      L_3m: 17.01,
      H_3m: 17.76,
      L_52w: 8.25,
      H_52w: 17.81,
      cur: 17.65,
      id: "1801",
    },
    1810: {
      d_1d: 0.03,
      d_3d: 0.04,
      d_5d: 0.06,
      d_10d: -0.14,
      d_20d: -0.25,
      L_5d: 11.2,
      H_5d: 11.25,
      L_1m: 11.19,
      H_1m: 11.51,
      L_3m: 11.19,
      H_3m: 12.21,
      L_52w: 10.1,
      H_52w: 14.11,
      cur: 11.25,
      id: "1810",
    },
    1811: {
      d_1d: 0.07,
      d_3d: -0.06,
      d_5d: 0.01,
      d_10d: 0,
      d_20d: -0.39,
      L_5d: 13.19,
      H_5d: 13.32,
      L_1m: 13.19,
      H_1m: 13.79,
      L_3m: 13.15,
      H_3m: 13.94,
      L_52w: 3.63,
      H_52w: 13.94,
      cur: 13.26,
      id: "1811",
    },
    1812: {
      d_1d: -0.87,
      d_3d: -0.72,
      d_5d: -0.54,
      d_10d: -0.42,
      d_20d: -0.69,
      L_5d: 35.88,
      H_5d: 36.75,
      L_1m: 35.88,
      H_1m: 36.75,
      L_3m: 31,
      H_3m: 36.82,
      L_52w: 24.28,
      H_52w: 45.96,
      cur: 35.88,
      id: "1812",
    },
    1813: {
      d_1d: -0.06,
      d_3d: -0.15,
      d_5d: -0.21,
      d_10d: -0.22,
      d_20d: -0.28,
      L_5d: 2.24,
      H_5d: 2.43,
      L_1m: 2.24,
      H_1m: 2.52,
      L_3m: 2.24,
      H_3m: 3.07,
      L_52w: 2.24,
      H_52w: 4.69,
      cur: 2.24,
      id: "1813",
    },
    1816: {
      d_1d: -0.04,
      d_3d: -0.08,
      d_5d: -0.11,
      d_10d: -0.08,
      d_20d: -0.54,
      L_5d: 32.78,
      H_5d: 32.87,
      L_1m: 32.78,
      H_1m: 33.32,
      L_3m: 31.09,
      H_3m: 33.88,
      L_52w: 22.74,
      H_52w: 33.88,
      cur: 32.78,
      id: "1816",
    },
    1817: {
      d_1d: 0,
      d_3d: 0,
      d_5d: -0.01,
      d_10d: -0.01,
      d_20d: -0.02,
      L_5d: 0.12,
      H_5d: 0.12,
      L_1m: 0.12,
      H_1m: 0.14,
      L_3m: 0.12,
      H_3m: 0.17,
      L_52w: 0.02,
      H_52w: 0.3,
      cur: 0.12,
      id: "1817",
    },
    1818: {
      d_1d: -0.19,
      d_3d: -0.22,
      d_5d: -0.34,
      d_10d: -0.21,
      d_20d: -0.12,
      L_5d: 10.43,
      H_5d: 10.76,
      L_1m: 10.43,
      H_1m: 10.87,
      L_3m: 8.8,
      H_3m: 10.87,
      L_52w: 4.83,
      H_52w: 10.87,
      cur: 10.43,
      id: "1818",
    },
    1821: {
      d_1d: 0,
      d_3d: 0.03,
      d_5d: 0.03,
      d_10d: 0.03,
      d_20d: 0.03,
      L_5d: 0.44,
      H_5d: 0.47,
      L_1m: 0.44,
      H_1m: 0.47,
      L_3m: 0.39,
      H_3m: 0.47,
      L_52w: 0,
      H_52w: 0.47,
      cur: 0.47,
      id: "1821",
    },
    1833: {
      d_1d: 0.08,
      d_3d: 0.13,
      d_5d: 0.14,
      d_10d: 0.39,
      d_20d: -0.7,
      L_5d: 9.68,
      H_5d: 9.85,
      L_1m: 9.43,
      H_1m: 10.6,
      L_3m: 9.43,
      H_3m: 10.6,
      L_52w: 6.13,
      H_52w: 10.6,
      cur: 9.85,
      id: "1833",
    },
    1836: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0.01,
      L_5d: 0.04,
      H_5d: 0.04,
      L_1m: 0.03,
      H_1m: 0.04,
      L_3m: 0.03,
      H_3m: 0.04,
      L_52w: 0.03,
      H_52w: 0.12,
      cur: 0.04,
      id: "1836",
    },
    1839: {
      d_1d: -0.06,
      d_3d: -0.04,
      d_5d: -0.02,
      d_10d: 0.02,
      d_20d: 0.06,
      L_5d: 0.51,
      H_5d: 0.57,
      L_1m: 0.44,
      H_1m: 0.57,
      L_3m: 0.13,
      H_3m: 0.57,
      L_52w: 0,
      H_52w: 0.57,
      cur: 0.51,
      id: "1839",
    },
    1848: {
      d_1d: 0,
      d_3d: -0.01,
      d_5d: -0.01,
      d_10d: -0.01,
      d_20d: -0.02,
      L_5d: 0.95,
      H_5d: 0.96,
      L_1m: 0.95,
      H_1m: 0.97,
      L_3m: 0.95,
      H_3m: 1,
      L_52w: 0.95,
      H_52w: 1.79,
      cur: 0.95,
      id: "1848",
    },
    1860: {
      d_1d: -0.01,
      d_3d: 0.03,
      d_5d: 0.02,
      d_10d: -0.16,
      d_20d: -0.28,
      L_5d: 5.63,
      H_5d: 5.7,
      L_1m: 5.63,
      H_1m: 5.94,
      L_3m: 5.63,
      H_3m: 6.6,
      L_52w: 1,
      H_52w: 6.61,
      cur: 5.69,
      id: "1860",
    },
    1873: {
      d_1d: 0.21,
      d_3d: 0.12,
      d_5d: 0.18,
      d_10d: -0.03,
      d_20d: 0.21,
      L_5d: 23.59,
      H_5d: 23.8,
      L_1m: 23.59,
      H_1m: 23.96,
      L_3m: 23.4,
      H_3m: 23.96,
      L_52w: 21.97,
      H_52w: 25.7,
      cur: 23.8,
      id: "1873",
    },
    1876: {
      d_1d: 0,
      d_3d: 0.01,
      d_5d: 0,
      d_10d: 0.01,
      d_20d: 0,
      L_5d: 0.07,
      H_5d: 0.08,
      L_1m: 0.07,
      H_1m: 0.08,
      L_3m: 0.07,
      H_3m: 0.13,
      L_52w: 0.07,
      H_52w: 0.24,
      cur: 0.08,
      id: "1876",
    },
    1877: {
      d_1d: -0.06,
      d_3d: 0.15,
      d_5d: 0.37,
      d_10d: 0.82,
      d_20d: 1.56,
      L_5d: 14.65,
      H_5d: 15.06,
      L_1m: 13.51,
      H_1m: 15.06,
      L_3m: 11.91,
      H_3m: 15.06,
      L_52w: 0,
      H_52w: 15.06,
      cur: 15,
      id: "1877",
    },
    1882: {
      d_1d: 0,
      d_3d: 0.01,
      d_5d: 0.01,
      d_10d: 0.01,
      d_20d: 0,
      L_5d: 0.32,
      H_5d: 0.33,
      L_1m: 0.32,
      H_1m: 0.33,
      L_3m: 0.32,
      H_3m: 0.53,
      L_52w: 0.32,
      H_52w: 1.9,
      cur: 0.33,
      id: "1882",
    },
    1883: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.1,
      H_5d: 0.1,
      L_1m: 0.1,
      H_1m: 0.1,
      L_3m: 0.1,
      H_3m: 0.14,
      L_52w: 0.1,
      H_52w: 0.37,
      cur: 0.1,
      id: "1883",
    },
    1888: {
      d_1d: 0,
      d_3d: 0,
      d_5d: -0.01,
      d_10d: 0,
      d_20d: 0.05,
      L_5d: 0.43,
      H_5d: 0.47,
      L_1m: 0.39,
      H_1m: 0.47,
      L_3m: 0.32,
      H_3m: 0.47,
      L_52w: 0.26,
      H_52w: 1.93,
      cur: 0.44,
      id: "1888",
    },
    1890: {
      d_1d: 0.03,
      d_3d: 0.08,
      d_5d: 0.11,
      d_10d: 0.18,
      d_20d: 0.1,
      L_5d: 3.89,
      H_5d: 4.01,
      L_1m: 3.82,
      H_1m: 4.01,
      L_3m: 3.82,
      H_3m: 4.31,
      L_52w: 3.82,
      H_52w: 6.68,
      cur: 4.01,
      id: "1890",
    },
    1896: {
      d_1d: 0.01,
      d_3d: 0.05,
      d_5d: 0.09,
      d_10d: 0.01,
      d_20d: -0.08,
      L_5d: 6.82,
      H_5d: 6.96,
      L_1m: 6.82,
      H_1m: 7.11,
      L_3m: 6.32,
      H_3m: 7.11,
      L_52w: 3.02,
      H_52w: 7.11,
      cur: 6.96,
      id: "1896",
    },
    1898: {
      d_1d: -0.05,
      d_3d: 0.01,
      d_5d: -0.09,
      d_10d: 0.09,
      d_20d: 0.32,
      L_5d: 10.53,
      H_5d: 10.63,
      L_1m: 10.13,
      H_1m: 10.67,
      L_3m: 10.1,
      H_3m: 10.95,
      L_52w: 9.53,
      H_52w: 12.97,
      cur: 10.58,
      id: "1898",
    },
    1905: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0,
      H_5d: 0,
      L_1m: 0,
      H_1m: 0,
      L_3m: 0,
      H_3m: 0.37,
      L_52w: 0,
      H_52w: 0.67,
      cur: 0,
      id: "1905",
    },
    1907: {
      d_1d: -0.01,
      d_3d: -0.03,
      d_5d: -0.02,
      d_10d: -0.03,
      d_20d: -0.08,
      L_5d: 3.82,
      H_5d: 3.85,
      L_1m: 3.82,
      H_1m: 3.91,
      L_3m: 3.44,
      H_3m: 3.91,
      L_52w: 1.24,
      H_52w: 4.3,
      cur: 3.82,
      id: "1907",
    },
    1908: {
      d_1d: 0,
      d_3d: -0.01,
      d_5d: -0.01,
      d_10d: 0,
      d_20d: 0.24,
      L_5d: 2.92,
      H_5d: 2.93,
      L_1m: 2.68,
      H_1m: 2.95,
      L_3m: 2.48,
      H_3m: 2.95,
      L_52w: 2.48,
      H_52w: 6.37,
      cur: 2.92,
      id: "1908",
    },
    1911: {
      d_1d: 0.03,
      d_3d: 0.12,
      d_5d: 0.17,
      d_10d: 0.25,
      d_20d: 0.47,
      L_5d: 10.39,
      H_5d: 10.55,
      L_1m: 10.15,
      H_1m: 10.55,
      L_3m: 9.41,
      H_3m: 10.55,
      L_52w: 3.15,
      H_52w: 10.55,
      cur: 10.55,
      id: "1911",
    },
    1916: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.8,
      H_5d: 0.8,
      L_1m: 0.8,
      H_1m: 0.8,
      L_3m: 0.8,
      H_3m: 0.8,
      L_52w: 0.8,
      H_52w: 0.8,
      cur: 0.8,
      id: "1916",
    },
    1918: {
      d_1d: 0.16,
      d_3d: 0.04,
      d_5d: -0.07,
      d_10d: -0.89,
      d_20d: 0.52,
      L_5d: 16.99,
      H_5d: 17.17,
      L_1m: 16.65,
      H_1m: 18.45,
      L_3m: 15.53,
      H_3m: 18.45,
      L_52w: 14.76,
      H_52w: 18.45,
      cur: 17.15,
      id: "1918",
    },
    1919: {
      d_1d: 0.05,
      d_3d: -0.02,
      d_5d: -0.09,
      d_10d: 0.23,
      d_20d: 0.31,
      L_5d: 24.55,
      H_5d: 24.73,
      L_1m: 24.25,
      H_1m: 24.83,
      L_3m: 21.15,
      H_3m: 24.83,
      L_52w: 17.12,
      H_52w: 27.65,
      cur: 24.71,
      id: "1919",
    },
    1928: {
      d_1d: -0.01,
      d_3d: 0.01,
      d_5d: -0.02,
      d_10d: 0.04,
      d_20d: 0.09,
      L_5d: 0.88,
      H_5d: 0.91,
      L_1m: 0.81,
      H_1m: 0.92,
      L_3m: 0.76,
      H_3m: 0.92,
      L_52w: 0.54,
      H_52w: 1.06,
      cur: 0.9,
      id: "1928",
    },
    1929: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0.04,
      d_10d: 0.08,
      d_20d: 0.08,
      L_5d: 0.5,
      H_5d: 0.5,
      L_1m: 0.42,
      H_1m: 0.5,
      L_3m: 0.38,
      H_3m: 0.5,
      L_52w: 0.31,
      H_52w: 0.5,
      cur: 0.5,
      id: "1929",
    },
    1951: {
      d_1d: -0.06,
      d_3d: -0.14,
      d_5d: -0.13,
      d_10d: -0.04,
      d_20d: 0.21,
      L_5d: 20.84,
      H_5d: 20.98,
      L_1m: 20.61,
      H_1m: 21.04,
      L_3m: 19.15,
      H_3m: 21.04,
      L_52w: 18.77,
      H_52w: 22.42,
      cur: 20.84,
      id: "1951",
    },
    1952: {
      d_1d: 0.05,
      d_3d: 0.1,
      d_5d: 0.11,
      d_10d: 0.13,
      d_20d: 0.17,
      L_5d: 0.59,
      H_5d: 0.69,
      L_1m: 0.54,
      H_1m: 0.69,
      L_3m: 0.36,
      H_3m: 0.69,
      L_52w: 0,
      H_52w: 0.69,
      cur: 0.69,
      id: "1952",
    },
    1958: {
      d_1d: 0.03,
      d_3d: 0.55,
      d_5d: 0.59,
      d_10d: -0.1,
      d_20d: -0.48,
      L_5d: 12.7,
      H_5d: 13.29,
      L_1m: 12.7,
      H_1m: 14.57,
      L_3m: 12.7,
      H_3m: 14.57,
      L_52w: 5.56,
      H_52w: 14.57,
      cur: 13.29,
      id: "1958",
    },
    1963: {
      d_1d: 0.01,
      d_3d: 0.05,
      d_5d: 0.05,
      d_10d: 0.08,
      d_20d: 0.06,
      L_5d: 6.73,
      H_5d: 6.78,
      L_1m: 6.7,
      H_1m: 6.78,
      L_3m: 6.54,
      H_3m: 6.78,
      L_52w: 6.48,
      H_52w: 7.72,
      cur: 6.78,
      id: "1963",
    },
    1966: {
      d_1d: 0,
      d_3d: 0.04,
      d_5d: 0.07,
      d_10d: -0.01,
      d_20d: 0.01,
      L_5d: 4.23,
      H_5d: 4.3,
      L_1m: 4.22,
      H_1m: 4.41,
      L_3m: 4.12,
      H_3m: 4.46,
      L_52w: 3.61,
      H_52w: 4.46,
      cur: 4.3,
      id: "1966",
    },
    1970: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: -0.12,
      L_5d: 1.24,
      H_5d: 1.24,
      L_1m: 1.24,
      H_1m: 1.35,
      L_3m: 1.24,
      H_3m: 1.73,
      L_52w: 1.24,
      H_52w: 3.28,
      cur: 1.24,
      id: "1970",
    },
    1972: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.02,
      H_5d: 0.02,
      L_1m: 0.02,
      H_1m: 0.02,
      L_3m: 0.02,
      H_3m: 0.02,
      L_52w: 0.02,
      H_52w: 0.04,
      cur: 0.02,
      id: "1972",
    },
    1980: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 1.41,
      H_5d: 1.41,
      L_1m: 1.41,
      H_1m: 1.41,
      L_3m: 1.41,
      H_3m: 1.41,
      L_52w: 1.37,
      H_52w: 1.57,
      cur: 1.41,
      id: "1980",
    },
    1981: {
      d_1d: -0.03,
      d_3d: -0.07,
      d_5d: -0.05,
      d_10d: -0.09,
      d_20d: -0.11,
      L_5d: 2.69,
      H_5d: 2.76,
      L_1m: 2.69,
      H_1m: 2.8,
      L_3m: 2.55,
      H_3m: 2.81,
      L_52w: 0,
      H_52w: 3.25,
      cur: 2.69,
      id: "1981",
    },
    1988: {
      d_1d: 0,
      d_3d: 0.12,
      d_5d: 0.24,
      d_10d: 0.29,
      d_20d: 0.34,
      L_5d: 22.59,
      H_5d: 22.8,
      L_1m: 22.48,
      H_1m: 22.8,
      L_3m: 21.01,
      H_3m: 22.8,
      L_52w: 15.6,
      H_52w: 22.8,
      cur: 22.8,
      id: "1988",
    },
    1992: {
      d_1d: 0,
      d_3d: 0,
      d_5d: -0.01,
      d_10d: 0.08,
      d_20d: 0.16,
      L_5d: 1.34,
      H_5d: 1.35,
      L_1m: 1.19,
      H_1m: 1.35,
      L_3m: 1.1,
      H_3m: 1.35,
      L_52w: 0.04,
      H_52w: 1.35,
      cur: 1.34,
      id: "1992",
    },
    1995: {
      d_1d: 0,
      d_3d: 0.01,
      d_5d: -0.03,
      d_10d: -0.13,
      d_20d: -0.34,
      L_5d: 9.26,
      H_5d: 9.27,
      L_1m: 9.26,
      H_1m: 9.55,
      L_3m: 8.81,
      H_3m: 10.04,
      L_52w: 4.32,
      H_52w: 10.04,
      cur: 9.27,
      id: "1995",
    },
    1996: {
      d_1d: 0.02,
      d_3d: 0.04,
      d_5d: 0.13,
      d_10d: 0.73,
      d_20d: 1.12,
      L_5d: 4.91,
      H_5d: 5,
      L_1m: 3.9,
      H_1m: 5,
      L_3m: 3.46,
      H_3m: 5,
      L_52w: 2.54,
      H_52w: 5,
      cur: 5,
      id: "1996",
    },
    1997: {
      d_1d: 0,
      d_3d: 0.03,
      d_5d: 0.03,
      d_10d: 0.04,
      d_20d: 0.03,
      L_5d: 0.18,
      H_5d: 0.21,
      L_1m: 0.17,
      H_1m: 0.21,
      L_3m: 0.17,
      H_3m: 0.21,
      L_52w: 0.11,
      H_52w: 0.32,
      cur: 0.21,
      id: "1997",
    },
    1999: {
      d_1d: -0.01,
      d_3d: -0.1,
      d_5d: -0.22,
      d_10d: -0.49,
      d_20d: -0.39,
      L_5d: 23.19,
      H_5d: 23.29,
      L_1m: 23.19,
      H_1m: 23.75,
      L_3m: 23.19,
      H_3m: 23.75,
      L_52w: 20.95,
      H_52w: 26.82,
      cur: 23.19,
      id: "1999",
    },
    2001: {
      d_1d: 0.06,
      d_3d: 0.16,
      d_5d: 0.48,
      d_10d: 0.68,
      d_20d: 0.79,
      L_5d: 10.27,
      H_5d: 10.48,
      L_1m: 9.64,
      H_1m: 10.48,
      L_3m: 9.02,
      H_3m: 10.48,
      L_52w: 7.77,
      H_52w: 11.24,
      cur: 10.48,
      id: "2001",
    },
    2003: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.37,
      H_5d: 0.37,
      L_1m: 0.37,
      H_1m: 0.37,
      L_3m: 0.37,
      H_3m: 0.37,
      L_52w: 0.37,
      H_52w: 0.43,
      cur: 0.37,
      id: "2003",
    },
    2005: {
      d_1d: 0.16,
      d_3d: 0.12,
      d_5d: 0.19,
      d_10d: 0.58,
      d_20d: 0.59,
      L_5d: 11.28,
      H_5d: 11.44,
      L_1m: 10.79,
      H_1m: 11.44,
      L_3m: 10.78,
      H_3m: 11.44,
      L_52w: 9.44,
      H_52w: 12.14,
      cur: 11.44,
      id: "2005",
    },
    2007: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: -0.03,
      d_20d: 0.01,
      L_5d: 1.07,
      H_5d: 1.08,
      L_1m: 1.07,
      H_1m: 1.11,
      L_3m: 1.07,
      H_3m: 1.13,
      L_52w: 1.07,
      H_52w: 1.35,
      cur: 1.08,
      id: "2007",
    },
    2008: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 3.83,
      H_5d: 3.83,
      L_1m: 3.83,
      H_1m: 3.83,
      L_3m: 3.83,
      H_3m: 3.83,
      L_52w: 3.83,
      H_52w: 4.11,
      cur: 3.83,
      id: "2008",
    },
    2009: {
      d_1d: 0.13,
      d_3d: 0.42,
      d_5d: 0.58,
      d_10d: 0.94,
      d_20d: 2.24,
      L_5d: 17.51,
      H_5d: 18.06,
      L_1m: 15.96,
      H_1m: 18.06,
      L_3m: 13.88,
      H_3m: 18.06,
      L_52w: 13.16,
      H_52w: 18.06,
      cur: 18.06,
      id: "2009",
    },
    2013: {
      d_1d: -0.27,
      d_3d: -0.24,
      d_5d: -0.35,
      d_10d: -0.83,
      d_20d: -1.72,
      L_5d: 12.04,
      H_5d: 12.4,
      L_1m: 12.04,
      H_1m: 13.82,
      L_3m: 12.04,
      H_3m: 14.66,
      L_52w: 9.97,
      H_52w: 26.47,
      cur: 12.04,
      id: "2013",
    },
    2016: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 11.02,
      H_5d: 11.02,
      L_1m: 11.02,
      H_1m: 11.02,
      L_3m: 11.01,
      H_3m: 11.02,
      L_52w: 2.06,
      H_52w: 11.02,
      cur: 11.02,
      id: "2016",
    },
    2018: {
      d_1d: 0.09,
      d_3d: 0.13,
      d_5d: 0.17,
      d_10d: 0.43,
      d_20d: 0.66,
      L_5d: 4.81,
      H_5d: 4.97,
      L_1m: 4.31,
      H_1m: 4.97,
      L_3m: 4.28,
      H_3m: 4.97,
      L_52w: 4.28,
      H_52w: 6.23,
      cur: 4.97,
      id: "2018",
    },
    2019: {
      d_1d: 0.03,
      d_3d: 0.05,
      d_5d: 0.09,
      d_10d: 0.28,
      d_20d: 0.58,
      L_5d: 2.62,
      H_5d: 2.7,
      L_1m: 2.14,
      H_1m: 2.7,
      L_3m: 1.6,
      H_3m: 2.7,
      L_52w: 1.09,
      H_52w: 2.7,
      cur: 2.7,
      id: "2019",
    },
    2020: {
      d_1d: 0,
      d_3d: 0.02,
      d_5d: 0.01,
      d_10d: -0.3,
      d_20d: -0.19,
      L_5d: 9.72,
      H_5d: 9.79,
      L_1m: 9.72,
      H_1m: 10.09,
      L_3m: 9.72,
      H_3m: 10.42,
      L_52w: 8.18,
      H_52w: 12.84,
      cur: 9.79,
      id: "2020",
    },
    2038: {
      d_1d: 0.06,
      d_3d: 0.12,
      d_5d: 0.14,
      d_10d: 0.15,
      d_20d: 0.21,
      L_5d: 1.61,
      H_5d: 1.75,
      L_1m: 1.54,
      H_1m: 1.75,
      L_3m: 1.53,
      H_3m: 1.75,
      L_52w: 1.53,
      H_52w: 2.04,
      cur: 1.75,
      id: "2038",
    },
    2039: {
      d_1d: 0,
      d_3d: 0.01,
      d_5d: 0.01,
      d_10d: 0.01,
      d_20d: 0,
      L_5d: 12.3,
      H_5d: 12.31,
      L_1m: 12.3,
      H_1m: 12.31,
      L_3m: 12.22,
      H_3m: 12.31,
      L_52w: 11.42,
      H_52w: 12.56,
      cur: 12.31,
      id: "2039",
    },
    2048: {
      d_1d: -0.7,
      d_3d: -0.44,
      d_5d: 1.79,
      d_10d: 2.31,
      d_20d: 2.44,
      L_5d: 8.94,
      H_5d: 10.76,
      L_1m: 7.37,
      H_1m: 10.76,
      L_3m: 4.1,
      H_3m: 10.76,
      L_52w: 0.18,
      H_52w: 10.76,
      cur: 10.06,
      id: "2048",
    },
    2051: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 1.28,
      H_5d: 1.28,
      L_1m: 1.28,
      H_1m: 1.28,
      L_3m: 1.28,
      H_3m: 1.3,
      L_52w: 1.28,
      H_52w: 3.36,
      cur: 1.28,
      id: "2051",
    },
    2066: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.01,
      H_5d: 0.01,
      L_1m: 0.01,
      H_1m: 0.01,
      L_3m: 0.01,
      H_3m: 0.01,
      L_52w: 0.01,
      H_52w: 0.01,
      cur: 0.01,
      id: "2066",
    },
    2068: {
      d_1d: -0.01,
      d_3d: 0.07,
      d_5d: 0.04,
      d_10d: 0.05,
      d_20d: 0.39,
      L_5d: 6.63,
      H_5d: 6.71,
      L_1m: 6.13,
      H_1m: 6.71,
      L_3m: 4.65,
      H_3m: 6.71,
      L_52w: 2.61,
      H_52w: 6.71,
      cur: 6.7,
      id: "2068",
    },
    2096: {
      d_1d: 0.01,
      d_3d: 0.1,
      d_5d: 0.12,
      d_10d: 0.15,
      d_20d: 0.05,
      L_5d: 2.6,
      H_5d: 2.71,
      L_1m: 2.54,
      H_1m: 2.71,
      L_3m: 2.26,
      H_3m: 2.71,
      L_52w: 0,
      H_52w: 2.71,
      cur: 2.71,
      id: "2096",
    },
    2098: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.27,
      H_5d: 0.27,
      L_1m: 0.27,
      H_1m: 0.27,
      L_3m: 0.27,
      H_3m: 0.27,
      L_52w: 0.27,
      H_52w: 0.27,
      cur: 0.27,
      id: "2098",
    },
    2099: {
      d_1d: 0,
      d_3d: -0.02,
      d_5d: -0.02,
      d_10d: -0.03,
      d_20d: -0.04,
      L_5d: 1.55,
      H_5d: 1.57,
      L_1m: 1.55,
      H_1m: 1.59,
      L_3m: 1.55,
      H_3m: 1.64,
      L_52w: 1.55,
      H_52w: 3.11,
      cur: 1.55,
      id: "2099",
    },
    2103: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.01,
      H_5d: 0.01,
      L_1m: 0.01,
      H_1m: 0.01,
      L_3m: 0.01,
      H_3m: 0.01,
      L_52w: 0.01,
      H_52w: 0.01,
      cur: 0.01,
      id: "2103",
    },
    2111: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.09,
      H_5d: 0.09,
      L_1m: 0.09,
      H_1m: 0.09,
      L_3m: 0.09,
      H_3m: 0.09,
      L_52w: 0.09,
      H_52w: 0.13,
      cur: 0.09,
      id: "2111",
    },
    2126: {
      d_1d: 0.15,
      d_3d: 0.28,
      d_5d: 0.54,
      d_10d: 0.95,
      d_20d: 1.62,
      L_5d: 7.75,
      H_5d: 8.28,
      L_1m: 6.67,
      H_1m: 8.28,
      L_3m: 5.51,
      H_3m: 8.28,
      L_52w: 0,
      H_52w: 8.28,
      cur: 8.28,
      id: "2126",
    },
    2128: {
      d_1d: -0.01,
      d_3d: -0.01,
      d_5d: -0.01,
      d_10d: -0.09,
      d_20d: -0.24,
      L_5d: 0.67,
      H_5d: 0.68,
      L_1m: 0.67,
      H_1m: 0.9,
      L_3m: 0.67,
      H_3m: 1.1,
      L_52w: 0.67,
      H_52w: 2.77,
      cur: 0.67,
      id: "2128",
    },
    2136: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.02,
      H_5d: 0.02,
      L_1m: 0.02,
      H_1m: 0.02,
      L_3m: 0.02,
      H_3m: 0.02,
      L_52w: 0.02,
      H_52w: 0.02,
      cur: 0.02,
      id: "2136",
    },
    2142: {
      d_1d: 0.02,
      d_3d: 0.16,
      d_5d: 0.35,
      d_10d: 1.65,
      d_20d: 3.13,
      L_5d: 4.53,
      H_5d: 4.79,
      L_1m: 1.68,
      H_1m: 4.79,
      L_3m: 1.11,
      H_3m: 4.79,
      L_52w: 0,
      H_52w: 4.79,
      cur: 4.79,
      id: "2142",
    },
    2150: {
      d_1d: 0.02,
      d_3d: 0.05,
      d_5d: 0.08,
      d_10d: 0.32,
      d_20d: 0.43,
      L_5d: 1.56,
      H_5d: 1.64,
      L_1m: 1.26,
      H_1m: 1.64,
      L_3m: 0.03,
      H_3m: 1.64,
      L_52w: 0.03,
      H_52w: 1.64,
      cur: 1.64,
      id: "2150",
    },
    2156: {
      d_1d: 0,
      d_3d: 0,
      d_5d: -0.01,
      d_10d: -0.03,
      d_20d: -0.07,
      L_5d: 1.59,
      H_5d: 1.6,
      L_1m: 1.59,
      H_1m: 1.66,
      L_3m: 1.59,
      H_3m: 1.76,
      L_52w: 1.59,
      H_52w: 5.36,
      cur: 1.59,
      id: "2156",
    },
    2158: {
      d_1d: 0.01,
      d_3d: 0.05,
      d_5d: 0.08,
      d_10d: 0.13,
      d_20d: 0.13,
      L_5d: 0.39,
      H_5d: 0.46,
      L_1m: 0.33,
      H_1m: 0.46,
      L_3m: 0.26,
      H_3m: 0.46,
      L_52w: 0,
      H_52w: 0.46,
      cur: 0.46,
      id: "2158",
    },
    2160: {
      d_1d: 0.01,
      d_3d: 0.08,
      d_5d: 0.16,
      d_10d: 0.21,
      d_20d: 0.41,
      L_5d: 2.46,
      H_5d: 2.61,
      L_1m: 2.31,
      H_1m: 2.61,
      L_3m: 1.14,
      H_3m: 2.61,
      L_52w: 0,
      H_52w: 2.61,
      cur: 2.61,
      id: "2160",
    },
    2171: {
      d_1d: 0.06,
      d_3d: 0.12,
      d_5d: 0.14,
      d_10d: 0.29,
      d_20d: 0.75,
      L_5d: 1.24,
      H_5d: 1.36,
      L_1m: 0.62,
      H_1m: 1.36,
      L_3m: 0.04,
      H_3m: 1.36,
      L_52w: 0.04,
      H_52w: 1.36,
      cur: 1.36,
      id: "2171",
    },
    2186: {
      d_1d: 0.04,
      d_3d: 0.19,
      d_5d: 0.11,
      d_10d: 0.18,
      d_20d: 0.27,
      L_5d: 14.88,
      H_5d: 15.07,
      L_1m: 14.82,
      H_1m: 15.07,
      L_3m: 14.28,
      H_3m: 15.07,
      L_52w: 13.69,
      H_52w: 15.65,
      cur: 15.07,
      id: "2186",
    },
    2196: {
      d_1d: -0.02,
      d_3d: 0.26,
      d_5d: 0.24,
      d_10d: 0.23,
      d_20d: 0.33,
      L_5d: 29.31,
      H_5d: 29.6,
      L_1m: 29.11,
      H_1m: 29.6,
      L_3m: 29.09,
      H_3m: 30.19,
      L_52w: 29.09,
      H_52w: 41.23,
      cur: 29.58,
      id: "2196",
    },
    2199: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: -0.03,
      d_20d: -0.03,
      L_5d: 0.2,
      H_5d: 0.2,
      L_1m: 0.2,
      H_1m: 0.23,
      L_3m: 0.2,
      H_3m: 0.23,
      L_52w: 0.2,
      H_52w: 0.24,
      cur: 0.2,
      id: "2199",
    },
    2202: {
      d_1d: 0.01,
      d_3d: 0.07,
      d_5d: 0.04,
      d_10d: -0.09,
      d_20d: 0.07,
      L_5d: 14.83,
      H_5d: 14.9,
      L_1m: 14.5,
      H_1m: 14.99,
      L_3m: 14.38,
      H_3m: 14.99,
      L_52w: 13.89,
      H_52w: 16.4,
      cur: 14.9,
      id: "2202",
    },
    2208: {
      d_1d: 0.01,
      d_3d: -0.03,
      d_5d: 0,
      d_10d: 0.02,
      d_20d: 0.5,
      L_5d: 3.41,
      H_5d: 3.46,
      L_1m: 3,
      H_1m: 3.52,
      L_3m: 2.89,
      H_3m: 4.02,
      L_52w: 1.44,
      H_52w: 4.94,
      cur: 3.43,
      id: "2208",
    },
    2218: {
      d_1d: 0,
      d_3d: -0.08,
      d_5d: -0.18,
      d_10d: -0.07,
      d_20d: -0.56,
      L_5d: 3.49,
      H_5d: 3.66,
      L_1m: 3.49,
      H_1m: 4.06,
      L_3m: 3.49,
      H_3m: 4.07,
      L_52w: 0.61,
      H_52w: 5.04,
      cur: 3.49,
      id: "2218",
    },
    2232: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: -0.01,
      L_5d: 0.09,
      H_5d: 0.09,
      L_1m: 0.09,
      H_1m: 0.1,
      L_3m: 0.09,
      H_3m: 0.1,
      L_52w: 0.09,
      H_52w: 0.12,
      cur: 0.09,
      id: "2232",
    },
    2233: {
      d_1d: 0,
      d_3d: 0.01,
      d_5d: 0,
      d_10d: -0.21,
      d_20d: 0.05,
      L_5d: 9.21,
      H_5d: 9.23,
      L_1m: 9.16,
      H_1m: 9.46,
      L_3m: 8.85,
      H_3m: 9.46,
      L_52w: 8.73,
      H_52w: 11.62,
      cur: 9.22,
      id: "2233",
    },
    2238: {
      d_1d: -0.25,
      d_3d: -0.12,
      d_5d: -0.16,
      d_10d: -0.06,
      d_20d: -0.52,
      L_5d: 29.53,
      H_5d: 29.78,
      L_1m: 29.53,
      H_1m: 30.24,
      L_3m: 28.64,
      H_3m: 30.44,
      L_52w: 16.06,
      H_52w: 31.84,
      cur: 29.53,
      id: "2238",
    },
    2255: {
      d_1d: 0,
      d_3d: -0.02,
      d_5d: -0.03,
      d_10d: -0.25,
      d_20d: -0.32,
      L_5d: 0.49,
      H_5d: 0.52,
      L_1m: 0.49,
      H_1m: 0.81,
      L_3m: 0.49,
      H_3m: 2.05,
      L_52w: 0.49,
      H_52w: 7.74,
      cur: 0.49,
      id: "2255",
    },
    2269: {
      d_1d: -0.01,
      d_3d: -0.02,
      d_5d: 0.02,
      d_10d: -0.36,
      d_20d: -0.75,
      L_5d: 7.28,
      H_5d: 7.32,
      L_1m: 7.27,
      H_1m: 7.83,
      L_3m: 7.27,
      H_3m: 9.11,
      L_52w: 2.36,
      H_52w: 10.11,
      cur: 7.29,
      id: "2269",
    },
    2280: {
      d_1d: 0,
      d_3d: -0.01,
      d_5d: -0.01,
      d_10d: -0.01,
      d_20d: -0.02,
      L_5d: 5.46,
      H_5d: 5.47,
      L_1m: 5.46,
      H_1m: 5.48,
      L_3m: 5.46,
      H_3m: 5.55,
      L_52w: 5.46,
      H_52w: 6.82,
      cur: 5.46,
      id: "2280",
    },
    2282: {
      d_1d: 0.04,
      d_3d: 0.17,
      d_5d: 0.17,
      d_10d: 0.23,
      d_20d: 0.33,
      L_5d: 0.57,
      H_5d: 0.76,
      L_1m: 0.43,
      H_1m: 0.76,
      L_3m: 0.26,
      H_3m: 0.76,
      L_52w: 0.17,
      H_52w: 0.76,
      cur: 0.76,
      id: "2282",
    },
    2298: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.39,
      H_5d: 0.39,
      L_1m: 0.39,
      H_1m: 0.39,
      L_3m: 0.39,
      H_3m: 0.39,
      L_52w: 0.39,
      H_52w: 0.42,
      cur: 0.39,
      id: "2298",
    },
    2313: {
      d_1d: 0,
      d_3d: -0.04,
      d_5d: 0.01,
      d_10d: 0.09,
      d_20d: 0.2,
      L_5d: 3.95,
      H_5d: 3.99,
      L_1m: 3.76,
      H_1m: 3.99,
      L_3m: 3.57,
      H_3m: 3.99,
      L_52w: 3.57,
      H_52w: 5.51,
      cur: 3.95,
      id: "2313",
    },
    2314: {
      d_1d: 0.01,
      d_3d: 0.04,
      d_5d: 0.04,
      d_10d: 0.09,
      d_20d: 0.1,
      L_5d: 0.38,
      H_5d: 0.42,
      L_1m: 0.32,
      H_1m: 0.42,
      L_3m: 0.29,
      H_3m: 0.42,
      L_52w: 0.27,
      H_52w: 0.64,
      cur: 0.42,
      id: "2314",
    },
    2318: {
      d_1d: 0.02,
      d_3d: 0.04,
      d_5d: 0.04,
      d_10d: -0.01,
      d_20d: -0.08,
      L_5d: 0.85,
      H_5d: 0.91,
      L_1m: 0.85,
      H_1m: 1.08,
      L_3m: 0.77,
      H_3m: 1.08,
      L_52w: 0.77,
      H_52w: 2.7,
      cur: 0.91,
      id: "2318",
    },
    2319: {
      d_1d: -0.02,
      d_3d: -0.03,
      d_5d: -0.04,
      d_10d: 0.01,
      d_20d: 0.11,
      L_5d: 3.54,
      H_5d: 3.59,
      L_1m: 3.39,
      H_1m: 3.59,
      L_3m: 3.29,
      H_3m: 3.68,
      L_52w: 1.88,
      H_52w: 5.14,
      cur: 3.54,
      id: "2319",
    },
    2326: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.26,
      H_5d: 0.26,
      L_1m: 0.26,
      H_1m: 0.26,
      L_3m: 0.26,
      H_3m: 0.26,
      L_52w: 0.26,
      H_52w: 0.27,
      cur: 0.26,
      id: "2326",
    },
    2328: {
      d_1d: 0.04,
      d_3d: 0.05,
      d_5d: 0.17,
      d_10d: 0.39,
      d_20d: 0.35,
      L_5d: 8.73,
      H_5d: 8.84,
      L_1m: 8.37,
      H_1m: 8.84,
      L_3m: 7.31,
      H_3m: 8.84,
      L_52w: 7.24,
      H_52w: 9.03,
      cur: 8.84,
      id: "2328",
    },
    2329: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: -0.01,
      L_5d: 3.65,
      H_5d: 3.65,
      L_1m: 3.65,
      H_1m: 3.66,
      L_3m: 3.65,
      H_3m: 3.67,
      L_52w: 3.47,
      H_52w: 3.93,
      cur: 3.65,
      id: "2329",
    },
    2331: {
      d_1d: 0.06,
      d_3d: 0.04,
      d_5d: 0.03,
      d_10d: -0.25,
      d_20d: -0.5,
      L_5d: 9.65,
      H_5d: 9.72,
      L_1m: 9.65,
      H_1m: 10.19,
      L_3m: 9.65,
      H_3m: 11.46,
      L_52w: 5.51,
      H_52w: 14.41,
      cur: 9.72,
      id: "2331",
    },
    2333: {
      d_1d: -0.02,
      d_3d: 0.06,
      d_5d: 0.04,
      d_10d: -0.06,
      d_20d: 0.16,
      L_5d: 48.84,
      H_5d: 48.95,
      L_1m: 48.82,
      H_1m: 48.99,
      L_3m: 47.73,
      H_3m: 48.99,
      L_52w: 36.72,
      H_52w: 49.37,
      cur: 48.93,
      id: "2333",
    },
    2338: {
      d_1d: 0.01,
      d_3d: -0.02,
      d_5d: -0.03,
      d_10d: 0.04,
      d_20d: -0.04,
      L_5d: 4.38,
      H_5d: 4.41,
      L_1m: 4.27,
      H_1m: 4.44,
      L_3m: 3.29,
      H_3m: 4.44,
      L_52w: 1.5,
      H_52w: 4.44,
      cur: 4.39,
      id: "2338",
    },
    2342: {
      d_1d: 0.01,
      d_3d: 0.06,
      d_5d: 0.13,
      d_10d: 0.13,
      d_20d: 0.14,
      L_5d: 1.53,
      H_5d: 1.65,
      L_1m: 1.5,
      H_1m: 1.65,
      L_3m: 1.39,
      H_3m: 1.65,
      L_52w: 0.14,
      H_52w: 1.65,
      cur: 1.65,
      id: "2342",
    },
    2343: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: -0.1,
      d_20d: -0.14,
      L_5d: 0.32,
      H_5d: 0.32,
      L_1m: 0.32,
      H_1m: 0.46,
      L_3m: 0.32,
      H_3m: 0.86,
      L_52w: 0.15,
      H_52w: 0.99,
      cur: 0.32,
      id: "2343",
    },
    2356: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0.01,
      d_20d: 0.01,
      L_5d: 0.03,
      H_5d: 0.03,
      L_1m: 0.02,
      H_1m: 0.05,
      L_3m: 0.02,
      H_3m: 0.05,
      L_52w: 0.02,
      H_52w: 0.09,
      cur: 0.03,
      id: "2356",
    },
    2357: {
      d_1d: -0.03,
      d_3d: -0.05,
      d_5d: -0.02,
      d_10d: -0.06,
      d_20d: -0.03,
      L_5d: 11.47,
      H_5d: 11.54,
      L_1m: 11.47,
      H_1m: 11.73,
      L_3m: 11,
      H_3m: 11.82,
      L_52w: 8.1,
      H_52w: 12.91,
      cur: 11.47,
      id: "2357",
    },
    2359: {
      d_1d: 0.01,
      d_3d: 0.04,
      d_5d: 0.05,
      d_10d: -0.29,
      d_20d: -0.39,
      L_5d: 8.54,
      H_5d: 8.6,
      L_1m: 8.54,
      H_1m: 9.01,
      L_3m: 8.54,
      H_3m: 10.85,
      L_52w: 6.68,
      H_52w: 11.95,
      cur: 8.6,
      id: "2359",
    },
    2362: {
      d_1d: -0.01,
      d_3d: -0.05,
      d_5d: -0.03,
      d_10d: -0.01,
      d_20d: -0.05,
      L_5d: 0.29,
      H_5d: 0.34,
      L_1m: 0.29,
      H_1m: 0.34,
      L_3m: 0.12,
      H_3m: 0.38,
      L_52w: 0.12,
      H_52w: 0.48,
      cur: 0.29,
      id: "2362",
    },
    2363: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.14,
      H_5d: 0.14,
      L_1m: 0.14,
      H_1m: 0.14,
      L_3m: 0.14,
      H_3m: 0.14,
      L_52w: 0.14,
      H_52w: 0.18,
      cur: 0.14,
      id: "2363",
    },
    2369: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.2,
      H_5d: 0.2,
      L_1m: 0.2,
      H_1m: 0.2,
      L_3m: 0.2,
      H_3m: 0.24,
      L_52w: 0.2,
      H_52w: 0.56,
      cur: 0.2,
      id: "2369",
    },
    2380: {
      d_1d: 0.42,
      d_3d: 0.88,
      d_5d: 1.09,
      d_10d: 1.33,
      d_20d: 1.34,
      L_5d: 16.82,
      H_5d: 17.7,
      L_1m: 16.34,
      H_1m: 17.7,
      L_3m: 9.6,
      H_3m: 17.7,
      L_52w: 4.15,
      H_52w: 17.7,
      cur: 17.7,
      id: "2380",
    },
    2382: {
      d_1d: -0.02,
      d_3d: -0.04,
      d_5d: -0.05,
      d_10d: -0.15,
      d_20d: -0.57,
      L_5d: 18.43,
      H_5d: 18.49,
      L_1m: 18.43,
      H_1m: 19.19,
      L_3m: 18.24,
      H_3m: 19.19,
      L_52w: 12.73,
      H_52w: 19.19,
      cur: 18.43,
      id: "2382",
    },
    2386: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: -0.01,
      d_20d: -0.01,
      L_5d: 0.91,
      H_5d: 0.91,
      L_1m: 0.91,
      H_1m: 0.92,
      L_3m: 0.91,
      H_3m: 1.1,
      L_52w: 0.91,
      H_52w: 3.04,
      cur: 0.91,
      id: "2386",
    },
    2388: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0.01,
      d_20d: 0.04,
      L_5d: 0.23,
      H_5d: 0.23,
      L_1m: 0.19,
      H_1m: 0.23,
      L_3m: 0.15,
      H_3m: 0.23,
      L_52w: 0.12,
      H_52w: 0.25,
      cur: 0.23,
      id: "2388",
    },
    2400: {
      d_1d: -0.01,
      d_3d: 0.05,
      d_5d: 0.01,
      d_10d: 0.35,
      d_20d: -0.08,
      L_5d: 18.14,
      H_5d: 18.26,
      L_1m: 17.79,
      H_1m: 18.44,
      L_3m: 17.54,
      H_3m: 18.47,
      L_52w: 13.28,
      H_52w: 18.51,
      cur: 18.25,
      id: "2400",
    },
    2500: {
      d_1d: 0.03,
      d_3d: 0.06,
      d_5d: -0.09,
      d_10d: -0.19,
      d_20d: 0.54,
      L_5d: 7.21,
      H_5d: 7.32,
      L_1m: 6.72,
      H_1m: 7.48,
      L_3m: 5.74,
      H_3m: 7.48,
      L_52w: 0,
      H_52w: 7.48,
      cur: 7.27,
      id: "2500",
    },
    2588: {
      d_1d: 0,
      d_3d: 0.08,
      d_5d: 0.13,
      d_10d: 0.17,
      d_20d: 0.21,
      L_5d: 1.46,
      H_5d: 1.54,
      L_1m: 1.33,
      H_1m: 1.54,
      L_3m: 1.28,
      H_3m: 1.54,
      L_52w: 1.21,
      H_52w: 1.54,
      cur: 1.54,
      id: "2588",
    },
    2599: {
      d_1d: 0.05,
      d_3d: 0.08,
      d_5d: 0.57,
      d_10d: 3,
      d_20d: 3.09,
      L_5d: 2.84,
      H_5d: 3.09,
      L_1m: 0,
      H_1m: 3.09,
      L_3m: 0,
      H_3m: 3.09,
      L_52w: 0,
      H_52w: 3.09,
      cur: 3.09,
      id: "2599",
    },
    2600: {
      d_1d: 0.07,
      d_3d: 0.04,
      d_5d: 0.05,
      d_10d: 0.12,
      d_20d: -0.37,
      L_5d: 6.76,
      H_5d: 6.93,
      L_1m: 6.76,
      H_1m: 7.22,
      L_3m: 6.76,
      H_3m: 12.77,
      L_52w: 4.84,
      H_52w: 12.77,
      cur: 6.93,
      id: "2600",
    },
    2601: {
      d_1d: -0.07,
      d_3d: -0.22,
      d_5d: -0.24,
      d_10d: -0.18,
      d_20d: 0.12,
      L_5d: 26.73,
      H_5d: 26.95,
      L_1m: 26.66,
      H_1m: 26.97,
      L_3m: 25.23,
      H_3m: 26.97,
      L_52w: 25.19,
      H_52w: 29.94,
      cur: 26.73,
      id: "2601",
    },
    2607: {
      d_1d: 0.09,
      d_3d: 0.2,
      d_5d: 0.17,
      d_10d: 0.23,
      d_20d: 0.25,
      L_5d: 12.22,
      H_5d: 12.42,
      L_1m: 12.16,
      H_1m: 12.42,
      L_3m: 11.34,
      H_3m: 12.42,
      L_52w: 10.63,
      H_52w: 12.69,
      cur: 12.42,
      id: "2607",
    },
    2611: {
      d_1d: 0.01,
      d_3d: 0.12,
      d_5d: 0.14,
      d_10d: 0.18,
      d_20d: 0.39,
      L_5d: 23.65,
      H_5d: 23.78,
      L_1m: 23.44,
      H_1m: 23.78,
      L_3m: 23.03,
      H_3m: 23.78,
      L_52w: 13.55,
      H_52w: 23.78,
      cur: 23.78,
      id: "2611",
    },
    2616: {
      d_1d: 0.01,
      d_3d: -0.01,
      d_5d: 0.12,
      d_10d: 0.38,
      d_20d: 0.66,
      L_5d: 1.69,
      H_5d: 1.74,
      L_1m: 1.11,
      H_1m: 1.74,
      L_3m: 0.12,
      H_3m: 1.74,
      L_52w: 0.12,
      H_52w: 1.74,
      cur: 1.73,
      id: "2616",
    },
    2618: {
      d_1d: 0.02,
      d_3d: 0.06,
      d_5d: 0.08,
      d_10d: 0.14,
      d_20d: 0.16,
      L_5d: 0.33,
      H_5d: 0.4,
      L_1m: 0.23,
      H_1m: 0.4,
      L_3m: 0.22,
      H_3m: 0.4,
      L_52w: 0,
      H_52w: 0.4,
      cur: 0.4,
      id: "2618",
    },
    2628: {
      d_1d: 0,
      d_3d: 0.02,
      d_5d: 0.03,
      d_10d: 0.03,
      d_20d: 0.23,
      L_5d: 4.59,
      H_5d: 4.61,
      L_1m: 4.43,
      H_1m: 4.61,
      L_3m: 2.8,
      H_3m: 4.61,
      L_52w: 2.8,
      H_52w: 4.93,
      cur: 4.61,
      id: "2628",
    },
    2666: {
      d_1d: -0.01,
      d_3d: -0.01,
      d_5d: 0,
      d_10d: -0.01,
      d_20d: -0.14,
      L_5d: 13.67,
      H_5d: 13.69,
      L_1m: 13.65,
      H_1m: 13.82,
      L_3m: 13.5,
      H_3m: 13.95,
      L_52w: 13.5,
      H_52w: 18.18,
      cur: 13.67,
      id: "2666",
    },
    2669: {
      d_1d: -0.17,
      d_3d: -0.3,
      d_5d: -0.29,
      d_10d: -0.45,
      d_20d: -0.55,
      L_5d: 15.29,
      H_5d: 15.6,
      L_1m: 15.29,
      H_1m: 15.98,
      L_3m: 14.83,
      H_3m: 15.98,
      L_52w: 11.56,
      H_52w: 15.98,
      cur: 15.29,
      id: "2669",
    },
    2678: {
      d_1d: -0.04,
      d_3d: -0.05,
      d_5d: -0.06,
      d_10d: -0.09,
      d_20d: -0.34,
      L_5d: 2.2,
      H_5d: 2.26,
      L_1m: 2.2,
      H_1m: 2.53,
      L_3m: 2.2,
      H_3m: 2.91,
      L_52w: 2.2,
      H_52w: 3.89,
      cur: 2.2,
      id: "2678",
    },
    2688: {
      d_1d: 0.01,
      d_3d: 0.01,
      d_5d: 0.01,
      d_10d: 0.01,
      d_20d: 0.04,
      L_5d: 0.66,
      H_5d: 0.68,
      L_1m: 0.64,
      H_1m: 0.69,
      L_3m: 0.53,
      H_3m: 0.69,
      L_52w: 0.53,
      H_52w: 1.37,
      cur: 0.68,
      id: "2688",
    },
    2689: {
      d_1d: 0.01,
      d_3d: 0.01,
      d_5d: 0,
      d_10d: -0.04,
      d_20d: -0.02,
      L_5d: 1.69,
      H_5d: 1.71,
      L_1m: 1.69,
      H_1m: 1.76,
      L_3m: 1.65,
      H_3m: 1.86,
      L_52w: 1.47,
      H_52w: 2.7,
      cur: 1.71,
      id: "2689",
    },
    2727: {
      d_1d: 0.02,
      d_3d: -0.05,
      d_5d: -0.09,
      d_10d: -0.25,
      d_20d: -0.64,
      L_5d: 32.63,
      H_5d: 32.7,
      L_1m: 32.63,
      H_1m: 32.99,
      L_3m: 32.63,
      H_3m: 33.66,
      L_52w: 29.36,
      H_52w: 33.94,
      cur: 32.65,
      id: "2727",
    },
    2768: {
      d_1d: -0.01,
      d_3d: -0.01,
      d_5d: -0.01,
      d_10d: -0.01,
      d_20d: -0.02,
      L_5d: 8.1,
      H_5d: 8.11,
      L_1m: 8.1,
      H_1m: 8.12,
      L_3m: 8.1,
      H_3m: 8.29,
      L_52w: 7.97,
      H_52w: 9.68,
      cur: 8.1,
      id: "2768",
    },
    2772: {
      d_1d: 0,
      d_3d: 0.01,
      d_5d: 0.01,
      d_10d: 0,
      d_20d: 0,
      L_5d: 1.33,
      H_5d: 1.34,
      L_1m: 1.33,
      H_1m: 1.35,
      L_3m: 1.22,
      H_3m: 1.35,
      L_52w: 1.16,
      H_52w: 1.55,
      cur: 1.34,
      id: "2772",
    },
    2777: {
      d_1d: -0.05,
      d_3d: -0.19,
      d_5d: -0.34,
      d_10d: -0.7,
      d_20d: -0.48,
      L_5d: 2.04,
      H_5d: 2.29,
      L_1m: 2.04,
      H_1m: 2.76,
      L_3m: 2.04,
      H_3m: 2.76,
      L_52w: 1.79,
      H_52w: 2.76,
      cur: 2.04,
      id: "2777",
    },
    2798: {
      d_1d: 0.07,
      d_3d: 0.14,
      d_5d: 0.38,
      d_10d: 0.26,
      d_20d: 0.07,
      L_5d: 6.92,
      H_5d: 7.14,
      L_1m: 6.69,
      H_1m: 7.14,
      L_3m: 4.86,
      H_3m: 7.14,
      L_52w: 0,
      H_52w: 7.14,
      cur: 7.14,
      id: "2798",
    },
    2799: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 9.12,
      H_5d: 9.12,
      L_1m: 9.12,
      H_1m: 9.12,
      L_3m: 9.12,
      H_3m: 9.12,
      L_52w: 9.12,
      H_52w: 9.48,
      cur: 9.12,
      id: "2799",
    },
    2858: {
      d_1d: 0,
      d_3d: 0.01,
      d_5d: 0.01,
      d_10d: 0.03,
      d_20d: 0.08,
      L_5d: 0.91,
      H_5d: 0.92,
      L_1m: 0.89,
      H_1m: 0.97,
      L_3m: 0.79,
      H_3m: 0.97,
      L_52w: 0.63,
      H_52w: 0.97,
      cur: 0.92,
      id: "2858",
    },
    2866: {
      d_1d: -0.03,
      d_3d: -0.07,
      d_5d: -0.05,
      d_10d: -0.2,
      d_20d: 0.11,
      L_5d: 22.45,
      H_5d: 22.52,
      L_1m: 22.33,
      H_1m: 22.65,
      L_3m: 20.3,
      H_3m: 22.65,
      L_52w: 17.88,
      H_52w: 23.07,
      cur: 22.45,
      id: "2866",
    },
    2869: {
      d_1d: 0.08,
      d_3d: 0.17,
      d_5d: 0.19,
      d_10d: 0.23,
      d_20d: 0.26,
      L_5d: 7.62,
      H_5d: 7.81,
      L_1m: 7.41,
      H_1m: 7.81,
      L_3m: 7.41,
      H_3m: 8.1,
      L_52w: 7.41,
      H_52w: 11.45,
      cur: 7.81,
      id: "2869",
    },
    2877: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 1.21,
      H_5d: 1.21,
      L_1m: 1.21,
      H_1m: 1.21,
      L_3m: 1.21,
      H_3m: 1.25,
      L_52w: 1.21,
      H_52w: 3.25,
      cur: 1.21,
      id: "2877",
    },
    2880: {
      d_1d: 0,
      d_3d: 0.02,
      d_5d: 0.02,
      d_10d: 0.03,
      d_20d: 0.05,
      L_5d: 17.54,
      H_5d: 17.56,
      L_1m: 17.51,
      H_1m: 17.56,
      L_3m: 17.43,
      H_3m: 17.56,
      L_52w: 17.01,
      H_52w: 17.56,
      cur: 17.56,
      id: "2880",
    },
    2883: {
      d_1d: -0.03,
      d_3d: -0.2,
      d_5d: -0.35,
      d_10d: -0.22,
      d_20d: -1.63,
      L_5d: 21.74,
      H_5d: 22,
      L_1m: 21.74,
      H_1m: 23.31,
      L_3m: 21.74,
      H_3m: 26.72,
      L_52w: 16.56,
      H_52w: 42.69,
      cur: 21.74,
      id: "2883",
    },
    2888: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0,
      H_5d: 0,
      L_1m: 0,
      H_1m: 0,
      L_3m: 0,
      H_3m: 0,
      L_52w: 0,
      H_52w: 0.01,
      cur: 0,
      id: "2888",
    },
    2899: {
      d_1d: 0.02,
      d_3d: -0.02,
      d_5d: -0.01,
      d_10d: -0.12,
      d_20d: -0.07,
      L_5d: 18.66,
      H_5d: 18.75,
      L_1m: 18.66,
      H_1m: 18.8,
      L_3m: 18.66,
      H_3m: 19.44,
      L_52w: 18.66,
      H_52w: 31.62,
      cur: 18.68,
      id: "2899",
    },
    3301: {
      d_1d: 0.02,
      d_3d: 0.02,
      d_5d: 0.01,
      d_10d: 0.01,
      d_20d: 0.28,
      L_5d: 6.75,
      H_5d: 6.77,
      L_1m: 6.53,
      H_1m: 6.77,
      L_3m: 6.45,
      H_3m: 6.97,
      L_52w: 6.45,
      H_52w: 7.91,
      cur: 6.77,
      id: "3301",
    },
    3306: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.95,
      H_5d: 0.95,
      L_1m: 0.95,
      H_1m: 0.95,
      L_3m: 0.95,
      H_3m: 1.08,
      L_52w: 0.95,
      H_52w: 4.07,
      cur: 0.95,
      id: "3306",
    },
    3308: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 5.14,
      H_5d: 5.14,
      L_1m: 5.14,
      H_1m: 5.14,
      L_3m: 5.14,
      H_3m: 5.14,
      L_52w: 5.14,
      H_52w: 5.17,
      cur: 5.14,
      id: "3308",
    },
    3309: {
      d_1d: -0.02,
      d_3d: 0.05,
      d_5d: 0.05,
      d_10d: -0.02,
      d_20d: -0.1,
      L_5d: 12.25,
      H_5d: 12.34,
      L_1m: 12.25,
      H_1m: 12.46,
      L_3m: 12.25,
      H_3m: 13.39,
      L_52w: 6.96,
      H_52w: 13.39,
      cur: 12.32,
      id: "3309",
    },
    3311: {
      d_1d: -0.01,
      d_3d: 0,
      d_5d: 0.01,
      d_10d: 0.01,
      d_20d: 0,
      L_5d: 7.25,
      H_5d: 7.27,
      L_1m: 7.2,
      H_1m: 7.27,
      L_3m: 7.04,
      H_3m: 7.27,
      L_52w: 3.89,
      H_52w: 7.33,
      cur: 7.26,
      id: "3311",
    },
    3319: {
      d_1d: 0.01,
      d_3d: 0.02,
      d_5d: 0.03,
      d_10d: 0.07,
      d_20d: 0.05,
      L_5d: 0.23,
      H_5d: 0.25,
      L_1m: 0.18,
      H_1m: 0.25,
      L_3m: 0.18,
      H_3m: 0.31,
      L_52w: 0.18,
      H_52w: 0.74,
      cur: 0.25,
      id: "3319",
    },
    3320: {
      d_1d: 0.09,
      d_3d: 0.24,
      d_5d: 0.45,
      d_10d: 0.88,
      d_20d: 1,
      L_5d: 3.55,
      H_5d: 3.91,
      L_1m: 2.92,
      H_1m: 3.91,
      L_3m: 2.47,
      H_3m: 3.91,
      L_52w: 2.35,
      H_52w: 3.91,
      cur: 3.91,
      id: "3320",
    },
    3323: {
      d_1d: 0.09,
      d_3d: 0.16,
      d_5d: 0.12,
      d_10d: 0.23,
      d_20d: 0.05,
      L_5d: 18.7,
      H_5d: 18.9,
      L_1m: 18.6,
      H_1m: 18.9,
      L_3m: 18.6,
      H_3m: 19.56,
      L_52w: 18.6,
      H_52w: 29,
      cur: 18.9,
      id: "3323",
    },
    3328: {
      d_1d: 0,
      d_3d: -0.01,
      d_5d: -0.01,
      d_10d: -0.02,
      d_20d: -0.01,
      L_5d: 2.07,
      H_5d: 2.08,
      L_1m: 2.07,
      H_1m: 2.09,
      L_3m: 2.07,
      H_3m: 2.12,
      L_52w: 2.07,
      H_52w: 2.48,
      cur: 2.07,
      id: "3328",
    },
    3329: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.13,
      H_5d: 0.13,
      L_1m: 0.13,
      H_1m: 0.13,
      L_3m: 0.13,
      H_3m: 0.14,
      L_52w: 0.13,
      H_52w: 0.55,
      cur: 0.13,
      id: "3329",
    },
    3331: {
      d_1d: 0.01,
      d_3d: 0.01,
      d_5d: 0,
      d_10d: -0.02,
      d_20d: -0.04,
      L_5d: 1.71,
      H_5d: 1.72,
      L_1m: 1.71,
      H_1m: 1.77,
      L_3m: 1.66,
      H_3m: 1.77,
      L_52w: 1.36,
      H_52w: 2.07,
      cur: 1.72,
      id: "3331",
    },
    3333: {
      d_1d: 0.08,
      d_3d: 0.57,
      d_5d: 1.09,
      d_10d: 1.33,
      d_20d: 1.68,
      L_5d: 6.55,
      H_5d: 7.48,
      L_1m: 5.85,
      H_1m: 7.48,
      L_3m: 3.23,
      H_3m: 7.48,
      L_52w: 0.96,
      H_52w: 7.48,
      cur: 7.48,
      id: "3333",
    },
    3339: {
      d_1d: 0,
      d_3d: 0.02,
      d_5d: 0.05,
      d_10d: 0.07,
      d_20d: 0.1,
      L_5d: 1.03,
      H_5d: 1.07,
      L_1m: 0.97,
      H_1m: 1.07,
      L_3m: 0.73,
      H_3m: 1.07,
      L_52w: 0.56,
      H_52w: 1.09,
      cur: 1.07,
      id: "3339",
    },
    3347: {
      d_1d: -0.01,
      d_3d: 0.03,
      d_5d: 0.22,
      d_10d: 0.47,
      d_20d: 0.88,
      L_5d: 5.63,
      H_5d: 5.82,
      L_1m: 4.94,
      H_1m: 5.82,
      L_3m: 4.93,
      H_3m: 5.82,
      L_52w: 0.3,
      H_52w: 8.21,
      cur: 5.81,
      id: "3347",
    },
    3360: {
      d_1d: 0.05,
      d_3d: 0.13,
      d_5d: 0.31,
      d_10d: 0.32,
      d_20d: 0.24,
      L_5d: 3.16,
      H_5d: 3.38,
      L_1m: 3.05,
      H_1m: 3.38,
      L_3m: 3.05,
      H_3m: 3.38,
      L_52w: 1.7,
      H_52w: 3.48,
      cur: 3.38,
      id: "3360",
    },
    3368: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.21,
      H_5d: 0.21,
      L_1m: 0.21,
      H_1m: 0.21,
      L_3m: 0.21,
      H_3m: 0.21,
      L_52w: 0.21,
      H_52w: 0.22,
      cur: 0.21,
      id: "3368",
    },
    3369: {
      d_1d: 0.02,
      d_3d: 0.08,
      d_5d: 0.08,
      d_10d: 0.16,
      d_20d: 0.24,
      L_5d: 15.3,
      H_5d: 15.38,
      L_1m: 15.15,
      H_1m: 15.38,
      L_3m: 14.22,
      H_3m: 15.38,
      L_52w: 10.31,
      H_52w: 15.38,
      cur: 15.38,
      id: "3369",
    },
    3377: {
      d_1d: 0.16,
      d_3d: 0.24,
      d_5d: 0.35,
      d_10d: 0.62,
      d_20d: 1.27,
      L_5d: 5.81,
      H_5d: 6.11,
      L_1m: 4.83,
      H_1m: 6.11,
      L_3m: 3.89,
      H_3m: 6.11,
      L_52w: 2.66,
      H_52w: 6.11,
      cur: 6.11,
      id: "3377",
    },
    3380: {
      d_1d: 0,
      d_3d: 0,
      d_5d: -0.02,
      d_10d: -0.19,
      d_20d: -0.41,
      L_5d: 1.19,
      H_5d: 1.2,
      L_1m: 1.19,
      H_1m: 1.57,
      L_3m: 1.19,
      H_3m: 1.99,
      L_52w: 1.19,
      H_52w: 2.72,
      cur: 1.19,
      id: "3380",
    },
    3382: {
      d_1d: 0,
      d_3d: 0,
      d_5d: -0.01,
      d_10d: -0.01,
      d_20d: -0.01,
      L_5d: 2.36,
      H_5d: 2.36,
      L_1m: 2.36,
      H_1m: 2.37,
      L_3m: 2.36,
      H_3m: 2.44,
      L_52w: 2.36,
      H_52w: 3.25,
      cur: 2.36,
      id: "3382",
    },
    3383: {
      d_1d: 0,
      d_3d: 0.02,
      d_5d: 0.03,
      d_10d: 0.01,
      d_20d: 0.02,
      L_5d: 0.72,
      H_5d: 0.75,
      L_1m: 0.71,
      H_1m: 0.77,
      L_3m: 0.67,
      H_3m: 0.77,
      L_52w: 0.67,
      H_52w: 1.21,
      cur: 0.75,
      id: "3383",
    },
    3389: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.02,
      H_5d: 0.02,
      L_1m: 0.02,
      H_1m: 0.02,
      L_3m: 0.02,
      H_3m: 0.02,
      L_52w: 0.02,
      H_52w: 0.02,
      cur: 0.02,
      id: "3389",
    },
    3393: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.02,
      H_5d: 0.02,
      L_1m: 0.02,
      H_1m: 0.02,
      L_3m: 0.02,
      H_3m: 0.02,
      L_52w: 0.02,
      H_52w: 0.13,
      cur: 0.02,
      id: "3393",
    },
    3396: {
      d_1d: 0.03,
      d_3d: 0.09,
      d_5d: 0.1,
      d_10d: 0.16,
      d_20d: 0.19,
      L_5d: 9.07,
      H_5d: 9.17,
      L_1m: 8.89,
      H_1m: 9.17,
      L_3m: 8.69,
      H_3m: 9.29,
      L_52w: 7.44,
      H_52w: 9.62,
      cur: 9.17,
      id: "3396",
    },
    3606: {
      d_1d: -0.26,
      d_3d: -0.82,
      d_5d: -0.88,
      d_10d: -0.68,
      d_20d: -0.22,
      L_5d: 23.87,
      H_5d: 24.69,
      L_1m: 23.87,
      H_1m: 24.75,
      L_3m: 22.3,
      H_3m: 24.75,
      L_52w: 16.95,
      H_52w: 29.48,
      cur: 23.87,
      id: "3606",
    },
    3613: {
      d_1d: -0.01,
      d_3d: 0,
      d_5d: -0.03,
      d_10d: 0,
      d_20d: -0.04,
      L_5d: 8.46,
      H_5d: 8.47,
      L_1m: 8.43,
      H_1m: 8.5,
      L_3m: 8.43,
      H_3m: 8.69,
      L_52w: 8.16,
      H_52w: 9.3,
      cur: 8.46,
      id: "3613",
    },
    3618: {
      d_1d: -0.13,
      d_3d: 0.42,
      d_5d: 1.44,
      d_10d: 3.09,
      d_20d: 3.95,
      L_5d: 32.95,
      H_5d: 34.17,
      L_1m: 30.16,
      H_1m: 34.17,
      L_3m: 27.24,
      H_3m: 34.17,
      L_52w: 24.36,
      H_52w: 34.17,
      cur: 34.04,
      id: "3618",
    },
    3633: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.88,
      H_5d: 0.88,
      L_1m: 0.88,
      H_1m: 0.88,
      L_3m: 0.88,
      H_3m: 0.95,
      L_52w: 0.68,
      H_52w: 1.02,
      cur: 0.88,
      id: "3633",
    },
    3669: {
      d_1d: -0.06,
      d_3d: -0.09,
      d_5d: -0.06,
      d_10d: -0.02,
      d_20d: -0.16,
      L_5d: 4.56,
      H_5d: 4.66,
      L_1m: 4.56,
      H_1m: 4.69,
      L_3m: 4.56,
      H_3m: 5.22,
      L_52w: 4.56,
      H_52w: 7.62,
      cur: 4.56,
      id: "3669",
    },
    3690: {
      d_1d: 0.01,
      d_3d: 0.02,
      d_5d: 0.06,
      d_10d: 0.06,
      d_20d: -0.04,
      L_5d: 5.2,
      H_5d: 5.25,
      L_1m: 5.18,
      H_1m: 5.32,
      L_3m: 4.79,
      H_3m: 5.32,
      L_52w: 4.77,
      H_52w: 7.67,
      cur: 5.25,
      id: "3690",
    },
    3692: {
      d_1d: 0.01,
      d_3d: 0.03,
      d_5d: 0.04,
      d_10d: 0.02,
      d_20d: 0.04,
      L_5d: 0.32,
      H_5d: 0.36,
      L_1m: 0.32,
      H_1m: 0.36,
      L_3m: 0.3,
      H_3m: 0.36,
      L_52w: 0.29,
      H_52w: 0.67,
      cur: 0.36,
      id: "3692",
    },
    3698: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.13,
      H_5d: 0.13,
      L_1m: 0.13,
      H_1m: 0.13,
      L_3m: 0.13,
      H_3m: 0.13,
      L_52w: 0.13,
      H_52w: 0.14,
      cur: 0.13,
      id: "3698",
    },
    3738: {
      d_1d: 0.12,
      d_3d: 0.12,
      d_5d: 0.18,
      d_10d: 0.19,
      d_20d: 1.27,
      L_5d: 4.41,
      H_5d: 4.57,
      L_1m: 3.38,
      H_1m: 4.57,
      L_3m: 0.74,
      H_3m: 4.57,
      L_52w: 0.74,
      H_52w: 4.57,
      cur: 4.57,
      id: "3738",
    },
    3759: {
      d_1d: -0.17,
      d_3d: -0.56,
      d_5d: -0.6,
      d_10d: -0.41,
      d_20d: -0.36,
      L_5d: 12.58,
      H_5d: 13.18,
      L_1m: 12.58,
      H_1m: 13.23,
      L_3m: 12.58,
      H_3m: 14.57,
      L_52w: 9.67,
      H_52w: 14.64,
      cur: 12.58,
      id: "3759",
    },
    3788: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.08,
      H_5d: 0.08,
      L_1m: 0.08,
      H_1m: 0.08,
      L_3m: 0.08,
      H_3m: 0.09,
      L_52w: 0.08,
      H_52w: 0.86,
      cur: 0.08,
      id: "3788",
    },
    3799: {
      d_1d: 0.01,
      d_3d: -0.03,
      d_5d: -0.03,
      d_10d: -0.01,
      d_20d: 0,
      L_5d: 3.22,
      H_5d: 3.26,
      L_1m: 3.22,
      H_1m: 3.26,
      L_3m: 3.22,
      H_3m: 3.35,
      L_52w: 3.12,
      H_52w: 3.36,
      cur: 3.23,
      id: "3799",
    },
    3800: {
      d_1d: -0.12,
      d_3d: -0.44,
      d_5d: -0.55,
      d_10d: -0.84,
      d_20d: -1.91,
      L_5d: 16.74,
      H_5d: 17.23,
      L_1m: 16.74,
      H_1m: 18.56,
      L_3m: 16.74,
      H_3m: 21.27,
      L_52w: 16.74,
      H_52w: 24.86,
      cur: 16.74,
      id: "3800",
    },
    3808: {
      d_1d: 0.07,
      d_3d: 0.06,
      d_5d: 0.07,
      d_10d: 0.12,
      d_20d: 0.05,
      L_5d: 2.79,
      H_5d: 2.86,
      L_1m: 2.72,
      H_1m: 2.86,
      L_3m: 2.72,
      H_3m: 2.98,
      L_52w: 1.98,
      H_52w: 4.11,
      cur: 2.86,
      id: "3808",
    },
    3813: {
      d_1d: 0,
      d_3d: 0.01,
      d_5d: 0.01,
      d_10d: 0.03,
      d_20d: 0,
      L_5d: 0.78,
      H_5d: 0.79,
      L_1m: 0.73,
      H_1m: 0.79,
      L_3m: 0.73,
      H_3m: 0.82,
      L_52w: 0.23,
      H_52w: 0.82,
      cur: 0.79,
      id: "3813",
    },
    3818: {
      d_1d: 0,
      d_3d: 0.01,
      d_5d: -0.05,
      d_10d: -0.03,
      d_20d: -0.03,
      L_5d: 2.43,
      H_5d: 2.48,
      L_1m: 2.43,
      H_1m: 2.49,
      L_3m: 2.16,
      H_3m: 2.54,
      L_52w: 2.16,
      H_52w: 6.57,
      cur: 2.44,
      id: "3818",
    },
    3836: {
      d_1d: 0,
      d_3d: -0.04,
      d_5d: -0.08,
      d_10d: -0.15,
      d_20d: -0.31,
      L_5d: 8.66,
      H_5d: 8.71,
      L_1m: 8.66,
      H_1m: 8.97,
      L_3m: 8.66,
      H_3m: 9.56,
      L_52w: 5.91,
      H_52w: 10.74,
      cur: 8.66,
      id: "3836",
    },
    3866: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.11,
      H_5d: 0.11,
      L_1m: 0.11,
      H_1m: 0.11,
      L_3m: 0.11,
      H_3m: 0.12,
      L_52w: 0.05,
      H_52w: 0.15,
      cur: 0.11,
      id: "3866",
    },
    3868: {
      d_1d: 0.01,
      d_3d: 0.01,
      d_5d: 0.02,
      d_10d: -0.01,
      d_20d: -0.07,
      L_5d: 1.59,
      H_5d: 1.6,
      L_1m: 1.58,
      H_1m: 1.67,
      L_3m: 1.58,
      H_3m: 1.84,
      L_52w: 1.58,
      H_52w: 2.02,
      cur: 1.6,
      id: "3868",
    },
    3877: {
      d_1d: 0.01,
      d_3d: 0.07,
      d_5d: 0.07,
      d_10d: 0.12,
      d_20d: 0.03,
      L_5d: 5.78,
      H_5d: 5.85,
      L_1m: 5.73,
      H_1m: 5.85,
      L_3m: 5.73,
      H_3m: 6.06,
      L_52w: 0.08,
      H_52w: 6.06,
      cur: 5.85,
      id: "3877",
    },
    3883: {
      d_1d: 0.12,
      d_3d: 0.01,
      d_5d: -0.03,
      d_10d: 0.12,
      d_20d: 0.15,
      L_5d: 6.63,
      H_5d: 6.78,
      L_1m: 6.51,
      H_1m: 7.02,
      L_3m: 5.65,
      H_3m: 7.02,
      L_52w: 4.7,
      H_52w: 7.02,
      cur: 6.75,
      id: "3883",
    },
    3886: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.49,
      H_5d: 0.49,
      L_1m: 0.49,
      H_1m: 0.49,
      L_3m: 0.49,
      H_3m: 0.5,
      L_52w: 0.49,
      H_52w: 1.94,
      cur: 0.49,
      id: "3886",
    },
    3888: {
      d_1d: 0.03,
      d_3d: 0.14,
      d_5d: 0.3,
      d_10d: 0.42,
      d_20d: 0.32,
      L_5d: 13.76,
      H_5d: 13.96,
      L_1m: 13.5,
      H_1m: 13.96,
      L_3m: 13.5,
      H_3m: 15.21,
      L_52w: 13.5,
      H_52w: 28.46,
      cur: 13.96,
      id: "3888",
    },
    3898: {
      d_1d: 0.4,
      d_3d: 0.44,
      d_5d: 0.32,
      d_10d: 1.46,
      d_20d: 1.62,
      L_5d: 31.05,
      H_5d: 31.49,
      L_1m: 29.98,
      H_1m: 31.49,
      L_3m: 29.02,
      H_3m: 32.81,
      L_52w: 9.26,
      H_52w: 37.24,
      cur: 31.49,
      id: "3898",
    },
    3899: {
      d_1d: 0.05,
      d_3d: 0.61,
      d_5d: 0.88,
      d_10d: 1.8,
      d_20d: 2.04,
      L_5d: 8.21,
      H_5d: 9.07,
      L_1m: 6.95,
      H_1m: 9.07,
      L_3m: 6.74,
      H_3m: 9.07,
      L_52w: 3.59,
      H_52w: 9.07,
      cur: 9.07,
      id: "3899",
    },
    3900: {
      d_1d: 0.02,
      d_3d: -0.03,
      d_5d: -0.11,
      d_10d: -0.03,
      d_20d: -0.14,
      L_5d: 15.09,
      H_5d: 15.17,
      L_1m: 15.09,
      H_1m: 15.25,
      L_3m: 15.09,
      H_3m: 16.31,
      L_52w: 12.92,
      H_52w: 16.31,
      cur: 15.11,
      id: "3900",
    },
    3908: {
      d_1d: 0.21,
      d_3d: 0.28,
      d_5d: 0.34,
      d_10d: 0.28,
      d_20d: 0.58,
      L_5d: 18.47,
      H_5d: 18.8,
      L_1m: 18.05,
      H_1m: 18.8,
      L_3m: 18,
      H_3m: 19.49,
      L_52w: 17.14,
      H_52w: 22.39,
      cur: 18.8,
      id: "3908",
    },
    3913: {
      d_1d: -0.03,
      d_3d: -0.09,
      d_5d: -0.14,
      d_10d: -0.14,
      d_20d: -0.24,
      L_5d: 2.25,
      H_5d: 2.37,
      L_1m: 2.25,
      H_1m: 2.47,
      L_3m: 2.25,
      H_3m: 3.94,
      L_52w: 2.25,
      H_52w: 4.88,
      cur: 2.25,
      id: "3913",
    },
    3933: {
      d_1d: -0.21,
      d_3d: -0.23,
      d_5d: -0.28,
      d_10d: -0.35,
      d_20d: -0.24,
      L_5d: 8.61,
      H_5d: 8.86,
      L_1m: 8.61,
      H_1m: 8.97,
      L_3m: 8.61,
      H_3m: 8.97,
      L_52w: 8.43,
      H_52w: 10.54,
      cur: 8.61,
      id: "3933",
    },
    3958: {
      d_1d: 0.03,
      d_3d: 0.09,
      d_5d: 0.15,
      d_10d: 0.75,
      d_20d: 0.83,
      L_5d: 54.62,
      H_5d: 54.76,
      L_1m: 53.67,
      H_1m: 54.76,
      L_3m: 53.51,
      H_3m: 56.52,
      L_52w: 39.13,
      H_52w: 56.52,
      cur: 54.76,
      id: "3958",
    },
    3968: {
      d_1d: 0.02,
      d_3d: 0.02,
      d_5d: 0.03,
      d_10d: 0.01,
      d_20d: 0.02,
      L_5d: 3.73,
      H_5d: 3.75,
      L_1m: 3.72,
      H_1m: 3.75,
      L_3m: 3.54,
      H_3m: 3.8,
      L_52w: 3.54,
      H_52w: 5.22,
      cur: 3.75,
      id: "3968",
    },
    3969: {
      d_1d: 0.02,
      d_3d: 0.18,
      d_5d: 0.47,
      d_10d: 0.44,
      d_20d: 1.42,
      L_5d: 24.96,
      H_5d: 25.18,
      L_1m: 23.93,
      H_1m: 25.18,
      L_3m: 21.45,
      H_3m: 25.18,
      L_52w: 12.76,
      H_52w: 25.18,
      cur: 25.18,
      id: "3969",
    },
    3988: {
      d_1d: -0.01,
      d_3d: -0.03,
      d_5d: -0.03,
      d_10d: -0.04,
      d_20d: 0.01,
      L_5d: 5.2,
      H_5d: 5.23,
      L_1m: 5.2,
      H_1m: 5.24,
      L_3m: 5.06,
      H_3m: 5.24,
      L_52w: 5.06,
      H_52w: 9.34,
      cur: 5.2,
      id: "3988",
    },
    3990: {
      d_1d: 0,
      d_3d: -0.01,
      d_5d: -0.1,
      d_10d: -0.17,
      d_20d: -0.53,
      L_5d: 5.3,
      H_5d: 5.35,
      L_1m: 5.3,
      H_1m: 5.82,
      L_3m: 5.3,
      H_3m: 5.95,
      L_52w: 5.3,
      H_52w: 6.44,
      cur: 5.3,
      id: "3990",
    },
    3993: {
      d_1d: 0.05,
      d_3d: 0.04,
      d_5d: -0.02,
      d_10d: -0.01,
      d_20d: -0.73,
      L_5d: 13.2,
      H_5d: 13.34,
      L_1m: 13.2,
      H_1m: 13.96,
      L_3m: 13.2,
      H_3m: 15.2,
      L_52w: 7.1,
      H_52w: 15.2,
      cur: 13.25,
      id: "3993",
    },
    3996: {
      d_1d: 0.16,
      d_3d: 0.62,
      d_5d: 0.69,
      d_10d: 0.78,
      d_20d: 1.41,
      L_5d: 1.54,
      H_5d: 2.25,
      L_1m: 0.92,
      H_1m: 2.25,
      L_3m: 0.11,
      H_3m: 2.25,
      L_52w: 0.11,
      H_52w: 2.25,
      cur: 2.25,
      id: "3996",
    },
    3998: {
      d_1d: 0.03,
      d_3d: 0.18,
      d_5d: 0.16,
      d_10d: -0.03,
      d_20d: -0.07,
      L_5d: 9.76,
      H_5d: 9.99,
      L_1m: 9.76,
      H_1m: 10.22,
      L_3m: 9.35,
      H_3m: 10.22,
      L_52w: 6.01,
      H_52w: 10.22,
      cur: 9.99,
      id: "3998",
    },
    6030: {
      d_1d: 0.02,
      d_3d: 0.01,
      d_5d: -0.01,
      d_10d: -0.05,
      d_20d: -0.31,
      L_5d: 11.15,
      H_5d: 11.19,
      L_1m: 11.15,
      H_1m: 11.42,
      L_3m: 10.88,
      H_3m: 11.59,
      L_52w: 10.75,
      H_52w: 21.98,
      cur: 11.17,
      id: "6030",
    },
    6049: {
      d_1d: 0.49,
      d_3d: 0.8,
      d_5d: 0.97,
      d_10d: 0.73,
      d_20d: 4.87,
      L_5d: 53.98,
      H_5d: 54.92,
      L_1m: 49.68,
      H_1m: 54.92,
      L_3m: 47.44,
      H_3m: 54.92,
      L_52w: 41.94,
      H_52w: 54.92,
      cur: 54.92,
      id: "6049",
    },
    6055: {
      d_1d: 0.02,
      d_3d: -0.01,
      d_5d: 0,
      d_10d: 0.14,
      d_20d: 0.42,
      L_5d: 5.11,
      H_5d: 5.22,
      L_1m: 4.81,
      H_1m: 5.22,
      L_3m: 4.52,
      H_3m: 5.22,
      L_52w: 4.21,
      H_52w: 5.38,
      cur: 5.15,
      id: "6055",
    },
    6060: {
      d_1d: 0.02,
      d_3d: 0.05,
      d_5d: 0.07,
      d_10d: 0.08,
      d_20d: -0.02,
      L_5d: 3.2,
      H_5d: 3.26,
      L_1m: 3.15,
      H_1m: 3.29,
      L_3m: 3.15,
      H_3m: 3.41,
      L_52w: 2.33,
      H_52w: 7.48,
      cur: 3.26,
      id: "6060",
    },
    6066: {
      d_1d: 0.06,
      d_3d: 0.17,
      d_5d: 0.23,
      d_10d: 0.26,
      d_20d: 0.29,
      L_5d: 29.11,
      H_5d: 29.33,
      L_1m: 29.05,
      H_1m: 29.33,
      L_3m: 28.48,
      H_3m: 29.33,
      L_52w: 24.73,
      H_52w: 30.19,
      cur: 29.33,
      id: "6066",
    },
    6068: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0.34,
      d_10d: 0.76,
      d_20d: 0.92,
      L_5d: 9.43,
      H_5d: 9.6,
      L_1m: 8.73,
      H_1m: 9.6,
      L_3m: 8.57,
      H_3m: 9.6,
      L_52w: 8.52,
      H_52w: 9.73,
      cur: 9.6,
      id: "6068",
    },
    6069: {
      d_1d: -0.04,
      d_3d: -0.04,
      d_5d: -0.05,
      d_10d: -0.06,
      d_20d: -0.11,
      L_5d: 9.14,
      H_5d: 9.2,
      L_1m: 9.14,
      H_1m: 9.24,
      L_3m: 9.14,
      H_3m: 10.08,
      L_52w: 3.71,
      H_52w: 10.08,
      cur: 9.14,
      id: "6069",
    },
    6078: {
      d_1d: -0.17,
      d_3d: -0.37,
      d_5d: -0.5,
      d_10d: -0.37,
      d_20d: -0.31,
      L_5d: 25.75,
      H_5d: 26.2,
      L_1m: 25.75,
      H_1m: 26.25,
      L_3m: 21.1,
      H_3m: 26.9,
      L_52w: 4.39,
      H_52w: 26.9,
      cur: 25.75,
      id: "6078",
    },
    6088: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: -0.04,
      L_5d: 2.88,
      H_5d: 2.88,
      L_1m: 2.87,
      H_1m: 2.91,
      L_3m: 2.87,
      H_3m: 3.17,
      L_52w: 2.87,
      H_52w: 4.91,
      cur: 2.88,
      id: "6088",
    },
    6098: {
      d_1d: 0.03,
      d_3d: 0.04,
      d_5d: -0.47,
      d_10d: -0.22,
      d_20d: -0.36,
      L_5d: 6.6,
      H_5d: 6.73,
      L_1m: 6.6,
      H_1m: 7.47,
      L_3m: 6.6,
      H_3m: 7.47,
      L_52w: 6.6,
      H_52w: 9.01,
      cur: 6.67,
      id: "6098",
    },
    6099: {
      d_1d: -0.01,
      d_3d: 0,
      d_5d: 0.02,
      d_10d: -0.05,
      d_20d: -0.07,
      L_5d: 23.56,
      H_5d: 23.6,
      L_1m: 23.56,
      H_1m: 23.65,
      L_3m: 23.56,
      H_3m: 24.15,
      L_52w: 20.49,
      H_52w: 24.15,
      cur: 23.59,
      id: "6099",
    },
    6100: {
      d_1d: 0,
      d_3d: 0,
      d_5d: -0.04,
      d_10d: -0.09,
      d_20d: -0.12,
      L_5d: 0.2,
      H_5d: 0.22,
      L_1m: 0.2,
      H_1m: 0.32,
      L_3m: 0.09,
      H_3m: 0.4,
      L_52w: 0.02,
      H_52w: 0.4,
      cur: 0.2,
      id: "6100",
    },
    6110: {
      d_1d: 0.01,
      d_3d: -0.01,
      d_5d: -0.02,
      d_10d: -0.02,
      d_20d: 0.02,
      L_5d: 0.59,
      H_5d: 0.62,
      L_1m: 0.59,
      H_1m: 0.62,
      L_3m: 0.55,
      H_3m: 0.99,
      L_52w: 0.18,
      H_52w: 1.33,
      cur: 0.6,
      id: "6110",
    },
    6116: {
      d_1d: 0,
      d_3d: -0.05,
      d_5d: -0.1,
      d_10d: -0.1,
      d_20d: -0.16,
      L_5d: 24.68,
      H_5d: 24.73,
      L_1m: 24.68,
      H_1m: 24.84,
      L_3m: 24.68,
      H_3m: 25.19,
      L_52w: 24.68,
      H_52w: 31.51,
      cur: 24.68,
      id: "6116",
    },
    6127: {
      d_1d: 0.09,
      d_3d: 0.09,
      d_5d: 0.22,
      d_10d: 1.09,
      d_20d: 3.61,
      L_5d: 15.73,
      H_5d: 15.82,
      L_1m: 12.51,
      H_1m: 15.82,
      L_3m: 9.72,
      H_3m: 15.82,
      L_52w: 0,
      H_52w: 15.82,
      cur: 15.82,
      id: "6127",
    },
    6158: {
      d_1d: -0.01,
      d_3d: -0.08,
      d_5d: -0.09,
      d_10d: -0.1,
      d_20d: -0.18,
      L_5d: 1.75,
      H_5d: 1.83,
      L_1m: 1.75,
      H_1m: 1.92,
      L_3m: 1.75,
      H_3m: 2.17,
      L_52w: 1.75,
      H_52w: 2.29,
      cur: 1.75,
      id: "6158",
    },
    6160: {
      d_1d: 0.01,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0.01,
      d_20d: 0.07,
      L_5d: 0.85,
      H_5d: 0.86,
      L_1m: 0.79,
      H_1m: 0.86,
      L_3m: 0.79,
      H_3m: 0.96,
      L_52w: 0.16,
      H_52w: 1.03,
      cur: 0.86,
      id: "6160",
    },
    6166: {
      d_1d: -0.01,
      d_3d: -0.01,
      d_5d: -0.01,
      d_10d: -0.01,
      d_20d: -1.05,
      L_5d: 3.95,
      H_5d: 3.96,
      L_1m: 3.95,
      H_1m: 5,
      L_3m: 3.95,
      H_3m: 5.42,
      L_52w: 3.95,
      H_52w: 5.55,
      cur: 3.95,
      id: "6166",
    },
    6169: {
      d_1d: 0.01,
      d_3d: 0.13,
      d_5d: 0.48,
      d_10d: 0.4,
      d_20d: -0.89,
      L_5d: 9.39,
      H_5d: 9.7,
      L_1m: 9.18,
      H_1m: 10.75,
      L_3m: 9.18,
      H_3m: 12.9,
      L_52w: 9.18,
      H_52w: 17.8,
      cur: 9.66,
      id: "6169",
    },
    6178: {
      d_1d: -0.1,
      d_3d: -0.14,
      d_5d: -0.18,
      d_10d: -0.21,
      d_20d: -0.23,
      L_5d: 14.71,
      H_5d: 14.87,
      L_1m: 14.71,
      H_1m: 15.11,
      L_3m: 14.33,
      H_3m: 15.11,
      L_52w: 12.71,
      H_52w: 15.11,
      cur: 14.71,
      id: "6178",
    },
    6185: {
      d_1d: 0.04,
      d_3d: 0.15,
      d_5d: -0.11,
      d_10d: -0.01,
      d_20d: -0.15,
      L_5d: 6.27,
      H_5d: 6.54,
      L_1m: 6.27,
      H_1m: 6.8,
      L_3m: 6.27,
      H_3m: 7.23,
      L_52w: 0,
      H_52w: 9.66,
      cur: 6.42,
      id: "6185",
    },
    6186: {
      d_1d: 0.08,
      d_3d: 0.08,
      d_5d: 0.19,
      d_10d: 0.23,
      d_20d: 0.14,
      L_5d: 6.03,
      H_5d: 6.24,
      L_1m: 5.98,
      H_1m: 6.24,
      L_3m: 5.98,
      H_3m: 6.58,
      L_52w: 5.98,
      H_52w: 10.29,
      cur: 6.24,
      id: "6186",
    },
    6196: {
      d_1d: 0,
      d_3d: -4.77,
      d_5d: -4.77,
      d_10d: -4.75,
      d_20d: -9.49,
      L_5d: 10.12,
      H_5d: 14.91,
      L_1m: 10.12,
      H_1m: 19.68,
      L_3m: 10.12,
      H_3m: 19.68,
      L_52w: 10.12,
      H_52w: 19.68,
      cur: 10.14,
      id: "6196",
    },
    6198: {
      d_1d: 0,
      d_3d: 0.04,
      d_5d: 0.04,
      d_10d: 0.15,
      d_20d: 0.19,
      L_5d: 7.49,
      H_5d: 7.53,
      L_1m: 7.33,
      H_1m: 7.53,
      L_3m: 5.7,
      H_3m: 7.53,
      L_52w: 0.93,
      H_52w: 7.53,
      cur: 7.53,
      id: "6198",
    },
    6600: {
      d_1d: 0,
      d_3d: 0.02,
      d_5d: 0.02,
      d_10d: 0.04,
      d_20d: 0.1,
      L_5d: 0.19,
      H_5d: 0.21,
      L_1m: 0.11,
      H_1m: 0.21,
      L_3m: 0.01,
      H_3m: 0.21,
      L_52w: 0.01,
      H_52w: 0.21,
      cur: 0.21,
      id: "6600",
    },
    6601: {
      d_1d: 0.12,
      d_3d: 0.45,
      d_5d: 0.68,
      d_10d: 1.65,
      d_20d: 3.01,
      L_5d: 5.23,
      H_5d: 5.76,
      L_1m: 2.86,
      H_1m: 5.76,
      L_3m: 0.02,
      H_3m: 5.76,
      L_52w: 0.02,
      H_52w: 5.76,
      cur: 5.76,
      id: "6601",
    },
    6606: {
      d_1d: 0.02,
      d_3d: 0.01,
      d_5d: 0,
      d_10d: 0.11,
      d_20d: 0.32,
      L_5d: 0.69,
      H_5d: 0.71,
      L_1m: 0.39,
      H_1m: 0.71,
      L_3m: 0.34,
      H_3m: 0.71,
      L_52w: 0,
      H_52w: 0.71,
      cur: 0.71,
      id: "6606",
    },
    6618: {
      d_1d: 0.03,
      d_3d: 0.06,
      d_5d: 0.07,
      d_10d: 0.08,
      d_20d: 0.08,
      L_5d: 0.8,
      H_5d: 0.86,
      L_1m: 0.77,
      H_1m: 0.86,
      L_3m: 0.74,
      H_3m: 0.86,
      L_52w: 0,
      H_52w: 0.86,
      cur: 0.86,
      id: "6618",
    },
    6666: {
      d_1d: 0.08,
      d_3d: 0.13,
      d_5d: 0.26,
      d_10d: 0.48,
      d_20d: 0.98,
      L_5d: 5.05,
      H_5d: 5.33,
      L_1m: 4.45,
      H_1m: 5.33,
      L_3m: 3.69,
      H_3m: 5.33,
      L_52w: 0,
      H_52w: 5.33,
      cur: 5.33,
      id: "6666",
    },
    6690: {
      d_1d: 0.03,
      d_3d: 0.02,
      d_5d: 0,
      d_10d: -0.08,
      d_20d: -0.43,
      L_5d: 7.93,
      H_5d: 7.96,
      L_1m: 7.93,
      H_1m: 8.43,
      L_3m: 7.93,
      H_3m: 11.21,
      L_52w: 7.93,
      H_52w: 15.48,
      cur: 7.96,
      id: "6690",
    },
    6699: {
      d_1d: 0.01,
      d_3d: 0.02,
      d_5d: 0.02,
      d_10d: 0.06,
      d_20d: 0.1,
      L_5d: 0.9,
      H_5d: 0.92,
      L_1m: 0.82,
      H_1m: 0.92,
      L_3m: 0.17,
      H_3m: 0.92,
      L_52w: 0.17,
      H_52w: 0.92,
      cur: 0.92,
      id: "6699",
    },
    6806: {
      d_1d: 0.01,
      d_3d: 0.19,
      d_5d: 0.42,
      d_10d: 0.55,
      d_20d: 0.56,
      L_5d: 19.5,
      H_5d: 19.84,
      L_1m: 19.29,
      H_1m: 19.84,
      L_3m: 19.06,
      H_3m: 20.89,
      L_52w: 10.93,
      H_52w: 20.89,
      cur: 19.84,
      id: "6806",
    },
    6808: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0.07,
      d_10d: 0.1,
      d_20d: 0.19,
      L_5d: 0.96,
      H_5d: 1.02,
      L_1m: 0.87,
      H_1m: 1.02,
      L_3m: 0.75,
      H_3m: 1.02,
      L_52w: 0.51,
      H_52w: 1.02,
      cur: 1.02,
      id: "6808",
    },
    6818: {
      d_1d: 0.02,
      d_3d: 0.04,
      d_5d: 0.05,
      d_10d: -0.01,
      d_20d: -0.02,
      L_5d: 9.42,
      H_5d: 9.47,
      L_1m: 9.42,
      H_1m: 9.52,
      L_3m: 9.37,
      H_3m: 9.52,
      L_52w: 7.93,
      H_52w: 9.57,
      cur: 9.47,
      id: "6818",
    },
    6826: {
      d_1d: -0.14,
      d_3d: -0.28,
      d_5d: -0.89,
      d_10d: -1.28,
      d_20d: -1.4,
      L_5d: 15.98,
      H_5d: 16.6,
      L_1m: 15.98,
      H_1m: 17.42,
      L_3m: 15.98,
      H_3m: 21.11,
      L_52w: 0,
      H_52w: 25.4,
      cur: 15.98,
      id: "6826",
    },
    6828: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 1.74,
      H_5d: 1.74,
      L_1m: 1.74,
      H_1m: 1.74,
      L_3m: 1.74,
      H_3m: 1.74,
      L_52w: 1.74,
      H_52w: 1.75,
      cur: 1.74,
      id: "6828",
    },
    6837: {
      d_1d: 0,
      d_3d: 0.04,
      d_5d: 0.13,
      d_10d: 0.15,
      d_20d: 0.17,
      L_5d: 25.84,
      H_5d: 25.93,
      L_1m: 25.73,
      H_1m: 25.93,
      L_3m: 25.73,
      H_3m: 27.12,
      L_52w: 24.97,
      H_52w: 28.54,
      cur: 25.93,
      id: "6837",
    },
    6855: {
      d_1d: -0.05,
      d_3d: -0.12,
      d_5d: -0.04,
      d_10d: 0.08,
      d_20d: 0.6,
      L_5d: 20.16,
      H_5d: 20.28,
      L_1m: 19.55,
      H_1m: 20.28,
      L_3m: 19.18,
      H_3m: 20.28,
      L_52w: 0,
      H_52w: 20.28,
      cur: 20.16,
      id: "6855",
    },
    6862: {
      d_1d: 0.02,
      d_3d: 0.07,
      d_5d: 0.1,
      d_10d: 0.28,
      d_20d: 0.78,
      L_5d: 3.51,
      H_5d: 3.63,
      L_1m: 2.91,
      H_1m: 3.63,
      L_3m: 2.74,
      H_3m: 3.63,
      L_52w: 2.55,
      H_52w: 4.12,
      cur: 3.63,
      id: "6862",
    },
    6865: {
      d_1d: 0.33,
      d_3d: 0.23,
      d_5d: -0.81,
      d_10d: -1.12,
      d_20d: -1.62,
      L_5d: 24.25,
      H_5d: 25.04,
      L_1m: 24.25,
      H_1m: 26.48,
      L_3m: 24.25,
      H_3m: 30.89,
      L_52w: 15.18,
      H_52w: 33.73,
      cur: 24.58,
      id: "6865",
    },
    6869: {
      d_1d: -0.05,
      d_3d: -0.01,
      d_5d: 0.01,
      d_10d: 0.08,
      d_20d: 0.19,
      L_5d: 9.45,
      H_5d: 9.5,
      L_1m: 9.25,
      H_1m: 9.5,
      L_3m: 7.53,
      H_3m: 9.5,
      L_52w: 5.81,
      H_52w: 9.5,
      cur: 9.45,
      id: "6869",
    },
    6881: {
      d_1d: 0,
      d_3d: 0.06,
      d_5d: 0,
      d_10d: 0.14,
      d_20d: 0.21,
      L_5d: 20.27,
      H_5d: 20.34,
      L_1m: 20.09,
      H_1m: 20.37,
      L_3m: 20.09,
      H_3m: 22.16,
      L_52w: 20.09,
      H_52w: 24.17,
      cur: 20.34,
      id: "6881",
    },
    6886: {
      d_1d: 0.12,
      d_3d: 0.21,
      d_5d: 0.23,
      d_10d: 0.27,
      d_20d: 0.23,
      L_5d: 35.56,
      H_5d: 35.79,
      L_1m: 35.5,
      H_1m: 35.79,
      L_3m: 34.29,
      H_3m: 35.79,
      L_52w: 27.57,
      H_52w: 36.78,
      cur: 35.79,
      id: "6886",
    },
    6969: {
      d_1d: -0.1,
      d_3d: -0.11,
      d_5d: 0.09,
      d_10d: 0.81,
      d_20d: 1.11,
      L_5d: 8.75,
      H_5d: 8.89,
      L_1m: 7.6,
      H_1m: 8.89,
      L_3m: 7.33,
      H_3m: 8.89,
      L_52w: 5.56,
      H_52w: 8.89,
      cur: 8.75,
      id: "6969",
    },
    6988: {
      d_1d: 0.01,
      d_3d: 0.16,
      d_5d: 0.22,
      d_10d: 0.27,
      d_20d: 0.07,
      L_5d: 2.92,
      H_5d: 3.14,
      L_1m: 2.84,
      H_1m: 3.14,
      L_3m: 2.57,
      H_3m: 3.14,
      L_52w: 0,
      H_52w: 3.14,
      cur: 3.14,
      id: "6988",
    },
    6989: {
      d_1d: -0.01,
      d_3d: 0.02,
      d_5d: 0.05,
      d_10d: 0.16,
      d_20d: 0.26,
      L_5d: 2.33,
      H_5d: 2.39,
      L_1m: 2.12,
      H_1m: 2.39,
      L_3m: 1.62,
      H_3m: 2.39,
      L_52w: 0,
      H_52w: 2.39,
      cur: 2.38,
      id: "6989",
    },
    6993: {
      d_1d: 0.01,
      d_3d: 0,
      d_5d: -0.01,
      d_10d: -0.02,
      d_20d: -0.03,
      L_5d: 0.81,
      H_5d: 0.82,
      L_1m: 0.8,
      H_1m: 0.85,
      L_3m: 0.75,
      H_3m: 0.85,
      L_52w: 0,
      H_52w: 0.93,
      cur: 0.82,
      id: "6993",
    },
    6996: {
      d_1d: -0.01,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0.16,
      d_20d: 0.34,
      L_5d: 1.55,
      H_5d: 1.57,
      L_1m: 1.22,
      H_1m: 1.58,
      L_3m: 1.08,
      H_3m: 1.58,
      L_52w: 0,
      H_52w: 1.58,
      cur: 1.56,
      id: "6996",
    },
    6998: {
      d_1d: 0.01,
      d_3d: 0.08,
      d_5d: 0.12,
      d_10d: 0.24,
      d_20d: 0.35,
      L_5d: 2.4,
      H_5d: 2.51,
      L_1m: 2.16,
      H_1m: 2.51,
      L_3m: 1.88,
      H_3m: 2.51,
      L_52w: 0,
      H_52w: 2.51,
      cur: 2.51,
      id: "6998",
    },
    8328: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.01,
      H_5d: 0.01,
      L_1m: 0.01,
      H_1m: 0.01,
      L_3m: 0.01,
      H_3m: 0.01,
      L_52w: 0.01,
      H_52w: 0.01,
      cur: 0.01,
      id: "8328",
    },
    8585: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0,
      H_5d: 0,
      L_1m: 0,
      H_1m: 0,
      L_3m: 0,
      H_3m: 0,
      L_52w: 0,
      H_52w: 0,
      cur: 0,
      id: "8585",
    },
    8591: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0,
      H_5d: 0,
      L_1m: 0,
      H_1m: 0,
      L_3m: 0,
      H_3m: 0,
      L_52w: 0,
      H_52w: 0,
      cur: 0,
      id: "8591",
    },
    8595: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: "-",
      L_5d: 0,
      H_5d: 0,
      L_1m: 0,
      H_1m: 0,
      L_3m: 0,
      H_3m: 0,
      L_52w: 0,
      H_52w: 0,
      cur: 0,
      id: "8595",
    },
    9633: {
      d_1d: 0,
      d_3d: -0.02,
      d_5d: -0.11,
      d_10d: -0.18,
      d_20d: -0.24,
      L_5d: 2.31,
      H_5d: 2.34,
      L_1m: 2.31,
      H_1m: 2.6,
      L_3m: 2.31,
      H_3m: 2.74,
      L_52w: 1.61,
      H_52w: 2.75,
      cur: 2.31,
      id: "9633",
    },
    9666: {
      d_1d: 0.03,
      d_3d: 0.08,
      d_5d: 0.16,
      d_10d: 0.43,
      d_20d: 0.93,
      L_5d: 6.21,
      H_5d: 6.34,
      L_1m: 5.4,
      H_1m: 6.34,
      L_3m: 4.64,
      H_3m: 19.86,
      L_52w: 0,
      H_52w: 20.54,
      cur: 6.34,
      id: "9666",
    },
    9668: {
      d_1d: -0.01,
      d_3d: -0.01,
      d_5d: -0.01,
      d_10d: -0.01,
      d_20d: -0.01,
      L_5d: 0.02,
      H_5d: 0.03,
      L_1m: 0.02,
      H_1m: 0.03,
      L_3m: 0.02,
      H_3m: 0.03,
      L_52w: 0,
      H_52w: 0.17,
      cur: 0.02,
      id: "9668",
    },
    9858: {
      d_1d: 0.05,
      d_3d: 0.1,
      d_5d: 0.11,
      d_10d: 0.13,
      d_20d: 0.1,
      L_5d: 0.43,
      H_5d: 0.53,
      L_1m: 0.39,
      H_1m: 0.53,
      L_3m: 0.04,
      H_3m: 0.53,
      L_52w: 0.04,
      H_52w: 0.53,
      cur: 0.53,
      id: "9858",
    },
    9909: {
      d_1d: -0.01,
      d_3d: -0.05,
      d_5d: -0.05,
      d_10d: 0.03,
      d_20d: 0.34,
      L_5d: 8.25,
      H_5d: 8.3,
      L_1m: 7.94,
      H_1m: 8.31,
      L_3m: 6.92,
      H_3m: 8.31,
      L_52w: 4.22,
      H_52w: 8.31,
      cur: 8.25,
      id: "9909",
    },
    9922: {
      d_1d: -0.01,
      d_3d: -0.12,
      d_5d: -0.32,
      d_10d: -0.19,
      d_20d: 0.12,
      L_5d: 12.48,
      H_5d: 12.68,
      L_1m: 12.37,
      H_1m: 12.8,
      L_3m: 12.34,
      H_3m: 12.8,
      L_52w: 8.55,
      H_52w: 12.9,
      cur: 12.48,
      id: "9922",
    },
    9923: {
      d_1d: 0.05,
      d_3d: 0.08,
      d_5d: 0.53,
      d_10d: 0.59,
      d_20d: 0.66,
      L_5d: 1.62,
      H_5d: 2.12,
      L_1m: 1.49,
      H_1m: 2.12,
      L_3m: 1.26,
      H_3m: 2.12,
      L_52w: 0.05,
      H_52w: 2.12,
      cur: 2.12,
      id: "9923",
    },
    9926: {
      d_1d: -0.12,
      d_3d: -0.14,
      d_5d: -0.07,
      d_10d: 0.21,
      d_20d: 0.55,
      L_5d: 11.12,
      H_5d: 11.29,
      L_1m: 10.47,
      H_1m: 11.29,
      L_3m: 9.26,
      H_3m: 11.29,
      L_52w: 0,
      H_52w: 11.29,
      cur: 11.12,
      id: "9926",
    },
    9928: {
      d_1d: -0.01,
      d_3d: -0.07,
      d_5d: -0.06,
      d_10d: -0.14,
      d_20d: -0.31,
      L_5d: 4.31,
      H_5d: 4.4,
      L_1m: 4.31,
      H_1m: 4.66,
      L_3m: 3.91,
      H_3m: 4.89,
      L_52w: 1.55,
      H_52w: 4.89,
      cur: 4.31,
      id: "9928",
    },
    9939: {
      d_1d: 0.08,
      d_3d: 0.57,
      d_5d: 1.14,
      d_10d: 1.77,
      d_20d: 4.99,
      L_5d: 10.36,
      H_5d: 11.27,
      L_1m: 6.54,
      H_1m: 11.27,
      L_3m: 0.5,
      H_3m: 11.27,
      L_52w: 0.5,
      H_52w: 11.27,
      cur: 11.27,
      id: "9939",
    },
    9959: {
      d_1d: 0,
      d_3d: 0.03,
      d_5d: 0.05,
      d_10d: 0.09,
      d_20d: "-",
      L_5d: 0.07,
      H_5d: 0.11,
      L_1m: 0,
      H_1m: 0.11,
      L_3m: 0,
      H_3m: 0.11,
      L_52w: 0,
      H_52w: 0.11,
      cur: 0.11,
      id: "9959",
    },
    9966: {
      d_1d: 0.01,
      d_3d: 0.04,
      d_5d: 0.28,
      d_10d: 0.67,
      d_20d: 1.17,
      L_5d: 9.38,
      H_5d: 9.64,
      L_1m: 8.49,
      H_1m: 9.64,
      L_3m: 8.46,
      H_3m: 9.64,
      L_52w: 0,
      H_52w: 9.64,
      cur: 9.63,
      id: "9966",
    },
    9968: {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: -0.01,
      d_20d: -0.03,
      L_5d: 0.25,
      H_5d: 0.25,
      L_1m: 0.25,
      H_1m: 0.28,
      L_3m: 0.25,
      H_3m: 0.32,
      L_52w: 0.15,
      H_52w: 0.35,
      cur: 0.25,
      id: "9968",
    },
    9969: {
      d_1d: 0.02,
      d_3d: 0.03,
      d_5d: 0.03,
      d_10d: 0.09,
      d_20d: 0.52,
      L_5d: 5.97,
      H_5d: 6.02,
      L_1m: 5.56,
      H_1m: 6.02,
      L_3m: 4.99,
      H_3m: 6.02,
      L_52w: 0,
      H_52w: 6.02,
      cur: 6.02,
      id: "9969",
    },
    9982: {
      d_1d: -0.01,
      d_3d: -0.01,
      d_5d: -0.01,
      d_10d: -0.02,
      d_20d: -0.17,
      L_5d: 3.11,
      H_5d: 3.12,
      L_1m: 3.11,
      H_1m: 3.27,
      L_3m: 3.11,
      H_3m: 3.68,
      L_52w: 3.11,
      H_52w: 4.62,
      cur: 3.11,
      id: "9982",
    },
    9983: {
      d_1d: -0.02,
      d_3d: -0.01,
      d_5d: -0.11,
      d_10d: -0.19,
      d_20d: -0.06,
      L_5d: 3.69,
      H_5d: 3.76,
      L_1m: 3.69,
      H_1m: 3.88,
      L_3m: 3.69,
      H_3m: 4.95,
      L_52w: 1.71,
      H_52w: 6.07,
      cur: 3.69,
      id: "9983",
    },
    9989: {
      d_1d: 0,
      d_3d: -0.03,
      d_5d: 0.01,
      d_10d: 0.08,
      d_20d: 0.21,
      L_5d: 12.02,
      H_5d: 12.05,
      L_1m: 11.87,
      H_1m: 12.05,
      L_3m: 11.11,
      H_3m: 12.05,
      L_52w: 7.38,
      H_52w: 12.05,
      cur: 12.02,
      id: "9989",
    },
    9990: {
      d_1d: 0.02,
      d_3d: 0.06,
      d_5d: 0.07,
      d_10d: 0.1,
      d_20d: 0.05,
      L_5d: 1.57,
      H_5d: 1.63,
      L_1m: 1.53,
      H_1m: 1.63,
      L_3m: 1.53,
      H_3m: 1.63,
      L_52w: 0,
      H_52w: 2.71,
      cur: 1.63,
      id: "9990",
    },
    9992: {
      d_1d: 0.11,
      d_3d: 0.58,
      d_5d: 0.78,
      d_10d: 1.19,
      d_20d: 3.43,
      L_5d: 12.68,
      H_5d: 13.41,
      L_1m: 10.29,
      H_1m: 13.41,
      L_3m: 6.7,
      H_3m: 13.41,
      L_52w: 0,
      H_52w: 13.41,
      cur: 13.41,
      id: "9992",
    },
    9993: {
      d_1d: 0.01,
      d_3d: 0.03,
      d_5d: 0.03,
      d_10d: 0.04,
      d_20d: 0.29,
      L_5d: 2.45,
      H_5d: 2.48,
      L_1m: 2.21,
      H_1m: 2.48,
      L_3m: 0.48,
      H_3m: 2.48,
      L_52w: 0,
      H_52w: 2.48,
      cur: 2.48,
      id: "9993",
    },
    9995: {
      d_1d: -0.04,
      d_3d: -0.07,
      d_5d: -0.02,
      d_10d: -0.13,
      d_20d: 1.24,
      L_5d: 8.41,
      H_5d: 8.48,
      L_1m: 7.11,
      H_1m: 8.54,
      L_3m: 6.52,
      H_3m: 8.54,
      L_52w: 0,
      H_52w: 8.54,
      cur: 8.41,
      id: "9995",
    },
    9996: {
      d_1d: 0,
      d_3d: 0.02,
      d_5d: 0.05,
      d_10d: 0.17,
      d_20d: 0.93,
      L_5d: 5.69,
      H_5d: 5.71,
      L_1m: 4.78,
      H_1m: 5.71,
      L_3m: 4.58,
      H_3m: 5.71,
      L_52w: 0,
      H_52w: 5.71,
      cur: 5.71,
      id: "9996",
    },
    9997: {
      d_1d: 0.05,
      d_3d: 0.06,
      d_5d: 0.34,
      d_10d: 0.41,
      d_20d: 0.36,
      L_5d: 3.43,
      H_5d: 3.5,
      L_1m: 3.03,
      H_1m: 3.5,
      L_3m: 2.99,
      H_3m: 3.5,
      L_52w: 0.65,
      H_52w: 4.41,
      cur: 3.5,
      id: "9997",
    },
    "0001": {
      d_1d: 0.02,
      d_3d: 0.04,
      d_5d: 0.05,
      d_10d: 0.07,
      d_20d: 0.08,
      L_5d: 0.78,
      H_5d: 0.83,
      L_1m: 0.76,
      H_1m: 0.83,
      L_3m: 0.69,
      H_3m: 0.83,
      L_52w: 0.64,
      H_52w: 0.83,
      cur: 0.83,
      id: "0001",
    },
    "0002": {
      d_1d: 0,
      d_3d: 0.01,
      d_5d: 0.01,
      d_10d: 0.01,
      d_20d: 0,
      L_5d: 0.15,
      H_5d: 0.16,
      L_1m: 0.15,
      H_1m: 0.16,
      L_3m: 0.15,
      H_3m: 0.16,
      L_52w: 0.15,
      H_52w: 0.29,
      cur: 0.16,
      id: "0002",
    },
    "0003": {
      d_1d: 0.01,
      d_3d: 0.02,
      d_5d: 0.02,
      d_10d: 0.01,
      d_20d: 0,
      L_5d: 0.16,
      H_5d: 0.18,
      L_1m: 0.16,
      H_1m: 0.18,
      L_3m: 0.16,
      H_3m: 0.21,
      L_52w: 0.12,
      H_52w: 0.33,
      cur: 0.18,
      id: "0003",
    },
    "0004": {
      d_1d: 0,
      d_3d: 0,
      d_5d: -0.01,
      d_10d: -0.01,
      d_20d: -0.01,
      L_5d: 0.14,
      H_5d: 0.15,
      L_1m: 0.14,
      H_1m: 0.15,
      L_3m: 0.14,
      H_3m: 0.16,
      L_52w: 0.14,
      H_52w: 0.29,
      cur: 0.14,
      id: "0004",
    },
    "0005": {
      d_1d: 0,
      d_3d: 0.01,
      d_5d: 0,
      d_10d: 0.01,
      d_20d: 0.03,
      L_5d: 9.28,
      H_5d: 9.29,
      L_1m: 9.26,
      H_1m: 9.29,
      L_3m: 9.21,
      H_3m: 9.29,
      L_52w: 9.21,
      H_52w: 9.54,
      cur: 9.29,
      id: "0005",
    },
    "0006": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0.01,
      d_10d: 0.01,
      d_20d: -0.01,
      L_5d: 0.21,
      H_5d: 0.22,
      L_1m: 0.21,
      H_1m: 0.23,
      L_3m: 0.2,
      H_3m: 0.26,
      L_52w: 0.19,
      H_52w: 0.34,
      cur: 0.22,
      id: "0006",
    },
    "0008": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.07,
      H_5d: 0.07,
      L_1m: 0.07,
      H_1m: 0.07,
      L_3m: 0.07,
      H_3m: 0.07,
      L_52w: 0.06,
      H_52w: 0.14,
      cur: 0.07,
      id: "0008",
    },
    "0010": {
      d_1d: -0.01,
      d_3d: -0.01,
      d_5d: 0,
      d_10d: -0.03,
      d_20d: -0.11,
      L_5d: 2.53,
      H_5d: 2.54,
      L_1m: 2.53,
      H_1m: 2.64,
      L_3m: 2.53,
      H_3m: 2.65,
      L_52w: 2.19,
      H_52w: 2.65,
      cur: 2.53,
      id: "0010",
    },
    "0011": {
      d_1d: 0,
      d_3d: -0.01,
      d_5d: 0,
      d_10d: 0,
      d_20d: -0.02,
      L_5d: 0.18,
      H_5d: 0.19,
      L_1m: 0.18,
      H_1m: 0.2,
      L_3m: 0.17,
      H_3m: 0.21,
      L_52w: 0.17,
      H_52w: 0.33,
      cur: 0.18,
      id: "0011",
    },
    "0012": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.08,
      H_5d: 0.08,
      L_1m: 0.08,
      H_1m: 0.08,
      L_3m: 0.08,
      H_3m: 0.09,
      L_52w: 0.08,
      H_52w: 0.16,
      cur: 0.08,
      id: "0012",
    },
    "0013": {
      d_1d: 0,
      d_3d: 0.05,
      d_5d: 0.12,
      d_10d: 0.21,
      d_20d: 0.22,
      L_5d: 0.15,
      H_5d: 0.25,
      L_1m: 0.03,
      H_1m: 0.25,
      L_3m: 0,
      H_3m: 0.25,
      L_52w: 0,
      H_52w: 0.25,
      cur: 0.25,
      id: "0013",
    },
    "0014": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0.01,
      d_20d: 0,
      L_5d: 0.04,
      H_5d: 0.04,
      L_1m: 0.03,
      H_1m: 0.04,
      L_3m: 0.03,
      H_3m: 0.04,
      L_52w: 0.03,
      H_52w: 0.16,
      cur: 0.04,
      id: "0014",
    },
    "0016": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0.01,
      d_20d: 0.02,
      L_5d: 0.27,
      H_5d: 0.27,
      L_1m: 0.25,
      H_1m: 0.27,
      L_3m: 0.25,
      H_3m: 0.27,
      L_52w: 0.24,
      H_52w: 0.5,
      cur: 0.27,
      id: "0016",
    },
    "0017": {
      d_1d: 0,
      d_3d: 0.01,
      d_5d: 0.01,
      d_10d: 0.01,
      d_20d: 0,
      L_5d: 0.16,
      H_5d: 0.17,
      L_1m: 0.16,
      H_1m: 0.17,
      L_3m: 0.16,
      H_3m: 0.17,
      L_52w: 0.16,
      H_52w: 0.48,
      cur: 0.17,
      id: "0017",
    },
    "0019": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.19,
      H_5d: 0.19,
      L_1m: 0.19,
      H_1m: 0.19,
      L_3m: 0.19,
      H_3m: 0.19,
      L_52w: 0.19,
      H_52w: 0.53,
      cur: 0.19,
      id: "0019",
    },
    "0023": {
      d_1d: 0,
      d_3d: 0.01,
      d_5d: 0.01,
      d_10d: 0.01,
      d_20d: 0.01,
      L_5d: 0.04,
      H_5d: 0.05,
      L_1m: 0.04,
      H_1m: 0.05,
      L_3m: 0.04,
      H_3m: 0.05,
      L_52w: 0.02,
      H_52w: 0.05,
      cur: 0.05,
      id: "0023",
    },
    "0027": {
      d_1d: 0,
      d_3d: -0.04,
      d_5d: -0.1,
      d_10d: -0.03,
      d_20d: 0.12,
      L_5d: 0.93,
      H_5d: 1.03,
      L_1m: 0.81,
      H_1m: 1.04,
      L_3m: 0.81,
      H_3m: 1.15,
      L_52w: 0.81,
      H_52w: 3.68,
      cur: 0.93,
      id: "0027",
    },
    "0035": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.03,
      H_5d: 0.03,
      L_1m: 0.03,
      H_1m: 0.03,
      L_3m: 0.03,
      H_3m: 0.03,
      L_52w: 0.03,
      H_52w: 0.04,
      cur: 0.03,
      id: "0035",
    },
    "0038": {
      d_1d: 0.23,
      d_3d: 0.37,
      d_5d: 0.49,
      d_10d: 0.99,
      d_20d: 1.36,
      L_5d: 33.82,
      H_5d: 34.35,
      L_1m: 33.29,
      H_1m: 34.35,
      L_3m: 32.74,
      H_3m: 34.35,
      L_52w: 26.32,
      H_52w: 38.2,
      cur: 34.35,
      id: "0038",
    },
    "0041": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.15,
      H_5d: 0.15,
      L_1m: 0.15,
      H_1m: 0.15,
      L_3m: 0.15,
      H_3m: 0.15,
      L_52w: 0.15,
      H_52w: 0.17,
      cur: 0.15,
      id: "0041",
    },
    "0042": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 5.59,
      H_5d: 5.59,
      L_1m: 5.59,
      H_1m: 5.59,
      L_3m: 5.59,
      H_3m: 5.59,
      L_52w: 5.59,
      H_52w: 6.02,
      cur: 5.59,
      id: "0042",
    },
    "0043": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: -0.01,
      L_5d: 0,
      H_5d: 0,
      L_1m: 0,
      H_1m: 0.01,
      L_3m: 0,
      H_3m: 0.03,
      L_52w: 0,
      H_52w: 0.03,
      cur: 0,
      id: "0043",
    },
    "0045": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0,
      H_5d: 0,
      L_1m: 0,
      H_1m: 0,
      L_3m: 0,
      H_3m: 0,
      L_52w: 0,
      H_52w: 0,
      cur: 0,
      id: "0045",
    },
    "0059": {
      d_1d: 0,
      d_3d: 0,
      d_5d: -0.01,
      d_10d: 0,
      d_20d: 0.05,
      L_5d: 1.99,
      H_5d: 2,
      L_1m: 1.94,
      H_1m: 2,
      L_3m: 1.92,
      H_3m: 2,
      L_52w: 0.8,
      H_52w: 2.11,
      cur: 1.99,
      id: "0059",
    },
    "0066": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0.01,
      d_20d: 0.02,
      L_5d: 0.13,
      H_5d: 0.13,
      L_1m: 0.12,
      H_1m: 0.13,
      L_3m: 0.09,
      H_3m: 0.13,
      L_52w: 0.09,
      H_52w: 0.19,
      cur: 0.13,
      id: "0066",
    },
    "0069": {
      d_1d: 0.01,
      d_3d: 0.02,
      d_5d: 0.01,
      d_10d: 0.01,
      d_20d: 0.01,
      L_5d: 0.51,
      H_5d: 0.53,
      L_1m: 0.51,
      H_1m: 0.53,
      L_3m: 0.49,
      H_3m: 0.53,
      L_52w: 0.44,
      H_52w: 0.54,
      cur: 0.53,
      id: "0069",
    },
    "0078": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0,
      H_5d: 0,
      L_1m: 0,
      H_1m: 0,
      L_3m: 0,
      H_3m: 0,
      L_52w: 0,
      H_52w: 0,
      cur: 0,
      id: "0078",
    },
    "0081": {
      d_1d: 0.02,
      d_3d: 0.01,
      d_5d: -0.01,
      d_10d: -0.08,
      d_20d: 0.06,
      L_5d: 8.6,
      H_5d: 8.62,
      L_1m: 8.59,
      H_1m: 8.72,
      L_3m: 8.5,
      H_3m: 8.72,
      L_52w: 8.5,
      H_52w: 10.28,
      cur: 8.62,
      id: "0081",
    },
    "0083": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.06,
      H_5d: 0.06,
      L_1m: 0.05,
      H_1m: 0.06,
      L_3m: 0.05,
      H_3m: 0.06,
      L_52w: 0.04,
      H_52w: 0.06,
      cur: 0.06,
      id: "0083",
    },
    "0086": {
      d_1d: -0.05,
      d_3d: 0.48,
      d_5d: 0.48,
      d_10d: 0.48,
      d_20d: 0.48,
      L_5d: 0.74,
      H_5d: 1.27,
      L_1m: 0.74,
      H_1m: 1.27,
      L_3m: 0.74,
      H_3m: 1.27,
      L_52w: 0.67,
      H_52w: 1.27,
      cur: 1.22,
      id: "0086",
    },
    "0087": {
      d_1d: 0,
      d_3d: 0.01,
      d_5d: 0.01,
      d_10d: 0.03,
      d_20d: 0.03,
      L_5d: 0.85,
      H_5d: 0.86,
      L_1m: 0.83,
      H_1m: 0.86,
      L_3m: 0.8,
      H_3m: 0.86,
      L_52w: 0.77,
      H_52w: 0.92,
      cur: 0.86,
      id: "0087",
    },
    "0095": {
      d_1d: 0,
      d_3d: 0.03,
      d_5d: 0.04,
      d_10d: 0.06,
      d_20d: 0.13,
      L_5d: 3.33,
      H_5d: 3.38,
      L_1m: 3.27,
      H_1m: 3.38,
      L_3m: 3.24,
      H_3m: 3.38,
      L_52w: 3.12,
      H_52w: 3.51,
      cur: 3.38,
      id: "0095",
    },
    "0101": {
      d_1d: 0,
      d_3d: -0.03,
      d_5d: -0.07,
      d_10d: -0.1,
      d_20d: -0.21,
      L_5d: 1.78,
      H_5d: 1.83,
      L_1m: 1.78,
      H_1m: 1.97,
      L_3m: 1.78,
      H_3m: 1.99,
      L_52w: 1.78,
      H_52w: 2,
      cur: 1.78,
      id: "0101",
    },
    "0107": {
      d_1d: -0.03,
      d_3d: 0.05,
      d_5d: 0.06,
      d_10d: -0.14,
      d_20d: -0.02,
      L_5d: 27.26,
      H_5d: 27.36,
      L_1m: 27.26,
      H_1m: 27.51,
      L_3m: 26.49,
      H_3m: 27.51,
      L_52w: 25.29,
      H_52w: 27.51,
      cur: 27.33,
      id: "0107",
    },
    "0109": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.63,
      H_5d: 0.63,
      L_1m: 0.63,
      H_1m: 0.63,
      L_3m: 0.63,
      H_3m: 0.63,
      L_52w: 0.63,
      H_52w: 0.63,
      cur: 0.63,
      id: "0109",
    },
    "0116": {
      d_1d: 0.03,
      d_3d: 0.02,
      d_5d: 0.04,
      d_10d: 0.05,
      d_20d: 0.08,
      L_5d: 3.46,
      H_5d: 3.5,
      L_1m: 3.4,
      H_1m: 3.5,
      L_3m: 2.94,
      H_3m: 3.5,
      L_52w: 2.82,
      H_52w: 3.5,
      cur: 3.5,
      id: "0116",
    },
    "0119": {
      d_1d: 0.01,
      d_3d: 0.01,
      d_5d: 0.01,
      d_10d: 0.14,
      d_20d: 0.58,
      L_5d: 13.6,
      H_5d: 13.62,
      L_1m: 13.09,
      H_1m: 13.62,
      L_3m: 12.55,
      H_3m: 13.62,
      L_52w: 10.1,
      H_52w: 13.62,
      cur: 13.62,
      id: "0119",
    },
    "0120": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0,
      H_5d: 0,
      L_1m: 0,
      H_1m: 0,
      L_3m: 0,
      H_3m: 0,
      L_52w: 0,
      H_52w: 0.05,
      cur: 0,
      id: "0120",
    },
    "0123": {
      d_1d: 0,
      d_3d: -0.02,
      d_5d: -0.02,
      d_10d: -0.03,
      d_20d: -0.06,
      L_5d: 1.53,
      H_5d: 1.55,
      L_1m: 1.53,
      H_1m: 1.59,
      L_3m: 1.53,
      H_3m: 1.7,
      L_52w: 0,
      H_52w: 11.35,
      cur: 1.53,
      id: "0123",
    },
    "0127": {
      d_1d: -0.01,
      d_3d: -0.01,
      d_5d: -0.01,
      d_10d: -0.01,
      d_20d: -0.01,
      L_5d: 0.05,
      H_5d: 0.06,
      L_1m: 0.05,
      H_1m: 0.06,
      L_3m: 0.05,
      H_3m: 0.07,
      L_52w: 0.05,
      H_52w: 0.14,
      cur: 0.05,
      id: "0127",
    },
    "0135": {
      d_1d: -0.01,
      d_3d: -0.02,
      d_5d: -0.01,
      d_10d: 0,
      d_20d: -0.02,
      L_5d: 0.53,
      H_5d: 0.55,
      L_1m: 0.52,
      H_1m: 0.57,
      L_3m: 0.43,
      H_3m: 0.57,
      L_52w: 0.43,
      H_52w: 1.77,
      cur: 0.53,
      id: "0135",
    },
    "0136": {
      d_1d: 0.02,
      d_3d: -0.11,
      d_5d: -0.34,
      d_10d: -0.71,
      d_20d: 2.53,
      L_5d: 11.84,
      H_5d: 11.97,
      L_1m: 9.87,
      H_1m: 12.83,
      L_3m: 7.63,
      H_3m: 12.83,
      L_52w: 7.18,
      H_52w: 85.62,
      cur: 11.86,
      id: "0136",
    },
    "0142": {
      d_1d: 0,
      d_3d: 0.01,
      d_5d: 0.01,
      d_10d: 0,
      d_20d: -0.01,
      L_5d: 0.34,
      H_5d: 0.35,
      L_1m: 0.34,
      H_1m: 0.36,
      L_3m: 0.32,
      H_3m: 0.39,
      L_52w: 0.09,
      H_52w: 0.39,
      cur: 0.35,
      id: "0142",
    },
    "0144": {
      d_1d: 0.01,
      d_3d: 0,
      d_5d: -0.01,
      d_10d: -0.02,
      d_20d: -0.14,
      L_5d: 1.95,
      H_5d: 1.96,
      L_1m: 1.95,
      H_1m: 2.09,
      L_3m: 1.95,
      H_3m: 2.42,
      L_52w: 1.95,
      H_52w: 4.07,
      cur: 1.96,
      id: "0144",
    },
    "0148": {
      d_1d: 0.01,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0.01,
      L_5d: 1.12,
      H_5d: 1.14,
      L_1m: 1.12,
      H_1m: 1.14,
      L_3m: 1.09,
      H_3m: 1.14,
      L_52w: 1.08,
      H_52w: 1.39,
      cur: 1.13,
      id: "0148",
    },
    "0151": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0.01,
      L_5d: 0.09,
      H_5d: 0.09,
      L_1m: 0.07,
      H_1m: 0.1,
      L_3m: 0.07,
      H_3m: 0.1,
      L_52w: 0.07,
      H_52w: 0.2,
      cur: 0.09,
      id: "0151",
    },
    "0152": {
      d_1d: 0.01,
      d_3d: 0.01,
      d_5d: 0.01,
      d_10d: -0.03,
      d_20d: -0.09,
      L_5d: 3.25,
      H_5d: 3.26,
      L_1m: 3.24,
      H_1m: 3.33,
      L_3m: 3.24,
      H_3m: 3.35,
      L_52w: 3.21,
      H_52w: 3.58,
      cur: 3.26,
      id: "0152",
    },
    "0163": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.28,
      H_5d: 0.28,
      L_1m: 0.28,
      H_1m: 0.28,
      L_3m: 0.28,
      H_3m: 0.28,
      L_52w: 0.28,
      H_52w: 0.29,
      cur: 0.28,
      id: "0163",
    },
    "0165": {
      d_1d: 0.01,
      d_3d: 0.17,
      d_5d: 0.3,
      d_10d: 0.5,
      d_20d: 0.77,
      L_5d: 13.11,
      H_5d: 13.38,
      L_1m: 12.62,
      H_1m: 13.38,
      L_3m: 12.1,
      H_3m: 13.38,
      L_52w: 12.1,
      H_52w: 13.38,
      cur: 13.38,
      id: "0165",
    },
    "0168": {
      d_1d: -0.01,
      d_3d: -0.21,
      d_5d: -0.12,
      d_10d: -0.12,
      d_20d: 0,
      L_5d: 19.42,
      H_5d: 19.63,
      L_1m: 19.4,
      H_1m: 19.63,
      L_3m: 18.88,
      H_3m: 21.05,
      L_52w: 14.63,
      H_52w: 22.35,
      cur: 19.42,
      id: "0168",
    },
    "0173": {
      d_1d: 0.01,
      d_3d: 0.01,
      d_5d: 0.01,
      d_10d: 0,
      d_20d: 0,
      L_5d: 1.01,
      H_5d: 1.02,
      L_1m: 1.01,
      H_1m: 1.02,
      L_3m: 1,
      H_3m: 1.03,
      L_52w: 1,
      H_52w: 1.26,
      cur: 1.02,
      id: "0173",
    },
    "0175": {
      d_1d: -0.11,
      d_3d: -0.49,
      d_5d: -0.54,
      d_10d: -0.75,
      d_20d: -0.64,
      L_5d: 16.78,
      H_5d: 17.28,
      L_1m: 16.78,
      H_1m: 17.63,
      L_3m: 16.67,
      H_3m: 17.63,
      L_52w: 7.45,
      H_52w: 17.63,
      cur: 16.78,
      id: "0175",
    },
    "0177": {
      d_1d: -0.01,
      d_3d: -0.06,
      d_5d: -0.08,
      d_10d: -0.28,
      d_20d: -0.2,
      L_5d: 10.8,
      H_5d: 10.86,
      L_1m: 10.8,
      H_1m: 11.15,
      L_3m: 9.63,
      H_3m: 11.15,
      L_52w: 9.38,
      H_52w: 11.15,
      cur: 10.8,
      id: "0177",
    },
    "0178": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: -0.01,
      L_5d: 0.17,
      H_5d: 0.17,
      L_1m: 0.17,
      H_1m: 0.18,
      L_3m: 0.17,
      H_3m: 0.19,
      L_52w: 0.17,
      H_52w: 0.48,
      cur: 0.17,
      id: "0178",
    },
    "0179": {
      d_1d: 0,
      d_3d: 0,
      d_5d: -0.01,
      d_10d: -0.01,
      d_20d: -0.01,
      L_5d: 0.1,
      H_5d: 0.11,
      L_1m: 0.1,
      H_1m: 0.12,
      L_3m: 0.1,
      H_3m: 0.13,
      L_52w: 0.09,
      H_52w: 0.24,
      cur: 0.1,
      id: "0179",
    },
    "0187": {
      d_1d: 0.17,
      d_3d: 0.25,
      d_5d: 0.16,
      d_10d: -1.29,
      d_20d: -1.21,
      L_5d: 18.91,
      H_5d: 19.16,
      L_1m: 18.56,
      H_1m: 20.46,
      L_3m: 18.34,
      H_3m: 20.46,
      L_52w: 18.3,
      H_52w: 27.01,
      cur: 19.16,
      id: "0187",
    },
    "0189": {
      d_1d: -0.51,
      d_3d: 0,
      d_5d: 0.27,
      d_10d: -0.94,
      d_20d: -3.3,
      L_5d: 23.82,
      H_5d: 24.56,
      L_1m: 23.78,
      H_1m: 27.11,
      L_3m: 23.78,
      H_3m: 32.96,
      L_52w: 9.08,
      H_52w: 32.96,
      cur: 24.05,
      id: "0189",
    },
    "0200": {
      d_1d: 0,
      d_3d: 0.01,
      d_5d: 0.01,
      d_10d: 0.01,
      d_20d: -0.03,
      L_5d: 0.41,
      H_5d: 0.43,
      L_1m: 0.41,
      H_1m: 0.44,
      L_3m: 0.34,
      H_3m: 0.47,
      L_52w: 0.3,
      H_52w: 0.85,
      cur: 0.42,
      id: "0200",
    },
    "0207": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.03,
      H_5d: 0.03,
      L_1m: 0.03,
      H_1m: 0.03,
      L_3m: 0.03,
      H_3m: 0.03,
      L_52w: 0.03,
      H_52w: 0.03,
      cur: 0.03,
      id: "0207",
    },
    "0215": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.12,
      H_5d: 0.12,
      L_1m: 0.12,
      H_1m: 0.12,
      L_3m: 0.12,
      H_3m: 0.14,
      L_52w: 0.11,
      H_52w: 0.24,
      cur: 0.12,
      id: "0215",
    },
    "0220": {
      d_1d: 0,
      d_3d: -0.03,
      d_5d: -0.03,
      d_10d: 0.01,
      d_20d: 0,
      L_5d: 0.16,
      H_5d: 0.19,
      L_1m: 0.11,
      H_1m: 0.19,
      L_3m: 0.1,
      H_3m: 0.19,
      L_52w: 0.07,
      H_52w: 0.2,
      cur: 0.16,
      id: "0220",
    },
    "0241": {
      d_1d: 0.03,
      d_3d: 0.08,
      d_5d: 0.16,
      d_10d: 0.32,
      d_20d: 0.3,
      L_5d: 2.07,
      H_5d: 2.18,
      L_1m: 1.86,
      H_1m: 2.18,
      L_3m: 1.73,
      H_3m: 2.18,
      L_52w: 1.62,
      H_52w: 2.18,
      cur: 2.18,
      id: "0241",
    },
    "0242": {
      d_1d: 0,
      d_3d: 0.01,
      d_5d: -0.03,
      d_10d: -0.04,
      d_20d: -0.08,
      L_5d: 3.38,
      H_5d: 3.4,
      L_1m: 3.38,
      H_1m: 3.47,
      L_3m: 3.36,
      H_3m: 3.48,
      L_52w: 3.03,
      H_52w: 3.48,
      cur: 3.39,
      id: "0242",
    },
    "0245": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.07,
      H_5d: 0.07,
      L_1m: 0.07,
      H_1m: 0.07,
      L_3m: 0.07,
      H_3m: 0.07,
      L_52w: 0.07,
      H_52w: 0.07,
      cur: 0.07,
      id: "0245",
    },
    "0256": {
      d_1d: 0.01,
      d_3d: 0.03,
      d_5d: 0.04,
      d_10d: 0.12,
      d_20d: 0.17,
      L_5d: 10.24,
      H_5d: 10.27,
      L_1m: 10.1,
      H_1m: 10.27,
      L_3m: 9.26,
      H_3m: 10.27,
      L_52w: 8.51,
      H_52w: 10.27,
      cur: 10.27,
      id: "0256",
    },
    "0257": {
      d_1d: 0.02,
      d_3d: -0.01,
      d_5d: 0.05,
      d_10d: 0.17,
      d_20d: 0.39,
      L_5d: 12.45,
      H_5d: 12.51,
      L_1m: 12.16,
      H_1m: 12.51,
      L_3m: 11.26,
      H_3m: 12.51,
      L_52w: 9.67,
      H_52w: 12.51,
      cur: 12.5,
      id: "0257",
    },
    "0267": {
      d_1d: 0.01,
      d_3d: 0.01,
      d_5d: 0.01,
      d_10d: 0.01,
      d_20d: -0.01,
      L_5d: 0.51,
      H_5d: 0.52,
      L_1m: 0.51,
      H_1m: 0.52,
      L_3m: 0.51,
      H_3m: 0.6,
      L_52w: 0.51,
      H_52w: 0.87,
      cur: 0.52,
      id: "0267",
    },
    "0268": {
      d_1d: 0.12,
      d_3d: 0.17,
      d_5d: 0.14,
      d_10d: 0.07,
      d_20d: 0.06,
      L_5d: 15.01,
      H_5d: 15.18,
      L_1m: 15.01,
      H_1m: 15.22,
      L_3m: 15.01,
      H_3m: 16.9,
      L_52w: 15.01,
      H_52w: 19.43,
      cur: 15.18,
      id: "0268",
    },
    "0270": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: -0.01,
      L_5d: 0.39,
      H_5d: 0.39,
      L_1m: 0.39,
      H_1m: 0.4,
      L_3m: 0.39,
      H_3m: 0.46,
      L_52w: 0.39,
      H_52w: 0.65,
      cur: 0.39,
      id: "0270",
    },
    "0272": {
      d_1d: 0.01,
      d_3d: 0.08,
      d_5d: 0.09,
      d_10d: 0.14,
      d_20d: 0.29,
      L_5d: 9.73,
      H_5d: 9.82,
      L_1m: 9.58,
      H_1m: 9.82,
      L_3m: 9.23,
      H_3m: 9.82,
      L_52w: 9.23,
      H_52w: 10.43,
      cur: 9.82,
      id: "0272",
    },
    "0279": {
      d_1d: 0,
      d_3d: -0.01,
      d_5d: -0.01,
      d_10d: -0.01,
      d_20d: -0.28,
      L_5d: 2.72,
      H_5d: 2.73,
      L_1m: 2.72,
      H_1m: 2.94,
      L_3m: 2.72,
      H_3m: 32.77,
      L_52w: 2.72,
      H_52w: 32.77,
      cur: 2.72,
      id: "0279",
    },
    "0285": {
      d_1d: 0.07,
      d_3d: 0.05,
      d_5d: 0.36,
      d_10d: 0.77,
      d_20d: 1.6,
      L_5d: 9.26,
      H_5d: 9.39,
      L_1m: 7.8,
      H_1m: 9.39,
      L_3m: 7.43,
      H_3m: 10.52,
      L_52w: 7.43,
      H_52w: 12.43,
      cur: 9.39,
      id: "0285",
    },
    "0288": {
      d_1d: 0.02,
      d_3d: 0.06,
      d_5d: 0.1,
      d_10d: 0.15,
      d_20d: 0.31,
      L_5d: 3.97,
      H_5d: 4.05,
      L_1m: 3.78,
      H_1m: 4.05,
      L_3m: 0.62,
      H_3m: 4.05,
      L_52w: 0.62,
      H_52w: 7.38,
      cur: 4.05,
      id: "0288",
    },
    "0291": {
      d_1d: -0.04,
      d_3d: -0.16,
      d_5d: -0.13,
      d_10d: -0.12,
      d_20d: -0.1,
      L_5d: 16.37,
      H_5d: 16.53,
      L_1m: 16.37,
      H_1m: 16.53,
      L_3m: 16.29,
      H_3m: 17.02,
      L_52w: 11.81,
      H_52w: 17.02,
      cur: 16.37,
      id: "0291",
    },
    "0293": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0.01,
      d_10d: 0,
      d_20d: 0.01,
      L_5d: 0.19,
      H_5d: 0.2,
      L_1m: 0.19,
      H_1m: 0.2,
      L_3m: 0.19,
      H_3m: 0.26,
      L_52w: 0.19,
      H_52w: 0.28,
      cur: 0.2,
      id: "0293",
    },
    "0295": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: -0.01,
      L_5d: 0.32,
      H_5d: 0.32,
      L_1m: 0.32,
      H_1m: 0.32,
      L_3m: 0.32,
      H_3m: 0.33,
      L_52w: 0.32,
      H_52w: 0.34,
      cur: 0.32,
      id: "0295",
    },
    "0297": {
      d_1d: 0,
      d_3d: -0.01,
      d_5d: 0,
      d_10d: -0.02,
      d_20d: -0.01,
      L_5d: 4.13,
      H_5d: 4.15,
      L_1m: 4.13,
      H_1m: 4.16,
      L_3m: 4.13,
      H_3m: 4.7,
      L_52w: 4.13,
      H_52w: 5.74,
      cur: 4.14,
      id: "0297",
    },
    "0302": {
      d_1d: 0.03,
      d_3d: 0.08,
      d_5d: 0.07,
      d_10d: 0,
      d_20d: 0.15,
      L_5d: 6.34,
      H_5d: 6.42,
      L_1m: 6.32,
      H_1m: 6.54,
      L_3m: 6.18,
      H_3m: 6.54,
      L_52w: 6.11,
      H_52w: 9.38,
      cur: 6.42,
      id: "0302",
    },
    "0303": {
      d_1d: 0.03,
      d_3d: 0.08,
      d_5d: 0.09,
      d_10d: 0.09,
      d_20d: 0.11,
      L_5d: 0.83,
      H_5d: 0.91,
      L_1m: 0.8,
      H_1m: 0.91,
      L_3m: 0.69,
      H_3m: 0.91,
      L_52w: 0.39,
      H_52w: 0.91,
      cur: 0.91,
      id: "0303",
    },
    "0308": {
      d_1d: 0,
      d_3d: -0.03,
      d_5d: -0.1,
      d_10d: -0.12,
      d_20d: -0.14,
      L_5d: 3.46,
      H_5d: 3.49,
      L_1m: 3.46,
      H_1m: 3.59,
      L_3m: 3.46,
      H_3m: 3.63,
      L_52w: 2.78,
      H_52w: 3.63,
      cur: 3.46,
      id: "0308",
    },
    "0315": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.26,
      H_5d: 0.26,
      L_1m: 0.26,
      H_1m: 0.26,
      L_3m: 0.26,
      H_3m: 0.28,
      L_52w: 0.17,
      H_52w: 0.37,
      cur: 0.26,
      id: "0315",
    },
    "0316": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0,
      H_5d: 0,
      L_1m: 0,
      H_1m: 0,
      L_3m: 0,
      H_3m: 0,
      L_52w: 0,
      H_52w: 0,
      cur: 0,
      id: "0316",
    },
    "0317": {
      d_1d: 0,
      d_3d: -0.05,
      d_5d: 0,
      d_10d: 0.09,
      d_20d: 0.27,
      L_5d: 6.14,
      H_5d: 6.19,
      L_1m: 5.79,
      H_1m: 6.19,
      L_3m: 5.48,
      H_3m: 6.19,
      L_52w: 4.11,
      H_52w: 6.19,
      cur: 6.14,
      id: "0317",
    },
    "0321": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.06,
      H_5d: 0.06,
      L_1m: 0.06,
      H_1m: 0.06,
      L_3m: 0.06,
      H_3m: 0.07,
      L_52w: 0.06,
      H_52w: 0.07,
      cur: 0.06,
      id: "0321",
    },
    "0322": {
      d_1d: -0.01,
      d_3d: -0.02,
      d_5d: -0.02,
      d_10d: -0.09,
      d_20d: -0.03,
      L_5d: 1.36,
      H_5d: 1.38,
      L_1m: 1.36,
      H_1m: 1.45,
      L_3m: 1.36,
      H_3m: 1.56,
      L_52w: 0.44,
      H_52w: 1.86,
      cur: 1.36,
      id: "0322",
    },
    "0323": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0.12,
      d_10d: 0.19,
      d_20d: -0.2,
      L_5d: 5.17,
      H_5d: 5.29,
      L_1m: 5,
      H_1m: 5.42,
      L_3m: 4.31,
      H_3m: 6.14,
      L_52w: 0.31,
      H_52w: 6.14,
      cur: 5.28,
      id: "0323",
    },
    "0327": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.45,
      H_5d: 0.45,
      L_1m: 0.45,
      H_1m: 0.45,
      L_3m: 0.45,
      H_3m: 0.45,
      L_52w: 0.45,
      H_52w: 0.57,
      cur: 0.45,
      id: "0327",
    },
    "0330": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.3,
      H_5d: 0.3,
      L_1m: 0.3,
      H_1m: 0.3,
      L_3m: 0.3,
      H_3m: 0.3,
      L_52w: 0.3,
      H_52w: 0.46,
      cur: 0.3,
      id: "0330",
    },
    "0336": {
      d_1d: 0.04,
      d_3d: 0.19,
      d_5d: 0.18,
      d_10d: 0.46,
      d_20d: 0.33,
      L_5d: 11.79,
      H_5d: 11.98,
      L_1m: 11.49,
      H_1m: 11.98,
      L_3m: 11.49,
      H_3m: 11.98,
      L_52w: 11.49,
      H_52w: 13.59,
      cur: 11.98,
      id: "0336",
    },
    "0337": {
      d_1d: 0.01,
      d_3d: 0,
      d_5d: -0.01,
      d_10d: 0.02,
      d_20d: 0.15,
      L_5d: 4.35,
      H_5d: 4.36,
      L_1m: 4.21,
      H_1m: 4.37,
      L_3m: 4.02,
      H_3m: 4.37,
      L_52w: 3.79,
      H_52w: 4.37,
      cur: 4.36,
      id: "0337",
    },
    "0338": {
      d_1d: 0.04,
      d_3d: -0.1,
      d_5d: 0.09,
      d_10d: 0.13,
      d_20d: 0.85,
      L_5d: 16.41,
      H_5d: 16.55,
      L_1m: 15.71,
      H_1m: 16.55,
      L_3m: 14.76,
      H_3m: 16.55,
      L_52w: 12.59,
      H_52w: 16.55,
      cur: 16.45,
      id: "0338",
    },
    "0341": {
      d_1d: 0.01,
      d_3d: 0.29,
      d_5d: 0.5,
      d_10d: 0.49,
      d_20d: 0.48,
      L_5d: 0.19,
      H_5d: 0.66,
      L_1m: 0.16,
      H_1m: 0.66,
      L_3m: 0.16,
      H_3m: 0.66,
      L_52w: 0.16,
      H_52w: 1.03,
      cur: 0.66,
      id: "0341",
    },
    "0345": {
      d_1d: 0.01,
      d_3d: 0.01,
      d_5d: 0.03,
      d_10d: 0.03,
      d_20d: 0.05,
      L_5d: 0.32,
      H_5d: 0.35,
      L_1m: 0.3,
      H_1m: 0.35,
      L_3m: 0.28,
      H_3m: 0.35,
      L_52w: 0.28,
      H_52w: 0.66,
      cur: 0.35,
      id: "0345",
    },
    "0347": {
      d_1d: 0.03,
      d_3d: 0.08,
      d_5d: 0.11,
      d_10d: 0.05,
      d_20d: -0.04,
      L_5d: 0.69,
      H_5d: 0.81,
      L_1m: 0.69,
      H_1m: 0.94,
      L_3m: 0.69,
      H_3m: 3.66,
      L_52w: 0.37,
      H_52w: 3.66,
      cur: 0.81,
      id: "0347",
    },
    "0354": {
      d_1d: 0.02,
      d_3d: -0.04,
      d_5d: 0.01,
      d_10d: -0.11,
      d_20d: -0.11,
      L_5d: 10.9,
      H_5d: 10.96,
      L_1m: 10.9,
      H_1m: 11.08,
      L_3m: 10.9,
      H_3m: 11.78,
      L_52w: 10.9,
      H_52w: 15.91,
      cur: 10.92,
      id: "0354",
    },
    "0358": {
      d_1d: 0.02,
      d_3d: 0.04,
      d_5d: 0.11,
      d_10d: 0.16,
      d_20d: 0.15,
      L_5d: 3.33,
      H_5d: 3.41,
      L_1m: 3.23,
      H_1m: 3.41,
      L_3m: 2.98,
      H_3m: 3.65,
      L_52w: 2.71,
      H_52w: 6.48,
      cur: 3.41,
      id: "0358",
    },
    "0363": {
      d_1d: 0,
      d_3d: 0,
      d_5d: -0.01,
      d_10d: -0.01,
      d_20d: -0.04,
      L_5d: 2.58,
      H_5d: 2.59,
      L_1m: 2.57,
      H_1m: 2.6,
      L_3m: 2.54,
      H_3m: 2.65,
      L_52w: 2.54,
      H_52w: 3.29,
      cur: 2.58,
      id: "0363",
    },
    "0371": {
      d_1d: 0.03,
      d_3d: 0.03,
      d_5d: 0.05,
      d_10d: 0.05,
      d_20d: 0.04,
      L_5d: 3.1,
      H_5d: 3.14,
      L_1m: 3.09,
      H_1m: 3.14,
      L_3m: 2.99,
      H_3m: 3.16,
      L_52w: 2.56,
      H_52w: 3.16,
      cur: 3.14,
      id: "0371",
    },
    "0378": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 2.42,
      H_5d: 2.42,
      L_1m: 2.42,
      H_1m: 2.42,
      L_3m: 2.42,
      H_3m: 2.42,
      L_52w: 2.42,
      H_52w: 2.67,
      cur: 2.42,
      id: "0378",
    },
    "0384": {
      d_1d: 0,
      d_3d: -0.01,
      d_5d: 0.01,
      d_10d: -0.06,
      d_20d: -0.22,
      L_5d: 1.52,
      H_5d: 1.58,
      L_1m: 1.51,
      H_1m: 1.75,
      L_3m: 1.51,
      H_3m: 1.99,
      L_52w: 0.73,
      H_52w: 2.34,
      cur: 1.54,
      id: "0384",
    },
    "0386": {
      d_1d: -0.01,
      d_3d: 0.07,
      d_5d: 0.11,
      d_10d: 0.13,
      d_20d: 0.1,
      L_5d: 3.46,
      H_5d: 3.58,
      L_1m: 3.43,
      H_1m: 3.58,
      L_3m: 3.43,
      H_3m: 3.73,
      L_52w: 3.43,
      H_52w: 5.72,
      cur: 3.57,
      id: "0386",
    },
    "0388": {
      d_1d: -0.01,
      d_3d: 0,
      d_5d: 0.03,
      d_10d: 0.01,
      d_20d: -0.17,
      L_5d: 6.04,
      H_5d: 6.06,
      L_1m: 6.02,
      H_1m: 6.21,
      L_3m: 6.02,
      H_3m: 6.4,
      L_52w: 5.79,
      H_52w: 9.17,
      cur: 6.05,
      id: "0388",
    },
    "0390": {
      d_1d: 0.06,
      d_3d: 0.17,
      d_5d: 0.28,
      d_10d: 0.33,
      d_20d: 0.34,
      L_5d: 11.62,
      H_5d: 11.85,
      L_1m: 11.49,
      H_1m: 11.85,
      L_3m: 10.79,
      H_3m: 11.85,
      L_52w: 7.2,
      H_52w: 11.85,
      cur: 11.85,
      id: "0390",
    },
    "0392": {
      d_1d: 0.01,
      d_3d: -0.1,
      d_5d: -0.11,
      d_10d: -0.12,
      d_20d: -0.09,
      L_5d: 5.57,
      H_5d: 5.68,
      L_1m: 5.57,
      H_1m: 5.72,
      L_3m: 5.57,
      H_3m: 5.9,
      L_52w: 5.57,
      H_52w: 7.42,
      cur: 5.58,
      id: "0392",
    },
    "0400": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: -0.2,
      L_5d: 2.29,
      H_5d: 2.29,
      L_1m: 2.29,
      H_1m: 2.48,
      L_3m: 2.29,
      H_3m: 2.57,
      L_52w: 2.29,
      H_52w: 3.47,
      cur: 2.29,
      id: "0400",
    },
    "0410": {
      d_1d: 0.01,
      d_3d: 0.11,
      d_5d: 0.08,
      d_10d: 0.17,
      d_20d: 0.24,
      L_5d: 8.55,
      H_5d: 8.66,
      L_1m: 8.45,
      H_1m: 8.66,
      L_3m: 7.66,
      H_3m: 8.66,
      L_52w: 5.14,
      H_52w: 8.97,
      cur: 8.66,
      id: "0410",
    },
    "0412": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: -0.01,
      d_20d: -0.01,
      L_5d: 0.02,
      H_5d: 0.02,
      L_1m: 0.02,
      H_1m: 0.03,
      L_3m: 0.02,
      H_3m: 0.03,
      L_52w: 0.02,
      H_52w: 0.04,
      cur: 0.02,
      id: "0412",
    },
    "0419": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.83,
      H_5d: 0.83,
      L_1m: 0.83,
      H_1m: 0.83,
      L_3m: 0.83,
      H_3m: 0.83,
      L_52w: 0.83,
      H_52w: 1.22,
      cur: 0.83,
      id: "0419",
    },
    "0425": {
      d_1d: 0.02,
      d_3d: 0.04,
      d_5d: 0.1,
      d_10d: 0.2,
      d_20d: 0.36,
      L_5d: 1.85,
      H_5d: 1.9,
      L_1m: 1.51,
      H_1m: 1.9,
      L_3m: 1.32,
      H_3m: 1.9,
      L_52w: 1.32,
      H_52w: 2.97,
      cur: 1.9,
      id: "0425",
    },
    "0432": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.01,
      H_5d: 0.01,
      L_1m: 0.01,
      H_1m: 0.01,
      L_3m: 0.01,
      H_3m: 0.01,
      L_52w: 0.01,
      H_52w: 0.02,
      cur: 0.01,
      id: "0432",
    },
    "0439": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: -0.01,
      L_5d: 4.19,
      H_5d: 4.19,
      L_1m: 4.19,
      H_1m: 4.19,
      L_3m: 4.19,
      H_3m: 4.21,
      L_52w: 4.19,
      H_52w: 4.36,
      cur: 4.19,
      id: "0439",
    },
    "0440": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0.01,
      d_10d: 0,
      d_20d: -0.02,
      L_5d: 0.43,
      H_5d: 0.43,
      L_1m: 0.42,
      H_1m: 0.45,
      L_3m: 0.42,
      H_3m: 0.47,
      L_52w: 0.42,
      H_52w: 0.9,
      cur: 0.43,
      id: "0440",
    },
    "0451": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: -0.02,
      d_20d: -0.06,
      L_5d: 1.18,
      H_5d: 1.18,
      L_1m: 1.18,
      H_1m: 1.23,
      L_3m: 1.18,
      H_3m: 1.26,
      L_52w: 1.18,
      H_52w: 6.22,
      cur: 1.18,
      id: "0451",
    },
    "0460": {
      d_1d: 0.03,
      d_3d: 0.24,
      d_5d: 0.34,
      d_10d: 0.41,
      d_20d: -0.32,
      L_5d: 6.26,
      H_5d: 6.62,
      L_1m: 6.06,
      H_1m: 6.82,
      L_3m: 6.06,
      H_3m: 10.25,
      L_52w: 5.88,
      H_52w: 13.31,
      cur: 6.62,
      id: "0460",
    },
    "0467": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.44,
      H_5d: 0.44,
      L_1m: 0.44,
      H_1m: 0.44,
      L_3m: 0.44,
      H_3m: 0.44,
      L_52w: 0.44,
      H_52w: 0.99,
      cur: 0.44,
      id: "0467",
    },
    "0468": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: -0.01,
      L_5d: 0.17,
      H_5d: 0.17,
      L_1m: 0.17,
      H_1m: 0.17,
      L_3m: 0.17,
      H_3m: 0.18,
      L_52w: 0.17,
      H_52w: 0.42,
      cur: 0.17,
      id: "0468",
    },
    "0480": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0,
      H_5d: 0,
      L_1m: 0,
      H_1m: 0,
      L_3m: 0,
      H_3m: 0,
      L_52w: 0,
      H_52w: 0,
      cur: 0,
      id: "0480",
    },
    "0488": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0,
      H_5d: 0,
      L_1m: 0,
      H_1m: 0,
      L_3m: 0,
      H_3m: 0,
      L_52w: 0,
      H_52w: 0,
      cur: 0,
      id: "0488",
    },
    "0489": {
      d_1d: -0.01,
      d_3d: 0.05,
      d_5d: 0.19,
      d_10d: 0.28,
      d_20d: 0.24,
      L_5d: 6.27,
      H_5d: 6.41,
      L_1m: 6.11,
      H_1m: 6.41,
      L_3m: 5.95,
      H_3m: 6.44,
      L_52w: 5.95,
      H_52w: 8.29,
      cur: 6.4,
      id: "0489",
    },
    "0493": {
      d_1d: 0.01,
      d_3d: 0.13,
      d_5d: 0.22,
      d_10d: 0.5,
      d_20d: 0.61,
      L_5d: 10.28,
      H_5d: 10.49,
      L_1m: 9.89,
      H_1m: 10.49,
      L_3m: 9.83,
      H_3m: 13.94,
      L_52w: 9.83,
      H_52w: 14.77,
      cur: 10.49,
      id: "0493",
    },
    "0496": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: -0.03,
      d_20d: -0.08,
      L_5d: 7.64,
      H_5d: 7.64,
      L_1m: 7.64,
      H_1m: 7.72,
      L_3m: 7.64,
      H_3m: 8.1,
      L_52w: 7.64,
      H_52w: 10.12,
      cur: 7.64,
      id: "0496",
    },
    "0506": {
      d_1d: 0.01,
      d_3d: 0,
      d_5d: 0,
      d_10d: -0.04,
      d_20d: -0.15,
      L_5d: 0.92,
      H_5d: 0.93,
      L_1m: 0.92,
      H_1m: 1.08,
      L_3m: 0.92,
      H_3m: 1.1,
      L_52w: 0.92,
      H_52w: 1.33,
      cur: 0.93,
      id: "0506",
    },
    "0508": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 5.09,
      H_5d: 5.09,
      L_1m: 5.09,
      H_1m: 5.09,
      L_3m: 5.09,
      H_3m: 5.1,
      L_52w: 5.09,
      H_52w: 5.16,
      cur: 5.09,
      id: "0508",
    },
    "0511": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.09,
      H_5d: 0.09,
      L_1m: 0.09,
      H_1m: 0.09,
      L_3m: 0.09,
      H_3m: 0.09,
      L_52w: 0.09,
      H_52w: 0.1,
      cur: 0.09,
      id: "0511",
    },
    "0512": {
      d_1d: 0,
      d_3d: -0.02,
      d_5d: -0.06,
      d_10d: -0.16,
      d_20d: -0.13,
      L_5d: 6.16,
      H_5d: 6.21,
      L_1m: 6.16,
      H_1m: 6.32,
      L_3m: 5.99,
      H_3m: 6.32,
      L_52w: 2.99,
      H_52w: 6.32,
      cur: 6.16,
      id: "0512",
    },
    "0517": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.01,
      H_5d: 0.01,
      L_1m: 0.01,
      H_1m: 0.01,
      L_3m: 0.01,
      H_3m: 0.01,
      L_52w: 0.01,
      H_52w: 0.03,
      cur: 0.01,
      id: "0517",
    },
    "0520": {
      d_1d: 0.1,
      d_3d: 0.03,
      d_5d: 0.05,
      d_10d: -0.14,
      d_20d: -0.25,
      L_5d: 6.48,
      H_5d: 6.58,
      L_1m: 6.48,
      H_1m: 6.88,
      L_3m: 6.35,
      H_3m: 6.88,
      L_52w: 6.18,
      H_52w: 19.43,
      cur: 6.58,
      id: "0520",
    },
    "0522": {
      d_1d: 0.06,
      d_3d: 0.13,
      d_5d: 0.11,
      d_10d: 0.03,
      d_20d: 0.02,
      L_5d: 1.82,
      H_5d: 1.95,
      L_1m: 1.82,
      H_1m: 1.95,
      L_3m: 1.82,
      H_3m: 2.31,
      L_52w: 1.82,
      H_52w: 4.08,
      cur: 1.95,
      id: "0522",
    },
    "0525": {
      d_1d: 0.02,
      d_3d: 0.12,
      d_5d: -0.45,
      d_10d: -1.38,
      d_20d: -1.54,
      L_5d: 25.19,
      H_5d: 25.31,
      L_1m: 25.19,
      H_1m: 26.93,
      L_3m: 25.19,
      H_3m: 27.42,
      L_52w: 25.19,
      H_52w: 27.88,
      cur: 25.31,
      id: "0525",
    },
    "0530": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.03,
      H_5d: 0.03,
      L_1m: 0.03,
      H_1m: 0.03,
      L_3m: 0.03,
      H_3m: 0.03,
      L_52w: 0.03,
      H_52w: 0.03,
      cur: 0.03,
      id: "0530",
    },
    "0535": {
      d_1d: -0.02,
      d_3d: 0,
      d_5d: -0.01,
      d_10d: -0.11,
      d_20d: -0.19,
      L_5d: 9.65,
      H_5d: 9.68,
      L_1m: 9.65,
      H_1m: 9.86,
      L_3m: 9.65,
      H_3m: 10.27,
      L_52w: 9.65,
      H_52w: 12.21,
      cur: 9.66,
      id: "0535",
    },
    "0546": {
      d_1d: 0.03,
      d_3d: 0.18,
      d_5d: 0.28,
      d_10d: 0.26,
      d_20d: 0.27,
      L_5d: 3.64,
      H_5d: 3.87,
      L_1m: 3.57,
      H_1m: 3.87,
      L_3m: 3.28,
      H_3m: 3.87,
      L_52w: 2.88,
      H_52w: 3.87,
      cur: 3.87,
      id: "0546",
    },
    "0547": {
      d_1d: 0,
      d_3d: -0.01,
      d_5d: -0.01,
      d_10d: -0.02,
      d_20d: -0.03,
      L_5d: 2.18,
      H_5d: 2.19,
      L_1m: 2.18,
      H_1m: 2.21,
      L_3m: 2.18,
      H_3m: 22.5,
      L_52w: 2.18,
      H_52w: 22.5,
      cur: 2.18,
      id: "0547",
    },
    "0548": {
      d_1d: 0.05,
      d_3d: 0.01,
      d_5d: 0.02,
      d_10d: -0.19,
      d_20d: 0.37,
      L_5d: 20.45,
      H_5d: 20.51,
      L_1m: 20.17,
      H_1m: 20.71,
      L_3m: 20.07,
      H_3m: 21.8,
      L_52w: 18.36,
      H_52w: 22.12,
      cur: 20.51,
      id: "0548",
    },
    "0551": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.05,
      H_5d: 0.05,
      L_1m: 0.05,
      H_1m: 0.05,
      L_3m: 0.05,
      H_3m: 0.06,
      L_52w: 0.05,
      H_52w: 0.45,
      cur: 0.05,
      id: "0551",
    },
    "0552": {
      d_1d: 0.03,
      d_3d: 0.12,
      d_5d: 0.13,
      d_10d: 0.15,
      d_20d: 0.2,
      L_5d: 4.98,
      H_5d: 5.1,
      L_1m: 4.89,
      H_1m: 5.1,
      L_3m: 4.72,
      H_3m: 5.1,
      L_52w: 1.62,
      H_52w: 5.99,
      cur: 5.1,
      id: "0552",
    },
    "0553": {
      d_1d: 0.01,
      d_3d: 0.09,
      d_5d: -0.54,
      d_10d: -2.34,
      d_20d: -3.25,
      L_5d: 19.11,
      H_5d: 19.29,
      L_1m: 19.11,
      H_1m: 22.42,
      L_3m: 19.11,
      H_3m: 23.63,
      L_52w: 19.11,
      H_52w: 26.37,
      cur: 19.2,
      id: "0553",
    },
    "0563": {
      d_1d: 0,
      d_3d: -0.01,
      d_5d: -0.01,
      d_10d: -0.01,
      d_20d: -0.02,
      L_5d: 1.7,
      H_5d: 1.71,
      L_1m: 1.7,
      H_1m: 1.72,
      L_3m: 1.7,
      H_3m: 1.76,
      L_52w: 1.7,
      H_52w: 2.27,
      cur: 1.7,
      id: "0563",
    },
    "0564": {
      d_1d: -0.01,
      d_3d: 0.07,
      d_5d: 0.36,
      d_10d: 2.16,
      d_20d: 4.18,
      L_5d: 24.76,
      H_5d: 24.99,
      L_1m: 21.17,
      H_1m: 24.99,
      L_3m: 20.2,
      H_3m: 25.99,
      L_52w: 15.65,
      H_52w: 25.99,
      cur: 24.95,
      id: "0564",
    },
    "0568": {
      d_1d: 0.39,
      d_3d: 0.51,
      d_5d: 0.54,
      d_10d: 0.81,
      d_20d: 0.58,
      L_5d: 64.12,
      H_5d: 64.66,
      L_1m: 63.85,
      H_1m: 64.66,
      L_3m: 63.85,
      H_3m: 65.63,
      L_52w: 23.58,
      H_52w: 68.91,
      cur: 64.66,
      id: "0568",
    },
    "0570": {
      d_1d: -0.02,
      d_3d: -0.15,
      d_5d: -0.25,
      d_10d: -0.33,
      d_20d: -0.55,
      L_5d: 11.33,
      H_5d: 11.54,
      L_1m: 11.33,
      H_1m: 11.88,
      L_3m: 11.33,
      H_3m: 12.61,
      L_52w: 11.24,
      H_52w: 14.63,
      cur: 11.33,
      id: "0570",
    },
    "0576": {
      d_1d: 0.15,
      d_3d: 0.34,
      d_5d: 0.38,
      d_10d: 0.57,
      d_20d: 0.45,
      L_5d: 5.79,
      H_5d: 6.14,
      L_1m: 5.55,
      H_1m: 6.14,
      L_3m: 3.56,
      H_3m: 6.14,
      L_52w: 3.56,
      H_52w: 6.14,
      cur: 6.14,
      id: "0576",
    },
    "0579": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 4.87,
      H_5d: 4.87,
      L_1m: 4.87,
      H_1m: 4.87,
      L_3m: 4.87,
      H_3m: 4.91,
      L_52w: 4.79,
      H_52w: 5.26,
      cur: 4.87,
      id: "0579",
    },
    "0581": {
      d_1d: -0.01,
      d_3d: -0.01,
      d_5d: -0.01,
      d_10d: -0.03,
      d_20d: 0.02,
      L_5d: 3.32,
      H_5d: 3.33,
      L_1m: 3.3,
      H_1m: 3.37,
      L_3m: 2.82,
      H_3m: 3.37,
      L_52w: 2.82,
      H_52w: 3.63,
      cur: 3.32,
      id: "0581",
    },
    "0582": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 2.53,
      H_5d: 2.53,
      L_1m: 2.53,
      H_1m: 2.53,
      L_3m: 2.53,
      H_3m: 2.55,
      L_52w: 2.53,
      H_52w: 3.17,
      cur: 2.53,
      id: "0582",
    },
    "0586": {
      d_1d: 0.01,
      d_3d: 0.14,
      d_5d: 0.19,
      d_10d: 0.21,
      d_20d: 0.19,
      L_5d: 9.13,
      H_5d: 9.32,
      L_1m: 9.11,
      H_1m: 9.32,
      L_3m: 7.91,
      H_3m: 9.32,
      L_52w: 7.89,
      H_52w: 9.32,
      cur: 9.32,
      id: "0586",
    },
    "0588": {
      d_1d: 0.01,
      d_3d: 0.18,
      d_5d: 0.17,
      d_10d: 0.09,
      d_20d: -0.02,
      L_5d: 34.31,
      H_5d: 34.57,
      L_1m: 34.31,
      H_1m: 34.62,
      L_3m: 33.26,
      H_3m: 34.63,
      L_52w: 32.2,
      H_52w: 34.63,
      cur: 34.57,
      id: "0588",
    },
    "0590": {
      d_1d: 0,
      d_3d: -0.02,
      d_5d: -0.02,
      d_10d: -0.01,
      d_20d: -0.02,
      L_5d: 0.22,
      H_5d: 0.24,
      L_1m: 0.22,
      H_1m: 0.24,
      L_3m: 0.22,
      H_3m: 0.35,
      L_52w: 0.22,
      H_52w: 0.49,
      cur: 0.22,
      id: "0590",
    },
    "0598": {
      d_1d: 0.03,
      d_3d: 0.32,
      d_5d: 0.34,
      d_10d: 0.36,
      d_20d: 0.72,
      L_5d: 7.33,
      H_5d: 7.67,
      L_1m: 6.95,
      H_1m: 7.67,
      L_3m: 6.69,
      H_3m: 7.67,
      L_52w: 6.69,
      H_52w: 13.56,
      cur: 7.67,
      id: "0598",
    },
    "0604": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0.01,
      d_10d: 0.04,
      d_20d: 0.03,
      L_5d: 0.3,
      H_5d: 0.31,
      L_1m: 0.27,
      H_1m: 0.31,
      L_3m: 0.25,
      H_3m: 0.31,
      L_52w: 0.24,
      H_52w: 0.48,
      cur: 0.31,
      id: "0604",
    },
    "0607": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: -0.01,
      d_20d: -0.01,
      L_5d: 16.38,
      H_5d: 16.38,
      L_1m: 16.38,
      H_1m: 16.39,
      L_3m: 16.38,
      H_3m: 16.5,
      L_52w: 16.38,
      H_52w: 20.75,
      cur: 16.38,
      id: "0607",
    },
    "0612": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.12,
      H_5d: 0.12,
      L_1m: 0.12,
      H_1m: 0.12,
      L_3m: 0.12,
      H_3m: 0.12,
      L_52w: 0.12,
      H_52w: 0.17,
      cur: 0.12,
      id: "0612",
    },
    "0631": {
      d_1d: -0.01,
      d_3d: -0.03,
      d_5d: 0,
      d_10d: 0.1,
      d_20d: 0.16,
      L_5d: 6.94,
      H_5d: 6.97,
      L_1m: 6.75,
      H_1m: 6.97,
      L_3m: 6.68,
      H_3m: 6.97,
      L_52w: 5.72,
      H_52w: 7.06,
      cur: 6.94,
      id: "0631",
    },
    "0636": {
      d_1d: 0,
      d_3d: 0.01,
      d_5d: 0.01,
      d_10d: 0.02,
      d_20d: 0.02,
      L_5d: 0.11,
      H_5d: 0.12,
      L_1m: 0.1,
      H_1m: 0.12,
      L_3m: 0.03,
      H_3m: 0.12,
      L_52w: 0.03,
      H_52w: 0.59,
      cur: 0.12,
      id: "0636",
    },
    "0639": {
      d_1d: -0.01,
      d_3d: 0.02,
      d_5d: 0.04,
      d_10d: 0.25,
      d_20d: 0.33,
      L_5d: 4.24,
      H_5d: 4.29,
      L_1m: 3.99,
      H_1m: 4.29,
      L_3m: 3.88,
      H_3m: 4.86,
      L_52w: 1.72,
      H_52w: 4.86,
      cur: 4.28,
      id: "0639",
    },
    "0656": {
      d_1d: 0.02,
      d_3d: 0.05,
      d_5d: 0.05,
      d_10d: 0.03,
      d_20d: -0.01,
      L_5d: 11.85,
      H_5d: 11.9,
      L_1m: 11.85,
      H_1m: 11.91,
      L_3m: 11.85,
      H_3m: 11.91,
      L_52w: 11.85,
      H_52w: 12.34,
      cur: 11.9,
      id: "0656",
    },
    "0658": {
      d_1d: -0.01,
      d_3d: 0.04,
      d_5d: -0.21,
      d_10d: -0.16,
      d_20d: 0.17,
      L_5d: 7.36,
      H_5d: 7.43,
      L_1m: 7.29,
      H_1m: 7.63,
      L_3m: 6.16,
      H_3m: 7.63,
      L_52w: 5.08,
      H_52w: 8.37,
      cur: 7.42,
      id: "0658",
    },
    "0659": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: -0.01,
      L_5d: 0.07,
      H_5d: 0.07,
      L_1m: 0.07,
      H_1m: 0.08,
      L_3m: 0.07,
      H_3m: 0.08,
      L_52w: 0.07,
      H_52w: 0.26,
      cur: 0.07,
      id: "0659",
    },
    "0665": {
      d_1d: 0,
      d_3d: 0.07,
      d_5d: 0.09,
      d_10d: 0.13,
      d_20d: 0.17,
      L_5d: 3.83,
      H_5d: 3.92,
      L_1m: 3.75,
      H_1m: 3.92,
      L_3m: 3.43,
      H_3m: 3.92,
      L_52w: 3.43,
      H_52w: 4.39,
      cur: 3.92,
      id: "0665",
    },
    "0667": {
      d_1d: 0.26,
      d_3d: 0.21,
      d_5d: 0.4,
      d_10d: 0.59,
      d_20d: 0.66,
      L_5d: 7.16,
      H_5d: 7.42,
      L_1m: 6.72,
      H_1m: 7.42,
      L_3m: 6.72,
      H_3m: 7.42,
      L_52w: 6.25,
      H_52w: 10.14,
      cur: 7.42,
      id: "0667",
    },
    "0669": {
      d_1d: 0,
      d_3d: 0.03,
      d_5d: 0.04,
      d_10d: 0.04,
      d_20d: 0.06,
      L_5d: 1.18,
      H_5d: 1.23,
      L_1m: 1.16,
      H_1m: 1.23,
      L_3m: 1.05,
      H_3m: 1.23,
      L_52w: 0.37,
      H_52w: 1.73,
      cur: 1.23,
      id: "0669",
    },
    "0670": {
      d_1d: 0.03,
      d_3d: -0.05,
      d_5d: -0.07,
      d_10d: -0.1,
      d_20d: -0.08,
      L_5d: 8.36,
      H_5d: 8.47,
      L_1m: 8.36,
      H_1m: 8.5,
      L_3m: 8.36,
      H_3m: 8.99,
      L_52w: 4.39,
      H_52w: 8.99,
      cur: 8.4,
      id: "0670",
    },
    "0680": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.32,
      H_5d: 0.32,
      L_1m: 0.32,
      H_1m: 0.32,
      L_3m: 0.32,
      H_3m: 0.32,
      L_52w: 0.32,
      H_52w: 0.7,
      cur: 0.32,
      id: "0680",
    },
    "0683": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.14,
      H_5d: 0.14,
      L_1m: 0.14,
      H_1m: 0.14,
      L_3m: 0.13,
      H_3m: 0.16,
      L_52w: 0.13,
      H_52w: 0.26,
      cur: 0.14,
      id: "0683",
    },
    "0686": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 1.26,
      H_5d: 1.26,
      L_1m: 1.26,
      H_1m: 1.26,
      L_3m: 1.26,
      H_3m: 1.27,
      L_52w: 1.26,
      H_52w: 1.32,
      cur: 1.26,
      id: "0686",
    },
    "0687": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.31,
      H_5d: 0.31,
      L_1m: 0.31,
      H_1m: 0.31,
      L_3m: 0.31,
      H_3m: 0.35,
      L_52w: 0.31,
      H_52w: 0.62,
      cur: 0.31,
      id: "0687",
    },
    "0688": {
      d_1d: 0.02,
      d_3d: 0.04,
      d_5d: 0.04,
      d_10d: 0.16,
      d_20d: 0.64,
      L_5d: 3.17,
      H_5d: 3.21,
      L_1m: 2.56,
      H_1m: 3.21,
      L_3m: 1.95,
      H_3m: 3.21,
      L_52w: 0.75,
      H_52w: 3.21,
      cur: 3.21,
      id: "0688",
    },
    "0691": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.07,
      H_5d: 0.07,
      L_1m: 0.07,
      H_1m: 0.07,
      L_3m: 0.07,
      H_3m: 0.07,
      L_52w: 0.07,
      H_52w: 0.09,
      cur: 0.07,
      id: "0691",
    },
    "0694": {
      d_1d: 0.04,
      d_3d: 0.12,
      d_5d: 0.08,
      d_10d: 0.07,
      d_20d: -0.23,
      L_5d: 6.81,
      H_5d: 6.93,
      L_1m: 6.81,
      H_1m: 7.14,
      L_3m: 6.81,
      H_3m: 7.61,
      L_52w: 6.81,
      H_52w: 18.69,
      cur: 6.93,
      id: "0694",
    },
    "0696": {
      d_1d: 0.04,
      d_3d: 0.1,
      d_5d: 0.07,
      d_10d: 0.11,
      d_20d: 0.43,
      L_5d: 3.05,
      H_5d: 3.15,
      L_1m: 2.73,
      H_1m: 3.15,
      L_3m: 2.09,
      H_3m: 3.15,
      L_52w: 1.91,
      H_52w: 3.47,
      cur: 3.15,
      id: "0696",
    },
    "0697": {
      d_1d: 0.01,
      d_3d: 0.02,
      d_5d: 0.02,
      d_10d: 0.01,
      d_20d: 0.1,
      L_5d: 1.55,
      H_5d: 1.57,
      L_1m: 1.48,
      H_1m: 1.57,
      L_3m: 1.28,
      H_3m: 1.57,
      L_52w: 0.57,
      H_52w: 1.57,
      cur: 1.57,
      id: "0697",
    },
    "0698": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.68,
      H_5d: 0.68,
      L_1m: 0.68,
      H_1m: 0.68,
      L_3m: 0.68,
      H_3m: 0.89,
      L_52w: 0.68,
      H_52w: 2.38,
      cur: 0.68,
      id: "0698",
    },
    "0700": {
      d_1d: -0.01,
      d_3d: -0.02,
      d_5d: -0.06,
      d_10d: -0.12,
      d_20d: -0.25,
      L_5d: 6.09,
      H_5d: 6.15,
      L_1m: 6.09,
      H_1m: 6.38,
      L_3m: 6,
      H_3m: 6.38,
      L_52w: 4.21,
      H_52w: 6.96,
      cur: 6.09,
      id: "0700",
    },
    "0709": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.05,
      H_5d: 0.05,
      L_1m: 0.05,
      H_1m: 0.05,
      L_3m: 0.05,
      H_3m: 0.05,
      L_52w: 0.05,
      H_52w: 0.13,
      cur: 0.05,
      id: "0709",
    },
    "0715": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0,
      H_5d: 0,
      L_1m: 0,
      H_1m: 0,
      L_3m: 0,
      H_3m: 0,
      L_52w: 0,
      H_52w: 0,
      cur: 0,
      id: "0715",
    },
    "0718": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.38,
      H_5d: 0.38,
      L_1m: 0.38,
      H_1m: 0.38,
      L_3m: 0.38,
      H_3m: 0.38,
      L_52w: 0.38,
      H_52w: 0.38,
      cur: 0.38,
      id: "0718",
    },
    "0719": {
      d_1d: -0.28,
      d_3d: -0.31,
      d_5d: -0.29,
      d_10d: -0.26,
      d_20d: 0.13,
      L_5d: 37.85,
      H_5d: 38.16,
      L_1m: 37.72,
      H_1m: 38.16,
      L_3m: 37.24,
      H_3m: 38.16,
      L_52w: 34.95,
      H_52w: 41.58,
      cur: 37.85,
      id: "0719",
    },
    "0721": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 2.77,
      H_5d: 2.77,
      L_1m: 2.77,
      H_1m: 2.77,
      L_3m: 2.77,
      H_3m: 2.77,
      L_52w: 2.77,
      H_52w: 2.78,
      cur: 2.77,
      id: "0721",
    },
    "0728": {
      d_1d: -0.03,
      d_3d: -0.05,
      d_5d: 0,
      d_10d: 0.13,
      d_20d: -0.86,
      L_5d: 27.76,
      H_5d: 27.82,
      L_1m: 27.58,
      H_1m: 28.3,
      L_3m: 27.58,
      H_3m: 29.59,
      L_52w: 3.65,
      H_52w: 32.9,
      cur: 27.77,
      id: "0728",
    },
    "0729": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 2.8,
      H_5d: 2.8,
      L_1m: 2.8,
      H_1m: 2.8,
      L_3m: 2.8,
      H_3m: 2.8,
      L_52w: 2.8,
      H_52w: 2.8,
      cur: 2.8,
      id: "0729",
    },
    "0732": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: -0.01,
      L_5d: 5.08,
      H_5d: 5.08,
      L_1m: 5.08,
      H_1m: 5.09,
      L_3m: 5.08,
      H_3m: 5.14,
      L_52w: 5.08,
      H_52w: 8.97,
      cur: 5.08,
      id: "0732",
    },
    "0737": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.04,
      H_5d: 0.04,
      L_1m: 0.04,
      H_1m: 0.04,
      L_3m: 0.04,
      H_3m: 0.05,
      L_52w: 0.04,
      H_52w: 0.05,
      cur: 0.04,
      id: "0737",
    },
    "0743": {
      d_1d: 0.01,
      d_3d: 0.01,
      d_5d: -0.01,
      d_10d: -0.05,
      d_20d: -0.05,
      L_5d: 2.41,
      H_5d: 2.42,
      L_1m: 2.41,
      H_1m: 2.48,
      L_3m: 1.38,
      H_3m: 2.48,
      L_52w: 1.38,
      H_52w: 2.48,
      cur: 2.42,
      id: "0743",
    },
    "0751": {
      d_1d: -0.14,
      d_3d: -0.21,
      d_5d: -0.37,
      d_10d: -0.85,
      d_20d: 2.34,
      L_5d: 12.71,
      H_5d: 12.92,
      L_1m: 10.31,
      H_1m: 13.56,
      L_3m: 5.35,
      H_3m: 13.56,
      L_52w: 5.35,
      H_52w: 13.56,
      cur: 12.71,
      id: "0751",
    },
    "0753": {
      d_1d: 0.29,
      d_3d: 0.73,
      d_5d: 0.72,
      d_10d: 0.92,
      d_20d: 1.38,
      L_5d: 10.15,
      H_5d: 10.88,
      L_1m: 9.52,
      H_1m: 10.88,
      L_3m: 8.12,
      H_3m: 10.88,
      L_52w: 7.15,
      H_52w: 10.88,
      cur: 10.88,
      id: "0753",
    },
    "0754": {
      d_1d: 0,
      d_3d: -0.01,
      d_5d: -0.2,
      d_10d: -0.03,
      d_20d: 0.04,
      L_5d: 1.94,
      H_5d: 1.95,
      L_1m: 1.92,
      H_1m: 2.14,
      L_3m: 1.72,
      H_3m: 2.14,
      L_52w: 1.55,
      H_52w: 2.38,
      cur: 1.94,
      id: "0754",
    },
    "0762": {
      d_1d: -0.01,
      d_3d: -0.06,
      d_5d: -0.11,
      d_10d: -0.08,
      d_20d: -0.08,
      L_5d: 3.96,
      H_5d: 4.05,
      L_1m: 3.96,
      H_1m: 4.09,
      L_3m: 3.96,
      H_3m: 4.09,
      L_52w: 0.88,
      H_52w: 5.28,
      cur: 3.96,
      id: "0762",
    },
    "0763": {
      d_1d: 0.01,
      d_3d: -0.55,
      d_5d: -0.54,
      d_10d: -0.82,
      d_20d: -2.13,
      L_5d: 22.22,
      H_5d: 22.9,
      L_1m: 22.22,
      H_1m: 24.34,
      L_3m: 22.22,
      H_3m: 27.85,
      L_52w: 22.22,
      H_52w: 31.5,
      cur: 22.23,
      id: "0763",
    },
    "0772": {
      d_1d: 0.01,
      d_3d: -0.03,
      d_5d: -0.06,
      d_10d: -0.15,
      d_20d: -0.17,
      L_5d: 8.56,
      H_5d: 8.61,
      L_1m: 8.56,
      H_1m: 8.74,
      L_3m: 8.56,
      H_3m: 9.69,
      L_52w: 7.57,
      H_52w: 10.09,
      cur: 8.57,
      id: "0772",
    },
    "0775": {
      d_1d: 0.01,
      d_3d: -0.01,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0.02,
      L_5d: 0.48,
      H_5d: 0.52,
      L_1m: 0.47,
      H_1m: 0.52,
      L_3m: 0.42,
      H_3m: 0.52,
      L_52w: 0.18,
      H_52w: 0.52,
      cur: 0.49,
      id: "0775",
    },
    "0777": {
      d_1d: -0.02,
      d_3d: -0.02,
      d_5d: -0.01,
      d_10d: 0,
      d_20d: 0,
      L_5d: 3.95,
      H_5d: 3.97,
      L_1m: 3.95,
      H_1m: 3.97,
      L_3m: 3.83,
      H_3m: 3.98,
      L_52w: 3.78,
      H_52w: 4.76,
      cur: 3.95,
      id: "0777",
    },
    "0780": {
      d_1d: 0.02,
      d_3d: 0.03,
      d_5d: 0.05,
      d_10d: -0.06,
      d_20d: -0.46,
      L_5d: 4.66,
      H_5d: 4.7,
      L_1m: 4.65,
      H_1m: 5.14,
      L_3m: 4.65,
      H_3m: 5.55,
      L_52w: 2.42,
      H_52w: 6.43,
      cur: 4.7,
      id: "0780",
    },
    "0787": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 5.89,
      H_5d: 5.89,
      L_1m: 5.89,
      H_1m: 5.89,
      L_3m: 5.89,
      H_3m: 5.89,
      L_52w: 5.89,
      H_52w: 6.38,
      cur: 5.89,
      id: "0787",
    },
    "0788": {
      d_1d: 0.01,
      d_3d: 0.03,
      d_5d: 0.03,
      d_10d: 0,
      d_20d: -0.01,
      L_5d: 3.35,
      H_5d: 3.38,
      L_1m: 3.35,
      H_1m: 3.41,
      L_3m: 3.35,
      H_3m: 3.51,
      L_52w: 3.29,
      H_52w: 4.83,
      cur: 3.38,
      id: "0788",
    },
    "0799": {
      d_1d: 0.05,
      d_3d: 0.11,
      d_5d: 0.16,
      d_10d: 0.18,
      d_20d: 0.14,
      L_5d: 7.79,
      H_5d: 7.93,
      L_1m: 7.72,
      H_1m: 7.93,
      L_3m: 7.72,
      H_3m: 8.05,
      L_52w: 6.16,
      H_52w: 9.34,
      cur: 7.93,
      id: "0799",
    },
    "0806": {
      d_1d: 0,
      d_3d: 0.02,
      d_5d: 0.01,
      d_10d: 0.18,
      d_20d: 0.78,
      L_5d: 5.28,
      H_5d: 5.3,
      L_1m: 4.66,
      H_1m: 5.3,
      L_3m: 4.52,
      H_3m: 5.3,
      L_52w: 2.74,
      H_52w: 5.41,
      cur: 5.3,
      id: "0806",
    },
    "0811": {
      d_1d: 0.03,
      d_3d: 0.39,
      d_5d: 0.5,
      d_10d: 1.03,
      d_20d: 1.18,
      L_5d: 21.35,
      H_5d: 21.85,
      L_1m: 20.53,
      H_1m: 21.85,
      L_3m: 20.1,
      H_3m: 21.85,
      L_52w: 17.73,
      H_52w: 21.85,
      cur: 21.85,
      id: "0811",
    },
    "0813": {
      d_1d: 0,
      d_3d: -0.01,
      d_5d: 0.04,
      d_10d: 0.09,
      d_20d: -0.18,
      L_5d: 1.67,
      H_5d: 1.71,
      L_1m: 1.57,
      H_1m: 1.74,
      L_3m: 1.57,
      H_3m: 2.06,
      L_52w: 1.42,
      H_52w: 2.06,
      cur: 1.7,
      id: "0813",
    },
    "0817": {
      d_1d: 0.02,
      d_3d: 0,
      d_5d: -0.03,
      d_10d: -0.03,
      d_20d: 0.16,
      L_5d: 8.87,
      H_5d: 8.92,
      L_1m: 8.74,
      H_1m: 8.98,
      L_3m: 8.66,
      H_3m: 9.01,
      L_52w: 8.66,
      H_52w: 10.14,
      cur: 8.89,
      id: "0817",
    },
    "0819": {
      d_1d: 0,
      d_3d: 0.03,
      d_5d: 0.05,
      d_10d: 0.09,
      d_20d: -0.13,
      L_5d: 19.18,
      H_5d: 19.26,
      L_1m: 19.14,
      H_1m: 19.39,
      L_3m: 18.88,
      H_3m: 19.54,
      L_52w: 14.55,
      H_52w: 19.54,
      cur: 19.24,
      id: "0819",
    },
    "0826": {
      d_1d: -0.03,
      d_3d: 0.06,
      d_5d: 0.08,
      d_10d: 0.16,
      d_20d: 0.26,
      L_5d: 13.84,
      H_5d: 13.93,
      L_1m: 13.5,
      H_1m: 13.93,
      L_3m: 13.1,
      H_3m: 13.93,
      L_52w: 4.89,
      H_52w: 14.48,
      cur: 13.9,
      id: "0826",
    },
    "0829": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: -0.01,
      L_5d: 3.99,
      H_5d: 3.99,
      L_1m: 3.99,
      H_1m: 3.99,
      L_3m: 3.99,
      H_3m: 4.02,
      L_52w: 3.99,
      H_52w: 4.34,
      cur: 3.99,
      id: "0829",
    },
    "0832": {
      d_1d: 0.05,
      d_3d: 0.29,
      d_5d: 0.31,
      d_10d: 0.38,
      d_20d: 0.46,
      L_5d: 4.88,
      H_5d: 5.17,
      L_1m: 4.74,
      H_1m: 5.17,
      L_3m: 4.41,
      H_3m: 5.17,
      L_52w: 4.41,
      H_52w: 5.63,
      cur: 5.17,
      id: "0832",
    },
    "0836": {
      d_1d: 0.26,
      d_3d: 0.42,
      d_5d: 0.65,
      d_10d: 0.47,
      d_20d: 0.2,
      L_5d: 15.8,
      H_5d: 16.24,
      L_1m: 15.59,
      H_1m: 16.24,
      L_3m: 11.93,
      H_3m: 16.24,
      L_52w: 4.62,
      H_52w: 16.24,
      cur: 16.24,
      id: "0836",
    },
    "0839": {
      d_1d: 0.15,
      d_3d: 0.2,
      d_5d: 0.48,
      d_10d: 0.71,
      d_20d: 0.84,
      L_5d: 5.91,
      H_5d: 6.18,
      L_1m: 5.33,
      H_1m: 6.18,
      L_3m: 4.95,
      H_3m: 6.18,
      L_52w: 3.69,
      H_52w: 6.9,
      cur: 6.18,
      id: "0839",
    },
    "0845": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.28,
      H_5d: 0.28,
      L_1m: 0.28,
      H_1m: 0.28,
      L_3m: 0.28,
      H_3m: 0.28,
      L_52w: 0.28,
      H_52w: 0.29,
      cur: 0.28,
      id: "0845",
    },
    "0846": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.07,
      H_5d: 0.07,
      L_1m: 0.07,
      H_1m: 0.07,
      L_3m: 0.07,
      H_3m: 0.07,
      L_52w: 0.07,
      H_52w: 0.09,
      cur: 0.07,
      id: "0846",
    },
    "0853": {
      d_1d: 0.01,
      d_3d: -0.03,
      d_5d: -0.12,
      d_10d: -0.02,
      d_20d: 0.59,
      L_5d: 15.29,
      H_5d: 15.36,
      L_1m: 14.79,
      H_1m: 15.45,
      L_3m: 14.48,
      H_3m: 15.45,
      L_52w: 12.46,
      H_52w: 15.45,
      cur: 15.33,
      id: "0853",
    },
    "0855": {
      d_1d: -0.01,
      d_3d: -0.05,
      d_5d: -0.05,
      d_10d: -0.06,
      d_20d: -0.04,
      L_5d: 7.73,
      H_5d: 7.78,
      L_1m: 7.72,
      H_1m: 7.79,
      L_3m: 7.72,
      H_3m: 8.48,
      L_52w: 7.72,
      H_52w: 10.99,
      cur: 7.73,
      id: "0855",
    },
    "0856": {
      d_1d: 0.02,
      d_3d: 0.05,
      d_5d: 0.06,
      d_10d: 0.05,
      d_20d: 0.07,
      L_5d: 0.58,
      H_5d: 0.63,
      L_1m: 0.56,
      H_1m: 0.63,
      L_3m: 0.49,
      H_3m: 0.63,
      L_52w: 0.42,
      H_52w: 1.11,
      cur: 0.63,
      id: "0856",
    },
    "0857": {
      d_1d: 0.01,
      d_3d: -0.09,
      d_5d: -0.05,
      d_10d: -0.14,
      d_20d: -0.26,
      L_5d: 5.13,
      H_5d: 5.23,
      L_1m: 5.13,
      H_1m: 5.41,
      L_3m: 5.13,
      H_3m: 6.05,
      L_52w: 5.13,
      H_52w: 9.21,
      cur: 5.14,
      id: "0857",
    },
    "0860": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: -0.02,
      L_5d: 0.25,
      H_5d: 0.25,
      L_1m: 0.25,
      H_1m: 0.27,
      L_3m: 0.25,
      H_3m: 0.28,
      L_52w: 0.25,
      H_52w: 0.33,
      cur: 0.25,
      id: "0860",
    },
    "0861": {
      d_1d: -0.03,
      d_3d: 0.02,
      d_5d: 0.03,
      d_10d: 0.07,
      d_20d: 0.18,
      L_5d: 31.65,
      H_5d: 31.7,
      L_1m: 31.51,
      H_1m: 31.7,
      L_3m: 31.33,
      H_3m: 31.7,
      L_52w: 30.88,
      H_52w: 36.36,
      cur: 31.67,
      id: "0861",
    },
    "0867": {
      d_1d: 0.01,
      d_3d: 0.02,
      d_5d: 0.02,
      d_10d: 0.03,
      d_20d: -0.22,
      L_5d: 2.69,
      H_5d: 2.71,
      L_1m: 2.67,
      H_1m: 2.9,
      L_3m: 2.67,
      H_3m: 3.1,
      L_52w: 1.53,
      H_52w: 3.98,
      cur: 2.71,
      id: "0867",
    },
    "0868": {
      d_1d: -0.04,
      d_3d: -0.03,
      d_5d: -0.03,
      d_10d: -0.22,
      d_20d: -0.31,
      L_5d: 2.8,
      H_5d: 2.84,
      L_1m: 2.8,
      H_1m: 3.13,
      L_3m: 2.8,
      H_3m: 3.47,
      L_52w: 2.8,
      H_52w: 5.58,
      cur: 2.8,
      id: "0868",
    },
    "0873": {
      d_1d: -0.04,
      d_3d: -0.17,
      d_5d: -0.2,
      d_10d: -0.21,
      d_20d: -0.55,
      L_5d: 1.58,
      H_5d: 1.78,
      L_1m: 1.58,
      H_1m: 2.09,
      L_3m: 1.58,
      H_3m: 2.43,
      L_52w: 0,
      H_52w: 2.48,
      cur: 1.58,
      id: "0873",
    },
    "0874": {
      d_1d: 0.08,
      d_3d: 0.32,
      d_5d: 0.13,
      d_10d: 0.54,
      d_20d: 0.71,
      L_5d: 33.3,
      H_5d: 33.69,
      L_1m: 33.03,
      H_1m: 33.69,
      L_3m: 31.27,
      H_3m: 33.69,
      L_52w: 30.11,
      H_52w: 33.69,
      cur: 33.69,
      id: "0874",
    },
    "0880": {
      d_1d: 0.06,
      d_3d: 0.21,
      d_5d: 0.1,
      d_10d: 0.14,
      d_20d: 0.03,
      L_5d: 3.11,
      H_5d: 3.32,
      L_1m: 3.11,
      H_1m: 3.32,
      L_3m: 3.11,
      H_3m: 3.48,
      L_52w: 3.11,
      H_52w: 5.85,
      cur: 3.32,
      id: "0880",
    },
    "0881": {
      d_1d: -0.02,
      d_3d: -0.1,
      d_5d: -0.12,
      d_10d: -0.18,
      d_20d: -0.3,
      L_5d: 3.81,
      H_5d: 3.93,
      L_1m: 3.81,
      H_1m: 4.09,
      L_3m: 3.81,
      H_3m: 4.5,
      L_52w: 1.9,
      H_52w: 4.73,
      cur: 3.81,
      id: "0881",
    },
    "0883": {
      d_1d: 0.02,
      d_3d: 0.01,
      d_5d: -0.07,
      d_10d: -0.23,
      d_20d: -0.29,
      L_5d: 12.15,
      H_5d: 12.19,
      L_1m: 12.15,
      H_1m: 12.46,
      L_3m: 11.64,
      H_3m: 12.58,
      L_52w: 1.11,
      H_52w: 13.72,
      cur: 12.17,
      id: "0883",
    },
    "0884": {
      d_1d: 0.03,
      d_3d: 0.02,
      d_5d: 0.01,
      d_10d: 0.09,
      d_20d: 0.3,
      L_5d: 5.35,
      H_5d: 5.38,
      L_1m: 5.09,
      H_1m: 5.38,
      L_3m: 4.93,
      H_3m: 5.38,
      L_52w: 4.51,
      H_52w: 5.38,
      cur: 5.38,
      id: "0884",
    },
    "0885": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.01,
      H_5d: 0.01,
      L_1m: 0.01,
      H_1m: 0.01,
      L_3m: 0.01,
      H_3m: 0.01,
      L_52w: 0.01,
      H_52w: 0.01,
      cur: 0.01,
      id: "0885",
    },
    "0895": {
      d_1d: 0,
      d_3d: 0.04,
      d_5d: 0.07,
      d_10d: 0.1,
      d_20d: 0.15,
      L_5d: 34.14,
      H_5d: 34.18,
      L_1m: 33.96,
      H_1m: 34.18,
      L_3m: 32.39,
      H_3m: 34.18,
      L_52w: 27.61,
      H_52w: 34.18,
      cur: 34.18,
      id: "0895",
    },
    "0902": {
      d_1d: -0.22,
      d_3d: -0.66,
      d_5d: -0.7,
      d_10d: -2.38,
      d_20d: -3.23,
      L_5d: 44.17,
      H_5d: 44.96,
      L_1m: 44.17,
      H_1m: 47.37,
      L_3m: 42.46,
      H_3m: 47.4,
      L_52w: 39.29,
      H_52w: 47.4,
      cur: 44.17,
      id: "0902",
    },
    "0906": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: -0.02,
      L_5d: 0.6,
      H_5d: 0.6,
      L_1m: 0.6,
      H_1m: 0.62,
      L_3m: 0.6,
      H_3m: 0.62,
      L_52w: 0.6,
      H_52w: 1.25,
      cur: 0.6,
      id: "0906",
    },
    "0909": {
      d_1d: -0.08,
      d_3d: -0.03,
      d_5d: 0.02,
      d_10d: 0,
      d_20d: 0.05,
      L_5d: 3.06,
      H_5d: 3.14,
      L_1m: 3.01,
      H_1m: 3.18,
      L_3m: 2.6,
      H_3m: 3.18,
      L_52w: 0,
      H_52w: 3.18,
      cur: 3.06,
      id: "0909",
    },
    "0911": {
      d_1d: 0,
      d_3d: 0,
      d_5d: -0.01,
      d_10d: -0.01,
      d_20d: -0.02,
      L_5d: 5.13,
      H_5d: 5.13,
      L_1m: 5.13,
      H_1m: 5.15,
      L_3m: 5.13,
      H_3m: 5.22,
      L_52w: 5.13,
      H_52w: 5.36,
      cur: 5.13,
      id: "0911",
    },
    "0914": {
      d_1d: -0.01,
      d_3d: 0.04,
      d_5d: 0.05,
      d_10d: 0.08,
      d_20d: 0.15,
      L_5d: 7.13,
      H_5d: 7.18,
      L_1m: 7.02,
      H_1m: 7.18,
      L_3m: 7.01,
      H_3m: 8.79,
      L_52w: 4.11,
      H_52w: 8.79,
      cur: 7.17,
      id: "0914",
    },
    "0916": {
      d_1d: 0.27,
      d_3d: 0.31,
      d_5d: 0.27,
      d_10d: -1.36,
      d_20d: -3.24,
      L_5d: 7.31,
      H_5d: 7.62,
      L_1m: 7.31,
      H_1m: 10.89,
      L_3m: 6.54,
      H_3m: 11.05,
      L_52w: 3.88,
      H_52w: 11.05,
      cur: 7.62,
      id: "0916",
    },
    "0921": {
      d_1d: 0.05,
      d_3d: 0.07,
      d_5d: 0.07,
      d_10d: 0.06,
      d_20d: 0.09,
      L_5d: 9.31,
      H_5d: 9.4,
      L_1m: 9.29,
      H_1m: 9.4,
      L_3m: 8.52,
      H_3m: 9.4,
      L_52w: 6.73,
      H_52w: 9.4,
      cur: 9.4,
      id: "0921",
    },
    "0931": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 1.93,
      H_5d: 1.93,
      L_1m: 1.93,
      H_1m: 1.93,
      L_3m: 1.93,
      H_3m: 1.96,
      L_52w: 1.93,
      H_52w: 2.09,
      cur: 1.93,
      id: "0931",
    },
    "0934": {
      d_1d: 0,
      d_3d: 0.06,
      d_5d: 0.08,
      d_10d: 0.12,
      d_20d: 0.22,
      L_5d: 5.21,
      H_5d: 5.28,
      L_1m: 5.09,
      H_1m: 5.28,
      L_3m: 4.81,
      H_3m: 5.28,
      L_52w: 4.81,
      H_52w: 6.56,
      cur: 5.28,
      id: "0934",
    },
    "0939": {
      d_1d: 0,
      d_3d: 0.01,
      d_5d: 0.02,
      d_10d: -0.03,
      d_20d: -0.19,
      L_5d: 10.49,
      H_5d: 10.52,
      L_1m: 10.49,
      H_1m: 10.7,
      L_3m: 10.49,
      H_3m: 11.06,
      L_52w: 10.49,
      H_52w: 11.6,
      cur: 10.52,
      id: "0939",
    },
    "0941": {
      d_1d: 0,
      d_3d: 0.03,
      d_5d: 0.04,
      d_10d: 0.1,
      d_20d: 0.03,
      L_5d: 8.24,
      H_5d: 8.28,
      L_1m: 8.18,
      H_1m: 8.28,
      L_3m: 7.86,
      H_3m: 8.28,
      L_52w: 0.78,
      H_52w: 8.28,
      cur: 8.28,
      id: "0941",
    },
    "0950": {
      d_1d: 0,
      d_3d: -0.01,
      d_5d: -0.01,
      d_10d: -0.01,
      d_20d: -0.01,
      L_5d: 0.15,
      H_5d: 0.16,
      L_1m: 0.15,
      H_1m: 0.16,
      L_3m: 0.15,
      H_3m: 0.16,
      L_52w: 0.15,
      H_52w: 0.23,
      cur: 0.15,
      id: "0950",
    },
    "0951": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 8.83,
      H_5d: 8.83,
      L_1m: 8.83,
      H_1m: 8.83,
      L_3m: 8.83,
      H_3m: 8.83,
      L_52w: 8.83,
      H_52w: 9.04,
      cur: 8.83,
      id: "0951",
    },
    "0956": {
      d_1d: 0.14,
      d_3d: 1.04,
      d_5d: 1.11,
      d_10d: 1.42,
      d_20d: -1.26,
      L_5d: 17.9,
      H_5d: 18.99,
      L_1m: 17.45,
      H_1m: 20.3,
      L_3m: 16.33,
      H_3m: 21.81,
      L_52w: 14.18,
      H_52w: 21.81,
      cur: 18.99,
      id: "0956",
    },
    "0960": {
      d_1d: -0.02,
      d_3d: -0.13,
      d_5d: -0.17,
      d_10d: -0.18,
      d_20d: -0.08,
      L_5d: 1.46,
      H_5d: 1.61,
      L_1m: 1.46,
      H_1m: 1.67,
      L_3m: 1.46,
      H_3m: 2.12,
      L_52w: 1.46,
      H_52w: 3.25,
      cur: 1.46,
      id: "0960",
    },
    "0966": {
      d_1d: 0.01,
      d_3d: 0.04,
      d_5d: 0.04,
      d_10d: 0.08,
      d_20d: 0.12,
      L_5d: 9.26,
      H_5d: 9.31,
      L_1m: 9.22,
      H_1m: 9.31,
      L_3m: 9.14,
      H_3m: 9.35,
      L_52w: 8.9,
      H_52w: 13.67,
      cur: 9.31,
      id: "0966",
    },
    "0967": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 0.02,
      H_5d: 0.02,
      L_1m: 0.02,
      H_1m: 0.02,
      L_3m: 0.02,
      H_3m: 0.02,
      L_52w: 0.02,
      H_52w: 0.02,
      cur: 0.02,
      id: "0967",
    },
    "0968": {
      d_1d: 0.01,
      d_3d: -0.03,
      d_5d: -0.11,
      d_10d: -0.42,
      d_20d: -0.75,
      L_5d: 4.38,
      H_5d: 4.45,
      L_1m: 4.38,
      H_1m: 5.03,
      L_3m: 4.38,
      H_3m: 5.38,
      L_52w: 4.33,
      H_52w: 7.61,
      cur: 4.39,
      id: "0968",
    },
    "0976": {
      d_1d: -0.01,
      d_3d: -0.01,
      d_5d: -0.01,
      d_10d: -0.06,
      d_20d: -0.23,
      L_5d: 8.02,
      H_5d: 8.03,
      L_1m: 8.02,
      H_1m: 8.25,
      L_3m: 8.02,
      H_3m: 8.7,
      L_52w: 8.02,
      H_52w: 12.33,
      cur: 8.02,
      id: "0976",
    },
    "0978": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: 0,
      d_20d: 0,
      L_5d: 4.28,
      H_5d: 4.28,
      L_1m: 4.28,
      H_1m: 4.28,
      L_3m: 4.28,
      H_3m: 4.34,
      L_52w: 3.94,
      H_52w: 4.34,
      cur: 4.28,
      id: "0978",
    },
    "0981": {
      d_1d: 0.06,
      d_3d: 0.1,
      d_5d: 0.14,
      d_10d: 0.03,
      d_20d: -0.16,
      L_5d: 19.07,
      H_5d: 19.19,
      L_1m: 19.01,
      H_1m: 19.53,
      L_3m: 19.01,
      H_3m: 19.9,
      L_52w: 13.52,
      H_52w: 22.6,
      cur: 19.19,
      id: "0981",
    },
    "0989": {
      d_1d: 0,
      d_3d: 0,
      d_5d: 0,
      d_10d: -0.03,
      d_20d: -0.05,
      L_5d: 1.7,
      H_5d: 1.7,
      L_1m: 1.7,
      H_1m: 1.75,
      L_3m: 1.7,
      H_3m: 1.81,
      L_52w: 1.7,
      H_52w: 2.28,
      cur: 1.7,
      id: "0989",
    },
    "0991": {
      d_1d: -0.01,
      d_3d: -0.02,
      d_5d: -0.01,
      d_10d: 0.04,
      d_20d: 0.02,
      L_5d: 5.69,
      H_5d: 5.71,
      L_1m: 5.63,
      H_1m: 5.71,
      L_3m: 5.18,
      H_3m: 5.71,
      L_52w: 5.18,
      H_52w: 6.59,
      cur: 5.69,
      id: "0991",
    },
    "0992": {
      d_1d: 0.06,
      d_3d: 0.12,
      d_5d: 0.13,
      d_10d: 0.11,
      d_20d: 0.15,
      L_5d: 2.11,
      H_5d: 2.23,
      L_1m: 2.1,
      H_1m: 2.23,
      L_3m: 2.07,
      H_3m: 2.33,
      L_52w: 0.82,
      H_52w: 6.35,
      cur: 2.23,
      id: "0992",
    },
    "0995": {
      d_1d: -0.08,
      d_3d: -0.86,
      d_5d: -0.94,
      d_10d: -1.44,
      d_20d: -1.3,
      L_5d: 25.55,
      H_5d: 26.43,
      L_1m: 25.55,
      H_1m: 26.99,
      L_3m: 25.01,
      H_3m: 26.99,
      L_52w: 24.72,
      H_52w: 26.99,
      cur: 25.55,
      id: "0995",
    },
    "0996": {
      d_1d: -0.02,
      d_3d: -0.02,
      d_5d: -0.02,
      d_10d: -0.03,
      d_20d: -0.08,
      L_5d: 23.46,
      H_5d: 23.48,
      L_1m: 23.46,
      H_1m: 23.54,
      L_3m: 23.46,
      H_3m: 23.85,
      L_52w: 23.46,
      H_52w: 25.33,
      cur: 23.46,
      id: "0996",
    },
    "0998": {
      d_1d: -0.02,
      d_3d: 0.02,
      d_5d: 0.02,
      d_10d: 0.02,
      d_20d: 0.17,
      L_5d: 18.95,
      H_5d: 19.01,
      L_1m: 18.85,
      H_1m: 19.01,
      L_3m: 18.68,
      H_3m: 19.01,
      L_52w: 18.56,
      H_52w: 19.87,
      cur: 18.99,
      id: "0998",
    },
  },
  topicInfo: {
    lastUpdate: "2021/12/03",
    name: "hkconnect",
  },
};

const price = {
  data: {
    1000: {
      H: 0.6,
      L: 0.54,
      O: 0.6,
      C: 0.6,
      V: 4000,
      p_w1_L: 0.54,
      p_w1_H: 0.65,
      p_m1_L: 0.53,
      p_m1_H: 0.7,
      p_m3_L: 0.53,
      p_m3_H: 0.7,
      p_m6_L: 0.5,
      p_m6_H: 0.8,
      p_m12_L: 0.5,
      p_m12_H: 1.08,
    },
    1001: {
      H: 0.445,
      L: 0.435,
      O: 0.435,
      C: 0.445,
      V: 90000,
      p_w1_L: 0.42,
      p_w1_H: 0.455,
      p_m1_L: 0.26,
      p_m1_H: 0.65,
      p_m3_L: 0.26,
      p_m3_H: 0.65,
      p_m6_L: 0.232,
      p_m6_H: 0.65,
      p_m12_L: 0.228,
      p_m12_H: 0.65,
    },
    1002: {
      H: 0.138,
      L: 0.138,
      O: 0.138,
      C: 0.138,
      V: 0,
      p_w1_L: 0.13,
      p_w1_H: 0.138,
      p_m1_L: 0.119,
      p_m1_H: 0.139,
      p_m3_L: 0.109,
      p_m3_H: 0.155,
      p_m6_L: 0.109,
      p_m6_H: 0.195,
      p_m12_L: 0.109,
      p_m12_H: 0.201,
    },
    1003: {
      H: 1.44,
      L: 1.39,
      O: 1.39,
      C: 1.44,
      V: 2640000,
      p_w1_L: 1.34,
      p_w1_H: 1.52,
      p_m1_L: 1.34,
      p_m1_H: 1.85,
      p_m3_L: 1.34,
      p_m3_H: 1.85,
      p_m6_L: 1.34,
      p_m6_H: 1.94,
      p_m12_L: 1.28,
      p_m12_H: 2.75,
    },
    1004: {
      H: 0.041,
      L: 0.041,
      O: 0.041,
      C: 0.041,
      V: 0,
      p_w1_L: 0.041,
      p_w1_H: 0.041,
      p_m1_L: 0.041,
      p_m1_H: 0.041,
      p_m3_L: 0.041,
      p_m3_H: 0.041,
      p_m6_L: 0.041,
      p_m6_H: 0.041,
      p_m12_L: 0.029,
      p_m12_H: 0.1,
    },
    1005: {
      H: 2.8,
      L: 2.8,
      O: 2.8,
      C: 2.8,
      V: 86000,
      p_w1_L: 2.6,
      p_w1_H: 2.8,
      p_m1_L: 2.55,
      p_m1_H: 2.8,
      p_m3_L: 2.55,
      p_m3_H: 2.8,
      p_m6_L: 2.55,
      p_m6_H: 3,
      p_m12_L: 1.78,
      p_m12_H: 3,
    },
    1007: {
      H: 0.115,
      L: 0.108,
      O: 0.108,
      C: 0.114,
      V: 770000,
      p_w1_L: 0.108,
      p_w1_H: 0.115,
      p_m1_L: 0.101,
      p_m1_H: 0.119,
      p_m3_L: 0.092,
      p_m3_H: 0.127,
      p_m6_L: 0.091,
      p_m6_H: 0.149,
      p_m12_L: 0.091,
      p_m12_H: 0.208,
    },
    1008: {
      H: 0.32,
      L: 0.32,
      O: 0.32,
      C: 0.32,
      V: 6000,
      p_w1_L: 0.32,
      p_w1_H: 0.33,
      p_m1_L: 0.32,
      p_m1_H: 0.35,
      p_m3_L: 0.315,
      p_m3_H: 0.37,
      p_m6_L: 0.315,
      p_m6_H: 0.53,
      p_m12_L: 0.275,
      p_m12_H: 0.53,
    },
    1009: {
      H: 0.355,
      L: 0.3,
      O: 0.3,
      C: 0.33,
      V: 3050000,
      p_w1_L: 0.27,
      p_w1_H: 0.385,
      p_m1_L: 0.27,
      p_m1_H: 0.57,
      p_m3_L: 0.27,
      p_m3_H: 0.57,
      p_m6_L: 0.27,
      p_m6_H: 0.57,
      p_m12_L: 0.27,
      p_m12_H: 0.8,
    },
    1010: {
      H: 5.46,
      L: 5.12,
      O: 5.15,
      C: 5.46,
      V: 1132000,
      p_w1_L: 4.11,
      p_w1_H: 5.46,
      p_m1_L: 3,
      p_m1_H: 6.3,
      p_m3_L: 2.38,
      p_m3_H: 6.3,
      p_m6_L: 2.3,
      p_m6_H: 6.3,
      p_m12_L: 0.64,
      p_m12_H: 6.3,
    },
    1011: {
      H: 0.07,
      L: 0.067,
      O: 0.07,
      C: 0.07,
      V: 9500,
      p_w1_L: 0.064,
      p_w1_H: 0.077,
      p_m1_L: 0.064,
      p_m1_H: 0.079,
      p_m3_L: 0.064,
      p_m3_H: 0.094,
      p_m6_L: 0.064,
      p_m6_H: 0.13,
      p_m12_L: 0.064,
      p_m12_H: 0.177,
    },
    1013: {
      H: 0.066,
      L: 0.06,
      O: 0.06,
      C: 0.065,
      V: 1120000,
      p_w1_L: 0.058,
      p_w1_H: 0.069,
      p_m1_L: 0.056,
      p_m1_H: 0.074,
      p_m3_L: 0.04,
      p_m3_H: 0.132,
      p_m6_L: 0.04,
      p_m6_H: 0.132,
      p_m12_L: 0.04,
      p_m12_H: 0.25,
    },
    1020: {
      H: 0.155,
      L: 0.148,
      O: 0.152,
      C: 0.155,
      V: 708000,
      p_w1_L: 0.145,
      p_w1_H: 0.163,
      p_m1_L: 0.145,
      p_m1_H: 0.182,
      p_m3_L: 0.145,
      p_m3_H: 0.244,
      p_m6_L: 0.081,
      p_m6_H: 0.28,
      p_m12_L: 0.061,
      p_m12_H: 0.28,
    },
    1022: {
      H: 0.465,
      L: 0.44,
      O: 0.465,
      C: 0.46,
      V: 615000,
      p_w1_L: 0.42,
      p_w1_H: 0.485,
      p_m1_L: 0.405,
      p_m1_H: 0.59,
      p_m3_L: 0.405,
      p_m3_H: 0.64,
      p_m6_L: 0.405,
      p_m6_H: 1.1,
      p_m12_L: 0.155,
      p_m12_H: 1.42,
    },
    1023: {
      H: 0.6,
      L: 0.53,
      O: 0.53,
      C: 0.55,
      V: 1189000,
      p_w1_L: 0.52,
      p_w1_H: 0.6,
      p_m1_L: 0.49,
      p_m1_H: 0.63,
      p_m3_L: 0.49,
      p_m3_H: 0.66,
      p_m6_L: 0.49,
      p_m6_H: 0.68,
      p_m12_L: 0.36,
      p_m12_H: 0.68,
    },
    1024: {
      H: 85.15,
      L: 82.8,
      O: 84.25,
      C: 84.2,
      V: 16304500,
      p_w1_L: 77.1,
      p_w1_H: 89.1,
      p_m1_L: 77.1,
      p_m1_H: 108.8,
      p_m3_L: 77.1,
      p_m3_H: 110.5,
      p_m6_L: 64.5,
      p_m6_H: 211,
      p_m12_L: 64.5,
      p_m12_H: 417.8,
    },
    1025: {
      H: 0.325,
      L: 0.31,
      O: 0.32,
      C: 0.31,
      V: 1092000,
      p_w1_L: 0.28,
      p_w1_H: 0.385,
      p_m1_L: 0.28,
      p_m1_H: 0.76,
      p_m3_L: 0.28,
      p_m3_H: 0.84,
      p_m6_L: 0.24,
      p_m6_H: 0.84,
      p_m12_L: 0.141,
      p_m12_H: 0.84,
    },
    1026: {
      H: 0.218,
      L: 0.218,
      O: 0.218,
      C: 0.218,
      V: 0,
      p_w1_L: 0.218,
      p_w1_H: 0.218,
      p_m1_L: 0.209,
      p_m1_H: 0.218,
      p_m3_L: 0.17,
      p_m3_H: 0.24,
      p_m6_L: 0.17,
      p_m6_H: 0.33,
      p_m12_L: 0.17,
      p_m12_H: 0.395,
    },
    1027: {
      H: 0.63,
      L: 0.61,
      O: 0.63,
      C: 0.63,
      V: 28000,
      p_w1_L: 0.61,
      p_w1_H: 0.68,
      p_m1_L: 0.56,
      p_m1_H: 0.74,
      p_m3_L: 0.56,
      p_m3_H: 0.8,
      p_m6_L: 0.55,
      p_m6_H: 0.97,
      p_m12_L: 0.223,
      p_m12_H: 1.36,
    },
    1028: {
      H: 0.375,
      L: 0.32,
      O: 0.375,
      C: 0.34,
      V: 81000,
      p_w1_L: 0.305,
      p_w1_H: 0.375,
      p_m1_L: 0.26,
      p_m1_H: 0.375,
      p_m3_L: 0.26,
      p_m3_H: 0.38,
      p_m6_L: 0.26,
      p_m6_H: 0.435,
      p_m12_L: 0.152,
      p_m12_H: 0.6,
    },
    1029: {
      H: 0.246,
      L: 0.234,
      O: 0.237,
      C: 0.244,
      V: 22893300,
      p_w1_L: 0.22,
      p_w1_H: 0.246,
      p_m1_L: 0.216,
      p_m1_H: 0.255,
      p_m3_L: 0.216,
      p_m3_H: 0.36,
      p_m6_L: 0.196,
      p_m6_H: 0.36,
      p_m12_L: 0.13,
      p_m12_H: 0.495,
    },
    1030: {
      H: 5.66,
      L: 5.46,
      O: 5.57,
      C: 5.47,
      V: 5904000,
      p_w1_L: 5.19,
      p_w1_H: 5.68,
      p_m1_L: 5.19,
      p_m1_H: 6.75,
      p_m3_L: 5.06,
      p_m3_H: 7.34,
      p_m6_L: 5.06,
      p_m6_H: 8.32,
      p_m12_L: 5.06,
      p_m12_H: 10.14,
    },
    1031: {
      H: 0.415,
      L: 0.395,
      O: 0.415,
      C: 0.415,
      V: 194000,
      p_w1_L: 0.365,
      p_w1_H: 0.485,
      p_m1_L: 0.365,
      p_m1_H: 0.57,
      p_m3_L: 0.365,
      p_m3_H: 0.63,
      p_m6_L: 0.365,
      p_m6_H: 0.63,
      p_m12_L: 0.365,
      p_m12_H: 0.74,
    },
    1033: {
      H: 0.66,
      L: 0.64,
      O: 0.65,
      C: 0.65,
      V: 2676000,
      p_w1_L: 0.62,
      p_w1_H: 0.66,
      p_m1_L: 0.62,
      p_m1_H: 0.72,
      p_m3_L: 0.62,
      p_m3_H: 0.96,
      p_m6_L: 0.58,
      p_m6_H: 0.96,
      p_m12_L: 0.56,
      p_m12_H: 0.96,
    },
    1034: {
      H: 2.09,
      L: 2.03,
      O: 2.06,
      C: 2.06,
      V: 2640000,
      p_w1_L: 2.02,
      p_w1_H: 2.1,
      p_m1_L: 2.02,
      p_m1_H: 2.19,
      p_m3_L: 1.89,
      p_m3_H: 2.4,
      p_m6_L: 1.48,
      p_m6_H: 2.4,
      p_m12_L: 0.315,
      p_m12_H: 2.4,
    },
    1036: {
      H: 2.23,
      L: 2.23,
      O: 2.23,
      C: 2.23,
      V: 0,
      p_w1_L: 2.2,
      p_w1_H: 2.39,
      p_m1_L: 2.2,
      p_m1_H: 2.5,
      p_m3_L: 2.2,
      p_m3_H: 2.85,
      p_m6_L: 2.2,
      p_m6_H: 3.49,
      p_m12_L: 2.2,
      p_m12_H: 3.49,
    },
    1037: {
      H: 0.355,
      L: 0.35,
      O: 0.355,
      C: 0.355,
      V: 10000,
      p_w1_L: 0.35,
      p_w1_H: 0.39,
      p_m1_L: 0.35,
      p_m1_H: 0.46,
      p_m3_L: 0.35,
      p_m3_H: 0.46,
      p_m6_L: 0.345,
      p_m6_H: 0.52,
      p_m12_L: 0.345,
      p_m12_H: 1,
    },
    1038: {
      H: 45.9,
      L: 45.2,
      O: 45.85,
      C: 45.9,
      V: 1808090,
      p_w1_L: 44.15,
      p_w1_H: 46.15,
      p_m1_L: 44.15,
      p_m1_H: 48,
      p_m3_L: 42.3,
      p_m3_H: 48,
      p_m6_L: 42.3,
      p_m6_H: 48.5,
      p_m12_L: 39.15,
      p_m12_H: 50.25,
    },
    1039: {
      H: 0.3,
      L: 0.3,
      O: 0.3,
      C: 0.3,
      V: 0,
      p_w1_L: 0.255,
      p_w1_H: 0.305,
      p_m1_L: 0.255,
      p_m1_H: 0.31,
      p_m3_L: 0.24,
      p_m3_H: 0.33,
      p_m6_L: 0.24,
      p_m6_H: 0.42,
      p_m12_L: 0.24,
      p_m12_H: 0.5,
    },
    1041: {
      H: 0.043,
      L: 0.043,
      O: 0.043,
      C: 0.043,
      V: 0,
      p_w1_L: 0.043,
      p_w1_H: 0.043,
      p_m1_L: 0.043,
      p_m1_H: 0.043,
      p_m3_L: 0.043,
      p_m3_H: 0.043,
      p_m6_L: 0.043,
      p_m6_H: 0.043,
      p_m12_L: 0.043,
      p_m12_H: 0.043,
    },
    1043: {
      H: 0.52,
      L: 0.52,
      O: 0.52,
      C: 0.52,
      V: 0,
      p_w1_L: 0.52,
      p_w1_H: 0.52,
      p_m1_L: 0.52,
      p_m1_H: 0.52,
      p_m3_L: 0.52,
      p_m3_H: 0.52,
      p_m6_L: 0.52,
      p_m6_H: 0.52,
      p_m12_L: 0.52,
      p_m12_H: 0.52,
    },
    1044: {
      H: 39.05,
      L: 38.2,
      O: 39.05,
      C: 38.35,
      V: 2450290,
      p_w1_L: 36.8,
      p_w1_H: 39.7,
      p_m1_L: 36.8,
      p_m1_H: 40.2,
      p_m3_L: 36.8,
      p_m3_H: 44.2,
      p_m6_L: 36.8,
      p_m6_H: 54.4,
      p_m12_L: 36.8,
      p_m12_H: 59,
    },
    1045: {
      H: 2.27,
      L: 2.21,
      O: 2.21,
      C: 2.25,
      V: 322000,
      p_w1_L: 2.2,
      p_w1_H: 2.29,
      p_m1_L: 2.2,
      p_m1_H: 2.32,
      p_m3_L: 2.2,
      p_m3_H: 2.64,
      p_m6_L: 2.2,
      p_m6_H: 2.68,
      p_m12_L: 1.7,
      p_m12_H: 2.95,
    },
    1046: {
      H: 0.53,
      L: 0.5,
      O: 0.52,
      C: 0.53,
      V: 348300,
      p_w1_L: 0.49,
      p_w1_H: 0.53,
      p_m1_L: 0.47,
      p_m1_H: 0.56,
      p_m3_L: 0.4,
      p_m3_H: 0.59,
      p_m6_L: 0.335,
      p_m6_H: 0.59,
      p_m12_L: 0.335,
      p_m12_H: 0.71,
    },
    1047: {
      H: 0.64,
      L: 0.64,
      O: 0.64,
      C: 0.64,
      V: 0,
      p_w1_L: 0.64,
      p_w1_H: 0.66,
      p_m1_L: 0.64,
      p_m1_H: 0.7,
      p_m3_L: 0.57,
      p_m3_H: 0.75,
      p_m6_L: 0.42,
      p_m6_H: 0.75,
      p_m12_L: 0.315,
      p_m12_H: 0.75,
    },
    1049: {
      H: 1.95,
      L: 1.95,
      O: 1.95,
      C: 1.95,
      V: 0,
      p_w1_L: 1.95,
      p_w1_H: 1.96,
      p_m1_L: 1.58,
      p_m1_H: 1.97,
      p_m3_L: 1.4,
      p_m3_H: 2.1,
      p_m6_L: 1.3,
      p_m6_H: 2.1,
      p_m12_L: 1.13,
      p_m12_H: 2.1,
    },
    1050: {
      H: 1.67,
      L: 1.66,
      O: 1.67,
      C: 1.67,
      V: 310000,
      p_w1_L: 1.63,
      p_w1_H: 1.68,
      p_m1_L: 1.53,
      p_m1_H: 1.68,
      p_m3_L: 1.41,
      p_m3_H: 1.68,
      p_m6_L: 1.41,
      p_m6_H: 1.92,
      p_m12_L: 1.08,
      p_m12_H: 2.07,
    },
    1051: {
      H: 2.78,
      L: 2.7,
      O: 2.78,
      C: 2.77,
      V: 40850,
      p_w1_L: 2.7,
      p_w1_H: 2.9,
      p_m1_L: 2.7,
      p_m1_H: 3.05,
      p_m3_L: 2.7,
      p_m3_H: 3.25,
      p_m6_L: 0.043,
      p_m6_H: 3.4,
      p_m12_L: 0.037,
      p_m12_H: 3.4,
    },
    1052: {
      H: 4.79,
      L: 4.72,
      O: 4.79,
      C: 4.76,
      V: 1096800,
      p_w1_L: 4.67,
      p_w1_H: 4.9,
      p_m1_L: 4.65,
      p_m1_H: 5.24,
      p_m3_L: 4.4,
      p_m3_H: 5.35,
      p_m6_L: 4,
      p_m6_H: 5.35,
      p_m12_L: 4,
      p_m12_H: 5.69,
    },
    1053: {
      H: 1.36,
      L: 1.33,
      O: 1.34,
      C: 1.36,
      V: 1596200,
      p_w1_L: 1.24,
      p_w1_H: 1.36,
      p_m1_L: 1.22,
      p_m1_H: 1.41,
      p_m3_L: 1.19,
      p_m3_H: 2.49,
      p_m6_L: 1.19,
      p_m6_H: 2.49,
      p_m12_L: 0.74,
      p_m12_H: 3.21,
    },
    1055: {
      H: 4.7,
      L: 4.58,
      O: 4.58,
      C: 4.62,
      V: 10158400,
      p_w1_L: 4.18,
      p_w1_H: 4.7,
      p_m1_L: 4.18,
      p_m1_H: 5.2,
      p_m3_L: 4.05,
      p_m3_H: 5.2,
      p_m6_L: 3.89,
      p_m6_H: 5.4,
      p_m12_L: 3.89,
      p_m12_H: 6.3,
    },
    1057: {
      H: 2.06,
      L: 1.77,
      O: 1.79,
      C: 1.95,
      V: 21443000,
      p_w1_L: 1.73,
      p_w1_H: 2.06,
      p_m1_L: 1.73,
      p_m1_H: 2.22,
      p_m3_L: 1.45,
      p_m3_H: 2.22,
      p_m6_L: 1.45,
      p_m6_H: 2.91,
      p_m12_L: 0.98,
      p_m12_H: 3.2,
    },
    1058: {
      H: 0.58,
      L: 0.55,
      O: 0.58,
      C: 0.56,
      V: 230000,
      p_w1_L: 0.5,
      p_w1_H: 0.67,
      p_m1_L: 0.5,
      p_m1_H: 0.93,
      p_m3_L: 0.25,
      p_m3_H: 1.51,
      p_m6_L: 0.25,
      p_m6_H: 1.51,
      p_m12_L: 0.228,
      p_m12_H: 1.51,
    },
    1059: {
      H: 0.58,
      L: 0.55,
      O: 0.56,
      C: 0.57,
      V: 246278,
      p_w1_L: 0.53,
      p_w1_H: 0.6,
      p_m1_L: 0.51,
      p_m1_H: 0.69,
      p_m3_L: 0.46,
      p_m3_H: 0.69,
      p_m6_L: 0.41,
      p_m6_H: 0.9,
      p_m12_L: 0.25,
      p_m12_H: 0.9,
    },
    1060: {
      H: 0.78,
      L: 0.76,
      O: 0.77,
      C: 0.76,
      V: 18980000,
      p_w1_L: 0.73,
      p_w1_H: 0.83,
      p_m1_L: 0.73,
      p_m1_H: 0.9,
      p_m3_L: 0.66,
      p_m3_H: 0.93,
      p_m6_L: 0.66,
      p_m6_H: 1.11,
      p_m12_L: 0.66,
      p_m12_H: 1.47,
    },
    1061: {
      H: 5.21,
      L: 5.13,
      O: 5.19,
      C: 5.21,
      V: 30000,
      p_w1_L: 5,
      p_w1_H: 5.6,
      p_m1_L: 4.72,
      p_m1_H: 5.65,
      p_m3_L: 4.72,
      p_m3_H: 6.78,
      p_m6_L: 4.72,
      p_m6_H: 8.29,
      p_m12_L: 3.65,
      p_m12_H: 8.29,
    },
    1062: {
      H: 0.132,
      L: 0.113,
      O: 0.132,
      C: 0.129,
      V: 30000,
      p_w1_L: 0.113,
      p_w1_H: 0.138,
      p_m1_L: 0.113,
      p_m1_H: 0.158,
      p_m3_L: 0.113,
      p_m3_H: 0.168,
      p_m6_L: 0.113,
      p_m6_H: 0.168,
      p_m12_L: 0.113,
      p_m12_H: 0.24,
    },
    1063: {
      H: 0.375,
      L: 0.355,
      O: 0.375,
      C: 0.355,
      V: 311520,
      p_w1_L: 0.355,
      p_w1_H: 0.395,
      p_m1_L: 0.355,
      p_m1_H: 0.435,
      p_m3_L: 0.285,
      p_m3_H: 0.46,
      p_m6_L: 0.129,
      p_m6_H: 0.58,
      p_m12_L: 0.078,
      p_m12_H: 0.58,
    },
    1064: {
      H: 0.115,
      L: 0.113,
      O: 0.113,
      C: 0.115,
      V: 45000,
      p_w1_L: 0.113,
      p_w1_H: 0.122,
      p_m1_L: 0.112,
      p_m1_H: 0.126,
      p_m3_L: 0.11,
      p_m3_H: 0.163,
      p_m6_L: 0.11,
      p_m6_H: 0.163,
      p_m12_L: 0.11,
      p_m12_H: 0.165,
    },
    1065: {
      H: 3.78,
      L: 3.61,
      O: 3.75,
      C: 3.7,
      V: 816000,
      p_w1_L: 3.55,
      p_w1_H: 3.8,
      p_m1_L: 3.5,
      p_m1_H: 4.18,
      p_m3_L: 3,
      p_m3_H: 4.18,
      p_m6_L: 2.71,
      p_m6_H: 4.18,
      p_m12_L: 2.71,
      p_m12_H: 4.18,
    },
    1066: {
      H: 11.08,
      L: 10.72,
      O: 11.02,
      C: 10.82,
      V: 3238200,
      p_w1_L: 10.32,
      p_w1_H: 11.24,
      p_m1_L: 10.32,
      p_m1_H: 12.48,
      p_m3_L: 10.32,
      p_m3_H: 14.86,
      p_m6_L: 10.32,
      p_m6_H: 20.65,
      p_m12_L: 10.32,
      p_m12_H: 20.65,
    },
    1068: {
      H: 0.84,
      L: 0.8,
      O: 0.8,
      C: 0.84,
      V: 767000,
      p_w1_L: 0.78,
      p_w1_H: 0.88,
      p_m1_L: 0.78,
      p_m1_H: 0.91,
      p_m3_L: 0.78,
      p_m3_H: 1,
      p_m6_L: 0.78,
      p_m6_H: 1.13,
      p_m12_L: 0.6,
      p_m12_H: 1.49,
    },
    1069: {
      H: 0.01,
      L: 0.01,
      O: 0.01,
      C: 0.01,
      V: 0,
      p_w1_L: 0.01,
      p_w1_H: 0.01,
      p_m1_L: 0.01,
      p_m1_H: 0.01,
      p_m3_L: 0.01,
      p_m3_H: 0.012,
      p_m6_L: 0.01,
      p_m6_H: 0.015,
      p_m12_L: 0.01,
      p_m12_H: 0.026,
    },
    1070: {
      H: 4.3,
      L: 4.21,
      O: 4.3,
      C: 4.24,
      V: 1316500,
      p_w1_L: 4.07,
      p_w1_H: 4.38,
      p_m1_L: 3.96,
      p_m1_H: 4.72,
      p_m3_L: 3.59,
      p_m3_H: 4.72,
      p_m6_L: 3.59,
      p_m6_H: 5.09,
      p_m12_L: 3.59,
      p_m12_H: 7.95,
    },
    1071: {
      H: 2.97,
      L: 2.78,
      O: 2.78,
      C: 2.94,
      V: 52284000,
      p_w1_L: 2.42,
      p_w1_H: 2.97,
      p_m1_L: 2.35,
      p_m1_H: 2.97,
      p_m3_L: 2.35,
      p_m3_H: 3.63,
      p_m6_L: 2.08,
      p_m6_H: 3.63,
      p_m12_L: 1.85,
      p_m12_H: 3.63,
    },
    1072: {
      H: 13.92,
      L: 13,
      O: 13.68,
      C: 13.34,
      V: 6815400,
      p_w1_L: 11.98,
      p_w1_H: 13.94,
      p_m1_L: 10.7,
      p_m1_H: 13.94,
      p_m3_L: 10.04,
      p_m3_H: 14.5,
      p_m6_L: 5.61,
      p_m6_H: 14.5,
      p_m12_L: 5.18,
      p_m12_H: 14.5,
    },
    1073: {
      H: 0.255,
      L: 0.255,
      O: 0.255,
      C: 0.255,
      V: 3000,
      p_w1_L: 0.245,
      p_w1_H: 0.27,
      p_m1_L: 0.245,
      p_m1_H: 0.27,
      p_m3_L: 0.23,
      p_m3_H: 0.31,
      p_m6_L: 0.225,
      p_m6_H: 0.4,
      p_m12_L: 0.225,
      p_m12_H: 0.4,
    },
    1074: {
      H: 0.088,
      L: 0.08,
      O: 0.08,
      C: 0.088,
      V: 288000,
      p_w1_L: 0.07,
      p_w1_H: 0.09,
      p_m1_L: 0.04,
      p_m1_H: 0.09,
      p_m3_L: 0.04,
      p_m3_H: 0.25,
      p_m6_L: 0.04,
      p_m6_H: 0.25,
      p_m12_L: 0.04,
      p_m12_H: 0.25,
    },
    1075: {
      H: 0.41,
      L: 0.4,
      O: 0.4,
      C: 0.4,
      V: 222000,
      p_w1_L: 0.39,
      p_w1_H: 0.42,
      p_m1_L: 0.39,
      p_m1_H: 0.495,
      p_m3_L: 0.39,
      p_m3_H: 0.63,
      p_m6_L: 0.34,
      p_m6_H: 0.63,
      p_m12_L: 0.335,
      p_m12_H: 0.63,
    },
    1076: {
      H: 0.107,
      L: 0.099,
      O: 0.104,
      C: 0.099,
      V: 4401650,
      p_w1_L: 0.08,
      p_w1_H: 0.122,
      p_m1_L: 0.077,
      p_m1_H: 0.122,
      p_m3_L: 0.037,
      p_m3_H: 0.2,
      p_m6_L: 0.01,
      p_m6_H: 0.24,
      p_m12_L: 0.01,
      p_m12_H: 0.24,
    },
    1079: {
      H: 0.115,
      L: 0.108,
      O: 0.109,
      C: 0.111,
      V: 1362000,
      p_w1_L: 0.1,
      p_w1_H: 0.132,
      p_m1_L: 0.1,
      p_m1_H: 0.137,
      p_m3_L: 0.076,
      p_m3_H: 0.137,
      p_m6_L: 0.076,
      p_m6_H: 0.137,
      p_m12_L: 0.076,
      p_m12_H: 0.182,
    },
    1080: {
      H: 0.076,
      L: 0.073,
      O: 0.075,
      C: 0.076,
      V: 57000,
      p_w1_L: 0.073,
      p_w1_H: 0.077,
      p_m1_L: 0.073,
      p_m1_H: 0.083,
      p_m3_L: 0.073,
      p_m3_H: 0.114,
      p_m6_L: 0.072,
      p_m6_H: 0.114,
      p_m12_L: 0.072,
      p_m12_H: 0.136,
    },
    1082: {
      H: 1.59,
      L: 1.54,
      O: 1.55,
      C: 1.59,
      V: 7700000,
      p_w1_L: 1.53,
      p_w1_H: 1.59,
      p_m1_L: 1.53,
      p_m1_H: 1.65,
      p_m3_L: 1.42,
      p_m3_H: 1.73,
      p_m6_L: 1.31,
      p_m6_H: 1.75,
      p_m12_L: 1.2,
      p_m12_H: 1.75,
    },
    1083: {
      H: 5.9,
      L: 5.63,
      O: 5.66,
      C: 5.89,
      V: 13711000,
      p_w1_L: 5.26,
      p_w1_H: 5.9,
      p_m1_L: 5.03,
      p_m1_H: 5.9,
      p_m3_L: 4.21,
      p_m3_H: 5.9,
      p_m6_L: 4.21,
      p_m6_H: 5.92,
      p_m12_L: 3.28,
      p_m12_H: 5.92,
    },
    1084: {
      H: 1.14,
      L: 1.12,
      O: 1.14,
      C: 1.12,
      V: 60000,
      p_w1_L: 1.12,
      p_w1_H: 1.14,
      p_m1_L: 1.04,
      p_m1_H: 1.17,
      p_m3_L: 1.03,
      p_m3_H: 1.21,
      p_m6_L: 1.03,
      p_m6_H: 1.3,
      p_m12_L: 0.96,
      p_m12_H: 1.37,
    },
    1085: {
      H: 3.51,
      L: 3.4,
      O: 3.44,
      C: 3.51,
      V: 192000,
      p_w1_L: 3.14,
      p_w1_H: 3.62,
      p_m1_L: 2.79,
      p_m1_H: 3.62,
      p_m3_L: 2.65,
      p_m3_H: 3.75,
      p_m6_L: 2.09,
      p_m6_H: 3.75,
      p_m12_L: 1.98,
      p_m12_H: 3.75,
    },
    1086: {
      H: 1.17,
      L: 1.14,
      O: 1.14,
      C: 1.16,
      V: 759000,
      p_w1_L: 1.09,
      p_w1_H: 1.23,
      p_m1_L: 1.09,
      p_m1_H: 1.39,
      p_m3_L: 1.07,
      p_m3_H: 1.39,
      p_m6_L: 1.07,
      p_m6_H: 2.02,
      p_m12_L: 0.92,
      p_m12_H: 2.57,
    },
    1087: {
      H: 1.9,
      L: 1.9,
      O: 1.9,
      C: 1.9,
      V: 1500,
      p_w1_L: 1.8,
      p_w1_H: 1.93,
      p_m1_L: 1.79,
      p_m1_H: 1.99,
      p_m3_L: 1.35,
      p_m3_H: 2.48,
      p_m6_L: 0.06,
      p_m6_H: 2.48,
      p_m12_L: 0.06,
      p_m12_H: 2.48,
    },
    1088: {
      H: 18.22,
      L: 17.78,
      O: 18.16,
      C: 18,
      V: 21467200,
      p_w1_L: 16.1,
      p_w1_H: 18.8,
      p_m1_L: 15.74,
      p_m1_H: 18.8,
      p_m3_L: 15.74,
      p_m3_H: 19.36,
      p_m6_L: 14.3,
      p_m6_H: 19.36,
      p_m12_L: 13.64,
      p_m12_H: 19.36,
    },
    1090: {
      H: 3.05,
      L: 2.88,
      O: 3.03,
      C: 2.88,
      V: 626000,
      p_w1_L: 2.88,
      p_w1_H: 3.42,
      p_m1_L: 2.88,
      p_m1_H: 3.5,
      p_m3_L: 2.88,
      p_m3_H: 4.25,
      p_m6_L: 2.88,
      p_m6_H: 4.25,
      p_m12_L: 2,
      p_m12_H: 4.25,
    },
    1091: {
      H: 0.98,
      L: 0.94,
      O: 0.96,
      C: 0.96,
      V: 8430000,
      p_w1_L: 0.93,
      p_w1_H: 1.06,
      p_m1_L: 0.87,
      p_m1_H: 1.14,
      p_m3_L: 0.87,
      p_m3_H: 1.6,
      p_m6_L: 0.47,
      p_m6_H: 1.89,
      p_m12_L: 0.35,
      p_m12_H: 1.89,
    },
    1093: {
      H: 8.21,
      L: 8.06,
      O: 8.17,
      C: 8.16,
      V: 26659300,
      p_w1_L: 7.96,
      p_w1_H: 8.5,
      p_m1_L: 7.73,
      p_m1_H: 8.56,
      p_m3_L: 7.73,
      p_m3_H: 9.89,
      p_m6_L: 7.73,
      p_m6_H: 12.58,
      p_m12_L: 7.08,
      p_m12_H: 12.68,
    },
    1094: {
      H: 1.65,
      L: 1.62,
      O: 1.62,
      C: 1.65,
      V: 131120,
      p_w1_L: 1.5,
      p_w1_H: 1.74,
      p_m1_L: 1.3,
      p_m1_H: 1.89,
      p_m3_L: 0.4,
      p_m3_H: 3,
      p_m6_L: 0.175,
      p_m6_H: 3,
      p_m12_L: 0.165,
      p_m12_H: 3,
    },
    1096: {
      H: 0.026,
      L: 0.026,
      O: 0.026,
      C: 0.026,
      V: 0,
      p_w1_L: 0.026,
      p_w1_H: 0.026,
      p_m1_L: 0.026,
      p_m1_H: 0.026,
      p_m3_L: 0.026,
      p_m3_H: 0.026,
      p_m6_L: 0.026,
      p_m6_H: 0.026,
      p_m12_L: 0.026,
      p_m12_H: 0.026,
    },
    1097: {
      H: 0.07,
      L: 0.069,
      O: 0.069,
      C: 0.069,
      V: 200086,
      p_w1_L: 0.067,
      p_w1_H: 0.074,
      p_m1_L: 0.064,
      p_m1_H: 0.095,
      p_m3_L: 0.064,
      p_m3_H: 0.095,
      p_m6_L: 0.064,
      p_m6_H: 0.095,
      p_m12_L: 0.059,
      p_m12_H: 0.1,
    },
    1098: {
      H: 7.36,
      L: 7.28,
      O: 7.36,
      C: 7.28,
      V: 81000,
      p_w1_L: 7.1,
      p_w1_H: 7.4,
      p_m1_L: 7.1,
      p_m1_H: 8.02,
      p_m3_L: 7.1,
      p_m3_H: 8.9,
      p_m6_L: 7.1,
      p_m6_H: 10.18,
      p_m12_L: 7.1,
      p_m12_H: 11.24,
    },
    1099: {
      H: 17.28,
      L: 17.02,
      O: 17.2,
      C: 17.22,
      V: 2794400,
      p_w1_L: 16.4,
      p_w1_H: 17.62,
      p_m1_L: 16.4,
      p_m1_H: 18.88,
      p_m3_L: 16.4,
      p_m3_H: 22.2,
      p_m6_L: 16.4,
      p_m6_H: 28,
      p_m12_L: 16.4,
      p_m12_H: 28,
    },
    1100: {
      H: 1.55,
      L: 1.46,
      O: 1.47,
      C: 1.55,
      V: 108000,
      p_w1_L: 1.46,
      p_w1_H: 1.55,
      p_m1_L: 1.38,
      p_m1_H: 1.55,
      p_m3_L: 1.25,
      p_m3_H: 1.55,
      p_m6_L: 0.9,
      p_m6_H: 1.55,
      p_m12_L: 0.79,
      p_m12_H: 1.55,
    },
    1101: {
      H: 0.11,
      L: 0.106,
      O: 0.11,
      C: 0.111,
      V: 614200,
      p_w1_L: 0.106,
      p_w1_H: 0.123,
      p_m1_L: 0.106,
      p_m1_H: 0.125,
      p_m3_L: 0.1,
      p_m3_H: 0.127,
      p_m6_L: 0.095,
      p_m6_H: 0.127,
      p_m12_L: 0.095,
      p_m12_H: 0.161,
    },
    1102: {
      H: 0.09,
      L: 0.09,
      O: 0.09,
      C: 0.09,
      V: 0,
      p_w1_L: 0.09,
      p_w1_H: 0.09,
      p_m1_L: 0.09,
      p_m1_H: 0.09,
      p_m3_L: 0.086,
      p_m3_H: 0.115,
      p_m6_L: 0.073,
      p_m6_H: 0.125,
      p_m12_L: 0.073,
      p_m12_H: 0.405,
    },
    1103: {
      H: 0.012,
      L: 0.01,
      O: 0.011,
      C: 0.012,
      V: 3488000,
      p_w1_L: 0.01,
      p_w1_H: 0.012,
      p_m1_L: 0.01,
      p_m1_H: 0.025,
      p_m3_L: 0.01,
      p_m3_H: 0.025,
      p_m6_L: 0.01,
      p_m6_H: 0.025,
      p_m12_L: 0.01,
      p_m12_H: 0.032,
    },
    1104: {
      H: 1.32,
      L: 1.28,
      O: 1.3,
      C: 1.29,
      V: 1120000,
      p_w1_L: 1.25,
      p_w1_H: 1.33,
      p_m1_L: 1.21,
      p_m1_H: 1.33,
      p_m3_L: 1.21,
      p_m3_H: 1.46,
      p_m6_L: 1.16,
      p_m6_H: 1.5,
      p_m12_L: 0.84,
      p_m12_H: 1.52,
    },
    1105: {
      H: 0.5,
      L: 0.49,
      O: 0.5,
      C: 0.495,
      V: 30000,
      p_w1_L: 0.49,
      p_w1_H: 0.54,
      p_m1_L: 0.485,
      p_m1_H: 0.62,
      p_m3_L: 0.485,
      p_m3_H: 0.89,
      p_m6_L: 0.485,
      p_m6_H: 0.99,
      p_m12_L: 0.485,
      p_m12_H: 1.4,
    },
    1107: {
      H: 0.38,
      L: 0.38,
      O: 0.38,
      C: 0.38,
      V: 0,
      p_w1_L: 0.38,
      p_w1_H: 0.38,
      p_m1_L: 0.38,
      p_m1_H: 0.38,
      p_m3_L: 0.38,
      p_m3_H: 0.7,
      p_m6_L: 0.38,
      p_m6_H: 0.82,
      p_m12_L: 0.38,
      p_m12_H: 1.06,
    },
    1108: {
      H: 17.48,
      L: 16.64,
      O: 17.02,
      C: 16.9,
      V: 4634000,
      p_w1_L: 14.48,
      p_w1_H: 18.6,
      p_m1_L: 10,
      p_m1_H: 18.6,
      p_m3_L: 8.75,
      p_m3_H: 18.6,
      p_m6_L: 5.96,
      p_m6_H: 18.6,
      p_m12_L: 4.28,
      p_m12_H: 18.6,
    },
    1109: {
      H: 34.35,
      L: 33.15,
      O: 34.35,
      C: 34.15,
      V: 13151000,
      p_w1_L: 32.05,
      p_w1_H: 35.5,
      p_m1_L: 28.2,
      p_m1_H: 35.75,
      p_m3_L: 26.85,
      p_m3_H: 35.75,
      p_m6_L: 25.5,
      p_m6_H: 36.85,
      p_m12_L: 25.5,
      p_m12_H: 41.3,
    },
    1110: {
      H: 0.76,
      L: 0.75,
      O: 0.76,
      C: 0.75,
      V: 464000,
      p_w1_L: 0.74,
      p_w1_H: 0.76,
      p_m1_L: 0.7,
      p_m1_H: 0.86,
      p_m3_L: 0.7,
      p_m3_H: 0.86,
      p_m6_L: 0.7,
      p_m6_H: 1.1,
      p_m12_L: 0.64,
      p_m12_H: 1.1,
    },
    1112: {
      H: 13.24,
      L: 12.76,
      O: 13.18,
      C: 13.1,
      V: 1393500,
      p_w1_L: 12.76,
      p_w1_H: 14.46,
      p_m1_L: 12.76,
      p_m1_H: 17.38,
      p_m3_L: 12.76,
      p_m3_H: 23.45,
      p_m6_L: 12.76,
      p_m6_H: 32.8,
      p_m12_L: 12.76,
      p_m12_H: 40.2,
    },
    1113: {
      H: 46.5,
      L: 45.75,
      O: 46.5,
      C: 46.25,
      V: 4388060,
      p_w1_L: 44.4,
      p_w1_H: 46.5,
      p_m1_L: 44.15,
      p_m1_H: 48.55,
      p_m3_L: 41.35,
      p_m3_H: 49.8,
      p_m6_L: 41.35,
      p_m6_H: 56.05,
      p_m12_L: 38.25,
      p_m12_H: 56.05,
    },
    1114: {
      H: 7.3,
      L: 7.3,
      O: 7.3,
      C: 7.3,
      V: 0,
      p_w1_L: 7.3,
      p_w1_H: 7.3,
      p_m1_L: 7.3,
      p_m1_H: 7.3,
      p_m3_L: 7.3,
      p_m3_H: 7.3,
      p_m6_L: 7.3,
      p_m6_H: 7.3,
      p_m12_L: 5.94,
      p_m12_H: 7.99,
    },
    1115: {
      H: 0.52,
      L: 0.52,
      O: 0.52,
      C: 0.52,
      V: 23000,
      p_w1_L: 0.51,
      p_w1_H: 0.57,
      p_m1_L: 0.51,
      p_m1_H: 0.63,
      p_m3_L: 0.48,
      p_m3_H: 0.67,
      p_m6_L: 0.48,
      p_m6_H: 0.99,
      p_m12_L: 0.48,
      p_m12_H: 1.21,
    },
    1116: {
      H: 0.188,
      L: 0.188,
      O: 0.188,
      C: 0.188,
      V: 0,
      p_w1_L: 0.165,
      p_w1_H: 0.197,
      p_m1_L: 0.165,
      p_m1_H: 0.206,
      p_m3_L: 0.148,
      p_m3_H: 0.218,
      p_m6_L: 0.148,
      p_m6_H: 0.245,
      p_m12_L: 0.148,
      p_m12_H: 0.25,
    },
    1117: {
      H: 1.32,
      L: 1.3,
      O: 1.31,
      C: 1.3,
      V: 4473000,
      p_w1_L: 1.27,
      p_w1_H: 1.33,
      p_m1_L: 1.27,
      p_m1_H: 1.6,
      p_m3_L: 1.27,
      p_m3_H: 1.86,
      p_m6_L: 1.25,
      p_m6_H: 1.9,
      p_m12_L: 1.25,
      p_m12_H: 3.1,
    },
    1118: {
      H: 0.95,
      L: 0.95,
      O: 0.95,
      C: 0.95,
      V: 0,
      p_w1_L: 0.95,
      p_w1_H: 0.95,
      p_m1_L: 0.9,
      p_m1_H: 0.97,
      p_m3_L: 0.86,
      p_m3_H: 0.97,
      p_m6_L: 0.8,
      p_m6_H: 0.97,
      p_m12_L: 0.6,
      p_m12_H: 0.97,
    },
    1119: {
      H: 6.46,
      L: 6.13,
      O: 6.35,
      C: 6.42,
      V: 5628800,
      p_w1_L: 5.81,
      p_w1_H: 6.6,
      p_m1_L: 4.99,
      p_m1_H: 6.94,
      p_m3_L: 3.8,
      p_m3_H: 6.94,
      p_m6_L: 3.8,
      p_m6_H: 8.42,
      p_m12_L: 3.54,
      p_m12_H: 8.42,
    },
    1120: {
      H: 0.67,
      L: 0.65,
      O: 0.67,
      C: 0.66,
      V: 160000,
      p_w1_L: 0.495,
      p_w1_H: 0.67,
      p_m1_L: 0.495,
      p_m1_H: 0.69,
      p_m3_L: 0.495,
      p_m3_H: 0.7,
      p_m6_L: 0.495,
      p_m6_H: 0.78,
      p_m12_L: 0.495,
      p_m12_H: 0.86,
    },
    1121: {
      H: 10.06,
      L: 9.65,
      O: 9.88,
      C: 9.86,
      V: 648000,
      p_w1_L: 9.2,
      p_w1_H: 10.14,
      p_m1_L: 9,
      p_m1_H: 11.56,
      p_m3_L: 7.41,
      p_m3_H: 17.68,
      p_m6_L: 4.73,
      p_m6_H: 17.68,
      p_m12_L: 0.52,
      p_m12_H: 17.68,
    },
    1122: {
      H: 1.64,
      L: 1.6,
      O: 1.62,
      C: 1.62,
      V: 564000,
      p_w1_L: 1.55,
      p_w1_H: 1.72,
      p_m1_L: 1.54,
      p_m1_H: 1.76,
      p_m3_L: 1.54,
      p_m3_H: 1.9,
      p_m6_L: 1.54,
      p_m6_H: 2.1,
      p_m12_L: 1.4,
      p_m12_H: 2.1,
    },
    1123: {
      H: 0.158,
      L: 0.158,
      O: 0.158,
      C: 0.158,
      V: 0,
      p_w1_L: 0.128,
      p_w1_H: 0.159,
      p_m1_L: 0.128,
      p_m1_H: 0.159,
      p_m3_L: 0.128,
      p_m3_H: 0.161,
      p_m6_L: 0.128,
      p_m6_H: 0.194,
      p_m12_L: 0.123,
      p_m12_H: 0.194,
    },
    1124: {
      H: 0.048,
      L: 0.048,
      O: 0.048,
      C: 0.048,
      V: 4000,
      p_w1_L: 0.043,
      p_w1_H: 0.048,
      p_m1_L: 0.042,
      p_m1_H: 0.062,
      p_m3_L: 0.042,
      p_m3_H: 0.065,
      p_m6_L: 0.042,
      p_m6_H: 0.069,
      p_m12_L: 0.042,
      p_m12_H: 0.088,
    },
    1125: {
      H: 5.3,
      L: 5.3,
      O: 5.3,
      C: 5.3,
      V: 0,
      p_w1_L: 5.3,
      p_w1_H: 5.3,
      p_m1_L: 5.2,
      p_m1_H: 6,
      p_m3_L: 5.2,
      p_m3_H: 6.8,
      p_m6_L: 5.2,
      p_m6_H: 7.7,
      p_m12_L: 5.2,
      p_m12_H: 8,
    },
    1126: {
      H: 2.83,
      L: 2.81,
      O: 2.81,
      C: 2.83,
      V: 22000,
      p_w1_L: 2.8,
      p_w1_H: 2.87,
      p_m1_L: 2.79,
      p_m1_H: 3,
      p_m3_L: 2.64,
      p_m3_H: 3,
      p_m6_L: 2.64,
      p_m6_H: 3.15,
      p_m12_L: 2.64,
      p_m12_H: 3.52,
    },
    1127: {
      H: 0.81,
      L: 0.81,
      O: 0.81,
      C: 0.81,
      V: 20973,
      p_w1_L: 0.81,
      p_w1_H: 0.84,
      p_m1_L: 0.74,
      p_m1_H: 0.84,
      p_m3_L: 0.74,
      p_m3_H: 0.84,
      p_m6_L: 0.74,
      p_m6_H: 0.91,
      p_m12_L: 0.7,
      p_m12_H: 0.94,
    },
    1128: {
      H: 6.76,
      L: 6.47,
      O: 6.6,
      C: 6.64,
      V: 8353060,
      p_w1_L: 6.15,
      p_w1_H: 6.9,
      p_m1_L: 6.15,
      p_m1_H: 8.47,
      p_m3_L: 5.8,
      p_m3_H: 9.96,
      p_m6_L: 5.8,
      p_m6_H: 13.38,
      p_m12_L: 5.8,
      p_m12_H: 16.68,
    },
    1129: {
      H: 0.315,
      L: 0.31,
      O: 0.315,
      C: 0.31,
      V: 949200,
      p_w1_L: 0.285,
      p_w1_H: 0.33,
      p_m1_L: 0.25,
      p_m1_H: 0.33,
      p_m3_L: 0.236,
      p_m3_H: 0.33,
      p_m6_L: 0.236,
      p_m6_H: 0.38,
      p_m12_L: 0.231,
      p_m12_H: 0.38,
    },
    1130: {
      H: 0.068,
      L: 0.068,
      O: 0.068,
      C: 0.068,
      V: 0,
      p_w1_L: 0.068,
      p_w1_H: 0.068,
      p_m1_L: 0.061,
      p_m1_H: 0.07,
      p_m3_L: 0.057,
      p_m3_H: 0.076,
      p_m6_L: 0.057,
      p_m6_H: 0.084,
      p_m12_L: 0.052,
      p_m12_H: 0.119,
    },
    1131: {
      H: 0.083,
      L: 0.083,
      O: 0.083,
      C: 0.083,
      V: 0,
      p_w1_L: 0.083,
      p_w1_H: 0.083,
      p_m1_L: 0.083,
      p_m1_H: 0.083,
      p_m3_L: 0.083,
      p_m3_H: 0.083,
      p_m6_L: 0.083,
      p_m6_H: 0.083,
      p_m12_L: 0.083,
      p_m12_H: 0.083,
    },
    1132: {
      H: 0.11,
      L: 0.1,
      O: 0.105,
      C: 0.1,
      V: 1380000,
      p_w1_L: 0.097,
      p_w1_H: 0.116,
      p_m1_L: 0.097,
      p_m1_H: 0.121,
      p_m3_L: 0.097,
      p_m3_H: 0.123,
      p_m6_L: 0.097,
      p_m6_H: 0.156,
      p_m12_L: 0.097,
      p_m12_H: 0.24,
    },
    1133: {
      H: 3.81,
      L: 3.58,
      O: 3.58,
      C: 3.74,
      V: 7940400,
      p_w1_L: 3.49,
      p_w1_H: 4,
      p_m1_L: 2.94,
      p_m1_H: 4,
      p_m3_L: 2.73,
      p_m3_H: 4.08,
      p_m6_L: 1.86,
      p_m6_H: 4.08,
      p_m12_L: 1.86,
      p_m12_H: 4.08,
    },
    1134: {
      H: 1.41,
      L: 1.29,
      O: 1.41,
      C: 1.33,
      V: 380000,
      p_w1_L: 1.29,
      p_w1_H: 1.48,
      p_m1_L: 1.29,
      p_m1_H: 1.63,
      p_m3_L: 1.29,
      p_m3_H: 2.2,
      p_m6_L: 1.01,
      p_m6_H: 2.2,
      p_m12_L: 1.01,
      p_m12_H: 4,
    },
    1137: {
      H: 8.42,
      L: 8.09,
      O: 8.24,
      C: 8.11,
      V: 1774220,
      p_w1_L: 7.58,
      p_w1_H: 9.06,
      p_m1_L: 7.58,
      p_m1_H: 12.06,
      p_m3_L: 7.58,
      p_m3_H: 12.58,
      p_m6_L: 7.58,
      p_m6_H: 12.58,
      p_m12_L: 7.58,
      p_m12_H: 17.02,
    },
    1138: {
      H: 3,
      L: 2.93,
      O: 2.96,
      C: 2.95,
      V: 4282000,
      p_w1_L: 2.91,
      p_w1_H: 3.13,
      p_m1_L: 2.9,
      p_m1_H: 3.21,
      p_m3_L: 2.9,
      p_m3_H: 4.54,
      p_m6_L: 2.81,
      p_m6_H: 4.54,
      p_m12_L: 2.81,
      p_m12_H: 4.54,
    },
    1139: {
      H: 0.238,
      L: 0.238,
      O: 0.238,
      C: 0.238,
      V: 0,
      p_w1_L: 0.238,
      p_w1_H: 0.238,
      p_m1_L: 0.238,
      p_m1_H: 0.238,
      p_m3_L: 0.238,
      p_m3_H: 0.238,
      p_m6_L: 0.238,
      p_m6_H: 0.238,
      p_m12_L: 0.238,
      p_m12_H: 0.238,
    },
    1140: {
      H: 0.73,
      L: 0.71,
      O: 0.73,
      C: 0.72,
      V: 2108000,
      p_w1_L: 0.68,
      p_w1_H: 0.76,
      p_m1_L: 0.61,
      p_m1_H: 0.79,
      p_m3_L: 0.61,
      p_m3_H: 0.84,
      p_m6_L: 0.61,
      p_m6_H: 0.85,
      p_m12_L: 0.61,
      p_m12_H: 1.04,
    },
    1141: {
      H: 0.073,
      L: 0.071,
      O: 0.071,
      C: 0.073,
      V: 77720000,
      p_w1_L: 0.071,
      p_w1_H: 0.082,
      p_m1_L: 0.071,
      p_m1_H: 0.087,
      p_m3_L: 0.071,
      p_m3_H: 0.102,
      p_m6_L: 0.071,
      p_m6_H: 0.115,
      p_m12_L: 0.071,
      p_m12_H: 0.203,
    },
    1142: {
      H: 1.04,
      L: 1.04,
      O: 1.04,
      C: 1.04,
      V: 0,
      p_w1_L: 1.04,
      p_w1_H: 1.04,
      p_m1_L: 1.01,
      p_m1_H: 1.3,
      p_m3_L: 1,
      p_m3_H: 1.48,
      p_m6_L: 0.88,
      p_m6_H: 1.97,
      p_m12_L: 0.45,
      p_m12_H: 3,
    },
    1143: {
      H: 0.193,
      L: 0.176,
      O: 0.186,
      C: 0.183,
      V: 38398000,
      p_w1_L: 0.176,
      p_w1_H: 0.205,
      p_m1_L: 0.176,
      p_m1_H: 0.28,
      p_m3_L: 0.176,
      p_m3_H: 0.28,
      p_m6_L: 0.176,
      p_m6_H: 0.445,
      p_m12_L: 0.176,
      p_m12_H: 1.97,
    },
    1145: {
      H: 0.3,
      L: 0.295,
      O: 0.295,
      C: 0.295,
      V: 10845000,
      p_w1_L: 0.224,
      p_w1_H: 0.3,
      p_m1_L: 0.224,
      p_m1_H: 0.325,
      p_m3_L: 0.181,
      p_m3_H: 0.325,
      p_m6_L: 0.181,
      p_m6_H: 0.38,
      p_m12_L: 0.136,
      p_m12_H: 0.54,
    },
    1146: {
      H: 0.2,
      L: 0.2,
      O: 0.2,
      C: 0.2,
      V: 4000,
      p_w1_L: 0.197,
      p_w1_H: 0.201,
      p_m1_L: 0.151,
      p_m1_H: 0.33,
      p_m3_L: 0.138,
      p_m3_H: 0.33,
      p_m6_L: 0.13,
      p_m6_H: 0.33,
      p_m12_L: 0.095,
      p_m12_H: 0.33,
    },
    1147: {
      H: 0.11,
      L: 0.107,
      O: 0.11,
      C: 0.109,
      V: 1840000,
      p_w1_L: 0.107,
      p_w1_H: 0.113,
      p_m1_L: 0.107,
      p_m1_H: 0.118,
      p_m3_L: 0.1,
      p_m3_H: 0.126,
      p_m6_L: 0.1,
      p_m6_H: 0.148,
      p_m12_L: 0.078,
      p_m12_H: 0.182,
    },
    1148: {
      H: 0.93,
      L: 0.89,
      O: 0.91,
      C: 0.92,
      V: 1146500,
      p_w1_L: 0.87,
      p_w1_H: 1.02,
      p_m1_L: 0.87,
      p_m1_H: 1.08,
      p_m3_L: 0.82,
      p_m3_H: 1.45,
      p_m6_L: 0.39,
      p_m6_H: 1.45,
      p_m12_L: 0.32,
      p_m12_H: 1.45,
    },
    1150: {
      H: 0.26,
      L: 0.26,
      O: 0.26,
      C: 0.26,
      V: 47000,
      p_w1_L: 0.26,
      p_w1_H: 0.295,
      p_m1_L: 0.26,
      p_m1_H: 0.295,
      p_m3_L: 0.26,
      p_m3_H: 0.38,
      p_m6_L: 0.228,
      p_m6_H: 0.42,
      p_m12_L: 0.135,
      p_m12_H: 0.42,
    },
    1152: {
      H: 0.068,
      L: 0.068,
      O: 0.068,
      C: 0.068,
      V: 0,
      p_w1_L: 0.068,
      p_w1_H: 0.068,
      p_m1_L: 0.068,
      p_m1_H: 0.068,
      p_m3_L: 0.045,
      p_m3_H: 0.072,
      p_m6_L: 0.033,
      p_m6_H: 0.072,
      p_m12_L: 0.033,
      p_m12_H: 0.09,
    },
    1153: {
      H: 3.84,
      L: 3.75,
      O: 3.84,
      C: 3.8,
      V: 3562000,
      p_w1_L: 3.72,
      p_w1_H: 3.94,
      p_m1_L: 3.72,
      p_m1_H: 4.15,
      p_m3_L: 3.72,
      p_m3_H: 4.3,
      p_m6_L: 3.72,
      p_m6_H: 4.3,
      p_m12_L: 3.7,
      p_m12_H: 4.3,
    },
    1156: {
      H: 0.45,
      L: 0.45,
      O: 0.45,
      C: 0.45,
      V: 0,
      p_w1_L: 0.45,
      p_w1_H: 0.45,
      p_m1_L: 0.45,
      p_m1_H: 0.45,
      p_m3_L: 0.45,
      p_m3_H: 0.45,
      p_m6_L: 0.45,
      p_m6_H: 0.45,
      p_m12_L: 0.275,
      p_m12_H: 0.68,
    },
    1157: {
      H: 5.4,
      L: 5.25,
      O: 5.38,
      C: 5.3,
      V: 10413500,
      p_w1_L: 4.86,
      p_w1_H: 5.4,
      p_m1_L: 4.86,
      p_m1_H: 5.59,
      p_m3_L: 4.86,
      p_m3_H: 8.56,
      p_m6_L: 4.86,
      p_m6_H: 8.93,
      p_m12_L: 4.86,
      p_m12_H: 13.98,
    },
    1159: {
      H: 0.85,
      L: 0.85,
      O: 0.85,
      C: 0.85,
      V: 0,
      p_w1_L: 0.8,
      p_w1_H: 0.98,
      p_m1_L: 0.8,
      p_m1_H: 1.2,
      p_m3_L: 0.8,
      p_m3_H: 1.3,
      p_m6_L: 0.8,
      p_m6_H: 1.73,
      p_m12_L: 0.8,
      p_m12_H: 1.98,
    },
    1160: {
      H: 1.7,
      L: 1.7,
      O: 1.7,
      C: 1.7,
      V: 0,
      p_w1_L: 1.7,
      p_w1_H: 1.7,
      p_m1_L: 1.7,
      p_m1_H: 1.7,
      p_m3_L: 1.7,
      p_m3_H: 1.7,
      p_m6_L: 0.72,
      p_m6_H: 1.7,
      p_m12_L: 0.72,
      p_m12_H: 1.7,
    },
    1161: {
      H: 1.3,
      L: 1.27,
      O: 1.28,
      C: 1.3,
      V: 152000,
      p_w1_L: 1.25,
      p_w1_H: 1.33,
      p_m1_L: 1.25,
      p_m1_H: 1.4,
      p_m3_L: 1.07,
      p_m3_H: 1.48,
      p_m6_L: 1.05,
      p_m6_H: 1.61,
      p_m12_L: 0.72,
      p_m12_H: 1.81,
    },
    1162: {
      H: 0.35,
      L: 0.239,
      O: 0.4,
      C: 0.26,
      V: 3624000,
      p_w1_L: 0.197,
      p_w1_H: 0.435,
      p_m1_L: 0.188,
      p_m1_H: 0.435,
      p_m3_L: 0.188,
      p_m3_H: 0.435,
      p_m6_L: 0.172,
      p_m6_H: 0.435,
      p_m12_L: 0.11,
      p_m12_H: 0.435,
    },
    1163: {
      H: 0.69,
      L: 0.66,
      O: 0.67,
      C: 0.66,
      V: 182500,
      p_w1_L: 0.66,
      p_w1_H: 0.7,
      p_m1_L: 0.6,
      p_m1_H: 0.85,
      p_m3_L: 0.55,
      p_m3_H: 0.85,
      p_m6_L: 0.55,
      p_m6_H: 1.09,
      p_m12_L: 0.55,
      p_m12_H: 1.29,
    },
    1164: {
      H: 0.91,
      L: 0.87,
      O: 0.88,
      C: 0.9,
      V: 9550000,
      p_w1_L: 0.8,
      p_w1_H: 0.91,
      p_m1_L: 0.8,
      p_m1_H: 1.11,
      p_m3_L: 0.8,
      p_m3_H: 1.53,
      p_m6_L: 0.53,
      p_m6_H: 1.53,
      p_m12_L: 0.265,
      p_m12_H: 1.53,
    },
    1165: {
      H: 0.084,
      L: 0.078,
      O: 0.08,
      C: 0.084,
      V: 2286000,
      p_w1_L: 0.076,
      p_w1_H: 0.085,
      p_m1_L: 0.076,
      p_m1_H: 0.096,
      p_m3_L: 0.076,
      p_m3_H: 0.165,
      p_m6_L: 0.076,
      p_m6_H: 0.19,
      p_m12_L: 0.076,
      p_m12_H: 0.325,
    },
    1166: {
      H: 0.068,
      L: 0.068,
      O: 0.068,
      C: 0.068,
      V: 20787,
      p_w1_L: 0.066,
      p_w1_H: 0.076,
      p_m1_L: 0.066,
      p_m1_H: 0.1,
      p_m3_L: 0.065,
      p_m3_H: 0.148,
      p_m6_L: 0.06,
      p_m6_H: 0.148,
      p_m12_L: 0.05,
      p_m12_H: 0.148,
    },
    1167: {
      H: 15.38,
      L: 14.86,
      O: 15.08,
      C: 15.34,
      V: 197100,
      p_w1_L: 13.72,
      p_w1_H: 16.12,
      p_m1_L: 13.72,
      p_m1_H: 17.98,
      p_m3_L: 13.72,
      p_m3_H: 22.85,
      p_m6_L: 13.72,
      p_m6_H: 23.7,
      p_m12_L: 13,
      p_m12_H: 25.35,
    },
    1168: {
      H: 0.248,
      L: 0.241,
      O: 0.248,
      C: 0.245,
      V: 26000,
      p_w1_L: 0.235,
      p_w1_H: 0.249,
      p_m1_L: 0.235,
      p_m1_H: 0.255,
      p_m3_L: 0.235,
      p_m3_H: 0.29,
      p_m6_L: 0.235,
      p_m6_H: 0.36,
      p_m12_L: 0.235,
      p_m12_H: 0.58,
    },
    1170: {
      H: 0.97,
      L: 0.94,
      O: 0.97,
      C: 0.96,
      V: 1014000,
      p_w1_L: 0.9,
      p_w1_H: 0.97,
      p_m1_L: 0.82,
      p_m1_H: 0.97,
      p_m3_L: 0.81,
      p_m3_H: 0.97,
      p_m6_L: 0.81,
      p_m6_H: 0.97,
      p_m12_L: 0.72,
      p_m12_H: 0.99,
    },
    1171: {
      H: 14.08,
      L: 13.6,
      O: 13.76,
      C: 13.72,
      V: 18459100,
      p_w1_L: 11.94,
      p_w1_H: 15,
      p_m1_L: 11.14,
      p_m1_H: 15,
      p_m3_L: 10.96,
      p_m3_H: 17.92,
      p_m6_L: 9.16,
      p_m6_H: 17.92,
      p_m12_L: 5.91,
      p_m12_H: 17.92,
    },
    1172: {
      H: 0.062,
      L: 0.062,
      O: 0.062,
      C: 0.062,
      V: 200000,
      p_w1_L: 0.062,
      p_w1_H: 0.066,
      p_m1_L: 0.062,
      p_m1_H: 0.075,
      p_m3_L: 0.062,
      p_m3_H: 0.077,
      p_m6_L: 0.062,
      p_m6_H: 0.093,
      p_m12_L: 0.062,
      p_m12_H: 0.11,
    },
    1173: {
      H: 0.057,
      L: 0.05,
      O: 0.05,
      C: 0.057,
      V: 360000,
      p_w1_L: 0.047,
      p_w1_H: 0.057,
      p_m1_L: 0.047,
      p_m1_H: 0.057,
      p_m3_L: 0.047,
      p_m3_H: 0.059,
      p_m6_L: 0.041,
      p_m6_H: 0.1,
      p_m12_L: 0.041,
      p_m12_H: 0.1,
    },
    1175: {
      H: 0.059,
      L: 0.059,
      O: 0.059,
      C: 0.059,
      V: 0,
      p_w1_L: 0.059,
      p_w1_H: 0.059,
      p_m1_L: 0.059,
      p_m1_H: 0.059,
      p_m3_L: 0.059,
      p_m3_H: 0.059,
      p_m6_L: 0.05,
      p_m6_H: 0.079,
      p_m12_L: 0.048,
      p_m12_H: 0.1,
    },
    1176: {
      H: 1.6,
      L: 1.56,
      O: 1.59,
      C: 1.56,
      V: 3634000,
      p_w1_L: 1.53,
      p_w1_H: 1.62,
      p_m1_L: 1.53,
      p_m1_H: 1.74,
      p_m3_L: 1.53,
      p_m3_H: 1.91,
      p_m6_L: 1.53,
      p_m6_H: 2.11,
      p_m12_L: 1,
      p_m12_H: 2.73,
    },
    1177: {
      H: 5.64,
      L: 5.4,
      O: 5.49,
      C: 5.53,
      V: 32936300,
      p_w1_L: 5.33,
      p_w1_H: 5.8,
      p_m1_L: 5.28,
      p_m1_H: 5.93,
      p_m3_L: 5.28,
      p_m3_H: 6.87,
      p_m6_L: 5.28,
      p_m6_H: 8.78,
      p_m12_L: 5.28,
      p_m12_H: 9.69,
    },
    1178: {
      H: 0.119,
      L: 0.119,
      O: 0.119,
      C: 0.119,
      V: 0,
      p_w1_L: 0.119,
      p_w1_H: 0.119,
      p_m1_L: 0.119,
      p_m1_H: 0.119,
      p_m3_L: 0.119,
      p_m3_H: 0.119,
      p_m6_L: 0.119,
      p_m6_H: 0.119,
      p_m12_L: 0.119,
      p_m12_H: 0.119,
    },
    1179: {
      H: 31.7,
      L: 31.2,
      O: 31.55,
      C: 31.4,
      V: 193120,
      p_w1_L: 29.35,
      p_w1_H: 32.4,
      p_m1_L: 29.35,
      p_m1_H: 38,
      p_m3_L: 29.35,
      p_m3_H: 40.5,
      p_m6_L: 24.4,
      p_m6_H: 45.82,
      p_m12_L: 24.4,
      p_m12_H: 49.3,
    },
    1180: {
      H: 1.01,
      L: 0.97,
      O: 0.97,
      C: 1.01,
      V: 20000,
      p_w1_L: 0.97,
      p_w1_H: 1.04,
      p_m1_L: 0.88,
      p_m1_H: 1.07,
      p_m3_L: 0.87,
      p_m3_H: 1.07,
      p_m6_L: 0.87,
      p_m6_H: 1.22,
      p_m12_L: 0.77,
      p_m12_H: 1.62,
    },
    1181: {
      H: 0.7,
      L: 0.68,
      O: 0.68,
      C: 0.68,
      V: 974000,
      p_w1_L: 0.65,
      p_w1_H: 0.77,
      p_m1_L: 0.65,
      p_m1_H: 0.79,
      p_m3_L: 0.65,
      p_m3_H: 0.85,
      p_m6_L: 0.65,
      p_m6_H: 0.94,
      p_m12_L: 0.65,
      p_m12_H: 1.14,
    },
    1182: {
      H: 0.4,
      L: 0.4,
      O: 0.4,
      C: 0.4,
      V: 0,
      p_w1_L: 0.39,
      p_w1_H: 0.41,
      p_m1_L: 0.39,
      p_m1_H: 0.49,
      p_m3_L: 0.39,
      p_m3_H: 0.64,
      p_m6_L: 0.39,
      p_m6_H: 1,
      p_m12_L: 0.094,
      p_m12_H: 2.84,
    },
    1183: {
      H: 3.59,
      L: 3.44,
      O: 3.44,
      C: 3.53,
      V: 3095000,
      p_w1_L: 3.32,
      p_w1_H: 3.59,
      p_m1_L: 3.32,
      p_m1_H: 3.77,
      p_m3_L: 3.27,
      p_m3_H: 4.06,
      p_m6_L: 3.2,
      p_m6_H: 4.4,
      p_m12_L: 1.747,
      p_m12_H: 5.28,
    },
    1184: {
      H: 4.88,
      L: 4.88,
      O: 4.88,
      C: 4.88,
      V: 6000,
      p_w1_L: 4.48,
      p_w1_H: 4.88,
      p_m1_L: 4.1,
      p_m1_H: 4.88,
      p_m3_L: 4.05,
      p_m3_H: 4.88,
      p_m6_L: 2.96,
      p_m6_H: 4.88,
      p_m12_L: 2.55,
      p_m12_H: 4.88,
    },
    1185: {
      H: 0.153,
      L: 0.153,
      O: 0.153,
      C: 0.153,
      V: 0,
      p_w1_L: 0.153,
      p_w1_H: 0.153,
      p_m1_L: 0.153,
      p_m1_H: 0.153,
      p_m3_L: 0.153,
      p_m3_H: 0.153,
      p_m6_L: 0.153,
      p_m6_H: 0.153,
      p_m12_L: 0.073,
      p_m12_H: 0.173,
    },
    1186: {
      H: 5.18,
      L: 5.02,
      O: 5.18,
      C: 5.11,
      V: 8654580,
      p_w1_L: 4.77,
      p_w1_H: 5.18,
      p_m1_L: 4.76,
      p_m1_H: 5.18,
      p_m3_L: 4.76,
      p_m3_H: 5.99,
      p_m6_L: 4.57,
      p_m6_H: 5.99,
      p_m12_L: 4.07,
      p_m12_H: 5.99,
    },
    1188: {
      H: 0.04,
      L: 0.04,
      O: 0.04,
      C: 0.04,
      V: 0,
      p_w1_L: 0.04,
      p_w1_H: 0.04,
      p_m1_L: 0.04,
      p_m1_H: 0.04,
      p_m3_L: 0.04,
      p_m3_H: 0.04,
      p_m6_L: 0.04,
      p_m6_H: 0.04,
      p_m12_L: 0.02,
      p_m12_H: 0.088,
    },
    1189: {
      H: 0.171,
      L: 0.17,
      O: 0.171,
      C: 0.17,
      V: 71000,
      p_w1_L: 0.166,
      p_w1_H: 0.181,
      p_m1_L: 0.166,
      p_m1_H: 0.199,
      p_m3_L: 0.156,
      p_m3_H: 0.199,
      p_m6_L: 0.156,
      p_m6_H: 0.2,
      p_m12_L: 0.156,
      p_m12_H: 0.225,
    },
    1191: {
      H: 0.021,
      L: 0.021,
      O: 0.021,
      C: 0.021,
      V: 0,
      p_w1_L: 0.021,
      p_w1_H: 0.021,
      p_m1_L: 0.021,
      p_m1_H: 0.021,
      p_m3_L: 0.021,
      p_m3_H: 0.021,
      p_m6_L: 0.019,
      p_m6_H: 0.06,
      p_m12_L: 0.01,
      p_m12_H: 0.075,
    },
    1192: {
      H: 0.315,
      L: 0.315,
      O: 0.315,
      C: 0.315,
      V: 0,
      p_w1_L: 0.315,
      p_w1_H: 0.315,
      p_m1_L: 0.315,
      p_m1_H: 0.315,
      p_m3_L: 0.315,
      p_m3_H: 0.315,
      p_m6_L: 0.315,
      p_m6_H: 0.315,
      p_m12_L: 0.073,
      p_m12_H: 0.355,
    },
    1193: {
      H: 40.65,
      L: 39.5,
      O: 40.15,
      C: 40.45,
      V: 1135380,
      p_w1_L: 38.3,
      p_w1_H: 42.4,
      p_m1_L: 36.85,
      p_m1_H: 43.05,
      p_m3_L: 36.85,
      p_m3_H: 50.65,
      p_m6_L: 36.85,
      p_m6_H: 52.25,
      p_m12_L: 36.85,
      p_m12_H: 52.25,
    },
    1194: {
      H: 0.35,
      L: 0.35,
      O: 0.35,
      C: 0.35,
      V: 0,
      p_w1_L: 0.35,
      p_w1_H: 0.37,
      p_m1_L: 0.35,
      p_m1_H: 0.445,
      p_m3_L: 0.345,
      p_m3_H: 0.455,
      p_m6_L: 0.345,
      p_m6_H: 0.71,
      p_m12_L: 0.345,
      p_m12_H: 2.29,
    },
    1195: {
      H: 0.047,
      L: 0.046,
      O: 0.046,
      C: 0.047,
      V: 124000,
      p_w1_L: 0.041,
      p_w1_H: 0.054,
      p_m1_L: 0.04,
      p_m1_H: 0.055,
      p_m3_L: 0.036,
      p_m3_H: 0.066,
      p_m6_L: 0.036,
      p_m6_H: 0.07,
      p_m12_L: 0.036,
      p_m12_H: 0.07,
    },
    1196: {
      H: 10.04,
      L: 9.85,
      O: 9.99,
      C: 9.85,
      V: 812000,
      p_w1_L: 9.85,
      p_w1_H: 11.36,
      p_m1_L: 9.82,
      p_m1_H: 11.6,
      p_m3_L: 9.65,
      p_m3_H: 14,
      p_m6_L: 9.65,
      p_m6_H: 14,
      p_m12_L: 4.43,
      p_m12_H: 14,
    },
    1198: {
      H: 2.34,
      L: 2.31,
      O: 2.31,
      C: 2.34,
      V: 48000,
      p_w1_L: 2.19,
      p_w1_H: 2.38,
      p_m1_L: 2.16,
      p_m1_H: 2.42,
      p_m3_L: 2,
      p_m3_H: 2.42,
      p_m6_L: 1.78,
      p_m6_H: 2.46,
      p_m12_L: 1.17,
      p_m12_H: 2.46,
    },
    1199: {
      H: 6.85,
      L: 6.65,
      O: 6.65,
      C: 6.75,
      V: 5581260,
      p_w1_L: 6.04,
      p_w1_H: 7.12,
      p_m1_L: 6.03,
      p_m1_H: 7.12,
      p_m3_L: 6.01,
      p_m3_H: 7.15,
      p_m6_L: 5.37,
      p_m6_H: 7.15,
      p_m12_L: 5.06,
      p_m12_H: 7.42,
    },
    1200: {
      H: 1.09,
      L: 1.06,
      O: 1.09,
      C: 1.07,
      V: 244000,
      p_w1_L: 1.04,
      p_w1_H: 1.1,
      p_m1_L: 1.04,
      p_m1_H: 1.25,
      p_m3_L: 1.04,
      p_m3_H: 1.6,
      p_m6_L: 1.04,
      p_m6_H: 1.75,
      p_m12_L: 0.69,
      p_m12_H: 1.75,
    },
    1201: {
      H: 0.51,
      L: 0.43,
      O: 0.44,
      C: 0.48,
      V: 2286000,
      p_w1_L: 0.42,
      p_w1_H: 0.51,
      p_m1_L: 0.42,
      p_m1_H: 0.53,
      p_m3_L: 0.405,
      p_m3_H: 0.6,
      p_m6_L: 0.405,
      p_m6_H: 0.74,
      p_m12_L: 0.305,
      p_m12_H: 0.74,
    },
    1202: {
      H: 1.25,
      L: 1.2,
      O: 1.2,
      C: 1.22,
      V: 868000,
      p_w1_L: 1.12,
      p_w1_H: 1.27,
      p_m1_L: 0.99,
      p_m1_H: 1.28,
      p_m3_L: 0.99,
      p_m3_H: 1.28,
      p_m6_L: 0.88,
      p_m6_H: 1.35,
      p_m12_L: 0.49,
      p_m12_H: 1.45,
    },
    1203: {
      H: 0.68,
      L: 0.66,
      O: 0.66,
      C: 0.68,
      V: 262000,
      p_w1_L: 0.65,
      p_w1_H: 0.68,
      p_m1_L: 0.65,
      p_m1_H: 0.68,
      p_m3_L: 0.65,
      p_m3_H: 0.78,
      p_m6_L: 0.65,
      p_m6_H: 0.78,
      p_m12_L: 0.62,
      p_m12_H: 0.8,
    },
    1205: {
      H: 0.47,
      L: 0.46,
      O: 0.47,
      C: 0.47,
      V: 3226000,
      p_w1_L: 0.44,
      p_w1_H: 0.475,
      p_m1_L: 0.44,
      p_m1_H: 0.56,
      p_m3_L: 0.44,
      p_m3_H: 0.73,
      p_m6_L: 0.355,
      p_m6_H: 0.73,
      p_m12_L: 0.28,
      p_m12_H: 0.73,
    },
    1206: {
      H: 0.83,
      L: 0.73,
      O: 0.77,
      C: 0.75,
      V: 2294000,
      p_w1_L: 0.73,
      p_w1_H: 0.87,
      p_m1_L: 0.64,
      p_m1_H: 1.55,
      p_m3_L: 0.63,
      p_m3_H: 1.55,
      p_m6_L: 0.58,
      p_m6_H: 1.55,
      p_m12_L: 0.58,
      p_m12_H: 1.55,
    },
    1207: {
      H: 0.036,
      L: 0.035,
      O: 0.037,
      C: 0.036,
      V: 1344940,
      p_w1_L: 0.035,
      p_w1_H: 0.039,
      p_m1_L: 0.035,
      p_m1_H: 0.042,
      p_m3_L: 0.035,
      p_m3_H: 0.048,
      p_m6_L: 0.031,
      p_m6_H: 0.056,
      p_m12_L: 0.031,
      p_m12_H: 0.065,
    },
    1208: {
      H: 2.86,
      L: 2.75,
      O: 2.8,
      C: 2.8,
      V: 15560000,
      p_w1_L: 2.6,
      p_w1_H: 2.9,
      p_m1_L: 2.6,
      p_m1_H: 3.32,
      p_m3_L: 2.6,
      p_m3_H: 4.32,
      p_m6_L: 2.6,
      p_m6_H: 4.32,
      p_m12_L: 2.6,
      p_m12_H: 6.5,
    },
    1209: {
      H: 41.4,
      L: 40.1,
      O: 40.2,
      C: 40.8,
      V: 2756020,
      p_w1_L: 37.8,
      p_w1_H: 41.4,
      p_m1_L: 37.65,
      p_m1_H: 42.6,
      p_m3_L: 34.25,
      p_m3_H: 45.9,
      p_m6_L: 34.25,
      p_m6_H: 55.25,
      p_m12_L: 27.25,
      p_m12_H: 55.25,
    },
    1210: {
      H: 0.178,
      L: 0.178,
      O: 0.178,
      C: 0.178,
      V: 0,
      p_w1_L: 0.178,
      p_w1_H: 0.179,
      p_m1_L: 0.135,
      p_m1_H: 0.19,
      p_m3_L: 0.125,
      p_m3_H: 0.19,
      p_m6_L: 0.125,
      p_m6_H: 0.23,
      p_m12_L: 0.125,
      p_m12_H: 0.248,
    },
    1211: {
      H: 296,
      L: 291,
      O: 296,
      C: 291.8,
      V: 3816660,
      p_w1_L: 282.4,
      p_w1_H: 311.8,
      p_m1_L: 282.4,
      p_m1_H: 319.2,
      p_m3_L: 232.6,
      p_m3_H: 324.6,
      p_m6_L: 191.4,
      p_m6_H: 324.6,
      p_m12_L: 138.4,
      p_m12_H: 324.6,
    },
    1212: {
      H: 4.35,
      L: 4.23,
      O: 4.26,
      C: 4.23,
      V: 535500,
      p_w1_L: 4.08,
      p_w1_H: 4.38,
      p_m1_L: 4.08,
      p_m1_H: 5.14,
      p_m3_L: 4.04,
      p_m3_H: 5.55,
      p_m6_L: 4.04,
      p_m6_H: 7.19,
      p_m12_L: 4.04,
      p_m12_H: 7.46,
    },
    1213: {
      H: 0.485,
      L: 0.485,
      O: 0.485,
      C: 0.485,
      V: 0,
      p_w1_L: 0.485,
      p_w1_H: 0.485,
      p_m1_L: 0.455,
      p_m1_H: 0.49,
      p_m3_L: 0.415,
      p_m3_H: 0.77,
      p_m6_L: 0.41,
      p_m6_H: 0.77,
      p_m12_L: 0.4,
      p_m12_H: 0.77,
    },
    1215: {
      H: 0.033,
      L: 0.033,
      O: 0.033,
      C: 0.033,
      V: 0,
      p_w1_L: 0.033,
      p_w1_H: 0.033,
      p_m1_L: 0.032,
      p_m1_H: 0.041,
      p_m3_L: 0.032,
      p_m3_H: 0.046,
      p_m6_L: 0.031,
      p_m6_H: 0.046,
      p_m12_L: 0.031,
      p_m12_H: 0.054,
    },
    1216: {
      H: 0.98,
      L: 0.97,
      O: 0.97,
      C: 0.97,
      V: 32000,
      p_w1_L: 0.95,
      p_w1_H: 1,
      p_m1_L: 0.94,
      p_m1_H: 1.01,
      p_m3_L: 0.91,
      p_m3_H: 1.15,
      p_m6_L: 0.91,
      p_m6_H: 1.15,
      p_m12_L: 0.91,
      p_m12_H: 1.15,
    },
    1217: {
      H: 0.01,
      L: 0.01,
      O: 0.01,
      C: 0.01,
      V: 80000,
      p_w1_L: 0.01,
      p_w1_H: 0.01,
      p_m1_L: 0.01,
      p_m1_H: 0.01,
      p_m3_L: 0.01,
      p_m3_H: 0.01,
      p_m6_L: 0.01,
      p_m6_H: 0.015,
      p_m12_L: 0.01,
      p_m12_H: 0.017,
    },
    1218: {
      H: 4.7,
      L: 4.7,
      O: 4.7,
      C: 4.7,
      V: 10,
      p_w1_L: 4.69,
      p_w1_H: 4.78,
      p_m1_L: 4,
      p_m1_H: 5.21,
      p_m3_L: 3.7,
      p_m3_H: 5.21,
      p_m6_L: 3.7,
      p_m6_H: 5.21,
      p_m12_L: 3.3,
      p_m12_H: 5.21,
    },
    1220: {
      H: 0.088,
      L: 0.08,
      O: 0.085,
      C: 0.085,
      V: 3900000,
      p_w1_L: 0.07,
      p_w1_H: 0.092,
      p_m1_L: 0.063,
      p_m1_H: 0.092,
      p_m3_L: 0.062,
      p_m3_H: 0.092,
      p_m6_L: 0.062,
      p_m6_H: 0.117,
      p_m12_L: 0.062,
      p_m12_H: 0.245,
    },
    1221: {
      H: 2.22,
      L: 2.13,
      O: 2.13,
      C: 2.22,
      V: 14000,
      p_w1_L: 2.13,
      p_w1_H: 2.22,
      p_m1_L: 2.1,
      p_m1_H: 2.22,
      p_m3_L: 2.1,
      p_m3_H: 2.4,
      p_m6_L: 2.1,
      p_m6_H: 2.5,
      p_m12_L: 2.1,
      p_m12_H: 2.79,
    },
    1222: {
      H: 0.071,
      L: 0.069,
      O: 0.071,
      C: 0.071,
      V: 6357740,
      p_w1_L: 0.069,
      p_w1_H: 0.073,
      p_m1_L: 0.069,
      p_m1_H: 0.079,
      p_m3_L: 0.058,
      p_m3_H: 0.079,
      p_m6_L: 0.058,
      p_m6_H: 0.079,
      p_m12_L: 0.053,
      p_m12_H: 0.079,
    },
    1223: {
      H: 0.92,
      L: 0.9,
      O: 0.91,
      C: 0.92,
      V: 3670000,
      p_w1_L: 0.86,
      p_w1_H: 1.03,
      p_m1_L: 0.86,
      p_m1_H: 1.03,
      p_m3_L: 0.86,
      p_m3_H: 1.03,
      p_m6_L: 0.83,
      p_m6_H: 1.03,
      p_m12_L: 0.8,
      p_m12_H: 1.03,
    },
    1224: {
      H: 1.76,
      L: 1.76,
      O: 1.76,
      C: 1.76,
      V: 139000,
      p_w1_L: 1.74,
      p_w1_H: 1.76,
      p_m1_L: 1.74,
      p_m1_H: 1.85,
      p_m3_L: 1.68,
      p_m3_H: 1.87,
      p_m6_L: 1.68,
      p_m6_H: 2,
      p_m12_L: 1.65,
      p_m12_H: 2.55,
    },
    1225: {
      H: 0.028,
      L: 0.028,
      O: 0.028,
      C: 0.028,
      V: 240000,
      p_w1_L: 0.027,
      p_w1_H: 0.03,
      p_m1_L: 0.027,
      p_m1_H: 0.035,
      p_m3_L: 0.027,
      p_m3_H: 0.047,
      p_m6_L: 0.027,
      p_m6_H: 0.217,
      p_m12_L: 0.027,
      p_m12_H: 0.217,
    },
    1226: {
      H: 0.97,
      L: 0.97,
      O: 0.97,
      C: 0.97,
      V: 6800,
      p_w1_L: 0.94,
      p_w1_H: 1.04,
      p_m1_L: 0.9,
      p_m1_H: 1.04,
      p_m3_L: 0.465,
      p_m3_H: 1.11,
      p_m6_L: 0.35,
      p_m6_H: 1.11,
      p_m12_L: 0.16,
      p_m12_H: 1.11,
    },
    1227: {
      H: 0.023,
      L: 0.023,
      O: 0.023,
      C: 0.023,
      V: 0,
      p_w1_L: 0.023,
      p_w1_H: 0.023,
      p_m1_L: 0.023,
      p_m1_H: 0.023,
      p_m3_L: 0.023,
      p_m3_H: 0.023,
      p_m6_L: 0.023,
      p_m6_H: 0.023,
      p_m12_L: 0.018,
      p_m12_H: 0.076,
    },
    1229: {
      H: 0.083,
      L: 0.083,
      O: 0.083,
      C: 0.083,
      V: 0,
      p_w1_L: 0.078,
      p_w1_H: 0.104,
      p_m1_L: 0.078,
      p_m1_H: 0.134,
      p_m3_L: 0.078,
      p_m3_H: 0.22,
      p_m6_L: 0.078,
      p_m6_H: 0.22,
      p_m12_L: 0.074,
      p_m12_H: 0.22,
    },
    1230: {
      H: 0.57,
      L: 0.51,
      O: 0.53,
      C: 0.53,
      V: 994000,
      p_w1_L: 0.51,
      p_w1_H: 0.57,
      p_m1_L: 0.51,
      p_m1_H: 0.6,
      p_m3_L: 0.51,
      p_m3_H: 0.62,
      p_m6_L: 0.51,
      p_m6_H: 0.79,
      p_m12_L: 0.49,
      p_m12_H: 0.91,
    },
    1231: {
      H: 0.84,
      L: 0.84,
      O: 0.84,
      C: 0.84,
      V: 0,
      p_w1_L: 0.78,
      p_w1_H: 0.85,
      p_m1_L: 0.78,
      p_m1_H: 0.88,
      p_m3_L: 0.78,
      p_m3_H: 0.9,
      p_m6_L: 0.6,
      p_m6_H: 0.95,
      p_m12_L: 0.6,
      p_m12_H: 0.95,
    },
    1232: {
      H: 0.175,
      L: 0.175,
      O: 0.175,
      C: 0.175,
      V: 6000,
      p_w1_L: 0.175,
      p_w1_H: 0.194,
      p_m1_L: 0.175,
      p_m1_H: 0.224,
      p_m3_L: 0.175,
      p_m3_H: 0.3,
      p_m6_L: 0.175,
      p_m6_H: 0.54,
      p_m12_L: 0.175,
      p_m12_H: 0.62,
    },
    1233: {
      H: 4.74,
      L: 4.45,
      O: 4.74,
      C: 4.52,
      V: 3539020,
      p_w1_L: 4.12,
      p_w1_H: 4.75,
      p_m1_L: 4.12,
      p_m1_H: 5.35,
      p_m3_L: 4.12,
      p_m3_H: 7.49,
      p_m6_L: 4.12,
      p_m6_H: 10.58,
      p_m12_L: 4.12,
      p_m12_H: 12.54,
    },
    1234: {
      H: 4.27,
      L: 4.22,
      O: 4.22,
      C: 4.23,
      V: 36000,
      p_w1_L: 4.2,
      p_w1_H: 4.3,
      p_m1_L: 4.2,
      p_m1_H: 4.5,
      p_m3_L: 4.2,
      p_m3_H: 5.1,
      p_m6_L: 4.2,
      p_m6_H: 6.75,
      p_m12_L: 4.2,
      p_m12_H: 6.75,
    },
    1235: {
      H: 0.24,
      L: 0.218,
      O: 0.226,
      C: 0.225,
      V: 855000,
      p_w1_L: 0.216,
      p_w1_H: 0.249,
      p_m1_L: 0.216,
      p_m1_H: 0.249,
      p_m3_L: 0.191,
      p_m3_H: 0.25,
      p_m6_L: 0.191,
      p_m6_H: 0.27,
      p_m12_L: 0.17,
      p_m12_H: 0.37,
    },
    1237: {
      H: 0.101,
      L: 0.101,
      O: 0.101,
      C: 0.101,
      V: 0,
      p_w1_L: 0.096,
      p_w1_H: 0.109,
      p_m1_L: 0.096,
      p_m1_H: 0.154,
      p_m3_L: 0.096,
      p_m3_H: 0.154,
      p_m6_L: 0.096,
      p_m6_H: 0.154,
      p_m12_L: 0.096,
      p_m12_H: 0.19,
    },
    1238: {
      H: 4.64,
      L: 4.51,
      O: 4.58,
      C: 4.59,
      V: 1153690,
      p_w1_L: 4.25,
      p_w1_H: 4.78,
      p_m1_L: 4.25,
      p_m1_H: 5.36,
      p_m3_L: 4.25,
      p_m3_H: 6.24,
      p_m6_L: 4.25,
      p_m6_H: 8.12,
      p_m12_L: 4.25,
      p_m12_H: 8.73,
    },
    1239: {
      H: 0.199,
      L: 0.199,
      O: 0.199,
      C: 0.199,
      V: 0,
      p_w1_L: 0.198,
      p_w1_H: 0.24,
      p_m1_L: 0.198,
      p_m1_H: 0.285,
      p_m3_L: 0.198,
      p_m3_H: 0.29,
      p_m6_L: 0.187,
      p_m6_H: 0.32,
      p_m12_L: 0.187,
      p_m12_H: 0.465,
    },
    1240: {
      H: 0.67,
      L: 0.67,
      O: 0.67,
      C: 0.67,
      V: 0,
      p_w1_L: 0.65,
      p_w1_H: 0.67,
      p_m1_L: 0.61,
      p_m1_H: 0.75,
      p_m3_L: 0.61,
      p_m3_H: 0.77,
      p_m6_L: 0.61,
      p_m6_H: 0.87,
      p_m12_L: 0.61,
      p_m12_H: 1.09,
    },
    1241: {
      H: 0.124,
      L: 0.124,
      O: 0.124,
      C: 0.124,
      V: 0,
      p_w1_L: 0.116,
      p_w1_H: 0.128,
      p_m1_L: 0.111,
      p_m1_H: 0.142,
      p_m3_L: 0.111,
      p_m3_H: 0.155,
      p_m6_L: 0.089,
      p_m6_H: 0.155,
      p_m12_L: 0.082,
      p_m12_H: 0.155,
    },
    1243: {
      H: 0.083,
      L: 0.081,
      O: 0.083,
      C: 0.081,
      V: 168000,
      p_w1_L: 0.08,
      p_w1_H: 0.084,
      p_m1_L: 0.078,
      p_m1_H: 0.095,
      p_m3_L: 0.078,
      p_m3_H: 0.099,
      p_m6_L: 0.078,
      p_m6_H: 0.108,
      p_m12_L: 0.078,
      p_m12_H: 0.118,
    },
    1245: {
      H: 0.275,
      L: 0.275,
      O: 0.275,
      C: 0.275,
      V: 0,
      p_w1_L: 0.25,
      p_w1_H: 0.285,
      p_m1_L: 0.25,
      p_m1_H: 0.34,
      p_m3_L: 0.25,
      p_m3_H: 0.445,
      p_m6_L: 0.218,
      p_m6_H: 0.55,
      p_m12_L: 0.192,
      p_m12_H: 0.55,
    },
    1246: {
      H: 0.18,
      L: 0.18,
      O: 0.18,
      C: 0.18,
      V: 0,
      p_w1_L: 0.161,
      p_w1_H: 0.18,
      p_m1_L: 0.161,
      p_m1_H: 0.205,
      p_m3_L: 0.161,
      p_m3_H: 0.205,
      p_m6_L: 0.161,
      p_m6_H: 0.395,
      p_m12_L: 0.036,
      p_m12_H: 0.43,
    },
    1247: {
      H: 0.5,
      L: 0.5,
      O: 0.5,
      C: 0.5,
      V: 400,
      p_w1_L: 0.5,
      p_w1_H: 0.51,
      p_m1_L: 0.42,
      p_m1_H: 0.51,
      p_m3_L: 0.42,
      p_m3_H: 0.6,
      p_m6_L: 0.42,
      p_m6_H: 0.85,
      p_m12_L: 0.33,
      p_m12_H: 1.17,
    },
    1250: {
      H: 0.093,
      L: 0.091,
      O: 0.091,
      C: 0.091,
      V: 23542900,
      p_w1_L: 0.089,
      p_w1_H: 0.097,
      p_m1_L: 0.086,
      p_m1_H: 0.106,
      p_m3_L: 0.086,
      p_m3_H: 0.123,
      p_m6_L: 0.08,
      p_m6_H: 0.125,
      p_m12_L: 0.07,
      p_m12_H: 0.149,
    },
    1251: {
      H: 0.27,
      L: 0.25,
      O: 0.27,
      C: 0.255,
      V: 5282000,
      p_w1_L: 0.24,
      p_w1_H: 0.27,
      p_m1_L: 0.235,
      p_m1_H: 0.3,
      p_m3_L: 0.221,
      p_m3_H: 0.45,
      p_m6_L: 0.202,
      p_m6_H: 0.45,
      p_m12_L: 0.202,
      p_m12_H: 0.55,
    },
    1252: {
      H: 5.54,
      L: 5.39,
      O: 5.51,
      C: 5.53,
      V: 877000,
      p_w1_L: 5.26,
      p_w1_H: 5.62,
      p_m1_L: 5.26,
      p_m1_H: 7.24,
      p_m3_L: 5.26,
      p_m3_H: 7.25,
      p_m6_L: 5.26,
      p_m6_H: 7.25,
      p_m12_L: 5.26,
      p_m12_H: 7.84,
    },
    1253: {
      H: 0.237,
      L: 0.237,
      O: 0.237,
      C: 0.237,
      V: 0,
      p_w1_L: 0.237,
      p_w1_H: 0.238,
      p_m1_L: 0.237,
      p_m1_H: 0.275,
      p_m3_L: 0.237,
      p_m3_H: 0.39,
      p_m6_L: 0.237,
      p_m6_H: 0.39,
      p_m12_L: 0.237,
      p_m12_H: 0.395,
    },
    1255: {
      H: 1.35,
      L: 1.3,
      O: 1.3,
      C: 1.35,
      V: 68000,
      p_w1_L: 1.18,
      p_w1_H: 1.39,
      p_m1_L: 1.18,
      p_m1_H: 1.49,
      p_m3_L: 1.18,
      p_m3_H: 6,
      p_m6_L: 1.18,
      p_m6_H: 8.08,
      p_m12_L: 1.18,
      p_m12_H: 8.5,
    },
    1257: {
      H: 2.73,
      L: 2.65,
      O: 2.66,
      C: 2.71,
      V: 3609120,
      p_w1_L: 2.63,
      p_w1_H: 2.76,
      p_m1_L: 2.59,
      p_m1_H: 2.81,
      p_m3_L: 2.59,
      p_m3_H: 3.5,
      p_m6_L: 2.5,
      p_m6_H: 3.5,
      p_m12_L: 2.5,
      p_m12_H: 3.83,
    },
    1258: {
      H: 3.09,
      L: 2.97,
      O: 3.06,
      C: 3.01,
      V: 2102000,
      p_w1_L: 2.8,
      p_w1_H: 3.16,
      p_m1_L: 2.58,
      p_m1_H: 3.16,
      p_m3_L: 2.58,
      p_m3_H: 5.08,
      p_m6_L: 2.58,
      p_m6_H: 5.08,
      p_m12_L: 1.92,
      p_m12_H: 5.41,
    },
    1259: {
      H: 0.055,
      L: 0.048,
      O: 0.048,
      C: 0.053,
      V: 632000,
      p_w1_L: 0.045,
      p_w1_H: 0.064,
      p_m1_L: 0.045,
      p_m1_H: 0.07,
      p_m3_L: 0.045,
      p_m3_H: 0.092,
      p_m6_L: 0.045,
      p_m6_H: 0.11,
      p_m12_L: 0.045,
      p_m12_H: 0.2,
    },
    1260: {
      H: 0.3,
      L: 0.3,
      O: 0.3,
      C: 0.3,
      V: 0,
      p_w1_L: 0.3,
      p_w1_H: 0.315,
      p_m1_L: 0.29,
      p_m1_H: 0.365,
      p_m3_L: 0.29,
      p_m3_H: 0.395,
      p_m6_L: 0.29,
      p_m6_H: 0.48,
      p_m12_L: 0.29,
      p_m12_H: 0.64,
    },
    1262: {
      H: 0.495,
      L: 0.48,
      O: 0.48,
      C: 0.485,
      V: 227000,
      p_w1_L: 0.455,
      p_w1_H: 0.51,
      p_m1_L: 0.45,
      p_m1_H: 0.56,
      p_m3_L: 0.33,
      p_m3_H: 0.57,
      p_m6_L: 0.255,
      p_m6_H: 0.57,
      p_m12_L: 0.23,
      p_m12_H: 0.57,
    },
    1263: {
      H: 15.18,
      L: 13.8,
      O: 14.08,
      C: 15.06,
      V: 7345600,
      p_w1_L: 12.7,
      p_w1_H: 17.4,
      p_m1_L: 10.62,
      p_m1_H: 17.4,
      p_m3_L: 7.01,
      p_m3_H: 17.4,
      p_m6_L: 3.91,
      p_m6_H: 17.4,
      p_m12_L: 2.2,
      p_m12_H: 17.4,
    },
    1265: {
      H: 0.405,
      L: 0.405,
      O: 0.405,
      C: 0.405,
      V: 100000,
      p_w1_L: 0.39,
      p_w1_H: 0.405,
      p_m1_L: 0.39,
      p_m1_H: 0.44,
      p_m3_L: 0.39,
      p_m3_H: 0.47,
      p_m6_L: 0.39,
      p_m6_H: 0.63,
      p_m12_L: 0.39,
      p_m12_H: 0.67,
    },
    1266: {
      H: 0.41,
      L: 0.4,
      O: 0.41,
      C: 0.41,
      V: 1765980,
      p_w1_L: 0.385,
      p_w1_H: 0.425,
      p_m1_L: 0.385,
      p_m1_H: 0.52,
      p_m3_L: 0.38,
      p_m3_H: 0.88,
      p_m6_L: 0.325,
      p_m6_H: 0.88,
      p_m12_L: 0.325,
      p_m12_H: 0.88,
    },
    1268: {
      H: 36.75,
      L: 35.1,
      O: 35.5,
      C: 36.45,
      V: 1054160,
      p_w1_L: 33.05,
      p_w1_H: 38.1,
      p_m1_L: 33.05,
      p_m1_H: 40.3,
      p_m3_L: 33.05,
      p_m3_H: 45,
      p_m6_L: 33.05,
      p_m6_H: 48.4,
      p_m12_L: 25,
      p_m12_H: 48.4,
    },
    1269: {
      H: 0.221,
      L: 0.201,
      O: 0.215,
      C: 0.221,
      V: 6106000,
      p_w1_L: 0.201,
      p_w1_H: 0.244,
      p_m1_L: 0.201,
      p_m1_H: 0.255,
      p_m3_L: 0.201,
      p_m3_H: 0.29,
      p_m6_L: 0.044,
      p_m6_H: 0.29,
      p_m12_L: 0.044,
      p_m12_H: 0.29,
    },
    1270: {
      H: 0.87,
      L: 0.86,
      O: 0.87,
      C: 0.87,
      V: 107000,
      p_w1_L: 0.84,
      p_w1_H: 0.91,
      p_m1_L: 0.84,
      p_m1_H: 0.94,
      p_m3_L: 0.82,
      p_m3_H: 0.94,
      p_m6_L: 0.82,
      p_m6_H: 1.02,
      p_m12_L: 0.82,
      p_m12_H: 1.3,
    },
    1271: {
      H: 7.95,
      L: 7.93,
      O: 7.95,
      C: 7.94,
      V: 13000,
      p_w1_L: 7.87,
      p_w1_H: 7.97,
      p_m1_L: 7.74,
      p_m1_H: 8,
      p_m3_L: 7.51,
      p_m3_H: 8.3,
      p_m6_L: 7.44,
      p_m6_H: 8.3,
      p_m12_L: 4.41,
      p_m12_H: 8.85,
    },
    1272: {
      H: 1.08,
      L: 1.08,
      O: 1.08,
      C: 1.08,
      V: 1000,
      p_w1_L: 0.96,
      p_w1_H: 1.1,
      p_m1_L: 0.96,
      p_m1_H: 1.1,
      p_m3_L: 0.91,
      p_m3_H: 1.1,
      p_m6_L: 0.91,
      p_m6_H: 1.1,
      p_m12_L: 0.75,
      p_m12_H: 1.28,
    },
    1273: {
      H: 0.435,
      L: 0.43,
      O: 0.43,
      C: 0.445,
      V: 84000,
      p_w1_L: 0.425,
      p_w1_H: 0.48,
      p_m1_L: 0.425,
      p_m1_H: 0.49,
      p_m3_L: 0.425,
      p_m3_H: 0.55,
      p_m6_L: 0.425,
      p_m6_H: 0.57,
      p_m12_L: 0.36,
      p_m12_H: 0.57,
    },
    1277: {
      H: 0.53,
      L: 0.475,
      O: 0.475,
      C: 0.53,
      V: 8510000,
      p_w1_L: 0.475,
      p_w1_H: 0.59,
      p_m1_L: 0.475,
      p_m1_H: 0.66,
      p_m3_L: 0.475,
      p_m3_H: 0.86,
      p_m6_L: 0.475,
      p_m6_H: 0.86,
      p_m12_L: 0.395,
      p_m12_H: 0.86,
    },
    1278: {
      H: 0.091,
      L: 0.085,
      O: 0.088,
      C: 0.085,
      V: 42500,
      p_w1_L: 0.08,
      p_w1_H: 0.091,
      p_m1_L: 0.08,
      p_m1_H: 0.098,
      p_m3_L: 0.08,
      p_m3_H: 0.107,
      p_m6_L: 0.08,
      p_m6_H: 0.178,
      p_m12_L: 0.08,
      p_m12_H: 0.192,
    },
    1280: {
      H: 0.88,
      L: 0.88,
      O: 0.88,
      C: 0.88,
      V: 0,
      p_w1_L: 0.71,
      p_w1_H: 0.98,
      p_m1_L: 0.71,
      p_m1_H: 1.1,
      p_m3_L: 0.71,
      p_m3_H: 1.55,
      p_m6_L: 0.71,
      p_m6_H: 3.51,
      p_m12_L: 0.71,
      p_m12_H: 3.51,
    },
    1281: {
      H: 0.151,
      L: 0.151,
      O: 0.151,
      C: 0.151,
      V: 263000,
      p_w1_L: 0.147,
      p_w1_H: 0.151,
      p_m1_L: 0.146,
      p_m1_H: 0.178,
      p_m3_L: 0.146,
      p_m3_H: 0.19,
      p_m6_L: 0.146,
      p_m6_H: 0.205,
      p_m12_L: 0.146,
      p_m12_H: 0.26,
    },
    1282: {
      H: 0.224,
      L: 0.211,
      O: 0.223,
      C: 0.211,
      V: 8400000,
      p_w1_L: 0.211,
      p_w1_H: 0.235,
      p_m1_L: 0.211,
      p_m1_H: 0.275,
      p_m3_L: 0.211,
      p_m3_H: 0.34,
      p_m6_L: 0.205,
      p_m6_H: 0.36,
      p_m12_L: 0.205,
      p_m12_H: 0.36,
    },
    1283: {
      H: 1.52,
      L: 1.5,
      O: 1.51,
      C: 1.5,
      V: 980000,
      p_w1_L: 1.47,
      p_w1_H: 1.62,
      p_m1_L: 1.47,
      p_m1_H: 1.7,
      p_m3_L: 1.43,
      p_m3_H: 1.86,
      p_m6_L: 1.39,
      p_m6_H: 1.92,
      p_m12_L: 1.39,
      p_m12_H: 2.15,
    },
    1285: {
      H: 1.35,
      L: 1.35,
      O: 1.35,
      C: 1.35,
      V: 0,
      p_w1_L: 1.28,
      p_w1_H: 1.44,
      p_m1_L: 1.28,
      p_m1_H: 1.56,
      p_m3_L: 1.28,
      p_m3_H: 1.75,
      p_m6_L: 1.28,
      p_m6_H: 1.88,
      p_m12_L: 1.2,
      p_m12_H: 1.96,
    },
    1286: {
      H: 1.97,
      L: 1.94,
      O: 1.95,
      C: 1.95,
      V: 57000,
      p_w1_L: 1.88,
      p_w1_H: 2.06,
      p_m1_L: 1.88,
      p_m1_H: 2.22,
      p_m3_L: 1.88,
      p_m3_H: 2.63,
      p_m6_L: 1.81,
      p_m6_H: 2.73,
      p_m12_L: 1.81,
      p_m12_H: 3.55,
    },
    1288: {
      H: 2.69,
      L: 2.65,
      O: 2.68,
      C: 2.67,
      V: 71629300,
      p_w1_L: 2.58,
      p_w1_H: 2.7,
      p_m1_L: 2.57,
      p_m1_H: 2.7,
      p_m3_L: 2.54,
      p_m3_H: 2.8,
      p_m6_L: 2.54,
      p_m6_H: 3.11,
      p_m12_L: 2.54,
      p_m12_H: 3.3,
    },
    1289: {
      H: 0.95,
      L: 0.95,
      O: 0.95,
      C: 0.95,
      V: 0,
      p_w1_L: 0.92,
      p_w1_H: 1.03,
      p_m1_L: 0.91,
      p_m1_H: 1.07,
      p_m3_L: 0.9,
      p_m3_H: 1.14,
      p_m6_L: 0.8,
      p_m6_H: 1.23,
      p_m12_L: 0.8,
      p_m12_H: 1.45,
    },
    1290: {
      H: 1.08,
      L: 1.03,
      O: 1.06,
      C: 1.06,
      V: 654000,
      p_w1_L: 1.03,
      p_w1_H: 1.12,
      p_m1_L: 1,
      p_m1_H: 1.22,
      p_m3_L: 1,
      p_m3_H: 1.32,
      p_m6_L: 1,
      p_m6_H: 1.32,
      p_m12_L: 0.69,
      p_m12_H: 1.32,
    },
    1292: {
      H: 1.78,
      L: 1.78,
      O: 1.78,
      C: 1.78,
      V: 5000,
      p_w1_L: 1.75,
      p_w1_H: 1.81,
      p_m1_L: 1.75,
      p_m1_H: 1.95,
      p_m3_L: 1.75,
      p_m3_H: 2.3,
      p_m6_L: 1.75,
      p_m6_H: 2.94,
      p_m12_L: 1.75,
      p_m12_H: 3.2,
    },
    1293: {
      H: 0.76,
      L: 0.72,
      O: 0.75,
      C: 0.72,
      V: 1720000,
      p_w1_L: 0.69,
      p_w1_H: 0.77,
      p_m1_L: 0.69,
      p_m1_H: 0.86,
      p_m3_L: 0.69,
      p_m3_H: 1.08,
      p_m6_L: 0.69,
      p_m6_H: 1.87,
      p_m12_L: 0.62,
      p_m12_H: 1.87,
    },
    1296: {
      H: 0.62,
      L: 0.53,
      O: 0.53,
      C: 0.58,
      V: 7887000,
      p_w1_L: 0.445,
      p_w1_H: 0.62,
      p_m1_L: 0.425,
      p_m1_H: 0.62,
      p_m3_L: 0.425,
      p_m3_H: 0.78,
      p_m6_L: 0.325,
      p_m6_H: 0.78,
      p_m12_L: 0.181,
      p_m12_H: 0.78,
    },
    1297: {
      H: 0.99,
      L: 0.89,
      O: 0.89,
      C: 0.91,
      V: 1744000,
      p_w1_L: 0.86,
      p_w1_H: 0.99,
      p_m1_L: 0.86,
      p_m1_H: 1.01,
      p_m3_L: 0.86,
      p_m3_H: 1.2,
      p_m6_L: 0.86,
      p_m6_H: 1.6,
      p_m12_L: 0.86,
      p_m12_H: 2.06,
    },
    1298: {
      H: 1.2,
      L: 1.2,
      O: 1.2,
      C: 1.2,
      V: 0,
      p_w1_L: 0.99,
      p_w1_H: 1.33,
      p_m1_L: 0.99,
      p_m1_H: 1.33,
      p_m3_L: 0.99,
      p_m3_H: 1.5,
      p_m6_L: 0.99,
      p_m6_H: 1.8,
      p_m12_L: 0.99,
      p_m12_H: 2.2,
    },
    1299: {
      H: 83.5,
      L: 82.5,
      O: 82.85,
      C: 83.2,
      V: 20556000,
      p_w1_L: 79,
      p_w1_H: 83.5,
      p_m1_L: 79,
      p_m1_H: 88.75,
      p_m3_L: 79,
      p_m3_H: 95.8,
      p_m6_L: 79,
      p_m6_H: 98.75,
      p_m12_L: 79,
      p_m12_H: 109.3,
    },
    1300: {
      H: 0.62,
      L: 0.6,
      O: 0.61,
      C: 0.61,
      V: 382000,
      p_w1_L: 0.6,
      p_w1_H: 0.62,
      p_m1_L: 0.6,
      p_m1_H: 0.66,
      p_m3_L: 0.6,
      p_m3_H: 0.83,
      p_m6_L: 0.6,
      p_m6_H: 0.83,
      p_m12_L: 0.6,
      p_m12_H: 0.98,
    },
    1301: {
      H: 1.27,
      L: 1.22,
      O: 1.23,
      C: 1.24,
      V: 300000,
      p_w1_L: 1.18,
      p_w1_H: 1.27,
      p_m1_L: 1.14,
      p_m1_H: 1.27,
      p_m3_L: 1.11,
      p_m3_H: 1.32,
      p_m6_L: 1.11,
      p_m6_H: 1.42,
      p_m12_L: 1.11,
      p_m12_H: 1.43,
    },
    1302: {
      H: 3.95,
      L: 3.76,
      O: 3.79,
      C: 3.78,
      V: 7416000,
      p_w1_L: 3.74,
      p_w1_H: 4.27,
      p_m1_L: 3.31,
      p_m1_H: 4.36,
      p_m3_L: 3.31,
      p_m3_H: 4.36,
      p_m6_L: 3.31,
      p_m6_H: 5.86,
      p_m12_L: 3.18,
      p_m12_H: 5.98,
    },
    1303: {
      H: 0.155,
      L: 0.155,
      O: 0.155,
      C: 0.155,
      V: 0,
      p_w1_L: 0.15,
      p_w1_H: 0.187,
      p_m1_L: 0.15,
      p_m1_H: 0.187,
      p_m3_L: 0.145,
      p_m3_H: 0.219,
      p_m6_L: 0.14,
      p_m6_H: 0.24,
      p_m12_L: 0.12,
      p_m12_H: 0.395,
    },
    1305: {
      H: 1.3,
      L: 1.3,
      O: 1.3,
      C: 1.3,
      V: 6000,
      p_w1_L: 1.23,
      p_w1_H: 1.48,
      p_m1_L: 0.95,
      p_m1_H: 1.6,
      p_m3_L: 0.45,
      p_m3_H: 1.6,
      p_m6_L: 0.45,
      p_m6_H: 1.6,
      p_m12_L: 0.355,
      p_m12_H: 1.6,
    },
    1308: {
      H: 31.75,
      L: 30.7,
      O: 30.95,
      C: 31.15,
      V: 5295960,
      p_w1_L: 28.7,
      p_w1_H: 31.9,
      p_m1_L: 24.1,
      p_m1_H: 31.9,
      p_m3_L: 22.8,
      p_m3_H: 36,
      p_m6_L: 22.8,
      p_m6_H: 36,
      p_m12_L: 14.44,
      p_m12_H: 36,
    },
    1310: {
      H: 9.8,
      L: 9.7,
      O: 9.8,
      C: 9.78,
      V: 800500,
      p_w1_L: 9.56,
      p_w1_H: 9.87,
      p_m1_L: 9.32,
      p_m1_H: 9.98,
      p_m3_L: 8.84,
      p_m3_H: 9.98,
      p_m6_L: 8.23,
      p_m6_H: 10.26,
      p_m12_L: 8.23,
      p_m12_H: 13.4,
    },
    1312: {
      H: 0.062,
      L: 0.062,
      O: 0.062,
      C: 0.062,
      V: 0,
      p_w1_L: 0.059,
      p_w1_H: 0.062,
      p_m1_L: 0.056,
      p_m1_H: 0.074,
      p_m3_L: 0.056,
      p_m3_H: 0.077,
      p_m6_L: 0.053,
      p_m6_H: 0.089,
      p_m12_L: 0.053,
      p_m12_H: 0.108,
    },
    1313: {
      H: 6.18,
      L: 6.06,
      O: 6.11,
      C: 6.08,
      V: 10963500,
      p_w1_L: 5.65,
      p_w1_H: 6.18,
      p_m1_L: 5.61,
      p_m1_H: 6.48,
      p_m3_L: 5.61,
      p_m3_H: 9.1,
      p_m6_L: 5.61,
      p_m6_H: 9.1,
      p_m12_L: 5.61,
      p_m12_H: 9.98,
    },
    1314: {
      H: 0.24,
      L: 0.234,
      O: 0.235,
      C: 0.239,
      V: 188000,
      p_w1_L: 0.227,
      p_w1_H: 0.249,
      p_m1_L: 0.224,
      p_m1_H: 0.25,
      p_m3_L: 0.224,
      p_m3_H: 0.305,
      p_m6_L: 0.224,
      p_m6_H: 0.33,
      p_m12_L: 0.224,
      p_m12_H: 0.39,
    },
    1315: {
      H: 0.033,
      L: 0.032,
      O: 0.033,
      C: 0.032,
      V: 1912000,
      p_w1_L: 0.031,
      p_w1_H: 0.035,
      p_m1_L: 0.027,
      p_m1_H: 0.038,
      p_m3_L: 0.026,
      p_m3_H: 0.038,
      p_m6_L: 0.022,
      p_m6_H: 0.057,
      p_m12_L: 0.022,
      p_m12_H: 0.108,
    },
    1316: {
      H: 10.4,
      L: 9.88,
      O: 9.95,
      C: 10.34,
      V: 5578000,
      p_w1_L: 9.65,
      p_w1_H: 10.5,
      p_m1_L: 8.9,
      p_m1_H: 11.18,
      p_m3_L: 7.53,
      p_m3_H: 11.18,
      p_m6_L: 7.53,
      p_m6_H: 12.9,
      p_m12_L: 7.51,
      p_m12_H: 14.7,
    },
    1317: {
      H: 0.87,
      L: 0.87,
      O: 0.87,
      C: 0.87,
      V: 0,
      p_w1_L: 0.87,
      p_w1_H: 0.87,
      p_m1_L: 0.85,
      p_m1_H: 1.32,
      p_m3_L: 0.85,
      p_m3_H: 1.71,
      p_m6_L: 0.85,
      p_m6_H: 2.11,
      p_m12_L: 0.85,
      p_m12_H: 2.4,
    },
    1319: {
      H: 0.255,
      L: 0.255,
      O: 0.255,
      C: 0.255,
      V: 8000,
      p_w1_L: 0.249,
      p_w1_H: 0.255,
      p_m1_L: 0.245,
      p_m1_H: 0.26,
      p_m3_L: 0.245,
      p_m3_H: 0.27,
      p_m6_L: 0.245,
      p_m6_H: 0.3,
      p_m12_L: 0.245,
      p_m12_H: 0.31,
    },
    1321: {
      H: 0.98,
      L: 0.97,
      O: 0.98,
      C: 0.96,
      V: 34000,
      p_w1_L: 0.84,
      p_w1_H: 0.98,
      p_m1_L: 0.84,
      p_m1_H: 1,
      p_m3_L: 0.78,
      p_m3_H: 1,
      p_m6_L: 0.72,
      p_m6_H: 1.07,
      p_m12_L: 0.72,
      p_m12_H: 1.07,
    },
    1323: {
      H: 1.9,
      L: 1.84,
      O: 1.89,
      C: 1.87,
      V: 17750000,
      p_w1_L: 1.82,
      p_w1_H: 1.92,
      p_m1_L: 1.26,
      p_m1_H: 1.95,
      p_m3_L: 0.99,
      p_m3_H: 1.95,
      p_m6_L: 0.85,
      p_m6_H: 1.95,
      p_m12_L: 0.76,
      p_m12_H: 1.95,
    },
    1326: {
      H: 0.128,
      L: 0.128,
      O: 0.128,
      C: 0.128,
      V: 0,
      p_w1_L: 0.118,
      p_w1_H: 0.13,
      p_m1_L: 0.118,
      p_m1_H: 0.138,
      p_m3_L: 0.11,
      p_m3_H: 0.138,
      p_m6_L: 0.11,
      p_m6_H: 0.157,
      p_m12_L: 0.079,
      p_m12_H: 0.157,
    },
    1327: {
      H: 0.35,
      L: 0.35,
      O: 0.35,
      C: 0.35,
      V: 50000,
      p_w1_L: 0.315,
      p_w1_H: 0.36,
      p_m1_L: 0.295,
      p_m1_H: 0.395,
      p_m3_L: 0.25,
      p_m3_H: 0.395,
      p_m6_L: 0.103,
      p_m6_H: 0.395,
      p_m12_L: 0.092,
      p_m12_H: 0.395,
    },
    1328: {
      H: 0.095,
      L: 0.095,
      O: 0.095,
      C: 0.095,
      V: 0,
      p_w1_L: 0.094,
      p_w1_H: 0.098,
      p_m1_L: 0.083,
      p_m1_H: 0.104,
      p_m3_L: 0.071,
      p_m3_H: 0.104,
      p_m6_L: 0.071,
      p_m6_H: 0.128,
      p_m12_L: 0.071,
      p_m12_H: 0.135,
    },
    1329: {
      H: 1.28,
      L: 1.28,
      O: 1.28,
      C: 1.19,
      V: 4000,
      p_w1_L: 0.82,
      p_w1_H: 1.28,
      p_m1_L: 0.82,
      p_m1_H: 1.28,
      p_m3_L: 0.82,
      p_m3_H: 1.28,
      p_m6_L: 0.82,
      p_m6_H: 1.46,
      p_m12_L: 0.82,
      p_m12_H: 1.46,
    },
    1330: {
      H: 4.07,
      L: 3.97,
      O: 3.97,
      C: 4.06,
      V: 1900000,
      p_w1_L: 3.89,
      p_w1_H: 4.13,
      p_m1_L: 3.81,
      p_m1_H: 4.4,
      p_m3_L: 3.81,
      p_m3_H: 5.36,
      p_m6_L: 3.26,
      p_m6_H: 5.36,
      p_m12_L: 3.03,
      p_m12_H: 5.36,
    },
    1332: {
      H: 1.62,
      L: 1.57,
      O: 1.62,
      C: 1.62,
      V: 2202810,
      p_w1_L: 1.49,
      p_w1_H: 1.69,
      p_m1_L: 0.92,
      p_m1_H: 1.69,
      p_m3_L: 0.77,
      p_m3_H: 1.69,
      p_m6_L: 0.77,
      p_m6_H: 1.69,
      p_m12_L: 0.49,
      p_m12_H: 1.75,
    },
    1333: {
      H: 1.68,
      L: 1.68,
      O: 1.68,
      C: 1.68,
      V: 0,
      p_w1_L: 1.68,
      p_w1_H: 1.68,
      p_m1_L: 1.68,
      p_m1_H: 1.68,
      p_m3_L: 1.68,
      p_m3_H: 1.68,
      p_m6_L: 1.48,
      p_m6_H: 2,
      p_m12_L: 1.4,
      p_m12_H: 2.69,
    },
    1335: {
      H: 0.15,
      L: 0.15,
      O: 0.15,
      C: 0.15,
      V: 0,
      p_w1_L: 0.142,
      p_w1_H: 0.151,
      p_m1_L: 0.13,
      p_m1_H: 0.151,
      p_m3_L: 0.116,
      p_m3_H: 0.16,
      p_m6_L: 0.1,
      p_m6_H: 0.29,
      p_m12_L: 0.095,
      p_m12_H: 0.29,
    },
    1336: {
      H: 21.6,
      L: 21.3,
      O: 21.45,
      C: 21.45,
      V: 2836200,
      p_w1_L: 20.65,
      p_w1_H: 21.6,
      p_m1_L: 20.65,
      p_m1_H: 22.5,
      p_m3_L: 20.65,
      p_m3_H: 25.15,
      p_m6_L: 20.55,
      p_m6_H: 28.45,
      p_m12_L: 20.55,
      p_m12_H: 34.2,
    },
    1337: {
      H: 2.5,
      L: 2.44,
      O: 2.48,
      C: 2.49,
      V: 14844000,
      p_w1_L: 2.31,
      p_w1_H: 2.67,
      p_m1_L: 2.11,
      p_m1_H: 3.1,
      p_m3_L: 1.5,
      p_m3_H: 3.1,
      p_m6_L: 1.5,
      p_m6_H: 3.1,
      p_m12_L: 1.5,
      p_m12_H: 3.36,
    },
    1338: {
      H: 0.102,
      L: 0.095,
      O: 0.095,
      C: 0.1,
      V: 264000,
      p_w1_L: 0.091,
      p_w1_H: 0.126,
      p_m1_L: 0.082,
      p_m1_H: 0.128,
      p_m3_L: 0.067,
      p_m3_H: 0.128,
      p_m6_L: 0.067,
      p_m6_H: 0.128,
      p_m12_L: 0.067,
      p_m12_H: 0.205,
    },
    1339: {
      H: 2.37,
      L: 2.34,
      O: 2.35,
      C: 2.35,
      V: 10685000,
      p_w1_L: 2.28,
      p_w1_H: 2.37,
      p_m1_L: 2.28,
      p_m1_H: 2.4,
      p_m3_L: 2.26,
      p_m3_H: 2.59,
      p_m6_L: 2.26,
      p_m6_H: 2.95,
      p_m12_L: 2.26,
      p_m12_H: 2.95,
    },
    1340: {
      H: 0.097,
      L: 0.091,
      O: 0.094,
      C: 0.097,
      V: 1436000,
      p_w1_L: 0.091,
      p_w1_H: 0.114,
      p_m1_L: 0.089,
      p_m1_H: 0.119,
      p_m3_L: 0.077,
      p_m3_H: 0.119,
      p_m6_L: 0.072,
      p_m6_H: 0.128,
      p_m12_L: 0.072,
      p_m12_H: 0.176,
    },
    1341: {
      H: 0.37,
      L: 0.345,
      O: 0.365,
      C: 0.37,
      V: 1100000,
      p_w1_L: 0.345,
      p_w1_H: 0.4,
      p_m1_L: 0.34,
      p_m1_H: 0.46,
      p_m3_L: 0.335,
      p_m3_H: 0.62,
      p_m6_L: 0.335,
      p_m6_H: 0.73,
      p_m12_L: 0.275,
      p_m12_H: 0.73,
    },
    1343: {
      H: 0.128,
      L: 0.11,
      O: 0.113,
      C: 0.12,
      V: 1600000,
      p_w1_L: 0.108,
      p_w1_H: 0.128,
      p_m1_L: 0.108,
      p_m1_H: 0.135,
      p_m3_L: 0.098,
      p_m3_H: 0.138,
      p_m6_L: 0.098,
      p_m6_H: 0.138,
      p_m12_L: 0.066,
      p_m12_H: 0.29,
    },
    1345: {
      H: 1.18,
      L: 1.16,
      O: 1.18,
      C: 1.16,
      V: 23000,
      p_w1_L: 1.1,
      p_w1_H: 1.22,
      p_m1_L: 1.1,
      p_m1_H: 1.31,
      p_m3_L: 1.1,
      p_m3_H: 1.54,
      p_m6_L: 1.04,
      p_m6_H: 1.65,
      p_m12_L: 0.88,
      p_m12_H: 1.65,
    },
    1346: {
      H: 0.4,
      L: 0.4,
      O: 0.4,
      C: 0.4,
      V: 148000,
      p_w1_L: 0.385,
      p_w1_H: 0.415,
      p_m1_L: 0.385,
      p_m1_H: 0.42,
      p_m3_L: 0.38,
      p_m3_H: 0.465,
      p_m6_L: 0.38,
      p_m6_H: 0.53,
      p_m12_L: 0.31,
      p_m12_H: 0.53,
    },
    1347: {
      H: 49.85,
      L: 47.7,
      O: 49.55,
      C: 47.95,
      V: 3859180,
      p_w1_L: 46.6,
      p_w1_H: 53.7,
      p_m1_L: 43.8,
      p_m1_H: 53.75,
      p_m3_L: 37.55,
      p_m3_H: 53.75,
      p_m6_L: 37.55,
      p_m6_H: 57,
      p_m12_L: 37.25,
      p_m12_H: 64.65,
    },
    1348: {
      H: 0.26,
      L: 0.25,
      O: 0.255,
      C: 0.255,
      V: 1264000,
      p_w1_L: 0.25,
      p_w1_H: 0.26,
      p_m1_L: 0.25,
      p_m1_H: 0.26,
      p_m3_L: 0.25,
      p_m3_H: 0.27,
      p_m6_L: 0.25,
      p_m6_H: 0.32,
      p_m12_L: 0.25,
      p_m12_H: 0.495,
    },
    1349: {
      H: 4.08,
      L: 3.99,
      O: 3.99,
      C: 4,
      V: 454000,
      p_w1_L: 3.96,
      p_w1_H: 4.18,
      p_m1_L: 3.76,
      p_m1_H: 4.18,
      p_m3_L: 3.71,
      p_m3_H: 4.42,
      p_m6_L: 3.71,
      p_m6_H: 5.64,
      p_m12_L: 3.71,
      p_m12_H: 5.8,
    },
    1351: {
      H: 0.84,
      L: 0.79,
      O: 0.8,
      C: 0.82,
      V: 122000,
      p_w1_L: 0.76,
      p_w1_H: 0.84,
      p_m1_L: 0.75,
      p_m1_H: 0.87,
      p_m3_L: 0.75,
      p_m3_H: 1.28,
      p_m6_L: 0.75,
      p_m6_H: 1.77,
      p_m12_L: 0.75,
      p_m12_H: 4.6,
    },
    1355: {
      H: 0.34,
      L: 0.3,
      O: 0.34,
      C: 0.325,
      V: 7048000,
      p_w1_L: 0.3,
      p_w1_H: 0.35,
      p_m1_L: 0.3,
      p_m1_H: 0.395,
      p_m3_L: 0.3,
      p_m3_H: 0.42,
      p_m6_L: 0.275,
      p_m6_H: 0.48,
      p_m12_L: 0.27,
      p_m12_H: 0.48,
    },
    1357: {
      H: 1.69,
      L: 1.66,
      O: 1.67,
      C: 1.68,
      V: 2639630,
      p_w1_L: 1.56,
      p_w1_H: 1.72,
      p_m1_L: 1.56,
      p_m1_H: 2.02,
      p_m3_L: 1.48,
      p_m3_H: 2.02,
      p_m6_L: 1.41,
      p_m6_H: 2.18,
      p_m12_L: 1.36,
      p_m12_H: 4.5,
    },
    1358: {
      H: 1.05,
      L: 0.89,
      O: 0.9,
      C: 1,
      V: 1908000,
      p_w1_L: 0.88,
      p_w1_H: 1.18,
      p_m1_L: 0.88,
      p_m1_H: 1.29,
      p_m3_L: 0.88,
      p_m3_H: 1.4,
      p_m6_L: 0.8,
      p_m6_H: 1.67,
      p_m12_L: 0.475,
      p_m12_H: 2.36,
    },
    1359: {
      H: 1.32,
      L: 1.29,
      O: 1.31,
      C: 1.3,
      V: 27756800,
      p_w1_L: 1.24,
      p_w1_H: 1.32,
      p_m1_L: 1.22,
      p_m1_H: 1.32,
      p_m3_L: 1.22,
      p_m3_H: 1.52,
      p_m6_L: 1.22,
      p_m6_H: 1.66,
      p_m12_L: 1.22,
      p_m12_H: 1.68,
    },
    1360: {
      H: 0.14,
      L: 0.135,
      O: 0.135,
      C: 0.14,
      V: 1080000,
      p_w1_L: 0.13,
      p_w1_H: 0.144,
      p_m1_L: 0.13,
      p_m1_H: 0.187,
      p_m3_L: 0.13,
      p_m3_H: 0.187,
      p_m6_L: 0.103,
      p_m6_H: 0.187,
      p_m12_L: 0.09,
      p_m12_H: 0.187,
    },
    1361: {
      H: 3.93,
      L: 3.76,
      O: 3.87,
      C: 3.9,
      V: 2811000,
      p_w1_L: 3.65,
      p_w1_H: 4.04,
      p_m1_L: 3.39,
      p_m1_H: 4.04,
      p_m3_L: 3.25,
      p_m3_H: 4.24,
      p_m6_L: 3.05,
      p_m6_H: 4.56,
      p_m12_L: 0.9,
      p_m12_H: 4.56,
    },
    1362: {
      H: 0.42,
      L: 0.42,
      O: 0.42,
      C: 0.42,
      V: 0,
      p_w1_L: 0.42,
      p_w1_H: 0.42,
      p_m1_L: 0.42,
      p_m1_H: 0.475,
      p_m3_L: 0.4,
      p_m3_H: 0.495,
      p_m6_L: 0.4,
      p_m6_H: 0.5,
      p_m12_L: 0.27,
      p_m12_H: 0.78,
    },
    1365: {
      H: 0.3,
      L: 0.3,
      O: 0.3,
      C: 0.3,
      V: 0,
      p_w1_L: 0.3,
      p_w1_H: 0.3,
      p_m1_L: 0.3,
      p_m1_H: 0.3,
      p_m3_L: 0.3,
      p_m3_H: 0.3,
      p_m6_L: 0.3,
      p_m6_H: 0.3,
      p_m12_L: 0.186,
      p_m12_H: 1.32,
    },
    1366: {
      H: 0.285,
      L: 0.28,
      O: 0.285,
      C: 0.285,
      V: 230000,
      p_w1_L: 0.275,
      p_w1_H: 0.3,
      p_m1_L: 0.265,
      p_m1_H: 0.3,
      p_m3_L: 0.265,
      p_m3_H: 0.37,
      p_m6_L: 0.265,
      p_m6_H: 0.41,
      p_m12_L: 0.265,
      p_m12_H: 0.52,
    },
    1367: {
      H: 0.51,
      L: 0.51,
      O: 0.51,
      C: 0.51,
      V: 0,
      p_w1_L: 0.51,
      p_w1_H: 0.51,
      p_m1_L: 0.51,
      p_m1_H: 0.51,
      p_m3_L: 0.51,
      p_m3_H: 0.51,
      p_m6_L: 0.51,
      p_m6_H: 0.51,
      p_m12_L: 0.35,
      p_m12_H: 0.81,
    },
    1368: {
      H: 10.42,
      L: 9.86,
      O: 10,
      C: 10.12,
      V: 23257000,
      p_w1_L: 9.35,
      p_w1_H: 10.56,
      p_m1_L: 9.35,
      p_m1_H: 11.38,
      p_m3_L: 9.21,
      p_m3_H: 14.9,
      p_m6_L: 8.56,
      p_m6_H: 16.32,
      p_m12_L: 3.02,
      p_m12_H: 16.32,
    },
    1370: {
      H: 1.06,
      L: 1.06,
      O: 1.06,
      C: 1.06,
      V: 0,
      p_w1_L: 1.06,
      p_w1_H: 1.06,
      p_m1_L: 1.06,
      p_m1_H: 1.06,
      p_m3_L: 1.06,
      p_m3_H: 1.06,
      p_m6_L: 1.06,
      p_m6_H: 1.06,
      p_m12_L: 0.52,
      p_m12_H: 1.55,
    },
    1371: {
      H: 0.096,
      L: 0.093,
      O: 0.096,
      C: 0.095,
      V: 540000,
      p_w1_L: 0.09,
      p_w1_H: 0.1,
      p_m1_L: 0.09,
      p_m1_H: 0.112,
      p_m3_L: 0.09,
      p_m3_H: 0.13,
      p_m6_L: 0.09,
      p_m6_H: 0.152,
      p_m12_L: 0.09,
      p_m12_H: 0.235,
    },
    1372: {
      H: 3.64,
      L: 3.56,
      O: 3.58,
      C: 3.56,
      V: 3885000,
      p_w1_L: 3.55,
      p_w1_H: 3.88,
      p_m1_L: 3.54,
      p_m1_H: 4.1,
      p_m3_L: 3.54,
      p_m3_H: 5.02,
      p_m6_L: 3.54,
      p_m6_H: 6.28,
      p_m12_L: 1,
      p_m12_H: 8.11,
    },
    1373: {
      H: 2.71,
      L: 2.69,
      O: 2.7,
      C: 2.7,
      V: 562000,
      p_w1_L: 2.69,
      p_w1_H: 2.74,
      p_m1_L: 2.67,
      p_m1_H: 2.85,
      p_m3_L: 2.63,
      p_m3_H: 2.94,
      p_m6_L: 2.63,
      p_m6_H: 3.4,
      p_m12_L: 2.44,
      p_m12_H: 3.4,
    },
    1375: {
      H: 1.42,
      L: 1.4,
      O: 1.41,
      C: 1.41,
      V: 1988000,
      p_w1_L: 1.38,
      p_w1_H: 1.45,
      p_m1_L: 1.38,
      p_m1_H: 1.45,
      p_m3_L: 1.37,
      p_m3_H: 1.68,
      p_m6_L: 1.36,
      p_m6_H: 1.68,
      p_m12_L: 1.36,
      p_m12_H: 1.94,
    },
    1376: {
      H: 0.107,
      L: 0.107,
      O: 0.107,
      C: 0.107,
      V: 0,
      p_w1_L: 0.107,
      p_w1_H: 0.107,
      p_m1_L: 0.107,
      p_m1_H: 0.107,
      p_m3_L: 0.107,
      p_m3_H: 0.107,
      p_m6_L: 0.107,
      p_m6_H: 0.107,
      p_m12_L: 0.1,
      p_m12_H: 0.21,
    },
    1378: {
      H: 7.85,
      L: 7.57,
      O: 7.63,
      C: 7.74,
      V: 9779680,
      p_w1_L: 7.27,
      p_w1_H: 7.85,
      p_m1_L: 6.88,
      p_m1_H: 8.14,
      p_m3_L: 6.88,
      p_m3_H: 14.34,
      p_m6_L: 6.88,
      p_m6_H: 14.34,
      p_m12_L: 6.47,
      p_m12_H: 14.4,
    },
    1379: {
      H: 5.06,
      L: 5.06,
      O: 5.06,
      C: 5.06,
      V: 0,
      p_w1_L: 4.88,
      p_w1_H: 5.06,
      p_m1_L: 4.81,
      p_m1_H: 5.37,
      p_m3_L: 4.81,
      p_m3_H: 5.7,
      p_m6_L: 4.5,
      p_m6_H: 6,
      p_m12_L: 4.5,
      p_m12_H: 7,
    },
    1380: {
      H: 0.026,
      L: 0.026,
      O: 0.026,
      C: 0.027,
      V: 62000,
      p_w1_L: 0.025,
      p_w1_H: 0.027,
      p_m1_L: 0.025,
      p_m1_H: 0.03,
      p_m3_L: 0.025,
      p_m3_H: 0.039,
      p_m6_L: 0.023,
      p_m6_H: 0.039,
      p_m12_L: 0.023,
      p_m12_H: 0.042,
    },
    1381: {
      H: 4.23,
      L: 4.13,
      O: 4.13,
      C: 4.22,
      V: 761553,
      p_w1_L: 4,
      p_w1_H: 4.29,
      p_m1_L: 3.93,
      p_m1_H: 4.32,
      p_m3_L: 3.93,
      p_m3_H: 4.94,
      p_m6_L: 3.72,
      p_m6_H: 4.94,
      p_m12_L: 3.08,
      p_m12_H: 4.94,
    },
    1382: {
      H: 3.88,
      L: 3.73,
      O: 3.88,
      C: 3.77,
      V: 3760000,
      p_w1_L: 3.73,
      p_w1_H: 4.16,
      p_m1_L: 3.73,
      p_m1_H: 4.35,
      p_m3_L: 3.73,
      p_m3_H: 4.35,
      p_m6_L: 3.73,
      p_m6_H: 5.78,
      p_m12_L: 3.73,
      p_m12_H: 5.79,
    },
    1383: {
      H: 0.154,
      L: 0.135,
      O: 0.135,
      C: 0.138,
      V: 16990000,
      p_w1_L: 0.105,
      p_w1_H: 0.19,
      p_m1_L: 0.105,
      p_m1_H: 0.305,
      p_m3_L: 0.105,
      p_m3_H: 0.34,
      p_m6_L: 0.105,
      p_m6_H: 0.495,
      p_m12_L: 0.105,
      p_m12_H: 0.86,
    },
    1385: {
      H: 27.7,
      L: 26.7,
      O: 27.2,
      C: 27.15,
      V: 2164000,
      p_w1_L: 25.5,
      p_w1_H: 28.95,
      p_m1_L: 20.8,
      p_m1_H: 29.8,
      p_m3_L: 18.24,
      p_m3_H: 29.8,
      p_m6_L: 13,
      p_m6_H: 33.2,
      p_m12_L: 9.5,
      p_m12_H: 33.2,
    },
    1386: {
      H: 0.455,
      L: 0.455,
      O: 0.455,
      C: 0.455,
      V: 0,
      p_w1_L: 0.455,
      p_w1_H: 0.455,
      p_m1_L: 0.455,
      p_m1_H: 0.455,
      p_m3_L: 0.455,
      p_m3_H: 0.455,
      p_m6_L: 0.42,
      p_m6_H: 0.5,
      p_m12_L: 0.395,
      p_m12_H: 0.73,
    },
    1387: {
      H: 2,
      L: 1.95,
      O: 1.95,
      C: 2,
      V: 1792000,
      p_w1_L: 1.91,
      p_w1_H: 2,
      p_m1_L: 1.89,
      p_m1_H: 2,
      p_m3_L: 1.86,
      p_m3_H: 2.15,
      p_m6_L: 1.86,
      p_m6_H: 2.19,
      p_m12_L: 1.69,
      p_m12_H: 3.08,
    },
    1388: {
      H: 1.02,
      L: 1.02,
      O: 1.02,
      C: 1.02,
      V: 100000,
      p_w1_L: 1.02,
      p_w1_H: 1.04,
      p_m1_L: 1.02,
      p_m1_H: 1.09,
      p_m3_L: 1,
      p_m3_H: 1.15,
      p_m6_L: 1,
      p_m6_H: 1.38,
      p_m12_L: 0.98,
      p_m12_H: 1.5,
    },
    1389: {
      H: 0.041,
      L: 0.038,
      O: 0.038,
      C: 0.041,
      V: 24000,
      p_w1_L: 0.037,
      p_w1_H: 0.042,
      p_m1_L: 0.037,
      p_m1_H: 0.048,
      p_m3_L: 0.035,
      p_m3_H: 0.049,
      p_m6_L: 0.031,
      p_m6_H: 0.049,
      p_m12_L: 0.031,
      p_m12_H: 0.055,
    },
    1393: {
      H: 0.415,
      L: 0.4,
      O: 0.41,
      C: 0.41,
      V: 857000,
      p_w1_L: 0.395,
      p_w1_H: 0.415,
      p_m1_L: 0.36,
      p_m1_H: 0.445,
      p_m3_L: 0.22,
      p_m3_H: 0.67,
      p_m6_L: 0.22,
      p_m6_H: 0.67,
      p_m12_L: 0.184,
      p_m12_H: 0.67,
    },
    1395: {
      H: 0.129,
      L: 0.129,
      O: 0.129,
      C: 0.129,
      V: 0,
      p_w1_L: 0.111,
      p_w1_H: 0.14,
      p_m1_L: 0.111,
      p_m1_H: 0.14,
      p_m3_L: 0.111,
      p_m3_H: 0.145,
      p_m6_L: 0.106,
      p_m6_H: 0.24,
      p_m12_L: 0.078,
      p_m12_H: 0.65,
    },
    1396: {
      H: 0.485,
      L: 0.435,
      O: 0.435,
      C: 0.47,
      V: 768000,
      p_w1_L: 0.41,
      p_w1_H: 0.485,
      p_m1_L: 0.365,
      p_m1_H: 0.485,
      p_m3_L: 0.365,
      p_m3_H: 0.53,
      p_m6_L: 0.365,
      p_m6_H: 0.55,
      p_m12_L: 0.365,
      p_m12_H: 0.57,
    },
    1397: {
      H: 0.53,
      L: 0.52,
      O: 0.52,
      C: 0.53,
      V: 244000,
      p_w1_L: 0.51,
      p_w1_H: 0.6,
      p_m1_L: 0.46,
      p_m1_H: 0.6,
      p_m3_L: 0.37,
      p_m3_H: 0.6,
      p_m6_L: 0.305,
      p_m6_H: 0.6,
      p_m12_L: 0.27,
      p_m12_H: 0.6,
    },
    1398: {
      H: 4.4,
      L: 4.29,
      O: 4.39,
      C: 4.31,
      V: 257763000,
      p_w1_L: 4.13,
      p_w1_H: 4.43,
      p_m1_L: 4.08,
      p_m1_H: 4.43,
      p_m3_L: 4.08,
      p_m3_H: 4.52,
      p_m6_L: 4.08,
      p_m6_H: 5.09,
      p_m12_L: 4.08,
      p_m12_H: 5.75,
    },
    1399: {
      H: 0.305,
      L: 0.29,
      O: 0.29,
      C: 0.305,
      V: 500000,
      p_w1_L: 0.28,
      p_w1_H: 0.305,
      p_m1_L: 0.265,
      p_m1_H: 0.315,
      p_m3_L: 0.25,
      p_m3_H: 0.32,
      p_m6_L: 0.22,
      p_m6_H: 0.32,
      p_m12_L: 0.217,
      p_m12_H: 0.32,
    },
    1400: {
      H: 0.061,
      L: 0.055,
      O: 0.058,
      C: 0.06,
      V: 2080000,
      p_w1_L: 0.055,
      p_w1_H: 0.075,
      p_m1_L: 0.04,
      p_m1_H: 0.076,
      p_m3_L: 0.04,
      p_m3_H: 0.076,
      p_m6_L: 0.04,
      p_m6_H: 0.119,
      p_m12_L: 0.04,
      p_m12_H: 0.48,
    },
    1401: {
      H: 0.71,
      L: 0.71,
      O: 0.71,
      C: 0.71,
      V: 0,
      p_w1_L: 0.7,
      p_w1_H: 0.74,
      p_m1_L: 0.7,
      p_m1_H: 0.76,
      p_m3_L: 0.67,
      p_m3_H: 0.94,
      p_m6_L: 0.65,
      p_m6_H: 0.94,
      p_m12_L: 0.49,
      p_m12_H: 1.01,
    },
    1402: {
      H: 0.43,
      L: 0.425,
      O: 0.43,
      C: 0.425,
      V: 260000,
      p_w1_L: 0.415,
      p_w1_H: 0.47,
      p_m1_L: 0.415,
      p_m1_H: 0.55,
      p_m3_L: 0.415,
      p_m3_H: 0.61,
      p_m6_L: 0.415,
      p_m6_H: 0.68,
      p_m12_L: 0.385,
      p_m12_H: 0.86,
    },
    1408: {
      H: 0.265,
      L: 0.255,
      O: 0.265,
      C: 0.255,
      V: 836000,
      p_w1_L: 0.255,
      p_w1_H: 0.285,
      p_m1_L: 0.255,
      p_m1_H: 0.33,
      p_m3_L: 0.255,
      p_m3_H: 0.37,
      p_m6_L: 0.255,
      p_m6_H: 0.415,
      p_m12_L: 0.255,
      p_m12_H: 0.64,
    },
    1410: {
      H: 0.67,
      L: 0.63,
      O: 0.67,
      C: 0.67,
      V: 1400000,
      p_w1_L: 0.61,
      p_w1_H: 0.72,
      p_m1_L: 0.61,
      p_m1_H: 0.72,
      p_m3_L: 0.6,
      p_m3_H: 0.72,
      p_m6_L: 0.55,
      p_m6_H: 0.74,
      p_m12_L: 0.495,
      p_m12_H: 0.74,
    },
    1412: {
      H: 1.24,
      L: 1.24,
      O: 1.24,
      C: 1.24,
      V: 0,
      p_w1_L: 1.23,
      p_w1_H: 1.25,
      p_m1_L: 1.22,
      p_m1_H: 1.29,
      p_m3_L: 1.2,
      p_m3_H: 1.29,
      p_m6_L: 1.18,
      p_m6_H: 1.41,
      p_m12_L: 0.88,
      p_m12_H: 1.5,
    },
    1413: {
      H: 0.31,
      L: 0.29,
      O: 0.29,
      C: 0.31,
      V: 5805000,
      p_w1_L: 0.248,
      p_w1_H: 0.31,
      p_m1_L: 0.24,
      p_m1_H: 0.31,
      p_m3_L: 0.24,
      p_m3_H: 0.355,
      p_m6_L: 0.235,
      p_m6_H: 0.355,
      p_m12_L: 0.235,
      p_m12_H: 0.52,
    },
    1415: {
      H: 11.5,
      L: 10.9,
      O: 11.08,
      C: 11.02,
      V: 1805000,
      p_w1_L: 10.38,
      p_w1_H: 11.72,
      p_m1_L: 6.38,
      p_m1_H: 11.84,
      p_m3_L: 4.5,
      p_m3_H: 11.84,
      p_m6_L: 4.07,
      p_m6_H: 11.84,
      p_m12_L: 4,
      p_m12_H: 11.84,
    },
    1416: {
      H: 0.249,
      L: 0.249,
      O: 0.249,
      C: 0.249,
      V: 0,
      p_w1_L: 0.249,
      p_w1_H: 0.249,
      p_m1_L: 0.249,
      p_m1_H: 0.249,
      p_m3_L: 0.249,
      p_m3_H: 0.249,
      p_m6_L: 0.249,
      p_m6_H: 0.249,
      p_m12_L: 0.2,
      p_m12_H: 0.49,
    },
    1417: {
      H: 1.74,
      L: 1.74,
      O: 1.74,
      C: 1.74,
      V: 0,
      p_w1_L: 1.74,
      p_w1_H: 1.83,
      p_m1_L: 1.65,
      p_m1_H: 1.88,
      p_m3_L: 1.6,
      p_m3_H: 2.12,
      p_m6_L: 1.6,
      p_m6_H: 2.14,
      p_m12_L: 1.51,
      p_m12_H: 2.14,
    },
    1418: {
      H: 0.29,
      L: 0.29,
      O: 0.29,
      C: 0.29,
      V: 0,
      p_w1_L: 0.25,
      p_w1_H: 0.305,
      p_m1_L: 0.214,
      p_m1_H: 0.305,
      p_m3_L: 0.212,
      p_m3_H: 0.35,
      p_m6_L: 0.16,
      p_m6_H: 0.4,
      p_m12_L: 0.089,
      p_m12_H: 0.4,
    },
    1419: {
      H: 1.25,
      L: 1.18,
      O: 1.25,
      C: 1.18,
      V: 358000,
      p_w1_L: 1.18,
      p_w1_H: 1.4,
      p_m1_L: 1.17,
      p_m1_H: 1.4,
      p_m3_L: 1.05,
      p_m3_H: 1.45,
      p_m6_L: 0.93,
      p_m6_H: 1.45,
      p_m12_L: 0.52,
      p_m12_H: 1.45,
    },
    1420: {
      H: 0.202,
      L: 0.201,
      O: 0.202,
      C: 0.201,
      V: 1092000,
      p_w1_L: 0.2,
      p_w1_H: 0.232,
      p_m1_L: 0.19,
      p_m1_H: 0.24,
      p_m3_L: 0.131,
      p_m3_H: 0.26,
      p_m6_L: 0.1,
      p_m6_H: 0.28,
      p_m12_L: 0.072,
      p_m12_H: 0.28,
    },
    1421: {
      H: 0.085,
      L: 0.081,
      O: 0.081,
      C: 0.084,
      V: 1355000,
      p_w1_L: 0.075,
      p_w1_H: 0.095,
      p_m1_L: 0.073,
      p_m1_H: 0.095,
      p_m3_L: 0.073,
      p_m3_H: 0.11,
      p_m6_L: 0.065,
      p_m6_H: 0.11,
      p_m12_L: 0.058,
      p_m12_H: 0.131,
    },
    1425: {
      H: 0.47,
      L: 0.455,
      O: 0.465,
      C: 0.455,
      V: 130000,
      p_w1_L: 0.45,
      p_w1_H: 0.485,
      p_m1_L: 0.42,
      p_m1_H: 0.49,
      p_m3_L: 0.42,
      p_m3_H: 0.56,
      p_m6_L: 0.42,
      p_m6_H: 0.8,
      p_m12_L: 0.355,
      p_m12_H: 0.8,
    },
    1426: {
      H: 2.74,
      L: 2.7,
      O: 2.7,
      C: 2.72,
      V: 29000,
      p_w1_L: 2.62,
      p_w1_H: 2.74,
      p_m1_L: 2.53,
      p_m1_H: 2.77,
      p_m3_L: 2.53,
      p_m3_H: 2.94,
      p_m6_L: 2.53,
      p_m6_H: 3,
      p_m12_L: 2.52,
      p_m12_H: 3,
    },
    1427: {
      H: 4,
      L: 3.76,
      O: 4,
      C: 3.89,
      V: 536000,
      p_w1_L: 3.76,
      p_w1_H: 4.3,
      p_m1_L: 3.39,
      p_m1_H: 4.44,
      p_m3_L: 3.13,
      p_m3_H: 4.44,
      p_m6_L: 2.75,
      p_m6_H: 4.44,
      p_m12_L: 2.75,
      p_m12_H: 4.44,
    },
    1428: {
      H: 1.5,
      L: 1.46,
      O: 1.5,
      C: 1.49,
      V: 1146000,
      p_w1_L: 1.44,
      p_w1_H: 1.51,
      p_m1_L: 1.44,
      p_m1_H: 1.64,
      p_m3_L: 1.39,
      p_m3_H: 1.71,
      p_m6_L: 1.39,
      p_m6_H: 2.3,
      p_m12_L: 1.39,
      p_m12_H: 2.6,
    },
    1429: {
      H: 0.127,
      L: 0.125,
      O: 0.127,
      C: 0.126,
      V: 1424000,
      p_w1_L: 0.125,
      p_w1_H: 0.138,
      p_m1_L: 0.125,
      p_m1_H: 0.154,
      p_m3_L: 0.125,
      p_m3_H: 0.168,
      p_m6_L: 0.125,
      p_m6_H: 0.17,
      p_m12_L: 0.125,
      p_m12_H: 0.224,
    },
    1430: {
      H: 2.42,
      L: 2.42,
      O: 2.42,
      C: 2.42,
      V: 0,
      p_w1_L: 2.42,
      p_w1_H: 2.42,
      p_m1_L: 2.42,
      p_m1_H: 2.42,
      p_m3_L: 2.31,
      p_m3_H: 2.43,
      p_m6_L: 1.9,
      p_m6_H: 2.43,
      p_m12_L: 1.77,
      p_m12_H: 2.43,
    },
    1431: {
      H: 0.295,
      L: 0.29,
      O: 0.29,
      C: 0.295,
      V: 216000,
      p_w1_L: 0.28,
      p_w1_H: 0.32,
      p_m1_L: 0.28,
      p_m1_H: 0.35,
      p_m3_L: 0.28,
      p_m3_H: 0.395,
      p_m6_L: 0.28,
      p_m6_H: 0.61,
      p_m12_L: 0.28,
      p_m12_H: 0.95,
    },
    1432: {
      H: 0.55,
      L: 0.53,
      O: 0.53,
      C: 0.54,
      V: 1871000,
      p_w1_L: 0.53,
      p_w1_H: 0.56,
      p_m1_L: 0.53,
      p_m1_H: 0.58,
      p_m3_L: 0.485,
      p_m3_H: 0.6,
      p_m6_L: 0.485,
      p_m6_H: 0.82,
      p_m12_L: 0.485,
      p_m12_H: 0.98,
    },
    1433: {
      H: 0.062,
      L: 0.062,
      O: 0.062,
      C: 0.062,
      V: 160000,
      p_w1_L: 0.06,
      p_w1_H: 0.067,
      p_m1_L: 0.06,
      p_m1_H: 0.079,
      p_m3_L: 0.052,
      p_m3_H: 0.079,
      p_m6_L: 0.045,
      p_m6_H: 0.079,
      p_m12_L: 0.045,
      p_m12_H: 0.079,
    },
    1439: {
      H: 0.041,
      L: 0.038,
      O: 0.042,
      C: 0.04,
      V: 245000,
      p_w1_L: 0.037,
      p_w1_H: 0.045,
      p_m1_L: 0.037,
      p_m1_H: 0.055,
      p_m3_L: 0.037,
      p_m3_H: 0.064,
      p_m6_L: 0.037,
      p_m6_H: 0.076,
      p_m12_L: 0.037,
      p_m12_H: 0.09,
    },
    1440: {
      H: 0.57,
      L: 0.57,
      O: 0.57,
      C: 0.57,
      V: 0,
      p_w1_L: 0.56,
      p_w1_H: 0.6,
      p_m1_L: 0.54,
      p_m1_H: 0.67,
      p_m3_L: 0.54,
      p_m3_H: 0.97,
      p_m6_L: 0.54,
      p_m6_H: 1.27,
      p_m12_L: 0.345,
      p_m12_H: 1.27,
    },
    1442: {
      H: 1.52,
      L: 1.48,
      O: 1.48,
      C: 1.51,
      V: 50000,
      p_w1_L: 1.46,
      p_w1_H: 1.54,
      p_m1_L: 1.46,
      p_m1_H: 1.79,
      p_m3_L: 1.39,
      p_m3_H: 1.83,
      p_m6_L: 0.54,
      p_m6_H: 1.83,
      p_m12_L: 0.34,
      p_m12_H: 1.83,
    },
    1443: {
      H: 0.24,
      L: 0.24,
      O: 0.24,
      C: 0.24,
      V: 334000,
      p_w1_L: 0.24,
      p_w1_H: 0.245,
      p_m1_L: 0.17,
      p_m1_H: 0.246,
      p_m3_L: 0.17,
      p_m3_H: 0.246,
      p_m6_L: 0.17,
      p_m6_H: 0.26,
      p_m12_L: 0.17,
      p_m12_H: 0.275,
    },
    1446: {
      H: 0.405,
      L: 0.405,
      O: 0.405,
      C: 0.405,
      V: 0,
      p_w1_L: 0.395,
      p_w1_H: 0.41,
      p_m1_L: 0.395,
      p_m1_H: 0.455,
      p_m3_L: 0.395,
      p_m3_H: 0.48,
      p_m6_L: 0.395,
      p_m6_H: 0.52,
      p_m12_L: 0.395,
      p_m12_H: 0.62,
    },
    1447: {
      H: 0.76,
      L: 0.76,
      O: 0.76,
      C: 0.76,
      V: 0,
      p_w1_L: 0.76,
      p_w1_H: 0.76,
      p_m1_L: 0.73,
      p_m1_H: 0.82,
      p_m3_L: 0.73,
      p_m3_H: 0.91,
      p_m6_L: 0.73,
      p_m6_H: 1.08,
      p_m12_L: 0.52,
      p_m12_H: 1.18,
    },
    1448: {
      H: 6.17,
      L: 6,
      O: 6.15,
      C: 6.04,
      V: 3498000,
      p_w1_L: 5.91,
      p_w1_H: 6.37,
      p_m1_L: 5.91,
      p_m1_H: 6.86,
      p_m3_L: 5.91,
      p_m3_H: 7.06,
      p_m6_L: 5.91,
      p_m6_H: 8.14,
      p_m12_L: 5.91,
      p_m12_H: 8.68,
    },
    1449: {
      H: 0.55,
      L: 0.5,
      O: 0.51,
      C: 0.5,
      V: 16806000,
      p_w1_L: 0.485,
      p_w1_H: 0.55,
      p_m1_L: 0.45,
      p_m1_H: 0.56,
      p_m3_L: 0.45,
      p_m3_H: 0.56,
      p_m6_L: 0.45,
      p_m6_H: 0.87,
      p_m12_L: 0.45,
      p_m12_H: 0.96,
    },
    1450: {
      H: 1.54,
      L: 1.49,
      O: 1.5,
      C: 1.54,
      V: 836000,
      p_w1_L: 1.49,
      p_w1_H: 1.66,
      p_m1_L: 1.47,
      p_m1_H: 1.76,
      p_m3_L: 1.32,
      p_m3_H: 2.08,
      p_m6_L: 0.175,
      p_m6_H: 2.08,
      p_m12_L: 0.076,
      p_m12_H: 2.08,
    },
    1451: {
      H: 0.58,
      L: 0.58,
      O: 0.58,
      C: 0.58,
      V: 0,
      p_w1_L: 0.51,
      p_w1_H: 0.59,
      p_m1_L: 0.51,
      p_m1_H: 0.63,
      p_m3_L: 0.51,
      p_m3_H: 0.64,
      p_m6_L: 0.51,
      p_m6_H: 0.69,
      p_m12_L: 0.445,
      p_m12_H: 0.69,
    },
    1452: {
      H: 0.18,
      L: 0.18,
      O: 0.18,
      C: 0.18,
      V: 0,
      p_w1_L: 0.179,
      p_w1_H: 0.18,
      p_m1_L: 0.173,
      p_m1_H: 0.185,
      p_m3_L: 0.173,
      p_m3_H: 0.3,
      p_m6_L: 0.173,
      p_m6_H: 0.32,
      p_m12_L: 0.135,
      p_m12_H: 0.45,
    },
    1455: {
      H: 0.238,
      L: 0.238,
      O: 0.238,
      C: 0.238,
      V: 0,
      p_w1_L: 0.23,
      p_w1_H: 0.248,
      p_m1_L: 0.23,
      p_m1_H: 0.28,
      p_m3_L: 0.23,
      p_m3_H: 0.29,
      p_m6_L: 0.221,
      p_m6_H: 0.39,
      p_m12_L: 0.18,
      p_m12_H: 0.39,
    },
    1456: {
      H: 4.05,
      L: 3.97,
      O: 3.98,
      C: 4.04,
      V: 971000,
      p_w1_L: 3.83,
      p_w1_H: 4.07,
      p_m1_L: 3.73,
      p_m1_H: 4.07,
      p_m3_L: 3.35,
      p_m3_H: 4.07,
      p_m6_L: 3.27,
      p_m6_H: 4.07,
      p_m12_L: 3.27,
      p_m12_H: 5.12,
    },
    1458: {
      H: 6.98,
      L: 6.69,
      O: 6.89,
      C: 6.76,
      V: 4314450,
      p_w1_L: 6.47,
      p_w1_H: 7.36,
      p_m1_L: 6.47,
      p_m1_H: 7.87,
      p_m3_L: 6.47,
      p_m3_H: 8.74,
      p_m6_L: 5.5,
      p_m6_H: 11.86,
      p_m12_L: 5.5,
      p_m12_H: 11.86,
    },
    1459: {
      H: 0.79,
      L: 0.79,
      O: 0.79,
      C: 0.79,
      V: 0,
      p_w1_L: 0.77,
      p_w1_H: 0.84,
      p_m1_L: 0.75,
      p_m1_H: 0.9,
      p_m3_L: 0.75,
      p_m3_H: 0.9,
      p_m6_L: 0.75,
      p_m6_H: 1,
      p_m12_L: 0.61,
      p_m12_H: 1,
    },
    1460: {
      H: 0.405,
      L: 0.305,
      O: 0.33,
      C: 0.405,
      V: 3849600,
      p_w1_L: 0.305,
      p_w1_H: 0.435,
      p_m1_L: 0.305,
      p_m1_H: 0.79,
      p_m3_L: 0.305,
      p_m3_H: 0.9,
      p_m6_L: 0.255,
      p_m6_H: 0.9,
      p_m12_L: 0.226,
      p_m12_H: 0.9,
    },
    1461: {
      H: 0.61,
      L: 0.6,
      O: 0.61,
      C: 0.6,
      V: 122000,
      p_w1_L: 0.59,
      p_w1_H: 0.64,
      p_m1_L: 0.57,
      p_m1_H: 0.65,
      p_m3_L: 0.56,
      p_m3_H: 0.69,
      p_m6_L: 0.49,
      p_m6_H: 0.69,
      p_m12_L: 0.49,
      p_m12_H: 0.77,
    },
    1463: {
      H: 4.08,
      L: 3.75,
      O: 3.86,
      C: 4,
      V: 776000,
      p_w1_L: 3.4,
      p_w1_H: 4.08,
      p_m1_L: 3.4,
      p_m1_H: 4.12,
      p_m3_L: 3.22,
      p_m3_H: 4.4,
      p_m6_L: 2.8,
      p_m6_H: 4.4,
      p_m12_L: 1.42,
      p_m12_H: 5.32,
    },
    1466: {
      H: 0.132,
      L: 0.132,
      O: 0.132,
      C: 0.132,
      V: 0,
      p_w1_L: 0.125,
      p_w1_H: 0.137,
      p_m1_L: 0.115,
      p_m1_H: 0.138,
      p_m3_L: 0.115,
      p_m3_H: 0.16,
      p_m6_L: 0.115,
      p_m6_H: 0.181,
      p_m12_L: 0.115,
      p_m12_H: 0.19,
    },
    1468: {
      H: 0.67,
      L: 0.65,
      O: 0.67,
      C: 0.67,
      V: 3340000,
      p_w1_L: 0.61,
      p_w1_H: 0.67,
      p_m1_L: 0.61,
      p_m1_H: 0.79,
      p_m3_L: 0.6,
      p_m3_H: 0.89,
      p_m6_L: 0.6,
      p_m6_H: 0.89,
      p_m12_L: 0.21,
      p_m12_H: 0.89,
    },
    1469: {
      H: 0.85,
      L: 0.83,
      O: 0.85,
      C: 0.83,
      V: 384500,
      p_w1_L: 0.8,
      p_w1_H: 0.86,
      p_m1_L: 0.74,
      p_m1_H: 0.89,
      p_m3_L: 0.7,
      p_m3_H: 0.89,
      p_m6_L: 0.7,
      p_m6_H: 0.95,
      p_m12_L: 0.65,
      p_m12_H: 1.2,
    },
    1470: {
      H: 0.101,
      L: 0.101,
      O: 0.101,
      C: 0.101,
      V: 124000,
      p_w1_L: 0.101,
      p_w1_H: 0.113,
      p_m1_L: 0.101,
      p_m1_H: 0.134,
      p_m3_L: 0.101,
      p_m3_H: 0.15,
      p_m6_L: 0.081,
      p_m6_H: 0.3,
      p_m12_L: 0.07,
      p_m12_H: 0.35,
    },
    1472: {
      H: 0.15,
      L: 0.149,
      O: 0.15,
      C: 0.149,
      V: 160000,
      p_w1_L: 0.147,
      p_w1_H: 0.15,
      p_m1_L: 0.147,
      p_m1_H: 0.17,
      p_m3_L: 0.147,
      p_m3_H: 0.184,
      p_m6_L: 0.147,
      p_m6_H: 0.385,
      p_m12_L: 0.147,
      p_m12_H: 1.16,
    },
    1473: {
      H: 0.315,
      L: 0.315,
      O: 0.315,
      C: 0.315,
      V: 124000,
      p_w1_L: 0.29,
      p_w1_H: 0.315,
      p_m1_L: 0.29,
      p_m1_H: 0.45,
      p_m3_L: 0.29,
      p_m3_H: 0.55,
      p_m6_L: 0.29,
      p_m6_H: 0.64,
      p_m12_L: 0.29,
      p_m12_H: 0.87,
    },
    1475: {
      H: 5.82,
      L: 5.68,
      O: 5.76,
      C: 5.78,
      V: 900000,
      p_w1_L: 5.51,
      p_w1_H: 5.82,
      p_m1_L: 5.42,
      p_m1_H: 5.9,
      p_m3_L: 5.42,
      p_m3_H: 6.1,
      p_m6_L: 4.96,
      p_m6_H: 6.4,
      p_m12_L: 4.96,
      p_m12_H: 7.61,
    },
    1476: {
      H: 2.97,
      L: 2.97,
      O: 2.97,
      C: 2.97,
      V: 0,
      p_w1_L: 2.97,
      p_w1_H: 2.97,
      p_m1_L: 2.97,
      p_m1_H: 3,
      p_m3_L: 2.96,
      p_m3_H: 3.11,
      p_m6_L: 2.56,
      p_m6_H: 3.2,
      p_m12_L: 2.02,
      p_m12_H: 3.25,
    },
    1477: {
      H: 16.6,
      L: 15.82,
      O: 15.82,
      C: 16.2,
      V: 229000,
      p_w1_L: 15.26,
      p_w1_H: 17.5,
      p_m1_L: 13.92,
      p_m1_H: 18.3,
      p_m3_L: 13.92,
      p_m3_H: 21.7,
      p_m6_L: 13.92,
      p_m6_H: 32.05,
      p_m12_L: 13.92,
      p_m12_H: 35,
    },
    1478: {
      H: 11.64,
      L: 11.28,
      O: 11.38,
      C: 11.46,
      V: 1792000,
      p_w1_L: 10.82,
      p_w1_H: 12.06,
      p_m1_L: 10.54,
      p_m1_H: 12.06,
      p_m3_L: 10.42,
      p_m3_H: 13.5,
      p_m6_L: 10.42,
      p_m6_H: 17.46,
      p_m12_L: 10.24,
      p_m12_H: 18.2,
    },
    1480: {
      H: 1.47,
      L: 1.45,
      O: 1.47,
      C: 1.47,
      V: 12000,
      p_w1_L: 1.42,
      p_w1_H: 1.76,
      p_m1_L: 1.32,
      p_m1_H: 1.76,
      p_m3_L: 1.3,
      p_m3_H: 1.76,
      p_m6_L: 1.3,
      p_m6_H: 1.82,
      p_m12_L: 1.3,
      p_m12_H: 2.1,
    },
    1481: {
      H: 0.89,
      L: 0.86,
      O: 0.89,
      C: 0.86,
      V: 150000,
      p_w1_L: 0.83,
      p_w1_H: 0.97,
      p_m1_L: 0.82,
      p_m1_H: 1.08,
      p_m3_L: 0.2,
      p_m3_H: 1.08,
      p_m6_L: 0.184,
      p_m6_H: 1.08,
      p_m12_L: 0.183,
      p_m12_H: 1.08,
    },
    1483: {
      H: 1.43,
      L: 1.43,
      O: 1.43,
      C: 1.43,
      V: 22000,
      p_w1_L: 1.23,
      p_w1_H: 1.5,
      p_m1_L: 1.23,
      p_m1_H: 1.5,
      p_m3_L: 1.09,
      p_m3_H: 1.58,
      p_m6_L: 1.07,
      p_m6_H: 1.66,
      p_m12_L: 0.92,
      p_m12_H: 2.75,
    },
    1486: {
      H: 1.01,
      L: 1.01,
      O: 1.01,
      C: 1.01,
      V: 0,
      p_w1_L: 1,
      p_w1_H: 1.01,
      p_m1_L: 0.94,
      p_m1_H: 1.08,
      p_m3_L: 0.92,
      p_m3_H: 1.25,
      p_m6_L: 0.92,
      p_m6_H: 1.25,
      p_m12_L: 0.68,
      p_m12_H: 1.52,
    },
    1488: {
      H: 0.95,
      L: 0.91,
      O: 0.95,
      C: 0.92,
      V: 26000,
      p_w1_L: 0.89,
      p_w1_H: 0.96,
      p_m1_L: 0.85,
      p_m1_H: 0.96,
      p_m3_L: 0.7,
      p_m3_H: 0.99,
      p_m6_L: 0.6,
      p_m6_H: 0.99,
      p_m12_L: 0.405,
      p_m12_H: 0.99,
    },
    1490: {
      H: 0.84,
      L: 0.75,
      O: 0.75,
      C: 0.83,
      V: 912000,
      p_w1_L: 0.69,
      p_w1_H: 0.84,
      p_m1_L: 0.66,
      p_m1_H: 1.05,
      p_m3_L: 0.445,
      p_m3_H: 1.05,
      p_m6_L: 0.445,
      p_m6_H: 1.38,
      p_m12_L: 0.445,
      p_m12_H: 1.83,
    },
    1495: {
      H: 1.15,
      L: 1.08,
      O: 1.15,
      C: 1.12,
      V: 1686000,
      p_w1_L: 1.06,
      p_w1_H: 1.18,
      p_m1_L: 1.06,
      p_m1_H: 1.2,
      p_m3_L: 0.63,
      p_m3_H: 1.3,
      p_m6_L: 0.56,
      p_m6_H: 1.3,
      p_m12_L: 0.56,
      p_m12_H: 1.3,
    },
    1496: {
      H: 0.163,
      L: 0.163,
      O: 0.163,
      C: 0.163,
      V: 0,
      p_w1_L: 0.163,
      p_w1_H: 0.163,
      p_m1_L: 0.152,
      p_m1_H: 0.165,
      p_m3_L: 0.143,
      p_m3_H: 0.185,
      p_m6_L: 0.128,
      p_m6_H: 0.185,
      p_m12_L: 0.116,
      p_m12_H: 0.21,
    },
    1498: {
      H: 0.78,
      L: 0.77,
      O: 0.78,
      C: 0.78,
      V: 21000,
      p_w1_L: 0.76,
      p_w1_H: 0.79,
      p_m1_L: 0.76,
      p_m1_H: 0.8,
      p_m3_L: 0.76,
      p_m3_H: 0.9,
      p_m6_L: 0.67,
      p_m6_H: 0.92,
      p_m12_L: 0.65,
      p_m12_H: 0.92,
    },
    1499: {
      H: 0.235,
      L: 0.225,
      O: 0.235,
      C: 0.228,
      V: 2490000,
      p_w1_L: 0.225,
      p_w1_H: 0.255,
      p_m1_L: 0.225,
      p_m1_H: 0.31,
      p_m3_L: 0.225,
      p_m3_H: 0.4,
      p_m6_L: 0.225,
      p_m6_H: 0.41,
      p_m12_L: 0.13,
      p_m12_H: 1.14,
    },
    1500: {
      H: 0.29,
      L: 0.285,
      O: 0.29,
      C: 0.285,
      V: 300000,
      p_w1_L: 0.285,
      p_w1_H: 0.31,
      p_m1_L: 0.22,
      p_m1_H: 0.38,
      p_m3_L: 0.202,
      p_m3_H: 0.38,
      p_m6_L: 0.184,
      p_m6_H: 0.38,
      p_m12_L: 0.121,
      p_m12_H: 0.38,
    },
    1501: {
      H: 22.5,
      L: 20,
      O: 22,
      C: 22.5,
      V: 106800,
      p_w1_L: 19.8,
      p_w1_H: 24.65,
      p_m1_L: 19.8,
      p_m1_H: 29.9,
      p_m3_L: 19.6,
      p_m3_H: 29.9,
      p_m6_L: 19.6,
      p_m6_H: 43.9,
      p_m12_L: 19.6,
      p_m12_H: 48.2,
    },
    1502: {
      H: 3.84,
      L: 3.69,
      O: 3.84,
      C: 3.83,
      V: 29000,
      p_w1_L: 3.69,
      p_w1_H: 4.2,
      p_m1_L: 3.69,
      p_m1_H: 4.39,
      p_m3_L: 3.68,
      p_m3_H: 4.81,
      p_m6_L: 3.6,
      p_m6_H: 5.89,
      p_m12_L: 3.6,
      p_m12_H: 7.1,
    },
    1503: {
      H: 2.61,
      L: 2.56,
      O: 2.59,
      C: 2.57,
      V: 1173000,
      p_w1_L: 2.56,
      p_w1_H: 2.63,
      p_m1_L: 2.55,
      p_m1_H: 2.78,
      p_m3_L: 2.55,
      p_m3_H: 2.96,
      p_m6_L: 2.55,
      p_m6_H: 3.04,
      p_m12_L: 2.37,
      p_m12_H: 3.04,
    },
    1508: {
      H: 0.76,
      L: 0.75,
      O: 0.76,
      C: 0.75,
      V: 7035440,
      p_w1_L: 0.72,
      p_w1_H: 0.76,
      p_m1_L: 0.72,
      p_m1_H: 0.79,
      p_m3_L: 0.72,
      p_m3_H: 0.93,
      p_m6_L: 0.72,
      p_m6_H: 0.93,
      p_m12_L: 0.72,
      p_m12_H: 0.95,
    },
    1513: {
      H: 26.1,
      L: 25.6,
      O: 26.05,
      C: 25.95,
      V: 507325,
      p_w1_L: 24.65,
      p_w1_H: 26.7,
      p_m1_L: 24.6,
      p_m1_H: 27.45,
      p_m3_L: 24.6,
      p_m3_H: 32.15,
      p_m6_L: 24.6,
      p_m6_H: 40.3,
      p_m12_L: 24.6,
      p_m12_H: 42,
    },
    1515: {
      H: 4.82,
      L: 4.64,
      O: 4.64,
      C: 4.73,
      V: 1304000,
      p_w1_L: 4.57,
      p_w1_H: 4.94,
      p_m1_L: 4.57,
      p_m1_H: 5.57,
      p_m3_L: 4.57,
      p_m3_H: 6.88,
      p_m6_L: 4.57,
      p_m6_H: 11.66,
      p_m12_L: 4.57,
      p_m12_H: 11.66,
    },
    1516: {
      H: 12.32,
      L: 11.4,
      O: 12.24,
      C: 11.66,
      V: 12755700,
      p_w1_L: 10.3,
      p_w1_H: 12.76,
      p_m1_L: 10.3,
      p_m1_H: 17.3,
      p_m3_L: 10.3,
      p_m3_H: 19.76,
      p_m6_L: 10.3,
      p_m6_H: 29.4,
      p_m12_L: 10.3,
      p_m12_H: 29.4,
    },
    1518: {
      H: 1.08,
      L: 0.98,
      O: 1.08,
      C: 0.98,
      V: 5500,
      p_w1_L: 0.85,
      p_w1_H: 1.13,
      p_m1_L: 0.83,
      p_m1_H: 1.13,
      p_m3_L: 0.83,
      p_m3_H: 1.14,
      p_m6_L: 0.83,
      p_m6_H: 1.45,
      p_m12_L: 0.83,
      p_m12_H: 2.2,
    },
    1520: {
      H: 0.155,
      L: 0.155,
      O: 0.155,
      C: 0.155,
      V: 90000,
      p_w1_L: 0.15,
      p_w1_H: 0.156,
      p_m1_L: 0.15,
      p_m1_H: 0.182,
      p_m3_L: 0.15,
      p_m3_H: 0.198,
      p_m6_L: 0.15,
      p_m6_H: 0.355,
      p_m12_L: 0.08,
      p_m12_H: 0.355,
    },
    1521: {
      H: 5.26,
      L: 4.89,
      O: 4.89,
      C: 4.91,
      V: 10066900,
      p_w1_L: 4.15,
      p_w1_H: 5.26,
      p_m1_L: 3.8,
      p_m1_H: 5.26,
      p_m3_L: 3.8,
      p_m3_H: 5.95,
      p_m6_L: 3.8,
      p_m6_H: 9.18,
      p_m12_L: 3.59,
      p_m12_H: 9.18,
    },
    1522: {
      H: 0.45,
      L: 0.435,
      O: 0.45,
      C: 0.445,
      V: 684000,
      p_w1_L: 0.42,
      p_w1_H: 0.465,
      p_m1_L: 0.42,
      p_m1_H: 0.61,
      p_m3_L: 0.42,
      p_m3_H: 0.66,
      p_m6_L: 0.39,
      p_m6_H: 0.66,
      p_m12_L: 0.39,
      p_m12_H: 0.78,
    },
    1523: {
      H: 4.14,
      L: 3.9,
      O: 4.08,
      C: 3.97,
      V: 1336000,
      p_w1_L: 3.9,
      p_w1_H: 4.68,
      p_m1_L: 2.75,
      p_m1_H: 4.68,
      p_m3_L: 2.3,
      p_m3_H: 4.68,
      p_m6_L: 1.6,
      p_m6_H: 4.68,
      p_m12_L: 0.89,
      p_m12_H: 4.68,
    },
    1525: {
      H: 5,
      L: 4.98,
      O: 5,
      C: 4.98,
      V: 186000,
      p_w1_L: 4.95,
      p_w1_H: 5.1,
      p_m1_L: 4.92,
      p_m1_H: 5.28,
      p_m3_L: 4.51,
      p_m3_H: 5.4,
      p_m6_L: 4.51,
      p_m6_H: 6.18,
      p_m12_L: 4.51,
      p_m12_H: 6.7,
    },
    1526: {
      H: 1.1,
      L: 1.1,
      O: 1.1,
      C: 1.1,
      V: 0,
      p_w1_L: 1.07,
      p_w1_H: 1.12,
      p_m1_L: 1,
      p_m1_H: 1.19,
      p_m3_L: 1,
      p_m3_H: 1.35,
      p_m6_L: 1,
      p_m6_H: 1.7,
      p_m12_L: 0.74,
      p_m12_H: 1.77,
    },
    1527: {
      H: 0.9,
      L: 0.9,
      O: 0.9,
      C: 0.9,
      V: 0,
      p_w1_L: 0.9,
      p_w1_H: 0.95,
      p_m1_L: 0.85,
      p_m1_H: 1.17,
      p_m3_L: 0.85,
      p_m3_H: 1.2,
      p_m6_L: 0.8,
      p_m6_H: 1.24,
      p_m12_L: 0.8,
      p_m12_H: 1.31,
    },
    1528: {
      H: 3.89,
      L: 3.82,
      O: 3.82,
      C: 3.87,
      V: 399060,
      p_w1_L: 3.77,
      p_w1_H: 4.03,
      p_m1_L: 3.7,
      p_m1_H: 4.15,
      p_m3_L: 3.7,
      p_m3_H: 5.09,
      p_m6_L: 3.7,
      p_m6_H: 6.2,
      p_m12_L: 3.7,
      p_m12_H: 6.2,
    },
    1529: {
      H: 0.241,
      L: 0.241,
      O: 0.241,
      C: 0.243,
      V: 10000,
      p_w1_L: 0.241,
      p_w1_H: 0.25,
      p_m1_L: 0.218,
      p_m1_H: 0.29,
      p_m3_L: 0.218,
      p_m3_H: 0.5,
      p_m6_L: 0.181,
      p_m6_H: 0.5,
      p_m12_L: 0.095,
      p_m12_H: 0.5,
    },
    1530: {
      H: 6.8,
      L: 6.63,
      O: 6.63,
      C: 6.76,
      V: 4700350,
      p_w1_L: 6.5,
      p_w1_H: 6.9,
      p_m1_L: 6.5,
      p_m1_H: 7.3,
      p_m3_L: 6.5,
      p_m3_H: 8.88,
      p_m6_L: 6.16,
      p_m6_H: 12.3,
      p_m12_L: 6.16,
      p_m12_H: 12.3,
    },
    1532: {
      H: 0.222,
      L: 0.203,
      O: 0.215,
      C: 0.21,
      V: 273000,
      p_w1_L: 0.202,
      p_w1_H: 0.234,
      p_m1_L: 0.196,
      p_m1_H: 0.244,
      p_m3_L: 0.164,
      p_m3_H: 0.35,
      p_m6_L: 0.156,
      p_m6_H: 0.35,
      p_m12_L: 0.112,
      p_m12_H: 0.35,
    },
    1533: {
      H: 7.26,
      L: 6.8,
      O: 7,
      C: 7.24,
      V: 91500,
      p_w1_L: 6.8,
      p_w1_H: 7.88,
      p_m1_L: 6.8,
      p_m1_H: 8.14,
      p_m3_L: 5.83,
      p_m3_H: 8.4,
      p_m6_L: 5.83,
      p_m6_H: 8.4,
      p_m12_L: 5.41,
      p_m12_H: 8.4,
    },
    1536: {
      H: 0.192,
      L: 0.192,
      O: 0.192,
      C: 0.192,
      V: 0,
      p_w1_L: 0.183,
      p_w1_H: 0.2,
      p_m1_L: 0.183,
      p_m1_H: 0.227,
      p_m3_L: 0.17,
      p_m3_H: 0.236,
      p_m6_L: 0.17,
      p_m6_H: 0.25,
      p_m12_L: 0.17,
      p_m12_H: 0.28,
    },
    1538: {
      H: 0.62,
      L: 0.62,
      O: 0.62,
      C: 0.62,
      V: 432000,
      p_w1_L: 0.55,
      p_w1_H: 0.66,
      p_m1_L: 0.55,
      p_m1_H: 0.72,
      p_m3_L: 0.55,
      p_m3_H: 0.8,
      p_m6_L: 0.55,
      p_m6_H: 0.84,
      p_m12_L: 0.55,
      p_m12_H: 1.02,
    },
    1539: {
      H: 0.68,
      L: 0.64,
      O: 0.67,
      C: 0.67,
      V: 9532000,
      p_w1_L: 0.6,
      p_w1_H: 0.7,
      p_m1_L: 0.445,
      p_m1_H: 0.74,
      p_m3_L: 0.325,
      p_m3_H: 0.74,
      p_m6_L: 0.325,
      p_m6_H: 0.74,
      p_m12_L: 0.102,
      p_m12_H: 0.74,
    },
    1540: {
      H: 0.64,
      L: 0.64,
      O: 0.64,
      C: 0.64,
      V: 3000,
      p_w1_L: 0.64,
      p_w1_H: 0.69,
      p_m1_L: 0.56,
      p_m1_H: 0.75,
      p_m3_L: 0.53,
      p_m3_H: 0.75,
      p_m6_L: 0.51,
      p_m6_H: 0.75,
      p_m12_L: 0.425,
      p_m12_H: 0.75,
    },
    1542: {
      H: 3.05,
      L: 3.05,
      O: 3.05,
      C: 3.05,
      V: 0,
      p_w1_L: 3.05,
      p_w1_H: 3.05,
      p_m1_L: 3.05,
      p_m1_H: 3.1,
      p_m3_L: 3.05,
      p_m3_H: 3.37,
      p_m6_L: 2.87,
      p_m6_H: 3.47,
      p_m12_L: 2.77,
      p_m12_H: 3.88,
    },
    1543: {
      H: 1.89,
      L: 1.89,
      O: 1.89,
      C: 1.89,
      V: 0,
      p_w1_L: 1.6,
      p_w1_H: 1.95,
      p_m1_L: 1.6,
      p_m1_H: 1.95,
      p_m3_L: 1.6,
      p_m3_H: 2,
      p_m6_L: 1.6,
      p_m6_H: 2.03,
      p_m12_L: 1.1,
      p_m12_H: 2.03,
    },
    1545: {
      H: 0.134,
      L: 0.13,
      O: 0.13,
      C: 0.134,
      V: 150000,
      p_w1_L: 0.118,
      p_w1_H: 0.134,
      p_m1_L: 0.118,
      p_m1_H: 0.149,
      p_m3_L: 0.118,
      p_m3_H: 0.155,
      p_m6_L: 0.118,
      p_m6_H: 0.215,
      p_m12_L: 0.095,
      p_m12_H: 0.215,
    },
    1546: {
      H: 0.2,
      L: 0.2,
      O: 0.2,
      C: 0.199,
      V: 16000,
      p_w1_L: 0.192,
      p_w1_H: 0.219,
      p_m1_L: 0.176,
      p_m1_H: 0.219,
      p_m3_L: 0.176,
      p_m3_H: 0.229,
      p_m6_L: 0.173,
      p_m6_H: 0.25,
      p_m12_L: 0.16,
      p_m12_H: 0.42,
    },
    1547: {
      H: 0.295,
      L: 0.285,
      O: 0.285,
      C: 0.295,
      V: 192000,
      p_w1_L: 0.28,
      p_w1_H: 0.3,
      p_m1_L: 0.27,
      p_m1_H: 0.31,
      p_m3_L: 0.235,
      p_m3_H: 0.335,
      p_m6_L: 0.235,
      p_m6_H: 0.35,
      p_m12_L: 0.228,
      p_m12_H: 0.35,
    },
    1548: {
      H: 40.15,
      L: 37.5,
      O: 37.5,
      C: 40,
      V: 8645200,
      p_w1_L: 35.4,
      p_w1_H: 40.15,
      p_m1_L: 26.9,
      p_m1_H: 42.5,
      p_m3_L: 26.9,
      p_m3_H: 42.5,
      p_m6_L: 26.9,
      p_m6_H: 43.4,
      p_m12_L: 9.66,
      p_m12_H: 43.4,
    },
    1549: {
      H: 0.134,
      L: 0.125,
      O: 0.131,
      C: 0.13,
      V: 4128000,
      p_w1_L: 0.125,
      p_w1_H: 0.149,
      p_m1_L: 0.122,
      p_m1_H: 0.183,
      p_m3_L: 0.122,
      p_m3_H: 0.31,
      p_m6_L: 0.122,
      p_m6_H: 0.51,
      p_m12_L: 0.06,
      p_m12_H: 0.51,
    },
    1551: {
      H: 3,
      L: 3,
      O: 3,
      C: 3.01,
      V: 10000,
      p_w1_L: 2.9,
      p_w1_H: 3.05,
      p_m1_L: 2.8,
      p_m1_H: 3.15,
      p_m3_L: 2.7,
      p_m3_H: 3.45,
      p_m6_L: 2.64,
      p_m6_H: 3.5,
      p_m12_L: 2.64,
      p_m12_H: 4.24,
    },
    1552: {
      H: 0.17,
      L: 0.17,
      O: 0.17,
      C: 0.17,
      V: 0,
      p_w1_L: 0.16,
      p_w1_H: 0.187,
      p_m1_L: 0.16,
      p_m1_H: 0.203,
      p_m3_L: 0.16,
      p_m3_H: 0.255,
      p_m6_L: 0.11,
      p_m6_H: 0.29,
      p_m12_L: 0.102,
      p_m12_H: 0.425,
    },
    1553: {
      H: 1.37,
      L: 1.34,
      O: 1.34,
      C: 1.35,
      V: 22000,
      p_w1_L: 1.29,
      p_w1_H: 1.51,
      p_m1_L: 1.29,
      p_m1_H: 1.66,
      p_m3_L: 1.29,
      p_m3_H: 1.68,
      p_m6_L: 1.29,
      p_m6_H: 1.86,
      p_m12_L: 1.21,
      p_m12_H: 1.86,
    },
    1555: {
      H: 0.065,
      L: 0.06,
      O: 0.058,
      C: 0.065,
      V: 12092000,
      p_w1_L: 0.056,
      p_w1_H: 0.065,
      p_m1_L: 0.054,
      p_m1_H: 0.072,
      p_m3_L: 0.047,
      p_m3_H: 0.1,
      p_m6_L: 0.04,
      p_m6_H: 0.1,
      p_m12_L: 0.04,
      p_m12_H: 0.149,
    },
    1556: {
      H: 0.199,
      L: 0.199,
      O: 0.199,
      C: 0.199,
      V: 5000,
      p_w1_L: 0.19,
      p_w1_H: 0.199,
      p_m1_L: 0.19,
      p_m1_H: 0.202,
      p_m3_L: 0.182,
      p_m3_H: 0.212,
      p_m6_L: 0.182,
      p_m6_H: 0.219,
      p_m12_L: 0.173,
      p_m12_H: 0.235,
    },
    1557: {
      H: 0.51,
      L: 0.485,
      O: 0.51,
      C: 0.485,
      V: 200000,
      p_w1_L: 0.42,
      p_w1_H: 0.51,
      p_m1_L: 0.38,
      p_m1_H: 0.51,
      p_m3_L: 0.38,
      p_m3_H: 0.7,
      p_m6_L: 0.38,
      p_m6_H: 1.02,
      p_m12_L: 0.35,
      p_m12_H: 1.02,
    },
    1558: {
      H: 5.64,
      L: 5.4,
      O: 5.48,
      C: 5.61,
      V: 2134200,
      p_w1_L: 5.01,
      p_w1_H: 5.78,
      p_m1_L: 4.04,
      p_m1_H: 6.09,
      p_m3_L: 3.35,
      p_m3_H: 6.09,
      p_m6_L: 3.35,
      p_m6_H: 9.18,
      p_m12_L: 3.35,
      p_m12_H: 10.6,
    },
    1559: {
      H: 0.185,
      L: 0.185,
      O: 0.185,
      C: 0.185,
      V: 0,
      p_w1_L: 0.185,
      p_w1_H: 0.185,
      p_m1_L: 0.184,
      p_m1_H: 0.21,
      p_m3_L: 0.177,
      p_m3_H: 0.24,
      p_m6_L: 0.177,
      p_m6_H: 0.38,
      p_m12_L: 0.177,
      p_m12_H: 0.445,
    },
    1560: {
      H: 0.45,
      L: 0.385,
      O: 0.41,
      C: 0.4,
      V: 10000,
      p_w1_L: 0.38,
      p_w1_H: 0.45,
      p_m1_L: 0.365,
      p_m1_H: 0.5,
      p_m3_L: 0.32,
      p_m3_H: 0.5,
      p_m6_L: 0.32,
      p_m6_H: 0.5,
      p_m12_L: 0.32,
      p_m12_H: 0.5,
    },
    1561: {
      H: 2,
      L: 1.94,
      O: 1.96,
      C: 1.94,
      V: 1252000,
      p_w1_L: 1.94,
      p_w1_H: 2.1,
      p_m1_L: 1.85,
      p_m1_H: 2.14,
      p_m3_L: 1.85,
      p_m3_H: 2.98,
      p_m6_L: 1.85,
      p_m6_H: 2.98,
      p_m12_L: 1.08,
      p_m12_H: 2.99,
    },
    1563: {
      H: 2.47,
      L: 2.45,
      O: 2.46,
      C: 2.47,
      V: 375000,
      p_w1_L: 2.24,
      p_w1_H: 2.47,
      p_m1_L: 2.15,
      p_m1_H: 2.5,
      p_m3_L: 2.15,
      p_m3_H: 2.67,
      p_m6_L: 2.1,
      p_m6_H: 3.02,
      p_m12_L: 0.65,
      p_m12_H: 3.02,
    },
    1565: {
      H: 0.46,
      L: 0.46,
      O: 0.46,
      C: 0.46,
      V: 0,
      p_w1_L: 0.46,
      p_w1_H: 0.46,
      p_m1_L: 0.4,
      p_m1_H: 0.55,
      p_m3_L: 0.4,
      p_m3_H: 0.61,
      p_m6_L: 0.4,
      p_m6_H: 1.27,
      p_m12_L: 0.4,
      p_m12_H: 2.44,
    },
    1566: {
      H: 0.61,
      L: 0.61,
      O: 0.61,
      C: 0.61,
      V: 0,
      p_w1_L: 0.61,
      p_w1_H: 0.61,
      p_m1_L: 0.61,
      p_m1_H: 0.61,
      p_m3_L: 0.375,
      p_m3_H: 3.25,
      p_m6_L: 0.375,
      p_m6_H: 3.41,
      p_m12_L: 0.375,
      p_m12_H: 3.43,
    },
    1568: {
      H: 0.54,
      L: 0.52,
      O: 0.54,
      C: 0.53,
      V: 2124000,
      p_w1_L: 0.48,
      p_w1_H: 0.85,
      p_m1_L: 0.48,
      p_m1_H: 0.93,
      p_m3_L: 0.48,
      p_m3_H: 1.3,
      p_m6_L: 0.48,
      p_m6_H: 1.7,
      p_m12_L: 0.48,
      p_m12_H: 2.39,
    },
    1569: {
      H: 1.09,
      L: 1.04,
      O: 1.04,
      C: 1.06,
      V: 7872000,
      p_w1_L: 1,
      p_w1_H: 1.1,
      p_m1_L: 1,
      p_m1_H: 1.19,
      p_m3_L: 1,
      p_m3_H: 1.3,
      p_m6_L: 0.99,
      p_m6_H: 1.45,
      p_m12_L: 0.99,
      p_m12_H: 1.85,
    },
    1570: {
      H: 4.03,
      L: 4.03,
      O: 4.03,
      C: 4.03,
      V: 0,
      p_w1_L: 4.03,
      p_w1_H: 4.04,
      p_m1_L: 4.03,
      p_m1_H: 4.05,
      p_m3_L: 4.03,
      p_m3_H: 4.2,
      p_m6_L: 4.03,
      p_m6_H: 4.2,
      p_m12_L: 3.04,
      p_m12_H: 4.2,
    },
    1571: {
      H: 2.91,
      L: 2.82,
      O: 2.82,
      C: 2.89,
      V: 42000,
      p_w1_L: 2.66,
      p_w1_H: 3,
      p_m1_L: 2.66,
      p_m1_H: 3.32,
      p_m3_L: 2.62,
      p_m3_H: 3.58,
      p_m6_L: 2.62,
      p_m6_H: 4.23,
      p_m12_L: 2.62,
      p_m12_H: 6.38,
    },
    1572: {
      H: 0.31,
      L: 0.295,
      O: 0.295,
      C: 0.3,
      V: 20000,
      p_w1_L: 0.29,
      p_w1_H: 0.315,
      p_m1_L: 0.228,
      p_m1_H: 0.395,
      p_m3_L: 0.228,
      p_m3_H: 0.395,
      p_m6_L: 0.155,
      p_m6_H: 0.5,
      p_m12_L: 0.082,
      p_m12_H: 0.5,
    },
    1575: {
      H: 0.178,
      L: 0.174,
      O: 0.174,
      C: 0.178,
      V: 4000,
      p_w1_L: 0.155,
      p_w1_H: 0.179,
      p_m1_L: 0.155,
      p_m1_H: 0.199,
      p_m3_L: 0.13,
      p_m3_H: 0.26,
      p_m6_L: 0.128,
      p_m6_H: 0.35,
      p_m12_L: 0.097,
      p_m12_H: 0.35,
    },
    1576: {
      H: 2.46,
      L: 2.38,
      O: 2.46,
      C: 2.42,
      V: 55000,
      p_w1_L: 2.35,
      p_w1_H: 2.6,
      p_m1_L: 2.3,
      p_m1_H: 2.6,
      p_m3_L: 2.2,
      p_m3_H: 2.6,
      p_m6_L: 2.06,
      p_m6_H: 2.6,
      p_m12_L: 1.7,
      p_m12_H: 2.6,
    },
    1577: {
      H: 1.18,
      L: 1.15,
      O: 1.15,
      C: 1.18,
      V: 48000,
      p_w1_L: 1.15,
      p_w1_H: 1.18,
      p_m1_L: 1.1,
      p_m1_H: 1.18,
      p_m3_L: 1.05,
      p_m3_H: 1.2,
      p_m6_L: 1.05,
      p_m6_H: 1.2,
      p_m12_L: 1.05,
      p_m12_H: 1.24,
    },
    1578: {
      H: 3.12,
      L: 3.12,
      O: 3.12,
      C: 3.12,
      V: 3500,
      p_w1_L: 2.93,
      p_w1_H: 3.57,
      p_m1_L: 2.93,
      p_m1_H: 3.57,
      p_m3_L: 2.91,
      p_m3_H: 3.57,
      p_m6_L: 2.91,
      p_m6_H: 3.66,
      p_m12_L: 2.85,
      p_m12_H: 3.66,
    },
    1579: {
      H: 44.5,
      L: 42,
      O: 43.05,
      C: 44,
      V: 2662200,
      p_w1_L: 40.2,
      p_w1_H: 44.95,
      p_m1_L: 40.2,
      p_m1_H: 51.15,
      p_m3_L: 37.15,
      p_m3_H: 53.5,
      p_m6_L: 37.15,
      p_m6_H: 59.5,
      p_m12_L: 37.15,
      p_m12_H: 148.6,
    },
    1580: {
      H: 0.11,
      L: 0.11,
      O: 0.11,
      C: 0.11,
      V: 20000,
      p_w1_L: 0.106,
      p_w1_H: 0.114,
      p_m1_L: 0.105,
      p_m1_H: 0.13,
      p_m3_L: 0.105,
      p_m3_H: 0.144,
      p_m6_L: 0.1,
      p_m6_H: 0.145,
      p_m12_L: 0.1,
      p_m12_H: 0.295,
    },
    1581: {
      H: 0.154,
      L: 0.154,
      O: 0.154,
      C: 0.154,
      V: 0,
      p_w1_L: 0.148,
      p_w1_H: 0.156,
      p_m1_L: 0.136,
      p_m1_H: 0.163,
      p_m3_L: 0.125,
      p_m3_H: 0.179,
      p_m6_L: 0.091,
      p_m6_H: 0.179,
      p_m12_L: 0.075,
      p_m12_H: 0.24,
    },
    1582: {
      H: 0.51,
      L: 0.51,
      O: 0.51,
      C: 0.51,
      V: 20000,
      p_w1_L: 0.51,
      p_w1_H: 0.53,
      p_m1_L: 0.49,
      p_m1_H: 0.54,
      p_m3_L: 0.49,
      p_m3_H: 0.58,
      p_m6_L: 0.49,
      p_m6_H: 0.63,
      p_m12_L: 0.49,
      p_m12_H: 0.66,
    },
    1583: {
      H: 2,
      L: 2,
      O: 2,
      C: 2,
      V: 0,
      p_w1_L: 2,
      p_w1_H: 2.05,
      p_m1_L: 2,
      p_m1_H: 2.2,
      p_m3_L: 2,
      p_m3_H: 2.3,
      p_m6_L: 2,
      p_m6_H: 2.32,
      p_m12_L: 2,
      p_m12_H: 2.5,
    },
    1585: {
      H: 14.08,
      L: 13.72,
      O: 14.02,
      C: 13.86,
      V: 2952180,
      p_w1_L: 13.5,
      p_w1_H: 14.28,
      p_m1_L: 13,
      p_m1_H: 14.46,
      p_m3_L: 11.56,
      p_m3_H: 14.46,
      p_m6_L: 11.56,
      p_m6_H: 18,
      p_m12_L: 11.56,
      p_m12_H: 24,
    },
    1586: {
      H: 1.7,
      L: 1.64,
      O: 1.64,
      C: 1.7,
      V: 36000,
      p_w1_L: 1.64,
      p_w1_H: 1.75,
      p_m1_L: 1.64,
      p_m1_H: 1.91,
      p_m3_L: 1.64,
      p_m3_H: 2.08,
      p_m6_L: 1.64,
      p_m6_H: 2.49,
      p_m12_L: 1.03,
      p_m12_H: 2.49,
    },
    1587: {
      H: 0.67,
      L: 0.64,
      O: 0.65,
      C: 0.67,
      V: 1088000,
      p_w1_L: 0.64,
      p_w1_H: 0.68,
      p_m1_L: 0.64,
      p_m1_H: 0.71,
      p_m3_L: 0.415,
      p_m3_H: 0.72,
      p_m6_L: 0.35,
      p_m6_H: 0.72,
      p_m12_L: 0.27,
      p_m12_H: 0.81,
    },
    1588: {
      H: 9.03,
      L: 8.5,
      O: 9.03,
      C: 8.77,
      V: 38400,
      p_w1_L: 8.5,
      p_w1_H: 9.09,
      p_m1_L: 8.5,
      p_m1_H: 10.9,
      p_m3_L: 8.5,
      p_m3_H: 12.4,
      p_m6_L: 8.5,
      p_m6_H: 21.5,
      p_m12_L: 8.5,
      p_m12_H: 36.2,
    },
    1589: {
      H: 4.27,
      L: 4.23,
      O: 4.26,
      C: 4.26,
      V: 2288430,
      p_w1_L: 4.2,
      p_w1_H: 4.3,
      p_m1_L: 4.17,
      p_m1_H: 4.3,
      p_m3_L: 3.91,
      p_m3_H: 4.3,
      p_m6_L: 3.17,
      p_m6_H: 4.35,
      p_m12_L: 3.17,
      p_m12_H: 4.98,
    },
    1591: {
      H: 0.024,
      L: 0.023,
      O: 0.023,
      C: 0.024,
      V: 9440000,
      p_w1_L: 0.023,
      p_w1_H: 0.028,
      p_m1_L: 0.023,
      p_m1_H: 0.033,
      p_m3_L: 0.023,
      p_m3_H: 0.04,
      p_m6_L: 0.023,
      p_m6_H: 0.069,
      p_m12_L: 0.017,
      p_m12_H: 0.132,
    },
    1592: {
      H: 1.75,
      L: 1.67,
      O: 1.71,
      C: 1.75,
      V: 2395000,
      p_w1_L: 1.5,
      p_w1_H: 1.88,
      p_m1_L: 1.5,
      p_m1_H: 2,
      p_m3_L: 1.49,
      p_m3_H: 2.26,
      p_m6_L: 0.93,
      p_m6_H: 2.87,
      p_m12_L: 0.098,
      p_m12_H: 2.87,
    },
    1593: {
      H: 2.24,
      L: 2.19,
      O: 2.24,
      C: 2.23,
      V: 1944000,
      p_w1_L: 2.03,
      p_w1_H: 2.26,
      p_m1_L: 2.03,
      p_m1_H: 2.3,
      p_m3_L: 2.03,
      p_m3_H: 2.42,
      p_m6_L: 2.03,
      p_m6_H: 2.75,
      p_m12_L: 1.9,
      p_m12_H: 3.44,
    },
    1596: {
      H: 4.3,
      L: 4.14,
      O: 4.18,
      C: 4.13,
      V: 124000,
      p_w1_L: 4.06,
      p_w1_H: 4.4,
      p_m1_L: 4.06,
      p_m1_H: 4.67,
      p_m3_L: 3.82,
      p_m3_H: 5.01,
      p_m6_L: 3.61,
      p_m6_H: 5.01,
      p_m12_L: 3.6,
      p_m12_H: 5.1,
    },
    1597: {
      H: 2,
      L: 1.91,
      O: 1.91,
      C: 2,
      V: 68000,
      p_w1_L: 1.91,
      p_w1_H: 2.08,
      p_m1_L: 1.91,
      p_m1_H: 2.08,
      p_m3_L: 1.72,
      p_m3_H: 2.5,
      p_m6_L: 1.31,
      p_m6_H: 2.5,
      p_m12_L: 1.2,
      p_m12_H: 2.5,
    },
    1598: {
      H: 0.425,
      L: 0.405,
      O: 0.425,
      C: 0.42,
      V: 2520000,
      p_w1_L: 0.405,
      p_w1_H: 0.485,
      p_m1_L: 0.405,
      p_m1_H: 0.53,
      p_m3_L: 0.405,
      p_m3_H: 0.56,
      p_m6_L: 0.28,
      p_m6_H: 0.65,
      p_m12_L: 0.28,
      p_m12_H: 0.83,
    },
    1599: {
      H: 2.08,
      L: 2.02,
      O: 2.04,
      C: 2.07,
      V: 1074000,
      p_w1_L: 1.97,
      p_w1_H: 2.08,
      p_m1_L: 1.96,
      p_m1_H: 2.09,
      p_m3_L: 1.96,
      p_m3_H: 2.61,
      p_m6_L: 1.8,
      p_m6_H: 2.61,
      p_m12_L: 1.8,
      p_m12_H: 2.61,
    },
    1600: {
      H: 7.98,
      L: 7.83,
      O: 7.83,
      C: 7.97,
      V: 1690500,
      p_w1_L: 7.45,
      p_w1_H: 7.98,
      p_m1_L: 6.8,
      p_m1_H: 7.98,
      p_m3_L: 6.49,
      p_m3_H: 8.06,
      p_m6_L: 6.49,
      p_m6_H: 8.85,
      p_m12_L: 6.08,
      p_m12_H: 8.85,
    },
    1601: {
      H: 0.82,
      L: 0.78,
      O: 0.82,
      C: 0.81,
      V: 44000,
      p_w1_L: 0.76,
      p_w1_H: 0.85,
      p_m1_L: 0.76,
      p_m1_H: 0.92,
      p_m3_L: 0.76,
      p_m3_H: 1.26,
      p_m6_L: 0.76,
      p_m6_H: 1.26,
      p_m12_L: 0.76,
      p_m12_H: 1.3,
    },
    1606: {
      H: 1.07,
      L: 1.06,
      O: 1.07,
      C: 1.07,
      V: 408000,
      p_w1_L: 1.04,
      p_w1_H: 1.07,
      p_m1_L: 1.04,
      p_m1_H: 1.12,
      p_m3_L: 1.04,
      p_m3_H: 1.17,
      p_m6_L: 1.02,
      p_m6_H: 1.22,
      p_m12_L: 1.02,
      p_m12_H: 1.29,
    },
    1608: {
      H: 1.29,
      L: 1.24,
      O: 1.26,
      C: 1.28,
      V: 4672000,
      p_w1_L: 1.16,
      p_w1_H: 1.36,
      p_m1_L: 1.14,
      p_m1_H: 1.49,
      p_m3_L: 1.14,
      p_m3_H: 1.84,
      p_m6_L: 1.14,
      p_m6_H: 2.11,
      p_m12_L: 1.14,
      p_m12_H: 2.95,
    },
    1609: {
      H: 0.78,
      L: 0.62,
      O: 0.63,
      C: 0.68,
      V: 658000,
      p_w1_L: 0.59,
      p_w1_H: 0.78,
      p_m1_L: 0.48,
      p_m1_H: 0.78,
      p_m3_L: 0.475,
      p_m3_H: 0.78,
      p_m6_L: 0.475,
      p_m6_H: 1.93,
      p_m12_L: 0.475,
      p_m12_H: 9.45,
    },
    1610: {
      H: 2.68,
      L: 2.6,
      O: 2.67,
      C: 2.65,
      V: 24892000,
      p_w1_L: 2.53,
      p_w1_H: 2.7,
      p_m1_L: 2.53,
      p_m1_H: 3.17,
      p_m3_L: 2.21,
      p_m3_H: 3.2,
      p_m6_L: 2.11,
      p_m6_H: 3.39,
      p_m12_L: 2.11,
      p_m12_H: 6.38,
    },
    1611: {
      H: 7.5,
      L: 7.1,
      O: 7.5,
      C: 7.13,
      V: 546500,
      p_w1_L: 7.1,
      p_w1_H: 8.4,
      p_m1_L: 7.1,
      p_m1_H: 9.94,
      p_m3_L: 6.7,
      p_m3_H: 13.3,
      p_m6_L: 6.7,
      p_m6_H: 16.66,
      p_m12_L: 3.3,
      p_m12_H: 29.55,
    },
    1612: {
      H: 1.06,
      L: 1.03,
      O: 1.05,
      C: 1.04,
      V: 642000,
      p_w1_L: 1.01,
      p_w1_H: 1.3,
      p_m1_L: 0.84,
      p_m1_H: 1.49,
      p_m3_L: 0.84,
      p_m3_H: 1.49,
      p_m6_L: 0.84,
      p_m6_H: 2.1,
      p_m12_L: 0.84,
      p_m12_H: 2.69,
    },
    1613: {
      H: 0.194,
      L: 0.19,
      O: 0.194,
      C: 0.191,
      V: 115200,
      p_w1_L: 0.172,
      p_w1_H: 0.218,
      p_m1_L: 0.172,
      p_m1_H: 0.24,
      p_m3_L: 0.165,
      p_m3_H: 0.75,
      p_m6_L: 0.165,
      p_m6_H: 0.8,
      p_m12_L: 0.165,
      p_m12_H: 0.8,
    },
    1615: {
      H: 0.27,
      L: 0.27,
      O: 0.27,
      C: 0.27,
      V: 235000,
      p_w1_L: 0.255,
      p_w1_H: 0.285,
      p_m1_L: 0.25,
      p_m1_H: 0.33,
      p_m3_L: 0.25,
      p_m3_H: 0.33,
      p_m6_L: 0.216,
      p_m6_H: 0.33,
      p_m12_L: 0.197,
      p_m12_H: 0.33,
    },
    1616: {
      H: 0.198,
      L: 0.16,
      O: 0.198,
      C: 0.16,
      V: 28000,
      p_w1_L: 0.155,
      p_w1_H: 0.198,
      p_m1_L: 0.12,
      p_m1_H: 0.2,
      p_m3_L: 0.117,
      p_m3_H: 0.2,
      p_m6_L: 0.1,
      p_m6_H: 0.2,
      p_m12_L: 0.1,
      p_m12_H: 0.2,
    },
    1617: {
      H: 0.189,
      L: 0.177,
      O: 0.178,
      C: 0.185,
      V: 3080000,
      p_w1_L: 0.16,
      p_w1_H: 0.194,
      p_m1_L: 0.128,
      p_m1_H: 0.204,
      p_m3_L: 0.124,
      p_m3_H: 0.26,
      p_m6_L: 0.124,
      p_m6_H: 0.365,
      p_m12_L: 0.124,
      p_m12_H: 0.81,
    },
    1618: {
      H: 2.2,
      L: 2.14,
      O: 2.17,
      C: 2.17,
      V: 9160000,
      p_w1_L: 1.94,
      p_w1_H: 2.26,
      p_m1_L: 1.88,
      p_m1_H: 2.26,
      p_m3_L: 1.86,
      p_m3_H: 3.42,
      p_m6_L: 1.71,
      p_m6_H: 3.42,
      p_m12_L: 1.3,
      p_m12_H: 3.42,
    },
    1620: {
      H: 0.206,
      L: 0.183,
      O: 0.183,
      C: 0.195,
      V: 530000,
      p_w1_L: 0.181,
      p_w1_H: 0.206,
      p_m1_L: 0.142,
      p_m1_H: 0.255,
      p_m3_L: 0.138,
      p_m3_H: 0.255,
      p_m6_L: 0.138,
      p_m6_H: 0.37,
      p_m12_L: 0.134,
      p_m12_H: 0.37,
    },
    1621: {
      H: 0.143,
      L: 0.143,
      O: 0.143,
      C: 0.14,
      V: 16000,
      p_w1_L: 0.119,
      p_w1_H: 0.15,
      p_m1_L: 0.109,
      p_m1_H: 0.15,
      p_m3_L: 0.109,
      p_m3_H: 0.15,
      p_m6_L: 0.109,
      p_m6_H: 0.157,
      p_m12_L: 0.078,
      p_m12_H: 0.16,
    },
    1622: {
      H: 2.56,
      L: 2.47,
      O: 2.5,
      C: 2.51,
      V: 5380000,
      p_w1_L: 2.17,
      p_w1_H: 2.56,
      p_m1_L: 2.15,
      p_m1_H: 2.56,
      p_m3_L: 2.04,
      p_m3_H: 3.03,
      p_m6_L: 1.76,
      p_m6_H: 3.03,
      p_m12_L: 1.76,
      p_m12_H: 3.43,
    },
    1623: {
      H: 0.325,
      L: 0.31,
      O: 0.325,
      C: 0.32,
      V: 32000,
      p_w1_L: 0.305,
      p_w1_H: 0.34,
      p_m1_L: 0.3,
      p_m1_H: 0.35,
      p_m3_L: 0.206,
      p_m3_H: 0.425,
      p_m6_L: 0.2,
      p_m6_H: 0.425,
      p_m12_L: 0.2,
      p_m12_H: 0.445,
    },
    1626: {
      H: 1.77,
      L: 1.77,
      O: 1.77,
      C: 1.77,
      V: 0,
      p_w1_L: 1.77,
      p_w1_H: 1.77,
      p_m1_L: 1.75,
      p_m1_H: 1.79,
      p_m3_L: 1.64,
      p_m3_H: 1.79,
      p_m6_L: 1.1,
      p_m6_H: 1.79,
      p_m12_L: 0.52,
      p_m12_H: 1.79,
    },
    1627: {
      H: 0.415,
      L: 0.415,
      O: 0.415,
      C: 0.42,
      V: 8000,
      p_w1_L: 0.4,
      p_w1_H: 0.445,
      p_m1_L: 0.39,
      p_m1_H: 0.445,
      p_m3_L: 0.36,
      p_m3_H: 0.495,
      p_m6_L: 0.36,
      p_m6_H: 0.495,
      p_m12_L: 0.36,
      p_m12_H: 0.495,
    },
    1628: {
      H: 0.77,
      L: 0.74,
      O: 0.76,
      C: 0.74,
      V: 3614970,
      p_w1_L: 0.7,
      p_w1_H: 0.81,
      p_m1_L: 0.7,
      p_m1_H: 0.92,
      p_m3_L: 0.7,
      p_m3_H: 1.42,
      p_m6_L: 0.7,
      p_m6_H: 2.45,
      p_m12_L: 0.7,
      p_m12_H: 3.2,
    },
    1629: {
      H: 0.62,
      L: 0.62,
      O: 0.62,
      C: 0.62,
      V: 52000,
      p_w1_L: 0.62,
      p_w1_H: 0.7,
      p_m1_L: 0.6,
      p_m1_H: 0.72,
      p_m3_L: 0.6,
      p_m3_H: 1.23,
      p_m6_L: 0.6,
      p_m6_H: 4.72,
      p_m12_L: 0.6,
      p_m12_H: 4.72,
    },
    1630: {
      H: 0.128,
      L: 0.117,
      O: 0.123,
      C: 0.121,
      V: 90000,
      p_w1_L: 0.112,
      p_w1_H: 0.133,
      p_m1_L: 0.11,
      p_m1_H: 0.141,
      p_m3_L: 0.11,
      p_m3_H: 0.141,
      p_m6_L: 0.082,
      p_m6_H: 0.141,
      p_m12_L: 0.063,
      p_m12_H: 0.208,
    },
    1631: {
      H: 0.78,
      L: 0.73,
      O: 0.73,
      C: 0.78,
      V: 10000,
      p_w1_L: 0.73,
      p_w1_H: 0.82,
      p_m1_L: 0.73,
      p_m1_H: 0.84,
      p_m3_L: 0.66,
      p_m3_H: 0.84,
      p_m6_L: 0.52,
      p_m6_H: 0.84,
      p_m12_L: 0.43,
      p_m12_H: 0.99,
    },
    1632: {
      H: 0.54,
      L: 0.53,
      O: 0.54,
      C: 0.53,
      V: 710000,
      p_w1_L: 0.48,
      p_w1_H: 0.54,
      p_m1_L: 0.47,
      p_m1_H: 0.65,
      p_m3_L: 0.435,
      p_m3_H: 0.65,
      p_m6_L: 0.435,
      p_m6_H: 0.79,
      p_m12_L: 0.41,
      p_m12_H: 0.95,
    },
    1633: {
      H: 0.176,
      L: 0.15,
      O: 0.176,
      C: 0.162,
      V: 54000,
      p_w1_L: 0.15,
      p_w1_H: 0.195,
      p_m1_L: 0.15,
      p_m1_H: 0.201,
      p_m3_L: 0.15,
      p_m3_H: 0.27,
      p_m6_L: 0.128,
      p_m6_H: 0.27,
      p_m12_L: 0.101,
      p_m12_H: 0.27,
    },
    1635: {
      H: 1.68,
      L: 1.67,
      O: 1.67,
      C: 1.68,
      V: 569000,
      p_w1_L: 1.66,
      p_w1_H: 1.69,
      p_m1_L: 1.66,
      p_m1_H: 1.83,
      p_m3_L: 1.66,
      p_m3_H: 1.99,
      p_m6_L: 1.64,
      p_m6_H: 2.15,
      p_m12_L: 1.64,
      p_m12_H: 2.32,
    },
    1636: {
      H: 0.174,
      L: 0.174,
      O: 0.174,
      C: 0.174,
      V: 0,
      p_w1_L: 0.173,
      p_w1_H: 0.199,
      p_m1_L: 0.172,
      p_m1_H: 0.227,
      p_m3_L: 0.172,
      p_m3_H: 0.255,
      p_m6_L: 0.172,
      p_m6_H: 0.38,
      p_m12_L: 0.118,
      p_m12_H: 0.445,
    },
    1637: {
      H: 0.58,
      L: 0.54,
      O: 0.54,
      C: 0.58,
      V: 414000,
      p_w1_L: 0.54,
      p_w1_H: 0.59,
      p_m1_L: 0.52,
      p_m1_H: 0.61,
      p_m3_L: 0.48,
      p_m3_H: 0.64,
      p_m6_L: 0.47,
      p_m6_H: 0.64,
      p_m12_L: 0.33,
      p_m12_H: 0.64,
    },
    1638: {
      H: 0.92,
      L: 0.92,
      O: 0.92,
      C: 0.92,
      V: 0,
      p_w1_L: 0.89,
      p_w1_H: 1.05,
      p_m1_L: 0.89,
      p_m1_H: 1.25,
      p_m3_L: 0.89,
      p_m3_H: 2.43,
      p_m6_L: 0.89,
      p_m6_H: 3.41,
      p_m12_L: 0.89,
      p_m12_H: 4.44,
    },
    1639: {
      H: 1.57,
      L: 1.5,
      O: 1.51,
      C: 1.56,
      V: 3847500,
      p_w1_L: 1.43,
      p_w1_H: 1.81,
      p_m1_L: 1.18,
      p_m1_H: 1.82,
      p_m3_L: 0.91,
      p_m3_H: 1.82,
      p_m6_L: 0.8,
      p_m6_H: 1.82,
      p_m12_L: 0.8,
      p_m12_H: 1.82,
    },
    1640: {
      H: 0.42,
      L: 0.42,
      O: 0.42,
      C: 0.42,
      V: 0,
      p_w1_L: 0.4,
      p_w1_H: 0.48,
      p_m1_L: 0.4,
      p_m1_H: 0.54,
      p_m3_L: 0.4,
      p_m3_H: 0.59,
      p_m6_L: 0.4,
      p_m6_H: 0.76,
      p_m12_L: 0.21,
      p_m12_H: 0.89,
    },
    1643: {
      H: 0.65,
      L: 0.6,
      O: 0.65,
      C: 0.63,
      V: 909000,
      p_w1_L: 0.59,
      p_w1_H: 0.65,
      p_m1_L: 0.59,
      p_m1_H: 0.74,
      p_m3_L: 0.59,
      p_m3_H: 0.85,
      p_m6_L: 0.59,
      p_m6_H: 1.03,
      p_m12_L: 0.59,
      p_m12_H: 1.4,
    },
    1645: {
      H: 0.83,
      L: 0.77,
      O: 0.81,
      C: 0.83,
      V: 9808000,
      p_w1_L: 0.75,
      p_w1_H: 0.87,
      p_m1_L: 0.75,
      p_m1_H: 1.08,
      p_m3_L: 0.72,
      p_m3_H: 1.25,
      p_m6_L: 0.72,
      p_m6_H: 1.34,
      p_m12_L: 0.55,
      p_m12_H: 1.37,
    },
    1647: {
      H: 0.205,
      L: 0.201,
      O: 0.203,
      C: 0.201,
      V: 180000,
      p_w1_L: 0.196,
      p_w1_H: 0.211,
      p_m1_L: 0.196,
      p_m1_H: 0.236,
      p_m3_L: 0.196,
      p_m3_H: 0.28,
      p_m6_L: 0.196,
      p_m6_H: 0.355,
      p_m12_L: 0.195,
      p_m12_H: 0.98,
    },
    1650: {
      H: 0.078,
      L: 0.078,
      O: 0.078,
      C: 0.078,
      V: 0,
      p_w1_L: 0.078,
      p_w1_H: 0.078,
      p_m1_L: 0.078,
      p_m1_H: 0.078,
      p_m3_L: 0.078,
      p_m3_H: 0.078,
      p_m6_L: 0.078,
      p_m6_H: 0.078,
      p_m12_L: 0.075,
      p_m12_H: 0.1,
    },
    1651: {
      H: 10.16,
      L: 10.04,
      O: 10.16,
      C: 10.12,
      V: 12000,
      p_w1_L: 9.74,
      p_w1_H: 10.96,
      p_m1_L: 9.74,
      p_m1_H: 11.2,
      p_m3_L: 8.91,
      p_m3_H: 12.2,
      p_m6_L: 8.66,
      p_m6_H: 12.2,
      p_m12_L: 7.15,
      p_m12_H: 12.2,
    },
    1652: {
      H: 1.55,
      L: 1.1,
      O: 1.44,
      C: 1.21,
      V: 21662000,
      p_w1_L: 1.1,
      p_w1_H: 2.4,
      p_m1_L: 1.1,
      p_m1_H: 2.54,
      p_m3_L: 1.1,
      p_m3_H: 3.48,
      p_m6_L: 1.1,
      p_m6_H: 3.6,
      p_m12_L: 1.1,
      p_m12_H: 6.82,
    },
    1653: {
      H: 0.355,
      L: 0.355,
      O: 0.355,
      C: 0.355,
      V: 0,
      p_w1_L: 0.355,
      p_w1_H: 0.365,
      p_m1_L: 0.31,
      p_m1_H: 0.365,
      p_m3_L: 0.3,
      p_m3_H: 0.365,
      p_m6_L: 0.25,
      p_m6_H: 0.41,
      p_m12_L: 0.25,
      p_m12_H: 0.7,
    },
    1655: {
      H: 0.59,
      L: 0.58,
      O: 0.58,
      C: 0.59,
      V: 10015000,
      p_w1_L: 0.58,
      p_w1_H: 0.67,
      p_m1_L: 0.58,
      p_m1_H: 0.69,
      p_m3_L: 0.44,
      p_m3_H: 1.71,
      p_m6_L: 0.4,
      p_m6_H: 1.71,
      p_m12_L: 0.4,
      p_m12_H: 1.71,
    },
    1656: {
      H: 3.98,
      L: 3.98,
      O: 3.98,
      C: 3.98,
      V: 0,
      p_w1_L: 3.98,
      p_w1_H: 3.98,
      p_m1_L: 3.51,
      p_m1_H: 4.25,
      p_m3_L: 3.2,
      p_m3_H: 4.25,
      p_m6_L: 3.2,
      p_m6_H: 4.78,
      p_m12_L: 2.26,
      p_m12_H: 4.78,
    },
    1657: {
      H: 4.1,
      L: 4.1,
      O: 4.1,
      C: 4.1,
      V: 0,
      p_w1_L: 4.1,
      p_w1_H: 5.77,
      p_m1_L: 4.1,
      p_m1_H: 6,
      p_m3_L: 3.9,
      p_m3_H: 6.79,
      p_m6_L: 3.9,
      p_m6_H: 6.79,
      p_m12_L: 2.7,
      p_m12_H: 6.79,
    },
    1658: {
      H: 5.73,
      L: 5.57,
      O: 5.73,
      C: 5.63,
      V: 41029200,
      p_w1_L: 5.29,
      p_w1_H: 5.73,
      p_m1_L: 5.27,
      p_m1_H: 5.82,
      p_m3_L: 5.03,
      p_m3_H: 5.93,
      p_m6_L: 4.71,
      p_m6_H: 5.93,
      p_m12_L: 4.08,
      p_m12_H: 6.37,
    },
    1660: {
      H: 0.475,
      L: 0.45,
      O: 0.475,
      C: 0.45,
      V: 5008000,
      p_w1_L: 0.445,
      p_w1_H: 0.5,
      p_m1_L: 0.445,
      p_m1_H: 0.52,
      p_m3_L: 0.42,
      p_m3_H: 0.55,
      p_m6_L: 0.42,
      p_m6_H: 0.71,
      p_m12_L: 0.42,
      p_m12_H: 1.13,
    },
    1661: {
      H: 0.167,
      L: 0.159,
      O: 0.159,
      C: 0.166,
      V: 66000,
      p_w1_L: 0.151,
      p_w1_H: 0.175,
      p_m1_L: 0.151,
      p_m1_H: 0.194,
      p_m3_L: 0.151,
      p_m3_H: 0.206,
      p_m6_L: 0.151,
      p_m6_H: 0.345,
      p_m12_L: 0.151,
      p_m12_H: 0.45,
    },
    1662: {
      H: 1.5,
      L: 1.47,
      O: 1.5,
      C: 1.47,
      V: 304000,
      p_w1_L: 1.37,
      p_w1_H: 1.5,
      p_m1_L: 1.37,
      p_m1_H: 1.85,
      p_m3_L: 1.37,
      p_m3_H: 2.08,
      p_m6_L: 1.37,
      p_m6_H: 2.38,
      p_m12_L: 1.37,
      p_m12_H: 3.42,
    },
    1663: {
      H: 0.139,
      L: 0.136,
      O: 0.138,
      C: 0.139,
      V: 378000,
      p_w1_L: 0.119,
      p_w1_H: 0.14,
      p_m1_L: 0.117,
      p_m1_H: 0.14,
      p_m3_L: 0.111,
      p_m3_H: 0.14,
      p_m6_L: 0.111,
      p_m6_H: 0.16,
      p_m12_L: 0.111,
      p_m12_H: 0.167,
    },
    1665: {
      H: 1.1,
      L: 1.09,
      O: 1.1,
      C: 1.09,
      V: 236000,
      p_w1_L: 1.05,
      p_w1_H: 1.11,
      p_m1_L: 1.05,
      p_m1_H: 1.21,
      p_m3_L: 1.01,
      p_m3_H: 1.23,
      p_m6_L: 0.94,
      p_m6_H: 1.28,
      p_m12_L: 0.94,
      p_m12_H: 1.4,
    },
    1666: {
      H: 6.19,
      L: 6.03,
      O: 6.19,
      C: 6.13,
      V: 492000,
      p_w1_L: 5.88,
      p_w1_H: 6.27,
      p_m1_L: 5.26,
      p_m1_H: 6.27,
      p_m3_L: 5.26,
      p_m3_H: 6.33,
      p_m6_L: 5.26,
      p_m6_H: 8.73,
      p_m12_L: 4.7,
      p_m12_H: 8.73,
    },
    1667: {
      H: 0.315,
      L: 0.315,
      O: 0.315,
      C: 0.315,
      V: 0,
      p_w1_L: 0.27,
      p_w1_H: 0.315,
      p_m1_L: 0.27,
      p_m1_H: 0.315,
      p_m3_L: 0.25,
      p_m3_H: 0.315,
      p_m6_L: 0.229,
      p_m6_H: 0.32,
      p_m12_L: 0.229,
      p_m12_H: 0.44,
    },
    1668: {
      H: 0.52,
      L: 0.51,
      O: 0.51,
      C: 0.52,
      V: 12572000,
      p_w1_L: 0.5,
      p_w1_H: 0.52,
      p_m1_L: 0.5,
      p_m1_H: 0.58,
      p_m3_L: 0.5,
      p_m3_H: 0.77,
      p_m6_L: 0.5,
      p_m6_H: 0.87,
      p_m12_L: 0.5,
      p_m12_H: 1.17,
    },
    1669: {
      H: 0.54,
      L: 0.54,
      O: 0.54,
      C: 0.54,
      V: 0,
      p_w1_L: 0.52,
      p_w1_H: 0.57,
      p_m1_L: 0.485,
      p_m1_H: 0.57,
      p_m3_L: 0.485,
      p_m3_H: 0.68,
      p_m6_L: 0.485,
      p_m6_H: 0.72,
      p_m12_L: 0.4,
      p_m12_H: 0.84,
    },
    1671: {
      H: 0.8,
      L: 0.77,
      O: 0.79,
      C: 0.8,
      V: 24000,
      p_w1_L: 0.75,
      p_w1_H: 0.8,
      p_m1_L: 0.75,
      p_m1_H: 0.95,
      p_m3_L: 0.75,
      p_m3_H: 1.17,
      p_m6_L: 0.75,
      p_m6_H: 1.17,
      p_m12_L: 0.6,
      p_m12_H: 1.78,
    },
    1672: {
      H: 3.05,
      L: 2.9,
      O: 3.05,
      C: 2.9,
      V: 1172000,
      p_w1_L: 2.85,
      p_w1_H: 3.45,
      p_m1_L: 2.51,
      p_m1_H: 3.45,
      p_m3_L: 2.44,
      p_m3_H: 3.45,
      p_m6_L: 2.44,
      p_m6_H: 3.93,
      p_m12_L: 2.44,
      p_m12_H: 4.07,
    },
    1673: {
      H: 0.67,
      L: 0.67,
      O: 0.67,
      C: 0.67,
      V: 60000,
      p_w1_L: 0.67,
      p_w1_H: 0.67,
      p_m1_L: 0.49,
      p_m1_H: 0.73,
      p_m3_L: 0.46,
      p_m3_H: 1.3,
      p_m6_L: 0.46,
      p_m6_H: 1.56,
      p_m12_L: 0.46,
      p_m12_H: 1.95,
    },
    1675: {
      H: 12.68,
      L: 12.24,
      O: 12.68,
      C: 12.26,
      V: 87200,
      p_w1_L: 12.2,
      p_w1_H: 12.9,
      p_m1_L: 12.2,
      p_m1_H: 13.62,
      p_m3_L: 12.2,
      p_m3_H: 13.9,
      p_m6_L: 10.78,
      p_m6_H: 13.9,
      p_m12_L: 10.2,
      p_m12_H: 13.9,
    },
    1676: {
      H: 1.35,
      L: 1.25,
      O: 1.35,
      C: 1.29,
      V: 200000,
      p_w1_L: 1.25,
      p_w1_H: 1.42,
      p_m1_L: 1.25,
      p_m1_H: 1.59,
      p_m3_L: 1.19,
      p_m3_H: 1.7,
      p_m6_L: 1.19,
      p_m6_H: 2.13,
      p_m12_L: 0.62,
      p_m12_H: 4.58,
    },
    1678: {
      H: 0.04,
      L: 0.04,
      O: 0.04,
      C: 0.04,
      V: 0,
      p_w1_L: 0.04,
      p_w1_H: 0.04,
      p_m1_L: 0.04,
      p_m1_H: 0.04,
      p_m3_L: 0.04,
      p_m3_H: 0.04,
      p_m6_L: 0.04,
      p_m6_H: 0.04,
      p_m12_L: 0.04,
      p_m12_H: 0.04,
    },
    1679: {
      H: 0.207,
      L: 0.205,
      O: 0.205,
      C: 0.207,
      V: 267500,
      p_w1_L: 0.2,
      p_w1_H: 0.21,
      p_m1_L: 0.196,
      p_m1_H: 0.223,
      p_m3_L: 0.188,
      p_m3_H: 0.223,
      p_m6_L: 0.171,
      p_m6_H: 0.265,
      p_m12_L: 0.171,
      p_m12_H: 0.31,
    },
    1680: {
      H: 0.62,
      L: 0.58,
      O: 0.61,
      C: 0.58,
      V: 500000,
      p_w1_L: 0.56,
      p_w1_H: 0.68,
      p_m1_L: 0.56,
      p_m1_H: 0.82,
      p_m3_L: 0.56,
      p_m3_H: 1.04,
      p_m6_L: 0.56,
      p_m6_H: 1.13,
      p_m12_L: 0.56,
      p_m12_H: 1.44,
    },
    1681: {
      H: 3.77,
      L: 3.58,
      O: 3.72,
      C: 3.71,
      V: 1440000,
      p_w1_L: 3.58,
      p_w1_H: 4.02,
      p_m1_L: 3.35,
      p_m1_H: 4.02,
      p_m3_L: 3.31,
      p_m3_H: 4.05,
      p_m6_L: 3.31,
      p_m6_H: 5.73,
      p_m12_L: 3,
      p_m12_H: 6.1,
    },
    1682: {
      H: 0.143,
      L: 0.143,
      O: 0.143,
      C: 0.147,
      V: 100000,
      p_w1_L: 0.123,
      p_w1_H: 0.156,
      p_m1_L: 0.123,
      p_m1_H: 0.193,
      p_m3_L: 0.123,
      p_m3_H: 0.38,
      p_m6_L: 0.123,
      p_m6_H: 0.38,
      p_m12_L: 0.11,
      p_m12_H: 0.5,
    },
    1683: {
      H: 0.23,
      L: 0.212,
      O: 0.226,
      C: 0.23,
      V: 1212000,
      p_w1_L: 0.162,
      p_w1_H: 0.25,
      p_m1_L: 0.1,
      p_m1_H: 0.25,
      p_m3_L: 0.1,
      p_m3_H: 0.25,
      p_m6_L: 0.1,
      p_m6_H: 0.25,
      p_m12_L: 0.1,
      p_m12_H: 1.82,
    },
    1685: {
      H: 0.44,
      L: 0.41,
      O: 0.43,
      C: 0.42,
      V: 84000,
      p_w1_L: 0.395,
      p_w1_H: 0.46,
      p_m1_L: 0.37,
      p_m1_H: 0.46,
      p_m3_L: 0.37,
      p_m3_H: 0.52,
      p_m6_L: 0.36,
      p_m6_H: 0.68,
      p_m12_L: 0.325,
      p_m12_H: 0.8,
    },
    1686: {
      H: 6.9,
      L: 6.66,
      O: 6.69,
      C: 6.85,
      V: 1676000,
      p_w1_L: 6.46,
      p_w1_H: 6.99,
      p_m1_L: 6.46,
      p_m1_H: 7.22,
      p_m3_L: 6.46,
      p_m3_H: 8.14,
      p_m6_L: 6.46,
      p_m6_H: 8.73,
      p_m12_L: 6.46,
      p_m12_H: 8.73,
    },
    1689: {
      H: 1.97,
      L: 1.92,
      O: 1.92,
      C: 1.94,
      V: 582000,
      p_w1_L: 1.84,
      p_w1_H: 1.97,
      p_m1_L: 1.84,
      p_m1_H: 2.16,
      p_m3_L: 1.84,
      p_m3_H: 2.54,
      p_m6_L: 1.84,
      p_m6_H: 2.94,
      p_m12_L: 1.84,
      p_m12_H: 3.02,
    },
    1690: {
      H: 0.059,
      L: 0.058,
      O: 0.059,
      C: 0.058,
      V: 2850000,
      p_w1_L: 0.058,
      p_w1_H: 0.063,
      p_m1_L: 0.058,
      p_m1_H: 0.067,
      p_m3_L: 0.058,
      p_m3_H: 0.074,
      p_m6_L: 0.058,
      p_m6_H: 0.096,
      p_m12_L: 0.056,
      p_m12_H: 0.133,
    },
    1691: {
      H: 15.3,
      L: 14.58,
      O: 14.8,
      C: 14.74,
      V: 3940000,
      p_w1_L: 13.94,
      p_w1_H: 15.36,
      p_m1_L: 13.6,
      p_m1_H: 17.78,
      p_m3_L: 12.98,
      p_m3_H: 18.94,
      p_m6_L: 12.98,
      p_m6_H: 23.8,
      p_m12_L: 12.98,
      p_m12_H: 26.5,
    },
    1692: {
      H: 2,
      L: 2,
      O: 2,
      C: 2,
      V: 150000,
      p_w1_L: 1.98,
      p_w1_H: 2.03,
      p_m1_L: 1.85,
      p_m1_H: 2.06,
      p_m3_L: 1.85,
      p_m3_H: 2.42,
      p_m6_L: 1.85,
      p_m6_H: 2.43,
      p_m12_L: 0.84,
      p_m12_H: 2.81,
    },
    1693: {
      H: 0.034,
      L: 0.034,
      O: 0.034,
      C: 0.034,
      V: 0,
      p_w1_L: 0.034,
      p_w1_H: 0.034,
      p_m1_L: 0.034,
      p_m1_H: 0.034,
      p_m3_L: 0.034,
      p_m3_H: 0.034,
      p_m6_L: 0.034,
      p_m6_H: 0.034,
      p_m12_L: 0.027,
      p_m12_H: 0.04,
    },
    1695: {
      H: 0.079,
      L: 0.067,
      O: 0.067,
      C: 0.079,
      V: 70000,
      p_w1_L: 0.066,
      p_w1_H: 0.079,
      p_m1_L: 0.066,
      p_m1_H: 0.08,
      p_m3_L: 0.066,
      p_m3_H: 0.084,
      p_m6_L: 0.066,
      p_m6_H: 0.11,
      p_m12_L: 0.066,
      p_m12_H: 0.114,
    },
    1696: {
      H: 10.46,
      L: 9.61,
      O: 10,
      C: 9.9,
      V: 2672800,
      p_w1_L: 9.34,
      p_w1_H: 12.3,
      p_m1_L: 9.34,
      p_m1_H: 14.26,
      p_m3_L: 9.34,
      p_m3_H: 15.1,
      p_m6_L: 9.34,
      p_m6_H: 35,
      p_m12_L: 2.44,
      p_m12_H: 35,
    },
    1697: {
      H: 0.37,
      L: 0.37,
      O: 0.37,
      C: 0.37,
      V: 7200,
      p_w1_L: 0.35,
      p_w1_H: 0.38,
      p_m1_L: 0.315,
      p_m1_H: 0.39,
      p_m3_L: 0.315,
      p_m3_H: 0.62,
      p_m6_L: 0.315,
      p_m6_H: 0.78,
      p_m12_L: 0.315,
      p_m12_H: 0.82,
    },
    1699: {
      H: 0.081,
      L: 0.075,
      O: 0.077,
      C: 0.078,
      V: 3528000,
      p_w1_L: 0.075,
      p_w1_H: 0.083,
      p_m1_L: 0.075,
      p_m1_H: 0.102,
      p_m3_L: 0.075,
      p_m3_H: 0.38,
      p_m6_L: 0.075,
      p_m6_H: 0.38,
      p_m12_L: 0.075,
      p_m12_H: 0.38,
    },
    1701: {
      H: 0.166,
      L: 0.16,
      O: 0.166,
      C: 0.16,
      V: 24000,
      p_w1_L: 0.152,
      p_w1_H: 0.168,
      p_m1_L: 0.152,
      p_m1_H: 0.193,
      p_m3_L: 0.152,
      p_m3_H: 0.248,
      p_m6_L: 0.151,
      p_m6_H: 0.25,
      p_m12_L: 0.151,
      p_m12_H: 0.365,
    },
    1702: {
      H: 1.74,
      L: 1.74,
      O: 1.74,
      C: 1.74,
      V: 0,
      p_w1_L: 1.74,
      p_w1_H: 1.75,
      p_m1_L: 1.71,
      p_m1_H: 2,
      p_m3_L: 1.31,
      p_m3_H: 2.2,
      p_m6_L: 1.28,
      p_m6_H: 2.2,
      p_m12_L: 1.28,
      p_m12_H: 2.2,
    },
    1703: {
      H: 0.88,
      L: 0.87,
      O: 0.88,
      C: 0.88,
      V: 3500000,
      p_w1_L: 0.84,
      p_w1_H: 0.89,
      p_m1_L: 0.84,
      p_m1_H: 0.92,
      p_m3_L: 0.74,
      p_m3_H: 0.98,
      p_m6_L: 0.63,
      p_m6_H: 0.98,
      p_m12_L: 0.44,
      p_m12_H: 0.98,
    },
    1705: {
      H: 0.46,
      L: 0.455,
      O: 0.455,
      C: 0.46,
      V: 40000,
      p_w1_L: 0.45,
      p_w1_H: 0.46,
      p_m1_L: 0.45,
      p_m1_H: 0.51,
      p_m3_L: 0.45,
      p_m3_H: 0.59,
      p_m6_L: 0.45,
      p_m6_H: 1.14,
      p_m12_L: 0.355,
      p_m12_H: 1.38,
    },
    1706: {
      H: 0.255,
      L: 0.255,
      O: 0.255,
      C: 0.255,
      V: 5000,
      p_w1_L: 0.24,
      p_w1_H: 0.265,
      p_m1_L: 0.23,
      p_m1_H: 0.29,
      p_m3_L: 0.23,
      p_m3_H: 0.3,
      p_m6_L: 0.122,
      p_m6_H: 0.38,
      p_m12_L: 0.092,
      p_m12_H: 0.38,
    },
    1707: {
      H: 0.355,
      L: 0.355,
      O: 0.355,
      C: 0.355,
      V: 0,
      p_w1_L: 0.355,
      p_w1_H: 0.355,
      p_m1_L: 0.255,
      p_m1_H: 0.4,
      p_m3_L: 0.235,
      p_m3_H: 0.48,
      p_m6_L: 0.235,
      p_m6_H: 0.48,
      p_m12_L: 0.235,
      p_m12_H: 0.8,
    },
    1708: {
      H: 3.76,
      L: 3.7,
      O: 3.75,
      C: 3.74,
      V: 161500,
      p_w1_L: 3.7,
      p_w1_H: 3.91,
      p_m1_L: 3.31,
      p_m1_H: 4.89,
      p_m3_L: 3.31,
      p_m3_H: 6.65,
      p_m6_L: 3.31,
      p_m6_H: 6.65,
      p_m12_L: 1.9,
      p_m12_H: 6.65,
    },
    1709: {
      H: 2.57,
      L: 2.49,
      O: 2.56,
      C: 2.55,
      V: 947000,
      p_w1_L: 2.4,
      p_w1_H: 2.7,
      p_m1_L: 2.4,
      p_m1_H: 2.8,
      p_m3_L: 2.3,
      p_m3_H: 3,
      p_m6_L: 2.21,
      p_m6_H: 3.88,
      p_m12_L: 2.21,
      p_m12_H: 4.3,
    },
    1710: {
      H: 0.18,
      L: 0.18,
      O: 0.18,
      C: 0.18,
      V: 0,
      p_w1_L: 0.172,
      p_w1_H: 0.181,
      p_m1_L: 0.169,
      p_m1_H: 0.187,
      p_m3_L: 0.168,
      p_m3_H: 0.19,
      p_m6_L: 0.168,
      p_m6_H: 0.25,
      p_m12_L: 0.165,
      p_m12_H: 0.27,
    },
    1711: {
      H: 0.27,
      L: 0.27,
      O: 0.27,
      C: 0.27,
      V: 30000,
      p_w1_L: 0.25,
      p_w1_H: 0.29,
      p_m1_L: 0.22,
      p_m1_H: 0.32,
      p_m3_L: 0.22,
      p_m3_H: 0.35,
      p_m6_L: 0.198,
      p_m6_H: 0.5,
      p_m12_L: 0.161,
      p_m12_H: 0.5,
    },
    1712: {
      H: 1.7,
      L: 1.7,
      O: 1.7,
      C: 1.7,
      V: 31000,
      p_w1_L: 1.6,
      p_w1_H: 1.75,
      p_m1_L: 1.31,
      p_m1_H: 1.75,
      p_m3_L: 1.31,
      p_m3_H: 1.75,
      p_m6_L: 1.31,
      p_m6_H: 1.78,
      p_m12_L: 1.07,
      p_m12_H: 2.75,
    },
    1713: {
      H: 1.74,
      L: 1.72,
      O: 1.73,
      C: 1.72,
      V: 28000,
      p_w1_L: 1.7,
      p_w1_H: 1.8,
      p_m1_L: 1.69,
      p_m1_H: 1.8,
      p_m3_L: 1.65,
      p_m3_H: 1.96,
      p_m6_L: 1.54,
      p_m6_H: 2,
      p_m12_L: 1.18,
      p_m12_H: 2,
    },
    1715: {
      H: 0.155,
      L: 0.151,
      O: 0.153,
      C: 0.151,
      V: 210000,
      p_w1_L: 0.14,
      p_w1_H: 0.158,
      p_m1_L: 0.14,
      p_m1_H: 0.175,
      p_m3_L: 0.14,
      p_m3_H: 0.176,
      p_m6_L: 0.14,
      p_m6_H: 0.204,
      p_m12_L: 0.14,
      p_m12_H: 0.27,
    },
    1716: {
      H: 0.54,
      L: 0.52,
      O: 0.52,
      C: 0.54,
      V: 104000,
      p_w1_L: 0.51,
      p_w1_H: 0.54,
      p_m1_L: 0.51,
      p_m1_H: 0.6,
      p_m3_L: 0.51,
      p_m3_H: 0.77,
      p_m6_L: 0.51,
      p_m6_H: 1.55,
      p_m12_L: 0.51,
      p_m12_H: 2,
    },
    1717: {
      H: 8.98,
      L: 8.84,
      O: 8.89,
      C: 8.9,
      V: 2736000,
      p_w1_L: 8.68,
      p_w1_H: 9.02,
      p_m1_L: 8.68,
      p_m1_H: 9.51,
      p_m3_L: 6.52,
      p_m3_H: 9.86,
      p_m6_L: 6.52,
      p_m6_H: 11.84,
      p_m12_L: 6.52,
      p_m12_H: 13.7,
    },
    1718: {
      H: 0.152,
      L: 0.151,
      O: 0.151,
      C: 0.152,
      V: 15000,
      p_w1_L: 0.122,
      p_w1_H: 0.165,
      p_m1_L: 0.122,
      p_m1_H: 0.36,
      p_m3_L: 0.101,
      p_m3_H: 0.36,
      p_m6_L: 0.101,
      p_m6_H: 0.36,
      p_m12_L: 0.101,
      p_m12_H: 0.38,
    },
    1719: {
      H: 0.85,
      L: 0.8,
      O: 0.8,
      C: 0.85,
      V: 412000,
      p_w1_L: 0.8,
      p_w1_H: 0.99,
      p_m1_L: 0.71,
      p_m1_H: 0.99,
      p_m3_L: 0.7,
      p_m3_H: 0.99,
      p_m6_L: 0.66,
      p_m6_H: 0.99,
      p_m12_L: 0.6,
      p_m12_H: 0.99,
    },
    1720: {
      H: 0.38,
      L: 0.325,
      O: 0.34,
      C: 0.345,
      V: 880000,
      p_w1_L: 0.325,
      p_w1_H: 0.53,
      p_m1_L: 0.3,
      p_m1_H: 0.53,
      p_m3_L: 0.3,
      p_m3_H: 0.53,
      p_m6_L: 0.3,
      p_m6_H: 0.53,
      p_m12_L: 0.3,
      p_m12_H: 0.93,
    },
    1722: {
      H: 0.114,
      L: 0.098,
      O: 0.1,
      C: 0.11,
      V: 1210000,
      p_w1_L: 0.098,
      p_w1_H: 0.114,
      p_m1_L: 0.095,
      p_m1_H: 0.114,
      p_m3_L: 0.093,
      p_m3_H: 0.115,
      p_m6_L: 0.093,
      p_m6_H: 0.129,
      p_m12_L: 0.083,
      p_m12_H: 0.139,
    },
    1723: {
      H: 4.79,
      L: 4.67,
      O: 4.79,
      C: 4.79,
      V: 118000,
      p_w1_L: 4.6,
      p_w1_H: 4.9,
      p_m1_L: 4,
      p_m1_H: 5.3,
      p_m3_L: 3.17,
      p_m3_H: 5.5,
      p_m6_L: 2.35,
      p_m6_H: 5.6,
      p_m12_L: 2.35,
      p_m12_H: 5.6,
    },
    1725: {
      H: 30.9,
      L: 29.55,
      O: 30.3,
      C: 29.65,
      V: 420800,
      p_w1_L: 28.4,
      p_w1_H: 31.8,
      p_m1_L: 26.65,
      p_m1_H: 32,
      p_m3_L: 26.65,
      p_m3_H: 35,
      p_m6_L: 23.6,
      p_m6_H: 42.8,
      p_m12_L: 1.03,
      p_m12_H: 50,
    },
    1726: {
      H: 0.9,
      L: 0.85,
      O: 0.85,
      C: 0.87,
      V: 1810000,
      p_w1_L: 0.69,
      p_w1_H: 0.93,
      p_m1_L: 0.64,
      p_m1_H: 1.03,
      p_m3_L: 0.4,
      p_m3_H: 1.03,
      p_m6_L: 0.35,
      p_m6_H: 1.03,
      p_m12_L: 0.168,
      p_m12_H: 1.03,
    },
    1727: {
      H: 1.65,
      L: 1.5,
      O: 1.65,
      C: 1.55,
      V: 341000,
      p_w1_L: 1.32,
      p_w1_H: 1.78,
      p_m1_L: 1.32,
      p_m1_H: 1.88,
      p_m3_L: 1.32,
      p_m3_H: 2.71,
      p_m6_L: 1.32,
      p_m6_H: 2.84,
      p_m12_L: 1.32,
      p_m12_H: 6.81,
    },
    1728: {
      H: 0.93,
      L: 0.88,
      O: 0.9,
      C: 0.93,
      V: 4829450,
      p_w1_L: 0.88,
      p_w1_H: 0.97,
      p_m1_L: 0.88,
      p_m1_H: 1.11,
      p_m3_L: 0.8,
      p_m3_H: 1.11,
      p_m6_L: 0.73,
      p_m6_H: 1.38,
      p_m12_L: 0.57,
      p_m12_H: 1.38,
    },
    1729: {
      H: 0.59,
      L: 0.52,
      O: 0.52,
      C: 0.59,
      V: 11984000,
      p_w1_L: 0.495,
      p_w1_H: 0.63,
      p_m1_L: 0.495,
      p_m1_H: 0.82,
      p_m3_L: 0.415,
      p_m3_H: 0.82,
      p_m6_L: 0.375,
      p_m6_H: 0.82,
      p_m12_L: 0.33,
      p_m12_H: 0.82,
    },
    1730: {
      H: 1.92,
      L: 1.9,
      O: 1.92,
      C: 1.91,
      V: 126000,
      p_w1_L: 1.9,
      p_w1_H: 1.97,
      p_m1_L: 1.82,
      p_m1_H: 2.05,
      p_m3_L: 1.63,
      p_m3_H: 2.05,
      p_m6_L: 1.63,
      p_m6_H: 2.33,
      p_m12_L: 0.95,
      p_m12_H: 2.33,
    },
    1731: {
      H: 1.32,
      L: 1.31,
      O: 1.32,
      C: 1.32,
      V: 384000,
      p_w1_L: 1.3,
      p_w1_H: 1.34,
      p_m1_L: 1.29,
      p_m1_H: 1.43,
      p_m3_L: 1.14,
      p_m3_H: 1.5,
      p_m6_L: 1.04,
      p_m6_H: 1.82,
      p_m12_L: 0.295,
      p_m12_H: 1.82,
    },
    1732: {
      H: 0.197,
      L: 0.197,
      O: 0.197,
      C: 0.197,
      V: 140000,
      p_w1_L: 0.168,
      p_w1_H: 0.205,
      p_m1_L: 0.14,
      p_m1_H: 0.23,
      p_m3_L: 0.14,
      p_m3_H: 0.24,
      p_m6_L: 0.117,
      p_m6_H: 0.29,
      p_m12_L: 0.09,
      p_m12_H: 0.29,
    },
    1733: {
      H: 0.85,
      L: 0.8,
      O: 0.81,
      C: 0.85,
      V: 9714450,
      p_w1_L: 0.77,
      p_w1_H: 0.9,
      p_m1_L: 0.72,
      p_m1_H: 0.9,
      p_m3_L: 0.69,
      p_m3_H: 1.08,
      p_m6_L: 0.355,
      p_m6_H: 1.08,
      p_m12_L: 0.32,
      p_m12_H: 1.08,
    },
    1735: {
      H: 8.23,
      L: 7.9,
      O: 8.1,
      C: 8.12,
      V: 1538000,
      p_w1_L: 7.37,
      p_w1_H: 8.47,
      p_m1_L: 5.28,
      p_m1_H: 8.47,
      p_m3_L: 5.28,
      p_m3_H: 9.08,
      p_m6_L: 3.41,
      p_m6_H: 9.08,
      p_m12_L: 3.12,
      p_m12_H: 9.08,
    },
    1736: {
      H: 0.084,
      L: 0.076,
      O: 0.079,
      C: 0.084,
      V: 436000,
      p_w1_L: 0.075,
      p_w1_H: 0.084,
      p_m1_L: 0.075,
      p_m1_H: 0.099,
      p_m3_L: 0.075,
      p_m3_H: 0.148,
      p_m6_L: 0.075,
      p_m6_H: 0.171,
      p_m12_L: 0.075,
      p_m12_H: 0.35,
    },
    1737: {
      H: 0.239,
      L: 0.239,
      O: 0.239,
      C: 0.239,
      V: 0,
      p_w1_L: 0.239,
      p_w1_H: 0.245,
      p_m1_L: 0.228,
      p_m1_H: 0.26,
      p_m3_L: 0.152,
      p_m3_H: 0.26,
      p_m6_L: 0.151,
      p_m6_H: 0.26,
      p_m12_L: 0.099,
      p_m12_H: 0.26,
    },
    1738: {
      H: 1.13,
      L: 1.13,
      O: 1.13,
      C: 1.13,
      V: 5000,
      p_w1_L: 1.13,
      p_w1_H: 1.32,
      p_m1_L: 1.11,
      p_m1_H: 1.32,
      p_m3_L: 0.91,
      p_m3_H: 1.32,
      p_m6_L: 0.8,
      p_m6_H: 1.32,
      p_m12_L: 0.8,
      p_m12_H: 1.45,
    },
    1739: {
      H: 1.05,
      L: 1.04,
      O: 1.05,
      C: 1.05,
      V: 84000,
      p_w1_L: 0.96,
      p_w1_H: 1.13,
      p_m1_L: 0.96,
      p_m1_H: 1.36,
      p_m3_L: 0.96,
      p_m3_H: 1.86,
      p_m6_L: 0.96,
      p_m6_H: 2.26,
      p_m12_L: 0.96,
      p_m12_H: 2.34,
    },
    1740: {
      H: 0.22,
      L: 0.216,
      O: 0.22,
      C: 0.219,
      V: 260000,
      p_w1_L: 0.216,
      p_w1_H: 0.24,
      p_m1_L: 0.202,
      p_m1_H: 0.24,
      p_m3_L: 0.133,
      p_m3_H: 0.24,
      p_m6_L: 0.118,
      p_m6_H: 0.24,
      p_m12_L: 0.118,
      p_m12_H: 0.25,
    },
    1741: {
      H: 0.93,
      L: 0.93,
      O: 0.93,
      C: 0.93,
      V: 1100000,
      p_w1_L: 0.82,
      p_w1_H: 1.02,
      p_m1_L: 0.82,
      p_m1_H: 1.21,
      p_m3_L: 0.82,
      p_m3_H: 1.36,
      p_m6_L: 0.82,
      p_m6_H: 1.55,
      p_m12_L: 0.82,
      p_m12_H: 1.74,
    },
    1742: {
      H: 0.139,
      L: 0.125,
      O: 0.126,
      C: 0.137,
      V: 1570000,
      p_w1_L: 0.125,
      p_w1_H: 0.145,
      p_m1_L: 0.106,
      p_m1_H: 0.147,
      p_m3_L: 0.081,
      p_m3_H: 0.147,
      p_m6_L: 0.076,
      p_m6_H: 0.147,
      p_m12_L: 0.075,
      p_m12_H: 0.147,
    },
    1745: {
      H: 0.54,
      L: 0.5,
      O: 0.54,
      C: 0.5,
      V: 2167600,
      p_w1_L: 0.5,
      p_w1_H: 0.56,
      p_m1_L: 0.5,
      p_m1_H: 0.72,
      p_m3_L: 0.5,
      p_m3_H: 0.87,
      p_m6_L: 0.5,
      p_m6_H: 1.19,
      p_m12_L: 0.5,
      p_m12_H: 1.49,
    },
    1746: {
      H: 0.52,
      L: 0.52,
      O: 0.52,
      C: 0.52,
      V: 12000,
      p_w1_L: 0.52,
      p_w1_H: 0.59,
      p_m1_L: 0.5,
      p_m1_H: 0.64,
      p_m3_L: 0.48,
      p_m3_H: 0.71,
      p_m6_L: 0.48,
      p_m6_H: 0.81,
      p_m12_L: 0.35,
      p_m12_H: 1.15,
    },
    1747: {
      H: 0.74,
      L: 0.74,
      O: 0.74,
      C: 0.74,
      V: 0,
      p_w1_L: 0.71,
      p_w1_H: 0.75,
      p_m1_L: 0.67,
      p_m1_H: 0.9,
      p_m3_L: 0.66,
      p_m3_H: 0.9,
      p_m6_L: 0.63,
      p_m6_H: 0.91,
      p_m12_L: 0.63,
      p_m12_H: 0.98,
    },
    1748: {
      H: 2.99,
      L: 2.99,
      O: 2.99,
      C: 2.99,
      V: 0,
      p_w1_L: 2.9,
      p_w1_H: 3.1,
      p_m1_L: 2.82,
      p_m1_H: 3.55,
      p_m3_L: 2.82,
      p_m3_H: 3.55,
      p_m6_L: 1.73,
      p_m6_H: 3.84,
      p_m12_L: 0.9,
      p_m12_H: 3.84,
    },
    1749: {
      H: 0.78,
      L: 0.78,
      O: 0.78,
      C: 0.78,
      V: 0,
      p_w1_L: 0.72,
      p_w1_H: 0.81,
      p_m1_L: 0.66,
      p_m1_H: 0.83,
      p_m3_L: 0.66,
      p_m3_H: 0.95,
      p_m6_L: 0.49,
      p_m6_H: 1.48,
      p_m12_L: 0.44,
      p_m12_H: 1.48,
    },
    1750: {
      H: 0.049,
      L: 0.049,
      O: 0.049,
      C: 0.049,
      V: 0,
      p_w1_L: 0.045,
      p_w1_H: 0.062,
      p_m1_L: 0.045,
      p_m1_H: 0.062,
      p_m3_L: 0.045,
      p_m3_H: 0.064,
      p_m6_L: 0.045,
      p_m6_H: 0.065,
      p_m12_L: 0.043,
      p_m12_H: 0.069,
    },
    1751: {
      H: 0.25,
      L: 0.25,
      O: 0.25,
      C: 0.25,
      V: 20000,
      p_w1_L: 0.25,
      p_w1_H: 0.315,
      p_m1_L: 0.23,
      p_m1_H: 0.315,
      p_m3_L: 0.23,
      p_m3_H: 0.35,
      p_m6_L: 0.18,
      p_m6_H: 0.38,
      p_m12_L: 0.11,
      p_m12_H: 0.44,
    },
    1752: {
      H: 0.203,
      L: 0.203,
      O: 0.203,
      C: 0.203,
      V: 10000,
      p_w1_L: 0.203,
      p_w1_H: 0.249,
      p_m1_L: 0.16,
      p_m1_H: 0.249,
      p_m3_L: 0.16,
      p_m3_H: 0.249,
      p_m6_L: 0.146,
      p_m6_H: 0.249,
      p_m12_L: 0.146,
      p_m12_H: 0.3,
    },
    1753: {
      H: 1.57,
      L: 1.52,
      O: 1.55,
      C: 1.53,
      V: 287200,
      p_w1_L: 1.46,
      p_w1_H: 1.63,
      p_m1_L: 1.46,
      p_m1_H: 1.86,
      p_m3_L: 1.46,
      p_m3_H: 2.09,
      p_m6_L: 1.46,
      p_m6_H: 2.46,
      p_m12_L: 1.46,
      p_m12_H: 5.38,
    },
    1755: {
      H: 14.14,
      L: 13.76,
      O: 14,
      C: 13.9,
      V: 1766000,
      p_w1_L: 12.64,
      p_w1_H: 14.36,
      p_m1_L: 12.64,
      p_m1_H: 15.4,
      p_m3_L: 12.64,
      p_m3_H: 17.8,
      p_m6_L: 12.64,
      p_m6_H: 24,
      p_m12_L: 12.64,
      p_m12_H: 27.8,
    },
    1756: {
      H: 1.2,
      L: 1.16,
      O: 1.2,
      C: 1.16,
      V: 24000,
      p_w1_L: 1.11,
      p_w1_H: 1.25,
      p_m1_L: 1.11,
      p_m1_H: 1.57,
      p_m3_L: 1.11,
      p_m3_H: 1.64,
      p_m6_L: 1.11,
      p_m6_H: 2.58,
      p_m12_L: 1.11,
      p_m12_H: 3,
    },
    1757: {
      H: 0.25,
      L: 0.25,
      O: 0.25,
      C: 0.25,
      V: 40000,
      p_w1_L: 0.245,
      p_w1_H: 0.25,
      p_m1_L: 0.245,
      p_m1_H: 0.28,
      p_m3_L: 0.24,
      p_m3_H: 0.325,
      p_m6_L: 0.24,
      p_m6_H: 0.52,
      p_m12_L: 0.159,
      p_m12_H: 0.52,
    },
    1758: {
      H: 0.465,
      L: 0.465,
      O: 0.465,
      C: 0.465,
      V: 0,
      p_w1_L: 0.465,
      p_w1_H: 0.465,
      p_m1_L: 0.4,
      p_m1_H: 0.57,
      p_m3_L: 0.395,
      p_m3_H: 0.57,
      p_m6_L: 0.395,
      p_m6_H: 0.8,
      p_m12_L: 0.395,
      p_m12_H: 0.8,
    },
    1759: {
      H: 1.22,
      L: 1.17,
      O: 1.22,
      C: 1.17,
      V: 15000,
      p_w1_L: 1.1,
      p_w1_H: 1.26,
      p_m1_L: 1.1,
      p_m1_H: 1.8,
      p_m3_L: 0.84,
      p_m3_H: 3.39,
      p_m6_L: 0.76,
      p_m6_H: 3.39,
      p_m12_L: 0.76,
      p_m12_H: 3.39,
    },
    1760: {
      H: 5.83,
      L: 5.33,
      O: 5.37,
      C: 5.79,
      V: 10807000,
      p_w1_L: 4.96,
      p_w1_H: 5.83,
      p_m1_L: 4.38,
      p_m1_H: 5.83,
      p_m3_L: 4,
      p_m3_H: 5.83,
      p_m6_L: 3.78,
      p_m6_H: 6.22,
      p_m12_L: 2.74,
      p_m12_H: 9.56,
    },
    1761: {
      H: 0.77,
      L: 0.69,
      O: 0.69,
      C: 0.75,
      V: 5218500,
      p_w1_L: 0.65,
      p_w1_H: 0.77,
      p_m1_L: 0.57,
      p_m1_H: 0.77,
      p_m3_L: 0.51,
      p_m3_H: 0.84,
      p_m6_L: 0.51,
      p_m6_H: 1.31,
      p_m12_L: 0.51,
      p_m12_H: 1.74,
    },
    1762: {
      H: 0.52,
      L: 0.51,
      O: 0.52,
      C: 0.51,
      V: 920000,
      p_w1_L: 0.51,
      p_w1_H: 0.56,
      p_m1_L: 0.51,
      p_m1_H: 0.59,
      p_m3_L: 0.51,
      p_m3_H: 0.77,
      p_m6_L: 0.51,
      p_m6_H: 0.95,
      p_m12_L: 0.435,
      p_m12_H: 2.25,
    },
    1763: {
      H: 22.7,
      L: 22.1,
      O: 22.5,
      C: 22.3,
      V: 6500,
      p_w1_L: 21.8,
      p_w1_H: 23.35,
      p_m1_L: 21.75,
      p_m1_H: 24.8,
      p_m3_L: 19,
      p_m3_H: 24.8,
      p_m6_L: 19,
      p_m6_H: 32.1,
      p_m12_L: 19,
      p_m12_H: 35.4,
    },
    1765: {
      H: 1.59,
      L: 1.49,
      O: 1.59,
      C: 1.53,
      V: 36578000,
      p_w1_L: 1.46,
      p_w1_H: 1.76,
      p_m1_L: 1.24,
      p_m1_H: 1.98,
      p_m3_L: 1.15,
      p_m3_H: 1.98,
      p_m6_L: 1.15,
      p_m6_H: 2.3,
      p_m12_L: 1.15,
      p_m12_H: 3.47,
    },
    1766: {
      H: 3.62,
      L: 3.55,
      O: 3.62,
      C: 3.59,
      V: 12177900,
      p_w1_L: 3.44,
      p_w1_H: 3.62,
      p_m1_L: 3.44,
      p_m1_H: 3.8,
      p_m3_L: 3.43,
      p_m3_H: 4.04,
      p_m6_L: 3.19,
      p_m6_H: 4.04,
      p_m12_L: 2.53,
      p_m12_H: 4.22,
    },
    1767: {
      H: 0.29,
      L: 0.29,
      O: 0.29,
      C: 0.29,
      V: 20000,
      p_w1_L: 0.28,
      p_w1_H: 0.3,
      p_m1_L: 0.275,
      p_m1_H: 0.325,
      p_m3_L: 0.25,
      p_m3_H: 0.335,
      p_m6_L: 0.25,
      p_m6_H: 0.395,
      p_m12_L: 0.25,
      p_m12_H: 0.69,
    },
    1769: {
      H: 2.2,
      L: 1.95,
      O: 2.1,
      C: 2.02,
      V: 16990000,
      p_w1_L: 1.87,
      p_w1_H: 2.55,
      p_m1_L: 1.25,
      p_m1_H: 3.16,
      p_m3_L: 1.25,
      p_m3_H: 3.16,
      p_m6_L: 1.25,
      p_m6_H: 5.45,
      p_m12_L: 1.25,
      p_m12_H: 15.5,
    },
    1771: {
      H: 2.29,
      L: 2.14,
      O: 2.14,
      C: 2.29,
      V: 5000,
      p_w1_L: 2.02,
      p_w1_H: 2.32,
      p_m1_L: 2.02,
      p_m1_H: 2.46,
      p_m3_L: 2.02,
      p_m3_H: 2.6,
      p_m6_L: 1.93,
      p_m6_H: 3.68,
      p_m12_L: 1.2,
      p_m12_H: 3.68,
    },
    1772: {
      H: 136.5,
      L: 130,
      O: 133,
      C: 136.3,
      V: 4142330,
      p_w1_L: 125.8,
      p_w1_H: 152.1,
      p_m1_L: 125.8,
      p_m1_H: 156.2,
      p_m3_L: 125.8,
      p_m3_H: 164.5,
      p_m6_L: 101.1,
      p_m6_H: 185,
      p_m12_L: 70,
      p_m12_H: 185,
    },
    1773: {
      H: 1.91,
      L: 1.91,
      O: 1.91,
      C: 1.91,
      V: 0,
      p_w1_L: 1.91,
      p_w1_H: 1.91,
      p_m1_L: 1.59,
      p_m1_H: 2.29,
      p_m3_L: 1.2,
      p_m3_H: 2.53,
      p_m6_L: 1.2,
      p_m6_H: 4.16,
      p_m12_L: 1.2,
      p_m12_H: 10.68,
    },
    1775: {
      H: 0.7,
      L: 0.7,
      O: 0.7,
      C: 0.7,
      V: 0,
      p_w1_L: 0.69,
      p_w1_H: 0.72,
      p_m1_L: 0.6,
      p_m1_H: 0.8,
      p_m3_L: 0.45,
      p_m3_H: 0.8,
      p_m6_L: 0.415,
      p_m6_H: 0.8,
      p_m12_L: 0.37,
      p_m12_H: 0.8,
    },
    1776: {
      H: 15.72,
      L: 15,
      O: 15.06,
      C: 15.46,
      V: 13408900,
      p_w1_L: 13.4,
      p_w1_H: 15.72,
      p_m1_L: 12.1,
      p_m1_H: 15.72,
      p_m3_L: 12.06,
      p_m3_H: 16.2,
      p_m6_L: 9.61,
      p_m6_H: 16.2,
      p_m12_L: 9.61,
      p_m12_H: 16.2,
    },
    1777: {
      H: 0.315,
      L: 0.315,
      O: 0.315,
      C: 0.315,
      V: 0,
      p_w1_L: 0.315,
      p_w1_H: 0.315,
      p_m1_L: 0.27,
      p_m1_H: 0.56,
      p_m3_L: 0.27,
      p_m3_H: 0.7,
      p_m6_L: 0.27,
      p_m6_H: 0.99,
      p_m12_L: 0.27,
      p_m12_H: 1.51,
    },
    1778: {
      H: 1.16,
      L: 1.16,
      O: 1.16,
      C: 1.16,
      V: 0,
      p_w1_L: 1.16,
      p_w1_H: 1.16,
      p_m1_L: 1.13,
      p_m1_H: 1.33,
      p_m3_L: 1.13,
      p_m3_H: 2.64,
      p_m6_L: 1.13,
      p_m6_H: 3.33,
      p_m12_L: 1.13,
      p_m12_H: 3.92,
    },
    1780: {
      H: 2.48,
      L: 2.42,
      O: 2.47,
      C: 2.48,
      V: 22500,
      p_w1_L: 2.28,
      p_w1_H: 2.63,
      p_m1_L: 2.28,
      p_m1_H: 3.21,
      p_m3_L: 1.85,
      p_m3_H: 3.59,
      p_m6_L: 0.76,
      p_m6_H: 3.99,
      p_m12_L: 0.35,
      p_m12_H: 3.99,
    },
    1781: {
      H: 0.183,
      L: 0.183,
      O: 0.183,
      C: 0.183,
      V: 0,
      p_w1_L: 0.183,
      p_w1_H: 0.183,
      p_m1_L: 0.183,
      p_m1_H: 0.183,
      p_m3_L: 0.183,
      p_m3_H: 0.183,
      p_m6_L: 0.183,
      p_m6_H: 0.183,
      p_m12_L: 0.183,
      p_m12_H: 0.183,
    },
    1782: {
      H: 0.42,
      L: 0.375,
      O: 0.42,
      C: 0.41,
      V: 70000,
      p_w1_L: 0.35,
      p_w1_H: 0.42,
      p_m1_L: 0.33,
      p_m1_H: 0.42,
      p_m3_L: 0.3,
      p_m3_H: 0.42,
      p_m6_L: 0.3,
      p_m6_H: 0.55,
      p_m12_L: 0.3,
      p_m12_H: 0.55,
    },
    1783: {
      H: 0.195,
      L: 0.18,
      O: 0.195,
      C: 0.182,
      V: 2010000,
      p_w1_L: 0.18,
      p_w1_H: 0.265,
      p_m1_L: 0.18,
      p_m1_H: 0.34,
      p_m3_L: 0.18,
      p_m3_H: 0.355,
      p_m6_L: 0.18,
      p_m6_H: 0.425,
      p_m12_L: 0.12,
      p_m12_H: 0.425,
    },
    1785: {
      H: 2.16,
      L: 2.11,
      O: 2.16,
      C: 2.11,
      V: 4000,
      p_w1_L: 2.1,
      p_w1_H: 2.2,
      p_m1_L: 2.05,
      p_m1_H: 2.24,
      p_m3_L: 2,
      p_m3_H: 2.4,
      p_m6_L: 2,
      p_m6_H: 2.4,
      p_m12_L: 1.88,
      p_m12_H: 2.4,
    },
    1786: {
      H: 0.79,
      L: 0.78,
      O: 0.79,
      C: 0.79,
      V: 242000,
      p_w1_L: 0.74,
      p_w1_H: 0.8,
      p_m1_L: 0.74,
      p_m1_H: 0.86,
      p_m3_L: 0.74,
      p_m3_H: 0.94,
      p_m6_L: 0.74,
      p_m6_H: 0.97,
      p_m12_L: 0.72,
      p_m12_H: 1.28,
    },
    1787: {
      H: 13.4,
      L: 13.12,
      O: 13.36,
      C: 13.14,
      V: 2962280,
      p_w1_L: 13.12,
      p_w1_H: 14.26,
      p_m1_L: 13.12,
      p_m1_H: 15.6,
      p_m3_L: 13.12,
      p_m3_H: 15.6,
      p_m6_L: 12.24,
      p_m6_H: 16.06,
      p_m12_L: 12.24,
      p_m12_H: 18.98,
    },
    1788: {
      H: 1.11,
      L: 1.09,
      O: 1.11,
      C: 1.09,
      V: 1951330,
      p_w1_L: 1.08,
      p_w1_H: 1.12,
      p_m1_L: 1.08,
      p_m1_H: 1.17,
      p_m3_L: 1.07,
      p_m3_H: 1.37,
      p_m6_L: 1.07,
      p_m6_H: 1.44,
      p_m12_L: 1,
      p_m12_H: 1.73,
    },
    1789: {
      H: 6.7,
      L: 6.46,
      O: 6.55,
      C: 6.63,
      V: 2632000,
      p_w1_L: 6.11,
      p_w1_H: 6.84,
      p_m1_L: 6.11,
      p_m1_H: 7.96,
      p_m3_L: 6.11,
      p_m3_H: 11.3,
      p_m6_L: 6.11,
      p_m6_H: 16.28,
      p_m12_L: 6.11,
      p_m12_H: 16.28,
    },
    1790: {
      H: 0.65,
      L: 0.65,
      O: 0.65,
      C: 0.65,
      V: 0,
      p_w1_L: 0.65,
      p_w1_H: 0.68,
      p_m1_L: 0.57,
      p_m1_H: 0.69,
      p_m3_L: 0.51,
      p_m3_H: 0.69,
      p_m6_L: 0.43,
      p_m6_H: 0.69,
      p_m12_L: 0.35,
      p_m12_H: 0.69,
    },
    1792: {
      H: 0.098,
      L: 0.098,
      O: 0.098,
      C: 0.098,
      V: 0,
      p_w1_L: 0.09,
      p_w1_H: 0.106,
      p_m1_L: 0.09,
      p_m1_H: 0.134,
      p_m3_L: 0.089,
      p_m3_H: 0.196,
      p_m6_L: 0.033,
      p_m6_H: 0.196,
      p_m12_L: 0.033,
      p_m12_H: 0.196,
    },
    1793: {
      H: 0.196,
      L: 0.196,
      O: 0.196,
      C: 0.196,
      V: 0,
      p_w1_L: 0.192,
      p_w1_H: 0.21,
      p_m1_L: 0.192,
      p_m1_H: 0.222,
      p_m3_L: 0.192,
      p_m3_H: 0.229,
      p_m6_L: 0.192,
      p_m6_H: 0.24,
      p_m12_L: 0.153,
      p_m12_H: 0.265,
    },
    1795: {
      H: 0.84,
      L: 0.74,
      O: 0.74,
      C: 0.8,
      V: 603000,
      p_w1_L: 0.74,
      p_w1_H: 0.89,
      p_m1_L: 0.62,
      p_m1_H: 0.9,
      p_m3_L: 0.51,
      p_m3_H: 0.9,
      p_m6_L: 0.51,
      p_m6_H: 0.9,
      p_m12_L: 0.4,
      p_m12_H: 1.58,
    },
    1796: {
      H: 1.24,
      L: 1.24,
      O: 1.24,
      C: 1.24,
      V: 0,
      p_w1_L: 1.05,
      p_w1_H: 1.3,
      p_m1_L: 0.98,
      p_m1_H: 1.3,
      p_m3_L: 0.85,
      p_m3_H: 1.3,
      p_m6_L: 0.55,
      p_m6_H: 1.85,
      p_m12_L: 0.33,
      p_m12_H: 1.85,
    },
    1797: {
      H: 7.6,
      L: 6.98,
      O: 7.51,
      C: 7.21,
      V: 16026500,
      p_w1_L: 6.95,
      p_w1_H: 8.31,
      p_m1_L: 4.05,
      p_m1_H: 9.72,
      p_m3_L: 4,
      p_m3_H: 9.72,
      p_m6_L: 3.71,
      p_m6_H: 10.98,
      p_m12_L: 3.71,
      p_m12_H: 31.2,
    },
    1798: {
      H: 3.18,
      L: 3.03,
      O: 3.03,
      C: 3.16,
      V: 41745000,
      p_w1_L: 2.86,
      p_w1_H: 3.18,
      p_m1_L: 2.67,
      p_m1_H: 3.24,
      p_m3_L: 2.47,
      p_m3_H: 4.41,
      p_m6_L: 1.67,
      p_m6_H: 4.41,
      p_m12_L: 1.05,
      p_m12_H: 4.41,
    },
    1799: {
      H: 16.68,
      L: 16.2,
      O: 16.3,
      C: 16.5,
      V: 4042800,
      p_w1_L: 15.66,
      p_w1_H: 17.88,
      p_m1_L: 15.66,
      p_m1_H: 21.35,
      p_m3_L: 15.66,
      p_m3_H: 27.4,
      p_m6_L: 12.46,
      p_m6_H: 27.4,
      p_m12_L: 5.6,
      p_m12_H: 31.3,
    },
    1800: {
      H: 4.3,
      L: 4.22,
      O: 4.29,
      C: 4.24,
      V: 6541920,
      p_w1_L: 4.05,
      p_w1_H: 4.3,
      p_m1_L: 4.01,
      p_m1_H: 4.3,
      p_m3_L: 4,
      p_m3_H: 4.73,
      p_m6_L: 3.57,
      p_m6_H: 4.73,
      p_m12_L: 3.21,
      p_m12_H: 4.73,
    },
    1801: {
      H: 67.55,
      L: 65.2,
      O: 65.55,
      C: 65.6,
      V: 4013660,
      p_w1_L: 60.85,
      p_w1_H: 70.45,
      p_m1_L: 60.85,
      p_m1_H: 82,
      p_m3_L: 60.35,
      p_m3_H: 82,
      p_m6_L: 57.65,
      p_m6_H: 94.75,
      p_m12_L: 54.5,
      p_m12_H: 107.1,
    },
    1802: {
      H: 1.01,
      L: 0.95,
      O: 1.01,
      C: 0.99,
      V: 1768000,
      p_w1_L: 0.95,
      p_w1_H: 1.08,
      p_m1_L: 0.82,
      p_m1_H: 1.32,
      p_m3_L: 0.82,
      p_m3_H: 1.32,
      p_m6_L: 0.81,
      p_m6_H: 1.32,
      p_m12_L: 0.81,
      p_m12_H: 2.04,
    },
    1803: {
      H: 0.225,
      L: 0.218,
      O: 0.218,
      C: 0.225,
      V: 667500,
      p_w1_L: 0.18,
      p_w1_H: 0.225,
      p_m1_L: 0.173,
      p_m1_H: 0.225,
      p_m3_L: 0.173,
      p_m3_H: 0.265,
      p_m6_L: 0.173,
      p_m6_H: 0.305,
      p_m12_L: 0.154,
      p_m12_H: 0.305,
    },
    1808: {
      H: 0.081,
      L: 0.076,
      O: 0.076,
      C: 0.081,
      V: 173200,
      p_w1_L: 0.076,
      p_w1_H: 0.082,
      p_m1_L: 0.072,
      p_m1_H: 0.1,
      p_m3_L: 0.071,
      p_m3_H: 0.1,
      p_m6_L: 0.071,
      p_m6_H: 0.11,
      p_m12_L: 0.071,
      p_m12_H: 0.3,
    },
    1809: {
      H: 6.84,
      L: 6.76,
      O: 6.82,
      C: 6.8,
      V: 202500,
      p_w1_L: 6.22,
      p_w1_H: 6.84,
      p_m1_L: 6.02,
      p_m1_H: 6.93,
      p_m3_L: 6.02,
      p_m3_H: 7.69,
      p_m6_L: 6.02,
      p_m6_H: 8.85,
      p_m12_L: 6.02,
      p_m12_H: 9.55,
    },
    1810: {
      H: 19.48,
      L: 19.12,
      O: 19.4,
      C: 19.22,
      V: 57459000,
      p_w1_L: 18.62,
      p_w1_H: 19.82,
      p_m1_L: 18.62,
      p_m1_H: 22,
      p_m3_L: 18.62,
      p_m3_H: 25.1,
      p_m6_L: 18.62,
      p_m6_H: 29.1,
      p_m12_L: 18.62,
      p_m12_H: 35.9,
    },
    1811: {
      H: 6.85,
      L: 6.45,
      O: 6.45,
      C: 6.84,
      V: 16064000,
      p_w1_L: 6.26,
      p_w1_H: 7.05,
      p_m1_L: 5.67,
      p_m1_H: 7.36,
      p_m3_L: 4.79,
      p_m3_H: 8.99,
      p_m6_L: 2.29,
      p_m6_H: 8.99,
      p_m12_L: 1.1,
      p_m12_H: 8.99,
    },
    1812: {
      H: 3.87,
      L: 3.8,
      O: 3.86,
      C: 3.8,
      V: 2873000,
      p_w1_L: 3.69,
      p_w1_H: 3.95,
      p_m1_L: 3.62,
      p_m1_H: 3.95,
      p_m3_L: 3.5,
      p_m3_H: 5.29,
      p_m6_L: 3.5,
      p_m6_H: 6.12,
      p_m12_L: 3.38,
      p_m12_H: 9.9,
    },
    1813: {
      H: 6.39,
      L: 6.13,
      O: 6.2,
      C: 6.18,
      V: 9314180,
      p_w1_L: 5.45,
      p_w1_H: 6.39,
      p_m1_L: 5.45,
      p_m1_H: 7.59,
      p_m3_L: 5.45,
      p_m3_H: 8.3,
      p_m6_L: 5.45,
      p_m6_H: 12.08,
      p_m12_L: 5.45,
      p_m12_H: 14.18,
    },
    1815: {
      H: 1.19,
      L: 1.08,
      O: 1.18,
      C: 1.1,
      V: 1750000,
      p_w1_L: 1.01,
      p_w1_H: 1.38,
      p_m1_L: 0.85,
      p_m1_H: 1.84,
      p_m3_L: 0.58,
      p_m3_H: 1.84,
      p_m6_L: 0.56,
      p_m6_H: 1.84,
      p_m12_L: 0.485,
      p_m12_H: 1.84,
    },
    1816: {
      H: 2.23,
      L: 2.17,
      O: 2.2,
      C: 2.21,
      V: 22458700,
      p_w1_L: 2.09,
      p_w1_H: 2.24,
      p_m1_L: 2.02,
      p_m1_H: 2.24,
      p_m3_L: 1.9,
      p_m3_H: 2.55,
      p_m6_L: 1.59,
      p_m6_H: 2.55,
      p_m12_L: 1.56,
      p_m12_H: 2.55,
    },
    1817: {
      H: 5.9,
      L: 5.86,
      O: 5.86,
      C: 5.88,
      V: 3000,
      p_w1_L: 5.74,
      p_w1_H: 6.24,
      p_m1_L: 5.28,
      p_m1_H: 6.5,
      p_m3_L: 4.73,
      p_m3_H: 6.56,
      p_m6_L: 4.73,
      p_m6_H: 7.53,
      p_m12_L: 3.99,
      p_m12_H: 8.19,
    },
    1818: {
      H: 7,
      L: 6.62,
      O: 6.9,
      C: 6.72,
      V: 17227400,
      p_w1_L: 6.62,
      p_w1_H: 8.06,
      p_m1_L: 5.8,
      p_m1_H: 8.33,
      p_m3_L: 5.31,
      p_m3_H: 8.33,
      p_m6_L: 5.31,
      p_m6_H: 8.33,
      p_m12_L: 5.31,
      p_m12_H: 10.1,
    },
    1820: {
      H: 6.27,
      L: 6.27,
      O: 6.27,
      C: 6.27,
      V: 40000,
      p_w1_L: 6.26,
      p_w1_H: 6.27,
      p_m1_L: 6.1,
      p_m1_H: 6.27,
      p_m3_L: 5.76,
      p_m3_H: 6.3,
      p_m6_L: 5.76,
      p_m6_H: 6.31,
      p_m12_L: 5.4,
      p_m12_H: 6.31,
    },
    1821: {
      H: 26.5,
      L: 25.7,
      O: 26,
      C: 26,
      V: 2239170,
      p_w1_L: 24.25,
      p_w1_H: 26.5,
      p_m1_L: 24.25,
      p_m1_H: 27.8,
      p_m3_L: 22.2,
      p_m3_H: 27.8,
      p_m6_L: 21.9,
      p_m6_H: 28.7,
      p_m12_L: 21.9,
      p_m12_H: 30.35,
    },
    1822: {
      H: 0.065,
      L: 0.065,
      O: 0.065,
      C: 0.065,
      V: 0,
      p_w1_L: 0.065,
      p_w1_H: 0.065,
      p_m1_L: 0.065,
      p_m1_H: 0.065,
      p_m3_L: 0.054,
      p_m3_H: 0.118,
      p_m6_L: 0.045,
      p_m6_H: 0.118,
      p_m12_L: 0.043,
      p_m12_H: 0.16,
    },
    1823: {
      H: 3.09,
      L: 2.85,
      O: 2.85,
      C: 3,
      V: 340000,
      p_w1_L: 2.85,
      p_w1_H: 3.27,
      p_m1_L: 2.85,
      p_m1_H: 3.93,
      p_m3_L: 2.85,
      p_m3_H: 7.03,
      p_m6_L: 1.31,
      p_m6_H: 7.7,
      p_m12_L: 0.6,
      p_m12_H: 7.7,
    },
    1825: {
      H: 0.183,
      L: 0.178,
      O: 0.183,
      C: 0.18,
      V: 222500,
      p_w1_L: 0.177,
      p_w1_H: 0.25,
      p_m1_L: 0.17,
      p_m1_H: 0.25,
      p_m3_L: 0.17,
      p_m3_H: 0.25,
      p_m6_L: 0.17,
      p_m6_H: 3.68,
      p_m12_L: 0.052,
      p_m12_H: 3.68,
    },
    1826: {
      H: 0.165,
      L: 0.157,
      O: 0.158,
      C: 0.165,
      V: 160000,
      p_w1_L: 0.156,
      p_w1_H: 0.18,
      p_m1_L: 0.154,
      p_m1_H: 0.23,
      p_m3_L: 0.115,
      p_m3_H: 0.33,
      p_m6_L: 0.115,
      p_m6_H: 0.33,
      p_m12_L: 0.11,
      p_m12_H: 0.33,
    },
    1827: {
      H: 1.72,
      L: 1.7,
      O: 1.72,
      C: 1.7,
      V: 525000,
      p_w1_L: 1.67,
      p_w1_H: 1.78,
      p_m1_L: 1.6,
      p_m1_H: 1.93,
      p_m3_L: 1.4,
      p_m3_H: 2,
      p_m6_L: 1.02,
      p_m6_H: 2.35,
      p_m12_L: 0.48,
      p_m12_H: 2.35,
    },
    1830: {
      H: 6,
      L: 5.81,
      O: 5.93,
      C: 5.89,
      V: 1030520,
      p_w1_L: 5.77,
      p_w1_H: 6.3,
      p_m1_L: 5.7,
      p_m1_H: 6.8,
      p_m3_L: 5.51,
      p_m3_H: 6.94,
      p_m6_L: 5.51,
      p_m6_H: 10.9,
      p_m12_L: 3.02,
      p_m12_H: 10.9,
    },
    1831: {
      H: 0.24,
      L: 0.24,
      O: 0.24,
      C: 0.24,
      V: 0,
      p_w1_L: 0.24,
      p_w1_H: 0.243,
      p_m1_L: 0.24,
      p_m1_H: 0.275,
      p_m3_L: 0.24,
      p_m3_H: 0.38,
      p_m6_L: 0.24,
      p_m6_H: 0.45,
      p_m12_L: 0.101,
      p_m12_H: 0.45,
    },
    1832: {
      H: 0.93,
      L: 0.93,
      O: 0.93,
      C: 0.93,
      V: 9000,
      p_w1_L: 0.9,
      p_w1_H: 0.93,
      p_m1_L: 0.9,
      p_m1_H: 1.09,
      p_m3_L: 0.85,
      p_m3_H: 1.09,
      p_m6_L: 0.85,
      p_m6_H: 1.17,
      p_m12_L: 0.63,
      p_m12_H: 1.17,
    },
    1833: {
      H: 30.05,
      L: 28.85,
      O: 30.05,
      C: 29.25,
      V: 3334610,
      p_w1_L: 26.95,
      p_w1_H: 30.05,
      p_m1_L: 26.95,
      p_m1_H: 35.95,
      p_m3_L: 26.95,
      p_m3_H: 58.3,
      p_m6_L: 26.95,
      p_m6_H: 97.5,
      p_m12_L: 26.95,
      p_m12_H: 148.5,
    },
    1835: {
      H: 4,
      L: 4,
      O: 4,
      C: 4,
      V: 0,
      p_w1_L: 4,
      p_w1_H: 4.05,
      p_m1_L: 4,
      p_m1_H: 4.05,
      p_m3_L: 3.87,
      p_m3_H: 4.2,
      p_m6_L: 3.87,
      p_m6_H: 4.6,
      p_m12_L: 3,
      p_m12_H: 4.99,
    },
    1836: {
      H: 8.96,
      L: 8.73,
      O: 8.9,
      C: 8.88,
      V: 40500,
      p_w1_L: 8.5,
      p_w1_H: 9.49,
      p_m1_L: 8.36,
      p_m1_H: 10.08,
      p_m3_L: 8.36,
      p_m3_H: 10.54,
      p_m6_L: 8.36,
      p_m6_H: 12.26,
      p_m12_L: 8.07,
      p_m12_H: 12.26,
    },
    1837: {
      H: 0.54,
      L: 0.52,
      O: 0.54,
      C: 0.52,
      V: 634000,
      p_w1_L: 0.5,
      p_w1_H: 0.6,
      p_m1_L: 0.5,
      p_m1_H: 0.67,
      p_m3_L: 0.5,
      p_m3_H: 0.67,
      p_m6_L: 0.435,
      p_m6_H: 0.68,
      p_m12_L: 0.435,
      p_m12_H: 0.78,
    },
    1838: {
      H: 0.415,
      L: 0.415,
      O: 0.415,
      C: 0.415,
      V: 0,
      p_w1_L: 0.415,
      p_w1_H: 0.415,
      p_m1_L: 0.415,
      p_m1_H: 0.415,
      p_m3_L: 0.415,
      p_m3_H: 0.415,
      p_m6_L: 0.415,
      p_m6_H: 0.415,
      p_m12_L: 0.4,
      p_m12_H: 0.62,
    },
    1839: {
      H: 6.91,
      L: 6.25,
      O: 6.55,
      C: 6.6,
      V: 1061000,
      p_w1_L: 4.9,
      p_w1_H: 7.09,
      p_m1_L: 4.81,
      p_m1_H: 7.09,
      p_m3_L: 4.81,
      p_m3_H: 7.1,
      p_m6_L: 4.81,
      p_m6_H: 8.27,
      p_m12_L: 4.81,
      p_m12_H: 8.45,
    },
    1841: {
      H: 0.46,
      L: 0.46,
      O: 0.46,
      C: 0.46,
      V: 70000,
      p_w1_L: 0.46,
      p_w1_H: 0.48,
      p_m1_L: 0.46,
      p_m1_H: 0.52,
      p_m3_L: 0.42,
      p_m3_H: 0.59,
      p_m6_L: 0.39,
      p_m6_H: 0.67,
      p_m12_L: 0.33,
      p_m12_H: 0.68,
    },
    1842: {
      H: 0.425,
      L: 0.355,
      O: 0.36,
      C: 0.375,
      V: 26964000,
      p_w1_L: 0.33,
      p_w1_H: 0.425,
      p_m1_L: 0.28,
      p_m1_H: 0.465,
      p_m3_L: 0.23,
      p_m3_H: 0.465,
      p_m6_L: 0.23,
      p_m6_H: 0.465,
      p_m12_L: 0.15,
      p_m12_H: 0.58,
    },
    1843: {
      H: 0.285,
      L: 0.275,
      O: 0.285,
      C: 0.275,
      V: 796000,
      p_w1_L: 0.275,
      p_w1_H: 0.285,
      p_m1_L: 0.275,
      p_m1_H: 0.3,
      p_m3_L: 0.25,
      p_m3_H: 0.36,
      p_m6_L: 0.25,
      p_m6_H: 0.435,
      p_m12_L: 0.188,
      p_m12_H: 0.465,
    },
    1845: {
      H: 0.39,
      L: 0.385,
      O: 0.39,
      C: 0.39,
      V: 252000,
      p_w1_L: 0.385,
      p_w1_H: 0.415,
      p_m1_L: 0.385,
      p_m1_H: 0.465,
      p_m3_L: 0.37,
      p_m3_H: 0.66,
      p_m6_L: 0.345,
      p_m6_H: 0.66,
      p_m12_L: 0.285,
      p_m12_H: 0.66,
    },
    1846: {
      H: 9.65,
      L: 9.4,
      O: 9.43,
      C: 9.44,
      V: 67000,
      p_w1_L: 8.89,
      p_w1_H: 9.7,
      p_m1_L: 8.65,
      p_m1_H: 10.4,
      p_m3_L: 8.65,
      p_m3_H: 12.6,
      p_m6_L: 8.65,
      p_m6_H: 14.9,
      p_m12_L: 6.2,
      p_m12_H: 14.9,
    },
    1847: {
      H: 1.25,
      L: 1.25,
      O: 1.25,
      C: 1.26,
      V: 1000,
      p_w1_L: 1.24,
      p_w1_H: 1.3,
      p_m1_L: 1.24,
      p_m1_H: 1.43,
      p_m3_L: 1.24,
      p_m3_H: 1.59,
      p_m6_L: 1.24,
      p_m6_H: 1.73,
      p_m12_L: 1.24,
      p_m12_H: 2.07,
    },
    1848: {
      H: 5.1,
      L: 5.04,
      O: 5.1,
      C: 5.1,
      V: 80000,
      p_w1_L: 4.98,
      p_w1_H: 5.1,
      p_m1_L: 4.98,
      p_m1_H: 5.32,
      p_m3_L: 4.98,
      p_m3_H: 5.69,
      p_m6_L: 4.98,
      p_m6_H: 6.25,
      p_m12_L: 4.98,
      p_m12_H: 7.68,
    },
    1849: {
      H: 0.59,
      L: 0.55,
      O: 0.59,
      C: 0.55,
      V: 150000,
      p_w1_L: 0.52,
      p_w1_H: 0.62,
      p_m1_L: 0.52,
      p_m1_H: 0.7,
      p_m3_L: 0.52,
      p_m3_H: 0.74,
      p_m6_L: 0.5,
      p_m6_H: 0.94,
      p_m12_L: 0.475,
      p_m12_H: 0.94,
    },
    1850: {
      H: 0.375,
      L: 0.181,
      O: 0.199,
      C: 0.35,
      V: 148431000,
      p_w1_L: 0.181,
      p_w1_H: 0.58,
      p_m1_L: 0.181,
      p_m1_H: 1.07,
      p_m3_L: 0.181,
      p_m3_H: 1.5,
      p_m6_L: 0.181,
      p_m6_H: 3.87,
      p_m12_L: 0.181,
      p_m12_H: 3.87,
    },
    1851: {
      H: 1.25,
      L: 1.21,
      O: 1.23,
      C: 1.23,
      V: 208000,
      p_w1_L: 1.16,
      p_w1_H: 1.27,
      p_m1_L: 1.14,
      p_m1_H: 1.38,
      p_m3_L: 1,
      p_m3_H: 1.46,
      p_m6_L: 1,
      p_m6_H: 1.52,
      p_m12_L: 1,
      p_m12_H: 1.75,
    },
    1853: {
      H: 1.95,
      L: 1.93,
      O: 1.93,
      C: 1.95,
      V: 12500,
      p_w1_L: 1.93,
      p_w1_H: 2.04,
      p_m1_L: 1.9,
      p_m1_H: 2.04,
      p_m3_L: 1.9,
      p_m3_H: 2.2,
      p_m6_L: 1.83,
      p_m6_H: 2.38,
      p_m12_L: 1.7,
      p_m12_H: 5.8,
    },
    1854: {
      H: 0.26,
      L: 0.25,
      O: 0.26,
      C: 0.255,
      V: 700000,
      p_w1_L: 0.24,
      p_w1_H: 0.325,
      p_m1_L: 0.223,
      p_m1_H: 0.325,
      p_m3_L: 0.157,
      p_m3_H: 0.325,
      p_m6_L: 0.14,
      p_m6_H: 0.325,
      p_m12_L: 0.07,
      p_m12_H: 0.325,
    },
    1855: {
      H: 3.25,
      L: 2.85,
      O: 3.09,
      C: 2.88,
      V: 1566000,
      p_w1_L: 2.71,
      p_w1_H: 3.25,
      p_m1_L: 2.1,
      p_m1_H: 3.25,
      p_m3_L: 1.92,
      p_m3_H: 3.25,
      p_m6_L: 1.86,
      p_m6_H: 3.25,
      p_m12_L: 1.7,
      p_m12_H: 3.25,
    },
    1856: {
      H: 2.58,
      L: 2.58,
      O: 2.58,
      C: 2.58,
      V: 0,
      p_w1_L: 2.58,
      p_w1_H: 2.63,
      p_m1_L: 2.3,
      p_m1_H: 2.67,
      p_m3_L: 2.22,
      p_m3_H: 2.78,
      p_m6_L: 2.22,
      p_m6_H: 2.78,
      p_m12_L: 2.04,
      p_m12_H: 3.25,
    },
    1857: {
      H: 1.75,
      L: 1.69,
      O: 1.71,
      C: 1.7,
      V: 382000,
      p_w1_L: 1.65,
      p_w1_H: 1.77,
      p_m1_L: 1.62,
      p_m1_H: 1.8,
      p_m3_L: 1.62,
      p_m3_H: 2.23,
      p_m6_L: 1.34,
      p_m6_H: 2.23,
      p_m12_L: 1.23,
      p_m12_H: 2.23,
    },
    1858: {
      H: 17.12,
      L: 16.66,
      O: 16.9,
      C: 16.98,
      V: 285000,
      p_w1_L: 16.04,
      p_w1_H: 18.88,
      p_m1_L: 16.04,
      p_m1_H: 22.2,
      p_m3_L: 15,
      p_m3_H: 23,
      p_m6_L: 15,
      p_m6_H: 34.95,
      p_m12_L: 15,
      p_m12_H: 34.95,
    },
    1859: {
      H: 0.7,
      L: 0.68,
      O: 0.68,
      C: 0.7,
      V: 7891000,
      p_w1_L: 0.66,
      p_w1_H: 0.72,
      p_m1_L: 0.64,
      p_m1_H: 0.82,
      p_m3_L: 0.61,
      p_m3_H: 1.17,
      p_m6_L: 0.56,
      p_m6_H: 1.17,
      p_m12_L: 0.56,
      p_m12_H: 1.87,
    },
    1860: {
      H: 6.46,
      L: 6.26,
      O: 6.4,
      C: 6.43,
      V: 2688140,
      p_w1_L: 6,
      p_w1_H: 6.9,
      p_m1_L: 6,
      p_m1_H: 7.98,
      p_m3_L: 6,
      p_m3_H: 8.65,
      p_m6_L: 6,
      p_m6_H: 11.3,
      p_m12_L: 3.5,
      p_m12_H: 11.44,
    },
    1861: {
      H: 2.14,
      L: 1.91,
      O: 1.93,
      C: 2.14,
      V: 46000,
      p_w1_L: 1.88,
      p_w1_H: 2.58,
      p_m1_L: 1.76,
      p_m1_H: 2.58,
      p_m3_L: 1.76,
      p_m3_H: 3.1,
      p_m6_L: 1.76,
      p_m6_H: 4.5,
      p_m12_L: 1.59,
      p_m12_H: 4.91,
    },
    1862: {
      H: 2.08,
      L: 2,
      O: 2.08,
      C: 2.04,
      V: 1638000,
      p_w1_L: 1.91,
      p_w1_H: 2.08,
      p_m1_L: 1.91,
      p_m1_H: 2.11,
      p_m3_L: 1.91,
      p_m3_H: 2.57,
      p_m6_L: 1.91,
      p_m6_H: 2.7,
      p_m12_L: 1.91,
      p_m12_H: 2.7,
    },
    1863: {
      H: 1.37,
      L: 1.37,
      O: 1.37,
      C: 1.37,
      V: 0,
      p_w1_L: 1.37,
      p_w1_H: 1.37,
      p_m1_L: 1.37,
      p_m1_H: 1.37,
      p_m3_L: 1.37,
      p_m3_H: 1.37,
      p_m6_L: 1.37,
      p_m6_H: 1.37,
      p_m12_L: 1.37,
      p_m12_H: 1.37,
    },
    1865: {
      H: 1.03,
      L: 0.91,
      O: 0.93,
      C: 0.92,
      V: 9972000,
      p_w1_L: 0.85,
      p_w1_H: 1.03,
      p_m1_L: 0.82,
      p_m1_H: 1.12,
      p_m3_L: 0.82,
      p_m3_H: 1.17,
      p_m6_L: 0.82,
      p_m6_H: 1.35,
      p_m12_L: 0.81,
      p_m12_H: 1.5,
    },
    1866: {
      H: 5.1,
      L: 4.91,
      O: 5.1,
      C: 5.02,
      V: 901000,
      p_w1_L: 4.55,
      p_w1_H: 5.1,
      p_m1_L: 4.55,
      p_m1_H: 5.47,
      p_m3_L: 4.55,
      p_m3_H: 7.35,
      p_m6_L: 3.75,
      p_m6_H: 7.35,
      p_m12_L: 2.44,
      p_m12_H: 7.35,
    },
    1867: {
      H: 0.335,
      L: 0.32,
      O: 0.33,
      C: 0.33,
      V: 754000,
      p_w1_L: 0.31,
      p_w1_H: 0.335,
      p_m1_L: 0.31,
      p_m1_H: 0.355,
      p_m3_L: 0.3,
      p_m3_H: 0.44,
      p_m6_L: 0.221,
      p_m6_H: 0.44,
      p_m12_L: 0.084,
      p_m12_H: 0.59,
    },
    1868: {
      H: 0.48,
      L: 0.475,
      O: 0.475,
      C: 0.48,
      V: 30000,
      p_w1_L: 0.475,
      p_w1_H: 0.48,
      p_m1_L: 0.46,
      p_m1_H: 0.53,
      p_m3_L: 0.45,
      p_m3_H: 0.54,
      p_m6_L: 0.45,
      p_m6_H: 0.54,
      p_m12_L: 0.45,
      p_m12_H: 0.57,
    },
    1869: {
      H: 0.35,
      L: 0.33,
      O: 0.33,
      C: 0.345,
      V: 100000,
      p_w1_L: 0.33,
      p_w1_H: 0.35,
      p_m1_L: 0.32,
      p_m1_H: 0.35,
      p_m3_L: 0.31,
      p_m3_H: 0.38,
      p_m6_L: 0.31,
      p_m6_H: 0.42,
      p_m12_L: 0.22,
      p_m12_H: 0.42,
    },
    1870: {
      H: 1.49,
      L: 1.18,
      O: 1.2,
      C: 1.41,
      V: 9900000,
      p_w1_L: 1.08,
      p_w1_H: 1.49,
      p_m1_L: 1.02,
      p_m1_H: 1.49,
      p_m3_L: 0.72,
      p_m3_H: 1.49,
      p_m6_L: 0.19,
      p_m6_H: 1.49,
      p_m12_L: 0.178,
      p_m12_H: 1.49,
    },
    1871: {
      H: 0.275,
      L: 0.26,
      O: 0.275,
      C: 0.265,
      V: 44000,
      p_w1_L: 0.255,
      p_w1_H: 0.28,
      p_m1_L: 0.255,
      p_m1_H: 0.31,
      p_m3_L: 0.25,
      p_m3_H: 0.31,
      p_m6_L: 0.25,
      p_m6_H: 0.345,
      p_m12_L: 0.227,
      p_m12_H: 0.385,
    },
    1872: {
      H: 0.19,
      L: 0.183,
      O: 0.189,
      C: 0.189,
      V: 90000,
      p_w1_L: 0.183,
      p_w1_H: 0.2,
      p_m1_L: 0.183,
      p_m1_H: 0.215,
      p_m3_L: 0.162,
      p_m3_H: 0.215,
      p_m6_L: 0.142,
      p_m6_H: 0.215,
      p_m12_L: 0.11,
      p_m12_H: 0.223,
    },
    1873: {
      H: 5.93,
      L: 5.47,
      O: 5.48,
      C: 5.78,
      V: 16828500,
      p_w1_L: 4.44,
      p_w1_H: 5.93,
      p_m1_L: 4.44,
      p_m1_H: 5.93,
      p_m3_L: 4.44,
      p_m3_H: 6.84,
      p_m6_L: 4.44,
      p_m6_H: 11.64,
      p_m12_L: 4.44,
      p_m12_H: 11.64,
    },
    1875: {
      H: 4,
      L: 4,
      O: 4,
      C: 4,
      V: 14800,
      p_w1_L: 3.91,
      p_w1_H: 4.32,
      p_m1_L: 3.91,
      p_m1_H: 4.45,
      p_m3_L: 3.91,
      p_m3_H: 4.75,
      p_m6_L: 3.9,
      p_m6_H: 4.85,
      p_m12_L: 3.7,
      p_m12_H: 5,
    },
    1876: {
      H: 20.1,
      L: 19.68,
      O: 20,
      C: 20.05,
      V: 3641470,
      p_w1_L: 19.08,
      p_w1_H: 20.2,
      p_m1_L: 19.08,
      p_m1_H: 21.7,
      p_m3_L: 18.18,
      p_m3_H: 21.85,
      p_m6_L: 18.18,
      p_m6_H: 27.05,
      p_m12_L: 18.18,
      p_m12_H: 28,
    },
    1877: {
      H: 44.4,
      L: 42.55,
      O: 43.5,
      C: 42.55,
      V: 631000,
      p_w1_L: 42.4,
      p_w1_H: 48.4,
      p_m1_L: 37.6,
      p_m1_H: 49.5,
      p_m3_L: 36.5,
      p_m3_H: 49.5,
      p_m6_L: 36.5,
      p_m6_H: 78.5,
      p_m12_L: 36.5,
      p_m12_H: 97.15,
    },
    1878: {
      H: 1.23,
      L: 0.99,
      O: 1.18,
      C: 1.01,
      V: 634950,
      p_w1_L: 0.99,
      p_w1_H: 1.23,
      p_m1_L: 0.99,
      p_m1_H: 1.41,
      p_m3_L: 0.99,
      p_m3_H: 2.31,
      p_m6_L: 0.99,
      p_m6_H: 2.31,
      p_m12_L: 0.5,
      p_m12_H: 2.31,
    },
    1881: {
      H: 1.44,
      L: 1.41,
      O: 1.44,
      C: 1.42,
      V: 509000,
      p_w1_L: 1.41,
      p_w1_H: 1.5,
      p_m1_L: 1.41,
      p_m1_H: 1.52,
      p_m3_L: 1.32,
      p_m3_H: 1.56,
      p_m6_L: 1.32,
      p_m6_H: 1.72,
      p_m12_L: 1.28,
      p_m12_H: 1.8,
    },
    1882: {
      H: 21.25,
      L: 19.92,
      O: 19.92,
      C: 21.15,
      V: 2954000,
      p_w1_L: 19.76,
      p_w1_H: 22.15,
      p_m1_L: 19,
      p_m1_H: 22.6,
      p_m3_L: 19,
      p_m3_H: 29.95,
      p_m6_L: 19,
      p_m6_H: 31.5,
      p_m12_L: 19,
      p_m12_H: 32.55,
    },
    1883: {
      H: 2.66,
      L: 2.63,
      O: 2.64,
      C: 2.63,
      V: 1377000,
      p_w1_L: 2.62,
      p_w1_H: 2.68,
      p_m1_L: 2.59,
      p_m1_H: 2.74,
      p_m3_L: 2.59,
      p_m3_H: 2.8,
      p_m6_L: 2.47,
      p_m6_H: 2.8,
      p_m12_L: 2.41,
      p_m12_H: 2.88,
    },
    1884: {
      H: 0.295,
      L: 0.295,
      O: 0.295,
      C: 0.295,
      V: 0,
      p_w1_L: 0.295,
      p_w1_H: 0.295,
      p_m1_L: 0.265,
      p_m1_H: 0.31,
      p_m3_L: 0.265,
      p_m3_H: 0.385,
      p_m6_L: 0.265,
      p_m6_H: 0.45,
      p_m12_L: 0.184,
      p_m12_H: 0.7,
    },
    1885: {
      H: 0.9,
      L: 0.9,
      O: 0.9,
      C: 0.9,
      V: 0,
      p_w1_L: 0.9,
      p_w1_H: 0.9,
      p_m1_L: 0.9,
      p_m1_H: 0.9,
      p_m3_L: 0.9,
      p_m3_H: 0.9,
      p_m6_L: 0.9,
      p_m6_H: 0.9,
      p_m12_L: 0.8,
      p_m12_H: 1.51,
    },
    1888: {
      H: 13.4,
      L: 13.08,
      O: 13.1,
      C: 13.3,
      V: 1673000,
      p_w1_L: 12.88,
      p_w1_H: 14.16,
      p_m1_L: 11.9,
      p_m1_H: 14.88,
      p_m3_L: 11.72,
      p_m3_H: 15.72,
      p_m6_L: 11.72,
      p_m6_H: 18.78,
      p_m12_L: 11.2,
      p_m12_H: 19.76,
    },
    1889: {
      H: 0.15,
      L: 0.137,
      O: 0.15,
      C: 0.141,
      V: 472500,
      p_w1_L: 0.136,
      p_w1_H: 0.155,
      p_m1_L: 0.112,
      p_m1_H: 0.16,
      p_m3_L: 0.112,
      p_m3_H: 0.28,
      p_m6_L: 0.09,
      p_m6_H: 0.28,
      p_m12_L: 0.09,
      p_m12_H: 0.28,
    },
    1890: {
      H: 3.87,
      L: 3.52,
      O: 3.87,
      C: 3.61,
      V: 1474000,
      p_w1_L: 3.45,
      p_w1_H: 4.4,
      p_m1_L: 3.45,
      p_m1_H: 4.84,
      p_m3_L: 3.45,
      p_m3_H: 4.84,
      p_m6_L: 3.45,
      p_m6_H: 5.89,
      p_m12_L: 3.45,
      p_m12_H: 6.28,
    },
    1891: {
      H: 0.243,
      L: 0.231,
      O: 0.231,
      C: 0.243,
      V: 88000,
      p_w1_L: 0.228,
      p_w1_H: 0.244,
      p_m1_L: 0.201,
      p_m1_H: 0.25,
      p_m3_L: 0.18,
      p_m3_H: 0.27,
      p_m6_L: 0.165,
      p_m6_H: 0.27,
      p_m12_L: 0.165,
      p_m12_H: 0.27,
    },
    1894: {
      H: 0.66,
      L: 0.66,
      O: 0.66,
      C: 0.66,
      V: 0,
      p_w1_L: 0.66,
      p_w1_H: 0.66,
      p_m1_L: 0.66,
      p_m1_H: 0.66,
      p_m3_L: 0.66,
      p_m3_H: 0.66,
      p_m6_L: 0.66,
      p_m6_H: 0.66,
      p_m12_L: 0.156,
      p_m12_H: 0.68,
    },
    1895: {
      H: 2.018,
      L: 2.018,
      O: 2.018,
      C: 2.018,
      V: 0,
      p_w1_L: 2.018,
      p_w1_H: 2.018,
      p_m1_L: 2.018,
      p_m1_H: 2.018,
      p_m3_L: 2.018,
      p_m3_H: 2.12,
      p_m6_L: 2.018,
      p_m6_H: 2.12,
      p_m12_L: 1.8,
      p_m12_H: 2.78,
    },
    1896: {
      H: 9.36,
      L: 9.03,
      O: 9.04,
      C: 9.2,
      V: 1072200,
      p_w1_L: 8.63,
      p_w1_H: 9.36,
      p_m1_L: 8.63,
      p_m1_H: 9.71,
      p_m3_L: 8.63,
      p_m3_H: 11.1,
      p_m6_L: 8.63,
      p_m6_H: 13.08,
      p_m12_L: 8.63,
      p_m12_H: 19.3,
    },
    1897: {
      H: 0.76,
      L: 0.76,
      O: 0.76,
      C: 0.76,
      V: 0,
      p_w1_L: 0.75,
      p_w1_H: 0.77,
      p_m1_L: 0.75,
      p_m1_H: 0.85,
      p_m3_L: 0.75,
      p_m3_H: 0.95,
      p_m6_L: 0.75,
      p_m6_H: 0.95,
      p_m12_L: 0.55,
      p_m12_H: 0.95,
    },
    1898: {
      H: 4.6,
      L: 4.46,
      O: 4.5,
      C: 4.59,
      V: 21691400,
      p_w1_L: 3.94,
      p_w1_H: 4.63,
      p_m1_L: 3.92,
      p_m1_H: 4.63,
      p_m3_L: 3.92,
      p_m3_H: 6.87,
      p_m6_L: 3.92,
      p_m6_H: 6.87,
      p_m12_L: 2.12,
      p_m12_H: 6.87,
    },
    1899: {
      H: 1.71,
      L: 1.65,
      O: 1.67,
      C: 1.66,
      V: 1530000,
      p_w1_L: 1.65,
      p_w1_H: 1.72,
      p_m1_L: 1.62,
      p_m1_H: 1.91,
      p_m3_L: 1.62,
      p_m3_H: 1.91,
      p_m6_L: 1.59,
      p_m6_H: 2.27,
      p_m12_L: 1.59,
      p_m12_H: 2.43,
    },
    1900: {
      H: 0.165,
      L: 0.154,
      O: 0.155,
      C: 0.165,
      V: 159000,
      p_w1_L: 0.152,
      p_w1_H: 0.165,
      p_m1_L: 0.152,
      p_m1_H: 0.178,
      p_m3_L: 0.152,
      p_m3_H: 0.194,
      p_m6_L: 0.152,
      p_m6_H: 0.212,
      p_m12_L: 0.147,
      p_m12_H: 0.29,
    },
    1901: {
      H: 1.08,
      L: 1.03,
      O: 1.03,
      C: 1.05,
      V: 2110000,
      p_w1_L: 0.89,
      p_w1_H: 1.11,
      p_m1_L: 0.8,
      p_m1_H: 1.35,
      p_m3_L: 0.79,
      p_m3_H: 1.35,
      p_m6_L: 0.72,
      p_m6_H: 1.35,
      p_m12_L: 0.69,
      p_m12_H: 1.35,
    },
    1902: {
      H: 2.89,
      L: 2.76,
      O: 2.89,
      C: 2.79,
      V: 1318000,
      p_w1_L: 2.6,
      p_w1_H: 3.06,
      p_m1_L: 2.32,
      p_m1_H: 3.06,
      p_m3_L: 2.32,
      p_m3_H: 3.61,
      p_m6_L: 2.32,
      p_m6_H: 7.97,
      p_m12_L: 2.32,
      p_m12_H: 7.97,
    },
    1903: {
      H: 0.55,
      L: 0.55,
      O: 0.55,
      C: 0.55,
      V: 0,
      p_w1_L: 0.5,
      p_w1_H: 0.57,
      p_m1_L: 0.5,
      p_m1_H: 0.64,
      p_m3_L: 0.5,
      p_m3_H: 0.67,
      p_m6_L: 0.5,
      p_m6_H: 0.77,
      p_m12_L: 0.5,
      p_m12_H: 0.93,
    },
    1905: {
      H: 1.02,
      L: 0.99,
      O: 1,
      C: 1.02,
      V: 2958000,
      p_w1_L: 0.99,
      p_w1_H: 1.11,
      p_m1_L: 0.99,
      p_m1_H: 1.14,
      p_m3_L: 0.98,
      p_m3_H: 1.22,
      p_m6_L: 0.98,
      p_m6_H: 1.56,
      p_m12_L: 0.98,
      p_m12_H: 1.82,
    },
    1906: {
      H: 0.55,
      L: 0.52,
      O: 0.55,
      C: 0.53,
      V: 905000,
      p_w1_L: 0.43,
      p_w1_H: 0.58,
      p_m1_L: 0.365,
      p_m1_H: 0.58,
      p_m3_L: 0.27,
      p_m3_H: 0.58,
      p_m6_L: 0.27,
      p_m6_H: 0.68,
      p_m12_L: 0.27,
      p_m12_H: 0.68,
    },
    1907: {
      H: 4.86,
      L: 4.73,
      O: 4.81,
      C: 4.74,
      V: 2752000,
      p_w1_L: 4.64,
      p_w1_H: 4.92,
      p_m1_L: 4.5,
      p_m1_H: 4.92,
      p_m3_L: 4.5,
      p_m3_H: 6.05,
      p_m6_L: 4.5,
      p_m6_H: 6.12,
      p_m12_L: 2.42,
      p_m12_H: 6.48,
    },
    1908: {
      H: 15.42,
      L: 14.7,
      O: 14.78,
      C: 15.2,
      V: 1284130,
      p_w1_L: 14.48,
      p_w1_H: 15.86,
      p_m1_L: 13.14,
      p_m1_H: 15.86,
      p_m3_L: 12.56,
      p_m3_H: 17.16,
      p_m6_L: 12.56,
      p_m6_H: 17.5,
      p_m12_L: 11.04,
      p_m12_H: 17.5,
    },
    1909: {
      H: 1.53,
      L: 1.4,
      O: 1.48,
      C: 1.42,
      V: 5142000,
      p_w1_L: 1.28,
      p_w1_H: 2.17,
      p_m1_L: 1.28,
      p_m1_H: 2.91,
      p_m3_L: 1.28,
      p_m3_H: 2.91,
      p_m6_L: 1.28,
      p_m6_H: 3.28,
      p_m12_L: 1.01,
      p_m12_H: 3.64,
    },
    1910: {
      H: 16.32,
      L: 15.7,
      O: 15.9,
      C: 15.94,
      V: 5721500,
      p_w1_L: 14.02,
      p_w1_H: 16.32,
      p_m1_L: 14.02,
      p_m1_H: 19.5,
      p_m3_L: 13.9,
      p_m3_H: 19.5,
      p_m6_L: 13.16,
      p_m6_H: 19.5,
      p_m12_L: 11.08,
      p_m12_H: 19.5,
    },
    1911: {
      H: 15.12,
      L: 14.44,
      O: 15.12,
      C: 14.86,
      V: 425580,
      p_w1_L: 14.34,
      p_w1_H: 16.38,
      p_m1_L: 14.34,
      p_m1_H: 18.52,
      p_m3_L: 14.34,
      p_m3_H: 22.9,
      p_m6_L: 14.34,
      p_m6_H: 26.15,
      p_m12_L: 13.74,
      p_m12_H: 32.15,
    },
    1912: {
      H: 0.218,
      L: 0.202,
      O: 0.202,
      C: 0.204,
      V: 1385000,
      p_w1_L: 0.201,
      p_w1_H: 0.218,
      p_m1_L: 0.201,
      p_m1_H: 0.242,
      p_m3_L: 0.195,
      p_m3_H: 0.265,
      p_m6_L: 0.195,
      p_m6_H: 0.365,
      p_m12_L: 0.189,
      p_m12_H: 0.43,
    },
    1913: {
      H: 54.35,
      L: 52.5,
      O: 53,
      C: 53.7,
      V: 671200,
      p_w1_L: 49.3,
      p_w1_H: 54.85,
      p_m1_L: 48,
      p_m1_H: 58.3,
      p_m3_L: 40.05,
      p_m3_H: 58.3,
      p_m6_L: 40.05,
      p_m6_H: 65.3,
      p_m12_L: 40.05,
      p_m12_H: 65.3,
    },
    1915: {
      H: 2.07,
      L: 2.07,
      O: 2.07,
      C: 2.07,
      V: 2000,
      p_w1_L: 1.93,
      p_w1_H: 2.08,
      p_m1_L: 1.19,
      p_m1_H: 2.2,
      p_m3_L: 1.19,
      p_m3_H: 2.2,
      p_m6_L: 1.15,
      p_m6_H: 2.25,
      p_m12_L: 0.52,
      p_m12_H: 2.25,
    },
    1916: {
      H: 2.99,
      L: 2.9,
      O: 2.99,
      C: 2.95,
      V: 169500,
      p_w1_L: 2.9,
      p_w1_H: 3.13,
      p_m1_L: 2.9,
      p_m1_H: 3.29,
      p_m3_L: 2.9,
      p_m3_H: 3.48,
      p_m6_L: 2.9,
      p_m6_H: 3.5,
      p_m12_L: 2.9,
      p_m12_H: 3.5,
    },
    1917: {
      H: 0.102,
      L: 0.102,
      O: 0.102,
      C: 0.101,
      V: 10000,
      p_w1_L: 0.096,
      p_w1_H: 0.137,
      p_m1_L: 0.086,
      p_m1_H: 0.146,
      p_m3_L: 0.086,
      p_m3_H: 0.146,
      p_m6_L: 0.086,
      p_m6_H: 0.146,
      p_m12_L: 0.086,
      p_m12_H: 0.275,
    },
    1918: {
      H: 15.62,
      L: 14.92,
      O: 15.48,
      C: 14.96,
      V: 69861200,
      p_w1_L: 12.34,
      p_w1_H: 15.62,
      p_m1_L: 12.34,
      p_m1_H: 17.82,
      p_m3_L: 12.34,
      p_m3_H: 20.65,
      p_m6_L: 12.34,
      p_m6_H: 29.65,
      p_m12_L: 12.34,
      p_m12_H: 35.9,
    },
    1919: {
      H: 14.98,
      L: 14.2,
      O: 14.5,
      C: 14.82,
      V: 47971000,
      p_w1_L: 13.06,
      p_w1_H: 15.2,
      p_m1_L: 10.94,
      p_m1_H: 15.2,
      p_m3_L: 9.76,
      p_m3_H: 16.1,
      p_m6_L: 9.76,
      p_m6_H: 21,
      p_m12_L: 6.59,
      p_m12_H: 21,
    },
    1920: {
      H: 0.05,
      L: 0.046,
      O: 0.049,
      C: 0.05,
      V: 2200000,
      p_w1_L: 0.046,
      p_w1_H: 0.056,
      p_m1_L: 0.046,
      p_m1_H: 0.065,
      p_m3_L: 0.046,
      p_m3_H: 0.079,
      p_m6_L: 0.046,
      p_m6_H: 0.134,
      p_m12_L: 0.046,
      p_m12_H: 0.385,
    },
    1921: {
      H: 2.44,
      L: 2.36,
      O: 2.38,
      C: 2.43,
      V: 3262000,
      p_w1_L: 2.34,
      p_w1_H: 2.44,
      p_m1_L: 1.85,
      p_m1_H: 2.44,
      p_m3_L: 1.85,
      p_m3_H: 2.44,
      p_m6_L: 1.55,
      p_m6_H: 2.5,
      p_m12_L: 0.93,
      p_m12_H: 2.5,
    },
    1922: {
      H: 3.3,
      L: 3.3,
      O: 3.3,
      C: 3.3,
      V: 22000,
      p_w1_L: 3.25,
      p_w1_H: 3.3,
      p_m1_L: 3.17,
      p_m1_H: 3.59,
      p_m3_L: 3.17,
      p_m3_H: 4.02,
      p_m6_L: 3.17,
      p_m6_H: 5.41,
      p_m12_L: 3.17,
      p_m12_H: 7.49,
    },
    1925: {
      H: 1.17,
      L: 1.16,
      O: 1.17,
      C: 1.16,
      V: 94000,
      p_w1_L: 1.15,
      p_w1_H: 1.19,
      p_m1_L: 1.12,
      p_m1_H: 1.19,
      p_m3_L: 1.11,
      p_m3_H: 1.21,
      p_m6_L: 1.06,
      p_m6_H: 1.22,
      p_m12_L: 1.06,
      p_m12_H: 1.54,
    },
    1927: {
      H: 0.25,
      L: 0.248,
      O: 0.25,
      C: 0.248,
      V: 50000,
      p_w1_L: 0.247,
      p_w1_H: 0.255,
      p_m1_L: 0.247,
      p_m1_H: 0.29,
      p_m3_L: 0.247,
      p_m3_H: 0.335,
      p_m6_L: 0.247,
      p_m6_H: 0.435,
      p_m12_L: 0.247,
      p_m12_H: 0.82,
    },
    1928: {
      H: 19.06,
      L: 18.26,
      O: 18.26,
      C: 19,
      V: 24817900,
      p_w1_L: 16.46,
      p_w1_H: 19.06,
      p_m1_L: 16.46,
      p_m1_H: 21.85,
      p_m3_L: 14.64,
      p_m3_H: 27.95,
      p_m6_L: 14.64,
      p_m6_H: 35.1,
      p_m12_L: 14.64,
      p_m12_H: 40.55,
    },
    1929: {
      H: 14,
      L: 13.5,
      O: 13.5,
      C: 14,
      V: 6315670,
      p_w1_L: 13.5,
      p_w1_H: 14.48,
      p_m1_L: 13.5,
      p_m1_H: 17.9,
      p_m3_L: 13.5,
      p_m3_H: 18.54,
      p_m6_L: 13.4,
      p_m6_H: 18.54,
      p_m12_L: 9.3,
      p_m12_H: 18.54,
    },
    1930: {
      H: 0.35,
      L: 0.345,
      O: 0.345,
      C: 0.35,
      V: 240000,
      p_w1_L: 0.295,
      p_w1_H: 0.37,
      p_m1_L: 0.295,
      p_m1_H: 0.37,
      p_m3_L: 0.27,
      p_m3_H: 0.41,
      p_m6_L: 0.27,
      p_m6_H: 0.45,
      p_m12_L: 0.27,
      p_m12_H: 0.89,
    },
    1931: {
      H: 3.16,
      L: 2.96,
      O: 3.15,
      C: 3.01,
      V: 805000,
      p_w1_L: 2.96,
      p_w1_H: 3.6,
      p_m1_L: 2.89,
      p_m1_H: 3.6,
      p_m3_L: 2.61,
      p_m3_H: 3.6,
      p_m6_L: 2.61,
      p_m6_H: 3.6,
      p_m12_L: 1.96,
      p_m12_H: 3.6,
    },
    1932: {
      H: 0.44,
      L: 0.44,
      O: 0.44,
      C: 0.44,
      V: 0,
      p_w1_L: 0.435,
      p_w1_H: 0.48,
      p_m1_L: 0.425,
      p_m1_H: 0.48,
      p_m3_L: 0.425,
      p_m3_H: 0.48,
      p_m6_L: 0.425,
      p_m6_H: 0.49,
      p_m12_L: 0.425,
      p_m12_H: 0.59,
    },
    1933: {
      H: 0.38,
      L: 0.375,
      O: 0.375,
      C: 0.38,
      V: 20000,
      p_w1_L: 0.32,
      p_w1_H: 0.38,
      p_m1_L: 0.32,
      p_m1_H: 0.395,
      p_m3_L: 0.32,
      p_m3_H: 0.42,
      p_m6_L: 0.3,
      p_m6_H: 0.44,
      p_m12_L: 0.23,
      p_m12_H: 0.495,
    },
    1935: {
      H: 3.45,
      L: 3.21,
      O: 3.28,
      C: 3.21,
      V: 834000,
      p_w1_L: 2.49,
      p_w1_H: 3.45,
      p_m1_L: 1.7,
      p_m1_H: 3.45,
      p_m3_L: 1.68,
      p_m3_H: 3.45,
      p_m6_L: 1.66,
      p_m6_H: 5.1,
      p_m12_L: 1.66,
      p_m12_H: 5.21,
    },
    1936: {
      H: 0.55,
      L: 0.52,
      O: 0.55,
      C: 0.53,
      V: 120000,
      p_w1_L: 0.52,
      p_w1_H: 0.55,
      p_m1_L: 0.5,
      p_m1_H: 0.63,
      p_m3_L: 0.5,
      p_m3_H: 1.01,
      p_m6_L: 0.5,
      p_m6_H: 1.2,
      p_m12_L: 0.47,
      p_m12_H: 1.2,
    },
    1937: {
      H: 1.17,
      L: 1.09,
      O: 1.14,
      C: 1.15,
      V: 1135000,
      p_w1_L: 1.07,
      p_w1_H: 1.23,
      p_m1_L: 1.05,
      p_m1_H: 1.4,
      p_m3_L: 1.05,
      p_m3_H: 1.49,
      p_m6_L: 0.118,
      p_m6_H: 1.9,
      p_m12_L: 0.085,
      p_m12_H: 1.9,
    },
    1938: {
      H: 0.29,
      L: 0.265,
      O: 0.27,
      C: 0.285,
      V: 387000,
      p_w1_L: 0.265,
      p_w1_H: 0.295,
      p_m1_L: 0.25,
      p_m1_H: 0.34,
      p_m3_L: 0.25,
      p_m3_H: 0.42,
      p_m6_L: 0.25,
      p_m6_H: 0.48,
      p_m12_L: 0.25,
      p_m12_H: 0.52,
    },
    1939: {
      H: 0.98,
      L: 0.83,
      O: 0.98,
      C: 0.98,
      V: 22000,
      p_w1_L: 0.83,
      p_w1_H: 1.04,
      p_m1_L: 0.72,
      p_m1_H: 1.17,
      p_m3_L: 0.59,
      p_m3_H: 1.17,
      p_m6_L: 0.55,
      p_m6_H: 1.17,
      p_m12_L: 0.36,
      p_m12_H: 1.17,
    },
    1940: {
      H: 3.82,
      L: 3.82,
      O: 3.82,
      C: 3.82,
      V: 0,
      p_w1_L: 3.82,
      p_w1_H: 3.82,
      p_m1_L: 3.82,
      p_m1_H: 3.82,
      p_m3_L: 3.82,
      p_m3_H: 3.82,
      p_m6_L: 3.82,
      p_m6_H: 3.82,
      p_m12_L: 1.45,
      p_m12_H: 4.89,
    },
    1941: {
      H: 0.72,
      L: 0.71,
      O: 0.71,
      C: 0.72,
      V: 12000,
      p_w1_L: 0.68,
      p_w1_H: 0.78,
      p_m1_L: 0.68,
      p_m1_H: 0.79,
      p_m3_L: 0.68,
      p_m3_H: 0.84,
      p_m6_L: 0.62,
      p_m6_H: 0.84,
      p_m12_L: 0.61,
      p_m12_H: 1.1,
    },
    1942: {
      H: 1.2,
      L: 1.16,
      O: 1.19,
      C: 1.16,
      V: 32000,
      p_w1_L: 1.13,
      p_w1_H: 1.29,
      p_m1_L: 0.88,
      p_m1_H: 1.29,
      p_m3_L: 0.86,
      p_m3_H: 1.29,
      p_m6_L: 0.8,
      p_m6_H: 1.29,
      p_m12_L: 0.59,
      p_m12_H: 1.48,
    },
    1943: {
      H: 0.55,
      L: 0.55,
      O: 0.55,
      C: 0.55,
      V: 0,
      p_w1_L: 0.53,
      p_w1_H: 0.55,
      p_m1_L: 0.495,
      p_m1_H: 0.64,
      p_m3_L: 0.44,
      p_m3_H: 0.65,
      p_m6_L: 0.255,
      p_m6_H: 0.85,
      p_m12_L: 0.153,
      p_m12_H: 0.85,
    },
    1945: {
      H: 3.9,
      L: 3.8,
      O: 3.9,
      C: 3.85,
      V: 64000,
      p_w1_L: 3.77,
      p_w1_H: 3.98,
      p_m1_L: 3.7,
      p_m1_H: 4.46,
      p_m3_L: 3.41,
      p_m3_H: 4.9,
      p_m6_L: 3.1,
      p_m6_H: 5.73,
      p_m12_L: 3.1,
      p_m12_H: 15.82,
    },
    1948: {
      H: 5.55,
      L: 5.3,
      O: 5.4,
      C: 5.44,
      V: 41000,
      p_w1_L: 5.29,
      p_w1_H: 6.05,
      p_m1_L: 5.2,
      p_m1_H: 6.85,
      p_m3_L: 5.2,
      p_m3_H: 6.85,
      p_m6_L: 5.2,
      p_m6_H: 6.85,
      p_m12_L: 5.2,
      p_m12_H: 6.85,
    },
    1949: {
      H: 0.21,
      L: 0.21,
      O: 0.21,
      C: 0.21,
      V: 0,
      p_w1_L: 0.21,
      p_w1_H: 0.224,
      p_m1_L: 0.2,
      p_m1_H: 0.235,
      p_m3_L: 0.191,
      p_m3_H: 0.255,
      p_m6_L: 0.191,
      p_m6_H: 0.315,
      p_m12_L: 0.191,
      p_m12_H: 0.375,
    },
    1950: {
      H: 0.118,
      L: 0.105,
      O: 0.115,
      C: 0.113,
      V: 3868000,
      p_w1_L: 0.105,
      p_w1_H: 0.127,
      p_m1_L: 0.105,
      p_m1_H: 0.25,
      p_m3_L: 0.105,
      p_m3_H: 0.255,
      p_m6_L: 0.105,
      p_m6_H: 0.375,
      p_m12_L: 0.105,
      p_m12_H: 1.02,
    },
    1951: {
      H: 10.16,
      L: 9.69,
      O: 9.91,
      C: 9.77,
      V: 18972000,
      p_w1_L: 9.4,
      p_w1_H: 10.88,
      p_m1_L: 9.4,
      p_m1_H: 12.3,
      p_m3_L: 9.4,
      p_m3_H: 13.8,
      p_m6_L: 9.4,
      p_m6_H: 21.4,
      p_m12_L: 9.4,
      p_m12_H: 24.55,
    },
    1952: {
      H: 34.55,
      L: 31.8,
      O: 32.1,
      C: 34.3,
      V: 1002440,
      p_w1_L: 31.8,
      p_w1_H: 36.3,
      p_m1_L: 31.8,
      p_m1_H: 45.95,
      p_m3_L: 31.8,
      p_m3_H: 66.5,
      p_m6_L: 31.8,
      p_m6_H: 79.9,
      p_m12_L: 31.8,
      p_m12_H: 104.8,
    },
    1953: {
      H: 0.193,
      L: 0.185,
      O: 0.191,
      C: 0.193,
      V: 1010000,
      p_w1_L: 0.185,
      p_w1_H: 0.211,
      p_m1_L: 0.185,
      p_m1_H: 0.56,
      p_m3_L: 0.185,
      p_m3_H: 0.56,
      p_m6_L: 0.14,
      p_m6_H: 0.56,
      p_m12_L: 0.131,
      p_m12_H: 0.56,
    },
    1955: {
      H: 1.18,
      L: 1.16,
      O: 1.17,
      C: 1.18,
      V: 32000,
      p_w1_L: 1.15,
      p_w1_H: 1.2,
      p_m1_L: 1.14,
      p_m1_H: 1.2,
      p_m3_L: 1.14,
      p_m3_H: 1.39,
      p_m6_L: 1.12,
      p_m6_H: 1.6,
      p_m12_L: 0.66,
      p_m12_H: 1.6,
    },
    1957: {
      H: 0.58,
      L: 0.54,
      O: 0.56,
      C: 0.56,
      V: 2340000,
      p_w1_L: 0.52,
      p_w1_H: 0.65,
      p_m1_L: 0.5,
      p_m1_H: 0.65,
      p_m3_L: 0.455,
      p_m3_H: 0.78,
      p_m6_L: 0.305,
      p_m6_H: 0.78,
      p_m12_L: 0.305,
      p_m12_H: 0.78,
    },
    1958: {
      H: 3.16,
      L: 3.1,
      O: 3.16,
      C: 3.14,
      V: 9338000,
      p_w1_L: 2.99,
      p_w1_H: 3.24,
      p_m1_L: 2.74,
      p_m1_H: 3.43,
      p_m3_L: 2.55,
      p_m3_H: 3.43,
      p_m6_L: 2.55,
      p_m6_H: 3.76,
      p_m12_L: 2.38,
      p_m12_H: 3.76,
    },
    1959: {
      H: 0.67,
      L: 0.67,
      O: 0.67,
      C: 0.67,
      V: 30000,
      p_w1_L: 0.65,
      p_w1_H: 0.73,
      p_m1_L: 0.65,
      p_m1_H: 0.79,
      p_m3_L: 0.65,
      p_m3_H: 0.91,
      p_m6_L: 0.65,
      p_m6_H: 0.95,
      p_m12_L: 0.415,
      p_m12_H: 1.38,
    },
    1960: {
      H: 0.45,
      L: 0.43,
      O: 0.44,
      C: 0.43,
      V: 1160000,
      p_w1_L: 0.415,
      p_w1_H: 0.455,
      p_m1_L: 0.41,
      p_m1_H: 0.475,
      p_m3_L: 0.37,
      p_m3_H: 0.5,
      p_m6_L: 0.37,
      p_m6_H: 0.5,
      p_m12_L: 0.2,
      p_m12_H: 0.5,
    },
    1961: {
      H: 0.58,
      L: 0.58,
      O: 0.58,
      C: 0.58,
      V: 14000,
      p_w1_L: 0.58,
      p_w1_H: 0.59,
      p_m1_L: 0.48,
      p_m1_H: 0.6,
      p_m3_L: 0.48,
      p_m3_H: 0.69,
      p_m6_L: 0.48,
      p_m6_H: 0.69,
      p_m12_L: 0.48,
      p_m12_H: 0.88,
    },
    1962: {
      H: 1.08,
      L: 1.08,
      O: 1.08,
      C: 1.07,
      V: 2000,
      p_w1_L: 1.05,
      p_w1_H: 1.08,
      p_m1_L: 0.9,
      p_m1_H: 1.1,
      p_m3_L: 0.48,
      p_m3_H: 1.1,
      p_m6_L: 0.41,
      p_m6_H: 1.1,
      p_m12_L: 0.41,
      p_m12_H: 1.74,
    },
    1963: {
      H: 4.41,
      L: 4.35,
      O: 4.37,
      C: 4.4,
      V: 485500,
      p_w1_L: 4.31,
      p_w1_H: 4.41,
      p_m1_L: 4.23,
      p_m1_H: 4.41,
      p_m3_L: 4.05,
      p_m3_H: 4.71,
      p_m6_L: 4.05,
      p_m6_H: 5.36,
      p_m12_L: 4.05,
      p_m12_H: 5.55,
    },
    1965: {
      H: 3.24,
      L: 3.2,
      O: 3.2,
      C: 3.24,
      V: 23000,
      p_w1_L: 3.19,
      p_w1_H: 3.24,
      p_m1_L: 3.17,
      p_m1_H: 3.24,
      p_m3_L: 1.61,
      p_m3_H: 3.32,
      p_m6_L: 1.61,
      p_m6_H: 7.4,
      p_m12_L: 1.61,
      p_m12_H: 7.4,
    },
    1966: {
      H: 2.16,
      L: 2.11,
      O: 2.14,
      C: 2.14,
      V: 8474840,
      p_w1_L: 2.04,
      p_w1_H: 2.17,
      p_m1_L: 2.03,
      p_m1_H: 2.41,
      p_m3_L: 2.03,
      p_m3_H: 3.13,
      p_m6_L: 2.03,
      p_m6_H: 3.7,
      p_m12_L: 2.03,
      p_m12_H: 3.71,
    },
    1967: {
      H: 23.4,
      L: 22,
      O: 23.4,
      C: 22,
      V: 52000,
      p_w1_L: 20.5,
      p_w1_H: 26.1,
      p_m1_L: 17.02,
      p_m1_H: 26.1,
      p_m3_L: 17.02,
      p_m3_H: 36,
      p_m6_L: 13.64,
      p_m6_H: 36,
      p_m12_L: 9.18,
      p_m12_H: 36,
    },
    1968: {
      H: 0.175,
      L: 0.175,
      O: 0.175,
      C: 0.175,
      V: 0,
      p_w1_L: 0.17,
      p_w1_H: 0.18,
      p_m1_L: 0.17,
      p_m1_H: 0.194,
      p_m3_L: 0.17,
      p_m3_H: 0.207,
      p_m6_L: 0.165,
      p_m6_H: 0.23,
      p_m12_L: 0.139,
      p_m12_H: 0.23,
    },
    1969: {
      H: 1.9,
      L: 1.62,
      O: 1.62,
      C: 1.76,
      V: 3067000,
      p_w1_L: 1.3,
      p_w1_H: 1.9,
      p_m1_L: 1.29,
      p_m1_H: 1.9,
      p_m3_L: 1.29,
      p_m3_H: 1.9,
      p_m6_L: 1.29,
      p_m6_H: 1.9,
      p_m12_L: 1.17,
      p_m12_H: 1.9,
    },
    1970: {
      H: 12.8,
      L: 12.52,
      O: 12.76,
      C: 12.7,
      V: 242900,
      p_w1_L: 12.48,
      p_w1_H: 12.9,
      p_m1_L: 11.22,
      p_m1_H: 13.02,
      p_m3_L: 10.8,
      p_m3_H: 13.02,
      p_m6_L: 9.5,
      p_m6_H: 13.02,
      p_m12_L: 9.5,
      p_m12_H: 26.3,
    },
    1971: {
      H: 4.28,
      L: 4.24,
      O: 4.28,
      C: 4.27,
      V: 222000,
      p_w1_L: 4.24,
      p_w1_H: 4.34,
      p_m1_L: 4.13,
      p_m1_H: 4.45,
      p_m3_L: 3.8,
      p_m3_H: 4.7,
      p_m6_L: 3.69,
      p_m6_H: 4.78,
      p_m12_L: 3.69,
      p_m12_H: 5.8,
    },
    1972: {
      H: 19.2,
      L: 18.84,
      O: 19.08,
      C: 19.18,
      V: 2547520,
      p_w1_L: 18.38,
      p_w1_H: 19.2,
      p_m1_L: 18.38,
      p_m1_H: 21.65,
      p_m3_L: 18.38,
      p_m3_H: 21.7,
      p_m6_L: 18.38,
      p_m6_H: 24.2,
      p_m12_L: 18.38,
      p_m12_H: 25.6,
    },
    1975: {
      H: 0.7,
      L: 0.7,
      O: 0.7,
      C: 0.7,
      V: 0,
      p_w1_L: 0.68,
      p_w1_H: 0.7,
      p_m1_L: 0.68,
      p_m1_H: 0.76,
      p_m3_L: 0.68,
      p_m3_H: 0.8,
      p_m6_L: 0.6,
      p_m6_H: 0.84,
      p_m12_L: 0.54,
      p_m12_H: 0.97,
    },
    1977: {
      H: 1.88,
      L: 1.84,
      O: 1.84,
      C: 1.88,
      V: 258000,
      p_w1_L: 1.79,
      p_w1_H: 1.88,
      p_m1_L: 1.75,
      p_m1_H: 1.99,
      p_m3_L: 1.72,
      p_m3_H: 1.99,
      p_m6_L: 1.72,
      p_m6_H: 2.19,
      p_m12_L: 1.2,
      p_m12_H: 2.19,
    },
    1978: {
      H: 1.03,
      L: 1.01,
      O: 1.02,
      C: 1.01,
      V: 302000,
      p_w1_L: 0.98,
      p_w1_H: 1.03,
      p_m1_L: 0.97,
      p_m1_H: 1.1,
      p_m3_L: 0.9,
      p_m3_H: 1.1,
      p_m6_L: 0.85,
      p_m6_H: 1.1,
      p_m12_L: 0.4,
      p_m12_H: 1.38,
    },
    1979: {
      H: 1.83,
      L: 1.75,
      O: 1.75,
      C: 1.78,
      V: 180000,
      p_w1_L: 1.69,
      p_w1_H: 1.89,
      p_m1_L: 1.69,
      p_m1_H: 2.02,
      p_m3_L: 1.69,
      p_m3_H: 2.52,
      p_m6_L: 1.69,
      p_m6_H: 2.62,
      p_m12_L: 1.4,
      p_m12_H: 2.75,
    },
    1980: {
      H: 0.86,
      L: 0.82,
      O: 0.84,
      C: 0.86,
      V: 207000,
      p_w1_L: 0.82,
      p_w1_H: 0.93,
      p_m1_L: 0.82,
      p_m1_H: 0.99,
      p_m3_L: 0.8,
      p_m3_H: 1.26,
      p_m6_L: 0.8,
      p_m6_H: 1.41,
      p_m12_L: 0.71,
      p_m12_H: 1.89,
    },
    1981: {
      H: 2.21,
      L: 2,
      O: 2.08,
      C: 2.15,
      V: 4481000,
      p_w1_L: 1.97,
      p_w1_H: 3.11,
      p_m1_L: 1.97,
      p_m1_H: 3.88,
      p_m3_L: 1.97,
      p_m3_H: 3.88,
      p_m6_L: 1.97,
      p_m6_H: 6.59,
      p_m12_L: 1.97,
      p_m12_H: 8.96,
    },
    1982: {
      H: 0.5,
      L: 0.5,
      O: 0.5,
      C: 0.5,
      V: 18000,
      p_w1_L: 0.49,
      p_w1_H: 0.56,
      p_m1_L: 0.48,
      p_m1_H: 0.56,
      p_m3_L: 0.465,
      p_m3_H: 0.56,
      p_m6_L: 0.465,
      p_m6_H: 0.72,
      p_m12_L: 0.365,
      p_m12_H: 0.73,
    },
    1983: {
      H: 2.16,
      L: 2.16,
      O: 2.16,
      C: 2.16,
      V: 0,
      p_w1_L: 2.11,
      p_w1_H: 2.78,
      p_m1_L: 2.11,
      p_m1_H: 2.88,
      p_m3_L: 2.11,
      p_m3_H: 3.06,
      p_m6_L: 2.11,
      p_m6_H: 3.25,
      p_m12_L: 2.11,
      p_m12_H: 3.35,
    },
    1985: {
      H: 1.03,
      L: 1.03,
      O: 1.03,
      C: 1.03,
      V: 0,
      p_w1_L: 0.97,
      p_w1_H: 1.05,
      p_m1_L: 0.96,
      p_m1_H: 1.1,
      p_m3_L: 0.88,
      p_m3_H: 1.1,
      p_m6_L: 0.88,
      p_m6_H: 1.13,
      p_m12_L: 0.72,
      p_m12_H: 1.13,
    },
    1986: {
      H: 1.44,
      L: 1.38,
      O: 1.44,
      C: 1.4,
      V: 484500,
      p_w1_L: 1.38,
      p_w1_H: 1.45,
      p_m1_L: 1.38,
      p_m1_H: 1.6,
      p_m3_L: 1.31,
      p_m3_H: 1.74,
      p_m6_L: 1.24,
      p_m6_H: 1.86,
      p_m12_L: 1.12,
      p_m12_H: 1.86,
    },
    1987: {
      H: 2.35,
      L: 2.24,
      O: 2.35,
      C: 2.3,
      V: 4940000,
      p_w1_L: 2.24,
      p_w1_H: 2.74,
      p_m1_L: 2.24,
      p_m1_H: 3.73,
      p_m3_L: 1.95,
      p_m3_H: 3.84,
      p_m6_L: 1.95,
      p_m6_H: 3.84,
      p_m12_L: 1.95,
      p_m12_H: 5.07,
    },
    1988: {
      H: 3.14,
      L: 3.06,
      O: 3.14,
      C: 3.06,
      V: 28880700,
      p_w1_L: 2.99,
      p_w1_H: 3.14,
      p_m1_L: 2.98,
      p_m1_H: 3.21,
      p_m3_L: 2.56,
      p_m3_H: 3.39,
      p_m6_L: 2.56,
      p_m6_H: 4.12,
      p_m12_L: 2.56,
      p_m12_H: 5.01,
    },
    1989: {
      H: 0.86,
      L: 0.86,
      O: 0.86,
      C: 0.86,
      V: 0,
      p_w1_L: 0.84,
      p_w1_H: 0.86,
      p_m1_L: 0.84,
      p_m1_H: 1.05,
      p_m3_L: 0.84,
      p_m3_H: 1.22,
      p_m6_L: 0.84,
      p_m6_H: 1.4,
      p_m12_L: 0.84,
      p_m12_H: 1.7,
    },
    1991: {
      H: 0.195,
      L: 0.194,
      O: 0.194,
      C: 0.195,
      V: 80000,
      p_w1_L: 0.165,
      p_w1_H: 0.27,
      p_m1_L: 0.144,
      p_m1_H: 0.28,
      p_m3_L: 0.136,
      p_m3_H: 0.28,
      p_m6_L: 0.135,
      p_m6_H: 0.28,
      p_m12_L: 0.125,
      p_m12_H: 0.28,
    },
    1992: {
      H: 9.95,
      L: 9.61,
      O: 9.95,
      C: 9.84,
      V: 244600,
      p_w1_L: 9.12,
      p_w1_H: 9.95,
      p_m1_L: 8.99,
      p_m1_H: 11.6,
      p_m3_L: 8.99,
      p_m3_H: 12.9,
      p_m6_L: 8.5,
      p_m6_H: 15.08,
      p_m12_L: 7.5,
      p_m12_H: 16.2,
    },
    1993: {
      H: 1.3,
      L: 1.3,
      O: 1.3,
      C: 1.3,
      V: 0,
      p_w1_L: 1.25,
      p_w1_H: 1.3,
      p_m1_L: 0.97,
      p_m1_H: 1.36,
      p_m3_L: 0.97,
      p_m3_H: 1.36,
      p_m6_L: 0.97,
      p_m6_H: 2.2,
      p_m12_L: 0.97,
      p_m12_H: 4.94,
    },
    1995: {
      H: 15,
      L: 14.4,
      O: 14.68,
      C: 14.88,
      V: 3066600,
      p_w1_L: 12.62,
      p_w1_H: 15,
      p_m1_L: 12.56,
      p_m1_H: 15.2,
      p_m3_L: 12,
      p_m3_H: 17.9,
      p_m6_L: 12,
      p_m6_H: 21.1,
      p_m12_L: 12,
      p_m12_H: 26.05,
    },
    1996: {
      H: 2.18,
      L: 2.12,
      O: 2.18,
      C: 2.12,
      V: 3819150,
      p_w1_L: 2.1,
      p_w1_H: 2.24,
      p_m1_L: 2.01,
      p_m1_H: 2.28,
      p_m3_L: 2.01,
      p_m3_H: 2.68,
      p_m6_L: 2.01,
      p_m6_H: 2.74,
      p_m12_L: 2.01,
      p_m12_H: 3,
    },
    1997: {
      H: 41.35,
      L: 39.3,
      O: 41,
      C: 40.55,
      V: 2900870,
      p_w1_L: 39.05,
      p_w1_H: 42.2,
      p_m1_L: 39.05,
      p_m1_H: 45.8,
      p_m3_L: 35.1,
      p_m3_H: 45.8,
      p_m6_L: 35.1,
      p_m6_H: 46.25,
      p_m12_L: 35.1,
      p_m12_H: 49.6,
    },
    1999: {
      H: 12.38,
      L: 11.72,
      O: 12.02,
      C: 11.92,
      V: 13598900,
      p_w1_L: 11.2,
      p_w1_H: 12.68,
      p_m1_L: 11.02,
      p_m1_H: 14.58,
      p_m3_L: 9.71,
      p_m3_H: 15.2,
      p_m6_L: 9.71,
      p_m6_H: 20.4,
      p_m12_L: 9.71,
      p_m12_H: 22.75,
    },
    2000: {
      H: 0.435,
      L: 0.415,
      O: 0.415,
      C: 0.425,
      V: 872000,
      p_w1_L: 0.405,
      p_w1_H: 0.435,
      p_m1_L: 0.39,
      p_m1_H: 0.44,
      p_m3_L: 0.38,
      p_m3_H: 0.44,
      p_m6_L: 0.355,
      p_m6_H: 0.48,
      p_m12_L: 0.31,
      p_m12_H: 0.51,
    },
    2001: {
      H: 3.29,
      L: 3.17,
      O: 3.26,
      C: 3.21,
      V: 5134000,
      p_w1_L: 3.17,
      p_w1_H: 3.94,
      p_m1_L: 3.17,
      p_m1_H: 4.25,
      p_m3_L: 3.15,
      p_m3_H: 4.53,
      p_m6_L: 3.15,
      p_m6_H: 5.98,
      p_m12_L: 3.15,
      p_m12_H: 6.55,
    },
    2002: {
      H: 2.14,
      L: 2.06,
      O: 2.14,
      C: 2.11,
      V: 2270000,
      p_w1_L: 2.06,
      p_w1_H: 2.21,
      p_m1_L: 1.46,
      p_m1_H: 2.22,
      p_m3_L: 1.46,
      p_m3_H: 2.26,
      p_m6_L: 1.46,
      p_m6_H: 2.26,
      p_m12_L: 1.38,
      p_m12_H: 2.8,
    },
    2003: {
      H: 3.8,
      L: 3.8,
      O: 3.8,
      C: 3.8,
      V: 1800,
      p_w1_L: 3.8,
      p_w1_H: 3.92,
      p_m1_L: 3.8,
      p_m1_H: 4.28,
      p_m3_L: 3.8,
      p_m3_H: 5.39,
      p_m6_L: 3.8,
      p_m6_H: 6.92,
      p_m12_L: 3.37,
      p_m12_H: 6.92,
    },
    2005: {
      H: 3.68,
      L: 3.53,
      O: 3.53,
      C: 3.58,
      V: 15284000,
      p_w1_L: 3.47,
      p_w1_H: 3.91,
      p_m1_L: 3.46,
      p_m1_H: 4,
      p_m3_L: 3.44,
      p_m3_H: 4.83,
      p_m6_L: 3.44,
      p_m6_H: 8.29,
      p_m12_L: 3.44,
      p_m12_H: 8.29,
    },
    2006: {
      H: 2.93,
      L: 2.9,
      O: 2.93,
      C: 2.9,
      V: 1534000,
      p_w1_L: 2.87,
      p_w1_H: 2.93,
      p_m1_L: 1.79,
      p_m1_H: 2.93,
      p_m3_L: 1.52,
      p_m3_H: 2.93,
      p_m6_L: 1.5,
      p_m6_H: 2.93,
      p_m12_L: 1.16,
      p_m12_H: 2.93,
    },
    2007: {
      H: 7.4,
      L: 7.19,
      O: 7.35,
      C: 7.3,
      V: 17113500,
      p_w1_L: 6.74,
      p_w1_H: 7.55,
      p_m1_L: 6.64,
      p_m1_H: 7.85,
      p_m3_L: 6.5,
      p_m3_H: 8.58,
      p_m6_L: 6.5,
      p_m6_H: 9.68,
      p_m12_L: 6.5,
      p_m12_H: 10.88,
    },
    2008: {
      H: 0.495,
      L: 0.475,
      O: 0.49,
      C: 0.48,
      V: 1636000,
      p_w1_L: 0.475,
      p_w1_H: 0.52,
      p_m1_L: 0.475,
      p_m1_H: 0.58,
      p_m3_L: 0.475,
      p_m3_H: 0.63,
      p_m6_L: 0.475,
      p_m6_H: 0.76,
      p_m12_L: 0.31,
      p_m12_H: 1.8,
    },
    2009: {
      H: 1.22,
      L: 1.2,
      O: 1.2,
      C: 1.21,
      V: 8591140,
      p_w1_L: 1.15,
      p_w1_H: 1.22,
      p_m1_L: 1.15,
      p_m1_H: 1.24,
      p_m3_L: 1.15,
      p_m3_H: 1.59,
      p_m6_L: 1.15,
      p_m6_H: 1.59,
      p_m12_L: 1.15,
      p_m12_H: 1.8,
    },
    2011: {
      H: 2.41,
      L: 2.24,
      O: 2.34,
      C: 2.41,
      V: 2004000,
      p_w1_L: 2.21,
      p_w1_H: 2.45,
      p_m1_L: 2.21,
      p_m1_H: 2.45,
      p_m3_L: 2.17,
      p_m3_H: 2.64,
      p_m6_L: 2.17,
      p_m6_H: 2.85,
      p_m12_L: 2.17,
      p_m12_H: 2.85,
    },
    2012: {
      H: 2.8,
      L: 2.8,
      O: 2.8,
      C: 2.8,
      V: 0,
      p_w1_L: 2.68,
      p_w1_H: 3.1,
      p_m1_L: 2.68,
      p_m1_H: 3.3,
      p_m3_L: 2.68,
      p_m3_H: 3.79,
      p_m6_L: 2.5,
      p_m6_H: 4.5,
      p_m12_L: 1.17,
      p_m12_H: 4.9,
    },
    2013: {
      H: 8.66,
      L: 8.23,
      O: 8.43,
      C: 8.62,
      V: 23437700,
      p_w1_L: 7.66,
      p_w1_H: 9.36,
      p_m1_L: 7.66,
      p_m1_H: 11.28,
      p_m3_L: 7.66,
      p_m3_H: 13.5,
      p_m6_L: 7.66,
      p_m6_H: 18.5,
      p_m12_L: 7.66,
      p_m12_H: 33.5,
    },
    2014: {
      H: 0.089,
      L: 0.089,
      O: 0.089,
      C: 0.089,
      V: 0,
      p_w1_L: 0.089,
      p_w1_H: 0.089,
      p_m1_L: 0.089,
      p_m1_H: 0.089,
      p_m3_L: 0.089,
      p_m3_H: 0.089,
      p_m6_L: 0.089,
      p_m6_H: 0.089,
      p_m12_L: 0.08,
      p_m12_H: 0.154,
    },
    2015: {
      H: 124.4,
      L: 119.3,
      O: 122.7,
      C: 122,
      V: 1856600,
      p_w1_L: 112.8,
      p_w1_H: 145,
      p_m1_L: 112.5,
      p_m1_H: 145,
      p_m3_L: 98.05,
      p_m3_H: 145,
      p_m6_L: 98.05,
      p_m6_H: 145,
      p_m12_L: 96.2,
      p_m12_H: 145,
    },
    2016: {
      H: 3.19,
      L: 3.13,
      O: 3.14,
      C: 3.17,
      V: 42000,
      p_w1_L: 3.05,
      p_w1_H: 3.26,
      p_m1_L: 3.03,
      p_m1_H: 3.26,
      p_m3_L: 2.95,
      p_m3_H: 3.61,
      p_m6_L: 2.95,
      p_m6_H: 4.11,
      p_m12_L: 2.95,
      p_m12_H: 4.3,
    },
    2017: {
      H: 0.3,
      L: 0.3,
      O: 0.3,
      C: 0.3,
      V: 0,
      p_w1_L: 0.295,
      p_w1_H: 0.335,
      p_m1_L: 0.28,
      p_m1_H: 0.34,
      p_m3_L: 0.265,
      p_m3_H: 0.42,
      p_m6_L: 0.265,
      p_m6_H: 0.68,
      p_m12_L: 0.265,
      p_m12_H: 0.72,
    },
    2018: {
      H: 33.25,
      L: 32.65,
      O: 32.9,
      C: 32.9,
      V: 2114480,
      p_w1_L: 32,
      p_w1_H: 34.7,
      p_m1_L: 31.35,
      p_m1_H: 37.3,
      p_m3_L: 31.15,
      p_m3_H: 43.1,
      p_m6_L: 31.15,
      p_m6_H: 60,
      p_m12_L: 31.15,
      p_m12_H: 60,
    },
    2019: {
      H: 2.55,
      L: 2.52,
      O: 2.53,
      C: 2.55,
      V: 828000,
      p_w1_L: 2.5,
      p_w1_H: 2.55,
      p_m1_L: 2.48,
      p_m1_H: 2.68,
      p_m3_L: 2.48,
      p_m3_H: 2.9,
      p_m6_L: 2.48,
      p_m6_H: 3.26,
      p_m12_L: 2.48,
      p_m12_H: 3.34,
    },
    2020: {
      H: 129.8,
      L: 126.6,
      O: 127.5,
      C: 127.5,
      V: 3786640,
      p_w1_L: 122.4,
      p_w1_H: 130.3,
      p_m1_L: 121.7,
      p_m1_H: 138.8,
      p_m3_L: 115.9,
      p_m3_H: 170,
      p_m6_L: 115.9,
      p_m6_H: 191.9,
      p_m12_L: 103.6,
      p_m12_H: 191.9,
    },
    2022: {
      H: 0.095,
      L: 0.081,
      O: 0.081,
      C: 0.094,
      V: 3400000,
      p_w1_L: 0.078,
      p_w1_H: 0.095,
      p_m1_L: 0.069,
      p_m1_H: 0.1,
      p_m3_L: 0.065,
      p_m3_H: 0.115,
      p_m6_L: 0.065,
      p_m6_H: 0.115,
      p_m12_L: 0.065,
      p_m12_H: 0.135,
    },
    2023: {
      H: 1.24,
      L: 1.22,
      O: 1.23,
      C: 1.24,
      V: 24000,
      p_w1_L: 1.17,
      p_w1_H: 1.26,
      p_m1_L: 1.08,
      p_m1_H: 1.26,
      p_m3_L: 1.08,
      p_m3_H: 1.35,
      p_m6_L: 1.08,
      p_m6_H: 1.35,
      p_m12_L: 0.88,
      p_m12_H: 1.35,
    },
    2025: {
      H: 0.94,
      L: 0.94,
      O: 0.94,
      C: 0.94,
      V: 0,
      p_w1_L: 0.89,
      p_w1_H: 0.94,
      p_m1_L: 0.78,
      p_m1_H: 0.95,
      p_m3_L: 0.78,
      p_m3_H: 0.98,
      p_m6_L: 0.78,
      p_m6_H: 1.17,
      p_m12_L: 0.73,
      p_m12_H: 1.33,
    },
    2028: {
      H: 0.255,
      L: 0.255,
      O: 0.255,
      C: 0.255,
      V: 0,
      p_w1_L: 0.255,
      p_w1_H: 0.26,
      p_m1_L: 0.126,
      p_m1_H: 0.275,
      p_m3_L: 0.126,
      p_m3_H: 0.275,
      p_m6_L: 0.126,
      p_m6_H: 0.36,
      p_m12_L: 0.105,
      p_m12_H: 0.36,
    },
    2030: {
      H: 3.01,
      L: 2.93,
      O: 2.94,
      C: 2.96,
      V: 121000,
      p_w1_L: 2.93,
      p_w1_H: 3.18,
      p_m1_L: 2.93,
      p_m1_H: 3.46,
      p_m3_L: 2.74,
      p_m3_H: 4.06,
      p_m6_L: 2.74,
      p_m6_H: 4.32,
      p_m12_L: 2.32,
      p_m12_H: 4.32,
    },
    2031: {
      H: 0.226,
      L: 0.224,
      O: 0.226,
      C: 0.225,
      V: 115000,
      p_w1_L: 0.21,
      p_w1_H: 0.25,
      p_m1_L: 0.21,
      p_m1_H: 0.275,
      p_m3_L: 0.21,
      p_m3_H: 0.47,
      p_m6_L: 0.21,
      p_m6_H: 0.47,
      p_m12_L: 0.21,
      p_m12_H: 0.47,
    },
    2033: {
      H: 0.86,
      L: 0.75,
      O: 0.77,
      C: 0.76,
      V: 804000,
      p_w1_L: 0.75,
      p_w1_H: 0.86,
      p_m1_L: 0.75,
      p_m1_H: 0.86,
      p_m3_L: 0.71,
      p_m3_H: 0.92,
      p_m6_L: 0.71,
      p_m6_H: 0.92,
      p_m12_L: 0.7,
      p_m12_H: 0.96,
    },
    2038: {
      H: 1.26,
      L: 1.22,
      O: 1.24,
      C: 1.24,
      V: 8564790,
      p_w1_L: 1.12,
      p_w1_H: 1.29,
      p_m1_L: 1.12,
      p_m1_H: 1.29,
      p_m3_L: 1.11,
      p_m3_H: 1.3,
      p_m6_L: 0.96,
      p_m6_H: 1.31,
      p_m12_L: 0.77,
      p_m12_H: 1.48,
    },
    2039: {
      H: 14.98,
      L: 14.58,
      O: 14.96,
      C: 14.72,
      V: 566400,
      p_w1_L: 14.26,
      p_w1_H: 15.66,
      p_m1_L: 13.86,
      p_m1_H: 15.66,
      p_m3_L: 13.86,
      p_m3_H: 18.9,
      p_m6_L: 13.86,
      p_m6_H: 18.9,
      p_m12_L: 10.9,
      p_m12_H: 18.9,
    },
    2048: {
      H: 2.62,
      L: 2.12,
      O: 2.24,
      C: 2.5,
      V: 127487000,
      p_w1_L: 1.43,
      p_w1_H: 2.62,
      p_m1_L: 1.23,
      p_m1_H: 2.62,
      p_m3_L: 1.12,
      p_m3_H: 2.62,
      p_m6_L: 1.12,
      p_m6_H: 7.58,
      p_m12_L: 1.12,
      p_m12_H: 9.69,
    },
    2051: {
      H: 0.58,
      L: 0.57,
      O: 0.58,
      C: 0.57,
      V: 90500,
      p_w1_L: 0.56,
      p_w1_H: 0.63,
      p_m1_L: 0.56,
      p_m1_H: 0.7,
      p_m3_L: 0.56,
      p_m3_H: 1.07,
      p_m6_L: 0.56,
      p_m6_H: 1.61,
      p_m12_L: 0.425,
      p_m12_H: 3.48,
    },
    2057: {
      H: 233.8,
      L: 227.8,
      O: 231.2,
      C: 233.4,
      V: 119650,
      p_w1_L: 223.6,
      p_w1_H: 252.6,
      p_m1_L: 215,
      p_m1_H: 261.2,
      p_m3_L: 215,
      p_m3_H: 261.2,
      p_m6_L: 190,
      p_m6_H: 261.2,
      p_m12_L: 190,
      p_m12_H: 299,
    },
    2060: {
      H: 3.38,
      L: 3.25,
      O: 3.35,
      C: 3.38,
      V: 574000,
      p_w1_L: 3.06,
      p_w1_H: 3.44,
      p_m1_L: 3.06,
      p_m1_H: 3.6,
      p_m3_L: 3.06,
      p_m3_H: 3.78,
      p_m6_L: 3.06,
      p_m6_H: 4.12,
      p_m12_L: 2.63,
      p_m12_H: 4.12,
    },
    2066: {
      H: 6.75,
      L: 6.75,
      O: 6.75,
      C: 6.75,
      V: 49000,
      p_w1_L: 6.75,
      p_w1_H: 6.75,
      p_m1_L: 5.71,
      p_m1_H: 6.9,
      p_m3_L: 5.71,
      p_m3_H: 7.3,
      p_m6_L: 5.31,
      p_m6_H: 7.4,
      p_m12_L: 5.31,
      p_m12_H: 7.4,
    },
    2068: {
      H: 1.66,
      L: 1.61,
      O: 1.64,
      C: 1.64,
      V: 1059000,
      p_w1_L: 1.59,
      p_w1_H: 1.74,
      p_m1_L: 1.59,
      p_m1_H: 1.9,
      p_m3_L: 1.58,
      p_m3_H: 3.45,
      p_m6_L: 1.42,
      p_m6_H: 3.45,
      p_m12_L: 1.2,
      p_m12_H: 3.45,
    },
    2078: {
      H: 0.25,
      L: 0.25,
      O: 0.25,
      C: 0.25,
      V: 0,
      p_w1_L: 0.242,
      p_w1_H: 0.28,
      p_m1_L: 0.227,
      p_m1_H: 0.285,
      p_m3_L: 0.226,
      p_m3_H: 0.305,
      p_m6_L: 0.226,
      p_m6_H: 0.36,
      p_m12_L: 0.226,
      p_m12_H: 0.54,
    },
    2080: {
      H: 0.93,
      L: 0.82,
      O: 0.84,
      C: 0.91,
      V: 1208000,
      p_w1_L: 0.77,
      p_w1_H: 0.93,
      p_m1_L: 0.61,
      p_m1_H: 0.93,
      p_m3_L: 0.51,
      p_m3_H: 0.93,
      p_m6_L: 0.425,
      p_m6_H: 0.93,
      p_m12_L: 0.3,
      p_m12_H: 0.93,
    },
    2086: {
      H: 1.18,
      L: 0.98,
      O: 1.18,
      C: 0.99,
      V: 90000,
      p_w1_L: 0.91,
      p_w1_H: 1.18,
      p_m1_L: 0.89,
      p_m1_H: 1.18,
      p_m3_L: 0.66,
      p_m3_H: 1.18,
      p_m6_L: 0.5,
      p_m6_H: 1.18,
      p_m12_L: 0.5,
      p_m12_H: 1.18,
    },
    2088: {
      H: 0.095,
      L: 0.095,
      O: 0.095,
      C: 0.095,
      V: 30000,
      p_w1_L: 0.093,
      p_w1_H: 0.095,
      p_m1_L: 0.093,
      p_m1_H: 0.125,
      p_m3_L: 0.093,
      p_m3_H: 0.162,
      p_m6_L: 0.093,
      p_m6_H: 0.162,
      p_m12_L: 0.074,
      p_m12_H: 0.205,
    },
    2096: {
      H: 9.15,
      L: 8.87,
      O: 8.89,
      C: 9.13,
      V: 2592000,
      p_w1_L: 8.41,
      p_w1_H: 9.35,
      p_m1_L: 7.56,
      p_m1_H: 10.5,
      p_m3_L: 6.45,
      p_m3_H: 10.5,
      p_m6_L: 6.45,
      p_m6_H: 14.98,
      p_m12_L: 6.45,
      p_m12_H: 14.98,
    },
    2098: {
      H: 0.48,
      L: 0.46,
      O: 0.475,
      C: 0.48,
      V: 6226000,
      p_w1_L: 0.415,
      p_w1_H: 0.48,
      p_m1_L: 0.405,
      p_m1_H: 0.51,
      p_m3_L: 0.405,
      p_m3_H: 0.54,
      p_m6_L: 0.405,
      p_m6_H: 0.62,
      p_m12_L: 0.405,
      p_m12_H: 0.76,
    },
    2099: {
      H: 20.8,
      L: 20.2,
      O: 20.3,
      C: 20.65,
      V: 282100,
      p_w1_L: 19.62,
      p_w1_H: 21.6,
      p_m1_L: 19.3,
      p_m1_H: 25.85,
      p_m3_L: 19.3,
      p_m3_H: 26.6,
      p_m6_L: 18.82,
      p_m6_H: 26.6,
      p_m12_L: 10.8,
      p_m12_H: 29.9,
    },
    2100: {
      H: 0.65,
      L: 0.61,
      O: 0.63,
      C: 0.63,
      V: 1932000,
      p_w1_L: 0.6,
      p_w1_H: 0.68,
      p_m1_L: 0.55,
      p_m1_H: 0.85,
      p_m3_L: 0.54,
      p_m3_H: 0.85,
      p_m6_L: 0.54,
      p_m6_H: 1.82,
      p_m12_L: 0.54,
      p_m12_H: 3.25,
    },
    2101: {
      H: 7.99,
      L: 7.52,
      O: 7.71,
      C: 7.73,
      V: 1049500,
      p_w1_L: 7.52,
      p_w1_H: 7.99,
      p_m1_L: 7.5,
      p_m1_H: 8.23,
      p_m3_L: 6.5,
      p_m3_H: 8.53,
      p_m6_L: 6.5,
      p_m6_H: 12.44,
      p_m12_L: 5.11,
      p_m12_H: 12.44,
    },
    2102: {
      H: 0.32,
      L: 0.32,
      O: 0.32,
      C: 0.32,
      V: 0,
      p_w1_L: 0.32,
      p_w1_H: 0.34,
      p_m1_L: 0.32,
      p_m1_H: 0.355,
      p_m3_L: 0.25,
      p_m3_H: 0.355,
      p_m6_L: 0.25,
      p_m6_H: 0.39,
      p_m12_L: 0.22,
      p_m12_H: 0.39,
    },
    2103: {
      H: 0.5,
      L: 0.5,
      O: 0.5,
      C: 0.5,
      V: 0,
      p_w1_L: 0.5,
      p_w1_H: 0.5,
      p_m1_L: 0.5,
      p_m1_H: 0.5,
      p_m3_L: 0.37,
      p_m3_H: 4.21,
      p_m6_L: 0.37,
      p_m6_H: 4.58,
      p_m12_L: 0.37,
      p_m12_H: 4.58,
    },
    2107: {
      H: 1.98,
      L: 1.79,
      O: 1.98,
      C: 1.91,
      V: 5205000,
      p_w1_L: 1.14,
      p_w1_H: 2.01,
      p_m1_L: 1.14,
      p_m1_H: 2.11,
      p_m3_L: 0.8,
      p_m3_H: 2.21,
      p_m6_L: 0.77,
      p_m6_H: 2.21,
      p_m12_L: 0.77,
      p_m12_H: 2.21,
    },
    2108: {
      H: 0.295,
      L: 0.275,
      O: 0.275,
      C: 0.29,
      V: 92000,
      p_w1_L: 0.25,
      p_w1_H: 0.305,
      p_m1_L: 0.25,
      p_m1_H: 0.305,
      p_m3_L: 0.24,
      p_m3_H: 0.305,
      p_m6_L: 0.22,
      p_m6_H: 0.305,
      p_m12_L: 0.144,
      p_m12_H: 0.325,
    },
    2110: {
      H: 0.152,
      L: 0.146,
      O: 0.148,
      C: 0.152,
      V: 1830000,
      p_w1_L: 0.145,
      p_w1_H: 0.152,
      p_m1_L: 0.145,
      p_m1_H: 0.187,
      p_m3_L: 0.126,
      p_m3_H: 0.187,
      p_m6_L: 0.12,
      p_m6_H: 0.187,
      p_m12_L: 0.092,
      p_m12_H: 0.224,
    },
    2111: {
      H: 2.25,
      L: 2.25,
      O: 2.25,
      C: 2.25,
      V: 0,
      p_w1_L: 2.03,
      p_w1_H: 2.25,
      p_m1_L: 1.96,
      p_m1_H: 2.64,
      p_m3_L: 1.96,
      p_m3_H: 3,
      p_m6_L: 1.96,
      p_m6_H: 3,
      p_m12_L: 1.1,
      p_m12_H: 3,
    },
    2112: {
      H: 0.08,
      L: 0.075,
      O: 0.073,
      C: 0.075,
      V: 238000,
      p_w1_L: 0.058,
      p_w1_H: 0.08,
      p_m1_L: 0.058,
      p_m1_H: 0.081,
      p_m3_L: 0.058,
      p_m3_H: 0.15,
      p_m6_L: 0.058,
      p_m6_H: 0.15,
      p_m12_L: 0.058,
      p_m12_H: 0.33,
    },
    2113: {
      H: 0.36,
      L: 0.36,
      O: 0.36,
      C: 0.36,
      V: 0,
      p_w1_L: 0.34,
      p_w1_H: 0.385,
      p_m1_L: 0.34,
      p_m1_H: 0.405,
      p_m3_L: 0.255,
      p_m3_H: 0.42,
      p_m6_L: 0.255,
      p_m6_H: 0.42,
      p_m12_L: 0.255,
      p_m12_H: 0.46,
    },
    2115: {
      H: 0.142,
      L: 0.142,
      O: 0.142,
      C: 0.142,
      V: 30000,
      p_w1_L: 0.132,
      p_w1_H: 0.142,
      p_m1_L: 0.114,
      p_m1_H: 0.16,
      p_m3_L: 0.111,
      p_m3_H: 0.16,
      p_m6_L: 0.111,
      p_m6_H: 0.173,
      p_m12_L: 0.111,
      p_m12_H: 2.16,
    },
    2116: {
      H: 0.37,
      L: 0.345,
      O: 0.345,
      C: 0.37,
      V: 82000,
      p_w1_L: 0.345,
      p_w1_H: 0.38,
      p_m1_L: 0.32,
      p_m1_H: 0.38,
      p_m3_L: 0.32,
      p_m3_H: 0.415,
      p_m6_L: 0.32,
      p_m6_H: 0.425,
      p_m12_L: 0.3,
      p_m12_H: 0.445,
    },
    2117: {
      H: 4.18,
      L: 4.11,
      O: 4.11,
      C: 4.15,
      V: 66000,
      p_w1_L: 3.64,
      p_w1_H: 4.34,
      p_m1_L: 3.07,
      p_m1_H: 4.8,
      p_m3_L: 3.07,
      p_m3_H: 5.34,
      p_m6_L: 3.07,
      p_m6_H: 5.34,
      p_m12_L: 3.07,
      p_m12_H: 6.46,
    },
    2118: {
      H: 1.95,
      L: 1.95,
      O: 1.95,
      C: 1.95,
      V: 2000,
      p_w1_L: 1.92,
      p_w1_H: 1.95,
      p_m1_L: 1,
      p_m1_H: 1.99,
      p_m3_L: 1,
      p_m3_H: 2.02,
      p_m6_L: 1,
      p_m6_H: 2.04,
      p_m12_L: 0.82,
      p_m12_H: 2.99,
    },
    2119: {
      H: 0.99,
      L: 0.97,
      O: 0.97,
      C: 0.98,
      V: 54000,
      p_w1_L: 0.96,
      p_w1_H: 0.99,
      p_m1_L: 0.96,
      p_m1_H: 1,
      p_m3_L: 0.96,
      p_m3_H: 1.03,
      p_m6_L: 0.96,
      p_m6_H: 1.11,
      p_m12_L: 0.96,
      p_m12_H: 1.25,
    },
    2120: {
      H: 24.55,
      L: 23.7,
      O: 24.15,
      C: 24,
      V: 40400,
      p_w1_L: 23.7,
      p_w1_H: 28.15,
      p_m1_L: 23.7,
      p_m1_H: 30,
      p_m3_L: 23.7,
      p_m3_H: 36.8,
      p_m6_L: 23.7,
      p_m6_H: 55,
      p_m12_L: 23.45,
      p_m12_H: 55,
    },
    2122: {
      H: 0.235,
      L: 0.225,
      O: 0.229,
      C: 0.225,
      V: 24000,
      p_w1_L: 0.225,
      p_w1_H: 0.239,
      p_m1_L: 0.225,
      p_m1_H: 0.255,
      p_m3_L: 0.225,
      p_m3_H: 0.3,
      p_m6_L: 0.225,
      p_m6_H: 0.335,
      p_m12_L: 0.225,
      p_m12_H: 0.375,
    },
    2125: {
      H: 2.45,
      L: 2.2,
      O: 2.36,
      C: 2.25,
      V: 5860000,
      p_w1_L: 2.14,
      p_w1_H: 2.53,
      p_m1_L: 2.14,
      p_m1_H: 2.56,
      p_m3_L: 2.14,
      p_m3_H: 4.58,
      p_m6_L: 2.14,
      p_m6_H: 8.96,
      p_m12_L: 2.14,
      p_m12_H: 15.3,
    },
    2126: {
      H: 11.56,
      L: 10.82,
      O: 10.94,
      C: 11.56,
      V: 3063560,
      p_w1_L: 10.42,
      p_w1_H: 12.6,
      p_m1_L: 10.42,
      p_m1_H: 14.6,
      p_m3_L: 10.42,
      p_m3_H: 24.85,
      p_m6_L: 10.42,
      p_m6_H: 34.75,
      p_m12_L: 10.42,
      p_m12_H: 52.8,
    },
    2127: {
      H: 2.19,
      L: 2.06,
      O: 2.19,
      C: 2.12,
      V: 14712000,
      p_w1_L: 2.06,
      p_w1_H: 2.26,
      p_m1_L: 2.06,
      p_m1_H: 2.58,
      p_m3_L: 2.06,
      p_m3_H: 2.8,
      p_m6_L: 2.03,
      p_m6_H: 3.09,
      p_m12_L: 1.65,
      p_m12_H: 3.48,
    },
    2128: {
      H: 11.48,
      L: 11.1,
      O: 11.18,
      C: 11.42,
      V: 3718200,
      p_w1_L: 10.6,
      p_w1_H: 11.52,
      p_m1_L: 10.6,
      p_m1_H: 12.4,
      p_m3_L: 10.6,
      p_m3_H: 16.2,
      p_m6_L: 10.6,
      p_m6_H: 20.3,
      p_m12_L: 10.6,
      p_m12_H: 21.6,
    },
    2129: {
      H: 0.132,
      L: 0.132,
      O: 0.132,
      C: 0.131,
      V: 415000,
      p_w1_L: 0.123,
      p_w1_H: 0.136,
      p_m1_L: 0.123,
      p_m1_H: 0.155,
      p_m3_L: 0.115,
      p_m3_H: 0.164,
      p_m6_L: 0.115,
      p_m6_H: 0.19,
      p_m12_L: 0.115,
      p_m12_H: 0.485,
    },
    2130: {
      H: 10.9,
      L: 10.3,
      O: 10.42,
      C: 10.46,
      V: 2591000,
      p_w1_L: 9.4,
      p_w1_H: 10.9,
      p_m1_L: 9.01,
      p_m1_H: 10.9,
      p_m3_L: 7.26,
      p_m3_H: 11.26,
      p_m6_L: 7.03,
      p_m6_H: 11.26,
      p_m12_L: 3.6,
      p_m12_H: 11.26,
    },
    2131: {
      H: 2.99,
      L: 2.79,
      O: 2.99,
      C: 2.83,
      V: 1915000,
      p_w1_L: 2.79,
      p_w1_H: 3.19,
      p_m1_L: 2.79,
      p_m1_H: 3.88,
      p_m3_L: 2.79,
      p_m3_H: 4.73,
      p_m6_L: 2.79,
      p_m6_H: 6.95,
      p_m12_L: 2.79,
      p_m12_H: 12.88,
    },
    2132: {
      H: 0.27,
      L: 0.26,
      O: 0.26,
      C: 0.27,
      V: 392000,
      p_w1_L: 0.25,
      p_w1_H: 0.29,
      p_m1_L: 0.232,
      p_m1_H: 0.315,
      p_m3_L: 0.192,
      p_m3_H: 0.315,
      p_m6_L: 0.15,
      p_m6_H: 0.315,
      p_m12_L: 0.115,
      p_m12_H: 0.315,
    },
    2133: {
      H: 0.228,
      L: 0.228,
      O: 0.228,
      C: 0.228,
      V: 0,
      p_w1_L: 0.228,
      p_w1_H: 0.228,
      p_m1_L: 0.228,
      p_m1_H: 0.228,
      p_m3_L: 0.228,
      p_m3_H: 0.228,
      p_m6_L: 0.228,
      p_m6_H: 0.228,
      p_m12_L: 0.189,
      p_m12_H: 0.41,
    },
    2135: {
      H: 0.305,
      L: 0.295,
      O: 0.305,
      C: 0.3,
      V: 1740000,
      p_w1_L: 0.29,
      p_w1_H: 0.31,
      p_m1_L: 0.285,
      p_m1_H: 0.485,
      p_m3_L: 0.285,
      p_m3_H: 0.485,
      p_m6_L: 0.285,
      p_m6_H: 0.74,
      p_m12_L: 0.285,
      p_m12_H: 1.05,
    },
    2136: {
      H: 1.35,
      L: 1.21,
      O: 1.21,
      C: 1.32,
      V: 9977500,
      p_w1_L: 1.08,
      p_w1_H: 1.35,
      p_m1_L: 1.01,
      p_m1_H: 1.35,
      p_m3_L: 0.94,
      p_m3_H: 1.35,
      p_m6_L: 0.94,
      p_m6_H: 1.38,
      p_m12_L: 0.94,
      p_m12_H: 1.45,
    },
    2137: {
      H: 44.6,
      L: 42,
      O: 42.9,
      C: 42.8,
      V: 1730000,
      p_w1_L: 35.65,
      p_w1_H: 47.5,
      p_m1_L: 17.3,
      p_m1_H: 48.3,
      p_m3_L: 17.3,
      p_m3_H: 52.6,
      p_m6_L: 17.3,
      p_m6_H: 52.6,
      p_m12_L: 17.3,
      p_m12_H: 52.6,
    },
    2138: {
      H: 12.2,
      L: 11.84,
      O: 12.02,
      C: 12.16,
      V: 1353000,
      p_w1_L: 11.72,
      p_w1_H: 12.38,
      p_m1_L: 11.64,
      p_m1_H: 13.36,
      p_m3_L: 9.82,
      p_m3_H: 13.36,
      p_m6_L: 9.82,
      p_m6_H: 16.26,
      p_m12_L: 5.32,
      p_m12_H: 16.26,
    },
    2139: {
      H: 1.09,
      L: 1.02,
      O: 1.03,
      C: 1.04,
      V: 34000,
      p_w1_L: 0.99,
      p_w1_H: 1.09,
      p_m1_L: 0.98,
      p_m1_H: 1.11,
      p_m3_L: 0.98,
      p_m3_H: 1.17,
      p_m6_L: 0.98,
      p_m6_H: 1.42,
      p_m12_L: 0.98,
      p_m12_H: 1.56,
    },
    2142: {
      H: 8.73,
      L: 8.18,
      O: 8.4,
      C: 8.19,
      V: 2490000,
      p_w1_L: 8.18,
      p_w1_H: 9.31,
      p_m1_L: 6.49,
      p_m1_H: 10.5,
      p_m3_L: 6.21,
      p_m3_H: 10.5,
      p_m6_L: 6.21,
      p_m6_H: 11.36,
      p_m12_L: 6.21,
      p_m12_H: 12.4,
    },
    2146: {
      H: 6.76,
      L: 6.15,
      O: 6.59,
      C: 6.26,
      V: 3155000,
      p_w1_L: 5.8,
      p_w1_H: 6.9,
      p_m1_L: 5.19,
      p_m1_H: 7.4,
      p_m3_L: 5.19,
      p_m3_H: 9.27,
      p_m6_L: 5.19,
      p_m6_H: 11.42,
      p_m12_L: 5.19,
      p_m12_H: 13.2,
    },
    2148: {
      H: 10.46,
      L: 10.14,
      O: 10.32,
      C: 10.4,
      V: 481000,
      p_w1_L: 10.14,
      p_w1_H: 10.82,
      p_m1_L: 9.88,
      p_m1_H: 11.8,
      p_m3_L: 9.5,
      p_m3_H: 13.28,
      p_m6_L: 8.7,
      p_m6_H: 14.5,
      p_m12_L: 8,
      p_m12_H: 23.9,
    },
    2150: {
      H: 8.85,
      L: 8.43,
      O: 8.43,
      C: 8.58,
      V: 1556000,
      p_w1_L: 8.03,
      p_w1_H: 9.6,
      p_m1_L: 8.03,
      p_m1_H: 10.16,
      p_m3_L: 8.03,
      p_m3_H: 14.28,
      p_m6_L: 8.03,
      p_m6_H: 18.98,
      p_m12_L: 8.03,
      p_m12_H: 18.98,
    },
    2153: {
      H: 1.17,
      L: 1.15,
      O: 1.17,
      C: 1.15,
      V: 418000,
      p_w1_L: 1.13,
      p_w1_H: 1.18,
      p_m1_L: 1.12,
      p_m1_H: 1.3,
      p_m3_L: 1.1,
      p_m3_H: 1.48,
      p_m6_L: 1.1,
      p_m6_H: 1.68,
      p_m12_L: 1.1,
      p_m12_H: 1.92,
    },
    2155: {
      H: 12.28,
      L: 11.18,
      O: 11.4,
      C: 12.28,
      V: 905000,
      p_w1_L: 10.32,
      p_w1_H: 12.98,
      p_m1_L: 9.78,
      p_m1_H: 13.9,
      p_m3_L: 8.1,
      p_m3_H: 13.9,
      p_m6_L: 4.96,
      p_m6_H: 15.64,
      p_m12_L: 4.96,
      p_m12_H: 15.64,
    },
    2156: {
      H: 4.2,
      L: 4.1,
      O: 4.19,
      C: 4.19,
      V: 892000,
      p_w1_L: 3.97,
      p_w1_H: 4.2,
      p_m1_L: 3.82,
      p_m1_H: 4.21,
      p_m3_L: 3.82,
      p_m3_H: 4.68,
      p_m6_L: 3.82,
      p_m6_H: 5.44,
      p_m12_L: 2.31,
      p_m12_H: 5.44,
    },
    2158: {
      H: 21.25,
      L: 20.5,
      O: 20.65,
      C: 20.75,
      V: 637300,
      p_w1_L: 19.54,
      p_w1_H: 21.9,
      p_m1_L: 19.54,
      p_m1_H: 28,
      p_m3_L: 19.54,
      p_m3_H: 32.55,
      p_m6_L: 19.54,
      p_m6_H: 48.4,
      p_m12_L: 19.54,
      p_m12_H: 69.8,
    },
    2159: {
      H: 1.4,
      L: 1.33,
      O: 1.37,
      C: 1.37,
      V: 112000,
      p_w1_L: 1.25,
      p_w1_H: 1.52,
      p_m1_L: 1.25,
      p_m1_H: 1.63,
      p_m3_L: 1.21,
      p_m3_H: 1.7,
      p_m6_L: 1.21,
      p_m6_H: 2.4,
      p_m12_L: 1.21,
      p_m12_H: 4.81,
    },
    2160: {
      H: 4.48,
      L: 4.3,
      O: 4.32,
      C: 4.38,
      V: 3513000,
      p_w1_L: 4.25,
      p_w1_H: 4.79,
      p_m1_L: 4.25,
      p_m1_H: 5.8,
      p_m3_L: 4.25,
      p_m3_H: 8.28,
      p_m6_L: 4.25,
      p_m6_H: 17.3,
      p_m12_L: 4.25,
      p_m12_H: 22.2,
    },
    2161: {
      H: 0.8,
      L: 0.78,
      O: 0.78,
      C: 0.79,
      V: 6000,
      p_w1_L: 0.76,
      p_w1_H: 0.9,
      p_m1_L: 0.76,
      p_m1_H: 0.99,
      p_m3_L: 0.76,
      p_m3_H: 1.38,
      p_m6_L: 0.76,
      p_m6_H: 1.88,
      p_m12_L: 0.76,
      p_m12_H: 3.2,
    },
    2162: {
      H: 45.45,
      L: 44.35,
      O: 45.35,
      C: 45.3,
      V: 122500,
      p_w1_L: 40.65,
      p_w1_H: 48.8,
      p_m1_L: 36.05,
      p_m1_H: 54.25,
      p_m3_L: 34.7,
      p_m3_H: 58.6,
      p_m6_L: 34.7,
      p_m6_H: 79.8,
      p_m12_L: 34.7,
      p_m12_H: 79.8,
    },
    2163: {
      H: 10.5,
      L: 10.06,
      O: 10.06,
      C: 10.4,
      V: 243000,
      p_w1_L: 9.6,
      p_w1_H: 10.5,
      p_m1_L: 9.4,
      p_m1_H: 10.98,
      p_m3_L: 7.84,
      p_m3_H: 13.4,
      p_m6_L: 7.84,
      p_m6_H: 17.14,
      p_m12_L: 7.84,
      p_m12_H: 23.35,
    },
    2165: {
      H: 4.1,
      L: 4.1,
      O: 4.1,
      C: 4.1,
      V: 0,
      p_w1_L: 3.3,
      p_w1_H: 4.17,
      p_m1_L: 2.48,
      p_m1_H: 4.29,
      p_m3_L: 2.48,
      p_m3_H: 4.3,
      p_m6_L: 2.48,
      p_m6_H: 5.5,
      p_m12_L: 2.48,
      p_m12_H: 5.5,
    },
    2166: {
      H: 1.59,
      L: 1.53,
      O: 1.56,
      C: 1.59,
      V: 138000,
      p_w1_L: 1.53,
      p_w1_H: 1.71,
      p_m1_L: 1.43,
      p_m1_H: 1.8,
      p_m3_L: 1.26,
      p_m3_H: 1.8,
      p_m6_L: 1.26,
      p_m6_H: 2.19,
      p_m12_L: 0.9,
      p_m12_H: 2.3,
    },
    2168: {
      H: 14.46,
      L: 13.56,
      O: 14.46,
      C: 13.88,
      V: 632500,
      p_w1_L: 12.82,
      p_w1_H: 14.46,
      p_m1_L: 12.6,
      p_m1_H: 15.1,
      p_m3_L: 12.6,
      p_m3_H: 25,
      p_m6_L: 12.6,
      p_m6_H: 34,
      p_m12_L: 12.6,
      p_m12_H: 34,
    },
    2169: {
      H: 1.88,
      L: 1.75,
      O: 1.88,
      C: 1.8,
      V: 1516000,
      p_w1_L: 1.53,
      p_w1_H: 1.88,
      p_m1_L: 1.42,
      p_m1_H: 1.88,
      p_m3_L: 1.36,
      p_m3_H: 1.88,
      p_m6_L: 1.21,
      p_m6_H: 1.88,
      p_m12_L: 1.05,
      p_m12_H: 1.88,
    },
    2170: {
      H: 8,
      L: 7.82,
      O: 7.92,
      C: 8,
      V: 184500,
      p_w1_L: 7.76,
      p_w1_H: 9.68,
      p_m1_L: 7.76,
      p_m1_H: 10.18,
      p_m3_L: 7.76,
      p_m3_H: 14.62,
      p_m6_L: 7.76,
      p_m6_H: 25.6,
      p_m12_L: 7.76,
      p_m12_H: 34.85,
    },
    2171: {
      H: 40.3,
      L: 38.6,
      O: 38.6,
      C: 40.3,
      V: 194500,
      p_w1_L: 38.4,
      p_w1_H: 43.2,
      p_m1_L: 35.05,
      p_m1_H: 49.7,
      p_m3_L: 34.65,
      p_m3_H: 57.25,
      p_m6_L: 22.55,
      p_m6_H: 57.25,
      p_m12_L: 22.55,
      p_m12_H: 57.25,
    },
    2175: {
      H: 4.21,
      L: 4.13,
      O: 4.21,
      C: 4.19,
      V: 649000,
      p_w1_L: 4.05,
      p_w1_H: 4.28,
      p_m1_L: 4.05,
      p_m1_H: 4.7,
      p_m3_L: 3.85,
      p_m3_H: 4.7,
      p_m6_L: 3.29,
      p_m6_H: 4.8,
      p_m12_L: 3.29,
      p_m12_H: 4.8,
    },
    2176: {
      H: 0.395,
      L: 0.395,
      O: 0.395,
      C: 0.395,
      V: 20000,
      p_w1_L: 0.37,
      p_w1_H: 0.42,
      p_m1_L: 0.37,
      p_m1_H: 0.435,
      p_m3_L: 0.37,
      p_m3_H: 0.51,
      p_m6_L: 0.365,
      p_m6_H: 0.72,
      p_m12_L: 0.35,
      p_m12_H: 0.72,
    },
    2177: {
      H: 4.92,
      L: 4.78,
      O: 4.85,
      C: 4.88,
      V: 4800,
      p_w1_L: 4.77,
      p_w1_H: 4.97,
      p_m1_L: 4.73,
      p_m1_H: 5.42,
      p_m3_L: 4.62,
      p_m3_H: 6.33,
      p_m6_L: 4.62,
      p_m6_H: 12.5,
      p_m12_L: 4.62,
      p_m12_H: 12.5,
    },
    2178: {
      H: 0.115,
      L: 0.108,
      O: 0.108,
      C: 0.114,
      V: 31000,
      p_w1_L: 0.106,
      p_w1_H: 0.117,
      p_m1_L: 0.101,
      p_m1_H: 0.136,
      p_m3_L: 0.088,
      p_m3_H: 0.18,
      p_m6_L: 0.082,
      p_m6_H: 0.18,
      p_m12_L: 0.081,
      p_m12_H: 0.18,
    },
    2180: {
      H: 8.85,
      L: 8.85,
      O: 8.85,
      C: 8.85,
      V: 0,
      p_w1_L: 8.8,
      p_w1_H: 9,
      p_m1_L: 8.8,
      p_m1_H: 9.65,
      p_m3_L: 8.67,
      p_m3_H: 9.89,
      p_m6_L: 8.59,
      p_m6_H: 9.89,
      p_m12_L: 8.59,
      p_m12_H: 13.6,
    },
    2181: {
      H: 0.99,
      L: 0.98,
      O: 0.99,
      C: 0.98,
      V: 66000,
      p_w1_L: 0.9,
      p_w1_H: 1,
      p_m1_L: 0.9,
      p_m1_H: 1.16,
      p_m3_L: 0.83,
      p_m3_H: 1.2,
      p_m6_L: 0.83,
      p_m6_H: 1.4,
      p_m12_L: 0.83,
      p_m12_H: 1.4,
    },
    2182: {
      H: 0.435,
      L: 0.435,
      O: 0.435,
      C: 0.435,
      V: 0,
      p_w1_L: 0.42,
      p_w1_H: 0.455,
      p_m1_L: 0.39,
      p_m1_H: 0.52,
      p_m3_L: 0.38,
      p_m3_H: 0.52,
      p_m6_L: 0.38,
      p_m6_H: 0.56,
      p_m12_L: 0.325,
      p_m12_H: 0.61,
    },
    2183: {
      H: 3.9,
      L: 3.8,
      O: 3.8,
      C: 3.9,
      V: 4376000,
      p_w1_L: 3.8,
      p_w1_H: 4.2,
      p_m1_L: 3.62,
      p_m1_H: 4.23,
      p_m3_L: 3.52,
      p_m3_H: 4.63,
      p_m6_L: 3.52,
      p_m6_H: 5.02,
      p_m12_L: 2.75,
      p_m12_H: 5.2,
    },
    2186: {
      H: 3.64,
      L: 3.6,
      O: 3.61,
      C: 3.64,
      V: 3545000,
      p_w1_L: 3.55,
      p_w1_H: 3.71,
      p_m1_L: 3.52,
      p_m1_H: 3.88,
      p_m3_L: 3.51,
      p_m3_H: 4.25,
      p_m6_L: 3.51,
      p_m6_H: 5.84,
      p_m12_L: 3.5,
      p_m12_H: 7,
    },
    2187: {
      H: 1.78,
      L: 1.73,
      O: 1.76,
      C: 1.78,
      V: 424000,
      p_w1_L: 1.55,
      p_w1_H: 1.81,
      p_m1_L: 1.23,
      p_m1_H: 2.37,
      p_m3_L: 1.23,
      p_m3_H: 2.37,
      p_m6_L: 1.07,
      p_m6_H: 2.37,
      p_m12_L: 0.98,
      p_m12_H: 2.37,
    },
    2188: {
      H: 0.39,
      L: 0.38,
      O: 0.38,
      C: 0.385,
      V: 92000,
      p_w1_L: 0.37,
      p_w1_H: 0.395,
      p_m1_L: 0.37,
      p_m1_H: 0.41,
      p_m3_L: 0.36,
      p_m3_H: 0.46,
      p_m6_L: 0.36,
      p_m6_H: 0.47,
      p_m12_L: 0.36,
      p_m12_H: 0.71,
    },
    2189: {
      H: 0.67,
      L: 0.65,
      O: 0.65,
      C: 0.66,
      V: 356000,
      p_w1_L: 0.64,
      p_w1_H: 0.67,
      p_m1_L: 0.64,
      p_m1_H: 0.67,
      p_m3_L: 0.63,
      p_m3_H: 0.67,
      p_m6_L: 0.6,
      p_m6_H: 0.7,
      p_m12_L: 0.53,
      p_m12_H: 0.7,
    },
    2190: {
      H: 24.95,
      L: 23.6,
      O: 24.95,
      C: 23.8,
      V: 60000,
      p_w1_L: 23.6,
      p_w1_H: 27,
      p_m1_L: 23.6,
      p_m1_H: 30.05,
      p_m3_L: 23.6,
      p_m3_H: 45,
      p_m6_L: 23.6,
      p_m6_H: 60.8,
      p_m12_L: 23.6,
      p_m12_H: 60.8,
    },
    2191: {
      H: 3.43,
      L: 3.34,
      O: 3.4,
      C: 3.35,
      V: 928000,
      p_w1_L: 3.33,
      p_w1_H: 3.48,
      p_m1_L: 3.33,
      p_m1_H: 3.75,
      p_m3_L: 3.33,
      p_m3_H: 4.38,
      p_m6_L: 3.33,
      p_m6_H: 4.55,
      p_m12_L: 3.33,
      p_m12_H: 4.74,
    },
    2192: {
      H: 37.15,
      L: 35.25,
      O: 35.9,
      C: 37.05,
      V: 495500,
      p_w1_L: 33.7,
      p_w1_H: 41.55,
      p_m1_L: 33.7,
      p_m1_H: 47,
      p_m3_L: 31.1,
      p_m3_H: 47,
      p_m6_L: 26.55,
      p_m6_H: 47,
      p_m12_L: 26.55,
      p_m12_H: 47,
    },
    2193: {
      H: 0.37,
      L: 0.37,
      O: 0.37,
      C: 0.37,
      V: 0,
      p_w1_L: 0.365,
      p_w1_H: 0.4,
      p_m1_L: 0.335,
      p_m1_H: 0.42,
      p_m3_L: 0.265,
      p_m3_H: 0.42,
      p_m6_L: 0.265,
      p_m6_H: 0.42,
      p_m12_L: 0.25,
      p_m12_H: 0.445,
    },
    2195: {
      H: 0.38,
      L: 0.36,
      O: 0.36,
      C: 0.38,
      V: 180000,
      p_w1_L: 0.36,
      p_w1_H: 0.4,
      p_m1_L: 0.33,
      p_m1_H: 0.42,
      p_m3_L: 0.33,
      p_m3_H: 0.42,
      p_m6_L: 0.33,
      p_m6_H: 0.55,
      p_m12_L: 0.29,
      p_m12_H: 0.55,
    },
    2196: {
      H: 37.2,
      L: 36,
      O: 36.25,
      C: 36.5,
      V: 2399930,
      p_w1_L: 34.95,
      p_w1_H: 40.05,
      p_m1_L: 32.55,
      p_m1_H: 42.7,
      p_m3_L: 31.6,
      p_m3_H: 52.2,
      p_m6_L: 31.6,
      p_m6_H: 82,
      p_m12_L: 31.25,
      p_m12_H: 82,
    },
    2197: {
      H: 12.7,
      L: 11.72,
      O: 11.72,
      C: 12.5,
      V: 1437500,
      p_w1_L: 11,
      p_w1_H: 12.7,
      p_m1_L: 8.8,
      p_m1_H: 13.14,
      p_m3_L: 8.8,
      p_m3_H: 13.56,
      p_m6_L: 8.8,
      p_m6_H: 13.56,
      p_m12_L: 8.8,
      p_m12_H: 13.56,
    },
    2198: {
      H: 2.53,
      L: 2.45,
      O: 2.47,
      C: 2.52,
      V: 1772000,
      p_w1_L: 2.37,
      p_w1_H: 2.53,
      p_m1_L: 2.27,
      p_m1_H: 2.53,
      p_m3_L: 2.27,
      p_m3_H: 3.14,
      p_m6_L: 2.27,
      p_m6_H: 3.47,
      p_m12_L: 2.27,
      p_m12_H: 4.58,
    },
    2199: {
      H: 6.49,
      L: 6.06,
      O: 6.48,
      C: 6.37,
      V: 839000,
      p_w1_L: 5.92,
      p_w1_H: 7,
      p_m1_L: 3.73,
      p_m1_H: 7,
      p_m3_L: 2.43,
      p_m3_H: 7,
      p_m6_L: 2.4,
      p_m6_H: 7,
      p_m12_L: 2.02,
      p_m12_H: 7,
    },
    2202: {
      H: 19.48,
      L: 18.78,
      O: 19.48,
      C: 18.96,
      V: 6135280,
      p_w1_L: 17.7,
      p_w1_H: 19.56,
      p_m1_L: 17.58,
      p_m1_H: 20.75,
      p_m3_L: 16.84,
      p_m3_H: 22.75,
      p_m6_L: 16.84,
      p_m6_H: 26.15,
      p_m12_L: 16.84,
      p_m12_H: 35,
    },
    2203: {
      H: 0.18,
      L: 0.18,
      O: 0.18,
      C: 0.18,
      V: 0,
      p_w1_L: 0.178,
      p_w1_H: 0.192,
      p_m1_L: 0.174,
      p_m1_H: 0.198,
      p_m3_L: 0.173,
      p_m3_H: 0.228,
      p_m6_L: 0.173,
      p_m6_H: 0.232,
      p_m12_L: 0.173,
      p_m12_H: 0.385,
    },
    2205: {
      H: 3.64,
      L: 3.62,
      O: 3.62,
      C: 3.62,
      V: 68000,
      p_w1_L: 3.6,
      p_w1_H: 3.66,
      p_m1_L: 3.52,
      p_m1_H: 3.68,
      p_m3_L: 3.2,
      p_m3_H: 3.75,
      p_m6_L: 3.2,
      p_m6_H: 3.79,
      p_m12_L: 3.2,
      p_m12_H: 3.79,
    },
    2207: {
      H: 4.9,
      L: 4.74,
      O: 4.9,
      C: 4.9,
      V: 460000,
      p_w1_L: 4.74,
      p_w1_H: 4.94,
      p_m1_L: 4.55,
      p_m1_H: 5.47,
      p_m3_L: 4.55,
      p_m3_H: 5.89,
      p_m6_L: 4.55,
      p_m6_H: 5.89,
      p_m12_L: 4.55,
      p_m12_H: 5.89,
    },
    2208: {
      H: 17.76,
      L: 17.04,
      O: 17.06,
      C: 17.44,
      V: 5212540,
      p_w1_L: 16.14,
      p_w1_H: 17.76,
      p_m1_L: 16.14,
      p_m1_H: 19,
      p_m3_L: 14.16,
      p_m3_H: 19.82,
      p_m6_L: 11.24,
      p_m6_H: 19.82,
      p_m12_L: 11.24,
      p_m12_H: 19.82,
    },
    2209: {
      H: 1.61,
      L: 1.6,
      O: 1.6,
      C: 1.6,
      V: 24000,
      p_w1_L: 1.38,
      p_w1_H: 1.75,
      p_m1_L: 1.17,
      p_m1_H: 2.02,
      p_m3_L: 1.13,
      p_m3_H: 2.02,
      p_m6_L: 1.13,
      p_m6_H: 4.97,
      p_m12_L: 1.13,
      p_m12_H: 4.97,
    },
    2210: {
      H: 6.75,
      L: 6.4,
      O: 6.75,
      C: 6.39,
      V: 19200,
      p_w1_L: 6.4,
      p_w1_H: 8.38,
      p_m1_L: 6.4,
      p_m1_H: 8.4,
      p_m3_L: 6.4,
      p_m3_H: 8.4,
      p_m6_L: 6.4,
      p_m6_H: 8.4,
      p_m12_L: 6.4,
      p_m12_H: 8.4,
    },
    2211: {
      H: 0.158,
      L: 0.145,
      O: 0.152,
      C: 0.158,
      V: 151000,
      p_w1_L: 0.14,
      p_w1_H: 0.169,
      p_m1_L: 0.14,
      p_m1_H: 0.179,
      p_m3_L: 0.135,
      p_m3_H: 0.185,
      p_m6_L: 0.135,
      p_m6_H: 0.26,
      p_m12_L: 0.135,
      p_m12_H: 0.5,
    },
    2212: {
      H: 0.044,
      L: 0.041,
      O: 0.043,
      C: 0.042,
      V: 2040000,
      p_w1_L: 0.041,
      p_w1_H: 0.045,
      p_m1_L: 0.04,
      p_m1_H: 0.052,
      p_m3_L: 0.024,
      p_m3_H: 0.062,
      p_m6_L: 0.014,
      p_m6_H: 0.062,
      p_m12_L: 0.014,
      p_m12_H: 0.068,
    },
    2213: {
      H: 0.101,
      L: 0.101,
      O: 0.101,
      C: 0.101,
      V: 0,
      p_w1_L: 0.101,
      p_w1_H: 0.101,
      p_m1_L: 0.101,
      p_m1_H: 0.101,
      p_m3_L: 0.101,
      p_m3_H: 0.101,
      p_m6_L: 0.101,
      p_m6_H: 0.101,
      p_m12_L: 0.101,
      p_m12_H: 0.101,
    },
    2215: {
      H: 3.09,
      L: 3.06,
      O: 3.09,
      C: 3.08,
      V: 160000,
      p_w1_L: 2.98,
      p_w1_H: 3.12,
      p_m1_L: 2.94,
      p_m1_H: 3.39,
      p_m3_L: 2.94,
      p_m3_H: 3.8,
      p_m6_L: 2.94,
      p_m6_H: 3.82,
      p_m12_L: 2.94,
      p_m12_H: 3.82,
    },
    2216: {
      H: 9.8,
      L: 9.44,
      O: 9.5,
      C: 9.6,
      V: 179000,
      p_w1_L: 9.41,
      p_w1_H: 10.36,
      p_m1_L: 9.41,
      p_m1_H: 11.64,
      p_m3_L: 9.41,
      p_m3_H: 18,
      p_m6_L: 9.41,
      p_m6_H: 18,
      p_m12_L: 9.41,
      p_m12_H: 18,
    },
    2217: {
      H: 3.13,
      L: 3.01,
      O: 3.12,
      C: 3.07,
      V: 3781020,
      p_w1_L: 2.79,
      p_w1_H: 3.13,
      p_m1_L: 2.79,
      p_m1_H: 3.81,
      p_m3_L: 2.49,
      p_m3_H: 3.81,
      p_m6_L: 2.49,
      p_m6_H: 3.81,
      p_m12_L: 2.49,
      p_m12_H: 3.81,
    },
    2218: {
      H: 6.19,
      L: 5.89,
      O: 5.89,
      C: 6.16,
      V: 19000,
      p_w1_L: 5.6,
      p_w1_H: 6.19,
      p_m1_L: 5.56,
      p_m1_H: 6.24,
      p_m3_L: 5.45,
      p_m3_H: 6.3,
      p_m6_L: 5.45,
      p_m6_H: 7.45,
      p_m12_L: 5.45,
      p_m12_H: 9.25,
    },
    2219: {
      H: 6.47,
      L: 6.21,
      O: 6.44,
      C: 6.3,
      V: 321500,
      p_w1_L: 6.2,
      p_w1_H: 7.26,
      p_m1_L: 6.2,
      p_m1_H: 8.5,
      p_m3_L: 6.2,
      p_m3_H: 10.98,
      p_m6_L: 6.2,
      p_m6_H: 17.8,
      p_m12_L: 6.2,
      p_m12_H: 17.8,
    },
    2221: {
      H: 0.76,
      L: 0.7,
      O: 0.76,
      C: 0.74,
      V: 1264000,
      p_w1_L: 0.7,
      p_w1_H: 0.81,
      p_m1_L: 0.7,
      p_m1_H: 1.03,
      p_m3_L: 0.59,
      p_m3_H: 1.38,
      p_m6_L: 0.46,
      p_m6_H: 1.38,
      p_m12_L: 0.15,
      p_m12_H: 1.38,
    },
    2222: {
      H: 0.167,
      L: 0.167,
      O: 0.167,
      C: 0.167,
      V: 0,
      p_w1_L: 0.16,
      p_w1_H: 0.167,
      p_m1_L: 0.158,
      p_m1_H: 0.18,
      p_m3_L: 0.158,
      p_m3_H: 0.201,
      p_m6_L: 0.158,
      p_m6_H: 0.21,
      p_m12_L: 0.154,
      p_m12_H: 0.214,
    },
    2223: {
      H: 0.69,
      L: 0.69,
      O: 0.69,
      C: 0.69,
      V: 0,
      p_w1_L: 0.69,
      p_w1_H: 0.69,
      p_m1_L: 0.62,
      p_m1_H: 0.8,
      p_m3_L: 0.61,
      p_m3_H: 0.8,
      p_m6_L: 0.61,
      p_m6_H: 0.8,
      p_m12_L: 0.5,
      p_m12_H: 0.89,
    },
    2225: {
      H: 2.54,
      L: 2.45,
      O: 2.54,
      C: 2.48,
      V: 1000000,
      p_w1_L: 2.45,
      p_w1_H: 2.7,
      p_m1_L: 2.35,
      p_m1_H: 2.8,
      p_m3_L: 2.35,
      p_m3_H: 3.02,
      p_m6_L: 2.15,
      p_m6_H: 3.41,
      p_m12_L: 1.08,
      p_m12_H: 3.41,
    },
    2226: {
      H: 3.07,
      L: 3.07,
      O: 3.07,
      C: 3.07,
      V: 0,
      p_w1_L: 3.07,
      p_w1_H: 3.07,
      p_m1_L: 3.07,
      p_m1_H: 3.07,
      p_m3_L: 3.07,
      p_m3_H: 3.07,
      p_m6_L: 3.07,
      p_m6_H: 3.07,
      p_m12_L: 2.1,
      p_m12_H: 3.32,
    },
    2227: {
      H: 0.181,
      L: 0.181,
      O: 0.181,
      C: 0.181,
      V: 104000,
      p_w1_L: 0.175,
      p_w1_H: 0.185,
      p_m1_L: 0.175,
      p_m1_H: 0.195,
      p_m3_L: 0.11,
      p_m3_H: 0.2,
      p_m6_L: 0.11,
      p_m6_H: 0.55,
      p_m12_L: 0.11,
      p_m12_H: 0.55,
    },
    2228: {
      H: 0.6,
      L: 0.6,
      O: 0.6,
      C: 0.6,
      V: 0,
      p_w1_L: 0.6,
      p_w1_H: 0.6,
      p_m1_L: 0.6,
      p_m1_H: 0.6,
      p_m3_L: 0.6,
      p_m3_H: 0.6,
      p_m6_L: 0.6,
      p_m6_H: 0.6,
      p_m12_L: 0.6,
      p_m12_H: 0.6,
    },
    2230: {
      H: 0.199,
      L: 0.192,
      O: 0.192,
      C: 0.196,
      V: 905000,
      p_w1_L: 0.168,
      p_w1_H: 0.213,
      p_m1_L: 0.152,
      p_m1_H: 0.213,
      p_m3_L: 0.152,
      p_m3_H: 0.22,
      p_m6_L: 0.152,
      p_m6_H: 0.285,
      p_m12_L: 0.114,
      p_m12_H: 0.35,
    },
    2231: {
      H: 2.79,
      L: 2.6,
      O: 2.73,
      C: 2.6,
      V: 779000,
      p_w1_L: 2.41,
      p_w1_H: 2.79,
      p_m1_L: 2.4,
      p_m1_H: 2.89,
      p_m3_L: 2.4,
      p_m3_H: 3.16,
      p_m6_L: 2.4,
      p_m6_H: 3.23,
      p_m12_L: 2.4,
      p_m12_H: 3.5,
    },
    2232: {
      H: 2.77,
      L: 2.59,
      O: 2.59,
      C: 2.71,
      V: 104000,
      p_w1_L: 2.55,
      p_w1_H: 2.84,
      p_m1_L: 2.42,
      p_m1_H: 2.99,
      p_m3_L: 2.39,
      p_m3_H: 2.99,
      p_m6_L: 2.39,
      p_m6_H: 4.23,
      p_m12_L: 2.23,
      p_m12_H: 4.56,
    },
    2233: {
      H: 1.38,
      L: 1.36,
      O: 1.37,
      C: 1.36,
      V: 14618400,
      p_w1_L: 1.32,
      p_w1_H: 1.38,
      p_m1_L: 1.29,
      p_m1_H: 1.38,
      p_m3_L: 1.29,
      p_m3_H: 1.5,
      p_m6_L: 1.11,
      p_m6_H: 1.5,
      p_m12_L: 1.11,
      p_m12_H: 1.5,
    },
    2235: {
      H: 27.9,
      L: 27.1,
      O: 27.25,
      C: 27.8,
      V: 12100,
      p_w1_L: 27,
      p_w1_H: 27.9,
      p_m1_L: 26.5,
      p_m1_H: 30.65,
      p_m3_L: 26.5,
      p_m3_H: 31.7,
      p_m6_L: 26.5,
      p_m6_H: 31.7,
      p_m12_L: 26.5,
      p_m12_H: 31.7,
    },
    2236: {
      H: 0.4,
      L: 0.375,
      O: 0.375,
      C: 0.38,
      V: 313000,
      p_w1_L: 0.365,
      p_w1_H: 0.4,
      p_m1_L: 0.365,
      p_m1_H: 0.425,
      p_m3_L: 0.365,
      p_m3_H: 0.465,
      p_m6_L: 0.32,
      p_m6_H: 0.465,
      p_m12_L: 0.32,
      p_m12_H: 0.76,
    },
    2238: {
      H: 8.35,
      L: 7.98,
      O: 8,
      C: 8.28,
      V: 38712600,
      p_w1_L: 7.65,
      p_w1_H: 8.35,
      p_m1_L: 7.2,
      p_m1_H: 8.4,
      p_m3_L: 6.58,
      p_m3_H: 9.05,
      p_m6_L: 6.1,
      p_m6_H: 9.25,
      p_m12_L: 6.1,
      p_m12_H: 10.08,
    },
    2239: {
      H: 4.12,
      L: 4.12,
      O: 4.12,
      C: 4.12,
      V: 0,
      p_w1_L: 3.52,
      p_w1_H: 4.19,
      p_m1_L: 3.21,
      p_m1_H: 4.72,
      p_m3_L: 3.21,
      p_m3_H: 4.8,
      p_m6_L: 3,
      p_m6_H: 5.5,
      p_m12_L: 2.55,
      p_m12_H: 5.5,
    },
    2251: {
      H: 53.8,
      L: 48.7,
      O: 52.65,
      C: 53.75,
      V: 243200,
      p_w1_L: 48.7,
      p_w1_H: 65,
      p_m1_L: 48.7,
      p_m1_H: 70.2,
      p_m3_L: 48.7,
      p_m3_H: 74.95,
      p_m6_L: 48.7,
      p_m6_H: 74.95,
      p_m12_L: 48.7,
      p_m12_H: 74.95,
    },
    2252: {
      H: 57.2,
      L: 54.7,
      O: 54.85,
      C: 56,
      V: 274003,
      p_w1_L: 50,
      p_w1_H: 59.8,
      p_m1_L: 44.75,
      p_m1_H: 73.45,
      p_m3_L: 39.6,
      p_m3_H: 73.45,
      p_m6_L: 39.6,
      p_m6_H: 73.45,
      p_m12_L: 39.6,
      p_m12_H: 73.45,
    },
    2255: {
      H: 2.62,
      L: 2.5,
      O: 2.62,
      C: 2.6,
      V: 14605000,
      p_w1_L: 2.5,
      p_w1_H: 2.77,
      p_m1_L: 2.37,
      p_m1_H: 2.95,
      p_m3_L: 1.3,
      p_m3_H: 2.95,
      p_m6_L: 0.5,
      p_m6_H: 2.95,
      p_m12_L: 0.4,
      p_m12_H: 2.95,
    },
    2256: {
      H: 9.6,
      L: 9.21,
      O: 9.6,
      C: 9.22,
      V: 408000,
      p_w1_L: 9.21,
      p_w1_H: 10.1,
      p_m1_L: 9.21,
      p_m1_H: 12.38,
      p_m3_L: 9.21,
      p_m3_H: 12.54,
      p_m6_L: 9.21,
      p_m6_H: 12.54,
      p_m12_L: 9.21,
      p_m12_H: 12.54,
    },
    2258: {
      H: 0.465,
      L: 0.46,
      O: 0.46,
      C: 0.465,
      V: 58000,
      p_w1_L: 0.445,
      p_w1_H: 0.47,
      p_m1_L: 0.42,
      p_m1_H: 0.52,
      p_m3_L: 0.42,
      p_m3_H: 0.58,
      p_m6_L: 0.42,
      p_m6_H: 0.6,
      p_m12_L: 0.42,
      p_m12_H: 0.78,
    },
    2262: {
      H: 0.77,
      L: 0.77,
      O: 0.77,
      C: 0.77,
      V: 0,
      p_w1_L: 0.75,
      p_w1_H: 0.82,
      p_m1_L: 0.75,
      p_m1_H: 1.05,
      p_m3_L: 0.69,
      p_m3_H: 1.05,
      p_m6_L: 0.69,
      p_m6_H: 1.05,
      p_m12_L: 0.69,
      p_m12_H: 1.18,
    },
    2263: {
      H: 0.17,
      L: 0.17,
      O: 0.17,
      C: 0.17,
      V: 0,
      p_w1_L: 0.166,
      p_w1_H: 0.172,
      p_m1_L: 0.163,
      p_m1_H: 0.186,
      p_m3_L: 0.163,
      p_m3_H: 0.216,
      p_m6_L: 0.163,
      p_m6_H: 0.217,
      p_m12_L: 0.14,
      p_m12_H: 0.37,
    },
    2265: {
      H: 0.86,
      L: 0.83,
      O: 0.83,
      C: 0.86,
      V: 2130000,
      p_w1_L: 0.76,
      p_w1_H: 0.86,
      p_m1_L: 0.74,
      p_m1_H: 1.11,
      p_m3_L: 0.74,
      p_m3_H: 1.11,
      p_m6_L: 0.74,
      p_m6_H: 1.11,
      p_m12_L: 0.74,
      p_m12_H: 1.11,
    },
    2266: {
      H: 0.345,
      L: 0.33,
      O: 0.33,
      C: 0.345,
      V: 70000,
      p_w1_L: 0.31,
      p_w1_H: 0.365,
      p_m1_L: 0.295,
      p_m1_H: 0.46,
      p_m3_L: 0.295,
      p_m3_H: 0.48,
      p_m6_L: 0.295,
      p_m6_H: 0.58,
      p_m12_L: 0.295,
      p_m12_H: 0.66,
    },
    2269: {
      H: 103.5,
      L: 98.9,
      O: 98.9,
      C: 101.8,
      V: 14288100,
      p_w1_L: 94.15,
      p_w1_H: 108.6,
      p_m1_L: 92.05,
      p_m1_H: 113.8,
      p_m3_L: 92.05,
      p_m3_H: 127.3,
      p_m6_L: 92.05,
      p_m6_H: 148,
      p_m12_L: 77.25,
      p_m12_H: 148,
    },
    2278: {
      H: 6.07,
      L: 6.07,
      O: 6.07,
      C: 6.07,
      V: 0,
      p_w1_L: 6.07,
      p_w1_H: 6.07,
      p_m1_L: 3.8,
      p_m1_H: 6.27,
      p_m3_L: 2.7,
      p_m3_H: 6.27,
      p_m6_L: 2.7,
      p_m6_H: 6.27,
      p_m12_L: 2.7,
      p_m12_H: 7.33,
    },
    2280: {
      H: 0.74,
      L: 0.72,
      O: 0.73,
      C: 0.74,
      V: 425998,
      p_w1_L: 0.72,
      p_w1_H: 0.8,
      p_m1_L: 0.72,
      p_m1_H: 0.88,
      p_m3_L: 0.72,
      p_m3_H: 0.93,
      p_m6_L: 0.72,
      p_m6_H: 1.09,
      p_m12_L: 0.72,
      p_m12_H: 2.02,
    },
    2281: {
      H: 1.03,
      L: 1.03,
      O: 1.03,
      C: 1.03,
      V: 0,
      p_w1_L: 1,
      p_w1_H: 1.1,
      p_m1_L: 1,
      p_m1_H: 1.21,
      p_m3_L: 1,
      p_m3_H: 1.21,
      p_m6_L: 0.98,
      p_m6_H: 1.3,
      p_m12_L: 0.94,
      p_m12_H: 1.3,
    },
    2282: {
      H: 5.21,
      L: 5,
      O: 5.11,
      C: 5.11,
      V: 9322000,
      p_w1_L: 4.83,
      p_w1_H: 5.25,
      p_m1_L: 4.83,
      p_m1_H: 6.42,
      p_m3_L: 4.81,
      p_m3_H: 8.51,
      p_m6_L: 4.81,
      p_m6_H: 13.06,
      p_m12_L: 4.81,
      p_m12_H: 14.62,
    },
    2283: {
      H: 2.45,
      L: 2.4,
      O: 2.45,
      C: 2.42,
      V: 342000,
      p_w1_L: 2.38,
      p_w1_H: 2.6,
      p_m1_L: 2.38,
      p_m1_H: 2.88,
      p_m3_L: 2.38,
      p_m3_H: 3.32,
      p_m6_L: 2.38,
      p_m6_H: 3.43,
      p_m12_L: 2.38,
      p_m12_H: 3.98,
    },
    2286: {
      H: 1.65,
      L: 1.65,
      O: 1.65,
      C: 1.65,
      V: 0,
      p_w1_L: 1.65,
      p_w1_H: 1.78,
      p_m1_L: 1.31,
      p_m1_H: 1.78,
      p_m3_L: 1.31,
      p_m3_H: 1.8,
      p_m6_L: 1.31,
      p_m6_H: 1.89,
      p_m12_L: 1.09,
      p_m12_H: 3.01,
    },
    2288: {
      H: 0.67,
      L: 0.67,
      O: 0.67,
      C: 0.67,
      V: 0,
      p_w1_L: 0.67,
      p_w1_H: 0.67,
      p_m1_L: 0.66,
      p_m1_H: 0.69,
      p_m3_L: 0.64,
      p_m3_H: 0.75,
      p_m6_L: 0.62,
      p_m6_H: 0.79,
      p_m12_L: 0.57,
      p_m12_H: 0.79,
    },
    2289: {
      H: 5.56,
      L: 5.56,
      O: 5.56,
      C: 5.56,
      V: 0,
      p_w1_L: 5.56,
      p_w1_H: 5.56,
      p_m1_L: 5.09,
      p_m1_H: 6.3,
      p_m3_L: 5.09,
      p_m3_H: 7.01,
      p_m6_L: 5.09,
      p_m6_H: 7.18,
      p_m12_L: 4.01,
      p_m12_H: 7.18,
    },
    2292: {
      H: 0.93,
      L: 0.93,
      O: 0.93,
      C: 0.93,
      V: 0,
      p_w1_L: 0.93,
      p_w1_H: 0.93,
      p_m1_L: 0.93,
      p_m1_H: 0.95,
      p_m3_L: 0.92,
      p_m3_H: 0.95,
      p_m6_L: 0.92,
      p_m6_H: 0.95,
      p_m12_L: 0.91,
      p_m12_H: 0.96,
    },
    2293: {
      H: 0.77,
      L: 0.77,
      O: 0.77,
      C: 0.77,
      V: 48000,
      p_w1_L: 0.71,
      p_w1_H: 0.8,
      p_m1_L: 0.71,
      p_m1_H: 0.83,
      p_m3_L: 0.71,
      p_m3_H: 1.46,
      p_m6_L: 0.71,
      p_m6_H: 1.46,
      p_m12_L: 0.68,
      p_m12_H: 1.46,
    },
    2296: {
      H: 1.06,
      L: 1.06,
      O: 1.06,
      C: 1.06,
      V: 0,
      p_w1_L: 1.06,
      p_w1_H: 1.06,
      p_m1_L: 1,
      p_m1_H: 1.06,
      p_m3_L: 0.99,
      p_m3_H: 1.16,
      p_m6_L: 0.99,
      p_m6_H: 1.35,
      p_m12_L: 0.285,
      p_m12_H: 1.49,
    },
    2298: {
      H: 0.7,
      L: 0.68,
      O: 0.7,
      C: 0.7,
      V: 243000,
      p_w1_L: 0.65,
      p_w1_H: 0.75,
      p_m1_L: 0.495,
      p_m1_H: 0.89,
      p_m3_L: 0.485,
      p_m3_H: 0.89,
      p_m6_L: 0.485,
      p_m6_H: 1.21,
      p_m12_L: 0.485,
      p_m12_H: 1.46,
    },
    2299: {
      H: 5.27,
      L: 5.27,
      O: 5.27,
      C: 5.27,
      V: 0,
      p_w1_L: 5.27,
      p_w1_H: 5.27,
      p_m1_L: 5.27,
      p_m1_H: 5.27,
      p_m3_L: 5.27,
      p_m3_H: 5.27,
      p_m6_L: 5.27,
      p_m6_H: 5.27,
      p_m12_L: 3.64,
      p_m12_H: 5.51,
    },
    2302: {
      H: 1.5,
      L: 1.5,
      O: 1.5,
      C: 1.5,
      V: 0,
      p_w1_L: 1.5,
      p_w1_H: 1.5,
      p_m1_L: 1.5,
      p_m1_H: 1.5,
      p_m3_L: 1.5,
      p_m3_H: 1.5,
      p_m6_L: 1.5,
      p_m6_H: 1.5,
      p_m12_L: 1.36,
      p_m12_H: 1.95,
    },
    2307: {
      H: 0.445,
      L: 0.445,
      O: 0.445,
      C: 0.445,
      V: 604000,
      p_w1_L: 0.375,
      p_w1_H: 0.445,
      p_m1_L: 0.37,
      p_m1_H: 0.475,
      p_m3_L: 0.36,
      p_m3_H: 0.475,
      p_m6_L: 0.355,
      p_m6_H: 0.475,
      p_m12_L: 0.33,
      p_m12_H: 0.485,
    },
    2308: {
      H: 1.3,
      L: 1.26,
      O: 1.27,
      C: 1.29,
      V: 112000,
      p_w1_L: 1.25,
      p_w1_H: 1.34,
      p_m1_L: 1.25,
      p_m1_H: 1.38,
      p_m3_L: 1.1,
      p_m3_H: 1.39,
      p_m6_L: 1.1,
      p_m6_H: 1.42,
      p_m12_L: 1,
      p_m12_H: 1.97,
    },
    2309: {
      H: 0.122,
      L: 0.111,
      O: 0.111,
      C: 0.122,
      V: 140000,
      p_w1_L: 0.111,
      p_w1_H: 0.128,
      p_m1_L: 0.106,
      p_m1_H: 0.135,
      p_m3_L: 0.106,
      p_m3_H: 0.159,
      p_m6_L: 0.106,
      p_m6_H: 0.19,
      p_m12_L: 0.106,
      p_m12_H: 0.28,
    },
    2310: {
      H: 0.086,
      L: 0.073,
      O: 0.08,
      C: 0.086,
      V: 300000,
      p_w1_L: 0.073,
      p_w1_H: 0.088,
      p_m1_L: 0.071,
      p_m1_H: 0.092,
      p_m3_L: 0.071,
      p_m3_H: 0.15,
      p_m6_L: 0.071,
      p_m6_H: 0.15,
      p_m12_L: 0.071,
      p_m12_H: 0.15,
    },
    2312: {
      H: 0.244,
      L: 0.243,
      O: 0.244,
      C: 0.243,
      V: 50000,
      p_w1_L: 0.243,
      p_w1_H: 0.27,
      p_m1_L: 0.231,
      p_m1_H: 0.4,
      p_m3_L: 0.231,
      p_m3_H: 0.415,
      p_m6_L: 0.026,
      p_m6_H: 0.415,
      p_m12_L: 0.026,
      p_m12_H: 0.415,
    },
    2313: {
      H: 159.7,
      L: 155.5,
      O: 157.5,
      C: 159,
      V: 1458140,
      p_w1_L: 146.4,
      p_w1_H: 159.7,
      p_m1_L: 146.1,
      p_m1_H: 172.9,
      p_m3_L: 146.1,
      p_m3_H: 176.7,
      p_m6_L: 146.1,
      p_m6_H: 202,
      p_m12_L: 131.1,
      p_m12_H: 207.6,
    },
    2314: {
      H: 5.64,
      L: 5.55,
      O: 5.6,
      C: 5.59,
      V: 4155550,
      p_w1_L: 5.21,
      p_w1_H: 5.64,
      p_m1_L: 5.21,
      p_m1_H: 5.96,
      p_m3_L: 5.21,
      p_m3_H: 7.59,
      p_m6_L: 5.21,
      p_m6_H: 7.59,
      p_m12_L: 5.21,
      p_m12_H: 8.7,
    },
    2317: {
      H: 0.78,
      L: 0.75,
      O: 0.75,
      C: 0.77,
      V: 428000,
      p_w1_L: 0.74,
      p_w1_H: 0.85,
      p_m1_L: 0.74,
      p_m1_H: 0.85,
      p_m3_L: 0.73,
      p_m3_H: 0.87,
      p_m6_L: 0.65,
      p_m6_H: 0.87,
      p_m12_L: 0.65,
      p_m12_H: 0.89,
    },
    2318: {
      H: 58.35,
      L: 57.35,
      O: 57.6,
      C: 58.3,
      V: 28234000,
      p_w1_L: 53.85,
      p_w1_H: 58.35,
      p_m1_L: 53.8,
      p_m1_H: 59.85,
      p_m3_L: 48.8,
      p_m3_H: 64.65,
      p_m6_L: 48.8,
      p_m6_H: 82.3,
      p_m12_L: 48.8,
      p_m12_H: 103.6,
    },
    2319: {
      H: 44.7,
      L: 43.75,
      O: 44.45,
      C: 44.15,
      V: 13632000,
      p_w1_L: 43.1,
      p_w1_H: 44.9,
      p_m1_L: 43.1,
      p_m1_H: 49.75,
      p_m3_L: 43.1,
      p_m3_H: 51.1,
      p_m6_L: 40.65,
      p_m6_H: 51.1,
      p_m12_L: 38.8,
      p_m12_H: 53.85,
    },
    2320: {
      H: 0.229,
      L: 0.228,
      O: 0.228,
      C: 0.229,
      V: 10000,
      p_w1_L: 0.228,
      p_w1_H: 0.229,
      p_m1_L: 0.224,
      p_m1_H: 0.245,
      p_m3_L: 0.22,
      p_m3_H: 0.26,
      p_m6_L: 0.22,
      p_m6_H: 0.33,
      p_m12_L: 0.22,
      p_m12_H: 0.4,
    },
    2322: {
      H: 0.96,
      L: 0.92,
      O: 0.92,
      C: 0.93,
      V: 480000,
      p_w1_L: 0.91,
      p_w1_H: 0.97,
      p_m1_L: 0.85,
      p_m1_H: 1.04,
      p_m3_L: 0.79,
      p_m3_H: 1.04,
      p_m6_L: 0.79,
      p_m6_H: 1.06,
      p_m12_L: 0.79,
      p_m12_H: 1.18,
    },
    2323: {
      H: 0.134,
      L: 0.134,
      O: 0.134,
      C: 0.134,
      V: 0,
      p_w1_L: 0.134,
      p_w1_H: 0.138,
      p_m1_L: 0.128,
      p_m1_H: 0.138,
      p_m3_L: 0.105,
      p_m3_H: 0.138,
      p_m6_L: 0.105,
      p_m6_H: 0.22,
      p_m12_L: 0.105,
      p_m12_H: 0.31,
    },
    2324: {
      H: 0.355,
      L: 0.355,
      O: 0.355,
      C: 0.355,
      V: 0,
      p_w1_L: 0.305,
      p_w1_H: 0.365,
      p_m1_L: 0.295,
      p_m1_H: 0.365,
      p_m3_L: 0.25,
      p_m3_H: 0.52,
      p_m6_L: 0.238,
      p_m6_H: 0.55,
      p_m12_L: 0.2,
      p_m12_H: 0.55,
    },
    2326: {
      H: 0.016,
      L: 0.014,
      O: 0.016,
      C: 0.016,
      V: 1090000,
      p_w1_L: 0.014,
      p_w1_H: 0.016,
      p_m1_L: 0.014,
      p_m1_H: 0.019,
      p_m3_L: 0.014,
      p_m3_H: 0.025,
      p_m6_L: 0.014,
      p_m6_H: 0.025,
      p_m12_L: 0.01,
      p_m12_H: 0.031,
    },
    2327: {
      H: 0.425,
      L: 0.415,
      O: 0.425,
      C: 0.42,
      V: 1488000,
      p_w1_L: 0.415,
      p_w1_H: 0.44,
      p_m1_L: 0.415,
      p_m1_H: 0.46,
      p_m3_L: 0.365,
      p_m3_H: 0.48,
      p_m6_L: 0.335,
      p_m6_H: 0.5,
      p_m12_L: 0.335,
      p_m12_H: 0.85,
    },
    2328: {
      H: 7,
      L: 6.84,
      O: 7,
      C: 6.87,
      V: 14291800,
      p_w1_L: 6.63,
      p_w1_H: 7,
      p_m1_L: 6.63,
      p_m1_H: 7.13,
      p_m3_L: 6.63,
      p_m3_H: 7.64,
      p_m6_L: 6,
      p_m6_H: 7.65,
      p_m12_L: 5.61,
      p_m12_H: 7.96,
    },
    2329: {
      H: 0.355,
      L: 0.35,
      O: 0.355,
      C: 0.35,
      V: 234000,
      p_w1_L: 0.34,
      p_w1_H: 0.395,
      p_m1_L: 0.33,
      p_m1_H: 0.395,
      p_m3_L: 0.28,
      p_m3_H: 0.395,
      p_m6_L: 0.28,
      p_m6_H: 0.475,
      p_m12_L: 0.28,
      p_m12_H: 0.98,
    },
    2330: {
      H: 0.66,
      L: 0.66,
      O: 0.66,
      C: 0.66,
      V: 0,
      p_w1_L: 0.59,
      p_w1_H: 0.71,
      p_m1_L: 0.49,
      p_m1_H: 0.71,
      p_m3_L: 0.4,
      p_m3_H: 0.74,
      p_m6_L: 0.4,
      p_m6_H: 0.8,
      p_m12_L: 0.4,
      p_m12_H: 0.98,
    },
    2331: {
      H: 92.4,
      L: 89.45,
      O: 90.3,
      C: 92.15,
      V: 13720000,
      p_w1_L: 83.6,
      p_w1_H: 92.4,
      p_m1_L: 83.6,
      p_m1_H: 98,
      p_m3_L: 76.85,
      p_m3_H: 108.2,
      p_m6_L: 68.35,
      p_m6_H: 108.2,
      p_m12_L: 39.55,
      p_m12_H: 108.2,
    },
    2333: {
      H: 31.5,
      L: 29.9,
      O: 31,
      C: 30.4,
      V: 27146200,
      p_w1_L: 29.7,
      p_w1_H: 33.25,
      p_m1_L: 29.7,
      p_m1_H: 37.4,
      p_m3_L: 26.6,
      p_m3_H: 37.8,
      p_m6_L: 21.4,
      p_m6_H: 39,
      p_m12_L: 14.26,
      p_m12_H: 39,
    },
    2336: {
      H: 0.127,
      L: 0.127,
      O: 0.127,
      C: 0.128,
      V: 620000,
      p_w1_L: 0.127,
      p_w1_H: 0.137,
      p_m1_L: 0.127,
      p_m1_H: 0.151,
      p_m3_L: 0.127,
      p_m3_H: 0.2,
      p_m6_L: 0.127,
      p_m6_H: 0.23,
      p_m12_L: 0.125,
      p_m12_H: 0.3,
    },
    2337: {
      H: 12.94,
      L: 11.64,
      O: 12.9,
      C: 11.78,
      V: 676000,
      p_w1_L: 10.88,
      p_w1_H: 13.56,
      p_m1_L: 9.61,
      p_m1_H: 14.84,
      p_m3_L: 8.66,
      p_m3_H: 14.84,
      p_m6_L: 7.35,
      p_m6_H: 16.16,
      p_m12_L: 7,
      p_m12_H: 16.16,
    },
    2338: {
      H: 16.16,
      L: 15.52,
      O: 15.98,
      C: 15.98,
      V: 11101500,
      p_w1_L: 13.86,
      p_w1_H: 16.98,
      p_m1_L: 13.12,
      p_m1_H: 16.98,
      p_m3_L: 12.76,
      p_m3_H: 20.2,
      p_m6_L: 12.76,
      p_m6_H: 20.75,
      p_m12_L: 12.76,
      p_m12_H: 26.4,
    },
    2339: {
      H: 0.85,
      L: 0.8,
      O: 0.81,
      C: 0.83,
      V: 70000,
      p_w1_L: 0.76,
      p_w1_H: 0.85,
      p_m1_L: 0.73,
      p_m1_H: 0.88,
      p_m3_L: 0.58,
      p_m3_H: 1.2,
      p_m6_L: 0.53,
      p_m6_H: 1.2,
      p_m12_L: 0.45,
      p_m12_H: 1.2,
    },
    2340: {
      H: 0.87,
      L: 0.77,
      O: 0.78,
      C: 0.79,
      V: 1392000,
      p_w1_L: 0.75,
      p_w1_H: 0.87,
      p_m1_L: 0.74,
      p_m1_H: 1.18,
      p_m3_L: 0.295,
      p_m3_H: 1.18,
      p_m6_L: 0.239,
      p_m6_H: 1.18,
      p_m12_L: 0.233,
      p_m12_H: 1.18,
    },
    2341: {
      H: 1.6,
      L: 1.58,
      O: 1.6,
      C: 1.59,
      V: 176000,
      p_w1_L: 1.56,
      p_w1_H: 1.65,
      p_m1_L: 1.55,
      p_m1_H: 1.75,
      p_m3_L: 1.55,
      p_m3_H: 2,
      p_m6_L: 1.55,
      p_m6_H: 2.52,
      p_m12_L: 1.23,
      p_m12_H: 2.52,
    },
    2342: {
      H: 1.81,
      L: 1.78,
      O: 1.79,
      C: 1.79,
      V: 1620040,
      p_w1_L: 1.72,
      p_w1_H: 1.88,
      p_m1_L: 1.72,
      p_m1_H: 2.04,
      p_m3_L: 1.72,
      p_m3_H: 2.33,
      p_m6_L: 1.66,
      p_m6_H: 2.33,
      p_m12_L: 1.66,
      p_m12_H: 2.74,
    },
    2343: {
      H: 3.19,
      L: 3.03,
      O: 3.18,
      C: 3.13,
      V: 26687600,
      p_w1_L: 2.83,
      p_w1_H: 3.22,
      p_m1_L: 2.6,
      p_m1_H: 3.22,
      p_m3_L: 2.6,
      p_m3_H: 4.55,
      p_m6_L: 2.6,
      p_m6_H: 4.55,
      p_m12_L: 1.29,
      p_m12_H: 4.55,
    },
    2346: {
      H: 1.7,
      L: 1.61,
      O: 1.62,
      C: 1.67,
      V: 854000,
      p_w1_L: 1.45,
      p_w1_H: 1.7,
      p_m1_L: 1.45,
      p_m1_H: 1.98,
      p_m3_L: 1.36,
      p_m3_H: 4.95,
      p_m6_L: 0.39,
      p_m6_H: 4.95,
      p_m12_L: 0.206,
      p_m12_H: 4.95,
    },
    2348: {
      H: 1.66,
      L: 1.65,
      O: 1.65,
      C: 1.66,
      V: 40000,
      p_w1_L: 1.58,
      p_w1_H: 1.7,
      p_m1_L: 1.58,
      p_m1_H: 1.74,
      p_m3_L: 1.43,
      p_m3_H: 1.76,
      p_m6_L: 1.43,
      p_m6_H: 1.93,
      p_m12_L: 1.09,
      p_m12_H: 1.93,
    },
    2349: {
      H: 0.17,
      L: 0.154,
      O: 0.154,
      C: 0.17,
      V: 200000,
      p_w1_L: 0.154,
      p_w1_H: 0.2,
      p_m1_L: 0.154,
      p_m1_H: 0.285,
      p_m3_L: 0.154,
      p_m3_H: 0.37,
      p_m6_L: 0.104,
      p_m6_H: 0.38,
      p_m12_L: 0.085,
      p_m12_H: 0.38,
    },
    2355: {
      H: 3.79,
      L: 3.65,
      O: 3.78,
      C: 3.65,
      V: 70000,
      p_w1_L: 3.65,
      p_w1_H: 3.85,
      p_m1_L: 3.6,
      p_m1_H: 3.98,
      p_m3_L: 3.55,
      p_m3_H: 4.09,
      p_m6_L: 3.55,
      p_m6_H: 4.65,
      p_m12_L: 3.53,
      p_m12_H: 4.7,
    },
    2356: {
      H: 6.85,
      L: 6.8,
      O: 6.83,
      C: 6.83,
      V: 500000,
      p_w1_L: 6.76,
      p_w1_H: 7.03,
      p_m1_L: 6.75,
      p_m1_H: 7.8,
      p_m3_L: 6.75,
      p_m3_H: 8.26,
      p_m6_L: 6.75,
      p_m6_H: 11.26,
      p_m12_L: 6.75,
      p_m12_H: 11.26,
    },
    2357: {
      H: 5.36,
      L: 5.25,
      O: 5.27,
      C: 5.31,
      V: 8111000,
      p_w1_L: 5.01,
      p_w1_H: 5.56,
      p_m1_L: 5.01,
      p_m1_H: 5.68,
      p_m3_L: 4.67,
      p_m3_H: 6.14,
      p_m6_L: 4.67,
      p_m6_H: 6.82,
      p_m12_L: 4.19,
      p_m12_H: 8.13,
    },
    2358: {
      H: 0.091,
      L: 0.091,
      O: 0.091,
      C: 0.091,
      V: 0,
      p_w1_L: 0.084,
      p_w1_H: 0.094,
      p_m1_L: 0.084,
      p_m1_H: 0.098,
      p_m3_L: 0.084,
      p_m3_H: 0.102,
      p_m6_L: 0.084,
      p_m6_H: 0.115,
      p_m12_L: 0.084,
      p_m12_H: 0.143,
    },
    2359: {
      H: 167.1,
      L: 160.4,
      O: 161.8,
      C: 165.4,
      V: 1332320,
      p_w1_L: 158.8,
      p_w1_H: 179.2,
      p_m1_L: 146,
      p_m1_H: 179.2,
      p_m3_L: 146,
      p_m3_H: 189.8,
      p_m6_L: 131.6,
      p_m6_H: 196.9,
      p_m12_L: 101.583,
      p_m12_H: 196.9,
    },
    2360: {
      H: 1.67,
      L: 1.67,
      O: 1.67,
      C: 1.67,
      V: 30000,
      p_w1_L: 1.65,
      p_w1_H: 1.71,
      p_m1_L: 1.63,
      p_m1_H: 1.83,
      p_m3_L: 1.63,
      p_m3_H: 1.88,
      p_m6_L: 1.63,
      p_m6_H: 2.32,
      p_m12_L: 1.63,
      p_m12_H: 2.56,
    },
    2362: {
      H: 1.2,
      L: 1.15,
      O: 1.16,
      C: 1.19,
      V: 6688000,
      p_w1_L: 1.12,
      p_w1_H: 1.23,
      p_m1_L: 0.98,
      p_m1_H: 1.24,
      p_m3_L: 0.98,
      p_m3_H: 1.44,
      p_m6_L: 0.94,
      p_m6_H: 2,
      p_m12_L: 0.76,
      p_m12_H: 2,
    },
    2363: {
      H: 0.185,
      L: 0.185,
      O: 0.185,
      C: 0.185,
      V: 5000,
      p_w1_L: 0.168,
      p_w1_H: 0.199,
      p_m1_L: 0.16,
      p_m1_H: 0.22,
      p_m3_L: 0.16,
      p_m3_H: 0.36,
      p_m6_L: 0.16,
      p_m6_H: 0.36,
      p_m12_L: 0.16,
      p_m12_H: 0.65,
    },
    2366: {
      H: 0.059,
      L: 0.059,
      O: 0.059,
      C: 0.059,
      V: 0,
      p_w1_L: 0.059,
      p_w1_H: 0.059,
      p_m1_L: 0.059,
      p_m1_H: 0.059,
      p_m3_L: 0.059,
      p_m3_H: 0.059,
      p_m6_L: 0.059,
      p_m6_H: 0.059,
      p_m12_L: 0.059,
      p_m12_H: 0.059,
    },
    2368: {
      H: 4.65,
      L: 4.6,
      O: 4.6,
      C: 4.65,
      V: 86000,
      p_w1_L: 4.37,
      p_w1_H: 4.65,
      p_m1_L: 4.37,
      p_m1_H: 5.05,
      p_m3_L: 4.26,
      p_m3_H: 5.05,
      p_m6_L: 4.26,
      p_m6_H: 5.92,
      p_m12_L: 3.69,
      p_m12_H: 5.92,
    },
    2369: {
      H: 0.465,
      L: 0.445,
      O: 0.46,
      C: 0.445,
      V: 41810000,
      p_w1_L: 0.375,
      p_w1_H: 0.47,
      p_m1_L: 0.36,
      p_m1_H: 0.47,
      p_m3_L: 0.295,
      p_m3_H: 0.49,
      p_m6_L: 0.247,
      p_m6_H: 0.49,
      p_m12_L: 0.21,
      p_m12_H: 0.66,
    },
    2371: {
      H: 0.096,
      L: 0.09,
      O: 0.09,
      C: 0.096,
      V: 1284000,
      p_w1_L: 0.087,
      p_w1_H: 0.105,
      p_m1_L: 0.087,
      p_m1_H: 0.114,
      p_m3_L: 0.087,
      p_m3_H: 0.125,
      p_m6_L: 0.082,
      p_m6_H: 0.143,
      p_m12_L: 0.027,
      p_m12_H: 0.157,
    },
    2377: {
      H: 1.42,
      L: 1.42,
      O: 1.42,
      C: 1.42,
      V: 0,
      p_w1_L: 1.31,
      p_w1_H: 1.46,
      p_m1_L: 1.19,
      p_m1_H: 1.5,
      p_m3_L: 1.05,
      p_m3_H: 1.76,
      p_m6_L: 1.05,
      p_m6_H: 2.33,
      p_m12_L: 1,
      p_m12_H: 2.8,
    },
    2378: {
      H: 137.1,
      L: 133.9,
      O: 133.9,
      C: 135.8,
      V: 95200,
      p_w1_L: 129.4,
      p_w1_H: 137.1,
      p_m1_L: 129,
      p_m1_H: 156.7,
      p_m3_L: 129,
      p_m3_H: 165,
      p_m6_L: 129,
      p_m6_H: 166.5,
      p_m12_L: 125,
      p_m12_H: 174.5,
    },
    2379: {
      H: 0.247,
      L: 0.247,
      O: 0.247,
      C: 0.247,
      V: 0,
      p_w1_L: 0.247,
      p_w1_H: 0.247,
      p_m1_L: 0.247,
      p_m1_H: 0.26,
      p_m3_L: 0.247,
      p_m3_H: 0.4,
      p_m6_L: 0.247,
      p_m6_H: 0.48,
      p_m12_L: 0.247,
      p_m12_H: 0.59,
    },
    2380: {
      H: 4.6,
      L: 4.09,
      O: 4.12,
      C: 4.59,
      V: 239505000,
      p_w1_L: 3.73,
      p_w1_H: 4.6,
      p_m1_L: 3.34,
      p_m1_H: 4.6,
      p_m3_L: 2.86,
      p_m3_H: 4.6,
      p_m6_L: 1.62,
      p_m6_H: 4.6,
      p_m12_L: 1.51,
      p_m12_H: 4.6,
    },
    2381: {
      H: 0.106,
      L: 0.106,
      O: 0.106,
      C: 0.106,
      V: 10000,
      p_w1_L: 0.103,
      p_w1_H: 0.115,
      p_m1_L: 0.103,
      p_m1_H: 0.132,
      p_m3_L: 0.095,
      p_m3_H: 0.138,
      p_m6_L: 0.095,
      p_m6_H: 0.138,
      p_m12_L: 0.09,
      p_m12_H: 0.173,
    },
    2382: {
      H: 239,
      L: 229.6,
      O: 230.8,
      C: 237.4,
      V: 4290400,
      p_w1_L: 224.4,
      p_w1_H: 241.4,
      p_m1_L: 196.9,
      p_m1_H: 243.8,
      p_m3_L: 184.2,
      p_m3_H: 243.8,
      p_m6_L: 184.2,
      p_m6_H: 259.4,
      p_m12_L: 141.2,
      p_m12_H: 259.4,
    },
    2383: {
      H: 0.85,
      L: 0.82,
      O: 0.83,
      C: 0.84,
      V: 294000,
      p_w1_L: 0.78,
      p_w1_H: 0.94,
      p_m1_L: 0.58,
      p_m1_H: 0.94,
      p_m3_L: 0.345,
      p_m3_H: 0.94,
      p_m6_L: 0.345,
      p_m6_H: 0.94,
      p_m12_L: 0.34,
      p_m12_H: 0.94,
    },
    2386: {
      H: 4.02,
      L: 3.9,
      O: 4.01,
      C: 3.94,
      V: 1353590,
      p_w1_L: 3.78,
      p_w1_H: 4.04,
      p_m1_L: 3.57,
      p_m1_H: 4.04,
      p_m3_L: 3.57,
      p_m3_H: 4.56,
      p_m6_L: 3.57,
      p_m6_H: 5.25,
      p_m12_L: 3.23,
      p_m12_H: 5.25,
    },
    2388: {
      H: 25.1,
      L: 24.6,
      O: 25.1,
      C: 24.9,
      V: 5824990,
      p_w1_L: 23.5,
      p_w1_H: 25.1,
      p_m1_L: 23.2,
      p_m1_H: 25.1,
      p_m3_L: 22.2,
      p_m3_H: 25.5,
      p_m6_L: 22.2,
      p_m6_H: 28.55,
      p_m12_L: 22.2,
      p_m12_H: 29.55,
    },
    2389: {
      H: 0.085,
      L: 0.085,
      O: 0.085,
      C: 0.085,
      V: 384000,
      p_w1_L: 0.081,
      p_w1_H: 0.104,
      p_m1_L: 0.081,
      p_m1_H: 0.111,
      p_m3_L: 0.081,
      p_m3_H: 0.122,
      p_m6_L: 0.081,
      p_m6_H: 0.145,
      p_m12_L: 0.081,
      p_m12_H: 0.145,
    },
    2393: {
      H: 1.23,
      L: 1.17,
      O: 1.23,
      C: 1.18,
      V: 107500,
      p_w1_L: 1.12,
      p_w1_H: 1.29,
      p_m1_L: 1.01,
      p_m1_H: 1.29,
      p_m3_L: 0.97,
      p_m3_H: 1.29,
      p_m6_L: 0.97,
      p_m6_H: 1.29,
      p_m12_L: 0.97,
      p_m12_H: 1.29,
    },
    2398: {
      H: 1.49,
      L: 1.49,
      O: 1.49,
      C: 1.49,
      V: 0,
      p_w1_L: 1.49,
      p_w1_H: 1.5,
      p_m1_L: 1.33,
      p_m1_H: 1.5,
      p_m3_L: 1.32,
      p_m3_H: 1.5,
      p_m6_L: 0.83,
      p_m6_H: 1.7,
      p_m12_L: 0.83,
      p_m12_H: 1.7,
    },
    2399: {
      H: 0.59,
      L: 0.58,
      O: 0.58,
      C: 0.58,
      V: 10387000,
      p_w1_L: 0.58,
      p_w1_H: 0.64,
      p_m1_L: 0.58,
      p_m1_H: 0.64,
      p_m3_L: 0.58,
      p_m3_H: 0.72,
      p_m6_L: 0.56,
      p_m6_H: 1.02,
      p_m12_L: 0.54,
      p_m12_H: 6.58,
    },
    2400: {
      H: 47.6,
      L: 46.2,
      O: 46.9,
      C: 46.9,
      V: 1363600,
      p_w1_L: 43.2,
      p_w1_H: 47.95,
      p_m1_L: 42,
      p_m1_H: 56.5,
      p_m3_L: 38.8,
      p_m3_H: 56.5,
      p_m6_L: 35.5,
      p_m6_H: 71.5,
      p_m12_L: 35.5,
      p_m12_H: 114.5,
    },
    2448: {
      H: 5.41,
      L: 5.19,
      O: 5.41,
      C: 5.4,
      V: 377500,
      p_w1_L: 5.18,
      p_w1_H: 5.44,
      p_m1_L: 4.96,
      p_m1_H: 5.77,
      p_m3_L: 4.73,
      p_m3_H: 6.03,
      p_m6_L: 3,
      p_m6_H: 6.03,
      p_m12_L: 1.59,
      p_m12_H: 6.03,
    },
    2488: {
      H: 2.78,
      L: 2.68,
      O: 2.73,
      C: 2.71,
      V: 153300,
      p_w1_L: 2.68,
      p_w1_H: 2.89,
      p_m1_L: 2.6,
      p_m1_H: 3.2,
      p_m3_L: 2.6,
      p_m3_H: 3.87,
      p_m6_L: 2.6,
      p_m6_H: 4.99,
      p_m12_L: 2.6,
      p_m12_H: 5.39,
    },
    2500: {
      H: 29.6,
      L: 26.6,
      O: 26.6,
      C: 28.45,
      V: 4576790,
      p_w1_L: 24.55,
      p_w1_H: 39,
      p_m1_L: 24.55,
      p_m1_H: 41.85,
      p_m3_L: 24.55,
      p_m3_H: 46.4,
      p_m6_L: 24.55,
      p_m6_H: 75,
      p_m12_L: 24.55,
      p_m12_H: 96,
    },
    2518: {
      H: 67.8,
      L: 65.55,
      O: 65.55,
      C: 67.5,
      V: 55800,
      p_w1_L: 56.55,
      p_w1_H: 68.5,
      p_m1_L: 56.55,
      p_m1_H: 77.1,
      p_m3_L: 56.55,
      p_m3_H: 101,
      p_m6_L: 56.55,
      p_m6_H: 143.3,
      p_m12_L: 56.55,
      p_m12_H: 199.6,
    },
    2528: {
      H: 1.06,
      L: 1.01,
      O: 1.03,
      C: 1.05,
      V: 26000,
      p_w1_L: 0.97,
      p_w1_H: 1.12,
      p_m1_L: 0.97,
      p_m1_H: 1.28,
      p_m3_L: 0.97,
      p_m3_H: 1.49,
      p_m6_L: 0.85,
      p_m6_H: 1.49,
      p_m12_L: 0.85,
      p_m12_H: 1.49,
    },
    2552: {
      H: 3.76,
      L: 3.68,
      O: 3.74,
      C: 3.7,
      V: 405000,
      p_w1_L: 3.61,
      p_w1_H: 4.23,
      p_m1_L: 3.51,
      p_m1_H: 4.35,
      p_m3_L: 3.51,
      p_m3_H: 5.38,
      p_m6_L: 3.5,
      p_m6_H: 5.38,
      p_m12_L: 3.5,
      p_m12_H: 7.21,
    },
    2558: {
      H: 1.41,
      L: 1.41,
      O: 1.41,
      C: 1.41,
      V: 0,
      p_w1_L: 1.36,
      p_w1_H: 1.42,
      p_m1_L: 1.35,
      p_m1_H: 1.5,
      p_m3_L: 1.3,
      p_m3_H: 1.68,
      p_m6_L: 1.3,
      p_m6_H: 1.68,
      p_m12_L: 1.3,
      p_m12_H: 1.78,
    },
    2588: {
      H: 58.45,
      L: 56.75,
      O: 57.8,
      C: 57.9,
      V: 1603600,
      p_w1_L: 52.3,
      p_w1_H: 58.45,
      p_m1_L: 52.3,
      p_m1_H: 70.9,
      p_m3_L: 52.3,
      p_m3_H: 70.9,
      p_m6_L: 52.3,
      p_m6_H: 70.9,
      p_m12_L: 52.3,
      p_m12_H: 81.95,
    },
    2599: {
      H: 1.15,
      L: 1.11,
      O: 1.15,
      C: 1.12,
      V: 9419000,
      p_w1_L: 1.05,
      p_w1_H: 1.41,
      p_m1_L: 1.05,
      p_m1_H: 4.96,
      p_m3_L: 1.05,
      p_m3_H: 6.1,
      p_m6_L: 1.05,
      p_m6_H: 6.4,
      p_m12_L: 1.05,
      p_m12_H: 6.56,
    },
    2600: {
      H: 4.47,
      L: 4.26,
      O: 4.31,
      C: 4.35,
      V: 76649500,
      p_w1_L: 3.68,
      p_w1_H: 4.47,
      p_m1_L: 3.68,
      p_m1_H: 4.47,
      p_m3_L: 3.68,
      p_m3_H: 7.49,
      p_m6_L: 3.68,
      p_m6_H: 7.49,
      p_m12_L: 2.31,
      p_m12_H: 7.49,
    },
    2601: {
      H: 23.65,
      L: 23.1,
      O: 23.65,
      C: 23.3,
      V: 3751800,
      p_w1_L: 22.55,
      p_w1_H: 23.65,
      p_m1_L: 22.5,
      p_m1_H: 25.3,
      p_m3_L: 21.35,
      p_m3_H: 26.2,
      p_m6_L: 20.85,
      p_m6_H: 27.4,
      p_m12_L: 20.85,
      p_m12_H: 42.75,
    },
    2607: {
      H: 14.4,
      L: 14.22,
      O: 14.32,
      C: 14.28,
      V: 2281800,
      p_w1_L: 13.92,
      p_w1_H: 14.64,
      p_m1_L: 13.28,
      p_m1_H: 14.64,
      p_m3_L: 13.22,
      p_m3_H: 16.48,
      p_m6_L: 13.22,
      p_m6_H: 18,
      p_m12_L: 12.18,
      p_m12_H: 19.02,
    },
    2608: {
      H: 0.48,
      L: 0.35,
      O: 0.35,
      C: 0.42,
      V: 426000,
      p_w1_L: 0.05,
      p_w1_H: 0.48,
      p_m1_L: 0.05,
      p_m1_H: 0.48,
      p_m3_L: 0.05,
      p_m3_H: 0.52,
      p_m6_L: 0.05,
      p_m6_H: 1.4,
      p_m12_L: 0.05,
      p_m12_H: 1.4,
    },
    2611: {
      H: 11.2,
      L: 11.04,
      O: 11.2,
      C: 11.08,
      V: 2258600,
      p_w1_L: 10.38,
      p_w1_H: 11.2,
      p_m1_L: 10.32,
      p_m1_H: 11.2,
      p_m3_L: 10.32,
      p_m3_H: 11.78,
      p_m6_L: 9.61,
      p_m6_H: 11.78,
      p_m12_L: 9.61,
      p_m12_H: 12.8,
    },
    2616: {
      H: 9.61,
      L: 9.36,
      O: 9.4,
      C: 9.42,
      V: 944368,
      p_w1_L: 9.17,
      p_w1_H: 10.32,
      p_m1_L: 8.5,
      p_m1_H: 11.68,
      p_m3_L: 8.5,
      p_m3_H: 13.88,
      p_m6_L: 8.5,
      p_m6_H: 19.12,
      p_m12_L: 7.99,
      p_m12_H: 19.12,
    },
    2618: {
      H: 27.6,
      L: 26.95,
      O: 27,
      C: 27.2,
      V: 8610450,
      p_w1_L: 24.6,
      p_w1_H: 27.6,
      p_m1_L: 24.55,
      p_m1_H: 33.8,
      p_m3_L: 24.55,
      p_m3_H: 36,
      p_m6_L: 24.55,
      p_m6_H: 46.15,
      p_m12_L: 24.55,
      p_m12_H: 48.15,
    },
    2623: {
      H: 0.134,
      L: 0.13,
      O: 0.132,
      C: 0.13,
      V: 46000,
      p_w1_L: 0.13,
      p_w1_H: 0.143,
      p_m1_L: 0.123,
      p_m1_H: 0.178,
      p_m3_L: 0.123,
      p_m3_H: 0.178,
      p_m6_L: 0.1,
      p_m6_H: 0.28,
      p_m12_L: 0.067,
      p_m12_H: 0.315,
    },
    2628: {
      H: 13.42,
      L: 13.2,
      O: 13.42,
      C: 13.32,
      V: 14021200,
      p_w1_L: 12.84,
      p_w1_H: 13.48,
      p_m1_L: 12.72,
      p_m1_H: 14,
      p_m3_L: 12.22,
      p_m3_H: 14.5,
      p_m6_L: 12.22,
      p_m6_H: 16.04,
      p_m12_L: 12.22,
      p_m12_H: 18.46,
    },
    2633: {
      H: 0.51,
      L: 0.49,
      O: 0.51,
      C: 0.49,
      V: 584000,
      p_w1_L: 0.49,
      p_w1_H: 0.52,
      p_m1_L: 0.48,
      p_m1_H: 0.56,
      p_m3_L: 0.48,
      p_m3_H: 0.73,
      p_m6_L: 0.48,
      p_m6_H: 0.8,
      p_m12_L: 0.48,
      p_m12_H: 1.4,
    },
    2638: {
      H: 7.67,
      L: 7.62,
      O: 7.63,
      C: 7.65,
      V: 1286420,
      p_w1_L: 7.61,
      p_w1_H: 7.72,
      p_m1_L: 7.61,
      p_m1_H: 7.84,
      p_m3_L: 7.5,
      p_m3_H: 7.84,
      p_m6_L: 7.5,
      p_m6_H: 7.99,
      p_m12_L: 7.5,
      p_m12_H: 7.99,
    },
    2660: {
      H: 1.27,
      L: 1.26,
      O: 1.27,
      C: 1.27,
      V: 12000,
      p_w1_L: 1.24,
      p_w1_H: 1.39,
      p_m1_L: 1.23,
      p_m1_H: 1.39,
      p_m3_L: 1.1,
      p_m3_H: 1.39,
      p_m6_L: 1.1,
      p_m6_H: 1.64,
      p_m12_L: 1.02,
      p_m12_H: 1.89,
    },
    2663: {
      H: 0.37,
      L: 0.36,
      O: 0.36,
      C: 0.37,
      V: 60000,
      p_w1_L: 0.345,
      p_w1_H: 0.385,
      p_m1_L: 0.34,
      p_m1_H: 0.395,
      p_m3_L: 0.33,
      p_m3_H: 0.395,
      p_m6_L: 0.32,
      p_m6_H: 0.45,
      p_m12_L: 0.238,
      p_m12_H: 0.45,
    },
    2666: {
      H: 5.41,
      L: 5.31,
      O: 5.41,
      C: 5.34,
      V: 966497,
      p_w1_L: 5.26,
      p_w1_H: 5.48,
      p_m1_L: 5.26,
      p_m1_H: 5.85,
      p_m3_L: 5.26,
      p_m3_H: 6.39,
      p_m6_L: 5.26,
      p_m6_H: 8.19,
      p_m12_L: 5.26,
      p_m12_H: 8.19,
    },
    2668: {
      H: 0.223,
      L: 0.212,
      O: 0.212,
      C: 0.222,
      V: 1460000,
      p_w1_L: 0.203,
      p_w1_H: 0.224,
      p_m1_L: 0.203,
      p_m1_H: 0.265,
      p_m3_L: 0.198,
      p_m3_H: 0.265,
      p_m6_L: 0.198,
      p_m6_H: 0.3,
      p_m12_L: 0.189,
      p_m12_H: 0.31,
    },
    2669: {
      H: 7.98,
      L: 7.58,
      O: 7.79,
      C: 7.95,
      V: 8924880,
      p_w1_L: 7.1,
      p_w1_H: 7.98,
      p_m1_L: 6.32,
      p_m1_H: 8.3,
      p_m3_L: 5.56,
      p_m3_H: 8.3,
      p_m6_L: 5.56,
      p_m6_H: 9.11,
      p_m12_L: 3.9,
      p_m12_H: 9.11,
    },
    2678: {
      H: 10.46,
      L: 10.24,
      O: 10.24,
      C: 10.44,
      V: 563000,
      p_w1_L: 10,
      p_w1_H: 11.28,
      p_m1_L: 10,
      p_m1_H: 11.92,
      p_m3_L: 10,
      p_m3_H: 12.8,
      p_m6_L: 10,
      p_m6_H: 13.7,
      p_m12_L: 6.21,
      p_m12_H: 14.56,
    },
    2680: {
      H: 0.56,
      L: 0.56,
      O: 0.56,
      C: 0.56,
      V: 0,
      p_w1_L: 0.51,
      p_w1_H: 0.58,
      p_m1_L: 0.5,
      p_m1_H: 0.68,
      p_m3_L: 0.5,
      p_m3_H: 0.8,
      p_m6_L: 0.5,
      p_m6_H: 1.08,
      p_m12_L: 0.41,
      p_m12_H: 1.17,
    },
    2682: {
      H: 0.162,
      L: 0.162,
      O: 0.162,
      C: 0.162,
      V: 0,
      p_w1_L: 0.156,
      p_w1_H: 0.166,
      p_m1_L: 0.156,
      p_m1_H: 0.173,
      p_m3_L: 0.148,
      p_m3_H: 0.18,
      p_m6_L: 0.136,
      p_m6_H: 0.228,
      p_m12_L: 0.103,
      p_m12_H: 0.228,
    },
    2683: {
      H: 0.44,
      L: 0.42,
      O: 0.42,
      C: 0.43,
      V: 38000,
      p_w1_L: 0.415,
      p_w1_H: 0.445,
      p_m1_L: 0.315,
      p_m1_H: 0.46,
      p_m3_L: 0.27,
      p_m3_H: 0.46,
      p_m6_L: 0.27,
      p_m6_H: 0.46,
      p_m12_L: 0.235,
      p_m12_H: 0.46,
    },
    2686: {
      H: 1.4,
      L: 1.32,
      O: 1.34,
      C: 1.39,
      V: 6608000,
      p_w1_L: 1.32,
      p_w1_H: 1.5,
      p_m1_L: 1.32,
      p_m1_H: 1.64,
      p_m3_L: 1.32,
      p_m3_H: 3.05,
      p_m6_L: 1,
      p_m6_H: 3.05,
      p_m12_L: 1,
      p_m12_H: 3.05,
    },
    2688: {
      H: 138.6,
      L: 134.6,
      O: 136.1,
      C: 137.8,
      V: 2822780,
      p_w1_L: 132.1,
      p_w1_H: 151.1,
      p_m1_L: 126.6,
      p_m1_H: 159,
      p_m3_L: 113.2,
      p_m3_H: 159,
      p_m6_L: 113.2,
      p_m6_H: 178.8,
      p_m12_L: 101.2,
      p_m12_H: 178.8,
    },
    2689: {
      H: 9.58,
      L: 9.36,
      O: 9.47,
      C: 9.4,
      V: 4348230,
      p_w1_L: 8.81,
      p_w1_H: 9.58,
      p_m1_L: 8.71,
      p_m1_H: 9.88,
      p_m3_L: 8.71,
      p_m3_H: 11.96,
      p_m6_L: 8.71,
      p_m6_H: 11.96,
      p_m12_L: 8.71,
      p_m12_H: 16.62,
    },
    2696: {
      H: 28.8,
      L: 25.6,
      O: 26.15,
      C: 28.7,
      V: 204700,
      p_w1_L: 25.4,
      p_w1_H: 30.1,
      p_m1_L: 24.2,
      p_m1_H: 34.05,
      p_m3_L: 22,
      p_m3_H: 34.05,
      p_m6_L: 22,
      p_m6_H: 42.95,
      p_m12_L: 22,
      p_m12_H: 48.1,
    },
    2698: {
      H: 2.67,
      L: 2.6,
      O: 2.6,
      C: 2.67,
      V: 448000,
      p_w1_L: 2.57,
      p_w1_H: 2.69,
      p_m1_L: 2.57,
      p_m1_H: 2.8,
      p_m3_L: 2.57,
      p_m3_H: 3.29,
      p_m6_L: 1.98,
      p_m6_H: 3.29,
      p_m12_L: 1.62,
      p_m12_H: 3.29,
    },
    2699: {
      H: 0.045,
      L: 0.04,
      O: 0.045,
      C: 0.043,
      V: 614000,
      p_w1_L: 0.04,
      p_w1_H: 0.047,
      p_m1_L: 0.039,
      p_m1_H: 0.055,
      p_m3_L: 0.034,
      p_m3_H: 0.058,
      p_m6_L: 0.034,
      p_m6_H: 0.085,
      p_m12_L: 0.034,
      p_m12_H: 0.125,
    },
    2700: {
      H: 0.39,
      L: 0.385,
      O: 0.39,
      C: 0.39,
      V: 100000,
      p_w1_L: 0.385,
      p_w1_H: 0.41,
      p_m1_L: 0.385,
      p_m1_H: 0.435,
      p_m3_L: 0.385,
      p_m3_H: 0.46,
      p_m6_L: 0.385,
      p_m6_H: 0.53,
      p_m12_L: 0.3,
      p_m12_H: 0.595,
    },
    2708: {
      H: 2.65,
      L: 2.47,
      O: 2.54,
      C: 2.65,
      V: 506000,
      p_w1_L: 2.47,
      p_w1_H: 2.85,
      p_m1_L: 2.47,
      p_m1_H: 3.09,
      p_m3_L: 2.47,
      p_m3_H: 3.29,
      p_m6_L: 2.47,
      p_m6_H: 4,
      p_m12_L: 2,
      p_m12_H: 6.26,
    },
    2718: {
      H: 0.94,
      L: 0.93,
      O: 0.93,
      C: 0.94,
      V: 12000,
      p_w1_L: 0.92,
      p_w1_H: 0.95,
      p_m1_L: 0.9,
      p_m1_H: 0.99,
      p_m3_L: 0.86,
      p_m3_H: 1.04,
      p_m6_L: 0.75,
      p_m6_H: 1.28,
      p_m12_L: 0.52,
      p_m12_H: 1.28,
    },
    2722: {
      H: 0.66,
      L: 0.63,
      O: 0.64,
      C: 0.66,
      V: 2560000,
      p_w1_L: 0.62,
      p_w1_H: 0.67,
      p_m1_L: 0.62,
      p_m1_H: 0.75,
      p_m3_L: 0.59,
      p_m3_H: 0.89,
      p_m6_L: 0.46,
      p_m6_H: 0.89,
      p_m12_L: 0.44,
      p_m12_H: 0.89,
    },
    2727: {
      H: 2.29,
      L: 2.24,
      O: 2.28,
      C: 2.28,
      V: 5294000,
      p_w1_L: 2.22,
      p_w1_H: 2.37,
      p_m1_L: 2.21,
      p_m1_H: 2.41,
      p_m3_L: 2.05,
      p_m3_H: 2.67,
      p_m6_L: 1.66,
      p_m6_H: 2.67,
      p_m12_L: 1.66,
      p_m12_H: 3.56,
    },
    2728: {
      H: 0.155,
      L: 0.155,
      O: 0.155,
      C: 0.155,
      V: 28000,
      p_w1_L: 0.135,
      p_w1_H: 0.174,
      p_m1_L: 0.135,
      p_m1_H: 0.196,
      p_m3_L: 0.135,
      p_m3_H: 0.219,
      p_m6_L: 0.135,
      p_m6_H: 0.3,
      p_m12_L: 0.128,
      p_m12_H: 0.3,
    },
    2738: {
      H: 2.5,
      L: 2.42,
      O: 2.47,
      C: 2.5,
      V: 352000,
      p_w1_L: 2.29,
      p_w1_H: 2.5,
      p_m1_L: 2.05,
      p_m1_H: 2.5,
      p_m3_L: 1.71,
      p_m3_H: 2.86,
      p_m6_L: 1.71,
      p_m6_H: 3.21,
      p_m12_L: 1.71,
      p_m12_H: 3.21,
    },
    2768: {
      H: 2.96,
      L: 2.95,
      O: 2.96,
      C: 2.95,
      V: 3486000,
      p_w1_L: 2.95,
      p_w1_H: 3,
      p_m1_L: 2.91,
      p_m1_H: 3.02,
      p_m3_L: 2.85,
      p_m3_H: 3.23,
      p_m6_L: 2.85,
      p_m6_H: 3.95,
      p_m12_L: 2.85,
      p_m12_H: 4,
    },
    2772: {
      H: 3.69,
      L: 3.62,
      O: 3.64,
      C: 3.65,
      V: 1615000,
      p_w1_L: 3.36,
      p_w1_H: 3.72,
      p_m1_L: 3.36,
      p_m1_H: 3.98,
      p_m3_L: 3.36,
      p_m3_H: 4.77,
      p_m6_L: 3.36,
      p_m6_H: 4.98,
      p_m12_L: 3.36,
      p_m12_H: 5.66,
    },
    2777: {
      H: 4.24,
      L: 4.09,
      O: 4.21,
      C: 4.11,
      V: 9679390,
      p_w1_L: 3.9,
      p_w1_H: 4.24,
      p_m1_L: 3.9,
      p_m1_H: 4.81,
      p_m3_L: 3.9,
      p_m3_H: 6.4,
      p_m6_L: 3.9,
      p_m6_H: 9.69,
      p_m12_L: 3.9,
      p_m12_H: 10.76,
    },
    2778: {
      H: 4.1,
      L: 4.04,
      O: 4.05,
      C: 4.05,
      V: 776175,
      p_w1_L: 4.01,
      p_w1_H: 4.11,
      p_m1_L: 4.01,
      p_m1_H: 4.19,
      p_m3_L: 3.72,
      p_m3_H: 4.39,
      p_m6_L: 3.72,
      p_m6_H: 4.65,
      p_m12_L: 3.72,
      p_m12_H: 4.9,
    },
    2779: {
      H: 1.59,
      L: 1.59,
      O: 1.59,
      C: 1.59,
      V: 0,
      p_w1_L: 1.54,
      p_w1_H: 1.75,
      p_m1_L: 1.54,
      p_m1_H: 1.84,
      p_m3_L: 1.43,
      p_m3_H: 1.84,
      p_m6_L: 1.43,
      p_m6_H: 2.28,
      p_m12_L: 1.43,
      p_m12_H: 2.58,
    },
    2788: {
      H: 0.82,
      L: 0.79,
      O: 0.82,
      C: 0.81,
      V: 1612000,
      p_w1_L: 0.79,
      p_w1_H: 0.83,
      p_m1_L: 0.79,
      p_m1_H: 0.86,
      p_m3_L: 0.47,
      p_m3_H: 0.87,
      p_m6_L: 0.47,
      p_m6_H: 0.87,
      p_m12_L: 0.47,
      p_m12_H: 0.87,
    },
    2789: {
      H: 0.048,
      L: 0.046,
      O: 0.048,
      C: 0.048,
      V: 3562000,
      p_w1_L: 0.046,
      p_w1_H: 0.052,
      p_m1_L: 0.046,
      p_m1_H: 0.054,
      p_m3_L: 0.043,
      p_m3_H: 0.063,
      p_m6_L: 0.042,
      p_m6_H: 0.073,
      p_m12_L: 0.042,
      p_m12_H: 0.093,
    },
    2798: {
      H: 1.75,
      L: 1.68,
      O: 1.74,
      C: 1.7,
      V: 3235000,
      p_w1_L: 1.65,
      p_w1_H: 1.91,
      p_m1_L: 1.58,
      p_m1_H: 1.98,
      p_m3_L: 1.5,
      p_m3_H: 2.17,
      p_m6_L: 1.42,
      p_m6_H: 2.7,
      p_m12_L: 1.42,
      p_m12_H: 16.7,
    },
    2799: {
      H: 1.02,
      L: 1.02,
      O: 1.02,
      C: 1.02,
      V: 0,
      p_w1_L: 1.02,
      p_w1_H: 1.02,
      p_m1_L: 1.02,
      p_m1_H: 1.02,
      p_m3_L: 1.02,
      p_m3_H: 1.02,
      p_m6_L: 1.02,
      p_m6_H: 1.02,
      p_m12_L: 0.8,
      p_m12_H: 1.08,
    },
    2800: {
      H: 24.22,
      L: 24.04,
      O: 24.2,
      C: 24.16,
      V: 87442500,
      p_w1_L: 23.46,
      p_w1_H: 24.22,
      p_m1_L: 23.3,
      p_m1_H: 25.9,
      p_m3_L: 23.3,
      p_m3_H: 27.18,
      p_m6_L: 23.3,
      p_m6_H: 29.76,
      p_m12_L: 23.3,
      p_m12_H: 31.34,
    },
    2828: {
      H: 86.94,
      L: 86.14,
      O: 86.5,
      C: 86.7,
      V: 12799300,
      p_w1_L: 83.8,
      p_w1_H: 86.94,
      p_m1_L: 83.8,
      p_m1_H: 93.62,
      p_m3_L: 83.8,
      p_m3_H: 97.8,
      p_m6_L: 83.8,
      p_m6_H: 110.45,
      p_m12_L: 83.8,
      p_m12_H: 124.3,
    },
    2858: {
      H: 1.38,
      L: 1.33,
      O: 1.38,
      C: 1.37,
      V: 1939500,
      p_w1_L: 1.31,
      p_w1_H: 1.42,
      p_m1_L: 1.31,
      p_m1_H: 1.53,
      p_m3_L: 1.31,
      p_m3_H: 1.87,
      p_m6_L: 1.31,
      p_m6_H: 2.47,
      p_m12_L: 1.31,
      p_m12_H: 3.95,
    },
    2863: {
      H: 0.395,
      L: 0.395,
      O: 0.395,
      C: 0.395,
      V: 18000,
      p_w1_L: 0.355,
      p_w1_H: 0.425,
      p_m1_L: 0.345,
      p_m1_H: 0.425,
      p_m3_L: 0.345,
      p_m3_H: 0.49,
      p_m6_L: 0.345,
      p_m6_H: 0.5,
      p_m12_L: 0.28,
      p_m12_H: 0.51,
    },
    2866: {
      H: 1.5,
      L: 1.47,
      O: 1.47,
      C: 1.48,
      V: 3321550,
      p_w1_L: 1.42,
      p_w1_H: 1.52,
      p_m1_L: 1.33,
      p_m1_H: 1.52,
      p_m3_L: 1.33,
      p_m3_H: 1.92,
      p_m6_L: 1.33,
      p_m6_H: 1.94,
      p_m12_L: 1.12,
      p_m12_H: 1.94,
    },
    2869: {
      H: 7.8,
      L: 7.55,
      O: 7.7,
      C: 7.73,
      V: 5398900,
      p_w1_L: 7.11,
      p_w1_H: 7.8,
      p_m1_L: 6.89,
      p_m1_H: 8.19,
      p_m3_L: 6.89,
      p_m3_H: 9.35,
      p_m6_L: 6.89,
      p_m6_H: 13.16,
      p_m12_L: 6.89,
      p_m12_H: 13.76,
    },
    2877: {
      H: 7.02,
      L: 6.92,
      O: 7,
      C: 6.92,
      V: 224000,
      p_w1_L: 6.86,
      p_w1_H: 7.19,
      p_m1_L: 6.86,
      p_m1_H: 7.66,
      p_m3_L: 6.86,
      p_m3_H: 8.86,
      p_m6_L: 6.86,
      p_m6_H: 9.93,
      p_m12_L: 5.15,
      p_m12_H: 9.93,
    },
    2878: {
      H: 0.8,
      L: 0.77,
      O: 0.78,
      C: 0.79,
      V: 5238000,
      p_w1_L: 0.73,
      p_w1_H: 0.83,
      p_m1_L: 0.71,
      p_m1_H: 0.96,
      p_m3_L: 0.63,
      p_m3_H: 0.96,
      p_m6_L: 0.63,
      p_m6_H: 1.45,
      p_m12_L: 0.235,
      p_m12_H: 1.45,
    },
    2880: {
      H: 0.73,
      L: 0.71,
      O: 0.72,
      C: 0.72,
      V: 2397000,
      p_w1_L: 0.69,
      p_w1_H: 0.75,
      p_m1_L: 0.69,
      p_m1_H: 0.75,
      p_m3_L: 0.69,
      p_m3_H: 0.78,
      p_m6_L: 0.69,
      p_m6_H: 0.83,
      p_m12_L: 0.69,
      p_m12_H: 0.93,
    },
    2882: {
      H: 0.285,
      L: 0.285,
      O: 0.285,
      C: 0.285,
      V: 10500,
      p_w1_L: 0.285,
      p_w1_H: 0.29,
      p_m1_L: 0.285,
      p_m1_H: 0.35,
      p_m3_L: 0.255,
      p_m3_H: 0.43,
      p_m6_L: 0.049,
      p_m6_H: 0.57,
      p_m12_L: 0.025,
      p_m12_H: 0.67,
    },
    2883: {
      H: 6.51,
      L: 6.33,
      O: 6.37,
      C: 6.43,
      V: 9200000,
      p_w1_L: 6.13,
      p_w1_H: 6.51,
      p_m1_L: 6.1,
      p_m1_H: 7.2,
      p_m3_L: 6.1,
      p_m3_H: 8.25,
      p_m6_L: 5.47,
      p_m6_H: 8.25,
      p_m12_L: 5.47,
      p_m12_H: 11.44,
    },
    2885: {
      H: 0.445,
      L: 0.42,
      O: 0.445,
      C: 0.445,
      V: 130000,
      p_w1_L: 0.42,
      p_w1_H: 0.495,
      p_m1_L: 0.39,
      p_m1_H: 0.495,
      p_m3_L: 0.36,
      p_m3_H: 0.495,
      p_m6_L: 0.36,
      p_m6_H: 0.495,
      p_m12_L: 0.32,
      p_m12_H: 0.495,
    },
    2886: {
      H: 1.48,
      L: 1.43,
      O: 1.47,
      C: 1.43,
      V: 34000,
      p_w1_L: 1.41,
      p_w1_H: 1.51,
      p_m1_L: 1.41,
      p_m1_H: 1.62,
      p_m3_L: 1.41,
      p_m3_H: 1.81,
      p_m6_L: 1.41,
      p_m6_H: 1.99,
      p_m12_L: 1.14,
      p_m12_H: 1.99,
    },
    2888: {
      H: 45.6,
      L: 44.85,
      O: 45.4,
      C: 45.5,
      V: 699237,
      p_w1_L: 43,
      p_w1_H: 46,
      p_m1_L: 42.8,
      p_m1_H: 49.5,
      p_m3_L: 42.8,
      p_m3_H: 53.2,
      p_m6_L: 42.8,
      p_m6_H: 55.4,
      p_m12_L: 42.8,
      p_m12_H: 56.85,
    },
    2892: {
      H: 1.03,
      L: 1.03,
      O: 1.03,
      C: 1.03,
      V: 0,
      p_w1_L: 1.03,
      p_w1_H: 1.04,
      p_m1_L: 0.96,
      p_m1_H: 1.08,
      p_m3_L: 0.96,
      p_m3_H: 1.08,
      p_m6_L: 0.96,
      p_m6_H: 1.18,
      p_m12_L: 0.96,
      p_m12_H: 1.3,
    },
    2899: {
      H: 10.34,
      L: 10.1,
      O: 10.26,
      C: 10.2,
      V: 30072300,
      p_w1_L: 9.82,
      p_w1_H: 10.34,
      p_m1_L: 9.82,
      p_m1_H: 11.12,
      p_m3_L: 8.9,
      p_m3_H: 12.6,
      p_m6_L: 8.9,
      p_m6_H: 12.76,
      p_m12_L: 6.94,
      p_m12_H: 16.24,
    },
    3300: {
      H: 2.12,
      L: 2.01,
      O: 2.03,
      C: 2.04,
      V: 36818000,
      p_w1_L: 1.75,
      p_w1_H: 2.12,
      p_m1_L: 1.75,
      p_m1_H: 2.42,
      p_m3_L: 1.75,
      p_m3_H: 3.75,
      p_m6_L: 1.35,
      p_m6_H: 4.5,
      p_m12_L: 0.58,
      p_m12_H: 4.5,
    },
    3301: {
      H: 3.11,
      L: 3.02,
      O: 3.08,
      C: 3.05,
      V: 1963000,
      p_w1_L: 2.83,
      p_w1_H: 3.11,
      p_m1_L: 2.83,
      p_m1_H: 3.34,
      p_m3_L: 2.83,
      p_m3_H: 4.32,
      p_m6_L: 2.83,
      p_m6_H: 4.95,
      p_m12_L: 2.83,
      p_m12_H: 6.05,
    },
    3302: {
      H: 0.6,
      L: 0.58,
      O: 0.59,
      C: 0.58,
      V: 102000,
      p_w1_L: 0.55,
      p_w1_H: 0.63,
      p_m1_L: 0.55,
      p_m1_H: 0.75,
      p_m3_L: 0.49,
      p_m3_H: 0.77,
      p_m6_L: 0.49,
      p_m6_H: 0.8,
      p_m12_L: 0.49,
      p_m12_H: 0.9,
    },
    3303: {
      H: 0.89,
      L: 0.87,
      O: 0.87,
      C: 0.88,
      V: 464000,
      p_w1_L: 0.87,
      p_w1_H: 0.9,
      p_m1_L: 0.86,
      p_m1_H: 1.02,
      p_m3_L: 0.86,
      p_m3_H: 1.24,
      p_m6_L: 0.86,
      p_m6_H: 1.75,
      p_m12_L: 0.48,
      p_m12_H: 1.75,
    },
    3306: {
      H: 12.94,
      L: 12.52,
      O: 12.54,
      C: 12.9,
      V: 146500,
      p_w1_L: 12.52,
      p_w1_H: 13.96,
      p_m1_L: 12.44,
      p_m1_H: 14.5,
      p_m3_L: 12.12,
      p_m3_H: 19.28,
      p_m6_L: 12.12,
      p_m6_H: 21.5,
      p_m12_L: 8.01,
      p_m12_H: 21.5,
    },
    3308: {
      H: 6.61,
      L: 6.58,
      O: 6.61,
      C: 6.6,
      V: 94000,
      p_w1_L: 6.57,
      p_w1_H: 7.06,
      p_m1_L: 6.57,
      p_m1_H: 7.06,
      p_m3_L: 6.57,
      p_m3_H: 7.06,
      p_m6_L: 6.57,
      p_m6_H: 7.06,
      p_m12_L: 6.57,
      p_m12_H: 7.22,
    },
    3309: {
      H: 7.01,
      L: 6.9,
      O: 6.96,
      C: 6.96,
      V: 1020000,
      p_w1_L: 6.52,
      p_w1_H: 7.01,
      p_m1_L: 6.52,
      p_m1_H: 7.93,
      p_m3_L: 6.52,
      p_m3_H: 9.15,
      p_m6_L: 6.39,
      p_m6_H: 12.06,
      p_m12_L: 5.12,
      p_m12_H: 12.06,
    },
    3311: {
      H: 8.79,
      L: 8.48,
      O: 8.65,
      C: 8.79,
      V: 7235860,
      p_w1_L: 7.77,
      p_w1_H: 8.95,
      p_m1_L: 7.21,
      p_m1_H: 8.95,
      p_m3_L: 6.26,
      p_m3_H: 8.95,
      p_m6_L: 4.72,
      p_m6_H: 8.95,
      p_m12_L: 4.11,
      p_m12_H: 8.95,
    },
    3313: {
      H: 0.044,
      L: 0.042,
      O: 0.042,
      C: 0.042,
      V: 1970000,
      p_w1_L: 0.04,
      p_w1_H: 0.044,
      p_m1_L: 0.04,
      p_m1_H: 0.045,
      p_m3_L: 0.04,
      p_m3_H: 0.055,
      p_m6_L: 0.04,
      p_m6_H: 0.073,
      p_m12_L: 0.04,
      p_m12_H: 0.085,
    },
    3315: {
      H: 1.72,
      L: 1.67,
      O: 1.67,
      C: 1.69,
      V: 376000,
      p_w1_L: 1.62,
      p_w1_H: 1.73,
      p_m1_L: 1.62,
      p_m1_H: 1.82,
      p_m3_L: 1.54,
      p_m3_H: 1.85,
      p_m6_L: 1.5,
      p_m6_H: 1.86,
      p_m12_L: 1.38,
      p_m12_H: 2.66,
    },
    3316: {
      H: 23.55,
      L: 22.65,
      O: 23.05,
      C: 22.65,
      V: 66214,
      p_w1_L: 20.85,
      p_w1_H: 23.55,
      p_m1_L: 19.6,
      p_m1_H: 23.55,
      p_m3_L: 17.42,
      p_m3_H: 26,
      p_m6_L: 17.42,
      p_m6_H: 37.3,
      p_m12_L: 13.22,
      p_m12_H: 37.3,
    },
    3318: {
      H: 4.68,
      L: 4.44,
      O: 4.58,
      C: 4.55,
      V: 2012000,
      p_w1_L: 4.3,
      p_w1_H: 5.25,
      p_m1_L: 2.45,
      p_m1_H: 5.7,
      p_m3_L: 2.45,
      p_m3_H: 5.7,
      p_m6_L: 2.45,
      p_m6_H: 5.7,
      p_m12_L: 1.6,
      p_m12_H: 13.4,
    },
    3319: {
      H: 18.6,
      L: 17.84,
      O: 18.5,
      C: 17.94,
      V: 17583800,
      p_w1_L: 17.06,
      p_w1_H: 18.98,
      p_m1_L: 17.06,
      p_m1_H: 24.5,
      p_m3_L: 17.06,
      p_m3_H: 31.45,
      p_m6_L: 17.06,
      p_m6_H: 39.55,
      p_m12_L: 17.06,
      p_m12_H: 40.25,
    },
    3320: {
      H: 3.47,
      L: 3.41,
      O: 3.43,
      C: 3.41,
      V: 7668500,
      p_w1_L: 3.33,
      p_w1_H: 3.69,
      p_m1_L: 3.28,
      p_m1_H: 3.79,
      p_m3_L: 3.28,
      p_m3_H: 4.2,
      p_m6_L: 3.28,
      p_m6_H: 5.49,
      p_m12_L: 3.28,
      p_m12_H: 5.89,
    },
    3321: {
      H: 2.1,
      L: 2.09,
      O: 2.1,
      C: 2.09,
      V: 7500,
      p_w1_L: 1.8,
      p_w1_H: 2.36,
      p_m1_L: 1.8,
      p_m1_H: 3.1,
      p_m3_L: 1.8,
      p_m3_H: 3.61,
      p_m6_L: 1.8,
      p_m6_H: 3.98,
      p_m12_L: 1.8,
      p_m12_H: 7.99,
    },
    3322: {
      H: 0.33,
      L: 0.315,
      O: 0.315,
      C: 0.33,
      V: 40000,
      p_w1_L: 0.315,
      p_w1_H: 0.34,
      p_m1_L: 0.305,
      p_m1_H: 0.345,
      p_m3_L: 0.305,
      p_m3_H: 0.35,
      p_m6_L: 0.29,
      p_m6_H: 0.37,
      p_m12_L: 0.29,
      p_m12_H: 0.405,
    },
    3323: {
      H: 9.33,
      L: 9.1,
      O: 9.28,
      C: 9.28,
      V: 23266600,
      p_w1_L: 8.4,
      p_w1_H: 9.33,
      p_m1_L: 8.4,
      p_m1_H: 9.53,
      p_m3_L: 8.4,
      p_m3_H: 13.58,
      p_m6_L: 8.21,
      p_m6_H: 13.58,
      p_m12_L: 8.21,
      p_m12_H: 13.58,
    },
    3326: {
      H: 0.275,
      L: 0.275,
      O: 0.275,
      C: 0.275,
      V: 0,
      p_w1_L: 0.275,
      p_w1_H: 0.31,
      p_m1_L: 0.275,
      p_m1_H: 0.32,
      p_m3_L: 0.25,
      p_m3_H: 0.32,
      p_m6_L: 0.25,
      p_m6_H: 0.4,
      p_m12_L: 0.205,
      p_m12_H: 0.45,
    },
    3328: {
      H: 4.72,
      L: 4.63,
      O: 4.71,
      C: 4.65,
      V: 24581500,
      p_w1_L: 4.57,
      p_w1_H: 4.79,
      p_m1_L: 4.53,
      p_m1_H: 4.88,
      p_m3_L: 4.35,
      p_m3_H: 4.88,
      p_m6_L: 4.35,
      p_m6_H: 5.26,
      p_m12_L: 4,
      p_m12_H: 5.26,
    },
    3329: {
      H: 1.66,
      L: 1.65,
      O: 1.65,
      C: 1.65,
      V: 182000,
      p_w1_L: 1.65,
      p_w1_H: 1.74,
      p_m1_L: 1.64,
      p_m1_H: 1.74,
      p_m3_L: 1.63,
      p_m3_H: 1.83,
      p_m6_L: 1.62,
      p_m6_H: 1.96,
      p_m12_L: 0.99,
      p_m12_H: 1.97,
    },
    3330: {
      H: 0.99,
      L: 0.97,
      O: 0.99,
      C: 0.97,
      V: 342000,
      p_w1_L: 0.9,
      p_w1_H: 0.99,
      p_m1_L: 0.9,
      p_m1_H: 1.11,
      p_m3_L: 0.9,
      p_m3_H: 1.11,
      p_m6_L: 0.9,
      p_m6_H: 1.16,
      p_m12_L: 0.9,
      p_m12_H: 1.49,
    },
    3331: {
      H: 21,
      L: 20.05,
      O: 21,
      C: 20.6,
      V: 1245800,
      p_w1_L: 20.05,
      p_w1_H: 21.75,
      p_m1_L: 20.05,
      p_m1_H: 22.2,
      p_m3_L: 20.05,
      p_m3_H: 24.7,
      p_m6_L: 19.8,
      p_m6_H: 27,
      p_m12_L: 19.8,
      p_m12_H: 29.35,
    },
    3332: {
      H: 0.159,
      L: 0.159,
      O: 0.159,
      C: 0.159,
      V: 0,
      p_w1_L: 0.159,
      p_w1_H: 0.16,
      p_m1_L: 0.133,
      p_m1_H: 0.16,
      p_m3_L: 0.133,
      p_m3_H: 0.17,
      p_m6_L: 0.133,
      p_m6_H: 0.192,
      p_m12_L: 0.121,
      p_m12_H: 0.244,
    },
    3333: {
      H: 1.86,
      L: 1.72,
      O: 1.85,
      C: 1.73,
      V: 200350000,
      p_w1_L: 1.72,
      p_w1_H: 2.34,
      p_m1_L: 1.72,
      p_m1_H: 3.06,
      p_m3_L: 1.72,
      p_m3_H: 3.75,
      p_m6_L: 1.72,
      p_m6_H: 12.28,
      p_m12_L: 1.72,
      p_m12_H: 17.58,
    },
    3336: {
      H: 1.28,
      L: 1.21,
      O: 1.21,
      C: 1.23,
      V: 244000,
      p_w1_L: 1.18,
      p_w1_H: 1.29,
      p_m1_L: 1.18,
      p_m1_H: 1.39,
      p_m3_L: 1.18,
      p_m3_H: 1.64,
      p_m6_L: 1.18,
      p_m6_H: 1.8,
      p_m12_L: 1.18,
      p_m12_H: 2.74,
    },
    3337: {
      H: 0.445,
      L: 0.44,
      O: 0.445,
      C: 0.445,
      V: 618000,
      p_w1_L: 0.415,
      p_w1_H: 0.445,
      p_m1_L: 0.415,
      p_m1_H: 0.5,
      p_m3_L: 0.415,
      p_m3_H: 0.58,
      p_m6_L: 0.405,
      p_m6_H: 0.58,
      p_m12_L: 0.405,
      p_m12_H: 0.7,
    },
    3339: {
      H: 2.13,
      L: 2.08,
      O: 2.1,
      C: 2.12,
      V: 3214000,
      p_w1_L: 1.94,
      p_w1_H: 2.14,
      p_m1_L: 1.94,
      p_m1_H: 2.24,
      p_m3_L: 1.94,
      p_m3_H: 2.6,
      p_m6_L: 1.94,
      p_m6_H: 2.8,
      p_m12_L: 1.94,
      p_m12_H: 3.58,
    },
    3344: {
      H: 0.017,
      L: 0.017,
      O: 0.017,
      C: 0.017,
      V: 0,
      p_w1_L: 0.017,
      p_w1_H: 0.017,
      p_m1_L: 0.017,
      p_m1_H: 0.017,
      p_m3_L: 0.015,
      p_m3_H: 0.019,
      p_m6_L: 0.015,
      p_m6_H: 0.036,
      p_m12_L: 0.015,
      p_m12_H: 0.066,
    },
    3347: {
      H: 122.7,
      L: 118,
      O: 120.6,
      C: 120.7,
      V: 948829,
      p_w1_L: 112.7,
      p_w1_H: 128,
      p_m1_L: 112.7,
      p_m1_H: 145.3,
      p_m3_L: 112.7,
      p_m3_H: 177,
      p_m6_L: 112.7,
      p_m6_H: 193.8,
      p_m12_L: 112.7,
      p_m12_H: 199.9,
    },
    3348: {
      H: 1.05,
      L: 0.97,
      O: 1.05,
      C: 1.02,
      V: 618000,
      p_w1_L: 0.97,
      p_w1_H: 1.08,
      p_m1_L: 0.97,
      p_m1_H: 1.1,
      p_m3_L: 0.97,
      p_m3_H: 1.1,
      p_m6_L: 0.96,
      p_m6_H: 1.19,
      p_m12_L: 0.76,
      p_m12_H: 1.42,
    },
    3360: {
      H: 6.96,
      L: 6.77,
      O: 6.96,
      C: 6.78,
      V: 4360160,
      p_w1_L: 6.77,
      p_w1_H: 7.14,
      p_m1_L: 6.74,
      p_m1_H: 7.5,
      p_m3_L: 6.74,
      p_m3_H: 9.03,
      p_m6_L: 6.74,
      p_m6_H: 9.13,
      p_m12_L: 6.74,
      p_m12_H: 9.58,
    },
    3363: {
      H: 0.72,
      L: 0.72,
      O: 0.72,
      C: 0.72,
      V: 0,
      p_w1_L: 0.72,
      p_w1_H: 0.73,
      p_m1_L: 0.68,
      p_m1_H: 0.76,
      p_m3_L: 0.66,
      p_m3_H: 0.84,
      p_m6_L: 0.63,
      p_m6_H: 0.99,
      p_m12_L: 0.52,
      p_m12_H: 0.99,
    },
    3366: {
      H: 1.3,
      L: 1.26,
      O: 1.26,
      C: 1.29,
      V: 32000,
      p_w1_L: 1.26,
      p_w1_H: 1.33,
      p_m1_L: 1.26,
      p_m1_H: 1.4,
      p_m3_L: 1.26,
      p_m3_H: 1.51,
      p_m6_L: 1.26,
      p_m6_H: 1.64,
      p_m12_L: 1.26,
      p_m12_H: 1.93,
    },
    3368: {
      H: 0.255,
      L: 0.255,
      O: 0.255,
      C: 0.255,
      V: 0,
      p_w1_L: 0.23,
      p_w1_H: 0.255,
      p_m1_L: 0.23,
      p_m1_H: 0.265,
      p_m3_L: 0.23,
      p_m3_H: 0.29,
      p_m6_L: 0.23,
      p_m6_H: 0.35,
      p_m12_L: 0.225,
      p_m12_H: 0.37,
    },
    3369: {
      H: 1.25,
      L: 1.23,
      O: 1.25,
      C: 1.24,
      V: 375500,
      p_w1_L: 1.21,
      p_w1_H: 1.3,
      p_m1_L: 1.21,
      p_m1_H: 1.3,
      p_m3_L: 1.21,
      p_m3_H: 1.41,
      p_m6_L: 1.19,
      p_m6_H: 1.41,
      p_m12_L: 1.19,
      p_m12_H: 1.6,
    },
    3377: {
      H: 1.94,
      L: 1.89,
      O: 1.94,
      C: 1.9,
      V: 5046140,
      p_w1_L: 1.8,
      p_w1_H: 1.99,
      p_m1_L: 1.66,
      p_m1_H: 1.99,
      p_m3_L: 1.31,
      p_m3_H: 1.99,
      p_m6_L: 1.31,
      p_m6_H: 1.99,
      p_m12_L: 1.31,
      p_m12_H: 1.99,
    },
    3378: {
      H: 0.83,
      L: 0.81,
      O: 0.83,
      C: 0.83,
      V: 436000,
      p_w1_L: 0.79,
      p_w1_H: 0.9,
      p_m1_L: 0.77,
      p_m1_H: 0.9,
      p_m3_L: 0.77,
      p_m3_H: 0.98,
      p_m6_L: 0.77,
      p_m6_H: 1.21,
      p_m12_L: 0.77,
      p_m12_H: 1.47,
    },
    3380: {
      H: 7.06,
      L: 6.84,
      O: 6.95,
      C: 6.88,
      V: 19613800,
      p_w1_L: 6.72,
      p_w1_H: 7.89,
      p_m1_L: 6.72,
      p_m1_H: 8.54,
      p_m3_L: 6.46,
      p_m3_H: 9.18,
      p_m6_L: 6.46,
      p_m6_H: 12.74,
      p_m12_L: 6.46,
      p_m12_H: 13.62,
    },
    3382: {
      H: 0.65,
      L: 0.64,
      O: 0.65,
      C: 0.64,
      V: 1306000,
      p_w1_L: 0.6,
      p_w1_H: 0.67,
      p_m1_L: 0.6,
      p_m1_H: 0.67,
      p_m3_L: 0.6,
      p_m3_H: 0.73,
      p_m6_L: 0.56,
      p_m6_H: 0.73,
      p_m12_L: 0.56,
      p_m12_H: 0.76,
    },
    3383: {
      H: 5.38,
      L: 5.12,
      O: 5.23,
      C: 5.14,
      V: 7044000,
      p_w1_L: 4.9,
      p_w1_H: 5.38,
      p_m1_L: 4.9,
      p_m1_H: 6.19,
      p_m3_L: 4.9,
      p_m3_H: 7.87,
      p_m6_L: 4.9,
      p_m6_H: 11.02,
      p_m12_L: 4.9,
      p_m12_H: 13.22,
    },
    3389: {
      H: 0.285,
      L: 0.28,
      O: 0.28,
      C: 0.28,
      V: 275400,
      p_w1_L: 0.28,
      p_w1_H: 0.285,
      p_m1_L: 0.27,
      p_m1_H: 0.31,
      p_m3_L: 0.27,
      p_m3_H: 0.35,
      p_m6_L: 0.27,
      p_m6_H: 0.35,
      p_m12_L: 0.265,
      p_m12_H: 0.41,
    },
    3390: {
      H: 1.31,
      L: 1.17,
      O: 1.29,
      C: 1.17,
      V: 1756000,
      p_w1_L: 1.17,
      p_w1_H: 1.39,
      p_m1_L: 1.17,
      p_m1_H: 1.46,
      p_m3_L: 1.17,
      p_m3_H: 1.51,
      p_m6_L: 1.17,
      p_m6_H: 1.81,
      p_m12_L: 1.17,
      p_m12_H: 1.86,
    },
    3393: {
      H: 2.98,
      L: 2.81,
      O: 2.85,
      C: 2.95,
      V: 2454000,
      p_w1_L: 2.77,
      p_w1_H: 3.09,
      p_m1_L: 2.57,
      p_m1_H: 3.18,
      p_m3_L: 2.48,
      p_m3_H: 3.18,
      p_m6_L: 2.13,
      p_m6_H: 3.21,
      p_m12_L: 2.1,
      p_m12_H: 3.21,
    },
    3395: {
      H: 0.52,
      L: 0.48,
      O: 0.51,
      C: 0.48,
      V: 139000,
      p_w1_L: 0.41,
      p_w1_H: 0.52,
      p_m1_L: 0.41,
      p_m1_H: 0.58,
      p_m3_L: 0.41,
      p_m3_H: 0.9,
      p_m6_L: 0.33,
      p_m6_H: 1.27,
      p_m12_L: 0.27,
      p_m12_H: 1.27,
    },
    3396: {
      H: 12.5,
      L: 12.06,
      O: 12.18,
      C: 12.1,
      V: 1037400,
      p_w1_L: 11.82,
      p_w1_H: 12.54,
      p_m1_L: 11.82,
      p_m1_H: 13.58,
      p_m3_L: 11.82,
      p_m3_H: 17.86,
      p_m6_L: 10.76,
      p_m6_H: 18.12,
      p_m12_L: 9.81,
      p_m12_H: 18.12,
    },
    3398: {
      H: 0.35,
      L: 0.35,
      O: 0.35,
      C: 0.35,
      V: 2000,
      p_w1_L: 0.31,
      p_w1_H: 0.35,
      p_m1_L: 0.295,
      p_m1_H: 0.35,
      p_m3_L: 0.295,
      p_m3_H: 0.35,
      p_m6_L: 0.295,
      p_m6_H: 0.35,
      p_m12_L: 0.295,
      p_m12_H: 0.445,
    },
    3399: {
      H: 1.1,
      L: 1.1,
      O: 1.1,
      C: 1.1,
      V: 29000,
      p_w1_L: 1.01,
      p_w1_H: 1.16,
      p_m1_L: 1.01,
      p_m1_H: 1.16,
      p_m3_L: 1.01,
      p_m3_H: 1.45,
      p_m6_L: 1.01,
      p_m6_H: 1.6,
      p_m12_L: 1.01,
      p_m12_H: 1.79,
    },
    3600: {
      H: 6.3,
      L: 5.97,
      O: 5.97,
      C: 6.27,
      V: 1429000,
      p_w1_L: 5.7,
      p_w1_H: 6.86,
      p_m1_L: 5.3,
      p_m1_H: 6.86,
      p_m3_L: 5.3,
      p_m3_H: 8.42,
      p_m6_L: 5.3,
      p_m6_H: 11,
      p_m12_L: 1.26,
      p_m12_H: 11,
    },
    3601: {
      H: 2.21,
      L: 1.95,
      O: 1.96,
      C: 2.12,
      V: 2089000,
      p_w1_L: 1.86,
      p_w1_H: 2.4,
      p_m1_L: 1.59,
      p_m1_H: 2.4,
      p_m3_L: 1.55,
      p_m3_H: 2.4,
      p_m6_L: 1.55,
      p_m6_H: 3.02,
      p_m12_L: 1.55,
      p_m12_H: 6.27,
    },
    3603: {
      H: 0.72,
      L: 0.66,
      O: 0.68,
      C: 0.72,
      V: 6957000,
      p_w1_L: 0.64,
      p_w1_H: 0.77,
      p_m1_L: 0.64,
      p_m1_H: 1.15,
      p_m3_L: 0.64,
      p_m3_H: 1.2,
      p_m6_L: 0.64,
      p_m6_H: 1.32,
      p_m12_L: 0.64,
      p_m12_H: 2.1,
    },
    3606: {
      H: 42.2,
      L: 41.6,
      O: 41.8,
      C: 42,
      V: 1600280,
      p_w1_L: 41.15,
      p_w1_H: 43.7,
      p_m1_L: 41.15,
      p_m1_H: 46.95,
      p_m3_L: 36.85,
      p_m3_H: 48.4,
      p_m6_L: 36.85,
      p_m6_H: 58.45,
      p_m12_L: 34,
      p_m12_H: 68.9,
    },
    3608: {
      H: 0.9,
      L: 0.9,
      O: 0.9,
      C: 0.9,
      V: 0,
      p_w1_L: 0.85,
      p_w1_H: 0.92,
      p_m1_L: 0.85,
      p_m1_H: 1.11,
      p_m3_L: 0.85,
      p_m3_H: 1.32,
      p_m6_L: 0.85,
      p_m6_H: 1.65,
      p_m12_L: 0.85,
      p_m12_H: 2.14,
    },
    3613: {
      H: 11,
      L: 10.64,
      O: 11,
      C: 10.72,
      V: 953000,
      p_w1_L: 10.2,
      p_w1_H: 11.2,
      p_m1_L: 9.8,
      p_m1_H: 11.26,
      p_m3_L: 9.33,
      p_m3_H: 11.26,
      p_m6_L: 9.33,
      p_m6_H: 13.8,
      p_m12_L: 7.93,
      p_m12_H: 14.2,
    },
    3616: {
      H: 1,
      L: 0.95,
      O: 0.99,
      C: 1,
      V: 298000,
      p_w1_L: 0.95,
      p_w1_H: 1.06,
      p_m1_L: 0.95,
      p_m1_H: 1.15,
      p_m3_L: 0.95,
      p_m3_H: 1.58,
      p_m6_L: 0.95,
      p_m6_H: 1.73,
      p_m12_L: 0.95,
      p_m12_H: 1.73,
    },
    3618: {
      H: 2.75,
      L: 2.72,
      O: 2.73,
      C: 2.74,
      V: 14746600,
      p_w1_L: 2.64,
      p_w1_H: 2.76,
      p_m1_L: 2.59,
      p_m1_H: 2.81,
      p_m3_L: 2.59,
      p_m3_H: 3.06,
      p_m6_L: 2.59,
      p_m6_H: 3.28,
      p_m12_L: 2.59,
      p_m12_H: 3.7,
    },
    3623: {
      H: 0.85,
      L: 0.82,
      O: 0.82,
      C: 0.85,
      V: 50000,
      p_w1_L: 0.81,
      p_w1_H: 0.85,
      p_m1_L: 0.76,
      p_m1_H: 0.85,
      p_m3_L: 0.71,
      p_m3_H: 0.87,
      p_m6_L: 0.56,
      p_m6_H: 1.09,
      p_m12_L: 0.56,
      p_m12_H: 1.11,
    },
    3626: {
      H: 0.77,
      L: 0.77,
      O: 0.77,
      C: 0.77,
      V: 0,
      p_w1_L: 0.77,
      p_w1_H: 0.8,
      p_m1_L: 0.61,
      p_m1_H: 0.84,
      p_m3_L: 0.59,
      p_m3_H: 1.02,
      p_m6_L: 0.39,
      p_m6_H: 1.02,
      p_m12_L: 0.29,
      p_m12_H: 1.02,
    },
    3628: {
      H: 0.244,
      L: 0.244,
      O: 0.244,
      C: 0.244,
      V: 1880000,
      p_w1_L: 0.244,
      p_w1_H: 0.245,
      p_m1_L: 0.2,
      p_m1_H: 0.365,
      p_m3_L: 0.138,
      p_m3_H: 0.365,
      p_m6_L: 0.115,
      p_m6_H: 0.365,
      p_m12_L: 0.082,
      p_m12_H: 0.365,
    },
    3633: {
      H: 7.59,
      L: 7.42,
      O: 7.58,
      C: 7.49,
      V: 995000,
      p_w1_L: 7.3,
      p_w1_H: 7.78,
      p_m1_L: 6.72,
      p_m1_H: 7.85,
      p_m3_L: 6.18,
      p_m3_H: 7.85,
      p_m6_L: 6.18,
      p_m6_H: 7.85,
      p_m12_L: 6.18,
      p_m12_H: 7.85,
    },
    3636: {
      H: 4.98,
      L: 4.22,
      O: 4.7,
      C: 4.42,
      V: 347000,
      p_w1_L: 4.22,
      p_w1_H: 4.98,
      p_m1_L: 4.22,
      p_m1_H: 5.09,
      p_m3_L: 4.22,
      p_m3_H: 6.02,
      p_m6_L: 3.8,
      p_m6_H: 7.14,
      p_m12_L: 3.79,
      p_m12_H: 7.14,
    },
    3638: {
      H: 0.113,
      L: 0.113,
      O: 0.113,
      C: 0.113,
      V: 0,
      p_w1_L: 0.09,
      p_w1_H: 0.113,
      p_m1_L: 0.09,
      p_m1_H: 0.124,
      p_m3_L: 0.08,
      p_m3_H: 0.199,
      p_m6_L: 0.08,
      p_m6_H: 0.199,
      p_m12_L: 0.08,
      p_m12_H: 0.3,
    },
    3639: {
      H: 0.93,
      L: 0.93,
      O: 0.93,
      C: 0.93,
      V: 0,
      p_w1_L: 0.93,
      p_w1_H: 0.93,
      p_m1_L: 0.93,
      p_m1_H: 1.1,
      p_m3_L: 0.93,
      p_m3_H: 1.1,
      p_m6_L: 0.93,
      p_m6_H: 2.18,
      p_m12_L: 0.93,
      p_m12_H: 2.18,
    },
    3658: {
      H: 2,
      L: 2,
      O: 2,
      C: 2,
      V: 0,
      p_w1_L: 1.96,
      p_w1_H: 2.07,
      p_m1_L: 1.96,
      p_m1_H: 2.13,
      p_m3_L: 1.96,
      p_m3_H: 2.21,
      p_m6_L: 1.82,
      p_m6_H: 3.97,
      p_m12_L: 1.82,
      p_m12_H: 4.2,
    },
    3662: {
      H: 3.11,
      L: 2.95,
      O: 3.11,
      C: 2.98,
      V: 1206130,
      p_w1_L: 2.88,
      p_w1_H: 3.19,
      p_m1_L: 2.75,
      p_m1_H: 3.35,
      p_m3_L: 2.74,
      p_m3_H: 4.46,
      p_m6_L: 2.74,
      p_m6_H: 7.26,
      p_m12_L: 2.74,
      p_m12_H: 7.26,
    },
    3666: {
      H: 0.066,
      L: 0.059,
      O: 0.064,
      C: 0.06,
      V: 432000,
      p_w1_L: 0.052,
      p_w1_H: 0.066,
      p_m1_L: 0.051,
      p_m1_H: 0.074,
      p_m3_L: 0.051,
      p_m3_H: 0.079,
      p_m6_L: 0.051,
      p_m6_H: 0.079,
      p_m12_L: 0.051,
      p_m12_H: 0.102,
    },
    3668: {
      H: 16.9,
      L: 16.9,
      O: 16.9,
      C: 16.9,
      V: 5000,
      p_w1_L: 16.64,
      p_w1_H: 17.48,
      p_m1_L: 15.9,
      p_m1_H: 18.8,
      p_m3_L: 12.2,
      p_m3_H: 23,
      p_m6_L: 11.02,
      p_m6_H: 23,
      p_m12_L: 10.8,
      p_m12_H: 23,
    },
    3669: {
      H: 11.3,
      L: 11.04,
      O: 11.12,
      C: 11.26,
      V: 1811500,
      p_w1_L: 10.9,
      p_w1_H: 12.12,
      p_m1_L: 10.9,
      p_m1_H: 12.88,
      p_m3_L: 10.32,
      p_m3_H: 13.58,
      p_m6_L: 10.32,
      p_m6_H: 17.14,
      p_m12_L: 10,
      p_m12_H: 17.14,
    },
    3678: {
      H: 1.07,
      L: 1.07,
      O: 1.07,
      C: 1.07,
      V: 0,
      p_w1_L: 1.07,
      p_w1_H: 1.07,
      p_m1_L: 0.98,
      p_m1_H: 1.15,
      p_m3_L: 0.85,
      p_m3_H: 1.15,
      p_m6_L: 0.72,
      p_m6_H: 1.15,
      p_m12_L: 0.68,
      p_m12_H: 1.15,
    },
    3680: {
      H: 5.6,
      L: 5.05,
      O: 5.11,
      C: 5.17,
      V: 1146000,
      p_w1_L: 5,
      p_w1_H: 6.7,
      p_m1_L: 5,
      p_m1_H: 6.72,
      p_m3_L: 5,
      p_m3_H: 6.72,
      p_m6_L: 5,
      p_m6_H: 6.72,
      p_m12_L: 4.98,
      p_m12_H: 10.16,
    },
    3681: {
      H: 2.9,
      L: 2.74,
      O: 2.85,
      C: 2.9,
      V: 32100,
      p_w1_L: 2.74,
      p_w1_H: 3.01,
      p_m1_L: 2.7,
      p_m1_H: 3.25,
      p_m3_L: 2.7,
      p_m3_H: 3.26,
      p_m6_L: 2.7,
      p_m6_H: 3.7,
      p_m12_L: 2.7,
      p_m12_H: 5,
    },
    3683: {
      H: 1.48,
      L: 1.46,
      O: 1.48,
      C: 1.48,
      V: 462500,
      p_w1_L: 1.46,
      p_w1_H: 1.49,
      p_m1_L: 1.33,
      p_m1_H: 1.51,
      p_m3_L: 1.2,
      p_m3_H: 1.51,
      p_m6_L: 0.89,
      p_m6_H: 1.51,
      p_m12_L: 0.8,
      p_m12_H: 1.51,
    },
    3686: {
      H: 0.5,
      L: 0.5,
      O: 0.5,
      C: 0.5,
      V: 190000,
      p_w1_L: 0.5,
      p_w1_H: 0.53,
      p_m1_L: 0.5,
      p_m1_H: 0.54,
      p_m3_L: 0.5,
      p_m3_H: 0.6,
      p_m6_L: 0.5,
      p_m6_H: 0.67,
      p_m12_L: 0.48,
      p_m12_H: 0.67,
    },
    3688: {
      H: 1.1,
      L: 1.1,
      O: 1.1,
      C: 1.1,
      V: 0,
      p_w1_L: 1.02,
      p_w1_H: 1.17,
      p_m1_L: 1.02,
      p_m1_H: 1.19,
      p_m3_L: 1.02,
      p_m3_H: 1.31,
      p_m6_L: 1.02,
      p_m6_H: 1.48,
      p_m12_L: 1.02,
      p_m12_H: 1.55,
    },
    3689: {
      H: 2.77,
      L: 2.77,
      O: 2.77,
      C: 2.77,
      V: 0,
      p_w1_L: 2.4,
      p_w1_H: 2.8,
      p_m1_L: 2.4,
      p_m1_H: 2.9,
      p_m3_L: 2.36,
      p_m3_H: 3.2,
      p_m6_L: 2.36,
      p_m6_H: 6.6,
      p_m12_L: 2.36,
      p_m12_H: 6.6,
    },
    3690: {
      H: 253.4,
      L: 245,
      O: 250,
      C: 245.2,
      V: 17961900,
      p_w1_L: 231,
      p_w1_H: 253.4,
      p_m1_L: 230.2,
      p_m1_H: 297.6,
      p_m3_L: 224.8,
      p_m3_H: 298,
      p_m6_L: 183.2,
      p_m6_H: 338,
      p_m12_L: 183.2,
      p_m12_H: 460,
    },
    3692: {
      H: 17.26,
      L: 16.88,
      O: 16.92,
      C: 17,
      V: 2685110,
      p_w1_L: 15.56,
      p_w1_H: 17.26,
      p_m1_L: 15.56,
      p_m1_H: 18.8,
      p_m3_L: 15.56,
      p_m3_H: 22.2,
      p_m6_L: 15.56,
      p_m6_H: 35.55,
      p_m12_L: 15.56,
      p_m12_H: 46.7,
    },
    3698: {
      H: 2.6,
      L: 2.55,
      O: 2.55,
      C: 2.6,
      V: 12100,
      p_w1_L: 2.55,
      p_w1_H: 2.64,
      p_m1_L: 2.52,
      p_m1_H: 2.67,
      p_m3_L: 2.5,
      p_m3_H: 2.69,
      p_m6_L: 2.5,
      p_m6_H: 2.69,
      p_m12_L: 2.49,
      p_m12_H: 2.78,
    },
    3699: {
      H: 0.51,
      L: 0.51,
      O: 0.51,
      C: 0.51,
      V: 0,
      p_w1_L: 0.5,
      p_w1_H: 0.51,
      p_m1_L: 0.5,
      p_m1_H: 0.55,
      p_m3_L: 0.5,
      p_m3_H: 0.58,
      p_m6_L: 0.5,
      p_m6_H: 0.71,
      p_m12_L: 0.45,
      p_m12_H: 0.97,
    },
    3700: {
      H: 1.5,
      L: 1.43,
      O: 1.48,
      C: 1.44,
      V: 1475000,
      p_w1_L: 1.38,
      p_w1_H: 1.92,
      p_m1_L: 1.21,
      p_m1_H: 1.92,
      p_m3_L: 1.21,
      p_m3_H: 2,
      p_m6_L: 1.21,
      p_m6_H: 2.27,
      p_m12_L: 0.98,
      p_m12_H: 3.08,
    },
    3708: {
      H: 0.129,
      L: 0.129,
      O: 0.129,
      C: 0.129,
      V: 0,
      p_w1_L: 0.129,
      p_w1_H: 0.129,
      p_m1_L: 0.129,
      p_m1_H: 0.129,
      p_m3_L: 0.1,
      p_m3_H: 0.133,
      p_m6_L: 0.081,
      p_m6_H: 0.175,
      p_m12_L: 0.081,
      p_m12_H: 0.21,
    },
    3709: {
      H: 10.96,
      L: 10.64,
      O: 10.64,
      C: 10.84,
      V: 937000,
      p_w1_L: 10.38,
      p_w1_H: 11.08,
      p_m1_L: 10,
      p_m1_H: 11.28,
      p_m3_L: 10,
      p_m3_H: 13.2,
      p_m6_L: 9.52,
      p_m6_H: 13.2,
      p_m12_L: 8.93,
      p_m12_H: 16.22,
    },
    3718: {
      H: 0.56,
      L: 0.53,
      O: 0.54,
      C: 0.54,
      V: 5016000,
      p_w1_L: 0.475,
      p_w1_H: 0.56,
      p_m1_L: 0.47,
      p_m1_H: 0.73,
      p_m3_L: 0.47,
      p_m3_H: 0.96,
      p_m6_L: 0.47,
      p_m6_H: 1.19,
      p_m12_L: 0.47,
      p_m12_H: 2.46,
    },
    3728: {
      H: 0.17,
      L: 0.17,
      O: 0.17,
      C: 0.17,
      V: 0,
      p_w1_L: 0.167,
      p_w1_H: 0.175,
      p_m1_L: 0.166,
      p_m1_H: 0.198,
      p_m3_L: 0.156,
      p_m3_H: 0.198,
      p_m6_L: 0.156,
      p_m6_H: 0.199,
      p_m12_L: 0.156,
      p_m12_H: 0.202,
    },
    3737: {
      H: 0.94,
      L: 0.93,
      O: 0.93,
      C: 0.96,
      V: 141000,
      p_w1_L: 0.93,
      p_w1_H: 1.09,
      p_m1_L: 0.93,
      p_m1_H: 1.13,
      p_m3_L: 0.93,
      p_m3_H: 1.38,
      p_m6_L: 0.93,
      p_m6_H: 1.66,
      p_m12_L: 0.93,
      p_m12_H: 1.88,
    },
    3738: {
      H: 7.01,
      L: 6.72,
      O: 6.78,
      C: 7,
      V: 7247000,
      p_w1_L: 6.36,
      p_w1_H: 7.38,
      p_m1_L: 6.36,
      p_m1_H: 8.49,
      p_m3_L: 5.92,
      p_m3_H: 8.49,
      p_m6_L: 5.92,
      p_m6_H: 35.85,
      p_m12_L: 5.92,
      p_m12_H: 48.45,
    },
    3759: {
      H: 150.6,
      L: 145.5,
      O: 146.1,
      C: 149.8,
      V: 652970,
      p_w1_L: 143.3,
      p_w1_H: 165.1,
      p_m1_L: 140.3,
      p_m1_H: 169,
      p_m3_L: 140.3,
      p_m3_H: 192.9,
      p_m6_L: 140.3,
      p_m6_H: 212,
      p_m12_L: 104,
      p_m12_H: 212,
    },
    3768: {
      H: 1.84,
      L: 1.76,
      O: 1.81,
      C: 1.77,
      V: 942000,
      p_w1_L: 1.76,
      p_w1_H: 1.84,
      p_m1_L: 1.76,
      p_m1_H: 1.91,
      p_m3_L: 1.76,
      p_m3_H: 2.29,
      p_m6_L: 1.76,
      p_m6_H: 2.29,
      p_m12_L: 1.76,
      p_m12_H: 2.29,
    },
    3773: {
      H: 0.7,
      L: 0.63,
      O: 0.63,
      C: 0.69,
      V: 2882000,
      p_w1_L: 0.6,
      p_w1_H: 0.7,
      p_m1_L: 0.52,
      p_m1_H: 0.75,
      p_m3_L: 0.34,
      p_m3_H: 0.75,
      p_m6_L: 0.34,
      p_m6_H: 0.75,
      p_m12_L: 0.315,
      p_m12_H: 0.75,
    },
    3778: {
      H: 0.51,
      L: 0.465,
      O: 0.51,
      C: 0.465,
      V: 40000,
      p_w1_L: 0.465,
      p_w1_H: 0.51,
      p_m1_L: 0.465,
      p_m1_H: 0.51,
      p_m3_L: 0.44,
      p_m3_H: 0.59,
      p_m6_L: 0.435,
      p_m6_H: 1,
      p_m12_L: 0.315,
      p_m12_H: 1,
    },
    3788: {
      H: 1.37,
      L: 1.34,
      O: 1.36,
      C: 1.37,
      V: 356000,
      p_w1_L: 1.3,
      p_w1_H: 1.39,
      p_m1_L: 1.28,
      p_m1_H: 1.4,
      p_m3_L: 1.28,
      p_m3_H: 2.36,
      p_m6_L: 1.21,
      p_m6_H: 2.37,
      p_m12_L: 1.21,
      p_m12_H: 2.37,
    },
    3789: {
      H: 0.199,
      L: 0.18,
      O: 0.183,
      C: 0.185,
      V: 364000,
      p_w1_L: 0.18,
      p_w1_H: 0.2,
      p_m1_L: 0.165,
      p_m1_H: 0.225,
      p_m3_L: 0.111,
      p_m3_H: 0.305,
      p_m6_L: 0.103,
      p_m6_H: 0.305,
      p_m12_L: 0.095,
      p_m12_H: 0.305,
    },
    3798: {
      H: 1.88,
      L: 1.58,
      O: 1.59,
      C: 1.7,
      V: 2304000,
      p_w1_L: 1.4,
      p_w1_H: 1.88,
      p_m1_L: 1.4,
      p_m1_H: 1.88,
      p_m3_L: 1.4,
      p_m3_H: 2.57,
      p_m6_L: 1.4,
      p_m6_H: 5.58,
      p_m12_L: 1.4,
      p_m12_H: 7.41,
    },
    3799: {
      H: 4.15,
      L: 4.05,
      O: 4.12,
      C: 4.06,
      V: 4063600,
      p_w1_L: 3.96,
      p_w1_H: 4.27,
      p_m1_L: 3.92,
      p_m1_H: 4.27,
      p_m3_L: 3.92,
      p_m3_H: 4.86,
      p_m6_L: 3.9,
      p_m6_H: 4.98,
      p_m12_L: 3.9,
      p_m12_H: 4.98,
    },
    3800: {
      H: 2.79,
      L: 2.68,
      O: 2.71,
      C: 2.71,
      V: 148289000,
      p_w1_L: 2.57,
      p_w1_H: 2.87,
      p_m1_L: 2.53,
      p_m1_H: 3.52,
      p_m3_L: 1.98,
      p_m3_H: 3.74,
      p_m6_L: 1.98,
      p_m6_H: 3.74,
      p_m12_L: 0.91,
      p_m12_H: 3.78,
    },
    3808: {
      H: 12.14,
      L: 11.74,
      O: 11.74,
      C: 12,
      V: 2882400,
      p_w1_L: 11.36,
      p_w1_H: 12.24,
      p_m1_L: 10,
      p_m1_H: 12.5,
      p_m3_L: 9.95,
      p_m3_H: 15.5,
      p_m6_L: 9.95,
      p_m6_H: 17.4,
      p_m12_L: 9.95,
      p_m12_H: 28.4,
    },
    3813: {
      H: 1.26,
      L: 1.18,
      O: 1.19,
      C: 1.24,
      V: 2478000,
      p_w1_L: 1.09,
      p_w1_H: 1.26,
      p_m1_L: 1.09,
      p_m1_H: 1.42,
      p_m3_L: 1.09,
      p_m3_H: 1.56,
      p_m6_L: 1.09,
      p_m6_H: 1.95,
      p_m12_L: 1.09,
      p_m12_H: 2.02,
    },
    3816: {
      H: 0.275,
      L: 0.27,
      O: 0.27,
      C: 0.275,
      V: 64000,
      p_w1_L: 0.27,
      p_w1_H: 0.285,
      p_m1_L: 0.265,
      p_m1_H: 0.295,
      p_m3_L: 0.255,
      p_m3_H: 0.31,
      p_m6_L: 0.23,
      p_m6_H: 0.34,
      p_m12_L: 0.178,
      p_m12_H: 0.34,
    },
    3818: {
      H: 0.75,
      L: 0.72,
      O: 0.73,
      C: 0.74,
      V: 4151000,
      p_w1_L: 0.68,
      p_w1_H: 0.75,
      p_m1_L: 0.68,
      p_m1_H: 0.77,
      p_m3_L: 0.68,
      p_m3_H: 1.04,
      p_m6_L: 0.68,
      p_m6_H: 1.42,
      p_m12_L: 0.68,
      p_m12_H: 1.42,
    },
    3822: {
      H: 0.097,
      L: 0.097,
      O: 0.097,
      C: 0.097,
      V: 0,
      p_w1_L: 0.092,
      p_w1_H: 0.097,
      p_m1_L: 0.092,
      p_m1_H: 0.102,
      p_m3_L: 0.092,
      p_m3_H: 0.113,
      p_m6_L: 0.091,
      p_m6_H: 0.115,
      p_m12_L: 0.091,
      p_m12_H: 0.137,
    },
    3828: {
      H: 0.47,
      L: 0.44,
      O: 0.44,
      C: 0.445,
      V: 1305000,
      p_w1_L: 0.43,
      p_w1_H: 0.47,
      p_m1_L: 0.43,
      p_m1_H: 0.5,
      p_m3_L: 0.43,
      p_m3_H: 0.63,
      p_m6_L: 0.43,
      p_m6_H: 0.7,
      p_m12_L: 0.43,
      p_m12_H: 0.86,
    },
    3830: {
      H: 0.172,
      L: 0.172,
      O: 0.172,
      C: 0.172,
      V: 0,
      p_w1_L: 0.172,
      p_w1_H: 0.173,
      p_m1_L: 0.162,
      p_m1_H: 0.173,
      p_m3_L: 0.142,
      p_m3_H: 0.19,
      p_m6_L: 0.142,
      p_m6_H: 0.199,
      p_m12_L: 0.142,
      p_m12_H: 0.205,
    },
    3833: {
      H: 1.28,
      L: 1.25,
      O: 1.25,
      C: 1.27,
      V: 450900,
      p_w1_L: 1.2,
      p_w1_H: 1.35,
      p_m1_L: 1.11,
      p_m1_H: 1.48,
      p_m3_L: 1.11,
      p_m3_H: 2.14,
      p_m6_L: 1.11,
      p_m6_H: 2.14,
      p_m12_L: 0.43,
      p_m12_H: 2.4,
    },
    3836: {
      H: 4.32,
      L: 4.17,
      O: 4.17,
      C: 4.3,
      V: 933500,
      p_w1_L: 4.09,
      p_w1_H: 4.34,
      p_m1_L: 3.75,
      p_m1_H: 4.34,
      p_m3_L: 3.67,
      p_m3_H: 4.59,
      p_m6_L: 3.21,
      p_m6_H: 4.73,
      p_m12_L: 3.21,
      p_m12_H: 4.73,
    },
    3838: {
      H: 0.184,
      L: 0.182,
      O: 0.183,
      C: 0.183,
      V: 2205000,
      p_w1_L: 0.173,
      p_w1_H: 0.185,
      p_m1_L: 0.156,
      p_m1_H: 0.185,
      p_m3_L: 0.156,
      p_m3_H: 0.207,
      p_m6_L: 0.156,
      p_m6_H: 0.207,
      p_m12_L: 0.138,
      p_m12_H: 0.249,
    },
    3839: {
      H: 1.3,
      L: 1.3,
      O: 1.3,
      C: 1.33,
      V: 700,
      p_w1_L: 1.3,
      p_w1_H: 1.45,
      p_m1_L: 1.3,
      p_m1_H: 1.6,
      p_m3_L: 1.3,
      p_m3_H: 1.6,
      p_m6_L: 1.3,
      p_m6_H: 1.8,
      p_m12_L: 1.21,
      p_m12_H: 1.8,
    },
    3848: {
      H: 6.9,
      L: 6.8,
      O: 6.8,
      C: 6.9,
      V: 111000,
      p_w1_L: 6.61,
      p_w1_H: 6.96,
      p_m1_L: 6.55,
      p_m1_H: 6.98,
      p_m3_L: 4.58,
      p_m3_H: 7.18,
      p_m6_L: 4.11,
      p_m6_H: 7.56,
      p_m12_L: 4.11,
      p_m12_H: 8.08,
    },
    3860: {
      H: 1.64,
      L: 1.64,
      O: 1.64,
      C: 1.64,
      V: 5000,
      p_w1_L: 1.54,
      p_w1_H: 1.67,
      p_m1_L: 1.54,
      p_m1_H: 1.76,
      p_m3_L: 1.54,
      p_m3_H: 1.88,
      p_m6_L: 1.41,
      p_m6_H: 1.99,
      p_m12_L: 0.48,
      p_m12_H: 2.68,
    },
    3866: {
      H: 4.19,
      L: 4.05,
      O: 4.19,
      C: 4.14,
      V: 25000,
      p_w1_L: 4,
      p_w1_H: 4.42,
      p_m1_L: 4,
      p_m1_H: 4.42,
      p_m3_L: 3.7,
      p_m3_H: 4.48,
      p_m6_L: 2.7,
      p_m6_H: 4.78,
      p_m12_L: 2.7,
      p_m12_H: 5.25,
    },
    3868: {
      H: 4.34,
      L: 4.04,
      O: 4.04,
      C: 4.32,
      V: 15863300,
      p_w1_L: 3.91,
      p_w1_H: 4.34,
      p_m1_L: 3.91,
      p_m1_H: 4.34,
      p_m3_L: 3.91,
      p_m3_H: 4.95,
      p_m6_L: 3.53,
      p_m6_H: 5.84,
      p_m12_L: 3.21,
      p_m12_H: 6.29,
    },
    3869: {
      H: 9.99,
      L: 9.99,
      O: 9.99,
      C: 9.99,
      V: 0,
      p_w1_L: 9.99,
      p_w1_H: 9.99,
      p_m1_L: 9.99,
      p_m1_H: 10.22,
      p_m3_L: 9.99,
      p_m3_H: 11,
      p_m6_L: 9.99,
      p_m6_H: 11.3,
      p_m12_L: 9.99,
      p_m12_H: 12.3,
    },
    3877: {
      H: 1.09,
      L: 1.06,
      O: 1.08,
      C: 1.09,
      V: 1974000,
      p_w1_L: 1.05,
      p_w1_H: 1.09,
      p_m1_L: 1.01,
      p_m1_H: 1.12,
      p_m3_L: 1.01,
      p_m3_H: 1.41,
      p_m6_L: 1.01,
      p_m6_H: 1.76,
      p_m12_L: 1.01,
      p_m12_H: 1.76,
    },
    3878: {
      H: 0.57,
      L: 0.57,
      O: 0.57,
      C: 0.6,
      V: 48000,
      p_w1_L: 0.57,
      p_w1_H: 0.61,
      p_m1_L: 0.51,
      p_m1_H: 0.66,
      p_m3_L: 0.485,
      p_m3_H: 0.66,
      p_m6_L: 0.26,
      p_m6_H: 0.79,
      p_m12_L: 0.25,
      p_m12_H: 0.79,
    },
    3882: {
      H: 0.185,
      L: 0.185,
      O: 0.185,
      C: 0.185,
      V: 0,
      p_w1_L: 0.165,
      p_w1_H: 0.185,
      p_m1_L: 0.165,
      p_m1_H: 0.19,
      p_m3_L: 0.16,
      p_m3_H: 0.2,
      p_m6_L: 0.16,
      p_m6_H: 0.236,
      p_m12_L: 0.149,
      p_m12_H: 0.26,
    },
    3883: {
      H: 1.89,
      L: 1.7,
      O: 1.88,
      C: 1.73,
      V: 45722000,
      p_w1_L: 1.62,
      p_w1_H: 2.2,
      p_m1_L: 1.62,
      p_m1_H: 2.81,
      p_m3_L: 1.62,
      p_m3_H: 4.68,
      p_m6_L: 1.62,
      p_m6_H: 7.67,
      p_m12_L: 1.62,
      p_m12_H: 9.34,
    },
    3886: {
      H: 0.475,
      L: 0.44,
      O: 0.475,
      C: 0.465,
      V: 19564000,
      p_w1_L: 0.41,
      p_w1_H: 0.475,
      p_m1_L: 0.41,
      p_m1_H: 0.49,
      p_m3_L: 0.41,
      p_m3_H: 0.65,
      p_m6_L: 0.255,
      p_m6_H: 0.74,
      p_m12_L: 0.255,
      p_m12_H: 0.95,
    },
    3888: {
      H: 35.2,
      L: 34.4,
      O: 34.75,
      C: 34.8,
      V: 2983280,
      p_w1_L: 31.9,
      p_w1_H: 35.2,
      p_m1_L: 31.85,
      p_m1_H: 37.25,
      p_m3_L: 28,
      p_m3_H: 37.25,
      p_m6_L: 27.55,
      p_m6_H: 55.2,
      p_m12_L: 27.55,
      p_m12_H: 79.5,
    },
    3889: {
      H: 0.094,
      L: 0.09,
      O: 0.094,
      C: 0.09,
      V: 298000,
      p_w1_L: 0.073,
      p_w1_H: 0.1,
      p_m1_L: 0.073,
      p_m1_H: 0.122,
      p_m3_L: 0.073,
      p_m3_H: 0.126,
      p_m6_L: 0.073,
      p_m6_H: 0.134,
      p_m12_L: 0.073,
      p_m12_H: 0.173,
    },
    3893: {
      H: 1.17,
      L: 1.08,
      O: 1.08,
      C: 1.15,
      V: 32500000,
      p_w1_L: 0.91,
      p_w1_H: 1.17,
      p_m1_L: 0.38,
      p_m1_H: 1.17,
      p_m3_L: 0.36,
      p_m3_H: 1.17,
      p_m6_L: 0.25,
      p_m6_H: 1.17,
      p_m12_L: 0.029,
      p_m12_H: 1.17,
    },
    3898: {
      H: 47,
      L: 43.75,
      O: 47,
      C: 45,
      V: 5158040,
      p_w1_L: 43.75,
      p_w1_H: 51.25,
      p_m1_L: 43.6,
      p_m1_H: 53.4,
      p_m3_L: 32.8,
      p_m3_H: 53.4,
      p_m6_L: 32.8,
      p_m6_H: 65.3,
      p_m12_L: 24.1,
      p_m12_H: 65.3,
    },
    3899: {
      H: 11.28,
      L: 10.92,
      O: 10.92,
      C: 10.92,
      V: 4389220,
      p_w1_L: 10.36,
      p_w1_H: 11.42,
      p_m1_L: 8.26,
      p_m1_H: 11.42,
      p_m3_L: 8.26,
      p_m3_H: 11.72,
      p_m6_L: 6.63,
      p_m6_H: 12.28,
      p_m12_L: 4.13,
      p_m12_H: 12.28,
    },
    3900: {
      H: 13.12,
      L: 12.26,
      O: 12.5,
      C: 12.8,
      V: 9410500,
      p_w1_L: 11.5,
      p_w1_H: 13.22,
      p_m1_L: 10.16,
      p_m1_H: 13.22,
      p_m3_L: 9.33,
      p_m3_H: 13.34,
      p_m6_L: 8.09,
      p_m6_H: 13.34,
      p_m12_L: 8.09,
      p_m12_H: 13.34,
    },
    3903: {
      H: 0.485,
      L: 0.42,
      O: 0.485,
      C: 0.43,
      V: 790000,
      p_w1_L: 0.42,
      p_w1_H: 0.57,
      p_m1_L: 0.42,
      p_m1_H: 0.66,
      p_m3_L: 0.42,
      p_m3_H: 0.7,
      p_m6_L: 0.42,
      p_m6_H: 0.75,
      p_m12_L: 0.241,
      p_m12_H: 0.75,
    },
    3908: {
      H: 22.1,
      L: 21.1,
      O: 21.4,
      C: 21.75,
      V: 16863200,
      p_w1_L: 18.76,
      p_w1_H: 22.1,
      p_m1_L: 18,
      p_m1_H: 22.1,
      p_m3_L: 17.98,
      p_m3_H: 23.8,
      p_m6_L: 16.76,
      p_m6_H: 23.8,
      p_m12_L: 15.8,
      p_m12_H: 25.75,
    },
    3913: {
      H: 4.49,
      L: 3.98,
      O: 4.37,
      C: 4.01,
      V: 13514300,
      p_w1_L: 3.98,
      p_w1_H: 4.83,
      p_m1_L: 3.98,
      p_m1_H: 5.77,
      p_m3_L: 3.98,
      p_m3_H: 6.34,
      p_m6_L: 3.98,
      p_m6_H: 10.5,
      p_m12_L: 3.98,
      p_m12_H: 10.5,
    },
    3918: {
      H: 6.92,
      L: 6.7,
      O: 6.86,
      C: 6.78,
      V: 820000,
      p_w1_L: 6.28,
      p_w1_H: 6.93,
      p_m1_L: 6.26,
      p_m1_H: 7.8,
      p_m3_L: 6.26,
      p_m3_H: 7.8,
      p_m6_L: 5.15,
      p_m6_H: 7.95,
      p_m12_L: 5.15,
      p_m12_H: 11.18,
    },
    3919: {
      H: 0.23,
      L: 0.223,
      O: 0.228,
      C: 0.225,
      V: 652000,
      p_w1_L: 0.223,
      p_w1_H: 0.239,
      p_m1_L: 0.223,
      p_m1_H: 0.3,
      p_m3_L: 0.22,
      p_m3_H: 0.3,
      p_m6_L: 0.22,
      p_m6_H: 0.345,
      p_m12_L: 0.22,
      p_m12_H: 0.48,
    },
    3928: {
      H: 0.96,
      L: 0.96,
      O: 0.96,
      C: 0.96,
      V: 0,
      p_w1_L: 0.96,
      p_w1_H: 0.96,
      p_m1_L: 0.96,
      p_m1_H: 0.96,
      p_m3_L: 0.96,
      p_m3_H: 0.96,
      p_m6_L: 0.96,
      p_m6_H: 0.96,
      p_m12_L: 0.65,
      p_m12_H: 1.15,
    },
    3933: {
      H: 4.83,
      L: 4.67,
      O: 4.74,
      C: 4.7,
      V: 4170580,
      p_w1_L: 4.46,
      p_w1_H: 4.83,
      p_m1_L: 4.37,
      p_m1_H: 5.68,
      p_m3_L: 4.37,
      p_m3_H: 5.95,
      p_m6_L: 4.37,
      p_m6_H: 7.07,
      p_m12_L: 4.37,
      p_m12_H: 7.6,
    },
    3938: {
      H: 0.248,
      L: 0.248,
      O: 0.248,
      C: 0.248,
      V: 6000,
      p_w1_L: 0.235,
      p_w1_H: 0.255,
      p_m1_L: 0.225,
      p_m1_H: 0.275,
      p_m3_L: 0.225,
      p_m3_H: 0.31,
      p_m6_L: 0.225,
      p_m6_H: 0.38,
      p_m12_L: 0.225,
      p_m12_H: 0.45,
    },
    3939: {
      H: 2.06,
      L: 2.06,
      O: 2.06,
      C: 2.06,
      V: 0,
      p_w1_L: 2.06,
      p_w1_H: 2.06,
      p_m1_L: 1.82,
      p_m1_H: 2.25,
      p_m3_L: 1.82,
      p_m3_H: 2.25,
      p_m6_L: 1.82,
      p_m6_H: 2.34,
      p_m12_L: 1.82,
      p_m12_H: 3.6,
    },
    3948: {
      H: 6.08,
      L: 5.83,
      O: 5.85,
      C: 6.08,
      V: 126100,
      p_w1_L: 5.22,
      p_w1_H: 6.08,
      p_m1_L: 5.22,
      p_m1_H: 6.08,
      p_m3_L: 5.22,
      p_m3_H: 7.79,
      p_m6_L: 4.83,
      p_m6_H: 7.79,
      p_m12_L: 4.25,
      p_m12_H: 7.79,
    },
    3958: {
      H: 7.44,
      L: 7.23,
      O: 7.34,
      C: 7.38,
      V: 2674000,
      p_w1_L: 6.11,
      p_w1_H: 7.48,
      p_m1_L: 5.8,
      p_m1_H: 7.48,
      p_m3_L: 5.76,
      p_m3_H: 8.29,
      p_m6_L: 5,
      p_m6_H: 8.99,
      p_m12_L: 4.81,
      p_m12_H: 8.99,
    },
    3963: {
      H: 0.124,
      L: 0.124,
      O: 0.124,
      C: 0.124,
      V: 0,
      p_w1_L: 0.124,
      p_w1_H: 0.129,
      p_m1_L: 0.115,
      p_m1_H: 0.129,
      p_m3_L: 0.095,
      p_m3_H: 0.177,
      p_m6_L: 0.095,
      p_m6_H: 0.177,
      p_m12_L: 0.095,
      p_m12_H: 0.295,
    },
    3968: {
      H: 65.2,
      L: 64.05,
      O: 65.05,
      C: 64.95,
      V: 10596100,
      p_w1_L: 60.2,
      p_w1_H: 65.45,
      p_m1_L: 59.85,
      p_m1_H: 65.95,
      p_m3_L: 55.55,
      p_m3_H: 69.8,
      p_m6_L: 55.55,
      p_m6_H: 70.3,
      p_m12_L: 45.95,
      p_m12_H: 72.45,
    },
    3969: {
      H: 2.8,
      L: 2.77,
      O: 2.78,
      C: 2.79,
      V: 2941000,
      p_w1_L: 2.7,
      p_w1_H: 2.8,
      p_m1_L: 2.63,
      p_m1_H: 2.8,
      p_m3_L: 2.62,
      p_m3_H: 3.09,
      p_m6_L: 2.62,
      p_m6_H: 3.32,
      p_m12_L: 2.53,
      p_m12_H: 3.48,
    },
    3978: {
      H: 0.36,
      L: 0.325,
      O: 0.335,
      C: 0.345,
      V: 2346000,
      p_w1_L: 0.285,
      p_w1_H: 0.5,
      p_m1_L: 0.285,
      p_m1_H: 0.57,
      p_m3_L: 0.285,
      p_m3_H: 0.76,
      p_m6_L: 0.285,
      p_m6_H: 1.71,
      p_m12_L: 0.285,
      p_m12_H: 3.15,
    },
    3983: {
      H: 2.28,
      L: 2.13,
      O: 2.19,
      C: 2.22,
      V: 19998000,
      p_w1_L: 2.12,
      p_w1_H: 2.4,
      p_m1_L: 2.12,
      p_m1_H: 2.52,
      p_m3_L: 2.12,
      p_m3_H: 3.58,
      p_m6_L: 2.12,
      p_m6_H: 3.58,
      p_m12_L: 1.14,
      p_m12_H: 3.58,
    },
    3988: {
      H: 2.76,
      L: 2.73,
      O: 2.75,
      C: 2.75,
      V: 245159000,
      p_w1_L: 2.72,
      p_w1_H: 2.78,
      p_m1_L: 2.7,
      p_m1_H: 2.8,
      p_m3_L: 2.67,
      p_m3_H: 2.9,
      p_m6_L: 2.67,
      p_m6_H: 2.9,
      p_m12_L: 2.54,
      p_m12_H: 3.17,
    },
    3989: {
      H: 0.18,
      L: 0.177,
      O: 0.179,
      C: 0.178,
      V: 2086000,
      p_w1_L: 0.173,
      p_w1_H: 0.185,
      p_m1_L: 0.167,
      p_m1_H: 0.195,
      p_m3_L: 0.164,
      p_m3_H: 0.224,
      p_m6_L: 0.131,
      p_m6_H: 0.24,
      p_m12_L: 0.113,
      p_m12_H: 0.24,
    },
    3990: {
      H: 12.96,
      L: 12.4,
      O: 12.88,
      C: 12.62,
      V: 841800,
      p_w1_L: 12.32,
      p_w1_H: 13.88,
      p_m1_L: 11.96,
      p_m1_H: 14.26,
      p_m3_L: 11.78,
      p_m3_H: 14.44,
      p_m6_L: 11.78,
      p_m6_H: 19.18,
      p_m12_L: 11.78,
      p_m12_H: 20.75,
    },
    3991: {
      H: 0.71,
      L: 0.71,
      O: 0.71,
      C: 0.71,
      V: 0,
      p_w1_L: 0.69,
      p_w1_H: 0.71,
      p_m1_L: 0.65,
      p_m1_H: 0.77,
      p_m3_L: 0.65,
      p_m3_H: 0.8,
      p_m6_L: 0.65,
      p_m6_H: 0.9,
      p_m12_L: 0.65,
      p_m12_H: 0.96,
    },
    3993: {
      H: 4.67,
      L: 4.53,
      O: 4.63,
      C: 4.6,
      V: 51904100,
      p_w1_L: 4.5,
      p_w1_H: 4.92,
      p_m1_L: 4.26,
      p_m1_H: 4.92,
      p_m3_L: 4.26,
      p_m3_H: 6.55,
      p_m6_L: 4.13,
      p_m6_H: 6.68,
      p_m12_L: 3.62,
      p_m12_H: 7.67,
    },
    3996: {
      H: 1.29,
      L: 1.21,
      O: 1.25,
      C: 1.28,
      V: 94057500,
      p_w1_L: 1.04,
      p_w1_H: 1.38,
      p_m1_L: 0.87,
      p_m1_H: 1.38,
      p_m3_L: 0.87,
      p_m3_H: 1.65,
      p_m6_L: 0.68,
      p_m6_H: 1.65,
      p_m12_L: 0.68,
      p_m12_H: 1.65,
    },
    3997: {
      H: 1.32,
      L: 1.32,
      O: 1.32,
      C: 1.32,
      V: 0,
      p_w1_L: 1.32,
      p_w1_H: 1.4,
      p_m1_L: 1.3,
      p_m1_H: 1.57,
      p_m3_L: 1.14,
      p_m3_H: 1.57,
      p_m6_L: 0.94,
      p_m6_H: 1.57,
      p_m12_L: 0.81,
      p_m12_H: 1.57,
    },
    3998: {
      H: 5.29,
      L: 5.05,
      O: 5.05,
      C: 5.18,
      V: 19732600,
      p_w1_L: 4.88,
      p_w1_H: 5.56,
      p_m1_L: 4.88,
      p_m1_H: 6.25,
      p_m3_L: 4.88,
      p_m3_H: 6.93,
      p_m6_L: 3.95,
      p_m6_H: 6.93,
      p_m12_L: 2.99,
      p_m12_H: 6.93,
    },
    3999: {
      H: 0.75,
      L: 0.72,
      O: 0.73,
      C: 0.75,
      V: 21000,
      p_w1_L: 0.72,
      p_w1_H: 0.76,
      p_m1_L: 0.71,
      p_m1_H: 0.79,
      p_m3_L: 0.71,
      p_m3_H: 0.89,
      p_m6_L: 0.71,
      p_m6_H: 0.94,
      p_m12_L: 0.71,
      p_m12_H: 0.99,
    },
    4332: {
      H: 0,
      L: 0,
      O: 0,
      C: 0,
      V: 0,
      p_w1_L: 0,
      p_w1_H: 0,
      p_m1_L: 0,
      p_m1_H: 1850,
      p_m3_L: 0,
      p_m3_H: 1850,
      p_m6_L: 0,
      p_m6_H: 1850,
      p_m12_L: 0,
      p_m12_H: 1850,
    },
    4333: {
      H: 0,
      L: 0,
      O: 0,
      C: 0,
      V: 0,
      p_w1_L: 0,
      p_w1_H: 0,
      p_m1_L: 0,
      p_m1_H: 0,
      p_m3_L: 0,
      p_m3_H: 375,
      p_m6_L: 0,
      p_m6_H: 375,
      p_m12_L: 0,
      p_m12_H: 375,
    },
    4335: {
      H: 0,
      L: 0,
      O: 0,
      C: 0,
      V: 0,
      p_w1_L: 0,
      p_w1_H: 0,
      p_m1_L: 0,
      p_m1_H: 0,
      p_m3_L: 0,
      p_m3_H: 350.2,
      p_m6_L: 0,
      p_m6_H: 350.2,
      p_m12_L: 0,
      p_m12_H: 374,
    },
    4336: {
      H: 0,
      L: 0,
      O: 0,
      C: 0,
      V: 0,
      p_w1_L: 0,
      p_w1_H: 0,
      p_m1_L: 0,
      p_m1_H: 700,
      p_m3_L: 0,
      p_m3_H: 700,
      p_m6_L: 0,
      p_m6_H: 700,
      p_m12_L: 0,
      p_m12_H: 700,
    },
    4337: {
      H: 0,
      L: 0,
      O: 0,
      C: 0,
      V: 0,
      p_w1_L: 0,
      p_w1_H: 0,
      p_m1_L: 0,
      p_m1_H: 0,
      p_m3_L: 0,
      p_m3_H: 0,
      p_m6_L: 0,
      p_m6_H: 58,
      p_m12_L: 0,
      p_m12_H: 58,
    },
    4338: {
      H: 0,
      L: 0,
      O: 0,
      C: 0,
      V: 0,
      p_w1_L: 0,
      p_w1_H: 0,
      p_m1_L: 0,
      p_m1_H: 0,
      p_m3_L: 0,
      p_m3_H: 0,
      p_m6_L: 0,
      p_m6_H: 1000,
      p_m12_L: 0,
      p_m12_H: 1000,
    },
    6030: {
      H: 20.95,
      L: 20.35,
      O: 20.65,
      C: 20.65,
      V: 11702400,
      p_w1_L: 18.46,
      p_w1_H: 20.95,
      p_m1_L: 18.12,
      p_m1_H: 20.95,
      p_m3_L: 18.12,
      p_m3_H: 21.65,
      p_m6_L: 16.58,
      p_m6_H: 21.65,
      p_m12_L: 15.22,
      p_m12_H: 22,
    },
    6033: {
      H: 3.43,
      L: 3.4,
      O: 3.4,
      C: 3.44,
      V: 96000,
      p_w1_L: 3.32,
      p_w1_H: 3.47,
      p_m1_L: 3.29,
      p_m1_H: 3.47,
      p_m3_L: 3.17,
      p_m3_H: 3.47,
      p_m6_L: 2.82,
      p_m6_H: 3.47,
      p_m12_L: 2.12,
      p_m12_H: 3.47,
    },
    6036: {
      H: 0.285,
      L: 0.265,
      O: 0.265,
      C: 0.27,
      V: 2400000,
      p_w1_L: 0.26,
      p_w1_H: 0.29,
      p_m1_L: 0.26,
      p_m1_H: 0.36,
      p_m3_L: 0.26,
      p_m3_H: 0.36,
      p_m6_L: 0.206,
      p_m6_H: 0.45,
      p_m12_L: 0.172,
      p_m12_H: 0.45,
    },
    6038: {
      H: 0.2,
      L: 0.2,
      O: 0.2,
      C: 0.2,
      V: 100000,
      p_w1_L: 0.186,
      p_w1_H: 0.21,
      p_m1_L: 0.176,
      p_m1_H: 0.21,
      p_m3_L: 0.175,
      p_m3_H: 0.21,
      p_m6_L: 0.141,
      p_m6_H: 0.21,
      p_m12_L: 0.12,
      p_m12_H: 0.21,
    },
    6049: {
      H: 56.9,
      L: 54.25,
      O: 55.3,
      C: 56.65,
      V: 1868960,
      p_w1_L: 50.25,
      p_w1_H: 56.9,
      p_m1_L: 40,
      p_m1_H: 58,
      p_m3_L: 38.1,
      p_m3_H: 58,
      p_m6_L: 38.1,
      p_m6_H: 61.7,
      p_m12_L: 38.1,
      p_m12_H: 73.5,
    },
    6055: {
      H: 17.56,
      L: 16.9,
      O: 17.26,
      C: 16.96,
      V: 1274600,
      p_w1_L: 16.9,
      p_w1_H: 18.16,
      p_m1_L: 16.68,
      p_m1_H: 19.6,
      p_m3_L: 14.76,
      p_m3_H: 19.6,
      p_m6_L: 14.5,
      p_m6_H: 19.6,
      p_m12_L: 14.46,
      p_m12_H: 21.55,
    },
    6058: {
      H: 0.18,
      L: 0.18,
      O: 0.18,
      C: 0.18,
      V: 0,
      p_w1_L: 0.18,
      p_w1_H: 0.199,
      p_m1_L: 0.18,
      p_m1_H: 0.21,
      p_m3_L: 0.18,
      p_m3_H: 0.228,
      p_m6_L: 0.17,
      p_m6_H: 0.232,
      p_m12_L: 0.17,
      p_m12_H: 0.33,
    },
    6060: {
      H: 27.15,
      L: 26.3,
      O: 26.6,
      C: 26.55,
      V: 2309560,
      p_w1_L: 24.6,
      p_w1_H: 27.15,
      p_m1_L: 24.6,
      p_m1_H: 28.95,
      p_m3_L: 24.6,
      p_m3_H: 38.05,
      p_m6_L: 24.6,
      p_m6_H: 50.85,
      p_m12_L: 24.6,
      p_m12_H: 78.8,
    },
    6063: {
      H: 0.097,
      L: 0.093,
      O: 0.094,
      C: 0.096,
      V: 640000,
      p_w1_L: 0.088,
      p_w1_H: 0.114,
      p_m1_L: 0.079,
      p_m1_H: 0.127,
      p_m3_L: 0.079,
      p_m3_H: 0.127,
      p_m6_L: 0.079,
      p_m6_H: 0.135,
      p_m12_L: 0.079,
      p_m12_H: 0.157,
    },
    6066: {
      H: 8.88,
      L: 8.7,
      O: 8.85,
      C: 8.79,
      V: 3717000,
      p_w1_L: 8.02,
      p_w1_H: 9,
      p_m1_L: 7.81,
      p_m1_H: 9,
      p_m3_L: 7.81,
      p_m3_H: 10.06,
      p_m6_L: 7.44,
      p_m6_H: 10.1,
      p_m12_L: 7.44,
      p_m12_H: 13.46,
    },
    6068: {
      H: 1.47,
      L: 1.47,
      O: 1.47,
      C: 1.47,
      V: 0,
      p_w1_L: 1.47,
      p_w1_H: 1.47,
      p_m1_L: 1.16,
      p_m1_H: 1.62,
      p_m3_L: 1.16,
      p_m3_H: 1.7,
      p_m6_L: 1.16,
      p_m6_H: 2.41,
      p_m12_L: 1.16,
      p_m12_H: 4.48,
    },
    6069: {
      H: 7.57,
      L: 7.15,
      O: 7.16,
      C: 7.55,
      V: 1571000,
      p_w1_L: 6.84,
      p_w1_H: 7.57,
      p_m1_L: 6.74,
      p_m1_H: 8.54,
      p_m3_L: 6.74,
      p_m3_H: 10.08,
      p_m6_L: 6.74,
      p_m6_H: 11.02,
      p_m12_L: 5,
      p_m12_H: 11.02,
    },
    6078: {
      H: 58,
      L: 55.9,
      O: 56.2,
      C: 56.35,
      V: 3499930,
      p_w1_L: 53.75,
      p_w1_H: 64.25,
      p_m1_L: 53.75,
      p_m1_H: 76.75,
      p_m3_L: 52.9,
      p_m3_H: 76.75,
      p_m6_L: 51.35,
      p_m6_H: 110,
      p_m12_L: 42.3,
      p_m12_H: 110,
    },
    6080: {
      H: 0.139,
      L: 0.139,
      O: 0.139,
      C: 0.139,
      V: 0,
      p_w1_L: 0.139,
      p_w1_H: 0.142,
      p_m1_L: 0.123,
      p_m1_H: 0.149,
      p_m3_L: 0.123,
      p_m3_H: 0.158,
      p_m6_L: 0.09,
      p_m6_H: 0.166,
      p_m12_L: 0.063,
      p_m12_H: 0.33,
    },
    6083: {
      H: 0.44,
      L: 0.43,
      O: 0.44,
      C: 0.435,
      V: 172000,
      p_w1_L: 0.425,
      p_w1_H: 0.45,
      p_m1_L: 0.42,
      p_m1_H: 0.46,
      p_m3_L: 0.42,
      p_m3_H: 0.55,
      p_m6_L: 0.42,
      p_m6_H: 0.66,
      p_m12_L: 0.37,
      p_m12_H: 0.66,
    },
    6088: {
      H: 1.76,
      L: 1.66,
      O: 1.7,
      C: 1.66,
      V: 9694000,
      p_w1_L: 1.56,
      p_w1_H: 1.76,
      p_m1_L: 1.56,
      p_m1_H: 1.76,
      p_m3_L: 1.42,
      p_m3_H: 1.84,
      p_m6_L: 1.42,
      p_m6_H: 2.19,
      p_m12_L: 1.42,
      p_m12_H: 3.95,
    },
    6090: {
      H: 1.9,
      L: 1.9,
      O: 1.9,
      C: 1.9,
      V: 0,
      p_w1_L: 1.9,
      p_w1_H: 1.91,
      p_m1_L: 1.86,
      p_m1_H: 1.92,
      p_m3_L: 1.85,
      p_m3_H: 1.92,
      p_m6_L: 1.78,
      p_m6_H: 1.95,
      p_m12_L: 1.78,
      p_m12_H: 2.11,
    },
    6093: {
      H: 4.25,
      L: 4.22,
      O: 4.25,
      C: 4.22,
      V: 20000,
      p_w1_L: 4.2,
      p_w1_H: 4.28,
      p_m1_L: 4.13,
      p_m1_H: 4.37,
      p_m3_L: 4.05,
      p_m3_H: 4.37,
      p_m6_L: 3.24,
      p_m6_H: 4.6,
      p_m12_L: 2.01,
      p_m12_H: 4.6,
    },
    6098: {
      H: 52.5,
      L: 50.85,
      O: 52.45,
      C: 51.95,
      V: 15418800,
      p_w1_L: 45.85,
      p_w1_H: 52.5,
      p_m1_L: 45.85,
      p_m1_H: 63.2,
      p_m3_L: 43.5,
      p_m3_H: 70.3,
      p_m6_L: 43.5,
      p_m6_H: 85.2,
      p_m12_L: 43.5,
      p_m12_H: 85.2,
    },
    6099: {
      H: 12.36,
      L: 12.18,
      O: 12.22,
      C: 12.2,
      V: 955600,
      p_w1_L: 11.62,
      p_w1_H: 12.54,
      p_m1_L: 11.3,
      p_m1_H: 12.54,
      p_m3_L: 11.3,
      p_m3_H: 13.06,
      p_m6_L: 10.16,
      p_m6_H: 13.06,
      p_m12_L: 10.16,
      p_m12_H: 14.48,
    },
    6100: {
      H: 16.16,
      L: 15.02,
      O: 16.16,
      C: 15.96,
      V: 709200,
      p_w1_L: 15.02,
      p_w1_H: 17.48,
      p_m1_L: 11.62,
      p_m1_H: 17.48,
      p_m3_L: 9.62,
      p_m3_H: 17.48,
      p_m6_L: 9.62,
      p_m6_H: 22.8,
      p_m12_L: 9.62,
      p_m12_H: 28.4,
    },
    6108: {
      H: 0.425,
      L: 0.425,
      O: 0.425,
      C: 0.425,
      V: 0,
      p_w1_L: 0.425,
      p_w1_H: 0.425,
      p_m1_L: 0.425,
      p_m1_H: 0.425,
      p_m3_L: 0.425,
      p_m3_H: 0.425,
      p_m6_L: 0.425,
      p_m6_H: 0.425,
      p_m12_L: 0.425,
      p_m12_H: 0.425,
    },
    6110: {
      H: 9.02,
      L: 8.6,
      O: 9.02,
      C: 8.65,
      V: 8157100,
      p_w1_L: 8.6,
      p_w1_H: 9.2,
      p_m1_L: 8.6,
      p_m1_H: 9.7,
      p_m3_L: 8.24,
      p_m3_H: 10.46,
      p_m6_L: 8.24,
      p_m6_H: 13.5,
      p_m12_L: 8.24,
      p_m12_H: 13.78,
    },
    6111: {
      H: 4.3,
      L: 4.29,
      O: 4.29,
      C: 4.3,
      V: 400000,
      p_w1_L: 4.27,
      p_w1_H: 4.35,
      p_m1_L: 4.27,
      p_m1_H: 5.07,
      p_m3_L: 4.27,
      p_m3_H: 6.68,
      p_m6_L: 4.27,
      p_m6_H: 6.85,
      p_m12_L: 4.27,
      p_m12_H: 7.94,
    },
    6113: {
      H: 1.21,
      L: 1.21,
      O: 1.21,
      C: 1.21,
      V: 0,
      p_w1_L: 1.13,
      p_w1_H: 1.21,
      p_m1_L: 1.13,
      p_m1_H: 1.81,
      p_m3_L: 1.13,
      p_m3_H: 1.81,
      p_m6_L: 1.1,
      p_m6_H: 1.81,
      p_m12_L: 0.8,
      p_m12_H: 1.81,
    },
    6116: {
      H: 0.63,
      L: 0.59,
      O: 0.62,
      C: 0.6,
      V: 1607200,
      p_w1_L: 0.56,
      p_w1_H: 0.65,
      p_m1_L: 0.52,
      p_m1_H: 0.69,
      p_m3_L: 0.51,
      p_m3_H: 0.77,
      p_m6_L: 0.51,
      p_m6_H: 0.88,
      p_m12_L: 0.25,
      p_m12_H: 0.93,
    },
    6117: {
      H: 0.52,
      L: 0.51,
      O: 0.52,
      C: 0.52,
      V: 14000,
      p_w1_L: 0.5,
      p_w1_H: 0.56,
      p_m1_L: 0.495,
      p_m1_H: 0.61,
      p_m3_L: 0.495,
      p_m3_H: 0.64,
      p_m6_L: 0.495,
      p_m6_H: 0.66,
      p_m12_L: 0.495,
      p_m12_H: 0.74,
    },
    6118: {
      H: 14.64,
      L: 13.92,
      O: 14.4,
      C: 14,
      V: 1137000,
      p_w1_L: 13.92,
      p_w1_H: 16.5,
      p_m1_L: 12.8,
      p_m1_H: 20.2,
      p_m3_L: 12.8,
      p_m3_H: 30.9,
      p_m6_L: 11.6,
      p_m6_H: 30.9,
      p_m12_L: 3.42,
      p_m12_H: 30.9,
    },
    6119: {
      H: 0.36,
      L: 0.35,
      O: 0.36,
      C: 0.36,
      V: 858000,
      p_w1_L: 0.35,
      p_w1_H: 0.38,
      p_m1_L: 0.35,
      p_m1_H: 0.385,
      p_m3_L: 0.35,
      p_m3_H: 0.44,
      p_m6_L: 0.35,
      p_m6_H: 0.45,
      p_m12_L: 0.31,
      p_m12_H: 0.59,
    },
    6122: {
      H: 2.4,
      L: 2.38,
      O: 2.4,
      C: 2.39,
      V: 59000,
      p_w1_L: 2.38,
      p_w1_H: 2.4,
      p_m1_L: 2.38,
      p_m1_H: 2.4,
      p_m3_L: 2.2,
      p_m3_H: 2.45,
      p_m6_L: 2.2,
      p_m6_H: 2.524,
      p_m12_L: 2.2,
      p_m12_H: 2.724,
    },
    6123: {
      H: 3.25,
      L: 3.03,
      O: 3.03,
      C: 3.15,
      V: 522000,
      p_w1_L: 3,
      p_w1_H: 3.25,
      p_m1_L: 3,
      p_m1_H: 3.72,
      p_m3_L: 2.89,
      p_m3_H: 3.73,
      p_m6_L: 2.85,
      p_m6_H: 4.8,
      p_m12_L: 2.85,
      p_m12_H: 7.3,
    },
    6127: {
      H: 89.85,
      L: 81.05,
      O: 86,
      C: 87,
      V: 239740,
      p_w1_L: 81.05,
      p_w1_H: 95,
      p_m1_L: 76.65,
      p_m1_H: 98.95,
      p_m3_L: 74.65,
      p_m3_H: 116.5,
      p_m6_L: 74.65,
      p_m6_H: 138,
      p_m12_L: 74.65,
      p_m12_H: 138,
    },
    6128: {
      H: 0.76,
      L: 0.71,
      O: 0.71,
      C: 0.74,
      V: 402000,
      p_w1_L: 0.7,
      p_w1_H: 0.79,
      p_m1_L: 0.68,
      p_m1_H: 0.87,
      p_m3_L: 0.62,
      p_m3_H: 0.91,
      p_m6_L: 0.62,
      p_m6_H: 1.3,
      p_m12_L: 0.36,
      p_m12_H: 3.8,
    },
    6133: {
      H: 0.29,
      L: 0.275,
      O: 0.29,
      C: 0.29,
      V: 39000,
      p_w1_L: 0.255,
      p_w1_H: 0.295,
      p_m1_L: 0.238,
      p_m1_H: 0.34,
      p_m3_L: 0.238,
      p_m3_H: 0.4,
      p_m6_L: 0.232,
      p_m6_H: 0.4,
      p_m12_L: 0.232,
      p_m12_H: 0.52,
    },
    6136: {
      H: 0.75,
      L: 0.72,
      O: 0.75,
      C: 0.73,
      V: 42000,
      p_w1_L: 0.7,
      p_w1_H: 0.77,
      p_m1_L: 0.7,
      p_m1_H: 0.77,
      p_m3_L: 0.7,
      p_m3_H: 1.06,
      p_m6_L: 0.63,
      p_m6_H: 1.06,
      p_m12_L: 0.63,
      p_m12_H: 1.06,
    },
    6138: {
      H: 0.8,
      L: 0.78,
      O: 0.79,
      C: 0.79,
      V: 33000,
      p_w1_L: 0.78,
      p_w1_H: 0.9,
      p_m1_L: 0.78,
      p_m1_H: 0.9,
      p_m3_L: 0.77,
      p_m3_H: 0.9,
      p_m6_L: 0.77,
      p_m6_H: 1,
      p_m12_L: 0.77,
      p_m12_H: 1.19,
    },
    6158: {
      H: 4.05,
      L: 3.77,
      O: 4.05,
      C: 3.9,
      V: 7394960,
      p_w1_L: 3.77,
      p_w1_H: 4.42,
      p_m1_L: 3.53,
      p_m1_H: 4.78,
      p_m3_L: 3.53,
      p_m3_H: 4.78,
      p_m6_L: 3.53,
      p_m6_H: 4.95,
      p_m12_L: 3.53,
      p_m12_H: 5.67,
    },
    6160: {
      H: 198.8,
      L: 187.2,
      O: 194,
      C: 188.1,
      V: 487384,
      p_w1_L: 178.1,
      p_w1_H: 217,
      p_m1_L: 178.1,
      p_m1_H: 239.6,
      p_m3_L: 178.1,
      p_m3_H: 278.6,
      p_m6_L: 151.1,
      p_m6_H: 278.6,
      p_m12_L: 130.4,
      p_m12_H: 278.6,
    },
    6161: {
      H: 0.57,
      L: 0.54,
      O: 0.55,
      C: 0.57,
      V: 162000,
      p_w1_L: 0.52,
      p_w1_H: 0.58,
      p_m1_L: 0.52,
      p_m1_H: 0.64,
      p_m3_L: 0.52,
      p_m3_H: 1.03,
      p_m6_L: 0.52,
      p_m6_H: 1.22,
      p_m12_L: 0.52,
      p_m12_H: 1.78,
    },
    6162: {
      H: 0.174,
      L: 0.169,
      O: 0.17,
      C: 0.174,
      V: 48000,
      p_w1_L: 0.169,
      p_w1_H: 0.196,
      p_m1_L: 0.143,
      p_m1_H: 0.238,
      p_m3_L: 0.142,
      p_m3_H: 0.246,
      p_m6_L: 0.142,
      p_m6_H: 0.49,
      p_m12_L: 0.115,
      p_m12_H: 0.49,
    },
    6163: {
      H: 0.73,
      L: 0.65,
      O: 0.67,
      C: 0.72,
      V: 202000,
      p_w1_L: 0.65,
      p_w1_H: 0.75,
      p_m1_L: 0.6,
      p_m1_H: 0.75,
      p_m3_L: 0.5,
      p_m3_H: 0.75,
      p_m6_L: 0.5,
      p_m6_H: 0.8,
      p_m12_L: 0.5,
      p_m12_H: 0.96,
    },
    6166: {
      H: 1.69,
      L: 1.69,
      O: 1.69,
      C: 1.69,
      V: 0,
      p_w1_L: 1.42,
      p_w1_H: 1.73,
      p_m1_L: 1.42,
      p_m1_H: 2.29,
      p_m3_L: 1.42,
      p_m3_H: 2.59,
      p_m6_L: 1.42,
      p_m6_H: 3.11,
      p_m12_L: 1.42,
      p_m12_H: 3.2,
    },
    6168: {
      H: 0.161,
      L: 0.161,
      O: 0.161,
      C: 0.161,
      V: 0,
      p_w1_L: 0.161,
      p_w1_H: 0.161,
      p_m1_L: 0.161,
      p_m1_H: 0.161,
      p_m3_L: 0.161,
      p_m3_H: 0.161,
      p_m6_L: 0.161,
      p_m6_H: 0.161,
      p_m12_L: 0.151,
      p_m12_H: 0.28,
    },
    6169: {
      H: 3.19,
      L: 3.1,
      O: 3.1,
      C: 3.17,
      V: 12805800,
      p_w1_L: 2.97,
      p_w1_H: 3.64,
      p_m1_L: 2.97,
      p_m1_H: 3.98,
      p_m3_L: 2.97,
      p_m3_H: 5.25,
      p_m6_L: 2.97,
      p_m6_H: 7.74,
      p_m12_L: 2.97,
      p_m12_H: 8.54,
    },
    6178: {
      H: 6.11,
      L: 6.02,
      O: 6.06,
      C: 6.08,
      V: 383400,
      p_w1_L: 5.86,
      p_w1_H: 6.32,
      p_m1_L: 5.83,
      p_m1_H: 6.32,
      p_m3_L: 5.67,
      p_m3_H: 6.85,
      p_m6_L: 5.67,
      p_m6_H: 7.35,
      p_m12_L: 5.67,
      p_m12_H: 8.18,
    },
    6182: {
      H: 0.27,
      L: 0.27,
      O: 0.27,
      C: 0.27,
      V: 0,
      p_w1_L: 0.27,
      p_w1_H: 0.275,
      p_m1_L: 0.265,
      p_m1_H: 0.33,
      p_m3_L: 0.265,
      p_m3_H: 0.375,
      p_m6_L: 0.163,
      p_m6_H: 0.375,
      p_m12_L: 0.143,
      p_m12_H: 0.375,
    },
    6183: {
      H: 0.081,
      L: 0.081,
      O: 0.081,
      C: 0.081,
      V: 0,
      p_w1_L: 0.081,
      p_w1_H: 0.081,
      p_m1_L: 0.081,
      p_m1_H: 0.081,
      p_m3_L: 0.081,
      p_m3_H: 0.081,
      p_m6_L: 0.081,
      p_m6_H: 0.081,
      p_m12_L: 0.081,
      p_m12_H: 0.081,
    },
    6185: {
      H: 144.5,
      L: 139.2,
      O: 142.1,
      C: 143.5,
      V: 963000,
      p_w1_L: 135.4,
      p_w1_H: 168.1,
      p_m1_L: 135.4,
      p_m1_H: 179,
      p_m3_L: 135.4,
      p_m3_H: 309,
      p_m6_L: 135.4,
      p_m6_H: 437,
      p_m12_L: 135.4,
      p_m12_H: 449.6,
    },
    6186: {
      H: 10.64,
      L: 10.18,
      O: 10.3,
      C: 10.6,
      V: 19356200,
      p_w1_L: 9.7,
      p_w1_H: 10.64,
      p_m1_L: 9.7,
      p_m1_H: 12.46,
      p_m3_L: 9.7,
      p_m3_H: 14.34,
      p_m6_L: 9.7,
      p_m6_H: 21.2,
      p_m12_L: 9.7,
      p_m12_H: 25.7,
    },
    6188: {
      H: 3.83,
      L: 3.83,
      O: 3.83,
      C: 3.83,
      V: 0,
      p_w1_L: 3.83,
      p_w1_H: 3.83,
      p_m1_L: 3.83,
      p_m1_H: 3.83,
      p_m3_L: 3.83,
      p_m3_H: 3.83,
      p_m6_L: 3.83,
      p_m6_H: 3.83,
      p_m12_L: 1.8,
      p_m12_H: 3.84,
    },
    6189: {
      H: 0.72,
      L: 0.72,
      O: 0.72,
      C: 0.72,
      V: 0,
      p_w1_L: 0.67,
      p_w1_H: 0.75,
      p_m1_L: 0.66,
      p_m1_H: 0.76,
      p_m3_L: 0.65,
      p_m3_H: 0.88,
      p_m6_L: 0.65,
      p_m6_H: 1.08,
      p_m12_L: 0.65,
      p_m12_H: 1.52,
    },
    6190: {
      H: 10.98,
      L: 10.98,
      O: 10.98,
      C: 10.98,
      V: 0,
      p_w1_L: 10.98,
      p_w1_H: 10.98,
      p_m1_L: 10.6,
      p_m1_H: 11.16,
      p_m3_L: 10.6,
      p_m3_H: 11.16,
      p_m6_L: 10.6,
      p_m6_H: 11.16,
      p_m12_L: 9.83,
      p_m12_H: 11.16,
    },
    6193: {
      H: 0.39,
      L: 0.39,
      O: 0.39,
      C: 0.39,
      V: 200000,
      p_w1_L: 0.37,
      p_w1_H: 0.4,
      p_m1_L: 0.355,
      p_m1_H: 0.415,
      p_m3_L: 0.35,
      p_m3_H: 0.51,
      p_m6_L: 0.35,
      p_m6_H: 0.88,
      p_m12_L: 0.35,
      p_m12_H: 1.13,
    },
    6196: {
      H: 1.7,
      L: 1.67,
      O: 1.7,
      C: 1.67,
      V: 442000,
      p_w1_L: 1.67,
      p_w1_H: 1.83,
      p_m1_L: 1.67,
      p_m1_H: 1.85,
      p_m3_L: 1.609,
      p_m3_H: 1.85,
      p_m6_L: 1.609,
      p_m6_H: 2.05,
      p_m12_L: 1.609,
      p_m12_H: 2.17,
    },
    6198: {
      H: 4.14,
      L: 4.05,
      O: 4.14,
      C: 4.08,
      V: 574000,
      p_w1_L: 3.92,
      p_w1_H: 4.26,
      p_m1_L: 3.85,
      p_m1_H: 4.26,
      p_m3_L: 3.78,
      p_m3_H: 4.32,
      p_m6_L: 3.78,
      p_m6_H: 4.67,
      p_m12_L: 3.78,
      p_m12_H: 5.03,
    },
    6199: {
      H: 2.55,
      L: 2.55,
      O: 2.55,
      C: 2.55,
      V: 0,
      p_w1_L: 2.39,
      p_w1_H: 2.6,
      p_m1_L: 2.39,
      p_m1_H: 2.65,
      p_m3_L: 2.39,
      p_m3_H: 2.77,
      p_m6_L: 2.38,
      p_m6_H: 2.92,
      p_m12_L: 2.2,
      p_m12_H: 2.92,
    },
    6600: {
      H: 8.98,
      L: 8.6,
      O: 8.62,
      C: 8.89,
      V: 122000,
      p_w1_L: 8.1,
      p_w1_H: 9.1,
      p_m1_L: 8.1,
      p_m1_H: 10.24,
      p_m3_L: 8.1,
      p_m3_H: 12.46,
      p_m6_L: 8.1,
      p_m6_H: 15.2,
      p_m12_L: 8.1,
      p_m12_H: 19.38,
    },
    6601: {
      H: 3.24,
      L: 3.16,
      O: 3.24,
      C: 3.18,
      V: 551000,
      p_w1_L: 3.03,
      p_w1_H: 3.38,
      p_m1_L: 3.03,
      p_m1_H: 4.32,
      p_m3_L: 3.03,
      p_m3_H: 4.87,
      p_m6_L: 3.03,
      p_m6_H: 9,
      p_m12_L: 3.03,
      p_m12_H: 9.36,
    },
    6606: {
      H: 26,
      L: 24.85,
      O: 25.5,
      C: 25.1,
      V: 500500,
      p_w1_L: 24.5,
      p_w1_H: 29.95,
      p_m1_L: 24.5,
      p_m1_H: 32.9,
      p_m3_L: 24.5,
      p_m3_H: 45.8,
      p_m6_L: 24.5,
      p_m6_H: 89.65,
      p_m12_L: 24.5,
      p_m12_H: 89.65,
    },
    6608: {
      H: 10.84,
      L: 10.26,
      O: 10.36,
      C: 10.58,
      V: 318500,
      p_w1_L: 9.6,
      p_w1_H: 10.84,
      p_m1_L: 9.6,
      p_m1_H: 12.3,
      p_m3_L: 9.6,
      p_m3_H: 15.54,
      p_m6_L: 9.6,
      p_m6_H: 21.9,
      p_m12_L: 9.6,
      p_m12_H: 29.45,
    },
    6609: {
      H: 93.2,
      L: 89.35,
      O: 92,
      C: 90.1,
      V: 15700,
      p_w1_L: 82.05,
      p_w1_H: 93.2,
      p_m1_L: 70.3,
      p_m1_H: 93.2,
      p_m3_L: 70.3,
      p_m3_H: 147.5,
      p_m6_L: 70.3,
      p_m6_H: 147.5,
      p_m12_L: 70.3,
      p_m12_H: 147.5,
    },
    6611: {
      H: 4.1,
      L: 3.79,
      O: 4.1,
      C: 3.85,
      V: 261000,
      p_w1_L: 3.59,
      p_w1_H: 4.5,
      p_m1_L: 3.59,
      p_m1_H: 4.5,
      p_m3_L: 3.59,
      p_m3_H: 4.72,
      p_m6_L: 3.59,
      p_m6_H: 5.88,
      p_m12_L: 3.59,
      p_m12_H: 5.88,
    },
    6616: {
      H: 7.93,
      L: 7.08,
      O: 7.25,
      C: 7.85,
      V: 2795000,
      p_w1_L: 7.06,
      p_w1_H: 8.57,
      p_m1_L: 5.68,
      p_m1_H: 8.57,
      p_m3_L: 4.6,
      p_m3_H: 8.57,
      p_m6_L: 3.2,
      p_m6_H: 8.57,
      p_m12_L: 3.2,
      p_m12_H: 8.57,
    },
    6618: {
      H: 68.5,
      L: 65.85,
      O: 66,
      C: 67.8,
      V: 7350890,
      p_w1_L: 60.5,
      p_w1_H: 69.3,
      p_m1_L: 60.5,
      p_m1_H: 79.35,
      p_m3_L: 60.5,
      p_m3_H: 80.95,
      p_m6_L: 58,
      p_m6_H: 114.2,
      p_m12_L: 58,
      p_m12_H: 198.5,
    },
    6622: {
      H: 5.76,
      L: 5.61,
      O: 5.73,
      C: 5.63,
      V: 173000,
      p_w1_L: 5.61,
      p_w1_H: 6.42,
      p_m1_L: 5.61,
      p_m1_H: 7.27,
      p_m3_L: 5.61,
      p_m3_H: 8.08,
      p_m6_L: 5.61,
      p_m6_H: 13.2,
      p_m12_L: 5.61,
      p_m12_H: 15.6,
    },
    6626: {
      H: 3.53,
      L: 3.43,
      O: 3.53,
      C: 3.44,
      V: 345315,
      p_w1_L: 3.33,
      p_w1_H: 3.55,
      p_m1_L: 3.31,
      p_m1_H: 3.77,
      p_m3_L: 3.18,
      p_m3_H: 4.09,
      p_m6_L: 3.18,
      p_m6_H: 5.73,
      p_m12_L: 3.18,
      p_m12_H: 5.73,
    },
    6628: {
      H: 7.81,
      L: 7.42,
      O: 7.6,
      C: 7.81,
      V: 85500,
      p_w1_L: 7.32,
      p_w1_H: 8.57,
      p_m1_L: 6.91,
      p_m1_H: 10.88,
      p_m3_L: 6.91,
      p_m3_H: 16.18,
      p_m6_L: 6.91,
      p_m6_H: 16.18,
      p_m12_L: 6.91,
      p_m12_H: 16.18,
    },
    6666: {
      H: 3.62,
      L: 3.43,
      O: 3.62,
      C: 3.45,
      V: 31570000,
      p_w1_L: 3.39,
      p_w1_H: 3.86,
      p_m1_L: 3.39,
      p_m1_H: 4.61,
      p_m3_L: 3.39,
      p_m3_H: 5.14,
      p_m6_L: 3.39,
      p_m6_H: 10.06,
      p_m12_L: 3.39,
      p_m12_H: 19.74,
    },
    6668: {
      H: 3.49,
      L: 3.37,
      O: 3.49,
      C: 3.44,
      V: 151000,
      p_w1_L: 3.21,
      p_w1_H: 3.58,
      p_m1_L: 3.21,
      p_m1_H: 3.88,
      p_m3_L: 3.21,
      p_m3_H: 4.27,
      p_m6_L: 3.21,
      p_m6_H: 6,
      p_m12_L: 3.21,
      p_m12_H: 6.15,
    },
    6669: {
      H: 11.62,
      L: 11.16,
      O: 11.5,
      C: 11.42,
      V: 673000,
      p_w1_L: 11.04,
      p_w1_H: 12.9,
      p_m1_L: 11.04,
      p_m1_H: 14.44,
      p_m3_L: 10.8,
      p_m3_H: 18.08,
      p_m6_L: 10.8,
      p_m6_H: 19.36,
      p_m12_L: 10.8,
      p_m12_H: 19.36,
    },
    6677: {
      H: 4.05,
      L: 3.92,
      O: 4.01,
      C: 3.93,
      V: 79500,
      p_w1_L: 3.7,
      p_w1_H: 4.05,
      p_m1_L: 3.32,
      p_m1_H: 4.15,
      p_m3_L: 3.32,
      p_m3_H: 5.09,
      p_m6_L: 3.32,
      p_m6_H: 6.88,
      p_m12_L: 3.32,
      p_m12_H: 6.88,
    },
    6690: {
      H: 32.4,
      L: 31.25,
      O: 32.4,
      C: 31.4,
      V: 8685340,
      p_w1_L: 29.25,
      p_w1_H: 32.4,
      p_m1_L: 26.55,
      p_m1_H: 32.4,
      p_m3_L: 25.25,
      p_m3_H: 32.4,
      p_m6_L: 24.75,
      p_m6_H: 32.5,
      p_m12_L: 22.6,
      p_m12_H: 38.45,
    },
    6699: {
      H: 315.6,
      L: 311.2,
      O: 314,
      C: 315,
      V: 66466,
      p_w1_L: 305,
      p_w1_H: 364.8,
      p_m1_L: 305,
      p_m1_H: 376,
      p_m3_L: 301.2,
      p_m3_H: 389.6,
      p_m6_L: 301.2,
      p_m6_H: 490,
      p_m12_L: 301.2,
      p_m12_H: 490,
    },
    6805: {
      H: 0.87,
      L: 0.87,
      O: 0.87,
      C: 0.87,
      V: 0,
      p_w1_L: 0.87,
      p_w1_H: 0.87,
      p_m1_L: 0.67,
      p_m1_H: 0.87,
      p_m3_L: 0.67,
      p_m3_H: 1.18,
      p_m6_L: 0.67,
      p_m6_H: 1.18,
      p_m12_L: 0.67,
      p_m12_H: 1.18,
    },
    6806: {
      H: 1.98,
      L: 1.95,
      O: 1.96,
      C: 1.95,
      V: 3151200,
      p_w1_L: 1.88,
      p_w1_H: 2.01,
      p_m1_L: 1.87,
      p_m1_H: 2.04,
      p_m3_L: 1.87,
      p_m3_H: 2.24,
      p_m6_L: 1.87,
      p_m6_H: 2.31,
      p_m12_L: 1.87,
      p_m12_H: 2.6,
    },
    6808: {
      H: 3.16,
      L: 3.07,
      O: 3.09,
      C: 3.1,
      V: 7751010,
      p_w1_L: 2.78,
      p_w1_H: 3.19,
      p_m1_L: 2.78,
      p_m1_H: 3.65,
      p_m3_L: 2.78,
      p_m3_H: 4.85,
      p_m6_L: 2.78,
      p_m6_H: 6.04,
      p_m12_L: 2.78,
      p_m12_H: 8.78,
    },
    6811: {
      H: 1.4,
      L: 1.37,
      O: 1.39,
      C: 1.38,
      V: 681000,
      p_w1_L: 1.35,
      p_w1_H: 1.47,
      p_m1_L: 1.35,
      p_m1_H: 1.62,
      p_m3_L: 1.35,
      p_m3_H: 1.75,
      p_m6_L: 1.35,
      p_m6_H: 2.27,
      p_m12_L: 1.35,
      p_m12_H: 2.45,
    },
    6812: {
      H: 0.43,
      L: 0.42,
      O: 0.425,
      C: 0.42,
      V: 1090000,
      p_w1_L: 0.415,
      p_w1_H: 0.495,
      p_m1_L: 0.415,
      p_m1_H: 0.495,
      p_m3_L: 0.415,
      p_m3_H: 0.495,
      p_m6_L: 0.39,
      p_m6_H: 0.61,
      p_m12_L: 0.36,
      p_m12_H: 0.61,
    },
    6816: {
      H: 0.63,
      L: 0.63,
      O: 0.63,
      C: 0.63,
      V: 0,
      p_w1_L: 0.56,
      p_w1_H: 0.63,
      p_m1_L: 0.54,
      p_m1_H: 0.68,
      p_m3_L: 0.51,
      p_m3_H: 0.68,
      p_m6_L: 0.5,
      p_m6_H: 0.73,
      p_m12_L: 0.5,
      p_m12_H: 1,
    },
    6818: {
      H: 2.77,
      L: 2.73,
      O: 2.77,
      C: 2.75,
      V: 6705000,
      p_w1_L: 2.68,
      p_w1_H: 2.8,
      p_m1_L: 2.66,
      p_m1_H: 2.81,
      p_m3_L: 2.63,
      p_m3_H: 2.96,
      p_m6_L: 2.63,
      p_m6_H: 3.26,
      p_m12_L: 2.63,
      p_m12_H: 3.54,
    },
    6819: {
      H: 7.39,
      L: 7.23,
      O: 7.33,
      C: 7.27,
      V: 433500,
      p_w1_L: 7.14,
      p_w1_H: 7.5,
      p_m1_L: 6.82,
      p_m1_H: 7.5,
      p_m3_L: 6.25,
      p_m3_H: 8.72,
      p_m6_L: 6.25,
      p_m6_H: 9.28,
      p_m12_L: 6,
      p_m12_H: 9.28,
    },
    6820: {
      H: 1.34,
      L: 1.29,
      O: 1.32,
      C: 1.32,
      V: 222000,
      p_w1_L: 1.27,
      p_w1_H: 1.34,
      p_m1_L: 1.15,
      p_m1_H: 1.38,
      p_m3_L: 1.11,
      p_m3_H: 1.54,
      p_m6_L: 1.11,
      p_m6_H: 2.41,
      p_m12_L: 1.11,
      p_m12_H: 3.1,
    },
    6822: {
      H: 1.31,
      L: 1.31,
      O: 1.31,
      C: 1.31,
      V: 0,
      p_w1_L: 1.27,
      p_w1_H: 1.34,
      p_m1_L: 1.27,
      p_m1_H: 1.36,
      p_m3_L: 1.22,
      p_m3_H: 1.45,
      p_m6_L: 1.22,
      p_m6_H: 1.5,
      p_m12_L: 1.22,
      p_m12_H: 1.65,
    },
    6823: {
      H: 10.7,
      L: 10.58,
      O: 10.62,
      C: 10.66,
      V: 9611530,
      p_w1_L: 10.5,
      p_w1_H: 10.72,
      p_m1_L: 10.5,
      p_m1_H: 10.76,
      p_m3_L: 10.46,
      p_m3_H: 10.8,
      p_m6_L: 10.34,
      p_m6_H: 10.9,
      p_m12_L: 9.8,
      p_m12_H: 11.54,
    },
    6826: {
      H: 52.95,
      L: 51.5,
      O: 52.6,
      C: 51.95,
      V: 354500,
      p_w1_L: 50.15,
      p_w1_H: 54.35,
      p_m1_L: 50.05,
      p_m1_H: 56.8,
      p_m3_L: 50.05,
      p_m3_H: 72.6,
      p_m6_L: 50.05,
      p_m6_H: 150,
      p_m12_L: 40.25,
      p_m12_H: 150,
    },
    6828: {
      H: 0.116,
      L: 0.116,
      O: 0.116,
      C: 0.116,
      V: 0,
      p_w1_L: 0.116,
      p_w1_H: 0.116,
      p_m1_L: 0.116,
      p_m1_H: 0.116,
      p_m3_L: 0.116,
      p_m3_H: 0.116,
      p_m6_L: 0.116,
      p_m6_H: 0.116,
      p_m12_L: 0.115,
      p_m12_H: 0.148,
    },
    6829: {
      H: 0.163,
      L: 0.163,
      O: 0.163,
      C: 0.163,
      V: 0,
      p_w1_L: 0.145,
      p_w1_H: 0.164,
      p_m1_L: 0.145,
      p_m1_H: 0.164,
      p_m3_L: 0.118,
      p_m3_H: 0.2,
      p_m6_L: 0.1,
      p_m6_H: 0.2,
      p_m12_L: 0.1,
      p_m12_H: 0.55,
    },
    6830: {
      H: 3.04,
      L: 2.87,
      O: 2.97,
      C: 3.03,
      V: 8528000,
      p_w1_L: 2.71,
      p_w1_H: 5.09,
      p_m1_L: 2.71,
      p_m1_H: 5.09,
      p_m3_L: 2.71,
      p_m3_H: 5.09,
      p_m6_L: 1.56,
      p_m6_H: 5.09,
      p_m12_L: 0.59,
      p_m12_H: 5.09,
    },
    6833: {
      H: 1.9,
      L: 1.64,
      O: 1.65,
      C: 1.78,
      V: 20150000,
      p_w1_L: 1.48,
      p_w1_H: 1.9,
      p_m1_L: 1.03,
      p_m1_H: 1.9,
      p_m3_L: 0.64,
      p_m3_H: 1.9,
      p_m6_L: 0.495,
      p_m6_H: 1.9,
      p_m12_L: 0.24,
      p_m12_H: 1.9,
    },
    6836: {
      H: 1.54,
      L: 1.51,
      O: 1.52,
      C: 1.54,
      V: 1484000,
      p_w1_L: 1.49,
      p_w1_H: 1.54,
      p_m1_L: 1.49,
      p_m1_H: 1.62,
      p_m3_L: 1.38,
      p_m3_H: 1.62,
      p_m6_L: 1.38,
      p_m6_H: 1.75,
      p_m12_L: 1.38,
      p_m12_H: 1.99,
    },
    6837: {
      H: 6.87,
      L: 6.8,
      O: 6.84,
      C: 6.87,
      V: 4963030,
      p_w1_L: 6.5,
      p_w1_H: 6.97,
      p_m1_L: 6.47,
      p_m1_H: 7.06,
      p_m3_L: 6.47,
      p_m3_H: 7.69,
      p_m6_L: 6.36,
      p_m6_H: 7.81,
      p_m12_L: 6.36,
      p_m12_H: 7.93,
    },
    6838: {
      H: 1.1,
      L: 1.1,
      O: 1.1,
      C: 1.1,
      V: 0,
      p_w1_L: 1.04,
      p_w1_H: 1.1,
      p_m1_L: 1.04,
      p_m1_H: 1.25,
      p_m3_L: 1.03,
      p_m3_H: 1.25,
      p_m6_L: 1.03,
      p_m6_H: 1.7,
      p_m12_L: 1.03,
      p_m12_H: 1.7,
    },
    6839: {
      H: 0.92,
      L: 0.9,
      O: 0.91,
      C: 0.91,
      V: 323000,
      p_w1_L: 0.89,
      p_w1_H: 0.97,
      p_m1_L: 0.89,
      p_m1_H: 0.99,
      p_m3_L: 0.89,
      p_m3_H: 1.24,
      p_m6_L: 0.89,
      p_m6_H: 1.34,
      p_m12_L: 0.89,
      p_m12_H: 1.34,
    },
    6855: {
      H: 29,
      L: 27.4,
      O: 28.05,
      C: 27.55,
      V: 834760,
      p_w1_L: 26.9,
      p_w1_H: 32.9,
      p_m1_L: 26.1,
      p_m1_H: 38,
      p_m3_L: 26.1,
      p_m3_H: 41.05,
      p_m6_L: 26.1,
      p_m6_H: 58.2,
      p_m12_L: 26.1,
      p_m12_H: 58.2,
    },
    6858: {
      H: 3.52,
      L: 3.38,
      O: 3.45,
      C: 3.52,
      V: 54500,
      p_w1_L: 3.21,
      p_w1_H: 3.52,
      p_m1_L: 3.2,
      p_m1_H: 3.96,
      p_m3_L: 3.08,
      p_m3_H: 3.96,
      p_m6_L: 3.04,
      p_m6_H: 5.7,
      p_m12_L: 3.04,
      p_m12_H: 6.9,
    },
    6860: {
      H: 0.325,
      L: 0.28,
      O: 0.29,
      C: 0.315,
      V: 13702000,
      p_w1_L: 0.193,
      p_w1_H: 0.33,
      p_m1_L: 0.178,
      p_m1_H: 0.33,
      p_m3_L: 0.178,
      p_m3_H: 0.33,
      p_m6_L: 0.16,
      p_m6_H: 0.385,
      p_m12_L: 0.16,
      p_m12_H: 0.84,
    },
    6862: {
      H: 18.74,
      L: 17.76,
      O: 18.52,
      C: 18.18,
      V: 10772100,
      p_w1_L: 16.74,
      p_w1_H: 18.74,
      p_m1_L: 16.74,
      p_m1_H: 23.45,
      p_m3_L: 16.74,
      p_m3_H: 33.35,
      p_m6_L: 16.74,
      p_m6_H: 49.8,
      p_m12_L: 16.74,
      p_m12_H: 85.8,
    },
    6865: {
      H: 35,
      L: 32.9,
      O: 33.35,
      C: 34.8,
      V: 11901500,
      p_w1_L: 32.15,
      p_w1_H: 35.15,
      p_m1_L: 32.15,
      p_m1_H: 39.4,
      p_m3_L: 32.15,
      p_m3_H: 43.3,
      p_m6_L: 21,
      p_m6_H: 45.4,
      p_m12_L: 17.2,
      p_m12_H: 45.4,
    },
    6866: {
      H: 0.335,
      L: 0.325,
      O: 0.335,
      C: 0.33,
      V: 554000,
      p_w1_L: 0.325,
      p_w1_H: 0.375,
      p_m1_L: 0.325,
      p_m1_H: 0.395,
      p_m3_L: 0.325,
      p_m3_H: 0.43,
      p_m6_L: 0.325,
      p_m6_H: 0.43,
      p_m12_L: 0.3,
      p_m12_H: 0.57,
    },
    6868: {
      H: 5.55,
      L: 5.45,
      O: 5.5,
      C: 5.5,
      V: 35000,
      p_w1_L: 5.4,
      p_w1_H: 5.65,
      p_m1_L: 5.4,
      p_m1_H: 5.65,
      p_m3_L: 5.05,
      p_m3_H: 5.75,
      p_m6_L: 5.05,
      p_m6_H: 5.75,
      p_m12_L: 4.53,
      p_m12_H: 6.08,
    },
    6869: {
      H: 11.94,
      L: 11.5,
      O: 11.8,
      C: 11.9,
      V: 941000,
      p_w1_L: 11.28,
      p_w1_H: 13.5,
      p_m1_L: 11.28,
      p_m1_H: 14.14,
      p_m3_L: 11.28,
      p_m3_H: 15.88,
      p_m6_L: 9,
      p_m6_H: 15.88,
      p_m12_L: 9,
      p_m12_H: 15.88,
    },
    6877: {
      H: 0.13,
      L: 0.13,
      O: 0.13,
      C: 0.13,
      V: 0,
      p_w1_L: 0.114,
      p_w1_H: 0.13,
      p_m1_L: 0.111,
      p_m1_H: 0.14,
      p_m3_L: 0.111,
      p_m3_H: 0.188,
      p_m6_L: 0.111,
      p_m6_H: 0.2,
      p_m12_L: 0.111,
      p_m12_H: 0.224,
    },
    6878: {
      H: 1.65,
      L: 1.53,
      O: 1.54,
      C: 1.6,
      V: 32008000,
      p_w1_L: 1.46,
      p_w1_H: 1.65,
      p_m1_L: 0.7,
      p_m1_H: 2.2,
      p_m3_L: 0.7,
      p_m3_H: 2.8,
      p_m6_L: 0.7,
      p_m6_H: 3.84,
      p_m12_L: 0.67,
      p_m12_H: 3.84,
    },
    6880: {
      H: 0.238,
      L: 0.238,
      O: 0.238,
      C: 0.238,
      V: 0,
      p_w1_L: 0.238,
      p_w1_H: 0.238,
      p_m1_L: 0.238,
      p_m1_H: 0.238,
      p_m3_L: 0.22,
      p_m3_H: 0.25,
      p_m6_L: 0.22,
      p_m6_H: 0.345,
      p_m12_L: 0.22,
      p_m12_H: 0.68,
    },
    6881: {
      H: 4.54,
      L: 4.47,
      O: 4.51,
      C: 4.5,
      V: 8063000,
      p_w1_L: 4.25,
      p_w1_H: 4.6,
      p_m1_L: 4.18,
      p_m1_H: 4.6,
      p_m3_L: 4.18,
      p_m3_H: 4.78,
      p_m6_L: 3.87,
      p_m6_H: 4.83,
      p_m12_L: 3.87,
      p_m12_H: 5.56,
    },
    6882: {
      H: 0.415,
      L: 0.41,
      O: 0.415,
      C: 0.41,
      V: 22000,
      p_w1_L: 0.405,
      p_w1_H: 0.43,
      p_m1_L: 0.405,
      p_m1_H: 0.495,
      p_m3_L: 0.385,
      p_m3_H: 0.495,
      p_m6_L: 0.385,
      p_m6_H: 0.58,
      p_m12_L: 0.37,
      p_m12_H: 0.75,
    },
    6885: {
      H: 3.8,
      L: 3.74,
      O: 3.8,
      C: 3.74,
      V: 73000,
      p_w1_L: 3.71,
      p_w1_H: 3.95,
      p_m1_L: 3.68,
      p_m1_H: 4.18,
      p_m3_L: 3.63,
      p_m3_H: 5.6,
      p_m6_L: 3.31,
      p_m6_H: 5.6,
      p_m12_L: 2.68,
      p_m12_H: 5.6,
    },
    6886: {
      H: 12.44,
      L: 12.22,
      O: 12.34,
      C: 12.34,
      V: 2309200,
      p_w1_L: 11.16,
      p_w1_H: 12.62,
      p_m1_L: 11.1,
      p_m1_H: 12.62,
      p_m3_L: 11.1,
      p_m3_H: 12.96,
      p_m6_L: 10.02,
      p_m6_H: 12.96,
      p_m12_L: 10.02,
      p_m12_H: 14.06,
    },
    6888: {
      H: 0.305,
      L: 0.285,
      O: 0.3,
      C: 0.305,
      V: 722000,
      p_w1_L: 0.28,
      p_w1_H: 0.305,
      p_m1_L: 0.275,
      p_m1_H: 0.345,
      p_m3_L: 0.265,
      p_m3_H: 0.345,
      p_m6_L: 0.25,
      p_m6_H: 0.345,
      p_m12_L: 0.125,
      p_m12_H: 0.45,
    },
    6889: {
      H: 6.82,
      L: 6.57,
      O: 6.64,
      C: 6.82,
      V: 102400,
      p_w1_L: 6.57,
      p_w1_H: 7.14,
      p_m1_L: 6.57,
      p_m1_H: 7.5,
      p_m3_L: 6.57,
      p_m3_H: 8.2,
      p_m6_L: 6.57,
      p_m6_H: 8.2,
      p_m12_L: 6.57,
      p_m12_H: 8.53,
    },
    6890: {
      H: 0.64,
      L: 0.64,
      O: 0.64,
      C: 0.64,
      V: 0,
      p_w1_L: 0.61,
      p_w1_H: 0.73,
      p_m1_L: 0.61,
      p_m1_H: 0.73,
      p_m3_L: 0.61,
      p_m3_H: 0.87,
      p_m6_L: 0.61,
      p_m6_H: 0.87,
      p_m12_L: 0.61,
      p_m12_H: 1.35,
    },
    6893: {
      H: 0.355,
      L: 0.355,
      O: 0.355,
      C: 0.35,
      V: 2000,
      p_w1_L: 0.33,
      p_w1_H: 0.355,
      p_m1_L: 0.315,
      p_m1_H: 0.38,
      p_m3_L: 0.3,
      p_m3_H: 0.38,
      p_m6_L: 0.3,
      p_m6_H: 0.41,
      p_m12_L: 0.295,
      p_m12_H: 0.75,
    },
    6896: {
      H: 1.63,
      L: 1.57,
      O: 1.61,
      C: 1.63,
      V: 912000,
      p_w1_L: 1.49,
      p_w1_H: 2.19,
      p_m1_L: 1.49,
      p_m1_H: 2.74,
      p_m3_L: 1.49,
      p_m3_H: 2.74,
      p_m6_L: 1.49,
      p_m6_H: 2.74,
      p_m12_L: 1.3,
      p_m12_H: 2.74,
    },
    6898: {
      H: 0.375,
      L: 0.375,
      O: 0.375,
      C: 0.375,
      V: 0,
      p_w1_L: 0.35,
      p_w1_H: 0.38,
      p_m1_L: 0.35,
      p_m1_H: 0.385,
      p_m3_L: 0.34,
      p_m3_H: 0.385,
      p_m6_L: 0.33,
      p_m6_H: 0.51,
      p_m12_L: 0.33,
      p_m12_H: 0.58,
    },
    6899: {
      H: 0.44,
      L: 0.44,
      O: 0.44,
      C: 0.44,
      V: 0,
      p_w1_L: 0.415,
      p_w1_H: 0.54,
      p_m1_L: 0.415,
      p_m1_H: 0.6,
      p_m3_L: 0.32,
      p_m3_H: 0.6,
      p_m6_L: 0.32,
      p_m6_H: 0.81,
      p_m12_L: 0.32,
      p_m12_H: 1.09,
    },
    6900: {
      H: 2.22,
      L: 2.14,
      O: 2.18,
      C: 2.18,
      V: 982000,
      p_w1_L: 2.13,
      p_w1_H: 2.33,
      p_m1_L: 2.13,
      p_m1_H: 2.5,
      p_m3_L: 2.13,
      p_m3_H: 2.7,
      p_m6_L: 2.13,
      p_m6_H: 2.73,
      p_m12_L: 2.13,
      p_m12_H: 2.81,
    },
    6908: {
      H: 6.98,
      L: 6.85,
      O: 6.98,
      C: 6.87,
      V: 738000,
      p_w1_L: 6.75,
      p_w1_H: 7.16,
      p_m1_L: 6.62,
      p_m1_H: 7.75,
      p_m3_L: 6.62,
      p_m3_H: 7.75,
      p_m6_L: 6.62,
      p_m6_H: 8.99,
      p_m12_L: 0.52,
      p_m12_H: 8.99,
    },
    6909: {
      H: 6,
      L: 6,
      O: 6,
      C: 5.99,
      V: 31000,
      p_w1_L: 5.84,
      p_w1_H: 6.2,
      p_m1_L: 5.6,
      p_m1_H: 6.5,
      p_m3_L: 5.2,
      p_m3_H: 7.7,
      p_m6_L: 5.05,
      p_m6_H: 9.7,
      p_m12_L: 5.05,
      p_m12_H: 9.7,
    },
    6913: {
      H: 1.18,
      L: 1.16,
      O: 1.18,
      C: 1.18,
      V: 54000,
      p_w1_L: 1.1,
      p_w1_H: 1.3,
      p_m1_L: 1.1,
      p_m1_H: 1.4,
      p_m3_L: 1.05,
      p_m3_H: 1.47,
      p_m6_L: 1.04,
      p_m6_H: 1.59,
      p_m12_L: 1.04,
      p_m12_H: 1.59,
    },
    6918: {
      H: 1.23,
      L: 1.23,
      O: 1.23,
      C: 1.23,
      V: 0,
      p_w1_L: 1.18,
      p_w1_H: 1.25,
      p_m1_L: 1.11,
      p_m1_H: 1.54,
      p_m3_L: 1.11,
      p_m3_H: 1.54,
      p_m6_L: 1.11,
      p_m6_H: 1.81,
      p_m12_L: 0.99,
      p_m12_H: 2.64,
    },
    6919: {
      H: 8.66,
      L: 8.24,
      O: 8.53,
      C: 8.4,
      V: 164700,
      p_w1_L: 8.06,
      p_w1_H: 8.96,
      p_m1_L: 7.61,
      p_m1_H: 9.92,
      p_m3_L: 7.61,
      p_m3_H: 10.22,
      p_m6_L: 7.61,
      p_m6_H: 23.45,
      p_m12_L: 7.61,
      p_m12_H: 35.65,
    },
    6928: {
      H: 3.79,
      L: 3.61,
      O: 3.77,
      C: 3.65,
      V: 416000,
      p_w1_L: 3.37,
      p_w1_H: 3.8,
      p_m1_L: 3.34,
      p_m1_H: 4.01,
      p_m3_L: 3.16,
      p_m3_H: 4.64,
      p_m6_L: 2.42,
      p_m6_H: 5.8,
      p_m12_L: 0.74,
      p_m12_H: 5.8,
    },
    6933: {
      H: 5.05,
      L: 4.33,
      O: 4.33,
      C: 4.8,
      V: 956000,
      p_w1_L: 3.99,
      p_w1_H: 5.05,
      p_m1_L: 1.84,
      p_m1_H: 5.05,
      p_m3_L: 1.64,
      p_m3_H: 5.05,
      p_m6_L: 0.78,
      p_m6_H: 5.05,
      p_m12_L: 0.78,
      p_m12_H: 5.05,
    },
    6939: {
      H: 1.27,
      L: 1.25,
      O: 1.26,
      C: 1.25,
      V: 1106000,
      p_w1_L: 1.24,
      p_w1_H: 1.3,
      p_m1_L: 1.22,
      p_m1_H: 1.36,
      p_m3_L: 1.22,
      p_m3_H: 1.55,
      p_m6_L: 1.22,
      p_m6_H: 2,
      p_m12_L: 1.22,
      p_m12_H: 2,
    },
    6958: {
      H: 4.78,
      L: 4.65,
      O: 4.76,
      C: 4.78,
      V: 1793000,
      p_w1_L: 4.46,
      p_w1_H: 4.8,
      p_m1_L: 4.2,
      p_m1_H: 4.82,
      p_m3_L: 4.2,
      p_m3_H: 5.23,
      p_m6_L: 4.2,
      p_m6_H: 5.29,
      p_m12_L: 3.51,
      p_m12_H: 5.63,
    },
    6966: {
      H: 0.5,
      L: 0.5,
      O: 0.5,
      C: 0.5,
      V: 0,
      p_w1_L: 0.445,
      p_w1_H: 0.51,
      p_m1_L: 0.42,
      p_m1_H: 0.51,
      p_m3_L: 0.42,
      p_m3_H: 0.51,
      p_m6_L: 0.4,
      p_m6_H: 0.51,
      p_m12_L: 0.4,
      p_m12_H: 0.59,
    },
    6968: {
      H: 4.25,
      L: 4.12,
      O: 4.25,
      C: 4.15,
      V: 1142000,
      p_w1_L: 4.07,
      p_w1_H: 4.52,
      p_m1_L: 3.94,
      p_m1_H: 4.52,
      p_m3_L: 3.94,
      p_m3_H: 4.71,
      p_m6_L: 3.94,
      p_m6_H: 4.78,
      p_m12_L: 3.94,
      p_m12_H: 6.06,
    },
    6969: {
      H: 46.4,
      L: 44.35,
      O: 44.45,
      C: 44.65,
      V: 14815400,
      p_w1_L: 41.9,
      p_w1_H: 52.15,
      p_m1_L: 33,
      p_m1_H: 53.5,
      p_m3_L: 32.8,
      p_m3_H: 53.5,
      p_m6_L: 31.2,
      p_m6_H: 53.5,
      p_m12_L: 31.2,
      p_m12_H: 90,
    },
    6978: {
      H: 15.7,
      L: 15.2,
      O: 15.7,
      C: 15.22,
      V: 620000,
      p_w1_L: 14.74,
      p_w1_H: 15.8,
      p_m1_L: 14.1,
      p_m1_H: 16,
      p_m3_L: 14.1,
      p_m3_H: 16.76,
      p_m6_L: 14.1,
      p_m6_H: 20.85,
      p_m12_L: 9.49,
      p_m12_H: 22.45,
    },
    6988: {
      H: 2.23,
      L: 2.14,
      O: 2.16,
      C: 2.17,
      V: 6189000,
      p_w1_L: 2.08,
      p_w1_H: 2.23,
      p_m1_L: 2.08,
      p_m1_H: 2.39,
      p_m3_L: 2.07,
      p_m3_H: 2.47,
      p_m6_L: 2.07,
      p_m6_H: 3.2,
      p_m12_L: 2.07,
      p_m12_H: 4.36,
    },
    6989: {
      H: 4.68,
      L: 4.51,
      O: 4.51,
      C: 4.66,
      V: 1866000,
      p_w1_L: 4.3,
      p_w1_H: 4.75,
      p_m1_L: 4.3,
      p_m1_H: 5.2,
      p_m3_L: 4.3,
      p_m3_H: 6.62,
      p_m6_L: 4.3,
      p_m6_H: 9.83,
      p_m12_L: 4.3,
      p_m12_H: 11.3,
    },
    6993: {
      H: 7.3,
      L: 7.04,
      O: 7.2,
      C: 7.3,
      V: 6982500,
      p_w1_L: 7.04,
      p_w1_H: 7.46,
      p_m1_L: 6.82,
      p_m1_H: 7.76,
      p_m3_L: 6.19,
      p_m3_H: 7.76,
      p_m6_L: 5.75,
      p_m6_H: 11.76,
      p_m12_L: 5.75,
      p_m12_H: 19.16,
    },
    6996: {
      H: 10.54,
      L: 10.4,
      O: 10.46,
      C: 10.4,
      V: 575000,
      p_w1_L: 10.4,
      p_w1_H: 11.92,
      p_m1_L: 10.32,
      p_m1_H: 13.06,
      p_m3_L: 10.02,
      p_m3_H: 14.68,
      p_m6_L: 10.02,
      p_m6_H: 20.3,
      p_m12_L: 10.02,
      p_m12_H: 22.5,
    },
    6998: {
      H: 8.2,
      L: 7.7,
      O: 7.7,
      C: 8.16,
      V: 704000,
      p_w1_L: 7.3,
      p_w1_H: 8.32,
      p_m1_L: 7.3,
      p_m1_H: 9.17,
      p_m3_L: 7.3,
      p_m3_H: 14.34,
      p_m6_L: 7.3,
      p_m6_H: 19,
      p_m12_L: 7.3,
      p_m12_H: 23.7,
    },
    6999: {
      H: 4.39,
      L: 3.89,
      O: 3.9,
      C: 4.25,
      V: 172000,
      p_w1_L: 3,
      p_w1_H: 4.39,
      p_m1_L: 3,
      p_m1_H: 5.2,
      p_m3_L: 3,
      p_m3_H: 7.49,
      p_m6_L: 3,
      p_m6_H: 7.79,
      p_m12_L: 3,
      p_m12_H: 9.23,
    },
    7200: {
      H: 7.265,
      L: 7.155,
      O: 7.265,
      C: 7.2,
      V: 31254400,
      p_w1_L: 6.81,
      p_w1_H: 7.265,
      p_m1_L: 6.7,
      p_m1_H: 8.315,
      p_m3_L: 6.7,
      p_m3_H: 8.87,
      p_m6_L: 6.7,
      p_m6_H: 10.82,
      p_m12_L: 6.7,
      p_m12_H: 12.01,
    },
    7300: {
      H: 5.13,
      L: 5.08,
      O: 5.08,
      C: 5.125,
      V: 2809800,
      p_w1_L: 5.08,
      p_w1_H: 5.27,
      p_m1_L: 4.794,
      p_m1_H: 5.315,
      p_m3_L: 4.712,
      p_m3_H: 5.315,
      p_m6_L: 4.35,
      p_m6_H: 5.315,
      p_m12_L: 4.236,
      p_m12_H: 456.2,
    },
    7322: {
      H: 5.895,
      L: 5.87,
      O: 5.895,
      C: 5.89,
      V: 38100,
      p_w1_L: 5.87,
      p_w1_H: 6.105,
      p_m1_L: 5.86,
      p_m1_H: 6.105,
      p_m3_L: 5.86,
      p_m3_H: 6.465,
      p_m6_L: 0.855,
      p_m6_H: 6.67,
      p_m12_L: 0.855,
      p_m12_H: 7.845,
    },
    7331: {
      H: 3.46,
      L: 3.42,
      O: 3.46,
      C: 3.428,
      V: 81600,
      p_w1_L: 3.42,
      p_w1_H: 3.574,
      p_m1_L: 3.39,
      p_m1_H: 3.574,
      p_m3_L: 3.39,
      p_m3_H: 3.916,
      p_m6_L: 3.39,
      p_m6_H: 4.144,
      p_m12_L: 0.537,
      p_m12_H: 4.782,
    },
    7333: {
      H: 6.885,
      L: 6.775,
      O: 6.885,
      C: 6.78,
      V: 230300,
      p_w1_L: 6.775,
      p_w1_H: 7.045,
      p_m1_L: 6.775,
      p_m1_H: 7.15,
      p_m3_L: 6.73,
      p_m3_H: 7.5,
      p_m6_L: 5.638,
      p_m6_H: 7.5,
      p_m12_L: 5.638,
      p_m12_H: 7.5,
    },
    7336: {
      H: 5.17,
      L: 5.16,
      O: 5.17,
      C: 5.16,
      V: 9100,
      p_w1_L: 5.16,
      p_w1_H: 5.3,
      p_m1_L: 4.868,
      p_m1_H: 5.3,
      p_m3_L: 4.798,
      p_m3_H: 5.31,
      p_m6_L: 4.464,
      p_m6_H: 5.31,
      p_m12_L: 4.376,
      p_m12_H: 5.31,
    },
    7345: {
      H: 7.175,
      L: 7.15,
      O: 7.175,
      C: 7.17,
      V: 5200,
      p_w1_L: 7.15,
      p_w1_H: 7.845,
      p_m1_L: 7.15,
      p_m1_H: 7.845,
      p_m3_L: 7.15,
      p_m3_H: 7.845,
      p_m6_L: 7.15,
      p_m6_H: 7.845,
      p_m12_L: 7.15,
      p_m12_H: 7.845,
    },
    7348: {
      H: 8.17,
      L: 8.16,
      O: 8.16,
      C: 8.135,
      V: 1500,
      p_w1_L: 8.16,
      p_w1_H: 8.515,
      p_m1_L: 8.16,
      p_m1_H: 8.535,
      p_m3_L: 8.06,
      p_m3_H: 9.055,
      p_m6_L: 0.973,
      p_m6_H: 9.095,
      p_m12_L: 0.948,
      p_m12_H: 9.095,
    },
    7362: {
      H: 5.98,
      L: 5.98,
      O: 5.98,
      C: 5.98,
      V: 3800,
      p_w1_L: 5.98,
      p_w1_H: 6.095,
      p_m1_L: 5.575,
      p_m1_H: 6.095,
      p_m3_L: 5.48,
      p_m3_H: 6.17,
      p_m6_L: 4.972,
      p_m6_H: 6.17,
      p_m12_L: 4.61,
      p_m12_H: 6.17,
    },
    7373: {
      H: 5.38,
      L: 5.36,
      O: 5.38,
      C: 5.35,
      V: 5700,
      p_w1_L: 5.36,
      p_w1_H: 5.55,
      p_m1_L: 5.36,
      p_m1_H: 5.6,
      p_m3_L: 5.36,
      p_m3_H: 5.95,
      p_m6_L: 5.36,
      p_m6_H: 6.21,
      p_m12_L: 5.21,
      p_m12_H: 6.55,
    },
    7374: {
      H: 7.71,
      L: 7.71,
      O: 7.71,
      C: 7.71,
      V: 0,
      p_w1_L: 7.69,
      p_w1_H: 7.775,
      p_m1_L: 7.38,
      p_m1_H: 7.775,
      p_m3_L: 7.38,
      p_m3_H: 7.8,
      p_m6_L: 7.38,
      p_m6_H: 7.8,
      p_m12_L: 7.38,
      p_m12_H: 7.8,
    },
    7500: {
      H: 5.505,
      L: 5.425,
      O: 5.43,
      C: 5.475,
      V: 31204900,
      p_w1_L: 5.425,
      p_w1_H: 5.8,
      p_m1_L: 4.804,
      p_m1_H: 5.9,
      p_m3_L: 4.66,
      p_m3_H: 5.9,
      p_m6_L: 4.016,
      p_m6_H: 5.9,
      p_m12_L: 3.858,
      p_m12_H: 5.9,
    },
    7522: {
      H: 1.618,
      L: 1.606,
      O: 1.618,
      C: 1.614,
      V: 4688000,
      p_w1_L: 1.606,
      p_w1_H: 1.76,
      p_m1_L: 1.578,
      p_m1_H: 1.76,
      p_m3_L: 1.578,
      p_m3_H: 2.124,
      p_m6_L: 1.578,
      p_m6_H: 2.364,
      p_m12_L: 1.578,
      p_m12_H: 3.182,
    },
    7552: {
      H: 9.095,
      L: 8.885,
      O: 8.99,
      C: 9.055,
      V: 9314200,
      p_w1_L: 8.69,
      p_w1_H: 9.965,
      p_m1_L: 7.295,
      p_m1_H: 9.965,
      p_m3_L: 7.24,
      p_m3_H: 10.08,
      p_m6_L: 5.815,
      p_m6_H: 10.85,
      p_m12_L: 5.645,
      p_m12_H: 10.85,
    },
    7568: {
      H: 1.11,
      L: 1.102,
      O: 1.108,
      C: 1.108,
      V: 56204600,
      p_w1_L: 1.102,
      p_w1_H: 1.208,
      p_m1_L: 1.082,
      p_m1_H: 1.208,
      p_m3_L: 1.082,
      p_m3_H: 1.458,
      p_m6_L: 1.082,
      p_m6_H: 1.624,
      p_m12_L: 1.082,
      p_m12_H: 2.176,
    },
    7588: {
      H: 5.48,
      L: 5.43,
      O: 5.455,
      C: 5.45,
      V: 217700,
      p_w1_L: 5.43,
      p_w1_H: 5.8,
      p_m1_L: 4.708,
      p_m1_H: 5.825,
      p_m3_L: 4.578,
      p_m3_H: 5.83,
      p_m6_L: 3.768,
      p_m6_H: 5.83,
      p_m12_L: 3.248,
      p_m12_H: 5.83,
    },
    8001: {
      H: 0.75,
      L: 0.66,
      O: 0.7,
      C: 0.72,
      V: 13296000,
      p_w1_L: 0.355,
      p_w1_H: 0.8,
      p_m1_L: 0.063,
      p_m1_H: 0.8,
      p_m3_L: 0.063,
      p_m3_H: 0.8,
      p_m6_L: 0.063,
      p_m6_H: 0.8,
      p_m12_L: 0.063,
      p_m12_H: 0.8,
    },
    8003: {
      H: 0.83,
      L: 0.78,
      O: 0.83,
      C: 0.74,
      V: 12000,
      p_w1_L: 0.65,
      p_w1_H: 0.83,
      p_m1_L: 0.56,
      p_m1_H: 0.83,
      p_m3_L: 0.52,
      p_m3_H: 0.83,
      p_m6_L: 0.12,
      p_m6_H: 0.85,
      p_m12_L: 0.12,
      p_m12_H: 5,
    },
    8005: {
      H: 0.41,
      L: 0.36,
      O: 0.38,
      C: 0.395,
      V: 1674000,
      p_w1_L: 0.355,
      p_w1_H: 0.49,
      p_m1_L: 0.355,
      p_m1_H: 0.5,
      p_m3_L: 0.33,
      p_m3_H: 0.5,
      p_m6_L: 0.33,
      p_m6_H: 0.51,
      p_m12_L: 0.195,
      p_m12_H: 0.69,
    },
    8006: {
      H: 0.073,
      L: 0.073,
      O: 0.073,
      C: 0.073,
      V: 0,
      p_w1_L: 0.07,
      p_w1_H: 0.08,
      p_m1_L: 0.07,
      p_m1_H: 0.081,
      p_m3_L: 0.066,
      p_m3_H: 0.086,
      p_m6_L: 0.066,
      p_m6_H: 0.105,
      p_m12_L: 0.066,
      p_m12_H: 0.115,
    },
    8007: {
      H: 0.248,
      L: 0.225,
      O: 0.246,
      C: 0.247,
      V: 5711000,
      p_w1_L: 0.22,
      p_w1_H: 0.285,
      p_m1_L: 0.22,
      p_m1_H: 0.44,
      p_m3_L: 0.199,
      p_m3_H: 0.46,
      p_m6_L: 0.17,
      p_m6_H: 0.46,
      p_m12_L: 0.17,
      p_m12_H: 0.46,
    },
    8009: {
      H: 0.74,
      L: 0.74,
      O: 0.74,
      C: 0.74,
      V: 0,
      p_w1_L: 0.72,
      p_w1_H: 0.75,
      p_m1_L: 0.72,
      p_m1_H: 0.89,
      p_m3_L: 0.7,
      p_m3_H: 0.98,
      p_m6_L: 0.7,
      p_m6_H: 1.01,
      p_m12_L: 0.28,
      p_m12_H: 1.04,
    },
    8011: {
      H: 0.17,
      L: 0.16,
      O: 0.161,
      C: 0.17,
      V: 760000,
      p_w1_L: 0.15,
      p_w1_H: 0.175,
      p_m1_L: 0.08,
      p_m1_H: 0.176,
      p_m3_L: 0.056,
      p_m3_H: 0.176,
      p_m6_L: 0.051,
      p_m6_H: 0.176,
      p_m12_L: 0.051,
      p_m12_H: 0.176,
    },
    8013: {
      H: 0.075,
      L: 0.07,
      O: 0.073,
      C: 0.075,
      V: 460000,
      p_w1_L: 0.07,
      p_w1_H: 0.09,
      p_m1_L: 0.05,
      p_m1_H: 0.099,
      p_m3_L: 0.05,
      p_m3_H: 0.099,
      p_m6_L: 0.045,
      p_m6_H: 0.099,
      p_m12_L: 0.044,
      p_m12_H: 0.099,
    },
    8017: {
      H: 0.8,
      L: 0.74,
      O: 0.74,
      C: 0.79,
      V: 3600000,
      p_w1_L: 0.74,
      p_w1_H: 0.82,
      p_m1_L: 0.7,
      p_m1_H: 0.86,
      p_m3_L: 0.465,
      p_m3_H: 0.86,
      p_m6_L: 0.455,
      p_m6_H: 0.86,
      p_m12_L: 0.21,
      p_m12_H: 0.99,
    },
    8018: {
      H: 0.245,
      L: 0.245,
      O: 0.245,
      C: 0.245,
      V: 0,
      p_w1_L: 0.226,
      p_w1_H: 0.265,
      p_m1_L: 0.22,
      p_m1_H: 0.275,
      p_m3_L: 0.22,
      p_m3_H: 0.3,
      p_m6_L: 0.22,
      p_m6_H: 0.37,
      p_m12_L: 0.22,
      p_m12_H: 0.55,
    },
    8019: {
      H: 0.295,
      L: 0.28,
      O: 0.28,
      C: 0.315,
      V: 120300,
      p_w1_L: 0.28,
      p_w1_H: 0.305,
      p_m1_L: 0.21,
      p_m1_H: 0.36,
      p_m3_L: 0.2,
      p_m3_H: 0.36,
      p_m6_L: 0.2,
      p_m6_H: 0.36,
      p_m12_L: 0.16,
      p_m12_H: 0.36,
    },
    8020: {
      H: 0.043,
      L: 0.043,
      O: 0.043,
      C: 0.045,
      V: 10000,
      p_w1_L: 0.043,
      p_w1_H: 0.049,
      p_m1_L: 0.043,
      p_m1_H: 0.063,
      p_m3_L: 0.043,
      p_m3_H: 0.1,
      p_m6_L: 0.043,
      p_m6_H: 0.108,
      p_m12_L: 0.024,
      p_m12_H: 0.108,
    },
    8021: {
      H: 0.045,
      L: 0.04,
      O: 0.042,
      C: 0.045,
      V: 2220000,
      p_w1_L: 0.04,
      p_w1_H: 0.048,
      p_m1_L: 0.04,
      p_m1_H: 0.054,
      p_m3_L: 0.033,
      p_m3_H: 0.058,
      p_m6_L: 0.033,
      p_m6_H: 0.06,
      p_m12_L: 0.025,
      p_m12_H: 0.066,
    },
    8022: {
      H: 0.01,
      L: 0.01,
      O: 0.01,
      C: 0.01,
      V: 0,
      p_w1_L: 0.01,
      p_w1_H: 0.01,
      p_m1_L: 0.01,
      p_m1_H: 0.01,
      p_m3_L: 0.01,
      p_m3_H: 0.01,
      p_m6_L: 0.01,
      p_m6_H: 0.011,
      p_m12_L: 0.01,
      p_m12_H: 0.022,
    },
    8023: {
      H: 0.42,
      L: 0.405,
      O: 0.405,
      C: 0.42,
      V: 222000,
      p_w1_L: 0.405,
      p_w1_H: 0.425,
      p_m1_L: 0.4,
      p_m1_H: 0.43,
      p_m3_L: 0.4,
      p_m3_H: 0.46,
      p_m6_L: 0.38,
      p_m6_H: 0.46,
      p_m12_L: 0.275,
      p_m12_H: 0.495,
    },
    8025: {
      H: 0.62,
      L: 0.53,
      O: 0.6,
      C: 0.61,
      V: 972000,
      p_w1_L: 0.53,
      p_w1_H: 0.67,
      p_m1_L: 0.5,
      p_m1_H: 0.93,
      p_m3_L: 0.285,
      p_m3_H: 0.95,
      p_m6_L: 0.27,
      p_m6_H: 1.68,
      p_m12_L: 0.27,
      p_m12_H: 1.94,
    },
    8026: {
      H: 0.43,
      L: 0.355,
      O: 0.36,
      C: 0.41,
      V: 200000,
      p_w1_L: 0.355,
      p_w1_H: 0.45,
      p_m1_L: 0.355,
      p_m1_H: 0.53,
      p_m3_L: 0.355,
      p_m3_H: 0.59,
      p_m6_L: 0.355,
      p_m6_H: 0.7,
      p_m12_L: 0.355,
      p_m12_H: 0.75,
    },
    8027: {
      H: 0.44,
      L: 0.44,
      O: 0.44,
      C: 0.44,
      V: 0,
      p_w1_L: 0.44,
      p_w1_H: 0.44,
      p_m1_L: 0.44,
      p_m1_H: 0.48,
      p_m3_L: 0.44,
      p_m3_H: 0.7,
      p_m6_L: 0.44,
      p_m6_H: 0.75,
      p_m12_L: 0.1,
      p_m12_H: 0.8,
    },
    8028: {
      H: 0.037,
      L: 0.036,
      O: 0.036,
      C: 0.037,
      V: 300000,
      p_w1_L: 0.033,
      p_w1_H: 0.037,
      p_m1_L: 0.033,
      p_m1_H: 0.039,
      p_m3_L: 0.033,
      p_m3_H: 0.049,
      p_m6_L: 0.033,
      p_m6_H: 0.055,
      p_m12_L: 0.031,
      p_m12_H: 0.063,
    },
    8029: {
      H: 0.58,
      L: 0.57,
      O: 0.57,
      C: 0.56,
      V: 45000,
      p_w1_L: 0.49,
      p_w1_H: 0.58,
      p_m1_L: 0.48,
      p_m1_H: 1.04,
      p_m3_L: 0.48,
      p_m3_H: 1.1,
      p_m6_L: 0.42,
      p_m6_H: 1.1,
      p_m12_L: 0.152,
      p_m12_H: 2.16,
    },
    8030: {
      H: 0.018,
      L: 0.018,
      O: 0.018,
      C: 0.018,
      V: 0,
      p_w1_L: 0.018,
      p_w1_H: 0.018,
      p_m1_L: 0.018,
      p_m1_H: 0.018,
      p_m3_L: 0.018,
      p_m3_H: 0.018,
      p_m6_L: 0.018,
      p_m6_H: 0.018,
      p_m12_L: 0.018,
      p_m12_H: 0.055,
    },
    8031: {
      H: 0.3,
      L: 0.3,
      O: 0.3,
      C: 0.3,
      V: 0,
      p_w1_L: 0.29,
      p_w1_H: 0.3,
      p_m1_L: 0.24,
      p_m1_H: 0.3,
      p_m3_L: 0.213,
      p_m3_H: 0.3,
      p_m6_L: 0.205,
      p_m6_H: 0.32,
      p_m12_L: 0.2,
      p_m12_H: 0.4,
    },
    8032: {
      H: 1.62,
      L: 1.59,
      O: 1.6,
      C: 1.59,
      V: 7492450,
      p_w1_L: 1.55,
      p_w1_H: 1.67,
      p_m1_L: 1.45,
      p_m1_H: 1.84,
      p_m3_L: 1.37,
      p_m3_H: 1.84,
      p_m6_L: 0.81,
      p_m6_H: 1.97,
      p_m12_L: 0.47,
      p_m12_H: 1.97,
    },
    8033: {
      H: 0.14,
      L: 0.14,
      O: 0.14,
      C: 0.148,
      V: 10000,
      p_w1_L: 0.14,
      p_w1_H: 0.16,
      p_m1_L: 0.14,
      p_m1_H: 0.16,
      p_m3_L: 0.126,
      p_m3_H: 0.164,
      p_m6_L: 0.125,
      p_m6_H: 0.179,
      p_m12_L: 0.102,
      p_m12_H: 0.179,
    },
    8035: {
      H: 0.225,
      L: 0.225,
      O: 0.225,
      C: 0.225,
      V: 0,
      p_w1_L: 0.204,
      p_w1_H: 0.225,
      p_m1_L: 0.2,
      p_m1_H: 0.225,
      p_m3_L: 0.2,
      p_m3_H: 0.25,
      p_m6_L: 0.2,
      p_m6_H: 0.3,
      p_m12_L: 0.131,
      p_m12_H: 0.315,
    },
    8036: {
      H: 0.09,
      L: 0.09,
      O: 0.09,
      C: 0.09,
      V: 0,
      p_w1_L: 0.087,
      p_w1_H: 0.1,
      p_m1_L: 0.087,
      p_m1_H: 0.107,
      p_m3_L: 0.087,
      p_m3_H: 0.108,
      p_m6_L: 0.087,
      p_m6_H: 0.116,
      p_m12_L: 0.08,
      p_m12_H: 0.13,
    },
    8037: {
      H: 1.46,
      L: 1.35,
      O: 1.35,
      C: 1.41,
      V: 265000,
      p_w1_L: 1.31,
      p_w1_H: 2.06,
      p_m1_L: 1,
      p_m1_H: 2.64,
      p_m3_L: 1,
      p_m3_H: 2.64,
      p_m6_L: 1,
      p_m6_H: 2.64,
      p_m12_L: 0.99,
      p_m12_H: 2.64,
    },
    8039: {
      H: 0.51,
      L: 0.51,
      O: 0.51,
      C: 0.51,
      V: 0,
      p_w1_L: 0.51,
      p_w1_H: 0.55,
      p_m1_L: 0.355,
      p_m1_H: 0.6,
      p_m3_L: 0.35,
      p_m3_H: 0.89,
      p_m6_L: 0.165,
      p_m6_H: 0.89,
      p_m12_L: 0.165,
      p_m12_H: 0.92,
    },
    8040: {
      H: 0.43,
      L: 0.425,
      O: 0.425,
      C: 0.43,
      V: 45000,
      p_w1_L: 0.425,
      p_w1_H: 0.5,
      p_m1_L: 0.385,
      p_m1_H: 0.52,
      p_m3_L: 0.167,
      p_m3_H: 0.52,
      p_m6_L: 0.107,
      p_m6_H: 0.52,
      p_m12_L: 0.083,
      p_m12_H: 0.52,
    },
    8041: {
      H: 0.445,
      L: 0.43,
      O: 0.44,
      C: 0.445,
      V: 480000,
      p_w1_L: 0.43,
      p_w1_H: 0.455,
      p_m1_L: 0.39,
      p_m1_H: 0.455,
      p_m3_L: 0.325,
      p_m3_H: 0.455,
      p_m6_L: 0.237,
      p_m6_H: 0.455,
      p_m12_L: 0.237,
      p_m12_H: 0.55,
    },
    8042: {
      H: 0.077,
      L: 0.077,
      O: 0.077,
      C: 0.077,
      V: 0,
      p_w1_L: 0.071,
      p_w1_H: 0.079,
      p_m1_L: 0.058,
      p_m1_H: 0.084,
      p_m3_L: 0.044,
      p_m3_H: 0.084,
      p_m6_L: 0.037,
      p_m6_H: 0.084,
      p_m12_L: 0.032,
      p_m12_H: 0.084,
    },
    8043: {
      H: 0.233,
      L: 0.226,
      O: 0.233,
      C: 0.228,
      V: 15000,
      p_w1_L: 0.225,
      p_w1_H: 0.265,
      p_m1_L: 0.203,
      p_m1_H: 0.265,
      p_m3_L: 0.193,
      p_m3_H: 0.3,
      p_m6_L: 0.138,
      p_m6_H: 0.3,
      p_m12_L: 0.131,
      p_m12_H: 0.3,
    },
    8045: {
      H: 0.042,
      L: 0.042,
      O: 0.042,
      C: 0.042,
      V: 0,
      p_w1_L: 0.041,
      p_w1_H: 0.043,
      p_m1_L: 0.04,
      p_m1_H: 0.052,
      p_m3_L: 0.04,
      p_m3_H: 0.054,
      p_m6_L: 0.04,
      p_m6_H: 0.057,
      p_m12_L: 0.037,
      p_m12_H: 0.11,
    },
    8047: {
      H: 0.048,
      L: 0.043,
      O: 0.047,
      C: 0.048,
      V: 18372000,
      p_w1_L: 0.043,
      p_w1_H: 0.059,
      p_m1_L: 0.043,
      p_m1_H: 0.067,
      p_m3_L: 0.042,
      p_m3_H: 0.09,
      p_m6_L: 0.042,
      p_m6_H: 0.167,
      p_m12_L: 0.042,
      p_m12_H: 0.219,
    },
    8048: {
      H: 0.049,
      L: 0.043,
      O: 0.043,
      C: 0.048,
      V: 1354000,
      p_w1_L: 0.037,
      p_w1_H: 0.049,
      p_m1_L: 0.035,
      p_m1_H: 0.05,
      p_m3_L: 0.035,
      p_m3_H: 0.052,
      p_m6_L: 0.035,
      p_m6_H: 0.062,
      p_m12_L: 0.034,
      p_m12_H: 0.1,
    },
    8049: {
      H: 1.24,
      L: 1.17,
      O: 1.2,
      C: 1.17,
      V: 109000,
      p_w1_L: 1.17,
      p_w1_H: 1.24,
      p_m1_L: 0.99,
      p_m1_H: 1.3,
      p_m3_L: 0.99,
      p_m3_H: 1.3,
      p_m6_L: 0.99,
      p_m6_H: 1.3,
      p_m12_L: 0.99,
      p_m12_H: 1.35,
    },
    8050: {
      H: 0.47,
      L: 0.47,
      O: 0.47,
      C: 0.47,
      V: 0,
      p_w1_L: 0.315,
      p_w1_H: 0.475,
      p_m1_L: 0.295,
      p_m1_H: 0.475,
      p_m3_L: 0.295,
      p_m3_H: 0.68,
      p_m6_L: 0.295,
      p_m6_H: 1.02,
      p_m12_L: 0.295,
      p_m12_H: 1.58,
    },
    8051: {
      H: 4.98,
      L: 4.98,
      O: 4.98,
      C: 4.98,
      V: 0,
      p_w1_L: 4.98,
      p_w1_H: 5.06,
      p_m1_L: 4.3,
      p_m1_H: 5.38,
      p_m3_L: 3.89,
      p_m3_H: 5.38,
      p_m6_L: 3.89,
      p_m6_H: 6.6,
      p_m12_L: 3.89,
      p_m12_H: 7.41,
    },
    8052: {
      H: 0.043,
      L: 0.04,
      O: 0.043,
      C: 0.042,
      V: 1650000,
      p_w1_L: 0.036,
      p_w1_H: 0.046,
      p_m1_L: 0.035,
      p_m1_H: 0.046,
      p_m3_L: 0.035,
      p_m3_H: 0.06,
      p_m6_L: 0.035,
      p_m6_H: 0.07,
      p_m12_L: 0.025,
      p_m12_H: 0.07,
    },
    8053: {
      H: 0.51,
      L: 0.51,
      O: 0.51,
      C: 0.51,
      V: 5020000,
      p_w1_L: 0.5,
      p_w1_H: 0.53,
      p_m1_L: 0.48,
      p_m1_H: 0.57,
      p_m3_L: 0.465,
      p_m3_H: 0.65,
      p_m6_L: 0.465,
      p_m6_H: 0.76,
      p_m12_L: 0.465,
      p_m12_H: 0.9,
    },
    8055: {
      H: 0.039,
      L: 0.037,
      O: 0.039,
      C: 0.038,
      V: 3736000,
      p_w1_L: 0.037,
      p_w1_H: 0.042,
      p_m1_L: 0.037,
      p_m1_H: 0.05,
      p_m3_L: 0.032,
      p_m3_H: 0.06,
      p_m6_L: 0.028,
      p_m6_H: 0.06,
      p_m12_L: 0.016,
      p_m12_H: 0.06,
    },
    8056: {
      H: 0.175,
      L: 0.165,
      O: 0.165,
      C: 0.175,
      V: 275000,
      p_w1_L: 0.112,
      p_w1_H: 0.175,
      p_m1_L: 0.081,
      p_m1_H: 0.175,
      p_m3_L: 0.07,
      p_m3_H: 0.175,
      p_m6_L: 0.06,
      p_m6_H: 0.175,
      p_m12_L: 0.043,
      p_m12_H: 0.175,
    },
    8057: {
      H: 0.124,
      L: 0.124,
      O: 0.124,
      C: 0.124,
      V: 0,
      p_w1_L: 0.118,
      p_w1_H: 0.134,
      p_m1_L: 0.112,
      p_m1_H: 0.138,
      p_m3_L: 0.112,
      p_m3_H: 0.138,
      p_m6_L: 0.112,
      p_m6_H: 0.169,
      p_m12_L: 0.068,
      p_m12_H: 0.38,
    },
    8059: {
      H: 0.034,
      L: 0.034,
      O: 0.034,
      C: 0.034,
      V: 0,
      p_w1_L: 0.034,
      p_w1_H: 0.034,
      p_m1_L: 0.034,
      p_m1_H: 0.043,
      p_m3_L: 0.033,
      p_m3_H: 0.043,
      p_m6_L: 0.033,
      p_m6_H: 0.077,
      p_m12_L: 0.033,
      p_m12_H: 0.086,
    },
    8060: {
      H: 0.2,
      L: 0.2,
      O: 0.2,
      C: 0.2,
      V: 0,
      p_w1_L: 0.2,
      p_w1_H: 0.202,
      p_m1_L: 0.2,
      p_m1_H: 0.24,
      p_m3_L: 0.142,
      p_m3_H: 0.45,
      p_m6_L: 0.135,
      p_m6_H: 0.45,
      p_m12_L: 0.116,
      p_m12_H: 0.45,
    },
    8062: {
      H: 0.265,
      L: 0.265,
      O: 0.265,
      C: 0.265,
      V: 0,
      p_w1_L: 0.238,
      p_w1_H: 0.27,
      p_m1_L: 0.207,
      p_m1_H: 0.375,
      p_m3_L: 0.207,
      p_m3_H: 0.45,
      p_m6_L: 0.207,
      p_m6_H: 0.455,
      p_m12_L: 0.207,
      p_m12_H: 0.51,
    },
    8063: {
      H: 0.105,
      L: 0.105,
      O: 0.105,
      C: 0.105,
      V: 10402,
      p_w1_L: 0.101,
      p_w1_H: 0.122,
      p_m1_L: 0.101,
      p_m1_H: 0.128,
      p_m3_L: 0.1,
      p_m3_H: 0.147,
      p_m6_L: 0.1,
      p_m6_H: 0.188,
      p_m12_L: 0.1,
      p_m12_H: 2.1,
    },
    8065: {
      H: 0.345,
      L: 0.345,
      O: 0.345,
      C: 0.345,
      V: 0,
      p_w1_L: 0.32,
      p_w1_H: 0.35,
      p_m1_L: 0.315,
      p_m1_H: 0.38,
      p_m3_L: 0.315,
      p_m3_H: 0.4,
      p_m6_L: 0.31,
      p_m6_H: 0.415,
      p_m12_L: 0.198,
      p_m12_H: 0.42,
    },
    8066: {
      H: 0.041,
      L: 0.038,
      O: 0.04,
      C: 0.041,
      V: 2155000,
      p_w1_L: 0.038,
      p_w1_H: 0.042,
      p_m1_L: 0.038,
      p_m1_H: 0.068,
      p_m3_L: 0.038,
      p_m3_H: 0.068,
      p_m6_L: 0.029,
      p_m6_H: 0.084,
      p_m12_L: 0.029,
      p_m12_H: 0.084,
    },
    8067: {
      H: 0.7,
      L: 0.7,
      O: 0.7,
      C: 0.7,
      V: 0,
      p_w1_L: 0.7,
      p_w1_H: 0.73,
      p_m1_L: 0.7,
      p_m1_H: 1.83,
      p_m3_L: 0.62,
      p_m3_H: 1.83,
      p_m6_L: 0.62,
      p_m6_H: 1.83,
      p_m12_L: 0.62,
      p_m12_H: 1.83,
    },
    8069: {
      H: 0.305,
      L: 0.305,
      O: 0.305,
      C: 0.305,
      V: 25000,
      p_w1_L: 0.27,
      p_w1_H: 0.305,
      p_m1_L: 0.27,
      p_m1_H: 0.35,
      p_m3_L: 0.27,
      p_m3_H: 0.4,
      p_m6_L: 0.26,
      p_m6_H: 0.4,
      p_m12_L: 0.236,
      p_m12_H: 0.68,
    },
    8070: {
      H: 0.21,
      L: 0.21,
      O: 0.21,
      C: 0.21,
      V: 0,
      p_w1_L: 0.21,
      p_w1_H: 0.21,
      p_m1_L: 0.2,
      p_m1_H: 0.24,
      p_m3_L: 0.188,
      p_m3_H: 0.27,
      p_m6_L: 0.188,
      p_m6_H: 0.27,
      p_m12_L: 0.128,
      p_m12_H: 0.325,
    },
    8071: {
      H: 0.033,
      L: 0.029,
      O: 0.032,
      C: 0.032,
      V: 215000,
      p_w1_L: 0.029,
      p_w1_H: 0.038,
      p_m1_L: 0.029,
      p_m1_H: 0.046,
      p_m3_L: 0.029,
      p_m3_H: 0.05,
      p_m6_L: 0.029,
      p_m6_H: 0.067,
      p_m12_L: 0.026,
      p_m12_H: 0.075,
    },
    8072: {
      H: 0.395,
      L: 0.38,
      O: 0.395,
      C: 0.38,
      V: 6883750,
      p_w1_L: 0.36,
      p_w1_H: 0.405,
      p_m1_L: 0.36,
      p_m1_H: 0.55,
      p_m3_L: 0.181,
      p_m3_H: 0.55,
      p_m6_L: 0.101,
      p_m6_H: 0.55,
      p_m12_L: 0.101,
      p_m12_H: 0.55,
    },
    8073: {
      H: 0.435,
      L: 0.435,
      O: 0.435,
      C: 0.435,
      V: 0,
      p_w1_L: 0.42,
      p_w1_H: 0.47,
      p_m1_L: 0.39,
      p_m1_H: 0.49,
      p_m3_L: 0.39,
      p_m3_H: 0.59,
      p_m6_L: 0.25,
      p_m6_H: 0.68,
      p_m12_L: 0.25,
      p_m12_H: 0.68,
    },
    8075: {
      H: 1.2,
      L: 1.2,
      O: 1.2,
      C: 1.2,
      V: 12000,
      p_w1_L: 1.2,
      p_w1_H: 1.34,
      p_m1_L: 1.2,
      p_m1_H: 1.45,
      p_m3_L: 1.2,
      p_m3_H: 1.74,
      p_m6_L: 1.2,
      p_m6_H: 2.03,
      p_m12_L: 0.24,
      p_m12_H: 2.44,
    },
    8076: {
      H: 0.071,
      L: 0.071,
      O: 0.071,
      C: 0.071,
      V: 0,
      p_w1_L: 0.065,
      p_w1_H: 0.075,
      p_m1_L: 0.064,
      p_m1_H: 0.084,
      p_m3_L: 0.057,
      p_m3_H: 0.105,
      p_m6_L: 0.057,
      p_m6_H: 0.105,
      p_m12_L: 0.054,
      p_m12_H: 0.105,
    },
    8078: {
      H: 0.048,
      L: 0.046,
      O: 0.048,
      C: 0.046,
      V: 47200,
      p_w1_L: 0.043,
      p_w1_H: 0.048,
      p_m1_L: 0.043,
      p_m1_H: 0.068,
      p_m3_L: 0.041,
      p_m3_H: 0.068,
      p_m6_L: 0.041,
      p_m6_H: 0.155,
      p_m12_L: 0.041,
      p_m12_H: 0.155,
    },
    8079: {
      H: 0.15,
      L: 0.15,
      O: 0.15,
      C: 0.15,
      V: 65000,
      p_w1_L: 0.142,
      p_w1_H: 0.15,
      p_m1_L: 0.142,
      p_m1_H: 0.172,
      p_m3_L: 0.121,
      p_m3_H: 0.208,
      p_m6_L: 0.121,
      p_m6_H: 0.213,
      p_m12_L: 0.121,
      p_m12_H: 0.243,
    },
    8080: {
      H: 0.73,
      L: 0.73,
      O: 0.73,
      C: 0.73,
      V: 0,
      p_w1_L: 0.69,
      p_w1_H: 0.75,
      p_m1_L: 0.69,
      p_m1_H: 0.77,
      p_m3_L: 0.69,
      p_m3_H: 0.81,
      p_m6_L: 0.69,
      p_m6_H: 1.18,
      p_m12_L: 0.63,
      p_m12_H: 1.18,
    },
    8081: {
      H: 0.108,
      L: 0.1,
      O: 0.1,
      C: 0.105,
      V: 43920,
      p_w1_L: 0.081,
      p_w1_H: 0.11,
      p_m1_L: 0.08,
      p_m1_H: 0.14,
      p_m3_L: 0.08,
      p_m3_H: 0.178,
      p_m6_L: 0.07,
      p_m6_H: 0.178,
      p_m12_L: 0.05,
      p_m12_H: 0.178,
    },
    8082: {
      H: 0.085,
      L: 0.082,
      O: 0.085,
      C: 0.082,
      V: 180000,
      p_w1_L: 0.076,
      p_w1_H: 0.109,
      p_m1_L: 0.059,
      p_m1_H: 0.185,
      p_m3_L: 0.059,
      p_m3_H: 0.3,
      p_m6_L: 0.059,
      p_m6_H: 0.325,
      p_m12_L: 0.059,
      p_m12_H: 0.39,
    },
    8083: {
      H: 0.71,
      L: 0.66,
      O: 0.66,
      C: 0.69,
      V: 40920000,
      p_w1_L: 0.6,
      p_w1_H: 0.74,
      p_m1_L: 0.6,
      p_m1_H: 0.94,
      p_m3_L: 0.6,
      p_m3_H: 1.17,
      p_m6_L: 0.6,
      p_m6_H: 1.68,
      p_m12_L: 0.6,
      p_m12_H: 4.52,
    },
    8086: {
      H: 0.88,
      L: 0.71,
      O: 0.74,
      C: 0.88,
      V: 2224260,
      p_w1_L: 0.39,
      p_w1_H: 0.88,
      p_m1_L: 0.074,
      p_m1_H: 0.88,
      p_m3_L: 0.06,
      p_m3_H: 0.88,
      p_m6_L: 0.06,
      p_m6_H: 0.88,
      p_m12_L: 0.04,
      p_m12_H: 0.88,
    },
    8087: {
      H: 0.12,
      L: 0.12,
      O: 0.12,
      C: 0.12,
      V: 0,
      p_w1_L: 0.101,
      p_w1_H: 0.12,
      p_m1_L: 0.101,
      p_m1_H: 0.138,
      p_m3_L: 0.082,
      p_m3_H: 0.138,
      p_m6_L: 0.066,
      p_m6_H: 0.138,
      p_m12_L: 0.039,
      p_m12_H: 0.138,
    },
    8088: {
      H: 0.06,
      L: 0.06,
      O: 0.06,
      C: 0.06,
      V: 0,
      p_w1_L: 0.06,
      p_w1_H: 0.06,
      p_m1_L: 0.06,
      p_m1_H: 0.06,
      p_m3_L: 0.06,
      p_m3_H: 0.06,
      p_m6_L: 0.035,
      p_m6_H: 0.079,
      p_m12_L: 0.03,
      p_m12_H: 0.079,
    },
    8089: {
      H: 0.033,
      L: 0.033,
      O: 0.033,
      C: 0.033,
      V: 0,
      p_w1_L: 0.033,
      p_w1_H: 0.033,
      p_m1_L: 0.033,
      p_m1_H: 0.033,
      p_m3_L: 0.033,
      p_m3_H: 0.033,
      p_m6_L: 0.033,
      p_m6_H: 0.033,
      p_m12_L: 0.031,
      p_m12_H: 0.093,
    },
    8091: {
      H: 0.192,
      L: 0.185,
      O: 0.19,
      C: 0.188,
      V: 9670000,
      p_w1_L: 0.168,
      p_w1_H: 0.192,
      p_m1_L: 0.146,
      p_m1_H: 0.225,
      p_m3_L: 0.085,
      p_m3_H: 0.225,
      p_m6_L: 0.069,
      p_m6_H: 0.225,
      p_m12_L: 0.023,
      p_m12_H: 0.25,
    },
    8092: {
      H: 0.045,
      L: 0.045,
      O: 0.045,
      C: 0.045,
      V: 8000,
      p_w1_L: 0.042,
      p_w1_H: 0.048,
      p_m1_L: 0.04,
      p_m1_H: 0.05,
      p_m3_L: 0.04,
      p_m3_H: 0.06,
      p_m6_L: 0.04,
      p_m6_H: 0.074,
      p_m12_L: 0.025,
      p_m12_H: 0.074,
    },
    8093: {
      H: 0.54,
      L: 0.54,
      O: 0.54,
      C: 0.54,
      V: 0,
      p_w1_L: 0.54,
      p_w1_H: 0.54,
      p_m1_L: 0.54,
      p_m1_H: 0.54,
      p_m3_L: 0.54,
      p_m3_H: 0.56,
      p_m6_L: 0.45,
      p_m6_H: 0.79,
      p_m12_L: 0.12,
      p_m12_H: 0.8,
    },
    8095: {
      H: 0.58,
      L: 0.54,
      O: 0.57,
      C: 0.58,
      V: 44000,
      p_w1_L: 0.53,
      p_w1_H: 0.58,
      p_m1_L: 0.47,
      p_m1_H: 0.58,
      p_m3_L: 0.455,
      p_m3_H: 0.58,
      p_m6_L: 0.45,
      p_m6_H: 0.58,
      p_m12_L: 0.45,
      p_m12_H: 0.71,
    },
    8096: {
      H: 0.415,
      L: 0.39,
      O: 0.39,
      C: 0.415,
      V: 1600000,
      p_w1_L: 0.34,
      p_w1_H: 0.415,
      p_m1_L: 0.34,
      p_m1_H: 0.46,
      p_m3_L: 0.2,
      p_m3_H: 0.46,
      p_m6_L: 0.1,
      p_m6_H: 0.46,
      p_m12_L: 0.082,
      p_m12_H: 0.46,
    },
    8098: {
      H: 0.072,
      L: 0.072,
      O: 0.072,
      C: 0.072,
      V: 0,
      p_w1_L: 0.07,
      p_w1_H: 0.072,
      p_m1_L: 0.068,
      p_m1_H: 0.1,
      p_m3_L: 0.067,
      p_m3_H: 0.1,
      p_m6_L: 0.061,
      p_m6_H: 0.1,
      p_m12_L: 0.055,
      p_m12_H: 0.1,
    },
    8100: {
      H: 0.39,
      L: 0.36,
      O: 0.365,
      C: 0.39,
      V: 44000,
      p_w1_L: 0.35,
      p_w1_H: 0.395,
      p_m1_L: 0.345,
      p_m1_H: 0.4,
      p_m3_L: 0.33,
      p_m3_H: 0.475,
      p_m6_L: 0.32,
      p_m6_H: 0.59,
      p_m12_L: 0.305,
      p_m12_H: 0.69,
    },
    8101: {
      H: 0.241,
      L: 0.241,
      O: 0.241,
      C: 0.241,
      V: 0,
      p_w1_L: 0.241,
      p_w1_H: 0.241,
      p_m1_L: 0.241,
      p_m1_H: 0.241,
      p_m3_L: 0.241,
      p_m3_H: 0.241,
      p_m6_L: 0.24,
      p_m6_H: 0.415,
      p_m12_L: 0.24,
      p_m12_H: 0.52,
    },
    8103: {
      H: 1.55,
      L: 1.5,
      O: 1.5,
      C: 1.54,
      V: 82500,
      p_w1_L: 1.5,
      p_w1_H: 1.7,
      p_m1_L: 1.5,
      p_m1_H: 2.29,
      p_m3_L: 1.5,
      p_m3_H: 2.61,
      p_m6_L: 1.5,
      p_m6_H: 2.7,
      p_m12_L: 1.5,
      p_m12_H: 4.21,
    },
    8106: {
      H: 0.19,
      L: 0.19,
      O: 0.19,
      C: 0.19,
      V: 0,
      p_w1_L: 0.19,
      p_w1_H: 0.19,
      p_m1_L: 0.19,
      p_m1_H: 0.19,
      p_m3_L: 0.185,
      p_m3_H: 0.2,
      p_m6_L: 0.167,
      p_m6_H: 0.2,
      p_m12_L: 0.099,
      p_m12_H: 0.3,
    },
    8107: {
      H: 0.25,
      L: 0.25,
      O: 0.25,
      C: 0.25,
      V: 0,
      p_w1_L: 0.25,
      p_w1_H: 0.255,
      p_m1_L: 0.25,
      p_m1_H: 0.29,
      p_m3_L: 0.25,
      p_m3_H: 0.305,
      p_m6_L: 0.25,
      p_m6_H: 0.35,
      p_m12_L: 0.25,
      p_m12_H: 0.44,
    },
    8109: {
      H: 0.2,
      L: 0.19,
      O: 0.195,
      C: 0.2,
      V: 13950000,
      p_w1_L: 0.154,
      p_w1_H: 0.232,
      p_m1_L: 0.154,
      p_m1_H: 0.26,
      p_m3_L: 0.154,
      p_m3_H: 0.295,
      p_m6_L: 0.154,
      p_m6_H: 0.31,
      p_m12_L: 0.154,
      p_m12_H: 0.36,
    },
    8111: {
      H: 0.255,
      L: 0.255,
      O: 0.255,
      C: 0.255,
      V: 0,
      p_w1_L: 0.255,
      p_w1_H: 0.365,
      p_m1_L: 0.25,
      p_m1_H: 0.375,
      p_m3_L: 0.25,
      p_m3_H: 0.375,
      p_m6_L: 0.079,
      p_m6_H: 0.45,
      p_m12_L: 0.07,
      p_m12_H: 0.465,
    },
    8112: {
      H: 0.54,
      L: 0.465,
      O: 0.465,
      C: 0.5,
      V: 4740000,
      p_w1_L: 0.42,
      p_w1_H: 0.54,
      p_m1_L: 0.295,
      p_m1_H: 0.7,
      p_m3_L: 0.25,
      p_m3_H: 0.7,
      p_m6_L: 0.25,
      p_m6_H: 0.7,
      p_m12_L: 0.17,
      p_m12_H: 0.7,
    },
    8113: {
      H: 0.365,
      L: 0.36,
      O: 0.36,
      C: 0.36,
      V: 110000,
      p_w1_L: 0.345,
      p_w1_H: 0.375,
      p_m1_L: 0.345,
      p_m1_H: 0.39,
      p_m3_L: 0.345,
      p_m3_H: 0.43,
      p_m6_L: 0.345,
      p_m6_H: 0.495,
      p_m12_L: 0.305,
      p_m12_H: 0.495,
    },
    8115: {
      H: 0.65,
      L: 0.65,
      O: 0.65,
      C: 0.65,
      V: 0,
      p_w1_L: 0.65,
      p_w1_H: 0.65,
      p_m1_L: 0.58,
      p_m1_H: 0.65,
      p_m3_L: 0.455,
      p_m3_H: 0.68,
      p_m6_L: 0.455,
      p_m6_H: 0.72,
      p_m12_L: 0.455,
      p_m12_H: 0.72,
    },
    8117: {
      H: 0.15,
      L: 0.13,
      O: 0.13,
      C: 0.13,
      V: 110000,
      p_w1_L: 0.13,
      p_w1_H: 0.15,
      p_m1_L: 0.129,
      p_m1_H: 0.15,
      p_m3_L: 0.129,
      p_m3_H: 0.157,
      p_m6_L: 0.129,
      p_m6_H: 0.164,
      p_m12_L: 0.129,
      p_m12_H: 0.38,
    },
    8118: {
      H: 0.202,
      L: 0.202,
      O: 0.202,
      C: 0.202,
      V: 0,
      p_w1_L: 0.198,
      p_w1_H: 0.22,
      p_m1_L: 0.163,
      p_m1_H: 0.26,
      p_m3_L: 0.163,
      p_m3_H: 0.27,
      p_m6_L: 0.163,
      p_m6_H: 0.425,
      p_m12_L: 0.163,
      p_m12_H: 0.48,
    },
    8120: {
      H: 0.14,
      L: 0.121,
      O: 0.121,
      C: 0.122,
      V: 531000,
      p_w1_L: 0.107,
      p_w1_H: 0.14,
      p_m1_L: 0.107,
      p_m1_H: 0.14,
      p_m3_L: 0.107,
      p_m3_H: 0.14,
      p_m6_L: 0.104,
      p_m6_H: 0.229,
      p_m12_L: 0.074,
      p_m12_H: 0.283,
    },
    8121: {
      H: 0.24,
      L: 0.23,
      O: 0.23,
      C: 0.238,
      V: 1281000,
      p_w1_L: 0.212,
      p_w1_H: 0.249,
      p_m1_L: 0.212,
      p_m1_H: 0.365,
      p_m3_L: 0.212,
      p_m3_H: 0.53,
      p_m6_L: 0.142,
      p_m6_H: 1.44,
      p_m12_L: 0.018,
      p_m12_H: 1.44,
    },
    8123: {
      H: 0.014,
      L: 0.013,
      O: 0.014,
      C: 0.014,
      V: 1854000,
      p_w1_L: 0.013,
      p_w1_H: 0.014,
      p_m1_L: 0.012,
      p_m1_H: 0.023,
      p_m3_L: 0.012,
      p_m3_H: 0.023,
      p_m6_L: 0.012,
      p_m6_H: 0.028,
      p_m12_L: 0.01,
      p_m12_H: 0.03,
    },
    8125: {
      H: 0.202,
      L: 0.202,
      O: 0.202,
      C: 0.202,
      V: 8000,
      p_w1_L: 0.19,
      p_w1_H: 0.215,
      p_m1_L: 0.153,
      p_m1_H: 0.218,
      p_m3_L: 0.153,
      p_m3_H: 0.219,
      p_m6_L: 0.135,
      p_m6_H: 0.305,
      p_m12_L: 0.135,
      p_m12_H: 1.64,
    },
    8126: {
      H: 0.255,
      L: 0.255,
      O: 0.255,
      C: 0.255,
      V: 0,
      p_w1_L: 0.255,
      p_w1_H: 0.255,
      p_m1_L: 0.255,
      p_m1_H: 0.27,
      p_m3_L: 0.21,
      p_m3_H: 0.27,
      p_m6_L: 0.192,
      p_m6_H: 0.27,
      p_m12_L: 0.192,
      p_m12_H: 0.28,
    },
    8128: {
      H: 0.09,
      L: 0.09,
      O: 0.09,
      C: 0.086,
      V: 16000,
      p_w1_L: 0.09,
      p_w1_H: 0.091,
      p_m1_L: 0.084,
      p_m1_H: 0.102,
      p_m3_L: 0.072,
      p_m3_H: 0.106,
      p_m6_L: 0.054,
      p_m6_H: 0.106,
      p_m12_L: 0.054,
      p_m12_H: 0.129,
    },
    8130: {
      H: 0.042,
      L: 0.042,
      O: 0.042,
      C: 0.042,
      V: 0,
      p_w1_L: 0.038,
      p_w1_H: 0.042,
      p_m1_L: 0.038,
      p_m1_H: 0.043,
      p_m3_L: 0.038,
      p_m3_H: 0.056,
      p_m6_L: 0.038,
      p_m6_H: 0.069,
      p_m12_L: 0.038,
      p_m12_H: 0.108,
    },
    8131: {
      H: 0.058,
      L: 0.058,
      O: 0.058,
      C: 0.058,
      V: 0,
      p_w1_L: 0.058,
      p_w1_H: 0.058,
      p_m1_L: 0.058,
      p_m1_H: 0.058,
      p_m3_L: 0.051,
      p_m3_H: 0.08,
      p_m6_L: 0.026,
      p_m6_H: 0.085,
      p_m12_L: 0.022,
      p_m12_H: 0.085,
    },
    8132: {
      H: 0.43,
      L: 0.4,
      O: 0.4,
      C: 0.41,
      V: 712500,
      p_w1_L: 0.4,
      p_w1_H: 0.44,
      p_m1_L: 0.36,
      p_m1_H: 0.5,
      p_m3_L: 0.36,
      p_m3_H: 0.51,
      p_m6_L: 0.156,
      p_m6_H: 0.79,
      p_m12_L: 0.156,
      p_m12_H: 0.79,
    },
    8133: {
      H: 0.222,
      L: 0.216,
      O: 0.216,
      C: 0.222,
      V: 89000,
      p_w1_L: 0.214,
      p_w1_H: 0.24,
      p_m1_L: 0.01,
      p_m1_H: 0.24,
      p_m3_L: 0.01,
      p_m3_H: 0.24,
      p_m6_L: 0.01,
      p_m6_H: 0.24,
      p_m12_L: 0.01,
      p_m12_H: 0.24,
    },
    8135: {
      H: 0.18,
      L: 0.18,
      O: 0.18,
      C: 0.18,
      V: 0,
      p_w1_L: 0.18,
      p_w1_H: 0.21,
      p_m1_L: 0.18,
      p_m1_H: 0.255,
      p_m3_L: 0.18,
      p_m3_H: 0.255,
      p_m6_L: 0.034,
      p_m6_H: 0.445,
      p_m12_L: 0.034,
      p_m12_H: 0.7,
    },
    8136: {
      H: 0.05,
      L: 0.049,
      O: 0.05,
      C: 0.05,
      V: 3460000,
      p_w1_L: 0.048,
      p_w1_H: 0.054,
      p_m1_L: 0.048,
      p_m1_H: 0.112,
      p_m3_L: 0.048,
      p_m3_H: 0.36,
      p_m6_L: 0.048,
      p_m6_H: 0.36,
      p_m12_L: 0.042,
      p_m12_H: 0.36,
    },
    8137: {
      H: 0.365,
      L: 0.355,
      O: 0.365,
      C: 0.365,
      V: 1648000,
      p_w1_L: 0.355,
      p_w1_H: 0.395,
      p_m1_L: 0.355,
      p_m1_H: 0.435,
      p_m3_L: 0.355,
      p_m3_H: 0.57,
      p_m6_L: 0.355,
      p_m6_H: 0.66,
      p_m12_L: 0.34,
      p_m12_H: 0.67,
    },
    8139: {
      H: 1.86,
      L: 1.85,
      O: 1.85,
      C: 1.86,
      V: 32500,
      p_w1_L: 1.72,
      p_w1_H: 1.86,
      p_m1_L: 1.49,
      p_m1_H: 1.86,
      p_m3_L: 1.49,
      p_m3_H: 1.99,
      p_m6_L: 1.49,
      p_m6_H: 2.27,
      p_m12_L: 1.49,
      p_m12_H: 2.85,
    },
    8140: {
      H: 0.155,
      L: 0.144,
      O: 0.153,
      C: 0.155,
      V: 300000,
      p_w1_L: 0.144,
      p_w1_H: 0.162,
      p_m1_L: 0.144,
      p_m1_H: 0.169,
      p_m3_L: 0.144,
      p_m3_H: 0.176,
      p_m6_L: 0.144,
      p_m6_H: 0.176,
      p_m12_L: 0.111,
      p_m12_H: 0.23,
    },
    8143: {
      H: 0.055,
      L: 0.048,
      O: 0.055,
      C: 0.052,
      V: 544000,
      p_w1_L: 0.042,
      p_w1_H: 0.058,
      p_m1_L: 0.042,
      p_m1_H: 0.074,
      p_m3_L: 0.042,
      p_m3_H: 0.074,
      p_m6_L: 0.042,
      p_m6_H: 0.099,
      p_m12_L: 0.04,
      p_m12_H: 0.099,
    },
    8146: {
      H: 0.176,
      L: 0.176,
      O: 0.176,
      C: 0.181,
      V: 10000,
      p_w1_L: 0.175,
      p_w1_H: 0.2,
      p_m1_L: 0.175,
      p_m1_H: 0.21,
      p_m3_L: 0.173,
      p_m3_H: 0.22,
      p_m6_L: 0.167,
      p_m6_H: 0.22,
      p_m12_L: 0.16,
      p_m12_H: 0.305,
    },
    8147: {
      H: 0.03,
      L: 0.03,
      O: 0.03,
      C: 0.03,
      V: 16000,
      p_w1_L: 0.03,
      p_w1_H: 0.031,
      p_m1_L: 0.028,
      p_m1_H: 0.034,
      p_m3_L: 0.028,
      p_m3_H: 0.036,
      p_m6_L: 0.028,
      p_m6_H: 0.15,
      p_m12_L: 0.028,
      p_m12_H: 0.15,
    },
    8148: {
      H: 0.023,
      L: 0.021,
      O: 0.022,
      C: 0.021,
      V: 360000,
      p_w1_L: 0.021,
      p_w1_H: 0.027,
      p_m1_L: 0.02,
      p_m1_H: 0.027,
      p_m3_L: 0.018,
      p_m3_H: 0.027,
      p_m6_L: 0.018,
      p_m6_H: 0.027,
      p_m12_L: 0.018,
      p_m12_H: 0.03,
    },
    8149: {
      H: 0.2,
      L: 0.2,
      O: 0.2,
      C: 0.2,
      V: 0,
      p_w1_L: 0.2,
      p_w1_H: 0.2,
      p_m1_L: 0.19,
      p_m1_H: 0.21,
      p_m3_L: 0.19,
      p_m3_H: 0.214,
      p_m6_L: 0.19,
      p_m6_H: 0.24,
      p_m12_L: 0.19,
      p_m12_H: 0.27,
    },
    8150: {
      H: 0.034,
      L: 0.033,
      O: 0.034,
      C: 0.033,
      V: 1171600,
      p_w1_L: 0.033,
      p_w1_H: 0.05,
      p_m1_L: 0.029,
      p_m1_H: 0.05,
      p_m3_L: 0.029,
      p_m3_H: 0.068,
      p_m6_L: 0.029,
      p_m6_H: 0.13,
      p_m12_L: 0.029,
      p_m12_H: 0.215,
    },
    8151: {
      H: 0.16,
      L: 0.16,
      O: 0.16,
      C: 0.16,
      V: 0,
      p_w1_L: 0.145,
      p_w1_H: 0.166,
      p_m1_L: 0.113,
      p_m1_H: 0.168,
      p_m3_L: 0.113,
      p_m3_H: 0.18,
      p_m6_L: 0.08,
      p_m6_H: 0.18,
      p_m12_L: 0.07,
      p_m12_H: 0.18,
    },
    8152: {
      H: 0.076,
      L: 0.076,
      O: 0.076,
      C: 0.076,
      V: 0,
      p_w1_L: 0.074,
      p_w1_H: 0.08,
      p_m1_L: 0.074,
      p_m1_H: 0.11,
      p_m3_L: 0.074,
      p_m3_H: 0.21,
      p_m6_L: 0.074,
      p_m6_H: 0.21,
      p_m12_L: 0.072,
      p_m12_H: 0.21,
    },
    8153: {
      H: 0.082,
      L: 0.077,
      O: 0.077,
      C: 0.081,
      V: 6675000,
      p_w1_L: 0.07,
      p_w1_H: 0.084,
      p_m1_L: 0.068,
      p_m1_H: 0.084,
      p_m3_L: 0.066,
      p_m3_H: 0.084,
      p_m6_L: 0.059,
      p_m6_H: 0.108,
      p_m12_L: 0.059,
      p_m12_H: 0.174,
    },
    8155: {
      H: 0.011,
      L: 0.011,
      O: 0.011,
      C: 0.011,
      V: 0,
      p_w1_L: 0.011,
      p_w1_H: 0.011,
      p_m1_L: 0.011,
      p_m1_H: 0.011,
      p_m3_L: 0.011,
      p_m3_H: 0.011,
      p_m6_L: 0.011,
      p_m6_H: 0.011,
      p_m12_L: 0.01,
      p_m12_H: 0.012,
    },
    8156: {
      H: 0.185,
      L: 0.171,
      O: 0.178,
      C: 0.177,
      V: 8385000,
      p_w1_L: 0.171,
      p_w1_H: 0.202,
      p_m1_L: 0.171,
      p_m1_H: 0.25,
      p_m3_L: 0.158,
      p_m3_H: 0.26,
      p_m6_L: 0.158,
      p_m6_H: 0.295,
      p_m12_L: 0.125,
      p_m12_H: 0.35,
    },
    8158: {
      H: 0.34,
      L: 0.265,
      O: 0.265,
      C: 0.34,
      V: 1530000,
      p_w1_L: 0.255,
      p_w1_H: 0.34,
      p_m1_L: 0.22,
      p_m1_H: 0.34,
      p_m3_L: 0.206,
      p_m3_H: 0.34,
      p_m6_L: 0.206,
      p_m6_H: 0.46,
      p_m12_L: 0.206,
      p_m12_H: 0.77,
    },
    8159: {
      H: 0.125,
      L: 0.125,
      O: 0.125,
      C: 0.125,
      V: 0,
      p_w1_L: 0.125,
      p_w1_H: 0.125,
      p_m1_L: 0.12,
      p_m1_H: 0.168,
      p_m3_L: 0.114,
      p_m3_H: 0.168,
      p_m6_L: 0.11,
      p_m6_H: 0.168,
      p_m12_L: 0.094,
      p_m12_H: 0.185,
    },
    8160: {
      H: 0.088,
      L: 0.082,
      O: 0.082,
      C: 0.084,
      V: 60000,
      p_w1_L: 0.08,
      p_w1_H: 0.088,
      p_m1_L: 0.061,
      p_m1_H: 0.089,
      p_m3_L: 0.061,
      p_m3_H: 0.089,
      p_m6_L: 0.061,
      p_m6_H: 0.11,
      p_m12_L: 0.061,
      p_m12_H: 0.63,
    },
    8161: {
      H: 0.055,
      L: 0.055,
      O: 0.055,
      C: 0.055,
      V: 0,
      p_w1_L: 0.054,
      p_w1_H: 0.057,
      p_m1_L: 0.053,
      p_m1_H: 0.061,
      p_m3_L: 0.047,
      p_m3_H: 0.061,
      p_m6_L: 0.047,
      p_m6_H: 0.079,
      p_m12_L: 0.047,
      p_m12_H: 0.079,
    },
    8162: {
      H: 0.275,
      L: 0.255,
      O: 0.27,
      C: 0.275,
      V: 680000,
      p_w1_L: 0.25,
      p_w1_H: 0.275,
      p_m1_L: 0.25,
      p_m1_H: 0.285,
      p_m3_L: 0.25,
      p_m3_H: 0.295,
      p_m6_L: 0.175,
      p_m6_H: 0.3,
      p_m12_L: 0.175,
      p_m12_H: 0.45,
    },
    8163: {
      H: 0.178,
      L: 0.178,
      O: 0.178,
      C: 0.178,
      V: 0,
      p_w1_L: 0.173,
      p_w1_H: 0.184,
      p_m1_L: 0.165,
      p_m1_H: 0.203,
      p_m3_L: 0.125,
      p_m3_H: 0.21,
      p_m6_L: 0.117,
      p_m6_H: 0.21,
      p_m12_L: 0.117,
      p_m12_H: 0.25,
    },
    8166: {
      H: 0.325,
      L: 0.325,
      O: 0.325,
      C: 0.325,
      V: 0,
      p_w1_L: 0.325,
      p_w1_H: 0.325,
      p_m1_L: 0.32,
      p_m1_H: 0.4,
      p_m3_L: 0.042,
      p_m3_H: 0.62,
      p_m6_L: 0.035,
      p_m6_H: 0.62,
      p_m12_L: 0.032,
      p_m12_H: 0.62,
    },
    8167: {
      H: 0.148,
      L: 0.116,
      O: 0.134,
      C: 0.148,
      V: 2188000,
      p_w1_L: 0.116,
      p_w1_H: 0.148,
      p_m1_L: 0.116,
      p_m1_H: 0.165,
      p_m3_L: 0.116,
      p_m3_H: 0.177,
      p_m6_L: 0.116,
      p_m6_H: 0.2,
      p_m12_L: 0.116,
      p_m12_H: 0.246,
    },
    8168: {
      H: 0.12,
      L: 0.12,
      O: 0.12,
      C: 0.12,
      V: 0,
      p_w1_L: 0.12,
      p_w1_H: 0.127,
      p_m1_L: 0.111,
      p_m1_H: 0.131,
      p_m3_L: 0.085,
      p_m3_H: 0.131,
      p_m6_L: 0.085,
      p_m6_H: 0.15,
      p_m12_L: 0.085,
      p_m12_H: 0.175,
    },
    8169: {
      H: 0.09,
      L: 0.09,
      O: 0.09,
      C: 0.09,
      V: 0,
      p_w1_L: 0.08,
      p_w1_H: 0.097,
      p_m1_L: 0.08,
      p_m1_H: 0.099,
      p_m3_L: 0.07,
      p_m3_H: 0.108,
      p_m6_L: 0.062,
      p_m6_H: 0.191,
      p_m12_L: 0.062,
      p_m12_H: 0.191,
    },
    8170: {
      H: 3,
      L: 3,
      O: 3,
      C: 3,
      V: 0,
      p_w1_L: 3,
      p_w1_H: 3.04,
      p_m1_L: 2.9,
      p_m1_H: 3.05,
      p_m3_L: 1.36,
      p_m3_H: 3.46,
      p_m6_L: 0.54,
      p_m6_H: 3.46,
      p_m12_L: 0.51,
      p_m12_H: 3.83,
    },
    8172: {
      H: 0.26,
      L: 0.26,
      O: 0.26,
      C: 0.26,
      V: 180000,
      p_w1_L: 0.26,
      p_w1_H: 0.295,
      p_m1_L: 0.249,
      p_m1_H: 0.35,
      p_m3_L: 0.205,
      p_m3_H: 0.385,
      p_m6_L: 0.205,
      p_m6_H: 0.385,
      p_m12_L: 0.066,
      p_m12_H: 0.53,
    },
    8173: {
      H: 0.78,
      L: 0.77,
      O: 0.78,
      C: 0.78,
      V: 211214,
      p_w1_L: 0.65,
      p_w1_H: 0.98,
      p_m1_L: 0.65,
      p_m1_H: 0.98,
      p_m3_L: 0.245,
      p_m3_H: 0.98,
      p_m6_L: 0.066,
      p_m6_H: 0.98,
      p_m12_L: 0.037,
      p_m12_H: 0.98,
    },
    8175: {
      H: 0.058,
      L: 0.058,
      O: 0.058,
      C: 0.058,
      V: 0,
      p_w1_L: 0.058,
      p_w1_H: 0.058,
      p_m1_L: 0.058,
      p_m1_H: 0.058,
      p_m3_L: 0.058,
      p_m3_H: 0.058,
      p_m6_L: 0.058,
      p_m6_H: 0.058,
      p_m12_L: 0.043,
      p_m12_H: 0.138,
    },
    8176: {
      H: 0.415,
      L: 0.415,
      O: 0.415,
      C: 0.415,
      V: 0,
      p_w1_L: 0.39,
      p_w1_H: 0.435,
      p_m1_L: 0.39,
      p_m1_H: 0.45,
      p_m3_L: 0.39,
      p_m3_H: 0.85,
      p_m6_L: 0.39,
      p_m6_H: 0.85,
      p_m12_L: 0.39,
      p_m12_H: 0.93,
    },
    8178: {
      H: 0.255,
      L: 0.255,
      O: 0.255,
      C: 0.255,
      V: 1200,
      p_w1_L: 0.255,
      p_w1_H: 0.345,
      p_m1_L: 0.222,
      p_m1_H: 0.345,
      p_m3_L: 0.222,
      p_m3_H: 0.395,
      p_m6_L: 0.222,
      p_m6_H: 0.45,
      p_m12_L: 0.22,
      p_m12_H: 0.672,
    },
    8179: {
      H: 0.4,
      L: 0.35,
      O: 0.39,
      C: 0.36,
      V: 714000,
      p_w1_L: 0.25,
      p_w1_H: 0.415,
      p_m1_L: 0.21,
      p_m1_H: 0.415,
      p_m3_L: 0.13,
      p_m3_H: 0.415,
      p_m6_L: 0.13,
      p_m6_H: 0.415,
      p_m12_L: 0.13,
      p_m12_H: 0.415,
    },
    8181: {
      H: 0.136,
      L: 0.136,
      O: 0.136,
      C: 0.136,
      V: 0,
      p_w1_L: 0.12,
      p_w1_H: 0.138,
      p_m1_L: 0.103,
      p_m1_H: 0.153,
      p_m3_L: 0.103,
      p_m3_H: 0.175,
      p_m6_L: 0.103,
      p_m6_H: 0.27,
      p_m12_L: 0.103,
      p_m12_H: 0.27,
    },
    8186: {
      H: 0.9,
      L: 0.9,
      O: 0.9,
      C: 0.9,
      V: 0,
      p_w1_L: 0.9,
      p_w1_H: 0.9,
      p_m1_L: 0.9,
      p_m1_H: 0.9,
      p_m3_L: 0.9,
      p_m3_H: 0.9,
      p_m6_L: 0.9,
      p_m6_H: 0.9,
      p_m12_L: 0.255,
      p_m12_H: 1,
    },
    8187: {
      H: 0.133,
      L: 0.122,
      O: 0.129,
      C: 0.133,
      V: 39560000,
      p_w1_L: 0.122,
      p_w1_H: 0.139,
      p_m1_L: 0.105,
      p_m1_H: 0.9,
      p_m3_L: 0.105,
      p_m3_H: 0.9,
      p_m6_L: 0.105,
      p_m6_H: 0.9,
      p_m12_L: 0.105,
      p_m12_H: 0.9,
    },
    8188: {
      H: 0.175,
      L: 0.153,
      O: 0.153,
      C: 0.175,
      V: 104000,
      p_w1_L: 0.153,
      p_w1_H: 0.2,
      p_m1_L: 0.153,
      p_m1_H: 0.2,
      p_m3_L: 0.145,
      p_m3_H: 0.2,
      p_m6_L: 0.14,
      p_m6_H: 0.2,
      p_m12_L: 0.101,
      p_m12_H: 0.21,
    },
    8189: {
      H: 0.249,
      L: 0.249,
      O: 0.249,
      C: 0.249,
      V: 0,
      p_w1_L: 0.249,
      p_w1_H: 0.255,
      p_m1_L: 0.2,
      p_m1_H: 0.27,
      p_m3_L: 0.2,
      p_m3_H: 0.3,
      p_m6_L: 0.189,
      p_m6_H: 0.41,
      p_m12_L: 0.074,
      p_m12_H: 0.55,
    },
    8191: {
      H: 0.054,
      L: 0.046,
      O: 0.049,
      C: 0.051,
      V: 8400000,
      p_w1_L: 0.043,
      p_w1_H: 0.067,
      p_m1_L: 0.043,
      p_m1_H: 0.166,
      p_m3_L: 0.043,
      p_m3_H: 0.166,
      p_m6_L: 0.043,
      p_m6_H: 0.166,
      p_m12_L: 0.043,
      p_m12_H: 0.34,
    },
    8193: {
      H: 0.062,
      L: 0.062,
      O: 0.062,
      C: 0.062,
      V: 0,
      p_w1_L: 0.061,
      p_w1_H: 0.079,
      p_m1_L: 0.054,
      p_m1_H: 0.079,
      p_m3_L: 0.054,
      p_m3_H: 0.089,
      p_m6_L: 0.041,
      p_m6_H: 0.089,
      p_m12_L: 0.041,
      p_m12_H: 0.164,
    },
    8195: {
      H: 1.308,
      L: 1.308,
      O: 1.308,
      C: 1.308,
      V: 0,
      p_w1_L: 1.308,
      p_w1_H: 1.308,
      p_m1_L: 1.308,
      p_m1_H: 1.32,
      p_m3_L: 1.25,
      p_m3_H: 1.7,
      p_m6_L: 0.295,
      p_m6_H: 1.7,
      p_m12_L: 0.27,
      p_m12_H: 1.7,
    },
    8196: {
      H: 0.385,
      L: 0.32,
      O: 0.38,
      C: 0.385,
      V: 48000,
      p_w1_L: 0.3,
      p_w1_H: 0.42,
      p_m1_L: 0.21,
      p_m1_H: 0.42,
      p_m3_L: 0.11,
      p_m3_H: 0.42,
      p_m6_L: 0.11,
      p_m6_H: 0.42,
      p_m12_L: 0.11,
      p_m12_H: 0.42,
    },
    8198: {
      H: 0.4,
      L: 0.4,
      O: 0.4,
      C: 0.4,
      V: 40000,
      p_w1_L: 0.375,
      p_w1_H: 0.86,
      p_m1_L: 0.25,
      p_m1_H: 0.86,
      p_m3_L: 0.25,
      p_m3_H: 0.86,
      p_m6_L: 0.25,
      p_m6_H: 0.86,
      p_m12_L: 0.25,
      p_m12_H: 1.79,
    },
    8200: {
      H: 1.35,
      L: 1.35,
      O: 1.35,
      C: 1.35,
      V: 8000,
      p_w1_L: 1.3,
      p_w1_H: 1.47,
      p_m1_L: 1.28,
      p_m1_H: 1.68,
      p_m3_L: 1.28,
      p_m3_H: 1.78,
      p_m6_L: 1.17,
      p_m6_H: 2.14,
      p_m12_L: 0.73,
      p_m12_H: 2.38,
    },
    8201: {
      H: 0.11,
      L: 0.101,
      O: 0.101,
      C: 0.11,
      V: 960000,
      p_w1_L: 0.091,
      p_w1_H: 0.11,
      p_m1_L: 0.088,
      p_m1_H: 0.12,
      p_m3_L: 0.076,
      p_m3_H: 0.12,
      p_m6_L: 0.076,
      p_m6_H: 0.121,
      p_m12_L: 0.07,
      p_m12_H: 0.15,
    },
    8203: {
      H: 0.28,
      L: 0.28,
      O: 0.28,
      C: 0.28,
      V: 0,
      p_w1_L: 0.25,
      p_w1_H: 0.29,
      p_m1_L: 0.25,
      p_m1_H: 0.35,
      p_m3_L: 0.248,
      p_m3_H: 0.35,
      p_m6_L: 0.12,
      p_m6_H: 0.35,
      p_m12_L: 0.075,
      p_m12_H: 0.35,
    },
    8205: {
      H: 0.325,
      L: 0.25,
      O: 0.25,
      C: 0.32,
      V: 92000,
      p_w1_L: 0.25,
      p_w1_H: 0.45,
      p_m1_L: 0.25,
      p_m1_H: 0.52,
      p_m3_L: 0.24,
      p_m3_H: 0.52,
      p_m6_L: 0.205,
      p_m6_H: 0.52,
      p_m12_L: 0.13,
      p_m12_H: 0.52,
    },
    8206: {
      H: 0.066,
      L: 0.053,
      O: 0.053,
      C: 0.054,
      V: 62000,
      p_w1_L: 0.052,
      p_w1_H: 0.066,
      p_m1_L: 0.048,
      p_m1_H: 0.1,
      p_m3_L: 0.048,
      p_m3_H: 0.1,
      p_m6_L: 0.045,
      p_m6_H: 0.1,
      p_m12_L: 0.045,
      p_m12_H: 0.1,
    },
    8208: {
      H: 0.103,
      L: 0.098,
      O: 0.103,
      C: 0.1,
      V: 1278000,
      p_w1_L: 0.098,
      p_w1_H: 0.109,
      p_m1_L: 0.098,
      p_m1_H: 0.117,
      p_m3_L: 0.098,
      p_m3_H: 0.165,
      p_m6_L: 0.098,
      p_m6_H: 0.65,
      p_m12_L: 0.098,
      p_m12_H: 0.65,
    },
    8209: {
      H: 0.14,
      L: 0.14,
      O: 0.14,
      C: 0.14,
      V: 0,
      p_w1_L: 0.14,
      p_w1_H: 0.14,
      p_m1_L: 0.14,
      p_m1_H: 0.14,
      p_m3_L: 0.045,
      p_m3_H: 0.148,
      p_m6_L: 0.02,
      p_m6_H: 0.148,
      p_m12_L: 0.02,
      p_m12_H: 0.148,
    },
    8210: {
      H: 0.059,
      L: 0.059,
      O: 0.059,
      C: 0.059,
      V: 10000,
      p_w1_L: 0.059,
      p_w1_H: 0.064,
      p_m1_L: 0.058,
      p_m1_H: 0.071,
      p_m3_L: 0.055,
      p_m3_H: 0.071,
      p_m6_L: 0.05,
      p_m6_H: 0.071,
      p_m12_L: 0.041,
      p_m12_H: 0.1,
    },
    8211: {
      H: 0.065,
      L: 0.065,
      O: 0.065,
      C: 0.065,
      V: 0,
      p_w1_L: 0.065,
      p_w1_H: 0.065,
      p_m1_L: 0.065,
      p_m1_H: 0.065,
      p_m3_L: 0.06,
      p_m3_H: 0.069,
      p_m6_L: 0.057,
      p_m6_H: 0.109,
      p_m12_L: 0.057,
      p_m12_H: 0.159,
    },
    8213: {
      H: 0.03,
      L: 0.03,
      O: 0.03,
      C: 0.03,
      V: 0,
      p_w1_L: 0.028,
      p_w1_H: 0.032,
      p_m1_L: 0.025,
      p_m1_H: 0.036,
      p_m3_L: 0.025,
      p_m3_H: 0.05,
      p_m6_L: 0.025,
      p_m6_H: 0.056,
      p_m12_L: 0.025,
      p_m12_H: 0.068,
    },
    8215: {
      H: 0.108,
      L: 0.108,
      O: 0.108,
      C: 0.108,
      V: 0,
      p_w1_L: 0.108,
      p_w1_H: 0.108,
      p_m1_L: 0.108,
      p_m1_H: 0.108,
      p_m3_L: 0.108,
      p_m3_H: 0.108,
      p_m6_L: 0.108,
      p_m6_H: 0.108,
      p_m12_L: 0.108,
      p_m12_H: 0.108,
    },
    8216: {
      H: 1.34,
      L: 1.34,
      O: 1.34,
      C: 1.34,
      V: 0,
      p_w1_L: 1.25,
      p_w1_H: 1.45,
      p_m1_L: 1.25,
      p_m1_H: 1.45,
      p_m3_L: 1.25,
      p_m3_H: 1.45,
      p_m6_L: 1.22,
      p_m6_H: 1.47,
      p_m12_L: 0.45,
      p_m12_H: 1.78,
    },
    8217: {
      H: 0.2,
      L: 0.2,
      O: 0.2,
      C: 0.2,
      V: 625,
      p_w1_L: 0.19,
      p_w1_H: 0.208,
      p_m1_L: 0.19,
      p_m1_H: 0.27,
      p_m3_L: 0.19,
      p_m3_H: 0.42,
      p_m6_L: 0.075,
      p_m6_H: 0.472,
      p_m12_L: 0.075,
      p_m12_H: 0.525,
    },
    8218: {
      H: 0.255,
      L: 0.255,
      O: 0.255,
      C: 0.255,
      V: 0,
      p_w1_L: 0.25,
      p_w1_H: 0.27,
      p_m1_L: 0.25,
      p_m1_H: 0.29,
      p_m3_L: 0.25,
      p_m3_H: 0.32,
      p_m6_L: 0.25,
      p_m6_H: 0.465,
      p_m12_L: 0.25,
      p_m12_H: 0.49,
    },
    8219: {
      H: 0.295,
      L: 0.295,
      O: 0.295,
      C: 0.295,
      V: 0,
      p_w1_L: 0.295,
      p_w1_H: 0.295,
      p_m1_L: 0.032,
      p_m1_H: 0.34,
      p_m3_L: 0.032,
      p_m3_H: 0.61,
      p_m6_L: 0.032,
      p_m6_H: 0.63,
      p_m12_L: 0.032,
      p_m12_H: 0.63,
    },
    8220: {
      H: 0.036,
      L: 0.027,
      O: 0.027,
      C: 0.032,
      V: 24015000,
      p_w1_L: 0.024,
      p_w1_H: 0.041,
      p_m1_L: 0.024,
      p_m1_H: 0.055,
      p_m3_L: 0.024,
      p_m3_H: 0.115,
      p_m6_L: 0.024,
      p_m6_H: 0.115,
      p_m12_L: 0.024,
      p_m12_H: 0.115,
    },
    8221: {
      H: 0.056,
      L: 0.056,
      O: 0.056,
      C: 0.056,
      V: 0,
      p_w1_L: 0.056,
      p_w1_H: 0.057,
      p_m1_L: 0.037,
      p_m1_H: 0.057,
      p_m3_L: 0.037,
      p_m3_H: 0.068,
      p_m6_L: 0.037,
      p_m6_H: 0.077,
      p_m12_L: 0.037,
      p_m12_H: 0.105,
    },
    8222: {
      H: 0.058,
      L: 0.058,
      O: 0.058,
      C: 0.058,
      V: 0,
      p_w1_L: 0.054,
      p_w1_H: 0.06,
      p_m1_L: 0.05,
      p_m1_H: 0.065,
      p_m3_L: 0.05,
      p_m3_H: 0.065,
      p_m6_L: 0.05,
      p_m6_H: 0.088,
      p_m12_L: 0.033,
      p_m12_H: 0.088,
    },
    8223: {
      H: 2.26,
      L: 2.24,
      O: 2.25,
      C: 2.22,
      V: 224000,
      p_w1_L: 1.88,
      p_w1_H: 2.26,
      p_m1_L: 1.71,
      p_m1_H: 2.26,
      p_m3_L: 1.71,
      p_m3_H: 2.4,
      p_m6_L: 1.12,
      p_m6_H: 2.4,
      p_m12_L: 0.96,
      p_m12_H: 2.4,
    },
    8225: {
      H: 0.4,
      L: 0.4,
      O: 0.4,
      C: 0.4,
      V: 100000,
      p_w1_L: 0.38,
      p_w1_H: 0.425,
      p_m1_L: 0.38,
      p_m1_H: 0.495,
      p_m3_L: 0.38,
      p_m3_H: 0.53,
      p_m6_L: 0.355,
      p_m6_H: 0.53,
      p_m12_L: 0.355,
      p_m12_H: 0.61,
    },
    8226: {
      H: 0.39,
      L: 0.39,
      O: 0.39,
      C: 0.39,
      V: 2000,
      p_w1_L: 0.3,
      p_w1_H: 0.39,
      p_m1_L: 0.3,
      p_m1_H: 0.39,
      p_m3_L: 0.26,
      p_m3_H: 0.39,
      p_m6_L: 0.226,
      p_m6_H: 0.39,
      p_m12_L: 0.226,
      p_m12_H: 0.68,
    },
    8227: {
      H: 1.35,
      L: 1.34,
      O: 1.34,
      C: 1.35,
      V: 6000,
      p_w1_L: 1.29,
      p_w1_H: 1.45,
      p_m1_L: 1.1,
      p_m1_H: 1.5,
      p_m3_L: 1.1,
      p_m3_H: 1.83,
      p_m6_L: 1.1,
      p_m6_H: 1.92,
      p_m12_L: 1.1,
      p_m12_H: 2.18,
    },
    8228: {
      H: 0.096,
      L: 0.092,
      O: 0.093,
      C: 0.095,
      V: 10756000,
      p_w1_L: 0.089,
      p_w1_H: 0.107,
      p_m1_L: 0.089,
      p_m1_H: 0.128,
      p_m3_L: 0.063,
      p_m3_H: 0.169,
      p_m6_L: 0.063,
      p_m6_H: 0.41,
      p_m12_L: 0.034,
      p_m12_H: 0.41,
    },
    8229: {
      H: 0.248,
      L: 0.248,
      O: 0.248,
      C: 0.248,
      V: 0,
      p_w1_L: 0.248,
      p_w1_H: 0.33,
      p_m1_L: 0.248,
      p_m1_H: 0.345,
      p_m3_L: 0.17,
      p_m3_H: 0.345,
      p_m6_L: 0.16,
      p_m6_H: 0.345,
      p_m12_L: 0.1,
      p_m12_H: 0.345,
    },
    8231: {
      H: 1.23,
      L: 1.23,
      O: 1.23,
      C: 1.23,
      V: 20000,
      p_w1_L: 1.12,
      p_w1_H: 1.24,
      p_m1_L: 1.08,
      p_m1_H: 1.27,
      p_m3_L: 0.67,
      p_m3_H: 1.27,
      p_m6_L: 0.67,
      p_m6_H: 1.27,
      p_m12_L: 0.67,
      p_m12_H: 1.98,
    },
    8232: {
      H: 0.054,
      L: 0.054,
      O: 0.054,
      C: 0.054,
      V: 0,
      p_w1_L: 0.054,
      p_w1_H: 0.065,
      p_m1_L: 0.052,
      p_m1_H: 0.079,
      p_m3_L: 0.044,
      p_m3_H: 0.135,
      p_m6_L: 0.044,
      p_m6_H: 0.135,
      p_m12_L: 0.041,
      p_m12_H: 0.135,
    },
    8237: {
      H: 0.02,
      L: 0.019,
      O: 0.02,
      C: 0.02,
      V: 230000,
      p_w1_L: 0.019,
      p_w1_H: 0.022,
      p_m1_L: 0.019,
      p_m1_H: 0.027,
      p_m3_L: 0.019,
      p_m3_H: 0.036,
      p_m6_L: 0.019,
      p_m6_H: 0.049,
      p_m12_L: 0.017,
      p_m12_H: 0.055,
    },
    8238: {
      H: 0.1,
      L: 0.1,
      O: 0.1,
      C: 0.1,
      V: 0,
      p_w1_L: 0.1,
      p_w1_H: 0.14,
      p_m1_L: 0.1,
      p_m1_H: 0.165,
      p_m3_L: 0.1,
      p_m3_H: 0.25,
      p_m6_L: 0.1,
      p_m6_H: 0.25,
      p_m12_L: 0.1,
      p_m12_H: 0.25,
    },
    8239: {
      H: 0.186,
      L: 0.186,
      O: 0.186,
      C: 0.186,
      V: 0,
      p_w1_L: 0.168,
      p_w1_H: 0.187,
      p_m1_L: 0.149,
      p_m1_H: 0.195,
      p_m3_L: 0.144,
      p_m3_H: 0.2,
      p_m6_L: 0.135,
      p_m6_H: 0.25,
      p_m12_L: 0.129,
      p_m12_H: 0.25,
    },
    8241: {
      H: 0.26,
      L: 0.241,
      O: 0.242,
      C: 0.26,
      V: 160000,
      p_w1_L: 0.241,
      p_w1_H: 0.27,
      p_m1_L: 0.211,
      p_m1_H: 0.27,
      p_m3_L: 0.211,
      p_m3_H: 0.305,
      p_m6_L: 0.2,
      p_m6_H: 0.4,
      p_m12_L: 0.15,
      p_m12_H: 0.6,
    },
    8242: {
      H: 0.01,
      L: 0.01,
      O: 0.01,
      C: 0.01,
      V: 0,
      p_w1_L: 0.01,
      p_w1_H: 0.01,
      p_m1_L: 0.01,
      p_m1_H: 0.01,
      p_m3_L: 0.01,
      p_m3_H: 0.01,
      p_m6_L: 0.01,
      p_m6_H: 0.01,
      p_m12_L: 0.01,
      p_m12_H: 0.023,
    },
    8245: {
      H: 0.068,
      L: 0.068,
      O: 0.068,
      C: 0.068,
      V: 0,
      p_w1_L: 0.065,
      p_w1_H: 0.079,
      p_m1_L: 0.062,
      p_m1_H: 0.09,
      p_m3_L: 0.062,
      p_m3_H: 0.09,
      p_m6_L: 0.062,
      p_m6_H: 0.09,
      p_m12_L: 0.062,
      p_m12_H: 0.104,
    },
    8246: {
      H: 0.204,
      L: 0.2,
      O: 0.2,
      C: 0.2,
      V: 480000,
      p_w1_L: 0.198,
      p_w1_H: 0.246,
      p_m1_L: 0.198,
      p_m1_H: 0.3,
      p_m3_L: 0.198,
      p_m3_H: 0.3,
      p_m6_L: 0.198,
      p_m6_H: 0.3,
      p_m12_L: 0.198,
      p_m12_H: 0.3,
    },
    8247: {
      H: 1.79,
      L: 1.79,
      O: 1.79,
      C: 1.79,
      V: 0,
      p_w1_L: 1.79,
      p_w1_H: 1.81,
      p_m1_L: 1.46,
      p_m1_H: 1.94,
      p_m3_L: 1.33,
      p_m3_H: 1.94,
      p_m6_L: 1.08,
      p_m6_H: 1.94,
      p_m12_L: 1.08,
      p_m12_H: 1.94,
    },
    8249: {
      H: 0.136,
      L: 0.136,
      O: 0.136,
      C: 0.136,
      V: 0,
      p_w1_L: 0.131,
      p_w1_H: 0.145,
      p_m1_L: 0.131,
      p_m1_H: 0.192,
      p_m3_L: 0.111,
      p_m3_H: 0.222,
      p_m6_L: 0.073,
      p_m6_H: 0.247,
      p_m12_L: 0.07,
      p_m12_H: 0.247,
    },
    8250: {
      H: 0.02,
      L: 0.018,
      O: 0.018,
      C: 0.02,
      V: 146800,
      p_w1_L: 0.018,
      p_w1_H: 0.02,
      p_m1_L: 0.017,
      p_m1_H: 0.023,
      p_m3_L: 0.016,
      p_m3_H: 0.023,
      p_m6_L: 0.015,
      p_m6_H: 0.033,
      p_m12_L: 0.014,
      p_m12_H: 0.039,
    },
    8255: {
      H: 0.125,
      L: 0.125,
      O: 0.125,
      C: 0.125,
      V: 0,
      p_w1_L: 0.125,
      p_w1_H: 0.153,
      p_m1_L: 0.125,
      p_m1_H: 0.159,
      p_m3_L: 0.125,
      p_m3_H: 0.196,
      p_m6_L: 0.125,
      p_m6_H: 0.225,
      p_m12_L: 0.125,
      p_m12_H: 0.25,
    },
    8257: {
      H: 0.185,
      L: 0.178,
      O: 0.178,
      C: 0.182,
      V: 20000,
      p_w1_L: 0.161,
      p_w1_H: 0.19,
      p_m1_L: 0.161,
      p_m1_H: 0.223,
      p_m3_L: 0.161,
      p_m3_H: 0.229,
      p_m6_L: 0.161,
      p_m6_H: 0.285,
      p_m12_L: 0.161,
      p_m12_H: 0.36,
    },
    8258: {
      H: 0.07,
      L: 0.07,
      O: 0.07,
      C: 0.07,
      V: 0,
      p_w1_L: 0.07,
      p_w1_H: 0.07,
      p_m1_L: 0.07,
      p_m1_H: 0.07,
      p_m3_L: 0.07,
      p_m3_H: 0.07,
      p_m6_L: 0.07,
      p_m6_H: 0.07,
      p_m12_L: 0.07,
      p_m12_H: 0.07,
    },
    8259: {
      H: 0.39,
      L: 0.39,
      O: 0.39,
      C: 0.39,
      V: 0,
      p_w1_L: 0.39,
      p_w1_H: 0.39,
      p_m1_L: 0.39,
      p_m1_H: 0.39,
      p_m3_L: 0.39,
      p_m3_H: 0.39,
      p_m6_L: 0.39,
      p_m6_H: 0.39,
      p_m12_L: 0.315,
      p_m12_H: 0.56,
    },
    8260: {
      H: 0.04,
      L: 0.04,
      O: 0.04,
      C: 0.04,
      V: 0,
      p_w1_L: 0.04,
      p_w1_H: 0.04,
      p_m1_L: 0.04,
      p_m1_H: 0.04,
      p_m3_L: 0.04,
      p_m3_H: 0.04,
      p_m6_L: 0.032,
      p_m6_H: 0.052,
      p_m12_L: 0.032,
      p_m12_H: 0.15,
    },
    8262: {
      H: 0.275,
      L: 0.275,
      O: 0.275,
      C: 0.275,
      V: 0,
      p_w1_L: 0.265,
      p_w1_H: 0.28,
      p_m1_L: 0.226,
      p_m1_H: 0.29,
      p_m3_L: 0.226,
      p_m3_H: 0.365,
      p_m6_L: 0.226,
      p_m6_H: 0.365,
      p_m12_L: 0.2,
      p_m12_H: 0.48,
    },
    8267: {
      H: 0.3,
      L: 0.3,
      O: 0.3,
      C: 0.3,
      V: 0,
      p_w1_L: 0.29,
      p_w1_H: 0.34,
      p_m1_L: 0.29,
      p_m1_H: 0.36,
      p_m3_L: 0.29,
      p_m3_H: 0.45,
      p_m6_L: 0.29,
      p_m6_H: 0.84,
      p_m12_L: 0.29,
      p_m12_H: 0.97,
    },
    8268: {
      H: 0.74,
      L: 0.74,
      O: 0.74,
      C: 0.74,
      V: 8000,
      p_w1_L: 0.74,
      p_w1_H: 0.76,
      p_m1_L: 0.72,
      p_m1_H: 0.88,
      p_m3_L: 0.47,
      p_m3_H: 0.88,
      p_m6_L: 0.305,
      p_m6_H: 0.88,
      p_m12_L: 0.21,
      p_m12_H: 0.88,
    },
    8269: {
      H: 0.177,
      L: 0.177,
      O: 0.177,
      C: 0.177,
      V: 65000,
      p_w1_L: 0.167,
      p_w1_H: 0.19,
      p_m1_L: 0.143,
      p_m1_H: 0.229,
      p_m3_L: 0.085,
      p_m3_H: 0.237,
      p_m6_L: 0.085,
      p_m6_H: 0.237,
      p_m12_L: 0.085,
      p_m12_H: 0.26,
    },
    8270: {
      H: 0.063,
      L: 0.062,
      O: 0.062,
      C: 0.063,
      V: 285000,
      p_w1_L: 0.062,
      p_w1_H: 0.072,
      p_m1_L: 0.057,
      p_m1_H: 0.075,
      p_m3_L: 0.038,
      p_m3_H: 0.078,
      p_m6_L: 0.032,
      p_m6_H: 0.078,
      p_m12_L: 0.024,
      p_m12_H: 0.092,
    },
    8271: {
      H: 0.109,
      L: 0.109,
      O: 0.109,
      C: 0.109,
      V: 0,
      p_w1_L: 0.103,
      p_w1_H: 0.12,
      p_m1_L: 0.09,
      p_m1_H: 0.12,
      p_m3_L: 0.084,
      p_m3_H: 0.12,
      p_m6_L: 0.081,
      p_m6_H: 0.12,
      p_m12_L: 0.08,
      p_m12_H: 0.12,
    },
    8275: {
      H: 0.201,
      L: 0.19,
      O: 0.201,
      C: 0.196,
      V: 5560000,
      p_w1_L: 0.19,
      p_w1_H: 0.229,
      p_m1_L: 0.18,
      p_m1_H: 0.24,
      p_m3_L: 0.177,
      p_m3_H: 0.241,
      p_m6_L: 0.177,
      p_m6_H: 0.265,
      p_m12_L: 0.177,
      p_m12_H: 0.4,
    },
    8277: {
      H: 0.195,
      L: 0.195,
      O: 0.195,
      C: 0.195,
      V: 0,
      p_w1_L: 0.195,
      p_w1_H: 0.216,
      p_m1_L: 0.163,
      p_m1_H: 0.35,
      p_m3_L: 0.163,
      p_m3_H: 0.35,
      p_m6_L: 0.163,
      p_m6_H: 0.42,
      p_m12_L: 0.163,
      p_m12_H: 0.5,
    },
    8279: {
      H: 0.29,
      L: 0.265,
      O: 0.29,
      C: 0.285,
      V: 8448000,
      p_w1_L: 0.26,
      p_w1_H: 0.3,
      p_m1_L: 0.26,
      p_m1_H: 0.33,
      p_m3_L: 0.231,
      p_m3_H: 0.375,
      p_m6_L: 0.191,
      p_m6_H: 0.395,
      p_m12_L: 0.191,
      p_m12_H: 0.395,
    },
    8280: {
      H: 0.109,
      L: 0.109,
      O: 0.109,
      C: 0.109,
      V: 134000,
      p_w1_L: 0.106,
      p_w1_H: 0.122,
      p_m1_L: 0.105,
      p_m1_H: 0.129,
      p_m3_L: 0.1,
      p_m3_H: 0.146,
      p_m6_L: 0.086,
      p_m6_H: 0.15,
      p_m12_L: 0.058,
      p_m12_H: 0.18,
    },
    8281: {
      H: 0.213,
      L: 0.213,
      O: 0.213,
      C: 0.213,
      V: 0,
      p_w1_L: 0.186,
      p_w1_H: 0.315,
      p_m1_L: 0.185,
      p_m1_H: 0.415,
      p_m3_L: 0.185,
      p_m3_H: 1.36,
      p_m6_L: 0.113,
      p_m6_H: 1.36,
      p_m12_L: 0.113,
      p_m12_H: 1.36,
    },
    8282: {
      H: 0.6,
      L: 0.6,
      O: 0.6,
      C: 0.6,
      V: 92000,
      p_w1_L: 0.59,
      p_w1_H: 0.6,
      p_m1_L: 0.43,
      p_m1_H: 0.84,
      p_m3_L: 0.36,
      p_m3_H: 0.84,
      p_m6_L: 0.265,
      p_m6_H: 0.84,
      p_m12_L: 0.242,
      p_m12_H: 0.84,
    },
    8283: {
      H: 0.205,
      L: 0.18,
      O: 0.18,
      C: 0.2,
      V: 1425000,
      p_w1_L: 0.175,
      p_w1_H: 0.205,
      p_m1_L: 0.16,
      p_m1_H: 0.225,
      p_m3_L: 0.12,
      p_m3_H: 0.28,
      p_m6_L: 0.12,
      p_m6_H: 0.28,
      p_m12_L: 0.12,
      p_m12_H: 0.28,
    },
    8285: {
      H: 0.105,
      L: 0.105,
      O: 0.105,
      C: 0.105,
      V: 0,
      p_w1_L: 0.1,
      p_w1_H: 0.107,
      p_m1_L: 0.1,
      p_m1_H: 0.107,
      p_m3_L: 0.08,
      p_m3_H: 0.108,
      p_m6_L: 0.076,
      p_m6_H: 0.108,
      p_m12_L: 0.05,
      p_m12_H: 0.108,
    },
    8286: {
      H: 0.163,
      L: 0.163,
      O: 0.163,
      C: 0.163,
      V: 0,
      p_w1_L: 0.155,
      p_w1_H: 0.163,
      p_m1_L: 0.153,
      p_m1_H: 0.194,
      p_m3_L: 0.115,
      p_m3_H: 0.29,
      p_m6_L: 0.1,
      p_m6_H: 0.29,
      p_m12_L: 0.076,
      p_m12_H: 0.29,
    },
    8287: {
      H: 0.075,
      L: 0.073,
      O: 0.073,
      C: 0.075,
      V: 20000,
      p_w1_L: 0.07,
      p_w1_H: 0.076,
      p_m1_L: 0.07,
      p_m1_H: 0.079,
      p_m3_L: 0.056,
      p_m3_H: 0.231,
      p_m6_L: 0.056,
      p_m6_H: 0.32,
      p_m12_L: 0.056,
      p_m12_H: 0.32,
    },
    8290: {
      H: 0.027,
      L: 0.027,
      O: 0.027,
      C: 0.027,
      V: 0,
      p_w1_L: 0.027,
      p_w1_H: 0.027,
      p_m1_L: 0.025,
      p_m1_H: 0.029,
      p_m3_L: 0.025,
      p_m3_H: 0.037,
      p_m6_L: 0.022,
      p_m6_H: 0.037,
      p_m12_L: 0.022,
      p_m12_H: 0.044,
    },
    8291: {
      H: 0.085,
      L: 0.085,
      O: 0.085,
      C: 0.085,
      V: 0,
      p_w1_L: 0.083,
      p_w1_H: 0.099,
      p_m1_L: 0.068,
      p_m1_H: 0.099,
      p_m3_L: 0.065,
      p_m3_H: 0.099,
      p_m6_L: 0.056,
      p_m6_H: 0.13,
      p_m12_L: 0.056,
      p_m12_H: 1.12,
    },
    8292: {
      H: 0.215,
      L: 0.18,
      O: 0.193,
      C: 0.209,
      V: 10048000,
      p_w1_L: 0.143,
      p_w1_H: 0.215,
      p_m1_L: 0.111,
      p_m1_H: 0.248,
      p_m3_L: 0.111,
      p_m3_H: 0.33,
      p_m6_L: 0.1,
      p_m6_H: 0.33,
      p_m12_L: 0.1,
      p_m12_H: 0.41,
    },
    8293: {
      H: 0.059,
      L: 0.053,
      O: 0.058,
      C: 0.057,
      V: 1925000,
      p_w1_L: 0.052,
      p_w1_H: 0.063,
      p_m1_L: 0.045,
      p_m1_H: 0.079,
      p_m3_L: 0.045,
      p_m3_H: 0.079,
      p_m6_L: 0.04,
      p_m6_H: 0.079,
      p_m12_L: 0.04,
      p_m12_H: 0.098,
    },
    8295: {
      H: 0.79,
      L: 0.79,
      O: 0.79,
      C: 0.79,
      V: 0,
      p_w1_L: 0.79,
      p_w1_H: 0.79,
      p_m1_L: 0.74,
      p_m1_H: 0.9,
      p_m3_L: 0.51,
      p_m3_H: 0.95,
      p_m6_L: 0.24,
      p_m6_H: 1.24,
      p_m12_L: 0.24,
      p_m12_H: 1.24,
    },
    8296: {
      H: 0.199,
      L: 0.174,
      O: 0.174,
      C: 0.194,
      V: 144000,
      p_w1_L: 0.165,
      p_w1_H: 0.2,
      p_m1_L: 0.165,
      p_m1_H: 0.235,
      p_m3_L: 0.164,
      p_m3_H: 0.235,
      p_m6_L: 0.131,
      p_m6_H: 0.235,
      p_m12_L: 0.131,
      p_m12_H: 0.235,
    },
    8297: {
      H: 0.38,
      L: 0.37,
      O: 0.38,
      C: 0.38,
      V: 65000,
      p_w1_L: 0.31,
      p_w1_H: 0.395,
      p_m1_L: 0.25,
      p_m1_H: 0.43,
      p_m3_L: 0.2,
      p_m3_H: 2.13,
      p_m6_L: 0.2,
      p_m6_H: 2.13,
      p_m12_L: 0.2,
      p_m12_H: 2.13,
    },
    8299: {
      H: 0.04,
      L: 0.04,
      O: 0.04,
      C: 0.04,
      V: 576000,
      p_w1_L: 0.04,
      p_w1_H: 0.041,
      p_m1_L: 0.038,
      p_m1_H: 0.058,
      p_m3_L: 0.037,
      p_m3_H: 0.08,
      p_m6_L: 0.034,
      p_m6_H: 0.08,
      p_m12_L: 0.025,
      p_m12_H: 0.08,
    },
    8300: {
      H: 0.038,
      L: 0.038,
      O: 0.038,
      C: 0.038,
      V: 0,
      p_w1_L: 0.038,
      p_w1_H: 0.042,
      p_m1_L: 0.036,
      p_m1_H: 0.042,
      p_m3_L: 0.036,
      p_m3_H: 0.054,
      p_m6_L: 0.036,
      p_m6_H: 0.085,
      p_m12_L: 0.028,
      p_m12_H: 0.085,
    },
    8305: {
      H: 0.16,
      L: 0.154,
      O: 0.154,
      C: 0.16,
      V: 110000,
      p_w1_L: 0.133,
      p_w1_H: 0.168,
      p_m1_L: 0.133,
      p_m1_H: 0.181,
      p_m3_L: 0.111,
      p_m3_H: 0.248,
      p_m6_L: 0.052,
      p_m6_H: 0.248,
      p_m12_L: 0.045,
      p_m12_H: 0.248,
    },
    8307: {
      H: 0.305,
      L: 0.305,
      O: 0.305,
      C: 0.29,
      V: 64000,
      p_w1_L: 0.23,
      p_w1_H: 0.4,
      p_m1_L: 0.164,
      p_m1_H: 0.4,
      p_m3_L: 0.164,
      p_m3_H: 0.4,
      p_m6_L: 0.164,
      p_m6_H: 0.4,
      p_m12_L: 0.147,
      p_m12_H: 0.4,
    },
    8308: {
      H: 0.345,
      L: 0.345,
      O: 0.345,
      C: 0.345,
      V: 24000,
      p_w1_L: 0.325,
      p_w1_H: 0.445,
      p_m1_L: 0.26,
      p_m1_H: 0.445,
      p_m3_L: 0.242,
      p_m3_H: 0.445,
      p_m6_L: 0.242,
      p_m6_H: 0.52,
      p_m12_L: 0.242,
      p_m12_H: 0.87,
    },
    8309: {
      H: 0.125,
      L: 0.113,
      O: 0.115,
      C: 0.125,
      V: 1030000,
      p_w1_L: 0.109,
      p_w1_H: 0.125,
      p_m1_L: 0.1,
      p_m1_H: 0.128,
      p_m3_L: 0.1,
      p_m3_H: 0.15,
      p_m6_L: 0.1,
      p_m6_H: 0.155,
      p_m12_L: 0.05,
      p_m12_H: 0.155,
    },
    8310: {
      H: 0.11,
      L: 0.11,
      O: 0.11,
      C: 0.11,
      V: 0,
      p_w1_L: 0.1,
      p_w1_H: 0.117,
      p_m1_L: 0.1,
      p_m1_H: 0.123,
      p_m3_L: 0.1,
      p_m3_H: 0.136,
      p_m6_L: 0.1,
      p_m6_H: 0.227,
      p_m12_L: 0.1,
      p_m12_H: 0.3,
    },
    8311: {
      H: 0.224,
      L: 0.224,
      O: 0.224,
      C: 0.224,
      V: 0,
      p_w1_L: 0.202,
      p_w1_H: 0.225,
      p_m1_L: 0.201,
      p_m1_H: 0.225,
      p_m3_L: 0.188,
      p_m3_H: 0.26,
      p_m6_L: 0.188,
      p_m6_H: 0.31,
      p_m12_L: 0.13,
      p_m12_H: 0.71,
    },
    8313: {
      H: 0.048,
      L: 0.042,
      O: 0.042,
      C: 0.042,
      V: 530000,
      p_w1_L: 0.042,
      p_w1_H: 0.05,
      p_m1_L: 0.042,
      p_m1_H: 0.07,
      p_m3_L: 0.042,
      p_m3_H: 0.093,
      p_m6_L: 0.041,
      p_m6_H: 0.093,
      p_m12_L: 0.041,
      p_m12_H: 0.375,
    },
    8315: {
      H: 0.47,
      L: 0.395,
      O: 0.425,
      C: 0.455,
      V: 542000,
      p_w1_L: 0.305,
      p_w1_H: 0.47,
      p_m1_L: 0.205,
      p_m1_H: 0.47,
      p_m3_L: 0.186,
      p_m3_H: 0.47,
      p_m6_L: 0.051,
      p_m6_H: 0.47,
      p_m12_L: 0.04,
      p_m12_H: 0.475,
    },
    8316: {
      H: 1.43,
      L: 1.18,
      O: 1.33,
      C: 1.34,
      V: 2550000,
      p_w1_L: 0.9,
      p_w1_H: 2.29,
      p_m1_L: 0.9,
      p_m1_H: 2.46,
      p_m3_L: 0.9,
      p_m3_H: 2.46,
      p_m6_L: 0.72,
      p_m6_H: 2.46,
      p_m12_L: 0.2,
      p_m12_H: 2.46,
    },
    8317: {
      H: 0.295,
      L: 0.295,
      O: 0.295,
      C: 0.295,
      V: 0,
      p_w1_L: 0.295,
      p_w1_H: 0.295,
      p_m1_L: 0.275,
      p_m1_H: 0.4,
      p_m3_L: 0.275,
      p_m3_H: 0.65,
      p_m6_L: 0.275,
      p_m6_H: 0.65,
      p_m12_L: 0.275,
      p_m12_H: 0.65,
    },
    8319: {
      H: 0.128,
      L: 0.128,
      O: 0.128,
      C: 0.128,
      V: 30000,
      p_w1_L: 0.128,
      p_w1_H: 0.133,
      p_m1_L: 0.126,
      p_m1_H: 0.143,
      p_m3_L: 0.126,
      p_m3_H: 0.16,
      p_m6_L: 0.126,
      p_m6_H: 0.17,
      p_m12_L: 0.09,
      p_m12_H: 0.185,
    },
    8320: {
      H: 0.1,
      L: 0.1,
      O: 0.1,
      C: 0.1,
      V: 0,
      p_w1_L: 0.095,
      p_w1_H: 0.105,
      p_m1_L: 0.091,
      p_m1_H: 0.118,
      p_m3_L: 0.091,
      p_m3_H: 0.12,
      p_m6_L: 0.091,
      p_m6_H: 0.15,
      p_m12_L: 0.06,
      p_m12_H: 0.186,
    },
    8321: {
      H: 0.95,
      L: 0.95,
      O: 0.95,
      C: 0.95,
      V: 0,
      p_w1_L: 0.95,
      p_w1_H: 1.1,
      p_m1_L: 0.8,
      p_m1_H: 1.18,
      p_m3_L: 0.8,
      p_m3_H: 1.3,
      p_m6_L: 0.57,
      p_m6_H: 1.49,
      p_m12_L: 0.115,
      p_m12_H: 1.49,
    },
    8325: {
      H: 0.185,
      L: 0.166,
      O: 0.185,
      C: 0.178,
      V: 710000,
      p_w1_L: 0.166,
      p_w1_H: 0.191,
      p_m1_L: 0.156,
      p_m1_H: 0.191,
      p_m3_L: 0.14,
      p_m3_H: 0.191,
      p_m6_L: 0.14,
      p_m6_H: 0.27,
      p_m12_L: 0.115,
      p_m12_H: 0.27,
    },
    8326: {
      H: 0.195,
      L: 0.194,
      O: 0.195,
      C: 0.195,
      V: 1005000,
      p_w1_L: 0.194,
      p_w1_H: 0.2,
      p_m1_L: 0.12,
      p_m1_H: 0.232,
      p_m3_L: 0.12,
      p_m3_H: 0.25,
      p_m6_L: 0.11,
      p_m6_H: 0.25,
      p_m12_L: 0.101,
      p_m12_H: 0.295,
    },
    8328: {
      H: 4.25,
      L: 3.89,
      O: 3.9,
      C: 4.14,
      V: 1397210,
      p_w1_L: 3.74,
      p_w1_H: 4.25,
      p_m1_L: 3.74,
      p_m1_H: 4.47,
      p_m3_L: 3.74,
      p_m3_H: 6.72,
      p_m6_L: 3.74,
      p_m6_H: 8,
      p_m12_L: 1.98,
      p_m12_H: 8.88,
    },
    8329: {
      H: 0.225,
      L: 0.212,
      O: 0.218,
      C: 0.222,
      V: 270000,
      p_w1_L: 0.208,
      p_w1_H: 0.234,
      p_m1_L: 0.208,
      p_m1_H: 0.249,
      p_m3_L: 0.208,
      p_m3_H: 0.249,
      p_m6_L: 0.208,
      p_m6_H: 0.275,
      p_m12_L: 0.208,
      p_m12_H: 0.37,
    },
    8331: {
      H: 0.39,
      L: 0.39,
      O: 0.39,
      C: 0.39,
      V: 10000,
      p_w1_L: 0.39,
      p_w1_H: 0.45,
      p_m1_L: 0.39,
      p_m1_H: 0.56,
      p_m3_L: 0.044,
      p_m3_H: 0.562,
      p_m6_L: 0.044,
      p_m6_H: 1.425,
      p_m12_L: 0.044,
      p_m12_H: 1.425,
    },
    8333: {
      H: 0.138,
      L: 0.138,
      O: 0.138,
      C: 0.138,
      V: 0,
      p_w1_L: 0.131,
      p_w1_H: 0.156,
      p_m1_L: 0.131,
      p_m1_H: 0.169,
      p_m3_L: 0.131,
      p_m3_H: 0.188,
      p_m6_L: 0.131,
      p_m6_H: 0.202,
      p_m12_L: 0.082,
      p_m12_H: 0.221,
    },
    8337: {
      H: 0.25,
      L: 0.25,
      O: 0.25,
      C: 0.25,
      V: 20000,
      p_w1_L: 0.25,
      p_w1_H: 0.255,
      p_m1_L: 0.25,
      p_m1_H: 0.255,
      p_m3_L: 0.16,
      p_m3_H: 0.75,
      p_m6_L: 0.072,
      p_m6_H: 0.75,
      p_m12_L: 0.072,
      p_m12_H: 0.75,
    },
    8340: {
      H: 0.042,
      L: 0.037,
      O: 0.038,
      C: 0.04,
      V: 610000,
      p_w1_L: 0.036,
      p_w1_H: 0.042,
      p_m1_L: 0.036,
      p_m1_H: 0.042,
      p_m3_L: 0.035,
      p_m3_H: 0.048,
      p_m6_L: 0.03,
      p_m6_H: 0.058,
      p_m12_L: 0.03,
      p_m12_H: 0.475,
    },
    8341: {
      H: 0.047,
      L: 0.04,
      O: 0.042,
      C: 0.042,
      V: 715000,
      p_w1_L: 0.04,
      p_w1_H: 0.052,
      p_m1_L: 0.039,
      p_m1_H: 0.055,
      p_m3_L: 0.035,
      p_m3_H: 0.058,
      p_m6_L: 0.035,
      p_m6_H: 0.066,
      p_m12_L: 0.035,
      p_m12_H: 1.08,
    },
    8347: {
      H: 0.05,
      L: 0.048,
      O: 0.048,
      C: 0.049,
      V: 8184000,
      p_w1_L: 0.046,
      p_w1_H: 0.053,
      p_m1_L: 0.036,
      p_m1_H: 0.07,
      p_m3_L: 0.036,
      p_m3_H: 0.189,
      p_m6_L: 0.036,
      p_m6_H: 0.189,
      p_m12_L: 0.036,
      p_m12_H: 0.27,
    },
    8348: {
      H: 0.375,
      L: 0.32,
      O: 0.375,
      C: 0.35,
      V: 122000,
      p_w1_L: 0.32,
      p_w1_H: 0.375,
      p_m1_L: 0.295,
      p_m1_H: 0.385,
      p_m3_L: 0.295,
      p_m3_H: 0.4,
      p_m6_L: 0.295,
      p_m6_H: 0.425,
      p_m12_L: 0.295,
      p_m12_H: 0.425,
    },
    8349: {
      H: 2.88,
      L: 2.8,
      O: 2.88,
      C: 2.8,
      V: 190000,
      p_w1_L: 2.8,
      p_w1_H: 3.04,
      p_m1_L: 2.68,
      p_m1_H: 3.58,
      p_m3_L: 2.52,
      p_m3_H: 3.78,
      p_m6_L: 0.51,
      p_m6_H: 5.5,
      p_m12_L: 0.47,
      p_m12_H: 5.5,
    },
    8350: {
      H: 0.22,
      L: 0.22,
      O: 0.22,
      C: 0.22,
      V: 0,
      p_w1_L: 0.22,
      p_w1_H: 0.255,
      p_m1_L: 0.205,
      p_m1_H: 0.325,
      p_m3_L: 0.15,
      p_m3_H: 0.325,
      p_m6_L: 0.15,
      p_m6_H: 0.325,
      p_m12_L: 0.118,
      p_m12_H: 0.325,
    },
    8351: {
      H: 0.01,
      L: 0.01,
      O: 0.01,
      C: 0.01,
      V: 0,
      p_w1_L: 0.01,
      p_w1_H: 0.01,
      p_m1_L: 0.01,
      p_m1_H: 0.01,
      p_m3_L: 0.01,
      p_m3_H: 0.01,
      p_m6_L: 0.01,
      p_m6_H: 0.01,
      p_m12_L: 0.01,
      p_m12_H: 0.01,
    },
    8353: {
      H: 0.42,
      L: 0.42,
      O: 0.42,
      C: 0.42,
      V: 0,
      p_w1_L: 0.415,
      p_w1_H: 0.43,
      p_m1_L: 0.4,
      p_m1_H: 0.43,
      p_m3_L: 0.32,
      p_m3_H: 0.45,
      p_m6_L: 0.245,
      p_m6_H: 0.6,
      p_m12_L: 0.22,
      p_m12_H: 0.6,
    },
    8356: {
      H: 0.016,
      L: 0.013,
      O: 0.014,
      C: 0.015,
      V: 1050000,
      p_w1_L: 0.012,
      p_w1_H: 0.017,
      p_m1_L: 0.012,
      p_m1_H: 0.017,
      p_m3_L: 0.012,
      p_m3_H: 0.018,
      p_m6_L: 0.011,
      p_m6_H: 0.019,
      p_m12_L: 0.011,
      p_m12_H: 0.026,
    },
    8357: {
      H: 0.43,
      L: 0.38,
      O: 0.38,
      C: 0.41,
      V: 115000,
      p_w1_L: 0.38,
      p_w1_H: 0.435,
      p_m1_L: 0.38,
      p_m1_H: 0.66,
      p_m3_L: 0.22,
      p_m3_H: 0.66,
      p_m6_L: 0.091,
      p_m6_H: 0.66,
      p_m12_L: 0.091,
      p_m12_H: 0.66,
    },
    8360: {
      H: 0.58,
      L: 0.53,
      O: 0.57,
      C: 0.54,
      V: 360000,
      p_w1_L: 0.53,
      p_w1_H: 0.63,
      p_m1_L: 0.41,
      p_m1_H: 0.66,
      p_m3_L: 0.275,
      p_m3_H: 0.66,
      p_m6_L: 0.031,
      p_m6_H: 0.66,
      p_m12_L: 0.031,
      p_m12_H: 0.66,
    },
    8362: {
      H: 0.045,
      L: 0.041,
      O: 0.045,
      C: 0.045,
      V: 360000,
      p_w1_L: 0.041,
      p_w1_H: 0.051,
      p_m1_L: 0.04,
      p_m1_H: 0.056,
      p_m3_L: 0.04,
      p_m3_H: 0.056,
      p_m6_L: 0.029,
      p_m6_H: 0.056,
      p_m12_L: 0.027,
      p_m12_H: 0.066,
    },
    8363: {
      H: 0.33,
      L: 0.325,
      O: 0.33,
      C: 0.33,
      V: 142000,
      p_w1_L: 0.28,
      p_w1_H: 0.37,
      p_m1_L: 0.28,
      p_m1_H: 0.4,
      p_m3_L: 0.255,
      p_m3_H: 0.55,
      p_m6_L: 0.255,
      p_m6_H: 0.74,
      p_m12_L: 0.255,
      p_m12_H: 2.49,
    },
    8365: {
      H: 0.53,
      L: 0.51,
      O: 0.53,
      C: 0.52,
      V: 4270000,
      p_w1_L: 0.5,
      p_w1_H: 0.57,
      p_m1_L: 0.139,
      p_m1_H: 0.62,
      p_m3_L: 0.115,
      p_m3_H: 0.62,
      p_m6_L: 0.115,
      p_m6_H: 0.62,
      p_m12_L: 0.071,
      p_m12_H: 0.62,
    },
    8366: {
      H: 0.022,
      L: 0.022,
      O: 0.022,
      C: 0.022,
      V: 0,
      p_w1_L: 0.022,
      p_w1_H: 0.022,
      p_m1_L: 0.022,
      p_m1_H: 0.022,
      p_m3_L: 0.022,
      p_m3_H: 0.022,
      p_m6_L: 0.021,
      p_m6_H: 0.122,
      p_m12_L: 0.021,
      p_m12_H: 0.25,
    },
    8367: {
      H: 0.6,
      L: 0.55,
      O: 0.6,
      C: 0.56,
      V: 140000,
      p_w1_L: 0.55,
      p_w1_H: 0.65,
      p_m1_L: 0.5,
      p_m1_H: 0.83,
      p_m3_L: 0.5,
      p_m3_H: 1,
      p_m6_L: 0.092,
      p_m6_H: 1.8,
      p_m12_L: 0.055,
      p_m12_H: 1.8,
    },
    8368: {
      H: 0.43,
      L: 0.43,
      O: 0.43,
      C: 0.43,
      V: 0,
      p_w1_L: 0.405,
      p_w1_H: 0.445,
      p_m1_L: 0.405,
      p_m1_H: 0.485,
      p_m3_L: 0.4,
      p_m3_H: 0.66,
      p_m6_L: 0.072,
      p_m6_H: 0.66,
      p_m12_L: 0.042,
      p_m12_H: 0.66,
    },
    8370: {
      H: 0.335,
      L: 0.33,
      O: 0.335,
      C: 0.33,
      V: 1928000,
      p_w1_L: 0.33,
      p_w1_H: 0.35,
      p_m1_L: 0.315,
      p_m1_H: 0.36,
      p_m3_L: 0.315,
      p_m3_H: 0.385,
      p_m6_L: 0.315,
      p_m6_H: 0.48,
      p_m12_L: 0.24,
      p_m12_H: 0.48,
    },
    8371: {
      H: 0.93,
      L: 0.93,
      O: 0.93,
      C: 0.93,
      V: 12000,
      p_w1_L: 0.86,
      p_w1_H: 0.94,
      p_m1_L: 0.86,
      p_m1_H: 1.08,
      p_m3_L: 0.86,
      p_m3_H: 1.3,
      p_m6_L: 0.86,
      p_m6_H: 1.3,
      p_m12_L: 0.86,
      p_m12_H: 1.36,
    },
    8372: {
      H: 0.082,
      L: 0.082,
      O: 0.082,
      C: 0.082,
      V: 0,
      p_w1_L: 0.08,
      p_w1_H: 0.084,
      p_m1_L: 0.077,
      p_m1_H: 0.084,
      p_m3_L: 0.077,
      p_m3_H: 0.085,
      p_m6_L: 0.07,
      p_m6_H: 0.086,
      p_m12_L: 0.07,
      p_m12_H: 0.094,
    },
    8373: {
      H: 0.285,
      L: 0.28,
      O: 0.285,
      C: 0.285,
      V: 335000,
      p_w1_L: 0.28,
      p_w1_H: 0.305,
      p_m1_L: 0.265,
      p_m1_H: 0.325,
      p_m3_L: 0.246,
      p_m3_H: 0.325,
      p_m6_L: 0.246,
      p_m6_H: 0.38,
      p_m12_L: 0.246,
      p_m12_H: 0.455,
    },
    8375: {
      H: 0.05,
      L: 0.05,
      O: 0.05,
      C: 0.05,
      V: 3500000,
      p_w1_L: 0.048,
      p_w1_H: 0.051,
      p_m1_L: 0.042,
      p_m1_H: 0.086,
      p_m3_L: 0.042,
      p_m3_H: 0.158,
      p_m6_L: 0.042,
      p_m6_H: 0.184,
      p_m12_L: 0.042,
      p_m12_H: 0.184,
    },
    8377: {
      H: 0.5,
      L: 0.5,
      O: 0.5,
      C: 0.5,
      V: 0,
      p_w1_L: 0.455,
      p_w1_H: 0.54,
      p_m1_L: 0.405,
      p_m1_H: 0.7,
      p_m3_L: 0.39,
      p_m3_H: 0.7,
      p_m6_L: 0.39,
      p_m6_H: 0.7,
      p_m12_L: 0.2,
      p_m12_H: 0.82,
    },
    8379: {
      H: 0.2,
      L: 0.192,
      O: 0.195,
      C: 0.198,
      V: 190000,
      p_w1_L: 0.189,
      p_w1_H: 0.215,
      p_m1_L: 0.17,
      p_m1_H: 0.239,
      p_m3_L: 0.063,
      p_m3_H: 0.239,
      p_m6_L: 0.063,
      p_m6_H: 0.239,
      p_m12_L: 0.051,
      p_m12_H: 0.239,
    },
    8383: {
      H: 0.128,
      L: 0.127,
      O: 0.128,
      C: 0.127,
      V: 88000,
      p_w1_L: 0.113,
      p_w1_H: 0.147,
      p_m1_L: 0.113,
      p_m1_H: 0.15,
      p_m3_L: 0.113,
      p_m3_H: 0.179,
      p_m6_L: 0.11,
      p_m6_H: 0.2,
      p_m12_L: 0.076,
      p_m12_H: 0.2,
    },
    8385: {
      H: 0.064,
      L: 0.057,
      O: 0.062,
      C: 0.06,
      V: 12680000,
      p_w1_L: 0.057,
      p_w1_H: 0.068,
      p_m1_L: 0.057,
      p_m1_H: 0.123,
      p_m3_L: 0.057,
      p_m3_H: 0.295,
      p_m6_L: 0.057,
      p_m6_H: 0.95,
      p_m12_L: 0.057,
      p_m12_H: 0.95,
    },
    8391: {
      H: 0.84,
      L: 0.8,
      O: 0.8,
      C: 0.84,
      V: 92000,
      p_w1_L: 0.72,
      p_w1_H: 1.04,
      p_m1_L: 0.72,
      p_m1_H: 1.04,
      p_m3_L: 0.72,
      p_m3_H: 1.06,
      p_m6_L: 0.72,
      p_m6_H: 1.3,
      p_m12_L: 0.45,
      p_m12_H: 1.46,
    },
    8392: {
      H: 0.122,
      L: 0.108,
      O: 0.111,
      C: 0.113,
      V: 2460000,
      p_w1_L: 0.107,
      p_w1_H: 0.123,
      p_m1_L: 0.103,
      p_m1_H: 0.124,
      p_m3_L: 0.092,
      p_m3_H: 0.124,
      p_m6_L: 0.078,
      p_m6_H: 0.137,
      p_m12_L: 0.061,
      p_m12_H: 0.149,
    },
    8395: {
      H: 0.88,
      L: 0.84,
      O: 0.85,
      C: 0.88,
      V: 5600000,
      p_w1_L: 0.84,
      p_w1_H: 0.89,
      p_m1_L: 0.76,
      p_m1_H: 0.96,
      p_m3_L: 0.58,
      p_m3_H: 0.96,
      p_m6_L: 0.365,
      p_m6_H: 0.96,
      p_m12_L: 0.24,
      p_m12_H: 0.96,
    },
    8400: {
      H: 0.046,
      L: 0.046,
      O: 0.046,
      C: 0.046,
      V: 0,
      p_w1_L: 0.045,
      p_w1_H: 0.046,
      p_m1_L: 0.045,
      p_m1_H: 0.055,
      p_m3_L: 0.044,
      p_m3_H: 0.065,
      p_m6_L: 0.037,
      p_m6_H: 0.088,
      p_m12_L: 0.035,
      p_m12_H: 0.088,
    },
    8401: {
      H: 0.57,
      L: 0.53,
      O: 0.59,
      C: 0.55,
      V: 1568000,
      p_w1_L: 0.51,
      p_w1_H: 0.67,
      p_m1_L: 0.51,
      p_m1_H: 0.93,
      p_m3_L: 0.405,
      p_m3_H: 1.13,
      p_m6_L: 0.405,
      p_m6_H: 1.87,
      p_m12_L: 0.405,
      p_m12_H: 4.07,
    },
    8402: {
      H: 2.36,
      L: 2.29,
      O: 2.29,
      C: 2.32,
      V: 115000,
      p_w1_L: 2.25,
      p_w1_H: 2.38,
      p_m1_L: 2.2,
      p_m1_H: 2.55,
      p_m3_L: 2.14,
      p_m3_H: 2.69,
      p_m6_L: 1.69,
      p_m6_H: 3,
      p_m12_L: 0.082,
      p_m12_H: 3,
    },
    8403: {
      H: 1.08,
      L: 1.08,
      O: 1.08,
      C: 1.08,
      V: 1921000,
      p_w1_L: 1.08,
      p_w1_H: 1.1,
      p_m1_L: 1,
      p_m1_H: 1.12,
      p_m3_L: 1,
      p_m3_H: 1.27,
      p_m6_L: 0.039,
      p_m6_H: 1.27,
      p_m12_L: 0.035,
      p_m12_H: 1.27,
    },
    8405: {
      H: 0.93,
      L: 0.93,
      O: 0.93,
      C: 0.93,
      V: 0,
      p_w1_L: 0.93,
      p_w1_H: 0.93,
      p_m1_L: 0.89,
      p_m1_H: 1.08,
      p_m3_L: 0.8,
      p_m3_H: 1.2,
      p_m6_L: 0.78,
      p_m6_H: 1.2,
      p_m12_L: 0.78,
      p_m12_H: 1.2,
    },
    8406: {
      H: 0.69,
      L: 0.49,
      O: 0.6,
      C: 0.51,
      V: 12740000,
      p_w1_L: 0.49,
      p_w1_H: 0.75,
      p_m1_L: 0.49,
      p_m1_H: 0.95,
      p_m3_L: 0.175,
      p_m3_H: 1.98,
      p_m6_L: 0.162,
      p_m6_H: 1.98,
      p_m12_L: 0.125,
      p_m12_H: 1.98,
    },
    8411: {
      H: 0.21,
      L: 0.198,
      O: 0.198,
      C: 0.208,
      V: 3290000,
      p_w1_L: 0.18,
      p_w1_H: 0.21,
      p_m1_L: 0.172,
      p_m1_H: 0.21,
      p_m3_L: 0.156,
      p_m3_H: 0.21,
      p_m6_L: 0.156,
      p_m6_H: 0.21,
      p_m12_L: 0.156,
      p_m12_H: 0.21,
    },
    8412: {
      H: 0.034,
      L: 0.032,
      O: 0.032,
      C: 0.034,
      V: 1670000,
      p_w1_L: 0.032,
      p_w1_H: 0.035,
      p_m1_L: 0.03,
      p_m1_H: 0.04,
      p_m3_L: 0.03,
      p_m3_H: 0.25,
      p_m6_L: 0.03,
      p_m6_H: 0.72,
      p_m12_L: 0.03,
      p_m12_H: 0.72,
    },
    8413: {
      H: 0.176,
      L: 0.176,
      O: 0.176,
      C: 0.176,
      V: 0,
      p_w1_L: 0.176,
      p_w1_H: 0.176,
      p_m1_L: 0.126,
      p_m1_H: 0.221,
      p_m3_L: 0.126,
      p_m3_H: 0.28,
      p_m6_L: 0.126,
      p_m6_H: 0.35,
      p_m12_L: 0.126,
      p_m12_H: 0.45,
    },
    8416: {
      H: 0.132,
      L: 0.132,
      O: 0.132,
      C: 0.132,
      V: 5000,
      p_w1_L: 0.12,
      p_w1_H: 0.132,
      p_m1_L: 0.12,
      p_m1_H: 0.139,
      p_m3_L: 0.108,
      p_m3_H: 0.145,
      p_m6_L: 0.073,
      p_m6_H: 0.145,
      p_m12_L: 0.06,
      p_m12_H: 0.145,
    },
    8417: {
      H: 0.031,
      L: 0.029,
      O: 0.031,
      C: 0.029,
      V: 1600000,
      p_w1_L: 0.028,
      p_w1_H: 0.031,
      p_m1_L: 0.026,
      p_m1_H: 0.032,
      p_m3_L: 0.026,
      p_m3_H: 0.034,
      p_m6_L: 0.026,
      p_m6_H: 0.045,
      p_m12_L: 0.026,
      p_m12_H: 0.045,
    },
    8418: {
      H: 1.14,
      L: 1.07,
      O: 1.11,
      C: 1.12,
      V: 366000,
      p_w1_L: 1.02,
      p_w1_H: 1.23,
      p_m1_L: 1,
      p_m1_H: 1.29,
      p_m3_L: 0.96,
      p_m3_H: 1.54,
      p_m6_L: 0.96,
      p_m6_H: 1.74,
      p_m12_L: 0.96,
      p_m12_H: 2.1,
    },
    8419: {
      H: 0.24,
      L: 0.24,
      O: 0.24,
      C: 0.24,
      V: 0,
      p_w1_L: 0.19,
      p_w1_H: 0.249,
      p_m1_L: 0.189,
      p_m1_H: 0.249,
      p_m3_L: 0.182,
      p_m3_H: 0.325,
      p_m6_L: 0.18,
      p_m6_H: 0.325,
      p_m12_L: 0.18,
      p_m12_H: 0.4,
    },
    8420: {
      H: 0.27,
      L: 0.27,
      O: 0.27,
      C: 0.27,
      V: 0,
      p_w1_L: 0.265,
      p_w1_H: 0.27,
      p_m1_L: 0.24,
      p_m1_H: 0.295,
      p_m3_L: 0.189,
      p_m3_H: 0.355,
      p_m6_L: 0.13,
      p_m6_H: 0.365,
      p_m12_L: 0.13,
      p_m12_H: 0.65,
    },
    8422: {
      H: 0.045,
      L: 0.043,
      O: 0.043,
      C: 0.044,
      V: 880000,
      p_w1_L: 0.04,
      p_w1_H: 0.046,
      p_m1_L: 0.04,
      p_m1_H: 0.063,
      p_m3_L: 0.04,
      p_m3_H: 0.072,
      p_m6_L: 0.035,
      p_m6_H: 0.27,
      p_m12_L: 0.035,
      p_m12_H: 0.44,
    },
    8423: {
      H: 0.229,
      L: 0.22,
      O: 0.22,
      C: 0.229,
      V: 100000,
      p_w1_L: 0.22,
      p_w1_H: 0.242,
      p_m1_L: 0.22,
      p_m1_H: 0.265,
      p_m3_L: 0.18,
      p_m3_H: 0.3,
      p_m6_L: 0.18,
      p_m6_H: 0.3,
      p_m12_L: 0.18,
      p_m12_H: 0.34,
    },
    8425: {
      H: 0.335,
      L: 0.33,
      O: 0.335,
      C: 0.335,
      V: 76000,
      p_w1_L: 0.32,
      p_w1_H: 0.43,
      p_m1_L: 0.28,
      p_m1_H: 0.43,
      p_m3_L: 0.265,
      p_m3_H: 0.59,
      p_m6_L: 0.265,
      p_m6_H: 1.04,
      p_m12_L: 0.265,
      p_m12_H: 1.7,
    },
    8426: {
      H: 0.141,
      L: 0.139,
      O: 0.139,
      C: 0.141,
      V: 150000,
      p_w1_L: 0.129,
      p_w1_H: 0.141,
      p_m1_L: 0.129,
      p_m1_H: 0.15,
      p_m3_L: 0.129,
      p_m3_H: 0.165,
      p_m6_L: 0.129,
      p_m6_H: 0.177,
      p_m12_L: 0.129,
      p_m12_H: 0.18,
    },
    8427: {
      H: 0.285,
      L: 0.285,
      O: 0.285,
      C: 0.285,
      V: 0,
      p_w1_L: 0.275,
      p_w1_H: 0.285,
      p_m1_L: 0.255,
      p_m1_H: 0.31,
      p_m3_L: 0.255,
      p_m3_H: 0.435,
      p_m6_L: 0.255,
      p_m6_H: 1,
      p_m12_L: 0.043,
      p_m12_H: 1.024,
    },
    8428: {
      H: 0.15,
      L: 0.144,
      O: 0.15,
      C: 0.144,
      V: 48000,
      p_w1_L: 0.138,
      p_w1_H: 0.191,
      p_m1_L: 0.138,
      p_m1_H: 0.215,
      p_m3_L: 0.075,
      p_m3_H: 0.79,
      p_m6_L: 0.021,
      p_m6_H: 0.79,
      p_m12_L: 0.018,
      p_m12_H: 0.79,
    },
    8429: {
      H: 0.163,
      L: 0.158,
      O: 0.162,
      C: 0.158,
      V: 585000,
      p_w1_L: 0.158,
      p_w1_H: 0.164,
      p_m1_L: 0.155,
      p_m1_H: 0.164,
      p_m3_L: 0.147,
      p_m3_H: 0.365,
      p_m6_L: 0.147,
      p_m6_H: 0.4,
      p_m12_L: 0.147,
      p_m12_H: 0.4,
    },
    8430: {
      H: 0.112,
      L: 0.112,
      O: 0.112,
      C: 0.112,
      V: 0,
      p_w1_L: 0.11,
      p_w1_H: 0.127,
      p_m1_L: 0.11,
      p_m1_H: 0.139,
      p_m3_L: 0.095,
      p_m3_H: 0.16,
      p_m6_L: 0.09,
      p_m6_H: 0.195,
      p_m12_L: 0.06,
      p_m12_H: 0.36,
    },
    8431: {
      H: 0.028,
      L: 0.028,
      O: 0.028,
      C: 0.028,
      V: 0,
      p_w1_L: 0.028,
      p_w1_H: 0.028,
      p_m1_L: 0.028,
      p_m1_H: 0.028,
      p_m3_L: 0.028,
      p_m3_H: 0.028,
      p_m6_L: 0.025,
      p_m6_H: 0.043,
      p_m12_L: 0.025,
      p_m12_H: 0.049,
    },
    8432: {
      H: 0.055,
      L: 0.055,
      O: 0.055,
      C: 0.055,
      V: 0,
      p_w1_L: 0.052,
      p_w1_H: 0.058,
      p_m1_L: 0.052,
      p_m1_H: 0.065,
      p_m3_L: 0.05,
      p_m3_H: 0.065,
      p_m6_L: 0.05,
      p_m6_H: 0.08,
      p_m12_L: 0.05,
      p_m12_H: 0.095,
    },
    8436: {
      H: 0.231,
      L: 0.23,
      O: 0.231,
      C: 0.23,
      V: 235000,
      p_w1_L: 0.23,
      p_w1_H: 0.24,
      p_m1_L: 0.22,
      p_m1_H: 0.255,
      p_m3_L: 0.22,
      p_m3_H: 0.3,
      p_m6_L: 0.22,
      p_m6_H: 0.3,
      p_m12_L: 0.22,
      p_m12_H: 0.38,
    },
    8437: {
      H: 0.14,
      L: 0.13,
      O: 0.14,
      C: 0.14,
      V: 3240000,
      p_w1_L: 0.13,
      p_w1_H: 0.149,
      p_m1_L: 0.13,
      p_m1_H: 0.166,
      p_m3_L: 0.105,
      p_m3_H: 0.45,
      p_m6_L: 0.105,
      p_m6_H: 0.45,
      p_m12_L: 0.105,
      p_m12_H: 0.57,
    },
    8439: {
      H: 1.6,
      L: 1.6,
      O: 1.6,
      C: 1.6,
      V: 2000,
      p_w1_L: 1.46,
      p_w1_H: 1.6,
      p_m1_L: 0.76,
      p_m1_H: 1.6,
      p_m3_L: 0.76,
      p_m3_H: 1.62,
      p_m6_L: 0.76,
      p_m6_H: 1.62,
      p_m12_L: 0.76,
      p_m12_H: 1.62,
    },
    8441: {
      H: 0.83,
      L: 0.78,
      O: 0.8,
      C: 0.81,
      V: 2300000,
      p_w1_L: 0.75,
      p_w1_H: 0.83,
      p_m1_L: 0.61,
      p_m1_H: 0.85,
      p_m3_L: 0.61,
      p_m3_H: 0.85,
      p_m6_L: 0.61,
      p_m6_H: 0.85,
      p_m12_L: 0.44,
      p_m12_H: 0.85,
    },
    8445: {
      H: 0.3,
      L: 0.3,
      O: 0.3,
      C: 0.3,
      V: 0,
      p_w1_L: 0.275,
      p_w1_H: 0.3,
      p_m1_L: 0.275,
      p_m1_H: 0.32,
      p_m3_L: 0.275,
      p_m3_H: 0.455,
      p_m6_L: 0.25,
      p_m6_H: 0.57,
      p_m12_L: 0.23,
      p_m12_H: 0.645,
    },
    8446: {
      H: 0.24,
      L: 0.24,
      O: 0.24,
      C: 0.24,
      V: 0,
      p_w1_L: 0.24,
      p_w1_H: 0.26,
      p_m1_L: 0.223,
      p_m1_H: 0.275,
      p_m3_L: 0.11,
      p_m3_H: 0.285,
      p_m6_L: 0.11,
      p_m6_H: 0.285,
      p_m12_L: 0.11,
      p_m12_H: 0.285,
    },
    8447: {
      H: 0.083,
      L: 0.08,
      O: 0.08,
      C: 0.083,
      V: 50000,
      p_w1_L: 0.072,
      p_w1_H: 0.086,
      p_m1_L: 0.071,
      p_m1_H: 0.093,
      p_m3_L: 0.061,
      p_m3_H: 0.105,
      p_m6_L: 0.04,
      p_m6_H: 0.19,
      p_m12_L: 0.036,
      p_m12_H: 0.19,
    },
    8448: {
      H: 0.024,
      L: 0.023,
      O: 0.023,
      C: 0.024,
      V: 340000,
      p_w1_L: 0.023,
      p_w1_H: 0.024,
      p_m1_L: 0.022,
      p_m1_H: 0.028,
      p_m3_L: 0.022,
      p_m3_H: 0.03,
      p_m6_L: 0.022,
      p_m6_H: 0.032,
      p_m12_L: 0.022,
      p_m12_H: 0.238,
    },
    8450: {
      H: 0.062,
      L: 0.062,
      O: 0.062,
      C: 0.062,
      V: 10000,
      p_w1_L: 0.06,
      p_w1_H: 0.07,
      p_m1_L: 0.06,
      p_m1_H: 0.086,
      p_m3_L: 0.059,
      p_m3_H: 0.087,
      p_m6_L: 0.056,
      p_m6_H: 0.158,
      p_m12_L: 0.053,
      p_m12_H: 0.158,
    },
    8451: {
      H: 0.053,
      L: 0.053,
      O: 0.053,
      C: 0.053,
      V: 0,
      p_w1_L: 0.051,
      p_w1_H: 0.057,
      p_m1_L: 0.051,
      p_m1_H: 0.079,
      p_m3_L: 0.051,
      p_m3_H: 0.088,
      p_m6_L: 0.051,
      p_m6_H: 0.088,
      p_m12_L: 0.05,
      p_m12_H: 0.088,
    },
    8452: {
      H: 0.49,
      L: 0.49,
      O: 0.49,
      C: 0.49,
      V: 0,
      p_w1_L: 0.345,
      p_w1_H: 0.49,
      p_m1_L: 0.345,
      p_m1_H: 0.495,
      p_m3_L: 0.315,
      p_m3_H: 0.5,
      p_m6_L: 0.315,
      p_m6_H: 0.69,
      p_m12_L: 0.315,
      p_m12_H: 0.7,
    },
    8455: {
      H: 0.051,
      L: 0.044,
      O: 0.048,
      C: 0.051,
      V: 530000,
      p_w1_L: 0.04,
      p_w1_H: 0.058,
      p_m1_L: 0.04,
      p_m1_H: 0.083,
      p_m3_L: 0.04,
      p_m3_H: 0.099,
      p_m6_L: 0.04,
      p_m6_H: 0.128,
      p_m12_L: 0.04,
      p_m12_H: 0.26,
    },
    8456: {
      H: 0.44,
      L: 0.43,
      O: 0.44,
      C: 0.43,
      V: 24000,
      p_w1_L: 0.43,
      p_w1_H: 0.46,
      p_m1_L: 0.4,
      p_m1_H: 0.6,
      p_m3_L: 0.36,
      p_m3_H: 0.85,
      p_m6_L: 0.26,
      p_m6_H: 0.89,
      p_m12_L: 0.26,
      p_m12_H: 1.36,
    },
    8460: {
      H: 0.04,
      L: 0.038,
      O: 0.038,
      C: 0.038,
      V: 140000,
      p_w1_L: 0.03,
      p_w1_H: 0.042,
      p_m1_L: 0.028,
      p_m1_H: 0.042,
      p_m3_L: 0.028,
      p_m3_H: 0.085,
      p_m6_L: 0.028,
      p_m6_H: 0.085,
      p_m12_L: 0.028,
      p_m12_H: 0.109,
    },
    8462: {
      H: 0.166,
      L: 0.166,
      O: 0.166,
      C: 0.166,
      V: 0,
      p_w1_L: 0.154,
      p_w1_H: 0.17,
      p_m1_L: 0.154,
      p_m1_H: 0.26,
      p_m3_L: 0.154,
      p_m3_H: 0.355,
      p_m6_L: 0.154,
      p_m6_H: 0.45,
      p_m12_L: 0.154,
      p_m12_H: 0.94,
    },
    8471: {
      H: 0.55,
      L: 0.52,
      O: 0.53,
      C: 0.55,
      V: 100000,
      p_w1_L: 0.5,
      p_w1_H: 0.63,
      p_m1_L: 0.5,
      p_m1_H: 0.72,
      p_m3_L: 0.46,
      p_m3_H: 0.72,
      p_m6_L: 0.28,
      p_m6_H: 0.82,
      p_m12_L: 0.15,
      p_m12_H: 0.95,
    },
    8472: {
      H: 0.85,
      L: 0.75,
      O: 0.79,
      C: 0.85,
      V: 1465000,
      p_w1_L: 0.66,
      p_w1_H: 0.94,
      p_m1_L: 0.66,
      p_m1_H: 1.21,
      p_m3_L: 0.1,
      p_m3_H: 1.72,
      p_m6_L: 0.1,
      p_m6_H: 1.95,
      p_m12_L: 0.1,
      p_m12_H: 1.95,
    },
    8473: {
      H: 0.245,
      L: 0.245,
      O: 0.245,
      C: 0.244,
      V: 10000,
      p_w1_L: 0.239,
      p_w1_H: 0.245,
      p_m1_L: 0.237,
      p_m1_H: 0.285,
      p_m3_L: 0.231,
      p_m3_H: 0.285,
      p_m6_L: 0.231,
      p_m6_H: 0.305,
      p_m12_L: 0.17,
      p_m12_H: 0.49,
    },
    8475: {
      H: 0.087,
      L: 0.086,
      O: 0.086,
      C: 0.087,
      V: 20000,
      p_w1_L: 0.085,
      p_w1_H: 0.092,
      p_m1_L: 0.083,
      p_m1_H: 0.104,
      p_m3_L: 0.083,
      p_m3_H: 0.12,
      p_m6_L: 0.083,
      p_m6_H: 0.153,
      p_m12_L: 0.083,
      p_m12_H: 1.05,
    },
    8476: {
      H: 0.88,
      L: 0.86,
      O: 0.86,
      C: 0.88,
      V: 300000,
      p_w1_L: 0.86,
      p_w1_H: 0.88,
      p_m1_L: 0.85,
      p_m1_H: 0.92,
      p_m3_L: 0.75,
      p_m3_H: 0.93,
      p_m6_L: 0.75,
      p_m6_H: 0.93,
      p_m12_L: 0.71,
      p_m12_H: 0.94,
    },
    8479: {
      H: 0.64,
      L: 0.62,
      O: 0.64,
      C: 0.63,
      V: 1116000,
      p_w1_L: 0.62,
      p_w1_H: 0.73,
      p_m1_L: 0.6,
      p_m1_H: 0.73,
      p_m3_L: 0.55,
      p_m3_H: 0.73,
      p_m6_L: 0.55,
      p_m6_H: 0.75,
      p_m12_L: 0.5,
      p_m12_H: 0.75,
    },
    8480: {
      H: 0.27,
      L: 0.27,
      O: 0.27,
      C: 0.27,
      V: 40000,
      p_w1_L: 0.265,
      p_w1_H: 0.285,
      p_m1_L: 0.265,
      p_m1_H: 0.295,
      p_m3_L: 0.24,
      p_m3_H: 0.325,
      p_m6_L: 0.22,
      p_m6_H: 0.345,
      p_m12_L: 0.22,
      p_m12_H: 0.62,
    },
    8481: {
      H: 0.2,
      L: 0.2,
      O: 0.2,
      C: 0.2,
      V: 0,
      p_w1_L: 0.189,
      p_w1_H: 0.219,
      p_m1_L: 0.189,
      p_m1_H: 0.22,
      p_m3_L: 0.184,
      p_m3_H: 0.4,
      p_m6_L: 0.181,
      p_m6_H: 0.4,
      p_m12_L: 0.181,
      p_m12_H: 0.79,
    },
    8482: {
      H: 0.395,
      L: 0.375,
      O: 0.395,
      C: 0.39,
      V: 320000,
      p_w1_L: 0.335,
      p_w1_H: 0.395,
      p_m1_L: 0.335,
      p_m1_H: 0.445,
      p_m3_L: 0.285,
      p_m3_H: 0.445,
      p_m6_L: 0.165,
      p_m6_H: 0.65,
      p_m12_L: 0.146,
      p_m12_H: 0.65,
    },
    8483: {
      H: 0.13,
      L: 0.117,
      O: 0.125,
      C: 0.13,
      V: 120000,
      p_w1_L: 0.117,
      p_w1_H: 0.13,
      p_m1_L: 0.117,
      p_m1_H: 0.15,
      p_m3_L: 0.108,
      p_m3_H: 0.15,
      p_m6_L: 0.082,
      p_m6_H: 0.15,
      p_m12_L: 0.074,
      p_m12_H: 0.15,
    },
    8487: {
      H: 0.31,
      L: 0.28,
      O: 0.31,
      C: 0.285,
      V: 1446000,
      p_w1_L: 0.28,
      p_w1_H: 0.415,
      p_m1_L: 0.28,
      p_m1_H: 0.54,
      p_m3_L: 0.28,
      p_m3_H: 0.66,
      p_m6_L: 0.28,
      p_m6_H: 0.98,
      p_m12_L: 0.28,
      p_m12_H: 2,
    },
    8489: {
      H: 0.345,
      L: 0.34,
      O: 0.34,
      C: 0.345,
      V: 295000,
      p_w1_L: 0.325,
      p_w1_H: 0.345,
      p_m1_L: 0.325,
      p_m1_H: 0.375,
      p_m3_L: 0.315,
      p_m3_H: 0.4,
      p_m6_L: 0.315,
      p_m6_H: 0.67,
      p_m12_L: 0.315,
      p_m12_H: 1.43,
    },
    8490: {
      H: 0.335,
      L: 0.335,
      O: 0.335,
      C: 0.335,
      V: 0,
      p_w1_L: 0.325,
      p_w1_H: 0.335,
      p_m1_L: 0.305,
      p_m1_H: 0.38,
      p_m3_L: 0.305,
      p_m3_H: 0.4,
      p_m6_L: 0.25,
      p_m6_H: 0.475,
      p_m12_L: 0.25,
      p_m12_H: 0.475,
    },
    8491: {
      H: 0.92,
      L: 0.78,
      O: 0.78,
      C: 0.9,
      V: 106000,
      p_w1_L: 0.78,
      p_w1_H: 0.95,
      p_m1_L: 0.78,
      p_m1_H: 1.25,
      p_m3_L: 0.71,
      p_m3_H: 1.27,
      p_m6_L: 0.71,
      p_m6_H: 2.2,
      p_m12_L: 0.066,
      p_m12_H: 2.2,
    },
    8493: {
      H: 0.082,
      L: 0.082,
      O: 0.082,
      C: 0.082,
      V: 0,
      p_w1_L: 0.082,
      p_w1_H: 0.082,
      p_m1_L: 0.081,
      p_m1_H: 0.12,
      p_m3_L: 0.081,
      p_m3_H: 0.162,
      p_m6_L: 0.081,
      p_m6_H: 2.23,
      p_m12_L: 0.081,
      p_m12_H: 2.6,
    },
    8495: {
      H: 0.335,
      L: 0.335,
      O: 0.335,
      C: 0.335,
      V: 0,
      p_w1_L: 0.315,
      p_w1_H: 0.335,
      p_m1_L: 0.29,
      p_m1_H: 0.48,
      p_m3_L: 0.225,
      p_m3_H: 0.53,
      p_m6_L: 0.174,
      p_m6_H: 0.53,
      p_m12_L: 0.174,
      p_m12_H: 0.53,
    },
    8496: {
      H: 0.56,
      L: 0.53,
      O: 0.55,
      C: 0.55,
      V: 925000,
      p_w1_L: 0.435,
      p_w1_H: 0.56,
      p_m1_L: 0.435,
      p_m1_H: 0.57,
      p_m3_L: 0.4,
      p_m3_H: 0.57,
      p_m6_L: 0.4,
      p_m6_H: 0.64,
      p_m12_L: 0.4,
      p_m12_H: 0.9,
    },
    8500: {
      H: 0.63,
      L: 0.55,
      O: 0.55,
      C: 0.59,
      V: 110000,
      p_w1_L: 0.52,
      p_w1_H: 0.63,
      p_m1_L: 0.5,
      p_m1_H: 0.67,
      p_m3_L: 0.5,
      p_m3_H: 0.67,
      p_m6_L: 0.5,
      p_m6_H: 0.85,
      p_m12_L: 0.5,
      p_m12_H: 6.48,
    },
    8501: {
      H: 0.7,
      L: 0.7,
      O: 0.7,
      C: 0.7,
      V: 0,
      p_w1_L: 0.6,
      p_w1_H: 0.7,
      p_m1_L: 0.5,
      p_m1_H: 0.7,
      p_m3_L: 0.49,
      p_m3_H: 0.7,
      p_m6_L: 0.4,
      p_m6_H: 0.7,
      p_m12_L: 0.35,
      p_m12_H: 0.7,
    },
    8502: {
      H: 0.29,
      L: 0.29,
      O: 0.29,
      C: 0.29,
      V: 0,
      p_w1_L: 0.28,
      p_w1_H: 0.29,
      p_m1_L: 0.28,
      p_m1_H: 0.305,
      p_m3_L: 0.28,
      p_m3_H: 0.33,
      p_m6_L: 0.246,
      p_m6_H: 0.4,
      p_m12_L: 0.22,
      p_m12_H: 0.4,
    },
    8506: {
      H: 1.31,
      L: 1.31,
      O: 1.31,
      C: 1.31,
      V: 0,
      p_w1_L: 1.31,
      p_w1_H: 1.31,
      p_m1_L: 1.31,
      p_m1_H: 1.31,
      p_m3_L: 1.31,
      p_m3_H: 1.31,
      p_m6_L: 1.31,
      p_m6_H: 1.31,
      p_m12_L: 0.247,
      p_m12_H: 1.97,
    },
    8507: {
      H: 0.199,
      L: 0.199,
      O: 0.199,
      C: 0.199,
      V: 0,
      p_w1_L: 0.189,
      p_w1_H: 0.2,
      p_m1_L: 0.187,
      p_m1_H: 0.202,
      p_m3_L: 0.178,
      p_m3_H: 0.202,
      p_m6_L: 0.17,
      p_m6_H: 0.58,
      p_m12_L: 0.147,
      p_m12_H: 0.58,
    },
    8509: {
      H: 0.53,
      L: 0.52,
      O: 0.53,
      C: 0.51,
      V: 16000,
      p_w1_L: 0.36,
      p_w1_H: 0.53,
      p_m1_L: 0.36,
      p_m1_H: 0.61,
      p_m3_L: 0.36,
      p_m3_H: 0.73,
      p_m6_L: 0.36,
      p_m6_H: 0.83,
      p_m12_L: 0.3,
      p_m12_H: 1.03,
    },
    8510: {
      H: 0.063,
      L: 0.063,
      O: 0.063,
      C: 0.063,
      V: 80000,
      p_w1_L: 0.063,
      p_w1_H: 0.072,
      p_m1_L: 0.05,
      p_m1_H: 0.099,
      p_m3_L: 0.05,
      p_m3_H: 0.13,
      p_m6_L: 0.05,
      p_m6_H: 0.13,
      p_m12_L: 0.05,
      p_m12_H: 0.158,
    },
    8511: {
      H: 0.42,
      L: 0.385,
      O: 0.415,
      C: 0.41,
      V: 1560000,
      p_w1_L: 0.3,
      p_w1_H: 0.465,
      p_m1_L: 0.255,
      p_m1_H: 0.52,
      p_m3_L: 0.111,
      p_m3_H: 0.52,
      p_m6_L: 0.1,
      p_m6_H: 0.52,
      p_m12_L: 0.1,
      p_m12_H: 0.54,
    },
    8512: {
      H: 0.305,
      L: 0.295,
      O: 0.3,
      C: 0.295,
      V: 180000,
      p_w1_L: 0.275,
      p_w1_H: 0.305,
      p_m1_L: 0.255,
      p_m1_H: 0.37,
      p_m3_L: 0.255,
      p_m3_H: 0.385,
      p_m6_L: 0.226,
      p_m6_H: 0.475,
      p_m12_L: 0.102,
      p_m12_H: 0.475,
    },
    8513: {
      H: 0.33,
      L: 0.32,
      O: 0.325,
      C: 0.32,
      V: 896000,
      p_w1_L: 0.31,
      p_w1_H: 0.33,
      p_m1_L: 0.285,
      p_m1_H: 0.39,
      p_m3_L: 0.285,
      p_m3_H: 0.41,
      p_m6_L: 0.285,
      p_m6_H: 0.58,
      p_m12_L: 0.285,
      p_m12_H: 0.93,
    },
    8516: {
      H: 0.09,
      L: 0.084,
      O: 0.084,
      C: 0.089,
      V: 510000,
      p_w1_L: 0.074,
      p_w1_H: 0.09,
      p_m1_L: 0.068,
      p_m1_H: 0.092,
      p_m3_L: 0.068,
      p_m3_H: 0.114,
      p_m6_L: 0.068,
      p_m6_H: 0.154,
      p_m12_L: 0.068,
      p_m12_H: 4.76,
    },
    8519: {
      H: 0.112,
      L: 0.104,
      O: 0.112,
      C: 0.107,
      V: 4810000,
      p_w1_L: 0.104,
      p_w1_H: 0.114,
      p_m1_L: 0.101,
      p_m1_H: 0.12,
      p_m3_L: 0.095,
      p_m3_H: 0.129,
      p_m6_L: 0.083,
      p_m6_H: 0.14,
      p_m12_L: 0.083,
      p_m12_H: 0.16,
    },
    8521: {
      H: 0.13,
      L: 0.13,
      O: 0.13,
      C: 0.13,
      V: 0,
      p_w1_L: 0.13,
      p_w1_H: 0.13,
      p_m1_L: 0.13,
      p_m1_H: 0.136,
      p_m3_L: 0.13,
      p_m3_H: 0.181,
      p_m6_L: 0.13,
      p_m6_H: 0.186,
      p_m12_L: 0.13,
      p_m12_H: 0.186,
    },
    8523: {
      H: 0.3,
      L: 0.3,
      O: 0.3,
      C: 0.3,
      V: 0,
      p_w1_L: 0.3,
      p_w1_H: 0.3,
      p_m1_L: 0.3,
      p_m1_H: 0.385,
      p_m3_L: 0.3,
      p_m3_H: 0.4,
      p_m6_L: 0.3,
      p_m6_H: 0.48,
      p_m12_L: 0.3,
      p_m12_H: 0.74,
    },
    8525: {
      H: 0.5,
      L: 0.5,
      O: 0.5,
      C: 0.5,
      V: 0,
      p_w1_L: 0.5,
      p_w1_H: 0.5,
      p_m1_L: 0.42,
      p_m1_H: 0.5,
      p_m3_L: 0.4,
      p_m3_H: 0.51,
      p_m6_L: 0.34,
      p_m6_H: 0.64,
      p_m12_L: 0.32,
      p_m12_H: 0.64,
    },
    8526: {
      H: 0.213,
      L: 0.19,
      O: 0.19,
      C: 0.21,
      V: 1480000,
      p_w1_L: 0.159,
      p_w1_H: 0.22,
      p_m1_L: 0.15,
      p_m1_H: 0.22,
      p_m3_L: 0.141,
      p_m3_H: 0.22,
      p_m6_L: 0.141,
      p_m6_H: 0.31,
      p_m12_L: 0.141,
      p_m12_H: 0.44,
    },
    8527: {
      H: 2.68,
      L: 2.6,
      O: 2.65,
      C: 2.68,
      V: 305000,
      p_w1_L: 2.6,
      p_w1_H: 2.82,
      p_m1_L: 2.6,
      p_m1_H: 3.25,
      p_m3_L: 2.39,
      p_m3_H: 3.47,
      p_m6_L: 1.55,
      p_m6_H: 3.47,
      p_m12_L: 0.2,
      p_m12_H: 3.47,
    },
    8532: {
      H: 0.1,
      L: 0.1,
      O: 0.1,
      C: 0.1,
      V: 0,
      p_w1_L: 0.099,
      p_w1_H: 0.1,
      p_m1_L: 0.09,
      p_m1_H: 0.1,
      p_m3_L: 0.077,
      p_m3_H: 0.138,
      p_m6_L: 0.066,
      p_m6_H: 0.138,
      p_m12_L: 0.064,
      p_m12_H: 0.138,
    },
    8535: {
      H: 0.42,
      L: 0.42,
      O: 0.42,
      C: 0.42,
      V: 0,
      p_w1_L: 0.37,
      p_w1_H: 0.42,
      p_m1_L: 0.37,
      p_m1_H: 0.44,
      p_m3_L: 0.355,
      p_m3_H: 0.44,
      p_m6_L: 0.355,
      p_m6_H: 0.45,
      p_m12_L: 0.085,
      p_m12_H: 0.45,
    },
    8536: {
      H: 0.42,
      L: 0.39,
      O: 0.39,
      C: 0.395,
      V: 475000,
      p_w1_L: 0.37,
      p_w1_H: 0.435,
      p_m1_L: 0.37,
      p_m1_H: 0.63,
      p_m3_L: 0.37,
      p_m3_H: 11.68,
      p_m6_L: 0.107,
      p_m6_H: 11.68,
      p_m12_L: 0.093,
      p_m12_H: 11.68,
    },
    8537: {
      H: 0.07,
      L: 0.07,
      O: 0.07,
      C: 0.07,
      V: 200000,
      p_w1_L: 0.07,
      p_w1_H: 0.072,
      p_m1_L: 0.062,
      p_m1_H: 0.098,
      p_m3_L: 0.056,
      p_m3_H: 0.098,
      p_m6_L: 0.056,
      p_m6_H: 0.112,
      p_m12_L: 0.056,
      p_m12_H: 0.119,
    },
    8540: {
      H: 2.35,
      L: 2.35,
      O: 2.35,
      C: 2.35,
      V: 0,
      p_w1_L: 2.35,
      p_w1_H: 2.35,
      p_m1_L: 2.24,
      p_m1_H: 2.35,
      p_m3_L: 2,
      p_m3_H: 2.35,
      p_m6_L: 1.8,
      p_m6_H: 2.46,
      p_m12_L: 1.25,
      p_m12_H: 2.46,
    },
    8545: {
      H: 0.127,
      L: 0.109,
      O: 0.123,
      C: 0.12,
      V: 11488000,
      p_w1_L: 0.109,
      p_w1_H: 0.13,
      p_m1_L: 0.109,
      p_m1_H: 0.145,
      p_m3_L: 0.09,
      p_m3_H: 0.145,
      p_m6_L: 0.076,
      p_m6_H: 0.145,
      p_m12_L: 0.074,
      p_m12_H: 0.345,
    },
    8547: {
      H: 0.05,
      L: 0.041,
      O: 0.048,
      C: 0.044,
      V: 22150000,
      p_w1_L: 0.041,
      p_w1_H: 0.057,
      p_m1_L: 0.038,
      p_m1_H: 0.169,
      p_m3_L: 0.038,
      p_m3_H: 0.25,
      p_m6_L: 0.038,
      p_m6_H: 0.25,
      p_m12_L: 0.038,
      p_m12_H: 0.25,
    },
    8585: {
      H: 1.288,
      L: 1.288,
      O: 1.288,
      C: 1.288,
      V: 0,
      p_w1_L: 1.288,
      p_w1_H: 1.288,
      p_m1_L: 1.288,
      p_m1_H: 1.3,
      p_m3_L: 1.28,
      p_m3_H: 1.44,
      p_m6_L: 1.28,
      p_m6_H: 1.44,
      p_m12_L: 0.1,
      p_m12_H: 1.44,
    },
    8591: {
      H: 0.5,
      L: 0.5,
      O: 0.5,
      C: 0.5,
      V: 0,
      p_w1_L: 0.5,
      p_w1_H: 0.5,
      p_m1_L: 0.5,
      p_m1_H: 0.525,
      p_m3_L: 0.5,
      p_m3_H: 0.7,
      p_m6_L: 0.5,
      p_m6_H: 0.7,
      p_m12_L: 0.235,
      p_m12_H: 0.7,
    },
    8595: {
      H: 0.7,
      L: 0.7,
      O: 0.7,
      C: 0.7,
      V: 1600,
      p_w1_L: 0.425,
      p_w1_H: 0.7,
      p_m1_L: 0.255,
      p_m1_H: 0.7,
      p_m3_L: 0.255,
      p_m3_H: 0.7,
      p_m6_L: 0.255,
      p_m6_H: 0.7,
      p_m12_L: 0.244,
      p_m12_H: 0.7,
    },
    8596: {
      H: 0.212,
      L: 0.212,
      O: 0.212,
      C: 0.212,
      V: 1000,
      p_w1_L: 0.212,
      p_w1_H: 0.24,
      p_m1_L: 0.21,
      p_m1_H: 0.46,
      p_m3_L: 0.21,
      p_m3_H: 0.46,
      p_m6_L: 0.21,
      p_m6_H: 0.46,
      p_m12_L: 0.21,
      p_m12_H: 0.46,
    },
    8597: {
      H: 0.255,
      L: 0.255,
      O: 0.255,
      C: 0.255,
      V: 0,
      p_w1_L: 0.25,
      p_w1_H: 0.3,
      p_m1_L: 0.25,
      p_m1_H: 0.3,
      p_m3_L: 0.25,
      p_m3_H: 0.3,
      p_m6_L: 0.25,
      p_m6_H: 0.3,
      p_m12_L: 0.25,
      p_m12_H: 0.3,
    },
    8601: {
      H: 0.355,
      L: 0.355,
      O: 0.355,
      C: 0.355,
      V: 160000,
      p_w1_L: 0.35,
      p_w1_H: 0.4,
      p_m1_L: 0.35,
      p_m1_H: 0.46,
      p_m3_L: 0.3,
      p_m3_H: 0.5,
      p_m6_L: 0.228,
      p_m6_H: 0.5,
      p_m12_L: 0.19,
      p_m12_H: 0.5,
    },
    8603: {
      H: 0.4,
      L: 0.4,
      O: 0.4,
      C: 0.4,
      V: 0,
      p_w1_L: 0.4,
      p_w1_H: 0.45,
      p_m1_L: 0.39,
      p_m1_H: 0.48,
      p_m3_L: 0.31,
      p_m3_H: 0.48,
      p_m6_L: 0.178,
      p_m6_H: 0.48,
      p_m12_L: 0.095,
      p_m12_H: 0.48,
    },
    8606: {
      H: 0.228,
      L: 0.223,
      O: 0.224,
      C: 0.228,
      V: 180000,
      p_w1_L: 0.21,
      p_w1_H: 0.232,
      p_m1_L: 0.21,
      p_m1_H: 0.265,
      p_m3_L: 0.115,
      p_m3_H: 0.51,
      p_m6_L: 0.115,
      p_m6_H: 0.94,
      p_m12_L: 0.115,
      p_m12_H: 3.99,
    },
    8607: {
      H: 0.199,
      L: 0.199,
      O: 0.199,
      C: 0.198,
      V: 15000,
      p_w1_L: 0.174,
      p_w1_H: 0.2,
      p_m1_L: 0.15,
      p_m1_H: 0.2,
      p_m3_L: 0.15,
      p_m3_H: 0.238,
      p_m6_L: 0.15,
      p_m6_H: 0.28,
      p_m12_L: 0.15,
      p_m12_H: 0.3,
    },
    8609: {
      H: 0.27,
      L: 0.27,
      O: 0.27,
      C: 0.27,
      V: 0,
      p_w1_L: 0.243,
      p_w1_H: 0.275,
      p_m1_L: 0.232,
      p_m1_H: 0.275,
      p_m3_L: 0.221,
      p_m3_H: 0.34,
      p_m6_L: 0.221,
      p_m6_H: 0.37,
      p_m12_L: 0.209,
      p_m12_H: 0.41,
    },
    8611: {
      H: 0.105,
      L: 0.105,
      O: 0.105,
      C: 0.105,
      V: 0,
      p_w1_L: 0.1,
      p_w1_H: 0.105,
      p_m1_L: 0.095,
      p_m1_H: 0.14,
      p_m3_L: 0.074,
      p_m3_H: 0.184,
      p_m6_L: 0.07,
      p_m6_H: 0.184,
      p_m12_L: 0.07,
      p_m12_H: 0.184,
    },
    8612: {
      H: 0.375,
      L: 0.325,
      O: 0.375,
      C: 0.35,
      V: 13890000,
      p_w1_L: 0.184,
      p_w1_H: 0.38,
      p_m1_L: 0.169,
      p_m1_H: 0.38,
      p_m3_L: 0.167,
      p_m3_H: 0.38,
      p_m6_L: 0.167,
      p_m6_H: 0.4,
      p_m12_L: 0.051,
      p_m12_H: 0.4,
    },
    8613: {
      H: 0.116,
      L: 0.097,
      O: 0.114,
      C: 0.102,
      V: 2160000,
      p_w1_L: 0.094,
      p_w1_H: 0.116,
      p_m1_L: 0.085,
      p_m1_H: 0.116,
      p_m3_L: 0.075,
      p_m3_H: 0.116,
      p_m6_L: 0.075,
      p_m6_H: 0.116,
      p_m12_L: 0.075,
      p_m12_H: 0.12,
    },
    8616: {
      H: 0.128,
      L: 0.122,
      O: 0.123,
      C: 0.128,
      V: 440000,
      p_w1_L: 0.122,
      p_w1_H: 0.135,
      p_m1_L: 0.118,
      p_m1_H: 0.155,
      p_m3_L: 0.116,
      p_m3_H: 0.155,
      p_m6_L: 0.085,
      p_m6_H: 0.169,
      p_m12_L: 0.062,
      p_m12_H: 0.169,
    },
    8617: {
      H: 0.5,
      L: 0.5,
      O: 0.5,
      C: 0.5,
      V: 390000,
      p_w1_L: 0.5,
      p_w1_H: 0.52,
      p_m1_L: 0.5,
      p_m1_H: 0.58,
      p_m3_L: 0.5,
      p_m3_H: 0.63,
      p_m6_L: 0.5,
      p_m6_H: 0.72,
      p_m12_L: 0.44,
      p_m12_H: 0.72,
    },
    8619: {
      H: 0.244,
      L: 0.22,
      O: 0.235,
      C: 0.233,
      V: 1072000,
      p_w1_L: 0.198,
      p_w1_H: 0.28,
      p_m1_L: 0.19,
      p_m1_H: 0.32,
      p_m3_L: 0.08,
      p_m3_H: 0.32,
      p_m6_L: 0.079,
      p_m6_H: 0.32,
      p_m12_L: 0.079,
      p_m12_H: 0.6,
    },
    8620: {
      H: 0.24,
      L: 0.24,
      O: 0.24,
      C: 0.24,
      V: 10000,
      p_w1_L: 0.224,
      p_w1_H: 0.248,
      p_m1_L: 0.224,
      p_m1_H: 0.34,
      p_m3_L: 0.161,
      p_m3_H: 0.385,
      p_m6_L: 0.14,
      p_m6_H: 0.385,
      p_m12_L: 0.14,
      p_m12_H: 0.385,
    },
    8621: {
      H: 0.08,
      L: 0.077,
      O: 0.077,
      C: 0.08,
      V: 40000,
      p_w1_L: 0.077,
      p_w1_H: 0.089,
      p_m1_L: 0.07,
      p_m1_H: 0.095,
      p_m3_L: 0.062,
      p_m3_H: 0.095,
      p_m6_L: 0.062,
      p_m6_H: 0.095,
      p_m12_L: 0.062,
      p_m12_H: 0.148,
    },
    8622: {
      H: 0.26,
      L: 0.26,
      O: 0.26,
      C: 0.26,
      V: 32000,
      p_w1_L: 0.243,
      p_w1_H: 0.28,
      p_m1_L: 0.225,
      p_m1_H: 0.3,
      p_m3_L: 0.218,
      p_m3_H: 0.3,
      p_m6_L: 0.091,
      p_m6_H: 0.375,
      p_m12_L: 0.091,
      p_m12_H: 0.375,
    },
    8623: {
      H: 0.08,
      L: 0.08,
      O: 0.08,
      C: 0.08,
      V: 190000,
      p_w1_L: 0.075,
      p_w1_H: 0.085,
      p_m1_L: 0.075,
      p_m1_H: 0.09,
      p_m3_L: 0.075,
      p_m3_H: 0.105,
      p_m6_L: 0.065,
      p_m6_H: 0.108,
      p_m12_L: 0.062,
      p_m12_H: 0.12,
    },
    8627: {
      H: 0.197,
      L: 0.188,
      O: 0.191,
      C: 0.191,
      V: 2168000,
      p_w1_L: 0.184,
      p_w1_H: 0.209,
      p_m1_L: 0.181,
      p_m1_H: 0.246,
      p_m3_L: 0.181,
      p_m3_H: 0.28,
      p_m6_L: 0.181,
      p_m6_H: 0.28,
      p_m12_L: 0.181,
      p_m12_H: 0.345,
    },
    8631: {
      H: 0.113,
      L: 0.109,
      O: 0.109,
      C: 0.11,
      V: 270000,
      p_w1_L: 0.105,
      p_w1_H: 0.12,
      p_m1_L: 0.099,
      p_m1_H: 0.127,
      p_m3_L: 0.096,
      p_m3_H: 0.129,
      p_m6_L: 0.075,
      p_m6_H: 0.22,
      p_m12_L: 0.07,
      p_m12_H: 0.22,
    },
    8635: {
      H: 0.122,
      L: 0.122,
      O: 0.122,
      C: 0.126,
      V: 20000,
      p_w1_L: 0.121,
      p_w1_H: 0.13,
      p_m1_L: 0.112,
      p_m1_H: 0.157,
      p_m3_L: 0.112,
      p_m3_H: 0.275,
      p_m6_L: 0.1,
      p_m6_H: 0.275,
      p_m12_L: 0.085,
      p_m12_H: 0.275,
    },
    8645: {
      H: 0.45,
      L: 0.44,
      O: 0.445,
      C: 0.445,
      V: 1692000,
      p_w1_L: 0.41,
      p_w1_H: 0.45,
      p_m1_L: 0.4,
      p_m1_H: 0.465,
      p_m3_L: 0.33,
      p_m3_H: 0.465,
      p_m6_L: 0.145,
      p_m6_H: 0.495,
      p_m12_L: 0.089,
      p_m12_H: 0.495,
    },
    8646: {
      H: 0.365,
      L: 0.365,
      O: 0.365,
      C: 0.365,
      V: 3000,
      p_w1_L: 0.34,
      p_w1_H: 0.375,
      p_m1_L: 0.325,
      p_m1_H: 0.38,
      p_m3_L: 0.25,
      p_m3_H: 0.38,
      p_m6_L: 0.25,
      p_m6_H: 0.395,
      p_m12_L: 0.25,
      p_m12_H: 0.47,
    },
    8657: {
      H: 0.98,
      L: 0.98,
      O: 0.98,
      C: 0.98,
      V: 0,
      p_w1_L: 0.9,
      p_w1_H: 1,
      p_m1_L: 0.85,
      p_m1_H: 1,
      p_m3_L: 0.77,
      p_m3_H: 1.05,
      p_m6_L: 0.77,
      p_m6_H: 1.14,
      p_m12_L: 0.7,
      p_m12_H: 1.41,
    },
    8659: {
      H: 0.205,
      L: 0.193,
      O: 0.202,
      C: 0.204,
      V: 485000,
      p_w1_L: 0.193,
      p_w1_H: 0.212,
      p_m1_L: 0.178,
      p_m1_H: 0.228,
      p_m3_L: 0.162,
      p_m3_H: 0.228,
      p_m6_L: 0.156,
      p_m6_H: 0.228,
      p_m12_L: 0.148,
      p_m12_H: 0.228,
    },
    8668: {
      H: 0.182,
      L: 0.153,
      O: 0.153,
      C: 0.153,
      V: 110000,
      p_w1_L: 0.153,
      p_w1_H: 0.182,
      p_m1_L: 0.15,
      p_m1_H: 0.186,
      p_m3_L: 0.135,
      p_m3_H: 0.186,
      p_m6_L: 0.133,
      p_m6_H: 0.248,
      p_m12_L: 0.105,
      p_m12_H: 0.248,
    },
    9001: {
      H: 35.6,
      L: 35.6,
      O: 35.6,
      C: 35.6,
      V: 0,
      p_w1_L: 34.35,
      p_w1_H: 35.6,
      p_m1_L: 31.55,
      p_m1_H: 38.45,
      p_m3_L: 31.55,
      p_m3_H: 45.45,
      p_m6_L: 31.55,
      p_m6_H: 50.05,
      p_m12_L: 31.55,
      p_m12_H: 50.5,
    },
    9010: {
      H: 7.975,
      L: 7.975,
      O: 7.975,
      C: 7.975,
      V: 0,
      p_w1_L: 7.85,
      p_w1_H: 7.975,
      p_m1_L: 7.85,
      p_m1_H: 8.35,
      p_m3_L: 7.85,
      p_m3_H: 8.645,
      p_m6_L: 7.85,
      p_m6_H: 70.16,
      p_m12_L: 7.85,
      p_m12_H: 70.16,
    },
    9011: {
      H: 1029.55,
      L: 1029.55,
      O: 1029.55,
      C: 1029.55,
      V: 0,
      p_w1_L: 1029.5,
      p_w1_H: 1029.55,
      p_m1_L: 1029.35,
      p_m1_H: 1029.55,
      p_m3_L: 1028.85,
      p_m3_H: 1029.55,
      p_m6_L: 1000,
      p_m6_H: 1029.55,
      p_m12_L: 1000,
      p_m12_H: 1029.55,
    },
    9022: {
      H: 71.2,
      L: 71.2,
      O: 71.2,
      C: 71.2,
      V: 0,
      p_w1_L: 70.38,
      p_w1_H: 71.2,
      p_m1_L: 69.98,
      p_m1_H: 74.62,
      p_m3_L: 69.98,
      p_m3_H: 77.18,
      p_m6_L: 69.98,
      p_m6_H: 80.1,
      p_m12_L: 69.98,
      p_m12_H: 80.1,
    },
    9031: {
      H: 1.722,
      L: 1.722,
      O: 1.722,
      C: 1.722,
      V: 0,
      p_w1_L: 1.674,
      p_w1_H: 1.722,
      p_m1_L: 1.668,
      p_m1_H: 1.722,
      p_m3_L: 1.598,
      p_m3_H: 1.722,
      p_m6_L: 1.378,
      p_m6_H: 1.75,
      p_m12_L: 1.378,
      p_m12_H: 1.768,
    },
    9047: {
      H: 1.548,
      L: 1.548,
      O: 1.548,
      C: 1.548,
      V: 25000,
      p_w1_L: 1.502,
      p_w1_H: 1.548,
      p_m1_L: 1.282,
      p_m1_H: 1.548,
      p_m3_L: 1.282,
      p_m3_H: 1.874,
      p_m6_L: 1.282,
      p_m6_H: 2.674,
      p_m12_L: 1.282,
      p_m12_H: 2.884,
    },
    9067: {
      H: 1.608,
      L: 1.6,
      O: 1.608,
      C: 1.6,
      V: 30300,
      p_w1_L: 1.53,
      p_w1_H: 1.716,
      p_m1_L: 1.53,
      p_m1_H: 1.8,
      p_m3_L: 1.53,
      p_m3_H: 1.868,
      p_m6_L: 1.53,
      p_m6_H: 16.77,
      p_m12_L: 1.53,
      p_m12_H: 16.77,
    },
    9072: {
      H: 19.55,
      L: 19.55,
      O: 19.55,
      C: 19.55,
      V: 0,
      p_w1_L: 18.74,
      p_w1_H: 20.08,
      p_m1_L: 18.74,
      p_m1_H: 21.98,
      p_m3_L: 18.74,
      p_m3_H: 22.14,
      p_m6_L: 18.74,
      p_m6_H: 22.14,
      p_m12_L: 18.74,
      p_m12_H: 23.12,
    },
    9074: {
      H: 26,
      L: 26,
      O: 26,
      C: 26,
      V: 110,
      p_w1_L: 25.38,
      p_w1_H: 26.02,
      p_m1_L: 24.92,
      p_m1_H: 26.16,
      p_m3_L: 24.04,
      p_m3_H: 26.52,
      p_m6_L: 24.04,
      p_m6_H: 199.4,
      p_m12_L: 22.68,
      p_m12_H: 202,
    },
    9077: {
      H: 499.96,
      L: 499.96,
      O: 499.96,
      C: 499.96,
      V: 0,
      p_w1_L: 499.96,
      p_w1_H: 499.96,
      p_m1_L: 499.96,
      p_m1_H: 499.96,
      p_m3_L: 499.96,
      p_m3_H: 500.05,
      p_m6_L: 499.96,
      p_m6_H: 500.26,
      p_m12_L: 499.96,
      p_m12_H: 500.5,
    },
    9078: {
      H: 506.7,
      L: 506.7,
      O: 506.7,
      C: 506.7,
      V: 0,
      p_w1_L: 506.7,
      p_w1_H: 506.7,
      p_m1_L: 506.7,
      p_m1_H: 506.7,
      p_m3_L: 506.65,
      p_m3_H: 506.7,
      p_m6_L: 506.6,
      p_m6_H: 506.7,
      p_m12_L: 506.6,
      p_m12_H: 506.75,
    },
    9081: {
      H: 5.485,
      L: 5.485,
      O: 5.485,
      C: 5.485,
      V: 0,
      p_w1_L: 5.445,
      p_w1_H: 5.485,
      p_m1_L: 5.445,
      p_m1_H: 5.725,
      p_m3_L: 5.32,
      p_m3_H: 5.725,
      p_m6_L: 5.32,
      p_m6_H: 5.825,
      p_m12_L: 5.175,
      p_m12_H: 5.99,
    },
    9086: {
      H: 4.244,
      L: 4.244,
      O: 4.244,
      C: 4.244,
      V: 0,
      p_w1_L: 4.08,
      p_w1_H: 4.244,
      p_m1_L: 4.08,
      p_m1_H: 4.308,
      p_m3_L: 3.758,
      p_m3_H: 4.308,
      p_m6_L: 3.578,
      p_m6_H: 4.308,
      p_m12_L: 3.204,
      p_m12_H: 4.308,
    },
    9088: {
      H: 0.964,
      L: 0.964,
      O: 0.964,
      C: 0.964,
      V: 0,
      p_w1_L: 0.925,
      p_w1_H: 0.984,
      p_m1_L: 0.925,
      p_m1_H: 1.082,
      p_m3_L: 0.925,
      p_m3_H: 1.114,
      p_m6_L: 0.925,
      p_m6_H: 1.338,
      p_m12_L: 0.925,
      p_m12_H: 1.774,
    },
    9091: {
      H: 13.64,
      L: 13.64,
      O: 13.64,
      C: 13.64,
      V: 0,
      p_w1_L: 13.22,
      p_w1_H: 13.64,
      p_m1_L: 13.22,
      p_m1_H: 14.31,
      p_m3_L: 12.41,
      p_m3_H: 14.31,
      p_m6_L: 12.41,
      p_m6_H: 109,
      p_m12_L: 12.41,
      p_m12_H: 117.55,
    },
    9096: {
      H: 102.55,
      L: 102.55,
      O: 102.55,
      C: 102.55,
      V: 0,
      p_w1_L: 102.55,
      p_w1_H: 102.55,
      p_m1_L: 102.55,
      p_m1_H: 102.55,
      p_m3_L: 102.55,
      p_m3_H: 102.55,
      p_m6_L: 102.45,
      p_m6_H: 797.3,
      p_m12_L: 102.45,
      p_m12_H: 797.75,
    },
    9115: {
      H: 11.02,
      L: 11.02,
      O: 11.02,
      C: 11.02,
      V: 0,
      p_w1_L: 10.76,
      p_w1_H: 11.02,
      p_m1_L: 10.64,
      p_m1_H: 11.77,
      p_m3_L: 10.64,
      p_m3_H: 12.34,
      p_m6_L: 10.64,
      p_m6_H: 13.64,
      p_m12_L: 10.64,
      p_m12_H: 14.92,
    },
    9125: {
      H: 7.905,
      L: 7.905,
      O: 7.905,
      C: 7.905,
      V: 0,
      p_w1_L: 7.88,
      p_w1_H: 7.905,
      p_m1_L: 7.83,
      p_m1_H: 7.905,
      p_m3_L: 7.82,
      p_m3_H: 7.905,
      p_m6_L: 7.82,
      p_m6_H: 7.905,
      p_m12_L: 7.82,
      p_m12_H: 7.905,
    },
    9141: {
      H: 2.068,
      L: 2.068,
      O: 2.068,
      C: 2.068,
      V: 0,
      p_w1_L: 2.06,
      p_w1_H: 2.068,
      p_m1_L: 2.056,
      p_m1_H: 2.068,
      p_m3_L: 2.04,
      p_m3_H: 2.104,
      p_m6_L: 2.04,
      p_m6_H: 2.104,
      p_m12_L: 2.04,
      p_m12_H: 2.146,
    },
    9151: {
      H: 1.428,
      L: 1.428,
      O: 1.428,
      C: 1.428,
      V: 1000,
      p_w1_L: 1.398,
      p_w1_H: 1.478,
      p_m1_L: 1.398,
      p_m1_H: 1.498,
      p_m3_L: 1.306,
      p_m3_H: 1.498,
      p_m6_L: 1.306,
      p_m6_H: 1.612,
      p_m12_L: 1.306,
      p_m12_H: 1.612,
    },
    9167: {
      H: 10.38,
      L: 10.37,
      O: 10.38,
      C: 10.37,
      V: 1400,
      p_w1_L: 10.01,
      p_w1_H: 10.49,
      p_m1_L: 10.01,
      p_m1_H: 11.47,
      p_m3_L: 10.01,
      p_m3_H: 11.66,
      p_m6_L: 10.01,
      p_m6_H: 13.68,
      p_m12_L: 10.01,
      p_m12_H: 17.01,
    },
    9173: {
      H: 1.606,
      L: 1.606,
      O: 1.606,
      C: 1.606,
      V: 0,
      p_w1_L: 1.572,
      p_w1_H: 1.632,
      p_m1_L: 1.566,
      p_m1_H: 1.632,
      p_m3_L: 1.45,
      p_m3_H: 1.632,
      p_m6_L: 1.45,
      p_m6_H: 1.67,
      p_m12_L: 1.426,
      p_m12_H: 1.744,
    },
    9181: {
      H: 16.6,
      L: 16.6,
      O: 16.6,
      C: 16.6,
      V: 0,
      p_w1_L: 16.22,
      p_w1_H: 16.82,
      p_m1_L: 16.22,
      p_m1_H: 17.48,
      p_m3_L: 15.46,
      p_m3_H: 17.56,
      p_m6_L: 15.46,
      p_m6_H: 142,
      p_m12_L: 15.46,
      p_m12_H: 158.1,
    },
    9186: {
      H: 7.495,
      L: 7.495,
      O: 7.495,
      C: 7.495,
      V: 0,
      p_w1_L: 7.435,
      p_w1_H: 7.815,
      p_m1_L: 7.435,
      p_m1_H: 9.065,
      p_m3_L: 7.435,
      p_m3_H: 9.52,
      p_m6_L: 7.435,
      p_m6_H: 78.6,
      p_m12_L: 7.435,
      p_m12_H: 116.65,
    },
    9187: {
      H: 2.738,
      L: 2.738,
      O: 2.738,
      C: 2.738,
      V: 0,
      p_w1_L: 2.694,
      p_w1_H: 2.738,
      p_m1_L: 2.694,
      p_m1_H: 2.85,
      p_m3_L: 2.694,
      p_m3_H: 2.878,
      p_m6_L: 2.694,
      p_m6_H: 2.972,
      p_m12_L: 2.694,
      p_m12_H: 2.972,
    },
    9191: {
      H: 8.83,
      L: 8.445,
      O: 8.445,
      C: 8.83,
      V: 26200,
      p_w1_L: 8.445,
      p_w1_H: 9.05,
      p_m1_L: 8.445,
      p_m1_H: 9.06,
      p_m3_L: 7.715,
      p_m3_H: 9.06,
      p_m6_L: 7.42,
      p_m6_H: 9.815,
      p_m12_L: 6.37,
      p_m12_H: 9.815,
    },
    9600: {
      H: 2.2,
      L: 2.1,
      O: 2.15,
      C: 2.12,
      V: 666400,
      p_w1_L: 2,
      p_w1_H: 2.22,
      p_m1_L: 2,
      p_m1_H: 2.35,
      p_m3_L: 1.65,
      p_m3_H: 2.35,
      p_m6_L: 1.65,
      p_m6_H: 2.85,
      p_m12_L: 1.65,
      p_m12_H: 4.76,
    },
    9608: {
      H: 0.15,
      L: 0.137,
      O: 0.137,
      C: 0.147,
      V: 580000,
      p_w1_L: 0.136,
      p_w1_H: 0.159,
      p_m1_L: 0.136,
      p_m1_H: 0.167,
      p_m3_L: 0.099,
      p_m3_H: 0.167,
      p_m6_L: 0.072,
      p_m6_H: 0.167,
      p_m12_L: 0.072,
      p_m12_H: 0.227,
    },
    9616: {
      H: 5.29,
      L: 5.24,
      O: 5.29,
      C: 5.24,
      V: 2400,
      p_w1_L: 5.01,
      p_w1_H: 5.43,
      p_m1_L: 5.01,
      p_m1_H: 5.57,
      p_m3_L: 4.92,
      p_m3_H: 6.15,
      p_m6_L: 4.92,
      p_m6_H: 7.06,
      p_m12_L: 4.52,
      p_m12_H: 7.06,
    },
    9618: {
      H: 312.4,
      L: 304.2,
      O: 307,
      C: 307,
      V: 5085050,
      p_w1_L: 295.8,
      p_w1_H: 345,
      p_m1_L: 295.8,
      p_m1_H: 365,
      p_m3_L: 268,
      p_m3_H: 365,
      p_m6_L: 236.4,
      p_m6_H: 365,
      p_m12_L: 236.4,
      p_m12_H: 422.8,
    },
    9626: {
      H: 482.4,
      L: 462.2,
      O: 480.6,
      C: 467.6,
      V: 1471240,
      p_w1_L: 422.4,
      p_w1_H: 526.5,
      p_m1_L: 422.4,
      p_m1_H: 710,
      p_m3_L: 422.4,
      p_m3_H: 720,
      p_m6_L: 422.4,
      p_m6_H: 1052,
      p_m12_L: 422.4,
      p_m12_H: 1052,
    },
    9633: {
      H: 47.1,
      L: 45.5,
      O: 46,
      C: 46.3,
      V: 5879830,
      p_w1_L: 44.15,
      p_w1_H: 47.1,
      p_m1_L: 39.5,
      p_m1_H: 49.15,
      p_m3_L: 36.5,
      p_m3_H: 49.15,
      p_m6_L: 36.5,
      p_m6_H: 49.15,
      p_m12_L: 36.5,
      p_m12_H: 68.75,
    },
    9666: {
      H: 35.3,
      L: 33.4,
      O: 35,
      C: 33.5,
      V: 2159040,
      p_w1_L: 30.4,
      p_w1_H: 35.6,
      p_m1_L: 30.4,
      p_m1_H: 41.6,
      p_m3_L: 30.4,
      p_m3_H: 49.75,
      p_m6_L: 30.4,
      p_m6_H: 75.7,
      p_m12_L: 30.4,
      p_m12_H: 90,
    },
    9668: {
      H: 2.07,
      L: 1.98,
      O: 2.04,
      C: 2.07,
      V: 4668000,
      p_w1_L: 1.98,
      p_w1_H: 2.37,
      p_m1_L: 1.98,
      p_m1_H: 2.9,
      p_m3_L: 1.98,
      p_m3_H: 3.4,
      p_m6_L: 1.98,
      p_m6_H: 3.6,
      p_m12_L: 1.98,
      p_m12_H: 5.49,
    },
    9677: {
      H: 3.23,
      L: 3.23,
      O: 3.23,
      C: 3.23,
      V: 0,
      p_w1_L: 3.22,
      p_w1_H: 3.61,
      p_m1_L: 3.22,
      p_m1_H: 3.61,
      p_m3_L: 3.21,
      p_m3_H: 3.61,
      p_m6_L: 3.21,
      p_m6_H: 3.61,
      p_m12_L: 3.21,
      p_m12_H: 3.61,
    },
    9688: {
      H: 563.5,
      L: 531.5,
      O: 531.5,
      C: 551,
      V: 49600,
      p_w1_L: 487,
      p_w1_H: 589.5,
      p_m1_L: 487,
      p_m1_H: 720,
      p_m3_L: 487,
      p_m3_H: 1180,
      p_m6_L: 487,
      p_m6_H: 1420,
      p_m12_L: 487,
      p_m12_H: 1512,
    },
    9698: {
      H: 51.15,
      L: 49.9,
      O: 51.15,
      C: 50.05,
      V: 2009800,
      p_w1_L: 47.65,
      p_w1_H: 55.8,
      p_m1_L: 47.65,
      p_m1_H: 61.2,
      p_m3_L: 47.65,
      p_m3_H: 64.05,
      p_m6_L: 46.55,
      p_m6_H: 78.6,
      p_m12_L: 46.55,
      p_m12_H: 118.7,
    },
    9801: {
      H: 3.532,
      L: 3.532,
      O: 3.532,
      C: 3.532,
      V: 0,
      p_w1_L: 3.432,
      p_w1_H: 3.56,
      p_m1_L: 3.432,
      p_m1_H: 3.85,
      p_m3_L: 3.432,
      p_m3_H: 4.014,
      p_m6_L: 3.432,
      p_m6_H: 4.014,
      p_m12_L: 3.432,
      p_m12_H: 4.014,
    },
    9804: {
      H: 13.17,
      L: 13.17,
      O: 13.17,
      C: 13.17,
      V: 0,
      p_w1_L: 13.06,
      p_w1_H: 13.42,
      p_m1_L: 13.06,
      p_m1_H: 13.66,
      p_m3_L: 12.67,
      p_m3_H: 13.68,
      p_m6_L: 12.09,
      p_m6_H: 13.68,
      p_m12_L: 10.43,
      p_m12_H: 13.68,
    },
    9806: {
      H: 9.265,
      L: 9.09,
      O: 9.105,
      C: 9.255,
      V: 330500,
      p_w1_L: 8.94,
      p_w1_H: 9.265,
      p_m1_L: 8.94,
      p_m1_H: 9.375,
      p_m3_L: 8.695,
      p_m3_H: 9.64,
      p_m6_L: 8.695,
      p_m6_H: 10.93,
      p_m12_L: 8.695,
      p_m12_H: 13.1,
    },
    9807: {
      H: 8.505,
      L: 8.505,
      O: 8.505,
      C: 8.505,
      V: 0,
      p_w1_L: 8.215,
      p_w1_H: 8.515,
      p_m1_L: 8.215,
      p_m1_H: 8.66,
      p_m3_L: 7.73,
      p_m3_H: 8.66,
      p_m6_L: 7.73,
      p_m6_H: 65,
      p_m12_L: 7.245,
      p_m12_H: 76.6,
    },
    9809: {
      H: 19.88,
      L: 19.6,
      O: 19.61,
      C: 19.88,
      V: 27196,
      p_w1_L: 19.2,
      p_w1_H: 20.1,
      p_m1_L: 19.2,
      p_m1_H: 21.54,
      p_m3_L: 18.5,
      p_m3_H: 21.7,
      p_m6_L: 14.31,
      p_m6_H: 21.7,
      p_m12_L: 11.63,
      p_m12_H: 21.7,
    },
    9810: {
      H: 9.16,
      L: 9.16,
      O: 9.16,
      C: 9.16,
      V: 0,
      p_w1_L: 9.09,
      p_w1_H: 9.16,
      p_m1_L: 9.09,
      p_m1_H: 9.48,
      p_m3_L: 8.85,
      p_m3_H: 9.52,
      p_m6_L: 8.41,
      p_m6_H: 9.52,
      p_m12_L: 8.41,
      p_m12_H: 9.76,
    },
    9812: {
      H: 1.85,
      L: 1.85,
      O: 1.85,
      C: 1.842,
      V: 7800,
      p_w1_L: 1.752,
      p_w1_H: 1.868,
      p_m1_L: 1.752,
      p_m1_H: 2.166,
      p_m3_L: 1.752,
      p_m3_H: 2.2,
      p_m6_L: 1.752,
      p_m6_H: 2.688,
      p_m12_L: 1.752,
      p_m12_H: 4,
    },
    9814: {
      H: 2.936,
      L: 2.936,
      O: 2.936,
      C: 2.936,
      V: 0,
      p_w1_L: 2.8,
      p_w1_H: 3.014,
      p_m1_L: 2.8,
      p_m1_H: 3.08,
      p_m3_L: 2.658,
      p_m3_H: 3.084,
      p_m6_L: 2.598,
      p_m6_H: 3.084,
      p_m12_L: 2.555,
      p_m12_H: 3.084,
    },
    9820: {
      H: 13.57,
      L: 13.43,
      O: 13.43,
      C: 13.48,
      V: 67032,
      p_w1_L: 13.16,
      p_w1_H: 14.15,
      p_m1_L: 12.96,
      p_m1_H: 14.57,
      p_m3_L: 12.96,
      p_m3_H: 15.32,
      p_m6_L: 12.96,
      p_m6_H: 18.19,
      p_m12_L: 12.95,
      p_m12_H: 20.3,
    },
    9826: {
      H: 10.29,
      L: 10.16,
      O: 10.17,
      C: 10.25,
      V: 100750,
      p_w1_L: 9.67,
      p_w1_H: 10.42,
      p_m1_L: 9.67,
      p_m1_H: 11.6,
      p_m3_L: 9.67,
      p_m3_H: 12.25,
      p_m6_L: 9.67,
      p_m6_H: 14.84,
      p_m12_L: 9.67,
      p_m12_H: 22.38,
    },
    9829: {
      H: 7.955,
      L: 7.955,
      O: 7.955,
      C: 7.955,
      V: 0,
      p_w1_L: 7.94,
      p_w1_H: 7.955,
      p_m1_L: 7.885,
      p_m1_H: 7.955,
      p_m3_L: 7.86,
      p_m3_H: 7.955,
      p_m6_L: 7.86,
      p_m6_H: 7.955,
      p_m12_L: 7.86,
      p_m12_H: 7.955,
    },
    9834: {
      H: 39.54,
      L: 39.54,
      O: 39.54,
      C: 39.54,
      V: 0,
      p_w1_L: 38.04,
      p_w1_H: 39.54,
      p_m1_L: 38.04,
      p_m1_H: 40.14,
      p_m3_L: 35,
      p_m3_H: 40.14,
      p_m6_L: 33.5,
      p_m6_H: 265.3,
      p_m12_L: 32.12,
      p_m12_H: 265.3,
    },
    9836: {
      H: 4.424,
      L: 4.424,
      O: 4.424,
      C: 4.424,
      V: 0,
      p_w1_L: 4.334,
      p_w1_H: 4.424,
      p_m1_L: 4.334,
      p_m1_H: 4.744,
      p_m3_L: 4.334,
      p_m3_H: 4.744,
      p_m6_L: 3.99,
      p_m6_H: 4.744,
      p_m12_L: 3.602,
      p_m12_H: 4.744,
    },
    9845: {
      H: 25.92,
      L: 25.56,
      O: 25.68,
      C: 25.86,
      V: 151180,
      p_w1_L: 25.06,
      p_w1_H: 27,
      p_m1_L: 25.06,
      p_m1_H: 27.46,
      p_m3_L: 21.76,
      p_m3_H: 27.46,
      p_m6_L: 17.91,
      p_m6_H: 27.46,
      p_m12_L: 13.47,
      p_m12_H: 27.46,
    },
    9846: {
      H: 5.11,
      L: 5.1,
      O: 5.1,
      C: 5.11,
      V: 8300,
      p_w1_L: 4.948,
      p_w1_H: 5.11,
      p_m1_L: 4.846,
      p_m1_H: 5.11,
      p_m3_L: 4.784,
      p_m3_H: 5.135,
      p_m6_L: 4.682,
      p_m6_H: 5.345,
      p_m12_L: 4.682,
      p_m12_H: 5.985,
    },
    9858: {
      H: 4.05,
      L: 4.01,
      O: 4.04,
      C: 4.04,
      V: 1780000,
      p_w1_L: 3.97,
      p_w1_H: 4.21,
      p_m1_L: 3.97,
      p_m1_H: 4.86,
      p_m3_L: 3.97,
      p_m3_H: 5.4,
      p_m6_L: 3.93,
      p_m6_H: 6.49,
      p_m12_L: 3.93,
      p_m12_H: 6.49,
    },
    9868: {
      H: 192,
      L: 183.6,
      O: 186.7,
      C: 188.8,
      V: 2495540,
      p_w1_L: 175.8,
      p_w1_H: 220,
      p_m1_L: 168.4,
      p_m1_H: 220,
      p_m3_L: 131,
      p_m3_H: 220,
      p_m6_L: 131,
      p_m6_H: 220,
      p_m12_L: 88.262,
      p_m12_H: 220,
    },
    9869: {
      H: 18.24,
      L: 16.36,
      O: 16.36,
      C: 18.14,
      V: 5676100,
      p_w1_L: 15.32,
      p_w1_H: 18.24,
      p_m1_L: 15,
      p_m1_H: 18.9,
      p_m3_L: 13.88,
      p_m3_H: 25.75,
      p_m6_L: 13.88,
      p_m6_H: 25.75,
      p_m12_L: 13.88,
      p_m12_H: 25.75,
    },
    9888: {
      H: 148.5,
      L: 144.3,
      O: 148.5,
      C: 145,
      V: 3024540,
      p_w1_L: 133.6,
      p_w1_H: 149,
      p_m1_L: 133.6,
      p_m1_H: 170,
      p_m3_L: 133.6,
      p_m3_H: 178.9,
      p_m6_L: 133,
      p_m6_H: 200,
      p_m12_L: 133,
      p_m12_H: 256.6,
    },
    9889: {
      H: 7.31,
      L: 7.3,
      O: 7.3,
      C: 7.31,
      V: 23000,
      p_w1_L: 7.19,
      p_w1_H: 7.4,
      p_m1_L: 7.12,
      p_m1_H: 7.55,
      p_m3_L: 6.51,
      p_m3_H: 8.4,
      p_m6_L: 6.51,
      p_m6_H: 8.4,
      p_m12_L: 6.51,
      p_m12_H: 8.4,
    },
    9899: {
      H: 205.8,
      L: 201.8,
      O: 205.6,
      C: 203.2,
      V: 127750,
      p_w1_L: 187.9,
      p_w1_H: 205.8,
      p_m1_L: 187.9,
      p_m1_H: 205.8,
      p_m3_L: 187.9,
      p_m3_H: 205.8,
      p_m6_L: 187.9,
      p_m6_H: 205.8,
      p_m12_L: 187.9,
      p_m12_H: 205.8,
    },
    9900: {
      H: 2,
      L: 2,
      O: 2,
      C: 2,
      V: 0,
      p_w1_L: 2,
      p_w1_H: 2,
      p_m1_L: 2,
      p_m1_H: 2,
      p_m3_L: 2,
      p_m3_H: 2.58,
      p_m6_L: 1.51,
      p_m6_H: 2.85,
      p_m12_L: 1.12,
      p_m12_H: 2.85,
    },
    9901: {
      H: 15.58,
      L: 15.1,
      O: 15.3,
      C: 15.5,
      V: 2630950,
      p_w1_L: 14.04,
      p_w1_H: 17.06,
      p_m1_L: 14.04,
      p_m1_H: 19.7,
      p_m3_L: 13.64,
      p_m3_H: 19.7,
      p_m6_L: 13,
      p_m6_H: 79.8,
      p_m12_L: 13,
      p_m12_H: 1397,
    },
    9906: {
      H: 2.45,
      L: 2.34,
      O: 2.34,
      C: 2.4,
      V: 544000,
      p_w1_L: 2.14,
      p_w1_H: 2.45,
      p_m1_L: 2.07,
      p_m1_H: 2.45,
      p_m3_L: 1.98,
      p_m3_H: 2.45,
      p_m6_L: 1.85,
      p_m6_H: 2.45,
      p_m12_L: 1.74,
      p_m12_H: 2.45,
    },
    9908: {
      H: 11.9,
      L: 11.68,
      O: 11.7,
      C: 11.72,
      V: 21000,
      p_w1_L: 11.5,
      p_w1_H: 11.96,
      p_m1_L: 10.18,
      p_m1_H: 12.1,
      p_m3_L: 9.99,
      p_m3_H: 12.1,
      p_m6_L: 9.97,
      p_m6_H: 12.1,
      p_m12_L: 9.8,
      p_m12_H: 12.1,
    },
    9909: {
      H: 16,
      L: 15.36,
      O: 16,
      C: 15.66,
      V: 553500,
      p_w1_L: 15,
      p_w1_H: 16.98,
      p_m1_L: 15,
      p_m1_H: 18.34,
      p_m3_L: 14.9,
      p_m3_H: 21.5,
      p_m6_L: 14.9,
      p_m6_H: 29.6,
      p_m12_L: 14.9,
      p_m12_H: 31.65,
    },
    9911: {
      H: 3.82,
      L: 3.69,
      O: 3.76,
      C: 3.8,
      V: 2744000,
      p_w1_L: 3.4,
      p_w1_H: 3.89,
      p_m1_L: 3.4,
      p_m1_H: 4.45,
      p_m3_L: 3.4,
      p_m3_H: 5.2,
      p_m6_L: 3.4,
      p_m6_H: 7.37,
      p_m12_L: 1.7,
      p_m12_H: 11.54,
    },
    9913: {
      H: 3.46,
      L: 3.37,
      O: 3.44,
      C: 3.44,
      V: 1076000,
      p_w1_L: 3.37,
      p_w1_H: 3.58,
      p_m1_L: 3.37,
      p_m1_H: 3.67,
      p_m3_L: 3.34,
      p_m3_H: 3.67,
      p_m6_L: 2.89,
      p_m6_H: 3.67,
      p_m12_L: 0.4,
      p_m12_H: 4.05,
    },
    9916: {
      H: 0.97,
      L: 0.96,
      O: 0.97,
      C: 0.96,
      V: 12000,
      p_w1_L: 0.96,
      p_w1_H: 1,
      p_m1_L: 0.95,
      p_m1_H: 1.03,
      p_m3_L: 0.95,
      p_m3_H: 1.09,
      p_m6_L: 0.95,
      p_m6_H: 1.12,
      p_m12_L: 0.95,
      p_m12_H: 1.31,
    },
    9918: {
      H: 0.097,
      L: 0.097,
      O: 0.097,
      C: 0.096,
      V: 8000,
      p_w1_L: 0.087,
      p_w1_H: 0.099,
      p_m1_L: 0.087,
      p_m1_H: 0.101,
      p_m3_L: 0.087,
      p_m3_H: 0.115,
      p_m6_L: 0.087,
      p_m6_H: 0.185,
      p_m12_L: 0.086,
      p_m12_H: 0.265,
    },
    9919: {
      H: 1.3,
      L: 1.3,
      O: 1.3,
      C: 1.29,
      V: 2000,
      p_w1_L: 1.15,
      p_w1_H: 1.35,
      p_m1_L: 1.15,
      p_m1_H: 1.44,
      p_m3_L: 1.15,
      p_m3_H: 1.99,
      p_m6_L: 0.7,
      p_m6_H: 2.03,
      p_m12_L: 0.7,
      p_m12_H: 2.03,
    },
    9922: {
      H: 15.38,
      L: 14.6,
      O: 15,
      C: 14.74,
      V: 11221900,
      p_w1_L: 14.24,
      p_w1_H: 16.08,
      p_m1_L: 14.24,
      p_m1_H: 19.36,
      p_m3_L: 14.24,
      p_m3_H: 27.2,
      p_m6_L: 14.24,
      p_m6_H: 33.75,
      p_m12_L: 14.24,
      p_m12_H: 38.7,
    },
    9923: {
      H: 27.15,
      L: 26,
      O: 26,
      C: 26.5,
      V: 664925,
      p_w1_L: 25,
      p_w1_H: 29.6,
      p_m1_L: 22.5,
      p_m1_H: 34,
      p_m3_L: 22.5,
      p_m3_H: 36.85,
      p_m6_L: 22.5,
      p_m6_H: 51.9,
      p_m12_L: 22.5,
      p_m12_H: 122.7,
    },
    9926: {
      H: 43.2,
      L: 41.45,
      O: 41.9,
      C: 42.6,
      V: 3065100,
      p_w1_L: 38.75,
      p_w1_H: 49.45,
      p_m1_L: 37.5,
      p_m1_H: 52.5,
      p_m3_L: 35.05,
      p_m3_H: 52.5,
      p_m6_L: 34.6,
      p_m6_H: 66,
      p_m12_L: 28,
      p_m12_H: 69.9,
    },
    9928: {
      H: 3.59,
      L: 3.44,
      O: 3.57,
      C: 3.49,
      V: 1028540,
      p_w1_L: 3.32,
      p_w1_H: 3.63,
      p_m1_L: 3.21,
      p_m1_H: 3.83,
      p_m3_L: 3.21,
      p_m3_H: 4.68,
      p_m6_L: 3.21,
      p_m6_H: 7.16,
      p_m12_L: 3.21,
      p_m12_H: 8.8,
    },
    9929: {
      H: 0.079,
      L: 0.072,
      O: 0.077,
      C: 0.075,
      V: 740000,
      p_w1_L: 0.071,
      p_w1_H: 0.085,
      p_m1_L: 0.071,
      p_m1_H: 0.1,
      p_m3_L: 0.063,
      p_m3_H: 0.1,
      p_m6_L: 0.057,
      p_m6_H: 0.1,
      p_m12_L: 0.057,
      p_m12_H: 0.1,
    },
    9933: {
      H: 0.295,
      L: 0.295,
      O: 0.295,
      C: 0.295,
      V: 20000,
      p_w1_L: 0.28,
      p_w1_H: 0.305,
      p_m1_L: 0.25,
      p_m1_H: 0.34,
      p_m3_L: 0.191,
      p_m3_H: 0.34,
      p_m6_L: 0.138,
      p_m6_H: 0.355,
      p_m12_L: 0.127,
      p_m12_H: 0.355,
    },
    9936: {
      H: 4.88,
      L: 4.69,
      O: 4.73,
      C: 4.88,
      V: 1345000,
      p_w1_L: 4.64,
      p_w1_H: 5.12,
      p_m1_L: 4.53,
      p_m1_H: 5.79,
      p_m3_L: 4.14,
      p_m3_H: 5.79,
      p_m6_L: 4.14,
      p_m6_H: 6.43,
      p_m12_L: 1.99,
      p_m12_H: 6.43,
    },
    9938: {
      H: 0.129,
      L: 0.129,
      O: 0.129,
      C: 0.129,
      V: 105000,
      p_w1_L: 0.129,
      p_w1_H: 0.141,
      p_m1_L: 0.128,
      p_m1_H: 0.148,
      p_m3_L: 0.125,
      p_m3_H: 0.148,
      p_m6_L: 0.125,
      p_m6_H: 0.154,
      p_m12_L: 0.112,
      p_m12_H: 0.23,
    },
    9939: {
      H: 60.15,
      L: 56.75,
      O: 56.9,
      C: 57.75,
      V: 1418800,
      p_w1_L: 53.2,
      p_w1_H: 60.15,
      p_m1_L: 33,
      p_m1_H: 64,
      p_m3_L: 33,
      p_m3_H: 83.4,
      p_m6_L: 33,
      p_m6_H: 89,
      p_m12_L: 7.58,
      p_m12_H: 89,
    },
    9956: {
      H: 9.21,
      L: 8.51,
      O: 9.09,
      C: 9.19,
      V: 345500,
      p_w1_L: 8.5,
      p_w1_H: 9.78,
      p_m1_L: 8.5,
      p_m1_H: 13.88,
      p_m3_L: 8.5,
      p_m3_H: 13.88,
      p_m6_L: 8.5,
      p_m6_H: 13.88,
      p_m12_L: 8.5,
      p_m12_H: 13.88,
    },
    9958: {
      H: 1.38,
      L: 1.34,
      O: 1.37,
      C: 1.38,
      V: 262000,
      p_w1_L: 1.28,
      p_w1_H: 1.45,
      p_m1_L: 1.23,
      p_m1_H: 1.45,
      p_m3_L: 1.23,
      p_m3_H: 1.74,
      p_m6_L: 1.11,
      p_m6_H: 1.74,
      p_m12_L: 1.11,
      p_m12_H: 1.88,
    },
    9959: {
      H: 7.55,
      L: 6.8,
      O: 7.1,
      C: 7.2,
      V: 12627500,
      p_w1_L: 5.28,
      p_w1_H: 7.55,
      p_m1_L: 5.28,
      p_m1_H: 7.77,
      p_m3_L: 5.28,
      p_m3_H: 10.78,
      p_m6_L: 5.28,
      p_m6_H: 18.18,
      p_m12_L: 5.28,
      p_m12_H: 25.1,
    },
    9960: {
      H: 4.74,
      L: 4.65,
      O: 4.66,
      C: 4.73,
      V: 236000,
      p_w1_L: 4.65,
      p_w1_H: 5.05,
      p_m1_L: 4.36,
      p_m1_H: 5.05,
      p_m3_L: 4.17,
      p_m3_H: 7.14,
      p_m6_L: 4.17,
      p_m6_H: 9.78,
      p_m12_L: 4.17,
      p_m12_H: 9.78,
    },
    9961: {
      H: 221.8,
      L: 212.6,
      O: 220,
      C: 212.6,
      V: 232969,
      p_w1_L: 193.9,
      p_w1_H: 226.8,
      p_m1_L: 193.9,
      p_m1_H: 249.2,
      p_m3_L: 193.9,
      p_m3_H: 261.2,
      p_m6_L: 185.7,
      p_m6_H: 297,
      p_m12_L: 185.7,
      p_m12_H: 326,
    },
    9963: {
      H: 1.27,
      L: 1.24,
      O: 1.24,
      C: 1.27,
      V: 10000,
      p_w1_L: 1.21,
      p_w1_H: 1.36,
      p_m1_L: 1.07,
      p_m1_H: 1.45,
      p_m3_L: 1.07,
      p_m3_H: 1.5,
      p_m6_L: 1.07,
      p_m6_H: 1.54,
      p_m12_L: 1.07,
      p_m12_H: 1.61,
    },
    9966: {
      H: 16.92,
      L: 16.1,
      O: 16.5,
      C: 16.34,
      V: 2084000,
      p_w1_L: 15.5,
      p_w1_H: 19.38,
      p_m1_L: 13.92,
      p_m1_H: 20.5,
      p_m3_L: 13.9,
      p_m3_H: 23.9,
      p_m6_L: 13.9,
      p_m6_H: 28.7,
      p_m12_L: 9.9,
      p_m12_H: 28.7,
    },
    9968: {
      H: 1.95,
      L: 1.94,
      O: 1.95,
      C: 1.95,
      V: 3646000,
      p_w1_L: 1.89,
      p_w1_H: 1.95,
      p_m1_L: 1.88,
      p_m1_H: 1.99,
      p_m3_L: 1.87,
      p_m3_H: 2.05,
      p_m6_L: 1.81,
      p_m6_H: 2.05,
      p_m12_L: 1.8,
      p_m12_H: 2.14,
    },
    9969: {
      H: 18.96,
      L: 18.34,
      O: 18.6,
      C: 18.44,
      V: 2181020,
      p_w1_L: 17.5,
      p_w1_H: 20.3,
      p_m1_L: 17.5,
      p_m1_H: 22.3,
      p_m3_L: 17.2,
      p_m3_H: 23.25,
      p_m6_L: 17.2,
      p_m6_H: 32.05,
      p_m12_L: 12.02,
      p_m12_H: 32.05,
    },
    9977: {
      H: 1.65,
      L: 1.58,
      O: 1.63,
      C: 1.58,
      V: 106000,
      p_w1_L: 1.56,
      p_w1_H: 1.73,
      p_m1_L: 1.56,
      p_m1_H: 1.9,
      p_m3_L: 1.51,
      p_m3_H: 1.97,
      p_m6_L: 1.51,
      p_m6_H: 2.32,
      p_m12_L: 1.51,
      p_m12_H: 3.94,
    },
    9978: {
      H: 0.475,
      L: 0.475,
      O: 0.475,
      C: 0.475,
      V: 0,
      p_w1_L: 0.475,
      p_w1_H: 0.475,
      p_m1_L: 0.455,
      p_m1_H: 0.53,
      p_m3_L: 0.455,
      p_m3_H: 0.55,
      p_m6_L: 0.455,
      p_m6_H: 0.68,
      p_m12_L: 0.455,
      p_m12_H: 0.72,
    },
    9979: {
      H: 4.5,
      L: 4.24,
      O: 4.5,
      C: 4.4,
      V: 5359000,
      p_w1_L: 4.24,
      p_w1_H: 4.65,
      p_m1_L: 3.34,
      p_m1_H: 4.66,
      p_m3_L: 3.06,
      p_m3_H: 4.66,
      p_m6_L: 3.06,
      p_m6_H: 5.56,
      p_m12_L: 2.73,
      p_m12_H: 5.67,
    },
    9982: {
      H: 1.63,
      L: 1.55,
      O: 1.57,
      C: 1.63,
      V: 3086000,
      p_w1_L: 1.52,
      p_w1_H: 1.72,
      p_m1_L: 1.02,
      p_m1_H: 1.72,
      p_m3_L: 1.02,
      p_m3_H: 1.73,
      p_m6_L: 1.02,
      p_m6_H: 2.74,
      p_m12_L: 1.02,
      p_m12_H: 2.95,
    },
    9983: {
      H: 5.43,
      L: 5.27,
      O: 5.35,
      C: 5.38,
      V: 833000,
      p_w1_L: 4.88,
      p_w1_H: 5.43,
      p_m1_L: 4.69,
      p_m1_H: 5.44,
      p_m3_L: 4.69,
      p_m3_H: 6.24,
      p_m6_L: 4.69,
      p_m6_H: 8.38,
      p_m12_L: 4.69,
      p_m12_H: 8.38,
    },
    9986: {
      H: 0.475,
      L: 0.475,
      O: 0.475,
      C: 0.475,
      V: 62000,
      p_w1_L: 0.41,
      p_w1_H: 0.495,
      p_m1_L: 0.41,
      p_m1_H: 0.53,
      p_m3_L: 0.37,
      p_m3_H: 0.53,
      p_m6_L: 0.37,
      p_m6_H: 0.84,
      p_m12_L: 0.37,
      p_m12_H: 1.08,
    },
    9987: {
      H: 412,
      L: 400.2,
      O: 412,
      C: 407,
      V: 165086,
      p_w1_L: 387.8,
      p_w1_H: 412,
      p_m1_L: 387.8,
      p_m1_H: 449.8,
      p_m3_L: 387.8,
      p_m3_H: 494.6,
      p_m6_L: 387.8,
      p_m6_H: 541,
      p_m12_L: 387.8,
      p_m12_H: 544,
    },
    9988: {
      H: 123,
      L: 119.2,
      O: 122.7,
      C: 120.6,
      V: 61244100,
      p_w1_L: 109.5,
      p_w1_H: 127,
      p_m1_L: 109.5,
      p_m1_H: 166,
      p_m3_L: 109.5,
      p_m3_H: 179.9,
      p_m6_L: 109.5,
      p_m6_H: 225,
      p_m12_L: 109.5,
      p_m12_H: 270,
    },
    9989: {
      H: 8.18,
      L: 8.06,
      O: 8.18,
      C: 8.15,
      V: 122500,
      p_w1_L: 8.03,
      p_w1_H: 8.29,
      p_m1_L: 7.96,
      p_m1_H: 8.48,
      p_m3_L: 7.81,
      p_m3_H: 9.26,
      p_m6_L: 7.81,
      p_m6_H: 11.34,
      p_m12_L: 7.81,
      p_m12_H: 14.68,
    },
    9990: {
      H: 9.7,
      L: 9.24,
      O: 9.42,
      C: 9.6,
      V: 865000,
      p_w1_L: 8.94,
      p_w1_H: 11.96,
      p_m1_L: 8.94,
      p_m1_H: 13.3,
      p_m3_L: 8.5,
      p_m3_H: 13.3,
      p_m6_L: 7.5,
      p_m6_H: 15.78,
      p_m12_L: 7.5,
      p_m12_H: 24.5,
    },
    9991: {
      H: 38.15,
      L: 36,
      O: 37.3,
      C: 38.15,
      V: 91350,
      p_w1_L: 32.85,
      p_w1_H: 38.75,
      p_m1_L: 32.85,
      p_m1_H: 49.8,
      p_m3_L: 32.85,
      p_m3_H: 63.7,
      p_m6_L: 32.85,
      p_m6_H: 97,
      p_m12_L: 32.85,
      p_m12_H: 145.4,
    },
    9992: {
      H: 48.8,
      L: 47.45,
      O: 48,
      C: 47.6,
      V: 2583180,
      p_w1_L: 46.8,
      p_w1_H: 55.8,
      p_m1_L: 46.8,
      p_m1_H: 61.5,
      p_m3_L: 45.15,
      p_m3_H: 63.65,
      p_m6_L: 45.15,
      p_m6_H: 78.15,
      p_m12_L: 45.15,
      p_m12_H: 107.6,
    },
    9993: {
      H: 3.87,
      L: 3.78,
      O: 3.84,
      C: 3.82,
      V: 1600000,
      p_w1_L: 3.63,
      p_w1_H: 4.08,
      p_m1_L: 3.6,
      p_m1_H: 4.08,
      p_m3_L: 3.6,
      p_m3_H: 4.51,
      p_m6_L: 3.6,
      p_m6_H: 4.76,
      p_m12_L: 3.6,
      p_m12_H: 5.2,
    },
    9995: {
      H: 95,
      L: 92.05,
      O: 93,
      C: 93.7,
      V: 648000,
      p_w1_L: 87.75,
      p_w1_H: 106.4,
      p_m1_L: 85.65,
      p_m1_H: 112.6,
      p_m3_L: 83.8,
      p_m3_H: 112.6,
      p_m6_L: 80.25,
      p_m6_H: 145.5,
      p_m12_L: 68.5,
      p_m12_H: 149.8,
    },
    9996: {
      H: 15.6,
      L: 14.96,
      O: 15.26,
      C: 15.2,
      V: 351000,
      p_w1_L: 14.62,
      p_w1_H: 17.04,
      p_m1_L: 14.62,
      p_m1_H: 20.8,
      p_m3_L: 14.62,
      p_m3_H: 24.2,
      p_m6_L: 14.62,
      p_m6_H: 39.1,
      p_m12_L: 14.62,
      p_m12_H: 39.1,
    },
    9997: {
      H: 7.71,
      L: 7.55,
      O: 7.61,
      C: 7.69,
      V: 2390000,
      p_w1_L: 7.29,
      p_w1_H: 8.76,
      p_m1_L: 7.29,
      p_m1_H: 9.85,
      p_m3_L: 7.29,
      p_m3_H: 11.84,
      p_m6_L: 7.29,
      p_m6_H: 14.42,
      p_m12_L: 7.29,
      p_m12_H: 17.5,
    },
    9998: {
      H: 0.193,
      L: 0.193,
      O: 0.193,
      C: 0.193,
      V: 0,
      p_w1_L: 0.192,
      p_w1_H: 0.194,
      p_m1_L: 0.18,
      p_m1_H: 0.219,
      p_m3_L: 0.18,
      p_m3_H: 0.22,
      p_m6_L: 0.18,
      p_m6_H: 0.335,
      p_m12_L: 0.16,
      p_m12_H: 0.335,
    },
    9999: {
      H: 169.9,
      L: 165.5,
      O: 168.9,
      C: 167.4,
      V: 4925780,
      p_w1_L: 159,
      p_w1_H: 179.4,
      p_m1_L: 156,
      p_m1_H: 188.9,
      p_m3_L: 121.4,
      p_m3_H: 188.9,
      p_m6_L: 117.2,
      p_m6_H: 188.9,
      p_m12_L: 117.2,
      p_m12_H: 207.4,
    },
    "0001": {
      H: 50.85,
      L: 49.8,
      O: 50.8,
      C: 50.1,
      V: 3961740,
      p_w1_L: 48.8,
      p_w1_H: 51,
      p_m1_L: 48.8,
      p_m1_H: 53.25,
      p_m3_L: 48.8,
      p_m3_H: 55.5,
      p_m6_L: 48.8,
      p_m6_H: 62.5,
      p_m12_L: 48.8,
      p_m12_H: 65.8,
    },
    "0002": {
      H: 75.45,
      L: 74.6,
      O: 75.4,
      C: 75,
      V: 3186970,
      p_w1_L: 74.55,
      p_w1_H: 76.65,
      p_m1_L: 74.55,
      p_m1_H: 77.25,
      p_m3_L: 73.8,
      p_m3_H: 77.25,
      p_m6_L: 73.8,
      p_m6_H: 81.65,
      p_m12_L: 70.25,
      p_m12_H: 81.65,
    },
    "0003": {
      H: 11.78,
      L: 11.62,
      O: 11.74,
      C: 11.66,
      V: 22585300,
      p_w1_L: 11.58,
      p_w1_H: 11.92,
      p_m1_L: 11.54,
      p_m1_H: 12,
      p_m3_L: 11.32,
      p_m3_H: 12.58,
      p_m6_L: 11.32,
      p_m6_H: 13.4,
      p_m12_L: 10.362,
      p_m12_H: 13.4,
    },
    "0004": {
      H: 26.2,
      L: 25.45,
      O: 25.65,
      C: 25.55,
      V: 780150,
      p_w1_L: 25.35,
      p_w1_H: 27.55,
      p_m1_L: 25.35,
      p_m1_H: 29,
      p_m3_L: 22.95,
      p_m3_H: 29,
      p_m6_L: 22.95,
      p_m6_H: 30.7,
      p_m12_L: 16.76,
      p_m12_H: 30.7,
    },
    "0005": {
      H: 45.95,
      L: 45.1,
      O: 45.95,
      C: 45.5,
      V: 8590650,
      p_w1_L: 43,
      p_w1_H: 45.95,
      p_m1_L: 42.2,
      p_m1_H: 46.5,
      p_m3_L: 38.3,
      p_m3_H: 48,
      p_m6_L: 38.3,
      p_m6_H: 49.1,
      p_m12_L: 38.3,
      p_m12_H: 50.85,
    },
    "0006": {
      H: 47.6,
      L: 46.95,
      O: 47.6,
      C: 47.2,
      V: 1628650,
      p_w1_L: 46.3,
      p_w1_H: 47.95,
      p_m1_L: 46.25,
      p_m1_H: 48.35,
      p_m3_L: 44.2,
      p_m3_H: 48.35,
      p_m6_L: 44.2,
      p_m6_H: 51.35,
      p_m12_L: 40,
      p_m12_H: 51.35,
    },
    "0007": {
      H: 0.127,
      L: 0.127,
      O: 0.127,
      C: 0.127,
      V: 0,
      p_w1_L: 0.127,
      p_w1_H: 0.127,
      p_m1_L: 0.127,
      p_m1_H: 0.127,
      p_m3_L: 0.127,
      p_m3_H: 0.127,
      p_m6_L: 0.127,
      p_m6_H: 0.127,
      p_m12_L: 0.09,
      p_m12_H: 0.192,
    },
    "0008": {
      H: 3.98,
      L: 3.96,
      O: 3.98,
      C: 3.96,
      V: 3488370,
      p_w1_L: 3.94,
      p_w1_H: 3.99,
      p_m1_L: 3.94,
      p_m1_H: 4.11,
      p_m3_L: 3.92,
      p_m3_H: 4.15,
      p_m6_L: 3.92,
      p_m6_H: 4.28,
      p_m12_L: 3.92,
      p_m12_H: 4.84,
    },
    "0009": {
      H: 0.038,
      L: 0.038,
      O: 0.038,
      C: 0.038,
      V: 402000,
      p_w1_L: 0.038,
      p_w1_H: 0.053,
      p_m1_L: 0.038,
      p_m1_H: 0.058,
      p_m3_L: 0.038,
      p_m3_H: 0.061,
      p_m6_L: 0.038,
      p_m6_H: 0.073,
      p_m12_L: 0.038,
      p_m12_H: 0.102,
    },
    "0010": {
      H: 17.66,
      L: 17.14,
      O: 17.52,
      C: 17.16,
      V: 696060,
      p_w1_L: 17,
      p_w1_H: 17.66,
      p_m1_L: 17,
      p_m1_H: 18.66,
      p_m3_L: 17,
      p_m3_H: 19,
      p_m6_L: 17,
      p_m6_H: 20.45,
      p_m12_L: 17,
      p_m12_H: 21.6,
    },
    "0011": {
      H: 143.6,
      L: 142,
      O: 143,
      C: 142.9,
      V: 471171,
      p_w1_L: 137.7,
      p_w1_H: 144.9,
      p_m1_L: 136.2,
      p_m1_H: 148.7,
      p_m3_L: 131,
      p_m3_H: 149.1,
      p_m6_L: 131,
      p_m6_H: 162.5,
      p_m12_L: 130.5,
      p_m12_H: 166.7,
    },
    "0012": {
      H: 33.2,
      L: 32.8,
      O: 32.95,
      C: 33.05,
      V: 1402410,
      p_w1_L: 31.8,
      p_w1_H: 33.2,
      p_m1_L: 31.8,
      p_m1_H: 34.3,
      p_m3_L: 28,
      p_m3_H: 34.65,
      p_m6_L: 28,
      p_m6_H: 38.85,
      p_m12_L: 28,
      p_m12_H: 38.85,
    },
    "0013": {
      H: 56.3,
      L: 54.35,
      O: 56.3,
      C: 54.65,
      V: 1312500,
      p_w1_L: 52.5,
      p_w1_H: 56.35,
      p_m1_L: 46,
      p_m1_H: 59.8,
      p_m3_L: 44.4,
      p_m3_H: 63.65,
      p_m6_L: 44.4,
      p_m6_H: 85.8,
      p_m12_L: 44.4,
      p_m12_H: 85.8,
    },
    "0014": {
      H: 24.65,
      L: 24.2,
      O: 24.25,
      C: 24.45,
      V: 662191,
      p_w1_L: 23.55,
      p_w1_H: 24.65,
      p_m1_L: 23.55,
      p_m1_H: 27.5,
      p_m3_L: 23.55,
      p_m3_H: 28,
      p_m6_L: 23.55,
      p_m6_H: 32.45,
      p_m12_L: 23.55,
      p_m12_H: 33.8,
    },
    "0016": {
      H: 96.25,
      L: 95.1,
      O: 96.1,
      C: 95.8,
      V: 3544250,
      p_w1_L: 93.2,
      p_w1_H: 96.6,
      p_m1_L: 93.2,
      p_m1_H: 101.7,
      p_m3_L: 93.2,
      p_m3_H: 111.8,
      p_m6_L: 93.2,
      p_m6_H: 124.3,
      p_m12_L: 93.2,
      p_m12_H: 126,
    },
    "0017": {
      H: 31.4,
      L: 30.95,
      O: 31.05,
      C: 31.1,
      V: 4214900,
      p_w1_L: 30.3,
      p_w1_H: 31.6,
      p_m1_L: 30.3,
      p_m1_H: 34.95,
      p_m3_L: 30.1,
      p_m3_H: 36.85,
      p_m6_L: 30.1,
      p_m6_H: 42.75,
      p_m12_L: 30.1,
      p_m12_H: 43,
    },
    "0018": {
      H: 0.69,
      L: 0.68,
      O: 0.68,
      C: 0.69,
      V: 30000,
      p_w1_L: 0.67,
      p_w1_H: 0.73,
      p_m1_L: 0.67,
      p_m1_H: 0.75,
      p_m3_L: 0.67,
      p_m3_H: 0.76,
      p_m6_L: 0.59,
      p_m6_H: 0.85,
      p_m12_L: 0.41,
      p_m12_H: 0.85,
    },
    "0019": {
      H: 44.65,
      L: 43.65,
      O: 44.3,
      C: 43.95,
      V: 865959,
      p_w1_L: 42.15,
      p_w1_H: 44.65,
      p_m1_L: 42.15,
      p_m1_H: 50.2,
      p_m3_L: 42.15,
      p_m3_H: 52.4,
      p_m6_L: 42.15,
      p_m6_H: 59.25,
      p_m12_L: 42.1,
      p_m12_H: 64.6,
    },
    "0021": {
      H: 0.15,
      L: 0.15,
      O: 0.15,
      C: 0.15,
      V: 0,
      p_w1_L: 0.139,
      p_w1_H: 0.157,
      p_m1_L: 0.135,
      p_m1_H: 0.18,
      p_m3_L: 0.131,
      p_m3_H: 0.194,
      p_m6_L: 0.131,
      p_m6_H: 0.228,
      p_m12_L: 0.07,
      p_m12_H: 0.34,
    },
    "0022": {
      H: 0.122,
      L: 0.121,
      O: 0.122,
      C: 0.121,
      V: 480000,
      p_w1_L: 0.121,
      p_w1_H: 0.128,
      p_m1_L: 0.121,
      p_m1_H: 0.129,
      p_m3_L: 0.104,
      p_m3_H: 0.14,
      p_m6_L: 0.104,
      p_m6_H: 0.14,
      p_m12_L: 0.103,
      p_m12_H: 0.14,
    },
    "0023": {
      H: 11.6,
      L: 11.46,
      O: 11.52,
      C: 11.46,
      V: 752991,
      p_w1_L: 11.18,
      p_w1_H: 11.68,
      p_m1_L: 11,
      p_m1_H: 12.62,
      p_m3_L: 11,
      p_m3_H: 13.8,
      p_m6_L: 11,
      p_m6_H: 15.88,
      p_m12_L: 11,
      p_m12_H: 19.5,
    },
    "0025": {
      H: 9.41,
      L: 9.41,
      O: 9.41,
      C: 9.41,
      V: 2000,
      p_w1_L: 9.4,
      p_w1_H: 9.5,
      p_m1_L: 9.23,
      p_m1_H: 9.66,
      p_m3_L: 9.18,
      p_m3_H: 9.76,
      p_m6_L: 9.18,
      p_m6_H: 10.12,
      p_m12_L: 9.18,
      p_m12_H: 10.24,
    },
    "0026": {
      H: 98.6,
      L: 98.2,
      O: 98.6,
      C: 98.2,
      V: 3600,
      p_w1_L: 98.2,
      p_w1_H: 99.95,
      p_m1_L: 98.2,
      p_m1_H: 104,
      p_m3_L: 98.2,
      p_m3_H: 108,
      p_m6_L: 98.2,
      p_m6_H: 111,
      p_m12_L: 96.25,
      p_m12_H: 119,
    },
    "0027": {
      H: 43.35,
      L: 42.5,
      O: 42.9,
      C: 43.2,
      V: 6892120,
      p_w1_L: 40.2,
      p_w1_H: 43.55,
      p_m1_L: 40.2,
      p_m1_H: 50.25,
      p_m3_L: 38.3,
      p_m3_H: 54.65,
      p_m6_L: 38.3,
      p_m6_H: 64.3,
      p_m12_L: 38.3,
      p_m12_H: 80.3,
    },
    "0028": {
      H: 4.34,
      L: 4.17,
      O: 4.34,
      C: 4.34,
      V: 12027000,
      p_w1_L: 4.11,
      p_w1_H: 4.34,
      p_m1_L: 4.06,
      p_m1_H: 4.48,
      p_m3_L: 4.06,
      p_m3_H: 4.89,
      p_m6_L: 4.06,
      p_m6_H: 5,
      p_m12_L: 4.06,
      p_m12_H: 5,
    },
    "0029": {
      H: 11.2,
      L: 10.96,
      O: 11.2,
      C: 10.96,
      V: 4000,
      p_w1_L: 10.4,
      p_w1_H: 11.4,
      p_m1_L: 10.4,
      p_m1_H: 12.3,
      p_m3_L: 10.4,
      p_m3_H: 13.9,
      p_m6_L: 10.4,
      p_m6_H: 14.08,
      p_m12_L: 10.4,
      p_m12_H: 14.8,
    },
    "0030": {
      H: 0.265,
      L: 0.25,
      O: 0.255,
      C: 0.255,
      V: 4998000,
      p_w1_L: 0.25,
      p_w1_H: 0.265,
      p_m1_L: 0.246,
      p_m1_H: 0.285,
      p_m3_L: 0.232,
      p_m3_H: 0.36,
      p_m6_L: 0.232,
      p_m6_H: 0.46,
      p_m12_L: 0.232,
      p_m12_H: 0.57,
    },
    "0031": {
      H: 0.55,
      L: 0.53,
      O: 0.54,
      C: 0.53,
      V: 1314000,
      p_w1_L: 0.51,
      p_w1_H: 0.55,
      p_m1_L: 0.5,
      p_m1_H: 0.58,
      p_m3_L: 0.5,
      p_m3_H: 0.68,
      p_m6_L: 0.5,
      p_m6_H: 0.75,
      p_m12_L: 0.445,
      p_m12_H: 0.83,
    },
    "0032": {
      H: 11.4,
      L: 11.4,
      O: 11.4,
      C: 11.4,
      V: 0,
      p_w1_L: 11.4,
      p_w1_H: 11.66,
      p_m1_L: 11.4,
      p_m1_H: 11.66,
      p_m3_L: 10,
      p_m3_H: 11.88,
      p_m6_L: 10,
      p_m6_H: 13.1,
      p_m12_L: 10,
      p_m12_H: 14.3,
    },
    "0033": {
      H: 1.37,
      L: 1.3,
      O: 1.31,
      C: 1.37,
      V: 60460,
      p_w1_L: 1.26,
      p_w1_H: 1.4,
      p_m1_L: 0.95,
      p_m1_H: 1.5,
      p_m3_L: 0.55,
      p_m3_H: 1.5,
      p_m6_L: 0.55,
      p_m6_H: 1.75,
      p_m12_L: 0.55,
      p_m12_H: 3.26,
    },
    "0034": {
      H: 10.3,
      L: 10.12,
      O: 10.3,
      C: 10.24,
      V: 111000,
      p_w1_L: 10.08,
      p_w1_H: 10.32,
      p_m1_L: 10,
      p_m1_H: 11.06,
      p_m3_L: 8.52,
      p_m3_H: 11.06,
      p_m6_L: 8.52,
      p_m6_H: 11.06,
      p_m12_L: 7.5,
      p_m12_H: 11.06,
    },
    "0035": {
      H: 2.58,
      L: 2.5,
      O: 2.58,
      C: 2.58,
      V: 2332380,
      p_w1_L: 2.36,
      p_w1_H: 2.58,
      p_m1_L: 2.36,
      p_m1_H: 2.58,
      p_m3_L: 2.36,
      p_m3_H: 2.84,
      p_m6_L: 2.36,
      p_m6_H: 3.25,
      p_m12_L: 2.36,
      p_m12_H: 3.25,
    },
    "0036": {
      H: 0.115,
      L: 0.115,
      O: 0.115,
      C: 0.115,
      V: 0,
      p_w1_L: 0.105,
      p_w1_H: 0.121,
      p_m1_L: 0.105,
      p_m1_H: 0.137,
      p_m3_L: 0.105,
      p_m3_H: 0.144,
      p_m6_L: 0.105,
      p_m6_H: 0.201,
      p_m12_L: 0.105,
      p_m12_H: 0.201,
    },
    "0037": {
      H: 0.137,
      L: 0.137,
      O: 0.137,
      C: 0.137,
      V: 0,
      p_w1_L: 0.129,
      p_w1_H: 0.138,
      p_m1_L: 0.128,
      p_m1_H: 0.14,
      p_m3_L: 0.12,
      p_m3_H: 0.149,
      p_m6_L: 0.111,
      p_m6_H: 0.149,
      p_m12_L: 0.111,
      p_m12_H: 0.23,
    },
    "0038": {
      H: 3.87,
      L: 3.75,
      O: 3.8,
      C: 3.82,
      V: 2716000,
      p_w1_L: 3.58,
      p_w1_H: 3.89,
      p_m1_L: 3.52,
      p_m1_H: 3.89,
      p_m3_L: 3.52,
      p_m3_H: 4.68,
      p_m6_L: 3.52,
      p_m6_H: 6.3,
      p_m12_L: 2.75,
      p_m12_H: 6.3,
    },
    "0039": {
      H: 0.052,
      L: 0.047,
      O: 0.052,
      C: 0.05,
      V: 2932000,
      p_w1_L: 0.047,
      p_w1_H: 0.054,
      p_m1_L: 0.047,
      p_m1_H: 0.059,
      p_m3_L: 0.047,
      p_m3_H: 0.067,
      p_m6_L: 0.042,
      p_m6_H: 0.08,
      p_m12_L: 0.042,
      p_m12_H: 0.144,
    },
    "0040": {
      H: 0.64,
      L: 0.62,
      O: 0.63,
      C: 0.62,
      V: 874000,
      p_w1_L: 0.61,
      p_w1_H: 0.67,
      p_m1_L: 0.6,
      p_m1_H: 0.67,
      p_m3_L: 0.6,
      p_m3_H: 0.75,
      p_m6_L: 0.6,
      p_m6_H: 0.75,
      p_m12_L: 0.53,
      p_m12_H: 0.76,
    },
    "0041": {
      H: 19.86,
      L: 19.6,
      O: 19.6,
      C: 19.86,
      V: 28404,
      p_w1_L: 19.36,
      p_w1_H: 20.1,
      p_m1_L: 19.36,
      p_m1_H: 21.45,
      p_m3_L: 19.28,
      p_m3_H: 24.7,
      p_m6_L: 19.28,
      p_m6_H: 27.7,
      p_m12_L: 19.28,
      p_m12_H: 30.9,
    },
    "0042": {
      H: 0.71,
      L: 0.68,
      O: 0.71,
      C: 0.69,
      V: 776000,
      p_w1_L: 0.6,
      p_w1_H: 0.71,
      p_m1_L: 0.59,
      p_m1_H: 0.71,
      p_m3_L: 0.58,
      p_m3_H: 0.76,
      p_m6_L: 0.53,
      p_m6_H: 0.76,
      p_m12_L: 0.51,
      p_m12_H: 0.92,
    },
    "0043": {
      H: 1.12,
      L: 1.1,
      O: 1.1,
      C: 1.11,
      V: 48828000,
      p_w1_L: 1.06,
      p_w1_H: 1.12,
      p_m1_L: 1.06,
      p_m1_H: 1.13,
      p_m3_L: 0.86,
      p_m3_H: 1.13,
      p_m6_L: 0.74,
      p_m6_H: 1.13,
      p_m12_L: 0.71,
      p_m12_H: 1.13,
    },
    "0045": {
      H: 7.1,
      L: 6.97,
      O: 7.1,
      C: 6.97,
      V: 328500,
      p_w1_L: 6.95,
      p_w1_H: 7.2,
      p_m1_L: 6.8,
      p_m1_H: 7.49,
      p_m3_L: 6.74,
      p_m3_H: 7.72,
      p_m6_L: 6.65,
      p_m6_H: 8.6,
      p_m12_L: 6.6,
      p_m12_H: 8.6,
    },
    "0046": {
      H: 2.7,
      L: 2.7,
      O: 2.7,
      C: 2.7,
      V: 0,
      p_w1_L: 2.66,
      p_w1_H: 2.7,
      p_m1_L: 2.66,
      p_m1_H: 2.73,
      p_m3_L: 2.62,
      p_m3_H: 2.89,
      p_m6_L: 2.55,
      p_m6_H: 2.89,
      p_m12_L: 2.25,
      p_m12_H: 2.89,
    },
    "0047": {
      H: 0.075,
      L: 0.074,
      O: 0.075,
      C: 0.075,
      V: 4844000,
      p_w1_L: 0.074,
      p_w1_H: 0.076,
      p_m1_L: 0.074,
      p_m1_H: 0.076,
      p_m3_L: 0.072,
      p_m3_H: 0.076,
      p_m6_L: 0.042,
      p_m6_H: 0.076,
      p_m12_L: 0.039,
      p_m12_H: 0.076,
    },
    "0048": {
      H: 1.01,
      L: 0.91,
      O: 0.92,
      C: 1.01,
      V: 171200,
      p_w1_L: 0.91,
      p_w1_H: 1.03,
      p_m1_L: 0.69,
      p_m1_H: 1.04,
      p_m3_L: 0.67,
      p_m3_H: 1.04,
      p_m6_L: 0.63,
      p_m6_H: 1.12,
      p_m12_L: 0.33,
      p_m12_H: 1.6,
    },
    "0050": {
      H: 5.5,
      L: 5.47,
      O: 5.48,
      C: 5.48,
      V: 40000,
      p_w1_L: 5.42,
      p_w1_H: 5.5,
      p_m1_L: 5.42,
      p_m1_H: 5.57,
      p_m3_L: 5.35,
      p_m3_H: 5.75,
      p_m6_L: 5.35,
      p_m6_H: 5.97,
      p_m12_L: 5.35,
      p_m12_H: 6.39,
    },
    "0051": {
      H: 6.99,
      L: 6.99,
      O: 6.99,
      C: 6.99,
      V: 0,
      p_w1_L: 6.51,
      p_w1_H: 7.1,
      p_m1_L: 6.51,
      p_m1_H: 7.1,
      p_m3_L: 6.51,
      p_m3_H: 7.93,
      p_m6_L: 6.51,
      p_m6_H: 8.8,
      p_m12_L: 6.51,
      p_m12_H: 8.8,
    },
    "0052": {
      H: 16.52,
      L: 16.44,
      O: 16.48,
      C: 16.46,
      V: 60000,
      p_w1_L: 16.3,
      p_w1_H: 16.58,
      p_m1_L: 16.26,
      p_m1_H: 17.2,
      p_m3_L: 16.26,
      p_m3_H: 18,
      p_m6_L: 16.26,
      p_m6_H: 18.24,
      p_m12_L: 16.26,
      p_m12_H: 18.96,
    },
    "0053": {
      H: 88.75,
      L: 88.2,
      O: 88.75,
      C: 88.2,
      V: 6000,
      p_w1_L: 88.2,
      p_w1_H: 90,
      p_m1_L: 88.2,
      p_m1_H: 92.5,
      p_m3_L: 88.2,
      p_m3_H: 93,
      p_m6_L: 88.2,
      p_m6_H: 93,
      p_m12_L: 85,
      p_m12_H: 99.3,
    },
    "0055": {
      H: 0.44,
      L: 0.44,
      O: 0.44,
      C: 0.415,
      V: 12000,
      p_w1_L: 0.39,
      p_w1_H: 0.45,
      p_m1_L: 0.39,
      p_m1_H: 0.51,
      p_m3_L: 0.375,
      p_m3_H: 0.53,
      p_m6_L: 0.36,
      p_m6_H: 0.55,
      p_m12_L: 0.34,
      p_m12_H: 0.67,
    },
    "0057": {
      H: 2.58,
      L: 2.45,
      O: 2.5,
      C: 2.53,
      V: 1322000,
      p_w1_L: 2.45,
      p_w1_H: 2.8,
      p_m1_L: 2.2,
      p_m1_H: 3.93,
      p_m3_L: 2.2,
      p_m3_H: 3.93,
      p_m6_L: 2.2,
      p_m6_H: 3.93,
      p_m12_L: 2.1,
      p_m12_H: 3.93,
    },
    "0058": {
      H: 0.25,
      L: 0.23,
      O: 0.23,
      C: 0.23,
      V: 620000,
      p_w1_L: 0.23,
      p_w1_H: 0.25,
      p_m1_L: 0.23,
      p_m1_H: 0.315,
      p_m3_L: 0.23,
      p_m3_H: 0.33,
      p_m6_L: 0.23,
      p_m6_H: 0.54,
      p_m12_L: 0.228,
      p_m12_H: 0.54,
    },
    "0059": {
      H: 0.87,
      L: 0.86,
      O: 0.87,
      C: 0.87,
      V: 4024000,
      p_w1_L: 0.84,
      p_w1_H: 0.94,
      p_m1_L: 0.82,
      p_m1_H: 0.95,
      p_m3_L: 0.82,
      p_m3_H: 0.97,
      p_m6_L: 0.82,
      p_m6_H: 1.04,
      p_m12_L: 0.82,
      p_m12_H: 1.11,
    },
    "0060": {
      H: 0.6,
      L: 0.6,
      O: 0.6,
      C: 0.6,
      V: 0,
      p_w1_L: 0.6,
      p_w1_H: 0.66,
      p_m1_L: 0.6,
      p_m1_H: 0.73,
      p_m3_L: 0.56,
      p_m3_H: 0.73,
      p_m6_L: 0.55,
      p_m6_H: 0.89,
      p_m12_L: 0.55,
      p_m12_H: 0.89,
    },
    "0061": {
      H: 0.125,
      L: 0.124,
      O: 0.124,
      C: 0.125,
      V: 220000,
      p_w1_L: 0.116,
      p_w1_H: 0.129,
      p_m1_L: 0.109,
      p_m1_H: 0.13,
      p_m3_L: 0.109,
      p_m3_H: 0.155,
      p_m6_L: 0.102,
      p_m6_H: 0.155,
      p_m12_L: 0.102,
      p_m12_H: 0.195,
    },
    "0062": {
      H: 12.98,
      L: 12.94,
      O: 12.96,
      C: 12.94,
      V: 40000,
      p_w1_L: 12.86,
      p_w1_H: 13.06,
      p_m1_L: 12.86,
      p_m1_H: 13.38,
      p_m3_L: 12.86,
      p_m3_H: 14.4,
      p_m6_L: 12.86,
      p_m6_H: 15.58,
      p_m12_L: 12.86,
      p_m12_H: 16.5,
    },
    "0063": {
      H: 0.076,
      L: 0.076,
      O: 0.076,
      C: 0.076,
      V: 0,
      p_w1_L: 0.062,
      p_w1_H: 0.077,
      p_m1_L: 0.062,
      p_m1_H: 0.095,
      p_m3_L: 0.062,
      p_m3_H: 0.105,
      p_m6_L: 0.062,
      p_m6_H: 0.119,
      p_m12_L: 0.062,
      p_m12_H: 0.126,
    },
    "0064": {
      H: 0.169,
      L: 0.162,
      O: 0.163,
      C: 0.163,
      V: 250000,
      p_w1_L: 0.162,
      p_w1_H: 0.169,
      p_m1_L: 0.161,
      p_m1_H: 0.172,
      p_m3_L: 0.161,
      p_m3_H: 0.177,
      p_m6_L: 0.161,
      p_m6_H: 0.19,
      p_m12_L: 0.161,
      p_m12_H: 0.248,
    },
    "0065": {
      H: 0.152,
      L: 0.135,
      O: 0.135,
      C: 0.147,
      V: 868000,
      p_w1_L: 0.12,
      p_w1_H: 0.161,
      p_m1_L: 0.12,
      p_m1_H: 0.31,
      p_m3_L: 0.12,
      p_m3_H: 0.44,
      p_m6_L: 0.12,
      p_m6_H: 0.52,
      p_m12_L: 0.12,
      p_m12_H: 0.81,
    },
    "0066": {
      H: 41.9,
      L: 41.25,
      O: 41.9,
      C: 41.4,
      V: 2075880,
      p_w1_L: 40.6,
      p_w1_H: 41.9,
      p_m1_L: 40.6,
      p_m1_H: 43.5,
      p_m3_L: 40.6,
      p_m3_H: 44.3,
      p_m6_L: 40.6,
      p_m6_H: 47.4,
      p_m12_L: 40.6,
      p_m12_H: 49,
    },
    "0067": {
      H: 25.12,
      L: 25.12,
      O: 25.12,
      C: 25.12,
      V: 0,
      p_w1_L: 25.12,
      p_w1_H: 25.12,
      p_m1_L: 25.12,
      p_m1_H: 25.12,
      p_m3_L: 25.12,
      p_m3_H: 25.12,
      p_m6_L: 25.12,
      p_m6_H: 25.12,
      p_m12_L: 25.12,
      p_m12_H: 25.12,
    },
    "0068": {
      H: 0.52,
      L: 0.52,
      O: 0.52,
      C: 0.52,
      V: 0,
      p_w1_L: 0.52,
      p_w1_H: 0.52,
      p_m1_L: 0.52,
      p_m1_H: 0.52,
      p_m3_L: 0.52,
      p_m3_H: 0.52,
      p_m6_L: 0.52,
      p_m6_H: 0.52,
      p_m12_L: 0.52,
      p_m12_H: 1.33,
    },
    "0069": {
      H: 6.5,
      L: 6.22,
      O: 6.35,
      C: 6.29,
      V: 476000,
      p_w1_L: 6.05,
      p_w1_H: 6.5,
      p_m1_L: 6.05,
      p_m1_H: 6.78,
      p_m3_L: 5.83,
      p_m3_H: 6.78,
      p_m6_L: 5.83,
      p_m6_H: 7.98,
      p_m12_L: 5.83,
      p_m12_H: 9.06,
    },
    "0070": {
      H: 0.077,
      L: 0.077,
      O: 0.077,
      C: 0.077,
      V: 100000,
      p_w1_L: 0.076,
      p_w1_H: 0.089,
      p_m1_L: 0.076,
      p_m1_H: 0.125,
      p_m3_L: 0.072,
      p_m3_H: 0.125,
      p_m6_L: 0.072,
      p_m6_H: 0.137,
      p_m12_L: 0.044,
      p_m12_H: 0.238,
    },
    "0071": {
      H: 12.9,
      L: 12.6,
      O: 12.6,
      C: 12.86,
      V: 3000,
      p_w1_L: 12.42,
      p_w1_H: 12.9,
      p_m1_L: 12.4,
      p_m1_H: 13.1,
      p_m3_L: 12.4,
      p_m3_H: 14,
      p_m6_L: 12.4,
      p_m6_H: 16,
      p_m12_L: 12.4,
      p_m12_H: 16.3,
    },
    "0072": {
      H: 0.183,
      L: 0.183,
      O: 0.183,
      C: 0.183,
      V: 0,
      p_w1_L: 0.183,
      p_w1_H: 0.183,
      p_m1_L: 0.183,
      p_m1_H: 0.199,
      p_m3_L: 0.169,
      p_m3_H: 0.199,
      p_m6_L: 0.162,
      p_m6_H: 0.212,
      p_m12_L: 0.148,
      p_m12_H: 0.275,
    },
    "0073": {
      H: 0.188,
      L: 0.18,
      O: 0.188,
      C: 0.182,
      V: 63000,
      p_w1_L: 0.17,
      p_w1_H: 0.199,
      p_m1_L: 0.166,
      p_m1_H: 0.208,
      p_m3_L: 0.161,
      p_m3_H: 0.245,
      p_m6_L: 0.161,
      p_m6_H: 0.385,
      p_m12_L: 0.141,
      p_m12_H: 0.51,
    },
    "0075": {
      H: 1.65,
      L: 1.65,
      O: 1.65,
      C: 1.65,
      V: 0,
      p_w1_L: 1.65,
      p_w1_H: 1.65,
      p_m1_L: 1.65,
      p_m1_H: 1.84,
      p_m3_L: 1.65,
      p_m3_H: 1.84,
      p_m6_L: 1.08,
      p_m6_H: 1.84,
      p_m12_L: 1.08,
      p_m12_H: 2.19,
    },
    "0076": {
      H: 0.038,
      L: 0.035,
      O: 0.037,
      C: 0.037,
      V: 12120700,
      p_w1_L: 0.033,
      p_w1_H: 0.039,
      p_m1_L: 0.024,
      p_m1_H: 0.044,
      p_m3_L: 0.023,
      p_m3_H: 0.044,
      p_m6_L: 0.021,
      p_m6_H: 0.044,
      p_m12_L: 0.01,
      p_m12_H: 0.044,
    },
    "0077": {
      H: 0.74,
      L: 0.74,
      O: 0.74,
      C: 0.74,
      V: 34000,
      p_w1_L: 0.73,
      p_w1_H: 0.74,
      p_m1_L: 0.7,
      p_m1_H: 0.75,
      p_m3_L: 0.62,
      p_m3_H: 0.75,
      p_m6_L: 0.56,
      p_m6_H: 0.79,
      p_m12_L: 0.53,
      p_m12_H: 0.79,
    },
    "0078": {
      H: 3.09,
      L: 3.02,
      O: 3.03,
      C: 3.06,
      V: 30006,
      p_w1_L: 2.96,
      p_w1_H: 3.15,
      p_m1_L: 2.96,
      p_m1_H: 3.34,
      p_m3_L: 2.95,
      p_m3_H: 3.6,
      p_m6_L: 2.95,
      p_m6_H: 4.2,
      p_m12_L: 2.65,
      p_m12_H: 4.27,
    },
    "0079": {
      H: 0.147,
      L: 0.147,
      O: 0.147,
      C: 0.147,
      V: 4000,
      p_w1_L: 0.147,
      p_w1_H: 0.15,
      p_m1_L: 0.147,
      p_m1_H: 0.218,
      p_m3_L: 0.142,
      p_m3_H: 0.218,
      p_m6_L: 0.142,
      p_m6_H: 0.3,
      p_m12_L: 0.13,
      p_m12_H: 0.3,
    },
    "0080": {
      H: 0.3,
      L: 0.25,
      O: 0.29,
      C: 0.28,
      V: 2910000,
      p_w1_L: 0.224,
      p_w1_H: 0.33,
      p_m1_L: 0.18,
      p_m1_H: 0.33,
      p_m3_L: 0.161,
      p_m3_H: 0.33,
      p_m6_L: 0.161,
      p_m6_H: 0.33,
      p_m12_L: 0.148,
      p_m12_H: 0.33,
    },
    "0081": {
      H: 3.92,
      L: 3.81,
      O: 3.86,
      C: 3.87,
      V: 6781860,
      p_w1_L: 3.42,
      p_w1_H: 3.93,
      p_m1_L: 3.42,
      p_m1_H: 3.94,
      p_m3_L: 3.4,
      p_m3_H: 5.16,
      p_m6_L: 3.4,
      p_m6_H: 5.34,
      p_m12_L: 3.4,
      p_m12_H: 5.39,
    },
    "0082": {
      H: 0.34,
      L: 0.33,
      O: 0.34,
      C: 0.335,
      V: 3141300,
      p_w1_L: 0.31,
      p_w1_H: 0.35,
      p_m1_L: 0.31,
      p_m1_H: 0.415,
      p_m3_L: 0.31,
      p_m3_H: 0.53,
      p_m6_L: 0.31,
      p_m6_H: 0.86,
      p_m12_L: 0.23,
      p_m12_H: 1,
    },
    "0083": {
      H: 9.7,
      L: 9.44,
      O: 9.7,
      C: 9.59,
      V: 3720890,
      p_w1_L: 9.27,
      p_w1_H: 9.7,
      p_m1_L: 9.22,
      p_m1_H: 10.18,
      p_m3_L: 9.22,
      p_m3_H: 11.36,
      p_m6_L: 9.22,
      p_m6_H: 12.98,
      p_m12_L: 9.22,
      p_m12_H: 12.98,
    },
    "0084": {
      H: 0.074,
      L: 0.074,
      O: 0.074,
      C: 0.074,
      V: 2000,
      p_w1_L: 0.066,
      p_w1_H: 0.077,
      p_m1_L: 0.066,
      p_m1_H: 0.084,
      p_m3_L: 0.066,
      p_m3_H: 0.085,
      p_m6_L: 0.066,
      p_m6_H: 0.098,
      p_m12_L: 0.066,
      p_m12_H: 0.116,
    },
    "0085": {
      H: 0.7,
      L: 0.67,
      O: 0.7,
      C: 0.69,
      V: 984000,
      p_w1_L: 0.67,
      p_w1_H: 0.74,
      p_m1_L: 0.67,
      p_m1_H: 0.8,
      p_m3_L: 0.67,
      p_m3_H: 0.92,
      p_m6_L: 0.67,
      p_m6_H: 1.03,
      p_m12_L: 0.66,
      p_m12_H: 1.18,
    },
    "0086": {
      H: 4.24,
      L: 4.08,
      O: 4.24,
      C: 4.12,
      V: 1160000,
      p_w1_L: 4.06,
      p_w1_H: 4.89,
      p_m1_L: 3.86,
      p_m1_H: 5.04,
      p_m3_L: 3.86,
      p_m3_H: 5.04,
      p_m6_L: 3.82,
      p_m6_H: 5.04,
      p_m12_L: 3.17,
      p_m12_H: 5.04,
    },
    "0087": {
      H: 7.55,
      L: 7.44,
      O: 7.55,
      C: 7.49,
      V: 622500,
      p_w1_L: 7.3,
      p_w1_H: 7.55,
      p_m1_L: 7.25,
      p_m1_H: 8.28,
      p_m3_L: 7.25,
      p_m3_H: 8.48,
      p_m6_L: 7.25,
      p_m6_H: 9.43,
      p_m12_L: 7.1,
      p_m12_H: 10.1,
    },
    "0088": {
      H: 4.89,
      L: 4.76,
      O: 4.88,
      C: 4.77,
      V: 22000,
      p_w1_L: 4.74,
      p_w1_H: 4.89,
      p_m1_L: 4.74,
      p_m1_H: 4.89,
      p_m3_L: 4.5,
      p_m3_H: 5.16,
      p_m6_L: 4.5,
      p_m6_H: 5.68,
      p_m12_L: 4.5,
      p_m12_H: 5.68,
    },
    "0089": {
      H: 4.05,
      L: 3.98,
      O: 4.02,
      C: 4.02,
      V: 22000,
      p_w1_L: 3.98,
      p_w1_H: 4.07,
      p_m1_L: 3.98,
      p_m1_H: 4.15,
      p_m3_L: 3.98,
      p_m3_H: 4.23,
      p_m6_L: 3.98,
      p_m6_H: 4.5,
      p_m12_L: 3.73,
      p_m12_H: 4.5,
    },
    "0090": {
      H: 0.78,
      L: 0.78,
      O: 0.78,
      C: 0.78,
      V: 2000,
      p_w1_L: 0.74,
      p_w1_H: 0.88,
      p_m1_L: 0.71,
      p_m1_H: 0.88,
      p_m3_L: 0.7,
      p_m3_H: 1.01,
      p_m6_L: 0.7,
      p_m6_H: 1.03,
      p_m12_L: 0.495,
      p_m12_H: 1.11,
    },
    "0091": {
      H: 0.238,
      L: 0.201,
      O: 0.219,
      C: 0.206,
      V: 5330310,
      p_w1_L: 0.201,
      p_w1_H: 0.238,
      p_m1_L: 0.201,
      p_m1_H: 0.247,
      p_m3_L: 0.201,
      p_m3_H: 0.255,
      p_m6_L: 0.177,
      p_m6_H: 0.255,
      p_m12_L: 0.177,
      p_m12_H: 0.305,
    },
    "0092": {
      H: 0.127,
      L: 0.118,
      O: 0.125,
      C: 0.122,
      V: 1637250,
      p_w1_L: 0.103,
      p_w1_H: 0.128,
      p_m1_L: 0.1,
      p_m1_H: 0.128,
      p_m3_L: 0.091,
      p_m3_H: 0.128,
      p_m6_L: 0.091,
      p_m6_H: 0.128,
      p_m12_L: 0.086,
      p_m12_H: 0.149,
    },
    "0093": {
      H: 0.241,
      L: 0.241,
      O: 0.241,
      C: 0.241,
      V: 0,
      p_w1_L: 0.24,
      p_w1_H: 0.29,
      p_m1_L: 0.24,
      p_m1_H: 0.29,
      p_m3_L: 0.24,
      p_m3_H: 0.29,
      p_m6_L: 0.24,
      p_m6_H: 0.32,
      p_m12_L: 0.24,
      p_m12_H: 0.365,
    },
    "0094": {
      H: 0.12,
      L: 0.119,
      O: 0.12,
      C: 0.12,
      V: 180000,
      p_w1_L: 0.119,
      p_w1_H: 0.129,
      p_m1_L: 0.118,
      p_m1_H: 0.136,
      p_m3_L: 0.118,
      p_m3_H: 0.148,
      p_m6_L: 0.118,
      p_m6_H: 0.156,
      p_m12_L: 0.076,
      p_m12_H: 0.2,
    },
    "0095": {
      H: 1.62,
      L: 1.52,
      O: 1.62,
      C: 1.6,
      V: 7426000,
      p_w1_L: 1.37,
      p_w1_H: 1.62,
      p_m1_L: 1.37,
      p_m1_H: 1.74,
      p_m3_L: 1.37,
      p_m3_H: 1.88,
      p_m6_L: 1.37,
      p_m6_H: 2.14,
      p_m12_L: 1.37,
      p_m12_H: 2.5,
    },
    "0096": {
      H: 1.19,
      L: 0.97,
      O: 1,
      C: 1.1,
      V: 14000,
      p_w1_L: 0.96,
      p_w1_H: 1.19,
      p_m1_L: 0.91,
      p_m1_H: 1.35,
      p_m3_L: 0.88,
      p_m3_H: 1.35,
      p_m6_L: 0.88,
      p_m6_H: 1.35,
      p_m12_L: 0.88,
      p_m12_H: 1.35,
    },
    "0097": {
      H: 0.37,
      L: 0.365,
      O: 0.37,
      C: 0.37,
      V: 209000,
      p_w1_L: 0.36,
      p_w1_H: 0.37,
      p_m1_L: 0.355,
      p_m1_H: 0.41,
      p_m3_L: 0.33,
      p_m3_H: 0.41,
      p_m6_L: 0.33,
      p_m6_H: 0.46,
      p_m12_L: 0.33,
      p_m12_H: 0.465,
    },
    "0098": {
      H: 7.8,
      L: 7.42,
      O: 7.8,
      C: 7.6,
      V: 11000,
      p_w1_L: 7.4,
      p_w1_H: 8.1,
      p_m1_L: 7.4,
      p_m1_H: 9.1,
      p_m3_L: 7.4,
      p_m3_H: 12.52,
      p_m6_L: 7.4,
      p_m6_H: 13.54,
      p_m12_L: 7.09,
      p_m12_H: 13.88,
    },
    "0099": {
      H: 2.09,
      L: 2.09,
      O: 2.09,
      C: 2.09,
      V: 0,
      p_w1_L: 2.05,
      p_w1_H: 2.2,
      p_m1_L: 1.95,
      p_m1_H: 2.2,
      p_m3_L: 1.95,
      p_m3_H: 2.4,
      p_m6_L: 1.95,
      p_m6_H: 2.81,
      p_m12_L: 1.87,
      p_m12_H: 2.81,
    },
    "0101": {
      H: 15.88,
      L: 15.42,
      O: 15.76,
      C: 15.7,
      V: 3132930,
      p_w1_L: 15.08,
      p_w1_H: 15.88,
      p_m1_L: 15.08,
      p_m1_H: 18,
      p_m3_L: 15.08,
      p_m3_H: 18.68,
      p_m6_L: 15.08,
      p_m6_H: 22,
      p_m12_L: 15.08,
      p_m12_H: 22.6,
    },
    "0102": {
      H: 0.162,
      L: 0.148,
      O: 0.148,
      C: 0.151,
      V: 5974000,
      p_w1_L: 0.14,
      p_w1_H: 0.206,
      p_m1_L: 0.14,
      p_m1_H: 0.54,
      p_m3_L: 0.14,
      p_m3_H: 0.58,
      p_m6_L: 0.14,
      p_m6_H: 0.78,
      p_m12_L: 0.14,
      p_m12_H: 1.13,
    },
    "0103": {
      H: 0.37,
      L: 0.325,
      O: 0.33,
      C: 0.365,
      V: 7776000,
      p_w1_L: 0.31,
      p_w1_H: 0.37,
      p_m1_L: 0.27,
      p_m1_H: 0.445,
      p_m3_L: 0.25,
      p_m3_H: 0.445,
      p_m6_L: 0.241,
      p_m6_H: 0.445,
      p_m12_L: 0.196,
      p_m12_H: 0.445,
    },
    "0104": {
      H: 0.212,
      L: 0.201,
      O: 0.21,
      C: 0.212,
      V: 62500,
      p_w1_L: 0.201,
      p_w1_H: 0.265,
      p_m1_L: 0.201,
      p_m1_H: 0.3,
      p_m3_L: 0.201,
      p_m3_H: 0.34,
      p_m6_L: 0.201,
      p_m6_H: 0.34,
      p_m12_L: 0.201,
      p_m12_H: 0.7,
    },
    "0105": {
      H: 13.22,
      L: 13.22,
      O: 13.22,
      C: 13.22,
      V: 0,
      p_w1_L: 13,
      p_w1_H: 14.92,
      p_m1_L: 13,
      p_m1_H: 14.92,
      p_m3_L: 12.5,
      p_m3_H: 14.92,
      p_m6_L: 12.5,
      p_m6_H: 14.92,
      p_m12_L: 12.5,
      p_m12_H: 14.92,
    },
    "0106": {
      H: 0.38,
      L: 0.365,
      O: 0.37,
      C: 0.37,
      V: 528000,
      p_w1_L: 0.365,
      p_w1_H: 0.415,
      p_m1_L: 0.365,
      p_m1_H: 0.485,
      p_m3_L: 0.365,
      p_m3_H: 0.59,
      p_m6_L: 0.365,
      p_m6_H: 0.67,
      p_m12_L: 0.365,
      p_m12_H: 0.67,
    },
    "0107": {
      H: 1.8,
      L: 1.78,
      O: 1.79,
      C: 1.78,
      V: 642000,
      p_w1_L: 1.74,
      p_w1_H: 1.82,
      p_m1_L: 1.73,
      p_m1_H: 1.84,
      p_m3_L: 1.68,
      p_m3_H: 1.95,
      p_m6_L: 1.68,
      p_m6_H: 1.95,
      p_m12_L: 1.68,
      p_m12_H: 1.95,
    },
    "0108": {
      H: 1.09,
      L: 1.03,
      O: 1.06,
      C: 1.06,
      V: 1758000,
      p_w1_L: 1.02,
      p_w1_H: 1.14,
      p_m1_L: 0.97,
      p_m1_H: 1.23,
      p_m3_L: 0.95,
      p_m3_H: 1.45,
      p_m6_L: 0.88,
      p_m6_H: 1.45,
      p_m12_L: 0.78,
      p_m12_H: 1.45,
    },
    "0109": {
      H: 0.085,
      L: 0.085,
      O: 0.085,
      C: 0.085,
      V: 0,
      p_w1_L: 0.085,
      p_w1_H: 0.085,
      p_m1_L: 0.085,
      p_m1_H: 0.085,
      p_m3_L: 0.085,
      p_m3_H: 0.085,
      p_m6_L: 0.085,
      p_m6_H: 0.085,
      p_m12_L: 0.085,
      p_m12_H: 0.085,
    },
    "0110": {
      H: 0.415,
      L: 0.415,
      O: 0.415,
      C: 0.415,
      V: 48000,
      p_w1_L: 0.405,
      p_w1_H: 0.495,
      p_m1_L: 0.405,
      p_m1_H: 0.495,
      p_m3_L: 0.38,
      p_m3_H: 0.53,
      p_m6_L: 0.365,
      p_m6_H: 0.53,
      p_m12_L: 0.365,
      p_m12_H: 0.7,
    },
    "0111": {
      H: 0.45,
      L: 0.43,
      O: 0.43,
      C: 0.45,
      V: 94000,
      p_w1_L: 0.425,
      p_w1_H: 0.455,
      p_m1_L: 0.425,
      p_m1_H: 0.5,
      p_m3_L: 0.425,
      p_m3_H: 0.59,
      p_m6_L: 0.41,
      p_m6_H: 0.59,
      p_m12_L: 0.365,
      p_m12_H: 0.59,
    },
    "0112": {
      H: 0.89,
      L: 0.89,
      O: 0.89,
      C: 0.89,
      V: 0,
      p_w1_L: 0.89,
      p_w1_H: 0.89,
      p_m1_L: 0.89,
      p_m1_H: 0.89,
      p_m3_L: 0.89,
      p_m3_H: 0.89,
      p_m6_L: 0.89,
      p_m6_H: 0.89,
      p_m12_L: 0.74,
      p_m12_H: 1.4,
    },
    "0113": {
      H: 4.4,
      L: 4.3,
      O: 4.4,
      C: 4.4,
      V: 17710,
      p_w1_L: 4.19,
      p_w1_H: 4.4,
      p_m1_L: 4.03,
      p_m1_H: 4.43,
      p_m3_L: 3.9,
      p_m3_H: 4.43,
      p_m6_L: 3.9,
      p_m6_H: 4.76,
      p_m12_L: 3.57,
      p_m12_H: 4.76,
    },
    "0114": {
      H: 0.54,
      L: 0.52,
      O: 0.52,
      C: 0.54,
      V: 160000,
      p_w1_L: 0.5,
      p_w1_H: 0.55,
      p_m1_L: 0.5,
      p_m1_H: 0.68,
      p_m3_L: 0.5,
      p_m3_H: 0.68,
      p_m6_L: 0.5,
      p_m6_H: 0.74,
      p_m12_L: 0.5,
      p_m12_H: 0.78,
    },
    "0115": {
      H: 0.7,
      L: 0.7,
      O: 0.7,
      C: 0.7,
      V: 3750,
      p_w1_L: 0.7,
      p_w1_H: 0.7,
      p_m1_L: 0.65,
      p_m1_H: 0.7,
      p_m3_L: 0.65,
      p_m3_H: 0.89,
      p_m6_L: 0.6,
      p_m6_H: 0.89,
      p_m12_L: 0.6,
      p_m12_H: 1,
    },
    "0116": {
      H: 11.02,
      L: 10.54,
      O: 10.58,
      C: 11,
      V: 387000,
      p_w1_L: 10.4,
      p_w1_H: 11.02,
      p_m1_L: 10.4,
      p_m1_H: 12.18,
      p_m3_L: 10.4,
      p_m3_H: 12.86,
      p_m6_L: 10.4,
      p_m6_H: 14.98,
      p_m12_L: 8.34,
      p_m12_H: 14.98,
    },
    "0117": {
      H: 0.55,
      L: 0.55,
      O: 0.55,
      C: 0.55,
      V: 122000,
      p_w1_L: 0.51,
      p_w1_H: 0.59,
      p_m1_L: 0.51,
      p_m1_H: 0.76,
      p_m3_L: 0.51,
      p_m3_H: 1.1,
      p_m6_L: 0.48,
      p_m6_H: 1.1,
      p_m12_L: 0.4,
      p_m12_H: 1.1,
    },
    "0118": {
      H: 0.43,
      L: 0.43,
      O: 0.43,
      C: 0.43,
      V: 0,
      p_w1_L: 0.41,
      p_w1_H: 0.43,
      p_m1_L: 0.41,
      p_m1_H: 0.455,
      p_m3_L: 0.38,
      p_m3_H: 0.52,
      p_m6_L: 0.335,
      p_m6_H: 0.53,
      p_m12_L: 0.335,
      p_m12_H: 0.59,
    },
    "0119": {
      H: 1.99,
      L: 1.95,
      O: 1.97,
      C: 1.97,
      V: 1696000,
      p_w1_L: 1.95,
      p_w1_H: 2.01,
      p_m1_L: 1.84,
      p_m1_H: 2.06,
      p_m3_L: 1.8,
      p_m3_H: 2.17,
      p_m6_L: 1.8,
      p_m6_H: 2.26,
      p_m12_L: 1.8,
      p_m12_H: 2.62,
    },
    "0120": {
      H: 1.48,
      L: 1.4,
      O: 1.43,
      C: 1.42,
      V: 5550000,
      p_w1_L: 1.16,
      p_w1_H: 1.48,
      p_m1_L: 1.05,
      p_m1_H: 1.48,
      p_m3_L: 1,
      p_m3_H: 1.48,
      p_m6_L: 1,
      p_m6_H: 1.54,
      p_m12_L: 1,
      p_m12_H: 1.58,
    },
    "0122": {
      H: 0.395,
      L: 0.39,
      O: 0.39,
      C: 0.39,
      V: 444000,
      p_w1_L: 0.39,
      p_w1_H: 0.415,
      p_m1_L: 0.39,
      p_m1_H: 0.425,
      p_m3_L: 0.39,
      p_m3_H: 0.48,
      p_m6_L: 0.345,
      p_m6_H: 0.61,
      p_m12_L: 0.315,
      p_m12_H: 0.61,
    },
    "0123": {
      H: 7.18,
      L: 6.96,
      O: 7.08,
      C: 7.02,
      V: 4614230,
      p_w1_L: 6.96,
      p_w1_H: 7.4,
      p_m1_L: 6.53,
      p_m1_H: 7.45,
      p_m3_L: 6.4,
      p_m3_H: 7.52,
      p_m6_L: 6.4,
      p_m6_H: 9.65,
      p_m12_L: 6.4,
      p_m12_H: 9.9,
    },
    "0124": {
      H: 0.75,
      L: 0.72,
      O: 0.72,
      C: 0.72,
      V: 179777,
      p_w1_L: 0.7,
      p_w1_H: 0.79,
      p_m1_L: 0.7,
      p_m1_H: 0.8,
      p_m3_L: 0.69,
      p_m3_H: 0.85,
      p_m6_L: 0.69,
      p_m6_H: 1.08,
      p_m12_L: 0.69,
      p_m12_H: 1.3,
    },
    "0125": {
      H: 1.29,
      L: 1.29,
      O: 1.29,
      C: 1.29,
      V: 0,
      p_w1_L: 1.29,
      p_w1_H: 1.29,
      p_m1_L: 1.23,
      p_m1_H: 1.35,
      p_m3_L: 1.2,
      p_m3_H: 2.4,
      p_m6_L: 1.2,
      p_m6_H: 2.4,
      p_m12_L: 1.2,
      p_m12_H: 2.4,
    },
    "0126": {
      H: 0.61,
      L: 0.59,
      O: 0.6,
      C: 0.61,
      V: 762000,
      p_w1_L: 0.58,
      p_w1_H: 0.68,
      p_m1_L: 0.58,
      p_m1_H: 0.68,
      p_m3_L: 0.58,
      p_m3_H: 0.93,
      p_m6_L: 0.54,
      p_m6_H: 0.93,
      p_m12_L: 0.44,
      p_m12_H: 0.93,
    },
    "0127": {
      H: 3.85,
      L: 3.79,
      O: 3.8,
      C: 3.85,
      V: 1092800,
      p_w1_L: 3.65,
      p_w1_H: 3.85,
      p_m1_L: 3.65,
      p_m1_H: 3.86,
      p_m3_L: 1.98,
      p_m3_H: 3.86,
      p_m6_L: 1.98,
      p_m6_H: 3.86,
      p_m12_L: 1.98,
      p_m12_H: 4.55,
    },
    "0128": {
      H: 0.57,
      L: 0.54,
      O: 0.57,
      C: 0.57,
      V: 3624000,
      p_w1_L: 0.54,
      p_w1_H: 0.58,
      p_m1_L: 0.54,
      p_m1_H: 0.6,
      p_m3_L: 0.54,
      p_m3_H: 0.63,
      p_m6_L: 0.54,
      p_m6_H: 0.69,
      p_m12_L: 0.54,
      p_m12_H: 0.7,
    },
    "0129": {
      H: 0.87,
      L: 0.87,
      O: 0.87,
      C: 0.87,
      V: 0,
      p_w1_L: 0.82,
      p_w1_H: 0.95,
      p_m1_L: 0.82,
      p_m1_H: 0.95,
      p_m3_L: 0.81,
      p_m3_H: 0.99,
      p_m6_L: 0.81,
      p_m6_H: 1.34,
      p_m12_L: 0.81,
      p_m12_H: 1.34,
    },
    "0130": {
      H: 0.29,
      L: 0.29,
      O: 0.29,
      C: 0.29,
      V: 0,
      p_w1_L: 0.275,
      p_w1_H: 0.3,
      p_m1_L: 0.275,
      p_m1_H: 0.315,
      p_m3_L: 0.275,
      p_m3_H: 0.345,
      p_m6_L: 0.275,
      p_m6_H: 0.395,
      p_m12_L: 0.25,
      p_m12_H: 0.395,
    },
    "0131": {
      H: 2.5,
      L: 2.5,
      O: 2.5,
      C: 2.5,
      V: 0,
      p_w1_L: 2.5,
      p_w1_H: 2.55,
      p_m1_L: 2.49,
      p_m1_H: 2.6,
      p_m3_L: 2.49,
      p_m3_H: 2.8,
      p_m6_L: 2.4,
      p_m6_H: 2.93,
      p_m12_L: 2.4,
      p_m12_H: 3.05,
    },
    "0132": {
      H: 0.48,
      L: 0.48,
      O: 0.48,
      C: 0.48,
      V: 0,
      p_w1_L: 0.48,
      p_w1_H: 0.48,
      p_m1_L: 0.4,
      p_m1_H: 0.52,
      p_m3_L: 0.38,
      p_m3_H: 0.55,
      p_m6_L: 0.38,
      p_m6_H: 0.56,
      p_m12_L: 0.38,
      p_m12_H: 0.66,
    },
    "0133": {
      H: 9.42,
      L: 9.42,
      O: 9.42,
      C: 9.42,
      V: 4000,
      p_w1_L: 9.06,
      p_w1_H: 9.54,
      p_m1_L: 9.06,
      p_m1_H: 9.54,
      p_m3_L: 9.04,
      p_m3_H: 9.99,
      p_m6_L: 9.04,
      p_m6_H: 11,
      p_m12_L: 9.04,
      p_m12_H: 11.38,
    },
    "0135": {
      H: 7.54,
      L: 7.4,
      O: 7.46,
      C: 7.49,
      V: 7473920,
      p_w1_L: 7.15,
      p_w1_H: 7.54,
      p_m1_L: 6.68,
      p_m1_H: 7.54,
      p_m3_L: 6.53,
      p_m3_H: 9,
      p_m6_L: 6.48,
      p_m6_H: 9,
      p_m12_L: 5.68,
      p_m12_H: 9.3,
    },
    "0136": {
      H: 3.2,
      L: 2.97,
      O: 3.09,
      C: 3.06,
      V: 68242700,
      p_w1_L: 2.8,
      p_w1_H: 3.39,
      p_m1_L: 1.47,
      p_m1_H: 4.19,
      p_m3_L: 1.47,
      p_m3_H: 4.19,
      p_m6_L: 1.47,
      p_m6_H: 7.25,
      p_m12_L: 1.47,
      p_m12_H: 17.8,
    },
    "0137": {
      H: 1.3,
      L: 1.3,
      O: 1.3,
      C: 1.3,
      V: 3000,
      p_w1_L: 1.29,
      p_w1_H: 1.35,
      p_m1_L: 1.22,
      p_m1_H: 1.46,
      p_m3_L: 1.22,
      p_m3_H: 1.53,
      p_m6_L: 1.11,
      p_m6_H: 1.55,
      p_m12_L: 0.53,
      p_m12_H: 1.55,
    },
    "0138": {
      H: 0.182,
      L: 0.182,
      O: 0.182,
      C: 0.182,
      V: 0,
      p_w1_L: 0.182,
      p_w1_H: 0.191,
      p_m1_L: 0.179,
      p_m1_H: 0.195,
      p_m3_L: 0.175,
      p_m3_H: 0.195,
      p_m6_L: 0.173,
      p_m6_H: 0.2,
      p_m12_L: 0.173,
      p_m12_H: 0.32,
    },
    "0139": {
      H: 0.01,
      L: 0.01,
      O: 0.01,
      C: 0.01,
      V: 3304000,
      p_w1_L: 0.01,
      p_w1_H: 0.01,
      p_m1_L: 0.01,
      p_m1_H: 0.012,
      p_m3_L: 0.01,
      p_m3_H: 0.015,
      p_m6_L: 0.01,
      p_m6_H: 0.018,
      p_m12_L: 0.01,
      p_m12_H: 0.034,
    },
    "0141": {
      H: 0.18,
      L: 0.18,
      O: 0.18,
      C: 0.18,
      V: 0,
      p_w1_L: 0.18,
      p_w1_H: 0.18,
      p_m1_L: 0.18,
      p_m1_H: 0.18,
      p_m3_L: 0.18,
      p_m3_H: 0.18,
      p_m6_L: 0.18,
      p_m6_H: 0.247,
      p_m12_L: 0.18,
      p_m12_H: 0.69,
    },
    "0142": {
      H: 2.86,
      L: 2.81,
      O: 2.84,
      C: 2.81,
      V: 3376590,
      p_w1_L: 2.72,
      p_w1_H: 2.9,
      p_m1_L: 2.72,
      p_m1_H: 3.11,
      p_m3_L: 2.6,
      p_m3_H: 3.23,
      p_m6_L: 2.41,
      p_m6_H: 3.3,
      p_m12_L: 2.17,
      p_m12_H: 3.3,
    },
    "0143": {
      H: 0.05,
      L: 0.05,
      O: 0.05,
      C: 0.05,
      V: 0,
      p_w1_L: 0.05,
      p_w1_H: 0.05,
      p_m1_L: 0.05,
      p_m1_H: 0.05,
      p_m3_L: 0.05,
      p_m3_H: 0.05,
      p_m6_L: 0.05,
      p_m6_H: 0.05,
      p_m12_L: 0.036,
      p_m12_H: 0.065,
    },
    "0144": {
      H: 14.6,
      L: 14.1,
      O: 14.44,
      C: 14.46,
      V: 7916120,
      p_w1_L: 11.52,
      p_w1_H: 14.92,
      p_m1_L: 11.52,
      p_m1_H: 14.92,
      p_m3_L: 11.52,
      p_m3_H: 14.92,
      p_m6_L: 10.38,
      p_m6_H: 14.92,
      p_m12_L: 9,
      p_m12_H: 14.92,
    },
    "0145": {
      H: 0.138,
      L: 0.138,
      O: 0.138,
      C: 0.138,
      V: 48000,
      p_w1_L: 0.12,
      p_w1_H: 0.142,
      p_m1_L: 0.12,
      p_m1_H: 0.165,
      p_m3_L: 0.12,
      p_m3_H: 0.18,
      p_m6_L: 0.12,
      p_m6_H: 0.18,
      p_m12_L: 0.12,
      p_m12_H: 0.223,
    },
    "0146": {
      H: 1.09,
      L: 1.09,
      O: 1.09,
      C: 1.09,
      V: 1000,
      p_w1_L: 0.96,
      p_w1_H: 1.09,
      p_m1_L: 0.96,
      p_m1_H: 1.15,
      p_m3_L: 0.9,
      p_m3_H: 1.2,
      p_m6_L: 0.9,
      p_m6_H: 1.2,
      p_m12_L: 0.9,
      p_m12_H: 1.55,
    },
    "0147": {
      H: 0.071,
      L: 0.071,
      O: 0.071,
      C: 0.071,
      V: 40000,
      p_w1_L: 0.065,
      p_w1_H: 0.073,
      p_m1_L: 0.053,
      p_m1_H: 0.084,
      p_m3_L: 0.053,
      p_m3_H: 0.13,
      p_m6_L: 0.053,
      p_m6_H: 0.13,
      p_m12_L: 0.053,
      p_m12_H: 0.15,
    },
    "0148": {
      H: 38.6,
      L: 37.3,
      O: 37.3,
      C: 38.5,
      V: 1044500,
      p_w1_L: 37,
      p_w1_H: 39.85,
      p_m1_L: 33.05,
      p_m1_H: 41.8,
      p_m3_L: 32.2,
      p_m3_H: 41.8,
      p_m6_L: 32.2,
      p_m6_H: 45.6,
      p_m12_L: 28.8,
      p_m12_H: 48,
    },
    "0149": {
      H: 0.118,
      L: 0.112,
      O: 0.118,
      C: 0.115,
      V: 130000,
      p_w1_L: 0.107,
      p_w1_H: 0.118,
      p_m1_L: 0.1,
      p_m1_H: 0.123,
      p_m3_L: 0.095,
      p_m3_H: 0.123,
      p_m6_L: 0.095,
      p_m6_H: 0.14,
      p_m12_L: 0.08,
      p_m12_H: 0.159,
    },
    "0150": {
      H: 1.18,
      L: 1.17,
      O: 1.17,
      C: 1.18,
      V: 387500,
      p_w1_L: 1.16,
      p_w1_H: 1.23,
      p_m1_L: 1.1,
      p_m1_H: 1.28,
      p_m3_L: 0.96,
      p_m3_H: 1.28,
      p_m6_L: 0.93,
      p_m6_H: 1.34,
      p_m12_L: 0.74,
      p_m12_H: 1.94,
    },
    "0151": {
      H: 6.9,
      L: 6.69,
      O: 6.9,
      C: 6.7,
      V: 14912900,
      p_w1_L: 6.45,
      p_w1_H: 6.9,
      p_m1_L: 6,
      p_m1_H: 6.9,
      p_m3_L: 5.23,
      p_m3_H: 6.9,
      p_m6_L: 5.06,
      p_m6_H: 6.9,
      p_m12_L: 5.06,
      p_m12_H: 6.9,
    },
    "0152": {
      H: 8.47,
      L: 8.32,
      O: 8.46,
      C: 8.32,
      V: 2171660,
      p_w1_L: 8.27,
      p_w1_H: 8.57,
      p_m1_L: 8.27,
      p_m1_H: 9.28,
      p_m3_L: 8.27,
      p_m3_H: 11.36,
      p_m6_L: 8.27,
      p_m6_H: 11.66,
      p_m12_L: 8.27,
      p_m12_H: 13.8,
    },
    "0153": {
      H: 0.104,
      L: 0.104,
      O: 0.104,
      C: 0.104,
      V: 0,
      p_w1_L: 0.104,
      p_w1_H: 0.104,
      p_m1_L: 0.104,
      p_m1_H: 0.104,
      p_m3_L: 0.104,
      p_m3_H: 0.104,
      p_m6_L: 0.104,
      p_m6_H: 0.104,
      p_m12_L: 0.104,
      p_m12_H: 0.104,
    },
    "0154": {
      H: 0.58,
      L: 0.57,
      O: 0.57,
      C: 0.58,
      V: 298000,
      p_w1_L: 0.55,
      p_w1_H: 0.6,
      p_m1_L: 0.54,
      p_m1_H: 0.66,
      p_m3_L: 0.54,
      p_m3_H: 0.79,
      p_m6_L: 0.49,
      p_m6_H: 0.79,
      p_m12_L: 0.49,
      p_m12_H: 0.79,
    },
    "0155": {
      H: 0.18,
      L: 0.18,
      O: 0.18,
      C: 0.18,
      V: 0,
      p_w1_L: 0.18,
      p_w1_H: 0.18,
      p_m1_L: 0.18,
      p_m1_H: 0.18,
      p_m3_L: 0.18,
      p_m3_H: 0.18,
      p_m6_L: 0.18,
      p_m6_H: 0.18,
      p_m12_L: 0.18,
      p_m12_H: 0.18,
    },
    "0156": {
      H: 0.124,
      L: 0.119,
      O: 0.119,
      C: 0.124,
      V: 184000,
      p_w1_L: 0.118,
      p_w1_H: 0.126,
      p_m1_L: 0.118,
      p_m1_H: 0.129,
      p_m3_L: 0.118,
      p_m3_H: 0.145,
      p_m6_L: 0.113,
      p_m6_H: 0.145,
      p_m12_L: 0.111,
      p_m12_H: 0.148,
    },
    "0157": {
      H: 0.51,
      L: 0.5,
      O: 0.51,
      C: 0.51,
      V: 90000,
      p_w1_L: 0.5,
      p_w1_H: 0.59,
      p_m1_L: 0.5,
      p_m1_H: 0.6,
      p_m3_L: 0.5,
      p_m3_H: 0.66,
      p_m6_L: 0.5,
      p_m6_H: 0.72,
      p_m12_L: 0.5,
      p_m12_H: 0.86,
    },
    "0158": {
      H: 148,
      L: 148,
      O: 148,
      C: 148,
      V: 0,
      p_w1_L: 146,
      p_w1_H: 148,
      p_m1_L: 146,
      p_m1_H: 148.4,
      p_m3_L: 146,
      p_m3_H: 158,
      p_m6_L: 146,
      p_m6_H: 162.8,
      p_m12_L: 146,
      p_m12_H: 164,
    },
    "0159": {
      H: 0.28,
      L: 0.275,
      O: 0.28,
      C: 0.275,
      V: 368000,
      p_w1_L: 0.265,
      p_w1_H: 0.31,
      p_m1_L: 0.24,
      p_m1_H: 0.32,
      p_m3_L: 0.24,
      p_m3_H: 0.34,
      p_m6_L: 0.19,
      p_m6_H: 0.435,
      p_m12_L: 0.081,
      p_m12_H: 0.435,
    },
    "0160": {
      H: 3.02,
      L: 2.88,
      O: 2.88,
      C: 3,
      V: 66000,
      p_w1_L: 2.71,
      p_w1_H: 3.02,
      p_m1_L: 2.71,
      p_m1_H: 3.02,
      p_m3_L: 2.71,
      p_m3_H: 3.33,
      p_m6_L: 2.71,
      p_m6_H: 3.88,
      p_m12_L: 2.51,
      p_m12_H: 3.88,
    },
    "0162": {
      H: 0.148,
      L: 0.148,
      O: 0.148,
      C: 0.148,
      V: 0,
      p_w1_L: 0.145,
      p_w1_H: 0.168,
      p_m1_L: 0.136,
      p_m1_H: 0.169,
      p_m3_L: 0.135,
      p_m3_H: 0.179,
      p_m6_L: 0.135,
      p_m6_H: 0.193,
      p_m12_L: 0.135,
      p_m12_H: 0.2,
    },
    "0163": {
      H: 1,
      L: 0.97,
      O: 0.98,
      C: 1,
      V: 50865,
      p_w1_L: 0.97,
      p_w1_H: 1,
      p_m1_L: 0.97,
      p_m1_H: 1.08,
      p_m3_L: 0.97,
      p_m3_H: 1.13,
      p_m6_L: 0.97,
      p_m6_H: 1.22,
      p_m12_L: 0.97,
      p_m12_H: 1.24,
    },
    "0164": {
      H: 0.325,
      L: 0.305,
      O: 0.31,
      C: 0.325,
      V: 610000,
      p_w1_L: 0.305,
      p_w1_H: 0.37,
      p_m1_L: 0.305,
      p_m1_H: 0.395,
      p_m3_L: 0.305,
      p_m3_H: 0.56,
      p_m6_L: 0.27,
      p_m6_H: 0.84,
      p_m12_L: 0.018,
      p_m12_H: 0.84,
    },
    "0165": {
      H: 9.56,
      L: 9.38,
      O: 9.49,
      C: 9.38,
      V: 3460000,
      p_w1_L: 8.33,
      p_w1_H: 9.74,
      p_m1_L: 8.21,
      p_m1_H: 9.74,
      p_m3_L: 8.21,
      p_m3_H: 10.62,
      p_m6_L: 8.21,
      p_m6_H: 10.62,
      p_m12_L: 8.21,
      p_m12_H: 11.4,
    },
    "0166": {
      H: 0.069,
      L: 0.064,
      O: 0.066,
      C: 0.065,
      V: 384250,
      p_w1_L: 0.062,
      p_w1_H: 0.077,
      p_m1_L: 0.062,
      p_m1_H: 0.086,
      p_m3_L: 0.054,
      p_m3_H: 0.106,
      p_m6_L: 0.05,
      p_m6_H: 0.106,
      p_m12_L: 0.05,
      p_m12_H: 0.106,
    },
    "0167": {
      H: 0.033,
      L: 0.032,
      O: 0.033,
      C: 0.032,
      V: 2508000,
      p_w1_L: 0.027,
      p_w1_H: 0.042,
      p_m1_L: 0.027,
      p_m1_H: 0.042,
      p_m3_L: 0.027,
      p_m3_H: 0.044,
      p_m6_L: 0.027,
      p_m6_H: 0.062,
      p_m12_L: 0.027,
      p_m12_H: 0.12,
    },
    "0168": {
      H: 67.8,
      L: 65,
      O: 65.3,
      C: 67.55,
      V: 5655640,
      p_w1_L: 60.45,
      p_w1_H: 67.8,
      p_m1_L: 60.45,
      p_m1_H: 68.8,
      p_m3_L: 57.3,
      p_m3_H: 73.6,
      p_m6_L: 57.3,
      p_m6_H: 93.55,
      p_m12_L: 57.3,
      p_m12_H: 93.55,
    },
    "0169": {
      H: 0.325,
      L: 0.325,
      O: 0.325,
      C: 0.325,
      V: 0,
      p_w1_L: 0.3,
      p_w1_H: 0.345,
      p_m1_L: 0.295,
      p_m1_H: 0.345,
      p_m3_L: 0.27,
      p_m3_H: 0.42,
      p_m6_L: 0.27,
      p_m6_H: 0.53,
      p_m12_L: 0.255,
      p_m12_H: 0.53,
    },
    "0171": {
      H: 0.53,
      L: 0.5,
      O: 0.53,
      C: 0.51,
      V: 18000,
      p_w1_L: 0.48,
      p_w1_H: 0.53,
      p_m1_L: 0.48,
      p_m1_H: 0.63,
      p_m3_L: 0.46,
      p_m3_H: 0.63,
      p_m6_L: 0.46,
      p_m6_H: 0.68,
      p_m12_L: 0.46,
      p_m12_H: 0.95,
    },
    "0173": {
      H: 3.05,
      L: 3.02,
      O: 3.03,
      C: 3.02,
      V: 582291,
      p_w1_L: 2.99,
      p_w1_H: 3.09,
      p_m1_L: 2.99,
      p_m1_H: 3.22,
      p_m3_L: 2.99,
      p_m3_H: 3.48,
      p_m6_L: 2.99,
      p_m6_H: 3.97,
      p_m12_L: 2.99,
      p_m12_H: 4.17,
    },
    "0174": {
      H: 0.66,
      L: 0.66,
      O: 0.66,
      C: 0.66,
      V: 0,
      p_w1_L: 0.62,
      p_w1_H: 0.67,
      p_m1_L: 0.62,
      p_m1_H: 0.72,
      p_m3_L: 0.62,
      p_m3_H: 0.77,
      p_m6_L: 0.62,
      p_m6_H: 0.85,
      p_m12_L: 0.62,
      p_m12_H: 1.19,
    },
    "0175": {
      H: 23.8,
      L: 22.85,
      O: 23.6,
      C: 23.5,
      V: 35329400,
      p_w1_L: 22.05,
      p_w1_H: 23.8,
      p_m1_L: 22.05,
      p_m1_H: 27.3,
      p_m3_L: 21.05,
      p_m3_H: 29.25,
      p_m6_L: 20.5,
      p_m6_H: 29.8,
      p_m12_L: 17.34,
      p_m12_H: 36.45,
    },
    "0176": {
      H: 0.18,
      L: 0.141,
      O: 0.179,
      C: 0.18,
      V: 16000,
      p_w1_L: 0.133,
      p_w1_H: 0.18,
      p_m1_L: 0.132,
      p_m1_H: 0.19,
      p_m3_L: 0.126,
      p_m3_H: 0.248,
      p_m6_L: 0.101,
      p_m6_H: 0.248,
      p_m12_L: 0.101,
      p_m12_H: 0.325,
    },
    "0177": {
      H: 7.94,
      L: 7.75,
      O: 7.75,
      C: 7.84,
      V: 4119000,
      p_w1_L: 7.61,
      p_w1_H: 7.94,
      p_m1_L: 7.19,
      p_m1_H: 7.95,
      p_m3_L: 7.14,
      p_m3_H: 8.26,
      p_m6_L: 7.14,
      p_m6_H: 9.94,
      p_m12_L: 7.14,
      p_m12_H: 9.98,
    },
    "0178": {
      H: 1.72,
      L: 1.67,
      O: 1.71,
      C: 1.68,
      V: 6013620,
      p_w1_L: 1.66,
      p_w1_H: 1.75,
      p_m1_L: 1.66,
      p_m1_H: 1.95,
      p_m3_L: 1.59,
      p_m3_H: 1.95,
      p_m6_L: 1.5,
      p_m6_H: 2.29,
      p_m12_L: 1.14,
      p_m12_H: 2.29,
    },
    "0179": {
      H: 17.22,
      L: 16.8,
      O: 17.22,
      C: 16.9,
      V: 515300,
      p_w1_L: 16.8,
      p_w1_H: 17.8,
      p_m1_L: 16.8,
      p_m1_H: 19.5,
      p_m3_L: 16.28,
      p_m3_H: 19.5,
      p_m6_L: 16.28,
      p_m6_H: 22.75,
      p_m12_L: 16.28,
      p_m12_H: 26.95,
    },
    "0180": {
      H: 0.44,
      L: 0.44,
      O: 0.44,
      C: 0.44,
      V: 0,
      p_w1_L: 0.42,
      p_w1_H: 0.44,
      p_m1_L: 0.39,
      p_m1_H: 0.445,
      p_m3_L: 0.39,
      p_m3_H: 0.475,
      p_m6_L: 0.39,
      p_m6_H: 0.475,
      p_m12_L: 0.38,
      p_m12_H: 0.51,
    },
    "0181": {
      H: 0.13,
      L: 0.13,
      O: 0.13,
      C: 0.13,
      V: 0,
      p_w1_L: 0.118,
      p_w1_H: 0.131,
      p_m1_L: 0.118,
      p_m1_H: 0.159,
      p_m3_L: 0.118,
      p_m3_H: 0.211,
      p_m6_L: 0.118,
      p_m6_H: 0.211,
      p_m12_L: 0.118,
      p_m12_H: 0.211,
    },
    "0182": {
      H: 0.79,
      L: 0.77,
      O: 0.78,
      C: 0.78,
      V: 8980000,
      p_w1_L: 0.74,
      p_w1_H: 0.82,
      p_m1_L: 0.73,
      p_m1_H: 0.82,
      p_m3_L: 0.73,
      p_m3_H: 1.01,
      p_m6_L: 0.52,
      p_m6_H: 1.01,
      p_m12_L: 0.445,
      p_m12_H: 1.01,
    },
    "0183": {
      H: 0.3,
      L: 0.285,
      O: 0.285,
      C: 0.29,
      V: 620000,
      p_w1_L: 0.285,
      p_w1_H: 0.315,
      p_m1_L: 0.285,
      p_m1_H: 0.33,
      p_m3_L: 0.255,
      p_m3_H: 0.375,
      p_m6_L: 0.255,
      p_m6_H: 0.375,
      p_m12_L: 0.255,
      p_m12_H: 0.88,
    },
    "0184": {
      H: 3.25,
      L: 3.01,
      O: 3.21,
      C: 3.23,
      V: 130000,
      p_w1_L: 2.97,
      p_w1_H: 3.25,
      p_m1_L: 2.95,
      p_m1_H: 3.49,
      p_m3_L: 2.95,
      p_m3_H: 4.32,
      p_m6_L: 2.95,
      p_m6_H: 6.1,
      p_m12_L: 2.68,
      p_m12_H: 6.1,
    },
    "0185": {
      H: 3.93,
      L: 3.79,
      O: 3.84,
      C: 3.84,
      V: 32000,
      p_w1_L: 3.71,
      p_w1_H: 4.4,
      p_m1_L: 3.49,
      p_m1_H: 4.4,
      p_m3_L: 3.49,
      p_m3_H: 5.95,
      p_m6_L: 3.49,
      p_m6_H: 6.7,
      p_m12_L: 0.34,
      p_m12_H: 6.9,
    },
    "0186": {
      H: 0.58,
      L: 0.58,
      O: 0.58,
      C: 0.58,
      V: 30000,
      p_w1_L: 0.58,
      p_w1_H: 0.59,
      p_m1_L: 0.56,
      p_m1_H: 0.6,
      p_m3_L: 0.5,
      p_m3_H: 0.7,
      p_m6_L: 0.5,
      p_m6_H: 0.87,
      p_m12_L: 0.41,
      p_m12_H: 0.87,
    },
    "0187": {
      H: 4.46,
      L: 3.35,
      O: 3.35,
      C: 4.46,
      V: 34682400,
      p_w1_L: 2.51,
      p_w1_H: 4.46,
      p_m1_L: 2.05,
      p_m1_H: 4.46,
      p_m3_L: 2.01,
      p_m3_H: 4.46,
      p_m6_L: 1.95,
      p_m6_H: 4.46,
      p_m12_L: 1.24,
      p_m12_H: 4.46,
    },
    "0188": {
      H: 0.365,
      L: 0.365,
      O: 0.365,
      C: 0.365,
      V: 12000,
      p_w1_L: 0.365,
      p_w1_H: 0.385,
      p_m1_L: 0.365,
      p_m1_H: 0.405,
      p_m3_L: 0.35,
      p_m3_H: 0.465,
      p_m6_L: 0.345,
      p_m6_H: 0.465,
      p_m12_L: 0.315,
      p_m12_H: 0.465,
    },
    "0189": {
      H: 13.14,
      L: 12.38,
      O: 13.14,
      C: 12.9,
      V: 22572500,
      p_w1_L: 12.28,
      p_w1_H: 15.16,
      p_m1_L: 12.28,
      p_m1_H: 17.46,
      p_m3_L: 12.28,
      p_m3_H: 26.45,
      p_m6_L: 5.18,
      p_m6_H: 28.5,
      p_m12_L: 3.95,
      p_m12_H: 28.5,
    },
    "0191": {
      H: 3.52,
      L: 3.52,
      O: 3.52,
      C: 3.52,
      V: 0,
      p_w1_L: 3.2,
      p_w1_H: 3.54,
      p_m1_L: 3.09,
      p_m1_H: 3.87,
      p_m3_L: 3.09,
      p_m3_H: 5.88,
      p_m6_L: 3.05,
      p_m6_H: 6.45,
      p_m12_L: 3.05,
      p_m12_H: 6.5,
    },
    "0193": {
      H: 0.33,
      L: 0.33,
      O: 0.33,
      C: 0.33,
      V: 500,
      p_w1_L: 0.325,
      p_w1_H: 0.34,
      p_m1_L: 0.325,
      p_m1_H: 0.355,
      p_m3_L: 0.325,
      p_m3_H: 0.395,
      p_m6_L: 0.325,
      p_m6_H: 0.42,
      p_m12_L: 0.325,
      p_m12_H: 0.6,
    },
    "0194": {
      H: 7.85,
      L: 7.75,
      O: 7.78,
      C: 7.75,
      V: 96000,
      p_w1_L: 7.64,
      p_w1_H: 7.85,
      p_m1_L: 7.61,
      p_m1_H: 8.05,
      p_m3_L: 7.5,
      p_m3_H: 8.16,
      p_m6_L: 7.5,
      p_m6_H: 8.44,
      p_m12_L: 6.91,
      p_m12_H: 8.67,
    },
    "0195": {
      H: 0.094,
      L: 0.094,
      O: 0.094,
      C: 0.094,
      V: 680000,
      p_w1_L: 0.09,
      p_w1_H: 0.099,
      p_m1_L: 0.088,
      p_m1_H: 0.099,
      p_m3_L: 0.088,
      p_m3_H: 0.108,
      p_m6_L: 0.088,
      p_m6_H: 0.127,
      p_m12_L: 0.08,
      p_m12_H: 0.15,
    },
    "0196": {
      H: 0.255,
      L: 0.25,
      O: 0.25,
      C: 0.25,
      V: 618000,
      p_w1_L: 0.24,
      p_w1_H: 0.26,
      p_m1_L: 0.205,
      p_m1_H: 0.29,
      p_m3_L: 0.205,
      p_m3_H: 0.31,
      p_m6_L: 0.201,
      p_m6_H: 0.31,
      p_m12_L: 0.201,
      p_m12_H: 0.385,
    },
    "0197": {
      H: 0.135,
      L: 0.13,
      O: 0.134,
      C: 0.135,
      V: 24399000,
      p_w1_L: 0.123,
      p_w1_H: 0.14,
      p_m1_L: 0.11,
      p_m1_H: 0.14,
      p_m3_L: 0.087,
      p_m3_H: 0.14,
      p_m6_L: 0.08,
      p_m6_H: 0.14,
      p_m12_L: 0.056,
      p_m12_H: 0.158,
    },
    "0199": {
      H: 1.25,
      L: 1.22,
      O: 1.22,
      C: 1.25,
      V: 50000,
      p_w1_L: 1.21,
      p_w1_H: 1.29,
      p_m1_L: 0.98,
      p_m1_H: 1.32,
      p_m3_L: 0.92,
      p_m3_H: 1.32,
      p_m6_L: 0.87,
      p_m6_H: 1.32,
      p_m12_L: 0.7,
      p_m12_H: 1.32,
    },
    "0200": {
      H: 10.6,
      L: 10.06,
      O: 10.1,
      C: 10.42,
      V: 2097200,
      p_w1_L: 9.43,
      p_w1_H: 10.6,
      p_m1_L: 9.43,
      p_m1_H: 11.66,
      p_m3_L: 8.55,
      p_m3_H: 12.96,
      p_m6_L: 8.55,
      p_m6_H: 14.68,
      p_m12_L: 8.55,
      p_m12_H: 17.5,
    },
    "0201": {
      H: 0.12,
      L: 0.114,
      O: 0.12,
      C: 0.12,
      V: 252000,
      p_w1_L: 0.105,
      p_w1_H: 0.125,
      p_m1_L: 0.105,
      p_m1_H: 0.13,
      p_m3_L: 0.105,
      p_m3_H: 0.13,
      p_m6_L: 0.105,
      p_m6_H: 0.13,
      p_m12_L: 0.094,
      p_m12_H: 0.13,
    },
    "0202": {
      H: 0.184,
      L: 0.18,
      O: 0.183,
      C: 0.184,
      V: 110000,
      p_w1_L: 0.167,
      p_w1_H: 0.184,
      p_m1_L: 0.167,
      p_m1_H: 0.186,
      p_m3_L: 0.125,
      p_m3_H: 0.196,
      p_m6_L: 0.125,
      p_m6_H: 0.204,
      p_m12_L: 0.125,
      p_m12_H: 0.204,
    },
    "0204": {
      H: 0.086,
      L: 0.066,
      O: 0.086,
      C: 0.078,
      V: 40740000,
      p_w1_L: 0.066,
      p_w1_H: 0.181,
      p_m1_L: 0.066,
      p_m1_H: 0.184,
      p_m3_L: 0.066,
      p_m3_H: 0.25,
      p_m6_L: 0.066,
      p_m6_H: 0.295,
      p_m12_L: 0.066,
      p_m12_H: 0.295,
    },
    "0205": {
      H: 0.54,
      L: 0.45,
      O: 0.54,
      C: 0.45,
      V: 1285000,
      p_w1_L: 0.45,
      p_w1_H: 0.68,
      p_m1_L: 0.45,
      p_m1_H: 0.68,
      p_m3_L: 0.45,
      p_m3_H: 0.88,
      p_m6_L: 0.23,
      p_m6_H: 1.17,
      p_m12_L: 0.091,
      p_m12_H: 1.17,
    },
    "0206": {
      H: 0.36,
      L: 0.34,
      O: 0.34,
      C: 0.36,
      V: 1316000,
      p_w1_L: 0.25,
      p_w1_H: 0.37,
      p_m1_L: 0.241,
      p_m1_H: 0.37,
      p_m3_L: 0.241,
      p_m3_H: 0.415,
      p_m6_L: 0.205,
      p_m6_H: 0.415,
      p_m12_L: 0.181,
      p_m12_H: 0.415,
    },
    "0207": {
      H: 0.395,
      L: 0.39,
      O: 0.39,
      C: 0.39,
      V: 530000,
      p_w1_L: 0.385,
      p_w1_H: 0.4,
      p_m1_L: 0.385,
      p_m1_H: 0.415,
      p_m3_L: 0.385,
      p_m3_H: 0.445,
      p_m6_L: 0.385,
      p_m6_H: 0.495,
      p_m12_L: 0.385,
      p_m12_H: 0.56,
    },
    "0209": {
      H: 0.062,
      L: 0.059,
      O: 0.059,
      C: 0.062,
      V: 140000,
      p_w1_L: 0.056,
      p_w1_H: 0.07,
      p_m1_L: 0.056,
      p_m1_H: 0.072,
      p_m3_L: 0.053,
      p_m3_H: 0.1,
      p_m6_L: 0.035,
      p_m6_H: 0.1,
      p_m12_L: 0.035,
      p_m12_H: 0.1,
    },
    "0210": {
      H: 0.15,
      L: 0.138,
      O: 0.15,
      C: 0.141,
      V: 102000,
      p_w1_L: 0.138,
      p_w1_H: 0.153,
      p_m1_L: 0.135,
      p_m1_H: 0.172,
      p_m3_L: 0.135,
      p_m3_H: 0.177,
      p_m6_L: 0.135,
      p_m6_H: 0.19,
      p_m12_L: 0.135,
      p_m12_H: 0.255,
    },
    "0211": {
      H: 0.224,
      L: 0.224,
      O: 0.224,
      C: 0.224,
      V: 250000,
      p_w1_L: 0.224,
      p_w1_H: 0.23,
      p_m1_L: 0.203,
      p_m1_H: 0.29,
      p_m3_L: 0.203,
      p_m3_H: 0.31,
      p_m6_L: 0.203,
      p_m6_H: 0.335,
      p_m12_L: 0.203,
      p_m12_H: 0.475,
    },
    "0212": {
      H: 38.65,
      L: 38.65,
      O: 38.65,
      C: 38.65,
      V: 0,
      p_w1_L: 38.65,
      p_w1_H: 39,
      p_m1_L: 38.55,
      p_m1_H: 39.4,
      p_m3_L: 35.3,
      p_m3_H: 40.55,
      p_m6_L: 35.3,
      p_m6_H: 42,
      p_m12_L: 35.3,
      p_m12_H: 43.55,
    },
    "0213": {
      H: 1.09,
      L: 1.09,
      O: 1.09,
      C: 1.09,
      V: 0,
      p_w1_L: 1.09,
      p_w1_H: 1.09,
      p_m1_L: 1.03,
      p_m1_H: 1.1,
      p_m3_L: 1.03,
      p_m3_H: 1.13,
      p_m6_L: 1.03,
      p_m6_H: 1.17,
      p_m12_L: 1.02,
      p_m12_H: 1.17,
    },
    "0214": {
      H: 0.89,
      L: 0.88,
      O: 0.89,
      C: 0.88,
      V: 44000,
      p_w1_L: 0.86,
      p_w1_H: 0.9,
      p_m1_L: 0.84,
      p_m1_H: 0.95,
      p_m3_L: 0.8,
      p_m3_H: 1.03,
      p_m6_L: 0.8,
      p_m6_H: 1.45,
      p_m12_L: 0.8,
      p_m12_H: 1.62,
    },
    "0215": {
      H: 1.32,
      L: 1.29,
      O: 1.32,
      C: 1.3,
      V: 3166380,
      p_w1_L: 1.26,
      p_w1_H: 1.35,
      p_m1_L: 1.26,
      p_m1_H: 1.36,
      p_m3_L: 1.17,
      p_m3_H: 1.36,
      p_m6_L: 1.17,
      p_m6_H: 1.82,
      p_m12_L: 1.15,
      p_m12_H: 1.82,
    },
    "0216": {
      H: 1.61,
      L: 1.56,
      O: 1.56,
      C: 1.61,
      V: 44000,
      p_w1_L: 1.49,
      p_w1_H: 1.61,
      p_m1_L: 1.48,
      p_m1_H: 1.61,
      p_m3_L: 1.48,
      p_m3_H: 1.76,
      p_m6_L: 1.48,
      p_m6_H: 1.99,
      p_m12_L: 1.48,
      p_m12_H: 1.99,
    },
    "0217": {
      H: 0.18,
      L: 0.17,
      O: 0.179,
      C: 0.174,
      V: 526000,
      p_w1_L: 0.147,
      p_w1_H: 0.184,
      p_m1_L: 0.145,
      p_m1_H: 0.184,
      p_m3_L: 0.145,
      p_m3_H: 0.191,
      p_m6_L: 0.133,
      p_m6_H: 0.199,
      p_m12_L: 0.133,
      p_m12_H: 0.25,
    },
    "0218": {
      H: 0.94,
      L: 0.91,
      O: 0.91,
      C: 0.92,
      V: 130000,
      p_w1_L: 0.89,
      p_w1_H: 0.94,
      p_m1_L: 0.89,
      p_m1_H: 1.03,
      p_m3_L: 0.89,
      p_m3_H: 1.28,
      p_m6_L: 0.89,
      p_m6_H: 1.28,
      p_m12_L: 0.89,
      p_m12_H: 1.32,
    },
    "0219": {
      H: 1.3,
      L: 1.3,
      O: 1.3,
      C: 1.3,
      V: 0,
      p_w1_L: 1.27,
      p_w1_H: 1.34,
      p_m1_L: 1.21,
      p_m1_H: 1.35,
      p_m3_L: 1.17,
      p_m3_H: 1.35,
      p_m6_L: 1.11,
      p_m6_H: 1.35,
      p_m12_L: 1.11,
      p_m12_H: 1.52,
    },
    "0220": {
      H: 7.83,
      L: 7.6,
      O: 7.8,
      C: 7.65,
      V: 4736600,
      p_w1_L: 7.46,
      p_w1_H: 7.9,
      p_m1_L: 6.14,
      p_m1_H: 7.9,
      p_m3_L: 6.14,
      p_m3_H: 7.9,
      p_m6_L: 6.14,
      p_m6_H: 9.08,
      p_m12_L: 6.14,
      p_m12_H: 10.46,
    },
    "0222": {
      H: 4,
      L: 4,
      O: 4,
      C: 4,
      V: 0,
      p_w1_L: 3.92,
      p_w1_H: 4.35,
      p_m1_L: 3.9,
      p_m1_H: 4.35,
      p_m3_L: 2.87,
      p_m3_H: 4.6,
      p_m6_L: 2.87,
      p_m6_H: 4.6,
      p_m12_L: 2.87,
      p_m12_H: 4.6,
    },
    "0223": {
      H: 0.053,
      L: 0.05,
      O: 0.053,
      C: 0.05,
      V: 1440000,
      p_w1_L: 0.04,
      p_w1_H: 0.055,
      p_m1_L: 0.034,
      p_m1_H: 0.06,
      p_m3_L: 0.034,
      p_m3_H: 0.06,
      p_m6_L: 0.034,
      p_m6_H: 0.071,
      p_m12_L: 0.034,
      p_m12_H: 0.1,
    },
    "0224": {
      H: 1.02,
      L: 1.02,
      O: 1.02,
      C: 1.02,
      V: 0,
      p_w1_L: 1.02,
      p_w1_H: 1.02,
      p_m1_L: 0.99,
      p_m1_H: 1.03,
      p_m3_L: 0.99,
      p_m3_H: 1.1,
      p_m6_L: 0.99,
      p_m6_H: 1.1,
      p_m12_L: 0.95,
      p_m12_H: 1.25,
    },
    "0225": {
      H: 11.5,
      L: 11.5,
      O: 11.5,
      C: 11.5,
      V: 0,
      p_w1_L: 11.4,
      p_w1_H: 11.8,
      p_m1_L: 11.4,
      p_m1_H: 12,
      p_m3_L: 11.1,
      p_m3_H: 12,
      p_m6_L: 11.1,
      p_m6_H: 12,
      p_m12_L: 11.1,
      p_m12_H: 12,
    },
    "0226": {
      H: 2.41,
      L: 2.41,
      O: 2.41,
      C: 2.41,
      V: 0,
      p_w1_L: 2.41,
      p_w1_H: 2.41,
      p_m1_L: 2.4,
      p_m1_H: 2.62,
      p_m3_L: 2.3,
      p_m3_H: 2.62,
      p_m6_L: 2.19,
      p_m6_H: 2.62,
      p_m12_L: 2.12,
      p_m12_H: 2.77,
    },
    "0227": {
      H: 0.222,
      L: 0.222,
      O: 0.222,
      C: 0.222,
      V: 0,
      p_w1_L: 0.21,
      p_w1_H: 0.243,
      p_m1_L: 0.21,
      p_m1_H: 0.255,
      p_m3_L: 0.21,
      p_m3_H: 0.31,
      p_m6_L: 0.21,
      p_m6_H: 0.435,
      p_m12_L: 0.21,
      p_m12_H: 0.44,
    },
    "0228": {
      H: 0.144,
      L: 0.139,
      O: 0.14,
      C: 0.144,
      V: 1420000,
      p_w1_L: 0.137,
      p_w1_H: 0.15,
      p_m1_L: 0.135,
      p_m1_H: 0.17,
      p_m3_L: 0.1,
      p_m3_H: 0.187,
      p_m6_L: 0.082,
      p_m6_H: 0.187,
      p_m12_L: 0.082,
      p_m12_H: 0.187,
    },
    "0229": {
      H: 1.03,
      L: 1.03,
      O: 1.03,
      C: 1.03,
      V: 20000,
      p_w1_L: 1,
      p_w1_H: 1.08,
      p_m1_L: 1,
      p_m1_H: 1.08,
      p_m3_L: 1,
      p_m3_H: 1.25,
      p_m6_L: 1,
      p_m6_H: 1.25,
      p_m12_L: 0.95,
      p_m12_H: 1.3,
    },
    "0230": {
      H: 0.78,
      L: 0.75,
      O: 0.76,
      C: 0.76,
      V: 28000,
      p_w1_L: 0.73,
      p_w1_H: 0.78,
      p_m1_L: 0.73,
      p_m1_H: 0.87,
      p_m3_L: 0.7,
      p_m3_H: 0.89,
      p_m6_L: 0.7,
      p_m6_H: 0.89,
      p_m12_L: 0.7,
      p_m12_H: 0.98,
    },
    "0231": {
      H: 0.01,
      L: 0.01,
      O: 0.01,
      C: 0.01,
      V: 0,
      p_w1_L: 0.01,
      p_w1_H: 0.01,
      p_m1_L: 0.01,
      p_m1_H: 0.01,
      p_m3_L: 0.01,
      p_m3_H: 0.01,
      p_m6_L: 0.01,
      p_m6_H: 0.01,
      p_m12_L: 0.01,
      p_m12_H: 0.036,
    },
    "0232": {
      H: 0.116,
      L: 0.109,
      O: 0.112,
      C: 0.115,
      V: 3303930,
      p_w1_L: 0.109,
      p_w1_H: 0.117,
      p_m1_L: 0.109,
      p_m1_H: 0.125,
      p_m3_L: 0.109,
      p_m3_H: 0.137,
      p_m6_L: 0.109,
      p_m6_H: 0.156,
      p_m12_L: 0.103,
      p_m12_H: 0.182,
    },
    "0234": {
      H: 0.069,
      L: 0.069,
      O: 0.069,
      C: 0.069,
      V: 0,
      p_w1_L: 0.061,
      p_w1_H: 0.069,
      p_m1_L: 0.061,
      p_m1_H: 0.076,
      p_m3_L: 0.059,
      p_m3_H: 0.076,
      p_m6_L: 0.056,
      p_m6_H: 0.076,
      p_m12_L: 0.052,
      p_m12_H: 0.09,
    },
    "0235": {
      H: 0.049,
      L: 0.048,
      O: 0.049,
      C: 0.048,
      V: 6401470,
      p_w1_L: 0.047,
      p_w1_H: 0.051,
      p_m1_L: 0.046,
      p_m1_H: 0.061,
      p_m3_L: 0.044,
      p_m3_H: 0.065,
      p_m6_L: 0.044,
      p_m6_H: 0.136,
      p_m12_L: 0.044,
      p_m12_H: 0.295,
    },
    "0236": {
      H: 1.01,
      L: 1.01,
      O: 1.01,
      C: 1.01,
      V: 0,
      p_w1_L: 0.94,
      p_w1_H: 1.05,
      p_m1_L: 0.94,
      p_m1_H: 1.07,
      p_m3_L: 0.94,
      p_m3_H: 1.18,
      p_m6_L: 0.91,
      p_m6_H: 1.18,
      p_m12_L: 0.72,
      p_m12_H: 1.39,
    },
    "0237": {
      H: 3.25,
      L: 3.2,
      O: 3.25,
      C: 3.28,
      V: 60000,
      p_w1_L: 3.2,
      p_w1_H: 3.32,
      p_m1_L: 3.2,
      p_m1_H: 3.41,
      p_m3_L: 3.2,
      p_m3_H: 3.5,
      p_m6_L: 3.2,
      p_m6_H: 3.94,
      p_m12_L: 3.12,
      p_m12_H: 3.94,
    },
    "0238": {
      H: 0.255,
      L: 0.255,
      O: 0.255,
      C: 0.255,
      V: 0,
      p_w1_L: 0.255,
      p_w1_H: 0.255,
      p_m1_L: 0.255,
      p_m1_H: 0.255,
      p_m3_L: 0.255,
      p_m3_H: 0.255,
      p_m6_L: 0.255,
      p_m6_H: 0.255,
      p_m12_L: 0.255,
      p_m12_H: 0.255,
    },
    "0239": {
      H: 1.65,
      L: 1.65,
      O: 1.65,
      C: 1.65,
      V: 0,
      p_w1_L: 1.64,
      p_w1_H: 1.65,
      p_m1_L: 1.6,
      p_m1_H: 1.68,
      p_m3_L: 1.59,
      p_m3_H: 1.9,
      p_m6_L: 1.59,
      p_m6_H: 1.94,
      p_m12_L: 1.59,
      p_m12_H: 1.94,
    },
    "0240": {
      H: 0.97,
      L: 0.97,
      O: 0.97,
      C: 0.98,
      V: 10000,
      p_w1_L: 0.96,
      p_w1_H: 1,
      p_m1_L: 0.96,
      p_m1_H: 1.03,
      p_m3_L: 0.91,
      p_m3_H: 1.15,
      p_m6_L: 0.88,
      p_m6_H: 1.15,
      p_m12_L: 0.88,
      p_m12_H: 1.2,
    },
    "0241": {
      H: 7.56,
      L: 7.18,
      O: 7.44,
      C: 7.22,
      V: 46605000,
      p_w1_L: 6.91,
      p_w1_H: 7.56,
      p_m1_L: 6.91,
      p_m1_H: 10.18,
      p_m3_L: 6.91,
      p_m3_H: 13.46,
      p_m6_L: 6.91,
      p_m6_H: 18.68,
      p_m12_L: 6.91,
      p_m12_H: 30.15,
    },
    "0242": {
      H: 2.12,
      L: 2.08,
      O: 2.12,
      C: 2.08,
      V: 1691750,
      p_w1_L: 2.06,
      p_w1_H: 2.15,
      p_m1_L: 2.02,
      p_m1_H: 2.34,
      p_m3_L: 2,
      p_m3_H: 2.42,
      p_m6_L: 2,
      p_m6_H: 2.6,
      p_m12_L: 2,
      p_m12_H: 2.77,
    },
    "0243": {
      H: 0.335,
      L: 0.335,
      O: 0.335,
      C: 0.335,
      V: 13000,
      p_w1_L: 0.325,
      p_w1_H: 0.39,
      p_m1_L: 0.325,
      p_m1_H: 0.43,
      p_m3_L: 0.325,
      p_m3_H: 0.52,
      p_m6_L: 0.325,
      p_m6_H: 0.6,
      p_m12_L: 0.196,
      p_m12_H: 0.6,
    },
    "0244": {
      H: 0.51,
      L: 0.495,
      O: 0.495,
      C: 0.5,
      V: 1329000,
      p_w1_L: 0.48,
      p_w1_H: 0.57,
      p_m1_L: 0.47,
      p_m1_H: 0.57,
      p_m3_L: 0.43,
      p_m3_H: 0.57,
      p_m6_L: 0.36,
      p_m6_H: 0.79,
      p_m12_L: 0.23,
      p_m12_H: 0.79,
    },
    "0245": {
      H: 0.074,
      L: 0.067,
      O: 0.073,
      C: 0.073,
      V: 83970000,
      p_w1_L: 0.067,
      p_w1_H: 0.077,
      p_m1_L: 0.067,
      p_m1_H: 0.083,
      p_m3_L: 0.067,
      p_m3_H: 0.093,
      p_m6_L: 0.067,
      p_m6_H: 0.107,
      p_m12_L: 0.067,
      p_m12_H: 0.128,
    },
    "0247": {
      H: 23.7,
      L: 23.7,
      O: 23.7,
      C: 23.7,
      V: 0,
      p_w1_L: 23.7,
      p_w1_H: 23.7,
      p_m1_L: 23.21,
      p_m1_H: 23.7,
      p_m3_L: 23.21,
      p_m3_H: 24,
      p_m6_L: 23.21,
      p_m6_H: 24.6,
      p_m12_L: 23.21,
      p_m12_H: 24.6,
    },
    "0248": {
      H: 0.089,
      L: 0.089,
      O: 0.089,
      C: 0.089,
      V: 20000,
      p_w1_L: 0.082,
      p_w1_H: 0.089,
      p_m1_L: 0.075,
      p_m1_H: 0.091,
      p_m3_L: 0.075,
      p_m3_H: 0.111,
      p_m6_L: 0.075,
      p_m6_H: 0.138,
      p_m12_L: 0.075,
      p_m12_H: 0.138,
    },
    "0250": {
      H: 0.033,
      L: 0.03,
      O: 0.031,
      C: 0.033,
      V: 12920000,
      p_w1_L: 0.03,
      p_w1_H: 0.034,
      p_m1_L: 0.03,
      p_m1_H: 0.035,
      p_m3_L: 0.03,
      p_m3_H: 0.04,
      p_m6_L: 0.03,
      p_m6_H: 0.054,
      p_m12_L: 0.03,
      p_m12_H: 0.074,
    },
    "0251": {
      H: 5.97,
      L: 5.9,
      O: 5.95,
      C: 5.96,
      V: 156000,
      p_w1_L: 5.84,
      p_w1_H: 6.01,
      p_m1_L: 5.76,
      p_m1_H: 6.26,
      p_m3_L: 5.4,
      p_m3_H: 7.06,
      p_m6_L: 5.4,
      p_m6_H: 9,
      p_m12_L: 5.4,
      p_m12_H: 9.99,
    },
    "0252": {
      H: 3.25,
      L: 3.25,
      O: 3.25,
      C: 3.25,
      V: 0,
      p_w1_L: 2.61,
      p_w1_H: 3.25,
      p_m1_L: 2.61,
      p_m1_H: 3.37,
      p_m3_L: 2.61,
      p_m3_H: 3.37,
      p_m6_L: 2.61,
      p_m6_H: 3.4,
      p_m12_L: 2.61,
      p_m12_H: 3.62,
    },
    "0253": {
      H: 0.89,
      L: 0.89,
      O: 0.89,
      C: 0.89,
      V: 0,
      p_w1_L: 0.88,
      p_w1_H: 0.91,
      p_m1_L: 0.88,
      p_m1_H: 0.91,
      p_m3_L: 0.82,
      p_m3_H: 0.98,
      p_m6_L: 0.82,
      p_m6_H: 1.02,
      p_m12_L: 0.72,
      p_m12_H: 1.02,
    },
    "0254": {
      H: 0.142,
      L: 0.142,
      O: 0.142,
      C: 0.142,
      V: 0,
      p_w1_L: 0.142,
      p_w1_H: 0.142,
      p_m1_L: 0.142,
      p_m1_H: 0.142,
      p_m3_L: 0.142,
      p_m3_H: 0.142,
      p_m6_L: 0.142,
      p_m6_H: 0.142,
      p_m12_L: 0.142,
      p_m12_H: 0.142,
    },
    "0255": {
      H: 3.7,
      L: 3.61,
      O: 3.7,
      C: 3.61,
      V: 116000,
      p_w1_L: 3.61,
      p_w1_H: 3.88,
      p_m1_L: 3.61,
      p_m1_H: 3.96,
      p_m3_L: 3.45,
      p_m3_H: 3.96,
      p_m6_L: 3.19,
      p_m6_H: 4.02,
      p_m12_L: 2.45,
      p_m12_H: 4.02,
    },
    "0256": {
      H: 1.34,
      L: 1.31,
      O: 1.34,
      C: 1.32,
      V: 2370000,
      p_w1_L: 1.27,
      p_w1_H: 1.36,
      p_m1_L: 1.24,
      p_m1_H: 1.36,
      p_m3_L: 1.18,
      p_m3_H: 1.56,
      p_m6_L: 1.18,
      p_m6_H: 1.63,
      p_m12_L: 1.18,
      p_m12_H: 1.81,
    },
    "0257": {
      H: 5.67,
      L: 5.36,
      O: 5.36,
      C: 5.63,
      V: 22397700,
      p_w1_L: 5.11,
      p_w1_H: 5.67,
      p_m1_L: 4.96,
      p_m1_H: 5.67,
      p_m3_L: 4.96,
      p_m3_H: 6.53,
      p_m6_L: 4.02,
      p_m6_H: 6.53,
      p_m12_L: 4.02,
      p_m12_H: 6.53,
    },
    "0258": {
      H: 2.26,
      L: 2.17,
      O: 2.18,
      C: 2.17,
      V: 116000,
      p_w1_L: 2.12,
      p_w1_H: 2.5,
      p_m1_L: 1.85,
      p_m1_H: 2.56,
      p_m3_L: 1.81,
      p_m3_H: 2.56,
      p_m6_L: 1.81,
      p_m6_H: 2.56,
      p_m12_L: 1.65,
      p_m12_H: 2.56,
    },
    "0259": {
      H: 2.7,
      L: 2.67,
      O: 2.7,
      C: 2.67,
      V: 96000,
      p_w1_L: 2.55,
      p_w1_H: 2.7,
      p_m1_L: 2.33,
      p_m1_H: 2.7,
      p_m3_L: 2,
      p_m3_H: 2.7,
      p_m6_L: 2,
      p_m6_H: 3.31,
      p_m12_L: 1.69,
      p_m12_H: 3.31,
    },
    "0260": {
      H: 0.022,
      L: 0.021,
      O: 0.022,
      C: 0.022,
      V: 480000,
      p_w1_L: 0.021,
      p_w1_H: 0.023,
      p_m1_L: 0.021,
      p_m1_H: 0.023,
      p_m3_L: 0.02,
      p_m3_H: 0.028,
      p_m6_L: 0.02,
      p_m6_H: 0.034,
      p_m12_L: 0.02,
      p_m12_H: 0.045,
    },
    "0261": {
      H: 0.01,
      L: 0.01,
      O: 0.01,
      C: 0.01,
      V: 0,
      p_w1_L: 0.01,
      p_w1_H: 0.01,
      p_m1_L: 0.01,
      p_m1_H: 0.01,
      p_m3_L: 0.01,
      p_m3_H: 0.01,
      p_m6_L: 0.01,
      p_m6_H: 0.01,
      p_m12_L: 0.01,
      p_m12_H: 0.01,
    },
    "0262": {
      H: 0.103,
      L: 0.103,
      O: 0.103,
      C: 0.103,
      V: 32000,
      p_w1_L: 0.098,
      p_w1_H: 0.104,
      p_m1_L: 0.096,
      p_m1_H: 0.112,
      p_m3_L: 0.096,
      p_m3_H: 0.12,
      p_m6_L: 0.096,
      p_m6_H: 0.14,
      p_m12_L: 0.093,
      p_m12_H: 0.16,
    },
    "0263": {
      H: 0.039,
      L: 0.039,
      O: 0.039,
      C: 0.039,
      V: 0,
      p_w1_L: 0.039,
      p_w1_H: 0.039,
      p_m1_L: 0.039,
      p_m1_H: 0.039,
      p_m3_L: 0.039,
      p_m3_H: 0.039,
      p_m6_L: 0.039,
      p_m6_H: 0.039,
      p_m12_L: 0.029,
      p_m12_H: 0.148,
    },
    "0264": {
      H: 0.75,
      L: 0.75,
      O: 0.75,
      C: 0.75,
      V: 0,
      p_w1_L: 0.73,
      p_w1_H: 0.75,
      p_m1_L: 0.61,
      p_m1_H: 0.75,
      p_m3_L: 0.6,
      p_m3_H: 0.75,
      p_m6_L: 0.6,
      p_m6_H: 1,
      p_m12_L: 0.6,
      p_m12_H: 1.25,
    },
    "0265": {
      H: 0.052,
      L: 0.052,
      O: 0.052,
      C: 0.052,
      V: 0,
      p_w1_L: 0.052,
      p_w1_H: 0.052,
      p_m1_L: 0.047,
      p_m1_H: 0.06,
      p_m3_L: 0.047,
      p_m3_H: 0.089,
      p_m6_L: 0.047,
      p_m6_H: 0.089,
      p_m12_L: 0.045,
      p_m12_H: 0.089,
    },
    "0266": {
      H: 5,
      L: 5,
      O: 5,
      C: 5,
      V: 0,
      p_w1_L: 4.57,
      p_w1_H: 5.5,
      p_m1_L: 4.57,
      p_m1_H: 5.54,
      p_m3_L: 4.57,
      p_m3_H: 5.55,
      p_m6_L: 4.51,
      p_m6_H: 5.55,
      p_m12_L: 4.51,
      p_m12_H: 5.55,
    },
    "0267": {
      H: 7.59,
      L: 7.4,
      O: 7.59,
      C: 7.48,
      V: 12297800,
      p_w1_L: 7.03,
      p_w1_H: 7.61,
      p_m1_L: 6.96,
      p_m1_H: 7.61,
      p_m3_L: 6.96,
      p_m3_H: 10.24,
      p_m6_L: 6.96,
      p_m6_H: 10.24,
      p_m12_L: 5.2,
      p_m12_H: 10.24,
    },
    "0268": {
      H: 23.5,
      L: 22.45,
      O: 22.8,
      C: 22.75,
      V: 11984400,
      p_w1_L: 20.9,
      p_w1_H: 23.65,
      p_m1_L: 20.9,
      p_m1_H: 28.3,
      p_m3_L: 20.9,
      p_m3_H: 29.2,
      p_m6_L: 20.3,
      p_m6_H: 29.45,
      p_m12_L: 20.3,
      p_m12_H: 39.5,
    },
    "0269": {
      H: 0.016,
      L: 0.014,
      O: 0.016,
      C: 0.015,
      V: 6540000,
      p_w1_L: 0.013,
      p_w1_H: 0.019,
      p_m1_L: 0.011,
      p_m1_H: 0.029,
      p_m3_L: 0.011,
      p_m3_H: 0.029,
      p_m6_L: 0.011,
      p_m6_H: 0.029,
      p_m12_L: 0.01,
      p_m12_H: 0.035,
    },
    "0270": {
      H: 10.24,
      L: 9.99,
      O: 10,
      C: 10.08,
      V: 6080260,
      p_w1_L: 9.88,
      p_w1_H: 10.58,
      p_m1_L: 9.76,
      p_m1_H: 11.16,
      p_m3_L: 9.5,
      p_m3_H: 11.32,
      p_m6_L: 9.5,
      p_m6_H: 12.1,
      p_m12_L: 9.5,
      p_m12_H: 14.6,
    },
    "0271": {
      H: 0.66,
      L: 0.65,
      O: 0.66,
      C: 0.65,
      V: 354000,
      p_w1_L: 0.62,
      p_w1_H: 0.68,
      p_m1_L: 0.6,
      p_m1_H: 1.94,
      p_m3_L: 0.6,
      p_m3_H: 1.94,
      p_m6_L: 0.6,
      p_m6_H: 2.2,
      p_m12_L: 0.6,
      p_m12_H: 2.2,
    },
    "0272": {
      H: 1.05,
      L: 1.03,
      O: 1.05,
      C: 1.04,
      V: 4216500,
      p_w1_L: 1.01,
      p_w1_H: 1.06,
      p_m1_L: 1.01,
      p_m1_H: 1.11,
      p_m3_L: 1.01,
      p_m3_H: 1.45,
      p_m6_L: 1.01,
      p_m6_H: 1.45,
      p_m12_L: 1.01,
      p_m12_H: 1.45,
    },
    "0273": {
      H: 0.024,
      L: 0.023,
      O: 0.024,
      C: 0.024,
      V: 3120000,
      p_w1_L: 0.022,
      p_w1_H: 0.025,
      p_m1_L: 0.018,
      p_m1_H: 0.034,
      p_m3_L: 0.018,
      p_m3_H: 0.034,
      p_m6_L: 0.018,
      p_m6_H: 0.039,
      p_m12_L: 0.018,
      p_m12_H: 0.072,
    },
    "0274": {
      H: 0.385,
      L: 0.32,
      O: 0.365,
      C: 0.325,
      V: 1450750,
      p_w1_L: 0.32,
      p_w1_H: 0.44,
      p_m1_L: 0.32,
      p_m1_H: 0.54,
      p_m3_L: 0.275,
      p_m3_H: 0.64,
      p_m6_L: 0.221,
      p_m6_H: 0.64,
      p_m12_L: 0.055,
      p_m12_H: 0.64,
    },
    "0276": {
      H: 1.08,
      L: 1.06,
      O: 1.06,
      C: 1.06,
      V: 123250,
      p_w1_L: 1,
      p_w1_H: 1.24,
      p_m1_L: 0.88,
      p_m1_H: 1.53,
      p_m3_L: 0.82,
      p_m3_H: 1.53,
      p_m6_L: 0.65,
      p_m6_H: 1.53,
      p_m12_L: 0.65,
      p_m12_H: 1.55,
    },
    "0277": {
      H: 3.86,
      L: 3.86,
      O: 3.86,
      C: 3.86,
      V: 0,
      p_w1_L: 3.86,
      p_w1_H: 3.86,
      p_m1_L: 3.2,
      p_m1_H: 3.88,
      p_m3_L: 2.99,
      p_m3_H: 3.88,
      p_m6_L: 2.99,
      p_m6_H: 3.88,
      p_m12_L: 2.99,
      p_m12_H: 3.88,
    },
    "0278": {
      H: 5.15,
      L: 5.15,
      O: 5.15,
      C: 5.15,
      V: 0,
      p_w1_L: 5.15,
      p_w1_H: 5.15,
      p_m1_L: 4.72,
      p_m1_H: 5.15,
      p_m3_L: 4.6,
      p_m3_H: 5.15,
      p_m6_L: 4.6,
      p_m6_H: 5.18,
      p_m12_L: 4.6,
      p_m12_H: 5.18,
    },
    "0279": {
      H: 0.229,
      L: 0.21,
      O: 0.229,
      C: 0.215,
      V: 2144000,
      p_w1_L: 0.21,
      p_w1_H: 0.24,
      p_m1_L: 0.18,
      p_m1_H: 0.29,
      p_m3_L: 0.09,
      p_m3_H: 0.335,
      p_m6_L: 0.09,
      p_m6_H: 0.335,
      p_m12_L: 0.09,
      p_m12_H: 0.335,
    },
    "0280": {
      H: 0.435,
      L: 0.42,
      O: 0.43,
      C: 0.435,
      V: 900000,
      p_w1_L: 0.415,
      p_w1_H: 0.445,
      p_m1_L: 0.415,
      p_m1_H: 0.54,
      p_m3_L: 0.415,
      p_m3_H: 0.54,
      p_m6_L: 0.335,
      p_m6_H: 0.63,
      p_m12_L: 0.26,
      p_m12_H: 0.63,
    },
    "0282": {
      H: 0.29,
      L: 0.29,
      O: 0.29,
      C: 0.29,
      V: 0,
      p_w1_L: 0.29,
      p_w1_H: 0.29,
      p_m1_L: 0.29,
      p_m1_H: 0.29,
      p_m3_L: 0.29,
      p_m3_H: 0.29,
      p_m6_L: 0.26,
      p_m6_H: 0.35,
      p_m12_L: 0.161,
      p_m12_H: 0.8,
    },
    "0285": {
      H: 31.4,
      L: 29.15,
      O: 29.2,
      C: 30.6,
      V: 14820600,
      p_w1_L: 27.5,
      p_w1_H: 31.4,
      p_m1_L: 24.25,
      p_m1_H: 34.6,
      p_m3_L: 22.1,
      p_m3_H: 36.9,
      p_m6_L: 22.1,
      p_m6_H: 54.75,
      p_m12_L: 22.1,
      p_m12_H: 63.1,
    },
    "0286": {
      H: 0.94,
      L: 0.89,
      O: 0.92,
      C: 0.9,
      V: 14386200,
      p_w1_L: 0.88,
      p_w1_H: 0.95,
      p_m1_L: 0.83,
      p_m1_H: 1.1,
      p_m3_L: 0.83,
      p_m3_H: 1.1,
      p_m6_L: 0.83,
      p_m6_H: 1.55,
      p_m12_L: 0.4,
      p_m12_H: 1.55,
    },
    "0287": {
      H: 9.18,
      L: 9.18,
      O: 9.18,
      C: 9.18,
      V: 2000,
      p_w1_L: 8.82,
      p_w1_H: 9.18,
      p_m1_L: 8.8,
      p_m1_H: 9.18,
      p_m3_L: 8.8,
      p_m3_H: 9.28,
      p_m6_L: 8.8,
      p_m6_H: 9.48,
      p_m12_L: 8.7,
      p_m12_H: 9.48,
    },
    "0288": {
      H: 4.96,
      L: 4.86,
      O: 4.95,
      C: 4.92,
      V: 12501500,
      p_w1_L: 4.66,
      p_w1_H: 4.96,
      p_m1_L: 4.66,
      p_m1_H: 5.46,
      p_m3_L: 4.66,
      p_m3_H: 6.08,
      p_m6_L: 4.66,
      p_m6_H: 7.43,
      p_m12_L: 4.66,
      p_m12_H: 7.55,
    },
    "0289": {
      H: 17.94,
      L: 17.94,
      O: 17.94,
      C: 17.94,
      V: 0,
      p_w1_L: 17.7,
      p_w1_H: 17.94,
      p_m1_L: 17.6,
      p_m1_H: 17.94,
      p_m3_L: 17.42,
      p_m3_H: 18.5,
      p_m6_L: 17.1,
      p_m6_H: 18.76,
      p_m12_L: 16.1,
      p_m12_H: 18.76,
    },
    "0290": {
      H: 0.242,
      L: 0.225,
      O: 0.242,
      C: 0.229,
      V: 184000,
      p_w1_L: 0.225,
      p_w1_H: 0.295,
      p_m1_L: 0.225,
      p_m1_H: 0.3,
      p_m3_L: 0.225,
      p_m3_H: 0.325,
      p_m6_L: 0.225,
      p_m6_H: 0.35,
      p_m12_L: 0.225,
      p_m12_H: 0.59,
    },
    "0291": {
      H: 64.8,
      L: 62.5,
      O: 63.5,
      C: 64.35,
      V: 8316510,
      p_w1_L: 59.85,
      p_w1_H: 64.8,
      p_m1_L: 59.65,
      p_m1_H: 67.9,
      p_m3_L: 53.35,
      p_m3_H: 67.9,
      p_m6_L: 53.35,
      p_m6_H: 76,
      p_m12_L: 53.35,
      p_m12_H: 78.6,
    },
    "0292": {
      H: 0.208,
      L: 0.208,
      O: 0.208,
      C: 0.208,
      V: 16075,
      p_w1_L: 0.208,
      p_w1_H: 0.21,
      p_m1_L: 0.19,
      p_m1_H: 0.21,
      p_m3_L: 0.18,
      p_m3_H: 0.223,
      p_m6_L: 0.18,
      p_m6_H: 0.26,
      p_m12_L: 0.18,
      p_m12_H: 0.265,
    },
    "0293": {
      H: 6.64,
      L: 6.47,
      O: 6.5,
      C: 6.49,
      V: 4424160,
      p_w1_L: 6.11,
      p_w1_H: 6.64,
      p_m1_L: 6.11,
      p_m1_H: 7.6,
      p_m3_L: 6.11,
      p_m3_H: 7.6,
      p_m6_L: 6.1,
      p_m6_H: 7.6,
      p_m12_L: 5.95,
      p_m12_H: 7.93,
    },
    "0294": {
      H: 2,
      L: 2,
      O: 2,
      C: 2,
      V: 0,
      p_w1_L: 1.99,
      p_w1_H: 2,
      p_m1_L: 1.96,
      p_m1_H: 2,
      p_m3_L: 1.9,
      p_m3_H: 2.05,
      p_m6_L: 1.9,
      p_m6_H: 2.3,
      p_m12_L: 1.85,
      p_m12_H: 2.32,
    },
    "0295": {
      H: 0.065,
      L: 0.057,
      O: 0.059,
      C: 0.064,
      V: 525000,
      p_w1_L: 0.057,
      p_w1_H: 0.065,
      p_m1_L: 0.056,
      p_m1_H: 0.075,
      p_m3_L: 0.052,
      p_m3_H: 0.083,
      p_m6_L: 0.05,
      p_m6_H: 0.102,
      p_m12_L: 0.05,
      p_m12_H: 0.145,
    },
    "0296": {
      H: 0.9,
      L: 0.89,
      O: 0.9,
      C: 0.89,
      V: 95000,
      p_w1_L: 0.87,
      p_w1_H: 0.98,
      p_m1_L: 0.87,
      p_m1_H: 0.98,
      p_m3_L: 0.82,
      p_m3_H: 0.98,
      p_m6_L: 0.82,
      p_m6_H: 1.16,
      p_m12_L: 0.82,
      p_m12_H: 1.38,
    },
    "0297": {
      H: 1.16,
      L: 1.13,
      O: 1.14,
      C: 1.15,
      V: 2480000,
      p_w1_L: 1.12,
      p_w1_H: 1.18,
      p_m1_L: 1.12,
      p_m1_H: 1.26,
      p_m3_L: 1.1,
      p_m3_H: 1.78,
      p_m6_L: 1.05,
      p_m6_H: 1.78,
      p_m12_L: 0.7,
      p_m12_H: 1.78,
    },
    "0298": {
      H: 0.4,
      L: 0.395,
      O: 0.395,
      C: 0.395,
      V: 100000,
      p_w1_L: 0.39,
      p_w1_H: 0.4,
      p_m1_L: 0.385,
      p_m1_H: 0.415,
      p_m3_L: 0.38,
      p_m3_H: 0.49,
      p_m6_L: 0.38,
      p_m6_H: 0.53,
      p_m12_L: 0.375,
      p_m12_H: 0.54,
    },
    "0299": {
      H: 0.188,
      L: 0.164,
      O: 0.188,
      C: 0.181,
      V: 130000,
      p_w1_L: 0.164,
      p_w1_H: 0.194,
      p_m1_L: 0.164,
      p_m1_H: 0.205,
      p_m3_L: 0.164,
      p_m3_H: 0.24,
      p_m6_L: 0.164,
      p_m6_H: 0.31,
      p_m12_L: 0.164,
      p_m12_H: 0.38,
    },
    "0301": {
      H: 1.32,
      L: 1.27,
      O: 1.27,
      C: 1.3,
      V: 34000,
      p_w1_L: 1.27,
      p_w1_H: 1.34,
      p_m1_L: 1.11,
      p_m1_H: 1.34,
      p_m3_L: 1.11,
      p_m3_H: 1.39,
      p_m6_L: 1.11,
      p_m6_H: 1.4,
      p_m12_L: 0.83,
      p_m12_H: 1.56,
    },
    "0302": {
      H: 3.28,
      L: 3.17,
      O: 3.17,
      C: 3.24,
      V: 3258000,
      p_w1_L: 3.07,
      p_w1_H: 3.34,
      p_m1_L: 3.07,
      p_m1_H: 3.78,
      p_m3_L: 2.9,
      p_m3_H: 3.78,
      p_m6_L: 2.9,
      p_m6_H: 5.09,
      p_m12_L: 2.68,
      p_m12_H: 5.09,
    },
    "0303": {
      H: 61.85,
      L: 61,
      O: 61,
      C: 61,
      V: 199200,
      p_w1_L: 60,
      p_w1_H: 65.2,
      p_m1_L: 60,
      p_m1_H: 65.95,
      p_m3_L: 56.3,
      p_m3_H: 78.95,
      p_m6_L: 56.3,
      p_m6_H: 84.3,
      p_m12_L: 56.3,
      p_m12_H: 84.3,
    },
    "0305": {
      H: 1.84,
      L: 1.68,
      O: 1.72,
      C: 1.83,
      V: 18140000,
      p_w1_L: 1.67,
      p_w1_H: 1.97,
      p_m1_L: 1.34,
      p_m1_H: 2.03,
      p_m3_L: 1.09,
      p_m3_H: 2.03,
      p_m6_L: 1.09,
      p_m6_H: 2.15,
      p_m12_L: 1.04,
      p_m12_H: 4.43,
    },
    "0306": {
      H: 2.1,
      L: 2.1,
      O: 2.1,
      C: 2.1,
      V: 0,
      p_w1_L: 2.08,
      p_w1_H: 2.32,
      p_m1_L: 1.97,
      p_m1_H: 2.43,
      p_m3_L: 1.97,
      p_m3_H: 2.43,
      p_m6_L: 1.97,
      p_m6_H: 2.47,
      p_m12_L: 1.97,
      p_m12_H: 2.7,
    },
    "0307": {
      H: 0.129,
      L: 0.129,
      O: 0.129,
      C: 0.129,
      V: 0,
      p_w1_L: 0.129,
      p_w1_H: 0.129,
      p_m1_L: 0.129,
      p_m1_H: 0.129,
      p_m3_L: 0.129,
      p_m3_H: 0.129,
      p_m6_L: 0.129,
      p_m6_H: 0.129,
      p_m12_L: 0.129,
      p_m12_H: 0.129,
    },
    "0308": {
      H: 1.58,
      L: 1.52,
      O: 1.55,
      C: 1.54,
      V: 11462000,
      p_w1_L: 1.3,
      p_w1_H: 1.64,
      p_m1_L: 1.2,
      p_m1_H: 1.64,
      p_m3_L: 1.05,
      p_m3_H: 1.64,
      p_m6_L: 1.03,
      p_m6_H: 1.64,
      p_m12_L: 1.03,
      p_m12_H: 1.64,
    },
    "0309": {
      H: 0.07,
      L: 0.07,
      O: 0.07,
      C: 0.07,
      V: 0,
      p_w1_L: 0.067,
      p_w1_H: 0.075,
      p_m1_L: 0.065,
      p_m1_H: 0.085,
      p_m3_L: 0.064,
      p_m3_H: 0.089,
      p_m6_L: 0.063,
      p_m6_H: 0.091,
      p_m12_L: 0.063,
      p_m12_H: 0.097,
    },
    "0310": {
      H: 0.03,
      L: 0.03,
      O: 0.03,
      C: 0.03,
      V: 165000,
      p_w1_L: 0.03,
      p_w1_H: 0.032,
      p_m1_L: 0.028,
      p_m1_H: 0.042,
      p_m3_L: 0.028,
      p_m3_H: 0.042,
      p_m6_L: 0.028,
      p_m6_H: 0.044,
      p_m12_L: 0.019,
      p_m12_H: 0.1,
    },
    "0311": {
      H: 0.425,
      L: 0.425,
      O: 0.425,
      C: 0.425,
      V: 0,
      p_w1_L: 0.405,
      p_w1_H: 0.44,
      p_m1_L: 0.38,
      p_m1_H: 0.44,
      p_m3_L: 0.38,
      p_m3_H: 0.455,
      p_m6_L: 0.34,
      p_m6_H: 0.455,
      p_m12_L: 0.305,
      p_m12_H: 0.455,
    },
    "0312": {
      H: 0.156,
      L: 0.139,
      O: 0.139,
      C: 0.156,
      V: 54000,
      p_w1_L: 0.13,
      p_w1_H: 0.156,
      p_m1_L: 0.13,
      p_m1_H: 0.179,
      p_m3_L: 0.13,
      p_m3_H: 0.241,
      p_m6_L: 0.13,
      p_m6_H: 0.45,
      p_m12_L: 0.13,
      p_m12_H: 0.65,
    },
    "0313": {
      H: 0.011,
      L: 0.011,
      O: 0.011,
      C: 0.011,
      V: 0,
      p_w1_L: 0.01,
      p_w1_H: 0.012,
      p_m1_L: 0.01,
      p_m1_H: 0.029,
      p_m3_L: 0.01,
      p_m3_H: 0.029,
      p_m6_L: 0.01,
      p_m6_H: 0.029,
      p_m12_L: 0.01,
      p_m12_H: 0.03,
    },
    "0315": {
      H: 4.19,
      L: 4.14,
      O: 4.15,
      C: 4.15,
      V: 245241,
      p_w1_L: 4.12,
      p_w1_H: 4.2,
      p_m1_L: 4.12,
      p_m1_H: 4.35,
      p_m3_L: 4.12,
      p_m3_H: 4.85,
      p_m6_L: 4.12,
      p_m6_H: 4.85,
      p_m12_L: 4.11,
      p_m12_H: 5.12,
    },
    "0316": {
      H: 169.5,
      L: 161.8,
      O: 162,
      C: 166,
      V: 1801550,
      p_w1_L: 146.2,
      p_w1_H: 169.5,
      p_m1_L: 126.5,
      p_m1_H: 169.5,
      p_m3_L: 111.3,
      p_m3_H: 179.6,
      p_m6_L: 111.3,
      p_m6_H: 201.6,
      p_m12_L: 52.05,
      p_m12_H: 201.6,
    },
    "0317": {
      H: 6.91,
      L: 6.68,
      O: 6.75,
      C: 6.77,
      V: 1671000,
      p_w1_L: 6.6,
      p_w1_H: 6.91,
      p_m1_L: 6.41,
      p_m1_H: 7.05,
      p_m3_L: 6.4,
      p_m3_H: 8.38,
      p_m6_L: 5.65,
      p_m6_H: 8.38,
      p_m12_L: 5.65,
      p_m12_H: 10.66,
    },
    "0318": {
      H: 0.405,
      L: 0.405,
      O: 0.405,
      C: 0.405,
      V: 4500,
      p_w1_L: 0.4,
      p_w1_H: 0.425,
      p_m1_L: 0.4,
      p_m1_H: 0.53,
      p_m3_L: 0.4,
      p_m3_H: 0.53,
      p_m6_L: 0.4,
      p_m6_H: 0.61,
      p_m12_L: 0.4,
      p_m12_H: 0.85,
    },
    "0320": {
      H: 0.96,
      L: 0.89,
      O: 0.9,
      C: 0.95,
      V: 1500000,
      p_w1_L: 0.85,
      p_w1_H: 0.96,
      p_m1_L: 0.81,
      p_m1_H: 0.96,
      p_m3_L: 0.64,
      p_m3_H: 0.96,
      p_m6_L: 0.64,
      p_m6_H: 1.15,
      p_m12_L: 0.345,
      p_m12_H: 1.15,
    },
    "0321": {
      H: 1.67,
      L: 1.64,
      O: 1.64,
      C: 1.66,
      V: 152000,
      p_w1_L: 1.64,
      p_w1_H: 1.7,
      p_m1_L: 1.55,
      p_m1_H: 1.73,
      p_m3_L: 1.48,
      p_m3_H: 1.73,
      p_m6_L: 1.48,
      p_m6_H: 1.98,
      p_m12_L: 1.41,
      p_m12_H: 2.11,
    },
    "0322": {
      H: 15.26,
      L: 14.9,
      O: 15.26,
      C: 15.04,
      V: 6316830,
      p_w1_L: 14.9,
      p_w1_H: 16,
      p_m1_L: 14.88,
      p_m1_H: 16.34,
      p_m3_L: 13.5,
      p_m3_H: 16.34,
      p_m6_L: 12.76,
      p_m6_H: 16.48,
      p_m12_L: 12.72,
      p_m12_H: 16.6,
    },
    "0323": {
      H: 3.04,
      L: 2.94,
      O: 3,
      C: 3.02,
      V: 7492000,
      p_w1_L: 2.8,
      p_w1_H: 3.07,
      p_m1_L: 2.75,
      p_m1_H: 3.07,
      p_m3_L: 2.75,
      p_m3_H: 4.8,
      p_m6_L: 2.75,
      p_m6_H: 4.8,
      p_m12_L: 2.1,
      p_m12_H: 4.8,
    },
    "0326": {
      H: 0.91,
      L: 0.91,
      O: 0.91,
      C: 0.91,
      V: 120,
      p_w1_L: 0.91,
      p_w1_H: 0.97,
      p_m1_L: 0.91,
      p_m1_H: 1.12,
      p_m3_L: 0.84,
      p_m3_H: 1.38,
      p_m6_L: 0.84,
      p_m6_H: 1.38,
      p_m12_L: 0.84,
      p_m12_H: 1.55,
    },
    "0327": {
      H: 5.92,
      L: 5.71,
      O: 5.8,
      C: 5.92,
      V: 3724680,
      p_w1_L: 5.53,
      p_w1_H: 6.37,
      p_m1_L: 5.53,
      p_m1_H: 6.8,
      p_m3_L: 4.3,
      p_m3_H: 10.5,
      p_m6_L: 4.3,
      p_m6_H: 11,
      p_m12_L: 4.3,
      p_m12_H: 11,
    },
    "0328": {
      H: 0.195,
      L: 0.195,
      O: 0.195,
      C: 0.195,
      V: 0,
      p_w1_L: 0.195,
      p_w1_H: 0.215,
      p_m1_L: 0.183,
      p_m1_H: 0.22,
      p_m3_L: 0.183,
      p_m3_H: 0.285,
      p_m6_L: 0.183,
      p_m6_H: 0.29,
      p_m12_L: 0.183,
      p_m12_H: 0.29,
    },
    "0329": {
      H: 3.14,
      L: 3.03,
      O: 3.14,
      C: 3.14,
      V: 901000,
      p_w1_L: 3.01,
      p_w1_H: 3.36,
      p_m1_L: 2.51,
      p_m1_H: 4.09,
      p_m3_L: 2.51,
      p_m3_H: 4.74,
      p_m6_L: 2.51,
      p_m6_H: 4.74,
      p_m12_L: 1.2,
      p_m12_H: 4.74,
    },
    "0330": {
      H: 0.67,
      L: 0.65,
      O: 0.65,
      C: 0.67,
      V: 427500,
      p_w1_L: 0.65,
      p_w1_H: 0.68,
      p_m1_L: 0.65,
      p_m1_H: 0.73,
      p_m3_L: 0.63,
      p_m3_H: 0.82,
      p_m6_L: 0.6,
      p_m6_H: 0.87,
      p_m12_L: 0.6,
      p_m12_H: 1.33,
    },
    "0331": {
      H: 6.23,
      L: 6.14,
      O: 6.23,
      C: 6.15,
      V: 85000,
      p_w1_L: 6.07,
      p_w1_H: 6.3,
      p_m1_L: 5.91,
      p_m1_H: 6.8,
      p_m3_L: 5.69,
      p_m3_H: 7.35,
      p_m6_L: 5.24,
      p_m6_H: 8.85,
      p_m12_L: 3.34,
      p_m12_H: 8.85,
    },
    "0332": {
      H: 0.53,
      L: 0.52,
      O: 0.53,
      C: 0.53,
      V: 23683000,
      p_w1_L: 0.5,
      p_w1_H: 0.58,
      p_m1_L: 0.48,
      p_m1_H: 0.59,
      p_m3_L: 0.46,
      p_m3_H: 0.76,
      p_m6_L: 0.29,
      p_m6_H: 0.76,
      p_m12_L: 0.25,
      p_m12_H: 0.76,
    },
    "0333": {
      H: 0.495,
      L: 0.495,
      O: 0.495,
      C: 0.495,
      V: 0,
      p_w1_L: 0.495,
      p_w1_H: 0.58,
      p_m1_L: 0.495,
      p_m1_H: 0.61,
      p_m3_L: 0.495,
      p_m3_H: 0.65,
      p_m6_L: 0.495,
      p_m6_H: 0.72,
      p_m12_L: 0.495,
      p_m12_H: 0.9,
    },
    "0334": {
      H: 0.52,
      L: 0.51,
      O: 0.51,
      C: 0.52,
      V: 344000,
      p_w1_L: 0.495,
      p_w1_H: 0.61,
      p_m1_L: 0.495,
      p_m1_H: 0.61,
      p_m3_L: 0.495,
      p_m3_H: 0.67,
      p_m6_L: 0.49,
      p_m6_H: 0.92,
      p_m12_L: 0.39,
      p_m12_H: 0.92,
    },
    "0335": {
      H: 0.86,
      L: 0.74,
      O: 0.75,
      C: 0.81,
      V: 162000,
      p_w1_L: 0.74,
      p_w1_H: 0.86,
      p_m1_L: 0.74,
      p_m1_H: 0.9,
      p_m3_L: 0.74,
      p_m3_H: 0.95,
      p_m6_L: 0.74,
      p_m6_H: 0.95,
      p_m12_L: 0.74,
      p_m12_H: 1,
    },
    "0336": {
      H: 21.15,
      L: 19.86,
      O: 20.15,
      C: 20.3,
      V: 9311800,
      p_w1_L: 19.78,
      p_w1_H: 24.55,
      p_m1_L: 12.92,
      p_m1_H: 26.15,
      p_m3_L: 12.92,
      p_m3_H: 26.15,
      p_m6_L: 12.5,
      p_m6_H: 26.15,
      p_m12_L: 6.99,
      p_m12_H: 26.15,
    },
    "0337": {
      H: 1.66,
      L: 1.62,
      O: 1.66,
      C: 1.62,
      V: 514926,
      p_w1_L: 1.56,
      p_w1_H: 1.67,
      p_m1_L: 1.47,
      p_m1_H: 1.71,
      p_m3_L: 1.47,
      p_m3_H: 2.22,
      p_m6_L: 1.47,
      p_m6_H: 2.64,
      p_m12_L: 1.47,
      p_m12_H: 2.96,
    },
    "0338": {
      H: 1.86,
      L: 1.83,
      O: 1.85,
      C: 1.84,
      V: 6372000,
      p_w1_L: 1.8,
      p_w1_H: 1.9,
      p_m1_L: 1.68,
      p_m1_H: 1.98,
      p_m3_L: 1.68,
      p_m3_H: 2.21,
      p_m6_L: 1.55,
      p_m6_H: 2.25,
      p_m12_L: 1.55,
      p_m12_H: 2.25,
    },
    "0339": {
      H: 0.35,
      L: 0.35,
      O: 0.35,
      C: 0.35,
      V: 20000,
      p_w1_L: 0.345,
      p_w1_H: 0.35,
      p_m1_L: 0.305,
      p_m1_H: 0.39,
      p_m3_L: 0.305,
      p_m3_H: 0.59,
      p_m6_L: 0.305,
      p_m6_H: 0.62,
      p_m12_L: 0.186,
      p_m12_H: 0.95,
    },
    "0340": {
      H: 0.79,
      L: 0.69,
      O: 0.69,
      C: 0.72,
      V: 55000,
      p_w1_L: 0.69,
      p_w1_H: 0.82,
      p_m1_L: 0.69,
      p_m1_H: 0.82,
      p_m3_L: 0.62,
      p_m3_H: 0.88,
      p_m6_L: 0.315,
      p_m6_H: 1.2,
      p_m12_L: 0.27,
      p_m12_H: 1.2,
    },
    "0341": {
      H: 14.08,
      L: 13.74,
      O: 13.74,
      C: 14.04,
      V: 431754,
      p_w1_L: 13.52,
      p_w1_H: 14.08,
      p_m1_L: 13.52,
      p_m1_H: 14.38,
      p_m3_L: 13.5,
      p_m3_H: 14.6,
      p_m6_L: 13.5,
      p_m6_H: 17.02,
      p_m12_L: 13.5,
      p_m12_H: 18.4,
    },
    "0342": {
      H: 0.058,
      L: 0.055,
      O: 0.056,
      C: 0.056,
      V: 4102000,
      p_w1_L: 0.054,
      p_w1_H: 0.066,
      p_m1_L: 0.054,
      p_m1_H: 0.11,
      p_m3_L: 0.054,
      p_m3_H: 0.4,
      p_m6_L: 0.054,
      p_m6_H: 0.475,
      p_m12_L: 0.054,
      p_m12_H: 0.73,
    },
    "0343": {
      H: 0.73,
      L: 0.71,
      O: 0.72,
      C: 0.72,
      V: 6175000,
      p_w1_L: 0.7,
      p_w1_H: 0.75,
      p_m1_L: 0.7,
      p_m1_H: 0.88,
      p_m3_L: 0.68,
      p_m3_H: 0.88,
      p_m6_L: 0.59,
      p_m6_H: 0.88,
      p_m12_L: 0.079,
      p_m12_H: 0.88,
    },
    "0345": {
      H: 16.68,
      L: 16.06,
      O: 16.2,
      C: 16.06,
      V: 1656000,
      p_w1_L: 14.32,
      p_w1_H: 16.68,
      p_m1_L: 14.32,
      p_m1_H: 18.56,
      p_m3_L: 14.32,
      p_m3_H: 20.95,
      p_m6_L: 14.32,
      p_m6_H: 30.6,
      p_m12_L: 14.32,
      p_m12_H: 36.6,
    },
    "0346": {
      H: 0.065,
      L: 0.06,
      O: 0.06,
      C: 0.065,
      V: 700000,
      p_w1_L: 0.058,
      p_w1_H: 0.066,
      p_m1_L: 0.058,
      p_m1_H: 0.074,
      p_m3_L: 0.058,
      p_m3_H: 0.105,
      p_m6_L: 0.05,
      p_m6_H: 0.105,
      p_m12_L: 0.031,
      p_m12_H: 0.135,
    },
    "0347": {
      H: 3.83,
      L: 3.71,
      O: 3.77,
      C: 3.76,
      V: 9831400,
      p_w1_L: 3.43,
      p_w1_H: 3.83,
      p_m1_L: 3.43,
      p_m1_H: 4,
      p_m3_L: 3.43,
      p_m3_H: 6.83,
      p_m6_L: 3.43,
      p_m6_H: 6.83,
      p_m12_L: 2.98,
      p_m12_H: 6.83,
    },
    "0348": {
      H: 0.29,
      L: 0.29,
      O: 0.29,
      C: 0.29,
      V: 100200,
      p_w1_L: 0.229,
      p_w1_H: 0.32,
      p_m1_L: 0.225,
      p_m1_H: 0.32,
      p_m3_L: 0.203,
      p_m3_H: 0.35,
      p_m6_L: 0.203,
      p_m6_H: 0.35,
      p_m12_L: 0.203,
      p_m12_H: 0.395,
    },
    "0351": {
      H: 0.45,
      L: 0.42,
      O: 0.45,
      C: 0.445,
      V: 2290560,
      p_w1_L: 0.415,
      p_w1_H: 0.45,
      p_m1_L: 0.415,
      p_m1_H: 0.45,
      p_m3_L: 0.4,
      p_m3_H: 0.485,
      p_m6_L: 0.365,
      p_m6_H: 0.54,
      p_m12_L: 0.27,
      p_m12_H: 0.59,
    },
    "0352": {
      H: 0.495,
      L: 0.495,
      O: 0.495,
      C: 0.495,
      V: 0,
      p_w1_L: 0.495,
      p_w1_H: 0.495,
      p_m1_L: 0.495,
      p_m1_H: 0.61,
      p_m3_L: 0.495,
      p_m3_H: 0.77,
      p_m6_L: 0.495,
      p_m6_H: 1.15,
      p_m12_L: 0.47,
      p_m12_H: 1.15,
    },
    "0353": {
      H: 0.071,
      L: 0.071,
      O: 0.071,
      C: 0.071,
      V: 0,
      p_w1_L: 0.071,
      p_w1_H: 0.071,
      p_m1_L: 0.071,
      p_m1_H: 0.071,
      p_m3_L: 0.071,
      p_m3_H: 0.071,
      p_m6_L: 0.064,
      p_m6_H: 0.073,
      p_m12_L: 0.057,
      p_m12_H: 0.136,
    },
    "0354": {
      H: 12.58,
      L: 12.3,
      O: 12.5,
      C: 12.44,
      V: 9668000,
      p_w1_L: 11.74,
      p_w1_H: 13.22,
      p_m1_L: 11.74,
      p_m1_H: 14.16,
      p_m3_L: 11.38,
      p_m3_H: 14.78,
      p_m6_L: 11,
      p_m6_H: 16.28,
      p_m12_L: 6.85,
      p_m12_H: 16.28,
    },
    "0355": {
      H: 0.435,
      L: 0.435,
      O: 0.435,
      C: 0.435,
      V: 24000,
      p_w1_L: 0.42,
      p_w1_H: 0.435,
      p_m1_L: 0.42,
      p_m1_H: 0.44,
      p_m3_L: 0.42,
      p_m3_H: 0.48,
      p_m6_L: 0.41,
      p_m6_H: 0.48,
      p_m12_L: 0.39,
      p_m12_H: 0.48,
    },
    "0356": {
      H: 0.045,
      L: 0.041,
      O: 0.041,
      C: 0.045,
      V: 240000,
      p_w1_L: 0.041,
      p_w1_H: 0.045,
      p_m1_L: 0.038,
      p_m1_H: 0.058,
      p_m3_L: 0.036,
      p_m3_H: 0.065,
      p_m6_L: 0.035,
      p_m6_H: 0.065,
      p_m12_L: 0.035,
      p_m12_H: 0.068,
    },
    "0357": {
      H: 26.35,
      L: 24.55,
      O: 24.55,
      C: 26.2,
      V: 354100,
      p_w1_L: 23.05,
      p_w1_H: 26.35,
      p_m1_L: 23.05,
      p_m1_H: 28.35,
      p_m3_L: 23.05,
      p_m3_H: 34.4,
      p_m6_L: 21.65,
      p_m6_H: 35.85,
      p_m12_L: 21.65,
      p_m12_H: 49.45,
    },
    "0358": {
      H: 13.08,
      L: 12.7,
      O: 12.74,
      C: 12.96,
      V: 7435120,
      p_w1_L: 12.14,
      p_w1_H: 13.08,
      p_m1_L: 12.14,
      p_m1_H: 13.86,
      p_m3_L: 12.14,
      p_m3_H: 18.2,
      p_m6_L: 12.14,
      p_m6_H: 18.2,
      p_m12_L: 11.62,
      p_m12_H: 23.7,
    },
    "0359": {
      H: 0.071,
      L: 0.066,
      O: 0.066,
      C: 0.068,
      V: 272000,
      p_w1_L: 0.06,
      p_w1_H: 0.075,
      p_m1_L: 0.06,
      p_m1_H: 0.082,
      p_m3_L: 0.06,
      p_m3_H: 0.088,
      p_m6_L: 0.06,
      p_m6_H: 0.099,
      p_m12_L: 0.06,
      p_m12_H: 0.19,
    },
    "0360": {
      H: 0.07,
      L: 0.066,
      O: 0.066,
      C: 0.069,
      V: 712000,
      p_w1_L: 0.065,
      p_w1_H: 0.105,
      p_m1_L: 0.065,
      p_m1_H: 0.12,
      p_m3_L: 0.065,
      p_m3_H: 0.12,
      p_m6_L: 0.065,
      p_m6_H: 0.132,
      p_m12_L: 0.049,
      p_m12_H: 0.16,
    },
    "0361": {
      H: 0.154,
      L: 0.154,
      O: 0.154,
      C: 0.154,
      V: 0,
      p_w1_L: 0.135,
      p_w1_H: 0.173,
      p_m1_L: 0.09,
      p_m1_H: 0.228,
      p_m3_L: 0.09,
      p_m3_H: 0.228,
      p_m6_L: 0.09,
      p_m6_H: 0.295,
      p_m12_L: 0.028,
      p_m12_H: 0.34,
    },
    "0362": {
      H: 0.103,
      L: 0.1,
      O: 0.101,
      C: 0.101,
      V: 970000,
      p_w1_L: 0.098,
      p_w1_H: 0.109,
      p_m1_L: 0.098,
      p_m1_H: 0.154,
      p_m3_L: 0.098,
      p_m3_H: 0.25,
      p_m6_L: 0.098,
      p_m6_H: 0.315,
      p_m12_L: 0.098,
      p_m12_H: 0.63,
    },
    "0363": {
      H: 11.54,
      L: 11.4,
      O: 11.54,
      C: 11.44,
      V: 269115,
      p_w1_L: 11.18,
      p_w1_H: 11.72,
      p_m1_L: 11.08,
      p_m1_H: 11.72,
      p_m3_L: 11.06,
      p_m3_H: 13.14,
      p_m6_L: 11.02,
      p_m6_H: 13.16,
      p_m12_L: 10.32,
      p_m12_H: 13.16,
    },
    "0365": {
      H: 0.78,
      L: 0.76,
      O: 0.76,
      C: 0.76,
      V: 30000,
      p_w1_L: 0.72,
      p_w1_H: 0.8,
      p_m1_L: 0.69,
      p_m1_H: 0.93,
      p_m3_L: 0.69,
      p_m3_H: 0.95,
      p_m6_L: 0.69,
      p_m6_H: 1.19,
      p_m12_L: 0.69,
      p_m12_H: 1.72,
    },
    "0366": {
      H: 1.28,
      L: 1.28,
      O: 1.28,
      C: 1.28,
      V: 0,
      p_w1_L: 1.28,
      p_w1_H: 1.29,
      p_m1_L: 1.24,
      p_m1_H: 1.33,
      p_m3_L: 1.24,
      p_m3_H: 1.4,
      p_m6_L: 1.24,
      p_m6_H: 1.7,
      p_m12_L: 1.21,
      p_m12_H: 1.7,
    },
    "0367": {
      H: 0.88,
      L: 0.88,
      O: 0.88,
      C: 0.88,
      V: 0,
      p_w1_L: 0.85,
      p_w1_H: 0.88,
      p_m1_L: 0.85,
      p_m1_H: 0.91,
      p_m3_L: 0.8,
      p_m3_H: 0.94,
      p_m6_L: 0.8,
      p_m6_H: 1.08,
      p_m12_L: 0.8,
      p_m12_H: 1.19,
    },
    "0368": {
      H: 0.48,
      L: 0.48,
      O: 0.48,
      C: 0.48,
      V: 40000,
      p_w1_L: 0.475,
      p_w1_H: 0.5,
      p_m1_L: 0.475,
      p_m1_H: 0.51,
      p_m3_L: 0.455,
      p_m3_H: 0.55,
      p_m6_L: 0.455,
      p_m6_H: 0.7,
      p_m12_L: 0.455,
      p_m12_H: 0.95,
    },
    "0369": {
      H: 4.2,
      L: 4.15,
      O: 4.15,
      C: 4.2,
      V: 26000,
      p_w1_L: 4.11,
      p_w1_H: 4.49,
      p_m1_L: 4.11,
      p_m1_H: 4.5,
      p_m3_L: 4.03,
      p_m3_H: 4.5,
      p_m6_L: 4.03,
      p_m6_H: 4.79,
      p_m12_L: 3.48,
      p_m12_H: 4.89,
    },
    "0370": {
      H: 0.44,
      L: 0.425,
      O: 0.435,
      C: 0.44,
      V: 1894000,
      p_w1_L: 0.415,
      p_w1_H: 0.46,
      p_m1_L: 0.4,
      p_m1_H: 0.475,
      p_m3_L: 0.335,
      p_m3_H: 0.475,
      p_m6_L: 0.19,
      p_m6_H: 0.475,
      p_m12_L: 0.19,
      p_m12_H: 0.802,
    },
    "0371": {
      H: 2.96,
      L: 2.92,
      O: 2.93,
      C: 2.95,
      V: 7504950,
      p_w1_L: 2.84,
      p_w1_H: 2.97,
      p_m1_L: 2.79,
      p_m1_H: 2.97,
      p_m3_L: 2.79,
      p_m3_H: 3.6,
      p_m6_L: 2.74,
      p_m6_H: 3.6,
      p_m12_L: 2.74,
      p_m12_H: 3.6,
    },
    "0372": {
      H: 0.275,
      L: 0.275,
      O: 0.275,
      C: 0.275,
      V: 0,
      p_w1_L: 0.26,
      p_w1_H: 0.285,
      p_m1_L: 0.26,
      p_m1_H: 0.36,
      p_m3_L: 0.25,
      p_m3_H: 0.36,
      p_m6_L: 0.25,
      p_m6_H: 0.385,
      p_m12_L: 0.184,
      p_m12_H: 0.43,
    },
    "0373": {
      H: 2.76,
      L: 2.76,
      O: 2.76,
      C: 2.76,
      V: 0,
      p_w1_L: 2.74,
      p_w1_H: 2.78,
      p_m1_L: 2.62,
      p_m1_H: 2.93,
      p_m3_L: 2.62,
      p_m3_H: 3.08,
      p_m6_L: 2.62,
      p_m6_H: 3.4,
      p_m12_L: 2.62,
      p_m12_H: 3.49,
    },
    "0374": {
      H: 2.99,
      L: 2.99,
      O: 2.99,
      C: 2.99,
      V: 0,
      p_w1_L: 2.78,
      p_w1_H: 2.99,
      p_m1_L: 2.78,
      p_m1_H: 3.4,
      p_m3_L: 2.77,
      p_m3_H: 3.4,
      p_m6_L: 2.6,
      p_m6_H: 3.4,
      p_m12_L: 2.4,
      p_m12_H: 3.4,
    },
    "0375": {
      H: 2.1,
      L: 2.1,
      O: 2.1,
      C: 2.1,
      V: 6000,
      p_w1_L: 2,
      p_w1_H: 2.1,
      p_m1_L: 1.78,
      p_m1_H: 2.1,
      p_m3_L: 1.75,
      p_m3_H: 2.41,
      p_m6_L: 1.75,
      p_m6_H: 2.41,
      p_m12_L: 1.75,
      p_m12_H: 2.41,
    },
    "0376": {
      H: 1.65,
      L: 1.57,
      O: 1.63,
      C: 1.58,
      V: 362000,
      p_w1_L: 1.54,
      p_w1_H: 1.8,
      p_m1_L: 1.54,
      p_m1_H: 2.05,
      p_m3_L: 1.54,
      p_m3_H: 2.28,
      p_m6_L: 1.54,
      p_m6_H: 3.18,
      p_m12_L: 1.54,
      p_m12_H: 3.52,
    },
    "0377": {
      H: 2.33,
      L: 2.33,
      O: 2.33,
      C: 2.33,
      V: 0,
      p_w1_L: 2.31,
      p_w1_H: 2.8,
      p_m1_L: 2.31,
      p_m1_H: 2.81,
      p_m3_L: 2.31,
      p_m3_H: 4.37,
      p_m6_L: 2.31,
      p_m6_H: 5.7,
      p_m12_L: 2.31,
      p_m12_H: 10.62,
    },
    "0378": {
      H: 0.018,
      L: 0.018,
      O: 0.018,
      C: 0.018,
      V: 0,
      p_w1_L: 0.018,
      p_w1_H: 0.018,
      p_m1_L: 0.018,
      p_m1_H: 0.018,
      p_m3_L: 0.018,
      p_m3_H: 0.018,
      p_m6_L: 0.01,
      p_m6_H: 0.03,
      p_m12_L: 0.01,
      p_m12_H: 0.074,
    },
    "0379": {
      H: 0.113,
      L: 0.095,
      O: 0.095,
      C: 0.113,
      V: 1039000,
      p_w1_L: 0.094,
      p_w1_H: 0.113,
      p_m1_L: 0.094,
      p_m1_H: 0.12,
      p_m3_L: 0.092,
      p_m3_H: 0.13,
      p_m6_L: 0.092,
      p_m6_H: 0.175,
      p_m12_L: 0.092,
      p_m12_H: 0.2,
    },
    "0380": {
      H: 0.114,
      L: 0.114,
      O: 0.114,
      C: 0.114,
      V: 0,
      p_w1_L: 0.112,
      p_w1_H: 0.115,
      p_m1_L: 0.104,
      p_m1_H: 0.124,
      p_m3_L: 0.095,
      p_m3_H: 0.13,
      p_m6_L: 0.095,
      p_m6_H: 0.14,
      p_m12_L: 0.075,
      p_m12_H: 0.148,
    },
    "0381": {
      H: 0.067,
      L: 0.06,
      O: 0.067,
      C: 0.064,
      V: 2670600,
      p_w1_L: 0.044,
      p_w1_H: 0.079,
      p_m1_L: 0.044,
      p_m1_H: 0.079,
      p_m3_L: 0.044,
      p_m3_H: 0.079,
      p_m6_L: 0.041,
      p_m6_H: 0.095,
      p_m12_L: 0.041,
      p_m12_H: 0.33,
    },
    "0382": {
      H: 4.89,
      L: 4.65,
      O: 4.71,
      C: 4.65,
      V: 1498000,
      p_w1_L: 4.63,
      p_w1_H: 5.2,
      p_m1_L: 4.31,
      p_m1_H: 5.75,
      p_m3_L: 4.3,
      p_m3_H: 5.9,
      p_m6_L: 4.3,
      p_m6_H: 8.2,
      p_m12_L: 4.3,
      p_m12_H: 10.68,
    },
    "0383": {
      H: 0.083,
      L: 0.083,
      O: 0.083,
      C: 0.083,
      V: 160000,
      p_w1_L: 0.077,
      p_w1_H: 0.085,
      p_m1_L: 0.076,
      p_m1_H: 0.096,
      p_m3_L: 0.076,
      p_m3_H: 0.11,
      p_m6_L: 0.076,
      p_m6_H: 0.122,
      p_m12_L: 0.076,
      p_m12_H: 0.134,
    },
    "0384": {
      H: 14.88,
      L: 14.46,
      O: 14.8,
      C: 14.68,
      V: 6682330,
      p_w1_L: 12.98,
      p_w1_H: 14.92,
      p_m1_L: 12.98,
      p_m1_H: 18.64,
      p_m3_L: 12.98,
      p_m3_H: 23.05,
      p_m6_L: 12.98,
      p_m6_H: 29.95,
      p_m12_L: 12.98,
      p_m12_H: 35,
    },
    "0385": {
      H: 0.9,
      L: 0.9,
      O: 0.9,
      C: 0.9,
      V: 0,
      p_w1_L: 0.87,
      p_w1_H: 0.9,
      p_m1_L: 0.87,
      p_m1_H: 0.97,
      p_m3_L: 0.87,
      p_m3_H: 0.97,
      p_m6_L: 0.87,
      p_m6_H: 1.3,
      p_m12_L: 0.87,
      p_m12_H: 1.34,
    },
    "0386": {
      H: 3.75,
      L: 3.68,
      O: 3.75,
      C: 3.73,
      V: 71892300,
      p_w1_L: 3.42,
      p_w1_H: 3.75,
      p_m1_L: 3.41,
      p_m1_H: 3.86,
      p_m3_L: 3.41,
      p_m3_H: 4.09,
      p_m6_L: 3.4,
      p_m6_H: 4.29,
      p_m12_L: 3.32,
      p_m12_H: 4.69,
    },
    "0387": {
      H: 0.81,
      L: 0.8,
      O: 0.81,
      C: 0.8,
      V: 24000,
      p_w1_L: 0.8,
      p_w1_H: 0.86,
      p_m1_L: 0.73,
      p_m1_H: 0.97,
      p_m3_L: 0.73,
      p_m3_H: 0.99,
      p_m6_L: 0.73,
      p_m6_H: 1.09,
      p_m12_L: 0.59,
      p_m12_H: 1.1,
    },
    "0388": {
      H: 475,
      L: 468.4,
      O: 475,
      C: 471.2,
      V: 3482680,
      p_w1_L: 426.2,
      p_w1_H: 475,
      p_m1_L: 426.2,
      p_m1_H: 475,
      p_m3_L: 426.2,
      p_m3_H: 515,
      p_m6_L: 426.2,
      p_m6_H: 544,
      p_m12_L: 379.4,
      p_m12_H: 587,
    },
    "0389": {
      H: 0.098,
      L: 0.094,
      O: 0.096,
      C: 0.094,
      V: 1266000,
      p_w1_L: 0.094,
      p_w1_H: 0.1,
      p_m1_L: 0.092,
      p_m1_H: 0.116,
      p_m3_L: 0.082,
      p_m3_H: 0.129,
      p_m6_L: 0.068,
      p_m6_H: 0.129,
      p_m12_L: 0.057,
      p_m12_H: 0.129,
    },
    "0390": {
      H: 4.07,
      L: 3.97,
      O: 4.01,
      C: 4.01,
      V: 16555000,
      p_w1_L: 3.68,
      p_w1_H: 4.07,
      p_m1_L: 3.64,
      p_m1_H: 4.07,
      p_m3_L: 3.61,
      p_m3_H: 4.7,
      p_m6_L: 3.53,
      p_m6_H: 4.7,
      p_m12_L: 3.33,
      p_m12_H: 4.7,
    },
    "0391": {
      H: 0.091,
      L: 0.091,
      O: 0.091,
      C: 0.091,
      V: 20000,
      p_w1_L: 0.09,
      p_w1_H: 0.098,
      p_m1_L: 0.09,
      p_m1_H: 0.099,
      p_m3_L: 0.09,
      p_m3_H: 0.103,
      p_m6_L: 0.089,
      p_m6_H: 0.111,
      p_m12_L: 0.083,
      p_m12_H: 0.133,
    },
    "0392": {
      H: 25.8,
      L: 25.45,
      O: 25.6,
      C: 25.8,
      V: 1401060,
      p_w1_L: 25.05,
      p_w1_H: 27.25,
      p_m1_L: 25.05,
      p_m1_H: 28.55,
      p_m3_L: 25.05,
      p_m3_H: 32.9,
      p_m6_L: 23.65,
      p_m6_H: 32.9,
      p_m12_L: 23.65,
      p_m12_H: 32.9,
    },
    "0393": {
      H: 0.85,
      L: 0.84,
      O: 0.84,
      C: 0.85,
      V: 28000,
      p_w1_L: 0.83,
      p_w1_H: 0.86,
      p_m1_L: 0.8,
      p_m1_H: 0.86,
      p_m3_L: 0.79,
      p_m3_H: 0.86,
      p_m6_L: 0.73,
      p_m6_H: 0.9,
      p_m12_L: 0.73,
      p_m12_H: 0.9,
    },
    "0395": {
      H: 0.016,
      L: 0.016,
      O: 0.016,
      C: 0.016,
      V: 0,
      p_w1_L: 0.016,
      p_w1_H: 0.016,
      p_m1_L: 0.016,
      p_m1_H: 0.016,
      p_m3_L: 0.016,
      p_m3_H: 0.016,
      p_m6_L: 0.016,
      p_m6_H: 0.016,
      p_m12_L: 0.015,
      p_m12_H: 0.054,
    },
    "0396": {
      H: 0.162,
      L: 0.15,
      O: 0.15,
      C: 0.162,
      V: 212000,
      p_w1_L: 0.15,
      p_w1_H: 0.186,
      p_m1_L: 0.15,
      p_m1_H: 0.395,
      p_m3_L: 0.126,
      p_m3_H: 0.395,
      p_m6_L: 0.111,
      p_m6_H: 0.395,
      p_m12_L: 0.085,
      p_m12_H: 0.395,
    },
    "0397": {
      H: 0.168,
      L: 0.159,
      O: 0.164,
      C: 0.168,
      V: 22030000,
      p_w1_L: 0.159,
      p_w1_H: 0.179,
      p_m1_L: 0.151,
      p_m1_H: 0.199,
      p_m3_L: 0.098,
      p_m3_H: 0.219,
      p_m6_L: 0.086,
      p_m6_H: 0.219,
      p_m12_L: 0.06,
      p_m12_H: 0.219,
    },
    "0398": {
      H: 4.79,
      L: 4.58,
      O: 4.79,
      C: 4.66,
      V: 2656000,
      p_w1_L: 4.54,
      p_w1_H: 5.42,
      p_m1_L: 4.47,
      p_m1_H: 5.42,
      p_m3_L: 3.1,
      p_m3_H: 5.42,
      p_m6_L: 3.1,
      p_m6_H: 5.42,
      p_m12_L: 2.46,
      p_m12_H: 5.42,
    },
    "0399": {
      H: 0.178,
      L: 0.169,
      O: 0.178,
      C: 0.173,
      V: 1120000,
      p_w1_L: 0.169,
      p_w1_H: 0.183,
      p_m1_L: 0.169,
      p_m1_H: 0.22,
      p_m3_L: 0.169,
      p_m3_H: 0.222,
      p_m6_L: 0.169,
      p_m6_H: 0.28,
      p_m12_L: 0.169,
      p_m12_H: 0.29,
    },
    "0400": {
      H: 3.14,
      L: 2.93,
      O: 2.97,
      C: 2.97,
      V: 5902000,
      p_w1_L: 2.82,
      p_w1_H: 3.14,
      p_m1_L: 2.81,
      p_m1_H: 3.93,
      p_m3_L: 2.34,
      p_m3_H: 3.93,
      p_m6_L: 2.01,
      p_m6_H: 3.93,
      p_m12_L: 1.32,
      p_m12_H: 4.1,
    },
    "0401": {
      H: 0.141,
      L: 0.141,
      O: 0.141,
      C: 0.141,
      V: 0,
      p_w1_L: 0.135,
      p_w1_H: 0.143,
      p_m1_L: 0.134,
      p_m1_H: 0.183,
      p_m3_L: 0.11,
      p_m3_H: 0.205,
      p_m6_L: 0.105,
      p_m6_H: 0.205,
      p_m12_L: 0.1,
      p_m12_H: 0.211,
    },
    "0403": {
      H: 0.445,
      L: 0.43,
      O: 0.445,
      C: 0.445,
      V: 3026000,
      p_w1_L: 0.43,
      p_w1_H: 0.46,
      p_m1_L: 0.35,
      p_m1_H: 0.49,
      p_m3_L: 0.345,
      p_m3_H: 0.49,
      p_m6_L: 0.305,
      p_m6_H: 0.49,
      p_m12_L: 0.26,
      p_m12_H: 0.49,
    },
    "0405": {
      H: 3.33,
      L: 3.3,
      O: 3.33,
      C: 3.32,
      V: 1827600,
      p_w1_L: 3.3,
      p_w1_H: 3.36,
      p_m1_L: 3.3,
      p_m1_H: 3.4,
      p_m3_L: 3.28,
      p_m3_H: 4.04,
      p_m6_L: 3.28,
      p_m6_H: 4.2,
      p_m12_L: 3.28,
      p_m12_H: 4.2,
    },
    "0406": {
      H: 1.43,
      L: 1.43,
      O: 1.43,
      C: 1.43,
      V: 0,
      p_w1_L: 1.32,
      p_w1_H: 1.44,
      p_m1_L: 1.32,
      p_m1_H: 1.48,
      p_m3_L: 1.31,
      p_m3_H: 1.5,
      p_m6_L: 1.25,
      p_m6_H: 1.5,
      p_m12_L: 0.9,
      p_m12_H: 1.5,
    },
    "0408": {
      H: 4.22,
      L: 4.16,
      O: 4.22,
      C: 4.21,
      V: 118000,
      p_w1_L: 3.8,
      p_w1_H: 4.22,
      p_m1_L: 3.8,
      p_m1_H: 4.65,
      p_m3_L: 3.8,
      p_m3_H: 5.41,
      p_m6_L: 3.8,
      p_m6_H: 5.76,
      p_m12_L: 2.84,
      p_m12_H: 5.76,
    },
    "0410": {
      H: 1.83,
      L: 1.78,
      O: 1.8,
      C: 1.8,
      V: 7063500,
      p_w1_L: 1.73,
      p_w1_H: 1.83,
      p_m1_L: 1.73,
      p_m1_H: 1.94,
      p_m3_L: 1.73,
      p_m3_H: 3.87,
      p_m6_L: 1.73,
      p_m6_H: 4.78,
      p_m12_L: 1.73,
      p_m12_H: 4.78,
    },
    "0411": {
      H: 15.5,
      L: 15.4,
      O: 15.5,
      C: 15.4,
      V: 10000,
      p_w1_L: 15.3,
      p_w1_H: 15.6,
      p_m1_L: 15.3,
      p_m1_H: 16.2,
      p_m3_L: 14.9,
      p_m3_H: 16.2,
      p_m6_L: 14.58,
      p_m6_H: 16.2,
      p_m12_L: 12.8,
      p_m12_H: 16.54,
    },
    "0412": {
      H: 0.75,
      L: 0.72,
      O: 0.74,
      C: 0.75,
      V: 18742300,
      p_w1_L: 0.7,
      p_w1_H: 0.79,
      p_m1_L: 0.49,
      p_m1_H: 0.82,
      p_m3_L: 0.47,
      p_m3_H: 0.82,
      p_m6_L: 0.39,
      p_m6_H: 0.82,
      p_m12_L: 0.34,
      p_m12_H: 0.88,
    },
    "0413": {
      H: 0.089,
      L: 0.089,
      O: 0.089,
      C: 0.089,
      V: 0,
      p_w1_L: 0.086,
      p_w1_H: 0.089,
      p_m1_L: 0.083,
      p_m1_H: 0.09,
      p_m3_L: 0.083,
      p_m3_H: 0.1,
      p_m6_L: 0.083,
      p_m6_H: 0.12,
      p_m12_L: 0.083,
      p_m12_H: 0.15,
    },
    "0416": {
      H: 1.84,
      L: 1.84,
      O: 1.84,
      C: 1.84,
      V: 0,
      p_w1_L: 1.84,
      p_w1_H: 1.85,
      p_m1_L: 1.77,
      p_m1_H: 1.85,
      p_m3_L: 1.77,
      p_m3_H: 1.86,
      p_m6_L: 1.6,
      p_m6_H: 1.91,
      p_m12_L: 1.6,
      p_m12_H: 2.68,
    },
    "0417": {
      H: 1.1,
      L: 1.1,
      O: 1.1,
      C: 1.1,
      V: 0,
      p_w1_L: 1.1,
      p_w1_H: 1.12,
      p_m1_L: 1.02,
      p_m1_H: 1.15,
      p_m3_L: 0.99,
      p_m3_H: 1.19,
      p_m6_L: 0.95,
      p_m6_H: 1.42,
      p_m12_L: 0.73,
      p_m12_H: 1.42,
    },
    "0418": {
      H: 0.91,
      L: 0.87,
      O: 0.88,
      C: 0.89,
      V: 1251760,
      p_w1_L: 0.8,
      p_w1_H: 0.91,
      p_m1_L: 0.8,
      p_m1_H: 0.97,
      p_m3_L: 0.62,
      p_m3_H: 1.1,
      p_m6_L: 0.55,
      p_m6_H: 1.1,
      p_m12_L: 0.35,
      p_m12_H: 1.1,
    },
    "0419": {
      H: 0.242,
      L: 0.23,
      O: 0.238,
      C: 0.232,
      V: 8260000,
      p_w1_L: 0.224,
      p_w1_H: 0.26,
      p_m1_L: 0.148,
      p_m1_H: 0.285,
      p_m3_L: 0.148,
      p_m3_H: 0.285,
      p_m6_L: 0.148,
      p_m6_H: 0.385,
      p_m12_L: 0.143,
      p_m12_H: 0.73,
    },
    "0420": {
      H: 1.34,
      L: 1.34,
      O: 1.34,
      C: 1.34,
      V: 0,
      p_w1_L: 1.33,
      p_w1_H: 1.36,
      p_m1_L: 1.29,
      p_m1_H: 1.37,
      p_m3_L: 1.29,
      p_m3_H: 1.4,
      p_m6_L: 1.18,
      p_m6_H: 1.48,
      p_m12_L: 0.89,
      p_m12_H: 1.48,
    },
    "0422": {
      H: 0.26,
      L: 0.26,
      O: 0.26,
      C: 0.26,
      V: 0,
      p_w1_L: 0.25,
      p_w1_H: 0.27,
      p_m1_L: 0.25,
      p_m1_H: 0.315,
      p_m3_L: 0.245,
      p_m3_H: 0.315,
      p_m6_L: 0.245,
      p_m6_H: 0.375,
      p_m12_L: 0.219,
      p_m12_H: 0.375,
    },
    "0423": {
      H: 1.28,
      L: 1.28,
      O: 1.28,
      C: 1.28,
      V: 0,
      p_w1_L: 1.25,
      p_w1_H: 1.38,
      p_m1_L: 1.21,
      p_m1_H: 1.38,
      p_m3_L: 1.12,
      p_m3_H: 1.38,
      p_m6_L: 1.1,
      p_m6_H: 1.38,
      p_m12_L: 0.97,
      p_m12_H: 1.38,
    },
    "0425": {
      H: 36.35,
      L: 34.45,
      O: 36.35,
      C: 35.2,
      V: 1118950,
      p_w1_L: 33.65,
      p_w1_H: 36.7,
      p_m1_L: 31,
      p_m1_H: 38.55,
      p_m3_L: 25.85,
      p_m3_H: 38.55,
      p_m6_L: 25.85,
      p_m6_H: 38.55,
      p_m12_L: 25.85,
      p_m12_H: 45.4,
    },
    "0426": {
      H: 0.185,
      L: 0.17,
      O: 0.18,
      C: 0.185,
      V: 294000,
      p_w1_L: 0.17,
      p_w1_H: 0.185,
      p_m1_L: 0.17,
      p_m1_H: 0.196,
      p_m3_L: 0.17,
      p_m3_H: 0.2,
      p_m6_L: 0.152,
      p_m6_H: 0.238,
      p_m12_L: 0.07,
      p_m12_H: 0.238,
    },
    "0428": {
      H: 0.295,
      L: 0.295,
      O: 0.295,
      C: 0.295,
      V: 0,
      p_w1_L: 0.295,
      p_w1_H: 0.295,
      p_m1_L: 0.28,
      p_m1_H: 0.305,
      p_m3_L: 0.24,
      p_m3_H: 0.335,
      p_m6_L: 0.218,
      p_m6_H: 0.35,
      p_m12_L: 0.218,
      p_m12_H: 0.36,
    },
    "0430": {
      H: 0.41,
      L: 0.41,
      O: 0.41,
      C: 0.41,
      V: 0,
      p_w1_L: 0.41,
      p_w1_H: 0.41,
      p_m1_L: 0.385,
      p_m1_H: 0.44,
      p_m3_L: 0.385,
      p_m3_H: 0.495,
      p_m6_L: 0.385,
      p_m6_H: 0.64,
      p_m12_L: 0.072,
      p_m12_H: 1,
    },
    "0431": {
      H: 0.205,
      L: 0.202,
      O: 0.204,
      C: 0.202,
      V: 960000,
      p_w1_L: 0.188,
      p_w1_H: 0.21,
      p_m1_L: 0.181,
      p_m1_H: 0.21,
      p_m3_L: 0.177,
      p_m3_H: 0.21,
      p_m6_L: 0.177,
      p_m6_H: 0.225,
      p_m12_L: 0.177,
      p_m12_H: 0.241,
    },
    "0432": {
      H: 0.6,
      L: 0.58,
      O: 0.58,
      C: 0.59,
      V: 510597,
      p_w1_L: 0.58,
      p_w1_H: 0.6,
      p_m1_L: 0.58,
      p_m1_H: 0.64,
      p_m3_L: 0.58,
      p_m3_H: 0.68,
      p_m6_L: 0.58,
      p_m6_H: 0.78,
      p_m12_L: 0.58,
      p_m12_H: 0.946,
    },
    "0433": {
      H: 0.013,
      L: 0.013,
      O: 0.013,
      C: 0.013,
      V: 0,
      p_w1_L: 0.013,
      p_w1_H: 0.013,
      p_m1_L: 0.013,
      p_m1_H: 0.013,
      p_m3_L: 0.013,
      p_m3_H: 0.013,
      p_m6_L: 0.013,
      p_m6_H: 0.013,
      p_m12_L: 0.01,
      p_m12_H: 0.03,
    },
    "0434": {
      H: 0.52,
      L: 0.49,
      O: 0.495,
      C: 0.51,
      V: 91000,
      p_w1_L: 0.49,
      p_w1_H: 0.52,
      p_m1_L: 0.48,
      p_m1_H: 0.53,
      p_m3_L: 0.45,
      p_m3_H: 0.6,
      p_m6_L: 0.415,
      p_m6_H: 0.61,
      p_m12_L: 0.415,
      p_m12_H: 0.97,
    },
    "0435": {
      H: 4.4,
      L: 4.28,
      O: 4.39,
      C: 4.33,
      V: 941528,
      p_w1_L: 4.24,
      p_w1_H: 4.48,
      p_m1_L: 4.24,
      p_m1_H: 4.59,
      p_m3_L: 4.24,
      p_m3_H: 5.04,
      p_m6_L: 4.24,
      p_m6_H: 5.07,
      p_m12_L: 3.68,
      p_m12_H: 5.07,
    },
    "0436": {
      H: 0.325,
      L: 0.325,
      O: 0.325,
      C: 0.325,
      V: 80000,
      p_w1_L: 0.325,
      p_w1_H: 0.335,
      p_m1_L: 0.315,
      p_m1_H: 0.345,
      p_m3_L: 0.26,
      p_m3_H: 0.345,
      p_m6_L: 0.243,
      p_m6_H: 0.345,
      p_m12_L: 0.24,
      p_m12_H: 0.345,
    },
    "0438": {
      H: 20.7,
      L: 20.15,
      O: 20.15,
      C: 20.35,
      V: 30400,
      p_w1_L: 20,
      p_w1_H: 21.5,
      p_m1_L: 19.4,
      p_m1_H: 22.6,
      p_m3_L: 19.4,
      p_m3_H: 33.5,
      p_m6_L: 19.4,
      p_m6_H: 37,
      p_m12_L: 17,
      p_m12_H: 56,
    },
    "0439": {
      H: 0.233,
      L: 0.212,
      O: 0.212,
      C: 0.218,
      V: 30503000,
      p_w1_L: 0.202,
      p_w1_H: 0.233,
      p_m1_L: 0.192,
      p_m1_H: 0.235,
      p_m3_L: 0.192,
      p_m3_H: 0.265,
      p_m6_L: 0.192,
      p_m6_H: 0.315,
      p_m12_L: 0.192,
      p_m12_H: 0.75,
    },
    "0440": {
      H: 23.6,
      L: 23.2,
      O: 23.25,
      C: 23.35,
      V: 439900,
      p_w1_L: 23.15,
      p_w1_H: 23.85,
      p_m1_L: 23.05,
      p_m1_H: 26.05,
      p_m3_L: 22.85,
      p_m3_H: 27.75,
      p_m6_L: 22.85,
      p_m6_H: 33.5,
      p_m12_L: 21.5,
      p_m12_H: 33.5,
    },
    "0442": {
      H: 1.86,
      L: 1.75,
      O: 1.84,
      C: 1.86,
      V: 242000,
      p_w1_L: 1.75,
      p_w1_H: 1.9,
      p_m1_L: 1.6,
      p_m1_H: 1.9,
      p_m3_L: 1.29,
      p_m3_H: 2.15,
      p_m6_L: 0.54,
      p_m6_H: 2.51,
      p_m12_L: 0.54,
      p_m12_H: 2.51,
    },
    "0444": {
      H: 0.05,
      L: 0.044,
      O: 0.048,
      C: 0.05,
      V: 5610000,
      p_w1_L: 0.044,
      p_w1_H: 0.052,
      p_m1_L: 0.044,
      p_m1_H: 0.055,
      p_m3_L: 0.044,
      p_m3_H: 0.065,
      p_m6_L: 0.044,
      p_m6_H: 0.078,
      p_m12_L: 0.044,
      p_m12_H: 0.09,
    },
    "0449": {
      H: 0.043,
      L: 0.043,
      O: 0.043,
      C: 0.043,
      V: 0,
      p_w1_L: 0.043,
      p_w1_H: 0.043,
      p_m1_L: 0.043,
      p_m1_H: 0.043,
      p_m3_L: 0.043,
      p_m3_H: 0.043,
      p_m6_L: 0.043,
      p_m6_H: 0.043,
      p_m12_L: 0.043,
      p_m12_H: 0.043,
    },
    "0450": {
      H: 1.29,
      L: 1.27,
      O: 1.29,
      C: 1.28,
      V: 148000,
      p_w1_L: 1.27,
      p_w1_H: 1.31,
      p_m1_L: 1.25,
      p_m1_H: 1.34,
      p_m3_L: 1.22,
      p_m3_H: 1.41,
      p_m6_L: 1.2,
      p_m6_H: 1.44,
      p_m12_L: 1,
      p_m12_H: 1.44,
    },
    "0451": {
      H: 0.255,
      L: 0.245,
      O: 0.25,
      C: 0.246,
      V: 28434000,
      p_w1_L: 0.245,
      p_w1_H: 0.31,
      p_m1_L: 0.245,
      p_m1_H: 0.325,
      p_m3_L: 0.245,
      p_m3_H: 0.395,
      p_m6_L: 0.209,
      p_m6_H: 0.415,
      p_m12_L: 0.201,
      p_m12_H: 0.56,
    },
    "0455": {
      H: 0.28,
      L: 0.265,
      O: 0.27,
      C: 0.27,
      V: 1454000,
      p_w1_L: 0.26,
      p_w1_H: 0.3,
      p_m1_L: 0.203,
      p_m1_H: 0.305,
      p_m3_L: 0.188,
      p_m3_H: 0.305,
      p_m6_L: 0.162,
      p_m6_H: 0.305,
      p_m12_L: 0.13,
      p_m12_H: 0.305,
    },
    "0456": {
      H: 0.072,
      L: 0.072,
      O: 0.072,
      C: 0.072,
      V: 0,
      p_w1_L: 0.07,
      p_w1_H: 0.072,
      p_m1_L: 0.061,
      p_m1_H: 0.095,
      p_m3_L: 0.061,
      p_m3_H: 0.113,
      p_m6_L: 0.061,
      p_m6_H: 0.113,
      p_m12_L: 0.061,
      p_m12_H: 0.113,
    },
    "0458": {
      H: 0.85,
      L: 0.85,
      O: 0.85,
      C: 0.85,
      V: 0,
      p_w1_L: 0.85,
      p_w1_H: 0.95,
      p_m1_L: 0.85,
      p_m1_H: 0.95,
      p_m3_L: 0.85,
      p_m3_H: 0.95,
      p_m6_L: 0.85,
      p_m6_H: 1,
      p_m12_L: 0.85,
      p_m12_H: 1.2,
    },
    "0459": {
      H: 0.128,
      L: 0.124,
      O: 0.124,
      C: 0.127,
      V: 440000,
      p_w1_L: 0.122,
      p_w1_H: 0.132,
      p_m1_L: 0.122,
      p_m1_H: 0.14,
      p_m3_L: 0.122,
      p_m3_H: 0.17,
      p_m6_L: 0.122,
      p_m6_H: 0.2,
      p_m12_L: 0.095,
      p_m12_H: 0.2,
    },
    "0460": {
      H: 1.53,
      L: 1.47,
      O: 1.5,
      C: 1.5,
      V: 34089200,
      p_w1_L: 1.44,
      p_w1_H: 1.54,
      p_m1_L: 1.43,
      p_m1_H: 1.79,
      p_m3_L: 1.43,
      p_m3_H: 2.43,
      p_m6_L: 1.43,
      p_m6_H: 3.69,
      p_m12_L: 0.79,
      p_m12_H: 3.95,
    },
    "0462": {
      H: 2.2,
      L: 2.2,
      O: 2.2,
      C: 2.2,
      V: 0,
      p_w1_L: 2.2,
      p_w1_H: 2.2,
      p_m1_L: 2.2,
      p_m1_H: 2.2,
      p_m3_L: 2.2,
      p_m3_H: 2.2,
      p_m6_L: 2.2,
      p_m6_H: 2.2,
      p_m12_L: 2.2,
      p_m12_H: 2.2,
    },
    "0464": {
      H: 0.44,
      L: 0.435,
      O: 0.435,
      C: 0.435,
      V: 1528000,
      p_w1_L: 0.4,
      p_w1_H: 0.45,
      p_m1_L: 0.4,
      p_m1_H: 0.49,
      p_m3_L: 0.4,
      p_m3_H: 0.65,
      p_m6_L: 0.4,
      p_m6_H: 0.65,
      p_m12_L: 0.221,
      p_m12_H: 0.89,
    },
    "0465": {
      H: 0.58,
      L: 0.53,
      O: 0.55,
      C: 0.56,
      V: 100000,
      p_w1_L: 0.52,
      p_w1_H: 0.61,
      p_m1_L: 0.52,
      p_m1_H: 0.65,
      p_m3_L: 0.52,
      p_m3_H: 0.79,
      p_m6_L: 0.52,
      p_m6_H: 0.85,
      p_m12_L: 0.51,
      p_m12_H: 0.85,
    },
    "0467": {
      H: 0.73,
      L: 0.71,
      O: 0.72,
      C: 0.71,
      V: 16352000,
      p_w1_L: 0.69,
      p_w1_H: 0.74,
      p_m1_L: 0.66,
      p_m1_H: 0.78,
      p_m3_L: 0.65,
      p_m3_H: 1.16,
      p_m6_L: 0.65,
      p_m6_H: 1.47,
      p_m12_L: 0.65,
      p_m12_H: 1.67,
    },
    "0468": {
      H: 2.83,
      L: 2.76,
      O: 2.8,
      C: 2.8,
      V: 1160000,
      p_w1_L: 2.65,
      p_w1_H: 2.92,
      p_m1_L: 2.62,
      p_m1_H: 3.12,
      p_m3_L: 2.62,
      p_m3_H: 3.37,
      p_m6_L: 2.62,
      p_m6_H: 3.98,
      p_m12_L: 2.62,
      p_m12_H: 4.64,
    },
    "0471": {
      H: 0.455,
      L: 0.415,
      O: 0.44,
      C: 0.425,
      V: 12517200,
      p_w1_L: 0.415,
      p_w1_H: 0.51,
      p_m1_L: 0.37,
      p_m1_H: 0.51,
      p_m3_L: 0.226,
      p_m3_H: 0.52,
      p_m6_L: 0.224,
      p_m6_H: 0.52,
      p_m12_L: 0.224,
      p_m12_H: 0.52,
    },
    "0472": {
      H: 0.119,
      L: 0.119,
      O: 0.119,
      C: 0.119,
      V: 0,
      p_w1_L: 0.116,
      p_w1_H: 0.132,
      p_m1_L: 0.116,
      p_m1_H: 0.145,
      p_m3_L: 0.116,
      p_m3_H: 0.164,
      p_m6_L: 0.116,
      p_m6_H: 0.212,
      p_m12_L: 0.101,
      p_m12_H: 0.39,
    },
    "0474": {
      H: 0.196,
      L: 0.188,
      O: 0.196,
      C: 0.196,
      V: 150000,
      p_w1_L: 0.185,
      p_w1_H: 0.2,
      p_m1_L: 0.174,
      p_m1_H: 0.2,
      p_m3_L: 0.174,
      p_m3_H: 0.23,
      p_m6_L: 0.172,
      p_m6_H: 0.23,
      p_m12_L: 0.172,
      p_m12_H: 0.28,
    },
    "0475": {
      H: 1.08,
      L: 1.08,
      O: 1.08,
      C: 1.08,
      V: 2000,
      p_w1_L: 0.86,
      p_w1_H: 1.08,
      p_m1_L: 0.85,
      p_m1_H: 1.14,
      p_m3_L: 0.85,
      p_m3_H: 1.45,
      p_m6_L: 0.64,
      p_m6_H: 1.45,
      p_m12_L: 0.6,
      p_m12_H: 1.45,
    },
    "0476": {
      H: 0.086,
      L: 0.08,
      O: 0.086,
      C: 0.082,
      V: 2500000,
      p_w1_L: 0.073,
      p_w1_H: 0.094,
      p_m1_L: 0.073,
      p_m1_H: 0.116,
      p_m3_L: 0.073,
      p_m3_H: 0.123,
      p_m6_L: 0.073,
      p_m6_H: 0.161,
      p_m12_L: 0.073,
      p_m12_H: 0.185,
    },
    "0479": {
      H: 0.01,
      L: 0.01,
      O: 0.01,
      C: 0.01,
      V: 0,
      p_w1_L: 0.01,
      p_w1_H: 0.01,
      p_m1_L: 0.01,
      p_m1_H: 0.01,
      p_m3_L: 0.01,
      p_m3_H: 0.01,
      p_m6_L: 0.01,
      p_m6_H: 0.01,
      p_m12_L: 0.01,
      p_m12_H: 0.01,
    },
    "0480": {
      H: 2.99,
      L: 2.92,
      O: 2.92,
      C: 2.97,
      V: 58400,
      p_w1_L: 2.9,
      p_w1_H: 2.99,
      p_m1_L: 2.88,
      p_m1_H: 3.08,
      p_m3_L: 2.86,
      p_m3_H: 3.2,
      p_m6_L: 2.86,
      p_m6_H: 3.95,
      p_m12_L: 2.86,
      p_m12_H: 3.95,
    },
    "0482": {
      H: 0.28,
      L: 0.28,
      O: 0.28,
      C: 0.28,
      V: 0,
      p_w1_L: 0.265,
      p_w1_H: 0.29,
      p_m1_L: 0.265,
      p_m1_H: 0.33,
      p_m3_L: 0.265,
      p_m3_H: 0.385,
      p_m6_L: 0.265,
      p_m6_H: 0.445,
      p_m12_L: 0.265,
      p_m12_H: 0.576,
    },
    "0483": {
      H: 0.98,
      L: 0.9,
      O: 0.97,
      C: 0.97,
      V: 844000,
      p_w1_L: 0.9,
      p_w1_H: 1.31,
      p_m1_L: 0.9,
      p_m1_H: 1.34,
      p_m3_L: 0.9,
      p_m3_H: 1.34,
      p_m6_L: 0.7,
      p_m6_H: 1.45,
      p_m12_L: 0.5,
      p_m12_H: 1.45,
    },
    "0484": {
      H: 1.15,
      L: 1.11,
      O: 1.15,
      C: 1.19,
      V: 21200,
      p_w1_L: 1.03,
      p_w1_H: 1.2,
      p_m1_L: 1.02,
      p_m1_H: 1.32,
      p_m3_L: 1.02,
      p_m3_H: 1.39,
      p_m6_L: 1.02,
      p_m6_H: 1.75,
      p_m12_L: 1.02,
      p_m12_H: 3.09,
    },
    "0485": {
      H: 0.275,
      L: 0.234,
      O: 0.235,
      C: 0.234,
      V: 820000,
      p_w1_L: 0.204,
      p_w1_H: 0.295,
      p_m1_L: 0.2,
      p_m1_H: 0.345,
      p_m3_L: 0.185,
      p_m3_H: 0.345,
      p_m6_L: 0.185,
      p_m6_H: 0.345,
      p_m12_L: 0.18,
      p_m12_H: 0.51,
    },
    "0486": {
      H: 7.43,
      L: 7.25,
      O: 7.28,
      C: 7.36,
      V: 935000,
      p_w1_L: 7,
      p_w1_H: 7.43,
      p_m1_L: 6.91,
      p_m1_H: 8.09,
      p_m3_L: 6.65,
      p_m3_H: 9.19,
      p_m6_L: 4.75,
      p_m6_H: 9.19,
      p_m12_L: 3.41,
      p_m12_H: 9.19,
    },
    "0487": {
      H: 0.128,
      L: 0.11,
      O: 0.112,
      C: 0.128,
      V: 112000,
      p_w1_L: 0.11,
      p_w1_H: 0.128,
      p_m1_L: 0.11,
      p_m1_H: 0.143,
      p_m3_L: 0.11,
      p_m3_H: 0.175,
      p_m6_L: 0.11,
      p_m6_H: 0.175,
      p_m12_L: 0.107,
      p_m12_H: 0.175,
    },
    "0488": {
      H: 3.75,
      L: 3.65,
      O: 3.65,
      C: 3.74,
      V: 126300,
      p_w1_L: 3.61,
      p_w1_H: 3.92,
      p_m1_L: 3.61,
      p_m1_H: 4.14,
      p_m3_L: 3.61,
      p_m3_H: 5.46,
      p_m6_L: 3.61,
      p_m6_H: 6.98,
      p_m12_L: 3.61,
      p_m12_H: 7.34,
    },
    "0489": {
      H: 7.29,
      L: 7.13,
      O: 7.29,
      C: 7.17,
      V: 5418180,
      p_w1_L: 7.01,
      p_w1_H: 7.37,
      p_m1_L: 6.96,
      p_m1_H: 7.83,
      p_m3_L: 6.61,
      p_m3_H: 8.98,
      p_m6_L: 6.1,
      p_m6_H: 8.98,
      p_m12_L: 6.1,
      p_m12_H: 10.1,
    },
    "0491": {
      H: 0.052,
      L: 0.047,
      O: 0.051,
      C: 0.05,
      V: 2120000,
      p_w1_L: 0.047,
      p_w1_H: 0.061,
      p_m1_L: 0.047,
      p_m1_H: 0.066,
      p_m3_L: 0.047,
      p_m3_H: 0.071,
      p_m6_L: 0.047,
      p_m6_H: 0.09,
      p_m12_L: 0.047,
      p_m12_H: 0.177,
    },
    "0493": {
      H: 0.68,
      L: 0.67,
      O: 0.68,
      C: 0.67,
      V: 27506600,
      p_w1_L: 0.66,
      p_w1_H: 0.69,
      p_m1_L: 0.66,
      p_m1_H: 0.79,
      p_m3_L: 0.66,
      p_m3_H: 0.93,
      p_m6_L: 0.66,
      p_m6_H: 1.27,
      p_m12_L: 0.66,
      p_m12_H: 2.55,
    },
    "0495": {
      H: 0.128,
      L: 0.128,
      O: 0.128,
      C: 0.128,
      V: 0,
      p_w1_L: 0.12,
      p_w1_H: 0.128,
      p_m1_L: 0.12,
      p_m1_H: 0.149,
      p_m3_L: 0.12,
      p_m3_H: 0.165,
      p_m6_L: 0.12,
      p_m6_H: 0.5,
      p_m12_L: 0.104,
      p_m12_H: 0.5,
    },
    "0496": {
      H: 0.58,
      L: 0.54,
      O: 0.58,
      C: 0.57,
      V: 81000,
      p_w1_L: 0.54,
      p_w1_H: 0.62,
      p_m1_L: 0.54,
      p_m1_H: 0.68,
      p_m3_L: 0.5,
      p_m3_H: 0.85,
      p_m6_L: 0.5,
      p_m6_H: 1.28,
      p_m12_L: 0.5,
      p_m12_H: 2.68,
    },
    "0497": {
      H: 0.204,
      L: 0.203,
      O: 0.203,
      C: 0.204,
      V: 320000,
      p_w1_L: 0.201,
      p_w1_H: 0.217,
      p_m1_L: 0.201,
      p_m1_H: 0.225,
      p_m3_L: 0.201,
      p_m3_H: 0.25,
      p_m6_L: 0.201,
      p_m6_H: 0.265,
      p_m12_L: 0.201,
      p_m12_H: 0.265,
    },
    "0498": {
      H: 0.142,
      L: 0.142,
      O: 0.142,
      C: 0.142,
      V: 0,
      p_w1_L: 0.125,
      p_w1_H: 0.154,
      p_m1_L: 0.125,
      p_m1_H: 0.238,
      p_m3_L: 0.125,
      p_m3_H: 0.3,
      p_m6_L: 0.125,
      p_m6_H: 0.45,
      p_m12_L: 0.125,
      p_m12_H: 0.56,
    },
    "0499": {
      H: 0.25,
      L: 0.238,
      O: 0.238,
      C: 0.249,
      V: 707300,
      p_w1_L: 0.231,
      p_w1_H: 0.25,
      p_m1_L: 0.231,
      p_m1_H: 0.28,
      p_m3_L: 0.231,
      p_m3_H: 0.295,
      p_m6_L: 0.231,
      p_m6_H: 0.38,
      p_m12_L: 0.231,
      p_m12_H: 0.57,
    },
    "0500": {
      H: 0.66,
      L: 0.64,
      O: 0.64,
      C: 0.66,
      V: 51000,
      p_w1_L: 0.59,
      p_w1_H: 0.77,
      p_m1_L: 0.54,
      p_m1_H: 0.77,
      p_m3_L: 0.54,
      p_m3_H: 0.9,
      p_m6_L: 0.54,
      p_m6_H: 0.94,
      p_m12_L: 0.46,
      p_m12_H: 1,
    },
    "0503": {
      H: 1.39,
      L: 1.22,
      O: 1.25,
      C: 1.38,
      V: 107000,
      p_w1_L: 1.2,
      p_w1_H: 1.41,
      p_m1_L: 1.2,
      p_m1_H: 1.61,
      p_m3_L: 1,
      p_m3_H: 3.08,
      p_m6_L: 1,
      p_m6_H: 4.15,
      p_m12_L: 0.86,
      p_m12_H: 4.15,
    },
    "0505": {
      H: 1.12,
      L: 1.05,
      O: 1.06,
      C: 1.06,
      V: 440000,
      p_w1_L: 1.02,
      p_w1_H: 1.12,
      p_m1_L: 1.02,
      p_m1_H: 1.14,
      p_m3_L: 1,
      p_m3_H: 1.19,
      p_m6_L: 0.95,
      p_m6_H: 1.2,
      p_m12_L: 0.95,
      p_m12_H: 1.39,
    },
    "0506": {
      H: 2.94,
      L: 2.85,
      O: 2.88,
      C: 2.86,
      V: 410350,
      p_w1_L: 2.76,
      p_w1_H: 2.94,
      p_m1_L: 2.75,
      p_m1_H: 2.99,
      p_m3_L: 2.72,
      p_m3_H: 3.25,
      p_m6_L: 2.45,
      p_m6_H: 3.25,
      p_m12_L: 2.45,
      p_m12_H: 3.71,
    },
    "0508": {
      H: 0.092,
      L: 0.092,
      O: 0.092,
      C: 0.092,
      V: 15000,
      p_w1_L: 0.09,
      p_w1_H: 0.098,
      p_m1_L: 0.086,
      p_m1_H: 0.104,
      p_m3_L: 0.085,
      p_m3_H: 0.115,
      p_m6_L: 0.072,
      p_m6_H: 0.128,
      p_m12_L: 0.07,
      p_m12_H: 0.164,
    },
    "0509": {
      H: 0.031,
      L: 0.029,
      O: 0.033,
      C: 0.03,
      V: 3270000,
      p_w1_L: 0.029,
      p_w1_H: 0.035,
      p_m1_L: 0.029,
      p_m1_H: 0.043,
      p_m3_L: 0.029,
      p_m3_H: 0.05,
      p_m6_L: 0.029,
      p_m6_H: 0.052,
      p_m12_L: 0.029,
      p_m12_H: 0.084,
    },
    "0510": {
      H: 0.47,
      L: 0.47,
      O: 0.47,
      C: 0.47,
      V: 600,
      p_w1_L: 0.45,
      p_w1_H: 0.47,
      p_m1_L: 0.385,
      p_m1_H: 0.58,
      p_m3_L: 0.385,
      p_m3_H: 0.72,
      p_m6_L: 0.385,
      p_m6_H: 0.8,
      p_m12_L: 0.385,
      p_m12_H: 0.96,
    },
    "0511": {
      H: 4.7,
      L: 4.46,
      O: 4.46,
      C: 4.65,
      V: 1360460,
      p_w1_L: 4.32,
      p_w1_H: 4.73,
      p_m1_L: 4.32,
      p_m1_H: 6.25,
      p_m3_L: 4.32,
      p_m3_H: 6.98,
      p_m6_L: 4.32,
      p_m6_H: 7.88,
      p_m12_L: 4.32,
      p_m12_H: 9.86,
    },
    "0512": {
      H: 6.41,
      L: 6.31,
      O: 6.35,
      C: 6.36,
      V: 3686500,
      p_w1_L: 6.09,
      p_w1_H: 6.41,
      p_m1_L: 5.87,
      p_m1_H: 6.8,
      p_m3_L: 5.82,
      p_m3_H: 6.8,
      p_m6_L: 5.53,
      p_m6_H: 8,
      p_m12_L: 5.53,
      p_m12_H: 8.45,
    },
    "0513": {
      H: 0.43,
      L: 0.43,
      O: 0.43,
      C: 0.43,
      V: 0,
      p_w1_L: 0.405,
      p_w1_H: 0.43,
      p_m1_L: 0.4,
      p_m1_H: 0.445,
      p_m3_L: 0.38,
      p_m3_H: 0.495,
      p_m6_L: 0.38,
      p_m6_H: 0.57,
      p_m12_L: 0.38,
      p_m12_H: 0.82,
    },
    "0515": {
      H: 0.049,
      L: 0.046,
      O: 0.049,
      C: 0.048,
      V: 508000,
      p_w1_L: 0.043,
      p_w1_H: 0.05,
      p_m1_L: 0.043,
      p_m1_H: 0.051,
      p_m3_L: 0.043,
      p_m3_H: 0.063,
      p_m6_L: 0.04,
      p_m6_H: 0.064,
      p_m12_L: 0.04,
      p_m12_H: 0.093,
    },
    "0517": {
      H: 2.39,
      L: 2.37,
      O: 2.38,
      C: 2.39,
      V: 220000,
      p_w1_L: 2.37,
      p_w1_H: 2.45,
      p_m1_L: 2.37,
      p_m1_H: 2.52,
      p_m3_L: 2.37,
      p_m3_H: 2.74,
      p_m6_L: 2.37,
      p_m6_H: 2.74,
      p_m12_L: 2.3,
      p_m12_H: 2.8,
    },
    "0518": {
      H: 0.35,
      L: 0.34,
      O: 0.34,
      C: 0.35,
      V: 144000,
      p_w1_L: 0.34,
      p_w1_H: 0.36,
      p_m1_L: 0.34,
      p_m1_H: 0.385,
      p_m3_L: 0.34,
      p_m3_H: 0.385,
      p_m6_L: 0.34,
      p_m6_H: 0.405,
      p_m12_L: 0.34,
      p_m12_H: 0.72,
    },
    "0519": {
      H: 0.131,
      L: 0.131,
      O: 0.131,
      C: 0.131,
      V: 0,
      p_w1_L: 0.123,
      p_w1_H: 0.159,
      p_m1_L: 0.101,
      p_m1_H: 0.159,
      p_m3_L: 0.095,
      p_m3_H: 0.159,
      p_m6_L: 0.092,
      p_m6_H: 0.159,
      p_m12_L: 0.092,
      p_m12_H: 0.214,
    },
    "0520": {
      H: 5.64,
      L: 5.47,
      O: 5.62,
      C: 5.59,
      V: 1952000,
      p_w1_L: 5.16,
      p_w1_H: 5.67,
      p_m1_L: 5.16,
      p_m1_H: 6.19,
      p_m3_L: 5.16,
      p_m3_H: 8.02,
      p_m6_L: 5.16,
      p_m6_H: 9.04,
      p_m12_L: 5.16,
      p_m12_H: 26.95,
    },
    "0521": {
      H: 0.1,
      L: 0.091,
      O: 0.1,
      C: 0.094,
      V: 760000,
      p_w1_L: 0.09,
      p_w1_H: 0.1,
      p_m1_L: 0.084,
      p_m1_H: 0.108,
      p_m3_L: 0.076,
      p_m3_H: 0.108,
      p_m6_L: 0.07,
      p_m6_H: 0.108,
      p_m12_L: 0.061,
      p_m12_H: 0.138,
    },
    "0522": {
      H: 84.1,
      L: 82,
      O: 84.1,
      C: 82.1,
      V: 950160,
      p_w1_L: 81,
      p_w1_H: 85.5,
      p_m1_L: 80.35,
      p_m1_H: 86.5,
      p_m3_L: 79.2,
      p_m3_H: 93.6,
      p_m6_L: 79.2,
      p_m6_H: 113,
      p_m12_L: 79.2,
      p_m12_H: 133.8,
    },
    "0524": {
      H: 0.1,
      L: 0.095,
      O: 0.1,
      C: 0.095,
      V: 1253400,
      p_w1_L: 0.095,
      p_w1_H: 0.111,
      p_m1_L: 0.095,
      p_m1_H: 0.129,
      p_m3_L: 0.095,
      p_m3_H: 0.142,
      p_m6_L: 0.095,
      p_m6_H: 0.191,
      p_m12_L: 0.095,
      p_m12_H: 0.409,
    },
    "0525": {
      H: 1.35,
      L: 1.33,
      O: 1.33,
      C: 1.33,
      V: 1272750,
      p_w1_L: 1.29,
      p_w1_H: 1.36,
      p_m1_L: 1.28,
      p_m1_H: 1.39,
      p_m3_L: 1.28,
      p_m3_H: 1.56,
      p_m6_L: 1.28,
      p_m6_H: 1.61,
      p_m12_L: 1.28,
      p_m12_H: 1.7,
    },
    "0526": {
      H: 0.05,
      L: 0.044,
      O: 0.047,
      C: 0.044,
      V: 2708000,
      p_w1_L: 0.044,
      p_w1_H: 0.05,
      p_m1_L: 0.039,
      p_m1_H: 0.056,
      p_m3_L: 0.039,
      p_m3_H: 0.06,
      p_m6_L: 0.034,
      p_m6_H: 0.088,
      p_m12_L: 0.034,
      p_m12_H: 0.183,
    },
    "0527": {
      H: 0.185,
      L: 0.18,
      O: 0.182,
      C: 0.181,
      V: 4280000,
      p_w1_L: 0.175,
      p_w1_H: 0.198,
      p_m1_L: 0.175,
      p_m1_H: 0.22,
      p_m3_L: 0.175,
      p_m3_H: 0.224,
      p_m6_L: 0.175,
      p_m6_H: 0.24,
      p_m12_L: 0.157,
      p_m12_H: 0.3,
    },
    "0528": {
      H: 1.2,
      L: 1.2,
      O: 1.2,
      C: 1.2,
      V: 0,
      p_w1_L: 1.1,
      p_w1_H: 1.25,
      p_m1_L: 1.02,
      p_m1_H: 1.25,
      p_m3_L: 1.02,
      p_m3_H: 1.28,
      p_m6_L: 1.02,
      p_m6_H: 1.3,
      p_m12_L: 1.02,
      p_m12_H: 1.41,
    },
    "0529": {
      H: 1.9,
      L: 1.9,
      O: 1.9,
      C: 1.9,
      V: 0,
      p_w1_L: 1.9,
      p_w1_H: 1.9,
      p_m1_L: 1.75,
      p_m1_H: 1.9,
      p_m3_L: 1.68,
      p_m3_H: 1.9,
      p_m6_L: 1.51,
      p_m6_H: 2.09,
      p_m12_L: 1.25,
      p_m12_H: 2.09,
    },
    "0530": {
      H: 0.176,
      L: 0.168,
      O: 0.173,
      C: 0.176,
      V: 984000,
      p_w1_L: 0.168,
      p_w1_H: 0.21,
      p_m1_L: 0.159,
      p_m1_H: 0.238,
      p_m3_L: 0.159,
      p_m3_H: 0.29,
      p_m6_L: 0.159,
      p_m6_H: 0.37,
      p_m12_L: 0.159,
      p_m12_H: 0.43,
    },
    "0531": {
      H: 0.23,
      L: 0.23,
      O: 0.23,
      C: 0.23,
      V: 120000,
      p_w1_L: 0.224,
      p_w1_H: 0.243,
      p_m1_L: 0.2,
      p_m1_H: 0.28,
      p_m3_L: 0.2,
      p_m3_H: 0.295,
      p_m6_L: 0.2,
      p_m6_H: 0.35,
      p_m12_L: 0.198,
      p_m12_H: 0.38,
    },
    "0532": {
      H: 1.06,
      L: 1.04,
      O: 1.04,
      C: 1.06,
      V: 6000,
      p_w1_L: 1,
      p_w1_H: 1.06,
      p_m1_L: 0.95,
      p_m1_H: 1.06,
      p_m3_L: 0.95,
      p_m3_H: 1.09,
      p_m6_L: 0.85,
      p_m6_H: 1.59,
      p_m12_L: 0.49,
      p_m12_H: 1.59,
    },
    "0533": {
      H: 1.66,
      L: 1.63,
      O: 1.64,
      C: 1.66,
      V: 61000,
      p_w1_L: 1.63,
      p_w1_H: 1.71,
      p_m1_L: 1.63,
      p_m1_H: 1.75,
      p_m3_L: 1.57,
      p_m3_H: 1.75,
      p_m6_L: 1.57,
      p_m6_H: 1.84,
      p_m12_L: 1.46,
      p_m12_H: 1.94,
    },
    "0535": {
      H: 0.8,
      L: 0.77,
      O: 0.78,
      C: 0.78,
      V: 8230000,
      p_w1_L: 0.74,
      p_w1_H: 0.8,
      p_m1_L: 0.7,
      p_m1_H: 0.8,
      p_m3_L: 0.7,
      p_m3_H: 0.91,
      p_m6_L: 0.7,
      p_m6_H: 1.04,
      p_m12_L: 0.7,
      p_m12_H: 1.29,
    },
    "0536": {
      H: 1.16,
      L: 1.15,
      O: 1.15,
      C: 1.15,
      V: 82000,
      p_w1_L: 1.14,
      p_w1_H: 1.16,
      p_m1_L: 1.11,
      p_m1_H: 1.17,
      p_m3_L: 1.07,
      p_m3_H: 1.2,
      p_m6_L: 1.07,
      p_m6_H: 1.23,
      p_m12_L: 0.91,
      p_m12_H: 1.31,
    },
    "0538": {
      H: 1.25,
      L: 1.23,
      O: 1.23,
      C: 1.24,
      V: 291000,
      p_w1_L: 1.21,
      p_w1_H: 1.27,
      p_m1_L: 1.21,
      p_m1_H: 1.32,
      p_m3_L: 1.21,
      p_m3_H: 1.44,
      p_m6_L: 1.21,
      p_m6_H: 1.85,
      p_m12_L: 1.1,
      p_m12_H: 1.85,
    },
    "0539": {
      H: 0,
      L: 0,
      O: 0,
      C: 0,
      V: 0,
      p_w1_L: 0,
      p_w1_H: 0,
      p_m1_L: 0,
      p_m1_H: 0,
      p_m3_L: 0,
      p_m3_H: 0,
      p_m6_L: 0,
      p_m6_H: 0,
      p_m12_L: 0,
      p_m12_H: 0.75,
    },
    "0540": {
      H: 0.218,
      L: 0.218,
      O: 0.218,
      C: 0.218,
      V: 0,
      p_w1_L: 0.218,
      p_w1_H: 0.218,
      p_m1_L: 0.199,
      p_m1_H: 0.285,
      p_m3_L: 0.17,
      p_m3_H: 0.72,
      p_m6_L: 0.15,
      p_m6_H: 0.72,
      p_m12_L: 0.14,
      p_m12_H: 0.72,
    },
    "0542": {
      H: 0.115,
      L: 0.115,
      O: 0.115,
      C: 0.115,
      V: 0,
      p_w1_L: 0.115,
      p_w1_H: 0.115,
      p_m1_L: 0.098,
      p_m1_H: 0.117,
      p_m3_L: 0.098,
      p_m3_H: 0.149,
      p_m6_L: 0.095,
      p_m6_H: 0.156,
      p_m12_L: 0.095,
      p_m12_H: 0.23,
    },
    "0543": {
      H: 1.43,
      L: 1.43,
      O: 1.43,
      C: 1.43,
      V: 0,
      p_w1_L: 1.35,
      p_w1_H: 1.52,
      p_m1_L: 1.35,
      p_m1_H: 1.62,
      p_m3_L: 1.35,
      p_m3_H: 1.62,
      p_m6_L: 1.35,
      p_m6_H: 1.92,
      p_m12_L: 1.17,
      p_m12_H: 2.12,
    },
    "0544": {
      H: 0.028,
      L: 0.027,
      O: 0.027,
      C: 0.028,
      V: 320000,
      p_w1_L: 0.027,
      p_w1_H: 0.029,
      p_m1_L: 0.025,
      p_m1_H: 0.03,
      p_m3_L: 0.025,
      p_m3_H: 0.04,
      p_m6_L: 0.025,
      p_m6_H: 0.048,
      p_m12_L: 0.022,
      p_m12_H: 0.069,
    },
    "0546": {
      H: 3.13,
      L: 2.96,
      O: 3.05,
      C: 3.04,
      V: 6110000,
      p_w1_L: 2.96,
      p_w1_H: 3.46,
      p_m1_L: 2.5,
      p_m1_H: 3.46,
      p_m3_L: 2.5,
      p_m3_H: 3.46,
      p_m6_L: 2.31,
      p_m6_H: 3.46,
      p_m12_L: 2.31,
      p_m12_H: 3.87,
    },
    "0547": {
      H: 0.62,
      L: 0.58,
      O: 0.59,
      C: 0.58,
      V: 2708020,
      p_w1_L: 0.56,
      p_w1_H: 0.69,
      p_m1_L: 0.56,
      p_m1_H: 0.94,
      p_m3_L: 0.48,
      p_m3_H: 0.94,
      p_m6_L: 0.055,
      p_m6_H: 0.94,
      p_m12_L: 0.055,
      p_m12_H: 0.94,
    },
    "0548": {
      H: 7.38,
      L: 7.27,
      O: 7.35,
      C: 7.3,
      V: 1852610,
      p_w1_L: 7.26,
      p_w1_H: 7.47,
      p_m1_L: 7.26,
      p_m1_H: 7.75,
      p_m3_L: 6.95,
      p_m3_H: 8.15,
      p_m6_L: 6.95,
      p_m6_H: 8.15,
      p_m12_L: 6.95,
      p_m12_H: 8.64,
    },
    "0550": {
      H: 0.5,
      L: 0.5,
      O: 0.5,
      C: 0.5,
      V: 6000,
      p_w1_L: 0.385,
      p_w1_H: 0.5,
      p_m1_L: 0.385,
      p_m1_H: 0.5,
      p_m3_L: 0.38,
      p_m3_H: 0.6,
      p_m6_L: 0.38,
      p_m6_H: 1.2,
      p_m12_L: 0.305,
      p_m12_H: 1.96,
    },
    "0551": {
      H: 14.24,
      L: 13.98,
      O: 14,
      C: 14.18,
      V: 974800,
      p_w1_L: 13,
      p_w1_H: 14.24,
      p_m1_L: 13,
      p_m1_H: 17.3,
      p_m3_L: 13,
      p_m3_H: 17.42,
      p_m6_L: 13,
      p_m6_H: 20.55,
      p_m12_L: 13,
      p_m12_H: 21.25,
    },
    "0552": {
      H: 3.95,
      L: 3.83,
      O: 3.83,
      C: 3.88,
      V: 5970550,
      p_w1_L: 3.68,
      p_w1_H: 3.95,
      p_m1_L: 3.62,
      p_m1_H: 3.95,
      p_m3_L: 3.62,
      p_m3_H: 4.57,
      p_m6_L: 3.24,
      p_m6_H: 4.57,
      p_m12_L: 3.18,
      p_m12_H: 4.57,
    },
    "0553": {
      H: 4.27,
      L: 4.12,
      O: 4.12,
      C: 4.13,
      V: 356000,
      p_w1_L: 3.92,
      p_w1_H: 4.34,
      p_m1_L: 2.99,
      p_m1_H: 4.8,
      p_m3_L: 2.99,
      p_m3_H: 4.8,
      p_m6_L: 2.99,
      p_m6_H: 5.15,
      p_m12_L: 2.99,
      p_m12_H: 5.55,
    },
    "0554": {
      H: 0.47,
      L: 0.45,
      O: 0.455,
      C: 0.47,
      V: 1192000,
      p_w1_L: 0.43,
      p_w1_H: 0.48,
      p_m1_L: 0.37,
      p_m1_H: 0.48,
      p_m3_L: 0.335,
      p_m3_H: 0.48,
      p_m6_L: 0.335,
      p_m6_H: 0.48,
      p_m12_L: 0.305,
      p_m12_H: 0.48,
    },
    "0556": {
      H: 0.94,
      L: 0.9,
      O: 0.9,
      C: 0.94,
      V: 952000,
      p_w1_L: 0.89,
      p_w1_H: 0.94,
      p_m1_L: 0.78,
      p_m1_H: 1.01,
      p_m3_L: 0.78,
      p_m3_H: 1.08,
      p_m6_L: 0.47,
      p_m6_H: 1.08,
      p_m12_L: 0.445,
      p_m12_H: 1.08,
    },
    "0557": {
      H: 1.7,
      L: 1.7,
      O: 1.7,
      C: 1.7,
      V: 0,
      p_w1_L: 1.7,
      p_w1_H: 1.7,
      p_m1_L: 1.7,
      p_m1_H: 1.7,
      p_m3_L: 1.7,
      p_m3_H: 1.7,
      p_m6_L: 1.7,
      p_m6_H: 1.7,
      p_m12_L: 1.7,
      p_m12_H: 1.7,
    },
    "0558": {
      H: 19.16,
      L: 18.34,
      O: 18.34,
      C: 19.12,
      V: 6096000,
      p_w1_L: 16.52,
      p_w1_H: 19.16,
      p_m1_L: 16.3,
      p_m1_H: 20.2,
      p_m3_L: 14.82,
      p_m3_H: 25.25,
      p_m6_L: 10.382,
      p_m6_H: 26.182,
      p_m12_L: 0.93,
      p_m12_H: 26.182,
    },
    "0559": {
      H: 0.032,
      L: 0.031,
      O: 0.032,
      C: 0.031,
      V: 12840000,
      p_w1_L: 0.029,
      p_w1_H: 0.037,
      p_m1_L: 0.029,
      p_m1_H: 0.042,
      p_m3_L: 0.029,
      p_m3_H: 0.057,
      p_m6_L: 0.027,
      p_m6_H: 0.076,
      p_m12_L: 0.011,
      p_m12_H: 0.078,
    },
    "0560": {
      H: 0.97,
      L: 0.95,
      O: 0.95,
      C: 0.96,
      V: 526000,
      p_w1_L: 0.94,
      p_w1_H: 0.97,
      p_m1_L: 0.94,
      p_m1_H: 0.99,
      p_m3_L: 0.94,
      p_m3_H: 1.1,
      p_m6_L: 0.94,
      p_m6_H: 1.12,
      p_m12_L: 0.88,
      p_m12_H: 1.35,
    },
    "0563": {
      H: 0.81,
      L: 0.73,
      O: 0.75,
      C: 0.77,
      V: 8965400,
      p_w1_L: 0.66,
      p_w1_H: 0.81,
      p_m1_L: 0.64,
      p_m1_H: 0.81,
      p_m3_L: 0.61,
      p_m3_H: 0.81,
      p_m6_L: 0.61,
      p_m6_H: 0.81,
      p_m12_L: 0.61,
      p_m12_H: 0.91,
    },
    "0564": {
      H: 8.26,
      L: 8.08,
      O: 8.26,
      C: 8.2,
      V: 559000,
      p_w1_L: 8.05,
      p_w1_H: 8.9,
      p_m1_L: 7.04,
      p_m1_H: 9.84,
      p_m3_L: 7.04,
      p_m3_H: 11.66,
      p_m6_L: 7.04,
      p_m6_H: 11.66,
      p_m12_L: 7.04,
      p_m12_H: 17.26,
    },
    "0565": {
      H: 0.37,
      L: 0.37,
      O: 0.37,
      C: 0.37,
      V: 0,
      p_w1_L: 0.37,
      p_w1_H: 0.37,
      p_m1_L: 0.37,
      p_m1_H: 0.44,
      p_m3_L: 0.37,
      p_m3_H: 0.44,
      p_m6_L: 0.35,
      p_m6_H: 0.44,
      p_m12_L: 0.234,
      p_m12_H: 0.5,
    },
    "0567": {
      H: 0.108,
      L: 0.108,
      O: 0.108,
      C: 0.109,
      V: 30000,
      p_w1_L: 0.104,
      p_w1_H: 0.12,
      p_m1_L: 0.104,
      p_m1_H: 0.145,
      p_m3_L: 0.104,
      p_m3_H: 0.159,
      p_m6_L: 0.104,
      p_m6_H: 0.186,
      p_m12_L: 0.104,
      p_m12_H: 0.233,
    },
    "0568": {
      H: 4.87,
      L: 4.3,
      O: 4.55,
      C: 4.41,
      V: 8401200,
      p_w1_L: 3.28,
      p_w1_H: 4.87,
      p_m1_L: 3.25,
      p_m1_H: 4.87,
      p_m3_L: 3.25,
      p_m3_H: 6.35,
      p_m6_L: 3.25,
      p_m6_H: 7.13,
      p_m12_L: 0.8,
      p_m12_H: 10.38,
    },
    "0570": {
      H: 3.74,
      L: 3.61,
      O: 3.68,
      C: 3.72,
      V: 10108000,
      p_w1_L: 3.56,
      p_w1_H: 3.76,
      p_m1_L: 3.43,
      p_m1_H: 3.87,
      p_m3_L: 3.34,
      p_m3_H: 4.05,
      p_m6_L: 3.34,
      p_m6_H: 5.59,
      p_m12_L: 3.34,
      p_m12_H: 5.59,
    },
    "0571": {
      H: 0.3,
      L: 0.3,
      O: 0.3,
      C: 0.3,
      V: 0,
      p_w1_L: 0.29,
      p_w1_H: 0.305,
      p_m1_L: 0.29,
      p_m1_H: 0.36,
      p_m3_L: 0.29,
      p_m3_H: 0.78,
      p_m6_L: 0.29,
      p_m6_H: 0.8,
      p_m12_L: 0.29,
      p_m12_H: 0.88,
    },
    "0572": {
      H: 0.08,
      L: 0.08,
      O: 0.08,
      C: 0.08,
      V: 795000,
      p_w1_L: 0.073,
      p_w1_H: 0.085,
      p_m1_L: 0.073,
      p_m1_H: 0.112,
      p_m3_L: 0.073,
      p_m3_H: 0.125,
      p_m6_L: 0.073,
      p_m6_H: 0.145,
      p_m12_L: 0.073,
      p_m12_H: 0.28,
    },
    "0573": {
      H: 0.88,
      L: 0.87,
      O: 0.87,
      C: 0.88,
      V: 210000,
      p_w1_L: 0.87,
      p_w1_H: 0.89,
      p_m1_L: 0.86,
      p_m1_H: 0.91,
      p_m3_L: 0.86,
      p_m3_H: 0.98,
      p_m6_L: 0.86,
      p_m6_H: 1.06,
      p_m12_L: 0.77,
      p_m12_H: 1.11,
    },
    "0574": {
      H: 0.07,
      L: 0.054,
      O: 0.07,
      C: 0.066,
      V: 6496000,
      p_w1_L: 0.054,
      p_w1_H: 0.09,
      p_m1_L: 0.054,
      p_m1_H: 0.09,
      p_m3_L: 0.054,
      p_m3_H: 0.09,
      p_m6_L: 0.054,
      p_m6_H: 0.095,
      p_m12_L: 0.041,
      p_m12_H: 0.102,
    },
    "0575": {
      H: 0.145,
      L: 0.135,
      O: 0.138,
      C: 0.145,
      V: 252099,
      p_w1_L: 0.135,
      p_w1_H: 0.146,
      p_m1_L: 0.135,
      p_m1_H: 0.16,
      p_m3_L: 0.135,
      p_m3_H: 0.203,
      p_m6_L: 0.135,
      p_m6_H: 0.203,
      p_m12_L: 0.135,
      p_m12_H: 0.29,
    },
    "0576": {
      H: 7.35,
      L: 6.86,
      O: 7.35,
      C: 6.98,
      V: 8143710,
      p_w1_L: 6.86,
      p_w1_H: 7.38,
      p_m1_L: 6.8,
      p_m1_H: 7.38,
      p_m3_L: 6.05,
      p_m3_H: 7.38,
      p_m6_L: 6.05,
      p_m6_H: 7.38,
      p_m12_L: 5.82,
      p_m12_H: 7.63,
    },
    "0577": {
      H: 0.024,
      L: 0.024,
      O: 0.024,
      C: 0.024,
      V: 0,
      p_w1_L: 0.024,
      p_w1_H: 0.024,
      p_m1_L: 0.024,
      p_m1_H: 0.024,
      p_m3_L: 0.024,
      p_m3_H: 0.024,
      p_m6_L: 0.021,
      p_m6_H: 0.031,
      p_m12_L: 0.021,
      p_m12_H: 0.095,
    },
    "0578": {
      H: 0.048,
      L: 0.048,
      O: 0.048,
      C: 0.048,
      V: 0,
      p_w1_L: 0.048,
      p_w1_H: 0.048,
      p_m1_L: 0.048,
      p_m1_H: 0.048,
      p_m3_L: 0.048,
      p_m3_H: 0.048,
      p_m6_L: 0.048,
      p_m6_H: 0.048,
      p_m12_L: 0.037,
      p_m12_H: 0.11,
    },
    "0579": {
      H: 2.18,
      L: 2.11,
      O: 2.12,
      C: 2.17,
      V: 5434000,
      p_w1_L: 2.09,
      p_w1_H: 2.26,
      p_m1_L: 2.05,
      p_m1_H: 2.28,
      p_m3_L: 2.05,
      p_m3_H: 2.93,
      p_m6_L: 1.68,
      p_m6_H: 2.93,
      p_m12_L: 1.59,
      p_m12_H: 2.93,
    },
    "0580": {
      H: 5.3,
      L: 4.87,
      O: 4.92,
      C: 5.23,
      V: 16746000,
      p_w1_L: 4.77,
      p_w1_H: 5.5,
      p_m1_L: 4.06,
      p_m1_H: 5.6,
      p_m3_L: 3.49,
      p_m3_H: 5.6,
      p_m6_L: 2.55,
      p_m6_H: 5.6,
      p_m12_L: 1.61,
      p_m12_H: 5.6,
    },
    "0581": {
      H: 2.24,
      L: 2.16,
      O: 2.24,
      C: 2.21,
      V: 2835460,
      p_w1_L: 2.06,
      p_w1_H: 2.28,
      p_m1_L: 2.02,
      p_m1_H: 2.37,
      p_m3_L: 2.02,
      p_m3_H: 3.26,
      p_m6_L: 2.02,
      p_m6_H: 3.26,
      p_m12_L: 2.02,
      p_m12_H: 3.27,
    },
    "0582": {
      H: 0.255,
      L: 0.244,
      O: 0.255,
      C: 0.246,
      V: 175600,
      p_w1_L: 0.231,
      p_w1_H: 0.255,
      p_m1_L: 0.229,
      p_m1_H: 0.255,
      p_m3_L: 0.228,
      p_m3_H: 0.26,
      p_m6_L: 0.216,
      p_m6_H: 0.275,
      p_m12_L: 0.17,
      p_m12_H: 0.61,
    },
    "0583": {
      H: 0.4,
      L: 0.4,
      O: 0.4,
      C: 0.4,
      V: 0,
      p_w1_L: 0.4,
      p_w1_H: 0.425,
      p_m1_L: 0.36,
      p_m1_H: 0.425,
      p_m3_L: 0.36,
      p_m3_H: 0.425,
      p_m6_L: 0.355,
      p_m6_H: 0.48,
      p_m12_L: 0.355,
      p_m12_H: 0.64,
    },
    "0585": {
      H: 0.62,
      L: 0.6,
      O: 0.62,
      C: 0.62,
      V: 211600,
      p_w1_L: 0.6,
      p_w1_H: 0.68,
      p_m1_L: 0.6,
      p_m1_H: 0.81,
      p_m3_L: 0.6,
      p_m3_H: 1.23,
      p_m6_L: 0.6,
      p_m6_H: 1.3,
      p_m12_L: 0.6,
      p_m12_H: 2.42,
    },
    "0586": {
      H: 38.6,
      L: 37.8,
      O: 38.6,
      C: 37.9,
      V: 3140420,
      p_w1_L: 37.75,
      p_w1_H: 39,
      p_m1_L: 37.2,
      p_m1_H: 39.45,
      p_m3_L: 32.45,
      p_m3_H: 39.5,
      p_m6_L: 27.6,
      p_m6_H: 39.5,
      p_m12_L: 27.6,
      p_m12_H: 40.85,
    },
    "0588": {
      H: 1.17,
      L: 1.15,
      O: 1.16,
      C: 1.15,
      V: 800000,
      p_w1_L: 1.14,
      p_w1_H: 1.19,
      p_m1_L: 1.13,
      p_m1_H: 1.27,
      p_m3_L: 1.13,
      p_m3_H: 1.44,
      p_m6_L: 1.13,
      p_m6_H: 1.55,
      p_m12_L: 1.13,
      p_m12_H: 1.64,
    },
    "0589": {
      H: 1,
      L: 1,
      O: 1,
      C: 1,
      V: 0,
      p_w1_L: 1,
      p_w1_H: 1.03,
      p_m1_L: 1,
      p_m1_H: 1.12,
      p_m3_L: 1,
      p_m3_H: 1.34,
      p_m6_L: 0.99,
      p_m6_H: 1.34,
      p_m12_L: 0.99,
      p_m12_H: 1.64,
    },
    "0590": {
      H: 23.95,
      L: 23.05,
      O: 23.5,
      C: 23.15,
      V: 345000,
      p_w1_L: 21.55,
      p_w1_H: 23.95,
      p_m1_L: 21.55,
      p_m1_H: 25.8,
      p_m3_L: 19.56,
      p_m3_H: 25.8,
      p_m6_L: 19.56,
      p_m6_H: 28.5,
      p_m12_L: 16.7,
      p_m12_H: 28.5,
    },
    "0591": {
      H: 1.22,
      L: 1.22,
      O: 1.22,
      C: 1.22,
      V: 0,
      p_w1_L: 1.22,
      p_w1_H: 1.22,
      p_m1_L: 1.22,
      p_m1_H: 1.22,
      p_m3_L: 1.22,
      p_m3_H: 1.22,
      p_m6_L: 1.22,
      p_m6_H: 1.22,
      p_m12_L: 1.22,
      p_m12_H: 1.22,
    },
    "0592": {
      H: 1.11,
      L: 1.05,
      O: 1.06,
      C: 1.1,
      V: 6920500,
      p_w1_L: 1,
      p_w1_H: 1.12,
      p_m1_L: 0.98,
      p_m1_H: 1.39,
      p_m3_L: 0.66,
      p_m3_H: 1.39,
      p_m6_L: 0.475,
      p_m6_H: 1.39,
      p_m12_L: 0.35,
      p_m12_H: 1.39,
    },
    "0593": {
      H: 1,
      L: 0.88,
      O: 0.98,
      C: 0.97,
      V: 12000,
      p_w1_L: 0.88,
      p_w1_H: 1.12,
      p_m1_L: 0.88,
      p_m1_H: 1.22,
      p_m3_L: 0.88,
      p_m3_H: 1.62,
      p_m6_L: 0.88,
      p_m6_H: 3.8,
      p_m12_L: 0.88,
      p_m12_H: 5.8,
    },
    "0595": {
      H: 0.59,
      L: 0.57,
      O: 0.57,
      C: 0.59,
      V: 1506000,
      p_w1_L: 0.56,
      p_w1_H: 0.59,
      p_m1_L: 0.55,
      p_m1_H: 0.67,
      p_m3_L: 0.54,
      p_m3_H: 0.67,
      p_m6_L: 0.53,
      p_m6_H: 0.88,
      p_m12_L: 0.36,
      p_m12_H: 0.88,
    },
    "0596": {
      H: 4.35,
      L: 3.91,
      O: 4,
      C: 4.25,
      V: 11340000,
      p_w1_L: 3.74,
      p_w1_H: 4.35,
      p_m1_L: 3.3,
      p_m1_H: 4.35,
      p_m3_L: 3.17,
      p_m3_H: 5.3,
      p_m6_L: 1.85,
      p_m6_H: 5.3,
      p_m12_L: 1.6,
      p_m12_H: 6.6,
    },
    "0598": {
      H: 2.56,
      L: 2.5,
      O: 2.53,
      C: 2.51,
      V: 2842000,
      p_w1_L: 2.38,
      p_w1_H: 2.59,
      p_m1_L: 2.28,
      p_m1_H: 2.59,
      p_m3_L: 2.28,
      p_m3_H: 3.53,
      p_m6_L: 2.28,
      p_m6_H: 3.8,
      p_m12_L: 2.28,
      p_m12_H: 3.87,
    },
    "0599": {
      H: 0.405,
      L: 0.405,
      O: 0.405,
      C: 0.405,
      V: 0,
      p_w1_L: 0.405,
      p_w1_H: 0.41,
      p_m1_L: 0.4,
      p_m1_H: 0.41,
      p_m3_L: 0.4,
      p_m3_H: 0.41,
      p_m6_L: 0.35,
      p_m6_H: 0.48,
      p_m12_L: 0.275,
      p_m12_H: 0.48,
    },
    "0600": {
      H: 0.044,
      L: 0.04,
      O: 0.043,
      C: 0.043,
      V: 4974000,
      p_w1_L: 0.037,
      p_w1_H: 0.046,
      p_m1_L: 0.035,
      p_m1_H: 0.046,
      p_m3_L: 0.026,
      p_m3_H: 0.072,
      p_m6_L: 0.021,
      p_m6_H: 0.072,
      p_m12_L: 0.021,
      p_m12_H: 0.072,
    },
    "0601": {
      H: 0.063,
      L: 0.063,
      O: 0.063,
      C: 0.063,
      V: 0,
      p_w1_L: 0.062,
      p_w1_H: 0.065,
      p_m1_L: 0.062,
      p_m1_H: 0.074,
      p_m3_L: 0.046,
      p_m3_H: 0.1,
      p_m6_L: 0.046,
      p_m6_H: 0.1,
      p_m12_L: 0.046,
      p_m12_H: 0.134,
    },
    "0602": {
      H: 0.102,
      L: 0.102,
      O: 0.102,
      C: 0.102,
      V: 0,
      p_w1_L: 0.102,
      p_w1_H: 0.105,
      p_m1_L: 0.102,
      p_m1_H: 0.121,
      p_m3_L: 0.102,
      p_m3_H: 0.155,
      p_m6_L: 0.102,
      p_m6_H: 0.183,
      p_m12_L: 0.102,
      p_m12_H: 0.2,
    },
    "0603": {
      H: 0.435,
      L: 0.425,
      O: 0.435,
      C: 0.435,
      V: 220000,
      p_w1_L: 0.425,
      p_w1_H: 0.465,
      p_m1_L: 0.405,
      p_m1_H: 0.485,
      p_m3_L: 0.405,
      p_m3_H: 0.64,
      p_m6_L: 0.395,
      p_m6_H: 0.64,
      p_m12_L: 0.33,
      p_m12_H: 0.64,
    },
    "0604": {
      H: 1.77,
      L: 1.74,
      O: 1.74,
      C: 1.76,
      V: 4581400,
      p_w1_L: 1.72,
      p_w1_H: 1.82,
      p_m1_L: 1.72,
      p_m1_H: 1.99,
      p_m3_L: 1.72,
      p_m3_H: 2.42,
      p_m6_L: 1.72,
      p_m6_H: 2.64,
      p_m12_L: 1.72,
      p_m12_H: 2.98,
    },
    "0605": {
      H: 1.7,
      L: 1.7,
      O: 1.7,
      C: 1.7,
      V: 0,
      p_w1_L: 1.7,
      p_w1_H: 1.7,
      p_m1_L: 1.7,
      p_m1_H: 1.7,
      p_m3_L: 1.7,
      p_m3_H: 1.7,
      p_m6_L: 1.7,
      p_m6_H: 1.7,
      p_m12_L: 1.4,
      p_m12_H: 2.56,
    },
    "0606": {
      H: 3.75,
      L: 3.66,
      O: 3.71,
      C: 3.72,
      V: 4822820,
      p_w1_L: 3.45,
      p_w1_H: 3.75,
      p_m1_L: 3.45,
      p_m1_H: 4.22,
      p_m3_L: 3.45,
      p_m3_H: 4.55,
      p_m6_L: 3.45,
      p_m6_H: 4.55,
      p_m12_L: 3.45,
      p_m12_H: 4.55,
    },
    "0607": {
      H: 0.107,
      L: 0.103,
      O: 0.107,
      C: 0.107,
      V: 3177500,
      p_w1_L: 0.103,
      p_w1_H: 0.11,
      p_m1_L: 0.103,
      p_m1_H: 0.114,
      p_m3_L: 0.1,
      p_m3_H: 0.125,
      p_m6_L: 0.099,
      p_m6_H: 0.168,
      p_m12_L: 0.099,
      p_m12_H: 0.215,
    },
    "0608": {
      H: 1.47,
      L: 1.47,
      O: 1.47,
      C: 1.47,
      V: 0,
      p_w1_L: 1.47,
      p_w1_H: 1.48,
      p_m1_L: 1.47,
      p_m1_H: 1.48,
      p_m3_L: 1.45,
      p_m3_H: 1.5,
      p_m6_L: 1.43,
      p_m6_H: 1.55,
      p_m12_L: 1.36,
      p_m12_H: 1.55,
    },
    "0609": {
      H: 1.1,
      L: 1.1,
      O: 1.1,
      C: 1.1,
      V: 40000,
      p_w1_L: 1.04,
      p_w1_H: 1.16,
      p_m1_L: 0.98,
      p_m1_H: 1.24,
      p_m3_L: 0.94,
      p_m3_H: 1.33,
      p_m6_L: 0.66,
      p_m6_H: 1.33,
      p_m12_L: 0.61,
      p_m12_H: 1.33,
    },
    "0610": {
      H: 3.72,
      L: 3.72,
      O: 3.72,
      C: 3.72,
      V: 0,
      p_w1_L: 3.66,
      p_w1_H: 3.74,
      p_m1_L: 3.66,
      p_m1_H: 3.88,
      p_m3_L: 3.58,
      p_m3_H: 3.95,
      p_m6_L: 3.58,
      p_m6_H: 4.21,
      p_m12_L: 3.58,
      p_m12_H: 4.43,
    },
    "0611": {
      H: 0.87,
      L: 0.83,
      O: 0.84,
      C: 0.86,
      V: 2572000,
      p_w1_L: 0.81,
      p_w1_H: 0.96,
      p_m1_L: 0.81,
      p_m1_H: 1.32,
      p_m3_L: 0.56,
      p_m3_H: 1.32,
      p_m6_L: 0.48,
      p_m6_H: 1.32,
      p_m12_L: 0.395,
      p_m12_H: 1.32,
    },
    "0612": {
      H: 3.15,
      L: 3.04,
      O: 3.04,
      C: 3.13,
      V: 1736000,
      p_w1_L: 2.93,
      p_w1_H: 3.15,
      p_m1_L: 2.43,
      p_m1_H: 3.4,
      p_m3_L: 2.43,
      p_m3_H: 4.22,
      p_m6_L: 2.43,
      p_m6_H: 5,
      p_m12_L: 1.71,
      p_m12_H: 5,
    },
    "0613": {
      H: 0.53,
      L: 0.53,
      O: 0.53,
      C: 0.53,
      V: 0,
      p_w1_L: 0.5,
      p_w1_H: 0.55,
      p_m1_L: 0.49,
      p_m1_H: 0.57,
      p_m3_L: 0.435,
      p_m3_H: 0.57,
      p_m6_L: 0.435,
      p_m6_H: 0.7,
      p_m12_L: 0.435,
      p_m12_H: 1.2,
    },
    "0616": {
      H: 0.24,
      L: 0.235,
      O: 0.24,
      C: 0.235,
      V: 1395000,
      p_w1_L: 0.225,
      p_w1_H: 0.32,
      p_m1_L: 0.225,
      p_m1_H: 0.47,
      p_m3_L: 0.225,
      p_m3_H: 0.49,
      p_m6_L: 0.225,
      p_m6_H: 0.49,
      p_m12_L: 0.225,
      p_m12_H: 0.6,
    },
    "0617": {
      H: 2,
      L: 2,
      O: 2,
      C: 2,
      V: 150,
      p_w1_L: 2,
      p_w1_H: 2,
      p_m1_L: 1.94,
      p_m1_H: 2.1,
      p_m3_L: 1.93,
      p_m3_H: 2.13,
      p_m6_L: 1.91,
      p_m6_H: 2.25,
      p_m12_L: 1.81,
      p_m12_H: 2.45,
    },
    "0618": {
      H: 0.162,
      L: 0.162,
      O: 0.162,
      C: 0.162,
      V: 0,
      p_w1_L: 0.162,
      p_w1_H: 0.162,
      p_m1_L: 0.162,
      p_m1_H: 0.162,
      p_m3_L: 0.162,
      p_m3_H: 0.162,
      p_m6_L: 0.162,
      p_m6_H: 0.162,
      p_m12_L: 0.072,
      p_m12_H: 0.375,
    },
    "0619": {
      H: 0.32,
      L: 0.32,
      O: 0.32,
      C: 0.32,
      V: 0,
      p_w1_L: 0.31,
      p_w1_H: 0.35,
      p_m1_L: 0.3,
      p_m1_H: 0.44,
      p_m3_L: 0.3,
      p_m3_H: 0.44,
      p_m6_L: 0.3,
      p_m6_H: 0.65,
      p_m12_L: 0.219,
      p_m12_H: 0.65,
    },
    "0620": {
      H: 3.65,
      L: 3.6,
      O: 3.65,
      C: 3.65,
      V: 30000,
      p_w1_L: 3.41,
      p_w1_H: 3.89,
      p_m1_L: 3.41,
      p_m1_H: 4.58,
      p_m3_L: 2.99,
      p_m3_H: 4.6,
      p_m6_L: 2.99,
      p_m6_H: 6.84,
      p_m12_L: 2.8,
      p_m12_H: 6.98,
    },
    "0621": {
      H: 0.025,
      L: 0.023,
      O: 0.025,
      C: 0.024,
      V: 4240000,
      p_w1_L: 0.023,
      p_w1_H: 0.026,
      p_m1_L: 0.022,
      p_m1_H: 0.026,
      p_m3_L: 0.022,
      p_m3_H: 0.03,
      p_m6_L: 0.022,
      p_m6_H: 0.046,
      p_m12_L: 0.022,
      p_m12_H: 0.056,
    },
    "0622": {
      H: 0.56,
      L: 0.54,
      O: 0.56,
      C: 0.56,
      V: 159200,
      p_w1_L: 0.53,
      p_w1_H: 0.56,
      p_m1_L: 0.52,
      p_m1_H: 0.59,
      p_m3_L: 0.495,
      p_m3_H: 0.59,
      p_m6_L: 0.44,
      p_m6_H: 0.67,
      p_m12_L: 0.44,
      p_m12_H: 0.83,
    },
    "0623": {
      H: 1.14,
      L: 1.13,
      O: 1.14,
      C: 1.14,
      V: 78000,
      p_w1_L: 1.12,
      p_w1_H: 1.18,
      p_m1_L: 1.11,
      p_m1_H: 1.18,
      p_m3_L: 1.1,
      p_m3_H: 1.42,
      p_m6_L: 1.08,
      p_m6_H: 1.42,
      p_m12_L: 0.85,
      p_m12_H: 1.42,
    },
    "0626": {
      H: 2.43,
      L: 2.41,
      O: 2.42,
      C: 2.43,
      V: 204000,
      p_w1_L: 2.41,
      p_w1_H: 2.45,
      p_m1_L: 2.41,
      p_m1_H: 2.52,
      p_m3_L: 2.32,
      p_m3_H: 2.62,
      p_m6_L: 2.32,
      p_m6_H: 2.62,
      p_m12_L: 1.95,
      p_m12_H: 2.68,
    },
    "0627": {
      H: 0.021,
      L: 0.02,
      O: 0.021,
      C: 0.021,
      V: 20583000,
      p_w1_L: 0.018,
      p_w1_H: 0.028,
      p_m1_L: 0.018,
      p_m1_H: 0.033,
      p_m3_L: 0.018,
      p_m3_H: 0.033,
      p_m6_L: 0.018,
      p_m6_H: 0.033,
      p_m12_L: 0.018,
      p_m12_H: 0.05,
    },
    "0628": {
      H: 0.275,
      L: 0.275,
      O: 0.275,
      C: 0.275,
      V: 60000,
      p_w1_L: 0.242,
      p_w1_H: 0.29,
      p_m1_L: 0.242,
      p_m1_H: 0.33,
      p_m3_L: 0.242,
      p_m3_H: 0.42,
      p_m6_L: 0.242,
      p_m6_H: 0.66,
      p_m12_L: 0.242,
      p_m12_H: 1.4,
    },
    "0629": {
      H: 0.29,
      L: 0.29,
      O: 0.29,
      C: 0.29,
      V: 0,
      p_w1_L: 0.25,
      p_w1_H: 0.29,
      p_m1_L: 0.25,
      p_m1_H: 0.3,
      p_m3_L: 0.25,
      p_m3_H: 0.3,
      p_m6_L: 0.25,
      p_m6_H: 0.36,
      p_m12_L: 0.24,
      p_m12_H: 0.4,
    },
    "0630": {
      H: 0.072,
      L: 0.072,
      O: 0.072,
      C: 0.072,
      V: 0,
      p_w1_L: 0.062,
      p_w1_H: 0.075,
      p_m1_L: 0.062,
      p_m1_H: 0.098,
      p_m3_L: 0.062,
      p_m3_H: 0.21,
      p_m6_L: 0.062,
      p_m6_H: 0.24,
      p_m12_L: 0.062,
      p_m12_H: 0.5,
    },
    "0631": {
      H: 8.26,
      L: 8.03,
      O: 8.18,
      C: 8.11,
      V: 1489000,
      p_w1_L: 7.92,
      p_w1_H: 8.28,
      p_m1_L: 7.52,
      p_m1_H: 8.66,
      p_m3_L: 7.52,
      p_m3_H: 10.8,
      p_m6_L: 7.2,
      p_m6_H: 10.8,
      p_m12_L: 4.43,
      p_m12_H: 10.8,
    },
    "0632": {
      H: 0.275,
      L: 0.26,
      O: 0.275,
      C: 0.26,
      V: 1180000,
      p_w1_L: 0.249,
      p_w1_H: 0.42,
      p_m1_L: 0.249,
      p_m1_H: 0.42,
      p_m3_L: 0.247,
      p_m3_H: 0.425,
      p_m6_L: 0.24,
      p_m6_H: 0.425,
      p_m12_L: 0.23,
      p_m12_H: 0.52,
    },
    "0633": {
      H: 0.104,
      L: 0.104,
      O: 0.104,
      C: 0.104,
      V: 0,
      p_w1_L: 0.104,
      p_w1_H: 0.104,
      p_m1_L: 0.104,
      p_m1_H: 0.104,
      p_m3_L: 0.104,
      p_m3_H: 0.104,
      p_m6_L: 0.104,
      p_m6_H: 0.104,
      p_m12_L: 0.101,
      p_m12_H: 0.148,
    },
    "0635": {
      H: 0.8,
      L: 0.77,
      O: 0.77,
      C: 0.79,
      V: 382000,
      p_w1_L: 0.76,
      p_w1_H: 0.8,
      p_m1_L: 0.76,
      p_m1_H: 0.82,
      p_m3_L: 0.76,
      p_m3_H: 0.85,
      p_m6_L: 0.76,
      p_m6_H: 0.98,
      p_m12_L: 0.76,
      p_m12_H: 0.98,
    },
    "0636": {
      H: 21.6,
      L: 20.8,
      O: 20.8,
      C: 21.1,
      V: 824812,
      p_w1_L: 20.8,
      p_w1_H: 22.7,
      p_m1_L: 18,
      p_m1_H: 25.15,
      p_m3_L: 16.2,
      p_m3_H: 26.95,
      p_m6_L: 16.2,
      p_m6_H: 27.15,
      p_m12_L: 15.34,
      p_m12_H: 27.15,
    },
    "0637": {
      H: 0.305,
      L: 0.305,
      O: 0.305,
      C: 0.305,
      V: 22000,
      p_w1_L: 0.285,
      p_w1_H: 0.31,
      p_m1_L: 0.285,
      p_m1_H: 0.325,
      p_m3_L: 0.285,
      p_m3_H: 0.36,
      p_m6_L: 0.285,
      p_m6_H: 0.395,
      p_m12_L: 0.24,
      p_m12_H: 0.49,
    },
    "0638": {
      H: 0.83,
      L: 0.82,
      O: 0.83,
      C: 0.83,
      V: 128000,
      p_w1_L: 0.82,
      p_w1_H: 0.88,
      p_m1_L: 0.71,
      p_m1_H: 0.91,
      p_m3_L: 0.71,
      p_m3_H: 0.91,
      p_m6_L: 0.71,
      p_m6_H: 1.38,
      p_m12_L: 0.71,
      p_m12_H: 2.18,
    },
    "0639": {
      H: 2.15,
      L: 2.09,
      O: 2.11,
      C: 2.14,
      V: 2584000,
      p_w1_L: 2.03,
      p_w1_H: 2.17,
      p_m1_L: 1.88,
      p_m1_H: 2.17,
      p_m3_L: 1.88,
      p_m3_H: 2.9,
      p_m6_L: 1.88,
      p_m6_H: 2.95,
      p_m12_L: 1.76,
      p_m12_H: 2.95,
    },
    "0640": {
      H: 0.7,
      L: 0.68,
      O: 0.68,
      C: 0.7,
      V: 32000,
      p_w1_L: 0.68,
      p_w1_H: 0.7,
      p_m1_L: 0.68,
      p_m1_H: 0.71,
      p_m3_L: 0.65,
      p_m3_H: 0.71,
      p_m6_L: 0.63,
      p_m6_H: 0.78,
      p_m12_L: 0.58,
      p_m12_H: 0.78,
    },
    "0641": {
      H: 0.4,
      L: 0.4,
      O: 0.4,
      C: 0.4,
      V: 0,
      p_w1_L: 0.39,
      p_w1_H: 0.4,
      p_m1_L: 0.355,
      p_m1_H: 0.435,
      p_m3_L: 0.355,
      p_m3_H: 0.455,
      p_m6_L: 0.35,
      p_m6_H: 0.48,
      p_m12_L: 0.33,
      p_m12_H: 0.55,
    },
    "0643": {
      H: 0.18,
      L: 0.18,
      O: 0.18,
      C: 0.18,
      V: 0,
      p_w1_L: 0.171,
      p_w1_H: 0.18,
      p_m1_L: 0.171,
      p_m1_H: 0.21,
      p_m3_L: 0.14,
      p_m3_H: 0.24,
      p_m6_L: 0.12,
      p_m6_H: 0.28,
      p_m12_L: 0.101,
      p_m12_H: 0.49,
    },
    "0645": {
      H: 0.26,
      L: 0.26,
      O: 0.26,
      C: 0.26,
      V: 0,
      p_w1_L: 0.26,
      p_w1_H: 0.26,
      p_m1_L: 0.26,
      p_m1_H: 0.26,
      p_m3_L: 0.26,
      p_m3_H: 0.26,
      p_m6_L: 0.26,
      p_m6_H: 0.32,
      p_m12_L: 0.255,
      p_m12_H: 0.35,
    },
    "0646": {
      H: 0.365,
      L: 0.31,
      O: 0.355,
      C: 0.31,
      V: 667600,
      p_w1_L: 0.28,
      p_w1_H: 0.375,
      p_m1_L: 0.26,
      p_m1_H: 0.41,
      p_m3_L: 0.24,
      p_m3_H: 0.41,
      p_m6_L: 0.024,
      p_m6_H: 0.6,
      p_m12_L: 0.013,
      p_m12_H: 0.6,
    },
    "0650": {
      H: 1.33,
      L: 1.21,
      O: 1.24,
      C: 1.25,
      V: 39554000,
      p_w1_L: 1.15,
      p_w1_H: 1.45,
      p_m1_L: 1.07,
      p_m1_H: 1.45,
      p_m3_L: 0.98,
      p_m3_H: 1.67,
      p_m6_L: 0.94,
      p_m6_H: 1.67,
      p_m12_L: 0.42,
      p_m12_H: 1.67,
    },
    "0651": {
      H: 0.125,
      L: 0.125,
      O: 0.125,
      C: 0.125,
      V: 0,
      p_w1_L: 0.125,
      p_w1_H: 0.125,
      p_m1_L: 0.125,
      p_m1_H: 0.125,
      p_m3_L: 0.125,
      p_m3_H: 0.125,
      p_m6_L: 0.125,
      p_m6_H: 0.125,
      p_m12_L: 0.1,
      p_m12_H: 0.158,
    },
    "0653": {
      H: 0.192,
      L: 0.186,
      O: 0.187,
      C: 0.188,
      V: 7378000,
      p_w1_L: 0.185,
      p_w1_H: 0.192,
      p_m1_L: 0.18,
      p_m1_H: 0.199,
      p_m3_L: 0.144,
      p_m3_H: 0.21,
      p_m6_L: 0.124,
      p_m6_H: 0.28,
      p_m12_L: 0.118,
      p_m12_H: 0.385,
    },
    "0655": {
      H: 0.57,
      L: 0.56,
      O: 0.56,
      C: 0.57,
      V: 23658,
      p_w1_L: 0.55,
      p_w1_H: 0.58,
      p_m1_L: 0.55,
      p_m1_H: 0.67,
      p_m3_L: 0.55,
      p_m3_H: 0.67,
      p_m6_L: 0.55,
      p_m6_H: 0.68,
      p_m12_L: 0.55,
      p_m12_H: 0.72,
    },
    "0656": {
      H: 8.95,
      L: 8.7,
      O: 8.8,
      C: 8.76,
      V: 4534600,
      p_w1_L: 8.46,
      p_w1_H: 8.96,
      p_m1_L: 8.46,
      p_m1_H: 9.3,
      p_m3_L: 8.46,
      p_m3_H: 10.42,
      p_m6_L: 8.46,
      p_m6_H: 11.96,
      p_m12_L: 8.46,
      p_m12_H: 13.42,
    },
    "0657": {
      H: 0.065,
      L: 0.065,
      O: 0.065,
      C: 0.065,
      V: 0,
      p_w1_L: 0.059,
      p_w1_H: 0.065,
      p_m1_L: 0.059,
      p_m1_H: 0.076,
      p_m3_L: 0.042,
      p_m3_H: 0.13,
      p_m6_L: 0.042,
      p_m6_H: 0.13,
      p_m12_L: 0.042,
      p_m12_H: 0.13,
    },
    "0658": {
      H: 6.17,
      L: 5.96,
      O: 6.13,
      C: 6.03,
      V: 2746000,
      p_w1_L: 5.9,
      p_w1_H: 6.57,
      p_m1_L: 5.9,
      p_m1_H: 7.3,
      p_m3_L: 5.52,
      p_m3_H: 7.79,
      p_m6_L: 4.35,
      p_m6_H: 7.79,
      p_m12_L: 4.35,
      p_m12_H: 9.18,
    },
    "0659": {
      H: 7.38,
      L: 7.33,
      O: 7.38,
      C: 7.35,
      V: 1446000,
      p_w1_L: 7.2,
      p_w1_H: 7.44,
      p_m1_L: 7.2,
      p_m1_H: 7.95,
      p_m3_L: 6.77,
      p_m3_H: 7.95,
      p_m6_L: 6.77,
      p_m6_H: 8.64,
      p_m12_L: 6.77,
      p_m12_H: 9.55,
    },
    "0660": {
      H: 0.054,
      L: 0.054,
      O: 0.054,
      C: 0.054,
      V: 0,
      p_w1_L: 0.051,
      p_w1_H: 0.055,
      p_m1_L: 0.051,
      p_m1_H: 0.059,
      p_m3_L: 0.045,
      p_m3_H: 0.078,
      p_m6_L: 0.045,
      p_m6_H: 0.08,
      p_m12_L: 0.021,
      p_m12_H: 0.17,
    },
    "0661": {
      H: 0.083,
      L: 0.08,
      O: 0.078,
      C: 0.08,
      V: 320000,
      p_w1_L: 0.077,
      p_w1_H: 0.097,
      p_m1_L: 0.077,
      p_m1_H: 0.098,
      p_m3_L: 0.077,
      p_m3_H: 0.151,
      p_m6_L: 0.077,
      p_m6_H: 0.151,
      p_m12_L: 0.067,
      p_m12_H: 0.188,
    },
    "0662": {
      H: 3.64,
      L: 3.64,
      O: 3.64,
      C: 3.55,
      V: 2000,
      p_w1_L: 3.6,
      p_w1_H: 3.64,
      p_m1_L: 3.26,
      p_m1_H: 3.65,
      p_m3_L: 3.25,
      p_m3_H: 3.65,
      p_m6_L: 3.25,
      p_m6_H: 3.8,
      p_m12_L: 3.25,
      p_m12_H: 4.15,
    },
    "0663": {
      H: 0.044,
      L: 0.039,
      O: 0.039,
      C: 0.044,
      V: 1262000,
      p_w1_L: 0.038,
      p_w1_H: 0.044,
      p_m1_L: 0.036,
      p_m1_H: 0.045,
      p_m3_L: 0.036,
      p_m3_H: 0.054,
      p_m6_L: 0.036,
      p_m6_H: 0.074,
      p_m12_L: 0.036,
      p_m12_H: 0.168,
    },
    "0665": {
      H: 1.66,
      L: 1.64,
      O: 1.65,
      C: 1.64,
      V: 4145000,
      p_w1_L: 1.6,
      p_w1_H: 1.69,
      p_m1_L: 1.59,
      p_m1_H: 1.77,
      p_m3_L: 1.59,
      p_m3_H: 2.28,
      p_m6_L: 1.59,
      p_m6_H: 2.38,
      p_m12_L: 1.59,
      p_m12_H: 2.79,
    },
    "0667": {
      H: 6.16,
      L: 5.95,
      O: 6.05,
      C: 6.05,
      V: 4932500,
      p_w1_L: 5.86,
      p_w1_H: 7.6,
      p_m1_L: 5.86,
      p_m1_H: 8.73,
      p_m3_L: 5.86,
      p_m3_H: 10.24,
      p_m6_L: 5.86,
      p_m6_H: 16.42,
      p_m12_L: 5.86,
      p_m12_H: 20.85,
    },
    "0668": {
      H: 0.237,
      L: 0.237,
      O: 0.237,
      C: 0.237,
      V: 0,
      p_w1_L: 0.236,
      p_w1_H: 0.237,
      p_m1_L: 0.2,
      p_m1_H: 0.3,
      p_m3_L: 0.2,
      p_m3_H: 0.3,
      p_m6_L: 0.2,
      p_m6_H: 0.3,
      p_m12_L: 0.176,
      p_m12_H: 0.4,
    },
    "0669": {
      H: 168.8,
      L: 165,
      O: 166.1,
      C: 167.4,
      V: 2211280,
      p_w1_L: 163,
      p_w1_H: 173,
      p_m1_L: 156.3,
      p_m1_H: 174.5,
      p_m3_L: 146.5,
      p_m3_H: 174.5,
      p_m6_L: 128.3,
      p_m6_H: 180,
      p_m12_L: 94.3,
      p_m12_H: 180,
    },
    "0670": {
      H: 2.97,
      L: 2.92,
      O: 2.96,
      C: 2.96,
      V: 5160000,
      p_w1_L: 2.67,
      p_w1_H: 2.98,
      p_m1_L: 2.67,
      p_m1_H: 3.13,
      p_m3_L: 2.67,
      p_m3_H: 3.36,
      p_m6_L: 2.66,
      p_m6_H: 3.51,
      p_m12_L: 2.66,
      p_m12_H: 4.08,
    },
    "0672": {
      H: 0.305,
      L: 0.3,
      O: 0.3,
      C: 0.305,
      V: 656800,
      p_w1_L: 0.29,
      p_w1_H: 0.305,
      p_m1_L: 0.275,
      p_m1_H: 0.32,
      p_m3_L: 0.275,
      p_m3_H: 0.365,
      p_m6_L: 0.275,
      p_m6_H: 0.42,
      p_m12_L: 0.27,
      p_m12_H: 0.45,
    },
    "0673": {
      H: 0.066,
      L: 0.062,
      O: 0.064,
      C: 0.066,
      V: 3375000,
      p_w1_L: 0.062,
      p_w1_H: 0.069,
      p_m1_L: 0.062,
      p_m1_H: 0.072,
      p_m3_L: 0.061,
      p_m3_H: 0.075,
      p_m6_L: 0.061,
      p_m6_H: 0.08,
      p_m12_L: 0.035,
      p_m12_H: 0.088,
    },
    "0674": {
      H: 0.157,
      L: 0.157,
      O: 0.157,
      C: 0.157,
      V: 0,
      p_w1_L: 0.157,
      p_w1_H: 0.157,
      p_m1_L: 0.137,
      p_m1_H: 0.173,
      p_m3_L: 0.133,
      p_m3_H: 0.188,
      p_m6_L: 0.098,
      p_m6_H: 0.255,
      p_m12_L: 0.078,
      p_m12_H: 0.295,
    },
    "0675": {
      H: 0.88,
      L: 0.88,
      O: 0.88,
      C: 0.88,
      V: 0,
      p_w1_L: 0.85,
      p_w1_H: 0.88,
      p_m1_L: 0.84,
      p_m1_H: 0.91,
      p_m3_L: 0.81,
      p_m3_H: 1.01,
      p_m6_L: 0.75,
      p_m6_H: 1.05,
      p_m12_L: 0.56,
      p_m12_H: 1.49,
    },
    "0676": {
      H: 0.79,
      L: 0.79,
      O: 0.79,
      C: 0.79,
      V: 0,
      p_w1_L: 0.79,
      p_w1_H: 0.79,
      p_m1_L: 0.72,
      p_m1_H: 0.8,
      p_m3_L: 0.72,
      p_m3_H: 0.81,
      p_m6_L: 0.7,
      p_m6_H: 1.54,
      p_m12_L: 0.7,
      p_m12_H: 1.54,
    },
    "0677": {
      H: 0.51,
      L: 0.5,
      O: 0.5,
      C: 0.51,
      V: 1002000,
      p_w1_L: 0.5,
      p_w1_H: 0.52,
      p_m1_L: 0.5,
      p_m1_H: 0.54,
      p_m3_L: 0.5,
      p_m3_H: 0.63,
      p_m6_L: 0.5,
      p_m6_H: 0.65,
      p_m12_L: 0.5,
      p_m12_H: 0.73,
    },
    "0678": {
      H: 0.97,
      L: 0.91,
      O: 0.91,
      C: 0.94,
      V: 4813000,
      p_w1_L: 0.82,
      p_w1_H: 0.97,
      p_m1_L: 0.69,
      p_m1_H: 1.19,
      p_m3_L: 0.495,
      p_m3_H: 1.19,
      p_m6_L: 0.495,
      p_m6_H: 1.19,
      p_m12_L: 0.26,
      p_m12_H: 1.19,
    },
    "0679": {
      H: 1.31,
      L: 1.31,
      O: 1.31,
      C: 1.31,
      V: 2500,
      p_w1_L: 1.29,
      p_w1_H: 1.31,
      p_m1_L: 1.19,
      p_m1_H: 1.31,
      p_m3_L: 1.08,
      p_m3_H: 1.31,
      p_m6_L: 1.08,
      p_m6_H: 1.31,
      p_m12_L: 1.08,
      p_m12_H: 1.63,
    },
    "0680": {
      H: 0.039,
      L: 0.037,
      O: 0.038,
      C: 0.038,
      V: 8400000,
      p_w1_L: 0.037,
      p_w1_H: 0.04,
      p_m1_L: 0.037,
      p_m1_H: 0.041,
      p_m3_L: 0.037,
      p_m3_H: 0.048,
      p_m6_L: 0.037,
      p_m6_H: 0.065,
      p_m12_L: 0.037,
      p_m12_H: 0.068,
    },
    "0681": {
      H: 0.05,
      L: 0.049,
      O: 0.05,
      C: 0.05,
      V: 2576700,
      p_w1_L: 0.046,
      p_w1_H: 0.051,
      p_m1_L: 0.046,
      p_m1_H: 0.055,
      p_m3_L: 0.046,
      p_m3_H: 0.065,
      p_m6_L: 0.046,
      p_m6_H: 0.065,
      p_m12_L: 0.046,
      p_m12_H: 0.092,
    },
    "0682": {
      H: 0.039,
      L: 0.038,
      O: 0.039,
      C: 0.039,
      V: 306000,
      p_w1_L: 0.037,
      p_w1_H: 0.044,
      p_m1_L: 0.037,
      p_m1_H: 0.051,
      p_m3_L: 0.037,
      p_m3_H: 0.052,
      p_m6_L: 0.037,
      p_m6_H: 0.067,
      p_m12_L: 0.037,
      p_m12_H: 0.115,
    },
    "0683": {
      H: 20.35,
      L: 20.05,
      O: 20.35,
      C: 20.05,
      V: 1104570,
      p_w1_L: 19.96,
      p_w1_H: 20.75,
      p_m1_L: 19.96,
      p_m1_H: 21.9,
      p_m3_L: 19.02,
      p_m3_H: 26.2,
      p_m6_L: 19.02,
      p_m6_H: 26.85,
      p_m12_L: 19.02,
      p_m12_H: 27.5,
    },
    "0684": {
      H: 1.67,
      L: 1.64,
      O: 1.64,
      C: 1.67,
      V: 28000,
      p_w1_L: 1.64,
      p_w1_H: 1.67,
      p_m1_L: 1.52,
      p_m1_H: 1.75,
      p_m3_L: 1.52,
      p_m3_H: 1.9,
      p_m6_L: 1.52,
      p_m6_H: 1.97,
      p_m12_L: 1.5,
      p_m12_H: 1.97,
    },
    "0685": {
      H: 0.305,
      L: 0.305,
      O: 0.305,
      C: 0.305,
      V: 0,
      p_w1_L: 0.305,
      p_w1_H: 0.305,
      p_m1_L: 0.255,
      p_m1_H: 0.34,
      p_m3_L: 0.255,
      p_m3_H: 0.34,
      p_m6_L: 0.255,
      p_m6_H: 0.35,
      p_m12_L: 0.249,
      p_m12_H: 0.355,
    },
    "0686": {
      H: 0.265,
      L: 0.255,
      O: 0.265,
      C: 0.26,
      V: 1012000,
      p_w1_L: 0.255,
      p_w1_H: 0.27,
      p_m1_L: 0.244,
      p_m1_H: 0.28,
      p_m3_L: 0.244,
      p_m3_H: 0.35,
      p_m6_L: 0.244,
      p_m6_H: 0.36,
      p_m12_L: 0.24,
      p_m12_H: 0.36,
    },
    "0687": {
      H: 0.395,
      L: 0.38,
      O: 0.38,
      C: 0.395,
      V: 182000,
      p_w1_L: 0.38,
      p_w1_H: 0.4,
      p_m1_L: 0.365,
      p_m1_H: 0.425,
      p_m3_L: 0.36,
      p_m3_H: 0.465,
      p_m6_L: 0.36,
      p_m6_H: 0.54,
      p_m12_L: 0.36,
      p_m12_H: 0.7,
    },
    "0688": {
      H: 19.3,
      L: 19.04,
      O: 19.2,
      C: 19.26,
      V: 18645000,
      p_w1_L: 17.88,
      p_w1_H: 19.32,
      p_m1_L: 16.3,
      p_m1_H: 19.32,
      p_m3_L: 15.42,
      p_m3_H: 19.32,
      p_m6_L: 15.42,
      p_m6_H: 19.78,
      p_m12_L: 14.92,
      p_m12_H: 21.95,
    },
    "0689": {
      H: 0.019,
      L: 0.018,
      O: 0.019,
      C: 0.018,
      V: 9825800,
      p_w1_L: 0.018,
      p_w1_H: 0.021,
      p_m1_L: 0.018,
      p_m1_H: 0.072,
      p_m3_L: 0.018,
      p_m3_H: 0.093,
      p_m6_L: 0.018,
      p_m6_H: 0.093,
      p_m12_L: 0.018,
      p_m12_H: 0.157,
    },
    "0690": {
      H: 0.093,
      L: 0.088,
      O: 0.092,
      C: 0.088,
      V: 433800,
      p_w1_L: 0.088,
      p_w1_H: 0.099,
      p_m1_L: 0.088,
      p_m1_H: 0.108,
      p_m3_L: 0.086,
      p_m3_H: 0.108,
      p_m6_L: 0.086,
      p_m6_H: 0.124,
      p_m12_L: 0.086,
      p_m12_H: 0.131,
    },
    "0691": {
      H: 2,
      L: 2,
      O: 2,
      C: 2,
      V: 7000,
      p_w1_L: 1.91,
      p_w1_H: 2,
      p_m1_L: 1.83,
      p_m1_H: 2.1,
      p_m3_L: 1.83,
      p_m3_H: 2.43,
      p_m6_L: 1.83,
      p_m6_H: 2.49,
      p_m12_L: 1.74,
      p_m12_H: 2.49,
    },
    "0693": {
      H: 2.01,
      L: 2.01,
      O: 2.01,
      C: 2.01,
      V: 0,
      p_w1_L: 2,
      p_w1_H: 2.03,
      p_m1_L: 2,
      p_m1_H: 2.03,
      p_m3_L: 2,
      p_m3_H: 2.04,
      p_m6_L: 2,
      p_m6_H: 2.07,
      p_m12_L: 2,
      p_m12_H: 2.2,
    },
    "0694": {
      H: 4.93,
      L: 4.74,
      O: 4.74,
      C: 4.85,
      V: 6707300,
      p_w1_L: 4.42,
      p_w1_H: 4.93,
      p_m1_L: 4.4,
      p_m1_H: 5.36,
      p_m3_L: 4.11,
      p_m3_H: 5.36,
      p_m6_L: 4.11,
      p_m6_H: 5.52,
      p_m12_L: 4.11,
      p_m12_H: 6.96,
    },
    "0695": {
      H: 4.76,
      L: 4.66,
      O: 4.76,
      C: 4.66,
      V: 272000,
      p_w1_L: 4.44,
      p_w1_H: 4.78,
      p_m1_L: 4.44,
      p_m1_H: 5.06,
      p_m3_L: 4.14,
      p_m3_H: 6.02,
      p_m6_L: 1.69,
      p_m6_H: 6.98,
      p_m12_L: 0.5,
      p_m12_H: 6.98,
    },
    "0696": {
      H: 13.82,
      L: 13.48,
      O: 13.52,
      C: 13.68,
      V: 2368100,
      p_w1_L: 12.76,
      p_w1_H: 13.82,
      p_m1_L: 12.46,
      p_m1_H: 14.84,
      p_m3_L: 12.46,
      p_m3_H: 16.76,
      p_m6_L: 12.24,
      p_m6_H: 17.22,
      p_m12_L: 12.24,
      p_m12_H: 21.35,
    },
    "0697": {
      H: 1.7,
      L: 1.67,
      O: 1.69,
      C: 1.67,
      V: 6500000,
      p_w1_L: 1.66,
      p_w1_H: 1.9,
      p_m1_L: 1.61,
      p_m1_H: 1.97,
      p_m3_L: 1.59,
      p_m3_H: 1.97,
      p_m6_L: 1.59,
      p_m6_H: 1.97,
      p_m12_L: 1.59,
      p_m12_H: 2.42,
    },
    "0698": {
      H: 0.255,
      L: 0.243,
      O: 0.244,
      C: 0.249,
      V: 16380000,
      p_w1_L: 0.24,
      p_w1_H: 0.255,
      p_m1_L: 0.24,
      p_m1_H: 0.31,
      p_m3_L: 0.236,
      p_m3_H: 0.345,
      p_m6_L: 0.236,
      p_m6_H: 0.65,
      p_m12_L: 0.236,
      p_m12_H: 0.69,
    },
    "0700": {
      H: 470.4,
      L: 465,
      O: 467.6,
      C: 467,
      V: 15173300,
      p_w1_L: 447.6,
      p_w1_H: 473.6,
      p_m1_L: 447.6,
      p_m1_H: 509,
      p_m3_L: 443.2,
      p_m3_H: 529,
      p_m6_L: 412.2,
      p_m6_H: 608.5,
      p_m12_L: 412.2,
      p_m12_H: 775.5,
    },
    "0701": {
      H: 0.4,
      L: 0.4,
      O: 0.4,
      C: 0.4,
      V: 0,
      p_w1_L: 0.385,
      p_w1_H: 0.4,
      p_m1_L: 0.385,
      p_m1_H: 0.41,
      p_m3_L: 0.365,
      p_m3_H: 0.42,
      p_m6_L: 0.36,
      p_m6_H: 0.44,
      p_m12_L: 0.315,
      p_m12_H: 0.44,
    },
    "0702": {
      H: 0.17,
      L: 0.151,
      O: 0.169,
      C: 0.156,
      V: 2762000,
      p_w1_L: 0.151,
      p_w1_H: 0.182,
      p_m1_L: 0.151,
      p_m1_H: 0.231,
      p_m3_L: 0.151,
      p_m3_H: 0.3,
      p_m6_L: 0.151,
      p_m6_H: 0.325,
      p_m12_L: 0.058,
      p_m12_H: 0.46,
    },
    "0703": {
      H: 0.161,
      L: 0.161,
      O: 0.161,
      C: 0.161,
      V: 0,
      p_w1_L: 0.161,
      p_w1_H: 0.167,
      p_m1_L: 0.161,
      p_m1_H: 0.188,
      p_m3_L: 0.159,
      p_m3_H: 0.189,
      p_m6_L: 0.152,
      p_m6_H: 0.2,
      p_m12_L: 0.147,
      p_m12_H: 0.255,
    },
    "0704": {
      H: 0.305,
      L: 0.305,
      O: 0.305,
      C: 0.305,
      V: 0,
      p_w1_L: 0.305,
      p_w1_H: 0.305,
      p_m1_L: 0.305,
      p_m1_H: 0.305,
      p_m3_L: 0.305,
      p_m3_H: 0.305,
      p_m6_L: 0.305,
      p_m6_H: 0.305,
      p_m12_L: 0.25,
      p_m12_H: 0.435,
    },
    "0706": {
      H: 0.013,
      L: 0.013,
      O: 0.013,
      C: 0.013,
      V: 0,
      p_w1_L: 0.013,
      p_w1_H: 0.013,
      p_m1_L: 0.013,
      p_m1_H: 0.013,
      p_m3_L: 0.013,
      p_m3_H: 0.013,
      p_m6_L: 0.013,
      p_m6_H: 0.013,
      p_m12_L: 0.013,
      p_m12_H: 0.013,
    },
    "0707": {
      H: 0.024,
      L: 0.022,
      O: 0.022,
      C: 0.023,
      V: 9394000,
      p_w1_L: 0.022,
      p_w1_H: 0.025,
      p_m1_L: 0.022,
      p_m1_H: 0.029,
      p_m3_L: 0.022,
      p_m3_H: 0.37,
      p_m6_L: 0.022,
      p_m6_H: 0.46,
      p_m12_L: 0.022,
      p_m12_H: 0.46,
    },
    "0708": {
      H: 3.35,
      L: 3.04,
      O: 3.3,
      C: 3.08,
      V: 45377500,
      p_w1_L: 2.75,
      p_w1_H: 3.63,
      p_m1_L: 2.75,
      p_m1_H: 5.13,
      p_m3_L: 1.66,
      p_m3_H: 7.25,
      p_m6_L: 1.66,
      p_m6_H: 34.5,
      p_m12_L: 1.66,
      p_m12_H: 72.45,
    },
    "0709": {
      H: 1.5,
      L: 1.46,
      O: 1.48,
      C: 1.46,
      V: 922000,
      p_w1_L: 1.46,
      p_w1_H: 1.52,
      p_m1_L: 1.46,
      p_m1_H: 1.6,
      p_m3_L: 1.46,
      p_m3_H: 1.64,
      p_m6_L: 1.46,
      p_m6_H: 1.79,
      p_m12_L: 1.12,
      p_m12_H: 1.91,
    },
    "0710": {
      H: 9.73,
      L: 9.29,
      O: 9.3,
      C: 9.55,
      V: 1407000,
      p_w1_L: 8.84,
      p_w1_H: 9.76,
      p_m1_L: 8.61,
      p_m1_H: 11.32,
      p_m3_L: 6.75,
      p_m3_H: 12.8,
      p_m6_L: 4,
      p_m6_H: 12.8,
      p_m12_L: 2.57,
      p_m12_H: 12.8,
    },
    "0711": {
      H: 0.6,
      L: 0.59,
      O: 0.59,
      C: 0.6,
      V: 414000,
      p_w1_L: 0.59,
      p_w1_H: 0.6,
      p_m1_L: 0.59,
      p_m1_H: 0.61,
      p_m3_L: 0.57,
      p_m3_H: 0.65,
      p_m6_L: 0.53,
      p_m6_H: 0.65,
      p_m12_L: 0.53,
      p_m12_H: 0.72,
    },
    "0712": {
      H: 0.415,
      L: 0.39,
      O: 0.4,
      C: 0.41,
      V: 1710000,
      p_w1_L: 0.375,
      p_w1_H: 0.43,
      p_m1_L: 0.375,
      p_m1_H: 0.48,
      p_m3_L: 0.375,
      p_m3_H: 0.58,
      p_m6_L: 0.375,
      p_m6_H: 0.76,
      p_m12_L: 0.26,
      p_m12_H: 1.09,
    },
    "0713": {
      H: 0.9,
      L: 0.86,
      O: 0.87,
      C: 0.89,
      V: 608000,
      p_w1_L: 0.84,
      p_w1_H: 0.92,
      p_m1_L: 0.84,
      p_m1_H: 1.21,
      p_m3_L: 0.58,
      p_m3_H: 1.21,
      p_m6_L: 0.58,
      p_m6_H: 1.21,
      p_m12_L: 0.34,
      p_m12_H: 1.21,
    },
    "0715": {
      H: 0.129,
      L: 0.129,
      O: 0.129,
      C: 0.129,
      V: 0,
      p_w1_L: 0.12,
      p_w1_H: 0.13,
      p_m1_L: 0.109,
      p_m1_H: 0.13,
      p_m3_L: 0.096,
      p_m3_H: 0.152,
      p_m6_L: 0.096,
      p_m6_H: 0.19,
      p_m12_L: 0.096,
      p_m12_H: 0.202,
    },
    "0716": {
      H: 1.37,
      L: 1.26,
      O: 1.29,
      C: 1.28,
      V: 41846000,
      p_w1_L: 1.15,
      p_w1_H: 1.37,
      p_m1_L: 0.81,
      p_m1_H: 1.37,
      p_m3_L: 0.81,
      p_m3_H: 1.37,
      p_m6_L: 0.64,
      p_m6_H: 1.37,
      p_m12_L: 0.495,
      p_m12_H: 1.37,
    },
    "0717": {
      H: 0.084,
      L: 0.078,
      O: 0.083,
      C: 0.08,
      V: 21720000,
      p_w1_L: 0.078,
      p_w1_H: 0.108,
      p_m1_L: 0.078,
      p_m1_H: 0.115,
      p_m3_L: 0.078,
      p_m3_H: 0.135,
      p_m6_L: 0.078,
      p_m6_H: 0.151,
      p_m12_L: 0.078,
      p_m12_H: 0.209,
    },
    "0718": {
      H: 0.1,
      L: 0.095,
      O: 0.095,
      C: 0.1,
      V: 355000,
      p_w1_L: 0.095,
      p_w1_H: 0.106,
      p_m1_L: 0.091,
      p_m1_H: 0.106,
      p_m3_L: 0.085,
      p_m3_H: 0.109,
      p_m6_L: 0.085,
      p_m6_H: 0.185,
      p_m12_L: 0.08,
      p_m12_H: 0.249,
    },
    "0719": {
      H: 4.54,
      L: 4.18,
      O: 4.29,
      C: 4.44,
      V: 1857000,
      p_w1_L: 3.99,
      p_w1_H: 4.73,
      p_m1_L: 3.72,
      p_m1_H: 4.73,
      p_m3_L: 3.72,
      p_m3_H: 4.73,
      p_m6_L: 3.61,
      p_m6_H: 4.73,
      p_m12_L: 3.4,
      p_m12_H: 5.7,
    },
    "0720": {
      H: 0.166,
      L: 0.154,
      O: 0.154,
      C: 0.166,
      V: 250000,
      p_w1_L: 0.152,
      p_w1_H: 0.166,
      p_m1_L: 0.152,
      p_m1_H: 0.17,
      p_m3_L: 0.152,
      p_m3_H: 0.174,
      p_m6_L: 0.152,
      p_m6_H: 0.207,
      p_m12_L: 0.152,
      p_m12_H: 0.21,
    },
    "0721": {
      H: 0.104,
      L: 0.096,
      O: 0.101,
      C: 0.1,
      V: 870000,
      p_w1_L: 0.096,
      p_w1_H: 0.112,
      p_m1_L: 0.094,
      p_m1_H: 0.124,
      p_m3_L: 0.091,
      p_m3_H: 0.135,
      p_m6_L: 0.091,
      p_m6_H: 0.145,
      p_m12_L: 0.091,
      p_m12_H: 0.2,
    },
    "0722": {
      H: 0.8,
      L: 0.78,
      O: 0.78,
      C: 0.8,
      V: 436000,
      p_w1_L: 0.76,
      p_w1_H: 0.85,
      p_m1_L: 0.76,
      p_m1_H: 0.85,
      p_m3_L: 0.75,
      p_m3_H: 0.97,
      p_m6_L: 0.75,
      p_m6_H: 1.23,
      p_m12_L: 0.65,
      p_m12_H: 1.29,
    },
    "0723": {
      H: 0.016,
      L: 0.015,
      O: 0.015,
      C: 0.016,
      V: 589148,
      p_w1_L: 0.015,
      p_w1_H: 0.017,
      p_m1_L: 0.015,
      p_m1_H: 0.021,
      p_m3_L: 0.015,
      p_m3_H: 0.024,
      p_m6_L: 0.015,
      p_m6_H: 0.055,
      p_m12_L: 0.015,
      p_m12_H: 0.064,
    },
    "0724": {
      H: 0.04,
      L: 0.038,
      O: 0.04,
      C: 0.038,
      V: 370000,
      p_w1_L: 0.038,
      p_w1_H: 0.046,
      p_m1_L: 0.038,
      p_m1_H: 0.05,
      p_m3_L: 0.038,
      p_m3_H: 0.052,
      p_m6_L: 0.038,
      p_m6_H: 0.065,
      p_m12_L: 0.037,
      p_m12_H: 0.076,
    },
    "0725": {
      H: 0.79,
      L: 0.79,
      O: 0.79,
      C: 0.79,
      V: 32000,
      p_w1_L: 0.79,
      p_w1_H: 0.79,
      p_m1_L: 0.79,
      p_m1_H: 0.9,
      p_m3_L: 0.7,
      p_m3_H: 1.2,
      p_m6_L: 0.65,
      p_m6_H: 1.2,
      p_m12_L: 0.55,
      p_m12_H: 1.2,
    },
    "0726": {
      H: 0.87,
      L: 0.84,
      O: 0.87,
      C: 0.87,
      V: 140000,
      p_w1_L: 0.8,
      p_w1_H: 0.88,
      p_m1_L: 0.75,
      p_m1_H: 0.89,
      p_m3_L: 0.74,
      p_m3_H: 0.96,
      p_m6_L: 0.7,
      p_m6_H: 1.08,
      p_m12_L: 0.7,
      p_m12_H: 1.43,
    },
    "0727": {
      H: 0.143,
      L: 0.143,
      O: 0.143,
      C: 0.143,
      V: 0,
      p_w1_L: 0.136,
      p_w1_H: 0.145,
      p_m1_L: 0.135,
      p_m1_H: 0.15,
      p_m3_L: 0.114,
      p_m3_H: 0.47,
      p_m6_L: 0.11,
      p_m6_H: 0.47,
      p_m12_L: 0.11,
      p_m12_H: 0.51,
    },
    "0728": {
      H: 2.53,
      L: 2.51,
      O: 2.52,
      C: 2.52,
      V: 39770700,
      p_w1_L: 2.49,
      p_w1_H: 2.6,
      p_m1_L: 2.49,
      p_m1_H: 2.67,
      p_m3_L: 2.49,
      p_m3_H: 2.85,
      p_m6_L: 2.3,
      p_m6_H: 3.24,
      p_m12_L: 1.82,
      p_m12_H: 3.24,
    },
    "0729": {
      H: 0.19,
      L: 0.19,
      O: 0.19,
      C: 0.19,
      V: 0,
      p_w1_L: 0.19,
      p_w1_H: 0.19,
      p_m1_L: 0.19,
      p_m1_H: 0.19,
      p_m3_L: 0.19,
      p_m3_H: 0.19,
      p_m6_L: 0.19,
      p_m6_H: 0.19,
      p_m12_L: 0.19,
      p_m12_H: 0.19,
    },
    "0730": {
      H: 0.169,
      L: 0.154,
      O: 0.154,
      C: 0.167,
      V: 841750,
      p_w1_L: 0.149,
      p_w1_H: 0.169,
      p_m1_L: 0.138,
      p_m1_H: 0.175,
      p_m3_L: 0.123,
      p_m3_H: 0.175,
      p_m6_L: 0.119,
      p_m6_H: 0.18,
      p_m12_L: 0.108,
      p_m12_H: 0.335,
    },
    "0731": {
      H: 0.365,
      L: 0.365,
      O: 0.365,
      C: 0.365,
      V: 0,
      p_w1_L: 0.365,
      p_w1_H: 0.365,
      p_m1_L: 0.365,
      p_m1_H: 0.365,
      p_m3_L: 0.365,
      p_m3_H: 0.365,
      p_m6_L: 0.365,
      p_m6_H: 0.365,
      p_m12_L: 0.365,
      p_m12_H: 0.365,
    },
    "0732": {
      H: 2.54,
      L: 2.47,
      O: 2.5,
      C: 2.48,
      V: 4566000,
      p_w1_L: 2.42,
      p_w1_H: 2.71,
      p_m1_L: 2.26,
      p_m1_H: 2.74,
      p_m3_L: 2.08,
      p_m3_H: 2.98,
      p_m6_L: 1.58,
      p_m6_H: 3.09,
      p_m12_L: 0.9,
      p_m12_H: 3.09,
    },
    "0733": {
      H: 1.38,
      L: 1.31,
      O: 1.38,
      C: 1.31,
      V: 436000,
      p_w1_L: 1.31,
      p_w1_H: 2.02,
      p_m1_L: 1.31,
      p_m1_H: 2.57,
      p_m3_L: 1.31,
      p_m3_H: 3.1,
      p_m6_L: 1.31,
      p_m6_H: 3.8,
      p_m12_L: 1.31,
      p_m12_H: 3.9,
    },
    "0736": {
      H: 0.42,
      L: 0.41,
      O: 0.41,
      C: 0.415,
      V: 246240,
      p_w1_L: 0.38,
      p_w1_H: 0.44,
      p_m1_L: 0.38,
      p_m1_H: 0.51,
      p_m3_L: 0.38,
      p_m3_H: 0.51,
      p_m6_L: 0.38,
      p_m6_H: 0.91,
      p_m12_L: 0.38,
      p_m12_H: 1.38,
    },
    "0737": {
      H: 2.81,
      L: 2.8,
      O: 2.8,
      C: 2.8,
      V: 15250,
      p_w1_L: 2.77,
      p_w1_H: 2.81,
      p_m1_L: 2.77,
      p_m1_H: 2.85,
      p_m3_L: 2.73,
      p_m3_H: 2.98,
      p_m6_L: 2.73,
      p_m6_H: 3.15,
      p_m12_L: 2.51,
      p_m12_H: 3.65,
    },
    "0738": {
      H: 0.64,
      L: 0.64,
      O: 0.64,
      C: 0.64,
      V: 0,
      p_w1_L: 0.62,
      p_w1_H: 0.68,
      p_m1_L: 0.62,
      p_m1_H: 0.69,
      p_m3_L: 0.61,
      p_m3_H: 0.75,
      p_m6_L: 0.61,
      p_m6_H: 1.55,
      p_m12_L: 0.61,
      p_m12_H: 1.55,
    },
    "0743": {
      H: 5.27,
      L: 5.13,
      O: 5.27,
      C: 5.15,
      V: 3843500,
      p_w1_L: 4.76,
      p_w1_H: 5.27,
      p_m1_L: 4.76,
      p_m1_H: 6.4,
      p_m3_L: 4.76,
      p_m3_H: 7.46,
      p_m6_L: 4.76,
      p_m6_H: 7.67,
      p_m12_L: 4.76,
      p_m12_H: 8.34,
    },
    "0745": {
      H: 0.19,
      L: 0.178,
      O: 0.19,
      C: 0.182,
      V: 4228250,
      p_w1_L: 0.173,
      p_w1_H: 0.209,
      p_m1_L: 0.155,
      p_m1_H: 0.209,
      p_m3_L: 0.141,
      p_m3_H: 0.209,
      p_m6_L: 0.139,
      p_m6_H: 0.209,
      p_m12_L: 0.139,
      p_m12_H: 0.25,
    },
    "0746": {
      H: 8.19,
      L: 7.8,
      O: 7.8,
      C: 8.14,
      V: 938000,
      p_w1_L: 7.46,
      p_w1_H: 8.19,
      p_m1_L: 7.46,
      p_m1_H: 9,
      p_m3_L: 7.42,
      p_m3_H: 13.78,
      p_m6_L: 4.6,
      p_m6_H: 13.78,
      p_m12_L: 3.11,
      p_m12_H: 13.78,
    },
    "0747": {
      H: 0.139,
      L: 0.119,
      O: 0.119,
      C: 0.119,
      V: 506000,
      p_w1_L: 0.113,
      p_w1_H: 0.139,
      p_m1_L: 0.112,
      p_m1_H: 0.139,
      p_m3_L: 0.112,
      p_m3_H: 0.15,
      p_m6_L: 0.112,
      p_m6_H: 0.161,
      p_m12_L: 0.091,
      p_m12_H: 0.249,
    },
    "0750": {
      H: 1.98,
      L: 1.9,
      O: 1.95,
      C: 1.94,
      V: 7377000,
      p_w1_L: 1.76,
      p_w1_H: 2,
      p_m1_L: 1.71,
      p_m1_H: 2.08,
      p_m3_L: 1.5,
      p_m3_H: 2.91,
      p_m6_L: 0.99,
      p_m6_H: 2.91,
      p_m12_L: 0.74,
      p_m12_H: 2.91,
    },
    "0751": {
      H: 5.33,
      L: 4.87,
      O: 4.94,
      C: 4.95,
      V: 52990700,
      p_w1_L: 4.6,
      p_w1_H: 5.33,
      p_m1_L: 4.52,
      p_m1_H: 6.61,
      p_m3_L: 2.1,
      p_m3_H: 6.61,
      p_m6_L: 1.97,
      p_m6_H: 6.61,
      p_m12_L: 1.97,
      p_m12_H: 6.61,
    },
    "0752": {
      H: 1.3,
      L: 1.28,
      O: 1.28,
      C: 1.29,
      V: 56000,
      p_w1_L: 1.26,
      p_w1_H: 1.3,
      p_m1_L: 1.26,
      p_m1_H: 1.35,
      p_m3_L: 1.15,
      p_m3_H: 1.37,
      p_m6_L: 1.13,
      p_m6_H: 1.4,
      p_m12_L: 1.13,
      p_m12_H: 1.49,
    },
    "0753": {
      H: 5.43,
      L: 5.21,
      O: 5.21,
      C: 5.37,
      V: 18846400,
      p_w1_L: 4.75,
      p_w1_H: 5.43,
      p_m1_L: 4.75,
      p_m1_H: 5.83,
      p_m3_L: 4.75,
      p_m3_H: 5.83,
      p_m6_L: 4.67,
      p_m6_H: 6.33,
      p_m12_L: 4.67,
      p_m12_H: 7.44,
    },
    "0754": {
      H: 19.4,
      L: 18.46,
      O: 18.46,
      C: 19.28,
      V: 1387650,
      p_w1_L: 17.6,
      p_w1_H: 19.4,
      p_m1_L: 17.32,
      p_m1_H: 20.15,
      p_m3_L: 17.18,
      p_m3_H: 29,
      p_m6_L: 17.18,
      p_m6_H: 36.45,
      p_m12_L: 17.18,
      p_m12_H: 39.8,
    },
    "0755": {
      H: 0.032,
      L: 0.03,
      O: 0.03,
      C: 0.032,
      V: 615000,
      p_w1_L: 0.03,
      p_w1_H: 0.033,
      p_m1_L: 0.03,
      p_m1_H: 0.04,
      p_m3_L: 0.03,
      p_m3_H: 0.051,
      p_m6_L: 0.03,
      p_m6_H: 0.063,
      p_m12_L: 0.03,
      p_m12_H: 0.104,
    },
    "0756": {
      H: 0.108,
      L: 0.101,
      O: 0.104,
      C: 0.101,
      V: 648000,
      p_w1_L: 0.097,
      p_w1_H: 0.114,
      p_m1_L: 0.097,
      p_m1_H: 0.118,
      p_m3_L: 0.088,
      p_m3_H: 0.13,
      p_m6_L: 0.088,
      p_m6_H: 0.186,
      p_m12_L: 0.088,
      p_m12_H: 0.33,
    },
    "0757": {
      H: 0.43,
      L: 0.405,
      O: 0.405,
      C: 0.425,
      V: 5606000,
      p_w1_L: 0.395,
      p_w1_H: 0.435,
      p_m1_L: 0.395,
      p_m1_H: 0.485,
      p_m3_L: 0.395,
      p_m3_H: 0.58,
      p_m6_L: 0.365,
      p_m6_H: 0.59,
      p_m12_L: 0.25,
      p_m12_H: 0.75,
    },
    "0758": {
      H: 0.18,
      L: 0.165,
      O: 0.165,
      C: 0.18,
      V: 18000,
      p_w1_L: 0.14,
      p_w1_H: 0.197,
      p_m1_L: 0.14,
      p_m1_H: 0.21,
      p_m3_L: 0.14,
      p_m3_H: 0.25,
      p_m6_L: 0.13,
      p_m6_H: 0.35,
      p_m12_L: 0.13,
      p_m12_H: 0.425,
    },
    "0759": {
      H: 0.65,
      L: 0.65,
      O: 0.65,
      C: 0.65,
      V: 2000,
      p_w1_L: 0.62,
      p_w1_H: 0.66,
      p_m1_L: 0.62,
      p_m1_H: 0.72,
      p_m3_L: 0.62,
      p_m3_H: 0.72,
      p_m6_L: 0.62,
      p_m6_H: 0.84,
      p_m12_L: 0.62,
      p_m12_H: 1,
    },
    "0760": {
      H: 0.02,
      L: 0.018,
      O: 0.018,
      C: 0.02,
      V: 210000,
      p_w1_L: 0.018,
      p_w1_H: 0.02,
      p_m1_L: 0.018,
      p_m1_H: 0.022,
      p_m3_L: 0.017,
      p_m3_H: 0.025,
      p_m6_L: 0.017,
      p_m6_H: 0.038,
      p_m12_L: 0.017,
      p_m12_H: 0.05,
    },
    "0762": {
      H: 3.85,
      L: 3.83,
      O: 3.85,
      C: 3.84,
      V: 20838900,
      p_w1_L: 3.82,
      p_w1_H: 3.94,
      p_m1_L: 3.82,
      p_m1_H: 4.1,
      p_m3_L: 3.82,
      p_m3_H: 4.2,
      p_m6_L: 3.82,
      p_m6_H: 4.73,
      p_m12_L: 3.82,
      p_m12_H: 5.5,
    },
    "0763": {
      H: 21,
      L: 20.35,
      O: 20.85,
      C: 20.45,
      V: 3955690,
      p_w1_L: 20.15,
      p_w1_H: 21.4,
      p_m1_L: 20.15,
      p_m1_H: 23.4,
      p_m3_L: 20.15,
      p_m3_H: 28.45,
      p_m6_L: 20.15,
      p_m6_H: 31.75,
      p_m12_L: 17.84,
      p_m12_H: 31.75,
    },
    "0764": {
      H: 0.193,
      L: 0.193,
      O: 0.193,
      C: 0.193,
      V: 0,
      p_w1_L: 0.192,
      p_w1_H: 0.196,
      p_m1_L: 0.192,
      p_m1_H: 0.214,
      p_m3_L: 0.192,
      p_m3_H: 0.249,
      p_m6_L: 0.192,
      p_m6_H: 0.335,
      p_m12_L: 0.171,
      p_m12_H: 0.345,
    },
    "0765": {
      H: 0.8,
      L: 0.8,
      O: 0.8,
      C: 0.8,
      V: 2000,
      p_w1_L: 0.8,
      p_w1_H: 0.84,
      p_m1_L: 0.8,
      p_m1_H: 1.15,
      p_m3_L: 0.7,
      p_m3_H: 1.49,
      p_m6_L: 0.7,
      p_m6_H: 1.65,
      p_m12_L: 0.355,
      p_m12_H: 3.8,
    },
    "0766": {
      H: 0.035,
      L: 0.035,
      O: 0.035,
      C: 0.035,
      V: 0,
      p_w1_L: 0.035,
      p_w1_H: 0.035,
      p_m1_L: 0.035,
      p_m1_H: 0.035,
      p_m3_L: 0.035,
      p_m3_H: 0.035,
      p_m6_L: 0.032,
      p_m6_H: 0.048,
      p_m12_L: 0.016,
      p_m12_H: 0.048,
    },
    "0767": {
      H: 0.32,
      L: 0.29,
      O: 0.32,
      C: 0.31,
      V: 4480000,
      p_w1_L: 0.28,
      p_w1_H: 0.365,
      p_m1_L: 0.28,
      p_m1_H: 0.485,
      p_m3_L: 0.28,
      p_m3_H: 0.61,
      p_m6_L: 0.28,
      p_m6_H: 0.75,
      p_m12_L: 0.26,
      p_m12_H: 0.99,
    },
    "0768": {
      H: 0.045,
      L: 0.045,
      O: 0.045,
      C: 0.045,
      V: 0,
      p_w1_L: 0.041,
      p_w1_H: 0.049,
      p_m1_L: 0.041,
      p_m1_H: 0.054,
      p_m3_L: 0.041,
      p_m3_H: 0.065,
      p_m6_L: 0.041,
      p_m6_H: 0.08,
      p_m12_L: 0.031,
      p_m12_H: 0.114,
    },
    "0769": {
      H: 0.91,
      L: 0.87,
      O: 0.89,
      C: 0.88,
      V: 16738000,
      p_w1_L: 0.74,
      p_w1_H: 0.91,
      p_m1_L: 0.7,
      p_m1_H: 0.91,
      p_m3_L: 0.7,
      p_m3_H: 0.99,
      p_m6_L: 0.7,
      p_m6_H: 1.13,
      p_m12_L: 0.4,
      p_m12_H: 1.46,
    },
    "0770": {
      H: 0.236,
      L: 0.236,
      O: 0.236,
      C: 0.236,
      V: 0,
      p_w1_L: 0.236,
      p_w1_H: 0.236,
      p_m1_L: 0.22,
      p_m1_H: 0.3,
      p_m3_L: 0.2,
      p_m3_H: 0.3,
      p_m6_L: 0.2,
      p_m6_H: 0.38,
      p_m12_L: 0.2,
      p_m12_H: 0.39,
    },
    "0771": {
      H: 1.27,
      L: 1.26,
      O: 1.27,
      C: 1.27,
      V: 250000,
      p_w1_L: 1.24,
      p_w1_H: 1.31,
      p_m1_L: 1.24,
      p_m1_H: 1.48,
      p_m3_L: 1.18,
      p_m3_H: 1.64,
      p_m6_L: 1.07,
      p_m6_H: 1.68,
      p_m12_L: 1.07,
      p_m12_H: 2.3,
    },
    "0772": {
      H: 56.95,
      L: 54.7,
      O: 54.7,
      C: 55.85,
      V: 2024810,
      p_w1_L: 50.75,
      p_w1_H: 56.95,
      p_m1_L: 50.75,
      p_m1_H: 60.75,
      p_m3_L: 50.75,
      p_m3_H: 70.8,
      p_m6_L: 50.75,
      p_m6_H: 94.8,
      p_m12_L: 50.75,
      p_m12_H: 95.75,
    },
    "0775": {
      H: 0.76,
      L: 0.73,
      O: 0.75,
      C: 0.73,
      V: 7698000,
      p_w1_L: 0.71,
      p_w1_H: 0.78,
      p_m1_L: 0.71,
      p_m1_H: 0.8,
      p_m3_L: 0.7,
      p_m3_H: 0.82,
      p_m6_L: 0.7,
      p_m6_H: 0.96,
      p_m12_L: 0.7,
      p_m12_H: 0.98,
    },
    "0776": {
      H: 16.66,
      L: 15.9,
      O: 16.2,
      C: 16.42,
      V: 164500,
      p_w1_L: 14.36,
      p_w1_H: 19.74,
      p_m1_L: 14.36,
      p_m1_H: 34.7,
      p_m3_L: 10.84,
      p_m3_H: 34.7,
      p_m6_L: 10.48,
      p_m6_H: 34.7,
      p_m12_L: 5.24,
      p_m12_H: 34.7,
    },
    "0777": {
      H: 18.8,
      L: 18.4,
      O: 18.5,
      C: 18.62,
      V: 1066500,
      p_w1_L: 17.68,
      p_w1_H: 18.8,
      p_m1_L: 16.3,
      p_m1_H: 18.8,
      p_m3_L: 16.24,
      p_m3_H: 19.38,
      p_m6_L: 14.16,
      p_m6_H: 22.65,
      p_m12_L: 14.16,
      p_m12_H: 23.8,
    },
    "0778": {
      H: 7.98,
      L: 7.88,
      O: 7.94,
      C: 7.9,
      V: 1344740,
      p_w1_L: 7.83,
      p_w1_H: 8.03,
      p_m1_L: 7.83,
      p_m1_H: 8.45,
      p_m3_L: 7.4,
      p_m3_H: 8.45,
      p_m6_L: 7.4,
      p_m6_H: 8.88,
      p_m12_L: 6.93,
      p_m12_H: 8.88,
    },
    "0780": {
      H: 18,
      L: 17.32,
      O: 17.8,
      C: 17.36,
      V: 3820160,
      p_w1_L: 15.88,
      p_w1_H: 18.02,
      p_m1_L: 15.88,
      p_m1_H: 18.2,
      p_m3_L: 15.88,
      p_m3_H: 19.5,
      p_m6_L: 14.72,
      p_m6_H: 20.8,
      p_m12_L: 13.24,
      p_m12_H: 21.35,
    },
    "0784": {
      H: 0.15,
      L: 0.15,
      O: 0.15,
      C: 0.15,
      V: 10000,
      p_w1_L: 0.15,
      p_w1_H: 0.153,
      p_m1_L: 0.15,
      p_m1_H: 0.172,
      p_m3_L: 0.14,
      p_m3_H: 0.172,
      p_m6_L: 0.132,
      p_m6_H: 0.248,
      p_m12_L: 0.132,
      p_m12_H: 0.31,
    },
    "0787": {
      H: 0.189,
      L: 0.189,
      O: 0.189,
      C: 0.189,
      V: 0,
      p_w1_L: 0.189,
      p_w1_H: 0.189,
      p_m1_L: 0.189,
      p_m1_H: 0.189,
      p_m3_L: 0.189,
      p_m3_H: 0.189,
      p_m6_L: 0.18,
      p_m6_H: 0.2,
      p_m12_L: 0.17,
      p_m12_H: 0.28,
    },
    "0788": {
      H: 0.93,
      L: 0.91,
      O: 0.92,
      C: 0.92,
      V: 142472000,
      p_w1_L: 0.91,
      p_w1_H: 1,
      p_m1_L: 0.91,
      p_m1_H: 1.03,
      p_m3_L: 0.91,
      p_m3_H: 1.09,
      p_m6_L: 0.91,
      p_m6_H: 1.12,
      p_m12_L: 0.91,
      p_m12_H: 1.31,
    },
    "0789": {
      H: 0.085,
      L: 0.084,
      O: 0.084,
      C: 0.085,
      V: 50000,
      p_w1_L: 0.084,
      p_w1_H: 0.09,
      p_m1_L: 0.082,
      p_m1_H: 0.11,
      p_m3_L: 0.082,
      p_m3_H: 0.13,
      p_m6_L: 0.082,
      p_m6_H: 0.135,
      p_m12_L: 0.082,
      p_m12_H: 0.241,
    },
    "0794": {
      H: 0.63,
      L: 0.62,
      O: 0.63,
      C: 0.62,
      V: 100000,
      p_w1_L: 0.58,
      p_w1_H: 0.64,
      p_m1_L: 0.58,
      p_m1_H: 0.68,
      p_m3_L: 0.58,
      p_m3_H: 0.69,
      p_m6_L: 0.55,
      p_m6_H: 0.78,
      p_m12_L: 0.53,
      p_m12_H: 0.78,
    },
    "0797": {
      H: 2.4,
      L: 2.33,
      O: 2.4,
      C: 2.33,
      V: 6000,
      p_w1_L: 2.33,
      p_w1_H: 2.45,
      p_m1_L: 2.29,
      p_m1_H: 2.45,
      p_m3_L: 2.27,
      p_m3_H: 2.65,
      p_m6_L: 2.27,
      p_m6_H: 2.65,
      p_m12_L: 2.27,
      p_m12_H: 2.9,
    },
    "0798": {
      H: 0.41,
      L: 0.41,
      O: 0.41,
      C: 0.41,
      V: 12000,
      p_w1_L: 0.39,
      p_w1_H: 0.44,
      p_m1_L: 0.39,
      p_m1_H: 0.44,
      p_m3_L: 0.39,
      p_m3_H: 0.46,
      p_m6_L: 0.39,
      p_m6_H: 0.61,
      p_m12_L: 0.375,
      p_m12_H: 0.61,
    },
    "0799": {
      H: 7.23,
      L: 7.05,
      O: 7.19,
      C: 7.09,
      V: 1942000,
      p_w1_L: 6.91,
      p_w1_H: 7.44,
      p_m1_L: 6.8,
      p_m1_H: 8.1,
      p_m3_L: 6.67,
      p_m3_H: 8.26,
      p_m6_L: 6.67,
      p_m6_H: 11.6,
      p_m12_L: 6.67,
      p_m12_H: 15.12,
    },
    "0800": {
      H: 0.29,
      L: 0.275,
      O: 0.275,
      C: 0.29,
      V: 552000,
      p_w1_L: 0.26,
      p_w1_H: 0.29,
      p_m1_L: 0.24,
      p_m1_H: 0.32,
      p_m3_L: 0.232,
      p_m3_H: 0.32,
      p_m6_L: 0.172,
      p_m6_H: 0.32,
      p_m12_L: 0.145,
      p_m12_H: 0.38,
    },
    "0802": {
      H: 0.1,
      L: 0.088,
      O: 0.1,
      C: 0.088,
      V: 2217500,
      p_w1_L: 0.086,
      p_w1_H: 0.11,
      p_m1_L: 0.086,
      p_m1_H: 0.11,
      p_m3_L: 0.086,
      p_m3_H: 0.122,
      p_m6_L: 0.086,
      p_m6_H: 0.135,
      p_m12_L: 0.086,
      p_m12_H: 0.161,
    },
    "0803": {
      H: 0.037,
      L: 0.037,
      O: 0.037,
      C: 0.037,
      V: 0,
      p_w1_L: 0.037,
      p_w1_H: 0.037,
      p_m1_L: 0.037,
      p_m1_H: 0.037,
      p_m3_L: 0.037,
      p_m3_H: 0.037,
      p_m6_L: 0.035,
      p_m6_H: 0.048,
      p_m12_L: 0.031,
      p_m12_H: 0.214,
    },
    "0804": {
      H: 0.045,
      L: 0.041,
      O: 0.043,
      C: 0.042,
      V: 1920000,
      p_w1_L: 0.041,
      p_w1_H: 0.048,
      p_m1_L: 0.036,
      p_m1_H: 0.05,
      p_m3_L: 0.036,
      p_m3_H: 0.083,
      p_m6_L: 0.034,
      p_m6_H: 0.083,
      p_m12_L: 0.022,
      p_m12_H: 0.084,
    },
    "0806": {
      H: 4.03,
      L: 3.92,
      O: 4,
      C: 3.94,
      V: 1042000,
      p_w1_L: 3.87,
      p_w1_H: 4.05,
      p_m1_L: 3.87,
      p_m1_H: 4.38,
      p_m3_L: 3.77,
      p_m3_H: 4.38,
      p_m6_L: 3.77,
      p_m6_H: 5.26,
      p_m12_L: 3.77,
      p_m12_H: 6.81,
    },
    "0807": {
      H: 1.37,
      L: 1.34,
      O: 1.36,
      C: 1.36,
      V: 638000,
      p_w1_L: 1.3,
      p_w1_H: 1.45,
      p_m1_L: 1.27,
      p_m1_H: 1.45,
      p_m3_L: 1.2,
      p_m3_H: 1.9,
      p_m6_L: 1.08,
      p_m6_H: 1.9,
      p_m12_L: 1.01,
      p_m12_H: 1.9,
    },
    "0808": {
      H: 3.08,
      L: 3.02,
      O: 3.04,
      C: 3.08,
      V: 619244,
      p_w1_L: 3.01,
      p_w1_H: 3.1,
      p_m1_L: 2.99,
      p_m1_H: 3.13,
      p_m3_L: 2.84,
      p_m3_H: 3.18,
      p_m6_L: 2.84,
      p_m6_H: 3.2,
      p_m12_L: 2.34,
      p_m12_H: 3.2,
    },
    "0809": {
      H: 0.153,
      L: 0.138,
      O: 0.146,
      C: 0.146,
      V: 862000,
      p_w1_L: 0.115,
      p_w1_H: 0.305,
      p_m1_L: 0.115,
      p_m1_H: 0.325,
      p_m3_L: 0.115,
      p_m3_H: 0.385,
      p_m6_L: 0.115,
      p_m6_H: 0.385,
      p_m12_L: 0.115,
      p_m12_H: 0.39,
    },
    "0810": {
      H: 1.31,
      L: 1.3,
      O: 1.31,
      C: 1.3,
      V: 67800,
      p_w1_L: 1.27,
      p_w1_H: 1.43,
      p_m1_L: 1.25,
      p_m1_H: 1.53,
      p_m3_L: 0.87,
      p_m3_H: 1.82,
      p_m6_L: 0.25,
      p_m6_H: 1.82,
      p_m12_L: 0.25,
      p_m12_H: 1.82,
    },
    "0811": {
      H: 5.94,
      L: 5.85,
      O: 5.87,
      C: 5.94,
      V: 1084000,
      p_w1_L: 5.66,
      p_w1_H: 5.94,
      p_m1_L: 5.38,
      p_m1_H: 5.94,
      p_m3_L: 5.21,
      p_m3_H: 5.94,
      p_m6_L: 4.9,
      p_m6_H: 5.94,
      p_m12_L: 4.68,
      p_m12_H: 5.94,
    },
    "0812": {
      H: 0.056,
      L: 0.054,
      O: 0.056,
      C: 0.056,
      V: 116000,
      p_w1_L: 0.054,
      p_w1_H: 0.058,
      p_m1_L: 0.054,
      p_m1_H: 0.076,
      p_m3_L: 0.054,
      p_m3_H: 0.076,
      p_m6_L: 0.054,
      p_m6_H: 0.091,
      p_m12_L: 0.054,
      p_m12_H: 0.152,
    },
    "0813": {
      H: 9.19,
      L: 8.31,
      O: 9.09,
      C: 8.33,
      V: 31303000,
      p_w1_L: 8.24,
      p_w1_H: 9.67,
      p_m1_L: 8.24,
      p_m1_H: 11.8,
      p_m3_L: 8.24,
      p_m3_H: 16.4,
      p_m6_L: 8.24,
      p_m6_H: 22.85,
      p_m12_L: 8.24,
      p_m12_H: 26.9,
    },
    "0814": {
      H: 1,
      L: 0.98,
      O: 0.98,
      C: 1,
      V: 25000,
      p_w1_L: 0.95,
      p_w1_H: 1,
      p_m1_L: 0.95,
      p_m1_H: 1.08,
      p_m3_L: 0.95,
      p_m3_H: 1.17,
      p_m6_L: 0.95,
      p_m6_H: 1.25,
      p_m12_L: 0.95,
      p_m12_H: 1.43,
    },
    "0815": {
      H: 0.6,
      L: 0.58,
      O: 0.59,
      C: 0.59,
      V: 492000,
      p_w1_L: 0.57,
      p_w1_H: 0.64,
      p_m1_L: 0.56,
      p_m1_H: 0.72,
      p_m3_L: 0.53,
      p_m3_H: 0.72,
      p_m6_L: 0.53,
      p_m6_H: 0.88,
      p_m12_L: 0.53,
      p_m12_H: 1.35,
    },
    "0817": {
      H: 2.62,
      L: 2.54,
      O: 2.61,
      C: 2.55,
      V: 15148300,
      p_w1_L: 2.33,
      p_w1_H: 2.63,
      p_m1_L: 2.16,
      p_m1_H: 2.63,
      p_m3_L: 2.16,
      p_m3_H: 3.05,
      p_m6_L: 2.12,
      p_m6_H: 3.13,
      p_m12_L: 2.12,
      p_m12_H: 4.38,
    },
    "0818": {
      H: 1.08,
      L: 1.07,
      O: 1.07,
      C: 1.07,
      V: 507000,
      p_w1_L: 1.02,
      p_w1_H: 1.08,
      p_m1_L: 1,
      p_m1_H: 1.13,
      p_m3_L: 0.94,
      p_m3_H: 1.47,
      p_m6_L: 0.94,
      p_m6_H: 1.63,
      p_m12_L: 0.9,
      p_m12_H: 3.25,
    },
    "0819": {
      H: 8.47,
      L: 8.28,
      O: 8.33,
      C: 8.43,
      V: 1240400,
      p_w1_L: 8.02,
      p_w1_H: 8.66,
      p_m1_L: 8.02,
      p_m1_H: 9.25,
      p_m3_L: 7.82,
      p_m3_H: 10.54,
      p_m6_L: 7.82,
      p_m6_H: 16.42,
      p_m12_L: 7.82,
      p_m12_H: 22.55,
    },
    "0820": {
      H: 17.88,
      L: 17.82,
      O: 17.88,
      C: 17.9,
      V: 4000,
      p_w1_L: 17.82,
      p_w1_H: 19,
      p_m1_L: 17.7,
      p_m1_H: 19,
      p_m3_L: 16.4,
      p_m3_H: 19,
      p_m6_L: 14.68,
      p_m6_H: 19,
      p_m12_L: 14.68,
      p_m12_H: 20.25,
    },
    "0821": {
      H: 0.385,
      L: 0.375,
      O: 0.375,
      C: 0.385,
      V: 44000,
      p_w1_L: 0.365,
      p_w1_H: 0.4,
      p_m1_L: 0.36,
      p_m1_H: 0.4,
      p_m3_L: 0.34,
      p_m3_H: 0.455,
      p_m6_L: 0.235,
      p_m6_H: 0.64,
      p_m12_L: 0.235,
      p_m12_H: 0.64,
    },
    "0822": {
      H: 1.1,
      L: 0.99,
      O: 1.05,
      C: 1.07,
      V: 13822000,
      p_w1_L: 0.99,
      p_w1_H: 1.32,
      p_m1_L: 0.83,
      p_m1_H: 1.32,
      p_m3_L: 0.54,
      p_m3_H: 1.32,
      p_m6_L: 0.54,
      p_m6_H: 1.32,
      p_m12_L: 0.495,
      p_m12_H: 1.32,
    },
    "0823": {
      H: 67.45,
      L: 66.95,
      O: 67.45,
      C: 67.15,
      V: 4827800,
      p_w1_L: 66.1,
      p_w1_H: 67.85,
      p_m1_L: 66.1,
      p_m1_H: 73,
      p_m3_L: 63.1,
      p_m3_H: 73,
      p_m6_L: 63.1,
      p_m6_H: 78.7,
      p_m12_L: 63.1,
      p_m12_H: 78.7,
    },
    "0825": {
      H: 1.3,
      L: 1.26,
      O: 1.27,
      C: 1.26,
      V: 67000,
      p_w1_L: 1.23,
      p_w1_H: 1.3,
      p_m1_L: 1.23,
      p_m1_H: 1.35,
      p_m3_L: 1.23,
      p_m3_H: 1.48,
      p_m6_L: 1.23,
      p_m6_H: 1.83,
      p_m12_L: 1.02,
      p_m12_H: 1.83,
    },
    "0826": {
      H: 4.74,
      L: 4.38,
      O: 4.45,
      C: 4.58,
      V: 5030000,
      p_w1_L: 4.35,
      p_w1_H: 4.84,
      p_m1_L: 4.15,
      p_m1_H: 4.84,
      p_m3_L: 4.15,
      p_m3_H: 5.66,
      p_m6_L: 3.05,
      p_m6_H: 5.66,
      p_m12_L: 2.96,
      p_m12_H: 5.66,
    },
    "0827": {
      H: 0.186,
      L: 0.18,
      O: 0.185,
      C: 0.182,
      V: 2412000,
      p_w1_L: 0.175,
      p_w1_H: 0.193,
      p_m1_L: 0.13,
      p_m1_H: 0.199,
      p_m3_L: 0.118,
      p_m3_H: 0.199,
      p_m6_L: 0.081,
      p_m6_H: 0.199,
      p_m12_L: 0.063,
      p_m12_H: 0.199,
    },
    "0828": {
      H: 0.375,
      L: 0.335,
      O: 0.345,
      C: 0.37,
      V: 36000,
      p_w1_L: 0.33,
      p_w1_H: 0.4,
      p_m1_L: 0.33,
      p_m1_H: 0.41,
      p_m3_L: 0.33,
      p_m3_H: 0.45,
      p_m6_L: 0.33,
      p_m6_H: 0.59,
      p_m12_L: 0.25,
      p_m12_H: 0.84,
    },
    "0829": {
      H: 0.42,
      L: 0.41,
      O: 0.41,
      C: 0.42,
      V: 188000,
      p_w1_L: 0.4,
      p_w1_H: 0.435,
      p_m1_L: 0.385,
      p_m1_H: 0.48,
      p_m3_L: 0.385,
      p_m3_H: 0.48,
      p_m6_L: 0.375,
      p_m6_H: 0.48,
      p_m12_L: 0.245,
      p_m12_H: 0.58,
    },
    "0830": {
      H: 1.67,
      L: 1.62,
      O: 1.67,
      C: 1.66,
      V: 490000,
      p_w1_L: 1.57,
      p_w1_H: 1.67,
      p_m1_L: 1.54,
      p_m1_H: 1.7,
      p_m3_L: 1.54,
      p_m3_H: 1.8,
      p_m6_L: 1.08,
      p_m6_H: 1.96,
      p_m12_L: 0.465,
      p_m12_H: 1.96,
    },
    "0831": {
      H: 0.77,
      L: 0.75,
      O: 0.76,
      C: 0.75,
      V: 40000,
      p_w1_L: 0.75,
      p_w1_H: 0.87,
      p_m1_L: 0.75,
      p_m1_H: 0.87,
      p_m3_L: 0.73,
      p_m3_H: 0.88,
      p_m6_L: 0.69,
      p_m6_H: 0.88,
      p_m12_L: 0.52,
      p_m12_H: 4.76,
    },
    "0832": {
      H: 1.01,
      L: 0.97,
      O: 1.01,
      C: 0.99,
      V: 7731000,
      p_w1_L: 0.97,
      p_w1_H: 1.11,
      p_m1_L: 0.97,
      p_m1_H: 1.19,
      p_m3_L: 0.97,
      p_m3_H: 1.7,
      p_m6_L: 0.97,
      p_m6_H: 2.12,
      p_m12_L: 0.97,
      p_m12_H: 4.56,
    },
    "0833": {
      H: 0.315,
      L: 0.315,
      O: 0.315,
      C: 0.315,
      V: 0,
      p_w1_L: 0.3,
      p_w1_H: 0.32,
      p_m1_L: 0.3,
      p_m1_H: 0.335,
      p_m3_L: 0.295,
      p_m3_H: 0.375,
      p_m6_L: 0.295,
      p_m6_H: 0.415,
      p_m12_L: 0.295,
      p_m12_H: 0.78,
    },
    "0834": {
      H: 0.35,
      L: 0.35,
      O: 0.35,
      C: 0.35,
      V: 8000,
      p_w1_L: 0.325,
      p_w1_H: 0.55,
      p_m1_L: 0.325,
      p_m1_H: 0.55,
      p_m3_L: 0.31,
      p_m3_H: 0.55,
      p_m6_L: 0.31,
      p_m6_H: 0.55,
      p_m12_L: 0.31,
      p_m12_H: 0.55,
    },
    "0836": {
      H: 23.75,
      L: 22.2,
      O: 22.25,
      C: 23.55,
      V: 24888400,
      p_w1_L: 19.72,
      p_w1_H: 24.2,
      p_m1_L: 17.18,
      p_m1_H: 24.2,
      p_m3_L: 17.18,
      p_m3_H: 24.8,
      p_m6_L: 9.18,
      p_m6_H: 24.8,
      p_m12_L: 7.5,
      p_m12_H: 24.8,
    },
    "0837": {
      H: 3.6,
      L: 3.53,
      O: 3.55,
      C: 3.58,
      V: 17264,
      p_w1_L: 3.49,
      p_w1_H: 3.6,
      p_m1_L: 3.49,
      p_m1_H: 3.75,
      p_m3_L: 3.48,
      p_m3_H: 3.79,
      p_m6_L: 3.41,
      p_m6_H: 3.85,
      p_m12_L: 3.41,
      p_m12_H: 4.53,
    },
    "0838": {
      H: 1.95,
      L: 1.85,
      O: 1.85,
      C: 1.9,
      V: 18376000,
      p_w1_L: 1.77,
      p_w1_H: 2.11,
      p_m1_L: 1.75,
      p_m1_H: 2.58,
      p_m3_L: 0.7,
      p_m3_H: 2.58,
      p_m6_L: 0.67,
      p_m6_H: 2.58,
      p_m12_L: 0.54,
      p_m12_H: 2.58,
    },
    "0839": {
      H: 13.4,
      L: 12.72,
      O: 13.08,
      C: 12.98,
      V: 10250500,
      p_w1_L: 12.72,
      p_w1_H: 14.66,
      p_m1_L: 12.24,
      p_m1_H: 15.2,
      p_m3_L: 12.24,
      p_m3_H: 18.3,
      p_m6_L: 11.3,
      p_m6_H: 19.88,
      p_m12_L: 11.3,
      p_m12_H: 20.8,
    },
    "0840": {
      H: 0.225,
      L: 0.225,
      O: 0.225,
      C: 0.225,
      V: 0,
      p_w1_L: 0.203,
      p_w1_H: 0.24,
      p_m1_L: 0.203,
      p_m1_H: 0.275,
      p_m3_L: 0.203,
      p_m3_H: 0.285,
      p_m6_L: 0.203,
      p_m6_H: 0.31,
      p_m12_L: 0.203,
      p_m12_H: 0.4,
    },
    "0841": {
      H: 0.315,
      L: 0.28,
      O: 0.29,
      C: 0.28,
      V: 12000,
      p_w1_L: 0.275,
      p_w1_H: 0.315,
      p_m1_L: 0.275,
      p_m1_H: 0.32,
      p_m3_L: 0.25,
      p_m3_H: 0.345,
      p_m6_L: 0.236,
      p_m6_H: 0.37,
      p_m12_L: 0.235,
      p_m12_H: 0.375,
    },
    "0842": {
      H: 0.94,
      L: 0.91,
      O: 0.91,
      C: 0.93,
      V: 1717000,
      p_w1_L: 0.86,
      p_w1_H: 0.94,
      p_m1_L: 0.82,
      p_m1_H: 1.04,
      p_m3_L: 0.74,
      p_m3_H: 1.05,
      p_m6_L: 0.68,
      p_m6_H: 1.31,
      p_m12_L: 0.41,
      p_m12_H: 1.66,
    },
    "0844": {
      H: 0.42,
      L: 0.42,
      O: 0.42,
      C: 0.42,
      V: 256000,
      p_w1_L: 0.405,
      p_w1_H: 0.43,
      p_m1_L: 0.365,
      p_m1_H: 0.43,
      p_m3_L: 0.345,
      p_m3_H: 0.43,
      p_m6_L: 0.345,
      p_m6_H: 0.43,
      p_m12_L: 0.32,
      p_m12_H: 0.48,
    },
    "0845": {
      H: 0.242,
      L: 0.21,
      O: 0.221,
      C: 0.242,
      V: 1851000,
      p_w1_L: 0.21,
      p_w1_H: 0.242,
      p_m1_L: 0.21,
      p_m1_H: 0.249,
      p_m3_L: 0.183,
      p_m3_H: 0.25,
      p_m6_L: 0.183,
      p_m6_H: 0.255,
      p_m12_L: 0.183,
      p_m12_H: 0.3,
    },
    "0846": {
      H: 0.46,
      L: 0.46,
      O: 0.46,
      C: 0.46,
      V: 0,
      p_w1_L: 0.42,
      p_w1_H: 0.475,
      p_m1_L: 0.42,
      p_m1_H: 0.5,
      p_m3_L: 0.42,
      p_m3_H: 0.55,
      p_m6_L: 0.42,
      p_m6_H: 0.65,
      p_m12_L: 0.42,
      p_m12_H: 1.89,
    },
    "0848": {
      H: 0.265,
      L: 0.25,
      O: 0.25,
      C: 0.265,
      V: 225000,
      p_w1_L: 0.241,
      p_w1_H: 0.275,
      p_m1_L: 0.241,
      p_m1_H: 0.28,
      p_m3_L: 0.241,
      p_m3_H: 0.3,
      p_m6_L: 0.241,
      p_m6_H: 0.34,
      p_m12_L: 0.241,
      p_m12_H: 0.43,
    },
    "0851": {
      H: 0.042,
      L: 0.04,
      O: 0.04,
      C: 0.042,
      V: 80000,
      p_w1_L: 0.035,
      p_w1_H: 0.043,
      p_m1_L: 0.034,
      p_m1_H: 0.052,
      p_m3_L: 0.034,
      p_m3_H: 0.058,
      p_m6_L: 0.034,
      p_m6_H: 0.06,
      p_m12_L: 0.022,
      p_m12_H: 0.07,
    },
    "0852": {
      H: 0.225,
      L: 0.225,
      O: 0.225,
      C: 0.225,
      V: 8000,
      p_w1_L: 0.202,
      p_w1_H: 0.26,
      p_m1_L: 0.202,
      p_m1_H: 0.285,
      p_m3_L: 0.202,
      p_m3_H: 0.44,
      p_m6_L: 0.202,
      p_m6_H: 0.44,
      p_m12_L: 0.202,
      p_m12_H: 0.44,
    },
    "0853": {
      H: 32.45,
      L: 30.7,
      O: 31.45,
      C: 31.3,
      V: 4151180,
      p_w1_L: 29.15,
      p_w1_H: 33.5,
      p_m1_L: 29.15,
      p_m1_H: 40.35,
      p_m3_L: 29.15,
      p_m3_H: 52.75,
      p_m6_L: 29.15,
      p_m6_H: 72.85,
      p_m12_L: 29.15,
      p_m12_H: 72.85,
    },
    "0854": {
      H: 4.3,
      L: 4.3,
      O: 4.3,
      C: 4.3,
      V: 0,
      p_w1_L: 4.3,
      p_w1_H: 4.3,
      p_m1_L: 4.03,
      p_m1_H: 4.32,
      p_m3_L: 3.29,
      p_m3_H: 4.32,
      p_m6_L: 3.29,
      p_m6_H: 4.4,
      p_m12_L: 2,
      p_m12_H: 4.4,
    },
    "0855": {
      H: 9.11,
      L: 8.82,
      O: 8.99,
      C: 9.01,
      V: 1974700,
      p_w1_L: 8.6,
      p_w1_H: 9.75,
      p_m1_L: 7.11,
      p_m1_H: 9.75,
      p_m3_L: 7.11,
      p_m3_H: 9.79,
      p_m6_L: 5.71,
      p_m6_H: 9.79,
      p_m12_L: 5.71,
      p_m12_H: 9.79,
    },
    "0856": {
      H: 7.25,
      L: 7.03,
      O: 7.06,
      C: 7.08,
      V: 1068000,
      p_w1_L: 6.78,
      p_w1_H: 7.25,
      p_m1_L: 6.78,
      p_m1_H: 7.98,
      p_m3_L: 6.69,
      p_m3_H: 7.98,
      p_m6_L: 5.59,
      p_m6_H: 7.98,
      p_m12_L: 5.59,
      p_m12_H: 8.97,
    },
    "0857": {
      H: 3.58,
      L: 3.51,
      O: 3.55,
      C: 3.55,
      V: 93551400,
      p_w1_L: 3.36,
      p_w1_H: 3.58,
      p_m1_L: 3.34,
      p_m1_H: 3.7,
      p_m3_L: 3.32,
      p_m3_H: 4.2,
      p_m6_L: 2.94,
      p_m6_H: 4.2,
      p_m12_L: 2.32,
      p_m12_H: 4.2,
    },
    "0858": {
      H: 0.1,
      L: 0.1,
      O: 0.1,
      C: 0.1,
      V: 0,
      p_w1_L: 0.095,
      p_w1_H: 0.103,
      p_m1_L: 0.092,
      p_m1_H: 0.105,
      p_m3_L: 0.089,
      p_m3_H: 0.12,
      p_m6_L: 0.089,
      p_m6_H: 0.159,
      p_m12_L: 0.089,
      p_m12_H: 0.195,
    },
    "0859": {
      H: 0.41,
      L: 0.4,
      O: 0.41,
      C: 0.4,
      V: 240000,
      p_w1_L: 0.4,
      p_w1_H: 0.41,
      p_m1_L: 0.315,
      p_m1_H: 0.41,
      p_m3_L: 0.25,
      p_m3_H: 0.435,
      p_m6_L: 0.241,
      p_m6_H: 0.435,
      p_m12_L: 0.241,
      p_m12_H: 0.45,
    },
    "0860": {
      H: 0.52,
      L: 0.495,
      O: 0.495,
      C: 0.52,
      V: 21900000,
      p_w1_L: 0.47,
      p_w1_H: 0.54,
      p_m1_L: 0.47,
      p_m1_H: 0.56,
      p_m3_L: 0.47,
      p_m3_H: 0.63,
      p_m6_L: 0.315,
      p_m6_H: 0.63,
      p_m12_L: 0.315,
      p_m12_H: 0.91,
    },
    "0861": {
      H: 4.55,
      L: 4.1,
      O: 4.16,
      C: 4.53,
      V: 4790000,
      p_w1_L: 3.95,
      p_w1_H: 4.55,
      p_m1_L: 3.95,
      p_m1_H: 4.55,
      p_m3_L: 3.95,
      p_m3_H: 4.85,
      p_m6_L: 3.95,
      p_m6_H: 5.39,
      p_m12_L: 3.95,
      p_m12_H: 7.06,
    },
    "0862": {
      H: 0.215,
      L: 0.215,
      O: 0.215,
      C: 0.215,
      V: 0,
      p_w1_L: 0.215,
      p_w1_H: 0.219,
      p_m1_L: 0.204,
      p_m1_H: 0.233,
      p_m3_L: 0.169,
      p_m3_H: 0.29,
      p_m6_L: 0.165,
      p_m6_H: 0.29,
      p_m12_L: 0.165,
      p_m12_H: 0.325,
    },
    "0863": {
      H: 10.8,
      L: 10.5,
      O: 10.8,
      C: 10.5,
      V: 293926,
      p_w1_L: 10.4,
      p_w1_H: 11.68,
      p_m1_L: 10.4,
      p_m1_H: 14.5,
      p_m3_L: 10.4,
      p_m3_H: 15.66,
      p_m6_L: 10.4,
      p_m6_H: 21.8,
      p_m12_L: 10.4,
      p_m12_H: 22.65,
    },
    "0864": {
      H: 0.47,
      L: 0.47,
      O: 0.47,
      C: 0.47,
      V: 8000,
      p_w1_L: 0.41,
      p_w1_H: 0.485,
      p_m1_L: 0.41,
      p_m1_H: 0.5,
      p_m3_L: 0.41,
      p_m3_H: 0.65,
      p_m6_L: 0.41,
      p_m6_H: 0.65,
      p_m12_L: 0.38,
      p_m12_H: 0.65,
    },
    "0865": {
      H: 0.07,
      L: 0.064,
      O: 0.064,
      C: 0.064,
      V: 60888000,
      p_w1_L: 0.06,
      p_w1_H: 0.07,
      p_m1_L: 0.06,
      p_m1_H: 0.07,
      p_m3_L: 0.06,
      p_m3_H: 0.072,
      p_m6_L: 0.06,
      p_m6_H: 0.089,
      p_m12_L: 0.06,
      p_m12_H: 0.118,
    },
    "0866": {
      H: 0.186,
      L: 0.17,
      O: 0.171,
      C: 0.18,
      V: 4714000,
      p_w1_L: 0.168,
      p_w1_H: 0.187,
      p_m1_L: 0.165,
      p_m1_H: 0.22,
      p_m3_L: 0.165,
      p_m3_H: 0.33,
      p_m6_L: 0.122,
      p_m6_H: 0.33,
      p_m12_L: 0.12,
      p_m12_H: 0.33,
    },
    "0867": {
      H: 12.64,
      L: 11.86,
      O: 12.28,
      C: 11.9,
      V: 6902700,
      p_w1_L: 11.86,
      p_w1_H: 13.16,
      p_m1_L: 11.86,
      p_m1_H: 13.66,
      p_m3_L: 11.86,
      p_m3_H: 15.88,
      p_m6_L: 11.86,
      p_m6_H: 22.8,
      p_m12_L: 7.37,
      p_m12_H: 22.8,
    },
    "0868": {
      H: 20.6,
      L: 20.05,
      O: 20.6,
      C: 20.2,
      V: 7511660,
      p_w1_L: 18.6,
      p_w1_H: 20.75,
      p_m1_L: 18.54,
      p_m1_H: 22.2,
      p_m3_L: 18.54,
      p_m3_H: 31.3,
      p_m6_L: 18.54,
      p_m6_H: 34.2,
      p_m12_L: 17.46,
      p_m12_H: 34.2,
    },
    "0869": {
      H: 0.62,
      L: 0.5,
      O: 0.5,
      C: 0.52,
      V: 2232000,
      p_w1_L: 0.48,
      p_w1_H: 0.62,
      p_m1_L: 0.46,
      p_m1_H: 0.62,
      p_m3_L: 0.46,
      p_m3_H: 0.62,
      p_m6_L: 0.46,
      p_m6_H: 0.76,
      p_m12_L: 0.29,
      p_m12_H: 0.76,
    },
    "0871": {
      H: 0.141,
      L: 0.141,
      O: 0.141,
      C: 0.141,
      V: 200000,
      p_w1_L: 0.128,
      p_w1_H: 0.142,
      p_m1_L: 0.12,
      p_m1_H: 0.145,
      p_m3_L: 0.106,
      p_m3_H: 0.18,
      p_m6_L: 0.102,
      p_m6_H: 0.18,
      p_m12_L: 0.102,
      p_m12_H: 0.208,
    },
    "0872": {
      H: 0.096,
      L: 0.096,
      O: 0.096,
      C: 0.096,
      V: 0,
      p_w1_L: 0.088,
      p_w1_H: 0.109,
      p_m1_L: 0.088,
      p_m1_H: 0.119,
      p_m3_L: 0.088,
      p_m3_H: 0.119,
      p_m6_L: 0.084,
      p_m6_H: 0.128,
      p_m12_L: 0.084,
      p_m12_H: 0.19,
    },
    "0873": {
      H: 9.18,
      L: 8.52,
      O: 9.17,
      C: 8.54,
      V: 11961700,
      p_w1_L: 8.52,
      p_w1_H: 9.91,
      p_m1_L: 8.52,
      p_m1_H: 12.66,
      p_m3_L: 8.52,
      p_m3_H: 18.6,
      p_m6_L: 8.52,
      p_m6_H: 27.55,
      p_m12_L: 8.52,
      p_m12_H: 27.55,
    },
    "0874": {
      H: 19,
      L: 18.68,
      O: 18.98,
      C: 18.76,
      V: 2160500,
      p_w1_L: 18.68,
      p_w1_H: 19.16,
      p_m1_L: 18.68,
      p_m1_H: 19.44,
      p_m3_L: 18.68,
      p_m3_H: 21.9,
      p_m6_L: 18.68,
      p_m6_H: 26.2,
      p_m12_L: 18.22,
      p_m12_H: 26.2,
    },
    "0875": {
      H: 2.5,
      L: 2.49,
      O: 2.5,
      C: 2.49,
      V: 25000,
      p_w1_L: 2.4,
      p_w1_H: 2.56,
      p_m1_L: 2.4,
      p_m1_H: 3.3,
      p_m3_L: 1.79,
      p_m3_H: 3.3,
      p_m6_L: 1.43,
      p_m6_H: 3.3,
      p_m12_L: 0.85,
      p_m12_H: 3.3,
    },
    "0876": {
      H: 0.1,
      L: 0.09,
      O: 0.095,
      C: 0.092,
      V: 7220000,
      p_w1_L: 0.09,
      p_w1_H: 0.122,
      p_m1_L: 0.09,
      p_m1_H: 0.137,
      p_m3_L: 0.089,
      p_m3_H: 0.255,
      p_m6_L: 0.089,
      p_m6_H: 0.51,
      p_m12_L: 0.089,
      p_m12_H: 0.51,
    },
    "0878": {
      H: 7.7,
      L: 7.7,
      O: 7.7,
      C: 7.7,
      V: 0,
      p_w1_L: 7.55,
      p_w1_H: 7.7,
      p_m1_L: 7.55,
      p_m1_H: 7.77,
      p_m3_L: 7.35,
      p_m3_H: 8.17,
      p_m6_L: 7.35,
      p_m6_H: 8.97,
      p_m12_L: 6.93,
      p_m12_H: 9.5,
    },
    "0880": {
      H: 5.64,
      L: 5.47,
      O: 5.51,
      C: 5.57,
      V: 9953190,
      p_w1_L: 5.2,
      p_w1_H: 5.64,
      p_m1_L: 5.2,
      p_m1_H: 6.6,
      p_m3_L: 5.05,
      p_m3_H: 7.56,
      p_m6_L: 5.05,
      p_m6_H: 8.97,
      p_m12_L: 5.05,
      p_m12_H: 11.9,
    },
    "0881": {
      H: 63.95,
      L: 61.7,
      O: 62.65,
      C: 63.5,
      V: 1452330,
      p_w1_L: 61.7,
      p_w1_H: 67.2,
      p_m1_L: 61.7,
      p_m1_H: 69.45,
      p_m3_L: 58.75,
      p_m3_H: 72.7,
      p_m6_L: 58.75,
      p_m6_H: 77.6,
      p_m12_L: 44,
      p_m12_H: 77.6,
    },
    "0882": {
      H: 1.69,
      L: 1.67,
      O: 1.67,
      C: 1.67,
      V: 118000,
      p_w1_L: 1.57,
      p_w1_H: 1.7,
      p_m1_L: 1.56,
      p_m1_H: 1.72,
      p_m3_L: 1.56,
      p_m3_H: 2.01,
      p_m6_L: 1.54,
      p_m6_H: 2.01,
      p_m12_L: 1.42,
      p_m12_H: 2.01,
    },
    "0883": {
      H: 7.83,
      L: 7.69,
      O: 7.82,
      C: 7.75,
      V: 52049200,
      p_w1_L: 7.62,
      p_w1_H: 7.87,
      p_m1_L: 7.55,
      p_m1_H: 8.29,
      p_m3_L: 7.55,
      p_m3_H: 9.2,
      p_m6_L: 7.55,
      p_m6_H: 9.3,
      p_m12_L: 6.5,
      p_m12_H: 10.6,
    },
    "0884": {
      H: 4.82,
      L: 4.55,
      O: 4.8,
      C: 4.62,
      V: 45154700,
      p_w1_L: 4.14,
      p_w1_H: 4.82,
      p_m1_L: 3.92,
      p_m1_H: 5.06,
      p_m3_L: 3.92,
      p_m3_H: 5.38,
      p_m6_L: 3.92,
      p_m6_H: 7,
      p_m12_L: 3.92,
      p_m12_H: 7.85,
    },
    "0885": {
      H: 0.01,
      L: 0.01,
      O: 0.01,
      C: 0.01,
      V: 0,
      p_w1_L: 0.01,
      p_w1_H: 0.01,
      p_m1_L: 0.01,
      p_m1_H: 0.01,
      p_m3_L: 0.01,
      p_m3_H: 0.01,
      p_m6_L: 0.01,
      p_m6_H: 0.01,
      p_m12_L: 0.01,
      p_m12_H: 0.01,
    },
    "0886": {
      H: 0.057,
      L: 0.051,
      O: 0.055,
      C: 0.054,
      V: 891750,
      p_w1_L: 0.047,
      p_w1_H: 0.071,
      p_m1_L: 0.047,
      p_m1_H: 0.13,
      p_m3_L: 0.047,
      p_m3_H: 0.141,
      p_m6_L: 0.047,
      p_m6_H: 0.23,
      p_m12_L: 0.047,
      p_m12_H: 0.255,
    },
    "0887": {
      H: 0.206,
      L: 0.201,
      O: 0.202,
      C: 0.202,
      V: 900000,
      p_w1_L: 0.198,
      p_w1_H: 0.21,
      p_m1_L: 0.196,
      p_m1_H: 0.23,
      p_m3_L: 0.169,
      p_m3_H: 0.23,
      p_m6_L: 0.169,
      p_m6_H: 0.249,
      p_m12_L: 0.098,
      p_m12_H: 0.249,
    },
    "0888": {
      H: 0.198,
      L: 0.198,
      O: 0.198,
      C: 0.198,
      V: 0,
      p_w1_L: 0.19,
      p_w1_H: 0.208,
      p_m1_L: 0.19,
      p_m1_H: 0.215,
      p_m3_L: 0.18,
      p_m3_H: 0.31,
      p_m6_L: 0.18,
      p_m6_H: 0.425,
      p_m12_L: 0.18,
      p_m12_H: 0.425,
    },
    "0889": {
      H: 0.53,
      L: 0.49,
      O: 0.49,
      C: 0.53,
      V: 6000,
      p_w1_L: 0.49,
      p_w1_H: 0.55,
      p_m1_L: 0.49,
      p_m1_H: 0.57,
      p_m3_L: 0.49,
      p_m3_H: 0.63,
      p_m6_L: 0.49,
      p_m6_H: 0.8,
      p_m12_L: 0.49,
      p_m12_H: 1.3,
    },
    "0891": {
      H: 0.042,
      L: 0.042,
      O: 0.042,
      C: 0.042,
      V: 0,
      p_w1_L: 0.042,
      p_w1_H: 0.042,
      p_m1_L: 0.042,
      p_m1_H: 0.042,
      p_m3_L: 0.042,
      p_m3_H: 0.042,
      p_m6_L: 0.042,
      p_m6_H: 0.042,
      p_m12_L: 0.038,
      p_m12_H: 0.101,
    },
    "0893": {
      H: 0.165,
      L: 0.161,
      O: 0.161,
      C: 0.163,
      V: 630000,
      p_w1_L: 0.16,
      p_w1_H: 0.172,
      p_m1_L: 0.156,
      p_m1_H: 0.187,
      p_m3_L: 0.156,
      p_m3_H: 0.255,
      p_m6_L: 0.156,
      p_m6_H: 0.255,
      p_m12_L: 0.128,
      p_m12_H: 0.33,
    },
    "0894": {
      H: 1.09,
      L: 1.06,
      O: 1.08,
      C: 1.08,
      V: 376000,
      p_w1_L: 1.03,
      p_w1_H: 1.35,
      p_m1_L: 1.01,
      p_m1_H: 1.39,
      p_m3_L: 0.98,
      p_m3_H: 1.75,
      p_m6_L: 0.98,
      p_m6_H: 2.37,
      p_m12_L: 0.365,
      p_m12_H: 2.37,
    },
    "0895": {
      H: 3.6,
      L: 3.55,
      O: 3.56,
      C: 3.59,
      V: 77400,
      p_w1_L: 3.51,
      p_w1_H: 3.63,
      p_m1_L: 3.37,
      p_m1_H: 3.65,
      p_m3_L: 3.37,
      p_m3_H: 4.05,
      p_m6_L: 3.37,
      p_m6_H: 4.55,
      p_m12_L: 3.37,
      p_m12_H: 6.15,
    },
    "0896": {
      H: 1.29,
      L: 1.29,
      O: 1.29,
      C: 1.29,
      V: 76000,
      p_w1_L: 1.29,
      p_w1_H: 1.3,
      p_m1_L: 1.29,
      p_m1_H: 1.33,
      p_m3_L: 1.26,
      p_m3_H: 1.36,
      p_m6_L: 1.22,
      p_m6_H: 1.47,
      p_m12_L: 0.94,
      p_m12_H: 1.47,
    },
    "0897": {
      H: 0.33,
      L: 0.33,
      O: 0.33,
      C: 0.33,
      V: 115000,
      p_w1_L: 0.315,
      p_w1_H: 0.35,
      p_m1_L: 0.3,
      p_m1_H: 0.51,
      p_m3_L: 0.29,
      p_m3_H: 0.51,
      p_m6_L: 0.285,
      p_m6_H: 0.51,
      p_m12_L: 0.28,
      p_m12_H: 0.51,
    },
    "0898": {
      H: 1.1,
      L: 1.1,
      O: 1.1,
      C: 1.1,
      V: 0,
      p_w1_L: 1.06,
      p_w1_H: 1.11,
      p_m1_L: 1.06,
      p_m1_H: 1.3,
      p_m3_L: 1.05,
      p_m3_H: 1.3,
      p_m6_L: 1.05,
      p_m6_H: 1.3,
      p_m12_L: 1.05,
      p_m12_H: 1.7,
    },
    "0899": {
      H: 0.077,
      L: 0.077,
      O: 0.077,
      C: 0.075,
      V: 130000,
      p_w1_L: 0.069,
      p_w1_H: 0.08,
      p_m1_L: 0.069,
      p_m1_H: 0.102,
      p_m3_L: 0.026,
      p_m3_H: 0.105,
      p_m6_L: 0.026,
      p_m6_H: 0.105,
      p_m12_L: 0.026,
      p_m12_H: 0.105,
    },
    "0900": {
      H: 4.97,
      L: 4.95,
      O: 4.95,
      C: 4.95,
      V: 56000,
      p_w1_L: 4.88,
      p_w1_H: 4.98,
      p_m1_L: 4.88,
      p_m1_H: 5.08,
      p_m3_L: 4.71,
      p_m3_H: 5.19,
      p_m6_L: 4.71,
      p_m6_H: 5.32,
      p_m12_L: 4.71,
      p_m12_H: 5.6,
    },
    "0901": {
      H: 0.108,
      L: 0.091,
      O: 0.091,
      C: 0.108,
      V: 220000,
      p_w1_L: 0.091,
      p_w1_H: 0.123,
      p_m1_L: 0.068,
      p_m1_H: 0.149,
      p_m3_L: 0.068,
      p_m3_H: 0.18,
      p_m6_L: 0.068,
      p_m6_H: 0.25,
      p_m12_L: 0.068,
      p_m12_H: 0.35,
    },
    "0902": {
      H: 4.14,
      L: 3.79,
      O: 3.81,
      C: 4.11,
      V: 121602000,
      p_w1_L: 3.42,
      p_w1_H: 4.14,
      p_m1_L: 3.08,
      p_m1_H: 4.14,
      p_m3_L: 3.08,
      p_m3_H: 4.9,
      p_m6_L: 2.53,
      p_m6_H: 4.91,
      p_m12_L: 2.44,
      p_m12_H: 4.91,
    },
    "0904": {
      H: 0.115,
      L: 0.115,
      O: 0.115,
      C: 0.115,
      V: 35,
      p_w1_L: 0.11,
      p_w1_H: 0.123,
      p_m1_L: 0.11,
      p_m1_H: 0.141,
      p_m3_L: 0.11,
      p_m3_H: 0.199,
      p_m6_L: 0.11,
      p_m6_H: 0.295,
      p_m12_L: 0.099,
      p_m12_H: 0.38,
    },
    "0905": {
      H: 0.153,
      L: 0.153,
      O: 0.153,
      C: 0.153,
      V: 1024000,
      p_w1_L: 0.15,
      p_w1_H: 0.153,
      p_m1_L: 0.13,
      p_m1_H: 0.156,
      p_m3_L: 0.123,
      p_m3_H: 0.32,
      p_m6_L: 0.1,
      p_m6_H: 0.34,
      p_m12_L: 0.07,
      p_m12_H: 0.34,
    },
    "0906": {
      H: 4.05,
      L: 3.95,
      O: 4.05,
      C: 4.02,
      V: 272000,
      p_w1_L: 3.93,
      p_w1_H: 4.22,
      p_m1_L: 3.74,
      p_m1_H: 4.23,
      p_m3_L: 3.7,
      p_m3_H: 4.57,
      p_m6_L: 3.7,
      p_m6_H: 5.27,
      p_m12_L: 3.34,
      p_m12_H: 5.27,
    },
    "0907": {
      H: 0.95,
      L: 0.9,
      O: 0.9,
      C: 0.91,
      V: 5120000,
      p_w1_L: 0.8,
      p_w1_H: 1.02,
      p_m1_L: 0.8,
      p_m1_H: 1.28,
      p_m3_L: 0.8,
      p_m3_H: 1.44,
      p_m6_L: 0.8,
      p_m6_H: 1.6,
      p_m12_L: 0.255,
      p_m12_H: 1.81,
    },
    "0909": {
      H: 23.05,
      L: 22,
      O: 22.45,
      C: 22.25,
      V: 4606510,
      p_w1_L: 19.12,
      p_w1_H: 23.05,
      p_m1_L: 19.12,
      p_m1_H: 29.5,
      p_m3_L: 19.12,
      p_m3_H: 32.3,
      p_m6_L: 19.12,
      p_m6_H: 40.5,
      p_m12_L: 19.12,
      p_m12_H: 60.8,
    },
    "0910": {
      H: 0.49,
      L: 0.49,
      O: 0.49,
      C: 0.49,
      V: 174400,
      p_w1_L: 0.48,
      p_w1_H: 0.5,
      p_m1_L: 0.45,
      p_m1_H: 0.5,
      p_m3_L: 0.45,
      p_m3_H: 0.58,
      p_m6_L: 0.45,
      p_m6_H: 0.6,
      p_m12_L: 0.45,
      p_m12_H: 0.69,
    },
    "0911": {
      H: 0.088,
      L: 0.072,
      O: 0.088,
      C: 0.072,
      V: 330000,
      p_w1_L: 0.072,
      p_w1_H: 0.088,
      p_m1_L: 0.072,
      p_m1_H: 0.091,
      p_m3_L: 0.072,
      p_m3_H: 0.102,
      p_m6_L: 0.072,
      p_m6_H: 0.128,
      p_m12_L: 0.072,
      p_m12_H: 0.208,
    },
    "0912": {
      H: 1.78,
      L: 1.75,
      O: 1.76,
      C: 1.77,
      V: 144000,
      p_w1_L: 1.74,
      p_w1_H: 1.79,
      p_m1_L: 1.72,
      p_m1_H: 1.88,
      p_m3_L: 1.72,
      p_m3_H: 1.93,
      p_m6_L: 1.72,
      p_m6_H: 2.37,
      p_m12_L: 1.48,
      p_m12_H: 2.37,
    },
    "0913": {
      H: 0.56,
      L: 0.56,
      O: 0.56,
      C: 0.56,
      V: 30000,
      p_w1_L: 0.5,
      p_w1_H: 0.62,
      p_m1_L: 0.48,
      p_m1_H: 0.68,
      p_m3_L: 0.25,
      p_m3_H: 0.68,
      p_m6_L: 0.25,
      p_m6_H: 0.68,
      p_m12_L: 0.128,
      p_m12_H: 0.68,
    },
    "0914": {
      H: 39.3,
      L: 38.55,
      O: 39.3,
      C: 38.85,
      V: 3631500,
      p_w1_L: 35.65,
      p_w1_H: 39.3,
      p_m1_L: 35.1,
      p_m1_H: 39.45,
      p_m3_L: 35.1,
      p_m3_H: 50.2,
      p_m6_L: 35.1,
      p_m6_H: 50.2,
      p_m12_L: 35.1,
      p_m12_H: 53.6,
    },
    "0915": {
      H: 0.157,
      L: 0.12,
      O: 0.14,
      C: 0.148,
      V: 2692000,
      p_w1_L: 0.12,
      p_w1_H: 0.165,
      p_m1_L: 0.12,
      p_m1_H: 0.208,
      p_m3_L: 0.12,
      p_m3_H: 0.39,
      p_m6_L: 0.06,
      p_m6_H: 0.66,
      p_m12_L: 0.06,
      p_m12_H: 0.66,
    },
    "0916": {
      H: 16.4,
      L: 15.7,
      O: 15.76,
      C: 16.38,
      V: 21281700,
      p_w1_L: 15.6,
      p_w1_H: 17.34,
      p_m1_L: 15,
      p_m1_H: 17.98,
      p_m3_L: 14.96,
      p_m3_H: 20.25,
      p_m6_L: 10.46,
      p_m6_H: 20.25,
      p_m12_L: 5.87,
      p_m12_H: 20.25,
    },
    "0918": {
      H: 0.34,
      L: 0.335,
      O: 0.34,
      C: 0.34,
      V: 144000,
      p_w1_L: 0.335,
      p_w1_H: 0.345,
      p_m1_L: 0.3,
      p_m1_H: 0.37,
      p_m3_L: 0.26,
      p_m3_H: 0.55,
      p_m6_L: 0.26,
      p_m6_H: 0.55,
      p_m12_L: 0.073,
      p_m12_H: 0.76,
    },
    "0919": {
      H: 0.179,
      L: 0.165,
      O: 0.171,
      C: 0.178,
      V: 120000,
      p_w1_L: 0.16,
      p_w1_H: 0.199,
      p_m1_L: 0.16,
      p_m1_H: 0.241,
      p_m3_L: 0.16,
      p_m3_H: 0.247,
      p_m6_L: 0.16,
      p_m6_H: 0.37,
      p_m12_L: 0.088,
      p_m12_H: 0.39,
    },
    "0921": {
      H: 8.87,
      L: 8.55,
      O: 8.87,
      C: 8.8,
      V: 1222000,
      p_w1_L: 7.5,
      p_w1_H: 8.87,
      p_m1_L: 7.5,
      p_m1_H: 8.87,
      p_m3_L: 7.5,
      p_m3_H: 10.48,
      p_m6_L: 7.5,
      p_m6_H: 11.74,
      p_m12_L: 7.5,
      p_m12_H: 17.16,
    },
    "0922": {
      H: 0.205,
      L: 0.198,
      O: 0.2,
      C: 0.205,
      V: 430000,
      p_w1_L: 0.198,
      p_w1_H: 0.208,
      p_m1_L: 0.19,
      p_m1_H: 0.215,
      p_m3_L: 0.19,
      p_m3_H: 0.235,
      p_m6_L: 0.173,
      p_m6_H: 0.27,
      p_m12_L: 0.13,
      p_m12_H: 0.27,
    },
    "0923": {
      H: 0.043,
      L: 0.043,
      O: 0.043,
      C: 0.043,
      V: 4000,
      p_w1_L: 0.042,
      p_w1_H: 0.046,
      p_m1_L: 0.042,
      p_m1_H: 0.056,
      p_m3_L: 0.042,
      p_m3_H: 0.058,
      p_m6_L: 0.042,
      p_m6_H: 0.065,
      p_m12_L: 0.041,
      p_m12_H: 0.071,
    },
    "0924": {
      H: 0.65,
      L: 0.65,
      O: 0.65,
      C: 0.65,
      V: 0,
      p_w1_L: 0.58,
      p_w1_H: 0.7,
      p_m1_L: 0.56,
      p_m1_H: 0.75,
      p_m3_L: 0.48,
      p_m3_H: 0.8,
      p_m6_L: 0.4,
      p_m6_H: 0.91,
      p_m12_L: 0.37,
      p_m12_H: 0.91,
    },
    "0925": {
      H: 0.099,
      L: 0.095,
      O: 0.097,
      C: 0.097,
      V: 3528000,
      p_w1_L: 0.09,
      p_w1_H: 0.099,
      p_m1_L: 0.09,
      p_m1_H: 0.102,
      p_m3_L: 0.09,
      p_m3_H: 0.114,
      p_m6_L: 0.09,
      p_m6_H: 0.131,
      p_m12_L: 0.09,
      p_m12_H: 0.134,
    },
    "0926": {
      H: 0.188,
      L: 0.184,
      O: 0.185,
      C: 0.184,
      V: 547000,
      p_w1_L: 0.184,
      p_w1_H: 0.212,
      p_m1_L: 0.184,
      p_m1_H: 0.24,
      p_m3_L: 0.184,
      p_m3_H: 0.34,
      p_m6_L: 0.184,
      p_m6_H: 0.49,
      p_m12_L: 0.184,
      p_m12_H: 0.49,
    },
    "0927": {
      H: 0.87,
      L: 0.87,
      O: 0.87,
      C: 0.87,
      V: 0,
      p_w1_L: 0.87,
      p_w1_H: 0.92,
      p_m1_L: 0.86,
      p_m1_H: 0.92,
      p_m3_L: 0.85,
      p_m3_H: 0.95,
      p_m6_L: 0.85,
      p_m6_H: 1.18,
      p_m12_L: 0.82,
      p_m12_H: 1.18,
    },
    "0928": {
      H: 0.135,
      L: 0.122,
      O: 0.135,
      C: 0.122,
      V: 1000000,
      p_w1_L: 0.12,
      p_w1_H: 0.151,
      p_m1_L: 0.12,
      p_m1_H: 0.154,
      p_m3_L: 0.1,
      p_m3_H: 0.18,
      p_m6_L: 0.096,
      p_m6_H: 0.18,
      p_m12_L: 0.096,
      p_m12_H: 0.28,
    },
    "0929": {
      H: 0.99,
      L: 0.86,
      O: 0.86,
      C: 0.9,
      V: 1690000,
      p_w1_L: 0.79,
      p_w1_H: 1.03,
      p_m1_L: 0.78,
      p_m1_H: 1.03,
      p_m3_L: 0.77,
      p_m3_H: 1.32,
      p_m6_L: 0.65,
      p_m6_H: 1.32,
      p_m12_L: 0.6,
      p_m12_H: 1.32,
    },
    "0931": {
      H: 0.385,
      L: 0.38,
      O: 0.38,
      C: 0.38,
      V: 414000,
      p_w1_L: 0.375,
      p_w1_H: 0.425,
      p_m1_L: 0.36,
      p_m1_H: 0.425,
      p_m3_L: 0.35,
      p_m3_H: 0.57,
      p_m6_L: 0.32,
      p_m6_H: 0.57,
      p_m12_L: 0.32,
      p_m12_H: 0.61,
    },
    "0932": {
      H: 0.31,
      L: 0.29,
      O: 0.3,
      C: 0.3,
      V: 11652000,
      p_w1_L: 0.29,
      p_w1_H: 0.32,
      p_m1_L: 0.29,
      p_m1_H: 0.375,
      p_m3_L: 0.23,
      p_m3_H: 0.44,
      p_m6_L: 0.107,
      p_m6_H: 0.44,
      p_m12_L: 0.107,
      p_m12_H: 0.44,
    },
    "0934": {
      H: 2.95,
      L: 2.92,
      O: 2.94,
      C: 2.95,
      V: 332000,
      p_w1_L: 2.85,
      p_w1_H: 2.98,
      p_m1_L: 2.84,
      p_m1_H: 2.98,
      p_m3_L: 2.83,
      p_m3_H: 3.29,
      p_m6_L: 2.65,
      p_m6_H: 3.29,
      p_m12_L: 2.65,
      p_m12_H: 3.29,
    },
    "0935": {
      H: 1.22,
      L: 1.21,
      O: 1.21,
      C: 1.22,
      V: 342000,
      p_w1_L: 1.2,
      p_w1_H: 1.22,
      p_m1_L: 1.18,
      p_m1_H: 1.22,
      p_m3_L: 1.17,
      p_m3_H: 1.24,
      p_m6_L: 0.83,
      p_m6_H: 1.24,
      p_m12_L: 0.53,
      p_m12_H: 1.24,
    },
    "0936": {
      H: 0.315,
      L: 0.285,
      O: 0.305,
      C: 0.315,
      V: 140000,
      p_w1_L: 0.285,
      p_w1_H: 0.345,
      p_m1_L: 0.285,
      p_m1_H: 0.46,
      p_m3_L: 0.285,
      p_m3_H: 0.96,
      p_m6_L: 0.285,
      p_m6_H: 1.36,
      p_m12_L: 0.285,
      p_m12_H: 1.36,
    },
    "0938": {
      H: 1.65,
      L: 1.65,
      O: 1.65,
      C: 1.65,
      V: 2000,
      p_w1_L: 1.54,
      p_w1_H: 1.65,
      p_m1_L: 1.5,
      p_m1_H: 1.66,
      p_m3_L: 1.4,
      p_m3_H: 1.7,
      p_m6_L: 1.4,
      p_m6_H: 2.08,
      p_m12_L: 1.4,
      p_m12_H: 2.36,
    },
    "0939": {
      H: 5.34,
      L: 5.25,
      O: 5.31,
      C: 5.27,
      V: 311117000,
      p_w1_L: 5.08,
      p_w1_H: 5.36,
      p_m1_L: 5.03,
      p_m1_H: 5.36,
      p_m3_L: 5.03,
      p_m3_H: 5.84,
      p_m6_L: 5.03,
      p_m6_H: 6.33,
      p_m12_L: 5.03,
      p_m12_H: 6.74,
    },
    "0941": {
      H: 46.7,
      L: 46.3,
      O: 46.5,
      C: 46.6,
      V: 12828900,
      p_w1_L: 46.15,
      p_w1_H: 47.25,
      p_m1_L: 46.15,
      p_m1_H: 48.55,
      p_m3_L: 46.15,
      p_m3_H: 49.8,
      p_m6_L: 46.15,
      p_m6_H: 52.95,
      p_m12_L: 39,
      p_m12_H: 59.2,
    },
    "0943": {
      H: 0.022,
      L: 0.021,
      O: 0.022,
      C: 0.022,
      V: 465000,
      p_w1_L: 0.021,
      p_w1_H: 0.023,
      p_m1_L: 0.021,
      p_m1_H: 0.026,
      p_m3_L: 0.02,
      p_m3_H: 0.034,
      p_m6_L: 0.02,
      p_m6_H: 0.034,
      p_m12_L: 0.02,
      p_m12_H: 0.085,
    },
    "0945": {
      H: 147.1,
      L: 146,
      O: 146,
      C: 146.5,
      V: 5816,
      p_w1_L: 142.8,
      p_w1_H: 147.1,
      p_m1_L: 142.8,
      p_m1_H: 159.5,
      p_m3_L: 142.8,
      p_m3_H: 161,
      p_m6_L: 142.8,
      p_m6_H: 161.6,
      p_m12_L: 130.6,
      p_m12_H: 170.8,
    },
    "0947": {
      H: 0.37,
      L: 0.36,
      O: 0.37,
      C: 0.37,
      V: 216000,
      p_w1_L: 0.36,
      p_w1_H: 0.395,
      p_m1_L: 0.36,
      p_m1_H: 0.395,
      p_m3_L: 0.355,
      p_m3_H: 0.425,
      p_m6_L: 0.355,
      p_m6_H: 0.55,
      p_m12_L: 0.355,
      p_m12_H: 0.69,
    },
    "0948": {
      H: 1.25,
      L: 1.05,
      O: 1.07,
      C: 1.25,
      V: 850000,
      p_w1_L: 1.01,
      p_w1_H: 1.4,
      p_m1_L: 1.01,
      p_m1_H: 2.09,
      p_m3_L: 1,
      p_m3_H: 2.09,
      p_m6_L: 0.61,
      p_m6_H: 2.09,
      p_m12_L: 0.61,
      p_m12_H: 2.09,
    },
    "0950": {
      H: 3.45,
      L: 3.37,
      O: 3.4,
      C: 3.42,
      V: 300998,
      p_w1_L: 3.32,
      p_w1_H: 3.56,
      p_m1_L: 3.3,
      p_m1_H: 3.9,
      p_m3_L: 3.3,
      p_m3_H: 4.17,
      p_m6_L: 3.3,
      p_m6_H: 5.13,
      p_m12_L: 3.3,
      p_m12_H: 6.82,
    },
    "0951": {
      H: 2.08,
      L: 2.05,
      O: 2.05,
      C: 2.05,
      V: 509000,
      p_w1_L: 1.97,
      p_w1_H: 2.17,
      p_m1_L: 1.95,
      p_m1_H: 2.31,
      p_m3_L: 1.95,
      p_m3_H: 2.54,
      p_m6_L: 1.95,
      p_m6_H: 3.88,
      p_m12_L: 1.95,
      p_m12_H: 3.99,
    },
    "0952": {
      H: 0.199,
      L: 0.199,
      O: 0.199,
      C: 0.199,
      V: 10000,
      p_w1_L: 0.165,
      p_w1_H: 0.199,
      p_m1_L: 0.165,
      p_m1_H: 0.22,
      p_m3_L: 0.165,
      p_m3_H: 0.28,
      p_m6_L: 0.165,
      p_m6_H: 0.28,
      p_m12_L: 0.165,
      p_m12_H: 0.335,
    },
    "0953": {
      H: 0.118,
      L: 0.105,
      O: 0.107,
      C: 0.118,
      V: 330000,
      p_w1_L: 0.103,
      p_w1_H: 0.119,
      p_m1_L: 0.095,
      p_m1_H: 0.119,
      p_m3_L: 0.095,
      p_m3_H: 0.142,
      p_m6_L: 0.095,
      p_m6_H: 0.154,
      p_m12_L: 0.095,
      p_m12_H: 0.16,
    },
    "0954": {
      H: 0.67,
      L: 0.67,
      O: 0.67,
      C: 0.67,
      V: 0,
      p_w1_L: 0.67,
      p_w1_H: 0.7,
      p_m1_L: 0.66,
      p_m1_H: 0.7,
      p_m3_L: 0.63,
      p_m3_H: 0.77,
      p_m6_L: 0.61,
      p_m6_H: 0.77,
      p_m12_L: 0.55,
      p_m12_H: 0.88,
    },
    "0956": {
      H: 5.81,
      L: 5.58,
      O: 5.7,
      C: 5.81,
      V: 14576500,
      p_w1_L: 5.4,
      p_w1_H: 5.93,
      p_m1_L: 5.34,
      p_m1_H: 6.39,
      p_m3_L: 5.34,
      p_m3_H: 8.23,
      p_m6_L: 3.1,
      p_m6_H: 8.23,
      p_m12_L: 1.98,
      p_m12_H: 8.23,
    },
    "0959": {
      H: 0.41,
      L: 0.41,
      O: 0.41,
      C: 0.41,
      V: 5530,
      p_w1_L: 0.39,
      p_w1_H: 0.42,
      p_m1_L: 0.385,
      p_m1_H: 0.45,
      p_m3_L: 0.365,
      p_m3_H: 0.455,
      p_m6_L: 0.335,
      p_m6_H: 0.49,
      p_m12_L: 0.335,
      p_m12_H: 0.61,
    },
    "0960": {
      H: 41,
      L: 39.05,
      O: 40.7,
      C: 39.6,
      V: 13316500,
      p_w1_L: 36.7,
      p_w1_H: 41,
      p_m1_L: 33.6,
      p_m1_H: 41.45,
      p_m3_L: 30.25,
      p_m3_H: 41.45,
      p_m6_L: 30.25,
      p_m6_H: 47.15,
      p_m12_L: 30.25,
      p_m12_H: 53.6,
    },
    "0966": {
      H: 11.16,
      L: 10.94,
      O: 11.1,
      C: 10.94,
      V: 4251800,
      p_w1_L: 10.82,
      p_w1_H: 11.22,
      p_m1_L: 10.76,
      p_m1_H: 11.82,
      p_m3_L: 10.76,
      p_m3_H: 13,
      p_m6_L: 10.64,
      p_m6_H: 14.2,
      p_m12_L: 10.64,
      p_m12_H: 19.12,
    },
    "0967": {
      H: 2.98,
      L: 2.98,
      O: 2.98,
      C: 2.98,
      V: 0,
      p_w1_L: 2.98,
      p_w1_H: 2.98,
      p_m1_L: 2.98,
      p_m1_H: 2.98,
      p_m3_L: 2.98,
      p_m3_H: 2.98,
      p_m6_L: 2.98,
      p_m6_H: 2.98,
      p_m12_L: 2.98,
      p_m12_H: 2.98,
    },
    "0968": {
      H: 14.68,
      L: 14.28,
      O: 14.38,
      C: 14.56,
      V: 29622400,
      p_w1_L: 13.8,
      p_w1_H: 14.68,
      p_m1_L: 13.14,
      p_m1_H: 15.02,
      p_m3_L: 13.14,
      p_m3_H: 18.14,
      p_m6_L: 12.72,
      p_m6_H: 19.36,
      p_m12_L: 9.9,
      p_m12_H: 24.8,
    },
    "0969": {
      H: 0.06,
      L: 0.06,
      O: 0.06,
      C: 0.06,
      V: 0,
      p_w1_L: 0.06,
      p_w1_H: 0.069,
      p_m1_L: 0.056,
      p_m1_H: 0.071,
      p_m3_L: 0.045,
      p_m3_H: 0.08,
      p_m6_L: 0.035,
      p_m6_H: 0.08,
      p_m12_L: 0.035,
      p_m12_H: 0.085,
    },
    "0970": {
      H: 0.2,
      L: 0.193,
      O: 0.194,
      C: 0.195,
      V: 9320000,
      p_w1_L: 0.18,
      p_w1_H: 0.207,
      p_m1_L: 0.17,
      p_m1_H: 0.207,
      p_m3_L: 0.142,
      p_m3_H: 0.207,
      p_m6_L: 0.142,
      p_m6_H: 0.235,
      p_m12_L: 0.142,
      p_m12_H: 0.35,
    },
    "0973": {
      H: 32.55,
      L: 31.4,
      O: 32,
      C: 31.8,
      V: 1235290,
      p_w1_L: 28,
      p_w1_H: 32.95,
      p_m1_L: 26.6,
      p_m1_H: 33,
      p_m3_L: 24.5,
      p_m3_H: 33,
      p_m6_L: 24.5,
      p_m6_H: 33,
      p_m12_L: 17.52,
      p_m12_H: 33,
    },
    "0974": {
      H: 0.7,
      L: 0.69,
      O: 0.69,
      C: 0.7,
      V: 43000,
      p_w1_L: 0.67,
      p_w1_H: 0.7,
      p_m1_L: 0.63,
      p_m1_H: 0.78,
      p_m3_L: 0.6,
      p_m3_H: 0.85,
      p_m6_L: 0.53,
      p_m6_H: 0.85,
      p_m12_L: 0.5,
      p_m12_H: 0.88,
    },
    "0975": {
      H: 1.81,
      L: 1.76,
      O: 1.79,
      C: 1.77,
      V: 252000,
      p_w1_L: 1.75,
      p_w1_H: 1.9,
      p_m1_L: 1.75,
      p_m1_H: 2.18,
      p_m3_L: 1.75,
      p_m3_H: 4.35,
      p_m6_L: 1.6,
      p_m6_H: 4.35,
      p_m12_L: 1.06,
      p_m12_H: 4.35,
    },
    "0976": {
      H: 1.11,
      L: 1.03,
      O: 1.09,
      C: 1.03,
      V: 3214000,
      p_w1_L: 0.79,
      p_w1_H: 1.37,
      p_m1_L: 0.79,
      p_m1_H: 1.37,
      p_m3_L: 0.79,
      p_m3_H: 1.65,
      p_m6_L: 0.44,
      p_m6_H: 1.65,
      p_m12_L: 0.175,
      p_m12_H: 1.65,
    },
    "0978": {
      H: 0.84,
      L: 0.81,
      O: 0.81,
      C: 0.83,
      V: 1054000,
      p_w1_L: 0.8,
      p_w1_H: 0.84,
      p_m1_L: 0.8,
      p_m1_H: 0.87,
      p_m3_L: 0.8,
      p_m3_H: 1.01,
      p_m6_L: 0.8,
      p_m6_H: 1.16,
      p_m12_L: 0.8,
      p_m12_H: 1.27,
    },
    "0979": {
      H: 0.23,
      L: 0.23,
      O: 0.23,
      C: 0.23,
      V: 250,
      p_w1_L: 0.227,
      p_w1_H: 0.248,
      p_m1_L: 0.213,
      p_m1_H: 0.275,
      p_m3_L: 0.201,
      p_m3_H: 0.325,
      p_m6_L: 0.201,
      p_m6_H: 0.325,
      p_m12_L: 0.201,
      p_m12_H: 0.41,
    },
    "0980": {
      H: 0.89,
      L: 0.89,
      O: 0.89,
      C: 0.89,
      V: 4000,
      p_w1_L: 0.85,
      p_w1_H: 0.91,
      p_m1_L: 0.85,
      p_m1_H: 0.94,
      p_m3_L: 0.85,
      p_m3_H: 1.05,
      p_m6_L: 0.85,
      p_m6_H: 1.18,
      p_m12_L: 0.85,
      p_m12_H: 1.63,
    },
    "0981": {
      H: 21.25,
      L: 20.8,
      O: 21.2,
      C: 20.85,
      V: 22306100,
      p_w1_L: 20.65,
      p_w1_H: 22.45,
      p_m1_L: 20.65,
      p_m1_H: 24.3,
      p_m3_L: 20.65,
      p_m3_H: 24.3,
      p_m6_L: 20.65,
      p_m6_H: 30.8,
      p_m12_L: 18.08,
      p_m12_H: 31.95,
    },
    "0982": {
      H: 0.161,
      L: 0.16,
      O: 0.161,
      C: 0.16,
      V: 480000,
      p_w1_L: 0.157,
      p_w1_H: 0.168,
      p_m1_L: 0.157,
      p_m1_H: 0.181,
      p_m3_L: 0.157,
      p_m3_H: 0.191,
      p_m6_L: 0.157,
      p_m6_H: 0.224,
      p_m12_L: 0.157,
      p_m12_H: 0.26,
    },
    "0983": {
      H: 1.35,
      L: 1.35,
      O: 1.35,
      C: 1.35,
      V: 30000,
      p_w1_L: 1.33,
      p_w1_H: 1.39,
      p_m1_L: 1.33,
      p_m1_H: 1.55,
      p_m3_L: 1.33,
      p_m3_H: 1.68,
      p_m6_L: 1.33,
      p_m6_H: 1.8,
      p_m12_L: 1.15,
      p_m12_H: 1.8,
    },
    "0984": {
      H: 1.35,
      L: 1.33,
      O: 1.35,
      C: 1.35,
      V: 16000,
      p_w1_L: 1.33,
      p_w1_H: 1.4,
      p_m1_L: 1.33,
      p_m1_H: 1.47,
      p_m3_L: 1.33,
      p_m3_H: 1.55,
      p_m6_L: 1.33,
      p_m6_H: 1.89,
      p_m12_L: 1.33,
      p_m12_H: 2.37,
    },
    "0985": {
      H: 2.34,
      L: 2.28,
      O: 2.3,
      C: 2.34,
      V: 362358,
      p_w1_L: 2.07,
      p_w1_H: 2.35,
      p_m1_L: 1.23,
      p_m1_H: 2.35,
      p_m3_L: 1.23,
      p_m3_H: 2.35,
      p_m6_L: 1.23,
      p_m6_H: 2.35,
      p_m12_L: 1.23,
      p_m12_H: 3.4,
    },
    "0986": {
      H: 0.1,
      L: 0.096,
      O: 0.1,
      C: 0.099,
      V: 590000,
      p_w1_L: 0.095,
      p_w1_H: 0.1,
      p_m1_L: 0.093,
      p_m1_H: 0.11,
      p_m3_L: 0.093,
      p_m3_H: 0.18,
      p_m6_L: 0.093,
      p_m6_H: 0.18,
      p_m12_L: 0.093,
      p_m12_H: 0.37,
    },
    "0987": {
      H: 0.3,
      L: 0.29,
      O: 0.29,
      C: 0.295,
      V: 599762,
      p_w1_L: 0.285,
      p_w1_H: 0.31,
      p_m1_L: 0.285,
      p_m1_H: 0.325,
      p_m3_L: 0.285,
      p_m3_H: 0.435,
      p_m6_L: 0.2,
      p_m6_H: 0.435,
      p_m12_L: 0.158,
      p_m12_H: 0.435,
    },
    "0988": {
      H: 0.052,
      L: 0.05,
      O: 0.052,
      C: 0.051,
      V: 639000,
      p_w1_L: 0.05,
      p_w1_H: 0.088,
      p_m1_L: 0.05,
      p_m1_H: 0.189,
      p_m3_L: 0.05,
      p_m3_H: 0.295,
      p_m6_L: 0.05,
      p_m6_H: 0.295,
      p_m12_L: 0.05,
      p_m12_H: 0.51,
    },
    "0989": {
      H: 0.61,
      L: 0.54,
      O: 0.54,
      C: 0.6,
      V: 17013000,
      p_w1_L: 0.49,
      p_w1_H: 0.61,
      p_m1_L: 0.38,
      p_m1_H: 0.61,
      p_m3_L: 0.36,
      p_m3_H: 0.61,
      p_m6_L: 0.305,
      p_m6_H: 0.61,
      p_m12_L: 0.305,
      p_m12_H: 0.64,
    },
    "0990": {
      H: 0.72,
      L: 0.69,
      O: 0.7,
      C: 0.69,
      V: 1000000,
      p_w1_L: 0.68,
      p_w1_H: 0.77,
      p_m1_L: 0.68,
      p_m1_H: 0.95,
      p_m3_L: 0.68,
      p_m3_H: 1.24,
      p_m6_L: 0.68,
      p_m6_H: 1.7,
      p_m12_L: 0.118,
      p_m12_H: 1.7,
    },
    "0991": {
      H: 1.34,
      L: 1.3,
      O: 1.3,
      C: 1.34,
      V: 17416600,
      p_w1_L: 1.22,
      p_w1_H: 1.34,
      p_m1_L: 1.19,
      p_m1_H: 1.37,
      p_m3_L: 1.19,
      p_m3_H: 1.59,
      p_m6_L: 1.12,
      p_m6_H: 1.59,
      p_m12_L: 0.97,
      p_m12_H: 1.59,
    },
    "0992": {
      H: 8.5,
      L: 8.36,
      O: 8.4,
      C: 8.46,
      V: 37784500,
      p_w1_L: 7.72,
      p_w1_H: 8.5,
      p_m1_L: 7.58,
      p_m1_H: 8.5,
      p_m3_L: 7.25,
      p_m3_H: 9.65,
      p_m6_L: 6.96,
      p_m6_H: 9.65,
      p_m12_L: 5.65,
      p_m12_H: 11.6,
    },
    "0993": {
      H: 0.197,
      L: 0.178,
      O: 0.189,
      C: 0.185,
      V: 240250,
      p_w1_L: 0.174,
      p_w1_H: 0.203,
      p_m1_L: 0.171,
      p_m1_H: 0.224,
      p_m3_L: 0.155,
      p_m3_H: 0.224,
      p_m6_L: 0.155,
      p_m6_H: 0.229,
      p_m12_L: 0.142,
      p_m12_H: 0.29,
    },
    "0994": {
      H: 2.27,
      L: 2.27,
      O: 2.27,
      C: 2.27,
      V: 0,
      p_w1_L: 2.27,
      p_w1_H: 2.27,
      p_m1_L: 2.27,
      p_m1_H: 2.27,
      p_m3_L: 2.27,
      p_m3_H: 2.27,
      p_m6_L: 2.27,
      p_m6_H: 2.27,
      p_m12_L: 0.63,
      p_m12_H: 2.74,
    },
    "0995": {
      H: 6.03,
      L: 5.95,
      O: 6,
      C: 5.97,
      V: 1270000,
      p_w1_L: 5.8,
      p_w1_H: 6.1,
      p_m1_L: 4.73,
      p_m1_H: 6.15,
      p_m3_L: 4.3,
      p_m3_H: 6.15,
      p_m6_L: 4.3,
      p_m6_H: 6.15,
      p_m12_L: 4.3,
      p_m12_H: 6.15,
    },
    "0996": {
      H: 0.088,
      L: 0.088,
      O: 0.088,
      C: 0.088,
      V: 110000,
      p_w1_L: 0.08,
      p_w1_H: 0.106,
      p_m1_L: 0.08,
      p_m1_H: 0.135,
      p_m3_L: 0.06,
      p_m3_H: 0.198,
      p_m6_L: 0.042,
      p_m6_H: 0.198,
      p_m12_L: 0.042,
      p_m12_H: 0.198,
    },
    "0997": {
      H: 0.067,
      L: 0.062,
      O: 0.067,
      C: 0.067,
      V: 4424000,
      p_w1_L: 0.062,
      p_w1_H: 0.087,
      p_m1_L: 0.062,
      p_m1_H: 0.167,
      p_m3_L: 0.062,
      p_m3_H: 0.98,
      p_m6_L: 0.062,
      p_m6_H: 0.98,
      p_m12_L: 0.062,
      p_m12_H: 0.98,
    },
    "0998": {
      H: 3.48,
      L: 3.43,
      O: 3.46,
      C: 3.44,
      V: 21931800,
      p_w1_L: 3.36,
      p_w1_H: 3.49,
      p_m1_L: 3.35,
      p_m1_H: 3.49,
      p_m3_L: 3.28,
      p_m3_H: 3.74,
      p_m6_L: 3.28,
      p_m6_H: 4.2,
      p_m12_L: 3.15,
      p_m12_H: 4.29,
    },
  },
};

const stockSector = {
  ind: {
    1000: {
      id: "1000",
      i: 6,
      s: 6,
      ss: 8,
    },
    1001: {
      id: "1001",
      i: 7,
      s: 8,
      ss: 10,
    },
    1002: {
      id: "1002",
      i: 9,
      s: 10,
      ss: 19,
    },
    1003: {
      id: "1003",
      i: 6,
      s: 6,
      ss: 77,
    },
    1004: {
      id: "1004",
      i: 1,
      s: 1,
      ss: 39,
    },
    1005: {
      id: "1005",
      i: 6,
      s: 12,
      ss: 15,
    },
    1007: {
      id: "1007",
      i: 6,
      s: 7,
      ss: 22,
    },
    1008: {
      id: "1008",
      i: 9,
      s: 17,
      ss: 24,
    },
    1009: {
      id: "1009",
      i: 2,
      s: 2,
      ss: 5,
    },
    1010: {
      id: "1010",
      i: 10,
      s: 23,
      ss: 37,
    },
    1011: {
      id: "1011",
      i: 5,
      s: 5,
      ss: 7,
    },
    1013: {
      id: "1013",
      i: 10,
      s: 26,
      ss: 48,
    },
    1019: {
      id: "1019",
      i: 3,
      s: 28,
      ss: 67,
    },
    1020: {
      id: "1020",
      i: 10,
      s: 15,
      ss: 52,
    },
    1022: {
      id: "1022",
      i: 10,
      s: 15,
      ss: 35,
    },
    1023: {
      id: "1023",
      i: 6,
      s: 27,
      ss: 49,
    },
    1024: {
      id: "1024",
      i: 10,
      s: 15,
      ss: 35,
    },
    1025: {
      id: "1025",
      i: 6,
      s: 27,
      ss: 50,
    },
    1026: {
      id: "1026",
      i: 1,
      s: 1,
      ss: 71,
    },
    1027: {
      id: "1027",
      i: 6,
      s: 12,
      ss: 83,
    },
    1028: {
      id: "1028",
      i: 6,
      s: 27,
      ss: 65,
    },
    1029: {
      id: "1029",
      i: 7,
      s: 8,
      ss: 10,
    },
    1030: {
      id: "1030",
      i: 2,
      s: 2,
      ss: 3,
    },
    1031: {
      id: "1031",
      i: 3,
      s: 20,
      ss: 29,
    },
    1033: {
      id: "1033",
      i: 11,
      s: 24,
      ss: 64,
    },
    1034: {
      id: "1034",
      i: 2,
      s: 18,
      ss: 69,
    },
    1036: {
      id: "1036",
      i: 2,
      s: 2,
      ss: 5,
    },
    1037: {
      id: "1037",
      i: 10,
      s: 15,
      ss: 21,
    },
    1038: {
      id: "1038",
      i: 1,
      s: 1,
      ss: 1,
    },
    1039: {
      id: "1039",
      i: 10,
      s: 15,
      ss: 52,
    },
    1041: {
      id: "1041",
      i: 3,
      s: 20,
      ss: 31,
    },
    1043: {
      id: "1043",
      i: 9,
      s: 10,
      ss: 58,
    },
    1044: {
      id: "1044",
      i: 8,
      s: 13,
      ss: 84,
    },
    1045: {
      id: "1045",
      i: 4,
      s: 4,
      ss: 88,
    },
    1046: {
      id: "1046",
      i: 6,
      s: 6,
      ss: 77,
    },
    1047: {
      id: "1047",
      i: 7,
      s: 21,
      ss: 30,
    },
    1049: {
      id: "1049",
      i: 6,
      s: 22,
      ss: 89,
    },
    1050: {
      id: "1050",
      i: 10,
      s: 26,
      ss: 78,
    },
    1051: {
      id: "1051",
      i: 3,
      s: 20,
      ss: 31,
    },
    1052: {
      id: "1052",
      i: 9,
      s: 25,
      ss: 46,
    },
    1053: {
      id: "1053",
      i: 7,
      s: 8,
      ss: 10,
    },
    1054: {
      id: "1054",
      i: 3,
      s: 20,
      ss: 29,
    },
    1055: {
      id: "1055",
      i: 6,
      s: 7,
      ss: 75,
    },
    1057: {
      id: "1057",
      i: 6,
      s: 16,
      ss: 23,
    },
    1058: {
      id: "1058",
      i: 6,
      s: 27,
      ss: 49,
    },
    1059: {
      id: "1059",
      i: 10,
      s: 15,
      ss: 21,
    },
    1060: {
      id: "1060",
      i: 6,
      s: 6,
      ss: 77,
    },
    1061: {
      id: "1061",
      i: 5,
      s: 5,
      ss: 7,
    },
    1062: {
      id: "1062",
      i: 3,
      s: 20,
      ss: 31,
    },
    1063: {
      id: "1063",
      i: 10,
      s: 26,
      ss: 48,
    },
    1064: {
      id: "1064",
      i: 2,
      s: 2,
      ss: 5,
    },
    1065: {
      id: "1065",
      i: 1,
      s: 1,
      ss: 71,
    },
    1066: {
      id: "1066",
      i: 5,
      s: 30,
      ss: 92,
    },
    1068: {
      id: "1068",
      i: 8,
      s: 14,
      ss: 74,
    },
    1069: {
      id: "1069",
      i: 7,
      s: 21,
      ss: 41,
    },
    1070: {
      id: "1070",
      i: 6,
      s: 12,
      ss: 18,
    },
    1071: {
      id: "1071",
      i: 1,
      s: 1,
      ss: 1,
    },
    1072: {
      id: "1072",
      i: 9,
      s: 10,
      ss: 57,
    },
    1073: {
      id: "1073",
      i: 3,
      s: 20,
      ss: 63,
    },
    1074: {
      id: "1074",
      i: 7,
      s: 8,
      ss: 10,
    },
    1075: {
      id: "1075",
      i: 10,
      s: 15,
      ss: 21,
    },
    1076: {
      id: "1076",
      i: 6,
      s: 7,
      ss: 11,
    },
    1079: {
      id: "1079",
      i: 10,
      s: 26,
      ss: 78,
    },
    1080: {
      id: "1080",
      i: 11,
      s: 24,
      ss: 64,
    },
    1082: {
      id: "1082",
      i: 6,
      s: 11,
      ss: 14,
    },
    1083: {
      id: "1083",
      i: 1,
      s: 1,
      ss: 2,
    },
    1084: {
      id: "1084",
      i: 8,
      s: 9,
      ss: 12,
    },
    1085: {
      id: "1085",
      i: 10,
      s: 26,
      ss: 48,
    },
    1086: {
      id: "1086",
      i: 6,
      s: 12,
      ss: 83,
    },
    1087: {
      id: "1087",
      i: 10,
      s: 15,
      ss: 21,
    },
    1088: {
      id: "1088",
      i: 11,
      s: 19,
      ss: 27,
    },
    1090: {
      id: "1090",
      i: 7,
      s: 8,
      ss: 10,
    },
    1091: {
      id: "1091",
      i: 7,
      s: 8,
      ss: 60,
    },
    1093: {
      id: "1093",
      i: 5,
      s: 5,
      ss: 7,
    },
    1094: {
      id: "1094",
      i: 10,
      s: 15,
      ss: 52,
    },
    1096: {
      id: "1096",
      i: 1,
      s: 1,
      ss: 2,
    },
    1097: {
      id: "1097",
      i: 6,
      s: 6,
      ss: 91,
    },
    1098: {
      id: "1098",
      i: 2,
      s: 2,
      ss: 3,
    },
    1099: {
      id: "1099",
      i: 5,
      s: 5,
      ss: 7,
    },
    1100: {
      id: "1100",
      i: 6,
      s: 27,
      ss: 49,
    },
    1101: {
      id: "1101",
      i: 11,
      s: 24,
      ss: 40,
    },
    1102: {
      id: "1102",
      i: 2,
      s: 18,
      ss: 25,
    },
    1103: {
      id: "1103",
      i: 7,
      s: 21,
      ss: 76,
    },
    1104: {
      id: "1104",
      i: 7,
      s: 8,
      ss: 10,
    },
    1105: {
      id: "1105",
      i: 6,
      s: 6,
      ss: 8,
    },
    1106: {
      id: "1106",
      i: 9,
      s: 17,
      ss: 24,
    },
    1107: {
      id: "1107",
      i: 2,
      s: 2,
      ss: 3,
    },
    1108: {
      id: "1108",
      i: 9,
      s: 10,
      ss: 19,
    },
    1109: {
      id: "1109",
      i: 2,
      s: 2,
      ss: 3,
    },
    1110: {
      id: "1110",
      i: 5,
      s: 5,
      ss: 7,
    },
    1111: {
      id: "1111",
      i: 3,
      s: 3,
      ss: 4,
    },
    1112: {
      id: "1112",
      i: 8,
      s: 13,
      ss: 84,
    },
    1113: {
      id: "1113",
      i: 2,
      s: 2,
      ss: 3,
    },
    1114: {
      id: "1114",
      i: 6,
      s: 16,
      ss: 62,
    },
    1115: {
      id: "1115",
      i: 8,
      s: 9,
      ss: 66,
    },
    1116: {
      id: "1116",
      i: 7,
      s: 8,
      ss: 10,
    },
    1117: {
      id: "1117",
      i: 8,
      s: 9,
      ss: 87,
    },
    1118: {
      id: "1118",
      i: 2,
      s: 18,
      ss: 25,
    },
    1119: {
      id: "1119",
      i: 10,
      s: 15,
      ss: 35,
    },
    1120: {
      id: "1120",
      i: 6,
      s: 27,
      ss: 49,
    },
    1121: {
      id: "1121",
      i: 6,
      s: 27,
      ss: 65,
    },
    1122: {
      id: "1122",
      i: 9,
      s: 10,
      ss: 45,
    },
    1123: {
      id: "1123",
      i: 6,
      s: 12,
      ss: 18,
    },
    1124: {
      id: "1124",
      i: 2,
      s: 2,
      ss: 5,
    },
    1125: {
      id: "1125",
      i: 2,
      s: 2,
      ss: 5,
    },
    1126: {
      id: "1126",
      i: 6,
      s: 12,
      ss: 15,
    },
    1127: {
      id: "1127",
      i: 9,
      s: 17,
      ss: 24,
    },
    1128: {
      id: "1128",
      i: 6,
      s: 7,
      ss: 11,
    },
    1129: {
      id: "1129",
      i: 1,
      s: 1,
      ss: 71,
    },
    1130: {
      id: "1130",
      i: 6,
      s: 16,
      ss: 62,
    },
    1131: {
      id: "1131",
      i: 11,
      s: 19,
      ss: 27,
    },
    1132: {
      id: "1132",
      i: 6,
      s: 6,
      ss: 77,
    },
    1133: {
      id: "1133",
      i: 9,
      s: 10,
      ss: 57,
    },
    1134: {
      id: "1134",
      i: 6,
      s: 27,
      ss: 49,
    },
    1137: {
      id: "1137",
      i: 10,
      s: 15,
      ss: 52,
    },
    1138: {
      id: "1138",
      i: 9,
      s: 25,
      ss: 53,
    },
    1139: {
      id: "1139",
      i: 6,
      s: 22,
      ss: 54,
    },
    1140: {
      id: "1140",
      i: 3,
      s: 20,
      ss: 31,
    },
    1141: {
      id: "1141",
      i: 3,
      s: 20,
      ss: 29,
    },
    1142: {
      id: "1142",
      i: 7,
      s: 8,
      ss: 60,
    },
    1143: {
      id: "1143",
      i: 6,
      s: 12,
      ss: 18,
    },
    1145: {
      id: "1145",
      i: 9,
      s: 25,
      ss: 53,
    },
    1146: {
      id: "1146",
      i: 6,
      s: 27,
      ss: 50,
    },
    1147: {
      id: "1147",
      i: 10,
      s: 15,
      ss: 21,
    },
    1148: {
      id: "1148",
      i: 6,
      s: 16,
      ss: 23,
    },
    1150: {
      id: "1150",
      i: 6,
      s: 27,
      ss: 49,
    },
    1152: {
      id: "1152",
      i: 8,
      s: 9,
      ss: 26,
    },
    1153: {
      id: "1153",
      i: 2,
      s: 2,
      ss: 47,
    },
    1156: {
      id: "1156",
      i: 11,
      s: 24,
      ss: 64,
    },
    1157: {
      id: "1157",
      i: 9,
      s: 10,
      ss: 16,
    },
    1158: {
      id: "1158",
      i: 6,
      s: 7,
      ss: 9,
    },
    1159: {
      id: "1159",
      i: 6,
      s: 6,
      ss: 77,
    },
    1160: {
      id: "1160",
      i: 3,
      s: 20,
      ss: 31,
    },
    1161: {
      id: "1161",
      i: 5,
      s: 30,
      ss: 73,
    },
    1162: {
      id: "1162",
      i: 2,
      s: 18,
      ss: 56,
    },
    1163: {
      id: "1163",
      i: 6,
      s: 6,
      ss: 44,
    },
    1164: {
      id: "1164",
      i: 7,
      s: 8,
      ss: 60,
    },
    1165: {
      id: "1165",
      i: 1,
      s: 1,
      ss: 39,
    },
    1166: {
      id: "1166",
      i: 9,
      s: 10,
      ss: 19,
    },
    1167: {
      id: "1167",
      i: 5,
      s: 5,
      ss: 94,
    },
    1168: {
      id: "1168",
      i: 2,
      s: 2,
      ss: 5,
    },
    1170: {
      id: "1170",
      i: 6,
      s: 27,
      ss: 65,
    },
    1171: {
      id: "1171",
      i: 11,
      s: 19,
      ss: 27,
    },
    1172: {
      id: "1172",
      i: 9,
      s: 17,
      ss: 24,
    },
    1173: {
      id: "1173",
      i: 8,
      s: 13,
      ss: 84,
    },
    1175: {
      id: "1175",
      i: 8,
      s: 9,
      ss: 26,
    },
    1176: {
      id: "1176",
      i: 2,
      s: 2,
      ss: 3,
    },
    1177: {
      id: "1177",
      i: 5,
      s: 5,
      ss: 7,
    },
    1178: {
      id: "1178",
      i: 0,
      s: 0,
      ss: 0,
    },
    1179: {
      id: "1179",
      i: 6,
      s: 7,
      ss: 9,
    },
    1180: {
      id: "1180",
      i: 6,
      s: 7,
      ss: 11,
    },
    1181: {
      id: "1181",
      i: 6,
      s: 7,
      ss: 22,
    },
    1182: {
      id: "1182",
      i: 6,
      s: 7,
      ss: 11,
    },
    1183: {
      id: "1183",
      i: 2,
      s: 18,
      ss: 56,
    },
    1184: {
      id: "1184",
      i: 9,
      s: 10,
      ss: 19,
    },
    1185: {
      id: "1185",
      i: 9,
      s: 10,
      ss: 57,
    },
    1186: {
      id: "1186",
      i: 2,
      s: 18,
      ss: 69,
    },
    1188: {
      id: "1188",
      i: 6,
      s: 16,
      ss: 23,
    },
    1189: {
      id: "1189",
      i: 6,
      s: 7,
      ss: 9,
    },
    1191: {
      id: "1191",
      i: 3,
      s: 20,
      ss: 31,
    },
    1192: {
      id: "1192",
      i: 9,
      s: 10,
      ss: 45,
    },
    1193: {
      id: "1193",
      i: 1,
      s: 1,
      ss: 2,
    },
    1194: {
      id: "1194",
      i: 7,
      s: 29,
      ss: 72,
    },
    1195: {
      id: "1195",
      i: 2,
      s: 2,
      ss: 3,
    },
    1196: {
      id: "1196",
      i: 9,
      s: 10,
      ss: 57,
    },
    1198: {
      id: "1198",
      i: 6,
      s: 12,
      ss: 83,
    },
    1199: {
      id: "1199",
      i: 9,
      s: 25,
      ss: 53,
    },
    1200: {
      id: "1200",
      i: 2,
      s: 2,
      ss: 80,
    },
    1201: {
      id: "1201",
      i: 2,
      s: 2,
      ss: 3,
    },
    1202: {
      id: "1202",
      i: 10,
      s: 26,
      ss: 48,
    },
    1203: {
      id: "1203",
      i: 7,
      s: 8,
      ss: 10,
    },
    1205: {
      id: "1205",
      i: 7,
      s: 8,
      ss: 60,
    },
    1206: {
      id: "1206",
      i: 10,
      s: 15,
      ss: 21,
    },
    1207: {
      id: "1207",
      i: 2,
      s: 2,
      ss: 3,
    },
    1208: {
      id: "1208",
      i: 7,
      s: 8,
      ss: 81,
    },
    1209: {
      id: "1209",
      i: 2,
      s: 2,
      ss: 47,
    },
    1210: {
      id: "1210",
      i: 8,
      s: 9,
      ss: 26,
    },
    1211: {
      id: "1211",
      i: 6,
      s: 16,
      ss: 62,
    },
    1212: {
      id: "1212",
      i: 6,
      s: 22,
      ss: 59,
    },
    1213: {
      id: "1213",
      i: 9,
      s: 10,
      ss: 19,
    },
    1215: {
      id: "1215",
      i: 6,
      s: 7,
      ss: 9,
    },
    1216: {
      id: "1216",
      i: 3,
      s: 3,
      ss: 4,
    },
    1217: {
      id: "1217",
      i: 3,
      s: 20,
      ss: 31,
    },
    1218: {
      id: "1218",
      i: 2,
      s: 2,
      ss: 3,
    },
    1220: {
      id: "1220",
      i: 2,
      s: 18,
      ss: 56,
    },
    1221: {
      id: "1221",
      i: 6,
      s: 7,
      ss: 9,
    },
    1222: {
      id: "1222",
      i: 2,
      s: 2,
      ss: 3,
    },
    1223: {
      id: "1223",
      i: 6,
      s: 22,
      ss: 51,
    },
    1224: {
      id: "1224",
      i: 2,
      s: 2,
      ss: 3,
    },
    1225: {
      id: "1225",
      i: 3,
      s: 20,
      ss: 38,
    },
    1226: {
      id: "1226",
      i: 3,
      s: 20,
      ss: 31,
    },
    1227: {
      id: "1227",
      i: 3,
      s: 20,
      ss: 31,
    },
    1229: {
      id: "1229",
      i: 11,
      s: 19,
      ss: 27,
    },
    1230: {
      id: "1230",
      i: 8,
      s: 9,
      ss: 87,
    },
    1231: {
      id: "1231",
      i: 7,
      s: 8,
      ss: 10,
    },
    1232: {
      id: "1232",
      i: 2,
      s: 2,
      ss: 3,
    },
    1233: {
      id: "1233",
      i: 2,
      s: 2,
      ss: 3,
    },
    1234: {
      id: "1234",
      i: 6,
      s: 27,
      ss: 50,
    },
    1235: {
      id: "1235",
      i: 6,
      s: 7,
      ss: 32,
    },
    1237: {
      id: "1237",
      i: 6,
      s: 12,
      ss: 83,
    },
    1238: {
      id: "1238",
      i: 2,
      s: 2,
      ss: 3,
    },
    1239: {
      id: "1239",
      i: 9,
      s: 17,
      ss: 24,
    },
    1240: {
      id: "1240",
      i: 2,
      s: 2,
      ss: 3,
    },
    1241: {
      id: "1241",
      i: 6,
      s: 16,
      ss: 23,
    },
    1243: {
      id: "1243",
      i: 2,
      s: 2,
      ss: 3,
    },
    1245: {
      id: "1245",
      i: 6,
      s: 7,
      ss: 11,
    },
    1246: {
      id: "1246",
      i: 2,
      s: 2,
      ss: 3,
    },
    1247: {
      id: "1247",
      i: 6,
      s: 27,
      ss: 50,
    },
    1250: {
      id: "1250",
      i: 1,
      s: 1,
      ss: 39,
    },
    1251: {
      id: "1251",
      i: 11,
      s: 24,
      ss: 64,
    },
    1252: {
      id: "1252",
      i: 2,
      s: 18,
      ss: 25,
    },
    1253: {
      id: "1253",
      i: 2,
      s: 18,
      ss: 56,
    },
    1255: {
      id: "1255",
      i: 6,
      s: 22,
      ss: 51,
    },
    1257: {
      id: "1257",
      i: 1,
      s: 1,
      ss: 39,
    },
    1258: {
      id: "1258",
      i: 7,
      s: 8,
      ss: 81,
    },
    1259: {
      id: "1259",
      i: 8,
      s: 13,
      ss: 84,
    },
    1260: {
      id: "1260",
      i: 3,
      s: 20,
      ss: 63,
    },
    1262: {
      id: "1262",
      i: 8,
      s: 9,
      ss: 26,
    },
    1263: {
      id: "1263",
      i: 10,
      s: 26,
      ss: 78,
    },
    1265: {
      id: "1265",
      i: 1,
      s: 1,
      ss: 2,
    },
    1266: {
      id: "1266",
      i: 7,
      s: 8,
      ss: 10,
    },
    1268: {
      id: "1268",
      i: 6,
      s: 22,
      ss: 54,
    },
    1269: {
      id: "1269",
      i: 6,
      s: 16,
      ss: 23,
    },
    1270: {
      id: "1270",
      i: 6,
      s: 7,
      ss: 9,
    },
    1271: {
      id: "1271",
      i: 2,
      s: 18,
      ss: 56,
    },
    1272: {
      id: "1272",
      i: 9,
      s: 10,
      ss: 57,
    },
    1273: {
      id: "1273",
      i: 3,
      s: 20,
      ss: 38,
    },
    1275: {
      id: "1275",
      i: "",
      s: "",
      ss: 85,
    },
    1277: {
      id: "1277",
      i: 11,
      s: 19,
      ss: 27,
    },
    1278: {
      id: "1278",
      i: 2,
      s: 2,
      ss: 3,
    },
    1280: {
      id: "1280",
      i: 6,
      s: 22,
      ss: 89,
    },
    1281: {
      id: "1281",
      i: 1,
      s: 1,
      ss: 39,
    },
    1282: {
      id: "1282",
      i: 3,
      s: 20,
      ss: 29,
    },
    1283: {
      id: "1283",
      i: 2,
      s: 18,
      ss: 56,
    },
    1285: {
      id: "1285",
      i: 8,
      s: 9,
      ss: 26,
    },
    1286: {
      id: "1286",
      i: 9,
      s: 10,
      ss: 19,
    },
    1288: {
      id: "1288",
      i: 3,
      s: 3,
      ss: 4,
    },
    1289: {
      id: "1289",
      i: 9,
      s: 10,
      ss: 19,
    },
    1290: {
      id: "1290",
      i: 3,
      s: 20,
      ss: 38,
    },
    1292: {
      id: "1292",
      i: 9,
      s: 25,
      ss: 90,
    },
    1293: {
      id: "1293",
      i: 6,
      s: 22,
      ss: 54,
    },
    1296: {
      id: "1296",
      i: 9,
      s: 10,
      ss: 57,
    },
    1297: {
      id: "1297",
      i: 10,
      s: 15,
      ss: 21,
    },
    1298: {
      id: "1298",
      i: 5,
      s: 30,
      ss: 92,
    },
    1299: {
      id: "1299",
      i: 3,
      s: 28,
      ss: 67,
    },
    1300: {
      id: "1300",
      i: 10,
      s: 26,
      ss: 48,
    },
    1301: {
      id: "1301",
      i: 9,
      s: 10,
      ss: 16,
    },
    1302: {
      id: "1302",
      i: 5,
      s: 30,
      ss: 92,
    },
    1303: {
      id: "1303",
      i: 11,
      s: 19,
      ss: 27,
    },
    1305: {
      id: "1305",
      i: 9,
      s: 10,
      ss: 19,
    },
    1308: {
      id: "1308",
      i: 9,
      s: 25,
      ss: 53,
    },
    1310: {
      id: "1310",
      i: 4,
      s: 4,
      ss: 6,
    },
    1312: {
      id: "1312",
      i: 2,
      s: 18,
      ss: 25,
    },
    1313: {
      id: "1313",
      i: 2,
      s: 18,
      ss: 25,
    },
    1314: {
      id: "1314",
      i: 6,
      s: 7,
      ss: 22,
    },
    1315: {
      id: "1315",
      i: 2,
      s: 18,
      ss: 56,
    },
    1316: {
      id: "1316",
      i: 6,
      s: 16,
      ss: 23,
    },
    1317: {
      id: "1317",
      i: 6,
      s: 11,
      ss: 14,
    },
    1319: {
      id: "1319",
      i: 3,
      s: 20,
      ss: 38,
    },
    1321: {
      id: "1321",
      i: 2,
      s: 2,
      ss: 3,
    },
    1323: {
      id: "1323",
      i: 0,
      s: 0,
      ss: 0,
    },
    1326: {
      id: "1326",
      i: 6,
      s: 6,
      ss: 77,
    },
    1327: {
      id: "1327",
      i: 6,
      s: 27,
      ss: 70,
    },
    1328: {
      id: "1328",
      i: 6,
      s: 11,
      ss: 34,
    },
    1329: {
      id: "1329",
      i: 2,
      s: 2,
      ss: 3,
    },
    1330: {
      id: "1330",
      i: 9,
      s: 10,
      ss: 57,
    },
    1332: {
      id: "1332",
      i: 9,
      s: 17,
      ss: 24,
    },
    1333: {
      id: "1333",
      i: 7,
      s: 8,
      ss: 42,
    },
    1335: {
      id: "1335",
      i: 1,
      s: 1,
      ss: 1,
    },
    1336: {
      id: "1336",
      i: 3,
      s: 28,
      ss: 67,
    },
    1337: {
      id: "1337",
      i: 10,
      s: 26,
      ss: 78,
    },
    1338: {
      id: "1338",
      i: 8,
      s: 13,
      ss: 84,
    },
    1339: {
      id: "1339",
      i: 3,
      s: 28,
      ss: 67,
    },
    1340: {
      id: "1340",
      i: 8,
      s: 14,
      ss: 74,
    },
    1341: {
      id: "1341",
      i: 9,
      s: 10,
      ss: 16,
    },
    1343: {
      id: "1343",
      i: 2,
      s: 18,
      ss: 69,
    },
    1345: {
      id: "1345",
      i: 5,
      s: 5,
      ss: 7,
    },
    1346: {
      id: "1346",
      i: 6,
      s: 27,
      ss: 50,
    },
    1347: {
      id: "1347",
      i: 10,
      s: 23,
      ss: 37,
    },
    1348: {
      id: "1348",
      i: 6,
      s: 12,
      ss: 15,
    },
    1349: {
      id: "1349",
      i: 5,
      s: 5,
      ss: 94,
    },
    1351: {
      id: "1351",
      i: 6,
      s: 6,
      ss: 44,
    },
    1355: {
      id: "1355",
      i: 6,
      s: 7,
      ss: 9,
    },
    1357: {
      id: "1357",
      i: 10,
      s: 15,
      ss: 52,
    },
    1358: {
      id: "1358",
      i: 5,
      s: 30,
      ss: 92,
    },
    1359: {
      id: "1359",
      i: 3,
      s: 20,
      ss: 31,
    },
    1360: {
      id: "1360",
      i: 6,
      s: 6,
      ss: 44,
    },
    1361: {
      id: "1361",
      i: 6,
      s: 27,
      ss: 65,
    },
    1362: {
      id: "1362",
      i: 10,
      s: 26,
      ss: 48,
    },
    1363: {
      id: "1363",
      i: 1,
      s: 1,
      ss: 71,
    },
    1365: {
      id: "1365",
      i: 6,
      s: 16,
      ss: 62,
    },
    1366: {
      id: "1366",
      i: 9,
      s: 10,
      ss: 19,
    },
    1367: {
      id: "1367",
      i: 6,
      s: 22,
      ss: 51,
    },
    1368: {
      id: "1368",
      i: 6,
      s: 27,
      ss: 65,
    },
    1370: {
      id: "1370",
      i: 7,
      s: 8,
      ss: 10,
    },
    1371: {
      id: "1371",
      i: 6,
      s: 7,
      ss: 11,
    },
    1372: {
      id: "1372",
      i: 2,
      s: 18,
      ss: 69,
    },
    1373: {
      id: "1373",
      i: 6,
      s: 22,
      ss: 89,
    },
    1375: {
      id: "1375",
      i: 3,
      s: 20,
      ss: 29,
    },
    1376: {
      id: "1376",
      i: 2,
      s: 18,
      ss: 56,
    },
    1378: {
      id: "1378",
      i: 7,
      s: 8,
      ss: 42,
    },
    1379: {
      id: "1379",
      i: 2,
      s: 2,
      ss: 5,
    },
    1380: {
      id: "1380",
      i: 7,
      s: 8,
      ss: 60,
    },
    1381: {
      id: "1381",
      i: 1,
      s: 1,
      ss: 39,
    },
    1382: {
      id: "1382",
      i: 6,
      s: 27,
      ss: 55,
    },
    1383: {
      id: "1383",
      i: 6,
      s: 7,
      ss: 32,
    },
    1385: {
      id: "1385",
      i: 10,
      s: 23,
      ss: 37,
    },
    1386: {
      id: "1386",
      i: 6,
      s: 22,
      ss: 36,
    },
    1387: {
      id: "1387",
      i: 2,
      s: 2,
      ss: 5,
    },
    1388: {
      id: "1388",
      i: 6,
      s: 27,
      ss: 50,
    },
    1389: {
      id: "1389",
      i: 8,
      s: 9,
      ss: 61,
    },
    1393: {
      id: "1393",
      i: 11,
      s: 19,
      ss: 27,
    },
    1395: {
      id: "1395",
      i: 1,
      s: 1,
      ss: 71,
    },
    1396: {
      id: "1396",
      i: 2,
      s: 2,
      ss: 3,
    },
    1397: {
      id: "1397",
      i: 6,
      s: 11,
      ss: 34,
    },
    1398: {
      id: "1398",
      i: 3,
      s: 3,
      ss: 4,
    },
    1399: {
      id: "1399",
      i: 9,
      s: 10,
      ss: 58,
    },
    1400: {
      id: "1400",
      i: 6,
      s: 27,
      ss: 55,
    },
    1401: {
      id: "1401",
      i: 9,
      s: 10,
      ss: 43,
    },
    1402: {
      id: "1402",
      i: 10,
      s: 15,
      ss: 21,
    },
    1408: {
      id: "1408",
      i: 2,
      s: 18,
      ss: 56,
    },
    1410: {
      id: "1410",
      i: 10,
      s: 15,
      ss: 21,
    },
    1412: {
      id: "1412",
      i: 9,
      s: 17,
      ss: 24,
    },
    1413: {
      id: "1413",
      i: 2,
      s: 18,
      ss: 69,
    },
    1415: {
      id: "1415",
      i: 9,
      s: 10,
      ss: 43,
    },
    1416: {
      id: "1416",
      i: 2,
      s: 18,
      ss: 56,
    },
    1417: {
      id: "1417",
      i: 2,
      s: 2,
      ss: 47,
    },
    1418: {
      id: "1418",
      i: 6,
      s: 12,
      ss: 83,
    },
    1419: {
      id: "1419",
      i: 5,
      s: 30,
      ss: 73,
    },
    1420: {
      id: "1420",
      i: 2,
      s: 18,
      ss: 56,
    },
    1421: {
      id: "1421",
      i: 2,
      s: 18,
      ss: 69,
    },
    1425: {
      id: "1425",
      i: 6,
      s: 27,
      ss: 50,
    },
    1426: {
      id: "1426",
      i: "",
      s: "",
      ss: 85,
    },
    1427: {
      id: "1427",
      i: 2,
      s: 18,
      ss: 56,
    },
    1428: {
      id: "1428",
      i: 3,
      s: 20,
      ss: 29,
    },
    1429: {
      id: "1429",
      i: 2,
      s: 18,
      ss: 56,
    },
    1430: {
      id: "1430",
      i: 1,
      s: 1,
      ss: 2,
    },
    1431: {
      id: "1431",
      i: 8,
      s: 9,
      ss: 87,
    },
    1432: {
      id: "1432",
      i: 8,
      s: 9,
      ss: 87,
    },
    1433: {
      id: "1433",
      i: 9,
      s: 17,
      ss: 24,
    },
    1439: {
      id: "1439",
      i: 9,
      s: 17,
      ss: 24,
    },
    1440: {
      id: "1440",
      i: 6,
      s: 27,
      ss: 55,
    },
    1442: {
      id: "1442",
      i: 9,
      s: 25,
      ss: 90,
    },
    1443: {
      id: "1443",
      i: 6,
      s: 7,
      ss: 22,
    },
    1446: {
      id: "1446",
      i: 8,
      s: 9,
      ss: 66,
    },
    1447: {
      id: "1447",
      i: 2,
      s: 18,
      ss: 56,
    },
    1448: {
      id: "1448",
      i: 6,
      s: 11,
      ss: 34,
    },
    1449: {
      id: "1449",
      i: 6,
      s: 11,
      ss: 14,
    },
    1450: {
      id: "1450",
      i: 10,
      s: 15,
      ss: 21,
    },
    1451: {
      id: "1451",
      i: 9,
      s: 17,
      ss: 24,
    },
    1452: {
      id: "1452",
      i: 9,
      s: 10,
      ss: 57,
    },
    1455: {
      id: "1455",
      i: 6,
      s: 12,
      ss: 68,
    },
    1456: {
      id: "1456",
      i: 3,
      s: 20,
      ss: 29,
    },
    1458: {
      id: "1458",
      i: 8,
      s: 9,
      ss: 26,
    },
    1459: {
      id: "1459",
      i: 2,
      s: 18,
      ss: 56,
    },
    1460: {
      id: "1460",
      i: 10,
      s: 15,
      ss: 21,
    },
    1461: {
      id: "1461",
      i: 3,
      s: 20,
      ss: 29,
    },
    1463: {
      id: "1463",
      i: 6,
      s: 11,
      ss: 34,
    },
    1466: {
      id: "1466",
      i: 6,
      s: 22,
      ss: 36,
    },
    1468: {
      id: "1468",
      i: 0,
      s: 0,
      ss: 0,
    },
    1469: {
      id: "1469",
      i: 3,
      s: 20,
      ss: 29,
    },
    1470: {
      id: "1470",
      i: 6,
      s: 22,
      ss: 36,
    },
    1472: {
      id: "1472",
      i: 2,
      s: 18,
      ss: 69,
    },
    1473: {
      id: "1473",
      i: 10,
      s: 26,
      ss: 48,
    },
    1475: {
      id: "1475",
      i: 8,
      s: 9,
      ss: 26,
    },
    1476: {
      id: "1476",
      i: 3,
      s: 20,
      ss: 29,
    },
    1477: {
      id: "1477",
      i: 5,
      s: 5,
      ss: 94,
    },
    1478: {
      id: "1478",
      i: 9,
      s: 10,
      ss: 43,
    },
    1480: {
      id: "1480",
      i: 9,
      s: 10,
      ss: 19,
    },
    1481: {
      id: "1481",
      i: 7,
      s: 21,
      ss: 93,
    },
    1483: {
      id: "1483",
      i: 6,
      s: 11,
      ss: 34,
    },
    1486: {
      id: "1486",
      i: 2,
      s: 18,
      ss: 56,
    },
    1488: {
      id: "1488",
      i: 6,
      s: 7,
      ss: 22,
    },
    1490: {
      id: "1490",
      i: 6,
      s: 6,
      ss: 44,
    },
    1492: {
      id: "1492",
      i: 8,
      s: 9,
      ss: 87,
    },
    1495: {
      id: "1495",
      i: 2,
      s: 18,
      ss: 56,
    },
    1496: {
      id: "1496",
      i: 2,
      s: 18,
      ss: 69,
    },
    1498: {
      id: "1498",
      i: 5,
      s: 5,
      ss: 7,
    },
    1499: {
      id: "1499",
      i: 2,
      s: 18,
      ss: 56,
    },
    1500: {
      id: "1500",
      i: 2,
      s: 18,
      ss: 56,
    },
    1501: {
      id: "1501",
      i: 5,
      s: 30,
      ss: 92,
    },
    1502: {
      id: "1502",
      i: 2,
      s: 2,
      ss: 47,
    },
    1503: {
      id: "1503",
      i: "",
      s: "",
      ss: 85,
    },
    1508: {
      id: "1508",
      i: 3,
      s: 28,
      ss: 67,
    },
    1509: {
      id: "1509",
      i: 5,
      s: 30,
      ss: 73,
    },
    1513: {
      id: "1513",
      i: 5,
      s: 5,
      ss: 7,
    },
    1515: {
      id: "1515",
      i: 5,
      s: 30,
      ss: 73,
    },
    1516: {
      id: "1516",
      i: 2,
      s: 2,
      ss: 47,
    },
    1518: {
      id: "1518",
      i: 5,
      s: 30,
      ss: 73,
    },
    1520: {
      id: "1520",
      i: 6,
      s: 27,
      ss: 50,
    },
    1521: {
      id: "1521",
      i: 5,
      s: 5,
      ss: 94,
    },
    1522: {
      id: "1522",
      i: 10,
      s: 15,
      ss: 21,
    },
    1523: {
      id: "1523",
      i: 10,
      s: 26,
      ss: 78,
    },
    1525: {
      id: "1525",
      i: 6,
      s: 11,
      ss: 14,
    },
    1526: {
      id: "1526",
      i: 5,
      s: 30,
      ss: 73,
    },
    1527: {
      id: "1527",
      i: 9,
      s: 10,
      ss: 57,
    },
    1528: {
      id: "1528",
      i: 2,
      s: 2,
      ss: 5,
    },
    1529: {
      id: "1529",
      i: 9,
      s: 25,
      ss: 90,
    },
    1530: {
      id: "1530",
      i: 5,
      s: 5,
      ss: 7,
    },
    1532: {
      id: "1532",
      i: 6,
      s: 27,
      ss: 50,
    },
    1533: {
      id: "1533",
      i: 8,
      s: 9,
      ss: 87,
    },
    1536: {
      id: "1536",
      i: 9,
      s: 10,
      ss: 19,
    },
    1538: {
      id: "1538",
      i: 2,
      s: 2,
      ss: 47,
    },
    1539: {
      id: "1539",
      i: 9,
      s: 10,
      ss: 57,
    },
    1540: {
      id: "1540",
      i: 9,
      s: 17,
      ss: 24,
    },
    1542: {
      id: "1542",
      i: 1,
      s: 1,
      ss: 71,
    },
    1543: {
      id: "1543",
      i: 3,
      s: 20,
      ss: 38,
    },
    1545: {
      id: "1545",
      i: 6,
      s: 22,
      ss: 89,
    },
    1546: {
      id: "1546",
      i: 2,
      s: 18,
      ss: 56,
    },
    1547: {
      id: "1547",
      i: 2,
      s: 18,
      ss: 56,
    },
    1548: {
      id: "1548",
      i: 5,
      s: 5,
      ss: 94,
    },
    1549: {
      id: "1549",
      i: 9,
      s: 25,
      ss: 53,
    },
    1551: {
      id: "1551",
      i: 3,
      s: 3,
      ss: 4,
    },
    1552: {
      id: "1552",
      i: 2,
      s: 18,
      ss: 56,
    },
    1553: {
      id: "1553",
      i: 7,
      s: 8,
      ss: 10,
    },
    1555: {
      id: "1555",
      i: 11,
      s: 24,
      ss: 40,
    },
    1556: {
      id: "1556",
      i: 2,
      s: 18,
      ss: 56,
    },
    1557: {
      id: "1557",
      i: 2,
      s: 18,
      ss: 69,
    },
    1558: {
      id: "1558",
      i: 5,
      s: 5,
      ss: 7,
    },
    1559: {
      id: "1559",
      i: 2,
      s: 18,
      ss: 69,
    },
    1560: {
      id: "1560",
      i: 2,
      s: 2,
      ss: 3,
    },
    1561: {
      id: "1561",
      i: 7,
      s: 21,
      ss: 30,
    },
    1563: {
      id: "1563",
      i: 3,
      s: 20,
      ss: 38,
    },
    1565: {
      id: "1565",
      i: 6,
      s: 11,
      ss: 14,
    },
    1566: {
      id: "1566",
      i: 6,
      s: 7,
      ss: 86,
    },
    1568: {
      id: "1568",
      i: 2,
      s: 18,
      ss: 56,
    },
    1569: {
      id: "1569",
      i: 6,
      s: 11,
      ss: 14,
    },
    1570: {
      id: "1570",
      i: 2,
      s: 2,
      ss: 3,
    },
    1571: {
      id: "1571",
      i: 6,
      s: 16,
      ss: 23,
    },
    1572: {
      id: "1572",
      i: 3,
      s: 20,
      ss: 38,
    },
    1573: {
      id: "1573",
      i: 11,
      s: 19,
      ss: 27,
    },
    1575: {
      id: "1575",
      i: 6,
      s: 12,
      ss: 83,
    },
    1576: {
      id: "1576",
      i: 9,
      s: 25,
      ss: 46,
    },
    1577: {
      id: "1577",
      i: 3,
      s: 20,
      ss: 38,
    },
    1578: {
      id: "1578",
      i: 3,
      s: 3,
      ss: 4,
    },
    1579: {
      id: "1579",
      i: 8,
      s: 9,
      ss: 12,
    },
    1580: {
      id: "1580",
      i: 7,
      s: 21,
      ss: 41,
    },
    1581: {
      id: "1581",
      i: 2,
      s: 18,
      ss: 56,
    },
    1582: {
      id: "1582",
      i: 2,
      s: 18,
      ss: 56,
    },
    1583: {
      id: "1583",
      i: 8,
      s: 9,
      ss: 26,
    },
    1585: {
      id: "1585",
      i: 6,
      s: 16,
      ss: 62,
    },
    1586: {
      id: "1586",
      i: 6,
      s: 11,
      ss: 34,
    },
    1587: {
      id: "1587",
      i: 8,
      s: 9,
      ss: 12,
    },
    1588: {
      id: "1588",
      i: 10,
      s: 15,
      ss: 35,
    },
    1589: {
      id: "1589",
      i: 2,
      s: 2,
      ss: 5,
    },
    1591: {
      id: "1591",
      i: 2,
      s: 18,
      ss: 56,
    },
    1592: {
      id: "1592",
      i: 2,
      s: 18,
      ss: 25,
    },
    1593: {
      id: "1593",
      i: 6,
      s: 11,
      ss: 14,
    },
    1596: {
      id: "1596",
      i: 9,
      s: 10,
      ss: 45,
    },
    1597: {
      id: "1597",
      i: 9,
      s: 10,
      ss: 57,
    },
    1598: {
      id: "1598",
      i: 6,
      s: 11,
      ss: 14,
    },
    1599: {
      id: "1599",
      i: 2,
      s: 18,
      ss: 56,
    },
    1600: {
      id: "1600",
      i: 1,
      s: 1,
      ss: 2,
    },
    1601: {
      id: "1601",
      i: 3,
      s: 20,
      ss: 38,
    },
    1606: {
      id: "1606",
      i: 3,
      s: 20,
      ss: 38,
    },
    1608: {
      id: "1608",
      i: 1,
      s: 1,
      ss: 1,
    },
    1609: {
      id: "1609",
      i: 2,
      s: 18,
      ss: 56,
    },
    1610: {
      id: "1610",
      i: 8,
      s: 14,
      ss: 74,
    },
    1611: {
      id: "1611",
      i: 9,
      s: 10,
      ss: 19,
    },
    1612: {
      id: "1612",
      i: 5,
      s: 30,
      ss: 92,
    },
    1613: {
      id: "1613",
      i: 10,
      s: 15,
      ss: 21,
    },
    1615: {
      id: "1615",
      i: 2,
      s: 18,
      ss: 56,
    },
    1616: {
      id: "1616",
      i: 6,
      s: 6,
      ss: 77,
    },
    1617: {
      id: "1617",
      i: 10,
      s: 26,
      ss: 48,
    },
    1618: {
      id: "1618",
      i: 2,
      s: 18,
      ss: 69,
    },
    1620: {
      id: "1620",
      i: 6,
      s: 7,
      ss: 32,
    },
    1621: {
      id: "1621",
      i: 11,
      s: 24,
      ss: 40,
    },
    1622: {
      id: "1622",
      i: 2,
      s: 2,
      ss: 3,
    },
    1623: {
      id: "1623",
      i: 11,
      s: 24,
      ss: 64,
    },
    1626: {
      id: "1626",
      i: 9,
      s: 17,
      ss: 24,
    },
    1627: {
      id: "1627",
      i: 2,
      s: 18,
      ss: 56,
    },
    1628: {
      id: "1628",
      i: 2,
      s: 2,
      ss: 3,
    },
    1629: {
      id: "1629",
      i: 9,
      s: 17,
      ss: 24,
    },
    1630: {
      id: "1630",
      i: 2,
      s: 18,
      ss: 56,
    },
    1631: {
      id: "1631",
      i: 9,
      s: 17,
      ss: 24,
    },
    1632: {
      id: "1632",
      i: 6,
      s: 7,
      ss: 22,
    },
    1633: {
      id: "1633",
      i: 2,
      s: 18,
      ss: 56,
    },
    1635: {
      id: "1635",
      i: 1,
      s: 1,
      ss: 2,
    },
    1636: {
      id: "1636",
      i: 7,
      s: 8,
      ss: 81,
    },
    1637: {
      id: "1637",
      i: 2,
      s: 18,
      ss: 56,
    },
    1638: {
      id: "1638",
      i: 2,
      s: 2,
      ss: 3,
    },
    1639: {
      id: "1639",
      i: 9,
      s: 10,
      ss: 19,
    },
    1640: {
      id: "1640",
      i: 6,
      s: 6,
      ss: 44,
    },
    1642: {
      id: "1642",
      i: 2,
      s: 18,
      ss: 56,
    },
    1643: {
      id: "1643",
      i: 5,
      s: 5,
      ss: 79,
    },
    1645: {
      id: "1645",
      i: 9,
      s: 10,
      ss: 19,
    },
    1647: {
      id: "1647",
      i: 2,
      s: 18,
      ss: 56,
    },
    1649: {
      id: "1649",
      i: 2,
      s: 18,
      ss: 69,
    },
    1650: {
      id: "1650",
      i: 6,
      s: 11,
      ss: 34,
    },
    1651: {
      id: "1651",
      i: 9,
      s: 10,
      ss: 19,
    },
    1652: {
      id: "1652",
      i: 5,
      s: 5,
      ss: 7,
    },
    1653: {
      id: "1653",
      i: 2,
      s: 18,
      ss: 25,
    },
    1655: {
      id: "1655",
      i: 6,
      s: 7,
      ss: 11,
    },
    1656: {
      id: "1656",
      i: 9,
      s: 10,
      ss: 19,
    },
    1657: {
      id: "1657",
      i: 6,
      s: 27,
      ss: 50,
    },
    1658: {
      id: "1658",
      i: 3,
      s: 3,
      ss: 4,
    },
    1660: {
      id: "1660",
      i: 9,
      s: 10,
      ss: 16,
    },
    1661: {
      id: "1661",
      i: 6,
      s: 7,
      ss: 86,
    },
    1662: {
      id: "1662",
      i: 2,
      s: 18,
      ss: 69,
    },
    1663: {
      id: "1663",
      i: 2,
      s: 2,
      ss: 3,
    },
    1665: {
      id: "1665",
      i: 10,
      s: 15,
      ss: 21,
    },
    1666: {
      id: "1666",
      i: 5,
      s: 5,
      ss: 79,
    },
    1667: {
      id: "1667",
      i: 2,
      s: 18,
      ss: 56,
    },
    1668: {
      id: "1668",
      i: 2,
      s: 2,
      ss: 3,
    },
    1669: {
      id: "1669",
      i: 3,
      s: 20,
      ss: 38,
    },
    1671: {
      id: "1671",
      i: 1,
      s: 1,
      ss: 1,
    },
    1672: {
      id: "1672",
      i: 5,
      s: 5,
      ss: 94,
    },
    1673: {
      id: "1673",
      i: 9,
      s: 10,
      ss: 19,
    },
    1675: {
      id: "1675",
      i: 10,
      s: 15,
      ss: 35,
    },
    1676: {
      id: "1676",
      i: 8,
      s: 9,
      ss: 26,
    },
    1678: {
      id: "1678",
      i: 6,
      s: 12,
      ss: 83,
    },
    1679: {
      id: "1679",
      i: 10,
      s: 15,
      ss: 35,
    },
    1680: {
      id: "1680",
      i: 6,
      s: 7,
      ss: 11,
    },
    1681: {
      id: "1681",
      i: 5,
      s: 5,
      ss: 7,
    },
    1682: {
      id: "1682",
      i: 6,
      s: 27,
      ss: 50,
    },
    1683: {
      id: "1683",
      i: 2,
      s: 18,
      ss: 56,
    },
    1685: {
      id: "1685",
      i: 9,
      s: 10,
      ss: 57,
    },
    1686: {
      id: "1686",
      i: 10,
      s: 15,
      ss: 21,
    },
    1689: {
      id: "1689",
      i: 9,
      s: 17,
      ss: 24,
    },
    1690: {
      id: "1690",
      i: 2,
      s: 18,
      ss: 56,
    },
    1691: {
      id: "1691",
      i: 6,
      s: 12,
      ss: 68,
    },
    1692: {
      id: "1692",
      i: 6,
      s: 12,
      ss: 68,
    },
    1693: {
      id: "1693",
      i: 2,
      s: 18,
      ss: 56,
    },
    1695: {
      id: "1695",
      i: 8,
      s: 9,
      ss: 12,
    },
    1696: {
      id: "1696",
      i: 5,
      s: 30,
      ss: 92,
    },
    1697: {
      id: "1697",
      i: 3,
      s: 20,
      ss: 31,
    },
    1699: {
      id: "1699",
      i: 8,
      s: 14,
      ss: 74,
    },
    1701: {
      id: "1701",
      i: 6,
      s: 7,
      ss: 32,
    },
    1702: {
      id: "1702",
      i: 7,
      s: 21,
      ss: 76,
    },
    1703: {
      id: "1703",
      i: 6,
      s: 7,
      ss: 22,
    },
    1705: {
      id: "1705",
      i: 8,
      s: 9,
      ss: 26,
    },
    1706: {
      id: "1706",
      i: 2,
      s: 18,
      ss: 69,
    },
    1707: {
      id: "1707",
      i: 2,
      s: 18,
      ss: 69,
    },
    1708: {
      id: "1708",
      i: 10,
      s: 15,
      ss: 21,
    },
    1709: {
      id: "1709",
      i: 3,
      s: 20,
      ss: 29,
    },
    1710: {
      id: "1710",
      i: 9,
      s: 10,
      ss: 19,
    },
    1711: {
      id: "1711",
      i: 6,
      s: 22,
      ss: 89,
    },
    1712: {
      id: "1712",
      i: 7,
      s: 29,
      ss: 72,
    },
    1713: {
      id: "1713",
      i: 1,
      s: 1,
      ss: 1,
    },
    1715: {
      id: "1715",
      i: 6,
      s: 12,
      ss: 83,
    },
    1716: {
      id: "1716",
      i: 6,
      s: 6,
      ss: 44,
    },
    1717: {
      id: "1717",
      i: 8,
      s: 9,
      ss: 87,
    },
    1718: {
      id: "1718",
      i: 2,
      s: 18,
      ss: 69,
    },
    1719: {
      id: "1719",
      i: 9,
      s: 17,
      ss: 82,
    },
    1720: {
      id: "1720",
      i: 10,
      s: 26,
      ss: 48,
    },
    1721: {
      id: "1721",
      i: 7,
      s: 8,
      ss: 10,
    },
    1722: {
      id: "1722",
      i: 2,
      s: 18,
      ss: 56,
    },
    1723: {
      id: "1723",
      i: 6,
      s: 22,
      ss: 59,
    },
    1725: {
      id: "1725",
      i: 9,
      s: 10,
      ss: 19,
    },
    1726: {
      id: "1726",
      i: 5,
      s: 30,
      ss: 92,
    },
    1727: {
      id: "1727",
      i: 2,
      s: 18,
      ss: 56,
    },
    1728: {
      id: "1728",
      i: 6,
      s: 22,
      ss: 54,
    },
    1729: {
      id: "1729",
      i: 10,
      s: 26,
      ss: 48,
    },
    1730: {
      id: "1730",
      i: 2,
      s: 2,
      ss: 5,
    },
    1731: {
      id: "1731",
      i: 6,
      s: 27,
      ss: 49,
    },
    1732: {
      id: "1732",
      i: 9,
      s: 25,
      ss: 53,
    },
    1733: {
      id: "1733",
      i: 11,
      s: 19,
      ss: 27,
    },
    1735: {
      id: "1735",
      i: 2,
      s: 18,
      ss: 56,
    },
    1736: {
      id: "1736",
      i: 10,
      s: 15,
      ss: 52,
    },
    1737: {
      id: "1737",
      i: 9,
      s: 25,
      ss: 90,
    },
    1738: {
      id: "1738",
      i: 11,
      s: 19,
      ss: 27,
    },
    1739: {
      id: "1739",
      i: 10,
      s: 15,
      ss: 52,
    },
    1740: {
      id: "1740",
      i: 6,
      s: 6,
      ss: 77,
    },
    1741: {
      id: "1741",
      i: 2,
      s: 18,
      ss: 56,
    },
    1742: {
      id: "1742",
      i: 2,
      s: 18,
      ss: 56,
    },
    1743: {
      id: "1743",
      i: 9,
      s: 10,
      ss: 19,
    },
    1745: {
      id: "1745",
      i: 10,
      s: 15,
      ss: 35,
    },
    1746: {
      id: "1746",
      i: 2,
      s: 18,
      ss: 56,
    },
    1747: {
      id: "1747",
      i: 6,
      s: 12,
      ss: 18,
    },
    1748: {
      id: "1748",
      i: 9,
      s: 25,
      ss: 53,
    },
    1749: {
      id: "1749",
      i: 6,
      s: 27,
      ss: 50,
    },
    1750: {
      id: "1750",
      i: 9,
      s: 10,
      ss: 19,
    },
    1751: {
      id: "1751",
      i: 2,
      s: 18,
      ss: 56,
    },
    1752: {
      id: "1752",
      i: 6,
      s: 11,
      ss: 14,
    },
    1753: {
      id: "1753",
      i: 6,
      s: 6,
      ss: 44,
    },
    1755: {
      id: "1755",
      i: 2,
      s: 2,
      ss: 47,
    },
    1756: {
      id: "1756",
      i: 6,
      s: 11,
      ss: 14,
    },
    1757: {
      id: "1757",
      i: 2,
      s: 18,
      ss: 69,
    },
    1758: {
      id: "1758",
      i: 6,
      s: 11,
      ss: 14,
    },
    1759: {
      id: "1759",
      i: 1,
      s: 1,
      ss: 2,
    },
    1760: {
      id: "1760",
      i: 6,
      s: 16,
      ss: 23,
    },
    1761: {
      id: "1761",
      i: 6,
      s: 6,
      ss: 44,
    },
    1762: {
      id: "1762",
      i: 6,
      s: 6,
      ss: 44,
    },
    1763: {
      id: "1763",
      i: 5,
      s: 5,
      ss: 7,
    },
    1765: {
      id: "1765",
      i: 6,
      s: 11,
      ss: 14,
    },
    1766: {
      id: "1766",
      i: 9,
      s: 10,
      ss: 45,
    },
    1767: {
      id: "1767",
      i: 8,
      s: 9,
      ss: 26,
    },
    1769: {
      id: "1769",
      i: 6,
      s: 11,
      ss: 14,
    },
    1771: {
      id: "1771",
      i: 6,
      s: 22,
      ss: 54,
    },
    1772: {
      id: "1772",
      i: 7,
      s: 8,
      ss: 60,
    },
    1773: {
      id: "1773",
      i: 6,
      s: 11,
      ss: 14,
    },
    1775: {
      id: "1775",
      i: 6,
      s: 11,
      ss: 14,
    },
    1776: {
      id: "1776",
      i: 3,
      s: 20,
      ss: 29,
    },
    1777: {
      id: "1777",
      i: 2,
      s: 2,
      ss: 3,
    },
    1778: {
      id: "1778",
      i: 2,
      s: 2,
      ss: 47,
    },
    1780: {
      id: "1780",
      i: 2,
      s: 18,
      ss: 56,
    },
    1781: {
      id: "1781",
      i: 6,
      s: 12,
      ss: 83,
    },
    1782: {
      id: "1782",
      i: 10,
      s: 15,
      ss: 21,
    },
    1783: {
      id: "1783",
      i: 2,
      s: 18,
      ss: 56,
    },
    1785: {
      id: "1785",
      i: 9,
      s: 25,
      ss: 46,
    },
    1786: {
      id: "1786",
      i: 9,
      s: 10,
      ss: 16,
    },
    1787: {
      id: "1787",
      i: 7,
      s: 29,
      ss: 72,
    },
    1788: {
      id: "1788",
      i: 3,
      s: 20,
      ss: 29,
    },
    1789: {
      id: "1789",
      i: 5,
      s: 30,
      ss: 92,
    },
    1790: {
      id: "1790",
      i: 1,
      s: 1,
      ss: 71,
    },
    1792: {
      id: "1792",
      i: 6,
      s: 12,
      ss: 15,
    },
    1793: {
      id: "1793",
      i: 2,
      s: 18,
      ss: 56,
    },
    1795: {
      id: "1795",
      i: 6,
      s: 27,
      ss: 55,
    },
    1796: {
      id: "1796",
      i: 2,
      s: 18,
      ss: 56,
    },
    1797: {
      id: "1797",
      i: 6,
      s: 11,
      ss: 14,
    },
    1798: {
      id: "1798",
      i: 1,
      s: 1,
      ss: 39,
    },
    1799: {
      id: "1799",
      i: 1,
      s: 1,
      ss: 39,
    },
    1800: {
      id: "1800",
      i: 2,
      s: 18,
      ss: 69,
    },
    1801: {
      id: "1801",
      i: 5,
      s: 5,
      ss: 7,
    },
    1802: {
      id: "1802",
      i: 2,
      s: 18,
      ss: 56,
    },
    1803: {
      id: "1803",
      i: 6,
      s: 7,
      ss: 86,
    },
    1806: {
      id: "1806",
      i: 10,
      s: 15,
      ss: 52,
    },
    1808: {
      id: "1808",
      i: 10,
      s: 15,
      ss: 21,
    },
    1809: {
      id: "1809",
      i: 6,
      s: 16,
      ss: 23,
    },
    1810: {
      id: "1810",
      i: 10,
      s: 26,
      ss: 48,
    },
    1811: {
      id: "1811",
      i: 1,
      s: 1,
      ss: 39,
    },
    1812: {
      id: "1812",
      i: 7,
      s: 21,
      ss: 93,
    },
    1813: {
      id: "1813",
      i: 2,
      s: 2,
      ss: 3,
    },
    1815: {
      id: "1815",
      i: 6,
      s: 22,
      ss: 36,
    },
    1816: {
      id: "1816",
      i: 1,
      s: 1,
      ss: 1,
    },
    1817: {
      id: "1817",
      i: 6,
      s: 22,
      ss: 51,
    },
    1818: {
      id: "1818",
      i: 7,
      s: 29,
      ss: 72,
    },
    1820: {
      id: "1820",
      i: 9,
      s: 17,
      ss: 24,
    },
    1821: {
      id: "1821",
      i: 3,
      s: 20,
      ss: 31,
    },
    1822: {
      id: "1822",
      i: 7,
      s: 21,
      ss: 41,
    },
    1823: {
      id: "1823",
      i: 9,
      s: 25,
      ss: 46,
    },
    1825: {
      id: "1825",
      i: 6,
      s: 27,
      ss: 50,
    },
    1826: {
      id: "1826",
      i: 2,
      s: 18,
      ss: 56,
    },
    1827: {
      id: "1827",
      i: 5,
      s: 30,
      ss: 73,
    },
    1829: {
      id: "1829",
      i: 2,
      s: 18,
      ss: 69,
    },
    1830: {
      id: "1830",
      i: 5,
      s: 30,
      ss: 73,
    },
    1831: {
      id: "1831",
      i: 6,
      s: 6,
      ss: 44,
    },
    1832: {
      id: "1832",
      i: 6,
      s: 7,
      ss: 9,
    },
    1833: {
      id: "1833",
      i: 5,
      s: 30,
      ss: 73,
    },
    1835: {
      id: "1835",
      i: 3,
      s: 20,
      ss: 31,
    },
    1836: {
      id: "1836",
      i: 6,
      s: 27,
      ss: 65,
    },
    1837: {
      id: "1837",
      i: 8,
      s: 9,
      ss: 26,
    },
    1838: {
      id: "1838",
      i: 2,
      s: 2,
      ss: 3,
    },
    1839: {
      id: "1839",
      i: 9,
      s: 10,
      ss: 45,
    },
    1841: {
      id: "1841",
      i: 9,
      s: 17,
      ss: 24,
    },
    1842: {
      id: "1842",
      i: 6,
      s: 27,
      ss: 49,
    },
    1843: {
      id: "1843",
      i: 6,
      s: 7,
      ss: 22,
    },
    1845: {
      id: "1845",
      i: 1,
      s: 1,
      ss: 39,
    },
    1846: {
      id: "1846",
      i: 5,
      s: 30,
      ss: 73,
    },
    1847: {
      id: "1847",
      i: 2,
      s: 18,
      ss: 25,
    },
    1848: {
      id: "1848",
      i: 6,
      s: 7,
      ss: 75,
    },
    1849: {
      id: "1849",
      i: 6,
      s: 6,
      ss: 44,
    },
    1850: {
      id: "1850",
      i: 2,
      s: 18,
      ss: 56,
    },
    1851: {
      id: "1851",
      i: 6,
      s: 11,
      ss: 14,
    },
    1853: {
      id: "1853",
      i: 1,
      s: 1,
      ss: 39,
    },
    1854: {
      id: "1854",
      i: 8,
      s: 14,
      ss: 33,
    },
    1855: {
      id: "1855",
      i: 2,
      s: 18,
      ss: 56,
    },
    1856: {
      id: "1856",
      i: 6,
      s: 27,
      ss: 70,
    },
    1857: {
      id: "1857",
      i: 1,
      s: 1,
      ss: 71,
    },
    1858: {
      id: "1858",
      i: 5,
      s: 30,
      ss: 92,
    },
    1859: {
      id: "1859",
      i: 6,
      s: 6,
      ss: 91,
    },
    1860: {
      id: "1860",
      i: 6,
      s: 6,
      ss: 44,
    },
    1861: {
      id: "1861",
      i: 7,
      s: 21,
      ss: 30,
    },
    1862: {
      id: "1862",
      i: 2,
      s: 2,
      ss: 3,
    },
    1863: {
      id: "1863",
      i: 7,
      s: 21,
      ss: 30,
    },
    1865: {
      id: "1865",
      i: 2,
      s: 18,
      ss: 69,
    },
    1866: {
      id: "1866",
      i: 7,
      s: 21,
      ss: 76,
    },
    1867: {
      id: "1867",
      i: 2,
      s: 18,
      ss: 56,
    },
    1868: {
      id: "1868",
      i: 6,
      s: 12,
      ss: 83,
    },
    1869: {
      id: "1869",
      i: 6,
      s: 7,
      ss: 22,
    },
    1870: {
      id: "1870",
      i: 2,
      s: 18,
      ss: 56,
    },
    1871: {
      id: "1871",
      i: 6,
      s: 11,
      ss: 14,
    },
    1872: {
      id: "1872",
      i: 6,
      s: 22,
      ss: 54,
    },
    1873: {
      id: "1873",
      i: 5,
      s: 5,
      ss: 94,
    },
    1875: {
      id: "1875",
      i: 5,
      s: 5,
      ss: 94,
    },
    1876: {
      id: "1876",
      i: 8,
      s: 9,
      ss: 61,
    },
    1877: {
      id: "1877",
      i: 5,
      s: 5,
      ss: 94,
    },
    1878: {
      id: "1878",
      i: 11,
      s: 19,
      ss: 27,
    },
    1881: {
      id: "1881",
      i: "",
      s: "",
      ss: 85,
    },
    1882: {
      id: "1882",
      i: 9,
      s: 10,
      ss: 19,
    },
    1883: {
      id: "1883",
      i: 4,
      s: 4,
      ss: 6,
    },
    1884: {
      id: "1884",
      i: 9,
      s: 17,
      ss: 24,
    },
    1885: {
      id: "1885",
      i: 7,
      s: 21,
      ss: 41,
    },
    1888: {
      id: "1888",
      i: 9,
      s: 10,
      ss: 19,
    },
    1889: {
      id: "1889",
      i: 5,
      s: 5,
      ss: 7,
    },
    1890: {
      id: "1890",
      i: 6,
      s: 11,
      ss: 14,
    },
    1891: {
      id: "1891",
      i: 7,
      s: 8,
      ss: 60,
    },
    1894: {
      id: "1894",
      i: 2,
      s: 18,
      ss: 25,
    },
    1895: {
      id: "1895",
      i: 2,
      s: 2,
      ss: 47,
    },
    1896: {
      id: "1896",
      i: 6,
      s: 6,
      ss: 77,
    },
    1897: {
      id: "1897",
      i: 2,
      s: 18,
      ss: 56,
    },
    1898: {
      id: "1898",
      i: 11,
      s: 19,
      ss: 27,
    },
    1899: {
      id: "1899",
      i: 9,
      s: 10,
      ss: 45,
    },
    1900: {
      id: "1900",
      i: 10,
      s: 15,
      ss: 21,
    },
    1901: {
      id: "1901",
      i: 6,
      s: 7,
      ss: 32,
    },
    1902: {
      id: "1902",
      i: 2,
      s: 2,
      ss: 3,
    },
    1903: {
      id: "1903",
      i: 9,
      s: 25,
      ss: 53,
    },
    1905: {
      id: "1905",
      i: 3,
      s: 20,
      ss: 38,
    },
    1906: {
      id: "1906",
      i: 6,
      s: 27,
      ss: 50,
    },
    1907: {
      id: "1907",
      i: 7,
      s: 21,
      ss: 30,
    },
    1908: {
      id: "1908",
      i: 2,
      s: 2,
      ss: 3,
    },
    1909: {
      id: "1909",
      i: 10,
      s: 15,
      ss: 35,
    },
    1910: {
      id: "1910",
      i: 6,
      s: 27,
      ss: 49,
    },
    1911: {
      id: "1911",
      i: 3,
      s: 20,
      ss: 31,
    },
    1912: {
      id: "1912",
      i: 10,
      s: 23,
      ss: 37,
    },
    1913: {
      id: "1913",
      i: 6,
      s: 27,
      ss: 49,
    },
    1915: {
      id: "1915",
      i: 3,
      s: 20,
      ss: 38,
    },
    1916: {
      id: "1916",
      i: 3,
      s: 3,
      ss: 4,
    },
    1917: {
      id: "1917",
      i: 6,
      s: 6,
      ss: 44,
    },
    1918: {
      id: "1918",
      i: 2,
      s: 2,
      ss: 3,
    },
    1919: {
      id: "1919",
      i: 9,
      s: 25,
      ss: 53,
    },
    1920: {
      id: "1920",
      i: 2,
      s: 18,
      ss: 56,
    },
    1921: {
      id: "1921",
      i: 11,
      s: 24,
      ss: 64,
    },
    1922: {
      id: "1922",
      i: 2,
      s: 2,
      ss: 47,
    },
    1925: {
      id: "1925",
      i: 6,
      s: 12,
      ss: 83,
    },
    1927: {
      id: "1927",
      i: 8,
      s: 9,
      ss: 26,
    },
    1928: {
      id: "1928",
      i: 6,
      s: 7,
      ss: 11,
    },
    1929: {
      id: "1929",
      i: 6,
      s: 22,
      ss: 36,
    },
    1930: {
      id: "1930",
      i: 9,
      s: 10,
      ss: 19,
    },
    1931: {
      id: "1931",
      i: 5,
      s: 30,
      ss: 92,
    },
    1932: {
      id: "1932",
      i: 7,
      s: 21,
      ss: 30,
    },
    1933: {
      id: "1933",
      i: 10,
      s: 15,
      ss: 35,
    },
    1935: {
      id: "1935",
      i: 6,
      s: 11,
      ss: 14,
    },
    1936: {
      id: "1936",
      i: 8,
      s: 14,
      ss: 20,
    },
    1937: {
      id: "1937",
      i: 2,
      s: 18,
      ss: 25,
    },
    1938: {
      id: "1938",
      i: 11,
      s: 24,
      ss: 64,
    },
    1939: {
      id: "1939",
      i: 6,
      s: 11,
      ss: 34,
    },
    1940: {
      id: "1940",
      i: 11,
      s: 24,
      ss: 40,
    },
    1941: {
      id: "1941",
      i: 2,
      s: 2,
      ss: 47,
    },
    1942: {
      id: "1942",
      i: 6,
      s: 22,
      ss: 36,
    },
    1943: {
      id: "1943",
      i: 2,
      s: 18,
      ss: 56,
    },
    1945: {
      id: "1945",
      i: 3,
      s: 20,
      ss: 63,
    },
    1948: {
      id: "1948",
      i: 6,
      s: 6,
      ss: 44,
    },
    1949: {
      id: "1949",
      i: 10,
      s: 15,
      ss: 21,
    },
    1950: {
      id: "1950",
      i: 7,
      s: 21,
      ss: 30,
    },
    1951: {
      id: "1951",
      i: 5,
      s: 5,
      ss: 94,
    },
    1952: {
      id: "1952",
      i: 5,
      s: 5,
      ss: 94,
    },
    1953: {
      id: "1953",
      i: 2,
      s: 18,
      ss: 56,
    },
    1955: {
      id: "1955",
      i: 6,
      s: 11,
      ss: 34,
    },
    1957: {
      id: "1957",
      i: 6,
      s: 27,
      ss: 50,
    },
    1958: {
      id: "1958",
      i: 6,
      s: 16,
      ss: 62,
    },
    1959: {
      id: "1959",
      i: 6,
      s: 22,
      ss: 54,
    },
    1960: {
      id: "1960",
      i: 2,
      s: 18,
      ss: 69,
    },
    1961: {
      id: "1961",
      i: 10,
      s: 15,
      ss: 35,
    },
    1962: {
      id: "1962",
      i: 6,
      s: 27,
      ss: 49,
    },
    1963: {
      id: "1963",
      i: 3,
      s: 3,
      ss: 4,
    },
    1965: {
      id: "1965",
      i: 2,
      s: 2,
      ss: 47,
    },
    1966: {
      id: "1966",
      i: 2,
      s: 2,
      ss: 3,
    },
    1967: {
      id: "1967",
      i: 9,
      s: 10,
      ss: 19,
    },
    1968: {
      id: "1968",
      i: 6,
      s: 27,
      ss: 55,
    },
    1969: {
      id: "1969",
      i: 6,
      s: 11,
      ss: 14,
    },
    1970: {
      id: "1970",
      i: 6,
      s: 6,
      ss: 77,
    },
    1971: {
      id: "1971",
      i: 2,
      s: 2,
      ss: 47,
    },
    1972: {
      id: "1972",
      i: 2,
      s: 2,
      ss: 5,
    },
    1975: {
      id: "1975",
      i: 9,
      s: 17,
      ss: 24,
    },
    1977: {
      id: "1977",
      i: 2,
      s: 18,
      ss: 56,
    },
    1978: {
      id: "1978",
      i: 6,
      s: 7,
      ss: 22,
    },
    1979: {
      id: "1979",
      i: 9,
      s: 10,
      ss: 43,
    },
    1980: {
      id: "1980",
      i: 10,
      s: 15,
      ss: 35,
    },
    1981: {
      id: "1981",
      i: 6,
      s: 6,
      ss: 77,
    },
    1982: {
      id: "1982",
      i: 6,
      s: 27,
      ss: 50,
    },
    1983: {
      id: "1983",
      i: 3,
      s: 3,
      ss: 4,
    },
    1985: {
      id: "1985",
      i: 10,
      s: 15,
      ss: 21,
    },
    1986: {
      id: "1986",
      i: 7,
      s: 21,
      ss: 30,
    },
    1987: {
      id: "1987",
      i: 2,
      s: 18,
      ss: 69,
    },
    1988: {
      id: "1988",
      i: 3,
      s: 3,
      ss: 4,
    },
    1989: {
      id: "1989",
      i: 6,
      s: 11,
      ss: 34,
    },
    1991: {
      id: "1991",
      i: 9,
      s: 10,
      ss: 19,
    },
    1992: {
      id: "1992",
      i: 6,
      s: 7,
      ss: 9,
    },
    1993: {
      id: "1993",
      i: 6,
      s: 6,
      ss: 44,
    },
    1995: {
      id: "1995",
      i: 2,
      s: 2,
      ss: 47,
    },
    1996: {
      id: "1996",
      i: 2,
      s: 2,
      ss: 3,
    },
    1997: {
      id: "1997",
      i: 2,
      s: 2,
      ss: 5,
    },
    1999: {
      id: "1999",
      i: 6,
      s: 12,
      ss: 83,
    },
    2000: {
      id: "2000",
      i: 10,
      s: 26,
      ss: 48,
    },
    2001: {
      id: "2001",
      i: 6,
      s: 11,
      ss: 14,
    },
    2002: {
      id: "2002",
      i: 7,
      s: 21,
      ss: 93,
    },
    2003: {
      id: "2003",
      i: 3,
      s: 20,
      ss: 38,
    },
    2005: {
      id: "2005",
      i: 5,
      s: 5,
      ss: 7,
    },
    2006: {
      id: "2006",
      i: 6,
      s: 7,
      ss: 9,
    },
    2007: {
      id: "2007",
      i: 2,
      s: 2,
      ss: 3,
    },
    2008: {
      id: "2008",
      i: 6,
      s: 6,
      ss: 91,
    },
    2009: {
      id: "2009",
      i: 2,
      s: 18,
      ss: 25,
    },
    2011: {
      id: "2011",
      i: 6,
      s: 27,
      ss: 49,
    },
    2012: {
      id: "2012",
      i: 11,
      s: 24,
      ss: 40,
    },
    2013: {
      id: "2013",
      i: 6,
      s: 6,
      ss: 44,
    },
    2014: {
      id: "2014",
      i: 6,
      s: 12,
      ss: 68,
    },
    2015: {
      id: "2015",
      i: 6,
      s: 16,
      ss: 62,
    },
    2016: {
      id: "2016",
      i: 3,
      s: 3,
      ss: 4,
    },
    2017: {
      id: "2017",
      i: 2,
      s: 18,
      ss: 56,
    },
    2018: {
      id: "2018",
      i: 9,
      s: 10,
      ss: 43,
    },
    2019: {
      id: "2019",
      i: 2,
      s: 2,
      ss: 3,
    },
    2020: {
      id: "2020",
      i: 6,
      s: 27,
      ss: 50,
    },
    2022: {
      id: "2022",
      i: 10,
      s: 15,
      ss: 35,
    },
    2023: {
      id: "2023",
      i: 6,
      s: 12,
      ss: 83,
    },
    2025: {
      id: "2025",
      i: 6,
      s: 16,
      ss: 23,
    },
    2028: {
      id: "2028",
      i: 10,
      s: 26,
      ss: 78,
    },
    2030: {
      id: "2030",
      i: 6,
      s: 27,
      ss: 50,
    },
    2031: {
      id: "2031",
      i: 5,
      s: 5,
      ss: 7,
    },
    2033: {
      id: "2033",
      i: 6,
      s: 27,
      ss: 70,
    },
    2038: {
      id: "2038",
      i: 10,
      s: 26,
      ss: 48,
    },
    2039: {
      id: "2039",
      i: 9,
      s: 10,
      ss: 45,
    },
    2048: {
      id: "2048",
      i: 2,
      s: 2,
      ss: 80,
    },
    2051: {
      id: "2051",
      i: 3,
      s: 20,
      ss: 38,
    },
    2057: {
      id: "2057",
      i: 9,
      s: 25,
      ss: 90,
    },
    2060: {
      id: "2060",
      i: 2,
      s: 18,
      ss: 25,
    },
    2066: {
      id: "2066",
      i: 3,
      s: 3,
      ss: 4,
    },
    2068: {
      id: "2068",
      i: 2,
      s: 18,
      ss: 69,
    },
    2078: {
      id: "2078",
      i: 7,
      s: 8,
      ss: 42,
    },
    2080: {
      id: "2080",
      i: 2,
      s: 2,
      ss: 47,
    },
    2083: {
      id: "2083",
      i: 6,
      s: 12,
      ss: 83,
    },
    2086: {
      id: "2086",
      i: 10,
      s: 26,
      ss: 78,
    },
    2088: {
      id: "2088",
      i: 2,
      s: 2,
      ss: 3,
    },
    2096: {
      id: "2096",
      i: 5,
      s: 5,
      ss: 7,
    },
    2098: {
      id: "2098",
      i: 9,
      s: 17,
      ss: 82,
    },
    2099: {
      id: "2099",
      i: 7,
      s: 29,
      ss: 72,
    },
    2100: {
      id: "2100",
      i: 10,
      s: 15,
      ss: 35,
    },
    2101: {
      id: "2101",
      i: 10,
      s: 15,
      ss: 52,
    },
    2102: {
      id: "2102",
      i: 9,
      s: 10,
      ss: 16,
    },
    2103: {
      id: "2103",
      i: 2,
      s: 2,
      ss: 3,
    },
    2107: {
      id: "2107",
      i: 2,
      s: 2,
      ss: 47,
    },
    2108: {
      id: "2108",
      i: 6,
      s: 7,
      ss: 22,
    },
    2110: {
      id: "2110",
      i: 2,
      s: 18,
      ss: 69,
    },
    2111: {
      id: "2111",
      i: 6,
      s: 27,
      ss: 55,
    },
    2112: {
      id: "2112",
      i: 7,
      s: 8,
      ss: 10,
    },
    2113: {
      id: "2113",
      i: 2,
      s: 18,
      ss: 56,
    },
    2115: {
      id: "2115",
      i: 2,
      s: 18,
      ss: 56,
    },
    2116: {
      id: "2116",
      i: 7,
      s: 21,
      ss: 30,
    },
    2117: {
      id: "2117",
      i: 2,
      s: 2,
      ss: 3,
    },
    2118: {
      id: "2118",
      i: 2,
      s: 2,
      ss: 3,
    },
    2119: {
      id: "2119",
      i: 8,
      s: 9,
      ss: 66,
    },
    2120: {
      id: "2120",
      i: 5,
      s: 30,
      ss: 73,
    },
    2122: {
      id: "2122",
      i: 6,
      s: 22,
      ss: 36,
    },
    2123: {
      id: "2123",
      i: 6,
      s: 27,
      ss: 55,
    },
    2125: {
      id: "2125",
      i: 6,
      s: 6,
      ss: 77,
    },
    2126: {
      id: "2126",
      i: 5,
      s: 5,
      ss: 94,
    },
    2127: {
      id: "2127",
      i: 6,
      s: 12,
      ss: 83,
    },
    2128: {
      id: "2128",
      i: 2,
      s: 18,
      ss: 25,
    },
    2129: {
      id: "2129",
      i: 9,
      s: 25,
      ss: 90,
    },
    2130: {
      id: "2130",
      i: 9,
      s: 25,
      ss: 90,
    },
    2131: {
      id: "2131",
      i: 6,
      s: 6,
      ss: 44,
    },
    2132: {
      id: "2132",
      i: 2,
      s: 18,
      ss: 69,
    },
    2133: {
      id: "2133",
      i: 7,
      s: 8,
      ss: 60,
    },
    2135: {
      id: "2135",
      i: 5,
      s: 30,
      ss: 73,
    },
    2136: {
      id: "2136",
      i: 6,
      s: 22,
      ss: 59,
    },
    2137: {
      id: "2137",
      i: 5,
      s: 5,
      ss: 94,
    },
    2138: {
      id: "2138",
      i: 5,
      s: 30,
      ss: 73,
    },
    2139: {
      id: "2139",
      i: 3,
      s: 3,
      ss: 4,
    },
    2142: {
      id: "2142",
      i: 5,
      s: 5,
      ss: 94,
    },
    2146: {
      id: "2146",
      i: 2,
      s: 2,
      ss: 47,
    },
    2148: {
      id: "2148",
      i: 6,
      s: 12,
      ss: 68,
    },
    2150: {
      id: "2150",
      i: 6,
      s: 7,
      ss: 22,
    },
    2153: {
      id: "2153",
      i: 9,
      s: 10,
      ss: 16,
    },
    2155: {
      id: "2155",
      i: 9,
      s: 10,
      ss: 16,
    },
    2156: {
      id: "2156",
      i: 2,
      s: 2,
      ss: 47,
    },
    2158: {
      id: "2158",
      i: 5,
      s: 30,
      ss: 73,
    },
    2159: {
      id: "2159",
      i: 5,
      s: 30,
      ss: 73,
    },
    2160: {
      id: "2160",
      i: 5,
      s: 30,
      ss: 92,
    },
    2161: {
      id: "2161",
      i: 5,
      s: 5,
      ss: 79,
    },
    2162: {
      id: "2162",
      i: 5,
      s: 5,
      ss: 94,
    },
    2163: {
      id: "2163",
      i: 2,
      s: 18,
      ss: 25,
    },
    2165: {
      id: "2165",
      i: 2,
      s: 2,
      ss: 47,
    },
    2166: {
      id: "2166",
      i: 10,
      s: 23,
      ss: 37,
    },
    2168: {
      id: "2168",
      i: 2,
      s: 2,
      ss: 47,
    },
    2169: {
      id: "2169",
      i: 9,
      s: 25,
      ss: 46,
    },
    2170: {
      id: "2170",
      i: 5,
      s: 5,
      ss: 94,
    },
    2171: {
      id: "2171",
      i: 5,
      s: 5,
      ss: 94,
    },
    2175: {
      id: "2175",
      i: 6,
      s: 11,
      ss: 14,
    },
    2176: {
      id: "2176",
      i: 10,
      s: 15,
      ss: 21,
    },
    2177: {
      id: "2177",
      i: 10,
      s: 15,
      ss: 52,
    },
    2178: {
      id: "2178",
      i: 11,
      s: 24,
      ss: 64,
    },
    2180: {
      id: "2180",
      i: 6,
      s: 11,
      ss: 34,
    },
    2181: {
      id: "2181",
      i: 5,
      s: 5,
      ss: 94,
    },
    2182: {
      id: "2182",
      i: 8,
      s: 9,
      ss: 26,
    },
    2183: {
      id: "2183",
      i: 2,
      s: 2,
      ss: 3,
    },
    2186: {
      id: "2186",
      i: 5,
      s: 5,
      ss: 7,
    },
    2187: {
      id: "2187",
      i: 2,
      s: 18,
      ss: 25,
    },
    2188: {
      id: "2188",
      i: 9,
      s: 10,
      ss: 57,
    },
    2189: {
      id: "2189",
      i: 6,
      s: 11,
      ss: 34,
    },
    2190: {
      id: "2190",
      i: 5,
      s: 30,
      ss: 92,
    },
    2191: {
      id: "2191",
      i: "",
      s: "",
      ss: 85,
    },
    2192: {
      id: "2192",
      i: 5,
      s: 30,
      ss: 73,
    },
    2193: {
      id: "2193",
      i: 2,
      s: 18,
      ss: 69,
    },
    2195: {
      id: "2195",
      i: 2,
      s: 18,
      ss: 56,
    },
    2196: {
      id: "2196",
      i: 5,
      s: 5,
      ss: 7,
    },
    2197: {
      id: "2197",
      i: 5,
      s: 5,
      ss: 94,
    },
    2198: {
      id: "2198",
      i: 7,
      s: 21,
      ss: 30,
    },
    2199: {
      id: "2199",
      i: 6,
      s: 27,
      ss: 50,
    },
    2202: {
      id: "2202",
      i: 2,
      s: 2,
      ss: 3,
    },
    2203: {
      id: "2203",
      i: 10,
      s: 23,
      ss: 37,
    },
    2205: {
      id: "2205",
      i: 2,
      s: 2,
      ss: 47,
    },
    2207: {
      id: "2207",
      i: 2,
      s: 2,
      ss: 47,
    },
    2208: {
      id: "2208",
      i: 9,
      s: 10,
      ss: 57,
    },
    2209: {
      id: "2209",
      i: 10,
      s: 15,
      ss: 52,
    },
    2210: {
      id: "2210",
      i: 2,
      s: 2,
      ss: 47,
    },
    2211: {
      id: "2211",
      i: 5,
      s: 5,
      ss: 7,
    },
    2212: {
      id: "2212",
      i: 7,
      s: 8,
      ss: 60,
    },
    2213: {
      id: "2213",
      i: 6,
      s: 22,
      ss: 59,
    },
    2215: {
      id: "2215",
      i: 2,
      s: 2,
      ss: 47,
    },
    2216: {
      id: "2216",
      i: 5,
      s: 30,
      ss: 92,
    },
    2217: {
      id: "2217",
      i: 6,
      s: 7,
      ss: 22,
    },
    2218: {
      id: "2218",
      i: 8,
      s: 9,
      ss: 66,
    },
    2219: {
      id: "2219",
      i: 5,
      s: 30,
      ss: 73,
    },
    2221: {
      id: "2221",
      i: 2,
      s: 18,
      ss: 69,
    },
    2222: {
      id: "2222",
      i: 6,
      s: 12,
      ss: 83,
    },
    2223: {
      id: "2223",
      i: 6,
      s: 12,
      ss: 83,
    },
    2225: {
      id: "2225",
      i: 6,
      s: 11,
      ss: 34,
    },
    2226: {
      id: "2226",
      i: 8,
      s: 9,
      ss: 12,
    },
    2227: {
      id: "2227",
      i: 2,
      s: 18,
      ss: 56,
    },
    2228: {
      id: "2228",
      i: 6,
      s: 27,
      ss: 55,
    },
    2230: {
      id: "2230",
      i: 6,
      s: 6,
      ss: 77,
    },
    2231: {
      id: "2231",
      i: 2,
      s: 2,
      ss: 3,
    },
    2232: {
      id: "2232",
      i: 6,
      s: 27,
      ss: 50,
    },
    2233: {
      id: "2233",
      i: 2,
      s: 18,
      ss: 25,
    },
    2235: {
      id: "2235",
      i: 5,
      s: 30,
      ss: 92,
    },
    2236: {
      id: "2236",
      i: 11,
      s: 24,
      ss: 64,
    },
    2238: {
      id: "2238",
      i: 6,
      s: 16,
      ss: 62,
    },
    2239: {
      id: "2239",
      i: 10,
      s: 26,
      ss: 48,
    },
    2251: {
      id: "2251",
      i: 5,
      s: 30,
      ss: 92,
    },
    2252: {
      id: "2252",
      i: 5,
      s: 30,
      ss: 92,
    },
    2255: {
      id: "2255",
      i: 6,
      s: 7,
      ss: 86,
    },
    2256: {
      id: "2256",
      i: 5,
      s: 5,
      ss: 94,
    },
    2258: {
      id: "2258",
      i: 2,
      s: 18,
      ss: 69,
    },
    2262: {
      id: "2262",
      i: 2,
      s: 18,
      ss: 56,
    },
    2263: {
      id: "2263",
      i: 3,
      s: 20,
      ss: 29,
    },
    2265: {
      id: "2265",
      i: 9,
      s: 10,
      ss: 57,
    },
    2266: {
      id: "2266",
      i: 2,
      s: 18,
      ss: 56,
    },
    2269: {
      id: "2269",
      i: 5,
      s: 5,
      ss: 94,
    },
    2278: {
      id: "2278",
      i: 2,
      s: 2,
      ss: 3,
    },
    2280: {
      id: "2280",
      i: 10,
      s: 15,
      ss: 52,
    },
    2281: {
      id: "2281",
      i: 1,
      s: 1,
      ss: 71,
    },
    2282: {
      id: "2282",
      i: 6,
      s: 7,
      ss: 11,
    },
    2283: {
      id: "2283",
      i: 9,
      s: 10,
      ss: 19,
    },
    2286: {
      id: "2286",
      i: 2,
      s: 2,
      ss: 3,
    },
    2288: {
      id: "2288",
      i: 2,
      s: 2,
      ss: 3,
    },
    2289: {
      id: "2289",
      i: 5,
      s: 5,
      ss: 7,
    },
    2292: {
      id: "2292",
      i: 2,
      s: 2,
      ss: 5,
    },
    2293: {
      id: "2293",
      i: 5,
      s: 30,
      ss: 73,
    },
    2296: {
      id: "2296",
      i: 2,
      s: 18,
      ss: 56,
    },
    2298: {
      id: "2298",
      i: 6,
      s: 27,
      ss: 50,
    },
    2299: {
      id: "2299",
      i: 6,
      s: 27,
      ss: 55,
    },
    2300: {
      id: "2300",
      i: 9,
      s: 17,
      ss: 24,
    },
    2302: {
      id: "2302",
      i: 7,
      s: 8,
      ss: 60,
    },
    2307: {
      id: "2307",
      i: 6,
      s: 27,
      ss: 55,
    },
    2308: {
      id: "2308",
      i: 10,
      s: 26,
      ss: 78,
    },
    2309: {
      id: "2309",
      i: 6,
      s: 7,
      ss: 86,
    },
    2310: {
      id: "2310",
      i: 2,
      s: 2,
      ss: 47,
    },
    2312: {
      id: "2312",
      i: 3,
      s: 20,
      ss: 31,
    },
    2313: {
      id: "2313",
      i: 6,
      s: 27,
      ss: 50,
    },
    2314: {
      id: "2314",
      i: 7,
      s: 21,
      ss: 93,
    },
    2317: {
      id: "2317",
      i: 8,
      s: 9,
      ss: 12,
    },
    2318: {
      id: "2318",
      i: 3,
      s: 28,
      ss: 67,
    },
    2319: {
      id: "2319",
      i: 8,
      s: 9,
      ss: 87,
    },
    2320: {
      id: "2320",
      i: 7,
      s: 21,
      ss: 93,
    },
    2322: {
      id: "2322",
      i: 7,
      s: 21,
      ss: 30,
    },
    2323: {
      id: "2323",
      i: 9,
      s: 10,
      ss: 19,
    },
    2324: {
      id: "2324",
      i: 3,
      s: 20,
      ss: 31,
    },
    2326: {
      id: "2326",
      i: 7,
      s: 8,
      ss: 60,
    },
    2327: {
      id: "2327",
      i: 0,
      s: 0,
      ss: 0,
    },
    2328: {
      id: "2328",
      i: 3,
      s: 28,
      ss: 67,
    },
    2329: {
      id: "2329",
      i: 2,
      s: 2,
      ss: 3,
    },
    2330: {
      id: "2330",
      i: 2,
      s: 2,
      ss: 3,
    },
    2331: {
      id: "2331",
      i: 6,
      s: 27,
      ss: 50,
    },
    2333: {
      id: "2333",
      i: 6,
      s: 16,
      ss: 62,
    },
    2336: {
      id: "2336",
      i: 7,
      s: 8,
      ss: 81,
    },
    2337: {
      id: "2337",
      i: 1,
      s: 1,
      ss: 2,
    },
    2338: {
      id: "2338",
      i: 9,
      s: 10,
      ss: 45,
    },
    2339: {
      id: "2339",
      i: 6,
      s: 16,
      ss: 23,
    },
    2340: {
      id: "2340",
      i: 2,
      s: 2,
      ss: 47,
    },
    2341: {
      id: "2341",
      i: 7,
      s: 21,
      ss: 30,
    },
    2342: {
      id: "2342",
      i: 10,
      s: 26,
      ss: 48,
    },
    2343: {
      id: "2343",
      i: 9,
      s: 25,
      ss: 53,
    },
    2346: {
      id: "2346",
      i: 9,
      s: 10,
      ss: 19,
    },
    2348: {
      id: "2348",
      i: 5,
      s: 5,
      ss: 7,
    },
    2349: {
      id: "2349",
      i: 2,
      s: 2,
      ss: 5,
    },
    2355: {
      id: "2355",
      i: 2,
      s: 18,
      ss: 56,
    },
    2356: {
      id: "2356",
      i: 3,
      s: 3,
      ss: 4,
    },
    2357: {
      id: "2357",
      i: 9,
      s: 10,
      ss: 13,
    },
    2358: {
      id: "2358",
      i: 6,
      s: 12,
      ss: 18,
    },
    2359: {
      id: "2359",
      i: 5,
      s: 5,
      ss: 94,
    },
    2360: {
      id: "2360",
      i: 8,
      s: 13,
      ss: 17,
    },
    2362: {
      id: "2362",
      i: 7,
      s: 8,
      ss: 60,
    },
    2363: {
      id: "2363",
      i: 10,
      s: 26,
      ss: 78,
    },
    2366: {
      id: "2366",
      i: 6,
      s: 6,
      ss: 77,
    },
    2368: {
      id: "2368",
      i: 6,
      s: 27,
      ss: 50,
    },
    2369: {
      id: "2369",
      i: 10,
      s: 26,
      ss: 48,
    },
    2371: {
      id: "2371",
      i: 6,
      s: 11,
      ss: 14,
    },
    2377: {
      id: "2377",
      i: 9,
      s: 10,
      ss: 57,
    },
    2378: {
      id: "2378",
      i: 3,
      s: 28,
      ss: 67,
    },
    2379: {
      id: "2379",
      i: 2,
      s: 2,
      ss: 5,
    },
    2380: {
      id: "2380",
      i: 1,
      s: 1,
      ss: 1,
    },
    2381: {
      id: "2381",
      i: 6,
      s: 12,
      ss: 68,
    },
    2382: {
      id: "2382",
      i: 9,
      s: 10,
      ss: 43,
    },
    2383: {
      id: "2383",
      i: 6,
      s: 6,
      ss: 8,
    },
    2386: {
      id: "2386",
      i: 11,
      s: 24,
      ss: 64,
    },
    2388: {
      id: "2388",
      i: 3,
      s: 3,
      ss: 4,
    },
    2389: {
      id: "2389",
      i: 5,
      s: 30,
      ss: 73,
    },
    2393: {
      id: "2393",
      i: 5,
      s: 30,
      ss: 92,
    },
    2398: {
      id: "2398",
      i: 9,
      s: 10,
      ss: 19,
    },
    2399: {
      id: "2399",
      i: 6,
      s: 27,
      ss: 50,
    },
    2400: {
      id: "2400",
      i: 10,
      s: 15,
      ss: 35,
    },
    2448: {
      id: "2448",
      i: 2,
      s: 18,
      ss: 56,
    },
    2488: {
      id: "2488",
      i: 6,
      s: 16,
      ss: 23,
    },
    2500: {
      id: "2500",
      i: 5,
      s: 30,
      ss: 92,
    },
    2518: {
      id: "2518",
      i: 10,
      s: 15,
      ss: 52,
    },
    2528: {
      id: "2528",
      i: 6,
      s: 22,
      ss: 51,
    },
    2552: {
      id: "2552",
      i: 5,
      s: 5,
      ss: 94,
    },
    2558: {
      id: "2558",
      i: 3,
      s: 3,
      ss: 4,
    },
    2588: {
      id: "2588",
      i: 6,
      s: 7,
      ss: 75,
    },
    2599: {
      id: "2599",
      i: 2,
      s: 2,
      ss: 3,
    },
    2600: {
      id: "2600",
      i: 7,
      s: 8,
      ss: 42,
    },
    2601: {
      id: "2601",
      i: 3,
      s: 28,
      ss: 67,
    },
    2606: {
      id: "2606",
      i: 2,
      s: 2,
      ss: 47,
    },
    2607: {
      id: "2607",
      i: 5,
      s: 5,
      ss: 7,
    },
    2608: {
      id: "2608",
      i: 2,
      s: 2,
      ss: 3,
    },
    2611: {
      id: "2611",
      i: 3,
      s: 20,
      ss: 29,
    },
    2616: {
      id: "2616",
      i: 5,
      s: 5,
      ss: 94,
    },
    2618: {
      id: "2618",
      i: 9,
      s: 17,
      ss: 82,
    },
    2623: {
      id: "2623",
      i: 7,
      s: 8,
      ss: 10,
    },
    2628: {
      id: "2628",
      i: 3,
      s: 28,
      ss: 67,
    },
    2633: {
      id: "2633",
      i: 5,
      s: 5,
      ss: 7,
    },
    2638: {
      id: "2638",
      i: 1,
      s: 1,
      ss: 1,
    },
    2660: {
      id: "2660",
      i: 10,
      s: 15,
      ss: 35,
    },
    2662: {
      id: "2662",
      i: 6,
      s: 11,
      ss: 34,
    },
    2663: {
      id: "2663",
      i: 2,
      s: 18,
      ss: 56,
    },
    2666: {
      id: "2666",
      i: 3,
      s: 20,
      ss: 38,
    },
    2668: {
      id: "2668",
      i: 9,
      s: 17,
      ss: 82,
    },
    2669: {
      id: "2669",
      i: 2,
      s: 2,
      ss: 47,
    },
    2678: {
      id: "2678",
      i: 6,
      s: 27,
      ss: 55,
    },
    2680: {
      id: "2680",
      i: 3,
      s: 20,
      ss: 63,
    },
    2682: {
      id: "2682",
      i: 9,
      s: 25,
      ss: 53,
    },
    2683: {
      id: "2683",
      i: 6,
      s: 27,
      ss: 49,
    },
    2686: {
      id: "2686",
      i: 11,
      s: 24,
      ss: 40,
    },
    2688: {
      id: "2688",
      i: 1,
      s: 1,
      ss: 2,
    },
    2689: {
      id: "2689",
      i: 7,
      s: 21,
      ss: 93,
    },
    2696: {
      id: "2696",
      i: 5,
      s: 5,
      ss: 94,
    },
    2698: {
      id: "2698",
      i: 6,
      s: 27,
      ss: 55,
    },
    2699: {
      id: "2699",
      i: 2,
      s: 2,
      ss: 3,
    },
    2700: {
      id: "2700",
      i: 5,
      s: 30,
      ss: 73,
    },
    2708: {
      id: "2708",
      i: 10,
      s: 15,
      ss: 21,
    },
    2718: {
      id: "2718",
      i: 3,
      s: 20,
      ss: 38,
    },
    2722: {
      id: "2722",
      i: 0,
      s: 0,
      ss: 0,
    },
    2727: {
      id: "2727",
      i: 9,
      s: 10,
      ss: 57,
    },
    2728: {
      id: "2728",
      i: 11,
      s: 24,
      ss: 40,
    },
    2738: {
      id: "2738",
      i: 7,
      s: 8,
      ss: 10,
    },
    2768: {
      id: "2768",
      i: 2,
      s: 2,
      ss: 3,
    },
    2772: {
      id: "2772",
      i: 2,
      s: 2,
      ss: 3,
    },
    2777: {
      id: "2777",
      i: 2,
      s: 2,
      ss: 3,
    },
    2778: {
      id: "2778",
      i: "",
      s: "",
      ss: 85,
    },
    2779: {
      id: "2779",
      i: 6,
      s: 11,
      ss: 14,
    },
    2788: {
      id: "2788",
      i: 9,
      s: 10,
      ss: 19,
    },
    2789: {
      id: "2789",
      i: 2,
      s: 18,
      ss: 56,
    },
    2798: {
      id: "2798",
      i: 11,
      s: 19,
      ss: 27,
    },
    2799: {
      id: "2799",
      i: 3,
      s: 20,
      ss: 31,
    },
    2800: {
      id: "2800",
      i: "",
      s: "",
      ss: 85,
    },
    2801: {
      id: "2801",
      i: "",
      s: "",
      ss: 85,
    },
    2802: {
      id: "2802",
      i: "",
      s: "",
      ss: 85,
    },
    2803: {
      id: "2803",
      i: "",
      s: "",
      ss: 85,
    },
    2804: {
      id: "2804",
      i: "",
      s: "",
      ss: 85,
    },
    2806: {
      id: "2806",
      i: "",
      s: "",
      ss: 85,
    },
    2807: {
      id: "2807",
      i: "",
      s: "",
      ss: 85,
    },
    2809: {
      id: "2809",
      i: "",
      s: "",
      ss: 85,
    },
    2810: {
      id: "2810",
      i: "",
      s: "",
      ss: 85,
    },
    2811: {
      id: "2811",
      i: "",
      s: "",
      ss: 85,
    },
    2812: {
      id: "2812",
      i: "",
      s: "",
      ss: 85,
    },
    2813: {
      id: "2813",
      i: "",
      s: "",
      ss: 85,
    },
    2814: {
      id: "2814",
      i: "",
      s: "",
      ss: 85,
    },
    2817: {
      id: "2817",
      i: "",
      s: "",
      ss: 85,
    },
    2819: {
      id: "2819",
      i: "",
      s: "",
      ss: 85,
    },
    2820: {
      id: "2820",
      i: "",
      s: "",
      ss: 85,
    },
    2821: {
      id: "2821",
      i: "",
      s: "",
      ss: 85,
    },
    2822: {
      id: "2822",
      i: "",
      s: "",
      ss: 85,
    },
    2823: {
      id: "2823",
      i: "",
      s: "",
      ss: 85,
    },
    2824: {
      id: "2824",
      i: "",
      s: "",
      ss: 85,
    },
    2825: {
      id: "2825",
      i: "",
      s: "",
      ss: 85,
    },
    2826: {
      id: "2826",
      i: "",
      s: "",
      ss: 85,
    },
    2827: {
      id: "2827",
      i: "",
      s: "",
      ss: 85,
    },
    2828: {
      id: "2828",
      i: "",
      s: "",
      ss: 85,
    },
    2829: {
      id: "2829",
      i: "",
      s: "",
      ss: 85,
    },
    2832: {
      id: "2832",
      i: "",
      s: "",
      ss: 85,
    },
    2833: {
      id: "2833",
      i: "",
      s: "",
      ss: 85,
    },
    2834: {
      id: "2834",
      i: "",
      s: "",
      ss: 85,
    },
    2836: {
      id: "2836",
      i: "",
      s: "",
      ss: 85,
    },
    2838: {
      id: "2838",
      i: "",
      s: "",
      ss: 85,
    },
    2840: {
      id: "2840",
      i: "",
      s: "",
      ss: 85,
    },
    2843: {
      id: "2843",
      i: "",
      s: "",
      ss: 85,
    },
    2845: {
      id: "2845",
      i: "",
      s: "",
      ss: 85,
    },
    2846: {
      id: "2846",
      i: "",
      s: "",
      ss: 85,
    },
    2848: {
      id: "2848",
      i: "",
      s: "",
      ss: 85,
    },
    2858: {
      id: "2858",
      i: 3,
      s: 20,
      ss: 38,
    },
    2863: {
      id: "2863",
      i: 2,
      s: 18,
      ss: 56,
    },
    2866: {
      id: "2866",
      i: 9,
      s: 25,
      ss: 53,
    },
    2868: {
      id: "2868",
      i: 2,
      s: 2,
      ss: 3,
    },
    2869: {
      id: "2869",
      i: 2,
      s: 2,
      ss: 47,
    },
    2877: {
      id: "2877",
      i: 5,
      s: 5,
      ss: 79,
    },
    2878: {
      id: "2878",
      i: 10,
      s: 23,
      ss: 37,
    },
    2880: {
      id: "2880",
      i: 9,
      s: 25,
      ss: 53,
    },
    2882: {
      id: "2882",
      i: 6,
      s: 22,
      ss: 36,
    },
    2883: {
      id: "2883",
      i: 11,
      s: 24,
      ss: 64,
    },
    2885: {
      id: "2885",
      i: 9,
      s: 10,
      ss: 19,
    },
    2886: {
      id: "2886",
      i: 1,
      s: 1,
      ss: 2,
    },
    2888: {
      id: "2888",
      i: 3,
      s: 3,
      ss: 4,
    },
    2892: {
      id: "2892",
      i: 2,
      s: 2,
      ss: 3,
    },
    2898: {
      id: "2898",
      i: 8,
      s: 9,
      ss: 66,
    },
    2899: {
      id: "2899",
      i: 7,
      s: 29,
      ss: 72,
    },
    2971: {
      id: "2971",
      i: 6,
      s: 7,
      ss: 11,
    },
    2972: {
      id: "2972",
      i: 1,
      s: 1,
      ss: 71,
    },
    2973: {
      id: "2973",
      i: 6,
      s: 27,
      ss: 50,
    },
    2974: {
      id: "2974",
      i: 9,
      s: 25,
      ss: 90,
    },
    2975: {
      id: "2975",
      i: 6,
      s: 6,
      ss: 77,
    },
    2981: {
      id: "2981",
      i: 5,
      s: 30,
      ss: 92,
    },
    2989: {
      id: "2989",
      i: 2,
      s: 18,
      ss: 56,
    },
    3001: {
      id: "3001",
      i: "",
      s: "",
      ss: 85,
    },
    3005: {
      id: "3005",
      i: "",
      s: "",
      ss: 85,
    },
    3007: {
      id: "3007",
      i: "",
      s: "",
      ss: 85,
    },
    3010: {
      id: "3010",
      i: "",
      s: "",
      ss: 85,
    },
    3011: {
      id: "3011",
      i: "",
      s: "",
      ss: 85,
    },
    3012: {
      id: "3012",
      i: "",
      s: "",
      ss: 85,
    },
    3015: {
      id: "3015",
      i: "",
      s: "",
      ss: 85,
    },
    3016: {
      id: "3016",
      i: "",
      s: "",
      ss: 85,
    },
    3019: {
      id: "3019",
      i: "",
      s: "",
      ss: 85,
    },
    3020: {
      id: "3020",
      i: "",
      s: "",
      ss: 85,
    },
    3022: {
      id: "3022",
      i: "",
      s: "",
      ss: 85,
    },
    3023: {
      id: "3023",
      i: "",
      s: "",
      ss: 85,
    },
    3024: {
      id: "3024",
      i: "",
      s: "",
      ss: 85,
    },
    3027: {
      id: "3027",
      i: "",
      s: "",
      ss: 85,
    },
    3031: {
      id: "3031",
      i: "",
      s: "",
      ss: 85,
    },
    3032: {
      id: "3032",
      i: "",
      s: "",
      ss: 85,
    },
    3033: {
      id: "3033",
      i: "",
      s: "",
      ss: 85,
    },
    3036: {
      id: "3036",
      i: "",
      s: "",
      ss: 85,
    },
    3037: {
      id: "3037",
      i: "",
      s: "",
      ss: 85,
    },
    3040: {
      id: "3040",
      i: "",
      s: "",
      ss: 85,
    },
    3043: {
      id: "3043",
      i: "",
      s: "",
      ss: 85,
    },
    3047: {
      id: "3047",
      i: "",
      s: "",
      ss: 85,
    },
    3048: {
      id: "3048",
      i: "",
      s: "",
      ss: 85,
    },
    3049: {
      id: "3049",
      i: "",
      s: "",
      ss: 85,
    },
    3050: {
      id: "3050",
      i: "",
      s: "",
      ss: 85,
    },
    3053: {
      id: "3053",
      i: "",
      s: "",
      ss: 85,
    },
    3055: {
      id: "3055",
      i: "",
      s: "",
      ss: 85,
    },
    3058: {
      id: "3058",
      i: "",
      s: "",
      ss: 85,
    },
    3065: {
      id: "3065",
      i: "",
      s: "",
      ss: 85,
    },
    3067: {
      id: "3067",
      i: "",
      s: "",
      ss: 85,
    },
    3069: {
      id: "3069",
      i: "",
      s: "",
      ss: 85,
    },
    3070: {
      id: "3070",
      i: "",
      s: "",
      ss: 85,
    },
    3071: {
      id: "3071",
      i: "",
      s: "",
      ss: 85,
    },
    3072: {
      id: "3072",
      i: "",
      s: "",
      ss: 85,
    },
    3073: {
      id: "3073",
      i: "",
      s: "",
      ss: 85,
    },
    3074: {
      id: "3074",
      i: "",
      s: "",
      ss: 85,
    },
    3077: {
      id: "3077",
      i: "",
      s: "",
      ss: 85,
    },
    3079: {
      id: "3079",
      i: "",
      s: "",
      ss: 85,
    },
    3080: {
      id: "3080",
      i: "",
      s: "",
      ss: 85,
    },
    3081: {
      id: "3081",
      i: "",
      s: "",
      ss: 85,
    },
    3082: {
      id: "3082",
      i: "",
      s: "",
      ss: 85,
    },
    3086: {
      id: "3086",
      i: "",
      s: "",
      ss: 85,
    },
    3087: {
      id: "3087",
      i: "",
      s: "",
      ss: 85,
    },
    3088: {
      id: "3088",
      i: "",
      s: "",
      ss: 85,
    },
    3091: {
      id: "3091",
      i: "",
      s: "",
      ss: 85,
    },
    3092: {
      id: "3092",
      i: "",
      s: "",
      ss: 85,
    },
    3093: {
      id: "3093",
      i: "",
      s: "",
      ss: 85,
    },
    3096: {
      id: "3096",
      i: "",
      s: "",
      ss: 85,
    },
    3097: {
      id: "3097",
      i: "",
      s: "",
      ss: 85,
    },
    3099: {
      id: "3099",
      i: "",
      s: "",
      ss: 85,
    },
    3100: {
      id: "3100",
      i: "",
      s: "",
      ss: 85,
    },
    3108: {
      id: "3108",
      i: "",
      s: "",
      ss: 85,
    },
    3109: {
      id: "3109",
      i: "",
      s: "",
      ss: 85,
    },
    3110: {
      id: "3110",
      i: "",
      s: "",
      ss: 85,
    },
    3115: {
      id: "3115",
      i: "",
      s: "",
      ss: 85,
    },
    3117: {
      id: "3117",
      i: "",
      s: "",
      ss: 85,
    },
    3118: {
      id: "3118",
      i: "",
      s: "",
      ss: 85,
    },
    3119: {
      id: "3119",
      i: "",
      s: "",
      ss: 85,
    },
    3121: {
      id: "3121",
      i: "",
      s: "",
      ss: 85,
    },
    3122: {
      id: "3122",
      i: "",
      s: "",
      ss: 85,
    },
    3124: {
      id: "3124",
      i: "",
      s: "",
      ss: 85,
    },
    3125: {
      id: "3125",
      i: "",
      s: "",
      ss: 85,
    },
    3127: {
      id: "3127",
      i: "",
      s: "",
      ss: 85,
    },
    3128: {
      id: "3128",
      i: "",
      s: "",
      ss: 85,
    },
    3130: {
      id: "3130",
      i: "",
      s: "",
      ss: 85,
    },
    3132: {
      id: "3132",
      i: "",
      s: "",
      ss: 85,
    },
    3134: {
      id: "3134",
      i: "",
      s: "",
      ss: 85,
    },
    3141: {
      id: "3141",
      i: "",
      s: "",
      ss: 85,
    },
    3143: {
      id: "3143",
      i: "",
      s: "",
      ss: 85,
    },
    3145: {
      id: "3145",
      i: "",
      s: "",
      ss: 85,
    },
    3147: {
      id: "3147",
      i: "",
      s: "",
      ss: 85,
    },
    3149: {
      id: "3149",
      i: "",
      s: "",
      ss: 85,
    },
    3151: {
      id: "3151",
      i: "",
      s: "",
      ss: 85,
    },
    3157: {
      id: "3157",
      i: "",
      s: "",
      ss: 85,
    },
    3160: {
      id: "3160",
      i: "",
      s: "",
      ss: 85,
    },
    3162: {
      id: "3162",
      i: "",
      s: "",
      ss: 85,
    },
    3163: {
      id: "3163",
      i: "",
      s: "",
      ss: 85,
    },
    3165: {
      id: "3165",
      i: "",
      s: "",
      ss: 85,
    },
    3166: {
      id: "3166",
      i: "",
      s: "",
      ss: 85,
    },
    3167: {
      id: "3167",
      i: "",
      s: "",
      ss: 85,
    },
    3173: {
      id: "3173",
      i: "",
      s: "",
      ss: 85,
    },
    3174: {
      id: "3174",
      i: "",
      s: "",
      ss: 85,
    },
    3175: {
      id: "3175",
      i: "",
      s: "",
      ss: 85,
    },
    3176: {
      id: "3176",
      i: "",
      s: "",
      ss: 85,
    },
    3181: {
      id: "3181",
      i: "",
      s: "",
      ss: 85,
    },
    3182: {
      id: "3182",
      i: "",
      s: "",
      ss: 85,
    },
    3186: {
      id: "3186",
      i: "",
      s: "",
      ss: 85,
    },
    3187: {
      id: "3187",
      i: "",
      s: "",
      ss: 85,
    },
    3188: {
      id: "3188",
      i: "",
      s: "",
      ss: 85,
    },
    3191: {
      id: "3191",
      i: "",
      s: "",
      ss: 85,
    },
    3193: {
      id: "3193",
      i: "",
      s: "",
      ss: 85,
    },
    3194: {
      id: "3194",
      i: "",
      s: "",
      ss: 85,
    },
    3197: {
      id: "3197",
      i: "",
      s: "",
      ss: 85,
    },
    3198: {
      id: "3198",
      i: "",
      s: "",
      ss: 85,
    },
    3199: {
      id: "3199",
      i: "",
      s: "",
      ss: 85,
    },
    3300: {
      id: "3300",
      i: 9,
      s: 10,
      ss: 19,
    },
    3301: {
      id: "3301",
      i: 2,
      s: 2,
      ss: 3,
    },
    3302: {
      id: "3302",
      i: 9,
      s: 10,
      ss: 19,
    },
    3303: {
      id: "3303",
      i: 11,
      s: 24,
      ss: 64,
    },
    3306: {
      id: "3306",
      i: 6,
      s: 27,
      ss: 50,
    },
    3308: {
      id: "3308",
      i: 6,
      s: 22,
      ss: 59,
    },
    3309: {
      id: "3309",
      i: 5,
      s: 30,
      ss: 73,
    },
    3311: {
      id: "3311",
      i: 2,
      s: 18,
      ss: 69,
    },
    3313: {
      id: "3313",
      i: 2,
      s: 18,
      ss: 25,
    },
    3315: {
      id: "3315",
      i: 10,
      s: 15,
      ss: 35,
    },
    3316: {
      id: "3316",
      i: 2,
      s: 2,
      ss: 47,
    },
    3318: {
      id: "3318",
      i: 8,
      s: 9,
      ss: 12,
    },
    3319: {
      id: "3319",
      i: 2,
      s: 2,
      ss: 47,
    },
    3320: {
      id: "3320",
      i: 5,
      s: 5,
      ss: 7,
    },
    3321: {
      id: "3321",
      i: 2,
      s: 18,
      ss: 56,
    },
    3322: {
      id: "3322",
      i: 6,
      s: 27,
      ss: 50,
    },
    3323: {
      id: "3323",
      i: 2,
      s: 18,
      ss: 25,
    },
    3326: {
      id: "3326",
      i: 6,
      s: 27,
      ss: 70,
    },
    3328: {
      id: "3328",
      i: 3,
      s: 3,
      ss: 4,
    },
    3329: {
      id: "3329",
      i: 3,
      s: 20,
      ss: 38,
    },
    3330: {
      id: "3330",
      i: 7,
      s: 29,
      ss: 72,
    },
    3331: {
      id: "3331",
      i: 8,
      s: 13,
      ss: 84,
    },
    3332: {
      id: "3332",
      i: 8,
      s: 13,
      ss: 84,
    },
    3333: {
      id: "3333",
      i: 2,
      s: 2,
      ss: 3,
    },
    3336: {
      id: "3336",
      i: 10,
      s: 26,
      ss: 78,
    },
    3337: {
      id: "3337",
      i: 11,
      s: 24,
      ss: 64,
    },
    3339: {
      id: "3339",
      i: 9,
      s: 10,
      ss: 16,
    },
    3344: {
      id: "3344",
      i: 6,
      s: 27,
      ss: 50,
    },
    3347: {
      id: "3347",
      i: 5,
      s: 5,
      ss: 94,
    },
    3348: {
      id: "3348",
      i: 9,
      s: 10,
      ss: 16,
    },
    3358: {
      id: "3358",
      i: 6,
      s: 12,
      ss: 15,
    },
    3360: {
      id: "3360",
      i: 3,
      s: 20,
      ss: 38,
    },
    3363: {
      id: "3363",
      i: 7,
      s: 21,
      ss: 93,
    },
    3366: {
      id: "3366",
      i: 2,
      s: 2,
      ss: 3,
    },
    3368: {
      id: "3368",
      i: 6,
      s: 22,
      ss: 59,
    },
    3369: {
      id: "3369",
      i: 9,
      s: 25,
      ss: 53,
    },
    3377: {
      id: "3377",
      i: 2,
      s: 2,
      ss: 3,
    },
    3378: {
      id: "3378",
      i: 9,
      s: 25,
      ss: 53,
    },
    3380: {
      id: "3380",
      i: 2,
      s: 2,
      ss: 3,
    },
    3382: {
      id: "3382",
      i: 9,
      s: 25,
      ss: 53,
    },
    3383: {
      id: "3383",
      i: 2,
      s: 2,
      ss: 3,
    },
    3389: {
      id: "3389",
      i: 6,
      s: 22,
      ss: 36,
    },
    3390: {
      id: "3390",
      i: 5,
      s: 5,
      ss: 7,
    },
    3393: {
      id: "3393",
      i: 9,
      s: 10,
      ss: 19,
    },
    3395: {
      id: "3395",
      i: 11,
      s: 24,
      ss: 40,
    },
    3396: {
      id: "3396",
      i: 10,
      s: 26,
      ss: 78,
    },
    3398: {
      id: "3398",
      i: 6,
      s: 27,
      ss: 50,
    },
    3399: {
      id: "3399",
      i: 6,
      s: 7,
      ss: 32,
    },
    3600: {
      id: "3600",
      i: 5,
      s: 30,
      ss: 92,
    },
    3601: {
      id: "3601",
      i: 10,
      s: 15,
      ss: 35,
    },
    3603: {
      id: "3603",
      i: 2,
      s: 2,
      ss: 5,
    },
    3606: {
      id: "3606",
      i: 6,
      s: 16,
      ss: 23,
    },
    3608: {
      id: "3608",
      i: 6,
      s: 27,
      ss: 55,
    },
    3613: {
      id: "3613",
      i: 5,
      s: 5,
      ss: 79,
    },
    3616: {
      id: "3616",
      i: 2,
      s: 2,
      ss: 3,
    },
    3618: {
      id: "3618",
      i: 3,
      s: 3,
      ss: 4,
    },
    3623: {
      id: "3623",
      i: 3,
      s: 20,
      ss: 63,
    },
    3626: {
      id: "3626",
      i: 9,
      s: 17,
      ss: 24,
    },
    3628: {
      id: "3628",
      i: 9,
      s: 10,
      ss: 19,
    },
    3633: {
      id: "3633",
      i: 1,
      s: 1,
      ss: 2,
    },
    3636: {
      id: "3636",
      i: 6,
      s: 6,
      ss: 77,
    },
    3638: {
      id: "3638",
      i: 10,
      s: 26,
      ss: 78,
    },
    3639: {
      id: "3639",
      i: 2,
      s: 2,
      ss: 3,
    },
    3658: {
      id: "3658",
      i: 2,
      s: 2,
      ss: 47,
    },
    3662: {
      id: "3662",
      i: 2,
      s: 2,
      ss: 47,
    },
    3663: {
      id: "3663",
      i: 6,
      s: 16,
      ss: 23,
    },
    3666: {
      id: "3666",
      i: 6,
      s: 7,
      ss: 22,
    },
    3668: {
      id: "3668",
      i: 11,
      s: 19,
      ss: 27,
    },
    3669: {
      id: "3669",
      i: 6,
      s: 22,
      ss: 54,
    },
    3678: {
      id: "3678",
      i: 3,
      s: 20,
      ss: 29,
    },
    3680: {
      id: "3680",
      i: 10,
      s: 15,
      ss: 21,
    },
    3681: {
      id: "3681",
      i: 5,
      s: 5,
      ss: 94,
    },
    3683: {
      id: "3683",
      i: 9,
      s: 25,
      ss: 53,
    },
    3686: {
      id: "3686",
      i: 0,
      s: 0,
      ss: 0,
    },
    3688: {
      id: "3688",
      i: 2,
      s: 2,
      ss: 5,
    },
    3689: {
      id: "3689",
      i: 5,
      s: 30,
      ss: 73,
    },
    3690: {
      id: "3690",
      i: 10,
      s: 15,
      ss: 52,
    },
    3692: {
      id: "3692",
      i: 5,
      s: 5,
      ss: 7,
    },
    3698: {
      id: "3698",
      i: 3,
      s: 3,
      ss: 4,
    },
    3699: {
      id: "3699",
      i: 2,
      s: 2,
      ss: 5,
    },
    3700: {
      id: "3700",
      i: 6,
      s: 6,
      ss: 91,
    },
    3708: {
      id: "3708",
      i: 2,
      s: 18,
      ss: 56,
    },
    3709: {
      id: "3709",
      i: 6,
      s: 27,
      ss: 50,
    },
    3718: {
      id: "3718",
      i: 9,
      s: 10,
      ss: 57,
    },
    3728: {
      id: "3728",
      i: 2,
      s: 18,
      ss: 56,
    },
    3737: {
      id: "3737",
      i: 5,
      s: 5,
      ss: 7,
    },
    3738: {
      id: "3738",
      i: 10,
      s: 15,
      ss: 35,
    },
    3759: {
      id: "3759",
      i: 5,
      s: 5,
      ss: 7,
    },
    3768: {
      id: "3768",
      i: 1,
      s: 1,
      ss: 71,
    },
    3773: {
      id: "3773",
      i: 10,
      s: 15,
      ss: 52,
    },
    3778: {
      id: "3778",
      i: 6,
      s: 27,
      ss: 55,
    },
    3788: {
      id: "3788",
      i: 7,
      s: 8,
      ss: 10,
    },
    3789: {
      id: "3789",
      i: 2,
      s: 18,
      ss: 56,
    },
    3798: {
      id: "3798",
      i: 10,
      s: 15,
      ss: 35,
    },
    3799: {
      id: "3799",
      i: 8,
      s: 9,
      ss: 26,
    },
    3800: {
      id: "3800",
      i: 9,
      s: 10,
      ss: 58,
    },
    3808: {
      id: "3808",
      i: 9,
      s: 10,
      ss: 45,
    },
    3813: {
      id: "3813",
      i: 6,
      s: 22,
      ss: 51,
    },
    3816: {
      id: "3816",
      i: 9,
      s: 10,
      ss: 19,
    },
    3818: {
      id: "3818",
      i: 6,
      s: 27,
      ss: 50,
    },
    3822: {
      id: "3822",
      i: 2,
      s: 18,
      ss: 69,
    },
    3828: {
      id: "3828",
      i: 8,
      s: 13,
      ss: 84,
    },
    3830: {
      id: "3830",
      i: 6,
      s: 12,
      ss: 15,
    },
    3833: {
      id: "3833",
      i: 7,
      s: 8,
      ss: 60,
    },
    3836: {
      id: "3836",
      i: 6,
      s: 22,
      ss: 54,
    },
    3838: {
      id: "3838",
      i: 8,
      s: 14,
      ss: 33,
    },
    3839: {
      id: "3839",
      i: 5,
      s: 5,
      ss: 94,
    },
    3848: {
      id: "3848",
      i: 3,
      s: 20,
      ss: 38,
    },
    3860: {
      id: "3860",
      i: 6,
      s: 27,
      ss: 50,
    },
    3866: {
      id: "3866",
      i: 3,
      s: 3,
      ss: 4,
    },
    3868: {
      id: "3868",
      i: 1,
      s: 1,
      ss: 39,
    },
    3869: {
      id: "3869",
      i: 5,
      s: 30,
      ss: 73,
    },
    3877: {
      id: "3877",
      i: 3,
      s: 20,
      ss: 38,
    },
    3878: {
      id: "3878",
      i: 2,
      s: 18,
      ss: 56,
    },
    3882: {
      id: "3882",
      i: 6,
      s: 12,
      ss: 18,
    },
    3883: {
      id: "3883",
      i: 2,
      s: 2,
      ss: 3,
    },
    3886: {
      id: "3886",
      i: 5,
      s: 30,
      ss: 73,
    },
    3888: {
      id: "3888",
      i: 10,
      s: 15,
      ss: 35,
    },
    3889: {
      id: "3889",
      i: 8,
      s: 14,
      ss: 33,
    },
    3893: {
      id: "3893",
      i: 6,
      s: 12,
      ss: 83,
    },
    3898: {
      id: "3898",
      i: 9,
      s: 10,
      ss: 45,
    },
    3899: {
      id: "3899",
      i: 11,
      s: 24,
      ss: 64,
    },
    3900: {
      id: "3900",
      i: 2,
      s: 2,
      ss: 3,
    },
    3903: {
      id: "3903",
      i: 3,
      s: 20,
      ss: 38,
    },
    3908: {
      id: "3908",
      i: 3,
      s: 20,
      ss: 29,
    },
    3913: {
      id: "3913",
      i: 2,
      s: 2,
      ss: 47,
    },
    3918: {
      id: "3918",
      i: 6,
      s: 7,
      ss: 11,
    },
    3919: {
      id: "3919",
      i: 9,
      s: 10,
      ss: 19,
    },
    3928: {
      id: "3928",
      i: 2,
      s: 18,
      ss: 69,
    },
    3933: {
      id: "3933",
      i: 5,
      s: 5,
      ss: 7,
    },
    3938: {
      id: "3938",
      i: 3,
      s: 20,
      ss: 63,
    },
    3939: {
      id: "3939",
      i: 7,
      s: 8,
      ss: 81,
    },
    3948: {
      id: "3948",
      i: 11,
      s: 19,
      ss: 27,
    },
    3958: {
      id: "3958",
      i: 3,
      s: 20,
      ss: 29,
    },
    3963: {
      id: "3963",
      i: 3,
      s: 20,
      ss: 38,
    },
    3968: {
      id: "3968",
      i: 3,
      s: 3,
      ss: 4,
    },
    3969: {
      id: "3969",
      i: 10,
      s: 15,
      ss: 21,
    },
    3978: {
      id: "3978",
      i: 6,
      s: 11,
      ss: 14,
    },
    3983: {
      id: "3983",
      i: 7,
      s: 21,
      ss: 76,
    },
    3988: {
      id: "3988",
      i: 3,
      s: 3,
      ss: 4,
    },
    3989: {
      id: "3989",
      i: 1,
      s: 1,
      ss: 39,
    },
    3990: {
      id: "3990",
      i: 2,
      s: 2,
      ss: 3,
    },
    3991: {
      id: "3991",
      i: 10,
      s: 26,
      ss: 78,
    },
    3992: {
      id: "3992",
      i: 2,
      s: 2,
      ss: 47,
    },
    3993: {
      id: "3993",
      i: 7,
      s: 8,
      ss: 60,
    },
    3996: {
      id: "3996",
      i: 9,
      s: 10,
      ss: 57,
    },
    3997: {
      id: "3997",
      i: 10,
      s: 26,
      ss: 48,
    },
    3998: {
      id: "3998",
      i: 6,
      s: 27,
      ss: 50,
    },
    3999: {
      id: "3999",
      i: 8,
      s: 14,
      ss: 20,
    },
    4332: {
      id: "4332",
      i: "",
      s: "",
      ss: 85,
    },
    4333: {
      id: "4333",
      i: "",
      s: "",
      ss: 85,
    },
    4335: {
      id: "4335",
      i: "",
      s: "",
      ss: 85,
    },
    4336: {
      id: "4336",
      i: "",
      s: "",
      ss: 85,
    },
    4337: {
      id: "4337",
      i: "",
      s: "",
      ss: 85,
    },
    4338: {
      id: "4338",
      i: "",
      s: "",
      ss: 85,
    },
    6030: {
      id: "6030",
      i: 3,
      s: 20,
      ss: 31,
    },
    6033: {
      id: "6033",
      i: 10,
      s: 26,
      ss: 48,
    },
    6036: {
      id: "6036",
      i: 9,
      s: 10,
      ss: 19,
    },
    6038: {
      id: "6038",
      i: 2,
      s: 18,
      ss: 56,
    },
    6049: {
      id: "6049",
      i: 2,
      s: 2,
      ss: 47,
    },
    6055: {
      id: "6055",
      i: 8,
      s: 9,
      ss: 95,
    },
    6058: {
      id: "6058",
      i: 3,
      s: 20,
      ss: 29,
    },
    6060: {
      id: "6060",
      i: 3,
      s: 28,
      ss: 67,
    },
    6063: {
      id: "6063",
      i: 2,
      s: 18,
      ss: 56,
    },
    6066: {
      id: "6066",
      i: 3,
      s: 20,
      ss: 29,
    },
    6068: {
      id: "6068",
      i: 6,
      s: 11,
      ss: 14,
    },
    6069: {
      id: "6069",
      i: 3,
      s: 20,
      ss: 38,
    },
    6078: {
      id: "6078",
      i: 5,
      s: 30,
      ss: 73,
    },
    6080: {
      id: "6080",
      i: 2,
      s: 18,
      ss: 56,
    },
    6083: {
      id: "6083",
      i: 9,
      s: 25,
      ss: 90,
    },
    6088: {
      id: "6088",
      i: 10,
      s: 26,
      ss: 48,
    },
    6090: {
      id: "6090",
      i: 2,
      s: 2,
      ss: 5,
    },
    6093: {
      id: "6093",
      i: 2,
      s: 2,
      ss: 47,
    },
    6098: {
      id: "6098",
      i: 2,
      s: 2,
      ss: 47,
    },
    6099: {
      id: "6099",
      i: 3,
      s: 20,
      ss: 29,
    },
    6100: {
      id: "6100",
      i: 6,
      s: 11,
      ss: 34,
    },
    6108: {
      id: "6108",
      i: 5,
      s: 5,
      ss: 7,
    },
    6110: {
      id: "6110",
      i: 6,
      s: 22,
      ss: 51,
    },
    6111: {
      id: "6111",
      i: 2,
      s: 2,
      ss: 3,
    },
    6113: {
      id: "6113",
      i: 6,
      s: 6,
      ss: 44,
    },
    6116: {
      id: "6116",
      i: 6,
      s: 27,
      ss: 50,
    },
    6117: {
      id: "6117",
      i: 9,
      s: 25,
      ss: 53,
    },
    6118: {
      id: "6118",
      i: 5,
      s: 5,
      ss: 94,
    },
    6119: {
      id: "6119",
      i: 9,
      s: 25,
      ss: 53,
    },
    6122: {
      id: "6122",
      i: 3,
      s: 3,
      ss: 4,
    },
    6123: {
      id: "6123",
      i: 9,
      s: 25,
      ss: 90,
    },
    6127: {
      id: "6127",
      i: 5,
      s: 5,
      ss: 94,
    },
    6128: {
      id: "6128",
      i: 7,
      s: 8,
      ss: 60,
    },
    6133: {
      id: "6133",
      i: 10,
      s: 26,
      ss: 48,
    },
    6136: {
      id: "6136",
      i: 1,
      s: 1,
      ss: 71,
    },
    6138: {
      id: "6138",
      i: 3,
      s: 3,
      ss: 4,
    },
    6158: {
      id: "6158",
      i: 2,
      s: 2,
      ss: 3,
    },
    6160: {
      id: "6160",
      i: 5,
      s: 5,
      ss: 94,
    },
    6161: {
      id: "6161",
      i: 3,
      s: 28,
      ss: 67,
    },
    6162: {
      id: "6162",
      i: 6,
      s: 16,
      ss: 23,
    },
    6163: {
      id: "6163",
      i: 9,
      s: 10,
      ss: 45,
    },
    6166: {
      id: "6166",
      i: 2,
      s: 2,
      ss: 3,
    },
    6168: {
      id: "6168",
      i: 10,
      s: 26,
      ss: 48,
    },
    6169: {
      id: "6169",
      i: 6,
      s: 11,
      ss: 14,
    },
    6178: {
      id: "6178",
      i: 3,
      s: 20,
      ss: 29,
    },
    6182: {
      id: "6182",
      i: 2,
      s: 18,
      ss: 56,
    },
    6183: {
      id: "6183",
      i: 8,
      s: 14,
      ss: 33,
    },
    6185: {
      id: "6185",
      i: 5,
      s: 5,
      ss: 94,
    },
    6186: {
      id: "6186",
      i: 8,
      s: 9,
      ss: 87,
    },
    6188: {
      id: "6188",
      i: 10,
      s: 26,
      ss: 48,
    },
    6189: {
      id: "6189",
      i: 2,
      s: 18,
      ss: 56,
    },
    6190: {
      id: "6190",
      i: 3,
      s: 3,
      ss: 4,
    },
    6193: {
      id: "6193",
      i: 2,
      s: 18,
      ss: 25,
    },
    6196: {
      id: "6196",
      i: 3,
      s: 3,
      ss: 4,
    },
    6198: {
      id: "6198",
      i: 9,
      s: 25,
      ss: 53,
    },
    6199: {
      id: "6199",
      i: 3,
      s: 3,
      ss: 4,
    },
    6288: {
      id: "6288",
      i: 6,
      s: 27,
      ss: 50,
    },
    6600: {
      id: "6600",
      i: 5,
      s: 5,
      ss: 7,
    },
    6601: {
      id: "6601",
      i: 6,
      s: 11,
      ss: 34,
    },
    6606: {
      id: "6606",
      i: 5,
      s: 30,
      ss: 92,
    },
    6608: {
      id: "6608",
      i: 10,
      s: 15,
      ss: 21,
    },
    6609: {
      id: "6609",
      i: 5,
      s: 30,
      ss: 92,
    },
    6611: {
      id: "6611",
      i: 2,
      s: 2,
      ss: 3,
    },
    6616: {
      id: "6616",
      i: 7,
      s: 21,
      ss: 30,
    },
    6618: {
      id: "6618",
      i: 5,
      s: 5,
      ss: 7,
    },
    6622: {
      id: "6622",
      i: 5,
      s: 5,
      ss: 7,
    },
    6626: {
      id: "6626",
      i: 2,
      s: 2,
      ss: 47,
    },
    6628: {
      id: "6628",
      i: 5,
      s: 5,
      ss: 94,
    },
    6666: {
      id: "6666",
      i: 2,
      s: 2,
      ss: 47,
    },
    6668: {
      id: "6668",
      i: 2,
      s: 2,
      ss: 47,
    },
    6669: {
      id: "6669",
      i: 5,
      s: 30,
      ss: 92,
    },
    6677: {
      id: "6677",
      i: 2,
      s: 2,
      ss: 47,
    },
    6690: {
      id: "6690",
      i: 6,
      s: 12,
      ss: 68,
    },
    6699: {
      id: "6699",
      i: 5,
      s: 30,
      ss: 73,
    },
    6805: {
      id: "6805",
      i: 9,
      s: 10,
      ss: 57,
    },
    6806: {
      id: "6806",
      i: 3,
      s: 20,
      ss: 29,
    },
    6808: {
      id: "6808",
      i: 8,
      s: 13,
      ss: 17,
    },
    6811: {
      id: "6811",
      i: 6,
      s: 7,
      ss: 22,
    },
    6812: {
      id: "6812",
      i: 6,
      s: 11,
      ss: 34,
    },
    6816: {
      id: "6816",
      i: 2,
      s: 18,
      ss: 69,
    },
    6818: {
      id: "6818",
      i: 3,
      s: 3,
      ss: 4,
    },
    6819: {
      id: "6819",
      i: 6,
      s: 11,
      ss: 34,
    },
    6820: {
      id: "6820",
      i: 10,
      s: 15,
      ss: 35,
    },
    6822: {
      id: "6822",
      i: 6,
      s: 12,
      ss: 83,
    },
    6823: {
      id: "6823",
      i: 4,
      s: 4,
      ss: 6,
    },
    6826: {
      id: "6826",
      i: 5,
      s: 5,
      ss: 94,
    },
    6828: {
      id: "6828",
      i: 1,
      s: 1,
      ss: 2,
    },
    6829: {
      id: "6829",
      i: 2,
      s: 18,
      ss: 56,
    },
    6830: {
      id: "6830",
      i: 6,
      s: 16,
      ss: 23,
    },
    6833: {
      id: "6833",
      i: 5,
      s: 5,
      ss: 7,
    },
    6836: {
      id: "6836",
      i: 8,
      s: 9,
      ss: 26,
    },
    6837: {
      id: "6837",
      i: 3,
      s: 20,
      ss: 29,
    },
    6838: {
      id: "6838",
      i: 6,
      s: 27,
      ss: 49,
    },
    6839: {
      id: "6839",
      i: 1,
      s: 1,
      ss: 71,
    },
    6855: {
      id: "6855",
      i: 5,
      s: 5,
      ss: 94,
    },
    6858: {
      id: "6858",
      i: 6,
      s: 12,
      ss: 15,
    },
    6860: {
      id: "6860",
      i: 10,
      s: 15,
      ss: 35,
    },
    6862: {
      id: "6862",
      i: 6,
      s: 7,
      ss: 22,
    },
    6865: {
      id: "6865",
      i: 9,
      s: 10,
      ss: 58,
    },
    6866: {
      id: "6866",
      i: 3,
      s: 20,
      ss: 38,
    },
    6868: {
      id: "6868",
      i: 8,
      s: 9,
      ss: 66,
    },
    6869: {
      id: "6869",
      i: 10,
      s: 26,
      ss: 48,
    },
    6877: {
      id: "6877",
      i: 3,
      s: 20,
      ss: 29,
    },
    6878: {
      id: "6878",
      i: 2,
      s: 2,
      ss: 3,
    },
    6880: {
      id: "6880",
      i: 9,
      s: 25,
      ss: 90,
    },
    6881: {
      id: "6881",
      i: 3,
      s: 20,
      ss: 29,
    },
    6882: {
      id: "6882",
      i: 6,
      s: 7,
      ss: 32,
    },
    6885: {
      id: "6885",
      i: 11,
      s: 19,
      ss: 27,
    },
    6886: {
      id: "6886",
      i: 3,
      s: 20,
      ss: 29,
    },
    6888: {
      id: "6888",
      i: 2,
      s: 18,
      ss: 69,
    },
    6889: {
      id: "6889",
      i: 6,
      s: 7,
      ss: 11,
    },
    6890: {
      id: "6890",
      i: 7,
      s: 8,
      ss: 10,
    },
    6893: {
      id: "6893",
      i: 5,
      s: 5,
      ss: 7,
    },
    6896: {
      id: "6896",
      i: 5,
      s: 5,
      ss: 7,
    },
    6898: {
      id: "6898",
      i: 9,
      s: 17,
      ss: 24,
    },
    6899: {
      id: "6899",
      i: 10,
      s: 15,
      ss: 35,
    },
    6900: {
      id: "6900",
      i: 2,
      s: 2,
      ss: 3,
    },
    6908: {
      id: "6908",
      i: 10,
      s: 23,
      ss: 37,
    },
    6909: {
      id: "6909",
      i: 6,
      s: 22,
      ss: 54,
    },
    6913: {
      id: "6913",
      i: 6,
      s: 11,
      ss: 14,
    },
    6918: {
      id: "6918",
      i: 6,
      s: 12,
      ss: 15,
    },
    6919: {
      id: "6919",
      i: 6,
      s: 11,
      ss: 34,
    },
    6928: {
      id: "6928",
      i: 6,
      s: 16,
      ss: 23,
    },
    6933: {
      id: "6933",
      i: 10,
      s: 15,
      ss: 35,
    },
    6939: {
      id: "6939",
      i: 9,
      s: 10,
      ss: 19,
    },
    6958: {
      id: "6958",
      i: 2,
      s: 2,
      ss: 47,
    },
    6966: {
      id: "6966",
      i: 6,
      s: 11,
      ss: 34,
    },
    6968: {
      id: "6968",
      i: 2,
      s: 2,
      ss: 3,
    },
    6969: {
      id: "6969",
      i: 6,
      s: 12,
      ss: 18,
    },
    6978: {
      id: "6978",
      i: 5,
      s: 5,
      ss: 94,
    },
    6988: {
      id: "6988",
      i: 6,
      s: 6,
      ss: 44,
    },
    6989: {
      id: "6989",
      i: 2,
      s: 2,
      ss: 47,
    },
    6993: {
      id: "6993",
      i: 6,
      s: 12,
      ss: 83,
    },
    6996: {
      id: "6996",
      i: 5,
      s: 5,
      ss: 7,
    },
    6998: {
      id: "6998",
      i: 5,
      s: 5,
      ss: 94,
    },
    6999: {
      id: "6999",
      i: 2,
      s: 2,
      ss: 3,
    },
    7200: {
      id: "7200",
      i: "",
      s: "",
      ss: 85,
    },
    7226: {
      id: "7226",
      i: "",
      s: "",
      ss: 85,
    },
    7230: {
      id: "7230",
      i: "",
      s: "",
      ss: 85,
    },
    7231: {
      id: "7231",
      i: "",
      s: "",
      ss: 85,
    },
    7232: {
      id: "7232",
      i: "",
      s: "",
      ss: 85,
    },
    7233: {
      id: "7233",
      i: "",
      s: "",
      ss: 85,
    },
    7248: {
      id: "7248",
      i: "",
      s: "",
      ss: 85,
    },
    7261: {
      id: "7261",
      i: "",
      s: "",
      ss: 85,
    },
    7266: {
      id: "7266",
      i: "",
      s: "",
      ss: 85,
    },
    7272: {
      id: "7272",
      i: "",
      s: "",
      ss: 85,
    },
    7288: {
      id: "7288",
      i: "",
      s: "",
      ss: 85,
    },
    7299: {
      id: "7299",
      i: "",
      s: "",
      ss: 85,
    },
    7300: {
      id: "7300",
      i: "",
      s: "",
      ss: 85,
    },
    7321: {
      id: "7321",
      i: "",
      s: "",
      ss: 85,
    },
    7322: {
      id: "7322",
      i: "",
      s: "",
      ss: 85,
    },
    7331: {
      id: "7331",
      i: "",
      s: "",
      ss: 85,
    },
    7332: {
      id: "7332",
      i: "",
      s: "",
      ss: 85,
    },
    7333: {
      id: "7333",
      i: "",
      s: "",
      ss: 85,
    },
    7336: {
      id: "7336",
      i: "",
      s: "",
      ss: 85,
    },
    7345: {
      id: "7345",
      i: "",
      s: "",
      ss: 85,
    },
    7348: {
      id: "7348",
      i: "",
      s: "",
      ss: 85,
    },
    7362: {
      id: "7362",
      i: "",
      s: "",
      ss: 85,
    },
    7373: {
      id: "7373",
      i: "",
      s: "",
      ss: 85,
    },
    7374: {
      id: "7374",
      i: "",
      s: "",
      ss: 85,
    },
    7500: {
      id: "7500",
      i: "",
      s: "",
      ss: 85,
    },
    7522: {
      id: "7522",
      i: "",
      s: "",
      ss: 85,
    },
    7552: {
      id: "7552",
      i: "",
      s: "",
      ss: 85,
    },
    7568: {
      id: "7568",
      i: "",
      s: "",
      ss: 85,
    },
    7588: {
      id: "7588",
      i: "",
      s: "",
      ss: 85,
    },
    8001: {
      id: "8001",
      i: 3,
      s: 20,
      ss: 38,
    },
    8003: {
      id: "8003",
      i: 6,
      s: 6,
      ss: 44,
    },
    8005: {
      id: "8005",
      i: 6,
      s: 12,
      ss: 18,
    },
    8006: {
      id: "8006",
      i: 6,
      s: 6,
      ss: 8,
    },
    8007: {
      id: "8007",
      i: 7,
      s: 8,
      ss: 81,
    },
    8009: {
      id: "8009",
      i: 11,
      s: 24,
      ss: 40,
    },
    8011: {
      id: "8011",
      i: 11,
      s: 24,
      ss: 40,
    },
    8013: {
      id: "8013",
      i: 10,
      s: 15,
      ss: 21,
    },
    8017: {
      id: "8017",
      i: 10,
      s: 15,
      ss: 21,
    },
    8018: {
      id: "8018",
      i: 10,
      s: 15,
      ss: 35,
    },
    8019: {
      id: "8019",
      i: 3,
      s: 20,
      ss: 38,
    },
    8020: {
      id: "8020",
      i: 9,
      s: 25,
      ss: 53,
    },
    8021: {
      id: "8021",
      i: 2,
      s: 18,
      ss: 56,
    },
    8022: {
      id: "8022",
      i: 2,
      s: 2,
      ss: 3,
    },
    8023: {
      id: "8023",
      i: 2,
      s: 18,
      ss: 56,
    },
    8025: {
      id: "8025",
      i: 3,
      s: 20,
      ss: 31,
    },
    8026: {
      id: "8026",
      i: 5,
      s: 5,
      ss: 7,
    },
    8027: {
      id: "8027",
      i: 6,
      s: 11,
      ss: 34,
    },
    8028: {
      id: "8028",
      i: 7,
      s: 8,
      ss: 60,
    },
    8029: {
      id: "8029",
      i: 3,
      s: 20,
      ss: 29,
    },
    8030: {
      id: "8030",
      i: 2,
      s: 2,
      ss: 5,
    },
    8031: {
      id: "8031",
      i: 6,
      s: 11,
      ss: 34,
    },
    8032: {
      id: "8032",
      i: 6,
      s: 7,
      ss: 86,
    },
    8033: {
      id: "8033",
      i: 10,
      s: 15,
      ss: 21,
    },
    8035: {
      id: "8035",
      i: 9,
      s: 25,
      ss: 90,
    },
    8036: {
      id: "8036",
      i: 10,
      s: 15,
      ss: 21,
    },
    8037: {
      id: "8037",
      i: 5,
      s: 30,
      ss: 73,
    },
    8039: {
      id: "8039",
      i: 2,
      s: 18,
      ss: 56,
    },
    8040: {
      id: "8040",
      i: 2,
      s: 18,
      ss: 56,
    },
    8041: {
      id: "8041",
      i: 10,
      s: 15,
      ss: 52,
    },
    8042: {
      id: "8042",
      i: 6,
      s: 11,
      ss: 34,
    },
    8043: {
      id: "8043",
      i: 10,
      s: 26,
      ss: 48,
    },
    8045: {
      id: "8045",
      i: 10,
      s: 15,
      ss: 21,
    },
    8047: {
      id: "8047",
      i: 9,
      s: 17,
      ss: 82,
    },
    8048: {
      id: "8048",
      i: 10,
      s: 15,
      ss: 21,
    },
    8049: {
      id: "8049",
      i: 5,
      s: 5,
      ss: 7,
    },
    8050: {
      id: "8050",
      i: 10,
      s: 15,
      ss: 21,
    },
    8051: {
      id: "8051",
      i: 10,
      s: 26,
      ss: 78,
    },
    8052: {
      id: "8052",
      i: 6,
      s: 7,
      ss: 86,
    },
    8053: {
      id: "8053",
      i: 2,
      s: 18,
      ss: 56,
    },
    8055: {
      id: "8055",
      i: 6,
      s: 11,
      ss: 14,
    },
    8056: {
      id: "8056",
      i: 6,
      s: 7,
      ss: 22,
    },
    8057: {
      id: "8057",
      i: 0,
      s: 0,
      ss: 0,
    },
    8059: {
      id: "8059",
      i: 2,
      s: 18,
      ss: 69,
    },
    8060: {
      id: "8060",
      i: 10,
      s: 15,
      ss: 21,
    },
    8062: {
      id: "8062",
      i: 10,
      s: 15,
      ss: 21,
    },
    8063: {
      id: "8063",
      i: 3,
      s: 20,
      ss: 38,
    },
    8065: {
      id: "8065",
      i: 10,
      s: 15,
      ss: 21,
    },
    8066: {
      id: "8066",
      i: 10,
      s: 26,
      ss: 78,
    },
    8067: {
      id: "8067",
      i: 6,
      s: 11,
      ss: 34,
    },
    8069: {
      id: "8069",
      i: 6,
      s: 7,
      ss: 32,
    },
    8070: {
      id: "8070",
      i: 9,
      s: 10,
      ss: 19,
    },
    8071: {
      id: "8071",
      i: 10,
      s: 15,
      ss: 52,
    },
    8072: {
      id: "8072",
      i: 3,
      s: 20,
      ss: 63,
    },
    8073: {
      id: "8073",
      i: 9,
      s: 10,
      ss: 19,
    },
    8075: {
      id: "8075",
      i: 6,
      s: 6,
      ss: 77,
    },
    8076: {
      id: "8076",
      i: 10,
      s: 15,
      ss: 52,
    },
    8078: {
      id: "8078",
      i: 6,
      s: 6,
      ss: 77,
    },
    8079: {
      id: "8079",
      i: 9,
      s: 17,
      ss: 82,
    },
    8080: {
      id: "8080",
      i: 9,
      s: 10,
      ss: 19,
    },
    8081: {
      id: "8081",
      i: 10,
      s: 15,
      ss: 21,
    },
    8082: {
      id: "8082",
      i: 6,
      s: 6,
      ss: 77,
    },
    8083: {
      id: "8083",
      i: 10,
      s: 15,
      ss: 52,
    },
    8086: {
      id: "8086",
      i: 10,
      s: 15,
      ss: 52,
    },
    8087: {
      id: "8087",
      i: 6,
      s: 6,
      ss: 44,
    },
    8088: {
      id: "8088",
      i: 10,
      s: 15,
      ss: 35,
    },
    8089: {
      id: "8089",
      i: 8,
      s: 9,
      ss: 66,
    },
    8091: {
      id: "8091",
      i: 6,
      s: 6,
      ss: 44,
    },
    8092: {
      id: "8092",
      i: 10,
      s: 15,
      ss: 21,
    },
    8093: {
      id: "8093",
      i: 6,
      s: 6,
      ss: 44,
    },
    8095: {
      id: "8095",
      i: 6,
      s: 7,
      ss: 32,
    },
    8096: {
      id: "8096",
      i: 6,
      s: 7,
      ss: 22,
    },
    8098: {
      id: "8098",
      i: 3,
      s: 20,
      ss: 29,
    },
    8100: {
      id: "8100",
      i: 10,
      s: 15,
      ss: 52,
    },
    8101: {
      id: "8101",
      i: 6,
      s: 22,
      ss: 89,
    },
    8103: {
      id: "8103",
      i: 6,
      s: 6,
      ss: 77,
    },
    8106: {
      id: "8106",
      i: 10,
      s: 15,
      ss: 35,
    },
    8107: {
      id: "8107",
      i: 6,
      s: 27,
      ss: 50,
    },
    8108: {
      id: "8108",
      i: 6,
      s: 11,
      ss: 34,
    },
    8109: {
      id: "8109",
      i: 3,
      s: 28,
      ss: 67,
    },
    8111: {
      id: "8111",
      i: 9,
      s: 10,
      ss: 57,
    },
    8112: {
      id: "8112",
      i: 6,
      s: 6,
      ss: 44,
    },
    8113: {
      id: "8113",
      i: 9,
      s: 10,
      ss: 19,
    },
    8115: {
      id: "8115",
      i: 9,
      s: 10,
      ss: 19,
    },
    8116: {
      id: "8116",
      i: 8,
      s: 9,
      ss: 61,
    },
    8117: {
      id: "8117",
      i: 1,
      s: 1,
      ss: 2,
    },
    8118: {
      id: "8118",
      i: 6,
      s: 12,
      ss: 83,
    },
    8119: {
      id: "8119",
      i: 10,
      s: 15,
      ss: 35,
    },
    8120: {
      id: "8120",
      i: 6,
      s: 7,
      ss: 22,
    },
    8121: {
      id: "8121",
      i: 6,
      s: 6,
      ss: 44,
    },
    8123: {
      id: "8123",
      i: 6,
      s: 22,
      ss: 54,
    },
    8125: {
      id: "8125",
      i: 2,
      s: 18,
      ss: 56,
    },
    8126: {
      id: "8126",
      i: 6,
      s: 22,
      ss: 54,
    },
    8128: {
      id: "8128",
      i: 9,
      s: 10,
      ss: 57,
    },
    8130: {
      id: "8130",
      i: 6,
      s: 6,
      ss: 8,
    },
    8131: {
      id: "8131",
      i: 10,
      s: 15,
      ss: 35,
    },
    8132: {
      id: "8132",
      i: 11,
      s: 24,
      ss: 40,
    },
    8133: {
      id: "8133",
      i: 9,
      s: 10,
      ss: 19,
    },
    8135: {
      id: "8135",
      i: 6,
      s: 16,
      ss: 23,
    },
    8136: {
      id: "8136",
      i: 6,
      s: 12,
      ss: 83,
    },
    8137: {
      id: "8137",
      i: 9,
      s: 10,
      ss: 58,
    },
    8139: {
      id: "8139",
      i: 7,
      s: 21,
      ss: 30,
    },
    8140: {
      id: "8140",
      i: 2,
      s: 18,
      ss: 69,
    },
    8143: {
      id: "8143",
      i: 5,
      s: 30,
      ss: 73,
    },
    8146: {
      id: "8146",
      i: 8,
      s: 9,
      ss: 61,
    },
    8147: {
      id: "8147",
      i: 4,
      s: 4,
      ss: 88,
    },
    8148: {
      id: "8148",
      i: 10,
      s: 15,
      ss: 35,
    },
    8149: {
      id: "8149",
      i: 3,
      s: 20,
      ss: 63,
    },
    8150: {
      id: "8150",
      i: 6,
      s: 12,
      ss: 83,
    },
    8151: {
      id: "8151",
      i: 9,
      s: 10,
      ss: 19,
    },
    8152: {
      id: "8152",
      i: 9,
      s: 10,
      ss: 16,
    },
    8153: {
      id: "8153",
      i: 6,
      s: 6,
      ss: 91,
    },
    8155: {
      id: "8155",
      i: 2,
      s: 2,
      ss: 3,
    },
    8156: {
      id: "8156",
      i: 6,
      s: 7,
      ss: 11,
    },
    8158: {
      id: "8158",
      i: 5,
      s: 30,
      ss: 92,
    },
    8159: {
      id: "8159",
      i: 10,
      s: 26,
      ss: 78,
    },
    8160: {
      id: "8160",
      i: 6,
      s: 11,
      ss: 14,
    },
    8161: {
      id: "8161",
      i: 5,
      s: 30,
      ss: 73,
    },
    8162: {
      id: "8162",
      i: 7,
      s: 29,
      ss: 72,
    },
    8163: {
      id: "8163",
      i: 8,
      s: 13,
      ss: 84,
    },
    8166: {
      id: "8166",
      i: 9,
      s: 17,
      ss: 82,
    },
    8167: {
      id: "8167",
      i: 10,
      s: 26,
      ss: 48,
    },
    8168: {
      id: "8168",
      i: 3,
      s: 20,
      ss: 63,
    },
    8169: {
      id: "8169",
      i: 9,
      s: 10,
      ss: 57,
    },
    8170: {
      id: "8170",
      i: 2,
      s: 18,
      ss: 56,
    },
    8171: {
      id: "8171",
      i: 10,
      s: 26,
      ss: 78,
    },
    8172: {
      id: "8172",
      i: 6,
      s: 6,
      ss: 77,
    },
    8173: {
      id: "8173",
      i: 2,
      s: 18,
      ss: 56,
    },
    8175: {
      id: "8175",
      i: 6,
      s: 6,
      ss: 77,
    },
    8176: {
      id: "8176",
      i: 5,
      s: 30,
      ss: 73,
    },
    8178: {
      id: "8178",
      i: 10,
      s: 15,
      ss: 21,
    },
    8179: {
      id: "8179",
      i: 8,
      s: 14,
      ss: 74,
    },
    8181: {
      id: "8181",
      i: 2,
      s: 2,
      ss: 47,
    },
    8186: {
      id: "8186",
      i: 7,
      s: 21,
      ss: 41,
    },
    8187: {
      id: "8187",
      i: 3,
      s: 20,
      ss: 38,
    },
    8188: {
      id: "8188",
      i: 2,
      s: 18,
      ss: 69,
    },
    8189: {
      id: "8189",
      i: 7,
      s: 21,
      ss: 76,
    },
    8191: {
      id: "8191",
      i: 7,
      s: 21,
      ss: 41,
    },
    8192: {
      id: "8192",
      i: 10,
      s: 26,
      ss: 78,
    },
    8193: {
      id: "8193",
      i: 3,
      s: 20,
      ss: 63,
    },
    8195: {
      id: "8195",
      i: 6,
      s: 27,
      ss: 50,
    },
    8196: {
      id: "8196",
      i: 1,
      s: 1,
      ss: 71,
    },
    8198: {
      id: "8198",
      i: 10,
      s: 15,
      ss: 21,
    },
    8200: {
      id: "8200",
      i: 6,
      s: 22,
      ss: 36,
    },
    8201: {
      id: "8201",
      i: 6,
      s: 11,
      ss: 34,
    },
    8202: {
      id: "8202",
      i: 6,
      s: 6,
      ss: 44,
    },
    8203: {
      id: "8203",
      i: 9,
      s: 17,
      ss: 82,
    },
    8205: {
      id: "8205",
      i: 10,
      s: 15,
      ss: 21,
    },
    8206: {
      id: "8206",
      i: 6,
      s: 11,
      ss: 14,
    },
    8208: {
      id: "8208",
      i: 2,
      s: 18,
      ss: 56,
    },
    8209: {
      id: "8209",
      i: 2,
      s: 18,
      ss: 56,
    },
    8210: {
      id: "8210",
      i: 3,
      s: 20,
      ss: 29,
    },
    8211: {
      id: "8211",
      i: 6,
      s: 27,
      ss: 55,
    },
    8213: {
      id: "8213",
      i: 6,
      s: 7,
      ss: 22,
    },
    8215: {
      id: "8215",
      i: 3,
      s: 20,
      ss: 38,
    },
    8216: {
      id: "8216",
      i: 2,
      s: 18,
      ss: 69,
    },
    8217: {
      id: "8217",
      i: 2,
      s: 18,
      ss: 69,
    },
    8218: {
      id: "8218",
      i: 9,
      s: 10,
      ss: 19,
    },
    8219: {
      id: "8219",
      i: 6,
      s: 27,
      ss: 70,
    },
    8220: {
      id: "8220",
      i: 6,
      s: 6,
      ss: 77,
    },
    8221: {
      id: "8221",
      i: 3,
      s: 20,
      ss: 63,
    },
    8222: {
      id: "8222",
      i: 6,
      s: 12,
      ss: 83,
    },
    8223: {
      id: "8223",
      i: 3,
      s: 20,
      ss: 38,
    },
    8225: {
      id: "8225",
      i: 5,
      s: 5,
      ss: 7,
    },
    8226: {
      id: "8226",
      i: 3,
      s: 20,
      ss: 29,
    },
    8227: {
      id: "8227",
      i: 2,
      s: 18,
      ss: 25,
    },
    8228: {
      id: "8228",
      i: 6,
      s: 6,
      ss: 77,
    },
    8229: {
      id: "8229",
      i: 10,
      s: 15,
      ss: 21,
    },
    8231: {
      id: "8231",
      i: 10,
      s: 23,
      ss: 37,
    },
    8232: {
      id: "8232",
      i: 6,
      s: 7,
      ss: 22,
    },
    8235: {
      id: "8235",
      i: 10,
      s: 15,
      ss: 21,
    },
    8237: {
      id: "8237",
      i: 6,
      s: 7,
      ss: 9,
    },
    8238: {
      id: "8238",
      i: 6,
      s: 6,
      ss: 44,
    },
    8239: {
      id: "8239",
      i: 3,
      s: 20,
      ss: 38,
    },
    8241: {
      id: "8241",
      i: 8,
      s: 9,
      ss: 66,
    },
    8242: {
      id: "8242",
      i: 2,
      s: 2,
      ss: 47,
    },
    8245: {
      id: "8245",
      i: 10,
      s: 26,
      ss: 48,
    },
    8246: {
      id: "8246",
      i: 9,
      s: 10,
      ss: 58,
    },
    8247: {
      id: "8247",
      i: 5,
      s: 5,
      ss: 94,
    },
    8249: {
      id: "8249",
      i: 10,
      s: 15,
      ss: 21,
    },
    8250: {
      id: "8250",
      i: 11,
      s: 19,
      ss: 27,
    },
    8255: {
      id: "8255",
      i: 3,
      s: 20,
      ss: 38,
    },
    8257: {
      id: "8257",
      i: 10,
      s: 23,
      ss: 37,
    },
    8258: {
      id: "8258",
      i: 9,
      s: 10,
      ss: 58,
    },
    8259: {
      id: "8259",
      i: 2,
      s: 18,
      ss: 56,
    },
    8260: {
      id: "8260",
      i: 6,
      s: 11,
      ss: 34,
    },
    8262: {
      id: "8262",
      i: 2,
      s: 18,
      ss: 56,
    },
    8265: {
      id: "8265",
      i: 6,
      s: 16,
      ss: 62,
    },
    8266: {
      id: "8266",
      i: 10,
      s: 26,
      ss: 48,
    },
    8267: {
      id: "8267",
      i: 10,
      s: 15,
      ss: 35,
    },
    8268: {
      id: "8268",
      i: 2,
      s: 18,
      ss: 56,
    },
    8269: {
      id: "8269",
      i: 6,
      s: 27,
      ss: 49,
    },
    8270: {
      id: "8270",
      i: 11,
      s: 24,
      ss: 40,
    },
    8271: {
      id: "8271",
      i: 2,
      s: 2,
      ss: 5,
    },
    8272: {
      id: "8272",
      i: 6,
      s: 7,
      ss: 22,
    },
    8275: {
      id: "8275",
      i: 2,
      s: 18,
      ss: 56,
    },
    8277: {
      id: "8277",
      i: 7,
      s: 21,
      ss: 41,
    },
    8279: {
      id: "8279",
      i: 6,
      s: 7,
      ss: 11,
    },
    8280: {
      id: "8280",
      i: 6,
      s: 6,
      ss: 91,
    },
    8281: {
      id: "8281",
      i: 8,
      s: 13,
      ss: 84,
    },
    8282: {
      id: "8282",
      i: 10,
      s: 15,
      ss: 35,
    },
    8283: {
      id: "8283",
      i: 6,
      s: 11,
      ss: 34,
    },
    8285: {
      id: "8285",
      i: 6,
      s: 27,
      ss: 49,
    },
    8286: {
      id: "8286",
      i: 10,
      s: 26,
      ss: 48,
    },
    8287: {
      id: "8287",
      i: 10,
      s: 26,
      ss: 78,
    },
    8290: {
      id: "8290",
      i: 10,
      s: 15,
      ss: 35,
    },
    8291: {
      id: "8291",
      i: 9,
      s: 17,
      ss: 24,
    },
    8292: {
      id: "8292",
      i: 9,
      s: 25,
      ss: 90,
    },
    8293: {
      id: "8293",
      i: 6,
      s: 11,
      ss: 34,
    },
    8295: {
      id: "8295",
      i: 3,
      s: 20,
      ss: 63,
    },
    8296: {
      id: "8296",
      i: 6,
      s: 11,
      ss: 34,
    },
    8297: {
      id: "8297",
      i: 6,
      s: 27,
      ss: 50,
    },
    8299: {
      id: "8299",
      i: 7,
      s: 29,
      ss: 72,
    },
    8300: {
      id: "8300",
      i: 6,
      s: 7,
      ss: 22,
    },
    8301: {
      id: "8301",
      i: 8,
      s: 9,
      ss: 61,
    },
    8305: {
      id: "8305",
      i: 2,
      s: 18,
      ss: 69,
    },
    8307: {
      id: "8307",
      i: 5,
      s: 30,
      ss: 73,
    },
    8308: {
      id: "8308",
      i: 6,
      s: 7,
      ss: 9,
    },
    8309: {
      id: "8309",
      i: 6,
      s: 11,
      ss: 34,
    },
    8310: {
      id: "8310",
      i: 9,
      s: 17,
      ss: 82,
    },
    8311: {
      id: "8311",
      i: 10,
      s: 26,
      ss: 48,
    },
    8313: {
      id: "8313",
      i: 0,
      s: 0,
      ss: 0,
    },
    8315: {
      id: "8315",
      i: 6,
      s: 11,
      ss: 34,
    },
    8316: {
      id: "8316",
      i: 2,
      s: 18,
      ss: 69,
    },
    8317: {
      id: "8317",
      i: 6,
      s: 6,
      ss: 8,
    },
    8319: {
      id: "8319",
      i: 10,
      s: 15,
      ss: 21,
    },
    8320: {
      id: "8320",
      i: 6,
      s: 11,
      ss: 34,
    },
    8321: {
      id: "8321",
      i: 2,
      s: 18,
      ss: 69,
    },
    8325: {
      id: "8325",
      i: 10,
      s: 15,
      ss: 52,
    },
    8326: {
      id: "8326",
      i: 1,
      s: 1,
      ss: 39,
    },
    8328: {
      id: "8328",
      i: 6,
      s: 16,
      ss: 23,
    },
    8329: {
      id: "8329",
      i: 5,
      s: 5,
      ss: 7,
    },
    8331: {
      id: "8331",
      i: 7,
      s: 8,
      ss: 60,
    },
    8333: {
      id: "8333",
      i: 3,
      s: 20,
      ss: 63,
    },
    8337: {
      id: "8337",
      i: 4,
      s: 4,
      ss: 6,
    },
    8340: {
      id: "8340",
      i: 3,
      s: 20,
      ss: 63,
    },
    8341: {
      id: "8341",
      i: 2,
      s: 18,
      ss: 56,
    },
    8347: {
      id: "8347",
      i: 11,
      s: 24,
      ss: 40,
    },
    8348: {
      id: "8348",
      i: 9,
      s: 17,
      ss: 82,
    },
    8349: {
      id: "8349",
      i: 2,
      s: 18,
      ss: 25,
    },
    8350: {
      id: "8350",
      i: 3,
      s: 20,
      ss: 29,
    },
    8351: {
      id: "8351",
      i: 8,
      s: 9,
      ss: 26,
    },
    8353: {
      id: "8353",
      i: 10,
      s: 15,
      ss: 35,
    },
    8356: {
      id: "8356",
      i: 2,
      s: 18,
      ss: 69,
    },
    8357: {
      id: "8357",
      i: 5,
      s: 30,
      ss: 73,
    },
    8360: {
      id: "8360",
      i: 2,
      s: 18,
      ss: 56,
    },
    8362: {
      id: "8362",
      i: 8,
      s: 9,
      ss: 26,
    },
    8363: {
      id: "8363",
      i: 6,
      s: 11,
      ss: 14,
    },
    8365: {
      id: "8365",
      i: 3,
      s: 20,
      ss: 63,
    },
    8366: {
      id: "8366",
      i: 2,
      s: 18,
      ss: 69,
    },
    8367: {
      id: "8367",
      i: 6,
      s: 7,
      ss: 22,
    },
    8368: {
      id: "8368",
      i: 6,
      s: 6,
      ss: 77,
    },
    8370: {
      id: "8370",
      i: 6,
      s: 12,
      ss: 83,
    },
    8371: {
      id: "8371",
      i: 6,
      s: 7,
      ss: 22,
    },
    8372: {
      id: "8372",
      i: 5,
      s: 5,
      ss: 7,
    },
    8373: {
      id: "8373",
      i: 2,
      s: 18,
      ss: 56,
    },
    8375: {
      id: "8375",
      i: 9,
      s: 10,
      ss: 19,
    },
    8377: {
      id: "8377",
      i: 6,
      s: 27,
      ss: 55,
    },
    8379: {
      id: "8379",
      i: 10,
      s: 26,
      ss: 78,
    },
    8383: {
      id: "8383",
      i: 9,
      s: 17,
      ss: 24,
    },
    8385: {
      id: "8385",
      i: 6,
      s: 6,
      ss: 8,
    },
    8391: {
      id: "8391",
      i: 9,
      s: 17,
      ss: 24,
    },
    8392: {
      id: "8392",
      i: 6,
      s: 12,
      ss: 83,
    },
    8395: {
      id: "8395",
      i: 6,
      s: 12,
      ss: 83,
    },
    8400: {
      id: "8400",
      i: 6,
      s: 7,
      ss: 11,
    },
    8401: {
      id: "8401",
      i: 6,
      s: 6,
      ss: 44,
    },
    8402: {
      id: "8402",
      i: 2,
      s: 18,
      ss: 56,
    },
    8403: {
      id: "8403",
      i: 6,
      s: 6,
      ss: 44,
    },
    8405: {
      id: "8405",
      i: 6,
      s: 11,
      ss: 34,
    },
    8406: {
      id: "8406",
      i: 6,
      s: 12,
      ss: 15,
    },
    8411: {
      id: "8411",
      i: 2,
      s: 18,
      ss: 56,
    },
    8412: {
      id: "8412",
      i: 6,
      s: 7,
      ss: 22,
    },
    8413: {
      id: "8413",
      i: 8,
      s: 9,
      ss: 26,
    },
    8416: {
      id: "8416",
      i: 9,
      s: 17,
      ss: 24,
    },
    8417: {
      id: "8417",
      i: 6,
      s: 11,
      ss: 14,
    },
    8418: {
      id: "8418",
      i: 6,
      s: 11,
      ss: 34,
    },
    8419: {
      id: "8419",
      i: 6,
      s: 12,
      ss: 18,
    },
    8420: {
      id: "8420",
      i: 10,
      s: 15,
      ss: 21,
    },
    8422: {
      id: "8422",
      i: 2,
      s: 18,
      ss: 56,
    },
    8423: {
      id: "8423",
      i: 2,
      s: 18,
      ss: 56,
    },
    8425: {
      id: "8425",
      i: 9,
      s: 10,
      ss: 16,
    },
    8426: {
      id: "8426",
      i: 2,
      s: 2,
      ss: 47,
    },
    8427: {
      id: "8427",
      i: 2,
      s: 18,
      ss: 25,
    },
    8428: {
      id: "8428",
      i: 6,
      s: 7,
      ss: 22,
    },
    8429: {
      id: "8429",
      i: 6,
      s: 6,
      ss: 44,
    },
    8430: {
      id: "8430",
      i: 9,
      s: 25,
      ss: 90,
    },
    8431: {
      id: "8431",
      i: 6,
      s: 7,
      ss: 86,
    },
    8432: {
      id: "8432",
      i: 6,
      s: 7,
      ss: 22,
    },
    8436: {
      id: "8436",
      i: 6,
      s: 22,
      ss: 36,
    },
    8437: {
      id: "8437",
      i: 5,
      s: 30,
      ss: 73,
    },
    8439: {
      id: "8439",
      i: 3,
      s: 20,
      ss: 63,
    },
    8441: {
      id: "8441",
      i: 6,
      s: 11,
      ss: 34,
    },
    8445: {
      id: "8445",
      i: 2,
      s: 18,
      ss: 56,
    },
    8446: {
      id: "8446",
      i: 6,
      s: 6,
      ss: 77,
    },
    8447: {
      id: "8447",
      i: 6,
      s: 7,
      ss: 22,
    },
    8448: {
      id: "8448",
      i: 9,
      s: 17,
      ss: 24,
    },
    8450: {
      id: "8450",
      i: 9,
      s: 17,
      ss: 24,
    },
    8451: {
      id: "8451",
      i: 8,
      s: 13,
      ss: 84,
    },
    8452: {
      id: "8452",
      i: 3,
      s: 20,
      ss: 63,
    },
    8455: {
      id: "8455",
      i: 2,
      s: 18,
      ss: 56,
    },
    8456: {
      id: "8456",
      i: 6,
      s: 27,
      ss: 50,
    },
    8460: {
      id: "8460",
      i: 2,
      s: 18,
      ss: 56,
    },
    8462: {
      id: "8462",
      i: 6,
      s: 11,
      ss: 34,
    },
    8471: {
      id: "8471",
      i: 6,
      s: 27,
      ss: 55,
    },
    8472: {
      id: "8472",
      i: 6,
      s: 11,
      ss: 34,
    },
    8473: {
      id: "8473",
      i: 6,
      s: 22,
      ss: 36,
    },
    8475: {
      id: "8475",
      i: 6,
      s: 7,
      ss: 22,
    },
    8476: {
      id: "8476",
      i: 8,
      s: 9,
      ss: 26,
    },
    8479: {
      id: "8479",
      i: 11,
      s: 24,
      ss: 40,
    },
    8480: {
      id: "8480",
      i: 6,
      s: 27,
      ss: 55,
    },
    8481: {
      id: "8481",
      i: 9,
      s: 17,
      ss: 24,
    },
    8482: {
      id: "8482",
      i: 9,
      s: 25,
      ss: 90,
    },
    8483: {
      id: "8483",
      i: 6,
      s: 11,
      ss: 34,
    },
    8487: {
      id: "8487",
      i: 10,
      s: 26,
      ss: 48,
    },
    8489: {
      id: "8489",
      i: 9,
      s: 25,
      ss: 90,
    },
    8490: {
      id: "8490",
      i: 9,
      s: 10,
      ss: 19,
    },
    8491: {
      id: "8491",
      i: 8,
      s: 9,
      ss: 26,
    },
    8493: {
      id: "8493",
      i: 6,
      s: 7,
      ss: 22,
    },
    8495: {
      id: "8495",
      i: 6,
      s: 7,
      ss: 22,
    },
    8496: {
      id: "8496",
      i: 8,
      s: 9,
      ss: 26,
    },
    8500: {
      id: "8500",
      i: 6,
      s: 6,
      ss: 44,
    },
    8501: {
      id: "8501",
      i: 2,
      s: 18,
      ss: 56,
    },
    8502: {
      id: "8502",
      i: 9,
      s: 25,
      ss: 53,
    },
    8506: {
      id: "8506",
      i: 9,
      s: 10,
      ss: 19,
    },
    8507: {
      id: "8507",
      i: 6,
      s: 27,
      ss: 50,
    },
    8509: {
      id: "8509",
      i: 8,
      s: 9,
      ss: 61,
    },
    8510: {
      id: "8510",
      i: 6,
      s: 7,
      ss: 22,
    },
    8511: {
      id: "8511",
      i: 9,
      s: 10,
      ss: 19,
    },
    8512: {
      id: "8512",
      i: 6,
      s: 12,
      ss: 83,
    },
    8513: {
      id: "8513",
      i: 5,
      s: 30,
      ss: 92,
    },
    8516: {
      id: "8516",
      i: 2,
      s: 18,
      ss: 69,
    },
    8519: {
      id: "8519",
      i: 6,
      s: 7,
      ss: 22,
    },
    8521: {
      id: "8521",
      i: 6,
      s: 27,
      ss: 55,
    },
    8523: {
      id: "8523",
      i: 2,
      s: 18,
      ss: 69,
    },
    8525: {
      id: "8525",
      i: 3,
      s: 20,
      ss: 38,
    },
    8526: {
      id: "8526",
      i: 2,
      s: 18,
      ss: 56,
    },
    8527: {
      id: "8527",
      i: 6,
      s: 7,
      ss: 22,
    },
    8532: {
      id: "8532",
      i: 2,
      s: 18,
      ss: 56,
    },
    8535: {
      id: "8535",
      i: 2,
      s: 18,
      ss: 56,
    },
    8536: {
      id: "8536",
      i: 11,
      s: 24,
      ss: 40,
    },
    8537: {
      id: "8537",
      i: 6,
      s: 27,
      ss: 70,
    },
    8540: {
      id: "8540",
      i: 3,
      s: 20,
      ss: 29,
    },
    8545: {
      id: "8545",
      i: 6,
      s: 12,
      ss: 15,
    },
    8547: {
      id: "8547",
      i: 6,
      s: 12,
      ss: 83,
    },
    8551: {
      id: "8551",
      i: 6,
      s: 11,
      ss: 34,
    },
    8570: {
      id: "8570",
      i: 6,
      s: 7,
      ss: 22,
    },
    8573: {
      id: "8573",
      i: 2,
      s: 18,
      ss: 56,
    },
    8575: {
      id: "8575",
      i: 2,
      s: 18,
      ss: 25,
    },
    8579: {
      id: "8579",
      i: 2,
      s: 18,
      ss: 56,
    },
    8580: {
      id: "8580",
      i: 2,
      s: 18,
      ss: 69,
    },
    8581: {
      id: "8581",
      i: 6,
      s: 6,
      ss: 77,
    },
    8582: {
      id: "8582",
      i: 6,
      s: 11,
      ss: 34,
    },
    8585: {
      id: "8585",
      i: 6,
      s: 27,
      ss: 50,
    },
    8589: {
      id: "8589",
      i: 9,
      s: 17,
      ss: 82,
    },
    8590: {
      id: "8590",
      i: 6,
      s: 27,
      ss: 50,
    },
    8591: {
      id: "8591",
      i: 7,
      s: 8,
      ss: 60,
    },
    8593: {
      id: "8593",
      i: 6,
      s: 11,
      ss: 34,
    },
    8595: {
      id: "8595",
      i: 3,
      s: 20,
      ss: 29,
    },
    8596: {
      id: "8596",
      i: 9,
      s: 10,
      ss: 19,
    },
    8597: {
      id: "8597",
      i: 6,
      s: 27,
      ss: 70,
    },
    8601: {
      id: "8601",
      i: 2,
      s: 18,
      ss: 69,
    },
    8603: {
      id: "8603",
      i: 5,
      s: 30,
      ss: 73,
    },
    8606: {
      id: "8606",
      i: 10,
      s: 15,
      ss: 21,
    },
    8607: {
      id: "8607",
      i: 6,
      s: 27,
      ss: 55,
    },
    8609: {
      id: "8609",
      i: 8,
      s: 14,
      ss: 33,
    },
    8611: {
      id: "8611",
      i: 10,
      s: 15,
      ss: 21,
    },
    8612: {
      id: "8612",
      i: 9,
      s: 10,
      ss: 16,
    },
    8613: {
      id: "8613",
      i: 10,
      s: 15,
      ss: 52,
    },
    8616: {
      id: "8616",
      i: 2,
      s: 18,
      ss: 56,
    },
    8617: {
      id: "8617",
      i: 9,
      s: 10,
      ss: 19,
    },
    8619: {
      id: "8619",
      i: 2,
      s: 18,
      ss: 56,
    },
    8620: {
      id: "8620",
      i: 9,
      s: 25,
      ss: 90,
    },
    8621: {
      id: "8621",
      i: 3,
      s: 20,
      ss: 38,
    },
    8622: {
      id: "8622",
      i: 5,
      s: 5,
      ss: 94,
    },
    8623: {
      id: "8623",
      i: 9,
      s: 10,
      ss: 19,
    },
    8627: {
      id: "8627",
      i: 6,
      s: 6,
      ss: 44,
    },
    8631: {
      id: "8631",
      i: 11,
      s: 24,
      ss: 40,
    },
    8635: {
      id: "8635",
      i: 10,
      s: 15,
      ss: 21,
    },
    8645: {
      id: "8645",
      i: 10,
      s: 15,
      ss: 21,
    },
    8646: {
      id: "8646",
      i: 9,
      s: 10,
      ss: 19,
    },
    8657: {
      id: "8657",
      i: 3,
      s: 20,
      ss: 31,
    },
    8659: {
      id: "8659",
      i: 9,
      s: 17,
      ss: 24,
    },
    8668: {
      id: "8668",
      i: 6,
      s: 7,
      ss: 32,
    },
    9001: {
      id: "9001",
      i: "",
      s: "",
      ss: 85,
    },
    9010: {
      id: "9010",
      i: "",
      s: "",
      ss: 85,
    },
    9011: {
      id: "9011",
      i: "",
      s: "",
      ss: 85,
    },
    9022: {
      id: "9022",
      i: "",
      s: "",
      ss: 85,
    },
    9031: {
      id: "9031",
      i: "",
      s: "",
      ss: 85,
    },
    9047: {
      id: "9047",
      i: "",
      s: "",
      ss: 85,
    },
    9067: {
      id: "9067",
      i: "",
      s: "",
      ss: 85,
    },
    9069: {
      id: "9069",
      i: "",
      s: "",
      ss: 85,
    },
    9072: {
      id: "9072",
      i: "",
      s: "",
      ss: 85,
    },
    9074: {
      id: "9074",
      i: "",
      s: "",
      ss: 85,
    },
    9077: {
      id: "9077",
      i: "",
      s: "",
      ss: 85,
    },
    9078: {
      id: "9078",
      i: "",
      s: "",
      ss: 85,
    },
    9081: {
      id: "9081",
      i: "",
      s: "",
      ss: 85,
    },
    9085: {
      id: "9085",
      i: "",
      s: "",
      ss: 85,
    },
    9086: {
      id: "9086",
      i: "",
      s: "",
      ss: 85,
    },
    9088: {
      id: "9088",
      i: "",
      s: "",
      ss: 85,
    },
    9091: {
      id: "9091",
      i: "",
      s: "",
      ss: 85,
    },
    9096: {
      id: "9096",
      i: "",
      s: "",
      ss: 85,
    },
    9101: {
      id: "9101",
      i: "",
      s: "",
      ss: 85,
    },
    9115: {
      id: "9115",
      i: "",
      s: "",
      ss: 85,
    },
    9125: {
      id: "9125",
      i: "",
      s: "",
      ss: 85,
    },
    9126: {
      id: "9126",
      i: "",
      s: "",
      ss: 85,
    },
    9140: {
      id: "9140",
      i: "",
      s: "",
      ss: 85,
    },
    9141: {
      id: "9141",
      i: "",
      s: "",
      ss: 85,
    },
    9151: {
      id: "9151",
      i: "",
      s: "",
      ss: 85,
    },
    9167: {
      id: "9167",
      i: "",
      s: "",
      ss: 85,
    },
    9169: {
      id: "9169",
      i: "",
      s: "",
      ss: 85,
    },
    9170: {
      id: "9170",
      i: "",
      s: "",
      ss: 85,
    },
    9173: {
      id: "9173",
      i: "",
      s: "",
      ss: 85,
    },
    9181: {
      id: "9181",
      i: "",
      s: "",
      ss: 85,
    },
    9186: {
      id: "9186",
      i: "",
      s: "",
      ss: 85,
    },
    9187: {
      id: "9187",
      i: "",
      s: "",
      ss: 85,
    },
    9191: {
      id: "9191",
      i: "",
      s: "",
      ss: 85,
    },
    9600: {
      id: "9600",
      i: 10,
      s: 15,
      ss: 21,
    },
    9608: {
      id: "9608",
      i: 2,
      s: 2,
      ss: 47,
    },
    9616: {
      id: "9616",
      i: 6,
      s: 11,
      ss: 14,
    },
    9618: {
      id: "9618",
      i: 10,
      s: 15,
      ss: 52,
    },
    9626: {
      id: "9626",
      i: 10,
      s: 15,
      ss: 35,
    },
    9633: {
      id: "9633",
      i: 8,
      s: 9,
      ss: 66,
    },
    9666: {
      id: "9666",
      i: 2,
      s: 2,
      ss: 47,
    },
    9668: {
      id: "9668",
      i: 3,
      s: 3,
      ss: 4,
    },
    9677: {
      id: "9677",
      i: 3,
      s: 3,
      ss: 4,
    },
    9688: {
      id: "9688",
      i: 5,
      s: 5,
      ss: 94,
    },
    9698: {
      id: "9698",
      i: 10,
      s: 15,
      ss: 21,
    },
    9801: {
      id: "9801",
      i: "",
      s: "",
      ss: 85,
    },
    9803: {
      id: "9803",
      i: "",
      s: "",
      ss: 85,
    },
    9804: {
      id: "9804",
      i: "",
      s: "",
      ss: 85,
    },
    9805: {
      id: "9805",
      i: "",
      s: "",
      ss: 85,
    },
    9806: {
      id: "9806",
      i: "",
      s: "",
      ss: 85,
    },
    9807: {
      id: "9807",
      i: "",
      s: "",
      ss: 85,
    },
    9809: {
      id: "9809",
      i: "",
      s: "",
      ss: 85,
    },
    9810: {
      id: "9810",
      i: "",
      s: "",
      ss: 85,
    },
    9812: {
      id: "9812",
      i: "",
      s: "",
      ss: 85,
    },
    9813: {
      id: "9813",
      i: "",
      s: "",
      ss: 85,
    },
    9814: {
      id: "9814",
      i: "",
      s: "",
      ss: 85,
    },
    9817: {
      id: "9817",
      i: "",
      s: "",
      ss: 85,
    },
    9820: {
      id: "9820",
      i: "",
      s: "",
      ss: 85,
    },
    9826: {
      id: "9826",
      i: "",
      s: "",
      ss: 85,
    },
    9829: {
      id: "9829",
      i: "",
      s: "",
      ss: 85,
    },
    9834: {
      id: "9834",
      i: "",
      s: "",
      ss: 85,
    },
    9836: {
      id: "9836",
      i: "",
      s: "",
      ss: 85,
    },
    9845: {
      id: "9845",
      i: "",
      s: "",
      ss: 85,
    },
    9846: {
      id: "9846",
      i: "",
      s: "",
      ss: 85,
    },
    9858: {
      id: "9858",
      i: 8,
      s: 9,
      ss: 87,
    },
    9868: {
      id: "9868",
      i: 6,
      s: 16,
      ss: 62,
    },
    9869: {
      id: "9869",
      i: 6,
      s: 7,
      ss: 22,
    },
    9888: {
      id: "9888",
      i: 10,
      s: 15,
      ss: 52,
    },
    9889: {
      id: "9889",
      i: 3,
      s: 3,
      ss: 4,
    },
    9900: {
      id: "9900",
      i: 2,
      s: 18,
      ss: 56,
    },
    9901: {
      id: "9901",
      i: 6,
      s: 11,
      ss: 14,
    },
    9906: {
      id: "9906",
      i: 5,
      s: 30,
      ss: 73,
    },
    9908: {
      id: "9908",
      i: 1,
      s: 1,
      ss: 2,
    },
    9909: {
      id: "9909",
      i: 2,
      s: 2,
      ss: 47,
    },
    9911: {
      id: "9911",
      i: 10,
      s: 15,
      ss: 52,
    },
    9913: {
      id: "9913",
      i: 2,
      s: 18,
      ss: 56,
    },
    9916: {
      id: "9916",
      i: 2,
      s: 2,
      ss: 47,
    },
    9918: {
      id: "9918",
      i: 9,
      s: 10,
      ss: 43,
    },
    9919: {
      id: "9919",
      i: 6,
      s: 6,
      ss: 44,
    },
    9922: {
      id: "9922",
      i: 6,
      s: 7,
      ss: 22,
    },
    9923: {
      id: "9923",
      i: 10,
      s: 15,
      ss: 52,
    },
    9926: {
      id: "9926",
      i: 5,
      s: 5,
      ss: 94,
    },
    9928: {
      id: "9928",
      i: 2,
      s: 2,
      ss: 47,
    },
    9929: {
      id: "9929",
      i: 2,
      s: 18,
      ss: 56,
    },
    9933: {
      id: "9933",
      i: 7,
      s: 21,
      ss: 30,
    },
    9936: {
      id: "9936",
      i: 7,
      s: 21,
      ss: 30,
    },
    9938: {
      id: "9938",
      i: 2,
      s: 18,
      ss: 56,
    },
    9939: {
      id: "9939",
      i: 5,
      s: 5,
      ss: 7,
    },
    9956: {
      id: "9956",
      i: 9,
      s: 25,
      ss: 90,
    },
    9958: {
      id: "9958",
      i: 6,
      s: 6,
      ss: 77,
    },
    9959: {
      id: "9959",
      i: 10,
      s: 15,
      ss: 21,
    },
    9960: {
      id: "9960",
      i: 5,
      s: 30,
      ss: 73,
    },
    9961: {
      id: "9961",
      i: 6,
      s: 7,
      ss: 32,
    },
    9963: {
      id: "9963",
      i: 10,
      s: 26,
      ss: 48,
    },
    9966: {
      id: "9966",
      i: 5,
      s: 5,
      ss: 94,
    },
    9968: {
      id: "9968",
      i: 2,
      s: 2,
      ss: 3,
    },
    9969: {
      id: "9969",
      i: 5,
      s: 5,
      ss: 94,
    },
    9977: {
      id: "9977",
      i: 8,
      s: 14,
      ss: 74,
    },
    9978: {
      id: "9978",
      i: 2,
      s: 2,
      ss: 80,
    },
    9979: {
      id: "9979",
      i: 2,
      s: 2,
      ss: 47,
    },
    9982: {
      id: "9982",
      i: 2,
      s: 2,
      ss: 3,
    },
    9983: {
      id: "9983",
      i: 2,
      s: 2,
      ss: 47,
    },
    9986: {
      id: "9986",
      i: 6,
      s: 11,
      ss: 14,
    },
    9987: {
      id: "9987",
      i: 6,
      s: 7,
      ss: 22,
    },
    9988: {
      id: "9988",
      i: 10,
      s: 15,
      ss: 52,
    },
    9989: {
      id: "9989",
      i: 5,
      s: 5,
      ss: 7,
    },
    9990: {
      id: "9990",
      i: 10,
      s: 15,
      ss: 35,
    },
    9991: {
      id: "9991",
      i: 10,
      s: 15,
      ss: 52,
    },
    9992: {
      id: "9992",
      i: 6,
      s: 12,
      ss: 15,
    },
    9993: {
      id: "9993",
      i: 2,
      s: 2,
      ss: 3,
    },
    9995: {
      id: "9995",
      i: 5,
      s: 5,
      ss: 94,
    },
    9996: {
      id: "9996",
      i: 5,
      s: 30,
      ss: 92,
    },
    9997: {
      id: "9997",
      i: 5,
      s: 30,
      ss: 92,
    },
    9998: {
      id: "9998",
      i: 2,
      s: 18,
      ss: 56,
    },
    9999: {
      id: "9999",
      i: 10,
      s: 15,
      ss: 35,
    },
    "0001": {
      id: "0001",
      i: 0,
      s: 0,
      ss: 0,
    },
    "0002": {
      id: "0002",
      i: 1,
      s: 1,
      ss: 1,
    },
    "0003": {
      id: "0003",
      i: 1,
      s: 1,
      ss: 2,
    },
    "0004": {
      id: "0004",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0005": {
      id: "0005",
      i: 3,
      s: 3,
      ss: 4,
    },
    "0006": {
      id: "0006",
      i: 1,
      s: 1,
      ss: 1,
    },
    "0007": {
      id: "0007",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0008": {
      id: "0008",
      i: 4,
      s: 4,
      ss: 6,
    },
    "0009": {
      id: "0009",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0010": {
      id: "0010",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0011": {
      id: "0011",
      i: 3,
      s: 3,
      ss: 4,
    },
    "0012": {
      id: "0012",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0013": {
      id: "0013",
      i: 5,
      s: 5,
      ss: 7,
    },
    "0014": {
      id: "0014",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0016": {
      id: "0016",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0017": {
      id: "0017",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0018": {
      id: "0018",
      i: 6,
      s: 6,
      ss: 8,
    },
    "0019": {
      id: "0019",
      i: 0,
      s: 0,
      ss: 0,
    },
    "0021": {
      id: "0021",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0022": {
      id: "0022",
      i: 6,
      s: 7,
      ss: 9,
    },
    "0023": {
      id: "0023",
      i: 3,
      s: 3,
      ss: 4,
    },
    "0024": {
      id: "0024",
      i: 7,
      s: 8,
      ss: 10,
    },
    "0025": {
      id: "0025",
      i: 0,
      s: 0,
      ss: 0,
    },
    "0026": {
      id: "0026",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0027": {
      id: "0027",
      i: 6,
      s: 7,
      ss: 11,
    },
    "0028": {
      id: "0028",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0029": {
      id: "0029",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0030": {
      id: "0030",
      i: 8,
      s: 9,
      ss: 12,
    },
    "0031": {
      id: "0031",
      i: 9,
      s: 10,
      ss: 13,
    },
    "0032": {
      id: "0032",
      i: 6,
      s: 11,
      ss: 14,
    },
    "0033": {
      id: "0033",
      i: 6,
      s: 12,
      ss: 15,
    },
    "0034": {
      id: "0034",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0035": {
      id: "0035",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0036": {
      id: "0036",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0037": {
      id: "0037",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0038": {
      id: "0038",
      i: 9,
      s: 10,
      ss: 16,
    },
    "0039": {
      id: "0039",
      i: 8,
      s: 13,
      ss: 17,
    },
    "0040": {
      id: "0040",
      i: 6,
      s: 12,
      ss: 18,
    },
    "0041": {
      id: "0041",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0042": {
      id: "0042",
      i: 9,
      s: 10,
      ss: 19,
    },
    "0043": {
      id: "0043",
      i: 8,
      s: 14,
      ss: 20,
    },
    "0045": {
      id: "0045",
      i: 6,
      s: 7,
      ss: 9,
    },
    "0046": {
      id: "0046",
      i: 10,
      s: 15,
      ss: 21,
    },
    "0047": {
      id: "0047",
      i: 6,
      s: 7,
      ss: 22,
    },
    "0048": {
      id: "0048",
      i: 6,
      s: 16,
      ss: 23,
    },
    "0050": {
      id: "0050",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0051": {
      id: "0051",
      i: 6,
      s: 7,
      ss: 9,
    },
    "0052": {
      id: "0052",
      i: 6,
      s: 7,
      ss: 22,
    },
    "0053": {
      id: "0053",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0055": {
      id: "0055",
      i: 9,
      s: 17,
      ss: 24,
    },
    "0057": {
      id: "0057",
      i: 9,
      s: 10,
      ss: 19,
    },
    "0058": {
      id: "0058",
      i: 2,
      s: 18,
      ss: 25,
    },
    "0059": {
      id: "0059",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0060": {
      id: "0060",
      i: 8,
      s: 9,
      ss: 26,
    },
    "0061": {
      id: "0061",
      i: 11,
      s: 19,
      ss: 27,
    },
    "0062": {
      id: "0062",
      i: 6,
      s: 7,
      ss: 28,
    },
    "0063": {
      id: "0063",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0064": {
      id: "0064",
      i: 3,
      s: 20,
      ss: 29,
    },
    "0065": {
      id: "0065",
      i: 11,
      s: 19,
      ss: 27,
    },
    "0066": {
      id: "0066",
      i: 6,
      s: 7,
      ss: 28,
    },
    "0067": {
      id: "0067",
      i: 7,
      s: 21,
      ss: 30,
    },
    "0068": {
      id: "0068",
      i: 3,
      s: 20,
      ss: 31,
    },
    "0069": {
      id: "0069",
      i: 6,
      s: 7,
      ss: 9,
    },
    "0070": {
      id: "0070",
      i: 6,
      s: 7,
      ss: 11,
    },
    "0071": {
      id: "0071",
      i: 6,
      s: 7,
      ss: 32,
    },
    "0072": {
      id: "0072",
      i: 6,
      s: 6,
      ss: 8,
    },
    "0073": {
      id: "0073",
      i: 8,
      s: 14,
      ss: 33,
    },
    "0075": {
      id: "0075",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0076": {
      id: "0076",
      i: 9,
      s: 10,
      ss: 19,
    },
    "0077": {
      id: "0077",
      i: 6,
      s: 7,
      ss: 28,
    },
    "0078": {
      id: "0078",
      i: 6,
      s: 7,
      ss: 9,
    },
    "0079": {
      id: "0079",
      i: 6,
      s: 11,
      ss: 34,
    },
    "0080": {
      id: "0080",
      i: 3,
      s: 20,
      ss: 31,
    },
    "0081": {
      id: "0081",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0082": {
      id: "0082",
      i: 10,
      s: 15,
      ss: 35,
    },
    "0083": {
      id: "0083",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0084": {
      id: "0084",
      i: 6,
      s: 22,
      ss: 36,
    },
    "0085": {
      id: "0085",
      i: 10,
      s: 23,
      ss: 37,
    },
    "0086": {
      id: "0086",
      i: 3,
      s: 20,
      ss: 38,
    },
    "0087": {
      id: "0087",
      i: 0,
      s: 0,
      ss: 0,
    },
    "0088": {
      id: "0088",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0089": {
      id: "0089",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0090": {
      id: "0090",
      i: 1,
      s: 1,
      ss: 39,
    },
    "0091": {
      id: "0091",
      i: 11,
      s: 24,
      ss: 40,
    },
    "0092": {
      id: "0092",
      i: 11,
      s: 24,
      ss: 40,
    },
    "0093": {
      id: "0093",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0094": {
      id: "0094",
      i: 7,
      s: 21,
      ss: 41,
    },
    "0095": {
      id: "0095",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0096": {
      id: "0096",
      i: 9,
      s: 10,
      ss: 19,
    },
    "0097": {
      id: "0097",
      i: 6,
      s: 22,
      ss: 36,
    },
    "0098": {
      id: "0098",
      i: 7,
      s: 8,
      ss: 42,
    },
    "0099": {
      id: "0099",
      i: 9,
      s: 10,
      ss: 43,
    },
    "0100": {
      id: "0100",
      i: 6,
      s: 6,
      ss: 44,
    },
    "0101": {
      id: "0101",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0102": {
      id: "0102",
      i: 6,
      s: 7,
      ss: 11,
    },
    "0103": {
      id: "0103",
      i: 9,
      s: 10,
      ss: 45,
    },
    "0104": {
      id: "0104",
      i: 6,
      s: 22,
      ss: 36,
    },
    "0105": {
      id: "0105",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0106": {
      id: "0106",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0107": {
      id: "0107",
      i: 9,
      s: 25,
      ss: 46,
    },
    "0108": {
      id: "0108",
      i: 2,
      s: 2,
      ss: 47,
    },
    "0109": {
      id: "0109",
      i: 3,
      s: 20,
      ss: 38,
    },
    "0110": {
      id: "0110",
      i: 10,
      s: 26,
      ss: 48,
    },
    "0111": {
      id: "0111",
      i: 3,
      s: 20,
      ss: 31,
    },
    "0112": {
      id: "0112",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0113": {
      id: "0113",
      i: 6,
      s: 27,
      ss: 49,
    },
    "0114": {
      id: "0114",
      i: 6,
      s: 12,
      ss: 15,
    },
    "0115": {
      id: "0115",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0116": {
      id: "0116",
      i: 6,
      s: 22,
      ss: 36,
    },
    "0117": {
      id: "0117",
      i: 9,
      s: 10,
      ss: 19,
    },
    "0118": {
      id: "0118",
      i: 9,
      s: 10,
      ss: 19,
    },
    "0119": {
      id: "0119",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0120": {
      id: "0120",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0122": {
      id: "0122",
      i: 6,
      s: 27,
      ss: 50,
    },
    "0123": {
      id: "0123",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0124": {
      id: "0124",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0125": {
      id: "0125",
      i: 6,
      s: 27,
      ss: 49,
    },
    "0126": {
      id: "0126",
      i: 6,
      s: 7,
      ss: 22,
    },
    "0127": {
      id: "0127",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0128": {
      id: "0128",
      i: 6,
      s: 22,
      ss: 51,
    },
    "0129": {
      id: "0129",
      i: 3,
      s: 20,
      ss: 31,
    },
    "0130": {
      id: "0130",
      i: 6,
      s: 27,
      ss: 50,
    },
    "0131": {
      id: "0131",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0132": {
      id: "0132",
      i: 0,
      s: 0,
      ss: 0,
    },
    "0133": {
      id: "0133",
      i: 3,
      s: 20,
      ss: 31,
    },
    "0135": {
      id: "0135",
      i: 1,
      s: 1,
      ss: 2,
    },
    "0136": {
      id: "0136",
      i: 10,
      s: 15,
      ss: 52,
    },
    "0137": {
      id: "0137",
      i: 9,
      s: 25,
      ss: 53,
    },
    "0138": {
      id: "0138",
      i: 6,
      s: 22,
      ss: 54,
    },
    "0139": {
      id: "0139",
      i: 3,
      s: 20,
      ss: 29,
    },
    "0141": {
      id: "0141",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0142": {
      id: "0142",
      i: 8,
      s: 9,
      ss: 26,
    },
    "0143": {
      id: "0143",
      i: 10,
      s: 26,
      ss: 48,
    },
    "0144": {
      id: "0144",
      i: 9,
      s: 25,
      ss: 53,
    },
    "0145": {
      id: "0145",
      i: 6,
      s: 11,
      ss: 34,
    },
    "0146": {
      id: "0146",
      i: 6,
      s: 27,
      ss: 55,
    },
    "0147": {
      id: "0147",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0148": {
      id: "0148",
      i: 9,
      s: 10,
      ss: 19,
    },
    "0149": {
      id: "0149",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0150": {
      id: "0150",
      i: 6,
      s: 6,
      ss: 44,
    },
    "0151": {
      id: "0151",
      i: 8,
      s: 9,
      ss: 26,
    },
    "0152": {
      id: "0152",
      i: 9,
      s: 25,
      ss: 46,
    },
    "0153": {
      id: "0153",
      i: 2,
      s: 18,
      ss: 56,
    },
    "0154": {
      id: "0154",
      i: 9,
      s: 10,
      ss: 57,
    },
    "0155": {
      id: "0155",
      i: 9,
      s: 10,
      ss: 58,
    },
    "0156": {
      id: "0156",
      i: 6,
      s: 7,
      ss: 22,
    },
    "0157": {
      id: "0157",
      i: 6,
      s: 22,
      ss: 36,
    },
    "0158": {
      id: "0158",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0159": {
      id: "0159",
      i: 7,
      s: 8,
      ss: 10,
    },
    "0160": {
      id: "0160",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0162": {
      id: "0162",
      i: 6,
      s: 22,
      ss: 59,
    },
    "0163": {
      id: "0163",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0164": {
      id: "0164",
      i: 10,
      s: 26,
      ss: 48,
    },
    "0165": {
      id: "0165",
      i: 3,
      s: 20,
      ss: 31,
    },
    "0166": {
      id: "0166",
      i: 7,
      s: 8,
      ss: 60,
    },
    "0167": {
      id: "0167",
      i: 6,
      s: 12,
      ss: 18,
    },
    "0168": {
      id: "0168",
      i: 8,
      s: 9,
      ss: 61,
    },
    "0169": {
      id: "0169",
      i: 6,
      s: 7,
      ss: 9,
    },
    "0171": {
      id: "0171",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0172": {
      id: "0172",
      i: 3,
      s: 20,
      ss: 38,
    },
    "0173": {
      id: "0173",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0174": {
      id: "0174",
      i: 3,
      s: 20,
      ss: 31,
    },
    "0175": {
      id: "0175",
      i: 6,
      s: 16,
      ss: 62,
    },
    "0176": {
      id: "0176",
      i: 6,
      s: 12,
      ss: 18,
    },
    "0177": {
      id: "0177",
      i: 9,
      s: 25,
      ss: 46,
    },
    "0178": {
      id: "0178",
      i: 6,
      s: 22,
      ss: 36,
    },
    "0179": {
      id: "0179",
      i: 6,
      s: 16,
      ss: 23,
    },
    "0180": {
      id: "0180",
      i: 6,
      s: 12,
      ss: 15,
    },
    "0181": {
      id: "0181",
      i: 6,
      s: 7,
      ss: 9,
    },
    "0182": {
      id: "0182",
      i: 1,
      s: 1,
      ss: 39,
    },
    "0183": {
      id: "0183",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0184": {
      id: "0184",
      i: 6,
      s: 7,
      ss: 9,
    },
    "0185": {
      id: "0185",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0186": {
      id: "0186",
      i: 6,
      s: 12,
      ss: 18,
    },
    "0187": {
      id: "0187",
      i: 9,
      s: 10,
      ss: 19,
    },
    "0188": {
      id: "0188",
      i: 3,
      s: 20,
      ss: 63,
    },
    "0189": {
      id: "0189",
      i: 7,
      s: 21,
      ss: 30,
    },
    "0190": {
      id: "0190",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0191": {
      id: "0191",
      i: 0,
      s: 0,
      ss: 0,
    },
    "0193": {
      id: "0193",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0194": {
      id: "0194",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0195": {
      id: "0195",
      i: 7,
      s: 8,
      ss: 60,
    },
    "0196": {
      id: "0196",
      i: 11,
      s: 24,
      ss: 64,
    },
    "0197": {
      id: "0197",
      i: 8,
      s: 9,
      ss: 26,
    },
    "0199": {
      id: "0199",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0200": {
      id: "0200",
      i: 6,
      s: 7,
      ss: 11,
    },
    "0201": {
      id: "0201",
      i: 6,
      s: 7,
      ss: 9,
    },
    "0202": {
      id: "0202",
      i: 0,
      s: 0,
      ss: 0,
    },
    "0204": {
      id: "0204",
      i: 3,
      s: 20,
      ss: 31,
    },
    "0205": {
      id: "0205",
      i: 6,
      s: 6,
      ss: 44,
    },
    "0206": {
      id: "0206",
      i: 11,
      s: 24,
      ss: 64,
    },
    "0207": {
      id: "0207",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0208": {
      id: "0208",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0209": {
      id: "0209",
      i: 6,
      s: 12,
      ss: 15,
    },
    "0210": {
      id: "0210",
      i: 6,
      s: 27,
      ss: 65,
    },
    "0211": {
      id: "0211",
      i: 3,
      s: 20,
      ss: 29,
    },
    "0212": {
      id: "0212",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0213": {
      id: "0213",
      i: 9,
      s: 10,
      ss: 19,
    },
    "0214": {
      id: "0214",
      i: 3,
      s: 20,
      ss: 31,
    },
    "0215": {
      id: "0215",
      i: 4,
      s: 4,
      ss: 6,
    },
    "0216": {
      id: "0216",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0217": {
      id: "0217",
      i: 7,
      s: 8,
      ss: 60,
    },
    "0218": {
      id: "0218",
      i: 3,
      s: 20,
      ss: 29,
    },
    "0219": {
      id: "0219",
      i: 6,
      s: 7,
      ss: 9,
    },
    "0220": {
      id: "0220",
      i: 8,
      s: 9,
      ss: 66,
    },
    "0222": {
      id: "0222",
      i: 3,
      s: 28,
      ss: 67,
    },
    "0223": {
      id: "0223",
      i: 10,
      s: 15,
      ss: 52,
    },
    "0224": {
      id: "0224",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0225": {
      id: "0225",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0226": {
      id: "0226",
      i: 6,
      s: 7,
      ss: 22,
    },
    "0227": {
      id: "0227",
      i: 3,
      s: 20,
      ss: 29,
    },
    "0228": {
      id: "0228",
      i: 11,
      s: 24,
      ss: 40,
    },
    "0229": {
      id: "0229",
      i: 6,
      s: 12,
      ss: 68,
    },
    "0230": {
      id: "0230",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0231": {
      id: "0231",
      i: 3,
      s: 20,
      ss: 29,
    },
    "0232": {
      id: "0232",
      i: 9,
      s: 10,
      ss: 13,
    },
    "0234": {
      id: "0234",
      i: 6,
      s: 7,
      ss: 32,
    },
    "0235": {
      id: "0235",
      i: 7,
      s: 8,
      ss: 60,
    },
    "0236": {
      id: "0236",
      i: 8,
      s: 9,
      ss: 61,
    },
    "0237": {
      id: "0237",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0238": {
      id: "0238",
      i: 6,
      s: 27,
      ss: 50,
    },
    "0239": {
      id: "0239",
      i: 5,
      s: 5,
      ss: 7,
    },
    "0240": {
      id: "0240",
      i: 2,
      s: 18,
      ss: 69,
    },
    "0241": {
      id: "0241",
      i: 5,
      s: 5,
      ss: 7,
    },
    "0242": {
      id: "0242",
      i: 0,
      s: 0,
      ss: 0,
    },
    "0243": {
      id: "0243",
      i: 10,
      s: 23,
      ss: 37,
    },
    "0244": {
      id: "0244",
      i: 6,
      s: 22,
      ss: 59,
    },
    "0245": {
      id: "0245",
      i: 3,
      s: 20,
      ss: 29,
    },
    "0247": {
      id: "0247",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0248": {
      id: "0248",
      i: 10,
      s: 26,
      ss: 48,
    },
    "0250": {
      id: "0250",
      i: 10,
      s: 15,
      ss: 21,
    },
    "0251": {
      id: "0251",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0252": {
      id: "0252",
      i: 9,
      s: 17,
      ss: 24,
    },
    "0253": {
      id: "0253",
      i: 6,
      s: 7,
      ss: 9,
    },
    "0254": {
      id: "0254",
      i: 6,
      s: 7,
      ss: 32,
    },
    "0255": {
      id: "0255",
      i: 9,
      s: 10,
      ss: 19,
    },
    "0256": {
      id: "0256",
      i: 6,
      s: 27,
      ss: 70,
    },
    "0257": {
      id: "0257",
      i: 1,
      s: 1,
      ss: 39,
    },
    "0258": {
      id: "0258",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0259": {
      id: "0259",
      i: 6,
      s: 12,
      ss: 18,
    },
    "0260": {
      id: "0260",
      i: 3,
      s: 20,
      ss: 38,
    },
    "0261": {
      id: "0261",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0262": {
      id: "0262",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0263": {
      id: "0263",
      i: 3,
      s: 20,
      ss: 29,
    },
    "0264": {
      id: "0264",
      i: 6,
      s: 27,
      ss: 49,
    },
    "0265": {
      id: "0265",
      i: 6,
      s: 7,
      ss: 32,
    },
    "0266": {
      id: "0266",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0267": {
      id: "0267",
      i: 0,
      s: 0,
      ss: 0,
    },
    "0268": {
      id: "0268",
      i: 10,
      s: 15,
      ss: 35,
    },
    "0269": {
      id: "0269",
      i: 9,
      s: 25,
      ss: 46,
    },
    "0270": {
      id: "0270",
      i: 1,
      s: 1,
      ss: 71,
    },
    "0271": {
      id: "0271",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0272": {
      id: "0272",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0273": {
      id: "0273",
      i: 3,
      s: 20,
      ss: 31,
    },
    "0274": {
      id: "0274",
      i: 7,
      s: 29,
      ss: 72,
    },
    "0276": {
      id: "0276",
      i: 11,
      s: 19,
      ss: 27,
    },
    "0277": {
      id: "0277",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0278": {
      id: "0278",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0279": {
      id: "0279",
      i: 3,
      s: 20,
      ss: 29,
    },
    "0280": {
      id: "0280",
      i: 6,
      s: 27,
      ss: 70,
    },
    "0281": {
      id: "0281",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0282": {
      id: "0282",
      i: 6,
      s: 6,
      ss: 8,
    },
    "0285": {
      id: "0285",
      i: 10,
      s: 26,
      ss: 48,
    },
    "0286": {
      id: "0286",
      i: 5,
      s: 30,
      ss: 73,
    },
    "0287": {
      id: "0287",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0288": {
      id: "0288",
      i: 8,
      s: 14,
      ss: 74,
    },
    "0289": {
      id: "0289",
      i: 6,
      s: 22,
      ss: 59,
    },
    "0290": {
      id: "0290",
      i: 3,
      s: 20,
      ss: 29,
    },
    "0291": {
      id: "0291",
      i: 8,
      s: 9,
      ss: 61,
    },
    "0292": {
      id: "0292",
      i: 6,
      s: 7,
      ss: 9,
    },
    "0293": {
      id: "0293",
      i: 6,
      s: 7,
      ss: 75,
    },
    "0294": {
      id: "0294",
      i: 6,
      s: 27,
      ss: 50,
    },
    "0295": {
      id: "0295",
      i: 1,
      s: 1,
      ss: 39,
    },
    "0296": {
      id: "0296",
      i: 6,
      s: 7,
      ss: 11,
    },
    "0297": {
      id: "0297",
      i: 7,
      s: 21,
      ss: 76,
    },
    "0298": {
      id: "0298",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0299": {
      id: "0299",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0301": {
      id: "0301",
      i: 7,
      s: 21,
      ss: 30,
    },
    "0302": {
      id: "0302",
      i: 10,
      s: 15,
      ss: 35,
    },
    "0303": {
      id: "0303",
      i: 10,
      s: 26,
      ss: 48,
    },
    "0305": {
      id: "0305",
      i: 6,
      s: 16,
      ss: 23,
    },
    "0306": {
      id: "0306",
      i: 6,
      s: 7,
      ss: 28,
    },
    "0307": {
      id: "0307",
      i: 11,
      s: 19,
      ss: 27,
    },
    "0308": {
      id: "0308",
      i: 6,
      s: 7,
      ss: 32,
    },
    "0309": {
      id: "0309",
      i: 6,
      s: 11,
      ss: 34,
    },
    "0310": {
      id: "0310",
      i: 3,
      s: 20,
      ss: 31,
    },
    "0311": {
      id: "0311",
      i: 6,
      s: 27,
      ss: 50,
    },
    "0312": {
      id: "0312",
      i: 6,
      s: 22,
      ss: 59,
    },
    "0313": {
      id: "0313",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0315": {
      id: "0315",
      i: 4,
      s: 4,
      ss: 6,
    },
    "0316": {
      id: "0316",
      i: 9,
      s: 25,
      ss: 53,
    },
    "0317": {
      id: "0317",
      i: 9,
      s: 10,
      ss: 45,
    },
    "0318": {
      id: "0318",
      i: 0,
      s: 0,
      ss: 0,
    },
    "0320": {
      id: "0320",
      i: 9,
      s: 10,
      ss: 19,
    },
    "0321": {
      id: "0321",
      i: 6,
      s: 27,
      ss: 55,
    },
    "0322": {
      id: "0322",
      i: 8,
      s: 9,
      ss: 66,
    },
    "0323": {
      id: "0323",
      i: 7,
      s: 8,
      ss: 10,
    },
    "0326": {
      id: "0326",
      i: 6,
      s: 6,
      ss: 77,
    },
    "0327": {
      id: "0327",
      i: 10,
      s: 15,
      ss: 52,
    },
    "0328": {
      id: "0328",
      i: 6,
      s: 12,
      ss: 18,
    },
    "0329": {
      id: "0329",
      i: 3,
      s: 20,
      ss: 29,
    },
    "0330": {
      id: "0330",
      i: 6,
      s: 27,
      ss: 50,
    },
    "0331": {
      id: "0331",
      i: 2,
      s: 18,
      ss: 56,
    },
    "0332": {
      id: "0332",
      i: 11,
      s: 24,
      ss: 40,
    },
    "0333": {
      id: "0333",
      i: 6,
      s: 27,
      ss: 50,
    },
    "0334": {
      id: "0334",
      i: 10,
      s: 26,
      ss: 78,
    },
    "0335": {
      id: "0335",
      i: 3,
      s: 20,
      ss: 38,
    },
    "0336": {
      id: "0336",
      i: 8,
      s: 9,
      ss: 12,
    },
    "0337": {
      id: "0337",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0338": {
      id: "0338",
      i: 7,
      s: 21,
      ss: 30,
    },
    "0339": {
      id: "0339",
      i: 3,
      s: 20,
      ss: 31,
    },
    "0340": {
      id: "0340",
      i: 7,
      s: 29,
      ss: 72,
    },
    "0341": {
      id: "0341",
      i: 6,
      s: 7,
      ss: 22,
    },
    "0342": {
      id: "0342",
      i: 11,
      s: 24,
      ss: 40,
    },
    "0343": {
      id: "0343",
      i: 6,
      s: 6,
      ss: 8,
    },
    "0345": {
      id: "0345",
      i: 8,
      s: 9,
      ss: 66,
    },
    "0346": {
      id: "0346",
      i: 11,
      s: 24,
      ss: 40,
    },
    "0347": {
      id: "0347",
      i: 7,
      s: 8,
      ss: 10,
    },
    "0348": {
      id: "0348",
      i: 5,
      s: 5,
      ss: 79,
    },
    "0351": {
      id: "0351",
      i: 9,
      s: 25,
      ss: 53,
    },
    "0352": {
      id: "0352",
      i: 2,
      s: 2,
      ss: 80,
    },
    "0353": {
      id: "0353",
      i: 11,
      s: 24,
      ss: 64,
    },
    "0354": {
      id: "0354",
      i: 10,
      s: 15,
      ss: 21,
    },
    "0355": {
      id: "0355",
      i: 6,
      s: 7,
      ss: 9,
    },
    "0356": {
      id: "0356",
      i: 3,
      s: 20,
      ss: 31,
    },
    "0357": {
      id: "0357",
      i: 6,
      s: 7,
      ss: 75,
    },
    "0358": {
      id: "0358",
      i: 7,
      s: 8,
      ss: 81,
    },
    "0359": {
      id: "0359",
      i: 8,
      s: 9,
      ss: 66,
    },
    "0360": {
      id: "0360",
      i: 6,
      s: 16,
      ss: 23,
    },
    "0361": {
      id: "0361",
      i: 6,
      s: 12,
      ss: 15,
    },
    "0362": {
      id: "0362",
      i: 7,
      s: 21,
      ss: 30,
    },
    "0363": {
      id: "0363",
      i: 0,
      s: 0,
      ss: 0,
    },
    "0364": {
      id: "0364",
      i: 8,
      s: 9,
      ss: 66,
    },
    "0365": {
      id: "0365",
      i: 9,
      s: 10,
      ss: 19,
    },
    "0366": {
      id: "0366",
      i: 2,
      s: 18,
      ss: 25,
    },
    "0367": {
      id: "0367",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0368": {
      id: "0368",
      i: 2,
      s: 18,
      ss: 56,
    },
    "0369": {
      id: "0369",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0370": {
      id: "0370",
      i: 6,
      s: 12,
      ss: 18,
    },
    "0371": {
      id: "0371",
      i: 1,
      s: 1,
      ss: 71,
    },
    "0372": {
      id: "0372",
      i: 3,
      s: 20,
      ss: 31,
    },
    "0373": {
      id: "0373",
      i: 3,
      s: 20,
      ss: 38,
    },
    "0374": {
      id: "0374",
      i: 8,
      s: 9,
      ss: 26,
    },
    "0375": {
      id: "0375",
      i: 6,
      s: 27,
      ss: 50,
    },
    "0376": {
      id: "0376",
      i: 3,
      s: 28,
      ss: 67,
    },
    "0377": {
      id: "0377",
      i: 9,
      s: 17,
      ss: 82,
    },
    "0378": {
      id: "0378",
      i: 7,
      s: 21,
      ss: 30,
    },
    "0379": {
      id: "0379",
      i: 3,
      s: 20,
      ss: 38,
    },
    "0380": {
      id: "0380",
      i: 2,
      s: 18,
      ss: 25,
    },
    "0381": {
      id: "0381",
      i: 6,
      s: 12,
      ss: 15,
    },
    "0382": {
      id: "0382",
      i: 6,
      s: 11,
      ss: 14,
    },
    "0383": {
      id: "0383",
      i: 5,
      s: 30,
      ss: 73,
    },
    "0384": {
      id: "0384",
      i: 1,
      s: 1,
      ss: 2,
    },
    "0385": {
      id: "0385",
      i: 2,
      s: 18,
      ss: 56,
    },
    "0386": {
      id: "0386",
      i: 11,
      s: 24,
      ss: 40,
    },
    "0387": {
      id: "0387",
      i: 9,
      s: 10,
      ss: 19,
    },
    "0388": {
      id: "0388",
      i: 3,
      s: 20,
      ss: 63,
    },
    "0389": {
      id: "0389",
      i: 8,
      s: 9,
      ss: 61,
    },
    "0390": {
      id: "0390",
      i: 2,
      s: 18,
      ss: 69,
    },
    "0391": {
      id: "0391",
      i: 6,
      s: 6,
      ss: 77,
    },
    "0392": {
      id: "0392",
      i: 1,
      s: 1,
      ss: 2,
    },
    "0393": {
      id: "0393",
      i: 6,
      s: 27,
      ss: 50,
    },
    "0395": {
      id: "0395",
      i: 10,
      s: 15,
      ss: 52,
    },
    "0396": {
      id: "0396",
      i: 6,
      s: 12,
      ss: 83,
    },
    "0397": {
      id: "0397",
      i: 3,
      s: 20,
      ss: 38,
    },
    "0398": {
      id: "0398",
      i: 6,
      s: 22,
      ss: 36,
    },
    "0399": {
      id: "0399",
      i: 8,
      s: 13,
      ss: 84,
    },
    "0400": {
      id: "0400",
      i: 10,
      s: 15,
      ss: 52,
    },
    "0401": {
      id: "0401",
      i: 5,
      s: 5,
      ss: 7,
    },
    "0403": {
      id: "0403",
      i: 9,
      s: 17,
      ss: 24,
    },
    "0405": {
      id: "0405",
      i: "",
      s: "",
      ss: 85,
    },
    "0406": {
      id: "0406",
      i: 2,
      s: 18,
      ss: 56,
    },
    "0408": {
      id: "0408",
      i: 7,
      s: 21,
      ss: 30,
    },
    "0410": {
      id: "0410",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0411": {
      id: "0411",
      i: 8,
      s: 14,
      ss: 33,
    },
    "0412": {
      id: "0412",
      i: 3,
      s: 20,
      ss: 38,
    },
    "0413": {
      id: "0413",
      i: 6,
      s: 12,
      ss: 15,
    },
    "0416": {
      id: "0416",
      i: 3,
      s: 3,
      ss: 4,
    },
    "0417": {
      id: "0417",
      i: 6,
      s: 22,
      ss: 36,
    },
    "0418": {
      id: "0418",
      i: 10,
      s: 15,
      ss: 35,
    },
    "0419": {
      id: "0419",
      i: 6,
      s: 7,
      ss: 86,
    },
    "0420": {
      id: "0420",
      i: 6,
      s: 27,
      ss: 55,
    },
    "0422": {
      id: "0422",
      i: 6,
      s: 16,
      ss: 62,
    },
    "0423": {
      id: "0423",
      i: 6,
      s: 6,
      ss: 8,
    },
    "0425": {
      id: "0425",
      i: 6,
      s: 16,
      ss: 23,
    },
    "0426": {
      id: "0426",
      i: 6,
      s: 6,
      ss: 8,
    },
    "0428": {
      id: "0428",
      i: 3,
      s: 20,
      ss: 31,
    },
    "0430": {
      id: "0430",
      i: 3,
      s: 20,
      ss: 31,
    },
    "0431": {
      id: "0431",
      i: 3,
      s: 20,
      ss: 38,
    },
    "0432": {
      id: "0432",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0433": {
      id: "0433",
      i: 7,
      s: 8,
      ss: 60,
    },
    "0434": {
      id: "0434",
      i: 10,
      s: 15,
      ss: 35,
    },
    "0435": {
      id: "0435",
      i: "",
      s: "",
      ss: 85,
    },
    "0436": {
      id: "0436",
      i: 9,
      s: 10,
      ss: 57,
    },
    "0438": {
      id: "0438",
      i: 9,
      s: 10,
      ss: 58,
    },
    "0439": {
      id: "0439",
      i: 9,
      s: 10,
      ss: 43,
    },
    "0440": {
      id: "0440",
      i: 3,
      s: 3,
      ss: 4,
    },
    "0442": {
      id: "0442",
      i: 6,
      s: 27,
      ss: 70,
    },
    "0444": {
      id: "0444",
      i: 6,
      s: 22,
      ss: 36,
    },
    "0449": {
      id: "0449",
      i: 6,
      s: 12,
      ss: 68,
    },
    "0450": {
      id: "0450",
      i: 9,
      s: 17,
      ss: 24,
    },
    "0451": {
      id: "0451",
      i: 1,
      s: 1,
      ss: 39,
    },
    "0455": {
      id: "0455",
      i: 5,
      s: 5,
      ss: 7,
    },
    "0456": {
      id: "0456",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0458": {
      id: "0458",
      i: 6,
      s: 27,
      ss: 50,
    },
    "0459": {
      id: "0459",
      i: 2,
      s: 2,
      ss: 80,
    },
    "0460": {
      id: "0460",
      i: 5,
      s: 5,
      ss: 7,
    },
    "0462": {
      id: "0462",
      i: 8,
      s: 9,
      ss: 87,
    },
    "0464": {
      id: "0464",
      i: 6,
      s: 12,
      ss: 68,
    },
    "0465": {
      id: "0465",
      i: 10,
      s: 15,
      ss: 21,
    },
    "0467": {
      id: "0467",
      i: 11,
      s: 24,
      ss: 40,
    },
    "0468": {
      id: "0468",
      i: 9,
      s: 17,
      ss: 24,
    },
    "0471": {
      id: "0471",
      i: 4,
      s: 4,
      ss: 88,
    },
    "0472": {
      id: "0472",
      i: 8,
      s: 9,
      ss: 61,
    },
    "0474": {
      id: "0474",
      i: 9,
      s: 10,
      ss: 16,
    },
    "0475": {
      id: "0475",
      i: 9,
      s: 10,
      ss: 58,
    },
    "0476": {
      id: "0476",
      i: 9,
      s: 10,
      ss: 45,
    },
    "0479": {
      id: "0479",
      i: 6,
      s: 16,
      ss: 62,
    },
    "0480": {
      id: "0480",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0482": {
      id: "0482",
      i: 6,
      s: 12,
      ss: 18,
    },
    "0483": {
      id: "0483",
      i: 6,
      s: 22,
      ss: 51,
    },
    "0484": {
      id: "0484",
      i: 10,
      s: 15,
      ss: 35,
    },
    "0485": {
      id: "0485",
      i: 6,
      s: 12,
      ss: 18,
    },
    "0486": {
      id: "0486",
      i: 7,
      s: 8,
      ss: 42,
    },
    "0487": {
      id: "0487",
      i: 6,
      s: 7,
      ss: 32,
    },
    "0488": {
      id: "0488",
      i: 0,
      s: 0,
      ss: 0,
    },
    "0489": {
      id: "0489",
      i: 9,
      s: 10,
      ss: 45,
    },
    "0491": {
      id: "0491",
      i: 6,
      s: 6,
      ss: 77,
    },
    "0493": {
      id: "0493",
      i: 6,
      s: 22,
      ss: 89,
    },
    "0495": {
      id: "0495",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0496": {
      id: "0496",
      i: 6,
      s: 12,
      ss: 83,
    },
    "0497": {
      id: "0497",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0498": {
      id: "0498",
      i: 2,
      s: 18,
      ss: 56,
    },
    "0499": {
      id: "0499",
      i: 6,
      s: 11,
      ss: 14,
    },
    "0500": {
      id: "0500",
      i: 9,
      s: 25,
      ss: 90,
    },
    "0503": {
      id: "0503",
      i: 5,
      s: 5,
      ss: 7,
    },
    "0505": {
      id: "0505",
      i: 7,
      s: 8,
      ss: 81,
    },
    "0506": {
      id: "0506",
      i: 8,
      s: 9,
      ss: 66,
    },
    "0508": {
      id: "0508",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0509": {
      id: "0509",
      i: 7,
      s: 21,
      ss: 76,
    },
    "0510": {
      id: "0510",
      i: 3,
      s: 20,
      ss: 29,
    },
    "0511": {
      id: "0511",
      i: 6,
      s: 6,
      ss: 91,
    },
    "0512": {
      id: "0512",
      i: 5,
      s: 5,
      ss: 7,
    },
    "0513": {
      id: "0513",
      i: 6,
      s: 27,
      ss: 70,
    },
    "0515": {
      id: "0515",
      i: 9,
      s: 10,
      ss: 19,
    },
    "0517": {
      id: "0517",
      i: 9,
      s: 17,
      ss: 82,
    },
    "0518": {
      id: "0518",
      i: 6,
      s: 27,
      ss: 50,
    },
    "0519": {
      id: "0519",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0520": {
      id: "0520",
      i: 6,
      s: 7,
      ss: 22,
    },
    "0521": {
      id: "0521",
      i: 7,
      s: 8,
      ss: 60,
    },
    "0522": {
      id: "0522",
      i: 10,
      s: 23,
      ss: 37,
    },
    "0524": {
      id: "0524",
      i: 4,
      s: 4,
      ss: 6,
    },
    "0525": {
      id: "0525",
      i: 6,
      s: 7,
      ss: 28,
    },
    "0526": {
      id: "0526",
      i: 6,
      s: 22,
      ss: 54,
    },
    "0527": {
      id: "0527",
      i: 1,
      s: 1,
      ss: 39,
    },
    "0528": {
      id: "0528",
      i: 6,
      s: 27,
      ss: 55,
    },
    "0529": {
      id: "0529",
      i: 10,
      s: 26,
      ss: 48,
    },
    "0530": {
      id: "0530",
      i: 0,
      s: 0,
      ss: 0,
    },
    "0531": {
      id: "0531",
      i: 6,
      s: 12,
      ss: 83,
    },
    "0532": {
      id: "0532",
      i: 9,
      s: 10,
      ss: 19,
    },
    "0533": {
      id: "0533",
      i: 6,
      s: 27,
      ss: 50,
    },
    "0535": {
      id: "0535",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0536": {
      id: "0536",
      i: 10,
      s: 15,
      ss: 52,
    },
    "0538": {
      id: "0538",
      i: 6,
      s: 7,
      ss: 22,
    },
    "0539": {
      id: "0539",
      i: 6,
      s: 27,
      ss: 55,
    },
    "0540": {
      id: "0540",
      i: 6,
      s: 27,
      ss: 50,
    },
    "0542": {
      id: "0542",
      i: 6,
      s: 7,
      ss: 9,
    },
    "0543": {
      id: "0543",
      i: 6,
      s: 22,
      ss: 54,
    },
    "0544": {
      id: "0544",
      i: 9,
      s: 25,
      ss: 90,
    },
    "0546": {
      id: "0546",
      i: 8,
      s: 9,
      ss: 12,
    },
    "0547": {
      id: "0547",
      i: 6,
      s: 6,
      ss: 77,
    },
    "0548": {
      id: "0548",
      i: 9,
      s: 25,
      ss: 46,
    },
    "0550": {
      id: "0550",
      i: 6,
      s: 6,
      ss: 44,
    },
    "0551": {
      id: "0551",
      i: 6,
      s: 27,
      ss: 65,
    },
    "0552": {
      id: "0552",
      i: 10,
      s: 26,
      ss: 48,
    },
    "0553": {
      id: "0553",
      i: 10,
      s: 26,
      ss: 48,
    },
    "0554": {
      id: "0554",
      i: 11,
      s: 24,
      ss: 64,
    },
    "0555": {
      id: "0555",
      i: 6,
      s: 7,
      ss: 11,
    },
    "0556": {
      id: "0556",
      i: 9,
      s: 10,
      ss: 57,
    },
    "0557": {
      id: "0557",
      i: 5,
      s: 30,
      ss: 73,
    },
    "0558": {
      id: "0558",
      i: 9,
      s: 10,
      ss: 19,
    },
    "0559": {
      id: "0559",
      i: 6,
      s: 7,
      ss: 9,
    },
    "0560": {
      id: "0560",
      i: 9,
      s: 25,
      ss: 53,
    },
    "0563": {
      id: "0563",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0564": {
      id: "0564",
      i: 6,
      s: 16,
      ss: 23,
    },
    "0565": {
      id: "0565",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0567": {
      id: "0567",
      i: 11,
      s: 24,
      ss: 40,
    },
    "0568": {
      id: "0568",
      i: 11,
      s: 24,
      ss: 64,
    },
    "0570": {
      id: "0570",
      i: 5,
      s: 5,
      ss: 79,
    },
    "0571": {
      id: "0571",
      i: 6,
      s: 6,
      ss: 77,
    },
    "0572": {
      id: "0572",
      i: 3,
      s: 20,
      ss: 31,
    },
    "0573": {
      id: "0573",
      i: 6,
      s: 7,
      ss: 22,
    },
    "0574": {
      id: "0574",
      i: 5,
      s: 5,
      ss: 7,
    },
    "0575": {
      id: "0575",
      i: 5,
      s: 5,
      ss: 7,
    },
    "0576": {
      id: "0576",
      i: 9,
      s: 25,
      ss: 46,
    },
    "0577": {
      id: "0577",
      i: 2,
      s: 18,
      ss: 56,
    },
    "0578": {
      id: "0578",
      i: 11,
      s: 19,
      ss: 27,
    },
    "0579": {
      id: "0579",
      i: 1,
      s: 1,
      ss: 1,
    },
    "0580": {
      id: "0580",
      i: 9,
      s: 10,
      ss: 19,
    },
    "0581": {
      id: "0581",
      i: 7,
      s: 8,
      ss: 10,
    },
    "0582": {
      id: "0582",
      i: 6,
      s: 7,
      ss: 9,
    },
    "0583": {
      id: "0583",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0585": {
      id: "0585",
      i: 3,
      s: 20,
      ss: 29,
    },
    "0586": {
      id: "0586",
      i: 9,
      s: 10,
      ss: 57,
    },
    "0588": {
      id: "0588",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0589": {
      id: "0589",
      i: 2,
      s: 18,
      ss: 69,
    },
    "0590": {
      id: "0590",
      i: 6,
      s: 22,
      ss: 36,
    },
    "0591": {
      id: "0591",
      i: 9,
      s: 10,
      ss: 19,
    },
    "0592": {
      id: "0592",
      i: 6,
      s: 27,
      ss: 50,
    },
    "0593": {
      id: "0593",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0595": {
      id: "0595",
      i: 10,
      s: 23,
      ss: 37,
    },
    "0596": {
      id: "0596",
      i: 10,
      s: 15,
      ss: 35,
    },
    "0598": {
      id: "0598",
      i: 9,
      s: 25,
      ss: 53,
    },
    "0599": {
      id: "0599",
      i: 2,
      s: 18,
      ss: 56,
    },
    "0600": {
      id: "0600",
      i: 11,
      s: 24,
      ss: 64,
    },
    "0601": {
      id: "0601",
      i: 7,
      s: 8,
      ss: 60,
    },
    "0602": {
      id: "0602",
      i: 6,
      s: 22,
      ss: 59,
    },
    "0603": {
      id: "0603",
      i: 1,
      s: 1,
      ss: 2,
    },
    "0604": {
      id: "0604",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0605": {
      id: "0605",
      i: 3,
      s: 20,
      ss: 38,
    },
    "0606": {
      id: "0606",
      i: 2,
      s: 2,
      ss: 47,
    },
    "0607": {
      id: "0607",
      i: 9,
      s: 10,
      ss: 57,
    },
    "0608": {
      id: "0608",
      i: 6,
      s: 27,
      ss: 50,
    },
    "0609": {
      id: "0609",
      i: 7,
      s: 21,
      ss: 30,
    },
    "0610": {
      id: "0610",
      i: 2,
      s: 18,
      ss: 69,
    },
    "0611": {
      id: "0611",
      i: 1,
      s: 1,
      ss: 39,
    },
    "0612": {
      id: "0612",
      i: 3,
      s: 20,
      ss: 31,
    },
    "0613": {
      id: "0613",
      i: 3,
      s: 20,
      ss: 31,
    },
    "0616": {
      id: "0616",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0617": {
      id: "0617",
      i: 6,
      s: 7,
      ss: 9,
    },
    "0618": {
      id: "0618",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0619": {
      id: "0619",
      i: 3,
      s: 20,
      ss: 29,
    },
    "0620": {
      id: "0620",
      i: 0,
      s: 0,
      ss: 0,
    },
    "0621": {
      id: "0621",
      i: 7,
      s: 29,
      ss: 72,
    },
    "0622": {
      id: "0622",
      i: 3,
      s: 20,
      ss: 29,
    },
    "0623": {
      id: "0623",
      i: 6,
      s: 6,
      ss: 44,
    },
    "0625": {
      id: "0625",
      i: "",
      s: "",
      ss: 85,
    },
    "0626": {
      id: "0626",
      i: 3,
      s: 3,
      ss: 4,
    },
    "0627": {
      id: "0627",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0628": {
      id: "0628",
      i: 3,
      s: 20,
      ss: 38,
    },
    "0629": {
      id: "0629",
      i: 3,
      s: 20,
      ss: 38,
    },
    "0630": {
      id: "0630",
      i: 2,
      s: 18,
      ss: 56,
    },
    "0631": {
      id: "0631",
      i: 9,
      s: 10,
      ss: 16,
    },
    "0632": {
      id: "0632",
      i: 11,
      s: 24,
      ss: 40,
    },
    "0633": {
      id: "0633",
      i: 4,
      s: 4,
      ss: 88,
    },
    "0635": {
      id: "0635",
      i: 6,
      s: 12,
      ss: 15,
    },
    "0636": {
      id: "0636",
      i: 9,
      s: 25,
      ss: 90,
    },
    "0637": {
      id: "0637",
      i: 7,
      s: 8,
      ss: 60,
    },
    "0638": {
      id: "0638",
      i: 6,
      s: 12,
      ss: 68,
    },
    "0639": {
      id: "0639",
      i: 11,
      s: 19,
      ss: 27,
    },
    "0640": {
      id: "0640",
      i: 7,
      s: 21,
      ss: 30,
    },
    "0641": {
      id: "0641",
      i: 9,
      s: 10,
      ss: 19,
    },
    "0643": {
      id: "0643",
      i: 6,
      s: 27,
      ss: 50,
    },
    "0645": {
      id: "0645",
      i: 11,
      s: 19,
      ss: 27,
    },
    "0646": {
      id: "0646",
      i: 1,
      s: 1,
      ss: 71,
    },
    "0648": {
      id: "0648",
      i: 5,
      s: 30,
      ss: 92,
    },
    "0650": {
      id: "0650",
      i: 11,
      s: 24,
      ss: 40,
    },
    "0651": {
      id: "0651",
      i: 9,
      s: 10,
      ss: 16,
    },
    "0653": {
      id: "0653",
      i: 8,
      s: 13,
      ss: 84,
    },
    "0655": {
      id: "0655",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0656": {
      id: "0656",
      i: 0,
      s: 0,
      ss: 0,
    },
    "0657": {
      id: "0657",
      i: 6,
      s: 7,
      ss: 22,
    },
    "0658": {
      id: "0658",
      i: 9,
      s: 10,
      ss: 57,
    },
    "0659": {
      id: "0659",
      i: 0,
      s: 0,
      ss: 0,
    },
    "0660": {
      id: "0660",
      i: 7,
      s: 21,
      ss: 30,
    },
    "0661": {
      id: "0661",
      i: 7,
      s: 8,
      ss: 81,
    },
    "0662": {
      id: "0662",
      i: 3,
      s: 28,
      ss: 67,
    },
    "0663": {
      id: "0663",
      i: 0,
      s: 0,
      ss: 0,
    },
    "0665": {
      id: "0665",
      i: 3,
      s: 20,
      ss: 29,
    },
    "0666": {
      id: "0666",
      i: 3,
      s: 20,
      ss: 31,
    },
    "0667": {
      id: "0667",
      i: 6,
      s: 11,
      ss: 14,
    },
    "0668": {
      id: "0668",
      i: 3,
      s: 20,
      ss: 38,
    },
    "0669": {
      id: "0669",
      i: 6,
      s: 12,
      ss: 68,
    },
    "0670": {
      id: "0670",
      i: 6,
      s: 7,
      ss: 75,
    },
    "0672": {
      id: "0672",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0673": {
      id: "0673",
      i: 5,
      s: 30,
      ss: 92,
    },
    "0674": {
      id: "0674",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0675": {
      id: "0675",
      i: 9,
      s: 10,
      ss: 19,
    },
    "0676": {
      id: "0676",
      i: 6,
      s: 27,
      ss: 65,
    },
    "0677": {
      id: "0677",
      i: 8,
      s: 14,
      ss: 33,
    },
    "0678": {
      id: "0678",
      i: 6,
      s: 7,
      ss: 32,
    },
    "0679": {
      id: "0679",
      i: 9,
      s: 10,
      ss: 19,
    },
    "0680": {
      id: "0680",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0681": {
      id: "0681",
      i: 1,
      s: 1,
      ss: 2,
    },
    "0682": {
      id: "0682",
      i: 8,
      s: 14,
      ss: 33,
    },
    "0683": {
      id: "0683",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0684": {
      id: "0684",
      i: 6,
      s: 12,
      ss: 68,
    },
    "0685": {
      id: "0685",
      i: 6,
      s: 6,
      ss: 8,
    },
    "0686": {
      id: "0686",
      i: 1,
      s: 1,
      ss: 39,
    },
    "0687": {
      id: "0687",
      i: 2,
      s: 18,
      ss: 69,
    },
    "0688": {
      id: "0688",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0689": {
      id: "0689",
      i: 3,
      s: 20,
      ss: 38,
    },
    "0690": {
      id: "0690",
      i: 5,
      s: 5,
      ss: 7,
    },
    "0691": {
      id: "0691",
      i: 2,
      s: 18,
      ss: 25,
    },
    "0693": {
      id: "0693",
      i: 6,
      s: 22,
      ss: 54,
    },
    "0694": {
      id: "0694",
      i: 6,
      s: 7,
      ss: 75,
    },
    "0695": {
      id: "0695",
      i: 2,
      s: 18,
      ss: 25,
    },
    "0696": {
      id: "0696",
      i: 10,
      s: 15,
      ss: 21,
    },
    "0697": {
      id: "0697",
      i: 3,
      s: 20,
      ss: 31,
    },
    "0698": {
      id: "0698",
      i: 9,
      s: 10,
      ss: 43,
    },
    "0699": {
      id: "0699",
      i: 6,
      s: 7,
      ss: 32,
    },
    "0700": {
      id: "0700",
      i: 10,
      s: 15,
      ss: 52,
    },
    "0701": {
      id: "0701",
      i: 2,
      s: 18,
      ss: 25,
    },
    "0702": {
      id: "0702",
      i: 11,
      s: 24,
      ss: 40,
    },
    "0703": {
      id: "0703",
      i: 6,
      s: 7,
      ss: 22,
    },
    "0704": {
      id: "0704",
      i: 11,
      s: 19,
      ss: 27,
    },
    "0706": {
      id: "0706",
      i: 7,
      s: 21,
      ss: 41,
    },
    "0707": {
      id: "0707",
      i: 6,
      s: 27,
      ss: 55,
    },
    "0708": {
      id: "0708",
      i: 5,
      s: 30,
      ss: 73,
    },
    "0709": {
      id: "0709",
      i: 6,
      s: 27,
      ss: 50,
    },
    "0710": {
      id: "0710",
      i: 10,
      s: 26,
      ss: 78,
    },
    "0711": {
      id: "0711",
      i: 2,
      s: 18,
      ss: 69,
    },
    "0712": {
      id: "0712",
      i: 9,
      s: 10,
      ss: 58,
    },
    "0713": {
      id: "0713",
      i: 6,
      s: 12,
      ss: 83,
    },
    "0715": {
      id: "0715",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0716": {
      id: "0716",
      i: 9,
      s: 10,
      ss: 45,
    },
    "0717": {
      id: "0717",
      i: 3,
      s: 20,
      ss: 29,
    },
    "0718": {
      id: "0718",
      i: 5,
      s: 30,
      ss: 73,
    },
    "0719": {
      id: "0719",
      i: 5,
      s: 5,
      ss: 7,
    },
    "0720": {
      id: "0720",
      i: 6,
      s: 16,
      ss: 62,
    },
    "0721": {
      id: "0721",
      i: 3,
      s: 20,
      ss: 31,
    },
    "0722": {
      id: "0722",
      i: 5,
      s: 30,
      ss: 73,
    },
    "0723": {
      id: "0723",
      i: 7,
      s: 21,
      ss: 41,
    },
    "0724": {
      id: "0724",
      i: 10,
      s: 23,
      ss: 37,
    },
    "0725": {
      id: "0725",
      i: 9,
      s: 10,
      ss: 19,
    },
    "0726": {
      id: "0726",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0727": {
      id: "0727",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0728": {
      id: "0728",
      i: 4,
      s: 4,
      ss: 6,
    },
    "0729": {
      id: "0729",
      i: 9,
      s: 10,
      ss: 45,
    },
    "0730": {
      id: "0730",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0731": {
      id: "0731",
      i: 7,
      s: 21,
      ss: 93,
    },
    "0732": {
      id: "0732",
      i: 9,
      s: 10,
      ss: 43,
    },
    "0733": {
      id: "0733",
      i: 2,
      s: 2,
      ss: 80,
    },
    "0736": {
      id: "0736",
      i: 3,
      s: 20,
      ss: 38,
    },
    "0737": {
      id: "0737",
      i: 9,
      s: 25,
      ss: 46,
    },
    "0738": {
      id: "0738",
      i: 6,
      s: 27,
      ss: 65,
    },
    "0743": {
      id: "0743",
      i: 2,
      s: 18,
      ss: 25,
    },
    "0745": {
      id: "0745",
      i: 6,
      s: 6,
      ss: 44,
    },
    "0746": {
      id: "0746",
      i: 7,
      s: 21,
      ss: 30,
    },
    "0747": {
      id: "0747",
      i: 2,
      s: 18,
      ss: 56,
    },
    "0750": {
      id: "0750",
      i: 2,
      s: 18,
      ss: 56,
    },
    "0751": {
      id: "0751",
      i: 6,
      s: 12,
      ss: 18,
    },
    "0752": {
      id: "0752",
      i: 6,
      s: 6,
      ss: 44,
    },
    "0753": {
      id: "0753",
      i: 6,
      s: 7,
      ss: 75,
    },
    "0754": {
      id: "0754",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0755": {
      id: "0755",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0756": {
      id: "0756",
      i: 8,
      s: 9,
      ss: 66,
    },
    "0757": {
      id: "0757",
      i: 9,
      s: 10,
      ss: 58,
    },
    "0758": {
      id: "0758",
      i: 7,
      s: 8,
      ss: 60,
    },
    "0759": {
      id: "0759",
      i: 8,
      s: 13,
      ss: 17,
    },
    "0760": {
      id: "0760",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0762": {
      id: "0762",
      i: 4,
      s: 4,
      ss: 6,
    },
    "0763": {
      id: "0763",
      i: 10,
      s: 26,
      ss: 48,
    },
    "0764": {
      id: "0764",
      i: 0,
      s: 0,
      ss: 0,
    },
    "0765": {
      id: "0765",
      i: 6,
      s: 12,
      ss: 15,
    },
    "0766": {
      id: "0766",
      i: 3,
      s: 20,
      ss: 38,
    },
    "0767": {
      id: "0767",
      i: 3,
      s: 20,
      ss: 38,
    },
    "0768": {
      id: "0768",
      i: 3,
      s: 20,
      ss: 31,
    },
    "0769": {
      id: "0769",
      i: 7,
      s: 8,
      ss: 60,
    },
    "0770": {
      id: "0770",
      i: 3,
      s: 20,
      ss: 31,
    },
    "0771": {
      id: "0771",
      i: 10,
      s: 15,
      ss: 21,
    },
    "0772": {
      id: "0772",
      i: 6,
      s: 6,
      ss: 8,
    },
    "0775": {
      id: "0775",
      i: 5,
      s: 5,
      ss: 7,
    },
    "0776": {
      id: "0776",
      i: 6,
      s: 12,
      ss: 83,
    },
    "0777": {
      id: "0777",
      i: 10,
      s: 15,
      ss: 35,
    },
    "0778": {
      id: "0778",
      i: "",
      s: "",
      ss: 85,
    },
    "0780": {
      id: "0780",
      i: 6,
      s: 7,
      ss: 32,
    },
    "0784": {
      id: "0784",
      i: 2,
      s: 18,
      ss: 56,
    },
    "0787": {
      id: "0787",
      i: 6,
      s: 27,
      ss: 50,
    },
    "0788": {
      id: "0788",
      i: 4,
      s: 4,
      ss: 88,
    },
    "0789": {
      id: "0789",
      i: 6,
      s: 27,
      ss: 70,
    },
    "0794": {
      id: "0794",
      i: 9,
      s: 17,
      ss: 24,
    },
    "0797": {
      id: "0797",
      i: 10,
      s: 15,
      ss: 35,
    },
    "0798": {
      id: "0798",
      i: 2,
      s: 2,
      ss: 47,
    },
    "0799": {
      id: "0799",
      i: 10,
      s: 15,
      ss: 35,
    },
    "0800": {
      id: "0800",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0802": {
      id: "0802",
      i: 10,
      s: 15,
      ss: 35,
    },
    "0803": {
      id: "0803",
      i: 7,
      s: 8,
      ss: 10,
    },
    "0804": {
      id: "0804",
      i: 3,
      s: 20,
      ss: 29,
    },
    "0806": {
      id: "0806",
      i: 3,
      s: 20,
      ss: 31,
    },
    "0807": {
      id: "0807",
      i: 1,
      s: 1,
      ss: 71,
    },
    "0808": {
      id: "0808",
      i: "",
      s: "",
      ss: 85,
    },
    "0809": {
      id: "0809",
      i: 7,
      s: 21,
      ss: 76,
    },
    "0810": {
      id: "0810",
      i: 3,
      s: 20,
      ss: 31,
    },
    "0811": {
      id: "0811",
      i: 6,
      s: 6,
      ss: 8,
    },
    "0812": {
      id: "0812",
      i: 3,
      s: 20,
      ss: 31,
    },
    "0813": {
      id: "0813",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0814": {
      id: "0814",
      i: 8,
      s: 13,
      ss: 17,
    },
    "0815": {
      id: "0815",
      i: 7,
      s: 29,
      ss: 72,
    },
    "0817": {
      id: "0817",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0818": {
      id: "0818",
      i: 10,
      s: 15,
      ss: 52,
    },
    "0819": {
      id: "0819",
      i: 9,
      s: 10,
      ss: 58,
    },
    "0820": {
      id: "0820",
      i: "",
      s: "",
      ss: 85,
    },
    "0821": {
      id: "0821",
      i: 3,
      s: 20,
      ss: 29,
    },
    "0822": {
      id: "0822",
      i: 9,
      s: 10,
      ss: 19,
    },
    "0823": {
      id: "0823",
      i: "",
      s: "",
      ss: 85,
    },
    "0825": {
      id: "0825",
      i: 6,
      s: 22,
      ss: 59,
    },
    "0826": {
      id: "0826",
      i: 7,
      s: 8,
      ss: 10,
    },
    "0827": {
      id: "0827",
      i: 7,
      s: 21,
      ss: 76,
    },
    "0828": {
      id: "0828",
      i: 8,
      s: 9,
      ss: 61,
    },
    "0829": {
      id: "0829",
      i: 8,
      s: 9,
      ss: 26,
    },
    "0830": {
      id: "0830",
      i: 2,
      s: 18,
      ss: 56,
    },
    "0831": {
      id: "0831",
      i: 8,
      s: 13,
      ss: 17,
    },
    "0832": {
      id: "0832",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0833": {
      id: "0833",
      i: 6,
      s: 12,
      ss: 68,
    },
    "0834": {
      id: "0834",
      i: 8,
      s: 9,
      ss: 26,
    },
    "0836": {
      id: "0836",
      i: 1,
      s: 1,
      ss: 1,
    },
    "0837": {
      id: "0837",
      i: 6,
      s: 12,
      ss: 83,
    },
    "0838": {
      id: "0838",
      i: 9,
      s: 10,
      ss: 19,
    },
    "0839": {
      id: "0839",
      i: 6,
      s: 11,
      ss: 14,
    },
    "0840": {
      id: "0840",
      i: 9,
      s: 10,
      ss: 19,
    },
    "0841": {
      id: "0841",
      i: 9,
      s: 10,
      ss: 58,
    },
    "0842": {
      id: "0842",
      i: 9,
      s: 10,
      ss: 58,
    },
    "0844": {
      id: "0844",
      i: 6,
      s: 27,
      ss: 50,
    },
    "0845": {
      id: "0845",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0846": {
      id: "0846",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0848": {
      id: "0848",
      i: 6,
      s: 22,
      ss: 59,
    },
    "0850": {
      id: "0850",
      i: 9,
      s: 10,
      ss: 19,
    },
    "0851": {
      id: "0851",
      i: 3,
      s: 20,
      ss: 29,
    },
    "0852": {
      id: "0852",
      i: 11,
      s: 24,
      ss: 40,
    },
    "0853": {
      id: "0853",
      i: 5,
      s: 30,
      ss: 92,
    },
    "0854": {
      id: "0854",
      i: 9,
      s: 10,
      ss: 19,
    },
    "0855": {
      id: "0855",
      i: 1,
      s: 1,
      ss: 71,
    },
    "0856": {
      id: "0856",
      i: 10,
      s: 15,
      ss: 21,
    },
    "0857": {
      id: "0857",
      i: 11,
      s: 24,
      ss: 40,
    },
    "0858": {
      id: "0858",
      i: 5,
      s: 5,
      ss: 7,
    },
    "0859": {
      id: "0859",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0860": {
      id: "0860",
      i: 6,
      s: 27,
      ss: 70,
    },
    "0861": {
      id: "0861",
      i: 10,
      s: 15,
      ss: 21,
    },
    "0862": {
      id: "0862",
      i: 10,
      s: 15,
      ss: 21,
    },
    "0863": {
      id: "0863",
      i: 3,
      s: 20,
      ss: 63,
    },
    "0864": {
      id: "0864",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0865": {
      id: "0865",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0866": {
      id: "0866",
      i: 11,
      s: 19,
      ss: 27,
    },
    "0867": {
      id: "0867",
      i: 5,
      s: 5,
      ss: 7,
    },
    "0868": {
      id: "0868",
      i: 9,
      s: 10,
      ss: 19,
    },
    "0869": {
      id: "0869",
      i: 6,
      s: 12,
      ss: 15,
    },
    "0871": {
      id: "0871",
      i: 2,
      s: 18,
      ss: 69,
    },
    "0872": {
      id: "0872",
      i: 6,
      s: 16,
      ss: 23,
    },
    "0873": {
      id: "0873",
      i: 2,
      s: 2,
      ss: 47,
    },
    "0874": {
      id: "0874",
      i: 5,
      s: 5,
      ss: 79,
    },
    "0875": {
      id: "0875",
      i: 8,
      s: 14,
      ss: 33,
    },
    "0876": {
      id: "0876",
      i: 5,
      s: 30,
      ss: 92,
    },
    "0878": {
      id: "0878",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0880": {
      id: "0880",
      i: 6,
      s: 7,
      ss: 11,
    },
    "0881": {
      id: "0881",
      i: 6,
      s: 22,
      ss: 54,
    },
    "0882": {
      id: "0882",
      i: 0,
      s: 0,
      ss: 0,
    },
    "0883": {
      id: "0883",
      i: 11,
      s: 24,
      ss: 40,
    },
    "0884": {
      id: "0884",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0885": {
      id: "0885",
      i: 10,
      s: 15,
      ss: 35,
    },
    "0886": {
      id: "0886",
      i: 8,
      s: 9,
      ss: 61,
    },
    "0887": {
      id: "0887",
      i: 6,
      s: 22,
      ss: 36,
    },
    "0888": {
      id: "0888",
      i: 6,
      s: 6,
      ss: 91,
    },
    "0889": {
      id: "0889",
      i: 9,
      s: 10,
      ss: 19,
    },
    "0891": {
      id: "0891",
      i: 6,
      s: 22,
      ss: 51,
    },
    "0893": {
      id: "0893",
      i: 7,
      s: 8,
      ss: 10,
    },
    "0894": {
      id: "0894",
      i: 9,
      s: 10,
      ss: 19,
    },
    "0895": {
      id: "0895",
      i: 9,
      s: 10,
      ss: 57,
    },
    "0896": {
      id: "0896",
      i: 2,
      s: 18,
      ss: 56,
    },
    "0897": {
      id: "0897",
      i: 5,
      s: 5,
      ss: 79,
    },
    "0898": {
      id: "0898",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0899": {
      id: "0899",
      i: 2,
      s: 2,
      ss: 5,
    },
    "0900": {
      id: "0900",
      i: 3,
      s: 20,
      ss: 38,
    },
    "0901": {
      id: "0901",
      i: 3,
      s: 20,
      ss: 31,
    },
    "0902": {
      id: "0902",
      i: 1,
      s: 1,
      ss: 1,
    },
    "0904": {
      id: "0904",
      i: 8,
      s: 14,
      ss: 33,
    },
    "0905": {
      id: "0905",
      i: 3,
      s: 20,
      ss: 31,
    },
    "0906": {
      id: "0906",
      i: 9,
      s: 17,
      ss: 24,
    },
    "0907": {
      id: "0907",
      i: 6,
      s: 27,
      ss: 49,
    },
    "0908": {
      id: "0908",
      i: 0,
      s: 0,
      ss: 0,
    },
    "0909": {
      id: "0909",
      i: 10,
      s: 15,
      ss: 21,
    },
    "0910": {
      id: "0910",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0911": {
      id: "0911",
      i: 5,
      s: 5,
      ss: 7,
    },
    "0912": {
      id: "0912",
      i: 6,
      s: 12,
      ss: 18,
    },
    "0913": {
      id: "0913",
      i: 3,
      s: 20,
      ss: 31,
    },
    "0914": {
      id: "0914",
      i: 2,
      s: 18,
      ss: 25,
    },
    "0915": {
      id: "0915",
      i: 9,
      s: 17,
      ss: 82,
    },
    "0916": {
      id: "0916",
      i: 1,
      s: 1,
      ss: 39,
    },
    "0918": {
      id: "0918",
      i: 6,
      s: 27,
      ss: 50,
    },
    "0919": {
      id: "0919",
      i: 5,
      s: 30,
      ss: 73,
    },
    "0921": {
      id: "0921",
      i: 6,
      s: 12,
      ss: 68,
    },
    "0922": {
      id: "0922",
      i: 6,
      s: 11,
      ss: 34,
    },
    "0923": {
      id: "0923",
      i: 7,
      s: 21,
      ss: 93,
    },
    "0924": {
      id: "0924",
      i: 2,
      s: 18,
      ss: 56,
    },
    "0925": {
      id: "0925",
      i: 9,
      s: 25,
      ss: 90,
    },
    "0926": {
      id: "0926",
      i: 8,
      s: 14,
      ss: 33,
    },
    "0927": {
      id: "0927",
      i: 6,
      s: 12,
      ss: 18,
    },
    "0928": {
      id: "0928",
      i: 5,
      s: 5,
      ss: 94,
    },
    "0929": {
      id: "0929",
      i: 9,
      s: 10,
      ss: 19,
    },
    "0931": {
      id: "0931",
      i: 1,
      s: 1,
      ss: 39,
    },
    "0932": {
      id: "0932",
      i: 5,
      s: 5,
      ss: 7,
    },
    "0934": {
      id: "0934",
      i: 11,
      s: 24,
      ss: 64,
    },
    "0935": {
      id: "0935",
      i: 9,
      s: 25,
      ss: 53,
    },
    "0936": {
      id: "0936",
      i: 9,
      s: 10,
      ss: 16,
    },
    "0938": {
      id: "0938",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0939": {
      id: "0939",
      i: 3,
      s: 3,
      ss: 4,
    },
    "0941": {
      id: "0941",
      i: 4,
      s: 4,
      ss: 6,
    },
    "0943": {
      id: "0943",
      i: 6,
      s: 12,
      ss: 83,
    },
    "0945": {
      id: "0945",
      i: 3,
      s: 28,
      ss: 67,
    },
    "0947": {
      id: "0947",
      i: 10,
      s: 26,
      ss: 48,
    },
    "0948": {
      id: "0948",
      i: 10,
      s: 26,
      ss: 48,
    },
    "0950": {
      id: "0950",
      i: 5,
      s: 5,
      ss: 7,
    },
    "0951": {
      id: "0951",
      i: 9,
      s: 10,
      ss: 58,
    },
    "0952": {
      id: "0952",
      i: 3,
      s: 20,
      ss: 29,
    },
    "0953": {
      id: "0953",
      i: 6,
      s: 6,
      ss: 77,
    },
    "0954": {
      id: "0954",
      i: 8,
      s: 9,
      ss: 12,
    },
    "0956": {
      id: "0956",
      i: 1,
      s: 1,
      ss: 2,
    },
    "0959": {
      id: "0959",
      i: 6,
      s: 7,
      ss: 11,
    },
    "0960": {
      id: "0960",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0966": {
      id: "0966",
      i: 3,
      s: 28,
      ss: 67,
    },
    "0967": {
      id: "0967",
      i: 1,
      s: 1,
      ss: 71,
    },
    "0968": {
      id: "0968",
      i: 9,
      s: 10,
      ss: 58,
    },
    "0969": {
      id: "0969",
      i: 8,
      s: 14,
      ss: 33,
    },
    "0970": {
      id: "0970",
      i: 6,
      s: 22,
      ss: 54,
    },
    "0973": {
      id: "0973",
      i: 6,
      s: 22,
      ss: 36,
    },
    "0974": {
      id: "0974",
      i: 6,
      s: 22,
      ss: 59,
    },
    "0975": {
      id: "0975",
      i: 11,
      s: 19,
      ss: 27,
    },
    "0976": {
      id: "0976",
      i: 7,
      s: 8,
      ss: 81,
    },
    "0978": {
      id: "0978",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0979": {
      id: "0979",
      i: 9,
      s: 10,
      ss: 58,
    },
    "0980": {
      id: "0980",
      i: 8,
      s: 13,
      ss: 17,
    },
    "0981": {
      id: "0981",
      i: 10,
      s: 23,
      ss: 37,
    },
    "0982": {
      id: "0982",
      i: 2,
      s: 2,
      ss: 47,
    },
    "0983": {
      id: "0983",
      i: 2,
      s: 18,
      ss: 56,
    },
    "0984": {
      id: "0984",
      i: 8,
      s: 13,
      ss: 17,
    },
    "0985": {
      id: "0985",
      i: 7,
      s: 8,
      ss: 81,
    },
    "0986": {
      id: "0986",
      i: 6,
      s: 27,
      ss: 70,
    },
    "0987": {
      id: "0987",
      i: 1,
      s: 1,
      ss: 39,
    },
    "0988": {
      id: "0988",
      i: 9,
      s: 25,
      ss: 90,
    },
    "0989": {
      id: "0989",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0990": {
      id: "0990",
      i: 7,
      s: 21,
      ss: 30,
    },
    "0991": {
      id: "0991",
      i: 1,
      s: 1,
      ss: 1,
    },
    "0992": {
      id: "0992",
      i: 10,
      s: 26,
      ss: 78,
    },
    "0993": {
      id: "0993",
      i: 3,
      s: 20,
      ss: 31,
    },
    "0994": {
      id: "0994",
      i: 2,
      s: 18,
      ss: 56,
    },
    "0995": {
      id: "0995",
      i: 9,
      s: 25,
      ss: 46,
    },
    "0996": {
      id: "0996",
      i: 2,
      s: 2,
      ss: 3,
    },
    "0997": {
      id: "0997",
      i: 9,
      s: 17,
      ss: 82,
    },
    "0998": {
      id: "0998",
      i: 3,
      s: 3,
      ss: 4,
    },
    "0999": {
      id: "0999",
      i: 6,
      s: 22,
      ss: 51,
    },
  },
  iDict: {
    0: "綜合企業",
    1: "公用事業",
    2: "地產建築業",
    3: "金融業",
    4: "電訊業",
    5: "醫療保健業",
    6: "非必需性消費",
    7: "原材料業",
    8: "必需性消費",
    9: "工業",
    10: "資訊科技業",
    11: "能源業",
  },
  sDict: {
    0: "綜合企業",
    1: "公用事業",
    2: "地產",
    3: "銀行",
    4: "電訊",
    5: "藥品及生物科技",
    6: "媒體及娛樂",
    7: "旅遊及消閒設施",
    8: "一般金屬及礦石",
    9: "食物飲品",
    10: "工業工程",
    11: "支援服務",
    12: "家庭電器及用品",
    13: "消費者主要零售商",
    14: "農業產品",
    15: "軟件服務",
    16: "汽車",
    17: "工用支援",
    18: "建築",
    19: "煤炭",
    20: "其他金融",
    21: "原材料",
    22: "專業零售",
    23: "半導體",
    24: "石油及天然氣",
    25: "工用運輸",
    26: "資訊科技器材",
    27: "紡織及服飾",
    28: "保險",
    29: "黃金及貴金屬",
    30: "醫療保健設備和服務",
  },
  ssDict: {
    0: "綜合企業",
    1: "電力",
    2: "燃氣供應",
    3: "地產發展商",
    4: "銀行",
    5: "地產投資",
    6: "電訊服務",
    7: "藥品",
    8: "出版",
    9: "酒店及度假村",
    10: "鋼鐵",
    11: "賭場及博彩",
    12: "食品添加劑",
    13: "航空航天與國防",
    14: "教育",
    15: "玩具及消閒用品",
    16: "重機械",
    17: "超市及便利店",
    18: "消費電子產品",
    19: "工業零件及器材",
    20: "禽畜飼料",
    21: "系統開發及資訊科技顧問",
    22: "餐飲",
    23: "汽車零件",
    24: "印刷及包裝",
    25: "建築材料",
    26: "包裝食品",
    27: "煤炭",
    28: "公共運輸",
    29: "證券及經紀",
    30: "特殊化工用品",
    31: "投資及資產管理",
    32: "旅遊及觀光",
    33: "農產品",
    34: "其他支援服務",
    35: "軟件開發",
    36: "其他零售商",
    37: "半導體",
    38: "信貸",
    39: "非傳統/ 可再生能源",
    40: "氣油生產商",
    41: "林業及木材",
    42: "鋁",
    43: "電子零件",
    44: "廣告及宣傳",
    45: "商業用車及貨車",
    46: "鐵路及公路",
    47: "物業服務及管理",
    48: "電訊設備",
    49: "其他服飾配件",
    50: "服裝",
    51: "服裝零售商",
    52: "電子商貿及互聯網服務",
    53: "航運及港口",
    54: "汽車零售商",
    55: "紡織品及布料",
    56: "樓宇建造",
    57: "環保工程",
    58: "新能源物料",
    59: "多元化零售商",
    60: "其他金屬及礦物",
    61: "酒精飲料",
    62: "汽車",
    63: "其他金融",
    64: "氣油設備與服務",
    65: "鞋類",
    66: "非酒精飲料",
    67: "保險",
    68: "家庭電器",
    69: "重型基建",
    70: "珠寶鐘錶",
    71: "水務",
    72: "黃金及貴金屬",
    73: "醫療及醫學美容服務",
    74: "禽畜肉類",
    75: "航空服務",
    76: "化肥及農用化合物",
    77: "影視娛樂",
    78: "電腦及週邊器材",
    79: "中醫藥",
    80: "地產代理",
    81: "銅",
    82: "採購及供應鏈管理",
    83: "家具",
    84: "個人護理",
    85: "-",
    86: "消閒及文娛設施",
    87: "乳製品",
    88: "衛星及無線通訊",
    89: "家居裝修零售商",
    90: "航空貨運及物流",
    91: "廣播",
    92: "醫療保健設備",
    93: "紙及紙製品",
    94: "生物技術",
    95: "煙草",
  },
  topicInfo: {
    lastUpdate: "2021/12/03 00:00",
    name: "stockSectorInfo",
  },
};
