import React, { useRef, useEffect, useState } from "react";
import _ from "lodash";
import { Search, Icon, Label } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import "./CategorySearchBarUS.css";
import PropTypes from "prop-types";
import { getAuth } from "firebase/auth";
import { analytics } from "../../config/fbConfig";
import { logEvent } from "firebase/analytics";
import { useSelector, useDispatch } from "react-redux";
import { selectAllTopic } from "../../features/topic/topicSlice";
import Fuse from "fuse.js";

const mktStr = ["US", "HK", "A"];
// const mktStr = ["HK"];
// const mktColor = ["rgb(33, 133, 208)"];

const mktColor = ["rgb(219, 40, 40)", "rgb(33, 133, 208)", "rgb(0, 0, 0)"];

const initialState = {
  loading: true,
  results: [],
  favourites: [],
  value: "",
  resultOpen: false,
  // market: 1,
  market: 2,
  fixMarket: false,
};

function reducer(state, action) {
  var retrievedObject;
  var jsonObject;
  var temp;

  switch (action.type) {
    case "ON_FOCUS":
    case "CLEAN_QUERY":
      if (action.query == null || action.query.length === 0) {
        retrievedObject = localStorage.getItem("history");

        var resultOpen = false;
        temp = {};
        if (state.favourites.length > 0) {
          temp["Favourites"] = { name: "最近搜尋", results: state.favourites };
          resultOpen = true;
        }
        if (retrievedObject) {
          jsonObject = JSON.parse(retrievedObject);
          var list = jsonObject["history"];
          if (list.length > 0) {
            temp["Recent"] = { name: "最近搜尋", results: list };
            resultOpen = true;
          }
        }
        if (resultOpen) {
          return {
            ...state,
            loading: false,
            resultOpen: true,
            results: temp,
            value: "",
            fixMarket: false,
          };
        } else {
          return {
            ...state,
            loading: false,
            resultOpen: true,
            fixMarket: false,
            value: "",
          };
        }
      } else {
        return {
          ...state,
          loading: false,
          resultOpen: true,
        };
      }

    case "ON_BLUR":
      return {
        ...state,
        loading: false,
        resultOpen: false,
      };

    case "START_SEARCH":
      return {
        ...state,
        loading: true,
        value: action.query,
        results: {},
      };

    case "FINISH_SEARCH":
      const result = {
        Search: {
          name: "搜尋",
          results: getFilteredResult(
            action.stockList,
            action.inputText,
            action.market
          ),
        },
      };
      return {
        ...state,
        loading: false,
        resultOpen: true,
        results: result,
      };
    case "FINISH_FETCHING":
      return {
        ...state,
        loading: false,
      };
    case "UPDATE_SELECTION":
      return {
        ...state,
        loading: false,
        resultOpen: false,
        results: {},
        value: action.selection,
        market: action.market,
        fixMarket: true,
      };
    case "ON_FAVOURITES":
      return {
        ...state,
        favourites: action.query,
      };
    case "CHANGE_MKT":
      if (state.fixMarket) {
        return {
          ...state,
        };
      }
      const nextMarket = (state.market + 1) % mktStr.length;
      if (action.inputText.length > 0) {
        const mktResult = {
          Search: {
            name: "搜尋",
            results: getFilteredResult(
              action.stockList,
              action.inputText,
              nextMarket
            ),
          },
        };
        return {
          ...state,
          results: mktResult,
          market: nextMarket,
        };
      }
      return {
        ...state,
        market: nextMarket,
      };
    default:
      throw new Error();
  }
}

const categoryLayoutRenderer = ({ categoryContent, resultsContent }) => {
  return (
    <div className="search-content">
      <div className="category-header">{categoryContent}</div>
      <div className="result-header">{resultsContent}</div>
    </div>
  );
};

categoryLayoutRenderer.propTypes = {
  categoryContent: PropTypes.node,
  resultsContent: PropTypes.node,
};

const categoryRenderer = ({ name }) => (
  <div className="category-content">{name}</div>
);

categoryRenderer.propTypes = {
  name: PropTypes.string,
};

const MarketLabel = (props) => {
  const mkt = parseInt(props.m);
  return (
    <div
      style={{
        margin: "auto",
      }}
    >
      <div
        style={{
          width: "24px",
          lineHeight: "24px",
          borderRadius: "12px",
          color: "white",
          textAlign: "center",
          backgroundColor: mktColor[mkt],
        }}
      >
        {mktStr[mkt]}
      </div>
    </div>
  );
};

const resultRenderer = (props) => {
  return (
    <div
      className="result-content"
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <MarketLabel m={props.m} />
      <div
        style={{
          flexGrow: 1,
          paddingLeft: "8px",
          paddingRight: "8px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            {props.symbol} {props.zh}
          </div>
          <div>{props.en}</div>
        </div>
      </div>
    </div>
  );
};
// </Link>

resultRenderer.propTypes = {
  zh: PropTypes.string,
  en: PropTypes.string,
  id: PropTypes.string,
  m: PropTypes.string,
  symbol: PropTypes.string,
};

const getFilteredResult = (stockList, inputText, market) => {
  // const isMatchMarket = (result) => market === 0;
  const isMatchMarket = (result) => market === 2 || result.m === "" + market;
  const search_result_after_market = _.concat(
    _.filter(stockList, isMatchMarket)
  );

  //   Old search code
  // const re = new RegExp(_.escapeRegExp(inputText), "i");
  // const isMatch = (result) =>
  //   re.test(result.id) || re.test(result.en) || re.test(result.zh);
  // const search_result = _.concat(_.filter(search_result_after_market, isMatch));
  const fuse = new Fuse(search_result_after_market, {
    shouldSort: true,
    threshold: 0.1,
    location: 0,
    distance: 100,
    keys: [
      { name: "id", weight: 2 },
      { name: "en", weight: 1 },
      { name: "zh", weight: 1 },
    ],
  });
  const search_result = fuse.search(inputText);

  // console.log(
  //   search_result.map((object) => {
  //     return { ...object.item, symbol: object.item.id };
  //   })
  // );
  const search_result_ex = search_result.map((object) => {
    return { ...object.item, symbol: object.item.id };
  });

  return search_result_ex;
};

function SearchBar(props) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { loading, results, value, resultOpen, market } = state;
  const [stockList, setStockList] = useState([]);
  const history = useHistory();
  const timeoutRef = React.useRef();
  const topicStatus = useSelector((state) => state.topic.status);
  const topics = useSelector(selectAllTopic);

  useEffect(() => {
    if (stockList.length === 0) {
      if (topicStatus === "succeeded") {
        const data = topics.find(
          (item) => item.topicInfo.name === "stockDict_all_n"
        );
        if (data && data["data"].length > 0) {
          setStockList(data["data"]);
          dispatch({ type: "FINISH_FETCHING" });
        }
      }
    }
  }, [topicStatus, topics, stockList]);

  const handleFocus = React.useCallback((e, data) => {
    dispatch({ type: "ON_FOCUS", query: "" + data.value });
  }, []);

  const handleBlur = React.useCallback((e, data) => {
    dispatch({ type: "ON_BLUR", query: "" });
  }, []);

  const handleSearchChange = React.useCallback(
    (event, data) => {
      clearTimeout(timeoutRef.current);
      dispatch({ type: "START_SEARCH", query: "" + data.value });

      timeoutRef.current = setTimeout(() => {
        if (data.value.length === 0) {
          dispatch({ type: "CLEAN_QUERY" });
          return;
        }

        dispatch({
          type: "FINISH_SEARCH",
          stockList: stockList,
          inputText: data.value,
          market: state.market,
        });
      }, 300);
    },
    [stockList, state.market]
  );

  React.useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const inputRef = useRef(null);

  const onClear = React.useCallback((e, data) => {
    inputRef.current.focus();
    dispatch({ type: "CLEAN_QUERY" });
  }, []);

  return (
    <div className="wrapper">
      <div
        className="iconfront"
        onClick={() =>
          dispatch({
            type: "CHANGE_MKT",
            stockList: stockList,
            inputText: state.value,
          })
        }
      >
        <MarketLabel m={state.market} />
      </div>
      <Search
        className="search-bar-us"
        loading={loading}
        onResultSelect={(e, data, isMobile) => {
          // console.log(data);
          dispatch({
            type: "UPDATE_SELECTION",
            selection:
              data.result.id + " " + data.result.zh + " " + data.result.en,
            market: data.result.m,
          });

          var retrievedObject = localStorage.getItem("history");
          var jsonObject = JSON.parse(retrievedObject);
          if (jsonObject && jsonObject["history"]) {
            // Remove exisiting item
            for (var i = jsonObject["history"].length - 1; i >= 0; i--) {
              if (jsonObject["history"][i].id === data.result.id) {
                jsonObject["history"].splice(i, 1);
              }
            }
            // Add new item
            jsonObject["history"].unshift({
              id: data.result.id,
              zh: data.result.zh,
              en: data.result.en,
              m: data.result.m,
            });
            // Remove old items
            while (jsonObject["history"].length > 10) {
              jsonObject["history"].pop();
            }
          } else {
            jsonObject = {
              history: [
                {
                  id: data.result.id,
                  zh: data.result.zh,
                  en: data.result.en,
                  m: data.result.m,
                },
              ],
            };
          }
          var str = JSON.stringify(jsonObject);

          logEvent(analytics, "search", {
            search_term: data.result.id + " " + data.result.zh,
          });

          localStorage.setItem("history", str);
          history.push("/stock/" + data.result.id);
          props.onHide();
        }}
        input={{
          input: { ref: inputRef },
        }}
        fluid={true}
        selectFirstResult={true}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onSearchChange={handleSearchChange}
        category={true}
        categoryLayoutRenderer={categoryLayoutRenderer}
        categoryRenderer={categoryRenderer}
        resultRenderer={resultRenderer}
        results={results}
        value={value}
        open={resultOpen}
        icon={<Icon name="close" inverted circular link onClick={onClear} />}
        placeholder="輸入股份代號或名稱"
      />
    </div>
  );
}

export default SearchBar;
