import './ShareholdingChangeByStockTable.css';
import React, { useEffect, useState } from 'react';
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import _ from 'lodash';
import { httpsCallable } from "firebase/functions";
import { Link } from "react-router-dom";
import { Table, Header, Icon, Divider, Loader } from 'semantic-ui-react'
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { useSelector, useDispatch } from 'react-redux'

import { functions } from "../../../config/fbConfig"
import SortableTable from "../SortableTable";
import { toDateString } from '../../../util/datetime'
import { formatSharePercentage, formatShare, } from '../../../util/dataFormatter'
import { fetchStockListData } from '../../../features/stock/stockListSlice'
import { fetchParticipantListData } from '../../../features/participant/participantListSlice'
import { fetchShareholdingDateListData } from '../../../features/shareholding/shareholdingDateListSlice'

const getShareholdingChangeByStock = httpsCallable(functions, 'shareholding_change_by_stock');

export default function ShareholdingChangeByStockTable(props) {
  const dispatch = useDispatch();

  // const stockListData = useSelector(state => state.stockList);
  const stockListStatus = useSelector(state => state.stockList.status);
  const participantListData = useSelector(state => state.participantList);
  const participantListStatus = useSelector(state => state.participantList.status);
  const shareholdingDateListData = useSelector(state => state.shareholdingDateList);
  const shareholdingDateListStatus = useSelector(state => state.shareholdingDateList.status);

  const [ shareholdingChangeByStock, setShareholdingChangeByStock ] = useState({list: []});
  const [ resultLoaded, setResultLoaded ] = useState(false);
  const [ selectFromDate , setSelectFromDate] = useState(new Date());
  const [ selectToDate , setSelectToDate] = useState(new Date());
  const [ isMobile, setMobile ] = useState(false);
  const stock_code = props.stockCode;

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return (() => {
        window.removeEventListener("resize", handleResize);
      }
    );
  }, []);

  const handleResize = () => {
    const widths = [window.innerWidth];
    if (window.screen?.width) {
      widths.push(window.screen?.width);
    }
    const width = Math.min(...widths);
    
    var isMobile = false;
    if (width < 800) {
      isMobile = true;
    }
    setMobile(isMobile);
  }

  useEffect(() => {
    if (stockListStatus === 'idle') {
      dispatch(fetchStockListData());
    } else if (stockListStatus === 'succeeded') {
    }
  }, [stockListStatus, dispatch]);

  useEffect(() => {
    if (participantListStatus === 'idle') {
      dispatch(fetchParticipantListData());
    } else if (participantListStatus === 'succeeded') {
    }
  }, [participantListStatus, dispatch]);

  useEffect(() => {
    if (shareholdingDateListStatus === 'idle') {
      dispatch(fetchShareholdingDateListData());
    } else if (shareholdingDateListStatus === 'succeeded') {
    }
  }, [shareholdingDateListStatus, dispatch]);

  let dataReady = false;
  if (stockListStatus === 'succeeded' &&
      participantListStatus === 'succeeded' &&
      shareholdingDateListStatus === 'succeeded') {
    dataReady = true;
  }

  useEffect(() => {
    const updateShareholdingChange = (target_from_date, target_to_date) => {
      getShareholdingChangeByStock({
        from_date: toDateString(target_from_date),
        to_date: toDateString(target_to_date),
        stock_code: parseInt(stock_code),
        language_code: "en",
      })
        .then((result) => {
          setResultLoaded(true);
          setShareholdingChangeByStock({list: result.data.data});
          if (result.data.data.length > 0) {
            setSelectFromDate(new Date(result.data.data[0].from_date));
            setSelectToDate(new Date(result.data.data[0].to_date));
          }
        })
        .catch((error) => {
          console.log("error");
          console.log(error);
        });
    };
    if (!resultLoaded) {
      updateShareholdingChange(selectFromDate, selectToDate);
    }
  }, [stock_code, resultLoaded, selectFromDate, selectToDate]);

  const datePickerFilterDate = (filtered_date) => {
    if (dataReady) {
      for (let i in shareholdingDateListData.shareholdingDateList) {
        if (toDateString(new Date(shareholdingDateListData.shareholdingDateList[i].date)) === toDateString(filtered_date)) {
          return true;
        }
      }
    }
    return false;
  };

  const handleFromDate = (event, data) => {
    if (data.value) {
      if (toDateString(selectFromDate) === toDateString(data.value)) {
        return;
      }
      if (!datePickerFilterDate(data.value)) {
        return;
      }
      setSelectFromDate(data.value);
      setResultLoaded(false);
    }
  };

  const handleToDate = (event, data) => {
    if (data.value) {
      if (toDateString(selectToDate) === toDateString(data.value)) {
        return;
      }
      if (!datePickerFilterDate(data.value)) {
        return;
      }
      setSelectToDate(data.value);
      setResultLoaded(false);
    }
  };

  const config = isMobile ? {
    col: [
      {content: '中介者', align: 'center', width: 8, prefix: '', postfix: '', },
      {content: '變動', align: 'right', width: 8, prefix: '', postfix: '', },
    ],
    advRowCount: 16,
  } : {
    col: [
      {content: '中介者', align: 'center', width: 5, prefix: '', postfix: '', },
      {content: '持倉變動(股)', align: 'right', width: 6, prefix: '', postfix: '', },
      {content: '持倉變動(%)', align: 'right', width: 5, prefix: '', postfix: '', },
    ],
    advRowCount: 16,
  };

  const sort = isMobile ? {
    col: [
      {type: 'string', sort: true},
      {type: 'key_absolute_number', sort: true},
    ],
    colIndex: 1,
    asc: false,
  } : {
    col: [
      {type: 'string', sort: true},
      {type: 'key_absolute_number', sort: true},
      {type: 'key_absolute_number', sort: true},
    ],
    colIndex: 2,
    asc: false,
  };

  const filter = isMobile ? {
    col: [
      {text: '過瀘中介者', type: 'match'},
      {text: '大於', type: 'key_absolute_larger'},
    ],
  } : {
    col: [
      {text: '過瀘中介者', type: 'match'},
      {text: '大於', type: 'key_absolute_larger'},
      {text: '大於', type: 'key_absolute_larger'},
    ],
  };


  const getData = (shareholdingChangeByStock) => {
    let data = [];
    let key = [];
    let positive = [];
    let postfix = [];
    if (dataReady) {
      for (let i in shareholdingChangeByStock.list) {

        let participantLink = <Link to={'/shareholding/shareholding_by_participant/' + shareholdingChangeByStock.list[i].participant_code}><Icon size='large' inverted name='chart line' color='blue' ></Icon></Link>;
        // participantLink = <></>;

        if (isMobile) {
          data.push([
            participantListData.participantMap[shareholdingChangeByStock.list[i].participant_code].short_name,
            formatSharePercentage(shareholdingChangeByStock.list[i].share_percentage_change),
          ]);
          key.push([
            null,
            shareholdingChangeByStock.list[i].share_percentage_change,
          ]);
          positive.push([
            shareholdingChangeByStock.list[i].share_percentage_change,
            shareholdingChangeByStock.list[i].share_percentage_change,
          ]);
          postfix.push([
            <><br />{participantLink}</>,
            <><br />({formatShare(shareholdingChangeByStock.list[i].share_change)})</>,
          ]);
        } else {
          data.push([
            participantListData.participantMap[shareholdingChangeByStock.list[i].participant_code].short_name,
            formatShare(shareholdingChangeByStock.list[i].share_change),
            formatSharePercentage(shareholdingChangeByStock.list[i].share_percentage_change),
          ]);
          key.push([
            null,
            shareholdingChangeByStock.list[i].share_change,
            shareholdingChangeByStock.list[i].share_percentage_change,
          ]);
          positive.push([
            shareholdingChangeByStock.list[i].share_percentage_change,
            shareholdingChangeByStock.list[i].share_percentage_change,
            shareholdingChangeByStock.list[i].share_percentage_change,
          ]);
          postfix.push([
            <> {participantLink}</>,
            <></>,
            <></>,
          ]);
        }
      }
    }
    return {
      data: data,
      key: key,
      positive: positive,
      postfix: postfix,
    };
  }

  const getChartData = (shareholdingChangeByStock) => {
    let posAgent = [];
    let negAgent = [];
    let posSharePercentage = [];
    let negSharePercentage = [];
    if (dataReady && resultLoaded) {
      for (let i in shareholdingChangeByStock.list) {
        if (shareholdingChangeByStock.list[i].share_percentage_change > 0) {
          if (posAgent.length < 10) {
            posAgent.push(participantListData.participantMap[shareholdingChangeByStock.list[i].participant_code].short_name);
            posSharePercentage.push(shareholdingChangeByStock.list[i].share_percentage_change);
          }
        } else if (shareholdingChangeByStock.list[i].share_percentage_change < 0) {
          if (negAgent.length < 10) {
            negAgent.push(participantListData.participantMap[shareholdingChangeByStock.list[i].participant_code].short_name);
            negSharePercentage.push(shareholdingChangeByStock.list[i].share_percentage_change);
          }
        }
      }
    }
    return {
      posAgent: posAgent,
      posSharePercentage: posSharePercentage,
      negAgent: negAgent,
      negSharePercentage: negSharePercentage,
    };
  }

  let data = getData(shareholdingChangeByStock);
  let chartData = getChartData(shareholdingChangeByStock);


  let posAgent = chartData.posAgent;
  let posShare = chartData.posSharePercentage;
  let negAgent = chartData.negAgent;
  let negShare = chartData.negSharePercentage;
  let yMax = _.max([_.max(posShare), Math.abs(_.min(negShare))]);

  const options = {

    chart: {
      height: 600,
      type: 'bar',
    },
    title: {
      text: '持倉改變',
      style: {
        display: 'none'
      }
    },
    xAxis: [{
      categories: negAgent,
      reversed: true,
      labels: {
        formatter: function () {
          var text = this.value,
            formatted = text.length > 15 ? text.substring(0, 15) + '...' : text;
          return '<div class="js-ellipse" style="width:150px; overflow:hidden" title="' + text + '">' + formatted + '</div>';
        },
        align: 'Left',
        y: -15,
        x: 30,
      },
      accessibility: {
        description: '淨賣出'
      },
      min: 0,
      max: negAgent.length - 1,
      visible: true,
      lineWidth: 0
    }, { // mirror axis on right side
      opposite: true,
      // reversed: true,
      categories: posAgent,
      linkedTo: 0,
      labels: {
        formatter: function () {
          var text = this.value,
            formatted = text.length > 15 ? text.substring(0, 15) + '...' : text;

          return '<div class="js-ellipse" style="text-align:right;" title="' + text + '">' + formatted + '</div>';
        },
        align: 'right',
        y: -15,
        x: -50,

      },
      accessibility: {
        description: '淨買入'
      },
      min: 0,
      max: posAgent.length - 1,
      visible: true,
      lineWidth: 0
    }],
    yAxis: [{
      width: '48%',
      max: 0,
      min: yMax * -1,
      title: {
        text: '%',
        align: 'low'
      },
      gridLineWidth: 0,
      lineWidth: 1,
    }, {
      width: '48%',
      left: '51%',
      max: yMax,
      min: 0,
      title: {
        text: '%',
        align: 'high'
      },
      offset: -0.35,
      gridLineWidth: 0,
      lineWidth: 1,
    }],

    plotOptions: {
      series: {
        stacking: 'normal',
        dataLabels: {
          allowOverlap: false,
          enabled: true,
          inside: false,
          useHTML: true,
          align: 'right',
          format: '{point.y}',
          color: 'black'
        },
        groupPadding: 0.2,
      },
    },

    tooltip: {
      formatter: function () {
        return '<b>' + this.series.chart.xAxis[this.series.index].categories[this.point.index] + '</b><br/>' +
          this.series.name + ' : ' + Highcharts.numberFormat(Math.abs(this.point.y), 1);
      },
    },
    series: [
      {
        name: '淨賣出',
        data: negShare,
        color: '#d91e19',
      }, {
        name: '淨買入',
        data: posShare,
        color: '#0072ce',
        yAxis: 1,
      },
    ],
    credits: {
      text: 'DaaSHK',
      style: {
        fontSize: '1em'
      },
    }
  };

  let title = "持倉變動";

  return (
    <>
      { !dataReady && (<Loader active inline='centered'>Loading</Loader>) }
      { dataReady && (
        <>
          <Divider horizontal>
            <Header as='h1'>
              {title}
            </Header>
          </Divider>
          <Table unstackable celled striped>
            <Table.Body>
              <Table.Row>
                <Table.Cell width={isMobile?4:3}>
                  由：
                </Table.Cell>
                <Table.Cell width={isMobile?12:13}>
                  <SemanticDatepicker
                    filterDate={datePickerFilterDate}
                    onChange={handleFromDate}
                    clearable={false}
                    showToday={false}
                    datePickerOnly={true}
                    value={selectFromDate}
                    clearOnSameDateClick={false}
                    disabled={!resultLoaded}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={isMobile?4:3}>
                  到：
                </Table.Cell>
                <Table.Cell width={isMobile?12:13}>
                  <SemanticDatepicker
                    filterDate={datePickerFilterDate}
                    onChange={handleToDate}
                    clearable={false}
                    showToday={false}
                    datePickerOnly={true}
                    value={selectToDate}
                    clearOnSameDateClick={false}
                    disabled={!resultLoaded}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell colSpan={2}>
                  *只顯示大於0.01%的變動
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={isMobile?4:3}>
                  總共：
                </Table.Cell>
                <Table.Cell width={isMobile?4:3}>
                  { !resultLoaded && <Loader active inline></Loader> }
                  { resultLoaded && data.data.length }
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          {
            resultLoaded &&
            <HighchartsReact
            highcharts={Highcharts}
            options={options}
            />
          }
          <SortableTable maxRowPerPage={32} config={config} data={data} sort={sort} filter={filter}></SortableTable>
        </>
      ) }
    </>
  );
}