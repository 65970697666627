import React, { useEffect, useState } from "react";
import axios from "axios";
import { Loader, Statistic, Container, Icon, Header } from "semantic-ui-react";
import { isMobile } from "react-device-detect";

function CovidKeyStat() {
  const [data, setData] = useState(null);

  useEffect(() => {
    axios
      .get(`https://chp-dashboard.geodata.gov.hk/covid-19/data/keynum.json`)
      .then((response) => setData(response.data));
  }, []);

  return (
    <div>
      {data !== null ? (
        <SummaryView data={data} />
      ) : (
        <Loader active inline="centered">
          {" "}
          Loading
        </Loader>
      )}
    </div>
  );
}

function SummaryView(data) {
  const res = data.data;
  const lastUpdate = timeConverter(res.As_of_date) + " " + res.As_of_time;
  console.log(res);

  return (
    <Container style={{ paddingTop: "25px" }}>
      <Statistic.Group widths="three">
        <Statistic color="red">
          <Statistic.Value text>
            {(res.Confirmed + res.RAT_Positive).toLocaleString()} <br />
            <Icon name="caret up" />
            {(res.Confirmed_Delta + res.RAT_Positive_Delta).toLocaleString()}
          </Statistic.Value>
          <Statistic.Label>陽性檢測(核酸+抗原)</Statistic.Label>
        </Statistic>
        <Statistic color="orange">
          <Statistic.Value text>
            {res.Hospitalised.toLocaleString()}
            <br />
            <Icon
              name={
                res.Hospitalised - res.P_Hospitalised >= 0
                  ? "caret up"
                  : "caret down"
              }
            />
            {(res.Hospitalised - res.P_Hospitalised).toLocaleString()}
          </Statistic.Value>
          <Statistic.Label>住院</Statistic.Label>
        </Statistic>
        <Statistic color="grey">
          <Statistic.Value text>
            {res.Death.toLocaleString()}
            <br />
            <Icon name="caret up" />
            {(res.Death - res.P_Death).toLocaleString()}
          </Statistic.Value>
          <Statistic.Label>死亡</Statistic.Label>
        </Statistic>
      </Statistic.Group>
      <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
        <Header as="h4" textAlign="right" color="grey">
          {" "}
          數據至 : {lastUpdate} <br />
        </Header>
      </div>

      {/* <div style={{ textAlign: "right", paddingTop: "15px" }}>{lastUpdate}</div> */}
    </Container>
  );
}

function SummaryView2(data) {
  const res = data.data;
  const lastUpdate = timeConverter(res.As_of_date) + " " + res.As_of_time;
  console.log(res);

  return (
    <Container style={{ paddingTop: "25px" }}>
      <Statistic.Group widths="three">
        <Statistic color="red">
          <Statistic.Value text>
            {res.Confirmed.toLocaleString()} <br />
            <Icon name="caret up" />
            {(res.ImportedCasedAdded + res.LocalCasesAdded).toLocaleString()}
          </Statistic.Value>
          <Statistic.Label>陽性檢測個案</Statistic.Label>
        </Statistic>
        <Statistic color="orange">
          <Statistic.Value text>
            {res.Hospitalised.toLocaleString()}
            <br />
            <Icon
              name={
                res.Hospitalised - res.P_Hospitalised >= 0
                  ? "caret up"
                  : "caret down"
              }
            />
            {(res.Hospitalised - res.P_Hospitalised).toLocaleString()}
          </Statistic.Value>
          <Statistic.Label>住院</Statistic.Label>
        </Statistic>
        <Statistic color="grey">
          <Statistic.Value text>
            {res.Death.toLocaleString()}
            <br />
            <Icon name="caret up" />
            {(res.Death - res.P_Death).toLocaleString()}
          </Statistic.Value>
          <Statistic.Label>死亡</Statistic.Label>
        </Statistic>
        {/* <Statistic.Group widths={isMobile ? "two" : "four"}>
         <Statistic color="teal">
           <Statistic.Value text style={{ paddingTop: "1rem" }}>
             {res.Confirmed2.toLocaleString()}
           </Statistic.Value>
           <Statistic.Label>確診</Statistic.Label>
         </Statistic>
         <Statistic color="red">
           <Statistic.Value text>
             {res.Confirmed.toLocaleString()} <br />
             <Icon name="caret up" />
             {res.LocalCasesAdded.toLocaleString()}
           </Statistic.Value>
           <Statistic.Label>陽性檢測個案</Statistic.Label>
         </Statistic> */}
        {/* {isMobile ? null : (
          <> */}
        {/* <Statistic color="orange">
              <Statistic.Value text style={{ paddingTop: "1rem" }}>
                {res.Hospitalised.toLocaleString()}
              </Statistic.Value>
              <Statistic.Label>住院</Statistic.Label>
            </Statistic>
            <Statistic color="grey">
              <Statistic.Value text style={{ paddingTop: "1rem" }}>
                {res.Death.toLocaleString()}
              </Statistic.Value>
              <Statistic.Label>死亡</Statistic.Label>
            </Statistic>
          </>
        )} */}
      </Statistic.Group>
      <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
        <Header as="h4" textAlign="right" color="grey">
          {" "}
          數據至 : {lastUpdate} <br />
        </Header>
      </div>

      {/* <div style={{ textAlign: "right", paddingTop: "15px" }}>{lastUpdate}</div> */}
    </Container>
  );
}

export default CovidKeyStat;

function timeConverter(UNIX_timestamp) {
  var a = new Date(UNIX_timestamp);
  var year = a.getFullYear();
  var month = ("0" + (a.getMonth() + 1)).slice(-2);
  var date = ("0" + a.getDate()).slice(-2);
  var time = year + "/" + month + "/" + date;
  return time;
}
