import React from "react";
import MetaTags from "react-meta-tags";
// import RSTable from "../../tables/RSTable";
import DisplayAdsBanner from "../../ads/DisplayAdsBanner";
import GoogleAd from "../../ads/Google_ad";
import { Container, Header, Icon, Popup } from "semantic-ui-react";
import ZoomFilter from "./ZoomFilter";
import { useParams } from "react-router-dom";
import Zoom from "./Zoom";

export const TopicStockZoom = ({ match }) => {
  const { id } = useParams();
  const isSignin = true;
  return (
    <>
      <MetaTags>
        <title>StockZoom</title>
        {/* <meta name="description" content="相對強度評級(Relative Strength)" />
        <meta property="og:title" content="相對強度評級(Relative Strength)" />
        <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" /> */}
      </MetaTags>
      <Container textAlign="center">
        <DisplayAdsBanner />
      </Container>

      <div
        style={{ paddingTop: "25px", paddingBottom: "25px", minHeight: "75vh" }}
      >
        <Container style={{ textAlign: "center" }}>
          <Header as="h1">
            StockZoom
            <div style={{ float: "right" }}>
              <Popup
                on="click"
                pinned
                position="bottom right"
                size="small"
                trigger={
                  <div style={{ paddingTop: "0em" }}>
                    <Icon color="grey" size="tiny" name="question circle" />
                  </div>
                }
              >
                <p>
                  {" "}
                  “相對強度(RS) 評級”
                  為對比該股票股價在過去十二個月之表現以及大市上其餘股票的表現後而得出的評級。RS
                  評級範圍由0
                  至100分，分數越大代表表現越好，例如：80分就是代表該股票在過去12個月跑贏市場上其餘80%的股票。
                </p>
                <p>
                  使用RS評級能讓投資者找出市場領導者；一般坊間專業投資者傾向建議選擇評級介乎80-90分的股票。
                </p>
              </Popup>
              {/* <Icon color="grey" size="small" name="question circle" /> */}
            </div>
            <Header.Subheader>stockzoom</Header.Subheader>
          </Header>
        </Container>
        {isSignin ? <Zoom id={id} /> : <>Please Log In</>}
        {/* <ZoomFilter /> */}
      </div>
      {/* <Container
        textAlign="center"
        style={{ paddingTop: "30px", paddingBottom: "0px" }}
      >
        <GoogleAd
          client="ca-pub-8753358837449417"
          slot="2486257452"
          format="auto"
          wrapperDivStyle={{
            marginTop: "30px",
            marginBottom: "20px",
          }}
        />
      </Container> */}
    </>
  );
};

export default TopicStockZoom;
