import React from "react";
import { Field, reduxForm } from "redux-form";
import {
  Form,
  Grid,
  Container,
  Table,
  Header,
  Divider,
} from "semantic-ui-react";
import { useHistory } from "react-router-dom";
// import "./ZoomFilterForm.css";
import { isMobile } from "react-device-detect";

const renderCheckbox = (field) => (
  <Form.Checkbox
    checked={!!field.input.value}
    name={field.input.name}
    label={field.label}
    onChange={(e, { checked }) => field.input.onChange(checked)}
  />
);

const renderRadio = (field) => (
  <Form.Radio
    checked={field.input.value === field.radioValue}
    label={field.label}
    name={field.input.name}
    onChange={(e, { checked }) => field.input.onChange(field.radioValue)}
  />
);

const renderSelect = (field) => (
  <Form.Select
    label={field.label}
    name={field.input.name}
    onChange={(e, { value }) => field.input.onChange(value)}
    options={field.options}
    placeholder={field.placeholder}
    // value={field.input.value}
  />
);

const renderDropDown = (field) => (
  <Form.Dropdown
    label={field.label}
    name={field.input.name}
    onChange={(e, { value }) => field.input.onChange(value)}
    options={field.options}
    placeholder={field.placeholder}
    defaultValue={field.defaultValue}
    value={field.input.value}
  />
);

// const renderTextArea = (field) => (
//   <Form.TextArea
//     {...field.input}
//     label={field.label}
//     placeholder={field.placeholder}
//   />
// );

const RSTableForm = (props) => {
  const { handleSubmit, reset, mode } = props;
  const dayOptions = [
    { key: "1", text: "1天", value: "1" },
    { key: "5", text: "5天", value: "5" },
    { key: "10", text: "10天", value: "10" },
  ];
  const rankOptions = [
    { key: "3", text: "Top3", value: "3" },
    { key: "5", text: "Top5", value: "5" },
    { key: "10", text: "Top10", value: "10" },
  ];

  const maxFieldBoxWidth = isMobile ? "75px" : "100px";
  return (
    <Form onSubmit={handleSubmit}>
      <div
        style={{
          overflowX: "auto",
          width: "100%",
          margin: "auto",
          textAlign: "center",
          minHeight: "75vh",
          maxWidth: "950px",
        }}
      >
        <Divider hidden />

        <Container
          style={{
            textAlign: "left",
            paddingBottom: "25px",
            maxWidth: "550px",
          }}
        >
          <div style={{ maxWidth: "450px" }}>
            <Field
              component={Form.Input}
              maxLength="40"
              label="選股條件名稱"
              name="title"
              placeholder="名稱"
            />
            <Field
              component={renderSelect}
              label="行業"
              name="sector"
              options={[
                { key: "m", text: "Male", value: "male" },
                { key: "f", text: "Female", value: "female" },
              ]}
              placeholder="行業"
            />
          </div>

          <Divider hidden />

          <Grid>
            <Grid.Row>
              <Grid.Column width={isMobile ? 4 : 2}>
                <Form.Group inline>
                  <label>分類</label>
                </Form.Group>
              </Grid.Column>
              <Grid.Column width={isMobile ? 12 : 14}>
                <Form.Group inline>
                  <Field
                    component={renderCheckbox}
                    label="主板"
                    name="isMain"
                  />
                  <Field
                    component={renderCheckbox}
                    label="創業板"
                    name="isGEM"
                  />
                  <Field component={renderCheckbox} label="恒指" name="isHSI" />
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid style={{ marginTop: "-1.5rem" }}>
            <Grid.Row>
              <Grid.Column width={isMobile ? 4 : 2}>
                <Form.Group inline>
                  <label>上市年期</label>
                </Form.Group>
              </Grid.Column>
              <Grid.Column width={isMobile ? 12 : 14}>
                <Form.Group inline>
                  <Field
                    component={renderRadio}
                    label="所有"
                    name="ipoType"
                    radioValue={0}
                  />
                  <Field
                    component={renderRadio}
                    label="IPO(半年)"
                    name="ipoType"
                    radioValue={1}
                  />
                  <Field
                    component={renderRadio}
                    label="IPO(一年)"
                    name="ipoType"
                    radioValue={2}
                  />
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Grid style={{ marginTop: "-1.5rem" }}>
            <Grid.Row>
              <Grid.Column width={isMobile ? 4 : 2}>
                <Form.Group inline>
                  <label>MA</label>
                </Form.Group>
              </Grid.Column>
              <Grid.Column width={isMobile ? 12 : 14}>
                <Form.Group>
                  <Field
                    component={renderCheckbox}
                    label="現價>50MA"
                    name="isPlgma50"
                  />
                  <Field
                    component={renderCheckbox}
                    label="現價>200MA"
                    name="isPlgma200"
                  />
                  <Field
                    component={renderCheckbox}
                    label="現價>30WMA"
                    name="isPlgwma30"
                  />
                  <Field
                    component={renderCheckbox}
                    label="50MA>150MA"
                    name="isMa50lgma150"
                  />
                  <Field
                    component={renderCheckbox}
                    label="50MA>200MA"
                    name="isMa50lgma200"
                  />
                  <Field
                    component={renderCheckbox}
                    label="150MA>200MA"
                    name="isMa150lgma200"
                  />
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Divider hidden />

          <Table
            basic="very"
            size="small"
            compact
            unstackable
            textAlign="center"
            style={{ margin: "auto" }}
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign="left">條件</Table.HeaderCell>
                <Table.HeaderCell>最小值</Table.HeaderCell>
                <Table.HeaderCell>最大值</Table.HeaderCell>
                <Table.HeaderCell>範圍</Table.HeaderCell>
                {/* <Table.HeaderCell>刪除</Table.HeaderCell> */}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <Form.Group inline>
                    <label>價格($)</label>
                  </Form.Group>
                </Table.Cell>
                <Table.Cell>
                  <Field
                    component={Form.Input}
                    size={"mini"}
                    name="pricel"
                    placeholder=""
                    style={{ maxWidth: maxFieldBoxWidth }}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Field
                    component={Form.Input}
                    size={"mini"}
                    name="priceh"
                    placeholder=""
                    style={{ maxWidth: maxFieldBoxWidth }}
                  />
                </Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Form.Group inline>
                    <label>漲跌幅(%)</label>
                  </Form.Group>
                </Table.Cell>
                <Table.Cell>
                  <Field
                    component={Form.Input}
                    size={"mini"}
                    name="priceChgl"
                    placeholder=""
                    style={{ maxWidth: maxFieldBoxWidth }}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Field
                    component={Form.Input}
                    size={"mini"}
                    name="priceChgh"
                    placeholder=""
                    style={{ maxWidth: maxFieldBoxWidth }}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Field
                    component={renderDropDown}
                    name="nPriceChgDay"
                    options={dayOptions}
                    defaultValue={dayOptions[0].value}
                    placeholder=""
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Form.Group inline>
                    <label>市值(億)</label>
                  </Form.Group>
                </Table.Cell>
                <Table.Cell>
                  {/* <Form.Group inline> */}
                  <Field
                    component={Form.Input}
                    // label={"億"}
                    size={"mini"}
                    name="capRl"
                    placeholder=""
                    style={{ maxWidth: maxFieldBoxWidth }}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Field
                    component={Form.Input}
                    size={"mini"}
                    name="capRh"
                    placeholder=""
                    style={{ maxWidth: maxFieldBoxWidth }}
                  />
                </Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>
                  <Form.Group inline>
                    <label>成交額(萬)</label>
                  </Form.Group>
                </Table.Cell>
                <Table.Cell>
                  <Field
                    component={Form.Input}
                    size={"mini"}
                    name="voll"
                    placeholder=""
                    style={{ maxWidth: maxFieldBoxWidth }}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Field
                    component={Form.Input}
                    size={"mini"}
                    name="volh"
                    placeholder=""
                    style={{ maxWidth: maxFieldBoxWidth }}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Field
                    component={renderDropDown}
                    name="nVolDay"
                    options={dayOptions}
                    defaultValue={dayOptions[0].value}
                    placeholder=""
                  />
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>
                  <Form.Group inline>
                    <label>成交量(萬股)</label>
                  </Form.Group>
                </Table.Cell>
                <Table.Cell>
                  <Field
                    component={Form.Input}
                    size={"mini"}
                    name="turnl"
                    placeholder=""
                    style={{ maxWidth: maxFieldBoxWidth }}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Field
                    component={Form.Input}
                    size={"mini"}
                    name="turnh"
                    placeholder=""
                    style={{ maxWidth: maxFieldBoxWidth }}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Field
                    component={renderDropDown}
                    name="nTurnDay"
                    options={dayOptions}
                    defaultValue={dayOptions[0].value}
                    placeholder=""
                  />
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>
                  <Form.Group inline>
                    <label>相對強度(RS)</label>
                  </Form.Group>
                </Table.Cell>
                <Table.Cell>
                  <Field
                    component={Form.Input}
                    size={"mini"}
                    name="rsl"
                    placeholder=""
                    style={{ maxWidth: maxFieldBoxWidth }}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Field
                    component={Form.Input}
                    size={"mini"}
                    name="rsh"
                    placeholder=""
                    style={{ maxWidth: maxFieldBoxWidth }}
                  />
                </Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>
                  <Form.Group inline>
                    <label>相對強度改變</label>
                  </Form.Group>
                </Table.Cell>
                <Table.Cell>
                  <Field
                    component={Form.Input}
                    size={"mini"}
                    name="rsChgl"
                    placeholder=""
                    style={{ maxWidth: maxFieldBoxWidth }}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Field
                    component={Form.Input}
                    size={"mini"}
                    name="rsChgh"
                    placeholder=""
                    style={{ maxWidth: maxFieldBoxWidth }}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Field
                    component={renderDropDown}
                    name="nRsChgDay"
                    options={dayOptions}
                    defaultValue={dayOptions[0].value}
                    placeholder=""
                  />
                </Table.Cell>
              </Table.Row>
              {/* <Table.Row>
                <Table.Cell>
                  <Form.Group inline>
                    <label>Non CCASS%</label>
                  </Form.Group>
                </Table.Cell>
                <Table.Cell>
                  <Field
                    component={Form.Input}
                    name="ncPctl"
                    size={"mini"}
                    placeholder=""
                    style={{ maxWidth: maxFieldBoxWidth }}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Field
                    component={Form.Input}
                    name="ncPcth"
                    size={"mini"}
                    placeholder=""
                    style={{ maxWidth: maxFieldBoxWidth }}
                  />
                </Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Form.Group inline>
                    <label>持倉集中比率%</label>
                  </Form.Group>
                </Table.Cell>
                <Table.Cell>
                  <Field
                    component={Form.Input}
                    size={"mini"}
                    name="TopNConcl"
                    placeholder=""
                    style={{ maxWidth: maxFieldBoxWidth }}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Field
                    component={Form.Input}
                    size={"mini"}
                    name="TopNConch"
                    placeholder=""
                    style={{ maxWidth: maxFieldBoxWidth }}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Field
                    component={renderDropDown}
                    // label="行業"
                    name="nTopNConc"
                    options={rankOptions}
                    defaultValue={rankOptions[0].value}
                    placeholder=""
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Form.Group inline>
                    <label>北水持倉%</label>
                  </Form.Group>
                </Table.Cell>
                <Table.Cell>
                  <Field
                    component={Form.Input}
                    name="hkcPctl"
                    placeholder=""
                    size={"mini"}
                    style={{ maxWidth: maxFieldBoxWidth }}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Field
                    component={Form.Input}
                    name="hkcPcth"
                    placeholder=""
                    size={"mini"}
                    style={{ maxWidth: maxFieldBoxWidth }}
                  />
                </Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <p style={{ textAlign: "left", fontWeight: "900" }}>
                    北水持倉 <br /> 改變(%)
                  </p>
                </Table.Cell>
                <Table.Cell>
                  <Field
                    component={Form.Input}
                    size={"mini"}
                    name="hkcPctChgl"
                    placeholder=""
                    style={{ maxWidth: maxFieldBoxWidth }}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Field
                    component={Form.Input}
                    size={"mini"}
                    name="hkcPctChgh"
                    placeholder=""
                    style={{ maxWidth: maxFieldBoxWidth }}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Field
                    component={renderDropDown}
                    name="nhkcPctChgDay"
                    options={dayOptions}
                    defaultValue={dayOptions[0].value}
                    placeholder=""
                  />
                </Table.Cell>
              </Table.Row> */}
            </Table.Body>
          </Table>
        </Container>
      </div>
    </Form>
  );
};

export default reduxForm({
  form: "rstable",
})(RSTableForm);
