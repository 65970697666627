import React, { useEffect, useState, Component } from "react";
import {
  Loader,
  Table,
  Pagination,
  Container,
  Header,
  Dropdown,
  Icon,
  Input,
  Form,
  Checkbox,
  Label,
} from "semantic-ui-react";
import _ from "lodash";
import { createStore, combineReducers } from "redux";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import {
  selectAllTopic,
  fetchTopic,
  toIdle,
} from "../../features/topic/topicSlice";
import { useSelector, useDispatch } from "react-redux";
// import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
import InFeedAdsWhite from "../ads/InFeedAdsWhite";
import source from "../../mock_source";
import PriceLine from "../charts/PriceLine2";
import Slider from "@material-ui/core/Slider";
import { formatNumber } from "../../util/dataFormatter";
import { Field, reduxForm } from "redux-form";
import { FavBtn } from "components/widget/MyFav";

// function RSTable() {
//   return (
//     <div>
//       <RSView data={data} stockSector={stockSector} />;
//     </div>
//   );
// }

export const RSTable = () => {
  const dispatch = useDispatch();
  const topics = useSelector(selectAllTopic);
  const topicStatus = useSelector((state) => state.topic.status);
  const error = useSelector((state) => state.topic.error);
  useEffect(() => {
    if (topicStatus === "idle") {
      if (
        topics.find((item) => item.topicInfo.name === "rstable3") === undefined
      ) {
        dispatch(fetchTopic("rstable3"));
      }
      if (
        topics.find((item) => item.topicInfo.name === "stockSectorInfo") ===
        undefined
      ) {
        dispatch(fetchTopic("stockSectorInfo"));
      }
    }
  }, [topicStatus, topics, dispatch]);
  let content;

  if (topicStatus === "loading") {
    content = (
      <div>
        <section className="posts-list">
          <div
            style={{
              padding: "100px",
              margin: "auto",
              alignItems: "center",
              height: "75vh",
              // overflow: "auto",
            }}
          >
            <Loader active inline="centered">
              {" "}
              Loading
            </Loader>
          </div>
        </section>
      </div>
    );
  } else if (topicStatus === "succeeded") {
    const data = topics.find((item) => item.topicInfo.name === "rstable3");
    const stockSector = topics.find(
      (item) => item.topicInfo.name === "stockSectorInfo"
    );
    if (data && stockSector) {
      content = <RSView data={data} stockSector={stockSector} />;
    } else {
      dispatch(toIdle({}));
    }
  } else if (topicStatus === "error") {
    content = (
      <div>
        <section className="posts-list">
          <div>{error}</div>
        </section>
      </div>
    );
  }
  return <>{content}</>;
};

export default RSTable;

function RSView(data) {
  let result = data.data;
  let stockSector = data.stockSector;
  // console.log(result);
  return (
    <>
      <div style={{ paddingTop: "25px", paddingBottom: "25px" }}>
        {/* hello */}
        {typeof result !== "undefined" ? (
          <SortableTable data={result} stockSector={stockSector} />
        ) : (
          <NotFound />
        )}
      </div>
    </>
  );
}

function NotFound() {
  return (
    <div
      style={{
        padding: "100px",
        margin: "auto",
        alignItems: "center",
        height: "75vh",
        // overflow: "auto",
      }}
    >
      <Loader active inline="centered">
        {" "}
        Loading
      </Loader>
    </div>
  );
}

const initialState = {
  data: [],
  direction: "descending",
  //   direction_gp: "descending",
  column: "rs",
  filterText: { value: "" },
  filterInd: "所有",
  filterSector: "",
  filterSubSector: "",
  indOption: [],
  sectorOption: [],
  activePage: "1",
  isFocus: "1",
  totalPage: "4",
  itemType: "0",
  lastUpdate: { value: "" },
  sliderFilter_RS: [0, 100],
  sliderFilter_PRICE: [0, 1000],
  priceFilter: ["", ""],
  capFilter: ["", ""],
  volFilter: "",
  filterCondition: {
    c1: false,
    c2: false,
    c3: false,
    c4: false,
    ipoType: "0",
  },
  dRS: "rsd10d",
  rangeFilter_PRICE: [0, 10000],
};

const reducer = combineReducers({
  resultTable: resultTableReducer,
});

function resultTableReducer(state = initialState, action) {
  switch (action.type) {
    case "CHANGE_SORT": {
      if (state.column === action.column) {
        return {
          ...state,
          direction:
            state.direction === "ascending" ? "descending" : "ascending",
          activePage: 1,
        };
      }
      return {
        ...state,
        column: action.column,
        direction: "ascending",
        activePage: 1,
      };
    }
    case "Filter_MESSAGE": {
      const string = action.text;
      return {
        ...state,
        filterText: string,
        activePage: 1,
      };
    }
    case "TOGGLE_CHECKBOX": {
      const string = action.text;
      return {
        ...state,
        filterCondition: {
          ...state.filterCondition,
          [string]: !state.filterCondition[string],
        },
      };
    }
    case "TOGGLE_IPO_CHECKBOX": {
      const string = action.text;
      return {
        ...state,
        filterCondition: {
          ...state.filterCondition,
          ipoType: string,
        },
      };
    }

    case "SET_IND_FILTER": {
      // console.log(action.state);
      if (state.filterInd === action.state) {
        return {
          ...state,
          filterInd: "所有",
          activePage: 1,
          // data: _.sortBy(state.data, state.column),
        };
      } else {
        return {
          ...state,
          filterInd: action.state,
          activePage: 1,
          // data: _.sortBy(state.data, state.column),
        };
      }
      // if (state.direction === "ascending") {
      //   return {
      //     ...state,
      //     filterInd: action.state,
      //     activePage: 1,
      //     // data: _.sortBy(state.data, state.column),
      //   };
      // } else {
      //   return {
      //     ...state,
      //     filterInd: action.state,
      //     activePage: 1,
      //     // data: _.sortBy(state.data, state.column).reverse(),
      //   };
      // }
    }
    case "SET_SLIDER_FILTER_RS": {
      const string = action.text;
      return {
        ...state,
        sliderFilter_RS: string.value,
      };
    }
    // case "SET_SLIDER_FILTER_PRICE": {
    //   const string = action.text;
    //   return {
    //     ...state,
    //     sliderFilter_PRICE: string.value,
    //   };
    // }
    case "SET_PRICE_FILTER": {
      const string = action.text;
      // console.log("fire");
      // console.log(string);
      return {
        ...state,
        priceFilter: string,
      };
    }
    case "SET_CAP_FILTER": {
      const string = action.text;
      // console.log(string);
      return {
        ...state,
        capFilter: string,
      };
    }
    case "SET_VOL_FILTER": {
      const string = action.text;
      // console.log(string);
      return {
        ...state,
        volFilter: string,
      };
    }
    case "SET_ACTIVE_PAGE": {
      const string = action.text;
      window.scrollTo(0, 0);
      return {
        ...state,
        activePage: string,
      };
    }
    case "ADD_MESSAGE": {
      return {
        ...state,
        data: action.state,
      };
    }
    case "ADD_OPTIONS": {
      return {
        ...state,
        options: action.state,
      };
    }
    case "ADD_LASTUPDATE": {
      return {
        ...state,
        lastUpdate: action.state,
      };
    }
    default: {
      return state;
    }
  }
}

const store = createStore(reducer);

class SortableTable extends React.Component {
  constructor(props) {
    super(props);
    this.setData();
  }

  setData() {
    const { topicInfo, rstable } = this.props.data;
    const header = rstable.header;
    function obj2Array(n) {
      return Object.values(n);
    }
    var data1 = _.map(rstable.data, obj2Array);
    var lastUpdate = topicInfo.lastUpdate;
    var ind = this.props.stockSector.ind;
    var iDict = this.props.stockSector.iDict;
    var sDict = this.props.stockSector.sDict;
    var ssDict = this.props.stockSector.ssDict;
    const options = [{ key: "所有", text: "所有", value: "所有" }];

    var tableData1 = data1.map((v) => {
      var d = {};
      var e = v[0];
      for (var i = 0; i < header.length; i++) {
        d[header[i]] = e[i];
      }
      let a = _.find(source, function (o) {
        return o.no === d.id;
      });
      let ii = ind[d.id];
      return {
        ...d,
        name_zh: a ? a["name_zh"] : "",
        name_en: a ? a["name_en"] : "",
        i: ii ? iDict[ii["i"]] : "",
        s: ii ? sDict[ii["s"]] : "",
        ss: ii ? ssDict[ii["ss"]] : "",
        isC1: d.C >= d.ma50 ? true : false,
        isC2: d.ma50 >= d.ma150 ? true : false,
        isC3: d.ma150 >= d.ma200 ? true : false,
        isC4: d.C >= d.maw30 ? true : false,
        // dRS10d:
        //   parseFloat(d.rs) && parseFloat(d.rsd10)
        //     ? parseInt((parseFloat(d.rs) - parseFloat(d.rsd10)) * 10) / 10
        //     : "-",
      };
    });
    // console.log(tableData1);
    tableData1 = _.sortBy(tableData1, ["rs"]).reverse();
    store.dispatch({
      type: "ADD_MESSAGE",
      state: tableData1,
    });
    let j = 0;
    var sDictArr = _.values(sDict);
    for (j; j < sDictArr.length; j += 1) {
      options.push({
        key: sDictArr[j],
        text: sDictArr[j],
        value: sDictArr[j],
      });
    }
    store.dispatch({
      type: "ADD_OPTIONS",
      state: options,
    });
    store.dispatch({
      type: "ADD_LASTUPDATE",
      state: lastUpdate,
    });
  }

  componentDidMount() {
    store.subscribe(() => this.forceUpdate());
  }

  render() {
    const state = store.getState();
    const {
      data,
      activePage,
      filterText,
      options,
      filterInd,
      lastUpdate,
      direction,
      column,
      sliderFilter_RS,
      sliderFilter_PRICE,
      filterCondition,
      rangeFilter_PRICE,
      priceFilter,
      capFilter,
      volFilter,
    } = state.resultTable;
    let newData = data;
    // console.log(newData);

    if (filterText.value && filterText.value !== "") {
      newData = _.filter(newData, function (d) {
        return (
          (typeof d.id === "string" &&
            d.id.toLowerCase().includes(filterText.value.toLowerCase())) ||
          (typeof d.name_zh === "string" &&
            d.name_zh.toLowerCase().includes(filterText.value.toLowerCase())) ||
          (typeof d.name_en === "string" &&
            d.name_en.toLowerCase().includes(filterText.value.toLowerCase()))
        );
      });
    }

    if (filterInd && filterInd !== "所有") {
      newData = _.filter(newData, function (d) {
        return d.s === filterInd;
      });
    }

    if (parseFloat(priceFilter[0]) > 0 || parseFloat(priceFilter[1] > 0)) {
      const low =
        parseFloat(priceFilter[0]) > 0 ? parseFloat(priceFilter[0]) : 0;
      const high =
        parseFloat(priceFilter[1]) > 0 ? parseFloat(priceFilter[1]) : 9999;
      newData = _.filter(newData, function (d) {
        return d.C >= low && d.C <= high;
      });
    }

    if (parseFloat(capFilter[0]) > 0 || parseFloat(capFilter[1]) > 0) {
      const low = parseFloat(capFilter[0]) > 0 ? parseFloat(capFilter[0]) : 0;
      const high =
        parseFloat(capFilter[1]) > 0 ? parseFloat(capFilter[1]) : 99999999;
      // console.log(low);
      // console.log(high);
      newData = _.filter(newData, function (d) {
        return d.cap >= low && d.cap <= high;
      });
    }

    if (parseFloat(volFilter) > 0) {
      newData = _.filter(newData, function (d) {
        return d.t >= parseFloat(volFilter);
      });
    }

    if (direction === "ascending") {
      newData = _.sortBy(newData, [column]);
    } else {
      newData = _.sortBy(newData, [column]).reverse();
    }

    newData = _.filter(newData, function (d) {
      // console.log(d);
      const c = d.rs;
      const close = d.C;
      return (
        sliderFilter_RS[0] <= c &&
        sliderFilter_RS[1] >= c &&
        sliderFilter_PRICE[0] <= close &&
        sliderFilter_PRICE[1] >= close &&
        d.name_zh !== ""
      );
    });

    if (
      filterCondition.c1 === true ||
      filterCondition.c2 === true ||
      filterCondition.c3 === true ||
      filterCondition.c4 === true ||
      filterCondition.ipoType !== "0"
    ) {
      if (filterCondition.c1 === true) {
        newData = _.filter(newData, function (d) {
          return d.isC1 === filterCondition.c1;
        });
      }
      if (filterCondition.c2 === true) {
        newData = _.filter(newData, function (d) {
          return d.isC2 === filterCondition.c2;
        });
      }
      if (filterCondition.c3 === true) {
        newData = _.filter(newData, function (d) {
          return d.isC3 === filterCondition.c3;
        });
      }
      if (filterCondition.c4 === true) {
        newData = _.filter(newData, function (d) {
          return d.isC4 === filterCondition.c4;
        });
      }
      if (filterCondition.ipoType !== "0") {
        // console.log(filterCondition.ipoType);
        if (filterCondition.ipoType === "1") {
          newData = _.filter(newData, function (d) {
            return String(d.ipoType) === filterCondition.ipoType;
          });
        } else {
          newData = _.filter(newData, function (d) {
            return (String(d.ipoType) === "1") | (String(d.ipoType) === "2");
          });
        }
      }
    }

    // console.log(newData);

    const nPerPage = 25;
    const totalPage = Math.ceil(newData.length / nPerPage);
    let newData2 = [];
    newData2 = _.slice(
      newData,
      (activePage - 1) * nPerPage,
      activePage * nPerPage
    );

    return (
      <Container
        style={{
          width: "100%",
          maxWidth: "950px",
          margin: "auto",
          alignItems: "center",
        }}
      >
        <div style={{ width: isMobile ? "95%" : "100%", maxWidth: "900px" }}>
          <div style={{ maxWidth: "400px" }}>
            <FilterInput value={filterText} />
          </div>

          <br />
          <DropdownExampleControlled options={options} value={filterInd} />
          <br />
          <AdvanceFilter
            filterCondition={filterCondition}
            rangeFilter_PRICE={rangeFilter_PRICE}
            priceFilter={priceFilter}
            capFilter={capFilter}
            volFilter={volFilter}
          />
        </div>
        <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
          <Header as="h4" textAlign="right" color="grey">
            {" "}
            數據至 : {lastUpdate} <br />
            總數 : {newData.length}{" "}
          </Header>
        </div>
        <div style={{ textAlign: "center", width: isMobile ? "95%" : "100%" }}>
          <TableComponent data={newData2} />
        </div>
        <div style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <PaginationContainer totalPage={totalPage} />
        </div>
      </Container>
    );
  }
}

function AdvanceFilter(props) {
  const { filterCondition, priceFilter, capFilter, volFilter } = props;
  const options = [
    { key: "1", text: "1天", value: "1" },
    // { key: "5", text: "5天", value: "5" },
    // { key: "10", text: "10天", value: "10" },
  ];
  // const optionsRSC = [
  //   { key: "1", text: "10天", value: "10" },
  // ];
  // console.log(priceFilter);

  const [priceFilterL, setPriceFilterL] = useState(priceFilter[0]);
  const [priceFilterH, setPriceFilterH] = useState(priceFilter[1]);
  const [capFilterL, setCapFilterL] = useState(capFilter[0]);
  const [capFilterH, setCapFilterH] = useState(capFilter[1]);
  const [volFilter_, setVolFilter_] = useState(volFilter);

  const onChangeFilterPL = (e) => {
    setPriceFilterL(e.target.value);
    store.dispatch({
      type: "SET_PRICE_FILTER",
      text: [e.target.value, priceFilterH],
    });
  };
  const onChangeFilterPH = (e) => {
    setPriceFilterH(e.target.value);
    store.dispatch({
      type: "SET_PRICE_FILTER",
      text: [priceFilterL, e.target.value],
    });
  };
  const onChangeFilterCL = (e) => {
    setCapFilterL(e.target.value);
    store.dispatch({
      type: "SET_CAP_FILTER",
      text: [e.target.value, capFilterH],
    });
  };
  const onChangeFilterCH = (e) => {
    setCapFilterH(e.target.value);
    store.dispatch({
      type: "SET_CAP_FILTER",
      text: [capFilterL, e.target.value],
    });
  };
  const onChangeFilterV = (e) => {
    setVolFilter_(e.target.value);
    store.dispatch({
      type: "SET_VOL_FILTER",
      text: e.target.value,
    });
  };

  return (
    <>
      <Form>
        <Form.Group inline>
          <Form.Field>
            <label>上市年期： </label>
            <Checkbox
              label="所有"
              style={{ paddingRight: "10px" }}
              checked={filterCondition.ipoType === "0"}
              onChange={() => {
                store.dispatch({ type: "TOGGLE_IPO_CHECKBOX", text: "0" });
              }}
            />
            <Checkbox
              label="IPO(半年)"
              style={{ paddingRight: "10px" }}
              checked={filterCondition.ipoType === "1"}
              onChange={() => {
                store.dispatch({ type: "TOGGLE_IPO_CHECKBOX", text: "1" });
              }}
            />
            <Checkbox
              label="IPO(一年)"
              style={{ paddingRight: "10px" }}
              checked={filterCondition.ipoType === "2"}
              onChange={() => {
                store.dispatch({ type: "TOGGLE_IPO_CHECKBOX", text: "2" });
              }}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group inline>
          <Form.Field>
            <Checkbox
              label="現價>50MA"
              style={{ paddingRight: "10px" }}
              checked={filterCondition.c1}
              onChange={() => {
                store.dispatch({ type: "TOGGLE_CHECKBOX", text: "c1" });
              }}
            />
            <Checkbox
              label="50MA>150MA "
              style={{ paddingRight: "10px" }}
              checked={filterCondition.c2}
              onChange={() => {
                store.dispatch({ type: "TOGGLE_CHECKBOX", text: "c2" });
              }}
            />
            <Checkbox
              label="150MA>200MA "
              style={{ paddingRight: "10px" }}
              checked={filterCondition.c3}
              onChange={() => {
                store.dispatch({ type: "TOGGLE_CHECKBOX", text: "c3" });
              }}
            />
            <Checkbox
              label="現價>30WMA"
              style={{ paddingRight: "10px" }}
              checked={filterCondition.c4}
              onChange={() => {
                store.dispatch({ type: "TOGGLE_CHECKBOX", text: "c4" });
              }}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      <Form>
        <Form.Field inline>
          <label>價格： </label>
          <label style={{ paddingRight: "5px" }}>$</label>
          <Input
            size="mini"
            placeholder=""
            style={{ maxWidth: "75px" }}
            defaultValue={priceFilter[0]}
            onChange={onChangeFilterPL}
          />
          <label style={{ paddingLeft: "10px", paddingRight: "10px" }}>~</label>
          <label style={{ paddingRight: "5px" }}>$</label>{" "}
          <Input
            size="mini"
            placeholder=""
            style={{ maxWidth: "75px" }}
            defaultValue={priceFilter[1]}
            onChange={onChangeFilterPH}
          />
        </Form.Field>
        <Form.Field inline>
          <label>市值： </label>
          <Input
            size="mini"
            placeholder=""
            style={{ maxWidth: "75px" }}
            defaultValue={capFilter[0]}
            onChange={onChangeFilterCL}
          />
          <label style={{ paddingLeft: "5px" }}>億</label>
          <label style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            {" "}
            ~{" "}
          </label>
          <Input
            size="mini"
            placeholder=""
            style={{ maxWidth: "75px" }}
            defaultValue={capFilter[1]}
            onChange={onChangeFilterCH}
          />
          <label style={{ paddingLeft: "5px" }}>億</label>
        </Form.Field>
        <Form.Field inline>
          <label>成交額：</label>
          <Dropdown inline options={options} defaultValue={options[0].value} />
          <label style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            {">"}
          </label>
          <Input
            size="mini"
            placeholder=""
            style={{ maxWidth: "75px" }}
            defaultValue={volFilter}
            onChange={onChangeFilterV}
          />
          <label style={{ paddingLeft: "5px" }}>萬</label>
        </Form.Field>
        {/* <Form.Field inline>
          <label>RS變化： </label>
          <Dropdown
            inline
            options={optionsRSC}
            defaultValue={optionsRSC[0].value}
          />
          <Input
            size="mini"
            placeholder=""
            style={{ maxWidth: "75px", paddingLeft: "10px" }}
            defaultValue={capFilter[0]}
            onChange={onChangeFilterCL}
          />
          <label style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            {" "}
            ~{" "}
          </label>
          <Input
            size="mini"
            placeholder=""
            style={{ maxWidth: "75px" }}
            defaultValue={capFilter[1]}
            onChange={onChangeFilterCH}
          />
        </Form.Field> */}
      </Form>
    </>
  );
}

function TableComponent(data) {
  const newData = data.data;
  const state = store.getState();
  const { column, direction, sliderFilter_RS, dRS } = state.resultTable;
  // console.log("hi");
  // console.log(state);
  return (
    <div
      style={{
        whiteSpace: "nowrap",
        overflowX: "auto",
        display: "inline-block",
        width: "100%",
        textAlign: "center",
      }}
    >
      <Table sortable selectable celled unstackable compact structured>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              textAlign="left"
              rowSpan="2"
              sorted={column === "id" ? direction : null}
              onClick={() => {
                store.dispatch({ type: "CHANGE_SORT", column: "id" });
              }}
            >
              名稱
              {isMobile ? <br /> : null}
              (編號)
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              rowSpan="2"
              // sorted={column === "cur" ? direction : null}
            >
              <div
                onClick={() => {
                  store.dispatch({ type: "CHANGE_SORT", column: "C" });
                }}
              >
                價格{" "}
                {column === "C" &&
                  (direction === "descending" ? (
                    <Icon link name="sort down" />
                  ) : (
                    <Icon link name="sort up" />
                  ))}
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              rowSpan="2"
              // sorted={column === "rs" ? direction : null}
            >
              <div
                onClick={() => {
                  store.dispatch({ type: "CHANGE_SORT", column: "rs" });
                }}
              >
                RS{" "}
                {column === "rs" &&
                  (direction === "descending" ? (
                    <Icon link name="sort down" />
                  ) : (
                    <Icon link name="sort up" />
                  ))}
              </div>
              <div
                style={{
                  margin: "auto",
                  maxWidth: "100px",
                  maxHeight: "1.5rem",
                }}
              >
                <SliderFilterRS range={sliderFilter_RS} />{" "}
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              rowSpan="2"
              // sorted={column === "rs" ? direction : null}
            >
              <div
                onClick={() => {
                  store.dispatch({ type: "CHANGE_SORT", column: dRS });
                }}
              >
                RS變化
                {column === "rsd10d" &&
                  (direction === "descending" ? (
                    <Icon link name="sort down" />
                  ) : (
                    <Icon link name="sort up" />
                  ))}
              </div>
              <div
                style={{
                  margin: "auto",
                  maxWidth: "100px",
                  maxHeight: "1.5rem",
                }}
              >
                (10天)
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              rowSpan="2"
              style={{ minWidth: "100px" }}
            >
              <div
                onClick={() => {
                  store.dispatch({ type: "CHANGE_SORT", column: "t" });
                }}
              >
                成交額{" "}
                {column === "t" &&
                  (direction === "descending" ? (
                    <Icon link name="sort down" />
                  ) : (
                    <Icon link name="sort up" />
                  ))}
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              rowSpan="2"
              style={{ minWidth: "100px" }}
              // sorted={column === "cur" ? direction : null}
            >
              <div
                onClick={() => {
                  store.dispatch({ type: "CHANGE_SORT", column: "cap" });
                }}
              >
                市值{" "}
                {column === "cap" &&
                  (direction === "descending" ? (
                    <Icon link name="sort down" />
                  ) : (
                    <Icon link name="sort up" />
                  ))}
              </div>
            </Table.HeaderCell>
            {/* <Table.HeaderCell
              textAlign="center"
              rowSpan="2"
            >
              <div>
                <Icon name="star" style={{ color: "#FFCA28" }} />
              </div>
            </Table.HeaderCell> */}
          </Table.Row>
          <Table.Row></Table.Row>
        </Table.Header>
        <Table.Body>
          {newData.map(
            (
              {
                id,
                C,
                V,
                rs,
                ma50,
                ma150,
                ma200,
                name_zh,
                name_en,
                cap,
                t,
                rsd1d,
                rsd5d,
                rsd10d,
                s,
                ss,
              },
              index
            ) => (
              <>
                {index !== 0 && index % 10 === 0 && (
                  <Table.Row key={index}>
                    <Table.Cell colSpan={6} textAlign="center">
                      <InFeedAdsWhite></InFeedAdsWhite>
                    </Table.Cell>
                  </Table.Row>
                )}
                <Table.Row key={id}>
                  <Table.Cell textAlign="left">
                    {name_zh !== "" ? (
                      <Link to={"/stock/" + id}>
                        {name_zh.length > 8
                          ? name_zh.substring(0, 8) + "..."
                          : name_zh}
                        {isMobile ? <br /> : null}({id})
                      </Link>
                    ) : (
                      <>({id})</>
                    )}
                    <br />
                    <span
                      style={{
                        color: "grey",
                        fontSize: "0.85rem",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        store.dispatch({ type: "SET_IND_FILTER", state: s });
                      }}
                    >
                      {s}
                    </span>
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <div style={{ marginBottom: "-0.75rem" }}> ${C} </div>
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <div style={{ marginBottom: "-0.75rem" }}> {rs} </div>
                    <div style={{ width: "100px", margin: "auto" }}>
                      <PriceLine
                        min={0}
                        secondMin={0}
                        mid={rs}
                        secondMax={rs}
                        max={100}
                        col={"#2471A3"}
                      />
                    </div>
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <div style={{ marginBottom: "-0.75rem" }}> {rsd10d} </div>
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <div style={{ marginBottom: "-0.75rem" }}>
                      {" "}
                      {formatNumber(t * 10000)}{" "}
                    </div>
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <div style={{ marginBottom: "-0.75rem" }}>
                      {" "}
                      {cap === 0 ? "-" : formatNumber(cap * 100000000)}{" "}
                    </div>
                  </Table.Cell>
                  {/* <Table.Cell textAlign="center">
                    <span>{FavBtn(id)}</span>
                  </Table.Cell> */}
                </Table.Row>
              </>
            )
          )}
        </Table.Body>
      </Table>
    </div>
  );
}

class PaginationContainer extends React.Component {
  componentDidMount() {
    store.subscribe(() => this.forceUpdate());
  }

  handlePaginationChange = (e, { activePage }) => {
    store.dispatch({
      type: "SET_ACTIVE_PAGE",
      text: activePage,
    });
  };

  render() {
    const pos = isMobile ? "center" : "right";
    const state = store.getState();
    const { activePage } = state.resultTable;

    return (
      <div style={{ float: pos }}>
        <Pagination
          boundaryRange={0}
          activePage={activePage}
          size={isMobile ? "small" : "big"}
          siblingRange={1}
          totalPages={this.props.totalPage}
          onPageChange={this.handlePaginationChange}
        />
      </div>
    );
  }
}

class FilterInput extends React.Component {
  componentDidMount() {
    store.subscribe(() => this.forceUpdate());
  }

  state = {
    value: store.getState().resultTable.filterText.value,
  };

  onChangeFilter = (e) => {
    this.setState({
      value: e.target.value,
    });

    store.dispatch({
      type: "Filter_MESSAGE",
      text: { value: e.target.value },
    });
  };

  onClear = (e) => {
    this.setState({
      value: "",
    });

    store.dispatch({
      type: "Filter_MESSAGE",
      text: { value: e.target.value },
    });
  };

  render() {
    return (
      <div>
        <Input
          style={{ width: "100%", maxWidth: "800px" }}
          icon={
            <Icon name="close" inverted circular link onClick={this.onClear} />
          }
          onChange={this.onChangeFilter}
          // value={this.props.value.value}
          value={this.state.value}
          type="text"
          placeholder="輸入股份代號或名稱"
        />
      </div>
    );
  }
}

function valueLabelFormat(value) {
  return value + "%";
}

function SliderFilterRS({ range }) {
  const [value, setValue] = useState(range);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeCommitted = (event, value) => {
    store.dispatch({
      type: "SET_SLIDER_FILTER_RS",
      text: { value },
    });
  };

  return (
    <form>
      <Slider
        valueLabelDisplay="auto"
        value={value}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
        valueLabelFormat={valueLabelFormat}
        // marks={[
        //   { value: 0, label: "0%" },
        //   { value: 100, label: "100%" },
        // ]}
      />
    </form>
  );
}

class DropdownExampleControlled extends Component {
  state = {};

  handleChange = (e, { value }) => {
    this.setState({ value });
    store.dispatch({
      type: "SET_IND_FILTER",
      state: value,
    });
  };

  render() {
    return (
      <div>
        <Dropdown
          onChange={this.handleChange}
          placeholder="行業"
          clearable
          scrolling
          selection
          value={this.props.value}
          options={this.props.options}
        />
      </div>
    );
  }
}

const renderRadio = (field) => (
  <Form.Radio
    checked={field.input.value === field.radioValue}
    label={field.label}
    name={field.input.name}
    onChange={(e, { checked }) => field.input.onChange(field.radioValue)}
  />
);
