import _ from "lodash";
import React, { useEffect, useState, useReducer } from "react";
import {
  Loader,
  Table,
  Pagination,
  Container,
  Header,
  Dropdown,
  Icon,
  Input,
  Form,
  Checkbox,
} from "semantic-ui-react";
import { createStore, combineReducers } from "redux";
import { isMobile } from "react-device-detect";
import source from "../../mock_source";
import { Link } from "react-router-dom";
import { formatNumber } from "../../util/dataFormatter";

const initialState = {
  direction: "descending",
  column: "rs",
  filterText: { value: "" },
  filterInd: "所有",
  filterSector: "",
  filterSubSector: "",
  indOption: [],
  sectorOption: [],
  activePage: "1",
  totalPage: "4",
  topN: "5",
  // isFocus: "1",
  // itemType: "0",
  // lastUpdate: { value: "" },
  // sliderFilter_RS: [0, 100],
  // sliderFilter_PRICE: [0, 1000],
  // priceFilter: ["", ""],
  // capFilter: ["", ""],
  // volFilter: "",
  // filterCondition: { c1: false, c2: false, c3: false, c4: false },
  // rangeFilter_PRICE: [0, 10000],
};

function resultTableReducer(state = initialState, action) {
  switch (action.type) {
    case "CHANGE_SORT": {
      console.log("change sort fire");
      if (state.column === action.column) {
        return {
          ...state,
          direction:
            state.direction === "ascending" ? "descending" : "ascending",
          activePage: 1,
        };
      }
      return {
        ...state,
        column: action.column,
        direction: "ascending",
        activePage: 1,
      };
    }
    case "SET_ACTIVE_PAGE": {
      const string = action.text;
      // window.scrollTo(0, 0);
      return {
        ...state,
        activePage: string,
      };
    }
    case "SET_TOPN_DROPDOWN": {
      return {
        ...state,
        topN: action.state,
      };
    }

    default: {
      return state;
    }
  }
}

const initFilter = {
  pricel: "",
  priceh: "",
  isMain: false,
  isGEM: false,
  isHSI: false,
  isIPO: false,
  isPlgma50: false,
  isPlgma200: false,
  isPlgwma30: false,
  isMa50lgma150: false,
  isMa50lgma200: false,
  isMa150lgma200: false,
  capRl: "",
  capRh: "",
  nVolDay: "",
  voll: "",
  nTurnDay: "",
  turnl: "",
  rsl: "",
  rsh: "",
  ncPctl: "",
  ncPcth: "",
  nTopNConc: "",
  TopNConcl: "",
  hkcPctl: "",
  hkcPcth: "",
};

function ZoomTable(props) {
  const filters = props.filters; //filter
  const { topicInfo, zoomtable } = props.data; //data
  const header = zoomtable.header;
  // const data1 = zoomtable.data;

  // console.log(zoomtable.header);
  // const data1 = _.values(zoomtable);
  const [tableData, setTableData] = useState([]);
  const [filter, setFilter] = useState({ ...initFilter, ...filters });
  function obj2Array(n) {
    return Object.values(n);
  }
  var data1 = _.map(zoomtable.data, obj2Array);
  // console.log(header);
  // console.log(data1);

  useEffect(() => {
    var buffer = data1.map((v) => {
      var d = {};
      for (var i = 0; i < header.length; i++) {
        d[header[i]] = v[0][i];
      }
      let a = _.find(source, function (o) {
        return o.no === d.id;
      });
      return {
        ...d,
        name_zh: a ? a["name_zh"] : "",
        name_en: a ? a["name_en"] : "",
        isPlgma50: v.C >= v.ma50 ? true : false,
        isPlgma200: v.C >= v.ma200 ? true : false,
        isPlgwma30: v.C >= v.maw30 ? true : false,
        isMa50lgma150: v.ma50 >= v.ma150 ? true : false,
        isMa50lgma200: v.ma50 >= v.ma200 ? true : false,
        isMa150lgma200: v.ma150 >= v.ma200 ? true : false,
      };
    });
    console.log(buffer);
    setTableData(buffer);
  }, []);

  useEffect(() => {
    if (filters) {
      setFilter({
        ...initFilter,
        ...filters,
      });
    } else {
      setFilter({
        initFilter,
      });
    }
  }, [filters]);

  let newData = _.sortBy(tableData, ["rs"]).reverse();

  const {
    pricel,
    priceh,
    isMain,
    isGEM,
    isHSI,
    isIPO,
    isPlgma50,
    isPlgma200,
    isPlgwma30,
    isMa50lgma150,
    isMa50lgma200,
    isMa150lgma200,
    capRl,
    capRh,
    nVolDay,
    voll,
    nTurnDay,
    turnl,
    rsl,
    rsh,
    ncPctl,
    ncPcth,
    nTopNConc,
    TopNConcl,
    hkcPctl,
    hkcPcth,
  } = filter;

  if (filters) {
    // Filter for Price
    if (parseFloat(pricel) > 0 || parseFloat(priceh) > 0) {
      newData = dataFilter(newData, pricel, priceh, "C");
    }
    // Filter for MA
    if (
      isPlgma50 === true ||
      isPlgma200 === true ||
      isPlgwma30 === true ||
      isMa50lgma150 === true ||
      isMa50lgma200 === true ||
      isMa150lgma200 === true
    ) {
      if (isPlgma50 === true) {
        newData = _.filter(newData, function (d) {
          return d.isPlgma50 === isPlgma50;
        });
      }
      if (isPlgma200 === true) {
        newData = _.filter(newData, function (d) {
          return d.isPlgma200 === isPlgma200;
        });
      }
      if (isPlgwma30 === true) {
        newData = _.filter(newData, function (d) {
          return d.isPlgwma30 === isPlgwma30;
        });
      }
      if (isMa50lgma150 === true) {
        newData = _.filter(newData, function (d) {
          return d.isMa50lgma150 === isMa50lgma150;
        });
      }
      if (isMa50lgma200 === true) {
        newData = _.filter(newData, function (d) {
          return d.isMa50lgma200 === isMa50lgma200;
        });
      }
      if (isMa150lgma200 === true) {
        newData = _.filter(newData, function (d) {
          return d.isMa150lgma200 === isMa150lgma200;
        });
      }
    }
    // Filter for Cap

    if (parseFloat(capRl) > 0 || parseFloat(capRh) > 0) {
      newData = dataFilter(newData, capRl, capRh, "cap");
    }

    // Filter for RS
    if (parseFloat(rsl) > 0 || parseFloat(rsh) > 0) {
      newData = dataFilter(newData, rsl, rsh, "rs");
    }

    // Filter for nonCCASS
    if (parseFloat(ncPctl) > 0 || parseFloat(ncPcth) > 0) {
      newData = dataFilter(newData, ncPctl, ncPcth, "ncC");
    }

    // Filter for topNpct
    console.log("nTopNConc");
    console.log(nTopNConc);
    console.log(TopNConcl);

    if (parseFloat(nTopNConc) > 0 && parseFloat(TopNConcl) > 0) {
      newData = dataFilter(newData, TopNConcl, 100, "t" + nTopNConc + "C");
    }

    // Filter for HKC pct
    if (parseFloat(hkcPctl) > 0 || parseFloat(hkcPcth) > 0) {
      newData = dataFilter(newData, hkcPctl, hkcPcth, "hkcC");
    }
  }

  return (
    <Container
      style={{
        widht: "100%",
        maxWidth: "950px",
        margin: "auto",
        alignItems: "center",
      }}
    >
      <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
        <Header as="h4" textAlign="right" color="grey">
          {" "}
          數據至 : {topicInfo.lastUpdate} <br />
          總數 : {newData.length}{" "}
        </Header>
      </div>
      <div style={{ textAlign: "center", width: isMobile ? "95%" : "100%" }}>
        <SortableTable data={newData} filter={filter} />
      </div>
    </Container>
  );
}

function dataFilter(data, filterLow, filterHigh, q) {
  const low = parseFloat(filterLow) > 0 ? parseFloat(filterLow) : 0;
  const high = parseFloat(filterHigh) > 0 ? parseFloat(filterHigh) : 99999999;
  const newData = _.filter(data, function (d) {
    return d[q] >= low && d[q] <= high;
  });
  return newData;
}

const rankOptions = [
  { key: "3", text: "Top3", value: "3" },
  { key: "5", text: "Top5", value: "5" },
  { key: "10", text: "Top10", value: "10" },
];

function SortableTable(props) {
  const [state, dispatch] = useReducer(resultTableReducer, initialState);
  const { direction, column, activePage, topN } = state;
  const { data } = props;
  let newData = data;
  const pos_pagination = isMobile ? "center" : "right";

  if (direction === "ascending") {
    newData = _.sortBy(newData, [column]);
  } else {
    newData = _.sortBy(newData, [column]).reverse();
  }

  const nPerPage = 25;
  const totalPage = Math.ceil(newData.length / nPerPage);
  let newData2 = [];
  newData2 = _.slice(
    newData,
    (activePage - 1) * nPerPage,
    activePage * nPerPage
  );
  const handlePaginationChange = (e, { activePage }) => {
    dispatch({
      type: "SET_ACTIVE_PAGE",
      text: activePage,
    });
  };
  return (
    <div
      style={{
        whiteSpace: "nowrap",
        overflowX: "auto",
        display: "inline-block",
        width: "100%",
        textAlign: "center",
      }}
    >
      <Table sortable selectable celled unstackable compact structured>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              textAlign="left"
              rowSpan="2"
              sorted={column === "id" ? direction : null}
              onClick={() => {
                dispatch({ type: "CHANGE_SORT", column: "id" });
              }}
            >
              名稱
              {isMobile ? <br /> : null}
              (編號)
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              rowSpan="2"
              // sorted={column === "cur" ? direction : null}
            >
              <div
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "C" });
                }}
              >
                價格{" "}
                {column === "C" &&
                  (direction === "descending" ? (
                    <Icon link name="sort down" />
                  ) : (
                    <Icon link name="sort up" />
                  ))}
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              rowSpan="2"
              // sorted={column === "rs" ? direction : null}
            >
              <div
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "rs" });
                }}
              >
                RS{" "}
                {column === "rs" &&
                  (direction === "descending" ? (
                    <Icon link name="sort down" />
                  ) : (
                    <Icon link name="sort up" />
                  ))}
              </div>
              <div
                style={{
                  margin: "auto",
                  maxWidth: "100px",
                  maxHeight: "1.5rem",
                }}
              >
                {/* <SliderFilterRS range={sliderFilter_RS} />{" "} */}
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              rowSpan="2"
              style={{ minWidth: "100px" }}
            >
              <div
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "t" });
                }}
              >
                成交額{" "}
                {column === "t" &&
                  (direction === "descending" ? (
                    <Icon link name="sort down" />
                  ) : (
                    <Icon link name="sort up" />
                  ))}
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              rowSpan="2"
              style={{ minWidth: "100px" }}
              // sorted={column === "cur" ? direction : null}
            >
              <div
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "cap" });
                }}
              >
                市值{" "}
                {column === "cap" &&
                  (direction === "descending" ? (
                    <Icon link name="sort down" />
                  ) : (
                    <Icon link name="sort up" />
                  ))}
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              rowSpan="2"
              style={{ minWidth: "100px" }}
            >
              <div
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "ncC" });
                }}
              >
                NON-CCASS持倉%{" "}
                {column === "ncC" &&
                  (direction === "descending" ? (
                    <Icon link name="sort down" />
                  ) : (
                    <Icon link name="sort up" />
                  ))}
              </div>
            </Table.HeaderCell>

            <Table.HeaderCell
              textAlign="center"
              rowSpan="2"
              style={{ minWidth: "100px" }}
            >
              <div
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "t5C" });
                }}
              >
                持倉集中比率%{" "}
                {column === "t5C" &&
                  (direction === "descending" ? (
                    <Icon link name="sort down" />
                  ) : (
                    <Icon link name="sort up" />
                  ))}
              </div>
              <Dropdown
                floating
                options={rankOptions}
                text={_.filter(rankOptions, { value: topN })[0]["text"]}
                onChange={(e, { value }) => {
                  console.log(value);
                  dispatch({
                    type: "SET_TOPN_DROPDOWN",
                    state: value,
                  });
                }}
              />
            </Table.HeaderCell>

            <Table.HeaderCell
              textAlign="center"
              rowSpan="2"
              style={{ minWidth: "100px" }}
            >
              <div
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "hkcC" });
                }}
              >
                北水持倉%{" "}
                {column === "hkcC" &&
                  (direction === "descending" ? (
                    <Icon link name="sort down" />
                  ) : (
                    <Icon link name="sort up" />
                  ))}
              </div>
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row></Table.Row>
        </Table.Header>
        <Table.Body>
          {newData2.map(
            (
              {
                id,
                C,
                V,
                rs,
                ma10,
                ma50,
                ma150,
                ma200,
                maw30,
                t,
                cap,
                ncC,
                hkcC,
                t3C,
                t5C,
                t10C,
                name_zh,
                name_en,
              },
              index
            ) => (
              <>
                {name_zh ? (
                  <Table.Row key={id}>
                    <Table.Cell textAlign="left">
                      {name_zh !== "" ? (
                        <Link to={"/stock/" + id}>
                          {name_zh.length > 8
                            ? name_zh.substring(0, 8) + "..."
                            : name_zh}
                          {isMobile ? <br /> : null}({id})
                        </Link>
                      ) : (
                        <>({id})</>
                      )}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <div style={{ marginBottom: "-0.75rem" }}> ${C} </div>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <div style={{ marginBottom: "-0.75rem" }}> {rs} </div>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <div style={{ marginBottom: "-0.75rem" }}>
                        {" "}
                        {formatNumber(t * 10000)}{" "}
                      </div>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <div style={{ marginBottom: "-0.75rem" }}>
                        {" "}
                        {cap === 0 ? "-" : formatNumber(cap * 100000000)}{" "}
                      </div>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <div style={{ marginBottom: "-0.75rem" }}>
                        {" "}
                        {parseInt(ncC * 10) / 10}
                      </div>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <div style={{ marginBottom: "-0.75rem" }}>
                        {topN === "3"
                          ? parseInt(t3C * 10) / 10
                          : topN === "5"
                          ? parseInt(t5C * 10) / 10
                          : parseInt(t10C * 10) / 10}
                      </div>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <div style={{ marginBottom: "-0.75rem" }}>
                        {" "}
                        {parseInt(hkcC * 10) / 10}
                      </div>
                    </Table.Cell>
                  </Table.Row>
                ) : null}
              </>
            )
          )}
        </Table.Body>
      </Table>
      <div
        style={{
          paddingTop: "25px",
          paddingBottom: "25px",
          float: pos_pagination,
        }}
      >
        <Pagination
          boundaryRange={0}
          activePage={activePage}
          size={isMobile ? "small" : "big"}
          siblingRange={1}
          totalPages={totalPage}
          onPageChange={handlePaginationChange}
        />
      </div>
    </div>
  );
}

export default ZoomTable;
