import React, { useEffect } from "react";
import { Loader } from "semantic-ui-react";
import {
  selectAllTopic,
  fetchTopic,
  toIdle,
} from "../../../features/topic/topicSlice";
import { useSelector, useDispatch } from "react-redux";
import FavouriteTable from "./FavouriteTable";
import { useIsLoggedIn, useUserFavourites } from "features/user/userSlice";
import { FirebaseLoginCard } from "components/auth/FirebaseLoginForm";

export const FavouriteView = () => {
  const isLoggedIn = useIsLoggedIn();
  const wishList = useUserFavourites();
  const dispatch = useDispatch();
  const topics = useSelector(selectAllTopic);
  const topicStatus = useSelector((state) => state.topic.status);
  const error = useSelector((state) => state.topic.error);
  useEffect(() => {
    if (topicStatus === "idle") {
      if (
        topics.find((item) => item.topicInfo.name === "rstable3") === undefined
      ) {
        dispatch(fetchTopic("rstable3"));
      }
      if (
        topics.find((item) => item.topicInfo.name === "stockSectorInfo") ===
        undefined
      ) {
        dispatch(fetchTopic("stockSectorInfo"));
      }
    }
  }, [topicStatus, topics, dispatch]);

  if (!isLoggedIn) {
    return <FirebaseLoginCard />;
  }

  let content;
  if (topicStatus === "loading") {
    content = (
      <div>
        <section className="posts-list">
          <div
            style={{
              padding: "100px",
              margin: "auto",
              alignItems: "center",
              height: "75vh",
            }}
          >
            <Loader active inline="centered">
              {" "}
              Loading
            </Loader>
          </div>
        </section>
      </div>
    );
  } else if (topicStatus === "succeeded") {
    const data = topics.find((item) => item.topicInfo.name === "rstable3");
    const stockSector = topics.find(
      (item) => item.topicInfo.name === "stockSectorInfo"
    );
    if (data && stockSector) {
      content = (
        <FavouriteTableContainer
          data={data}
          stockSector={stockSector}
          wishList={wishList}
        />
      );
    } else {
      dispatch(toIdle({}));
    }
  } else if (topicStatus === "error") {
    content = (
      <div>
        <section className="posts-list">
          <div>{error}</div>
        </section>
      </div>
    );
  }
  return <>{content}</>;
};

export default FavouriteView;

function FavouriteTableContainer(data) {
  let result = data.data;
  let stockSector = data.stockSector;
  let wishList = data.wishList;
  return (
    <>
      <div style={{ paddingTop: "25px", paddingBottom: "25px" }}>
        {typeof result !== "undefined" ? (
          <FavouriteTable
            data={result}
            stockSector={stockSector}
            wishList={wishList}
          />
        ) : (
          <NotFound />
        )}
      </div>
    </>
  );
}

function NotFound() {
  return (
    <div
      style={{
        padding: "100px",
        margin: "auto",
        alignItems: "center",
        height: "75vh",
        // overflow: "auto",
      }}
    >
      <Loader active inline="centered">
        {" "}
        Loading
      </Loader>
    </div>
  );
}
