import React, {useState, useEffect} from 'react'
import { Image, Header, Card, Container } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import GoogleAd from '../ads/DisplayAdsLargeBanner'
import DisplayAdsBanner from '../ads/DisplayAdsBanner'
import HomeWidget from '../widget/HomeWidget'

function CardGroupContent() {
  const history = useHistory();

  return (
    <>
      <Card.Group centered >
        <Card centered onClick={() => { history.push("/topics/vaccine_luckydraw_info") }} >
          <Image
            alt='疫苗抽獎及優惠詳情'
            size='big'
            src="images/topics/T_vaccine.png"
            centered
            wrapped ui={false}
          />
          <Card.Content>
            <Card.Header>疫苗抽獎及優惠詳情</Card.Header>
            <Card.Meta>
              <span className='date'>定期更新</span>
            </Card.Meta>
          </Card.Content>
        </Card>

        <Card centered onClick={() => { history.push("/topics/consumption_voucher") }} >
          <Image
            alt='$5000 消費劵及優惠'
            size='big'
            src="images/topics/topics_consumption_voucher.png"
            centered
            wrapped ui={false}
          />
          <Card.Content>
            <Card.Header>$5000 消費劵及優惠</Card.Header>
            <Card.Meta>
              <span className='date'>定期更新</span>
            </Card.Meta>
          </Card.Content>
        </Card>

        <Card centered onClick={() => { history.push("/rank/active") }} >
          <Image
            alt='港股排行榜'
            size='big'
            src="images/topics/T_rank.png"
            centered
            wrapped ui={false}
          />
          <Card.Content>
            <Card.Header>港股排行榜</Card.Header>
            <Card.Meta>
              <span className='date'>定期更新</span>
            </Card.Meta>
          </Card.Content>
        </Card>

        <Card centered onClick={() => { history.push("/topics/buyback") }} >
          <Image
            alt='股份回購'
            size='big'
            src="images/topics/topics_buyback.png"
            centered
            wrapped ui={false}
          />
          <Card.Content>
            <Card.Header>股份回購</Card.Header>
            <Card.Meta>
              <span className='date'>定期更新</span>
            </Card.Meta>
          </Card.Content>
        </Card>

        <Card centered onClick={() => { history.push("/topics/resultcalendar") }} >
          <Image
            alt='業績公佈時間表'
            size='big'
            src="images/topics/T_companyResultA.png"
            centered
            wrapped ui={false}
          />
          <Card.Content>
            <Card.Header>業績公佈時間表</Card.Header>
            <Card.Meta>
              <span className='date'>定期更新</span>
            </Card.Meta>
          </Card.Content>
        </Card>

        <Card centered onClick={() => { history.push("/shareholding/shareholding_change") }} >
          <Image
            alt='持倉異動'
            size='big'
            src="images/topics/topics_shareholding_change.png"
            centered
            wrapped ui={false}
          />
          <Card.Content>
            <Card.Header>持倉異動</Card.Header>
            <Card.Meta>
              <span className='date'>定期更新</span>
            </Card.Meta>
          </Card.Content>
        </Card>

        <Card centered onClick={() => { history.push("/topics/listedipo") }} >
          <Image
            alt='半新股資訊'
            size='big'
            src="images/topics/T_listedipo.png"
            centered
            wrapped ui={false}
          />
          <Card.Content>
            <Card.Header>半新股資訊</Card.Header>
            <Card.Meta>
              <span className='date'>定期更新</span>
            </Card.Meta>
          </Card.Content>
        </Card>

        <Card centered onClick={() => { history.push("/topics/vaccination") }} >
          <Image
            alt='疫苗接種統計'
            size='big'
            src="images/topics/T_vaccineStat.png"
            centered
            wrapped ui={false}
          />
          <Card.Content>
            <Card.Header>疫苗接種統計</Card.Header>
            <Card.Meta>
              <span className='date'>定期更新</span>
            </Card.Meta>
          </Card.Content>
        </Card>
        <Card centered onClick={() => { history.push("/eta") }} >
          <Image
            alt='交通工具到站預報'
            size='big'
            src="images/topics/topics_eta.png"
            centered
            wrapped ui={false}
          />
          <Card.Content>
            <Card.Header>交通工具到站預報</Card.Header>
            <Card.Meta>
              <span className='date'>建設中</span>
            </Card.Meta>
          </Card.Content>
        </Card>
        {/* <Card centered onClick={() => { history.push("/topics/bigchange") }} >
          <Image
            alt='ccass 持倉異動'
            size='big'
            src="images/topics/topics_bigchange.png"
            centered
            wrapped ui={false}
          />
          <Card.Content>
            <Card.Header>ccass 持倉異動</Card.Header>
            <Card.Meta>
              <span className='date'>每逢交易日後更新</span>
            </Card.Meta>
          </Card.Content>
        </Card> */}

        {/* <Card centered onClick={() => { history.push("/topics/profit_warn") }} >
          <Image
            alt='盈利預告'
            size='big'
            src="images/topics/topics_profit_warn.png"
            centered
            wrapped ui={false}
          />
          <Card.Content>
            <Card.Header>盈利預告</Card.Header>
            <Card.Meta>
              <span className='date'>定期更新</span>
            </Card.Meta>
          </Card.Content>
        </Card> */}

        {/* <Card centered onClick={() => { history.push("/topics/connect_list_update") }} >
          <Image
            alt='港股通名單調整'
            size='big'
            src="images/topics/stock_connect_adjust_pic.png"
            centered
            wrapped ui={false}
          />
          <Card.Content>
            <Card.Header>港股通名單調整</Card.Header>
            <Card.Meta>
              <span className='date'>03/15/2021</span>
            </Card.Meta>
          </Card.Content>
        </Card> */}
      </Card.Group>
    </>
  )
}

function Topics(){
  // const [isMobile, setMobile ] = useState(false);
  // const [sWidth, setSWidth] = useState(null);

  // const handleResize = () => {
  //   const widths = [window.innerWidth];
  //   if (window.screen?.width) {
  //     widths.push(window.screen?.width);
  //   }
  //   const width = Math.min(...widths);

  //   var isMobile = false;
  //   if (width < 800) {
  //     isMobile = true;
  //   }
  //   setMobile(isMobile);
  //   setSWidth(width);
  // };

  // useEffect(() => {
  //   handleResize();
  //   window.addEventListener("resize", handleResize);
  //   return (() => {
  //       window.removeEventListener("resize", handleResize);
  //     }
  //   );
  // }, []);

  return (
    <>
      <MetaTags>
        <title>專題</title>
        <meta name="description" content='專題' />
        <meta property="og:title" content='專題' />
        <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
      </MetaTags>
      <Container textAlign='center'>
        <DisplayAdsBanner />
      </Container>
      <Container
        style={{
          paddingTop: "25px",
          paddingBottom: "25px",
          minHeight: "75vh",
        }}
      >
        <div style={{ textAlign: 'center', paddingTop: '25px' }}>
          <Header as='h1'>專題</Header>
        </div>
        <div style={{paddingTop:"0px"}} >
          < CardGroupContent />
        </div>
        
      </Container>
      <Container textAlign='center' style={{paddingTop:'30px', paddingBottom:'0px'}}>
        <GoogleAd 
            client="ca-pub-8753358837449417" 
            slot="2486257452" 
            format="auto" 
            wrapperDivStyle={{
            marginTop: '0px',
            marginBottom: '0px'
            }}
        />
      </Container>
    </>
  )
}

export default Topics