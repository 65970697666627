import React, { useRef, useEffect } from "react";

import "./PriceLine.css";

function InTableBar(props) {
  //const [ size, setSize ] = useState({width: 0, height: 0})
  const tableRef = useRef(null);
  const canvasRef = useRef(null);

  const min = props.min;
  const q1 = props.secondMin;
  const median = props.mid;
  const q2 = props.secondMax;
  const max = props.max;
  const col = props.col;

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    context.canvas.width = canvas.offsetWidth;
    context.canvas.height = canvas.offsetHeight;

    const circleSize = 5;
    const minPix = circleSize / 2;
    const maxPix = context.canvas.width - circleSize / 2;
    const ratio = (1 / (max - min)) * (maxPix - minPix);

    const q1Pix = (q1 - min) * ratio;
    const medianPix = (median - min) * ratio;
    const q2Pix = (q2 - min) * ratio;

    const lineYPix = context.canvas.height / 2;
    //const circleYPix = context.canvas.height / 3;

    context.lineWidth = "2";
    context.strokeStyle = "lightgray";
    context.lineCap = "round";
    context.beginPath();
    context.moveTo(minPix, lineYPix);
    context.lineTo(maxPix, lineYPix);
    context.stroke();

    context.lineWidth = "2";
    context.strokeStyle = col;
    context.lineCap = "round";
    context.beginPath();
    context.moveTo(q1Pix, lineYPix);
    context.lineTo(q2Pix, lineYPix);
    context.stroke();

    context.lineWidth = "2";
    context.strokeStyle = "#283747";
    context.lineCap = "round";
    context.beginPath();
    context.moveTo(medianPix, lineYPix + 2);
    context.lineTo(medianPix, lineYPix - 5);
    // context.arc(medianPix, lineYPix, circleSize, 0, 2 * Math.PI);
    context.stroke();
  }, [canvasRef, tableRef, max, median, min, q1, q2]);

  // const prefix = props.prefix ? props.prefix : "";
  // const minStr = prefix + Number.parseFloat(min).toFixed(3);
  // const maxStr = prefix + Number.parseFloat(max).toFixed(3);

  return (
    <div ref={tableRef}>
      <div className="ratio-line-table">
        <div className="ratio-line-row">
          <div className="table-cell-canvas" width={16}>
            <canvas
              data-paper-resize
              className="ratio-line-canvas"
              ref={canvasRef}
            ></canvas>
          </div>
        </div>
      </div>
      {/* <Table unstackable className="ratio-line-table" style>
        <Table.Row className="ratio-line-row">
          <Table.Cell className="table-cell-canvas" width={16} colSpan={2}>
            <canvas data-paper-resize className="ratio-line-canvas" ref={canvasRef}></canvas>
          </Table.Cell>
        </Table.Row>
        <Table.Row className="ratio-line-row">
          <Table.Cell className="table-cell-min" width={8}>
            {minStr}
          </Table.Cell>
          <Table.Cell className="table-cell-max" width={8}>
            {maxStr}
          </Table.Cell>
        </Table.Row>
      </Table> */}
    </div>
  );
}

export default InTableBar;
