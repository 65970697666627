import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { analytics } from '../../config/fbConfig';
import { logEvent } from "firebase/analytics";

const initialState = {
  LRRoute: {},
  route: {},
  status: 'idle',
  error: null,
}

export const fetchLRRouteData = createAsyncThunk('index/LRRoute', async () => {
  logEvent(analytics, "select_content", {
    content_type: "lr_route",
  });
  const tempBody = JSON.stringify({
    "mode": "cors",
    "request": "route",
    "transport_name": "LR",
    "lang": "zh-HK"
  });
  const result = await fetch("https://n6r56ojg5m.execute-api.ap-east-1.amazonaws.com/default/public-transport-route-function-xWIkOoHW0aQr", {
    method: 'POST',
    body: tempBody
  });
  return result.json();
});

const lrRouteDataSlice = createSlice({
  name: 'LRRoute',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchLRRouteData.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchLRRouteData.fulfilled]: (state, action) => {
      state.LRRoute = action.payload;
      state.route = action.payload;
      state.status = 'succeeded';
    },
    [fetchLRRouteData.rejected]: (state, action) => {
      state.error = action.payload;
      state.status = 'failed';
    },
  },
})

export default lrRouteDataSlice.reducer;
