import React from "react";
import MetaTags from "react-meta-tags";
import StockMarketView from "./StockMarketView";
import RSChart from "../../charts/RSChart";
import DisplayAdsBanner from "../../ads/DisplayAdsBanner";
import GoogleAd from "../../ads/Google_ad";
import { Container, Header } from "semantic-ui-react";

export const TopicStockMarket = () => {
  return (
    <>
      <MetaTags>
        <title>StockMarket</title>
        <meta name="description" content="" />
        <meta property="og:title" content="" />
        <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
      </MetaTags>
      <Container textAlign="center">
        <DisplayAdsBanner />
      </Container>

      <div
        style={{ paddingTop: "25px", paddingBottom: "25px", minHeight: "75vh" }}
      >
        <StockMarketView />
      </div>
      <div
        textAlign="center"
        style={{ paddingTop: "30px", paddingBottom: "0px" }}
      >
        <GoogleAd
          client="ca-pub-8753358837449417"
          slot="2486257452"
          format="auto"
          wrapperDivStyle={{
            marginTop: "30px",
            marginBottom: "20px",
          }}
        />
      </div>
    </>
  );
};

export default TopicStockMarket;
