import { fetchCTBBusRouteData } from '../../../../features/ctb/CTBBusRouteSlice'
import { fetchGMBBusRouteData } from '../../../../features/gmb/GMBBusRouteSlice'
import { fetchKMBBusRouteData } from '../../../../features/kmb/KMBBusRouteSlice'
import { fetchLRRouteData } from '../../../../features/lr/LRRouteSlice'
import { fetchMTRRouteData } from '../../../../features/mtr/MTRRouteSlice'
import { fetchMTRBRouteData } from '../../../../features/mtrb/MTRBRouteSlice'
import { fetchNLBRouteData } from '../../../../features/nlb/NLBRouteSlice'
import { fetchNWFBBusRouteData } from '../../../../features/nwfb/NWFBBusRouteSlice'


import { useSelector } from 'react-redux'

export const convHK1980GridToWGS84LatLong = (N, E) => {
  const N_0 = 819069.8;
  const E_0 = 836694.05;
  // const PHI_0 = 22.3121 / 180.0 * Math.PI;
  const lambda_0 = 114.1786 / 180.0 * Math.PI;
  const m_0 = 1.0;
  const M_0 = 2468395.728;
  // const v_s = 6381480.502;
  // const p_s = 6344727.809;
  // const psi_s = 1.0058;
  const a = 6378388;
  const e2 = 0.00672267;
  const A_0 = 1.0 - e2 / 4.0 - 3.0 * e2 * e2 / 64.0;
  const A_2 = 3.0 / 8.0 * (e2 + e2 * e2 / 4);
  const A_4 = 15.0 / 256.0 * e2 * e2;
  
  const delta_N = N - N_0;
  const delta_E = E - E_0;
  const M = (delta_N + M_0) / m_0;

  let PHI_P = 0;
  for (let i = 0; i < 10; i++) {
    PHI_P = (M / a + A_2 * Math.sin(2 * PHI_P) - A_4 * Math.sin(4 * PHI_P)) / A_0;
  }
  const t_p = Math.tan(PHI_P);
  const v_p = a / Math.pow(1 - e2 * Math.pow(Math.sin(PHI_P), 2), 0.5);
  const p_p = a * (1 - e2) / Math.pow(1 - e2 * Math.pow(Math.sin(PHI_P), 2), 1.5);
  const psi_p = v_p / p_p;

  const lambda = lambda_0 + 
                 delta_E / m_0 / v_p / Math.cos(PHI_P) +
                 (psi_p + 2 * t_p * t_p) * Math.pow(delta_E / m_0 / v_p, 3) / 6 / Math.cos(PHI_P);
  const PHI = PHI_P - t_p / m_0 / p_p * delta_E * delta_E / 2 / m_0 / v_p;

  const longitude = lambda / Math.PI * 180 + 8.8 / 60 / 60;
  const latitude = PHI / Math.PI * 180 - 5.5 / 60 / 60;

  return {latitude: latitude, longitude: longitude};
}

export const useFetchData = (state) => {

  const kmbData = useSelector(state => state.kmbBusRoute);
  const kmbStatus = useSelector(state => state.kmbBusRoute.status);
  const mtrData = useSelector(state => state.mtrRoute);
  const mtrStatus = useSelector(state => state.mtrRoute.status);
  const lrData = useSelector(state => state.lrRoute);
  const lrStatus = useSelector(state => state.lrRoute.status);
  const nwfbData = useSelector(state => state.nwfbBusRoute);
  const nwfbStatus = useSelector(state => state.nwfbBusRoute.status);
  const ctbData = useSelector(state => state.ctbBusRoute);
  const ctbStatus = useSelector(state => state.ctbBusRoute.status);
  const gmbData = useSelector(state => state.gmbBusRoute);
  const gmbStatus = useSelector(state => state.gmbBusRoute.status);
  const mtrbData = useSelector(state => state.mtrbRoute);
  const mtrbStatus = useSelector(state => state.mtrbRoute.status);
  const nlbData = useSelector(state => state.nlbRoute);
  const nlbStatus = useSelector(state => state.nlbRoute.status);

  let data;
  let status;
  let fetchData;

  if (state.transport_name === "KMB") {
    data = kmbData;
    status = kmbStatus;
    fetchData = fetchKMBBusRouteData;
  } else if (state.transport_name === "MTR") {
    data = mtrData;
    status = mtrStatus;
    fetchData = fetchMTRRouteData;
  } else if (state.transport_name === "LR") {
    data = lrData;
    status = lrStatus;
    fetchData = fetchLRRouteData;
  } else if (state.transport_name === "NWFB") {
    data = nwfbData;
    status = nwfbStatus;
    fetchData = fetchNWFBBusRouteData;
  } else if (state.transport_name === "CTB") {
    data = ctbData;
    status = ctbStatus;
    fetchData = fetchCTBBusRouteData;
  } else if (state.transport_name === "GMB") {
    data = gmbData;
    status = gmbStatus;
    fetchData = fetchGMBBusRouteData;
  } else if (state.transport_name === "MTRB") {
    data = mtrbData;
    status = mtrbStatus;
    fetchData = fetchMTRBRouteData;
  } else if (state.transport_name === "NLB") {
    data = nlbData;
    status = nlbStatus;
    fetchData = fetchNLBRouteData;
  }
  return [fetchData, status, data];
}

export const fetchETA = (routeStops, setRouteStops) => {
  let requests = [];
  let para = [];
  for (let index in routeStops.data) {
    let routeStopSet = routeStops.data[index];
    for (let subIndex in routeStopSet) {
      let routeStop = routeStopSet[subIndex];
      if (routeStop.transport_name === "KMB") {
        let temp = "https://data.etabus.gov.hk/v1/transport/kmb/stop-eta/" + 
                    routeStop.stop_code;
        requests.push(temp);
        para.push({});
      } else if (routeStop.transport_name === "MTR") {
        let temp = "https://rt.data.gov.hk/v1/transport/mtr/getSchedule.php?line=" +
                    routeStop.route_code + 
                    "&sta=" + 
                    routeStop.stop_code;
        requests.push(temp);
        para.push({});
      } else if (routeStop.transport_name === "LR") {
        let temp = "https://rt.data.gov.hk/v1/transport/mtr/lrt/getSchedule?station_id=" +
                    routeStop.stop_code;
        requests.push(temp);
        para.push({});
      } else if (routeStop.transport_name === "NWFB") {
        let temp = "https://rt.data.gov.hk/v1/transport/citybus-nwfb/eta/NWFB/" + routeStop.stop_code + '/' + routeStop.route_code;
        requests.push(temp);
        para.push({});
      } else if (routeStop.transport_name === "CTB") {
        let temp = "https://rt.data.gov.hk/v1/transport/citybus-nwfb/eta/CTB/" + routeStop.stop_code + '/' + routeStop.route_code;
        requests.push(temp);
        para.push({});
      } else if (routeStop.transport_name === "GMB") {
        let temp = "https://data.etagmb.gov.hk/eta/route-stop/" + routeStop.route_code + '/' + routeStop.stop_code;
        requests.push(temp);
        para.push({});
      } else if (routeStop.transport_name === "MTRB") {
        let temp = "https://rt.data.gov.hk/v1/transport/mtr/bus/getSchedule";
        requests.push(temp);
        para.push({
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            language: "en",
            routeName: routeStop.route_code,
          }),
        });
      } else if (routeStop.transport_name === "NLB") {
        let temp = "https://rt.data.gov.hk/v1/transport/nlb/stop.php?action=estimatedArrivals";
        requests.push(temp);
        para.push({
          method: "POST",
          body: JSON.stringify({
            routeId: routeStop.route_code,
            stopId: routeStop.stop_code,
            language: "en",
          })
        });
      }
    }
  }
  let promises = [];
  for (let j = 0; j < requests.length; j++) {
    promises.push(fetch(requests[j], para[j]).catch((error) => {
      return [];
    }));
  }
  Promise.all(promises)
  .then((responses) => Promise.all(responses.map(response => {
    try {
      return response.json();
    } catch(error) {
      return [];
    }
  })))
  .then(function handleData(data) {
    let wholeEta = [];
    let i = 0;
    for (let index = 0; index < routeStops.data.length; index++) {
      let groupEta = [];
      for (let subIndex in routeStops.data[index]) {

        let etaRow = routeStops.data[index][subIndex];
        if (!etaRow) {
          continue;
        }
        if (etaRow.transport_name === "KMB") {
          etaRow["eta"] = [];
          for (let k = 0; k < data[i].data.length; k++) {
            if (data[i].data[k].dir === etaRow.route_bound + "" && 
                data[i].data[k].route === etaRow.route_code && 
                data[i].data[k].service_type + "" === etaRow.route_service_type + "") {
              etaRow["eta"].push({
                time: data[i].data[k].eta
              });
            }
          }
          
        } else if (etaRow.transport_name === "MTR") {
          
          etaRow["eta"] = [];
          
          const station_code = etaRow.route_code + "-" + etaRow.stop_code;
  
          if (data[i].data) {
            let target_route_bond = "DOWN";
            if (etaRow.route_bound === "I") {
              target_route_bond = "UP"
            }
            let eta_row = data[i].data[station_code][target_route_bond];
            if (eta_row) {
              for (let k = 0; k < eta_row.length; k++) {
                etaRow["eta"].push({
                  time: eta_row[k].time.replace(" ", "T") + "+08:00",
                  dest: etaRow.last_stop_name,
                });
              }
            }
          }
          
  
        } else if (etaRow.transport_name === "LR") {
          etaRow["eta"] = [];
          const platform_list = data[i]["platform_list"];
          if (platform_list) {
            for (let j = 0; j < platform_list.length; j++) {
              const route_list = platform_list[j]["route_list"];
              if (route_list && ((platform_list[j]["platform_id"] + "") === (etaRow.stop_platform + ""))) {
                for (let k = 0; k < route_list.length; k++) {
                  if (route_list[k]["dest_ch"] === etaRow.last_stop_name && route_list[k]["route_no"] === etaRow.route_code) {
                    let remain_min = -1;
                    if (route_list[k]["time_en"] === "-") {
                      remain_min = 0;
                    } else {
                      remain_min = parseInt(route_list[k]["time_en"].replace(" min", ""));
                    }
                    if (remain_min >= 0) {
                      etaRow["eta"].push({
                        dest: etaRow.last_stop_name,
                        remain_min: remain_min,
                      });
                    }
                  }
                }
              }
            }
          } else {
            etaRow = routeStops.data[index][subIndex];
          }  
        } else if (etaRow.transport_name === "NWFB") {
          etaRow["eta"] = [];
          
          for (let k = 0; k < data[i].data.length; k++) {
            if (data[i].data[k]['co'] === "NWFB" && data[i].data[k]['route'] === ("" + etaRow.route_code) && data[i].data[k]['dir'] === etaRow.route_bound) {
              etaRow["eta"].push({
                time: data[i].data[k].eta
              });
            }
          }
          
        } else if (etaRow.transport_name === "CTB") {
          etaRow["eta"] = [];
          
          for (let k = 0; k < data[i].data.length; k++) {
            if (data[i].data[k]['co'] === "CTB" && data[i].data[k]['route'] === etaRow.route_code && data[i].data[k]['dir'] === etaRow.route_bound) {
              etaRow["eta"].push({
                time: data[i].data[k].eta
              });
            }
          }
          
        } else if (etaRow.transport_name === "GMB") {
          etaRow["eta"] = [];
          for (let k = 0; k < data[i].data.length; k++) {
            let expect_route_seq = "1";
            if (etaRow.route_bound === "I") {
              expect_route_seq = "2";
            }
            if ((data[i].data[k]['route_seq'] + "") === expect_route_seq) {
              for (let l = 0; l < data[i].data[k].eta.length; l++) {
                etaRow["eta"].push({
                  time: data[i].data[k].eta[l].timestamp
                });
              }
            }
          }
          
        } else if (etaRow.transport_name === "MTRB") {
          etaRow["eta"] = [];
  
  
          for (let j = 0; j < data[i].busStop.length; j++) {
            if (etaRow.stop_code === data[i].busStop[j].busStopId) {
              for (let k = 0; k < data[i].busStop[j].bus.length; k++) {
                if (data[i].busStop[j].bus[k].arrivalTimeInSecond < 108000) {
                  etaRow["eta"].push({
                    remain_min: data[i].busStop[j].bus[k].arrivalTimeInSecond / 60.0,
                  });
                } else {
                  etaRow["eta"].push({
                    remain_min: data[i].busStop[j].bus[k].departureTimeInSecond / 60.0,
                  });
                }
              }
            }
          }
          
        } else if (etaRow.transport_name === "NLB") {
          etaRow["eta"] = [];
          if (data[i].estimatedArrivals)
          {
            for (let k = 0; k < data[i].estimatedArrivals.length; k++) {
              etaRow["eta"].push({
                time: data[i].estimatedArrivals[k]["estimatedArrivalTime"],
              });
            }
          }
          
        } else {
          i = i - 1;
          
        }
        i = i + 1;
        groupEta.push(etaRow);
      }
      wholeEta.push(groupEta);
    }
    setRouteStops({
      ...routeStops,
      data: wholeEta,
      etaLoaded: true,
    });
  })


}

export const getKey = (state, curr_route) => {
  if (state.transport_name === "KMB" || 
      state.transport_name === "LR" ||
      state.transport_name === "NWFB" ||
      state.transport_name === "CTB" ||
      state.transport_name === "MTRB" || 
      state.transport_name === "NLB") {
    const regexp1 = /([a-zA-Z]*)[0-9]+[a-zA-Z]*/;
    const match1 = curr_route.route_code.match(regexp1);
    const regexp2 = /[a-zA-Z]*([0-9]+)[a-zA-Z]*/;
    const match2 = curr_route.route_code.match(regexp2);
    const regexp3 = /[a-zA-Z]*[0-9]+([a-zA-Z]*)/;
    const match3 = curr_route.route_code.match(regexp3);
    let id0 = ("00000" + match2[1]).slice(-5);
    let id1 = ("00000" + (match1.length >= 2 ? match1[1] : "0")).slice(-5);
    let id2 = ("00000" + (match3.length >= 2 ? match3[1] : "0")).slice(-5);
    let id3 = ("00000" + (curr_route.route_service_type)).slice(-5);
    const id = id0 + id1 + id2 + id3;
    return id;
  } else if (state.transport_name === "GMB") {
    let temp = curr_route.route_name.slice(0, curr_route.route_name.indexOf(" "));

    const regexp1 = /([a-zA-Z]*)[0-9]+[a-zA-Z]*/;
    const match1 = temp.match(regexp1);
    const regexp2 = /[a-zA-Z]*([0-9]+)[a-zA-Z]*/;
    const match2 = temp.match(regexp2);
    const regexp3 = /[a-zA-Z]*[0-9]+([a-zA-Z]*)/;
    const match3 = temp.match(regexp3);
    let id0 = ("00000" + match2[1]).slice(-5);
    let id1 = ("00000" + (match1.length >= 2 ? match1[1] : "0")).slice(-5);
    let id2 = ("00000" + (match3.length >= 2 ? match3[1] : "0")).slice(-5);
    const id = id0 + id1 + id2;
    return id;
  }
  return "";
}

export const getTransportName = (transport_name) => {
  if (transport_name === "KMB") {
    return "九巴";
  } else if (transport_name === "MTR") {
    return "港鐵";
  } else if (transport_name === "LR") {
    return "輕鐵";
  } else if (transport_name === "NWFB") {
    return "新巴";
  } else if (transport_name === "CTB") {
    return "城巴";
  } else if (transport_name === "GMB") {
    return "專線小巴";
  } else if (transport_name === "MTRB") {
    return "港鐵巴士";
  } else if (transport_name === "NLB") {
    return "新大嶼山巴士";
  } else if (transport_name === "FAV") {
    return "收藏";
  } else if (transport_name === "NEAR") {
    return "附近";
  } else if (transport_name === "SRCH") {
    return "搜尋";
  }
  return ""
}