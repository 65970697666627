import React from 'react'
import { Table, Container, Icon, Label, Menu} from 'semantic-ui-react'

const data = [
    {name : 'a', action:'No Action', b: 'None'},
    {name : 'b', action:'No Action', b: 'None'},
    {name : 'c', action:'No Action', b: 'None'}
]
export default function TableExampleSelectableRow(){
    const [selected, setSelected] = React.useState([]);
    const isSelected = (name) => selected.indexOf(name) !== -1;
    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
    
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
          );
        }
        setSelected(newSelected);
      };
    return (
        <Container>
            <Table celled selectable>
                <Table.Header>
                <Table.Row>
                    <Table.HeaderCell> Name </Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Notes</Table.HeaderCell>
                </Table.Row>
                </Table.Header>
                <Table.Body>
                {data.map((row  , index) => {
                    const isItemSelected = isSelected(row.name);
                    console.log(isItemSelected)
                    return (
                        <>
                            <Table.Row key={row.name} onClick={(event) => handleClick(event, row.name)}>
                                <Table.Cell>
                                    <Icon size='large' name = {isItemSelected ? "caret down": "caret right"} />
                                    {row.name} 
                                </Table.Cell>
                                <Table.Cell >
                                    {row.action}                
                                </Table.Cell>
                                <Table.Cell >
                                    {row.b}                
                                </Table.Cell>
                            </Table.Row>
                            {
                                isItemSelected? 
                                    <Table.Row>
                                        <Table.Cell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                                            <Row />
                                        </Table.Cell>
                                    </Table.Row>
                                    : null
                                
                            }     
                        </>
                    )
                })}
            </Table.Body>
        </Table>
    </Container>
)}

function Row(){
    return(
        <Table celled>
        <Table.Header>
        <Table.Row>
            <Table.HeaderCell>Header</Table.HeaderCell>
            <Table.HeaderCell>Header</Table.HeaderCell>
            <Table.HeaderCell>Header</Table.HeaderCell>
        </Table.Row>
        </Table.Header>

        <Table.Body>
        <Table.Row>
            <Table.Cell>
            <Label ribbon>First</Label>
            </Table.Cell>
            <Table.Cell>Cell</Table.Cell>
            <Table.Cell>Cell</Table.Cell>
        </Table.Row>
        <Table.Row>
            <Table.Cell>Cell</Table.Cell>
            <Table.Cell>Cell</Table.Cell>
            <Table.Cell>Cell</Table.Cell>
        </Table.Row>
        <Table.Row>
            <Table.Cell>Cell</Table.Cell>
            <Table.Cell>Cell</Table.Cell>
            <Table.Cell>Cell</Table.Cell>
        </Table.Row>
        </Table.Body>

        <Table.Footer>
        <Table.Row>
            <Table.HeaderCell colSpan='3'>
            <Menu floated='right' pagination>
                <Menu.Item as='a' icon>
                <Icon name='chevron left' />
                </Menu.Item>
                <Menu.Item as='a'>1</Menu.Item>
                <Menu.Item as='a'>2</Menu.Item>
                <Menu.Item as='a'>3</Menu.Item>
                <Menu.Item as='a'>4</Menu.Item>
                <Menu.Item as='a' icon>
                <Icon name='chevron right' />
                </Menu.Item>
            </Menu>
            </Table.HeaderCell>
        </Table.Row>
        </Table.Footer>
    </Table>
    )
}
