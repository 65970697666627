import { TableContainer, Table,TableHead, TableBody, TableRow, TableCell, TextField, TablePagination, CircularProgress } from "@material-ui/core";
import { useState } from "react";
import RouteRow from "./RouteRow";
import { getKey, getTransportName } from "../lib/ETAUtility";
import Credit from './Credit';

export default function RouteTable(props) {
  const [ page, setPage ] = useState(0);
  const [ rowsPerPage, setRowsPerPage] = useState(25);
  const [ filterText, setFilterText ] = useState("");

  let routes = [];
  if (props.routes) {
    routes = props.routes.map((item) =>
      Object.assign({}, item, { visible: false, key: "" })
    )
  }

  const state = props.state;
  const onStateChange = props.onStateChange; 
  const loading = props.loading;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (event) => {
    setFilterText(event.target.value);
  };

  for (let routeIndex in routes) {
    let temp_visible = true;

    const words = filterText.split(" ");
    for (let wordIndex in words) {
      let match = false;
      const word = words[wordIndex];
      const regExp = new RegExp(word, "i");
      if (routes[routeIndex].route_name.match(regExp)) {
        match = true;
      }
      if (routes[routeIndex].route_src_name.match(regExp)) {
        match = true;
      }
      if (routes[routeIndex].route_dest_name.match(regExp)) {
        match = true;
      }
      temp_visible = temp_visible && match;
    }
    routes[routeIndex].visible = temp_visible;
    routes[routeIndex].key = getKey(state, routes[routeIndex]);
  }

  routes.sort((a, b) => {
    return a.key.localeCompare(b.key);
  });


  let filteredRoutes = [];
  for (let routeIndex in routes) {
    if (routes[routeIndex].visible) {
      filteredRoutes.push(routes[routeIndex]);
    }
  }
  
  return (
    <>
      <TableContainer>
        <Table size="small" aria-label="a dense table">
        
        <TableRow>
          <TableCell>
            <h1>{getTransportName(state.transport_name)}路線</h1>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Credit state={state}></Credit>
          </TableCell>
        </TableRow>
        </Table>
      </TableContainer>
      <TableContainer>
        <TablePagination
          component="div"
          count={filteredRoutes.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell width="30%">
                <h3>
                  路線
                </h3>
              </TableCell>
              <TableCell width="70%">
                <h3>
                  詳情
                </h3>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>
                <TextField fullWidth={true} placeholder="過濾..." value={filterText} onChange={handleChange} ></TextField>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { loading && 
              <TableRow>
                <TableCell colSpan={2} align="center">
                  <CircularProgress></CircularProgress>
                </TableCell>
              </TableRow>
            }
            { !loading && filteredRoutes.map((route, index) => (
              ((page) * rowsPerPage <= index) && (index < (page + 1) * rowsPerPage) && <RouteRow state={state} onStateChange={(state) => onStateChange(state)} route={route}></RouteRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={filteredRoutes.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}