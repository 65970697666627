import React, { useEffect } from "react";
// import "./StockChart.css";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import _ from "lodash";
import MetaTags from "react-meta-tags";
import { useSelector, useDispatch } from "react-redux";
import { Loader } from "semantic-ui-react";
import {
  selectAllStock,
  fetchStock,
  toIdle,
} from "../../../features/stock/stockSlice";
import StockChart2T from "../../../components/charts/StockChart2T";

export const StockChartContainer = ({ id }) => {
  const stockId = ("0000" + id).slice(-4);
  const dispatch = useDispatch();
  const stocks = useSelector(selectAllStock);
  const stockStatus = useSelector((state) => state.stock.status);
  const error = useSelector((state) => state.stock.error);

  useEffect(() => {
    if (stockStatus === "idle") {
      if (true) {
        dispatch(fetchStock(stockId));
      }
    }
  }, [stockId, stockStatus, dispatch]);

  let content;

  if (stockStatus === "loading") {
    content = (
      <div>
        <section className="posts-list">
          <div
            style={{
              padding: "100px",
              margin: "auto",
              alignItems: "center",
              height: "75vh",
              // overflow: "auto",
            }}
          >
            <Loader active inline="centered">
              {" "}
              Loading
            </Loader>
          </div>
        </section>
      </div>
    );
  } else if (stockStatus === "succeeded") {
    const data = stocks.find((item) => item.stockInfo.no === stockId);
    if (data) {
      content = <ChartView result={data} />;
    } else {
      dispatch(toIdle({}));
    }
  } else if (stockStatus === "error") {
    content = (
      <div>
        <section className="posts-list">
          <div>{error}</div>
        </section>
      </div>
    );
  }
  return (
    <>
      <MetaTags>
        <title>{stockId}</title>
        <meta name="description" content={stockId} />
        <meta property="og:title" content={stockId} />
        <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
      </MetaTags>
      {content}
    </>
  );
};

function ChartView(props) {
  // console.log(props);
  return <StockChart2T chartData={props.result} />;
}

export default StockChartContainer;
