import _ from "lodash";
import React, { useEffect, useState, useReducer, useRef } from "react";
import {
  // Loader,
  Table,
  // Pagination,
  // Container,
  Header,
  // Dropdown,
  // Icon,
  // Input,
  // Form,
  // Checkbox,
  Sticky,
  Menu,
} from "semantic-ui-react";
// import { createStore, combineReducers } from "redux";
import { isMobile } from "react-device-detect";
import source from "../../../mock_source";
import { Link } from "react-router-dom";
import { formatNumber } from "../../../util/dataFormatter";
// import StockChart2T from "../../charts/StockChart2T";
// import RSChart from "../../charts/RSChart";
// import { useSelector, useDispatch } from "react-redux";
// import {
//   selectAllStock,
//   fetchStock,
//   toIdle,
// } from "../../../features/stock/stockSlice";
import InFeedAdsWhite from "../../ads/InFeedAdsWhite";

const initialState = {
  direction: "descending",
  column: "chgPct",
  tableType: "0",
  // activePage: "1",
  // totalPage: "4",
};

function resultTableReducer(state = initialState, action) {
  switch (action.type) {
    case "CHANGE_SORT": {
      if (state.column === action.column) {
        return {
          ...state,
          direction:
            state.direction === "ascending" ? "descending" : "ascending",
          activePage: 1,
        };
      }
      return {
        ...state,
        column: action.column,
        direction: "ascending",
        activePage: 1,
      };
    }
    case "SET_TABLETYPE": {
      const string = action.state;
      return {
        ...state,
        tableType: string,
      };
    }
    default: {
      return state;
    }
  }
}

function IndexTableTable(props) {
  const { topicInfo, data } = props.data; //data
  const header = data.header;
  const [hsiData, setHsiData] = useState([]);
  const [htechData, setHtechData] = useState([]);
  function obj2Array(n) {
    return Object.values(n);
  }
  var hsiData_ = _.map(data.hsi, obj2Array);
  var htechData_ = _.map(data.htech, obj2Array);

  useEffect(() => {
    var buffer = hsiData_
      .map((v) => {
        var d = {};
        for (var i = 0; i < header.length; i++) {
          d[header[i]] = v[0][i];
        }
        let a = _.find(source, function (o) {
          return o.no === d.id;
        });
        return {
          ...d,
          name_zh: a ? a["name_zh"] : "",
          name_en: a ? a["name_en"] : "",
        };
      })
      .filter((data) => data.name_zh !== "");
    setHsiData(buffer);
    buffer = htechData_
      .map((v) => {
        var d = {};
        for (var i = 0; i < header.length; i++) {
          d[header[i]] = v[0][i];
        }
        let a = _.find(source, function (o) {
          return o.no === d.id;
        });
        return {
          ...d,
          name_zh: a ? a["name_zh"] : "",
          name_en: a ? a["name_en"] : "",
        };
      })
      .filter((data) => data.name_zh !== "");
    setHtechData(buffer);
  }, []);
  // console.log(hsiData);
  // console.log(htechData);

  return (
    <div
      style={{
        width: "100%",
        maxWidth: "1000px",
        margin: "auto",
        alignItems: "center",
      }}
    >
      <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
        <Header as="h4" textAlign="right" color="grey">
          {" "}
          數據至 : {topicInfo.lastUpdate} <br />
          {/* 總數 : {newData.length}{" "} */}
        </Header>
      </div>
      <div
        style={{
          textAlign: "center",
        }}
      >
        <SortableTable
          hsiData={_.sortBy(hsiData, ["chgPct"]).reverse()}
          htechData={_.sortBy(htechData, ["chgPct"]).reverse()}
        />
      </div>
      {/* <div
        style={{
          textAlign: "center",
        }}
      >
        <SortableTable data={_.sortBy(htechData, ["chgPct"]).reverse()} />
      </div> */}
    </div>
  );
}

function SortableTable(props) {
  const scrollRef = useRef(null);
  const [chartLeft, setChartLeft] = useState(0);
  const [chartWidth, setChartWidth] = useState(10);
  const [state, dispatch] = useReducer(resultTableReducer, initialState);
  const { direction, column, tableType } = state;
  const { hsiData, htechData } = props;
  // console.log(hsiData);
  // console.log(htechData);
  let newData = [];
  if (tableType === "0") {
    newData = hsiData;
  } else {
    newData = htechData;
  }

  const [selected, setSelected] = React.useState([]);
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const resize = (event) => {
    if (scrollRef && scrollRef.current) {
      setChartWidth(scrollRef.current.clientWidth - 20);
    }
  };
  const handleScroll = (event) => {
    setChartLeft(scrollRef.current.scrollLeft);
  };
  useEffect(() => {
    resize();
    window.addEventListener("resize", resize);
    return () => {
      window.addEventListener("resize", resize);
    };
  }, [scrollRef]);
  useEffect(() => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.addEventListener("scroll", handleScroll);
      return () => {
        if (scrollRef && scrollRef.current) {
          scrollRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [scrollRef]);

  const pos_pagination = isMobile ? "center" : "right";
  if (direction === "ascending") {
    newData = _.sortBy(newData, [column]);
    // console.log(newData);
  } else {
    newData = _.sortBy(newData, [column]).reverse();
    // console.log(newData);
  }

  // const nPerPage = 100;
  // const totalPage = Math.ceil(newData.length / nPerPage);
  // let newData2 = [];
  // newData2 = _.slice(
  //   newData,
  //   (activePage - 1) * nPerPage,
  //   activePage * nPerPage
  // );
  let newData2 = newData;
  // const handlePaginationChange = (e, { activePage }) => {
  //   dispatch({
  //     type: "SET_ACTIVE_PAGE",
  //     text: activePage,
  //   });
  // };

  const topRowHeaderStyle = {
    position: "sticky",
    top: "0px",
    zIndex: "10",
    backgroundColor: "rgb(240,240,240)",
    // fontSize: normalSize[fontSize],
  };
  const firstColHeaderStyle = {
    position: "sticky",
    left: "0px",
    zIndex: "5",
    // backgroundColor: "rgb(240,240,240)",
    backgroundColor: "rgb(255,255,255)",
    // fontSize: normalSize[fontSize],
  };
  const contentStyle = {
    // fontSize: normalSize[fontSize],
    zIndex: "0",
  };
  // const rowHeaderStyle = {
  //   // fontSize: smallSize[fontSize],
  //   color: "grey",
  // };

  return (
    <div
      style={{
        whiteSpace: "nowrap",
        overflowX: "hidden",
        display: "inline-block",
        width: "100%",
        textAlign: "center",
      }}
    >
      <div style={{ paddingBottom: "10px" }}>
        <Menu compact pointing secondary size="big">
          <Menu.Item
            name="恒生指數"
            active={tableType === "0"}
            onClick={() => {
              dispatch({ type: "SET_TABLETYPE", state: "0" });
            }}
          />
          <Menu.Item
            name="恒生科技指數"
            active={tableType === "1"}
            onClick={() => {
              dispatch({ type: "SET_TABLETYPE", state: "1" });
            }}
          />
        </Menu>
      </div>
      <div
        style={{
          overflow: "auto",
          // maxHeight: "85vh",
          overscrollBehavior: "auto",
        }}
        ref={scrollRef}
      >
        <Table sortable selectable celled unstackable compact structured>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                style={{
                  position: "sticky",
                  top: "0px",
                  left: "0px",
                  zIndex: "15",
                  backgroundColor: "rgb(240,240,240)",
                  borderWidth: "0px",
                }}
                textAlign="left"
                rowSpan="2"
                sorted={column === "id" ? direction : null}
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "id" });
                }}
              >
                名稱
                <br />
                {isMobile ? <br /> : null}
                (編號)
              </Table.HeaderCell>
              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
                sorted={column === "close" ? direction : null}
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "close" });
                }}
              >
                價格
              </Table.HeaderCell>
              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
                sorted={column === "chg" ? direction : null}
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "chg" });
                }}
              >
                升跌
              </Table.HeaderCell>
              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
                sorted={column === "chgPct" ? direction : null}
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "chgPct" });
                }}
              >
                升跌(%)
              </Table.HeaderCell>
              {/* <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
                sorted={column === "point" ? direction : null}
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "point" });
                }}
              >
                貢獻點數
              </Table.HeaderCell> */}
              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
                sorted={column === "volume" ? direction : null}
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "volume" });
                }}
              >
                成交量
              </Table.HeaderCell>
              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
                sorted={column === "turnover" ? direction : null}
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "turnover" });
                }}
              >
                成交額
              </Table.HeaderCell>
              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
                sorted={column === "PE" ? direction : null}
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "PE" });
                }}
              >
                PE
              </Table.HeaderCell>
              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
                sorted={column === "PB" ? direction : null}
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "PB" });
                }}
              >
                PB
              </Table.HeaderCell>
              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
                sorted={column === "yield" ? direction : null}
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "yield" });
                }}
              >
                收益率
              </Table.HeaderCell>
              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
                sorted={column === "marketCap" ? direction : null}
                onClick={() => {
                  dispatch({ type: "CHANGE_SORT", column: "marketCap" });
                }}
              >
                市值
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row></Table.Row>
          </Table.Header>
          <Table.Body>
            {newData2.map((d, index) => {
              const isItemSelected = isSelected(d.id);
              return (
                <>
                  {index !== 0 && index % 15 === 0 && (
                    <Table.Row>
                      <Table.Cell colSpan={11} textAlign="center">
                        <InFeedAdsWhite></InFeedAdsWhite>
                      </Table.Cell>
                    </Table.Row>
                  )}
                  <Table.Row
                    key={d.id}
                    // onClick={(event) => handleClick(event, d.id)}
                  >
                    <Table.Cell style={firstColHeaderStyle} textAlign="left">
                      {/* <Icon
                        name={isItemSelected ? "caret down" : "caret right"}
                      /> */}
                      <>
                        {d.name_zh !== "" ? (
                          <Link to={"/stock/" + d.id}>
                            {d.name_zh.length > 8
                              ? d.name_zh.substring(0, 8) + "..."
                              : d.name_zh}
                            <br />
                            {/* {isMobile ? <br /> : null} */}({d.id})
                          </Link>
                        ) : (
                          <>({d.id})</>
                        )}
                      </>
                    </Table.Cell>
                    <Table.Cell style={contentStyle} textAlign="center">
                      ${d.close}{" "}
                    </Table.Cell>
                    <Table.Cell style={contentStyle} textAlign="center">
                      {d.chg > 0 ? (
                        <span style={{ color: "green" }}>{"+" + d.chg} </span>
                      ) : d.chg < 0 ? (
                        <span style={{ color: "red" }}>{d.chg}</span>
                      ) : (
                        d.chg
                      )}
                    </Table.Cell>
                    <Table.Cell style={contentStyle} textAlign="center">
                      {d.chgPct > 0 ? (
                        <span style={{ color: "green" }}>
                          {"+" + d.chgPct + "%"}{" "}
                        </span>
                      ) : d.chgPct < 0 ? (
                        <span style={{ color: "red" }}>{d.chgPct + "%"}</span>
                      ) : (
                        d.chgPct + "%"
                      )}
                    </Table.Cell>
                    {/* <Table.Cell style={contentStyle} textAlign="center">
                      {d.point > 0 ? (
                        <span style={{ color: "green" }}>{"+" + d.point} </span>
                      ) : d.chg < 0 ? (
                        <span style={{ color: "red" }}>{d.point}</span>
                      ) : (
                        d.chg
                      )}
                    </Table.Cell> */}
                    <Table.Cell style={contentStyle} textAlign="center">
                      {d.volume === "-" ? "-" : formatNumber(d.volume)}
                    </Table.Cell>
                    <Table.Cell style={contentStyle} textAlign="center">
                      {d.turnover === "-" ? "-" : formatNumber(d.turnover)}
                    </Table.Cell>
                    <Table.Cell style={contentStyle} textAlign="center">
                      {d.PE === -1 ? "-" : d.PE}{" "}
                    </Table.Cell>
                    <Table.Cell style={contentStyle} textAlign="center">
                      {d.PB === -1 ? "-" : d.PB}{" "}
                    </Table.Cell>
                    <Table.Cell style={contentStyle} textAlign="center">
                      {d.yield + "%"}{" "}
                    </Table.Cell>
                    <Table.Cell style={contentStyle} textAlign="center">
                      {d.marketCap === "-" ? "-" : formatNumber(d.marketCap)}
                    </Table.Cell>
                  </Table.Row>
                  {isItemSelected ? (
                    <Table.Row>
                      <Table.Cell
                        style={{
                          paddingBottom: "10px",
                          paddingTop: "10px",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                        }}
                        colSpan={10}
                      >
                        <div
                          style={{
                            position: "sticky",
                            left: "10px",
                            width: chartWidth,
                            zIndex: "9",
                          }}
                        >
                          {d.id}
                          {/* <InTableChartContainer id={d.id} /> */}
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                </>
              );
            })}
          </Table.Body>
        </Table>
      </div>
      {/* <div
        style={{
          paddingTop: "25px",
          paddingBottom: "25px",
          float: pos_pagination,
        }}
      >
        <Pagination
          boundaryRange={0}
          activePage={activePage}
          size={isMobile ? "small" : "big"}
          siblingRange={1}
          totalPages={totalPage}
          onPageChange={handlePaginationChange}
        />
      </div> */}
    </div>
  );
}

// export const InTableChartContainer = (props) => {
//   const stockId = props.id;
//   const dispatch = useDispatch();
//   const stocks = useSelector(selectAllStock);
//   const stockStatus = useSelector((state) => state.stock.status);
//   const error = useSelector((state) => state.stock.error);
//   const [data, setData] = useState(null);
//   const [content, setContent] = useState(null);
//   console.log("render");

//   useEffect(() => {
//     const stockData = stocks.find((item) => item.stockInfo.no === stockId);
//     console.log("stockData1");
//     console.log(stockData);
//     if (!stockData) {
//       if (stockStatus === "idle") {
//         if (true) {
//           dispatch(fetchStock(stockId));
//         }
//       } else if (stockStatus === "loading") {
//         console.log("stockStatus123");
//         setContent(
//           <div>
//             <section className="posts-list">
//               <div
//                 style={{
//                   padding: "100px",
//                   margin: "auto",
//                   alignItems: "center",
//                   height: "75vh",
//                   // overflow: "auto",
//                 }}
//               >
//                 <Loader active inline="centered">
//                   {" "}
//                   Loading
//                 </Loader>
//               </div>
//             </section>
//           </div>
//         );
//       } else if (stockStatus === "error") {
//         setContent(
//           <div>
//             <section className="posts-list">
//               <div> Stock Not Found</div>
//             </section>
//           </div>
//         );
//       }
//     } else if (!data) {
//       setData(stockData);
//       console.log("stockData2");
//       console.log(stockData);
//     }
//   }, [stockId, stocks, data, stockStatus, dispatch]);

//   useEffect(() => {
//     console.log("2nd effect");
//     console.log(data);

//     if (data) {
//       setContent(<StockChart2T chartData={data} />);
//     }

//     dispatch(toIdle({}));
//   }, [data, dispatch]);

//   return <>{content}</>;
// };

// export const InTableChartContainer = (props) => {
//   const stockId = props.id;
//   return (
//     <>
//       {" "}
//       <RSChart />
//     </>
//   );
// };
export default IndexTableTable;
