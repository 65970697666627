import React, { useEffect } from 'react';
import '../../App.css';
import StockInfoCard from '../views/StockInfoCard';
import StockChart2T from '../charts/AlibabaChart';
import { Container, Segment, Header, Popup, Icon, Loader } from 'semantic-ui-react'
import MetaTags from 'react-meta-tags';
import { useSelector, useDispatch } from 'react-redux'
import { selectAllStock, fetchStock, toIdle } from '../../features/stock/stockSlice'
import { useParams } from 'react-router';
import DisplayAdsBanner from '../ads/DisplayAdsBanner'
import ShareholdingByStock from '../views/shareholding/ShareholdingByStock';



export const StockViewContainer8T = ({ match }) => {
  // const dispatch = useDispatch()
  // const stocks = useSelector(selectAllStock)
  // const { stockId } = useParams()
  // const stockStatus = useSelector((state) => state.stock.status)
  // const error = useSelector((state) => state.stock.error)

  // useEffect(() => {
  //   if (stockStatus === 'idle') {
  //     if (true) {
  //       dispatch(fetchStock(stockId))
  //     }
  //   }
  // }, [stockId, stockStatus, dispatch])

  // let content

  // if (stockStatus === 'loading') {
  //   content =
  //     <div>
  //       <section className="posts-list">
  //         <div
  //           style={{
  //             padding: "100px",
  //             margin: "auto",
  //             alignItems: "center",
  //             height: "75vh",
  //             // overflow: "auto",
  //           }}
  //         >
  //           <Loader active inline='centered' > Loading</Loader>
  //         </div>
  //       </section>
  //     </div>
  // } else if (stockStatus === 'succeeded') {
  //   const data = stocks.find((item) => item.stockInfo.no === stockId)
  //   if (data) {
  //     content = <StockView result={data} />
  //   } 
  //   else {
  //     dispatch(toIdle({}))
  //   }
  // } else if (stockStatus === 'error') {
  //   content = 
  //     <div>
  //       <section className="posts-list">
  //         <div>{error}</div>
  //       </section>
  //     </div>
  // }
  // return (
  //   <>
  //     <MetaTags>
  //       <title>{stockId}</title>
  //       <meta name="description" content={stockId} />
  //       <meta property="og:title" content={stockId} />
  //       <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
  //     </MetaTags>
  //     {content}
  //   </>
  // )
  return(
    <div>
      <StockView result={alibabaData} />
    </div>
  )
}



function StockView(data) {
  let result = data.result
  console.log(result)
  return (
    <>
      <Segment style={{ padding: '2em 0em' }} vertical>
        <Container >
          <StockChart2T chartData={result} />
        </Container>
      </Segment>

    </>
  )
}

export default StockViewContainer8T; 

const alibabaData =
{
  "stockInfo": {"no": "9988", "name_en": "ALIBABA GROUP HOLDING LIMITED", "name_zh": "阿里巴巴-SW", "dStockId": "1000015694", "lastUpdate": "2021-10-05", "isHKConnect": 0},
  "mainChartData": [
      {
          "0": [
              1577923200000,
              211,
              208,
              208,
              210,
              9516678,
              210,
              0.712,
              18.171,
              0.839,
              0.674,
              0.083,
              0.092,
              0.153,
              0.008,
              0.029,
              0.042,
              77.862,
              0.03,
              0.114,
              0.314,
              0.147,
              0.044,
              0.009,
              0.043,
              0.019,
              0.004
          ]
      },
      {
          "1": [
              1578009600000,
              215,
              211.1999969482422,
              215,
              212,
              11307103,
              212,
              0.73,
              18.135,
              0.837,
              0.675,
              0.081,
              0.098,
              0.158,
              0.008,
              0.025,
              0.043,
              77.862,
              0.029,
              0.104,
              0.314,
              0.142,
              0.045,
              0.009,
              0.043,
              0.021,
              0.004
          ]
      },
      {
          "2": [
              1578268800000,
              211.8000030517578,
              209.1999969482422,
              211,
              211,
              11303411,
              211,
              0.738,
              18.101,
              0.859,
              0.695,
              0.081,
              0.096,
              0.16,
              0.007,
              0.025,
              0.044,
              77.862,
              0.029,
              0.098,
              0.315,
              0.144,
              0.045,
              0.009,
              0.044,
              0.02,
              0.004
          ]
      },
      {
          "3": [
              1578355200000,
              214,
              211.6000061035156,
              211.8000030517578,
              212.6000061035156,
              11676599,
              212.6000061035156,
              0.76,
              18.077,
              0.863,
              0.7,
              0.083,
              0.097,
              0.16,
              0.008,
              0.025,
              0.044,
              77.862,
              0.032,
              0.083,
              0.315,
              0.143,
              0.045,
              0.009,
              0.044,
              0.02,
              0.004
          ]
      },
      {
          "4": [
              1578441600000,
              211.6000061035156,
              207.8000030517578,
              210,
              210.8000030517578,
              15095573,
              210.8000030517578,
              0.772,
              18.044,
              0.882,
              0.697,
              0.08,
              0.098,
              0.161,
              0.008,
              0.025,
              0.044,
              77.862,
              0.034,
              0.087,
              0.315,
              0.142,
              0.044,
              0.009,
              0.044,
              0.021,
              0.004
          ]
      },
      {
          "5": [
              1578528000000,
              215.8000030517578,
              213.1999969482422,
              214,
              215.6000061035156,
              12261435,
              215.6000061035156,
              0.787,
              18.022,
              0.879,
              0.701,
              0.084,
              0.094,
              0.16,
              0.008,
              0.025,
              0.045,
              77.862,
              0.033,
              0.088,
              0.315,
              0.143,
              0.044,
              0.009,
              0.044,
              0.02,
              0.004
          ]
      },
      {
          "6": [
              1578614400000,
              219,
              216.3999938964844,
              216.6000061035156,
              218,
              11860220,
              218,
              0.787,
              18.006,
              0.873,
              0.692,
              0.084,
              0.094,
              0.163,
              0.008,
              0.028,
              0.045,
              77.862,
              0.039,
              0.097,
              0.314,
              0.144,
              0.044,
              0.009,
              0.044,
              0.021,
              0.004
          ]
      },
      {
          "7": [
              1578873600000,
              224.6000061035156,
              219.6000061035156,
              219.8000030517578,
              222.8000030517578,
              17692255,
              222.8000030517578,
              0.787,
              17.997,
              0.882,
              0.692,
              0.086,
              0.095,
              0.161,
              0.008,
              0.027,
              0.046,
              77.862,
              0.028,
              0.104,
              0.314,
              0.144,
              0.044,
              0.008,
              0.044,
              0.02,
              0.004
          ]
      },
      {
          "8": [
              1578960000000,
              227.3999938964844,
              222.8000030517578,
              225,
              223.6000061035156,
              16784465,
              223.6000061035156,
              0.787,
              17.966,
              0.887,
              0.692,
              0.084,
              0.097,
              0.162,
              0.008,
              0.028,
              0.046,
              77.863,
              0.028,
              0.108,
              0.314,
              0.15,
              0.044,
              0.008,
              0.044,
              0.019,
              0.004
          ]
      },
      {
          "9": [
              1579046400000,
              221.6000061035156,
              218.1999969482422,
              220.1999969482422,
              220.8000030517578,
              21735164,
              220.8000030517578,
              0.795,
              17.943,
              0.883,
              0.701,
              0.083,
              0.094,
              0.166,
              0.01,
              0.028,
              0.049,
              77.863,
              0.029,
              0.104,
              0.315,
              0.15,
              0.045,
              0.008,
              0.045,
              0.019,
              0.004
          ]
      },
      {
          "10": [
              1579132800000,
              220.6000061035156,
              218.6000061035156,
              219.3999938964844,
              219.3999938964844,
              12993720,
              219.3999938964844,
              0.806,
              17.915,
              0.891,
              0.699,
              0.09,
              0.096,
              0.168,
              0.01,
              0.028,
              0.05,
              77.863,
              0.03,
              0.099,
              0.315,
              0.149,
              0.045,
              0.008,
              0.045,
              0.019,
              0.005
          ]
      },
      {
          "11": [
              1579219200000,
              221.1999969482422,
              216.1999969482422,
              218.8000030517578,
              220.3999938964844,
              21245436,
              220.3999938964844,
              0.821,
              17.899,
              0.881,
              0.702,
              0.087,
              0.094,
              0.171,
              0.01,
              0.028,
              0.053,
              77.863,
              0.032,
              0.097,
              0.315,
              0.145,
              0.045,
              0.009,
              0.045,
              0.021,
              0.005
          ]
      },
      {
          "12": [
              1579478400000,
              222.8000030517578,
              220.1999969482422,
              221,
              220.1999969482422,
              11229916,
              220.1999969482422,
              0.936,
              17.815,
              0.909,
              0.597,
              0.082,
              0.093,
              0.175,
              0.01,
              0.028,
              0.054,
              77.863,
              0.032,
              0.138,
              0.316,
              0.146,
              0.045,
              0.009,
              0.045,
              0.022,
              0.005
          ]
      },
      {
          "13": [
              1579564800000,
              219,
              214.6000061035156,
              218,
              215.8000030517578,
              20241728,
              215.8000030517578,
              0.962,
              17.809,
              0.889,
              0.592,
              0.079,
              0.088,
              0.178,
              0.011,
              0.026,
              0.054,
              77.863,
              0.034,
              0.14,
              0.316,
              0.142,
              0.045,
              0.009,
              0.045,
              0.023,
              0.005
          ]
      },
      {
          "14": [
              1579651200000,
              219.8000030517578,
              212.3999938964844,
              215.8000030517578,
              218.3999938964844,
              14632484,
              218.3999938964844,
              0.969,
              17.793,
              0.886,
              0.598,
              0.087,
              0.09,
              0.181,
              0.012,
              0.026,
              0.057,
              77.862,
              0.032,
              0.137,
              0.316,
              0.143,
              0.045,
              0.008,
              0.046,
              0.023,
              0.005
          ]
      },
      {
          "15": [
              1579737600000,
              216.8000030517578,
              212.1999969482422,
              216,
              213.3999938964844,
              16013394,
              213.3999938964844,
              0.982,
              17.772,
              0.883,
              0.6,
              0.092,
              0.095,
              0.183,
              0.012,
              0.026,
              0.058,
              77.862,
              0.033,
              0.138,
              0.316,
              0.142,
              0.045,
              0.008,
              0.045,
              0.023,
              0.005
          ]
      },
      {
          "16": [
              1579824000000,
              215.3999938964844,
              211.8000030517578,
              213.3999938964844,
              214.6000061035156,
              7583855,
              214.6000061035156,
              0.982,
              17.772,
              0.883,
              0.6,
              0.092,
              0.095,
              0.183,
              0.012,
              0.026,
              0.058,
              77.862,
              0.033,
              0.138,
              0.316,
              0.142,
              0.045,
              0.008,
              0.045,
              0.023,
              0.005
          ]
      },
      {
          "17": [
              1580256000000,
              209.6000061035156,
              205.6000061035156,
              205.8000030517578,
              207.3999938964844,
              24946947,
              207.3999938964844,
              0.988,
              17.757,
              0.887,
              0.605,
              0.089,
              0.098,
              0.183,
              0.013,
              0.027,
              0.058,
              77.862,
              0.034,
              0.157,
              0.316,
              0.139,
              0.045,
              0.006,
              0.045,
              0.023,
              0.005
          ]
      },
      {
          "18": [
              1580342400000,
              207.1999969482422,
              199.8000030517578,
              207.1999969482422,
              201.8000030517578,
              26416987,
              201.8000030517578,
              1.002,
              17.736,
              0.894,
              0.601,
              0.096,
              0.104,
              0.186,
              0.013,
              0.027,
              0.058,
              77.862,
              0.034,
              0.145,
              0.316,
              0.138,
              0.045,
              0.006,
              0.044,
              0.024,
              0.005
          ]
      },
      {
          "19": [
              1580428800000,
              204.8000030517578,
              199.6000061035156,
              204.8000030517578,
              200.3999938964844,
              18257747,
              200.3999938964844,
              1.01,
              17.723,
              0.892,
              0.596,
              0.095,
              0.099,
              0.189,
              0.013,
              0.028,
              0.058,
              77.862,
              0.044,
              0.146,
              0.316,
              0.139,
              0.045,
              0.006,
              0.043,
              0.023,
              0.005
          ]
      },
      {
          "20": [
              1580688000000,
              206,
              200,
              201.1999969482422,
              205,
              23784275,
              205,
              1.002,
              17.727,
              0.893,
              0.589,
              0.097,
              0.1,
              0.191,
              0.012,
              0.029,
              0.061,
              77.862,
              0.045,
              0.12,
              0.317,
              0.135,
              0.045,
              0.006,
              0.042,
              0.021,
              0.006
          ]
      },
      {
          "21": [
              1580774400000,
              214.8000030517578,
              207.1999969482422,
              208.3999938964844,
              213.1999969482422,
              25035681,
              213.1999969482422,
              1.007,
              17.697,
              0.904,
              0.575,
              0.096,
              0.092,
              0.194,
              0.013,
              0.031,
              0.06,
              77.862,
              0.048,
              0.137,
              0.317,
              0.14,
              0.044,
              0.007,
              0.042,
              0.021,
              0.006
          ]
      },
      {
          "22": [
              1580860800000,
              218.6000061035156,
              214,
              215.6000061035156,
              215.6000061035156,
              23975014,
              215.6000061035156,
              1.015,
              17.684,
              0.922,
              0.577,
              0.101,
              0.093,
              0.19,
              0.014,
              0.027,
              0.061,
              77.862,
              0.045,
              0.158,
              0.317,
              0.132,
              0.045,
              0.007,
              0.042,
              0.022,
              0.006
          ]
      },
      {
          "23": [
              1580947200000,
              219.1999969482422,
              213.8000030517578,
              215.1999969482422,
              217.3999938964844,
              18777127,
              217.3999938964844,
              1.023,
              17.691,
              0.923,
              0.578,
              0.099,
              0.096,
              0.188,
              0.014,
              0.027,
              0.062,
              77.863,
              0.044,
              0.144,
              0.316,
              0.131,
              0.045,
              0.007,
              0.042,
              0.022,
              0.005
          ]
      },
      {
          "24": [
              1581033600000,
              216.1999969482422,
              214.1999969482422,
              215,
              215.3999938964844,
              14223318,
              215.3999938964844,
              1.03,
              17.683,
              0.929,
              0.578,
              0.107,
              0.093,
              0.186,
              0.014,
              0.027,
              0.059,
              77.863,
              0.046,
              0.142,
              0.316,
              0.13,
              0.045,
              0.007,
              0.042,
              0.022,
              0.005
          ]
      },
      {
          "25": [
              1581292800000,
              214.8000030517578,
              211.1999969482422,
              211.1999969482422,
              212.6000061035156,
              23432236,
              212.6000061035156,
              1.278,
              17.66,
              0.943,
              0.333,
              0.109,
              0.092,
              0.187,
              0.014,
              0.028,
              0.058,
              77.863,
              0.047,
              0.146,
              0.316,
              0.129,
              0.045,
              0.007,
              0.042,
              0.022,
              0.005
          ]
      },
      {
          "26": [
              1581379200000,
              213.6000061035156,
              207.1999969482422,
              211.8000030517578,
              212.1999969482422,
              29376694,
              212.1999969482422,
              1.275,
              17.649,
              0.945,
              0.334,
              0.114,
              0.097,
              0.189,
              0.014,
              0.029,
              0.059,
              77.863,
              0.042,
              0.143,
              0.316,
              0.128,
              0.045,
              0.007,
              0.042,
              0.022,
              0.005
          ]
      },
      {
          "27": [
              1581465600000,
              215.8000030517578,
              210.1999969482422,
              212,
              215,
              24402441,
              215,
              1.288,
              17.603,
              0.931,
              0.335,
              0.124,
              0.091,
              0.193,
              0.014,
              0.033,
              0.06,
              77.863,
              0.043,
              0.146,
              0.317,
              0.131,
              0.045,
              0.008,
              0.042,
              0.023,
              0.006
          ]
      },
      {
          "28": [
              1581552000000,
              218,
              215,
              217.3999938964844,
              217.1999969482422,
              20547490,
              217.1999969482422,
              1.292,
              17.535,
              0.931,
              0.338,
              0.143,
              0.095,
              0.199,
              0.014,
              0.035,
              0.064,
              77.863,
              0.046,
              0.124,
              0.317,
              0.129,
              0.045,
              0.008,
              0.042,
              0.024,
              0.006
          ]
      },
      {
          "29": [
              1581638400000,
              217,
              213.8000030517578,
              214.3999938964844,
              215,
              15926098,
              215,
              1.309,
              17.503,
              0.915,
              0.339,
              0.116,
              0.1,
              0.201,
              0.015,
              0.035,
              0.065,
              77.863,
              0.047,
              0.148,
              0.317,
              0.129,
              0.045,
              0.008,
              0.043,
              0.025,
              0.006
          ]
      },
      {
          "30": [
              1581897600000,
              217.8000030517578,
              213.3999938964844,
              214.3999938964844,
              217.3999938964844,
              15531105,
              217.3999938964844,
              1.318,
              17.478,
              0.92,
              0.34,
              0.124,
              0.097,
              0.201,
              0.015,
              0.037,
              0.065,
              77.863,
              0.044,
              0.158,
              0.317,
              0.128,
              0.045,
              0.008,
              0.043,
              0.025,
              0.006
          ]
      },
      {
          "31": [
              1581984000000,
              216,
              213.3999938964844,
              215.8000030517578,
              214,
              13177196,
              214,
              1.328,
              17.478,
              0.919,
              0.338,
              0.134,
              0.098,
              0.201,
              0.015,
              0.037,
              0.065,
              77.863,
              0.045,
              0.139,
              0.317,
              0.134,
              0.045,
              0.008,
              0.043,
              0.025,
              0.006
          ]
      },
      {
          "32": [
              1582070400000,
              216.8000030517578,
              214,
              214.6000061035156,
              214,
              10179246,
              214,
              1.332,
              17.464,
              0.937,
              0.338,
              0.144,
              0.081,
              0.201,
              0.015,
              0.038,
              0.064,
              77.863,
              0.048,
              0.151,
              0.317,
              0.137,
              0.045,
              0.008,
              0.043,
              0.025,
              0.006
          ]
      },
      {
          "33": [
              1582156800000,
              216.8000030517578,
              213.8000030517578,
              216.8000030517578,
              215.6000061035156,
              12728604,
              215.6000061035156,
              1.341,
              17.433,
              0.925,
              0.338,
              0.144,
              0.087,
              0.204,
              0.015,
              0.037,
              0.065,
              77.863,
              0.05,
              0.143,
              0.317,
              0.135,
              0.045,
              0.008,
              0.043,
              0.026,
              0.006
          ]
      },
      {
          "34": [
              1582243200000,
              214,
              212,
              213,
              212.6000061035156,
              15106823,
              212.6000061035156,
              1.342,
              17.423,
              0.902,
              0.34,
              0.139,
              0.078,
              0.203,
              0.015,
              0.038,
              0.065,
              77.863,
              0.05,
              0.19,
              0.317,
              0.135,
              0.046,
              0.008,
              0.043,
              0.026,
              0.006
          ]
      },
      {
          "35": [
              1582502400000,
              207.6000061035156,
              205,
              206.3999938964844,
              205.1999969482422,
              30852998,
              205.1999969482422,
              1.342,
              17.406,
              0.904,
              0.339,
              0.14,
              0.082,
              0.204,
              0.016,
              0.038,
              0.066,
              77.863,
              0.054,
              0.183,
              0.317,
              0.136,
              0.046,
              0.008,
              0.043,
              0.026,
              0.006
          ]
      },
      {
          "36": [
              1582588800000,
              207.3999938964844,
              203,
              203.3999938964844,
              206.6000061035156,
              23465654,
              206.6000061035156,
              1.333,
              17.41,
              0.905,
              0.341,
              0.139,
              0.083,
              0.206,
              0.016,
              0.038,
              0.067,
              77.863,
              0.047,
              0.164,
              0.318,
              0.138,
              0.046,
              0.008,
              0.042,
              0.026,
              0.006
          ]
      },
      {
          "37": [
              1582675200000,
              204.3999938964844,
              201,
              201.8000030517578,
              203,
              29014994,
              203,
              1.344,
              17.312,
              0.962,
              0.345,
              0.159,
              0.082,
              0.215,
              0.016,
              0.037,
              0.07,
              77.863,
              0.046,
              0.16,
              0.319,
              0.135,
              0.046,
              0.009,
              0.042,
              0.026,
              0.006
          ]
      },
      {
          "38": [
              1582761600000,
              205.6000061035156,
              201.3999938964844,
              203,
              205.1999969482422,
              23397941,
              205.1999969482422,
              1.346,
              17.243,
              0.979,
              0.347,
              0.142,
              0.079,
              0.22,
              0.016,
              0.039,
              0.073,
              77.863,
              0.058,
              0.187,
              0.319,
              0.138,
              0.046,
              0.009,
              0.042,
              0.027,
              0.006
          ]
      },
      {
          "39": [
              1582848000000,
              202,
              199.5,
              200.6000061035156,
              200,
              38816951,
              200,
              1.363,
              17.208,
              0.947,
              0.351,
              0.135,
              0.084,
              0.229,
              0.018,
              0.039,
              0.077,
              77.863,
              0.059,
              0.186,
              0.32,
              0.141,
              0.046,
              0.009,
              0.042,
              0.027,
              0.006
          ]
      },
      {
          "40": [
              1583107200000,
              205,
              200.6000061035156,
              201.1999969482422,
              204.6000061035156,
              17004513,
              204.6000061035156,
              1.365,
              17.113,
              0.979,
              0.355,
              0.158,
              0.083,
              0.235,
              0.019,
              0.039,
              0.081,
              77.863,
              0.06,
              0.199,
              0.321,
              0.135,
              0.046,
              0.009,
              0.042,
              0.027,
              0.006
          ]
      },
      {
          "41": [
              1583193600000,
              206.6000061035156,
              202.3999938964844,
              206.1999969482422,
              203.1999969482422,
              19156355,
              203.1999969482422,
              1.394,
              17.067,
              0.931,
              0.365,
              0.153,
              0.082,
              0.25,
              0.019,
              0.036,
              0.088,
              77.863,
              0.056,
              0.188,
              0.322,
              0.137,
              0.046,
              0.01,
              0.042,
              0.027,
              0.007
          ]
      },
      {
          "42": [
              1583280000000,
              203.1999969482422,
              200.6000061035156,
              203,
              202.1999969482422,
              14553445,
              202.1999969482422,
              1.41,
              17.052,
              0.925,
              0.361,
              0.149,
              0.087,
              0.25,
              0.018,
              0.036,
              0.09,
              77.862,
              0.046,
              0.192,
              0.322,
              0.152,
              0.046,
              0.01,
              0.043,
              0.029,
              0.007
          ]
      },
      {
          "43": [
              1583366400000,
              208.1999969482422,
              204,
              205.1999969482422,
              207.1999969482422,
              19343293,
              207.1999969482422,
              1.417,
              17.028,
              0.917,
              0.362,
              0.141,
              0.082,
              0.251,
              0.018,
              0.037,
              0.091,
              77.862,
              0.047,
              0.229,
              0.322,
              0.143,
              0.047,
              0.011,
              0.044,
              0.028,
              0.007
          ]
      },
      {
          "44": [
              1583452800000,
              205.1999969482422,
              202.8000030517578,
              205,
              203.8000030517578,
              12734463,
              203.8000030517578,
              1.431,
              17.007,
              0.907,
              0.363,
              0.157,
              0.085,
              0.252,
              0.019,
              0.037,
              0.09,
              77.862,
              0.048,
              0.239,
              0.322,
              0.143,
              0.047,
              0.011,
              0.044,
              0.027,
              0.007
          ]
      },
      {
          "45": [
              1583712000000,
              198.5,
              193.5,
              193.5,
              193.5,
              56936906,
              193.5,
              1.453,
              17.001,
              0.916,
              0.363,
              0.154,
              0.09,
              0.25,
              0.019,
              0.036,
              0.088,
              77.862,
              0.052,
              0.219,
              0.322,
              0.149,
              0.047,
              0.011,
              0.044,
              0.028,
              0.007
          ]
      },
      {
          "46": [
              1583798400000,
              201,
              193.6000061035156,
              197,
              199.3000030517578,
              28569375,
              199.3000030517578,
              1.463,
              16.941,
              0.971,
              0.363,
              0.151,
              0.092,
              0.251,
              0.019,
              0.037,
              0.089,
              77.862,
              0.057,
              0.229,
              0.322,
              0.144,
              0.047,
              0.011,
              0.044,
              0.026,
              0.007
          ]
      },
      {
          "47": [
              1583884800000,
              200.3999938964844,
              196.6999969482422,
              200,
              197,
              17860192,
              197,
              1.48,
              16.907,
              0.905,
              0.371,
              0.155,
              0.086,
              0.269,
              0.019,
              0.033,
              0.11,
              77.862,
              0.057,
              0.203,
              0.324,
              0.147,
              0.047,
              0.012,
              0.042,
              0.025,
              0.006
          ]
      },
      {
          "48": [
              1583971200000,
              192.1999969482422,
              187.1999969482422,
              192,
              188.8000030517578,
              41842108,
              188.8000030517578,
              1.482,
              16.872,
              0.908,
              0.374,
              0.154,
              0.087,
              0.267,
              0.019,
              0.034,
              0.129,
              77.862,
              0.06,
              0.226,
              0.324,
              0.138,
              0.047,
              0.012,
              0.043,
              0.026,
              0.006
          ]
      },
      {
          "49": [
              1584057600000,
              194,
              177,
              178.8000030517578,
              191.6999969482422,
              53292364,
              191.6999969482422,
              1.485,
              16.833,
              0.943,
              0.375,
              0.153,
              0.082,
              0.268,
              0.019,
              0.034,
              0.129,
              77.862,
              0.056,
              0.236,
              0.324,
              0.137,
              0.047,
              0.012,
              0.043,
              0.025,
              0.006
          ]
      },
      {
          "50": [
              1584316800000,
              185.1000061035156,
              178,
              185,
              179.3000030517578,
              47207430,
              179.3000030517578,
              1.497,
              16.78,
              0.945,
              0.38,
              0.125,
              0.09,
              0.28,
              0.019,
              0.033,
              0.135,
              77.862,
              0.057,
              0.233,
              0.325,
              0.148,
              0.047,
              0.014,
              0.041,
              0.025,
              0.006
          ]
      },
      {
          "51": [
              1584403200000,
              185.8999938964844,
              175,
              182,
              183.1999969482422,
              36872240,
              183.1999969482422,
              1.501,
              16.723,
              0.941,
              0.39,
              0.122,
              0.094,
              0.285,
              0.019,
              0.032,
              0.135,
              77.862,
              0.062,
              0.238,
              0.325,
              0.161,
              0.047,
              0.013,
              0.041,
              0.027,
              0.006
          ]
      },
      {
          "52": [
              1584489600000,
              181.8000030517578,
              172.8000030517578,
              177.8000030517578,
              173,
              58603973,
              173,
              1.516,
              16.67,
              0.907,
              0.395,
              0.123,
              0.1,
              0.299,
              0.019,
              0.031,
              0.151,
              77.862,
              0.049,
              0.22,
              0.327,
              0.157,
              0.047,
              0.014,
              0.04,
              0.026,
              0.006
          ]
      },
      {
          "53": [
              1584576000000,
              176.5,
              167.60000610351562,
              176.10000610351562,
              174,
              34599488,
              174,
              1.546,
              16.477,
              0.937,
              0.391,
              0.187,
              0.112,
              0.301,
              0.019,
              0.034,
              0.151,
              77.862,
              0.047,
              0.293,
              0.327,
              0.154,
              0.047,
              0.014,
              0.039,
              0.028,
              0.006
          ]
      },
      {
          "54": [
              1584662400000,
              183,
              177,
              177,
              183,
              27899247,
              183,
              1.565,
              16.445,
              0.926,
              0.403,
              0.169,
              0.096,
              0.316,
              0.019,
              0.033,
              0.174,
              77.862,
              0.065,
              0.241,
              0.328,
              0.151,
              0.047,
              0.015,
              0.039,
              0.029,
              0.007
          ]
      },
      {
          "55": [
              1584921600000,
              176.89999389648438,
              170,
              172.5,
              170,
              37535058,
              170,
              1.598,
              16.419,
              0.927,
              0.402,
              0.171,
              0.09,
              0.317,
              0.018,
              0.034,
              0.177,
              77.862,
              0.072,
              0.236,
              0.328,
              0.153,
              0.047,
              0.012,
              0.039,
              0.028,
              0.013
          ]
      },
      {
          "56": [
              1585008000000,
              175,
              170.1999969482422,
              173.6999969482422,
              174.5,
              26729551,
              174.5,
              1.597,
              16.351,
              0.96,
              0.408,
              0.18,
              0.136,
              0.313,
              0.018,
              0.032,
              0.185,
              77.862,
              0.072,
              0.216,
              0.327,
              0.156,
              0.047,
              0.012,
              0.039,
              0.028,
              0.007
          ]
      },
      {
          "57": [
              1585094400000,
              181.6999969482422,
              177.8000030517578,
              181,
              179.6999969482422,
              28012053,
              179.6999969482422,
              1.595,
              16.285,
              0.956,
              0.41,
              0.181,
              0.102,
              0.322,
              0.017,
              0.032,
              0.197,
              77.862,
              0.09,
              0.24,
              0.328,
              0.155,
              0.047,
              0.013,
              0.039,
              0.027,
              0.007
          ]
      },
      {
          "58": [
              1585180800000,
              182.3999938964844,
              178.3000030517578,
              181,
              180.8999938964844,
              17816738,
              180.8999938964844,
              1.597,
              16.262,
              0.946,
              0.413,
              0.175,
              0.096,
              0.331,
              0.018,
              0.033,
              0.198,
              77.862,
              0.084,
              0.273,
              0.327,
              0.167,
              0.047,
              0.013,
              0.04,
              0.021,
              0.008
          ]
      },
      {
          "59": [
              1585267200000,
              186,
              182.8999938964844,
              186,
              183.8999938964844,
              23922455,
              183.8999938964844,
              1.595,
              16.266,
              0.947,
              0.418,
              0.187,
              0.098,
              0.328,
              0.018,
              0.033,
              0.183,
              77.862,
              0.06,
              0.303,
              0.327,
              0.171,
              0.047,
              0.012,
              0.04,
              0.021,
              0.008
          ]
      },
      {
          "60": [
              1585526400000,
              182.1000061035156,
              177.5,
              180.1999969482422,
              180.3000030517578,
              18129547,
              180.3000030517578,
              1.609,
              16.252,
              0.949,
              0.413,
              0.183,
              0.133,
              0.326,
              0.018,
              0.034,
              0.182,
              77.862,
              0.064,
              0.286,
              0.327,
              0.172,
              0.047,
              0.012,
              0.041,
              0.021,
              0.008
          ]
      },
      {
          "61": [
              1585612800000,
              185.6999969482422,
              182.6999969482422,
              185.1999969482422,
              183.3999938964844,
              13598127,
              183.3999938964844,
              1.634,
              16.247,
              0.94,
              0.413,
              0.187,
              0.125,
              0.323,
              0.018,
              0.034,
              0.189,
              77.862,
              0.063,
              0.268,
              0.327,
              0.178,
              0.047,
              0.012,
              0.041,
              0.021,
              0.008
          ]
      },
      {
          "62": [
              1585699200000,
              187.3999938964844,
              181.1000061035156,
              185.3999938964844,
              184,
              22814764,
              184,
              1.635,
              16.276,
              0.925,
              0.417,
              0.165,
              0.149,
              0.323,
              0.018,
              0.039,
              0.196,
              77.863,
              0.067,
              0.229,
              0.327,
              0.176,
              0.047,
              0.012,
              0.041,
              0.02,
              0.008
          ]
      },
      {
          "63": [
              1585785600000,
              186.5,
              182.1999969482422,
              183.1999969482422,
              186,
              12304930,
              186,
              1.641,
              16.283,
              0.928,
              0.417,
              0.16,
              0.13,
              0.322,
              0.018,
              0.039,
              0.195,
              77.863,
              0.065,
              0.258,
              0.327,
              0.179,
              0.047,
              0.012,
              0.041,
              0.019,
              0.008
          ]
      },
      {
          "64": [
              1585872000000,
              184.8999938964844,
              182.6000061035156,
              183.5,
              183.6999969482422,
              9290512,
              183.6999969482422,
              1.645,
              16.303,
              0.922,
              0.42,
              0.146,
              0.154,
              0.32,
              0.018,
              0.038,
              0.19,
              77.863,
              0.066,
              0.232,
              0.327,
              0.178,
              0.047,
              0.011,
              0.041,
              0.019,
              0.008
          ]
      },
      {
          "65": [
              1586131200000,
              189.6999969482422,
              185.1999969482422,
              186,
              188.6000061035156,
              18253609,
              188.6000061035156,
              1.644,
              16.29,
              0.93,
              0.414,
              0.148,
              0.134,
              0.319,
              0.018,
              0.039,
              0.194,
              77.859,
              0.067,
              0.255,
              0.327,
              0.178,
              0.047,
              0.011,
              0.041,
              0.019,
              0.008
          ]
      },
      {
          "66": [
              1586217600000,
              191.6999969482422,
              188.5,
              191.5,
              191.5,
              16381930,
              191.5,
              1.656,
              16.289,
              0.926,
              0.416,
              0.141,
              0.13,
              0.319,
              0.017,
              0.039,
              0.194,
              77.859,
              0.067,
              0.264,
              0.327,
              0.179,
              0.047,
              0.012,
              0.041,
              0.018,
              0.008
          ]
      },
      {
          "67": [
              1586304000000,
              191.5,
              189,
              189.3999938964844,
              190.1000061035156,
              9180063,
              190.1000061035156,
              1.672,
              16.284,
              0.925,
              0.415,
              0.137,
              0.133,
              0.317,
              0.018,
              0.042,
              0.194,
              77.859,
              0.066,
              0.254,
              0.327,
              0.182,
              0.047,
              0.011,
              0.041,
              0.02,
              0.01
          ]
      },
      {
          "68": [
              1586390400000,
              191.8999938964844,
              189.3000030517578,
              191.3000030517578,
              191.6999969482422,
              13161858,
              191.6999969482422,
              1.69,
              16.281,
              0.932,
              0.415,
              0.147,
              0.132,
              0.316,
              0.018,
              0.042,
              0.193,
              77.859,
              0.065,
              0.241,
              0.327,
              0.184,
              0.047,
              0.011,
              0.042,
              0.021,
              0.009
          ]
      },
      {
          "69": [
              1586822400000,
              198,
              194,
              194,
              197,
              18233007,
              197,
              1.671,
              16.255,
              0.959,
              0.424,
              0.157,
              0.133,
              0.316,
              0.018,
              0.04,
              0.193,
              77.859,
              0.062,
              0.223,
              0.326,
              0.193,
              0.047,
              0.011,
              0.042,
              0.022,
              0.009
          ]
      },
      {
          "70": [
              1586908800000,
              201.1999969482422,
              196.8000030517578,
              199,
              197.8999938964844,
              20722474,
              197.8999938964844,
              1.684,
              16.259,
              0.951,
              0.424,
              0.162,
              0.135,
              0.316,
              0.018,
              0.04,
              0.194,
              77.859,
              0.063,
              0.219,
              0.326,
              0.193,
              0.047,
              0.011,
              0.042,
              0.022,
              0.009
          ]
      },
      {
          "71": [
              1586995200000,
              202.8000030517578,
              199.8999938964844,
              200,
              202.8000030517578,
              19818106,
              202.8000030517578,
              1.693,
              16.278,
              0.969,
              0.426,
              0.163,
              0.13,
              0.312,
              0.018,
              0.041,
              0.186,
              77.859,
              0.066,
              0.21,
              0.326,
              0.188,
              0.047,
              0.011,
              0.043,
              0.022,
              0.009
          ]
      },
      {
          "72": [
              1587081600000,
              211.8000030517578,
              206.1999969482422,
              210,
              207.6000061035156,
              33397811,
              207.6000061035156,
              1.699,
              16.257,
              0.971,
              0.422,
              0.169,
              0.128,
              0.309,
              0.018,
              0.04,
              0.182,
              77.859,
              0.068,
              0.225,
              0.325,
              0.192,
              0.047,
              0.011,
              0.043,
              0.022,
              0.009
          ]
      },
      {
          "73": [
              1587340800000,
              207.6000061035156,
              203.3999938964844,
              205.3999938964844,
              205.8000030517578,
              22875450,
              205.8000030517578,
              1.688,
              16.251,
              0.978,
              0.425,
              0.156,
              0.132,
              0.304,
              0.018,
              0.045,
              0.179,
              77.859,
              0.069,
              0.263,
              0.325,
              0.189,
              0.047,
              0.011,
              0.044,
              0.02,
              0.01
          ]
      },
      {
          "74": [
              1587427200000,
              207.8000030517578,
              200.6000061035156,
              207,
              204.1999969482422,
              17778769,
              204.1999969482422,
              1.686,
              16.254,
              0.983,
              0.419,
              0.166,
              0.133,
              0.295,
              0.019,
              0.053,
              0.175,
              77.859,
              0.076,
              0.27,
              0.324,
              0.186,
              0.045,
              0.01,
              0.045,
              0.019,
              0.009
          ]
      },
      {
          "75": [
              1587513600000,
              206.6000061035156,
              200.6000061035156,
              201,
              205.6000061035156,
              12962733,
              205.6000061035156,
              1.681,
              16.248,
              0.973,
              0.423,
              0.157,
              0.129,
              0.295,
              0.019,
              0.052,
              0.176,
              77.859,
              0.082,
              0.298,
              0.324,
              0.185,
              0.045,
              0.01,
              0.045,
              0.02,
              0.009
          ]
      },
      {
          "76": [
              1587600000000,
              205.1999969482422,
              202.3999938964844,
              204.3999938964844,
              204.3999938964844,
              16359183,
              204.3999938964844,
              1.673,
              16.271,
              0.969,
              0.413,
              0.175,
              0.126,
              0.294,
              0.019,
              0.052,
              0.175,
              77.859,
              0.082,
              0.274,
              0.324,
              0.192,
              0.045,
              0.01,
              0.045,
              0.019,
              0.01
          ]
      },
      {
          "77": [
              1587686400000,
              200.6000061035156,
              197.3000030517578,
              198.6000061035156,
              198,
              23966198,
              198,
              1.673,
              16.269,
              0.973,
              0.413,
              0.172,
              0.126,
              0.294,
              0.019,
              0.052,
              0.17,
              77.859,
              0.08,
              0.273,
              0.323,
              0.197,
              0.045,
              0.01,
              0.045,
              0.025,
              0.01
          ]
      },
      {
          "78": [
              1587945600000,
              202.3999938964844,
              198.5,
              198.5,
              200.6000061035156,
              13936896,
              200.6000061035156,
              1.68,
              16.269,
              0.978,
              0.413,
              0.173,
              0.126,
              0.294,
              0.019,
              0.051,
              0.17,
              77.859,
              0.073,
              0.276,
              0.324,
              0.193,
              0.045,
              0.01,
              0.045,
              0.025,
              0.01
          ]
      },
      {
          "79": [
              1588032000000,
              199.1999969482422,
              196.3000030517578,
              198,
              199,
              19944823,
              199,
              1.69,
              16.245,
              0.978,
              0.419,
              0.167,
              0.132,
              0.299,
              0.019,
              0.052,
              0.172,
              77.859,
              0.057,
              0.274,
              0.324,
              0.188,
              0.045,
              0.01,
              0.043,
              0.025,
              0.01
          ]
      },
      {
          "80": [
              1588118400000,
              197.8999938964844,
              196.5,
              196.5,
              197,
              14850119,
              197,
              1.691,
              16.217,
              0.992,
              0.423,
              0.167,
              0.134,
              0.301,
              0.02,
              0.055,
              0.173,
              77.859,
              0.058,
              0.283,
              0.324,
              0.184,
              0.045,
              0.01,
              0.043,
              0.025,
              0.011
          ]
      },
      {
          "81": [
              1588550400000,
              189.8999938964844,
              188.1999969482422,
              189.8000030517578,
              189.6999969482422,
              26903568,
              189.6999969482422,
              1.707,
              16.194,
              0.989,
              0.425,
              0.164,
              0.137,
              0.303,
              0.021,
              0.053,
              0.174,
              77.859,
              0.07,
              0.247,
              0.325,
              0.187,
              0.045,
              0.01,
              0.043,
              0.026,
              0.011
          ]
      },
      {
          "82": [
              1588636800000,
              189.1999969482422,
              186.8999938964844,
              187.6999969482422,
              188.5,
              27110375,
              188.5,
              1.716,
              16.146,
              0.997,
              0.424,
              0.172,
              0.133,
              0.306,
              0.022,
              0.054,
              0.175,
              77.859,
              0.068,
              0.263,
              0.325,
              0.185,
              0.045,
              0.01,
              0.043,
              0.025,
              0.011
          ]
      },
      {
          "83": [
              1588723200000,
              195.6000061035156,
              189.5,
              189.8999938964844,
              193.6000061035156,
              23022901,
              193.6000061035156,
              1.756,
              15.748,
              1.356,
              0.426,
              0.166,
              0.129,
              0.313,
              0.021,
              0.056,
              0.183,
              77.859,
              0.069,
              0.231,
              0.325,
              0.199,
              0.045,
              0.011,
              0.043,
              0.024,
              0.011
          ]
      },
      {
          "84": [
              1588809600000,
              190.8999938964844,
              189.5,
              189.8999938964844,
              190.5,
              20506910,
              190.5,
              1.816,
              15.634,
              1.363,
              0.428,
              0.192,
              0.127,
              0.318,
              0.022,
              0.057,
              0.185,
              77.859,
              0.074,
              0.215,
              0.326,
              0.195,
              0.045,
              0.011,
              0.043,
              0.024,
              0.012
          ]
      },
      {
          "85": [
              1588896000000,
              194.8000030517578,
              191.6000061035156,
              192.5,
              193.3999938964844,
              17851592,
              193.3999938964844,
              1.825,
              15.591,
              1.377,
              0.429,
              0.188,
              0.125,
              0.32,
              0.023,
              0.064,
              0.185,
              77.859,
              0.074,
              0.244,
              0.326,
              0.184,
              0.045,
              0.011,
              0.043,
              0.024,
              0.012
          ]
      },
      {
          "86": [
              1589155200000,
              199.5,
              196.5,
              196.8999938964844,
              198.6999969482422,
              19839300,
              198.6999969482422,
              1.832,
              15.549,
              1.351,
              0.431,
              0.202,
              0.126,
              0.322,
              0.023,
              0.071,
              0.186,
              77.859,
              0.086,
              0.256,
              0.326,
              0.181,
              0.045,
              0.011,
              0.043,
              0.024,
              0.012
          ]
      },
      {
          "87": [
              1589241600000,
              199.6999969482422,
              197.1999969482422,
              198.8999938964844,
              199.6999969482422,
              14037259,
              199.6999969482422,
              1.838,
              15.528,
              1.378,
              0.439,
              0.197,
              0.139,
              0.322,
              0.023,
              0.071,
              0.186,
              77.859,
              0.082,
              0.237,
              0.327,
              0.185,
              0.045,
              0.011,
              0.043,
              0.023,
              0.01
          ]
      },
      {
          "88": [
              1589328000000,
              197.3999938964844,
              194.3000030517578,
              195.5,
              196.3000030517578,
              26074457,
              196.3000030517578,
              1.862,
              15.474,
              1.38,
              0.439,
              0.199,
              0.143,
              0.321,
              0.023,
              0.072,
              0.186,
              77.859,
              0.08,
              0.251,
              0.326,
              0.195,
              0.045,
              0.011,
              0.043,
              0.024,
              0.01
          ]
      },
      {
          "89": [
              1589414400000,
              195.8999938964844,
              194.1000061035156,
              194.5,
              194.5,
              19248894,
              194.5,
              1.872,
              15.384,
              1.456,
              0.441,
              0.199,
              0.138,
              0.322,
              0.024,
              0.069,
              0.186,
              77.859,
              0.082,
              0.244,
              0.326,
              0.194,
              0.045,
              0.011,
              0.043,
              0.024,
              0.012
          ]
      },
      {
          "90": [
              1589500800000,
              197.1000061035156,
              194.1000061035156,
              195,
              196.8999938964844,
              16672799,
              196.8999938964844,
              1.882,
              15.322,
              1.505,
              0.447,
              0.185,
              0.148,
              0.326,
              0.023,
              0.07,
              0.188,
              77.859,
              0.083,
              0.215,
              0.326,
              0.201,
              0.045,
              0.012,
              0.044,
              0.026,
              0.012
          ]
      },
      {
          "91": [
              1589760000000,
              203,
              197.3999938964844,
              198.3000030517578,
              203,
              26818890,
              203,
              1.9,
              15.301,
              1.466,
              0.449,
              0.186,
              0.146,
              0.328,
              0.024,
              0.072,
              0.191,
              77.859,
              0.087,
              0.251,
              0.326,
              0.201,
              0.045,
              0.013,
              0.044,
              0.023,
              0.016
          ]
      },
      {
          "92": [
              1589846400000,
              211.8000030517578,
              205.6000061035156,
              210,
              209.8000030517578,
              45308325,
              209.8000030517578,
              1.928,
              15.26,
              1.479,
              0.452,
              0.181,
              0.146,
              0.327,
              0.023,
              0.071,
              0.191,
              77.859,
              0.087,
              0.257,
              0.327,
              0.195,
              0.045,
              0.013,
              0.044,
              0.022,
              0.016
          ]
      },
      {
          "93": [
              1589932800000,
              211.6000061035156,
              208.6000061035156,
              211,
              211,
              22533385,
              211,
              1.947,
              15.193,
              1.478,
              0.455,
              0.22,
              0.142,
              0.325,
              0.024,
              0.072,
              0.166,
              77.859,
              0.084,
              0.285,
              0.327,
              0.203,
              0.045,
              0.013,
              0.044,
              0.033,
              0.016
          ]
      },
      {
          "94": [
              1590019200000,
              209.6000061035156,
              205.6000061035156,
              209.3999938964844,
              206.1999969482422,
              23271613,
              206.1999969482422,
              1.965,
              15.159,
              1.51,
              0.458,
              0.216,
              0.161,
              0.317,
              0.024,
              0.072,
              0.144,
              77.859,
              0.087,
              0.289,
              0.326,
              0.202,
              0.034,
              0.013,
              0.044,
              0.031,
              0.016
          ]
      },
      {
          "95": [
              1590105600000,
              205,
              196,
              205,
              198.1999969482422,
              40226609,
              198.1999969482422,
              2.007,
              15.078,
              1.518,
              0.462,
              0.23,
              0.157,
              0.312,
              0.024,
              0.072,
              0.133,
              77.859,
              0.09,
              0.309,
              0.325,
              0.228,
              0.034,
              0.013,
              0.044,
              0.03,
              0.016
          ]
      },
      {
          "96": [
              1590364800000,
              199.6000061035156,
              190.3000030517578,
              193.6999969482422,
              198.1999969482422,
              40103124,
              198.1999969482422,
              2.084,
              15.009,
              1.532,
              0.467,
              0.202,
              0.16,
              0.311,
              0.025,
              0.073,
              0.125,
              77.859,
              0.088,
              0.32,
              0.325,
              0.227,
              0.034,
              0.013,
              0.044,
              0.029,
              0.016
          ]
      },
      {
          "97": [
              1590451200000,
              203.1999969482422,
              200,
              200,
              201,
              26834881,
              201,
              2.093,
              15.023,
              1.592,
              0.474,
              0.184,
              0.164,
              0.302,
              0.025,
              0.073,
              0.118,
              77.785,
              0.094,
              0.34,
              0.325,
              0.24,
              0.034,
              0.013,
              0.044,
              0.028,
              0.016
          ]
      },
      {
          "98": [
              1590537600000,
              199.6000061035156,
              196.1000061035156,
              198.8999938964844,
              197.3000030517578,
              33136407,
              197.3000030517578,
              2.136,
              14.992,
              1.571,
              0.484,
              0.192,
              0.163,
              0.304,
              0.025,
              0.064,
              0.12,
              77.785,
              0.079,
              0.354,
              0.325,
              0.236,
              0.034,
              0.013,
              0.044,
              0.029,
              0.011
          ]
      },
      {
          "99": [
              1590624000000,
              196.5,
              191.5,
              195.3999938964844,
              193.5,
              30919109,
              193.5,
              2.322,
              14.736,
              1.599,
              0.496,
              0.205,
              0.168,
              0.302,
              0.025,
              0.066,
              0.12,
              77.785,
              0.077,
              0.384,
              0.325,
              0.243,
              0.034,
              0.013,
              0.044,
              0.03,
              0.011
          ]
      },
      {
          "100": [
              1590710400000,
              196.3000030517578,
              192,
              193,
              194.3000030517578,
              19459191,
              194.3000030517578,
              2.354,
              14.71,
              1.598,
              0.506,
              0.211,
              0.17,
              0.302,
              0.025,
              0.065,
              0.119,
              77.785,
              0.073,
              0.36,
              0.326,
              0.239,
              0.034,
              0.013,
              0.044,
              0.029,
              0.011
          ]
      },
      {
          "101": [
              1590969600000,
              204,
              200,
              200,
              202.8000030517578,
              26826195,
              202.8000030517578,
              2.362,
              14.521,
              1.762,
              0.508,
              0.223,
              0.176,
              0.304,
              0.026,
              0.067,
              0.122,
              77.785,
              0.072,
              0.344,
              0.326,
              0.238,
              0.034,
              0.013,
              0.044,
              0.03,
              0.011
          ]
      },
      {
          "102": [
              1591056000000,
              201.8000030517578,
              199.5,
              201.6000061035156,
              200,
              22818103,
              200,
              2.465,
              14.396,
              1.808,
              0.512,
              0.215,
              0.174,
              0.303,
              0.027,
              0.066,
              0.122,
              77.771,
              0.074,
              0.344,
              0.326,
              0.239,
              0.034,
              0.013,
              0.044,
              0.026,
              0.011
          ]
      },
      {
          "103": [
              1591142400000,
              209.8000030517578,
              206.8000030517578,
              208,
              209.6000061035156,
              38794267,
              209.6000061035156,
              2.484,
              14.243,
              2.093,
              0.517,
              0.193,
              0.186,
              0.3,
              0.026,
              0.066,
              0.119,
              77.589,
              0.078,
              0.372,
              0.325,
              0.262,
              0.034,
              0.013,
              0.044,
              0.029,
              0.012
          ]
      },
      {
          "104": [
              1591228800000,
              213,
              207.3999938964844,
              213,
              211,
              22860283,
              211,
              2.527,
              14.217,
              2.077,
              0.517,
              0.186,
              0.186,
              0.301,
              0.026,
              0.061,
              0.12,
              77.589,
              0.084,
              0.388,
              0.325,
              0.238,
              0.034,
              0.013,
              0.044,
              0.03,
              0.012
          ]
      },
      {
          "105": [
              1591315200000,
              213.6000061035156,
              209.3999938964844,
              212,
              212.1999969482422,
              22415326,
              212.1999969482422,
              2.575,
              14.135,
              2.076,
              0.552,
              0.198,
              0.194,
              0.291,
              0.026,
              0.06,
              0.113,
              77.589,
              0.092,
              0.404,
              0.324,
              0.243,
              0.034,
              0.013,
              0.044,
              0.033,
              0.012
          ]
      },
      {
          "106": [
              1591574400000,
              214.6000061035156,
              210.8000030517578,
              214.6000061035156,
              212.3999938964844,
              17992461,
              212.3999938964844,
              2.656,
              14.029,
              2.083,
              0.569,
              0.185,
              0.185,
              0.283,
              0.026,
              0.059,
              0.118,
              77.589,
              0.114,
              0.428,
              0.323,
              0.233,
              0.034,
              0.013,
              0.044,
              0.032,
              0.013
          ]
      },
      {
          "107": [
              1591660800000,
              214.6000061035156,
              211.6000061035156,
              212.8000030517578,
              212.3999938964844,
              23063431,
              212.3999938964844,
              2.793,
              13.878,
              2.072,
              0.572,
              0.215,
              0.186,
              0.279,
              0.025,
              0.059,
              0.124,
              77.589,
              0.117,
              0.424,
              0.323,
              0.238,
              0.034,
              0.013,
              0.044,
              0.034,
              0.013
          ]
      },
      {
          "108": [
              1591747200000,
              216.8000030517578,
              213.8000030517578,
              214.1999969482422,
              215.6000061035156,
              19038860,
              215.6000061035156,
              2.854,
              13.774,
              2.097,
              0.576,
              0.235,
              0.191,
              0.278,
              0.025,
              0.059,
              0.124,
              77.589,
              0.122,
              0.422,
              0.323,
              0.235,
              0.034,
              0.012,
              0.044,
              0.031,
              0.013
          ]
      },
      {
          "109": [
              1591833600000,
              220.6000061035156,
              211.8000030517578,
              218,
              213,
              33036598,
              213,
              2.92,
              13.704,
              2.124,
              0.575,
              0.23,
              0.191,
              0.276,
              0.025,
              0.058,
              0.121,
              77.589,
              0.126,
              0.425,
              0.323,
              0.236,
              0.034,
              0.012,
              0.044,
              0.03,
              0.012
          ]
      },
      {
          "110": [
              1591920000000,
              214,
              207.3999938964844,
              208.1999969482422,
              212,
              25058536,
              212,
              2.94,
              13.677,
              2.134,
              0.574,
              0.231,
              0.2,
              0.274,
              0.025,
              0.058,
              0.119,
              77.589,
              0.131,
              0.408,
              0.323,
              0.247,
              0.034,
              0.012,
              0.044,
              0.028,
              0.012
          ]
      },
      {
          "111": [
              1592179200000,
              212.3999938964844,
              205.3999938964844,
              209.8000030517578,
              206.8000030517578,
              31716109,
              206.8000030517578,
              3.027,
              13.593,
              2.159,
              0.57,
              0.234,
              0.2,
              0.27,
              0.025,
              0.056,
              0.119,
              77.589,
              0.132,
              0.4,
              0.322,
              0.247,
              0.034,
              0.012,
              0.043,
              0.026,
              0.012
          ]
      },
      {
          "112": [
              1592265600000,
              214.3999938964844,
              211,
              211.1999969482422,
              213.1999969482422,
              18039173,
              213.1999969482422,
              3.074,
              13.55,
              2.206,
              0.568,
              0.222,
              0.195,
              0.271,
              0.025,
              0.056,
              0.119,
              77.589,
              0.137,
              0.373,
              0.322,
              0.245,
              0.034,
              0.012,
              0.043,
              0.024,
              0.013
          ]
      },
      {
          "113": [
              1592352000000,
              218.3999938964844,
              214.1999969482422,
              216,
              217.8000030517578,
              21385682,
              217.8000030517578,
              3.115,
              13.047,
              2.665,
              0.57,
              0.211,
              0.191,
              0.274,
              0.025,
              0.049,
              0.121,
              77.589,
              0.137,
              0.388,
              0.323,
              0.242,
              0.034,
              0.012,
              0.043,
              0.024,
              0.013
          ]
      },
      {
          "114": [
              1592438400000,
              218.8000030517578,
              215.1999969482422,
              218.8000030517578,
              217.8000030517578,
              16168952,
              217.8000030517578,
              3.18,
              12.97,
              2.677,
              0.572,
              0.226,
              0.194,
              0.288,
              0.025,
              0.054,
              0.121,
              77.589,
              0.138,
              0.355,
              0.323,
              0.234,
              0.034,
              0.012,
              0.043,
              0.025,
              0.014
          ]
      },
      {
          "115": [
              1592524800000,
              219.8000030517578,
              216.1999969482422,
              217,
              219,
              27295880,
              219,
              3.189,
              12.913,
              2.693,
              0.573,
              0.234,
              0.192,
              0.285,
              0.025,
              0.055,
              0.12,
              77.589,
              0.131,
              0.392,
              0.322,
              0.236,
              0.034,
              0.012,
              0.043,
              0.027,
              0.014
          ]
      },
      {
          "116": [
              1592784000000,
              216.1999969482422,
              212.6000061035156,
              216.1999969482422,
              213,
              28166629,
              213,
              3.2,
              12.875,
              2.735,
              0.575,
              0.236,
              0.192,
              0.281,
              0.025,
              0.055,
              0.12,
              77.589,
              0.134,
              0.381,
              0.322,
              0.244,
              0.034,
              0.012,
              0.043,
              0.025,
              0.012
          ]
      },
      {
          "117": [
              1592870400000,
              220,
              212,
              216,
              220,
              40290287,
              220,
              3.239,
              12.856,
              2.744,
              0.576,
              0.232,
              0.195,
              0.281,
              0.025,
              0.054,
              0.12,
              77.589,
              0.132,
              0.356,
              0.321,
              0.248,
              0.034,
              0.014,
              0.039,
              0.024,
              0.012
          ]
      },
      {
          "118": [
              1592956800000,
              224.8000030517578,
              221.3999938964844,
              222.8000030517578,
              221.3999938964844,
              30207600,
              221.3999938964844,
              3.29,
              12.784,
              2.769,
              0.587,
              0.232,
              0.191,
              0.284,
              0.026,
              0.059,
              0.121,
              77.589,
              0.134,
              0.336,
              0.322,
              0.242,
              0.034,
              0.015,
              0.039,
              0.023,
              0.012
          ]
      },
      {
          "119": [
              1593129600000,
              217,
              214.3999938964844,
              217,
              214.6000061035156,
              33158894,
              214.6000061035156,
              3.308,
              12.731,
              2.783,
              0.589,
              0.333,
              0.176,
              0.283,
              0.026,
              0.055,
              0.122,
              77.589,
              0.133,
              0.274,
              0.321,
              0.236,
              0.034,
              0.015,
              0.039,
              0.023,
              0.011
          ]
      },
      {
          "120": [
              1593388800000,
              211.6000061035156,
              208.6000061035156,
              211,
              209.3999938964844,
              33824007,
              209.3999938964844,
              3.328,
              12.711,
              2.794,
              0.586,
              0.247,
              0.172,
              0.281,
              0.027,
              0.051,
              0.119,
              77.589,
              0.133,
              0.38,
              0.321,
              0.238,
              0.033,
              0.015,
              0.04,
              0.018,
              0.011
          ]
      },
      {
          "121": [
              1593475200000,
              212.1999969482422,
              208.3999938964844,
              210.6000061035156,
              209.6000061035156,
              24171917,
              209.6000061035156,
              3.358,
              12.663,
              2.767,
              0.594,
              0.26,
              0.169,
              0.286,
              0.028,
              0.05,
              0.112,
              77.589,
              0.143,
              0.385,
              0.322,
              0.228,
              0.033,
              0.015,
              0.04,
              0.016,
              0.011
          ]
      },
      {
          "122": [
              1593648000000,
              215,
              210.1999969482422,
              211.8000030517578,
              214.3999938964844,
              31018228,
              214.3999938964844,
              3.474,
              12.448,
              2.825,
              0.596,
              0.272,
              0.162,
              0.293,
              0.028,
              0.041,
              0.114,
              77.589,
              0.144,
              0.386,
              0.323,
              0.231,
              0.034,
              0.015,
              0.04,
              0.021,
              0.011
          ]
      },
      {
          "123": [
              1593734400000,
              221.3999938964844,
              217.1999969482422,
              220,
              220.8000030517578,
              31233712,
              220.8000030517578,
              3.658,
              12.279,
              2.839,
              0.602,
              0.262,
              0.171,
              0.295,
              0.028,
              0.041,
              0.106,
              77.589,
              0.147,
              0.342,
              0.323,
              0.232,
              0.034,
              0.015,
              0.04,
              0.025,
              0.011
          ]
      },
      {
          "124": [
              1593993600000,
              226.8000030517578,
              223,
              224,
              225.3999938964844,
              42489587,
              225.3999938964844,
              3.684,
              12.28,
              2.819,
              0.612,
              0.273,
              0.166,
              0.297,
              0.029,
              0.04,
              0.106,
              77.589,
              0.153,
              0.321,
              0.323,
              0.229,
              0.034,
              0.015,
              0.041,
              0.026,
              0.012
          ]
      },
      {
          "125": [
              1594080000000,
              239,
              232,
              233.1999969482422,
              232.3999938964844,
              46585757,
              232.3999938964844,
              3.71,
              12.267,
              2.805,
              0.618,
              0.269,
              0.173,
              0.298,
              0.029,
              0.041,
              0.104,
              77.589,
              0.159,
              0.319,
              0.323,
              0.236,
              0.034,
              0.016,
              0.042,
              0.025,
              0.013
          ]
      },
      {
          "126": [
              1594166400000,
              238.6000061035156,
              231,
              231,
              237.8000030517578,
              31883443,
              237.8000030517578,
              3.71,
              12.21,
              2.853,
              0.618,
              0.264,
              0.178,
              0.298,
              0.029,
              0.041,
              0.102,
              77.589,
              0.155,
              0.316,
              0.323,
              0.235,
              0.033,
              0.016,
              0.042,
              0.025,
              0.01
          ]
      },
      {
          "127": [
              1594252800000,
              263.79998779296875,
              252,
              254.8000030517578,
              261.60000610351557,
              61690256,
              261.60000610351557,
              3.737,
              12.16,
              2.897,
              0.631,
              0.254,
              0.168,
              0.286,
              0.03,
              0.046,
              0.097,
              77.589,
              0.158,
              0.338,
              0.322,
              0.237,
              0.032,
              0.015,
              0.043,
              0.022,
              0.012
          ]
      },
      {
          "128": [
              1594339200000,
              262,
              253,
              257,
              255.3999938964844,
              44519242,
              255.3999938964844,
              3.766,
              12.11,
              2.879,
              0.633,
              0.259,
              0.171,
              0.291,
              0.03,
              0.047,
              0.099,
              77.589,
              0.161,
              0.332,
              0.323,
              0.237,
              0.032,
              0.015,
              0.044,
              0.021,
              0.012
          ]
      },
      {
          "129": [
              1594598400000,
              258.39999389648443,
              251.1999969482422,
              254,
              254.3999938964844,
              30979669,
              254.3999938964844,
              3.777,
              12.038,
              2.882,
              0.637,
              0.282,
              0.181,
              0.289,
              0.031,
              0.051,
              0.098,
              77.589,
              0.17,
              0.318,
              0.322,
              0.244,
              0.027,
              0.016,
              0.045,
              0.024,
              0.011
          ]
      },
      {
          "130": [
              1594684800000,
              246.3999938964844,
              238.3999938964844,
              244.3999938964844,
              240.3999938964844,
              53646972,
              240.3999938964844,
              3.799,
              11.982,
              2.885,
              0.644,
              0.28,
              0.164,
              0.289,
              0.031,
              0.051,
              0.1,
              77.589,
              0.187,
              0.329,
              0.323,
              0.258,
              0.027,
              0.016,
              0.045,
              0.025,
              0.011
          ]
      },
      {
          "131": [
              1594771200000,
              245.1999969482422,
              239,
              244,
              243.3999938964844,
              23131222,
              243.3999938964844,
              3.808,
              11.721,
              2.895,
              0.791,
              0.288,
              0.183,
              0.291,
              0.031,
              0.051,
              0.101,
              77.589,
              0.195,
              0.364,
              0.323,
              0.272,
              0.027,
              0.016,
              0.045,
              0.024,
              0.011
          ]
      },
      {
          "132": [
              1594857600000,
              242.8000030517578,
              231.6000061035156,
              242.8000030517578,
              233.1999969482422,
              34282035,
              233.1999969482422,
              3.841,
              11.693,
              2.876,
              0.794,
              0.279,
              0.156,
              0.3,
              0.031,
              0.053,
              0.104,
              77.589,
              0.198,
              0.405,
              0.324,
              0.261,
              0.027,
              0.016,
              0.044,
              0.023,
              0.011
          ]
      },
      {
          "133": [
              1594944000000,
              240.6000061035156,
              233.3999938964844,
              235.3999938964844,
              238.8000030517578,
              20224245,
              238.8000030517578,
              3.866,
              11.691,
              2.865,
              0.796,
              0.291,
              0.168,
              0.299,
              0.031,
              0.055,
              0.104,
              77.589,
              0.199,
              0.374,
              0.323,
              0.263,
              0.028,
              0.016,
              0.044,
              0.022,
              0.01
          ]
      },
      {
          "134": [
              1595203200000,
              241.8000030517578,
              235.1999969482422,
              238,
              239.6000061035156,
              11353749,
              239.6000061035156,
              3.919,
              11.581,
              2.898,
              0.801,
              0.285,
              0.174,
              0.3,
              0.03,
              0.056,
              0.104,
              77.589,
              0.2,
              0.394,
              0.323,
              0.263,
              0.028,
              0.016,
              0.044,
              0.022,
              0.01
          ]
      },
      {
          "135": [
              1595289600000,
              257.60000610351557,
              250.8000030517578,
              251.8000030517578,
              257,
              45185546,
              257,
              3.935,
              11.603,
              2.878,
              0.805,
              0.303,
              0.178,
              0.299,
              0.03,
              0.056,
              0.102,
              77.589,
              0.2,
              0.37,
              0.322,
              0.26,
              0.028,
              0.016,
              0.044,
              0.022,
              0.009
          ]
      },
      {
          "136": [
              1595376000000,
              254.8000030517578,
              247.6000061035156,
              251.3999938964844,
              248,
              36503672,
              248,
              4.043,
              11.437,
              2.895,
              0.806,
              0.278,
              0.18,
              0.3,
              0.03,
              0.056,
              0.103,
              77.589,
              0.198,
              0.405,
              0.322,
              0.298,
              0.028,
              0.016,
              0.044,
              0.02,
              0.009
          ]
      },
      {
          "137": [
              1595462400000,
              248,
              242,
              245,
              246.6000061035156,
              31183903,
              246.6000061035156,
              4.055,
              11.394,
              2.901,
              0.812,
              0.287,
              0.181,
              0.299,
              0.03,
              0.056,
              0.105,
              77.589,
              0.199,
              0.391,
              0.322,
              0.301,
              0.028,
              0.014,
              0.044,
              0.023,
              0.01
          ]
      },
      {
          "138": [
              1595548800000,
              244.3999938964844,
              237.1999969482422,
              244.1999969482422,
              239,
              27470849,
              239,
              4.097,
              11.282,
              2.925,
              0.859,
              0.288,
              0.181,
              0.303,
              0.03,
              0.054,
              0.106,
              77.589,
              0.182,
              0.378,
              0.323,
              0.3,
              0.028,
              0.015,
              0.044,
              0.022,
              0.009
          ]
      },
      {
          "139": [
              1595808000000,
              244.1999969482422,
              235.6000061035156,
              240.8000030517578,
              239,
              18243796,
              239,
              4.126,
              11.21,
              2.91,
              0.863,
              0.287,
              0.191,
              0.306,
              0.031,
              0.054,
              0.109,
              77.589,
              0.192,
              0.376,
              0.323,
              0.309,
              0.028,
              0.015,
              0.044,
              0.023,
              0.009
          ]
      },
      {
          "140": [
              1595894400000,
              245.6000061035156,
              242.8000030517578,
              244,
              244.6000061035156,
              14674368,
              244.6000061035156,
              4.177,
              11.101,
              2.901,
              0.879,
              0.306,
              0.179,
              0.31,
              0.031,
              0.054,
              0.108,
              77.589,
              0.197,
              0.425,
              0.324,
              0.304,
              0.028,
              0.015,
              0.044,
              0.023,
              0.009
          ]
      },
      {
          "141": [
              1595980800000,
              244,
              241.1999969482422,
              242.1999969482422,
              243,
              10549764,
              243,
              4.171,
              11.102,
              2.903,
              0.881,
              0.307,
              0.175,
              0.309,
              0.032,
              0.055,
              0.108,
              77.589,
              0.201,
              0.436,
              0.324,
              0.303,
              0.028,
              0.015,
              0.044,
              0.023,
              0.009
          ]
      },
      {
          "142": [
              1596067200000,
              249.6000061035156,
              243.3999938964844,
              246,
              244.8000030517578,
              21715110,
              244.8000030517578,
              4.163,
              11.094,
              2.896,
              0.882,
              0.297,
              0.179,
              0.308,
              0.032,
              0.054,
              0.108,
              77.589,
              0.205,
              0.472,
              0.323,
              0.302,
              0.028,
              0.015,
              0.044,
              0.024,
              0.009
          ]
      },
      {
          "143": [
              1596153600000,
              249,
              245,
              249,
              246,
              14687907,
              246,
              4.172,
              11.071,
              2.896,
              0.884,
              0.291,
              0.178,
              0.309,
              0.032,
              0.058,
              0.11,
              77.589,
              0.206,
              0.476,
              0.323,
              0.303,
              0.028,
              0.015,
              0.044,
              0.024,
              0.009
          ]
      },
      {
          "144": [
              1596412800000,
              246.6000061035156,
              243.3999938964844,
              244.8000030517578,
              245.6000061035156,
              18274346,
              245.6000061035156,
              4.185,
              11.058,
              2.909,
              0.89,
              0.295,
              0.179,
              0.308,
              0.031,
              0.06,
              0.109,
              77.589,
              0.197,
              0.455,
              0.323,
              0.304,
              0.028,
              0.015,
              0.044,
              0.023,
              0.009
          ]
      },
      {
          "145": [
              1596499200000,
              255,
              250.3999938964844,
              250.8000030517578,
              253.8000030517578,
              24914559,
              253.8000030517578,
              4.213,
              11.041,
              2.908,
              0.891,
              0.292,
              0.178,
              0.309,
              0.031,
              0.056,
              0.111,
              77.589,
              0.197,
              0.453,
              0.323,
              0.305,
              0.028,
              0.018,
              0.044,
              0.023,
              0.009
          ]
      },
      {
          "146": [
              1596585600000,
              256,
              252,
              255.8000030517578,
              255.8000030517578,
              18116054,
              255.8000030517578,
              4.23,
              11.017,
              2.906,
              0.895,
              0.293,
              0.177,
              0.311,
              0.032,
              0.054,
              0.111,
              77.589,
              0.197,
              0.452,
              0.323,
              0.302,
              0.028,
              0.018,
              0.045,
              0.02,
              0.009
          ]
      },
      {
          "147": [
              1596672000000,
              257.79998779296875,
              253.1999969482422,
              257,
              257,
              15966937,
              257,
              4.242,
              10.993,
              2.908,
              0.897,
              0.293,
              0.192,
              0.309,
              0.031,
              0.054,
              0.113,
              77.589,
              0.202,
              0.436,
              0.323,
              0.311,
              0.028,
              0.018,
              0.046,
              0.02,
              0.009
          ]
      },
      {
          "148": [
              1596758400000,
              256.20001220703125,
              240.6000061035156,
              256,
              249.1999969482422,
              36237559,
              249.1999969482422,
              4.257,
              10.964,
              2.896,
              0.897,
              0.312,
              0.174,
              0.313,
              0.031,
              0.056,
              0.114,
              77.589,
              0.199,
              0.441,
              0.324,
              0.314,
              0.028,
              0.018,
              0.046,
              0.02,
              0.009
          ]
      },
      {
          "149": [
              1597017600000,
              244.8000030517578,
              240.3999938964844,
              244.8000030517578,
              242.3999938964844,
              26039892,
              242.3999938964844,
              4.27,
              10.977,
              2.878,
              0.898,
              0.313,
              0.175,
              0.314,
              0.032,
              0.056,
              0.113,
              77.589,
              0.202,
              0.437,
              0.324,
              0.315,
              0.028,
              0.018,
              0.046,
              0.019,
              0.009
          ]
      },
      {
          "150": [
              1597104000000,
              246.8000030517578,
              241.6000061035156,
              242,
              244,
              16702416,
              244,
              4.273,
              10.869,
              2.95,
              0.91,
              0.312,
              0.182,
              0.318,
              0.032,
              0.056,
              0.114,
              77.589,
              0.202,
              0.441,
              0.324,
              0.3,
              0.028,
              0.018,
              0.046,
              0.021,
              0.009
          ]
      },
      {
          "151": [
              1597190400000,
              244.6000061035156,
              238,
              242.1999969482422,
              243.3999938964844,
              15796928,
              243.3999938964844,
              4.28,
              10.847,
              2.953,
              0.915,
              0.314,
              0.175,
              0.323,
              0.032,
              0.057,
              0.115,
              77.589,
              0.208,
              0.426,
              0.325,
              0.307,
              0.028,
              0.018,
              0.046,
              0.019,
              0.008
          ]
      },
      {
          "152": [
              1597276800000,
              251,
              246.1999969482422,
              247.8000030517578,
              248.1999969482422,
              12489234,
              248.1999969482422,
              4.288,
              10.805,
              2.984,
              0.916,
              0.314,
              0.188,
              0.318,
              0.032,
              0.057,
              0.116,
              77.589,
              0.206,
              0.418,
              0.325,
              0.309,
              0.028,
              0.018,
              0.046,
              0.018,
              0.009
          ]
      },
      {
          "153": [
              1597363200000,
              248.8000030517578,
              244.3999938964844,
              246.1999969482422,
              246.8000030517578,
              12607886,
              246.8000030517578,
              4.302,
              10.808,
              2.958,
              0.914,
              0.322,
              0.189,
              0.318,
              0.033,
              0.057,
              0.114,
              77.589,
              0.206,
              0.412,
              0.325,
              0.311,
              0.028,
              0.018,
              0.046,
              0.02,
              0.009
          ]
      },
      {
          "154": [
              1597622400000,
              246.6000061035156,
              241.1999969482422,
              242.6000061035156,
              242.8000030517578,
              20853111,
              242.8000030517578,
              4.299,
              10.784,
              2.958,
              0.916,
              0.303,
              0.189,
              0.305,
              0.033,
              0.058,
              0.114,
              77.589,
              0.202,
              0.472,
              0.325,
              0.311,
              0.028,
              0.018,
              0.046,
              0.022,
              0.009
          ]
      },
      {
          "155": [
              1597708800000,
              252.6000061035156,
              247.6000061035156,
              249.3999938964844,
              252,
              23158760,
              252,
              4.342,
              10.723,
              2.961,
              0.915,
              0.303,
              0.202,
              0.305,
              0.033,
              0.058,
              0.114,
              77.589,
              0.199,
              0.482,
              0.325,
              0.312,
              0.028,
              0.018,
              0.047,
              0.021,
              0.009
          ]
      },
      {
          "156": [
              1597795200000,
              256,
              251,
              252,
              255.3999938964844,
              15227171,
              255.3999938964844,
              4.348,
              10.681,
              2.984,
              0.917,
              0.298,
              0.193,
              0.305,
              0.032,
              0.057,
              0.114,
              77.589,
              0.2,
              0.493,
              0.325,
              0.313,
              0.028,
              0.018,
              0.047,
              0.021,
              0.009
          ]
      },
      {
          "157": [
              1597881600000,
              255.8000030517578,
              250.3999938964844,
              254,
              255,
              22881516,
              255,
              4.386,
              10.635,
              3,
              0.918,
              0.298,
              0.202,
              0.3,
              0.033,
              0.055,
              0.114,
              77.589,
              0.201,
              0.491,
              0.325,
              0.322,
              0.028,
              0.018,
              0.047,
              0.023,
              0.009
          ]
      },
      {
          "158": [
              1597968000000,
              258.60000610351557,
              250.6000061035156,
              251.8000030517578,
              252.8000030517578,
              32079272,
              252.8000030517578,
              4.391,
              10.6,
              3.031,
              0.922,
              0.302,
              0.201,
              0.3,
              0.034,
              0.055,
              0.114,
              77.589,
              0.202,
              0.497,
              0.325,
              0.308,
              0.028,
              0.018,
              0.047,
              0.023,
              0.009
          ]
      },
      {
          "159": [
              1598227200000,
              266,
              259.20001220703125,
              261,
              264.39999389648443,
              43488850,
              264.39999389648443,
              4.397,
              10.463,
              3.103,
              0.923,
              0.308,
              0.243,
              0.311,
              0.034,
              0.055,
              0.115,
              77.589,
              0.218,
              0.464,
              0.325,
              0.31,
              0.028,
              0.018,
              0.047,
              0.022,
              0.009
          ]
      },
      {
          "160": [
              1598313600000,
              274.39999389648443,
              266.60000610351557,
              270,
              269.20001220703125,
              30708120,
              269.20001220703125,
              4.543,
              10.302,
              3.077,
              0.923,
              0.445,
              0.214,
              0.313,
              0.034,
              0.056,
              0.117,
              77.589,
              0.217,
              0.378,
              0.325,
              0.315,
              0.028,
              0.019,
              0.047,
              0.022,
              0.009
          ]
      },
      {
          "161": [
              1598400000000,
              280.39999389648443,
              276.79998779296875,
              279.79998779296875,
              280.39999389648443,
              31949336,
              280.39999389648443,
              4.592,
              10.244,
              3.059,
              0.926,
              0.447,
              0.252,
              0.31,
              0.034,
              0.057,
              0.122,
              77.589,
              0.213,
              0.363,
              0.325,
              0.324,
              0.022,
              0.019,
              0.048,
              0.022,
              0.009
          ]
      },
      {
          "162": [
              1598486400000,
              284,
              278,
              282.6000061035156,
              280.60000610351557,
              28072548,
              280.60000610351557,
              4.609,
              10.199,
              3.05,
              0.946,
              0.351,
              0.249,
              0.309,
              0.034,
              0.058,
              0.125,
              77.589,
              0.212,
              0.461,
              0.325,
              0.329,
              0.022,
              0.019,
              0.048,
              0.022,
              0.009
          ]
      },
      {
          "163": [
              1598572800000,
              279.60000610351557,
              275.20001220703125,
              277,
              277,
              29542320,
              277,
              4.631,
              10.131,
              3.076,
              0.951,
              0.352,
              0.249,
              0.309,
              0.034,
              0.058,
              0.126,
              77.589,
              0.245,
              0.444,
              0.325,
              0.326,
              0.022,
              0.019,
              0.048,
              0.022,
              0.008
          ]
      },
      {
          "164": [
              1598832000000,
              287.6000061035156,
              281,
              282.79998779296875,
              282.79998779296875,
              26933353,
              282.79998779296875,
              4.789,
              9.796,
              3.032,
              0.986,
              0.352,
              0.246,
              0.302,
              0.034,
              0.058,
              0.131,
              77.776,
              0.239,
              0.469,
              0.322,
              0.328,
              0.022,
              0.019,
              0.048,
              0.019,
              0.009
          ]
      },
      {
          "165": [
              1598918400000,
              285.3999938964844,
              278.20001220703125,
              279,
              282.79998779296875,
              24207208,
              282.79998779296875,
              4.81,
              9.741,
              3.057,
              0.981,
              0.349,
              0.245,
              0.301,
              0.035,
              0.057,
              0.139,
              77.776,
              0.249,
              0.454,
              0.322,
              0.322,
              0.022,
              0.019,
              0.048,
              0.019,
              0.009
          ]
      },
      {
          "166": [
              1599004800000,
              292,
              287.3999938964844,
              290,
              291.20001220703125,
              24662027,
              291.20001220703125,
              4.84,
              9.679,
              3.089,
              0.983,
              0.312,
              0.245,
              0.297,
              0.034,
              0.055,
              0.14,
              76.95,
              1.076,
              0.489,
              0.322,
              0.325,
              0.022,
              0.018,
              0.048,
              0.02,
              0.009
          ]
      },
      {
          "167": [
              1599091200000,
              289.20001220703125,
              283.3999938964844,
              287.79998779296875,
              284.20001220703125,
              28987875,
              284.20001220703125,
              4.884,
              9.547,
              3.08,
              0.987,
              0.37,
              0.254,
              0.299,
              0.035,
              0.056,
              0.146,
              76.951,
              1.092,
              0.484,
              0.322,
              0.322,
              0.022,
              0.018,
              0.048,
              0.021,
              0.009
          ]
      },
      {
          "168": [
              1599177600000,
              275.39999389648443,
              265.20001220703125,
              268,
              274,
              78861798,
              274,
              4.946,
              9.41,
              3.064,
              1.036,
              0.383,
              0.254,
              0.299,
              0.035,
              0.058,
              0.144,
              76.951,
              1.092,
              0.522,
              0.322,
              0.321,
              0.022,
              0.018,
              0.048,
              0.022,
              0.009
          ]
      },
      {
          "169": [
              1599436800000,
              274.60000610351557,
              265.39999389648443,
              271.20001220703125,
              265.60000610351557,
              47493625,
              265.60000610351557,
              5.024,
              9.261,
              3.215,
              1.053,
              0.385,
              0.25,
              0.301,
              0.035,
              0.058,
              0.145,
              76.951,
              1.093,
              0.414,
              0.322,
              0.329,
              0.022,
              0.018,
              0.048,
              0.017,
              0.009
          ]
      },
      {
          "170": [
              1599523200000,
              271.79998779296875,
              262.20001220703125,
              270,
              269.60000610351557,
              27608738,
              269.60000610351557,
              5.168,
              9.277,
              3.169,
              1.054,
              0.365,
              0.248,
              0.308,
              0.035,
              0.056,
              0.145,
              76.951,
              1.085,
              0.307,
              0.323,
              0.329,
              0.022,
              0.018,
              0.047,
              0.02,
              0.008
          ]
      },
      {
          "171": [
              1599609600000,
              264.60000610351557,
              258.60000610351557,
              262,
              263.20001220703125,
              27066740,
              263.20001220703125,
              5.204,
              9.176,
              3.187,
              1.056,
              0.375,
              0.254,
              0.31,
              0.035,
              0.057,
              0.147,
              76.951,
              1.098,
              0.325,
              0.323,
              0.324,
              0.022,
              0.018,
              0.047,
              0.019,
              0.008
          ]
      },
      {
          "172": [
              1599696000000,
              268,
              262,
              266.79998779296875,
              262.60000610351557,
              25531787,
              262.60000610351557,
              5.238,
              9.11,
              3.206,
              1.058,
              0.369,
              0.258,
              0.303,
              0.035,
              0.058,
              0.142,
              76.951,
              1.109,
              0.32,
              0.322,
              0.327,
              0.022,
              0.018,
              0.045,
              0.018,
              0.008
          ]
      },
      {
          "173": [
              1599782400000,
              267,
              260.79998779296875,
              263.39999389648443,
              266,
              20757663,
              266,
              5.249,
              9.093,
              3.204,
              1.06,
              0.371,
              0.252,
              0.29,
              0.035,
              0.058,
              0.141,
              76.951,
              1.106,
              0.333,
              0.322,
              0.333,
              0.022,
              0.019,
              0.043,
              0.019,
              0.008
          ]
      },
      {
          "174": [
              1600041600000,
              268.60000610351557,
              264,
              266.79998779296875,
              268.20001220703125,
              16063671,
              268.20001220703125,
              5.339,
              8.95,
              3.265,
              1.064,
              0.366,
              0.254,
              0.291,
              0.035,
              0.059,
              0.142,
              76.951,
              1.112,
              0.35,
              0.323,
              0.331,
              0.022,
              0.019,
              0.042,
              0.018,
              0.008
          ]
      },
      {
          "175": [
              1600128000000,
              269.60000610351557,
              266.20001220703125,
              268,
              267.39999389648443,
              19666515,
              267.39999389648443,
              5.346,
              8.922,
              3.271,
              1.073,
              0.386,
              0.259,
              0.29,
              0.035,
              0.059,
              0.143,
              76.951,
              1.107,
              0.325,
              0.323,
              0.336,
              0.022,
              0.018,
              0.042,
              0.018,
              0.008
          ]
      },
      {
          "176": [
              1600214400000,
              273,
              269.20001220703125,
              269.79998779296875,
              273,
              20927130,
              273,
              5.372,
              8.854,
              3.279,
              1.074,
              0.389,
              0.259,
              0.29,
              0.035,
              0.06,
              0.144,
              76.951,
              1.133,
              0.319,
              0.323,
              0.337,
              0.022,
              0.018,
              0.042,
              0.018,
              0.008
          ]
      },
      {
          "177": [
              1600300800000,
              271.60000610351557,
              265.60000610351557,
              270.79998779296875,
              267.79998779296875,
              19364862,
              267.79998779296875,
              5.39,
              8.824,
              3.256,
              1.076,
              0.39,
              0.261,
              0.292,
              0.035,
              0.058,
              0.144,
              76.951,
              1.13,
              0.316,
              0.323,
              0.354,
              0.022,
              0.018,
              0.042,
              0.018,
              0.009
          ]
      },
      {
          "178": [
              1600387200000,
              269.60000610351557,
              264.39999389648443,
              266.60000610351557,
              269.60000610351557,
              20935638,
              269.60000610351557,
              5.395,
              8.621,
              3.432,
              1.088,
              0.392,
              0.262,
              0.292,
              0.035,
              0.058,
              0.145,
              76.951,
              1.111,
              0.342,
              0.323,
              0.353,
              0.022,
              0.018,
              0.042,
              0.017,
              0.009
          ]
      },
      {
          "179": [
              1600646400000,
              268.20001220703125,
              262,
              267,
              262,
              20930001,
              262,
              5.494,
              8.498,
              3.45,
              1.085,
              0.395,
              0.26,
              0.297,
              0.035,
              0.058,
              0.145,
              76.951,
              1.122,
              0.359,
              0.323,
              0.369,
              0.022,
              0.018,
              0.042,
              0.018,
              0.008
          ]
      },
      {
          "180": [
              1600732800000,
              267,
              262.39999389648443,
              267,
              264.79998779296875,
              16372015,
              264.79998779296875,
              5.523,
              8.481,
              3.446,
              1.085,
              0.401,
              0.26,
              0.298,
              0.035,
              0.058,
              0.147,
              76.951,
              1.118,
              0.342,
              0.323,
              0.369,
              0.022,
              0.018,
              0.042,
              0.018,
              0.009
          ]
      },
      {
          "181": [
              1600819200000,
              269.20001220703125,
              264.79998779296875,
              266.79998779296875,
              267,
              20296916,
              267,
              5.565,
              8.45,
              3.436,
              1.084,
              0.401,
              0.264,
              0.299,
              0.036,
              0.058,
              0.151,
              76.946,
              1.119,
              0.337,
              0.323,
              0.366,
              0.022,
              0.019,
              0.042,
              0.017,
              0.009
          ]
      },
      {
          "182": [
              1600905600000,
              265.39999389648443,
              261,
              264,
              261,
              16462927,
              261,
              5.587,
              8.433,
              3.434,
              1.083,
              0.41,
              0.27,
              0.298,
              0.036,
              0.057,
              0.15,
              76.946,
              1.128,
              0.313,
              0.323,
              0.366,
              0.022,
              0.018,
              0.042,
              0.017,
              0.008
          ]
      },
      {
          "183": [
              1600992000000,
              264.39999389648443,
              255.3999938964844,
              264.39999389648443,
              258,
              15319675,
              258,
              5.608,
              8.433,
              3.422,
              1.086,
              0.409,
              0.264,
              0.299,
              0.04,
              0.057,
              0.149,
              76.946,
              1.117,
              0.326,
              0.323,
              0.363,
              0.022,
              0.018,
              0.042,
              0.017,
              0.008
          ]
      },
      {
          "184": [
              1601251200000,
              264.60000610351557,
              261.20001220703125,
              262,
              263.39999389648443,
              11818697,
              263.39999389648443,
              5.718,
              8.294,
              3.432,
              1.087,
              0.408,
              0.266,
              0.298,
              0.042,
              0.057,
              0.149,
              76.946,
              1.119,
              0.351,
              0.323,
              0.358,
              0.022,
              0.018,
              0.042,
              0.016,
              0.009
          ]
      },
      {
          "185": [
              1601337600000,
              270,
              265.39999389648443,
              268.20001220703125,
              265.39999389648443,
              16607941,
              265.39999389648443,
              5.73,
              8.266,
              3.445,
              1.087,
              0.403,
              0.264,
              0.299,
              0.042,
              0.058,
              0.147,
              76.946,
              1.122,
              0.353,
              0.323,
              0.361,
              0.022,
              0.018,
              0.042,
              0.016,
              0.009
          ]
      },
      {
          "186": [
              1601424000000,
              276,
              268.79998779296875,
              269.39999389648443,
              275.60000610351557,
              35325185,
              275.60000610351557,
              5.735,
              8.244,
              3.449,
              1.09,
              0.405,
              0.261,
              0.299,
              0.042,
              0.06,
              0.146,
              76.946,
              1.121,
              0.363,
              0.323,
              0.363,
              0.022,
              0.018,
              0.042,
              0.016,
              0.009
          ]
      },
      {
          "187": [
              1601856000000,
              285,
              278.60000610351557,
              285,
              282,
              25233089,
              282,
              5.923,
              8.019,
              3.48,
              1.089,
              0.415,
              0.261,
              0.294,
              0.043,
              0.057,
              0.152,
              76.946,
              1.118,
              0.352,
              0.323,
              0.36,
              0.022,
              0.018,
              0.043,
              0.019,
              0.011
          ]
      },
      {
          "188": [
              1601942400000,
              281.20001220703125,
              277.39999389648443,
              279.79998779296875,
              280,
              18023022,
              280,
              5.945,
              7.995,
              3.499,
              1.096,
              0.413,
              0.271,
              0.288,
              0.043,
              0.055,
              0.151,
              76.946,
              1.122,
              0.335,
              0.322,
              0.359,
              0.022,
              0.017,
              0.044,
              0.018,
              0.011
          ]
      },
      {
          "189": [
              1602028800000,
              289,
              282.6000061035156,
              283.79998779296875,
              288.20001220703125,
              21555736,
              288.20001220703125,
              5.964,
              7.948,
              3.523,
              1.095,
              0.412,
              0.283,
              0.287,
              0.042,
              0.056,
              0.152,
              76.946,
              1.127,
              0.315,
              0.322,
              0.368,
              0.022,
              0.016,
              0.044,
              0.018,
              0.011
          ]
      },
      {
          "190": [
              1602115200000,
              289.79998779296875,
              285.20001220703125,
              289.79998779296875,
              289.6000061035156,
              17175080,
              289.6000061035156,
              6.008,
              7.906,
              3.519,
              1.093,
              0.413,
              0.281,
              0.289,
              0.042,
              0.056,
              0.155,
              76.934,
              1.131,
              0.315,
              0.322,
              0.367,
              0.022,
              0.016,
              0.044,
              0.021,
              0.011
          ]
      },
      {
          "191": [
              1602201600000,
              293,
              283.79998779296875,
              291,
              286.20001220703125,
              22209544,
              286.20001220703125,
              6.02,
              7.887,
              3.534,
              1.096,
              0.405,
              0.286,
              0.287,
              0.043,
              0.056,
              0.154,
              76.934,
              1.129,
              0.345,
              0.322,
              0.363,
              0.022,
              0.013,
              0.044,
              0.021,
              0.011
          ]
      },
      {
          "192": [
              1602460800000,
              293,
              287.6000061035156,
              288,
              292,
              15212791,
              292,
              6.034,
              7.805,
              3.588,
              1.091,
              0.406,
              0.285,
              0.287,
              0.043,
              0.057,
              0.153,
              76.933,
              1.136,
              0.354,
              0.322,
              0.362,
              0.022,
              0.013,
              0.044,
              0.022,
              0.011
          ]
      },
      {
          "193": [
              1602633600000,
              298,
              294.6000061035156,
              298,
              297.6000061035156,
              27640402,
              297.6000061035156,
              6.051,
              7.783,
              3.589,
              1.093,
              0.42,
              0.298,
              0.286,
              0.043,
              0.059,
              0.152,
              76.933,
              1.135,
              0.332,
              0.322,
              0.369,
              0.022,
              0.013,
              0.044,
              0.021,
              0.011
          ]
      },
      {
          "194": [
              1602720000000,
              291,
              284.20001220703125,
              290.3999938964844,
              284.79998779296875,
              26871063,
              284.79998779296875,
              6.074,
              7.727,
              3.634,
              1.085,
              0.413,
              0.294,
              0.283,
              0.047,
              0.059,
              0.15,
              76.934,
              1.131,
              0.357,
              0.322,
              0.367,
              0.022,
              0.013,
              0.044,
              0.019,
              0.011
          ]
      },
      {
          "195": [
              1602806400000,
              294,
              288.79998779296875,
              290,
              294,
              18581210,
              294,
              6.091,
              7.712,
              3.639,
              1.086,
              0.413,
              0.324,
              0.28,
              0.049,
              0.06,
              0.145,
              76.934,
              1.132,
              0.337,
              0.321,
              0.356,
              0.022,
              0.013,
              0.044,
              0.018,
              0.011
          ]
      },
      {
          "196": [
              1603065600000,
              299.3999938964844,
              295.3999938964844,
              298.20001220703125,
              297.20001220703125,
              19704407,
              297.20001220703125,
              6.096,
              7.709,
              3.511,
              1.082,
              0.411,
              0.331,
              0.279,
              0.049,
              0.06,
              0.143,
              76.934,
              1.132,
              0.335,
              0.322,
              0.482,
              0.022,
              0.013,
              0.044,
              0.017,
              0.011
          ]
      },
      {
          "197": [
              1603152000000,
              300.3999938964844,
              296.20001220703125,
              297.79998779296875,
              299.3999938964844,
              19447954,
              299.3999938964844,
              6.095,
              7.704,
              3.529,
              1.087,
              0.415,
              0.337,
              0.277,
              0.05,
              0.059,
              0.142,
              76.934,
              1.144,
              0.329,
              0.322,
              0.466,
              0.022,
              0.013,
              0.044,
              0.018,
              0.011
          ]
      },
      {
          "198": [
              1603238400000,
              301.6000061035156,
              299.20001220703125,
              301.20001220703125,
              300.20001220703125,
              17439667,
              300.20001220703125,
              6.12,
              7.692,
              3.526,
              1.084,
              0.412,
              0.34,
              0.277,
              0.049,
              0.06,
              0.133,
              76.934,
              1.141,
              0.333,
              0.322,
              0.469,
              0.022,
              0.013,
              0.044,
              0.016,
              0.012
          ]
      },
      {
          "199": [
              1603324800000,
              301.6000061035156,
              297,
              298.6000061035156,
              300,
              14381076,
              300,
              6.136,
              7.67,
              3.568,
              1.084,
              0.412,
              0.34,
              0.276,
              0.049,
              0.06,
              0.128,
              76.934,
              1.141,
              0.314,
              0.321,
              0.47,
              0.022,
              0.013,
              0.044,
              0.016,
              0.012
          ]
      },
      {
          "200": [
              1603411200000,
              300.3999938964844,
              297,
              297.79998779296875,
              298,
              15074407,
              298,
              6.124,
              7.655,
              3.562,
              1.084,
              0.413,
              0.331,
              0.276,
              0.049,
              0.061,
              0.127,
              76.934,
              1.159,
              0.307,
              0.321,
              0.486,
              0.022,
              0.013,
              0.044,
              0.018,
              0.012
          ]
      },
      {
          "201": [
              1603756800000,
              300.6000061035156,
              294.20001220703125,
              296.6000061035156,
              300,
              18285289,
              300,
              6.133,
              7.608,
              3.613,
              1.085,
              0.415,
              0.329,
              0.277,
              0.049,
              0.061,
              0.128,
              76.934,
              1.161,
              0.314,
              0.321,
              0.479,
              0.022,
              0.013,
              0.044,
              0.017,
              0.011
          ]
      },
      {
          "202": [
              1603843200000,
              309.3999938964844,
              304.20001220703125,
              304.3999938964844,
              307.3999938964844,
              27847200,
              307.3999938964844,
              6.17,
              7.578,
              3.621,
              1.089,
              0.412,
              0.326,
              0.277,
              0.049,
              0.06,
              0.129,
              76.934,
              1.15,
              0.312,
              0.321,
              0.475,
              0.022,
              0.013,
              0.044,
              0.018,
              0.011
          ]
      },
      {
          "203": [
              1603929600000,
              305.6000061035156,
              299,
              300,
              302.6000061035156,
              24070737,
              302.6000061035156,
              6.185,
              7.552,
              3.646,
              1.084,
              0.413,
              0.324,
              0.273,
              0.048,
              0.059,
              0.128,
              76.934,
              1.153,
              0.302,
              0.321,
              0.467,
              0.022,
              0.013,
              0.044,
              0.02,
              0.011
          ]
      },
      {
          "204": [
              1604016000000,
              303,
              292.6000061035156,
              301,
              293.20001220703125,
              26853552,
              293.20001220703125,
              6.195,
              7.543,
              3.649,
              1.082,
              0.419,
              0.325,
              0.269,
              0.047,
              0.059,
              0.128,
              76.934,
              1.161,
              0.295,
              0.32,
              0.474,
              0.022,
              0.013,
              0.044,
              0.017,
              0.011
          ]
      },
      {
          "205": [
              1604275200000,
              298.3999938964844,
              291.3999938964844,
              298.3999938964844,
              297.79998779296875,
              18371445,
              297.79998779296875,
              6.202,
              7.543,
              3.638,
              1.081,
              0.411,
              0.323,
              0.271,
              0.046,
              0.057,
              0.128,
              76.934,
              1.175,
              0.277,
              0.321,
              0.478,
              0.022,
              0.012,
              0.044,
              0.022,
              0.011
          ]
      },
      {
          "206": [
              1604361600000,
              301.6000061035156,
              299,
              300.3999938964844,
              299.79998779296875,
              17260262,
              299.79998779296875,
              6.196,
              7.567,
              3.655,
              1.08,
              0.411,
              0.325,
              0.272,
              0.046,
              0.056,
              0.129,
              76.934,
              1.152,
              0.249,
              0.321,
              0.494,
              0.022,
              0.013,
              0.044,
              0.021,
              0.011
          ]
      },
      {
          "207": [
              1604448000000,
              282,
              272,
              272,
              277.20001220703125,
              101173866,
              277.20001220703125,
              6.202,
              7.568,
              3.646,
              1.083,
              0.408,
              0.326,
              0.272,
              0.046,
              0.055,
              0.128,
              76.934,
              1.154,
              0.26,
              0.321,
              0.487,
              0.022,
              0.012,
              0.044,
              0.021,
              0.011
          ]
      },
      {
          "208": [
              1604534400000,
              294.6000061035156,
              285.6000061035156,
              285.6000061035156,
              294.6000061035156,
              42593676,
              294.6000061035156,
              6.214,
              13.39,
              3.65,
              1.074,
              0.409,
              0.325,
              0.27,
              0.046,
              0.056,
              0.127,
              71.103,
              1.158,
              0.258,
              0.321,
              0.48,
              0.022,
              0.012,
              0.044,
              0.02,
              0.011
          ]
      },
      {
          "209": [
              1604620800000,
              289.79998779296875,
              282,
              282.79998779296875,
              282,
              52373045,
              282,
              6.591,
              13.025,
              3.621,
              1.064,
              0.408,
              0.312,
              0.284,
              0.045,
              0.058,
              0.128,
              71.103,
              1.161,
              0.251,
              0.321,
              0.468,
              0.023,
              0.013,
              0.044,
              0.023,
              0.012
          ]
      },
      {
          "210": [
              1604880000000,
              293,
              289.3999938964844,
              291.3999938964844,
              290.20001220703125,
              35794135,
              290.20001220703125,
              6.597,
              13.003,
              3.625,
              1.062,
              0.411,
              0.308,
              0.282,
              0.045,
              0.061,
              0.126,
              71.103,
              1.159,
              0.267,
              0.321,
              0.439,
              0.023,
              0.013,
              0.044,
              0.021,
              0.011
          ]
      },
      {
          "211": [
              1604966400000,
              283.79998779296875,
              273,
              283.3999938964844,
              275.39999389648443,
              63855746,
              275.39999389648443,
              6.633,
              13.036,
              3.598,
              1.057,
              0.412,
              0.309,
              0.279,
              0.045,
              0.058,
              0.132,
              71.103,
              1.162,
              0.258,
              0.321,
              0.413,
              0.024,
              0.013,
              0.044,
              0.023,
              0.011
          ]
      },
      {
          "212": [
              1605052800000,
              258.79998779296875,
              248.3999938964844,
              255,
              248.3999938964844,
              116686276,
              248.3999938964844,
              6.621,
              13.005,
              3.606,
              1.051,
              0.421,
              0.312,
              0.278,
              0.045,
              0.058,
              0.132,
              71.103,
              1.175,
              0.291,
              0.321,
              0.383,
              0.024,
              0.013,
              0.044,
              0.022,
              0.011
          ]
      },
      {
          "213": [
              1605139200000,
              258.60000610351557,
              252.1999969482422,
              257.79998779296875,
              253,
              54771508,
              253,
              6.606,
              12.995,
              3.599,
              1.05,
              0.423,
              0.298,
              0.292,
              0.046,
              0.06,
              0.137,
              71.103,
              1.174,
              0.319,
              0.322,
              0.363,
              0.023,
              0.013,
              0.043,
              0.023,
              0.011
          ]
      },
      {
          "214": [
              1605225600000,
              260,
              252.3999938964844,
              258.60000610351557,
              257,
              42011134,
              257,
              6.508,
              12.988,
              3.58,
              1.038,
              0.42,
              0.323,
              0.312,
              0.051,
              0.07,
              0.14,
              71.103,
              1.163,
              0.269,
              0.324,
              0.389,
              0.024,
              0.013,
              0.04,
              0.023,
              0.012
          ]
      },
      {
          "215": [
              1605484800000,
              254.6000061035156,
              245,
              253.3999938964844,
              254,
              40495713,
              254,
              6.475,
              13.015,
              3.592,
              1.039,
              0.417,
              0.323,
              0.316,
              0.053,
              0.071,
              0.142,
              71.103,
              1.164,
              0.315,
              0.324,
              0.37,
              0.024,
              0.013,
              0.04,
              0.023,
              0.012
          ]
      },
      {
          "216": [
              1605571200000,
              255.6000061035156,
              249.8000030517578,
              255,
              249.8000030517578,
              27731447,
              249.8000030517578,
              6.496,
              13.003,
              3.605,
              1.034,
              0.417,
              0.306,
              0.311,
              0.052,
              0.07,
              0.142,
              71.103,
              1.18,
              0.271,
              0.324,
              0.343,
              0.023,
              0.013,
              0.04,
              0.023,
              0.012
          ]
      },
      {
          "217": [
              1605657600000,
              252,
              247.6000061035156,
              251,
              250,
              21190712,
              250,
              6.506,
              13.063,
              3.641,
              1.035,
              0.412,
              0.302,
              0.308,
              0.053,
              0.074,
              0.14,
              71.103,
              1.172,
              0.264,
              0.324,
              0.346,
              0.022,
              0.013,
              0.04,
              0.023,
              0.009
          ]
      },
      {
          "218": [
              1605744000000,
              250,
              245,
              250,
              245.1999969482422,
              19558960,
              245.1999969482422,
              6.519,
              13.032,
              3.638,
              1.032,
              0.414,
              0.306,
              0.306,
              0.058,
              0.075,
              0.141,
              71.103,
              1.165,
              0.324,
              0.324,
              0.309,
              0.022,
              0.013,
              0.04,
              0.023,
              0.009
          ]
      },
      {
          "219": [
              1605830400000,
              255,
              249.1999969482422,
              251,
              253,
              24969028,
              253,
              6.511,
              13.049,
              3.635,
              1.037,
              0.432,
              0.306,
              0.306,
              0.058,
              0.075,
              0.141,
              71.103,
              1.154,
              0.31,
              0.324,
              0.308,
              0.022,
              0.013,
              0.039,
              0.023,
              0.009
          ]
      },
      {
          "220": [
              1606089600000,
              266.20001220703125,
              260.60000610351557,
              263.20001220703125,
              264,
              44892281,
              264,
              6.527,
              13.068,
              3.617,
              1.041,
              0.431,
              0.309,
              0.307,
              0.058,
              0.076,
              0.141,
              71.103,
              1.145,
              0.296,
              0.324,
              0.297,
              0.023,
              0.013,
              0.039,
              0.023,
              0.009
          ]
      },
      {
          "221": [
              1606176000000,
              274.60000610351557,
              267.79998779296875,
              273,
              270,
              41400443,
              270,
              6.555,
              13.058,
              3.617,
              1.041,
              0.421,
              0.305,
              0.304,
              0.058,
              0.076,
              0.141,
              71.103,
              1.143,
              0.305,
              0.323,
              0.299,
              0.023,
              0.013,
              0.04,
              0.024,
              0.009
          ]
      },
      {
          "222": [
              1606262400000,
              275,
              264.60000610351557,
              274.79998779296875,
              267,
              27982144,
              267,
              6.607,
              13.057,
              3.609,
              1.041,
              0.426,
              0.304,
              0.296,
              0.059,
              0.073,
              0.138,
              71.103,
              1.146,
              0.309,
              0.323,
              0.28,
              0.023,
              0.013,
              0.04,
              0.025,
              0.009
          ]
      },
      {
          "223": [
              1606348800000,
              271.79998779296875,
              267.20001220703125,
              270,
              269,
              18539582,
              269,
              6.635,
              13.051,
              3.614,
              1.048,
              0.446,
              0.31,
              0.292,
              0.058,
              0.072,
              0.137,
              71.103,
              1.154,
              0.278,
              0.323,
              0.274,
              0.023,
              0.013,
              0.041,
              0.022,
              0.007
          ]
      },
      {
          "224": [
              1606435200000,
              271,
              267.20001220703125,
              271,
              269.60000610351557,
              11575149,
              269.60000610351557,
              6.639,
              13.048,
              3.626,
              1.049,
              0.445,
              0.299,
              0.291,
              0.058,
              0.071,
              0.137,
              71.103,
              1.157,
              0.284,
              0.323,
              0.266,
              0.023,
              0.013,
              0.041,
              0.022,
              0.007
          ]
      },
      {
          "225": [
              1606694400000,
              264.79998779296875,
              258.60000610351557,
              264,
              261.39999389648443,
              19453729,
              261.39999389648443,
              6.634,
              13.043,
              3.626,
              1.05,
              0.445,
              0.299,
              0.29,
              0.057,
              0.071,
              0.137,
              71.103,
              1.159,
              0.292,
              0.323,
              0.265,
              0.023,
              0.013,
              0.039,
              0.021,
              0.007
          ]
      },
      {
          "226": [
              1606780800000,
              259.60000610351557,
              254.6000061035156,
              256.79998779296875,
              257.20001220703125,
              19622885,
              257.20001220703125,
              6.647,
              13.068,
              3.591,
              1.055,
              0.453,
              0.301,
              0.29,
              0.058,
              0.07,
              0.139,
              71.102,
              1.153,
              0.28,
              0.322,
              0.267,
              0.023,
              0.013,
              0.039,
              0.02,
              0.007
          ]
      },
      {
          "227": [
              1606867200000,
              257.20001220703125,
              254,
              257,
              255.1999969482422,
              17518631,
              255.1999969482422,
              6.653,
              13.045,
              3.625,
              1.059,
              0.455,
              0.294,
              0.294,
              0.058,
              0.07,
              0.137,
              71.102,
              1.158,
              0.26,
              0.322,
              0.271,
              0.023,
              0.013,
              0.039,
              0.02,
              0.007
          ]
      },
      {
          "228": [
              1606953600000,
              258.39999389648443,
              254.6000061035156,
              255.1999969482422,
              257.39999389648443,
              14374923,
              257.39999389648443,
              6.712,
              12.986,
              3.629,
              1.062,
              0.447,
              0.295,
              0.291,
              0.059,
              0.071,
              0.137,
              71.102,
              1.155,
              0.26,
              0.322,
              0.272,
              0.023,
              0.013,
              0.039,
              0.02,
              0.007
          ]
      },
      {
          "229": [
              1607040000000,
              266.60000610351557,
              258.39999389648443,
              259,
              264,
              28041474,
              264,
              6.704,
              12.971,
              3.649,
              1.063,
              0.447,
              0.296,
              0.291,
              0.06,
              0.071,
              0.136,
              71.102,
              1.153,
              0.264,
              0.322,
              0.27,
              0.023,
              0.012,
              0.039,
              0.02,
              0.007
          ]
      },
      {
          "230": [
              1607299200000,
              261.20001220703125,
              255,
              261,
              258,
              16021766,
              258,
              6.747,
              12.891,
              3.667,
              1.093,
              0.449,
              0.291,
              0.292,
              0.064,
              0.072,
              0.135,
              71.102,
              1.137,
              0.256,
              0.322,
              0.271,
              0.023,
              0.012,
              0.039,
              0.02,
              0.007
          ]
      },
      {
          "231": [
              1607385600000,
              259.20001220703125,
              255.3999938964844,
              257,
              258,
              11781749,
              258,
              6.924,
              12.784,
              3.639,
              1.087,
              0.458,
              0.294,
              0.29,
              0.064,
              0.072,
              0.134,
              71.102,
              1.136,
              0.237,
              0.322,
              0.263,
              0.023,
              0.012,
              0.039,
              0.02,
              0.007
          ]
      },
      {
          "232": [
              1607472000000,
              262.79998779296875,
              259.39999389648443,
              262,
              260.79998779296875,
              17024320,
              260.79998779296875,
              6.938,
              12.738,
              3.678,
              1.088,
              0.45,
              0.287,
              0.291,
              0.064,
              0.072,
              0.134,
              71.102,
              1.128,
              0.255,
              0.322,
              0.261,
              0.023,
              0.012,
              0.039,
              0.02,
              0.007
          ]
      },
      {
          "233": [
              1607558400000,
              256.20001220703125,
              253,
              255.6000061035156,
              255.3999938964844,
              21316945,
              255.3999938964844,
              6.97,
              12.729,
              3.657,
              1.091,
              0.45,
              0.281,
              0.291,
              0.064,
              0.071,
              0.135,
              71.102,
              1.131,
              0.263,
              0.322,
              0.261,
              0.023,
              0.012,
              0.038,
              0.02,
              0.007
          ]
      },
      {
          "234": [
              1607644800000,
              260.60000610351557,
              257,
              259,
              258.39999389648443,
              12567160,
              258.39999389648443,
              7.012,
              12.676,
              3.666,
              1.099,
              0.451,
              0.28,
              0.288,
              0.064,
              0.071,
              0.134,
              71.102,
              1.134,
              0.262,
              0.322,
              0.259,
              0.023,
              0.012,
              0.038,
              0.021,
              0.007
          ]
      },
      {
          "235": [
              1607904000000,
              258.79998779296875,
              250,
              258.79998779296875,
              251.6000061035156,
              22655912,
              251.6000061035156,
              7.03,
              12.648,
              3.653,
              1.099,
              0.45,
              0.282,
              0.288,
              0.064,
              0.071,
              0.138,
              71.099,
              1.132,
              0.275,
              0.322,
              0.264,
              0.023,
              0.012,
              0.037,
              0.022,
              0.007
          ]
      },
      {
          "236": [
              1607990400000,
              250,
              245,
              250,
              246,
              27969305,
              246,
              7.061,
              12.508,
              3.779,
              1.101,
              0.45,
              0.282,
              0.29,
              0.063,
              0.069,
              0.137,
              71.099,
              1.132,
              0.268,
              0.322,
              0.266,
              0.023,
              0.012,
              0.037,
              0.02,
              0.007
          ]
      },
      {
          "237": [
              1608076800000,
              252.3999938964844,
              246.8000030517578,
              248.8000030517578,
              251,
              16279191,
              251,
              7.035,
              12.502,
              3.794,
              1.093,
              0.449,
              0.279,
              0.292,
              0.064,
              0.07,
              0.137,
              71.099,
              1.129,
              0.286,
              0.322,
              0.263,
              0.023,
              0.012,
              0.037,
              0.025,
              0.007
          ]
      },
      {
          "238": [
              1608163200000,
              258,
              254.3999938964844,
              255.3999938964844,
              258,
              18134098,
              258,
              7.038,
              12.486,
              3.793,
              1.094,
              0.464,
              0.283,
              0.291,
              0.065,
              0.072,
              0.137,
              71.099,
              1.113,
              0.302,
              0.322,
              0.263,
              0.023,
              0.012,
              0.037,
              0.018,
              0.006
          ]
      },
      {
          "239": [
              1608249600000,
              257.39999389648443,
              252.6000061035156,
              257.39999389648443,
              255.6000061035156,
              23943869,
              255.6000061035156,
              7.067,
              12.474,
              3.759,
              1.105,
              0.463,
              0.284,
              0.291,
              0.066,
              0.072,
              0.136,
              71.099,
              1.112,
              0.304,
              0.322,
              0.265,
              0.023,
              0.012,
              0.037,
              0.017,
              0.006
          ]
      },
      {
          "240": [
              1608508800000,
              255,
              248.3999938964844,
              255,
              250,
              14314027,
              250,
              7.078,
              12.44,
              3.802,
              1.098,
              0.466,
              0.285,
              0.29,
              0.065,
              0.072,
              0.135,
              71.099,
              1.113,
              0.299,
              0.322,
              0.262,
              0.023,
              0.012,
              0.037,
              0.017,
              0.006
          ]
      },
      {
          "241": [
              1608595200000,
              252.1999969482422,
              247.6000061035156,
              252,
              248.8000030517578,
              12519867,
              248.8000030517578,
              7.13,
              12.38,
              3.788,
              1.133,
              0.467,
              0.286,
              0.29,
              0.065,
              0.071,
              0.136,
              71.099,
              1.127,
              0.273,
              0.322,
              0.26,
              0.023,
              0.012,
              0.037,
              0.017,
              0.006
          ]
      },
      {
          "242": [
              1608681600000,
              249.8000030517578,
              245.1999969482422,
              249.1999969482422,
              248.3999938964844,
              15189316,
              248.3999938964844,
              7.138,
              12.371,
              3.787,
              1.137,
              0.468,
              0.284,
              0.289,
              0.065,
              0.071,
              0.135,
              71.099,
              1.117,
              0.289,
              0.322,
              0.259,
              0.023,
              0.012,
              0.037,
              0.02,
              0.006
          ]
      },
      {
          "243": [
              1608768000000,
              228.6000061035156,
              228.6000061035156,
              228.6000061035156,
              228.6000061035156,
              0,
              228.6000061035156,
              7.138,
              12.371,
              3.787,
              1.137,
              0.468,
              0.284,
              0.289,
              0.065,
              0.071,
              0.135,
              71.099,
              1.117,
              0.289,
              0.322,
              0.259,
              0.023,
              0.012,
              0.037,
              0.02,
              0.006
          ]
      },
      {
          "244": [
              1609113600000,
              219.8000030517578,
              207.1999969482422,
              219.3999938964844,
              210,
              104426789,
              210,
              7.135,
              12.284,
              3.867,
              1.141,
              0.467,
              0.28,
              0.289,
              0.065,
              0.072,
              0.134,
              71.099,
              1.118,
              0.287,
              0.322,
              0.252,
              0.023,
              0.012,
              0.036,
              0.024,
              0.006
          ]
      },
      {
          "245": [
              1609200000000,
              225,
              216.1999969482422,
              216.1999969482422,
              222,
              42704055,
              222,
              7.123,
              12.329,
              3.728,
              1.135,
              0.467,
              0.262,
              0.291,
              0.065,
              0.073,
              0.137,
              71.099,
              1.121,
              0.215,
              0.323,
              0.227,
              0.023,
              0.011,
              0.035,
              0.022,
              0.006
          ]
      },
      {
          "246": [
              1609286400000,
              236.3999938964844,
              229.3999938964844,
              229.8000030517578,
              236.1999969482422,
              40430776,
              236.1999969482422,
              7.001,
              12.448,
              3.784,
              1.13,
              0.474,
              0.281,
              0.289,
              0.062,
              0.09,
              0.144,
              71.099,
              1.12,
              0.294,
              0.323,
              0.235,
              0.01,
              0.011,
              0.033,
              0.018,
              0.006
          ]
      },
      {
          "247": [
              1609372800000,
              232.1999969482422,
              232.1999969482422,
              232.1999969482422,
              232.1999969482422,
              0,
              232.1999969482422,
              7.001,
              12.448,
              3.784,
              1.13,
              0.474,
              0.281,
              0.289,
              0.062,
              0.09,
              0.144,
              71.099,
              1.12,
              0.294,
              0.323,
              0.235,
              0.01,
              0.011,
              0.033,
              0.018,
              0.006
          ]
      },
      {
          "248": [
              1609718400000,
              228.6000061035156,
              223.3999938964844,
              224.8000030517578,
              227.6000061035156,
              27907724,
              227.6000061035156,
              7.019,
              12.422,
              3.785,
              1.13,
              0.471,
              0.249,
              0.29,
              0.064,
              0.09,
              0.144,
              71.099,
              1.12,
              0.329,
              0.322,
              0.229,
              0.01,
              0.011,
              0.034,
              0.019,
              0.006
          ]
      },
      {
          "249": [
              1609804800000,
              224.1999969482422,
              219.1999969482422,
              220,
              223,
              35061260,
              223,
              7.05,
              12.43,
              3.779,
              1.139,
              0.467,
              0.248,
              0.291,
              0.063,
              0.091,
              0.146,
              71.099,
              1.127,
              0.3,
              0.322,
              0.233,
              0.011,
              0.011,
              0.034,
              0.019,
              0.006
          ]
      },
      {
          "250": [
              1609891200000,
              232.6000061035156,
              227.8000030517578,
              228,
              230,
              31462508,
              230,
              7.076,
              12.409,
              3.773,
              1.14,
              0.467,
              0.249,
              0.293,
              0.065,
              0.092,
              0.147,
              71.099,
              1.12,
              0.292,
              0.312,
              0.235,
              0.01,
              0.011,
              0.034,
              0.019,
              0.006
          ]
      },
      {
          "251": [
              1609977600000,
              221.8000030517578,
              216.3999938964844,
              221,
              221,
              47362360,
              221,
              7.081,
              12.367,
              3.778,
              1.142,
              0.468,
              0.249,
              0.293,
              0.066,
              0.093,
              0.148,
              71.099,
              1.12,
              0.306,
              0.302,
              0.237,
              0.01,
              0.011,
              0.033,
              0.018,
              0.006
          ]
      },
      {
          "252": [
              1610064000000,
              226.3999938964844,
              219.6000061035156,
              223.3999938964844,
              224.1999969482422,
              29880895,
              224.1999969482422,
              7.127,
              12.324,
              3.752,
              1.147,
              0.471,
              0.247,
              0.306,
              0.068,
              0.095,
              0.148,
              71.099,
              1.122,
              0.305,
              0.291,
              0.26,
              0.01,
              0.011,
              0.033,
              0.013,
              0.007
          ]
      },
      {
          "253": [
              1610323200000,
              230.3999938964844,
              220,
              227.1999969482422,
              221,
              49219037,
              221,
              7.128,
              12.307,
              3.74,
              1.147,
              0.469,
              0.255,
              0.307,
              0.069,
              0.097,
              0.151,
              71.099,
              1.121,
              0.323,
              0.28,
              0.242,
              0.01,
              0.011,
              0.033,
              0.013,
              0.007
          ]
      },
      {
          "254": [
              1610409600000,
              221,
              217.1999969482422,
              221,
              220,
              36773693,
              220,
              7.15,
              12.3,
              3.716,
              1.147,
              0.479,
              0.251,
              0.311,
              0.07,
              0.1,
              0.152,
              71.099,
              1.123,
              0.327,
              0.278,
              0.24,
              0.01,
              0.011,
              0.032,
              0.013,
              0.007
          ]
      },
      {
          "255": [
              1610496000000,
              225.6000061035156,
              221,
              221.6000061035156,
              223.8000030517578,
              25521304,
              223.8000030517578,
              7.155,
              12.209,
              3.732,
              1.155,
              0.477,
              0.27,
              0.314,
              0.071,
              0.1,
              0.152,
              71.099,
              1.13,
              0.291,
              0.273,
              0.291,
              0.011,
              0.011,
              0.032,
              0.012,
              0.007
          ]
      },
      {
          "256": [
              1610582400000,
              235.3999938964844,
              229.3999938964844,
              231.8000030517578,
              235,
              56102214,
              235,
              7.148,
              12.217,
              3.761,
              1.158,
              0.481,
              0.256,
              0.314,
              0.072,
              0.099,
              0.154,
              71.099,
              1.127,
              0.261,
              0.267,
              0.286,
              0.011,
              0.011,
              0.032,
              0.014,
              0.007
          ]
      },
      {
          "257": [
              1610668800000,
              244.1999969482422,
              236.1999969482422,
              238,
              241,
              56724043,
              241,
              7.175,
              12.176,
              3.8,
              1.154,
              0.485,
              0.271,
              0.314,
              0.072,
              0.1,
              0.154,
              71.099,
              1.129,
              0.292,
              0.256,
              0.232,
              0.011,
              0.011,
              0.032,
              0.015,
              0.007
          ]
      },
      {
          "258": [
              1610928000000,
              243.6000061035156,
              236.6000061035156,
              238.6000061035156,
              242,
              28459710,
              242,
              7.219,
              12.141,
              3.791,
              1.16,
              0.492,
              0.263,
              0.314,
              0.073,
              0.101,
              0.158,
              71.099,
              1.129,
              0.296,
              0.245,
              0.228,
              0.011,
              0.011,
              0.034,
              0.014,
              0.007
          ]
      },
      {
          "259": [
              1611014400000,
              248.8000030517578,
              242.1999969482422,
              245,
              244.1999969482422,
              35728468,
              244.1999969482422,
              7.264,
              12.165,
              3.759,
              1.162,
              0.497,
              0.259,
              0.315,
              0.073,
              0.101,
              0.159,
              71.099,
              1.133,
              0.253,
              0.24,
              0.238,
              0.011,
              0.011,
              0.034,
              0.013,
              0.007
          ]
      },
      {
          "260": [
              1611100800000,
              270,
              244.3999938964844,
              245.1999969482422,
              265,
              105283224,
              265,
              7.28,
              12.145,
              3.762,
              1.163,
              0.493,
              0.258,
              0.312,
              0.074,
              0.102,
              0.159,
              71.099,
              1.132,
              0.26,
              0.24,
              0.246,
              0.011,
              0.01,
              0.034,
              0.013,
              0.007
          ]
      },
      {
          "261": [
              1611187200000,
              262.60000610351557,
              255.1999969482422,
              259.60000610351557,
              258.60000610351557,
              52014003,
              258.60000610351557,
              7.29,
              12.104,
              3.766,
              1.169,
              0.503,
              0.257,
              0.297,
              0.075,
              0.104,
              0.159,
              71.099,
              1.138,
              0.246,
              0.24,
              0.255,
              0.012,
              0.011,
              0.034,
              0.013,
              0.007
          ]
      },
      {
          "262": [
              1611273600000,
              253.3999938964844,
              247.1999969482422,
              252.3999938964844,
              250.6000061035156,
              39959960,
              250.6000061035156,
              7.288,
              12.032,
              3.786,
              1.189,
              0.541,
              0.268,
              0.293,
              0.074,
              0.117,
              0.163,
              71.099,
              1.14,
              0.191,
              0.238,
              0.262,
              0.012,
              0.012,
              0.034,
              0.014,
              0.007
          ]
      },
      {
          "263": [
              1611532800000,
              262.79998779296875,
              251,
              252.6000061035156,
              258,
              47792619,
              258,
              7.313,
              11.942,
              3.776,
              1.197,
              0.551,
              0.266,
              0.298,
              0.078,
              0.115,
              0.163,
              71.099,
              1.162,
              0.219,
              0.238,
              0.25,
              0.012,
              0.011,
              0.034,
              0.013,
              0.007
          ]
      },
      {
          "264": [
              1611619200000,
              256,
              252.1999969482422,
              255.3999938964844,
              253.3999938964844,
              33317774,
              253.3999938964844,
              7.365,
              11.835,
              3.824,
              1.2,
              0.538,
              0.262,
              0.299,
              0.078,
              0.12,
              0.159,
              71.099,
              1.138,
              0.262,
              0.238,
              0.248,
              0.012,
              0.011,
              0.034,
              0.014,
              0.006
          ]
      },
      {
          "265": [
              1611705600000,
              263.20001220703125,
              255.1999969482422,
              261.79998779296875,
              260,
              34684877,
              260,
              7.393,
              11.815,
              3.844,
              1.206,
              0.526,
              0.265,
              0.299,
              0.079,
              0.123,
              0.161,
              71.099,
              1.137,
              0.271,
              0.237,
              0.231,
              0.012,
              0.011,
              0.034,
              0.015,
              0.006
          ]
      },
      {
          "266": [
              1611792000000,
              257.60000610351557,
              250.3999938964844,
              252.8000030517578,
              251.6000061035156,
              34740164,
              251.6000061035156,
              7.412,
              11.807,
              3.838,
              1.21,
              0.522,
              0.248,
              0.301,
              0.08,
              0.122,
              0.16,
              71.098,
              1.139,
              0.264,
              0.237,
              0.228,
              0.013,
              0.011,
              0.034,
              0.015,
              0.006
          ]
      },
      {
          "267": [
              1611878400000,
              254,
              242,
              251.6000061035156,
              246.8000030517578,
              34866053,
              246.8000030517578,
              7.435,
              11.761,
              3.848,
              1.215,
              0.516,
              0.256,
              0.302,
              0.08,
              0.123,
              0.16,
              71.098,
              1.139,
              0.278,
              0.237,
              0.224,
              0.013,
              0.011,
              0.035,
              0.014,
              0.006
          ]
      },
      {
          "268": [
              1612137600000,
              252,
              245,
              246,
              251.1999969482422,
              27611659,
              251.1999969482422,
              7.436,
              11.729,
              3.867,
              1.226,
              0.521,
              0.255,
              0.303,
              0.081,
              0.123,
              0.161,
              71.098,
              1.14,
              0.264,
              0.236,
              0.218,
              0.012,
              0.011,
              0.035,
              0.014,
              0.006
          ]
      },
      {
          "269": [
              1612224000000,
              261.39999389648443,
              255.8000030517578,
              260,
              260,
              35793652,
              260,
              7.418,
              11.703,
              3.885,
              1.232,
              0.518,
              0.263,
              0.306,
              0.082,
              0.123,
              0.158,
              71.098,
              1.141,
              0.272,
              0.236,
              0.218,
              0.012,
              0.011,
              0.035,
              0.016,
              0.006
          ]
      },
      {
          "270": [
              1612310400000,
              266,
              247.1999969482422,
              249.8000030517578,
              261,
              58494910,
              261,
              7.421,
              11.69,
              3.892,
              1.233,
              0.516,
              0.266,
              0.308,
              0.083,
              0.127,
              0.158,
              71.098,
              1.139,
              0.275,
              0.236,
              0.217,
              0.013,
              0.011,
              0.035,
              0.016,
              0.006
          ]
      },
      {
          "271": [
              1612396800000,
              267.39999389648443,
              257.39999389648443,
              260.79998779296875,
              262.20001220703125,
              72252607,
              262.20001220703125,
              7.454,
              11.637,
              3.911,
              1.231,
              0.548,
              0.281,
              0.309,
              0.083,
              0.127,
              0.157,
              71.098,
              1.14,
              0.263,
              0.225,
              0.219,
              0.012,
              0.011,
              0.035,
              0.015,
              0.006
          ]
      },
      {
          "272": [
              1612483200000,
              261.39999389648443,
              256.39999389648443,
              259.79998779296875,
              258,
              30738742,
              258,
              7.484,
              11.605,
              3.913,
              1.223,
              0.561,
              0.282,
              0.313,
              0.084,
              0.127,
              0.16,
              71.098,
              1.14,
              0.244,
              0.224,
              0.221,
              0.012,
              0.011,
              0.035,
              0.016,
              0.006
          ]
      },
      {
          "273": [
              1612742400000,
              262.39999389648443,
              255.3999938964844,
              259.60000610351557,
              256.39999389648443,
              14861452,
              256.39999389648443,
              7.528,
              11.56,
              3.899,
              1.228,
              0.543,
              0.285,
              0.315,
              0.083,
              0.13,
              0.159,
              71.098,
              1.147,
              0.186,
              0.223,
              0.258,
              0.013,
              0.011,
              0.036,
              0.016,
              0.006
          ]
      },
      {
          "274": [
              1612828800000,
              257.39999389648443,
              253,
              255.8000030517578,
              255.3999938964844,
              21534617,
              255.3999938964844,
              7.546,
              11.537,
              3.88,
              1.229,
              0.535,
              0.283,
              0.316,
              0.085,
              0.129,
              0.16,
              71.098,
              1.145,
              0.237,
              0.223,
              0.248,
              0.013,
              0.011,
              0.036,
              0.015,
              0.006
          ]
      },
      {
          "275": [
              1612915200000,
              263,
              257.39999389648443,
              258.60000610351557,
              262,
              28952395,
              262,
              7.576,
              11.47,
              3.885,
              1.233,
              0.542,
              0.285,
              0.315,
              0.087,
              0.126,
              0.159,
              71.098,
              1.142,
              0.273,
              0.222,
              0.228,
              0.013,
              0.011,
              0.036,
              0.014,
              0.006
          ]
      },
      {
          "276": [
              1613001600000,
              261,
              261,
              261,
              261,
              0,
              261,
              7.576,
              11.47,
              3.885,
              1.233,
              0.542,
              0.285,
              0.315,
              0.087,
              0.126,
              0.159,
              71.098,
              1.142,
              0.273,
              0.222,
              0.228,
              0.013,
              0.011,
              0.036,
              0.014,
              0.006
          ]
      },
      {
          "277": [
              1613433600000,
              266,
              263.20001220703125,
              264.79998779296875,
              264.20001220703125,
              35490449,
              264.20001220703125,
              7.632,
              11.311,
              3.942,
              1.242,
              0.541,
              0.288,
              0.312,
              0.087,
              0.124,
              0.16,
              71.098,
              1.142,
              0.301,
              0.221,
              0.23,
              0.013,
              0.011,
              0.036,
              0.015,
              0.006
          ]
      },
      {
          "278": [
              1613520000000,
              269.39999389648443,
              262.20001220703125,
              263.39999389648443,
              267,
              36931302,
              267,
              7.662,
              11.282,
              3.968,
              1.232,
              0.545,
              0.285,
              0.309,
              0.086,
              0.124,
              0.158,
              71.098,
              1.144,
              0.302,
              0.22,
              0.23,
              0.013,
              0.011,
              0.036,
              0.017,
              0.006
          ]
      },
      {
          "279": [
              1613606400000,
              263.79998779296875,
              260,
              263.39999389648443,
              260.39999389648443,
              29507989,
              260.39999389648443,
              7.688,
              11.253,
              3.976,
              1.232,
              0.556,
              0.284,
              0.308,
              0.086,
              0.123,
              0.158,
              71.098,
              1.143,
              0.296,
              0.22,
              0.229,
              0.013,
              0.011,
              0.036,
              0.017,
              0.006
          ]
      },
      {
          "280": [
              1613692800000,
              258,
              252.3999938964844,
              256,
              257,
              27537542,
              257,
              7.688,
              11.235,
              3.965,
              1.239,
              0.558,
              0.277,
              0.309,
              0.086,
              0.122,
              0.159,
              71.098,
              1.147,
              0.297,
              0.219,
              0.227,
              0.013,
              0.01,
              0.036,
              0.016,
              0.006
          ]
      },
      {
          "281": [
              1613952000000,
              256.39999389648443,
              249.8000030517578,
              256,
              250.6000061035156,
              30923499,
              250.6000061035156,
              7.695,
              11.222,
              3.972,
              1.238,
              0.557,
              0.278,
              0.313,
              0.088,
              0.121,
              0.16,
              71.098,
              1.146,
              0.293,
              0.226,
              0.227,
              0.013,
              0.011,
              0.036,
              0.017,
              0.005
          ]
      },
      {
          "282": [
              1614038400000,
              248.8000030517578,
              243.1999969482422,
              246.3999938964844,
              247.6000061035156,
              24370829,
              247.6000061035156,
              7.724,
              11.18,
              3.968,
              1.239,
              0.557,
              0.279,
              0.315,
              0.089,
              0.12,
              0.16,
              71.098,
              1.15,
              0.3,
              0.226,
              0.224,
              0.013,
              0.011,
              0.035,
              0.016,
              0.005
          ]
      },
      {
          "283": [
              1614124800000,
              248.8000030517578,
              240,
              247.6000061035156,
              242.8000030517578,
              31209824,
              242.8000030517578,
              7.72,
              11.137,
              3.987,
              1.241,
              0.555,
              0.287,
              0.317,
              0.09,
              0.119,
              0.161,
              71.097,
              1.161,
              0.288,
              0.226,
              0.229,
              0.013,
              0.011,
              0.034,
              0.016,
              0.005
          ]
      },
      {
          "284": [
              1614211200000,
              248.8000030517578,
              239.1999969482422,
              243.3999938964844,
              243.3999938964844,
              27601154,
              243.3999938964844,
              7.73,
              11.112,
              4.034,
              1.23,
              0.567,
              0.275,
              0.318,
              0.09,
              0.117,
              0.162,
              71.097,
              1.154,
              0.283,
              0.225,
              0.226,
              0.013,
              0.01,
              0.034,
              0.016,
              0.005
          ]
      },
      {
          "285": [
              1614297600000,
              234.3999938964844,
              230.8000030517578,
              232.8000030517578,
              232.3999938964844,
              42971525,
              232.3999938964844,
              7.737,
              11.092,
              4.058,
              1.227,
              0.562,
              0.275,
              0.32,
              0.09,
              0.117,
              0.163,
              71.097,
              1.148,
              0.275,
              0.226,
              0.226,
              0.013,
              0.01,
              0.034,
              0.017,
              0.005
          ]
      },
      {
          "286": [
              1614556800000,
              236.6000061035156,
              232.3999938964844,
              234.8000030517578,
              234.6000061035156,
              25781049,
              234.6000061035156,
              7.735,
              11.079,
              4.076,
              1.23,
              0.558,
              0.269,
              0.322,
              0.101,
              0.117,
              0.164,
              71.097,
              1.147,
              0.273,
              0.225,
              0.228,
              0.012,
              0.011,
              0.034,
              0.015,
              0.005
          ]
      },
      {
          "287": [
              1614643200000,
              235.3999938964844,
              229,
              234.6000061035156,
              229.3999938964844,
              28438280,
              229.3999938964844,
              7.748,
              11.05,
              4.081,
              1.233,
              0.557,
              0.267,
              0.328,
              0.1,
              0.117,
              0.166,
              71.097,
              1.146,
              0.252,
              0.225,
              0.229,
              0.012,
              0.011,
              0.034,
              0.014,
              0.006
          ]
      },
      {
          "288": [
              1614729600000,
              234,
              227.3999938964844,
              229.6000061035156,
              232.3999938964844,
              18744515,
              232.3999938964844,
              7.764,
              11.004,
              4.093,
              1.235,
              0.575,
              0.264,
              0.33,
              0.1,
              0.112,
              0.168,
              71.097,
              1.146,
              0.251,
              0.225,
              0.231,
              0.012,
              0.011,
              0.034,
              0.014,
              0.006
          ]
      },
      {
          "289": [
              1614816000000,
              231.1999969482422,
              227,
              231,
              227.1999969482422,
              17756272,
              227.1999969482422,
              7.779,
              10.968,
              4.095,
              1.241,
              0.573,
              0.261,
              0.331,
              0.101,
              0.111,
              0.17,
              71.097,
              1.166,
              0.251,
              0.226,
              0.234,
              0.012,
              0.011,
              0.033,
              0.014,
              0.006
          ]
      },
      {
          "290": [
              1614902400000,
              229.8000030517578,
              221.1999969482422,
              223.8000030517578,
              227,
              26560051,
              227,
              7.766,
              10.944,
              4.095,
              1.24,
              0.561,
              0.262,
              0.331,
              0.103,
              0.107,
              0.169,
              71.156,
              1.151,
              0.257,
              0.224,
              0.233,
              0.012,
              0.011,
              0.034,
              0.014,
              0.006
          ]
      },
      {
          "291": [
              1615161600000,
              231.6000061035156,
              220.1999969482422,
              228.8000030517578,
              220.6000061035156,
              24540546,
              220.6000061035156,
              7.772,
              10.91,
              4.103,
              1.244,
              0.578,
              0.26,
              0.332,
              0.101,
              0.105,
              0.17,
              71.156,
              1.153,
              0.261,
              0.224,
              0.234,
              0.012,
              0.011,
              0.034,
              0.015,
              0.006
          ]
      },
      {
          "292": [
              1615248000000,
              230,
              218.6000061035156,
              224,
              224,
              35249748,
              224,
              7.787,
              10.904,
              4.112,
              1.245,
              0.578,
              0.261,
              0.333,
              0.104,
              0.107,
              0.171,
              71.156,
              1.146,
              0.248,
              0.224,
              0.233,
              0.012,
              0.011,
              0.034,
              0.013,
              0.006
          ]
      },
      {
          "293": [
              1615334400000,
              231.6000061035156,
              228,
              231,
              228.3999938964844,
              30292760,
              228.3999938964844,
              7.783,
              10.877,
              4.138,
              1.248,
              0.579,
              0.263,
              0.333,
              0.101,
              0.105,
              0.172,
              71.156,
              1.147,
              0.188,
              0.224,
              0.284,
              0.012,
              0.011,
              0.034,
              0.014,
              0.006
          ]
      },
      {
          "294": [
              1615420800000,
              230.3999938964844,
              226.3999938964844,
              229,
              227.6000061035156,
              29228815,
              227.6000061035156,
              7.797,
              10.853,
              4.174,
              1.253,
              0.583,
              0.266,
              0.334,
              0.102,
              0.104,
              0.18,
              71.156,
              1.156,
              0.193,
              0.224,
              0.233,
              0.012,
              0.011,
              0.034,
              0.014,
              0.006
          ]
      },
      {
          "295": [
              1615507200000,
              234.3999938964844,
              225.8000030517578,
              233,
              226,
              34231547,
              226,
              7.877,
              10.76,
              4.168,
              1.255,
              0.583,
              0.277,
              0.334,
              0.093,
              0.104,
              0.179,
              71.156,
              1.166,
              0.186,
              0.223,
              0.234,
              0.012,
              0.011,
              0.034,
              0.013,
              0.006
          ]
      },
      {
          "296": [
              1615766400000,
              227.8000030517578,
              219.3999938964844,
              227,
              221.3999938964844,
              33908103,
              221.3999938964844,
              7.886,
              10.786,
              4.134,
              1.248,
              0.576,
              0.268,
              0.334,
              0.093,
              0.105,
              0.178,
              71.156,
              1.161,
              0.199,
              0.223,
              0.237,
              0.012,
              0.01,
              0.034,
              0.013,
              0.006
          ]
      },
      {
          "297": [
              1615852800000,
              222.6000061035156,
              220.6000061035156,
              222.3999938964844,
              221.3999938964844,
              20868000,
              221.3999938964844,
              7.864,
              10.775,
              4.079,
              1.253,
              0.633,
              0.276,
              0.333,
              0.094,
              0.104,
              0.178,
              71.156,
              1.161,
              0.207,
              0.222,
              0.237,
              0.012,
              0.01,
              0.034,
              0.012,
              0.006
          ]
      },
      {
          "298": [
              1615939200000,
              223.3999938964844,
              219.3999938964844,
              221,
              222.6000061035156,
              18718973,
              222.6000061035156,
              7.881,
              10.749,
              4.135,
              1.259,
              0.639,
              0.275,
              0.333,
              0.094,
              0.105,
              0.178,
              71.156,
              1.179,
              0.234,
              0.221,
              0.15,
              0.013,
              0.01,
              0.034,
              0.012,
              0.006
          ]
      },
      {
          "299": [
              1616025600000,
              238.6000061035156,
              226.3999938964844,
              226.6000061035156,
              233.6000061035156,
              58621778,
              233.6000061035156,
              7.882,
              10.711,
              4.033,
              1.26,
              0.592,
              0.277,
              0.332,
              0.094,
              0.105,
              0.179,
              71.156,
              1.167,
              0.318,
              0.221,
              0.244,
              0.013,
              0.01,
              0.034,
              0.014,
              0.006
          ]
      },
      {
          "300": [
              1616112000000,
              234.6000061035156,
              229.6000061035156,
              232,
              234.6000061035156,
              44914799,
              234.6000061035156,
              8.193,
              10.385,
              4.049,
              1.256,
              0.59,
              0.28,
              0.333,
              0.092,
              0.106,
              0.18,
              71.156,
              1.169,
              0.313,
              0.221,
              0.248,
              0.013,
              0.01,
              0.033,
              0.013,
              0.006
          ]
      },
      {
          "301": [
              1616371200000,
              234.3999938964844,
              230.6000061035156,
              232.3999938964844,
              231.1999969482422,
              14508251,
              231.1999969482422,
              8.222,
              8.547,
              5.792,
              1.267,
              0.598,
              0.278,
              0.333,
              0.091,
              0.109,
              0.178,
              71.157,
              1.166,
              0.344,
              0.22,
              0.276,
              0.013,
              0.01,
              0.033,
              0.013,
              0.006
          ]
      },
      {
          "302": [
              1616457600000,
              232.8000030517578,
              228,
              231.3999938964844,
              230,
              26218754,
              230,
              8.332,
              8.519,
              5.803,
              1.272,
              0.593,
              0.274,
              0.333,
              0.091,
              0.107,
              0.179,
              71.157,
              1.17,
              0.292,
              0.218,
              0.259,
              0.013,
              0.01,
              0.033,
              0.012,
              0.006
          ]
      },
      {
          "303": [
              1616544000000,
              233.6000061035156,
              228.3999938964844,
              232.6000061035156,
              230,
              15811948,
              230,
              8.376,
              17.793,
              5.79,
              1.272,
              0.598,
              0.28,
              0.335,
              0.091,
              0.107,
              0.179,
              61.824,
              1.172,
              0.283,
              0.218,
              0.263,
              0.013,
              0.01,
              0.033,
              0.012,
              0.006
          ]
      },
      {
          "304": [
              1616630400000,
              222.1999969482422,
              215.1999969482422,
              218.6000061035156,
              221,
              30783245,
              221,
              8.568,
              17.633,
              5.732,
              1.28,
              0.593,
              0.28,
              0.334,
              0.088,
              0.104,
              0.179,
              61.824,
              1.174,
              0.31,
              0.217,
              0.267,
              0.013,
              0.01,
              0.033,
              0.011,
              0.006
          ]
      },
      {
          "305": [
              1616716800000,
              218.6000061035156,
              214,
              217.8000030517578,
              216,
              32976932,
              216,
              8.599,
              17.528,
              5.823,
              1.284,
              0.596,
              0.272,
              0.334,
              0.088,
              0.099,
              0.183,
              61.824,
              1.175,
              0.322,
              0.216,
              0.274,
              0.013,
              0.01,
              0.033,
              0.011,
              0.006
          ]
      },
      {
          "306": [
              1616976000000,
              217.6000061035156,
              214.3999938964844,
              216,
              216.1999969482422,
              25704513,
              216.1999969482422,
              8.603,
              17.488,
              5.811,
              1.286,
              0.595,
              0.271,
              0.335,
              0.087,
              0.099,
              0.184,
              61.824,
              1.186,
              0.351,
              0.217,
              0.275,
              0.014,
              0.01,
              0.033,
              0.011,
              0.006
          ]
      },
      {
          "307": [
              1617062400000,
              225.1999969482422,
              220.8000030517578,
              224.3999938964844,
              221.6000061035156,
              33848578,
              221.6000061035156,
              8.577,
              17.484,
              5.801,
              1.324,
              0.597,
              0.27,
              0.337,
              0.088,
              0.102,
              0.187,
              61.823,
              1.18,
              0.33,
              0.217,
              0.275,
              0.014,
              0.01,
              0.033,
              0.013,
              0.006
          ]
      },
      {
          "308": [
              1617148800000,
              224.1999969482422,
              219.8000030517578,
              224,
              220,
              19946806,
              220,
              8.551,
              17.51,
              5.81,
              1.292,
              0.595,
              0.275,
              0.337,
              0.088,
              0.103,
              0.188,
              61.823,
              1.18,
              0.333,
              0.217,
              0.273,
              0.014,
              0.01,
              0.033,
              0.012,
              0.006
          ]
      },
      {
          "309": [
              1617235200000,
              226.1999969482422,
              220.6000061035156,
              223,
              225.6000061035156,
              23025316,
              225.6000061035156,
              8.533,
              17.518,
              5.761,
              1.295,
              0.623,
              0.246,
              0.336,
              0.096,
              0.103,
              0.189,
              61.823,
              1.177,
              0.364,
              0.217,
              0.287,
              0.014,
              0.01,
              0.033,
              0.011,
              0.006
          ]
      },
      {
          "310": [
              1617753600000,
              225.6000061035156,
              221.3999938964844,
              224.1999969482422,
              222,
              24885227,
              222,
              8.567,
              17.489,
              5.753,
              1.297,
              0.599,
              0.25,
              0.336,
              0.095,
              0.104,
              0.189,
              61.823,
              1.178,
              0.402,
              0.217,
              0.276,
              0.014,
              0.01,
              0.033,
              0.012,
              0.006
          ]
      },
      {
          "311": [
              1617840000000,
              225,
              220.8000030517578,
              221.8000030517578,
              223,
              25337633,
              223,
              8.578,
              17.488,
              5.74,
              1.297,
              0.605,
              0.254,
              0.335,
              0.094,
              0.106,
              0.187,
              61.823,
              1.176,
              0.401,
              0.217,
              0.279,
              0.013,
              0.01,
              0.033,
              0.011,
              0.005
          ]
      },
      {
          "312": [
              1617926400000,
              223,
              216.8000030517578,
              223,
              218,
              22699587,
              218,
              8.609,
              17.492,
              5.697,
              1.295,
              0.604,
              0.254,
              0.337,
              0.094,
              0.108,
              0.188,
              61.823,
              1.175,
              0.401,
              0.216,
              0.279,
              0.013,
              0.01,
              0.032,
              0.011,
              0.006
          ]
      },
      {
          "313": [
              1618185600000,
              237.6000061035156,
              226.6000061035156,
              230,
              232.1999969482422,
              77835113,
              232.1999969482422,
              8.615,
              17.498,
              5.67,
              1.296,
              0.605,
              0.251,
              0.338,
              0.094,
              0.108,
              0.188,
              61.823,
              1.171,
              0.388,
              0.215,
              0.278,
              0.014,
              0.01,
              0.032,
              0.01,
              0.006
          ]
      },
      {
          "314": [
              1618272000000,
              241.6000061035156,
              232.3999938964844,
              240,
              233.1999969482422,
              35951883,
              233.1999969482422,
              8.629,
              17.487,
              5.666,
              1.301,
              0.6,
              0.256,
              0.34,
              0.094,
              0.111,
              0.189,
              61.823,
              1.175,
              0.381,
              0.215,
              0.276,
              0.014,
              0.01,
              0.032,
              0.01,
              0.006
          ]
      },
      {
          "315": [
              1618358400000,
              238,
              235,
              236.6000061035156,
              237.8000030517578,
              21526494,
              237.8000030517578,
              8.633,
              17.485,
              5.623,
              1.307,
              0.602,
              0.3,
              0.336,
              0.093,
              0.106,
              0.186,
              61.823,
              1.174,
              0.395,
              0.213,
              0.284,
              0.012,
              0.01,
              0.033,
              0.01,
              0.006
          ]
      },
      {
          "316": [
              1618444800000,
              234.8000030517578,
              230.6000061035156,
              234,
              233.8000030517578,
              20324396,
              233.8000030517578,
              8.632,
              17.496,
              5.63,
              1.317,
              0.603,
              0.286,
              0.336,
              0.093,
              0.103,
              0.185,
              61.823,
              1.177,
              0.409,
              0.212,
              0.276,
              0.012,
              0.01,
              0.033,
              0.01,
              0.006
          ]
      },
      {
          "317": [
              1618531200000,
              237.3999938964844,
              231.6000061035156,
              232,
              235.6000061035156,
              31491743,
              235.6000061035156,
              8.652,
              17.498,
              5.649,
              1.299,
              0.603,
              0.268,
              0.335,
              0.093,
              0.103,
              0.185,
              61.823,
              1.178,
              0.404,
              0.212,
              0.275,
              0.012,
              0.01,
              0.033,
              0.01,
              0.006
          ]
      },
      {
          "318": [
              1618790400000,
              234.3999938964844,
              231.1999969482422,
              232,
              232,
              24351578,
              232,
              8.658,
              17.478,
              5.652,
              1.298,
              0.6,
              0.268,
              0.336,
              0.093,
              0.103,
              0.184,
              61.82,
              1.177,
              0.412,
              0.211,
              0.273,
              0.012,
              0.01,
              0.033,
              0.01,
              0.006
          ]
      },
      {
          "319": [
              1618876800000,
              228.6000061035156,
              225.1999969482422,
              228.3999938964844,
              228.1999969482422,
              19985710,
              228.1999969482422,
              8.731,
              17.484,
              5.648,
              1.299,
              0.603,
              0.255,
              0.335,
              0.093,
              0.103,
              0.185,
              61.82,
              1.18,
              0.349,
              0.21,
              0.274,
              0.012,
              0.01,
              0.033,
              0.012,
              0.006
          ]
      },
      {
          "320": [
              1618963200000,
              222.8000030517578,
              220.1999969482422,
              222,
              221.8000030517578,
              21338590,
              221.8000030517578,
              8.743,
              17.48,
              5.655,
              1.291,
              0.6,
              0.253,
              0.336,
              0.092,
              0.104,
              0.185,
              61.82,
              1.18,
              0.346,
              0.209,
              0.275,
              0.012,
              0.01,
              0.033,
              0.013,
              0.006
          ]
      },
      {
          "321": [
              1619049600000,
              224.3999938964844,
              220.6000061035156,
              221.8000030517578,
              221.3999938964844,
              15020535,
              221.3999938964844,
              8.738,
              17.462,
              5.667,
              1.292,
              0.601,
              0.253,
              0.336,
              0.092,
              0.104,
              0.186,
              61.82,
              1.176,
              0.353,
              0.208,
              0.275,
              0.012,
              0.01,
              0.032,
              0.013,
              0.006
          ]
      },
      {
          "322": [
              1619136000000,
              226.6000061035156,
              222.8000030517578,
              223.6000061035156,
              224.3999938964844,
              16197504,
              224.3999938964844,
              8.722,
              17.463,
              5.664,
              1.29,
              0.608,
              0.255,
              0.345,
              0.092,
              0.105,
              0.188,
              61.82,
              1.181,
              0.336,
              0.209,
              0.271,
              0.012,
              0.01,
              0.032,
              0.014,
              0.006
          ]
      },
      {
          "323": [
              1619395200000,
              227.8000030517578,
              224.3999938964844,
              226,
              224.6000061035156,
              14207473,
              224.6000061035156,
              8.723,
              17.433,
              5.989,
              1.29,
              0.61,
              0.261,
              0.346,
              0.092,
              0.104,
              0.188,
              61.82,
              0.866,
              0.346,
              0.208,
              0.27,
              0.012,
              0.01,
              0.032,
              0.014,
              0.006
          ]
      },
      {
          "324": [
              1619481600000,
              228.6000061035156,
              225.3999938964844,
              226,
              226.3999938964844,
              14100733,
              226.3999938964844,
              8.727,
              17.431,
              5.988,
              1.292,
              0.61,
              0.263,
              0.346,
              0.093,
              0.103,
              0.187,
              61.82,
              0.868,
              0.345,
              0.207,
              0.269,
              0.012,
              0.01,
              0.032,
              0.013,
              0.006
          ]
      },
      {
          "325": [
              1619568000000,
              232,
              227,
              228.8000030517578,
              231.6000061035156,
              27538276,
              231.6000061035156,
              8.736,
              17.429,
              5.992,
              1.295,
              0.606,
              0.26,
              0.346,
              0.088,
              0.103,
              0.187,
              61.82,
              0.871,
              0.335,
              0.206,
              0.278,
              0.012,
              0.01,
              0.032,
              0.013,
              0.006
          ]
      },
      {
          "326": [
              1619654400000,
              233.8000030517578,
              230.3999938964844,
              233.8000030517578,
              231.3999938964844,
              13445304,
              231.3999938964844,
              8.741,
              17.428,
              5.993,
              1.295,
              0.606,
              0.259,
              0.345,
              0.089,
              0.104,
              0.186,
              61.82,
              0.872,
              0.331,
              0.205,
              0.277,
              0.012,
              0.01,
              0.033,
              0.015,
              0.006
          ]
      },
      {
          "327": [
              1619740800000,
              227.3999938964844,
              223.3999938964844,
              227,
              225,
              18390472,
              225,
              8.785,
              17.418,
              5.982,
              1.296,
              0.604,
              0.258,
              0.343,
              0.09,
              0.103,
              0.186,
              61.82,
              0.875,
              0.321,
              0.203,
              0.279,
              0.012,
              0.01,
              0.033,
              0.018,
              0.006
          ]
      },
      {
          "328": [
              1620000000000,
              226.8000030517578,
              222.3999938964844,
              225,
              223.8000030517578,
              10743054,
              223.8000030517578,
              8.794,
              17.421,
              5.99,
              1.297,
              0.604,
              0.259,
              0.343,
              0.091,
              0.103,
              0.185,
              61.82,
              0.876,
              0.314,
              0.203,
              0.279,
              0.012,
              0.01,
              0.033,
              0.014,
              0.006
          ]
      },
      {
          "329": [
              1620086400000,
              225.6000061035156,
              223,
              223.3999938964844,
              225.1999969482422,
              8757722,
              225.1999969482422,
              8.806,
              17.419,
              5.983,
              1.296,
              0.608,
              0.258,
              0.344,
              0.091,
              0.103,
              0.185,
              61.82,
              0.877,
              0.302,
              0.202,
              0.278,
              0.012,
              0.01,
              0.033,
              0.014,
              0.006
          ]
      },
      {
          "330": [
              1620172800000,
              222.6000061035156,
              219.3999938964844,
              221.6000061035156,
              220,
              15431071,
              220,
              8.801,
              17.498,
              5.907,
              1.297,
              0.61,
              0.26,
              0.345,
              0.091,
              0.102,
              0.185,
              61.82,
              0.876,
              0.301,
              0.201,
              0.28,
              0.012,
              0.01,
              0.033,
              0.014,
              0.006
          ]
      },
      {
          "331": [
              1620259200000,
              222.8000030517578,
              219.6000061035156,
              220.8000030517578,
              221.6000061035156,
              11577986,
              221.6000061035156,
              8.799,
              17.501,
              5.889,
              1.297,
              0.614,
              0.261,
              0.345,
              0.091,
              0.102,
              0.185,
              61.82,
              0.878,
              0.303,
              0.2,
              0.284,
              0.012,
              0.01,
              0.033,
              0.012,
              0.006
          ]
      },
      {
          "332": [
              1620345600000,
              222.3999938964844,
              219,
              221,
              219.8000030517578,
              15513681,
              219.8000030517578,
              8.818,
              17.475,
              5.836,
              1.299,
              0.614,
              0.262,
              0.348,
              0.091,
              0.105,
              0.186,
              61.82,
              0.874,
              0.349,
              0.201,
              0.278,
              0.012,
              0.01,
              0.033,
              0.012,
              0.006
          ]
      },
      {
          "333": [
              1620604800000,
              221.1999969482422,
              213,
              219.8000030517578,
              215.1999969482422,
              22333260,
              215.1999969482422,
              8.816,
              17.474,
              5.836,
              1.303,
              0.616,
              0.258,
              0.348,
              0.091,
              0.105,
              0.186,
              61.82,
              0.871,
              0.346,
              0.199,
              0.282,
              0.012,
              0.01,
              0.033,
              0.011,
              0.006
          ]
      },
      {
          "334": [
              1620691200000,
              211.3999938964844,
              207,
              210.6000061035156,
              207.6000061035156,
              24014630,
              207.6000061035156,
              8.819,
              17.477,
              5.848,
              1.292,
              0.612,
              0.258,
              0.349,
              0.091,
              0.106,
              0.186,
              61.82,
              0.873,
              0.336,
              0.198,
              0.283,
              0.012,
              0.01,
              0.033,
              0.011,
              0.006
          ]
      },
      {
          "335": [
              1620777600000,
              220.8000030517578,
              214,
              215,
              220.1999969482422,
              31199699,
              220.1999969482422,
              8.813,
              17.423,
              5.891,
              1.291,
              0.614,
              0.257,
              0.351,
              0.091,
              0.106,
              0.187,
              61.82,
              0.879,
              0.332,
              0.198,
              0.279,
              0.012,
              0.01,
              0.033,
              0.011,
              0.006
          ]
      },
      {
          "336": [
              1620864000000,
              218,
              213.1999969482422,
              215.8000030517578,
              213.1999969482422,
              18348468,
              213.1999969482422,
              8.843,
              17.387,
              5.932,
              1.266,
              0.612,
              0.26,
              0.353,
              0.091,
              0.107,
              0.181,
              61.82,
              0.876,
              0.337,
              0.198,
              0.274,
              0.012,
              0.01,
              0.032,
              0.01,
              0.006
          ]
      },
      {
          "337": [
              1620950400000,
              205.1999969482422,
              200.1999969482422,
              202,
              204.6000061035156,
              52690766,
              204.6000061035156,
              8.881,
              17.384,
              5.898,
              1.269,
              0.613,
              0.257,
              0.352,
              0.091,
              0.106,
              0.181,
              61.82,
              0.884,
              0.334,
              0.197,
              0.279,
              0.012,
              0.01,
              0.032,
              0.01,
              0.006
          ]
      },
      {
          "338": [
              1621209600000,
              208.8000030517578,
              203.1999969482422,
              204.6000061035156,
              205.3999938964844,
              27817583,
              205.3999938964844,
              8.89,
              17.286,
              5.964,
              1.269,
              0.631,
              0.259,
              0.353,
              0.094,
              0.107,
              0.189,
              61.82,
              0.882,
              0.315,
              0.196,
              0.28,
              0.013,
              0.01,
              0.032,
              0.014,
              0.006
          ]
      },
      {
          "339": [
              1621296000000,
              208.6000061035156,
              206.6000061035156,
              207.1999969482422,
              207.6000061035156,
              18530861,
              207.6000061035156,
              8.89,
              17.275,
              5.957,
              1.275,
              0.64,
              0.266,
              0.358,
              0.096,
              0.109,
              0.194,
              61.82,
              0.885,
              0.277,
              0.196,
              0.275,
              0.013,
              0.01,
              0.032,
              0.013,
              0.006
          ]
      },
      {
          "340": [
              1621468800000,
              208,
              204.3999938964844,
              207,
              206,
              30886478,
              206,
              10.26,
              15.898,
              5.948,
              1.28,
              0.635,
              0.264,
              0.357,
              0.096,
              0.11,
              0.197,
              61.82,
              0.888,
              0.273,
              0.195,
              0.272,
              0.013,
              0.01,
              0.032,
              0.012,
              0.006
          ]
      },
      {
          "341": [
              1621555200000,
              210.6000061035156,
              206,
              210.1999969482422,
              208,
              38645780,
              208,
              10.4,
              15.746,
              5.94,
              1.287,
              0.634,
              0.275,
              0.358,
              0.096,
              0.109,
              0.197,
              61.82,
              0.891,
              0.273,
              0.194,
              0.273,
              0.013,
              0.01,
              0.032,
              0.011,
              0.006
          ]
      },
      {
          "342": [
              1621814400000,
              206,
              204,
              206,
              205,
              17159113,
              205,
              10.41,
              15.537,
              6.077,
              1.295,
              0.634,
              0.273,
              0.359,
              0.098,
              0.11,
              0.198,
              61.82,
              0.916,
              0.299,
              0.193,
              0.274,
              0.013,
              0.01,
              0.032,
              0.011,
              0.006
          ]
      },
      {
          "343": [
              1621900800000,
              207,
              203.3999938964844,
              203.6000061035156,
              206,
              23472797,
              206,
              10.523,
              15.407,
              6.061,
              1.304,
              0.632,
              0.6,
              0.359,
              0.097,
              0.11,
              0.199,
              61.82,
              0.568,
              0.333,
              0.193,
              0.281,
              0.013,
              0.01,
              0.033,
              0.011,
              0.006
          ]
      },
      {
          "344": [
              1621987200000,
              208.6000061035156,
              206.3999938964844,
              207.8000030517578,
              207,
              16688924,
              207,
              10.827,
              14.52,
              6.518,
              1.299,
              0.631,
              0.598,
              0.359,
              0.097,
              0.11,
              0.197,
              61.82,
              0.566,
              0.333,
              0.193,
              0.416,
              0.013,
              0.01,
              0.033,
              0.012,
              0.006
          ]
      },
      {
          "345": [
              1622073600000,
              208.8000030517578,
              205,
              205.3999938964844,
              208.8000030517578,
              31113636,
              208.8000030517578,
              11.15,
              14.027,
              6.733,
              1.299,
              0.633,
              0.595,
              0.358,
              0.097,
              0.11,
              0.199,
              61.82,
              0.569,
              0.406,
              0.193,
              0.275,
              0.013,
              0.01,
              0.033,
              0.011,
              0.006
          ]
      },
      {
          "346": [
              1622160000000,
              209.3999938964844,
              206.6000061035156,
              208.3999938964844,
              207.3999938964844,
              24377407,
              207.3999938964844,
              12.154,
              12.87,
              6.76,
              1.432,
              0.633,
              0.593,
              0.36,
              0.097,
              0.109,
              0.199,
              61.82,
              0.568,
              0.405,
              0.193,
              0.275,
              0.013,
              0.01,
              0.033,
              0.011,
              0.006
          ]
      },
      {
          "347": [
              1622419200000,
              211.3999938964844,
              207.8000030517578,
              208.3999938964844,
              211,
              21707496,
              211,
              12.579,
              12.232,
              6.886,
              1.443,
              0.681,
              0.589,
              0.36,
              0.097,
              0.111,
              0.199,
              61.82,
              0.588,
              0.391,
              0.192,
              0.271,
              0.013,
              0.01,
              0.033,
              0.011,
              0.006
          ]
      },
      {
          "348": [
              1622505600000,
              219.6000061035156,
              212.3999938964844,
              213.6000061035156,
              218.1999969482422,
              38847778,
              218.1999969482422,
              12.605,
              12.235,
              6.878,
              1.448,
              0.69,
              0.586,
              0.36,
              0.104,
              0.112,
              0.2,
              61.82,
              0.588,
              0.351,
              0.191,
              0.272,
              0.013,
              0.01,
              0.033,
              0.011,
              0.006
          ]
      },
      {
          "349": [
              1622592000000,
              215.8000030517578,
              212.6000061035156,
              215.8000030517578,
              214.3999938964844,
              31538468,
              214.3999938964844,
              12.602,
              11.902,
              7.128,
              1.483,
              0.681,
              0.586,
              0.359,
              0.104,
              0.109,
              0.2,
              61.82,
              0.608,
              0.4,
              0.191,
              0.277,
              0.013,
              0.01,
              0.033,
              0.01,
              0.006
          ]
      },
      {
          "350": [
              1622678400000,
              213.8000030517578,
              209.6000061035156,
              213.8000030517578,
              210.8000030517578,
              18209307,
              210.8000030517578,
              12.981,
              14.288,
              7.17,
              1.484,
              0.678,
              0.587,
              0.358,
              0.1,
              0.11,
              0.2,
              59.021,
              0.581,
              0.419,
              0.19,
              0.277,
              0.013,
              0.01,
              0.033,
              0.013,
              0.006
          ]
      },
      {
          "351": [
              1622764800000,
              213.8000030517578,
              208.6000061035156,
              208.8000030517578,
              210.1999969482422,
              39982639,
              210.1999969482422,
              13.539,
              13.638,
              7.162,
              1.553,
              0.715,
              0.59,
              0.358,
              0.099,
              0.11,
              0.2,
              59.021,
              0.582,
              0.413,
              0.19,
              0.274,
              0.013,
              0.01,
              0.033,
              0.014,
              0.006
          ]
      },
      {
          "352": [
              1623024000000,
              212.6000061035156,
              209,
              211.6000061035156,
              212,
              23713310,
              212,
              13.689,
              13.288,
              7.362,
              1.553,
              0.69,
              0.609,
              0.359,
              0.099,
              0.112,
              0.201,
              59.021,
              0.591,
              0.407,
              0.19,
              0.28,
              0.013,
              0.01,
              0.033,
              0.014,
              0.006
          ]
      },
      {
          "353": [
              1623110400000,
              212,
              208.8000030517578,
              211.8000030517578,
              210.1999969482422,
              14131162,
              210.1999969482422,
              13.824,
              13.224,
              7.409,
              1.558,
              0.683,
              0.592,
              0.363,
              0.099,
              0.11,
              0.201,
              59.021,
              0.589,
              0.315,
              0.189,
              0.277,
              0.013,
              0.01,
              0.033,
              0.012,
              0.006
          ]
      },
      {
          "354": [
              1623196800000,
              210.3999938964844,
              207.1999969482422,
              209.1999969482422,
              208,
              14721409,
              208,
              13.878,
              18.79,
              7.409,
              1.562,
              0.683,
              0.591,
              0.366,
              0.099,
              0.111,
              0.201,
              53.346,
              0.591,
              0.362,
              0.189,
              0.277,
              0.013,
              0.01,
              0.033,
              0.012,
              0.007
          ]
      },
      {
          "355": [
              1623283200000,
              211.1999969482422,
              206,
              208.8000030517578,
              207.6000061035156,
              15353710,
              207.6000061035156,
              13.974,
              18.662,
              7.414,
              1.564,
              0.689,
              0.592,
              0.366,
              0.099,
              0.111,
              0.201,
              53.346,
              0.591,
              0.367,
              0.189,
              0.279,
              0.013,
              0.01,
              0.034,
              0.012,
              0.007
          ]
      },
      {
          "356": [
              1623369600000,
              208,
              204.3999938964844,
              207.8000030517578,
              205.1999969482422,
              21751657,
              205.1999969482422,
              14.09,
              18.029,
              7.564,
              1.929,
              0.684,
              0.586,
              0.367,
              0.098,
              0.112,
              0.201,
              53.346,
              0.599,
              0.378,
              0.189,
              0.274,
              0.013,
              0.01,
              0.034,
              0.012,
              0.007
          ]
      },
      {
          "357": [
              1623715200000,
              209.6000061035156,
              204.3999938964844,
              209.6000061035156,
              206.3999938964844,
              16521833,
              206.3999938964844,
              14.173,
              17.727,
              7.542,
              2.161,
              0.69,
              0.584,
              0.368,
              0.098,
              0.112,
              0.201,
              53.346,
              0.607,
              0.361,
              0.19,
              0.279,
              0.013,
              0.01,
              0.034,
              0.013,
              0.007
          ]
      },
      {
          "358": [
              1623801600000,
              205.3999938964844,
              203.3999938964844,
              205.3999938964844,
              203.3999938964844,
              15362004,
              203.3999938964844,
              14.184,
              17.638,
              7.578,
              2.158,
              0.69,
              0.587,
              0.369,
              0.099,
              0.114,
              0.202,
              53.346,
              0.645,
              0.376,
              0.19,
              0.279,
              0.013,
              0.01,
              0.034,
              0.013,
              0.007
          ]
      },
      {
          "359": [
              1623888000000,
              204,
              201.1999969482422,
              202,
              203.8000030517578,
              12114454,
              203.8000030517578,
              14.219,
              17.575,
              7.576,
              2.162,
              0.692,
              0.591,
              0.369,
              0.099,
              0.114,
              0.202,
              53.346,
              0.644,
              0.386,
              0.19,
              0.278,
              0.013,
              0.01,
              0.034,
              0.013,
              0.006
          ]
      },
      {
          "360": [
              1623974400000,
              207.6000061035156,
              203.8000030517578,
              204.3999938964844,
              207,
              26572314,
              207,
              14.262,
              17.471,
              7.615,
              2.223,
              0.692,
              0.59,
              0.37,
              0.1,
              0.115,
              0.202,
              53.346,
              0.609,
              0.36,
              0.19,
              0.282,
              0.013,
              0.01,
              0.034,
              0.013,
              0.006
          ]
      },
      {
          "361": [
              1624233600000,
              207.6000061035156,
              203.8000030517578,
              206,
              205.3999938964844,
              14510523,
              205.3999938964844,
              14.29,
              17.422,
              7.651,
              2.219,
              0.693,
              0.59,
              0.371,
              0.1,
              0.114,
              0.203,
              53.346,
              0.608,
              0.355,
              0.19,
              0.283,
              0.013,
              0.01,
              0.034,
              0.013,
              0.006
          ]
      },
      {
          "362": [
              1624320000000,
              204.3999938964844,
              201.8000030517578,
              204,
              203,
              16162775,
              203,
              14.34,
              17.404,
              7.63,
              2.219,
              0.696,
              0.587,
              0.371,
              0.101,
              0.115,
              0.202,
              53.346,
              0.609,
              0.358,
              0.19,
              0.284,
              0.013,
              0.01,
              0.034,
              0.013,
              0.006
          ]
      },
      {
          "363": [
              1624406400000,
              207.6000061035156,
              204.1999969482422,
              205,
              207.1999969482422,
              16558423,
              207.1999969482422,
              14.349,
              17.365,
              7.637,
              2.226,
              0.699,
              0.589,
              0.371,
              0.1,
              0.115,
              0.202,
              53.346,
              0.629,
              0.361,
              0.19,
              0.281,
              0.013,
              0.01,
              0.034,
              0.011,
              0.006
          ]
      },
      {
          "364": [
              1624492800000,
              212.6000061035156,
              209.1999969482422,
              209.6000061035156,
              210.1999969482422,
              16482032,
              210.1999969482422,
              14.351,
              17.204,
              7.771,
              2.223,
              0.697,
              0.592,
              0.371,
              0.1,
              0.117,
              0.201,
              53.346,
              0.633,
              0.381,
              0.19,
              0.283,
              0.013,
              0.01,
              0.034,
              0.01,
              0.006
          ]
      },
      {
          "365": [
              1624579200000,
              216.3999938964844,
              211.1999969482422,
              212.8000030517578,
              215.6000061035156,
              29426102,
              215.6000061035156,
              14.363,
              17.117,
              7.796,
              2.225,
              0.696,
              0.592,
              0.37,
              0.101,
              0.117,
              0.201,
              53.346,
              0.654,
              0.411,
              0.189,
              0.277,
              0.013,
              0.01,
              0.034,
              0.01,
              0.006
          ]
      },
      {
          "366": [
              1624838400000,
              225,
              219.1999969482422,
              220.8000030517578,
              222,
              23248108,
              222,
              14.354,
              17.098,
              7.792,
              2.227,
              0.701,
              0.591,
              0.369,
              0.101,
              0.116,
              0.201,
              53.346,
              0.654,
              0.428,
              0.189,
              0.283,
              0.013,
              0.01,
              0.034,
              0.011,
              0.006
          ]
      },
      {
          "367": [
              1624924800000,
              222.8000030517578,
              218,
              222,
              219.3999938964844,
              22735870,
              219.3999938964844,
              14.46,
              17.043,
              7.784,
              2.224,
              0.699,
              0.592,
              0.366,
              0.103,
              0.117,
              0.2,
              53.346,
              0.643,
              0.41,
              0.189,
              0.277,
              0.013,
              0.01,
              0.034,
              0.011,
              0.006
          ]
      },
      {
          "368": [
              1625011200000,
              222.8000030517578,
              220,
              222,
              220,
              17882516,
              220,
              14.503,
              17.028,
              7.789,
              2.222,
              0.697,
              0.595,
              0.365,
              0.103,
              0.118,
              0.199,
              53.346,
              0.636,
              0.392,
              0.189,
              0.275,
              0.013,
              0.01,
              0.035,
              0.012,
              0.006
          ]
      },
      {
          "369": [
              1625184000000,
              216.2,
              210.6,
              216.2,
              212,
              27196400,
              212,
              14.51,
              17.029,
              7.762,
              2.216,
              0.705,
              0.589,
              0.364,
              0.108,
              0.118,
              0.2,
              53.346,
              0.651,
              0.424,
              0.188,
              0.278,
              0.013,
              0.01,
              0.035,
              0.011,
              0.006
          ]
      },
      {
          "370": [
              1625443200000,
              209.8,
              205,
              209,
              206,
              21390700,
              206,
              14.521,
              17.026,
              7.765,
              2.203,
              0.707,
              0.591,
              0.363,
              0.108,
              0.118,
              0.2,
              53.346,
              0.643,
              0.438,
              0.188,
              0.279,
              0.013,
              0.01,
              0.035,
              0.01,
              0.006
          ]
      },
      {
          "371": [
              1625529600000,
              209.4,
              203.6,
              205.2,
              209.4,
              18506500,
              209.4,
              14.562,
              17.04,
              7.734,
              2.204,
              0.703,
              0.591,
              0.364,
              0.109,
              0.119,
              0.2,
              53.346,
              0.645,
              0.425,
              0.188,
              0.267,
              0.013,
              0.009,
              0.035,
              0.009,
              0.006
          ]
      },
      {
          "372": [
              1625616000000,
              207,
              204.4,
              207,
              205.8,
              16935600,
              205.8,
              14.581,
              17.024,
              7.743,
              2.206,
              0.699,
              0.589,
              0.364,
              0.11,
              0.12,
              0.196,
              53.346,
              0.662,
              0.393,
              0.188,
              0.269,
              0.013,
              0.009,
              0.035,
              0.009,
              0.006
          ]
      },
      {
          "373": [
              1625702400000,
              203.2,
              197,
              202.2,
              197.3,
              37789500,
              197.3,
              14.567,
              17.034,
              7.747,
              2.207,
              0.698,
              0.59,
              0.363,
              0.109,
              0.12,
              0.196,
              53.346,
              0.662,
              0.386,
              0.188,
              0.267,
              0.013,
              0.009,
              0.035,
              0.009,
              0.006
          ]
      },
      {
          "374": [
              1625788800000,
              196.6,
              189.9,
              195,
              195.5,
              35815400,
              195.5,
              14.619,
              16.954,
              7.752,
              2.208,
              0.698,
              0.593,
              0.364,
              0.109,
              0.12,
              0.196,
              53.346,
              0.663,
              0.382,
              0.188,
              0.275,
              0.013,
              0.009,
              0.035,
              0.009,
              0.006
          ]
      },
      {
          "375": [
              1626048000000,
              200.8,
              195.3,
              199.3,
              197.4,
              19585700,
              197.4,
              14.642,
              16.846,
              7.78,
              2.205,
              0.702,
              0.59,
              0.37,
              0.109,
              0.12,
              0.198,
              53.346,
              0.664,
              0.382,
              0.189,
              0.294,
              0.013,
              0.01,
              0.035,
              0.009,
              0.006
          ]
      },
      {
          "376": [
              1626134400000,
              207.4,
              197.6,
              199.4,
              205.2,
              30179200,
              205.2,
              14.724,
              16.787,
              7.757,
              2.2,
              0.703,
              0.596,
              0.372,
              0.11,
              0.122,
              0.2,
              53.346,
              0.664,
              0.391,
              0.189,
              0.277,
              0.013,
              0.01,
              0.034,
              0.011,
              0.006
          ]
      },
      {
          "377": [
              1626220800000,
              206.8,
              203.8,
              205.2,
              206.4,
              25764600,
              206.4,
              14.728,
              16.761,
              7.776,
              2.196,
              0.701,
              0.592,
              0.373,
              0.11,
              0.122,
              0.2,
              53.346,
              0.662,
              0.397,
              0.189,
              0.28,
              0.014,
              0.01,
              0.034,
              0.013,
              0.006
          ]
      },
      {
          "378": [
              1626307200000,
              214.2,
              206,
              206.4,
              210.6,
              45293700,
              210.6,
              14.8,
              16.684,
              7.794,
              2.203,
              0.702,
              0.599,
              0.372,
              0.108,
              0.121,
              0.199,
              53.346,
              0.664,
              0.386,
              0.189,
              0.272,
              0.014,
              0.01,
              0.034,
              0.014,
              0.006
          ]
      },
      {
          "379": [
              1626393600000,
              211.6,
              207.8,
              210.4,
              209.4,
              55141200,
              209.4,
              14.819,
              16.597,
              7.813,
              2.204,
              0.731,
              0.592,
              0.371,
              0.108,
              0.121,
              0.199,
              53.346,
              0.66,
              0.396,
              0.188,
              0.271,
              0.013,
              0.01,
              0.034,
              0.017,
              0.006
          ]
      },
      {
          "380": [
              1626652800000,
              205.8,
              201.8,
              205.8,
              202.6,
              43294400,
              202.6,
              14.838,
              16.57,
              7.818,
              2.21,
              0.727,
              0.598,
              0.37,
              0.108,
              0.12,
              0.197,
              53.346,
              0.663,
              0.364,
              0.187,
              0.271,
              0.013,
              0.009,
              0.034,
              0.017,
              0.006
          ]
      },
      {
          "381": [
              1626739200000,
              206.4,
              200.4,
              204,
              202.8,
              14791000,
              202.8,
              14.954,
              16.495,
              7.813,
              2.221,
              0.716,
              0.597,
              0.369,
              0.108,
              0.121,
              0.197,
              53.346,
              0.674,
              0.3,
              0.187,
              0.262,
              0.013,
              0.009,
              0.034,
              0.015,
              0.006
          ]
      },
      {
          "382": [
              1626825600000,
              206,
              201.4,
              205.6,
              203,
              14800300,
              203,
              14.954,
              16.439,
              7.858,
              2.222,
              0.713,
              0.599,
              0.376,
              0.107,
              0.12,
              0.198,
              53.346,
              0.665,
              0.307,
              0.187,
              0.272,
              0.013,
              0.009,
              0.034,
              0.015,
              0.006
          ]
      },
      {
          "383": [
              1626912000000,
              207.6,
              205.4,
              205.6,
              207.4,
              12066100,
              207.4,
              14.979,
              16.324,
              7.932,
              2.222,
              0.693,
              0.6,
              0.375,
              0.107,
              0.121,
              0.198,
              53.346,
              0.682,
              0.319,
              0.187,
              0.272,
              0.013,
              0.009,
              0.034,
              0.012,
              0.006
          ]
      },
      {
          "384": [
              1626998400000,
              208.8,
              203.4,
              208.8,
              205.2,
              11735100,
              205.2,
              15.023,
              16.311,
              7.94,
              2.23,
              0.692,
              0.607,
              0.377,
              0.107,
              0.121,
              0.198,
              53.346,
              0.68,
              0.326,
              0.181,
              0.268,
              0.012,
              0.009,
              0.034,
              0.012,
              0.006
          ]
      },
      {
          "385": [
              1627257600000,
              199.2,
              192,
              199.2,
              192.1,
              43322100,
              192.1,
              15.043,
              16.24,
              7.961,
              2.231,
              0.698,
              0.607,
              0.378,
              0.107,
              0.121,
              0.198,
              53.346,
              0.682,
              0.377,
              0.18,
              0.269,
              0.012,
              0.009,
              0.034,
              0.012,
              0.006
          ]
      },
      {
          "386": [
              1627344000000,
              188.5,
              174.5,
              183.8,
              179.9,
              66752400,
              179.9,
              15.055,
              16.171,
              8.024,
              2.234,
              0.707,
              0.595,
              0.378,
              0.107,
              0.12,
              0.198,
              53.346,
              0.67,
              0.377,
              0.179,
              0.267,
              0.012,
              0.009,
              0.034,
              0.01,
              0.006
          ]
      },
      {
          "387": [
              1627430400000,
              184.6,
              176.2,
              179.5,
              183.2,
              52598800,
              183.2,
              15.109,
              16.077,
              8.052,
              2.242,
              0.71,
              0.597,
              0.381,
              0.106,
              0.121,
              0.199,
              53.346,
              0.689,
              0.342,
              0.179,
              0.264,
              0.012,
              0.01,
              0.034,
              0.01,
              0.006
          ]
      },
      {
          "388": [
              1627516800000,
              197.3,
              189.3,
              191.8,
              197.3,
              57050300,
              197.3,
              15.137,
              16.016,
              8.075,
              2.242,
              0.713,
              0.596,
              0.384,
              0.108,
              0.119,
              0.199,
              53.346,
              0.682,
              0.326,
              0.179,
              0.272,
              0.013,
              0.01,
              0.034,
              0.01,
              0.006
          ]
      },
      {
          "389": [
              1627603200000,
              190.7,
              185.6,
              190.4,
              189,
              26604400,
              189,
              15.131,
              15.914,
              8.051,
              2.228,
              0.713,
              0.607,
              0.384,
              0.108,
              0.12,
              0.199,
              53.467,
              0.675,
              0.323,
              0.178,
              0.29,
              0.012,
              0.01,
              0.034,
              0.011,
              0.006
          ]
      },
      {
          "390": [
              1627862400000,
              193.3,
              187.2,
              189,
              191.9,
              24424100,
              191.9,
              15.223,
              15.843,
              8.084,
              2.227,
              0.719,
              0.605,
              0.381,
              0.112,
              0.121,
              0.2,
              53.451,
              0.668,
              0.312,
              0.176,
              0.295,
              0.012,
              0.01,
              0.034,
              0.01,
              0.005
          ]
      },
      {
          "391": [
              1627948800000,
              195.5,
              187.2,
              195.5,
              193.5,
              25920600,
              193.5,
              15.287,
              15.719,
              8.131,
              2.227,
              0.729,
              0.603,
              0.383,
              0.113,
              0.121,
              0.202,
              53.451,
              0.673,
              0.313,
              0.176,
              0.295,
              0.012,
              0.01,
              0.034,
              0.01,
              0.005
          ]
      },
      {
          "392": [
              1628035200000,
              194.5,
              186.5,
              191.3,
              192.3,
              23029300,
              192.3,
              15.295,
              15.687,
              8.154,
              2.238,
              0.728,
              0.602,
              0.383,
              0.113,
              0.122,
              0.202,
              53.451,
              0.661,
              0.337,
              0.174,
              0.283,
              0.013,
              0.01,
              0.034,
              0.01,
              0.005
          ]
      },
      {
          "393": [
              1628121600000,
              197.6,
              191.8,
              194.9,
              192.5,
              21169900,
              192.5,
              15.51,
              15.49,
              8.156,
              2.241,
              0.726,
              0.593,
              0.382,
              0.112,
              0.122,
              0.202,
              53.451,
              0.658,
              0.329,
              0.173,
              0.285,
              0.012,
              0.01,
              0.034,
              0.01,
              0.005
          ]
      },
      {
          "394": [
              1628208000000,
              194.3,
              190.3,
              192.2,
              193.5,
              15486500,
              193.5,
              15.552,
              15.412,
              8.193,
              2.225,
              0.721,
              0.597,
              0.381,
              0.112,
              0.122,
              0.202,
              53.451,
              0.665,
              0.322,
              0.172,
              0.289,
              0.013,
              0.01,
              0.033,
              0.01,
              0.006
          ]
      },
      {
          "395": [
              1628467200000,
              191.6,
              185.1,
              187,
              188.7,
              28015600,
              188.7,
              15.586,
              15.367,
              8.194,
              2.233,
              0.722,
              0.595,
              0.38,
              0.112,
              0.122,
              0.202,
              53.451,
              0.664,
              0.338,
              0.172,
              0.289,
              0.012,
              0.01,
              0.033,
              0.01,
              0.006
          ]
      },
      {
          "396": [
              1628553600000,
              192.9,
              187.6,
              190,
              192.9,
              17983700,
              192.9,
              15.632,
              15.322,
              8.192,
              2.234,
              0.724,
              0.604,
              0.38,
              0.112,
              0.122,
              0.202,
              53.435,
              0.663,
              0.344,
              0.172,
              0.288,
              0.012,
              0.01,
              0.033,
              0.01,
              0.006
          ]
      },
      {
          "397": [
              1628640000000,
              192.4,
              188.4,
              190.6,
              191.2,
              13796200,
              191.2,
              15.648,
              15.293,
              8.222,
              2.23,
              0.706,
              0.582,
              0.382,
              0.112,
              0.122,
              0.203,
              53.435,
              0.662,
              0.351,
              0.172,
              0.288,
              0.012,
              0.01,
              0.033,
              0.008,
              0.006
          ]
      },
      {
          "398": [
              1628726400000,
              190.8,
              186.4,
              190,
              188.7,
              14517300,
              188.7,
              15.704,
              15.289,
              8.275,
              2.254,
              0.704,
              0.585,
              0.383,
              0.113,
              0.122,
              0.204,
              53.31,
              0.666,
              0.338,
              0.172,
              0.286,
              0.012,
              0.01,
              0.033,
              0.008,
              0.006
          ]
      },
      {
          "399": [
              1628812800000,
              185.8,
              181.5,
              185.5,
              184,
              21527700,
              184,
              15.761,
              15.227,
              8.294,
              2.249,
              0.701,
              0.591,
              0.382,
              0.113,
              0.122,
              0.203,
              53.31,
              0.666,
              0.329,
              0.172,
              0.284,
              0.012,
              0.01,
              0.033,
              0.009,
              0.006
          ]
      },
      {
          "400": [
              1629072000000,
              183.9,
              179.6,
              183.9,
              180.4,
              19542500,
              180.4,
              15.851,
              15.344,
              8.289,
              2.248,
              0.701,
              0.599,
              0.381,
              0.113,
              0.122,
              0.204,
              53.31,
              0.667,
              0.104,
              0.172,
              0.284,
              0.012,
              0.01,
              0.033,
              0.009,
              0.006
          ]
      },
      {
          "401": [
              1629158400000,
              178.9,
              171,
              177.9,
              171.8,
              42641100,
              171.8,
              15.917,
              15.261,
              8.263,
              2.269,
              0.71,
              0.6,
              0.382,
              0.116,
              0.123,
              0.204,
              53.31,
              0.679,
              0.104,
              0.171,
              0.287,
              0.012,
              0.01,
              0.033,
              0.009,
              0.005
          ]
      },
      {
          "402": [
              1629244800000,
              172.7,
              169.4,
              170,
              171.6,
              23291000,
              171.6,
              15.899,
              15.231,
              8.299,
              2.271,
              0.712,
              0.6,
              0.383,
              0.116,
              0.124,
              0.203,
              53.31,
              0.677,
              0.104,
              0.169,
              0.285,
              0.012,
              0.01,
              0.033,
              0.012,
              0.005
          ]
      },
      {
          "403": [
              1629331200000,
              169.4,
              160.3,
              168.6,
              162.1,
              45396300,
              162.1,
              15.988,
              15.114,
              8.304,
              2.281,
              0.704,
              0.603,
              0.386,
              0.115,
              0.127,
              0.207,
              53.31,
              0.676,
              0.104,
              0.169,
              0.284,
              0.012,
              0.01,
              0.033,
              0.012,
              0.005
          ]
      },
      {
          "404": [
              1629417600000,
              160.7,
              155.1,
              158.1,
              157.9,
              54863100,
              157.9,
              16.001,
              15.08,
              8.298,
              2.285,
              0.715,
              0.599,
              0.387,
              0.115,
              0.129,
              0.208,
              53.31,
              0.679,
              0.104,
              0.168,
              0.298,
              0.013,
              0.01,
              0.033,
              0.012,
              0.005
          ]
      },
      {
          "405": [
              1629676800000,
              160,
              151.2,
              155.9,
              152.1,
              69681100,
              152.1,
              16.061,
              14.995,
              8.32,
              2.291,
              0.709,
              0.591,
              0.392,
              0.116,
              0.127,
              0.209,
              53.31,
              0.676,
              0.102,
              0.167,
              0.295,
              0.013,
              0.01,
              0.033,
              0.012,
              0.005
          ]
      },
      {
          "406": [
              1629763200000,
              166.6,
              156.3,
              156.8,
              166.5,
              52107400,
              166.5,
              16.082,
              14.919,
              8.329,
              2.291,
              0.723,
              0.586,
              0.394,
              0.127,
              0.129,
              0.21,
              53.294,
              0.686,
              0.104,
              0.167,
              0.292,
              0.013,
              0.01,
              0.033,
              0.012,
              0.005
          ]
      },
      {
          "407": [
              1629849600000,
              172.3,
              162.4,
              170.8,
              164.1,
              53797800,
              164.1,
              16.104,
              14.8,
              8.348,
              2.3,
              0.738,
              0.594,
              0.396,
              0.129,
              0.132,
              0.211,
              53.294,
              0.67,
              0.104,
              0.167,
              0.293,
              0.013,
              0.01,
              0.033,
              0.012,
              0.006
          ]
      },
      {
          "408": [
              1629936000000,
              165.9,
              160.1,
              164.3,
              162.2,
              25997400,
              162.2,
              16.143,
              14.786,
              8.323,
              2.321,
              0.723,
              0.597,
              0.395,
              0.13,
              0.133,
              0.211,
              53.294,
              0.677,
              0.105,
              0.167,
              0.295,
              0.013,
              0.01,
              0.032,
              0.013,
              0.006
          ]
      },
      {
          "409": [
              1630022400000,
              163.1,
              153.7,
              160.7,
              155.9,
              51566600,
              155.9,
              16.156,
              14.726,
              8.354,
              2.338,
              0.714,
              0.599,
              0.395,
              0.131,
              0.134,
              0.211,
              53.294,
              0.674,
              0.106,
              0.156,
              0.296,
              0.013,
              0.01,
              0.033,
              0.013,
              0.006
          ]
      },
      {
          "410": [
              1630281600000,
              160.1,
              154.3,
              155.9,
              158.2,
              33016800,
              158.2,
              16.195,
              14.669,
              8.347,
              2.354,
              0.718,
              0.599,
              0.393,
              0.132,
              0.134,
              0.212,
              53.294,
              0.672,
              0.107,
              0.156,
              0.295,
              0.013,
              0.01,
              0.033,
              0.013,
              0.006
          ]
      },
      {
          "411": [
              1630368000000,
              165.5,
              154.7,
              158.5,
              165.5,
              49830800,
              165.5,
              16.166,
              14.66,
              8.341,
              2.355,
              0.718,
              0.594,
              0.394,
              0.134,
              0.135,
              0.212,
              53.294,
              0.711,
              0.107,
              0.155,
              0.289,
              0.013,
              0.01,
              0.033,
              0.01,
              0.006
          ]
      },
      {
          "412": [
              1630454400000,
              167.6,
              161.7,
              163.5,
              165.2,
              36318500,
              165.2,
              16.161,
              14.575,
              8.359,
              2.373,
              0.749,
              0.593,
              0.394,
              0.143,
              0.135,
              0.215,
              53.294,
              0.711,
              0.107,
              0.155,
              0.303,
              0.013,
              0.01,
              0.033,
              0.011,
              0.006
          ]
      },
      {
          "413": [
              1630540800000,
              171.9,
              167.6,
              170,
              171,
              32232100,
              171,
              16.157,
              14.524,
              8.382,
              2.425,
              0.753,
              0.587,
              0.394,
              0.144,
              0.136,
              0.213,
              53.279,
              0.696,
              0.107,
              0.155,
              0.298,
              0.013,
              0.01,
              0.033,
              0.011,
              0.005
          ]
      },
      {
          "414": [
              1630627200000,
              168.5,
              164,
              168.4,
              164.9,
              48210700,
              164.9,
              16.182,
              14.497,
              8.388,
              2.434,
              0.756,
              0.59,
              0.391,
              0.148,
              0.137,
              0.212,
              53.279,
              0.692,
              0.108,
              0.142,
              0.298,
              0.013,
              0.01,
              0.033,
              0.011,
              0.006
          ]
      },
      {
          "415": [
              1630886400000,
              168,
              163.8,
              164.5,
              168,
              24264500,
              168,
              16.23,
              14.434,
              8.426,
              2.439,
              0.758,
              0.582,
              0.391,
              0.146,
              0.137,
              0.211,
              53.279,
              0.696,
              0.108,
              0.136,
              0.297,
              0.013,
              0.01,
              0.033,
              0.011,
              0.005
          ]
      },
      {
          "416": [
              1630972800000,
              171.8,
              168.3,
              168.4,
              170,
              24179500,
              170,
              16.157,
              14.449,
              8.419,
              2.447,
              0.771,
              0.581,
              0.391,
              0.147,
              0.138,
              0.212,
              53.279,
              0.691,
              0.109,
              0.136,
              0.31,
              0.013,
              0.01,
              0.034,
              0.011,
              0.005
          ]
      },
      {
          "417": [
              1631059200000,
              173.4,
              169.3,
              170,
              170.9,
              24995900,
              170.9,
              16.17,
              14.417,
              8.458,
              2.443,
              0.775,
              0.582,
              0.391,
              0.147,
              0.139,
              0.214,
              53.279,
              0.675,
              0.109,
              0.133,
              0.298,
              0.013,
              0.01,
              0.034,
              0.012,
              0.005
          ]
      },
      {
          "418": [
              1631145600000,
              166.1,
              160.3,
              165,
              161,
              28379900,
              161,
              16.196,
              14.393,
              8.438,
              2.444,
              0.802,
              0.593,
              0.393,
              0.146,
              0.14,
              0.214,
              53.28,
              0.7,
              0.109,
              0.128,
              0.302,
              0.013,
              0.009,
              0.034,
              0.011,
              0.005
          ]
      },
      {
          "419": [
              1631232000000,
              168.2,
              163.4,
              163.7,
              167.9,
              27766700,
              167.9,
              16.195,
              14.332,
              8.488,
              2.474,
              0.778,
              0.593,
              0.393,
              0.145,
              0.141,
              0.214,
              53.28,
              0.703,
              0.111,
              0.128,
              0.303,
              0.013,
              0.009,
              0.035,
              0.012,
              0.006
          ]
      },
      {
          "420": [
              1631491200000,
              163,
              156.9,
              161.5,
              160.8,
              25905100,
              160.8,
              16.236,
              14.317,
              8.467,
              2.474,
              0.772,
              0.588,
              0.395,
              0.144,
              0.142,
              0.215,
              53.28,
              0.686,
              0.112,
              0.128,
              0.309,
              0.013,
              0.009,
              0.035,
              0.012,
              0.006
          ]
      },
      {
          "421": [
              1631577600000,
              162.1,
              156.4,
              160.4,
              158.3,
              22361500,
              158.3,
              16.36,
              14.184,
              8.467,
              2.486,
              0.762,
              0.599,
              0.395,
              0.144,
              0.141,
              0.215,
              53.28,
              0.688,
              0.111,
              0.127,
              0.309,
              0.013,
              0.009,
              0.035,
              0.011,
              0.006
          ]
      },
      {
          "422": [
              1631664000000,
              156.9,
              153.6,
              155.5,
              154,
              28239200,
              154,
              16.441,
              14.026,
              8.499,
              2.487,
              0.785,
              0.589,
              0.398,
              0.144,
              0.143,
              0.216,
              53.28,
              0.694,
              0.114,
              0.128,
              0.306,
              0.013,
              0.009,
              0.035,
              0.011,
              0.005
          ]
      },
      {
          "423": [
              1631750400000,
              153.9,
              149.1,
              153.6,
              151.5,
              33935100,
              151.5,
              16.46,
              13.971,
              8.551,
              2.495,
              0.784,
              0.585,
              0.398,
              0.143,
              0.143,
              0.218,
              53.278,
              0.688,
              0.114,
              0.128,
              0.302,
              0.014,
              0.009,
              0.035,
              0.01,
              0.005
          ]
      },
      {
          "424": [
              1631836800000,
              155.7,
              150.2,
              150.6,
              154.9,
              30179300,
              154.9,
              16.475,
              13.951,
              8.493,
              2.498,
              0.779,
              0.577,
              0.401,
              0.143,
              0.143,
              0.22,
              53.278,
              0.742,
              0.114,
              0.128,
              0.296,
              0.014,
              0.009,
              0.035,
              0.01,
              0.005
          ]
      },
      {
          "425": [
              1632096000000,
              154.3,
              150.7,
              154.2,
              151.5,
              17981500,
              151.5,
              16.437,
              13.918,
              8.547,
              2.502,
              0.775,
              0.588,
              0.403,
              0.144,
              0.144,
              0.219,
              53.278,
              0.746,
              0.114,
              0.128,
              0.296,
              0.013,
              0.009,
              0.035,
              0.011,
              0.005
          ]
      },
      {
          "426": [
              1632182400000,
              149.5,
              145.5,
              146,
              148.7,
              23944100,
              148.7,
              16.485,
              17.011,
              8.566,
              2.517,
              0.779,
              0.57,
              0.402,
              0.144,
              0.145,
              0.219,
              50.142,
              0.722,
              0.114,
              0.128,
              0.294,
              0.013,
              0.009,
              0.035,
              0.011,
              0.005
          ]
      },
      {
          "427": [
              1632355200000,
              150.8,
              146.4,
              148,
              148.4,
              32529400,
              148.4,
              16.492,
              16.991,
              8.635,
              2.473,
              0.78,
              0.578,
              0.402,
              0.143,
              0.146,
              0.219,
              50.142,
              0.692,
              0.114,
              0.128,
              0.297,
              0.013,
              0.009,
              0.035,
              0.011,
              0.005
          ]
      },
      {
          "428": [
              1632441600000,
              148.3,
              143.8,
              146.7,
              144.3,
              30354900,
              144.3,
              16.514,
              16.948,
              8.646,
              2.467,
              0.783,
              0.589,
              0.403,
              0.145,
              0.149,
              0.222,
              50.142,
              0.687,
              0.116,
              0.128,
              0.298,
              0.013,
              0.012,
              0.035,
              0.011,
              0.005
          ]
      },
      {
          "429": [
              1632700800000,
              145.5,
              141.2,
              142.2,
              141.9,
              31609200,
              141.9,
              16.552,
              16.893,
              8.666,
              2.47,
              0.778,
              0.591,
              0.405,
              0.145,
              0.149,
              0.221,
              50.142,
              0.679,
              0.116,
              0.128,
              0.294,
              0.013,
              0.012,
              0.035,
              0.011,
              0.005
          ]
      },
      {
          "430": [
              1632787200000,
              153,
              144.5,
              145.4,
              150.9,
              42598000,
              150.9,
              16.547,
              16.878,
              8.674,
              2.462,
              0.778,
              0.61,
              0.407,
              0.147,
              0.15,
              0.223,
              50.142,
              0.687,
              0.116,
              0.129,
              0.297,
              0.014,
              0.013,
              0.035,
              0.011,
              0.005
          ]
      },
      {
          "431": [
              1632873600000,
              149.3,
              145.5,
              148.3,
              148.3,
              24903500,
              148.3,
              16.549,
              16.869,
              8.675,
              2.465,
              0.784,
              0.606,
              0.407,
              0.148,
              0.15,
              0.226,
              50.142,
              0.69,
              0.116,
              0.129,
              0.296,
              0.014,
              0.013,
              0.035,
              0.011,
              0.005
          ]
      },
      {
          "432": [
              1632960000000,
              144.9,
              141,
              144.9,
              142.3,
              31604500,
              142.3,
              16.571,
              16.846,
              8.654,
              2.474,
              0.782,
              0.607,
              0.409,
              0.148,
              0.153,
              0.224,
              50.142,
              0.69,
              0.117,
              0.128,
              0.295,
              0.014,
              0.009,
              0.035,
              0.011,
              0.005
          ]
      },
      {
          "433": [
              1633305600000,
              143.5,
              136.4,
              143.5,
              137,
              41840400,
              137,
              16.625,
              16.755,
              8.616,
              2.479,
              0.796,
              0.603,
              0.412,
              0.156,
              0.155,
              0.226,
              50.142,
              0.7,
              0.118,
              0.128,
              0.306,
              0.014,
              0.01,
              0.035,
              0.013,
              0.005
          ]
      },
      {
          "434": [
              1633392000000,
              135.9,
              132,
              132,
              135.3,
              30437300,
              135.3,
              16.702,
              16.725,
              8.499,
              2.479,
              0.812,
              0.604,
              0.418,
              0.161,
              0.152,
              0.228,
              50.142,
              0.723,
              0.118,
              0.129,
              0.309,
              0.014,
              0.01,
              0.035,
              0.012,
              0.005
          ]
      }
  ],
  "agentDict": {
      "C00019": "香港上海匯豐銀行",
      "C00010": "花旗銀行",
      "C00100": "摩根大通銀行",
      "C00039": "渣打銀行",
      "B01161": "瑞銀",
      "B01451": "高盛",
      "C00033": "中國銀行",
      "C00016": "DBS BANK LTD",
      "B01590": "盈透",
      "B01130": "中銀國際",
      "nonCCASS": "不在中央結算",
      "C00093": "BNP PARIBAS",
      "C00074": "德意志銀行",
      "C00002": "交通銀行信託",
      "B01274": "摩根士丹利",
      "C00088": "招商銀行",
      "B01842": "交銀國際",
      "B01376": "大眾",
      "B01143": "海通國際",
      "B01938": "興證國際"
  }
}