export function ETAFavourites() {
  this.favourites = [];

  let favouritesString = localStorage.getItem("ETAFavourites");
  if (favouritesString) {
    this.favourites = JSON.parse(favouritesString);
  }

  this.find = (favourite) => {
    for (let i in this.favourites) {
      let temp = this.favourites[i];
      if (temp.transport_name && favourite.transport_name) {
        if (temp.transport_name === favourite.transport_name) {
          if (temp.transport_name === "KMB") {
            if (temp.route_code === favourite.route_code &&
                temp.route_bound === favourite.route_bound && 
                temp.route_service_type === favourite.route_service_type && 
                temp.stop_code === favourite.stop_code) {
              return i;
            }
          } else if (temp.transport_name === "MTR") {
            if (temp.route_code === favourite.route_code &&
                temp.dest_stop_code === favourite.dest_stop_code &&
                temp.stop_code === favourite.stop_code) {
              return i;
            }
          } else if (temp.transport_name === "LR") {
            if (temp.route_code === favourite.route_code &&
                temp.dest_stop_code === favourite.dest_stop_code &&
                temp.stop_code === favourite.stop_code &&
                temp.stop_seq  === favourite.stop_seq) {
              return i;
            }
          } else if (temp.transport_name === "NWFB") {
            if (temp.route_code === favourite.route_code && 
                temp.route_bound === favourite.route_bound && 
                temp.stop_code === favourite.stop_code) {
              return i;
            }
          } else if (temp.transport_name === "CTB") {
            if (temp.route_code === favourite.route_code && 
                temp.route_bound === favourite.route_bound && 
                temp.stop_code === favourite.stop_code) {
              return i;
            }
          } else if (temp.transport_name === "GMB") {
            if (temp.route_code === favourite.route_code && 
                temp.route_bound === favourite.route_bound && 
                temp.stop_code === favourite.stop_code) {
              return i;
            }
          } else if (temp.transport_name === "NLB") {
            if (temp.route_code === favourite.route_code && 
                temp.route_bound === favourite.route_bound && 
                temp.stop_code === favourite.stop_code) {
              return i;
            }
          } else if (temp.transport_name === "MTRB") {
            if (temp.route_code === favourite.route_code && 
                temp.route_bound === favourite.route_bound && 
                temp.stop_code === favourite.stop_code) {
              return i;
            }
          }
        }
      }
    }
    return -1;
  }

  this.isExist = (favourite) => {
    return this.find(favourite) !== -1;
  }

  this.add = (favourite) => {
    if (!this.isExist(favourite)) {
      this.favourites.push(favourite);
      localStorage.setItem("ETAFavourites", JSON.stringify(this.favourites));
    }
  }

  this.remove = (favourite) => {
    let temp_favourites = [];
    let index = this.find(favourite);
    if (index !== -1) {
      for (let i in this.favourites) {
        if (i !== index) {
          temp_favourites.push(this.favourites[i]);
        }
      }
      this.favourites = temp_favourites;
      localStorage.setItem("ETAFavourites", JSON.stringify(this.favourites));
    } 
  }
}