import React from "react";
import {
  Icon,
  Dropdown,
  Image,
  Button,
  List,
  Segment,
  Sidebar,
} from "semantic-ui-react";
import { useHistory } from "react-router";
import { firebaseLogout } from "./FirebaseLogoutForm";
import { useDispatch } from "react-redux";
import { FireBaseLoginModal } from "./FirebaseLoginForm";

export const MobileAccountButton = (props) => {
  const user = props.user;
  if (!user) {
    let btn = (
      <div style={{ marginBottom: "-7px" }}>
        <Button circular icon="user outline" size="tiny" />
      </div>
    );
    return <FireBaseLoginModal prompt="登入" trigger={btn} />;
  } else {
    return (
      <div style={{ marginBottom: "-6px" }}>
        <Image src={user.photoURL} avatar />
      </div>
    );
  }
};

export const MobileAccountDropDown = ({
  animation,
  direction,
  visible,
  onHide,
  user,
}) => {
  let history = useHistory();
  let dispatch = useDispatch();
  let list;
  if (user) {
    list = (
      <List selection verticalAlign="middle" relaxed="very" size="large">
        <List.Item>
          <List.Icon name="sign-in" size="large" />
          <List.Content>
            <List.Header>您已登入為&nbsp;{user.displayName}</List.Header>
          </List.Content>
        </List.Item>
        <List.Item
          onClick={() => {
            history.push("/favourite");
            onHide();
          }}
        >
          <List.Icon name="star" size="large" />
          <List.Content>
            <List.Header>我的最愛</List.Header>
          </List.Content>
        </List.Item>
        <List.Item
          onClick={() => {
            history.push("/myaccount");
            onHide();
          }}
        >
          <List.Icon name="user" size="large" />
          <List.Content>
            <List.Header>我的帳戶</List.Header>
          </List.Content>
        </List.Item>
        <List.Item
          onClick={() => {
            firebaseLogout(dispatch, history);
            onHide();
          }}
        >
          <List.Icon name="sign-out" size="large" />
          <List.Content>
            <List.Header>登出</List.Header>
          </List.Content>
        </List.Item>
      </List>
    );
  }
  return (
    <Sidebar
      as={Segment}
      animation={animation}
      direction={direction}
      visible={visible}
      onHide={onHide}
    >
      {list}
    </Sidebar>
  );
};

export const AccountDropDown = (props) => {
  const user = props.user;

  let dispatch = useDispatch();
  let history = useHistory();

  if (!user) {
    let btn = (
      <Button.Group>
        <Button>
          <div style={{ color: "rgba(0,0,0,0.9)" }}>登入</div>
        </Button>
      </Button.Group>
    );
    return <FireBaseLoginModal prompt="登入" trigger={btn} />;
  } else {
    let trigger = (
      <div
        style={{
          backgroundColor: "rgba(255,255,255,0.9)",
          padding: "0.4em",
          borderRadius: "5px",
        }}
      >
        <Image src={user.photoURL} avatar />
        &nbsp;&nbsp;{user.displayName}&nbsp;&nbsp;
      </div>
    );

    let header = `您已登入為 ${user.displayName}`;
    let dropdown = (
      <Dropdown
        trigger={trigger}
        labeled
        floating
        pointing="top right"
        button
        icon={null}
      >
        <Dropdown.Menu>
          <Dropdown.Header icon="sign-in" content={header} />
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => history.push("/favourite")}>
            <div style={{ color: "rgba(0,0,0,0.9)" }}>
              <Icon name="star" />
              我的最愛
            </div>
          </Dropdown.Item>
          <Dropdown.Item onClick={() => history.push("/myaccount")}>
            <div style={{ color: "rgba(0,0,0,0.9)" }}>
              <Icon name="user" />
              我的帳戶
            </div>
          </Dropdown.Item>
          <Dropdown.Item onClick={() => firebaseLogout(dispatch, history)}>
            <Icon name="sign-out" />
            登出
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
    return <Button.Group>{dropdown}</Button.Group>;
  }
};
