import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { analytics, functions} from '../../config/fbConfig';
import { logEvent } from "firebase/analytics";
import { httpsCallable } from "firebase/functions";

const initialState = {
  dateList: {},
  status: 'idle',
  error: null,
}

const getDateList = httpsCallable(functions, 'date_list');

export const fetchShareholdingDateListData = createAsyncThunk('index/fetchShareholdingDateList', async () => {
  logEvent(analytics, "select_content", {
    content_type: "shareholding_date_list",
  });
  const result = await getDateList({});
  return (result.data.data)
});

const shareholdingDateListSlice = createSlice({
  name: 'shareholdingDateList',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchShareholdingDateListData.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchShareholdingDateListData.fulfilled]: (state, action) => {
      state.shareholdingDateList = action.payload
      state.status = 'succeeded'
    },
    [fetchShareholdingDateListData.rejected]: (state, action) => {
      state.error = action.payload
      state.status = 'failed'
    },
  },
})

export default shareholdingDateListSlice.reducer
