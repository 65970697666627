import { Button, Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    minHeight: 100,
    fontSize: 24,
    borderRadius: 20,
    marginTop: "5pt",
    marginBottom: "5pt",
  },
  button_grid: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  add_favourite_title: {
    fontSize: '1.28571429rem',
    fontWeight: 700,
  }
}));

export default function RouteShortCut(props) {
  const classes = useStyles();

  const state = props.state;
  const onStateChange = props.onStateChange;

  return (
    <>
      <div className={classes.add_favourite_title}>請選擇路線與車站以加入我的收藏</div>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid className={classes.button_grid} item xs={6} sm={4}>
            <Button className={classes.button} variant="contained" color="primary" size="large" fullWidth onClick={() => onStateChange({
                ...state,
                transport_name: "NEAR",
                route_code: "",
              })}>附近</Button>
          </Grid>
          <Grid className={classes.button_grid} item xs={6} sm={4}>
            <Button className={classes.button} variant="contained" color="primary" size="large" fullWidth onClick={() => onStateChange({
              ...state,
              transport_name: "GMB",
              route_code: "",
            })}>小巴</Button>
          </Grid>
          <Grid className={classes.button_grid} item xs={6} sm={4}>
            <Button className={classes.button} variant="contained" color="primary" size="large" fullWidth onClick={() => onStateChange({
              ...state,
              transport_name: "KMB",
              route_code: "",
            })}>九巴</Button>
          </Grid>
          <Grid className={classes.button_grid} item xs={6} sm={4}>
            <Button className={classes.button} variant="contained" color="primary" size="large" fullWidth onClick={() => onStateChange({
              ...state,
              transport_name: "NWFB",
              route_code: "",
            })}>新巴</Button>
          </Grid>
          <Grid className={classes.button_grid} item xs={6} sm={4}>
            <Button className={classes.button} variant="contained" color="primary" size="large" fullWidth onClick={() => onStateChange({
              ...state,
              transport_name: "CTB",
              route_code: "",
            })}>城巴</Button>
          </Grid>
          <Grid className={classes.button_grid} item xs={6} sm={4}>
            <Button className={classes.button} variant="contained" color="primary" size="large" fullWidth onClick={() => onStateChange({
              ...state,
              transport_name: "MTR",
              route_code: "",
            })}>港鐵</Button>
          </Grid>
          <Grid className={classes.button_grid} item xs={6} sm={4}>
            <Button className={classes.button} variant="contained" color="primary" size="large" fullWidth onClick={() => onStateChange({
              ...state,
              transport_name: "LR",
              route_code: "",
            })}>輕鐵</Button>
          </Grid>
          <Grid className={classes.button_grid} item xs={6} sm={4}>
            <Button className={classes.button} variant="contained" color="primary" size="large" fullWidth onClick={() => onStateChange({
              ...state,
              transport_name: "MTRB",
              route_code: "",
            })}>港鐵巴士</Button>
          </Grid>
          <Grid className={classes.button_grid} item xs={6} sm={4}>
            <Button className={classes.button} variant="contained" color="primary" size="large" fullWidth onClick={() => onStateChange({
              ...state,
              transport_name: "NLB",
              route_code: "",
            })}>新大嶼山巴士</Button>
          </Grid>
        </Grid>
      </div>
    </>
  )
}