import React from "react";
// import '../App.css';
import "./HeroSection.css";
import { Header } from "semantic-ui-react";
import LazyHero from "react-lazy-hero";
// import SearchBar from '../views/CategorySearchBar'
import SearchBar from "../views/CategorySearchBarUS";

function HeroSection(props) {
  const sWidth = props.sWidth;
  const searchBarWidth = sWidth * 0.9 > 800 ? 800 : sWidth * 0.9;
  function onHide() {}

  //   function onHide(){};
  return (
    <div>
      <LazyHero imageSrc="images/hero7.png" color="#000000" opacity={0.55}>
        {/* <div class="moon"></div> */}
        <div style={{ paddingBottom: "25px" }}>
          <Header className="header-1">DaaSHK</Header>
          <Header className="header-2">助你瞬間看清香港數據</Header>
        </div>
        <div style={{ width: searchBarWidth.toString() + "px" }}>
          <SearchBar onHide={onHide} />
        </div>{" "}
        :
      </LazyHero>
    </div>
  );
}

export default HeroSection;
