import React, { useEffect } from 'react';
import './ParticipantList.css';
import { Link } from 'react-router-dom';
import SortableTable from "../../tables/SortableTable";
import { Loader } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux'
import { fetchParticipantListData } from '../../../features/participant/participantListSlice'

export default function ParticipantList() {
  const dispatch = useDispatch();

  const participantListData = useSelector(state => state.participantList);
  const participantListStatus = useSelector(state => state.participantList.status);

  useEffect(() => {
    if (participantListStatus === 'idle') {
      dispatch(fetchParticipantListData());
    } else if (participantListStatus === 'succeeded') {
    }
  }, [participantListStatus, dispatch]);

  const config = {
    col: [
      {content: '編號', align: 'center', width: 4, prefix: '', postfix: '', },
      {content: '名稱', align: 'center', width: 4, prefix: '', postfix: '', },
      {content: '', align: 'center', width: 4, prefix: '', postfix: '', },
      {content: '', align: 'center', width: 4, prefix: '', postfix: '', },
    ],
    advRowCount: 16,
  };

  const sort = {
    col: [
      {type: 'string', sort: true},
      {type: 'string', sort: true},
      {type: '', sort: false},
      {type: '', sort: false},
    ],
    colIndex: 0,
    asc: true,
  };

  const filter = {
    col: [
      {text: '過瀘編號', type: 'match'},
      {text: '過瀘名稱', type: 'match'},
      {text: '', type: ''},
      {text: '', type: ''},
    ],
  };

  const getData = () => {
    let data = [];
    if (participantListStatus === 'succeeded')　{
      if (participantListData.participantList) {
        for (let i in participantListData.participantList) {
          const part_link = "/shareholding/shareholding_by_participant/" + participantListData.participantList[i].participant_code;
          const part_chg_link = "/shareholding/shareholding_change_by_participant/" + participantListData.participantList[i].participant_code;
          data.push([
            participantListData.participantList[i].participant_code,
            participantListData.participantList[i].name,
            <Link to={part_link}>持股</Link>,
            <Link to={part_chg_link}>持股變動</Link>,
          ]);
        }
      }
    }
    return {data: data};
  }

  let data = getData();
  
  return (
    <>
    { !(participantListStatus === 'succeeded') && (<Loader active inline='centered'>Loading</Loader>) }
    { participantListStatus === 'succeeded' && (<SortableTable maxRowPerPage={32} config={config} data={data} sort={sort} filter={filter}></SortableTable>) } 
    </>
  );
}