import { Input, TextField, makeStyles, Button, IconButton } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useState } from 'react';
import { useUser } from '../../../../features/user/userSlice';
import { db } from '../../../../config/fbConfig';
import { doc, setDoc } from "firebase/firestore";

const useStyles = makeStyles(theme => ({
  new_post_container: {
    width: '100%',
  },
  form_container: {
    width: '100%',
    display: 'flex',
    paddingLeft: '10px',
    paddingRight: '10px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid gray',
    borderRadius: '20px',
  },
  file: {
    display: 'none',
  },
  file_button: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '50%',
  },
  form_item: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '5px',
    marginBottom: '5px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'block'
  },
  general_button_container: {
    textAlign: 'left',
  },
  general_button: {
    width: '200px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  close_button: {
    marginLeft: 'auto',
    float: 'right',
  },
  image_list: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
  },
  image_list_item: {
    margin: '5px',
    padding: '5px',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '10px',
    border: '1px solid gray',
  },
  image_list_image: {
    height: '180px',
    objectFit: 'cover',
  },
  close_icon: {
    color: theme.palette.primary.light,
  },
}));

const editUserPost = async (uid, origPost, editPost) => {
  try {
    const postRef = doc(db, "posts", origPost.postID);
    await setDoc(postRef, editPost, {merge: true});
  } catch (error) {
    console.log("editUserPost,Error");
    console.log(editPost);
    console.log(error);
  }
}

export const EditPost = (props) => {
  const reload = props.reload;
  const post = props.post;
  const setEditing = props.setEditing;
  const user = useUser();
  const [ images, setImages ] = useState(post.images);
  const [ title, setTitle ] = useState(post.title);
  const [ content, setContent ] = useState(post.content);
  const [ posting, setPosting ] = useState(false);
  const classes = useStyles();

  const handleTitle = (event) => {
    setTitle(event.target.value);
  };

  const handleContent = (event) => {
    setContent(event.target.value);
  };

  const removeImage = (event, index) => {
    let temp = [...images];
    temp.splice(index, 1);
    setImages(temp);
  };

  const handleImageDescription = (event, index) => {
    let temp = [...images];
    temp[index] = {
      ...temp[index],
      description: event.target.value,
    }
    setImages(temp);
  };

  const handleClick = () => {
    setPosting(true);

    editUserPost(user.uid, post, {
      date: new Date(),
      title: title,
      content: content,
      images: images,
      valid: true,
    }).then(() => {
      setPosting(false);
      setEditing(false);
      reload();
    });
  };

  const canPost = (title.length > 0) && (content.length > 0);

  return (
    <>
      <div className={classes.new_post_container}>
        <div className={classes.form_container}>
          <div className={classes.form_item}>
            <IconButton className={classes.close_button} onClick={(event) => setEditing(false)}>
              <HighlightOffIcon className={classes.close_icon} />
            </IconButton>
          </div>
          <div className={classes.form_item}>
            <TextField
              value={title}
              onChange={handleTitle}
              fullWidth
              label="標題"
              variant="outlined" />
          </div>

          <div className={classes.form_item}>
            <div className={classes.image_list}>
              {images.map((item, index) => (
                <div className={classes.image_list_item} key={index}>
                  <img className={classes.image_list_image} src={item.url} alt={item.description} />
                  <Input 
                    value={item.description}
                    onChange={(event) => handleImageDescription(event, index)}
                    fullWidth
                    label="內容"
                    variant="standard" />
                </div>
              ))}
            </div>
          </div>
          <div className={classes.form_item}>
            <TextField
              value={content}
              onChange={handleContent}
              fullWidth
              label="內容"
              multiline
              rows={4}
              variant="outlined"
              />
          </div>
          <div className={classes.form_item}>
            <div className={classes.general_button_container}>
              <Button className={classes.general_button} disabled={!canPost} onClick={() => handleClick()} variant="contained" color="primary">修改</Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}