import React, {useEffect, useState} from 'react'
import { Container, Header, Loader, Label, Grid, Statistic } from 'semantic-ui-react'
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import MetaTags from 'react-meta-tags';
import axios from 'axios';
import VacPopSummaryContainer from '../charts/VacPopSummaryContainer'
import DisplayAdsBanner from '../ads/DisplayAdsBanner';
import GoogleAd from '../ads/Google_ad';

const TopicsVacPop = () => (
  <>
    <MetaTags>
      <title>香港新冠疫苗接種資訊</title>
      <meta name="description" content='香港新冠疫苗接種劑次' />
      <meta property="og:title" content='香港新冠疫苗接種劑次' />
      <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
    </MetaTags>
    <Container textAlign='center'>
      <DisplayAdsBanner />
    </Container>
    <Container
      style={{
        paddingTop: "25px",
        paddingBottom: "25px",
        minHeight: "75vh",
      }}
    >
      <div style={{ textAlign: 'center', paddingTop: '25px' }}>
        <Header as='h1'>香港新冠疫苗接種概覽</Header>
      </div>
      <VacPopSummaryContainer />
      <VacPopChartContainer />
    </Container>
    <Container textAlign='center' style={{paddingTop:'30px', paddingBottom:'0px'}}>
          <GoogleAd 
              client="ca-pub-8753358837449417" 
              slot="2486257452" 
              format="auto" 
              wrapperDivStyle={{
              marginTop: '30px',
              marginBottom: '20px'
              }}
          />
      </Container>
  </>
)

export default TopicsVacPop;


// function VacPopSummaryContainer(){
//   const [data, setData] = useState(null);

//   useEffect(() => {
//       axios.get(`https://vcd.cvpss.hk/data/summary.json`)
//           .then(response => setData(response.data))
//   }, []);

//   return(
//       <div>
//           <div style={{ textAlign: 'center', paddingTop: '25px' }}>
//             <Header as='h1'>香港新冠疫苗接種概覽</Header>
//           </div>
//           {data !== null ? <SummaryView data = {data}/> : <Loader active inline='centered' > Loading</Loader> }
//       </div>
//   )
// }

function VacPopChartContainer(){
    const [data, setData] = useState(null);

    useEffect(() => {
        axios.get(`https://vcd.cvpss.hk/data/bar_vaccination_date.json`)
            .then(response => setData(response.data))
    }, []);

    return(
        <div>
            <div style={{ textAlign: 'center', paddingTop: '50px' }}>
              <Header as='h1'>香港新冠疫苗接種人口</Header>
            </div>
            {data !== null ? <ChartView data = {data}/> : <Loader active inline='centered' > Loading</Loader> }
        </div>
    )
}

function ChartView(data){
    const chartData = data.data
    const [dataSet, setDataSet] = useState(1);
  
    var firstDoseB = [],
        firstDoseS = [],
        firstDoseT = [],
        SecondDoseB = [],
        SecondDoseS = [],
        SecondDoseT = [],
        dataLength = chartData.length,
        i = 0;

    for (i; i < dataLength; i += 1) {
        let d = chartData[i]
        firstDoseB.push( [Date.parse(d.date), d.firstDose.cumulative.biontech] )
        firstDoseS.push( [Date.parse(d.date), d.firstDose.cumulative.sinovac] )
        firstDoseT.push( [Date.parse(d.date), d.firstDose.cumulative.total] )
        SecondDoseB.push( [Date.parse(d.date), d.secondDose.cumulative.biontech] )
        SecondDoseS.push( [Date.parse(d.date), d.secondDose.cumulative.sinovac] )
        SecondDoseT.push( [Date.parse(d.date), d.secondDose.cumulative.total] )
    }
    const lastUpdate = chartData[dataLength-1].date

    return(
        <div>
            <div style={{ textAlign:"center", paddingTop:"15px", paddingBottom: "15px" }}>
                {/* 時期: */}
                <Label color={dataSet === 0 ? 'blue' : null} onClick={() => setDataSet(0)} basic style={{ marginLeft: '.5rem' }}>
                    接種第一針的人口
                </Label>
                <Label color={dataSet === 1 ? 'blue' : null} onClick={() => setDataSet(1)} basic>
                    接種第二針的人口
                </Label>
            </div>
            
            <div>
                { dataSet === 0 ?  
                    <VacPopChart data={{ B:firstDoseB, S: firstDoseS, T:firstDoseT}}/> :
                    <VacPopChart data={{ B:SecondDoseB, S: SecondDoseS, T:SecondDoseT}}/>
                }
            </div>
            <div style={{textAlign:'right'}}>
                <Header as="h5" textAlign='right' color='grey'> 數據截至 {lastUpdate} 晚上8時  <br/>
                    數據來源: 
                    <a target="_blank" rel="noopener noreferrer" href={'https://www.covidvaccine.gov.hk/zh-HK/dashboard'}>
                        covidvaccine.gov.hk
                    </a>
                </Header>
            </div>
      </div>

    )
}

function VacPopChart(data){

    const options = {
        chart: {
        //   type: 'spline'
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        xAxis: {
            type: 'datetime',
            // dateTimeLabelFormats: { // don't display the dummy year
            //     month: '%e-%b',
            //     year: '%b'
            // },
            dateTimeLabelFormats: { // don't display the dummy year
              month: '%d/%m',
              year: '%b'
            },
            title: {
                text: '日期 (日/月)'
            }
        },
        yAxis: {
            title: {
                text: '接種人口 (百萬)'
            },
            min: 0
        },
        tooltip: {
            headerFormat: '<b>{point.x:%m月%d日}</b><br>',
            pointFormat: '<b>{series.name}</b> : {point.y:,.0f} <br>',
            split: true
        },
    
        plotOptions: {
            series: {
                marker: {
                    enabled: false
                }
            }
        },
    
        // var colors: ['#6CF', '#39F', '#06C', '#036', '#000']
    
        series: [
          {
            name: "復必泰",
            data: data.data.B,
            color: '#24779C',
          },{
            name: "科興",
            data: data.data.S,
            color: '#EE7304',
          },{
            name: "總數",
            data: data.data.T,
            color: '#008A85',
          },      
        ],
        credits: {
          text: 'DaaSHK',
          href: 'https://DaaSHK.com/',
          style: {
            fontSize: '1em'
          },
        },
      };
    
      return (
        <div >
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
          />
        </div>
      )
}
