import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  arrayUnion,
  arrayRemove,
  collection,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { db, analytics } from "../../config/fbConfig";
import _ from "lodash";
// import { nanoid } from "@reduxjs/toolkit";
// import { parseISO } from "date-fns";
// import { logEvent } from "firebase/analytics";

const initialState = {
  filters: [],
  data: {},
  active: "",
  activeFilter: "",
  status: "idle",
  error: null,
};

export const fetchFilters = createAsyncThunk(
  "zoom/fetchFilters",
  async (uid) => {
    console.log("fetch Filters");
    let zoomRef = collection(db, "zoom");
    const snapshot = await getDoc(doc(zoomRef, uid));
    // console.log(_.values(snapshot.data().zoomFilters));
    // console.log("hihi");
    return _.values(snapshot.data().zoomFilters);
  }
);

export const addFilters = createAsyncThunk("zoom/addFilters", async (data) => {
  console.log("addFilter");
  console.log(data);
  let zoomRef = collection(db, "zoom");
  try {
    await updateDoc(doc(zoomRef, data.uid), {
      zoomFilters: arrayUnion(data.newFilter),
    });
    return data.newFilter;
  } catch (err) {
    alert(err);
  }
});

export const editFilters = createAsyncThunk(
  "zoom/editFilters",
  async (data) => {
    let zoomRef = collection(db, "zoom");
    console.log("start send edit");
    console.log(data.oldFilter);
    console.log(data.newFilter);
    try {
      await updateDoc(doc(zoomRef, data.uid), {
        zoomFilters: arrayRemove(data.oldFilter),
      })
        .then(
          updateDoc(doc(zoomRef, data.uid), {
            zoomFilters: arrayUnion(data.newFilter),
          })
        )
        .then(console.log("done done"));
      return data.newFilter;
    } catch (err) {
      alert(err);
    }
  }
);

export const removeFilters = createAsyncThunk(
  "zoom/removeFilters",
  async (data) => {
    let zoomRef = collection(db, "zoom");
    try {
      await updateDoc(doc(zoomRef, data.uid), {
        zoomFilters: arrayRemove(data.filter),
      });
      return data.filter;
    } catch (err) {
      alert(err);
    }
  }
);

const zoomSlice = createSlice({
  name: "zoom",
  initialState,
  reducers: {
    toIdle(state, action) {
      state.status = "idle";
    },
    setActive(state, action) {
      console.log("setActive");
      console.log(action.payload);
      state.active = action.payload;
    },
  },
  extraReducers: {
    [fetchFilters.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchFilters.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.filters.push(...action.payload);
    },
    [fetchFilters.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [addFilters.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.filters.push(...[action.payload]);
    },
    [removeFilters.pending]: (state, action) => {
      state.status = "loading";
      console.log("loading");
    },
    [removeFilters.fulfilled]: (state, action) => {
      state.status = "succeeded";
      console.log(action.payload);
      state.filters = state.filters.filter((i) => i.id !== action.payload.id);
    },
    [editFilters.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.filters = state.filters.filter((i) => i.id !== action.payload.id);
      state.filters.push(...[action.payload]);
    },
  },
});

export const { toIdle, setActive } = zoomSlice.actions;
export default zoomSlice.reducer;

export const selectAllFilters = (state) => state.zoom.filters;

export const selectFilterById = (state, id) => {
  return state.zoom.filters.find((filter) => filter.id === id);
};
