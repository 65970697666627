import React from "react";
import { Container, Header, Popup, Icon, Grid } from "semantic-ui-react";
import { FavBtn } from "components/widget/MyFav";

function StockInfoCard(info) {
  return (
    <div>
      <Container>
        {/* <Header as='h2' dividing> */}
        <Header as="h2">
          <Grid>
            <Grid.Column floated="left" width={10}>
              {info.info.name_zh} ({info.info.no})
              <Header.Subheader>
                {info.info.name_en}
                <Header as="h6">
                  <p> 數據至: {info.info.lastUpdate.slice(0, 10)} </p>
                </Header>
              </Header.Subheader>
            </Grid.Column>
            <Grid.Column floated="right" textAlign="right" width={5}>
              <span style={{ margin: "5px" }}>{FavBtn(info.info.no)}</span>
              <Popup
                on="click"
                pinned
                position="bottom right"
                size="large"
                trigger={
                  <Icon size="small" color="grey" name="question circle" />
                }
              >
                <p>
                  <span style={{ color: "#0072ce" }}>藍線</span>
                  為個別股票在中央結算系統紀錄的<sup>*</sup>參與者數目變化。
                </p>
                <p>
                  觀察過去股價及參與者數目變化能幫助投資者對未來股價作出預測
                  {/* <Link to="/about">
                    (例子)
                  </Link> */}
                </p>
                <p>
                  <span style={{ color: "red" }}>紅</span>/
                  <span style={{ color: "#EB7E30" }}>橙</span>/
                  <span style={{ color: "#F8C753" }}>黃</span>/
                  <span style={{ color: "purple" }}>紫</span>
                  線分別代表股票頭3/5/10大持券商合共/港股通的持股比例
                  (不在中央結算的持股合算為一個持券商)
                </p>

                <p>
                  <span style={{ color: "grey" }}>灰</span>/
                  <span style={{ color: "brown" }}>啡</span>/
                  <span style={{ color: "black" }}>黑</span>
                  線:分別代表不在中央結算(nonCCASS)/不願意披露的投資者(NCIP)的持股比例及總發行股份
                </p>

                <p>
                  <sup>*</sup>參與者為願意披露其持股紀錄的投資戶口持有人
                  {/* <a target="_blank" rel="noopener noreferrer" href='https://www.hkexnews.hk/sdw/search/partlist_c.aspx?sortby=partid&shareholdingdate'> (HKEX參與者名錄) </a> */}
                </p>
              </Popup>
            </Grid.Column>
          </Grid>
        </Header>
      </Container>
    </div>
  );
}

function StockInfoCardUS(props) {
  const { info } = props;
  return (
    <div>
      <Container>
        <Header as="h2">
          <Grid>
            <Grid.Column floated="left" width={10}>
              {info.stockCode} ({info.stockCode})
              <Header.Subheader>
                {info.stockCode}
                <Header as="h6">
                  <p> 數據至: {info.lastUpdate} </p>
                </Header>
              </Header.Subheader>
            </Grid.Column>
            <Grid.Column floated="right" textAlign="right" width={5}>
              <span style={{ margin: "5px" }}>{FavBtn(info.stockCode)}</span>
            </Grid.Column>
          </Grid>
        </Header>
      </Container>
    </div>
  );
}

export default StockInfoCard;
export { StockInfoCardUS };
