import React, { useEffect, useState } from 'react';
import './DateList.css';
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../config/fbConfig"

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { toDateString } from '../../../util/datetime';

export default function StockList() {
  const [ dateList, setDateList ] = useState({list: []});
  const getDateList = httpsCallable(functions, 'date_list');

  useEffect(() => {
    getDateList({})
      .then((result) => {
        setDateList({list: result.data.data});
      })
      .catch((error) => {
      });
  }, []);

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>日期</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dateList.list.map((row) => (
              <TableRow key={row.date}>
                <TableCell component="th" scope="row">
                  <TableCell>{row.date + toDateString(new Date(row.date))}</TableCell>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}