import { TableRow, TableCell, Grid, IconButton } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

export default function RouteRow(props) {
  const route = props.route;

  const state = props.state;
  const onStateChange = props.onStateChange;

  const handleClick = (state) => {
    onStateChange({
      ...state,
      route_code: route.route_code,
      route_name: route.route_name,
      route_bound: route.route_bound,
      route_service_type: route.route_service_type,
    })
  }

  return (
    <TableRow onClick={() => {handleClick(state)}}>
      <TableCell>
        <h3>{ route.route_name + ((route.route_service_type !== "" && route.route_service_type !== "1") ? " 特別班" : "") }</h3>
      </TableCell>
      <TableCell>
        <Grid container spacing={1}>
          <Grid item xs={10}>
            <h4>往{ route.route_dest_name }<br /></h4>
            從{ route.route_src_name }
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={(event) => {event.stopPropagation(); handleClick(state)}} color="primary" component="span">
              <NavigateNextIcon color="primary"></NavigateNextIcon>
            </IconButton>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
}

