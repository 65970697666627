import './ShareholdingByStockTable.css';
import React, { useEffect, useState } from 'react';
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { httpsCallable } from "firebase/functions";
import { Link } from "react-router-dom";
import { Table, Header, Icon, Divider, Loader } from 'semantic-ui-react'
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { useSelector, useDispatch } from 'react-redux'

import { functions } from "../../../config/fbConfig";
import SortableTable from "../SortableTable";
import { toDateString } from '../../../util/datetime'
import { formatSharePercentage, formatShare } from '../../../util/dataFormatter'
import { fetchStockListData } from '../../../features/stock/stockListSlice'
import { fetchParticipantListData } from '../../../features/participant/participantListSlice'
import { fetchShareholdingDateListData } from '../../../features/shareholding/shareholdingDateListSlice'

const getShareholdingByStock = httpsCallable(functions, 'shareholding_by_stock');

export default function ShareholdingByStockTable(props) {
  const dispatch = useDispatch();

  // const stockListData = useSelector(state => state.stockList);
  const stockListStatus = useSelector(state => state.stockList.status);
  const participantListData = useSelector(state => state.participantList);
  const participantListStatus = useSelector(state => state.participantList.status);
  const shareholdingDateListData = useSelector(state => state.shareholdingDateList);
  const shareholdingDateListStatus = useSelector(state => state.shareholdingDateList.status);

  const [ shareholdingByStock, setShareholdingByStock ] = useState({list: []});
  const [ resultLoaded, setResultLoaded ] = useState(false);
  const [ selectDate , setSelectDate] = useState(new Date());
  const [ isMobile, setMobile ] = useState(false);
  const stock_code = props.stockCode;

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return (() => {
        window.removeEventListener("resize", handleResize);
      }
    );
  }, []);

  const handleResize = () => {
    const widths = [window.innerWidth];
    if (window.screen?.width) {
      widths.push(window.screen?.width);
    }
    const width = Math.min(...widths);
    
    var isMobile = false;
    if (width < 800) {
      isMobile = true;
    }
    setMobile(isMobile);
  }

  useEffect(() => {
    if (stockListStatus === 'idle') {
      dispatch(fetchStockListData());
    } else if (stockListStatus === 'succeeded') {
    }
  }, [stockListStatus, dispatch]);

  useEffect(() => {
    if (participantListStatus === 'idle') {
      dispatch(fetchParticipantListData());
    } else if (participantListStatus === 'succeeded') {
    }
  }, [participantListStatus, dispatch]);

  useEffect(() => {
    if (shareholdingDateListStatus === 'idle') {
      dispatch(fetchShareholdingDateListData());
    } else if (shareholdingDateListStatus === 'succeeded') {
    }
  }, [shareholdingDateListStatus, dispatch]);

  let dataReady = false;
  if (stockListStatus === 'succeeded' &&
      participantListStatus === 'succeeded' &&
      shareholdingDateListStatus === 'succeeded') {
    dataReady = true;
  }

  useEffect(() => {
    const updateShareholding = (target_date) => {
      getShareholdingByStock({
        date: toDateString(target_date),
        stock_code: parseInt(stock_code),
        language_code: "zh-hk",
      })
        .then((result) => {
          setResultLoaded(true);
          setShareholdingByStock({list: result.data.data});
          if (result.data.data.length > 0) {
            setSelectDate(new Date(result.data.data[0].date));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    if (!resultLoaded) {
      updateShareholding(selectDate);
    }
  }, [stock_code, resultLoaded, selectDate]);


  const datePickerFilterDate = (filtered_date) => {
    if (dataReady) {
      for (let i in shareholdingDateListData.shareholdingDateList) {
        if (toDateString(new Date(shareholdingDateListData.shareholdingDateList[i].date)) === toDateString(filtered_date)) {
          return true;
        }
      }
    }
    return false;
  };

  const handleDate = (event, data) => {
    if (data.value) {
      if (toDateString(selectDate) === toDateString(data.value)) {
        return;
      }
      if (!datePickerFilterDate(data.value)) {
        return;
      }
      setSelectDate(data.value);
      setResultLoaded(false);
    }
  };

  const config = isMobile ? {
    col: [
      {content: '中介者', align: 'center', width: 8, prefix: '', postfix: '', },
      {content: '持倉', align: 'right', width: 8, prefix: '', postfix: '', },
    ],
    advRowCount: 16,
  } : {
    col: [
      {content: '中介者', align: 'center', width: 5, prefix: '', postfix: '', },
      {content: '持倉(股)', align: 'right', width: 6, prefix: '', postfix: '', },
      {content: '持倉(%)', align: 'right', width: 5, prefix: '', postfix: '', },
    ],
    advRowCount: 16,
  };

  const sort = isMobile ? {
    col: [
      {type: 'string', sort: true},
      {type: 'key_absolute_number', sort: true},
    ],
    colIndex: 1,
    asc: false,
  } : {
    col: [
      {type: 'string', sort: true},
      {type: 'key_absolute_number', sort: true},
      {type: 'key_absolute_number', sort: true},
    ],
    colIndex: 2,
    asc: false,
  };

  const filter = isMobile ? {
    col: [
      {text: '過瀘中介者', type: 'match'},
      {text: '大於', type: 'key_absolute_larger'},
    ],
  } : {
    col: [
      {text: '過瀘中介者', type: 'match'},
      {text: '大於', type: 'key_absolute_larger'},
      {text: '大於', type: 'key_absolute_larger'},
    ],
  };

  const getData = (shareholdingByStock) => {
    let data = [];
    let key = [];
    let postfix = [];
    if (dataReady) {
      for (let i in shareholdingByStock.list) {

        let participantLink = <Link to={'/shareholding/shareholding_by_participant/' + shareholdingByStock.list[i].participant_code}><Icon size='large' inverted name='chart line' color='blue' ></Icon></Link>;
        // participantLink = <></>;

        if (isMobile) {
          data.push([
            participantListData.participantMap[shareholdingByStock.list[i].participant_code].short_name,
            formatSharePercentage(shareholdingByStock.list[i].share_percentage),
          ]);
          key.push([
            '',
            shareholdingByStock.list[i].share_percentage,
          ]);
          postfix.push([
            <><br />{participantLink}</>,
            <><br />({formatShare(shareholdingByStock.list[i].share)})</>,
          ]);
        } else {
          data.push([
            participantListData.participantMap[shareholdingByStock.list[i].participant_code].short_name,
            formatShare(shareholdingByStock.list[i].share),
            formatSharePercentage(shareholdingByStock.list[i].share_percentage),
          ]);
          key.push([
            '',
            shareholdingByStock.list[i].share,
            shareholdingByStock.list[i].share_percentage,
          ]);
          postfix.push([
            <> {participantLink}</>,
            <></>,
            <></>,
          ]);
        }
      }
    }
    return {
      data: data,
      key: key,
      postfix: postfix,
    };
  }

  const getChartData = (shareholdingByStock) => {
    let agent = [];
    let percentage = [];
    if (dataReady && resultLoaded) {
      for (let i in shareholdingByStock.list) {
        if (shareholdingByStock.list[i].share_percentage) {
          if (agent.length < 100) {
            agent.push(participantListData.participantMap[shareholdingByStock.list[i].participant_code].short_name);
            percentage.push(parseInt(shareholdingByStock.list[i].share_percentage * 100) / 100.0);
          }
        }
      }
    }
    return {
      agent: agent,
      percentage: percentage,
    };
  }

  let data = getData(shareholdingByStock);
  let chartData = getChartData(shareholdingByStock);


  var agent = chartData.agent;
  var percentage = chartData.percentage;

  const options = {
    chart: {
      type: 'bar',
      height: 500,
    },
    title: {
      text: '經紀商持股比例',
      style: {
        display: 'none'
      }
    },
    xAxis: {
      categories: agent,
      title: {
        text: null
      },
      labels: {
        formatter: function () {
          var text = this.value,
            formatted = text.length > 15 ? text.substring(0, 15) + '...' : text;

          return '<div class="js-ellipse" style="width:150px; overflow:hidden" title="' + text + '">' + formatted + '</div>';
        },
        align: 'Left',
        y: -15,
        x: 5,
      },
      min: 0,
      max: 9,
      lineWidth: 0
    },
    yAxis: {
      min: 0,
      title: {
        text: '%',
        align: 'high'
      },
      labels: {
        overflow: 'justify'
      },
      gridLineWidth: 0,
      lineWidth: 1,
    },
    tooltip: {
      formatter: function () {
        return '<b>' + this.point.category + '</b><br/>' +
          Highcharts.numberFormat(Math.abs(this.point.y), 2) + '%';
      },
    },
    scrollbar: {
      enabled: true
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
          format: '{y} %'
        },
        groupPadding: 0.3,
      },
    },
    legend: {
      enabled: false
    },
    series: [{
      data: percentage,
      color: '#0072ce',
    }],
    credits: {
      text: 'DaaSHK',
      style: {
        fontSize: '1em'
      },
    }
  };

  let title = "持倉";
  
  return (
    <>
      { !dataReady && (<Loader active inline='centered'>Loading</Loader>) }
      { dataReady && (
        <>
          <Divider horizontal>
            <Header as='h1'>
              {title}
            </Header>
          </Divider>
          <Table>
            <Table.Body>
              <Table.Row>
                <Table.Cell width="3">
                  日期
                </Table.Cell>
                <Table.Cell>
                  <SemanticDatepicker
                    filterDate={datePickerFilterDate}
                    onChange={handleDate}
                    clearable={false}
                    showToday={false}
                    datePickerOnly={true}
                    value={selectDate}
                    clearOnSameDateClick={false}
                    disabled={!resultLoaded}
                  />
                </Table.Cell>
                <Table.Cell>
                </Table.Cell>
                <Table.Cell width="10">
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width="3">
                  總共：
                </Table.Cell>
                <Table.Cell>
                  { !resultLoaded && <Loader active inline></Loader> }
                  { resultLoaded && data.data.length }
                </Table.Cell>
                <Table.Cell width="10">
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          {
            resultLoaded &&
            <HighchartsReact
            highcharts={Highcharts}
            options={options}
            />
          }
          <SortableTable maxRowPerPage={32} config={config} data={data} sort={sort} filter={filter}></SortableTable>
        </>
      ) }
    </>
  );
}