import React, { useEffect } from "react";
import "./StockChart.css";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { Loader } from "semantic-ui-react";
import { selectAllRS, fetchRS, toIdle } from "../../features/rs/rsSlice";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";

function roundData3(number) {
  if (number === null) {
    return null;
  } else {
    return Math.round(number * 1000) / 1000;
  }
}

function roundData1(number) {
  if (number === null) {
    return null;
  } else {
    return Math.round(number * 10) / 10;
  }
}

const fontSizePct = isMobile ? "85%" : "100%";

function zip(arrays) {
  return Array.apply(null, Array(arrays[0].length)).map(function (_, i) {
    return arrays.map(function (array) {
      return array[i];
    });
  });
}

function zipVol(arrays) {
  var volume = [];
  var i = 0;
  var dateLength = arrays[0].length;
  for (i; i < dateLength; i += 1) {
    volume.push({
      x: arrays[0][i], // the date
      y: arrays[1][i], // the volume
      color: volumeBarColor(arrays[2][i], arrays[3][i]),
    });
  }
  return volume;
}

export const RSChart = ({ id }) => {
  const stockId = ("0000" + id).slice(-5);
  const dispatch = useDispatch();
  const rs = useSelector(selectAllRS);
  const rsStatus = useSelector((state) => state.rs.status);
  const error = useSelector((state) => state.rs.error);
  // console.log(rs);
  // console.log(rsStatus);
  // console.log(error);
  useEffect(() => {
    if (rsStatus === "idle") {
      if (true) {
        dispatch(fetchRS(stockId));
      }
    }
  }, [stockId, rsStatus, dispatch]);
  // console.log("first");

  let content;

  if (rsStatus === "loading") {
    content = (
      <div>
        <section className="posts-list">
          <div
            style={{
              padding: "100px",
              margin: "auto",
              alignItems: "center",
              height: "75vh",
              // overflow: "auto",
            }}
          >
            <Loader active inline="centered">
              {" "}
              Loading
            </Loader>
          </div>
        </section>
      </div>
    );
  } else if (rsStatus === "succeeded") {
    const data = rs.find((item) => item.topicInfo.stockCode === stockId);
    if (data) {
      // console.log(data);
      content = <RSChartView data={data} />;
    } else {
      dispatch(toIdle({}));
    }
  } else if (rsStatus === "error") {
    content = (
      <div>
        <section className="posts-list">
          <div>{error}</div>
        </section>
      </div>
    );
  }
  return <>{content}</>;
};

function volumeBarColor(open, close) {
  if (open < close) return "Green";
  if (open > close) return "red";
  return "grey";
}

function RSChartView(props) {
  const topicInfo = props.data.topicInfo;
  const stockStat = props.data;
  let stockMkt = /^\d+$/.test(topicInfo.stockCode[0]) ? "HK" : "US";
  var windowWidth = window.innerWidth;
  // console.log(windowWidth);
  // console.log("this widnowWidth in highchart");
  // const chartHeight =
  //   0.656 * windowWidth < 550
  //     ? 550
  //     : 0.656 * windowWidth > 633
  //     ? 633
  //     : 0.656 * windowWidth;
  const chartHeight =
    0.656 * windowWidth < 600
      ? 600
      : 0.656 * windowWidth > 675
      ? 675
      : 0.656 * windowWidth;

  let data = [];
  let datalength = 0;
  let startDate = new Date();
  startDate.setFullYear(startDate.getFullYear() - 1);
  var groupingUnits = [
    [
      "day", // unit name
      [1], // allowed multiples
    ],
    ["week", [1]],
  ];

  if (stockStat.data) {
    let dates = stockStat.data["date"].map((item) => new Date(item).getTime());
    let index = 0;
    for (index; index < dates.length && dates[index] < startDate; index++);
    datalength = dates.length;
    data.push({
      yAxis: 0,
      type: "ohlc",
      name: topicInfo.stockName,
      useOhlcData: true,
      data: zip([
        dates,
        stockStat.data["open"],
        stockStat.data["high"],
        stockStat.data["low"],
        stockStat.data["close"],
      ]),
    });
    data.push({
      yAxis: 0,
      name: "MA5",
      data: zip([dates, _.map(stockStat.data["sma_5_day"], roundData3)]),
    });
    data.push({
      yAxis: 0,
      name: "MA20",
      data: zip([dates, _.map(stockStat.data["sma_20_day"], roundData3)]),
    });
    data.push({
      yAxis: 0,
      name: "MA50",
      data: zip([dates, _.map(stockStat.data["sma_50_day"], roundData3)]),
    });
    data.push({
      yAxis: 0,
      name: "MA150",
      data: zip([dates, _.map(stockStat.data["sma_150_day"], roundData3)]),
    });
    data.push({
      yAxis: 0,
      name: "MA200",
      data: zip([dates, _.map(stockStat.data["sma_200_day"], roundData3)]),
    });
    data.push({
      yAxis: 1,
      name: "RS",
      data: zip([
        dates.slice(index),
        _.map(stockStat.data["rs"].slice(index), roundData1),
      ]),
    });
    data.push({
      yAxis: 2,
      name: "成交量",
      data: zipVol([
        dates,
        stockStat.data["volume"],
        stockStat.data["open"],
        stockStat.data["close"],
      ]),
    });
    // data.push({
    //   yAxis: 1,
    //   name: "RS",
    //   data: zip([dates, _.map(stockStat.data["rs"], roundData1)]),
    // });
  }

  const options = {
    chart: {
      height: chartHeight,
      // plotBackgroundImage: `${process.env.PUBLIC_URL}/images/watermark.svg`
    },

    rangeSelector: {
      selected: isMobile ? 1 : 2,
      dropdown: "never",
    },

    legend: {
      enabled: true,
      align: "center",
      /* layout: 'vertical', */
      verticalAlign: "bottom",
      x: 0,
      y: 0,
    },

    yAxis: [
      {
        // Primary yAxis
        labels: {
          style: {
            color: "black",
          },
          //align: 'left',
          align: "right",
          x: -2,
        },
        title: {
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        opposite: true,
        // startOnTick: false,
        endOnTick: false,
      },
      {
        // Secondary yAxis
        labels: {
          enabled: false,
        },
        opposite: false,
        endOnTick: false,
        gridLineWidth: 0,
        top: "50%",
        height: "50%",
      },
      {
        opposite: false,
        min: 0,
        labels: {
          style: {
            color: "black",
          },
          align: "left",
          x: 3.5,
        },
        title: {
          text: "",
        },
        top: "75%",
        height: "25%",
        offset: 0,
        gridLineWidth: 0,
        // lineWidth: 2
      },
    ],

    plotOptions: {
      candlestick: {
        lineColor: "red",
        color: "white",
        upLineColor: "black",
        upColor: "Green",
      },
    },

    navigator: {
      series: {
        lineWidth: 1,
        lineColor: "#2F4F4F",
        color: "#2F4F4F",
        fillOpacity: 0.2,
      },
    },

    tooltip: {
      // formatter: function () {
      //   // The first returned item is the header, subsequent items are the
      //   // points
      //   // console.log(this);
      //   return [
      //     "<b>" + Highcharts.dateFormat("%a, %b %d, %Y", this.x) + "</b>",
      //   ].concat(
      //     this.points
      //       ? this.points.map(function (point) {
      //           if (point.series.name === "") {
      //             return (
      //               "Open: " +
      //               point.point.open +
      //               "<br />High: " +
      //               point.point.high +
      //               "<br />Low: " +
      //               point.point.low +
      //               "<br />Close: " +
      //               point.point.close
      //             );
      //           } else if (point.series.name === "RS") {
      //             return null;
      //           } else {
      //             return "<br />" + point.series.name + ": " + point.y;
      //           }
      //         })
      //       : []
      //   );
      // },
      shared: true,
    },

    scrollbar: {
      enabled: true,
    },

    series: [
      {
        type: "candlestick",
        name: data[0]["name"],
        data: data[0]["data"],
        tooltip: {
          enabled: true,
        },
        yAxis: 0,
        dataGrouping: {
          units: groupingUnits,
        },
        showInLegend: false,
      },
      {
        type: "line",
        name: data[1]["name"],
        data: data[1]["data"],
        tooltip: {
          enabled: true,
        },
        yAxis: 0,
        color: "#EB7E30",
        lineWidth: 1.5,
        dataGrouping: {
          units: groupingUnits,
        },
        showInLegend: datalength >= 5 ? true : false,
        visible: isMobile ? false : true,
      },
      {
        type: "line",
        name: data[2]["name"],
        data: data[2]["data"],
        tooltip: {
          enabled: true,
        },
        yAxis: 0,
        color: "#0072ce",
        lineWidth: 1.5,
        dataGrouping: {
          units: groupingUnits,
        },
        visible: datalength >= 20 ? true : false,
      },
      {
        type: "line",
        name: data[3]["name"],
        data: data[3]["data"],
        tooltip: {
          enabled: true,
        },
        yAxis: 0,
        color: "red",
        lineWidth: 1.5,
        dataGrouping: {
          units: groupingUnits,
        },
        visible: datalength >= 50 ? true : false,
      },
      {
        type: "line",
        name: data[4]["name"],
        data: data[4]["data"],
        tooltip: {
          enabled: true,
        },
        yAxis: 0,
        color: "#228B22",
        lineWidth: 1.5,
        dataGrouping: {
          units: groupingUnits,
        },
        visible: datalength >= 150 ? true : false,
      },
      {
        type: "line",
        name: data[5]["name"],
        data: data[5]["data"],
        tooltip: {
          enabled: true,
        },
        yAxis: 0,
        color: "#0CBAA6",
        lineWidth: 1.5,
        dataGrouping: {
          units: groupingUnits,
        },
        visible: datalength >= 200 ? true : false,
      },
      {
        type: "line",
        name: data[6]["name"],
        data: data[6]["data"],
        yAxis: 1,
        color: "purple",
        dataGrouping: {
          units: groupingUnits,
        },
        tooltip: {
          enabled: false,
        },
        dataLabels: {
          enabled: stockMkt === "HK" ? true : false,
          formatter() {
            let points = this.series.points;
            if (this.x === points[points.length - 1].x) {
              return (
                '<span style="color:purple;font-weight:900;font-size:1.2em" >' +
                this.y +
                "</span>"
              );
            }
          },
        },
      },
      {
        type: "column",
        name: data[7]["name"],
        data: data[7]["data"],
        tooltip: {
          enabled: true,
        },
        yAxis: 2,
        color: "grey",
        opacity: 0.6,
        dataGrouping: {
          units: groupingUnits,
        },
        showInLegend: false,
      },
    ],
    credits: {
      text: "DaaSHK",
      href: "https://daashk.com/",
      style: {
        fontSize: "1em",
      },
    },
  };

  return (
    <div className="chart">
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"stockChart"}
        options={options}
      />
      <div style={{ fontSize: fontSizePct }}>
        {stockMkt === "HK" ? (
          <>
            <span style={{ color: "purple" }}>紫線</span>
            :為相對強度評級(RS)之變化{" "}
            <Link to="/topics/rs">(相對強度評級列表)</Link>{" "}
          </>
        ) : (
          <>
            {" "}
            <span style={{ color: "purple" }}>紫線</span>
            :為相對強度(RS) vs S&P 500之變化{" "}
            <Link to="/topics/rs">(相對強度評級列表)</Link>
          </>
        )}
        <br />
      </div>
    </div>
  );
}

function RSChartViewUS(props) {
  const topicInfo = props.data.topicInfo;
  const stockStat = props.data;
  let stockMkt = "US";
  var windowWidth = window.innerWidth;
  const chartHeight =
    0.656 * windowWidth < 600
      ? 600
      : 0.656 * windowWidth > 675
      ? 675
      : 0.656 * windowWidth;

  let data = [];
  let datalength = 0;
  let startDate = new Date();
  startDate.setFullYear(startDate.getFullYear() - 1);
  var groupingUnits = [
    [
      "day", // unit name
      [1], // allowed multiples
    ],
    ["week", [1]],
  ];

  if (stockStat.data) {
    let dates = stockStat.data["date"].map((item) => new Date(item).getTime());
    let index = 0;
    for (index; index < dates.length && dates[index] < startDate; index++);
    datalength = dates.length;
    data.push({
      yAxis: 0,
      type: "ohlc",
      name: topicInfo.stockCode,
      useOhlcData: true,
      data: zip([
        dates,
        stockStat.data["open"],
        stockStat.data["high"],
        stockStat.data["low"],
        stockStat.data["close"],
      ]),
    });
    data.push({
      yAxis: 0,
      name: "MA5",
      data: zip([dates, _.map(stockStat.data["sma_5_day"], roundData3)]),
    });
    data.push({
      yAxis: 0,
      name: "MA20",
      data: zip([dates, _.map(stockStat.data["sma_20_day"], roundData3)]),
    });
    data.push({
      yAxis: 0,
      name: "MA50",
      data: zip([dates, _.map(stockStat.data["sma_50_day"], roundData3)]),
    });
    data.push({
      yAxis: 0,
      name: "MA150",
      data: zip([dates, _.map(stockStat.data["sma_150_day"], roundData3)]),
    });
    data.push({
      yAxis: 0,
      name: "MA200",
      data: zip([dates, _.map(stockStat.data["sma_200_day"], roundData3)]),
    });
    data.push({
      yAxis: 1,
      name: "RS",
      data: zip([
        dates.slice(index),
        _.map(stockStat.data["rs"].slice(index), roundData1),
      ]),
    });
    data.push({
      yAxis: 2,
      name: "RS vs S&P500",
      data: zip([
        dates.slice(index),
        _.map(stockStat.data["rs_to_SP500"].slice(index), roundData1),
      ]),
    });
    data.push({
      yAxis: 3,
      name: "成交量",
      data: zipVol([
        dates,
        stockStat.data["volume"],
        stockStat.data["open"],
        stockStat.data["close"],
      ]),
    });
    // data.push({
    //   yAxis: 1,
    //   name: "RS",
    //   data: zip([dates, _.map(stockStat.data["rs"], roundData1)]),
    // });
  }
  // console.log(data);
  const options = {
    chart: {
      height: chartHeight,
      // plotBackgroundImage: `${process.env.PUBLIC_URL}/images/watermark.svg`
    },

    rangeSelector: {
      selected: isMobile ? 1 : 2,
      dropdown: "never",
    },

    legend: {
      enabled: true,
      align: "center",
      /* layout: 'vertical', */
      verticalAlign: "bottom",
      x: 0,
      y: 0,
    },

    yAxis: [
      {
        // Primary yAxis
        labels: {
          style: {
            color: "black",
          },
          //align: 'left',
          align: "right",
          x: -2,
        },
        title: {
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        opposite: true,
        // startOnTick: false,
        endOnTick: false,
      },
      {
        // Secondary yAxis
        labels: {
          enabled: false,
        },
        opposite: false,
        endOnTick: false,
        gridLineWidth: 0,
        top: "40%",
        height: "60%",
      },
      {
        labels: {
          enabled: false,
        },
        opposite: false,
        endOnTick: false,
        gridLineWidth: 0,
        top: "40%",
        height: "60%",
      },
      {
        opposite: false,
        min: 0,
        labels: {
          style: {
            color: "black",
          },
          align: "left",
          x: 3.5,
        },
        title: {
          text: "",
        },
        top: "75%",
        height: "25%",
        offset: 0,
        gridLineWidth: 0,
        // lineWidth: 2
      },
    ],

    plotOptions: {
      candlestick: {
        lineColor: "red",
        color: "white",
        upLineColor: "black",
        upColor: "Green",
      },
    },

    navigator: {
      series: {
        lineWidth: 1,
        lineColor: "#2F4F4F",
        color: "#2F4F4F",
        fillOpacity: 0.2,
      },
    },

    tooltip: {
      // formatter: function () {
      // The first returned item is the header, subsequent items are the
      // points
      // console.log(this);
      // return [
      //   "<b>" + Highcharts.dateFormat("%a, %b %d, %Y", this.x) + "</b>",
      // ].concat(
      //   this.points
      //     ? this.points.map(function (point) {
      //         if (point.series.name === "") {
      //           return (
      //             "Open: " +
      //             point.point.open +
      //             "<br />High: " +
      //             point.point.high +
      //             "<br />Low: " +
      //             point.point.low +
      //             "<br />Close: " +
      //             point.point.close
      //           );
      //         } else if (point.series.name === "RS") {
      //           return null;
      //         } else {
      //           return "<br />" + point.series.name + ": " + point.y;
      //         }
      //       })
      //     : []
      // );
      // },
      shared: true,
    },

    scrollbar: {
      enabled: true,
    },

    series: [
      {
        type: "candlestick",
        name: data[0]["name"],
        data: data[0]["data"],
        tooltip: {
          enabled: true,
        },
        yAxis: 0,
        dataGrouping: {
          units: groupingUnits,
        },
        showInLegend: false,
      },
      {
        type: "line",
        name: data[1]["name"],
        data: data[1]["data"],
        tooltip: {
          enabled: true,
        },
        yAxis: 0,
        color: "#EB7E30",
        lineWidth: 1.5,
        dataGrouping: {
          units: groupingUnits,
        },
        showInLegend: datalength >= 5 ? true : false,
        visible: isMobile ? false : true,
      },
      {
        type: "line",
        name: data[2]["name"],
        data: data[2]["data"],
        tooltip: {
          enabled: true,
        },
        yAxis: 0,
        color: "#0072ce",
        lineWidth: 1.5,
        dataGrouping: {
          units: groupingUnits,
        },
        visible: datalength >= 20 ? true : false,
      },
      {
        type: "line",
        name: data[3]["name"],
        data: data[3]["data"],
        tooltip: {
          enabled: true,
        },
        yAxis: 0,
        color: "red",
        lineWidth: 1.5,
        dataGrouping: {
          units: groupingUnits,
        },
        visible: datalength >= 50 ? true : false,
      },
      {
        type: "line",
        name: data[4]["name"],
        data: data[4]["data"],
        tooltip: {
          enabled: true,
        },
        yAxis: 0,
        color: "#228B22",
        lineWidth: 1.5,
        dataGrouping: {
          units: groupingUnits,
        },
        visible: datalength >= 150 ? true : false,
      },
      {
        type: "line",
        name: data[5]["name"],
        data: data[5]["data"],
        tooltip: {
          enabled: true,
        },
        yAxis: 0,
        color: "#0CBAA6",
        lineWidth: 1.5,
        dataGrouping: {
          units: groupingUnits,
        },
        visible: datalength >= 200 ? true : false,
      },
      {
        type: "line",
        name: data[6]["name"],
        data: data[6]["data"],
        yAxis: 1,
        color: "purple",
        dataGrouping: {
          units: groupingUnits,
        },
        tooltip: {
          enabled: true,
        },
        dataLabels: {
          enabled: true,
          formatter() {
            let points = this.series.points;
            if (this.x === points[points.length - 1].x) {
              return (
                '<span style="color:purple;font-weight:900;font-size:1.2em" >' +
                this.y +
                "</span>"
              );
            }
          },
        },
      },
      {
        type: "line",
        name: data[7]["name"],
        data: data[7]["data"],
        yAxis: 2,
        color: "black",
        visible: false,
        dataGrouping: {
          units: groupingUnits,
        },
        enableMouseTracking: false,
        states: {
          inactive: {
            enabled: false,
          },
        },
      },
      {
        type: "column",
        name: data[8]["name"],
        data: data[8]["data"],
        tooltip: {
          enabled: true,
        },
        yAxis: 3,
        color: "grey",
        opacity: 0.6,
        dataGrouping: {
          units: groupingUnits,
        },
        showInLegend: false,
      },
      // {
      //   type: "column",
      //   name: data[7]["name"],
      //   data: data[7]["data"],
      //   tooltip: {
      //     enabled: true,
      //   },
      //   yAxis: 2,
      //   color: "grey",
      //   opacity: 0.6,
      //   dataGrouping: {
      //     units: groupingUnits,
      //   },
      //   showInLegend: false,
      // },
    ],
    credits: {
      text: "DaaSHK",
      href: "https://daashk.com/",
      style: {
        fontSize: "1em",
      },
    },
  };

  return (
    <div className="chart">
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"stockChart"}
        options={options}
      />
      <div style={{ fontSize: fontSizePct }}>
        <>
          <span style={{ color: "purple" }}>紫線</span>
          :為相對強度評級(RS)之變化{" "}
          <Link to="/topics/rs">(相對強度評級列表)</Link>{" "}
        </>
        {stockMkt === "US" ? (
          <>
            <br /> <span style={{ color: "black" }}>黑線</span>
            :為相對強度(RS) vs S&P 500之變化{" "}
          </>
        ) : null}
        <br />
      </div>
    </div>
  );
}

export default RSChart;
export { RSChartView, RSChartViewUS };
