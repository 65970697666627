import React, {
  useEffect,
  useState,
  useRef,
  // useReducer,
  // useCallback,
} from "react";
import {
  Table,
  Pagination,
  Container,
  Header,
  Menu,
  Icon,
  Label,
  Button,
  Card,
  Modal,
  Loader,
  Dimmer,
} from "semantic-ui-react";
import _, { filter } from "lodash";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import InFeedAdsWhite from "../../ads/InFeedAdsWhite";
import source from "../../../mock_source";
import PriceLine from "../../charts/PriceLine2";
// import Slider from "@material-ui/core/Slider";
import { formatNumber } from "../../../util/dataFormatter";
// import { Field, reduxForm } from "redux-form";
// import StockChart2T from "../../charts/StockChart2T";
// import InTableChart from "../FavouritePage/InTableChart";
// import RSChartContainer from "../FavouritePage/RSChartContainer";
import StockChartContainer from "../FavouritePage/StockChartContainer";
import { RSChartViewUS } from "../../charts/RSChart";
import RSChartBatch from "../../charts/RSChart3";
import { AddFavModal, FavBtns } from "components/widget/MyFav";
import { change } from "redux-form";
import {
  setDisplayMode,
  setActivePage,
  changeSort,
  showMoreCharts,
} from "features/rs/rsSlice";
import {
  selectAllStock,
  fetchUSStock,
  toIdle,
} from "features/stock/stockSlice";
import { useSelector, useDispatch } from "react-redux";
import store from "Store";
import "./RSTable.css";
import Fuse from "fuse.js";
// import { TestPlanCard } from "../../widget/SubscriptionWidget";

function obj2Array(n) {
  return Object.values(n);
}

function RSTable(props) {
  const {
    data,
    filters,
    allowedBehindPaywall,
    stockSector,
    wishList,
    stockDict,
  } = props;
  const [tableStatus, setTableStatus] = useState("loading");
  var lastUpdate = data.topicInfo.lastUpdate.slice(0, 16);
  var iDict = stockSector.ind.iDict;
  var sDict = stockSector.ind.sDict;
  var cDict = stockSector.ind.cDict;
  const [tableData, setTableData] = useState([]);
  let column2Show = [];
  // console.log(tableData);
  // console.log(filters);
  // console.log(stockDict);
  // console.log("this is filters");

  useEffect(() => {
    // console.log(data.data);
    // console.log("hi");
    var tableData1 = _.filter(data.data, function (d) {
      return d.id;
    }).map((v) => {
      var tabelData1 = data.data;
      // let a = _.find(stockDict, function (o) {
      //   return o.id === v.id;
      // });
      // let ii = ind[d.id];
      return {
        ...v,
        name_zh: "",
        name_en: "",
        // name_zh: a ? a["zh"] : "",
        // name_en: a ? a["en"] : "",
        ind: iDict[v["ind"]],
        sec: sDict[v["sec"]],
        count: cDict[v["count"]],
        turnover: v["turnover"] / 10000,
        cap: v["cap"] / 100000000,
      };
    });
    tableData1 = _.sortBy(tableData1, ["rs"]).reverse();
    // console.log(tableData1);
    // console.log("hi");
    setTableData(tableData1);
    setTableStatus("Done");
  }, []);

  let newData = tableData;
  // console.log(filters);

  if (filters !== undefined) {
    // console.log(filters);
    if (filters.title !== undefined || filters.ticker !== undefined) {
      let filterList = stockDict;
      // old code
      if (filters.title !== undefined) {
        // filterList = _.filter(stockDict, function (d) {
        //   return (
        //     // (typeof d.id === "string" &&
        //     //   d.id.toLowerCase().includes(filters.title.toLowerCase())) ||
        //     (typeof d.zh === "string" &&
        //       d.zh.toLowerCase().includes(filters.title.toLowerCase())) ||
        //     (typeof d.en === "string" &&
        //       d.en.toLowerCase().includes(filters.title.toLowerCase()))
        //   );
        // });
        const fuse = new Fuse(stockDict, {
          shouldSort: true,
          threshold: 0.1,
          location: 0,
          distance: 100,
          keys: [
            { name: "id", weight: 5 },
            { name: "en", weight: 1 },
            { name: "zh", weight: 1 },
          ],
        });
        filterList = fuse.search(filters.title.toLowerCase()).map((object) => {
          return { ...object.item, symbol: object.item.id };
        });
      }
      if (filters.ticker !== undefined) {
        const re = new RegExp("^" + filters.ticker, "i");
        // console.log(re);
        // console.log("fuxxk uou ");
        filterList = filterList.filter((obj) => re.test(obj.id));
      }

      newData = filterList
        .map((v) => {
          let a = _.find(newData, function (o) {
            return o.id === v.id;
          });
          return {
            ...a,
            name_zh: v ? v["zh"] : "",
            name_en: v ? v["en"] : "",
          };
        })
        .filter(function (d) {
          return d.id !== undefined;
        });
      // console.log(newData);
    }

    if (filters["sector"] && filters["sector"] !== "所有") {
      newData = _.filter(newData, function (d) {
        return d.sec === filters["sector"];
      });
    }

    [
      { n: "price", i: "c" },
      { n: "cap", i: "cap" },
      { n: "t", i: "turnover" },
      { n: "rs", i: "rs" },
    ].forEach(function (f, i) {
      if (
        parseFloat(filters[f["n"] + "h"]) > 0 ||
        parseFloat(filters[f["n"] + "l"]) > 0
      ) {
        const low =
          parseFloat(filters[f["n"] + "l"]) > 0
            ? parseFloat(filters[f["n"] + "l"])
            : 0;
        const high =
          parseFloat(filters[f["n"] + "h"]) > 0
            ? parseFloat(filters[f["n"] + "h"])
            : 99999999;
        i = f["i"];
        // console.log(i);
        newData = _.filter(newData, function (d) {
          return d[i] >= low && d[i] <= high;
        });
      }
    });

    if (
      parseFloat(filters["d52wHPctl"]) <= 0 ||
      parseFloat(filters["d52wHPcth"]) <= 0
    ) {
      const low =
        parseFloat(filters["d52wHPctl"]) <= 0
          ? parseFloat(filters["d52wHPctl"])
          : -99999999;
      const high =
        parseFloat(filters["d52wHPcth"]) <= 0
          ? parseFloat(filters["d52wHPcth"])
          : 0;
      var i = "d52wHPct";
      column2Show.push(i);
      // console.log(column2Show);
      newData = _.filter(newData, function (d) {
        return d[i] >= low && d[i] <= high;
      });
    }

    [
      "isMa50lgma150",
      "isMa50lgma200",
      "isMa150lgma200",
      "isPlgma50",
      "isPlgma200",
      "isPlgwma30",
    ].forEach(function (f, i) {
      if (filters[f]) {
        newData = _.filter(newData, function (d) {
          return d[f] === 1;
        });
      }
    });
  }

  return (
    <Container
      style={{
        width: "100%",
        maxWidth: "950px",
        margin: "auto",
        alignItems: "center",
      }}
    >
      <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
        <Header as="h4" textAlign="right" color="grey">
          {" "}
          數據至 : {lastUpdate} <br />
          總數 : {newData.length}{" "}
        </Header>
      </div>
      {tableStatus === "loading" ? (
        <Loader active inline="centered">
          {" "}
          Loading
        </Loader>
      ) : (
        <div>
          <RSTableDisplay
            data={newData}
            stockDict={stockDict}
            column2Show={column2Show}
            allowedBehindPaywall={allowedBehindPaywall}
          />
        </div>
      )}
    </Container>
  );
}

function RSTableDisplay(props) {
  const { data, allowedBehindPaywall, stockDict, column2Show } = props;
  // const [state, dispatch] = useReducer(resultTableReducer, initialState);
  const dispatch = useDispatch();
  // const [newData, setNewData] = useState([]);
  // useEffect(() => {
  //   setNewData(data);
  // }, [data]);
  // const topics = useSelector(selectAllTopic);
  let newData = [];
  const tableSetting = useSelector((state) => state.rs.tableSetting);
  const { displayMode, direction, column } = tableSetting;
  // console.log(data);
  // console.log("data to sort");
  if (direction === "ascending") {
    newData = _.orderBy(data, [column], ["asc"]);
  } else {
    newData = _.orderBy(data, [column], ["desc"]);
  }
  // console.log(newData);
  // console.log("newData Feeded");

  return (
    <>
      <div>
        <Menu pointing secondary compact>
          <Menu.Item
            name="列表"
            active={tableSetting.displayMode === "0"}
            // onClick={() => {
            //   dispatch({ type: "SET_DISPLAYMODE", text: "0" });
            // }}
            onClick={() => {
              dispatch(setDisplayMode("0"));
              dispatch(toIdle());
            }}
          >
            <Icon name="table" />
            列表模式
          </Menu.Item>
          {/* <Menu.Item
            name="圖表"
            active={tableSetting.displayMode === "1"}
            onClick={() => {
              dispatch(setDisplayMode("1"));
              dispatch(toIdle());
            }}
          >
            <Icon name="chart bar" />
            圖表模式
          </Menu.Item> */}
        </Menu>
      </div>

      <div style={{ textAlign: "center" }}>
        {displayMode === "0" ? (
          <SortableTable
            stockDict={stockDict}
            data={newData}
            allowedBehindPaywall={allowedBehindPaywall}
            dispatch={dispatch}
            tableSetting={tableSetting}
            column2Show={column2Show}
          />
        ) : allowedBehindPaywall ? (
          <Modal
            closeIcon
            centered={false}
            size="fullscreen"
            onClose={() => dispatch(setDisplayMode("0"))}
            onOpen={() => dispatch(setDisplayMode("1"))}
            open={displayMode === "1"}
            // trigger={<Button>Basic Modal</Button>}
          >
            <Modal.Header>圖表模式</Modal.Header>
            <Modal.Content image>
              {/* <ChartTable
                  data={newData}
                  allowedBehindPaywall={allowedBehindPaywall}
                  dispatch={dispatch}
                  tableSetting={tableSetting}
                  column2Show={column2Show}
                /> */}
            </Modal.Content>
          </Modal>
        ) : (
          <div style={{ paddingTop: "25px" }}>
            <AskForSubscribe />
          </div>
        )}
      </div>
    </>
  );
}

function SortableTable(props) {
  const allowedBehindPaywall = props.allowedBehindPaywall;
  const scrollRef = useRef(null);
  const [chartLeft, setChartLeft] = useState(0);
  const [chartWidth, setChartWidth] = useState(10);
  const { direction, column, activePage } = props.tableSetting;
  const { data, dispatch, stockDict, column2Show } = props;
  let newData = data;
  if (newData.length < 25 && activePage !== 1) {
    dispatch(setActivePage(1));
  }

  const [selected, setSelected] = React.useState([]);
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const resize = (event) => {
    if (scrollRef && scrollRef.current) {
      setChartWidth(scrollRef.current.clientWidth - 20);
    }
  };
  const handleScroll = (event) => {
    setChartLeft(scrollRef.current.scrollLeft);
  };
  useEffect(() => {
    resize();
    window.addEventListener("resize", resize);
    return () => {
      window.addEventListener("resize", resize);
    };
  }, [scrollRef]);
  useEffect(() => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.addEventListener("scroll", handleScroll);
      return () => {
        if (scrollRef && scrollRef.current) {
          scrollRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [scrollRef]);

  const pos_pagination = isMobile ? "center" : "right";
  // if (direction === "ascending") {
  //   newData = _.orderBy(newData, [column], ["asc"]);
  // } else {
  //   newData = _.orderBy(newData, [column], ["desc"]);
  // }

  const nPerPage = 25;
  const totalPage = Math.ceil(newData.length / nPerPage);
  let newData2 = [];
  newData2 = _.slice(
    newData,
    (activePage - 1) * nPerPage,
    activePage * nPerPage
  );
  let favBtns = FavBtns(newData2.map((d) => d.id));
  // console.log(newData2);

  const handlePaginationChange = (e, { activePage }) => {
    // dispatch({
    //   type: "SET_ACTIVE_PAGE",
    //   text: activePage,
    // });
    dispatch(setActivePage(activePage));
  };

  const topRowHeaderStyle = {
    position: "sticky",
    top: "0px",
    zIndex: "10",
    backgroundColor: "rgb(240,240,240)",
    // fontSize: normalSize[fontSize],
  };
  const firstColHeaderStyle = {
    position: "sticky",
    left: "0px",
    zIndex: "5",
    // backgroundColor: "rgb(240,240,240)",
    backgroundColor: "rgb(255,255,255)",
    // fontSize: normalSize[fontSize],
  };
  const contentStyle = {
    // fontSize: normalSize[fontSize],
    zIndex: "0",
  };
  // const rowHeaderStyle = {
  //   // fontSize: smallSize[fontSize],
  //   color: "grey",
  // };

  return (
    <div
      style={{
        whiteSpace: "nowrap",
        overflowX: "hidden",
        display: "inline-block",
        width: "100%",
        textAlign: "center",
      }}
    >
      <div
        style={{
          overflow: "auto",
          // maxHeight: "85vh",
          overscrollBehavior: "auto",
        }}
        ref={scrollRef}
      >
        <Table sortable selectable celled unstackable compact structured>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                style={{
                  position: "sticky",
                  top: "0px",
                  left: "0px",
                  zIndex: "15",
                  backgroundColor: "rgb(240,240,240)",
                  borderWidth: "0px",
                }}
                textAlign="left"
                rowSpan="2"
                sorted={column === "id" ? direction : null}
                onClick={() => {
                  dispatch(changeSort("id"));
                }}
              >
                名稱
                {isMobile ? <br /> : null}
                (編號)
              </Table.HeaderCell>
              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
                sorted={column === "c" ? direction : null}
                onClick={() => {
                  dispatch(changeSort("c"));
                }}
              >
                價格
              </Table.HeaderCell>
              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
                sorted={column === "rs" ? direction : null}
                onClick={() => {
                  dispatch(changeSort("rs"));
                }}
              >
                RS
              </Table.HeaderCell>
              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
                sorted={column === "rsd10d" ? direction : null}
                onClick={() => {
                  dispatch(changeSort("rsd10d"));
                }}
              >
                RS變化 <br /> (10天)
              </Table.HeaderCell>
              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
                sorted={column === "nc" ? direction : null}
                onClick={() => {
                  dispatch(changeSort("nc"));
                }}
              >
                升跌
              </Table.HeaderCell>
              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
                sorted={column === "pc" ? direction : null}
                onClick={() => {
                  dispatch(changeSort("pc"));
                }}
              >
                升跌(%)
              </Table.HeaderCell>
              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
                sorted={column === "turnover" ? direction : null}
                onClick={() => {
                  dispatch(changeSort("turnover"));
                }}
              >
                成交額
              </Table.HeaderCell>
              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
                sorted={column === "cap" ? direction : null}
                onClick={() => {
                  dispatch(changeSort("cap"));
                }}
              >
                市值
              </Table.HeaderCell>
              {column2Show.includes("d52wHPct") ? (
                <Table.HeaderCell
                  style={topRowHeaderStyle}
                  textAlign="center"
                  rowSpan="2"
                  sorted={column === "d52wHPct" ? direction : null}
                  onClick={() => {
                    dispatch(changeSort("d52wHPct"));
                  }}
                >
                  離52W高%
                </Table.HeaderCell>
              ) : null}
              <Table.HeaderCell
                style={topRowHeaderStyle}
                textAlign="center"
                rowSpan="2"
              >
                <div>
                  <Icon
                    name="star"
                    // style={{ color: "#FFCA28" }}
                  />
                </div>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {newData2.map((d, index) => {
              const isItemSelected = isSelected(d.id);
              let name = _.find(stockDict, function (o) {
                return o.id === d.id;
              });
              // console.log(name);
              return (
                <>
                  {index !== 0 && index % 10 === 0 && (
                    <Table.Row key={index}>
                      <Table.Cell colSpan={7} textAlign="center">
                        <InFeedAdsWhite></InFeedAdsWhite>
                      </Table.Cell>
                    </Table.Row>
                  )}
                  <Table.Row
                    key={d.id}
                    onClick={(event) => handleClick(event, d.id)}
                  >
                    <Table.Cell style={firstColHeaderStyle} textAlign="left">
                      <Icon
                        name={isItemSelected ? "caret down" : "caret right"}
                      />
                      <Link to={"/stock/" + d.id}>
                        {name
                          ? name.zh !== "-"
                            ? name.zh
                            : name.en.length > 20
                            ? name.en.substring(0, 20) + "..."
                            : name.en
                          : null}
                        <br />

                        {d.id}
                        <br />
                      </Link>
                      {d.sec !== undefined && d.sec !== "" ? (
                        <Label
                          size="tiny"
                          basic
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            store.dispatch(
                              change("rstableUS", "sector", d.sec)
                            );
                          }}
                        >
                          {d.sec}
                        </Label>
                      ) : null}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <div style={{ marginBottom: "-0.75rem" }}> ${d.c} </div>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <div style={{ marginBottom: "-0.75rem" }}> {d.rs} </div>
                      <div style={{ width: "100px", margin: "auto" }}>
                        <PriceLine
                          min={0}
                          secondMin={0}
                          mid={d.rs}
                          secondMax={d.rs}
                          max={100}
                          col={"#2471A3"}
                        />
                      </div>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <div style={{ marginBottom: "-0.75rem" }}>
                        {" "}
                        {d.rsd10d}{" "}
                      </div>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <div style={{ marginBottom: "-0.75rem" }}> {d.nc} </div>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <div style={{ marginBottom: "-0.75rem" }}> {d.pc} </div>
                    </Table.Cell>

                    <Table.Cell textAlign="center">
                      <div style={{ marginBottom: "-0.75rem" }}>
                        {" "}
                        {formatNumber(d.turnover * 10000)}{" "}
                      </div>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <div style={{ marginBottom: "-0.75rem" }}>
                        {" "}
                        {/* {d.cap} */}
                        {d.cap === 0 || d.cap === ""
                          ? "-"
                          : formatNumber(d.cap * 100000000)}{" "}
                      </div>
                    </Table.Cell>

                    {column2Show.includes("d52wHPct") ? (
                      <Table.Cell textAlign="center">
                        <div style={{ marginBottom: "-0.75rem" }}>
                          {" "}
                          {d.d52wHPct === 0 ? "-" : d.d52wHPct}
                        </div>
                      </Table.Cell>
                    ) : null}

                    <Table.Cell textAlign="center">
                      {favBtns.get(d.id)}
                    </Table.Cell>
                  </Table.Row>

                  {isItemSelected ? (
                    <Table.Row>
                      <Table.Cell
                        style={{
                          paddingBottom: "10px",
                          paddingTop: "10px",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                        }}
                        colSpan={9}
                      >
                        <div
                          style={{
                            position: "sticky",
                            left: "10px",
                            width: chartWidth,
                            zIndex: "9",
                          }}
                        >
                          {allowedBehindPaywall ? (
                            <InTableChartContainer id={d.id} mode="table" />
                          ) : (
                            <AskForSubscribe />
                          )}
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                </>
              );
            })}
          </Table.Body>
        </Table>
      </div>

      <div
        style={{
          paddingTop: "25px",
          paddingBottom: "25px",
          float: pos_pagination,
        }}
      >
        <Pagination
          boundaryRange={0}
          activePage={activePage}
          size={isMobile ? "small" : "large"}
          siblingRange={1}
          totalPages={totalPage}
          onPageChange={handlePaginationChange}
        />
      </div>
    </div>
  );
}

export const InTableChartContainer = (props) => {
  const stockId = props.id;
  const mode = props.mode;
  return (
    <>
      {
        // mode === "table" ? (
        <RSChartUS id={stockId} />
        // ) : (
        //   <RSChartBatch id={stockId} />
        // )
      }
    </>
  );
};

export const RSChartUS = ({ id }) => {
  const stockId = id;
  const dispatch = useDispatch();
  const stocks = useSelector(selectAllStock);
  const stockStatus = useSelector((state) => state.stock.status);
  const error = useSelector((state) => state.stock.error);
  const [stockData, setStockData] = useState(
    stocks.find((item) => item.topicInfo.no === stockId)
  );
  // console.log(stockData);
  // console.log(rsStatus);
  // console.log(error);
  useEffect(() => {
    if (stockStatus === "idle" && stockData === undefined) {
      if (true) {
        dispatch(fetchUSStock(stockId));
      }
    }
  }, [stockId, stockStatus, dispatch, stockData]);
  // console.log("first");
  let content;

  if (stockData) {
    content = (
      <RSChartViewUS
        data={stocks.find((item) => item.topicInfo.no === stockId)}
      />
    );
  } else {
    if (stockStatus === "loading") {
      content = (
        <div>
          <section className="posts-list">
            <div
              style={{
                padding: "100px",
                margin: "auto",
                alignItems: "center",
                height: "75vh",
                // overflow: "auto",
              }}
            >
              <Loader active inline="centered">
                {" "}
                Loading
              </Loader>
            </div>
          </section>
        </div>
      );
    } else if (stockStatus === "succeeded") {
      let data = stocks.find((item) => item.topicInfo.no === stockId);
      // let stockName = {id: stockId, zh: "" , en:""}
      if (data) {
        setStockData(data);
        // content = <RSChartViewUS data={data} />;
      } else {
        dispatch(toIdle({}));
      }
    } else if (stockStatus === "error") {
      content = (
        <div>
          <section className="posts-list">
            <div>{error}</div>
          </section>
        </div>
      );
    }
  }
  return <>{content}</>;
};

function AskForSubscribe() {
  return (
    <div style={{ margin: "auto" }}>
      <Card centered style={{ textAlign: "center" }}>
        <Card.Content>
          <Card.Header>加入付費版，以體驗進階功能</Card.Header>
        </Card.Content>
        <Card.Content extra>
          <Link to="/subscription">
            <Button basic color="blue">
              了解更多
            </Button>
          </Link>
        </Card.Content>
      </Card>
    </div>
  );
}

export default RSTable;
