import React, { useEffect } from "react";
import { Loader, Container, Header, Button, Table, Icon} from 'semantic-ui-react'
// import _ from 'lodash'
import MetaTags from 'react-meta-tags';
import VaccineTableZh from '../tables/VaccineTableZh'
import { selectAllTopic, fetchTopic, toIdle } from '../../features/topic/topicSlice'
import { useSelector, useDispatch } from 'react-redux'
import DisplayAdsBanner from '../ads/DisplayAdsBanner_vac';
import GoogleAd from '../ads/Google_ad';
import { Link } from 'react-router-dom';
import VacPopSummaryContainer from '../charts/VacPopSummaryContainer'



export const TopicVaccineZh = () => {
  const dispatch = useDispatch()
  const topics = useSelector(selectAllTopic)
  const topicStatus = useSelector((state) => state.topic.status)
  const error = useSelector((state) => state.topic.error)

  useEffect(() => {
    if (topicStatus === 'idle') {
      dispatch(fetchTopic('vaccine_en'))
    }
  }, [topicStatus, dispatch])

  let content

  if (topicStatus === 'loading') {
    content =
      <div>
        <section className="posts-list">
          <div
            style={{
              padding: "100px",
              margin: "auto",
              alignItems: "center",
              height: "75vh",
              // overflow: "auto",
            }}
          >
            <Loader active inline='centered' > Loading</Loader>
          </div>
        </section>
      </div>
  } else if (topicStatus === 'succeeded') {
    const data = topics.find((item) => item.topicInfo.name === 'vaccine_en')
    if (data) {
      content = <VaccineView result={data} />
    }
    else {
      dispatch(toIdle({}))
    }
  } else if (topicStatus === 'error') {
    content = 
      <div>
        <section className="posts-list">
          <div>{error}</div>
        </section>
      </div>
  }
  return (
    <>
      <MetaTags>
        <title>疫苗抽獎及優惠詳情</title>
        <meta name="description" content='疫苗抽獎及優惠詳情' />
        <meta name="keywords" content='疫苗抽獎,打針抽獎,一文看清疫苗抽獎' />
        <meta property="og:description" content="疫苗抽獎及優惠詳情" />
        <meta property="og:title" content='疫苗抽獎及優惠詳情' />
        <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
      </MetaTags>
      <Container textAlign='center'>
        <DisplayAdsBanner />
      </Container>
      <div  style = {{margin: 'auto', width: "90%", textAlign:'right' }}>
        <Link to="/topics/vaccine_luckydraw_info_en">
          <Button circular color='teal'>
            Eng
          </Button>
        </Link>
      </div>
      <div style={{textAlign:'center'}}>
        <Header as='h1'> 疫苗抽獎及優惠詳情 </Header>
      </div>
      <div  style = {{margin: 'auto', width: "90%" }}>
        <div style={{ whiteSpace: 'nowrap', overflowX: 'auto', width:'100%', display: 'inline-block', textAlign:'center'}} > 
        <Header as='h2'  textAlign='left' style={{paddingTop: '10px'}}>
          <Header.Subheader>
            *接種疫苗前應先諮詢專業健康建議 <br/>
            **所有資訊僅供參考，並不構成要約、招攬或邀請、宣傳、誘使，或任何不論種類或形式之表示、建議或推薦任何行動
          </Header.Subheader>
        </Header>
        <Table collapsing style={{border:"0px"}}>
          <Table.Row positive >
              綠色為已接受報名之抽奬或接受使用之優惠
          </Table.Row>
          <Table.Row negative >
              紅色為已完結之抽奬或使用之優惠
          </Table.Row>
        </Table>
        <Container style={{paddingBottom:"25px"}}>
          <VacPopSummaryContainer />
          <div style={{textAlign:'right', paddingTop:'5px'}}>
            <Link to="/topics/vaccination">
              <Header as="h5" textAlign='right' color='grey'> 
                觀看更多新冠疫苗接種數據 <Icon name = "arrow alternate circle right" />
              </Header>
            </Link>
          </div>
        </Container>
        </div>
      </div>
      {content}
      <Container textAlign='center' style={{paddingTop:'30px'}}>
          <GoogleAd 
              client="ca-pub-8753358837449417" 
              slot="2486257452" 
              format="auto" 
              wrapperDivStyle={{
              marginTop: '30px',
              marginBottom: '20px'
              }}
          />
      </Container>
    </>
  )
}

function VaccineView(data) {
  let result = data.result
  // console.log(result)
  return (
    <>
      <div style={{ paddingTop: '25px', paddingBottom: '25px' }}>
        {typeof result !== 'undefined' ? <VaccineTableZh data={result} /> : <NotFound />}
      </div>
    </>
  )
}

function NotFound() {
  return (
    <div
      style={{
        padding: "100px",
        margin: "auto",
        alignItems: "center",
        height: "75vh",
        // overflow: "auto",
      }}
    >
      <Loader active inline='centered' > Loading</Loader>
    </div>
  )
}

export default TopicVaccineZh;