import React, { useEffect, useState, useRef } from "react";
import {
  Loader,
  Table,
  Card,
  Button,
  //   Pagination,
  Menu,
  Container,
  Header,
  Icon,
  //   Input,
} from "semantic-ui-react";
import source from "../../../mock_source";
import _ from "lodash";
// import { createStore, combineReducers } from "redux";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import { formatNumber } from "../../../util/dataFormatter";
import {
  selectAllTopic,
  fetchTopic,
  toIdle,
} from "../../../features/topic/topicSlice";
import { useSelector, useDispatch } from "react-redux";
import { useAllowedBehindPaywall } from "features/user/userSlice";
// import SemanticDatepicker from "react-semantic-ui-datepickers";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
// import InFeedAdsWhite from "../../ads/InFeedAdsWhite";

export const ActiveHKC = () => {
  const dispatch = useDispatch();
  const topics = useSelector(selectAllTopic);
  const topicStatus = useSelector((state) => state.topic.status);
  const error = useSelector((state) => state.topic.error);

  useEffect(() => {
    if (topicStatus === "idle") {
      dispatch(fetchTopic("activeHKC"));
    }
  }, [topicStatus, dispatch]);

  let content;

  if (topicStatus === "loading") {
    content = (
      <div>
        <section className="posts-list">
          <div
            style={{
              padding: "100px",
              margin: "auto",
              alignItems: "center",
              height: "75vh",
              // overflow: "auto",
            }}
          >
            <Loader active inline="centered">
              {" "}
              Loading
            </Loader>
          </div>
        </section>
      </div>
    );
  } else if (topicStatus === "succeeded") {
    const data = topics.find((item) => item.topicInfo.name === "activeHKC");
    if (data) {
      content = <ActiveHKCView result={data} />;
    } else {
      dispatch(toIdle({}));
    }
  } else if (topicStatus === "error") {
    content = (
      <div>
        <section className="posts-list">
          <div>{error}</div>
        </section>
      </div>
    );
  }
  return <>{content}</>;
};

// function ActiveHKC() {
//   return <ActiveHKCView result={data} />;
// }

function ActiveHKCView(props) {
  let result = props.result;
  return (
    <>
      <div style={{ paddingTop: "25px", paddingBottom: "25px" }}>
        {typeof result !== "undefined" ? (
          <ActiveHKCContainer data={result} />
        ) : (
          <NotFound />
        )}
      </div>
    </>
  );
}

function ActiveHKCContainer(props) {
  const { data } = props;
  const lastUpdate = data.topicInfo.lastUpdate;
  const { code, dateList, sum1d, sum5d, sum10d } = data.data;
  const [tableData, setTableData] = useState([]);
  const [filterRange, setFilterRange] = useState(5);
  const [filteredData, setFilteredData] = useState([]);
  const allowedBehindPaywall = useAllowedBehindPaywall();

  useEffect(() => {
    let tableData1 = [];
    let i = 0;
    for (i; i < code.length; i += 1) {
      let c = code[i];
      let a = _.find(source, function (o) {
        return o.no === c;
      });
      let buffer = {
        code: code[i],
        name_zh: a ? a["name_zh"] : "",
        name_en: a ? a["name_en"] : "",
        sum1d: sum1d[i],
        sum5d: sum5d[i],
        sum10d: sum10d[i],
      };
      let j = 0;
      for (j; j < dateList.length; j += 1) {
        buffer[dateList[j]] = data.data[dateList[j]][i];
      }
      tableData1.push(buffer);
    }
    setTableData(tableData1);
    // console.log(tableData1);
  }, []);

  return (
    <Container>
      <div style={{ paddingBottom: "25px", margin: "auto" }}>
        <Menu pointing secondary compact size="large">
          <Menu.Item
            name="一天"
            active={filterRange === 1}
            onClick={() => {
              setFilterRange(1);
            }}
          />
          <Menu.Item
            name="五天"
            active={filterRange === 5}
            onClick={() => {
              setFilterRange(5);
            }}
          />
          <Menu.Item
            name="十天"
            active={filterRange === 10}
            onClick={() => {
              setFilterRange(10);
            }}
          />
          {/* <Menu.Item
            name="二十天"
            active={filterRange === 20}
            onClick={() => {
              setFilterRange(20);
            }}
          /> */}
        </Menu>
      </div>
      <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
        <Header as="h4" textAlign="right" color="grey">
          {" "}
          數據至 : {lastUpdate} <br />
        </Header>
      </div>

      {filterRange === 10 && !allowedBehindPaywall ? (
        <AskForSubscribe />
      ) : (
        <TableSortedByPeriod
          data={tableData}
          dateList={dateList}
          filterRange={filterRange}
        />
      )}
    </Container>
  );
}

function TableSortedByPeriod(props) {
  const { data, dateList, filterRange } = props;
  const dateListS = dateList.slice(0, filterRange).reverse();
  const scrollRef = useRef(null);
  const [chartLeft, setChartLeft] = useState(0);
  const [chartWidth, setChartWidth] = useState(10);
  let newData = data;

  const resize = (event) => {
    if (scrollRef && scrollRef.current) {
      setChartWidth(scrollRef.current.clientWidth - 20);
    }
  };
  const handleScroll = (event) => {
    setChartLeft(scrollRef.current.scrollLeft);
  };
  useEffect(() => {
    resize();
    window.addEventListener("resize", resize);
    return () => {
      window.addEventListener("resize", resize);
    };
  }, [scrollRef]);
  useEffect(() => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.addEventListener("scroll", handleScroll);
      return () => {
        if (scrollRef && scrollRef.current) {
          scrollRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [scrollRef]);

  newData = _.orderBy(
    newData,
    ["sum" + filterRange.toString() + "d"],
    ["desc"]
  );

  const topRowHeaderStyle = {
    position: "sticky",
    top: "0px",
    zIndex: "10",
    backgroundColor: "rgb(240,240,240)",
    // fontSize: normalSize[fontSize],
  };
  const firstColHeaderStyle = {
    position: "sticky",
    left: "0px",
    zIndex: "5",
    // backgroundColor: "rgb(240,240,240)",
    backgroundColor: "rgb(255,255,255)",
    // fontSize: normalSize[fontSize],
  };
  const contentStyle = {
    // fontSize: normalSize[fontSize],
    zIndex: "0",
  };
  // const rowHeaderStyle = {
  //   // fontSize: smallSize[fontSize],
  //   color: "grey",
  // };

  return (
    <div
      style={{
        whiteSpace: "nowrap",
        overflowX: "hidden",
        display: "inline-block",
        width: "100%",
        textAlign: "center",
      }}
    >
      <div
        style={{
          overflow: "auto",
          // maxHeight: "85vh",
          overscrollBehavior: "auto",
        }}
        ref={scrollRef}
      >
        <Table sortable selectable celled unstackable compact structured>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                style={{
                  position: "sticky",
                  top: "0px",
                  left: "0px",
                  zIndex: "15",
                  backgroundColor: "rgb(240,240,240)",
                  borderWidth: "0px",
                }}
                textAlign="left"
                rowSpan="2"
              >
                名稱
                {isMobile ? <br /> : null}
                (編號)
              </Table.HeaderCell>
              {dateListS.map((t, index) => (
                <Table.HeaderCell
                  // style={timeHeaderStyle}
                  textAlign="center"
                  // rowSpan="2"
                >
                  {t.toString().slice(4, -2) + "/" + t.toString().slice(-2)}
                </Table.HeaderCell>
              ))}
              {filterRange > 1 ? (
                <Table.HeaderCell
                  style={topRowHeaderStyle}
                  textAlign="center"
                  rowSpan="2"
                >
                  <Icon name="caret down" />
                  總流入
                </Table.HeaderCell>
              ) : null}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {newData.map((d, index) => {
              let sumtype =
                d["sum" + filterRange.toString() + "d"] > 0
                  ? "positive"
                  : d["sum" + filterRange.toString() + "d"] < 0
                  ? "negative"
                  : null;
              return (
                <>
                  {d["sum" + filterRange.toString() + "d"] !== 0 ? (
                    <Table.Row key={d.code + "t"}>
                      <Table.Cell style={firstColHeaderStyle} textAlign="left">
                        {d.name_zh !== "" ? (
                          <Link to={"/stock/" + d.code}>
                            <span style={{ fontSize: "0.8rem" }}>
                              {d.name_zh.length > 8
                                ? d.name_zh.substring(0, 8) + "..."
                                : d.name_zh}
                            </span>
                            {isMobile ? <br /> : null}({d.code})
                          </Link>
                        ) : (
                          <>({d.code})</>
                        )}
                      </Table.Cell>
                      {dateListS.map((t, index) => {
                        let type =
                          d[t] > 0 ? "positive" : d[t] < 0 ? "negative" : null;
                        // console.log(type);
                        return (
                          <Table.Cell
                            positive={type === "positive"}
                            negative={type === "negative"}
                            textAlign="center"
                          >
                            {d[t] !== 0 ? formatNumber(d[t]) : "-"}
                          </Table.Cell>
                        );
                      })}
                      {filterRange > 1 ? (
                        <Table.Cell
                          positive={sumtype === "positive"}
                          negative={sumtype === "negative"}
                          textAlign="center"
                        >
                          {formatNumber(
                            d["sum" + filterRange.toString() + "d"]
                          )}
                        </Table.Cell>
                      ) : null}
                    </Table.Row>
                  ) : null}
                </>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
}

function NotFound() {
  return (
    <div
      style={{
        padding: "100px",
        margin: "auto",
        alignItems: "center",
        height: "75vh",
        // overflow: "auto",
      }}
    >
      <Loader active inline="centered">
        {" "}
        Loading
      </Loader>
    </div>
  );
}

export default ActiveHKC;

function AskForSubscribe() {
  return (
    <div style={{ margin: "auto" }}>
      <Card centered style={{ textAlign: "center" }}>
        <Card.Content>
          <Card.Header>加入付費版，以體驗進階功能</Card.Header>
        </Card.Content>
        <Card.Content extra>
          <Link to="/subscription">
            <Button basic color="blue">
              了解更多
            </Button>
          </Link>
        </Card.Content>
      </Card>
    </div>
  );
}
