import React, { useEffect } from "react";
import {
  Loader,
  Table,
  Pagination,
  Menu,
  Container,
  Header,
  Popup,
  Icon,
} from "semantic-ui-react";
import _ from "lodash";
import { createStore, combineReducers } from "redux";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import InFeedAdsWhite from "../ads/InFeedAdsWhite";
import {
  selectAllTopic,
  fetchTopic,
  toIdle,
  fetchTopicwM,
} from "../../features/topic/topicSlice";
import { useSelector, useDispatch } from "react-redux";
// import DisplayAdsBanner from '../../ads/DisplayAdsBanner';

// import source from "../../mock_source";

export const ListedIPOTable = () => {
  const dispatch = useDispatch();
  const topics = useSelector(selectAllTopic);
  const topicStatus = useSelector((state) => state.topic.status);
  const error = useSelector((state) => state.topic.error);

  useEffect(() => {
    if (topicStatus === "idle") {
      dispatch(fetchTopic("listedIPO"));
    }
    if (
      topics.find((item) => item.topicInfo.name === "stockDict_all_n") ===
      undefined
    ) {
      dispatch(fetchTopicwM("stockDict_all_n"));
    }
  }, [topicStatus, dispatch]);

  let content;

  if (topicStatus === "loading") {
    content = (
      <div>
        <section className="posts-list">
          <div
            style={{
              padding: "100px",
              margin: "auto",
              alignItems: "center",
              height: "75vh",
              // overflow: "auto",
            }}
          >
            <Loader active inline="centered">
              {" "}
              Loading
            </Loader>
          </div>
        </section>
      </div>
    );
  } else if (topicStatus === "succeeded") {
    const data = topics.find((item) => item.topicInfo.name === "listedIPO");
    const stockDict = topics.find(
      (item) => item.topicInfo.name === "stockDict_all_n"
    );
    if (data && stockDict) {
      content = <ListedIPOView result={data} stockDict={stockDict} />;
    } else {
      dispatch(toIdle({}));
    }
  } else if (topicStatus === "error") {
    content = (
      <div>
        <section className="posts-list">
          <div>{error}</div>
        </section>
      </div>
    );
  }
  return <>{content}</>;
};

export default ListedIPOTable;

function ListedIPOView(data) {
  let { result, stockDict } = data;
  return (
    <>
      {/* <Container textAlign='center'>
        <DisplayAdsBanner />
      </Container> */}
      <div style={{ paddingTop: "25px", paddingBottom: "25px" }}>
        {typeof result !== "undefined" ? (
          <SortableTable data={result} stockDict={stockDict} />
        ) : (
          <NotFound />
        )}
      </div>
    </>
  );
}

function NotFound() {
  return (
    <div
      style={{
        padding: "100px",
        margin: "auto",
        alignItems: "center",
        height: "75vh",
        // overflow: "auto",
      }}
    >
      <Loader active inline="centered">
        {" "}
        Loading
      </Loader>
    </div>
  );
}

const initialState = {
  column: null,
  data: [],
  options: [],
  direction: null,
  filterText: { value: "" },
  filterInd: "",
  activePage: "1",
  isFocus: "1",
  totalPage: "4",
};

const reducer = combineReducers({
  resultTable: resultTableReducer,
});

function resultTableReducer(state = initialState, action) {
  switch (action.type) {
    case "CHANGE_SORT": {
      if (state.column === action.column) {
        return {
          ...state,
          data: state.data.reverse(),
          direction:
            state.direction === "ascending" ? "descending" : "ascending",
        };
      }
      return {
        column: action.column,
        data: _.sortBy(state.data, [action.column]),
        direction: "ascending",
        filterText: state.filterText,
      };
    }
    case "Filter_MESSAGE": {
      const string = action.text;
      return {
        ...state,
        filterText: string,
      };
    }
    case "TOGGLE_TYPE": {
      const string = action.text;
      return {
        ...state,
        itemType: string,
      };
    }
    case "Toggle_isFocus": {
      const string = action.text;
      return {
        ...state,
        isFocus: string,
      };
    }
    case "SET_IND_FILTER": {
      return {
        ...state,
        filterInd: action.state,
      };
    }
    case "SET_ACTIVE_PAGE": {
      const string = action.text;
      window.scrollTo(0, 0);
      return {
        ...state,
        activePage: string,
      };
    }
    case "ADD_MESSAGE": {
      return {
        ...state,
        data: action.state,
      };
    }
    case "ADD_OPTIONS": {
      return {
        ...state,
        options: action.state,
      };
    }
    default: {
      return state;
    }
  }
}

const store = createStore(reducer);

class SortableTable extends React.Component {
  constructor(props) {
    super(props);
    this.setData();
  }

  setData() {
    // console.log(this.props.data)
    const data1 = this.props.data;
    const source = this.props.stockDict.data;
    const code = data1.code;
    const name = data1.name;
    const listingDatedt = data1.listingDatedt;
    const listingDatedtts = data1.listingDatedtts;
    const listingDatedt6mts = data1.listingDatedt6mts;
    const listingDatedt1yts = data1.listingDatedt1yts;
    const listingPrice = data1.listingPrice;
    const oneLotSuccessRate = data1.oneLotSuccessRate;
    const overSubRate = data1.overSubRate;
    const pctChgOnDebut = data1.pctChgOnDebut;
    const tableHeader = data1.tableData1;
    const topic = data1.topicInfo.name;
    const lastUpdate = data1.lastUpdate;
    const options = [{ key: "所有", text: "所有", value: "所有" }];
    const currentTs = new Date().getTime() / 1000;
    const tableData1 = [];
    let i = 0;
    for (i; i < name.length; i += 1) {
      let id = code[i];
      let a = _.find(source, function (o) {
        return o.id === id;
      });
      tableData1.push({
        no: i + 1,
        code: code[i],
        name: a ? a["zh"] : "",
        listingDatedt: listingDatedt[i],
        listingDatedt6mts: Math.ceil(
          (listingDatedt6mts[i] - currentTs) / 86400
        ),
        listingDatedt1yts: Math.floor(
          (listingDatedt1yts[i] - currentTs) / 86400
        ),
        listingPrice: listingPrice[i],
        oneLotSuccessRate: oneLotSuccessRate[i],
        overSubRate: overSubRate[i],
        pctChgOnDebut: pctChgOnDebut[i],
      });
    }
    store.dispatch({
      type: "ADD_MESSAGE",
      state: tableData1,
    });
    store.dispatch({
      type: "ADD_OPTIONS",
      state: options,
    });
  }

  componentDidMount() {
    store.subscribe(() => this.forceUpdate());
  }

  render() {
    const state = store.getState();
    const { data, filterText, activePage, itemType } = state.resultTable;
    let newData = data;

    if (filterText.value) {
      newData = newData.filter((d) => {
        return (
          (typeof d.code === "string" &&
            d.code.toLowerCase().includes(filterText.value.toLowerCase())) ||
          (typeof d.name === "string" &&
            d.name.toLowerCase().includes(filterText.value.toLowerCase()))
        );
      });
    }
    if (itemType === "1") {
      newData = newData.filter((d) => {
        return d.listingDatedt6mts >= -5;
      });
      newData = _.sortBy(newData, [
        function (o) {
          return o.listingDatedt6mts;
        },
      ]);
    } else if (itemType === "2") {
      newData = newData.filter((d) => {
        return d.listingDatedt1yts >= -5;
      });
      newData = _.sortBy(newData, [
        function (o) {
          return o.listingDatedt1yts;
        },
      ]);
    }
    const nPerPage = 24;
    const totalPage = Math.ceil(newData.length / nPerPage);
    newData = _.slice(
      newData,
      (activePage - 1) * nPerPage,
      activePage * nPerPage
    );
    // console.log(this.props)

    return (
      <Container
        style={{
          widht: "100%",
          maxWidth: "950px",
          margin: "auto",
          alignItems: "center",
        }}
      >
        <div style={{ paddingBottom: "25px" }}>
          {/* <Grid stackable columns={2}> */}
          {/* <Grid.Column floated='left'> */}
          <ToggleType />
          {/* </Grid.Column> */}
          {/* </Grid> */}
        </div>

        <div style={{ paddingBottom: "25px", paddingTop: "25px" }}>
          <FilterInput value={filterText} />
        </div>

        <div>
          <Header as="h4" textAlign="right" color="grey">
            {" "}
            最後更新 : {this.props.data.topicInfo.lastUpdate.slice(0, 10)}{" "}
          </Header>
        </div>

        {/* {
          isMobile ? 
          <div style={{width:'95%'}}>
            <TableComponent data={newData}/>
          </div> : */}
        <div style={{ textAlign: "center" }}>
          <TableComponent data={newData} />
        </div>
        {/* } */}

        <div style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          <PaginationContainer totalPage={totalPage} />
        </div>
      </Container>
    );
  }
}

function TableComponent(data) {
  const newData = data.data;
  return (
    <div
      style={{
        whiteSpace: "nowrap",
        overflowX: "auto",
        display: "inline-block",
        width: "100%",
        textAlign: "center",
      }}
    >
      <Table unstackable compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell rowSpan="2">名稱/編號</Table.HeaderCell>
            <Table.HeaderCell rowSpan="2" textAlign="center">
              上市日期
            </Table.HeaderCell>
            <Table.HeaderCell rowSpan="2" textAlign="center">
              發行價格
            </Table.HeaderCell>
            <Table.HeaderCell colSpan="2" textAlign="center">
              距離巳上市
            </Table.HeaderCell>
            <Table.HeaderCell rowSpan="2" textAlign="center">
              超額倍數/ <br />
              中籤率
            </Table.HeaderCell>
            <Table.HeaderCell rowSpan="2" textAlign="center">
              首日表現
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell textAlign="center"> 半年(日) </Table.HeaderCell>
            <Table.HeaderCell textAlign="center"> 一年(日) </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {newData.map(
            (
              {
                no,
                code,
                name,
                listingDatedt,
                listingDatedt6mts,
                listingDatedt1yts,
                listingPrice,
                oneLotSuccessRate,
                overSubRate,
                pctChgOnDebut,
              },
              index
            ) => (
              <>
                {index !== 0 && index % 10 === 0 && (
                  <Table.Row>
                    <Table.Cell colspan={7} textAlign="center">
                      <InFeedAdsWhite></InFeedAdsWhite>
                    </Table.Cell>
                  </Table.Row>
                )}
                <Table.Row key={code}>
                  <Table.Cell>
                    {name !== "" ? (
                      <Link to={"/stock/" + code}>
                        {name.length > 8 ? name.substring(0, 8) + "..." : name}{" "}
                        <br /> ({code})
                      </Link>
                    ) : (
                      code
                    )}
                  </Table.Cell>

                  <Table.Cell textAlign="center">{listingDatedt}</Table.Cell>
                  <Table.Cell textAlign="center">{listingPrice}</Table.Cell>
                  <Table.Cell textAlign="center">
                    {listingDatedt6mts}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {listingDatedt1yts}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {overSubRate} <br /> {oneLotSuccessRate}{" "}
                  </Table.Cell>
                  {/* <Table.Cell textAlign='center'>{oneLotSuccessRate}</Table.Cell> */}
                  <Table.Cell
                    textAlign="center"
                    positive={pctChgOnDebut[0] === "+" ? 1 : 0}
                    negative={pctChgOnDebut[0] === "-" ? 1 : 0}
                  >
                    {pctChgOnDebut}
                  </Table.Cell>
                </Table.Row>
              </>
            )
          )}
        </Table.Body>
      </Table>
    </div>
  );
}

class PaginationContainer extends React.Component {
  componentDidMount() {
    store.subscribe(() => this.forceUpdate());
  }

  handlePaginationChange = (e, { activePage }) => {
    store.dispatch({
      type: "SET_ACTIVE_PAGE",
      text: activePage,
    });
  };

  render() {
    const pos = isMobile ? "center" : "right";
    const state = store.getState();
    const { activePage } = state.resultTable;

    return (
      <div style={{ float: pos }}>
        <Pagination
          boundaryRange={0}
          activePage={activePage}
          size={isMobile ? "small" : "big"}
          siblingRange={1}
          totalPages={this.props.totalPage}
          onPageChange={this.handlePaginationChange}
        />
      </div>
    );
  }
}

class ToggleType extends React.Component {
  componentDidMount() {
    store.subscribe(() => this.forceUpdate());
  }

  state = { activeItem: "所有" };

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
    if (name === "所有") {
      store.dispatch({
        type: "SET_ACTIVE_PAGE",
        text: "1",
      });
      store.dispatch({
        type: "TOGGLE_TYPE",
        text: "0",
      });
    } else if (name === "上市半年內") {
      store.dispatch({
        type: "SET_ACTIVE_PAGE",
        text: "1",
      });
      store.dispatch({
        type: "TOGGLE_TYPE",
        text: "1",
      });
    } else if (name === "上市一年內") {
      store.dispatch({
        type: "SET_ACTIVE_PAGE",
        text: "1",
      });
      store.dispatch({
        type: "TOGGLE_TYPE",
        text: "2",
      });
    }
  };
  render() {
    const { activeItem } = this.state;

    return (
      //   <Container>
      <div>
        {/* <div style={{float:'left'}}> */}
        <Menu pointing secondary compact>
          <Menu.Item
            name="所有"
            active={activeItem === "所有"}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name="上市半年內"
            active={activeItem === "上市半年內"}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name="上市一年內"
            active={activeItem === "上市一年內"}
            onClick={this.handleItemClick}
          />
        </Menu>

        {/* </div> */}
        <div style={{ float: "right", textAlign: "right" }}>
          <Popup
            on="click"
            pinned
            position="bottom right"
            size="small"
            trigger={
              <div style={{ paddingTop: "1em" }}>
                <Icon color="grey" name="question circle" />
              </div>
            }
          >
            <p>
              {" "}
              根據上市規則，基礎投資者<sup>*</sup>
              在上市日期起計至少六個月內不得出售股份。實際時期需參閱招股書，較常見的是半年或一年。
              另外。通常上市公司會在招股書承諾，12個月內控股權不會易手。
            </p>
            <p>
              <sup>*</sup>{" "}
              基礎投資者通常是一些較大型機構及知名人士（包括資產管理基金、主權基金及富豪）
            </p>
          </Popup>
        </div>
      </div>
    );
  }
}

class FilterInput extends React.Component {
  componentDidMount() {
    store.subscribe(() => this.forceUpdate());
  }

  state = {
    value: "",
  };

  onChangeFilter = (e) => {
    this.setState({
      value: e.target.value,
    });

    store.dispatch({
      type: "Filter_MESSAGE",
      text: { value: e.target.value },
    });
    store.dispatch({
      type: "SET_ACTIVE_PAGE",
      text: "1",
    });
  };

  render() {
    return (
      <div>
        <div className="ui input">
          <input
            onChange={this.onChangeFilter}
            value={this.props.value.value}
            type="text"
            placeholder="股票編號或名字"
          />
        </div>
      </div>
    );
  }
}
