import React, { Component, createRef} from "react";
import { Loader, Table, Header, Icon, Menu,  Label, Button, Input, List, Container } from 'semantic-ui-react'
// import { useHistory } from 'react-router-dom';
import _ from 'lodash'
import { createStore, combineReducers } from 'redux';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import './VaccineTable.css';
import InFeedAdsWhite from '../ads/InFeedAdsWhite';
import DisplayAdsBanner from '../ads/DisplayAdsBanner';


export class VaccineTableEn extends Component {
    state = this.props.data
    render() {
      return (
        <>
          <MetaTags>
            <title>Vaccination Lucky Draw and Discount Details</title>
            <meta name="description" content='Vaccination Lucky Draw and Discount Details' />
            <meta name="keywords" content='Covid vaccination perks, Vaccination Lucky Draw, Vaccination Discount, covid vaccine, covid vaccine hong kong, covid vaccine incentives' />
            <meta property="og:description" content="Vaccination Lucky Draw and Discount Details" />
            <meta property="og:title" content='Vaccination Lucky Draw and Discount Details' />
            <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
          </MetaTags>
          <div style={{paddingTop:'25px', paddingBottom:'25px'}}>
              { typeof this.state !== 'undefined' ? <SortableTable data={this.state}/> : <NotFound/>}
          </div>
        </>
      );
    }
}

export default VaccineTableEn;

function NotFound(){
    return (
        <div 
          style={{
            padding: "100px",
            margin: "auto",
            alignItems: "center",
            height: "75vh",
            // overflow: "auto",
          }}
        >
          <Loader active inline='centered' > Loading</Loader>
          {/* <Header as='h1'> <span style={{color:"#ffe392", backgroundColor:"#c6070c"}}>發</span>  </Header> */}
        </div>
    )
}

const initialState = {
    column: null,
    data: [],
    options: [],
    // direction: null,
    filterText: {value:''},
    lastUpdate: {value:''},
    showReady: 0,
    // filterInd: "",
    // activePage: "1",
    itemType: "2",
    // totalPage: "4"

};

const reducer = combineReducers({
    resultTable: resultTableReducer,
});

function resultTableReducer(state = initialState, action) {
    switch (action.type) {
      case 'CHANGE_SORT':{
        if (state.column === action.column) {
          return {
            ...state,
            data: state.data.reverse(),
            direction:
              state.direction === 'ascending' ? 'descending' : 'ascending',
          }
        }
        return {
          column: action.column,
          data: _.sortBy(state.data, [action.column]),
          direction: 'ascending',
          filterText: state.filterText
        }
      }
      case 'Filter_MESSAGE':{
        const string = action.text
        // console.log(string)
        // console.log('fire')
  
        return {
          ...state, filterText: string
        }
      }
      case 'TOGGLE_TYPE':{
        const string = action.text
        // console.log(string)
        // console.log('fire')
  
        return {
          ...state, itemType: string
        }
      }
      case 'TOGGLE_READY':{
        const string = action.text
        // console.log(string)
        // console.log('fire')
  
        return {
          ...state, showReady: string
        }
      }
      // case 'SET_IND_FILTER':{
      //   // console.log(action.state)
  
      //   return {
      //     ...state, filterInd: action.state
      //   }
      // }
      // case 'SET_ACTIVE_PAGE':{
      //   const string = action.text
      //   window.scrollTo(0, 0)
        
      //   // console.log('activepage')
      //   // console.log(string)
      //   return {
      //     ...state, activePage: string
      //   }
      // }
      case 'ADD_MESSAGE':{
        // console.log('hihi')
        // console.log(state)
        // console.log({
        //   ...state, data: action.state
        // })
        return {
          ...state, data: action.state
        }
      }
      case 'ADD_OPTIONS':{
        return {
          ...state, options: action.state
        }
      }
      case 'ADD_LASTUPDATE':{
        return {
          ...state, lastUpdate: action.state
        }
      }
      default:{
        return state;
      }
    }
}

const store = createStore(reducer);

class SortableTable extends React.Component{
    contextRef = createRef();

    constructor(props) {
        super(props);
        this.setData();
    }

    setData(){
      const data1 =this.props.data
      const lastUpdate = data1.topicInfo.lastUpdate
      const name_zh = data1.name_en
      const code = data1.code
      const cond = data1.cond_en
      const deadline = data1.deadline
      const details = data1.details_en
      const linkNews = data1.linkNews_en
      const link_ = data1.link_en
      const isLucky = data1.isLucky
      const isReady = data1.isReady
      const isNew = data1.isNew
      const isNonResidence = data1.isNonResidence
      const isEng = data1.isEng
      const isResult = data1.isResult
      const resultLink = data1.resultLink_en
      const appDetails = data1.appDetails_en
      const appLink = data1.appLink_en
      const options = [{ key: '所有', text: '所有', value: '所有' }]
      const tableData1 = []
        let i = 0
        for (i; i<name_zh.length; i +=1){
          tableData1.push({name_zh: name_zh[i], code: code[i], deadline: deadline[i], cond: cond[i], details: details[i], 
            link_: link_[i], linkNews: linkNews[i], isLucky:isLucky[i], isReady:isReady[i], appDetails:appDetails[i], 
            appLink:appLink[i], isNew:isNew[i], resultLink:resultLink[i], isNonResidence: isNonResidence[i], isResult: isResult[i], isEng: isEng[i] })
        }
        store.dispatch({
            type: 'ADD_MESSAGE',
            state: tableData1,
        });
        store.dispatch({
          type: 'ADD_OPTIONS',
          state: options,
        });
        store.dispatch({
          type: 'ADD_LASTUPDATE',
          state: lastUpdate,
        });
    }

    componentDidMount() {
        // this.setData();
        store.subscribe(() => this.forceUpdate());
    
    }

    render(){
        const state = store.getState();
        // console.log(state)
        // const tableData = state.resultTable
        // const {column, data, direction, filterText, activePage, isFocus} = state.resultTable
        const {data, filterText, lastUpdate, itemType } = state.resultTable

        let newData = data  
        newData = newData.filter((d) => {
          return (
          (d.isEng === 1)
          )
        })

        if (filterText.value){
            newData = newData.filter((d) => {
                return (
                (typeof d.code === 'string' && d.code.toLowerCase().includes(filterText.value.toLowerCase())) ||
                (typeof d.name_zh === 'string' && d.name_zh.toLowerCase().includes(filterText.value.toLowerCase())) ||
                (typeof d.cond === 'string' && d.cond.toLowerCase().includes(filterText.value.toLowerCase())) ||
                (typeof d.details === 'string' && d.details.toLowerCase().includes(filterText.value.toLowerCase())) ||
                (typeof d.appDetails === 'string' && d.appDetails.toLowerCase().includes(filterText.value.toLowerCase()))
                )
            })
        }
        if (itemType === '1'){
          newData = newData.filter((d) => {
              return(
                  d.isLucky === 1 || d.isLucky === 2
              )
          });
        } else if (itemType === '0'){
          newData = newData.filter((d) => {
              return(
                  d.isLucky === 0 || d.isLucky === 2
              )
          });
        } else if (itemType === '3'){
            newData = newData.filter((d) => {
                return(
                    d.isNew === 1
                )
          });
        } else if (itemType === '4'){
          newData = newData.filter((d) => {
              return(
                  d.isResult === 1
              )
        });

      }

        return(
            <div style={{width:'100%', margin: "auto", alignItems: "center"}}> 
              <div  style = {{margin: 'auto', width: "90%", textAlign:'right', paddingBottom: '15px' }}>
                <Link to="/topics/vaccine_luckydraw_info_zh">
                  <Button circular color='teal'>
                  中
                  </Button>
                </Link>
              </div>
              <div style={{textAlign:'center', paddingBottom: '10px'}}>
                <Header as='h1'> Vaccination Lucky Draw and Discount Details </Header>
              </div>

              <div  style = {{margin: 'auto', width: "90%" }}>
                <div style={{ whiteSpace: 'nowrap', overflowX: 'auto', width:'100%', display: 'inline-block', textAlign:'center'}} > 
                <Header as='h2'  textAlign='left' style={{paddingTop: '10px'}}>
                  <Header.Subheader>
                    * Please consult professional health advice before vaccination <br/>
                    ** All information is for reference only
                  </Header.Subheader>
                </Header>
                <Table collapsing style={{border:"0px"}}>
                  <Table.Row positive >
                    Green: Lucky draw that has started registration or discount is active 
                  </Table.Row>
                  <Table.Row negative >
                    Red: Lucky draw or discount has ended or expired.
                  </Table.Row>
                </Table>
                <div style={{ textAlign:'left' }}>
                  <ToggleType />
                </div>

                <div style={{ paddingTop: '25px', textAlign:'left'}}>
                    <FilterInput value={filterText} />
                </div>

                  < Header as="h5"  textAlign='left' color ='grey'> Total: {newData.length} </Header>  

                    <Container textAlign='center'>
                        <DisplayAdsBanner />
                    </Container>

                    <Header as="h4"  textAlign='right' color ='grey'> Last Updated  : {lastUpdate} </Header>
                    

                <Table  unstackable celled striped collapsing>

                        <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell width={1}  >
                                Code/Org
                              </Table.HeaderCell>
                              <Table.HeaderCell  >
                                Details
                              </Table.HeaderCell>
                              <Table.HeaderCell  >
                                Registration Conditions/ Methods
                              </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                        {newData.map(({ code, name_zh, cond, deadline, details,linkNews,link_,isLucky,isReady,appDetails,appLink,resultLink,isNonResidence}, index) => (
                          <>
                            {(index !== 0) && (index % 10 === 0) && (
                              <Table.Row>
                                <Table.Cell colSpan={3} textAlign='center' >
                                  <InFeedAdsWhite></InFeedAdsWhite>
                                </Table.Cell>
                              </Table.Row>
                            )}
                            {/* {(index === 3) && ( newData.length > 8) && (
                              <Table.Row>
                                <Table.Cell colSpan={3}   textAlign='center' >
                                  <VoucherPromoBlock/>
                                </Table.Cell>
                              </Table.Row>
                            )} */}

                            <Table.Row key={index}  positive={isReady === 1} negative={isReady === -1}>
                            <Table.Cell >
                              {code.split("\n").map(c => (
                                c ?
                                <Link to={"/stock/" + c} > {c} <Icon size='large' name='chart line' />  <br/> </Link> 
                                : null
                              ))}
                              {name_zh ? 
                                <List style={{margin : "0.5em 0em"}}>
                                  {name_zh.split("\n").map(c => (
                                    <List.Item>{c}</List.Item>
                                  ))}
                                </List>:
                                null
                              }
                              {/* {name_zh.split("\n").map(c => (
                                <ul>{c}</ul>
                              ))} */}
                            </Table.Cell>
                            <Table.Cell >
                              <>
                                { isLucky === 1? 
                                  <span style={{color:"grey",  fontWeight: "bold", textDecoration: "underline"}}>LuckyDraw:</span>: 
                                  (isLucky === 0 ? 
                                    <span style={{color:"grey",  fontWeight: "bold", textDecoration: "underline"}}>Discount:</span>:
                                    <span style={{color:"grey",  fontWeight: "bold", textDecoration: "underline"}}>LuckyDraw + Discount:</span>
                                  )
                                }
                                <br/>
                                {details ? 
                                  <List style={{margin : "0.5em 0em"}}>
                                    {details.split("\n").map(c => (
                                      <List.Item>{c}</List.Item>
                                    ))}
                                  </List>:
                                  null
                                }

                                
                                { link_ === "" ? null : 
                                  <Label as='a' basic target="_blank" rel="noopener noreferrer" href={link_}>
                                    <Icon name='linkify' />
                                    Official
                                  </Label>
                                }

                                { linkNews === "" ? null : 
                                  <Label as='a' basic target="_blank" rel="noopener noreferrer" href={linkNews}>
                                    <Icon name='linkify' />
                                    Press
                                  </Label>
                                }
                                { isNonResidence === 1 ? 
                                    <Label  color='blue' >
                                      Applicable for Non-PR 
                                    </Label>
                                  : null
                                }


                                {/* { link_ === "" ? null : 
                                  <a target="_blank" rel="noopener noreferrer" href={link_}> 
                                    <Icon name='linkify'/> 官方
                                  </a>
                                } 
                                { linkNews === "" ? null : 
                                  <a target="_blank" rel="noopener noreferrer" href={linkNews}> 
                                    <Icon name='linkify'/> 新聞
                                  </a>
                                }*/}
                              </> 
                            </Table.Cell>
                            <Table.Cell >
                            <>
                              {cond ? 
                                <>
                                  <span style={{color:"grey",  fontWeight: "bold", textDecoration: "underline"}}>參加條件:</span> <br/>
                                  <List style={{margin : "0.5em 0em"}}>
                                    {cond.split("\n").map(c => (
                                      <List.Item>{c}</List.Item>
                                    ))}
                                  </List>
                                </>
                                :null
                              }
                              {appDetails ? 
                                <>
                                  <span style={{color:"grey", fontWeight: "bold", textDecoration: "underline"}}>參加辦法:</span> <br/>
                                  <List style={{margin : "0.5em 0em"}}>
                                    {appDetails.split("\n").map(c => (
                                      <List.Item>{c}</List.Item>
                                    ))}
                                  </List>
                                </>
                                :null
                              }                      
                              {appLink === "" ? null :
                                <>
                                  <Label as='a' basic target="_blank" rel="noopener noreferrer" href={appLink} color='blue'>
                                    <Icon name='linkify'  />
                                    Register
                                  </Label>
                                </>
                              }
                              {resultLink === "" ? null :
                                <Label as='a' basic target="_blank" rel="noopener noreferrer" href={resultLink} color='blue'>
                                  <Icon name='linkify'  />
                                  Result
                                </Label>
                              }

                            </>
                            </Table.Cell>
                            </Table.Row>
                            {/* {(index === newData.length-1) && (
                              <Table.Row>
                                <Table.Cell colSpan={3} textAlign='center' >
                                  <VoucherPromoBlock/>
                                </Table.Cell>
                              </Table.Row>
                            )} */}
                          </>
                        ))}
                        </Table.Body>
                    </Table>
                

                  </div>
                </div>
            {/* </Container> */}
            {/* <Container style={{paddingTop:'25px', paddingBottom:'25px'}}>
              <PaginationContainer totalPage = {totalPage}/>
            </Container> */}
            {/* <Container textAlign='center' style={{paddingTop:'30px', paddingBottom:'30px'}}>
                <GoogleAd 
                    client="ca-pub-8753358837449417" 
                    slot="2486257452" 
                    format="auto" 
                    wrapperDivStyle={{
                    marginTop: '30px',
                    marginBottom: '30px'
                    }}
                />
            </Container> */}
        </div>
        )
    }
}

class ToggleType extends React.Component {
  componentDidMount() {
    store.subscribe(() => this.forceUpdate());
  }
  
  state = { activeItem: 'All' }

  handleItemClick = (e, { name }) => {
      this.setState({ activeItem: name })
      if (name === 'All'){
          store.dispatch({
              type: 'TOGGLE_TYPE',
              text: "2",
          });
      } else if (name === 'Lucky Draw') {
          store.dispatch({
              type: 'TOGGLE_TYPE',
              text: "1",
          });
      } else if (name === 'Promotion') {
        store.dispatch({
            type: 'TOGGLE_TYPE',
            text: "0",
        });
      } else if (name === 'Latest') {
        store.dispatch({
            type: 'TOGGLE_TYPE',
            text: "3",
        });
      } else if (name === 'Reuslt Released') {
        store.dispatch({
            type: 'TOGGLE_TYPE',
            text: "4",
        });
      }
  }

  render() {
      const { activeItem } = this.state

      return (
          <div >
              <Menu pointing secondary compact>
                  <Menu.Item
                      name='All'
                      active={activeItem === 'All'}
                      onClick={this.handleItemClick}
                  />
                  <Menu.Item
                      name='Lucky Draw'
                      active={activeItem === 'Lucky Draw'}
                      onClick={this.handleItemClick}
                  />
                  <Menu.Item
                      name='Promotion'
                      active={activeItem === 'Promotion'}
                      onClick={this.handleItemClick}
                  />
                  <Menu.Item
                      name='Latest'
                      active={activeItem === 'Latest'}
                      onClick={this.handleItemClick}
                  />
                  <Menu.Item
                      name='Reuslt Released'
                      active={activeItem === 'Reuslt Released'}
                      onClick={this.handleItemClick}
                  />
              </Menu>
          </div>
      );
  }
}


// function VoucherPromoBlock(){
//   const history = useHistory();
//   return(
//     <div>
//       <Card centered onClick={() => { history.push("/topics/consumption_voucher") }} style ={{ width:'100%', textAlign:'center'}}>
//           <div>
//           <Image
//             alt='一文看清$5000消費劵及優惠'
//             size='medium'
//             src="../images/topics/topics_consumption_voucher.png"
//             verticalAlign='middle'
//           /> {' '}
//             {/* <span>一文看清疫苗抽獎及優惠詳情</span> */}
//           {isMobile ? null:
//             <Label as='a' color='teal' size='big' tag>
//               一文看清$5000消費劵及優惠
//             </Label>
//           }
//           </div>
//       </Card>
//     </div>
//   )
// }


class FilterInput extends React.Component {
  componentDidMount() {
    store.subscribe(() => this.forceUpdate());
  }

  state = {
    value: ''
  };

  onChangeFilter = (e) => {
    this.setState({
      value: e.target.value,
    })

    store.dispatch({
      type: 'Filter_MESSAGE',
      text: { value: e.target.value },
    });
  };

  onClear = (e) => {
    this.setState({
      value: '',
    })

    store.dispatch({
      type: 'Filter_MESSAGE',
      text: { value: e.target.value },
    });
  };

  render() {
    return (
      <div  >
        {/* <div className='ui input'> */}
          <Input
            style={{width:'100%', maxWidth:'800px'}}
            icon={<Icon name='close' inverted circular link onClick={this.onClear} />}
            onChange={this.onChangeFilter}
            // value={this.props.value.value}
            value = {this.state.value}
            type='text'
            placeholder="輸入任何關鍵字"
          />
        {/* </div> */}
      </div>
    );
  }
}
