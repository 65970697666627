import { Collapse, Table, TableBody, TableRow, TableCell, Grid, IconButton } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import StarBorderOutlinedIcon from "@material-ui/icons/StarBorderOutlined";
import StarIcon from "@material-ui/icons/Star";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { useState, useEffect } from "react";
import ETAView from "./ETAView";
import useWindowSize from "../../../../util/useWidowSize";
import { ETAFavourites } from "../ETAFavourite";
import { getTransportName } from "../lib/ETAUtility";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grid_content: {
    textAlign: "center",
  },
  table: {
    margin: 0,
  },
  icon: {
    margin: 0,
  },
}));

export default function RouteStopRow(props) {
  const { height, width } = useWindowSize();
  const [ open, setOpen ] = useState(false);
  const [ fav, setFav ] = useState(false);
  const classes = useStyles();

  const routeStop = props.routeStop;
  const firstRouteStop = props.firstRouteStop;
  const lastRouteStop = props.lastRouteStop;
  const state = props.state;
  const onStateChange = props.onStateChange;

  useEffect(() => {
    setFav(isFavourite());
  });

  const getFavourite = (() => {
    return {
      transport_name: routeStop.transport_name,
      stop_code: routeStop.stop_code,
      route_code: routeStop.route_code,
      route_name: routeStop.route_name,
      route_src_name: routeStop.route_src_name,
      route_dest_name: routeStop.route_dest_name,
      route_bound: routeStop.route_bound,
      route_service_type: routeStop.route_service_type,
      first_stop_code: firstRouteStop ? firstRouteStop.stop_code : routeStop.first_stop_code,
      first_stop_name: firstRouteStop ? firstRouteStop.stop_name : routeStop.first_stop_name,
      first_stop_latitude: firstRouteStop ? firstRouteStop.stop_latitude : routeStop.first_stop_latitude,
      first_stop_longitude: firstRouteStop ? firstRouteStop.stop_longitude : routeStop.first_stop_longitude,
      first_stop_platform: firstRouteStop ? firstRouteStop.stop_platform : routeStop.first_stop_platform,
      first_stop_seq: firstRouteStop ? firstRouteStop.stop_seq : routeStop.first_stop_seq,
      last_stop_code: lastRouteStop ? lastRouteStop.stop_code : routeStop.last_stop_code,
      last_stop_name: lastRouteStop ? lastRouteStop.stop_name : routeStop.last_stop_name,
      last_stop_latitude: lastRouteStop ? lastRouteStop.stop_latitude : routeStop.last_stop_latitude,
      last_stop_longitude: lastRouteStop ? lastRouteStop.stop_longitude : routeStop.last_stop_longitude,
      last_stop_platform: lastRouteStop ? lastRouteStop.stop_platform : routeStop.last_stop_platform,
      last_stop_seq: lastRouteStop ? lastRouteStop.stop_seq : routeStop.last_stop_seq,
      stop_code: routeStop.stop_code,
      stop_name: routeStop.stop_name,
      stop_latitude: routeStop.stop_latitude,
      stop_longitude: routeStop.stop_longitude,
      stop_platform: routeStop.stop_platform,
      stop_seq: routeStop.stop_seq,
    }
  })
  const handleClick = () => {
    onStateChange({
      ...state,
      transport_name: routeStop.transport_name,
      route_code: routeStop.route_code,
      route_name: routeStop.route_name,
      route_bound: routeStop.route_bound,
      route_service_type: routeStop.route_service_type,
    })
  }
  const toggleFavourites = (() => {
    if (isFavourite()) {
      removeFromFavourites();
    } else {
      addToFavourites();
    }
    setFav(isFavourite());
  })
  const addToFavourites = (() => {
    let etaFavourites = new ETAFavourites();
    etaFavourites.add(getFavourite());
    onStateChange({
      ...state,
      transport_name: state.transport_name,
      favourites: etaFavourites.favourites,
    })
  })
  const isFavourite = (() => {
    let etaFavourites = new ETAFavourites();
    return etaFavourites.isExist(getFavourite());
  })
  const removeFromFavourites = (() => {
    let etaFavourites = new ETAFavourites();
    etaFavourites.remove(getFavourite());
    onStateChange({
      ...state,
      favourites: etaFavourites.favourites,
    })
  })

  const handleCollapse = (() => {
    setOpen(!open);
  });

  let etaItem0 =
    <Grid container spacing={0}>
    <Grid item xs={7} >
      <Table size="small" aria-label="a dense table">
        <TableBody>
          <TableRow>
            <TableCell className={classes.table}>
              { routeStop[0].eta && <ETAView eta={routeStop[0].eta[0]}></ETAView> }
            </TableCell>
          </TableRow>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                <TableRow>
                  <TableCell>
                    { routeStop[0].eta && <ETAView eta={routeStop[0].eta[1]}></ETAView> }
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    { routeStop[0].eta && <ETAView eta={routeStop[0].eta[2]}></ETAView> }
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Collapse>
        </TableBody>
      </Table>
    </Grid>
    <Grid item xs={5}>
      { (state.transport_name === "FAV" || state.transport_name === "NEAR" || state.transport_name === "SRCH") ?
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <IconButton onClick={(event) => {event.stopPropagation(); handleCollapse()}} color="primary" component="span">
                  { open ? <ExpandLessIcon fontSize="small" color="primary"></ExpandLessIcon> : <ExpandMoreIcon color="primary"></ExpandMoreIcon> }
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <IconButton onClick={(event) => {event.stopPropagation(); toggleFavourites()}} color="primary" component="span">
                  { fav ? <StarIcon color="primary"></StarIcon> : <StarBorderOutlinedIcon color="primary"></StarBorderOutlinedIcon> }
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <div>
              <IconButton onClick={(event) => {event.stopPropagation(); handleClick()}} color="primary" component="span">
                <NavigateNextIcon color="primary"></NavigateNextIcon>
              </IconButton>
            </div>
          </Grid>
        </Grid>
      :
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <IconButton onClick={(event) => {event.stopPropagation(); handleCollapse()}} color="primary" component="span">
              { open ? <ExpandLessIcon fontSize="small" color="primary"></ExpandLessIcon> : <ExpandMoreIcon color="primary"></ExpandMoreIcon> }
            </IconButton>
          </Grid>
          <Grid item xs={6}>
            <IconButton onClick={(event) => {event.stopPropagation(); toggleFavourites()}} color="primary" component="span">
              { fav ? <StarIcon color="primary"></StarIcon> : <StarBorderOutlinedIcon color="primary"></StarBorderOutlinedIcon> }
            </IconButton>
          </Grid>
        </Grid>
      }
    </Grid>
  </Grid>

  let etaItem1 =
    <Grid container spacing={0}>
    <Grid item xs={7} >
      <Table size="small" aria-label="a dense table">
        <TableBody>
          <TableRow>
            <TableCell className={classes.table}>
              { routeStop[1].eta && <ETAView eta={routeStop[1].eta[0]}></ETAView> }
            </TableCell>
          </TableRow>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                <TableRow>
                  <TableCell>
                    { routeStop[1].eta && <ETAView eta={routeStop[1].eta[1]}></ETAView> }
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    { routeStop[1].eta && <ETAView eta={routeStop[1].eta[2]}></ETAView> }
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Collapse>
        </TableBody>
      </Table>
    </Grid>
    <Grid item xs={5}>
      { (state.transport_name === "FAV" || state.transport_name === "NEAR" || state.transport_name === "SRCH") ?
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <IconButton onClick={(event) => {event.stopPropagation(); handleCollapse()}} color="primary" component="span">
                  { open ? <ExpandLessIcon fontSize="small" color="primary"></ExpandLessIcon> : <ExpandMoreIcon color="primary"></ExpandMoreIcon> }
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <IconButton onClick={(event) => {event.stopPropagation(); toggleFavourites()}} color="primary" component="span">
                  { fav ? <StarIcon color="primary"></StarIcon> : <StarBorderOutlinedIcon color="primary"></StarBorderOutlinedIcon> }
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <div>
              <IconButton onClick={(event) => {event.stopPropagation(); handleClick()}} color="primary" component="span">
                <NavigateNextIcon color="primary"></NavigateNextIcon>
              </IconButton>
            </div>
          </Grid>
        </Grid>
      :
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <IconButton onClick={(event) => {event.stopPropagation(); handleCollapse()}} color="primary" component="span">
              { open ? <ExpandLessIcon fontSize="small" color="primary"></ExpandLessIcon> : <ExpandMoreIcon color="primary"></ExpandMoreIcon> }
            </IconButton>
          </Grid>
          <Grid item xs={6}>
            <IconButton onClick={(event) => {event.stopPropagation(); toggleFavourites()}} color="primary" component="span">
              { fav ? <StarIcon color="primary"></StarIcon> : <StarBorderOutlinedIcon color="primary"></StarBorderOutlinedIcon> }
            </IconButton>
          </Grid>
        </Grid>
      }
    </Grid>
  </Grid>

  if (width > 800) {
    etaItem0 =
    <Grid container spacing={1}>
      <Grid className={classes.paper} item xs={8} >
        <Table aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell>
                { routeStop[0].eta && <ETAView eta={routeStop[0].eta[0]}></ETAView> }
              </TableCell>
              <TableCell>
                { routeStop[0].eta && <ETAView eta={routeStop[0].eta[1]}></ETAView> }
              </TableCell>
              <TableCell>
              { routeStop[0].eta && <ETAView eta={routeStop[0].eta[2]}></ETAView> }
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={4}>
        <IconButton className={classes.icon} onClick={(event) => {event.stopPropagation(); toggleFavourites()}} variant="outlined" color="primary" component="span">
          { fav ? <StarIcon color="primary"></StarIcon> : <StarBorderOutlinedIcon color="primary"></StarBorderOutlinedIcon> }
        </IconButton>
        { (state.transport_name === "FAV" || state.transport_name === "NEAR" || state.transport_name === "SRCH") && 
          <IconButton onClick={(event) => {event.stopPropagation(); handleClick()}} variant="outlined" color="primary">
            <NavigateNextIcon color="primary"></NavigateNextIcon>
          </IconButton>
        }
      </Grid>
    </Grid>
    etaItem1 =
    <Grid container spacing={1}>
      <Grid className={classes.paper} item xs={8} >
        <Table aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell>
                { routeStop[1].eta && <ETAView eta={routeStop[1].eta[0]}></ETAView> }
              </TableCell>
              <TableCell>
                { routeStop[1].eta && <ETAView eta={routeStop[1].eta[1]}></ETAView> }
              </TableCell>
              <TableCell>
                { routeStop[1].eta && <ETAView eta={routeStop[1].eta[2]}></ETAView> }
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={4}>
        <IconButton className={classes.icon} onClick={(event) => {event.stopPropagation(); toggleFavourites()}} variant="outlined" color="primary" component="span">
          { fav ? <StarIcon color="primary"></StarIcon> : <StarBorderOutlinedIcon color="primary"></StarBorderOutlinedIcon> }
        </IconButton>
        { (state.transport_name === "FAV" || state.transport_name === "NEAR" || state.transport_name === "SRCH") && 
          <IconButton onClick={(event) => {event.stopPropagation(); handleClick()}} variant="outlined" color="primary">
            <NavigateNextIcon color="primary"></NavigateNextIcon>
          </IconButton>
        }
      </Grid>
    </Grid>
  }

  let etaHead0 = <>
    { getTransportName(routeStop[0].transport_name) }
    <h4 style={{margin: 0}}>{ routeStop[0].route_name }</h4>
    { routeStop[0].stop_name }<br />
    往 { routeStop[0].dest_stop_name }<br />
    { routeStop[0].last_stop_name } 方向
  </>;
  let etaHead1 = <>
    { getTransportName(routeStop[1].transport_name) }
    <h4 style={{margin: 0}}>{ routeStop[1].route_name }</h4>
    { routeStop[1].stop_name }<br />
    往 { routeStop[1].dest_stop_name }<br />
    { routeStop[1].last_stop_name } 方向
  </>;

  return (
    <>
      <TableRow>
        <TableCell colSpan={2} align="center">
          <h3>在 { routeStop[0].stop_name } 乘搭 {getTransportName(routeStop[0].transport_name) + routeStop[0].route_name }
              {} 到 {routeStop[0].dest_stop_name} 轉乘 
              {} {getTransportName(routeStop[1].transport_name)}
              {} 到 {routeStop[1].dest_stop_name}
          </h3>
        </TableCell>
      </TableRow>
      <TableRow onClick={handleClick}>
        <TableCell>
          { etaHead0 }
        </TableCell>
        <TableCell>
          { etaItem0 }
        </TableCell>
      </TableRow>
      <TableRow onClick={handleClick}>
        <TableCell>
          { etaHead1 }
        </TableCell>
        <TableCell>
          { etaItem1 }
        </TableCell>
      </TableRow>
    </>
  );
}

