import React, { useEffect, useState } from "react";
import "../../App.css";
import StockInfoCard, { StockInfoCardUS } from "../views/StockInfoCard";
import StockChart2T from "../charts/StockChart2T";
import RSChart, { RSChartView, RSChartViewUS } from "../charts/RSChart";
import ShareBarChart from "../charts/ShareBarChart";
import ShareBarChartChange from "../charts/ShareBarChartChange";
import KeyholderChart from "../charts/KeyholderChart";
import KeyHolderPctChart2 from "../charts/KeyHolderPctChart2";
import {
  Container,
  Segment,
  Header,
  Popup,
  Icon,
  Loader,
  Button,
  Message,
} from "semantic-ui-react";
import MetaTags from "react-meta-tags";
import { useSelector, useDispatch } from "react-redux";
import {
  selectAllStock,
  fetchStock,
  fetchUSStock,
  toIdle,
} from "../../features/stock/stockSlice";
import { selectAllTopic } from "../../features/topic/topicSlice";
import { useParams } from "react-router";
import DisplayAdsBanner from "../ads/DisplayAdsBanner";
import ShareholdingByStock from "../views/shareholding/ShareholdingByStock";
import GoogleAd from "../ads/Google_ad";

export const StockViewContainer10T = ({ match }) => {
  const dispatch = useDispatch();
  const stocks = useSelector(selectAllStock);
  const topics = useSelector(selectAllTopic);
  const { stockId } = useParams();
  const stockStatus = useSelector((state) => state.stock.status);
  const topicStatus = useSelector((state) => state.topic.status);
  const error = useSelector((state) => state.stock.error);
  let stockMkt = /^\d+$/.test(stockId[0]) ? "HK" : "US";
  // console.log(stockMkt);
  // console.log(stockStatus);

  useEffect(() => {
    if (stockStatus === "idle") {
      if (true) {
        if (stockMkt === "HK") {
          dispatch(fetchStock(stockId));
        } else {
          dispatch(fetchUSStock(stockId));
        }
        //
      }
    }
  }, [stockId, stockStatus, dispatch, stockMkt]);

  let content;

  if (stockStatus === "loading") {
    content = (
      <div>
        <section className="posts-list">
          <div
            style={{
              padding: "100px",
              margin: "auto",
              alignItems: "center",
              height: "75vh",
              // overflow: "auto",
            }}
          >
            <Loader active inline="centered">
              {" "}
              Loading
            </Loader>
          </div>
        </section>
      </div>
    );
  } else if (stockStatus === "succeeded" && topicStatus === "succeeded") {
    // console.log(data);
    // console.log("data");
    const data = stocks.find((item) => item.stockInfo.no === stockId);
    let stockName = {};
    if (stockMkt === "US") {
      stockName = topics
        .find((item) => item.topicInfo.name === "stockDict_all_n")
        .data.find((stock) => stock.id === stockId);
    }
    if (data) {
      content =
        stockMkt === "HK" ? (
          <StockView result={data} />
        ) : (
          <StockViewUS result={data} stockName={stockName} />
        );
    } else {
      dispatch(toIdle({}));
    }
  } else if (stockStatus === "error") {
    content = (
      <div>
        <section className="posts-list">
          <div>{error}</div>
        </section>
      </div>
    );
  }
  return (
    <>
      <MetaTags>
        <title>{stockId}</title>
        <meta name="description" content={stockId} />
        <meta property="og:title" content={stockId} />
        <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
      </MetaTags>
      {content}
    </>
  );
};

function StockViewUS(props) {
  const { result, stockName } = props;
  let { data, topicInfo } = result;
  topicInfo = {
    ...topicInfo,
    name_en: stockName["zh"] !== "-" ? stockName["en"] : "",
    name_zh: stockName["zh"] !== "-" ? stockName["zh"] : stockName["en"],
    no: topicInfo["stockCode"],
  };
  // console.log(topicInfo);
  return (
    <>
      <MetaTags>
        <title>
          {topicInfo.name_zh}({topicInfo.no})
        </title>
        <meta name="description" content={topicInfo.no} />
        <meta property="og:title" content={topicInfo.no} />
        <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
      </MetaTags>
      <Container textAlign="center">
        <DisplayAdsBanner />
      </Container>

      <Segment style={{ padding: "2em 0em" }} vertical>
        {/* <Container>
          <div style={{ paddingBottom: "10px" }}>
            <Message size="big" color="yellow" style={{ textAlign: "center" }}>
              美股功能測試中
            </Message>
          </div>
        </Container> */}
        <Container>
          <StockInfoCard info={topicInfo} />
        </Container>

        <Container>
          <RSChartViewUS data={result} />
        </Container>

        <Container>
          <GoogleAd
            client="ca-pub-8753358837449417"
            slot="4377549893"
            format="auto"
            wrapperDivStyle={{
              marginTop: "30px",
              marginBottom: "30px",
            }}
          />
        </Container>
      </Segment>
    </>
  );
}

function StockView(data) {
  const [activeChart, setActiveChart] = useState("0");
  let { result } = data;
  // console.log(result)
  // const today = new Date()
  // const date = today.getFullYear() + "" + ("0" + (today.getMonth() + 1)).slice(-2) + "" + today.getDate();
  // const dStockId = result.stockInfo.dStockId
  // let disclosureLink
  // if (dStockId) {
  //   disclosureLink = "https://www1.hkexnews.hk/search/titlesearch.xhtml?lang=ZH&category=0&market=SEHK&searchType=0&documentType=&t1code=&t2Gcode=&t2code=&stockId=" + dStockId + "&from=19990401&to=" + date + "&MB-Daterange=0&title="
  // } else {
  //   disclosureLink = undefined
  // }
  return (
    <>
      <MetaTags>
        <title>
          {result.stockInfo.name_zh}({result.stockInfo.no})
        </title>
        <meta name="description" content={result.stockInfo.name_zh} />
        <meta property="og:title" content={result.stockInfo.name_zh} />
        <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
      </MetaTags>
      <Container textAlign="center">
        <DisplayAdsBanner />
      </Container>

      <Segment style={{ padding: "2em 0em" }} vertical>
        <Container>
          <StockInfoCard info={result.stockInfo} />
        </Container>
        <Container
          style={{
            paddingLeft: "15px",
            paddingTop: "5px",
            paddingBottom: "5px",
          }}
        >
          <Button
            style={{ borderRadius: "15px" }}
            active={activeChart === "0"}
            onClick={() => {
              setActiveChart("0");
            }}
            size="tiny"
          >
            CCASS
          </Button>
          <Button
            style={{ borderRadius: "15px" }}
            active={activeChart === "1"}
            onClick={() => {
              setActiveChart("1");
            }}
            size="tiny"
          >
            RS
          </Button>
        </Container>

        <Container>
          {activeChart === "0" ? (
            <StockChart2T chartData={result} />
          ) : (
            <RSChart id={result.stockInfo.no} />
          )}
        </Container>

        <Container style={{ paddingTop: "20px" }}>
          <Header as="h6" textAlign="right">
            <Popup
              on="click"
              pinned
              position="top right"
              size="large"
              trigger={
                <Icon size="small" color="grey" name="question circle" />
              }
            >
              <p>
                顏色部份顯示十大經紀商（當中包括不願意披露/不在中央結算）分別的持股比例,黑色部份為十大經紀商以外其他經紀商的總持股比例分佈。由此圖可觀察出股權集中情況
              </p>
              <p>股權集中股票股價波動較大，可能會迅速下跌或拉昇。</p>
            </Popup>
          </Header>
          <Header as="h2" textAlign="center">
            十大經紀商股權分佈
          </Header>
        </Container>

        <Container>
          <KeyholderChart keyholderData={result.keyholder} />
        </Container>

        {/* <Container textAlign="center">
          <DisplayAdsBanner />
        </Container> */}
        <Container>
          <GoogleAd
            client="ca-pub-8753358837449417"
            slot="4377549893"
            format="auto"
            wrapperDivStyle={{
              marginTop: "30px",
              marginBottom: "30px",
            }}
          />
        </Container>

        {result.keyHolderPctChg2 ? (
          <>
            <Container style={{ paddingTop: "20px" }}>
              <Header as="h6" textAlign="right">
                <Popup
                  on="click"
                  pinned
                  position="top right"
                  size="large"
                  trigger={
                    <Icon size="small" color="grey" name="question circle" />
                  }
                >
                  <p>
                    {" "}
                    圖表顯示持股量最大的十個經紀商在過去N個交易天的持股量跟過去第N+1個交易天的持股量改變。
                  </p>
                  <p>
                    {" "}
                    大戶持股例變化。一定程度能反映其投資者對後市股價的態度。{" "}
                  </p>
                  <p>
                    例子: 圖表在第N個交易天的數據為其經紀商在當天的持股量 -
                    其經紀商在第零天的持股量。
                  </p>
                </Popup>
              </Header>
              <Header as="h3" textAlign="center">
                十大經紀商累計買入/賣出股數
                <Header.Subheader>
                  (顯示最近N個交易天) - {result.stockInfo.no}.HK
                </Header.Subheader>
              </Header>
            </Container>

            <Container>
              <KeyHolderPctChart2
                HolderPctChg={{
                  keyHolderPctChg: result.keyHolderPctChg2,
                  ClosePrice: result.close,
                }}
              />
            </Container>

            <Container style={{ paddingTop: "20px" }}>
              <Header as="h6" textAlign="right">
                <Popup
                  on="click"
                  pinned
                  position="top right"
                  size="large"
                  trigger={
                    <Icon size="small" color="grey" name="question circle" />
                  }
                >
                  <p> 十大活躍經紀商為過去N個交易天持股量變化最大的經紀商。</p>
                  <p>
                    {" "}
                    圖表顯示持股量變化最大的十個經紀商在過去N個交易天的持股量跟過去第N+1個交易天的持股量改變。
                  </p>
                  <p>
                    {" "}
                    大戶持股例變化。一定程度能反映其投資者對後市股價的態度。{" "}
                  </p>
                  <p>
                    例子: 圖表在第N個交易天的數據為其經紀商在當天的持股量 -
                    其經紀商在第零天的持股量。
                  </p>
                </Popup>
              </Header>
              <Header as="h3" textAlign="center">
                十大活躍經紀商累計買入/賣出股數
                <Header.Subheader>
                  (顯示最近N個交易天) - {result.stockInfo.no}.HK
                </Header.Subheader>
              </Header>
            </Container>

            <Container>
              <KeyHolderPctChart2
                HolderPctChg={{
                  keyHolderPctChg: result.activeHolderPctChg2,
                  ClosePrice: result.close,
                }}
              />
            </Container>
          </>
        ) : null}

        <Container textAlign="center">
          <DisplayAdsBanner />
        </Container>

        <Container style={{ paddingTop: "20px" }}>
          <Header as="h6" textAlign="right"></Header>
          <Header as="h2" textAlign="center">
            經紀商持股比例
            <Header.Subheader>{result.stockInfo.no}.HK</Header.Subheader>
          </Header>
        </Container>

        <Container>
          <ShareBarChart shareholdingData={result.shareholding} />
        </Container>

        <Container style={{ paddingTop: "20px" }}>
          <Header as="h6" textAlign="right"></Header>
          <Header as="h2" textAlign="center">
            經紀商持股改變
            <Header.Subheader>
              (顯示最近一個交易天) - {result.stockInfo.no}.HK
            </Header.Subheader>
          </Header>
        </Container>

        <Container>
          <ShareBarChartChange shareholdingData={result.delta} />
        </Container>

        {/*
        <Container >
          <ShareholdingByStock stockCode={result.stockInfo.no} />
        </Container>
        */}

        {/* <Container textAlign='center'>
          <DisplayAdsBanner />
        </Container> */}

        {/* <SortableBigChangeByStockTable stock={'0' + result.stockInfo.no} ></SortableBigChangeByStockTable> */}

        <Container>
          <GoogleAd
            client="ca-pub-8753358837449417"
            slot="4377549893"
            format="auto"
            wrapperDivStyle={{
              marginTop: "30px",
              marginBottom: "30px",
            }}
          />
        </Container>

        {/* <Container style={{ paddingTop: '15px', paddingBottom: "25px" }}>
          <Header as='h2' icon='linkify'>
            連結
          </Header>
          <List>
            {
              disclosureLink ?
                <List.Item>
                  <a target="_blank" rel="noopener noreferrer" href={disclosureLink}>
                    HKEX 公告
                  </a>
                </List.Item> :
                null
            }

            <List.Item>
              <a target="_blank" rel="noopener noreferrer" href='https://www.hkexnews.hk/sdw/search/searchsdw_c.aspx'>
                中央結算系統持股紀錄查詢服務
              </a>
            </List.Item>
          </List>
        </Container> */}
      </Segment>
    </>
  );
}

export default StockViewContainer10T;
