import React, {useEffect } from "react";
import { Loader, Container, Header } from 'semantic-ui-react'
import MetaTags from 'react-meta-tags';
import CompanyResultTable from '../tables/CompanyResultTable'
import { selectAllTopic, fetchTopic, toIdle } from '../../features/topic/topicSlice'
import { useSelector, useDispatch } from 'react-redux'
import DisplayAdsBanner from '../ads/DisplayAdsBanner';
import GoogleAd from '../ads/Google_ad';

export const TopicCompanyResultSchedule = () => {
  return (
    <>
      <MetaTags>
          <title>業績公佈時間表</title>
          <meta name="description" content= '業績公佈時間表' />
          <meta property="og:title" content= '業績公佈時間表' />
          <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
      </MetaTags>
      <Container textAlign='center'>
        <DisplayAdsBanner />
      </Container>
      <div style={{paddingTop:'25px', textAlign: 'center' }}>
        <Header as='h1'> 業績公佈時間表</Header>
      </div>


      <div>
        <div style={{ paddingBottom:'25px'}}>
          <CompanyResultScheduleContainer /> 
        </div>
      </div>
      <Container textAlign='center' style={{paddingTop:'30px', paddingBottom:'0px'}}>
          <GoogleAd 
              client="ca-pub-8753358837449417" 
              slot="2486257452" 
              format="auto" 
              wrapperDivStyle={{
              marginTop: '30px',
              marginBottom: '20px'
              }}
          />
      </Container>
    </>
  )
}

export const CompanyResultScheduleContainer = () => {
    const dispatch = useDispatch()
    const topics = useSelector(selectAllTopic)
    const topicStatus = useSelector((state) => state.topic.status)
    const error = useSelector((state) => state.topic.error)

    useEffect(() => {
        if (topicStatus === 'idle') {
            dispatch(fetchTopic('resultA'))
        }
    }, [topicStatus, dispatch])

    let content
    if (topicStatus === 'loading') {
      content =
        <div>
          <section className="posts-list">
            <div
              style={{
                padding: "100px",
                margin: "auto",
                alignItems: "center",
                height: "75vh",
                // overflow: "auto",
              }}
            >
              <Loader active inline='centered' > Loading</Loader>
            </div>
          </section>
        </div>
    } else if (topicStatus === 'succeeded') {
        const data = topics.find((item) => item.topicInfo.name === 'resultA')
        if (data){
          content = <ResultAView result ={data} />
        } else{
            dispatch(toIdle({}))
        }
    } else if (topicStatus === 'error') {
      content = 
        <div>
          <section className="posts-list">
            <div>{error}</div>
          </section>
        </div>
    }
  return (
    <>
      {/* <MetaTags>
          <title>業績公佈時間表</title>
          <meta name="description" content= '業績公佈時間表' />
          <meta property="og:title" content= '業績公佈時間表' />
          <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
      </MetaTags> */}
      {content}
    </>
  )
}

function ResultAView(data){
  let result = data.result
  return(
    <>
      {/* <Container textAlign='center'>
        <DisplayAdsBanner />
      </Container> */}
      <div style={{paddingTop:'25px', paddingBottom:'25px'}}>
          { typeof result !== 'undefined' ? <CompanyResultTable  data={result} /> : <NotFound/>}
      </div>
    </>
  )
}

function NotFound(){
  return (
      <div
        style={{
          padding: "100px",
          margin: "auto",
          alignItems: "center",
          height: "75vh",
          // overflow: "auto",
        }}
      >
        <Loader active inline='centered' > Loading</Loader>
      </div>
  )
}

export default TopicCompanyResultSchedule;