import React from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { firebaseApp } from "config/fbConfig";
import { useHistory } from "react-router-dom";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
import { Card, Modal } from "semantic-ui-react";

const FirebaseLoginForm = (props) => {
  const auth = getAuth(firebaseApp);
  const history = useHistory();
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      GoogleAuthProvider.PROVIDER_ID,
      // FacebookAuthProvider.PROVIDER_ID
    ],
    callbacks: {
      signInSuccessWithAuthResult: (authResult) => {
        let user = {
          uid: authResult.user.uid,
          displayName: authResult.user.displayName,
          email: authResult.user.email,
          photoURL: authResult.user.photoURL,
        };
        if (props.onLoginCallback) {
          props.onLoginCallback(user);
        }
        if (props.redirectUrl) {
          history.push(props.redirectUrl);
        }
        return false;
      },
    },
  };
  return <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />;
};

function FireBaseLoginModal(props) {
  const { prompt, trigger, children } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={trigger || children}
      size="mini"
    >
      <Modal.Header>{prompt}</Modal.Header>
      <Modal.Content>
        <FirebaseLoginForm />
      </Modal.Content>
    </Modal>
  );
}

function FirebaseLoginCard(props) {
  const { redirectUrl, onLoginCallback } = props;
  return (
    <Card centered>
      <Card.Content>
        <Card.Header textAlign="center">以Google帳號登入</Card.Header>
        <FirebaseLoginForm
          redirectUrl={redirectUrl}
          onLoginCallback={onLoginCallback}
        />
      </Card.Content>
    </Card>
  );
}

export { FirebaseLoginForm, FireBaseLoginModal, FirebaseLoginCard };
