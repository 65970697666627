import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { analytics, functions} from '../../config/fbConfig';
import { logEvent } from "firebase/analytics";
import { httpsCallable } from "firebase/functions";

const initialState = {
  stockList: {},
  status: 'idle',
  error: null,
}

const getStockList = httpsCallable(functions, 'stock_list');

export const fetchStockListData = createAsyncThunk('index/fetchStockList', async () => {
  logEvent(analytics, "select_content", {
    content_type: "stock_list",
  });
  const result = await getStockList({
    language_code: "zh-hk",
  });
  return (result.data.data)
});

const stockListSlice = createSlice({
  name: 'stockList',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchStockListData.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchStockListData.fulfilled]: (state, action) => {
      state.stockList = action.payload
      state.stockMap = {}
      for (let i in state.stockList) {
        state.stockMap[state.stockList[i].stock_code] = state.stockList[i];
      }
      state.status = 'succeeded'
    },
    [fetchStockListData.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    },
  },
})

export default stockListSlice.reducer
