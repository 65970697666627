import { useState, useEffect } from "react";
import { TextField, FormHelperText, Container } from "@material-ui/core";
import { Button, Grid } from "@material-ui/core";
import { TableContainer, Table, TableRow, TableCell } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import RouteStopTable from "./RouteStopTable";
import { convHK1980GridToWGS84LatLong } from "../lib/ETAUtility";

export default function SearchRouteTable(props) {
  const [searchSrc, setSearchSrc] = useState({
    data: [{ nameZH: "現在所在地點", curr: true }],
  });

  const [searchDest, setSearchDest] = useState({
    data: [{ nameZH: "現在所在地點", curr: true }],
  });

  const [currPos, setCurrPos] = useState({
    loaded: false,
    pos: null,
  });

  const [srcPos, setSrcPos] = useState({
    pos: null,
    name: null,
    inputName: "",
  });

  const [destPos, setDestPos] = useState({
    pos: null,
    name: null,
    inputName: "",
  });

  const [srcTimer, setSrcTimer] = useState(null);
  const [destTimer, setDestTimer] = useState(null);

  function showPosition(position) {
    setCurrPos({
      loaded: true,
      pos: position.coords,
    });
  }
  function showErrPosition(err) {
    setCurrPos({
      loaded: true,
      pos: null,
    });
  }

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showErrPosition);
    }
  }, []);

  useEffect(() => {
    if (state.transport_name === "FAV") {
      setState({
        ...state,
        search: null,
      });
    }
  }, []);

  const state = props.state;
  const setState = props.onStateChange;

  const handleSelect = (event, value, pos, setPos, timer, setTimer) => {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    if (value) {
      let coor = null;
      let name = value.nameZH;
      if (!value.curr) {
        const latLong = convHK1980GridToWGS84LatLong(value.y, value.x);
        coor = {
          latitude: latLong.latitude,
          longitude: latLong.longitude,
        };
      }
      if (value.curr && currPos.pos) {
        coor = {
          latitude: currPos.pos.latitude,
          longitude: currPos.pos.longitude,
        };
      }
      setPos({
        ...pos,
        pos: coor,
        name: name,
      });
    } else {
      setPos({
        ...pos,
        pos: null,
        name: "",
        inputName: "",
      });
    }
  };

  const handleInput = (
    event,
    value,
    pos,
    setPos,
    timer,
    setTimer,
    search,
    setSearch
  ) => {
    if (pos.name !== value) {
      setPos({
        ...pos,
        pos: null,
        inputName: value,
      });
    } else {
      setPos({
        ...pos,
        inputName: value,
      });
    }

    if (destTimer) {
      clearTimeout(destTimer);
    }
    if (value !== "") {
      setTimer(
        setTimeout(() => {
          const url =
            "https://geodata.gov.hk/gs/api/v1.0.0/locationSearch?q=" + value;
          fetch(url)
            .then(function (response) {
              return response.json();
            })
            .then(function (json) {
              let temp = Object.assign([], json);
              temp.unshift({ nameZH: "現在所在地點", curr: true });
              setSearch({
                ...search,
                data: temp,
              });
            });
        }, 200)
      );
    } else {
      setSearch({
        ...search,
        data: [],
      });
    }
  };

  const getError = () => {
    let error = "";
    if (srcPos.name === "" || srcPos.inputName === "") {
      error = error + "起點不能留空。";
    }
    if (destPos.name === "" || destPos.inputName === "") {
      error = error + "目的地不能留空。";
    }
    return error;
  };

  const getWarning = () => {
    let warning = "";
    if (currPos.pos === null && currPos.loaded) {
      warning = "請開啟及准許開定位功能。";
    }
    return warning;
  };

  const handleSubmit = (event) => {
    setState({
      ...state,
      transport_name: "SRCH",
      search: {
        src_pos: srcPos.pos,
        src_stop_name: srcPos.name,
        dest_pos: destPos.pos,
        dest_stop_name: destPos.name,
      },
    });
  };

  const srcTextField = (
    <Autocomplete
      fullWidth
      forcePopupIcon={false}
      options={searchSrc.data}
      getOptionDisabled={(option) => {
        return option.curr && null === currPos.pos;
      }}
      getOptionLabel={(element) => {
        if (element.nameZH) {
          let nameZH = element.nameZH;
          return nameZH;
        }
        if (element.addressZH) {
          let addressZH = element.addressZH;
          return addressZH;
        }
        return element;
      }}
      filterOptions={(options, state) => options}
      value={srcPos.name}
      onChange={(event, value) =>
        handleSelect(event, value, srcPos, setSrcPos, srcTimer, setSrcTimer)
      }
      inputValue={srcPos.inputName}
      onInputChange={(event, value) =>
        handleInput(
          event,
          value,
          srcPos,
          setSrcPos,
          srcTimer,
          setSrcTimer,
          searchSrc,
          setSearchSrc
        )
      }
      renderInput={(params) => (
        <TextField
          fullWidth
          {...params}
          label="起點"
          variant="outlined"
          InputProps={{ ...params.InputProps, type: "search" }}
        />
      )}
    />
  );
  const destTextField = (
    <Autocomplete
      fullWidth
      forcePopupIcon={false}
      options={searchDest.data}
      getOptionDisabled={(option) => {
        return option.curr && null === currPos.pos;
      }}
      getOptionLabel={(element) => {
        if (element.nameZH) {
          let nameZH = element.nameZH;
          return nameZH;
        }
        if (element.addressZH) {
          let addressZH = element.addressZH;
          return addressZH;
        }
        return element;
      }}
      filterOptions={(options, state) => options}
      value={destPos.name}
      onChange={(event, value) =>
        handleSelect(event, value, destPos, setDestPos, destTimer, setDestTimer)
      }
      inputValue={destPos.inputName}
      onInputChange={(event, value) =>
        handleInput(
          event,
          value,
          destPos,
          setDestPos,
          destTimer,
          setDestTimer,
          searchDest,
          setSearchDest
        )
      }
      renderInput={(params) => (
        <TextField
          fullWidth
          {...params}
          label="目的地"
          variant="outlined"
          InputProps={{ ...params.InputProps, type: "search" }}
        />
      )}
    />
  );

  const show_route_stop = state.search;

  return (
    <>
      {show_route_stop && (
        <>
          <RouteStopTable
            state={state}
            onStateChange={(state) => setState(state)}
          ></RouteStopTable>
        </>
      )}
      {!show_route_stop && (
        <Container maxWidth="md">
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableRow>
                <TableCell align="center" width="80%">
                  <h1>搜尋路線</h1>
                </TableCell>
              </TableRow>
            </Table>
          </TableContainer>
          <div style={{ margin: 10 }}></div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {srcTextField}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {destTextField}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                disabled={getError() !== ""}
                onClick={handleSubmit}
              >
                搜尋
              </Button>
              <FormHelperText color="secondary"> {getError()} </FormHelperText>
              <FormHelperText color="secondary">
                {" "}
                {getWarning()}{" "}
              </FormHelperText>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
}
