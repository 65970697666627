import React, { useState } from 'react'
import { Button, Card, Dimmer, Grid, Loader, Segment } from 'semantic-ui-react'
import { getPortalLink } from 'components/subscription/subscribe'

const MySubscription = (props) => {
  const subs = props.subscriptions
  const contents = []
  const [isLoading, setLoading] = useState(false)
  const onClick = async () => {
    setLoading(true);
    const data = await getPortalLink()
    window.location.assign(data.url);
  }
  for (let i = 0; i < subs.length; ++i) {
    const sub = subs[i]
    const current_period_end = new Date(sub.current_period_end.seconds * 1000)
    const item = sub.items[0] // assume only one item?
    const product = item.price.product
    const msg = sub.cancel_at_period_end? "您的訂閱計劃將會在到期日取消，在到期日前您仍可續訂": "您的訂閱計劃將會在到期日自動續期"
    const content =
      (
        <Card.Description key={`mysub${i}`}>
          <div style={{ textAlign: "center" }}>{product.name}</div>
          <div style={{ textAlign: "center" }}>到期日：{current_period_end.toLocaleDateString()}</div>
          <div style={{padding: "0.5em"}}></div>
          <div style={{ textAlign: "center" }}>{msg}</div>
        </Card.Description>
      )
    contents.push(content)
  }

  return (

    <Segment basic>
      {isLoading ? <Dimmer active inverted><Loader size='medium'>Loading</Loader></Dimmer> : undefined}
      <Card.Group style={{ padding: "1em 0em" }}>
        <Card fluid color='blue'>
          <Card.Content>
            <Card.Header textAlign="center">
              現時訂閱計劃
            </Card.Header>
          </Card.Content>
          <Card.Content>
            {contents}
          </Card.Content>
          <Card.Content extra>
            <Grid>
              <Grid.Column textAlign="center">
                <Button onClick={onClick}>管理我的訂閱</Button>
              </Grid.Column>
            </Grid>
          </Card.Content>
        </Card>
      </Card.Group>
    </Segment>
  )
}

export default MySubscription
