import './ShareholdingByParticipant.css';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import MetaTags from 'react-meta-tags';
import { Container, Header, Divider, Loader, Menu } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'

import DisplayAdsBanner from '../../ads/DisplayAdsBanner';
import GoogleAd from '../../ads/Google_ad';
import ShareholdingByParticipantTable from "../../tables/shareholding/ShareholdingByParticipantTable";
import ShareholdingChangeByParticipantTable from "../../tables/shareholding/ShareholdingChangeByParticipantTable";
import { fetchStockListData } from '../../../features/stock/stockListSlice'
import { fetchParticipantListData } from '../../../features/participant/participantListSlice'
import { fetchShareholdingDateListData } from '../../../features/shareholding/shareholdingDateListSlice'

export default function ShareholdingByParticipant() {
  const dispatch = useDispatch();
  const stockListStatus = useSelector(state => state.stockList.status);
  const participantListData = useSelector(state => state.participantList);
  const participantListStatus = useSelector(state => state.participantList.status);
  const shareholdingDateListStatus = useSelector(state => state.shareholdingDateList.status);

  const [ activeItem, setActiveItem ] = useState("持倉變動");
  const [ isMobile, setMobile ] = useState(false);
  const { participant_code } = useParams();

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return (() => {
        window.removeEventListener("resize", handleResize);
      }
    );
  }, []);

  const handleResize = () => {
    const widths = [window.innerWidth];
    if (window.screen?.width) {
      widths.push(window.screen?.width);
    }
    const width = Math.min(...widths);
    
    var isMobile = false;
    if (width < 800) {
      isMobile = true;
    }
    setMobile(isMobile);
  }

  const handleItemClick = (event, data) => {
    setActiveItem(data.name);
  }

  useEffect(() => {
    if (stockListStatus === 'idle') {
      dispatch(fetchStockListData());
    } else if (stockListStatus === 'succeeded') {
    }
  }, [stockListStatus, dispatch]);

  useEffect(() => {
    if (participantListStatus === 'idle') {
      dispatch(fetchParticipantListData());
    } else if (participantListStatus === 'succeeded') {
    }
  }, [participantListStatus, dispatch]);

  useEffect(() => {
    if (shareholdingDateListStatus === 'idle') {
      dispatch(fetchShareholdingDateListData());
    } else if (shareholdingDateListStatus === 'succeeded') {
    }
  }, [shareholdingDateListStatus, dispatch]);

  let dataReady = false;
  if (stockListStatus === 'succeeded' &&
      participantListStatus === 'succeeded' &&
      shareholdingDateListStatus === 'succeeded') {
    dataReady = true;
  }

  let title = participant_code
  if (dataReady) {
    if (participantListData.participantMap[participant_code]) {
      title = participantListData.participantMap[participant_code].short_name;
    }
  }

  let content = <></>;

  if (dataReady) {
    content = 
      <>
        <DisplayAdsBanner />
        <Divider horizontal>
          <Header as='h1'>
            {title}
          </Header>
        </Divider>
        <div className='tabDiv'>
          <div style={{ display: 'inline-block' }} >
            <Menu compact pointing secondary size='large' style={{margin:'auto'}}>
              <Menu.Item
                name="持倉變動"
                active={activeItem === "持倉變動"}
                onClick={handleItemClick}
              >
              持倉變動
              </Menu.Item>
              <Menu.Item
                name="持倉"
                active={activeItem === "持倉"}
                onClick={handleItemClick}
              >
              持倉
              </Menu.Item>
            </Menu>
          </div>
        </div>
        { activeItem === "持倉變動" && <ShareholdingChangeByParticipantTable participantCode={participant_code} /> }
        { activeItem === "持倉" && <ShareholdingByParticipantTable participantCode={participant_code} /> }
        <div style={{paddingTop:'30px', paddingBottom:'0px'}}>
            <GoogleAd 
                client="ca-pub-8753358837449417" 
                slot="2486257452" 
                format="auto" 
                wrapperDivStyle={{
                marginTop: '30px',
                marginBottom: '20px'
                }}
            />
        </div>
      </>;
  }
  if (!isMobile) {
    content =
      <Container textAlign='center'>
        {content}
      </Container>;
  }

  return (
    <>
      <MetaTags>
        <title>{title}</title>
        <meta name="description" content= {title} />
        <meta property="og:title" content= {title} />
        <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
      </MetaTags>
      { !dataReady && (<Loader active inline='centered'>Loading</Loader>) }
      { content } 
    </>
  );
}