import './ShareholdingChangeContainer.css';
import React, { useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { httpsCallable } from "firebase/functions";
import { Link } from "react-router-dom";
import { Container, Table, Header, Icon, Divider, Loader } from 'semantic-ui-react'
import SemanticDatepicker from 'react-semantic-ui-datepickers';

import { functions } from "../../../config/fbConfig"
import DisplayAdsBanner from '../../ads/DisplayAdsBanner';
import GoogleAd from '../../ads/Google_ad';
import SortableTable from "../../tables/SortableTable";
import { toDateString } from '../../../util/datetime'
import { formatSharePercentage, formatShare, formatStockCode } from '../../../util/dataFormatter'
import { useSelector, useDispatch } from 'react-redux'
import { fetchStockListData } from '../../../features/stock/stockListSlice'
import { fetchParticipantListData } from '../../../features/participant/participantListSlice'
import { fetchShareholdingDateListData } from '../../../features/shareholding/shareholdingDateListSlice'
import { fetchLatestShareholdingTopChangeData } from '../../../features/shareholding/latestShareholdingTopChangeSlice'
import source from "../../../mock_source"

export default function ShareholdingChangeContainer() {
  const dispatch = useDispatch();

  const stockListData = useSelector(state => state.stockList);
  const stockListStatus = useSelector(state => state.stockList.status);
  const participantListData = useSelector(state => state.participantList);
  const participantListStatus = useSelector(state => state.participantList.status);
  const shareholdingDateListData = useSelector(state => state.shareholdingDateList);
  const shareholdingDateListStatus = useSelector(state => state.shareholdingDateList.status);
  const latestShareholdingTopChangeData = useSelector(state => state.latestShareholdingTopChange);
  const latestShareholdingTopChangeStatus = useSelector(state => state.latestShareholdingTopChange.status);

  const [ shareholdingChange, setShareholdingChange ] = useState({list: []});
  const [ resultLoaded, setResultLoaded ] = useState(false);
  const [ isMobile, setMobile ] = useState(false);
  const [ selectFromDate , setSelectFromDate] = useState(new Date());
  const [ selectToDate , setSelectToDate] = useState(new Date());
  const getShareholdingChange = httpsCallable(functions, 'shareholding_change');
  const getBufferShareholdingChange = httpsCallable(functions, 'buffer_shareholding_change');

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return (() => {
        window.removeEventListener("resize", handleResize);
      }
    );
  }, []);

  const handleResize = () => {
    const widths = [window.innerWidth];
    if (window.screen?.width) {
      widths.push(window.screen?.width);
    }
    const width = Math.min(...widths);
    
    var isMobile = false;
    if (width < 800) {
      isMobile = true;
    }
    setMobile(isMobile);
  }

  useEffect(() => {
    if (stockListStatus === 'idle') {
      console.log("stockListStatus: " + stockListStatus);
      dispatch(fetchStockListData());
    } else if (stockListStatus === 'succeeded') {
      console.log("stockListStatus: " + stockListStatus);
    }
  }, [stockListStatus, dispatch]);

  useEffect(() => {
    if (participantListStatus === 'idle') {
      console.log("participantListStatus: " + participantListStatus);
      dispatch(fetchParticipantListData());
    } else if (participantListStatus === 'succeeded') {
      console.log("participantListStatus: " + participantListStatus);
    }
  }, [participantListStatus, dispatch]);

  useEffect(() => {
    if (shareholdingDateListStatus === 'idle') {
      console.log("shareholdingDateListStatus: " + shareholdingDateListStatus);
      dispatch(fetchShareholdingDateListData());
    } else if (shareholdingDateListStatus === 'succeeded') {
      console.log("shareholdingDateListStatus: " + shareholdingDateListStatus);
    }
  }, [shareholdingDateListStatus, dispatch]);

  useEffect(() => {
    if (latestShareholdingTopChangeStatus === 'idle') {
      dispatch(fetchLatestShareholdingTopChangeData());
    } else if (latestShareholdingTopChangeStatus === 'succeeded') {
      setShareholdingChange({list: latestShareholdingTopChangeData.latestShareholdingTopChange});
      if (latestShareholdingTopChangeData.latestShareholdingTopChange.length > 0) {
        setSelectFromDate(new Date(latestShareholdingTopChangeData.latestShareholdingTopChange[0].from_date));
        setSelectToDate(new Date(latestShareholdingTopChangeData.latestShareholdingTopChange[0].to_date));
      }
      setResultLoaded(true);
    }
  }, [latestShareholdingTopChangeStatus, dispatch]);

  let dataReady = false;
  if (stockListStatus === 'succeeded' &&
      participantListStatus === 'succeeded' &&
      shareholdingDateListStatus === 'succeeded') {
    dataReady = true;
  }

  useEffect(() => {
    let from_date = new Date();
    let to_date = new Date();
    let day_ms = 86400000;
    to_date.setTime(to_date.getTime() - day_ms);
    while (to_date.getDay() === 0 || to_date.getDay() === 6)
    {
      to_date.setTime(to_date.getTime() - day_ms);
    } 
    from_date.setTime(to_date.getTime() - day_ms);
    while (from_date.getDay() === 0 || from_date.getDay() === 6)
    {
      from_date.setTime(from_date.getTime() - day_ms);
    }
    setSelectFromDate(from_date);
    setSelectToDate(to_date);
    //updateBufferShareholdingChange();
  }, []);

  const updateBufferShareholdingChange = () => {
    getBufferShareholdingChange({})
      .then((result) => {
        setShareholdingChange({list: result.data.data});
        if (result.data.data.length > 0) {
          setSelectFromDate(new Date(result.data.data[0].from_date));
          setSelectToDate(new Date(result.data.data[0].to_date));
        }
        setResultLoaded(true);
      })
      .catch((error) => {
        console.log("error");
        console.log(error);
      });
  };

  const updateShareholdingChange = (target_from_date, target_to_date) => {
    getShareholdingChange({
      from_date: toDateString(target_from_date),
      to_date: toDateString(target_to_date),
    })
      .then((result) => {
        setShareholdingChange({list: result.data.data});
        if (result.data.data.length > 0) {
          setSelectFromDate(new Date(result.data.data[0].from_date));
          setSelectToDate(new Date(result.data.data[0].to_date));
        }
        setResultLoaded(true);
      })
      .catch((error) => {
        console.log("error");
        console.log(error);
      });
  };

  const datePickerFilterDate = (filtered_date) => {
    if (dataReady) {
      for (let i in shareholdingDateListData.shareholdingDateList) {
        if (toDateString(new Date(shareholdingDateListData.shareholdingDateList[i].date)) === toDateString(filtered_date)) {
          return true;
        }
      }
    }
    return false;
  };

  const handleFromDate = (event, data) => {
    if (data.value) {
      if (toDateString(selectFromDate) === toDateString(data.value)) {
        return;
      }
      if (!datePickerFilterDate(data.value)) {
        return;
      }
      setSelectFromDate(data.value);
      setResultLoaded(false);
      updateShareholdingChange(data.value, selectToDate);
    }
  };

  const handleToDate = (event, data) => {
    if (data.value) {
      if (toDateString(selectToDate) === toDateString(data.value)) {
        return;
      }
      if (!datePickerFilterDate(data.value)) {
        return;
      }
      setSelectToDate(data.value);
      setResultLoaded(false);
      updateShareholdingChange(selectFromDate, data.value);
    }
  };

  const config = isMobile ? {
    col: [
      {content: '證劵', align: 'center', width: 5, prefix: '', postfix: '', },
      {content: '參與者', align: 'center', width: 6, prefix: '', postfix: '', },
      {content: '變動', align: 'right', width: 5, prefix: '', postfix: '', },
    ],
    advRowCount: 16,
  } : {
    col: [
      {content: '編號', align: 'center', width: 3, prefix: '', postfix: '', },
      {content: '證劵', align: 'center', width: 3, prefix: '', postfix: '', },
      {content: '參與者', align: 'center', width: 4, prefix: '', postfix: '', },
      {content: '持倉變動(股)', align: 'right', width: 3, prefix: '', postfix: '', },
      {content: '持倉變動(%)', align: 'right', width: 3, prefix: '', postfix: '', },
    ],
    advRowCount: 16,
  };

  const sort = isMobile ? {
    col: [
      {type: 'string', sort: true},
      {type: 'string', sort: true},
      {type: 'key_absolute_number', sort: true},
    ],
    colIndex: 2,
    asc: false,
  } : {
    col: [
      {type: 'string', sort: true},
      {type: 'string', sort: true},
      {type: 'string', sort: true},
      {type: 'key_absolute_number', sort: true},
      {type: 'key_absolute_number', sort: true},
    ],
    colIndex: 4,
    asc: false,
  };

  const filter = isMobile ? {
    col: [
      {text: '過瀘編號', type: 'match'},
      {text: '過瀘參與者', type: 'match'},
      {text: '大於', type: 'key_absolute_larger'},
    ],
  } : {
    col: [
      {text: '過瀘編號', type: 'match'},
      {text: '過瀘名稱', type: 'match'},
      {text: '過瀘參與者', type: 'match'},
      {text: '大於', type: 'key_absolute_larger'},
      {text: '大於', type: 'key_absolute_larger'},
    ],
  };

  const getData = (shareholdingChange) => {
    let data = [];
    let key = [];
    let positive = [];
    let postfix = [];
    if (dataReady) {
      for (let i in shareholdingChange.list) {

        let hasLink = false;
        for (let j = 0; j < source.length; j++) {
          let iStockID = parseInt(shareholdingChange.list[i].stock_code);
          let jStockID = parseInt(source[j].no);
          if (iStockID === jStockID) {
            hasLink = true;
            break;
          }
        }

        let stockLink = <></>;
        if (hasLink) {
          stockLink = <Link to={'/stock/' + formatStockCode(shareholdingChange.list[i].stock_code)}><Icon size='large' inverted name='chart line' color='blue' ></Icon></Link>;
        }

        let participantLink = <Link to={'/shareholding/shareholding_by_participant/' + shareholdingChange.list[i].participant_code}><Icon size='large' inverted name='chart line' color='blue' ></Icon></Link>;
        // participantLink = <></>;
        
        if (isMobile) {
          data.push([
            formatStockCode(shareholdingChange.list[i].stock_code),
            participantListData.participantMap[shareholdingChange.list[i].participant_code].short_name,
            formatSharePercentage(shareholdingChange.list[i].share_percentage_change),
          ]);
          key.push([
            null,
            null,
            shareholdingChange.list[i].share_percentage_change,
          ]);
          positive.push([
            shareholdingChange.list[i].share_percentage_change,
            shareholdingChange.list[i].share_percentage_change,
            shareholdingChange.list[i].share_percentage_change,
          ]);
          postfix.push([
            <> {stockLink}<br />{stockListData.stockMap[shareholdingChange.list[i].stock_code].short_name}</>,
            <><br />{participantLink}</>,
            <><br />({formatShare(shareholdingChange.list[i].share_change)})</>,
          ]);
        } else {
          data.push([
            formatStockCode(shareholdingChange.list[i].stock_code),
            stockListData.stockMap[shareholdingChange.list[i].stock_code].short_name,
            participantListData.participantMap[shareholdingChange.list[i].participant_code].short_name,
            formatShare(shareholdingChange.list[i].share_change),
            formatSharePercentage(shareholdingChange.list[i].share_percentage_change),
          ]);
          key.push([
            null,
            null,
            null,
            shareholdingChange.list[i].share_change,
            shareholdingChange.list[i].share_percentage_change,
          ]);
          positive.push([
            shareholdingChange.list[i].share_percentage_change,
            shareholdingChange.list[i].share_percentage_change,
            shareholdingChange.list[i].share_percentage_change,
            shareholdingChange.list[i].share_percentage_change,
            shareholdingChange.list[i].share_percentage_change,
          ]);
          postfix.push([
            <> {stockLink}</>,
            '',
            <> {participantLink}</>,
            '',
            '',
          ]);
        }
      }
    }
    return {
      data: data,
      key: key,
      positive: positive,
      postfix: postfix,
    };
  }

  let data = getData(shareholdingChange);

  let title = "持倉異動";

  let content = <></>;

  if (dataReady) {
    content = 
      <>
        <DisplayAdsBanner />
        <Table unstackable celled striped>
          <Table.Body>
            <Table.Row>
              <Table.Cell width={isMobile?4:3}>
                由：
              </Table.Cell>
              <Table.Cell width={isMobile?12:13}>
                <SemanticDatepicker
                  filterDate={datePickerFilterDate}
                  onChange={handleFromDate}
                  clearable={false}
                  showToday={false}
                  datePickerOnly={true}
                  value={selectFromDate}
                  clearOnSameDateClick={false}
                  disabled={!resultLoaded}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={isMobile?4:3}>
                到：
              </Table.Cell>
              <Table.Cell width={isMobile?12:13}>
                <SemanticDatepicker
                  filterDate={datePickerFilterDate}
                  onChange={handleToDate}
                  clearable={false}
                  showToday={false}
                  datePickerOnly={true}
                  value={selectToDate}
                  clearOnSameDateClick={false}
                  disabled={!resultLoaded}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell colSpan={2}>
                *只顯示大於0.25%的變動
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={isMobile?4:3}>
                總共：
              </Table.Cell>
              <Table.Cell width={isMobile?4:3}>
                { !resultLoaded && <Loader active inline></Loader> }
                { resultLoaded && data.data.length }
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <SortableTable maxRowPerPage={32} config={config} data={data} sort={sort} filter={filter}></SortableTable>
        <div style={{paddingTop:'30px', paddingBottom:'0px'}}>
            <GoogleAd 
                client="ca-pub-8753358837449417" 
                slot="2486257452" 
                format="auto" 
                wrapperDivStyle={{
                marginTop: '30px',
                marginBottom: '20px'
                }}
            />
        </div>
      </>;
  }
  if (!isMobile) {
    content =
      <Container textAlign='center'>
        {content}
      </Container>;
  }

  return (
    <>
      <MetaTags>
        <title>持倉異動</title>
        <meta name="description" content= "持倉異動" />
        <meta property="og:title" content= "持倉異動" />
        <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
      </MetaTags>
      { !dataReady && (<Loader active inline='centered'>Loading</Loader>) }
      { content } 
    </>
  );
}