import React from "react";
import RSTableBySectorTable from "./RSTableBySectorTable";
import _ from "lodash";
import { Container } from "semantic-ui-react";

function RSTableContainer(props) {
  const { data, stockSector, wishList, allowedBehindPaywall, industry } = props;
  const sector_options = [{ key: "所有", text: "所有", value: "所有" }];
  let j = 0;
  // console.log(stockSector);
  var sDictArr = _.values(stockSector.sDict);
  for (j; j < sDictArr.length; j += 1) {
    sector_options.push({
      key: sDictArr[j],
      text: sDictArr[j],
      value: sDictArr[j],
    });
  }
  return (
    // <Container style={{ maxWidth: "1000px", width: "100%" }}>
    <RSTableBySectorTable
      data={data}
      stockSector={stockSector}
      wishList={wishList}
      allowedBehindPaywall={allowedBehindPaywall}
      filters={{ industry: industry }}
    />
    // </Container>
  );
}

export default RSTableContainer;
