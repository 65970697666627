import React from "react";
import MetaTags from "react-meta-tags";
import IndexTableView from "./IndexTableView";
import DisplayAdsBanner from "../../ads/DisplayAdsBanner";
import GoogleAd from "../../ads/Google_ad";
import {
  Container,
  Header,
  Table,
  Icon,
  Grid,
  Segment,
} from "semantic-ui-react";
import { isMobile } from "react-device-detect";

export const TopicIndexTable = () => {
  return (
    <>
      <MetaTags>
        <title>指數成份股表現</title>
        <meta name="description" content="指數成份股表現" />
        <meta property="og:title" content="指數成份股表現" />
        <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
      </MetaTags>
      <Container textAlign="center">
        <DisplayAdsBanner />
      </Container>

      <div
        style={{
          paddingTop: "25px",
          paddingBottom: "25px",
          minHeight: "75vh",
          width: "90%",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <Header as="h1">
            指數成份股表現
            {/* <Header.Subheader>CovidTest</Header.Subheader> */}
          </Header>
        </div>
        <IndexTableView />
      </div>
      <Container textAlign="center">
        <DisplayAdsBanner />
      </Container>

      {/* <Container
        textAlign="center"
        style={{ paddingTop: "30px", paddingBottom: "0px" }}
      >
        <GoogleAd
          client="ca-pub-8753358837449417"
          slot="2486257452"
          format="auto"
          wrapperDivStyle={{
            marginTop: "30px",
            marginBottom: "20px",
          }}
        />
      </Container> */}
    </>
  );
};

export default TopicIndexTable;
