import React, { Component } from "react";

export default class InFeedAdsWhite extends Component {
  // componentDidMount() {
  //   (window.adsbygoogle = window.adsbygoogle || []).push({});
  // }

  render() {
    return (
      <div>
        {/* <ins
          className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-format="fluid"
          data-ad-layout-key="-fb+5w+4e-db+86"
          data-ad-client="ca-pub-8753358837449417"
          data-ad-slot="7469886511"
        ></ins> */}
      </div>
    );
  }
}

//   render() {
//     return <div></div>;
//   }
// }
