import { makeStyles, IconButton } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { useEffect, useRef, useState } from 'react';

const useStyles = makeStyles(theme => ({
  image_modal_area: {
    position: 'absolute',
    top: '-2000px',
    width: '0px',
    height: '0px',
    padding: '12px',
    zIndex: 50,
    overflowY: 'hidden',
  },
  image_modal_container: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    backgroundColor: 'white',
    border: '1px solid gray',
    borderRadius: '20px',
    padding: '10px',
  },
  image_modal_content_container: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  image_modal_image: {
    position: 'relative',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '80%',
  },
  image_modal_description: {
    color: 'black',
    width: '100%',
  },
  close_icon: {
    color: theme.palette.primary.light,
  },
  close_button: {
    marginLeft: 'auto',
    float: 'right',
  },
}));

export const ImageModal = (props) => {
  const classes = useStyles();
  const containerRef = useRef(null);
  const content = props.content;
  const setContent = props.setContent;

  const handleScroll = () => {
    if (containerRef && containerRef.current) {
      let temp = window.pageYOffset;
      if (temp > 68) {
        temp = temp - 68;
      } else {
        temp = 0;
      }
      containerRef.current.style.setProperty("top", temp + "px");
      containerRef.current.style.setProperty("width", "100%");
      containerRef.current.style.setProperty("height", "80vh");
    }
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [content, containerRef]);

  return (
    <>
      { content && 
        <div className={classes.image_modal_area} ref={containerRef}>
          <div className={classes.image_modal_container}>
            <IconButton className={classes.close_button} onClick={(event) => setContent(null)}>
              <HighlightOffIcon className={classes.close_icon} />
            </IconButton>
            <div className={classes.image_modal_content_container}>
              <img className={classes.image_modal_image} alt={classes.image_modal_description} src={content.url} />
              <div className={classes.image_modal_description}>
                {content.description}
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
};