import React from "react";
import MetaTags from "react-meta-tags";
import DisplayAdsBanner from "../../ads/DisplayAdsBanner";
import GoogleAd from "../../ads/Google_ad";
import { Container, Header, Icon, Popup, Message } from "semantic-ui-react";
import RSView from "./RSView";

export const TopicRsUS = () => {
  return (
    <>
      <MetaTags>
        <title>美股相對強度評級(US Relative Strength Ranking)</title>
        <meta
          name="description"
          content="美股相對強度評級(US Relative Strength Ranking)"
        />
        <meta
          property="og:title"
          content="美股相對強度評級(US Relative Strength Ranking)"
        />
        <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
      </MetaTags>
      <Container textAlign="center">
        <DisplayAdsBanner />
      </Container>

      <div
        style={{ paddingTop: "25px", paddingBottom: "25px", minHeight: "75vh" }}
      >
        <Container style={{ textAlign: "center" }}>
          {/* <Container>
            <div style={{ paddingBottom: "10px" }}>
              <Message
                size="big"
                color="yellow"
                style={{ textAlign: "center" }}
              >
                美股功能測試中
              </Message>
            </div>
          </Container> */}
          <Header as="h1">
            美股相對強度評級 (RS)
            <div style={{ float: "right" }}>
              <Popup
                on="click"
                pinned
                position="bottom right"
                size="small"
                trigger={
                  <div style={{ paddingTop: "0em" }}>
                    <Icon color="grey" size="tiny" name="question circle" />
                  </div>
                }
              >
                <p>
                  {" "}
                  “相對強度(RS) 評級”
                  為對比該股票股價在過去十二個月之表現以及大市上其餘股票的表現後而得出的評級。RS
                  評級範圍由1
                  至99分，分數越大代表表現越好，例如：80分就是代表該股票在過去12個月跑贏市場上其餘80%的股票。
                </p>
                <p>
                  使用RS評級能讓投資者找出市場領導者；一般坊間專業投資者傾向建議選擇評級介乎80-90分的股票。
                </p>
              </Popup>
              {/* <Icon color="grey" size="small" name="question circle" /> */}
            </div>
            <Header.Subheader>(Relative Strength Ranking)</Header.Subheader>
          </Header>
        </Container>
        <RSView />
      </div>
      <Container
        textAlign="center"
        style={{ paddingTop: "30px", paddingBottom: "0px" }}
      >
        <GoogleAd
          client="ca-pub-8753358837449417"
          slot="2486257452"
          format="auto"
          wrapperDivStyle={{
            marginTop: "30px",
            marginBottom: "20px",
          }}
        />
      </Container>
    </>
  );
};

export default TopicRsUS;
