import React, { useState, useEffect, Fragment } from "react";
import { Table, Icon, Input, Button, Loader, Pagination } from 'semantic-ui-react'
import InFeedAdsWhite from '../ads/InFeedAdsWhite';

import './SortableTable.css';

export const SortableTable = (props) => {
  const [ pageState, setPageState ] = useState(1);
  const [ filterState , setFilterState ] = useState({text: Array(props.config.col.length).fill('')});
  const [ sortState , setSortState ] = useState({colIndex: props.sort.colIndex, asc: props.sort.asc});


  const maxRowPerPage = props.maxRowPerPage ? props.maxRowPerPage : 200;

  useEffect(() => {
    if (sortState.colIndex !== props.sort.colIndex || sortState.asc !== props.sort.asc) {
      setSortState({
        colIndex: props.sort.colIndex, 
        asc: props.sort.asc,
      })
    }
  }, [props.sort.colIndex, props.sort.asc]);

  useEffect(() => {
    setPageState(1);
  }, [props.data]);


  const sortBy = (event, data, colIndex) => {
    setPageState(1);
    if (sortState.colIndex === colIndex) {
      setSortState({
        ...sortState,
        asc: !sortState.asc,
      });
    } else {
      let asc = true;
      if (props.sort.col[colIndex].type.search('number') >= 0) {
        asc = false;
      }
      setSortState({
        ...sortState,
        colIndex: colIndex,
        asc: asc,
      });
    }
  };

  const selectPage = (event, data) => {
    const activePage = data.activePage;
    setPageState(activePage);
  };

  const setFilter = (colIndex, data) => {
    setPageState(1);
    let backupText = filterState.text;
    backupText[colIndex] = data;
    setFilterState({
      ...filterState,
      text: backupText,
    });
  }

  const handleFilter = (event, data, colIndex) => {
    setFilter(colIndex, event.target.value);
  };

  const clearFilter = (event, data, colIndex) => {
    setFilter(colIndex, '');
  };

  const selectFilter = (event, data, colIndex, text) => {
    setFilter(colIndex, text + '');
  }

  const getDisplayData = () => {
    let displayData = [];

    if (props.data) {
      for (let i = 0; i < props.data.data.length; i++) {
        let prefixData = props.data.prefix?props.data.prefix[i]:null;
        let data = props.data.data[i]
        let postfixData = props.data.postfix?props.data.postfix[i]:null;
        let positiveData = props.data.positive?props.data.positive[i]:null;
        let keyData = props.data.key?props.data.key[i]:null;
        let included = true;

        for (let j = 0; included && j < filterState.text.length; j++) {
          if (filterState.text[j].length > 0) {
            if (props.filter.col[j].type === 'key_absolute_larger') {
              if (Math.abs(parseFloat(filterState.text[j])) > 0) {
                included = false;
                if (Math.abs(keyData[j]) >= Math.abs(parseFloat(filterState.text[j]))) {
                  included = true;
                }
              }
            } else if (props.filter.col[j].type === 'key_larger') {
              if (Math.abs(parseFloat(filterState.text[j])) > 0) {
                included = false;
                if (keyData[j] >= parseFloat(filterState.text[j])) {
                  included = true;
                }
              }
            } else if (props.filter.col[j].type === 'key_match') {
              if (Math.abs(parseFloat(filterState.text[j])) > 0) {
                included = false;
                if (keyData[j].toLowerCase().indexOf(filterState.text[j].toLowerCase()) >= 0) {
                  included = true;
                }
              }
            } else if (props.filter.col[j].type === 'absolute_larger') {
              if (Math.abs(parseFloat(filterState.text[j])) > 0) {
                included = false;
                if (Math.abs(data[j]) >= Math.abs(parseFloat(filterState.text[j]))) {
                  included = true;
                }
              }
            } else if (props.filter.col[j].type === 'larger') {
              if (Math.abs(parseFloat(filterState.text[j])) > 0) {
                included = false;
                if (data[j] >= parseFloat(filterState.text[j])) {
                  included = true;
                }
              }
            } else { // 'match'
              included = false;
              if (data[j].toLowerCase().indexOf(filterState.text[j].toLowerCase()) >= 0) {
                included = true;
              }
            }
          }
        }
        if (included) {
          displayData.push({
            prefix: prefixData,
            data: data,
            key: keyData,
            postfix: postfixData,
            positive: positiveData,
          });
        }
      }
    } else {
      return {data: null, len: 0};
    }


    if (sortState.colIndex >= 0 && sortState.colIndex < props.sort.col.length) {
      displayData.sort((a, b) => {
        if (props.sort.col[sortState.colIndex].type.search('key_absolute_number') >= 0) {
          return Math.abs(b.key[sortState.colIndex]) - Math.abs(a.key[sortState.colIndex]);
        } else if (props.sort.col[sortState.colIndex].type.search('key_number') >= 0) {
          return b.key[sortState.colIndex] - a.key[sortState.colIndex];
        } else if (props.sort.col[sortState.colIndex].type.search('key_string') >= 0) {
          return b.key[sortState.colIndex].localeCompare(a.key[sortState.colIndex])
        } else if (props.sort.col[sortState.colIndex].type.search('absolute_number') >= 0) {
          return Math.abs(b.data[sortState.colIndex]) - Math.abs(a.data[sortState.colIndex]);
        } else if (props.sort.col[sortState.colIndex].type.search('number') >= 0) {
           return b.data[sortState.colIndex] - a.data[sortState.colIndex];
        } else {
          return b.data[sortState.colIndex].localeCompare(a.data[sortState.colIndex])
        }
      })
      if (sortState.asc) {
        displayData.reverse();
      }
    }

    const fromIndex = props.maxRowPerPage ? ((pageState - 1) * maxRowPerPage) : 0;
    const toIndex = props.maxRowPerPage ? ((pageState) * maxRowPerPage) : displayData.length;

    let finalData = [];
    for (let i = fromIndex; i < displayData.length && i < toIndex; i++) {
      finalData.push(displayData[i])
    }

    return {data: finalData, len: displayData.length};
  };

  let sortActive = Array(props.config.col.ength).fill(false);
  sortActive[sortState.colIndex] = true;
  let sortIcon = Array(props.config.col.length).fill('sort alphabet down');
  for (let i = 0; i < sortIcon.length; i++) {
    if (props.sort.col[i].type.search('number') >= 0) {
      sortIcon[i] = sortIcon[i].replace('alphabet', 'amount');
    }
    if (sortState.colIndex === i) {
      if ((props.sort.col[i].type.search('number') >= 0 && sortState.asc) ||
          (props.sort.col[i].type.search('number') < 0 && !sortState.asc)) {
        sortIcon[i] = sortIcon[i].replace('down', 'up');
      }
    }
  }

  let temp = getDisplayData();

  let displayData = temp["data"];
  const totalPage = parseInt((temp["len"] + 0.5) / maxRowPerPage) + 1;
  
  return(
    <>
    <Table unstackable celled striped fixed>
      {props.config && 
        <Table.Header>
          <Table.Row>
            {props.config.col.map((element, colIndex) => (
              <Table.HeaderCell key={colIndex} width={element.width}>
                <Button
                  basic
                  fluid
                  color={sortActive[colIndex]?'green':'grey'}
                  onClick={props.sort.col[colIndex].type.length > 0 ? ((event, data) => sortBy(event, data, colIndex)) : ((event, data) => {})}
                  >
                  { props.sort.col[colIndex].type.length > 0 && (<Icon link name={sortIcon[colIndex]}></Icon>)} 
                {element.content}
                </Button>
                
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
      }
      {props.filter && 
        <Table.Header>
          <Table.Row>
            {props.filter.col.map((element, colIndex) => (
              <Table.HeaderCell key={colIndex}>
                { props.filter.col[colIndex].type.length > 0 && (
                  <>
                    <Input
                      fluid
                      placeholder={element.text}
                      icon={<Icon name='cancel' inverted circular link size={'small'} onClick={(event, data) => clearFilter(event, data, colIndex)}/>} 
                      value={filterState.text[colIndex]}
                      onChange={(event, data) => handleFilter(event, data, colIndex)}/>
                    {filterState[colIndex]}
                  </>
                )}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
      }
      {props.maxRowPerPage && 
        <Table.Header>
          <Table.Row>
            <Table.Cell colSpan={props.config.col.length} textAlign="center">
              <Pagination
                activePage={pageState}
                boundaryRange={1}
                onPageChange={(event, data) => selectPage(event, data)}
                size='mini'
                siblingRange={1}
                totalPages={totalPage}
              />
            </Table.Cell>
          </Table.Row>
        </Table.Header>
      }
      <Table.Body>
        {!displayData &&
          <Table.Row>
            <Table.Cell colSpan={props.config.col.length} textAlign='center'>
              <Loader active inline='centered'>Loading</Loader>
            </Table.Cell>
          </Table.Row>
        }
        {displayData && displayData.map((row, rowIndex) => (
          <Fragment key={rowIndex}>
            {props.config.advRowCount > 0 && (rowIndex !== 0) && (rowIndex % props.config.advRowCount === 0) && (
              <Table.Row>
                <Table.Cell className='adv_cell' colSpan={props.config.col.length} textAlign='center' >
                  <InFeedAdsWhite></InFeedAdsWhite>
                </Table.Cell>
              </Table.Row>

            )}
            <Table.Row>
              {row.data.map((element, colIndex) => ( 
                <Table.Cell
                  key={colIndex}
                  className={props.filter.col[colIndex].type.length > 0 ? 'can_filter' : ''}
                  textAlign={props.config.col[colIndex].align}
                  onClick={(props.filter.col[colIndex].type.length > 0) ? (
                    (props.filter.col[colIndex].type.indexOf("key") >= 0) ? (
                      (event, data) => selectFilter(event, data, colIndex, row.key[colIndex])
                    ) : (
                      (event, data) => selectFilter(event, data, colIndex, element)
                    )) : (
                      (event, data) => {}
                    ) }
                  positive={row.positive && row.positive[colIndex] && row.positive[colIndex] > 0}
                  negative={row.positive && row.positive[colIndex] && row.positive[colIndex] < 0}>
                    {row.prefix && row.prefix[colIndex]}
                    {props.config.col[colIndex].prefix}
                    {element}
                    {props.config.col[colIndex].postfix}
                    {row.postfix && row.postfix[colIndex]}
                </Table.Cell>
              ))}
            </Table.Row>
          </Fragment>
        ))}
      </Table.Body>
      {props.maxRowPerPage && 
        <Table.Header>
          <Table.Row>
            <Table.Cell colSpan={props.config.col.length} textAlign="center">
              <Pagination
                activePage={pageState}
                boundaryRange={1}
                onPageChange={(event, data) => selectPage(event, data)}
                size='mini'
                siblingRange={1}
                totalPages={totalPage}
              />
            </Table.Cell>
          </Table.Row>
        </Table.Header>
      }
    </Table>
    </>
  );
};

export default SortableTable;