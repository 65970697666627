import React from "react";
import { connect } from "react-redux";
import { Container, Divider, Form, Header } from "semantic-ui-react";
import ZoomTable from "./ZoomTable";
import ZoomFilterForm from "./ZoomFilterForm2";
import { selectFilterById } from "./zoomSlice";
import { useDispatch, useSelector } from "react-redux";
import { editFilters } from "./zoomSlice";
import { nanoid } from "@reduxjs/toolkit";
import { useHistory } from "react-router-dom";

function ZoomPicker(props) {
  const { id } = props.match.params;
  console.log(props);
  console.log("props");
  const { values } = props;
  const history = useHistory();
  const filter = useSelector((state) => selectFilterById(state, id));
  let mode = "Search";
  const dispatch = useDispatch();
  const users = "xyz";
  const canSave = true;

  const onEditFilterClicked = async (e) => {
    e.preventDefault();
    let time = new Date();
    let timeString = time.toISOString();
    if (canSave) {
      await dispatch(
        editFilters({
          uid: "xyz",
          oldFilter: filter,
          newFilter: {
            ...filter,
            timeString: timeString,
            filter: values,
          },
        })
      );
      await history.push("/zoom/filterlist");
    }
  };

  const onSearchClicked = (e) => {
    console.log(users);
    e.preventDefault();
    history.push(`/zoom/picker/${id}`);
  };
  return (
    <Container>
      <Divider hidden />

      <ZoomFilterForm
        initialValues={filter ? filter.filter : undefined}
        mode={mode}
        onEditFilterClicked={onEditFilterClicked}
        onSearchClicked={onSearchClicked}
        id={id}
      />
      <ZoomTable filters={values} data={tableData} />
    </Container>
  );
}

const mapStateToProps = (state) => {
  return state.form.zoom
    ? {
        values: state.form.zoom.values,
        submitSucceeded: state.form.zoom.submitSucceeded,
      }
    : {};
};

export default connect(mapStateToProps)(ZoomPicker);

const tableData = {
  topicInfo: {
    lastUpdate: "2022-01-13",
    name: "zoomtable",
  },
  zoomtable: {
    header: [
      "id",
      "C",
      "V",
      "rs",
      "ma10",
      "ma50",
      "ma150",
      "ma200",
      "maw30",
      "t",
      "cap",
      "ncC",
      "hkcC",
      "t3C",
      "t5C",
      "t10C",
    ],
    data: [
      {
        0: [
          "1937",
          1.07,
          11015000,
          100,
          "1.047",
          "1.168",
          "0.966",
          "0.755",
          "1.026",
          1182,
          10.7,
          72,
          0,
          83.61,
          89.33,
          94.59,
        ],
      },
      {
        1: [
          "0663",
          0.43,
          299100,
          100,
          "0.082",
          "0.051",
          "0.056",
          "0.068",
          "-",
          11.653,
          3.403,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        2: [
          "1725",
          25.4,
          1284800,
          99.9,
          "26.375",
          "28.48",
          "30.531",
          "27.46",
          "30.718",
          3293,
          78.4,
          14.93,
          0,
          85.79,
          89.02,
          94.57,
        ],
      },
      {
        3: [
          "8292",
          0.86,
          22941000,
          99.9,
          "0.657",
          "0.339",
          "0.211",
          "0.205",
          "0.23",
          1798,
          5.449,
          0.63,
          0,
          72.76,
          80.86,
          88.36,
        ],
      },
      {
        4: [
          "1450",
          1.22,
          56000,
          99.9,
          "1.22",
          "1.429",
          "1.168",
          "0.917",
          "1.233",
          6.758,
          13.2,
          6,
          0,
          70.29,
          80.02,
          90.04,
        ],
      },
      {
        5: [
          "1094",
          1.41,
          143480,
          99.8,
          "1.479",
          "1.535",
          "1.028",
          "0.821",
          "1.104",
          20.52,
          4.132,
          33.48,
          0,
          58.02,
          68.54,
          80.2,
        ],
      },
      {
        6: [
          "8305",
          0.315,
          11610000,
          99.8,
          "0.284",
          "0.189",
          "0.138",
          "0.116",
          "0.146",
          354,
          3.308,
          0.01,
          0,
          83.98,
          87.95,
          93.03,
        ],
      },
      {
        7: [
          "1870",
          1.36,
          6275000,
          99.7,
          "1.64",
          "1.386",
          "0.881",
          "0.711",
          "0.937",
          902,
          8.486,
          34.46,
          0,
          55.32,
          69.9,
          85.9,
        ],
      },
      {
        8: [
          "1481",
          1,
          150000,
          99.7,
          "1.065",
          "0.964",
          "0.526",
          "0.446",
          "0.558",
          14.64,
          10,
          75.46,
          0,
          85.29,
          89.46,
          93.8,
        ],
      },
      {
        9: [
          "1726",
          1.27,
          235000,
          99.7,
          "1.299",
          "1.032",
          "0.651",
          "0.606",
          "0.66",
          28.515,
          12.1,
          0.04,
          0,
          81.01,
          85.13,
          91.13,
        ],
      },
      {
        10: [
          "6833",
          1.99,
          13544000,
          99.6,
          "1.986",
          "1.676",
          "1.0",
          "0.896",
          "1.037",
          2714,
          40.4,
          5.16,
          0,
          61.97,
          70.63,
          85.84,
        ],
      },
      {
        11: [
          "8527",
          3.01,
          1345000,
          99.6,
          "2.847",
          "2.859",
          "2.878",
          "2.462",
          "2.968",
          404,
          15,
          57.73,
          0,
          80.55,
          86.17,
          95.38,
        ],
      },
      {
        12: [
          "0147",
          0.32,
          18580000,
          99.6,
          "0.164",
          "0.095",
          "0.082",
          "0.084",
          "0.088",
          495,
          65,
          73.41,
          0,
          82.05,
          88.35,
          95.08,
        ],
      },
      {
        13: [
          "2255",
          2.99,
          7201000,
          99.5,
          "2.88",
          "2.711",
          "1.734",
          "1.459",
          "1.851",
          2132,
          119.6,
          14.84,
          0.45,
          63.49,
          72.49,
          87.21,
        ],
      },
      {
        14: [
          "1121",
          9.3,
          1822000,
          99.5,
          "10.107",
          "9.962",
          "9.405",
          "8.452",
          "9.632",
          1696,
          154.5,
          53.57,
          0,
          67.97,
          74.6,
          83.84,
        ],
      },
      {
        15: [
          "0913",
          0.96,
          402000,
          99.5,
          "0.958",
          "0.676",
          "0.436",
          "0.409",
          "0.448",
          39.033,
          2.646,
          5.04,
          0,
          41.51,
          53.73,
          73.52,
        ],
      },
      {
        16: [
          "0274",
          0.495,
          1080350,
          99.4,
          "0.439",
          "0.421",
          "0.356",
          "0.315",
          "0.367",
          52.364,
          7.5,
          57.46,
          0,
          70.63,
          78.39,
          87.61,
        ],
      },
      {
        17: [
          "8406",
          0.93,
          1140000,
          99.4,
          "0.886",
          "0.754",
          "0.524",
          "0.434",
          "0.533",
          106,
          7.44,
          12.52,
          0,
          71.78,
          79.62,
          90.21,
        ],
      },
      {
        18: [
          "8645",
          0.73,
          3761000,
          99.4,
          "0.6",
          "0.495",
          "0.398",
          "0.333",
          "0.42",
          256,
          4.38,
          56.25,
          0,
          79.29,
          86.99,
          93.42,
        ],
      },
      {
        19: [
          "1539",
          0.77,
          1522000,
          99.3,
          "0.777",
          "0.659",
          "0.535",
          "0.47",
          "0.526",
          116,
          5.104,
          10.22,
          0,
          32.6,
          47.5,
          71.85,
        ],
      },
      {
        20: [
          "8511",
          0.49,
          2188000,
          99.3,
          "0.404",
          "0.367",
          "0.234",
          "0.228",
          "0.243",
          100,
          1.96,
          1.96,
          0,
          82.65,
          87.38,
          92.29,
        ],
      },
      {
        21: [
          "6908",
          6.04,
          1642000,
          99.2,
          "6.465",
          "6.841",
          "7.33",
          "7.174",
          "7.376",
          968,
          34,
          67.42,
          0,
          79.02,
          83.21,
          89.28,
        ],
      },
      {
        22: [
          "8196",
          0.74,
          924000,
          99.2,
          "0.359",
          "0.321",
          "0.251",
          "0.262",
          "0.263",
          37.722,
          2.22,
          3.68,
          0,
          76.49,
          84.84,
          94.22,
        ],
      },
      {
        23: [
          "8472",
          1.7,
          1865000,
          99.2,
          "1.05",
          "0.906",
          "0.999",
          "0.837",
          "1.058",
          300,
          6.8,
          51.85,
          0,
          80.49,
          88.94,
          96.58,
        ],
      },
      {
        24: [
          "0810",
          1.63,
          378000,
          99.1,
          "1.528",
          "1.432",
          "1.083",
          "0.907",
          "1.151",
          61.596,
          2.305,
          4.6,
          0,
          63.53,
          72.37,
          86.91,
        ],
      },
      {
        25: [
          "0343",
          0.76,
          3267200,
          99.1,
          "0.772",
          "0.789",
          "0.748",
          "0.642",
          "0.754",
          248,
          12.6,
          22.83,
          0,
          46.53,
          59.29,
          72.24,
        ],
      },
      {
        26: [
          "1780",
          2.66,
          10000,
          99.1,
          "2.765",
          "2.775",
          "2.377",
          "2.014",
          "2.452",
          2.69,
          16.4,
          80.35,
          0,
          89.03,
          92.15,
          95.59,
        ],
      },
      {
        27: [
          "2346",
          1.8,
          824000,
          99,
          "1.8",
          "1.823",
          "1.872",
          "1.525",
          "1.968",
          147,
          10.7,
          14.7,
          0,
          48.21,
          63.65,
          78.58,
        ],
      },
      {
        28: [
          "8315",
          0.68,
          13000,
          99,
          "0.623",
          "0.423",
          "0.336",
          "0.32",
          "-",
          0.941,
          3.954,
          16.96,
          0,
          80.29,
          87.58,
          92.66,
        ],
      },
      {
        29: [
          "1263",
          12.36,
          1261900,
          99,
          "12.968",
          "13.92",
          "9.027",
          "7.75",
          "9.274",
          1563,
          47.8,
          32.62,
          0,
          54.57,
          66.74,
          79.38,
        ],
      },
      {
        30: [
          "0076",
          0.051,
          6628000,
          98.9,
          "0.053",
          "0.041",
          "0.031",
          "0.029",
          "0.032",
          33.722,
          3.573,
          6.59,
          0,
          37.46,
          56.13,
          72.33,
        ],
      },
      {
        31: [
          "8091",
          0.156,
          3190000,
          98.9,
          "0.155",
          "0.17",
          "0.129",
          "0.133",
          "0.128",
          50.573,
          1.123,
          75.59,
          0,
          83.25,
          87.02,
          92.21,
        ],
      },
      {
        32: [
          "2340",
          0.82,
          32000,
          98.9,
          "0.773",
          "0.801",
          "0.483",
          "0.429",
          "0.494",
          2.624,
          3.484,
          0.58,
          0,
          74.05,
          78.4,
          84.09,
        ],
      },
      {
        33: [
          "1733",
          1.2,
          29852800,
          98.8,
          "1.21",
          "0.941",
          "0.723",
          "0.64",
          "0.754",
          3457,
          36.3,
          1.3,
          0,
          61.88,
          73.11,
          84.69,
        ],
      },
      {
        34: [
          "0827",
          0.249,
          9112000,
          98.8,
          "0.215",
          "0.182",
          "0.139",
          "0.127",
          "0.145",
          226,
          14.1,
          18.09,
          0,
          46.17,
          56.26,
          66.55,
        ],
      },
      {
        35: [
          "2199",
          6.56,
          597100,
          98.7,
          "6.705",
          "5.998",
          "3.92",
          "3.513",
          "4.029",
          394,
          80.3,
          0.01,
          0.19,
          79.17,
          89.08,
          96.09,
        ],
      },
      {
        36: [
          "6933",
          4.98,
          76000,
          98.7,
          "4.98",
          "4.244",
          "2.651",
          "2.512",
          "2.764",
          37.68,
          20.3,
          8.16,
          0,
          90.75,
          94.33,
          97.42,
        ],
      },
      {
        37: [
          "0279",
          0.22,
          4596000,
          98.7,
          "0.231",
          "0.234",
          "0.141",
          "0.129",
          "0.143",
          102,
          41,
          76.67,
          2.66,
          86.71,
          92.21,
          97.53,
        ],
      },
      {
        38: [
          "0646",
          0.76,
          405600,
          98.6,
          "0.738",
          "0.546",
          "0.444",
          "0.424",
          "0.446",
          30.456,
          1.664,
          1.09,
          0,
          47.22,
          60.03,
          77.7,
        ],
      },
      {
        39: [
          "1010",
          5.01,
          738000,
          98.6,
          "5.637",
          "5.485",
          "3.778",
          "3.459",
          "3.767",
          397,
          18.5,
          16.67,
          0,
          89.01,
          96.24,
          98.72,
        ],
      },
      {
        40: [
          "1815",
          1.48,
          525000,
          98.6,
          "1.665",
          "1.397",
          "0.897",
          "0.865",
          "0.92",
          78.918,
          18.3,
          44.38,
          0,
          69.99,
          78.87,
          88.41,
        ],
      },
      {
        41: [
          "0471",
          0.73,
          1688800,
          98.5,
          "0.76",
          "0.549",
          "0.389",
          "0.372",
          "0.402",
          122,
          3.499,
          0.52,
          0,
          60.52,
          66.91,
          76.44,
        ],
      },
      {
        42: [
          "8402",
          2.28,
          90000,
          98.5,
          "2.242",
          "2.309",
          "2.341",
          "2.205",
          "2.354",
          20.125,
          10.9,
          52.17,
          0,
          78.55,
          85.37,
          92.23,
        ],
      },
      {
        43: [
          "8446",
          0.48,
          4910000,
          98.5,
          "0.374",
          "0.281",
          "0.213",
          "0.21",
          "0.217",
          233,
          3.84,
          76.26,
          0,
          83.05,
          87.16,
          93.4,
        ],
      },
      {
        44: [
          "0838",
          1.86,
          11910000,
          98.4,
          "1.851",
          "1.956",
          "1.24",
          "1.116",
          "1.271",
          2183,
          32.5,
          1.04,
          0,
          49.07,
          61.03,
          74.05,
        ],
      },
      {
        45: [
          "1823",
          2.97,
          210000,
          98.4,
          "3.32",
          "3.317",
          "3.322",
          "2.809",
          "3.447",
          62.99,
          12.2,
          72.73,
          0,
          81.62,
          87.63,
          94.48,
        ],
      },
      {
        46: [
          "6928",
          3.61,
          12000,
          98.4,
          "3.723",
          "3.723",
          "3.792",
          "3.21",
          "3.773",
          4.332,
          16.2,
          62.02,
          0,
          74.64,
          82.41,
          92.14,
        ],
      },
      {
        47: [
          "1921",
          3.4,
          1994000,
          98.3,
          "3.226",
          "2.518",
          "2.091",
          "1.908",
          "2.131",
          670,
          51,
          72.47,
          0,
          82.15,
          87.65,
          94.7,
        ],
      },
      {
        48: [
          "0187",
          4.48,
          33069000,
          98.3,
          "4.258",
          "3.552",
          "2.721",
          "2.576",
          "2.795",
          15284,
          4.48,
          0.69,
          32.97,
          44.78,
          58.31,
          72.55,
        ],
      },
      {
        49: [
          "1327",
          0.355,
          90000,
          98.2,
          "0.344",
          "0.343",
          "0.256",
          "0.221",
          "0.266",
          3.195,
          1.914,
          14.74,
          0,
          42.83,
          56.96,
          76.16,
        ],
      },
      {
        50: [
          "8040",
          0.38,
          0,
          98.2,
          "0.357",
          "0.41",
          "0.271",
          "0.237",
          "0.281",
          0,
          1.216,
          71.38,
          0,
          78.35,
          84.14,
          90.87,
        ],
      },
      {
        51: [
          "1202",
          1.92,
          768000,
          98.2,
          "1.864",
          "1.493",
          "1.206",
          "1.138",
          "1.23",
          146,
          3.072,
          1.5,
          0,
          26.26,
          36.88,
          51.78,
        ],
      },
      {
        52: [
          "8365",
          0.47,
          3245000,
          98.1,
          "0.511",
          "0.475",
          "0.425",
          "0.361",
          "0.437",
          152,
          2.802,
          13.93,
          0,
          83.87,
          90.07,
          93.96,
        ],
      },
      {
        53: [
          "8379",
          0.168,
          1210000,
          98.1,
          "0.192",
          "0.201",
          "0.137",
          "0.122",
          "0.138",
          21.684,
          1.344,
          75.05,
          0,
          80.82,
          84.48,
          90.28,
        ],
      },
      {
        54: [
          "1854",
          0.315,
          600000,
          98.1,
          "0.328",
          "0.274",
          "0.218",
          "0.192",
          "0.223",
          19.455,
          4.763,
          16.21,
          0,
          84.03,
          89.43,
          95.1,
        ],
      },
      {
        55: [
          "1587",
          0.96,
          3124000,
          98,
          "0.857",
          "0.715",
          "0.562",
          "0.533",
          "0.58",
          311,
          6.528,
          75.01,
          0,
          84.73,
          92.14,
          96.86,
        ],
      },
      {
        56: [
          "8072",
          0.42,
          6930000,
          98,
          "0.376",
          "0.397",
          "0.287",
          "0.252",
          "0.3",
          279,
          2.949,
          14.81,
          0,
          70.53,
          81.78,
          89.53,
        ],
      },
      {
        57: [
          "1523",
          3.79,
          441913,
          98,
          "3.852",
          "3.779",
          "2.862",
          "2.532",
          "2.944",
          166,
          41.3,
          71.19,
          0,
          81.43,
          86.54,
          93.57,
        ],
      },
      {
        58: [
          "1811",
          6.38,
          10104000,
          97.9,
          "6.999",
          "6.933",
          "5.436",
          "4.597",
          "5.656",
          6406,
          273.7,
          72.3,
          14.35,
          88.86,
          91.49,
          95.33,
        ],
      },
      {
        59: [
          "1796",
          1.37,
          2466000,
          97.9,
          "1.192",
          "1.158",
          "1.035",
          "0.918",
          "1.08",
          334,
          6.576,
          0,
          0,
          93.5,
          95.38,
          98.12,
        ],
      },
      {
        60: [
          "1415",
          10.8,
          22706500,
          97.9,
          "12.734",
          "10.841",
          "7.049",
          "6.419",
          "7.222",
          22796,
          90.1,
          0.02,
          0,
          77.93,
          82.52,
          88.6,
        ],
      },
      {
        61: [
          "0751",
          5.09,
          40077000,
          97.8,
          "5.061",
          "5.295",
          "3.37",
          "3.13",
          "3.444",
          20367,
          135.7,
          47.6,
          11.75,
          71.1,
          78.04,
          87.79,
        ],
      },
      {
        62: [
          "1058",
          0.93,
          100000,
          97.8,
          "0.918",
          "0.811",
          "0.572",
          "0.498",
          "0.613",
          9.2,
          5.004,
          1.53,
          0,
          83,
          87.73,
          93.47,
        ],
      },
      {
        63: [
          "0205",
          0.53,
          5750,
          97.7,
          "0.598",
          "0.577",
          "0.627",
          "0.523",
          "0.65",
          0.281,
          3.266,
          27.73,
          0,
          46.76,
          59.83,
          77.96,
        ],
      },
      {
        64: [
          "1063",
          0.345,
          218233,
          97.7,
          "0.363",
          "0.384",
          "0.358",
          "0.301",
          "0.367",
          7.507,
          5.151,
          9.83,
          0,
          36.22,
          52.98,
          72.83,
        ],
      },
      {
        65: [
          "9913",
          3.41,
          1241400,
          97.7,
          "3.448",
          "3.516",
          "3.404",
          "3.323",
          "3.432",
          425,
          34.1,
          0.49,
          0,
          90.65,
          96.32,
          98.39,
        ],
      },
      {
        66: [
          "6830",
          3.06,
          6002000,
          97.6,
          "3.048",
          "3.26",
          "3.135",
          "2.811",
          "3.18",
          1821,
          54.1,
          74.68,
          0,
          83.2,
          87.32,
          91.03,
        ],
      },
      {
        67: [
          "8170",
          3,
          0,
          97.6,
          "3.0",
          "3.027",
          "1.822",
          "1.833",
          "1.896",
          0,
          12.3,
          0.03,
          0,
          93.19,
          98.85,
          99.69,
        ],
      },
      {
        68: [
          "1943",
          0.63,
          6935000,
          97.6,
          "0.569",
          "0.544",
          "0.523",
          "0.459",
          "0.541",
          423,
          6.3,
          75.02,
          0,
          86.05,
          89.5,
          94.97,
        ],
      },
      {
        69: [
          "0732",
          3.29,
          9234000,
          97.5,
          "3.316",
          "2.672",
          "2.513",
          "2.227",
          "2.567",
          3004,
          108.2,
          46.57,
          4.91,
          64.7,
          70.34,
          80.94,
        ],
      },
      {
        70: [
          "0710",
          10.24,
          2618000,
          97.5,
          "10.4",
          "9.775",
          "8.545",
          "7.364",
          "8.866",
          2704,
          75.3,
          1.2,
          0,
          67.18,
          74.78,
          85.15,
        ],
      },
      {
        71: [
          "0776",
          17,
          97500,
          97.5,
          "16.614",
          "18.007",
          "14.225",
          "13.047",
          "14.298",
          169,
          52.8,
          0.51,
          0,
          83.94,
          86.48,
          90.91,
        ],
      },
      {
        72: [
          "8011",
          0.164,
          1280000,
          97.4,
          "0.165",
          "0.146",
          "0.095",
          "0.093",
          "0.095",
          20.584,
          6.293,
          18.2,
          0,
          79.09,
          86.05,
          93.62,
        ],
      },
      {
        73: [
          "8395",
          0.93,
          0,
          97.4,
          "0.9",
          "0.886",
          "0.705",
          "0.621",
          "0.728",
          0,
          14.7,
          25.2,
          0,
          71.64,
          91.87,
          97.69,
        ],
      },
      {
        74: [
          "2380",
          4.33,
          89631000,
          97.4,
          "4.63",
          "4.367",
          "3.226",
          "2.875",
          "3.352",
          38519,
          469,
          46.09,
          23.03,
          69.12,
          85.19,
          94.9,
        ],
      },
      {
        75: [
          "8086",
          1.3,
          1056000,
          97.3,
          "1.406",
          "0.854",
          "0.71",
          "0.893",
          "0.697",
          135,
          0.734,
          11.21,
          0,
          44.9,
          53.62,
          70.41,
        ],
      },
      {
        76: [
          "1442",
          1.65,
          720000,
          97.3,
          "1.684",
          "1.626",
          "1.467",
          "1.312",
          "1.486",
          121,
          34,
          2.93,
          0,
          84.22,
          90,
          97.39,
        ],
      },
      {
        77: [
          "8349",
          2.08,
          230000,
          97.2,
          "2.188",
          "2.689",
          "2.874",
          "2.339",
          "2.973",
          48.26,
          8.32,
          51.9,
          0,
          67.56,
          75.58,
          88.02,
        ],
      },
      {
        78: [
          "2006",
          2.92,
          3950000,
          97.2,
          "2.923",
          "2.652",
          "1.991",
          "1.914",
          "2.016",
          1151,
          40.6,
          5.38,
          0,
          57.5,
          68.51,
          79.45,
        ],
      },
      {
        79: [
          "0269",
          0.026,
          19270000,
          97.2,
          "0.027",
          "0.019",
          "0.016",
          "0.017",
          "0.016",
          50.209,
          2.767,
          31.98,
          0,
          65.43,
          72.75,
          81.09,
        ],
      },
      {
        80: [
          "0442",
          1.88,
          5000,
          97.1,
          "1.87",
          "1.763",
          "1.369",
          "1.224",
          "1.432",
          0.94,
          3.245,
          10.69,
          0,
          78.67,
          82.76,
          89.39,
        ],
      },
      {
        81: [
          "1025",
          0.47,
          184000,
          97.1,
          "0.491",
          "0.49",
          "0.391",
          "0.36",
          "0.394",
          8.674,
          4.399,
          1.07,
          0,
          69.26,
          79.39,
          88.48,
        ],
      },
      {
        82: [
          "0568",
          4.77,
          1897200,
          97.1,
          "4.883",
          "4.357",
          "4.792",
          "4.794",
          "4.737",
          918,
          12.2,
          0.08,
          65.39,
          71.64,
          79.26,
          86.67,
        ],
      },
      {
        83: [
          "2383",
          0.89,
          3736000,
          97,
          "0.929",
          "0.833",
          "0.582",
          "0.546",
          "0.587",
          315,
          35.2,
          63.71,
          0,
          91.75,
          93.75,
          96.13,
        ],
      },
      {
        84: [
          "8229",
          0.29,
          0,
          97,
          "0.265",
          "0.276",
          "0.228",
          "0.205",
          "0.232",
          0,
          1.16,
          77.29,
          0,
          82.37,
          86.61,
          92.34,
        ],
      },
      {
        85: [
          "1735",
          9.28,
          1039000,
          97,
          "9.186",
          "7.916",
          "6.403",
          "5.722",
          "6.707",
          960,
          97.9,
          5.45,
          0,
          83.01,
          90.01,
          96.12,
        ],
      },
      {
        86: [
          "8585",
          1.275,
          0,
          96.9,
          "1.275",
          "1.284",
          "-",
          "-",
          "-",
          0,
          4.57,
          99.93,
          0,
          99.97,
          99.98,
          100,
        ],
      },
      {
        87: [
          "1393",
          0.495,
          2471000,
          96.9,
          "0.48",
          "0.423",
          "0.327",
          "0.3",
          "0.335",
          120,
          10.1,
          1.38,
          0,
          65.41,
          73.48,
          80.05,
        ],
      },
      {
        88: [
          "2448",
          5.68,
          212500,
          96.8,
          "5.769",
          "5.547",
          "4.864",
          "4.343",
          "4.989",
          120,
          45.7,
          15.99,
          0,
          53.01,
          67.48,
          82.8,
        ],
      },
      {
        89: [
          "8203",
          0.29,
          71000,
          96.8,
          "0.291",
          "0.302",
          "0.259",
          "0.221",
          "0.269",
          1.961,
          1.672,
          31.04,
          0,
          63.07,
          71.91,
          81.32,
        ],
      },
      {
        90: [
          "6860",
          0.49,
          40074900,
          96.8,
          "0.323",
          "0.245",
          "0.217",
          "0.27",
          "0.219",
          2003,
          9.464,
          0.77,
          0,
          65.48,
          73.7,
          85.37,
        ],
      },
      {
        91: [
          "1368",
          12.94,
          19518000,
          96.7,
          "12.268",
          "11.274",
          "11.937",
          "10.642",
          "11.969",
          25123,
          340.3,
          0.03,
          15.76,
          79,
          89.27,
          96.47,
        ],
      },
      {
        92: [
          "1345",
          2.03,
          2903000,
          96.7,
          "1.772",
          "1.362",
          "1.334",
          "1.257",
          "1.345",
          584,
          25.5,
          0.05,
          0,
          77.6,
          83.77,
          93.5,
        ],
      },
      {
        93: [
          "1729",
          0.75,
          7624000,
          96.7,
          "0.768",
          "0.683",
          "0.523",
          "0.497",
          "0.53",
          564,
          13.8,
          63.7,
          0,
          79.64,
          83.53,
          88.54,
        ],
      },
      {
        94: [
          "0875",
          2.8,
          100000,
          96.6,
          "2.712",
          "2.71",
          "2.215",
          "2.055",
          "2.257",
          27.94,
          10.6,
          84.29,
          0,
          91.17,
          93,
          95.31,
        ],
      },
      {
        95: [
          "8112",
          0.465,
          4560000,
          96.6,
          "0.413",
          "0.395",
          "0.342",
          "0.329",
          "0.343",
          214,
          0.267,
          13.95,
          0,
          36.95,
          47.08,
          63.34,
        ],
      },
      {
        96: [
          "8622",
          0.249,
          32000,
          96.6,
          "0.244",
          "0.249",
          "0.222",
          "0.191",
          "0.226",
          0.796,
          0.996,
          7.79,
          0,
          78.02,
          83.55,
          90.96,
        ],
      },
      {
        97: [
          "0800",
          0.4,
          9552000,
          96.5,
          "0.367",
          "0.3",
          "0.255",
          "0.246",
          "0.26",
          385,
          10.8,
          0.03,
          0,
          74.8,
          79.39,
          86.14,
        ],
      },
      {
        98: [
          "8501",
          0.85,
          4000,
          96.5,
          "0.822",
          "0.716",
          "0.572",
          "0.533",
          "0.586",
          0.338,
          1.7,
          82.42,
          0,
          90.65,
          94.31,
          98.3,
        ],
      },
      {
        99: [
          "2788",
          0.97,
          724000,
          96.5,
          "0.921",
          "0.844",
          "0.65",
          "0.619",
          "0.658",
          70.278,
          7.918,
          42.01,
          0,
          69.8,
          77.37,
          84.41,
        ],
      },
      {
        100: [
          "1305",
          1.3,
          5000,
          96.4,
          "1.257",
          "1.275",
          "0.858",
          "0.799",
          "0.886",
          0.643,
          2.819,
          4.12,
          0,
          65.45,
          73.71,
          83.17,
        ],
      },
      {
        101: [
          "1529",
          0.355,
          2180000,
          96.4,
          "0.336",
          "0.286",
          "0.268",
          "0.251",
          "0.272",
          77.425,
          3.14,
          6.43,
          0,
          80.49,
          84.88,
          90.68,
        ],
      },
      {
        102: [
          "8093",
          0.54,
          0,
          96.3,
          "0.54",
          "0.54",
          "0.561",
          "0.506",
          "0.554",
          0,
          2.635,
          41.01,
          0,
          82.46,
          89.76,
          94.62,
        ],
      },
      {
        103: [
          "2086",
          1.27,
          13179400,
          96.3,
          "1.098",
          "1.019",
          "0.794",
          "0.756",
          "0.814",
          1835,
          4.058,
          0.35,
          0,
          84.58,
          88.68,
          92.82,
        ],
      },
      {
        104: [
          "1226",
          0.74,
          0,
          96.3,
          "0.814",
          "0.921",
          "0.693",
          "0.626",
          "0.709",
          0,
          2.406,
          0.23,
          0,
          60.55,
          74.77,
          91.32,
        ],
      },
      {
        105: [
          "3628",
          0.24,
          0,
          96.2,
          "0.239",
          "0.238",
          "0.185",
          "0.173",
          "0.188",
          0,
          1.93,
          74.63,
          0,
          84.89,
          89.92,
          94.96,
        ],
      },
      {
        106: [
          "0020",
          7.15,
          24878000,
          96.2,
          "7.273",
          "-",
          "-",
          "-",
          "-",
          17941,
          2379.6,
          94.82,
          0,
          97.9,
          98.41,
          99.05,
        ],
      },
      {
        107: [
          "1867",
          0.31,
          3110000,
          96.2,
          "0.319",
          "0.324",
          "0.312",
          "0.302",
          "0.313",
          96.36,
          4.166,
          72.06,
          0,
          79.25,
          83.98,
          90.62,
        ],
      },
      {
        108: [
          "0340",
          0.7,
          170000,
          96.1,
          "0.709",
          "0.739",
          "0.63",
          "0.551",
          "0.649",
          12.205,
          23.7,
          63.67,
          0,
          73.15,
          79.59,
          87.09,
        ],
      },
      {
        109: [
          "0836",
          20.75,
          29142800,
          96.1,
          "22.585",
          "22.208",
          "17.967",
          "16.022",
          "18.572",
          59852,
          998.1,
          63.16,
          16.72,
          79.88,
          90.82,
          96,
        ],
      },
      {
        110: [
          "6616",
          7.13,
          1474000,
          96.1,
          "8.645",
          "7.62",
          "-",
          "-",
          "-",
          1074,
          84.9,
          66.18,
          0,
          80.71,
          89.64,
          97.1,
        ],
      },
      {
        111: [
          "1967",
          26.25,
          62000,
          96,
          "25.29",
          "22.284",
          "21.508",
          "20.153",
          "22.008",
          165,
          65.6,
          61.37,
          0,
          86.48,
          93.81,
          98.6,
        ],
      },
      {
        112: [
          "1198",
          2.92,
          852750,
          96,
          "2.926",
          "2.488",
          "2.28",
          "2.069",
          "2.318",
          247,
          75.8,
          17.66,
          0,
          70.14,
          85.68,
          96.22,
        ],
      },
      {
        113: [
          "1146",
          0.245,
          524000,
          96,
          "0.224",
          "0.213",
          "0.188",
          "0.175",
          "0.187",
          12.783,
          8.441,
          19.68,
          0,
          54.83,
          69.04,
          88,
        ],
      },
      {
        114: [
          "8017",
          0.8,
          6876000,
          95.9,
          "0.791",
          "0.788",
          "0.691",
          "0.677",
          "0.692",
          561,
          4.8,
          33.94,
          0,
          70.06,
          78.82,
          88.76,
        ],
      },
      {
        115: [
          "8056",
          0.15,
          0,
          95.9,
          "0.15",
          "0.146",
          "0.108",
          "0.103",
          "0.111",
          0,
          1.215,
          0,
          0,
          86.77,
          90.95,
          94.81,
        ],
      },
      {
        116: [
          "9933",
          0.325,
          0,
          95.8,
          "0.329",
          "0.314",
          "0.256",
          "0.228",
          "0.262",
          0,
          3.25,
          78.78,
          0,
          88.54,
          92.06,
          96.03,
        ],
      },
      {
        117: [
          "0609",
          1.33,
          144000,
          95.8,
          "1.337",
          "1.178",
          "1.007",
          "0.932",
          "1.027",
          19.236,
          11.3,
          71.88,
          0,
          80.77,
          84.69,
          90.9,
        ],
      },
      {
        118: [
          "0830",
          1.71,
          3362000,
          95.8,
          "1.657",
          "1.634",
          "1.624",
          "1.454",
          "1.642",
          582,
          36.8,
          71.55,
          0,
          84.43,
          89.49,
          92.59,
        ],
      },
      {
        119: [
          "9919",
          1.7,
          34000,
          95.7,
          "1.629",
          "1.366",
          "1.313",
          "1.194",
          "1.356",
          5.88,
          12.8,
          59.55,
          0,
          74.76,
          83.61,
          91.68,
        ],
      },
      {
        120: [
          "3311",
          9.84,
          9891220,
          95.7,
          "9.774",
          "8.69",
          "7.064",
          "6.663",
          "7.24",
          9731,
          495.7,
          56.31,
          7.33,
          76.56,
          86.12,
          95.81,
        ],
      },
      {
        121: [
          "0558",
          13.9,
          4563900,
          95.7,
          "14.406",
          "17.364",
          "17.603",
          "15.936",
          "17.808",
          6466,
          191.3,
          43.39,
          0,
          67.36,
          76.82,
          89.76,
        ],
      },
      {
        122: [
          "0695",
          4.2,
          336000,
          95.6,
          "4.319",
          "4.583",
          "4.597",
          "3.941",
          "4.742",
          140,
          23.1,
          0.01,
          0,
          75.28,
          79.94,
          88.02,
        ],
      },
      {
        123: [
          "1262",
          0.52,
          404000,
          95.6,
          "0.512",
          "0.497",
          "0.406",
          "0.373",
          "0.415",
          20.984,
          6.911,
          55,
          0,
          74.69,
          78.97,
          84.66,
        ],
      },
      {
        124: [
          "1091",
          1.07,
          18318100,
          95.6,
          "1.093",
          "1.03",
          "1.063",
          "0.923",
          "1.09",
          1998,
          36.6,
          0.14,
          0,
          59.63,
          69.39,
          83.14,
        ],
      },
      {
        125: [
          "8268",
          0.67,
          40000,
          95.5,
          "0.762",
          "0.751",
          "0.569",
          "0.505",
          "-",
          2.68,
          1.34,
          0.15,
          0,
          53.82,
          68.41,
          83.52,
        ],
      },
      {
        126: [
          "0316",
          200,
          1029310,
          95.5,
          "193.85",
          "161.452",
          "155.473",
          "146.201",
          "156.82",
          20520,
          1320.7,
          47.99,
          0,
          85.16,
          91.9,
          95.9,
        ],
      },
      {
        127: [
          "1171",
          16.94,
          26089700,
          95.5,
          "16.076",
          "14.097",
          "13.04",
          "12.272",
          "13.149",
          44647,
          321.8,
          0.13,
          31.4,
          45.88,
          67.07,
          84.9,
        ],
      },
      {
        128: [
          "3600",
          5.21,
          1805000,
          95.4,
          "5.35",
          "5.831",
          "7.014",
          "6.342",
          "6.908",
          977,
          49.8,
          54.84,
          0,
          74.07,
          82.7,
          89.96,
        ],
      },
      {
        129: [
          "1548",
          32.25,
          4109350,
          95.4,
          "31.82",
          "34.937",
          "34.415",
          "30.966",
          "34.382",
          13268,
          677.7,
          36.8,
          20.14,
          57.6,
          75.87,
          89.1,
        ],
      },
      {
        130: [
          "0197",
          0.142,
          9015000,
          95.3,
          "0.154",
          "0.144",
          "0.113",
          "0.105",
          "0.115",
          126,
          2.984,
          10.95,
          0,
          52.55,
          59.97,
          73.13,
        ],
      },
      {
        131: [
          "6878",
          1.94,
          20020000,
          95.3,
          "2.079",
          "1.85",
          "2.056",
          "1.838",
          "2.054",
          3891,
          139.8,
          11.16,
          0,
          36.82,
          55.68,
          74.92,
        ],
      },
      {
        132: [
          "8223",
          2.44,
          220000,
          95.3,
          "2.414",
          "2.148",
          "1.975",
          "1.769",
          "2.024",
          53.292,
          9.76,
          75,
          0,
          91.19,
          94.02,
          97.56,
        ],
      },
      {
        133: [
          "8173",
          0.57,
          645,
          95.2,
          "0.536",
          "0.768",
          "0.516",
          "0.45",
          "0.54",
          0,
          1.227,
          1.27,
          0,
          80.68,
          85.05,
          92.41,
        ],
      },
      {
        134: [
          "1827",
          1.5,
          35000,
          95.2,
          "1.605",
          "1.69",
          "1.484",
          "1.334",
          "1.533",
          5.295,
          6,
          0.01,
          0,
          86.94,
          89.97,
          94.22,
        ],
      },
      {
        135: [
          "1108",
          14.92,
          5319310,
          95.2,
          "16.874",
          "15.437",
          "12.479",
          "10.973",
          "12.826",
          8086,
          37.3,
          0.37,
          31.64,
          44.61,
          58.69,
          77.74,
        ],
      },
      {
        136: [
          "8357",
          0.365,
          0,
          95.1,
          "0.366",
          "0.434",
          "0.304",
          "0.261",
          "0.312",
          0,
          2.278,
          56.23,
          0,
          71.9,
          82.25,
          91.53,
        ],
      },
      {
        137: [
          "2068",
          2.64,
          11877000,
          95.1,
          "2.556",
          "2.188",
          "1.917",
          "1.783",
          "1.97",
          3166,
          10.5,
          0,
          11.52,
          79.53,
          89.52,
          94.79,
        ],
      },
      {
        138: [
          "1626",
          2,
          20000,
          95.1,
          "1.934",
          "1.807",
          "1.619",
          "1.489",
          "1.655",
          4.004,
          6,
          4.24,
          0,
          92.15,
          95.41,
          98.07,
        ],
      },
      {
        139: [
          "2996",
          0.41,
          124410,
          95,
          "0.431",
          "0.193",
          "-",
          "-",
          "-",
          4.972,
          3.245,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        140: [
          "1894",
          0.66,
          0,
          95,
          "0.66",
          "0.66",
          "0.66",
          "0.649",
          "0.66",
          0,
          5.066,
          20.97,
          0,
          73.27,
          78.74,
          85.59,
        ],
      },
      {
        141: [
          "3773",
          0.64,
          262000,
          95,
          "0.638",
          "0.626",
          "0.485",
          "0.47",
          "0.488",
          16.606,
          2.656,
          42.3,
          0,
          77.47,
          82.8,
          90.34,
        ],
      },
      {
        142: [
          "1001",
          0.44,
          262000,
          94.9,
          "0.449",
          "0.425",
          "0.332",
          "0.311",
          "0.34",
          11.338,
          2.82,
          35.27,
          0,
          57.59,
          64.2,
          77.79,
        ],
      },
      {
        143: [
          "0596",
          4.15,
          2084000,
          94.9,
          "4.22",
          "4.047",
          "3.561",
          "3.233",
          "3.702",
          869,
          47.3,
          53.66,
          0,
          70.81,
          75.49,
          83.03,
        ],
      },
      {
        144: [
          "0746",
          8.08,
          1060000,
          94.8,
          "7.99",
          "8.022",
          "7.315",
          "6.549",
          "7.426",
          860,
          66.6,
          75.95,
          0,
          81.08,
          84.83,
          91.98,
        ],
      },
      {
        145: [
          "2878",
          0.86,
          31508500,
          94.8,
          "0.805",
          "0.791",
          "0.876",
          "0.824",
          "0.878",
          2789,
          21.4,
          5.3,
          0,
          46.64,
          56.07,
          70.49,
        ],
      },
      {
        146: [
          "0989",
          0.65,
          3475000,
          94.8,
          "0.55",
          "0.552",
          "0.447",
          "0.444",
          "0.457",
          197,
          43.9,
          5.68,
          1.67,
          73.03,
          81.42,
          90.74,
        ],
      },
      {
        147: [
          "1706",
          0.25,
          620000,
          94.7,
          "0.255",
          "0.255",
          "0.257",
          "0.222",
          "0.26",
          15.5,
          2.5,
          61.05,
          0,
          78.7,
          83.49,
          92.04,
        ],
      },
      {
        148: [
          "2187",
          2,
          3608000,
          94.7,
          "2.022",
          "1.866",
          "1.568",
          "-",
          "1.607",
          722,
          14.9,
          65.01,
          0,
          78.32,
          88.41,
          95.3,
        ],
      },
      {
        149: [
          "0412",
          0.75,
          5796110,
          94.7,
          "0.749",
          "0.725",
          "0.567",
          "0.545",
          "0.572",
          442,
          180.6,
          7.1,
          0.02,
          72.07,
          84.49,
          94.05,
        ],
      },
      {
        150: [
          "2221",
          0.62,
          184000,
          94.6,
          "0.647",
          "0.762",
          "0.703",
          "0.639",
          "0.714",
          11.408,
          5.904,
          5.76,
          0,
          53.67,
          64.22,
          78.84,
        ],
      },
      {
        151: [
          "0159",
          0.29,
          0,
          94.6,
          "0.29",
          "0.283",
          "0.265",
          "0.249",
          "0.269",
          0,
          26.9,
          46.43,
          0,
          62,
          71.45,
          86.41,
        ],
      },
      {
        152: [
          "1034",
          1.97,
          1744000,
          94.6,
          "2.078",
          "2.116",
          "1.933",
          "1.835",
          "1.958",
          343,
          31.5,
          82.76,
          0,
          86.31,
          88.28,
          91.88,
        ],
      },
      {
        153: [
          "0206",
          0.4,
          298000,
          94.5,
          "0.403",
          "0.331",
          "0.3",
          "0.279",
          "0.31",
          11.94,
          12.9,
          60.83,
          0,
          84.4,
          92.1,
          95.56,
        ],
      },
      {
        154: [
          "1798",
          3.37,
          30679700,
          94.5,
          "3.42",
          "3.138",
          "2.781",
          "2.481",
          "2.858",
          10229,
          84.2,
          0.15,
          0,
          44.94,
          59.72,
          81.35,
        ],
      },
      {
        155: [
          "1563",
          2.47,
          288000,
          94.5,
          "2.518",
          "2.457",
          "2.545",
          "2.39",
          "2.561",
          70.86,
          37,
          1.73,
          0,
          70.92,
          76.47,
          85.43,
        ],
      },
      {
        156: [
          "1361",
          3.88,
          480000,
          94.4,
          "3.902",
          "3.761",
          "3.754",
          "3.532",
          "3.784",
          186,
          80.2,
          0.04,
          0.62,
          76.39,
          81.54,
          90.42,
        ],
      },
      {
        157: [
          "1939",
          0.97,
          0,
          94.4,
          "0.964",
          "0.949",
          "0.765",
          "0.742",
          "0.77",
          0,
          4.85,
          52.13,
          0,
          84.59,
          92.71,
          98.02,
        ],
      },
      {
        158: [
          "1160",
          1.7,
          0,
          94.3,
          "1.7",
          "1.7",
          "1.676",
          "1.437",
          "1.7",
          0,
          2.938,
          3.45,
          0,
          78.98,
          91.44,
          97.56,
        ],
      },
      {
        159: [
          "3899",
          11.02,
          2210000,
          94.3,
          "11.4",
          "10.597",
          "9.677",
          "8.875",
          "9.766",
          2458,
          223.4,
          1.94,
          9.64,
          23.73,
          29.16,
          37.1,
        ],
      },
      {
        160: [
          "1533",
          9.5,
          30500,
          94.3,
          "9.341",
          "8.165",
          "6.977",
          "6.771",
          "7.122",
          28.983,
          3.337,
          0.02,
          22.76,
          76.06,
          85.07,
          92.56,
        ],
      },
      {
        161: [
          "1220",
          0.121,
          240000,
          94.2,
          "0.123",
          "0.094",
          "0.086",
          "0.09",
          "0.086",
          2.817,
          2.396,
          0.95,
          0,
          41.86,
          57.21,
          69.12,
        ],
      },
      {
        162: [
          "1161",
          1.69,
          1634000,
          94.2,
          "1.801",
          "1.4",
          "1.291",
          "1.229",
          "1.317",
          276,
          11.5,
          48.83,
          0,
          69.83,
          74.47,
          81.65,
        ],
      },
      {
        163: [
          "8316",
          1.43,
          2630000,
          94.2,
          "1.438",
          "1.544",
          "1.365",
          "1.184",
          "1.378",
          377,
          11.4,
          6.62,
          0,
          85.14,
          90.17,
          95.46,
        ],
      },
      {
        164: [
          "1470",
          0.159,
          284000,
          94.1,
          "0.134",
          "0.117",
          "0.122",
          "0.117",
          "0.125",
          4.587,
          1.272,
          0.03,
          0,
          83.58,
          87.99,
          91.58,
        ],
      },
      {
        165: [
          "1100",
          1.72,
          508000,
          94.1,
          "1.611",
          "1.535",
          "1.349",
          "1.243",
          "1.384",
          86.172,
          6.972,
          65.89,
          0,
          81.43,
          87.68,
          94.55,
        ],
      },
      {
        166: [
          "1639",
          1.58,
          0,
          94.1,
          "1.481",
          "1.483",
          "1.176",
          "1.131",
          "1.182",
          0,
          24.3,
          71.8,
          0,
          79.59,
          84.27,
          91.36,
        ],
      },
      {
        167: [
          "1683",
          0.19,
          160000,
          94,
          "0.21",
          "0.196",
          "0.139",
          "0.14",
          "0.14",
          3.02,
          1.368,
          0.13,
          0,
          47.73,
          59.59,
          75.38,
        ],
      },
      {
        168: [
          "8535",
          0.415,
          500000,
          94,
          "0.415",
          "0.423",
          "0.42",
          "0.411",
          "0.419",
          20.75,
          4.98,
          75.01,
          0,
          83.53,
          88.64,
          94.55,
        ],
      },
      {
        169: [
          "8032",
          1.45,
          2668950,
          93.9,
          "1.471",
          "1.57",
          "1.495",
          "1.288",
          "1.526",
          388,
          139.5,
          29.28,
          0,
          70.4,
          76.74,
          87.97,
        ],
      },
      {
        170: [
          "1719",
          1.12,
          4576000,
          93.9,
          "1.091",
          "0.901",
          "0.804",
          "0.781",
          "0.813",
          512,
          19.3,
          24.28,
          0,
          83.78,
          90.13,
          94.2,
        ],
      },
      {
        171: [
          "2185",
          30,
          212000,
          93.9,
          "33.16",
          "-",
          "-",
          "-",
          "-",
          655,
          18.4,
          61.15,
          0,
          81.76,
          88.06,
          97.33,
        ],
      },
      {
        172: [
          "2080",
          0.76,
          0,
          93.8,
          "0.818",
          "0.79",
          "0.697",
          "0.619",
          "0.709",
          0,
          2.85,
          57.73,
          0,
          77.23,
          82.58,
          88.11,
        ],
      },
      {
        173: [
          "1085",
          3.69,
          380000,
          93.8,
          "3.861",
          "3.508",
          "2.977",
          "2.769",
          "3.055",
          133,
          14.3,
          10.76,
          0,
          72.24,
          81.64,
          93.02,
        ],
      },
      {
        174: [
          "8321",
          0.88,
          0,
          93.8,
          "1.113",
          "1.025",
          "1.048",
          "0.955",
          "1.078",
          0,
          2.027,
          0.91,
          0,
          35.5,
          52.77,
          78.32,
        ],
      },
      {
        175: [
          "8282",
          0.61,
          72000,
          93.7,
          "0.615",
          "0.577",
          "0.486",
          "0.442",
          "0.501",
          4.476,
          0.976,
          19.1,
          0,
          81.42,
          85.74,
          91.53,
        ],
      },
      {
        176: [
          "3878",
          0.54,
          0,
          93.7,
          "0.535",
          "0.575",
          "0.527",
          "0.476",
          "0.538",
          0,
          2.59,
          0.01,
          0,
          63.29,
          74.06,
          90.3,
        ],
      },
      {
        177: [
          "3800",
          2.61,
          78100800,
          93.7,
          "2.738",
          "2.825",
          "2.285",
          "2.209",
          "2.287",
          20584,
          707.2,
          2.24,
          14.73,
          36.36,
          46.77,
          66.46,
        ],
      },
      {
        178: [
          "2337",
          13.1,
          356000,
          93.6,
          "13.532",
          "12.918",
          "11.004",
          "10.313",
          "10.851",
          478,
          49,
          1.58,
          0,
          86.36,
          91.34,
          96.79,
        ],
      },
      {
        179: [
          "8603",
          0.4,
          0,
          93.6,
          "0.4",
          "0.399",
          "0.336",
          "0.305",
          "0.347",
          0,
          3.2,
          79.12,
          0,
          90.74,
          94.97,
          98.54,
        ],
      },
      {
        180: [
          "1184",
          5.35,
          40000,
          93.6,
          "5.185",
          "4.815",
          "4.278",
          "4.0",
          "4.366",
          21.176,
          33.4,
          36.18,
          0,
          68.18,
          81.54,
          92.44,
        ],
      },
      {
        181: [
          "0361",
          0.118,
          860000,
          93.5,
          "0.118",
          "0.152",
          "0.198",
          "0.183",
          "0.194",
          9.968,
          6.137,
          0.14,
          0,
          73.26,
          79.89,
          87.61,
        ],
      },
      {
        182: [
          "1083",
          6.22,
          10443000,
          93.5,
          "6.545",
          "5.961",
          "5.446",
          "5.168",
          "5.425",
          6491,
          196.5,
          64.31,
          2.58,
          81.04,
          87.22,
          93.3,
        ],
      },
      {
        183: [
          "1296",
          0.52,
          348000,
          93.4,
          "0.532",
          "0.518",
          "0.507",
          "0.47",
          "0.524",
          18.186,
          6.811,
          0.02,
          0,
          41.11,
          55.99,
          72.01,
        ],
      },
      {
        184: [
          "8400",
          0.065,
          350000,
          93.4,
          "0.05",
          "0.05",
          "0.05",
          "0.047",
          "0.05",
          2.145,
          0.65,
          72.52,
          0,
          81.17,
          87.08,
          91.96,
        ],
      },
      {
        185: [
          "0855",
          10.32,
          1834000,
          93.4,
          "10.644",
          "8.887",
          "7.732",
          "7.348",
          "7.94",
          1876,
          168.4,
          0.19,
          7.1,
          49.95,
          63.64,
          86.11,
        ],
      },
      {
        186: [
          "0486",
          8.25,
          3662020,
          93.3,
          "7.738",
          "7.431",
          "6.705",
          "6.394",
          "6.789",
          3001,
          1253.4,
          94.58,
          0,
          98.6,
          98.96,
          99.37,
        ],
      },
      {
        187: [
          "1072",
          11.3,
          13052600,
          93.3,
          "12.558",
          "12.602",
          "10.317",
          "9.459",
          "10.607",
          15111,
          38.4,
          0.62,
          29.7,
          48.63,
          64.92,
          77.4,
        ],
      },
      {
        188: [
          "1575",
          0.224,
          9564000,
          93.3,
          "0.162",
          "0.167",
          "0.175",
          "0.166",
          "0.176",
          208,
          2.24,
          60.02,
          0,
          77.6,
          83.38,
          89.07,
        ],
      },
      {
        189: [
          "0956",
          5.65,
          8067770,
          93.2,
          "5.951",
          "5.873",
          "5.39",
          "4.801",
          "5.552",
          4559,
          103.9,
          0.18,
          19.8,
          56.71,
          72.9,
          85.07,
        ],
      },
      {
        190: [
          "2169",
          1.98,
          1540000,
          93.2,
          "2.022",
          "1.783",
          "1.563",
          "1.481",
          "1.584",
          304,
          19.8,
          72.39,
          0,
          91.5,
          96.96,
          98.55,
        ],
      },
      {
        191: [
          "8172",
          0.25,
          340000,
          93.2,
          "0.238",
          "0.272",
          "0.277",
          "0.3",
          "0.271",
          8.5,
          10.5,
          58.18,
          0,
          73.44,
          82.76,
          92.48,
        ],
      },
      {
        192: [
          "0948",
          1.36,
          60000,
          93.1,
          "1.328",
          "1.304",
          "1.083",
          "1.036",
          "1.111",
          8.16,
          4.275,
          6.72,
          0,
          81.91,
          85.82,
          92,
        ],
      },
      {
        193: [
          "1192",
          0.315,
          0,
          93.1,
          "0.315",
          "0.315",
          "0.315",
          "0.314",
          "0.315",
          0,
          3.875,
          59.39,
          0,
          72.47,
          81.15,
          88.71,
        ],
      },
      {
        194: [
          "8269",
          0.185,
          152167,
          93.1,
          "0.202",
          "0.19",
          "0.158",
          "0.161",
          "0.16",
          2.712,
          1.33,
          0.01,
          0,
          39.24,
          53.27,
          78.16,
        ],
      },
      {
        195: [
          "1708",
          6.32,
          7000,
          93,
          "6.587",
          "5.044",
          "5.194",
          "5.075",
          "5.238",
          4.405,
          14.5,
          17.81,
          0,
          53.67,
          73.14,
          94.26,
        ],
      },
      {
        196: [
          "3996",
          1.28,
          37188000,
          93,
          "1.309",
          "1.189",
          "1.092",
          "1.014",
          "1.116",
          4784,
          118.5,
          2.65,
          3.9,
          58.41,
          67.88,
          81.34,
        ],
      },
      {
        197: [
          "8231",
          1.2,
          0,
          92.9,
          "1.202",
          "1.186",
          "0.935",
          "0.963",
          "-",
          0,
          1.456,
          71.51,
          0,
          77.94,
          81.29,
          87.34,
        ],
      },
      {
        198: [
          "0320",
          0.91,
          106000,
          92.9,
          "0.931",
          "0.911",
          "0.846",
          "0.759",
          "0.848",
          9.742,
          7.667,
          40.51,
          0,
          70.55,
          79.28,
          86.66,
        ],
      },
      {
        199: [
          "8620",
          0.26,
          100000,
          92.9,
          "0.249",
          "0.253",
          "0.202",
          "0.191",
          "0.207",
          2.577,
          1.248,
          0,
          0,
          81.68,
          87.54,
          92.9,
        ],
      },
      {
        200: [
          "6829",
          0.202,
          770000,
          92.8,
          "0.19",
          "0.169",
          "0.155",
          "0.146",
          "0.149",
          15.332,
          2.424,
          51.48,
          0,
          81.84,
          87.93,
          92.64,
        ],
      },
      {
        201: [
          "2273",
          41.5,
          120400,
          92.8,
          "42.77",
          "-",
          "-",
          "-",
          "-",
          521,
          95.6,
          86.09,
          0,
          91.06,
          94.56,
          98.63,
        ],
      },
      {
        202: [
          "1702",
          2.25,
          0,
          92.8,
          "2.323",
          "1.975",
          "1.693",
          "1.662",
          "1.739",
          0,
          13.9,
          74.08,
          0,
          92.65,
          97.18,
          99.86,
        ],
      },
      {
        203: [
          "8027",
          0.43,
          0,
          92.7,
          "0.43",
          "0.445",
          "0.536",
          "0.493",
          "0.536",
          0,
          0.963,
          16.35,
          0,
          41.78,
          56.44,
          80.8,
        ],
      },
      {
        204: [
          "0047",
          0.079,
          0,
          92.7,
          "0.079",
          "0.077",
          "0.065",
          "0.061",
          "0.066",
          0,
          7.956,
          75.18,
          0,
          82.93,
          85.24,
          89.2,
        ],
      },
      {
        205: [
          "0397",
          0.154,
          5756160,
          92.7,
          "0.143",
          "0.167",
          "0.138",
          "0.127",
          "0.141",
          85.838,
          4.287,
          2.71,
          0,
          34.77,
          43.46,
          58.69,
        ],
      },
      {
        206: [
          "3680",
          9,
          3113000,
          92.6,
          "7.94",
          "6.626",
          "6.689",
          "6.708",
          "6.732",
          2729,
          36.1,
          34.59,
          0,
          65.2,
          73.74,
          82.28,
        ],
      },
      {
        207: [
          "0650",
          1.15,
          694000,
          92.6,
          "1.174",
          "1.223",
          "1.127",
          "1.07",
          "1.126",
          79.918,
          79.9,
          72.93,
          0,
          83,
          88.93,
          94.65,
        ],
      },
      {
        208: [
          "8270",
          0.059,
          0,
          92.6,
          "0.057",
          "0.062",
          "0.052",
          "0.051",
          "0.052",
          0,
          1.226,
          46.81,
          0,
          70.63,
          78.18,
          87.54,
        ],
      },
      {
        209: [
          "2296",
          1.06,
          0,
          92.5,
          "1.06",
          "1.059",
          "1.124",
          "1.175",
          "1.116",
          0,
          21.2,
          67.55,
          0,
          92.07,
          95.99,
          98.88,
        ],
      },
      {
        210: [
          "0924",
          0.71,
          24000,
          92.5,
          "0.687",
          "0.653",
          "0.602",
          "0.563",
          "0.616",
          1.712,
          7.1,
          4,
          0,
          82.8,
          91.52,
          97.58,
        ],
      },
      {
        211: [
          "1500",
          0.275,
          340000,
          92.4,
          "0.261",
          "0.267",
          "0.234",
          "0.223",
          "0.235",
          9.602,
          2.282,
          72.32,
          0,
          81.22,
          85.42,
          90.17,
        ],
      },
      {
        212: [
          "9979",
          5.07,
          14206000,
          92.4,
          "5.128",
          "4.499",
          "4.233",
          "4.313",
          "4.198",
          7070,
          99.2,
          73.19,
          0,
          79.69,
          83.49,
          90.89,
        ],
      },
      {
        213: [
          "2683",
          0.415,
          0,
          92.4,
          "0.408",
          "0.402",
          "0.345",
          "0.328",
          "0.346",
          0,
          1.696,
          75.28,
          0,
          80.63,
          84.93,
          91.73,
        ],
      },
      {
        214: [
          "1488",
          1,
          396000,
          92.3,
          "1.082",
          "0.953",
          "0.829",
          "0.788",
          "0.846",
          39.072,
          15.7,
          78.28,
          0,
          87.04,
          91.38,
          95.43,
        ],
      },
      {
        215: [
          "8025",
          0.5,
          0,
          92.3,
          "0.5",
          "0.578",
          "0.571",
          "0.651",
          "0.522",
          0,
          1.004,
          69.42,
          0,
          78.34,
          84.5,
          89.94,
        ],
      },
      {
        216: [
          "0259",
          3.01,
          292000,
          92.3,
          "2.899",
          "2.647",
          "2.622",
          "2.508",
          "2.632",
          87.402,
          29.8,
          57.62,
          0,
          78.34,
          84.54,
          91.97,
        ],
      },
      {
        217: [
          "3997",
          1.52,
          0,
          92.2,
          "1.463",
          "1.373",
          "1.255",
          "1.183",
          "1.28",
          0,
          1.951,
          61.72,
          0,
          91.62,
          94.39,
          97.22,
        ],
      },
      {
        218: [
          "1385",
          23.35,
          1136000,
          92.2,
          "23.665",
          "25.678",
          "23.497",
          "20.438",
          "23.756",
          2630,
          66.3,
          0.04,
          20.5,
          35,
          51.1,
          72.74,
        ],
      },
      {
        219: [
          "1672",
          4.03,
          12092000,
          92.2,
          "3.864",
          "3.108",
          "3.051",
          "3.07",
          "3.02",
          4948,
          44.1,
          60.85,
          0,
          74.06,
          79.49,
          88.26,
        ],
      },
      {
        220: [
          "1372",
          4.56,
          103000,
          92.1,
          "4.738",
          "4.254",
          "4.857",
          "5.164",
          "4.817",
          46.761,
          13.9,
          1.58,
          0,
          57.27,
          70.51,
          84.74,
        ],
      },
      {
        221: [
          "1919",
          15.54,
          36576600,
          92.1,
          "15.294",
          "13.643",
          "14.052",
          "14.108",
          "13.759",
          56676,
          521.3,
          0.29,
          25.07,
          47.68,
          62.24,
          78.69,
        ],
      },
      {
        222: [
          "0401",
          0.16,
          0,
          92.1,
          "0.16",
          "0.159",
          "0.136",
          "0.129",
          "0.138",
          0,
          0.896,
          23.28,
          0,
          69.86,
          76.45,
          85.13,
        ],
      },
      {
        223: [
          "0080",
          0.26,
          3112400,
          92,
          "0.248",
          "0.262",
          "0.213",
          "0.214",
          "0.212",
          75.765,
          2.247,
          0.82,
          0,
          58.28,
          70.4,
          83.69,
        ],
      },
      {
        224: [
          "8047",
          0.124,
          31040000,
          92,
          "0.105",
          "0.067",
          "0.081",
          "0.098",
          "0.08",
          376,
          6.328,
          5.37,
          0,
          66.46,
          72.33,
          80.36,
        ],
      },
      {
        225: [
          "8540",
          2.29,
          0,
          91.9,
          "2.29",
          "2.271",
          "2.19",
          "2.084",
          "2.205",
          0,
          4.581,
          64.57,
          0,
          95.21,
          96.48,
          98.79,
        ],
      },
      {
        226: [
          "1961",
          0.75,
          504000,
          91.9,
          "0.782",
          "0.637",
          "0.583",
          "0.589",
          "0.587",
          38.568,
          4.095,
          33.25,
          0,
          86.48,
          92.04,
          96.18,
        ],
      },
      {
        227: [
          "2132",
          0.238,
          344000,
          91.9,
          "0.235",
          "0.255",
          "0.21",
          "0.199",
          "0.213",
          7.859,
          3.808,
          75.12,
          0,
          84.69,
          89.83,
          94.89,
        ],
      },
      {
        228: [
          "0455",
          0.28,
          2626000,
          91.8,
          "0.299",
          "0.281",
          "0.235",
          "0.228",
          "0.238",
          74.379,
          6.02,
          78.49,
          0,
          85.33,
          88.47,
          91.96,
        ],
      },
      {
        229: [
          "0995",
          6.6,
          4052300,
          91.8,
          "6.482",
          "5.89",
          "5.172",
          "5.226",
          "5.203",
          2706,
          32.5,
          1.98,
          24.95,
          54.98,
          71.9,
          87.6,
        ],
      },
      {
        230: [
          "9868",
          184.3,
          1179280,
          91.8,
          "183.29",
          "185.354",
          "167.279",
          "-",
          "167.512",
          21846,
          3156.6,
          50.78,
          0,
          97.59,
          98.75,
          99.32,
        ],
      },
      {
        231: [
          "8428",
          0.193,
          1248000,
          91.7,
          "0.216",
          "0.186",
          "0.201",
          "0.207",
          "0.201",
          24.258,
          0.75,
          1.38,
          0,
          47.63,
          57.63,
          70.55,
        ],
      },
      {
        232: [
          "8416",
          0.123,
          65000,
          91.7,
          "0.123",
          "0.123",
          "0.112",
          "0.105",
          "0.115",
          0.797,
          0.492,
          55.16,
          0,
          82.91,
          86,
          90.89,
        ],
      },
      {
        233: [
          "0332",
          0.52,
          1692000,
          91.7,
          "0.504",
          "0.512",
          "0.459",
          "0.416",
          "0.47",
          86.528,
          34,
          2.41,
          0,
          71.68,
          78.49,
          88.63,
        ],
      },
      {
        234: [
          "1397",
          0.52,
          176000,
          91.6,
          "0.512",
          "0.524",
          "0.433",
          "0.427",
          "0.435",
          8.52,
          2.158,
          0.13,
          0,
          82.04,
          85.68,
          90.61,
        ],
      },
      {
        235: [
          "0418",
          0.8,
          468000,
          91.6,
          "0.817",
          "0.871",
          "0.769",
          "0.725",
          "0.778",
          37.302,
          9.598,
          31.23,
          0,
          51.79,
          60.81,
          74.58,
        ],
      },
      {
        236: [
          "1028",
          0.345,
          129000,
          91.6,
          "0.354",
          "0.352",
          "0.358",
          "0.339",
          "0.358",
          4.455,
          7.166,
          7.83,
          6.76,
          45.47,
          58.24,
          80.49,
        ],
      },
      {
        237: [
          "0987",
          0.335,
          1514020,
          91.5,
          "0.327",
          "0.305",
          "0.291",
          "0.272",
          "0.298",
          51.072,
          8.396,
          0.49,
          0,
          82.02,
          84.53,
          88.87,
        ],
      },
      {
        238: [
          "8118",
          0.45,
          1816000,
          91.5,
          "0.337",
          "0.254",
          "0.286",
          "0.323",
          "0.279",
          85.36,
          2.25,
          57.01,
          0,
          74.66,
          83.77,
          90.73,
        ],
      },
      {
        239: [
          "0556",
          0.88,
          1326000,
          91.4,
          "0.898",
          "0.91",
          "0.813",
          "0.735",
          "0.837",
          115,
          7.392,
          0.02,
          0,
          83.84,
          87.79,
          92.1,
        ],
      },
      {
        240: [
          "1046",
          0.64,
          55000,
          91.4,
          "0.612",
          "0.547",
          "0.462",
          "0.473",
          "0.465",
          3.465,
          5.802,
          0.78,
          0,
          84.9,
          89.59,
          94.14,
        ],
      },
      {
        241: [
          "0929",
          1.06,
          290000,
          91.4,
          "0.965",
          "0.892",
          "0.839",
          "0.802",
          "0.866",
          31.54,
          11.1,
          0.71,
          0,
          75.75,
          84.93,
          92.11,
        ],
      },
      {
        242: [
          "0973",
          32.65,
          142859,
          91.3,
          "32.555",
          "30.956",
          "28.57",
          "27.552",
          "28.597",
          468,
          482.2,
          66.97,
          0,
          87.86,
          95.77,
          99.36,
        ],
      },
      {
        243: [
          "1962",
          1.21,
          842000,
          91.3,
          "1.159",
          "1.089",
          "0.796",
          "0.861",
          "0.807",
          99.082,
          8.302,
          0.01,
          0,
          68.35,
          79.41,
          91.33,
        ],
      },
      {
        244: [
          "1576",
          2.91,
          348000,
          91.3,
          "2.964",
          "2.555",
          "2.335",
          "2.332",
          "2.358",
          100,
          32,
          54.6,
          0,
          79.86,
          89.09,
          96.63,
        ],
      },
      {
        245: [
          "1740",
          0.226,
          50000,
          91.2,
          "0.23",
          "0.236",
          "0.179",
          "0.174",
          "0.182",
          1.123,
          2.345,
          53.35,
          0,
          72.33,
          80.05,
          88.06,
        ],
      },
      {
        246: [
          "1520",
          0.226,
          186000,
          91.2,
          "0.241",
          "0.192",
          "0.203",
          "0.202",
          "0.205",
          4.25,
          3.819,
          0.57,
          0,
          58.28,
          64.8,
          76.53,
        ],
      },
      {
        247: [
          "8075",
          1.19,
          0,
          91.2,
          "1.196",
          "1.287",
          "1.467",
          "1.455",
          "1.453",
          0,
          35.5,
          2.95,
          0,
          91.07,
          96,
          98.83,
        ],
      },
      {
        248: [
          "8483",
          0.141,
          0,
          91.1,
          "0.133",
          "0.13",
          "0.112",
          "0.106",
          "0.115",
          0,
          1.128,
          75.02,
          0,
          81.52,
          85.54,
          91.77,
        ],
      },
      {
        249: [
          "0398",
          4.55,
          728000,
          91.1,
          "4.716",
          "4.803",
          "4.303",
          "4.018",
          "4.31",
          333,
          22.1,
          36.52,
          0,
          57.87,
          66.5,
          79.24,
        ],
      },
      {
        250: [
          "0985",
          2.4,
          44700,
          91,
          "2.438",
          "2.112",
          "1.853",
          "1.887",
          "1.834",
          10.734,
          11.6,
          0.04,
          0,
          84.39,
          87.74,
          92.16,
        ],
      },
      {
        251: [
          "1666",
          7.6,
          2540680,
          91,
          "8.246",
          "6.439",
          "6.197",
          "6.242",
          "6.188",
          1924,
          47.7,
          0.09,
          11.35,
          37.63,
          54.12,
          76.96,
        ],
      },
      {
        252: [
          "0308",
          1.61,
          6000000,
          91,
          "1.641",
          "1.457",
          "1.261",
          "1.273",
          "1.265",
          969,
          89.1,
          55.61,
          3.33,
          70.28,
          76.71,
          84.68,
        ],
      },
      {
        253: [
          "1020",
          0.177,
          1772000,
          90.9,
          "0.141",
          "0.156",
          "0.189",
          "0.16",
          "0.197",
          29.85,
          7.036,
          0.03,
          0,
          42.43,
          58.1,
          73.38,
        ],
      },
      {
        254: [
          "1696",
          10.18,
          1739200,
          90.9,
          "9.478",
          "10.855",
          "14.771",
          "14.152",
          "14.45",
          1785,
          47.4,
          46.65,
          0,
          75.27,
          81.14,
          90.13,
        ],
      },
      {
        255: [
          "1969",
          1.88,
          2037000,
          90.9,
          "1.845",
          "1.629",
          "1.487",
          "1.473",
          "1.504",
          380,
          22.5,
          78.96,
          0,
          93.89,
          95.52,
          98.06,
        ],
      },
      {
        256: [
          "1223",
          1.2,
          4330490,
          90.8,
          "1.03",
          "0.959",
          "0.918",
          "0.9",
          "0.927",
          501,
          35.6,
          10.96,
          0,
          53.69,
          74.28,
          94.67,
        ],
      },
      {
        257: [
          "0902",
          4.19,
          50433900,
          90.8,
          "4.657",
          "4.188",
          "3.669",
          "3.443",
          "3.757",
          21163,
          196.9,
          11.82,
          44.58,
          56.4,
          74.06,
          87.48,
        ],
      },
      {
        258: [
          "8019",
          0.29,
          3000,
          90.8,
          "0.292",
          "0.287",
          "0.257",
          "0.241",
          "0.259",
          0,
          0.747,
          6.25,
          0,
          27.79,
          44.42,
          70.05,
        ],
      },
      {
        259: [
          "6128",
          1.02,
          990000,
          90.7,
          "0.978",
          "0.812",
          "0.816",
          "0.882",
          "0.809",
          100,
          5.193,
          5.65,
          0,
          43.77,
          57.39,
          77.48,
        ],
      },
      {
        260: [
          "1795",
          0.88,
          2127000,
          90.7,
          "0.952",
          "0.835",
          "0.681",
          "0.672",
          "0.691",
          189,
          5.28,
          79.08,
          0,
          88.76,
          93.56,
          96.77,
        ],
      },
      {
        261: [
          "0570",
          5.44,
          56185000,
          90.7,
          "5.666",
          "4.156",
          "4.224",
          "4.337",
          "4.194",
          30899,
          273.9,
          25.64,
          15.58,
          49.95,
          68,
          90.26,
        ],
      },
      {
        262: [
          "2002",
          2.3,
          1898000,
          90.6,
          "2.237",
          "2.114",
          "1.938",
          "1.89",
          "1.946",
          434,
          20.8,
          45.32,
          0,
          60.22,
          69.38,
          82.74,
        ],
      },
      {
        263: [
          "0151",
          7.51,
          36176200,
          90.6,
          "7.63",
          "6.824",
          "5.969",
          "5.923",
          "5.992",
          27306,
          893.6,
          52.77,
          0.1,
          83.51,
          93.9,
          99.23,
        ],
      },
      {
        264: [
          "1152",
          0.068,
          0,
          90.5,
          "0.068",
          "0.068",
          "0.056",
          "0.054",
          "0.056",
          0,
          0.668,
          0.03,
          0,
          65.82,
          73.55,
          84.97,
        ],
      },
      {
        265: [
          "2108",
          0.3,
          168000,
          90.5,
          "0.305",
          "0.285",
          "0.266",
          "0.255",
          "0.266",
          5.04,
          2.4,
          75.01,
          0,
          85.63,
          90.88,
          96.08,
        ],
      },
      {
        266: [
          "0426",
          0.179,
          10000,
          90.5,
          "0.185",
          "0.183",
          "0.19",
          "0.176",
          "0.191",
          0.171,
          0.718,
          74.36,
          0,
          81.38,
          84.79,
          90.69,
        ],
      },
      {
        267: [
          "0370",
          0.48,
          1140000,
          90.4,
          "0.499",
          "0.448",
          "0.379",
          "0.359",
          "0.383",
          57.012,
          7.321,
          24.6,
          0,
          75.05,
          85.03,
          90.15,
        ],
      },
      {
        268: [
          "8087",
          0.109,
          5000,
          90.4,
          "0.113",
          "0.12",
          "0.103",
          "0.099",
          "0.104",
          0,
          0.753,
          9.22,
          0,
          30.87,
          48.02,
          71.81,
        ],
      },
      {
        269: [
          "1133",
          3.25,
          4295930,
          90.4,
          "3.48",
          "3.456",
          "2.898",
          "2.71",
          "2.94",
          1404,
          21.9,
          7.14,
          0,
          32.06,
          41.4,
          58.01,
        ],
      },
      {
        270: [
          "8333",
          0.198,
          0,
          90.3,
          "0.199",
          "0.173",
          "0.17",
          "0.165",
          "0.172",
          0,
          2.362,
          63.8,
          0,
          76.52,
          83.36,
          92.16,
        ],
      },
      {
        271: [
          "0935",
          1.21,
          286000,
          90.3,
          "1.22",
          "1.212",
          "1.108",
          "1.029",
          "1.126",
          34.296,
          14.7,
          54.49,
          0,
          83.1,
          91.99,
          97.74,
        ],
      },
      {
        272: [
          "3395",
          0.56,
          0,
          90.3,
          "0.558",
          "0.504",
          "0.47",
          "0.453",
          "0.474",
          0,
          2.228,
          32.24,
          0,
          83.22,
          87.5,
          94.45,
        ],
      },
      {
        273: [
          "8616",
          0.126,
          0,
          90.2,
          "0.126",
          "0.129",
          "0.122",
          "0.113",
          "0.125",
          0,
          1.26,
          75,
          0,
          82.22,
          86.9,
          93.6,
        ],
      },
      {
        274: [
          "0600",
          0.041,
          4982000,
          90.2,
          "0.04",
          "0.04",
          "0.035",
          "0.033",
          "0.035",
          20.148,
          1.751,
          0.61,
          0,
          55.66,
          66.72,
          76.01,
        ],
      },
      {
        275: [
          "2130",
          9.54,
          1568000,
          90.2,
          "9.522",
          "9.734",
          "8.561",
          "8.412",
          "8.63",
          1516,
          26.3,
          16.23,
          0,
          79.4,
          84.11,
          91.53,
        ],
      },
      {
        276: [
          "0865",
          0.099,
          25588000,
          90.1,
          "0.095",
          "0.075",
          "0.073",
          "0.075",
          "0.074",
          253,
          5.78,
          59.66,
          0,
          78.47,
          86.15,
          92.61,
        ],
      },
      {
        277: [
          "1737",
          0.196,
          640000,
          90.1,
          "0.201",
          "0.231",
          "0.195",
          "0.185",
          "0.198",
          12.288,
          1.96,
          75.01,
          0,
          82.41,
          85.26,
          90.13,
        ],
      },
      {
        278: [
          "3318",
          3.79,
          1364000,
          90,
          "3.813",
          "3.966",
          "3.791",
          "3.808",
          "3.763",
          529,
          40.9,
          40.59,
          0,
          74.79,
          82.52,
          91.62,
        ],
      },
      {
        279: [
          "8482",
          0.36,
          400000,
          90,
          "0.371",
          "0.381",
          "0.34",
          "0.315",
          "0.338",
          14.345,
          3.024,
          25.45,
          0,
          69.67,
          78.07,
          87.35,
        ],
      },
      {
        280: [
          "2343",
          3.23,
          69797100,
          90,
          "2.968",
          "2.969",
          "3.35",
          "3.164",
          "3.374",
          21837,
          155.4,
          0.28,
          0.23,
          74.8,
          81.53,
          90.62,
        ],
      },
      {
        281: [
          "0990",
          0.83,
          7005400,
          89.9,
          "0.829",
          "0.809",
          "1.074",
          "0.981",
          "1.056",
          557,
          111.8,
          1.08,
          0,
          76.94,
          80.31,
          87.19,
        ],
      },
      {
        282: [
          "8179",
          0.221,
          206000,
          89.9,
          "0.238",
          "0.253",
          "0.214",
          "0.209",
          "0.214",
          4.163,
          1.296,
          60.68,
          0,
          83.45,
          87.27,
          90.87,
        ],
      },
      {
        283: [
          "1164",
          0.89,
          6610000,
          89.9,
          "0.88",
          "0.922",
          "0.85",
          "0.809",
          "0.859",
          582,
          58.7,
          0.18,
          0,
          74.05,
          80.1,
          87.64,
        ],
      },
      {
        284: [
          "3838",
          0.217,
          4960000,
          89.8,
          "0.235",
          "0.187",
          "0.181",
          "0.18",
          "0.181",
          106,
          13,
          62.01,
          0,
          71.28,
          76.53,
          83.06,
        ],
      },
      {
        285: [
          "1443",
          0.26,
          0,
          89.8,
          "0.26",
          "0.236",
          "0.218",
          "0.226",
          "0.217",
          0,
          3.38,
          76.35,
          0,
          87.67,
          91.1,
          95.38,
        ],
      },
      {
        286: [
          "0643",
          0.18,
          20000,
          89.8,
          "0.215",
          "0.2",
          "0.181",
          "0.168",
          "0.183",
          0.369,
          1.472,
          0.05,
          0,
          68.5,
          78.55,
          88.81,
        ],
      },
      {
        287: [
          "1978",
          1.02,
          400000,
          89.7,
          "1.012",
          "1.039",
          "0.984",
          "1.02",
          "0.988",
          40.138,
          8.16,
          75.11,
          0,
          81.92,
          86.71,
          92.36,
        ],
      },
      {
        288: [
          "0899",
          0.069,
          2020000,
          89.7,
          "0.072",
          "0.078",
          "0.057",
          "0.054",
          "0.059",
          13.843,
          5.252,
          44,
          0,
          59.05,
          67.61,
          78.78,
        ],
      },
      {
        289: [
          "9936",
          4.6,
          1470000,
          89.7,
          "4.661",
          "4.899",
          "5.04",
          "4.763",
          "5.05",
          673,
          13.8,
          78.08,
          0,
          82.71,
          86.21,
          92.5,
        ],
      },
      {
        290: [
          "8525",
          0.6,
          0,
          89.6,
          "0.596",
          "0.541",
          "0.485",
          "0.461",
          "0.486",
          0,
          1.62,
          0.02,
          0,
          88.69,
          93,
          96.31,
        ],
      },
      {
        291: [
          "8495",
          0.32,
          24000,
          89.6,
          "0.302",
          "0.345",
          "0.297",
          "0.271",
          "0.308",
          0.764,
          1.229,
          34.39,
          0,
          70.25,
          77.44,
          87.6,
        ],
      },
      {
        292: [
          "8060",
          0.198,
          20000,
          89.5,
          "0.206",
          "0.21",
          "0.208",
          "0.192",
          "0.211",
          0.388,
          0.646,
          71.26,
          0,
          81.79,
          87.9,
          92.51,
        ],
      },
      {
        293: [
          "0228",
          0.156,
          2870000,
          89.5,
          "0.156",
          "0.149",
          "0.128",
          "0.121",
          "0.129",
          44.68,
          14.8,
          30.67,
          0,
          42.76,
          49.8,
          62.93,
        ],
      },
      {
        294: [
          "0976",
          0.72,
          4842000,
          89.5,
          "0.915",
          "1.08",
          "1.026",
          "0.872",
          "1.055",
          343,
          11.5,
          0.01,
          7.97,
          77.47,
          80.95,
          87.82,
        ],
      },
      {
        295: [
          "3393",
          3.2,
          2696660,
          89.4,
          "3.441",
          "3.112",
          "2.768",
          "2.702",
          "2.775",
          870,
          31.8,
          0.39,
          0.02,
          53.56,
          59.89,
          65.33,
        ],
      },
      {
        296: [
          "0887",
          0.213,
          1530000,
          89.4,
          "0.207",
          "0.207",
          "0.208",
          "0.196",
          "0.207",
          32.469,
          14.4,
          47.34,
          0,
          75.45,
          81.98,
          91.52,
        ],
      },
      {
        297: [
          "0716",
          0.96,
          8822000,
          89.4,
          "1.188",
          "1.081",
          "1.014",
          "0.922",
          "1.041",
          849,
          23.2,
          0.43,
          0,
          68.12,
          76.52,
          83.34,
        ],
      },
      {
        298: [
          "0822",
          0.75,
          11800000,
          89.3,
          "0.782",
          "0.93",
          "0.724",
          "0.683",
          "0.724",
          892,
          6.703,
          22.33,
          0,
          66.09,
          77.29,
          85.64,
        ],
      },
      {
        299: [
          "1866",
          5.43,
          1431000,
          89.3,
          "5.608",
          "5.221",
          "5.272",
          "4.853",
          "5.339",
          785,
          63.6,
          0.87,
          0,
          63.42,
          73.38,
          86.55,
        ],
      },
      {
        300: [
          "1047",
          0.64,
          0,
          89.3,
          "0.642",
          "0.657",
          "0.605",
          "0.577",
          "0.612",
          0,
          2.363,
          59.03,
          0,
          69.5,
          72.49,
          76.94,
        ],
      },
      {
        301: [
          "8377",
          0.49,
          10000,
          89.2,
          "0.491",
          "0.497",
          "0.536",
          "0.531",
          "0.531",
          0.49,
          2.005,
          13.88,
          0,
          85.31,
          90.37,
          95.81,
        ],
      },
      {
        302: [
          "1855",
          2.58,
          456000,
          89.2,
          "2.661",
          "2.609",
          "2.231",
          "2.223",
          "2.257",
          118,
          7.095,
          77.59,
          0,
          85.47,
          90.6,
          95.95,
        ],
      },
      {
        303: [
          "0991",
          1.55,
          24404000,
          89.2,
          "1.635",
          "1.458",
          "1.371",
          "1.331",
          "1.384",
          3780,
          94.7,
          0.44,
          6.23,
          67.43,
          74.87,
          85.52,
        ],
      },
      {
        304: [
          "1468",
          0.63,
          4320000,
          89.1,
          "0.644",
          "0.687",
          "0.712",
          "0.694",
          "0.71",
          267,
          42.3,
          2.25,
          0,
          73.79,
          83.51,
          92.93,
        ],
      },
      {
        305: [
          "2022",
          0.099,
          60000,
          89.1,
          "0.091",
          "0.085",
          "0.083",
          "0.085",
          "0.082",
          0.599,
          1.98,
          45.58,
          0,
          70.36,
          80.13,
          89.37,
        ],
      },
      {
        306: [
          "2125",
          5.6,
          5222000,
          89,
          "5.63",
          "3.485",
          "4.468",
          "6.332",
          "4.328",
          2888,
          38.9,
          68.01,
          0,
          80.01,
          87.36,
          94.77,
        ],
      },
      {
        307: [
          "0612",
          3.37,
          2112000,
          89,
          "3.279",
          "3.099",
          "3.297",
          "3.174",
          "3.312",
          730,
          45.4,
          14.63,
          0.12,
          46.45,
          57.34,
          73.1,
        ],
      },
      {
        308: [
          "7552",
          9.31,
          23054800,
          89,
          "10.055",
          "9.145",
          "8.353",
          "7.842",
          "8.449",
          21191,
          0,
          0,
          0,
          39,
          52.67,
          75.37,
        ],
      },
      {
        309: [
          "1308",
          30.05,
          2905140,
          88.9,
          "29.275",
          "28.642",
          "29.829",
          "29.312",
          "29.83",
          8634,
          806,
          19.62,
          1.25,
          73.31,
          91.21,
          97.55,
        ],
      },
      {
        310: [
          "1419",
          1.15,
          0,
          88.9,
          "1.171",
          "1.189",
          "1.117",
          "1.06",
          "1.127",
          0,
          4.365,
          72.09,
          0,
          83.6,
          88.2,
          94.99,
        ],
      },
      {
        311: [
          "1942",
          1.11,
          0,
          88.9,
          "1.096",
          "1.066",
          "0.965",
          "0.956",
          "0.974",
          0,
          5.55,
          75,
          0,
          87.52,
          92.97,
          97.96,
        ],
      },
      {
        312: [
          "1118",
          1.07,
          0,
          88.8,
          "1.055",
          "0.979",
          "0.906",
          "0.889",
          "0.913",
          0,
          6.146,
          17.4,
          0,
          26.65,
          31.92,
          38.49,
        ],
      },
      {
        313: [
          "3860",
          1.47,
          0,
          88.8,
          "1.496",
          "1.59",
          "1.687",
          "1.68",
          "1.673",
          0,
          7.35,
          5.51,
          0,
          84.41,
          90.38,
          95.85,
        ],
      },
      {
        314: [
          "3175",
          5.685,
          2191700,
          88.8,
          "5.444",
          "5.205",
          "5.001",
          "4.808",
          "5.044",
          1246,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        315: [
          "8096",
          0.181,
          3090000,
          88.7,
          "0.2",
          "0.312",
          "0.243",
          "0.208",
          "0.251",
          54.168,
          0.905,
          0.06,
          0,
          66.19,
          74.82,
          86.96,
        ],
      },
      {
        316: [
          "1410",
          0.74,
          550000,
          88.7,
          "0.726",
          "0.679",
          "0.643",
          "0.63",
          "0.643",
          39.8,
          7.451,
          5.49,
          0,
          74.69,
          82.12,
          90.78,
        ],
      },
      {
        317: [
          "3300",
          1.69,
          2830000,
          88.7,
          "1.718",
          "1.965",
          "2.507",
          "2.104",
          "2.537",
          476,
          30.9,
          22.78,
          0,
          57.02,
          74.87,
          85.11,
        ],
      },
      {
        318: [
          "0916",
          16.74,
          21200200,
          88.6,
          "17.026",
          "16.941",
          "15.702",
          "14.513",
          "15.96",
          35311,
          559.1,
          0.14,
          10.77,
          71.63,
          84.65,
          94.01,
        ],
      },
      {
        319: [
          "8441",
          0.81,
          2070000,
          88.6,
          "0.816",
          "0.791",
          "0.752",
          "0.722",
          "0.75",
          168,
          6.48,
          74.73,
          0,
          83.35,
          88.32,
          93.47,
        ],
      },
      {
        320: [
          "3097",
          4.628,
          18000,
          88.5,
          "4.42",
          "4.242",
          "4.082",
          "3.924",
          "4.118",
          8.328,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        321: [
          "1960",
          0.445,
          2555000,
          88.5,
          "0.444",
          "0.441",
          "0.425",
          "0.404",
          "0.422",
          113,
          4.45,
          63.8,
          0,
          82.03,
          88.18,
          93.85,
        ],
      },
      {
        322: [
          "0103",
          0.33,
          610000,
          88.5,
          "0.34",
          "0.337",
          "0.295",
          "0.294",
          "0.297",
          20.485,
          6.266,
          61.64,
          0,
          72.67,
          78.33,
          83.8,
        ],
      },
      {
        323: [
          "8250",
          0.022,
          960000,
          88.4,
          "0.021",
          "0.019",
          "0.018",
          "0.018",
          "0.018",
          2.108,
          1.648,
          14.19,
          0,
          42.63,
          62.16,
          86.96,
        ],
      },
      {
        324: [
          "3623",
          1.1,
          404000,
          88.4,
          "1.001",
          "0.908",
          "0.852",
          "0.891",
          "0.853",
          41.738,
          5.973,
          10.16,
          0,
          59.27,
          70.9,
          84.38,
        ],
      },
      {
        325: [
          "8635",
          0.142,
          28000,
          88.4,
          "0.141",
          "0.137",
          "0.134",
          "0.126",
          "0.135",
          0.392,
          0.568,
          75,
          0,
          81.95,
          84.47,
          89.18,
        ],
      },
      {
        326: [
          "0766",
          0.035,
          0,
          88.3,
          "0.035",
          "0.035",
          "0.035",
          "0.033",
          "0.035",
          0,
          0.806,
          0.05,
          0,
          42.38,
          49.36,
          62.59,
        ],
      },
      {
        327: [
          "1723",
          4.85,
          162000,
          88.3,
          "5.293",
          "4.858",
          "4.398",
          "4.295",
          "4.448",
          76.854,
          19.4,
          52.62,
          0,
          79.07,
          84.5,
          90.57,
        ],
      },
      {
        328: [
          "1931",
          3.32,
          646000,
          88.3,
          "3.263",
          "3.177",
          "3.072",
          "2.98",
          "3.069",
          207,
          44.9,
          79.03,
          0,
          89.6,
          93.68,
          98.72,
        ],
      },
      {
        329: [
          "0688",
          22.15,
          26470900,
          88.2,
          "20.833",
          "18.88",
          "18.162",
          "18.469",
          "18.178",
          58851,
          2424.2,
          48.81,
          3.63,
          72.59,
          86.01,
          95.97,
        ],
      },
      {
        330: [
          "8205",
          0.33,
          0,
          88.2,
          "0.31",
          "0.363",
          "0.295",
          "0.284",
          "0.301",
          0,
          0.436,
          0.06,
          0,
          76.82,
          82.43,
          90.02,
        ],
      },
      {
        331: [
          "1898",
          5.01,
          31883200,
          88.1,
          "4.741",
          "4.501",
          "4.922",
          "4.762",
          "4.911",
          16044,
          205.7,
          0.42,
          11.09,
          71.8,
          80.78,
          91.22,
        ],
      },
      {
        332: [
          "2000",
          0.485,
          8637050,
          88.1,
          "0.462",
          "0.431",
          "0.412",
          "0.409",
          "0.414",
          410,
          11,
          52.71,
          0,
          74.14,
          83.14,
          89.67,
        ],
      },
      {
        333: [
          "1498",
          0.94,
          10058500,
          88.1,
          "0.922",
          "0.811",
          "0.786",
          "0.763",
          "0.791",
          960,
          3.712,
          3.74,
          0,
          65.02,
          72.23,
          84.01,
        ],
      },
      {
        334: [
          "0529",
          2,
          0,
          88,
          "2.08",
          "1.933",
          "1.859",
          "1.751",
          "1.867",
          0,
          5.559,
          74.4,
          0,
          85.06,
          89.26,
          93.69,
        ],
      },
      {
        335: [
          "8611",
          0.112,
          130000,
          88,
          "0.096",
          "0.107",
          "0.092",
          "0.09",
          "0.093",
          1.456,
          0.437,
          70.05,
          0,
          78.88,
          83.51,
          87.64,
        ],
      },
      {
        336: [
          "1059",
          0.53,
          3000,
          88,
          "0.502",
          "0.541",
          "0.488",
          "0.464",
          "0.495",
          0.145,
          1.15,
          0.2,
          0,
          73.69,
          79.63,
          87.02,
        ],
      },
      {
        337: [
          "3903",
          0.52,
          2000,
          87.9,
          "0.526",
          "0.519",
          "0.587",
          "0.531",
          "0.588",
          0.104,
          6.084,
          0.01,
          0,
          68.73,
          80.56,
          90.17,
        ],
      },
      {
        338: [
          "3613",
          12.7,
          834000,
          87.9,
          "13.0",
          "11.222",
          "11.075",
          "11.223",
          "11.077",
          1056,
          106.3,
          71.75,
          8.21,
          86.12,
          90.89,
          95.19,
        ],
      },
      {
        339: [
          "8132",
          0.305,
          40000,
          87.9,
          "0.311",
          "0.378",
          "0.379",
          "0.323",
          "0.386",
          1.21,
          7.727,
          0.63,
          0,
          86.49,
          89.08,
          92.44,
        ],
      },
      {
        340: [
          "0553",
          4.59,
          130000,
          87.8,
          "4.629",
          "3.994",
          "3.714",
          "3.769",
          "3.702",
          59.85,
          11.1,
          0.15,
          19.31,
          35.5,
          49.95,
          75.06,
        ],
      },
      {
        341: [
          "0043",
          1.14,
          0,
          87.8,
          "1.14",
          "1.118",
          "0.987",
          "0.983",
          "0.991",
          0,
          274.4,
          82.04,
          0.01,
          87.27,
          91.31,
          97.53,
        ],
      },
      {
        342: [
          "2028",
          0.26,
          0,
          87.8,
          "0.252",
          "0.249",
          "0.25",
          "0.248",
          "0.251",
          0,
          1.593,
          0.04,
          0,
          82.92,
          87.35,
          92.2,
        ],
      },
      {
        343: [
          "1891",
          0.24,
          0,
          87.7,
          "0.236",
          "0.237",
          "0.211",
          "0.203",
          "0.213",
          0,
          2.4,
          0.06,
          0,
          85.05,
          89.16,
          93.39,
        ],
      },
      {
        344: [
          "1991",
          0.2,
          20000,
          87.7,
          "0.194",
          "0.195",
          "0.165",
          "0.169",
          "0.167",
          0.4,
          2.614,
          2.15,
          0,
          75.79,
          81.64,
          90.12,
        ],
      },
      {
        345: [
          "8131",
          0.058,
          0,
          87.6,
          "0.058",
          "0.058",
          "0.052",
          "0.046",
          "0.053",
          0,
          0.276,
          77.85,
          0,
          86.87,
          89.35,
          93.61,
        ],
      },
      {
        346: [
          "1776",
          14.42,
          10281600,
          87.6,
          "14.658",
          "14.274",
          "13.086",
          "12.619",
          "13.249",
          14890,
          245.3,
          0.1,
          38.03,
          55.42,
          69.15,
          90.24,
        ],
      },
      {
        347: [
          "1057",
          2.09,
          3458000,
          87.6,
          "2.251",
          "2.043",
          "1.959",
          "1.947",
          "1.924",
          735,
          4.531,
          0.15,
          29.07,
          40.73,
          54.57,
          68.25,
        ],
      },
      {
        348: [
          "8360",
          0.45,
          196000,
          87.5,
          "0.453",
          "0.504",
          "0.384",
          "0.371",
          "0.386",
          8.725,
          1.599,
          4.42,
          0,
          59.09,
          69.15,
          83.08,
        ],
      },
      {
        349: [
          "6093",
          4.11,
          8000,
          87.5,
          "4.141",
          "4.186",
          "4.198",
          "3.961",
          "4.19",
          3.294,
          23,
          53.57,
          0,
          91.55,
          95.31,
          98.15,
        ],
      },
      {
        350: [
          "0599",
          0.47,
          0,
          87.5,
          "0.47",
          "0.436",
          "0.426",
          "0.409",
          "0.425",
          0,
          2.823,
          52.66,
          0,
          65.76,
          74.08,
          86.71,
        ],
      },
      {
        351: [
          "1692",
          1.99,
          14000,
          87.4,
          "2.001",
          "1.984",
          "2.074",
          "2.113",
          "2.081",
          2.796,
          7.144,
          83.6,
          0,
          88.01,
          91.14,
          94.88,
        ],
      },
      {
        352: [
          "2012",
          2.85,
          0,
          87.4,
          "2.859",
          "2.902",
          "3.29",
          "3.046",
          "3.219",
          0,
          6.939,
          50.3,
          0,
          67.06,
          77.6,
          90.15,
        ],
      },
      {
        353: [
          "1663",
          0.167,
          1955570,
          87.4,
          "0.154",
          "0.137",
          "0.14",
          "0.143",
          "0.139",
          32.968,
          4.115,
          48.16,
          0,
          69.2,
          78.93,
          92.87,
        ],
      },
      {
        354: [
          "0621",
          0.034,
          28380000,
          87.3,
          "0.033",
          "0.027",
          "0.027",
          "0.028",
          "0.027",
          93.642,
          6.172,
          2.89,
          0,
          32.73,
          43.75,
          60.52,
        ],
      },
      {
        355: [
          "6100",
          19.2,
          66600,
          87.3,
          "18.034",
          "16.04",
          "14.889",
          "17.229",
          "14.579",
          127,
          100.5,
          16.98,
          0.11,
          60.38,
          87.39,
          97.66,
        ],
      },
      {
        356: [
          "0144",
          14.66,
          4545800,
          87.3,
          "14.658",
          "13.385",
          "12.556",
          "12.551",
          "12.699",
          6598,
          554.9,
          0.38,
          2.03,
          83.23,
          89.89,
          94.68,
        ],
      },
      {
        357: [
          "0189",
          10.98,
          12015300,
          87.2,
          "11.33",
          "13.488",
          "15.093",
          "12.901",
          "15.451",
          13265,
          247.6,
          15.58,
          20.5,
          46.36,
          62.06,
          83.35,
        ],
      },
      {
        358: [
          "1071",
          3.01,
          8914000,
          87.2,
          "3.17",
          "2.91",
          "2.73",
          "2.646",
          "2.771",
          2713,
          51.6,
          0.42,
          47.09,
          57.78,
          69.19,
          83.29,
        ],
      },
      {
        359: [
          "6033",
          3.45,
          0,
          87.1,
          "3.425",
          "3.392",
          "3.259",
          "3.113",
          "3.288",
          0,
          13.9,
          10.85,
          0,
          80.23,
          92.03,
          95.5,
        ],
      },
      {
        360: [
          "3836",
          4.83,
          967000,
          87.1,
          "4.832",
          "4.367",
          "4.101",
          "4.017",
          "4.17",
          467,
          75.8,
          8.81,
          8.59,
          59.01,
          74.61,
          89.45,
        ],
      },
      {
        361: [
          "3683",
          1.47,
          2380000,
          87.1,
          "1.465",
          "1.431",
          "1.24",
          "1.181",
          "1.271",
          345,
          14,
          0.32,
          0,
          75.11,
          86.86,
          95.06,
        ],
      },
      {
        362: [
          "7232",
          9.99,
          1000,
          87,
          "9.85",
          "9.288",
          "8.752",
          "-",
          "8.797",
          0.999,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        363: [
          "1949",
          0.3,
          0,
          87,
          "0.296",
          "0.251",
          "0.255",
          "0.263",
          "0.253",
          0,
          1.2,
          70.5,
          0,
          79.62,
          86.08,
          91.09,
        ],
      },
      {
        364: [
          "8247",
          1.8,
          0,
          87,
          "1.744",
          "1.767",
          "1.509",
          "1.492",
          "1.509",
          0,
          1.157,
          53.51,
          0,
          70.85,
          78.84,
          86.63,
        ],
      },
      {
        365: [
          "1658",
          6.3,
          68930200,
          86.9,
          "5.924",
          "5.661",
          "5.452",
          "5.44",
          "5.488",
          43405,
          1250.9,
          0.06,
          4.78,
          39.96,
          56.15,
          83.15,
        ],
      },
      {
        366: [
          "1463",
          3.65,
          160000,
          86.9,
          "3.773",
          "3.841",
          "3.597",
          "3.536",
          "3.604",
          59.004,
          29.2,
          13.5,
          0,
          74.7,
          87.7,
          97.92,
        ],
      },
      {
        367: [
          "7261",
          27.12,
          27900,
          86.9,
          "27.722",
          "28.444",
          "26.048",
          "24.705",
          "26.247",
          75.702,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        368: [
          "8163",
          0.18,
          0,
          86.8,
          "0.184",
          "0.182",
          "0.163",
          "0.168",
          "-",
          0,
          0.873,
          0.05,
          0,
          59.92,
          72.21,
          86.9,
        ],
      },
      {
        369: [
          "0994",
          2.27,
          0,
          86.8,
          "2.27",
          "2.27",
          "2.27",
          "2.26",
          "2.27",
          0,
          17.2,
          1.93,
          0,
          77.28,
          82.17,
          88.34,
        ],
      },
      {
        370: [
          "8226",
          0.38,
          10000,
          86.8,
          "0.381",
          "0.365",
          "0.34",
          "0.371",
          "0.323",
          0.38,
          0.529,
          0.07,
          0,
          40.66,
          54.53,
          72.18,
        ],
      },
      {
        371: [
          "2112",
          0.097,
          2000,
          86.7,
          "0.086",
          "0.075",
          "0.078",
          "0.081",
          "0.079",
          0,
          1.455,
          14.04,
          0,
          80.48,
          89.06,
          92.79,
        ],
      },
      {
        372: [
          "0719",
          4.81,
          781800,
          86.7,
          "4.781",
          "4.334",
          "4.059",
          "4.202",
          "4.045",
          379,
          9.38,
          1,
          36.51,
          47.17,
          56.79,
          73.59,
        ],
      },
      {
        373: [
          "7266",
          20.98,
          37300,
          86.6,
          "21.414",
          "22.02",
          "20.21",
          "19.191",
          "20.358",
          78.183,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        374: [
          "1332",
          1.08,
          971562,
          86.6,
          "1.197",
          "1.328",
          "1.057",
          "1.07",
          "1.046",
          106,
          30.3,
          22.83,
          0,
          54.67,
          69.19,
          86.25,
        ],
      },
      {
        375: [
          "8167",
          0.2,
          792000,
          86.6,
          "0.204",
          "0.161",
          "0.163",
          "0.168",
          "0.163",
          15.932,
          19,
          2.63,
          0,
          68.55,
          76.65,
          86.29,
        ],
      },
      {
        376: [
          "6188",
          3.83,
          0,
          86.5,
          "3.83",
          "3.83",
          "3.83",
          "3.796",
          "3.83",
          0,
          15.1,
          86.19,
          0,
          97.81,
          99.09,
          99.6,
        ],
      },
      {
        377: [
          "1205",
          0.495,
          12230000,
          86.5,
          "0.491",
          "0.493",
          "0.486",
          "0.452",
          "0.492",
          608,
          38.8,
          43.19,
          1.49,
          69.24,
          76.73,
          83.4,
        ],
      },
      {
        378: [
          "0199",
          1.16,
          87000,
          86.5,
          "1.158",
          "1.164",
          "1.042",
          "1.049",
          "1.047",
          10.083,
          11.1,
          2.06,
          0,
          53.04,
          72.93,
          85.57,
        ],
      },
      {
        379: [
          "1572",
          0.245,
          904000,
          86.4,
          "0.257",
          "0.286",
          "0.277",
          "0.246",
          "0.284",
          22.12,
          4.111,
          0.18,
          0,
          74.12,
          80.66,
          88.79,
        ],
      },
      {
        380: [
          "6888",
          0.275,
          58000,
          86.4,
          "0.276",
          "0.29",
          "0.297",
          "0.298",
          "0.293",
          1.564,
          2.967,
          44.62,
          0,
          69.43,
          79.27,
          85.92,
        ],
      },
      {
        381: [
          "0380",
          0.138,
          0,
          86.4,
          "0.13",
          "0.118",
          "0.121",
          "0.122",
          "0.12",
          0,
          1.838,
          0.19,
          0,
          69.7,
          81.63,
          91.51,
        ],
      },
      {
        382: [
          "1915",
          1.85,
          26000,
          86.3,
          "1.944",
          "2.03",
          "1.939",
          "1.809",
          "1.978",
          4.894,
          2.775,
          13.07,
          0,
          41.4,
          59.22,
          80.11,
        ],
      },
      {
        383: [
          "8126",
          0.295,
          248000,
          86.3,
          "0.261",
          "0.259",
          "0.244",
          "0.244",
          "0.246",
          7.284,
          1.405,
          35.77,
          0,
          71.58,
          83.38,
          91.53,
        ],
      },
      {
        384: [
          "0769",
          0.88,
          8524000,
          86.3,
          "0.898",
          "0.844",
          "0.876",
          "0.886",
          "0.879",
          751,
          20.6,
          0.24,
          0,
          50.9,
          59.6,
          71.36,
        ],
      },
      {
        385: [
          "3377",
          1.99,
          3201790,
          86.2,
          "1.923",
          "1.838",
          "1.712",
          "1.714",
          "1.71",
          636,
          151.5,
          0.61,
          6.73,
          70.58,
          80.83,
          91.84,
        ],
      },
      {
        386: [
          "1637",
          0.55,
          68000,
          86.2,
          "0.541",
          "0.565",
          "0.537",
          "0.513",
          "0.539",
          3.616,
          2.2,
          0.02,
          0,
          81.32,
          85.71,
          91.11,
        ],
      },
      {
        387: [
          "1173",
          0.068,
          260000,
          86.1,
          "0.06",
          "0.056",
          "0.057",
          "0.058",
          "0.058",
          1.828,
          1.712,
          60.45,
          0,
          84.95,
          89.94,
          93.4,
        ],
      },
      {
        388: [
          "1901",
          0.99,
          982000,
          86.1,
          "1.034",
          "1.011",
          "0.902",
          "0.886",
          "0.908",
          95.144,
          5.94,
          60.66,
          0,
          70.08,
          77.42,
          88.25,
        ],
      },
      {
        389: [
          "1703",
          0.8,
          330000,
          86.1,
          "0.868",
          "0.879",
          "0.813",
          "0.742",
          "0.818",
          25.135,
          9.2,
          0,
          0,
          76.91,
          86.48,
          93.34,
        ],
      },
      {
        390: [
          "0336",
          14.88,
          6031520,
          86,
          "14.302",
          "17.219",
          "17.056",
          "15.188",
          "17.262",
          9076,
          480.6,
          54.13,
          14.63,
          75.43,
          87.44,
          95.58,
        ],
      },
      {
        391: [
          "3633",
          8.21,
          965000,
          86,
          "8.232",
          "7.719",
          "7.046",
          "6.97",
          "7.09",
          792,
          232.6,
          13.59,
          0.88,
          70.84,
          83.25,
          93.21,
        ],
      },
      {
        392: [
          "1790",
          0.6,
          0,
          86,
          "0.602",
          "0.631",
          "0.592",
          "0.548",
          "0.595",
          0,
          6,
          75,
          0,
          90.83,
          95.24,
          98.95,
        ],
      },
      {
        393: [
          "0244",
          0.45,
          650000,
          85.9,
          "0.48",
          "0.489",
          "0.511",
          "0.471",
          "0.515",
          28.965,
          5.913,
          18.49,
          0,
          83.8,
          87.2,
          91.56,
        ],
      },
      {
        394: [
          "0857",
          3.89,
          158688000,
          85.9,
          "3.625",
          "3.526",
          "3.554",
          "3.41",
          "3.561",
          61809,
          820.7,
          0.95,
          5.08,
          60.97,
          69.53,
          82.36,
        ],
      },
      {
        395: [
          "1088",
          19.7,
          40792300,
          85.9,
          "18.772",
          "17.629",
          "16.953",
          "16.885",
          "16.928",
          80954,
          665.3,
          0.24,
          31.87,
          58.41,
          74.78,
          87.06,
        ],
      },
      {
        396: [
          "8337",
          0.23,
          0,
          85.8,
          "0.23",
          "0.256",
          "0.274",
          "0.363",
          "-",
          0,
          0.425,
          77.69,
          0,
          84.21,
          88.58,
          94.1,
        ],
      },
      {
        397: [
          "1495",
          0.91,
          404000,
          85.8,
          "0.939",
          "1.079",
          "0.862",
          "0.803",
          "0.879",
          36.424,
          8.102,
          4.26,
          0,
          66.81,
          78.05,
          89.82,
        ],
      },
      {
        398: [
          "8115",
          0.65,
          0,
          85.8,
          "0.65",
          "0.639",
          "0.621",
          "0.595",
          "0.622",
          0,
          0.361,
          24.52,
          0,
          68.7,
          88.79,
          94.72,
        ],
      },
      {
        399: [
          "8088",
          0.06,
          0,
          85.7,
          "0.06",
          "0.06",
          "0.058",
          "0.054",
          "0.059",
          0,
          0.333,
          30.96,
          0,
          57.57,
          69.78,
          82.67,
        ],
      },
      {
        400: [
          "0277",
          3.848,
          0,
          85.7,
          "3.848",
          "3.749",
          "3.445",
          "3.454",
          "3.437",
          0,
          10.6,
          96.2,
          0,
          98.9,
          99.47,
          99.86,
        ],
      },
      {
        401: [
          "8383",
          0.16,
          464000,
          85.6,
          "0.154",
          "0.139",
          "0.138",
          "0.142",
          "0.14",
          7.4,
          1.28,
          51.02,
          0,
          74.34,
          82.19,
          89.63,
        ],
      },
      {
        402: [
          "0005",
          53.65,
          40548300,
          85.6,
          "49.985",
          "46.356",
          "44.573",
          "45.283",
          "44.573",
          216000,
          11066.4,
          52.25,
          9.35,
          73.21,
          80.98,
          88.92,
        ],
      },
      {
        403: [
          "1731",
          1.1,
          404000,
          85.6,
          "1.096",
          "1.244",
          "1.328",
          "1.238",
          "1.362",
          45.116,
          12.3,
          75,
          0,
          81.09,
          86.21,
          92.64,
        ],
      },
      {
        404: [
          "8013",
          0.065,
          1340000,
          85.5,
          "0.084",
          "0.075",
          "0.062",
          "0.062",
          "0.063",
          8.662,
          1.04,
          6.29,
          0,
          73.4,
          83.56,
          91.27,
        ],
      },
      {
        405: [
          "3900",
          12.88,
          5107280,
          85.5,
          "12.82",
          "11.975",
          "11.131",
          "10.699",
          "11.355",
          6607,
          322,
          21.16,
          14.94,
          57.75,
          71.59,
          87.09,
        ],
      },
      {
        406: [
          "2600",
          4.86,
          47953900,
          85.5,
          "4.468",
          "4.192",
          "4.803",
          "4.633",
          "4.868",
          23032,
          191.6,
          0.25,
          7,
          50.64,
          61.61,
          78.08,
        ],
      },
      {
        407: [
          "7588",
          5.29,
          519500,
          85.4,
          "5.724",
          "5.473",
          "5.029",
          "4.759",
          "5.08",
          275,
          0,
          0,
          0,
          50.33,
          66.12,
          83.99,
        ],
      },
      {
        408: [
          "1826",
          0.147,
          0,
          85.4,
          "0.147",
          "0.167",
          "0.132",
          "0.128",
          "0.136",
          0,
          1.958,
          62.1,
          0,
          83.38,
          89.28,
          94.9,
        ],
      },
      {
        409: [
          "1360",
          0.158,
          30000,
          85.4,
          "0.16",
          "0.159",
          "0.148",
          "0.141",
          "0.15",
          0.474,
          2.932,
          6.41,
          0,
          72.41,
          81.89,
          90.83,
        ],
      },
      {
        410: [
          "3315",
          2.04,
          2641600,
          85.3,
          "1.894",
          "1.746",
          "1.694",
          "1.75",
          "1.699",
          539,
          16.8,
          18.7,
          0,
          65.64,
          77.82,
          87.03,
        ],
      },
      {
        411: [
          "0580",
          3.77,
          4652000,
          85.3,
          "3.875",
          "4.548",
          "4.005",
          "3.63",
          "4.085",
          1746,
          61.7,
          0.1,
          0,
          38.31,
          47.61,
          63.07,
        ],
      },
      {
        412: [
          "9906",
          2.36,
          404000,
          85.2,
          "2.393",
          "2.315",
          "2.151",
          "2.078",
          "2.163",
          95.904,
          14.1,
          24.63,
          0,
          84.22,
          94.4,
          98.77,
        ],
      },
      {
        413: [
          "1857",
          1.87,
          826000,
          85.2,
          "1.891",
          "1.753",
          "1.667",
          "1.623",
          "1.685",
          154,
          53.4,
          87.74,
          0,
          93.81,
          95.77,
          97.66,
        ],
      },
      {
        414: [
          "1127",
          0.95,
          0,
          85.2,
          "0.903",
          "0.827",
          "0.823",
          "0.838",
          "0.823",
          0,
          7.315,
          51.06,
          0,
          69.16,
          77.71,
          83.01,
        ],
      },
      {
        415: [
          "8042",
          0.056,
          0,
          85.1,
          "0.058",
          "0.067",
          "0.055",
          "0.052",
          "0.056",
          0,
          0.448,
          75.01,
          0,
          81.37,
          85.81,
          92.34,
        ],
      },
      {
        416: [
          "1816",
          2.27,
          44097100,
          85.1,
          "2.289",
          "2.208",
          "2.001",
          "1.952",
          "2.026",
          9961,
          253.4,
          0.24,
          31.97,
          54.1,
          66.49,
          81.41,
        ],
      },
      {
        417: [
          "0532",
          1.01,
          0,
          85.1,
          "1.012",
          "1.022",
          "1.012",
          "0.981",
          "1.032",
          0,
          7.37,
          58.8,
          0,
          71.21,
          76.89,
          84.85,
        ],
      },
      {
        418: [
          "0257",
          6.02,
          24556700,
          85,
          "6.251",
          "5.654",
          "5.29",
          "5.193",
          "5.361",
          14715,
          369.8,
          39.93,
          12.71,
          66.24,
          77.35,
          89.05,
        ],
      },
      {
        419: [
          "0592",
          0.84,
          2558000,
          85,
          "0.831",
          "1.006",
          "0.864",
          "0.781",
          "0.886",
          212,
          20.7,
          71.22,
          0,
          78.49,
          82.53,
          87.57,
        ],
      },
      {
        420: [
          "8613",
          0.103,
          11680000,
          85,
          "0.12",
          "0.11",
          "0.091",
          "0.089",
          "0.092",
          129,
          1.03,
          5.02,
          0,
          67.42,
          77.29,
          86.59,
        ],
      },
      {
        421: [
          "0854",
          3.78,
          0,
          84.9,
          "3.809",
          "4.045",
          "3.949",
          "3.839",
          "3.922",
          0,
          3.235,
          59.04,
          0,
          87.03,
          90.53,
          95.51,
        ],
      },
      {
        422: [
          "1540",
          0.62,
          0,
          84.9,
          "0.646",
          "0.652",
          "0.593",
          "0.579",
          "0.599",
          0,
          3.092,
          74.31,
          0,
          83.4,
          88.26,
          93.47,
        ],
      },
      {
        423: [
          "1271",
          7.96,
          15000,
          84.9,
          "7.925",
          "7.935",
          "7.87",
          "7.781",
          "7.873",
          11.915,
          112.9,
          87.52,
          0,
          91.16,
          93.91,
          97.28,
        ],
      },
      {
        424: [
          "1440",
          0.69,
          0,
          84.8,
          "0.641",
          "0.598",
          "0.737",
          "0.699",
          "0.737",
          0,
          8.694,
          75,
          0,
          90.96,
          95.25,
          97.5,
        ],
      },
      {
        425: [
          "1456",
          4.46,
          1142000,
          84.8,
          "4.379",
          "4.069",
          "3.811",
          "3.804",
          "3.826",
          507,
          19.7,
          0.03,
          31.81,
          44.99,
          62.49,
          79.76,
        ],
      },
      {
        426: [
          "1420",
          0.155,
          48000,
          84.7,
          "0.154",
          "0.192",
          "0.177",
          "0.159",
          "0.179",
          0.744,
          1.606,
          0,
          0,
          65.74,
          72.07,
          79.59,
        ],
      },
      {
        427: [
          "0750",
          1.62,
          4170000,
          84.7,
          "1.703",
          "1.818",
          "1.784",
          "1.617",
          "1.844",
          677,
          40.8,
          7.33,
          0,
          67.32,
          77.37,
          86.89,
        ],
      },
      {
        428: [
          "2722",
          0.67,
          302000,
          84.7,
          "0.678",
          "0.684",
          "0.632",
          "0.612",
          "0.643",
          19.956,
          7.371,
          0.85,
          0,
          29.29,
          41.88,
          60.47,
        ],
      },
      {
        429: [
          "0033",
          1.36,
          376000,
          84.6,
          "1.363",
          "1.304",
          "1.314",
          "1.54",
          "1.317",
          50.148,
          7.306,
          53.09,
          0,
          81.97,
          86.21,
          92.64,
        ],
      },
      {
        430: [
          "2669",
          7.93,
          4277000,
          84.6,
          "8.011",
          "7.688",
          "7.355",
          "7.448",
          "7.375",
          3427,
          260.6,
          54.14,
          15.66,
          73.88,
          86.92,
          96.34,
        ],
      },
      {
        431: [
          "0417",
          1.13,
          0,
          84.6,
          "1.158",
          "1.113",
          "1.119",
          "1.066",
          "1.118",
          0,
          2.816,
          1.31,
          0,
          83.98,
          87.04,
          92.22,
        ],
      },
      {
        432: [
          "0406",
          1.47,
          0,
          84.5,
          "1.425",
          "1.431",
          "1.415",
          "1.366",
          "1.42",
          0,
          6.439,
          36.58,
          0,
          72.58,
          76.92,
          83.62,
        ],
      },
      {
        433: [
          "0807",
          1.45,
          1649000,
          84.5,
          "1.528",
          "1.405",
          "1.306",
          "1.295",
          "1.314",
          239,
          37.3,
          46.48,
          0,
          83.63,
          90.22,
          93.83,
        ],
      },
      {
        434: [
          "3678",
          1,
          0,
          84.5,
          "1.022",
          "1.033",
          "0.949",
          "0.93",
          "0.954",
          0,
          2.497,
          6.11,
          0,
          54.67,
          66.95,
          81.39,
        ],
      },
      {
        435: [
          "1935",
          3.04,
          722000,
          84.4,
          "2.939",
          "2.589",
          "2.302",
          "2.745",
          "2.264",
          217,
          48.6,
          74.82,
          0,
          95.99,
          98.36,
          99.37,
        ],
      },
      {
        436: [
          "1218",
          4.46,
          0,
          84.4,
          "4.58",
          "4.562",
          "4.196",
          "4.208",
          "4.181",
          0,
          3.345,
          64.37,
          0,
          75.71,
          80.21,
          85.1,
        ],
      },
      {
        437: [
          "0034",
          10.34,
          128411,
          84.4,
          "10.244",
          "10.239",
          "9.501",
          "9.415",
          "9.522",
          131,
          121.6,
          36.76,
          0,
          81.01,
          86.07,
          92.45,
        ],
      },
      {
        438: [
          "0282",
          0.29,
          0,
          84.3,
          "0.29",
          "0.29",
          "0.29",
          "0.268",
          "0.29",
          0,
          7.645,
          67.53,
          0,
          76.72,
          82.84,
          89.55,
        ],
      },
      {
        439: [
          "3833",
          1.31,
          2125000,
          84.3,
          "1.227",
          "1.246",
          "1.465",
          "1.419",
          "1.483",
          286,
          9.943,
          2.46,
          0,
          39.74,
          49.18,
          65.14,
        ],
      },
      {
        440: [
          "1730",
          1.73,
          0,
          84.2,
          "1.776",
          "1.877",
          "1.908",
          "1.772",
          "1.899",
          0,
          7.075,
          76.53,
          0,
          93.55,
          96.08,
          98.02,
        ],
      },
      {
        441: [
          "1188",
          0.04,
          0,
          84.2,
          "0.04",
          "0.04",
          "0.04",
          "0.04",
          "0.04",
          0,
          8.141,
          16.75,
          0.49,
          39.66,
          48.85,
          62.06,
        ],
      },
      {
        442: [
          "0547",
          0.75,
          12761000,
          84.2,
          "0.815",
          "0.74",
          "0.657",
          "0.683",
          "0.66",
          991,
          32.4,
          17.25,
          2.16,
          47.38,
          58.63,
          78.26,
        ],
      },
      {
        443: [
          "2252",
          53.2,
          110928,
          84.1,
          "54.55",
          "56.939",
          "-",
          "-",
          "-",
          595,
          506.4,
          95.63,
          0,
          98.23,
          98.8,
          99.48,
        ],
      },
      {
        444: [
          "8039",
          0.455,
          568000,
          84.1,
          "0.439",
          "0.46",
          "0.442",
          "0.45",
          "0.455",
          26.792,
          2.243,
          18.37,
          0,
          88.12,
          92.63,
          95.85,
        ],
      },
      {
        445: [
          "0540",
          0.21,
          0,
          84.1,
          "0.207",
          "0.22",
          "0.208",
          "0.201",
          "0.208",
          0,
          1.26,
          0.01,
          0,
          78.39,
          83.96,
          91.14,
        ],
      },
      {
        446: [
          "8659",
          0.2,
          15000,
          84,
          "0.205",
          "0.203",
          "0.184",
          "0.184",
          "0.184",
          0.301,
          1.2,
          66,
          0,
          78.7,
          86.07,
          92.29,
        ],
      },
      {
        447: [
          "8021",
          0.048,
          0,
          84,
          "0.049",
          "0.047",
          "0.049",
          "0.048",
          "0.048",
          0,
          6.896,
          11.02,
          0,
          34.6,
          47.91,
          69.99,
        ],
      },
      {
        448: [
          "8285",
          0.096,
          0,
          84,
          "0.099",
          "0.1",
          "0.095",
          "0.093",
          "0.096",
          0,
          0.538,
          75.02,
          0,
          81.73,
          86.4,
          92.6,
        ],
      },
      {
        449: [
          "0804",
          0.051,
          6620000,
          83.9,
          "0.055",
          "0.045",
          "0.054",
          "0.05",
          "0.055",
          33.814,
          2.301,
          46.54,
          0,
          59,
          67.5,
          79.34,
        ],
      },
      {
        450: [
          "2668",
          0.248,
          0,
          83.9,
          "0.23",
          "0.224",
          "0.224",
          "0.226",
          "0.224",
          0,
          9.672,
          2.19,
          0,
          87.57,
          91.2,
          94.63,
        ],
      },
      {
        451: [
          "0322",
          16.46,
          4648090,
          83.9,
          "16.24",
          "15.672",
          "14.917",
          "14.779",
          "14.889",
          7623,
          926.8,
          0.08,
          1.44,
          90.83,
          95.46,
          98.54,
        ],
      },
      {
        452: [
          "1119",
          5.24,
          3755600,
          83.8,
          "5.653",
          "6.057",
          "5.407",
          "5.02",
          "5.475",
          1992,
          72.7,
          1.81,
          8.05,
          33.37,
          47.36,
          69.49,
        ],
      },
      {
        453: [
          "1321",
          1.05,
          20000,
          83.8,
          "1.005",
          "0.966",
          "0.902",
          "0.912",
          "0.897",
          2.01,
          21.1,
          9.37,
          0,
          79.75,
          93.47,
          98.53,
        ],
      },
      {
        454: [
          "1958",
          3.29,
          3904900,
          83.7,
          "3.259",
          "3.277",
          "3.004",
          "2.929",
          "3.014",
          1274,
          82.9,
          30.58,
          12.6,
          61.17,
          78.46,
          90.59,
        ],
      },
      {
        455: [
          "0048",
          0.78,
          10000,
          83.7,
          "0.838",
          "0.894",
          "0.86",
          "0.822",
          "0.864",
          0.78,
          1.304,
          8.63,
          0,
          30.81,
          46.57,
          70.96,
        ],
      },
      {
        456: [
          "2310",
          0.117,
          644000,
          83.7,
          "0.101",
          "0.084",
          "0.094",
          "0.097",
          "0.096",
          6.849,
          1.279,
          0.01,
          0,
          75.27,
          82.76,
          91.23,
        ],
      },
      {
        457: [
          "2166",
          1.5,
          132000,
          83.6,
          "1.511",
          "1.545",
          "1.536",
          "1.477",
          "1.527",
          20.01,
          7.33,
          48.67,
          0,
          84.28,
          91.26,
          95.35,
        ],
      },
      {
        458: [
          "8201",
          0.106,
          80000,
          83.6,
          "0.1",
          "0.103",
          "0.098",
          "0.101",
          "0.097",
          0.86,
          0.572,
          1.11,
          0,
          83.36,
          86.13,
          89.97,
        ],
      },
      {
        459: [
          "3668",
          17.34,
          1100,
          83.6,
          "17.386",
          "17.055",
          "15.734",
          "15.039",
          "15.907",
          1.903,
          228.9,
          94.09,
          0,
          97.48,
          99.1,
          99.99,
        ],
      },
      {
        460: [
          "1065",
          3.87,
          134000,
          83.5,
          "3.983",
          "3.869",
          "3.5",
          "3.394",
          "3.536",
          52.15,
          13.1,
          0.6,
          48.32,
          54.71,
          65.58,
          77.14,
        ],
      },
      {
        461: [
          "1148",
          0.69,
          1224000,
          83.5,
          "0.719",
          "0.865",
          "0.777",
          "0.677",
          "0.793",
          86.385,
          8.847,
          62.41,
          0,
          72.83,
          77.92,
          85.8,
        ],
      },
      {
        462: [
          "8271",
          0.11,
          228000,
          83.5,
          "0.117",
          "0.111",
          "0.102",
          "0.1",
          "0.103",
          2.517,
          1.659,
          43.16,
          0,
          62.03,
          72.36,
          83.53,
        ],
      },
      {
        463: [
          "0182",
          0.75,
          25467900,
          83.4,
          "0.779",
          "0.79",
          "0.739",
          "0.691",
          "0.753",
          1917,
          67.3,
          0.04,
          0,
          38.17,
          51.01,
          69.27,
        ],
      },
      {
        464: [
          "1713",
          1.85,
          6000,
          83.4,
          "1.769",
          "1.738",
          "1.752",
          "1.733",
          "1.755",
          1.102,
          4.973,
          0.01,
          0,
          83.55,
          86.11,
          90.84,
        ],
      },
      {
        465: [
          "1162",
          0.201,
          0,
          83.4,
          "0.204",
          "0.21",
          "0.204",
          "0.197",
          "0.204",
          0,
          1.206,
          64.59,
          0,
          76,
          80.2,
          87.22,
        ],
      },
      {
        466: [
          "0137",
          1.23,
          42000,
          83.3,
          "1.228",
          "1.295",
          "1.352",
          "1.274",
          "1.359",
          5.166,
          6.522,
          27.32,
          0,
          83.55,
          91.77,
          94.33,
        ],
      },
      {
        467: [
          "0639",
          2.4,
          8490490,
          83.3,
          "2.351",
          "2.159",
          "2.243",
          "2.195",
          "2.244",
          2019,
          121.2,
          6.33,
          3.95,
          54.11,
          69.24,
          85.19,
        ],
      },
      {
        468: [
          "2698",
          2.64,
          396500,
          83.2,
          "2.618",
          "2.645",
          "2.691",
          "2.531",
          "2.713",
          105,
          10.9,
          0.75,
          0,
          43.63,
          52.65,
          67.03,
        ],
      },
      {
        469: [
          "1878",
          1.26,
          113900,
          83.2,
          "1.294",
          "1.244",
          "1.432",
          "1.458",
          "1.428",
          14.572,
          3.454,
          4,
          0,
          59.92,
          68.09,
          78.97,
        ],
      },
      {
        470: [
          "2331",
          79.5,
          9985050,
          83.2,
          "79.475",
          "87.231",
          "88.962",
          "82.609",
          "89.087",
          79045,
          2080.1,
          0.02,
          9.35,
          73.01,
          84.2,
          95.93,
        ],
      },
      {
        471: [
          "2688",
          144,
          2045390,
          83.1,
          "142.06",
          "141.764",
          "144.775",
          "141.877",
          "143.95",
          29383,
          1627.3,
          0.01,
          0.79,
          92.41,
          95.27,
          98.81,
        ],
      },
      {
        472: [
          "1180",
          1.1,
          36000,
          83.1,
          "1.121",
          "1.004",
          "0.981",
          "1.02",
          "0.974",
          3.976,
          11.5,
          59.54,
          0,
          78.08,
          87.31,
          94.66,
        ],
      },
      {
        473: [
          "3015",
          1851,
          0,
          83.1,
          "1808.8",
          "1769.37",
          "1725.087",
          "1674.142",
          "1734.583",
          0,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        474: [
          "0626",
          2.67,
          298000,
          83,
          "2.629",
          "2.479",
          "2.473",
          "2.447",
          "2.474",
          79.642,
          29.3,
          68.34,
          0,
          79.76,
          83.82,
          88.28,
        ],
      },
      {
        475: [
          "1600",
          8.52,
          6202230,
          83,
          "8.345",
          "7.738",
          "7.67",
          "7.729",
          "7.668",
          5298,
          85.5,
          0.3,
          0.64,
          57.35,
          75.46,
          90.5,
        ],
      },
      {
        476: [
          "0771",
          1.52,
          858000,
          83,
          "1.464",
          "1.348",
          "1.374",
          "1.39",
          "1.393",
          127,
          12.6,
          55.06,
          0,
          73.82,
          79.73,
          86.73,
        ],
      },
      {
        477: [
          "6038",
          0.186,
          340000,
          82.9,
          "0.189",
          "0.195",
          "0.18",
          "0.175",
          "0.181",
          6.073,
          1.86,
          75.02,
          0,
          82.15,
          86.08,
          91.49,
        ],
      },
      {
        478: [
          "1681",
          4.31,
          331000,
          82.9,
          "4.515",
          "3.85",
          "4.156",
          "4.317",
          "4.056",
          142,
          35.5,
          0.15,
          0.87,
          60.84,
          75.61,
          90.9,
        ],
      },
      {
        479: [
          "0894",
          1.09,
          620000,
          82.8,
          "1.108",
          "1.133",
          "1.304",
          "1.193",
          "1.319",
          67.124,
          5.183,
          29.27,
          0,
          65.39,
          72.29,
          81.35,
        ],
      },
      {
        480: [
          "6885",
          4.2,
          481000,
          82.8,
          "4.2",
          "3.923",
          "4.075",
          "3.969",
          "4.144",
          204,
          22.4,
          31.39,
          0,
          78.73,
          83.85,
          91.03,
        ],
      },
      {
        481: [
          "1170",
          0.98,
          0,
          82.8,
          "0.972",
          "0.932",
          "0.91",
          "0.913",
          "0.909",
          0,
          6.679,
          47.68,
          0,
          63.37,
          73.87,
          85.03,
        ],
      },
      {
        482: [
          "1800",
          4.49,
          27944200,
          82.7,
          "4.376",
          "4.213",
          "4.066",
          "3.996",
          "4.101",
          12559,
          198.3,
          0.96,
          31.4,
          43.57,
          56.87,
          70.04,
        ],
      },
      {
        483: [
          "1103",
          0.015,
          1480000,
          82.7,
          "0.015",
          "0.012",
          "0.014",
          "0.014",
          "0.014",
          2.178,
          0.93,
          7.72,
          0,
          40.64,
          52.91,
          69.38,
        ],
      },
      {
        484: [
          "6819",
          7.96,
          875500,
          82.7,
          "7.759",
          "7.257",
          "7.808",
          "7.64",
          "7.755",
          703,
          36.1,
          46.72,
          0,
          75,
          85.61,
          96.37,
        ],
      },
      {
        485: [
          "1721",
          0.415,
          0,
          82.6,
          "0.427",
          "0.38",
          "0.37",
          "0.365",
          "-",
          0,
          4.15,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        486: [
          "0390",
          4.5,
          37086400,
          82.6,
          "4.27",
          "3.97",
          "3.94",
          "3.978",
          "3.954",
          16699,
          189.3,
          0.81,
          13.47,
          52.37,
          67.85,
          79.8,
        ],
      },
      {
        487: [
          "1552",
          0.175,
          20000,
          82.6,
          "0.176",
          "0.175",
          "0.193",
          "0.179",
          "0.2",
          0.35,
          1.4,
          75.24,
          0,
          82.9,
          87.77,
          92.07,
        ],
      },
      {
        488: [
          "8277",
          0.29,
          0,
          82.5,
          "0.259",
          "0.219",
          "0.284",
          "0.289",
          "0.281",
          0,
          0.761,
          0.01,
          0,
          85.26,
          88.79,
          92.81,
        ],
      },
      {
        489: [
          "1210",
          0.176,
          0,
          82.5,
          "0.176",
          "0.175",
          "0.163",
          "0.163",
          "0.162",
          0,
          1.778,
          7.49,
          0,
          46.01,
          68.07,
          92.58,
        ],
      },
      {
        490: [
          "0077",
          0.73,
          18000,
          82.5,
          "0.737",
          "0.727",
          "0.694",
          "0.681",
          "0.694",
          1.314,
          1.985,
          70.86,
          0,
          81.39,
          88.02,
          92.41,
        ],
      },
      {
        491: [
          "2836",
          36.5,
          7134,
          82.4,
          "35.762",
          "34.973",
          "34.133",
          "33.128",
          "34.315",
          26.049,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        492: [
          "1399",
          0.29,
          616000,
          82.4,
          "0.29",
          "0.291",
          "0.271",
          "0.268",
          "0.274",
          17.916,
          3.161,
          48.25,
          0,
          59.56,
          69.33,
          84.21,
        ],
      },
      {
        493: [
          "1029",
          0.26,
          10014000,
          82.3,
          "0.257",
          "0.237",
          "0.258",
          "0.266",
          "0.256",
          263,
          18.4,
          31.09,
          0,
          51.01,
          59.04,
          71.19,
        ],
      },
      {
        494: [
          "1461",
          0.68,
          931000,
          82.3,
          "0.646",
          "0.611",
          "0.585",
          "0.599",
          "0.592",
          62.992,
          1.884,
          0.22,
          0,
          33.72,
          47.84,
          66.78,
        ],
      },
      {
        495: [
          "6805",
          1,
          78000,
          82.3,
          "0.959",
          "0.882",
          "0.897",
          "0.871",
          "-",
          7.8,
          11.2,
          75,
          0,
          95.23,
          97.91,
          99.47,
        ],
      },
      {
        496: [
          "6049",
          58,
          650369,
          82.2,
          "58.97",
          "53.826",
          "49.74",
          "51.565",
          "49.382",
          3809,
          88.9,
          0.02,
          63.21,
          77.24,
          83.95,
          92.8,
        ],
      },
      {
        497: [
          "2663",
          0.365,
          30000,
          82.2,
          "0.369",
          "0.359",
          "0.366",
          "0.353",
          "0.367",
          1.095,
          2.19,
          72.12,
          0,
          79.87,
          83.58,
          89.12,
        ],
      },
      {
        498: [
          "0150",
          1.09,
          95000,
          82.2,
          "1.087",
          "1.138",
          "1.097",
          "1.084",
          "1.095",
          10.377,
          22.3,
          0.32,
          0,
          85.3,
          91.59,
          95.69,
        ],
      },
      {
        499: [
          "1618",
          2.29,
          27930000,
          82.1,
          "2.138",
          "2.057",
          "2.225",
          "2.147",
          "2.252",
          6427,
          65.7,
          1.01,
          23.97,
          44.5,
          56.04,
          74.44,
        ],
      },
      {
        500: [
          "1183",
          3.69,
          850000,
          82.1,
          "3.649",
          "3.525",
          "3.718",
          "3.558",
          "3.708",
          312,
          65.7,
          50.6,
          0,
          72.05,
          78.28,
          86.94,
        ],
      },
      {
        501: [
          "9836",
          4.686,
          1000,
          82.1,
          "4.582",
          "4.484",
          "4.384",
          "4.258",
          "4.404",
          3.65,
          0,
          96.6,
          0,
          99.73,
          99.93,
          100,
        ],
      },
      {
        502: [
          "0674",
          0.142,
          0,
          82,
          "0.155",
          "0.16",
          "0.158",
          "0.147",
          "0.162",
          0,
          3.278,
          56.73,
          0,
          85.37,
          88.31,
          92.61,
        ],
      },
      {
        503: [
          "1241",
          0.128,
          0,
          82,
          "0.127",
          "0.128",
          "0.129",
          "0.121",
          "0.131",
          0,
          0.832,
          0.11,
          0,
          84.78,
          87.01,
          91.91,
        ],
      },
      {
        504: [
          "8512",
          0.26,
          0,
          82,
          "0.256",
          "0.284",
          "0.31",
          "0.285",
          "0.315",
          0,
          2.86,
          75,
          0,
          82.84,
          88.51,
          95.11,
        ],
      },
      {
        505: [
          "9908",
          11.54,
          11000,
          81.9,
          "11.786",
          "11.51",
          "10.598",
          "10.514",
          "10.639",
          12.742,
          4.367,
          0.01,
          0,
          92.24,
          95.22,
          97.28,
        ],
      },
      {
        506: [
          "8070",
          0.21,
          0,
          81.9,
          "0.209",
          "0.214",
          "0.207",
          "0.202",
          "0.208",
          0,
          0.42,
          63.04,
          0,
          78.61,
          89.07,
          95.35,
        ],
      },
      {
        507: [
          "6898",
          0.475,
          12000,
          81.8,
          "0.451",
          "0.39",
          "0.43",
          "0.435",
          "-",
          0.521,
          4.283,
          49.08,
          0,
          76.34,
          83.9,
          91.59,
        ],
      },
      {
        508: [
          "1782",
          0.475,
          0,
          81.8,
          "0.416",
          "0.392",
          "0.424",
          "0.435",
          "0.416",
          0,
          2.413,
          73.22,
          0,
          81.66,
          85.89,
          92.52,
        ],
      },
      {
        509: [
          "8095",
          0.56,
          1103000,
          81.8,
          "0.541",
          "0.538",
          "0.506",
          "0.512",
          "0.505",
          60.66,
          4.561,
          0.05,
          0,
          51.57,
          65.54,
          79.48,
        ],
      },
      {
        510: [
          "1222",
          0.074,
          33152200,
          81.7,
          "0.074",
          "0.073",
          "0.069",
          "0.069",
          "0.069",
          244,
          12.3,
          1.17,
          0,
          55.6,
          60.63,
          71.55,
        ],
      },
      {
        511: [
          "0811",
          6.04,
          278000,
          81.7,
          "6.032",
          "5.85",
          "5.562",
          "5.541",
          "5.59",
          168,
          26.6,
          1.55,
          23.4,
          53.03,
          64.02,
          76.47,
        ],
      },
      {
        512: [
          "8106",
          0.19,
          0,
          81.7,
          "0.19",
          "0.19",
          "0.186",
          "0.185",
          "0.187",
          0,
          0.498,
          0,
          0,
          75.73,
          82.85,
          91.09,
        ],
      },
      {
        513: [
          "1430",
          2.42,
          0,
          81.6,
          "2.42",
          "2.42",
          "2.279",
          "2.206",
          "2.309",
          0,
          21.8,
          0.01,
          0,
          62.67,
          78.25,
          95.37,
        ],
      },
      {
        514: [
          "2349",
          0.17,
          16000,
          81.6,
          "0.181",
          "0.196",
          "0.192",
          "0.174",
          "0.195",
          0.24,
          5.318,
          41.57,
          0,
          78.73,
          83.93,
          92.33,
        ],
      },
      {
        515: [
          "0267",
          8.53,
          17219500,
          81.6,
          "8.151",
          "7.576",
          "8.195",
          "8.209",
          "8.186",
          14574,
          2481.3,
          66.25,
          0.56,
          82.14,
          90.01,
          97,
        ],
      },
      {
        516: [
          "8286",
          0.164,
          0,
          81.5,
          "0.164",
          "0.165",
          "0.155",
          "0.153",
          "0.159",
          0,
          0.18,
          24.7,
          0,
          68.53,
          87.4,
          93.41,
        ],
      },
      {
        517: [
          "3036",
          474.7,
          0,
          81.5,
          "470.45",
          "453.802",
          "441.955",
          "438.36",
          "442.403",
          0,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        518: [
          "1558",
          6.6,
          4573600,
          81.5,
          "6.088",
          "5.558",
          "5.705",
          "6.222",
          "5.637",
          2974,
          43.1,
          0,
          19.74,
          54.37,
          69.78,
          83.98,
        ],
      },
      {
        519: [
          "2178",
          0.115,
          404000,
          81.4,
          "0.108",
          "0.11",
          "0.105",
          "0.101",
          "0.105",
          4.356,
          1.986,
          24.57,
          0,
          62.46,
          75.56,
          88.9,
        ],
      },
      {
        520: [
          "6182",
          0.245,
          0,
          81.4,
          "0.248",
          "0.268",
          "0.255",
          "0.236",
          "0.26",
          0,
          1.96,
          75.01,
          0,
          87.86,
          91.23,
          95.11,
        ],
      },
      {
        521: [
          "1760",
          4.96,
          805000,
          81.3,
          "5.193",
          "5.195",
          "4.841",
          "4.792",
          "4.842",
          401,
          53.7,
          0,
          0,
          79.47,
          87.65,
          92.57,
        ],
      },
      {
        522: [
          "0932",
          0.217,
          5624000,
          81.3,
          "0.257",
          "0.3",
          "0.255",
          "0.226",
          "0.266",
          122,
          6.744,
          16.8,
          0,
          51.65,
          62.32,
          73.53,
        ],
      },
      {
        523: [
          "1640",
          0.405,
          64000,
          81.3,
          "0.396",
          "0.423",
          "0.523",
          "0.525",
          "0.508",
          2.793,
          1.62,
          22.88,
          0,
          78.59,
          86.05,
          93.68,
        ],
      },
      {
        524: [
          "0425",
          37.35,
          1412600,
          81.2,
          "34.78",
          "34.627",
          "32.845",
          "32.54",
          "32.775",
          5279,
          433.7,
          0,
          1.9,
          70.61,
          76.7,
          83.32,
        ],
      },
      {
        525: [
          "3074",
          213.8,
          12600,
          81.2,
          "211.01",
          "203.547",
          "199.691",
          "197.727",
          "199.917",
          267,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        526: [
          "3020",
          1061,
          300,
          81.2,
          "1067.05",
          "1052.8",
          "1017.498",
          "995.365",
          "1022.183",
          31.83,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        527: [
          "2834",
          298.8,
          1700,
          81.1,
          "302.06",
          "304.85",
          "289.538",
          "281.037",
          "290.9",
          50.826,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        528: [
          "3086",
          32.1,
          504005,
          81.1,
          "32.398",
          "32.705",
          "31.087",
          "30.172",
          "31.226",
          1621,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        529: [
          "2845",
          168.45,
          470445,
          81.1,
          "172.06",
          "194.474",
          "182.532",
          "167.607",
          "183.907",
          7969,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        530: [
          "2388",
          28.65,
          12967300,
          81,
          "27.475",
          "25.194",
          "25.038",
          "25.767",
          "24.928",
          36896,
          3029.1,
          67.77,
          0.34,
          83.87,
          90.05,
          94.77,
        ],
      },
      {
        531: [
          "9845",
          21.62,
          164485,
          81,
          "22.076",
          "24.943",
          "23.437",
          "21.534",
          "23.596",
          2789,
          0,
          60.16,
          0,
          98.1,
          99.36,
          99.91,
        ],
      },
      {
        532: [
          "9834",
          38.4,
          0,
          81,
          "38.756",
          "39.091",
          "37.178",
          "36.103",
          "37.356",
          0,
          0,
          90.49,
          0,
          97.14,
          98.29,
          99.63,
        ],
      },
      {
        533: [
          "0423",
          1.27,
          8000,
          80.9,
          "1.222",
          "1.251",
          "1.213",
          "1.181",
          "1.214",
          0.98,
          5.481,
          68.19,
          0,
          74.15,
          78.57,
          84.73,
        ],
      },
      {
        534: [
          "8479",
          0.65,
          104000,
          80.9,
          "0.681",
          "0.668",
          "0.63",
          "0.614",
          "0.635",
          6.832,
          6.045,
          32.36,
          0,
          84.46,
          88.15,
          92.04,
        ],
      },
      {
        535: [
          "6886",
          13.14,
          3529220,
          80.8,
          "12.926",
          "12.194",
          "11.669",
          "11.607",
          "11.741",
          4633,
          225.8,
          0.2,
          36.79,
          64.65,
          76.77,
          87.59,
        ],
      },
      {
        536: [
          "0255",
          3.78,
          86000,
          80.8,
          "3.781",
          "3.748",
          "3.65",
          "3.628",
          "3.667",
          32.69,
          23.8,
          63.58,
          0,
          71.97,
          77.08,
          81.52,
        ],
      },
      {
        537: [
          "2111",
          2.25,
          0,
          80.8,
          "2.13",
          "2.18",
          "2.393",
          "2.358",
          "2.368",
          0,
          23.3,
          7.22,
          0.09,
          78.84,
          90.82,
          95.98,
        ],
      },
      {
        538: [
          "8309",
          0.098,
          0,
          80.7,
          "0.102",
          "0.114",
          "0.125",
          "0.116",
          "0.125",
          0,
          0.588,
          60.51,
          0,
          70.07,
          77.31,
          87.76,
        ],
      },
      {
        539: [
          "0400",
          2.52,
          2802000,
          80.7,
          "2.647",
          "2.928",
          "2.735",
          "2.58",
          "2.762",
          715,
          35.6,
          0.01,
          2.28,
          57.49,
          68.6,
          85.12,
        ],
      },
      {
        540: [
          "9086",
          4.116,
          60000,
          80.7,
          "4.152",
          "4.194",
          "3.994",
          "3.878",
          "4.012",
          192,
          0,
          99.82,
          0,
          99.98,
          100,
          100,
        ],
      },
      {
        541: [
          "0883",
          9.24,
          131756000,
          80.6,
          "8.535",
          "8.059",
          "8.251",
          "8.286",
          "8.241",
          122000,
          4125.4,
          64.59,
          12.94,
          80.15,
          87.7,
          93.42,
        ],
      },
      {
        542: [
          "1842",
          0.305,
          5856000,
          80.6,
          "0.307",
          "0.353",
          "0.29",
          "0.285",
          "0.29",
          180,
          3.05,
          66.48,
          0,
          85.48,
          89.3,
          93.98,
        ],
      },
      {
        543: [
          "2278",
          6.09,
          51000,
          80.6,
          "6.124",
          "5.745",
          "5.558",
          "5.658",
          "5.523",
          31.059,
          18.2,
          78.92,
          0,
          93.44,
          99.33,
          99.82,
        ],
      },
      {
        544: [
          "8392",
          0.119,
          2410000,
          80.5,
          "0.117",
          "0.115",
          "0.108",
          "0.109",
          "0.108",
          28.939,
          1.19,
          0.01,
          0,
          78.94,
          83.14,
          88.79,
        ],
      },
      {
        545: [
          "1597",
          1.99,
          0,
          80.5,
          "1.977",
          "1.998",
          "1.848",
          "1.837",
          "1.879",
          0,
          4.975,
          80.57,
          0,
          89.02,
          93.38,
          96.53,
        ],
      },
      {
        546: [
          "3134",
          10.15,
          767000,
          80.5,
          "10.583",
          "11.509",
          "10.882",
          "-",
          "11.063",
          780,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        547: [
          "8331",
          0.44,
          0,
          80.4,
          "0.453",
          "0.442",
          "0.494",
          "0.541",
          "-",
          0,
          0.7,
          51.15,
          0,
          82.1,
          87.98,
          93.05,
        ],
      },
      {
        548: [
          "0408",
          4.27,
          52000,
          80.4,
          "4.276",
          "4.303",
          "4.593",
          "4.499",
          "4.6",
          22.214,
          24.2,
          54.9,
          0,
          75.45,
          80.92,
          89.56,
        ],
      },
      {
        549: [
          "2238",
          8.32,
          35982000,
          80.3,
          "7.767",
          "7.866",
          "7.477",
          "7.304",
          "7.541",
          29868,
          257.8,
          0.11,
          28.7,
          57.25,
          74.21,
          85.55,
        ],
      },
      {
        550: [
          "9074",
          27.28,
          6750,
          80.3,
          "27.066",
          "26.108",
          "25.674",
          "25.425",
          "25.694",
          141,
          0,
          15.5,
          0,
          82.62,
          92.45,
          99.78,
        ],
      },
      {
        551: [
          "1211",
          260.4,
          5426980,
          80.3,
          "255.76",
          "283.668",
          "260.99",
          "238.333",
          "262.647",
          140000,
          2859.1,
          20.55,
          5.11,
          61.24,
          77.3,
          87.09,
        ],
      },
      {
        552: [
          "1581",
          0.125,
          200000,
          80.2,
          "0.13",
          "0.147",
          "0.134",
          "0.126",
          "0.137",
          2.44,
          1.297,
          51.11,
          0,
          73,
          77.97,
          85.22,
        ],
      },
      {
        553: [
          "6030",
          20.8,
          8707620,
          80.2,
          "20.289",
          "19.79",
          "19.399",
          "19.398",
          "19.447",
          18197,
          473.8,
          0.05,
          10.79,
          68.2,
          81.53,
          91.41,
        ],
      },
      {
        554: [
          "6869",
          12.42,
          1102000,
          80.2,
          "12.722",
          "12.779",
          "11.79",
          "11.281",
          "11.895",
          1381,
          43.6,
          51.19,
          9.51,
          63.91,
          72.03,
          83.7,
        ],
      },
      {
        555: [
          "1206",
          0.79,
          392000,
          80.1,
          "0.802",
          "0.808",
          "0.75",
          "0.761",
          "0.745",
          31.026,
          6.179,
          28.3,
          0,
          45.78,
          55.85,
          76.68,
        ],
      },
      {
        556: [
          "2382",
          217.6,
          5992320,
          80.1,
          "231.12",
          "231.844",
          "224.457",
          "214.669",
          "225.73",
          131000,
          2386.7,
          0.06,
          18.3,
          61.03,
          81.86,
          96.25,
        ],
      },
      {
        557: [
          "0050",
          6.45,
          1725750,
          80.1,
          "5.962",
          "5.575",
          "5.653",
          "5.743",
          "5.64",
          1141,
          22.9,
          52.21,
          0,
          66,
          73.32,
          80.18,
        ],
      },
      {
        558: [
          "8447",
          0.07,
          1510000,
          80,
          "0.076",
          "0.082",
          "0.089",
          "0.079",
          "0.091",
          10.375,
          0.7,
          51.01,
          0,
          79.56,
          85.97,
          92.76,
        ],
      },
      {
        559: [
          "3816",
          0.28,
          0,
          80,
          "0.279",
          "0.28",
          "0.286",
          "0.276",
          "0.289",
          0,
          1.68,
          0.01,
          0,
          82.04,
          85.88,
          91.28,
        ],
      },
      {
        560: [
          "3328",
          5.14,
          27212000,
          79.9,
          "4.895",
          "4.754",
          "4.705",
          "4.792",
          "4.693",
          13887,
          1799.6,
          57.21,
          1.87,
          67.83,
          71.52,
          75.83,
        ],
      },
      {
        561: [
          "0866",
          0.188,
          6000,
          79.9,
          "0.18",
          "0.183",
          "0.192",
          "0.177",
          "0.196",
          0.109,
          4.688,
          69.83,
          0,
          76.3,
          80.68,
          86.6,
        ],
      },
      {
        562: [
          "8291",
          0.135,
          0,
          79.9,
          "0.132",
          "0.095",
          "0.086",
          "0.165",
          "0.085",
          0,
          0.432,
          0.01,
          0,
          35.52,
          45.17,
          66.02,
        ],
      },
      {
        563: [
          "0675",
          0.9,
          34000,
          79.8,
          "0.879",
          "0.882",
          "0.879",
          "0.873",
          "0.883",
          3.05,
          2.403,
          51.79,
          0,
          74.07,
          81.4,
          87.42,
        ],
      },
      {
        564: [
          "0276",
          1.1,
          253425,
          79.8,
          "1.153",
          "1.066",
          "0.964",
          "0.994",
          "0.958",
          28.084,
          2.069,
          3.15,
          0,
          46.25,
          54.67,
          68.24,
        ],
      },
      {
        565: [
          "3908",
          21.75,
          12408600,
          79.8,
          "21.445",
          "20.462",
          "20.145",
          "20.079",
          "20.161",
          27223,
          414,
          0.51,
          20.04,
          49.25,
          69.3,
          91.66,
        ],
      },
      {
        566: [
          "2233",
          1.42,
          33782300,
          79.7,
          "1.381",
          "1.35",
          "1.298",
          "1.305",
          "1.311",
          4826,
          77.2,
          16.95,
          7.91,
          44.2,
          61.45,
          84.66,
        ],
      },
      {
        567: [
          "8452",
          0.47,
          0,
          79.7,
          "0.48",
          "0.475",
          "0.484",
          "0.47",
          "0.478",
          0,
          0.422,
          0.04,
          0,
          50.68,
          66.58,
          89.47,
        ],
      },
      {
        568: [
          "6036",
          0.28,
          635000,
          79.7,
          "0.299",
          "0.288",
          "0.296",
          "0.273",
          "0.298",
          17.902,
          2.827,
          75.37,
          0,
          81.91,
          85.75,
          90.46,
        ],
      },
      {
        569: [
          "1023",
          0.53,
          15000,
          79.6,
          "0.545",
          "0.525",
          "0.56",
          "0.547",
          "0.552",
          0.792,
          5.117,
          0.04,
          0,
          85.71,
          89.32,
          93.65,
        ],
      },
      {
        570: [
          "1109",
          35.85,
          14648800,
          79.6,
          "34.91",
          "33.533",
          "31.572",
          "32.898",
          "31.57",
          53162,
          2556.4,
          55.34,
          2.54,
          82.61,
          91.29,
          98.6,
        ],
      },
      {
        571: [
          "2038",
          1.31,
          30199600,
          79.6,
          "1.376",
          "1.254",
          "1.195",
          "1.176",
          "1.194",
          3958,
          104.9,
          0.25,
          2.02,
          76.5,
          84.77,
          92.27,
        ],
      },
      {
        572: [
          "8340",
          0.059,
          70000,
          79.5,
          "0.058",
          "0.048",
          "0.046",
          "0.048",
          "0.045",
          0.405,
          0.378,
          11.51,
          0,
          46.21,
          57.79,
          70.56,
        ],
      },
      {
        573: [
          "0018",
          0.68,
          26000,
          79.5,
          "0.68",
          "0.69",
          "0.712",
          "0.666",
          "0.717",
          1.762,
          16.3,
          80.32,
          0,
          85.54,
          88.87,
          93.11,
        ],
      },
      {
        574: [
          "1330",
          4.14,
          2349000,
          79.4,
          "4.096",
          "4.045",
          "3.925",
          "3.855",
          "3.964",
          972,
          16.7,
          6.15,
          44.17,
          54.73,
          65.31,
          78.06,
        ],
      },
      {
        575: [
          "0934",
          3.26,
          6670000,
          79.4,
          "3.129",
          "2.972",
          "2.952",
          "2.969",
          "2.945",
          2144,
          81,
          60.36,
          5.32,
          75.08,
          82.7,
          92.08,
        ],
      },
      {
        576: [
          "2218",
          6.98,
          121600,
          79.4,
          "6.437",
          "6.098",
          "6.083",
          "6.226",
          "6.04",
          84.962,
          6.754,
          0.12,
          2.6,
          77.26,
          83.99,
          91.64,
        ],
      },
      {
        577: [
          "1953",
          0.22,
          1475000,
          79.3,
          "0.224",
          "0.239",
          "0.218",
          "0.205",
          "0.22",
          31.57,
          2.772,
          75,
          0,
          81.86,
          85.74,
          91.51,
        ],
      },
      {
        578: [
          "1113",
          51.55,
          5758440,
          79.3,
          "49.945",
          "47.451",
          "49.274",
          "49.21",
          "48.993",
          29501,
          1878.2,
          49.47,
          0.25,
          82.22,
          89.53,
          94.62,
        ],
      },
      {
        579: [
          "2809",
          140.45,
          95518,
          79.3,
          "144.325",
          "154.249",
          "148.451",
          "137.981",
          "150.333",
          1340,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        580: [
          "1163",
          0.93,
          265000,
          79.2,
          "0.885",
          "0.789",
          "0.822",
          "0.847",
          "0.818",
          25.355,
          5.789,
          14.46,
          0,
          83.53,
          91.62,
          97.7,
        ],
      },
      {
        581: [
          "0331",
          5.92,
          109000,
          79.2,
          "5.788",
          "6.159",
          "6.245",
          "6.174",
          "6.217",
          64.336,
          26.6,
          79.56,
          0,
          85.62,
          88.97,
          92.52,
        ],
      },
      {
        582: [
          "1543",
          1.88,
          0,
          79.2,
          "1.887",
          "1.814",
          "1.865",
          "1.835",
          "1.859",
          0,
          10.4,
          0.01,
          0,
          59.5,
          71.47,
          90.91,
        ],
      },
      {
        583: [
          "1596",
          4.5,
          138000,
          79.1,
          "4.798",
          "4.532",
          "4.339",
          "4.272",
          "4.377",
          60.322,
          10.1,
          9.05,
          0,
          37.48,
          55.06,
          84.03,
        ],
      },
      {
        584: [
          "0636",
          21.5,
          3468980,
          79.1,
          "19.43",
          "20.49",
          "21.548",
          "21.983",
          "21.565",
          7199,
          388.5,
          26.05,
          0.16,
          84.31,
          91.51,
          96.73,
        ],
      },
      {
        585: [
          "0135",
          7.9,
          12179000,
          79.1,
          "7.428",
          "7.26",
          "7.319",
          "7.566",
          "7.335",
          9538,
          684,
          0.33,
          0.52,
          84.32,
          92.6,
          95.8,
        ],
      },
      {
        586: [
          "0094",
          0.131,
          0,
          79,
          "0.128",
          "0.125",
          "0.132",
          "0.135",
          "0.132",
          0,
          2.43,
          72.4,
          0,
          80.57,
          85.1,
          89.93,
        ],
      },
      {
        587: [
          "1326",
          0.128,
          16000,
          79,
          "0.13",
          "0.13",
          "0.133",
          "0.134",
          "0.132",
          0.206,
          3.322,
          0,
          0,
          80.68,
          87.22,
          92.91,
        ],
      },
      {
        588: [
          "1156",
          0.45,
          0,
          78.9,
          "0.45",
          "0.45",
          "0.45",
          "0.45",
          "0.45",
          0,
          2.654,
          18.79,
          0,
          60.49,
          72.93,
          88.56,
        ],
      },
      {
        589: [
          "3087",
          358.5,
          4570,
          78.9,
          "365.65",
          "361.332",
          "346.613",
          "338.927",
          "347.797",
          165,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        590: [
          "1656",
          4,
          0,
          78.9,
          "3.986",
          "3.929",
          "3.93",
          "3.909",
          "3.916",
          0,
          17.5,
          68.36,
          0,
          99.65,
          99.74,
          99.85,
        ],
      },
      {
        591: [
          "2110",
          0.155,
          16790000,
          78.8,
          "0.16",
          "0.156",
          "0.142",
          "0.14",
          "0.142",
          258,
          3.447,
          0,
          0,
          74.01,
          80.94,
          89.79,
        ],
      },
      {
        592: [
          "8169",
          0.083,
          30000,
          78.8,
          "0.08",
          "0.088",
          "0.078",
          "0.077",
          "0.078",
          0.249,
          0.539,
          57.77,
          0,
          73.17,
          80.92,
          86.82,
        ],
      },
      {
        593: [
          "1433",
          0.06,
          0,
          78.8,
          "0.062",
          "0.064",
          "0.059",
          "0.059",
          "0.06",
          0,
          1.2,
          74.5,
          0,
          82.56,
          86.9,
          91.62,
        ],
      },
      {
        594: [
          "9809",
          17.97,
          26126,
          78.7,
          "18.503",
          "19.769",
          "19.058",
          "17.726",
          "19.289",
          366,
          0,
          70.98,
          0,
          96.01,
          99.07,
          99.76,
        ],
      },
      {
        595: [
          "1068",
          0.93,
          4669000,
          78.7,
          "0.928",
          "0.847",
          "0.887",
          "0.903",
          "0.873",
          436,
          16.9,
          0.14,
          0.83,
          44.39,
          52.1,
          64.58,
        ],
      },
      {
        596: [
          "1335",
          0.145,
          0,
          78.7,
          "0.145",
          "0.14",
          "0.146",
          "0.143",
          "0.146",
          0,
          3.529,
          48.13,
          0,
          75.31,
          83.07,
          91.95,
        ],
      },
      {
        597: [
          "0874",
          22.25,
          1689000,
          78.6,
          "22.75",
          "20.022",
          "20.748",
          "20.731",
          "20.669",
          3733,
          48.9,
          0.09,
          31.91,
          53.45,
          71.99,
          82.53,
        ],
      },
      {
        598: [
          "0618",
          0.162,
          0,
          78.6,
          "0.162",
          "0.162",
          "0.162",
          "0.166",
          "0.162",
          0,
          10.3,
          2.35,
          0,
          62.7,
          84.17,
          92.39,
        ],
      },
      {
        599: [
          "1082",
          1.65,
          456000,
          78.6,
          "1.58",
          "1.573",
          "1.587",
          "1.536",
          "1.615",
          72.776,
          9.841,
          0.03,
          0,
          58.79,
          71.17,
          85.15,
        ],
      },
      {
        600: [
          "8350",
          0.22,
          48000,
          78.5,
          "0.22",
          "0.219",
          "0.218",
          "0.221",
          "0.216",
          1.056,
          1.76,
          74.05,
          0,
          88.06,
          91.16,
          94.84,
        ],
      },
      {
        601: [
          "0829",
          0.415,
          170000,
          78.5,
          "0.415",
          "0.418",
          "0.417",
          "0.426",
          "0.417",
          7.006,
          13.4,
          67.86,
          3.98,
          76.4,
          82.78,
          90.48,
        ],
      },
      {
        602: [
          "0475",
          0.96,
          2000,
          78.4,
          "0.945",
          "0.964",
          "0.948",
          "0.892",
          "0.958",
          0.194,
          3.721,
          71.68,
          0,
          82.58,
          87.9,
          93.15,
        ],
      },
      {
        603: [
          "7500",
          5.22,
          58280300,
          78.4,
          "5.616",
          "5.476",
          "5.056",
          "4.877",
          "5.095",
          30308,
          0,
          -10.45,
          0,
          41.02,
          56,
          73.2,
        ],
      },
      {
        604: [
          "0420",
          1.33,
          528000,
          78.4,
          "1.348",
          "1.345",
          "1.345",
          "1.309",
          "1.343",
          70.22,
          16.2,
          34.84,
          0,
          55.63,
          66.99,
          81.26,
        ],
      },
      {
        605: [
          "0201",
          0.125,
          184000,
          78.3,
          "0.123",
          "0.121",
          "0.119",
          "0.117",
          "0.119",
          2.157,
          11.1,
          68.97,
          0,
          84.54,
          88.35,
          90.52,
        ],
      },
      {
        606: [
          "0808",
          3.09,
          665813,
          78.3,
          "3.085",
          "3.06",
          "3.048",
          "2.958",
          "3.057",
          204,
          46.4,
          26.24,
          0,
          57.81,
          68.39,
          79.76,
        ],
      },
      {
        607: [
          "2099",
          20.8,
          311600,
          78.3,
          "20.21",
          "20.749",
          "21.633",
          "22.249",
          "21.66",
          652,
          82.4,
          50.32,
          1.54,
          61.7,
          70.27,
          82.74,
        ],
      },
      {
        608: [
          "6865",
          34.65,
          4042300,
          78.2,
          "36.155",
          "35.642",
          "35.171",
          "32.005",
          "35.777",
          14065,
          155.9,
          0.01,
          22.37,
          51.25,
          73.12,
          93.21,
        ],
      },
      {
        609: [
          "8041",
          0.39,
          460000,
          78.2,
          "0.374",
          "0.415",
          "0.35",
          "0.335",
          "0.359",
          16.91,
          3.08,
          0.15,
          0,
          73.01,
          79.94,
          87.87,
        ],
      },
      {
        610: [
          "3983",
          2.45,
          6427650,
          78.2,
          "2.303",
          "2.27",
          "2.496",
          "2.445",
          "2.496",
          1595,
          43.3,
          0.6,
          0,
          76.46,
          82.57,
          88.48,
        ],
      },
      {
        611: [
          "1186",
          5.59,
          22544800,
          78.1,
          "5.334",
          "5.052",
          "5.091",
          "5.093",
          "5.109",
          12594,
          116,
          0.76,
          28.81,
          44.19,
          55.92,
          69.08,
        ],
      },
      {
        612: [
          "0301",
          1.31,
          0,
          78.1,
          "1.297",
          "1.282",
          "1.315",
          "1.297",
          "1.316",
          0,
          5.6,
          0,
          0,
          88.75,
          95.65,
          98.47,
        ],
      },
      {
        613: [
          "7362",
          5.905,
          0,
          78.1,
          "6.136",
          "5.986",
          "5.727",
          "5.564",
          "5.758",
          0,
          0,
          0,
          0,
          99.92,
          99.97,
          100,
        ],
      },
      {
        614: [
          "1038",
          48.8,
          587048,
          78,
          "48.95",
          "47.143",
          "46.308",
          "46.654",
          "46.198",
          2858,
          1229.5,
          76.47,
          0.17,
          89.68,
          93.38,
          96.1,
        ],
      },
      {
        615: [
          "1610",
          3.27,
          46640000,
          78,
          "3.38",
          "2.953",
          "2.732",
          "3.012",
          "2.732",
          15368,
          127.5,
          27.65,
          26.51,
          63.67,
          71.75,
          82.9,
        ],
      },
      {
        616: [
          "8031",
          0.28,
          70000,
          77.9,
          "0.306",
          "0.295",
          "0.275",
          "0.277",
          "0.276",
          1.923,
          0.784,
          1.82,
          0,
          84.7,
          89.08,
          94.84,
        ],
      },
      {
        617: [
          "2225",
          2.3,
          9695000,
          77.9,
          "2.442",
          "2.524",
          "2.659",
          "2.52",
          "2.691",
          2151,
          28.2,
          21.47,
          0,
          91.27,
          96.06,
          97.82,
        ],
      },
      {
        618: [
          "1005",
          2.6,
          0,
          77.9,
          "2.6",
          "2.621",
          "2.68",
          "2.514",
          "2.666",
          0,
          19.6,
          70.44,
          0,
          90.75,
          95.97,
          98.47,
        ],
      },
      {
        619: [
          "0107",
          2,
          1496400,
          77.8,
          "2.014",
          "1.873",
          "1.821",
          "1.838",
          "1.821",
          299,
          17.9,
          0.86,
          26.83,
          43.96,
          57.73,
          78.54,
        ],
      },
      {
        620: [
          "3165",
          16.32,
          4000,
          77.8,
          "16.636",
          "16.581",
          "16.224",
          "15.861",
          "16.269",
          6.531,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        621: [
          "1711",
          0.26,
          0,
          77.8,
          "0.271",
          "0.269",
          "0.302",
          "0.273",
          "0.302",
          0,
          2.08,
          75.01,
          0,
          80.55,
          82.76,
          86.39,
        ],
      },
      {
        622: [
          "1732",
          0.16,
          3860000,
          77.7,
          "0.15",
          "0.174",
          "0.188",
          "0.171",
          "0.194",
          64.142,
          1.6,
          56.25,
          0,
          72.17,
          77.88,
          85.36,
        ],
      },
      {
        623: [
          "8646",
          0.315,
          15000,
          77.7,
          "0.327",
          "0.348",
          "0.317",
          "0.318",
          "0.313",
          0.477,
          0.945,
          75,
          0,
          80.44,
          83.29,
          88.89,
        ],
      },
      {
        624: [
          "1977",
          1.79,
          76000,
          77.7,
          "1.787",
          "1.841",
          "1.897",
          "1.808",
          "1.892",
          13.434,
          25,
          63.79,
          0,
          72.75,
          80.35,
          87.33,
        ],
      },
      {
        625: [
          "6136",
          0.82,
          209000,
          77.6,
          "0.791",
          "0.754",
          "0.763",
          "0.746",
          "0.775",
          16.724,
          17.5,
          1.41,
          0,
          74.41,
          78.7,
          86.84,
        ],
      },
      {
        626: [
          "1940",
          3.82,
          0,
          77.6,
          "3.82",
          "3.82",
          "3.82",
          "3.82",
          "3.82",
          0,
          45.8,
          82.36,
          0,
          93.99,
          96.93,
          98.82,
        ],
      },
      {
        627: [
          "0906",
          4.43,
          1991000,
          77.6,
          "4.323",
          "4.044",
          "4.293",
          "4.335",
          "4.279",
          888,
          49.3,
          17.72,
          0.58,
          61.54,
          78.75,
          91.65,
        ],
      },
      {
        628: [
          "1621",
          0.13,
          56000,
          77.5,
          "0.132",
          "0.135",
          "0.141",
          "0.138",
          "0.141",
          0.723,
          1.3,
          72.03,
          0,
          79.79,
          82.98,
          88.78,
        ],
      },
      {
        629: [
          "8043",
          0.193,
          15000,
          77.5,
          "0.202",
          "0.222",
          "0.202",
          "0.188",
          "0.204",
          0.283,
          0.772,
          75,
          0,
          82.2,
          87.69,
          93.98,
        ],
      },
      {
        630: [
          "0108",
          1.07,
          1552000,
          77.4,
          "1.045",
          "1.042",
          "1.024",
          "1.03",
          "1.026",
          164,
          34.2,
          0.42,
          0,
          89.51,
          95.56,
          99.11,
        ],
      },
      {
        631: [
          "1337",
          2.19,
          9353480,
          77.4,
          "2.296",
          "2.466",
          "2.079",
          "2.224",
          "2.065",
          2047,
          191.8,
          0.17,
          0,
          56.15,
          68.69,
          85.25,
        ],
      },
      {
        632: [
          "1821",
          26.9,
          1580310,
          77.4,
          "26.405",
          "26.136",
          "25.182",
          "25.152",
          "25.222",
          4235,
          820,
          0.01,
          0.41,
          80.32,
          95.09,
          99.61,
        ],
      },
      {
        633: [
          "0844",
          0.42,
          0,
          77.3,
          "0.401",
          "0.401",
          "0.389",
          "0.4",
          "0.39",
          0,
          2.076,
          5.71,
          0,
          67.91,
          75.03,
          87.1,
        ],
      },
      {
        634: [
          "0038",
          4.21,
          3142000,
          77.3,
          "4.128",
          "3.882",
          "4.165",
          "4.093",
          "4.118",
          1326,
          16.5,
          0.97,
          33.33,
          41.68,
          53.25,
          65.54,
        ],
      },
      {
        635: [
          "0436",
          0.31,
          1140000,
          77.3,
          "0.306",
          "0.323",
          "0.289",
          "0.29",
          "0.292",
          34.41,
          9.411,
          51.07,
          0,
          78.02,
          86.69,
          94.17,
        ],
      },
      {
        636: [
          "2226",
          3.07,
          0,
          77.2,
          "3.07",
          "3.07",
          "3.07",
          "3.069",
          "3.07",
          0,
          17.7,
          0.1,
          0,
          75.35,
          79.62,
          86.08,
        ],
      },
      {
        637: [
          "2212",
          0.033,
          3340000,
          77.2,
          "0.034",
          "0.041",
          "0.032",
          "0.031",
          "0.033",
          10.892,
          1.448,
          0.31,
          0,
          76.72,
          83.88,
          88.68,
        ],
      },
      {
        638: [
          "2682",
          0.153,
          56000,
          77.2,
          "0.156",
          "0.16",
          "0.167",
          "0.161",
          "0.169",
          0.856,
          1.53,
          66.31,
          0,
          76.54,
          82.17,
          89.38,
        ],
      },
      {
        639: [
          "8367",
          0.23,
          4580000,
          77.1,
          "0.419",
          "0.563",
          "0.784",
          "0.609",
          "0.805",
          123,
          2.208,
          41.79,
          0,
          67.88,
          80.5,
          90.11,
        ],
      },
      {
        640: [
          "0826",
          4.42,
          5544000,
          77.1,
          "4.57",
          "4.576",
          "4.412",
          "4.294",
          "4.451",
          2376,
          123.5,
          1.18,
          14.55,
          69.26,
          83.36,
          93.65,
        ],
      },
      {
        641: [
          "2156",
          4.18,
          371000,
          77,
          "4.347",
          "4.158",
          "4.364",
          "4.349",
          "4.35",
          156,
          55.8,
          22.09,
          1.39,
          68.26,
          79.83,
          90.17,
        ],
      },
      {
        642: [
          "0974",
          0.68,
          0,
          77,
          "0.744",
          "0.714",
          "0.661",
          "0.66",
          "0.66",
          0,
          1.975,
          39.02,
          0,
          96.29,
          97.67,
          98.73,
        ],
      },
      {
        643: [
          "2189",
          0.66,
          100000,
          77,
          "0.659",
          "0.655",
          "0.648",
          "0.637",
          "0.647",
          6.6,
          6.6,
          63.55,
          0,
          76.71,
          84.17,
          93.74,
        ],
      },
      {
        644: [
          "1050",
          1.56,
          96000,
          76.9,
          "1.548",
          "1.587",
          "1.619",
          "1.629",
          "1.602",
          14.904,
          31.4,
          69.62,
          0,
          78.1,
          83.36,
          90.89,
        ],
      },
      {
        645: [
          "2348",
          1.64,
          62000,
          76.9,
          "1.669",
          "1.659",
          "1.693",
          "1.67",
          "1.69",
          10.193,
          24.5,
          46.84,
          0,
          66.55,
          80.18,
          92.25,
        ],
      },
      {
        646: [
          "8200",
          1.44,
          12000,
          76.9,
          "1.467",
          "1.461",
          "1.598",
          "1.605",
          "1.574",
          1.704,
          1.081,
          0.12,
          0,
          36.18,
          50.47,
          73.67,
        ],
      },
      {
        647: [
          "1761",
          0.94,
          334000,
          76.8,
          "0.936",
          "0.82",
          "0.848",
          "0.984",
          "0.823",
          31.283,
          15.6,
          31.52,
          0.18,
          72.98,
          83.83,
          91.79,
        ],
      },
      {
        648: [
          "0820",
          18.1,
          58500,
          76.8,
          "18.254",
          "18.336",
          "17.161",
          "16.981",
          "17.209",
          106,
          0,
          7.08,
          0,
          51.87,
          66,
          79.57,
        ],
      },
      {
        649: [
          "2307",
          0.44,
          364000,
          76.8,
          "0.422",
          "0.417",
          "0.404",
          "0.404",
          "0.4",
          14.066,
          3.828,
          48.56,
          0,
          65.98,
          73.05,
          83.11,
        ],
      },
      {
        650: [
          "0006",
          48.8,
          1544220,
          76.7,
          "48.835",
          "47.913",
          "47.919",
          "47.799",
          "47.795",
          7523,
          1041.5,
          42.51,
          0.22,
          74.43,
          82.39,
          89.82,
        ],
      },
      {
        651: [
          "3988",
          3.02,
          263038000,
          76.7,
          "2.93",
          "2.792",
          "2.764",
          "2.833",
          "2.765",
          79147,
          2525.3,
          2.13,
          5.06,
          47.51,
          59.19,
          71.59,
        ],
      },
      {
        652: [
          "1150",
          0.28,
          130000,
          76.7,
          "0.276",
          "0.28",
          "0.3",
          "0.285",
          "0.303",
          3.62,
          1.993,
          0.02,
          0,
          44.72,
          56.71,
          76.94,
        ],
      },
      {
        653: [
          "0258",
          2.07,
          662000,
          76.6,
          "2.072",
          "2.088",
          "1.994",
          "1.991",
          "2.0",
          134,
          40.8,
          75.13,
          0,
          83.91,
          91.44,
          95.41,
        ],
      },
      {
        654: [
          "2096",
          9.11,
          897000,
          76.6,
          "8.98",
          "8.905",
          "9.202",
          "9.069",
          "8.983",
          825,
          240.5,
          68.16,
          3.2,
          83.36,
          89.43,
          95.55,
        ],
      },
      {
        655: [
          "6690",
          32.4,
          10924500,
          76.5,
          "32.9",
          "31.019",
          "29.148",
          "29.942",
          "29.337",
          35414,
          913.4,
          22.25,
          7.6,
          69.69,
          82.21,
          93.83,
        ],
      },
      {
        656: [
          "2355",
          4.18,
          72000,
          76.5,
          "4.028",
          "3.789",
          "3.953",
          "3.965",
          "3.931",
          29.986,
          8.773,
          1.61,
          0,
          31.79,
          44.79,
          67.62,
        ],
      },
      {
        657: [
          "8188",
          0.172,
          12000,
          76.5,
          "0.168",
          "0.179",
          "0.174",
          "0.162",
          "0.172",
          0.206,
          0.839,
          57.09,
          0,
          70.24,
          75.84,
          83.49,
        ],
      },
      {
        658: [
          "2138",
          9.64,
          2392540,
          76.4,
          "10.235",
          "11.727",
          "11.916",
          "11.452",
          "11.796",
          2348,
          113.5,
          0.14,
          0,
          73.79,
          81.98,
          91.46,
        ],
      },
      {
        659: [
          "2362",
          1.35,
          28729000,
          76.4,
          "1.268",
          "1.173",
          "1.197",
          "1.211",
          "1.214",
          3941,
          170.2,
          44.35,
          0.43,
          69.07,
          85.62,
          95.74,
        ],
      },
      {
        660: [
          "0297",
          1.2,
          6662000,
          76.4,
          "1.209",
          "1.162",
          "1.299",
          "1.24",
          "1.305",
          803,
          84.2,
          71.14,
          4.16,
          79.92,
          85.78,
          91.14,
        ],
      },
      {
        661: [
          "0579",
          2.34,
          6854000,
          76.3,
          "2.443",
          "2.269",
          "2.176",
          "2.061",
          "2.222",
          1604,
          66.2,
          3.68,
          4.86,
          45.38,
          59.84,
          78.96,
        ],
      },
      {
        662: [
          "8507",
          0.198,
          44000,
          76.3,
          "0.208",
          "0.194",
          "0.189",
          "0.189",
          "0.19",
          0.848,
          0.792,
          70.01,
          0,
          76.11,
          79.41,
          86.24,
        ],
      },
      {
        663: [
          "3005",
          23.88,
          1200,
          76.3,
          "24.172",
          "24.098",
          "23.48",
          "22.924",
          "23.589",
          2.876,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        664: [
          "0563",
          0.76,
          350400,
          76.2,
          "0.756",
          "0.729",
          "0.696",
          "0.72",
          "0.695",
          26.567,
          36.5,
          24.08,
          1.69,
          48.71,
          64.93,
          76.02,
        ],
      },
      {
        665: [
          "0086",
          4.16,
          166862,
          76.2,
          "4.151",
          "4.076",
          "4.091",
          "4.092",
          "4.076",
          69.203,
          82,
          32.45,
          1.25,
          73.86,
          86.01,
          95.08,
        ],
      },
      {
        666: [
          "0396",
          0.141,
          140000,
          76.2,
          "0.146",
          "0.198",
          "0.161",
          "0.153",
          "0.167",
          1.898,
          1.139,
          65.96,
          0,
          76.59,
          84.99,
          93.82,
        ],
      },
      {
        667: [
          "0280",
          0.405,
          324000,
          76.1,
          "0.401",
          "0.439",
          "0.453",
          "0.422",
          "0.452",
          13.264,
          3.692,
          5.61,
          0,
          71.11,
          78.11,
          83.7,
        ],
      },
      {
        668: [
          "0379",
          0.15,
          50000,
          76.1,
          "0.151",
          "0.13",
          "0.131",
          "0.138",
          "0.132",
          0.75,
          2.531,
          24.77,
          0,
          57.53,
          74.39,
          87.54,
        ],
      },
      {
        669: [
          "8151",
          0.11,
          0,
          76,
          "0.117",
          "0.135",
          "0.131",
          "0.119",
          "0.134",
          0,
          0.462,
          49.62,
          0,
          78.48,
          83.79,
          90.95,
        ],
      },
      {
        670: [
          "2369",
          0.385,
          13508000,
          76,
          "0.367",
          "0.394",
          "0.35",
          "0.369",
          "0.352",
          520,
          46.6,
          6.69,
          0.17,
          31.17,
          45.27,
          72.59,
        ],
      },
      {
        671: [
          "3948",
          6.1,
          188800,
          76,
          "5.989",
          "5.853",
          "5.799",
          "5.643",
          "5.834",
          114,
          19.8,
          0.01,
          0,
          65.57,
          73.37,
          88.95,
        ],
      },
      {
        672: [
          "0723",
          0.026,
          1680000,
          75.9,
          "0.028",
          "0.02",
          "0.024",
          "0.026",
          "0.024",
          4.536,
          2.368,
          46.25,
          0,
          61.12,
          70.69,
          83.9,
        ],
      },
      {
        673: [
          "0061",
          0.138,
          0,
          75.9,
          "0.143",
          "0.127",
          "0.129",
          "0.134",
          "0.129",
          0,
          0.726,
          0.7,
          0,
          48.34,
          61.57,
          78.76,
        ],
      },
      {
        674: [
          "3329",
          1.75,
          386400,
          75.9,
          "1.755",
          "1.681",
          "1.742",
          "1.748",
          "1.742",
          67.874,
          47.8,
          74.01,
          0.13,
          85.4,
          89.88,
          94.62,
        ],
      },
      {
        675: [
          "3778",
          0.485,
          0,
          75.8,
          "0.482",
          "0.482",
          "0.481",
          "0.477",
          "0.481",
          0,
          6.074,
          0.21,
          0,
          77.85,
          87.61,
          97.03,
        ],
      },
      {
        676: [
          "1199",
          6.65,
          3060740,
          75.8,
          "6.685",
          "6.454",
          "6.297",
          "6.334",
          "6.323",
          2031,
          220.4,
          38.46,
          0.4,
          77.24,
          87.38,
          90.26,
        ],
      },
      {
        677: [
          "1413",
          0.345,
          340000,
          75.8,
          "0.349",
          "0.308",
          "0.289",
          "0.304",
          "0.29",
          11.762,
          3.45,
          75,
          0,
          83.3,
          88.11,
          92.45,
        ],
      },
      {
        678: [
          "1589",
          4.34,
          3107000,
          75.7,
          "4.336",
          "4.26",
          "3.947",
          "4.043",
          "3.99",
          1346,
          150.7,
          6.26,
          0.32,
          71.87,
          83.72,
          98.89,
        ],
      },
      {
        679: [
          "1751",
          0.215,
          0,
          75.7,
          "0.237",
          "0.253",
          "0.264",
          "0.246",
          "0.261",
          0,
          1.734,
          0.01,
          0,
          80.13,
          85.35,
          91.54,
        ],
      },
      {
        680: [
          "1631",
          0.7,
          5000,
          75.7,
          "0.722",
          "0.751",
          "0.69",
          "0.686",
          "0.699",
          0.35,
          1.792,
          75.01,
          0,
          82.21,
          87.05,
          92.51,
        ],
      },
      {
        681: [
          "3958",
          6.43,
          3573200,
          75.6,
          "6.538",
          "6.709",
          "6.832",
          "6.392",
          "6.897",
          2310,
          66,
          0.01,
          53.87,
          64.42,
          80.11,
          90.49,
        ],
      },
      {
        682: [
          "9804",
          13.42,
          0,
          75.6,
          "13.494",
          "13.397",
          "13.057",
          "12.893",
          "13.087",
          0,
          0,
          42.27,
          0,
          95.36,
          98.66,
          99.87,
        ],
      },
      {
        683: [
          "2324",
          0.285,
          292000,
          75.5,
          "0.296",
          "0.316",
          "0.293",
          "0.301",
          "0.294",
          8.324,
          1.174,
          2.91,
          0,
          40.08,
          55.29,
          72.21,
        ],
      },
      {
        684: [
          "2611",
          11.76,
          1738400,
          75.5,
          "11.64",
          "11.039",
          "10.89",
          "10.959",
          "10.957",
          2049,
          163.6,
          0.02,
          23.98,
          53.92,
          76.53,
          96.08,
        ],
      },
      {
        685: [
          "1542",
          3.32,
          0,
          75.5,
          "3.326",
          "3.117",
          "3.138",
          "3.149",
          "3.15",
          0,
          1.66,
          0.01,
          0,
          92.69,
          97.57,
          98.89,
        ],
      },
      {
        686: [
          "1623",
          0.325,
          4345000,
          75.4,
          "0.313",
          "0.313",
          "0.278",
          "0.278",
          "0.278",
          138,
          5.513,
          19.49,
          0,
          73,
          78.64,
          85.78,
        ],
      },
      {
        687: [
          "3898",
          40.95,
          2712330,
          75.4,
          "42.445",
          "45.664",
          "45.12",
          "42.371",
          "45.042",
          11059,
          224.1,
          0.26,
          31.26,
          55.95,
          75.24,
          87.51,
        ],
      },
      {
        688: [
          "0728",
          2.87,
          154315000,
          75.4,
          "2.744",
          "2.619",
          "2.733",
          "2.709",
          "2.757",
          44354,
          398.2,
          0.21,
          27.43,
          52.98,
          66.89,
          80.64,
        ],
      },
      {
        689: [
          "0506",
          3.1,
          918000,
          75.3,
          "3.085",
          "2.944",
          "2.836",
          "2.918",
          "2.834",
          284,
          86.7,
          57.97,
          0.92,
          84.04,
          90.81,
          95.65,
        ],
      },
      {
        690: [
          "0546",
          2.94,
          6733000,
          75.3,
          "2.942",
          "2.795",
          "2.674",
          "2.698",
          "2.67",
          1976,
          74.4,
          0.11,
          4.41,
          69.14,
          82.29,
          93.87,
        ],
      },
      {
        691: [
          "9817",
          16.85,
          500,
          75.3,
          "16.78",
          "16.559",
          "16.215",
          "-",
          "16.255",
          6.563,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        692: [
          "8403",
          1,
          40000,
          75.2,
          "1.002",
          "1.047",
          "1.007",
          "0.956",
          "-",
          4.08,
          1,
          1.6,
          0,
          78.49,
          82.29,
          89.28,
        ],
      },
      {
        693: [
          "2817",
          131.05,
          400,
          75.2,
          "130.72",
          "129.082",
          "126.249",
          "-",
          "126.545",
          5.242,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        694: [
          "1908",
          15.26,
          1013680,
          75.2,
          "15.826",
          "15.21",
          "14.915",
          "14.924",
          "15.035",
          1577,
          210.2,
          26.26,
          3.06,
          70.82,
          80.67,
          92.48,
        ],
      },
      {
        695: [
          "1738",
          1.19,
          5000,
          75.1,
          "1.136",
          "1.21",
          "1.097",
          "1.086",
          "1.099",
          0.595,
          16.4,
          51.19,
          0,
          92.35,
          96.57,
          98.27,
        ],
      },
      {
        696: [
          "1258",
          3.38,
          7509000,
          75.1,
          "3.079",
          "2.968",
          "3.55",
          "3.59",
          "3.555",
          2565,
          126.3,
          57.56,
          0,
          73.75,
          80.04,
          90.13,
        ],
      },
      {
        697: [
          "2217",
          3.14,
          1116000,
          75,
          "3.149",
          "3.193",
          "-",
          "-",
          "-",
          348,
          42,
          0.01,
          0,
          86.86,
          89.77,
          93.87,
        ],
      },
      {
        698: [
          "1272",
          1.01,
          0,
          75,
          "1.023",
          "1.02",
          "1.008",
          "1.008",
          "1.016",
          0,
          6.305,
          0.03,
          0,
          56.28,
          82.88,
          98.98,
        ],
      },
      {
        699: [
          "1265",
          0.48,
          0,
          75,
          "0.485",
          "0.428",
          "0.446",
          "0.467",
          "0.438",
          0,
          2.4,
          0.18,
          0,
          27.48,
          37.53,
          52.01,
        ],
      },
      {
        700: [
          "2804",
          104.25,
          50,
          74.9,
          "105.315",
          "104.381",
          "101.576",
          "100.271",
          "101.824",
          0.521,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        701: [
          "8139",
          2.13,
          0,
          74.9,
          "2.142",
          "1.919",
          "1.918",
          "1.975",
          "1.906",
          0,
          0.818,
          0,
          0,
          72.17,
          77.61,
          86.08,
        ],
      },
      {
        702: [
          "8537",
          0.08,
          0,
          74.9,
          "0.079",
          "0.074",
          "0.073",
          "0.074",
          "0.073",
          0,
          0.6,
          65.25,
          0,
          79.04,
          84.21,
          90.97,
        ],
      },
      {
        703: [
          "8081",
          0.095,
          1020000,
          74.8,
          "0.098",
          "0.108",
          "0.112",
          "0.102",
          "0.116",
          9.63,
          5.069,
          0.02,
          0,
          52.15,
          65.06,
          80.57,
        ],
      },
      {
        704: [
          "1045",
          2.38,
          606000,
          74.8,
          "2.341",
          "2.288",
          "2.339",
          "2.391",
          "2.339",
          144,
          22.1,
          53.8,
          0,
          68.33,
          72.67,
          78.81,
        ],
      },
      {
        705: [
          "1897",
          0.82,
          507600,
          74.8,
          "0.798",
          "0.778",
          "0.813",
          "0.82",
          "0.813",
          41.089,
          3.452,
          63.17,
          0,
          69.33,
          74.61,
          79.96,
        ],
      },
      {
        706: [
          "3119",
          63.26,
          4851,
          74.7,
          "64.126",
          "63.788",
          "-",
          "-",
          "-",
          30.699,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        707: [
          "1329",
          1.11,
          8000,
          74.7,
          "1.055",
          "1.094",
          "1.089",
          "1.091",
          "1.071",
          0.878,
          10.6,
          69.3,
          0,
          93.34,
          97.93,
          98.85,
        ],
      },
      {
        708: [
          "1651",
          9.9,
          2000,
          74.7,
          "9.859",
          "10.39",
          "10.657",
          "10.534",
          "10.63",
          1.964,
          37.6,
          0,
          0,
          86.68,
          93.99,
          98.31,
        ],
      },
      {
        709: [
          "9898",
          269.6,
          28160,
          74.6,
          "252.8",
          "-",
          "-",
          "-",
          "-",
          758,
          655.9,
          73.65,
          0,
          97.99,
          98.8,
          99.86,
        ],
      },
      {
        710: [
          "2803",
          9.13,
          0,
          74.6,
          "9.06",
          "8.759",
          "8.609",
          "8.644",
          "8.625",
          0,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        711: [
          "8049",
          1.22,
          4000,
          74.5,
          "1.222",
          "1.161",
          "1.131",
          "1.168",
          "1.128",
          0.488,
          2.104,
          0.01,
          0,
          47.86,
          60.15,
          80.19,
        ],
      },
      {
        712: [
          "1447",
          0.83,
          388000,
          74.5,
          "0.831",
          "0.805",
          "0.876",
          "0.893",
          "0.866",
          32.402,
          3.32,
          72.1,
          0,
          80.23,
          86.82,
          94.81,
        ],
      },
      {
        713: [
          "0219",
          1.36,
          6300,
          74.5,
          "1.338",
          "1.292",
          "1.248",
          "1.256",
          "1.248",
          0.855,
          7.885,
          53.72,
          0,
          71.17,
          75.72,
          80.77,
        ],
      },
      {
        714: [
          "1792",
          0.078,
          280000,
          74.4,
          "0.081",
          "0.097",
          "0.084",
          "0.078",
          "0.088",
          2.166,
          1.409,
          14.49,
          0,
          66.61,
          78.28,
          88.72,
        ],
      },
      {
        715: [
          "2886",
          1.68,
          72000,
          74.4,
          "1.679",
          "1.536",
          "1.619",
          "1.591",
          "1.63",
          11.808,
          22.7,
          3.77,
          0,
          77.28,
          84.22,
          90.93,
        ],
      },
      {
        716: [
          "3989",
          0.172,
          3310000,
          74.4,
          "0.184",
          "0.184",
          "0.178",
          "0.17",
          "0.18",
          57.242,
          24.5,
          34.68,
          0,
          72.27,
          78.91,
          84.9,
        ],
      },
      {
        717: [
          "1469",
          0.84,
          18250,
          74.3,
          "0.836",
          "0.827",
          "0.837",
          "0.866",
          "0.833",
          1.503,
          21,
          73.8,
          0,
          91.97,
          93.56,
          96.2,
        ],
      },
      {
        718: [
          "0996",
          0.105,
          1022000,
          74.3,
          "0.105",
          "0.102",
          "0.082",
          "0.086",
          "0.086",
          9.701,
          0.995,
          0.79,
          23.14,
          44,
          54.57,
          67.95,
        ],
      },
      {
        719: [
          "2015",
          117.5,
          613000,
          74.3,
          "117.24",
          "121.606",
          "118.931",
          "-",
          "118.929",
          7228,
          2427.2,
          47.19,
          0,
          95.04,
          96.82,
          99.13,
        ],
      },
      {
        720: [
          "8290",
          0.03,
          0,
          74.2,
          "0.03",
          "0.029",
          "0.029",
          "0.029",
          "0.029",
          0,
          0.6,
          75,
          0,
          80.78,
          84.74,
          88.99,
        ],
      },
      {
        721: [
          "0941",
          51.05,
          25005400,
          74.2,
          "49.295",
          "47.491",
          "48.207",
          "48.723",
          "48.237",
          127000,
          10884.4,
          74.07,
          8.1,
          83.31,
          89.52,
          94.69,
        ],
      },
      {
        722: [
          "0882",
          1.74,
          0,
          74.1,
          "1.715",
          "1.675",
          "1.706",
          "1.692",
          "1.704",
          0,
          18.6,
          53.63,
          0,
          66.1,
          73.05,
          82.3,
        ],
      },
      {
        723: [
          "0992",
          9.03,
          20697100,
          74.1,
          "8.964",
          "8.441",
          "8.347",
          "8.776",
          "8.307",
          18520,
          1087.3,
          26.62,
          2.67,
          78.03,
          87.82,
          94.91,
        ],
      },
      {
        724: [
          "9900",
          1.84,
          52000,
          74.1,
          "1.971",
          "1.994",
          "1.945",
          "1.943",
          "1.963",
          10.028,
          6.845,
          0.01,
          0,
          71.46,
          84.39,
          93.51,
        ],
      },
      {
        725: [
          "0465",
          0.69,
          88000,
          74,
          "0.631",
          "0.586",
          "0.652",
          "0.656",
          "0.644",
          5.802,
          2.148,
          69.3,
          0,
          84.45,
          90.64,
          94.61,
        ],
      },
      {
        726: [
          "1872",
          0.174,
          685000,
          74,
          "0.175",
          "0.188",
          "0.175",
          "0.169",
          "0.176",
          11.922,
          1.566,
          0.03,
          0,
          65.59,
          72.64,
          82.52,
        ],
      },
      {
        727: [
          "2299",
          5.25,
          800000,
          74,
          "5.6",
          "5.365",
          "5.302",
          "5.286",
          "5.308",
          424,
          111.2,
          64.07,
          0,
          96.89,
          98.27,
          99.28,
        ],
      },
      {
        728: [
          "0896",
          1.28,
          142000,
          73.9,
          "1.291",
          "1.292",
          "1.305",
          "1.308",
          "1.304",
          18.238,
          14.1,
          61.38,
          0,
          70.27,
          76.18,
          82.35,
        ],
      },
      {
        729: [
          "1549",
          0.175,
          5416000,
          73.9,
          "0.174",
          "0.147",
          "0.229",
          "0.231",
          "0.226",
          97.646,
          2.712,
          0.01,
          0,
          74.56,
          79.79,
          87.71,
        ],
      },
      {
        730: [
          "2814",
          22.2,
          45400,
          73.9,
          "22.194",
          "22.822",
          "22.113",
          "-",
          "22.188",
          100,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        731: [
          "1231",
          0.8,
          0,
          73.8,
          "0.8",
          "0.839",
          "0.832",
          "0.799",
          "0.844",
          0,
          32,
          2.01,
          0,
          48.22,
          68.1,
          94.58,
        ],
      },
      {
        732: [
          "0954",
          0.7,
          0,
          73.8,
          "0.685",
          "0.679",
          "0.682",
          "0.695",
          "0.681",
          0,
          1.286,
          0.11,
          0,
          30.39,
          44.97,
          63.78,
        ],
      },
      {
        733: [
          "1412",
          1.23,
          0,
          73.8,
          "1.23",
          "1.237",
          "1.27",
          "1.282",
          "1.265",
          0,
          6.544,
          75.05,
          0,
          83.61,
          88.5,
          94.35,
        ],
      },
      {
        734: [
          "8293",
          0.062,
          815000,
          73.7,
          "0.058",
          "0.055",
          "0.056",
          "0.055",
          "0.057",
          4.451,
          0.93,
          0.12,
          0,
          60.16,
          68.86,
          77.66,
        ],
      },
      {
        735: [
          "1889",
          0.119,
          1675000,
          73.7,
          "0.118",
          "0.132",
          "0.125",
          "0.116",
          "0.127",
          19.629,
          3.65,
          0.23,
          0,
          42.29,
          53.26,
          70.69,
        ],
      },
      {
        736: [
          "8445",
          0.35,
          110000,
          73.6,
          "0.335",
          "0.317",
          "0.346",
          "0.343",
          "0.361",
          3.6,
          0.733,
          14.05,
          0,
          73.95,
          81.39,
          88.96,
        ],
      },
      {
        737: [
          "1359",
          1.52,
          40380200,
          73.6,
          "1.466",
          "1.326",
          "1.385",
          "1.437",
          "1.374",
          6111,
          206.2,
          0.4,
          18.34,
          43.58,
          54.17,
          64.35,
        ],
      },
      {
        738: [
          "7300",
          5.005,
          4941500,
          73.6,
          "5.193",
          "5.12",
          "4.901",
          "4.803",
          "4.922",
          2472,
          0,
          0,
          0,
          61.79,
          72.31,
          81.54,
        ],
      },
      {
        739: [
          "1185",
          0.153,
          0,
          73.5,
          "0.153",
          "0.153",
          "0.153",
          "0.152",
          "0.153",
          0,
          6.684,
          60.81,
          0,
          71.71,
          78.38,
          87.49,
        ],
      },
      {
        740: [
          "2136",
          1.17,
          214500,
          73.5,
          "1.187",
          "1.147",
          "1.135",
          "1.152",
          "1.129",
          25.26,
          17.1,
          23.25,
          0.02,
          56.92,
          74.55,
          86.36,
        ],
      },
      {
        741: [
          "1632",
          0.66,
          18000,
          73.5,
          "0.663",
          "0.597",
          "0.591",
          "0.622",
          "0.589",
          1.174,
          5.669,
          0.21,
          0,
          70.39,
          78.35,
          87.71,
        ],
      },
      {
        742: [
          "8496",
          0.5,
          0,
          73.4,
          "0.505",
          "0.509",
          "0.487",
          "0.512",
          "0.488",
          0,
          1.2,
          64.53,
          0,
          77.23,
          81.48,
          87.8,
        ],
      },
      {
        743: [
          "9633",
          44.85,
          4569630,
          73.4,
          "46.98",
          "46.534",
          "42.283",
          "41.924",
          "42.382",
          20266,
          2258,
          0,
          2.11,
          95.17,
          97.28,
          98.59,
        ],
      },
      {
        744: [
          "3143",
          7.45,
          58800,
          73.4,
          "7.131",
          "6.826",
          "6.966",
          "7.115",
          "6.954",
          43.712,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        745: [
          "1675",
          12.82,
          60800,
          73.3,
          "12.736",
          "12.835",
          "12.728",
          "12.732",
          "12.751",
          77.643,
          118.5,
          20.28,
          0.97,
          69.92,
          87.54,
          98.8,
        ],
      },
      {
        746: [
          "1475",
          6.27,
          804000,
          73.3,
          "6.242",
          "5.851",
          "5.755",
          "5.817",
          "5.761",
          505,
          65.6,
          72.99,
          0.35,
          84.52,
          89.03,
          94.55,
        ],
      },
      {
        747: [
          "9814",
          2.846,
          0,
          73.3,
          "2.847",
          "2.928",
          "2.84",
          "-",
          "2.85",
          0,
          0,
          87.98,
          0,
          99.33,
          99.89,
          100,
        ],
      },
      {
        748: [
          "0046",
          2.78,
          6000,
          73.2,
          "2.715",
          "2.702",
          "2.704",
          "2.697",
          "2.713",
          1.644,
          6.936,
          62.49,
          0,
          73.91,
          79.01,
          84.63,
        ],
      },
      {
        749: [
          "0403",
          0.34,
          1320000,
          73.2,
          "0.338",
          "0.38",
          "0.359",
          "0.344",
          "0.36",
          44.353,
          1.785,
          52.69,
          0,
          67.26,
          75.12,
          82.48,
        ],
      },
      {
        750: [
          "0003",
          12.16,
          10653900,
          73.1,
          "12.14",
          "11.89",
          "12.147",
          "12.158",
          "12.131",
          12913,
          2269,
          48.82,
          0.22,
          71.96,
          80.86,
          89.53,
        ],
      },
      {
        751: [
          "3198",
          101.15,
          0,
          73.1,
          "101.515",
          "100.715",
          "99.363",
          "98.393",
          "99.522",
          0,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        752: [
          "1910",
          16.3,
          2830000,
          73.1,
          "15.592",
          "16.002",
          "15.919",
          "15.579",
          "15.823",
          4654,
          234.2,
          4.01,
          0,
          70.44,
          82.98,
          96.83,
        ],
      },
      {
        753: [
          "3193",
          7.955,
          35500,
          73,
          "8.296",
          "8.272",
          "7.912",
          "7.691",
          "7.921",
          28.261,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        754: [
          "0149",
          0.12,
          142125,
          73,
          "0.119",
          "0.116",
          "0.118",
          "0.12",
          "0.117",
          1.682,
          11.9,
          53.4,
          0,
          81.6,
          85.66,
          90.65,
        ],
      },
      {
        755: [
          "8526",
          0.24,
          10650000,
          73,
          "0.215",
          "0.199",
          "0.197",
          "0.24",
          "0.198",
          236,
          1.558,
          45.13,
          0,
          59.68,
          68.76,
          82.76,
        ],
      },
      {
        756: [
          "8451",
          0.061,
          0,
          72.9,
          "0.059",
          "0.059",
          "0.06",
          "0.058",
          "0.06",
          0,
          0.488,
          72.07,
          0,
          83.77,
          88.68,
          94.17,
        ],
      },
      {
        757: [
          "1196",
          8.99,
          1750000,
          72.9,
          "9.542",
          "9.898",
          "10.638",
          "10.815",
          "10.538",
          1572,
          129.4,
          76.02,
          8.25,
          88.07,
          91.61,
          97.07,
        ],
      },
      {
        758: [
          "7348",
          8.51,
          6500,
          72.9,
          "8.391",
          "8.321",
          "8.369",
          "8.262",
          "8.393",
          5.525,
          0,
          0,
          0,
          93.57,
          99.9,
          100,
        ],
      },
      {
        759: [
          "1389",
          0.038,
          8808000,
          72.8,
          "0.038",
          "0.041",
          "0.039",
          "0.039",
          "0.039",
          33.47,
          1.264,
          67.99,
          0,
          76.74,
          82.3,
          89.77,
        ],
      },
      {
        760: [
          "0329",
          3.09,
          760000,
          72.8,
          "3.143",
          "3.216",
          "3.593",
          "3.553",
          "3.545",
          232,
          46.3,
          29.35,
          0,
          76.31,
          84.59,
          91.64,
        ],
      },
      {
        761: [
          "8362",
          0.04,
          80000,
          72.8,
          "0.041",
          "0.044",
          "0.04",
          "0.039",
          "0.041",
          0.324,
          0.56,
          75.1,
          0,
          81.56,
          85.95,
          92.79,
        ],
      },
      {
        762: [
          "1049",
          1.65,
          0,
          72.7,
          "1.782",
          "1.835",
          "1.693",
          "1.663",
          "1.714",
          0,
          1.332,
          50.27,
          0,
          69.11,
          76.84,
          85.51,
        ],
      },
      {
        763: [
          "1427",
          3.5,
          583000,
          72.7,
          "3.655",
          "3.775",
          "3.414",
          "3.385",
          "3.428",
          205,
          28.3,
          74.94,
          0,
          88.48,
          96.05,
          99.45,
        ],
      },
      {
        764: [
          "2102",
          0.28,
          0,
          72.6,
          "0.285",
          "0.311",
          "0.294",
          "0.284",
          "0.294",
          0,
          2.8,
          75.09,
          0,
          83.85,
          87.2,
          93.15,
        ],
      },
      {
        765: [
          "3363",
          0.68,
          0,
          72.6,
          "0.683",
          "0.713",
          "0.698",
          "0.693",
          "0.701",
          0,
          3.4,
          75.03,
          0,
          87.1,
          91.03,
          95.44,
        ],
      },
      {
        766: [
          "8280",
          0.11,
          414000,
          72.6,
          "0.119",
          "0.113",
          "0.114",
          "0.115",
          "0.114",
          4.807,
          0.693,
          12.24,
          0,
          69,
          79.15,
          88.13,
        ],
      },
      {
        767: [
          "3160",
          13.93,
          265200,
          72.5,
          "13.906",
          "13.81",
          "13.611",
          "13.485",
          "13.646",
          369,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        768: [
          "0422",
          0.265,
          0,
          72.5,
          "0.259",
          "0.258",
          "0.261",
          "0.263",
          "0.262",
          0,
          2.405,
          2.72,
          0,
          85.77,
          89.21,
          93.48,
        ],
      },
      {
        769: [
          "3093",
          13.85,
          175000,
          72.5,
          "13.776",
          "13.439",
          "13.232",
          "13.2",
          "13.259",
          243,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        770: [
          "0313",
          0.012,
          2850000,
          72.4,
          "0.012",
          "0.011",
          "0.012",
          "0.012",
          "0.012",
          3.155,
          2.8,
          0.35,
          0,
          77.75,
          82.55,
          88.53,
        ],
      },
      {
        771: [
          "1560",
          0.41,
          0,
          72.4,
          "0.407",
          "0.397",
          "0.403",
          "0.412",
          "0.401",
          0,
          2.63,
          48.25,
          0,
          76.74,
          85.11,
          91.28,
        ],
      },
      {
        772: [
          "1775",
          0.54,
          0,
          72.4,
          "0.582",
          "0.656",
          "0.571",
          "0.545",
          "0.577",
          0,
          2.7,
          75.07,
          0,
          87.64,
          91.47,
          95.08,
        ],
      },
      {
        773: [
          "0623",
          1.13,
          31000,
          72.3,
          "1.114",
          "1.128",
          "1.175",
          "1.159",
          "1.179",
          3.521,
          5.216,
          3.24,
          0,
          72.62,
          78.58,
          85.54,
        ],
      },
      {
        774: [
          "2270",
          1.1,
          46000,
          72.3,
          "1.104",
          "-",
          "-",
          "-",
          "-",
          5.06,
          6.747,
          69.7,
          0,
          87.13,
          94.15,
          99.54,
        ],
      },
      {
        775: [
          "0464",
          0.475,
          1240000,
          72.3,
          "0.466",
          "0.453",
          "0.517",
          "0.528",
          "0.517",
          59.052,
          2.117,
          6.52,
          0,
          78.98,
          88.81,
          95.45,
        ],
      },
      {
        776: [
          "1907",
          4.67,
          4389000,
          72.2,
          "4.653",
          "4.656",
          "4.974",
          "5.062",
          "4.95",
          2044,
          207.3,
          60.97,
          3.72,
          76.74,
          82.37,
          90.37,
        ],
      },
      {
        777: [
          "3709",
          11.56,
          852500,
          72.2,
          "11.474",
          "10.88",
          "10.863",
          "10.786",
          "11.006",
          958,
          81.3,
          4.39,
          0,
          73.12,
          78.35,
          88.91,
        ],
      },
      {
        778: [
          "1750",
          0.051,
          490000,
          72.1,
          "0.048",
          "0.051",
          "0.054",
          "0.053",
          "0.054",
          2.405,
          0.918,
          75.02,
          0,
          81.9,
          86.03,
          90.82,
        ],
      },
      {
        779: [
          "1346",
          0.4,
          64000,
          72.1,
          "0.4",
          "0.405",
          "0.411",
          "0.407",
          "0.412",
          2.56,
          2.556,
          57.58,
          0,
          78.63,
          90.02,
          95.92,
        ],
      },
      {
        780: [
          "0536",
          1.16,
          236000,
          72.1,
          "1.162",
          "1.149",
          "1.157",
          "1.165",
          "1.157",
          27.378,
          9.218,
          19.27,
          0,
          43.91,
          57.98,
          72.48,
        ],
      },
      {
        781: [
          "0842",
          0.83,
          469000,
          72,
          "0.845",
          "0.888",
          "0.878",
          "0.889",
          "0.885",
          38.811,
          11.2,
          0.52,
          0,
          80.34,
          84.32,
          89.43,
        ],
      },
      {
        782: [
          "8249",
          0.115,
          0,
          72,
          "0.118",
          "0.143",
          "0.132",
          "0.123",
          "0.135",
          0,
          0.15,
          4.31,
          0,
          29.78,
          41.25,
          59.58,
        ],
      },
      {
        783: [
          "1129",
          0.29,
          102000,
          72,
          "0.302",
          "0.296",
          "0.297",
          "0.295",
          "0.298",
          2.94,
          4.63,
          0.59,
          0,
          64.63,
          72.5,
          82.61,
        ],
      },
      {
        784: [
          "1902",
          2.99,
          402000,
          71.9,
          "2.823",
          "2.654",
          "2.929",
          "2.997",
          "2.914",
          118,
          43.2,
          55.88,
          0,
          79.59,
          86.71,
          96.13,
        ],
      },
      {
        785: [
          "1586",
          1.69,
          8000,
          71.9,
          "1.73",
          "1.737",
          "1.901",
          "1.818",
          "1.89",
          1.348,
          7.495,
          0,
          0,
          87.38,
          90,
          94.43,
        ],
      },
      {
        786: [
          "0369",
          4.38,
          2000,
          71.9,
          "4.321",
          "4.299",
          "4.387",
          "4.415",
          "4.368",
          0.886,
          59.3,
          30.48,
          0,
          66.48,
          76.78,
          85.71,
        ],
      },
      {
        787: [
          "7333",
          7.075,
          160500,
          71.8,
          "6.943",
          "6.903",
          "6.891",
          "6.859",
          "6.9",
          112,
          0,
          0,
          0,
          79.36,
          95.19,
          98.81,
        ],
      },
      {
        788: [
          "1093",
          9.35,
          31193000,
          71.8,
          "8.856",
          "8.352",
          "9.554",
          "9.736",
          "9.387",
          29123,
          1117.3,
          0.07,
          4.26,
          65.54,
          82.09,
          93.91,
        ],
      },
      {
        789: [
          "1417",
          1.83,
          0,
          71.8,
          "1.83",
          "1.767",
          "1.851",
          "1.848",
          "1.841",
          0,
          7.412,
          0,
          0,
          87.1,
          91.79,
          98.49,
        ],
      },
      {
        790: [
          "0640",
          0.69,
          12000,
          71.7,
          "0.672",
          "0.688",
          "0.681",
          "0.669",
          "0.681",
          0.828,
          3.887,
          60.82,
          0,
          81.06,
          86.94,
          93.72,
        ],
      },
      {
        791: [
          "1193",
          43.1,
          2149860,
          71.7,
          "43.69",
          "41.671",
          "44.375",
          "44.368",
          "43.895",
          9273,
          997.3,
          59.57,
          0.29,
          88.21,
          93.86,
          99.11,
        ],
      },
      {
        792: [
          "7336",
          5.05,
          0,
          71.6,
          "5.233",
          "5.164",
          "4.971",
          "4.884",
          "4.991",
          0,
          0,
          0,
          0,
          96.69,
          98.19,
          99.6,
        ],
      },
      {
        793: [
          "3323",
          10.4,
          33032300,
          71.6,
          "10.157",
          "9.335",
          "9.798",
          "10.109",
          "9.863",
          34498,
          474,
          0.27,
          20.53,
          53.37,
          70.32,
          86.8,
        ],
      },
      {
        794: [
          "2877",
          6.77,
          561000,
          71.6,
          "6.791",
          "7.009",
          "7.612",
          "7.28",
          "7.581",
          382,
          55.9,
          66.15,
          1.16,
          83.6,
          90.27,
          94.37,
        ],
      },
      {
        795: [
          "3079",
          138.8,
          0,
          71.5,
          "138.61",
          "137.845",
          "135.677",
          "134.855",
          "135.848",
          0,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        796: [
          "0002",
          78.1,
          3047870,
          71.5,
          "78.535",
          "76.866",
          "76.991",
          "77.098",
          "76.825",
          23705,
          1973.1,
          44.86,
          0.17,
          74.64,
          80.88,
          88.97,
        ],
      },
      {
        797: [
          "0581",
          2.45,
          2328000,
          71.5,
          "2.431",
          "2.219",
          "2.398",
          "2.454",
          "2.405",
          574,
          91.2,
          11.98,
          3.05,
          78.47,
          84.79,
          92.83,
        ],
      },
      {
        798: [
          "8326",
          0.174,
          1158000,
          71.4,
          "0.187",
          "0.197",
          "0.2",
          "0.183",
          "0.198",
          19.216,
          1.423,
          2.42,
          0,
          66.3,
          77.18,
          87.42,
        ],
      },
      {
        799: [
          "1948",
          6.68,
          663000,
          71.4,
          "6.596",
          "-",
          "-",
          "-",
          "-",
          447,
          40,
          77.29,
          0,
          89.25,
          92.77,
          97.77,
        ],
      },
      {
        800: [
          "1860",
          7.01,
          1109150,
          71.4,
          "6.918",
          "6.844",
          "7.503",
          "7.587",
          "7.421",
          777,
          116.6,
          55.97,
          5.02,
          74.59,
          86.42,
          95.69,
        ],
      },
      {
        801: [
          "0531",
          0.29,
          441000,
          71.3,
          "0.28",
          "0.244",
          "0.276",
          "0.284",
          "0.274",
          13.014,
          9.012,
          0.04,
          0,
          78.27,
          85.15,
          90.73,
        ],
      },
      {
        802: [
          "2319",
          47.8,
          6720170,
          71.3,
          "45.655",
          "45.674",
          "45.948",
          "45.419",
          "46.083",
          32084,
          1889.6,
          13.77,
          3.97,
          69.06,
          85.7,
          95.92,
        ],
      },
      {
        803: [
          "1932",
          0.47,
          0,
          71.2,
          "0.469",
          "0.45",
          "0.455",
          "0.457",
          "0.455",
          0,
          4.7,
          80.99,
          0,
          88.64,
          92.92,
          96.46,
        ],
      },
      {
        804: [
          "1401",
          0.72,
          0,
          71.2,
          "0.721",
          "0.718",
          "0.745",
          "0.733",
          "0.746",
          0,
          7.2,
          76.86,
          0,
          84.15,
          89.63,
          95.49,
        ],
      },
      {
        805: [
          "0113",
          4.29,
          10500,
          71.2,
          "4.277",
          "4.303",
          "4.327",
          "4.272",
          "4.319",
          4.422,
          16.9,
          49.9,
          0,
          75.87,
          88.49,
          93.67,
        ],
      },
      {
        806: [
          "2057",
          245,
          177900,
          71.1,
          "223.48",
          "229.58",
          "228.778",
          "231.118",
          "229.86",
          4372,
          2095.4,
          63.93,
          0,
          96.81,
          98.6,
          99.73,
        ],
      },
      {
        807: [
          "0450",
          1.3,
          222000,
          71.1,
          "1.288",
          "1.287",
          "1.304",
          "1.313",
          "1.31",
          28.752,
          11.8,
          64.39,
          0,
          74.77,
          79.95,
          86.17,
        ],
      },
      {
        808: [
          "3968",
          63.8,
          9627340,
          71.1,
          "62.09",
          "63.093",
          "63.77",
          "63.927",
          "63.685",
          61344,
          2928.9,
          0.83,
          3.65,
          69.58,
          81.44,
          91.69,
        ],
      },
      {
        809: [
          "2023",
          1.16,
          24000,
          71,
          "1.151",
          "1.191",
          "1.197",
          "1.183",
          "1.195",
          2.764,
          5.705,
          0.02,
          0,
          84.87,
          87.36,
          91.36,
        ],
      },
      {
        810: [
          "0611",
          0.78,
          544000,
          71,
          "0.812",
          "0.935",
          "0.752",
          "0.721",
          "0.757",
          42.756,
          10.2,
          30.54,
          0,
          43.12,
          53.46,
          70.3,
        ],
      },
      {
        811: [
          "2328",
          7.17,
          50184900,
          71,
          "6.834",
          "6.773",
          "6.974",
          "7.048",
          "6.947",
          35749,
          494.6,
          0.84,
          9.59,
          65.96,
          75.55,
          88.52,
        ],
      },
      {
        812: [
          "0311",
          0.415,
          0,
          70.9,
          "0.412",
          "0.412",
          "0.407",
          "0.401",
          "0.41",
          0,
          4.292,
          65.26,
          0,
          93.85,
          96.71,
          98.09,
        ],
      },
      {
        813: [
          "0392",
          28.75,
          800172,
          70.9,
          "27.96",
          "27.328",
          "27.558",
          "27.284",
          "27.642",
          2296,
          362.8,
          45.99,
          6,
          64.35,
          73.83,
          85.85,
        ],
      },
      {
        814: [
          "3848",
          7.16,
          75000,
          70.9,
          "7.091",
          "6.921",
          "6.969",
          "6.985",
          "6.939",
          53.638,
          11.2,
          13.51,
          0,
          76.18,
          91.6,
          98.54,
        ],
      },
      {
        815: [
          "2341",
          1.75,
          132000,
          70.8,
          "1.719",
          "1.682",
          "1.897",
          "1.889",
          "1.886",
          22.882,
          13.5,
          3.62,
          0,
          66.97,
          78.17,
          89.27,
        ],
      },
      {
        816: [
          "1288",
          2.92,
          125587000,
          70.8,
          "2.791",
          "2.678",
          "2.667",
          "2.773",
          "2.667",
          36395,
          897.5,
          0.64,
          19.93,
          57.75,
          72.61,
          84.61,
        ],
      },
      {
        817: [
          "1616",
          0.169,
          4000,
          70.7,
          "0.172",
          "0.168",
          "0.153",
          "0.153",
          "0.155",
          0,
          3.636,
          2.55,
          0,
          49.35,
          72.09,
          94.22,
        ],
      },
      {
        818: [
          "2813",
          131.4,
          0,
          70.7,
          "131.385",
          "130.269",
          "128.393",
          "127.708",
          "128.511",
          0,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        819: [
          "1786",
          0.89,
          207000,
          70.7,
          "0.884",
          "0.824",
          "0.857",
          "0.875",
          "0.861",
          18.232,
          4.734,
          0.12,
          0,
          57.11,
          66.2,
          74.8,
        ],
      },
      {
        820: [
          "1367",
          0.51,
          0,
          70.6,
          "0.51",
          "0.51",
          "0.51",
          "0.51",
          "0.51",
          0,
          2.448,
          0.01,
          0,
          70.86,
          79.94,
          90.68,
        ],
      },
      {
        821: [
          "1496",
          0.166,
          0,
          70.6,
          "0.165",
          "0.162",
          "0.16",
          "0.159",
          "0.16",
          0,
          1.434,
          82.53,
          0,
          87.46,
          90.48,
          93.79,
        ],
      },
      {
        822: [
          "0004",
          25.3,
          806687,
          70.6,
          "23.955",
          "25.606",
          "26.503",
          "25.797",
          "26.413",
          2033,
          773.1,
          47.86,
          0.14,
          80.98,
          88.24,
          94.82,
        ],
      },
      {
        823: [
          "1871",
          0.275,
          0,
          70.5,
          "0.264",
          "0.269",
          "0.29",
          "0.287",
          "0.288",
          0,
          1.1,
          64.5,
          0,
          79.71,
          84.99,
          90.21,
        ],
      },
      {
        824: [
          "1615",
          0.26,
          0,
          70.5,
          "0.263",
          "0.279",
          "0.282",
          "0.266",
          "0.283",
          0,
          1.56,
          75,
          0,
          85.33,
          88.96,
          93.88,
        ],
      },
      {
        825: [
          "0548",
          7.78,
          2619360,
          70.5,
          "7.646",
          "7.466",
          "7.467",
          "7.633",
          "7.489",
          2025,
          58.1,
          2.32,
          21.14,
          47.76,
          62.03,
          80.23,
        ],
      },
      {
        826: [
          "2738",
          2.57,
          302000,
          70.4,
          "2.594",
          "2.43",
          "2.612",
          "2.56",
          "2.585",
          77.786,
          15.4,
          8.04,
          0,
          89.65,
          92.86,
          97.23,
        ],
      },
      {
        827: [
          "2128",
          14.52,
          5380930,
          70.4,
          "13.486",
          "11.777",
          "14.22",
          "15.442",
          "13.887",
          7754,
          450.4,
          0.02,
          1.24,
          91.02,
          95.64,
          98.4,
        ],
      },
      {
        828: [
          "0391",
          0.099,
          520000,
          70.4,
          "0.099",
          "0.095",
          "0.096",
          "0.096",
          "0.097",
          5.072,
          5.864,
          35.38,
          0,
          60.94,
          70.74,
          83.38,
        ],
      },
      {
        829: [
          "0042",
          0.66,
          426000,
          70.3,
          "0.674",
          "0.674",
          "0.65",
          "0.658",
          "0.653",
          27.914,
          1.702,
          0.37,
          5.34,
          35.08,
          44.45,
          59.12,
        ],
      },
      {
        830: [
          "0011",
          155.3,
          1417190,
          70.3,
          "150.21",
          "145.33",
          "146.102",
          "148.551",
          "145.447",
          21953,
          2969,
          67.16,
          0.27,
          83.31,
          89.27,
          93.71,
        ],
      },
      {
        831: [
          "1055",
          4.99,
          9253840,
          70.2,
          "4.856",
          "4.631",
          "4.587",
          "4.805",
          "4.559",
          4583,
          213.3,
          59.06,
          8.59,
          77.45,
          85.21,
          93.49,
        ],
      },
      {
        832: [
          "8411",
          0.192,
          60000,
          70.2,
          "0.194",
          "0.192",
          "0.183",
          "0.183",
          "0.183",
          1.141,
          1.92,
          0,
          0,
          80.61,
          84.6,
          91,
        ],
      },
      {
        833: [
          "0374",
          2.84,
          0,
          70.2,
          "2.849",
          "2.977",
          "2.882",
          "2.864",
          "2.884",
          0,
          10.9,
          54.05,
          0,
          70.88,
          81.12,
          89.87,
        ],
      },
      {
        834: [
          "2183",
          4.5,
          0,
          70.1,
          "4.375",
          "4.039",
          "4.331",
          "4.438",
          "4.284",
          0,
          22,
          0.05,
          0,
          80.67,
          87.63,
          95.67,
        ],
      },
      {
        835: [
          "6069",
          7.57,
          2441500,
          70.1,
          "7.389",
          "7.559",
          "8.504",
          "7.978",
          "8.547",
          1801,
          76,
          7.27,
          9.31,
          62.46,
          78.22,
          93.38,
        ],
      },
      {
        836: [
          "1476",
          3,
          7000,
          70.1,
          "3.08",
          "2.992",
          "3.002",
          "2.984",
          "3.037",
          2.1,
          13.5,
          0.01,
          0,
          68.85,
          77.35,
          95.98,
        ],
      },
      {
        837: [
          "3199",
          127.6,
          920,
          70,
          "127.31",
          "126.41",
          "124.855",
          "124.339",
          "124.953",
          11.728,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        838: [
          "0778",
          8.09,
          1124550,
          70,
          "8.108",
          "8.07",
          "8.2",
          "8.131",
          "8.168",
          910,
          159.6,
          2.06,
          0,
          63.07,
          71.43,
          85.37,
        ],
      },
      {
        839: [
          "1283",
          1.64,
          1090000,
          70,
          "1.604",
          "1.564",
          "1.557",
          "1.577",
          "1.554",
          179,
          13.1,
          0,
          0,
          88.99,
          94.24,
          97.13,
        ],
      },
      {
        840: [
          "8296",
          0.173,
          2604000,
          69.9,
          "0.178",
          "0.191",
          "0.181",
          "0.175",
          "0.182",
          43.285,
          1.284,
          0.07,
          0,
          60.91,
          70.98,
          80.91,
        ],
      },
      {
        841: [
          "0576",
          7.03,
          4040570,
          69.9,
          "6.951",
          "6.995",
          "6.793",
          "6.81",
          "6.78",
          2842,
          100.7,
          0.64,
          7.72,
          68.51,
          83.1,
          92.29,
        ],
      },
      {
        842: [
          "1503",
          2.75,
          677000,
          69.9,
          "2.705",
          "2.639",
          "2.758",
          "2.746",
          "2.753",
          186,
          31,
          31.19,
          0,
          52.76,
          66.35,
          82.91,
        ],
      },
      {
        843: [
          "0035",
          2.87,
          1541970,
          69.8,
          "2.882",
          "2.608",
          "2.783",
          "2.792",
          "2.771",
          441,
          69.2,
          24.42,
          0.02,
          55.91,
          72.88,
          88.23,
        ],
      },
      {
        844: [
          "0112",
          0.89,
          0,
          69.8,
          "0.89",
          "0.89",
          "0.89",
          "0.89",
          "0.89",
          0,
          9.542,
          56.31,
          0,
          92.21,
          95.34,
          97.46,
        ],
      },
      {
        845: [
          "0460",
          1.62,
          72710000,
          69.7,
          "1.557",
          "1.518",
          "2.117",
          "2.306",
          "2.025",
          12087,
          151.1,
          0.16,
          6.75,
          73.09,
          78.84,
          86.67,
        ],
      },
      {
        846: [
          "1741",
          1.25,
          100000,
          69.7,
          "1.125",
          "1.032",
          "1.149",
          "1.222",
          "1.172",
          11.36,
          10,
          80.14,
          0,
          84.92,
          88.89,
          95.64,
        ],
      },
      {
        847: [
          "0603",
          0.47,
          19147500,
          69.7,
          "0.452",
          "0.437",
          "0.45",
          "0.443",
          "0.458",
          863,
          27.1,
          3.83,
          0,
          49.44,
          59.4,
          73.61,
        ],
      },
      {
        848: [
          "3122",
          192.65,
          0,
          69.6,
          "192.63",
          "192.181",
          "189.642",
          "188.97",
          "189.767",
          0,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        849: [
          "0287",
          9.2,
          0,
          69.6,
          "9.2",
          "9.004",
          "9.157",
          "9.112",
          "9.149",
          0,
          3.68,
          92.3,
          0,
          95.58,
          96.92,
          98.15,
        ],
      },
      {
        850: [
          "0867",
          14.12,
          4063970,
          69.6,
          "13.566",
          "12.982",
          "15.426",
          "16.055",
          "15.062",
          5729,
          346.9,
          21.14,
          3.01,
          61.77,
          79.39,
          92.62,
        ],
      },
      {
        851: [
          "1547",
          0.295,
          0,
          69.5,
          "0.298",
          "0.289",
          "0.3",
          "0.296",
          "0.301",
          0,
          2.36,
          0.01,
          0,
          88.19,
          91.12,
          94.22,
        ],
      },
      {
        852: [
          "0243",
          0.35,
          54000,
          69.5,
          "0.348",
          "0.361",
          "0.437",
          "0.432",
          "0.431",
          1.926,
          0.79,
          16.99,
          0,
          32.33,
          42.43,
          58.2,
        ],
      },
      {
        853: [
          "9813",
          16.85,
          0,
          69.5,
          "16.852",
          "16.712",
          "-",
          "-",
          "-",
          0,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        854: [
          "9999",
          162.8,
          6612840,
          69.4,
          "157.42",
          "164.074",
          "155.439",
          "159.177",
          "154.587",
          107000,
          5624.9,
          40.69,
          0,
          91.84,
          97.17,
          99.35,
        ],
      },
      {
        855: [
          "0881",
          62.3,
          5405610,
          69.4,
          "60.24",
          "63.848",
          "66.063",
          "64.308",
          "66.262",
          33324,
          1504.5,
          20.95,
          3.41,
          77.54,
          90.32,
          98.51,
        ],
      },
      {
        856: [
          "3348",
          1.01,
          34000,
          69.4,
          "1.016",
          "1.032",
          "1.032",
          "1.017",
          "1.027",
          3.434,
          5.05,
          75.01,
          0,
          91.6,
          94.49,
          97.61,
        ],
      },
      {
        857: [
          "0090",
          0.8,
          44000,
          69.3,
          "0.798",
          "0.779",
          "0.868",
          "0.887",
          "0.866",
          3.496,
          3.669,
          0.19,
          0,
          77.81,
          83.34,
          89.75,
        ],
      },
      {
        858: [
          "0701",
          0.4,
          0,
          69.3,
          "0.4",
          "0.399",
          "0.4",
          "0.397",
          "0.398",
          0,
          7.615,
          74.04,
          0,
          77.72,
          80.28,
          85.29,
        ],
      },
      {
        859: [
          "0945",
          159.2,
          14937,
          69.2,
          "153.07",
          "149.774",
          "151.281",
          "154.623",
          "151.207",
          237,
          3092.6,
          98.85,
          0,
          99.28,
          99.44,
          99.66,
        ],
      },
      {
        860: [
          "1820",
          6.25,
          18000,
          69.2,
          "6.252",
          "6.258",
          "6.221",
          "6.177",
          "6.221",
          11.25,
          18.7,
          65.22,
          0,
          81.65,
          91.59,
          99.33,
        ],
      },
      {
        861: [
          "0126",
          0.61,
          774000,
          69.2,
          "0.664",
          "0.642",
          "0.701",
          "0.659",
          "0.71",
          47.176,
          9.585,
          45.16,
          0,
          61.46,
          69.29,
          81.11,
        ],
      },
      {
        862: [
          "0505",
          1.15,
          0,
          69.1,
          "1.137",
          "1.11",
          "1.098",
          "1.12",
          "1.099",
          0,
          10.3,
          0.11,
          0,
          65.28,
          77.88,
          92.94,
        ],
      },
      {
        863: [
          "2285",
          57.5,
          900800,
          69.1,
          "57.44",
          "-",
          "-",
          "-",
          "-",
          5197,
          275.6,
          82.75,
          0,
          91.51,
          94.67,
          98.66,
        ],
      },
      {
        864: [
          "1224",
          1.85,
          22403,
          69.1,
          "1.824",
          "1.765",
          "1.815",
          "1.825",
          "1.809",
          4.15,
          71.8,
          0.07,
          0,
          17.13,
          20.73,
          25.46,
        ],
      },
      {
        865: [
          "2840",
          1329,
          17385,
          69,
          "1317.75",
          "1316.1",
          "1306.847",
          "1309.135",
          "1306.983",
          2309,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        866: [
          "8073",
          0.395,
          248000,
          69,
          "0.424",
          "0.441",
          "0.449",
          "0.418",
          "0.463",
          11.75,
          2.054,
          76.94,
          0,
          86.28,
          91,
          96.45,
        ],
      },
      {
        867: [
          "7299",
          7.745,
          1541000,
          69,
          "7.619",
          "7.633",
          "7.598",
          "7.663",
          "7.586",
          1192,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        868: [
          "8065",
          0.33,
          110000,
          68.9,
          "0.34",
          "0.341",
          "0.361",
          "0.364",
          "0.362",
          3.577,
          1.328,
          73.56,
          0,
          80.11,
          85.63,
          91.74,
        ],
      },
      {
        869: [
          "2888",
          53.25,
          1289260,
          68.9,
          "49.59",
          "46.682",
          "48.27",
          "49.757",
          "48.107",
          6832,
          1639.6,
          90.43,
          0,
          94.21,
          95.99,
          97.73,
        ],
      },
      {
        870: [
          "0831",
          0.74,
          64000,
          68.9,
          "0.74",
          "0.755",
          "0.763",
          "0.752",
          "0.768",
          4.71,
          5.744,
          44.04,
          0,
          67.73,
          81.06,
          91.45,
        ],
      },
      {
        871: [
          "6113",
          1.18,
          36000,
          68.8,
          "1.191",
          "1.281",
          "1.264",
          "1.217",
          "1.278",
          4.248,
          4.72,
          75.01,
          0,
          92.51,
          96.35,
          98.17,
        ],
      },
      {
        872: [
          "2175",
          3.96,
          3109000,
          68.8,
          "3.893",
          "4.153",
          "-",
          "-",
          "-",
          1227,
          20,
          50.65,
          0,
          77.6,
          89.79,
          98.91,
        ],
      },
      {
        873: [
          "2371",
          0.076,
          13116000,
          68.7,
          "0.077",
          "0.093",
          "0.107",
          "0.111",
          "0.106",
          100,
          5.132,
          10.67,
          0,
          35.28,
          51.84,
          75.71,
        ],
      },
      {
        874: [
          "8186",
          0.9,
          0,
          68.7,
          "0.9",
          "0.898",
          "0.823",
          "0.959",
          "-",
          0,
          0.14,
          39.91,
          0,
          58.21,
          67.02,
          79.16,
        ],
      },
      {
        875: [
          "1418",
          0.235,
          0,
          68.7,
          "0.235",
          "0.258",
          "0.273",
          "0.248",
          "0.276",
          0,
          4.112,
          30.02,
          0,
          77.74,
          85.78,
          92.55,
        ],
      },
      {
        876: [
          "0225",
          11.76,
          0,
          68.6,
          "11.76",
          "11.711",
          "11.568",
          "11.605",
          "11.563",
          0,
          12.9,
          85,
          0,
          89.24,
          92.1,
          96.14,
        ],
      },
      {
        877: [
          "1749",
          0.65,
          202000,
          68.6,
          "0.619",
          "0.718",
          "0.791",
          "0.712",
          "0.809",
          13.711,
          0.217,
          0.07,
          0,
          38.27,
          52.59,
          69.15,
        ],
      },
      {
        878: [
          "0595",
          0.54,
          310000,
          68.6,
          "0.526",
          "0.565",
          "0.597",
          "0.607",
          "0.592",
          16.78,
          4.907,
          43.96,
          0,
          63.94,
          72.17,
          82.38,
        ],
      },
      {
        879: [
          "3081",
          43.56,
          31330,
          68.5,
          "43.182",
          "43.179",
          "42.891",
          "42.968",
          "42.884",
          136,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        880: [
          "2810",
          73.74,
          0,
          68.5,
          "72.926",
          "72.481",
          "70.683",
          "70.863",
          "70.62",
          0,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        881: [
          "2849",
          63,
          2950,
          68.5,
          "62.486",
          "-",
          "-",
          "-",
          "-",
          18.558,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        882: [
          "1606",
          1.14,
          516000,
          68.4,
          "1.13",
          "1.083",
          "1.103",
          "1.115",
          "1.1",
          59.242,
          31.5,
          0.02,
          0.01,
          70.24,
          83.74,
          94.52,
        ],
      },
      {
        883: [
          "1316",
          9.81,
          3183780,
          68.4,
          "9.809",
          "9.89",
          "9.666",
          "9.591",
          "9.532",
          3140,
          246.2,
          0,
          7.62,
          84.1,
          90.83,
          97.24,
        ],
      },
      {
        884: [
          "0969",
          0.056,
          4000,
          68.3,
          "0.064",
          "0.063",
          "0.055",
          "0.054",
          "0.055",
          0,
          1.227,
          10.07,
          0,
          64.66,
          76.39,
          89.07,
        ],
      },
      {
        885: [
          "0371",
          3.14,
          6524100,
          68.3,
          "3.107",
          "2.948",
          "3.015",
          "3.023",
          "3.017",
          2041,
          318.2,
          40.71,
          3.46,
          70.11,
          78.19,
          90.35,
        ],
      },
      {
        886: [
          "2829",
          62.58,
          420,
          68.3,
          "62.382",
          "62.02",
          "-",
          "-",
          "-",
          2.629,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        887: [
          "1002",
          0.149,
          128000,
          68.2,
          "0.149",
          "0.142",
          "0.143",
          "0.144",
          "0.144",
          1.907,
          3.438,
          47.15,
          0,
          67.8,
          79.25,
          91.39,
        ],
      },
      {
        888: [
          "1996",
          2.48,
          1475000,
          68.2,
          "2.403",
          "2.215",
          "2.388",
          "2.456",
          "2.373",
          370,
          82.7,
          68.37,
          4.94,
          78.58,
          87.09,
          94.96,
        ],
      },
      {
        889: [
          "1358",
          0.92,
          74000,
          68.2,
          "0.987",
          "1.067",
          "1.166",
          "1.052",
          "1.188",
          6.889,
          14.4,
          24.68,
          0,
          70.33,
          80.47,
          89.11,
        ],
      },
      {
        890: [
          "1785",
          2.18,
          0,
          68.1,
          "2.159",
          "2.144",
          "2.175",
          "2.168",
          "2.179",
          0,
          9.943,
          0.02,
          0,
          83.17,
          97.92,
          99.5,
        ],
      },
      {
        891: [
          "8189",
          0.17,
          0,
          68.1,
          "0.17",
          "0.224",
          "0.248",
          "0.224",
          "0.252",
          0,
          2.035,
          11.09,
          0,
          45.9,
          60.79,
          81.17,
        ],
      },
      {
        892: [
          "2885",
          0.41,
          402000,
          68.1,
          "0.422",
          "0.42",
          "0.409",
          "0.416",
          "0.407",
          16.507,
          1.64,
          75.02,
          0,
          82.85,
          86.93,
          91.39,
        ],
      },
      {
        893: [
          "0389",
          0.089,
          1942000,
          68,
          "0.094",
          "0.098",
          "0.096",
          "0.091",
          "0.097",
          18.215,
          2.35,
          24.67,
          0,
          51.15,
          67.1,
          79.34,
        ],
      },
      {
        894: [
          "9810",
          9.455,
          0,
          68,
          "9.354",
          "9.291",
          "9.077",
          "9.105",
          "9.064",
          0,
          0,
          5.55,
          0,
          98.94,
          99.56,
          100,
        ],
      },
      {
        895: [
          "0188",
          0.385,
          20000,
          68,
          "0.403",
          "0.387",
          "0.376",
          "0.375",
          "0.376",
          0.77,
          2.77,
          49.67,
          0,
          68.39,
          76.49,
          86.09,
        ],
      },
      {
        896: [
          "6837",
          7.15,
          10850000,
          67.9,
          "6.967",
          "6.835",
          "6.906",
          "6.947",
          "6.933",
          7742,
          243.7,
          0.02,
          25.99,
          49.45,
          64.63,
          81.32,
        ],
      },
      {
        897: [
          "3080",
          133.45,
          700,
          67.9,
          "133.595",
          "134.449",
          "132.867",
          "132.218",
          "132.918",
          9.338,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        898: [
          "0323",
          3.18,
          10004300,
          67.8,
          "3.055",
          "2.936",
          "3.428",
          "3.459",
          "3.439",
          3178,
          55.1,
          0.85,
          7.88,
          55.51,
          64.69,
          79.03,
        ],
      },
      {
        899: [
          "8052",
          0.04,
          0,
          67.8,
          "0.036",
          "0.039",
          "0.048",
          "0.046",
          "0.048",
          0,
          0.902,
          70.9,
          0,
          78.42,
          84.77,
          91.01,
        ],
      },
      {
        900: [
          "0713",
          0.88,
          150000,
          67.8,
          "0.87",
          "0.88",
          "0.832",
          "0.839",
          "0.832",
          12.86,
          6.83,
          53.5,
          0,
          65.56,
          70.9,
          80.55,
        ],
      },
      {
        901: [
          "3326",
          0.285,
          3000,
          67.7,
          "0.29",
          "0.286",
          "0.294",
          "0.303",
          "0.291",
          0,
          3.848,
          0.18,
          0,
          86.5,
          91.12,
          95.19,
        ],
      },
      {
        902: [
          "0998",
          3.68,
          27977500,
          67.7,
          "3.504",
          "3.436",
          "3.557",
          "3.7",
          "3.527",
          10249,
          547.6,
          18.47,
          18.96,
          52.26,
          71.71,
          88.02,
        ],
      },
      {
        903: [
          "0177",
          8.53,
          1754130,
          67.7,
          "8.229",
          "7.814",
          "8.108",
          "8.407",
          "8.023",
          1487,
          104.2,
          0.35,
          11.46,
          77.06,
          88.85,
          94.85,
        ],
      },
      {
        904: [
          "0230",
          0.85,
          138000,
          67.6,
          "0.84",
          "0.8",
          "0.804",
          "0.817",
          "0.803",
          11.73,
          28.4,
          47.42,
          0,
          79.34,
          87.2,
          92.44,
        ],
      },
      {
        905: [
          "0715",
          0.142,
          152000,
          67.6,
          "0.152",
          "0.139",
          "0.146",
          "0.153",
          "0.146",
          2.041,
          22.9,
          0.59,
          0,
          85.69,
          91.29,
          95.38,
        ],
      },
      {
        906: [
          "9081",
          5.585,
          0,
          67.6,
          "5.54",
          "5.54",
          "5.511",
          "5.523",
          "5.509",
          0,
          0,
          99.46,
          0,
          99.74,
          99.9,
          100,
        ],
      },
      {
        907: [
          "2607",
          15.28,
          2197140,
          67.5,
          "15.262",
          "14.202",
          "15.24",
          "15.61",
          "15.125",
          3327,
          140.4,
          0.1,
          13.79,
          61.35,
          74.74,
          87.4,
        ],
      },
      {
        908: [
          "0869",
          0.5,
          108000,
          67.5,
          "0.496",
          "0.498",
          "0.578",
          "0.572",
          "0.569",
          5.39,
          5.9,
          51.61,
          0,
          64.29,
          71.54,
          82.12,
        ],
      },
      {
        909: [
          "8155",
          0.011,
          0,
          67.5,
          "0.011",
          "0.011",
          "0.011",
          "0.011",
          "0.011",
          0,
          1.23,
          74.79,
          0,
          93.69,
          95.69,
          97.21,
        ],
      },
      {
        910: [
          "1114",
          7.3,
          0,
          67.4,
          "7.3",
          "7.3",
          "7.3",
          "7.296",
          "7.3",
          0,
          368.3,
          42.52,
          1.96,
          72.21,
          82.96,
          93.87,
        ],
      },
      {
        911: [
          "6818",
          2.99,
          19627100,
          67.4,
          "2.862",
          "2.77",
          "2.81",
          "2.936",
          "2.791",
          5833,
          379,
          55.71,
          9.46,
          72.02,
          82.42,
          95.89,
        ],
      },
      {
        912: [
          "0142",
          2.88,
          2172000,
          67.3,
          "2.862",
          "2.903",
          "2.83",
          "2.786",
          "2.832",
          627,
          123.3,
          0.89,
          0.37,
          78.93,
          87.96,
          95.88,
        ],
      },
      {
        913: [
          "1883",
          2.7,
          2978000,
          67.3,
          "2.687",
          "2.663",
          "2.635",
          "2.663",
          "2.643",
          806,
          99.4,
          58.35,
          0.1,
          77.92,
          82.74,
          89.53,
        ],
      },
      {
        914: [
          "0528",
          1.26,
          4000,
          67.3,
          "1.205",
          "1.181",
          "1.231",
          "1.239",
          "1.234",
          0.496,
          7.934,
          31.88,
          0,
          75.24,
          82.69,
          90.08,
        ],
      },
      {
        915: [
          "9829",
          8.005,
          0,
          67.2,
          "7.992",
          "7.95",
          "-",
          "-",
          "-",
          0,
          0,
          100,
          0,
          100,
          200,
          200,
        ],
      },
      {
        916: [
          "1395",
          0.12,
          10000,
          67.2,
          "0.126",
          "0.123",
          "0.127",
          "0.125",
          "0.127",
          0.12,
          1.329,
          34.7,
          0,
          73.99,
          79.72,
          86.87,
        ],
      },
      {
        917: [
          "1140",
          0.8,
          5160000,
          67.2,
          "0.753",
          "0.725",
          "0.752",
          "0.769",
          "0.754",
          399,
          32.4,
          42.32,
          2.09,
          65.06,
          75.46,
          86.54,
        ],
      },
      {
        918: [
          "0143",
          0.05,
          0,
          67.1,
          "0.05",
          "0.05",
          "0.05",
          "0.05",
          "0.05",
          0,
          3.874,
          7.68,
          0,
          68.13,
          74.59,
          85.28,
        ],
      },
      {
        919: [
          "0386",
          4.03,
          142259000,
          67.1,
          "3.832",
          "3.714",
          "3.783",
          "3.865",
          "3.765",
          57360,
          1028.1,
          0.5,
          3.61,
          65.35,
          77.53,
          86.45,
        ],
      },
      {
        920: [
          "0669",
          141.5,
          5369360,
          67.1,
          "147.81",
          "160.076",
          "153.945",
          "150.407",
          "154.613",
          75610,
          2595.7,
          8.27,
          1.13,
          82.13,
          92.24,
          96.48,
        ],
      },
      {
        921: [
          "8353",
          0.41,
          0,
          67,
          "0.414",
          "0.416",
          "0.425",
          "0.394",
          "0.42",
          0,
          1.652,
          55.06,
          0,
          70.8,
          80.93,
          94.39,
        ],
      },
      {
        922: [
          "0617",
          2.1,
          0,
          67,
          "2.063",
          "2.044",
          "2.086",
          "2.097",
          "2.077",
          0,
          23.4,
          53.08,
          0,
          87.52,
          91.45,
          94.86,
        ],
      },
      {
        923: [
          "3382",
          0.65,
          1290000,
          67,
          "0.63",
          "0.625",
          "0.63",
          "0.635",
          "0.631",
          83.542,
          40,
          74.81,
          2.33,
          81.9,
          86.1,
          90.32,
        ],
      },
      {
        924: [
          "1665",
          1.21,
          235481,
          66.9,
          "1.245",
          "1.162",
          "1.108",
          "1.111",
          "1.111",
          28.007,
          29,
          0,
          0,
          84.46,
          90.1,
          96.29,
        ],
      },
      {
        925: [
          "0787",
          0.189,
          0,
          66.9,
          "0.189",
          "0.189",
          "0.189",
          "0.189",
          "0.189",
          0,
          1.944,
          32.44,
          5.89,
          49.84,
          57.56,
          71.81,
        ],
      },
      {
        926: [
          "0194",
          7.86,
          0,
          66.8,
          "7.819",
          "7.8",
          "7.997",
          "7.992",
          "7.969",
          0,
          29.7,
          58.33,
          0,
          70.98,
          77.23,
          85.48,
        ],
      },
      {
        927: [
          "3145",
          11.07,
          1397,
          66.8,
          "10.93",
          "10.713",
          "10.893",
          "10.975",
          "10.882",
          1.545,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        928: [
          "0797",
          2.58,
          2000,
          66.8,
          "2.577",
          "2.408",
          "2.479",
          "2.486",
          "2.483",
          0.518,
          71,
          6.45,
          0,
          58.92,
          72,
          93.2,
        ],
      },
      {
        929: [
          "0629",
          0.3,
          0,
          66.7,
          "0.302",
          "0.293",
          "0.297",
          "0.306",
          "0.294",
          0,
          3.506,
          0.06,
          0,
          77.08,
          84.45,
          91.65,
        ],
      },
      {
        930: [
          "8033",
          0.148,
          0,
          66.7,
          "0.149",
          "0.156",
          "0.159",
          "0.16",
          "0.158",
          0,
          0.912,
          4.83,
          0,
          68.71,
          75.79,
          84.71,
        ],
      },
      {
        931: [
          "1771",
          2.19,
          51000,
          66.7,
          "2.245",
          "2.258",
          "2.471",
          "2.351",
          "2.486",
          11.172,
          13.1,
          0.08,
          0,
          83.81,
          90.79,
          97.78,
        ],
      },
      {
        932: [
          "1715",
          0.172,
          600000,
          66.6,
          "0.176",
          "0.159",
          "0.163",
          "0.171",
          "0.162",
          10.506,
          2.58,
          52.86,
          0,
          67.74,
          78.82,
          92.38,
        ],
      },
      {
        933: [
          "8476",
          0.86,
          246000,
          66.6,
          "0.848",
          "0.869",
          "0.834",
          "0.825",
          "0.834",
          21.2,
          2.408,
          71.45,
          0,
          77.38,
          80.98,
          88.48,
        ],
      },
      {
        934: [
          "6812",
          0.445,
          145000,
          66.6,
          "0.431",
          "0.437",
          "0.461",
          "0.444",
          "0.462",
          6.452,
          2.67,
          78.85,
          0,
          84.49,
          88.1,
          92.99,
        ],
      },
      {
        935: [
          "6163",
          0.7,
          0,
          66.5,
          "0.69",
          "0.675",
          "0.64",
          "0.691",
          "0.634",
          0,
          1.76,
          0.04,
          0,
          83.4,
          92.82,
          97.32,
        ],
      },
      {
        936: [
          "6866",
          0.395,
          4000,
          66.5,
          "0.387",
          "0.367",
          "0.384",
          "0.377",
          "-",
          0.154,
          1.185,
          0.08,
          0,
          51.13,
          60.29,
          74,
        ],
      },
      {
        937: [
          "1763",
          23.85,
          128800,
          66.5,
          "22.655",
          "22.348",
          "24.882",
          "25.769",
          "24.554",
          308,
          19,
          0.05,
          0,
          58.35,
          69.9,
          83.57,
        ],
      },
      {
        938: [
          "8391",
          0.8,
          0,
          66.4,
          "0.774",
          "0.8",
          "0.955",
          "0.927",
          "0.939",
          0,
          4.862,
          27.48,
          0,
          81.6,
          85.72,
          91.85,
        ],
      },
      {
        939: [
          "0905",
          0.126,
          0,
          66.4,
          "0.139",
          "0.148",
          "0.159",
          "0.147",
          "0.161",
          0,
          0.882,
          0.24,
          0,
          83.42,
          87.2,
          92.63,
        ],
      },
      {
        940: [
          "0939",
          5.89,
          271014000,
          66.3,
          "5.642",
          "5.332",
          "5.543",
          "5.745",
          "5.509",
          158000,
          14160.5,
          60.02,
          10.45,
          81.67,
          88.81,
          95.01,
        ],
      },
      {
        941: [
          "9125",
          7.935,
          0,
          66.3,
          "7.925",
          "7.887",
          "-",
          "-",
          "-",
          0,
          0,
          99.93,
          0,
          100,
          199.93,
          199.93,
        ],
      },
      {
        942: [
          "0586",
          37.8,
          3449810,
          66.3,
          "37.95",
          "38.074",
          "34.964",
          "35.323",
          "35.067",
          13075,
          690.5,
          0.01,
          10.08,
          57.48,
          74.18,
          90.54,
        ],
      },
      {
        943: [
          "2257",
          79,
          131250,
          66.2,
          "84.61",
          "-",
          "-",
          "-",
          "-",
          1023,
          69.5,
          91.44,
          0,
          96.82,
          98.33,
          99.61,
        ],
      },
      {
        944: [
          "2182",
          0.405,
          236000,
          66.2,
          "0.418",
          "0.425",
          "0.416",
          "0.417",
          "0.418",
          9.542,
          2.511,
          75.04,
          0,
          83.72,
          90.17,
          94.46,
        ],
      },
      {
        945: [
          "3306",
          12.38,
          578000,
          66.2,
          "12.186",
          "12.804",
          "14.636",
          "14.557",
          "14.747",
          718,
          64.2,
          0,
          0.87,
          87.63,
          92.16,
          96.09,
        ],
      },
      {
        946: [
          "1884",
          0.275,
          84000,
          66.1,
          "0.275",
          "0.286",
          "0.295",
          "0.305",
          "0.293",
          2.33,
          1.512,
          0.07,
          0,
          69.7,
          77.93,
          88.1,
        ],
      },
      {
        947: [
          "3125",
          61.82,
          0,
          66.1,
          "61.804",
          "61.519",
          "-",
          "-",
          "-",
          0,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        948: [
          "2137",
          25.3,
          4255020,
          66.1,
          "29.725",
          "34.274",
          "-",
          "-",
          "-",
          10760,
          182.2,
          9.59,
          0.81,
          62.82,
          79.3,
          94.47,
        ],
      },
      {
        949: [
          "6836",
          1.52,
          2134000,
          66,
          "1.469",
          "1.523",
          "1.537",
          "1.572",
          "1.53",
          314,
          15,
          6.98,
          0,
          41.39,
          56.6,
          83.68,
        ],
      },
      {
        950: [
          "1933",
          0.36,
          0,
          66,
          "0.357",
          "0.372",
          "0.377",
          "0.366",
          "0.375",
          0,
          1.814,
          60.45,
          0,
          80.65,
          88.54,
          93.95,
        ],
      },
      {
        951: [
          "8007",
          0.25,
          8800010,
          66,
          "0.256",
          "0.298",
          "0.278",
          "0.261",
          "0.281",
          224,
          1.14,
          0.33,
          0,
          45.18,
          54.71,
          73.22,
        ],
      },
      {
        952: [
          "0092",
          0.106,
          7794400,
          65.9,
          "0.104",
          "0.109",
          "0.102",
          "0.105",
          "0.102",
          79.87,
          2.899,
          1.2,
          0,
          52.65,
          59.61,
          73.56,
        ],
      },
      {
        953: [
          "3132",
          16.13,
          55100,
          65.9,
          "16.21",
          "-",
          "-",
          "-",
          "-",
          88.738,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        954: [
          "0302",
          3.22,
          21872000,
          65.8,
          "3.092",
          "3.229",
          "3.509",
          "3.455",
          "3.503",
          7185,
          89.1,
          0.82,
          6.72,
          48.06,
          62.86,
          84.08,
        ],
      },
      {
        955: [
          "3147",
          13.35,
          279800,
          65.8,
          "13.686",
          "14.522",
          "14.206",
          "13.836",
          "14.194",
          375,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        956: [
          "3398",
          0.325,
          0,
          65.8,
          "0.314",
          "0.332",
          "0.327",
          "0.33",
          "0.329",
          0,
          6.824,
          72.15,
          0,
          82.23,
          89.74,
          93.93,
        ],
      },
      {
        957: [
          "0552",
          3.9,
          10587600,
          65.7,
          "3.748",
          "3.756",
          "3.815",
          "3.711",
          "3.841",
          4141,
          93.2,
          0.38,
          4.83,
          67.99,
          73.38,
          84.19,
        ],
      },
      {
        958: [
          "0711",
          0.61,
          456000,
          65.7,
          "0.609",
          "0.604",
          "0.598",
          "0.597",
          "0.598",
          27.364,
          11,
          9.21,
          0,
          81.16,
          87.4,
          92.25,
        ],
      },
      {
        959: [
          "2265",
          0.92,
          485000,
          65.7,
          "0.936",
          "-",
          "-",
          "-",
          "-",
          44.595,
          9.2,
          77.98,
          0,
          93.6,
          96.19,
          98.02,
        ],
      },
      {
        960: [
          "0334",
          0.51,
          160000,
          65.6,
          "0.51",
          "0.534",
          "0.574",
          "0.556",
          "0.576",
          8.08,
          10.7,
          35.91,
          0,
          70.48,
          82.59,
          88.39,
        ],
      },
      {
        961: [
          "0236",
          0.97,
          0,
          65.6,
          "0.992",
          "1.024",
          "1.024",
          "1.014",
          "1.028",
          0,
          3.624,
          75.74,
          0,
          87.47,
          89.46,
          92.9,
        ],
      },
      {
        962: [
          "8450",
          0.065,
          0,
          65.6,
          "0.065",
          "0.067",
          "0.069",
          "0.067",
          "0.069",
          0,
          0.65,
          55.06,
          0,
          78.59,
          82.34,
          89.06,
        ],
      },
      {
        963: [
          "6900",
          2.47,
          832000,
          65.5,
          "2.481",
          "2.281",
          "2.449",
          "2.475",
          "2.441",
          212,
          51.2,
          0,
          0,
          77.25,
          84.15,
          94.46,
        ],
      },
      {
        964: [
          "1832",
          0.9,
          0,
          65.5,
          "0.863",
          "0.939",
          "0.966",
          "0.941",
          "0.963",
          0,
          3.24,
          80,
          0,
          90.69,
          94.72,
          98.07,
        ],
      },
      {
        965: [
          "1925",
          1.23,
          156000,
          65.4,
          "1.185",
          "1.156",
          "1.151",
          "1.163",
          "1.156",
          18.98,
          4.982,
          54.18,
          0,
          86.61,
          92.1,
          97.71,
        ],
      },
      {
        966: [
          "0560",
          1.01,
          14000,
          65.4,
          "0.984",
          "0.962",
          "0.991",
          "1.018",
          "0.992",
          1.414,
          11.3,
          0.5,
          0,
          75.22,
          80.36,
          88.23,
        ],
      },
      {
        967: [
          "1955",
          1.02,
          0,
          65.4,
          "1.011",
          "1.129",
          "1.238",
          "1.178",
          "1.239",
          0,
          5.1,
          0,
          0,
          87.01,
          92.49,
          97.71,
        ],
      },
      {
        968: [
          "3023",
          139.2,
          400,
          65.3,
          "140.875",
          "144.493",
          "142.839",
          "141.303",
          "143.018",
          5.568,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        969: [
          "1627",
          0.415,
          20000,
          65.3,
          "0.416",
          "0.414",
          "0.404",
          "0.406",
          "0.404",
          0.83,
          8.3,
          75.39,
          0,
          83.9,
          87.38,
          94.44,
        ],
      },
      {
        970: [
          "0202",
          0.186,
          43000,
          65.3,
          "0.186",
          "0.184",
          "0.185",
          "0.185",
          "0.186",
          0.793,
          13.5,
          28.2,
          0,
          71.98,
          78.08,
          87.92,
        ],
      },
      {
        971: [
          "0286",
          0.72,
          3886000,
          65.2,
          "0.76",
          "0.895",
          "1.019",
          "0.917",
          "1.005",
          280,
          30.8,
          2.92,
          0,
          41.85,
          49.16,
          63.8,
        ],
      },
      {
        972: [
          "2019",
          2.86,
          624000,
          65.2,
          "2.75",
          "2.588",
          "2.768",
          "2.84",
          "2.761",
          177,
          77.2,
          7.11,
          3.06,
          83.88,
          90.17,
          98.51,
        ],
      },
      {
        973: [
          "8162",
          0.29,
          10000,
          65.2,
          "0.287",
          "0.279",
          "0.267",
          "0.271",
          "0.268",
          0.29,
          2.405,
          16.36,
          0,
          62.8,
          70.93,
          85.59,
        ],
      },
      {
        974: [
          "1203",
          0.69,
          398000,
          65.1,
          "0.693",
          "0.674",
          "0.69",
          "0.69",
          "0.692",
          27.208,
          6.262,
          56.27,
          0,
          65.78,
          73.63,
          83.87,
        ],
      },
      {
        975: [
          "2117",
          4.6,
          116000,
          65.1,
          "4.208",
          "4.154",
          "4.33",
          "4.533",
          "4.353",
          52.858,
          62.7,
          56.52,
          0,
          74.72,
          83.88,
          93.94,
        ],
      },
      {
        976: [
          "0363",
          11.44,
          1386000,
          65.1,
          "11.524",
          "11.367",
          "11.633",
          "11.688",
          "11.654",
          1588,
          124.3,
          17.27,
          2.66,
          64.76,
          71.17,
          83.21,
        ],
      },
      {
        977: [
          "0037",
          0.134,
          4000,
          65,
          "0.138",
          "0.135",
          "0.133",
          "0.135",
          "0.133",
          0,
          0.982,
          32.4,
          0,
          56.86,
          66.04,
          76.83,
        ],
      },
      {
        978: [
          "8532",
          0.079,
          0,
          65,
          "0.089",
          "0.096",
          "0.09",
          "0.086",
          "0.091",
          0,
          0.632,
          75.01,
          0,
          83.7,
          87.82,
          93.15,
        ],
      },
      {
        979: [
          "3877",
          1.21,
          3650000,
          64.9,
          "1.152",
          "1.087",
          "1.241",
          "1.264",
          "1.221",
          439,
          74.2,
          74.62,
          5.98,
          89.17,
          94.31,
          97.83,
        ],
      },
      {
        980: [
          "1513",
          30.1,
          1092420,
          64.9,
          "29.83",
          "27.06",
          "29.456",
          "31.223",
          "28.943",
          3322,
          94.2,
          0.07,
          12.82,
          61.52,
          73.12,
          89.5,
        ],
      },
      {
        981: [
          "8283",
          0.154,
          55000,
          64.9,
          "0.16",
          "0.184",
          "0.167",
          "0.163",
          "0.166",
          0.849,
          3.08,
          8.3,
          0,
          74.5,
          80.53,
          89.32,
        ],
      },
      {
        982: [
          "0338",
          1.88,
          15038000,
          64.8,
          "1.833",
          "1.808",
          "1.817",
          "1.849",
          "1.814",
          2842,
          65.7,
          1.18,
          17.02,
          47.38,
          60.59,
          78.37,
        ],
      },
      {
        983: [
          "2293",
          0.86,
          48000,
          64.8,
          "0.838",
          "0.797",
          "0.85",
          "0.837",
          "0.867",
          4.128,
          3.44,
          0.03,
          0,
          79.64,
          92.48,
          95.01,
        ],
      },
      {
        984: [
          "0440",
          24.9,
          140800,
          64.8,
          "24.355",
          "24.119",
          "24.569",
          "24.963",
          "24.502",
          350,
          79.5,
          10.81,
          0.43,
          69.83,
          78.2,
          88.62,
        ],
      },
      {
        985: [
          "3011",
          8028.75,
          0,
          64.7,
          "8032.515",
          "8028.017",
          "8010.264",
          "8003.561",
          "8011.667",
          0,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        986: [
          "0120",
          1.2,
          914000,
          64.7,
          "1.455",
          "1.304",
          "1.221",
          "1.211",
          "1.235",
          110,
          76.7,
          72.1,
          0,
          85.85,
          90.29,
          96.66,
        ],
      },
      {
        987: [
          "1122",
          1.62,
          569882,
          64.7,
          "1.596",
          "1.636",
          "1.685",
          "1.693",
          "1.689",
          91.807,
          20,
          39.29,
          0,
          63.45,
          70.99,
          81.94,
        ],
      },
      {
        988: [
          "0709",
          1.48,
          312000,
          64.6,
          "1.485",
          "1.503",
          "1.557",
          "1.577",
          "1.552",
          46.182,
          23.3,
          2.61,
          0.05,
          58.14,
          67.1,
          77.69,
        ],
      },
      {
        989: [
          "2039",
          14.86,
          524024,
          64.6,
          "14.41",
          "14.387",
          "15.868",
          "15.531",
          "15.809",
          776,
          306,
          0.01,
          12.09,
          86.75,
          92.04,
          97.66,
        ],
      },
      {
        990: [
          "8187",
          0.265,
          23140000,
          64.6,
          "0.174",
          "0.244",
          "0.26",
          "0.275",
          "0.267",
          602,
          1.329,
          0.1,
          0,
          23.55,
          36.41,
          56.55,
        ],
      },
      {
        991: [
          "8657",
          0.96,
          0,
          64.5,
          "0.902",
          "0.945",
          "0.972",
          "1.002",
          "0.958",
          0,
          3.84,
          28.59,
          0,
          82.24,
          95.24,
          98.55,
        ],
      },
      {
        992: [
          "2030",
          2.98,
          165000,
          64.5,
          "2.832",
          "3.0",
          "3.269",
          "3.156",
          "3.259",
          50.17,
          19.9,
          0.01,
          0,
          66.41,
          80.07,
          93.6,
        ],
      },
      {
        993: [
          "9807",
          8.045,
          4900,
          64.4,
          "8.245",
          "8.44",
          "8.362",
          "8.267",
          "8.362",
          30.897,
          0,
          75.11,
          0,
          98.08,
          99.3,
          99.94,
        ],
      },
      {
        994: [
          "3096",
          800.1,
          0,
          64.4,
          "800.46",
          "799.969",
          "798.742",
          "798.445",
          "798.827",
          0,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        995: [
          "0180",
          0.435,
          0,
          64.4,
          "0.438",
          "0.433",
          "0.434",
          "0.434",
          "0.436",
          0,
          4.135,
          34.4,
          0,
          63.87,
          80.37,
          90.54,
        ],
      },
      {
        996: [
          "1027",
          0.5,
          0,
          64.3,
          "0.555",
          "0.6",
          "0.659",
          "0.664",
          "0.655",
          0,
          1.514,
          41.26,
          0,
          63.55,
          75.05,
          87.68,
        ],
      },
      {
        997: [
          "8023",
          0.44,
          0,
          64.3,
          "0.445",
          "0.427",
          "0.427",
          "0.427",
          "0.427",
          0,
          2.64,
          62.64,
          0,
          95.15,
          96.41,
          97.99,
        ],
      },
      {
        998: [
          "0185",
          4.92,
          2296000,
          64.3,
          "4.491",
          "4.012",
          "5.001",
          "5.103",
          "4.97",
          1167,
          94.1,
          0.17,
          0,
          76.03,
          86.63,
          95.25,
        ],
      },
      {
        999: [
          "1333",
          1.68,
          0,
          64.2,
          "1.68",
          "1.68",
          "1.7",
          "1.751",
          "1.697",
          0,
          91.5,
          71.69,
          6.59,
          80.77,
          86.84,
          93.58,
        ],
      },
      {
        1000: [
          "0521",
          0.085,
          280000,
          64.2,
          "0.084",
          "0.09",
          "0.086",
          "0.088",
          "0.087",
          2.333,
          9.69,
          9.91,
          0,
          58.67,
          66.81,
          81.29,
        ],
      },
      {
        1001: [
          "0900",
          5.07,
          94000,
          64.2,
          "5.013",
          "4.98",
          "5.0",
          "5.062",
          "4.995",
          47.706,
          21.2,
          66.09,
          0,
          86.95,
          90.03,
          94.39,
        ],
      },
      {
        1002: [
          "3077",
          3898.45,
          0,
          64.1,
          "3900.87",
          "3898.395",
          "3892.728",
          "3891.088",
          "3893.235",
          0,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1003: [
          "2292",
          0.93,
          0,
          64.1,
          "0.93",
          "0.93",
          "0.929",
          "0.929",
          "0.928",
          0,
          6.696,
          82.01,
          0,
          96.36,
          99.39,
          99.75,
        ],
      },
      {
        1004: [
          "0040",
          0.66,
          772000,
          64.1,
          "0.659",
          "0.628",
          "0.648",
          "0.662",
          "0.648",
          51.101,
          5.179,
          29.3,
          0,
          52.7,
          63.09,
          79.67,
        ],
      },
      {
        1005: [
          "2133",
          0.228,
          0,
          64,
          "0.228",
          "0.228",
          "0.228",
          "0.228",
          "0.228",
          0,
          1.02,
          20.03,
          0,
          59.12,
          72.69,
          86.23,
        ],
      },
      {
        1006: [
          "9011",
          1029.9,
          0,
          64,
          "1029.835",
          "1029.586",
          "1028.975",
          "1028.744",
          "1029.037",
          0,
          0,
          41.8,
          0,
          96.75,
          99.97,
          100,
        ],
      },
      {
        1007: [
          "2638",
          7.73,
          1167040,
          63.9,
          "7.71",
          "7.691",
          "7.754",
          "7.765",
          "7.75",
          902,
          683,
          27.55,
          0,
          78.88,
          88.08,
          93.36,
        ],
      },
      {
        1008: [
          "8473",
          0.235,
          0,
          63.9,
          "0.233",
          "0.243",
          "0.251",
          "0.266",
          "0.248",
          0,
          2.632,
          26.55,
          0,
          74.74,
          82.84,
          93.86,
        ],
      },
      {
        1009: [
          "3698",
          2.56,
          9000,
          63.9,
          "2.578",
          "2.6",
          "2.58",
          "2.578",
          "2.575",
          2.306,
          89,
          0.11,
          0.13,
          81.81,
          89.61,
          97.89,
        ],
      },
      {
        1010: [
          "3173",
          12.07,
          1750,
          63.8,
          "12.286",
          "12.48",
          "12.283",
          "12.207",
          "12.261",
          2.14,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1011: [
          "3191",
          60.5,
          54800,
          63.8,
          "62.64",
          "66.708",
          "66.186",
          "63.411",
          "66.507",
          333,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1012: [
          "0072",
          0.183,
          0,
          63.8,
          "0.183",
          "0.187",
          "0.183",
          "0.182",
          "0.184",
          0,
          0.802,
          26.51,
          0,
          75.79,
          88.01,
          94.65,
        ],
      },
      {
        1013: [
          "0060",
          0.7,
          0,
          63.7,
          "0.679",
          "0.66",
          "0.681",
          "0.7",
          "0.678",
          0,
          1.817,
          16.72,
          0,
          55.1,
          65.66,
          82.57,
        ],
      },
      {
        1014: [
          "1486",
          0.98,
          0,
          63.7,
          "0.988",
          "1.016",
          "1.052",
          "1.109",
          "1.046",
          0,
          2.825,
          68.56,
          0,
          80.19,
          86.05,
          92.01,
        ],
      },
      {
        1015: [
          "3788",
          1.43,
          845000,
          63.7,
          "1.379",
          "1.357",
          "1.453",
          "1.453",
          "1.459",
          121,
          28,
          4,
          0.08,
          72.8,
          81.42,
          90.26,
        ],
      },
      {
        1016: [
          "9096",
          102.6,
          0,
          63.6,
          "102.58",
          "102.556",
          "102.537",
          "102.528",
          "102.54",
          0,
          0,
          0.31,
          0,
          86.85,
          98.83,
          100,
        ],
      },
      {
        1017: [
          "2205",
          3.66,
          15000,
          63.6,
          "3.638",
          "3.621",
          "-",
          "-",
          "-",
          5.501,
          25.6,
          71.25,
          0,
          90.51,
          94.92,
          98.9,
        ],
      },
      {
        1018: [
          "8128",
          0.086,
          168000,
          63.6,
          "0.094",
          "0.091",
          "0.083",
          "0.081",
          "0.083",
          1.444,
          3.893,
          0.09,
          0,
          64.18,
          72.6,
          83.39,
        ],
      },
      {
        1019: [
          "0088",
          4.88,
          4359,
          63.5,
          "4.824",
          "4.807",
          "4.987",
          "4.952",
          "4.994",
          2.118,
          30.1,
          58.71,
          0,
          74.75,
          80.96,
          86.88,
        ],
      },
      {
        1020: [
          "1643",
          0.84,
          3948000,
          63.5,
          "0.9",
          "0.713",
          "0.773",
          "0.811",
          "0.763",
          328,
          5.04,
          75,
          0,
          81.05,
          84.85,
          91.18,
        ],
      },
      {
        1021: [
          "1398",
          4.7,
          213449000,
          63.4,
          "4.543",
          "4.338",
          "4.418",
          "4.617",
          "4.388",
          99835,
          4079.3,
          0.73,
          27.29,
          59.02,
          69.16,
          79.98,
        ],
      },
      {
        1022: [
          "2883",
          7.48,
          34454900,
          63.4,
          "7.004",
          "6.694",
          "6.698",
          "6.94",
          "6.709",
          25606,
          135.4,
          0.13,
          21.58,
          58.04,
          72.92,
          85.27,
        ],
      },
      {
        1023: [
          "2193",
          0.3,
          196000,
          63.4,
          "0.333",
          "0.362",
          "0.322",
          "0.312",
          "0.324",
          5.784,
          1.259,
          0.03,
          0,
          75.66,
          79.23,
          86.27,
        ],
      },
      {
        1024: [
          "1084",
          1.14,
          74000,
          63.3,
          "1.141",
          "1.126",
          "1.147",
          "1.167",
          "1.144",
          8.438,
          9.357,
          44.9,
          0,
          90.59,
          94.02,
          97.93,
        ],
      },
      {
        1025: [
          "8210",
          0.058,
          0,
          63.3,
          "0.058",
          "0.06",
          "0.058",
          "0.057",
          "0.058",
          0,
          0.464,
          56.89,
          0,
          76.56,
          81.64,
          89.31,
        ],
      },
      {
        1026: [
          "3071",
          1001.65,
          0,
          63.3,
          "1001.625",
          "1001.584",
          "1001.399",
          "1001.412",
          "1001.425",
          0,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1027: [
          "1341",
          0.44,
          1552000,
          63.2,
          "0.44",
          "0.405",
          "0.477",
          "0.457",
          "0.478",
          67.5,
          33.2,
          14.11,
          0,
          60.52,
          77.12,
          92.19,
        ],
      },
      {
        1028: [
          "0960",
          40.55,
          8238090,
          63.2,
          "39.185",
          "38.326",
          "38.297",
          "40.769",
          "37.958",
          33572,
          2463.1,
          24.7,
          1.17,
          59.76,
          78.26,
          95.94,
        ],
      },
      {
        1029: [
          "9078",
          506.8,
          0,
          63.2,
          "506.76",
          "506.716",
          "506.695",
          "506.699",
          "506.7",
          0,
          0,
          0,
          0,
          93.67,
          100,
          100,
        ],
      },
      {
        1030: [
          "2678",
          10.64,
          809000,
          63.1,
          "10.362",
          "10.668",
          "11.358",
          "11.402",
          "11.257",
          858,
          97.6,
          8.78,
          2.04,
          57.36,
          62.48,
          71.02,
        ],
      },
      {
        1031: [
          "1378",
          9.32,
          17065800,
          63.1,
          "8.639",
          "7.875",
          "9.64",
          "10.216",
          "9.623",
          15727,
          850.1,
          0.01,
          7.35,
          70.07,
          82.08,
          95.81,
        ],
      },
      {
        1032: [
          "2903",
          0.29,
          0,
          62.3,
          "0.29",
          "0.29",
          "0.29",
          "0.29",
          "0.29",
          0,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1033: [
          "1781",
          0.183,
          0,
          62.3,
          "0.183",
          "0.183",
          "0.183",
          "0.183",
          "0.183",
          0,
          0.988,
          1.26,
          0,
          74.82,
          79.48,
          86.2,
        ],
      },
      {
        1034: [
          "0729",
          0.19,
          0,
          62.3,
          "0.19",
          "0.19",
          "0.19",
          "0.19",
          "0.19",
          0,
          4.759,
          45.35,
          2.8,
          59.04,
          68.69,
          80.31,
        ],
      },
      {
        1035: [
          "0731",
          0.365,
          0,
          62.3,
          "0.365",
          "0.365",
          "0.365",
          "0.365",
          "0.365",
          0,
          4.165,
          5.56,
          0,
          83.6,
          86.56,
          90.2,
        ],
      },
      {
        1036: [
          "1043",
          0.52,
          0,
          62.3,
          "0.52",
          "0.52",
          "0.52",
          "0.52",
          "0.52",
          0,
          1.951,
          54.96,
          0,
          74.08,
          80.7,
          85.23,
        ],
      },
      {
        1037: [
          "1131",
          0.083,
          0,
          62.3,
          "0.083",
          "0.083",
          "0.083",
          "0.083",
          "0.083",
          0,
          5.291,
          7.93,
          9.15,
          56.43,
          71.82,
          88.48,
        ],
      },
      {
        1038: [
          "8557",
          0.88,
          1525200,
          62.3,
          "0.235",
          "-",
          "-",
          "-",
          "-",
          134,
          0.44,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1039: [
          "6108",
          0.425,
          0,
          62.3,
          "0.425",
          "0.425",
          "0.425",
          "0.425",
          "0.425",
          0,
          7.105,
          27.78,
          0,
          48.69,
          63.76,
          83.68,
        ],
      },
      {
        1040: [
          "1041",
          0.043,
          0,
          62.3,
          "0.043",
          "0.043",
          "0.043",
          "0.043",
          "0.043",
          0,
          0.761,
          0.08,
          0,
          54.29,
          65.13,
          77.96,
        ],
      },
      {
        1041: [
          "3021",
          7.955,
          1400,
          62.3,
          "-",
          "-",
          "-",
          "-",
          "-",
          1.122,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1042: [
          "0885",
          0.01,
          0,
          62.3,
          "0.01",
          "0.01",
          "0.01",
          "0.01",
          "0.01",
          0,
          1.102,
          7.54,
          0.01,
          47.66,
          59.57,
          70.34,
        ],
      },
      {
        1043: [
          "0449",
          0.043,
          0,
          62.3,
          "0.043",
          "0.043",
          "0.043",
          "0.043",
          "0.043",
          0,
          3.627,
          0.04,
          0,
          62.29,
          71.26,
          80.44,
        ],
      },
      {
        1044: [
          "1863",
          1.37,
          0,
          62.3,
          "1.37",
          "1.37",
          "1.37",
          "1.37",
          "1.37",
          0,
          11.6,
          4.11,
          0,
          60.53,
          68.81,
          83,
        ],
      },
      {
        1045: [
          "0591",
          1.22,
          0,
          62.3,
          "1.22",
          "1.22",
          "1.22",
          "1.22",
          "1.22",
          0,
          12.6,
          4.94,
          0,
          58.64,
          68.51,
          80.91,
        ],
      },
      {
        1046: [
          "0462",
          2.2,
          0,
          62.3,
          "2.2",
          "2.2",
          "2.2",
          "2.2",
          "2.2",
          0,
          59.5,
          58.64,
          0,
          73.15,
          81.01,
          90.81,
        ],
      },
      {
        1047: [
          "0706",
          0.013,
          0,
          62.3,
          "0.013",
          "0.013",
          "0.013",
          "0.013",
          "0.013",
          0,
          1.204,
          2.98,
          0,
          64.5,
          71.33,
          82.32,
        ],
      },
      {
        1048: [
          "0153",
          0.104,
          0,
          62.3,
          "0.104",
          "0.104",
          "0.104",
          "0.104",
          "0.104",
          0,
          3.142,
          30.36,
          0,
          70.42,
          79.55,
          89.09,
        ],
      },
      {
        1049: [
          "0155",
          0.18,
          0,
          62.3,
          "0.18",
          "0.18",
          "0.18",
          "0.18",
          "0.18",
          0,
          2.769,
          44.75,
          0,
          61.2,
          65.96,
          74.01,
        ],
      },
      {
        1050: [
          "2228",
          0.6,
          0,
          62.3,
          "0.6",
          "0.6",
          "0.6",
          "0.6",
          "0.6",
          0,
          13.9,
          29.94,
          0,
          79.24,
          87.34,
          92.69,
        ],
      },
      {
        1051: [
          "2366",
          0.059,
          0,
          62.3,
          "0.059",
          "0.059",
          "0.059",
          "0.059",
          "0.059",
          0,
          0.932,
          36.62,
          0,
          62.93,
          73.21,
          86.38,
        ],
      },
      {
        1052: [
          "1678",
          0.04,
          0,
          62.3,
          "0.04",
          "0.04",
          "0.04",
          "0.04",
          "0.04",
          0,
          0.868,
          0.02,
          0,
          61.65,
          69.58,
          82.15,
        ],
      },
      {
        1053: [
          "0261",
          0.01,
          400000,
          62.3,
          "0.01",
          "0.01",
          "0.01",
          "0.01",
          "0.01",
          0.4,
          18.3,
          5.67,
          0,
          41.14,
          55.23,
          70.12,
        ],
      },
      {
        1054: [
          "1178",
          0.119,
          0,
          62.3,
          "0.119",
          "0.119",
          "0.119",
          "0.119",
          "0.119",
          0,
          0.354,
          0.2,
          0,
          46.25,
          56.49,
          71.27,
        ],
      },
      {
        1055: [
          "0648",
          0.38,
          0,
          62.3,
          "0.38",
          "-",
          "-",
          "-",
          "-",
          0,
          1.239,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1056: [
          "8215",
          0.108,
          0,
          62.3,
          "0.108",
          "0.108",
          "0.108",
          "0.108",
          "0.108",
          0,
          3.919,
          69.16,
          0,
          78.07,
          83.92,
          88.38,
        ],
      },
      {
        1057: [
          "0067",
          25.12,
          0,
          62.3,
          "25.12",
          "25.12",
          "25.12",
          "25.12",
          "25.12",
          0,
          70.3,
          1.28,
          0,
          43.99,
          56.03,
          68.89,
        ],
      },
      {
        1058: [
          "0967",
          2.98,
          0,
          62.3,
          "2.98",
          "2.98",
          "2.98",
          "2.98",
          "2.98",
          0,
          44.8,
          51.32,
          0.02,
          79.8,
          87.64,
          94.09,
        ],
      },
      {
        1059: [
          "6183",
          0.081,
          0,
          62.3,
          "0.081",
          "0.081",
          "0.081",
          "0.081",
          "0.081",
          0,
          1.229,
          15.17,
          0,
          48.51,
          61.39,
          74.18,
        ],
      },
      {
        1060: [
          "1096",
          0.026,
          0,
          62.3,
          "0.026",
          "0.026",
          "0.026",
          "0.026",
          "0.026",
          0,
          0.501,
          7.71,
          0,
          41.6,
          53.96,
          67.04,
        ],
      },
      {
        1061: [
          "8258",
          0.07,
          0,
          62.3,
          "0.07",
          "0.072",
          "-",
          "-",
          "-",
          0,
          0.232,
          0.42,
          0,
          41.69,
          54.05,
          73.54,
        ],
      },
      {
        1062: [
          "2983",
          0.27,
          4480,
          62.3,
          "-",
          "-",
          "-",
          "-",
          "-",
          0.124,
          0.622,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1063: [
          "0238",
          0.255,
          0,
          62.3,
          "0.255",
          "0.255",
          "0.255",
          "0.255",
          "0.255",
          0,
          2.42,
          3.26,
          0,
          78.21,
          84.88,
          90.74,
        ],
      },
      {
        1064: [
          "2906",
          0.38,
          0,
          62.3,
          "0.38",
          "0.38",
          "0.38",
          "0.38",
          "0.38",
          0,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1065: [
          "3928",
          0.96,
          0,
          62.3,
          "0.96",
          "0.96",
          "0.96",
          "0.96",
          "0.96",
          0,
          4.608,
          75,
          0,
          92.93,
          95.98,
          98.15,
        ],
      },
      {
        1066: [
          "0109",
          0.085,
          0,
          62.3,
          "0.085",
          "0.085",
          "0.085",
          "0.085",
          "0.085",
          0,
          6.025,
          14.83,
          0.63,
          60.1,
          75.06,
          90.27,
        ],
      },
      {
        1067: [
          "2213",
          0.101,
          0,
          62.3,
          "0.101",
          "0.101",
          "0.101",
          "0.101",
          "0.101",
          0,
          1.013,
          11.3,
          0,
          78.32,
          87.37,
          92.76,
        ],
      },
      {
        1068: [
          "1693",
          0.034,
          0,
          62.3,
          "0.034",
          "0.034",
          "0.034",
          "0.034",
          "0.034",
          0,
          0.612,
          0.02,
          0,
          86.95,
          89.1,
          92.77,
        ],
      },
      {
        1069: [
          "3076",
          7.8,
          213200,
          62.3,
          "-",
          "-",
          "-",
          "-",
          "-",
          166,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1070: [
          "0254",
          0.142,
          0,
          62.3,
          "0.142",
          "0.142",
          "0.142",
          "0.142",
          "0.142",
          0,
          9.105,
          0.49,
          0,
          35.28,
          50.37,
          63.9,
        ],
      },
      {
        1071: [
          "0479",
          0.01,
          0,
          62.3,
          "0.01",
          "0.01",
          "0.01",
          "0.01",
          "0.01",
          0,
          0.52,
          30.01,
          0,
          54.35,
          67.35,
          77.85,
        ],
      },
      {
        1072: [
          "1139",
          0.238,
          0,
          62.3,
          "0.238",
          "0.238",
          "0.238",
          "0.238",
          "0.238",
          0,
          2.045,
          2.68,
          0,
          83.75,
          88.55,
          92.99,
        ],
      },
      {
        1073: [
          "2807",
          62.6,
          22326,
          61.6,
          "64.278",
          "65.759",
          "65.068",
          "64.304",
          "65.073",
          140,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1074: [
          "0745",
          0.176,
          20000,
          61.5,
          "0.176",
          "0.178",
          "0.172",
          "0.177",
          "0.171",
          0.346,
          1.287,
          4.16,
          0,
          28.23,
          41.2,
          62.34,
        ],
      },
      {
        1075: [
          "3053",
          1033.6,
          0,
          61.5,
          "1033.535",
          "1033.557",
          "1033.907",
          "1033.956",
          "1033.892",
          0,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1076: [
          "9173",
          1.55,
          0,
          61.5,
          "1.576",
          "1.6",
          "1.577",
          "1.568",
          "1.574",
          0,
          0,
          67.4,
          0,
          97.07,
          99.19,
          99.99,
        ],
      },
      {
        1077: [
          "9077",
          500.05,
          0,
          61.4,
          "500.01",
          "499.974",
          "500.064",
          "500.129",
          "500.053",
          0,
          0,
          29,
          0,
          92,
          99.83,
          100,
        ],
      },
      {
        1078: [
          "0289",
          17.64,
          4000,
          61.4,
          "17.742",
          "17.81",
          "17.851",
          "17.788",
          "17.854",
          7.048,
          51.4,
          72.27,
          0,
          85.56,
          89.5,
          94.06,
        ],
      },
      {
        1079: [
          "2866",
          1.48,
          5826300,
          61.4,
          "1.448",
          "1.435",
          "1.531",
          "1.509",
          "1.528",
          862,
          54.4,
          0.52,
          22.75,
          42.95,
          52.7,
          66.41,
        ],
      },
      {
        1080: [
          "1556",
          0.2,
          50000,
          61.3,
          "0.201",
          "0.198",
          "0.2",
          "0.201",
          "0.2",
          1,
          3,
          74.83,
          0,
          80.12,
          82.86,
          87.41,
        ],
      },
      {
        1081: [
          "0097",
          0.375,
          665000,
          61.3,
          "0.368",
          "0.367",
          "0.382",
          "0.388",
          "0.38",
          24.985,
          11.4,
          70.93,
          0,
          78.1,
          82.57,
          87.23,
        ],
      },
      {
        1082: [
          "3050",
          61.98,
          23023,
          61.3,
          "63.4",
          "65.478",
          "65.036",
          "64.569",
          "64.925",
          143,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1083: [
          "0348",
          0.26,
          0,
          61.2,
          "0.262",
          "0.26",
          "0.281",
          "0.295",
          "0.278",
          0,
          2.003,
          0.4,
          0,
          52.24,
          62.47,
          76.09,
        ],
      },
      {
        1084: [
          "1853",
          2.06,
          2500,
          61.2,
          "2.025",
          "1.99",
          "2.029",
          "2.094",
          "2.026",
          0.515,
          2.404,
          0.02,
          0,
          84.71,
          88.79,
          94.22,
        ],
      },
      {
        1085: [
          "6823",
          10.52,
          4326640,
          61.2,
          "10.584",
          "10.59",
          "10.612",
          "10.69",
          "10.614",
          4562,
          796.9,
          54.65,
          0,
          79.67,
          89.8,
          94.17,
        ],
      },
      {
        1086: [
          "8299",
          0.038,
          39600,
          61.1,
          "0.039",
          "0.04",
          "0.044",
          "0.043",
          "0.044",
          0.142,
          0.569,
          18.18,
          0,
          39.19,
          51.48,
          68.04,
        ],
      },
      {
        1087: [
          "3700",
          1.53,
          3057000,
          61.1,
          "1.559",
          "1.509",
          "1.669",
          "1.825",
          "1.641",
          461,
          30.7,
          31.3,
          0,
          55.07,
          67.5,
          84.45,
        ],
      },
      {
        1088: [
          "0012",
          33.45,
          1548210,
          61,
          "33.155",
          "33.122",
          "34.097",
          "34.425",
          "33.815",
          5170,
          1619.4,
          74.28,
          0.08,
          87.95,
          91.22,
          94.71,
        ],
      },
      {
        1089: [
          "6828",
          0.116,
          0,
          61,
          "0.116",
          "0.117",
          "0.127",
          "0.126",
          "-",
          0,
          15,
          41.49,
          1.74,
          58.75,
          66.2,
          76.29,
        ],
      },
      {
        1090: [
          "6122",
          2.4,
          0,
          61,
          "2.395",
          "2.396",
          "2.393",
          "2.42",
          "2.386",
          0,
          21,
          53.58,
          0,
          91.74,
          97.57,
          98.95,
        ],
      },
      {
        1091: [
          "3070",
          25.62,
          1900,
          60.9,
          "24.74",
          "24.404",
          "25.202",
          "25.757",
          "25.089",
          4.867,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1092: [
          "0919",
          0.155,
          12000,
          60.9,
          "0.167",
          "0.179",
          "0.229",
          "0.225",
          "0.222",
          0.184,
          1.402,
          56.54,
          0,
          79.3,
          84.76,
          91.26,
        ],
      },
      {
        1093: [
          "9191",
          7.73,
          44050,
          60.9,
          "8.034",
          "8.551",
          "8.494",
          "8.143",
          "8.537",
          267,
          0,
          62.52,
          0,
          98.09,
          99.26,
          99.85,
        ],
      },
      {
        1094: [
          "0393",
          0.81,
          36000,
          60.8,
          "0.818",
          "0.826",
          "0.811",
          "0.816",
          "0.813",
          2.92,
          12.3,
          59.88,
          0,
          74.27,
          78.27,
          84.02,
        ],
      },
      {
        1095: [
          "9911",
          3.61,
          3224000,
          60.8,
          "3.541",
          "3.825",
          "4.769",
          "5.061",
          "4.636",
          1178,
          39.6,
          10.48,
          0,
          39.91,
          57.09,
          77.17,
        ],
      },
      {
        1096: [
          "6190",
          10.92,
          0,
          60.8,
          "10.92",
          "10.972",
          "10.961",
          "10.926",
          "10.974",
          0,
          44.4,
          1.64,
          0,
          79.95,
          93.47,
          99.86,
        ],
      },
      {
        1097: [
          "9091",
          13.13,
          0,
          60.7,
          "13.293",
          "13.566",
          "13.38",
          "13.415",
          "13.351",
          0,
          0,
          33.22,
          0,
          98.43,
          99.49,
          100,
        ],
      },
      {
        1098: [
          "1175",
          0.059,
          0,
          60.7,
          "0.059",
          "0.059",
          "0.059",
          "0.059",
          "0.059",
          0,
          0.971,
          51.22,
          0,
          60.27,
          67.47,
          80.38,
        ],
      },
      {
        1099: [
          "0922",
          0.182,
          2000000,
          60.7,
          "0.187",
          "0.199",
          "0.21",
          "0.198",
          "0.207",
          35.733,
          4.043,
          2.02,
          0,
          77.89,
          82.27,
          88.47,
        ],
      },
      {
        1100: [
          "0573",
          0.9,
          123000,
          60.6,
          "0.885",
          "0.892",
          "0.94",
          "0.952",
          "0.934",
          11.066,
          9.147,
          11.42,
          0,
          78.17,
          89.52,
          93.92,
        ],
      },
      {
        1101: [
          "8098",
          0.076,
          0,
          60.6,
          "0.077",
          "0.074",
          "0.078",
          "0.077",
          "0.078",
          0,
          1.672,
          72.18,
          0,
          94.04,
          96.22,
          98.14,
        ],
      },
      {
        1102: [
          "2308",
          1.22,
          544000,
          60.5,
          "1.237",
          "1.257",
          "1.258",
          "1.278",
          "1.258",
          66.332,
          3.762,
          0.72,
          0,
          40.72,
          50.16,
          66.18,
        ],
      },
      {
        1103: [
          "0605",
          1.7,
          0,
          60.5,
          "1.7",
          "1.713",
          "1.952",
          "2.253",
          "-",
          0,
          3.558,
          9.83,
          0,
          58.47,
          69.01,
          83.22,
        ],
      },
      {
        1104: [
          "0517",
          2.54,
          762000,
          60.5,
          "2.443",
          "2.422",
          "2.505",
          "2.557",
          "2.506",
          194,
          38.9,
          0.75,
          0.01,
          80.74,
          83.89,
          88.92,
        ],
      },
      {
        1105: [
          "0651",
          0.125,
          0,
          60.4,
          "0.125",
          "0.125",
          "0.125",
          "0.125",
          "0.125",
          0,
          0.511,
          16.83,
          0,
          45.01,
          55.48,
          66.27,
        ],
      },
      {
        1106: [
          "3091",
          102,
          500,
          60.4,
          "103.615",
          "105.728",
          "104.132",
          "104.369",
          "103.929",
          5.1,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1107: [
          "8159",
          0.126,
          0,
          60.4,
          "0.126",
          "0.129",
          "0.132",
          "0.134",
          "0.132",
          0,
          0.887,
          68.41,
          0,
          79.48,
          84.91,
          92,
        ],
      },
      {
        1108: [
          "2330",
          0.57,
          0,
          60.3,
          "0.563",
          "0.584",
          "0.652",
          "0.683",
          "0.632",
          0,
          1.45,
          44.09,
          0,
          84.16,
          88.72,
          94.34,
        ],
      },
      {
        1109: [
          "2014",
          0.089,
          0,
          60.3,
          "0.089",
          "0.089",
          "0.089",
          "0.089",
          "0.089",
          0,
          1.898,
          15.71,
          0,
          45.31,
          63.89,
          85.63,
        ],
      },
      {
        1110: [
          "0411",
          15.1,
          0,
          60.3,
          "15.062",
          "15.378",
          "15.189",
          "15.303",
          "15.193",
          0,
          36.7,
          3.23,
          0,
          88.43,
          94.44,
          97.59,
        ],
      },
      {
        1111: [
          "0921",
          10.22,
          1403000,
          60.2,
          "10.078",
          "8.776",
          "9.24",
          "10.08",
          "9.153",
          1449,
          46.9,
          0.41,
          10.38,
          53.17,
          70.47,
          89.78,
        ],
      },
      {
        1112: [
          "0148",
          38.2,
          1143440,
          60.2,
          "37.41",
          "37.313",
          "38.197",
          "39.677",
          "37.81",
          4309,
          423.5,
          45.79,
          1.11,
          77.88,
          86.58,
          93.59,
        ],
      },
      {
        1113: [
          "1145",
          0.255,
          440000,
          60.2,
          "0.254",
          "0.273",
          "0.251",
          "0.279",
          "0.247",
          10.9,
          2.799,
          19.09,
          0,
          47.21,
          57.43,
          70.62,
        ],
      },
      {
        1114: [
          "0435",
          4.32,
          274580,
          60.1,
          "4.312",
          "4.365",
          "4.587",
          "4.516",
          "4.579",
          117,
          72.4,
          21.95,
          0,
          71.25,
          78.95,
          87.83,
        ],
      },
      {
        1115: [
          "0119",
          2.13,
          5556180,
          60.1,
          "2.099",
          "1.993",
          "2.007",
          "2.065",
          "2.004",
          1196,
          79,
          18.42,
          13.35,
          46.56,
          60.66,
          81.61,
        ],
      },
      {
        1116: [
          "2260",
          1.22,
          10160000,
          60,
          "-",
          "-",
          "-",
          "-",
          "-",
          1248,
          5.856,
          72.64,
          0,
          87.73,
          90.42,
          93.85,
        ],
      },
      {
        1117: [
          "0375",
          2,
          1000,
          60,
          "1.997",
          "1.96",
          "2.078",
          "2.088",
          "2.088",
          0.2,
          3.317,
          36.66,
          0,
          78.25,
          85.96,
          92.5,
        ],
      },
      {
        1118: [
          "0676",
          0.76,
          0,
          60,
          "0.76",
          "0.774",
          "0.785",
          "0.782",
          "0.79",
          0,
          5.553,
          78.62,
          0,
          89.21,
          94.46,
          99.41,
        ],
      },
      {
        1119: [
          "0633",
          0.104,
          0,
          59.9,
          "0.104",
          "0.104",
          "0.104",
          "0.104",
          "0.104",
          0,
          2.404,
          13.46,
          0,
          50.26,
          59.78,
          74.71,
        ],
      },
      {
        1120: [
          "6881",
          4.54,
          23588800,
          59.9,
          "4.494",
          "4.434",
          "4.418",
          "4.496",
          "4.414",
          10699,
          167.5,
          0.07,
          20.34,
          47.87,
          64.31,
          78.95,
        ],
      },
      {
        1121: [
          "1339",
          2.48,
          19874500,
          59.9,
          "2.42",
          "2.36",
          "2.46",
          "2.513",
          "2.437",
          4932,
          216.4,
          0.27,
          20.99,
          51.41,
          64.55,
          79.19,
        ],
      },
      {
        1122: [
          "1766",
          3.5,
          16394900,
          59.8,
          "3.458",
          "3.524",
          "3.533",
          "3.492",
          "3.534",
          5753,
          152.9,
          0.29,
          21.05,
          45.91,
          57.28,
          73.33,
        ],
      },
      {
        1123: [
          "2819",
          100.45,
          244,
          59.8,
          "100.39",
          "100.582",
          "101.042",
          "101.146",
          "101.005",
          2.441,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1124: [
          "1328",
          0.096,
          0,
          59.8,
          "0.107",
          "0.1",
          "0.094",
          "0.095",
          "0.094",
          0,
          10.8,
          36.84,
          0,
          85.41,
          95.37,
          98.2,
        ],
      },
      {
        1125: [
          "1310",
          9.84,
          2532650,
          59.7,
          "9.564",
          "9.641",
          "9.337",
          "9.775",
          "9.358",
          2474,
          129,
          2,
          0.09,
          72.79,
          84.08,
          92,
        ],
      },
      {
        1126: [
          "8175",
          0.058,
          0,
          59.7,
          "0.058",
          "0.058",
          "0.058",
          "0.058",
          "0.058",
          0,
          1.577,
          31.35,
          0,
          47.03,
          57.77,
          76.75,
        ],
      },
      {
        1127: [
          "3108",
          11.4,
          0,
          59.6,
          "11.559",
          "11.73",
          "11.691",
          "11.707",
          "11.667",
          0,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1128: [
          "3162",
          7.56,
          54000,
          59.6,
          "7.565",
          "8.012",
          "-",
          "-",
          "-",
          41.176,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1129: [
          "2357",
          5.25,
          7390750,
          59.6,
          "5.41",
          "5.317",
          "5.378",
          "5.32",
          "5.376",
          3934,
          326,
          0.06,
          11.78,
          76.82,
          83.03,
          90.17,
        ],
      },
      {
        1130: [
          "0431",
          0.201,
          1204000,
          59.5,
          "0.204",
          "0.198",
          "0.198",
          "0.203",
          "0.197",
          24.112,
          15.6,
          13.03,
          0,
          52.97,
          66.84,
          81.41,
        ],
      },
      {
        1131: [
          "1839",
          6.2,
          97500,
          59.5,
          "6.358",
          "5.91",
          "6.365",
          "6.389",
          "6.307",
          61.474,
          34.9,
          50.54,
          0.49,
          78.2,
          90.38,
          97.47,
        ],
      },
      {
        1132: [
          "0226",
          2.37,
          0,
          59.5,
          "2.384",
          "2.433",
          "2.446",
          "2.418",
          "2.447",
          0,
          11.6,
          4.39,
          0,
          80.39,
          87.24,
          93.94,
        ],
      },
      {
        1133: [
          "0133",
          9.6,
          12000,
          59.4,
          "9.504",
          "9.342",
          "9.486",
          "9.779",
          "9.443",
          11.544,
          14.6,
          27.46,
          0,
          58.59,
          64.03,
          73.65,
        ],
      },
      {
        1134: [
          "0256",
          1.48,
          3302000,
          59.4,
          "1.502",
          "1.372",
          "1.418",
          "1.478",
          "1.406",
          487,
          64.4,
          25.93,
          10.53,
          51.82,
          69.41,
          93.75,
        ],
      },
      {
        1135: [
          "6198",
          4.3,
          846000,
          59.4,
          "4.318",
          "4.114",
          "4.13",
          "4.258",
          "4.116",
          365,
          47.2,
          0.02,
          6.81,
          60.11,
          74.07,
          89.06,
        ],
      },
      {
        1136: [
          "1013",
          0.067,
          0,
          59.3,
          "0.066",
          "0.066",
          "0.073",
          "0.079",
          "0.072",
          0,
          1.433,
          0.14,
          0,
          87.55,
          91.09,
          94.26,
        ],
      },
      {
        1137: [
          "0317",
          7.64,
          440000,
          59.3,
          "7.727",
          "7.207",
          "7.069",
          "7.268",
          "7.08",
          337,
          45.2,
          0.47,
          6.95,
          68.82,
          76.06,
          82.68,
        ],
      },
      {
        1138: [
          "9968",
          1.93,
          3890000,
          59.3,
          "1.943",
          "1.939",
          "1.945",
          "1.944",
          "1.942",
          752,
          101.4,
          51.58,
          0.23,
          81.68,
          86.95,
          93.64,
        ],
      },
      {
        1139: [
          "3031",
          12.76,
          0,
          59.2,
          "12.945",
          "13.14",
          "13.056",
          "13.004",
          "13.048",
          0,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1140: [
          "0693",
          2.01,
          0,
          59.2,
          "2.01",
          "2.011",
          "2.029",
          "2.035",
          "2.027",
          0,
          40.4,
          48.98,
          0,
          84.31,
          93.92,
          98.75,
        ],
      },
      {
        1141: [
          "0864",
          0.465,
          0,
          59.1,
          "0.465",
          "0.479",
          "0.495",
          "0.502",
          "0.494",
          0,
          1.796,
          70.37,
          0,
          77.14,
          82.51,
          88.84,
        ],
      },
      {
        1142: [
          "2302",
          1.5,
          0,
          59.1,
          "1.5",
          "1.5",
          "1.5",
          "1.519",
          "1.5",
          0,
          7.338,
          0.76,
          0,
          86.21,
          89.24,
          93.91,
        ],
      },
      {
        1143: [
          "2588",
          66.55,
          618544,
          59.1,
          "62.945",
          "61.086",
          "62.774",
          "64.832",
          "62.712",
          4130,
          461.8,
          70.46,
          1.56,
          91.1,
          95.96,
          98.61,
        ],
      },
      {
        1144: [
          "0679",
          1.21,
          10000,
          59,
          "1.221",
          "1.262",
          "1.203",
          "1.227",
          "1.205",
          1.21,
          5.16,
          58.57,
          0,
          81.06,
          85.34,
          90.46,
        ],
      },
      {
        1145: [
          "0753",
          5.73,
          12499800,
          59,
          "5.596",
          "5.39",
          "5.376",
          "5.632",
          "5.334",
          7154,
          261.4,
          59.35,
          11.88,
          74.78,
          82.65,
          92.61,
        ],
      },
      {
        1146: [
          "0066",
          42.9,
          2832810,
          59,
          "42.46",
          "41.941",
          "43.155",
          "43.329",
          "43.128",
          12124,
          2656.9,
          79.55,
          0.15,
          89.87,
          92.97,
          95.89,
        ],
      },
      {
        1147: [
          "0111",
          0.44,
          708000,
          58.9,
          "0.438",
          "0.443",
          "0.46",
          "0.475",
          "0.457",
          31.029,
          2.821,
          1.27,
          0,
          70.51,
          76.94,
          86.36,
        ],
      },
      {
        1148: [
          "0217",
          0.175,
          36000,
          58.9,
          "0.175",
          "0.17",
          "0.178",
          "0.18",
          "0.177",
          0.628,
          10.4,
          52.66,
          0,
          62.89,
          69.52,
          78.93,
        ],
      },
      {
        1149: [
          "0278",
          4.9,
          0,
          58.9,
          "4.843",
          "4.966",
          "-",
          "-",
          "-",
          0,
          5.927,
          51.38,
          0,
          75.96,
          86.96,
          94.96,
        ],
      },
      {
        1150: [
          "9677",
          3.23,
          0,
          58.8,
          "3.23",
          "3.255",
          "3.243",
          "3.27",
          "3.236",
          0,
          32.5,
          0.01,
          0,
          75.33,
          99.75,
          99.95,
        ],
      },
      {
        1151: [
          "1650",
          0.078,
          0,
          58.8,
          "0.078",
          "0.078",
          "0.078",
          "0.078",
          "0.078",
          0,
          1.56,
          75,
          0,
          81.06,
          85.65,
          90.52,
        ],
      },
      {
        1152: [
          "2821",
          119.55,
          0,
          58.8,
          "119.345",
          "119.558",
          "120.281",
          "120.705",
          "120.087",
          0,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1153: [
          "9929",
          0.069,
          30000,
          58.7,
          "0.069",
          "0.077",
          "0.072",
          "0.07",
          "0.072",
          0.207,
          1.38,
          78.54,
          0,
          83.88,
          87.09,
          91.42,
        ],
      },
      {
        1154: [
          "1570",
          4.01,
          0,
          58.7,
          "4.012",
          "4.033",
          "4.109",
          "4.06",
          "4.105",
          0,
          7.865,
          2.83,
          0,
          93.58,
          97.86,
          99.67,
        ],
      },
      {
        1155: [
          "0213",
          1.06,
          14400,
          58.6,
          "1.053",
          "1.068",
          "1.077",
          "1.081",
          "1.075",
          1.5,
          10.2,
          67.73,
          0,
          86.49,
          88.89,
          90.8,
        ],
      },
      {
        1156: [
          "2139",
          1.19,
          40000,
          58.6,
          "1.358",
          "1.128",
          "1.153",
          "1.188",
          "1.15",
          4.77,
          45.1,
          35.74,
          0,
          60.24,
          79.65,
          95.03,
        ],
      },
      {
        1157: [
          "9031",
          1.638,
          0,
          58.6,
          "1.66",
          "1.685",
          "1.677",
          "1.672",
          "1.676",
          0,
          0,
          75.55,
          0,
          99.99,
          100,
          175.55,
        ],
      },
      {
        1158: [
          "0247",
          23.55,
          0,
          58.5,
          "23.565",
          "23.497",
          "23.767",
          "23.891",
          "23.737",
          0,
          469.2,
          90.87,
          0,
          98.41,
          99.62,
          99.91,
        ],
      },
      {
        1159: [
          "3866",
          4.2,
          10500,
          58.5,
          "4.346",
          "4.21",
          "4.251",
          "4.365",
          "4.215",
          4.41,
          96.2,
          35.35,
          0.08,
          62.69,
          77.05,
          93.57,
        ],
      },
      {
        1160: [
          "0752",
          1.27,
          40000,
          58.5,
          "1.272",
          "1.283",
          "1.275",
          "1.294",
          "1.272",
          5.066,
          15.7,
          37.27,
          0,
          72.91,
          79.97,
          88.6,
        ],
      },
      {
        1161: [
          "3141",
          15.77,
          56443,
          58.4,
          "15.902",
          "16.05",
          "16.14",
          "16.119",
          "16.134",
          88.905,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1162: [
          "0216",
          1.68,
          0,
          58.4,
          "1.624",
          "1.573",
          "1.707",
          "1.737",
          "1.697",
          0,
          9.263,
          41.18,
          0,
          77.46,
          83.09,
          88.66,
        ],
      },
      {
        1163: [
          "0662",
          3.65,
          0,
          58.4,
          "3.649",
          "3.59",
          "3.545",
          "3.612",
          "3.548",
          0,
          34.3,
          1.73,
          0,
          69.79,
          85.34,
          96.6,
        ],
      },
      {
        1164: [
          "0653",
          0.172,
          7263800,
          58.3,
          "0.176",
          "0.185",
          "0.193",
          "0.201",
          "0.188",
          125,
          6.042,
          0.49,
          0,
          72.3,
          78.3,
          86.88,
        ],
      },
      {
        1165: [
          "0856",
          7.06,
          622000,
          58.3,
          "7.092",
          "7.167",
          "6.879",
          "6.987",
          "6.924",
          443,
          102.7,
          0.13,
          0.75,
          70.14,
          81.42,
          93.03,
        ],
      },
      {
        1166: [
          "2119",
          1,
          4000,
          58.3,
          "0.999",
          "0.99",
          "1.011",
          "1.022",
          "1.007",
          0.4,
          7.207,
          66.93,
          0,
          77.28,
          84.46,
          90.27,
        ],
      },
      {
        1167: [
          "0022",
          0.125,
          120000,
          58.2,
          "0.12",
          "0.122",
          "0.121",
          "0.12",
          "0.121",
          1.5,
          2.458,
          1.08,
          0,
          74.79,
          81.58,
          86.98,
        ],
      },
      {
        1168: [
          "1980",
          0.93,
          2274000,
          58.2,
          "0.861",
          "0.858",
          "0.995",
          "1.04",
          "0.975",
          207,
          11.7,
          3.16,
          1.39,
          61.86,
          71.64,
          87.13,
        ],
      },
      {
        1169: [
          "1875",
          4.1,
          9200,
          58.1,
          "4.162",
          "4.123",
          "4.24",
          "4.261",
          "4.234",
          3.772,
          25.2,
          19.17,
          0,
          64.32,
          81.99,
          92.86,
        ],
      },
      {
        1170: [
          "0610",
          3.89,
          98000,
          58.1,
          "3.733",
          "3.739",
          "3.892",
          "3.983",
          "3.876",
          37.302,
          30.8,
          27.95,
          0,
          57.67,
          81.65,
          89.91,
        ],
      },
      {
        1171: [
          "1000",
          0.7,
          0,
          58.1,
          "0.699",
          "0.662",
          "0.688",
          "0.719",
          "0.687",
          0,
          0.384,
          1.52,
          0,
          53.74,
          60.95,
          72.06,
        ],
      },
      {
        1172: [
          "3882",
          0.19,
          0,
          58,
          "0.177",
          "0.176",
          "0.186",
          "0.185",
          "0.185",
          0,
          1.81,
          17.1,
          0,
          74.43,
          82.65,
          89.35,
        ],
      },
      {
        1173: [
          "2320",
          0.25,
          0,
          58,
          "0.254",
          "0.241",
          "0.256",
          "0.265",
          "0.252",
          0,
          2.044,
          56.9,
          0,
          79.82,
          85.99,
          92.13,
        ],
      },
      {
        1174: [
          "6199",
          2.6,
          0,
          58,
          "2.614",
          "2.591",
          "2.577",
          "2.578",
          "2.582",
          0,
          57.2,
          0.01,
          0,
          89.94,
          97.37,
          99.67,
        ],
      },
      {
        1175: [
          "3330",
          1.01,
          462000,
          57.9,
          "0.972",
          "0.961",
          "0.978",
          "1.025",
          "0.977",
          46.332,
          3.002,
          0.37,
          0,
          48.13,
          57.04,
          70.11,
        ],
      },
      {
        1176: [
          "9141",
          2.026,
          0,
          57.9,
          "2.041",
          "2.059",
          "2.074",
          "2.072",
          "2.073",
          0,
          0,
          85.16,
          0,
          98.71,
          99.9,
          99.99,
        ],
      },
      {
        1177: [
          "1622",
          2.71,
          5479000,
          57.9,
          "2.703",
          "2.439",
          "2.465",
          "2.52",
          "2.487",
          1543,
          96.2,
          59.92,
          6.58,
          75.63,
          88.06,
          96.63,
        ],
      },
      {
        1178: [
          "1757",
          0.224,
          0,
          57.8,
          "0.24",
          "0.255",
          "0.295",
          "0.283",
          "0.297",
          0,
          2.688,
          11.63,
          0,
          90.25,
          93.07,
          95.86,
        ],
      },
      {
        1179: [
          "1375",
          1.48,
          5511000,
          57.8,
          "1.435",
          "1.418",
          "1.452",
          "1.468",
          "1.447",
          818,
          17.6,
          0.03,
          46.16,
          51.86,
          62.31,
          79.55,
        ],
      },
      {
        1180: [
          "3369",
          1.29,
          1246000,
          57.8,
          "1.277",
          "1.251",
          "1.282",
          "1.299",
          "1.281",
          160,
          10.7,
          0.3,
          15.94,
          36.88,
          52.98,
          80.11,
        ],
      },
      {
        1181: [
          "6099",
          11.7,
          1568520,
          57.7,
          "11.652",
          "11.884",
          "11.747",
          "11.566",
          "11.847",
          1837,
          149.1,
          0.02,
          23.68,
          66.76,
          84.97,
          95.51,
        ],
      },
      {
        1182: [
          "0253",
          0.87,
          22000,
          57.7,
          "0.893",
          "0.898",
          "0.896",
          "0.903",
          "0.895",
          1.916,
          2.648,
          58.61,
          0,
          64.83,
          69.28,
          72.71,
        ],
      },
      {
        1183: [
          "1868",
          0.48,
          0,
          57.6,
          "0.482",
          "0.481",
          "0.489",
          "0.49",
          "0.492",
          0,
          10,
          47.82,
          0,
          73.3,
          82.37,
          93.33,
        ],
      },
      {
        1184: [
          "3110",
          26.5,
          216300,
          57.6,
          "26.018",
          "25.23",
          "26.609",
          "27.311",
          "26.483",
          573,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1185: [
          "0622",
          0.57,
          2703080,
          57.6,
          "0.556",
          "0.552",
          "0.537",
          "0.552",
          "0.538",
          155,
          34.8,
          6.77,
          0,
          50.38,
          64.68,
          84.14,
        ],
      },
      {
        1186: [
          "1985",
          0.91,
          18000,
          57.5,
          "0.94",
          "0.993",
          "0.983",
          "0.972",
          "0.976",
          1.648,
          2.73,
          64.96,
          0,
          73.65,
          79.91,
          88.09,
        ],
      },
      {
        1187: [
          "2207",
          4.91,
          434000,
          57.5,
          "4.906",
          "4.938",
          "-",
          "-",
          "-",
          211,
          24.9,
          71.71,
          0,
          85.64,
          92.74,
          99.41,
        ],
      },
      {
        1188: [
          "3320",
          4.07,
          32124000,
          57.5,
          "4.251",
          "3.609",
          "4.018",
          "4.314",
          "3.935",
          13136,
          255.6,
          64.13,
          4.06,
          79.65,
          85.49,
          95.48,
        ],
      },
      {
        1189: [
          "0802",
          0.104,
          50000,
          57.4,
          "0.106",
          "0.098",
          "0.106",
          "0.107",
          "0.106",
          0.516,
          3.138,
          11.87,
          0,
          31.77,
          44.23,
          66.71,
        ],
      },
      {
        1190: [
          "1176",
          1.6,
          3976000,
          57.4,
          "1.639",
          "1.586",
          "1.668",
          "1.814",
          "1.665",
          646,
          115.6,
          15.97,
          4.74,
          77.15,
          87.21,
          93.93,
        ],
      },
      {
        1191: [
          "0684",
          1.66,
          0,
          57.4,
          "1.661",
          "1.667",
          "1.751",
          "1.714",
          "1.755",
          0,
          5.534,
          53.78,
          0,
          64.31,
          69.86,
          77.52,
        ],
      },
      {
        1192: [
          "2832",
          10.95,
          100,
          57.3,
          "11.295",
          "11.854",
          "12.081",
          "11.799",
          "12.037",
          0.109,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1193: [
          "0691",
          2.01,
          346000,
          57.3,
          "1.953",
          "1.981",
          "2.118",
          "2.098",
          "2.112",
          73.155,
          87.5,
          55.76,
          0.07,
          85,
          97.36,
          98.65,
        ],
      },
      {
        1194: [
          "3789",
          0.144,
          4000,
          57.3,
          "0.141",
          "0.175",
          "0.151",
          "0.151",
          "0.151",
          0,
          1.728,
          75.17,
          0,
          82.04,
          87.03,
          92.68,
        ],
      },
      {
        1195: [
          "3969",
          2.89,
          3936000,
          57.2,
          "2.877",
          "2.762",
          "2.823",
          "2.914",
          "2.821",
          1140,
          56.8,
          0.05,
          25.47,
          49.06,
          66.42,
          86.43,
        ],
      },
      {
        1196: [
          "3316",
          21.5,
          48000,
          57.2,
          "21.2",
          "21.378",
          "24.362",
          "23.862",
          "23.761",
          103,
          59.4,
          46.57,
          0,
          76.42,
          84.3,
          94.2,
        ],
      },
      {
        1197: [
          "2215",
          3.09,
          238000,
          57.1,
          "3.077",
          "3.074",
          "-",
          "-",
          "-",
          73.67,
          31.4,
          70.2,
          0,
          92.95,
          95.55,
          99.07,
        ],
      },
      {
        1198: [
          "1620",
          0.18,
          10000,
          57.1,
          "0.18",
          "0.191",
          "0.208",
          "0.209",
          "0.206",
          0.18,
          2.16,
          0.01,
          0,
          84.49,
          88.63,
          92.34,
        ],
      },
      {
        1199: [
          "2727",
          2.25,
          8516000,
          57.1,
          "2.335",
          "2.324",
          "2.171",
          "2.275",
          "2.186",
          1924,
          65.8,
          0.18,
          32.37,
          56.12,
          64.9,
          75.02,
        ],
      },
      {
        1200: [
          "0730",
          0.149,
          0,
          57,
          "0.155",
          "0.157",
          "0.147",
          "0.152",
          "0.147",
          0,
          5.937,
          37.46,
          0,
          67.15,
          80.11,
          87.78,
        ],
      },
      {
        1201: [
          "3118",
          20.82,
          1000,
          57,
          "21.72",
          "21.627",
          "21.281",
          "21.246",
          "21.261",
          2.082,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1202: [
          "1986",
          1.35,
          422500,
          57,
          "1.331",
          "1.409",
          "1.417",
          "1.369",
          "1.425",
          57.352,
          13.8,
          0.01,
          0,
          62.61,
          68.92,
          80.02,
        ],
      },
      {
        1203: [
          "8035",
          0.22,
          680000,
          56.9,
          "0.213",
          "0.22",
          "0.242",
          "0.252",
          "0.239",
          13.698,
          1.32,
          0,
          0,
          76.18,
          80.93,
          89.27,
        ],
      },
      {
        1204: [
          "2327",
          0.43,
          3207000,
          56.9,
          "0.463",
          "0.445",
          "0.425",
          "0.428",
          "0.428",
          142,
          18,
          4.78,
          0,
          63.39,
          71.73,
          84.31,
        ],
      },
      {
        1205: [
          "2899",
          10,
          56548000,
          56.9,
          "9.252",
          "9.98",
          "10.476",
          "10.666",
          "10.452",
          57058,
          573.6,
          0.28,
          18.04,
          60.23,
          73.82,
          86.18,
        ],
      },
      {
        1206: [
          "0196",
          0.26,
          2960000,
          56.8,
          "0.26",
          "0.252",
          "0.247",
          "0.249",
          "0.247",
          75.968,
          13.9,
          31.08,
          0,
          55.33,
          65.03,
          78.57,
        ],
      },
      {
        1207: [
          "1052",
          4.83,
          3081500,
          56.8,
          "4.736",
          "4.764",
          "4.675",
          "4.704",
          "4.698",
          1482,
          80.8,
          40.67,
          3.66,
          71.69,
          79.91,
          89.56,
        ],
      },
      {
        1208: [
          "1657",
          4.5,
          0,
          56.7,
          "4.851",
          "4.891",
          "5.47",
          "5.401",
          "5.385",
          0,
          1.44,
          75.34,
          0,
          83.88,
          90.07,
          96.31,
        ],
      },
      {
        1209: [
          "0578",
          0.048,
          0,
          56.7,
          "0.048",
          "0.048",
          "0.048",
          "0.048",
          "0.048",
          0,
          0.499,
          36.13,
          0,
          66.62,
          74.86,
          85.27,
        ],
      },
      {
        1210: [
          "3187",
          21.12,
          76200,
          56.7,
          "21.184",
          "21.427",
          "21.963",
          "22.042",
          "21.921",
          160,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1211: [
          "3389",
          0.3,
          0,
          56.6,
          "0.287",
          "0.289",
          "0.298",
          "0.3",
          "0.298",
          0,
          13.3,
          0.01,
          0.02,
          57.91,
          68.29,
          87.22,
        ],
      },
      {
        1212: [
          "6868",
          5.3,
          8000,
          56.6,
          "5.418",
          "5.5",
          "5.369",
          "5.399",
          "5.354",
          4.24,
          58,
          1.59,
          0,
          83.8,
          95.84,
          99.17,
        ],
      },
      {
        1213: [
          "1718",
          0.17,
          0,
          56.6,
          "0.161",
          "0.155",
          "0.179",
          "0.201",
          "0.175",
          0,
          1.632,
          0.02,
          0,
          52.56,
          62.98,
          80.85,
        ],
      },
      {
        1214: [
          "6116",
          0.5,
          74800,
          56.5,
          "0.503",
          "0.544",
          "0.647",
          "0.637",
          "0.639",
          3.611,
          1.074,
          0.01,
          24.34,
          48.67,
          61.82,
          80.79,
        ],
      },
      {
        1215: [
          "8502",
          0.26,
          136000,
          56.5,
          "0.277",
          "0.286",
          "0.288",
          "0.277",
          "0.289",
          3.54,
          2.08,
          81.58,
          0,
          86.51,
          89.84,
          94.69,
        ],
      },
      {
        1216: [
          "1532",
          0.185,
          18000,
          56.5,
          "0.18",
          "0.205",
          "0.207",
          "0.209",
          "0.208",
          0.34,
          1.993,
          37.82,
          0,
          74.27,
          79,
          86.5,
        ],
      },
      {
        1217: [
          "1838",
          0.415,
          0,
          56.4,
          "0.415",
          "0.415",
          "0.415",
          "0.415",
          "0.415",
          0,
          7.508,
          37.28,
          0,
          78.44,
          82.46,
          88.21,
        ],
      },
      {
        1218: [
          "1373",
          2.67,
          539000,
          56.4,
          "2.746",
          "2.726",
          "2.851",
          "2.823",
          "2.834",
          143,
          19.3,
          45.47,
          0,
          62.92,
          70.58,
          79.75,
        ],
      },
      {
        1219: [
          "1885",
          0.9,
          0,
          56.4,
          "0.9",
          "0.9",
          "0.9",
          "0.909",
          "0.9",
          0,
          9,
          2.91,
          0,
          79.06,
          86.84,
          93.91,
        ],
      },
      {
        1220: [
          "1982",
          0.51,
          0,
          56.3,
          "0.516",
          "0.506",
          "0.547",
          "0.554",
          "0.542",
          0,
          11.6,
          74.64,
          0,
          82.87,
          87.12,
          91.66,
        ],
      },
      {
        1221: [
          "9608",
          0.128,
          1520000,
          56.3,
          "0.129",
          "0.143",
          "0.121",
          "0.12",
          "0.122",
          19.408,
          4.096,
          75,
          0,
          84.72,
          88.02,
          93.58,
        ],
      },
      {
        1222: [
          "0777",
          18,
          758500,
          56.2,
          "18.044",
          "17.992",
          "18.151",
          "18.949",
          "18.059",
          1379,
          98.1,
          0.03,
          3.93,
          67.9,
          82.9,
          90.9,
        ],
      },
      {
        1223: [
          "1090",
          2.87,
          0,
          56.2,
          "2.809",
          "3.064",
          "3.262",
          "3.169",
          "3.277",
          0,
          36.5,
          49.56,
          0,
          83.34,
          88.31,
          94.27,
        ],
      },
      {
        1224: [
          "0388",
          456.6,
          3651210,
          56.2,
          "451.34",
          "456.192",
          "476.484",
          "474.23",
          "476.683",
          166000,
          5788.9,
          5.88,
          5.99,
          61.8,
          71.45,
          85.03,
        ],
      },
      {
        1225: [
          "0001",
          55,
          6165500,
          56.1,
          "52.83",
          "51.363",
          "54.587",
          "56.593",
          "54.173",
          33873,
          2109,
          33.59,
          0.89,
          72.29,
          83.21,
          90.2,
        ],
      },
      {
        1226: [
          "8417",
          0.029,
          0,
          56.1,
          "0.029",
          "0.029",
          "0.03",
          "0.03",
          "0.03",
          0,
          0.508,
          26.24,
          0,
          82.39,
          87.94,
          93,
        ],
      },
      {
        1227: [
          "3197",
          14.14,
          0,
          56.1,
          "14.14",
          "14.133",
          "14.365",
          "14.411",
          "14.325",
          0,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1228: [
          "3799",
          4.34,
          3028930,
          56,
          "4.221",
          "4.144",
          "4.304",
          "4.385",
          "4.281",
          1312,
          594.3,
          83.15,
          3.27,
          91,
          95.16,
          99.22,
        ],
      },
      {
        1229: [
          "2230",
          0.166,
          135000,
          56,
          "0.165",
          "0.172",
          "0.204",
          "0.206",
          "0.2",
          2.241,
          3.307,
          72.01,
          0,
          84.95,
          91.57,
          95.78,
        ],
      },
      {
        1230: [
          "2289",
          5.59,
          0,
          56,
          "5.588",
          "5.677",
          "6.296",
          "6.014",
          "6.297",
          0,
          1.565,
          0.05,
          0,
          66.17,
          81.15,
          94.51,
        ],
      },
      {
        1231: [
          "1971",
          4.2,
          66000,
          55.9,
          "4.198",
          "4.262",
          "4.321",
          "4.324",
          "4.311",
          27.859,
          17.4,
          68.68,
          0,
          78.01,
          85.82,
          95.02,
        ],
      },
      {
        1232: [
          "9187",
          2.704,
          0,
          55.9,
          "2.718",
          "2.748",
          "2.821",
          "2.833",
          "2.816",
          0,
          0,
          61.72,
          0,
          97.83,
          98.94,
          99.86,
        ],
      },
      {
        1233: [
          "9889",
          7.6,
          3000,
          55.9,
          "7.642",
          "7.414",
          "-",
          "-",
          "-",
          2.26,
          87.2,
          0,
          0,
          50.95,
          73.89,
          97.46,
        ],
      },
      {
        1234: [
          "1388",
          1.06,
          0,
          55.8,
          "1.05",
          "1.038",
          "1.12",
          "1.155",
          "1.11",
          0,
          4.478,
          73.35,
          0,
          87.82,
          89.94,
          93.81,
        ],
      },
      {
        1235: [
          "0128",
          0.6,
          928000,
          55.8,
          "0.553",
          "0.57",
          "0.607",
          "0.615",
          "0.606",
          55.776,
          9.904,
          11.39,
          0,
          46.11,
          57.43,
          68.99,
        ],
      },
      {
        1236: [
          "2368",
          4.55,
          20000,
          55.7,
          "4.626",
          "4.665",
          "4.862",
          "4.827",
          "4.841",
          9.12,
          24.2,
          57.78,
          0,
          81.03,
          84.96,
          89.82,
        ],
      },
      {
        1237: [
          "0582",
          0.229,
          528397,
          55.7,
          "0.235",
          "0.24",
          "0.242",
          "0.253",
          "0.241",
          12.153,
          9.678,
          18.14,
          2.53,
          70.48,
          79.97,
          88.9,
        ],
      },
      {
        1238: [
          "1599",
          2.08,
          446000,
          55.7,
          "2.067",
          "2.05",
          "2.111",
          "2.136",
          "2.12",
          93.496,
          8.069,
          0.04,
          0,
          45.45,
          54.93,
          70.68,
        ],
      },
      {
        1239: [
          "1147",
          0.106,
          360000,
          55.6,
          "0.104",
          "0.109",
          "0.114",
          "0.113",
          "0.113",
          3.805,
          2.12,
          75.01,
          0,
          82.02,
          85.8,
          90.44,
        ],
      },
      {
        1240: [
          "0608",
          1.45,
          0,
          55.6,
          "1.45",
          "1.475",
          "1.481",
          "1.478",
          "1.483",
          0,
          4.431,
          14.65,
          0,
          23.46,
          28.12,
          33.34,
        ],
      },
      {
        1241: [
          "1686",
          7.28,
          220514,
          55.6,
          "7.234",
          "7.046",
          "7.541",
          "7.635",
          "7.529",
          160,
          170.2,
          76.45,
          0.45,
          88.36,
          91.05,
          94.81,
        ],
      },
      {
        1242: [
          "0081",
          4.23,
          4918630,
          55.5,
          "4.211",
          "3.82",
          "4.401",
          "4.544",
          "4.374",
          2128,
          144.8,
          16.82,
          8.92,
          48.46,
          66.58,
          82.06,
        ],
      },
      {
        1243: [
          "2101",
          7.59,
          150000,
          55.5,
          "7.542",
          "7.556",
          "8.177",
          "8.387",
          "8.008",
          113,
          30.6,
          60.4,
          0,
          80.61,
          87.08,
          92.93,
        ],
      },
      {
        1244: [
          "1963",
          4.71,
          1958000,
          55.5,
          "4.531",
          "4.386",
          "4.598",
          "4.779",
          "4.568",
          918,
          74.3,
          25.72,
          6.78,
          56.31,
          71.25,
          89.52,
        ],
      },
      {
        1245: [
          "2288",
          0.64,
          48000,
          55.4,
          "0.646",
          "0.664",
          "0.683",
          "0.664",
          "0.687",
          3.056,
          2.403,
          2.77,
          0,
          69.51,
          76.19,
          84.14,
        ],
      },
      {
        1246: [
          "3738",
          6.2,
          8662500,
          55.4,
          "6.026",
          "6.856",
          "7.172",
          "7.36",
          "7.101",
          5441,
          131.2,
          14.1,
          6.62,
          37.18,
          51.5,
          75.87,
        ],
      },
      {
        1247: [
          "0367",
          0.91,
          48000,
          55.4,
          "0.901",
          "0.884",
          "0.921",
          "0.96",
          "0.914",
          4.284,
          15.2,
          53.72,
          0,
          63.41,
          68.67,
          74.88,
        ],
      },
      {
        1248: [
          "1217",
          0.01,
          200000,
          55.3,
          "0.01",
          "0.01",
          "0.01",
          "0.01",
          "0.01",
          0.2,
          1.28,
          0.38,
          0,
          49.28,
          59.45,
          71.35,
        ],
      },
      {
        1249: [
          "2686",
          1.45,
          8546000,
          55.3,
          "1.449",
          "1.43",
          "1.544",
          "1.484",
          "1.554",
          1271,
          49.2,
          0.03,
          0,
          75.57,
          82.51,
          91.23,
        ],
      },
      {
        1250: [
          "8325",
          0.164,
          170000,
          55.2,
          "0.173",
          "0.175",
          "0.179",
          "0.178",
          "0.178",
          2.81,
          3.883,
          5.34,
          0,
          53.63,
          65.5,
          78.29,
        ],
      },
      {
        1251: [
          "1970",
          12,
          111139,
          55.2,
          "11.77",
          "12.234",
          "11.564",
          "12.535",
          "11.547",
          134,
          41,
          71.11,
          1.08,
          84.78,
          91.7,
          97.12,
        ],
      },
      {
        1252: [
          "0222",
          3.79,
          142000,
          55.2,
          "3.896",
          "4.035",
          "3.785",
          "3.815",
          "3.766",
          52.446,
          22.6,
          28.41,
          0,
          64.75,
          83.18,
          91.16,
        ],
      },
      {
        1253: [
          "7374",
          7.53,
          0,
          55.1,
          "7.593",
          "7.616",
          "-",
          "-",
          "-",
          0,
          0,
          0,
          0,
          99.27,
          99.91,
          100,
        ],
      },
      {
        1254: [
          "0480",
          3.1,
          97919,
          55.1,
          "3.1",
          "3.025",
          "3.178",
          "3.253",
          "3.144",
          30.28,
          46,
          67.06,
          0,
          80.43,
          84.65,
          89.84,
        ],
      },
      {
        1255: [
          "3022",
          569.4,
          0,
          55.1,
          "555.98",
          "558.88",
          "579.592",
          "585.071",
          "577.7",
          0,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1256: [
          "8372",
          0.075,
          0,
          55,
          "0.071",
          "0.077",
          "0.078",
          "0.08",
          "0.078",
          0,
          0.6,
          73.97,
          0,
          79.47,
          83.73,
          90.35,
        ],
      },
      {
        1257: [
          "0763",
          22.6,
          4253220,
          55,
          "21.895",
          "21.804",
          "24.402",
          "23.28",
          "24.35",
          9680,
          170.7,
          0.14,
          23.8,
          55.48,
          69.08,
          82.34,
        ],
      },
      {
        1258: [
          "0025",
          9.23,
          0,
          55,
          "9.304",
          "9.453",
          "9.598",
          "9.608",
          "9.586",
          0,
          27.8,
          16.07,
          0,
          64.17,
          80.34,
          91.35,
        ],
      },
      {
        1259: [
          "0165",
          9.16,
          1670000,
          54.9,
          "9.181",
          "8.876",
          "9.096",
          "9.221",
          "9.101",
          1534,
          154.3,
          50.26,
          13.84,
          69.72,
          78.61,
          90.15,
        ],
      },
      {
        1260: [
          "0220",
          7.7,
          5951570,
          54.9,
          "7.575",
          "7.432",
          "7.586",
          "8.001",
          "7.548",
          4613,
          332.5,
          0.02,
          0.28,
          86.04,
          95.96,
          99.13,
        ],
      },
      {
        1261: [
          "2066",
          6.76,
          0,
          54.9,
          "6.876",
          "6.804",
          "6.9",
          "6.907",
          "6.891",
          0,
          158.2,
          34.19,
          0.01,
          54.75,
          70.82,
          94.33,
        ],
      },
      {
        1262: [
          "1451",
          0.53,
          0,
          54.8,
          "0.538",
          "0.578",
          "0.618",
          "0.606",
          "0.613",
          0,
          1.06,
          75.02,
          0,
          82.72,
          86.68,
          91.72,
        ],
      },
      {
        1263: [
          "3636",
          4.53,
          10800,
          54.8,
          "4.62",
          "4.684",
          "4.752",
          "4.611",
          "4.816",
          4.862,
          4.052,
          2.06,
          0,
          33.37,
          42.38,
          58.56,
        ],
      },
      {
        1264: [
          "0234",
          0.061,
          0,
          54.7,
          "0.062",
          "0.066",
          "0.064",
          "0.063",
          "0.064",
          0,
          3.526,
          46.55,
          0,
          73.68,
          86.91,
          93.04,
        ],
      },
      {
        1265: [
          "1712",
          1.58,
          0,
          54.7,
          "1.699",
          "1.577",
          "1.587",
          "1.649",
          "1.576",
          0,
          2.501,
          7.86,
          0,
          74.03,
          83.86,
          93.19,
        ],
      },
      {
        1266: [
          "1803",
          0.197,
          1440000,
          54.7,
          "0.195",
          "0.201",
          "0.208",
          "0.211",
          "0.207",
          25.349,
          2.774,
          4.87,
          0,
          44.18,
          56.75,
          76.95,
        ],
      },
      {
        1267: [
          "1772",
          117.4,
          4256220,
          54.6,
          "118.53",
          "134.002",
          "141.221",
          "130.842",
          "142.117",
          49810,
          338.4,
          0,
          21.69,
          64.54,
          75.34,
          84.85,
        ],
      },
      {
        1268: [
          "0495",
          0.132,
          0,
          54.6,
          "0.131",
          "0.133",
          "0.145",
          "0.141",
          "0.145",
          0,
          1.85,
          74.71,
          0,
          83.8,
          88.74,
          91.94,
        ],
      },
      {
        1269: [
          "1551",
          2.99,
          57000,
          54.6,
          "3.081",
          "3.072",
          "3.06",
          "3.123",
          "3.052",
          17.096,
          63.5,
          0.84,
          0.11,
          48.85,
          68.49,
          94.65,
        ],
      },
      {
        1270: [
          "0637",
          0.3,
          0,
          54.5,
          "0.294",
          "0.301",
          "0.319",
          "0.327",
          "0.323",
          0,
          2.486,
          72.91,
          0,
          78.82,
          82.51,
          87.59,
        ],
      },
      {
        1271: [
          "8631",
          0.086,
          4700000,
          54.5,
          "0.105",
          "0.111",
          "0.109",
          "0.103",
          "0.109",
          40.827,
          0.344,
          51.01,
          0,
          67.2,
          74.66,
          84.01,
        ],
      },
      {
        1272: [
          "0694",
          5.1,
          4634070,
          54.5,
          "4.994",
          "4.846",
          "4.803",
          "5.04",
          "4.775",
          2346,
          95.8,
          0.26,
          7.6,
          70.19,
          78.47,
          90.83,
        ],
      },
      {
        1273: [
          "3127",
          20.38,
          0,
          54.4,
          "20.7",
          "20.912",
          "20.885",
          "20.99",
          "20.86",
          0,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1274: [
          "1865",
          0.96,
          21524000,
          54.4,
          "0.967",
          "0.964",
          "1.076",
          "1.084",
          "1.067",
          2060,
          8.832,
          24.67,
          0,
          56.53,
          63.68,
          74.89,
        ],
      },
      {
        1275: [
          "1881",
          1.43,
          104097,
          54.4,
          "1.426",
          "1.439",
          "1.515",
          "1.557",
          "1.501",
          14.72,
          46.5,
          70.95,
          0,
          84.82,
          88.8,
          92.39,
        ],
      },
      {
        1276: [
          "0959",
          0.41,
          0,
          54.3,
          "0.408",
          "0.414",
          "0.432",
          "0.449",
          "0.417",
          0,
          0.526,
          20.58,
          0,
          46.87,
          64.44,
          80.32,
        ],
      },
      {
        1277: [
          "0433",
          0.013,
          0,
          54.3,
          "0.013",
          "0.013",
          "0.013",
          "0.013",
          "0.013",
          0,
          3.038,
          1.49,
          0,
          46.45,
          59.61,
          70.86,
        ],
      },
      {
        1278: [
          "3194",
          16.05,
          0,
          54.2,
          "16.252",
          "17.567",
          "17.755",
          "-",
          "17.78",
          0,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1279: [
          "9022",
          73.08,
          0,
          54.2,
          "71.3",
          "71.619",
          "74.434",
          "75.163",
          "74.236",
          0,
          0,
          92.09,
          0,
          98.26,
          99.38,
          100,
        ],
      },
      {
        1280: [
          "1087",
          1.81,
          6700,
          54.2,
          "1.669",
          "1.842",
          "1.717",
          "1.751",
          "1.734",
          1.198,
          1.525,
          2.34,
          0,
          50.19,
          61.82,
          75.71,
        ],
      },
      {
        1281: [
          "1835",
          4.16,
          0,
          54.1,
          "4.142",
          "4.055",
          "4.198",
          "4.287",
          "4.171",
          0,
          1.595,
          0.02,
          0,
          62.29,
          77.49,
          98.38,
        ],
      },
      {
        1282: [
          "2180",
          9.3,
          7250,
          54.1,
          "9.092",
          "9.088",
          "9.152",
          "9.264",
          "9.138",
          6.651,
          19.2,
          36.87,
          0,
          76.79,
          88.36,
          98.33,
        ],
      },
      {
        1283: [
          "1690",
          0.064,
          1510000,
          54.1,
          "0.061",
          "0.062",
          "0.066",
          "0.067",
          "0.066",
          9.924,
          0.979,
          37.66,
          0,
          62.56,
          72.28,
          80.66,
        ],
      },
      {
        1284: [
          "2322",
          0.93,
          2480000,
          54,
          "0.934",
          "0.93",
          "0.957",
          "0.971",
          "0.95",
          232,
          38.3,
          0.05,
          0,
          81.61,
          88.6,
          96.21,
        ],
      },
      {
        1285: [
          "3188",
          59.64,
          1291010,
          54,
          "60.678",
          "61.184",
          "61.057",
          "61.471",
          "60.93",
          7736,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1286: [
          "1349",
          4,
          1044000,
          54,
          "3.967",
          "3.944",
          "4.29",
          "4.431",
          "4.223",
          416,
          13.6,
          0.19,
          11.27,
          51.92,
          62.84,
          78.19,
        ],
      },
      {
        1287: [
          "0670",
          3.14,
          7578000,
          53.9,
          "3.079",
          "2.94",
          "3.027",
          "3.156",
          "3.003",
          2383,
          162.5,
          9.19,
          9.27,
          42.34,
          56.64,
          73.26,
        ],
      },
      {
        1288: [
          "1862",
          2.15,
          757000,
          53.9,
          "1.948",
          "1.931",
          "2.173",
          "2.198",
          "2.17",
          160,
          33,
          1.09,
          0,
          77.11,
          89.2,
          96.1,
        ],
      },
      {
        1289: [
          "0008",
          4.03,
          2900700,
          53.8,
          "4.01",
          "3.99",
          "4.044",
          "4.138",
          "4.037",
          1166,
          311.5,
          44.87,
          0.08,
          65.8,
          75.13,
          85.07,
        ],
      },
      {
        1290: [
          "0252",
          3.25,
          0,
          53.8,
          "3.25",
          "3.277",
          "3.351",
          "3.369",
          "3.346",
          0,
          7.326,
          43.9,
          0,
          90.26,
          95.56,
          97.27,
        ],
      },
      {
        1291: [
          "3618",
          2.92,
          13025000,
          53.8,
          "2.849",
          "2.743",
          "2.888",
          "3.012",
          "2.87",
          3800,
          73.3,
          0.28,
          33.43,
          54.75,
          69.53,
          84.27,
        ],
      },
      {
        1292: [
          "2827",
          48.1,
          600,
          53.7,
          "48.99",
          "49.537",
          "49.364",
          "49.66",
          "49.281",
          2.917,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1293: [
          "1746",
          0.5,
          0,
          53.7,
          "0.518",
          "0.534",
          "0.578",
          "0.624",
          "0.569",
          0,
          5,
          1.01,
          0,
          85.42,
          90.16,
          94.93,
        ],
      },
      {
        1294: [
          "6083",
          0.44,
          1440000,
          53.7,
          "0.386",
          "0.423",
          "0.481",
          "0.467",
          "0.481",
          61.196,
          2.208,
          0.72,
          0,
          64.82,
          76.53,
          87.79,
        ],
      },
      {
        1295: [
          "0330",
          0.74,
          1306300,
          53.6,
          "0.739",
          "0.689",
          "0.707",
          "0.726",
          "0.703",
          95.809,
          20.9,
          0.6,
          0.29,
          42.59,
          55.67,
          75.16,
        ],
      },
      {
        1296: [
          "3699",
          0.51,
          0,
          53.6,
          "0.506",
          "0.51",
          "0.55",
          "0.565",
          "0.54",
          0,
          2.251,
          71.28,
          0,
          82.15,
          88.27,
          95.21,
        ],
      },
      {
        1297: [
          "1386",
          0.455,
          0,
          53.6,
          "0.455",
          "0.455",
          "0.455",
          "0.458",
          "0.455",
          0,
          3.634,
          10.43,
          0,
          79.2,
          83.95,
          90.35,
        ],
      },
      {
        1298: [
          "0825",
          1.2,
          37000,
          53.5,
          "1.218",
          "1.276",
          "1.348",
          "1.339",
          "1.325",
          4.442,
          20.2,
          74.22,
          0,
          85.37,
          88.78,
          92.99,
        ],
      },
      {
        1299: [
          "0512",
          6.29,
          2004000,
          53.5,
          "6.397",
          "6.314",
          "6.444",
          "6.548",
          "6.388",
          1273,
          223.2,
          4.1,
          6.12,
          56.86,
          66.22,
          82.43,
        ],
      },
      {
        1300: [
          "6090",
          1.88,
          0,
          53.5,
          "1.88",
          "1.893",
          "1.902",
          "1.915",
          "1.896",
          0,
          15.8,
          86.69,
          0,
          97.23,
          99.11,
          99.88,
        ],
      },
      {
        1301: [
          "2386",
          3.99,
          5599000,
          53.4,
          "3.901",
          "3.838",
          "4.2",
          "4.336",
          "4.137",
          2233,
          58.2,
          0.48,
          0.9,
          67.49,
          78.4,
          87.59,
        ],
      },
      {
        1302: [
          "0533",
          1.62,
          60000,
          53.4,
          "1.629",
          "1.655",
          "1.705",
          "1.726",
          "1.701",
          9.66,
          15.9,
          74.07,
          0,
          86.42,
          88.25,
          91.42,
        ],
      },
      {
        1303: [
          "1667",
          0.295,
          20000,
          53.3,
          "0.3",
          "0.303",
          "0.292",
          "0.297",
          "0.293",
          0.595,
          2.832,
          0.72,
          0,
          89.61,
          92.29,
          96,
        ],
      },
      {
        1304: [
          "1717",
          9.71,
          5715000,
          53.3,
          "9.611",
          "9.295",
          "8.833",
          "9.482",
          "8.704",
          5563,
          166.8,
          5.67,
          1.91,
          54.54,
          67.22,
          87.48,
        ],
      },
      {
        1305: [
          "0726",
          0.81,
          279250,
          53.3,
          "0.793",
          "0.811",
          "0.82",
          "0.842",
          "0.81",
          22.393,
          25.1,
          9.81,
          0,
          75.09,
          80.65,
          89.04,
        ],
      },
      {
        1306: [
          "2197",
          12.46,
          177000,
          53.2,
          "12.87",
          "-",
          "-",
          "-",
          "-",
          221,
          144.3,
          78.43,
          0,
          90.01,
          94.04,
          98.29,
        ],
      },
      {
        1307: [
          "1983",
          2.79,
          0,
          53.2,
          "2.804",
          "2.557",
          "2.801",
          "2.881",
          "2.765",
          0,
          21,
          0.02,
          0,
          62.81,
          86.29,
          99.81,
        ],
      },
      {
        1308: [
          "7373",
          5.51,
          10000,
          53.2,
          "5.441",
          "5.455",
          "5.613",
          "5.665",
          "5.603",
          5.47,
          0,
          0,
          0,
          95.35,
          98.1,
          99.5,
        ],
      },
      {
        1309: [
          "0315",
          4.31,
          299500,
          53.1,
          "4.243",
          "4.224",
          "4.42",
          "4.467",
          "4.411",
          128,
          47.7,
          73.25,
          0.26,
          84.21,
          87.16,
          92.46,
        ],
      },
      {
        1310: [
          "2381",
          0.1,
          1560000,
          53.1,
          "0.107",
          "0.113",
          "0.115",
          "0.118",
          "0.114",
          15.352,
          2,
          0.01,
          0,
          87.29,
          89.42,
          92.62,
        ],
      },
      {
        1311: [
          "8045",
          0.048,
          666000,
          53.1,
          "0.049",
          "0.045",
          "0.047",
          "0.047",
          "0.047",
          2.968,
          0.242,
          0.07,
          0,
          46.28,
          57.51,
          70.29,
        ],
      },
      {
        1312: [
          "2198",
          2.72,
          2282000,
          53,
          "2.589",
          "2.435",
          "2.777",
          "2.966",
          "2.739",
          610,
          32.3,
          0.07,
          0,
          71.27,
          76.37,
          83.39,
        ],
      },
      {
        1313: [
          "0762",
          4.11,
          64906900,
          53,
          "4.004",
          "3.941",
          "4.089",
          "4.165",
          "4.081",
          26473,
          1257.5,
          79.98,
          4.02,
          84.91,
          88.42,
          94.07,
        ],
      },
      {
        1314: [
          "3130",
          29.22,
          0,
          53,
          "29.614",
          "29.913",
          "29.935",
          "30.116",
          "29.875",
          0,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1315: [
          "2811",
          20.64,
          4600,
          52.9,
          "20.926",
          "21.169",
          "21.104",
          "21.258",
          "21.073",
          9.498,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1316: [
          "0057",
          2.42,
          238000,
          52.9,
          "2.431",
          "2.618",
          "2.625",
          "2.6",
          "2.621",
          57.966,
          15.2,
          65.41,
          0,
          73.35,
          77.74,
          83.51,
        ],
      },
      {
        1317: [
          "8140",
          0.143,
          10000,
          52.8,
          "0.145",
          "0.153",
          "0.157",
          "0.158",
          "0.157",
          0.145,
          1.144,
          59.47,
          0,
          77.43,
          81.01,
          85.43,
        ],
      },
      {
        1318: [
          "1061",
          5.1,
          240000,
          52.8,
          "4.936",
          "5.105",
          "6.008",
          "5.858",
          "5.979",
          121,
          29.3,
          1.19,
          0,
          59.83,
          72.49,
          81.34,
        ],
      },
      {
        1319: [
          "3149",
          16.74,
          0,
          52.8,
          "17.002",
          "17.453",
          "17.392",
          "17.367",
          "17.394",
          0,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1320: [
          "8092",
          0.043,
          192000,
          52.7,
          "0.045",
          "0.045",
          "0.053",
          "0.051",
          "0.053",
          0.798,
          0.398,
          52.3,
          0,
          65.31,
          71,
          81.17,
        ],
      },
      {
        1321: [
          "0294",
          1.97,
          0,
          52.7,
          "1.991",
          "1.99",
          "2.056",
          "2.066",
          "2.048",
          0,
          4.073,
          30.59,
          0,
          74.61,
          80.69,
          88.52,
        ],
      },
      {
        1322: [
          "0983",
          1.37,
          11032,
          52.7,
          "1.316",
          "1.344",
          "1.453",
          "1.451",
          "1.439",
          1.513,
          5.129,
          34.7,
          0,
          70.98,
          75.9,
          83.46,
        ],
      },
      {
        1323: [
          "8241",
          0.228,
          0,
          52.6,
          "0.229",
          "0.244",
          "0.254",
          "0.253",
          "0.255",
          0,
          0.824,
          74.88,
          0,
          83.37,
          87.37,
          91.96,
        ],
      },
      {
        1324: [
          "3100",
          55.84,
          0,
          52.6,
          "56.22",
          "56.592",
          "56.833",
          "57.48",
          "56.577",
          0,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1325: [
          "0215",
          1.28,
          1424000,
          52.6,
          "1.272",
          "1.283",
          "1.358",
          "1.39",
          "1.35",
          182,
          61.6,
          73.79,
          0.12,
          83.92,
          87.82,
          93.28,
        ],
      },
      {
        1326: [
          "3073",
          52.04,
          400,
          52.5,
          "50.96",
          "51.476",
          "53.197",
          "54.195",
          "52.974",
          2.081,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1327: [
          "0212",
          39,
          2500,
          52.5,
          "39.0",
          "39.042",
          "39.393",
          "39.874",
          "39.41",
          9.78,
          13.2,
          20.74,
          0,
          72.56,
          80.47,
          87.53,
        ],
      },
      {
        1328: [
          "1053",
          1.38,
          4192000,
          52.5,
          "1.338",
          "1.318",
          "1.706",
          "1.783",
          "1.697",
          590,
          7.426,
          1.1,
          10.88,
          32.75,
          43.29,
          57.26,
        ],
      },
      {
        1329: [
          "1347",
          40.3,
          7086730,
          52.4,
          "40.055",
          "44.744",
          "43.286",
          "43.721",
          "43.337",
          28808,
          524.3,
          39.35,
          11.44,
          66.36,
          80.65,
          94.08,
        ],
      },
      {
        1330: [
          "1201",
          0.43,
          3810000,
          52.4,
          "0.447",
          "0.47",
          "0.518",
          "0.488",
          "0.522",
          164,
          5.317,
          38.62,
          0,
          68.26,
          74.36,
          83.45,
        ],
      },
      {
        1331: [
          "1036",
          2.4,
          0,
          52.3,
          "2.352",
          "2.356",
          "2.49",
          "2.523",
          "2.494",
          0,
          9.349,
          75.74,
          0,
          88.98,
          91.17,
          93.96,
        ],
      },
      {
        1332: [
          "2208",
          14,
          3889280,
          52.3,
          "14.402",
          "16.528",
          "15.155",
          "14.713",
          "15.29",
          5460,
          108.3,
          0.19,
          3.37,
          69.96,
          81.87,
          89.2,
        ],
      },
      {
        1333: [
          "8259",
          0.39,
          0,
          52.3,
          "0.39",
          "0.39",
          "0.39",
          "0.39",
          "0.39",
          0,
          2.696,
          53.49,
          0,
          67.5,
          74.78,
          84.1,
        ],
      },
      {
        1334: [
          "0641",
          0.42,
          0,
          52.2,
          "0.405",
          "0.402",
          "0.422",
          "0.434",
          "0.42",
          0,
          4.621,
          59.66,
          0,
          80.23,
          85.98,
          93.81,
        ],
      },
      {
        1335: [
          "1555",
          0.059,
          1992000,
          52.2,
          "0.059",
          "0.061",
          "0.056",
          "0.061",
          "0.058",
          11.175,
          1.929,
          0.06,
          0,
          59.57,
          70.72,
          83.11,
        ],
      },
      {
        1336: [
          "1426",
          2.68,
          71000,
          52.2,
          "2.63",
          "2.665",
          "2.795",
          "2.789",
          "2.786",
          18.909,
          39.4,
          0.32,
          0,
          61.47,
          77.35,
          94.18,
        ],
      },
      {
        1337: [
          "8405",
          0.89,
          4000,
          52.1,
          "0.879",
          "0.934",
          "0.914",
          "0.931",
          "0.909",
          0.356,
          3.56,
          73.29,
          0,
          87.44,
          90.15,
          94.35,
        ],
      },
      {
        1338: [
          "8436",
          0.24,
          260000,
          52.1,
          "0.234",
          "0.243",
          "0.253",
          "0.255",
          "0.252",
          6.24,
          0.96,
          0.02,
          0,
          80.02,
          83.54,
          89.36,
        ],
      },
      {
        1339: [
          "2846",
          38.16,
          176149,
          52.1,
          "38.808",
          "39.085",
          "39.159",
          "39.347",
          "39.089",
          684,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1340: [
          "9846",
          4.9,
          18400,
          52,
          "4.974",
          "5.01",
          "5.029",
          "5.056",
          "5.021",
          71.031,
          0,
          89.58,
          0,
          96.8,
          98.7,
          99.94,
        ],
      },
      {
        1341: [
          "3109",
          14.16,
          36100,
          52,
          "14.568",
          "15.368",
          "15.747",
          "15.389",
          "15.696",
          51.319,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1342: [
          "8617",
          0.52,
          0,
          52,
          "0.525",
          "0.521",
          "0.57",
          "0.576",
          "0.564",
          0,
          2.08,
          75,
          0,
          82.54,
          89.09,
          95.25,
        ],
      },
      {
        1343: [
          "0021",
          0.139,
          0,
          51.9,
          "0.138",
          "0.148",
          "0.172",
          "0.183",
          "0.167",
          0,
          5.526,
          49.82,
          0,
          75.41,
          85.74,
          92.39,
        ],
      },
      {
        1344: [
          "1075",
          0.4,
          60000,
          51.9,
          "0.395",
          "0.425",
          "0.426",
          "0.415",
          "0.433",
          2.403,
          3.098,
          1.41,
          0,
          33.4,
          48.68,
          67.07,
        ],
      },
      {
        1345: [
          "0765",
          0.77,
          0,
          51.8,
          "0.77",
          "0.84",
          "0.978",
          "1.116",
          "0.97",
          0,
          2.517,
          0.57,
          0,
          87.38,
          93.62,
          96.97,
        ],
      },
      {
        1346: [
          "9993",
          4.2,
          1483000,
          51.8,
          "4.108",
          "3.892",
          "4.176",
          "4.283",
          "4.17",
          629,
          169.8,
          84.48,
          2.49,
          90.02,
          93.99,
          98.54,
        ],
      },
      {
        1347: [
          "1752",
          0.195,
          0,
          51.8,
          "0.194",
          "0.201",
          "0.186",
          "0.196",
          "0.186",
          0,
          4.745,
          51.09,
          0,
          74.73,
          87.28,
          95.49,
        ],
      },
      {
        1348: [
          "0075",
          1.6,
          84666,
          51.7,
          "1.637",
          "1.66",
          "1.579",
          "1.615",
          "1.587",
          13.522,
          12.7,
          1.38,
          0,
          53.53,
          69.76,
          85.24,
        ],
      },
      {
        1349: [
          "1425",
          0.435,
          5075000,
          51.7,
          "0.445",
          "0.457",
          "0.507",
          "0.492",
          "0.503",
          215,
          5.438,
          4.29,
          0,
          87.8,
          94.13,
          97.54,
        ],
      },
      {
        1350: [
          "8439",
          1.5,
          0,
          51.7,
          "1.49",
          "1.357",
          "1.433",
          "1.428",
          "1.437",
          0,
          2.13,
          68.36,
          0,
          77.99,
          84.31,
          92.84,
        ],
      },
      {
        1351: [
          "0089",
          3.98,
          11000,
          51.6,
          "4.0",
          "4.05",
          "4.195",
          "4.216",
          "4.185",
          4.39,
          11.4,
          72.18,
          0,
          76.73,
          79.32,
          83.7,
        ],
      },
      {
        1352: [
          "0013",
          52.4,
          1884560,
          51.6,
          "54.245",
          "53.588",
          "-",
          "-",
          "-",
          9968,
          453,
          82,
          0.28,
          93.48,
          96.68,
          98.77,
        ],
      },
      {
        1353: [
          "2393",
          1.06,
          52500,
          51.6,
          "1.109",
          "1.143",
          "1.112",
          "1.124",
          "1.109",
          5.572,
          24.7,
          22.13,
          0,
          46.19,
          65.09,
          87.52,
        ],
      },
      {
        1354: [
          "0168",
          67,
          4833270,
          51.5,
          "68.82",
          "68.039",
          "68.538",
          "70.454",
          "67.298",
          32336,
          438.8,
          4.56,
          25.08,
          57.2,
          77.24,
          90.35,
        ],
      },
      {
        1355: [
          "0845",
          0.227,
          485000,
          51.5,
          "0.232",
          "0.233",
          "0.229",
          "0.234",
          "0.23",
          10.782,
          17.6,
          0.07,
          0.28,
          75.43,
          81.19,
          88.83,
        ],
      },
      {
        1356: [
          "3322",
          0.33,
          80000,
          51.5,
          "0.331",
          "0.33",
          "0.329",
          "0.332",
          "0.331",
          2.6,
          4.238,
          51.78,
          0,
          64.45,
          71.01,
          79.28,
        ],
      },
      {
        1357: [
          "6968",
          4.41,
          1279000,
          51.4,
          "4.459",
          "4.235",
          "4.346",
          "4.392",
          "4.365",
          555,
          71.8,
          22.59,
          0,
          55.16,
          69.81,
          88.47,
        ],
      },
      {
        1358: [
          "2802",
          65.24,
          1000,
          51.4,
          "64.058",
          "64.431",
          "66.747",
          "67.763",
          "66.538",
          6.521,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1359: [
          "1191",
          0.021,
          0,
          51.3,
          "0.021",
          "0.021",
          "0.03",
          "0.036",
          "0.028",
          0,
          0.892,
          17.86,
          0,
          37.87,
          48.79,
          63.92,
        ],
      },
      {
        1360: [
          "8370",
          0.32,
          2328000,
          51.3,
          "0.329",
          "0.332",
          "0.363",
          "0.355",
          "0.364",
          74.824,
          2.903,
          56.88,
          0,
          68.82,
          77.89,
          87.44,
        ],
      },
      {
        1361: [
          "0686",
          0.265,
          8212000,
          51.3,
          "0.282",
          "0.271",
          "0.279",
          "0.274",
          "0.28",
          218,
          59.4,
          34.87,
          1.24,
          75.1,
          85.24,
          92.22,
        ],
      },
      {
        1362: [
          "1116",
          0.189,
          380000,
          51.2,
          "0.19",
          "0.189",
          "0.203",
          "0.21",
          "0.201",
          6.997,
          4.079,
          47.78,
          0,
          91.58,
          94.11,
          96.36,
        ],
      },
      {
        1363: [
          "0574",
          0.063,
          242000,
          51.2,
          "0.063",
          "0.067",
          "0.072",
          "0.074",
          "0.072",
          1.579,
          0.929,
          0.74,
          0,
          65.16,
          71.28,
          79.61,
        ],
      },
      {
        1364: [
          "0366",
          1.28,
          122000,
          51.2,
          "1.272",
          "1.265",
          "1.361",
          "1.349",
          "1.357",
          15.67,
          6.433,
          69.95,
          0,
          81.56,
          85.15,
          89.44,
        ],
      },
      {
        1365: [
          "1245",
          0.255,
          30000,
          51.1,
          "0.262",
          "0.275",
          "0.295",
          "0.284",
          "0.295",
          0.751,
          3.049,
          69.04,
          0,
          93.29,
          94.66,
          96.08,
        ],
      },
      {
        1366: [
          "2880",
          0.73,
          474200,
          51.1,
          "0.726",
          "0.718",
          "0.737",
          "0.759",
          "0.735",
          34.477,
          37.6,
          0.71,
          17.44,
          88.6,
          91.84,
          95.13,
        ],
      },
      {
        1367: [
          "3337",
          0.48,
          10300000,
          51.1,
          "0.462",
          "0.452",
          "0.475",
          "0.484",
          "0.473",
          498,
          14.4,
          0.16,
          0,
          34.89,
          50.98,
          70.9,
        ],
      },
      {
        1368: [
          "1459",
          0.76,
          0,
          51,
          "0.739",
          "0.781",
          "0.819",
          "0.814",
          "0.812",
          0,
          1.014,
          2.37,
          0,
          49.18,
          60.02,
          73.93,
        ],
      },
      {
        1369: [
          "0817",
          2.67,
          28757500,
          51,
          "2.604",
          "2.459",
          "2.49",
          "2.612",
          "2.486",
          7724,
          338.7,
          26.57,
          9.12,
          59.95,
          73.66,
          92.9,
        ],
      },
      {
        1370: [
          "6822",
          1.31,
          0,
          50.9,
          "1.307",
          "1.299",
          "1.383",
          "1.391",
          "-",
          0,
          9.17,
          75.03,
          0,
          84.14,
          87.39,
          90.89,
        ],
      },
      {
        1371: [
          "0704",
          0.305,
          0,
          50.9,
          "0.305",
          "0.305",
          "0.305",
          "0.305",
          "0.305",
          0,
          0.876,
          10.42,
          0,
          68.95,
          75.72,
          85.75,
        ],
      },
      {
        1372: [
          "0154",
          0.57,
          123000,
          50.9,
          "0.569",
          "0.593",
          "0.588",
          "0.583",
          "0.591",
          6.889,
          8.552,
          50.06,
          0,
          78.08,
          81.43,
          85.91,
        ],
      },
      {
        1373: [
          "1843",
          0.28,
          732000,
          50.8,
          "0.287",
          "0.282",
          "0.332",
          "0.34",
          "0.329",
          20.388,
          2.24,
          0,
          0,
          85.89,
          89.15,
          93.41,
        ],
      },
      {
        1374: [
          "0156",
          0.12,
          450000,
          50.8,
          "0.119",
          "0.123",
          "0.123",
          "0.124",
          "0.124",
          5.282,
          11,
          0.77,
          0,
          80.89,
          83.82,
          87.39,
        ],
      },
      {
        1375: [
          "8036",
          0.09,
          0,
          50.8,
          "0.092",
          "0.094",
          "0.099",
          "0.098",
          "0.098",
          0,
          1.107,
          54.81,
          0,
          80.76,
          92.13,
          96.26,
        ],
      },
      {
        1376: [
          "3010",
          62.94,
          9659,
          50.7,
          "61.81",
          "62.368",
          "64.774",
          "65.816",
          "64.534",
          60.802,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1377: [
          "3768",
          1.88,
          134000,
          50.7,
          "1.851",
          "1.826",
          "1.91",
          "1.943",
          "1.898",
          25.245,
          6.392,
          0.14,
          0,
          63,
          73.58,
          84.76,
        ],
      },
      {
        1378: [
          "9010",
          8.095,
          1300,
          50.7,
          "7.924",
          "8.002",
          "8.326",
          "8.463",
          "8.292",
          8.231,
          0,
          16.06,
          0,
          98.11,
          99.77,
          99.98,
        ],
      },
      {
        1379: [
          "0489",
          7.22,
          5028730,
          50.6,
          "6.853",
          "7.042",
          "7.2",
          "7.195",
          "7.217",
          3617,
          206.1,
          0.15,
          6.66,
          79.61,
          86.28,
          92.32,
        ],
      },
      {
        1380: [
          "3012",
          20.68,
          4500,
          50.6,
          "20.161",
          "20.643",
          "21.679",
          "22.105",
          "21.584",
          9.479,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1381: [
          "1707",
          0.34,
          112000,
          50.6,
          "0.328",
          "0.33",
          "0.343",
          "0.345",
          "0.347",
          3.412,
          5.712,
          0.08,
          0,
          84.16,
          88.93,
          94.07,
        ],
      },
      {
        1382: [
          "0174",
          0.8,
          0,
          50.5,
          "0.68",
          "0.683",
          "0.728",
          "0.794",
          "0.727",
          0,
          5.084,
          6.54,
          0,
          74.62,
          86.03,
          94.98,
        ],
      },
      {
        1383: [
          "2339",
          0.67,
          34000,
          50.5,
          "0.702",
          "0.778",
          "0.737",
          "0.688",
          "0.744",
          2.256,
          3.848,
          52.69,
          0,
          64.32,
          70.83,
          80.45,
        ],
      },
      {
        1384: [
          "8279",
          0.265,
          4788000,
          50.4,
          "0.277",
          "0.286",
          "0.292",
          "0.289",
          "0.29",
          127,
          30.9,
          56.35,
          0,
          80.98,
          87.04,
          92.56,
        ],
      },
      {
        1385: [
          "0737",
          2.74,
          29860,
          50.4,
          "2.751",
          "2.788",
          "2.863",
          "2.9",
          "2.854",
          8.199,
          84.4,
          2.05,
          0.04,
          91.86,
          95.14,
          96.53,
        ],
      },
      {
        1386: [
          "0878",
          7.68,
          0,
          50.4,
          "7.66",
          "7.637",
          "7.979",
          "8.155",
          "7.926",
          0,
          21.7,
          73.66,
          0,
          80.45,
          84.83,
          89.72,
        ],
      },
      {
        1387: [
          "1455",
          0.235,
          30000,
          50.3,
          "0.234",
          "0.246",
          "0.267",
          "0.253",
          "0.268",
          0.7,
          3.005,
          75.58,
          0,
          88.13,
          91.2,
          94.88,
        ],
      },
      {
        1388: [
          "0538",
          1.27,
          32000,
          50.3,
          "1.268",
          "1.262",
          "1.384",
          "1.377",
          "1.377",
          4.112,
          13.8,
          2.77,
          0,
          76.64,
          85.76,
          94.58,
        ],
      },
      {
        1389: [
          "1545",
          0.131,
          0,
          50.3,
          "0.133",
          "0.134",
          "0.147",
          "0.15",
          "0.143",
          0,
          2.62,
          75.06,
          0,
          84.06,
          88.09,
          93.04,
        ],
      },
      {
        1390: [
          "3024",
          32.62,
          1000,
          50.2,
          "33.266",
          "33.243",
          "33.03",
          "33.585",
          "32.926",
          3.262,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1391: [
          "0635",
          0.81,
          360000,
          50.2,
          "0.792",
          "0.789",
          "0.841",
          "0.857",
          "0.835",
          28.86,
          16.9,
          65.92,
          0,
          73.9,
          78.25,
          84.43,
        ],
      },
      {
        1392: [
          "8668",
          0.14,
          0,
          50.2,
          "0.14",
          "0.152",
          "0.156",
          "0.153",
          "0.156",
          0,
          1.68,
          75.46,
          0,
          91.34,
          95.36,
          98.56,
        ],
      },
      {
        1393: [
          "2700",
          0.38,
          0,
          50.1,
          "0.379",
          "0.397",
          "0.411",
          "0.397",
          "-",
          0,
          2.508,
          60.39,
          0,
          85.67,
          92.06,
          96.61,
        ],
      },
      {
        1394: [
          "2223",
          0.66,
          0,
          50.1,
          "0.633",
          "0.656",
          "0.672",
          "0.692",
          "0.668",
          0,
          1.702,
          50.53,
          0,
          74.23,
          81.72,
          90.36,
        ],
      },
      {
        1395: [
          "6196",
          1.76,
          744100,
          50.1,
          "1.721",
          "1.757",
          "1.764",
          "1.825",
          "1.757",
          130,
          32.3,
          0.01,
          10.16,
          38.93,
          52.42,
          80.39,
        ],
      },
      {
        1396: [
          "1913",
          47.8,
          323936,
          50,
          "48.035",
          "50.665",
          "50.684",
          "50.503",
          "50.29",
          1538,
          1223.1,
          79.99,
          0,
          96.33,
          98.68,
          99.82,
        ],
      },
      {
        1397: [
          "0162",
          0.168,
          0,
          50,
          "0.166",
          "0.158",
          "0.165",
          "0.171",
          "0.166",
          0,
          1.931,
          29.55,
          0,
          63.27,
          70.41,
          82.43,
        ],
      },
      {
        1398: [
          "0146",
          1.09,
          0,
          49.9,
          "1.018",
          "1.058",
          "1.092",
          "1.115",
          "1.083",
          0,
          2.313,
          63.72,
          0,
          86.73,
          88.51,
          91.87,
        ],
      },
      {
        1399: [
          "0105",
          12.7,
          0,
          49.9,
          "12.62",
          "12.915",
          "13.261",
          "13.388",
          "13.203",
          0,
          45.7,
          56.67,
          0,
          81.65,
          90.83,
          96.93,
        ],
      },
      {
        1400: [
          "0158",
          146,
          0,
          49.9,
          "146.03",
          "146.918",
          "151.767",
          "153.323",
          "151.247",
          0,
          36.5,
          90.78,
          0,
          95.7,
          97.57,
          98.7,
        ],
      },
      {
        1401: [
          "3626",
          0.48,
          4000,
          49.8,
          "0.504",
          "0.664",
          "0.712",
          "0.637",
          "0.728",
          0.194,
          0.883,
          75.02,
          0,
          87.06,
          89.87,
          93.95,
        ],
      },
      {
        1402: [
          "6066",
          8.72,
          3705900,
          49.8,
          "8.548",
          "8.471",
          "8.638",
          "8.874",
          "8.605",
          3258,
          109.9,
          0.02,
          29.37,
          59.81,
          75.51,
          85.17,
        ],
      },
      {
        1403: [
          "0356",
          0.041,
          0,
          49.8,
          "0.042",
          "0.041",
          "0.041",
          "0.041",
          "0.041",
          0,
          1.121,
          16.9,
          0,
          68.58,
          84.4,
          91.87,
        ],
      },
      {
        1404: [
          "2317",
          0.73,
          0,
          49.7,
          "0.717",
          "0.754",
          "0.759",
          "0.766",
          "0.759",
          0,
          11.1,
          58.48,
          0,
          85.29,
          89.58,
          94.88,
        ],
      },
      {
        1405: [
          "1181",
          0.78,
          0,
          49.7,
          "0.763",
          "0.714",
          "0.76",
          "0.798",
          "0.755",
          0,
          8.393,
          42.76,
          0,
          73.08,
          85.14,
          93.9,
        ],
      },
      {
        1406: [
          "0823",
          66.9,
          4138180,
          49.7,
          "68.04",
          "68.523",
          "70.76",
          "71.401",
          "70.322",
          27739,
          1411.7,
          3.84,
          0,
          71.89,
          79.19,
          88.84,
        ],
      },
      {
        1407: [
          "9938",
          0.124,
          570000,
          49.6,
          "0.126",
          "0.133",
          "0.135",
          "0.137",
          "0.135",
          7.004,
          1.24,
          0.21,
          0,
          83.32,
          85.56,
          89.89,
        ],
      },
      {
        1408: [
          "0118",
          0.43,
          0,
          49.6,
          "0.427",
          "0.431",
          "0.428",
          "0.45",
          "0.422",
          0,
          3.706,
          57.3,
          0,
          82.22,
          86.67,
          92.01,
        ],
      },
      {
        1409: [
          "2326",
          0.014,
          0,
          49.6,
          "0.015",
          "0.016",
          "0.018",
          "0.018",
          "0.018",
          0,
          2.952,
          61.38,
          0.26,
          74.86,
          82.21,
          90.03,
        ],
      },
      {
        1410: [
          "0703",
          0.158,
          72000,
          49.5,
          "0.16",
          "0.167",
          "0.169",
          "0.173",
          "0.169",
          1.147,
          1.097,
          1.26,
          0,
          66.64,
          72.4,
          81.36,
        ],
      },
      {
        1411: [
          "6806",
          1.98,
          5700800,
          49.5,
          "1.938",
          "1.951",
          "2.001",
          "2.052",
          "2.001",
          1131,
          49.5,
          0.01,
          20.01,
          51.18,
          67.53,
          91.43,
        ],
      },
      {
        1412: [
          "1370",
          1.06,
          0,
          49.4,
          "1.06",
          "1.06",
          "1.06",
          "1.06",
          "1.06",
          0,
          17.3,
          6.08,
          0,
          75.83,
          81.97,
          90.26,
        ],
      },
      {
        1413: [
          "2118",
          2,
          20000,
          49.4,
          "1.989",
          "1.957",
          "1.93",
          "1.955",
          "1.932",
          4.018,
          20.1,
          1.01,
          0,
          69.04,
          77.19,
          87.46,
        ],
      },
      {
        1414: [
          "2016",
          3.3,
          22000,
          49.4,
          "3.275",
          "3.195",
          "3.38",
          "3.419",
          "3.357",
          7.225,
          150.2,
          0,
          11.01,
          59.89,
          83.45,
          99.16,
        ],
      },
      {
        1415: [
          "1376",
          0.107,
          0,
          49.3,
          "0.107",
          "0.107",
          "0.107",
          "0.107",
          "0.107",
          0,
          1.07,
          0.1,
          0,
          80.75,
          85.39,
          90.22,
        ],
      },
      {
        1416: [
          "2025",
          0.94,
          50000,
          49.3,
          "0.936",
          "0.921",
          "0.956",
          "0.96",
          "0.955",
          4.7,
          7.52,
          51.39,
          0,
          85.44,
          91.72,
          98.26,
        ],
      },
      {
        1417: [
          "8506",
          1.31,
          0,
          49.3,
          "1.31",
          "1.31",
          "1.31",
          "1.308",
          "1.31",
          0,
          13.1,
          59.85,
          0,
          91.06,
          94.25,
          97.68,
        ],
      },
      {
        1418: [
          "1221",
          2.23,
          4000,
          49.2,
          "2.284",
          "2.232",
          "2.325",
          "2.365",
          "2.31",
          0.91,
          25.4,
          74.14,
          0,
          93.57,
          97.9,
          99.36,
        ],
      },
      {
        1419: [
          "8490",
          0.315,
          0,
          49.2,
          "0.296",
          "0.339",
          "0.324",
          "0.324",
          "0.324",
          0,
          2.222,
          72.3,
          0,
          86.78,
          90,
          93.26,
        ],
      },
      {
        1420: [
          "0720",
          0.166,
          0,
          49.2,
          "0.166",
          "0.164",
          "0.174",
          "0.18",
          "0.172",
          0,
          8.786,
          7.6,
          0,
          58.16,
          70.67,
          87.59,
        ],
      },
      {
        1421: [
          "8143",
          0.053,
          12000,
          49.1,
          "0.055",
          "0.054",
          "0.063",
          "0.061",
          "0.063",
          0,
          1.494,
          12.4,
          0,
          76.13,
          81.08,
          88.61,
        ],
      },
      {
        1422: [
          "0229",
          1.03,
          0,
          49.1,
          "1.018",
          "1.024",
          "1.058",
          "1.088",
          "1.049",
          0,
          5.162,
          45.02,
          0,
          66.14,
          72.32,
          78.19,
        ],
      },
      {
        1423: [
          "0298",
          0.395,
          110000,
          49.1,
          "0.392",
          "0.395",
          "0.431",
          "0.436",
          "0.429",
          4.345,
          9.275,
          53.68,
          0,
          62.83,
          68.62,
          77.96,
        ],
      },
      {
        1424: [
          "0631",
          7.81,
          817841,
          49,
          "7.584",
          "7.86",
          "8.652",
          "8.791",
          "8.642",
          639,
          246.3,
          61.14,
          7.2,
          74.7,
          82.73,
          92.14,
        ],
      },
      {
        1425: [
          "2660",
          1.18,
          210000,
          49,
          "1.191",
          "1.247",
          "1.267",
          "1.278",
          "1.264",
          24.45,
          12,
          50.72,
          0,
          77.61,
          85.13,
          93.72,
        ],
      },
      {
        1426: [
          "0459",
          0.127,
          197600,
          48.9,
          "0.13",
          "0.13",
          "0.148",
          "0.151",
          "0.146",
          2.52,
          2.293,
          51.86,
          0,
          67.8,
          74.32,
          81.36,
        ],
      },
      {
        1427: [
          "8148",
          0.021,
          0,
          48.9,
          "0.021",
          "0.023",
          "0.022",
          "0.023",
          "0.022",
          0,
          0.267,
          0.17,
          0,
          64.81,
          72.75,
          82.26,
        ],
      },
      {
        1428: [
          "8120",
          0.125,
          840000,
          48.9,
          "0.121",
          "0.123",
          "0.123",
          "0.14",
          "0.123",
          10.052,
          0.765,
          23.89,
          0,
          66.41,
          73.89,
          84.14,
        ],
      },
      {
        1429: [
          "1381",
          3.75,
          501000,
          48.8,
          "3.894",
          "4.106",
          "4.247",
          "4.153",
          "4.24",
          187,
          91.4,
          12.3,
          2.08,
          70.47,
          81.82,
          92.34,
        ],
      },
      {
        1430: [
          "2165",
          3.94,
          8000,
          48.8,
          "3.892",
          "3.958",
          "-",
          "-",
          "-",
          3.014,
          11.2,
          69.83,
          0,
          81.43,
          90.14,
          98.88,
        ],
      },
      {
        1431: [
          "8348",
          0.32,
          0,
          48.8,
          "0.337",
          "0.339",
          "0.334",
          "0.334",
          "0.333",
          0,
          0.314,
          20.57,
          0,
          50.83,
          63.61,
          81.41,
        ],
      },
      {
        1432: [
          "9618",
          293.2,
          7179350,
          48.7,
          "272.52",
          "304.196",
          "295.904",
          "296.09",
          "298.247",
          210000,
          9176.5,
          56.14,
          0,
          83.76,
          92.98,
          97.79,
        ],
      },
      {
        1433: [
          "0757",
          0.385,
          3290000,
          48.7,
          "0.398",
          "0.427",
          "0.462",
          "0.442",
          "0.465",
          127,
          12.7,
          18.28,
          0,
          49.96,
          66.31,
          78.49,
        ],
      },
      {
        1434: [
          "0071",
          13.26,
          18000,
          48.7,
          "12.966",
          "12.834",
          "13.747",
          "14.092",
          "13.633",
          23.69,
          91.6,
          63.95,
          0,
          82.53,
          87.53,
          93.27,
        ],
      },
      {
        1435: [
          "0224",
          1.01,
          0,
          48.6,
          "1.012",
          "1.019",
          "1.048",
          "1.06",
          "1.046",
          0,
          11.6,
          11.7,
          0,
          72.33,
          89.33,
          95.04,
        ],
      },
      {
        1436: [
          "1252",
          6.11,
          1190000,
          48.6,
          "6.294",
          "6.169",
          "6.143",
          "6.198",
          "6.17",
          725,
          179.5,
          6.06,
          0,
          43.68,
          55.64,
          76.81,
        ],
      },
      {
        1437: [
          "0351",
          0.4,
          60000,
          48.5,
          "0.396",
          "0.422",
          "0.449",
          "0.457",
          "0.446",
          2.405,
          6.78,
          64.91,
          0,
          75.88,
          81.81,
          91.2,
        ],
      },
      {
        1438: [
          "2633",
          0.7,
          212000,
          48.5,
          "0.688",
          "0.553",
          "0.623",
          "0.681",
          "0.613",
          14.512,
          13.5,
          24.32,
          0,
          78.58,
          83.46,
          90.12,
        ],
      },
      {
        1439: [
          "1240",
          0.7,
          107500,
          48.5,
          "0.691",
          "0.683",
          "0.733",
          "0.756",
          "0.73",
          7.525,
          10.6,
          41.05,
          0,
          87.76,
          91.36,
          94.71,
        ],
      },
      {
        1440: [
          "6168",
          0.161,
          0,
          48.4,
          "0.161",
          "0.161",
          "0.161",
          "0.164",
          "0.161",
          0,
          4.604,
          19.28,
          0,
          47.01,
          61.5,
          84.68,
        ],
      },
      {
        1441: [
          "1709",
          2.71,
          4115000,
          48.4,
          "2.629",
          "2.616",
          "2.695",
          "2.835",
          "2.69",
          1111,
          38.7,
          0.07,
          0,
          59.34,
          79.87,
          93.05,
        ],
      },
      {
        1442: [
          "0026",
          97.1,
          2021,
          48.4,
          "95.955",
          "98.559",
          "104.241",
          "104.435",
          "103.963",
          19.414,
          43.9,
          70.83,
          0,
          82.66,
          89.15,
          94.07,
        ],
      },
      {
        1443: [
          "0434",
          0.51,
          180000,
          48.3,
          "0.512",
          "0.512",
          "0.508",
          "0.532",
          "0.501",
          9.165,
          3.62,
          0.04,
          0,
          60.43,
          69.37,
          81.91,
        ],
      },
      {
        1444: [
          "2558",
          1.41,
          0,
          48.3,
          "1.435",
          "1.437",
          "1.476",
          "1.497",
          "1.469",
          0,
          13.6,
          0.02,
          0,
          54.96,
          76.08,
          99.64,
        ],
      },
      {
        1445: [
          "1120",
          0.69,
          0,
          48.3,
          "0.687",
          "0.667",
          "0.692",
          "0.713",
          "0.689",
          0,
          2.665,
          51.91,
          0,
          74.66,
          81.92,
          89.18,
        ],
      },
      {
        1446: [
          "2060",
          3.15,
          133000,
          48.2,
          "3.355",
          "3.387",
          "3.454",
          "3.426",
          "3.46",
          42.272,
          25.5,
          0,
          0,
          60.61,
          73.34,
          87.24,
        ],
      },
      {
        1447: [
          "0070",
          0.072,
          1360500,
          48.2,
          "0.077",
          "0.081",
          "0.093",
          "0.102",
          "0.091",
          10.775,
          1.396,
          55.35,
          0,
          82.41,
          85.25,
          90.49,
        ],
      },
      {
        1448: [
          "6178",
          6.12,
          822000,
          48.2,
          "6.058",
          "6.024",
          "6.205",
          "6.312",
          "6.18",
          503,
          43,
          0.06,
          14.54,
          52.5,
          72.25,
          89.62,
        ],
      },
      {
        1449: [
          "3128",
          67.6,
          3200,
          48.1,
          "68.884",
          "67.764",
          "68.177",
          "69.805",
          "67.891",
          21.675,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1450: [
          "0659",
          7.52,
          758000,
          48.1,
          "7.408",
          "7.432",
          "7.647",
          "7.884",
          "7.578",
          569,
          294.1,
          76.31,
          0.07,
          86.33,
          89.11,
          94.07,
        ],
      },
      {
        1451: [
          "8601",
          0.27,
          0,
          48,
          "0.284",
          "0.369",
          "0.343",
          "0.323",
          "0.345",
          0,
          2.16,
          75,
          0,
          89.24,
          93.78,
          98.29,
        ],
      },
      {
        1452: [
          "1895",
          2.018,
          0,
          48,
          "2.018",
          "2.018",
          "2.079",
          "2.089",
          "2.076",
          0,
          11.4,
          5.83,
          0,
          63.93,
          74.7,
          87.84,
        ],
      },
      {
        1453: [
          "9151",
          1.32,
          0,
          48,
          "1.351",
          "1.419",
          "-",
          "-",
          "-",
          0,
          0,
          97.74,
          0,
          99.57,
          99.92,
          197.74,
        ],
      },
      {
        1454: [
          "9963",
          1.34,
          38000,
          47.9,
          "1.408",
          "1.348",
          "1.393",
          "1.406",
          "1.395",
          4.842,
          3.484,
          83.5,
          0,
          92.12,
          94.3,
          96.51,
        ],
      },
      {
        1455: [
          "1856",
          2.53,
          200000,
          47.9,
          "2.523",
          "2.542",
          "2.584",
          "2.601",
          "2.591",
          50.723,
          8.79,
          0.02,
          0,
          79.67,
          86.47,
          96.71,
        ],
      },
      {
        1456: [
          "0500",
          0.62,
          88000,
          47.9,
          "0.641",
          "0.638",
          "0.743",
          "0.758",
          "0.735",
          5.683,
          14.5,
          28.96,
          0,
          46.09,
          58.56,
          76.72,
        ],
      },
      {
        1457: [
          "1483",
          1.4,
          0,
          47.8,
          "1.491",
          "1.461",
          "1.4",
          "1.511",
          "1.404",
          0,
          11.1,
          3.77,
          0,
          64.33,
          75.51,
          90.64,
        ],
      },
      {
        1458: [
          "1869",
          0.3,
          160000,
          47.8,
          "0.294",
          "0.325",
          "0.352",
          "0.353",
          "0.35",
          4.665,
          3,
          42.95,
          0,
          85.28,
          89.11,
          94.72,
        ],
      },
      {
        1459: [
          "9801",
          3.502,
          600000,
          47.8,
          "3.401",
          "3.541",
          "-",
          "-",
          "-",
          1628,
          0,
          98.32,
          0,
          99.95,
          99.99,
          100,
        ],
      },
      {
        1460: [
          "1979",
          2.09,
          216000,
          47.7,
          "1.969",
          "1.9",
          "2.108",
          "2.101",
          "2.112",
          44.404,
          20.9,
          30.51,
          0,
          73.21,
          84.92,
          92.06,
        ],
      },
      {
        1461: [
          "2116",
          0.365,
          106000,
          47.7,
          "0.326",
          "0.358",
          "0.384",
          "0.384",
          "0.381",
          3.794,
          1.752,
          75.02,
          0,
          86.84,
          88.93,
          92.3,
        ],
      },
      {
        1462: [
          "6889",
          6.96,
          281400,
          47.7,
          "6.946",
          "7.01",
          "7.292",
          "7.269",
          "7.275",
          196,
          50.9,
          75.61,
          0,
          87.69,
          93.02,
          96.05,
        ],
      },
      {
        1463: [
          "3121",
          9.005,
          0,
          47.6,
          "8.942",
          "9.061",
          "9.439",
          "9.623",
          "9.395",
          0,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1464: [
          "0722",
          0.83,
          204000,
          47.6,
          "0.844",
          "0.824",
          "0.873",
          "0.86",
          "0.861",
          16.936,
          6.435,
          52.14,
          0,
          71.99,
          80.71,
          92.28,
        ],
      },
      {
        1465: [
          "1079",
          0.087,
          1060000,
          47.5,
          "0.09",
          "0.106",
          "0.095",
          "0.096",
          "0.095",
          9.032,
          1.154,
          1.95,
          0,
          60.96,
          67.39,
          78.13,
        ],
      },
      {
        1466: [
          "0160",
          2.9,
          0,
          47.5,
          "2.906",
          "2.871",
          "3.23",
          "3.277",
          "3.209",
          0,
          20.8,
          42.01,
          0,
          61.96,
          76.72,
          89.41,
        ],
      },
      {
        1467: [
          "0485",
          0.224,
          0,
          47.5,
          "0.224",
          "0.23",
          "0.24",
          "0.278",
          "0.238",
          0,
          0.477,
          2.87,
          0,
          77.85,
          82.82,
          88.45,
        ],
      },
      {
        1468: [
          "9616",
          5.13,
          39200,
          47.4,
          "5.208",
          "5.295",
          "5.494",
          "5.518",
          "5.434",
          19.98,
          34.3,
          11.08,
          0,
          71.19,
          79.16,
          90.41,
        ],
      },
      {
        1469: [
          "1069",
          0.01,
          0,
          47.4,
          "0.01",
          "0.01",
          "0.011",
          "0.011",
          "0.01",
          0,
          1.102,
          1.84,
          0,
          58.11,
          64.51,
          76.09,
        ],
      },
      {
        1470: [
          "3111",
          2.97,
          86800,
          47.4,
          "3.019",
          "-",
          "-",
          "-",
          "-",
          25.76,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1471: [
          "1348",
          0.255,
          1328000,
          47.3,
          "0.251",
          "0.254",
          "0.264",
          "0.268",
          "0.263",
          33.254,
          3.759,
          0.83,
          0,
          46.37,
          60.51,
          79.46,
        ],
      },
      {
        1472: [
          "6055",
          15.66,
          531000,
          47.3,
          "15.602",
          "16.969",
          "16.528",
          "16.894",
          "16.466",
          831,
          108.3,
          72.31,
          5.49,
          82.52,
          85.66,
          89.55,
        ],
      },
      {
        1473: [
          "1822",
          0.065,
          0,
          47.3,
          "0.065",
          "0.065",
          "0.059",
          "0.061",
          "0.06",
          0,
          0.223,
          21.19,
          0,
          65.83,
          72.66,
          82.18,
        ],
      },
      {
        1474: [
          "0668",
          0.22,
          0,
          47.2,
          "0.22",
          "0.229",
          "0.231",
          "0.232",
          "0.23",
          0,
          2.803,
          15.06,
          0,
          91.8,
          95.42,
          97.4,
        ],
      },
      {
        1475: [
          "1490",
          0.74,
          5476000,
          47.2,
          "0.895",
          "0.824",
          "0.795",
          "0.901",
          "0.782",
          426,
          9.136,
          69.05,
          0,
          80.29,
          85.32,
          91.3,
        ],
      },
      {
        1476: [
          "1831",
          0.2,
          0,
          47.2,
          "0.2",
          "0.237",
          "0.314",
          "0.282",
          "0.31",
          0,
          1.887,
          57.98,
          0,
          71.36,
          80,
          89.93,
        ],
      },
      {
        1477: [
          "0496",
          0.74,
          99000,
          47.1,
          "0.749",
          "0.657",
          "0.755",
          "0.796",
          "0.739",
          7.423,
          11,
          9.42,
          7.63,
          62.76,
          76.41,
          91.1,
        ],
      },
      {
        1478: [
          "3151",
          10.23,
          30000,
          47.1,
          "10.524",
          "11.061",
          "-",
          "-",
          "-",
          30.903,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1479: [
          "2155",
          8.84,
          920000,
          47,
          "8.535",
          "11.078",
          "-",
          "-",
          "-",
          809,
          91.7,
          68.68,
          0,
          85.36,
          91.35,
          97.03,
        ],
      },
      {
        1480: [
          "0210",
          0.17,
          8000,
          47,
          "0.172",
          "0.16",
          "0.167",
          "0.17",
          "0.168",
          0.131,
          3.084,
          24.75,
          0,
          56.18,
          72.14,
          83.12,
        ],
      },
      {
        1481: [
          "0123",
          7.31,
          3586570,
          47,
          "7.135",
          "7.054",
          "7.384",
          "7.839",
          "7.313",
          2631,
          226.3,
          34.01,
          1.66,
          66.55,
          76.3,
          87.44,
        ],
      },
      {
        1482: [
          "2666",
          5.69,
          2319500,
          46.9,
          "5.599",
          "5.518",
          "6.106",
          "6.236",
          "6.055",
          1315,
          107.6,
          34.26,
          14.46,
          58.66,
          68.87,
          82.9,
        ],
      },
      {
        1483: [
          "2122",
          0.23,
          78000,
          46.9,
          "0.227",
          "0.233",
          "0.25",
          "0.251",
          "0.252",
          1.757,
          1.84,
          4.91,
          0,
          72.29,
          79.13,
          88.27,
        ],
      },
      {
        1484: [
          "0292",
          0.2,
          160000,
          46.9,
          "0.199",
          "0.201",
          "0.211",
          "0.218",
          "0.209",
          3.199,
          4.036,
          68.13,
          0,
          80.85,
          85.34,
          90.42,
        ],
      },
      {
        1485: [
          "0798",
          0.415,
          348000,
          46.8,
          "0.408",
          "0.41",
          "0.438",
          "0.432",
          "0.443",
          14.432,
          31.4,
          33.67,
          0,
          71.03,
          82.63,
          95.62,
        ],
      },
      {
        1486: [
          "3686",
          0.5,
          10000,
          46.8,
          "0.507",
          "0.512",
          "0.533",
          "0.544",
          "0.53",
          0.5,
          5.079,
          64.57,
          0,
          86.83,
          90.05,
          94.19,
        ],
      },
      {
        1487: [
          "0099",
          2,
          9000,
          46.8,
          "2.035",
          "2.055",
          "2.221",
          "2.217",
          "2.207",
          1.8,
          9.57,
          72.35,
          0,
          82.8,
          85.07,
          87.85,
        ],
      },
      {
        1488: [
          "0837",
          3.58,
          79500,
          46.7,
          "3.534",
          "3.575",
          "3.626",
          "3.708",
          "3.623",
          28.343,
          8.904,
          67.47,
          0,
          79.83,
          87.2,
          93.33,
        ],
      },
      {
        1489: [
          "1809",
          7.08,
          253000,
          46.7,
          "7.225",
          "6.855",
          "7.44",
          "7.786",
          "7.334",
          178,
          45,
          73.29,
          0,
          90.01,
          96.57,
          99.49,
        ],
      },
      {
        1490: [
          "0508",
          0.089,
          20000,
          46.7,
          "0.09",
          "0.094",
          "0.095",
          "0.093",
          "0.096",
          0.178,
          6.548,
          0.48,
          5.09,
          55.9,
          67.31,
          82.73,
        ],
      },
      {
        1491: [
          "1975",
          0.65,
          0,
          46.6,
          "0.652",
          "0.695",
          "0.707",
          "0.698",
          "0.711",
          0,
          3.12,
          75.77,
          0,
          84.45,
          88.62,
          92.92,
        ],
      },
      {
        1492: [
          "1722",
          0.093,
          0,
          46.6,
          "0.095",
          "0.101",
          "0.105",
          "0.107",
          "0.104",
          0,
          0.93,
          67.01,
          0,
          76.34,
          80.63,
          86.67,
        ],
      },
      {
        1493: [
          "0178",
          1.57,
          1234000,
          46.5,
          "1.597",
          "1.719",
          "1.8",
          "1.813",
          "1.782",
          195,
          48.7,
          11.08,
          0.17,
          44.09,
          60.53,
          79.97,
        ],
      },
      {
        1494: [
          "0078",
          3.15,
          34000,
          46.5,
          "3.11",
          "3.131",
          "3.506",
          "3.607",
          "3.461",
          10.606,
          28.3,
          28.22,
          0,
          75.74,
          82.66,
          91.34,
        ],
      },
      {
        1495: [
          "3606",
          43.25,
          2328600,
          46.5,
          "40.74",
          "42.387",
          "46.204",
          "46.154",
          "45.802",
          9952,
          262.4,
          1.79,
          22.12,
          59.5,
          76.36,
          93.01,
        ],
      },
      {
        1496: [
          "3868",
          4.26,
          4042070,
          46.4,
          "4.154",
          "4.163",
          "4.485",
          "4.294",
          "4.515",
          1719,
          302.8,
          70.21,
          1.89,
          85.02,
          92.32,
          96.44,
        ],
      },
      {
        1497: [
          "7272",
          11.03,
          9100,
          46.4,
          "11.442",
          "11.679",
          "11.607",
          "11.745",
          "11.565",
          10.116,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1498: [
          "0093",
          0.255,
          0,
          46.4,
          "0.252",
          "0.247",
          "0.271",
          "0.282",
          "0.268",
          0,
          6.802,
          85.67,
          0,
          96.51,
          98.92,
          99.44,
        ],
      },
      {
        1499: [
          "0861",
          4.55,
          1028000,
          46.3,
          "4.634",
          "4.302",
          "4.517",
          "4.771",
          "4.477",
          470,
          76.1,
          0.15,
          31.78,
          52.34,
          66.92,
          86.78,
        ],
      },
      {
        1500: [
          "0303",
          61.6,
          73200,
          46.3,
          "61.695",
          "61.789",
          "68.715",
          "69.809",
          "67.59",
          449,
          155.4,
          0.7,
          1.08,
          70.34,
          86.36,
          92.11,
        ],
      },
      {
        1501: [
          "1008",
          0.34,
          372000,
          46.3,
          "0.325",
          "0.327",
          "0.362",
          "0.383",
          "0.354",
          12.279,
          5.331,
          0.01,
          0,
          80.97,
          88.82,
          94.7,
        ],
      },
      {
        1502: [
          "9858",
          4.91,
          20611000,
          46.2,
          "4.364",
          "4.32",
          "-",
          "-",
          "4.736",
          10195,
          186.3,
          78.32,
          0.75,
          87.89,
          92.39,
          96.34,
        ],
      },
      {
        1503: [
          "1710",
          0.169,
          12000,
          46.2,
          "0.171",
          "0.175",
          "0.187",
          "0.196",
          "0.184",
          0.2,
          1.69,
          75.11,
          0,
          83.85,
          87.36,
          93.25,
        ],
      },
      {
        1504: [
          "1290",
          1.03,
          80000,
          46.2,
          "1.017",
          "1.066",
          "1.127",
          "1.12",
          "1.133",
          8.162,
          11.2,
          0.05,
          0,
          78.09,
          86.01,
          93.2,
        ],
      },
      {
        1505: [
          "0841",
          0.29,
          0,
          46.1,
          "0.283",
          "0.292",
          "0.298",
          "0.304",
          "0.296",
          0,
          1.696,
          51.39,
          0,
          68.23,
          77.54,
          85.04,
        ],
      },
      {
        1506: [
          "3689",
          2.86,
          2800,
          46.1,
          "2.861",
          "2.746",
          "3.282",
          "3.219",
          "3.28",
          0.798,
          2.414,
          0.04,
          0,
          56.32,
          73.65,
          87.86,
        ],
      },
      {
        1507: [
          "1742",
          0.093,
          5000,
          46,
          "0.098",
          "0.117",
          "0.103",
          "0.1",
          "0.104",
          0,
          1.488,
          75.56,
          0,
          78.95,
          81.8,
          87.42,
        ],
      },
      {
        1508: [
          "2839",
          32.1,
          908350,
          46,
          "32.606",
          "-",
          "-",
          "-",
          "-",
          2932,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1509: [
          "1200",
          1,
          50000,
          46,
          "0.98",
          "1.07",
          "1.283",
          "1.242",
          "1.257",
          5,
          7.171,
          34.57,
          0,
          68.85,
          75.51,
          85.55,
        ],
      },
      {
        1510: [
          "8146",
          0.175,
          50000,
          45.9,
          "0.185",
          "0.191",
          "0.189",
          "0.19",
          "0.189",
          0.865,
          1.4,
          0.01,
          0,
          79.2,
          85.24,
          91.15,
        ],
      },
      {
        1511: [
          "3869",
          9.97,
          0,
          45.9,
          "9.984",
          "9.999",
          "10.436",
          "10.839",
          "10.414",
          0,
          13.7,
          73.06,
          0,
          84.6,
          92.92,
          99.31,
        ],
      },
      {
        1512: [
          "3124",
          54.86,
          19250,
          45.9,
          "53.084",
          "54.898",
          "-",
          "-",
          "-",
          105,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1513: [
          "0645",
          0.26,
          0,
          45.8,
          "0.26",
          "0.26",
          "0.264",
          "0.274",
          "0.262",
          0,
          1.334,
          68.03,
          0,
          77.58,
          84.47,
          89.47,
        ],
      },
      {
        1514: [
          "3003",
          7.275,
          444500,
          45.8,
          "7.399",
          "-",
          "-",
          "-",
          "-",
          325,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1515: [
          "0266",
          4.6,
          50000,
          45.8,
          "4.501",
          "5.034",
          "5.017",
          "5.089",
          "5.012",
          23,
          21.8,
          61.71,
          0,
          80.38,
          87.21,
          96.53,
        ],
      },
      {
        1516: [
          "9839",
          4.114,
          290600,
          45.7,
          "4.185",
          "-",
          "-",
          "-",
          "-",
          939,
          0,
          99.73,
          0,
          100,
          199.73,
          199.73,
        ],
      },
      {
        1517: [
          "6117",
          0.56,
          0,
          45.7,
          "0.546",
          "0.537",
          "0.586",
          "0.597",
          "0.585",
          0,
          4.592,
          43.9,
          0,
          93.56,
          95.37,
          97.67,
        ],
      },
      {
        1518: [
          "0237",
          3.25,
          16000,
          45.6,
          "3.285",
          "3.307",
          "3.488",
          "3.46",
          "3.468",
          5.164,
          13.1,
          71.85,
          0,
          79.57,
          83.06,
          86.77,
        ],
      },
      {
        1519: [
          "1110",
          0.71,
          8000,
          45.6,
          "0.737",
          "0.751",
          "0.767",
          "0.773",
          "0.763",
          0.564,
          4.42,
          57.57,
          0,
          78.15,
          85.11,
          92.3,
        ],
      },
      {
        1520: [
          "1583",
          2.14,
          0,
          45.6,
          "2.15",
          "2.108",
          "2.203",
          "2.216",
          "2.198",
          0,
          16.1,
          48.86,
          0,
          76.5,
          86.59,
          95.81,
        ],
      },
      {
        1521: [
          "2823",
          17.86,
          5454980,
          45.5,
          "18.13",
          "18.492",
          "18.519",
          "18.888",
          "18.425",
          9789,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1522: [
          "1830",
          5.08,
          1285760,
          45.5,
          "5.256",
          "5.805",
          "6.846",
          "6.849",
          "6.72",
          653,
          62.9,
          20.81,
          0,
          79.99,
          84.79,
          90.19,
        ],
      },
      {
        1523: [
          "8089",
          0.033,
          0,
          45.5,
          "0.033",
          "0.033",
          "0.033",
          "0.033",
          "0.033",
          0,
          0.139,
          7.6,
          0,
          28.34,
          41.37,
          61.38,
        ],
      },
      {
        1524: [
          "0028",
          4.19,
          269000,
          45.4,
          "4.243",
          "4.282",
          "4.464",
          "4.501",
          "4.456",
          111,
          61.4,
          40.61,
          0,
          84.36,
          94.09,
          97.24,
        ],
      },
      {
        1525: [
          "0053",
          86.3,
          0,
          45.4,
          "85.15",
          "87.776",
          "89.242",
          "90.237",
          "89.092",
          0,
          283.9,
          73.42,
          0,
          92.18,
          96.13,
          98.64,
        ],
      },
      {
        1526: [
          "0291",
          58.1,
          6267140,
          45.4,
          "59.07",
          "63.011",
          "63.084",
          "63.614",
          "62.453",
          36013,
          1884.8,
          52.04,
          16.56,
          75.28,
          88.46,
          97.5,
        ],
      },
      {
        1527: [
          "3396",
          12.06,
          1000300,
          45.3,
          "11.86",
          "12.29",
          "13.061",
          "12.95",
          "13.088",
          1208,
          153.3,
          0.04,
          9.5,
          75.06,
          83.54,
          93.13,
        ],
      },
      {
        1528: [
          "0685",
          0.29,
          0,
          45.3,
          "0.292",
          "0.302",
          "0.311",
          "0.309",
          "0.312",
          0,
          4.893,
          80.14,
          0,
          96.51,
          97.93,
          98.72,
        ],
      },
      {
        1529: [
          "1126",
          2.78,
          0,
          45.3,
          "2.761",
          "2.803",
          "2.875",
          "2.938",
          "2.876",
          0,
          18.8,
          67.37,
          0,
          86.14,
          90.37,
          96.86,
        ],
      },
      {
        1530: [
          "3839",
          1.37,
          1635,
          45.2,
          "1.369",
          "1.408",
          "1.495",
          "1.514",
          "1.476",
          0.219,
          3.298,
          83.37,
          0,
          88.52,
          91.83,
          94.85,
        ],
      },
      {
        1531: [
          "1530",
          6.7,
          5516020,
          45.2,
          "6.575",
          "6.735",
          "7.673",
          "7.664",
          "7.469",
          3703,
          168.9,
          0.26,
          14.61,
          60.05,
          75.26,
          91.39,
        ],
      },
      {
        1532: [
          "8623",
          0.08,
          100000,
          45.1,
          "0.079",
          "0.081",
          "0.082",
          "0.08",
          "0.082",
          0.8,
          0.736,
          72.88,
          0,
          83.45,
          87.02,
          91.97,
        ],
      },
      {
        1533: [
          "2276",
          4.2,
          2635500,
          45.1,
          "4.466",
          "-",
          "-",
          "-",
          "-",
          1165,
          17.9,
          0,
          0,
          83.51,
          88.91,
          96.94,
        ],
      },
      {
        1534: [
          "2843",
          18.73,
          29400,
          45.1,
          "18.994",
          "19.217",
          "19.369",
          "19.808",
          "19.253",
          55.085,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1535: [
          "1593",
          2.21,
          1418000,
          45,
          "2.27",
          "2.242",
          "2.367",
          "2.407",
          "2.349",
          312,
          22.1,
          32.48,
          0,
          51.59,
          62.32,
          81.26,
        ],
      },
      {
        1536: [
          "1888",
          13.46,
          5255780,
          45,
          "13.168",
          "13.194",
          "14.334",
          "15.211",
          "14.075",
          6988,
          419.9,
          65.46,
          0.32,
          79.7,
          85.14,
          93,
        ],
      },
      {
        1537: [
          "1097",
          0.064,
          329129,
          45,
          "0.065",
          "0.068",
          "0.074",
          "0.075",
          "0.073",
          2.098,
          4.566,
          45.62,
          0,
          63.99,
          74.47,
          84.97,
        ],
      },
      {
        1538: [
          "0760",
          0.02,
          45000,
          44.9,
          "0.02",
          "0.021",
          "0.023",
          "0.024",
          "0.023",
          0,
          2.059,
          44.02,
          0,
          75.67,
          82.58,
          89.54,
        ],
      },
      {
        1539: [
          "1033",
          0.68,
          13337000,
          44.9,
          "0.675",
          "0.664",
          "0.707",
          "0.713",
          "0.705",
          914,
          36.8,
          0.25,
          7.9,
          70.05,
          77.64,
          85.54,
        ],
      },
      {
        1540: [
          "2848",
          716.8,
          150,
          44.9,
          "714.18",
          "719.304",
          "762.769",
          "781.258",
          "756.72",
          10.749,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1541: [
          "8227",
          1.4,
          34000,
          44.8,
          "1.349",
          "1.37",
          "1.493",
          "1.522",
          "1.471",
          4.76,
          9.041,
          11.07,
          0,
          43.73,
          56.94,
          74.53,
        ],
      },
      {
        1542: [
          "3302",
          0.58,
          96000,
          44.8,
          "0.676",
          "0.641",
          "0.619",
          "0.64",
          "0.618",
          5.482,
          5.338,
          65.48,
          0,
          82.1,
          91.44,
          98.02,
        ],
      },
      {
        1543: [
          "1340",
          0.087,
          504000,
          44.8,
          "0.086",
          "0.093",
          "0.089",
          "0.09",
          "0.088",
          4.234,
          0.766,
          3.91,
          0,
          36.44,
          46.12,
          61.03,
        ],
      },
      {
        1544: [
          "3939",
          1.85,
          0,
          44.7,
          "1.833",
          "2.019",
          "2.051",
          "2.055",
          "2.037",
          0,
          15.3,
          5.05,
          0,
          80.2,
          88.61,
          96.42,
        ],
      },
      {
        1545: [
          "1818",
          6.28,
          3504500,
          44.7,
          "6.189",
          "6.741",
          "6.668",
          "6.927",
          "6.591",
          2193,
          163.8,
          0.14,
          10.66,
          83.15,
          88.22,
          93.57,
        ],
      },
      {
        1546: [
          "2281",
          1.03,
          20000,
          44.6,
          "1.031",
          "1.057",
          "1.124",
          "1.123",
          "1.124",
          2.06,
          2.214,
          0.09,
          0,
          82.65,
          89.26,
          94.01,
        ],
      },
      {
        1547: [
          "0993",
          0.171,
          824750,
          44.6,
          "0.179",
          "0.185",
          "0.2",
          "0.206",
          "0.197",
          14.387,
          14.8,
          44.76,
          0,
          77.53,
          82.91,
          90.43,
        ],
      },
      {
        1548: [
          "3181",
          122.25,
          500,
          44.6,
          "121.37",
          "128.205",
          "131.862",
          "132.613",
          "131.333",
          6.112,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1549: [
          "1266",
          0.415,
          5083000,
          44.5,
          "0.394",
          "0.417",
          "0.434",
          "0.426",
          "0.437",
          212,
          9.832,
          0.71,
          0,
          52.8,
          62.65,
          72.05,
        ],
      },
      {
        1550: [
          "1142",
          0.89,
          175,
          44.5,
          "0.94",
          "1.026",
          "1.172",
          "1.187",
          "1.141",
          0,
          1.291,
          73.16,
          0,
          85.32,
          89.81,
          94.22,
        ],
      },
      {
        1551: [
          "1968",
          0.182,
          0,
          44.5,
          "0.178",
          "0.181",
          "0.192",
          "0.19",
          "0.191",
          0,
          1.165,
          75,
          0,
          82.81,
          86.78,
          91.59,
        ],
      },
      {
        1552: [
          "1787",
          13.38,
          3028390,
          44.4,
          "13.048",
          "13.704",
          "13.721",
          "14.208",
          "13.662",
          4064,
          114.9,
          0.03,
          18.89,
          47.75,
          62.93,
          78.74,
        ],
      },
      {
        1553: [
          "9181",
          15.72,
          0,
          44.4,
          "15.606",
          "16.455",
          "16.94",
          "17.045",
          "16.881",
          0,
          0,
          45.22,
          0,
          85.85,
          97.84,
          100,
        ],
      },
      {
        1554: [
          "1992",
          10.12,
          40800,
          44.4,
          "10.356",
          "10.096",
          "10.682",
          "11.254",
          "10.488",
          41.388,
          125.3,
          78.84,
          1.44,
          87.37,
          91.31,
          97.62,
        ],
      },
      {
        1555: [
          "0687",
          0.39,
          0,
          44.3,
          "0.381",
          "0.39",
          "0.425",
          "0.438",
          "0.42",
          0,
          13.1,
          0.09,
          0.31,
          82.6,
          90.14,
          97.18,
        ],
      },
      {
        1556: [
          "1402",
          0.48,
          120000,
          44.3,
          "0.499",
          "0.484",
          "0.554",
          "0.558",
          "0.548",
          6.1,
          5.042,
          0,
          0,
          87.98,
          91.03,
          94.17,
        ],
      },
      {
        1557: [
          "2358",
          0.092,
          0,
          44.3,
          "0.092",
          "0.093",
          "0.098",
          "0.1",
          "0.098",
          0,
          5.034,
          4.09,
          0,
          47.11,
          65.96,
          85.15,
        ],
      },
      {
        1558: [
          "0970",
          0.181,
          55552000,
          44.2,
          "0.172",
          "0.184",
          "0.187",
          "0.2",
          "0.184",
          1001,
          9.904,
          1.7,
          0,
          44.04,
          61.86,
          72.79,
        ],
      },
      {
        1559: [
          "1247",
          0.465,
          0,
          44.2,
          "0.465",
          "0.483",
          "0.544",
          "0.54",
          "-",
          0,
          0.549,
          0.07,
          0,
          56.38,
          67.46,
          82.86,
        ],
      },
      {
        1560: [
          "0601",
          0.058,
          0,
          44.1,
          "0.063",
          "0.066",
          "0.06",
          "0.065",
          "0.06",
          0,
          3.819,
          45.64,
          0,
          79,
          84.88,
          92.85,
        ],
      },
      {
        1561: [
          "8018",
          0.285,
          0,
          44.1,
          "0.256",
          "0.244",
          "0.295",
          "0.337",
          "-",
          0,
          0.359,
          0.18,
          0,
          49.98,
          64.78,
          79.98,
        ],
      },
      {
        1562: [
          "0914",
          40.8,
          9843260,
          44.1,
          "40.6",
          "38.655",
          "40.89",
          "42.575",
          "40.852",
          40227,
          530.2,
          0.09,
          7.72,
          61.95,
          73.4,
          82.05,
        ],
      },
      {
        1563: [
          "8373",
          0.27,
          2115000,
          44,
          "0.273",
          "0.282",
          "0.289",
          "0.299",
          "0.287",
          56.052,
          1.08,
          0,
          0,
          64.93,
          71.55,
          80.3,
        ],
      },
      {
        1564: [
          "8242",
          0.01,
          0,
          44,
          "0.01",
          "0.01",
          "0.01",
          "0.01",
          "0.01",
          0,
          0.168,
          0.62,
          0,
          69.19,
          76.18,
          83.21,
        ],
      },
      {
        1565: [
          "1679",
          0.199,
          362500,
          44,
          "0.189",
          "0.196",
          "0.202",
          "0.204",
          "0.201",
          7.024,
          3.534,
          39.19,
          0,
          64.78,
          73.39,
          85.59,
        ],
      },
      {
        1566: [
          "3051",
          56.4,
          1500,
          43.9,
          "56.336",
          "-",
          "-",
          "-",
          "-",
          8.46,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1567: [
          "0069",
          6.39,
          450000,
          43.9,
          "6.395",
          "6.415",
          "6.648",
          "6.832",
          "6.572",
          287,
          229.1,
          32.31,
          0.54,
          74.83,
          83.93,
          95.3,
        ],
      },
      {
        1568: [
          "9600",
          1.9,
          481600,
          43.9,
          "1.921",
          "2.1",
          "2.047",
          "2.073",
          "2.05",
          91.387,
          14.9,
          72.17,
          0,
          80.24,
          84.99,
          90.09,
        ],
      },
      {
        1569: [
          "1273",
          0.425,
          64000,
          43.8,
          "0.421",
          "0.442",
          "0.48",
          "0.473",
          "0.48",
          2.72,
          1.764,
          41.07,
          0,
          81.85,
          86.28,
          91.33,
        ],
      },
      {
        1570: [
          "3308",
          6.32,
          727000,
          43.8,
          "6.439",
          "6.612",
          "6.763",
          "6.811",
          "6.759",
          456,
          104.9,
          0.05,
          5.14,
          70.05,
          89.08,
          97.38,
        ],
      },
      {
        1571: [
          "0428",
          0.28,
          0,
          43.8,
          "0.288",
          "0.29",
          "0.291",
          "0.288",
          "0.292",
          0,
          1.209,
          0.42,
          0,
          69.28,
          79.99,
          91.32,
        ],
      },
      {
        1572: [
          "1957",
          0.405,
          37500,
          43.7,
          "0.426",
          "0.497",
          "0.491",
          "0.491",
          "0.497",
          1.518,
          2.543,
          75,
          0,
          80.98,
          85.83,
          93.48,
        ],
      },
      {
        1573: [
          "1582",
          0.52,
          122500,
          43.7,
          "0.527",
          "0.521",
          "0.555",
          "0.564",
          "0.551",
          6.47,
          2.6,
          77.47,
          0,
          85.3,
          89.84,
          94.38,
        ],
      },
      {
        1574: [
          "0535",
          0.87,
          11704000,
          43.6,
          "0.847",
          "0.782",
          "0.851",
          "0.92",
          "0.845",
          1008,
          144.3,
          36.53,
          9.32,
          70.57,
          82.92,
          92.55,
        ],
      },
      {
        1575: [
          "2195",
          0.335,
          975000,
          43.6,
          "0.347",
          "0.364",
          "0.388",
          "-",
          "0.388",
          32.842,
          3.35,
          60,
          0,
          80.86,
          87.47,
          93.02,
        ],
      },
      {
        1576: [
          "8481",
          0.21,
          0,
          43.6,
          "0.212",
          "0.211",
          "0.215",
          "0.222",
          "0.214",
          0,
          1.05,
          62.76,
          0,
          86.43,
          91.03,
          96.52,
        ],
      },
      {
        1577: [
          "0059",
          0.85,
          4780000,
          43.5,
          "0.874",
          "0.873",
          "0.909",
          "0.926",
          "0.902",
          409,
          71.7,
          0.15,
          2.03,
          65.97,
          71.04,
          81.48,
        ],
      },
      {
        1578: [
          "3037",
          24.86,
          5800,
          43.5,
          "24.01",
          "24.482",
          "25.923",
          "-",
          "25.763",
          14.467,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1579: [
          "0264",
          0.75,
          0,
          43.5,
          "0.742",
          "0.729",
          "0.768",
          "0.804",
          "0.762",
          0,
          2.87,
          8.18,
          0,
          82.27,
          88.44,
          93.6,
        ],
      },
      {
        1580: [
          "8152",
          0.082,
          0,
          43.4,
          "0.079",
          "0.083",
          "0.097",
          "0.093",
          "0.097",
          0,
          0.492,
          69.84,
          0,
          79.42,
          84.45,
          90.92,
        ],
      },
      {
        1581: [
          "0891",
          0.042,
          0,
          43.4,
          "0.042",
          "0.042",
          "0.042",
          "0.042",
          "0.042",
          0,
          1.511,
          70.96,
          0,
          81.75,
          85.68,
          90.56,
        ],
      },
      {
        1582: [
          "1051",
          2.7,
          8117,
          43.4,
          "2.741",
          "2.822",
          "2.934",
          "2.92",
          "2.946",
          2.167,
          12.1,
          0.07,
          0,
          47.97,
          57.99,
          76.79,
        ],
      },
      {
        1583: [
          "0775",
          0.73,
          2178000,
          43.3,
          "0.734",
          "0.738",
          "0.776",
          "0.782",
          "0.765",
          159,
          70.1,
          76.11,
          0.46,
          82.91,
          86.15,
          89.4,
        ],
      },
      {
        1584: [
          "1022",
          0.35,
          1443000,
          43.3,
          "0.365",
          "0.43",
          "0.582",
          "0.641",
          "0.559",
          50.897,
          6.016,
          0.01,
          0,
          68.03,
          74.66,
          83.66,
        ],
      },
      {
        1585: [
          "2628",
          13.58,
          32367000,
          43.3,
          "13.216",
          "13.309",
          "13.587",
          "14.168",
          "13.475",
          43883,
          1010.5,
          1.52,
          4.62,
          49.04,
          60.54,
          74.69,
        ],
      },
      {
        1586: [
          "0923",
          0.047,
          24000,
          43.2,
          "0.044",
          "0.047",
          "0.052",
          "0.054",
          "0.051",
          0.108,
          2.267,
          54.45,
          0,
          77.31,
          82.07,
          86.62,
        ],
      },
      {
        1587: [
          "8003",
          0.6,
          102000,
          43.2,
          "0.644",
          "0.651",
          "0.745",
          "0.744",
          "-",
          5.916,
          1.982,
          40.98,
          0,
          60.2,
          69.69,
          82.34,
        ],
      },
      {
        1588: [
          "0834",
          0.34,
          0,
          43.1,
          "0.328",
          "0.341",
          "0.348",
          "0.35",
          "0.348",
          0,
          1.472,
          3.7,
          0,
          81.83,
          88.36,
          93.98,
        ],
      },
      {
        1589: [
          "2822",
          17.36,
          8486020,
          43.1,
          "17.615",
          "18.053",
          "18.146",
          "18.527",
          "18.046",
          14846,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1590: [
          "1080",
          0.077,
          40500,
          43.1,
          "0.079",
          "0.078",
          "0.083",
          "0.083",
          "0.083",
          0.311,
          2.983,
          12.69,
          0,
          48.17,
          66.76,
          77.84,
        ],
      },
      {
        1591: [
          "3339",
          2.33,
          3202000,
          43,
          "2.287",
          "2.174",
          "2.365",
          "2.569",
          "2.347",
          746,
          99.7,
          0.17,
          1.02,
          75.69,
          81.84,
          90.51,
        ],
      },
      {
        1592: [
          "8080",
          0.69,
          1125,
          43,
          "0.699",
          "0.716",
          "0.767",
          "0.76",
          "0.763",
          0,
          2.933,
          52.63,
          0,
          72.31,
          81.74,
          89.35,
        ],
      },
      {
        1593: [
          "0387",
          0.87,
          4000,
          43,
          "0.849",
          "0.857",
          "0.922",
          "0.944",
          "0.918",
          0.348,
          2.002,
          69.28,
          0,
          85.22,
          87.75,
          91.72,
        ],
      },
      {
        1594: [
          "1876",
          20.2,
          5701020,
          42.9,
          "20.58",
          "20.608",
          "21.223",
          "22.234",
          "20.984",
          11579,
          2675.1,
          87.22,
          0.07,
          96.75,
          98.98,
          99.66,
        ],
      },
      {
        1595: [
          "1561",
          1.91,
          292000,
          42.9,
          "1.903",
          "1.935",
          "2.098",
          "2.087",
          "2.106",
          55.528,
          14.5,
          5.68,
          0,
          38.76,
          55.53,
          76.42,
        ],
      },
      {
        1596: [
          "2833",
          24.88,
          233908,
          42.9,
          "24.034",
          "24.48",
          "26.038",
          "26.842",
          "25.842",
          582,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1597: [
          "1682",
          0.138,
          472000,
          42.8,
          "0.161",
          "0.165",
          "0.156",
          "0.162",
          "0.154",
          6.529,
          1.084,
          13.24,
          0,
          64.97,
          75.41,
          91.36,
        ],
      },
      {
        1598: [
          "1285",
          1.51,
          229000,
          42.8,
          "1.425",
          "1.393",
          "1.575",
          "1.619",
          "1.563",
          34.587,
          6.266,
          0.04,
          0,
          87.65,
          90.44,
          94.13,
        ],
      },
      {
        1599: [
          "1669",
          0.53,
          0,
          42.7,
          "0.53",
          "0.532",
          "0.589",
          "0.618",
          "0.588",
          0,
          2.12,
          75.03,
          0,
          83.19,
          88.37,
          92.46,
        ],
      },
      {
        1600: [
          "0115",
          0.7,
          0,
          42.7,
          "0.699",
          "0.706",
          "0.775",
          "0.813",
          "-",
          0,
          1.715,
          35.51,
          0,
          48.03,
          57.2,
          75.73,
        ],
      },
      {
        1601: [
          "1899",
          1.75,
          716000,
          42.7,
          "1.748",
          "1.719",
          "1.769",
          "1.845",
          "1.753",
          125,
          29,
          0.13,
          0,
          70.73,
          85.2,
          91.54,
        ],
      },
      {
        1602: [
          "6909",
          5.4,
          22000,
          42.6,
          "5.582",
          "5.929",
          "-",
          "-",
          "-",
          12.032,
          33.6,
          0,
          0,
          86.86,
          92.25,
          98.13,
        ],
      },
      {
        1603: [
          "0124",
          0.81,
          474305,
          42.6,
          "0.799",
          "0.759",
          "0.844",
          "0.899",
          "0.831",
          38.887,
          13.8,
          76.84,
          0,
          81.92,
          86.06,
          91.41,
        ],
      },
      {
        1604: [
          "8006",
          0.073,
          150,
          42.6,
          "0.07",
          "0.074",
          "0.078",
          "0.078",
          "0.077",
          0,
          0.349,
          4.02,
          0,
          35.57,
          47.39,
          67.21,
        ],
      },
      {
        1605: [
          "2892",
          1.01,
          0,
          42.5,
          "1.02",
          "1.031",
          "1.08",
          "1.118",
          "1.07",
          0,
          7.575,
          75,
          0,
          94.01,
          97.86,
          99.53,
        ],
      },
      {
        1606: [
          "1189",
          0.166,
          0,
          42.5,
          "0.17",
          "0.173",
          "0.18",
          "0.183",
          "0.178",
          0,
          1.31,
          0.31,
          0,
          40.16,
          53,
          69.96,
        ],
      },
      {
        1607: [
          "1166",
          0.068,
          100006,
          42.5,
          "0.07",
          "0.076",
          "0.077",
          "0.077",
          "0.077",
          0.68,
          1.615,
          0.02,
          0,
          75.89,
          83.72,
          90.98,
        ],
      },
      {
        1608: [
          "0458",
          0.9,
          0,
          42.4,
          "0.88",
          "0.9",
          "0.939",
          "0.947",
          "0.938",
          0,
          2.444,
          10.03,
          0,
          84.37,
          91.31,
          94.02,
        ],
      },
      {
        1609: [
          "0385",
          0.89,
          400,
          42.4,
          "0.865",
          "0.898",
          "1.014",
          "1.017",
          "1.002",
          0,
          5.295,
          72.64,
          0,
          81.88,
          85.15,
          89.1,
        ],
      },
      {
        1610: [
          "0310",
          0.03,
          420000,
          42.4,
          "0.03",
          "0.03",
          "0.033",
          "0.034",
          "0.033",
          1.176,
          0.363,
          10.66,
          0,
          54.01,
          61.13,
          73.66,
        ],
      },
      {
        1611: [
          "0938",
          1.62,
          8000,
          42.3,
          "1.657",
          "1.626",
          "1.694",
          "1.757",
          "-",
          1.242,
          10.4,
          41.24,
          0,
          67.39,
          83.12,
          91.05,
        ],
      },
      {
        1612: [
          "0122",
          0.37,
          190000,
          42.3,
          "0.376",
          "0.395",
          "0.418",
          "0.406",
          "0.423",
          7.124,
          3.506,
          2.44,
          0,
          55.42,
          60.17,
          68.87,
        ],
      },
      {
        1613: [
          "8221",
          0.053,
          0,
          42.2,
          "0.058",
          "0.055",
          "0.06",
          "0.064",
          "0.059",
          0,
          1.06,
          15.28,
          0,
          89.52,
          92.56,
          95.19,
        ],
      },
      {
        1614: [
          "0525",
          1.35,
          3216000,
          42.2,
          "1.353",
          "1.331",
          "1.404",
          "1.45",
          "1.395",
          433,
          19.3,
          1,
          25.71,
          57.64,
          69.69,
          81.07,
        ],
      },
      {
        1615: [
          "0131",
          2.5,
          0,
          42.2,
          "2.493",
          "2.518",
          "2.647",
          "2.695",
          "2.646",
          0,
          16.3,
          48.84,
          0,
          81.1,
          84.03,
          88.39,
        ],
      },
      {
        1616: [
          "1676",
          1.13,
          1200,
          42.1,
          "1.202",
          "1.352",
          "1.513",
          "1.616",
          "1.493",
          0.117,
          1.22,
          0.01,
          0,
          68.42,
          76.32,
          86.3,
        ],
      },
      {
        1617: [
          "8022",
          0.01,
          0,
          42.1,
          "0.01",
          "0.01",
          "0.01",
          "0.01",
          "0.01",
          0,
          0.216,
          16.69,
          0,
          42.81,
          55.77,
          71.91,
        ],
      },
      {
        1618: [
          "0041",
          21.4,
          48761,
          42.1,
          "21.28",
          "20.47",
          "22.847",
          "23.902",
          "22.558",
          104,
          156.4,
          37.47,
          0.15,
          67.61,
          80.42,
          94.88,
        ],
      },
      {
        1619: [
          "0590",
          20.2,
          266000,
          42,
          "20.123",
          "22.526",
          "22.97",
          "22.43",
          "22.888",
          539,
          118.5,
          28.37,
          0.27,
          84.84,
          92.66,
          96.06,
        ],
      },
      {
        1620: [
          "1338",
          0.088,
          250000,
          42,
          "0.088",
          "0.096",
          "0.093",
          "0.099",
          "0.091",
          2.213,
          2.783,
          49.44,
          0,
          73.43,
          79.47,
          86.21,
        ],
      },
      {
        1621: [
          "8553",
          0.9,
          24200,
          42,
          "0.909",
          "-",
          "-",
          "-",
          "-",
          1.938,
          0.514,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1622: [
          "1848",
          5.31,
          64000,
          41.9,
          "5.441",
          "5.285",
          "5.5",
          "5.691",
          "5.461",
          34.316,
          39.6,
          58.96,
          0.95,
          71.13,
          78.42,
          87.63,
        ],
      },
      {
        1623: [
          "3991",
          0.72,
          114000,
          41.9,
          "0.723",
          "0.716",
          "0.768",
          "0.79",
          "0.76",
          8.028,
          10.4,
          65.85,
          0,
          87.01,
          92.53,
          96.59,
        ],
      },
      {
        1624: [
          "0032",
          11.1,
          9550,
          41.9,
          "11.05",
          "11.316",
          "11.752",
          "12.143",
          "11.638",
          10.899,
          41.3,
          7.13,
          0,
          17.96,
          23.39,
          27.23,
        ],
      },
      {
        1625: [
          "1257",
          2.76,
          5045030,
          41.8,
          "2.84",
          "2.755",
          "2.832",
          "2.893",
          "2.828",
          1402,
          57,
          70.85,
          10.14,
          85.83,
          91.04,
          94.36,
        ],
      },
      {
        1626: [
          "1546",
          0.2,
          0,
          41.8,
          "0.197",
          "0.194",
          "0.213",
          "0.237",
          "0.216",
          0,
          1.6,
          69.38,
          0,
          78.87,
          83.25,
          89.16,
        ],
      },
      {
        1627: [
          "2360",
          1.72,
          18000,
          41.7,
          "1.692",
          "1.701",
          "1.838",
          "1.883",
          "1.821",
          3.108,
          17.2,
          75.01,
          0,
          85.19,
          90.19,
          96.12,
        ],
      },
      {
        1628: [
          "0087",
          7.73,
          217500,
          41.7,
          "7.669",
          "7.693",
          "8.185",
          "8.535",
          "8.109",
          167,
          230.4,
          74.77,
          0.98,
          85.3,
          89.98,
          93.38,
        ],
      },
      {
        1629: [
          "6633",
          9.68,
          33500,
          41.7,
          "10.766",
          "-",
          "-",
          "-",
          "-",
          32.212,
          66.9,
          85.86,
          0,
          92.04,
          95.61,
          99.08,
        ],
      },
      {
        1630: [
          "0116",
          10.74,
          216000,
          41.6,
          "10.706",
          "11.009",
          "11.915",
          "12.171",
          "11.79",
          231,
          72.7,
          42.19,
          3.64,
          69.18,
          83.01,
          94.32,
        ],
      },
      {
        1631: [
          "8028",
          0.034,
          140000,
          41.6,
          "0.031",
          "0.034",
          "0.041",
          "0.042",
          "0.04",
          0.464,
          0.956,
          28.55,
          0,
          48.22,
          60.79,
          80.4,
        ],
      },
      {
        1632: [
          "1758",
          0.465,
          0,
          41.6,
          "0.465",
          "0.463",
          "0.525",
          "0.547",
          "0.515",
          0,
          3.822,
          24.21,
          0,
          82.89,
          88.59,
          93.31,
        ],
      },
      {
        1633: [
          "2778",
          4.01,
          359506,
          41.5,
          "3.991",
          "4.025",
          "4.216",
          "4.306",
          "4.178",
          144,
          238,
          60.9,
          0,
          76.8,
          83.16,
          91.73,
        ],
      },
      {
        1634: [
          "0893",
          0.166,
          499000,
          41.5,
          "0.161",
          "0.167",
          "0.191",
          "0.194",
          "0.19",
          8.233,
          3.733,
          37.6,
          0,
          59.65,
          70.52,
          83.17,
        ],
      },
      {
        1635: [
          "0672",
          0.31,
          216300,
          41.5,
          "0.311",
          "0.304",
          "0.336",
          "0.35",
          "0.335",
          6.596,
          17.4,
          0.32,
          0,
          73.82,
          80.61,
          88.58,
        ],
      },
      {
        1636: [
          "1289",
          0.92,
          15000,
          41.4,
          "0.963",
          "0.967",
          "0.973",
          "1.023",
          "0.968",
          1.433,
          0.294,
          0.07,
          0,
          32.02,
          38.82,
          52.61,
        ],
      },
      {
        1637: [
          "1250",
          0.098,
          32304200,
          41.4,
          "0.1",
          "0.095",
          "0.098",
          "0.1",
          "0.098",
          320,
          62.2,
          39.59,
          1.02,
          69.68,
          76.88,
          85.91,
        ],
      },
      {
        1638: [
          "8079",
          0.18,
          0,
          41.4,
          "0.17",
          "0.159",
          "0.182",
          "0.187",
          "0.181",
          0,
          0.433,
          14.35,
          0,
          55.61,
          66.18,
          79.38,
        ],
      },
      {
        1639: [
          "3115",
          87.64,
          32000,
          41.3,
          "84.662",
          "86.211",
          "92.214",
          "94.993",
          "91.606",
          281,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1640: [
          "3728",
          0.17,
          80000,
          41.3,
          "0.166",
          "0.171",
          "0.178",
          "0.18",
          "0.177",
          1.376,
          1.722,
          1.29,
          0,
          74.55,
          79.59,
          85.96,
        ],
      },
      {
        1641: [
          "0145",
          0.144,
          0,
          41.2,
          "0.138",
          "0.14",
          "0.143",
          "0.148",
          "0.143",
          0,
          0.754,
          7.67,
          0,
          48.51,
          63.96,
          76.06,
        ],
      },
      {
        1642: [
          "0897",
          0.3,
          120,
          41.2,
          "0.304",
          "0.329",
          "0.323",
          "0.322",
          "0.322",
          0,
          3.695,
          0.85,
          0,
          69.96,
          76.08,
          82.83,
        ],
      },
      {
        1643: [
          "8051",
          4.79,
          0,
          41.2,
          "4.797",
          "4.864",
          "5.156",
          "5.59",
          "5.079",
          0,
          1.122,
          9.47,
          0,
          68.72,
          76.86,
          84.82,
        ],
      },
      {
        1644: [
          "0655",
          0.57,
          0,
          41.1,
          "0.573",
          "0.576",
          "0.601",
          "0.617",
          "0.598",
          0,
          11.3,
          12.1,
          0,
          79.69,
          84.14,
          88.55,
        ],
      },
      {
        1645: [
          "3163",
          37.36,
          0,
          41.1,
          "36.704",
          "37.529",
          "39.96",
          "40.836",
          "39.75",
          0,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1646: [
          "0211",
          0.285,
          555,
          41.1,
          "0.281",
          "0.266",
          "0.293",
          "0.297",
          "0.294",
          0,
          2.002,
          2.34,
          0,
          55.28,
          63.8,
          78.01,
        ],
      },
      {
        1647: [
          "2378",
          139.6,
          38750,
          41,
          "136.27",
          "139.922",
          "149.619",
          "153.577",
          "148.867",
          543,
          3833.9,
          96.52,
          0,
          99.17,
          99.77,
          99.94,
        ],
      },
      {
        1648: [
          "8509",
          0.52,
          0,
          41,
          "0.511",
          "0.504",
          "0.624",
          "0.648",
          "0.612",
          0,
          2.08,
          0.03,
          0,
          89.57,
          92.62,
          95.98,
        ],
      },
      {
        1649: [
          "1972",
          20.1,
          1391060,
          41,
          "20.162",
          "19.834",
          "21.12",
          "21.66",
          "20.92",
          2804,
          1175.8,
          82.5,
          0.02,
          96.09,
          98.1,
          99.04,
        ],
      },
      {
        1650: [
          "0240",
          0.93,
          180000,
          40.9,
          "0.927",
          "0.971",
          "1.014",
          "1.045",
          "1.003",
          16.79,
          11.5,
          54.23,
          0,
          74.22,
          81.49,
          88.51,
        ],
      },
      {
        1651: [
          "0268",
          23.3,
          6869840,
          40.9,
          "23.275",
          "24.078",
          "25.37",
          "25.543",
          "25.215",
          15989,
          809,
          0.15,
          15.9,
          67.54,
          85.26,
          94.52,
        ],
      },
      {
        1652: [
          "0242",
          2.07,
          2739570,
          40.9,
          "2.102",
          "2.119",
          "2.22",
          "2.276",
          "2.203",
          567,
          62.5,
          23.98,
          3.5,
          54.18,
          68.2,
          81.29,
        ],
      },
      {
        1653: [
          "1070",
          4.19,
          2629120,
          40.8,
          "4.119",
          "4.185",
          "4.208",
          "4.562",
          "4.177",
          1112,
          103.9,
          38.52,
          4.51,
          68.31,
          81.64,
          92.43,
        ],
      },
      {
        1654: [
          "3309",
          6.34,
          119570000,
          40.8,
          "6.873",
          "7.086",
          "7.936",
          "7.408",
          "7.884",
          77317,
          72.9,
          41.83,
          12.25,
          80.54,
          86.77,
          92.53,
        ],
      },
      {
        1655: [
          "1668",
          0.66,
          12720500,
          40.7,
          "0.697",
          "0.573",
          "0.678",
          "0.721",
          "0.67",
          848,
          53.4,
          9.14,
          13.84,
          49.54,
          68.84,
          82.78,
        ],
      },
      {
        1656: [
          "1645",
          0.8,
          11452000,
          40.7,
          "0.813",
          "0.851",
          "1.021",
          "1.047",
          "1.0",
          902,
          4.512,
          61.71,
          0,
          69.85,
          74.95,
          82.91,
        ],
      },
      {
        1657: [
          "0565",
          0.315,
          0,
          40.7,
          "0.315",
          "0.366",
          "0.386",
          "0.389",
          "0.387",
          0,
          8.47,
          70.81,
          0,
          85.04,
          91.23,
          96.3,
        ],
      },
      {
        1658: [
          "2188",
          0.36,
          1188000,
          40.6,
          "0.373",
          "0.381",
          "0.395",
          "0.394",
          "0.396",
          42.791,
          3.33,
          0.9,
          0,
          68.06,
          75.04,
          82.65,
        ],
      },
      {
        1659: [
          "1268",
          33.3,
          4069660,
          40.6,
          "35.89",
          "38.354",
          "40.259",
          "39.707",
          "40.12",
          13510,
          415.5,
          0.32,
          6.37,
          70.03,
          82.93,
          96.26,
        ],
      },
      {
        1660: [
          "1213",
          0.46,
          0,
          40.6,
          "0.46",
          "0.475",
          "0.463",
          "0.478",
          "0.464",
          0,
          0.92,
          76.61,
          0,
          86.54,
          93.21,
          96.81,
        ],
      },
      {
        1661: [
          "2314",
          5.63,
          5526940,
          40.5,
          "5.512",
          "5.581",
          "6.1",
          "6.331",
          "6.114",
          3139,
          243.8,
          41.21,
          0.43,
          84.16,
          89.6,
          95.43,
        ],
      },
      {
        1662: [
          "0305",
          1.55,
          4220000,
          40.5,
          "1.602",
          "1.69",
          "1.607",
          "1.67",
          "1.581",
          656,
          51.1,
          65.62,
          0,
          73.02,
          77.77,
          84.65,
        ],
      },
      {
        1663: [
          "2005",
          4.02,
          4824000,
          40.5,
          "4.031",
          "3.745",
          "4.608",
          "4.692",
          "4.546",
          1953,
          120.4,
          4.66,
          11.71,
          52.92,
          68.58,
          89.73,
        ],
      },
      {
        1664: [
          "0207",
          0.405,
          2040670,
          40.4,
          "0.404",
          "0.399",
          "0.424",
          "0.439",
          "0.42",
          82.724,
          57.6,
          64.51,
          0.03,
          77.73,
          83.02,
          90.83,
        ],
      },
      {
        1665: [
          "9115",
          11.24,
          400,
          40.4,
          "10.852",
          "11.061",
          "11.85",
          "12.215",
          "11.773",
          3.496,
          0,
          97.31,
          0,
          99,
          99.54,
          99.93,
        ],
      },
      {
        1666: [
          "1004",
          0.041,
          0,
          40.4,
          "0.041",
          "0.041",
          "0.041",
          "0.041",
          "0.041",
          0,
          3.843,
          0.01,
          0,
          66.98,
          80.46,
          93.2,
        ],
      },
      {
        1667: [
          "0898",
          1.08,
          25774,
          40.3,
          "1.043",
          "1.078",
          "1.219",
          "1.317",
          "-",
          2.743,
          9.03,
          73.24,
          0,
          92.37,
          96.22,
          97.96,
        ],
      },
      {
        1668: [
          "2338",
          15.22,
          6004690,
          40.3,
          "15.338",
          "14.868",
          "16.449",
          "16.918",
          "16.39",
          9183,
          295.7,
          0.22,
          4.75,
          77.63,
          86.55,
          92.74,
        ],
      },
      {
        1669: [
          "2313",
          147.4,
          4826490,
          40.2,
          "143.08",
          "154.246",
          "167.396",
          "169.826",
          "165.92",
          71515,
          2215.7,
          45.77,
          4.01,
          81.6,
          93.59,
          98.87,
        ],
      },
      {
        1670: [
          "0045",
          6.67,
          76500,
          40.2,
          "6.721",
          "7.008",
          "7.296",
          "7.45",
          "7.236",
          51.181,
          110,
          63.55,
          0,
          84.09,
          89.06,
          94.3,
        ],
      },
      {
        1671: [
          "1577",
          1.06,
          462000,
          40.2,
          "1.116",
          "1.158",
          "1.175",
          "1.164",
          "1.176",
          49.432,
          1.908,
          0.02,
          0,
          79.22,
          97.16,
          99,
        ],
      },
      {
        1672: [
          "6816",
          0.55,
          2000,
          40.1,
          "0.589",
          "0.592",
          "0.647",
          "-",
          "-",
          0.12,
          4.4,
          12.89,
          0,
          96.56,
          97.51,
          98.83,
        ],
      },
      {
        1673: [
          "9918",
          0.091,
          24000,
          40.1,
          "0.092",
          "0.094",
          "0.111",
          "0.116",
          "0.109",
          0.215,
          1.82,
          24.02,
          0,
          83.08,
          87.73,
          93.46,
        ],
      },
      {
        1674: [
          "3176",
          10.75,
          0,
          40.1,
          "10.614",
          "-",
          "-",
          "-",
          "-",
          0,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1675: [
          "2186",
          3.64,
          3983000,
          40,
          "3.65",
          "3.627",
          "4.074",
          "4.283",
          "3.995",
          1450,
          128.9,
          0,
          15.02,
          42.74,
          59.55,
          85.86,
        ],
      },
      {
        1676: [
          "2800",
          24.56,
          136489000,
          40,
          "23.702",
          "24.185",
          "25.959",
          "26.709",
          "25.783",
          335000,
          0,
          7.56,
          0,
          51.49,
          62.62,
          75.58,
        ],
      },
      {
        1677: [
          "2825",
          25.9,
          170000,
          40,
          "25.016",
          "25.768",
          "27.287",
          "28.186",
          "27.117",
          443,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1678: [
          "1906",
          0.395,
          585000,
          39.9,
          "0.4",
          "0.428",
          "0.449",
          "0.458",
          "0.445",
          22.99,
          4.74,
          57.14,
          0,
          80.02,
          85.92,
          95.39,
        ],
      },
      {
        1679: [
          "1228",
          8,
          247000,
          39.9,
          "8.312",
          "-",
          "-",
          "-",
          "-",
          187,
          33.9,
          84.97,
          0,
          95.01,
          96.97,
          98.76,
        ],
      },
      {
        1680: [
          "0718",
          0.096,
          0,
          39.8,
          "0.095",
          "0.098",
          "0.115",
          "0.129",
          "0.111",
          0,
          5.04,
          0.02,
          0.38,
          88.93,
          91.2,
          93.95,
        ],
      },
      {
        1681: [
          "0270",
          9.96,
          5001430,
          39.8,
          "9.912",
          "10.088",
          "10.509",
          "10.865",
          "10.433",
          4981,
          651.1,
          56.97,
          0.35,
          84.16,
          92.09,
          95.69,
        ],
      },
      {
        1682: [
          "0702",
          0.145,
          270000,
          39.8,
          "0.152",
          "0.177",
          "0.23",
          "0.251",
          "0.225",
          3.923,
          4.851,
          18.24,
          0,
          51.73,
          69.59,
          81,
        ],
      },
      {
        1683: [
          "1357",
          1.65,
          9002810,
          39.7,
          "1.572",
          "1.649",
          "1.687",
          "1.855",
          "1.672",
          1510,
          72.3,
          1.57,
          9.48,
          43,
          55.81,
          74.54,
        ],
      },
      {
        1684: [
          "0306",
          1.99,
          6000,
          39.7,
          "2.06",
          "2.174",
          "2.194",
          "2.247",
          "2.174",
          1.19,
          9.488,
          5.16,
          0,
          84.3,
          88.17,
          92.26,
        ],
      },
      {
        1685: [
          "1099",
          18.3,
          3111980,
          39.7,
          "17.836",
          "17.446",
          "19.979",
          "20.945",
          "19.688",
          5680,
          245.5,
          0.1,
          3.16,
          76.56,
          85.47,
          91.83,
        ],
      },
      {
        1686: [
          "0358",
          13.74,
          13265700,
          39.6,
          "12.754",
          "12.918",
          "14.576",
          "15.401",
          "14.478",
          18328,
          190.6,
          0.4,
          3.57,
          58.14,
          70.3,
          80.82,
        ],
      },
      {
        1687: [
          "0051",
          6.78,
          0,
          39.6,
          "6.813",
          "6.925",
          "7.573",
          "7.685",
          "7.519",
          0,
          48,
          75.99,
          0,
          88.5,
          94.43,
          96.61,
        ],
      },
      {
        1688: [
          "1365",
          0.3,
          0,
          39.6,
          "0.3",
          "0.3",
          "0.3",
          "0.301",
          "0.3",
          0,
          3.44,
          23.31,
          0,
          64.18,
          77.23,
          94.87,
        ],
      },
      {
        1689: [
          "2295",
          1.3,
          155000,
          39.5,
          "1.365",
          "-",
          "-",
          "-",
          "-",
          19.745,
          5.2,
          75,
          0,
          86.1,
          90.92,
          96.36,
        ],
      },
      {
        1690: [
          "0588",
          1.23,
          7078000,
          39.5,
          "1.231",
          "1.193",
          "1.305",
          "1.344",
          "1.295",
          872,
          8.696,
          2.86,
          34.43,
          51.2,
          63.71,
          74.57,
        ],
      },
      {
        1691: [
          "2356",
          7.22,
          1006000,
          39.5,
          "6.928",
          "6.984",
          "7.557",
          "7.867",
          "7.506",
          724,
          101.4,
          75.11,
          0.03,
          86.13,
          90.03,
          92.8,
        ],
      },
      {
        1692: [
          "0859",
          0.31,
          2000,
          39.4,
          "0.345",
          "0.38",
          "0.337",
          "0.335",
          "0.34",
          0,
          3.488,
          0.03,
          0,
          91.35,
          93.61,
          96.72,
        ],
      },
      {
        1693: [
          "6988",
          2.38,
          5447000,
          39.4,
          "2.495",
          "2.285",
          "2.464",
          "2.596",
          "2.456",
          1313,
          52,
          52.6,
          4.15,
          62.31,
          71.13,
          84.82,
        ],
      },
      {
        1694: [
          "2995",
          3.02,
          188750,
          39.3,
          "3.456",
          "-",
          "-",
          "-",
          "-",
          57.651,
          35.4,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1695: [
          "0195",
          0.091,
          2550000,
          39.3,
          "0.091",
          "0.093",
          "0.099",
          "0.098",
          "0.098",
          23.36,
          6.215,
          25.76,
          0,
          62.74,
          71.26,
          80.68,
        ],
      },
      {
        1696: [
          "1997",
          39,
          1721180,
          39.3,
          "38.63",
          "40.834",
          "41.44",
          "42.305",
          "41.172",
          6683,
          1184.1,
          55.04,
          0.22,
          84.01,
          89.8,
          94.24,
        ],
      },
      {
        1697: [
          "0232",
          0.115,
          4390000,
          39.2,
          "0.114",
          "0.117",
          "0.127",
          "0.128",
          "0.126",
          50.121,
          10.6,
          26.17,
          0,
          45.54,
          57.87,
          70.59,
        ],
      },
      {
        1698: [
          "1007",
          0.108,
          542000,
          39.2,
          "0.113",
          "0.112",
          "0.114",
          "0.119",
          "0.11",
          5.808,
          0.736,
          6.02,
          0,
          71.05,
          76.89,
          85.79,
        ],
      },
      {
        1699: [
          "2708",
          2.7,
          588000,
          39.2,
          "2.487",
          "2.621",
          "3.08",
          "3.392",
          "3.038",
          155,
          14.8,
          11.83,
          0,
          45,
          60.37,
          81.69,
        ],
      },
      {
        1700: [
          "7233",
          9.275,
          1744400,
          39.1,
          "9.587",
          "9.788",
          "9.895",
          "10.095",
          "9.838",
          1634,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1701: [
          "0068",
          0.52,
          0,
          39.1,
          "0.52",
          "0.52",
          "0.52",
          "0.52",
          "0.52",
          0,
          0.763,
          89.51,
          0,
          98.94,
          99.17,
          99.48,
        ],
      },
      {
        1702: [
          "6162",
          0.14,
          240000,
          39.1,
          "0.146",
          "0.163",
          "0.191",
          "0.182",
          "0.19",
          3.36,
          2.8,
          75.02,
          0,
          94.22,
          96.1,
          97.93,
        ],
      },
      {
        1703: [
          "1227",
          0.023,
          0,
          39,
          "0.023",
          "0.023",
          "0.023",
          "0.023",
          "0.023",
          0,
          0.377,
          0.21,
          0,
          27.47,
          39.28,
          57.87,
        ],
      },
      {
        1704: [
          "0658",
          5.39,
          2337660,
          39,
          "5.528",
          "6.079",
          "5.769",
          "5.76",
          "5.813",
          1266,
          88.1,
          0.16,
          7.01,
          86.23,
          89.26,
          94.48,
        ],
      },
      {
        1705: [
          "0218",
          0.93,
          90000,
          39,
          "0.93",
          "0.959",
          "1.011",
          "1.008",
          "1.011",
          8.43,
          14.5,
          26.24,
          0,
          80.27,
          86.77,
          90.24,
        ],
      },
      {
        1706: [
          "0052",
          15.34,
          52940,
          38.9,
          "15.362",
          "16.192",
          "16.973",
          "17.067",
          "16.893",
          81.335,
          19.8,
          40.36,
          0,
          65.37,
          75.22,
          84.47,
        ],
      },
      {
        1707: [
          "1877",
          46.25,
          1485000,
          38.9,
          "47.335",
          "44.985",
          "48.351",
          "55.637",
          "46.99",
          7004,
          101.4,
          0,
          16.35,
          52.4,
          64.95,
          83.35,
        ],
      },
      {
        1708: [
          "2115",
          0.144,
          1430000,
          38.8,
          "0.144",
          "0.133",
          "0.132",
          "0.137",
          "0.131",
          20.163,
          2.016,
          11.04,
          0,
          51.57,
          72.9,
          85.56,
        ],
      },
      {
        1709: [
          "0912",
          1.6,
          0,
          38.8,
          "1.596",
          "1.693",
          "1.902",
          "1.869",
          "1.879",
          0,
          4.557,
          12.43,
          0,
          68.64,
          75.2,
          82.69,
        ],
      },
      {
        1710: [
          "0619",
          0.335,
          40000,
          38.8,
          "0.327",
          "0.323",
          "0.389",
          "0.396",
          "0.393",
          1.34,
          1.009,
          33.77,
          0,
          64.54,
          72.27,
          79.48,
        ],
      },
      {
        1711: [
          "8368",
          0.355,
          0,
          38.7,
          "0.355",
          "0.413",
          "0.467",
          "0.441",
          "0.496",
          0,
          1.176,
          51.77,
          0,
          83.79,
          87.38,
          93.17,
        ],
      },
      {
        1712: [
          "1580",
          0.116,
          0,
          38.7,
          "0.119",
          "0.117",
          "0.117",
          "0.126",
          "0.117",
          0,
          1.13,
          17.38,
          0,
          50.52,
          68.93,
          90.34,
        ],
      },
      {
        1713: [
          "0062",
          12.82,
          7600,
          38.7,
          "12.828",
          "12.855",
          "13.83",
          "14.333",
          "13.712",
          9.728,
          59.6,
          53.76,
          0,
          76.92,
          82.39,
          89.87,
        ],
      },
      {
        1714: [
          "6609",
          103.6,
          36550,
          38.6,
          "103.77",
          "89.496",
          "-",
          "-",
          "-",
          377,
          32.7,
          57.87,
          0,
          80.72,
          85.95,
          94.53,
        ],
      },
      {
        1715: [
          "0851",
          0.037,
          0,
          38.6,
          "0.037",
          "0.04",
          "0.047",
          "0.049",
          "0.047",
          0,
          1.413,
          29.63,
          0,
          64.97,
          75.71,
          88.09,
        ],
      },
      {
        1716: [
          "3007",
          286.7,
          0,
          38.6,
          "275.39",
          "285.946",
          "302.105",
          "311.552",
          "300.36",
          0,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1717: [
          "2333",
          25.5,
          16864200,
          38.5,
          "25.365",
          "30.087",
          "30.5",
          "28.229",
          "30.938",
          43090,
          790.3,
          0.46,
          46.67,
          70.19,
          83.86,
          92.6,
        ],
      },
      {
        1718: [
          "8149",
          0.19,
          0,
          38.5,
          "0.191",
          "0.195",
          "0.204",
          "0.208",
          "0.203",
          0,
          1.527,
          78,
          0,
          86.78,
          91.65,
          96.25,
        ],
      },
      {
        1719: [
          "0487",
          0.123,
          0,
          38.5,
          "0.125",
          "0.128",
          "0.139",
          "0.141",
          "0.137",
          0,
          6.06,
          44.03,
          0,
          89.9,
          92.55,
          95.35,
        ],
      },
      {
        1720: [
          "8113",
          0.355,
          10000,
          38.4,
          "0.354",
          "0.363",
          "0.399",
          "0.407",
          "0.395",
          0.355,
          2.317,
          34.89,
          0,
          72.2,
          80.74,
          90.1,
        ],
      },
      {
        1721: [
          "0083",
          9.86,
          2895510,
          38.4,
          "9.734",
          "9.72",
          "11.016",
          "11.171",
          "10.858",
          2855,
          750.8,
          41.11,
          0.05,
          68.16,
          87.27,
          96.97,
        ],
      },
      {
        1722: [
          "3360",
          7.11,
          2203940,
          38.3,
          "6.983",
          "6.952",
          "7.807",
          "8.052",
          "7.781",
          1562,
          306.7,
          21.33,
          4.03,
          56.31,
          77.45,
          97.63,
        ],
      },
      {
        1723: [
          "8319",
          0.121,
          250000,
          38.3,
          "0.13",
          "0.134",
          "0.149",
          "0.147",
          "0.147",
          3.032,
          0.972,
          75.35,
          0,
          79.95,
          83.76,
          89.72,
        ],
      },
      {
        1724: [
          "3166",
          43.3,
          0,
          38.3,
          "43.24",
          "44.601",
          "46.634",
          "47.996",
          "46.199",
          0,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1725: [
          "0239",
          1.58,
          0,
          38.2,
          "1.58",
          "1.621",
          "1.722",
          "1.75",
          "1.713",
          0,
          4.924,
          58.13,
          0,
          73.9,
          83.78,
          93.22,
        ],
      },
      {
        1726: [
          "1748",
          2.12,
          24000,
          38.2,
          "2.678",
          "2.891",
          "3.133",
          "2.8",
          "3.165",
          5.482,
          9.328,
          64.78,
          0,
          88.7,
          97.68,
          99.35,
        ],
      },
      {
        1727: [
          "2033",
          0.72,
          30000,
          38.2,
          "0.714",
          "0.755",
          "0.803",
          "0.804",
          "0.797",
          2.154,
          14.9,
          0.05,
          0,
          80.09,
          88.69,
          91.9,
        ],
      },
      {
        1728: [
          "2286",
          1.55,
          96000,
          38.1,
          "1.511",
          "1.624",
          "1.716",
          "1.741",
          "1.709",
          15.351,
          9.3,
          0.02,
          0,
          95.69,
          96.68,
          98.03,
        ],
      },
      {
        1729: [
          "0169",
          0.315,
          375000,
          38.1,
          "0.31",
          "0.314",
          "0.379",
          "0.396",
          "0.368",
          11.121,
          14.7,
          66.76,
          0,
          82.55,
          89.84,
          94.39,
        ],
      },
      {
        1730: [
          "1508",
          0.75,
          9230000,
          38.1,
          "0.759",
          "0.755",
          "0.793",
          "0.802",
          "0.792",
          696,
          50,
          0.21,
          18.16,
          46.53,
          61.79,
          79.59,
        ],
      },
      {
        1731: [
          "6913",
          1.15,
          12000,
          38,
          "1.154",
          "1.203",
          "-",
          "-",
          "-",
          1.388,
          15.3,
          74.96,
          0,
          88.17,
          93.52,
          97.61,
        ],
      },
      {
        1732: [
          "0114",
          0.56,
          17500,
          38,
          "0.535",
          "0.543",
          "0.607",
          "0.62",
          "0.602",
          0.96,
          3.385,
          34.86,
          0,
          80.91,
          85.65,
          91.52,
        ],
      },
      {
        1733: [
          "0272",
          1.07,
          2176860,
          38,
          "1.063",
          "1.054",
          "1.185",
          "1.209",
          "1.174",
          235,
          86.2,
          18.02,
          9.74,
          43.44,
          61.91,
          83.46,
        ],
      },
      {
        1734: [
          "1362",
          0.37,
          108000,
          37.9,
          "0.429",
          "0.442",
          "0.463",
          "0.465",
          "0.461",
          3.996,
          1.036,
          75.71,
          0,
          83.96,
          88.02,
          93.38,
        ],
      },
      {
        1735: [
          "1195",
          0.042,
          56000,
          37.9,
          "0.044",
          "0.045",
          "0.045",
          "0.046",
          "0.045",
          0.235,
          1.216,
          10.06,
          0,
          31.41,
          42.65,
          63.77,
        ],
      },
      {
        1736: [
          "1104",
          1.04,
          3646760,
          37.8,
          "1.082",
          "1.205",
          "1.295",
          "1.288",
          "1.288",
          382,
          13.5,
          23.47,
          0,
          72.59,
          79.07,
          89.68,
        ],
      },
      {
        1737: [
          "3185",
          54,
          9500,
          37.8,
          "56.134",
          "-",
          "-",
          "-",
          "-",
          51.459,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1738: [
          "8048",
          0.045,
          1144000,
          37.8,
          "0.045",
          "0.046",
          "0.048",
          "0.05",
          "0.048",
          4.498,
          0.876,
          8.75,
          0,
          75.3,
          81.37,
          90.37,
        ],
      },
      {
        1739: [
          "0395",
          0.016,
          0,
          37.7,
          "0.016",
          "0.016",
          "0.016",
          "0.016",
          "0.016",
          0,
          0.902,
          7.15,
          0,
          29.97,
          39.86,
          55.16,
        ],
      },
      {
        1740: [
          "6138",
          0.8,
          155000,
          37.7,
          "0.796",
          "0.802",
          "0.868",
          "0.899",
          "0.858",
          12.378,
          24.1,
          0.15,
          0,
          68.35,
          83.8,
          94.07,
        ],
      },
      {
        1741: [
          "3998",
          4.23,
          45910400,
          37.7,
          "4.446",
          "5.175",
          "5.451",
          "5.071",
          "5.502",
          19591,
          460.3,
          1.85,
          9.15,
          73.7,
          84.46,
          95.95,
        ],
      },
      {
        1742: [
          "1941",
          0.68,
          4000,
          37.6,
          "0.68",
          "0.715",
          "0.711",
          "0.709",
          "0.714",
          0.272,
          2.756,
          54.97,
          0,
          69.63,
          77.32,
          90.86,
        ],
      },
      {
        1743: [
          "0683",
          20.45,
          2146210,
          37.6,
          "20.565",
          "20.588",
          "22.828",
          "23.561",
          "22.585",
          4427,
          297.4,
          43.3,
          0.16,
          71.31,
          82.3,
          95.72,
        ],
      },
      {
        1744: [
          "0019",
          46,
          666712,
          37.6,
          "45.155",
          "45.478",
          "49.271",
          "52.073",
          "48.797",
          3061,
          416.3,
          51.93,
          0.22,
          82.45,
          88.35,
          93.66,
        ],
      },
      {
        1745: [
          "2838",
          171.1,
          561,
          37.5,
          "164.405",
          "170.625",
          "180.533",
          "186.743",
          "179.328",
          9.614,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1746: [
          "1177",
          5.97,
          26704400,
          37.5,
          "5.868",
          "5.63",
          "6.347",
          "6.842",
          "6.245",
          15971,
          1124.7,
          36.58,
          3.09,
          72.36,
          83.41,
          91.75,
        ],
      },
      {
        1747: [
          "3058",
          52.08,
          22564,
          37.5,
          "52.152",
          "55.204",
          "57.863",
          "58.62",
          "57.411",
          117,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1748: [
          "3888",
          36.5,
          4664670,
          37.4,
          "34.66",
          "34.165",
          "35.971",
          "40.684",
          "35.058",
          16993,
          500.1,
          0.21,
          14.29,
          69.63,
          83.92,
          91.93,
        ],
      },
      {
        1749: [
          "1466",
          0.118,
          0,
          37.4,
          "0.119",
          "0.126",
          "0.135",
          "0.137",
          "0.134",
          0,
          0.377,
          0.06,
          0,
          69.27,
          76.53,
          85.5,
        ],
      },
      {
        1750: [
          "8030",
          0.018,
          0,
          37.3,
          "0.018",
          "0.018",
          "0.018",
          "0.018",
          "0.018",
          0,
          0.312,
          21.47,
          0,
          64.36,
          74.07,
          84.17,
        ],
      },
      {
        1751: [
          "1841",
          0.425,
          100000,
          37.3,
          "0.423",
          "0.465",
          "0.509",
          "0.489",
          "0.515",
          4.2,
          1.7,
          45.86,
          0,
          79.75,
          86.05,
          94.01,
        ],
      },
      {
        1752: [
          "0432",
          0.61,
          250360,
          37.3,
          "0.597",
          "0.6",
          "0.644",
          "0.662",
          "0.638",
          15.11,
          12.4,
          28.5,
          0.01,
          63.57,
          71.09,
          80.31,
        ],
      },
      {
        1753: [
          "0293",
          6.34,
          4612210,
          37.2,
          "6.416",
          "6.667",
          "6.669",
          "6.717",
          "6.637",
          2935,
          408.1,
          68.42,
          0.31,
          88.92,
          91.8,
          95.32,
        ],
      },
      {
        1754: [
          "6958",
          4.12,
          2485000,
          37.2,
          "4.414",
          "4.532",
          "4.798",
          "4.882",
          "4.795",
          1037,
          42.7,
          0.01,
          0,
          79.61,
          85.93,
          92.83,
        ],
      },
      {
        1755: [
          "0231",
          0.01,
          0,
          37.2,
          "0.01",
          "0.01",
          "0.01",
          "0.01",
          "0.01",
          0,
          0.516,
          1.65,
          0,
          39.71,
          47.72,
          61.84,
        ],
      },
      {
        1756: [
          "8161",
          0.054,
          0,
          37.1,
          "0.054",
          "0.056",
          "0.059",
          "0.06",
          "0.059",
          0,
          0.562,
          59.71,
          0,
          81.08,
          86.1,
          91.07,
        ],
      },
      {
        1757: [
          "8206",
          0.054,
          0,
          37.1,
          "0.059",
          "0.06",
          "0.058",
          "0.058",
          "0.057",
          0,
          1.024,
          50.01,
          0,
          69.65,
          79,
          88.58,
        ],
      },
      {
        1758: [
          "9699",
          13.16,
          675000,
          37.1,
          "14.526",
          "-",
          "-",
          "-",
          "-",
          907,
          30.4,
          20.8,
          0,
          76.32,
          84.3,
          93.25,
        ],
      },
      {
        1759: [
          "1343",
          0.112,
          235000,
          37,
          "0.116",
          "0.121",
          "0.12",
          "0.129",
          "0.12",
          2.56,
          1.192,
          75,
          0,
          82.14,
          86.52,
          90.07,
        ],
      },
      {
        1760: [
          "0770",
          0.236,
          0,
          37,
          "0.236",
          "0.244",
          "0.252",
          "0.278",
          "0.248",
          0,
          0.196,
          35.75,
          0,
          59.42,
          68.14,
          83.02,
        ],
      },
      {
        1761: [
          "9072",
          18.33,
          0,
          36.9,
          "18.287",
          "19.745",
          "20.735",
          "20.658",
          "20.688",
          0,
          0,
          14.68,
          0,
          98.85,
          99.75,
          99.99,
        ],
      },
      {
        1762: [
          "0484",
          1.27,
          67300,
          36.9,
          "1.276",
          "1.195",
          "1.317",
          "1.444",
          "1.289",
          8.378,
          1.863,
          8.86,
          0,
          32.18,
          48.25,
          71.55,
        ],
      },
      {
        1763: [
          "1319",
          0.25,
          0,
          36.9,
          "0.246",
          "0.25",
          "0.265",
          "0.271",
          "0.264",
          0,
          4.823,
          0.4,
          0,
          89.37,
          91.36,
          93.53,
        ],
      },
      {
        1764: [
          "1234",
          4.38,
          202000,
          36.8,
          "4.361",
          "4.325",
          "4.713",
          "4.784",
          "4.678",
          88.521,
          52.4,
          58.34,
          6.27,
          74.11,
          84.11,
          92.8,
        ],
      },
      {
        1765: [
          "6119",
          0.34,
          0,
          36.8,
          "0.335",
          "0.356",
          "0.389",
          "0.394",
          "0.385",
          0,
          2.04,
          75.03,
          0,
          84.33,
          88.81,
          94.32,
        ],
      },
      {
        1766: [
          "1246",
          0.179,
          800,
          36.8,
          "0.189",
          "0.181",
          "0.202",
          "0.219",
          "0.197",
          0,
          2.431,
          10.91,
          0,
          77.31,
          85.57,
          92.77,
        ],
      },
      {
        1767: [
          "6189",
          0.68,
          30500,
          36.7,
          "0.696",
          "0.695",
          "0.741",
          "0.776",
          "0.738",
          2.017,
          0.427,
          0.29,
          0,
          32.13,
          42.64,
          60.8,
        ],
      },
      {
        1768: [
          "1123",
          0.13,
          102000,
          36.7,
          "0.142",
          "0.143",
          "0.156",
          "0.155",
          "0.154",
          1.32,
          1.541,
          66.56,
          0,
          74.91,
          81.07,
          87.47,
        ],
      },
      {
        1769: [
          "0522",
          82.5,
          646482,
          36.7,
          "83.585",
          "83.168",
          "91.29",
          "94.944",
          "90.712",
          5356,
          340.4,
          0.74,
          2.1,
          75.31,
          89.73,
          94.58,
        ],
      },
      {
        1770: [
          "0096",
          0.93,
          2000,
          36.6,
          "1.023",
          "1.034",
          "1.015",
          "1.038",
          "1.016",
          0.186,
          5.92,
          75.47,
          0,
          90.73,
          96.22,
          99.32,
        ],
      },
      {
        1771: [
          "1929",
          12.98,
          4395740,
          36.6,
          "13.184",
          "14.684",
          "15.532",
          "14.903",
          "15.496",
          5739,
          1298,
          84.64,
          0.54,
          94.03,
          97.24,
          98.69,
        ],
      },
      {
        1772: [
          "0979",
          0.25,
          0,
          36.6,
          "0.258",
          "0.25",
          "0.258",
          "0.265",
          "0.257",
          0,
          2.841,
          36.85,
          0,
          67.37,
          77.24,
          85.36,
        ],
      },
      {
        1773: [
          "3999",
          0.72,
          64000,
          36.5,
          "0.741",
          "0.741",
          "0.795",
          "0.827",
          "0.783",
          4.566,
          7.316,
          60.58,
          0,
          76.36,
          82.42,
          91.53,
        ],
      },
      {
        1774: [
          "0341",
          13.66,
          144000,
          36.5,
          "13.768",
          "13.877",
          "14.584",
          "15.135",
          "14.451",
          196,
          80,
          31.04,
          1.01,
          77.25,
          84.64,
          91.86,
        ],
      },
      {
        1775: [
          "2488",
          3.2,
          89500,
          36.4,
          "3.094",
          "2.882",
          "3.498",
          "3.684",
          "3.424",
          28.763,
          5.717,
          0.11,
          0,
          27.34,
          37.02,
          55.74,
        ],
      },
      {
        1776: [
          "1526",
          1,
          30000,
          36.4,
          "1.164",
          "1.103",
          "1.217",
          "1.216",
          "1.223",
          2.957,
          15.9,
          4.7,
          0,
          72.28,
          86.74,
          95.34,
        ],
      },
      {
        1777: [
          "0497",
          0.21,
          642914,
          36.4,
          "0.208",
          "0.216",
          "0.234",
          "0.239",
          "0.233",
          13.504,
          19.7,
          2.52,
          0,
          70.68,
          76.46,
          87.32,
        ],
      },
      {
        1778: [
          "1379",
          4.86,
          0,
          36.3,
          "4.927",
          "5.028",
          "5.182",
          "5.389",
          "5.119",
          0,
          0.972,
          0.01,
          0,
          68.05,
          78.22,
          94.1,
        ],
      },
      {
        1779: [
          "2232",
          2.61,
          328000,
          36.3,
          "2.584",
          "2.669",
          "3.038",
          "3.245",
          "2.971",
          86.871,
          74.4,
          75.87,
          0.09,
          94.98,
          96.76,
          98.38,
        ],
      },
      {
        1780: [
          "0321",
          1.49,
          1196000,
          36.3,
          "1.535",
          "1.613",
          "1.679",
          "1.732",
          "1.663",
          179,
          20.5,
          1.65,
          0.06,
          75.64,
          81.66,
          89.83,
        ],
      },
      {
        1781: [
          "1747",
          0.73,
          0,
          36.2,
          "0.736",
          "0.731",
          "0.778",
          "0.804",
          "0.777",
          0,
          3.662,
          7.5,
          0,
          88.4,
          93.17,
          97.01,
        ],
      },
      {
        1782: [
          "3072",
          141.8,
          90,
          36.2,
          "142.45",
          "153.87",
          "161.358",
          "160.694",
          "160.983",
          1.276,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1783: [
          "1432",
          0.55,
          870000,
          36.2,
          "0.541",
          "0.551",
          "0.578",
          "0.589",
          "0.573",
          48.348,
          46,
          9.39,
          1,
          54.48,
          70.26,
          88.51,
        ],
      },
      {
        1784: [
          "0543",
          1.29,
          166000,
          36.1,
          "1.294",
          "1.429",
          "1.567",
          "1.628",
          "1.544",
          21.401,
          14.6,
          30.02,
          0,
          69.63,
          78.66,
          89.03,
        ],
      },
      {
        1785: [
          "0910",
          0.475,
          17000,
          36.1,
          "0.48",
          "0.488",
          "0.515",
          "0.525",
          "0.51",
          0.797,
          24.1,
          12.24,
          0,
          78.64,
          91.23,
          96.67,
        ],
      },
      {
        1786: [
          "1355",
          0.29,
          822000,
          36.1,
          "0.3",
          "0.334",
          "0.338",
          "0.335",
          "0.335",
          24.226,
          1.3,
          11.72,
          0,
          75.93,
          83.51,
          89.68,
        ],
      },
      {
        1787: [
          "0263",
          0.039,
          0,
          36,
          "0.039",
          "0.039",
          "0.039",
          "0.039",
          "0.039",
          0,
          0.066,
          0.08,
          0,
          34.2,
          42.32,
          57.6,
        ],
      },
      {
        1788: [
          "1060",
          0.84,
          23644500,
          36,
          "0.782",
          "0.801",
          "0.885",
          "0.927",
          "0.872",
          2003,
          225.4,
          50.35,
          15.6,
          69.38,
          77.4,
          88.46,
        ],
      },
      {
        1789: [
          "0064",
          0.16,
          0,
          35.9,
          "0.162",
          "0.163",
          "0.172",
          "0.177",
          "0.171",
          0,
          15.4,
          31.36,
          0,
          69.95,
          76.84,
          82.71,
        ],
      },
      {
        1790: [
          "0759",
          0.65,
          44000,
          35.9,
          "0.655",
          "0.646",
          "0.709",
          "0.728",
          "0.701",
          2.808,
          4.33,
          64.01,
          0,
          80.4,
          85.4,
          92.31,
        ],
      },
      {
        1791: [
          "0104",
          0.22,
          0,
          35.9,
          "0.218",
          "0.226",
          "0.249",
          "0.249",
          "0.246",
          0,
          1.644,
          17.56,
          0,
          72.57,
          81.15,
          88.12,
        ],
      },
      {
        1792: [
          "2283",
          2.59,
          18000,
          35.8,
          "2.565",
          "2.613",
          "2.885",
          "2.952",
          "2.865",
          4.65,
          21.5,
          23.86,
          0,
          78.44,
          89.48,
          94.38,
        ],
      },
      {
        1793: [
          "0318",
          0.425,
          0,
          35.8,
          "0.422",
          "0.434",
          "0.462",
          "0.482",
          "0.459",
          0,
          0.965,
          56.6,
          0,
          68.1,
          73.99,
          80.34,
        ],
      },
      {
        1794: [
          "0355",
          0.385,
          68450,
          35.8,
          "0.397",
          "0.42",
          "0.434",
          "0.436",
          "0.433",
          2.658,
          11.9,
          52.69,
          0,
          77.32,
          82.23,
          89.67,
        ],
      },
      {
        1795: [
          "0372",
          0.26,
          5204000,
          35.7,
          "0.267",
          "0.285",
          "0.302",
          "0.323",
          "0.299",
          137,
          5.248,
          0.18,
          0,
          54.95,
          64.51,
          77.89,
        ],
      },
      {
        1796: [
          "0373",
          2.72,
          0,
          35.7,
          "2.721",
          "2.743",
          "2.932",
          "3.003",
          "2.935",
          0,
          95.6,
          44.64,
          0,
          93.41,
          95.11,
          96.36,
        ],
      },
      {
        1797: [
          "0163",
          0.92,
          64720,
          35.7,
          "0.926",
          "0.977",
          "1.057",
          "1.072",
          "1.049",
          5.994,
          33.8,
          67.22,
          0.28,
          88.64,
          91.5,
          94.91,
        ],
      },
      {
        1798: [
          "1630",
          0.093,
          1458000,
          35.6,
          "0.098",
          "0.117",
          "0.113",
          "0.111",
          "0.115",
          13.473,
          1.395,
          0.01,
          0,
          86.12,
          88.58,
          91.18,
        ],
      },
      {
        1799: [
          "0815",
          0.6,
          1924000,
          35.6,
          "0.591",
          "0.603",
          "0.617",
          "0.704",
          "0.612",
          115,
          9.77,
          0.04,
          0,
          42.34,
          55.07,
          70.52,
        ],
      },
      {
        1800: [
          "1299",
          82.7,
          47113200,
          35.6,
          "79.91",
          "82.02",
          "89.062",
          "91.504",
          "88.58",
          390000,
          10004.2,
          0.18,
          0.76,
          84.67,
          92.47,
          96.64,
        ],
      },
      {
        1801: [
          "1138",
          3.12,
          3272000,
          35.5,
          "3.074",
          "3.026",
          "3.265",
          "3.333",
          "3.236",
          1026,
          40.4,
          0.79,
          19.45,
          43.69,
          57.54,
          75.28,
        ],
      },
      {
        1802: [
          "1585",
          13.48,
          2750140,
          35.5,
          "13.918",
          "14.156",
          "14.307",
          "15.07",
          "14.129",
          3724,
          403.7,
          65.7,
          9.04,
          83.29,
          90.17,
          95.14,
        ],
      },
      {
        1803: [
          "1635",
          1.73,
          477000,
          35.4,
          "1.723",
          "1.71",
          "1.818",
          "1.903",
          "1.801",
          82.808,
          9.232,
          0.02,
          21.6,
          64.24,
          79.29,
          93.91,
        ],
      },
      {
        1804: [
          "0632",
          0.26,
          225200,
          35.4,
          "0.263",
          "0.269",
          "0.273",
          "0.277",
          "0.272",
          5.902,
          2.189,
          11.69,
          0,
          83.55,
          87.54,
          91.63,
        ],
      },
      {
        1805: [
          "0677",
          0.51,
          452000,
          35.4,
          "0.511",
          "0.516",
          "0.557",
          "0.576",
          "0.553",
          23.054,
          8.657,
          45.2,
          0,
          62.45,
          73.53,
          86.35,
        ],
      },
      {
        1806: [
          "0925",
          0.093,
          142000,
          35.3,
          "0.094",
          "0.096",
          "0.105",
          "0.105",
          "0.104",
          1.319,
          6.481,
          37.23,
          0,
          67.39,
          78.09,
          86.31,
        ],
      },
      {
        1807: [
          "2009",
          1.27,
          11390000,
          35.3,
          "1.249",
          "1.211",
          "1.345",
          "1.404",
          "1.336",
          1452,
          29.7,
          0.53,
          18.39,
          35.31,
          47.64,
          65.56,
        ],
      },
      {
        1808: [
          "0690",
          0.086,
          2310200,
          35.3,
          "0.091",
          "0.092",
          "0.098",
          "0.098",
          "0.097",
          19.829,
          5.461,
          35.43,
          0,
          63.5,
          71.43,
          80.93,
        ],
      },
      {
        1809: [
          "1130",
          0.06,
          140000,
          35.2,
          "0.063",
          "0.066",
          "0.067",
          "0.07",
          "0.066",
          0.84,
          1.222,
          0.34,
          0,
          57.04,
          68.25,
          79.26,
        ],
      },
      {
        1810: [
          "8195",
          1.295,
          0,
          35.2,
          "1.295",
          "1.307",
          "1.419",
          "1.455",
          "-",
          0,
          4.642,
          35.69,
          0,
          60.49,
          69.09,
          84.43,
        ],
      },
      {
        1811: [
          "2181",
          0.99,
          0,
          35.2,
          "0.991",
          "1.007",
          "1.065",
          "1.095",
          "1.061",
          0,
          40.8,
          5.85,
          0,
          85.79,
          94.82,
          98.73,
        ],
      },
      {
        1812: [
          "7332",
          6.545,
          5400,
          35.1,
          "6.635",
          "6.86",
          "7.179",
          "-",
          "7.148",
          3.536,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1813: [
          "0184",
          3.1,
          66000,
          35.1,
          "2.957",
          "3.082",
          "3.763",
          "3.701",
          "3.733",
          20.58,
          10.5,
          55.83,
          0,
          82.78,
          90.67,
          97.33,
        ],
      },
      {
        1814: [
          "1788",
          1.11,
          6222000,
          35.1,
          "1.092",
          "1.097",
          "1.211",
          "1.261",
          "1.205",
          692,
          106.7,
          0.08,
          1.96,
          86.27,
          89.87,
          93.96,
        ],
      },
      {
        1815: [
          "0262",
          0.1,
          2000000,
          35,
          "0.098",
          "0.1",
          "0.111",
          "0.116",
          "0.11",
          20,
          1.467,
          36.48,
          0,
          65.93,
          74.98,
          87.6,
        ],
      },
      {
        1816: [
          "6677",
          4.32,
          278570,
          35,
          "4.481",
          "4.059",
          "4.782",
          "4.84",
          "4.701",
          120,
          51.1,
          67.12,
          0,
          84.65,
          91.1,
          96.43,
        ],
      },
      {
        1817: [
          "1251",
          0.285,
          6694000,
          34.9,
          "0.27",
          "0.264",
          "0.279",
          "0.289",
          "0.28",
          192,
          5.283,
          0.02,
          0,
          59.73,
          68.82,
          79.5,
        ],
      },
      {
        1818: [
          "0183",
          0.305,
          36000,
          34.9,
          "0.303",
          "0.301",
          "0.302",
          "0.337",
          "0.296",
          1.078,
          1.71,
          44.1,
          0,
          59.37,
          69.67,
          80.92,
        ],
      },
      {
        1819: [
          "6668",
          3.45,
          477000,
          34.9,
          "3.481",
          "3.498",
          "4.08",
          "4.227",
          "4.007",
          165,
          35.1,
          0,
          0,
          79.72,
          84.23,
          91.21,
        ],
      },
      {
        1820: [
          "0129",
          0.88,
          10270,
          34.8,
          "0.872",
          "0.887",
          "0.995",
          "1.05",
          "0.981",
          0.889,
          11.6,
          16.29,
          0,
          38.08,
          55.78,
          74.08,
        ],
      },
      {
        1821: [
          "1336",
          22.75,
          5147260,
          34.8,
          "21.77",
          "21.492",
          "22.947",
          "24.588",
          "22.73",
          11751,
          235.2,
          0.08,
          28.66,
          56.22,
          70.95,
          85.72,
        ],
      },
      {
        1822: [
          "0513",
          0.435,
          0,
          34.8,
          "0.438",
          "0.416",
          "0.471",
          "0.524",
          "-",
          0,
          2.972,
          81.9,
          0,
          86.88,
          90.02,
          93.95,
        ],
      },
      {
        1823: [
          "7322",
          5.83,
          7700,
          34.7,
          "5.828",
          "5.904",
          "6.188",
          "6.341",
          "6.157",
          4.49,
          0,
          0,
          0,
          38.86,
          51.31,
          68.81,
        ],
      },
      {
        1824: [
          "8029",
          0.405,
          5000,
          34.7,
          "0.436",
          "0.582",
          "0.587",
          "0.72",
          "0.571",
          0.2,
          9.251,
          34.46,
          0,
          80.54,
          85.47,
          92.47,
        ],
      },
      {
        1825: [
          "0474",
          0.195,
          0,
          34.7,
          "0.195",
          "0.195",
          "0.206",
          "0.209",
          "0.205",
          0,
          14.4,
          0.05,
          0,
          66.82,
          75.24,
          86.75,
        ],
      },
      {
        1826: [
          "6820",
          1.45,
          760000,
          34.6,
          "1.41",
          "1.3",
          "1.511",
          "1.794",
          "1.471",
          111,
          31.6,
          55.86,
          0,
          84.26,
          92.08,
          96.45,
        ],
      },
      {
        1827: [
          "1277",
          0.485,
          2370000,
          34.6,
          "0.506",
          "0.563",
          "0.624",
          "0.601",
          "0.627",
          115,
          40.8,
          0,
          0,
          84.57,
          91.98,
          96.21,
        ],
      },
      {
        1828: [
          "0416",
          1.65,
          0,
          34.6,
          "1.65",
          "1.773",
          "1.806",
          "1.859",
          "1.796",
          0,
          58,
          57.66,
          0,
          76.96,
          84.31,
          95.78,
        ],
      },
      {
        1829: [
          "1836",
          8.82,
          105500,
          34.5,
          "8.863",
          "9.113",
          "9.735",
          "9.768",
          "9.757",
          92.6,
          70,
          32.92,
          0.05,
          67.16,
          82.47,
          97.07,
        ],
      },
      {
        1830: [
          "0821",
          0.32,
          648000,
          34.5,
          "0.344",
          "0.373",
          "0.376",
          "0.352",
          "0.376",
          20.886,
          6.652,
          1.32,
          0,
          44.99,
          60.15,
          77.94,
        ],
      },
      {
        1831: [
          "0931",
          0.375,
          1600500,
          34.4,
          "0.383",
          "0.383",
          "0.396",
          "0.409",
          "0.393",
          59.675,
          21.1,
          7.9,
          1.91,
          62.6,
          73.71,
          85.98,
        ],
      },
      {
        1832: [
          "2828",
          87.32,
          22379300,
          34.4,
          "84.038",
          "86.572",
          "92.971",
          "97.174",
          "92.189",
          195000,
          0,
          0.15,
          0,
          53.34,
          63.29,
          78.51,
        ],
      },
      {
        1833: [
          "0681",
          0.048,
          96000,
          34.4,
          "0.049",
          "0.051",
          "0.053",
          "0.054",
          "0.053",
          0.47,
          4.288,
          34.87,
          0,
          55.31,
          65.69,
          78.24,
        ],
      },
      {
        1834: [
          "0784",
          0.145,
          0,
          34.3,
          "0.138",
          "0.149",
          "0.149",
          "0.156",
          "0.149",
          0,
          1.16,
          66.26,
          0,
          78.07,
          82.77,
          88.6,
        ],
      },
      {
        1835: [
          "0700",
          475.2,
          18308700,
          34.3,
          "454.04",
          "464.73",
          "490.14",
          "520.928",
          "484.693",
          873000,
          45661.2,
          28.86,
          6.39,
          65.55,
          75.54,
          91.9,
        ],
      },
      {
        1836: [
          "1396",
          0.42,
          700000,
          34.3,
          "0.427",
          "0.45",
          "0.471",
          "0.482",
          "0.47",
          29.07,
          19,
          24.56,
          0,
          70.26,
          82.51,
          92.78,
        ],
      },
      {
        1837: [
          "0130",
          0.27,
          126000,
          34.2,
          "0.279",
          "0.292",
          "0.324",
          "0.322",
          "0.322",
          3.402,
          0.777,
          57.44,
          0,
          75.76,
          85.56,
          90.82,
        ],
      },
      {
        1838: [
          "3366",
          1.34,
          32000,
          34.2,
          "1.301",
          "1.304",
          "1.425",
          "1.479",
          "1.412",
          4.314,
          10,
          70.98,
          0,
          81.47,
          85.18,
          91.78,
        ],
      },
      {
        1839: [
          "7331",
          3.508,
          6100,
          34.2,
          "3.482",
          "3.468",
          "3.68",
          "3.816",
          "3.658",
          2.141,
          0,
          0,
          0,
          40.27,
          50.38,
          64.1,
        ],
      },
      {
        1840: [
          "8521",
          0.138,
          0,
          34.1,
          "0.14",
          "0.132",
          "0.15",
          "0.154",
          "0.15",
          0,
          0.662,
          75.01,
          0,
          81.78,
          87.38,
          93.02,
        ],
      },
      {
        1841: [
          "0347",
          3.82,
          8854900,
          34.1,
          "3.683",
          "3.666",
          "4.585",
          "4.697",
          "4.569",
          3390,
          53.9,
          0.93,
          1.23,
          54.71,
          65.08,
          74.77,
        ],
      },
      {
        1842: [
          "1421",
          0.073,
          100000,
          34,
          "0.071",
          "0.077",
          "0.084",
          "0.086",
          "0.083",
          0.687,
          1.015,
          8.04,
          0,
          28.18,
          42.52,
          63.8,
        ],
      },
      {
        1843: [
          "2359",
          131.8,
          3167570,
          34,
          "123.88",
          "147.838",
          "161.475",
          "157.678",
          "161.04",
          41892,
          516.7,
          0.01,
          8.44,
          72.97,
          83.8,
          95.65,
        ],
      },
      {
        1844: [
          "1270",
          0.85,
          103055,
          34,
          "0.831",
          "0.863",
          "0.898",
          "0.951",
          "0.89",
          8.717,
          27.5,
          66,
          0,
          81.32,
          87.28,
          91.41,
        ],
      },
      {
        1845: [
          "0697",
          1.57,
          1790000,
          33.9,
          "1.556",
          "1.663",
          "1.695",
          "1.722",
          "1.688",
          280,
          114.4,
          42.91,
          1.83,
          60.68,
          72.85,
          86.75,
        ],
      },
      {
        1846: [
          "9916",
          0.94,
          44000,
          33.9,
          "0.935",
          "0.965",
          "1.018",
          "1.041",
          "1.016",
          4.16,
          3.76,
          0.02,
          0,
          86.33,
          88.71,
          92.42,
        ],
      },
      {
        1847: [
          "1039",
          0.3,
          15000,
          33.9,
          "0.313",
          "0.299",
          "0.317",
          "0.336",
          "0.313",
          0.45,
          5.433,
          7.86,
          0,
          55.97,
          69.92,
          87.76,
        ],
      },
      {
        1848: [
          "2768",
          2.69,
          964339,
          33.8,
          "2.698",
          "2.861",
          "3.035",
          "3.186",
          "2.988",
          260,
          132,
          0.04,
          8.06,
          73.71,
          81.77,
          92.25,
        ],
      },
      {
        1849: [
          "3378",
          0.85,
          1224000,
          33.8,
          "0.851",
          "0.826",
          "0.888",
          "0.949",
          "0.875",
          104,
          8.387,
          1.16,
          0,
          41.78,
          53.56,
          64.87,
        ],
      },
      {
        1850: [
          "0519",
          0.103,
          1100000,
          33.8,
          "0.108",
          "0.115",
          "0.113",
          "0.116",
          "0.112",
          11.296,
          2.58,
          23.73,
          0,
          50.77,
          59.67,
          74.79,
        ],
      },
      {
        1851: [
          "8307",
          0.236,
          200000,
          33.7,
          "0.278",
          "0.237",
          "0.259",
          "0.253",
          "0.253",
          4.72,
          0.936,
          62.72,
          0,
          76.04,
          85.01,
          94.82,
        ],
      },
      {
        1852: [
          "0031",
          0.52,
          928000,
          33.7,
          "0.529",
          "0.532",
          "0.613",
          "0.633",
          "0.605",
          48.268,
          16,
          38.99,
          0,
          54.22,
          60.39,
          70.92,
        ],
      },
      {
        1853: [
          "0079",
          0.14,
          0,
          33.7,
          "0.138",
          "0.153",
          "0.165",
          "0.16",
          "0.164",
          0,
          0.456,
          55.22,
          0,
          65.67,
          70.21,
          78.68,
        ],
      },
      {
        1854: [
          "0378",
          0.018,
          0,
          33.6,
          "0.018",
          "0.018",
          "0.018",
          "0.019",
          "0.018",
          0,
          1.216,
          0.45,
          2.42,
          77.14,
          81.76,
          87.46,
        ],
      },
      {
        1855: [
          "8076",
          0.064,
          0,
          33.6,
          "0.068",
          "0.071",
          "0.078",
          "0.077",
          "0.076",
          0,
          0.843,
          43.43,
          0,
          56.15,
          63.11,
          75.27,
        ],
      },
      {
        1856: [
          "0966",
          11.56,
          9374040,
          33.5,
          "11.22",
          "11.138",
          "11.771",
          "12.478",
          "11.683",
          10853,
          415.4,
          57.19,
          9.81,
          74.12,
          82.21,
          92.72,
        ],
      },
      {
        1857: [
          "1044",
          42.15,
          2249080,
          33.5,
          "41.105",
          "39.798",
          "43.771",
          "45.608",
          "43.087",
          9562,
          489.8,
          23.26,
          1.61,
          59.56,
          78.3,
          94.48,
        ],
      },
      {
        1858: [
          "0884",
          4.91,
          39088300,
          33.5,
          "4.912",
          "4.56",
          "5.037",
          "5.522",
          "4.984",
          19420,
          431.2,
          0.02,
          6.04,
          70.34,
          81.61,
          96.03,
        ],
      },
      {
        1859: [
          "6999",
          5.1,
          17000,
          33.4,
          "4.613",
          "4.388",
          "5.56",
          "5.845",
          "5.509",
          8.447,
          52.3,
          53.5,
          0,
          81.54,
          88.02,
          95.64,
        ],
      },
      {
        1860: [
          "0179",
          16.54,
          113418,
          33.4,
          "16.534",
          "17.154",
          "17.989",
          "18.608",
          "17.719",
          187,
          149.8,
          48.35,
          0.18,
          86.31,
          92.7,
          96.8,
        ],
      },
      {
        1861: [
          "2196",
          33.85,
          2001940,
          33.4,
          "32.995",
          "36.252",
          "47.119",
          "47.535",
          "46.157",
          6787,
          186.8,
          0.11,
          29.39,
          59.04,
          72.11,
          83.45,
        ],
      },
      {
        1862: [
          "0023",
          12.46,
          2799130,
          33.3,
          "11.722",
          "11.67",
          "12.751",
          "13.622",
          "12.603",
          3472,
          364.1,
          10.42,
          0.07,
          68.8,
          83.2,
          92.73,
        ],
      },
      {
        1863: [
          "8069",
          0.285,
          45000,
          33.3,
          "0.288",
          "0.298",
          "0.317",
          "0.346",
          "0.314",
          1.262,
          1.14,
          0.01,
          0,
          76.78,
          84.26,
          90.92,
        ],
      },
      {
        1864: [
          "1194",
          0.425,
          0,
          33.3,
          "0.399",
          "0.372",
          "0.436",
          "0.542",
          "0.425",
          0,
          1.268,
          36.82,
          0,
          57.96,
          71.46,
          84.07,
        ],
      },
      {
        1865: [
          "2176",
          0.375,
          0,
          33.2,
          "0.384",
          "0.402",
          "0.427",
          "0.431",
          "0.426",
          0,
          0.784,
          0,
          0,
          38.75,
          51.23,
          70.53,
        ],
      },
      {
        1866: [
          "8246",
          0.202,
          200000,
          33.2,
          "0.202",
          "0.204",
          "0.214",
          "0.221",
          "0.212",
          4.04,
          7.407,
          42.03,
          0,
          76.72,
          85.83,
          92.39,
        ],
      },
      {
        1867: [
          "6890",
          0.61,
          32000,
          33.2,
          "0.608",
          "0.629",
          "0.659",
          "0.681",
          "0.653",
          1.952,
          3.698,
          0,
          0,
          90.13,
          95.47,
          98.5,
        ],
      },
      {
        1868: [
          "0157",
          0.55,
          0,
          33.1,
          "0.556",
          "0.542",
          "0.609",
          "0.628",
          "0.602",
          0,
          11,
          0.17,
          0,
          92.18,
          97.54,
          98.78,
        ],
      },
      {
        1869: [
          "0016",
          94.4,
          2843250,
          33.1,
          "94.55",
          "96.551",
          "105.209",
          "108.492",
          "104.198",
          26884,
          2735.5,
          50.66,
          0.26,
          75.45,
          86.1,
          94.03,
        ],
      },
      {
        1870: [
          "2078",
          0.248,
          26000,
          33,
          "0.252",
          "0.254",
          "0.263",
          "0.28",
          "0.261",
          0.645,
          2.976,
          75.14,
          0,
          81.53,
          84.55,
          89.21,
        ],
      },
      {
        1871: [
          "2601",
          23.3,
          13186600,
          33,
          "21.86",
          "22.854",
          "23.337",
          "24.694",
          "23.292",
          30558,
          646.6,
          0.1,
          26.6,
          73.58,
          85.32,
          93.83,
        ],
      },
      {
        1872: [
          "2806",
          66.62,
          34850,
          33,
          "67.628",
          "70.398",
          "72.861",
          "75.561",
          "72.237",
          234,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1873: [
          "1553",
          1.34,
          10000,
          32.9,
          "1.356",
          "1.432",
          "1.541",
          "1.574",
          "1.533",
          1.34,
          5.813,
          77.32,
          0,
          89.55,
          97.29,
          98.67,
        ],
      },
      {
        1874: [
          "8005",
          0.39,
          16000,
          32.9,
          "0.389",
          "0.414",
          "0.413",
          "0.45",
          "0.413",
          0.624,
          9.702,
          49.48,
          0,
          66.63,
          75.92,
          87.63,
        ],
      },
      {
        1875: [
          "1216",
          0.89,
          174000,
          32.9,
          "0.874",
          "0.94",
          "1.036",
          "1.045",
          "1.03",
          15.366,
          33.7,
          0.02,
          0,
          63.17,
          79.63,
          96.51,
        ],
      },
      {
        1876: [
          "3117",
          46.58,
          0,
          32.8,
          "46.046",
          "47.808",
          "-",
          "-",
          "-",
          0,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1877: [
          "2031",
          0.235,
          1135000,
          32.8,
          "0.238",
          "0.233",
          "0.265",
          "0.271",
          "0.261",
          26.922,
          1.791,
          57.57,
          0,
          92.63,
          94.55,
          96.83,
        ],
      },
      {
        1878: [
          "2318",
          61.95,
          25546200,
          32.8,
          "59.015",
          "57.197",
          "62.637",
          "68.479",
          "61.608",
          158000,
          4613.7,
          0.26,
          0.95,
          71.6,
          83.56,
          92,
        ],
      },
      {
        1879: [
          "3830",
          0.154,
          0,
          32.7,
          "0.155",
          "0.163",
          "0.172",
          "0.176",
          "0.172",
          0,
          1.54,
          75.11,
          0,
          83.27,
          85.81,
          89.2,
        ],
      },
      {
        1880: [
          "2227",
          0.165,
          16000,
          32.7,
          "0.168",
          "0.178",
          "0.2",
          "0.208",
          "0.204",
          0.262,
          1.511,
          0.23,
          0,
          72.06,
          78.02,
          86.93,
        ],
      },
      {
        1881: [
          "1965",
          3.2,
          33000,
          32.7,
          "3.339",
          "3.234",
          "-",
          "-",
          "-",
          10.496,
          13.1,
          72,
          0,
          82.99,
          90.8,
          98.63,
        ],
      },
      {
        1882: [
          "3157",
          24.82,
          600,
          32.6,
          "24.612",
          "25.711",
          "28.137",
          "28.827",
          "27.947",
          1.492,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1883: [
          "9806",
          8.545,
          28200,
          32.6,
          "8.671",
          "9.028",
          "9.36",
          "9.713",
          "9.282",
          188,
          0,
          54.88,
          0,
          98.46,
          99.23,
          99.9,
        ],
      },
      {
        1884: [
          "1900",
          0.156,
          0,
          32.5,
          "0.16",
          "0.164",
          "0.176",
          "0.181",
          "0.175",
          0,
          2.58,
          1.8,
          0,
          58.49,
          69.86,
          83.12,
        ],
      },
      {
        1885: [
          "2191",
          3.38,
          2821000,
          32.5,
          "3.413",
          "3.472",
          "3.954",
          "-",
          "3.908",
          951,
          27,
          35.02,
          0,
          58.77,
          75.21,
          89.56,
        ],
      },
      {
        1886: [
          "1896",
          10,
          3022400,
          32.5,
          "9.349",
          "9.114",
          "10.025",
          "10.931",
          "9.928",
          3064,
          114.1,
          1.69,
          7.22,
          65.86,
          83.95,
          95,
        ],
      },
      {
        1887: [
          "1837",
          0.5,
          560000,
          32.4,
          "0.493",
          "0.554",
          "0.538",
          "0.556",
          "0.539",
          27.896,
          10.9,
          10.58,
          0,
          79.38,
          84.24,
          92.46,
        ],
      },
      {
        1888: [
          "1518",
          1.08,
          0,
          32.4,
          "1.092",
          "1.048",
          "1.122",
          "1.188",
          "1.114",
          0,
          5.292,
          18.23,
          0,
          70.36,
          81.45,
          95.28,
        ],
      },
      {
        1889: [
          "0542",
          0.112,
          0,
          32.4,
          "0.104",
          "0.108",
          "0.119",
          "0.121",
          "0.117",
          0,
          7.78,
          0.04,
          0,
          82.85,
          90.38,
          96.14,
        ],
      },
      {
        1890: [
          "2342",
          1.84,
          2049970,
          32.3,
          "1.825",
          "1.861",
          "1.928",
          "1.941",
          "1.921",
          376,
          51.1,
          0.1,
          1.72,
          27.7,
          37.66,
          48.45,
        ],
      },
      {
        1891: [
          "1115",
          0.58,
          599000,
          32.3,
          "0.571",
          "0.58",
          "0.665",
          "0.706",
          "0.652",
          35.339,
          15,
          5.29,
          6.17,
          39.3,
          49.52,
          68.69,
        ],
      },
      {
        1892: [
          "8009",
          0.54,
          0,
          32.3,
          "0.54",
          "0.68",
          "0.791",
          "0.79",
          "0.786",
          0,
          0.318,
          21.07,
          0,
          49.84,
          63.25,
          78.5,
        ],
      },
      {
        1893: [
          "8100",
          0.355,
          0,
          32.2,
          "0.355",
          "0.381",
          "0.394",
          "0.396",
          "0.396",
          0,
          1.578,
          5.52,
          0,
          85.17,
          88.21,
          92.43,
        ],
      },
      {
        1894: [
          "9998",
          0.188,
          165000,
          32.2,
          "0.187",
          "0.195",
          "0.209",
          "0.205",
          "0.211",
          3.069,
          1.504,
          75,
          0,
          86.69,
          91.2,
          96.91,
        ],
      },
      {
        1895: [
          "8328",
          3.89,
          276166,
          32.2,
          "3.935",
          "4.1",
          "5.048",
          "5.022",
          "5.038",
          107,
          27.7,
          45.62,
          0.01,
          73.48,
          80.01,
          86.31,
        ],
      },
      {
        1896: [
          "0871",
          0.13,
          225000,
          32.1,
          "0.127",
          "0.131",
          "0.135",
          "0.142",
          "-",
          2.895,
          1.955,
          52.64,
          0,
          74.24,
          80.84,
          88.76,
        ],
      },
      {
        1897: [
          "9978",
          0.46,
          0,
          32.1,
          "0.462",
          "0.473",
          "0.529",
          "0.541",
          "0.527",
          0,
          1.84,
          62.83,
          0,
          76.18,
          81.15,
          89.14,
        ],
      },
      {
        1898: [
          "0248",
          0.086,
          32004,
          32,
          "0.082",
          "0.085",
          "0.1",
          "0.101",
          "0.099",
          0.267,
          1.071,
          61.34,
          0,
          71.22,
          77.11,
          84.05,
        ],
      },
      {
        1899: [
          "0125",
          1.23,
          0,
          32,
          "1.211",
          "1.249",
          "1.373",
          "1.377",
          "1.371",
          0,
          3.232,
          56.21,
          0,
          75.48,
          79.15,
          84.51,
        ],
      },
      {
        1900: [
          "0975",
          1.74,
          516000,
          32,
          "1.754",
          "1.82",
          "2.29",
          "2.294",
          "2.287",
          91.362,
          17.9,
          7.47,
          0,
          50.33,
          64.94,
          83.41,
        ],
      },
      {
        1901: [
          "0110",
          0.415,
          0,
          31.9,
          "0.395",
          "0.422",
          "0.473",
          "0.5",
          "-",
          0,
          0.762,
          58.54,
          0,
          67.59,
          75.34,
          83.54,
        ],
      },
      {
        1902: [
          "0055",
          0.405,
          100000,
          31.9,
          "0.413",
          "0.436",
          "0.459",
          "0.481",
          "0.454",
          4.035,
          1.027,
          16.19,
          0,
          40.6,
          52.33,
          68.71,
        ],
      },
      {
        1903: [
          "2239",
          2.93,
          28000,
          31.9,
          "3.132",
          "3.507",
          "3.953",
          "3.779",
          "3.949",
          8.287,
          9.386,
          1.33,
          0,
          73.72,
          80.98,
          91.24,
        ],
      },
      {
        1904: [
          "2222",
          0.159,
          20000,
          31.8,
          "0.152",
          "0.159",
          "0.183",
          "0.185",
          "0.181",
          0.318,
          6.721,
          1.95,
          0,
          72.48,
          78.27,
          88.15,
        ],
      },
      {
        1905: [
          "0895",
          3.75,
          258400,
          31.8,
          "3.716",
          "3.587",
          "3.891",
          "4.15",
          "3.859",
          97.159,
          7.505,
          0.02,
          34.74,
          48.49,
          59.1,
          75.46,
        ],
      },
      {
        1906: [
          "2020",
          115.8,
          8852160,
          31.8,
          "112.22",
          "122.612",
          "146.965",
          "145.681",
          "144.917",
          103000,
          3130.4,
          52.81,
          9.16,
          75.16,
          85.32,
          96.28,
        ],
      },
      {
        1907: [
          "1172",
          0.07,
          0,
          31.7,
          "0.069",
          "0.068",
          "0.072",
          "0.077",
          "0.071",
          0,
          4.045,
          0.06,
          0,
          73.21,
          82.96,
          90.81,
        ],
      },
      {
        1908: [
          "0173",
          3.05,
          609497,
          31.7,
          "3.032",
          "3.057",
          "3.331",
          "3.47",
          "3.296",
          185,
          95.4,
          6.06,
          1,
          40.06,
          43.97,
          49.3,
        ],
      },
      {
        1909: [
          "0862",
          0.2,
          69000,
          31.7,
          "0.194",
          "0.207",
          "0.203",
          "0.212",
          "0.204",
          1.475,
          7.848,
          0.59,
          0,
          69.04,
          76.95,
          86.25,
        ],
      },
      {
        1910: [
          "1382",
          3.86,
          1189000,
          31.6,
          "3.742",
          "3.86",
          "4.153",
          "4.377",
          "4.077",
          457,
          54.4,
          0.37,
          3.22,
          73.74,
          79.07,
          87.93,
        ],
      },
      {
        1911: [
          "2231",
          2.54,
          277000,
          31.6,
          "2.466",
          "2.573",
          "2.868",
          "2.939",
          "2.831",
          71.814,
          41.8,
          0.01,
          0,
          90.52,
          95.94,
          98.75,
        ],
      },
      {
        1912: [
          "0214",
          0.89,
          2000,
          31.5,
          "0.89",
          "0.9",
          "1.046",
          "1.11",
          "1.023",
          0.178,
          7.484,
          21.77,
          0,
          60.07,
          77.22,
          88.08,
        ],
      },
      {
        1913: [
          "0598",
          2.59,
          6726000,
          31.5,
          "2.552",
          "2.478",
          "2.899",
          "3.042",
          "2.865",
          1748,
          55.5,
          1.74,
          7.65,
          63.45,
          76.61,
          87.64,
        ],
      },
      {
        1914: [
          "2772",
          3.79,
          4526500,
          31.5,
          "3.898",
          "3.682",
          "4.154",
          "4.413",
          "4.106",
          1743,
          135.7,
          0.01,
          1.22,
          62.09,
          83.23,
          95.83,
        ],
      },
      {
        1915: [
          "8238",
          0.098,
          64000,
          31.4,
          "0.093",
          "0.114",
          "0.115",
          "0.115",
          "0.115",
          0.526,
          0.423,
          10.09,
          0,
          78.28,
          83.54,
          89.62,
        ],
      },
      {
        1916: [
          "3808",
          12.74,
          4221830,
          31.4,
          "12.502",
          "11.776",
          "13.354",
          "14.985",
          "13.085",
          5412,
          351.7,
          76.22,
          3.93,
          90.44,
          94.92,
          97.57,
        ],
      },
      {
        1917: [
          "3658",
          2.32,
          7000,
          31.4,
          "2.437",
          "2.152",
          "2.308",
          "-",
          "2.26",
          1.612,
          18.8,
          67.81,
          0,
          84.77,
          89.79,
          97.54,
        ],
      },
      {
        1918: [
          "0589",
          1.03,
          4000,
          31.3,
          "1.015",
          "1.04",
          "1.109",
          "1.179",
          "1.104",
          0.412,
          6.438,
          0,
          0,
          86.25,
          95.4,
          99.18,
        ],
      },
      {
        1919: [
          "3040",
          35.14,
          16500,
          31.3,
          "34.152",
          "35.764",
          "38.392",
          "39.83",
          "38.079",
          58.32,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1920: [
          "1207",
          0.037,
          3214000,
          31.3,
          "0.036",
          "0.038",
          "0.041",
          "0.042",
          "0.041",
          11.901,
          7.609,
          0.03,
          0,
          86.32,
          89.42,
          92.48,
        ],
      },
      {
        1921: [
          "2163",
          11.96,
          714000,
          31.2,
          "11.802",
          "10.639",
          "12.053",
          "13.8",
          "11.983",
          852,
          37,
          0,
          0,
          79.93,
          85.8,
          94.6,
        ],
      },
      {
        1922: [
          "0515",
          0.047,
          6000,
          31.2,
          "0.049",
          "0.048",
          "0.052",
          "0.057",
          "0.051",
          0,
          1.311,
          26.06,
          0,
          47.52,
          61.98,
          77.09,
        ],
      },
      {
        1923: [
          "1303",
          0.165,
          0,
          31.1,
          "0.164",
          "0.161",
          "0.18",
          "0.198",
          "0.176",
          0,
          2.673,
          16.18,
          0,
          52.6,
          72.88,
          88.18,
        ],
      },
      {
        1924: [
          "6158",
          3.8,
          1795400,
          31.1,
          "3.837",
          "3.937",
          "4.199",
          "4.448",
          "4.161",
          695,
          165.9,
          3.67,
          1.75,
          50.05,
          61.86,
          78.11,
        ],
      },
      {
        1925: [
          "1387",
          1.8,
          1161000,
          31.1,
          "1.928",
          "1.955",
          "1.994",
          "2.044",
          "1.985",
          213,
          160.1,
          2.18,
          1.44,
          60.8,
          73,
          87.38,
        ],
      },
      {
        1926: [
          "0527",
          0.178,
          56000,
          31,
          "0.182",
          "0.185",
          "0.203",
          "0.211",
          "0.203",
          1.024,
          3.523,
          15.78,
          0,
          44.02,
          61.36,
          78.8,
        ],
      },
      {
        1927: [
          "6626",
          3.62,
          1287070,
          31,
          "3.525",
          "3.416",
          "-",
          "-",
          "-",
          471,
          55,
          69.31,
          0,
          77.87,
          83.1,
          89.47,
        ],
      },
      {
        1928: [
          "0333",
          0.52,
          0,
          31,
          "0.501",
          "0.509",
          "0.566",
          "0.579",
          "0.559",
          0,
          1.565,
          44.69,
          0,
          62.41,
          68.3,
          74.24,
        ],
      },
      {
        1929: [
          "1988",
          3.13,
          29222900,
          30.9,
          "3.05",
          "3.062",
          "3.264",
          "3.52",
          "3.222",
          9150,
          260.4,
          0.43,
          23.01,
          49.12,
          61.04,
          81.3,
        ],
      },
      {
        1930: [
          "0564",
          8.35,
          934200,
          30.9,
          "8.25",
          "8.172",
          "8.739",
          "8.95",
          "8.708",
          782,
          20.3,
          9.24,
          25.48,
          36.75,
          53.16,
          71.4,
        ],
      },
      {
        1931: [
          "1845",
          0.325,
          288000,
          30.9,
          "0.345",
          "0.395",
          "0.418",
          "0.406",
          "0.415",
          9.36,
          4.333,
          69.53,
          0,
          84.49,
          91.96,
          98.53,
        ],
      },
      {
        1932: [
          "6893",
          0.335,
          0,
          30.8,
          "0.341",
          "0.346",
          "0.354",
          "0.385",
          "0.348",
          0,
          3.658,
          2.8,
          0,
          75.17,
          85.06,
          93.78,
        ],
      },
      {
        1933: [
          "8117",
          0.144,
          0,
          30.8,
          "0.146",
          "0.143",
          "0.153",
          "0.162",
          "0.153",
          0,
          1.474,
          21.44,
          0,
          69.9,
          80.48,
          91,
        ],
      },
      {
        1934: [
          "0017",
          30.85,
          3310420,
          30.8,
          "30.405",
          "31.693",
          "34.864",
          "36.455",
          "34.38",
          10236,
          776.3,
          35.4,
          0.17,
          64.27,
          76.58,
          90.49,
        ],
      },
      {
        1935: [
          "1416",
          0.249,
          0,
          30.7,
          "0.249",
          "0.249",
          "0.249",
          "0.275",
          "0.249",
          0,
          3.486,
          75,
          0,
          79.02,
          82.1,
          88.45,
        ],
      },
      {
        1936: [
          "2153",
          1.15,
          372000,
          30.7,
          "1.168",
          "1.178",
          "1.329",
          "1.372",
          "1.31",
          43.008,
          13.4,
          65.9,
          0,
          86.82,
          91.12,
          95.71,
        ],
      },
      {
        1937: [
          "0860",
          0.44,
          3620000,
          30.6,
          "0.43",
          "0.491",
          "0.479",
          "0.477",
          "0.481",
          159,
          35.1,
          20.13,
          0.22,
          55.55,
          72.17,
          83.48,
        ],
      },
      {
        1938: [
          "0010",
          16.44,
          251843,
          30.6,
          "16.438",
          "17.202",
          "18.523",
          "18.819",
          "18.347",
          414,
          223.8,
          38.5,
          2.46,
          79.5,
          87.69,
          94.63,
        ],
      },
      {
        1939: [
          "1655",
          0.55,
          0,
          30.6,
          "0.548",
          "0.615",
          "0.563",
          "0.582",
          "0.555",
          0,
          2.75,
          76.02,
          0,
          88.27,
          94.86,
          97.72,
        ],
      },
      {
        1940: [
          "0724",
          0.037,
          20000,
          30.5,
          "0.037",
          "0.04",
          "0.044",
          "0.046",
          "0.044",
          0,
          0.311,
          22.39,
          0,
          41.75,
          55.04,
          67.4,
        ],
      },
      {
        1941: [
          "8020",
          0.05,
          0,
          30.5,
          "0.05",
          "0.05",
          "0.074",
          "0.072",
          "0.074",
          0,
          1.306,
          40.07,
          0,
          73.02,
          83.94,
          92.56,
        ],
      },
      {
        1942: [
          "2801",
          27.24,
          147826,
          30.5,
          "26.54",
          "27.613",
          "29.821",
          "30.999",
          "29.568",
          403,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1943: [
          "0788",
          0.91,
          137593000,
          30.4,
          "0.893",
          "0.941",
          "1.01",
          "1.035",
          "1.005",
          12583,
          424.6,
          0.14,
          3.55,
          45.38,
          59.2,
          72.48,
        ],
      },
      {
        1944: [
          "0245",
          0.071,
          234000,
          30.4,
          "0.07",
          "0.073",
          "0.082",
          "0.087",
          "0.082",
          1.675,
          24.6,
          14.02,
          0.07,
          68.34,
          80.16,
          92.05,
        ],
      },
      {
        1945: [
          "1302",
          3.56,
          7200000,
          30.4,
          "3.593",
          "3.701",
          "4.133",
          "4.165",
          "4.066",
          2609,
          164.8,
          2.25,
          21.13,
          45.01,
          57.3,
          78.52,
        ],
      },
      {
        1946: [
          "3666",
          0.059,
          0,
          30.3,
          "0.067",
          "0.062",
          "0.064",
          "0.068",
          "0.064",
          0,
          1.306,
          11.2,
          0,
          56.94,
          68.52,
          88.75,
        ],
      },
      {
        1947: [
          "0665",
          1.82,
          10626000,
          30.3,
          "1.744",
          "1.687",
          "1.973",
          "2.074",
          "1.955",
          1963,
          109.8,
          0.18,
          4.23,
          80.64,
          85.98,
          92.28,
        ],
      },
      {
        1948: [
          "0518",
          0.325,
          14000,
          30.3,
          "0.333",
          "0.348",
          "0.369",
          "0.374",
          "0.367",
          0.444,
          1.466,
          40.47,
          0,
          52.71,
          62.52,
          75.98,
        ],
      },
      {
        1949: [
          "2863",
          0.34,
          0,
          30.2,
          "0.363",
          "0.379",
          "0.414",
          "0.411",
          "0.412",
          0,
          2.231,
          67.66,
          0,
          84.51,
          91.01,
          95.6,
        ],
      },
      {
        1950: [
          "6939",
          1.23,
          802000,
          30.2,
          "1.25",
          "1.272",
          "1.432",
          "-",
          "1.426",
          99.074,
          6.381,
          68.72,
          0,
          79.73,
          83.79,
          89.37,
        ],
      },
      {
        1951: [
          "1847",
          1.36,
          56,
          30.1,
          "1.357",
          "1.322",
          "1.485",
          "1.567",
          "1.475",
          0,
          1.821,
          0.03,
          0,
          75.83,
          88.16,
          94.6,
        ],
      },
      {
        1952: [
          "6058",
          0.181,
          106000,
          30.1,
          "0.181",
          "0.19",
          "0.196",
          "0.202",
          "0.196",
          1.918,
          7.24,
          14.19,
          0,
          87.57,
          93.06,
          97.13,
        ],
      },
      {
        1953: [
          "3303",
          0.86,
          858000,
          30.1,
          "0.936",
          "0.922",
          "1.042",
          "1.089",
          "1.014",
          72.394,
          14.4,
          41.22,
          0,
          73.87,
          81.4,
          88.11,
        ],
      },
      {
        1954: [
          "3601",
          1.76,
          48000,
          30,
          "1.709",
          "1.814",
          "1.951",
          "2.18",
          "1.89",
          8.211,
          4.734,
          0,
          0,
          69.52,
          78.06,
          86.62,
        ],
      },
      {
        1955: [
          "3708",
          0.129,
          0,
          30,
          "0.129",
          "0.129",
          "0.134",
          "0.143",
          "0.132",
          0,
          7.216,
          6.66,
          0,
          86.61,
          91.26,
          95.03,
        ],
      },
      {
        1956: [
          "1031",
          0.465,
          0,
          30,
          "0.427",
          "0.469",
          "0.491",
          "0.513",
          "0.486",
          0,
          63.3,
          81.96,
          0,
          94.07,
          96.33,
          98.56,
        ],
      },
      {
        1957: [
          "1448",
          6.13,
          2111400,
          29.9,
          "6.069",
          "6.209",
          "6.814",
          "7.172",
          "6.761",
          1295,
          142.2,
          0.06,
          11.11,
          40.21,
          61.73,
          89.22,
        ],
      },
      {
        1958: [
          "2003",
          4,
          1000,
          29.9,
          "4.132",
          "4.001",
          "4.723",
          "4.754",
          "4.71",
          0.393,
          19.6,
          8.44,
          0.37,
          70.43,
          85.01,
          94.52,
        ],
      },
      {
        1959: [
          "0927",
          0.8,
          198000,
          29.9,
          "0.808",
          "0.856",
          "0.909",
          "0.92",
          "0.905",
          15.84,
          3.407,
          39.1,
          0,
          64.76,
          70.59,
          80.28,
        ],
      },
      {
        1960: [
          "0405",
          3.25,
          2786700,
          29.8,
          "3.263",
          "3.299",
          "3.685",
          "3.761",
          "3.65",
          908,
          111,
          36.02,
          0,
          57.53,
          65.96,
          78.07,
        ],
      },
      {
        1961: [
          "2824",
          61.58,
          0,
          29.8,
          "61.58",
          "61.836",
          "67.939",
          "71.636",
          "67.283",
          0,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1962: [
          "2142",
          6.52,
          1357000,
          29.8,
          "6.918",
          "7.999",
          "8.139",
          "8.563",
          "8.004",
          894,
          50,
          11.95,
          4.6,
          40.48,
          63.16,
          89.05,
        ],
      },
      {
        1963: [
          "0544",
          0.028,
          620000,
          29.7,
          "0.028",
          "0.027",
          "0.034",
          "0.037",
          "0.034",
          1.646,
          0.812,
          11.17,
          0,
          52.18,
          63.28,
          77.43,
        ],
      },
      {
        1964: [
          "1003",
          1.46,
          3060000,
          29.7,
          "1.458",
          "1.506",
          "1.601",
          "1.728",
          "1.581",
          446,
          53.3,
          6.48,
          0,
          55.11,
          67.95,
          85.79,
        ],
      },
      {
        1965: [
          "0725",
          0.75,
          28000,
          29.6,
          "0.791",
          "0.82",
          "0.925",
          "0.894",
          "0.924",
          2.104,
          1.492,
          0.9,
          0,
          80.47,
          84.61,
          90.16,
        ],
      },
      {
        1966: [
          "2018",
          33.4,
          4322120,
          29.6,
          "32.675",
          "32.795",
          "40.844",
          "41.73",
          "39.532",
          14553,
          403.6,
          0.01,
          5.21,
          66.39,
          75.35,
          86.64,
        ],
      },
      {
        1967: [
          "9047",
          1.736,
          7500,
          29.6,
          "1.643",
          "1.507",
          "1.892",
          "2.014",
          "1.852",
          10.143,
          0,
          58.25,
          0,
          99.71,
          99.97,
          100,
        ],
      },
      {
        1968: [
          "0084",
          0.069,
          56000,
          29.5,
          "0.07",
          "0.073",
          "0.079",
          "0.081",
          "0.077",
          0.358,
          0.722,
          47.57,
          0,
          67.96,
          76.7,
          84.87,
        ],
      },
      {
        1969: [
          "1301",
          1.06,
          354000,
          29.5,
          "1.136",
          "1.19",
          "1.277",
          "1.259",
          "1.267",
          37.676,
          6.778,
          6.29,
          0,
          68.21,
          77.7,
          90.53,
        ],
      },
      {
        1970: [
          "1209",
          37.3,
          2668590,
          29.5,
          "35.825",
          "37.979",
          "41.751",
          "42.664",
          "41.753",
          10034,
          851.3,
          70.01,
          2.63,
          84.49,
          92.33,
          98.57,
        ],
      },
      {
        1971: [
          "0673",
          0.053,
          0,
          29.4,
          "0.053",
          "0.061",
          "0.068",
          "0.068",
          "0.067",
          0,
          2.327,
          41.02,
          0,
          55.03,
          65.12,
          77.72,
        ],
      },
      {
        1972: [
          "1168",
          0.248,
          430000,
          29.4,
          "0.245",
          "0.246",
          "0.271",
          "0.282",
          "0.269",
          10.632,
          15.8,
          42.36,
          0,
          59.45,
          67.76,
          79.84,
        ],
      },
      {
        1973: [
          "1671",
          0.76,
          14000,
          29.4,
          "0.816",
          "0.819",
          "0.924",
          "0.969",
          "0.918",
          1.092,
          1.215,
          0.03,
          0,
          82.11,
          86.24,
          91.19,
        ],
      },
      {
        1974: [
          "2202",
          20.15,
          12055900,
          29.3,
          "19.63",
          "18.843",
          "20.929",
          "22.559",
          "20.746",
          24534,
          383,
          0.01,
          14.47,
          55.11,
          72.88,
          87.42,
        ],
      },
      {
        1975: [
          "3332",
          0.142,
          0,
          29.3,
          "0.145",
          "0.154",
          "0.162",
          "0.17",
          "0.159",
          0,
          0.387,
          4.85,
          0,
          42.27,
          51.96,
          68.82,
        ],
      },
      {
        1976: [
          "1460",
          0.31,
          7800000,
          29.3,
          "0.332",
          "0.409",
          "0.495",
          "0.445",
          "0.505",
          257,
          2.72,
          18.17,
          0,
          46.35,
          67.77,
          82.49,
        ],
      },
      {
        1977: [
          "2623",
          0.117,
          100000,
          29.2,
          "0.125",
          "0.135",
          "0.162",
          "0.176",
          "0.155",
          1.23,
          6.149,
          38.41,
          0,
          59.44,
          74.77,
          92.52,
        ],
      },
      {
        1978: [
          "6882",
          0.4,
          0,
          29.2,
          "0.402",
          "0.423",
          "0.467",
          "0.504",
          "0.447",
          0,
          2.01,
          63.55,
          0,
          79.62,
          85.2,
          89.7,
        ],
      },
      {
        1979: [
          "1613",
          0.188,
          1986240,
          29.1,
          "0.203",
          "0.195",
          "0.269",
          "0.298",
          "0.259",
          38.078,
          2.245,
          5.76,
          0,
          42.87,
          56.78,
          77.94,
        ],
      },
      {
        1980: [
          "2269",
          91.25,
          23450800,
          29.1,
          "85.535",
          "96.497",
          "114.193",
          "112.854",
          "113.072",
          216000,
          3887.1,
          12.82,
          6.64,
          67.38,
          81.51,
          95.24,
        ],
      },
      {
        1981: [
          "0030",
          0.285,
          24028000,
          29.1,
          "0.28",
          "0.266",
          "0.307",
          "0.346",
          "0.302",
          673,
          18.3,
          76.24,
          0,
          82.5,
          84.92,
          89.25,
        ],
      },
      {
        1982: [
          "0747",
          0.115,
          292000,
          29,
          "0.118",
          "0.125",
          "0.137",
          "0.143",
          "0.137",
          3.313,
          0.696,
          0.97,
          0,
          34.98,
          43.4,
          56.17,
        ],
      },
      {
        1983: [
          "3688",
          1.04,
          0,
          29,
          "1.044",
          "1.074",
          "1.19",
          "1.23",
          "1.176",
          0,
          14.6,
          28.83,
          0,
          68.9,
          84.13,
          92.04,
        ],
      },
      {
        1984: [
          "8147",
          0.036,
          0,
          29,
          "0.038",
          "0.033",
          "0.041",
          "0.054",
          "0.037",
          0,
          0.371,
          0.07,
          0,
          59.95,
          68.8,
          81.01,
        ],
      },
      {
        1985: [
          "1528",
          3.94,
          956262,
          28.9,
          "3.926",
          "3.909",
          "4.535",
          "4.541",
          "4.51",
          374,
          29.2,
          0.04,
          22.53,
          43.12,
          61.28,
          85.6,
        ],
      },
      {
        1986: [
          "0656",
          8.78,
          1431550,
          28.9,
          "8.485",
          "8.669",
          "9.71",
          "10.196",
          "9.588",
          1260,
          730.3,
          39.63,
          11.84,
          65.75,
          77.89,
          91.42,
        ],
      },
      {
        1987: [
          "2203",
          0.183,
          0,
          28.9,
          "0.18",
          "0.184",
          "0.199",
          "0.208",
          "0.197",
          0,
          1.464,
          0,
          0,
          81.22,
          86.42,
          93.37,
        ],
      },
      {
        1988: [
          "0419",
          0.188,
          6920000,
          28.8,
          "0.201",
          "0.204",
          "0.235",
          "0.281",
          "0.227",
          130,
          25.3,
          14.27,
          0.83,
          53.81,
          62.43,
          77.43,
        ],
      },
      {
        1989: [
          "3681",
          2.77,
          33300,
          28.8,
          "2.837",
          "2.877",
          "3.084",
          "3.224",
          "3.067",
          9.192,
          27.8,
          35.93,
          0,
          73.54,
          82.2,
          92.08,
        ],
      },
      {
        1990: [
          "6966",
          0.39,
          0,
          28.8,
          "0.397",
          "0.439",
          "0.439",
          "0.454",
          "0.435",
          0,
          3.9,
          0.03,
          0,
          91.81,
          97,
          99.24,
        ],
      },
      {
        1991: [
          "3933",
          4.59,
          3812000,
          28.7,
          "4.535",
          "4.761",
          "5.34",
          "5.645",
          "5.255",
          1755,
          83.9,
          49.14,
          7.13,
          68.33,
          80.28,
          92.71,
        ],
      },
      {
        1992: [
          "0444",
          0.052,
          0,
          28.7,
          "0.048",
          "0.05",
          "0.054",
          "0.058",
          "0.053",
          0,
          3.143,
          12.54,
          0,
          65.85,
          76.04,
          85.58,
        ],
      },
      {
        1993: [
          "8262",
          0.229,
          160000,
          28.6,
          "0.222",
          "0.258",
          "0.285",
          "0.295",
          "0.281",
          3.351,
          1.832,
          34.36,
          0,
          68.2,
          87.21,
          94.43,
        ],
      },
      {
        1994: [
          "6839",
          0.91,
          116000,
          28.6,
          "0.912",
          "0.94",
          "1.06",
          "1.079",
          "1.045",
          10.612,
          3.31,
          0.31,
          0,
          37.28,
          50.94,
          64.46,
        ],
      },
      {
        1995: [
          "1527",
          0.88,
          0,
          28.6,
          "0.882",
          "0.941",
          "1.013",
          "1.033",
          "1.009",
          0,
          0.308,
          30.6,
          0,
          54.9,
          67.16,
          83.82,
        ],
      },
      {
        1996: [
          "0101",
          16.02,
          1754850,
          28.5,
          "15.954",
          "16.226",
          "18.011",
          "18.591",
          "17.852",
          2810,
          720.7,
          36.83,
          1.64,
          67.5,
          73.08,
          75.07,
        ],
      },
      {
        1997: [
          "2528",
          0.96,
          2000,
          28.5,
          "0.941",
          "1.062",
          "1.127",
          "1.084",
          "1.143",
          0.192,
          3.84,
          76.25,
          0,
          89.63,
          94.85,
          97.58,
        ],
      },
      {
        1998: [
          "3047",
          13.37,
          144300,
          28.5,
          "12.987",
          "11.787",
          "14.744",
          "15.71",
          "14.356",
          195,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        1999: [
          "1557",
          0.47,
          124000,
          28.4,
          "0.542",
          "0.484",
          "0.613",
          "0.622",
          "0.604",
          5.888,
          1.88,
          0.04,
          0,
          91.58,
          93.81,
          96.27,
        ],
      },
      {
        2000: [
          "1480",
          1.31,
          26000,
          28.4,
          "1.326",
          "1.411",
          "1.499",
          "1.588",
          "1.484",
          3.346,
          3.144,
          75.02,
          0,
          81.02,
          84.42,
          89.57,
        ],
      },
      {
        2001: [
          "6899",
          0.4,
          68000,
          28.4,
          "0.416",
          "0.47",
          "0.532",
          "0.566",
          "0.484",
          2.689,
          4.311,
          24.05,
          0,
          55.61,
          73.54,
          93.05,
        ],
      },
      {
        2002: [
          "1882",
          22,
          804511,
          28.3,
          "21.795",
          "20.963",
          "24.778",
          "26.155",
          "24.677",
          1777,
          351.1,
          39.86,
          0.34,
          82.11,
          94.41,
          98.96,
        ],
      },
      {
        2003: [
          "0575",
          0.147,
          80000,
          28.3,
          "0.147",
          "0.146",
          "0.161",
          "0.168",
          "0.161",
          1.144,
          3.527,
          20.84,
          0,
          50.57,
          64.28,
          73.15,
        ],
      },
      {
        2004: [
          "0288",
          4.93,
          24445100,
          28.2,
          "5.03",
          "5.07",
          "5.779",
          "6.035",
          "5.67",
          12145,
          632.5,
          36.93,
          4.03,
          77.73,
          86.05,
          95.8,
        ],
      },
      {
        2005: [
          "7200",
          7.435,
          28891300,
          28.2,
          "6.94",
          "7.234",
          "8.22",
          "8.723",
          "8.105",
          21509,
          0,
          0,
          0,
          34.81,
          48,
          68.56,
        ],
      },
      {
        2006: [
          "1313",
          6.44,
          14683000,
          28.2,
          "6.278",
          "6.06",
          "6.989",
          "7.365",
          "6.939",
          9544,
          449.7,
          61.44,
          1.3,
          77.32,
          85.92,
          93.54,
        ],
      },
      {
        2007: [
          "8317",
          0.3,
          0,
          28.1,
          "0.29",
          "0.311",
          "0.322",
          "0.334",
          "0.322",
          0,
          2,
          27.97,
          0,
          73.3,
          85.36,
          96.24,
        ],
      },
      {
        2008: [
          "8513",
          0.385,
          1464000,
          28.1,
          "0.376",
          "0.331",
          "0.378",
          "0.391",
          "0.378",
          57.738,
          1.834,
          43.96,
          0,
          65.19,
          73.4,
          85.25,
        ],
      },
      {
        2009: [
          "0138",
          0.163,
          5000,
          28.1,
          "0.169",
          "0.185",
          "0.187",
          "0.189",
          "0.187",
          0,
          1.423,
          58.85,
          0,
          69.36,
          77.19,
          85.62,
        ],
      },
      {
        2010: [
          "0085",
          0.68,
          430000,
          28,
          "0.681",
          "0.716",
          "0.797",
          "0.797",
          "0.792",
          29.128,
          13.8,
          59.62,
          0,
          73.94,
          79.54,
          86.18,
        ],
      },
      {
        2011: [
          "7231",
          7.705,
          61600,
          28,
          "7.183",
          "7.49",
          "8.505",
          "9.04",
          "8.389",
          47.554,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        2012: [
          "1999",
          11.84,
          10052800,
          28,
          "11.406",
          "12.164",
          "14.062",
          "14.737",
          "13.664",
          11994,
          467.9,
          56.66,
          23.5,
          80.17,
          89.99,
          96.8,
        ],
      },
      {
        2013: [
          "0814",
          0.96,
          10000,
          27.9,
          "0.964",
          "0.995",
          "1.093",
          "1.13",
          "1.086",
          0.961,
          1.749,
          0.66,
          0,
          46.4,
          56.47,
          68.85,
        ],
      },
      {
        2014: [
          "0014",
          23.7,
          1663290,
          27.9,
          "24.04",
          "24.821",
          "27.229",
          "27.894",
          "26.973",
          3958,
          245.1,
          45.2,
          0.03,
          82.14,
          89.19,
          93.25,
        ],
      },
      {
        2015: [
          "8218",
          0.255,
          0,
          27.9,
          "0.254",
          "0.264",
          "0.306",
          "0.329",
          "0.298",
          0,
          0.402,
          22.27,
          0,
          71.54,
          79.69,
          89.82,
        ],
      },
      {
        2016: [
          "2552",
          3.77,
          237000,
          27.8,
          "3.773",
          "3.875",
          "4.206",
          "4.334",
          "4.208",
          88.588,
          39.7,
          16.72,
          0,
          49.53,
          66.75,
          85.65,
        ],
      },
      {
        2017: [
          "1633",
          0.152,
          12000,
          27.8,
          "0.167",
          "0.182",
          "0.186",
          "0.179",
          "0.186",
          0.182,
          1.041,
          72.3,
          0,
          81.16,
          85.6,
          90.89,
        ],
      },
      {
        2018: [
          "0091",
          0.204,
          900156,
          27.7,
          "0.204",
          "0.207",
          "0.221",
          "0.226",
          "0.222",
          18.261,
          9.086,
          68.42,
          0,
          91.88,
          94.22,
          96.95,
        ],
      },
      {
        2019: [
          "3918",
          6.53,
          572000,
          27.7,
          "6.582",
          "6.836",
          "6.704",
          "7.13",
          "6.643",
          372,
          283.4,
          41.41,
          0,
          73.94,
          82.73,
          90.07,
        ],
      },
      {
        2020: [
          "1802",
          0.94,
          2990000,
          27.7,
          "0.973",
          "1.012",
          "1.001",
          "1.048",
          "0.998",
          281,
          5.584,
          22.51,
          0,
          64.97,
          74.9,
          86.87,
        ],
      },
      {
        2021: [
          "1767",
          0.275,
          160000,
          27.6,
          "0.288",
          "0.293",
          "0.321",
          "0.332",
          "0.316",
          4.4,
          2.75,
          0.05,
          0,
          85.32,
          89.13,
          93.79,
        ],
      },
      {
        2022: [
          "0712",
          0.345,
          532500,
          27.6,
          "0.358",
          "0.401",
          "0.485",
          "0.468",
          "0.483",
          18.181,
          2.685,
          8.54,
          0,
          37.5,
          54.6,
          70.46,
        ],
      },
      {
        2023: [
          "0794",
          0.51,
          0,
          27.6,
          "0.502",
          "0.575",
          "0.619",
          "0.625",
          "0.618",
          0,
          1.754,
          0.14,
          0,
          82.52,
          86.82,
          91.47,
        ],
      },
      {
        2024: [
          "0468",
          2.83,
          643000,
          27.5,
          "2.832",
          "2.813",
          "3.12",
          "3.298",
          "3.084",
          182,
          37.8,
          28.23,
          0.17,
          73.15,
          88.55,
          95.29,
        ],
      },
      {
        2025: [
          "7248",
          5.27,
          353800,
          27.5,
          "5.44",
          "5.59",
          "5.74",
          "6.023",
          "5.676",
          189,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        2026: [
          "1912",
          0.192,
          475000,
          27.5,
          "0.19",
          "0.207",
          "0.243",
          "0.254",
          "0.239",
          8.991,
          1.536,
          59.38,
          0,
          76.22,
          81.59,
          88.15,
        ],
      },
      {
        2027: [
          "3331",
          19.94,
          731182,
          27.4,
          "20.04",
          "20.607",
          "21.847",
          "22.905",
          "21.598",
          1460,
          239.5,
          53.82,
          1.79,
          81.32,
          90.67,
          98.1,
        ],
      },
      {
        2028: [
          "8193",
          0.058,
          10000,
          27.4,
          "0.061",
          "0.065",
          "0.064",
          "0.072",
          "0.065",
          0,
          0.676,
          24.02,
          0,
          42.51,
          56.77,
          76.35,
        ],
      },
      {
        2029: [
          "0296",
          0.82,
          65000,
          27.4,
          "0.821",
          "0.873",
          "0.951",
          "1.009",
          "0.938",
          5.325,
          9.818,
          53.89,
          0,
          81.29,
          86.39,
          92.33,
        ],
      },
      {
        2030: [
          "1101",
          0.098,
          327000,
          27.3,
          "0.099",
          "0.109",
          "0.109",
          "0.111",
          "0.109",
          3.149,
          4.675,
          23.33,
          0,
          55.93,
          70.8,
          82,
        ],
      },
      {
        2031: [
          "1428",
          1.46,
          774000,
          27.3,
          "1.434",
          "1.502",
          "1.659",
          "1.775",
          "1.625",
          112,
          24.7,
          53.14,
          0,
          75.04,
          83.78,
          88.98,
        ],
      },
      {
        2032: [
          "3993",
          4.26,
          38056200,
          27.2,
          "4.007",
          "4.381",
          "5.031",
          "5.084",
          "5.03",
          16105,
          167.5,
          0.88,
          12.61,
          46.3,
          60.53,
          77.72,
        ],
      },
      {
        2033: [
          "2377",
          1.35,
          321000,
          27.2,
          "1.498",
          "1.417",
          "1.584",
          "1.644",
          "1.566",
          42.715,
          13.5,
          10.98,
          0,
          69.63,
          85.84,
          92.93,
        ],
      },
      {
        2034: [
          "1314",
          0.24,
          112000,
          27.2,
          "0.24",
          "0.238",
          "0.275",
          "0.285",
          "0.273",
          2.633,
          3.387,
          54.96,
          0,
          68.87,
          76.9,
          86.01,
        ],
      },
      {
        2035: [
          "0918",
          0.345,
          0,
          27.1,
          "0.337",
          "0.338",
          "0.368",
          "0.38",
          "0.363",
          0,
          2.639,
          51.15,
          0,
          77.65,
          85.23,
          91.74,
        ],
      },
      {
        2036: [
          "6896",
          1.56,
          186000,
          27.1,
          "1.572",
          "2.005",
          "2.178",
          "2.065",
          "2.223",
          29.047,
          11.5,
          52.37,
          0,
          74.56,
          83.71,
          92.2,
        ],
      },
      {
        2037: [
          "0335",
          0.71,
          0,
          27.1,
          "0.749",
          "0.797",
          "0.824",
          "0.828",
          "0.821",
          0,
          19,
          72.34,
          0,
          96.29,
          98.36,
          98.99,
        ],
      },
      {
        2038: [
          "1300",
          0.58,
          50000,
          27,
          "0.574",
          "0.609",
          "0.665",
          "0.687",
          "0.658",
          2.9,
          10.3,
          23.94,
          0,
          58.47,
          79.15,
          86.63,
        ],
      },
      {
        2039: [
          "0943",
          0.022,
          1001000,
          27,
          "0.022",
          "0.023",
          "0.024",
          "0.024",
          "0.024",
          2.298,
          2.359,
          58.2,
          0,
          76.02,
          80.82,
          86.93,
        ],
      },
      {
        2040: [
          "8037",
          1.09,
          3855000,
          27,
          "1.24",
          "1.377",
          "1.431",
          "1.369",
          "1.453",
          426,
          10.4,
          13.43,
          0,
          76.15,
          84.42,
          92.76,
        ],
      },
      {
        2041: [
          "1073",
          0.222,
          195000,
          26.9,
          "0.243",
          "0.249",
          "0.255",
          "0.267",
          "0.253",
          4.494,
          2.529,
          24.15,
          0,
          85.12,
          88.46,
          93.27,
        ],
      },
      {
        2042: [
          "2262",
          0.71,
          0,
          26.9,
          "0.752",
          "0.808",
          "0.801",
          "0.805",
          "0.802",
          0,
          8.104,
          0.36,
          0,
          81.53,
          89.1,
          94.34,
        ],
      },
      {
        2043: [
          "8222",
          0.042,
          80000,
          26.9,
          "0.044",
          "0.054",
          "0.061",
          "0.058",
          "0.06",
          0.332,
          0.189,
          46.56,
          0,
          61.62,
          72.67,
          85.11,
        ],
      },
      {
        2044: [
          "9997",
          8.78,
          2772730,
          26.8,
          "8.224",
          "8.215",
          "10.056",
          "10.483",
          "9.928",
          2435,
          109.3,
          0,
          4.08,
          80.59,
          85.47,
          92.76,
        ],
      },
      {
        2045: [
          "6118",
          8.79,
          4876200,
          26.8,
          "13.107",
          "15.124",
          "18.994",
          "16.232",
          "19.306",
          4354,
          45,
          67.61,
          0,
          75.19,
          80.14,
          87.23,
        ],
      },
      {
        2046: [
          "9888",
          153,
          3063270,
          26.7,
          "146.99",
          "147.87",
          "158.885",
          "168.938",
          "157.97",
          47000,
          4328.1,
          17.29,
          0,
          94.36,
          96.16,
          97.79,
        ],
      },
      {
        2047: [
          "1689",
          1.96,
          580000,
          26.7,
          "2.069",
          "2.044",
          "2.332",
          "2.426",
          "2.303",
          114,
          13.7,
          68.79,
          0,
          80.42,
          86.18,
          95.41,
        ],
      },
      {
        2048: [
          "8053",
          0.5,
          140000,
          26.7,
          "0.501",
          "0.513",
          "0.572",
          "0.61",
          "0.564",
          7.04,
          17.7,
          4.32,
          0,
          83.95,
          90.56,
          96.64,
        ],
      },
      {
        2049: [
          "1685",
          0.39,
          132000,
          26.6,
          "0.409",
          "0.407",
          "0.445",
          "0.461",
          "0.44",
          5.15,
          3.018,
          0.05,
          0.19,
          74.27,
          79.6,
          85.59,
        ],
      },
      {
        2050: [
          "8426",
          0.129,
          650000,
          26.6,
          "0.14",
          "0.14",
          "0.148",
          "0.152",
          "0.146",
          9.201,
          1.032,
          61.47,
          0,
          81.67,
          87.81,
          93.32,
        ],
      },
      {
        2051: [
          "0175",
          20.1,
          47020600,
          26.6,
          "20.167",
          "23.288",
          "24.533",
          "23.447",
          "24.506",
          94407,
          2013.7,
          20.67,
          15.59,
          62.48,
          78.69,
          91.19,
        ],
      },
      {
        2052: [
          "9167",
          10.13,
          600,
          26.5,
          "9.826",
          "10.401",
          "11.244",
          "11.806",
          "11.133",
          4.775,
          0,
          99.17,
          0,
          99.86,
          99.98,
          100,
        ],
      },
      {
        2053: [
          "3167",
          78.46,
          9200,
          26.5,
          "76.496",
          "80.978",
          "87.45",
          "91.753",
          "86.558",
          72.426,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        2054: [
          "6193",
          0.375,
          0,
          26.5,
          "0.375",
          "0.385",
          "0.495",
          "0.517",
          "0.476",
          0,
          1.5,
          0,
          0,
          85.22,
          91.13,
          95.89,
        ],
      },
      {
        2055: [
          "0780",
          14.44,
          5199420,
          26.4,
          "14.19",
          "16.047",
          "17.324",
          "17.807",
          "17.171",
          7587,
          320.2,
          21.2,
          4.5,
          67.31,
          84.02,
          96.58,
        ],
      },
      {
        2056: [
          "0488",
          4.35,
          543822,
          26.4,
          "4.301",
          "3.998",
          "5.005",
          "5.224",
          "4.905",
          234,
          42.1,
          39.53,
          0,
          68.93,
          79.77,
          92.14,
        ],
      },
      {
        2057: [
          "2236",
          0.36,
          607000,
          26.4,
          "0.366",
          "0.387",
          "0.393",
          "0.401",
          "0.394",
          21.713,
          14.6,
          0.22,
          0,
          81.62,
          86.33,
          92.41,
        ],
      },
      {
        2058: [
          "0952",
          0.186,
          0,
          26.3,
          "0.189",
          "0.197",
          "0.219",
          "0.226",
          "0.217",
          0,
          11.5,
          0.04,
          0,
          92.25,
          95.51,
          97.72,
        ],
      },
      {
        2059: [
          "0772",
          51.6,
          1955220,
          26.3,
          "49.525",
          "53.549",
          "64.935",
          "68.742",
          "63.272",
          10162,
          527.3,
          56.99,
          8.21,
          74.29,
          84.8,
          95.31,
        ],
      },
      {
        2060: [
          "8356",
          0.012,
          0,
          26.2,
          "0.013",
          "0.014",
          "0.014",
          "0.015",
          "0.014",
          0,
          0.487,
          30.43,
          0,
          50.9,
          60.47,
          75.52,
        ],
      },
      {
        2061: [
          "1064",
          0.102,
          0,
          26.2,
          "0.101",
          "0.115",
          "0.13",
          "0.133",
          "0.129",
          0,
          0.728,
          62.13,
          0,
          77.69,
          82.69,
          90.69,
        ],
      },
      {
        2062: [
          "1243",
          0.08,
          2381,
          26.2,
          "0.08",
          "0.083",
          "0.093",
          "0.095",
          "0.092",
          0,
          12.1,
          0.01,
          0,
          86.51,
          89.95,
          93.02,
        ],
      },
      {
        2063: [
          "0696",
          13.54,
          1979800,
          26.1,
          "13.46",
          "13.377",
          "14.241",
          "15.035",
          "14.117",
          2691,
          126.2,
          0.11,
          3.81,
          70.74,
          80.56,
          91.47,
        ],
      },
      {
        2064: [
          "1846",
          8.9,
          320000,
          26.1,
          "8.394",
          "9.024",
          "10.736",
          "10.806",
          "10.731",
          282,
          29.3,
          5.67,
          0,
          76.45,
          84.83,
          92.3,
        ],
      },
      {
        2065: [
          "7345",
          6.16,
          536000,
          26.1,
          "6.475",
          "-",
          "-",
          "-",
          "-",
          330,
          0,
          0,
          0,
          99.89,
          99.98,
          100,
        ],
      },
      {
        2066: [
          "1292",
          1.91,
          183000,
          26,
          "1.795",
          "1.814",
          "2.212",
          "2.364",
          "2.16",
          35.036,
          1.05,
          0.35,
          0,
          43.87,
          55.91,
          73.14,
        ],
      },
      {
        2067: [
          "8621",
          0.068,
          24000,
          26,
          "0.07",
          "0.079",
          "0.08",
          "0.082",
          "0.08",
          0.164,
          0.653,
          0.01,
          0,
          71.89,
          76.48,
          83.97,
        ],
      },
      {
        2068: [
          "1525",
          4.62,
          12500,
          26,
          "4.742",
          "4.951",
          "5.272",
          "5.469",
          "5.225",
          5.839,
          19.1,
          0,
          0,
          79.87,
          88.76,
          94.43,
        ],
      },
      {
        2069: [
          "0743",
          5.18,
          1022500,
          25.9,
          "5.102",
          "5.254",
          "5.767",
          "6.258",
          "5.699",
          535,
          81.1,
          71.82,
          2.41,
          85.24,
          90.86,
          95.35,
        ],
      },
      {
        2070: [
          "8480",
          0.25,
          8000,
          25.9,
          "0.245",
          "0.272",
          "0.262",
          "0.283",
          "0.261",
          0.2,
          1.4,
          8.81,
          0,
          65.09,
          81.02,
          91.89,
        ],
      },
      {
        2071: [
          "8101",
          0.241,
          0,
          25.9,
          "0.241",
          "0.241",
          "0.256",
          "0.278",
          "0.254",
          0,
          0.836,
          0,
          0,
          34.15,
          50.43,
          71.27,
        ],
      },
      {
        2072: [
          "8320",
          0.084,
          380000,
          25.8,
          "0.093",
          "0.099",
          "0.104",
          "0.113",
          "-",
          3.354,
          0.589,
          59.82,
          0,
          78.14,
          84.71,
          91.25,
        ],
      },
      {
        2073: [
          "1601",
          0.83,
          22000,
          25.8,
          "0.821",
          "0.83",
          "0.908",
          "0.954",
          "0.907",
          1.84,
          4.095,
          0.01,
          0,
          80.09,
          87.14,
          96.12,
        ],
      },
      {
        2074: [
          "2389",
          0.09,
          1824000,
          25.7,
          "0.09",
          "0.091",
          "0.105",
          "0.106",
          "0.104",
          16.414,
          5.471,
          0.44,
          0,
          45.73,
          54.55,
          70.41,
        ],
      },
      {
        2075: [
          "6858",
          3.34,
          133000,
          25.7,
          "3.285",
          "3.362",
          "3.638",
          "3.891",
          "3.58",
          44.263,
          20.2,
          6.33,
          0,
          76.78,
          88.9,
          96.96,
        ],
      },
      {
        2076: [
          "0550",
          0.36,
          2000,
          25.7,
          "0.361",
          "0.403",
          "0.562",
          "0.615",
          "0.532",
          0,
          1.608,
          11.22,
          0,
          80.02,
          85.15,
          92.26,
        ],
      },
      {
        2077: [
          "1728",
          0.73,
          3706500,
          25.6,
          "0.8",
          "0.909",
          "0.974",
          "0.896",
          "0.979",
          268,
          20,
          0.02,
          5.95,
          59.8,
          67.08,
          78.88,
        ],
      },
      {
        2078: [
          "3336",
          1.38,
          12000,
          25.6,
          "1.359",
          "1.267",
          "1.466",
          "1.607",
          "1.457",
          1.662,
          16.5,
          22.84,
          0,
          68.56,
          88.9,
          95.6,
        ],
      },
      {
        2079: [
          "1936",
          0.53,
          4000,
          25.6,
          "0.53",
          "0.549",
          "0.75",
          "0.73",
          "0.742",
          0.212,
          2.502,
          71.51,
          0,
          82.64,
          86.66,
          92.33,
        ],
      },
      {
        2080: [
          "1521",
          4.15,
          2062000,
          25.5,
          "3.903",
          "4.149",
          "5.361",
          "5.329",
          "5.24",
          858,
          85.1,
          0.66,
          10.52,
          71.54,
          86.86,
          96.39,
        ],
      },
      {
        2081: [
          "8607",
          0.18,
          140000,
          25.5,
          "0.164",
          "0.176",
          "0.2",
          "0.213",
          "0.196",
          2.52,
          1.44,
          0,
          0,
          82.58,
          87.94,
          93.73,
        ],
      },
      {
        2082: [
          "1260",
          0.3,
          0,
          25.5,
          "0.307",
          "0.317",
          "0.361",
          "0.375",
          "0.356",
          0,
          3.454,
          65.22,
          0,
          75.7,
          84.6,
          93.31,
        ],
      },
      {
        2083: [
          "0982",
          0.162,
          200000,
          25.4,
          "0.154",
          "0.163",
          "0.183",
          "0.193",
          "0.181",
          3.236,
          16.2,
          0.01,
          0,
          56.22,
          68.97,
          84.03,
        ],
      },
      {
        2084: [
          "3182",
          14.69,
          0,
          25.4,
          "14.189",
          "15.548",
          "16.81",
          "17.317",
          "16.67",
          0,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        2085: [
          "2329",
          0.335,
          7000,
          25.3,
          "0.328",
          "0.349",
          "0.353",
          "0.363",
          "0.348",
          0.231,
          14.8,
          24.63,
          3.64,
          78.66,
          84.94,
          93.72,
        ],
      },
      {
        2086: [
          "2779",
          1.55,
          367000,
          25.3,
          "1.571",
          "1.628",
          "1.755",
          "1.882",
          "1.722",
          58.404,
          24.9,
          68.22,
          0,
          81.62,
          88.65,
          95.22,
        ],
      },
      {
        2087: [
          "1366",
          0.28,
          0,
          25.3,
          "0.279",
          "0.282",
          "0.332",
          "0.346",
          "0.327",
          0,
          17.1,
          10.07,
          0,
          72.44,
          78.73,
          87.61,
        ],
      },
      {
        2088: [
          "3990",
          12.4,
          544400,
          25.2,
          "12.314",
          "12.711",
          "14.046",
          "15.0",
          "13.771",
          682,
          153,
          4.86,
          5.41,
          55.65,
          70.42,
          94.07,
        ],
      },
      {
        2089: [
          "0806",
          3.84,
          2412000,
          25.2,
          "3.858",
          "3.969",
          "4.262",
          "4.487",
          "4.228",
          933,
          71,
          0.88,
          4.98,
          47.95,
          59.49,
          70.78,
        ],
      },
      {
        2090: [
          "3822",
          0.085,
          28000,
          25.2,
          "0.087",
          "0.093",
          "0.101",
          "0.105",
          "0.1",
          0.232,
          1.428,
          71.46,
          0,
          78.59,
          82.87,
          88.48,
        ],
      },
      {
        2091: [
          "0953",
          0.107,
          2188000,
          25.1,
          "0.112",
          "0.109",
          "0.123",
          "0.128",
          "0.123",
          22.657,
          1.519,
          0.3,
          0,
          41.79,
          53.01,
          67.97,
        ],
      },
      {
        2092: [
          "8431",
          0.028,
          0,
          25.1,
          "0.028",
          "0.028",
          "0.031",
          "0.033",
          "0.03",
          0,
          0.364,
          1.01,
          0,
          81.99,
          85.9,
          91.88,
        ],
      },
      {
        2093: [
          "2098",
          0.44,
          11152000,
          25.1,
          "0.462",
          "0.476",
          "0.512",
          "0.528",
          "0.506",
          505,
          51.8,
          5.28,
          0.27,
          56.7,
          66.97,
          85.2,
        ],
      },
      {
        2094: [
          "8516",
          0.97,
          143500,
          25,
          "0.853",
          "0.83",
          "1.071",
          "1.152",
          "-",
          12.032,
          0.554,
          0,
          0,
          52.08,
          65.15,
          83.09,
        ],
      },
      {
        2095: [
          "1278",
          0.088,
          2500,
          25,
          "0.085",
          "0.084",
          "0.096",
          "0.113",
          "0.093",
          0,
          8.559,
          55.95,
          0,
          75.79,
          80.89,
          87.06,
        ],
      },
      {
        2096: [
          "1153",
          3.33,
          408000,
          25,
          "3.38",
          "3.731",
          "4.012",
          "4.045",
          "4.003",
          135,
          20.3,
          0,
          0,
          83.96,
          89.16,
          95.42,
        ],
      },
      {
        2097: [
          "9966",
          12.8,
          1745000,
          24.9,
          "12.784",
          "15.5",
          "19.135",
          "18.168",
          "18.853",
          2283,
          119.9,
          19.4,
          9.97,
          65.83,
          80.89,
          90.59,
        ],
      },
      {
        2098: [
          "0848",
          0.255,
          263000,
          24.9,
          "0.246",
          "0.253",
          "0.286",
          "0.299",
          "0.283",
          6.561,
          13.1,
          0.02,
          0,
          88.2,
          91.32,
          94.35,
        ],
      },
      {
        2099: [
          "2263",
          0.15,
          100000,
          24.8,
          "0.152",
          "0.164",
          "0.184",
          "0.193",
          "0.183",
          1.501,
          1.5,
          71.25,
          0,
          79.95,
          83.56,
          89.17,
        ],
      },
      {
        2100: [
          "8432",
          0.055,
          0,
          24.8,
          "0.053",
          "0.056",
          "0.063",
          "0.069",
          "0.063",
          0,
          0.473,
          54.2,
          0,
          69.55,
          79.99,
          88.48,
        ],
      },
      {
        2101: [
          "0483",
          0.67,
          0,
          24.8,
          "0.658",
          "0.921",
          "1.056",
          "0.936",
          "1.052",
          0,
          2.461,
          0.38,
          0,
          81.56,
          83.86,
          86.48,
        ],
      },
      {
        2102: [
          "2689",
          8.61,
          12924800,
          24.7,
          "8.518",
          "8.955",
          "9.986",
          "10.392",
          "9.971",
          11041,
          404,
          58.58,
          1.66,
          80.35,
          87.14,
          94.44,
        ],
      },
      {
        2103: [
          "0036",
          0.106,
          0,
          24.7,
          "0.113",
          "0.12",
          "0.133",
          "0.136",
          "0.133",
          0,
          1.154,
          0.35,
          0,
          71.45,
          78.62,
          85.31,
        ],
      },
      {
        2104: [
          "0186",
          0.49,
          0,
          24.7,
          "0.49",
          "0.541",
          "0.624",
          "0.611",
          "0.613",
          0,
          26.9,
          88.91,
          0,
          96.4,
          97.47,
          98.95,
        ],
      },
      {
        2105: [
          "1086",
          1.08,
          4780000,
          24.6,
          "1.02",
          "1.129",
          "1.366",
          "1.463",
          "1.331",
          520,
          18,
          47.74,
          1.09,
          70.71,
          78.25,
          87.37,
        ],
      },
      {
        2106: [
          "0360",
          0.066,
          1848500,
          24.6,
          "0.066",
          "0.081",
          "0.091",
          "0.088",
          "0.091",
          12.197,
          4.467,
          1.49,
          0,
          78.58,
          82.9,
          89.01,
        ],
      },
      {
        2107: [
          "2279",
          13.34,
          2268000,
          24.6,
          "13.994",
          "-",
          "-",
          "-",
          "-",
          3046,
          70.3,
          78.05,
          0,
          86.99,
          90.65,
          95.44,
        ],
      },
      {
        2108: [
          "1989",
          0.86,
          0,
          24.5,
          "0.899",
          "0.888",
          "0.993",
          "1.035",
          "0.979",
          0,
          7.765,
          0.05,
          0,
          67.4,
          83.64,
          96.71,
        ],
      },
      {
        2109: [
          "2820",
          94.86,
          83794,
          24.5,
          "94.65",
          "102.839",
          "114.989",
          "118.167",
          "113.598",
          801,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        2110: [
          "1062",
          0.126,
          0,
          24.5,
          "0.129",
          "0.133",
          "0.14",
          "0.15",
          "0.14",
          0,
          3.657,
          1.11,
          0,
          76.25,
          80.46,
          87.12,
        ],
      },
      {
        2111: [
          "9989",
          8.06,
          507000,
          24.4,
          "8.073",
          "8.113",
          "8.972",
          "9.523",
          "8.846",
          408,
          17.7,
          0.02,
          12.59,
          36.18,
          50.66,
          70.01,
        ],
      },
      {
        2112: [
          "9987",
          376.8,
          132250,
          24.4,
          "380.44",
          "399.788",
          "454.214",
          "461.725",
          "448.87",
          5008,
          1579.1,
          90.56,
          0,
          97.41,
          98.77,
          99.6,
        ],
      },
      {
        2113: [
          "0721",
          0.101,
          1100000,
          24.3,
          "0.101",
          "0.106",
          "0.117",
          "0.119",
          "0.119",
          10.923,
          11,
          30.91,
          2.77,
          52.32,
          69.96,
          84.66,
        ],
      },
      {
        2114: [
          "1446",
          0.395,
          38000,
          24.3,
          "0.393",
          "0.408",
          "0.454",
          "0.475",
          "0.449",
          1.506,
          2.591,
          60.61,
          0,
          73.39,
          82.47,
          90.05,
        ],
      },
      {
        2115: [
          "0951",
          2.16,
          3099000,
          24.3,
          "2.069",
          "2.084",
          "2.389",
          "2.493",
          "2.396",
          675,
          23.8,
          40.89,
          8.81,
          64.07,
          76.21,
          87,
        ],
      },
      {
        2116: [
          "9982",
          1.37,
          1593000,
          24.2,
          "1.47",
          "1.4",
          "1.64",
          "-",
          "1.59",
          222,
          45.1,
          0.02,
          3.08,
          51.25,
          62.94,
          82.56,
        ],
      },
      {
        2117: [
          "0346",
          0.051,
          3820000,
          24.2,
          "0.049",
          "0.06",
          "0.068",
          "0.073",
          "0.068",
          19.403,
          9.351,
          34.78,
          0,
          75.45,
          79.95,
          86.1,
        ],
      },
      {
        2118: [
          "3067",
          12.09,
          11820800,
          24.2,
          "11.707",
          "12.469",
          "13.692",
          "14.514",
          "13.531",
          14364,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        2119: [
          "1098",
          7.24,
          290000,
          24.1,
          "7.251",
          "7.378",
          "8.374",
          "8.893",
          "8.257",
          210,
          54.2,
          44.57,
          0.56,
          75.34,
          84.33,
          90.4,
        ],
      },
      {
        2120: [
          "1230",
          0.51,
          276000,
          24.1,
          "0.518",
          "0.541",
          "0.58",
          "0.615",
          "0.569",
          14.077,
          24.2,
          25.05,
          1.49,
          87.26,
          90.71,
          94.97,
        ],
      },
      {
        2121: [
          "0803",
          0.037,
          0,
          24.1,
          "0.037",
          "0.037",
          "0.037",
          "0.046",
          "0.037",
          0,
          0.497,
          19.08,
          0,
          42.15,
          55.95,
          74.55,
        ],
      },
      {
        2122: [
          "6969",
          35.85,
          9316090,
          24,
          "35.68",
          "40.542",
          "40.077",
          "43.273",
          "39.812",
          33721,
          2154.6,
          0,
          8.71,
          75.57,
          83.43,
          93.49,
        ],
      },
      {
        2123: [
          "2135",
          0.29,
          1900000,
          24,
          "0.292",
          "0.307",
          "0.401",
          "0.458",
          "0.387",
          55.05,
          6.058,
          6.18,
          0,
          69.23,
          75.46,
          85.55,
        ],
      },
      {
        2124: [
          "9820",
          12.17,
          19690,
          24,
          "12.14",
          "13.193",
          "14.778",
          "15.193",
          "14.608",
          189,
          0,
          47.15,
          0,
          93.55,
          99.7,
          99.95,
        ],
      },
      {
        2125: [
          "9067",
          1.55,
          47000,
          23.9,
          "1.5",
          "1.599",
          "1.757",
          "1.864",
          "1.736",
          56.841,
          0,
          95.28,
          0,
          98.62,
          99.17,
          99.8,
        ],
      },
      {
        2126: [
          "8260",
          0.04,
          0,
          23.9,
          "0.04",
          "0.04",
          "0.04",
          "0.045",
          "0.04",
          0,
          0.689,
          6.6,
          0,
          43.24,
          60.64,
          79.63,
        ],
      },
      {
        2127: [
          "0027",
          40.65,
          9564340,
          23.8,
          "40.62",
          "42.439",
          "47.559",
          "52.44",
          "46.373",
          38985,
          1772.1,
          30.07,
          0.88,
          68.44,
          81.11,
          87.07,
        ],
      },
      {
        2128: [
          "0828",
          0.38,
          40000,
          23.8,
          "0.383",
          "0.362",
          "0.426",
          "0.449",
          "0.422",
          1.485,
          4.743,
          71.89,
          0,
          80.47,
          84.38,
          88.85,
        ],
      },
      {
        2129: [
          "3088",
          7.28,
          537000,
          23.8,
          "7.057",
          "7.527",
          "8.261",
          "8.758",
          "8.16",
          394,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        2130: [
          "1235",
          0.19,
          0,
          23.7,
          "0.192",
          "0.215",
          "0.223",
          "0.231",
          "0.221",
          0,
          0.969,
          73.29,
          0,
          85.84,
          90.52,
          94.32,
        ],
      },
      {
        2131: [
          "3033",
          5.715,
          48179400,
          23.7,
          "5.535",
          "5.904",
          "6.489",
          "6.883",
          "6.409",
          27665,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        2132: [
          "8456",
          0.41,
          311600,
          23.7,
          "0.415",
          "0.44",
          "0.473",
          "0.592",
          "0.463",
          13.354,
          0.867,
          1.92,
          0,
          43.14,
          62.57,
          79.72,
        ],
      },
      {
        2133: [
          "1930",
          0.295,
          332000,
          23.6,
          "0.295",
          "0.311",
          "0.329",
          "0.348",
          "0.321",
          9.544,
          1.947,
          53.78,
          0,
          76.49,
          79.75,
          85.61,
        ],
      },
      {
        2134: [
          "8429",
          0.16,
          2660000,
          23.6,
          "0.161",
          "0.16",
          "0.203",
          "0.208",
          "0.198",
          41.395,
          0.768,
          12.57,
          0,
          75.31,
          80.52,
          89.51,
        ],
      },
      {
        2135: [
          "2718",
          0.73,
          1063000,
          23.6,
          "0.803",
          "0.907",
          "0.916",
          "0.898",
          "0.92",
          76.976,
          3.939,
          0.01,
          0,
          35.67,
          50.9,
          67.9,
        ],
      },
      {
        2136: [
          "3032",
          5.74,
          3299400,
          23.5,
          "5.567",
          "5.934",
          "6.516",
          "6.909",
          "6.436",
          1903,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        2137: [
          "0607",
          0.108,
          31533500,
          23.5,
          "0.109",
          "0.108",
          "0.114",
          "0.127",
          "0.112",
          344,
          21.2,
          7.29,
          16.3,
          43.4,
          57.28,
          81.76,
        ],
      },
      {
        2138: [
          "9088",
          0.935,
          0,
          23.5,
          "0.905",
          "0.966",
          "1.062",
          "1.126",
          "1.049",
          0,
          0,
          99.78,
          0,
          99.89,
          99.95,
          100,
        ],
      },
      {
        2139: [
          "0981",
          18.66,
          17234600,
          23.4,
          "18.468",
          "20.518",
          "22.396",
          "22.969",
          "22.254",
          32363,
          1474.8,
          24.67,
          19.75,
          46.83,
          64.29,
          80.37,
        ],
      },
      {
        2140: [
          "8413",
          0.194,
          0,
          23.4,
          "0.193",
          "0.188",
          "0.237",
          "0.254",
          "0.234",
          0,
          2.254,
          49.13,
          0,
          62.87,
          71.21,
          84.31,
        ],
      },
      {
        2141: [
          "2127",
          1.87,
          3998000,
          23.3,
          "1.94",
          "2.136",
          "2.325",
          "2.3",
          "2.279",
          738,
          57.3,
          58.28,
          0,
          74.65,
          82.72,
          92.12,
        ],
      },
      {
        2142: [
          "8066",
          0.037,
          30000,
          23.3,
          "0.037",
          "0.041",
          "0.045",
          "0.045",
          "0.044",
          0.109,
          0.194,
          16.3,
          0,
          39.99,
          51.7,
          68.03,
        ],
      },
      {
        2143: [
          "0285",
          26.95,
          4459190,
          23.3,
          "27.285",
          "28.672",
          "34.46",
          "37.091",
          "33.177",
          12104,
          607.2,
          65.78,
          8.66,
          75.44,
          83.9,
          91.72,
        ],
      },
      {
        2144: [
          "8232",
          0.045,
          55000,
          23.2,
          "0.05",
          "0.059",
          "0.063",
          "0.065",
          "0.062",
          0.242,
          0.201,
          0.66,
          0,
          55.27,
          62.41,
          73.87,
        ],
      },
      {
        2145: [
          "1812",
          3.89,
          9020500,
          23.2,
          "3.846",
          "3.734",
          "4.298",
          "4.943",
          "4.245",
          3558,
          20.5,
          0.28,
          36.26,
          47.82,
          62.67,
          75.84,
        ],
      },
      {
        2146: [
          "0181",
          0.127,
          0,
          23.2,
          "0.127",
          "0.136",
          "0.148",
          "0.152",
          "0.147",
          0,
          1.455,
          70.79,
          0,
          80.09,
          85.77,
          92.27,
        ],
      },
      {
        2147: [
          "1917",
          0.095,
          0,
          23.1,
          "0.096",
          "0.099",
          "0.111",
          "0.117",
          "0.11",
          0,
          2.185,
          45.47,
          0,
          68.8,
          84.23,
          93.64,
        ],
      },
      {
        2148: [
          "0620",
          2.93,
          166030,
          23.1,
          "3.079",
          "3.572",
          "4.051",
          "4.446",
          "3.926",
          48.228,
          19.5,
          17.67,
          0,
          75.27,
          80.54,
          88.48,
        ],
      },
      {
        2149: [
          "1701",
          0.165,
          764000,
          23.1,
          "0.162",
          "0.17",
          "0.19",
          "0.205",
          "0.188",
          12.193,
          1.65,
          10.78,
          0,
          82.15,
          85.82,
          90.26,
        ],
      },
      {
        2150: [
          "3344",
          0.017,
          0,
          23,
          "0.017",
          "0.017",
          "0.02",
          "0.025",
          "0.02",
          0,
          1.071,
          1.03,
          0,
          56.22,
          61.9,
          71.32,
        ],
      },
      {
        2151: [
          "0265",
          0.049,
          100000,
          23,
          "0.05",
          "0.051",
          "0.062",
          "0.064",
          "0.061",
          0.492,
          6.332,
          19.19,
          0,
          77.93,
          87.01,
          94.43,
        ],
      },
      {
        2152: [
          "8491",
          0.9,
          25000,
          23,
          "0.921",
          "0.958",
          "0.937",
          "1.086",
          "0.901",
          2.25,
          0.525,
          5.59,
          0,
          47.67,
          58.6,
          74.73,
        ],
      },
      {
        2153: [
          "3690",
          226.2,
          18464500,
          22.9,
          "213.75",
          "246.142",
          "254.837",
          "263.271",
          "253.08",
          417000,
          13879.5,
          15.45,
          5.88,
          51.35,
          65.03,
          80.5,
        ],
      },
      {
        2154: [
          "1705",
          0.39,
          60000,
          22.9,
          "0.394",
          "0.439",
          "0.555",
          "0.581",
          "0.527",
          2.31,
          1.56,
          50.01,
          0,
          80.66,
          85.79,
          91.38,
        ],
      },
      {
        2155: [
          "0353",
          0.055,
          60000,
          22.8,
          "0.055",
          "0.067",
          "0.069",
          "0.069",
          "0.069",
          0.334,
          3.963,
          25.67,
          0,
          67.02,
          74.49,
          84.22,
        ],
      },
      {
        2156: [
          "1759",
          1.3,
          52000,
          22.8,
          "1.228",
          "1.338",
          "1.393",
          "1.422",
          "1.414",
          6.762,
          2.808,
          75,
          0,
          88.82,
          92.89,
          95.66,
        ],
      },
      {
        2157: [
          "0846",
          0.475,
          425000,
          22.8,
          "0.435",
          "0.467",
          "0.493",
          "0.54",
          "0.487",
          21.539,
          28.9,
          0.01,
          0.07,
          75.27,
          89.95,
          96.79,
        ],
      },
      {
        2158: [
          "1124",
          0.048,
          16000,
          22.7,
          "0.047",
          "0.048",
          "0.055",
          "0.059",
          "0.054",
          0,
          1.99,
          0.57,
          0,
          60.17,
          69.44,
          80.58,
        ],
      },
      {
        2159: [
          "1117",
          1.41,
          23465000,
          22.7,
          "1.349",
          "1.353",
          "1.462",
          "1.591",
          "1.443",
          3283,
          111.6,
          40.38,
          7.69,
          63.1,
          77,
          90.85,
        ],
      },
      {
        2160: [
          "0868",
          18.8,
          7315480,
          22.7,
          "19.008",
          "19.716",
          "25.394",
          "25.822",
          "24.9",
          13888,
          755.5,
          45.07,
          3.18,
          79.67,
          85.59,
          94.13,
        ],
      },
      {
        2161: [
          "1212",
          4.2,
          381898,
          22.6,
          "4.245",
          "4.343",
          "4.914",
          "5.301",
          "4.815",
          162,
          63,
          22.73,
          0.39,
          65.14,
          82.43,
          92.92,
        ],
      },
      {
        2162: [
          "1237",
          0.1,
          0,
          22.6,
          "0.101",
          "0.107",
          "0.115",
          "0.122",
          "0.113",
          0,
          0.85,
          7.95,
          0,
          60.5,
          79.84,
          91.5,
        ],
      },
      {
        2163: [
          "6060",
          29.15,
          2393300,
          22.6,
          "27.98",
          "27.279",
          "34.098",
          "36.657",
          "33.607",
          6988,
          413.8,
          0.02,
          3.49,
          74.56,
          78.39,
          86.04,
        ],
      },
      {
        2164: [
          "0368",
          0.465,
          420000,
          22.5,
          "0.477",
          "0.494",
          "0.531",
          "0.562",
          "0.525",
          19.476,
          3.72,
          76.32,
          0,
          87.83,
          91.88,
          96.83,
        ],
      },
      {
        2165: [
          "1571",
          2.82,
          28000,
          22.5,
          "2.727",
          "2.95",
          "3.2",
          "3.55",
          "3.137",
          7.926,
          28.2,
          0.03,
          0,
          88.43,
          91.88,
          95.99,
        ],
      },
      {
        2166: [
          "1030",
          5.35,
          13288600,
          22.4,
          "5.339",
          "5.543",
          "6.338",
          "6.969",
          "6.268",
          7121,
          332.2,
          67.67,
          7.02,
          76.88,
          83.55,
          93.25,
        ],
      },
      {
        2167: [
          "0309",
          0.065,
          25000,
          22.4,
          "0.069",
          "0.071",
          "0.076",
          "0.078",
          "0.076",
          0.164,
          1.173,
          15.9,
          0,
          39.65,
          57.09,
          79.2,
        ],
      },
      {
        2168: [
          "1695",
          0.07,
          95000,
          22.4,
          "0.076",
          "0.076",
          "0.085",
          "0.089",
          "0.084",
          0.665,
          0.756,
          75.01,
          0,
          81.05,
          84.41,
          89.4,
        ],
      },
      {
        2169: [
          "6826",
          53,
          276100,
          22.3,
          "52.725",
          "52.295",
          "69.286",
          "69.581",
          "68.203",
          1476,
          20.1,
          0.2,
          17.59,
          34.3,
          49.37,
          73.01,
        ],
      },
      {
        2170: [
          "0888",
          0.211,
          0,
          22.3,
          "0.222",
          "0.208",
          "0.258",
          "0.259",
          "0.258",
          0,
          3,
          17.32,
          0,
          74.15,
          80.97,
          88.48,
        ],
      },
      {
        2171: [
          "0947",
          0.37,
          119000,
          22.3,
          "0.368",
          "0.372",
          "0.41",
          "0.445",
          "0.402",
          4.354,
          3.023,
          28.5,
          0,
          46.78,
          57.56,
          75.81,
        ],
      },
      {
        2172: [
          "1591",
          0.023,
          5500000,
          22.2,
          "0.024",
          "0.026",
          "0.037",
          "0.043",
          "0.036",
          12.358,
          0.92,
          52.53,
          0,
          64.03,
          73.58,
          83.46,
        ],
      },
      {
        2173: [
          "0736",
          0.425,
          75,
          22.2,
          "0.464",
          "0.462",
          "0.5",
          "0.609",
          "0.478",
          0,
          1.135,
          4.64,
          0,
          40.1,
          51.07,
          69.29,
        ],
      },
      {
        2174: [
          "8606",
          0.215,
          0,
          22.2,
          "0.218",
          "0.229",
          "0.267",
          "0.497",
          "0.237",
          0,
          2.072,
          20.36,
          0,
          56.93,
          64.62,
          79.51,
        ],
      },
      {
        2175: [
          "2011",
          1.93,
          1000000,
          22.1,
          "2.115",
          "2.303",
          "2.444",
          "2.455",
          "2.424",
          193,
          8.971,
          1.52,
          0,
          79.64,
          84.33,
          90.85,
        ],
      },
      {
        2176: [
          "0399",
          0.175,
          545000,
          22.1,
          "0.179",
          "0.18",
          "0.207",
          "0.217",
          "0.203",
          9.424,
          2.562,
          22.56,
          0,
          53.5,
          65.5,
          79.44,
        ],
      },
      {
        2177: [
          "1817",
          4.82,
          171500,
          22.1,
          "4.918",
          "5.516",
          "5.865",
          "6.089",
          "5.886",
          84.252,
          45.7,
          22.5,
          0.12,
          74.93,
          89.75,
          97.54,
        ],
      },
      {
        2178: [
          "1799",
          14.52,
          3831400,
          22,
          "14.522",
          "16.661",
          "18.543",
          "18.116",
          "18.8",
          5679,
          54.6,
          0.03,
          0,
          30.77,
          40.97,
          60.87,
        ],
      },
      {
        2179: [
          "8158",
          0.265,
          26000,
          22,
          "0.284",
          "0.274",
          "0.307",
          "0.346",
          "0.297",
          0.677,
          7.564,
          29.47,
          0,
          65.65,
          78.19,
          87.59,
        ],
      },
      {
        2180: [
          "1598",
          0.48,
          2220000,
          21.9,
          "0.477",
          "0.452",
          "0.508",
          "0.543",
          "0.505",
          106,
          5.603,
          0.02,
          0,
          76.05,
          81.3,
          90.62,
        ],
      },
      {
        2181: [
          "2379",
          0.247,
          0,
          21.9,
          "0.247",
          "0.249",
          "0.333",
          "0.344",
          "0.329",
          0,
          1.013,
          13.42,
          0,
          73.21,
          83.39,
          91.32,
        ],
      },
      {
        2182: [
          "0818",
          1.03,
          315700,
          21.9,
          "1.021",
          "1.043",
          "1.222",
          "1.266",
          "1.223",
          32.473,
          28.6,
          22.97,
          0,
          48.99,
          63.24,
          77.39,
        ],
      },
      {
        2183: [
          "1905",
          0.94,
          400000,
          21.8,
          "0.977",
          "1.022",
          "1.143",
          "1.199",
          "1.126",
          38.494,
          54.4,
          78.68,
          0,
          91.97,
          96.61,
          99.5,
        ],
      },
      {
        2184: [
          "0889",
          0.5,
          60000,
          21.8,
          "0.525",
          "0.528",
          "0.601",
          "0.617",
          "0.589",
          3.008,
          1.6,
          68.03,
          0,
          79.17,
          84.59,
          91.65,
        ],
      },
      {
        2185: [
          "9869",
          17.64,
          1469000,
          21.8,
          "18.44",
          "18.265",
          "-",
          "-",
          "-",
          2610,
          223.4,
          9.87,
          2.26,
          80.41,
          90.23,
          97.07,
        ],
      },
      {
        2186: [
          "8225",
          0.36,
          660000,
          21.7,
          "0.38",
          "0.417",
          "0.427",
          "0.433",
          "0.428",
          24.1,
          3.583,
          62.08,
          0,
          72.7,
          81.2,
          89.78,
        ],
      },
      {
        2187: [
          "6123",
          3.03,
          597000,
          21.7,
          "2.922",
          "3.053",
          "3.354",
          "3.768",
          "3.284",
          183,
          12.7,
          0.03,
          0,
          74.64,
          79.03,
          86.38,
        ],
      },
      {
        2188: [
          "8078",
          0.045,
          0,
          21.7,
          "0.043",
          "0.046",
          "0.058",
          "0.062",
          "0.057",
          0,
          0.176,
          0.23,
          0,
          30.83,
          46.41,
          64.5,
        ],
      },
      {
        2189: [
          "0606",
          3.14,
          1950000,
          21.6,
          "3.394",
          "3.602",
          "3.94",
          "-",
          "-",
          620,
          65.1,
          72.3,
          0,
          87.22,
          91.21,
          96.23,
        ],
      },
      {
        2190: [
          "0273",
          0.022,
          3340000,
          21.6,
          "0.022",
          "0.023",
          "0.025",
          "0.027",
          "0.025",
          7.348,
          9.76,
          0.03,
          0,
          49.97,
          67.2,
          88.42,
        ],
      },
      {
        2191: [
          "2258",
          0.415,
          138000,
          21.6,
          "0.421",
          "0.46",
          "0.505",
          "0.526",
          "0.497",
          5.727,
          3.425,
          75.06,
          0,
          91.78,
          95.04,
          97.51,
        ],
      },
      {
        2192: [
          "8423",
          0.2,
          0,
          21.5,
          "0.202",
          "0.23",
          "0.247",
          "0.249",
          "0.243",
          0,
          1.6,
          66.63,
          0,
          79.8,
          83.65,
          90.17,
        ],
      },
      {
        2193: [
          "8311",
          0.23,
          0,
          21.5,
          "0.24",
          "0.227",
          "0.248",
          "0.278",
          "0.247",
          0,
          3.412,
          60.87,
          0,
          76.89,
          84.07,
          92.29,
        ],
      },
      {
        2194: [
          "1945",
          3.57,
          114800,
          21.4,
          "3.649",
          "3.853",
          "3.837",
          "4.176",
          "3.787",
          40.71,
          11.1,
          47.95,
          0,
          60.7,
          68.13,
          81.56,
        ],
      },
      {
        2195: [
          "0039",
          0.05,
          31844000,
          21.4,
          "0.041",
          "0.048",
          "0.058",
          "0.064",
          "0.057",
          146,
          3.112,
          24.34,
          0,
          42.17,
          56.86,
          73.84,
        ],
      },
      {
        2196: [
          "3399",
          1.08,
          31000,
          21.4,
          "1.077",
          "1.087",
          "1.285",
          "1.313",
          "1.271",
          3.346,
          2.236,
          2.05,
          0,
          52.56,
          66.11,
          79.28,
        ],
      },
      {
        2197: [
          "1903",
          0.53,
          0,
          21.3,
          "0.533",
          "0.564",
          "0.622",
          "0.638",
          "0.612",
          0,
          2.65,
          70.76,
          0,
          90.62,
          98.19,
          99.04,
        ],
      },
      {
        2198: [
          "6821",
          287.2,
          311600,
          21.3,
          "281.36",
          "-",
          "-",
          "-",
          "-",
          8839,
          56.5,
          0,
          0.95,
          71.91,
          86.43,
          94.23,
        ],
      },
      {
        2199: [
          "3390",
          1.18,
          586000,
          21.3,
          "1.258",
          "1.317",
          "1.473",
          "1.534",
          "1.458",
          69.588,
          9.44,
          57.67,
          0,
          74.96,
          84.11,
          94.61,
        ],
      },
      {
        2200: [
          "0381",
          0.061,
          540000,
          21.2,
          "0.06",
          "0.058",
          "0.061",
          "0.07",
          "0.059",
          3.285,
          0.703,
          8.36,
          0,
          32.79,
          43.79,
          62.72,
        ],
      },
      {
        2201: [
          "0251",
          5.48,
          162172,
          21.2,
          "5.656",
          "5.802",
          "6.365",
          "6.836",
          "6.268",
          88.772,
          32.9,
          15.82,
          0,
          80.89,
          97.17,
          98.69,
        ],
      },
      {
        2202: [
          "1916",
          2.58,
          123500,
          21.2,
          "2.691",
          "2.965",
          "3.22",
          "3.226",
          "3.205",
          32.195,
          34.7,
          0.01,
          0.8,
          46.9,
          63.94,
          89,
        ],
      },
      {
        2203: [
          "8519",
          0.095,
          4060000,
          21.1,
          "0.099",
          "0.106",
          "0.11",
          "0.118",
          "0.109",
          38.22,
          1.102,
          45.51,
          0,
          57.91,
          66.68,
          82.73,
        ],
      },
      {
        2204: [
          "0604",
          1.77,
          8539950,
          21.1,
          "1.742",
          "1.776",
          "2.063",
          "2.241",
          "2.029",
          1524,
          157.5,
          51.75,
          0.38,
          74.5,
          83.19,
          90.24,
        ],
      },
      {
        2205: [
          "8609",
          0.24,
          0,
          21.1,
          "0.236",
          "0.248",
          "0.291",
          "0.305",
          "0.287",
          0,
          1.2,
          75.68,
          0,
          84.62,
          89.2,
          94.9,
        ],
      },
      {
        2206: [
          "0839",
          12.2,
          4062260,
          21,
          "12.388",
          "13.15",
          "14.342",
          "15.165",
          "14.092",
          4999,
          291.4,
          9.05,
          6.67,
          68.85,
          86.92,
          98.03,
        ],
      },
      {
        2207: [
          "1793",
          0.167,
          2288000,
          21,
          "0.194",
          "0.204",
          "0.215",
          "0.217",
          "0.213",
          38.182,
          1.336,
          75.11,
          0,
          86.34,
          89.7,
          93.81,
        ],
      },
      {
        2208: [
          "0858",
          0.091,
          150000,
          20.9,
          "0.094",
          "0.097",
          "0.11",
          "0.12",
          "0.107",
          1.355,
          2.175,
          14.67,
          0,
          46.46,
          55.9,
          67.84,
        ],
      },
      {
        2209: [
          "0472",
          0.12,
          0,
          20.9,
          "0.12",
          "0.126",
          "0.152",
          "0.16",
          "0.149",
          0,
          3.849,
          1.48,
          0,
          72.55,
          80.25,
          91.55,
        ],
      },
      {
        2210: [
          "0984",
          1.31,
          72500,
          20.9,
          "1.322",
          "1.371",
          "1.515",
          "1.619",
          "1.5",
          9.64,
          3.406,
          57.91,
          0,
          79.33,
          85.76,
          90.94,
        ],
      },
      {
        2211: [
          "0968",
          12.28,
          22681100,
          20.8,
          "12.504",
          "13.736",
          "15.312",
          "14.697",
          "15.263",
          27966,
          1091.7,
          46.22,
          4.32,
          79.7,
          87.34,
          94.89,
        ],
      },
      {
        2212: [
          "8419",
          0.18,
          100000,
          20.8,
          "0.208",
          "0.213",
          "0.228",
          "0.225",
          "0.231",
          1.759,
          0.72,
          74.8,
          0,
          81.34,
          84.51,
          88.86,
        ],
      },
      {
        2213: [
          "2007",
          6.61,
          120780000,
          20.8,
          "6.98",
          "7.111",
          "7.825",
          "8.274",
          "7.733",
          81337,
          1530.1,
          42.9,
          1.11,
          65.77,
          82.36,
          96.76,
        ],
      },
      {
        2214: [
          "1993",
          1.5,
          10000,
          20.7,
          "1.557",
          "1.376",
          "1.514",
          "1.83",
          "1.489",
          1.5,
          7.135,
          76.7,
          0,
          85.17,
          91.66,
          97.54,
        ],
      },
      {
        2215: [
          "0191",
          3.93,
          553607,
          20.7,
          "3.722",
          "3.566",
          "4.317",
          "4.707",
          "4.267",
          217,
          23.1,
          39.95,
          0,
          69.98,
          81.65,
          90.82,
        ],
      },
      {
        2216: [
          "2113",
          0.295,
          0,
          20.7,
          "0.318",
          "0.353",
          "0.337",
          "0.342",
          "0.338",
          0,
          2.374,
          0.1,
          0,
          82.63,
          87.88,
          93.53,
        ],
      },
      {
        2217: [
          "0661",
          0.077,
          3790000,
          20.6,
          "0.078",
          "0.084",
          "0.105",
          "0.112",
          "0.104",
          30.034,
          13.7,
          60.36,
          0,
          72.68,
          80.86,
          86.73,
        ],
      },
      {
        2218: [
          "0098",
          7.2,
          60000,
          20.6,
          "7.177",
          "7.7",
          "10.419",
          "10.329",
          "10.261",
          43.21,
          30.2,
          29.98,
          0,
          73.72,
          83.1,
          91.16,
        ],
      },
      {
        2219: [
          "1132",
          0.098,
          45000,
          20.6,
          "0.108",
          "0.11",
          "0.12",
          "0.124",
          "0.119",
          0.449,
          2.744,
          10.97,
          0,
          60.39,
          75.34,
          86.74,
        ],
      },
      {
        2220: [
          "2280",
          0.71,
          527000,
          20.5,
          "0.723",
          "0.736",
          "0.834",
          "0.883",
          "0.821",
          38.515,
          9.3,
          0.75,
          5.4,
          34.91,
          48.75,
          72.23,
        ],
      },
      {
        2221: [
          "0413",
          0.08,
          0,
          20.5,
          "0.078",
          "0.085",
          "0.094",
          "0.099",
          "0.093",
          0,
          10.5,
          76.56,
          0,
          96.95,
          97.93,
          98.77,
        ],
      },
      {
        2222: [
          "1371",
          0.092,
          344000,
          20.4,
          "0.095",
          "0.098",
          "0.108",
          "0.111",
          "0.107",
          3.153,
          2.841,
          0.85,
          0,
          64.17,
          71.07,
          80.68,
        ],
      },
      {
        2223: [
          "0467",
          0.91,
          15444000,
          20.4,
          "0.758",
          "0.686",
          "0.954",
          "1.083",
          "0.926",
          1358,
          239.2,
          2.34,
          0.44,
          77.53,
          85.92,
          95.9,
        ],
      },
      {
        2224: [
          "0789",
          0.096,
          10060000,
          20.4,
          "0.078",
          "0.085",
          "0.105",
          "0.112",
          "0.104",
          113,
          1.06,
          0.38,
          0,
          75.56,
          80.87,
          87.78,
        ],
      },
      {
        2225: [
          "0152",
          8.54,
          2374570,
          20.3,
          "8.429",
          "8.435",
          "9.721",
          "10.482",
          "9.63",
          2033,
          193.5,
          0.55,
          3.63,
          68.15,
          77.74,
          89.65,
        ],
      },
      {
        2226: [
          "1653",
          0.285,
          0,
          20.3,
          "0.294",
          "0.331",
          "0.334",
          "0.341",
          "0.331",
          0,
          0.684,
          42.88,
          0,
          74.28,
          80.66,
          89.88,
        ],
      },
      {
        2227: [
          "0141",
          0.18,
          0,
          20.3,
          "0.18",
          "0.18",
          "0.188",
          "0.214",
          "0.185",
          0,
          0.622,
          5.08,
          0,
          70.1,
          80.95,
          89.93,
        ],
      },
      {
        2228: [
          "2120",
          23.9,
          23900,
          20.2,
          "22.875",
          "24.895",
          "31.524",
          "30.382",
          "31.497",
          56.903,
          4.622,
          0.03,
          0,
          44,
          62.27,
          78.78,
        ],
      },
      {
        2229: [
          "0682",
          0.042,
          723000,
          20.2,
          "0.042",
          "0.041",
          "0.047",
          "0.055",
          "0.046",
          2.96,
          1.384,
          19.69,
          0,
          40.02,
          53.41,
          69.68,
        ],
      },
      {
        2230: [
          "1569",
          0.96,
          188000,
          20.2,
          "0.939",
          "1.007",
          "1.149",
          "1.206",
          "1.134",
          18.066,
          40.4,
          51.44,
          2.76,
          77.18,
          86.81,
          94.82,
        ],
      },
      {
        2231: [
          "2869",
          7.18,
          9539450,
          20.1,
          "7.302",
          "7.379",
          "8.493",
          "9.345",
          "8.238",
          6942,
          233,
          0,
          8.18,
          52.25,
          70.35,
          92.87,
        ],
      },
      {
        2232: [
          "0117",
          0.52,
          0,
          20.1,
          "0.534",
          "0.589",
          "0.707",
          "0.655",
          "0.722",
          0,
          3.873,
          48.23,
          0,
          65.73,
          73.47,
          87.09,
        ],
      },
      {
        2233: [
          "3301",
          3.2,
          2356500,
          20.1,
          "3.211",
          "3.015",
          "3.729",
          "4.134",
          "3.672",
          770,
          53.8,
          28.71,
          6.63,
          51.66,
          63.36,
          84.01,
        ],
      },
      {
        2234: [
          "6088",
          1.55,
          1323000,
          20,
          "1.555",
          "1.605",
          "1.691",
          "1.857",
          "1.67",
          207,
          110.5,
          1.12,
          3.03,
          81.21,
          85.39,
          91.45,
        ],
      },
      {
        2235: [
          "3737",
          0.99,
          305000,
          20,
          "1.02",
          "1.009",
          "1.194",
          "1.239",
          "1.165",
          29.473,
          8.55,
          61.39,
          0,
          78.18,
          85.17,
          93.18,
        ],
      },
      {
        2236: [
          "0638",
          0.76,
          44000,
          19.9,
          "0.791",
          "0.813",
          "0.895",
          "0.943",
          "0.879",
          3.348,
          3.336,
          63.87,
          0,
          73.23,
          77.62,
          83.59,
        ],
      },
      {
        2237: [
          "0354",
          8.16,
          23758900,
          19.9,
          "9.123",
          "11.574",
          "12.942",
          "11.933",
          "12.842",
          19542,
          250.4,
          2.97,
          8.18,
          45.43,
          61.37,
          82.9,
        ],
      },
      {
        2238: [
          "0660",
          0.052,
          0,
          19.9,
          "0.056",
          "0.055",
          "0.062",
          "0.065",
          "0.061",
          0,
          0.874,
          1.32,
          0,
          82.3,
          86.45,
          92.67,
        ],
      },
      {
        2239: [
          "0430",
          0.395,
          0,
          19.8,
          "0.392",
          "0.404",
          "0.547",
          "0.594",
          "-",
          0,
          1.533,
          65.74,
          0,
          86.55,
          88.99,
          92.03,
        ],
      },
      {
        2240: [
          "6993",
          7,
          5762500,
          19.8,
          "7.152",
          "7.279",
          "7.643",
          "8.433",
          "7.432",
          4042,
          410.1,
          75.89,
          0.81,
          88.89,
          92.07,
          94.99,
        ],
      },
      {
        2241: [
          "1125",
          5.14,
          0,
          19.8,
          "5.074",
          "5.326",
          "6.087",
          "6.31",
          "5.974",
          0,
          17,
          74.8,
          0,
          93.3,
          94.63,
          96.83,
        ],
      },
      {
        2242: [
          "3616",
          0.96,
          206000,
          19.7,
          "0.996",
          "1.035",
          "1.279",
          "1.299",
          "1.271",
          19.956,
          11.5,
          75,
          0,
          91.45,
          95.23,
          99,
        ],
      },
      {
        2243: [
          "8300",
          0.031,
          2020000,
          19.7,
          "0.033",
          "0.037",
          "0.043",
          "0.043",
          "0.043",
          5.818,
          0.819,
          0.01,
          0,
          81.41,
          88.4,
          94.33,
        ],
      },
      {
        2244: [
          "1102",
          0.09,
          0,
          19.7,
          "0.09",
          "0.09",
          "0.091",
          "0.104",
          "0.091",
          0,
          0.488,
          0.49,
          0,
          57.37,
          66.52,
          77.4,
        ],
      },
      {
        2245: [
          "0337",
          1.63,
          1414000,
          19.6,
          "1.563",
          "1.569",
          "1.896",
          "2.098",
          "1.862",
          231,
          45.5,
          31.98,
          4.35,
          68.49,
          77.68,
          89.2,
        ],
      },
      {
        2246: [
          "0171",
          0.475,
          506000,
          19.6,
          "0.485",
          "0.495",
          "0.556",
          "0.583",
          "0.548",
          23.891,
          10.9,
          0.7,
          0,
          60.41,
          67.64,
          80.5,
        ],
      },
      {
        2247: [
          "2088",
          0.082,
          22593,
          19.5,
          "0.09",
          "0.096",
          "0.115",
          "0.116",
          "0.114",
          0.184,
          1.155,
          0.86,
          0,
          80.18,
          82.94,
          87.66,
        ],
      },
      {
        2248: [
          "6080",
          0.096,
          0,
          19.5,
          "0.101",
          "0.121",
          "0.123",
          "0.118",
          "0.122",
          0,
          0.896,
          0.32,
          0,
          75.51,
          79.77,
          87.58,
        ],
      },
      {
        2249: [
          "6855",
          27.45,
          622500,
          19.5,
          "26.87",
          "28.94",
          "36.441",
          "37.207",
          "35.722",
          1733,
          72.1,
          5.36,
          21.6,
          50.79,
          62.29,
          80.74,
        ],
      },
      {
        2250: [
          "1298",
          1.08,
          0,
          19.4,
          "1.08",
          "1.119",
          "1.359",
          "1.43",
          "1.334",
          0,
          2.975,
          7.79,
          0,
          91.57,
          97.59,
          99.29,
        ],
      },
      {
        2251: [
          "6160",
          148.5,
          979900,
          19.4,
          "146.56",
          "187.232",
          "197.091",
          "196.997",
          "197.647",
          14586,
          1977.2,
          57.45,
          0.99,
          96.68,
          98.37,
          99.63,
        ],
      },
      {
        2252: [
          "1609",
          0.86,
          356000,
          19.4,
          "0.847",
          "0.693",
          "0.788",
          "2.326",
          "0.776",
          28.53,
          9.42,
          44,
          0,
          91.07,
          95.49,
          97.74,
        ],
      },
      {
        2253: [
          "1037",
          0.365,
          33213,
          19.3,
          "0.351",
          "0.373",
          "0.41",
          "0.448",
          "0.408",
          1.132,
          2.561,
          30.92,
          0,
          53.64,
          67.62,
          83.24,
        ],
      },
      {
        2254: [
          "0764",
          0.17,
          52541,
          19.3,
          "0.176",
          "0.191",
          "0.23",
          "0.24",
          "0.225",
          0.837,
          6.493,
          7.36,
          0,
          74.28,
          79.04,
          87.76,
        ],
      },
      {
        2255: [
          "0250",
          0.031,
          600000,
          19.3,
          "0.03",
          "0.032",
          "0.038",
          "0.042",
          "0.038",
          1.77,
          6.733,
          38.03,
          0,
          43.78,
          47.37,
          52.18,
        ],
      },
      {
        2256: [
          "1765",
          1.4,
          26394000,
          19.2,
          "1.457",
          "1.509",
          "1.511",
          "1.771",
          "1.482",
          3696,
          112.3,
          0.01,
          19.56,
          78.27,
          86.29,
          92.49,
        ],
      },
      {
        2257: [
          "6110",
          7.64,
          8135390,
          19.2,
          "7.722",
          "8.645",
          "9.837",
          "10.188",
          "9.671",
          6242,
          473.7,
          69.02,
          0.99,
          93.45,
          97.73,
          99.25,
        ],
      },
      {
        2258: [
          "0756",
          0.093,
          0,
          19.2,
          "0.094",
          "0.102",
          "0.121",
          "0.137",
          "0.117",
          0,
          2.122,
          12.29,
          0,
          69.7,
          78.69,
          84.77,
        ],
      },
      {
        2259: [
          "0200",
          9.01,
          1140000,
          19.1,
          "9.37",
          "9.803",
          "10.994",
          "11.969",
          "10.776",
          1030,
          136.6,
          5.1,
          0.52,
          78.33,
          87.43,
          93.35,
        ],
      },
      {
        2260: [
          "7522",
          1.694,
          1623100,
          19.1,
          "1.668",
          "1.655",
          "1.872",
          "2.017",
          "1.849",
          275,
          0,
          0,
          0,
          36.05,
          51.17,
          69.51,
        ],
      },
      {
        2261: [
          "1849",
          0.48,
          155000,
          19,
          "0.5",
          "0.561",
          "0.583",
          "0.592",
          "0.583",
          7.7,
          3.84,
          59.59,
          0,
          82.59,
          87.22,
          91.74,
        ],
      },
      {
        2262: [
          "0577",
          0.024,
          0,
          19,
          "0.024",
          "0.024",
          "0.024",
          "0.033",
          "0.024",
          0,
          0.243,
          10.28,
          0,
          35.4,
          47.37,
          64.06,
        ],
      },
      {
        2263: [
          "7568",
          1.162,
          12866100,
          19,
          "1.145",
          "1.137",
          "1.286",
          "1.386",
          "1.27",
          1495,
          0,
          0,
          0,
          43.02,
          54.02,
          69.2,
        ],
      },
      {
        2264: [
          "9969",
          14.2,
          2570500,
          18.9,
          "14.138",
          "17.661",
          "21.409",
          "21.646",
          "20.947",
          3674,
          212.9,
          19.71,
          6.48,
          52.26,
          68.66,
          90.17,
        ],
      },
      {
        2265: [
          "8178",
          0.23,
          0,
          18.9,
          "0.23",
          "0.256",
          "0.288",
          "0.327",
          "-",
          0,
          0.789,
          8.22,
          0,
          54.91,
          70.39,
          83.54,
        ],
      },
      {
        2266: [
          "1477",
          16.34,
          104000,
          18.9,
          "16.9",
          "16.707",
          "20.262",
          "20.795",
          "19.763",
          168,
          108.9,
          11.76,
          1.85,
          67.3,
          86.18,
          95.47,
        ],
      },
      {
        2267: [
          "3759",
          118.5,
          1055660,
          18.8,
          "112.79",
          "139.282",
          "164.123",
          "163.398",
          "161.16",
          12597,
          158.8,
          0.01,
          13.05,
          74.85,
          87.55,
          96.45,
        ],
      },
      {
        2268: [
          "1141",
          2.99,
          3243500,
          18.8,
          "2.774",
          "3.22",
          "3.697",
          "3.825",
          "-",
          987,
          35.1,
          60.34,
          8.86,
          82.54,
          89.99,
          95.7,
        ],
      },
      {
        2269: [
          "0680",
          0.036,
          1750000,
          18.8,
          "0.036",
          "0.038",
          "0.046",
          "0.048",
          "0.045",
          6.25,
          24.7,
          13.16,
          0.31,
          74.19,
          82.65,
          90.75,
        ],
      },
      {
        2270: [
          "0840",
          0.203,
          0,
          18.7,
          "0.211",
          "0.23",
          "0.248",
          "0.255",
          "0.245",
          0,
          0.411,
          0.62,
          0,
          46.52,
          57.41,
          74.33,
        ],
      },
      {
        2271: [
          "3818",
          0.68,
          3072000,
          18.7,
          "0.676",
          "0.709",
          "0.915",
          "0.928",
          "0.894",
          210,
          40,
          0.29,
          2.67,
          68.34,
          77.36,
          87.49,
        ],
      },
      {
        2272: [
          "9826",
          9.75,
          109350,
          18.7,
          "9.633",
          "10.273",
          "11.429",
          "12.163",
          "11.248",
          838,
          0,
          59.57,
          0,
          98.3,
          99.33,
          99.93,
        ],
      },
      {
        2273: [
          "0166",
          0.056,
          2123500,
          18.6,
          "0.058",
          "0.067",
          "0.069",
          "0.069",
          "0.069",
          11.669,
          4.905,
          63.87,
          0,
          77.55,
          82.9,
          91.11,
        ],
      },
      {
        2274: [
          "9983",
          4.8,
          842000,
          18.6,
          "4.834",
          "5.052",
          "5.891",
          "6.132",
          "5.763",
          405,
          60.9,
          -0.01,
          3.44,
          84.51,
          90.38,
          94.21,
        ],
      },
      {
        2275: [
          "2826",
          75.92,
          99148,
          18.5,
          "75.13",
          "80.144",
          "88.985",
          "94.639",
          "87.583",
          757,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        2276: [
          "3669",
          9.63,
          8610000,
          18.5,
          "9.536",
          "11.181",
          "12.463",
          "12.818",
          "12.364",
          8300,
          189.6,
          0.11,
          4.48,
          74.46,
          83.17,
          93.75,
        ],
      },
      {
        2277: [
          "1810",
          18.68,
          47981800,
          18.5,
          "18.454",
          "19.383",
          "22.962",
          "23.831",
          "22.546",
          89489,
          4670.1,
          18.85,
          11.78,
          51.39,
          65.93,
          85.87,
        ],
      },
      {
        2278: [
          "8329",
          0.189,
          200000,
          18.4,
          "0.184",
          "0.207",
          "0.232",
          "0.236",
          "0.229",
          3.755,
          0.805,
          0.07,
          0,
          43.74,
          56.3,
          71.04,
        ],
      },
      {
        2279: [
          "1452",
          0.149,
          84000,
          18.4,
          "0.167",
          "0.182",
          "0.216",
          "0.213",
          "0.215",
          1.238,
          0.736,
          0.03,
          0,
          66.25,
          76.85,
          90.96,
        ],
      },
      {
        2280: [
          "6111",
          4.3,
          18000,
          18.4,
          "4.351",
          "4.508",
          "5.386",
          "5.6",
          "5.334",
          7.652,
          35.5,
          40.87,
          0,
          69.31,
          79.79,
          91.41,
        ],
      },
      {
        2281: [
          "8135",
          0.215,
          0,
          18.3,
          "0.212",
          "0.216",
          "0.261",
          "0.296",
          "0.258",
          0,
          1.705,
          26.71,
          0,
          65.75,
          78.18,
          89.27,
        ],
      },
      {
        2282: [
          "7288",
          4.814,
          2098500,
          18.3,
          "4.485",
          "4.792",
          "5.575",
          "6.115",
          "5.471",
          1014,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        2283: [
          "2812",
          13.96,
          114400,
          18.3,
          "13.436",
          "14.522",
          "16.101",
          "17.325",
          "15.885",
          160,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        2284: [
          "1608",
          1.37,
          2782000,
          18.2,
          "1.312",
          "1.287",
          "1.556",
          "1.628",
          "1.541",
          379,
          37,
          55.19,
          0.59,
          81.26,
          88.91,
          95.39,
        ],
      },
      {
        2285: [
          "1281",
          0.138,
          0,
          18.2,
          "0.146",
          "0.15",
          "0.17",
          "0.176",
          "0.169",
          0,
          2.049,
          0.56,
          0,
          79.42,
          86.09,
          92.12,
        ],
      },
      {
        2286: [
          "9812",
          1.798,
          200,
          18.2,
          "1.723",
          "1.863",
          "2.069",
          "2.228",
          "2.042",
          0.285,
          0,
          99.41,
          0,
          99.9,
          99.99,
          100,
        ],
      },
      {
        2287: [
          "0978",
          0.76,
          392000,
          18.1,
          "0.749",
          "0.789",
          "0.929",
          "0.983",
          "0.915",
          29.956,
          37.2,
          76.32,
          3.9,
          83.53,
          87.6,
          92,
        ],
      },
      {
        2288: [
          "1286",
          1.81,
          0,
          18.1,
          "1.826",
          "1.966",
          "2.226",
          "2.229",
          "2.195",
          0,
          34,
          6.64,
          0.04,
          80.73,
          91.63,
          98.22,
        ],
      },
      {
        2289: [
          "1660",
          0.445,
          5080000,
          18,
          "0.436",
          "0.457",
          "0.522",
          "0.564",
          "0.512",
          225,
          27.5,
          18.89,
          0,
          86.14,
          91.68,
          96.46,
        ],
      },
      {
        2290: [
          "0073",
          0.157,
          111000,
          18,
          "0.153",
          "0.173",
          "0.219",
          "0.251",
          "0.21",
          1.673,
          3.924,
          1.82,
          0,
          62.32,
          69.67,
          81.28,
        ],
      },
      {
        2291: [
          "9992",
          46.3,
          4001000,
          18,
          "44.75",
          "50.31",
          "56.409",
          "58.291",
          "55.243",
          18856,
          649,
          0.24,
          13.64,
          57.78,
          69.4,
          86.87,
        ],
      },
      {
        2292: [
          "7230",
          4.822,
          104400,
          17.9,
          "4.497",
          "4.807",
          "5.603",
          "6.154",
          "5.496",
          50.573,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        2293: [
          "0439",
          0.196,
          1906000,
          17.9,
          "0.197",
          "0.206",
          "0.234",
          "0.232",
          "0.231",
          36.828,
          12,
          3.98,
          4.19,
          51.24,
          65.15,
          81.28,
        ],
      },
      {
        2294: [
          "0127",
          2.8,
          413566,
          17.9,
          "2.836",
          "3.454",
          "3.307",
          "3.457",
          "3.293",
          116,
          53.4,
          76.43,
          0.05,
          86.97,
          91.17,
          94.87,
        ],
      },
      {
        2295: [
          "3813",
          1.19,
          6539000,
          17.8,
          "1.115",
          "1.165",
          "1.42",
          "1.531",
          "1.393",
          765,
          63.3,
          61.31,
          0.82,
          79.51,
          87.84,
          93.81,
        ],
      },
      {
        2296: [
          "0167",
          0.03,
          320000,
          17.8,
          "0.029",
          "0.032",
          "0.039",
          "0.041",
          "0.038",
          0.899,
          0.78,
          2.73,
          0,
          63.61,
          72.3,
          81,
        ],
      },
      {
        2297: [
          "6078",
          49.15,
          2924950,
          17.8,
          "46.37",
          "57.203",
          "68.965",
          "68.224",
          "67.003",
          14776,
          303.7,
          0,
          26.78,
          61.33,
          78.56,
          94.84,
        ],
      },
      {
        2298: [
          "6838",
          1.04,
          0,
          17.7,
          "1.049",
          "1.08",
          "1.319",
          "1.427",
          "-",
          0,
          6.24,
          75.51,
          0,
          86.34,
          90.65,
          93.15,
        ],
      },
      {
        2299: [
          "2728",
          0.123,
          332000,
          17.7,
          "0.125",
          "0.153",
          "0.177",
          "0.17",
          "0.179",
          4.018,
          5.48,
          18.67,
          0,
          70.44,
          78.99,
          88.21,
        ],
      },
      {
        2300: [
          "8198",
          0.4,
          0,
          17.7,
          "0.425",
          "0.381",
          "0.474",
          "0.546",
          "0.461",
          0,
          2.194,
          1.37,
          0,
          70.47,
          76,
          82.94,
        ],
      },
      {
        2301: [
          "1429",
          0.115,
          232000,
          17.6,
          "0.117",
          "0.129",
          "0.142",
          "0.147",
          "0.141",
          2.594,
          1.84,
          75,
          0,
          83.37,
          87.44,
          91.65,
        ],
      },
      {
        2302: [
          "1538",
          0.57,
          212000,
          17.6,
          "0.601",
          "0.621",
          "0.689",
          "0.722",
          "0.679",
          12.498,
          4.871,
          0.03,
          0,
          72.81,
          77.84,
          87.27,
        ],
      },
      {
        2303: [
          "1636",
          0.184,
          0,
          17.5,
          "0.184",
          "0.182",
          "0.21",
          "0.232",
          "0.208",
          0,
          6.359,
          42.24,
          0.99,
          66.29,
          77.62,
          84.99,
        ],
      },
      {
        2304: [
          "2618",
          26.55,
          4068390,
          17.5,
          "25.67",
          "27.782",
          "31.659",
          "-",
          "31.015",
          10822,
          1641.6,
          65.83,
          0.49,
          78.33,
          83.82,
          91.27,
        ],
      },
      {
        2305: [
          "2148",
          8.73,
          625000,
          17.5,
          "9.081",
          "10.06",
          "10.864",
          "11.28",
          "10.95",
          546,
          101.7,
          71.41,
          0,
          80.89,
          88.24,
          95.05,
        ],
      },
      {
        2306: [
          "0193",
          0.285,
          1500,
          17.4,
          "0.287",
          "0.326",
          "0.359",
          "0.369",
          "0.357",
          0,
          0.554,
          31.07,
          0,
          59.77,
          70.14,
          82.31,
        ],
      },
      {
        2307: [
          "6811",
          1.29,
          619000,
          17.4,
          "1.295",
          "1.407",
          "1.661",
          "1.786",
          "1.626",
          79.727,
          12.9,
          0.29,
          0,
          63.94,
          73.57,
          81.1,
        ],
      },
      {
        2308: [
          "0833",
          0.275,
          0,
          17.4,
          "0.285",
          "0.308",
          "0.328",
          "0.351",
          "0.326",
          0,
          2.602,
          25.52,
          0,
          56.65,
          68.99,
          88.08,
        ],
      },
      {
        2309: [
          "8071",
          0.03,
          540000,
          17.3,
          "0.032",
          "0.034",
          "0.045",
          "0.044",
          "0.044",
          1.78,
          1.406,
          0.02,
          0,
          60.55,
          69.3,
          78.05,
        ],
      },
      {
        2310: [
          "8257",
          0.177,
          0,
          17.3,
          "0.177",
          "0.191",
          "0.216",
          "0.234",
          "0.212",
          0,
          1.77,
          68.27,
          0,
          80.58,
          88.97,
          93.63,
        ],
      },
      {
        2311: [
          "9668",
          2.42,
          8586480,
          17.3,
          "2.532",
          "2.375",
          "2.804",
          "2.953",
          "2.791",
          2122,
          150,
          46.59,
          0.02,
          60.27,
          69.91,
          85.82,
        ],
      },
      {
        2312: [
          "0630",
          0.089,
          20000,
          17.2,
          "0.091",
          "0.079",
          "0.118",
          "0.125",
          "0.117",
          0.182,
          2.153,
          8.94,
          0,
          38.95,
          48.98,
          67.15,
        ],
      },
      {
        2313: [
          "2171",
          25.5,
          1120000,
          17.2,
          "25.54",
          "36.416",
          "-",
          "-",
          "37.305",
          2885,
          144.7,
          39.55,
          2.51,
          58.14,
          66.8,
          82.26,
        ],
      },
      {
        2314: [
          "8418",
          1.06,
          566000,
          17.2,
          "1.104",
          "1.122",
          "1.281",
          "1.391",
          "1.257",
          61.466,
          9.01,
          73.13,
          0,
          84.47,
          91.39,
          98.54,
        ],
      },
      {
        2315: [
          "3368",
          0.201,
          331500,
          17.1,
          "0.21",
          "0.238",
          "0.264",
          "0.264",
          "0.262",
          6.695,
          5.295,
          35.78,
          0.21,
          68.79,
          78.49,
          89.29,
        ],
      },
      {
        2316: [
          "2161",
          0.94,
          58000,
          17.1,
          "1.001",
          "0.872",
          "1.109",
          "1.215",
          "1.076",
          5.452,
          8.401,
          74.53,
          0,
          88.37,
          91.22,
          94.49,
        ],
      },
      {
        2317: [
          "0339",
          0.35,
          0,
          17,
          "0.35",
          "0.351",
          "0.471",
          "0.533",
          "0.464",
          0,
          0.842,
          5.72,
          0,
          66.63,
          79.19,
          88.95,
        ],
      },
      {
        2318: [
          "6880",
          0.193,
          0,
          17,
          "0.208",
          "0.226",
          "0.269",
          "0.281",
          "-",
          0,
          0.674,
          15.59,
          0,
          81.7,
          87.84,
          94.51,
        ],
      },
      {
        2319: [
          "0980",
          0.8,
          453000,
          17,
          "0.8",
          "0.864",
          "0.941",
          "0.972",
          "0.936",
          34.867,
          2.981,
          0.11,
          0,
          51.39,
          59.35,
          74.16,
        ],
      },
      {
        2320: [
          "3886",
          0.395,
          6006000,
          16.9,
          "0.409",
          "0.442",
          "0.518",
          "0.474",
          "0.53",
          236,
          29.7,
          37.23,
          0.49,
          64.26,
          75.83,
          90.97,
        ],
      },
      {
        2321: [
          "0295",
          0.06,
          0,
          16.9,
          "0.06",
          "0.063",
          "0.07",
          "0.075",
          "0.069",
          0,
          8.979,
          0.25,
          0.32,
          72.33,
          81.45,
          90.89,
        ],
      },
      {
        2322: [
          "1179",
          28.2,
          71500,
          16.9,
          "28.16",
          "31.349",
          "35.5",
          "37.611",
          "34.888",
          204,
          921.4,
          46.96,
          0,
          97.64,
          98.93,
          99.8,
        ],
      },
      {
        2323: [
          "1559",
          0.163,
          0,
          16.8,
          "0.165",
          "0.185",
          "0.226",
          "0.245",
          "0.218",
          0,
          2.582,
          33.7,
          0,
          55.56,
          67.69,
          83.07,
        ],
      },
      {
        2324: [
          "2211",
          0.15,
          113000,
          16.8,
          "0.153",
          "0.158",
          "0.17",
          "0.193",
          "0.165",
          1.702,
          0.695,
          42.5,
          0,
          63.63,
          72.91,
          81.89,
        ],
      },
      {
        2325: [
          "9961",
          195.5,
          171426,
          16.8,
          "194.19",
          "208.564",
          "227.871",
          "-",
          "225.387",
          3345,
          1246.2,
          7.76,
          0,
          97.12,
          98.65,
          99.5,
        ],
      },
      {
        2326: [
          "2017",
          0.3,
          54000,
          16.7,
          "0.326",
          "0.321",
          "0.388",
          "0.41",
          "0.378",
          1.493,
          1.856,
          2.58,
          0,
          89.79,
          92.82,
          95.56,
        ],
      },
      {
        2327: [
          "0627",
          0.016,
          54653000,
          16.7,
          "0.016",
          "0.019",
          "0.02",
          "0.02",
          "0.02",
          82.083,
          1.818,
          60.92,
          0,
          72.68,
          78.68,
          86.29,
        ],
      },
      {
        2328: [
          "3938",
          0.21,
          82000,
          16.6,
          "0.23",
          "0.249",
          "0.279",
          "0.291",
          "0.272",
          1.751,
          0.852,
          79.22,
          0,
          84.52,
          87.29,
          92.14,
        ],
      },
      {
        2329: [
          "1208",
          2.76,
          21851300,
          16.6,
          "2.496",
          "2.756",
          "3.309",
          "3.695",
          "3.302",
          6008,
          238.4,
          65.76,
          4.51,
          83.71,
          88.38,
          94.07,
        ],
      },
      {
        2330: [
          "1922",
          3.3,
          66000,
          16.6,
          "3.516",
          "3.39",
          "3.956",
          "4.189",
          "3.883",
          21.78,
          8.816,
          41.82,
          0,
          75.99,
          85.15,
          95.84,
        ],
      },
      {
        2331: [
          "1773",
          1.91,
          0,
          16.5,
          "1.91",
          "1.881",
          "2.134",
          "3.165",
          "2.049",
          0,
          41.3,
          0,
          15.77,
          73.9,
          83.56,
          93.25,
        ],
      },
      {
        2332: [
          "1911",
          15.34,
          733475,
          16.5,
          "14.908",
          "15.826",
          "18.792",
          "20.365",
          "18.569",
          1121,
          84.4,
          0.3,
          11.11,
          66.01,
          81.36,
          95.81,
        ],
      },
      {
        2333: [
          "8062",
          0.27,
          0,
          16.5,
          "0.269",
          "0.296",
          "0.374",
          "0.382",
          "0.372",
          0,
          1.296,
          22.52,
          0,
          75.15,
          82.74,
          92.02,
        ],
      },
      {
        2334: [
          "0438",
          20.25,
          62000,
          16.4,
          "21.205",
          "21.077",
          "25.448",
          "25.388",
          "25.218",
          126,
          19.4,
          0.1,
          0,
          65.81,
          75.51,
          85.72,
        ],
      },
      {
        2335: [
          "9977",
          1.57,
          4149000,
          16.4,
          "1.669",
          "1.688",
          "1.838",
          "2.008",
          "1.811",
          672,
          5.574,
          0.01,
          0,
          34.28,
          52.19,
          80.94,
        ],
      },
      {
        2336: [
          "0551",
          13,
          1311490,
          16.4,
          "12.98",
          "14.052",
          "15.857",
          "16.848",
          "15.647",
          1705,
          209.5,
          40.07,
          0.04,
          78.7,
          90.13,
          97.05,
        ],
      },
      {
        2337: [
          "9923",
          26.55,
          1312800,
          16.3,
          "26.06",
          "26.139",
          "32.389",
          "38.682",
          "31.4",
          3518,
          119.9,
          6.2,
          2.18,
          60.46,
          76.02,
          89.06,
        ],
      },
      {
        2338: [
          "3963",
          0.119,
          0,
          16.3,
          "0.119",
          "0.122",
          "0.143",
          "0.149",
          "0.142",
          0,
          0.491,
          53.44,
          0,
          76.9,
          85.74,
          95.61,
        ],
      },
      {
        2339: [
          "1159",
          0.86,
          0,
          16.3,
          "0.938",
          "0.937",
          "1.183",
          "1.285",
          "1.146",
          0,
          7.083,
          33.34,
          0,
          58.89,
          74.14,
          85.42,
        ],
      },
      {
        2340: [
          "1502",
          3.3,
          753000,
          16.2,
          "3.46",
          "3.79",
          "4.136",
          "4.308",
          "4.052",
          249,
          3.416,
          0.03,
          0,
          27.27,
          40.69,
          65.09,
        ],
      },
      {
        2341: [
          "0029",
          9.78,
          120000,
          16.2,
          "9.92",
          "10.765",
          "12.09",
          "12.565",
          "11.985",
          114,
          23.2,
          44.97,
          0,
          63.78,
          73.51,
          83.51,
        ],
      },
      {
        2342: [
          "8281",
          0.165,
          8000,
          16.1,
          "0.167",
          "0.207",
          "0.21",
          "0.202",
          "0.203",
          0.136,
          1.65,
          78.44,
          0,
          96.92,
          97.95,
          98.95,
        ],
      },
      {
        2343: [
          "1851",
          1.03,
          4000,
          16.1,
          "1.139",
          "1.214",
          "1.316",
          "1.346",
          "1.306",
          0.42,
          5.15,
          1.89,
          0,
          90.94,
          95.6,
          98.1,
        ],
      },
      {
        2344: [
          "6288",
          41.7,
          0,
          16.1,
          "42.42",
          "47.647",
          "52.13",
          "55.008",
          "51.265",
          0,
          208.5,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        2345: [
          "0499",
          0.231,
          612000,
          16,
          "0.238",
          "0.247",
          "0.277",
          "0.308",
          "0.273",
          14.135,
          2.307,
          50.24,
          0,
          74.18,
          78.78,
          85.36,
        ],
      },
      {
        2346: [
          "9995",
          74.6,
          1196500,
          16,
          "68.535",
          "88.532",
          "99.768",
          "100.265",
          "97.325",
          8871,
          141.4,
          5.79,
          11.12,
          45.49,
          62.63,
          84.95,
        ],
      },
      {
        2347: [
          "6918",
          0.99,
          128000,
          16,
          "0.989",
          "1.149",
          "1.348",
          "1.47",
          "1.322",
          13.604,
          5.157,
          14.03,
          0,
          79.67,
          84.57,
          93.26,
        ],
      },
      {
        2348: [
          "9960",
          5.3,
          695500,
          15.9,
          "5.938",
          "5.098",
          "-",
          "-",
          "-",
          377,
          47.8,
          67.63,
          0,
          85.06,
          90.77,
          95.29,
        ],
      },
      {
        2349: [
          "1515",
          4.59,
          2172700,
          15.9,
          "4.645",
          "4.829",
          "6.394",
          "6.56",
          "6.183",
          1009,
          59.5,
          35.74,
          8.47,
          68.77,
          76.47,
          88.61,
        ],
      },
      {
        2350: [
          "1522",
          0.425,
          196000,
          15.9,
          "0.435",
          "0.48",
          "0.49",
          "0.506",
          "0.496",
          8.404,
          8.913,
          0.11,
          0,
          80.63,
          85.79,
          89.99,
        ],
      },
      {
        2351: [
          "8125",
          0.175,
          2176000,
          15.8,
          "0.187",
          "0.185",
          "0.19",
          "0.224",
          "0.192",
          37.466,
          0.274,
          0,
          0,
          43.36,
          54.79,
          68.45,
        ],
      },
      {
        2352: [
          "0768",
          0.035,
          0,
          15.8,
          "0.037",
          "0.044",
          "0.054",
          "0.055",
          "0.052",
          0,
          0.445,
          54.67,
          0,
          81.29,
          84.39,
          89.34,
        ],
      },
      {
        2353: [
          "1259",
          0.057,
          18694000,
          15.8,
          "0.054",
          "0.058",
          "0.071",
          "0.073",
          "0.07",
          102,
          1.296,
          14.1,
          0,
          38.71,
          50.49,
          71.28,
        ],
      },
      {
        2354: [
          "0852",
          0.24,
          238000,
          15.7,
          "0.235",
          "0.242",
          "0.277",
          "0.292",
          "0.271",
          5.498,
          5.096,
          52.87,
          0,
          77.44,
          86.43,
          92.7,
        ],
      },
      {
        2355: [
          "9958",
          1.06,
          882000,
          15.7,
          "1.219",
          "1.323",
          "1.414",
          "1.448",
          "1.399",
          95.293,
          3.18,
          44.22,
          0,
          72.77,
          84.24,
          93.42,
        ],
      },
      {
        2356: [
          "0007",
          0.091,
          26736000,
          15.6,
          "0.104",
          "0.122",
          "0.125",
          "0.126",
          "0.125",
          243,
          3.64,
          9.65,
          0,
          38.72,
          55.77,
          72.42,
        ],
      },
      {
        2357: [
          "1762",
          0.48,
          1270000,
          15.6,
          "0.487",
          "0.527",
          "0.624",
          "0.718",
          "0.611",
          61.321,
          7.131,
          17.52,
          0,
          41.93,
          53.87,
          70.99,
        ],
      },
      {
        2358: [
          "0491",
          0.05,
          540000,
          15.6,
          "0.05",
          "0.056",
          "0.067",
          "0.071",
          "0.065",
          2.758,
          1.607,
          57.79,
          0,
          79.21,
          84.05,
          88.59,
        ],
      },
      {
        2359: [
          "1928",
          17.1,
          9707010,
          15.5,
          "17.518",
          "18.1",
          "22.479",
          "25.846",
          "21.519",
          16717,
          1383.9,
          52.72,
          0.85,
          85.19,
          92.89,
          95.88,
        ],
      },
      {
        2360: [
          "3069",
          16.4,
          27100,
          15.5,
          "16.023",
          "17.834",
          "21.511",
          "22.528",
          "21.108",
          45.204,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        2361: [
          "1282",
          0.199,
          22800000,
          15.5,
          "0.199",
          "0.224",
          "0.238",
          "0.242",
          "0.237",
          457,
          62.4,
          0.16,
          15.9,
          69.27,
          85.17,
          95.41,
        ],
      },
      {
        2362: [
          "1927",
          0.255,
          450000,
          15.4,
          "0.259",
          "0.255",
          "0.3",
          "0.337",
          "0.291",
          11.527,
          2.02,
          72.76,
          0,
          80.21,
          84.1,
          89.5,
        ],
      },
      {
        2363: [
          "8430",
          0.105,
          480000,
          15.4,
          "0.109",
          "0.116",
          "0.13",
          "0.154",
          "0.128",
          5.68,
          0.82,
          1.65,
          0,
          52.27,
          61.77,
          77.34,
        ],
      },
      {
        2364: [
          "1380",
          0.022,
          67000,
          15.4,
          "0.023",
          "0.026",
          "0.029",
          "0.03",
          "0.028",
          0.14,
          0.623,
          7.39,
          0,
          32.4,
          41.87,
          59.28,
        ],
      },
      {
        2365: [
          "0738",
          0.62,
          38000,
          15.3,
          "0.62",
          "0.646",
          "0.835",
          "0.881",
          "0.793",
          2.354,
          4.376,
          13.95,
          0,
          73.72,
          81.09,
          89.48,
        ],
      },
      {
        2366: [
          "0009",
          0.039,
          156000,
          15.3,
          "0.039",
          "0.042",
          "0.049",
          "0.054",
          "0.047",
          0.587,
          1.392,
          4.87,
          0,
          66.36,
          73.16,
          81.62,
        ],
      },
      {
        2367: [
          "8371",
          0.82,
          108000,
          15.3,
          "0.872",
          "0.921",
          "1.048",
          "1.083",
          "1.041",
          9.06,
          3.173,
          74.91,
          0,
          82.9,
          87.76,
          95.52,
        ],
      },
      {
        2368: [
          "0476",
          0.087,
          8420000,
          15.2,
          "0.089",
          "0.095",
          "0.114",
          "0.122",
          "0.111",
          75.066,
          7.838,
          17.88,
          0,
          45.34,
          56.92,
          73.68,
        ],
      },
      {
        2369: [
          "3313",
          0.041,
          5330000,
          15.2,
          "0.04",
          "0.042",
          "0.051",
          "0.056",
          "0.05",
          21.452,
          1.791,
          11.59,
          0,
          34.37,
          44.33,
          58.91,
        ],
      },
      {
        2370: [
          "1691",
          13.56,
          4408000,
          15.1,
          "13.188",
          "14.303",
          "17.434",
          "18.714",
          "17.08",
          6039,
          473.8,
          5.25,
          8.11,
          56.55,
          69.23,
          90.52,
        ],
      },
      {
        2371: [
          "1478",
          9.5,
          1818000,
          15.1,
          "9.717",
          "10.735",
          "12.284",
          "12.775",
          "12.227",
          1742,
          112.2,
          53.94,
          9.28,
          73.49,
          83.82,
          92,
        ],
      },
      {
        2372: [
          "0410",
          1.73,
          4380500,
          15.1,
          "1.715",
          "1.773",
          "2.678",
          "2.607",
          "2.594",
          761,
          89.9,
          0.88,
          9.45,
          79.42,
          85.61,
          91.16,
        ],
      },
      {
        2373: [
          "0063",
          0.072,
          0,
          15,
          "0.069",
          "0.078",
          "0.095",
          "0.099",
          "0.094",
          0,
          2.03,
          11.31,
          0,
          78.08,
          82.51,
          89.74,
        ],
      },
      {
        2374: [
          "2696",
          22.8,
          34500,
          15,
          "22.875",
          "26.828",
          "29.153",
          "32.022",
          "28.42",
          80.032,
          37.2,
          1.48,
          0,
          44.19,
          58.88,
          79.71,
        ],
      },
      {
        2375: [
          "2616",
          7.45,
          1472250,
          15,
          "7.64",
          "9.025",
          "11.968",
          "11.663",
          "11.613",
          1110,
          88.4,
          10.65,
          2.8,
          60.04,
          72.67,
          84.87,
        ],
      },
      {
        2376: [
          "0359",
          0.066,
          336000,
          14.9,
          "0.069",
          "0.072",
          "0.08",
          "0.081",
          "0.079",
          2.217,
          0.851,
          0.01,
          0,
          60.17,
          69.78,
          85.17,
        ],
      },
      {
        2377: [
          "2323",
          0.109,
          0,
          14.9,
          "0.117",
          "0.129",
          "0.143",
          "0.16",
          "0.138",
          0,
          2.407,
          59.42,
          0,
          87.74,
          94.3,
          98.7,
        ],
      },
      {
        2378: [
          "1009",
          0.29,
          4000,
          14.9,
          "0.289",
          "0.336",
          "0.356",
          "0.382",
          "0.351",
          0.116,
          3.97,
          3.76,
          0,
          61.64,
          75.38,
          86.51,
        ],
      },
      {
        2379: [
          "1536",
          0.162,
          56000,
          14.8,
          "0.163",
          "0.19",
          "0.203",
          "0.213",
          "0.202",
          0.907,
          0.616,
          0.06,
          0,
          68,
          76.06,
          86.37,
        ],
      },
      {
        2380: [
          "8103",
          1.8,
          1651500,
          14.8,
          "1.861",
          "1.766",
          "2.167",
          "2.253",
          "2.15",
          297,
          1.942,
          5.45,
          0,
          53.09,
          63.39,
          79.4,
        ],
      },
      {
        2381: [
          "8026",
          0.41,
          23000,
          14.8,
          "0.427",
          "0.436",
          "0.518",
          "0.545",
          "0.507",
          0.943,
          5.975,
          5.04,
          0,
          64.83,
          76.62,
          91.68,
        ],
      },
      {
        2382: [
          "0880",
          4.99,
          13942500,
          14.7,
          "5.105",
          "5.468",
          "6.365",
          "7.153",
          "6.172",
          7017,
          283.5,
          8.83,
          3.73,
          73.6,
          78.53,
          86.92,
        ],
      },
      {
        2383: [
          "1859",
          0.63,
          6110000,
          14.7,
          "0.655",
          "0.69",
          "0.848",
          "0.894",
          "0.837",
          386,
          10,
          0.16,
          0,
          34.18,
          53.42,
          74.75,
        ],
      },
      {
        2384: [
          "0583",
          0.3,
          448000,
          14.6,
          "0.301",
          "0.373",
          "0.406",
          "0.413",
          "0.401",
          13.46,
          4.703,
          57.42,
          0,
          91.05,
          94.56,
          97.27,
        ],
      },
      {
        2385: [
          "6628",
          9.8,
          31500,
          14.6,
          "9.368",
          "8.479",
          "-",
          "-",
          "-",
          30.931,
          43.6,
          88.74,
          0,
          93.84,
          96.42,
          98.84,
        ],
      },
      {
        2386: [
          "8153",
          0.059,
          4670000,
          14.6,
          "0.06",
          "0.07",
          "0.074",
          "0.078",
          "0.074",
          27.292,
          4.213,
          0.32,
          0,
          30.38,
          43.11,
          63.57,
        ],
      },
      {
        2387: [
          "0602",
          0.1,
          0,
          14.5,
          "0.101",
          "0.112",
          "0.131",
          "0.141",
          "0.129",
          0,
          1.038,
          72.5,
          0,
          82.29,
          86.37,
          90.81,
        ],
      },
      {
        2388: [
          "1588",
          8.9,
          122200,
          14.5,
          "8.956",
          "9.282",
          "11.208",
          "14.076",
          "10.644",
          109,
          7.342,
          0.02,
          0,
          44.44,
          59.29,
          79.33,
        ],
      },
      {
        2389: [
          "0382",
          4.54,
          530000,
          14.5,
          "4.539",
          "4.837",
          "5.56",
          "6.208",
          "5.424",
          241,
          48.6,
          69.98,
          0,
          76.87,
          81.01,
          88.09,
        ],
      },
      {
        2390: [
          "3889",
          0.082,
          32000,
          14.4,
          "0.086",
          "0.09",
          "0.104",
          "0.105",
          "0.102",
          0.277,
          1.253,
          64.14,
          0,
          76.51,
          83.16,
          89.43,
        ],
      },
      {
        2391: [
          "8237",
          0.016,
          1308000,
          14.4,
          "0.016",
          "0.02",
          "0.025",
          "0.025",
          "0.024",
          2.091,
          0.558,
          0.33,
          0,
          86.29,
          92.4,
          94.56,
        ],
      },
      {
        2392: [
          "9899",
          140.5,
          6050,
          14.4,
          "144.78",
          "-",
          "-",
          "-",
          "-",
          85.694,
          291.8,
          91.14,
          0,
          98.01,
          99.4,
          99.8,
        ],
      },
      {
        2393: [
          "0559",
          0.023,
          16180000,
          14.3,
          "0.026",
          "0.032",
          "0.043",
          "0.044",
          "0.042",
          37.267,
          3.61,
          1.54,
          0,
          65.02,
          72.22,
          83.96,
        ],
      },
      {
        2394: [
          "1938",
          0.27,
          16000,
          14.3,
          "0.274",
          "0.287",
          "0.345",
          "0.35",
          "0.343",
          0.442,
          2.73,
          47.36,
          0,
          74.66,
          82.74,
          88.47,
        ],
      },
      {
        2395: [
          "1858",
          13.32,
          666500,
          14.2,
          "13.902",
          "16.852",
          "19.626",
          "20.518",
          "19.051",
          909,
          12.7,
          0.01,
          0,
          30.75,
          45.44,
          66.26,
        ],
      },
      {
        2396: [
          "1499",
          0.249,
          960000,
          14.2,
          "0.256",
          "0.258",
          "0.297",
          "0.358",
          "0.292",
          23.972,
          13.3,
          0.01,
          0,
          77.7,
          85.23,
          92.48,
        ],
      },
      {
        2397: [
          "1612",
          0.94,
          236000,
          14.2,
          "0.981",
          "0.995",
          "1.215",
          "1.404",
          "1.163",
          22.336,
          6.157,
          61.17,
          0,
          75.22,
          80.75,
          86.7,
        ],
      },
      {
        2398: [
          "8067",
          0.75,
          0,
          14.1,
          "0.75",
          "0.836",
          "0.928",
          "1.072",
          "0.898",
          0,
          1.35,
          0,
          0,
          91.1,
          96.53,
          98.09,
        ],
      },
      {
        2399: [
          "0915",
          0.093,
          43000,
          14.1,
          "0.096",
          "0.144",
          "0.255",
          "0.214",
          "0.252",
          0.399,
          1.404,
          53.81,
          0,
          69.32,
          75.54,
          87.09,
        ],
      },
      {
        2400: [
          "6133",
          0.225,
          15000,
          14.1,
          "0.256",
          "0.297",
          "0.288",
          "0.296",
          "0.292",
          0.337,
          1.912,
          0.02,
          0,
          80.18,
          84.9,
          90.03,
        ],
      },
      {
        2401: [
          "0530",
          0.18,
          788000,
          14,
          "0.171",
          "0.189",
          "0.253",
          "0.265",
          "0.247",
          13.485,
          12.5,
          40.38,
          0.03,
          81.31,
          88.19,
          94.86,
        ],
      },
      {
        2402: [
          "0950",
          3.2,
          180500,
          14,
          "3.28",
          "3.392",
          "3.91",
          "4.228",
          "3.849",
          58.563,
          18.8,
          2.94,
          0.15,
          49.56,
          70.87,
          86.57,
        ],
      },
      {
        2403: [
          "0526",
          0.04,
          392000,
          14,
          "0.041",
          "0.045",
          "0.055",
          "0.061",
          "0.053",
          1.575,
          3.218,
          6.43,
          0,
          60.28,
          70.04,
          82.79,
        ],
      },
      {
        2404: [
          "1449",
          0.445,
          689000,
          13.9,
          "0.445",
          "0.482",
          "0.552",
          "0.599",
          "0.538",
          30.909,
          2.967,
          0,
          0,
          81.38,
          85.5,
          92.67,
        ],
      },
      {
        2405: [
          "6186",
          11.32,
          13130000,
          13.9,
          "10.712",
          "10.967",
          "13.555",
          "15.625",
          "13.201",
          14821,
          1009.2,
          24.43,
          6.47,
          62.5,
          79.11,
          95,
        ],
      },
      {
        2406: [
          "1501",
          19.98,
          69400,
          13.9,
          "21.308",
          "24.033",
          "28.307",
          "29.563",
          "27.292",
          135,
          20.8,
          0,
          0,
          73.19,
          84.67,
          94.13,
        ],
      },
      {
        2407: [
          "2129",
          0.121,
          1020000,
          13.8,
          "0.125",
          "0.133",
          "0.145",
          "0.154",
          "0.142",
          12.507,
          1.512,
          75,
          0,
          81.67,
          85.79,
          90.19,
        ],
      },
      {
        2408: [
          "0058",
          0.25,
          0,
          13.8,
          "0.26",
          "0.265",
          "0.34",
          "0.346",
          "0.33",
          0,
          0.449,
          36.51,
          0,
          63.1,
          72.84,
          80.68,
        ],
      },
      {
        2409: [
          "3828",
          0.42,
          321000,
          13.7,
          "0.422",
          "0.456",
          "0.542",
          "0.582",
          "0.532",
          13.525,
          3.084,
          32.13,
          0,
          46.15,
          54.62,
          70.95,
        ],
      },
      {
        2410: [
          "1720",
          0.305,
          0,
          13.7,
          "0.322",
          "0.341",
          "0.396",
          "0.421",
          "0.389",
          0,
          3.355,
          0.29,
          0,
          86.54,
          91.75,
          96.39,
        ],
      },
      {
        2411: [
          "0812",
          0.051,
          444000,
          13.7,
          "0.05",
          "0.056",
          "0.062",
          "0.066",
          "0.062",
          2.229,
          1.868,
          0.04,
          0,
          80.66,
          83.59,
          88.57,
        ],
      },
      {
        2412: [
          "0657",
          0.059,
          0,
          13.6,
          "0.06",
          "0.064",
          "0.072",
          "0.077",
          "0.07",
          0,
          1.148,
          73.75,
          0,
          84.86,
          89.36,
          93.98,
        ],
      },
      {
        2413: [
          "8245",
          0.053,
          940000,
          13.6,
          "0.053",
          "0.065",
          "0.073",
          "0.074",
          "0.071",
          5.487,
          0.317,
          0.05,
          0,
          75.14,
          81,
          88.2,
        ],
      },
      {
        2414: [
          "8107",
          0.21,
          0,
          13.6,
          "0.226",
          "0.246",
          "0.283",
          "0.296",
          "0.28",
          0,
          0.21,
          75.64,
          0,
          83.32,
          87.02,
          91.41,
        ],
      },
      {
        2415: [
          "0095",
          1.38,
          5996000,
          13.5,
          "1.43",
          "1.5",
          "1.743",
          "1.844",
          "1.725",
          840,
          70.3,
          2.18,
          3.75,
          50.73,
          70.47,
          93.42,
        ],
      },
      {
        2416: [
          "0132",
          0.34,
          110000,
          13.5,
          "0.376",
          "0.445",
          "0.465",
          "0.488",
          "0.458",
          4.14,
          5.822,
          78.45,
          0,
          89.09,
          91.66,
          94.89,
        ],
      },
      {
        2417: [
          "8181",
          0.113,
          0,
          13.5,
          "0.112",
          "0.13",
          "0.16",
          "0.168",
          "0.158",
          0,
          1.16,
          14.24,
          0,
          83.82,
          89.31,
          92.36,
        ],
      },
      {
        2418: [
          "1238",
          4.06,
          3496690,
          13.4,
          "4.102",
          "4.489",
          "5.516",
          "6.129",
          "5.407",
          1447,
          168.1,
          1.18,
          1.59,
          65.31,
          76.84,
          89.12,
        ],
      },
      {
        2419: [
          "0260",
          0.018,
          2430000,
          13.4,
          "0.02",
          "0.021",
          "0.024",
          "0.026",
          "0.024",
          4.283,
          1.07,
          2.29,
          0,
          45.94,
          54.87,
          66.4,
        ],
      },
      {
        2420: [
          "0482",
          0.255,
          0,
          13.4,
          "0.277",
          "0.287",
          "0.364",
          "0.374",
          "0.348",
          0,
          1.255,
          4.26,
          0,
          80.41,
          86.02,
          91.59,
        ],
      },
      {
        2421: [
          "9909",
          14.28,
          734500,
          13.3,
          "14.706",
          "15.636",
          "19.97",
          "22.075",
          "19.403",
          1073,
          91.8,
          47.17,
          8.1,
          76.53,
          90.12,
          97.23,
        ],
      },
      {
        2422: [
          "8123",
          0.011,
          1140000,
          13.3,
          "0.012",
          "0.014",
          "0.016",
          "0.017",
          "0.016",
          1.253,
          0.852,
          24.12,
          0,
          46.63,
          59.01,
          71.91,
        ],
      },
      {
        2423: [
          "1995",
          12.14,
          3984610,
          13.2,
          "11.724",
          "13.116",
          "15.244",
          "16.26",
          "15.055",
          4926,
          212.9,
          0,
          10.43,
          60.55,
          82.04,
          95.56,
        ],
      },
      {
        2424: [
          "1861",
          1.98,
          60000,
          13.2,
          "2.134",
          "2.181",
          "2.928",
          "3.258",
          "2.871",
          11.626,
          4.632,
          0.02,
          0,
          82.15,
          86.23,
          92.06,
        ],
      },
      {
        2425: [
          "1959",
          0.59,
          4000,
          13.2,
          "0.59",
          "0.678",
          "0.758",
          "0.77",
          "0.756",
          0.236,
          2.981,
          0,
          0,
          85.86,
          92.53,
          95.71,
        ],
      },
      {
        2426: [
          "1716",
          0.46,
          128000,
          13.1,
          "0.465",
          "0.52",
          "0.756",
          "0.79",
          "0.726",
          5.794,
          1.242,
          67.51,
          0,
          77.1,
          83.83,
          90.63,
        ],
      },
      {
        2427: [
          "0907",
          0.61,
          13480000,
          13.1,
          "0.63",
          "0.885",
          "1.203",
          "1.278",
          "1.178",
          797,
          5.324,
          12.85,
          0,
          58.46,
          66.99,
          80.48,
        ],
      },
      {
        2428: [
          "0290",
          0.223,
          0,
          13.1,
          "0.223",
          "0.24",
          "0.276",
          "0.31",
          "0.271",
          0,
          2.041,
          43.4,
          0,
          65.86,
          72.6,
          81.39,
        ],
      },
      {
        2429: [
          "9988",
          132.2,
          36032000,
          13,
          "123.35",
          "130.898",
          "163.012",
          "176.969",
          "159.607",
          477000,
          28670.6,
          46.59,
          0,
          81.06,
          93.64,
          96.72,
        ],
      },
      {
        2430: [
          "2858",
          1.39,
          3469500,
          13,
          "1.289",
          "1.36",
          "1.666",
          "1.9",
          "1.627",
          480,
          90.6,
          35.94,
          1.09,
          68.32,
          82.35,
          92.12,
        ],
      },
      {
        2431: [
          "1745",
          0.54,
          160000,
          13,
          "0.543",
          "0.562",
          "0.737",
          "0.85",
          "0.708",
          8.606,
          7.904,
          36.63,
          0,
          65.49,
          77.87,
          91.02,
        ],
      },
      {
        2432: [
          "1066",
          10.16,
          6428000,
          12.9,
          "9.965",
          "10.731",
          "13.399",
          "14.187",
          "13.099",
          6533,
          459.4,
          0,
          10.44,
          76.55,
          87.38,
          97.99,
        ],
      },
      {
        2433: [
          "2882",
          0.265,
          0,
          12.9,
          "0.276",
          "0.293",
          "0.412",
          "0.458",
          "0.356",
          0,
          0.715,
          53.81,
          0,
          70.89,
          79.19,
          87.95,
        ],
      },
      {
        2434: [
          "0451",
          0.206,
          43145400,
          12.9,
          "0.219",
          "0.266",
          "0.305",
          "0.29",
          "0.306",
          896,
          43.4,
          0.03,
          1.15,
          68.13,
          75.16,
          83.49,
        ],
      },
      {
        2435: [
          "2256",
          8.48,
          68000,
          12.8,
          "8.747",
          "9.8",
          "-",
          "-",
          "-",
          57.636,
          59.5,
          65.05,
          0,
          80.77,
          89.4,
          96.62,
        ],
      },
      {
        2436: [
          "9990",
          8.75,
          168000,
          12.8,
          "8.627",
          "9.749",
          "10.316",
          "11.36",
          "10.067",
          147,
          71.2,
          0,
          1.8,
          70.42,
          85.35,
          94.26,
        ],
      },
      {
        2437: [
          "0511",
          4.84,
          569600,
          12.7,
          "4.759",
          "4.908",
          "6.142",
          "6.686",
          "6.034",
          274,
          21.1,
          27.44,
          0.08,
          66.34,
          74.05,
          84.45,
        ],
      },
      {
        2438: [
          "8420",
          0.16,
          0,
          12.7,
          "0.161",
          "0.213",
          "0.228",
          "0.27",
          "0.223",
          0,
          1.152,
          2.08,
          0,
          56.55,
          75.18,
          88,
        ],
      },
      {
        2439: [
          "2680",
          0.5,
          50000,
          12.7,
          "0.488",
          "0.543",
          "0.687",
          "0.712",
          "0.685",
          2.275,
          2,
          0,
          0,
          79.7,
          82.92,
          87.91,
        ],
      },
      {
        2440: [
          "9186",
          7.185,
          0,
          12.6,
          "6.823",
          "7.595",
          "8.825",
          "9.782",
          "8.669",
          0,
          0,
          89.44,
          0,
          96.89,
          99.11,
          100,
        ],
      },
      {
        2441: [
          "9069",
          2.116,
          0,
          12.6,
          "2.053",
          "2.29",
          "-",
          "-",
          "-",
          0,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        2442: [
          "3186",
          56,
          0,
          12.6,
          "53.236",
          "59.141",
          "68.634",
          "76.038",
          "67.391",
          0,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        2443: [
          "0493",
          0.66,
          52478100,
          12.5,
          "0.66",
          "0.692",
          "0.817",
          "0.936",
          "0.789",
          3459,
          222.7,
          33.48,
          11.15,
          50.33,
          61.96,
          80.11,
        ],
      },
      {
        2444: [
          "8425",
          0.355,
          984000,
          12.5,
          "0.332",
          "0.329",
          "0.475",
          "0.666",
          "0.439",
          35.856,
          1.42,
          0.77,
          0,
          75.01,
          84.27,
          93.05,
        ],
      },
      {
        2445: [
          "1661",
          0.14,
          0,
          12.5,
          "0.144",
          "0.164",
          "0.189",
          "0.204",
          "0.187",
          0,
          2.23,
          1.13,
          0.38,
          60.37,
          68.56,
          76.84,
        ],
      },
      {
        2446: [
          "6618",
          64.6,
          5160980,
          12.4,
          "60.285",
          "66.535",
          "78.336",
          "86.552",
          "76.455",
          33654,
          2063.7,
          65.49,
          1.06,
          77.47,
          83.64,
          93.41,
        ],
      },
      {
        2447: [
          "0819",
          8.26,
          1506000,
          12.4,
          "8.214",
          "8.406",
          "10.736",
          "11.631",
          "10.542",
          1258,
          93,
          30.28,
          19.49,
          64.2,
          77.92,
          89.19,
        ],
      },
      {
        2448: [
          "1229",
          0.08,
          792000,
          12.4,
          "0.087",
          "0.093",
          "0.108",
          "0.107",
          "0.109",
          6.315,
          0.612,
          71.45,
          0,
          80.21,
          85.36,
          90.58,
        ],
      },
      {
        2449: [
          "3919",
          0.205,
          0,
          12.3,
          "0.195",
          "0.223",
          "0.248",
          "0.285",
          "0.243",
          0,
          0.738,
          48.37,
          0,
          60.46,
          69.98,
          81.72,
        ],
      },
      {
        2450: [
          "0678",
          0.32,
          271494000,
          12.3,
          "0.72",
          "0.849",
          "0.678",
          "0.646",
          "0.669",
          10106,
          27.1,
          5.65,
          0,
          83.84,
          87.78,
          93.82,
        ],
      },
      {
        2451: [
          "0698",
          0.246,
          10360000,
          12.2,
          "0.247",
          "0.257",
          "0.306",
          "0.372",
          "0.293",
          258,
          23.9,
          7.35,
          0.68,
          59.43,
          66.84,
          78.03,
        ],
      },
      {
        2452: [
          "8130",
          0.041,
          200000,
          12.2,
          "0.041",
          "0.041",
          "0.048",
          "0.055",
          "0.046",
          0.768,
          1.493,
          2.46,
          0,
          55.08,
          71.26,
          85.15,
        ],
      },
      {
        2453: [
          "2008",
          0.415,
          372000,
          12.2,
          "0.437",
          "0.479",
          "0.57",
          "0.619",
          "0.556",
          15.285,
          20.7,
          66.64,
          3.82,
          80.68,
          85.78,
          90.55,
        ],
      },
      {
        2454: [
          "8448",
          0.023,
          120000,
          12.1,
          "0.023",
          "0.025",
          "0.027",
          "0.028",
          "0.027",
          0.276,
          0.207,
          36.6,
          0,
          67.48,
          75.56,
          84.36,
        ],
      },
      {
        2455: [
          "9986",
          0.415,
          450000,
          12.1,
          "0.442",
          "0.463",
          "0.55",
          "0.619",
          "0.534",
          17.976,
          3.32,
          71.85,
          0,
          80.65,
          86.22,
          94.36,
        ],
      },
      {
        2456: [
          "1137",
          7.78,
          2803790,
          12.1,
          "8.34",
          "9.135",
          "10.075",
          "10.156",
          "10.105",
          2191,
          71.3,
          30.7,
          0.49,
          49.88,
          59.64,
          73.14,
        ],
      },
      {
        2457: [
          "8211",
          0.054,
          80000,
          12,
          "0.063",
          "0.063",
          "0.072",
          "0.078",
          "0.07",
          0.471,
          0.257,
          1.77,
          0,
          65.02,
          71.92,
          82.85,
        ],
      },
      {
        2458: [
          "6611",
          3.11,
          474000,
          12,
          "3.053",
          "3.796",
          "-",
          "-",
          "-",
          144,
          21,
          64.7,
          0,
          83.22,
          89.22,
          98.94,
        ],
      },
      {
        2459: [
          "8156",
          0.153,
          1145000,
          12,
          "0.162",
          "0.192",
          "0.216",
          "0.232",
          "0.214",
          16.931,
          6.943,
          18.46,
          0,
          44.25,
          56.94,
          73.03,
        ],
      },
      {
        2460: [
          "0365",
          0.67,
          638000,
          11.9,
          "0.574",
          "0.699",
          "0.857",
          "0.936",
          "0.836",
          42.708,
          9.748,
          68.01,
          0,
          84.67,
          91.76,
          94.99,
        ],
      },
      {
        2461: [
          "1408",
          0.244,
          536000,
          11.9,
          "0.241",
          "0.268",
          "0.316",
          "0.329",
          "0.315",
          12.724,
          1.22,
          75,
          0,
          83.26,
          86.42,
          90.38,
        ],
      },
      {
        2462: [
          "0872",
          0.075,
          1008000,
          11.9,
          "0.077",
          "0.093",
          "0.102",
          "0.104",
          "0.101",
          7.667,
          1.548,
          27.66,
          0,
          58.84,
          72.69,
          83.36,
        ],
      },
      {
        2463: [
          "2001",
          3.1,
          2669000,
          11.8,
          "3.159",
          "3.364",
          "3.968",
          "4.346",
          "3.893",
          826,
          49.1,
          0,
          12.07,
          48.91,
          69.14,
          90.96,
        ],
      },
      {
        2464: [
          "2192",
          22.75,
          646000,
          11.8,
          "23.022",
          "34.829",
          "-",
          "-",
          "-",
          1455,
          162.2,
          71.76,
          0.02,
          92.19,
          96.71,
          99.36,
        ],
      },
      {
        2465: [
          "3174",
          4.616,
          24900,
          11.7,
          "4.527",
          "5.17",
          "-",
          "-",
          "-",
          11.694,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        2466: [
          "0357",
          21.55,
          452000,
          11.7,
          "22.5",
          "24.813",
          "27.654",
          "29.102",
          "27.362",
          979,
          48.8,
          0.21,
          0,
          55.69,
          71.01,
          86.1,
        ],
      },
      {
        2467: [
          "1315",
          0.026,
          4806000,
          11.7,
          "0.028",
          "0.03",
          "0.029",
          "0.036",
          "0.029",
          12.815,
          1.95,
          22.51,
          10.65,
          47.02,
          62.74,
          80.25,
        ],
      },
      {
        2468: [
          "1128",
          6.26,
          7903160,
          11.6,
          "6.372",
          "6.599",
          "8.363",
          "9.823",
          "7.993",
          4971,
          325.9,
          72.06,
          1.11,
          85.91,
          90.15,
          94.05,
        ],
      },
      {
        2469: [
          "6185",
          165,
          737088,
          11.6,
          "166.73",
          "164.974",
          "255.151",
          "272.45",
          "247.023",
          12326,
          218.9,
          26.08,
          8.27,
          58.95,
          75.49,
          89.51,
        ],
      },
      {
        2470: [
          "0727",
          0.129,
          0,
          11.6,
          "0.127",
          "0.139",
          "0.152",
          "0.208",
          "0.141",
          0,
          4.425,
          8.72,
          0,
          72.48,
          83.88,
          95.28,
        ],
      },
      {
        2471: [
          "2048",
          1.77,
          36678200,
          11.5,
          "1.905",
          "1.779",
          "2.796",
          "4.081",
          "2.488",
          6723,
          30.9,
          21.78,
          14.51,
          69.66,
          79.24,
          86.66,
        ],
      },
      {
        2472: [
          "6919",
          8.6,
          170500,
          11.5,
          "8.395",
          "8.378",
          "10.574",
          "14.227",
          "9.727",
          146,
          13.4,
          37.03,
          0,
          63.12,
          77.45,
          91.31,
        ],
      },
      {
        2473: [
          "0911",
          0.072,
          0,
          11.5,
          "0.073",
          "0.078",
          "0.095",
          "0.103",
          "0.093",
          0,
          1.22,
          1.35,
          5.12,
          75.49,
          81.02,
          87.85,
        ],
      },
      {
        2474: [
          "0863",
          9.83,
          231500,
          11.4,
          "9.082",
          "10.853",
          "13.892",
          "14.829",
          "13.372",
          235,
          41.6,
          16.57,
          0,
          56.44,
          70.44,
          85.29,
        ],
      },
      {
        2475: [
          "8555",
          0.24,
          870000,
          11.4,
          "0.707",
          "-",
          "-",
          "-",
          "-",
          21.829,
          0.691,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        2476: [
          "8489",
          0.3,
          0,
          11.3,
          "0.312",
          "0.334",
          "0.396",
          "0.453",
          "0.383",
          0,
          0.9,
          75.01,
          0,
          83.73,
          88.17,
          91.98,
        ],
      },
      {
        2477: [
          "1617",
          0.14,
          2744000,
          11.3,
          "0.142",
          "0.17",
          "0.21",
          "0.242",
          "0.199",
          37.932,
          2.07,
          0.01,
          0,
          70.68,
          77.49,
          85.98,
        ],
      },
      {
        2478: [
          "1297",
          0.84,
          238000,
          11.3,
          "0.833",
          "0.887",
          "1.095",
          "1.245",
          "1.068",
          20.309,
          10.2,
          8.1,
          0,
          54.41,
          72.59,
          84.78,
        ],
      },
      {
        2479: [
          "1293",
          0.63,
          2139500,
          11.2,
          "0.674",
          "0.742",
          "0.995",
          "0.939",
          "0.966",
          136,
          17.8,
          0.01,
          0.72,
          83.05,
          86.42,
          92.66,
        ],
      },
      {
        2480: [
          "8057",
          0.099,
          324000,
          11.2,
          "0.097",
          "0.106",
          "0.126",
          "0.135",
          "0.124",
          3.12,
          6.169,
          1.66,
          0,
          67.05,
          79.12,
          86.36,
        ],
      },
      {
        2481: [
          "0082",
          0.32,
          4109000,
          11.2,
          "0.32",
          "0.346",
          "0.484",
          "0.534",
          "0.464",
          130,
          14.4,
          10.58,
          0,
          35.73,
          51.06,
          67.72,
        ],
      },
      {
        2482: [
          "1789",
          6.89,
          6234000,
          11.1,
          "6.417",
          "6.717",
          "9.234",
          "9.855",
          "9.069",
          4399,
          76.8,
          0.01,
          7.5,
          72.9,
          79.71,
          91.13,
        ],
      },
      {
        2483: [
          "2235",
          20,
          20100,
          11.1,
          "21.33",
          "26.091",
          "-",
          "-",
          "-",
          40.401,
          13.1,
          0.01,
          0,
          77.19,
          91.05,
          97.75,
        ],
      },
      {
        2484: [
          "0326",
          0.85,
          0,
          11.1,
          "0.848",
          "0.942",
          "1.057",
          "1.135",
          "1.035",
          0,
          21.1,
          0.04,
          0,
          89.79,
          92.73,
          96.33,
        ],
      },
      {
        2485: [
          "3001",
          219.4,
          10770,
          11,
          "244.905",
          "269.766",
          "324.735",
          "-",
          "319.703",
          237,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        2486: [
          "1011",
          0.067,
          173000,
          11,
          "0.068",
          "0.071",
          "0.09",
          "0.099",
          "0.087",
          1.176,
          1.276,
          0.03,
          0,
          57.02,
          66.97,
          77.39,
        ],
      },
      {
        2487: [
          "8310",
          0.111,
          0,
          11,
          "0.107",
          "0.113",
          "0.141",
          "0.159",
          "0.135",
          0,
          1.43,
          0.87,
          0,
          69.5,
          78.99,
          88.4,
        ],
      },
      {
        2488: [
          "3692",
          18.16,
          1552830,
          10.9,
          "18.458",
          "17.885",
          "22.794",
          "25.627",
          "22.098",
          2845,
          1075.4,
          83.11,
          0.32,
          91.94,
          95.65,
          99.08,
        ],
      },
      {
        2489: [
          "1312",
          0.042,
          5136000,
          10.9,
          "0.048",
          "0.055",
          "0.063",
          "0.064",
          "0.062",
          21.971,
          2.347,
          5.52,
          0,
          82.22,
          85.17,
          90.2,
        ],
      },
      {
        2490: [
          "2051",
          0.54,
          206500,
          10.8,
          "0.542",
          "0.594",
          "0.865",
          "0.971",
          "0.829",
          11.103,
          7.335,
          4.28,
          1.26,
          45.21,
          62.81,
          80.73,
        ],
      },
      {
        2491: [
          "1105",
          0.52,
          148000,
          10.8,
          "0.508",
          "0.514",
          "0.736",
          "0.773",
          "0.722",
          7.626,
          4.579,
          21.79,
          0,
          54.96,
          63.42,
          77.85,
        ],
      },
      {
        2492: [
          "0139",
          0.01,
          210000,
          10.8,
          "0.01",
          "0.01",
          "0.013",
          "0.014",
          "0.012",
          0.21,
          1.597,
          13,
          0,
          40.39,
          57.91,
          72.8,
        ],
      },
      {
        2493: [
          "0754",
          16.34,
          1364540,
          10.7,
          "16.322",
          "17.418",
          "24.192",
          "25.906",
          "23.469",
          2268,
          389.2,
          9.28,
          2.1,
          67.65,
          80.7,
          91.33,
        ],
      },
      {
        2494: [
          "9698",
          44.2,
          941749,
          10.7,
          "42.9",
          "50.313",
          "58.14",
          "62.401",
          "57.257",
          4183,
          660.8,
          31.22,
          0,
          91.73,
          95.97,
          98.88,
        ],
      },
      {
        2495: [
          "6996",
          9.17,
          435500,
          10.7,
          "9.368",
          "10.58",
          "12.956",
          "13.843",
          "12.627",
          400,
          61.2,
          16.07,
          1.96,
          67.28,
          81.78,
          91.9,
        ],
      },
      {
        2496: [
          "1157",
          5.25,
          9515200,
          10.6,
          "5.173",
          "5.184",
          "6.677",
          "7.583",
          "6.557",
          5059,
          83,
          0.19,
          4.08,
          55.46,
          70.06,
          80.26,
        ],
      },
      {
        2497: [
          "1458",
          5.37,
          6741100,
          10.6,
          "5.513",
          "6.506",
          "7.441",
          "7.907",
          "7.228",
          3628,
          127.9,
          13.99,
          15.72,
          42.9,
          63.83,
          88.56,
        ],
      },
      {
        2498: [
          "9001",
          28.1,
          1500,
          10.6,
          "31.45",
          "34.591",
          "41.714",
          "-",
          "41.033",
          32.955,
          0,
          54.56,
          0,
          86.92,
          97.53,
          99.88,
        ],
      },
      {
        2499: [
          "2216",
          8.75,
          41000,
          10.5,
          "8.749",
          "9.538",
          "-",
          "-",
          "-",
          35.887,
          46,
          72.8,
          0,
          85.01,
          91.83,
          98.14,
        ],
      },
      {
        2500: [
          "1269",
          0.2,
          2488000,
          10.5,
          "0.208",
          "0.226",
          "0.25",
          "0.27",
          "0.248",
          51.432,
          2.69,
          28.57,
          25.06,
          57.43,
          71.02,
          86.43,
        ],
      },
      {
        2501: [
          "6098",
          43.2,
          17712300,
          10.5,
          "43.375",
          "49.997",
          "60.098",
          "64.585",
          "58.978",
          77484,
          1454.1,
          34.42,
          7.84,
          66.86,
          79.09,
          94.6,
        ],
      },
      {
        2502: [
          "8627",
          0.168,
          2696000,
          10.4,
          "0.186",
          "0.205",
          "0.235",
          "0.244",
          "0.232",
          47.988,
          1.344,
          52.51,
          0,
          70.89,
          75.41,
          83.5,
        ],
      },
      {
        2503: [
          "0524",
          0.094,
          690000,
          10.4,
          "0.084",
          "0.094",
          "0.125",
          "0.143",
          "0.122",
          6.225,
          1.481,
          1.42,
          0,
          70.05,
          73.75,
          80.54,
        ],
      },
      {
        2504: [
          "6877",
          0.115,
          70000,
          10.3,
          "0.117",
          "0.126",
          "0.164",
          "0.175",
          "-",
          0.828,
          2.338,
          73.81,
          0,
          83.48,
          89.81,
          93.98,
        ],
      },
      {
        2505: [
          "0227",
          0.199,
          120000,
          10.3,
          "0.203",
          "0.222",
          "0.269",
          "0.284",
          "0.264",
          2.379,
          3.114,
          36.18,
          0,
          53.28,
          66.83,
          77.84,
        ],
      },
      {
        2506: [
          "0717",
          0.082,
          396000,
          10.3,
          "0.08",
          "0.093",
          "0.113",
          "0.118",
          "0.111",
          3.135,
          5.527,
          38.55,
          0,
          55.87,
          68.27,
          81.33,
        ],
      },
      {
        2507: [
          "0352",
          0.46,
          0,
          10.2,
          "0.46",
          "0.499",
          "0.664",
          "0.707",
          "0.649",
          0,
          1.132,
          56.85,
          0,
          76.16,
          88.66,
          95.97,
        ],
      },
      {
        2508: [
          "2363",
          0.169,
          1064750,
          10.2,
          "0.18",
          "0.184",
          "0.224",
          "0.251",
          "0.219",
          16.425,
          1.15,
          27.5,
          0.14,
          55.24,
          63.69,
          74.99,
        ],
      },
      {
        2509: [
          "8160",
          0.062,
          2592000,
          10.2,
          "0.054",
          "0.07",
          "0.08",
          "0.137",
          "0.079",
          15.291,
          0.324,
          5.51,
          0,
          48.82,
          58.92,
          76.18,
        ],
      },
      {
        2510: [
          "0613",
          0.42,
          400,
          10.1,
          "0.457",
          "0.508",
          "0.553",
          "0.604",
          "0.551",
          0,
          3.959,
          0.57,
          0,
          79.98,
          83.71,
          89.09,
        ],
      },
      {
        2511: [
          "8275",
          0.145,
          0,
          10.1,
          "0.144",
          "0.177",
          "0.21",
          "0.226",
          "0.205",
          0,
          0.392,
          0.06,
          0,
          71.06,
          78.75,
          87.34,
        ],
      },
      {
        2512: [
          "2209",
          1.48,
          78000,
          10.1,
          "1.515",
          "1.541",
          "-",
          "-",
          "-",
          11.595,
          5.859,
          31.08,
          0,
          81.35,
          88.42,
          95.91,
        ],
      },
      {
        2513: [
          "2798",
          1.69,
          1020000,
          10,
          "1.716",
          "1.694",
          "1.733",
          "4.685",
          "1.704",
          174,
          27,
          49.61,
          7.03,
          88.44,
          92.06,
          95.98,
        ],
      },
      {
        2514: [
          "0136",
          2.22,
          219702000,
          10,
          "2.657",
          "2.649",
          "3.561",
          "4.939",
          "3.298",
          49246,
          205,
          2.76,
          14.07,
          59.13,
          73.2,
          83.98,
        ],
      },
      {
        2515: [
          "0345",
          15.1,
          6104000,
          10,
          "15.656",
          "16.609",
          "20.236",
          "22.633",
          "19.705",
          9214,
          161.5,
          17.2,
          0.6,
          63.4,
          79.33,
          92.41,
        ],
      },
      {
        2516: [
          "3321",
          2.12,
          0,
          9.9,
          "2.093",
          "2.366",
          "2.874",
          "3.191",
          "2.84",
          0,
          10.6,
          6.06,
          0,
          76.97,
          84.17,
          90.35,
        ],
      },
      {
        2517: [
          "0901",
          0.115,
          40000,
          9.9,
          "0.123",
          "0.116",
          "0.164",
          "0.182",
          "0.159",
          0.46,
          2.489,
          43.14,
          0,
          62.29,
          71.97,
          86.14,
        ],
      },
      {
        2518: [
          "8217",
          0.222,
          54375,
          9.8,
          "0.221",
          "0.212",
          "0.27",
          "0.303",
          "0.256",
          1.174,
          0.67,
          1.3,
          0,
          49.23,
          60.68,
          78.4,
        ],
      },
      {
        2519: [
          "2177",
          4.37,
          376600,
          9.8,
          "4.506",
          "4.768",
          "-",
          "-",
          "-",
          170,
          7.25,
          74.68,
          0,
          89.45,
          92.71,
          97.19,
        ],
      },
      {
        2520: [
          "2400",
          33.45,
          3104520,
          9.8,
          "36.425",
          "43.177",
          "47.538",
          "50.478",
          "46.97",
          10682,
          160.7,
          0,
          18.44,
          73.66,
          80.64,
          91.69,
        ],
      },
      {
        2521: [
          "1167",
          12.12,
          172800,
          9.7,
          "12.89",
          "14.82",
          "18.115",
          "18.354",
          "17.787",
          210,
          93.5,
          7.94,
          0.93,
          56.05,
          75.84,
          93.43,
        ],
      },
      {
        2522: [
          "8255",
          0.126,
          0,
          9.7,
          "0.128",
          "0.135",
          "0.17",
          "0.174",
          "0.169",
          0,
          0.605,
          0,
          0,
          75.09,
          83.42,
          90.31,
        ],
      },
      {
        2523: [
          "1680",
          0.6,
          284000,
          9.7,
          "0.614",
          "0.641",
          "0.853",
          "0.914",
          "0.835",
          17.137,
          37.2,
          15.98,
          1.56,
          51.58,
          72.2,
          89.83,
        ],
      },
      {
        2524: [
          "0503",
          1.22,
          12000,
          9.6,
          "1.22",
          "1.358",
          "2.018",
          "2.018",
          "1.978",
          1.448,
          4.603,
          0.23,
          0,
          78.03,
          82.63,
          87.73,
        ],
      },
      {
        2525: [
          "1351",
          0.78,
          700000,
          9.6,
          "0.747",
          "0.79",
          "0.972",
          "1.196",
          "0.936",
          51.894,
          3.9,
          50.57,
          0,
          83.95,
          89.12,
          93.53,
        ],
      },
      {
        2526: [
          "2266",
          0.31,
          38000,
          9.6,
          "0.312",
          "0.348",
          "0.435",
          "0.46",
          "0.428",
          1.197,
          1.24,
          75.01,
          0,
          86.57,
          89.42,
          93.94,
        ],
      },
      {
        2527: [
          "1024",
          85.45,
          21389700,
          9.5,
          "75.545",
          "85.102",
          "106.497",
          "140.607",
          "100.128",
          181000,
          3611.7,
          19.74,
          4.55,
          50.07,
          70.8,
          92.17,
        ],
      },
      {
        2528: [
          "2399",
          0.58,
          26834000,
          9.5,
          "0.591",
          "0.598",
          "0.641",
          "1.169",
          "0.643",
          1563,
          12.1,
          7.11,
          0,
          41.72,
          55.72,
          73.86,
        ],
      },
      {
        2529: [
          "1578",
          2.05,
          908500,
          9.5,
          "2.096",
          "2.898",
          "3.25",
          "3.308",
          "3.213",
          187,
          36.1,
          45.29,
          0,
          70.05,
          81.89,
          94.59,
        ],
      },
      {
        2530: [
          "0904",
          0.098,
          96000,
          9.4,
          "0.099",
          "0.114",
          "0.161",
          "0.159",
          "0.158",
          0.94,
          0.429,
          0.14,
          0,
          38.06,
          53.11,
          67.88,
        ],
      },
      {
        2531: [
          "8510",
          0.057,
          0,
          9.4,
          "0.062",
          "0.069",
          "0.082",
          "0.09",
          "0.08",
          0,
          0.788,
          0.01,
          0,
          79.02,
          84.14,
          92.39,
        ],
      },
      {
        2532: [
          "1611",
          7.66,
          345500,
          9.3,
          "7.763",
          "8.23",
          "10.392",
          "12.869",
          "10.062",
          265,
          23.5,
          0,
          0,
          74.53,
          80.88,
          88.75,
        ],
      },
      {
        2533: [
          "0585",
          0.62,
          62265,
          9.3,
          "0.618",
          "0.658",
          "0.909",
          "1.04",
          "0.916",
          3.834,
          5.146,
          17.06,
          0,
          51.04,
          68.68,
          87.16,
        ],
      },
      {
        2534: [
          "2789",
          0.033,
          17524000,
          9.3,
          "0.036",
          "0.045",
          "0.05",
          "0.054",
          "0.048",
          57.86,
          2.049,
          2.53,
          0,
          72.31,
          76.66,
          84.11,
        ],
      },
      {
        2535: [
          "8287",
          0.079,
          100000,
          9.2,
          "0.076",
          "0.074",
          "0.128",
          "0.127",
          "0.123",
          0.79,
          0.521,
          0.01,
          0,
          64.96,
          77.42,
          88.73,
        ],
      },
      {
        2536: [
          "1076",
          0.076,
          5102000,
          9.2,
          "0.084",
          "0.095",
          "0.141",
          "0.168",
          "0.132",
          39.981,
          5.433,
          0.01,
          0.26,
          61.05,
          68.04,
          80.8,
        ],
      },
      {
        2537: [
          "6669",
          10.06,
          491000,
          9.2,
          "10.844",
          "11.985",
          "-",
          "-",
          "-",
          498,
          31.5,
          64.47,
          0,
          80.87,
          87.26,
          93.56,
        ],
      },
      {
        2538: [
          "6600",
          8.35,
          235000,
          9.1,
          "8.424",
          "8.918",
          "10.994",
          "12.117",
          "10.726",
          197,
          56.8,
          2.72,
          0.34,
          66.55,
          86.29,
          92.75,
        ],
      },
      {
        2539: [
          "3608",
          0.92,
          42500,
          9.1,
          "0.929",
          "0.949",
          "1.202",
          "1.323",
          "1.188",
          3.752,
          6.739,
          1.34,
          0,
          82.42,
          86.26,
          92.88,
        ],
      },
      {
        2540: [
          "0384",
          15.68,
          6647840,
          9.1,
          "15.808",
          "16.24",
          "20.757",
          "22.981",
          "20.366",
          10436,
          868,
          29.07,
          1.62,
          68.76,
          78.82,
          89.65,
        ],
      },
      {
        2541: [
          "6699",
          229.2,
          142800,
          9,
          "244.6",
          "304.528",
          "-",
          "-",
          "344.72",
          3358,
          385.8,
          58.33,
          0.96,
          88.69,
          93.91,
          98.44,
        ],
      },
      {
        2542: [
          "0509",
          0.028,
          375000,
          9,
          "0.028",
          "0.031",
          "0.039",
          "0.043",
          "0.038",
          1.05,
          1.283,
          18.42,
          0,
          49.25,
          60.43,
          74.44,
        ],
      },
      {
        2543: [
          "1253",
          0.18,
          0,
          9,
          "0.18",
          "0.223",
          "0.262",
          "0.275",
          "0.257",
          0,
          6.016,
          41.13,
          0.19,
          76.82,
          88.62,
          95.08,
        ],
      },
      {
        2544: [
          "8059",
          0.031,
          0,
          8.9,
          "0.031",
          "0.034",
          "0.042",
          "0.047",
          "0.041",
          0,
          0.313,
          0.05,
          0,
          70.65,
          78.19,
          86.78,
        ],
      },
      {
        2545: [
          "8050",
          0.39,
          0,
          8.9,
          "0.406",
          "0.409",
          "0.572",
          "0.668",
          "0.549",
          0,
          5.289,
          41.01,
          0,
          88.02,
          92.17,
          94.96,
        ],
      },
      {
        2546: [
          "2336",
          0.116,
          20000,
          8.8,
          "0.112",
          "0.129",
          "0.166",
          "0.177",
          "0.161",
          0.232,
          2.106,
          56,
          0,
          76.35,
          81.81,
          89.75,
        ],
      },
      {
        2547: [
          "0567",
          0.093,
          2300000,
          8.8,
          "0.089",
          "0.109",
          "0.139",
          "0.148",
          "0.137",
          20.224,
          1.5,
          0.41,
          0,
          71.3,
          76.75,
          83.56,
        ],
      },
      {
        2548: [
          "1873",
          4.38,
          3015500,
          8.8,
          "4.419",
          "4.898",
          "6.629",
          "6.891",
          "6.405",
          1335,
          84.7,
          9.83,
          24.12,
          59.68,
          78.93,
          91.52,
        ],
      },
      {
        2549: [
          "3893",
          0.193,
          285885000,
          8.7,
          "1.534",
          "1.091",
          "0.622",
          "0.53",
          "0.636",
          5830,
          1.39,
          0,
          0,
          36.58,
          49.1,
          69.71,
        ],
      },
      {
        2550: [
          "1753",
          1.3,
          1020800,
          8.7,
          "1.29",
          "1.5",
          "1.805",
          "1.89",
          "1.787",
          133,
          13.9,
          2.09,
          0,
          57.86,
          68.69,
          80.67,
        ],
      },
      {
        2551: [
          "8341",
          0.039,
          200000,
          8.7,
          "0.042",
          "0.044",
          "0.049",
          "0.052",
          "0.048",
          0.781,
          0.312,
          0.06,
          0,
          29.18,
          41.61,
          63.23,
        ],
      },
      {
        2552: [
          "3380",
          6.02,
          17742800,
          8.6,
          "6.068",
          "6.865",
          "8.445",
          "9.434",
          "8.205",
          10827,
          342.5,
          74.7,
          1.52,
          87.27,
          92.55,
          97.27,
        ],
      },
      {
        2553: [
          "0928",
          0.091,
          584000,
          8.6,
          "0.097",
          "0.124",
          "0.124",
          "0.144",
          "0.124",
          5.312,
          1.169,
          14.18,
          0,
          70.46,
          75.32,
          83.39,
        ],
      },
      {
        2554: [
          "0758",
          0.168,
          0,
          8.6,
          "0.169",
          "0.183",
          "0.234",
          "0.268",
          "0.231",
          0,
          2.026,
          0.85,
          0,
          84.13,
          89.88,
          93.98,
        ],
      },
      {
        2555: [
          "2699",
          0.034,
          980000,
          8.5,
          "0.037",
          "0.043",
          "0.052",
          "0.058",
          "0.051",
          3.243,
          0.639,
          12.76,
          0,
          62.96,
          77.22,
          89.54,
        ],
      },
      {
        2556: [
          "8137",
          0.32,
          388000,
          8.5,
          "0.337",
          "0.365",
          "0.458",
          "0.464",
          "0.455",
          12.703,
          31.5,
          25.74,
          0,
          64.9,
          72.8,
          82.79,
        ],
      },
      {
        2557: [
          "0106",
          0.33,
          2127200,
          8.4,
          "0.347",
          "0.397",
          "0.47",
          "0.48",
          "0.465",
          68.603,
          15.5,
          44.75,
          0,
          65.79,
          79.47,
          93.46,
        ],
      },
      {
        2558: [
          "8176",
          0.39,
          0,
          8.4,
          "0.391",
          "0.425",
          "0.595",
          "0.611",
          "0.585",
          0,
          1.974,
          45.77,
          0,
          66.83,
          80.39,
          94.98,
        ],
      },
      {
        2559: [
          "2158",
          21.3,
          1088900,
          8.4,
          "20.955",
          "22.508",
          "29.513",
          "31.741",
          "28.532",
          2323,
          209.2,
          58.33,
          0.63,
          78.18,
          85.91,
          94.4,
        ],
      },
      {
        2560: [
          "1215",
          0.025,
          3040000,
          8.3,
          "0.028",
          "0.033",
          "0.037",
          "0.038",
          "0.036",
          7.782,
          3.195,
          39.86,
          0,
          65.53,
          78.14,
          88.85,
        ],
      },
      {
        2561: [
          "9956",
          8.3,
          697500,
          8.3,
          "8.937",
          "-",
          "-",
          "-",
          "-",
          587,
          96.4,
          83.45,
          0,
          91.92,
          95.55,
          98.21,
        ],
      },
      {
        2562: [
          "1951",
          8.73,
          9072390,
          8.3,
          "8.489",
          "9.872",
          "12.873",
          "14.395",
          "12.468",
          7983,
          218.9,
          12.25,
          22.65,
          35.98,
          56.32,
          82.65,
        ],
      },
      {
        2563: [
          "1756",
          1.2,
          47000,
          8.2,
          "1.204",
          "1.268",
          "1.586",
          "1.802",
          "1.535",
          5.649,
          14.4,
          0,
          0,
          80.84,
          87.71,
          94.04,
        ],
      },
      {
        2564: [
          "1797",
          4.79,
          8735500,
          8.2,
          "4.843",
          "6.368",
          "6.122",
          "8.221",
          "5.844",
          4223,
          47.9,
          55.67,
          17.03,
          72.71,
          85.9,
          92.8,
        ],
      },
      {
        2565: [
          "6978",
          9.18,
          2912000,
          8.2,
          "14.622",
          "15.276",
          "16.399",
          "16.727",
          "16.075",
          2641,
          47.2,
          22.66,
          0,
          69.06,
          82.77,
          91.65,
        ],
      },
      {
        2566: [
          "6808",
          3.24,
          5640320,
          8.1,
          "3.177",
          "3.183",
          "4.224",
          "4.802",
          "4.096",
          1842,
          309,
          80.82,
          1,
          90.4,
          94.44,
          96.96,
        ],
      },
      {
        2567: [
          "1439",
          0.038,
          0,
          8.1,
          "0.039",
          "0.043",
          "0.053",
          "0.059",
          "0.052",
          0,
          0.523,
          11.36,
          0,
          49.4,
          60.72,
          81.08,
        ],
      },
      {
        2568: [
          "1890",
          3,
          614000,
          8.1,
          "3.099",
          "3.709",
          "4.359",
          "4.688",
          "4.288",
          184,
          60.4,
          0.05,
          3.95,
          86.86,
          92.19,
          97.84,
        ],
      },
      {
        2569: [
          "6989",
          4.35,
          301000,
          8,
          "4.38",
          "4.629",
          "5.911",
          "6.907",
          "5.699",
          131,
          53.1,
          71.78,
          2.33,
          80.5,
          86.5,
          94.91,
        ],
      },
      {
        2570: [
          "1755",
          11.78,
          1857000,
          8,
          "11.568",
          "12.802",
          "16.423",
          "18.409",
          "16.052",
          2230,
          102.5,
          69.42,
          7.97,
          81.67,
          87.07,
          92.76,
        ],
      },
      {
        2571: [
          "8001",
          0.109,
          1444000,
          7.9,
          "0.111",
          "0.298",
          "0.208",
          "0.211",
          "0.197",
          15.326,
          0.471,
          9.77,
          0,
          38.84,
          51.87,
          70.99,
        ],
      },
      {
        2572: [
          "8168",
          0.083,
          0,
          7.9,
          "0.088",
          "0.109",
          "0.121",
          "0.125",
          "0.12",
          0,
          0.83,
          75.02,
          0,
          82.51,
          86.28,
          91.24,
        ],
      },
      {
        2573: [
          "7226",
          3.246,
          61948300,
          7.9,
          "3.061",
          "3.532",
          "4.443",
          "5.12",
          "4.311",
          20287,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        2574: [
          "2159",
          1.25,
          800,
          7.8,
          "1.29",
          "1.393",
          "1.627",
          "1.877",
          "1.584",
          0.1,
          2.5,
          0.01,
          0,
          76.08,
          83.42,
          91.87,
        ],
      },
      {
        2575: [
          "1579",
          36,
          2646160,
          7.8,
          "35.155",
          "42.104",
          "45.521",
          "52.17",
          "45.082",
          9585,
          376.8,
          0,
          11.72,
          72.66,
          83.9,
          96.27,
        ],
      },
      {
        2576: [
          "1182",
          0.43,
          0,
          7.8,
          "0.421",
          "0.411",
          "0.706",
          "0.973",
          "-",
          0,
          0.601,
          44.23,
          0,
          55.6,
          64.43,
          76.19,
        ],
      },
      {
        2577: [
          "2126",
          11.6,
          2735000,
          7.7,
          "12.174",
          "12.446",
          "17.393",
          "20.674",
          "16.736",
          3198,
          47.2,
          22.86,
          12.01,
          45.26,
          60.78,
          74.45,
        ],
      },
      {
        2578: [
          "8471",
          0.255,
          3410000,
          7.7,
          "0.269",
          "0.497",
          "0.549",
          "0.591",
          "0.537",
          90.69,
          2.168,
          4.99,
          0,
          72.61,
          82.68,
          96.18,
        ],
      },
      {
        2579: [
          "2107",
          0.69,
          2957500,
          7.7,
          "0.826",
          "1.595",
          "1.23",
          "1.194",
          "1.209",
          204,
          6.9,
          8.64,
          0,
          74.92,
          83.36,
          90.99,
        ],
      },
      {
        2580: [
          "1966",
          1.72,
          7061000,
          7.6,
          "1.836",
          "2.056",
          "2.638",
          "2.867",
          "2.571",
          1253,
          72.6,
          0.52,
          4.69,
          44.81,
          61,
          89.71,
        ],
      },
      {
        2581: [
          "2309",
          0.091,
          15000,
          7.6,
          "0.088",
          "0.112",
          "0.135",
          "0.152",
          "0.132",
          0.117,
          17.5,
          78.39,
          0,
          84.25,
          86.93,
          91.63,
        ],
      },
      {
        2582: [
          "3347",
          95.8,
          2925380,
          7.6,
          "90.435",
          "115.559",
          "143.308",
          "145.239",
          "141.46",
          27965,
          117.9,
          0,
          4.08,
          74,
          84.01,
          92.6,
        ],
      },
      {
        2583: [
          "0571",
          0.4,
          0,
          7.5,
          "0.342",
          "0.315",
          "0.563",
          "0.586",
          "0.553",
          0,
          5.967,
          71.94,
          0,
          88.01,
          94.22,
          96.39,
        ],
      },
      {
        2584: [
          "8437",
          0.14,
          3072000,
          7.5,
          "0.137",
          "0.143",
          "0.201",
          "0.213",
          "0.195",
          41.812,
          1.21,
          1.3,
          0,
          55.35,
          63.93,
          77.31,
        ],
      },
      {
        2585: [
          "6127",
          61.6,
          465880,
          7.4,
          "58.315",
          "77.689",
          "100.374",
          "100.638",
          "98.535",
          2897,
          37.3,
          0.01,
          16.81,
          37.93,
          52.44,
          78.99,
        ],
      },
      {
        2586: [
          "0312",
          0.147,
          220000,
          7.4,
          "0.148",
          "0.154",
          "0.218",
          "0.254",
          "0.209",
          3.254,
          3.668,
          0.09,
          0,
          81.47,
          86.85,
          92.33,
        ],
      },
      {
        2587: [
          "1472",
          0.124,
          145000,
          7.4,
          "0.125",
          "0.142",
          "0.165",
          "0.288",
          "0.161",
          1.808,
          1.24,
          60.01,
          0,
          70.11,
          74.59,
          82.82,
        ],
      },
      {
        2588: [
          "6166",
          1.56,
          29000,
          7.3,
          "1.531",
          "1.722",
          "2.33",
          "2.496",
          "2.278",
          4.567,
          25.7,
          0,
          3.95,
          72.78,
          85.92,
          97.2,
        ],
      },
      {
        2589: [
          "0327",
          5.35,
          3425000,
          7.3,
          "5.404",
          "5.84",
          "8.042",
          "8.219",
          "7.967",
          1841,
          58.3,
          33.52,
          0.45,
          80.33,
          86.23,
          91.94,
        ],
      },
      {
        2590: [
          "9666",
          33.8,
          3943930,
          7.3,
          "32.86",
          "32.901",
          "45.835",
          "50.491",
          "44.493",
          13795,
          220.6,
          0,
          7.32,
          76.86,
          86.68,
          96.7,
        ],
      },
      {
        2591: [
          "1952",
          35.25,
          401451,
          7.2,
          "32.92",
          "36.591",
          "51.518",
          "56.512",
          "50.085",
          1423,
          105.2,
          4.01,
          1.05,
          68.95,
          77.73,
          92.33,
        ],
      },
      {
        2592: [
          "1825",
          0.188,
          675000,
          7.2,
          "0.181",
          "0.185",
          "0.7",
          "0.773",
          "0.631",
          11.95,
          0.376,
          20.05,
          0,
          34.18,
          44.23,
          62.13,
        ],
      },
      {
        2593: [
          "0209",
          0.037,
          2180000,
          7.2,
          "0.049",
          "0.059",
          "0.059",
          "0.058",
          "0.059",
          8.076,
          1.355,
          10.95,
          0,
          43.38,
          53.19,
          72.72,
        ],
      },
      {
        2594: [
          "0755",
          0.03,
          3710000,
          7.1,
          "0.031",
          "0.032",
          "0.043",
          "0.051",
          "0.042",
          11.277,
          4.464,
          0.05,
          0,
          58.27,
          68.7,
          81.63,
        ],
      },
      {
        2595: [
          "1697",
          0.385,
          59400,
          7.1,
          "0.398",
          "0.376",
          "0.537",
          "0.576",
          "0.53",
          2.255,
          4.484,
          0.02,
          0,
          74.76,
          83.26,
          92.88,
        ],
      },
      {
        2596: [
          "8136",
          0.052,
          1120000,
          7.1,
          "0.051",
          "0.061",
          "0.099",
          "0.087",
          "0.101",
          5.822,
          0.52,
          66.01,
          0,
          77.05,
          83.94,
          90.83,
        ],
      },
      {
        2597: [
          "9926",
          28.8,
          2910740,
          7,
          "29.195",
          "39.552",
          "45.545",
          "47.894",
          "44.243",
          8462,
          235.3,
          14.59,
          10.21,
          46.29,
          64.89,
          88.82,
        ],
      },
      {
        2598: [
          "8063",
          0.105,
          150000,
          7,
          "0.108",
          "0.111",
          "0.13",
          "0.434",
          "0.127",
          1.51,
          0.536,
          1.24,
          0,
          70.98,
          76.43,
          83.31,
        ],
      },
      {
        2599: [
          "0926",
          0.175,
          281000,
          6.9,
          "0.178",
          "0.198",
          "0.288",
          "0.299",
          "0.286",
          4.916,
          2.853,
          10.39,
          0,
          61.86,
          71.24,
          85.94,
        ],
      },
      {
        2600: [
          "8267",
          0.28,
          21000,
          6.9,
          "0.277",
          "0.315",
          "0.408",
          "0.502",
          "0.388",
          0.588,
          1.03,
          6.95,
          0,
          46.7,
          66.23,
          85.06,
        ],
      },
      {
        2601: [
          "2608",
          0.43,
          0,
          6.9,
          "0.437",
          "0.381",
          "0.67",
          "0.808",
          "0.632",
          0,
          10.9,
          1.11,
          0,
          89.41,
          92.18,
          96.57,
        ],
      },
      {
        2602: [
          "1026",
          0.149,
          0,
          6.8,
          "0.16",
          "0.204",
          "0.227",
          "0.247",
          "0.225",
          0,
          8.214,
          44.14,
          0,
          81.8,
          87.22,
          94.64,
        ],
      },
      {
        2603: [
          "9922",
          15,
          15691900,
          6.8,
          "14.182",
          "15.574",
          "22.401",
          "24.487",
          "21.748",
          24516,
          218,
          0,
          15.02,
          69.16,
          80.83,
          95.01,
        ],
      },
      {
        2604: [
          "0376",
          1.43,
          342000,
          6.8,
          "1.396",
          "1.646",
          "2.143",
          "2.336",
          "2.082",
          48.662,
          55.3,
          56.39,
          0,
          77.86,
          84.27,
          92.9,
        ],
      },
      {
        2605: [
          "6063",
          0.06,
          88000,
          6.7,
          "0.075",
          "0.095",
          "0.102",
          "0.105",
          "0.102",
          0.522,
          1.2,
          75,
          0,
          81.04,
          85.34,
          91.49,
        ],
      },
      {
        2606: [
          "8422",
          0.041,
          1670000,
          6.7,
          "0.042",
          "0.043",
          "0.081",
          "0.114",
          "0.069",
          6.861,
          0.492,
          0,
          0,
          40.39,
          50.53,
          69.77,
        ],
      },
      {
        2607: [
          "8475",
          0.078,
          15000,
          6.7,
          "0.083",
          "0.089",
          "0.107",
          "0.125",
          "0.105",
          0.117,
          0.343,
          28.45,
          0,
          46.03,
          59.65,
          74.28,
        ],
      },
      {
        2608: [
          "8313",
          0.04,
          0,
          6.6,
          "0.041",
          "0.049",
          "0.055",
          "0.056",
          "0.055",
          0,
          0.8,
          0.98,
          0,
          83.96,
          88.05,
          93.19,
        ],
      },
      {
        2609: [
          "1134",
          1.17,
          80000,
          6.6,
          "1.184",
          "1.356",
          "1.577",
          "1.684",
          "1.562",
          9.416,
          5.85,
          48.4,
          0,
          62.68,
          69.99,
          84.21,
        ],
      },
      {
        2610: [
          "0853",
          27.5,
          4689010,
          6.6,
          "26.79",
          "31.611",
          "46.003",
          "48.229",
          "44.278",
          13036,
          500.7,
          0.15,
          16.43,
          53.9,
          72.14,
          90.59,
        ],
      },
      {
        2611: [
          "6161",
          0.48,
          0,
          6.5,
          "0.474",
          "0.559",
          "0.826",
          "0.9",
          "0.791",
          0,
          3.003,
          51.1,
          0,
          74.36,
          83.85,
          90.54,
        ],
      },
      {
        2612: [
          "1565",
          0.46,
          0,
          6.5,
          "0.46",
          "0.477",
          "0.635",
          "0.841",
          "0.6",
          0,
          14.2,
          12.19,
          0,
          71.13,
          76.88,
          85.17,
        ],
      },
      {
        2613: [
          "0520",
          5.05,
          2178000,
          6.4,
          "5.275",
          "5.635",
          "6.724",
          "8.096",
          "6.644",
          1107,
          54.8,
          -0.01,
          6.7,
          81.73,
          89.26,
          94.05,
        ],
      },
      {
        2614: [
          "3798",
          1.8,
          506000,
          6.4,
          "1.825",
          "1.665",
          "2.576",
          "3.211",
          "2.418",
          92.118,
          23.1,
          66.61,
          0,
          84.72,
          92.99,
          96.58,
        ],
      },
      {
        2615: [
          "0383",
          0.061,
          280000,
          6.4,
          "0.067",
          "0.08",
          "0.098",
          "0.102",
          "0.097",
          1.809,
          8.833,
          17.08,
          0,
          90,
          93.33,
          97.46,
        ],
      },
      {
        2616: [
          "1783",
          0.139,
          3840000,
          6.3,
          "0.146",
          "0.213",
          "0.288",
          "0.287",
          "0.279",
          54.473,
          1.112,
          40.02,
          0,
          75.62,
          82.58,
          89.72,
        ],
      },
      {
        2617: [
          "1647",
          0.179,
          225000,
          6.3,
          "0.184",
          "0.205",
          "0.236",
          "0.264",
          "0.229",
          4.095,
          1.96,
          0,
          0,
          41.6,
          55.31,
          76.53,
        ],
      },
      {
        2618: [
          "2210",
          4.71,
          800,
          6.3,
          "4.668",
          "-",
          "-",
          "-",
          "-",
          0.376,
          1.727,
          0.02,
          0,
          70.6,
          90.54,
          98.93,
        ],
      },
      {
        2619: [
          "1727",
          1.38,
          450500,
          6.2,
          "1.394",
          "1.528",
          "1.983",
          "2.169",
          "1.935",
          62.275,
          6.367,
          0.01,
          0,
          19.78,
          28.97,
          45.21,
        ],
      },
      {
        2620: [
          "8460",
          0.032,
          0,
          6.2,
          "0.034",
          "0.036",
          "0.046",
          "0.049",
          "0.046",
          0,
          0.352,
          51.23,
          0,
          63.8,
          71.65,
          81.56,
        ],
      },
      {
        2621: [
          "0799",
          4.73,
          8818000,
          6.2,
          "5.907",
          "6.968",
          "7.985",
          "8.872",
          "7.755",
          4216,
          56.4,
          15.18,
          8.27,
          64.61,
          75.84,
          84.73,
        ],
      },
      {
        2622: [
          "0554",
          0.213,
          12170000,
          6.1,
          "0.332",
          "0.42",
          "0.39",
          "0.384",
          "0.388",
          266,
          8.428,
          0.42,
          0,
          78.05,
          84.05,
          92.72,
        ],
      },
      {
        2623: [
          "0223",
          0.029,
          0,
          6.1,
          "0.036",
          "0.039",
          "0.047",
          "0.051",
          "0.046",
          0,
          0.964,
          17.68,
          0,
          41.16,
          55.94,
          69.19,
        ],
      },
      {
        2624: [
          "0328",
          0.14,
          3000,
          6.1,
          "0.153",
          "0.192",
          "0.231",
          "0.233",
          "0.228",
          0,
          1.012,
          59.67,
          0,
          71.97,
          76.75,
          83.08,
        ],
      },
      {
        2625: [
          "2282",
          4.56,
          6135200,
          6,
          "4.636",
          "4.982",
          "7.209",
          "8.512",
          "6.821",
          2833,
          173.2,
          78.51,
          1.61,
          87.39,
          90.34,
          94.15,
        ],
      },
      {
        2626: [
          "0593",
          0.73,
          55500,
          6,
          "0.834",
          "1.004",
          "1.356",
          "1.395",
          "1.323",
          4.363,
          2.084,
          1.49,
          0,
          79.37,
          85.29,
          95.46,
        ],
      },
      {
        2627: [
          "2150",
          7.48,
          4159100,
          5.9,
          "7.532",
          "8.742",
          "-",
          "-",
          "-",
          3163,
          128.2,
          61.82,
          2.05,
          80.01,
          87.89,
          93.92,
        ],
      },
      {
        2628: [
          "3718",
          0.59,
          7740000,
          5.9,
          "0.619",
          "0.581",
          "0.774",
          "0.899",
          "0.757",
          468,
          21.2,
          23.12,
          0,
          50.03,
          66.39,
          86.52,
        ],
      },
      {
        2629: [
          "1736",
          0.073,
          0,
          5.9,
          "0.071",
          "0.08",
          "0.108",
          "0.125",
          "0.104",
          0,
          0.749,
          4.64,
          0,
          52.96,
          63,
          81.44,
        ],
      },
      {
        2630: [
          "8500",
          0.42,
          10000,
          5.8,
          "0.463",
          "0.543",
          "0.623",
          "0.658",
          "0.608",
          0.428,
          0.756,
          75,
          0,
          84.18,
          89.44,
          94.22,
        ],
      },
      {
        2631: [
          "1225",
          0.029,
          6044000,
          5.8,
          "0.03",
          "0.031",
          "0.043",
          "0.064",
          "0.039",
          0,
          0.067,
          -875.82,
          0,
          322.61,
          469.99,
          623.41,
        ],
      },
      {
        2632: [
          "2298",
          0.49,
          888000,
          5.8,
          "0.492",
          "0.601",
          "0.773",
          "0.862",
          "0.747",
          44.91,
          11,
          0.01,
          0.36,
          74.43,
          83.16,
          91.17,
        ],
      },
      {
        2633: [
          "0986",
          0.084,
          296000,
          5.7,
          "0.089",
          "0.098",
          "0.109",
          "0.137",
          "0.109",
          2.481,
          1.049,
          4.02,
          0,
          30.17,
          40.44,
          60.62,
        ],
      },
      {
        2634: [
          "1143",
          0.173,
          1144000,
          5.7,
          "0.18",
          "0.198",
          "0.231",
          "0.463",
          "0.227",
          19.614,
          1.599,
          5.38,
          0,
          27.4,
          40.11,
          60.5,
        ],
      },
      {
        2635: [
          "0876",
          0.091,
          170000,
          5.7,
          "0.086",
          "0.094",
          "0.176",
          "0.17",
          "0.177",
          1.483,
          4.588,
          1.02,
          0,
          68.76,
          77.1,
          85.19,
        ],
      },
      {
        2636: [
          "2100",
          0.57,
          2800000,
          5.6,
          "0.582",
          "0.629",
          "0.912",
          "1.168",
          "0.859",
          163,
          15.7,
          38.4,
          0,
          58.06,
          68.16,
          81.16,
        ],
      },
      {
        2637: [
          "2518",
          70.1,
          25000,
          5.6,
          "64.22",
          "65.522",
          "86.815",
          "108.253",
          "84.338",
          176,
          357,
          43.24,
          0,
          97.19,
          98.99,
          99.86,
        ],
      },
      {
        2638: [
          "8166",
          0.265,
          0,
          5.5,
          "0.304",
          "0.35",
          "0.424",
          "0.442",
          "0.398",
          0,
          0.281,
          0.17,
          0,
          33.4,
          48.63,
          66.4,
        ],
      },
      {
        2639: [
          "1592",
          0.41,
          1445000,
          5.5,
          "0.53",
          "1.361",
          "1.675",
          "1.639",
          "1.675",
          60.73,
          5.114,
          13.19,
          0,
          76.67,
          83.99,
          92.79,
        ],
      },
      {
        2640: [
          "2168",
          11.6,
          570500,
          5.5,
          "11.724",
          "12.975",
          "20.439",
          "21.799",
          "19.672",
          687,
          17.8,
          0.04,
          0,
          76.18,
          83.08,
          91.9,
        ],
      },
      {
        2641: [
          "6608",
          9.47,
          351500,
          5.4,
          "10.058",
          "10.501",
          "13.446",
          "-",
          "13.081",
          337,
          47.3,
          25.2,
          0,
          53.44,
          66.61,
          88.59,
        ],
      },
      {
        2642: [
          "8308",
          0.218,
          16000,
          5.4,
          "0.222",
          "0.29",
          "0.351",
          "0.391",
          "0.336",
          0.335,
          2.136,
          40.75,
          0,
          85.48,
          91.5,
          97.33,
        ],
      },
      {
        2643: [
          "1473",
          0.29,
          128000,
          5.4,
          "0.296",
          "0.33",
          "0.448",
          "0.492",
          "0.438",
          3.7,
          2.9,
          0.78,
          0,
          79.59,
          84.15,
          91.25,
        ],
      },
      {
        2644: [
          "0628",
          0.26,
          10000,
          5.3,
          "0.259",
          "0.279",
          "0.38",
          "0.464",
          "0.366",
          0.26,
          7.023,
          65.06,
          0,
          79.82,
          85.29,
          91.12,
        ],
      },
      {
        2645: [
          "0733",
          1.26,
          30000,
          5.3,
          "1.243",
          "1.703",
          "2.468",
          "2.539",
          "2.382",
          3.844,
          8.494,
          2.98,
          0,
          67.63,
          79.42,
          91.32,
        ],
      },
      {
        2646: [
          "8401",
          0.475,
          476000,
          5.3,
          "0.491",
          "0.585",
          "0.934",
          "1.213",
          "0.892",
          22.897,
          0.95,
          22.15,
          0,
          66.92,
          77.81,
          91.89,
        ],
      },
      {
        2647: [
          "2013",
          7.73,
          26074100,
          5.2,
          "7.448",
          "8.745",
          "11.299",
          "12.728",
          "10.952",
          20485,
          196.9,
          0,
          10.34,
          43.8,
          57.37,
          75.99,
        ],
      },
      {
        2648: [
          "3662",
          2.45,
          602000,
          5.2,
          "2.439",
          "2.808",
          "4.063",
          "4.365",
          "3.898",
          148,
          17.7,
          54.69,
          0,
          69.15,
          78.74,
          87.06,
        ],
      },
      {
        2649: [
          "8228",
          0.082,
          16960000,
          5.2,
          "0.081",
          "0.099",
          "0.147",
          "0.244",
          "0.132",
          138,
          0.682,
          19.17,
          0,
          42.48,
          50.17,
          64,
        ],
      },
      {
        2650: [
          "1255",
          1.62,
          48000,
          5.1,
          "1.631",
          "1.394",
          "3.824",
          "4.423",
          "3.536",
          7.596,
          3.934,
          10.74,
          0,
          65.44,
          71.44,
          82.34,
        ],
      },
      {
        2651: [
          "2500",
          28.35,
          2053400,
          5.1,
          "27.72",
          "31.938",
          "42.798",
          "48.971",
          "41.285",
          5964,
          125,
          0,
          8.51,
          46.89,
          64,
          89.38,
        ],
      },
      {
        2652: [
          "8612",
          0.076,
          4820000,
          5,
          "0.089",
          "0.232",
          "0.245",
          "0.238",
          "0.232",
          36.455,
          0.623,
          4.19,
          0,
          51.46,
          61.75,
          78.82,
        ],
      },
      {
        2653: [
          "9991",
          35.65,
          52400,
          5,
          "35.365",
          "38.763",
          "56.39",
          "64.953",
          "53.91",
          188,
          87.8,
          30.42,
          0,
          96.32,
          99.04,
          99.48,
        ],
      },
      {
        2654: [
          "8111",
          0.21,
          0,
          5,
          "0.225",
          "0.26",
          "0.366",
          "0.391",
          "-",
          0,
          0.941,
          59.72,
          0,
          67.94,
          74.05,
          84.01,
        ],
      },
      {
        2655: [
          "1431",
          0.255,
          717000,
          4.9,
          "0.251",
          "0.292",
          "0.381",
          "0.451",
          "0.365",
          18.549,
          11.9,
          0.37,
          0,
          79.75,
          84.98,
          90.06,
        ],
      },
      {
        2656: [
          "2251",
          36.25,
          104900,
          4.9,
          "34.525",
          "-",
          "-",
          "-",
          "-",
          383,
          9.401,
          14.14,
          0,
          43.02,
          59.42,
          84.89,
        ],
      },
      {
        2657: [
          "9959",
          7,
          5921500,
          4.9,
          "6.848",
          "6.783",
          "10.191",
          "-",
          "9.796",
          4245,
          161.2,
          27.75,
          0.4,
          56.83,
          73.42,
          90.46,
        ],
      },
      {
        2658: [
          "6622",
          5.27,
          166500,
          4.8,
          "5.449",
          "6.02",
          "7.877",
          "-",
          "7.582",
          88.617,
          28.5,
          36.84,
          0,
          63.62,
          78.18,
          89.98,
        ],
      },
      {
        2659: [
          "1112",
          13.06,
          1329500,
          4.8,
          "12.428",
          "13.963",
          "21.001",
          "23.054",
          "20.264",
          1777,
          84.2,
          0.03,
          3.89,
          86.3,
          92.83,
          97.61,
        ],
      },
      {
        2660: [
          "1801",
          42.75,
          8346000,
          4.8,
          "42.88",
          "61.329",
          "71.125",
          "74.308",
          "69.698",
          36601,
          625,
          0.06,
          16.46,
          58.29,
          74.75,
          90.83,
        ],
      },
      {
        2661: [
          "0235",
          0.046,
          20140000,
          4.7,
          "0.047",
          "0.049",
          "0.066",
          "0.083",
          "0.063",
          92.749,
          9.377,
          16.81,
          0,
          38.9,
          50.71,
          65.84,
        ],
      },
      {
        2662: [
          "9928",
          2.7,
          1025000,
          4.7,
          "2.737",
          "3.234",
          "4.423",
          "4.772",
          "4.278",
          284,
          26.6,
          0.03,
          3.94,
          64.34,
          76.32,
          88.99,
        ],
      },
      {
        2663: [
          "0988",
          0.123,
          33,
          4.7,
          "0.125",
          "0.132",
          "0.184",
          "0.237",
          "-",
          0,
          0.789,
          31.92,
          0,
          52.29,
          61.44,
          75.97,
        ],
      },
      {
        2664: [
          "8121",
          0.155,
          1270000,
          4.6,
          "0.195",
          "0.242",
          "0.401",
          "0.37",
          "0.369",
          18.483,
          0.258,
          14.84,
          0,
          35.15,
          43.78,
          58.21,
        ],
      },
      {
        2665: [
          "1813",
          4.6,
          25689600,
          4.6,
          "4.883",
          "5.846",
          "7.705",
          "8.889",
          "7.461",
          12075,
          146.4,
          51.91,
          0.99,
          73.24,
          84.56,
          94.22,
        ],
      },
      {
        2666: [
          "2131",
          2.6,
          4182000,
          4.5,
          "2.812",
          "2.958",
          "4.003",
          "4.735",
          "3.833",
          1105,
          20.6,
          31.23,
          0,
          66.87,
          79.41,
          89.25,
        ],
      },
      {
        2667: [
          "2219",
          5.93,
          191500,
          4.5,
          "6.146",
          "6.793",
          "-",
          "-",
          "-",
          115,
          41.9,
          55.82,
          0,
          72.5,
          83.91,
          93.8,
        ],
      },
      {
        2668: [
          "2162",
          29.05,
          473000,
          4.5,
          "30.57",
          "38.93",
          "-",
          "-",
          "-",
          1364,
          81.2,
          53.39,
          0,
          77.52,
          86.94,
          95.09,
        ],
      },
      {
        2669: [
          "3639",
          0.74,
          0,
          4.4,
          "0.719",
          "0.864",
          "1.161",
          "1.416",
          "1.097",
          0,
          19.1,
          12,
          0,
          85.48,
          94.21,
          99.42,
        ],
      },
      {
        2670: [
          "3603",
          0.63,
          9171000,
          4.4,
          "0.633",
          "0.782",
          "1.014",
          "1.12",
          "0.999",
          570,
          9.45,
          9.79,
          0,
          55.54,
          72.21,
          86.94,
        ],
      },
      {
        2671: [
          "0557",
          0.86,
          0,
          4.4,
          "0.898",
          "1.424",
          "1.608",
          "1.631",
          "1.585",
          0,
          3.431,
          12.8,
          0,
          80.79,
          87.41,
          93.01,
        ],
      },
      {
        2672: [
          "1673",
          0.53,
          538000,
          4.3,
          "0.622",
          "0.626",
          "0.981",
          "1.183",
          "0.95",
          28.576,
          4.7,
          0,
          0,
          58.2,
          75.58,
          92.94,
        ],
      },
      {
        2673: [
          "8366",
          0.022,
          0,
          4.3,
          "0.022",
          "0.022",
          "0.035",
          "0.068",
          "0.031",
          0,
          0.347,
          0.03,
          0,
          82.54,
          85.99,
          92.01,
        ],
      },
      {
        2674: [
          "2190",
          23.65,
          6500,
          4.3,
          "24.585",
          "24.759",
          "-",
          "-",
          "-",
          15.62,
          30.8,
          47.14,
          0,
          76.07,
          88.07,
          95.46,
        ],
      },
      {
        2675: [
          "1280",
          0.53,
          10500,
          4.2,
          "0.603",
          "0.811",
          "0.984",
          "1.003",
          "0.969",
          0.555,
          1.162,
          47.59,
          0,
          76,
          84.63,
          90.92,
        ],
      },
      {
        2676: [
          "8523",
          0.22,
          0,
          4.2,
          "0.229",
          "0.3",
          "0.356",
          "0.394",
          "0.346",
          0,
          0.88,
          67.53,
          0,
          88.21,
          93.55,
          97.33,
        ],
      },
      {
        2677: [
          "8462",
          0.17,
          320000,
          4.2,
          "0.182",
          "0.186",
          "0.259",
          "0.323",
          "0.252",
          5.003,
          1.02,
          1.39,
          0,
          61.72,
          69.34,
          80.53,
        ],
      },
      {
        2678: [
          "6998",
          6.79,
          495000,
          4.1,
          "6.923",
          "7.912",
          "11.302",
          "12.485",
          "10.909",
          339,
          34.1,
          3.35,
          2.9,
          64.58,
          70.9,
          83.76,
        ],
      },
      {
        2679: [
          "0164",
          0.265,
          441000,
          4.1,
          "0.276",
          "0.323",
          "0.399",
          "-",
          "0.387",
          11.504,
          1.479,
          0.19,
          0,
          45.45,
          58.67,
          74.86,
        ],
      },
      {
        2680: [
          "8133",
          0.237,
          119500,
          4,
          "0.223",
          "0.259",
          "0.421",
          "0.455",
          "0.416",
          2.676,
          0.37,
          0.22,
          0,
          40.63,
          51.7,
          69.02,
        ],
      },
      {
        2681: [
          "8219",
          0.315,
          0,
          4,
          "0.308",
          "0.297",
          "0.646",
          "0.693",
          "0.449",
          0,
          0.472,
          57.04,
          0,
          70.08,
          74.58,
          82.7,
        ],
      },
      {
        2682: [
          "8599",
          0.14,
          203750,
          4,
          "1.353",
          "-",
          "-",
          "-",
          "-",
          3.181,
          0.729,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        2683: [
          "1662",
          1.1,
          260000,
          3.9,
          "1.143",
          "1.42",
          "1.752",
          "2.045",
          "1.721",
          28.964,
          5.5,
          75.02,
          0,
          83.36,
          86.51,
          92.62,
        ],
      },
      {
        2684: [
          "6601",
          2.7,
          10705900,
          3.9,
          "3.032",
          "3.269",
          "4.891",
          "5.788",
          "4.651",
          2969,
          36,
          71.25,
          5.83,
          81.79,
          86.48,
          93.32,
        ],
      },
      {
        2685: [
          "1739",
          0.87,
          143500,
          3.9,
          "0.848",
          "1.059",
          "1.551",
          "1.661",
          "1.513",
          12.25,
          10,
          9.09,
          0,
          55.75,
          74.72,
          94.09,
        ],
      },
      {
        2686: [
          "2103",
          0.5,
          0,
          3.8,
          "0.5",
          "0.5",
          "2.205",
          "2.679",
          "2.047",
          0,
          17.8,
          67.22,
          0.01,
          76,
          80.77,
          89.54,
        ],
      },
      {
        2687: [
          "0909",
          16.24,
          11918600,
          3.8,
          "17.276",
          "21.279",
          "27.802",
          "30.073",
          "27.114",
          19989,
          319.6,
          0,
          2.74,
          68.15,
          81.56,
          93.71,
        ],
      },
      {
        2688: [
          "1233",
          3.57,
          6249000,
          3.8,
          "3.724",
          "4.311",
          "6.445",
          "7.573",
          "6.208",
          2315,
          70.8,
          0.03,
          3.02,
          81.13,
          88.38,
          94.82,
        ],
      },
      {
        2689: [
          "2146",
          4.4,
          133000,
          3.7,
          "4.863",
          "5.913",
          "8.001",
          "8.199",
          "7.845",
          57.832,
          4.136,
          1.68,
          0,
          42.49,
          57.84,
          77.03,
        ],
      },
      {
        2690: [
          "1808",
          0.046,
          407800,
          3.7,
          "0.051",
          "0.072",
          "0.079",
          "0.092",
          "0.078",
          1.903,
          0.626,
          26.12,
          0,
          51.47,
          61.74,
          78.4,
        ],
      },
      {
        2691: [
          "8208",
          0.072,
          570000,
          3.7,
          "0.079",
          "0.096",
          "0.177",
          "0.177",
          "0.17",
          3.904,
          0.518,
          50.01,
          0,
          61.3,
          69.16,
          80.73,
        ],
      },
      {
        2692: [
          "0362",
          0.118,
          26667500,
          3.6,
          "0.117",
          "0.117",
          "0.203",
          "0.237",
          "0.197",
          320,
          3.139,
          8.37,
          0,
          42.55,
          53.25,
          68.06,
        ],
      },
      {
        2693: [
          "0241",
          6.78,
          52488900,
          3.6,
          "6.503",
          "7.622",
          "11.242",
          "13.924",
          "10.815",
          36367,
          914.6,
          60.1,
          2.49,
          75.2,
          83.15,
          92.96,
        ],
      },
      {
        2694: [
          "9996",
          11.42,
          584000,
          3.5,
          "12.318",
          "15.487",
          "22.786",
          "23.974",
          "22.044",
          675,
          76.6,
          6.27,
          5.82,
          53.65,
          66.82,
          86.47,
        ],
      },
      {
        2695: [
          "0832",
          0.86,
          6004000,
          3.5,
          "0.898",
          "0.99",
          "1.463",
          "1.926",
          "1.422",
          525,
          25.4,
          0.03,
          5.47,
          42.62,
          60.55,
          87.99,
        ],
      },
      {
        2696: [
          "6169",
          2.43,
          19188700,
          3.5,
          "2.595",
          "3.085",
          "4.351",
          "5.035",
          "4.128",
          4811,
          87.3,
          0,
          8.9,
          77.49,
          85.87,
          94,
        ],
      },
      {
        2697: [
          "1699",
          0.067,
          1804000,
          3.4,
          "0.069",
          "0.076",
          "0.101",
          "0.104",
          "0.104",
          12.024,
          1.266,
          5.25,
          0,
          67.02,
          76.29,
          84.42,
        ],
      },
      {
        2698: [
          "9626",
          315.8,
          1572580,
          3.4,
          "320.24",
          "469.134",
          "613.949",
          "-",
          "592.153",
          49909,
          1213.8,
          40.59,
          0,
          83.59,
          91.74,
          97.32,
        ],
      },
      {
        2699: [
          "6862",
          18.16,
          9308900,
          3.4,
          "17.406",
          "18.311",
          "28.031",
          "33.031",
          "27.252",
          16914,
          1012.2,
          2.07,
          3.63,
          87.27,
          92.06,
          97.48,
        ],
      },
      {
        2700: [
          "8150",
          0.03,
          1360000,
          3.3,
          "0.031",
          "0.033",
          "0.05",
          "0.072",
          "0.047",
          4.168,
          0.566,
          25.61,
          0,
          45.15,
          56.44,
          69.13,
        ],
      },
      {
        2701: [
          "1628",
          0.67,
          20267900,
          3.3,
          "0.678",
          "0.736",
          "1.272",
          "1.513",
          "1.209",
          1370,
          43.8,
          48.13,
          3.49,
          71.13,
          83.4,
          91.96,
        ],
      },
      {
        2702: [
          "2799",
          0.455,
          490008000,
          3.3,
          "0.606",
          "0.937",
          "0.992",
          "0.999",
          "0.981",
          22391,
          122.8,
          53.1,
          8.11,
          67.71,
          78.95,
          88.28,
        ],
      },
      {
        2703: [
          "8295",
          0.39,
          360000,
          3.2,
          "0.437",
          "0.678",
          "0.802",
          "0.82",
          "0.802",
          14.41,
          16.3,
          14.74,
          0,
          81.59,
          88.35,
          93.1,
        ],
      },
      {
        2704: [
          "1568",
          0.67,
          0,
          3.2,
          "0.67",
          "0.714",
          "1.14",
          "1.275",
          "1.109",
          0,
          14.4,
          0,
          0,
          77.71,
          85,
          96.57,
        ],
      },
      {
        2705: [
          "1909",
          0.85,
          5816000,
          3.2,
          "0.94",
          "1.717",
          "2.282",
          "2.341",
          "2.222",
          500,
          32.6,
          10.86,
          0,
          60.34,
          72.28,
          86.16,
        ],
      },
      {
        2706: [
          "3913",
          3.08,
          4912450,
          3.1,
          "3.066",
          "4.156",
          "6.069",
          "6.492",
          "5.88",
          1560,
          62.1,
          43.29,
          0.85,
          71.99,
          82.99,
          91.02,
        ],
      },
      {
        2707: [
          "0936",
          0.28,
          0,
          3.1,
          "0.278",
          "0.343",
          "0.713",
          "0.73",
          "0.691",
          0,
          2.968,
          5.72,
          0,
          83.64,
          88.82,
          94.77,
        ],
      },
      {
        2708: [
          "1232",
          0.159,
          84000,
          3,
          "0.173",
          "0.189",
          "0.303",
          "0.357",
          "0.289",
          1.341,
          2.86,
          46.49,
          0,
          72.47,
          81.87,
          94.45,
        ],
      },
      {
        2709: [
          "1850",
          0.23,
          11540000,
          3,
          "0.242",
          "0.504",
          "1.135",
          "0.936",
          "1.143",
          279,
          1.84,
          0,
          0,
          43.14,
          54.46,
          73.94,
        ],
      },
      {
        2710: [
          "2777",
          3.18,
          16432800,
          3,
          "3.175",
          "3.805",
          "5.866",
          "6.927",
          "5.615",
          5353,
          119.3,
          43.31,
          2.82,
          59.62,
          70.43,
          83.93,
        ],
      },
      {
        2711: [
          "1920",
          0.041,
          260000,
          2.9,
          "0.041",
          "0.05",
          "0.076",
          "0.094",
          "0.071",
          1.066,
          1.066,
          75,
          0,
          82.28,
          87.09,
          92.41,
        ],
      },
      {
        2712: [
          "2312",
          0.195,
          2000,
          2.9,
          "0.199",
          "0.243",
          "0.336",
          "0.397",
          "0.318",
          0,
          0.451,
          4.11,
          0,
          41.19,
          54.94,
          72.5,
        ],
      },
      {
        2713: [
          "9688",
          430.2,
          44475,
          2.9,
          "451.82",
          "559.43",
          "909.843",
          "988.433",
          "877.07",
          1927,
          378.6,
          20.61,
          0,
          95.19,
          98.82,
          99.81,
        ],
      },
      {
        2714: [
          "8619",
          0.072,
          35114000,
          2.8,
          "0.122",
          "0.207",
          "0.146",
          "0.147",
          "0.145",
          285,
          0.829,
          19.54,
          0,
          61.26,
          68.84,
          82.55,
        ],
      },
      {
        2715: [
          "3319",
          13.42,
          5996410,
          2.8,
          "13.252",
          "17.39",
          "26.444",
          "28.644",
          "25.817",
          8294,
          190.5,
          0.53,
          1.09,
          85.47,
          91.48,
          95.44,
        ],
      },
      {
        2716: [
          "8427",
          0.21,
          450000,
          2.8,
          "0.232",
          "0.27",
          "0.419",
          "0.482",
          "0.393",
          9.549,
          0.217,
          5.05,
          0,
          50.59,
          59.35,
          74.3,
        ],
      },
      {
        2717: [
          "0456",
          0.039,
          19320000,
          2.7,
          "0.057",
          "0.069",
          "0.083",
          "0.085",
          "0.082",
          78.352,
          1.688,
          17.22,
          0,
          82.85,
          88.26,
          94.53,
        ],
      },
      {
        2718: [
          "3383",
          3.65,
          50549500,
          2.7,
          "4.11",
          "4.82",
          "7.241",
          "8.43",
          "6.978",
          19309,
          142.9,
          37.21,
          0.79,
          71.02,
          80.89,
          91.38,
        ],
      },
      {
        2719: [
          "8455",
          0.043,
          650000,
          2.6,
          "0.047",
          "0.06",
          "0.08",
          "0.095",
          "0.078",
          2.678,
          0.344,
          7.82,
          0,
          65.04,
          75.66,
          87.53,
        ],
      },
      {
        2720: [
          "1950",
          0.112,
          988000,
          2.6,
          "0.113",
          "0.135",
          "0.211",
          "0.279",
          "0.203",
          11.098,
          1.344,
          0.46,
          0,
          44.22,
          56.36,
          69.93,
        ],
      },
      {
        2721: [
          "8297",
          0.43,
          5027500,
          2.6,
          "0.388",
          "0.354",
          "0.918",
          "0.966",
          "0.89",
          224,
          2.27,
          34.23,
          0,
          67.2,
          74.63,
          85.83,
        ],
      },
      {
        2722: [
          "1165",
          0.064,
          2640000,
          2.5,
          "0.07",
          "0.081",
          "0.12",
          "0.137",
          "0.117",
          16.782,
          3.189,
          0.65,
          6.1,
          46.19,
          59,
          69.98,
        ],
      },
      {
        2723: [
          "1918",
          9.13,
          878216000,
          2.5,
          "11.083",
          "13.224",
          "18.091",
          "21.215",
          "17.442",
          863000,
          456.2,
          0.14,
          19.79,
          61.63,
          77.73,
          92.39,
        ],
      },
      {
        2724: [
          "0708",
          3.46,
          104674000,
          2.5,
          "3.809",
          "3.445",
          "9.558",
          "20.303",
          "8.201",
          39562,
          375.1,
          13.16,
          0,
          62.14,
          72.12,
          80.71,
        ],
      },
      {
        2725: [
          "8545",
          0.051,
          1984000,
          2.4,
          "0.065",
          "0.103",
          "0.101",
          "0.098",
          "0.102",
          10.034,
          0.51,
          23.65,
          0,
          44.32,
          57.96,
          74.6,
        ],
      },
      {
        2726: [
          "2160",
          3.82,
          3466000,
          2.4,
          "3.786",
          "4.446",
          "8.14",
          "10.06",
          "7.581",
          1349,
          91.8,
          48.71,
          3.31,
          68.8,
          77.63,
          89.03,
        ],
      },
      {
        2727: [
          "0065",
          0.155,
          392000,
          2.4,
          "0.152",
          "0.182",
          "0.307",
          "0.354",
          "0.293",
          6.252,
          2.33,
          60.63,
          0,
          69.72,
          77.31,
          89.07,
        ],
      },
      {
        2728: [
          "9901",
          14.22,
          912800,
          2.3,
          "14.708",
          "16.064",
          "24.887",
          "44.85",
          "21.954",
          1320,
          243.7,
          12.13,
          0,
          93.47,
          95.38,
          97.62,
        ],
      },
      {
        2729: [
          "6606",
          20.6,
          1495230,
          2.3,
          "20.889",
          "25.94",
          "44.927",
          "50.152",
          "41.973",
          3124,
          88.5,
          3.57,
          1.82,
          50.58,
          71.24,
          90.32,
        ],
      },
      {
        2730: [
          "9939",
          11.58,
          2515500,
          2.3,
          "12.016",
          "42.452",
          "55.232",
          "55.421",
          "54.197",
          2952,
          44.8,
          6.15,
          11.21,
          40.56,
          57.02,
          79.35,
        ],
      },
      {
        2731: [
          "1769",
          1.14,
          20370000,
          2.2,
          "1.307",
          "1.738",
          "2.107",
          "3.397",
          "1.943",
          2378,
          6.335,
          0.28,
          15.23,
          57.54,
          78.76,
          92.07,
        ],
      },
      {
        2732: [
          "8363",
          0.25,
          120000,
          2.2,
          "0.273",
          "0.312",
          "0.408",
          "0.507",
          "0.4",
          3.1,
          1.144,
          16.48,
          0,
          82.31,
          91.65,
          95.43,
        ],
      },
      {
        2733: [
          "8191",
          0.042,
          31236000,
          2.1,
          "0.046",
          "0.072",
          "0.088",
          "0.115",
          "0.083",
          0,
          0.021,
          -768.61,
          0,
          276.85,
          382.8,
          578.1,
        ],
      },
      {
        2734: [
          "2170",
          6.74,
          49500,
          2.1,
          "6.663",
          "8.109",
          "13.299",
          "15.924",
          "12.482",
          33.674,
          5.455,
          0.03,
          0,
          37.72,
          51.23,
          69.4,
        ],
      },
      {
        2735: [
          "0510",
          0.255,
          796200,
          2.1,
          "0.245",
          "0.418",
          "0.549",
          "0.592",
          "0.535",
          19.588,
          0.666,
          4.28,
          0,
          51.84,
          59.74,
          71.68,
        ],
      },
      {
        2736: [
          "0377",
          1.75,
          0,
          2,
          "1.748",
          "2.356",
          "3.625",
          "4.313",
          "3.468",
          0,
          1.077,
          4.05,
          0,
          92.89,
          94.45,
          96.61,
        ],
      },
      {
        2737: [
          "1833",
          27.6,
          8543450,
          2,
          "27.44",
          "29.86",
          "54.474",
          "64.074",
          "51.658",
          23742,
          316.6,
          31.44,
          10.46,
          60.59,
          74.27,
          87.02,
        ],
      },
      {
        2738: [
          "1777",
          0.305,
          28695000,
          2,
          "0.316",
          "0.339",
          "0.592",
          "0.695",
          "0.565",
          883,
          17.6,
          75.33,
          5.35,
          83.26,
          87.71,
          93.75,
        ],
      },
      {
        2739: [
          "1400",
          0.032,
          1475000,
          1.9,
          "0.04",
          "0.054",
          "0.071",
          "0.08",
          "0.068",
          4.615,
          0.167,
          0.03,
          0,
          41.03,
          54.13,
          69.49,
        ],
      },
      {
        2740: [
          "1981",
          1.83,
          5025000,
          1.9,
          "1.923",
          "2.524",
          "3.418",
          "4.222",
          "3.253",
          936,
          30.3,
          0,
          3.31,
          80.68,
          86.38,
          94.66,
        ],
      },
      {
        2741: [
          "0667",
          4.51,
          3652500,
          1.9,
          "4.684",
          "6.111",
          "8.406",
          "10.605",
          "7.962",
          1670,
          98,
          31.44,
          7.77,
          76.18,
          87.88,
          98.8,
        ],
      },
      {
        2742: [
          "0271",
          0.53,
          0,
          1.8,
          "0.552",
          "0.727",
          "1.278",
          "1.263",
          "1.266",
          0,
          6.576,
          60.48,
          0,
          93.02,
          94.37,
          96.4,
        ],
      },
      {
        2743: [
          "8220",
          0.023,
          2960000,
          1.8,
          "0.025",
          "0.035",
          "0.057",
          "0.065",
          "0.055",
          6.588,
          0.236,
          3.76,
          0,
          51.62,
          61.43,
          73.04,
        ],
      },
      {
        2744: [
          "0809",
          0.12,
          418000,
          1.8,
          "0.122",
          "0.19",
          "0.245",
          "0.256",
          "0.243",
          5.059,
          10.6,
          63.5,
          0,
          74.4,
          79.12,
          86.27,
        ],
      },
      {
        2745: [
          "8536",
          0.325,
          545000,
          1.7,
          "0.366",
          "0.429",
          "3.222",
          "2.53",
          "3.69",
          17.957,
          0.576,
          2.87,
          0,
          59.75,
          68.09,
          79.95,
        ],
      },
      {
        2746: [
          "0572",
          0.049,
          2140000,
          1.7,
          "0.047",
          "0.069",
          "0.098",
          "0.109",
          "0.096",
          10.48,
          0.536,
          16.96,
          0,
          55.44,
          65.34,
          79.56,
        ],
      },
      {
        2747: [
          "8547",
          0.047,
          12500000,
          1.6,
          "0.046",
          "0.06",
          "0.128",
          "0.125",
          "0.126",
          61.694,
          0.62,
          0.1,
          0,
          65.25,
          77.76,
          87.19,
        ],
      },
      {
        2748: [
          "1516",
          7.65,
          40253600,
          1.6,
          "7.565",
          "10.847",
          "17.233",
          "18.848",
          "16.714",
          31575,
          235.9,
          0.01,
          5.46,
          69.38,
          83.41,
          95.81,
        ],
      },
      {
        2749: [
          "8082",
          0.075,
          0,
          1.6,
          "0.079",
          "0.109",
          "0.21",
          "0.229",
          "0.2",
          0,
          1.572,
          1.69,
          0,
          81.03,
          87.26,
          93.63,
        ],
      },
      {
        2750: [
          "1652",
          1.24,
          324000,
          1.5,
          "1.256",
          "1.74",
          "2.422",
          "2.878",
          "2.348",
          40.579,
          9.547,
          56.5,
          0,
          88.98,
          91.69,
          94.97,
        ],
      },
      {
        2751: [
          "0689",
          0.026,
          7425000,
          1.5,
          "0.023",
          "0.033",
          "0.055",
          "0.062",
          "0.055",
          18.834,
          1.362,
          11.08,
          0,
          32.66,
          47.34,
          69.74,
        ],
      },
      {
        2752: [
          "6666",
          2.6,
          33004500,
          1.5,
          "2.676",
          "3.358",
          "5.621",
          "7.784",
          "-",
          8699,
          281,
          47.02,
          6.82,
          63.25,
          70.62,
          82.75,
        ],
      },
      {
        2753: [
          "1566",
          0.405,
          9877000,
          1.4,
          "0.432",
          "0.534",
          "1.786",
          "2.087",
          "1.703",
          399,
          3.992,
          3.29,
          0,
          25.81,
          36.76,
          54.72,
        ],
      },
      {
        2754: [
          "1629",
          0.425,
          96000,
          1.4,
          "0.445",
          "0.591",
          "1.59",
          "1.916",
          "1.469",
          4.238,
          2.321,
          8.5,
          0,
          81.68,
          88.66,
          93.3,
        ],
      },
      {
        2755: [
          "3638",
          0.049,
          108000,
          1.4,
          "0.076",
          "0.098",
          "0.111",
          "0.135",
          "0.108",
          0.529,
          2.148,
          0.64,
          0,
          83.24,
          91.67,
          95.98,
        ],
      },
      {
        2756: [
          "8493",
          0.082,
          0,
          1.3,
          "0.082",
          "0.084",
          "0.158",
          "0.475",
          "0.137",
          0,
          0.142,
          1.05,
          0,
          34.02,
          44.63,
          60.92,
        ],
      },
      {
        2757: [
          "8487",
          0.248,
          1096000,
          1.3,
          "0.298",
          "0.343",
          "0.558",
          "0.674",
          "0.536",
          27.867,
          2.182,
          28.54,
          0,
          47.61,
          59.59,
          84.32,
        ],
      },
      {
        2758: [
          "1638",
          0.85,
          27346700,
          1.3,
          "0.833",
          "0.93",
          "1.857",
          "2.288",
          "1.761",
          2376,
          59.6,
          9.33,
          15.21,
          55.98,
          73.11,
          89.08,
        ],
      },
      {
        2759: [
          "8385",
          0.053,
          120000,
          1.2,
          "0.054",
          "0.072",
          "0.138",
          "0.145",
          "0.131",
          0.626,
          0.424,
          61.26,
          0,
          73.51,
          78.32,
          85.83,
        ],
      },
      {
        2760: [
          "0498",
          0.099,
          530000,
          1.2,
          "0.099",
          "0.142",
          "0.233",
          "0.272",
          "0.223",
          5.297,
          1.093,
          0.49,
          0,
          47.73,
          57.38,
          72.33,
        ],
      },
      {
        2761: [
          "8055",
          0.011,
          0,
          1.1,
          "0.011",
          "0.03",
          "0.036",
          "0.034",
          "0.036",
          0,
          0.433,
          4.97,
          0,
          26.89,
          43.14,
          68,
        ],
      },
      {
        2762: [
          "8412",
          0.029,
          310000,
          1.1,
          "0.03",
          "0.032",
          "0.21",
          "0.263",
          "0.196",
          0.899,
          0.29,
          0.01,
          0,
          53.3,
          61.28,
          75.38,
        ],
      },
      {
        2763: [
          "0616",
          0.153,
          4591300,
          1.1,
          "0.17",
          "0.28",
          "0.391",
          "0.416",
          "0.382",
          68.889,
          1.425,
          30.58,
          0,
          80.03,
          89.32,
          92.67,
        ],
      },
      {
        2764: [
          "0176",
          0.056,
          11220000,
          1,
          "0.113",
          "0.161",
          "0.166",
          "0.17",
          "0.164",
          66.282,
          1.138,
          0.39,
          0,
          79.97,
          82.84,
          88.57,
        ],
      },
      {
        2765: [
          "0707",
          0.021,
          1802000,
          1,
          "0.021",
          "0.024",
          "0.179",
          "0.145",
          "0.187",
          3.837,
          2.084,
          8.91,
          0,
          33.25,
          46.28,
          64.25,
        ],
      },
      {
        2766: [
          "1317",
          0.56,
          17742000,
          1,
          "0.576",
          "0.847",
          "1.352",
          "1.488",
          "1.316",
          989,
          16.7,
          0.55,
          25.03,
          77.7,
          84.97,
          90.65,
        ],
      },
      {
        2767: [
          "3883",
          1.55,
          24541200,
          0.9,
          "1.54",
          "1.883",
          "3.961",
          "5.038",
          "3.755",
          3923,
          45.9,
          51.4,
          8.35,
          63.87,
          72.85,
          86.21,
        ],
      },
      {
        2768: [
          "8347",
          0.038,
          8976000,
          0.9,
          "0.039",
          "0.047",
          "0.084",
          "0.1",
          "0.081",
          34.104,
          0.383,
          0,
          0,
          61.69,
          68.98,
          81.18,
        ],
      },
      {
        2769: [
          "1778",
          0.93,
          465000,
          0.9,
          "0.931",
          "1.101",
          "2.126",
          "2.433",
          "2.039",
          43.66,
          13.8,
          5.4,
          3.65,
          73.48,
          82.45,
          93.36,
        ],
      },
      {
        2770: [
          "0767",
          0.182,
          5110000,
          0.8,
          "0.193",
          "0.296",
          "0.47",
          "0.525",
          "0.455",
          95.039,
          7.044,
          29.71,
          0,
          54.26,
          64.59,
          78.93,
        ],
      },
      {
        2771: [
          "0204",
          0.058,
          40800000,
          0.8,
          "0.063",
          "0.12",
          "0.181",
          "0.19",
          "0.177",
          232,
          1.326,
          15.86,
          0,
          32.65,
          48.11,
          66.66,
        ],
      },
      {
        2772: [
          "8556",
          0.019,
          9092000,
          0.8,
          "0.128",
          "-",
          "-",
          "-",
          "-",
          0,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        2773: [
          "0873",
          5.36,
          24672900,
          0.7,
          "5.37",
          "8.157",
          "15.531",
          "16.528",
          "14.996",
          13581,
          132.8,
          48.07,
          7.19,
          65.65,
          77.4,
          92.16,
        ],
      },
      {
        2774: [
          "1239",
          0.086,
          300000,
          0.7,
          "0.109",
          "0.202",
          "0.245",
          "0.257",
          "0.241",
          2.578,
          0.566,
          50.46,
          0,
          60.69,
          68.59,
          80.75,
        ],
      },
      {
        2775: [
          "0886",
          0.045,
          1239080,
          0.6,
          "0.053",
          "0.065",
          "0.124",
          "0.14",
          "0.118",
          5.534,
          1.017,
          0.06,
          0,
          60.97,
          78.51,
          84.95,
        ],
      },
      {
        2776: [
          "3333",
          1.62,
          58577000,
          0.6,
          "1.652",
          "2.028",
          "4.469",
          "6.668",
          "4.045",
          9640,
          213.9,
          52.05,
          9.71,
          71.84,
          79.38,
          90.42,
        ],
      },
      {
        2777: [
          "3978",
          0.31,
          551000,
          0.6,
          "0.312",
          "0.408",
          "0.671",
          "1.093",
          "0.617",
          17.483,
          2.626,
          1.83,
          0,
          81.3,
          90.3,
          94.86,
        ],
      },
      {
        2778: [
          "0813",
          4.92,
          75857200,
          0.5,
          "5.155",
          "7.647",
          "13.301",
          "15.703",
          "12.724",
          38544,
          186.8,
          32.96,
          4.93,
          66.02,
          75,
          89.13,
        ],
      },
      {
        2779: [
          "1107",
          0.176,
          12411200,
          0.5,
          "0.308",
          "0.366",
          "0.548",
          "0.614",
          "0.533",
          221,
          4.919,
          66.16,
          0,
          82.55,
          88.04,
          94.7,
        ],
      },
      {
        2780: [
          "6068",
          0.61,
          2558120,
          0.5,
          "0.644",
          "1.126",
          "1.515",
          "1.97",
          "1.469",
          159,
          13.2,
          43.67,
          10.09,
          63.17,
          79.51,
          91.08,
        ],
      },
      {
        2781: [
          "2997",
          0.087,
          21708700,
          0.4,
          "68.49",
          "-",
          "-",
          "-",
          "-",
          250,
          0,
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        2782: [
          "8083",
          0.395,
          140097000,
          0.4,
          "0.457",
          "0.67",
          "0.974",
          "1.274",
          "0.927",
          5549,
          68.1,
          0.05,
          0,
          36.95,
          51.12,
          72.56,
        ],
      },
      {
        2783: [
          "8239",
          0.041,
          41690000,
          0.4,
          "0.111",
          "0.168",
          "0.168",
          "0.164",
          "0.166",
          177,
          0.415,
          6.81,
          0,
          34.7,
          47.66,
          72.37,
        ],
      },
      {
        2784: [
          "0299",
          0.054,
          5712690,
          0.3,
          "0.075",
          "0.154",
          "0.211",
          "0.229",
          "0.207",
          33.494,
          2.948,
          0.72,
          8.93,
          71.03,
          82.07,
          91.37,
        ],
      },
      {
        2785: [
          "2599",
          1.14,
          33734000,
          0.3,
          "1.029",
          "1.759",
          "4.195",
          "4.669",
          "4.021",
          3945,
          34.6,
          4.14,
          3.69,
          74.97,
          83.22,
          95.12,
        ],
      },
      {
        2786: [
          "1383",
          0.108,
          13601600,
          0.3,
          "0.117",
          "0.184",
          "0.295",
          "0.361",
          "0.285",
          154,
          7.201,
          4.58,
          1.06,
          82.78,
          87.05,
          91.71,
        ],
      },
      {
        2787: [
          "0102",
          0.131,
          1612000,
          0.2,
          "0.141",
          "0.273",
          "0.492",
          "0.571",
          "0.478",
          21.047,
          5.907,
          0.15,
          0,
          79.28,
          83.01,
          88.75,
        ],
      },
      {
        2788: [
          "0997",
          0.065,
          1912080,
          0.2,
          "0.066",
          "0.089",
          "0.255",
          "0.296",
          "0.239",
          12.74,
          0.76,
          16.46,
          0,
          68,
          74.85,
          84.1,
        ],
      },
      {
        2789: [
          "0342",
          0.055,
          1628000,
          0.1,
          "0.056",
          "0.066",
          "0.27",
          "0.335",
          "0.258",
          9.325,
          0.807,
          17.04,
          0,
          44.42,
          55.1,
          71.22,
        ],
      },
      {
        2790: [
          "8109",
          0.034,
          0,
          0.1,
          "0.034",
          "0.138",
          "0.223",
          "0.24",
          "0.217",
          0,
          0.171,
          20.13,
          0,
          34.78,
          43.64,
          59.44,
        ],
      },
      {
        2791: [
          "1323",
          0.159,
          159310000,
          0.1,
          "0.811",
          "1.581",
          "1.291",
          "1.179",
          "1.288",
          2590,
          5.564,
          1.16,
          0,
          30.76,
          38.18,
          52.75,
        ],
      },
      {
        2792: [
          "1987",
          0.194,
          41184000,
          0,
          "0.981",
          "2.357",
          "2.42",
          "2.473",
          "2.379",
          829,
          1.94,
          43.17,
          0,
          56.6,
          64.91,
          76.4,
        ],
      },
    ],
  },
};
