import { Fragment } from "react";

export const linkRenderer = (text) => {
  const linkExp = /^https?:\/\/[a-z0-9_./-]*$/i;
  return <>
    {
    text.split(/(https?:\/\/[a-z0-9_./-]*)/gi).map((content, index) =>
      <Fragment key={index}>
        { content.match(linkExp) ?
          <a
            href={content}
            target="_blank"
            rel="noreferrer"
          >
            { content }
          </a>
        : 
          <>
            { content }
          </>
        }
      </Fragment>)
    }
  </>
}