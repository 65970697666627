import { makeStyles } from '@material-ui/core';
import { useUserCanViewBlog } from 'features/user/userSlice';
import { useState } from 'react';
import { AllPosts } from "./components/AllPosts";
import { ImageModal } from "./components/ImageModal";

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    paddingTop: '12px',
    paddingBottom: '12px',
    display: 'flex',
    flexDirection: 'column',
  },
  item: {
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const Blog = () => {
  const classes = useStyles();
  const userCanViewBlog = useUserCanViewBlog();
  const [ imageModalContent, setImageModalContent ] = useState(null);

  return (
    <>
      <ImageModal content={imageModalContent} setContent={setImageModalContent}/>
      { userCanViewBlog && 
        <div className={classes.container}>
          <div className={classes.item}>
            <AllPosts setImageModalContent={setImageModalContent} />
          </div>
        </div>
      }
      { !userCanViewBlog && 
        <div className={classes.container}>
          <div className={classes.item}>
            只限付費會員。
          </div>
        </div>
      }
    </>
  ) 
}

export default Blog;