import React, { Component } from "react";
import { Loader, Table, Container, Header, Icon, Menu, Label, Button, List, Image,Card  } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom';
import _ from 'lodash'
import { createStore, combineReducers } from 'redux';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
// import GoogleAd from '../Google_ad'
// import { Link } from 'react-router-dom';
// import './VoucherTable.css';
// import InFeedAdsWhite from '../../ads/InFeedAdsWhite';
// import DisplayAdsBanner from '../../ads/DisplayAdsBanner';
import { isMobile } from "react-device-detect";


export class VoucherTable extends Component {
    state = this.props.data
    render() {
      // console.log('ho')
      // console.log(this.state)
      return (
          <>
              <MetaTags>
                <title>$5000消費券優惠</title>
                <meta name="description" content= '$5000 消費劵及優惠' />
                <meta name="keywords" content='$5000消費劵,一文看清' />
                <meta property="og:title" content= '$5000 消費劵及優惠' />
                <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
              </MetaTags>
              <div style={{paddingTop:'25px', paddingBottom:'25px'}}>
                  { typeof this.state !== 'undefined' ? <SortableTable data={this.state}/> : <NotFound/>}
              </div>
          </>
      );
    }
}

export default VoucherTable;

function NotFound(){
    return (
        <div 
          style={{
            padding: "100px",
            margin: "auto",
            alignItems: "center",
            height: "75vh",
            // overflow: "auto",
          }}
        >
          <Loader active inline='centered' > Loading</Loader>
        </div>
    )
}

const initialState = {
    column: null,
    data: [],
    options: [],
    // direction: null,
    filterText: {value:''},
    lastUpdate: {value:''},
    showReady: 0,
    // filterInd: "",
    // activePage: "1",
    itemType: "1",
    // totalPage: "4"

};

const reducer = combineReducers({
    resultTable: resultTableReducer,
});

function resultTableReducer(state = initialState, action) {
    switch (action.type) {
      case 'CHANGE_SORT':{
        // console.log('fire')
        // console.log(state)
        // console.log(action)
        if (state.column === action.column) {
          return {
            ...state,
            data: state.data.reverse(),
            direction:
              state.direction === 'ascending' ? 'descending' : 'ascending',
          }
        }
        return {
          column: action.column,
          data: _.sortBy(state.data, [action.column]),
          direction: 'ascending',
          filterText: state.filterText
        }
      }
      case 'Filter_MESSAGE':{
        const string = action.text
        // console.log(string)
        // console.log('fire')
  
        return {
          ...state, filterText: string
        }
      }
      case 'TOGGLE_TYPE':{
        const string = action.text
        // console.log(string)
        // console.log('fire')
  
        return {
          ...state, itemType: string
        }
      }
      case 'TOGGLE_READY':{
        const string = action.text
        // console.log(string)
        // console.log('fire')
  
        return {
          ...state, showReady: string
        }
      }
      // case 'SET_IND_FILTER':{
      //   // console.log(action.state)
  
      //   return {
      //     ...state, filterInd: action.state
      //   }
      // }
      // case 'SET_ACTIVE_PAGE':{
      //   const string = action.text
      //   window.scrollTo(0, 0)
        
      //   // console.log('activepage')
      //   // console.log(string)
      //   return {
      //     ...state, activePage: string
      //   }
      // }
      case 'ADD_MESSAGE':{
        // console.log('hihi')
        // console.log(state)
        // console.log({
        //   ...state, data: action.state
        // })
        return {
          ...state, data: action.state
        }
      }
      case 'ADD_OPTIONS':{
        return {
          ...state, options: action.state
        }
      }
      case 'ADD_LASTUPDATE':{
        return {
          ...state, lastUpdate: action.state
        }
      }
      default:{
        return state;
      }
    }
}

const store = createStore(reducer);

class SortableTable extends React.Component{
    constructor(props) {
        super(props);
        this.setData();
    }

    setData(){
        // console.log('ho0')
        // console.log(this.props.data)
        const data1 =this.props.data
        const lastUpdate = data1.topicInfo.lastUpdate
        const name_zh = data1.company
        const cat = data1.cat
        const code = data1.code
        const cond = data1.cond
        const title = data1.title
        const linkNews = data1.linkNews
        const priority = data1.priority
        const details = data1.details
        const isNew = data1.isNew
        const tool = data1.tool
        const appPeriod = data1.appPeriod
        const mallList = data1.mallList
        const tableHeader = data1.tableHeader
        const linkO = data1.linkO
        // const appLink = data1.appLink
        // const options = [{ key: '所有', text: '所有', value: '所有' }]
        const tableData1 = []
        // const totalPage = Math.ceil(date.length/50)
        // console.log(name_zh.length)
        let i = 0
        for (i; i<name_zh.length; i +=1){
          tableData1.push({name_zh: name_zh[i] , cat:cat[i], title: title[i], linkNews: linkNews[i], priority: priority[i],
            details: details[i], isNew: isNew[i],tool: tool[i], appPeriod: appPeriod[i], mallList: mallList[i], linkO: linkO[i], code: code[i]
          })
        }
        // console.log(tableData1)

        store.dispatch({
            type: 'ADD_MESSAGE',
            state: tableData1,
        });
        store.dispatch({
          type: 'ADD_LASTUPDATE',
          state: lastUpdate,
        });
    }

    componentDidMount() {
        // this.setData();
        store.subscribe(() => this.forceUpdate());
    }
    render(){
        const state = store.getState();
        // console.log(state)
        // const tableData = state.resultTable
        // const {column, data, direction, filterText, activePage, isFocus} = state.resultTable
        const {data,lastUpdate, itemType} = state.resultTable

        // console.log(state.resultTable)
        let newData = data  
        // console.log(newData)
        // console.log(newData)  
        // if (filterText.value){
        //     newData = newData.filter((d) => {
        //         return (
        //         (typeof d.code === 'string' && d.code.toLowerCase().includes(filterText.value.toLowerCase())) ||
        //         (typeof d.name_zh === 'string' && d.name_zh.toLowerCase().includes(filterText.value.toLowerCase())) 
        //         // || (typeof d.name_en === 'string' && d.name_en.toLowerCase().includes(filterText.value.toLowerCase()))
        //         )
        //     })
        // }
        // if (filterInd && filterInd !== "所有"){
        //   newData = newData.filter((d) => {
        //     return (
        //     (d.industry === filterInd)
        //     )
        //   })
        // } 
        if (itemType === '1'){
          newData = newData.filter((d) => {
              return(
                  d.cat === "Mall" 
              )
          });
        } else if (itemType === '2'){
          newData = newData.filter((d) => {
              return(
                d.cat === "Daily" 
              )
          });
        } else if (itemType === '3'){
          newData = newData.filter((d) => {
              return(
                d.cat === "O" 
              )
          });
        }else if (itemType === '4'){
          newData = newData.filter((d) => {
              return(
                d.cat === "Other" 
              )
          });
        }

        let content;
        
        if (itemType === '1' || itemType === '2' || itemType === '3'|| itemType === '4'){
          content = <MallTable newData= {newData} lastUpdate = {lastUpdate} />
        } else if (itemType === '0'){
          content = <AppMethod /> 
        } 



        return(
            <div style={{width:'100%', margin: "auto", alignItems: "center"}}>               
              {
                isMobile ? 
                <> <ToggleType/> </> :
                <Container> <ToggleType/> </Container>
              }
              <div style = {{margin: 'auto', width: "100%", maxWidth:"1200px" }}>
                <div style={{  width:'100%', display: 'inline-block', textAlign:'center'}} > 
                <br/>
                {/* <br/>
                <Container textAlign='center'>
                    <DisplayAdsBanner />
                </Container> */}

                {content}
                
                </div>
              </div>

        </div>
        )
    }
}

function VaccinePromoBlock(){
  const history = useHistory();
  return(
    <div>
      <Card centered onClick={() => { history.push("/topics/vaccine_luckydraw_info") }} style ={{ width:'100%', textAlign:'center'}}>
          <div>
          <Image
            alt='一文看清疫苗抽獎及優惠詳情'
            size='medium'
            src="../images/topics/topics_vaccine.png"
            verticalAlign='middle'
          /> {' '}
            {/* <span>一文看清疫苗抽獎及優惠詳情</span> */}
          {isMobile ? null:
            <Label as='a' color='teal' size='big' tag>
              一文看清疫苗抽獎及優惠詳情
            </Label>
          }
          </div>
      </Card>
    </div>
  )
}


function MallTable(props){
  const newData = props.newData
  const lastUpdate = props.lastUpdate
  const table = 
    <Table  unstackable celled striped >
      <Table.Body>
      {newData.map(({ name_zh ,cat ,title ,linkNews ,priority ,details ,isNew ,tool , appPeriod , mallList , linkO, code}, index) => (
        <>
          {/* {(index !== 0) && (index % 10 === 0) && (
            <Table.Row>
              <Table.Cell colSpan={1} textAlign='center' >
                <InFeedAdsWhite></InFeedAdsWhite>
              </Table.Cell>
            </Table.Row>
          )} */}
          {(index === 3) && ( newData.length > 8) &&(
            <Table.Row>
              <Table.Cell  textAlign='center' >
                <VaccinePromoBlock/>
              </Table.Cell>
            </Table.Row>
          )}
          <Table.Row key={index} >
            <Table.Cell >
              <>
              <Header as='h2' color='blue' size='huge'>
                {name_zh.split("\n").join(',')} 
                { code !== "" ? 
                  <span color="black"><Link to={"/stock/" + code} > ({code}) <Icon size='small' name='chart line' /> </Link> </span> 
                  : null
                }  
                {/* { code !== "" ? 
                  <span color="black"> ({code}) <Icon size='small' name='chart line' /> </span> 
                  : null
                }   */}
                <Header.Subheader>
                  <span style={{ontWeight: "bold", color:'black', fontSize:'1.15em'}}> {title}</span>
                </Header.Subheader>
              </Header>

              {
                mallList ==="" ? null:
                <p>
                  <span style={{ color:"grey", fontWeight: "bold", textDecoration: "underline"}}>參與單位 </span>: 
                  &nbsp;
                  {/* <div style={{textIndent: "0.5em"}}> */}
                    {mallList.split("\n").join(' , ')}
                  {/* </div> */}
                </p>
              }


              {/* <Segment basice>
                <span style={{ontWeight: "bold"}}> {title}</span>
              </Segment> */}

              { details === "" ? null:
                <>
                  <span style={{ color:"grey", fontWeight: "bold" , textDecoration: "underline"}}>詳請</span>：
                  <div style={{textIndent: "0.5em"}}>
                    <List>
                      {details.split("\n").map(c => (
                        <List.Item>{c}</List.Item>
                      ))} 
                    </List>
                  </div>
                </>
              }

              {
                appPeriod ==="" ? null:
                <>
                  <span style={{ color:"grey", fontWeight: "bold", textDecoration: "underline"}}>有效期</span>:
                  <div style={{textIndent: "0.5em"}}>
                    {appPeriod}
                  </div>
                </>
              }
              {
                tool ==="" ? null:
                <>
                  <div style={{lineHeight:"50%"}}><br/></div>
                  {/* <span style={{ color:"grey", fontWeight: "bold", textDecoration: "underline" , paddingTop:'10px'}}>支付方法</span>: */}
                  <div style={{textIndent: "0.5em"}}>
                    {tool.includes("Z") ?  <Label color='grey'>任何電子支付工具</Label> : null}
                    {tool.includes("A") ?  <Label color='blue'>Alipay</Label> : null}
                    {tool.includes("W") ?  <Label color='green'>WeChat Pay</Label> : null}
                    {tool.includes("T") ?  <Label color='orange'>Tap&go</Label> : null}
                    {tool.includes("O") ?  <Label color='yellow'>八逹通</Label> : null}
                  </div>
                </>
              }
              <div style={{textIndent: "0.5em", paddingTop:'10px'}}>
                { linkNews === ""  ? null : 
                  // <div style={{textIndent: "0.5em", paddingTop:'10px'}}>
                  <>
                        <Button color='teal' size='tiny' compact target="_blank" rel="noopener noreferrer" href={linkNews}>
                          <Icon name='linkify' /> 新聞連結
                        </Button>
                    </>
                  // </div>
                }
                { linkO === "" ? null : 
                  // <div style={{textIndent: "0.5em", paddingTop:'10px'}}>
                        <Button color='olive' size='tiny' compact target="_blank" rel="noopener noreferrer" href={linkO}>
                          <Icon name='linkify' /> 官方連結
                        </Button>
                  // </div>
                }
              </div>
            </> 
          </Table.Cell>
          </Table.Row>
          {(index === newData.length-1) && (
            <Table.Row>
              <Table.Cell  textAlign='center' >
                <VaccinePromoBlock/>
              </Table.Cell>
            </Table.Row>
          )}
        </>
      ))}
      </Table.Body>
    </Table>
  const head = 
    <div style = {{margin: 'auto', paddingLeft: "15px", paddingRight:"15px" }}>
      <Header as="h5"  textAlign='left' color ='grey'> 總共 {newData.length} 項 </Header>  
      <Header as="h4"  textAlign='right' color ='grey'> 最後更新時間 : {lastUpdate} </Header>
    </div>
  return (
    <>
      {
        isMobile ? head : <Container> {head} </Container>
      }

      {
        isMobile ? 
        table : <Container> {table} </Container>
      
      }
    </>  
  );
}

function AppMethod(){
  const table = 
    <Table unstackable celled>
  <Table.Header>

    <Table.Row>
      <Table.HeaderCell colSpan={5} textAlign="center">
        $5000 消費劵
      </Table.HeaderCell>
    </Table.Row>

    <Table.Row>
      <Table.HeaderCell>
        登記
      </Table.HeaderCell>
      <Table.Cell colSpan={4}>
        
        <Table basic='very' unstackable>
          <Table.Body>
            <Table.Row>
              <Table.HeaderCell width={2} colSpan={2} textAlign="center">
                2021年7月4日－2021年8月14日
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell width={8} textAlign="center">
                電子登記
              </Table.HeaderCell>
              <Table.HeaderCell width={8} textAlign="center">
                書面登記
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                在
                <a href="https://www.consumptionvoucher.gov.hk" target="_blank">政府網站</a>
                登記
              </Table.Cell>
              <Table.Cell>
                在政府網站
                <a href="https://www.consumptionvoucher.gov.hk/tc/forms.html" target="_blank">下載</a>
                及遞交書面登記表格並連同其香港身分證複本釘好及對摺，在以下地點交表
                <List bulleted>
                  <List.Item>郵局</List.Item>
                  <List.Item>滙豐</List.Item>
                  <List.Item>恒生</List.Item>
                  <List.Item>渣打</List.Item>
                  <List.Item>中銀</List.Item>
                  <List.Item>東亞</List.Item>
                </List>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Table.Cell>
    </Table.Row>

    <Table.Row>
      <Table.HeaderCell width = {2}>
        支付工具
      </Table.HeaderCell>
      <Table.HeaderCell width = {6} textAlign="center">
        八逹通
      </Table.HeaderCell>
      <Table.HeaderCell width = {2} textAlign="center">
        Tap & Go
      </Table.HeaderCell>
      <Table.HeaderCell width = {2} textAlign="center">
        Alipay HK
      </Table.HeaderCell>
      <Table.HeaderCell width = {2} textAlign="center">
        WeChat Pay HK
      </Table.HeaderCell>
    </Table.Row>

    <Table.Row>
      <Table.HeaderCell>
        收錢方法
      </Table.HeaderCell>
      <Table.Cell>
        每期收到短訊後，用八達通App或到達以下地點拍卡領取
        <List bulleted>
          <List.Item>交通津貼領取站</List.Item>
          <List.Item>八達通服務站</List.Item>
          <List.Item>7-11 便利店</List.Item>
          <List.Item>OK 便利店</List.Item>
          <List.Item>惠康分店</List.Item>
        </List>
      </Table.Cell>
      <Table.Cell colSpan={3}>
        每期自動存入App
      </Table.Cell>
    </Table.Row>

    <Table.Row>
      <Table.HeaderCell>
        分期方式
      </Table.HeaderCell>
      <Table.Cell>
        <Table unstackable>
          <Table.Body>
            <Table.Row>
              <Table.HeaderCell width={8}>
                第一期
              </Table.HeaderCell>
              <Table.Cell textAlign="center" width={8}>
                <Label size="large">$2000</Label>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell textAlign="center" colSpan={2}>
                兩個月後
                <Icon name="arrow circle down" size="large"></Icon>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell width={5}>
                第二期
              </Table.HeaderCell>
              <Table.Cell textAlign="center">
                <Label size="large">$2000</Label>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell textAlign="center" colSpan={2}>
                兩個月後（如用畢$4000）
                <Icon name="arrow circle down" size="large"></Icon>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell width={5}>
                第三期
              </Table.HeaderCell>
              <Table.Cell textAlign="center">
                <Label size="large">$1000</Label>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        **若八達通到達$3000儲值上限，可待儲值較低時再領取，並可分開多次領取。
      </Table.Cell>
      <Table.Cell colSpan={3}>
        <Table unstackable>
          <Table.Body>
            <Table.Row>
              <Table.HeaderCell width={8}>
                第一期
              </Table.HeaderCell>
              <Table.Cell textAlign="center" width={8}>
                <Label size="large">$2000</Label>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell textAlign="center" colSpan={2}>
                兩個月後
                <Icon name="arrow circle down" size="large"></Icon>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>
                第二期
              </Table.HeaderCell>
              <Table.Cell textAlign="center">
                <Label size="large">$3000</Label>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Table.Cell>
    </Table.Row>

    <Table.Row>
      <Table.HeaderCell>
        期限
      </Table.HeaderCell>
      <Table.Cell>
        每期須3個月內領取，無使用期限。必須在收到第一期後7個月內用畢$4000才獲發第三期。
      </Table.Cell>
      <Table.Cell colSpan={3}>
        必須在5個月內用畢$5000，否則餘額作廢。
      </Table.Cell>
    </Table.Row>

    <Table.Row>
      <Table.HeaderCell>
        第一期錢
      </Table.HeaderCell>
      <Table.Cell colSpan={4}>
        <Table>
          <Table.Body>
            <Table.Row>
              <Table.HeaderCell width={5}>
                7月4日－7月17日電子登記
              </Table.HeaderCell>
              <Table.Cell>
                最快8月1日收取
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>
                7月18日電子登記或以後
              </Table.HeaderCell>
              <Table.Cell>
                最快9月1日收取
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>
                書面登記
              </Table.HeaderCell>
              <Table.Cell>
                最快9月1日收取
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Table.Cell>
    </Table.Row>

    <Table.Row positive>
      <Table.HeaderCell>
        可用消費
      </Table.HeaderCell>
      <Table.Cell colSpan={4}>
        <List bulleted>
          <List.Item>本地零售實體店／網店</List.Item>
          <List.Item>餐飲實體店／網店</List.Item>
          <List.Item>服務業實體店／網店</List.Item>
          <List.Item>交通工具</List.Item>
        </List>
      </Table.Cell>
    </Table.Row>


    <Table.Row negative>
      <Table.HeaderCell>
        不可用消費
      </Table.HeaderCell>
      <Table.Cell colSpan={4}>
        <List bulleted>
          <List.Item>水電煤</List.Item>
          <List.Item>政府費用</List.Item>
          <List.Item>大中小學學費</List.Item>
          <List.Item>金融産品</List.Item>
          <List.Item>向個人付款</List.Item>
          <List.Item>兌換現金</List.Item>
        </List>
      </Table.Cell>
    </Table.Row>

    <Table.Row>
      <Table.Cell width={16} colSpan={5}>
        資料來源：
        <a href="https://www.consumptionvoucher.gov.hk" target="_blank">政府消費劵網站</a>、
        <a href="https://voucher.octopus.com.hk/tc/overview/index.html" target="_blank">八達通</a>、
        <a href="https://www.tapngo.com.hk/chi/cvs.html" target="_blank">Tap & Go</a>、
        <a href="https://www.alipayhk.com/zh/5000voucher" target="_blank">Alipay HK</a>、
        <a href="https://pay.wechat.com/" target="_blank">WeChat Pay HK</a>
        <br />
        **所有資訊僅供參考，並不構成要約、招攬或邀請、宣傳、誘使，或任何不論種類或形式之表示、建議或推薦任何行動
      </Table.Cell>
    </Table.Row>
  </Table.Header>
</Table> 
  return (
    <>
    {
      isMobile ? table: <Container> {table} </Container>
    }
   </>
  )
}

class ToggleType extends React.Component {
  componentDidMount() {
    store.subscribe(() => this.forceUpdate());
  }
  
  state = { activeItem: '商場' }

  handleItemClick = (e, { name }) => {
      this.setState({ activeItem: name })
      if (name === '商場'){
          store.dispatch({
              type: 'TOGGLE_TYPE',
              text: "1",
          });
      } else if (name === '登記及領取流程') {
          store.dispatch({
              type: 'TOGGLE_TYPE',
              text: "0",
          });
      } else if (name === '購物') {
        store.dispatch({
            type: 'TOGGLE_TYPE',
            text: "2",
        });
      } else if (name === '其他') {
        store.dispatch({
            type: 'TOGGLE_TYPE',
            text: "4",
        });
      } else if (name === '官方優惠專區') {
        store.dispatch({
            type: 'TOGGLE_TYPE',
            text: "3",
        });
      } 
  }

  render() {
      const { activeItem } = this.state

      return (
          <div >
              <Menu pointing secondary compact>
                  <Menu.Item
                      name='商場'
                      active={activeItem === '商場'}
                      onClick={this.handleItemClick}
                  />
                  <Menu.Item
                      name='購物'
                      active={activeItem === '購物'}
                      onClick={this.handleItemClick}
                  />
                  <Menu.Item
                      name='其他'
                      active={activeItem === '其他'}
                      onClick={this.handleItemClick}
                  />
                  <Menu.Item
                      name='官方優惠專區'
                      active={activeItem === '官方優惠專區'}
                      onClick={this.handleItemClick}
                  />
                  <Menu.Item
                      name='登記及領取流程'
                      active={activeItem === '登記及領取流程'}
                      onClick={this.handleItemClick}
                  />
              </Menu>
          </div>
      );
  }
}

