import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  Dimmer,
  Grid,
  Loader,
  Modal,
  Segment,
  Header,
  List,
  Container,
  Icon,
} from "semantic-ui-react";
import { SupportUsDescription } from "./SupportUsWidget";
import { subscribeProfessionalPlan } from "components/subscription/subscribe";
import { useUser, useIsSubscribed } from "features/user/userSlice";

const DonationPlanCard = (
  <Card>
    <Card.Content>
      <Card.Header textAlign="center">隨緣樂助</Card.Header>
      <Card.Description>
        <SupportUsDescription />
      </Card.Description>
    </Card.Content>
  </Card>
);

const ProfessionalPlanCard = (props) => {
  let { onClick } = props;
  return (
    <Card>
      <Card.Content>
        <Card.Header textAlign="center">專業</Card.Header>
        <Card.Description>
          <div style={{ textAlign: "center" }}>
            <span style={{ textDecoration: "underline", fontWeight: "bold" }}>
              $1500 / 半年
            </span>
          </div>
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Grid>
          <Grid.Column textAlign="center">
            <Button color="blue" onClick={onClick}>
              訂閱
            </Button>
          </Grid.Column>
        </Grid>
      </Card.Content>
    </Card>
  );
};

const TestPlanCard = (props) => {
  let { onClick } = props;
  return (
    <Card>
      <Card.Content>
        <Card.Header textAlign="center">付費版</Card.Header>
        <Card.Description>
          <div style={{ textAlign: "center" }}>
            <span style={{ textDecoration: "underline", fontWeight: "bold" }}>
              <Header as="h2"> $88/一個月 </Header>
            </span>
          </div>
          <div
            style={{
              textAlign: "center",
              paddingTop: "25px",
              fontSize: "large",
            }}
          >
            <List>
              <List.Item>
                <List.Icon
                  name="chart line"
                  size="large"
                  verticalAlign="middle"
                />
                <List.Content>
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    快速檢閱個股相對強度(RS)及CCASS走勢圖。
                  </span>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Icon
                  name="chart line"
                  size="large"
                  verticalAlign="middle"
                />
                <List.Content>
                  <span style={{ fontWeight: "bold" }}> 檢閱大市市寛。</span>
                </List.Content>
              </List.Item>
              {/* <List.Item>
                <List.Description>
                  <span style={{ fontSize: "medium", paddingTop: "50px" }}>
                    {" "}
                    *測試版為每月收費，不設退款。
                  </span>
                </List.Description>
              </List.Item> */}
            </List>
          </div>
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Grid>
          <Grid.Column textAlign="center">
            <Button color="blue" onClick={onClick}>
              加入付費版
            </Button>
          </Grid.Column>
        </Grid>
      </Card.Content>
    </Card>
  );
};

function SubscriptionPlans() {
  const user = useUser();
  const subscribed = useIsSubscribed();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const onClick = async () => {
    if (!user) {
      history.push(`/myaccount?onLogin=subscribe`);
    } else {
      if (!subscribed) {
        setLoading(true);
        await subscribeProfessionalPlan(user.uid);
      } else {
        history.push("/myaccount");
      }
    }
  };
  const emailString = (
    <a href="mailto:contact@daashk.com"> contact@DaaSHK.com</a>
  );
  return (
    <Container>
      <Segment basic>
        {isLoading ? (
          <Dimmer active inverted>
            <Loader size="medium">Loading</Loader>
          </Dimmer>
        ) : undefined}
        <Card.Group centered style={{ padding: "1em 0em" }}>
          {<TestPlanCard onClick={onClick} />}
          {DonationPlanCard}
        </Card.Group>
      </Segment>
      <div
        style={{
          padding: "2em 0em",
          paddingBottom: "5px",
          textAlign: "center",
        }}
      >
        <Header as="h2">聯絡我們</Header>
        <p>如有任何問題，你可以透過以下途徑與我們聯絡:</p>

        <p>電郵(Email) : {emailString}</p>

        <p>
          facebook :
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/idaashk"
          >
            <Icon name="facebook official" size="large" />
          </a>
        </p>
      </div>
    </Container>
  );
}

function SubScriptionPlansModal(props) {
  const { prompt, trigger } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={trigger}
    >
      <Modal.Header>{prompt}</Modal.Header>
      <Modal.Content>
        <SubscriptionPlans />
      </Modal.Content>
    </Modal>
  );
}

export { SubscriptionPlans, SubScriptionPlansModal, TestPlanCard };
